export const countryList = 
[{"label": "Afghanistan", "value": "AF"},
 {"label": "Albania", "value": "AL"},
 {"label": "Algeria", "value": "DZ"},
 {"label": "Andorra", "value": "AD"},
 {"label": "Angola", "value": "AO"},
 {"label": "Antigua And Barbuda", "value": "AG"},
 {"label": "Argentina", "value": "AR"},
 {"label": "Armenia", "value": "AM"},
 {"label": "Australia", "value": "AU"},
 {"label": "Austria", "value": "AT"},
 {"label": "Azerbaijan", "value": "AZ"},
 {"label": "Bahrain", "value": "BH"},
 {"label": "Bangladesh", "value": "BD"},
 {"label": "Barbados", "value": "BB"},
 {"label": "Belarus", "value": "BY"},
 {"label": "Belgium", "value": "BE"},
 {"label": "Belize", "value": "BZ"},
 {"label": "Benin", "value": "BJ"},
 {"label": "Bhutan", "value": "BT"},
 {"label": "Bolivia", "value": "BO"},
 {"label": "Bonaire, Sint Eustatius and Saba", "value": "BQ"},
 {"label": "Bosnia and Herzegovina", "value": "BA"},
 {"label": "Botswana", "value": "BW"},
 {"label": "Brazil", "value": "BR"},
 {"label": "Brunei", "value": "BN"},
 {"label": "Bulgaria", "value": "BG"},
 {"label": "Burkina Faso", "value": "BF"},
 {"label": "Burundi", "value": "BI"},
 {"label": "Cambodia", "value": "KH"},
 {"label": "Cameroon", "value": "CM"},
 {"label": "Canada", "value": "CA"},
 {"label": "Cape Verde", "value": "CV"},
 {"label": "Central African Republic", "value": "CF"},
 {"label": "Chad", "value": "TD"},
 {"label": "Chile", "value": "CL"},
 {"label": "China", "value": "CN"},
 {"label": "Colombia", "value": "CO"},
 {"label": "Comoros", "value": "KM"},
 {"label": "Congo", "value": "CG"},
 {"label": "Costa Rica", "value": "CR"},
 {"label": "Cote DIvoire (Ivory Coast)", "value": "CI"},
 {"label": "Croatia", "value": "HR"},
 {"label": "Cuba", "value": "CU"},
 {"label": "Cyprus", "value": "CY"},
 {"label": "Czech Republic", "value": "CZ"},
 {"label": "Democratic Republic of the Congo", "value": "CD"},
 {"label": "Denmark", "value": "DK"},
 {"label": "Djibouti", "value": "DJ"},
 {"label": "Dominica", "value": "DM"},
 {"label": "Dominican Republic", "value": "DO"},
 {"label": "East Timor", "value": "TL"},
 {"label": "Ecuador", "value": "EC"},
 {"label": "Egypt", "value": "EG"},
 {"label": "El Salvador", "value": "SV"},
 {"label": "Equatorial Guinea", "value": "GQ"},
 {"label": "Eritrea", "value": "ER"},
 {"label": "Estonia", "value": "EE"},
 {"label": "Ethiopia", "value": "ET"},
 {"label": "Fiji Islands", "value": "FJ"},
 {"label": "Finland", "value": "FI"},
 {"label": "France", "value": "FR"},
 {"label": "Gabon", "value": "GA"},
 {"label": "Gambia The", "value": "GM"},
 {"label": "Georgia", "value": "GE"},
 {"label": "Germany", "value": "DE"},
 {"label": "Ghana", "value": "GH"},
 {"label": "Greece", "value": "GR"},
 {"label": "Grenada", "value": "GD"},
 {"label": "Guatemala", "value": "GT"},
 {"label": "Guinea", "value": "GN"},
 {"label": "Guinea-Bissau", "value": "GW"},
 {"label": "Guyana", "value": "GY"},
 {"label": "Haiti", "value": "HT"},
 {"label": "Honduras", "value": "HN"},
 {"label": "Hungary", "value": "HU"},
 {"label": "Iceland", "value": "IS"},
 {"label": "India", "value": "IN"},
 {"label": "Indonesia", "value": "ID"},
 {"label": "Iran", "value": "IR"},
 {"label": "Iraq", "value": "IQ"},
 {"label": "Ireland", "value": "IE"},
 {"label": "Israel", "value": "IL"},
 {"label": "Italy", "value": "IT"},
 {"label": "Jamaica", "value": "JM"},
 {"label": "Japan", "value": "JP"},
 {"label": "Jordan", "value": "JO"},
 {"label": "Kazakhstan", "value": "KZ"},
 {"label": "Kenya", "value": "KE"},
 {"label": "Kiribati", "value": "KI"},
 {"label": "Kuwait", "value": "KW"},
 {"label": "Kyrgyzstan", "value": "KG"},
 {"label": "Laos", "value": "LA"},
 {"label": "Latvia", "value": "LV"},
 {"label": "Lebanon", "value": "LB"},
 {"label": "Lesotho", "value": "LS"},
 {"label": "Liberia", "value": "LR"},
 {"label": "Libya", "value": "LY"},
 {"label": "Liechtenstein", "value": "LI"},
 {"label": "Lithuania", "value": "LT"},
 {"label": "Luxembourg", "value": "LU"},
 {"label": "Macedonia", "value": "MK"},
 {"label": "Madagascar", "value": "MG"},
 {"label": "Malawi", "value": "MW"},
 {"label": "Malaysia", "value": "MY"},
 {"label": "Maldives", "value": "MV"},
 {"label": "Mali", "value": "ML"},
 {"label": "Malta", "value": "MT"},
 {"label": "Mauritania", "value": "MR"},
 {"label": "Mauritius", "value": "MU"},
 {"label": "Mexico", "value": "MX"},
 {"label": "Micronesia", "value": "FM"},
 {"label": "Moldova", "value": "MD"},
 {"label": "Mongolia", "value": "MN"},
 {"label": "Montenegro", "value": "ME"},
 {"label": "Morocco", "value": "MA"},
 {"label": "Mozambique", "value": "MZ"},
 {"label": "Myanmar", "value": "MM"},
 {"label": "Namibia", "value": "NA"},
 {"label": "Nauru", "value": "NR"},
 {"label": "Nepal", "value": "NP"},
 {"label": "Netherlands", "value": "NL"},
 {"label": "New Zealand", "value": "NZ"},
 {"label": "Nicaragua", "value": "NI"},
 {"label": "Niger", "value": "NE"},
 {"label": "Nigeria", "value": "NG"},
 {"label": "North Korea", "value": "KP"},
 {"label": "Norway", "value": "NO"},
 {"label": "Oman", "value": "OM"},
 {"label": "Pakistan", "value": "PK"},
 {"label": "Palau", "value": "PW"},
 {"label": "Panama", "value": "PA"},
 {"label": "Papua new Guinea", "value": "PG"},
 {"label": "Paraguay", "value": "PY"},
 {"label": "Peru", "value": "PE"},
 {"label": "Philippines", "value": "PH"},
 {"label": "Poland", "value": "PL"},
 {"label": "Portugal", "value": "PT"},
 {"label": "Qatar", "value": "QA"},
 {"label": "Romania", "value": "RO"},
 {"label": "Russia", "value": "RU"},
 {"label": "Rwanda", "value": "RW"},
 {"label": "Saint Kitts And Nevis", "value": "KN"},
 {"label": "Saint Lucia", "value": "LC"},
 {"label": "Saint Vincent And The Grenadines", "value": "VC"},
 {"label": "Samoa", "value": "WS"},
 {"label": "San Marino", "value": "SM"},
 {"label": "Sao Tome and Principe", "value": "ST"},
 {"label": "Saudi Arabia", "value": "SA"},
 {"label": "Senegal", "value": "SN"},
 {"label": "Serbia", "value": "RS"},
 {"label": "Seychelles", "value": "SC"},
 {"label": "Sierra Leone", "value": "SL"},
 {"label": "Singapore", "value": "SG"},
 {"label": "Slovakia", "value": "SK"},
 {"label": "Slovenia", "value": "SI"},
 {"label": "Solomon Islands", "value": "SB"},
 {"label": "Somalia", "value": "SO"},
 {"label": "South Africa", "value": "ZA"},
 {"label": "South Korea", "value": "KR"},
 {"label": "South Sudan", "value": "SS"},
 {"label": "Spain", "value": "ES"},
 {"label": "Sri Lanka", "value": "LK"},
 {"label": "Sudan", "value": "SD"},
 {"label": "Suriname", "value": "SR"},
 {"label": "Swaziland", "value": "SZ"},
 {"label": "Sweden", "value": "SE"},
 {"label": "Switzerland", "value": "CH"},
 {"label": "Syria", "value": "SY"},
 {"label": "Taiwan", "value": "TW"},
 {"label": "Tajikistan", "value": "TJ"},
 {"label": "Tanzania", "value": "TZ"},
 {"label": "Thailand", "value": "TH"},
 {"label": "The Bahamas", "value": "BS"},
 {"label": "Togo", "value": "TG"},
 {"label": "Tonga", "value": "TO"},
 {"label": "Trinidad And Tobago", "value": "TT"},
 {"label": "Tunisia", "value": "TN"},
 {"label": "Turkey", "value": "TR"},
 {"label": "Turkmenistan", "value": "TM"},
 {"label": "Tuvalu", "value": "TV"},
 {"label": "Uganda", "value": "UG"},
 {"label": "Ukraine", "value": "UA"},
 {"label": "United Arab Emirates", "value": "AE"},
 {"label": "United Kingdom", "value": "GB"},
 {"label": "United States", "value": "US"},
 {"label": "Uruguay", "value": "UY"},
 {"label": "Uzbekistan", "value": "UZ"},
 {"label": "Vanuatu", "value": "VU"},
 {"label": "Venezuela", "value": "VE"},
 {"label": "Vietnam", "value": "VN"},
 {"label": "Virgin Islands (US)", "value": "VI"},
 {"label": "Yemen", "value": "YE"},
 {"label": "Zambia", "value": "ZM"},
 {"label": "Zimbabwe", "value": "ZW"}]

export const statesMap = 
{"Afghanistan": [{"label": "Badakhshan", "value": "BDS"},
                 {"label": "Badghis", "value": "BDG"},
                 {"label": "Baghlan", "value": "BGL"},
                 {"label": "Balkh", "value": "BAL"},
                 {"label": "Bamyan", "value": "BAM"},
                 {"label": "Daykundi", "value": "DAY"},
                 {"label": "Farah", "value": "FRA"},
                 {"label": "Faryab", "value": "FYB"},
                 {"label": "Ghazni", "value": "GHA"},
                 {"label": "Ghor", "value": "GHO"},
                 {"label": "Helmand", "value": "HEL"},
                 {"label": "Herat", "value": "HER"},
                 {"label": "Jowzjan", "value": "JOW"},
                 {"label": "Kabul", "value": "KAB"},
                 {"label": "Kandahar", "value": "KAN"},
                 {"label": "Kapisa", "value": "KAP"},
                 {"label": "Khost", "value": "KHO"},
                 {"label": "Kunar", "value": "KNR"},
                 {"label": "Kunduz Province", "value": "KDZ"},
                 {"label": "Laghman", "value": "LAG"},
                 {"label": "Logar", "value": "LOG"},
                 {"label": "Nangarhar", "value": "NAN"},
                 {"label": "Nimruz", "value": "NIM"},
                 {"label": "Nuristan", "value": "NUR"},
                 {"label": "Paktia", "value": "PIA"},
                 {"label": "Paktika", "value": "PKA"},
                 {"label": "Panjshir", "value": "PAN"},
                 {"label": "Parwan", "value": "PAR"},
                 {"label": "Samangan", "value": "SAM"},
                 {"label": "Sar-e Pol", "value": "SAR"},
                 {"label": "Takhar", "value": "TAK"},
                 {"label": "Urozgan", "value": "URU"},
                 {"label": "Zabul", "value": "ZAB"}],
 "Albania": [{"label": "Berat District", "value": "BR"},
             {"label": "Diber County", "value": "09"},
             {"label": "Durres District", "value": "DR"},
             {"label": "Fier County", "value": "04"},
             {"label": "Gjirokaster District", "value": "GJ"},
             {"label": "Korce County", "value": "06"},
             {"label": "Kukes District", "value": "KU"},
             {"label": "Lezhe County", "value": "08"},
             {"label": "Shkoder District", "value": "SH"},
             {"label": "Tirana District", "value": "TR"},
             {"label": "Vlore County", "value": "12"}],
 "Algeria": [{"label": "Adrar", "value": "01"},
             {"label": "Ain Defla", "value": "44"},
             {"label": "Ain Temouchent", "value": "46"},
             {"label": "Algiers", "value": "16"},
             {"label": "Annaba", "value": "23"},
             {"label": "Batna", "value": "05"},
             {"label": "Bechar", "value": "08"},
             {"label": "Bejaia", "value": "06"},
             {"label": "Biskra", "value": "07"},
             {"label": "Blida", "value": "09"},
             {"label": "Bordj Bou Arreridj", "value": "34"},
             {"label": "Bouira", "value": "10"},
             {"label": "Boumerdes", "value": "35"},
             {"label": "Chlef", "value": "02"},
             {"label": "Constantine", "value": "25"},
             {"label": "Djelfa", "value": "17"},
             {"label": "El Bayadh", "value": "32"},
             {"label": "El Oued", "value": "39"},
             {"label": "El Tarf", "value": "36"},
             {"label": "Ghardaia", "value": "47"},
             {"label": "Guelma", "value": "24"},
             {"label": "Illizi", "value": "33"},
             {"label": "Jijel", "value": "18"},
             {"label": "Khenchela", "value": "40"},
             {"label": "Laghouat", "value": "03"},
             {"label": "MSila", "value": "28"},
             {"label": "Mascara", "value": "29"},
             {"label": "Medea", "value": "26"},
             {"label": "Mila", "value": "43"},
             {"label": "Mostaganem", "value": "27"},
             {"label": "Naama", "value": "45"},
             {"label": "Oran", "value": "31"},
             {"label": "Ouargla", "value": "30"},
             {"label": "Oum El Bouaghi", "value": "04"},
             {"label": "Relizane", "value": "48"},
             {"label": "Saida", "value": "20"},
             {"label": "Setif", "value": "19"},
             {"label": "Sidi Bel Abbes", "value": "22"},
             {"label": "Skikda", "value": "21"},
             {"label": "Souk Ahras", "value": "41"},
             {"label": "Tamanghasset", "value": "11"},
             {"label": "Tebessa", "value": "12"},
             {"label": "Tiaret", "value": "14"},
             {"label": "Tindouf", "value": "37"},
             {"label": "Tipasa", "value": "42"},
             {"label": "Tissemsilt", "value": "38"},
             {"label": "Tizi Ouzou", "value": "15"},
             {"label": "Tlemcen", "value": "13"}],
 "Andorra": [{"label": "Andorra la Vella", "value": "07"},
             {"label": "Canillo", "value": "02"},
             {"label": "Encamp", "value": "03"},
             {"label": "Escaldes-Engordany", "value": "08"},
             {"label": "La Massana", "value": "04"},
             {"label": "Ordino", "value": "05"},
             {"label": "Sant Julia de Loria", "value": "06"}],
 "Angola": [{"label": "Bengo Province", "value": "BGO"},
            {"label": "Benguela Province", "value": "BGU"},
            {"label": "Bie Province", "value": "BIE"},
            {"label": "Cabinda Province", "value": "CAB"},
            {"label": "Cuando Cubango Province", "value": "CCU"},
            {"label": "Cuanza Norte Province", "value": "CNO"},
            {"label": "Cuanza Sul", "value": "CUS"},
            {"label": "Cunene Province", "value": "CNN"},
            {"label": "Huambo Province", "value": "HUA"},
            {"label": "Huila Province", "value": "HUI"},
            {"label": "Luanda Province", "value": "LUA"},
            {"label": "Lunda Norte Province", "value": "LNO"},
            {"label": "Lunda Sul Province", "value": "LSU"},
            {"label": "Malanje Province", "value": "MAL"},
            {"label": "Moxico Province", "value": "MOX"},
            {"label": "Uige Province", "value": "UIG"},
            {"label": "Zaire Province", "value": "ZAI"}],
 "Antigua And Barbuda": [{"label": "Barbuda", "value": "10"},
                         {"label": "Saint George Parish", "value": "03"},
                         {"label": "Saint John Parish", "value": "04"},
                         {"label": "Saint Mary Parish", "value": "05"},
                         {"label": "Saint Paul Parish", "value": "06"},
                         {"label": "Saint Peter Parish", "value": "07"}],
 "Argentina": [{"label": "Buenos Aires", "value": "B"},
               {"label": "Catamarca", "value": "K"},
               {"label": "Chaco", "value": "H"},
               {"label": "Chubut", "value": "U"},
               {"label": "Cordoba", "value": "X"},
               {"label": "Corrientes", "value": "W"},
               {"label": "Entre Rios", "value": "E"},
               {"label": "Formosa", "value": "P"},
               {"label": "Jujuy", "value": "Y"},
               {"label": "La Pampa", "value": "L"},
               {"label": "La Rioja", "value": "F"},
               {"label": "Mendoza", "value": "M"},
               {"label": "Misiones", "value": "N"},
               {"label": "Neuquen", "value": "Q"},
               {"label": "Rio Negro", "value": "R"},
               {"label": "Salta", "value": "A"},
               {"label": "San Juan", "value": "J"},
               {"label": "San Luis", "value": "D"},
               {"label": "Santa Cruz", "value": "Z"},
               {"label": "Santa Fe", "value": "S"},
               {"label": "Santiago del Estero", "value": "G"},
               {"label": "Tierra del Fuego", "value": "V"},
               {"label": "Tucuman", "value": "T"}],
 "Armenia": [{"label": "Aragatsotn Region", "value": "AG"},
             {"label": "Ararat Province", "value": "AR"},
             {"label": "Armavir Region", "value": "AV"},
             {"label": "Gegharkunik Province", "value": "GR"},
             {"label": "Kotayk Region", "value": "KT"},
             {"label": "Lori Region", "value": "LO"},
             {"label": "Shirak Region", "value": "SH"},
             {"label": "Syunik Province", "value": "SU"},
             {"label": "Tavush Region", "value": "TV"},
             {"label": "Vayots Dzor Region", "value": "VD"},
             {"label": "Yerevan", "value": "ER"}],
 "Australia": [{"label": "Australian Capital Territory", "value": "ACT"},
               {"label": "New South Wales", "value": "NSW"},
               {"label": "Northern Territory", "value": "NT"},
               {"label": "Queensland", "value": "QLD"},
               {"label": "South Australia", "value": "SA"},
               {"label": "Tasmania", "value": "TAS"},
               {"label": "Victoria", "value": "VIC"},
               {"label": "Western Australia", "value": "WA"}],
 "Austria": [{"label": "Burgenland", "value": "1"},
             {"label": "Carinthia", "value": "2"},
             {"label": "Lower Austria", "value": "3"},
             {"label": "Salzburg", "value": "5"},
             {"label": "Styria", "value": "6"},
             {"label": "Tyrol", "value": "7"},
             {"label": "Upper Austria", "value": "4"},
             {"label": "Vienna", "value": "9"},
             {"label": "Vorarlberg", "value": "8"}],
 "Azerbaijan": [{"label": "Absheron District", "value": "ABS"},
                {"label": "Agdam District", "value": "AGM"},
                {"label": "Agdash District", "value": "AGS"},
                {"label": "Aghjabadi District", "value": "AGC"},
                {"label": "Agstafa District", "value": "AGA"},
                {"label": "Agsu District", "value": "AGU"},
                {"label": "Astara District", "value": "AST"},
                {"label": "Baku", "value": "BA"},
                {"label": "Balakan District", "value": "BAL"},
                {"label": "Barda District", "value": "BAR"},
                {"label": "Beylagan District", "value": "BEY"},
                {"label": "Bilasuvar District", "value": "BIL"},
                {"label": "Dashkasan District", "value": "DAS"},
                {"label": "Fizuli District", "value": "FUZ"},
                {"label": "Ganja", "value": "GA"},
                {"label": "G@d@b@y", "value": "GAD"},
                {"label": "Gobustan District", "value": "QOB"},
                {"label": "Goranboy District", "value": "GOR"},
                {"label": "Goychay", "value": "GOY"},
                {"label": "Goygol District", "value": "GYG"},
                {"label": "Hajigabul District", "value": "HAC"},
                {"label": "Imishli District", "value": "IMI"},
                {"label": "Ismailli District", "value": "ISM"},
                {"label": "Jabrayil District", "value": "CAB"},
                {"label": "Jalilabad District", "value": "CAL"},
                {"label": "Kalbajar District", "value": "KAL"},
                {"label": "Khachmaz District", "value": "XAC"},
                {"label": "Khizi District", "value": "XIZ"},
                {"label": "Khojali District", "value": "XCI"},
                {"label": "Kurdamir District", "value": "KUR"},
                {"label": "Lachin District", "value": "LAC"},
                {"label": "Lankaran District", "value": "LA"},
                {"label": "Lerik District", "value": "LER"},
                {"label": "Martuni", "value": "XVD"},
                {"label": "Masally District", "value": "MAS"},
                {"label": "Mingachevir", "value": "MI"},
                {"label": "Nakhchivan Autonomous Republic", "value": "NX"},
                {"label": "Neftchala District", "value": "NEF"},
                {"label": "Oghuz District", "value": "OGU"},
                {"label": "Qabala District", "value": "QAB"},
                {"label": "Qakh District", "value": "QAX"},
                {"label": "Qazakh District", "value": "QAZ"},
                {"label": "Quba District", "value": "QBA"},
                {"label": "Qubadli District", "value": "QBI"},
                {"label": "Qusar District", "value": "QUS"},
                {"label": "Saatly District", "value": "SAT"},
                {"label": "Sabirabad District", "value": "SAB"},
                {"label": "Salyan District", "value": "SAL"},
                {"label": "Samukh District", "value": "SMX"},
                {"label": "Shabran District", "value": "SBN"},
                {"label": "Shaki", "value": "SA"},
                {"label": "Shaki District", "value": "SAK"},
                {"label": "Shamakhi District", "value": "SMI"},
                {"label": "Shamkir District", "value": "SKR"},
                {"label": "Shirvan", "value": "SR"},
                {"label": "Shusha District", "value": "SUS"},
                {"label": "Siazan District", "value": "SIY"},
                {"label": "Sumqayit", "value": "SM"},
                {"label": "Tartar District", "value": "TAR"},
                {"label": "Tovuz District", "value": "TOV"},
                {"label": "Ujar District", "value": "UCA"},
                {"label": "Yardymli District", "value": "YAR"},
                {"label": "Yevlakh", "value": "YE"},
                {"label": "Yevlakh District", "value": "YEV"},
                {"label": "Zangilan District", "value": "ZAN"},
                {"label": "Zaqatala District", "value": "ZAQ"},
                {"label": "Zardab District", "value": "ZAR"}],
 "Bahrain": [{"label": "Capital Governorate", "value": "13"},
             {"label": "Central Governorate", "value": "16"},
             {"label": "Muharraq Governorate", "value": "15"},
             {"label": "Southern Governorate", "value": "14"}],
 "Bangladesh": [{"label": "Barisal District", "value": "06"},
                {"label": "Chittagong Division", "value": "B"},
                {"label": "Dhaka District", "value": "13"},
                {"label": "Khulna District", "value": "27"},
                {"label": "Mymensingh District", "value": "34"},
                {"label": "Rajshahi District", "value": "54"},
                {"label": "Rangpur District", "value": "55"},
                {"label": "Sylhet District", "value": "60"}],
 "Barbados": [{"label": "Christ Church", "value": "01"},
              {"label": "Saint Andrew", "value": "02"},
              {"label": "Saint James", "value": "04"},
              {"label": "Saint Joseph", "value": "06"},
              {"label": "Saint Michael", "value": "08"},
              {"label": "Saint Peter", "value": "09"},
              {"label": "Saint Philip", "value": "10"}],
 "Belarus": [{"label": "Brest Region", "value": "BR"},
             {"label": "Gomel Region", "value": "HO"},
             {"label": "Grodno Region", "value": "HR"},
             {"label": "Minsk", "value": "HM"},
             {"label": "Minsk Region", "value": "MI"},
             {"label": "Mogilev Region", "value": "MA"},
             {"label": "Vitebsk Region", "value": "VI"}],
 "Belgium": [{"label": "Brussels-Capital Region", "value": "BRU"},
             {"label": "Flanders", "value": "VLG"},
             {"label": "Wallonia", "value": "WAL"}],
 "Belize": [{"label": "Belize District", "value": "BZ"},
            {"label": "Cayo District", "value": "CY"},
            {"label": "Corozal District", "value": "CZL"},
            {"label": "Orange Walk District", "value": "OW"},
            {"label": "Stann Creek District", "value": "SC"},
            {"label": "Toledo District", "value": "TOL"}],
 "Benin": [{"label": "Alibori Department", "value": "AL"},
           {"label": "Atakora Department", "value": "AK"},
           {"label": "Atlantique Department", "value": "AQ"},
           {"label": "Borgou Department", "value": "BO"},
           {"label": "Collines Department", "value": "CO"},
           {"label": "Donga Department", "value": "DO"},
           {"label": "Kouffo Department", "value": "KO"},
           {"label": "Littoral Department", "value": "LI"},
           {"label": "Mono Department", "value": "MO"},
           {"label": "Oueme Department", "value": "OU"},
           {"label": "Plateau Department", "value": "PL"},
           {"label": "Zou Department", "value": "ZO"}],
 "Bhutan": [{"label": "Bumthang District", "value": "33"},
            {"label": "Chukha District", "value": "12"},
            {"label": "Dagana District", "value": "22"},
            {"label": "Gasa District", "value": "GA"},
            {"label": "Haa District", "value": "13"},
            {"label": "Lhuntse District", "value": "44"},
            {"label": "Mongar District", "value": "42"},
            {"label": "Paro District", "value": "11"},
            {"label": "Pemagatshel District", "value": "43"},
            {"label": "Punakha District", "value": "23"},
            {"label": "Samdrup Jongkhar District", "value": "45"},
            {"label": "Samtse District", "value": "14"},
            {"label": "Sarpang District", "value": "31"},
            {"label": "Thimphu District", "value": "15"},
            {"label": "Trashigang District", "value": "41"},
            {"label": "Tsirang District", "value": "21"},
            {"label": "Wangdue Phodrang District", "value": "24"},
            {"label": "Zhemgang District", "value": "34"}],
 "Bolivia": [{"label": "Beni Department", "value": "B"},
             {"label": "Chuquisaca Department", "value": "H"},
             {"label": "Cochabamba Department", "value": "C"},
             {"label": "La Paz Department", "value": "L"},
             {"label": "Oruro Department", "value": "O"},
             {"label": "Pando Department", "value": "N"},
             {"label": "Potosi Department", "value": "P"},
             {"label": "Santa Cruz Department", "value": "S"},
             {"label": "Tarija Department", "value": "T"}],
 "Bonaire, Sint Eustatius and Saba": [{"label": "Bonaire", "value": "BQ1"}],
 "Bosnia and Herzegovina": [{"label": "Brcko District", "value": "BRC"},
                            {"label": "Federation of Bosnia and Herzegovina",
                             "value": "BIH"},
                            {"label": "Republika Srpska", "value": "SRP"}],
 "Botswana": [{"label": "Central District", "value": "CE"},
              {"label": "Ghanzi District", "value": "GH"},
              {"label": "Kgalagadi District", "value": "KG"},
              {"label": "Kgatleng District", "value": "KL"},
              {"label": "Kweneng District", "value": "KW"},
              {"label": "North-East District", "value": "NE"},
              {"label": "North-West District", "value": "NW"},
              {"label": "South-East District", "value": "SE"},
              {"label": "Southern District", "value": "SO"}],
 "Brazil": [{"label": "Acre", "value": "AC"},
            {"label": "Alagoas", "value": "AL"},
            {"label": "Amapa", "value": "AP"},
            {"label": "Amazonas", "value": "AM"},
            {"label": "Bahia", "value": "BA"},
            {"label": "Ceara", "value": "CE"},
            {"label": "Distrito Federal", "value": "DF"},
            {"label": "Espirito Santo", "value": "ES"},
            {"label": "Goias", "value": "GO"},
            {"label": "Maranhao", "value": "MA"},
            {"label": "Mato Grosso", "value": "MT"},
            {"label": "Mato Grosso do Sul", "value": "MS"},
            {"label": "Minas Gerais", "value": "MG"},
            {"label": "Para", "value": "PA"},
            {"label": "Paraiba", "value": "PB"},
            {"label": "Parana", "value": "PR"},
            {"label": "Pernambuco", "value": "PE"},
            {"label": "Piaui", "value": "PI"},
            {"label": "Rio de Janeiro", "value": "RJ"},
            {"label": "Rio Grande do Norte", "value": "RN"},
            {"label": "Rio Grande do Sul", "value": "RS"},
            {"label": "Rondonia", "value": "RO"},
            {"label": "Roraima", "value": "RR"},
            {"label": "Santa Catarina", "value": "SC"},
            {"label": "Sao Paulo", "value": "SP"},
            {"label": "Sergipe", "value": "SE"},
            {"label": "Tocantins", "value": "TO"}],
 "Brunei": [{"label": "Belait District", "value": "BE"},
            {"label": "Brunei-Muara District", "value": "BM"},
            {"label": "Temburong District", "value": "TE"},
            {"label": "Tutong District", "value": "TU"}],
 "Bulgaria": [{"label": "Blagoevgrad Province", "value": "01"},
              {"label": "Burgas Province", "value": "02"},
              {"label": "Dobrich Province", "value": "08"},
              {"label": "Gabrovo Province", "value": "07"},
              {"label": "Haskovo Province", "value": "26"},
              {"label": "Kardzhali Province", "value": "09"},
              {"label": "Kyustendil Province", "value": "10"},
              {"label": "Lovech Province", "value": "11"},
              {"label": "Montana Province", "value": "12"},
              {"label": "Pazardzhik Province", "value": "13"},
              {"label": "Pernik Province", "value": "14"},
              {"label": "Pleven Province", "value": "15"},
              {"label": "Plovdiv Province", "value": "16"},
              {"label": "Razgrad Province", "value": "17"},
              {"label": "Ruse Province", "value": "18"},
              {"label": "Shumen", "value": "27"},
              {"label": "Silistra Province", "value": "19"},
              {"label": "Sliven Province", "value": "20"},
              {"label": "Smolyan Province", "value": "21"},
              {"label": "Sofia City Province", "value": "22"},
              {"label": "Sofia Province", "value": "23"},
              {"label": "Stara Zagora Province", "value": "24"},
              {"label": "Targovishte Province", "value": "25"},
              {"label": "Varna Province", "value": "03"},
              {"label": "Veliko Tarnovo Province", "value": "04"},
              {"label": "Vidin Province", "value": "05"},
              {"label": "Vratsa Province", "value": "06"},
              {"label": "Yambol Province", "value": "28"}],
 "Burkina Faso": [{"label": "Boucle du Mouhoun Region", "value": "01"},
                  {"label": "Cascades Region", "value": "02"},
                  {"label": "Centre", "value": "03"},
                  {"label": "Centre-Est Region", "value": "04"},
                  {"label": "Centre-Nord Region", "value": "05"},
                  {"label": "Centre-Ouest Region", "value": "06"},
                  {"label": "Centre-Sud Region", "value": "07"},
                  {"label": "Est Region", "value": "08"},
                  {"label": "Hauts-Bassins Region", "value": "09"},
                  {"label": "Nord Region, Burkina Faso", "value": "10"},
                  {"label": "Plateau-Central Region", "value": "11"},
                  {"label": "Sahel Region", "value": "12"},
                  {"label": "Sud-Ouest Region", "value": "13"}],
 "Burundi": [{"label": "Bubanza Province", "value": "BB"},
             {"label": "Bujumbura Mairie Province", "value": "BM"},
             {"label": "Bururi Province", "value": "BR"},
             {"label": "Cankuzo Province", "value": "CA"},
             {"label": "Cibitoke Province", "value": "CI"},
             {"label": "Gitega Province", "value": "GI"},
             {"label": "Karuzi Province", "value": "KR"},
             {"label": "Kayanza Province", "value": "KY"},
             {"label": "Kirundo Province", "value": "KI"},
             {"label": "Makamba Province", "value": "MA"},
             {"label": "Muramvya Province", "value": "MU"},
             {"label": "Muyinga Province", "value": "MY"},
             {"label": "Mwaro Province", "value": "MW"},
             {"label": "Ngozi Province", "value": "NG"},
             {"label": "Rumonge Province", "value": "RM"},
             {"label": "Rutana Province", "value": "RT"},
             {"label": "Ruyigi Province", "value": "RY"}],
 "Cambodia": [{"label": "Banteay Meanchey", "value": "1"},
              {"label": "Battambang", "value": "2"},
              {"label": "Kampong Cham", "value": "3"},
              {"label": "Kampong Chhnang", "value": "4"},
              {"label": "Kampong Speu", "value": "5"},
              {"label": "Kampot", "value": "7"},
              {"label": "Kandal", "value": "8"},
              {"label": "Kep", "value": "23"},
              {"label": "Koh Kong", "value": "9"},
              {"label": "Kratie", "value": "10"},
              {"label": "Mondulkiri", "value": "11"},
              {"label": "Oddar Meanchey", "value": "22"},
              {"label": "Pailin", "value": "24"},
              {"label": "Phnom Penh", "value": "12"},
              {"label": "Preah Vihear", "value": "13"},
              {"label": "Prey Veng", "value": "14"},
              {"label": "Pursat", "value": "15"},
              {"label": "Ratanakiri", "value": "16"},
              {"label": "Siem Reap", "value": "17"},
              {"label": "Sihanoukville", "value": "18"},
              {"label": "Stung Treng", "value": "19"},
              {"label": "Svay Rieng", "value": "20"},
              {"label": "Takeo", "value": "21"}],
 "Cameroon": [{"label": "Adamawa", "value": "AD"},
              {"label": "Centre", "value": "CE"},
              {"label": "East", "value": "ES"},
              {"label": "Far North", "value": "EN"},
              {"label": "Littoral", "value": "LT"},
              {"label": "North", "value": "NO"},
              {"label": "Northwest", "value": "NW"},
              {"label": "South", "value": "SU"},
              {"label": "Southwest", "value": "SW"},
              {"label": "West", "value": "OU"}],
 "Canada": [{"label": "Alberta", "value": "AB"},
            {"label": "British Columbia", "value": "BC"},
            {"label": "Manitoba", "value": "MB"},
            {"label": "New Brunswick", "value": "NB"},
            {"label": "Newfoundland and Labrador", "value": "NL"},
            {"label": "Northwest Territories", "value": "NT"},
            {"label": "Nova Scotia", "value": "NS"},
            {"label": "Nunavut", "value": "NU"},
            {"label": "Ontario", "value": "ON"},
            {"label": "Prince Edward Island", "value": "PE"},
            {"label": "Quebec", "value": "QC"},
            {"label": "Saskatchewan", "value": "SK"},
            {"label": "Yukon", "value": "YT"}],
 "Cape Verde": [{"label": "Boa Vista", "value": "BV"},
                {"label": "Brava", "value": "BR"},
                {"label": "Maio Municipality", "value": "MA"},
                {"label": "Mosteiros", "value": "MO"},
                {"label": "Paul", "value": "PA"},
                {"label": "Porto Novo", "value": "PN"},
                {"label": "Praia", "value": "PR"},
                {"label": "Ribeira Brava Municipality", "value": "RB"},
                {"label": "Ribeira Grande", "value": "RG"},
                {"label": "Ribeira Grande de Santiago", "value": "RS"},
                {"label": "Sal", "value": "SL"},
                {"label": "Santa Catarina", "value": "CA"},
                {"label": "Santa Catarina do Fogo", "value": "CF"},
                {"label": "Santa Cruz", "value": "CR"},
                {"label": "Sao Domingos", "value": "SD"},
                {"label": "Sao Filipe", "value": "SF"},
                {"label": "Sao Lourenco dos orgaos", "value": "SO"},
                {"label": "Sao Miguel", "value": "SM"},
                {"label": "Sao Vicente", "value": "SV"},
                {"label": "Tarrafal", "value": "TA"},
                {"label": "Tarrafal de Sao Nicolau", "value": "TS"}],
 "Central African Republic": [{"label": "Bamingui-Bangoran Prefecture",
                               "value": "BB"},
                              {"label": "Bangui", "value": "BGF"},
                              {"label": "Basse-Kotto Prefecture",
                               "value": "BK"},
                              {"label": "Haut-Mbomou Prefecture",
                               "value": "HM"},
                              {"label": "Haute-Kotto Prefecture",
                               "value": "HK"},
                              {"label": "Kemo Prefecture", "value": "KG"},
                              {"label": "Lobaye Prefecture", "value": "LB"},
                              {"label": "Mambere-Kadei", "value": "HS"},
                              {"label": "Mbomou Prefecture", "value": "MB"},
                              {"label": "Nana-Grebizi Economic Prefecture",
                               "value": "KB"},
                              {"label": "Nana-Mambere Prefecture",
                               "value": "NM"},
                              {"label": "Ombella-MPoko Prefecture",
                               "value": "MP"},
                              {"label": "Ouaka Prefecture", "value": "UK"},
                              {"label": "Ouham Prefecture", "value": "AC"},
                              {"label": "Ouham-Pende Prefecture",
                               "value": "OP"},
                              {"label": "Sangha-Mbaere", "value": "SE"},
                              {"label": "Vakaga Prefecture", "value": "VK"}],
 "Chad": [{"label": "Bahr el Gazel", "value": "BG"},
          {"label": "Batha Region", "value": "BA"},
          {"label": "Borkou", "value": "BO"},
          {"label": "Ennedi-Ouest", "value": "EO"},
          {"label": "Guera Region", "value": "GR"},
          {"label": "Hadjer-Lamis", "value": "HL"},
          {"label": "Kanem Region", "value": "KA"},
          {"label": "Lac Region", "value": "LC"},
          {"label": "Logone Occidental Region", "value": "LO"},
          {"label": "Logone Oriental Region", "value": "LR"},
          {"label": "Mandoul Region", "value": "MA"},
          {"label": "Mayo-Kebbi Est Region", "value": "ME"},
          {"label": "Mayo-Kebbi Ouest Region", "value": "MO"},
          {"label": "Moyen-Chari Region", "value": "MC"},
          {"label": "Ouaddai Region", "value": "OD"},
          {"label": "Salamat Region", "value": "SA"},
          {"label": "Sila Region", "value": "SI"},
          {"label": "Tandjile Region", "value": "TA"},
          {"label": "Tibesti Region", "value": "TI"},
          {"label": "Wadi Fira Region", "value": "WF"}],
 "Chile": [{"label": "Aisen del General Carlos Ibanez del Campo",
            "value": "AI"},
           {"label": "Antofagasta", "value": "AN"},
           {"label": "Arica y Parinacota", "value": "AP"},
           {"label": "Atacama", "value": "AT"},
           {"label": "Biobio", "value": "BI"},
           {"label": "Coquimbo", "value": "CO"},
           {"label": "La Araucania", "value": "AR"},
           {"label": "Libertador General Bernardo OHiggins", "value": "LI"},
           {"label": "Los Lagos", "value": "LL"},
           {"label": "Los Rios", "value": "LR"},
           {"label": "Magallanes y de la Antartica Chilena", "value": "MA"},
           {"label": "Maule", "value": "ML"},
           {"label": "nuble", "value": "NB"},
           {"label": "Region Metropolitana de Santiago", "value": "RM"},
           {"label": "Tarapaca", "value": "TA"},
           {"label": "Valparaiso", "value": "VS"}],
 "China": [{"label": "Anhui", "value": "AH"},
           {"label": "Beijing", "value": "BJ"},
           {"label": "Chongqing", "value": "CQ"},
           {"label": "Fujian", "value": "FJ"},
           {"label": "Gansu", "value": "GS"},
           {"label": "Guangdong", "value": "GD"},
           {"label": "Guangxi Zhuang", "value": "GX"},
           {"label": "Guizhou", "value": "GZ"},
           {"label": "Hainan", "value": "HI"},
           {"label": "Hebei", "value": "HE"},
           {"label": "Heilongjiang", "value": "HL"},
           {"label": "Henan", "value": "HA"},
           {"label": "Hubei", "value": "HB"},
           {"label": "Hunan", "value": "HN"},
           {"label": "Inner Mongolia", "value": "NM"},
           {"label": "Jiangsu", "value": "JS"},
           {"label": "Jiangxi", "value": "JX"},
           {"label": "Jilin", "value": "JL"},
           {"label": "Liaoning", "value": "LN"},
           {"label": "Ningxia Huizu", "value": "NX"},
           {"label": "Qinghai", "value": "QH"},
           {"label": "Shaanxi", "value": "SN"},
           {"label": "Shandong", "value": "SD"},
           {"label": "Shanghai", "value": "SH"},
           {"label": "Shanxi", "value": "SX"},
           {"label": "Sichuan", "value": "SC"},
           {"label": "Taiwan", "value": "TW"},
           {"label": "Tianjin", "value": "TJ"},
           {"label": "Xinjiang", "value": "XJ"},
           {"label": "Xizang", "value": "XZ"},
           {"label": "Yunnan", "value": "YN"},
           {"label": "Zhejiang", "value": "ZJ"}],
 "Colombia": [{"label": "Amazonas", "value": "AMA"},
              {"label": "Antioquia", "value": "ANT"},
              {"label": "Arauca", "value": "ARA"},
              {"label": "Archipielago de San Andres, Providencia y Santa ",
               "value": "SAP"},
              {"label": "Atlantico", "value": "ATL"},
              {"label": "Bogota D.C.", "value": "DC"},
              {"label": "Bolivar", "value": "BOL"},
              {"label": "Boyaca", "value": "BOY"},
              {"label": "Caldas", "value": "CAL"},
              {"label": "Caqueta", "value": "CAQ"},
              {"label": "Casanare", "value": "CAS"},
              {"label": "Cauca", "value": "CAU"},
              {"label": "Cesar", "value": "CES"},
              {"label": "Choco", "value": "CHO"},
              {"label": "Cordoba", "value": "COR"},
              {"label": "Cundinamarca", "value": "CUN"},
              {"label": "Guainia", "value": "GUA"},
              {"label": "Guaviare", "value": "GUV"},
              {"label": "Huila", "value": "HUI"},
              {"label": "La Guajira", "value": "LAG"},
              {"label": "Magdalena", "value": "MAG"},
              {"label": "Meta", "value": "MET"},
              {"label": "Narino", "value": "NAR"},
              {"label": "Norte de Santander", "value": "NSA"},
              {"label": "Putumayo", "value": "PUT"},
              {"label": "Quindio", "value": "QUI"},
              {"label": "Risaralda", "value": "RIS"},
              {"label": "Santander", "value": "SAN"},
              {"label": "Sucre", "value": "SUC"},
              {"label": "Tolima", "value": "TOL"},
              {"label": "Valle del Cauca", "value": "VAC"},
              {"label": "Vaupes", "value": "VAU"},
              {"label": "Vichada", "value": "VID"}],
 "Comoros": [{"label": "Anjouan", "value": "A"},
             {"label": "Grande Comore", "value": "G"},
             {"label": "Moheli", "value": "M"}],
 "Congo": [{"label": "Bouenza Department", "value": "11"},
           {"label": "Brazzaville", "value": "BZV"},
           {"label": "Cuvette Department", "value": "8"},
           {"label": "Cuvette-Ouest Department", "value": "15"},
           {"label": "Lekoumou Department", "value": "2"},
           {"label": "Likouala Department", "value": "7"},
           {"label": "Niari Department", "value": "9"},
           {"label": "Plateaux Department", "value": "14"},
           {"label": "Pointe-Noire", "value": "16"},
           {"label": "Pool Department", "value": "12"},
           {"label": "Sangha Department", "value": "13"}],
 "Costa Rica": [{"label": "Alajuela Province", "value": "A"},
                {"label": "Guanacaste Province", "value": "G"},
                {"label": "Heredia Province", "value": "H"},
                {"label": "Limon Province", "value": "L"},
                {"label": "Provincia de Cartago", "value": "C"},
                {"label": "Puntarenas Province", "value": "P"},
                {"label": "San Jose Province", "value": "SJ"}],
 "Cote DIvoire (Ivory Coast)": [{"label": "Abidjan", "value": "AB"},
                                {"label": "Bas-Sassandra District",
                                 "value": "BS"},
                                {"label": "Comoe District", "value": "CM"},
                                {"label": "Denguele District", "value": "DN"},
                                {"label": "Dix-Huit Montagnes", "value": "06"},
                                {"label": "Goh-Djiboua District",
                                 "value": "GD"},
                                {"label": "Lacs Region", "value": "07"},
                                {"label": "Lagunes region", "value": "01"},
                                {"label": "Sassandra-Marahoue District",
                                 "value": "SM"},
                                {"label": "Vallee du Bandama Region",
                                 "value": "04"},
                                {"label": "Woroba District", "value": "WR"},
                                {"label": "Zanzan Region", "value": "ZZ"}],
 "Croatia": [{"label": "Bjelovar-Bilogora", "value": "07"},
             {"label": "Brod-Posavina", "value": "12"},
             {"label": "Dubrovnik-Neretva", "value": "19"},
             {"label": "Istria", "value": "18"},
             {"label": "Koprivnica-Krizevci", "value": "06"},
             {"label": "Krapina-Zagorje", "value": "02"},
             {"label": "Lika-Senj", "value": "09"},
             {"label": "Medimurje", "value": "20"},
             {"label": "Osijek-Baranja", "value": "14"},
             {"label": "Pozega-Slavonia", "value": "11"},
             {"label": "Primorje-Gorski Kotar", "value": "08"},
             {"label": "Sibenik-Knin", "value": "15"},
             {"label": "Sisak-Moslavina", "value": "03"},
             {"label": "Split-Dalmatia", "value": "17"},
             {"label": "Varazdin", "value": "05"},
             {"label": "Virovitica-Podravina", "value": "10"},
             {"label": "Vukovar-Syrmia", "value": "16"},
             {"label": "Zadar", "value": "13"},
             {"label": "Zagreb", "value": "01"},
             {"label": "Zagreb", "value": "21"}],
 "Cuba": [{"label": "Artemisa Province", "value": "15"},
          {"label": "Camaguey Province", "value": "09"},
          {"label": "Ciego de avila Province", "value": "08"},
          {"label": "Cienfuegos Province", "value": "06"},
          {"label": "Granma Province", "value": "12"},
          {"label": "Guantanamo Province", "value": "14"},
          {"label": "Havana Province", "value": "03"},
          {"label": "Holguin Province", "value": "11"},
          {"label": "Isla de la Juventud", "value": "99"},
          {"label": "Las Tunas Province", "value": "10"},
          {"label": "Matanzas Province", "value": "04"},
          {"label": "Mayabeque Province", "value": "16"},
          {"label": "Pinar del Rio Province", "value": "01"},
          {"label": "Sancti Spiritus Province", "value": "07"},
          {"label": "Santiago de Cuba Province", "value": "13"},
          {"label": "Villa Clara Province", "value": "05"}],
 "Cyprus": [{"label": "Famagusta District (Magusa)", "value": "04"},
            {"label": "Kyrenia District (Keryneia)", "value": "06"},
            {"label": "Larnaca District (Larnaka)", "value": "03"},
            {"label": "Limassol District (Leymasun)", "value": "02"},
            {"label": "Limassol District (Leymasun)", "value": "03"},
            {"label": "Nicosia District (Lefkosa)", "value": "01"},
            {"label": "Paphos District (Pafos)", "value": "05"}],
 "Czech Republic": [{"label": "Jihocesky kraj", "value": "31"},
                    {"label": "Jihomoravsky kraj", "value": "64"},
                    {"label": "Karlovarsky kraj", "value": "41"},
                    {"label": "Kraj Vysocina", "value": "63"},
                    {"label": "Kralovehradecky kraj", "value": "52"},
                    {"label": "Liberecky kraj", "value": "51"},
                    {"label": "Moravskoslezsky kraj", "value": "80"},
                    {"label": "Olomoucky kraj", "value": "71"},
                    {"label": "Pardubicky kraj", "value": "53"},
                    {"label": "Plzen-jih", "value": "324"},
                    {"label": "Praha, Hlavni mesto", "value": "10"},
                    {"label": "Stredocesky kraj", "value": "20"},
                    {"label": "Ustecky kraj", "value": "42"},
                    {"label": "Zlin", "value": "724"}],
 "Democratic Republic of the Congo": [{"label": "Bas-Uele", "value": "BU"},
                                      {"label": "equateur", "value": "EQ"},
                                      {"label": "Haut-Katanga", "value": "HK"},
                                      {"label": "Haut-Lomami", "value": "HL"},
                                      {"label": "Haut-Uele", "value": "HU"},
                                      {"label": "Ituri", "value": "IT"},
                                      {"label": "Kasai", "value": "KS"},
                                      {"label": "Kasai Oriental",
                                       "value": "KE"},
                                      {"label": "Kinshasa", "value": "KN"},
                                      {"label": "Kongo Central", "value": "BC"},
                                      {"label": "Kwango", "value": "KG"},
                                      {"label": "Kwilu", "value": "KL"},
                                      {"label": "Lomami", "value": "LO"},
                                      {"label": "Mai-Ndombe", "value": "MN"},
                                      {"label": "Maniema", "value": "MA"},
                                      {"label": "Mongala", "value": "MO"},
                                      {"label": "Nord-Kivu", "value": "NK"},
                                      {"label": "Nord-Ubangi", "value": "NU"},
                                      {"label": "Sankuru", "value": "SA"},
                                      {"label": "Sud-Kivu", "value": "SK"},
                                      {"label": "Sud-Ubangi", "value": "SU"},
                                      {"label": "Tanganyika", "value": "TA"},
                                      {"label": "Tshopo", "value": "TO"},
                                      {"label": "Tshuapa", "value": "TU"}],
 "Denmark": [{"label": "Capital Region of Denmark", "value": "84"},
             {"label": "Central Denmark Region", "value": "82"},
             {"label": "North Denmark Region", "value": "81"},
             {"label": "Region of Southern Denmark", "value": "83"},
             {"label": "Region Zealand", "value": "85"}],
 "Djibouti": [{"label": "Ali Sabieh Region", "value": "AS"},
              {"label": "Arta Region", "value": "AR"},
              {"label": "Dikhil Region", "value": "DI"},
              {"label": "Djibouti", "value": "DJ"},
              {"label": "Obock Region", "value": "OB"},
              {"label": "Tadjourah Region", "value": "TA"}],
 "Dominica": [{"label": "Saint Andrew Parish", "value": "02"},
              {"label": "Saint David Parish", "value": "03"},
              {"label": "Saint George Parish", "value": "04"},
              {"label": "Saint John Parish", "value": "05"},
              {"label": "Saint Joseph Parish", "value": "06"},
              {"label": "Saint Luke Parish", "value": "07"},
              {"label": "Saint Mark Parish", "value": "08"},
              {"label": "Saint Patrick Parish", "value": "09"},
              {"label": "Saint Paul Parish", "value": "10"},
              {"label": "Saint Peter Parish", "value": "11"}],
 "Dominican Republic": [{"label": "Azua Province", "value": "02"},
                        {"label": "Baoruco Province", "value": "03"},
                        {"label": "Barahona Province", "value": "04"},
                        {"label": "Dajabon Province", "value": "05"},
                        {"label": "Distrito Nacional", "value": "01"},
                        {"label": "Duarte Province", "value": "06"},
                        {"label": "El Seibo Province", "value": "08"},
                        {"label": "Espaillat Province", "value": "09"},
                        {"label": "Hato Mayor Province", "value": "30"},
                        {"label": "Hermanas Mirabal Province", "value": "19"},
                        {"label": "Independencia", "value": "10"},
                        {"label": "La Altagracia Province", "value": "11"},
                        {"label": "La Romana Province", "value": "12"},
                        {"label": "La Vega Province", "value": "13"},
                        {"label": "Maria Trinidad Sanchez Province",
                         "value": "14"},
                        {"label": "Monsenor Nouel Province", "value": "28"},
                        {"label": "Monte Cristi Province", "value": "15"},
                        {"label": "Monte Plata Province", "value": "29"},
                        {"label": "Pedernales Province", "value": "16"},
                        {"label": "Peravia Province", "value": "17"},
                        {"label": "Puerto Plata Province", "value": "18"},
                        {"label": "Samana Province", "value": "20"},
                        {"label": "San Cristobal Province", "value": "21"},
                        {"label": "San Jose de Ocoa Province", "value": "31"},
                        {"label": "San Juan Province", "value": "22"},
                        {"label": "San Pedro de Macoris", "value": "23"},
                        {"label": "Sanchez Ramirez Province", "value": "24"},
                        {"label": "Santiago Province", "value": "25"},
                        {"label": "Santiago Rodriguez Province", "value": "26"},
                        {"label": "Santo Domingo Province", "value": "32"},
                        {"label": "Valverde Province", "value": "27"}],
 "East Timor": [{"label": "Aileu municipality", "value": "AL"},
                {"label": "Ainaro Municipality", "value": "AN"},
                {"label": "Baucau Municipality", "value": "BA"},
                {"label": "Bobonaro Municipality", "value": "BO"},
                {"label": "Cova Lima Municipality", "value": "CO"},
                {"label": "Dili municipality", "value": "DI"},
                {"label": "Ermera District", "value": "ER"},
                {"label": "Lautem Municipality", "value": "LA"},
                {"label": "Liquica Municipality", "value": "LI"},
                {"label": "Manatuto District", "value": "MT"},
                {"label": "Manufahi Municipality", "value": "MF"},
                {"label": "Viqueque Municipality", "value": "VI"}],
 "Ecuador": [{"label": "Azuay", "value": "A"},
             {"label": "Bolivar", "value": "B"},
             {"label": "Canar", "value": "F"},
             {"label": "Carchi", "value": "C"},
             {"label": "Chimborazo", "value": "H"},
             {"label": "Cotopaxi", "value": "X"},
             {"label": "El Oro", "value": "O"},
             {"label": "Esmeraldas", "value": "E"},
             {"label": "Galapagos", "value": "W"},
             {"label": "Guayas", "value": "G"},
             {"label": "Imbabura", "value": "I"},
             {"label": "Loja", "value": "L"},
             {"label": "Los Rios", "value": "R"},
             {"label": "Manabi", "value": "M"},
             {"label": "Morona-Santiago", "value": "S"},
             {"label": "Napo", "value": "N"},
             {"label": "Orellana", "value": "D"},
             {"label": "Pastaza", "value": "Y"},
             {"label": "Pichincha", "value": "P"},
             {"label": "Santa Elena", "value": "SE"},
             {"label": "Santo Domingo de los Tsachilas", "value": "SD"},
             {"label": "Sucumbios", "value": "U"},
             {"label": "Tungurahua", "value": "T"},
             {"label": "Zamora Chinchipe", "value": "Z"}],
 "Egypt": [{"label": "Alexandria", "value": "ALX"},
           {"label": "Aswan", "value": "ASN"},
           {"label": "Asyut", "value": "AST"},
           {"label": "Beheira", "value": "BH"},
           {"label": "Beni Suef", "value": "BNS"},
           {"label": "Cairo", "value": "C"},
           {"label": "Dakahlia", "value": "DK"},
           {"label": "Damietta", "value": "DT"},
           {"label": "Faiyum", "value": "FYM"},
           {"label": "Gharbia", "value": "GH"},
           {"label": "Giza", "value": "GZ"},
           {"label": "Ismailia", "value": "IS"},
           {"label": "Kafr el-Sheikh", "value": "KFS"},
           {"label": "Luxor", "value": "LX"},
           {"label": "Matrouh", "value": "MT"},
           {"label": "Minya", "value": "MN"},
           {"label": "Monufia", "value": "MNF"},
           {"label": "New Valley", "value": "WAD"},
           {"label": "North Sinai", "value": "SIN"},
           {"label": "Port Said", "value": "PTS"},
           {"label": "Qalyubia", "value": "KB"},
           {"label": "Qena", "value": "KN"},
           {"label": "Red Sea", "value": "BA"},
           {"label": "Sharqia", "value": "SHR"},
           {"label": "Sohag", "value": "SHG"},
           {"label": "South Sinai", "value": "JS"},
           {"label": "Suez", "value": "SUZ"}],
 "El Salvador": [{"label": "Ahuachapan Department", "value": "AH"},
                 {"label": "Cabanas Department", "value": "CA"},
                 {"label": "Chalatenango Department", "value": "CH"},
                 {"label": "Cuscatlan Department", "value": "CU"},
                 {"label": "La Libertad Department", "value": "LI"},
                 {"label": "La Paz Department", "value": "PA"},
                 {"label": "La Union Department", "value": "UN"},
                 {"label": "Morazan Department", "value": "MO"},
                 {"label": "San Miguel Department", "value": "SM"},
                 {"label": "San Salvador Department", "value": "SS"},
                 {"label": "San Vicente Department", "value": "SV"},
                 {"label": "Santa Ana Department", "value": "SA"},
                 {"label": "Sonsonate Department", "value": "SO"},
                 {"label": "Usulutan Department", "value": "US"}],
 "Equatorial Guinea": [{"label": "Annobon Province", "value": "AN"},
                       {"label": "Bioko Norte Province", "value": "BN"},
                       {"label": "Bioko Sur Province", "value": "BS"},
                       {"label": "Centro Sur Province", "value": "CS"},
                       {"label": "Kie-Ntem Province", "value": "KN"},
                       {"label": "Litoral Province", "value": "LI"},
                       {"label": "Wele-Nzas Province", "value": "WN"}],
 "Eritrea": [{"label": "Anseba Region", "value": "AN"},
             {"label": "Debub Region", "value": "DU"},
             {"label": "Gash-Barka Region", "value": "GB"},
             {"label": "Maekel Region", "value": "MA"},
             {"label": "Northern Red Sea Region", "value": "SK"},
             {"label": "Southern Red Sea Region", "value": "DK"}],
 "Estonia": [{"label": "Harju County", "value": "37"},
             {"label": "Hiiu County", "value": "39"},
             {"label": "Ida-Viru County", "value": "44"},
             {"label": "Jarva County", "value": "51"},
             {"label": "Jogeva County", "value": "49"},
             {"label": "Laane County", "value": "57"},
             {"label": "Laane-Viru County", "value": "59"},
             {"label": "Parnu County", "value": "67"},
             {"label": "Polva County", "value": "65"},
             {"label": "Rapla County", "value": "70"},
             {"label": "Saare County", "value": "74"},
             {"label": "Tartu County", "value": "78"},
             {"label": "Valga County", "value": "82"},
             {"label": "Viljandi County", "value": "84"},
             {"label": "Voru County", "value": "86"}],
 "Ethiopia": [{"label": "Addis Ababa", "value": "AA"},
              {"label": "Afar Region", "value": "AF"},
              {"label": "Amhara Region", "value": "AM"},
              {"label": "Benishangul-Gumuz Region", "value": "BE"},
              {"label": "Dire Dawa", "value": "DD"},
              {"label": "Gambela Region", "value": "GA"},
              {"label": "Harari Region", "value": "HA"},
              {"label": "Oromia Region", "value": "OR"},
              {"label": "Somali Region", "value": "SO"},
              {"label": "Southern Nations, Nationalities, and Peoples Region",
               "value": "SN"},
              {"label": "Tigray Region", "value": "TI"}],
 "Fiji Islands": [{"label": "Central Division", "value": "C"},
                  {"label": "Eastern Division", "value": "E"},
                  {"label": "Northern Division", "value": "N"},
                  {"label": "Western Division", "value": "W"}],
 "Finland": [{"label": "Central Finland", "value": "08"},
             {"label": "Central Ostrobothnia", "value": "07"},
             {"label": "Finland Proper", "value": "19"},
             {"label": "Kainuu", "value": "05"},
             {"label": "Kymenlaakso", "value": "09"},
             {"label": "Lapland", "value": "LL"},
             {"label": "North Karelia", "value": "13"},
             {"label": "Northern Ostrobothnia", "value": "14"},
             {"label": "Ostrobothnia", "value": "12"},
             {"label": "Paijanne Tavastia", "value": "16"},
             {"label": "Pirkanmaa", "value": "11"},
             {"label": "Satakunta", "value": "17"},
             {"label": "South Karelia", "value": "02"},
             {"label": "Southern Ostrobothnia", "value": "03"},
             {"label": "Southern Savonia", "value": "04"},
             {"label": "Tavastia Proper", "value": "06"},
             {"label": "Uusimaa", "value": "18"}],
 "France": [{"label": "Auvergne-Rhone-Alpes", "value": "ARA"},
            {"label": "Bourgogne-Franche-Comte", "value": "BFC"},
            {"label": "Bretagne", "value": "BRE"},
            {"label": "Centre-Val de Loire", "value": "CVL"},
            {"label": "Corse", "value": "COR"},
            {"label": "Grand-Est", "value": "GES"},
            {"label": "Hauts-de-France", "value": "HDF"},
            {"label": "Ile-de-France", "value": "IDF"},
            {"label": "Normandie", "value": "NOR"},
            {"label": "Nouvelle-Aquitaine", "value": "NAQ"},
            {"label": "Occitanie", "value": "OCC"},
            {"label": "Pays-de-la-Loire", "value": "PDL"},
            {"label": "Provence-Alpes-Cote-dAzur", "value": "PAC"}],
 "Gabon": [{"label": "Estuaire Province", "value": "1"},
           {"label": "Haut-Ogooue Province", "value": "2"},
           {"label": "Moyen-Ogooue Province", "value": "3"},
           {"label": "Ngounie Province", "value": "4"},
           {"label": "Nyanga Province", "value": "5"},
           {"label": "Ogooue-Ivindo Province", "value": "6"},
           {"label": "Ogooue-Lolo Province", "value": "7"},
           {"label": "Ogooue-Maritime Province", "value": "8"},
           {"label": "Woleu-Ntem Province", "value": "9"}],
 "Gambia The": [{"label": "Banjul", "value": "B"},
                {"label": "Central River Division", "value": "M"},
                {"label": "Lower River Division", "value": "L"},
                {"label": "North Bank Division", "value": "N"},
                {"label": "Upper River Division", "value": "U"},
                {"label": "West Coast Division", "value": "W"}],
 "Georgia": [{"label": "Adjara", "value": "AJ"},
             {"label": "Autonomous Republic of Abkhazia", "value": "AB"},
             {"label": "Guria", "value": "GU"},
             {"label": "Imereti", "value": "IM"},
             {"label": "Kakheti", "value": "KA"},
             {"label": "Kvemo Kartli", "value": "KK"},
             {"label": "Mtskheta-Mtianeti", "value": "MM"},
             {"label": "Racha-Lechkhumi and Kvemo Svaneti", "value": "RL"},
             {"label": "Samegrelo-Zemo Svaneti", "value": "SZ"},
             {"label": "Samtskhe-Javakheti", "value": "SJ"},
             {"label": "Shida Kartli", "value": "SK"},
             {"label": "Tbilisi", "value": "TB"}],
 "Germany": [{"label": "Baden-Wurttemberg", "value": "BW"},
             {"label": "Bavaria", "value": "BY"},
             {"label": "Berlin", "value": "BE"},
             {"label": "Brandenburg", "value": "BB"},
             {"label": "Bremen", "value": "HB"},
             {"label": "Hamburg", "value": "HH"},
             {"label": "Hesse", "value": "HE"},
             {"label": "Lower Saxony", "value": "NI"},
             {"label": "Mecklenburg-Vorpommern", "value": "MV"},
             {"label": "North Rhine-Westphalia", "value": "NW"},
             {"label": "Rhineland-Palatinate", "value": "RP"},
             {"label": "Saarland", "value": "SL"},
             {"label": "Saxony", "value": "SN"},
             {"label": "Saxony-Anhalt", "value": "ST"},
             {"label": "Schleswig-Holstein", "value": "SH"},
             {"label": "Thuringia", "value": "TH"}],
 "Ghana": [{"label": "Ahafo", "value": "AF"},
           {"label": "Ashanti", "value": "AH"},
           {"label": "Bono", "value": "BO"},
           {"label": "Bono East", "value": "BE"},
           {"label": "Central", "value": "CP"},
           {"label": "Eastern", "value": "EP"},
           {"label": "Greater Accra", "value": "AA"},
           {"label": "North East", "value": "NE"},
           {"label": "Northern", "value": "NP"},
           {"label": "Oti", "value": "OT"},
           {"label": "Savannah", "value": "SV"},
           {"label": "Upper East", "value": "UE"},
           {"label": "Upper West", "value": "UW"},
           {"label": "Volta", "value": "TV"},
           {"label": "Western", "value": "WP"},
           {"label": "Western North", "value": "WN"}],
 "Greece": [{"label": "Attica Region", "value": "I"},
            {"label": "Central Greece Region", "value": "H"},
            {"label": "Central Macedonia", "value": "B"},
            {"label": "Corfu Prefecture", "value": "22"},
            {"label": "Crete Region", "value": "M"},
            {"label": "East Attica Regional Unit", "value": "A2"},
            {"label": "Epirus Region", "value": "D"},
            {"label": "Ionian Islands Region", "value": "F"},
            {"label": "Kefalonia Prefecture", "value": "23"},
            {"label": "Lefkada Regional Unit", "value": "24"},
            {"label": "Peloponnese Region", "value": "J"},
            {"label": "South Aegean", "value": "L"},
            {"label": "West Greece Region", "value": "G"},
            {"label": "West Macedonia Region", "value": "C"}],
 "Grenada": [{"label": "Carriacou and Petite Martinique", "value": "10"},
             {"label": "Saint Andrew Parish", "value": "01"},
             {"label": "Saint David Parish", "value": "02"},
             {"label": "Saint George Parish", "value": "03"},
             {"label": "Saint John Parish", "value": "04"},
             {"label": "Saint Mark Parish", "value": "05"},
             {"label": "Saint Patrick Parish", "value": "06"}],
 "Guatemala": [{"label": "Alta Verapaz Department", "value": "AV"},
               {"label": "Baja Verapaz Department", "value": "BV"},
               {"label": "Chimaltenango Department", "value": "CM"},
               {"label": "Chiquimula Department", "value": "CQ"},
               {"label": "El Progreso Department", "value": "PR"},
               {"label": "Escuintla Department", "value": "ES"},
               {"label": "Guatemala Department", "value": "GU"},
               {"label": "Huehuetenango Department", "value": "HU"},
               {"label": "Izabal Department", "value": "IZ"},
               {"label": "Jalapa Department", "value": "JA"},
               {"label": "Jutiapa Department", "value": "JU"},
               {"label": "Peten Department", "value": "PE"},
               {"label": "Quetzaltenango Department", "value": "QZ"},
               {"label": "Quiche Department", "value": "QC"},
               {"label": "Retalhuleu Department", "value": "RE"},
               {"label": "Sacatepequez Department", "value": "SA"},
               {"label": "San Marcos Department", "value": "SM"},
               {"label": "Santa Rosa Department", "value": "SR"},
               {"label": "Solola Department", "value": "SO"},
               {"label": "Suchitepequez Department", "value": "SU"},
               {"label": "Totonicapan Department", "value": "TO"}],
 "Guinea": [{"label": "Boke Region", "value": "B"},
            {"label": "Conakry", "value": "C"},
            {"label": "Kankan Region", "value": "K"},
            {"label": "Kindia Region", "value": "D"},
            {"label": "Labe Region", "value": "L"},
            {"label": "Mamou Region", "value": "M"},
            {"label": "Nzerekore Region", "value": "N"}],
 "Guinea-Bissau": [{"label": "Bafata", "value": "BA"},
                   {"label": "Biombo Region", "value": "BM"},
                   {"label": "Bolama Region", "value": "BL"},
                   {"label": "Cacheu Region", "value": "CA"},
                   {"label": "Gabu Region", "value": "GA"},
                   {"label": "Oio Region", "value": "OI"},
                   {"label": "Quinara Region", "value": "QU"},
                   {"label": "Tombali Region", "value": "TO"}],
 "Guyana": [{"label": "Barima-Waini", "value": "BA"},
            {"label": "Cuyuni-Mazaruni", "value": "CU"},
            {"label": "Demerara-Mahaica", "value": "DE"},
            {"label": "East Berbice-Corentyne", "value": "EB"},
            {"label": "Essequibo Islands-West Demerara", "value": "ES"},
            {"label": "Mahaica-Berbice", "value": "MA"},
            {"label": "Pomeroon-Supenaam", "value": "PM"},
            {"label": "Potaro-Siparuni", "value": "PT"},
            {"label": "Upper Demerara-Berbice", "value": "UD"},
            {"label": "Upper Takutu-Upper Essequibo", "value": "UT"}],
 "Haiti": [{"label": "Artibonite", "value": "AR"},
           {"label": "Centre", "value": "CE"},
           {"label": "GrandAnse", "value": "GA"},
           {"label": "Nippes", "value": "NI"},
           {"label": "Nord", "value": "ND"},
           {"label": "Nord-Est", "value": "NE"},
           {"label": "Nord-Ouest", "value": "NO"},
           {"label": "Ouest", "value": "OU"},
           {"label": "Sud", "value": "SD"},
           {"label": "Sud-Est", "value": "SE"}],
 "Honduras": [{"label": "Atlantida Department", "value": "AT"},
              {"label": "Bay Islands Department", "value": "IB"},
              {"label": "Choluteca Department", "value": "CH"},
              {"label": "Colon Department", "value": "CL"},
              {"label": "Comayagua Department", "value": "CM"},
              {"label": "Copan Department", "value": "CP"},
              {"label": "Cortes Department", "value": "CR"},
              {"label": "El Paraiso Department", "value": "EP"},
              {"label": "Francisco Morazan Department", "value": "FM"},
              {"label": "Gracias a Dios Department", "value": "GD"},
              {"label": "Intibuca Department", "value": "IN"},
              {"label": "La Paz Department", "value": "LP"},
              {"label": "Lempira Department", "value": "LE"},
              {"label": "Ocotepeque Department", "value": "OC"},
              {"label": "Olancho Department", "value": "OL"},
              {"label": "Santa Barbara Department", "value": "SB"},
              {"label": "Valle Department", "value": "VA"},
              {"label": "Yoro Department", "value": "YO"}],
 "Hungary": [{"label": "Bacs-Kiskun", "value": "BK"},
             {"label": "Baranya", "value": "BA"},
             {"label": "Bekes", "value": "BE"},
             {"label": "Borsod-Abauj-Zemplen", "value": "BZ"},
             {"label": "Budapest", "value": "BU"},
             {"label": "Csongrad County", "value": "CS"},
             {"label": "Fejer County", "value": "FE"},
             {"label": "Gyor-Moson-Sopron County", "value": "GS"},
             {"label": "Hajdu-Bihar County", "value": "HB"},
             {"label": "Heves County", "value": "HE"},
             {"label": "Jasz-Nagykun-Szolnok County", "value": "JN"},
             {"label": "Komarom-Esztergom", "value": "KE"},
             {"label": "Nograd County", "value": "NO"},
             {"label": "Pest County", "value": "PE"},
             {"label": "Somogy County", "value": "SO"},
             {"label": "Szabolcs-Szatmar-Bereg County", "value": "SZ"},
             {"label": "Tolna County", "value": "TO"},
             {"label": "Vas County", "value": "VA"},
             {"label": "Veszprem County", "value": "VE"},
             {"label": "Zala County", "value": "ZA"}],
 "Iceland": [{"label": "Capital Region", "value": "1"},
             {"label": "Eastern Region", "value": "7"},
             {"label": "Northeastern Region", "value": "6"},
             {"label": "Northwestern Region", "value": "5"},
             {"label": "Southern Peninsula Region", "value": "2"},
             {"label": "Southern Region", "value": "8"},
             {"label": "Western Region", "value": "3"},
             {"label": "Westfjords", "value": "4"}],
 "India": [{"label": "Andaman and Nicobar Islands", "value": "AN"},
           {"label": "Andhra Pradesh", "value": "AP"},
           {"label": "Arunachal Pradesh", "value": "AR"},
           {"label": "Assam", "value": "AS"},
           {"label": "Bihar", "value": "BR"},
           {"label": "Chandigarh", "value": "CH"},
           {"label": "Chhattisgarh", "value": "CT"},
           {"label": "Dadra and Nagar Haveli and Daman and Diu", "value": "DH"},
           {"label": "Delhi", "value": "DL"},
           {"label": "Goa", "value": "GA"},
           {"label": "Gujarat", "value": "GJ"},
           {"label": "Haryana", "value": "HR"},
           {"label": "Himachal Pradesh", "value": "HP"},
           {"label": "Jammu and Kashmir", "value": "JK"},
           {"label": "Jharkhand", "value": "JH"},
           {"label": "Karnataka", "value": "KA"},
           {"label": "Kerala", "value": "KL"},
           {"label": "Ladakh", "value": "LA"},
           {"label": "Lakshadweep", "value": "LD"},
           {"label": "Madhya Pradesh", "value": "MP"},
           {"label": "Maharashtra", "value": "MH"},
           {"label": "Manipur", "value": "MN"},
           {"label": "Meghalaya", "value": "ML"},
           {"label": "Mizoram", "value": "MZ"},
           {"label": "Nagaland", "value": "NL"},
           {"label": "Odisha", "value": "OR"},
           {"label": "Puducherry", "value": "PY"},
           {"label": "Punjab", "value": "PB"},
           {"label": "Rajasthan", "value": "RJ"},
           {"label": "Sikkim", "value": "SK"},
           {"label": "Tamil Nadu", "value": "TN"},
           {"label": "Telangana", "value": "TG"},
           {"label": "Tripura", "value": "TR"},
           {"label": "Uttar Pradesh", "value": "UP"},
           {"label": "Uttarakhand", "value": "UT"},
           {"label": "West Bengal", "value": "WB"}],
 "Indonesia": [{"label": "Aceh", "value": "AC"},
               {"label": "Bali", "value": "BA"},
               {"label": "Banten", "value": "BT"},
               {"label": "Bengkulu", "value": "BE"},
               {"label": "DI Yogyakarta", "value": "YO"},
               {"label": "DKI Jakarta", "value": "JK"},
               {"label": "Gorontalo", "value": "GO"},
               {"label": "Jambi", "value": "JA"},
               {"label": "Jawa Barat", "value": "JB"},
               {"label": "Jawa Tengah", "value": "JT"},
               {"label": "Jawa Timur", "value": "JI"},
               {"label": "Kalimantan Barat", "value": "KB"},
               {"label": "Kalimantan Selatan", "value": "KS"},
               {"label": "Kalimantan Tengah", "value": "KT"},
               {"label": "Kalimantan Timur", "value": "KI"},
               {"label": "Kalimantan Utara", "value": "KU"},
               {"label": "Kepulauan Bangka Belitung", "value": "BB"},
               {"label": "Kepulauan Riau", "value": "KR"},
               {"label": "Lampung", "value": "LA"},
               {"label": "Maluku", "value": "MA"},
               {"label": "Maluku Utara", "value": "MU"},
               {"label": "Nusa Tenggara Barat", "value": "NB"},
               {"label": "Nusa Tenggara Timur", "value": "NT"},
               {"label": "Papua", "value": "PA"},
               {"label": "Papua Barat", "value": "PB"},
               {"label": "Riau", "value": "RI"},
               {"label": "Sulawesi Barat", "value": "SR"},
               {"label": "Sulawesi Selatan", "value": "SN"},
               {"label": "Sulawesi Tengah", "value": "ST"},
               {"label": "Sulawesi Tenggara", "value": "SG"},
               {"label": "Sulawesi Utara", "value": "SA"},
               {"label": "Sumatera Barat", "value": "SB"},
               {"label": "Sumatera Selatan", "value": "SS"},
               {"label": "Sumatera Utara", "value": "SU"}],
 "Iran": [{"label": "Alborz", "value": "30"},
          {"label": "Ardabil", "value": "24"},
          {"label": "Bushehr", "value": "18"},
          {"label": "Chaharmahal and Bakhtiari", "value": "14"},
          {"label": "East Azerbaijan", "value": "03"},
          {"label": "Fars", "value": "07"},
          {"label": "Gilan", "value": "01"},
          {"label": "Golestan", "value": "27"},
          {"label": "Hamadan", "value": "13"},
          {"label": "Hormozgan", "value": "22"},
          {"label": "Ilam", "value": "16"},
          {"label": "Isfahan", "value": "10"},
          {"label": "Kerman", "value": "08"},
          {"label": "Kermanshah", "value": "05"},
          {"label": "Khuzestan", "value": "06"},
          {"label": "Kohgiluyeh and Boyer-Ahmad", "value": "17"},
          {"label": "Kurdistan", "value": "12"},
          {"label": "Lorestan", "value": "15"},
          {"label": "Markazi", "value": "00"},
          {"label": "Mazandaran", "value": "02"},
          {"label": "North Khorasan", "value": "28"},
          {"label": "Qazvin", "value": "26"},
          {"label": "Qom", "value": "25"},
          {"label": "Razavi Khorasan", "value": "09"},
          {"label": "Semnan", "value": "20"},
          {"label": "Sistan and Baluchestan", "value": "11"},
          {"label": "South Khorasan", "value": "29"},
          {"label": "Tehran", "value": "23"},
          {"label": "West Azarbaijan", "value": "04"},
          {"label": "Yazd", "value": "21"},
          {"label": "Zanjan", "value": "19"}],
 "Iraq": [{"label": "Al Anbar Governorate", "value": "AN"},
          {"label": "Al Muthanna Governorate", "value": "MU"},
          {"label": "Al-Qadisiyyah Governorate", "value": "QA"},
          {"label": "Babylon Governorate", "value": "BB"},
          {"label": "Baghdad Governorate", "value": "BG"},
          {"label": "Basra Governorate", "value": "BA"},
          {"label": "Dhi Qar Governorate", "value": "DQ"},
          {"label": "Diyala Governorate", "value": "DI"},
          {"label": "Dohuk Governorate", "value": "DA"},
          {"label": "Erbil Governorate", "value": "AR"},
          {"label": "Karbala Governorate", "value": "KA"},
          {"label": "Kirkuk Governorate", "value": "KI"},
          {"label": "Maysan Governorate", "value": "MA"},
          {"label": "Najaf Governorate", "value": "NA"},
          {"label": "Nineveh Governorate", "value": "NI"},
          {"label": "Saladin Governorate", "value": "SD"},
          {"label": "Sulaymaniyah Governorate", "value": "SU"},
          {"label": "Wasit Governorate", "value": "WA"}],
 "Ireland": [{"label": "Connacht", "value": "C"},
             {"label": "Leinster", "value": "L"},
             {"label": "Munster", "value": "M"},
             {"label": "Ulster", "value": "U"}],
 "Israel": [{"label": "Central District", "value": "M"},
            {"label": "Haifa District", "value": "HA"},
            {"label": "Jerusalem District", "value": "JM"},
            {"label": "Northern District", "value": "Z"},
            {"label": "Southern District", "value": "D"},
            {"label": "Tel Aviv District", "value": "TA"}],
 "Italy": [{"label": "Abruzzo", "value": "65"},
           {"label": "Aosta Valley", "value": "23"},
           {"label": "Apulia", "value": "75"},
           {"label": "Basilicata", "value": "77"},
           {"label": "Calabria", "value": "78"},
           {"label": "Campania", "value": "72"},
           {"label": "Emilia-Romagna", "value": "45"},
           {"label": "Friuli-Venezia Giulia", "value": "36"},
           {"label": "Lazio", "value": "62"},
           {"label": "Liguria", "value": "42"},
           {"label": "Lombardy", "value": "25"},
           {"label": "Marche", "value": "57"},
           {"label": "Molise", "value": "67"},
           {"label": "Piedmont", "value": "21"},
           {"label": "Ravenna", "value": "45"},
           {"label": "Sardinia", "value": "88"},
           {"label": "Sicily", "value": "82"},
           {"label": "Trentino-South Tyrol", "value": "32"},
           {"label": "Tuscany", "value": "52"},
           {"label": "Umbria", "value": "55"},
           {"label": "Veneto", "value": "34"}],
 "Jamaica": [{"label": "Clarendon Parish", "value": "13"},
             {"label": "Hanover Parish", "value": "09"},
             {"label": "Kingston Parish", "value": "01"},
             {"label": "Manchester Parish", "value": "12"},
             {"label": "Portland Parish", "value": "04"},
             {"label": "Saint Andrew", "value": "02"},
             {"label": "Saint Ann Parish", "value": "06"},
             {"label": "Saint Catherine Parish", "value": "14"},
             {"label": "Saint Elizabeth Parish", "value": "11"},
             {"label": "Saint James Parish", "value": "08"},
             {"label": "Saint Mary Parish", "value": "05"},
             {"label": "Saint Thomas Parish", "value": "03"},
             {"label": "Trelawny Parish", "value": "07"},
             {"label": "Westmoreland Parish", "value": "10"}],
 "Japan": [{"label": "Aichi Prefecture", "value": "23"},
           {"label": "Akita Prefecture", "value": "05"},
           {"label": "Aomori Prefecture", "value": "02"},
           {"label": "Chiba Prefecture", "value": "12"},
           {"label": "Ehime Prefecture", "value": "38"},
           {"label": "Fukui Prefecture", "value": "18"},
           {"label": "Fukuoka Prefecture", "value": "40"},
           {"label": "Fukushima Prefecture", "value": "07"},
           {"label": "Gifu Prefecture", "value": "21"},
           {"label": "Gunma Prefecture", "value": "10"},
           {"label": "Hiroshima Prefecture", "value": "34"},
           {"label": "Hokkaido Prefecture", "value": "01"},
           {"label": "Hyogo Prefecture", "value": "28"},
           {"label": "Ibaraki Prefecture", "value": "08"},
           {"label": "Ishikawa Prefecture", "value": "17"},
           {"label": "Iwate Prefecture", "value": "03"},
           {"label": "Kagawa Prefecture", "value": "37"},
           {"label": "Kagoshima Prefecture", "value": "46"},
           {"label": "Kanagawa Prefecture", "value": "14"},
           {"label": "Kochi Prefecture", "value": "39"},
           {"label": "Kumamoto Prefecture", "value": "43"},
           {"label": "Kyoto Prefecture", "value": "26"},
           {"label": "Mie Prefecture", "value": "24"},
           {"label": "Miyagi Prefecture", "value": "04"},
           {"label": "Miyazaki Prefecture", "value": "45"},
           {"label": "Nagano Prefecture", "value": "20"},
           {"label": "Nagasaki Prefecture", "value": "42"},
           {"label": "Nara Prefecture", "value": "29"},
           {"label": "Niigata Prefecture", "value": "15"},
           {"label": "Oita Prefecture", "value": "44"},
           {"label": "Okayama Prefecture", "value": "33"},
           {"label": "Okinawa Prefecture", "value": "47"},
           {"label": "Osaka Prefecture", "value": "27"},
           {"label": "Saga Prefecture", "value": "41"},
           {"label": "Saitama Prefecture", "value": "11"},
           {"label": "Shiga Prefecture", "value": "25"},
           {"label": "Shimane Prefecture", "value": "32"},
           {"label": "Shizuoka Prefecture", "value": "22"},
           {"label": "Tochigi Prefecture", "value": "09"},
           {"label": "Tokushima Prefecture", "value": "36"},
           {"label": "Tokyo", "value": "13"},
           {"label": "Tottori Prefecture", "value": "31"},
           {"label": "Toyama Prefecture", "value": "16"},
           {"label": "Wakayama Prefecture", "value": "30"},
           {"label": "Yamagata Prefecture", "value": "06"},
           {"label": "Yamaguchi Prefecture", "value": "35"},
           {"label": "Yamanashi Prefecture", "value": "19"}],
 "Jordan": [{"label": "Ajloun Governorate", "value": "AJ"},
            {"label": "Amman Governorate", "value": "AM"},
            {"label": "Aqaba Governorate", "value": "AQ"},
            {"label": "Balqa Governorate", "value": "BA"},
            {"label": "Irbid Governorate", "value": "IR"},
            {"label": "Jerash Governorate", "value": "JA"},
            {"label": "Karak Governorate", "value": "KA"},
            {"label": "Maan Governorate", "value": "MN"},
            {"label": "Madaba Governorate", "value": "MD"},
            {"label": "Mafraq Governorate", "value": "MA"},
            {"label": "Tafilah Governorate", "value": "AT"},
            {"label": "Zarqa Governorate", "value": "AZ"}],
 "Kazakhstan": [{"label": "Akmola Region", "value": "AKM"},
                {"label": "Aktobe Region", "value": "AKT"},
                {"label": "Almaty", "value": "ALA"},
                {"label": "Almaty Region", "value": "ALM"},
                {"label": "Atyrau Region", "value": "ATY"},
                {"label": "Baikonur", "value": "BAY"},
                {"label": "East Kazakhstan Region", "value": "VOS"},
                {"label": "Jambyl Region", "value": "ZHA"},
                {"label": "Karaganda Region", "value": "KAR"},
                {"label": "Kostanay Region", "value": "KUS"},
                {"label": "Kyzylorda Region", "value": "KZY"},
                {"label": "Mangystau Region", "value": "MAN"},
                {"label": "North Kazakhstan Region", "value": "SEV"},
                {"label": "Nur-Sultan", "value": "AST"},
                {"label": "Pavlodar Region", "value": "PAV"},
                {"label": "Turkestan Region", "value": "YUZ"},
                {"label": "West Kazakhstan Province", "value": "ZAP"}],
 "Kenya": [{"label": "Baringo", "value": "01"},
           {"label": "Bomet", "value": "02"},
           {"label": "Bungoma", "value": "03"},
           {"label": "Busia", "value": "04"},
           {"label": "Embu", "value": "06"},
           {"label": "Garissa", "value": "07"},
           {"label": "Homa Bay", "value": "08"},
           {"label": "Isiolo", "value": "09"},
           {"label": "Kajiado", "value": "10"},
           {"label": "Kakamega", "value": "11"},
           {"label": "Kericho", "value": "12"},
           {"label": "Kiambu", "value": "13"},
           {"label": "Kiambu", "value": "110"},
           {"label": "Kilifi", "value": "14"},
           {"label": "Kirinyaga", "value": "15"},
           {"label": "Kisii", "value": "16"},
           {"label": "Kisumu", "value": "17"},
           {"label": "Kitui", "value": "18"},
           {"label": "Kwale", "value": "19"},
           {"label": "Laikipia", "value": "20"},
           {"label": "Lamu", "value": "21"},
           {"label": "Machakos", "value": "22"},
           {"label": "Makueni", "value": "23"},
           {"label": "Mandera", "value": "24"},
           {"label": "Marsabit", "value": "25"},
           {"label": "Meru", "value": "26"},
           {"label": "Migori", "value": "27"},
           {"label": "Mombasa", "value": "28"},
           {"label": "Muranga", "value": "29"},
           {"label": "Nairobi City", "value": "110"},
           {"label": "Nakuru", "value": "31"},
           {"label": "Nandi", "value": "32"},
           {"label": "Narok", "value": "33"},
           {"label": "Nyamira", "value": "34"},
           {"label": "Nyandarua", "value": "35"},
           {"label": "Nyeri", "value": "36"},
           {"label": "Samburu", "value": "37"},
           {"label": "Siaya", "value": "38"},
           {"label": "Tana River", "value": "40"},
           {"label": "Tharaka-Nithi", "value": "41"},
           {"label": "Trans Nzoia", "value": "42"},
           {"label": "Turkana", "value": "43"},
           {"label": "Uasin Gishu", "value": "44"},
           {"label": "Wajir", "value": "46"},
           {"label": "West Pokot", "value": "47"}],
 "Kiribati": [{"label": "Gilbert Islands", "value": "G"},
              {"label": "Line Islands", "value": "L"},
              {"label": "Phoenix Islands", "value": "P"}],
 "Kuwait": [{"label": "Al Ahmadi Governorate", "value": "AH"},
            {"label": "Al Farwaniyah Governorate", "value": "FA"},
            {"label": "Al Jahra Governorate", "value": "JA"},
            {"label": "Capital Governorate", "value": "KU"},
            {"label": "Hawalli Governorate", "value": "HA"},
            {"label": "Mubarak Al-Kabeer Governorate", "value": "MU"}],
 "Kyrgyzstan": [{"label": "Batken Region", "value": "B"},
                {"label": "Bishkek", "value": "GB"},
                {"label": "Chuy Region", "value": "C"},
                {"label": "Issyk-Kul Region", "value": "Y"},
                {"label": "Jalal-Abad Region", "value": "J"},
                {"label": "Naryn Region", "value": "N"},
                {"label": "Osh Region", "value": "O"},
                {"label": "Talas Region", "value": "T"}],
 "Laos": [{"label": "Attapeu Province", "value": "AT"},
          {"label": "Bokeo Province", "value": "BK"},
          {"label": "Bolikhamsai Province", "value": "BL"},
          {"label": "Champasak Province", "value": "CH"},
          {"label": "Houaphanh Province", "value": "HO"},
          {"label": "Khammouane Province", "value": "KH"},
          {"label": "Luang Namtha Province", "value": "LM"},
          {"label": "Luang Prabang Province", "value": "LP"},
          {"label": "Oudomxay Province", "value": "OU"},
          {"label": "Phongsaly Province", "value": "PH"},
          {"label": "Sainyabuli Province", "value": "XA"},
          {"label": "Salavan Province", "value": "SL"},
          {"label": "Savannakhet Province", "value": "SV"},
          {"label": "Sekong Province", "value": "XE"},
          {"label": "Vientiane Prefecture", "value": "VT"},
          {"label": "Vientiane Province", "value": "VI"},
          {"label": "Xaisomboun Province", "value": "XS"},
          {"label": "Xiangkhouang Province", "value": "XI"}],
 "Latvia": [{"label": "Aglona Municipality", "value": "001"},
            {"label": "Aizkraukle Municipality", "value": "002"},
            {"label": "Aizpute Municipality", "value": "003"},
            {"label": "Akniste Municipality", "value": "004"},
            {"label": "Aloja Municipality", "value": "005"},
            {"label": "Alsunga Municipality", "value": "006"},
            {"label": "Aluksne Municipality", "value": "007"},
            {"label": "Ape Municipality", "value": "009"},
            {"label": "Auce Municipality", "value": "010"},
            {"label": "Babite Municipality", "value": "012"},
            {"label": "Baldone Municipality", "value": "013"},
            {"label": "Baltinava Municipality", "value": "014"},
            {"label": "Balvi Municipality", "value": "015"},
            {"label": "Bauska Municipality", "value": "016"},
            {"label": "Beverina Municipality", "value": "017"},
            {"label": "Broceni Municipality", "value": "018"},
            {"label": "Carnikava Municipality", "value": "020"},
            {"label": "Cesis Municipality", "value": "022"},
            {"label": "Cesvaine Municipality", "value": "021"},
            {"label": "Cibla Municipality", "value": "023"},
            {"label": "Dagda Municipality", "value": "024"},
            {"label": "Daugavpils Municipality", "value": "025"},
            {"label": "Dobele Municipality", "value": "026"},
            {"label": "Dundaga Municipality", "value": "027"},
            {"label": "Durbe Municipality", "value": "028"},
            {"label": "Engure Municipality", "value": "029"},
            {"label": "Ergli Municipality", "value": "030"},
            {"label": "Garkalne Municipality", "value": "031"},
            {"label": "Grobina Municipality", "value": "032"},
            {"label": "Gulbene Municipality", "value": "033"},
            {"label": "Iecava Municipality", "value": "034"},
            {"label": "Ikskile Municipality", "value": "035"},
            {"label": "Ilukste Municipality", "value": "036"},
            {"label": "Incukalns Municipality", "value": "037"},
            {"label": "Jaunjelgava Municipality", "value": "038"},
            {"label": "Jaunpils Municipality", "value": "040"},
            {"label": "Jekabpils Municipality", "value": "042"},
            {"label": "Jelgava", "value": "JEL"},
            {"label": "Jelgava Municipality", "value": "041"},
            {"label": "Jurmala", "value": "JUR"},
            {"label": "Kandava Municipality", "value": "043"},
            {"label": "Karsava Municipality", "value": "044"},
            {"label": "Kegums Municipality", "value": "051"},
            {"label": "Kekava Municipality", "value": "052"},
            {"label": "Koceni Municipality", "value": "045"},
            {"label": "Koknese Municipality", "value": "046"},
            {"label": "Kraslava Municipality", "value": "047"},
            {"label": "Kuldiga Municipality", "value": "050"},
            {"label": "Lielvarde Municipality", "value": "053"},
            {"label": "Liepaja", "value": "LPX"},
            {"label": "Ligatne Municipality", "value": "055"},
            {"label": "Limbazi Municipality", "value": "054"},
            {"label": "Livani Municipality", "value": "056"},
            {"label": "Lubana Municipality", "value": "057"},
            {"label": "Ludza Municipality", "value": "058"},
            {"label": "Madona Municipality", "value": "059"},
            {"label": "Malpils Municipality", "value": "061"},
            {"label": "Marupe Municipality", "value": "062"},
            {"label": "Mazsalaca Municipality", "value": "060"},
            {"label": "Naukseni Municipality", "value": "064"},
            {"label": "Nereta Municipality", "value": "065"},
            {"label": "Nica Municipality", "value": "066"},
            {"label": "Ogre Municipality", "value": "067"},
            {"label": "Olaine Municipality", "value": "068"},
            {"label": "Ozolnieki Municipality", "value": "069"},
            {"label": "Pargauja Municipality", "value": "070"},
            {"label": "Pavilosta Municipality", "value": "071"},
            {"label": "Plavinas Municipality", "value": "072"},
            {"label": "Preili Municipality", "value": "073"},
            {"label": "Priekule Municipality", "value": "074"},
            {"label": "Priekuli Municipality", "value": "075"},
            {"label": "Rauna Municipality", "value": "076"},
            {"label": "Rezekne", "value": "REZ"},
            {"label": "Riebini Municipality", "value": "078"},
            {"label": "Riga", "value": "RIX"},
            {"label": "Roja Municipality", "value": "079"},
            {"label": "Ropazi Municipality", "value": "080"},
            {"label": "Rucava Municipality", "value": "081"},
            {"label": "Rugaji Municipality", "value": "082"},
            {"label": "Rujiena Municipality", "value": "084"},
            {"label": "Rundale Municipality", "value": "083"},
            {"label": "Salacgriva Municipality", "value": "086"},
            {"label": "Salaspils Municipality", "value": "087"},
            {"label": "Saldus Municipality", "value": "088"},
            {"label": "Saulkrasti Municipality", "value": "089"},
            {"label": "Seja Municipality", "value": "090"},
            {"label": "Sigulda Municipality", "value": "091"},
            {"label": "Skriveri Municipality", "value": "092"},
            {"label": "Skrunda Municipality", "value": "093"},
            {"label": "Smiltene Municipality", "value": "094"},
            {"label": "Stopini Municipality", "value": "095"},
            {"label": "Strenci Municipality", "value": "096"},
            {"label": "Talsi Municipality", "value": "097"},
            {"label": "Tervete Municipality", "value": "098"},
            {"label": "Vainode Municipality", "value": "100"},
            {"label": "Valka Municipality", "value": "101"},
            {"label": "Valmiera", "value": "VMR"},
            {"label": "Varaklani Municipality", "value": "102"},
            {"label": "Varkava Municipality", "value": "103"},
            {"label": "Vecpiebalga Municipality", "value": "104"},
            {"label": "Vecumnieki Municipality", "value": "105"},
            {"label": "Ventspils", "value": "VEN"},
            {"label": "Ventspils Municipality", "value": "106"},
            {"label": "Viesite Municipality", "value": "107"},
            {"label": "Vilaka Municipality", "value": "108"},
            {"label": "Vilani Municipality", "value": "109"},
            {"label": "Zilupe Municipality", "value": "110"}],
 "Lebanon": [{"label": "Akkar Governorate", "value": "AK"},
             {"label": "Baalbek-Hermel Governorate", "value": "BH"},
             {"label": "Beirut Governorate", "value": "BA"},
             {"label": "Beqaa Governorate", "value": "BI"},
             {"label": "Mount Lebanon Governorate", "value": "JL"},
             {"label": "Nabatieh Governorate", "value": "NA"},
             {"label": "North Governorate", "value": "AS"},
             {"label": "South Governorate", "value": "JA"}],
 "Lesotho": [{"label": "Berea District", "value": "D"},
             {"label": "Butha-Buthe District", "value": "B"},
             {"label": "Leribe District", "value": "C"},
             {"label": "Mafeteng District", "value": "E"},
             {"label": "Maseru District", "value": "A"},
             {"label": "Mohales Hoek District", "value": "F"},
             {"label": "Mokhotlong District", "value": "J"},
             {"label": "Qachas Nek District", "value": "H"},
             {"label": "Quthing District", "value": "G"},
             {"label": "Thaba-Tseka District", "value": "K"}],
 "Liberia": [{"label": "Bomi County", "value": "BM"},
             {"label": "Bong County", "value": "BG"},
             {"label": "Gbarpolu County", "value": "GP"},
             {"label": "Grand Bassa County", "value": "GB"},
             {"label": "Grand Cape Mount County", "value": "CM"},
             {"label": "Grand Gedeh County", "value": "GG"},
             {"label": "Grand Kru County", "value": "GK"},
             {"label": "Lofa County", "value": "LO"},
             {"label": "Margibi County", "value": "MG"},
             {"label": "Maryland County", "value": "MY"},
             {"label": "Montserrado County", "value": "MO"},
             {"label": "Nimba", "value": "NI"},
             {"label": "River Cess County", "value": "RI"},
             {"label": "River Gee County", "value": "RG"},
             {"label": "Sinoe County", "value": "SI"}],
 "Libya": [{"label": "Al Wahat District", "value": "WA"},
           {"label": "Benghazi", "value": "BA"},
           {"label": "Derna District", "value": "DR"},
           {"label": "Ghat District", "value": "GT"},
           {"label": "Jabal al Akhdar", "value": "JA"},
           {"label": "Jabal al Gharbi District", "value": "JG"},
           {"label": "Jafara", "value": "JI"},
           {"label": "Jufra", "value": "JU"},
           {"label": "Kufra District", "value": "KF"},
           {"label": "Marj District", "value": "MJ"},
           {"label": "Misrata District", "value": "MI"},
           {"label": "Murqub", "value": "MB"},
           {"label": "Murzuq District", "value": "MQ"},
           {"label": "Nalut District", "value": "NL"},
           {"label": "Nuqat al Khams", "value": "NQ"},
           {"label": "Sabha District", "value": "SB"},
           {"label": "Sirte District", "value": "SR"},
           {"label": "Tripoli District", "value": "TB"},
           {"label": "Wadi al Hayaa District", "value": "WD"},
           {"label": "Wadi al Shatii District", "value": "WS"},
           {"label": "Zawiya District", "value": "ZA"}],
 "Liechtenstein": [{"label": "Balzers", "value": "01"},
                   {"label": "Eschen", "value": "02"},
                   {"label": "Gamprin", "value": "03"},
                   {"label": "Mauren", "value": "04"},
                   {"label": "Planken", "value": "05"},
                   {"label": "Ruggell", "value": "06"},
                   {"label": "Schaan", "value": "07"},
                   {"label": "Schellenberg", "value": "08"},
                   {"label": "Triesen", "value": "09"},
                   {"label": "Triesenberg", "value": "10"},
                   {"label": "Vaduz", "value": "11"}],
 "Lithuania": [{"label": "Alytus City Municipality", "value": "02"},
               {"label": "Kaunas City Municipality", "value": "15"},
               {"label": "Klaipeda District Municipality", "value": "21"},
               {"label": "Marijampole Municipality", "value": "25"},
               {"label": "Panevezys District Municipality", "value": "33"},
               {"label": "Siauliai City Municipality", "value": "43"},
               {"label": "Taurage County", "value": "TA"},
               {"label": "Telsiai District Municipality", "value": "51"},
               {"label": "Utena County", "value": "UT"},
               {"label": "Vilnius City Municipality", "value": "57"}],
 "Luxembourg": [{"label": "Canton of Capellen", "value": "CA"},
                {"label": "Canton of Clervaux", "value": "CL"},
                {"label": "Canton of Diekirch", "value": "DI"},
                {"label": "Canton of Echternach", "value": "EC"},
                {"label": "Canton of Esch-sur-Alzette", "value": "ES"},
                {"label": "Canton of Grevenmacher", "value": "GR"},
                {"label": "Canton of Luxembourg", "value": "LU"},
                {"label": "Canton of Mersch", "value": "ME"},
                {"label": "Canton of Redange", "value": "RD"},
                {"label": "Canton of Remich", "value": "RM"},
                {"label": "Canton of Vianden", "value": "VD"},
                {"label": "Canton of Wiltz", "value": "WI"}],
 "Macedonia": [{"label": "Aracinovo Municipality", "value": "02"},
               {"label": "Berovo Municipality", "value": "03"},
               {"label": "Bitola Municipality", "value": "04"},
               {"label": "Bogdanci Municipality", "value": "05"},
               {"label": "Bogovinje Municipality", "value": "06"},
               {"label": "Bosilovo Municipality", "value": "07"},
               {"label": "Brvenica Municipality", "value": "08"},
               {"label": "Butel Municipality", "value": "09"},
               {"label": "Cair Municipality", "value": "79"},
               {"label": "Caska Municipality", "value": "80"},
               {"label": "Centar Zupa Municipality", "value": "78"},
               {"label": "Cesinovo-Oblesevo Municipality", "value": "81"},
               {"label": "Cucer-Sandevo Municipality", "value": "82"},
               {"label": "Debarca Municipality", "value": "22"},
               {"label": "Delcevo Municipality", "value": "23"},
               {"label": "Demir Hisar Municipality", "value": "25"},
               {"label": "Demir Kapija Municipality", "value": "24"},
               {"label": "Dojran Municipality", "value": "26"},
               {"label": "Dolneni Municipality", "value": "27"},
               {"label": "Gazi Baba Municipality", "value": "17"},
               {"label": "Gevgelija Municipality", "value": "18"},
               {"label": "Gjorce Petrov Municipality", "value": "29"},
               {"label": "Gostivar Municipality", "value": "19"},
               {"label": "Gradsko Municipality", "value": "20"},
               {"label": "Greater Skopje", "value": "85"},
               {"label": "Ilinden Municipality", "value": "34"},
               {"label": "Jegunovce Municipality", "value": "35"},
               {"label": "Karbinci", "value": "37"},
               {"label": "Karpos Municipality", "value": "38"},
               {"label": "Kavadarci Municipality", "value": "36"},
               {"label": "Kicevo Municipality", "value": "40"},
               {"label": "Kisela Voda Municipality", "value": "39"},
               {"label": "Kocani Municipality", "value": "42"},
               {"label": "Konce Municipality", "value": "41"},
               {"label": "Kratovo Municipality", "value": "43"},
               {"label": "Kriva Palanka Municipality", "value": "44"},
               {"label": "Krivogastani Municipality", "value": "45"},
               {"label": "Krusevo Municipality", "value": "46"},
               {"label": "Kumanovo Municipality", "value": "47"},
               {"label": "Lipkovo Municipality", "value": "48"},
               {"label": "Lozovo Municipality", "value": "49"},
               {"label": "Makedonska Kamenica Municipality", "value": "51"},
               {"label": "Makedonski Brod Municipality", "value": "52"},
               {"label": "Mavrovo and Rostusa Municipality", "value": "50"},
               {"label": "Mogila Municipality", "value": "53"},
               {"label": "Negotino Municipality", "value": "54"},
               {"label": "Novaci Municipality", "value": "55"},
               {"label": "Novo Selo Municipality", "value": "56"},
               {"label": "Ohrid Municipality", "value": "58"},
               {"label": "Pehcevo Municipality", "value": "60"},
               {"label": "Petrovec Municipality", "value": "59"},
               {"label": "Plasnica Municipality", "value": "61"},
               {"label": "Prilep Municipality", "value": "62"},
               {"label": "Probistip Municipality", "value": "63"},
               {"label": "Radovis Municipality", "value": "64"},
               {"label": "Rankovce Municipality", "value": "65"},
               {"label": "Resen Municipality", "value": "66"},
               {"label": "Rosoman Municipality", "value": "67"},
               {"label": "Saraj Municipality", "value": "68"},
               {"label": "Sopiste Municipality", "value": "70"},
               {"label": "Staro Nagoricane Municipality", "value": "71"},
               {"label": "Stip Municipality", "value": "83"},
               {"label": "Struga Municipality", "value": "72"},
               {"label": "Strumica Municipality", "value": "73"},
               {"label": "Studenicani Municipality", "value": "74"},
               {"label": "Suto Orizari Municipality", "value": "84"},
               {"label": "Sveti Nikole Municipality", "value": "69"},
               {"label": "Tearce Municipality", "value": "75"},
               {"label": "Tetovo Municipality", "value": "76"},
               {"label": "Valandovo Municipality", "value": "10"},
               {"label": "Vasilevo Municipality", "value": "11"},
               {"label": "Veles Municipality", "value": "13"},
               {"label": "Vevcani Municipality", "value": "12"},
               {"label": "Vinica Municipality", "value": "14"},
               {"label": "Vrapciste Municipality", "value": "16"},
               {"label": "Zelenikovo Municipality", "value": "32"},
               {"label": "Zelino Municipality", "value": "30"},
               {"label": "Zrnovci Municipality", "value": "33"}],
 "Madagascar": [{"label": "Antsiranana Province", "value": "D"}],
 "Malawi": [{"label": "Central Region", "value": "C"},
            {"label": "Northern Region", "value": "N"},
            {"label": "Southern Region", "value": "S"}],
 "Malaysia": [{"label": "Johor", "value": "01"},
              {"label": "Kedah", "value": "02"},
              {"label": "Kelantan", "value": "03"},
              {"label": "Kuala Lumpur", "value": "14"},
              {"label": "Labuan", "value": "15"},
              {"label": "Malacca", "value": "04"},
              {"label": "Negeri Sembilan", "value": "05"},
              {"label": "Pahang", "value": "06"},
              {"label": "Penang", "value": "07"},
              {"label": "Perak", "value": "08"},
              {"label": "Perlis", "value": "09"},
              {"label": "Putrajaya", "value": "16"},
              {"label": "Sabah", "value": "12"},
              {"label": "Sarawak", "value": "13"},
              {"label": "Selangor", "value": "10"},
              {"label": "Terengganu", "value": "11"}],
 "Maldives": [{"label": "Addu Atoll", "value": "01"},
              {"label": "Dhaalu Atoll", "value": "17"},
              {"label": "Gaafu Alif Atoll", "value": "27"},
              {"label": "Gaafu Dhaalu Atoll", "value": "28"},
              {"label": "Gnaviyani Atoll", "value": "29"},
              {"label": "Haa Alif Atoll", "value": "07"},
              {"label": "Haa Dhaalu Atoll", "value": "23"},
              {"label": "Kaafu Atoll", "value": "26"},
              {"label": "Laamu Atoll", "value": "05"},
              {"label": "Lhaviyani Atoll", "value": "03"},
              {"label": "Meemu Atoll", "value": "12"},
              {"label": "Noonu Atoll", "value": "25"},
              {"label": "Raa Atoll", "value": "13"},
              {"label": "Shaviyani Atoll", "value": "24"},
              {"label": "South Central Province", "value": "SC"},
              {"label": "Thaa Atoll", "value": "08"},
              {"label": "Vaavu Atoll", "value": "04"}],
 "Mali": [{"label": "Bamako", "value": "BKO"},
          {"label": "Gao Region", "value": "7"},
          {"label": "Kayes Region", "value": "1"},
          {"label": "Kidal Region", "value": "8"},
          {"label": "Koulikoro Region", "value": "2"},
          {"label": "Mopti Region", "value": "5"},
          {"label": "Segou Region", "value": "4"},
          {"label": "Sikasso Region", "value": "3"},
          {"label": "Tombouctou Region", "value": "6"}],
 "Malta": [{"label": "Attard", "value": "01"},
           {"label": "Balzan", "value": "02"},
           {"label": "Birgu", "value": "03"},
           {"label": "Birkirkara", "value": "04"},
           {"label": "Birzebbuga", "value": "05"},
           {"label": "Cospicua", "value": "06"},
           {"label": "Dingli", "value": "07"},
           {"label": "Fgura", "value": "08"},
           {"label": "Floriana", "value": "09"},
           {"label": "Fontana", "value": "10"},
           {"label": "Ghajnsielem", "value": "13"},
           {"label": "Gharghur", "value": "15"},
           {"label": "Ghasri", "value": "16"},
           {"label": "Ghaxaq", "value": "17"},
           {"label": "Gzira", "value": "12"},
           {"label": "Hamrun", "value": "18"},
           {"label": "Iklin", "value": "19"},
           {"label": "Kalkara", "value": "21"},
           {"label": "Kercem", "value": "22"},
           {"label": "Kirkop", "value": "23"},
           {"label": "Lija", "value": "24"},
           {"label": "Luqa", "value": "25"},
           {"label": "Marsa", "value": "26"},
           {"label": "Marsaskala", "value": "27"},
           {"label": "Marsaxlokk", "value": "28"},
           {"label": "Mgarr", "value": "31"},
           {"label": "Msida", "value": "34"},
           {"label": "Munxar", "value": "36"},
           {"label": "Nadur", "value": "37"},
           {"label": "Naxxar", "value": "38"},
           {"label": "Paola", "value": "39"},
           {"label": "Pembroke", "value": "40"},
           {"label": "Pieta", "value": "41"},
           {"label": "Qala", "value": "42"},
           {"label": "Qormi", "value": "43"},
           {"label": "Qrendi", "value": "44"},
           {"label": "Saint Lawrence", "value": "50"},
           {"label": "San Gwann", "value": "49"},
           {"label": "Sannat", "value": "52"},
           {"label": "Santa Lucija", "value": "53"},
           {"label": "Senglea", "value": "20"},
           {"label": "Siggiewi", "value": "55"},
           {"label": "Sliema", "value": "56"},
           {"label": "St. Julians", "value": "48"},
           {"label": "St. Pauls Bay", "value": "51"},
           {"label": "Swieqi", "value": "57"},
           {"label": "Ta Xbiex", "value": "58"},
           {"label": "Tarxien", "value": "59"},
           {"label": "Valletta", "value": "60"},
           {"label": "Victoria", "value": "45"},
           {"label": "Xaghra", "value": "61"},
           {"label": "Xewkija", "value": "62"},
           {"label": "Xghajra", "value": "63"},
           {"label": "Zabbar", "value": "64"},
           {"label": "Zebbug Gozo", "value": "65"},
           {"label": "Zebbug Malta", "value": "66"},
           {"label": "Zejtun", "value": "67"},
           {"label": "Zurrieq", "value": "68"}],
 "Mauritania": [{"label": "Adrar", "value": "07"},
                {"label": "Assaba", "value": "03"},
                {"label": "Brakna", "value": "05"},
                {"label": "Dakhlet Nouadhibou", "value": "08"},
                {"label": "Gorgol", "value": "04"},
                {"label": "Guidimaka", "value": "10"},
                {"label": "Hodh Ech Chargui", "value": "01"},
                {"label": "Hodh El Gharbi", "value": "02"},
                {"label": "Inchiri", "value": "12"},
                {"label": "Nouakchott-Nord", "value": "14"},
                {"label": "Nouakchott-Ouest", "value": "13"},
                {"label": "Nouakchott-Sud", "value": "15"},
                {"label": "Tiris Zemmour", "value": "11"},
                {"label": "Trarza", "value": "06"}],
 "Mauritius": [{"label": "Agalega", "value": "AG"},
               {"label": "Cargados Carajos", "value": "CC"},
               {"label": "Flacq District", "value": "FL"},
               {"label": "Grand Port District", "value": "GP"},
               {"label": "Moka District", "value": "MO"},
               {"label": "Pamplemousses District", "value": "PA"},
               {"label": "Plaines Wilhems District", "value": "PW"},
               {"label": "Port Louis District", "value": "PL"},
               {"label": "Riviere du Rempart District", "value": "RR"},
               {"label": "Riviere Noire District", "value": "BL"},
               {"label": "Rodrigues", "value": "RO"},
               {"label": "Savanne District", "value": "SA"}],
 "Mexico": [{"label": "Aguascalientes", "value": "AGU"},
            {"label": "Baja California", "value": "BCN"},
            {"label": "Baja California Sur", "value": "BCS"},
            {"label": "Campeche", "value": "CAM"},
            {"label": "Chiapas", "value": "CHP"},
            {"label": "Chihuahua", "value": "CHH"},
            {"label": "Ciudad de Mexico", "value": "CDMX"},
            {"label": "Coahuila de Zaragoza", "value": "COA"},
            {"label": "Colima", "value": "COL"},
            {"label": "Durango", "value": "DUR"},
            {"label": "Estado de Mexico", "value": "MEX"},
            {"label": "Guanajuato", "value": "GUA"},
            {"label": "Guerrero", "value": "GRO"},
            {"label": "Hidalgo", "value": "HID"},
            {"label": "Jalisco", "value": "JAL"},
            {"label": "Michoacan de Ocampo", "value": "MIC"},
            {"label": "Morelos", "value": "MOR"},
            {"label": "Nayarit", "value": "NAY"},
            {"label": "Nuevo Leon", "value": "NLE"},
            {"label": "Oaxaca", "value": "OAX"},
            {"label": "Puebla", "value": "PUE"},
            {"label": "Queretaro", "value": "QUE"},
            {"label": "Quintana Roo", "value": "ROO"},
            {"label": "San Luis Potosi", "value": "SLP"},
            {"label": "Sinaloa", "value": "SIN"},
            {"label": "Sonora", "value": "SON"},
            {"label": "Tabasco", "value": "TAB"},
            {"label": "Tamaulipas", "value": "TAM"},
            {"label": "Tlaxcala", "value": "TLA"},
            {"label": "Veracruz de Ignacio de la Llave", "value": "VER"},
            {"label": "Yucatan", "value": "YUC"},
            {"label": "Zacatecas", "value": "ZAC"}],
 "Micronesia": [{"label": "Chuuk State", "value": "TRK"},
                {"label": "Kosrae State", "value": "KSA"},
                {"label": "Pohnpei State", "value": "PNI"},
                {"label": "Yap State", "value": "YAP"}],
 "Moldova": [{"label": "Anenii Noi District", "value": "AN"},
             {"label": "Balti Municipality", "value": "BA"},
             {"label": "Basarabeasca District", "value": "BS"},
             {"label": "Bender Municipality", "value": "BD"},
             {"label": "Briceni District", "value": "BR"},
             {"label": "Cahul District", "value": "CA"},
             {"label": "Calarasi District", "value": "CL"},
             {"label": "Cantemir District", "value": "CT"},
             {"label": "Causeni District", "value": "CS"},
             {"label": "Chisinau Municipality", "value": "CU"},
             {"label": "Cimislia District", "value": "CM"},
             {"label": "Criuleni District", "value": "CR"},
             {"label": "Donduseni District", "value": "DO"},
             {"label": "Drochia District", "value": "DR"},
             {"label": "Dubasari District", "value": "DU"},
             {"label": "Edinet District", "value": "ED"},
             {"label": "Falesti District", "value": "FA"},
             {"label": "Floresti District", "value": "FL"},
             {"label": "Gagauzia", "value": "GA"},
             {"label": "Glodeni District", "value": "GL"},
             {"label": "Hincesti District", "value": "HI"},
             {"label": "Ialoveni District", "value": "IA"},
             {"label": "Nisporeni District", "value": "NI"},
             {"label": "Ocnita District", "value": "OC"},
             {"label": "Orhei District", "value": "OR"},
             {"label": "Rezina District", "value": "RE"},
             {"label": "Riscani District", "value": "RI"},
             {"label": "Singerei District", "value": "SI"},
             {"label": "Soldanesti District", "value": "SD"},
             {"label": "Soroca District", "value": "SO"},
             {"label": "Stefan Voda District", "value": "SV"},
             {"label": "Straseni District", "value": "ST"},
             {"label": "Taraclia District", "value": "TA"},
             {"label": "Telenesti District", "value": "TE"},
             {"label": "Transnistria autonomous territorial unit",
              "value": "SN"},
             {"label": "Ungheni District", "value": "UN"}],
 "Mongolia": [{"label": "Arkhangai Province", "value": "073"},
              {"label": "Bayan-Olgii Province", "value": "071"},
              {"label": "Bayankhongor Province", "value": "069"},
              {"label": "Bulgan Province", "value": "067"},
              {"label": "Darkhan-Uul Province", "value": "037"},
              {"label": "Dornod Province", "value": "061"},
              {"label": "Dundgovi Province", "value": "059"},
              {"label": "Govi-Altai Province", "value": "065"},
              {"label": "Govisumber Province", "value": "064"},
              {"label": "Khentii Province", "value": "039"},
              {"label": "Khovd Province", "value": "043"},
              {"label": "Khovsgol Province", "value": "041"},
              {"label": "Omnogovi Province", "value": "053"},
              {"label": "Orkhon Province", "value": "035"},
              {"label": "Ovorkhangai Province", "value": "055"},
              {"label": "Selenge Province", "value": "049"},
              {"label": "Sukhbaatar Province", "value": "051"},
              {"label": "Tov Province", "value": "047"},
              {"label": "Uvs Province", "value": "046"},
              {"label": "Zavkhan Province", "value": "057"}],
 "Montenegro": [{"label": "Andrijevica Municipality", "value": "01"},
                {"label": "Bar Municipality", "value": "02"},
                {"label": "Berane Municipality", "value": "03"},
                {"label": "Bijelo Polje Municipality", "value": "04"},
                {"label": "Budva Municipality", "value": "05"},
                {"label": "Danilovgrad Municipality", "value": "07"},
                {"label": "Gusinje Municipality", "value": "22"},
                {"label": "Kolasin Municipality", "value": "09"},
                {"label": "Kotor Municipality", "value": "10"},
                {"label": "Mojkovac Municipality", "value": "11"},
                {"label": "Niksic Municipality", "value": "12"},
                {"label": "Old Royal Capital Cetinje", "value": "06"},
                {"label": "Plav Municipality", "value": "13"},
                {"label": "Pljevlja Municipality", "value": "14"},
                {"label": "Pluzine Municipality", "value": "15"},
                {"label": "Podgorica Municipality", "value": "16"},
                {"label": "Rozaje Municipality", "value": "17"},
                {"label": "Savnik Municipality", "value": "18"},
                {"label": "Tivat Municipality", "value": "19"},
                {"label": "Ulcinj Municipality", "value": "20"},
                {"label": "Zabljak Municipality", "value": "21"}],
 "Morocco": [{"label": "Assa-Zag (EH-partial)", "value": "ASZ"},
             {"label": "Casablanca-Settat", "value": "06"},
             {"label": "Draa-Tafilalet", "value": "08"},
             {"label": "Fes-Meknes", "value": "03"},
             {"label": "Guelmim-Oued Noun (EH-partial)", "value": "10"},
             {"label": "Kenitra", "value": "KEN"},
             {"label": "Khenifra", "value": "KHN"},
             {"label": "LOriental", "value": "02"},
             {"label": "Laayoune-Sakia El Hamra (EH-partial)", "value": "11"},
             {"label": "Oued Ed-Dahab (EH)", "value": "OUD"},
             {"label": "Safi", "value": "SAF"},
             {"label": "Tanger-Tetouan-Al Hoceima", "value": "01"}],
 "Mozambique": [{"label": "Cabo Delgado Province", "value": "P"},
                {"label": "Gaza Province", "value": "G"},
                {"label": "Inhambane Province", "value": "I"},
                {"label": "Manica Province", "value": "B"},
                {"label": "Maputo", "value": "MPM"},
                {"label": "Maputo Province", "value": "L"},
                {"label": "Nampula Province", "value": "N"},
                {"label": "Niassa Province", "value": "A"},
                {"label": "Sofala Province", "value": "S"},
                {"label": "Tete Province", "value": "T"},
                {"label": "Zambezia Province", "value": "Q"}],
 "Myanmar": [{"label": "Ayeyarwady Region", "value": "07"},
             {"label": "Bago", "value": "02"},
             {"label": "Chin State", "value": "14"},
             {"label": "Kachin State", "value": "11"},
             {"label": "Kayah State", "value": "12"},
             {"label": "Kayin State", "value": "13"},
             {"label": "Magway Region", "value": "03"},
             {"label": "Mandalay Region", "value": "04"},
             {"label": "Mon State", "value": "15"},
             {"label": "Naypyidaw Union Territory", "value": "18"},
             {"label": "Rakhine State", "value": "16"},
             {"label": "Sagaing Region", "value": "01"},
             {"label": "Shan State", "value": "17"},
             {"label": "Tanintharyi Region", "value": "05"},
             {"label": "Yangon Region", "value": "06"}],
 "Namibia": [{"label": "Erongo Region", "value": "ER"},
             {"label": "Hardap Region", "value": "HA"},
             {"label": "Karas Region", "value": "KA"},
             {"label": "Kavango East Region", "value": "KE"},
             {"label": "Khomas Region", "value": "KH"},
             {"label": "Kunene Region", "value": "KU"},
             {"label": "Ohangwena Region", "value": "OW"},
             {"label": "Omaheke Region", "value": "OH"},
             {"label": "Omusati Region", "value": "OS"},
             {"label": "Oshana Region", "value": "ON"},
             {"label": "Oshikoto Region", "value": "OT"},
             {"label": "Otjozondjupa Region", "value": "OD"},
             {"label": "Zambezi Region", "value": "CA"}],
 "Nauru": [{"label": "Aiwo District", "value": "01"},
           {"label": "Anabar District", "value": "02"},
           {"label": "Baiti District", "value": "05"},
           {"label": "Ijuw District", "value": "10"},
           {"label": "Meneng District", "value": "11"},
           {"label": "Uaboe District", "value": "13"},
           {"label": "Yaren District", "value": "14"}],
 "Nepal": [{"label": "Central Region", "value": "1"},
           {"label": "Eastern Development Region", "value": "4"},
           {"label": "Far-Western Development Region", "value": "5"},
           {"label": "Mid-Western Region", "value": "2"},
           {"label": "Western Region", "value": "3"}],
 "Netherlands": [{"label": "Bonaire", "value": "BQ1"},
                 {"label": "Drenthe", "value": "DR"},
                 {"label": "Flevoland", "value": "FL"},
                 {"label": "Friesland", "value": "FR"},
                 {"label": "Gelderland", "value": "GE"},
                 {"label": "Groningen", "value": "GR"},
                 {"label": "Limburg", "value": "LI"},
                 {"label": "North Brabant", "value": "NB"},
                 {"label": "North Holland", "value": "NH"},
                 {"label": "Overijssel", "value": "OV"},
                 {"label": "South Holland", "value": "ZH"},
                 {"label": "Utrecht", "value": "UT"},
                 {"label": "Zeeland", "value": "ZE"}],
 "New Zealand": [{"label": "Auckland Region", "value": "AUK"},
                 {"label": "Bay of Plenty Region", "value": "BOP"},
                 {"label": "Canterbury Region", "value": "CAN"},
                 {"label": "Chatham Islands", "value": "CIT"},
                 {"label": "Gisborne District", "value": "GIS"},
                 {"label": "Hawkes Bay Region", "value": "HKB"},
                 {"label": "Manawatu-Wanganui Region", "value": "MWT"},
                 {"label": "Marlborough Region", "value": "MBH"},
                 {"label": "Nelson Region", "value": "NSN"},
                 {"label": "Northland Region", "value": "NTL"},
                 {"label": "Otago Region", "value": "OTA"},
                 {"label": "Southland Region", "value": "STL"},
                 {"label": "Taranaki Region", "value": "TKI"},
                 {"label": "Tasman District", "value": "TAS"},
                 {"label": "Waikato Region", "value": "WKO"},
                 {"label": "Wellington Region", "value": "WGN"},
                 {"label": "West Coast Region", "value": "WTC"}],
 "Nicaragua": [{"label": "Boaco", "value": "BO"},
               {"label": "Carazo", "value": "CA"},
               {"label": "Chinandega", "value": "CI"},
               {"label": "Chontales", "value": "CO"},
               {"label": "Esteli", "value": "ES"},
               {"label": "Granada", "value": "GR"},
               {"label": "Jinotega", "value": "JI"},
               {"label": "Leon", "value": "LE"},
               {"label": "Madriz", "value": "MD"},
               {"label": "Managua", "value": "MN"},
               {"label": "Masaya", "value": "MS"},
               {"label": "Matagalpa", "value": "MT"},
               {"label": "North Caribbean Coast", "value": "AN"},
               {"label": "Nueva Segovia", "value": "NS"},
               {"label": "Rio San Juan", "value": "SJ"},
               {"label": "Rivas", "value": "RI"},
               {"label": "South Caribbean Coast", "value": "AS"}],
 "Niger": [{"label": "Agadez Region", "value": "1"},
           {"label": "Diffa Region", "value": "2"},
           {"label": "Dosso Region", "value": "3"},
           {"label": "Maradi Region", "value": "4"},
           {"label": "Tahoua Region", "value": "5"},
           {"label": "Tillaberi Region", "value": "6"},
           {"label": "Zinder Region", "value": "7"}],
 "Nigeria": [{"label": "Abia", "value": "AB"},
             {"label": "Abuja Federal Capital Territory", "value": "FC"},
             {"label": "Adamawa", "value": "AD"},
             {"label": "Akwa Ibom", "value": "AK"},
             {"label": "Anambra", "value": "AN"},
             {"label": "Bauchi", "value": "BA"},
             {"label": "Bayelsa", "value": "BY"},
             {"label": "Benue", "value": "BE"},
             {"label": "Borno", "value": "BO"},
             {"label": "Cross River", "value": "CR"},
             {"label": "Delta", "value": "DE"},
             {"label": "Ebonyi", "value": "EB"},
             {"label": "Edo", "value": "ED"},
             {"label": "Ekiti", "value": "EK"},
             {"label": "Enugu", "value": "EN"},
             {"label": "Gombe", "value": "GO"},
             {"label": "Imo", "value": "IM"},
             {"label": "Jigawa", "value": "JI"},
             {"label": "Kaduna", "value": "KD"},
             {"label": "Kano", "value": "KN"},
             {"label": "Katsina", "value": "KT"},
             {"label": "Kebbi", "value": "KE"},
             {"label": "Kogi", "value": "KO"},
             {"label": "Kwara", "value": "KW"},
             {"label": "Lagos", "value": "LA"},
             {"label": "Nasarawa", "value": "NA"},
             {"label": "Niger", "value": "NI"},
             {"label": "Ogun", "value": "OG"},
             {"label": "Ondo", "value": "ON"},
             {"label": "Osun", "value": "OS"},
             {"label": "Oyo", "value": "OY"},
             {"label": "Plateau", "value": "PL"},
             {"label": "Rivers", "value": "RI"},
             {"label": "Sokoto", "value": "SO"},
             {"label": "Taraba", "value": "TA"},
             {"label": "Yobe", "value": "YO"},
             {"label": "Zamfara", "value": "ZA"}],
 "North Korea": [{"label": "Chagang Province", "value": "04"},
                 {"label": "Kangwon Province", "value": "07"},
                 {"label": "North Hamgyong Province", "value": "09"},
                 {"label": "North Hwanghae Province", "value": "06"},
                 {"label": "North Pyongan Province", "value": "03"},
                 {"label": "Pyongyang", "value": "01"},
                 {"label": "Rason", "value": "13"},
                 {"label": "Ryanggang Province", "value": "10"},
                 {"label": "South Hamgyong Province", "value": "08"},
                 {"label": "South Hwanghae Province", "value": "05"},
                 {"label": "South Pyongan Province", "value": "02"}],
 "Norway": [{"label": "Akershus", "value": "02"},
            {"label": "Buskerud", "value": "06"},
            {"label": "Finnmark", "value": "20"},
            {"label": "Hedmark", "value": "04"},
            {"label": "Hordaland", "value": "12"},
            {"label": "More og Romsdal", "value": "15"},
            {"label": "Nordland", "value": "18"},
            {"label": "Oppland", "value": "05"},
            {"label": "Oslo", "value": "03"},
            {"label": "Ostfold", "value": "01"},
            {"label": "Rogaland", "value": "11"},
            {"label": "Sogn og Fjordane", "value": "14"},
            {"label": "Telemark", "value": "08"},
            {"label": "Troms", "value": "19"},
            {"label": "Trondelag", "value": "50"},
            {"label": "Vest-Agder", "value": "10"},
            {"label": "Vestfold", "value": "07"}],
 "Oman": [{"label": "Ad Dakhiliyah Governorate", "value": "DA"},
          {"label": "Ad Dhahirah Governorate", "value": "ZA"},
          {"label": "Al Batinah North Governorate", "value": "BS"},
          {"label": "Al Batinah Region", "value": "BA"},
          {"label": "Al Buraimi Governorate", "value": "BU"},
          {"label": "Al Wusta Governorate", "value": "WU"},
          {"label": "Ash Sharqiyah Region", "value": "SH"},
          {"label": "Dhofar Governorate", "value": "ZU"},
          {"label": "Musandam Governorate", "value": "MU"},
          {"label": "Muscat Governorate", "value": "MA"}],
 "Pakistan": [{"label": "Azad Kashmir", "value": "JK"},
              {"label": "Balochistan", "value": "BA"},
              {"label": "Federally Administered Tribal Areas", "value": "TA"},
              {"label": "Gilgit-Baltistan", "value": "GB"},
              {"label": "Islamabad Capital Territory", "value": "IS"},
              {"label": "Khyber Pakhtunkhwa", "value": "KP"},
              {"label": "Punjab", "value": "PB"},
              {"label": "Sindh", "value": "SD"}],
 "Palau": [{"label": "Aimeliik", "value": "002"},
           {"label": "Airai", "value": "004"},
           {"label": "Angaur", "value": "010"},
           {"label": "Hatohobei", "value": "050"},
           {"label": "Kayangel", "value": "100"},
           {"label": "Koror", "value": "150"},
           {"label": "Melekeok", "value": "212"},
           {"label": "Ngaraard", "value": "214"},
           {"label": "Ngarchelong", "value": "218"},
           {"label": "Ngardmau", "value": "222"},
           {"label": "Ngchesar", "value": "226"},
           {"label": "Ngeremlengui", "value": "227"},
           {"label": "Ngiwal", "value": "228"},
           {"label": "Peleliu", "value": "350"},
           {"label": "Sonsorol", "value": "370"}],
 "Panama": [{"label": "Bocas del Toro Province", "value": "1"},
            {"label": "Chiriqui Province", "value": "4"},
            {"label": "Cocle Province", "value": "2"},
            {"label": "Colon Province", "value": "3"},
            {"label": "Darien Province", "value": "5"},
            {"label": "Embera-Wounaan Comarca", "value": "EM"},
            {"label": "Guna Yala", "value": "KY"},
            {"label": "Herrera Province", "value": "6"},
            {"label": "Los Santos Province", "value": "7"},
            {"label": "Ngobe-Bugle Comarca", "value": "NB"},
            {"label": "Panama Oeste Province", "value": "10"},
            {"label": "Panama Province", "value": "8"},
            {"label": "Veraguas Province", "value": "9"}],
 "Papua new Guinea": [{"label": "Bougainville", "value": "NSB"},
                      {"label": "Central Province", "value": "CPM"},
                      {"label": "Chimbu Province", "value": "CPK"},
                      {"label": "East New Britain", "value": "EBR"},
                      {"label": "Eastern Highlands Province", "value": "EHG"},
                      {"label": "Enga Province", "value": "EPW"},
                      {"label": "Gulf", "value": "GPK"},
                      {"label": "Hela", "value": "HLA"},
                      {"label": "Jiwaka Province", "value": "JWK"},
                      {"label": "Madang Province", "value": "MPM"},
                      {"label": "Manus Province", "value": "MRL"},
                      {"label": "Milne Bay Province", "value": "MBA"},
                      {"label": "Morobe Province", "value": "MPL"},
                      {"label": "New Ireland Province", "value": "NIK"},
                      {"label": "Oro Province", "value": "NPP"},
                      {"label": "Port Moresby", "value": "NCD"},
                      {"label": "Sandaun Province", "value": "SAN"},
                      {"label": "Southern Highlands Province", "value": "SHM"},
                      {"label": "West New Britain Province", "value": "WBK"},
                      {"label": "Western Highlands Province", "value": "WHM"},
                      {"label": "Western Province", "value": "WPD"}],
 "Paraguay": [{"label": "Alto Paraguay Department", "value": "16"},
              {"label": "Alto Parana Department", "value": "10"},
              {"label": "Amambay Department", "value": "13"},
              {"label": "Boqueron Department", "value": "19"},
              {"label": "Caaguazu", "value": "5"},
              {"label": "Caazapa", "value": "6"},
              {"label": "Canindeyu", "value": "14"},
              {"label": "Central Department", "value": "11"},
              {"label": "Concepcion Department", "value": "1"},
              {"label": "Cordillera Department", "value": "3"},
              {"label": "Guaira Department", "value": "4"},
              {"label": "Itapua", "value": "7"},
              {"label": "Misiones Department", "value": "8"},
              {"label": "neembucu Department", "value": "12"},
              {"label": "Paraguari Department", "value": "9"},
              {"label": "Presidente Hayes Department", "value": "15"},
              {"label": "San Pedro Department", "value": "2"}],
 "Peru": [{"label": "Amazonas", "value": "AMA"},
          {"label": "ancash", "value": "ANC"},
          {"label": "Apurimac", "value": "APU"},
          {"label": "Arequipa", "value": "ARE"},
          {"label": "Ayacucho", "value": "AYA"},
          {"label": "Cajamarca", "value": "CAJ"},
          {"label": "Callao", "value": "CAL"},
          {"label": "Cusco", "value": "CUS"},
          {"label": "Huancavelica", "value": "HUV"},
          {"label": "Huanuco", "value": "HUC"},
          {"label": "Ica", "value": "ICA"},
          {"label": "Junin", "value": "JUN"},
          {"label": "La Libertad", "value": "LAL"},
          {"label": "Lambayeque", "value": "LAM"},
          {"label": "Lima", "value": "LIM"},
          {"label": "Loreto", "value": "LOR"},
          {"label": "Madre de Dios", "value": "MDD"},
          {"label": "Moquegua", "value": "MOQ"},
          {"label": "Pasco", "value": "PAS"},
          {"label": "Piura", "value": "PIU"},
          {"label": "Puno", "value": "PUN"},
          {"label": "San Martin", "value": "SAM"},
          {"label": "Tacna", "value": "TAC"},
          {"label": "Tumbes", "value": "TUM"},
          {"label": "Ucayali", "value": "UCA"}],
 "Philippines": [{"label": "Abra", "value": "ABR"},
                 {"label": "Agusan del Norte", "value": "AGN"},
                 {"label": "Agusan del Sur", "value": "AGS"},
                 {"label": "Albay", "value": "ALB"},
                 {"label": "Antique", "value": "ANT"},
                 {"label": "Autonomous Region in Muslim Mindanao",
                  "value": "14"},
                 {"label": "Bataan", "value": "BAN"},
                 {"label": "Batanes", "value": "BTN"},
                 {"label": "Benguet", "value": "BEN"},
                 {"label": "Bohol", "value": "BOH"},
                 {"label": "Bukidnon", "value": "BUK"},
                 {"label": "Bulacan", "value": "BUL"},
                 {"label": "Cagayan", "value": "CAG"},
                 {"label": "Cagayan Valley", "value": "02"},
                 {"label": "Camarines Norte", "value": "CAN"},
                 {"label": "Caraga", "value": "13"},
                 {"label": "Central Luzon", "value": "03"},
                 {"label": "Cordillera Administrative Region", "value": "15"},
                 {"label": "Davao Occidental", "value": "DVO"},
                 {"label": "Davao Region", "value": "11"},
                 {"label": "Eastern Visayas", "value": "08"},
                 {"label": "Ilocos Region", "value": "01"},
                 {"label": "Metro Manila", "value": "NCR"},
                 {"label": "Occidental Mindoro", "value": "MDC"},
                 {"label": "Oriental Mindoro", "value": "MDR"},
                 {"label": "Soccsksargen", "value": "12"},
                 {"label": "Zamboanga Sibugay", "value": "ZSI"}],
 "Poland": [{"label": "Greater Poland Voivodeship", "value": "WP"},
            {"label": "Kuyavian-Pomeranian Voivodeship", "value": "KP"},
            {"label": "Lesser Poland Voivodeship", "value": "MA"},
            {"label": "Lower Silesian Voivodeship", "value": "DS"},
            {"label": "Lublin Voivodeship", "value": "LU"},
            {"label": "Lubusz Voivodeship", "value": "LB"},
            {"label": "Lodz Voivodeship", "value": "LD"},
            {"label": "Masovian Voivodeship", "value": "MZ"},
            {"label": "Opole Voivodeship", "value": "OP"},
            {"label": "Podkarpackie Voivodeship", "value": "PK"},
            {"label": "Podlaskie Voivodeship", "value": "PD"},
            {"label": "Pomeranian Voivodeship", "value": "PM"},
            {"label": "Silesian Voivodeship", "value": "SL"},
            {"label": "Swietokrzyskie Voivodeship", "value": "SK"},
            {"label": "Warmian-Masurian Voivodeship", "value": "WN"},
            {"label": "West Pomeranian Voivodeship", "value": "ZP"}],
 "Portugal": [{"label": "Acores", "value": "20"},
              {"label": "Aveiro", "value": "01"},
              {"label": "Beja", "value": "02"},
              {"label": "Braga", "value": "03"},
              {"label": "Braganca", "value": "04"},
              {"label": "Castelo Branco", "value": "05"},
              {"label": "Coimbra", "value": "06"},
              {"label": "evora", "value": "07"},
              {"label": "Faro", "value": "08"},
              {"label": "Guarda", "value": "09"},
              {"label": "Leiria", "value": "10"},
              {"label": "Lisbon", "value": "11"},
              {"label": "Madeira", "value": "30"},
              {"label": "Portalegre", "value": "12"},
              {"label": "Porto", "value": "13"},
              {"label": "Santarem", "value": "14"},
              {"label": "Setubal", "value": "15"},
              {"label": "Viana do Castelo", "value": "16"},
              {"label": "Vila Real", "value": "17"},
              {"label": "Viseu", "value": "18"}],
 "Qatar": [{"label": "Al Khor", "value": "KH"},
           {"label": "Al Rayyan Municipality", "value": "RA"},
           {"label": "Al Wakrah", "value": "WA"},
           {"label": "Al-Shahaniya", "value": "SH"},
           {"label": "Doha", "value": "DA"},
           {"label": "Madinat ash Shamal", "value": "MS"},
           {"label": "Umm Salal Municipality", "value": "US"}],
 "Romania": [{"label": "Alba", "value": "AB"},
             {"label": "Arad County", "value": "AR"},
             {"label": "Arges", "value": "AG"},
             {"label": "Bacau County", "value": "BC"},
             {"label": "Bihor County", "value": "BH"},
             {"label": "Bistrita-Nasaud County", "value": "BN"},
             {"label": "Botosani County", "value": "BT"},
             {"label": "Braila", "value": "BR"},
             {"label": "Brasov County", "value": "BV"},
             {"label": "Bucharest", "value": "B"},
             {"label": "Buzau County", "value": "BZ"},
             {"label": "Calarasi County", "value": "CL"},
             {"label": "Caras-Severin County", "value": "CS"},
             {"label": "Cluj County", "value": "CJ"},
             {"label": "Constanta County", "value": "CT"},
             {"label": "Covasna County", "value": "CV"},
             {"label": "Dambovita County", "value": "DB"},
             {"label": "Dolj County", "value": "DJ"},
             {"label": "Galati County", "value": "GL"},
             {"label": "Giurgiu County", "value": "GR"},
             {"label": "Gorj County", "value": "GJ"},
             {"label": "Harghita County", "value": "HR"},
             {"label": "Hunedoara County", "value": "HD"},
             {"label": "Ialomita County", "value": "IL"},
             {"label": "Iasi County", "value": "IS"},
             {"label": "Ilfov County", "value": "IF"},
             {"label": "Maramures County", "value": "MM"},
             {"label": "Mehedinti County", "value": "MH"},
             {"label": "Mures County", "value": "MS"},
             {"label": "Neamt County", "value": "NT"},
             {"label": "Olt County", "value": "OT"},
             {"label": "Prahova County", "value": "PH"},
             {"label": "Salaj County", "value": "SJ"},
             {"label": "Satu Mare County", "value": "SM"},
             {"label": "Sibiu County", "value": "SB"},
             {"label": "Suceava County", "value": "SV"},
             {"label": "Teleorman County", "value": "TR"},
             {"label": "Timis County", "value": "TM"},
             {"label": "Tulcea County", "value": "TL"},
             {"label": "Valcea County", "value": "VL"},
             {"label": "Vaslui County", "value": "VS"},
             {"label": "Vrancea County", "value": "VN"}],
 "Russia": [{"label": "Altai Krai", "value": "ALT"},
            {"label": "Altai Republic", "value": "AL"},
            {"label": "Amur Oblast", "value": "AMU"},
            {"label": "Arkhangelsk", "value": "ARK"},
            {"label": "Astrakhan Oblast", "value": "AST"},
            {"label": "Belgorod Oblast", "value": "BEL"},
            {"label": "Bryansk Oblast", "value": "BRY"},
            {"label": "Chechen Republic", "value": "CE"},
            {"label": "Chelyabinsk Oblast", "value": "CHE"},
            {"label": "Chukotka Autonomous Okrug", "value": "CHU"},
            {"label": "Chuvash Republic", "value": "CU"},
            {"label": "Ivanovo Oblast", "value": "IVA"},
            {"label": "Jewish Autonomous Oblast", "value": "YEV"},
            {"label": "Kabardino-Balkar Republic", "value": "KB"},
            {"label": "Kaliningrad", "value": "KGD"},
            {"label": "Kaluga Oblast", "value": "KLU"},
            {"label": "Kamchatka Krai", "value": "KAM"},
            {"label": "Karachay-Cherkess Republic", "value": "KC"},
            {"label": "Kemerovo Oblast", "value": "KEM"},
            {"label": "Khabarovsk Krai", "value": "KHA"},
            {"label": "Khanty-Mansi Autonomous Okrug", "value": "KHM"},
            {"label": "Kirov Oblast", "value": "KIR"},
            {"label": "Komi Republic", "value": "KO"},
            {"label": "Kostroma Oblast", "value": "KOS"},
            {"label": "Krasnodar Krai", "value": "KDA"},
            {"label": "Krasnoyarsk Krai", "value": "KYA"},
            {"label": "Kurgan Oblast", "value": "KGN"},
            {"label": "Kursk Oblast", "value": "KRS"},
            {"label": "Leningrad Oblast", "value": "LEN"},
            {"label": "Lipetsk Oblast", "value": "LIP"},
            {"label": "Magadan Oblast", "value": "MAG"},
            {"label": "Mari El Republic", "value": "ME"},
            {"label": "Moscow", "value": "MOW"},
            {"label": "Moscow Oblast", "value": "MOS"},
            {"label": "Murmansk Oblast", "value": "MUR"},
            {"label": "Nenets Autonomous Okrug", "value": "NEN"},
            {"label": "Nizhny Novgorod Oblast", "value": "NIZ"},
            {"label": "Novgorod Oblast", "value": "NGR"},
            {"label": "Novosibirsk", "value": "NVS"},
            {"label": "Omsk Oblast", "value": "OMS"},
            {"label": "Orenburg Oblast", "value": "ORE"},
            {"label": "Oryol Oblast", "value": "ORL"},
            {"label": "Penza Oblast", "value": "PNZ"},
            {"label": "Perm Krai", "value": "PER"},
            {"label": "Primorsky Krai", "value": "PRI"},
            {"label": "Pskov Oblast", "value": "PSK"},
            {"label": "Republic of Adygea", "value": "AD"},
            {"label": "Republic of Bashkortostan", "value": "BA"},
            {"label": "Republic of Buryatia", "value": "BU"},
            {"label": "Republic of Dagestan", "value": "DA"},
            {"label": "Republic of Ingushetia", "value": "IN"},
            {"label": "Republic of Kalmykia", "value": "KL"},
            {"label": "Republic of Karelia", "value": "KR"},
            {"label": "Republic of Khakassia", "value": "KK"},
            {"label": "Republic of Mordovia", "value": "MO"},
            {"label": "Republic of North Ossetia-Alania", "value": "SE"},
            {"label": "Republic of Tatarstan", "value": "TA"},
            {"label": "Rostov Oblast", "value": "ROS"},
            {"label": "Ryazan Oblast", "value": "RYA"},
            {"label": "Saint Petersburg", "value": "SPE"},
            {"label": "Sakha Republic", "value": "SA"},
            {"label": "Sakhalin", "value": "SAK"},
            {"label": "Samara Oblast", "value": "SAM"},
            {"label": "Saratov Oblast", "value": "SAR"},
            {"label": "Sevastopol", "value": "UA-40"},
            {"label": "Smolensk Oblast", "value": "SMO"},
            {"label": "Stavropol Krai", "value": "STA"},
            {"label": "Sverdlovsk", "value": "SVE"},
            {"label": "Tambov Oblast", "value": "TAM"},
            {"label": "Tomsk Oblast", "value": "TOM"},
            {"label": "Tula Oblast", "value": "TUL"},
            {"label": "Tuva Republic", "value": "TY"},
            {"label": "Tver Oblast", "value": "TVE"},
            {"label": "Tyumen Oblast", "value": "TYU"},
            {"label": "Udmurt Republic", "value": "UD"},
            {"label": "Ulyanovsk Oblast", "value": "ULY"},
            {"label": "Vladimir Oblast", "value": "VLA"},
            {"label": "Volgograd Oblast", "value": "VGG"},
            {"label": "Vologda Oblast", "value": "VLG"},
            {"label": "Voronezh Oblast", "value": "VOR"},
            {"label": "Yamalo-Nenets Autonomous Okrug", "value": "YAN"},
            {"label": "Yaroslavl Oblast", "value": "YAR"},
            {"label": "Zabaykalsky Krai", "value": "ZAB"}],
 "Rwanda": [{"label": "Eastern Province", "value": "02"},
            {"label": "Kigali district", "value": "01"},
            {"label": "Northern Province", "value": "03"},
            {"label": "Southern Province", "value": "05"},
            {"label": "Western Province", "value": "04"}],
 "Saint Kitts And Nevis": [{"label": "Christ Church Nichola Town Parish",
                            "value": "01"},
                           {"label": "Saint Anne Sandy Point Parish",
                            "value": "02"},
                           {"label": "Saint George Gingerland Parish",
                            "value": "04"},
                           {"label": "Saint James Windward Parish",
                            "value": "05"},
                           {"label": "Saint John Capisterre Parish",
                            "value": "06"},
                           {"label": "Saint John Figtree Parish",
                            "value": "07"},
                           {"label": "Saint Mary Cayon Parish", "value": "08"},
                           {"label": "Saint Paul Capisterre Parish",
                            "value": "09"},
                           {"label": "Saint Paul Charlestown Parish",
                            "value": "10"},
                           {"label": "Saint Peter Basseterre Parish",
                            "value": "11"},
                           {"label": "Saint Thomas Lowland Parish",
                            "value": "12"},
                           {"label": "Saint Thomas Middle Island Parish",
                            "value": "13"},
                           {"label": "Trinity Palmetto Point Parish",
                            "value": "15"}],
 "Saint Lucia": [{"label": "Anse la Raye Quarter", "value": "01"},
                 {"label": "Canaries", "value": "12"},
                 {"label": "Castries Quarter", "value": "02"},
                 {"label": "Choiseul Quarter", "value": "03"},
                 {"label": "Dennery Quarter", "value": "05"},
                 {"label": "Gros Islet Quarter", "value": "06"},
                 {"label": "Laborie Quarter", "value": "07"},
                 {"label": "Micoud Quarter", "value": "08"},
                 {"label": "Soufriere Quarter", "value": "10"},
                 {"label": "Vieux Fort Quarter", "value": "11"}],
 "Saint Vincent And The Grenadines": [{"label": "Charlotte Parish",
                                       "value": "01"},
                                      {"label": "Grenadines Parish",
                                       "value": "06"},
                                      {"label": "Saint Andrew Parish",
                                       "value": "02"},
                                      {"label": "Saint David Parish",
                                       "value": "03"},
                                      {"label": "Saint George Parish",
                                       "value": "04"},
                                      {"label": "Saint Patrick Parish",
                                       "value": "05"}],
 "Samoa": [{"label": "Aana", "value": "AA"},
           {"label": "Aiga-i-le-Tai", "value": "AL"},
           {"label": "Atua", "value": "AT"},
           {"label": "Gagaifomauga", "value": "GI"},
           {"label": "Palauli", "value": "PA"},
           {"label": "Tuamasaga", "value": "TU"},
           {"label": "Vaa-o-Fonoti", "value": "VF"},
           {"label": "Vaisigano", "value": "VS"}],
 "San Marino": [{"label": "Acquaviva", "value": "01"},
                {"label": "Borgo Maggiore", "value": "06"},
                {"label": "Chiesanuova", "value": "02"},
                {"label": "Domagnano", "value": "03"},
                {"label": "Faetano", "value": "04"},
                {"label": "Fiorentino", "value": "05"},
                {"label": "Montegiardino", "value": "08"},
                {"label": "San Marino", "value": "07"},
                {"label": "Serravalle", "value": "09"}],
 "Sao Tome and Principe": [{"label": "Principe Province", "value": "P"},
                           {"label": "Sao Tome Province", "value": "S"}],
 "Saudi Arabia": [{"label": "Asir", "value": "14"},
                  {"label": "Al Bahah", "value": "11"},
                  {"label": "Al Jawf", "value": "12"},
                  {"label": "Al Madinah", "value": "03"},
                  {"label": "Al-Qassim", "value": "05"},
                  {"label": "Eastern Province", "value": "04"},
                  {"label": "Hail", "value": "06"},
                  {"label": "Jizan", "value": "09"},
                  {"label": "Makkah", "value": "02"},
                  {"label": "Najran", "value": "10"},
                  {"label": "Northern Borders", "value": "08"},
                  {"label": "Riyadh", "value": "01"},
                  {"label": "Tabuk", "value": "07"}],
 "Senegal": [{"label": "Dakar", "value": "DK"},
             {"label": "Diourbel Region", "value": "DB"},
             {"label": "Fatick", "value": "FK"},
             {"label": "Kaffrine", "value": "KA"},
             {"label": "Kaolack", "value": "KL"},
             {"label": "Kedougou", "value": "KE"},
             {"label": "Kolda", "value": "KD"},
             {"label": "Louga", "value": "LG"},
             {"label": "Matam", "value": "MT"},
             {"label": "Saint-Louis", "value": "SL"},
             {"label": "Sedhiou", "value": "SE"},
             {"label": "Tambacounda Region", "value": "TC"},
             {"label": "Thies Region", "value": "TH"},
             {"label": "Ziguinchor", "value": "ZG"}],
 "Serbia": [{"label": "Central Banat District", "value": "02"},
            {"label": "Vojvodina", "value": "VO"}],
 "Seychelles": [{"label": "Anse Boileau", "value": "02"},
                {"label": "Anse Royale", "value": "05"},
                {"label": "Beau Vallon", "value": "08"},
                {"label": "Bel Ombre", "value": "10"},
                {"label": "Cascade", "value": "11"},
                {"label": "La Riviere Anglaise", "value": "16"},
                {"label": "Port Glaud", "value": "21"},
                {"label": "Takamaka", "value": "23"}],
 "Sierra Leone": [{"label": "Eastern Province", "value": "E"},
                  {"label": "Northern Province", "value": "N"},
                  {"label": "Southern Province", "value": "S"},
                  {"label": "Western Area", "value": "W"}],
 "Singapore": [{"label": "Central Singapore Community Development Council",
                "value": "01"},
               {"label": "North West Community Development Council",
                "value": "03"}],
 "Slovakia": [{"label": "Banska Bystrica Region", "value": "BC"},
              {"label": "Bratislava Region", "value": "BL"},
              {"label": "Kosice Region", "value": "KI"},
              {"label": "Nitra Region", "value": "NI"},
              {"label": "Presov Region", "value": "PV"},
              {"label": "Trencin Region", "value": "TC"},
              {"label": "Trnava Region", "value": "TA"},
              {"label": "Zilina Region", "value": "ZI"}],
 "Slovenia": [{"label": "Ajdovscina Municipality", "value": "001"},
              {"label": "Ankaran Municipality", "value": "213"},
              {"label": "Beltinci Municipality", "value": "002"},
              {"label": "Benedikt Municipality", "value": "148"},
              {"label": "Bistrica ob Sotli Municipality", "value": "149"},
              {"label": "Bled Municipality", "value": "003"},
              {"label": "Bloke Municipality", "value": "150"},
              {"label": "Bohinj Municipality", "value": "004"},
              {"label": "Borovnica Municipality", "value": "005"},
              {"label": "Bovec Municipality", "value": "006"},
              {"label": "Braslovce Municipality", "value": "151"},
              {"label": "Brda Municipality", "value": "007"},
              {"label": "Brezice Municipality", "value": "009"},
              {"label": "Brezovica Municipality", "value": "008"},
              {"label": "Cankova Municipality", "value": "152"},
              {"label": "Cerklje na Gorenjskem Municipality", "value": "012"},
              {"label": "Cerknica Municipality", "value": "013"},
              {"label": "Cerkno Municipality", "value": "014"},
              {"label": "Cerkvenjak Municipality", "value": "153"},
              {"label": "City Municipality of Celje", "value": "011"},
              {"label": "City Municipality of Novo Mesto", "value": "085"},
              {"label": "Crensovci Municipality", "value": "015"},
              {"label": "Crna na Koroskem Municipality", "value": "016"},
              {"label": "Crnomelj Municipality", "value": "017"},
              {"label": "Destrnik Municipality", "value": "018"},
              {"label": "Divaca Municipality", "value": "019"},
              {"label": "Dobje Municipality", "value": "154"},
              {"label": "Dobrepolje Municipality", "value": "020"},
              {"label": "Dobrna Municipality", "value": "155"},
              {"label": "Dobrova-Polhov Gradec Municipality", "value": "021"},
              {"label": "Dobrovnik Municipality", "value": "156"},
              {"label": "Dol pri Ljubljani Municipality", "value": "022"},
              {"label": "Dolenjske Toplice Municipality", "value": "157"},
              {"label": "Domzale Municipality", "value": "023"},
              {"label": "Dornava Municipality", "value": "024"},
              {"label": "Dravograd Municipality", "value": "025"},
              {"label": "Duplek Municipality", "value": "026"},
              {"label": "Gorenja Vas-Poljane Municipality", "value": "027"},
              {"label": "Gorisnica Municipality", "value": "028"},
              {"label": "Gornja Radgona Municipality", "value": "029"},
              {"label": "Gornji Grad Municipality", "value": "030"},
              {"label": "Gornji Petrovci Municipality", "value": "031"},
              {"label": "Grad Municipality", "value": "158"},
              {"label": "Grosuplje Municipality", "value": "032"},
              {"label": "Hajdina Municipality", "value": "159"},
              {"label": "Hoce-Slivnica Municipality", "value": "160"},
              {"label": "Hodos Municipality", "value": "161"},
              {"label": "Horjul Municipality", "value": "162"},
              {"label": "Hrastnik Municipality", "value": "034"},
              {"label": "Hrpelje-Kozina Municipality", "value": "035"},
              {"label": "Idrija Municipality", "value": "036"},
              {"label": "Ig Municipality", "value": "037"},
              {"label": "Ivancna Gorica Municipality", "value": "039"},
              {"label": "Izola Municipality", "value": "040"},
              {"label": "Jesenice Municipality", "value": "041"},
              {"label": "Jezersko Municipality", "value": "163"},
              {"label": "Jursinci Municipality", "value": "042"},
              {"label": "Kamnik Municipality", "value": "043"},
              {"label": "Kanal ob Soci Municipality", "value": "044"},
              {"label": "Kidricevo Municipality", "value": "045"},
              {"label": "Kobarid Municipality", "value": "046"},
              {"label": "Kobilje Municipality", "value": "047"},
              {"label": "Kocevje Municipality", "value": "048"},
              {"label": "Komen Municipality", "value": "049"},
              {"label": "Komenda Municipality", "value": "164"},
              {"label": "Koper City Municipality", "value": "050"},
              {"label": "Kostel Municipality", "value": "165"},
              {"label": "Kozje Municipality", "value": "051"},
              {"label": "Kranj City Municipality", "value": "052"},
              {"label": "Kranjska Gora Municipality", "value": "053"},
              {"label": "Krizevci Municipality", "value": "166"},
              {"label": "Kungota", "value": "055"},
              {"label": "Kuzma Municipality", "value": "056"},
              {"label": "Lasko Municipality", "value": "057"},
              {"label": "Lenart Municipality", "value": "058"},
              {"label": "Lendava Municipality", "value": "059"},
              {"label": "Litija Municipality", "value": "060"},
              {"label": "Ljubljana City Municipality", "value": "061"},
              {"label": "Ljubno Municipality", "value": "062"},
              {"label": "Ljutomer Municipality", "value": "063"},
              {"label": "Logatec Municipality", "value": "064"},
              {"label": "Loska Dolina Municipality", "value": "065"},
              {"label": "Loski Potok Municipality", "value": "066"},
              {"label": "Lovrenc na Pohorju Municipality", "value": "167"},
              {"label": "Luce Municipality", "value": "067"},
              {"label": "Lukovica Municipality", "value": "068"},
              {"label": "Majsperk Municipality", "value": "069"},
              {"label": "Maribor City Municipality", "value": "070"},
              {"label": "Markovci Municipality", "value": "168"},
              {"label": "Medvode Municipality", "value": "071"},
              {"label": "Menges Municipality", "value": "072"},
              {"label": "Metlika Municipality", "value": "073"},
              {"label": "Mezica Municipality", "value": "074"},
              {"label": "Miklavz na Dravskem Polju Municipality",
               "value": "169"},
              {"label": "Miren-Kostanjevica Municipality", "value": "075"},
              {"label": "Mirna Pec Municipality", "value": "170"},
              {"label": "Mislinja Municipality", "value": "076"},
              {"label": "Moravce Municipality", "value": "077"},
              {"label": "Moravske Toplice Municipality", "value": "078"},
              {"label": "Mozirje Municipality", "value": "079"},
              {"label": "Municipality of Apace", "value": "195"},
              {"label": "Municipality of Ilirska Bistrica", "value": "038"},
              {"label": "Municipality of Krsko", "value": "054"},
              {"label": "Municipality of Skofljica", "value": "123"},
              {"label": "Murska Sobota City Municipality", "value": "080"},
              {"label": "Muta Municipality", "value": "081"},
              {"label": "Naklo Municipality", "value": "082"},
              {"label": "Nazarje Municipality", "value": "083"},
              {"label": "Nova Gorica City Municipality", "value": "084"},
              {"label": "Odranci Municipality", "value": "086"},
              {"label": "Oplotnica", "value": "171"},
              {"label": "Ormoz Municipality", "value": "087"},
              {"label": "Osilnica Municipality", "value": "088"},
              {"label": "Pesnica Municipality", "value": "089"},
              {"label": "Piran Municipality", "value": "090"},
              {"label": "Pivka Municipality", "value": "091"},
              {"label": "Podcetrtek Municipality", "value": "092"},
              {"label": "Podlehnik Municipality", "value": "172"},
              {"label": "Podvelka Municipality", "value": "093"},
              {"label": "Polzela Municipality", "value": "173"},
              {"label": "Postojna Municipality", "value": "094"},
              {"label": "Prebold Municipality", "value": "174"},
              {"label": "Preddvor Municipality", "value": "095"},
              {"label": "Prevalje Municipality", "value": "175"},
              {"label": "Ptuj City Municipality", "value": "096"},
              {"label": "Puconci Municipality", "value": "097"},
              {"label": "Race-Fram Municipality", "value": "098"},
              {"label": "Radece Municipality", "value": "099"},
              {"label": "Radenci Municipality", "value": "100"},
              {"label": "Radlje ob Dravi Municipality", "value": "101"},
              {"label": "Radovljica Municipality", "value": "102"},
              {"label": "Ravne na Koroskem Municipality", "value": "103"},
              {"label": "Razkrizje Municipality", "value": "176"},
              {"label": "Rence-Vogrsko Municipality", "value": "201"},
              {"label": "Ribnica Municipality", "value": "104"},
              {"label": "Ribnica na Pohorju Municipality", "value": "177"},
              {"label": "Rogaska Slatina Municipality", "value": "106"},
              {"label": "Rogasovci Municipality", "value": "105"},
              {"label": "Rogatec Municipality", "value": "107"},
              {"label": "Ruse Municipality", "value": "108"},
              {"label": "Salovci Municipality", "value": "033"},
              {"label": "Selnica ob Dravi Municipality", "value": "178"},
              {"label": "Semic Municipality", "value": "109"},
              {"label": "Sempeter-Vrtojba Municipality", "value": "183"},
              {"label": "Sencur Municipality", "value": "117"},
              {"label": "Sentilj Municipality", "value": "118"},
              {"label": "Sentjernej Municipality", "value": "119"},
              {"label": "Sentjur Municipality", "value": "120"},
              {"label": "Sentrupert Municipality", "value": "211"},
              {"label": "Sevnica Municipality", "value": "110"},
              {"label": "Sezana Municipality", "value": "111"},
              {"label": "Skocjan Municipality", "value": "121"},
              {"label": "Skofja Loka Municipality", "value": "122"},
              {"label": "Slovenj Gradec City Municipality", "value": "112"},
              {"label": "Slovenska Bistrica Municipality", "value": "113"},
              {"label": "Slovenske Konjice Municipality", "value": "114"},
              {"label": "Smarje pri Jelsah Municipality", "value": "124"},
              {"label": "Smarjeske Toplice Municipality", "value": "206"},
              {"label": "Smartno ob Paki Municipality", "value": "125"},
              {"label": "Smartno pri Litiji Municipality", "value": "194"},
              {"label": "Sodrazica Municipality", "value": "179"},
              {"label": "Solcava Municipality", "value": "180"},
              {"label": "Sostanj Municipality", "value": "126"},
              {"label": "Sredisce ob Dravi", "value": "202"},
              {"label": "Starse Municipality", "value": "115"},
              {"label": "Store Municipality", "value": "127"},
              {"label": "Straza Municipality", "value": "203"},
              {"label": "Sveta Ana Municipality", "value": "181"},
              {"label": "Sveta Trojica v Slovenskih Goricah Municipality",
               "value": "204"},
              {"label": "Sveti Andraz v Slovenskih Goricah Municipality",
               "value": "182"},
              {"label": "Sveti Jurij v Slovenskih Goricah Municipality",
               "value": "210"},
              {"label": "Sveti Tomaz Municipality", "value": "205"},
              {"label": "Tabor Municipality", "value": "184"},
              {"label": "Tisina Municipality", "value": "010"},
              {"label": "Tolmin Municipality", "value": "128"},
              {"label": "Trbovlje Municipality", "value": "129"},
              {"label": "Trebnje Municipality", "value": "130"},
              {"label": "Trnovska Vas Municipality", "value": "185"},
              {"label": "Trzic Municipality", "value": "131"},
              {"label": "Trzin Municipality", "value": "186"},
              {"label": "Turnisce Municipality", "value": "132"},
              {"label": "Velika Polana Municipality", "value": "187"},
              {"label": "Velike Lasce Municipality", "value": "134"},
              {"label": "Verzej Municipality", "value": "188"},
              {"label": "Videm Municipality", "value": "135"},
              {"label": "Vipava Municipality", "value": "136"},
              {"label": "Vitanje Municipality", "value": "137"},
              {"label": "Vodice Municipality", "value": "138"},
              {"label": "Vojnik Municipality", "value": "139"},
              {"label": "Vransko Municipality", "value": "189"},
              {"label": "Vrhnika Municipality", "value": "140"},
              {"label": "Vuzenica Municipality", "value": "141"},
              {"label": "Zagorje ob Savi Municipality", "value": "142"},
              {"label": "Zalec Municipality", "value": "190"},
              {"label": "Zavrc Municipality", "value": "143"},
              {"label": "Zelezniki Municipality", "value": "146"},
              {"label": "Zetale Municipality", "value": "191"},
              {"label": "Ziri Municipality", "value": "147"},
              {"label": "Zirovnica Municipality", "value": "192"},
              {"label": "Zrece Municipality", "value": "144"},
              {"label": "Zuzemberk Municipality", "value": "193"}],
 "Solomon Islands": [{"label": "Central Province", "value": "CE"},
                     {"label": "Guadalcanal Province", "value": "GU"},
                     {"label": "Isabel Province", "value": "IS"},
                     {"label": "Makira-Ulawa Province", "value": "MK"},
                     {"label": "Malaita Province", "value": "ML"},
                     {"label": "Temotu Province", "value": "TE"},
                     {"label": "Western Province", "value": "WE"}],
 "Somalia": [{"label": "Bakool", "value": "BK"},
             {"label": "Banaadir", "value": "BN"},
             {"label": "Bari", "value": "BR"},
             {"label": "Bay", "value": "BY"},
             {"label": "Galguduud", "value": "GA"},
             {"label": "Gedo", "value": "GE"},
             {"label": "Hiran", "value": "HI"},
             {"label": "Lower Juba", "value": "JH"},
             {"label": "Lower Shebelle", "value": "SH"},
             {"label": "Middle Juba", "value": "JD"},
             {"label": "Middle Shebelle", "value": "SD"},
             {"label": "Mudug", "value": "MU"},
             {"label": "Nugal", "value": "NU"},
             {"label": "Sanaag Region", "value": "SA"},
             {"label": "Togdheer Region", "value": "TO"}],
 "South Africa": [{"label": "Eastern Cape", "value": "EC"},
                  {"label": "Free State", "value": "FS"},
                  {"label": "Gauteng", "value": "GP"},
                  {"label": "KwaZulu-Natal", "value": "KZN"},
                  {"label": "Limpopo", "value": "LP"},
                  {"label": "Mpumalanga", "value": "MP"},
                  {"label": "North West", "value": "NW"},
                  {"label": "Northern Cape", "value": "NC"},
                  {"label": "Western Cape", "value": "WC"}],
 "South Korea": [{"label": "Busan", "value": "26"},
                 {"label": "Daegu", "value": "27"},
                 {"label": "Daejeon", "value": "30"},
                 {"label": "Gangwon Province", "value": "42"},
                 {"label": "Gwangju", "value": "29"},
                 {"label": "Gyeonggi Province", "value": "41"},
                 {"label": "Incheon", "value": "28"},
                 {"label": "Jeju", "value": "49"},
                 {"label": "North Chungcheong Province", "value": "43"},
                 {"label": "North Gyeongsang Province", "value": "47"},
                 {"label": "North Jeolla Province", "value": "45"},
                 {"label": "Sejong City", "value": "50"},
                 {"label": "Seoul", "value": "11"},
                 {"label": "South Chungcheong Province", "value": "44"},
                 {"label": "South Gyeongsang Province", "value": "48"},
                 {"label": "South Jeolla Province", "value": "46"},
                 {"label": "Ulsan", "value": "31"}],
 "South Sudan": [{"label": "Lakes", "value": "LK"}],
 "Spain": [{"label": "Andalusia", "value": "AN"},
           {"label": "Aragon", "value": "AR"},
           {"label": "Balearic Islands", "value": "PM"},
           {"label": "Basque Country", "value": "PV"},
           {"label": "Canary Islands", "value": "CN"},
           {"label": "Cantabria", "value": "S"},
           {"label": "Castilla La Mancha", "value": "CM"},
           {"label": "Catalonia", "value": "CT"},
           {"label": "Ceuta", "value": "CE"},
           {"label": "Extremadura", "value": "EX"},
           {"label": "Galicia", "value": "GA"},
           {"label": "La Rioja", "value": "RI"},
           {"label": "Leon", "value": "LE"},
           {"label": "Madrid", "value": "MD"},
           {"label": "Melilla", "value": "ML"},
           {"label": "Murcia", "value": "MC"},
           {"label": "Navarra", "value": "NC"},
           {"label": "Valencia", "value": "VC"}],
 "Sri Lanka": [{"label": "Central Province", "value": "2"},
               {"label": "Colombo District", "value": "11"},
               {"label": "Eastern Province", "value": "5"},
               {"label": "Kandy District", "value": "21"},
               {"label": "North Central Province", "value": "7"},
               {"label": "North Western Province", "value": "6"},
               {"label": "Northern Province", "value": "4"},
               {"label": "Sabaragamuwa Province", "value": "9"},
               {"label": "Southern Province", "value": "3"},
               {"label": "Uva Province", "value": "8"},
               {"label": "Western Province", "value": "1"}],
 "Sudan": [{"label": "Al Jazirah", "value": "GZ"},
           {"label": "Al Qadarif", "value": "GD"},
           {"label": "Blue Nile", "value": "NB"},
           {"label": "Central Darfur", "value": "DC"},
           {"label": "East Darfur", "value": "DE"},
           {"label": "Kassala", "value": "KA"},
           {"label": "Khartoum", "value": "KH"},
           {"label": "North Darfur", "value": "DN"},
           {"label": "North Kordofan", "value": "KN"},
           {"label": "Northern", "value": "NO"},
           {"label": "Red Sea", "value": "RS"},
           {"label": "River Nile", "value": "NR"},
           {"label": "Sennar", "value": "SI"},
           {"label": "South Darfur", "value": "DS"},
           {"label": "South Kordofan", "value": "KS"},
           {"label": "West Darfur", "value": "DW"},
           {"label": "West Kordofan", "value": "GK"},
           {"label": "White Nile", "value": "NW"}],
 "Suriname": [{"label": "Brokopondo District", "value": "BR"},
              {"label": "Commewijne District", "value": "CM"},
              {"label": "Coronie District", "value": "CR"},
              {"label": "Marowijne District", "value": "MA"},
              {"label": "Nickerie District", "value": "NI"},
              {"label": "Para District", "value": "PR"},
              {"label": "Paramaribo District", "value": "PM"},
              {"label": "Saramacca District", "value": "SA"},
              {"label": "Wanica District", "value": "WA"}],
 "Swaziland": [{"label": "Hhohho District", "value": "HH"},
               {"label": "Lubombo District", "value": "LU"},
               {"label": "Manzini District", "value": "MA"},
               {"label": "Shiselweni District", "value": "SH"}],
 "Sweden": [{"label": "Blekinge", "value": "K"},
            {"label": "Dalarna County", "value": "W"},
            {"label": "Gavleborg County", "value": "X"},
            {"label": "Gotland County", "value": "I"},
            {"label": "Halland County", "value": "N"},
            {"label": "Jonkoping County", "value": "F"},
            {"label": "Kalmar County", "value": "H"},
            {"label": "Kronoberg County", "value": "G"},
            {"label": "Norrbotten County", "value": "BD"},
            {"label": "Orebro County", "value": "T"},
            {"label": "Ostergotland County", "value": "E"},
            {"label": "Skane County", "value": "M"},
            {"label": "Sodermanland County", "value": "D"},
            {"label": "Stockholm County", "value": "AB"},
            {"label": "Uppsala County", "value": "C"},
            {"label": "Varmland County", "value": "S"},
            {"label": "Vasterbotten County", "value": "AC"},
            {"label": "Vasternorrland County", "value": "Y"},
            {"label": "Vastmanland County", "value": "U"},
            {"label": "Vastra Gotaland County", "value": "O"}],
 "Switzerland": [{"label": "Aargau", "value": "AG"},
                 {"label": "Appenzell Ausserrhoden", "value": "AR"},
                 {"label": "Appenzell Innerrhoden", "value": "AI"},
                 {"label": "Basel-Land", "value": "BL"},
                 {"label": "Basel-Stadt", "value": "BS"},
                 {"label": "Bern", "value": "BE"},
                 {"label": "Fribourg", "value": "FR"},
                 {"label": "Geneva", "value": "GE"},
                 {"label": "Glarus", "value": "GL"},
                 {"label": "Graubunden", "value": "GR"},
                 {"label": "Jura", "value": "JU"},
                 {"label": "Lucerne", "value": "LU"},
                 {"label": "Neuchatel", "value": "NE"},
                 {"label": "Nidwalden", "value": "NW"},
                 {"label": "Obwalden", "value": "OW"},
                 {"label": "Schaffhausen", "value": "SH"},
                 {"label": "Schwyz", "value": "SZ"},
                 {"label": "Solothurn", "value": "SO"},
                 {"label": "St. Gallen", "value": "SG"},
                 {"label": "Thurgau", "value": "TG"},
                 {"label": "Ticino", "value": "TI"},
                 {"label": "Uri", "value": "UR"},
                 {"label": "Valais", "value": "VS"},
                 {"label": "Vaud", "value": "VD"},
                 {"label": "Zug", "value": "ZG"},
                 {"label": "Zurich", "value": "ZH"}],
 "Syria": [{"label": "Al-Hasakah Governorate", "value": "HA"},
           {"label": "Al-Raqqah Governorate", "value": "RA"},
           {"label": "Aleppo Governorate", "value": "HL"},
           {"label": "As-Suwayda Governorate", "value": "SU"},
           {"label": "Damascus Governorate", "value": "DI"},
           {"label": "Daraa Governorate", "value": "DR"},
           {"label": "Deir ez-Zor Governorate", "value": "DY"},
           {"label": "Hama Governorate", "value": "HM"},
           {"label": "Homs Governorate", "value": "HI"},
           {"label": "Idlib Governorate", "value": "ID"},
           {"label": "Latakia Governorate", "value": "LA"},
           {"label": "Quneitra Governorate", "value": "QU"},
           {"label": "Rif Dimashq Governorate", "value": "RD"},
           {"label": "Tartus Governorate", "value": "TA"}],
 "Taiwan": [{"label": "Changhua", "value": "CHA"},
            {"label": "Chiayi", "value": "CYI"},
            {"label": "Chiayi", "value": "CYQ"},
            {"label": "Hsinchu", "value": "HSQ"},
            {"label": "Hsinchu", "value": "HSZ"},
            {"label": "Hualien", "value": "HUA"},
            {"label": "Kaohsiung", "value": "KHH"},
            {"label": "Kinmen", "value": "KIN"},
            {"label": "Lienchiang", "value": "LIE"},
            {"label": "Miaoli", "value": "MIA"},
            {"label": "Nantou", "value": "NAN"},
            {"label": "Penghu", "value": "PEN"},
            {"label": "Pingtung", "value": "PIF"},
            {"label": "Taichung", "value": "TXG"},
            {"label": "Tainan", "value": "TNN"},
            {"label": "Taipei", "value": "TPE"},
            {"label": "Taitung", "value": "TTT"},
            {"label": "Taoyuan", "value": "TAO"},
            {"label": "Yilan", "value": "ILA"},
            {"label": "Yunlin", "value": "YUN"}],
 "Tajikistan": [{"label": "districts of Republican Subordination",
                 "value": "RA"},
                {"label": "Gorno-Badakhshan Autonomous Province",
                 "value": "GB"},
                {"label": "Khatlon Province", "value": "KT"},
                {"label": "Sughd Province", "value": "SU"}],
 "Tanzania": [{"label": "Arusha", "value": "01"},
              {"label": "Dar es Salaam", "value": "02"},
              {"label": "Dodoma", "value": "03"},
              {"label": "Geita", "value": "27"},
              {"label": "Iringa", "value": "04"},
              {"label": "Kagera", "value": "05"},
              {"label": "Katavi", "value": "28"},
              {"label": "Kigoma", "value": "08"},
              {"label": "Kilimanjaro", "value": "09"},
              {"label": "Lindi", "value": "12"},
              {"label": "Manyara", "value": "26"},
              {"label": "Mara", "value": "13"},
              {"label": "Mbeya", "value": "14"},
              {"label": "Morogoro", "value": "16"},
              {"label": "Mtwara", "value": "17"},
              {"label": "Mwanza", "value": "18"},
              {"label": "Njombe", "value": "29"},
              {"label": "Pemba North", "value": "06"},
              {"label": "Pemba South", "value": "10"},
              {"label": "Pwani", "value": "19"},
              {"label": "Rukwa", "value": "20"},
              {"label": "Ruvuma", "value": "21"},
              {"label": "Shinyanga", "value": "22"},
              {"label": "Simiyu", "value": "30"},
              {"label": "Singida", "value": "23"},
              {"label": "Tabora", "value": "24"},
              {"label": "Tanga", "value": "25"},
              {"label": "Zanzibar North", "value": "07"},
              {"label": "Zanzibar South", "value": "11"},
              {"label": "Zanzibar West", "value": "15"}],
 "Thailand": [{"label": "Amnat Charoen", "value": "37"},
              {"label": "Ang Thong", "value": "15"},
              {"label": "Bangkok", "value": "10"},
              {"label": "Bueng Kan", "value": "38"},
              {"label": "Buri Ram", "value": "31"},
              {"label": "Chachoengsao", "value": "24"},
              {"label": "Chai Nat", "value": "18"},
              {"label": "Chaiyaphum", "value": "36"},
              {"label": "Chanthaburi", "value": "22"},
              {"label": "Chiang Mai", "value": "50"},
              {"label": "Chiang Rai", "value": "57"},
              {"label": "Chon Buri", "value": "20"},
              {"label": "Chumphon", "value": "86"},
              {"label": "Kalasin", "value": "46"},
              {"label": "Kamphaeng Phet", "value": "62"},
              {"label": "Kanchanaburi", "value": "71"},
              {"label": "Khon Kaen", "value": "40"},
              {"label": "Krabi", "value": "81"},
              {"label": "Lampang", "value": "52"},
              {"label": "Lamphun", "value": "51"},
              {"label": "Loei", "value": "42"},
              {"label": "Lop Buri", "value": "16"},
              {"label": "Mae Hong Son", "value": "58"},
              {"label": "Maha Sarakham", "value": "44"},
              {"label": "Mukdahan", "value": "49"},
              {"label": "Nakhon Nayok", "value": "26"},
              {"label": "Nakhon Pathom", "value": "73"},
              {"label": "Nakhon Phanom", "value": "48"},
              {"label": "Nakhon Ratchasima", "value": "30"},
              {"label": "Nakhon Sawan", "value": "60"},
              {"label": "Nakhon Si Thammarat", "value": "80"},
              {"label": "Nan", "value": "55"},
              {"label": "Narathiwat", "value": "96"},
              {"label": "Nong Bua Lam Phu", "value": "39"},
              {"label": "Nong Khai", "value": "43"},
              {"label": "Nonthaburi", "value": "12"},
              {"label": "Pathum Thani", "value": "13"},
              {"label": "Pattani", "value": "94"},
              {"label": "Phangnga", "value": "82"},
              {"label": "Phatthalung", "value": "93"},
              {"label": "Phayao", "value": "56"},
              {"label": "Phetchabun", "value": "67"},
              {"label": "Phetchaburi", "value": "76"},
              {"label": "Phichit", "value": "66"},
              {"label": "Phitsanulok", "value": "65"},
              {"label": "Phra Nakhon Si Ayutthaya", "value": "14"},
              {"label": "Phrae", "value": "54"},
              {"label": "Phuket", "value": "83"},
              {"label": "Prachin Buri", "value": "25"},
              {"label": "Prachuap Khiri Khan", "value": "77"},
              {"label": "Ranong", "value": "85"},
              {"label": "Ratchaburi", "value": "70"},
              {"label": "Rayong", "value": "21"},
              {"label": "Roi Et", "value": "45"},
              {"label": "Sa Kaeo", "value": "27"},
              {"label": "Sakon Nakhon", "value": "47"},
              {"label": "Samut Prakan", "value": "11"},
              {"label": "Samut Sakhon", "value": "74"},
              {"label": "Samut Songkhram", "value": "75"},
              {"label": "Saraburi", "value": "19"},
              {"label": "Satun", "value": "91"},
              {"label": "Si Sa Ket", "value": "33"},
              {"label": "Sing Buri", "value": "17"},
              {"label": "Songkhla", "value": "90"},
              {"label": "Sukhothai", "value": "64"},
              {"label": "Suphan Buri", "value": "72"},
              {"label": "Surat Thani", "value": "84"},
              {"label": "Surin", "value": "32"},
              {"label": "Tak", "value": "63"},
              {"label": "Trang", "value": "92"},
              {"label": "Trat", "value": "23"},
              {"label": "Ubon Ratchathani", "value": "34"},
              {"label": "Udon Thani", "value": "41"},
              {"label": "Uthai Thani", "value": "61"},
              {"label": "Uttaradit", "value": "53"},
              {"label": "Yala", "value": "95"},
              {"label": "Yasothon", "value": "35"}],
 "The Bahamas": [{"label": "Bimini", "value": "BI"},
                 {"label": "Cat Island", "value": "CI"},
                 {"label": "Central Abaco", "value": "CO"},
                 {"label": "Crooked Island", "value": "CK"},
                 {"label": "East Grand Bahama", "value": "EG"},
                 {"label": "Exuma", "value": "EX"},
                 {"label": "Freeport", "value": "FP"},
                 {"label": "Harbour Island", "value": "HI"},
                 {"label": "Inagua", "value": "IN"},
                 {"label": "Long Island", "value": "LI"},
                 {"label": "Mayaguana District", "value": "MG"},
                 {"label": "New Providence", "value": "NP"},
                 {"label": "North Abaco", "value": "NO"},
                 {"label": "North Andros", "value": "NS"},
                 {"label": "Ragged Island", "value": "RI"},
                 {"label": "Rum Cay District", "value": "RC"},
                 {"label": "San Salvador Island", "value": "SS"},
                 {"label": "Spanish Wells", "value": "SW"},
                 {"label": "West Grand Bahama", "value": "WG"}],
 "Togo": [{"label": "Centrale Region", "value": "C"},
          {"label": "Kara Region", "value": "K"},
          {"label": "Maritime", "value": "M"},
          {"label": "Plateaux Region", "value": "P"},
          {"label": "Savanes Region", "value": "S"}],
 "Tonga": [{"label": "Ha`apai", "value": "02"},
           {"label": "`Eua", "value": "01"},
           {"label": "Niuas", "value": "03"},
           {"label": "Tongatapu", "value": "04"},
           {"label": "Vava`u", "value": "05"}],
 "Trinidad And Tobago": [{"label": "Arima", "value": "ARI"},
                         {"label": "Chaguanas", "value": "CHA"},
                         {"label": "Couva-Tabaquite-Talparo Regional Corporation",
                          "value": "CTT"},
                         {"label": "Diego Martin Regional Corporation",
                          "value": "DMN"},
                         {"label": "Eastern Tobago", "value": "ETO"},
                         {"label": "Penal-Debe Regional Corporation",
                          "value": "PED"},
                         {"label": "Point Fortin", "value": "PTF"},
                         {"label": "Port of Spain", "value": "POS"},
                         {"label": "Princes Town Regional Corporation",
                          "value": "PRT"},
                         {"label": "San Fernando", "value": "SFO"},
                         {"label": "San Juan-Laventille Regional Corporation",
                          "value": "SJL"},
                         {"label": "Sangre Grande Regional Corporation",
                          "value": "SGE"},
                         {"label": "Siparia Regional Corporation",
                          "value": "SIP"},
                         {"label": "Tunapuna-Piarco Regional Corporation",
                          "value": "TUP"},
                         {"label": "Western Tobago", "value": "WTO"}],
 "Tunisia": [{"label": "Ariana Governorate", "value": "12"},
             {"label": "Ben Arous Governorate", "value": "13"},
             {"label": "Bizerte Governorate", "value": "23"},
             {"label": "Gabes Governorate", "value": "81"},
             {"label": "Gafsa Governorate", "value": "71"},
             {"label": "Jendouba Governorate", "value": "32"},
             {"label": "Kairouan Governorate", "value": "41"},
             {"label": "Kasserine Governorate", "value": "42"},
             {"label": "Kassrine", "value": "31"},
             {"label": "Kebili Governorate", "value": "73"},
             {"label": "Kef Governorate", "value": "33"},
             {"label": "Mahdia Governorate", "value": "53"},
             {"label": "Manouba Governorate", "value": "14"},
             {"label": "Medenine Governorate", "value": "82"},
             {"label": "Monastir Governorate", "value": "52"},
             {"label": "Sfax Governorate", "value": "61"},
             {"label": "Sidi Bouzid Governorate", "value": "43"},
             {"label": "Siliana Governorate", "value": "34"},
             {"label": "Sousse Governorate", "value": "51"},
             {"label": "Tataouine Governorate", "value": "83"},
             {"label": "Tozeur Governorate", "value": "72"},
             {"label": "Tunis Governorate", "value": "11"},
             {"label": "Zaghouan Governorate", "value": "22"}],
 "Turkey": [{"label": "Adana", "value": "01"},
            {"label": "Adiyaman", "value": "02"},
            {"label": "Afyonkarahisar", "value": "03"},
            {"label": "Agri", "value": "04"},
            {"label": "Aksaray", "value": "68"},
            {"label": "Amasya", "value": "05"},
            {"label": "Ankara", "value": "06"},
            {"label": "Antalya", "value": "07"},
            {"label": "Ardahan", "value": "75"},
            {"label": "Artvin", "value": "08"},
            {"label": "Aydin", "value": "09"},
            {"label": "Balikesir", "value": "10"},
            {"label": "Bartin", "value": "74"},
            {"label": "Batman", "value": "72"},
            {"label": "Bayburt", "value": "69"},
            {"label": "Bilecik", "value": "11"},
            {"label": "Bingol", "value": "12"},
            {"label": "Bitlis", "value": "13"},
            {"label": "Bolu", "value": "14"},
            {"label": "Burdur", "value": "15"},
            {"label": "Bursa", "value": "16"},
            {"label": "Canakkale", "value": "17"},
            {"label": "Cankiri", "value": "18"},
            {"label": "Corum", "value": "19"},
            {"label": "Denizli", "value": "20"},
            {"label": "Diyarbakir", "value": "21"},
            {"label": "Duzce", "value": "81"},
            {"label": "Edirne", "value": "22"},
            {"label": "Elazig", "value": "23"},
            {"label": "Erzincan", "value": "24"},
            {"label": "Erzurum", "value": "25"},
            {"label": "Eskisehir", "value": "26"},
            {"label": "Gaziantep", "value": "27"},
            {"label": "Giresun", "value": "28"},
            {"label": "Gumushane", "value": "29"},
            {"label": "Hakkari", "value": "30"},
            {"label": "Hatay", "value": "31"},
            {"label": "Igdir", "value": "76"},
            {"label": "Isparta", "value": "32"},
            {"label": "Istanbul", "value": "34"},
            {"label": "Izmir", "value": "35"},
            {"label": "Kahramanmaras", "value": "46"},
            {"label": "Karabuk", "value": "78"},
            {"label": "Karaman", "value": "70"},
            {"label": "Kars", "value": "36"},
            {"label": "Kastamonu", "value": "37"},
            {"label": "Kayseri", "value": "38"},
            {"label": "Kilis", "value": "79"},
            {"label": "Kirikkale", "value": "71"},
            {"label": "Kirklareli", "value": "39"},
            {"label": "Kirsehir", "value": "40"},
            {"label": "Kocaeli", "value": "41"},
            {"label": "Konya", "value": "42"},
            {"label": "Kutahya", "value": "43"},
            {"label": "Malatya", "value": "44"},
            {"label": "Manisa", "value": "45"},
            {"label": "Mardin", "value": "47"},
            {"label": "Mersin", "value": "33"},
            {"label": "Mugla", "value": "48"},
            {"label": "Mus", "value": "49"},
            {"label": "Nevsehir", "value": "50"},
            {"label": "Nigde", "value": "51"},
            {"label": "Ordu", "value": "52"},
            {"label": "Osmaniye", "value": "80"},
            {"label": "Rize", "value": "53"},
            {"label": "Sakarya", "value": "54"},
            {"label": "Samsun", "value": "55"},
            {"label": "Sanliurfa", "value": "63"},
            {"label": "Siirt", "value": "56"},
            {"label": "Sinop", "value": "57"},
            {"label": "Sivas", "value": "58"},
            {"label": "Sirnak", "value": "73"},
            {"label": "Tekirdag", "value": "59"},
            {"label": "Tokat", "value": "60"},
            {"label": "Trabzon", "value": "61"},
            {"label": "Tunceli", "value": "62"},
            {"label": "Usak", "value": "64"},
            {"label": "Van", "value": "65"},
            {"label": "Yalova", "value": "77"},
            {"label": "Yozgat", "value": "66"},
            {"label": "Zonguldak", "value": "67"}],
 "Turkmenistan": [{"label": "Ahal Region", "value": "A"},
                  {"label": "Ashgabat", "value": "S"},
                  {"label": "Balkan Region", "value": "B"},
                  {"label": "Dasoguz Region", "value": "D"},
                  {"label": "Lebap Region", "value": "L"},
                  {"label": "Mary Region", "value": "M"}],
 "Tuvalu": [{"label": "Funafuti", "value": "FUN"},
            {"label": "Nanumanga", "value": "NMG"},
            {"label": "Niutao Island Council", "value": "NIT"},
            {"label": "Nui", "value": "NUI"},
            {"label": "Nukufetau", "value": "NKF"},
            {"label": "Vaitupu", "value": "VAI"}],
 "Uganda": [{"label": "Central Region", "value": "C"},
            {"label": "Eastern Region", "value": "E"},
            {"label": "Northern Region", "value": "N"},
            {"label": "Western Region", "value": "W"}],
 "Ukraine": [{"label": "Autonomous Republic of Crimea", "value": "43"},
             {"label": "Cherkaska oblast", "value": "71"},
             {"label": "Chernihivska oblast", "value": "74"},
             {"label": "Chernivetska oblast", "value": "77"},
             {"label": "Dnipropetrovska oblast", "value": "12"},
             {"label": "Donetska oblast", "value": "14"},
             {"label": "Ivano-Frankivska oblast", "value": "26"},
             {"label": "Kharkivska oblast", "value": "63"},
             {"label": "Khersonska oblast", "value": "65"},
             {"label": "Khmelnytska oblast", "value": "68"},
             {"label": "Kirovohradska oblast", "value": "35"},
             {"label": "Kyiv", "value": "30"},
             {"label": "Kyivska oblast", "value": "32"},
             {"label": "Luhanska oblast", "value": "09"},
             {"label": "Lvivska oblast", "value": "46"},
             {"label": "Mykolaivska oblast", "value": "48"},
             {"label": "Odeska oblast", "value": "51"},
             {"label": "Poltavska oblast", "value": "53"},
             {"label": "Rivnenska oblast", "value": "56"},
             {"label": "Sumska oblast", "value": "59"},
             {"label": "Ternopilska oblast", "value": "61"},
             {"label": "Vinnytska oblast", "value": "05"},
             {"label": "Volynska oblast", "value": "07"},
             {"label": "Zakarpatska Oblast", "value": "21"},
             {"label": "Zaporizka oblast", "value": "23"},
             {"label": "Zhytomyrska oblast", "value": "18"}],
 "United Arab Emirates": [{"label": "Abu Dhabi Emirate", "value": "AZ"},
                          {"label": "Ajman Emirate", "value": "AJ"},
                          {"label": "Dubai", "value": "DU"},
                          {"label": "Fujairah", "value": "FU"},
                          {"label": "Ras al-Khaimah", "value": "RK"},
                          {"label": "Sharjah Emirate", "value": "SH"},
                          {"label": "Umm al-Quwain", "value": "UQ"}],
 "United Kingdom": [{"label": "England", "value": "ENG"},
                    {"label": "Northern Ireland", "value": "NYK"},
                    {"label": "Scotland", "value": "SCT"},
                    {"label": "Wales", "value": "WLS"}],
 "United States": [{"label": "Alabama", "value": "AL"},
                   {"label": "Alaska", "value": "AK"},
                   {"label": "Arizona", "value": "AZ"},
                   {"label": "Arkansas", "value": "AR"},
                   {"label": "California", "value": "CA"},
                   {"label": "Colorado", "value": "CO"},
                   {"label": "Connecticut", "value": "CT"},
                   {"label": "Delaware", "value": "DE"},
                   {"label": "District of Columbia", "value": "DC"},
                   {"label": "Florida", "value": "FL"},
                   {"label": "Georgia", "value": "GA"},
                   {"label": "Hawaii", "value": "HI"},
                   {"label": "Idaho", "value": "ID"},
                   {"label": "Illinois", "value": "IL"},
                   {"label": "Indiana", "value": "IN"},
                   {"label": "Iowa", "value": "IA"},
                   {"label": "Kansas", "value": "KS"},
                   {"label": "Kentucky", "value": "KY"},
                   {"label": "Louisiana", "value": "LA"},
                   {"label": "Maine", "value": "ME"},
                   {"label": "Maryland", "value": "MD"},
                   {"label": "Massachusetts", "value": "MA"},
                   {"label": "Michigan", "value": "MI"},
                   {"label": "Minnesota", "value": "MN"},
                   {"label": "Mississippi", "value": "MS"},
                   {"label": "Missouri", "value": "MO"},
                   {"label": "Montana", "value": "MT"},
                   {"label": "Nebraska", "value": "NE"},
                   {"label": "Nevada", "value": "NV"},
                   {"label": "New Hampshire", "value": "NH"},
                   {"label": "New Jersey", "value": "NJ"},
                   {"label": "New Mexico", "value": "NM"},
                   {"label": "New York", "value": "NY"},
                   {"label": "North Carolina", "value": "NC"},
                   {"label": "North Dakota", "value": "ND"},
                   {"label": "Ohio", "value": "OH"},
                   {"label": "Oklahoma", "value": "OK"},
                   {"label": "Oregon", "value": "OR"},
                   {"label": "Pennsylvania", "value": "PA"},
                   {"label": "Puerto Rico", "value": "PR"},
                   {"label": "Rhode Island", "value": "RI"},
                   {"label": "South Carolina", "value": "SC"},
                   {"label": "South Dakota", "value": "SD"},
                   {"label": "Tennessee", "value": "TN"},
                   {"label": "Texas", "value": "TX"},
                   {"label": "Utah", "value": "UT"},
                   {"label": "Vermont", "value": "VT"},
                   {"label": "Virginia", "value": "VA"},
                   {"label": "Washington", "value": "WA"},
                   {"label": "West Virginia", "value": "WV"},
                   {"label": "Wisconsin", "value": "WI"},
                   {"label": "Wyoming", "value": "WY"}],
 "Uruguay": [{"label": "Artigas Department", "value": "AR"},
             {"label": "Canelones Department", "value": "CA"},
             {"label": "Cerro Largo Department", "value": "CL"},
             {"label": "Colonia Department", "value": "CO"},
             {"label": "Durazno Department", "value": "DU"},
             {"label": "Flores Department", "value": "FS"},
             {"label": "Florida Department", "value": "FD"},
             {"label": "Lavalleja Department", "value": "LA"},
             {"label": "Maldonado Department", "value": "MA"},
             {"label": "Montevideo Department", "value": "MO"},
             {"label": "Paysandu Department", "value": "PA"},
             {"label": "Rio Negro Department", "value": "RN"},
             {"label": "Rivera Department", "value": "RV"},
             {"label": "Rocha Department", "value": "RO"},
             {"label": "Salto Department", "value": "SA"},
             {"label": "San Jose Department", "value": "SJ"},
             {"label": "Soriano Department", "value": "SO"},
             {"label": "Tacuarembo Department", "value": "TA"},
             {"label": "Treinta y Tres Department", "value": "TT"}],
 "Uzbekistan": [{"label": "Andijan Region", "value": "AN"},
                {"label": "Bukhara Region", "value": "BU"},
                {"label": "Fergana Region", "value": "FA"},
                {"label": "Jizzakh Region", "value": "JI"},
                {"label": "Karakalpakstan", "value": "QR"},
                {"label": "Namangan Region", "value": "NG"},
                {"label": "Navoiy Region", "value": "NW"},
                {"label": "Qashqadaryo Region", "value": "QA"},
                {"label": "Samarqand Region", "value": "SA"},
                {"label": "Sirdaryo Region", "value": "SI"},
                {"label": "Surxondaryo Region", "value": "SU"},
                {"label": "Tashkent", "value": "TK"},
                {"label": "Tashkent Region", "value": "TO"},
                {"label": "Xorazm Region", "value": "XO"}],
 "Vanuatu": [{"label": "Malampa", "value": "MAP"},
             {"label": "Sanma", "value": "SAM"},
             {"label": "Shefa", "value": "SEE"},
             {"label": "Tafea", "value": "TAE"},
             {"label": "Torba", "value": "TOB"}],
 "Venezuela": [{"label": "Amazonas", "value": "Z"},
               {"label": "Anzoategui", "value": "B"},
               {"label": "Aragua", "value": "D"},
               {"label": "Barinas", "value": "E"},
               {"label": "Bolivar", "value": "F"},
               {"label": "Carabobo", "value": "G"},
               {"label": "Cojedes", "value": "H"},
               {"label": "Delta Amacuro", "value": "Y"},
               {"label": "Distrito Capital", "value": "A"},
               {"label": "Falcon", "value": "I"},
               {"label": "Guarico", "value": "J"},
               {"label": "La Guaira", "value": "X"},
               {"label": "Lara", "value": "K"},
               {"label": "Merida", "value": "L"},
               {"label": "Miranda", "value": "M"},
               {"label": "Monagas", "value": "N"},
               {"label": "Nueva Esparta", "value": "O"},
               {"label": "Portuguesa", "value": "P"},
               {"label": "Sucre", "value": "R"},
               {"label": "Tachira", "value": "S"},
               {"label": "Trujillo", "value": "T"},
               {"label": "Yaracuy", "value": "U"},
               {"label": "Zulia", "value": "V"}],
 "Vietnam": [{"label": "An Giang", "value": "44"},
             {"label": "Ba Ria-Vung Tau", "value": "43"},
             {"label": "Bac Giang", "value": "54"},
             {"label": "Bac Kan", "value": "53"},
             {"label": "Bac Lieu", "value": "55"},
             {"label": "Bac Ninh", "value": "56"},
             {"label": "Ben Tre", "value": "50"},
             {"label": "Binh Duong", "value": "57"},
             {"label": "Binh Dinh", "value": "31"},
             {"label": "Binh Phuoc", "value": "58"},
             {"label": "Binh Thuan", "value": "40"},
             {"label": "Ca Mau", "value": "59"},
             {"label": "Can Tho", "value": "CT"},
             {"label": "Cao Bang", "value": "04"},
             {"label": "Da Nang", "value": "DN"},
             {"label": "Dak Lak", "value": "33"},
             {"label": "Dak Nong", "value": "72"},
             {"label": "Dien Bien", "value": "71"},
             {"label": "Dong Nai", "value": "39"},
             {"label": "Dong Thap", "value": "45"},
             {"label": "Gia Lai", "value": "30"},
             {"label": "Ha Giang", "value": "03"},
             {"label": "Ha Nam", "value": "63"},
             {"label": "Ha Noi", "value": "HN"},
             {"label": "Ha Tinh", "value": "23"},
             {"label": "Hai Duong", "value": "61"},
             {"label": "Hai Phong", "value": "HP"},
             {"label": "Hau Giang", "value": "73"},
             {"label": "Ho Chi Minh", "value": "SG"},
             {"label": "Hoa Binh", "value": "14"},
             {"label": "Hung Yen", "value": "66"},
             {"label": "Khanh Hoa", "value": "34"},
             {"label": "Kien Giang", "value": "47"},
             {"label": "Kon Tum", "value": "28"},
             {"label": "Lai Chau", "value": "01"},
             {"label": "Lam Dong", "value": "35"},
             {"label": "Lang Son", "value": "09"},
             {"label": "Lao Cai", "value": "02"},
             {"label": "Long An", "value": "41"},
             {"label": "Nam Dinh", "value": "67"},
             {"label": "Nghe An", "value": "22"},
             {"label": "Ninh Binh", "value": "18"},
             {"label": "Ninh Thuan", "value": "36"},
             {"label": "Phu Tho", "value": "68"},
             {"label": "Phu Yen", "value": "32"},
             {"label": "Quang Binh", "value": "24"},
             {"label": "Quang Nam", "value": "27"},
             {"label": "Quang Ngai", "value": "29"},
             {"label": "Quang Ninh", "value": "13"},
             {"label": "Quang Tri", "value": "25"},
             {"label": "Soc Trang", "value": "52"},
             {"label": "Son La", "value": "05"},
             {"label": "Tay Ninh", "value": "37"},
             {"label": "Thai Binh", "value": "20"},
             {"label": "Thai Nguyen", "value": "69"},
             {"label": "Thanh Hoa", "value": "21"},
             {"label": "Thua Thien-Hue", "value": "26"},
             {"label": "Tien Giang", "value": "46"},
             {"label": "Tra Vinh", "value": "51"},
             {"label": "Tuyen Quang", "value": "07"},
             {"label": "Vinh Long", "value": "49"},
             {"label": "Vinh Phuc", "value": "70"},
             {"label": "Yen Bai", "value": "06"}],
 "Virgin Islands (US)": [{"label": "Saint Croix", "value": "SC"},
                         {"label": "Saint John", "value": "SJ"},
                         {"label": "Saint Thomas", "value": "ST"}],
 "Yemen": [{"label": "Adan Governorate", "value": "AD"},
           {"label": "Amran Governorate", "value": "AM"},
           {"label": "Abyan Governorate", "value": "AB"},
           {"label": "Al Bayda Governorate", "value": "BA"},
           {"label": "Al Hudaydah Governorate", "value": "HU"},
           {"label": "Al Jawf Governorate", "value": "JA"},
           {"label": "Al Mahrah Governorate", "value": "MR"},
           {"label": "Al Mahwit Governorate", "value": "MW"},
           {"label": "Dhamar Governorate", "value": "DH"},
           {"label": "Hadhramaut Governorate", "value": "HD"},
           {"label": "Hajjah Governorate", "value": "HJ"},
           {"label": "Ibb Governorate", "value": "IB"},
           {"label": "Lahij Governorate", "value": "LA"},
           {"label": "Marib Governorate", "value": "MA"},
           {"label": "Raymah Governorate", "value": "RA"},
           {"label": "Saada Governorate", "value": "SD"},
           {"label": "Sanaa Governorate", "value": "SN"},
           {"label": "Shabwah Governorate", "value": "SH"},
           {"label": "Socotra Governorate", "value": "SU"},
           {"label": "Taizz Governorate", "value": "TA"}],
 "Zambia": [{"label": "Central Province", "value": "02"},
            {"label": "Copperbelt Province", "value": "08"},
            {"label": "Eastern Province", "value": "03"},
            {"label": "Luapula Province", "value": "04"},
            {"label": "Lusaka Province", "value": "09"},
            {"label": "Muchinga Province", "value": "10"},
            {"label": "Northern Province", "value": "05"},
            {"label": "Northwestern Province", "value": "06"},
            {"label": "Southern Province", "value": "07"},
            {"label": "Western Province", "value": "01"}],
 "Zimbabwe": [{"label": "Bulawayo Province", "value": "BU"},
              {"label": "Harare Province", "value": "HA"},
              {"label": "Manicaland", "value": "MA"},
              {"label": "Mashonaland Central Province", "value": "MC"},
              {"label": "Mashonaland East Province", "value": "ME"},
              {"label": "Mashonaland West Province", "value": "MW"},
              {"label": "Masvingo Province", "value": "MV"},
              {"label": "Matabeleland North Province", "value": "MN"},
              {"label": "Matabeleland South Province", "value": "MS"},
              {"label": "Midlands Province", "value": "MI"}]}

export const citiesMap = 
{"Aana": [{"label": "Fasitoouta", "value": "C106225"},
          {"label": "Leulumoega", "value": "C106226"},
          {"label": "Nofoalii", "value": "C106227"},
          {"label": "Satapuala", "value": "C106228"}],
 "Aargau": [{"label": "Aarau", "value": "C116307"},
            {"label": "Aarburg", "value": "C116308"},
            {"label": "Aristau", "value": "C116309"},
            {"label": "Auw", "value": "C116310"},
            {"label": "Bad Zurzach", "value": "C116311"},
            {"label": "Baden", "value": "C116312"},
            {"label": "Berikon", "value": "C116313"},
            {"label": "Besenburen", "value": "C116314"},
            {"label": "Bezirk Aarau", "value": "C116315"},
            {"label": "Bezirk Baden", "value": "C116316"},
            {"label": "Bezirk Bremgarten", "value": "C116317"},
            {"label": "Bezirk Brugg", "value": "C116318"},
            {"label": "Bezirk Kulm", "value": "C116319"},
            {"label": "Bezirk Laufenburg", "value": "C116320"},
            {"label": "Bezirk Lenzburg", "value": "C116321"},
            {"label": "Bezirk Muri", "value": "C116322"},
            {"label": "Bezirk Rheinfelden", "value": "C116323"},
            {"label": "Bezirk Zofingen", "value": "C116324"},
            {"label": "Bezirk Zurzach", "value": "C116325"},
            {"label": "Birmenstorf", "value": "C116326"},
            {"label": "Birr", "value": "C116327"},
            {"label": "Boniswil", "value": "C116328"},
            {"label": "Bremgarten", "value": "C116329"},
            {"label": "Brittnau", "value": "C116330"},
            {"label": "Brugg", "value": "C116331"},
            {"label": "Buchs", "value": "C116332"},
            {"label": "Buttwil", "value": "C116333"},
            {"label": "Densburen", "value": "C116334"},
            {"label": "Dottikon", "value": "C116335"},
            {"label": "Durrenasch", "value": "C116336"},
            {"label": "Egliswil", "value": "C116337"},
            {"label": "Ehrendingen", "value": "C116338"},
            {"label": "Eiken", "value": "C116339"},
            {"label": "Endingen", "value": "C116340"},
            {"label": "Frick", "value": "C116341"},
            {"label": "Gebenstorf", "value": "C116342"},
            {"label": "Gipf-Oberfrick", "value": "C116343"},
            {"label": "Gontenschwil", "value": "C116344"},
            {"label": "Granichen", "value": "C116345"},
            {"label": "Hagglingen", "value": "C116346"},
            {"label": "Hausen", "value": "C116347"},
            {"label": "Jonen", "value": "C116348"},
            {"label": "Kaiseraugst", "value": "C116349"},
            {"label": "Kaisten", "value": "C116350"},
            {"label": "Killwangen", "value": "C116351"},
            {"label": "Klingnau", "value": "C116352"},
            {"label": "Koblenz", "value": "C116353"},
            {"label": "Kolliken", "value": "C116354"},
            {"label": "Kunten", "value": "C116355"},
            {"label": "Kuttigen", "value": "C116356"},
            {"label": "Laufenburg", "value": "C116357"},
            {"label": "Lauffohr (Brugg)", "value": "C116358"},
            {"label": "Leibstadt", "value": "C116359"},
            {"label": "Lenzburg", "value": "C116360"},
            {"label": "Magden", "value": "C116361"},
            {"label": "Meisterschwanden", "value": "C116362"},
            {"label": "Mellingen", "value": "C116363"},
            {"label": "Menziken", "value": "C116364"},
            {"label": "Merenschwand", "value": "C116365"},
            {"label": "Mohlin", "value": "C116366"},
            {"label": "Muhen", "value": "C116367"},
            {"label": "Mumpf", "value": "C116368"},
            {"label": "Murgenthal", "value": "C116369"},
            {"label": "Muri", "value": "C116370"},
            {"label": "Neuenhof", "value": "C116371"},
            {"label": "Niederlenz", "value": "C116372"},
            {"label": "Niederrohrdorf", "value": "C116373"},
            {"label": "Oberentfelden", "value": "C116374"},
            {"label": "Oberlunkhofen", "value": "C116375"},
            {"label": "Oberrohrdorf", "value": "C116376"},
            {"label": "Oberruti", "value": "C116377"},
            {"label": "Obersiggenthal", "value": "C116378"},
            {"label": "Oftringen", "value": "C116379"},
            {"label": "Othmarsingen", "value": "C116380"},
            {"label": "Reinach", "value": "C116381"},
            {"label": "Rheinfelden", "value": "C116382"},
            {"label": "Rothrist", "value": "C116383"},
            {"label": "Rudolfstetten", "value": "C116384"},
            {"label": "Rupperswil", "value": "C116385"},
            {"label": "Safenwil", "value": "C116386"},
            {"label": "Sarmenstorf", "value": "C116387"},
            {"label": "Schafisheim", "value": "C116388"},
            {"label": "Schinznach Bad", "value": "C116389"},
            {"label": "Schinznach Dorf", "value": "C116390"},
            {"label": "Schoftland", "value": "C116391"},
            {"label": "Schwaderloch", "value": "C116392"},
            {"label": "Seengen", "value": "C116393"},
            {"label": "Seon", "value": "C116394"},
            {"label": "Sins", "value": "C116395"},
            {"label": "Spreitenbach", "value": "C116396"},
            {"label": "Staffelbach", "value": "C116397"},
            {"label": "Staufen", "value": "C116398"},
            {"label": "Stein", "value": "C116399"},
            {"label": "Strengelbach", "value": "C116400"},
            {"label": "Suhr", "value": "C116401"},
            {"label": "Sulz", "value": "C116402"},
            {"label": "Tegerfelden", "value": "C116403"},
            {"label": "Teufenthal", "value": "C116404"},
            {"label": "Turgi", "value": "C116405"},
            {"label": "Uerkheim", "value": "C116406"},
            {"label": "Unterkulm", "value": "C116407"},
            {"label": "Unterlunkhofen", "value": "C116408"},
            {"label": "Untersiggenthal", "value": "C116409"},
            {"label": "Veltheim", "value": "C116410"},
            {"label": "Villigen", "value": "C116411"},
            {"label": "Villmergen", "value": "C116412"},
            {"label": "Villnachern", "value": "C116413"},
            {"label": "Vordemwald", "value": "C116414"},
            {"label": "Waltenschwil", "value": "C116415"},
            {"label": "Wegenstetten", "value": "C116416"},
            {"label": "Wettingen", "value": "C116417"},
            {"label": "Windisch", "value": "C116418"},
            {"label": "Wittnau", "value": "C116419"},
            {"label": "Wohlen", "value": "C116420"},
            {"label": "Wolflinswil", "value": "C116421"},
            {"label": "Wurenlingen", "value": "C116422"},
            {"label": "Wurenlos", "value": "C116423"},
            {"label": "Zofingen", "value": "C116424"}],
 "Abia": [{"label": "Aba", "value": "C78112"},
          {"label": "Amaigbo", "value": "C78113"},
          {"label": "Arochukwu", "value": "C78114"},
          {"label": "Bende", "value": "C78115"},
          {"label": "Ohafia-Ifigh", "value": "C78116"},
          {"label": "Umuahia", "value": "C78117"}],
 "Abidjan": [{"label": "Abidjan", "value": "C20922"},
             {"label": "Abobo", "value": "C20923"},
             {"label": "Anyama", "value": "C20924"},
             {"label": "Bingerville", "value": "C20925"}],
 "Abra": [{"label": "Adams", "value": "C81110"},
          {"label": "Agno", "value": "C81111"},
          {"label": "Agoo", "value": "C81112"},
          {"label": "Aguilar", "value": "C81113"},
          {"label": "Alac", "value": "C81114"},
          {"label": "Alaminos", "value": "C81115"},
          {"label": "Alcala", "value": "C81116"},
          {"label": "Alilem", "value": "C81117"},
          {"label": "Allangigan Primero", "value": "C81118"},
          {"label": "Aloleng", "value": "C81119"},
          {"label": "Amagbagan", "value": "C81120"},
          {"label": "Anambongan", "value": "C81121"},
          {"label": "Anda", "value": "C81122"},
          {"label": "Angatel", "value": "C81123"},
          {"label": "Anulid", "value": "C81124"},
          {"label": "Aringay", "value": "C81125"},
          {"label": "Asingan", "value": "C81126"},
          {"label": "Baay", "value": "C81127"},
          {"label": "Bacag", "value": "C81128"},
          {"label": "Bacarra", "value": "C81129"},
          {"label": "Bacnar", "value": "C81130"},
          {"label": "Bacnotan", "value": "C81131"},
          {"label": "Bactad Proper", "value": "C81132"},
          {"label": "Bacundao Weste", "value": "C81133"},
          {"label": "Badoc", "value": "C81134"},
          {"label": "Bagulin", "value": "C81135"},
          {"label": "Bail", "value": "C81136"},
          {"label": "Balaoan", "value": "C81137"},
          {"label": "Balingasay", "value": "C81138"},
          {"label": "Balingueo", "value": "C81139"},
          {"label": "Balogo", "value": "C81140"},
          {"label": "Balungao", "value": "C81141"},
          {"label": "Baluyot", "value": "C81142"},
          {"label": "Banayoyo", "value": "C81143"},
          {"label": "Bangan-Oda", "value": "C81144"},
          {"label": "Bangar", "value": "C81145"},
          {"label": "Bangui", "value": "C81146"},
          {"label": "Bani", "value": "C81147"},
          {"label": "Banna", "value": "C81148"},
          {"label": "Banog Sur", "value": "C81149"},
          {"label": "Bantay", "value": "C81150"},
          {"label": "Bantog", "value": "C81151"},
          {"label": "Barangobong", "value": "C81152"},
          {"label": "Baro", "value": "C81153"},
          {"label": "Barong", "value": "C81154"},
          {"label": "Basing", "value": "C81155"},
          {"label": "Basista", "value": "C81156"},
          {"label": "Batac", "value": "C81157"},
          {"label": "Bataquil", "value": "C81158"},
          {"label": "Bauang", "value": "C81159"},
          {"label": "Bautista", "value": "C81160"},
          {"label": "Bayambang", "value": "C81161"},
          {"label": "Bayaoas", "value": "C81162"},
          {"label": "Bical Norte", "value": "C81163"},
          {"label": "Bil-Loca", "value": "C81164"},
          {"label": "Binabalian", "value": "C81165"},
          {"label": "Binalonan", "value": "C81166"},
          {"label": "Binday", "value": "C81167"},
          {"label": "Binmaley", "value": "C81168"},
          {"label": "Bobonan", "value": "C81169"},
          {"label": "Bogtong", "value": "C81170"},
          {"label": "Bolaoit", "value": "C81171"},
          {"label": "Bolinao", "value": "C81172"},
          {"label": "Bolingit", "value": "C81173"},
          {"label": "Bolo", "value": "C81174"},
          {"label": "Bongalon", "value": "C81175"},
          {"label": "Botao", "value": "C81176"},
          {"label": "Bued", "value": "C81177"},
          {"label": "Buenlag", "value": "C81178"},
          {"label": "Bugallon", "value": "C81179"},
          {"label": "Bulog", "value": "C81180"},
          {"label": "Burgos", "value": "C81181"},
          {"label": "Butubut Norte", "value": "C81182"},
          {"label": "Caabiangan", "value": "C81183"},
          {"label": "Caba", "value": "C81184"},
          {"label": "Cabalaoangan", "value": "C81185"},
          {"label": "Cabalitian", "value": "C81186"},
          {"label": "Cabittaogan", "value": "C81187"},
          {"label": "Cabugao", "value": "C81188"},
          {"label": "Cabungan", "value": "C81189"},
          {"label": "Calasiao", "value": "C81190"},
          {"label": "Calepaan", "value": "C81191"},
          {"label": "Callaguip", "value": "C81192"},
          {"label": "Calomboyan", "value": "C81193"},
          {"label": "Calongbuyan", "value": "C81194"},
          {"label": "Calsib", "value": "C81195"},
          {"label": "Camaley", "value": "C81196"},
          {"label": "Canan Norte", "value": "C81197"},
          {"label": "Canaoalan", "value": "C81198"},
          {"label": "Candon", "value": "C81199"},
          {"label": "Cantoria", "value": "C81200"},
          {"label": "Caoayan", "value": "C81201"},
          {"label": "Capandanan", "value": "C81202"},
          {"label": "Capulaan", "value": "C81203"},
          {"label": "Caramutan", "value": "C81204"},
          {"label": "Carasi", "value": "C81205"},
          {"label": "Carmen", "value": "C81206"},
          {"label": "Caronoan West", "value": "C81207"},
          {"label": "Carot", "value": "C81208"},
          {"label": "Carriedo", "value": "C81209"},
          {"label": "Carusucan", "value": "C81210"},
          {"label": "Caterman", "value": "C81211"},
          {"label": "Cato", "value": "C81212"},
          {"label": "Catuday", "value": "C81213"},
          {"label": "Cayanga", "value": "C81214"},
          {"label": "Cayungnan", "value": "C81215"},
          {"label": "Cervantes", "value": "C81216"},
          {"label": "City of Batac", "value": "C81217"},
          {"label": "City of Candon", "value": "C81218"},
          {"label": "City of Urdaneta", "value": "C81219"},
          {"label": "City of Vigan", "value": "C81220"},
          {"label": "Comillas Norte", "value": "C81221"},
          {"label": "Corrooy", "value": "C81222"},
          {"label": "Currimao", "value": "C81223"},
          {"label": "Dagup", "value": "C81224"},
          {"label": "Dagupan", "value": "C81225"},
          {"label": "Damortis", "value": "C81226"},
          {"label": "Darapidap", "value": "C81227"},
          {"label": "Dasol", "value": "C81228"},
          {"label": "Davila", "value": "C81229"},
          {"label": "Diaz", "value": "C81230"},
          {"label": "Dilan", "value": "C81231"},
          {"label": "Dingras", "value": "C81232"},
          {"label": "Domalanoan", "value": "C81233"},
          {"label": "Domampot", "value": "C81234"},
          {"label": "Don Pedro", "value": "C81235"},
          {"label": "Dorongan Punta", "value": "C81236"},
          {"label": "Doyong", "value": "C81237"},
          {"label": "Dulig", "value": "C81238"},
          {"label": "Dumalneg", "value": "C81239"},
          {"label": "Dumpay", "value": "C81240"},
          {"label": "Eguia", "value": "C81241"},
          {"label": "Esmeralda", "value": "C81242"},
          {"label": "Fuerte", "value": "C81243"},
          {"label": "Galimuyod", "value": "C81244"},
          {"label": "Gayaman", "value": "C81245"},
          {"label": "Gregorio del Pilar", "value": "C81246"},
          {"label": "Guiling", "value": "C81247"},
          {"label": "Guiset East", "value": "C81248"},
          {"label": "Hacienda", "value": "C81249"},
          {"label": "Halog West", "value": "C81250"},
          {"label": "Ilioilio", "value": "C81251"},
          {"label": "Inabaan Sur", "value": "C81252"},
          {"label": "Infanta", "value": "C81253"},
          {"label": "Isla", "value": "C81254"},
          {"label": "Labayug", "value": "C81255"},
          {"label": "Labney", "value": "C81256"},
          {"label": "Labrador", "value": "C81257"},
          {"label": "Lagasit", "value": "C81258"},
          {"label": "Laguit Centro", "value": "C81259"},
          {"label": "Laoac", "value": "C81260"},
          {"label": "Laoag", "value": "C81261"},
          {"label": "Leones East", "value": "C81262"},
          {"label": "Lepa", "value": "C81263"},
          {"label": "Libas", "value": "C81264"},
          {"label": "Lidlidda", "value": "C81265"},
          {"label": "Lingayen", "value": "C81266"},
          {"label": "Linmansangan", "value": "C81267"},
          {"label": "Lloren", "value": "C81268"},
          {"label": "Lobong", "value": "C81269"},
          {"label": "Longos", "value": "C81270"},
          {"label": "Loqueb Este", "value": "C81271"},
          {"label": "Lucap", "value": "C81272"},
          {"label": "Lucero", "value": "C81273"},
          {"label": "Luna", "value": "C81274"},
          {"label": "Lunec", "value": "C81275"},
          {"label": "Lungog", "value": "C81276"},
          {"label": "Lusong", "value": "C81277"},
          {"label": "Mabilao", "value": "C81278"},
          {"label": "Mabilbila Sur", "value": "C81279"},
          {"label": "Mabini", "value": "C81280"},
          {"label": "Mabusag", "value": "C81281"},
          {"label": "Macabuboni", "value": "C81282"},
          {"label": "Macalong", "value": "C81283"},
          {"label": "Macalva Norte", "value": "C81284"},
          {"label": "Macayug", "value": "C81285"},
          {"label": "Magallanes", "value": "C81286"},
          {"label": "Magsingal", "value": "C81287"},
          {"label": "Magtaking", "value": "C81288"},
          {"label": "Malabago", "value": "C81289"},
          {"label": "Malanay", "value": "C81290"},
          {"label": "Malasiqui", "value": "C81291"},
          {"label": "Malawa", "value": "C81292"},
          {"label": "Malibong East", "value": "C81293"},
          {"label": "Manaoag", "value": "C81294"},
          {"label": "Mangaldan", "value": "C81295"},
          {"label": "Mangatarem", "value": "C81296"},
          {"label": "Mapandan", "value": "C81297"},
          {"label": "Mapolopolo", "value": "C81298"},
          {"label": "Marcos", "value": "C81299"},
          {"label": "Maticmatic", "value": "C81300"},
          {"label": "Minien East", "value": "C81301"},
          {"label": "Nagbacalan", "value": "C81302"},
          {"label": "Nagbukel", "value": "C81303"},
          {"label": "Nagsaing", "value": "C81304"},
          {"label": "Naguelguel", "value": "C81305"},
          {"label": "Naguilayan", "value": "C81306"},
          {"label": "Naguilian", "value": "C81307"},
          {"label": "Nalsian Norte", "value": "C81308"},
          {"label": "Nama", "value": "C81309"},
          {"label": "Namboongan", "value": "C81310"},
          {"label": "Nancalobasaan", "value": "C81311"},
          {"label": "Narvacan", "value": "C81312"},
          {"label": "Natividad", "value": "C81313"},
          {"label": "Navatat", "value": "C81314"},
          {"label": "Nibaliw Central", "value": "C81315"},
          {"label": "Nilombot", "value": "C81316"},
          {"label": "Ninoy", "value": "C81317"},
          {"label": "Nueva Era", "value": "C81318"},
          {"label": "Oaqui", "value": "C81319"},
          {"label": "Olea", "value": "C81320"},
          {"label": "Padong", "value": "C81321"},
          {"label": "Pagsanahan Norte", "value": "C81322"},
          {"label": "Pagudpud", "value": "C81323"},
          {"label": "Paitan Este", "value": "C81324"},
          {"label": "Palacpalac", "value": "C81325"},
          {"label": "Palguyod", "value": "C81326"},
          {"label": "Panganiban", "value": "C81327"},
          {"label": "Pangapisan", "value": "C81328"},
          {"label": "Pangascasan", "value": "C81329"},
          {"label": "Pangpang", "value": "C81330"},
          {"label": "Paoay", "value": "C81331"},
          {"label": "Paringao", "value": "C81332"},
          {"label": "Parioc Segundo", "value": "C81333"},
          {"label": "Pasibi West", "value": "C81334"},
          {"label": "Pasuquin", "value": "C81335"},
          {"label": "Patayac", "value": "C81336"},
          {"label": "Patpata Segundo", "value": "C81337"},
          {"label": "Payocpoc Sur", "value": "C81338"},
          {"label": "Piddig", "value": "C81339"},
          {"label": "Pindangan Centro", "value": "C81340"},
          {"label": "Pinili", "value": "C81341"},
          {"label": "Pogonsili", "value": "C81342"},
          {"label": "Polo", "value": "C81343"},
          {"label": "Polong", "value": "C81344"},
          {"label": "Polong Norte", "value": "C81345"},
          {"label": "Pozorrubio", "value": "C81346"},
          {"label": "Pudoc", "value": "C81347"},
          {"label": "Pudoc North", "value": "C81348"},
          {"label": "Puelay", "value": "C81349"},
          {"label": "Pugo", "value": "C81350"},
          {"label": "Puro Pinget", "value": "C81351"},
          {"label": "Quiling", "value": "C81352"},
          {"label": "Quinarayan", "value": "C81353"},
          {"label": "Quintong", "value": "C81354"},
          {"label": "Quirino", "value": "C81355"},
          {"label": "Ranao", "value": "C81356"},
          {"label": "Real", "value": "C81357"},
          {"label": "Rimus", "value": "C81358"},
          {"label": "Rissing", "value": "C81359"},
          {"label": "Rosales", "value": "C81360"},
          {"label": "Rosario", "value": "C81361"},
          {"label": "Sablig", "value": "C81362"},
          {"label": "Sagud-Bahley", "value": "C81363"},
          {"label": "Sagunto", "value": "C81364"},
          {"label": "Salcedo", "value": "C81365"},
          {"label": "Samon", "value": "C81366"},
          {"label": "San Carlos", "value": "C81367"},
          {"label": "San Emilio", "value": "C81368"},
          {"label": "San Esteban", "value": "C81369"},
          {"label": "San Eugenio", "value": "C81370"},
          {"label": "San Fabian", "value": "C81371"},
          {"label": "San Fernando", "value": "C81372"},
          {"label": "San Fernando Poblacion", "value": "C81373"},
          {"label": "San Gabriel", "value": "C81374"},
          {"label": "San Gabriel First", "value": "C81375"},
          {"label": "San Ildefonso", "value": "C81376"},
          {"label": "San Jacinto", "value": "C81377"},
          {"label": "San Juan", "value": "C81378"},
          {"label": "San Lorenzo", "value": "C81379"},
          {"label": "San Manuel", "value": "C81380"},
          {"label": "San Nicolas", "value": "C81381"},
          {"label": "San Pedro Apartado", "value": "C81382"},
          {"label": "San Quintin", "value": "C81383"},
          {"label": "San Sebastian", "value": "C81384"},
          {"label": "San Vicente", "value": "C81385"},
          {"label": "Sanlibo", "value": "C81386"},
          {"label": "Santa", "value": "C81387"},
          {"label": "Santa Barbara", "value": "C81388"},
          {"label": "Santa Catalina", "value": "C81389"},
          {"label": "Santa Cruz", "value": "C81390"},
          {"label": "Santa Lucia", "value": "C81391"},
          {"label": "Santa Maria", "value": "C81392"},
          {"label": "Santiago", "value": "C81393"},
          {"label": "Santo Domingo", "value": "C81394"},
          {"label": "Santo Tomas", "value": "C81395"},
          {"label": "Santol", "value": "C81396"},
          {"label": "Sarrat", "value": "C81397"},
          {"label": "Sigay", "value": "C81398"},
          {"label": "Sinait", "value": "C81399"},
          {"label": "Sison", "value": "C81400"},
          {"label": "Solsona", "value": "C81401"},
          {"label": "Sonquil", "value": "C81402"},
          {"label": "Sual", "value": "C81403"},
          {"label": "Subusub", "value": "C81404"},
          {"label": "Sudipen", "value": "C81405"},
          {"label": "Sugpon", "value": "C81406"},
          {"label": "Sumabnit", "value": "C81407"},
          {"label": "Suso", "value": "C81408"},
          {"label": "Suyo", "value": "C81409"},
          {"label": "Tablac", "value": "C81410"},
          {"label": "Tabug", "value": "C81411"},
          {"label": "Tagudin", "value": "C81412"},
          {"label": "Talospatang", "value": "C81413"},
          {"label": "Taloy", "value": "C81414"},
          {"label": "Tamayo", "value": "C81415"},
          {"label": "Tamorong", "value": "C81416"},
          {"label": "Tandoc", "value": "C81417"},
          {"label": "Tanolong", "value": "C81418"},
          {"label": "Tayug", "value": "C81419"},
          {"label": "Tebag East", "value": "C81420"},
          {"label": "Telbang", "value": "C81421"},
          {"label": "Tiep", "value": "C81422"},
          {"label": "Toboy", "value": "C81423"},
          {"label": "Tobuan", "value": "C81424"},
          {"label": "Tococ East", "value": "C81425"},
          {"label": "Tocok", "value": "C81426"},
          {"label": "Tombod", "value": "C81427"},
          {"label": "Tondol", "value": "C81428"},
          {"label": "Toritori", "value": "C81429"},
          {"label": "Tubao", "value": "C81430"},
          {"label": "Umanday Centro", "value": "C81431"},
          {"label": "Umingan", "value": "C81432"},
          {"label": "Unzad", "value": "C81433"},
          {"label": "Urbiztondo", "value": "C81434"},
          {"label": "Urdaneta", "value": "C81435"},
          {"label": "Uyong", "value": "C81436"},
          {"label": "Vigan", "value": "C81437"},
          {"label": "Villasis", "value": "C81438"},
          {"label": "Vintar", "value": "C81439"},
          {"label": "Zaragoza", "value": "C81440"}],
 "Abruzzo": [{"label": "Abbateggio", "value": "C51736"},
             {"label": "Acciano", "value": "C51737"},
             {"label": "Aielli", "value": "C51738"},
             {"label": "Alanno", "value": "C51739"},
             {"label": "Alba Adriatica", "value": "C51740"},
             {"label": "Alfedena", "value": "C51741"},
             {"label": "Altino", "value": "C51742"},
             {"label": "Ancarano", "value": "C51743"},
             {"label": "Anversa degli Abruzzi", "value": "C51744"},
             {"label": "Archi", "value": "C51745"},
             {"label": "Ari", "value": "C51746"},
             {"label": "Arielli", "value": "C51747"},
             {"label": "Arsita", "value": "C51748"},
             {"label": "Ateleta", "value": "C51749"},
             {"label": "Atessa", "value": "C51750"},
             {"label": "Atri", "value": "C51751"},
             {"label": "Avezzano", "value": "C51752"},
             {"label": "Balsorano", "value": "C51753"},
             {"label": "Balsorano Nuovo", "value": "C51754"},
             {"label": "Barberi", "value": "C51755"},
             {"label": "Barete", "value": "C51756"},
             {"label": "Barisciano", "value": "C51757"},
             {"label": "Barrea", "value": "C51758"},
             {"label": "Basciano", "value": "C51759"},
             {"label": "Bellante", "value": "C51760"},
             {"label": "Bellante Stazione", "value": "C51761"},
             {"label": "Bisegna", "value": "C51762"},
             {"label": "Bisenti", "value": "C51763"},
             {"label": "Bolognano", "value": "C51764"},
             {"label": "Bomba", "value": "C51765"},
             {"label": "Borgo Santa Maria Immacolata", "value": "C51766"},
             {"label": "Borrello", "value": "C51767"},
             {"label": "Brittoli", "value": "C51768"},
             {"label": "Bucchianico", "value": "C51769"},
             {"label": "Bugnara", "value": "C51770"},
             {"label": "Bussi sul Tirino", "value": "C51771"},
             {"label": "Cagnano Amiterno", "value": "C51772"},
             {"label": "Calascio", "value": "C51773"},
             {"label": "Campli", "value": "C51774"},
             {"label": "Campo di Giove", "value": "C51775"},
             {"label": "Campotosto", "value": "C51776"},
             {"label": "Canistro Inferiore", "value": "C51777"},
             {"label": "Canosa Sannita", "value": "C51778"},
             {"label": "Cansano", "value": "C51779"},
             {"label": "Canzano", "value": "C51780"},
             {"label": "Capestrano", "value": "C51781"},
             {"label": "Capistrello", "value": "C51782"},
             {"label": "Capitignano", "value": "C51783"},
             {"label": "Caporciano", "value": "C51784"},
             {"label": "Cappadocia", "value": "C51785"},
             {"label": "Cappelle sul Tavo", "value": "C51786"},
             {"label": "Caramanico Terme", "value": "C51787"},
             {"label": "Carapelle Calvisio", "value": "C51788"},
             {"label": "Carpineto della Nora", "value": "C51789"},
             {"label": "Carpineto Sinello", "value": "C51790"},
             {"label": "Carsoli", "value": "C51791"},
             {"label": "Carunchio", "value": "C51792"},
             {"label": "Casacanditella", "value": "C51793"},
             {"label": "Casalanguida", "value": "C51794"},
             {"label": "Casalbordino-Miracoli", "value": "C51795"},
             {"label": "Casalincontrada", "value": "C51796"},
             {"label": "Casoli", "value": "C51797"},
             {"label": "Castel Castagna", "value": "C51798"},
             {"label": "Castel del Monte", "value": "C51799"},
             {"label": "Castel di Ieri", "value": "C51800"},
             {"label": "Castel di Sangro", "value": "C51801"},
             {"label": "Castel Frentano", "value": "C51802"},
             {"label": "Castelguidone", "value": "C51803"},
             {"label": "Castellafiume", "value": "C51804"},
             {"label": "Castellalto", "value": "C51805"},
             {"label": "Castelli", "value": "C51806"},
             {"label": "Castelnuovo Vomano", "value": "C51807"},
             {"label": "Castelvecchio Calvisio", "value": "C51808"},
             {"label": "Castelvecchio Subequo", "value": "C51809"},
             {"label": "Castiglione a Casauria", "value": "C51810"},
             {"label": "Castiglione Messer Marino", "value": "C51811"},
             {"label": "Castiglione Messer Raimondo", "value": "C51812"},
             {"label": "Castilenti", "value": "C51813"},
             {"label": "Catignano", "value": "C51814"},
             {"label": "Celano", "value": "C51815"},
             {"label": "Celenza sul Trigno", "value": "C51816"},
             {"label": "Cellino Attanasio", "value": "C51817"},
             {"label": "Cepagatti", "value": "C51818"},
             {"label": "Cerchio", "value": "C51819"},
             {"label": "Cermignano", "value": "C51820"},
             {"label": "Cerratina", "value": "C51821"},
             {"label": "Chieti", "value": "C51822"},
             {"label": "Citta SantAngelo", "value": "C51823"},
             {"label": "Civita dAntino", "value": "C51824"},
             {"label": "Civitaluparella", "value": "C51825"},
             {"label": "Civitaquana", "value": "C51826"},
             {"label": "Civitella Alfedena", "value": "C51827"},
             {"label": "Civitella Casanova", "value": "C51828"},
             {"label": "Civitella del Tronto", "value": "C51829"},
             {"label": "Civitella Messer Raimondo", "value": "C51830"},
             {"label": "Civitella Roveto", "value": "C51831"},
             {"label": "Cocullo", "value": "C51832"},
             {"label": "Collarmele", "value": "C51833"},
             {"label": "Collecorvino", "value": "C51834"},
             {"label": "Colledara", "value": "C51835"},
             {"label": "Colledimacine", "value": "C51836"},
             {"label": "Colledimezzo", "value": "C51837"},
             {"label": "Collelongo", "value": "C51838"},
             {"label": "Collepietro", "value": "C51839"},
             {"label": "Colleranesco", "value": "C51840"},
             {"label": "Cologna Spiaggia", "value": "C51841"},
             {"label": "Colonnella", "value": "C51842"},
             {"label": "Cominio", "value": "C51843"},
             {"label": "Controguerra", "value": "C51844"},
             {"label": "Coppito", "value": "C51845"},
             {"label": "Corfinio", "value": "C51846"},
             {"label": "Corropoli", "value": "C51847"},
             {"label": "Cortino", "value": "C51848"},
             {"label": "Corvara", "value": "C51849"},
             {"label": "Crecchio", "value": "C51850"},
             {"label": "Crognaleto", "value": "C51851"},
             {"label": "Cugnoli", "value": "C51852"},
             {"label": "Cupello", "value": "C51853"},
             {"label": "Dogliola", "value": "C51854"},
             {"label": "Elice", "value": "C51855"},
             {"label": "Fallo", "value": "C51856"},
             {"label": "Fano Adriano", "value": "C51857"},
             {"label": "Fara Filiorum Petri", "value": "C51858"},
             {"label": "Fara San Martino", "value": "C51859"},
             {"label": "Farindola", "value": "C51860"},
             {"label": "Filetto", "value": "C51861"},
             {"label": "Fonte Umano-San Martino Alta", "value": "C51862"},
             {"label": "Fontecchio", "value": "C51863"},
             {"label": "Fossa", "value": "C51864"},
             {"label": "Fossacesia", "value": "C51865"},
             {"label": "Fraine", "value": "C51866"},
             {"label": "Francavilla al Mare", "value": "C51867"},
             {"label": "Fresagrandinaria", "value": "C51868"},
             {"label": "Frisa", "value": "C51869"},
             {"label": "Furci", "value": "C51870"},
             {"label": "Gagliano Aterno", "value": "C51871"},
             {"label": "Gamberale", "value": "C51872"},
             {"label": "Gessopalena", "value": "C51873"},
             {"label": "Gioia dei Marsi", "value": "C51874"},
             {"label": "Gissi", "value": "C51875"},
             {"label": "Giuliano Teatino", "value": "C51876"},
             {"label": "Giulianova", "value": "C51877"},
             {"label": "Goriano Sicoli", "value": "C51878"},
             {"label": "Guardiagrele", "value": "C51879"},
             {"label": "Guilmi", "value": "C51880"},
             {"label": "Imposte", "value": "C51881"},
             {"label": "Introdacqua", "value": "C51882"},
             {"label": "Isola del Gran Sasso dItalia", "value": "C51883"},
             {"label": "LAquila", "value": "C51884"},
             {"label": "Lama dei Peligni", "value": "C51885"},
             {"label": "Lanciano", "value": "C51886"},
             {"label": "Lecce Nei Marsi", "value": "C51887"},
             {"label": "Lentella", "value": "C51888"},
             {"label": "Lettomanoppello", "value": "C51889"},
             {"label": "Lettopalena", "value": "C51890"},
             {"label": "Liscia", "value": "C51891"},
             {"label": "Loreto Aprutino", "value": "C51892"},
             {"label": "Luco dei Marsi", "value": "C51893"},
             {"label": "Lucoli", "value": "C51894"},
             {"label": "Magliano DeMarsi", "value": "C51895"},
             {"label": "Manoppello", "value": "C51896"},
             {"label": "Manoppello Scalo", "value": "C51897"},
             {"label": "Marina di San Vito", "value": "C51898"},
             {"label": "Marina di Vasto", "value": "C51899"},
             {"label": "Martinsicuro", "value": "C51900"},
             {"label": "Massa dAlbe-Corona", "value": "C51901"},
             {"label": "Miglianico", "value": "C51902"},
             {"label": "Molina Aterno", "value": "C51903"},
             {"label": "Montazzoli", "value": "C51904"},
             {"label": "Montebello di Bertona", "value": "C51905"},
             {"label": "Montebello sul Sangro", "value": "C51906"},
             {"label": "Monteferrante", "value": "C51907"},
             {"label": "Montefino", "value": "C51908"},
             {"label": "Montelapiano", "value": "C51909"},
             {"label": "Montenerodomo", "value": "C51910"},
             {"label": "Monteodorisio", "value": "C51911"},
             {"label": "Montereale", "value": "C51912"},
             {"label": "Montesilvano Marina", "value": "C51913"},
             {"label": "Monticchio", "value": "C51914"},
             {"label": "Montorio al Vomano", "value": "C51915"},
             {"label": "Morino", "value": "C51916"},
             {"label": "Morro dOro", "value": "C51917"},
             {"label": "Mosciano SantAngelo", "value": "C51918"},
             {"label": "Moscufo", "value": "C51919"},
             {"label": "Mozzagrogna", "value": "C51920"},
             {"label": "Navelli", "value": "C51921"},
             {"label": "Nepezzano", "value": "C51922"},
             {"label": "Nereto", "value": "C51923"},
             {"label": "Nerito", "value": "C51924"},
             {"label": "Nocciano", "value": "C51925"},
             {"label": "Notaresco", "value": "C51926"},
             {"label": "Nucleo Industriale di Bazzano", "value": "C51927"},
             {"label": "Ofena", "value": "C51928"},
             {"label": "Opi", "value": "C51929"},
             {"label": "Oricola", "value": "C51930"},
             {"label": "Orsogna", "value": "C51931"},
             {"label": "Ortona", "value": "C51932"},
             {"label": "Ortona dei Marsi", "value": "C51933"},
             {"label": "Ortucchio", "value": "C51934"},
             {"label": "Ovindoli", "value": "C51935"},
             {"label": "Pacentro", "value": "C51936"},
             {"label": "Paganica-Tempera", "value": "C51937"},
             {"label": "Pagliare", "value": "C51938"},
             {"label": "Paglieta", "value": "C51939"},
             {"label": "Palena", "value": "C51940"},
             {"label": "Palmoli", "value": "C51941"},
             {"label": "Palombaro", "value": "C51942"},
             {"label": "Penna SantAndrea", "value": "C51943"},
             {"label": "Pennadomo", "value": "C51944"},
             {"label": "Pennapiedimonte", "value": "C51945"},
             {"label": "Penne", "value": "C51946"},
             {"label": "Perano", "value": "C51947"},
             {"label": "Pereto", "value": "C51948"},
             {"label": "Pescara", "value": "C51949"},
             {"label": "Pescasseroli", "value": "C51950"},
             {"label": "Pescina", "value": "C51951"},
             {"label": "Pescocostanzo", "value": "C51952"},
             {"label": "Pescosansonesco Nuovo", "value": "C51953"},
             {"label": "Pettorano sul Gizio", "value": "C51954"},
             {"label": "Pianella", "value": "C51955"},
             {"label": "Pianola", "value": "C51956"},
             {"label": "Pianura Vomano", "value": "C51957"},
             {"label": "Picciano", "value": "C51958"},
             {"label": "Pietracamela", "value": "C51959"},
             {"label": "Pietraferrazzana", "value": "C51960"},
             {"label": "Pietranico", "value": "C51961"},
             {"label": "Pineto", "value": "C51962"},
             {"label": "Pizzoferrato", "value": "C51963"},
             {"label": "Pizzoli", "value": "C51964"},
             {"label": "Poggio Picenze", "value": "C51965"},
             {"label": "Poggiofiorito", "value": "C51966"},
             {"label": "Pollutri", "value": "C51967"},
             {"label": "Popoli", "value": "C51968"},
             {"label": "Prata dAnsidonia", "value": "C51969"},
             {"label": "Pratola Peligna", "value": "C51970"},
             {"label": "Pretoro", "value": "C51971"},
             {"label": "Preturo", "value": "C51972"},
             {"label": "Prezza", "value": "C51973"},
             {"label": "Progetto Case Bazzano", "value": "C51974"},
             {"label": "Progetto Case Coppito 3", "value": "C51975"},
             {"label": "Progetto Case Sassa Nsi", "value": "C51976"},
             {"label": "Provincia dell Aquila", "value": "C51977"},
             {"label": "Provincia di Chieti", "value": "C51978"},
             {"label": "Provincia di Pescara", "value": "C51979"},
             {"label": "Provincia di Teramo", "value": "C51980"},
             {"label": "Quadri", "value": "C51981"},
             {"label": "Raiano", "value": "C51982"},
             {"label": "Rapino", "value": "C51983"},
             {"label": "Ripa Teatina", "value": "C51984"},
             {"label": "Rivisondoli", "value": "C51985"},
             {"label": "Rocca di Botte", "value": "C51986"},
             {"label": "Rocca di Cambio", "value": "C51987"},
             {"label": "Rocca di Mezzo", "value": "C51988"},
             {"label": "Rocca Pia", "value": "C51989"},
             {"label": "Rocca San Giovanni", "value": "C51990"},
             {"label": "Roccacasale", "value": "C51991"},
             {"label": "Roccamorice", "value": "C51992"},
             {"label": "Roccaraso", "value": "C51993"},
             {"label": "Roccascalegna", "value": "C51994"},
             {"label": "Roccaspinalveti-Santa Giusta", "value": "C51995"},
             {"label": "Roccavivi", "value": "C51996"},
             {"label": "Roio del Sangro", "value": "C51997"},
             {"label": "Rosciano", "value": "C51998"},
             {"label": "Rosello", "value": "C51999"},
             {"label": "Roseto degli Abruzzi", "value": "C52000"},
             {"label": "Salino", "value": "C52001"},
             {"label": "Salle", "value": "C52002"},
             {"label": "Salvo Marina", "value": "C52003"},
             {"label": "Sambuceto", "value": "C52004"},
             {"label": "San Benedetto dei Marsi", "value": "C52005"},
             {"label": "San Benedetto in Perillis", "value": "C52006"},
             {"label": "San Buono", "value": "C52007"},
             {"label": "San Demetrio Ne Vestini", "value": "C52008"},
             {"label": "San Giovanni Lipioni", "value": "C52009"},
             {"label": "San Martino Bassa", "value": "C52010"},
             {"label": "San Martino sulla Marrucina", "value": "C52011"},
             {"label": "San Nicolo a Tordino", "value": "C52012"},
             {"label": "San Panfilo dOcre", "value": "C52013"},
             {"label": "San Pio delle Camere", "value": "C52014"},
             {"label": "San Rocco", "value": "C52015"},
             {"label": "San Salvo", "value": "C52016"},
             {"label": "San Valentino in Abruzzo Citeriore", "value": "C52017"},
             {"label": "San Vincenzo Valle Roveto", "value": "C52018"},
             {"label": "San Vito Chietino", "value": "C52019"},
             {"label": "SantEgidio alla Vibrata", "value": "C52020"},
             {"label": "SantEufemia a Maiella", "value": "C52021"},
             {"label": "SantEusanio del Sangro", "value": "C52022"},
             {"label": "SantEusanio Forconese", "value": "C52023"},
             {"label": "SantOmero", "value": "C52024"},
             {"label": "Santa Maria Imbaro", "value": "C52025"},
             {"label": "Santa Teresa", "value": "C52026"},
             {"label": "Sante Marie", "value": "C52027"},
             {"label": "Santo Stefano di Sessanio", "value": "C52028"},
             {"label": "Sassa", "value": "C52029"},
             {"label": "Scafa", "value": "C52030"},
             {"label": "Scanno", "value": "C52031"},
             {"label": "Scerne", "value": "C52032"},
             {"label": "Scerni", "value": "C52033"},
             {"label": "Schiavi di Abruzzo", "value": "C52034"},
             {"label": "Scontrone", "value": "C52035"},
             {"label": "Scoppito", "value": "C52036"},
             {"label": "Scurcola Marsicana", "value": "C52037"},
             {"label": "Secinaro", "value": "C52038"},
             {"label": "Selva", "value": "C52039"},
             {"label": "Serramonacesca", "value": "C52040"},
             {"label": "Silvi", "value": "C52041"},
             {"label": "Spoltore", "value": "C52042"},
             {"label": "Sulmona", "value": "C52043"},
             {"label": "Tagliacozzo", "value": "C52044"},
             {"label": "Taranta Peligna", "value": "C52045"},
             {"label": "Teramo", "value": "C52046"},
             {"label": "Tione degli Abruzzi", "value": "C52047"},
             {"label": "Tocco da Casauria", "value": "C52048"},
             {"label": "Tollo", "value": "C52049"},
             {"label": "Torano Nuovo", "value": "C52050"},
             {"label": "Torino di Sangro", "value": "C52051"},
             {"label": "Tornareccio", "value": "C52052"},
             {"label": "Tornimparte", "value": "C52053"},
             {"label": "Torre de Passeri", "value": "C52054"},
             {"label": "Torrebruna", "value": "C52055"},
             {"label": "Torrevecchia", "value": "C52056"},
             {"label": "Torricella", "value": "C52057"},
             {"label": "Torricella Peligna", "value": "C52058"},
             {"label": "Tortoreto", "value": "C52059"},
             {"label": "Tortoreto Lido", "value": "C52060"},
             {"label": "Tossicia", "value": "C52061"},
             {"label": "Trasacco", "value": "C52062"},
             {"label": "Treglio", "value": "C52063"},
             {"label": "Tufillo", "value": "C52064"},
             {"label": "Turrivalignani", "value": "C52065"},
             {"label": "Vacri", "value": "C52066"},
             {"label": "Valle Castellana", "value": "C52067"},
             {"label": "Vallecupa", "value": "C52068"},
             {"label": "Vasto", "value": "C52069"},
             {"label": "Vicoli", "value": "C52070"},
             {"label": "Villa Caldari", "value": "C52071"},
             {"label": "Villa Celiera", "value": "C52072"},
             {"label": "Villa Lempa", "value": "C52073"},
             {"label": "Villa Raspa", "value": "C52074"},
             {"label": "Villa Rosa", "value": "C52075"},
             {"label": "Villa SantAngelo", "value": "C52076"},
             {"label": "Villa Santa Lucia degli Abruzzi", "value": "C52077"},
             {"label": "Villa Santa Maria", "value": "C52078"},
             {"label": "Villagrande", "value": "C52079"},
             {"label": "Villalago", "value": "C52080"},
             {"label": "Villalfonsina", "value": "C52081"},
             {"label": "Villamagna", "value": "C52082"},
             {"label": "Villanova", "value": "C52083"},
             {"label": "Villavallelonga", "value": "C52084"},
             {"label": "Villetta Barrea", "value": "C52085"},
             {"label": "Vittorito", "value": "C52086"}],
 "Absheron District": [{"label": "Ceyranbatan", "value": "C8367"},
                       {"label": "Digah", "value": "C8368"},
                       {"label": "Gyuzdek", "value": "C8369"},
                       {"label": "Khirdalan", "value": "C8370"},
                       {"label": "Qobu", "value": "C8371"},
                       {"label": "Saray", "value": "C8372"}],
 "Abu Dhabi Emirate": [{"label": "Abu Dhabi Island and Internal Islands City",
                        "value": "C123095"},
                       {"label": "Abu Dhabi Municipality", "value": "C123096"},
                       {"label": "Al Ain City", "value": "C123097"},
                       {"label": "Al Ain Municipality", "value": "C123098"},
                       {"label": "Al Dhafra", "value": "C123099"},
                       {"label": "Al Shamkhah City", "value": "C123100"},
                       {"label": "Ar Ruways", "value": "C123101"},
                       {"label": "Bani Yas City", "value": "C123102"},
                       {"label": "Khalifah A City", "value": "C123103"},
                       {"label": "Musaffah", "value": "C123104"},
                       {"label": "Muzayri", "value": "C123105"},
                       {"label": "Zayed City", "value": "C123106"}],
 "Abuja Federal Capital Territory": [{"label": "Abuja", "value": "C78118"},
                                     {"label": "Kuje", "value": "C78119"},
                                     {"label": "Kwali", "value": "C78120"},
                                     {"label": "Madala", "value": "C78121"}],
 "Abyan Governorate": [{"label": "Ahwar", "value": "C147773"},
                       {"label": "Al Mahfad", "value": "C147774"},
                       {"label": "Al Wadea", "value": "C147775"},
                       {"label": "Jawf al Maqbabah", "value": "C147776"},
                       {"label": "Jayshan", "value": "C147777"},
                       {"label": "Khanfir", "value": "C147778"},
                       {"label": "Lawdar", "value": "C147779"},
                       {"label": "Mudiyah", "value": "C147780"},
                       {"label": "Rasad", "value": "C147781"},
                       {"label": "Sarar", "value": "C147782"},
                       {"label": "Sibah", "value": "C147783"},
                       {"label": "Zingibar", "value": "C147784"},
                       {"label": "Zinjibar", "value": "C147785"}],
 "Aceh": [{"label": "Banda Aceh", "value": "C49138"},
          {"label": "Bireun", "value": "C49139"},
          {"label": "Kabupaten Aceh Barat", "value": "C49140"},
          {"label": "Kabupaten Aceh Barat Daya", "value": "C49141"},
          {"label": "Kabupaten Aceh Besar", "value": "C49142"},
          {"label": "Kabupaten Aceh Jaya", "value": "C49143"},
          {"label": "Kabupaten Aceh Selatan", "value": "C49144"},
          {"label": "Kabupaten Aceh Singkil", "value": "C49145"},
          {"label": "Kabupaten Aceh Tamiang", "value": "C49146"},
          {"label": "Kabupaten Aceh Tengah", "value": "C49147"},
          {"label": "Kabupaten Aceh Tenggara", "value": "C49148"},
          {"label": "Kabupaten Aceh Timur", "value": "C49149"},
          {"label": "Kabupaten Aceh Utara", "value": "C49150"},
          {"label": "Kabupaten Bener Meriah", "value": "C49151"},
          {"label": "Kabupaten Bireuen", "value": "C49152"},
          {"label": "Kabupaten Gayo Lues", "value": "C49153"},
          {"label": "Kabupaten Nagan Raya", "value": "C49154"},
          {"label": "Kabupaten Pidie", "value": "C49155"},
          {"label": "Kabupaten Simeulue", "value": "C49156"},
          {"label": "Kota Banda Aceh", "value": "C49157"},
          {"label": "Kota Langsa", "value": "C49158"},
          {"label": "Kota Lhokseumawe", "value": "C49159"},
          {"label": "Kota Sabang", "value": "C49160"},
          {"label": "Kota Subulussalam", "value": "C49161"},
          {"label": "Langsa", "value": "C49162"},
          {"label": "Lhokseumawe", "value": "C49163"},
          {"label": "Meulaboh", "value": "C49164"},
          {"label": "Reuleuet", "value": "C49165"},
          {"label": "Sabang", "value": "C49166"},
          {"label": "Sigli", "value": "C49167"},
          {"label": "Sinabang", "value": "C49168"},
          {"label": "Singkil", "value": "C49169"}],
 "Acores": [{"label": "agua de Pau", "value": "C90756"},
            {"label": "Angra do Heroismo", "value": "C90757"},
            {"label": "Angustias", "value": "C90758"},
            {"label": "Arrifes", "value": "C90759"},
            {"label": "Biscoitos", "value": "C90760"},
            {"label": "Cabouco", "value": "C90761"},
            {"label": "Cais do Pico", "value": "C90762"},
            {"label": "Calheta", "value": "C90763"},
            {"label": "Calheta de Sao Jorge", "value": "C90764"},
            {"label": "Castelo Branco", "value": "C90765"},
            {"label": "Corvo", "value": "C90766"},
            {"label": "Faja de Baixo", "value": "C90767"},
            {"label": "Fenais da Ajuda", "value": "C90768"},
            {"label": "Fenais da Luz", "value": "C90769"},
            {"label": "Feteira", "value": "C90770"},
            {"label": "Fonte Bastardo", "value": "C90771"},
            {"label": "Furnas", "value": "C90772"},
            {"label": "Horta", "value": "C90773"},
            {"label": "Lagoa", "value": "C90774"},
            {"label": "Lajes", "value": "C90775"},
            {"label": "Lajes das Flores", "value": "C90776"},
            {"label": "Lajes do Pico", "value": "C90777"},
            {"label": "Madalena", "value": "C90778"},
            {"label": "Maia", "value": "C90779"},
            {"label": "Mosteiros", "value": "C90780"},
            {"label": "Nordeste", "value": "C90781"},
            {"label": "Ponta Delgada", "value": "C90782"},
            {"label": "Ponta Garca", "value": "C90783"},
            {"label": "Porto Judeu", "value": "C90784"},
            {"label": "Porto Martins", "value": "C90785"},
            {"label": "Povoacao", "value": "C90786"},
            {"label": "Praia da Vitoria", "value": "C90787"},
            {"label": "Rabo de Peixe", "value": "C90788"},
            {"label": "Relva", "value": "C90789"},
            {"label": "Ribeira Grande", "value": "C90790"},
            {"label": "Ribeira Seca", "value": "C90791"},
            {"label": "Ribeirinha", "value": "C90792"},
            {"label": "Rosto de Cao", "value": "C90793"},
            {"label": "Santa Barbara", "value": "C90794"},
            {"label": "Santa Cruz da Graciosa", "value": "C90795"},
            {"label": "Santa Cruz das Flores", "value": "C90796"},
            {"label": "Sao Bartolomeu", "value": "C90797"},
            {"label": "Sao Mateus", "value": "C90798"},
            {"label": "Sao Roque", "value": "C90799"},
            {"label": "Sao Roque do Pico", "value": "C90800"},
            {"label": "Sao Sebastiao", "value": "C90801"},
            {"label": "Sao Vicente", "value": "C90802"},
            {"label": "Senhora do Rosario", "value": "C90803"},
            {"label": "Velas", "value": "C90804"},
            {"label": "Vila do Porto", "value": "C90805"},
            {"label": "Vila Franca do Campo", "value": "C90806"}],
 "Acquaviva": [{"label": "Acquaviva", "value": "C106245"}],
 "Acre": [{"label": "Acrelandia", "value": "C10197"},
          {"label": "Assis Brasil", "value": "C10198"},
          {"label": "Brasileia", "value": "C10199"},
          {"label": "Bujari", "value": "C10200"},
          {"label": "Capixaba", "value": "C10201"},
          {"label": "Cruzeiro do Sul", "value": "C10202"},
          {"label": "Epitaciolandia", "value": "C10203"},
          {"label": "Feijo", "value": "C10204"},
          {"label": "Jordao", "value": "C10205"},
          {"label": "Mancio Lima", "value": "C10206"},
          {"label": "Manoel Urbano", "value": "C10207"},
          {"label": "Marechal Thaumaturgo", "value": "C10208"},
          {"label": "Placido de Castro", "value": "C10209"},
          {"label": "Porto Acre", "value": "C10210"},
          {"label": "Porto Walter", "value": "C10211"},
          {"label": "Rio Branco", "value": "C10212"},
          {"label": "Rodrigues Alves", "value": "C10213"},
          {"label": "Santa Rosa do Purus", "value": "C10214"},
          {"label": "Sena Madureira", "value": "C10215"},
          {"label": "Senador Guiomard", "value": "C10216"},
          {"label": "Tarauaca", "value": "C10217"},
          {"label": "Xapuri", "value": "C10218"}],
 "Ad Dakhiliyah Governorate": [{"label": "Adam", "value": "C79284"},
                               {"label": "Bahla", "value": "C79285"},
                               {"label": "Bidbid", "value": "C79286"},
                               {"label": "Izki", "value": "C79287"},
                               {"label": "Nizwa", "value": "C79288"},
                               {"label": "Sufalat Samail", "value": "C79289"}],
 "Ad Dhahirah Governorate": [{"label": "Ibri", "value": "C79290"},
                             {"label": "Yanqul", "value": "C79291"}],
 "Adamawa": [{"label": "Bankim", "value": "C16565"},
             {"label": "Banyo", "value": "C16566"},
             {"label": "Belel", "value": "C16567"},
             {"label": "Djohong", "value": "C16568"},
             {"label": "Kontcha", "value": "C16569"},
             {"label": "Mayo-Banyo", "value": "C16570"},
             {"label": "Meiganga", "value": "C16571"},
             {"label": "Ngaoundere", "value": "C16572"},
             {"label": "Somie", "value": "C16573"},
             {"label": "Tibati", "value": "C16574"},
             {"label": "Tignere", "value": "C16575"},
             {"label": "Vina", "value": "C16576"},
             {"label": "Ganye", "value": "C78122"},
             {"label": "Gombi", "value": "C78123"},
             {"label": "Holma", "value": "C78124"},
             {"label": "Jimeta", "value": "C78125"},
             {"label": "Madagali", "value": "C78126"},
             {"label": "Mayo-Belwa", "value": "C78127"},
             {"label": "Mubi", "value": "C78128"},
             {"label": "Ngurore", "value": "C78129"},
             {"label": "Numan", "value": "C78130"},
             {"label": "Toungo", "value": "C78131"},
             {"label": "Yola", "value": "C78132"}],
 "Adan Governorate": [{"label": "Aden", "value": "C147745"},
                      {"label": "Al Buraiqeh", "value": "C147746"},
                      {"label": "Al Mansura", "value": "C147747"},
                      {"label": "Al Mualla", "value": "C147748"},
                      {"label": "Ash Shaikh Outhman", "value": "C147749"},
                      {"label": "Attawahi", "value": "C147750"},
                      {"label": "Craiter", "value": "C147751"},
                      {"label": "Dar Sad", "value": "C147752"}],
 "Adana": [{"label": "Adana", "value": "C119814"},
           {"label": "Aladag", "value": "C119815"},
           {"label": "Bahce", "value": "C119816"},
           {"label": "Ceyhan", "value": "C119817"},
           {"label": "Cukurova", "value": "C119818"},
           {"label": "Feke", "value": "C119819"},
           {"label": "Imamoglu", "value": "C119820"},
           {"label": "Karaisali", "value": "C119821"},
           {"label": "Karatas", "value": "C119822"},
           {"label": "Kozan", "value": "C119823"},
           {"label": "Pozanti", "value": "C119824"},
           {"label": "Saimbeyli", "value": "C119825"},
           {"label": "Saricam", "value": "C119826"},
           {"label": "Seyhan", "value": "C119827"},
           {"label": "Tufanbeyli", "value": "C119828"},
           {"label": "Yumurtalik", "value": "C119829"},
           {"label": "Yuregir", "value": "C119830"}],
 "Addis Ababa": [{"label": "Addis Ababa", "value": "C24661"}],
 "Addu Atoll": [{"label": "Hithadhoo", "value": "C65801"},
                {"label": "Meedhoo", "value": "C65802"}],
 "Adiyaman": [{"label": "Adiyaman", "value": "C119831"},
              {"label": "Aralik Ilcesi", "value": "C119832"},
              {"label": "Besni", "value": "C119833"},
              {"label": "Celikhan", "value": "C119834"},
              {"label": "Gerger", "value": "C119835"},
              {"label": "Golbasi", "value": "C119836"},
              {"label": "Kahta", "value": "C119837"},
              {"label": "Merkez", "value": "C119838"},
              {"label": "Samsat", "value": "C119839"},
              {"label": "Sincik", "value": "C119840"},
              {"label": "Tut", "value": "C119841"}],
 "Adjara": [{"label": "Akhaldaba", "value": "C34253"},
            {"label": "Batumi", "value": "C34254"},
            {"label": "Chakvi", "value": "C34255"},
            {"label": "Dioknisi", "value": "C34256"},
            {"label": "Khelvachauri", "value": "C34257"},
            {"label": "Khulo", "value": "C34258"},
            {"label": "Kobuleti", "value": "C34259"},
            {"label": "Makhinjauri", "value": "C34260"},
            {"label": "Ochkhamuri", "value": "C34261"},
            {"label": "Shuakhevi", "value": "C34262"},
            {"label": "Tsikhisdziri", "value": "C34263"}],
 "Adrar": [{"label": "Adrar", "value": "C223"},
           {"label": "Aoulef", "value": "C224"},
           {"label": "Reggane", "value": "C225"},
           {"label": "Timimoun", "value": "C226"},
           {"label": "Atar", "value": "C65937"},
           {"label": "Azougui", "value": "C65938"},
           {"label": "Chinguetti", "value": "C65939"},
           {"label": "Ksar el Khali", "value": "C65940"}],
 "Afar Region": [{"label": "Administrative Zone 2", "value": "C24662"},
                 {"label": "Administrative Zone 3", "value": "C24663"},
                 {"label": "Asaita", "value": "C24664"},
                 {"label": "awash", "value": "C24665"},
                 {"label": "Dubti", "value": "C24666"},
                 {"label": "Gewane", "value": "C24667"},
                 {"label": "Semera", "value": "C24668"}],
 "Afyonkarahisar": [{"label": "Afyonkarahisar", "value": "C119842"},
                    {"label": "Basmakci", "value": "C119843"},
                    {"label": "Bayat", "value": "C119844"},
                    {"label": "Bolvadin", "value": "C119845"},
                    {"label": "Cay", "value": "C119846"},
                    {"label": "Cobanlar Ilcesi", "value": "C119847"},
                    {"label": "Dazkiri", "value": "C119848"},
                    {"label": "Dinar", "value": "C119849"},
                    {"label": "Emirdag", "value": "C119850"},
                    {"label": "Evciler", "value": "C119851"},
                    {"label": "Hocalar", "value": "C119852"},
                    {"label": "Ihsaniye", "value": "C119853"},
                    {"label": "Iscehisar", "value": "C119854"},
                    {"label": "Isiklar", "value": "C119855"},
                    {"label": "Kiziloren", "value": "C119856"},
                    {"label": "Merkez", "value": "C119857"},
                    {"label": "Sandikli", "value": "C119858"},
                    {"label": "Sinanpasa", "value": "C119859"},
                    {"label": "Suhut", "value": "C119860"},
                    {"label": "Sultandagi", "value": "C119861"}],
 "Agadez Region": [{"label": "Agadez", "value": "C78042"},
                   {"label": "Alaghsas", "value": "C78043"},
                   {"label": "Arlit", "value": "C78044"},
                   {"label": "Bilma", "value": "C78045"},
                   {"label": "Departement de Bilma", "value": "C78046"},
                   {"label": "Departement de Tchirozerine", "value": "C78047"}],
 "Agalega": [{"label": "Vingt Cinq", "value": "C65976"}],
 "Agdam District": [{"label": "Agdam", "value": "C8373"}],
 "Agdash District": [{"label": "Agdas", "value": "C8374"}],
 "Aghjabadi District": [{"label": "Agdzhabedy", "value": "C8375"},
                        {"label": "Avsar", "value": "C8376"}],
 "Aglona Municipality": [{"label": "Aglona", "value": "C64839"}],
 "Agri": [{"label": "Agri", "value": "C119862"},
          {"label": "Diyadin", "value": "C119863"},
          {"label": "Dogubayazit", "value": "C119864"},
          {"label": "Eleskirt", "value": "C119865"},
          {"label": "Hamur", "value": "C119866"},
          {"label": "Patnos", "value": "C119867"},
          {"label": "Taslicay", "value": "C119868"},
          {"label": "Tutak Ilcesi", "value": "C119869"}],
 "Agstafa District": [{"label": "Aghstafa", "value": "C8377"},
                      {"label": "Saloglu", "value": "C8378"},
                      {"label": "Vurgun", "value": "C8379"}],
 "Agsu District": [{"label": "Aghsu", "value": "C8380"}],
 "Aguascalientes": [{"label": "Aguascalientes", "value": "C66073"},
                    {"label": "Arboledas Paso Blanco [Fraccionamiento]",
                     "value": "C66074"},
                    {"label": "Arellano", "value": "C66075"},
                    {"label": "Asientos", "value": "C66076"},
                    {"label": "Bimbaletes Aguascalientes (El alamo)",
                     "value": "C66077"},
                    {"label": "Calvillo", "value": "C66078"},
                    {"label": "Carboneras", "value": "C66079"},
                    {"label": "Cartagena [Fraccionamiento]", "value": "C66080"},
                    {"label": "Centro de Arriba", "value": "C66081"},
                    {"label": "Cienega Grande", "value": "C66082"},
                    {"label": "Corral de Barrancos", "value": "C66083"},
                    {"label": "Cosio", "value": "C66084"},
                    {"label": "Cotorina", "value": "C66085"},
                    {"label": "Crucero Las Pilas", "value": "C66086"},
                    {"label": "Cumbres III", "value": "C66087"},
                    {"label": "Ejido la Guayana (Rancho Seco)",
                     "value": "C66088"},
                    {"label": "El Bajio", "value": "C66089"},
                    {"label": "El Chayote", "value": "C66090"},
                    {"label": "El Cuervero (Cuerveros)", "value": "C66091"},
                    {"label": "El Llano", "value": "C66092"},
                    {"label": "El Refugio de Penuelas", "value": "C66093"},
                    {"label": "El Refugio de Providencia (Providencia)",
                     "value": "C66094"},
                    {"label": "El Salero", "value": "C66095"},
                    {"label": "El Tule", "value": "C66096"},
                    {"label": "Emiliano Zapata", "value": "C66097"},
                    {"label": "Escaleras", "value": "C66098"},
                    {"label": "Ex-Vinedos Guadalupe", "value": "C66099"},
                    {"label": "General Ignacio Zaragoza", "value": "C66100"},
                    {"label": "Guadalupe de Atlas", "value": "C66101"},
                    {"label": "Jaltiche de Arriba", "value": "C66102"},
                    {"label": "Jaltomate", "value": "C66103"},
                    {"label": "Jarillas", "value": "C66104"},
                    {"label": "Jesus Gomez Portugal", "value": "C66105"},
                    {"label": "Jesus Gomez Portugal (Margaritas)",
                     "value": "C66106"},
                    {"label": "Jesus Maria", "value": "C66107"},
                    {"label": "La Concepcion", "value": "C66108"},
                    {"label": "La Escondida", "value": "C66109"},
                    {"label": "La Labor", "value": "C66110"},
                    {"label": "La Loma de los Negritos", "value": "C66111"},
                    {"label": "La Panadera", "value": "C66112"},
                    {"label": "La Punta", "value": "C66113"},
                    {"label": "Las animas", "value": "C66114"},
                    {"label": "Las Norias de Ojocaliente", "value": "C66115"},
                    {"label": "Lazaro Cardenas", "value": "C66116"},
                    {"label": "Los Arquitos", "value": "C66117"},
                    {"label": "Los Canos", "value": "C66118"},
                    {"label": "Los Conos", "value": "C66119"},
                    {"label": "Macario J. Gomez [Colonia]", "value": "C66120"},
                    {"label": "Malpaso", "value": "C66121"},
                    {"label": "Maravillas", "value": "C66122"},
                    {"label": "Mesa Grande", "value": "C66123"},
                    {"label": "Miravalle", "value": "C66124"},
                    {"label": "Molinos", "value": "C66125"},
                    {"label": "Montoro", "value": "C66126"},
                    {"label": "Noria del Borrego (Norias)", "value": "C66127"},
                    {"label": "Norias del Paso Hondo", "value": "C66128"},
                    {"label": "Ojo de Agua de Crucitas", "value": "C66129"},
                    {"label": "Ojocaliente", "value": "C66130"},
                    {"label": "Pabellon de Arteaga", "value": "C66131"},
                    {"label": "Pabellon de Hidalgo", "value": "C66132"},
                    {"label": "Palo Alto", "value": "C66133"},
                    {"label": "Paredes", "value": "C66134"},
                    {"label": "Paseos de la Providencia [Fraccionamiento]",
                     "value": "C66135"},
                    {"label": "Paseos de las Haciendas [Fraccionamiento]",
                     "value": "C66136"},
                    {"label": "Paso Blanco", "value": "C66137"},
                    {"label": "Penuelas (El Cienegal)", "value": "C66138"},
                    {"label": "Pilotos", "value": "C66139"},
                    {"label": "Pocitos", "value": "C66140"},
                    {"label": "Puertecito de la Virgen", "value": "C66141"},
                    {"label": "Rincon de Romos", "value": "C66142"},
                    {"label": "San Antonio", "value": "C66143"},
                    {"label": "San Antonio de los Horcones", "value": "C66144"},
                    {"label": "San Antonio de los Rios", "value": "C66145"},
                    {"label": "San Antonio de Penuelas", "value": "C66146"},
                    {"label": "San Francisco de los Romo", "value": "C66147"},
                    {"label": "San Ignacio", "value": "C66148"},
                    {"label": "San Jacinto", "value": "C66149"},
                    {"label": "San Jose de Gracia", "value": "C66150"},
                    {"label": "San Luis de Letras", "value": "C66151"},
                    {"label": "San Sebastian [Fraccionamiento]",
                     "value": "C66152"},
                    {"label": "San Tadeo", "value": "C66153"},
                    {"label": "Santa Isabel [Fraccionamiento]",
                     "value": "C66154"},
                    {"label": "Santa Maria de la Paz", "value": "C66155"},
                    {"label": "Santa Rosa (El Huizache)", "value": "C66156"},
                    {"label": "Santiago", "value": "C66157"},
                    {"label": "Tepetates", "value": "C66158"},
                    {"label": "Tepezala", "value": "C66159"},
                    {"label": "Valladolid", "value": "C66160"},
                    {"label": "Valle Huejucar (Fraccionamiento Popular) ", "value": "C66161"},
                    {"label": "Villa Juarez", "value": "C66162"},
                    {"label": "Villa Licenciado Jesus Teran (Calvillito)",
                     "value": "C66163"}],
 "Agusan del Norte": [{"label": "Abut", "value": "C81441"},
                      {"label": "Accusilian", "value": "C81442"},
                      {"label": "Afusing Centro", "value": "C81443"},
                      {"label": "Aglipay", "value": "C81444"},
                      {"label": "Alabug", "value": "C81445"},
                      {"label": "Alannay", "value": "C81446"},
                      {"label": "Alcala", "value": "C81447"},
                      {"label": "Alfonso Castaneda", "value": "C81448"},
                      {"label": "Alicia", "value": "C81449"},
                      {"label": "Allacapan", "value": "C81450"},
                      {"label": "Almaguer North", "value": "C81451"},
                      {"label": "Ambaguio", "value": "C81452"},
                      {"label": "Amulung", "value": "C81453"},
                      {"label": "Angadanan", "value": "C81454"},
                      {"label": "Antagan Segunda", "value": "C81455"},
                      {"label": "Aparri", "value": "C81456"},
                      {"label": "Aritao", "value": "C81457"},
                      {"label": "Atulayan", "value": "C81458"},
                      {"label": "Aurora", "value": "C81459"},
                      {"label": "Awallan", "value": "C81460"},
                      {"label": "Bacnor East", "value": "C81461"},
                      {"label": "Bagabag", "value": "C81462"},
                      {"label": "Baggabag B", "value": "C81463"},
                      {"label": "Baggao", "value": "C81464"},
                      {"label": "Bagong Tanza", "value": "C81465"},
                      {"label": "Bagu", "value": "C81466"},
                      {"label": "Bagumbayan", "value": "C81467"},
                      {"label": "Ballesteros", "value": "C81468"},
                      {"label": "Bambang", "value": "C81469"},
                      {"label": "Bangad", "value": "C81470"},
                      {"label": "Banganan", "value": "C81471"},
                      {"label": "Banquero", "value": "C81472"},
                      {"label": "Barucboc Norte", "value": "C81473"},
                      {"label": "Basco", "value": "C81474"},
                      {"label": "Batal", "value": "C81475"},
                      {"label": "Battung", "value": "C81476"},
                      {"label": "Bauan", "value": "C81477"},
                      {"label": "Bayombong", "value": "C81478"},
                      {"label": "Belance", "value": "C81479"},
                      {"label": "Benito Soliven", "value": "C81480"},
                      {"label": "Binalan", "value": "C81481"},
                      {"label": "Binguang", "value": "C81482"},
                      {"label": "Bintawan", "value": "C81483"},
                      {"label": "Bitag Grande", "value": "C81484"},
                      {"label": "Bone South", "value": "C81485"},
                      {"label": "Buguey", "value": "C81486"},
                      {"label": "Buliwao", "value": "C81487"},
                      {"label": "Bulu", "value": "C81488"},
                      {"label": "Burgos", "value": "C81489"},
                      {"label": "Busilak", "value": "C81490"},
                      {"label": "Cabagan", "value": "C81491"},
                      {"label": "Cabannungan Second", "value": "C81492"},
                      {"label": "Cabaritan East", "value": "C81493"},
                      {"label": "Cabarroguis", "value": "C81494"},
                      {"label": "Cabatuan", "value": "C81495"},
                      {"label": "Cabiraoan", "value": "C81496"},
                      {"label": "Calamagui East", "value": "C81497"},
                      {"label": "Calantac", "value": "C81498"},
                      {"label": "Calaoagan", "value": "C81499"},
                      {"label": "Calayan", "value": "C81500"},
                      {"label": "Calinaoan Malasin", "value": "C81501"},
                      {"label": "Calog Norte", "value": "C81502"},
                      {"label": "Camalaniugan", "value": "C81503"},
                      {"label": "Capissayan Sur", "value": "C81504"},
                      {"label": "Carig", "value": "C81505"},
                      {"label": "Casambalangan", "value": "C81506"},
                      {"label": "Catayauan", "value": "C81507"},
                      {"label": "Cauayan", "value": "C81508"},
                      {"label": "Claveria", "value": "C81509"},
                      {"label": "Cordon", "value": "C81510"},
                      {"label": "Cullalabo del Sur", "value": "C81511"},
                      {"label": "Dagupan", "value": "C81512"},
                      {"label": "Dalaoig", "value": "C81513"},
                      {"label": "Daragutan", "value": "C81514"},
                      {"label": "Dassun", "value": "C81515"},
                      {"label": "Delfin Albano", "value": "C81516"},
                      {"label": "Diadi", "value": "C81517"},
                      {"label": "Diamantina", "value": "C81518"},
                      {"label": "Dibuluan", "value": "C81519"},
                      {"label": "Dicabisagan", "value": "C81520"},
                      {"label": "Dicamay", "value": "C81521"},
                      {"label": "Diffun", "value": "C81522"},
                      {"label": "Dinapigui", "value": "C81523"},
                      {"label": "Divilican", "value": "C81524"},
                      {"label": "Divisoria", "value": "C81525"},
                      {"label": "Dodan", "value": "C81526"},
                      {"label": "Dumabato", "value": "C81527"},
                      {"label": "Dupax del Norte", "value": "C81528"},
                      {"label": "Dupax del Sur", "value": "C81529"},
                      {"label": "Echague (town)", "value": "C81530"},
                      {"label": "Eden", "value": "C81531"},
                      {"label": "Enrile", "value": "C81532"},
                      {"label": "Esperanza East", "value": "C81533"},
                      {"label": "Estefania", "value": "C81534"},
                      {"label": "Furao", "value": "C81535"},
                      {"label": "Gadu", "value": "C81536"},
                      {"label": "Gammad", "value": "C81537"},
                      {"label": "Gamu", "value": "C81538"},
                      {"label": "Ganapi", "value": "C81539"},
                      {"label": "Gappal", "value": "C81540"},
                      {"label": "Gattaran", "value": "C81541"},
                      {"label": "Gonzaga", "value": "C81542"},
                      {"label": "Guiddam", "value": "C81543"},
                      {"label": "Ibung", "value": "C81544"},
                      {"label": "Iguig", "value": "C81545"},
                      {"label": "Ilagan", "value": "C81546"},
                      {"label": "Ineangan", "value": "C81547"},
                      {"label": "Itbayat", "value": "C81548"},
                      {"label": "Ivana", "value": "C81549"},
                      {"label": "Jones", "value": "C81550"},
                      {"label": "Kasibu", "value": "C81551"},
                      {"label": "Kayapa", "value": "C81552"},
                      {"label": "La Paz", "value": "C81553"},
                      {"label": "Lal-lo", "value": "C81554"},
                      {"label": "Lallayug", "value": "C81555"},
                      {"label": "Lanna", "value": "C81556"},
                      {"label": "Lapi", "value": "C81557"},
                      {"label": "Larion Alto", "value": "C81558"},
                      {"label": "Lasam", "value": "C81559"},
                      {"label": "Lucban", "value": "C81560"},
                      {"label": "Luna", "value": "C81561"},
                      {"label": "Mabasa", "value": "C81562"},
                      {"label": "Mabini", "value": "C81563"},
                      {"label": "Mabuttal East", "value": "C81564"},
                      {"label": "Maconacon", "value": "C81565"},
                      {"label": "Maddarulug Norte", "value": "C81566"},
                      {"label": "Maddela", "value": "C81567"},
                      {"label": "Magalalag", "value": "C81568"},
                      {"label": "Magdalena", "value": "C81569"},
                      {"label": "Maguilling", "value": "C81570"},
                      {"label": "Mahatao", "value": "C81571"},
                      {"label": "Malasin", "value": "C81572"},
                      {"label": "Mallig", "value": "C81573"},
                      {"label": "Maluno Sur", "value": "C81574"},
                      {"label": "Manaring", "value": "C81575"},
                      {"label": "Manga", "value": "C81576"},
                      {"label": "Masaya Sur", "value": "C81577"},
                      {"label": "Masipi West", "value": "C81578"},
                      {"label": "Maxingal", "value": "C81579"},
                      {"label": "Minallo", "value": "C81580"},
                      {"label": "Minanga Norte", "value": "C81581"},
                      {"label": "Minante Segundo", "value": "C81582"},
                      {"label": "Minuri", "value": "C81583"},
                      {"label": "Mozzozzin Sur", "value": "C81584"},
                      {"label": "Mungo", "value": "C81585"},
                      {"label": "Municipality of Delfin Albano",
                       "value": "C81586"},
                      {"label": "Munoz East", "value": "C81587"},
                      {"label": "Nabannagan West", "value": "C81588"},
                      {"label": "Nagrumbuan", "value": "C81589"},
                      {"label": "Nagtipunan", "value": "C81590"},
                      {"label": "Naguilian", "value": "C81591"},
                      {"label": "Namuac", "value": "C81592"},
                      {"label": "Nattapian", "value": "C81593"},
                      {"label": "Paddaya", "value": "C81594"},
                      {"label": "Palagao Norte", "value": "C81595"},
                      {"label": "Palanan", "value": "C81596"},
                      {"label": "Pamplona", "value": "C81597"},
                      {"label": "Pangal Sur", "value": "C81598"},
                      {"label": "Pata", "value": "C81599"},
                      {"label": "Pattao", "value": "C81600"},
                      {"label": "Penablanca", "value": "C81601"},
                      {"label": "Piat", "value": "C81602"},
                      {"label": "Pinoma", "value": "C81603"},
                      {"label": "Quezon", "value": "C81604"},
                      {"label": "Quibal", "value": "C81605"},
                      {"label": "Quirino", "value": "C81606"},
                      {"label": "Ragan Norte", "value": "C81607"},
                      {"label": "Ramon (municipal capital)", "value": "C81608"},
                      {"label": "Ramos West", "value": "C81609"},
                      {"label": "Reina Mercedes", "value": "C81610"},
                      {"label": "Rizal", "value": "C81611"},
                      {"label": "Roxas", "value": "C81612"},
                      {"label": "Sabtang", "value": "C81613"},
                      {"label": "Saguday", "value": "C81614"},
                      {"label": "Salinas", "value": "C81615"},
                      {"label": "Salinungan Proper", "value": "C81616"},
                      {"label": "San Agustin", "value": "C81617"},
                      {"label": "San Antonio", "value": "C81618"},
                      {"label": "San Bernardo", "value": "C81619"},
                      {"label": "San Fernando", "value": "C81620"},
                      {"label": "San Guillermo", "value": "C81621"},
                      {"label": "San Isidro", "value": "C81622"},
                      {"label": "San Jose", "value": "C81623"},
                      {"label": "San Luis", "value": "C81624"},
                      {"label": "San Manuel", "value": "C81625"},
                      {"label": "San Mariano", "value": "C81626"},
                      {"label": "San Mateo", "value": "C81627"},
                      {"label": "San Pablo", "value": "C81628"},
                      {"label": "San Pedro", "value": "C81629"},
                      {"label": "San Vicente", "value": "C81630"},
                      {"label": "Sanchez Mira", "value": "C81631"},
                      {"label": "Sandiat Centro", "value": "C81632"},
                      {"label": "Santa Ana", "value": "C81633"},
                      {"label": "Santa Cruz", "value": "C81634"},
                      {"label": "Santa Fe", "value": "C81635"},
                      {"label": "Santa Maria", "value": "C81636"},
                      {"label": "Santa Praxedes", "value": "C81637"},
                      {"label": "Santa Teresita", "value": "C81638"},
                      {"label": "Santiago", "value": "C81639"},
                      {"label": "Santo Domingo", "value": "C81640"},
                      {"label": "Santo Nino", "value": "C81641"},
                      {"label": "Santo Tomas", "value": "C81642"},
                      {"label": "Siempre Viva", "value": "C81643"},
                      {"label": "Sillawit", "value": "C81644"},
                      {"label": "Simanu Sur", "value": "C81645"},
                      {"label": "Simimbaan", "value": "C81646"},
                      {"label": "Sinamar", "value": "C81647"},
                      {"label": "Sindon", "value": "C81648"},
                      {"label": "Solana", "value": "C81649"},
                      {"label": "Solano", "value": "C81650"},
                      {"label": "Soyung", "value": "C81651"},
                      {"label": "Taguing", "value": "C81652"},
                      {"label": "Tapel", "value": "C81653"},
                      {"label": "Tuao", "value": "C81654"},
                      {"label": "Tuguegarao", "value": "C81655"},
                      {"label": "Tuguegarao City", "value": "C81656"},
                      {"label": "Tumauini", "value": "C81657"},
                      {"label": "Tupang", "value": "C81658"},
                      {"label": "Uddiawan", "value": "C81659"},
                      {"label": "Ugac Sur", "value": "C81660"},
                      {"label": "Ugad", "value": "C81661"},
                      {"label": "Upi", "value": "C81662"},
                      {"label": "Uyugan", "value": "C81663"},
                      {"label": "Villaverde", "value": "C81664"},
                      {"label": "Yeban Norte", "value": "C81665"}],
 "Agusan del Sur": [{"label": "Acli", "value": "C81666"},
                    {"label": "Agbannawag", "value": "C81667"},
                    {"label": "Akle", "value": "C81668"},
                    {"label": "Aliaga", "value": "C81669"},
                    {"label": "Alua", "value": "C81670"},
                    {"label": "Amacalan", "value": "C81671"},
                    {"label": "Amucao", "value": "C81672"},
                    {"label": "Amungan", "value": "C81673"},
                    {"label": "Anao", "value": "C81674"},
                    {"label": "Angat", "value": "C81675"},
                    {"label": "Angeles", "value": "C81676"},
                    {"label": "Antipolo", "value": "C81677"},
                    {"label": "Apalit", "value": "C81678"},
                    {"label": "Arayat", "value": "C81679"},
                    {"label": "Arenas", "value": "C81680"},
                    {"label": "Arminia", "value": "C81681"},
                    {"label": "Bacabac", "value": "C81682"},
                    {"label": "Bacolor", "value": "C81683"},
                    {"label": "Bacsay", "value": "C81684"},
                    {"label": "Bagac", "value": "C81685"},
                    {"label": "Bagong Barrio", "value": "C81686"},
                    {"label": "Bagong-Sikat", "value": "C81687"},
                    {"label": "Bahay Pare", "value": "C81688"},
                    {"label": "Bakulong", "value": "C81689"},
                    {"label": "Balagtas", "value": "C81690"},
                    {"label": "Balanga", "value": "C81691"},
                    {"label": "Balaoang", "value": "C81692"},
                    {"label": "Balas", "value": "C81693"},
                    {"label": "Balasing", "value": "C81694"},
                    {"label": "Balayang", "value": "C81695"},
                    {"label": "Baler", "value": "C81696"},
                    {"label": "Balingcanaway", "value": "C81697"},
                    {"label": "Balite", "value": "C81698"},
                    {"label": "Baliuag", "value": "C81699"},
                    {"label": "Baloc", "value": "C81700"},
                    {"label": "Baloy", "value": "C81701"},
                    {"label": "Balsic", "value": "C81702"},
                    {"label": "Balucuc", "value": "C81703"},
                    {"label": "Balut", "value": "C81704"},
                    {"label": "Balutu", "value": "C81705"},
                    {"label": "Bamban", "value": "C81706"},
                    {"label": "Banawang", "value": "C81707"},
                    {"label": "Bani", "value": "C81708"},
                    {"label": "Baquero Norte", "value": "C81709"},
                    {"label": "Batasan Bata", "value": "C81710"},
                    {"label": "Batitang", "value": "C81711"},
                    {"label": "Bayanan", "value": "C81712"},
                    {"label": "Beddeng", "value": "C81713"},
                    {"label": "Biay", "value": "C81714"},
                    {"label": "Bibiclat", "value": "C81715"},
                    {"label": "Bicos", "value": "C81716"},
                    {"label": "Biga", "value": "C81717"},
                    {"label": "Bilad", "value": "C81718"},
                    {"label": "Bobon Second", "value": "C81719"},
                    {"label": "Bocaue", "value": "C81720"},
                    {"label": "Bodega", "value": "C81721"},
                    {"label": "Bolitoc", "value": "C81722"},
                    {"label": "Bongabon", "value": "C81723"},
                    {"label": "Botolan", "value": "C81724"},
                    {"label": "Buenlag", "value": "C81725"},
                    {"label": "Buensuseso", "value": "C81726"},
                    {"label": "Bulakan", "value": "C81727"},
                    {"label": "Bulaon", "value": "C81728"},
                    {"label": "Bularit", "value": "C81729"},
                    {"label": "Bulawin", "value": "C81730"},
                    {"label": "Bulihan", "value": "C81731"},
                    {"label": "Buliran", "value": "C81732"},
                    {"label": "Buliran Segundo", "value": "C81733"},
                    {"label": "Bulualto", "value": "C81734"},
                    {"label": "Bundoc", "value": "C81735"},
                    {"label": "Bunol", "value": "C81736"},
                    {"label": "Burgos", "value": "C81737"},
                    {"label": "Bustos", "value": "C81738"},
                    {"label": "Cabanatuan", "value": "C81739"},
                    {"label": "Cabangan", "value": "C81740"},
                    {"label": "Cabayaoasan", "value": "C81741"},
                    {"label": "Cabcaben", "value": "C81742"},
                    {"label": "Cabiao", "value": "C81743"},
                    {"label": "Cabog", "value": "C81744"},
                    {"label": "Cafe", "value": "C81745"},
                    {"label": "Calaba", "value": "C81746"},
                    {"label": "Calancuasan Norte", "value": "C81747"},
                    {"label": "Calangain", "value": "C81748"},
                    {"label": "Calantas", "value": "C81749"},
                    {"label": "Calayaan", "value": "C81750"},
                    {"label": "Calibungan", "value": "C81751"},
                    {"label": "Calibutbut", "value": "C81752"},
                    {"label": "Calingcuan", "value": "C81753"},
                    {"label": "Calumpang", "value": "C81754"},
                    {"label": "Calumpit", "value": "C81755"},
                    {"label": "Cama Juan", "value": "C81756"},
                    {"label": "Camachile", "value": "C81757"},
                    {"label": "Camias", "value": "C81758"},
                    {"label": "Camiling", "value": "C81759"},
                    {"label": "Candaba", "value": "C81760"},
                    {"label": "Candating", "value": "C81761"},
                    {"label": "Candelaria", "value": "C81762"},
                    {"label": "Capas", "value": "C81763"},
                    {"label": "Cardona", "value": "C81764"},
                    {"label": "Carmen", "value": "C81765"},
                    {"label": "Carranglan", "value": "C81766"},
                    {"label": "Casiguran", "value": "C81767"},
                    {"label": "Castillejos", "value": "C81768"},
                    {"label": "Cauayan", "value": "C81769"},
                    {"label": "Cavite", "value": "C81770"},
                    {"label": "Cawayan Bugtong", "value": "C81771"},
                    {"label": "City of Balanga", "value": "C81772"},
                    {"label": "City of Gapan", "value": "C81773"},
                    {"label": "City of Malolos", "value": "C81774"},
                    {"label": "City of Meycauayan", "value": "C81775"},
                    {"label": "City of San Fernando", "value": "C81776"},
                    {"label": "City of San Jose del Monte", "value": "C81777"},
                    {"label": "Comillas", "value": "C81778"},
                    {"label": "Communal", "value": "C81779"},
                    {"label": "Concepcion", "value": "C81780"},
                    {"label": "Conversion", "value": "C81781"},
                    {"label": "Culianin", "value": "C81782"},
                    {"label": "Culubasa", "value": "C81783"},
                    {"label": "Cut-cut Primero", "value": "C81784"},
                    {"label": "Cuyapo", "value": "C81785"},
                    {"label": "Dampol", "value": "C81786"},
                    {"label": "Del Carmen", "value": "C81787"},
                    {"label": "Del Pilar", "value": "C81788"},
                    {"label": "Digdig", "value": "C81789"},
                    {"label": "Dilasag", "value": "C81790"},
                    {"label": "Diliman Primero", "value": "C81791"},
                    {"label": "Dinalongan", "value": "C81792"},
                    {"label": "Dinalupihan", "value": "C81793"},
                    {"label": "Dingalan", "value": "C81794"},
                    {"label": "Dipaculao", "value": "C81795"},
                    {"label": "Dolores", "value": "C81796"},
                    {"label": "Dona Remedios Trinidad", "value": "C81797"},
                    {"label": "Entablado", "value": "C81798"},
                    {"label": "Estipona", "value": "C81799"},
                    {"label": "Estrella", "value": "C81800"},
                    {"label": "Floridablanca", "value": "C81801"},
                    {"label": "Gabaldon", "value": "C81802"},
                    {"label": "Gapan", "value": "C81803"},
                    {"label": "General Luna", "value": "C81804"},
                    {"label": "General Mamerto Natividad", "value": "C81805"},
                    {"label": "General Tinio", "value": "C81806"},
                    {"label": "Gerona", "value": "C81807"},
                    {"label": "Guagua", "value": "C81808"},
                    {"label": "Gueset", "value": "C81809"},
                    {"label": "Guiguinto", "value": "C81810"},
                    {"label": "Guimba", "value": "C81811"},
                    {"label": "Guisguis", "value": "C81812"},
                    {"label": "Guyong", "value": "C81813"},
                    {"label": "Hagonoy", "value": "C81814"},
                    {"label": "Hermosa", "value": "C81815"},
                    {"label": "Iba", "value": "C81816"},
                    {"label": "Jaen", "value": "C81817"},
                    {"label": "La Paz", "value": "C81818"},
                    {"label": "Lambakin", "value": "C81819"},
                    {"label": "Lanat", "value": "C81820"},
                    {"label": "Laug", "value": "C81821"},
                    {"label": "Laur", "value": "C81822"},
                    {"label": "Lawang Kupang", "value": "C81823"},
                    {"label": "Lennec", "value": "C81824"},
                    {"label": "Licab", "value": "C81825"},
                    {"label": "Ligaya", "value": "C81826"},
                    {"label": "Limay", "value": "C81827"},
                    {"label": "Liozon", "value": "C81828"},
                    {"label": "Lipay", "value": "C81829"},
                    {"label": "Llanera", "value": "C81830"},
                    {"label": "Lomboy", "value": "C81831"},
                    {"label": "Lourdes", "value": "C81832"},
                    {"label": "Lubao", "value": "C81833"},
                    {"label": "Lucapon", "value": "C81834"},
                    {"label": "Lupao", "value": "C81835"},
                    {"label": "Maasim", "value": "C81836"},
                    {"label": "Mabalacat", "value": "C81837"},
                    {"label": "Mabayo", "value": "C81838"},
                    {"label": "Mabilang", "value": "C81839"},
                    {"label": "Mabilog", "value": "C81840"},
                    {"label": "Mabini", "value": "C81841"},
                    {"label": "Macabebe", "value": "C81842"},
                    {"label": "Macapsing", "value": "C81843"},
                    {"label": "Macarse", "value": "C81844"},
                    {"label": "Macatbong", "value": "C81845"},
                    {"label": "Magalang", "value": "C81846"},
                    {"label": "Magliman", "value": "C81847"},
                    {"label": "Magtangol", "value": "C81848"},
                    {"label": "Maguinao", "value": "C81849"},
                    {"label": "Malabon", "value": "C81850"},
                    {"label": "Malacampa", "value": "C81851"},
                    {"label": "Maligaya", "value": "C81852"},
                    {"label": "Malino", "value": "C81853"},
                    {"label": "Malolos", "value": "C81854"},
                    {"label": "Maloma", "value": "C81855"},
                    {"label": "Maluid", "value": "C81856"},
                    {"label": "Malusac", "value": "C81857"},
                    {"label": "Mambog", "value": "C81858"},
                    {"label": "Mamonit", "value": "C81859"},
                    {"label": "Manacsac", "value": "C81860"},
                    {"label": "Manatal", "value": "C81861"},
                    {"label": "Mandili", "value": "C81862"},
                    {"label": "Mangga", "value": "C81863"},
                    {"label": "Manibaug Pasig", "value": "C81864"},
                    {"label": "Manogpi", "value": "C81865"},
                    {"label": "Mapalacsiao", "value": "C81866"},
                    {"label": "Mapalad", "value": "C81867"},
                    {"label": "Mapaniqui", "value": "C81868"},
                    {"label": "Maquiapo", "value": "C81869"},
                    {"label": "Marawa", "value": "C81870"},
                    {"label": "Maria Aurora", "value": "C81871"},
                    {"label": "Marilao", "value": "C81872"},
                    {"label": "Mariveles", "value": "C81873"},
                    {"label": "Masalipit", "value": "C81874"},
                    {"label": "Masantol", "value": "C81875"},
                    {"label": "Masinloc", "value": "C81876"},
                    {"label": "Matayumtayum", "value": "C81877"},
                    {"label": "Maturanoc", "value": "C81878"},
                    {"label": "Mayantoc", "value": "C81879"},
                    {"label": "Mexico", "value": "C81880"},
                    {"label": "Meycauayan", "value": "C81881"},
                    {"label": "Minalin", "value": "C81882"},
                    {"label": "Moncada", "value": "C81883"},
                    {"label": "Moriones", "value": "C81884"},
                    {"label": "Morong", "value": "C81885"},
                    {"label": "Motrico", "value": "C81886"},
                    {"label": "Munoz", "value": "C81887"},
                    {"label": "Murcia", "value": "C81888"},
                    {"label": "Nagpandayan", "value": "C81889"},
                    {"label": "Nambalan", "value": "C81890"},
                    {"label": "Nampicuan", "value": "C81891"},
                    {"label": "Nancamarinan", "value": "C81892"},
                    {"label": "Nieves", "value": "C81893"},
                    {"label": "Niugan", "value": "C81894"},
                    {"label": "Norzagaray", "value": "C81895"},
                    {"label": "Obando", "value": "C81896"},
                    {"label": "Olongapo", "value": "C81897"},
                    {"label": "Orani", "value": "C81898"},
                    {"label": "Orion", "value": "C81899"},
                    {"label": "Paco Roman", "value": "C81900"},
                    {"label": "Padapada", "value": "C81901"},
                    {"label": "Paitan Norte", "value": "C81902"},
                    {"label": "Palauig", "value": "C81903"},
                    {"label": "Palayan", "value": "C81904"},
                    {"label": "Palusapis", "value": "C81905"},
                    {"label": "Pamatawan", "value": "C81906"},
                    {"label": "Panabingan", "value": "C81907"},
                    {"label": "Panan", "value": "C81908"},
                    {"label": "Pance", "value": "C81909"},
                    {"label": "Pandacaqui", "value": "C81910"},
                    {"label": "Pandi", "value": "C81911"},
                    {"label": "Pando", "value": "C81912"},
                    {"label": "Paniqui", "value": "C81913"},
                    {"label": "Pantabangan", "value": "C81914"},
                    {"label": "Pantubig", "value": "C81915"},
                    {"label": "Paombong", "value": "C81916"},
                    {"label": "Papaya", "value": "C81917"},
                    {"label": "Parang", "value": "C81918"},
                    {"label": "Parista", "value": "C81919"},
                    {"label": "Pau", "value": "C81920"},
                    {"label": "Penaranda", "value": "C81921"},
                    {"label": "Pias", "value": "C81922"},
                    {"label": "Pilar", "value": "C81923"},
                    {"label": "Pinahan", "value": "C81924"},
                    {"label": "Pinambaran", "value": "C81925"},
                    {"label": "Pio", "value": "C81926"},
                    {"label": "Plaridel", "value": "C81927"},
                    {"label": "Porac", "value": "C81928"},
                    {"label": "Porais", "value": "C81929"},
                    {"label": "Prado Siongco", "value": "C81930"},
                    {"label": "Pulilan", "value": "C81931"},
                    {"label": "Pulo", "value": "C81932"},
                    {"label": "Pulong Gubat", "value": "C81933"},
                    {"label": "Pulong Sampalok", "value": "C81934"},
                    {"label": "Pulung Santol", "value": "C81935"},
                    {"label": "Pulungmasle", "value": "C81936"},
                    {"label": "Puncan", "value": "C81937"},
                    {"label": "Pura", "value": "C81938"},
                    {"label": "Purac", "value": "C81939"},
                    {"label": "Putlod", "value": "C81940"},
                    {"label": "Quezon", "value": "C81941"},
                    {"label": "Rajal Norte", "value": "C81942"},
                    {"label": "Ramos", "value": "C81943"},
                    {"label": "Rizal", "value": "C81944"},
                    {"label": "Sabang", "value": "C81945"},
                    {"label": "Sagana", "value": "C81946"},
                    {"label": "Salapungan", "value": "C81947"},
                    {"label": "Salaza", "value": "C81948"},
                    {"label": "Salcedo", "value": "C81949"},
                    {"label": "Salvacion I", "value": "C81950"},
                    {"label": "Samal", "value": "C81951"},
                    {"label": "Sampaloc", "value": "C81952"},
                    {"label": "San Agustin", "value": "C81953"},
                    {"label": "San Alejandro", "value": "C81954"},
                    {"label": "San Andres", "value": "C81955"},
                    {"label": "San Anton", "value": "C81956"},
                    {"label": "San Antonio", "value": "C81957"},
                    {"label": "San Basilio", "value": "C81958"},
                    {"label": "San Benito", "value": "C81959"},
                    {"label": "San Carlos", "value": "C81960"},
                    {"label": "San Casimiro", "value": "C81961"},
                    {"label": "San Clemente", "value": "C81962"},
                    {"label": "San Cristobal", "value": "C81963"},
                    {"label": "San Fabian", "value": "C81964"},
                    {"label": "San Felipe", "value": "C81965"},
                    {"label": "San Felipe Old", "value": "C81966"},
                    {"label": "San Fernando", "value": "C81967"},
                    {"label": "San Francisco", "value": "C81968"},
                    {"label": "San Ildefonso", "value": "C81969"},
                    {"label": "San Isidro", "value": "C81970"},
                    {"label": "San Jacinto", "value": "C81971"},
                    {"label": "San Jose", "value": "C81972"},
                    {"label": "San Jose del Monte", "value": "C81973"},
                    {"label": "San Juan", "value": "C81974"},
                    {"label": "San Juan de Mata", "value": "C81975"},
                    {"label": "San Leonardo", "value": "C81976"},
                    {"label": "San Lorenzo", "value": "C81977"},
                    {"label": "San Luis", "value": "C81978"},
                    {"label": "San Manuel", "value": "C81979"},
                    {"label": "San Marcelino", "value": "C81980"},
                    {"label": "San Mariano", "value": "C81981"},
                    {"label": "San Mateo", "value": "C81982"},
                    {"label": "San Miguel", "value": "C81983"},
                    {"label": "San Narciso", "value": "C81984"},
                    {"label": "San Nicolas", "value": "C81985"},
                    {"label": "San Pascual", "value": "C81986"},
                    {"label": "San Patricio", "value": "C81987"},
                    {"label": "San Rafael", "value": "C81988"},
                    {"label": "San Ricardo", "value": "C81989"},
                    {"label": "San Roque", "value": "C81990"},
                    {"label": "San Roque Dau First", "value": "C81991"},
                    {"label": "San Simon", "value": "C81992"},
                    {"label": "San Vicente", "value": "C81993"},
                    {"label": "San Vincente", "value": "C81994"},
                    {"label": "Santa Ana", "value": "C81995"},
                    {"label": "Santa Barbara", "value": "C81996"},
                    {"label": "Santa Cruz", "value": "C81997"},
                    {"label": "Santa Fe", "value": "C81998"},
                    {"label": "Santa Ignacia", "value": "C81999"},
                    {"label": "Santa Ines West", "value": "C82000"},
                    {"label": "Santa Juliana", "value": "C82001"},
                    {"label": "Santa Lucia", "value": "C82002"},
                    {"label": "Santa Maria", "value": "C82003"},
                    {"label": "Santa Monica", "value": "C82004"},
                    {"label": "Santa Rita", "value": "C82005"},
                    {"label": "Santa Rosa", "value": "C82006"},
                    {"label": "Santa Teresa First", "value": "C82007"},
                    {"label": "Santiago", "value": "C82008"},
                    {"label": "Santo Cristo", "value": "C82009"},
                    {"label": "Santo Domingo", "value": "C82010"},
                    {"label": "Santo Nino", "value": "C82011"},
                    {"label": "Santo Rosario", "value": "C82012"},
                    {"label": "Santo Tomas", "value": "C82013"},
                    {"label": "Santol", "value": "C82014"},
                    {"label": "Sapang", "value": "C82015"},
                    {"label": "Sapang Buho", "value": "C82016"},
                    {"label": "Sapol", "value": "C82017"},
                    {"label": "Sasmuan", "value": "C82018"},
                    {"label": "Saysain", "value": "C82019"},
                    {"label": "Sibul", "value": "C82020"},
                    {"label": "Siclong", "value": "C82021"},
                    {"label": "Sinait", "value": "C82022"},
                    {"label": "Sinilian First", "value": "C82023"},
                    {"label": "Soledad", "value": "C82024"},
                    {"label": "Subic", "value": "C82025"},
                    {"label": "Suklayin", "value": "C82026"},
                    {"label": "Sula", "value": "C82027"},
                    {"label": "Sulucan", "value": "C82028"},
                    {"label": "Tabacao", "value": "C82029"},
                    {"label": "Tabon", "value": "C82030"},
                    {"label": "Tabuating", "value": "C82031"},
                    {"label": "Tal I Mun Doc", "value": "C82032"},
                    {"label": "Talaga", "value": "C82033"},
                    {"label": "Talang", "value": "C82034"},
                    {"label": "Talavera", "value": "C82035"},
                    {"label": "Taltal", "value": "C82036"},
                    {"label": "Talugtug", "value": "C82037"},
                    {"label": "Tariji", "value": "C82038"},
                    {"label": "Tarlac City", "value": "C82039"},
                    {"label": "Tayabo", "value": "C82040"},
                    {"label": "Telabastagan", "value": "C82041"},
                    {"label": "Tikiw", "value": "C82042"},
                    {"label": "Tinang", "value": "C82043"},
                    {"label": "Tondod", "value": "C82044"},
                    {"label": "Uacon", "value": "C82045"},
                    {"label": "Umiray", "value": "C82046"},
                    {"label": "Upig", "value": "C82047"},
                    {"label": "Vargas", "value": "C82048"},
                    {"label": "Victoria", "value": "C82049"},
                    {"label": "Villa Aglipay", "value": "C82050"},
                    {"label": "Villa Isla", "value": "C82051"},
                    {"label": "Vizal San Pablo", "value": "C82052"},
                    {"label": "Vizal Santo Nino", "value": "C82053"},
                    {"label": "Zaragoza", "value": "C82054"}],
 "Ahafo": [{"label": "Asunafo North", "value": "C41462"},
           {"label": "Asunafo South", "value": "C41463"},
           {"label": "Asutifi North", "value": "C41464"},
           {"label": "Asutifi South", "value": "C41465"},
           {"label": "Tano North", "value": "C41466"},
           {"label": "Tano South", "value": "C41467"}],
 "Ahal Region": [{"label": "Abadan", "value": "C121161"},
                 {"label": "Annau", "value": "C121162"},
                 {"label": "Arcabil", "value": "C121163"},
                 {"label": "Baharly", "value": "C121164"},
                 {"label": "Kaka", "value": "C121165"},
                 {"label": "Tejen", "value": "C121166"}],
 "Ahuachapan Department": [{"label": "Ahuachapan", "value": "C24364"},
                           {"label": "Atiquizaya", "value": "C24365"},
                           {"label": "Concepcion de Ataco", "value": "C24366"},
                           {"label": "Guaymango", "value": "C24367"},
                           {"label": "Jujutla", "value": "C24368"},
                           {"label": "San Francisco Menendez",
                            "value": "C24369"},
                           {"label": "Tacuba", "value": "C24370"}],
 "Aichi Prefecture": [{"label": "Aisai-shi", "value": "C62524"},
                      {"label": "Ama-gun", "value": "C62525"},
                      {"label": "Anjo-shi", "value": "C62526"},
                      {"label": "Chiryu", "value": "C62527"},
                      {"label": "Chiryu-shi", "value": "C62528"},
                      {"label": "Chita", "value": "C62529"},
                      {"label": "Chita-shi", "value": "C62530"},
                      {"label": "Gamagori", "value": "C62531"},
                      {"label": "Gamagori-shi", "value": "C62532"},
                      {"label": "Handa", "value": "C62533"},
                      {"label": "Handa-shi", "value": "C62534"},
                      {"label": "Hekinan", "value": "C62535"},
                      {"label": "Hekinan-shi", "value": "C62536"},
                      {"label": "Ichinomiya", "value": "C62537"},
                      {"label": "Ichinomiya-shi", "value": "C62538"},
                      {"label": "Inazawa", "value": "C62539"},
                      {"label": "Inazawa-shi", "value": "C62540"},
                      {"label": "Inuyama", "value": "C62541"},
                      {"label": "Inuyama-shi", "value": "C62542"},
                      {"label": "Ishiki", "value": "C62543"},
                      {"label": "Iwakura", "value": "C62544"},
                      {"label": "Iwakura-shi", "value": "C62545"},
                      {"label": "Kanie", "value": "C62546"},
                      {"label": "Kariya", "value": "C62547"},
                      {"label": "Kariya-shi", "value": "C62548"},
                      {"label": "Kasugai", "value": "C62549"},
                      {"label": "Kasugai-shi", "value": "C62550"},
                      {"label": "Kitanagoya", "value": "C62551"},
                      {"label": "Kitanagoya-shi", "value": "C62552"},
                      {"label": "Kiyosu-shi", "value": "C62553"},
                      {"label": "Komaki", "value": "C62554"},
                      {"label": "Komaki-shi", "value": "C62555"},
                      {"label": "Konan", "value": "C62556"},
                      {"label": "Konan-shi", "value": "C62557"},
                      {"label": "Kozakai-cho", "value": "C62558"},
                      {"label": "Kuroda", "value": "C62559"},
                      {"label": "Miyoshi-shi", "value": "C62560"},
                      {"label": "Nagakute-shi", "value": "C62561"},
                      {"label": "Nagoya", "value": "C62562"},
                      {"label": "Nishio", "value": "C62563"},
                      {"label": "Nishio-shi", "value": "C62564"},
                      {"label": "Nisshin-shi", "value": "C62565"},
                      {"label": "Obu", "value": "C62566"},
                      {"label": "Obu-shi", "value": "C62567"},
                      {"label": "Okazaki-shi", "value": "C62568"},
                      {"label": "Owariasahi-shi", "value": "C62569"},
                      {"label": "Seto", "value": "C62570"},
                      {"label": "Seto-shi", "value": "C62571"},
                      {"label": "Shinshiro", "value": "C62572"},
                      {"label": "Shinshiro-shi", "value": "C62573"},
                      {"label": "Sobue", "value": "C62574"},
                      {"label": "Tahara", "value": "C62575"},
                      {"label": "Tahara-shi", "value": "C62576"},
                      {"label": "Takahama", "value": "C62577"},
                      {"label": "Takahama-shi", "value": "C62578"},
                      {"label": "Taketoyo", "value": "C62579"},
                      {"label": "Tokai-shi", "value": "C62580"},
                      {"label": "Tokoname", "value": "C62581"},
                      {"label": "Tokoname-shi", "value": "C62582"},
                      {"label": "Toyoake", "value": "C62583"},
                      {"label": "Toyoake-shi", "value": "C62584"},
                      {"label": "Toyohama", "value": "C62585"},
                      {"label": "Toyohashi", "value": "C62586"},
                      {"label": "Toyohashi-shi", "value": "C62587"},
                      {"label": "Toyokawa", "value": "C62588"},
                      {"label": "Toyokawa-shi", "value": "C62589"},
                      {"label": "Toyota-shi", "value": "C62590"},
                      {"label": "Tsushima", "value": "C62591"},
                      {"label": "Tsushima-shi", "value": "C62592"},
                      {"label": "Yatomi-shi", "value": "C62593"}],
 "Aiga-i-le-Tai": [{"label": "Mulifanua", "value": "C106229"}],
 "Aileu municipality": [{"label": "Aileu", "value": "C24042"},
                        {"label": "Lequidoe", "value": "C24043"},
                        {"label": "Remexio", "value": "C24044"}],
 "Aimeliik": [{"label": "Ngchemiangel", "value": "C79770"}],
 "Ain Defla": [{"label": "Ain Defla", "value": "C227"},
               {"label": "El Abadia", "value": "C228"},
               {"label": "El Attaf", "value": "C229"},
               {"label": "Khemis Miliana", "value": "C230"},
               {"label": "Theniet el Had", "value": "C231"}],
 "Ain Temouchent": [{"label": "Ain Temouchent", "value": "C232"},
                    {"label": "Beni Saf", "value": "C233"},
                    {"label": "El Amria", "value": "C234"},
                    {"label": "El Malah", "value": "C235"},
                    {"label": "Hammam Bou Hadjar", "value": "C236"}],
 "Ainaro Municipality": [{"label": "Ainaro", "value": "C24045"},
                         {"label": "Hato-Udo", "value": "C24046"}],
 "Airai": [{"label": "Ngetkib", "value": "C79771"}],
 "Aisen del General Carlos Ibanez del Campo": [{"label": "Aysen",
                                                "value": "C17892"},
                                               {"label": "Chile Chico",
                                                "value": "C17893"},
                                               {"label": "Cisnes",
                                                "value": "C17894"},
                                               {"label": "Cochrane",
                                                "value": "C17895"},
                                               {"label": "Coyhaique",
                                                "value": "C17896"},
                                               {"label": "Guaitecas",
                                                "value": "C17897"},
                                               {"label": "Lago Verde",
                                                "value": "C17898"},
                                               {"label": "OHiggins",
                                                "value": "C17899"},
                                               {"label": "Rio Ibanez",
                                                "value": "C17900"},
                                               {"label": "Tortel",
                                                "value": "C17901"}],
 "Aiwo District": [{"label": "Arijejen", "value": "C76012"}],
 "Aizkraukle Municipality": [{"label": "Aizkraukle", "value": "C64840"}],
 "Aizpute Municipality": [{"label": "Aizpute", "value": "C64841"}],
 "Ajdovscina Municipality": [{"label": "Ajdovscina", "value": "C107562"},
                             {"label": "Cirkulane", "value": "C107563"},
                             {"label": "Lokavec", "value": "C107564"}],
 "Ajloun Governorate": [{"label": "Ajlun", "value": "C64195"},
                        {"label": "Anjarah", "value": "C64196"},
                        {"label": "Ayn Jannah", "value": "C64197"},
                        {"label": "Halawah", "value": "C64198"},
                        {"label": "Sakhrah", "value": "C64199"}],
 "Ajman Emirate": [{"label": "Ajman", "value": "C123107"},
                   {"label": "Ajman City", "value": "C123108"},
                   {"label": "Manama", "value": "C123109"},
                   {"label": "Masfout", "value": "C123110"}],
 "Akershus": [{"label": "Aneby", "value": "C78616"},
              {"label": "Arnes", "value": "C78617"},
              {"label": "As", "value": "C78618"},
              {"label": "Ask", "value": "C78619"},
              {"label": "Asker", "value": "C78620"},
              {"label": "Auli", "value": "C78621"},
              {"label": "Aurskog-Holand", "value": "C78622"},
              {"label": "Aursmoen", "value": "C78623"},
              {"label": "Baerum", "value": "C78624"},
              {"label": "Billingstad", "value": "C78625"},
              {"label": "Bjorkelangen", "value": "C78626"},
              {"label": "Blakstad", "value": "C78627"},
              {"label": "Drobak", "value": "C78628"},
              {"label": "Eidsvoll", "value": "C78629"},
              {"label": "Enebakk", "value": "C78630"},
              {"label": "Fagerstrand", "value": "C78631"},
              {"label": "Fet", "value": "C78632"},
              {"label": "Fetsund", "value": "C78633"},
              {"label": "Fjellfoten", "value": "C78634"},
              {"label": "Flateby", "value": "C78635"},
              {"label": "Frogn", "value": "C78636"},
              {"label": "Frogner", "value": "C78637"},
              {"label": "Gjerdrum", "value": "C78638"},
              {"label": "Hurdal", "value": "C78639"},
              {"label": "Jessheim", "value": "C78640"},
              {"label": "Klofta", "value": "C78641"},
              {"label": "Leirsund", "value": "C78642"},
              {"label": "Lillestrom", "value": "C78643"},
              {"label": "Lorenskog", "value": "C78644"},
              {"label": "Lysaker", "value": "C78645"},
              {"label": "Maura", "value": "C78646"},
              {"label": "Nannestad", "value": "C78647"},
              {"label": "Nes", "value": "C78648"},
              {"label": "Neskollen", "value": "C78649"},
              {"label": "Nesodden", "value": "C78650"},
              {"label": "Nesoddtangen", "value": "C78651"},
              {"label": "Nittedal", "value": "C78652"},
              {"label": "Oppegard", "value": "C78653"},
              {"label": "Raholt", "value": "C78654"},
              {"label": "Raelingen", "value": "C78655"},
              {"label": "Rotnes", "value": "C78656"},
              {"label": "Skedsmo", "value": "C78657"},
              {"label": "Ski", "value": "C78658"},
              {"label": "Skui", "value": "C78659"},
              {"label": "Sorum", "value": "C78660"},
              {"label": "Sorumsand", "value": "C78661"},
              {"label": "Ullensaker", "value": "C78662"},
              {"label": "Vestby", "value": "C78663"}],
 "Akita Prefecture": [{"label": "Akita", "value": "C62594"},
                      {"label": "Akita Shi", "value": "C62595"},
                      {"label": "Daisen", "value": "C62596"},
                      {"label": "Daisen-shi", "value": "C62597"},
                      {"label": "Hanawa", "value": "C62598"},
                      {"label": "Kakunodatemachi", "value": "C62599"},
                      {"label": "Katagami", "value": "C62600"},
                      {"label": "Katagami-shi", "value": "C62601"},
                      {"label": "Kazuno Shi", "value": "C62602"},
                      {"label": "Kitaakita-shi", "value": "C62603"},
                      {"label": "Nikaho-shi", "value": "C62604"},
                      {"label": "Noshiro", "value": "C62605"},
                      {"label": "Noshiro Shi", "value": "C62606"},
                      {"label": "Odate", "value": "C62607"},
                      {"label": "Odate-shi", "value": "C62608"},
                      {"label": "Oga", "value": "C62609"},
                      {"label": "Oga-shi", "value": "C62610"},
                      {"label": "Omagari", "value": "C62611"},
                      {"label": "Semboku-shi", "value": "C62612"},
                      {"label": "Takanosu", "value": "C62613"},
                      {"label": "Tenno", "value": "C62614"},
                      {"label": "Yokote", "value": "C62615"},
                      {"label": "Yokote-shi", "value": "C62616"},
                      {"label": "Yurihonjo", "value": "C62617"},
                      {"label": "Yurihonjo-shi", "value": "C62618"},
                      {"label": "Yuzawa", "value": "C62619"},
                      {"label": "Yuzawa-shi", "value": "C62620"}],
 "Akkar Governorate": [{"label": "Caza de Aakkar", "value": "C64964"}],
 "Akmola Region": [{"label": "Akkol", "value": "C64277"},
                   {"label": "Akkol", "value": "C64278"},
                   {"label": "Aksu", "value": "C64279"},
                   {"label": "Astrakhan", "value": "C64280"},
                   {"label": "Atbasar", "value": "C64281"},
                   {"label": "Balkashino", "value": "C64282"},
                   {"label": "Bestobe", "value": "C64283"},
                   {"label": "Derzhavinsk", "value": "C64284"},
                   {"label": "Egindikol", "value": "C64285"},
                   {"label": "Esil", "value": "C64286"},
                   {"label": "Kokshetau", "value": "C64287"},
                   {"label": "Krasnogorskiy", "value": "C64288"},
                   {"label": "Makinsk", "value": "C64289"},
                   {"label": "Shantobe", "value": "C64290"},
                   {"label": "Shchuchinsk", "value": "C64291"},
                   {"label": "Shortandy", "value": "C64292"},
                   {"label": "Stepnogorsk", "value": "C64293"},
                   {"label": "Stepnyak", "value": "C64294"},
                   {"label": "Yermentau", "value": "C64295"},
                   {"label": "Zavodskoy", "value": "C64296"},
                   {"label": "Zhaqsy", "value": "C64297"},
                   {"label": "Zholymbet", "value": "C64298"}],
 "Akniste Municipality": [{"label": "Akniste", "value": "C64842"}],
 "Aksaray": [{"label": "Agacoren", "value": "C119870"},
             {"label": "Aksaray", "value": "C119871"},
             {"label": "Eskil Ilcesi", "value": "C119872"},
             {"label": "Gulagac Ilcesi", "value": "C119873"},
             {"label": "Guzelyurt", "value": "C119874"},
             {"label": "Merkez", "value": "C119875"},
             {"label": "Ortakoy", "value": "C119876"},
             {"label": "Sariyahsi", "value": "C119877"},
             {"label": "Sultanhani", "value": "C119878"}],
 "Aktobe Region": [{"label": "Aktobe", "value": "C64299"},
                   {"label": "Batamshinskiy", "value": "C64300"},
                   {"label": "Bayganin", "value": "C64301"},
                   {"label": "Embi", "value": "C64302"},
                   {"label": "Kandyagash", "value": "C64303"},
                   {"label": "Khromtau", "value": "C64304"},
                   {"label": "Martuk", "value": "C64305"},
                   {"label": "Shalqar", "value": "C64306"},
                   {"label": "Shubarkuduk", "value": "C64307"},
                   {"label": "Shubarshi", "value": "C64308"},
                   {"label": "Temir", "value": "C64309"},
                   {"label": "Yrghyz", "value": "C64310"}],
 "Akwa Ibom": [{"label": "Eket", "value": "C78133"},
               {"label": "Esuk Oron", "value": "C78134"},
               {"label": "Ikot Ekpene", "value": "C78135"},
               {"label": "Itu", "value": "C78136"},
               {"label": "Uyo", "value": "C78137"}],
 "Al Ahmadi Governorate": [{"label": "Al Ahmadi", "value": "C64685"},
                           {"label": "Al Fahahil", "value": "C64686"},
                           {"label": "Al Fintas", "value": "C64687"},
                           {"label": "Al Mahbulah", "value": "C64688"},
                           {"label": "Al Manqaf", "value": "C64689"},
                           {"label": "Al Wafrah", "value": "C64690"},
                           {"label": "Ar Riqqah", "value": "C64691"}],
 "Al Anbar Governorate": [{"label": "Anah", "value": "C51133"},
                          {"label": "Anat al Qadimah", "value": "C51134"},
                          {"label": "Al Fallujah", "value": "C51135"},
                          {"label": "Ar Rutbah", "value": "C51136"},
                          {"label": "Hadithah", "value": "C51137"},
                          {"label": "Hit", "value": "C51138"},
                          {"label": "Hit District", "value": "C51139"},
                          {"label": "Ramadi", "value": "C51140"},
                          {"label": "Rawah", "value": "C51141"}],
 "Al Bahah": [{"label": "Abu `Urwah", "value": "C106273"},
              {"label": "Abu Hisani", "value": "C106274"},
              {"label": "Abu Qirfah", "value": "C106275"},
              {"label": "Abu Shu`ayb", "value": "C106276"},
              {"label": "Ad Dabbah", "value": "C106277"},
              {"label": "Ad Dawh", "value": "C106278"},
              {"label": "Ad Dur", "value": "C106279"},
              {"label": "Al abar", "value": "C106280"},
              {"label": "Al Adl", "value": "C106281"},
              {"label": "Al Ashraf", "value": "C106282"},
              {"label": "Al Bahah", "value": "C106283"},
              {"label": "Al Balad", "value": "C106284"},
              {"label": "Al Barabir", "value": "C106285"},
              {"label": "Al Biar", "value": "C106286"},
              {"label": "Al Birk", "value": "C106287"},
              {"label": "Al Buraykah", "value": "C106288"},
              {"label": "Al Fawwarah", "value": "C106289"},
              {"label": "Al Faydah", "value": "C106290"},
              {"label": "Al Fazz", "value": "C106291"},
              {"label": "Al Gharith", "value": "C106292"},
              {"label": "Al Ghassalah", "value": "C106293"},
              {"label": "Al Ghulah", "value": "C106294"},
              {"label": "Al Hada", "value": "C106295"},
              {"label": "Al Halaqah", "value": "C106296"},
              {"label": "Al Hamimah", "value": "C106297"},
              {"label": "Al Harra, Makkah", "value": "C106298"},
              {"label": "Al Hawiyah", "value": "C106299"},
              {"label": "Al Iskan", "value": "C106300"},
              {"label": "Al Jadidah", "value": "C106301"},
              {"label": "Al Jami`ah", "value": "C106302"},
              {"label": "Al Jid`", "value": "C106303"},
              {"label": "Al Ju`ranah", "value": "C106304"},
              {"label": "Al Khadra, Makkah", "value": "C106305"},
              {"label": "Al Khalas", "value": "C106306"},
              {"label": "Al Khamrah", "value": "C106307"},
              {"label": "Al Khaydar", "value": "C106308"},
              {"label": "Al Khayf", "value": "C106309"},
              {"label": "Al Khulasah", "value": "C106310"},
              {"label": "Al Kidwah", "value": "C106311"},
              {"label": "Al Kura`", "value": "C106312"},
              {"label": "Al Ma`rash", "value": "C106313"},
              {"label": "Al Madiq, Makkah", "value": "C106314"},
              {"label": "Al Maghal", "value": "C106315"},
              {"label": "Al Mahjar", "value": "C106316"},
              {"label": "Al Maqrah", "value": "C106317"},
              {"label": "Al Masarrah", "value": "C106318"},
              {"label": "Al Masfalah", "value": "C106319"},
              {"label": "Al Mashayikh", "value": "C106320"},
              {"label": "Al Mathnah", "value": "C106321"},
              {"label": "Al Mindak", "value": "C106322"},
              {"label": "Al Mubarak", "value": "C106323"},
              {"label": "Al Mudawwarah", "value": "C106324"},
              {"label": "Al Mulayha", "value": "C106325"},
              {"label": "Al Mundassah", "value": "C106326"},
              {"label": "Al Muqayti`", "value": "C106327"},
              {"label": "Al Muqr", "value": "C106328"},
              {"label": "Al Muwayh", "value": "C106329"},
              {"label": "Al Qadimah", "value": "C106330"},
              {"label": "Al Qararah", "value": "C106331"},
              {"label": "Al Qaryat", "value": "C106332"},
              {"label": "Al Qawba`iyah", "value": "C106333"},
              {"label": "Al Qirshan", "value": "C106334"},
              {"label": "Al Qu`tubah", "value": "C106335"},
              {"label": "Al Qufayf", "value": "C106336"},
              {"label": "Al Qushashiyah", "value": "C106337"},
              {"label": "Al Ukhaydir", "value": "C106338"},
              {"label": "Al Waht", "value": "C106339"},
              {"label": "Ar Rabwah as Sufla", "value": "C106340"},
              {"label": "Ar Rafah", "value": "C106341"},
              {"label": "Ar Rawdah ash Shamaliyah", "value": "C106342"},
              {"label": "Ar Rudaymah", "value": "C106343"},
              {"label": "Arya`", "value": "C106344"},
              {"label": "As Sadr", "value": "C106345"},
              {"label": "As Samd ash Shamali", "value": "C106346"},
              {"label": "As Sayl al Kabir", "value": "C106347"},
              {"label": "As Sayl as Saghir", "value": "C106348"},
              {"label": "As Sifyani", "value": "C106349"},
              {"label": "As Sudayrah, Makkah", "value": "C106350"},
              {"label": "As Suwadah", "value": "C106351"},
              {"label": "Ash Shajwah", "value": "C106352"},
              {"label": "Ash Shamiyah", "value": "C106353"},
              {"label": "Ash Sharai`", "value": "C106354"},
              {"label": "Ash Shaybi", "value": "C106355"},
              {"label": "Ash Shi`b", "value": "C106356"},
              {"label": "Ash Shishah", "value": "C106357"},
              {"label": "Ash Shumaysi", "value": "C106358"},
              {"label": "Ash Shuwaybit", "value": "C106359"},
              {"label": "At Tan`im", "value": "C106360"},
              {"label": "At Tarfa", "value": "C106361"},
              {"label": "At Turqi", "value": "C106362"},
              {"label": "Az Zaymah", "value": "C106363"},
              {"label": "Az Zilal", "value": "C106364"},
              {"label": "Az Zughbah", "value": "C106365"},
              {"label": "Az Zurra`", "value": "C106366"},
              {"label": "Az Zuwayb", "value": "C106367"},
              {"label": "Bahrat al Qadimah", "value": "C106368"},
              {"label": "Bahwil", "value": "C106369"},
              {"label": "Baranah", "value": "C106370"},
              {"label": "Barzah", "value": "C106371"},
              {"label": "Bashm", "value": "C106372"},
              {"label": "Buraykah", "value": "C106373"},
              {"label": "Burayman", "value": "C106374"},
              {"label": "CITY GHRAN", "value": "C106375"},
              {"label": "Dabyah", "value": "C106376"},
              {"label": "Dahaban", "value": "C106377"},
              {"label": "Dughaybjah", "value": "C106378"},
              {"label": "Fayd", "value": "C106379"},
              {"label": "Ghran", "value": "C106380"},
              {"label": "Hadda", "value": "C106381"},
              {"label": "Haddat ash Sham", "value": "C106382"},
              {"label": "Hadhah", "value": "C106383"},
              {"label": "Hajrah", "value": "C106384"},
              {"label": "Hajur", "value": "C106385"},
              {"label": "Halamah", "value": "C106386"},
              {"label": "Husnah", "value": "C106387"},
              {"label": "Jarwal", "value": "C106388"},
              {"label": "Jeddah", "value": "C106389"},
              {"label": "Julayyil", "value": "C106390"},
              {"label": "Khumrah", "value": "C106391"},
              {"label": "Kulakh", "value": "C106392"},
              {"label": "Madrakah", "value": "C106393"},
              {"label": "Mafruq", "value": "C106394"},
              {"label": "Malakan", "value": "C106395"},
              {"label": "Mashajji", "value": "C106396"},
              {"label": "Masihat Mahd al Hayl", "value": "C106397"},
              {"label": "Maskar", "value": "C106398"},
              {"label": "Matiyah", "value": "C106399"},
              {"label": "Mecca", "value": "C106400"},
              {"label": "Mina", "value": "C106401"},
              {"label": "Murshidiyah", "value": "C106402"},
              {"label": "Mushrif", "value": "C106403"},
              {"label": "Nughayshiyah", "value": "C106404"},
              {"label": "Nuzlat al Faqin", "value": "C106405"},
              {"label": "Qiya", "value": "C106406"},
              {"label": "Quwayzah", "value": "C106407"},
              {"label": "Rabwah Ghran", "value": "C106408"},
              {"label": "Raqiyah", "value": "C106409"},
              {"label": "Sabuhah", "value": "C106410"},
              {"label": "Shi`b `amir", "value": "C106411"},
              {"label": "Shira`ayn", "value": "C106412"},
              {"label": "Sulaym", "value": "C106413"},
              {"label": "Sumaymah", "value": "C106414"},
              {"label": "Suways", "value": "C106415"},
              {"label": "Taif", "value": "C106416"},
              {"label": "Tharwah", "value": "C106417"},
              {"label": "Thuwal", "value": "C106418"},
              {"label": "Usfan", "value": "C106419"},
              {"label": "Wadi al Jalil", "value": "C106420"}],
 "Al Batinah North Governorate": [{"label": "Al Khaburah", "value": "C79292"},
                                  {"label": "As Suwayq", "value": "C79293"},
                                  {"label": "Liwa", "value": "C79294"},
                                  {"label": "Saham", "value": "C79295"},
                                  {"label": "Shinas", "value": "C79296"},
                                  {"label": "Sohar", "value": "C79297"}],
 "Al Batinah Region": [{"label": "Barka", "value": "C79298"},
                       {"label": "Bayt al Awabi", "value": "C79299"},
                       {"label": "Oman Smart Future City", "value": "C79300"},
                       {"label": "Rustaq", "value": "C79301"}],
 "Al Bayda Governorate": [{"label": "Al Arsh", "value": "C147786"},
                          {"label": "Al Bayda", "value": "C147787"},
                          {"label": "Al Bayda City", "value": "C147788"},
                          {"label": "Al Bayda", "value": "C147789"},
                          {"label": "Al Malagim", "value": "C147790"},
                          {"label": "Al Quraishyah", "value": "C147791"},
                          {"label": "Ar Ryashyyah", "value": "C147792"},
                          {"label": "As Sawadiyah", "value": "C147793"},
                          {"label": "As Sawmaah", "value": "C147794"},
                          {"label": "Ash Sharafayn", "value": "C147795"},
                          {"label": "At Taffah", "value": "C147796"},
                          {"label": "Az Zahir", "value": "C147797"},
                          {"label": "Dhi Naim", "value": "C147798"},
                          {"label": "Maswarah", "value": "C147799"},
                          {"label": "Mukayras", "value": "C147800"},
                          {"label": "Naman", "value": "C147801"},
                          {"label": "Nati", "value": "C147802"},
                          {"label": "Rada", "value": "C147803"},
                          {"label": "Radman Al Awad", "value": "C147804"},
                          {"label": "Sabah", "value": "C147805"},
                          {"label": "Wald Rabi", "value": "C147806"}],
 "Al Buraimi Governorate": [{"label": "Al Buraymi", "value": "C79302"}],
 "Al Farwaniyah Governorate": [{"label": "Al Farwaniyah", "value": "C64692"},
                               {"label": "Janub as Surrah", "value": "C64693"}],
 "Al Hudaydah Governorate": [{"label": "Ad Dahi", "value": "C147807"},
                             {"label": "Ad Durayhimi", "value": "C147808"},
                             {"label": "Al Garrahi", "value": "C147809"},
                             {"label": "Al Hajjaylah", "value": "C147810"},
                             {"label": "Al Hali", "value": "C147811"},
                             {"label": "Al Hawak", "value": "C147812"},
                             {"label": "Al Hudaydah", "value": "C147813"},
                             {"label": "Al Mansuriyah", "value": "C147814"},
                             {"label": "Al Marawiah", "value": "C147815"},
                             {"label": "Al Mighlaf", "value": "C147816"},
                             {"label": "Al Mina", "value": "C147817"},
                             {"label": "Al Munirah", "value": "C147818"},
                             {"label": "Al Qanawis", "value": "C147819"},
                             {"label": "Alluheyah", "value": "C147820"},
                             {"label": "As Salif", "value": "C147821"},
                             {"label": "As Sukhnah", "value": "C147822"},
                             {"label": "At Tuhayat", "value": "C147823"},
                             {"label": "Az Zaydiyah", "value": "C147824"},
                             {"label": "Az Zuhrah", "value": "C147825"},
                             {"label": "Bajil", "value": "C147826"},
                             {"label": "Bayt al Faqih", "value": "C147827"},
                             {"label": "Bura", "value": "C147828"},
                             {"label": "Hays", "value": "C147829"},
                             {"label": "Jabal Ras", "value": "C147830"},
                             {"label": "Kamaran", "value": "C147831"},
                             {"label": "Zabid", "value": "C147832"}],
 "Al Jahra Governorate": [{"label": "Al Jahra", "value": "C64694"}],
 "Al Jawf": [{"label": "Al Isawiyah", "value": "C106421"},
             {"label": "Al-Haditha", "value": "C106422"},
             {"label": "Halat Ammar", "value": "C106423"},
             {"label": "Qurayyat", "value": "C106424"},
             {"label": "Sakakah", "value": "C106425"},
             {"label": "Suwayr", "value": "C106426"},
             {"label": "Tabarjal", "value": "C106427"},
             {"label": "Tubarjal", "value": "C106428"}],
 "Al Jawf Governorate": [{"label": "Al Inan", "value": "C147833"},
                         {"label": "Al Ghayl", "value": "C147834"},
                         {"label": "Al Hazm", "value": "C147835"},
                         {"label": "Al Humaydat", "value": "C147836"},
                         {"label": "Al Khalq", "value": "C147837"},
                         {"label": "Al Maslub", "value": "C147838"},
                         {"label": "Al Matammah", "value": "C147839"},
                         {"label": "Al Maton", "value": "C147840"},
                         {"label": "Az Zahir", "value": "C147841"},
                         {"label": "Bart al Anan", "value": "C147842"},
                         {"label": "Khabb wa ash Shaaf", "value": "C147843"},
                         {"label": "Kharab Al Marashi", "value": "C147844"},
                         {"label": "Rajuzah", "value": "C147845"}],
 "Al Jazirah": [{"label": "Al Hasaheisa", "value": "C115389"},
                {"label": "Al Hilaliyya", "value": "C115390"},
                {"label": "Al Kiremit al Arakiyyin", "value": "C115391"},
                {"label": "Al Manaqil", "value": "C115392"},
                {"label": "Al Masallamiyya", "value": "C115393"},
                {"label": "Wad Medani", "value": "C115394"},
                {"label": "Wad Rawah", "value": "C115395"}],
 "Al Khor": [{"label": "Al Ghuwayriyah", "value": "C92069"},
             {"label": "Al Khawr", "value": "C92070"}],
 "Al Madinah": [{"label": "`Ajmiyah", "value": "C106429"},
                {"label": "`Alya", "value": "C106430"},
                {"label": "`Ushash", "value": "C106431"},
                {"label": "`Ushayrah", "value": "C106432"},
                {"label": "Abu Dhiba", "value": "C106433"},
                {"label": "Abu Shaytanah", "value": "C106434"},
                {"label": "Abyar Ali", "value": "C106435"},
                {"label": "Ad Dulu`", "value": "C106436"},
                {"label": "Al `Awali", "value": "C106437"},
                {"label": "Al `Uqul", "value": "C106438"},
                {"label": "Al Akhal", "value": "C106439"},
                {"label": "Al Bardiyah", "value": "C106440"},
                {"label": "Al Biqa", "value": "C106441"},
                {"label": "Al Bustan", "value": "C106442"},
                {"label": "Al Faqirah", "value": "C106443"},
                {"label": "Al Furaysh", "value": "C106444"},
                {"label": "Al Fuwayliq", "value": "C106445"},
                {"label": "Al Jabriyah", "value": "C106446"},
                {"label": "Al Jissah", "value": "C106447"},
                {"label": "Al Kharma", "value": "C106448"},
                {"label": "Al Madiq, Al Madinah", "value": "C106449"},
                {"label": "Al Malbanah", "value": "C106450"},
                {"label": "Al Mufrihat", "value": "C106451"},
                {"label": "Al Multasa", "value": "C106452"},
                {"label": "Al Musayjid", "value": "C106453"},
                {"label": "Al Thybiyah", "value": "C106454"},
                {"label": "Al Wuday", "value": "C106455"},
                {"label": "Al-Jafr", "value": "C106456"},
                {"label": "Al-Ula", "value": "C106457"},
                {"label": "Ar Rass", "value": "C106458"},
                {"label": "As Sadayir", "value": "C106459"},
                {"label": "As Safra", "value": "C106460"},
                {"label": "As Sumariyah", "value": "C106461"},
                {"label": "As Suwayriqiyah", "value": "C106462"},
                {"label": "Ash Shufayyah", "value": "C106463"},
                {"label": "Asira", "value": "C106464"},
                {"label": "Badr Hunayn", "value": "C106465"},
                {"label": "Baq`a", "value": "C106466"},
                {"label": "Bartiyah", "value": "C106467"},
                {"label": "Bir al Mashi", "value": "C106468"},
                {"label": "Birkah", "value": "C106469"},
                {"label": "Buraidah", "value": "C106470"},
                {"label": "Dukhnah", "value": "C106471"},
                {"label": "Far`", "value": "C106472"},
                {"label": "Fiji", "value": "C106473"},
                {"label": "Harthiyah", "value": "C106474"},
                {"label": "Hasa, Al Madinah", "value": "C106475"},
                {"label": "Haylat Radi al Baham", "value": "C106476"},
                {"label": "Husayniyah", "value": "C106477"},
                {"label": "Jadidah", "value": "C106478"},
                {"label": "Khayf Fadil", "value": "C106479"},
                {"label": "Madsus", "value": "C106480"},
                {"label": "Mahattat al Hafah", "value": "C106481"},
                {"label": "Maqrah", "value": "C106482"},
                {"label": "Maqshush", "value": "C106483"},
                {"label": "Masahili", "value": "C106484"},
                {"label": "Mastoorah", "value": "C106485"},
                {"label": "Mawarah", "value": "C106486"},
                {"label": "Medina", "value": "C106487"},
                {"label": "Milhah", "value": "C106488"},
                {"label": "Nujayl", "value": "C106489"},
                {"label": "Qaba", "value": "C106490"},
                {"label": "Qiba", "value": "C106491"},
                {"label": "Rayyis", "value": "C106492"},
                {"label": "Sha`tha", "value": "C106493"},
                {"label": "Sidi Hamzah", "value": "C106494"},
                {"label": "Sultanah", "value": "C106495"},
                {"label": "Suq Suwayq", "value": "C106496"},
                {"label": "Suqubiya", "value": "C106497"},
                {"label": "Suwadah", "value": "C106498"},
                {"label": "Tanumah", "value": "C106499"},
                {"label": "Wasitah", "value": "C106500"},
                {"label": "Yanbu", "value": "C106501"}],
 "Al Mahrah Governorate": [{"label": "Al Ghaydah", "value": "C147846"},
                           {"label": "Al Ghayzah", "value": "C147847"},
                           {"label": "Al Masilah", "value": "C147848"},
                           {"label": "Hat", "value": "C147849"},
                           {"label": "Hawf", "value": "C147850"},
                           {"label": "Huswain", "value": "C147851"},
                           {"label": "Manar", "value": "C147852"},
                           {"label": "Qishn", "value": "C147853"},
                           {"label": "Sayhut", "value": "C147854"},
                           {"label": "Shahan", "value": "C147855"}],
 "Al Mahwit Governorate": [{"label": "Al Khabt", "value": "C147856"},
                           {"label": "Al Mahwait", "value": "C147857"},
                           {"label": "Al Mahwit", "value": "C147858"},
                           {"label": "Ar Rujum", "value": "C147859"},
                           {"label": "At Tawilah", "value": "C147860"},
                           {"label": "Bani Sad", "value": "C147861"},
                           {"label": "Hufash", "value": "C147862"},
                           {"label": "Milhan", "value": "C147863"},
                           {"label": "Shibam Kawkaban", "value": "C147864"}],
 "Al Muthanna Governorate": [{"label": "Ar Rumaythah", "value": "C51142"},
                             {"label": "As Samawah", "value": "C51143"}],
 "Al Qadarif": [{"label": "Al Hawatah", "value": "C115396"},
                {"label": "Al Qadarif", "value": "C115397"},
                {"label": "Doka", "value": "C115398"}],
 "Al Rayyan Municipality": [{"label": "Ar Rayyan", "value": "C92071"},
                            {"label": "Umm Bab", "value": "C92072"}],
 "Al Wahat District": [{"label": "Ajdabiya", "value": "C65020"},
                       {"label": "Al Burayqah", "value": "C65021"},
                       {"label": "Awjilah", "value": "C65022"},
                       {"label": "Az Zuwaytinah", "value": "C65023"},
                       {"label": "Gialo", "value": "C65024"},
                       {"label": "Maradah", "value": "C65025"}],
 "Al Wakrah": [{"label": "Al Wakrah", "value": "C92073"},
               {"label": "Al Wukayr", "value": "C92074"},
               {"label": "Musayid", "value": "C92075"}],
 "Al Wusta Governorate": [{"label": "Hayma", "value": "C79303"}],
 "Al-Hasakah Governorate": [{"label": "Ad Darbasiyah", "value": "C117813"},
                            {"label": "Al Hasakah", "value": "C117814"},
                            {"label": "Al Malikiyah", "value": "C117815"},
                            {"label": "Al Qamishli", "value": "C117816"},
                            {"label": "Al-Malikiyah District",
                             "value": "C117817"},
                            {"label": "Amude", "value": "C117818"}],
 "Al-Qadisiyyah Governorate": [{"label": "Afak", "value": "C51144"},
                               {"label": "Ad Diwaniyah", "value": "C51145"},
                               {"label": "Ash Shamiyah", "value": "C51146"},
                               {"label": "Nahiyat ash Shinafiyah",
                                "value": "C51147"},
                               {"label": "Nahiyat Ghammas", "value": "C51148"}],
 "Al-Qassim": [{"label": "Adh Dhibiyah", "value": "C106502"},
               {"label": "Al Bukayriyah", "value": "C106503"},
               {"label": "Al Fuwayliq", "value": "C106504"},
               {"label": "Al Mithnab", "value": "C106505"},
               {"label": "Al Thybiyah", "value": "C106506"},
               {"label": "Ar Rass", "value": "C106507"},
               {"label": "Arar", "value": "C106508"},
               {"label": "Buraidah", "value": "C106509"},
               {"label": "Buraydah", "value": "C106510"},
               {"label": "Dukhnah", "value": "C106511"},
               {"label": "Nisab", "value": "C106512"},
               {"label": "Qiba", "value": "C106513"},
               {"label": "Tanumah", "value": "C106514"},
               {"label": "Umm Radamah", "value": "C106515"},
               {"label": "Wed Alnkil", "value": "C106516"}],
 "Al-Raqqah Governorate": [{"label": "Al-Thawrah District", "value": "C117819"},
                           {"label": "Ar Raqqah", "value": "C117820"},
                           {"label": "Ar-Raqqah District", "value": "C117821"},
                           {"label": "Ath Thawrah", "value": "C117822"},
                           {"label": "Tall Abyad", "value": "C117823"},
                           {"label": "Tell Abyad District",
                            "value": "C117824"}],
 "Al-Shahaniya": [{"label": "Al Jumayliyah", "value": "C92076"},
                  {"label": "Ash Shihaniyah", "value": "C92077"},
                  {"label": "Dukhan", "value": "C92078"}],
 "Alabama": [{"label": "Abbeville", "value": "C127005"},
             {"label": "Adamsville", "value": "C127006"},
             {"label": "Alabaster", "value": "C127007"},
             {"label": "Albertville", "value": "C127008"},
             {"label": "Alexander City", "value": "C127009"},
             {"label": "Alexandria", "value": "C127010"},
             {"label": "Aliceville", "value": "C127011"},
             {"label": "Andalusia", "value": "C127012"},
             {"label": "Anniston", "value": "C127013"},
             {"label": "Arab", "value": "C127014"},
             {"label": "Argo", "value": "C127015"},
             {"label": "Ashford", "value": "C127016"},
             {"label": "Ashland", "value": "C127017"},
             {"label": "Ashville", "value": "C127018"},
             {"label": "Athens", "value": "C127019"},
             {"label": "Atmore", "value": "C127020"},
             {"label": "Attalla", "value": "C127021"},
             {"label": "Auburn", "value": "C127022"},
             {"label": "Autauga County", "value": "C127023"},
             {"label": "Baldwin County", "value": "C127024"},
             {"label": "Ballplay", "value": "C127025"},
             {"label": "Barbour County", "value": "C127026"},
             {"label": "Bay Minette", "value": "C127027"},
             {"label": "Bayou La Batre", "value": "C127028"},
             {"label": "Bear Creek", "value": "C127029"},
             {"label": "Berry", "value": "C127030"},
             {"label": "Bessemer", "value": "C127031"},
             {"label": "Bibb County", "value": "C127032"},
             {"label": "Birmingham", "value": "C127033"},
             {"label": "Blount County", "value": "C127034"},
             {"label": "Blountsville", "value": "C127035"},
             {"label": "Blue Ridge", "value": "C127036"},
             {"label": "Boaz", "value": "C127037"},
             {"label": "Brent", "value": "C127038"},
             {"label": "Brewton", "value": "C127039"},
             {"label": "Bridgeport", "value": "C127040"},
             {"label": "Brighton", "value": "C127041"},
             {"label": "Brook Highland", "value": "C127042"},
             {"label": "Brookside", "value": "C127043"},
             {"label": "Brookwood", "value": "C127044"},
             {"label": "Brundidge", "value": "C127045"},
             {"label": "Bullock County", "value": "C127046"},
             {"label": "Butler", "value": "C127047"},
             {"label": "Butler County", "value": "C127048"},
             {"label": "Bynum", "value": "C127049"},
             {"label": "Cahaba Heights", "value": "C127050"},
             {"label": "Calera", "value": "C127051"},
             {"label": "Calhoun County", "value": "C127052"},
             {"label": "Camden", "value": "C127053"},
             {"label": "Carbon Hill", "value": "C127054"},
             {"label": "Carlisle-Rockledge", "value": "C127055"},
             {"label": "Carrollton", "value": "C127056"},
             {"label": "Cedar Bluff", "value": "C127057"},
             {"label": "Center Point", "value": "C127058"},
             {"label": "Centre", "value": "C127059"},
             {"label": "Centreville", "value": "C127060"},
             {"label": "Chalkville", "value": "C127061"},
             {"label": "Chambers County", "value": "C127062"},
             {"label": "Chatom", "value": "C127063"},
             {"label": "Chelsea", "value": "C127064"},
             {"label": "Cherokee", "value": "C127065"},
             {"label": "Cherokee County", "value": "C127066"},
             {"label": "Chickasaw", "value": "C127067"},
             {"label": "Childersburg", "value": "C127068"},
             {"label": "Chilton County", "value": "C127069"},
             {"label": "Choccolocco", "value": "C127070"},
             {"label": "Choctaw County", "value": "C127071"},
             {"label": "Citronelle", "value": "C127072"},
             {"label": "Clanton", "value": "C127073"},
             {"label": "Clarke County", "value": "C127074"},
             {"label": "Clay", "value": "C127075"},
             {"label": "Clay County", "value": "C127076"},
             {"label": "Clayton", "value": "C127077"},
             {"label": "Cleburne County", "value": "C127078"},
             {"label": "Cleveland", "value": "C127079"},
             {"label": "Clio", "value": "C127080"},
             {"label": "Coaling", "value": "C127081"},
             {"label": "Coffee County", "value": "C127082"},
             {"label": "Colbert County", "value": "C127083"},
             {"label": "Collinsville", "value": "C127084"},
             {"label": "Columbiana", "value": "C127085"},
             {"label": "Concord", "value": "C127086"},
             {"label": "Conecuh County", "value": "C127087"},
             {"label": "Coosa County", "value": "C127088"},
             {"label": "Coosada", "value": "C127089"},
             {"label": "Cordova", "value": "C127090"},
             {"label": "Cottonwood", "value": "C127091"},
             {"label": "Covington County", "value": "C127092"},
             {"label": "Cowarts", "value": "C127093"},
             {"label": "Crenshaw County", "value": "C127094"},
             {"label": "Creola", "value": "C127095"},
             {"label": "Crossville", "value": "C127096"},
             {"label": "Cullman", "value": "C127097"},
             {"label": "Cullman County", "value": "C127098"},
             {"label": "Dadeville", "value": "C127099"},
             {"label": "Dale County", "value": "C127100"},
             {"label": "Daleville", "value": "C127101"},
             {"label": "Dallas County", "value": "C127102"},
             {"label": "Danville", "value": "C127103"},
             {"label": "Daphne", "value": "C127104"},
             {"label": "Dauphin Island", "value": "C127105"},
             {"label": "Deatsville", "value": "C127106"},
             {"label": "Decatur", "value": "C127107"},
             {"label": "DeKalb County", "value": "C127108"},
             {"label": "Demopolis", "value": "C127109"},
             {"label": "Dixiana", "value": "C127110"},
             {"label": "Dora", "value": "C127111"},
             {"label": "Dothan", "value": "C127112"},
             {"label": "Double Springs", "value": "C127113"},
             {"label": "East Brewton", "value": "C127114"},
             {"label": "East Florence", "value": "C127115"},
             {"label": "Eclectic", "value": "C127116"},
             {"label": "Elba", "value": "C127117"},
             {"label": "Elberta", "value": "C127118"},
             {"label": "Elmore", "value": "C127119"},
             {"label": "Elmore County", "value": "C127120"},
             {"label": "Emerald Mountain", "value": "C127121"},
             {"label": "Enterprise", "value": "C127122"},
             {"label": "Escambia County", "value": "C127123"},
             {"label": "Etowah County", "value": "C127124"},
             {"label": "Eufaula", "value": "C127125"},
             {"label": "Eutaw", "value": "C127126"},
             {"label": "Evergreen", "value": "C127127"},
             {"label": "Fairfield", "value": "C127128"},
             {"label": "Fairhope", "value": "C127129"},
             {"label": "Falkville", "value": "C127130"},
             {"label": "Fayette", "value": "C127131"},
             {"label": "Fayette County", "value": "C127132"},
             {"label": "Fayetteville", "value": "C127133"},
             {"label": "Flint City", "value": "C127134"},
             {"label": "Flomaton", "value": "C127135"},
             {"label": "Florala", "value": "C127136"},
             {"label": "Florence", "value": "C127137"},
             {"label": "Foley", "value": "C127138"},
             {"label": "Forestdale", "value": "C127139"},
             {"label": "Fort Deposit", "value": "C127140"},
             {"label": "Fort Payne", "value": "C127141"},
             {"label": "Fort Rucker", "value": "C127142"},
             {"label": "Franklin County", "value": "C127143"},
             {"label": "Frisco City", "value": "C127144"},
             {"label": "Fultondale", "value": "C127145"},
             {"label": "Fyffe", "value": "C127146"},
             {"label": "Gadsden", "value": "C127147"},
             {"label": "Gardendale", "value": "C127148"},
             {"label": "Geneva", "value": "C127149"},
             {"label": "Geneva County", "value": "C127150"},
             {"label": "Georgiana", "value": "C127151"},
             {"label": "Glencoe", "value": "C127152"},
             {"label": "Good Hope", "value": "C127153"},
             {"label": "Goodwater", "value": "C127154"},
             {"label": "Gordo", "value": "C127155"},
             {"label": "Grand Bay", "value": "C127156"},
             {"label": "Grayson Valley", "value": "C127157"},
             {"label": "Graysville", "value": "C127158"},
             {"label": "Greene County", "value": "C127159"},
             {"label": "Greensboro", "value": "C127160"},
             {"label": "Greenville", "value": "C127161"},
             {"label": "Grove Hill", "value": "C127162"},
             {"label": "Guin", "value": "C127163"},
             {"label": "Gulf Shores", "value": "C127164"},
             {"label": "Guntersville", "value": "C127165"},
             {"label": "Hackleburg", "value": "C127166"},
             {"label": "Hale County", "value": "C127167"},
             {"label": "Haleyville", "value": "C127168"},
             {"label": "Hamilton", "value": "C127169"},
             {"label": "Hanceville", "value": "C127170"},
             {"label": "Harpersville", "value": "C127171"},
             {"label": "Hartford", "value": "C127172"},
             {"label": "Hartselle", "value": "C127173"},
             {"label": "Harvest", "value": "C127174"},
             {"label": "Hayden", "value": "C127175"},
             {"label": "Hayneville", "value": "C127176"},
             {"label": "Hazel Green", "value": "C127177"},
             {"label": "Headland", "value": "C127178"},
             {"label": "Heflin", "value": "C127179"},
             {"label": "Helena", "value": "C127180"},
             {"label": "Henagar", "value": "C127181"},
             {"label": "Henry County", "value": "C127182"},
             {"label": "Highland Lakes", "value": "C127183"},
             {"label": "Hokes Bluff", "value": "C127184"},
             {"label": "Holt", "value": "C127185"},
             {"label": "Holtville", "value": "C127186"},
             {"label": "Homewood", "value": "C127187"},
             {"label": "Hoover", "value": "C127188"},
             {"label": "Houston County", "value": "C127189"},
             {"label": "Hueytown", "value": "C127190"},
             {"label": "Huguley", "value": "C127191"},
             {"label": "Huntsville", "value": "C127192"},
             {"label": "Indian Springs Village", "value": "C127193"},
             {"label": "Inverness", "value": "C127194"},
             {"label": "Irondale", "value": "C127195"},
             {"label": "Jackson", "value": "C127196"},
             {"label": "Jackson County", "value": "C127197"},
             {"label": "Jacksonville", "value": "C127198"},
             {"label": "Jasper", "value": "C127199"},
             {"label": "Jefferson County", "value": "C127200"},
             {"label": "Jemison", "value": "C127201"},
             {"label": "Kimberly", "value": "C127202"},
             {"label": "Kinsey", "value": "C127203"},
             {"label": "Ladonia", "value": "C127204"},
             {"label": "Lafayette", "value": "C127205"},
             {"label": "Lake Purdy", "value": "C127206"},
             {"label": "Lake View", "value": "C127207"},
             {"label": "Lamar County", "value": "C127208"},
             {"label": "Lanett", "value": "C127209"},
             {"label": "Lauderdale County", "value": "C127210"},
             {"label": "Lawrence County", "value": "C127211"},
             {"label": "Lee County", "value": "C127212"},
             {"label": "Leeds", "value": "C127213"},
             {"label": "Leesburg", "value": "C127214"},
             {"label": "Level Plains", "value": "C127215"},
             {"label": "Limestone County", "value": "C127216"},
             {"label": "Lincoln", "value": "C127217"},
             {"label": "Linden", "value": "C127218"},
             {"label": "Lineville", "value": "C127219"},
             {"label": "Lipscomb", "value": "C127220"},
             {"label": "Livingston", "value": "C127221"},
             {"label": "Locust Fork", "value": "C127222"},
             {"label": "Lowndes County", "value": "C127223"},
             {"label": "Loxley", "value": "C127224"},
             {"label": "Luverne", "value": "C127225"},
             {"label": "Macon County", "value": "C127226"},
             {"label": "Madison", "value": "C127227"},
             {"label": "Madison County", "value": "C127228"},
             {"label": "Malvern", "value": "C127229"},
             {"label": "Marbury", "value": "C127230"},
             {"label": "Marengo County", "value": "C127231"},
             {"label": "Margaret", "value": "C127232"},
             {"label": "Marion", "value": "C127233"},
             {"label": "Marion County", "value": "C127234"},
             {"label": "Marshall County", "value": "C127235"},
             {"label": "Meadowbrook", "value": "C127236"},
             {"label": "Meridianville", "value": "C127237"},
             {"label": "Midfield", "value": "C127238"},
             {"label": "Midland City", "value": "C127239"},
             {"label": "Mignon", "value": "C127240"},
             {"label": "Millbrook", "value": "C127241"},
             {"label": "Minor", "value": "C127242"},
             {"label": "Mobile", "value": "C127243"},
             {"label": "Mobile County", "value": "C127244"},
             {"label": "Monroe County", "value": "C127245"},
             {"label": "Monroeville", "value": "C127246"},
             {"label": "Montevallo", "value": "C127247"},
             {"label": "Montgomery", "value": "C127248"},
             {"label": "Montgomery County", "value": "C127249"},
             {"label": "Moody", "value": "C127250"},
             {"label": "Moores Mill", "value": "C127251"},
             {"label": "Morgan County", "value": "C127252"},
             {"label": "Morris", "value": "C127253"},
             {"label": "Moulton", "value": "C127254"},
             {"label": "Moundville", "value": "C127255"},
             {"label": "Mount Olive", "value": "C127256"},
             {"label": "Mount Vernon", "value": "C127257"},
             {"label": "Mountain Brook", "value": "C127258"},
             {"label": "Munford", "value": "C127259"},
             {"label": "Muscle Shoals", "value": "C127260"},
             {"label": "New Brockton", "value": "C127261"},
             {"label": "New Hope", "value": "C127262"},
             {"label": "New Market", "value": "C127263"},
             {"label": "Newton", "value": "C127264"},
             {"label": "North Bibb", "value": "C127265"},
             {"label": "Northport", "value": "C127266"},
             {"label": "Odenville", "value": "C127267"},
             {"label": "Ohatchee", "value": "C127268"},
             {"label": "Oneonta", "value": "C127269"},
             {"label": "Opelika", "value": "C127270"},
             {"label": "Opp", "value": "C127271"},
             {"label": "Orange Beach", "value": "C127272"},
             {"label": "Owens Cross Roads", "value": "C127273"},
             {"label": "Oxford", "value": "C127274"},
             {"label": "Ozark", "value": "C127275"},
             {"label": "Pelham", "value": "C127276"},
             {"label": "Pell City", "value": "C127277"},
             {"label": "Perry County", "value": "C127278"},
             {"label": "Phenix City", "value": "C127279"},
             {"label": "Phil Campbell", "value": "C127280"},
             {"label": "Pickens County", "value": "C127281"},
             {"label": "Piedmont", "value": "C127282"},
             {"label": "Pike County", "value": "C127283"},
             {"label": "Pike Road", "value": "C127284"},
             {"label": "Pine Level", "value": "C127285"},
             {"label": "Pinson", "value": "C127286"},
             {"label": "Pleasant Grove", "value": "C127287"},
             {"label": "Point Clear", "value": "C127288"},
             {"label": "Prattville", "value": "C127289"},
             {"label": "Priceville", "value": "C127290"},
             {"label": "Prichard", "value": "C127291"},
             {"label": "Ragland", "value": "C127292"},
             {"label": "Rainbow City", "value": "C127293"},
             {"label": "Rainsville", "value": "C127294"},
             {"label": "Randolph County", "value": "C127295"},
             {"label": "Red Bay", "value": "C127296"},
             {"label": "Redstone Arsenal", "value": "C127297"},
             {"label": "Reform", "value": "C127298"},
             {"label": "Rehobeth", "value": "C127299"},
             {"label": "Riverside", "value": "C127300"},
             {"label": "Roanoke", "value": "C127301"},
             {"label": "Robertsdale", "value": "C127302"},
             {"label": "Rock Creek", "value": "C127303"},
             {"label": "Rockford", "value": "C127304"},
             {"label": "Rogersville", "value": "C127305"},
             {"label": "Russell County", "value": "C127306"},
             {"label": "Russellville", "value": "C127307"},
             {"label": "Saint Clair County", "value": "C127308"},
             {"label": "Saks", "value": "C127309"},
             {"label": "Samson", "value": "C127310"},
             {"label": "Saraland", "value": "C127311"},
             {"label": "Sardis City", "value": "C127312"},
             {"label": "Satsuma", "value": "C127313"},
             {"label": "Scottsboro", "value": "C127314"},
             {"label": "Selma", "value": "C127315"},
             {"label": "Selmont-West Selmont", "value": "C127316"},
             {"label": "Semmes", "value": "C127317"},
             {"label": "Sheffield", "value": "C127318"},
             {"label": "Shelby", "value": "C127319"},
             {"label": "Shelby County", "value": "C127320"},
             {"label": "Shoal Creek", "value": "C127321"},
             {"label": "Slocomb", "value": "C127322"},
             {"label": "Smiths Station", "value": "C127323"},
             {"label": "Smoke Rise", "value": "C127324"},
             {"label": "Southside", "value": "C127325"},
             {"label": "Spanish Fort", "value": "C127326"},
             {"label": "Springville", "value": "C127327"},
             {"label": "Steele", "value": "C127328"},
             {"label": "Stevenson", "value": "C127329"},
             {"label": "Stewartville", "value": "C127330"},
             {"label": "Sulligent", "value": "C127331"},
             {"label": "Sumiton", "value": "C127332"},
             {"label": "Summerdale", "value": "C127333"},
             {"label": "Sumter County", "value": "C127334"},
             {"label": "Sylacauga", "value": "C127335"},
             {"label": "Sylvan Springs", "value": "C127336"},
             {"label": "Sylvania", "value": "C127337"},
             {"label": "Talladega", "value": "C127338"},
             {"label": "Talladega County", "value": "C127339"},
             {"label": "Tallapoosa County", "value": "C127340"},
             {"label": "Tallassee", "value": "C127341"},
             {"label": "Tarrant", "value": "C127342"},
             {"label": "Taylor", "value": "C127343"},
             {"label": "Theodore", "value": "C127344"},
             {"label": "Thomasville", "value": "C127345"},
             {"label": "Thorsby", "value": "C127346"},
             {"label": "Tillmans Corner", "value": "C127347"},
             {"label": "Town Creek", "value": "C127348"},
             {"label": "Trinity", "value": "C127349"},
             {"label": "Troy", "value": "C127350"},
             {"label": "Trussville", "value": "C127351"},
             {"label": "Tuscaloosa", "value": "C127352"},
             {"label": "Tuscaloosa County", "value": "C127353"},
             {"label": "Tuscumbia", "value": "C127354"},
             {"label": "Tuskegee", "value": "C127355"},
             {"label": "Underwood-Petersville", "value": "C127356"},
             {"label": "Union Springs", "value": "C127357"},
             {"label": "Uniontown", "value": "C127358"},
             {"label": "Valley", "value": "C127359"},
             {"label": "Valley Grande", "value": "C127360"},
             {"label": "Vance", "value": "C127361"},
             {"label": "Vandiver", "value": "C127362"},
             {"label": "Vernon", "value": "C127363"},
             {"label": "Vestavia Hills", "value": "C127364"},
             {"label": "Vincent", "value": "C127365"},
             {"label": "Walker County", "value": "C127366"},
             {"label": "Warrior", "value": "C127367"},
             {"label": "Washington County", "value": "C127368"},
             {"label": "Weaver", "value": "C127369"},
             {"label": "Webb", "value": "C127370"},
             {"label": "Wedowee", "value": "C127371"},
             {"label": "West Blocton", "value": "C127372"},
             {"label": "West End-Cobb Town", "value": "C127373"},
             {"label": "Westover", "value": "C127374"},
             {"label": "Wetumpka", "value": "C127375"},
             {"label": "Whitesboro", "value": "C127376"},
             {"label": "Wilcox County", "value": "C127377"},
             {"label": "Wilsonville", "value": "C127378"},
             {"label": "Winfield", "value": "C127379"},
             {"label": "Winston County", "value": "C127380"},
             {"label": "Woodstock", "value": "C127381"},
             {"label": "York", "value": "C127382"}],
 "Alagoas": [{"label": "agua Branca", "value": "C10219"},
             {"label": "Anadia", "value": "C10220"},
             {"label": "Arapiraca", "value": "C10221"},
             {"label": "Atalaia", "value": "C10222"},
             {"label": "Barra de Santo Antonio", "value": "C10223"},
             {"label": "Barra de Sao Miguel", "value": "C10224"},
             {"label": "Batalha", "value": "C10225"},
             {"label": "Belem", "value": "C10226"},
             {"label": "Belo Monte", "value": "C10227"},
             {"label": "Boca da Mata", "value": "C10228"},
             {"label": "Branquinha", "value": "C10229"},
             {"label": "Cacimbinhas", "value": "C10230"},
             {"label": "Cajueiro", "value": "C10231"},
             {"label": "Campestre", "value": "C10232"},
             {"label": "Campo Alegre", "value": "C10233"},
             {"label": "Campo Grande", "value": "C10234"},
             {"label": "Canapi", "value": "C10235"},
             {"label": "Capela", "value": "C10236"},
             {"label": "Carneiros", "value": "C10237"},
             {"label": "Cha Preta", "value": "C10238"},
             {"label": "Coite do Noia", "value": "C10239"},
             {"label": "Colonia Leopoldina", "value": "C10240"},
             {"label": "Coqueiro Seco", "value": "C10241"},
             {"label": "Coruripe", "value": "C10242"},
             {"label": "Craibas", "value": "C10243"},
             {"label": "Delmiro Gouveia", "value": "C10244"},
             {"label": "Dois Riachos", "value": "C10245"},
             {"label": "Estrela de Alagoas", "value": "C10246"},
             {"label": "Feira Grande", "value": "C10247"},
             {"label": "Feliz Deserto", "value": "C10248"},
             {"label": "Flexeiras", "value": "C10249"},
             {"label": "Girau do Ponciano", "value": "C10250"},
             {"label": "Ibateguara", "value": "C10251"},
             {"label": "Igaci", "value": "C10252"},
             {"label": "Igreja Nova", "value": "C10253"},
             {"label": "Inhapi", "value": "C10254"},
             {"label": "Jacare dos Homens", "value": "C10255"},
             {"label": "Jacuipe", "value": "C10256"},
             {"label": "Japaratinga", "value": "C10257"},
             {"label": "Jaramataia", "value": "C10258"},
             {"label": "Jequia da Praia", "value": "C10259"},
             {"label": "Joaquim Gomes", "value": "C10260"},
             {"label": "Jundia", "value": "C10261"},
             {"label": "Junqueiro", "value": "C10262"},
             {"label": "Lagoa da Canoa", "value": "C10263"},
             {"label": "Limoeiro de Anadia", "value": "C10264"},
             {"label": "Maceio", "value": "C10265"},
             {"label": "Major Isidoro", "value": "C10266"},
             {"label": "Mar Vermelho", "value": "C10267"},
             {"label": "Maragogi", "value": "C10268"},
             {"label": "Maravilha", "value": "C10269"},
             {"label": "Marechal Deodoro", "value": "C10270"},
             {"label": "Maribondo", "value": "C10271"},
             {"label": "Mata Grande", "value": "C10272"},
             {"label": "Matriz de Camaragibe", "value": "C10273"},
             {"label": "Messias", "value": "C10274"},
             {"label": "Minador do Negrao", "value": "C10275"},
             {"label": "Monteiropolis", "value": "C10276"},
             {"label": "Murici", "value": "C10277"},
             {"label": "Novo Lino", "value": "C10278"},
             {"label": "Olho dagua das Flores", "value": "C10279"},
             {"label": "Olho dagua do Casado", "value": "C10280"},
             {"label": "Olho dagua Grande", "value": "C10281"},
             {"label": "Olivenca", "value": "C10282"},
             {"label": "Ouro Branco", "value": "C10283"},
             {"label": "Palestina", "value": "C10284"},
             {"label": "Palmeira dos indios", "value": "C10285"},
             {"label": "Pao de Acucar", "value": "C10286"},
             {"label": "Pariconha", "value": "C10287"},
             {"label": "Paripueira", "value": "C10288"},
             {"label": "Passo de Camaragibe", "value": "C10289"},
             {"label": "Paulo Jacinto", "value": "C10290"},
             {"label": "Penedo", "value": "C10291"},
             {"label": "Piacabucu", "value": "C10292"},
             {"label": "Pilar", "value": "C10293"},
             {"label": "Pindoba", "value": "C10294"},
             {"label": "Piranhas", "value": "C10295"},
             {"label": "Poco das Trincheiras", "value": "C10296"},
             {"label": "Porto Calvo", "value": "C10297"},
             {"label": "Porto de Pedras", "value": "C10298"},
             {"label": "Porto Real do Colegio", "value": "C10299"},
             {"label": "Quebrangulo", "value": "C10300"},
             {"label": "Rio Largo", "value": "C10301"},
             {"label": "Roteiro", "value": "C10302"},
             {"label": "Santa Luzia do Norte", "value": "C10303"},
             {"label": "Santana do Ipanema", "value": "C10304"},
             {"label": "Santana do Mundau", "value": "C10305"},
             {"label": "Sao Bras", "value": "C10306"},
             {"label": "Sao Jose da Laje", "value": "C10307"},
             {"label": "Sao Jose da Tapera", "value": "C10308"},
             {"label": "Sao Luis do Quitunde", "value": "C10309"},
             {"label": "Sao Miguel dos Campos", "value": "C10310"},
             {"label": "Sao Miguel dos Milagres", "value": "C10311"},
             {"label": "Sao Sebastiao", "value": "C10312"},
             {"label": "Satuba", "value": "C10313"},
             {"label": "Senador Rui Palmeira", "value": "C10314"},
             {"label": "Tanque dArca", "value": "C10315"},
             {"label": "Taquarana", "value": "C10316"},
             {"label": "Teotonio Vilela", "value": "C10317"},
             {"label": "Traipu", "value": "C10318"},
             {"label": "Uniao dos Palmares", "value": "C10319"},
             {"label": "Vicosa", "value": "C10320"}],
 "Alajuela Province": [{"label": "Alajuela", "value": "C20763"},
                       {"label": "Atenas", "value": "C20764"},
                       {"label": "Bijagua", "value": "C20765"},
                       {"label": "Carrillos", "value": "C20766"},
                       {"label": "Desamparados", "value": "C20767"},
                       {"label": "Esquipulas", "value": "C20768"},
                       {"label": "Grecia", "value": "C20769"},
                       {"label": "Guatuso", "value": "C20770"},
                       {"label": "La Fortuna", "value": "C20771"},
                       {"label": "Los Chiles", "value": "C20772"},
                       {"label": "Naranjo", "value": "C20773"},
                       {"label": "Orotina", "value": "C20774"},
                       {"label": "Palmares", "value": "C20775"},
                       {"label": "Pital", "value": "C20776"},
                       {"label": "Poas", "value": "C20777"},
                       {"label": "Pocosol", "value": "C20778"},
                       {"label": "Quesada", "value": "C20779"},
                       {"label": "Rio Segundo", "value": "C20780"},
                       {"label": "Sabanilla", "value": "C20781"},
                       {"label": "San Carlos", "value": "C20782"},
                       {"label": "San Jose", "value": "C20783"},
                       {"label": "San Juan", "value": "C20784"},
                       {"label": "San Mateo", "value": "C20785"},
                       {"label": "San Rafael", "value": "C20786"},
                       {"label": "San Ramon", "value": "C20787"},
                       {"label": "Santiago", "value": "C20788"},
                       {"label": "Upala", "value": "C20789"},
                       {"label": "Valverde Vega", "value": "C20790"},
                       {"label": "Zarcero", "value": "C20791"}],
 "Alaska": [{"label": "Akutan", "value": "C127383"},
            {"label": "Aleutians East Borough", "value": "C127384"},
            {"label": "Aleutians West Census Area", "value": "C127385"},
            {"label": "Anchor Point", "value": "C127386"},
            {"label": "Anchorage", "value": "C127387"},
            {"label": "Anchorage Municipality", "value": "C127388"},
            {"label": "Badger", "value": "C127389"},
            {"label": "Barrow", "value": "C127390"},
            {"label": "Bear Creek", "value": "C127391"},
            {"label": "Bethel", "value": "C127392"},
            {"label": "Bethel Census Area", "value": "C127393"},
            {"label": "Big Lake", "value": "C127394"},
            {"label": "Bristol Bay Borough", "value": "C127395"},
            {"label": "Butte", "value": "C127396"},
            {"label": "Chevak", "value": "C127397"},
            {"label": "City and Borough of Wrangell", "value": "C127398"},
            {"label": "Cohoe", "value": "C127399"},
            {"label": "College", "value": "C127400"},
            {"label": "Cordova", "value": "C127401"},
            {"label": "Craig", "value": "C127402"},
            {"label": "Deltana", "value": "C127403"},
            {"label": "Denali Borough", "value": "C127404"},
            {"label": "Diamond Ridge", "value": "C127405"},
            {"label": "Dillingham", "value": "C127406"},
            {"label": "Dillingham Census Area", "value": "C127407"},
            {"label": "Dutch Harbor", "value": "C127408"},
            {"label": "Eagle River", "value": "C127409"},
            {"label": "Eielson Air Force Base", "value": "C127410"},
            {"label": "Elmendorf Air Force Base", "value": "C127411"},
            {"label": "Ester", "value": "C127412"},
            {"label": "Fairbanks", "value": "C127413"},
            {"label": "Fairbanks North Star Borough", "value": "C127414"},
            {"label": "Farm Loop", "value": "C127415"},
            {"label": "Farmers Loop", "value": "C127416"},
            {"label": "Fishhook", "value": "C127417"},
            {"label": "Fritz Creek", "value": "C127418"},
            {"label": "Gateway", "value": "C127419"},
            {"label": "Girdwood", "value": "C127420"},
            {"label": "Haines", "value": "C127421"},
            {"label": "Haines Borough", "value": "C127422"},
            {"label": "Healy", "value": "C127423"},
            {"label": "Homer", "value": "C127424"},
            {"label": "Hoonah-Angoon Census Area", "value": "C127425"},
            {"label": "Hooper Bay", "value": "C127426"},
            {"label": "Houston", "value": "C127427"},
            {"label": "Juneau", "value": "C127428"},
            {"label": "Kalifornsky", "value": "C127429"},
            {"label": "Kenai", "value": "C127430"},
            {"label": "Kenai Peninsula Borough", "value": "C127431"},
            {"label": "Ketchikan", "value": "C127432"},
            {"label": "Ketchikan Gateway Borough", "value": "C127433"},
            {"label": "King Cove", "value": "C127434"},
            {"label": "Knik-Fairview", "value": "C127435"},
            {"label": "Kodiak", "value": "C127436"},
            {"label": "Kodiak Island Borough", "value": "C127437"},
            {"label": "Kodiak Station", "value": "C127438"},
            {"label": "Kotzebue", "value": "C127439"},
            {"label": "Lake and Peninsula Borough", "value": "C127440"},
            {"label": "Lakes", "value": "C127441"},
            {"label": "Lazy Mountain", "value": "C127442"},
            {"label": "Matanuska-Susitna Borough", "value": "C127443"},
            {"label": "Meadow Lakes", "value": "C127444"},
            {"label": "Metlakatla", "value": "C127445"},
            {"label": "Nikiski", "value": "C127446"},
            {"label": "Nome", "value": "C127447"},
            {"label": "Nome Census Area", "value": "C127448"},
            {"label": "North Pole", "value": "C127449"},
            {"label": "North Slope Borough", "value": "C127450"},
            {"label": "Northwest Arctic Borough", "value": "C127451"},
            {"label": "Palmer", "value": "C127452"},
            {"label": "Petersburg", "value": "C127453"},
            {"label": "Petersburg Borough", "value": "C127454"},
            {"label": "Prudhoe Bay", "value": "C127455"},
            {"label": "Ridgeway", "value": "C127456"},
            {"label": "Salcha", "value": "C127457"},
            {"label": "Sand Point", "value": "C127458"},
            {"label": "Seward", "value": "C127459"},
            {"label": "Sitka", "value": "C127460"},
            {"label": "Sitka City and Borough", "value": "C127461"},
            {"label": "Skagway Municipality", "value": "C127462"},
            {"label": "Soldotna", "value": "C127463"},
            {"label": "Southeast Fairbanks Census Area", "value": "C127464"},
            {"label": "Sterling", "value": "C127465"},
            {"label": "Sutton-Alpine", "value": "C127466"},
            {"label": "Tanaina", "value": "C127467"},
            {"label": "Tok", "value": "C127468"},
            {"label": "Unalaska", "value": "C127469"},
            {"label": "Valdez", "value": "C127470"},
            {"label": "Valdez-Cordova Census Area", "value": "C127471"},
            {"label": "Wasilla", "value": "C127472"},
            {"label": "Willow", "value": "C127473"},
            {"label": "Wrangell", "value": "C127474"},
            {"label": "Y", "value": "C127475"},
            {"label": "Yakutat City and Borough", "value": "C127476"},
            {"label": "Yukon-Koyukuk Census Area", "value": "C127477"}],
 "Alba": [{"label": "Abrud", "value": "C92084"},
          {"label": "Abrud-Sat", "value": "C92085"},
          {"label": "Aiud", "value": "C92086"},
          {"label": "Aiudul de Sus", "value": "C92087"},
          {"label": "Alba Iulia", "value": "C92088"},
          {"label": "Albac", "value": "C92089"},
          {"label": "Almasu Mare", "value": "C92090"},
          {"label": "Arieseni", "value": "C92091"},
          {"label": "Avram Iancu", "value": "C92092"},
          {"label": "Baia de Aries", "value": "C92093"},
          {"label": "Balcaciu", "value": "C92094"},
          {"label": "Barabant", "value": "C92095"},
          {"label": "Berghin", "value": "C92096"},
          {"label": "Biia", "value": "C92097"},
          {"label": "Bistra", "value": "C92098"},
          {"label": "Blaj", "value": "C92099"},
          {"label": "Blandiana", "value": "C92100"},
          {"label": "Blidesti", "value": "C92101"},
          {"label": "Bucerdea-Granoasa", "value": "C92102"},
          {"label": "Bucium", "value": "C92103"},
          {"label": "Calnic", "value": "C92104"},
          {"label": "Campeni", "value": "C92105"},
          {"label": "Cenade", "value": "C92106"},
          {"label": "Cergau Mare", "value": "C92107"},
          {"label": "Cergau Mic", "value": "C92108"},
          {"label": "Ceru-Bacainti", "value": "C92109"},
          {"label": "Cetatea de Balta", "value": "C92110"},
          {"label": "Ciugud", "value": "C92111"},
          {"label": "Ciumbrud", "value": "C92112"},
          {"label": "Ciuruleasa", "value": "C92113"},
          {"label": "Comuna Albac", "value": "C92114"},
          {"label": "Comuna Almasu Mare", "value": "C92115"},
          {"label": "Comuna Arieseni", "value": "C92116"},
          {"label": "Comuna Avram Iancu", "value": "C92117"},
          {"label": "Comuna Berghin", "value": "C92118"},
          {"label": "Comuna Bistra", "value": "C92119"},
          {"label": "Comuna Blandiana", "value": "C92120"},
          {"label": "Comuna Bucerdea-Granoasa", "value": "C92121"},
          {"label": "Comuna Bucium", "value": "C92122"},
          {"label": "Comuna Calnic", "value": "C92123"},
          {"label": "Comuna Cenade", "value": "C92124"},
          {"label": "Comuna Cergau", "value": "C92125"},
          {"label": "Comuna Ceru-Bacainti", "value": "C92126"},
          {"label": "Comuna Cetatea de Balta", "value": "C92127"},
          {"label": "Comuna Ciugud", "value": "C92128"},
          {"label": "Comuna Ciuruleasa", "value": "C92129"},
          {"label": "Comuna Craciunelu de Jos", "value": "C92130"},
          {"label": "Comuna Cricau", "value": "C92131"},
          {"label": "Comuna Cut", "value": "C92132"},
          {"label": "Comuna Daia Romana", "value": "C92133"},
          {"label": "Comuna Dostat", "value": "C92134"},
          {"label": "Comuna Farau", "value": "C92135"},
          {"label": "Comuna Galda de Jos", "value": "C92136"},
          {"label": "Comuna Garbova", "value": "C92137"},
          {"label": "Comuna Garda de Sus", "value": "C92138"},
          {"label": "Comuna Hoparta", "value": "C92139"},
          {"label": "Comuna Horea", "value": "C92140"},
          {"label": "Comuna Ighiu", "value": "C92141"},
          {"label": "Comuna Intregalde", "value": "C92142"},
          {"label": "Comuna Jidvei", "value": "C92143"},
          {"label": "Comuna Livezile", "value": "C92144"},
          {"label": "Comuna Lopadea Noua", "value": "C92145"},
          {"label": "Comuna Lunca Muresului", "value": "C92146"},
          {"label": "Comuna Lupsa", "value": "C92147"},
          {"label": "Comuna Metes", "value": "C92148"},
          {"label": "Comuna Mihalt", "value": "C92149"},
          {"label": "Comuna Miraslau", "value": "C92150"},
          {"label": "Comuna Mogos", "value": "C92151"},
          {"label": "Comuna Noslac", "value": "C92152"},
          {"label": "Comuna Ocolis", "value": "C92153"},
          {"label": "Comuna Ohaba", "value": "C92154"},
          {"label": "Comuna Pianu", "value": "C92155"},
          {"label": "Comuna Poiana Vadului", "value": "C92156"},
          {"label": "Comuna Ponor", "value": "C92157"},
          {"label": "Comuna Posaga", "value": "C92158"},
          {"label": "Comuna Radesti", "value": "C92159"},
          {"label": "Comuna Ramet", "value": "C92160"},
          {"label": "Comuna Rametea", "value": "C92161"},
          {"label": "Comuna Rosia de Secas", "value": "C92162"},
          {"label": "Comuna Rosia Montana", "value": "C92163"},
          {"label": "Comuna Salciua", "value": "C92164"},
          {"label": "Comuna Salistea", "value": "C92165"},
          {"label": "Comuna Sancel", "value": "C92166"},
          {"label": "Comuna Santimbru", "value": "C92167"},
          {"label": "Comuna Sasciori", "value": "C92168"},
          {"label": "Comuna Scarisoara", "value": "C92169"},
          {"label": "Comuna Sibot", "value": "C92170"},
          {"label": "Comuna Sohodol", "value": "C92171"},
          {"label": "Comuna Sona", "value": "C92172"},
          {"label": "Comuna Spring", "value": "C92173"},
          {"label": "Comuna Stremt", "value": "C92174"},
          {"label": "Comuna Sugag", "value": "C92175"},
          {"label": "Comuna Unirea", "value": "C92176"},
          {"label": "Comuna Vadu Motilor", "value": "C92177"},
          {"label": "Comuna Valea Lunga", "value": "C92178"},
          {"label": "Comuna Vidra", "value": "C92179"},
          {"label": "Comuna Vintu de Jos", "value": "C92180"},
          {"label": "Craciunelu de Jos", "value": "C92181"},
          {"label": "Cricau", "value": "C92182"},
          {"label": "Cugir", "value": "C92183"},
          {"label": "Cut", "value": "C92184"},
          {"label": "Daia Romana", "value": "C92185"},
          {"label": "Dobra", "value": "C92186"},
          {"label": "Dostat", "value": "C92187"},
          {"label": "Farau", "value": "C92188"},
          {"label": "Feisa", "value": "C92189"},
          {"label": "Fenes", "value": "C92190"},
          {"label": "Galda de Jos", "value": "C92191"},
          {"label": "Garbova", "value": "C92192"},
          {"label": "Garda de Sus", "value": "C92193"},
          {"label": "Hoparta", "value": "C92194"},
          {"label": "Horea", "value": "C92195"},
          {"label": "Ighiel", "value": "C92196"},
          {"label": "Ighiu", "value": "C92197"},
          {"label": "Intregalde", "value": "C92198"},
          {"label": "Izvoarele", "value": "C92199"},
          {"label": "Jidvei", "value": "C92200"},
          {"label": "Lancram", "value": "C92201"},
          {"label": "Livezile", "value": "C92202"},
          {"label": "Lopadea Noua", "value": "C92203"},
          {"label": "Lunca Muresului", "value": "C92204"},
          {"label": "Lupsa", "value": "C92205"},
          {"label": "Metes", "value": "C92206"},
          {"label": "Micesti", "value": "C92207"},
          {"label": "Mihalt", "value": "C92208"},
          {"label": "Miraslau", "value": "C92209"},
          {"label": "Mogos", "value": "C92210"},
          {"label": "Municipiul Aiud", "value": "C92211"},
          {"label": "Municipiul Alba Iulia", "value": "C92212"},
          {"label": "Municipiul Blaj", "value": "C92213"},
          {"label": "Municipiul Sebes", "value": "C92214"},
          {"label": "Noslac", "value": "C92215"},
          {"label": "Oarda", "value": "C92216"},
          {"label": "Ocna Mures", "value": "C92217"},
          {"label": "Ocolis", "value": "C92218"},
          {"label": "Ohaba", "value": "C92219"},
          {"label": "Oras abrud", "value": "C92220"},
          {"label": "Oras Baia de Aries", "value": "C92221"},
          {"label": "Oras Campeni", "value": "C92222"},
          {"label": "Oras Cugir", "value": "C92223"},
          {"label": "Oras Ocna Mures", "value": "C92224"},
          {"label": "Oras Teius", "value": "C92225"},
          {"label": "Oras Zlatna", "value": "C92226"},
          {"label": "Petresti", "value": "C92227"},
          {"label": "Pianu de Jos", "value": "C92228"},
          {"label": "Pianu de Sus", "value": "C92229"},
          {"label": "Poiana Vadului", "value": "C92230"},
          {"label": "Poienile-Mogos", "value": "C92231"},
          {"label": "Ponor", "value": "C92232"},
          {"label": "Posaga de Sus", "value": "C92233"},
          {"label": "Radesti", "value": "C92234"},
          {"label": "Razboieni-Cetate", "value": "C92235"},
          {"label": "Rimetea", "value": "C92236"},
          {"label": "Rosia de Secas", "value": "C92237"},
          {"label": "Rosia Montana", "value": "C92238"},
          {"label": "Salciua de Sus", "value": "C92239"},
          {"label": "Salistea", "value": "C92240"},
          {"label": "Salistea-Deal", "value": "C92241"},
          {"label": "Sancel", "value": "C92242"},
          {"label": "Sancrai", "value": "C92243"},
          {"label": "Santimbru", "value": "C92244"},
          {"label": "Sard", "value": "C92245"},
          {"label": "Sasciori", "value": "C92246"},
          {"label": "Scarisoara", "value": "C92247"},
          {"label": "Sebes", "value": "C92248"},
          {"label": "Sebesel", "value": "C92249"},
          {"label": "Sibot", "value": "C92250"},
          {"label": "Sohodol", "value": "C92251"},
          {"label": "Sona", "value": "C92252"},
          {"label": "Spring", "value": "C92253"},
          {"label": "Stremt", "value": "C92254"},
          {"label": "Sugag", "value": "C92255"},
          {"label": "Teius", "value": "C92256"},
          {"label": "Telna", "value": "C92257"},
          {"label": "Tiur", "value": "C92258"},
          {"label": "Uioara de Jos", "value": "C92259"},
          {"label": "Unirea", "value": "C92260"},
          {"label": "Vadu Motilor", "value": "C92261"},
          {"label": "Valea Lunga Alba Romania", "value": "C92262"},
          {"label": "Veseus", "value": "C92263"},
          {"label": "Veza", "value": "C92264"},
          {"label": "Vidra", "value": "C92265"},
          {"label": "Vinerea", "value": "C92266"},
          {"label": "Vintu de Jos", "value": "C92267"},
          {"label": "Vurpar", "value": "C92268"},
          {"label": "Zlatna", "value": "C92269"}],
 "Albay": [{"label": "Aanislag", "value": "C82055"},
           {"label": "Abucay", "value": "C82056"},
           {"label": "Abuyog", "value": "C82057"},
           {"label": "Agos", "value": "C82058"},
           {"label": "Aguada", "value": "C82059"},
           {"label": "Agupit", "value": "C82060"},
           {"label": "Alayao", "value": "C82061"},
           {"label": "Antipolo", "value": "C82062"},
           {"label": "Anuling", "value": "C82063"},
           {"label": "Apad", "value": "C82064"},
           {"label": "Apud", "value": "C82065"},
           {"label": "Armenia", "value": "C82066"},
           {"label": "Aroroy", "value": "C82067"},
           {"label": "Ayugan", "value": "C82068"},
           {"label": "Baao", "value": "C82069"},
           {"label": "Bacacay", "value": "C82070"},
           {"label": "Bacolod", "value": "C82071"},
           {"label": "Bacon", "value": "C82072"},
           {"label": "Badian", "value": "C82073"},
           {"label": "Bagacay", "value": "C82074"},
           {"label": "Bagahanlad", "value": "C82075"},
           {"label": "Bagamanoc", "value": "C82076"},
           {"label": "Bagumbayan", "value": "C82077"},
           {"label": "Bahay", "value": "C82078"},
           {"label": "Balading", "value": "C82079"},
           {"label": "Balaogan", "value": "C82080"},
           {"label": "Balatan", "value": "C82081"},
           {"label": "Baleno", "value": "C82082"},
           {"label": "Balete", "value": "C82083"},
           {"label": "Baligang", "value": "C82084"},
           {"label": "Balinad", "value": "C82085"},
           {"label": "Baliuag Nuevo", "value": "C82086"},
           {"label": "Balogo", "value": "C82087"},
           {"label": "Balucawi", "value": "C82088"},
           {"label": "Balud", "value": "C82089"},
           {"label": "Banag", "value": "C82090"},
           {"label": "Bangad", "value": "C82091"},
           {"label": "Bangkirohan", "value": "C82092"},
           {"label": "Banocboc", "value": "C82093"},
           {"label": "Bao", "value": "C82094"},
           {"label": "Baras", "value": "C82095"},
           {"label": "Barayong", "value": "C82096"},
           {"label": "Barcelona", "value": "C82097"},
           {"label": "Bariw", "value": "C82098"},
           {"label": "Barra", "value": "C82099"},
           {"label": "Bascaron", "value": "C82100"},
           {"label": "Basiad", "value": "C82101"},
           {"label": "Basicao Coastal", "value": "C82102"},
           {"label": "Basud", "value": "C82103"},
           {"label": "Batana", "value": "C82104"},
           {"label": "Bato", "value": "C82105"},
           {"label": "Batobalane", "value": "C82106"},
           {"label": "Batuan", "value": "C82107"},
           {"label": "Baybay", "value": "C82108"},
           {"label": "Beberon", "value": "C82109"},
           {"label": "Bigaa", "value": "C82110"},
           {"label": "Binanwanaan", "value": "C82111"},
           {"label": "Binitayan", "value": "C82112"},
           {"label": "Binodegahan", "value": "C82113"},
           {"label": "Bolo", "value": "C82114"},
           {"label": "Bombon", "value": "C82115"},
           {"label": "Bonga", "value": "C82116"},
           {"label": "Boton", "value": "C82117"},
           {"label": "Buang", "value": "C82118"},
           {"label": "Buenavista", "value": "C82119"},
           {"label": "Buga", "value": "C82120"},
           {"label": "Buhatan", "value": "C82121"},
           {"label": "Buhi", "value": "C82122"},
           {"label": "Bula", "value": "C82123"},
           {"label": "Bulan", "value": "C82124"},
           {"label": "Bulo", "value": "C82125"},
           {"label": "Buluang", "value": "C82126"},
           {"label": "Bulusan", "value": "C82127"},
           {"label": "Burabod", "value": "C82128"},
           {"label": "Buracan", "value": "C82129"},
           {"label": "Busing", "value": "C82130"},
           {"label": "Butag", "value": "C82131"},
           {"label": "Buyo", "value": "C82132"},
           {"label": "Cabcab", "value": "C82133"},
           {"label": "Cabiguan", "value": "C82134"},
           {"label": "Cabitan", "value": "C82135"},
           {"label": "Cabognon", "value": "C82136"},
           {"label": "Cabugao", "value": "C82137"},
           {"label": "Cabusao", "value": "C82138"},
           {"label": "Caditaan", "value": "C82139"},
           {"label": "Cadlan", "value": "C82140"},
           {"label": "Cagmanaba", "value": "C82141"},
           {"label": "Calabaca", "value": "C82142"},
           {"label": "Calabanga", "value": "C82143"},
           {"label": "Calachuchi", "value": "C82144"},
           {"label": "Calasgasan", "value": "C82145"},
           {"label": "Calatagan", "value": "C82146"},
           {"label": "Calolbon", "value": "C82147"},
           {"label": "Camalig", "value": "C82148"},
           {"label": "Camaligan", "value": "C82149"},
           {"label": "Canaman", "value": "C82150"},
           {"label": "Canomoy", "value": "C82151"},
           {"label": "Capalonga", "value": "C82152"},
           {"label": "Capucnasan", "value": "C82153"},
           {"label": "Capuy", "value": "C82154"},
           {"label": "Caramoan", "value": "C82155"},
           {"label": "Caramoran", "value": "C82156"},
           {"label": "Caranan", "value": "C82157"},
           {"label": "Caraycayon", "value": "C82158"},
           {"label": "Carriedo", "value": "C82159"},
           {"label": "Casiguran", "value": "C82160"},
           {"label": "Castilla", "value": "C82161"},
           {"label": "Castillo", "value": "C82162"},
           {"label": "Catabangan", "value": "C82163"},
           {"label": "Cataingan", "value": "C82164"},
           {"label": "Causip", "value": "C82165"},
           {"label": "Claveria", "value": "C82166"},
           {"label": "Cotmon", "value": "C82167"},
           {"label": "Culacling", "value": "C82168"},
           {"label": "Cumadcad", "value": "C82169"},
           {"label": "Curry", "value": "C82170"},
           {"label": "Daet", "value": "C82171"},
           {"label": "Daguit", "value": "C82172"},
           {"label": "Dalupaon", "value": "C82173"},
           {"label": "Dangcalan", "value": "C82174"},
           {"label": "Dapdap", "value": "C82175"},
           {"label": "Daraga", "value": "C82176"},
           {"label": "Del Gallego", "value": "C82177"},
           {"label": "Del Rosario", "value": "C82178"},
           {"label": "Dimasalang", "value": "C82179"},
           {"label": "Donsol", "value": "C82180"},
           {"label": "Dugcal", "value": "C82181"},
           {"label": "Dugongan", "value": "C82182"},
           {"label": "Esperanza", "value": "C82183"},
           {"label": "Estancia", "value": "C82184"},
           {"label": "Fabrica", "value": "C82185"},
           {"label": "Gabao", "value": "C82186"},
           {"label": "Gainza", "value": "C82187"},
           {"label": "Gambalidio", "value": "C82188"},
           {"label": "Garchitorena", "value": "C82189"},
           {"label": "Gatbo", "value": "C82190"},
           {"label": "Gibgos", "value": "C82191"},
           {"label": "Gigmoto", "value": "C82192"},
           {"label": "Goa", "value": "C82193"},
           {"label": "Gubat", "value": "C82194"},
           {"label": "Guijalo", "value": "C82195"},
           {"label": "Guinacotan", "value": "C82196"},
           {"label": "Guinobatan", "value": "C82197"},
           {"label": "Gumaus", "value": "C82198"},
           {"label": "Guruyan", "value": "C82199"},
           {"label": "Hamoraon", "value": "C82200"},
           {"label": "Herrera", "value": "C82201"},
           {"label": "Himaao", "value": "C82202"},
           {"label": "Hobo", "value": "C82203"},
           {"label": "Imelda", "value": "C82204"},
           {"label": "Inapatan", "value": "C82205"},
           {"label": "Iraya", "value": "C82206"},
           {"label": "Iriga City", "value": "C82207"},
           {"label": "Irosin", "value": "C82208"},
           {"label": "Joroan", "value": "C82209"},
           {"label": "Jose Panganiban", "value": "C82210"},
           {"label": "Jovellar", "value": "C82211"},
           {"label": "Juban", "value": "C82212"},
           {"label": "Kaliliog", "value": "C82213"},
           {"label": "Kinalansan", "value": "C82214"},
           {"label": "Labnig", "value": "C82215"},
           {"label": "Labo", "value": "C82216"},
           {"label": "Lacag", "value": "C82217"},
           {"label": "Lagonoy", "value": "C82218"},
           {"label": "Lajong", "value": "C82219"},
           {"label": "Lanigay", "value": "C82220"},
           {"label": "Lantangan", "value": "C82221"},
           {"label": "Larap", "value": "C82222"},
           {"label": "Legaspi", "value": "C82223"},
           {"label": "Libmanan", "value": "C82224"},
           {"label": "Libog", "value": "C82225"},
           {"label": "Libon", "value": "C82226"},
           {"label": "Liboro", "value": "C82227"},
           {"label": "Ligao", "value": "C82228"},
           {"label": "Limbuhan", "value": "C82229"},
           {"label": "Lourdes", "value": "C82230"},
           {"label": "Lubigan", "value": "C82231"},
           {"label": "Lugui", "value": "C82232"},
           {"label": "Luklukan", "value": "C82233"},
           {"label": "Luna", "value": "C82234"},
           {"label": "Lupi", "value": "C82235"},
           {"label": "Lupi Viejo", "value": "C82236"},
           {"label": "Maagnas", "value": "C82237"},
           {"label": "Mabiton", "value": "C82238"},
           {"label": "Macabugos", "value": "C82239"},
           {"label": "Macalaya", "value": "C82240"},
           {"label": "Magallanes", "value": "C82241"},
           {"label": "Magarao", "value": "C82242"},
           {"label": "Magsalangi", "value": "C82243"},
           {"label": "Mahaba", "value": "C82244"},
           {"label": "Malabog", "value": "C82245"},
           {"label": "Malasugui", "value": "C82246"},
           {"label": "Malatap", "value": "C82247"},
           {"label": "Malawag", "value": "C82248"},
           {"label": "Malbug", "value": "C82249"},
           {"label": "Malidong", "value": "C82250"},
           {"label": "Malilipot", "value": "C82251"},
           {"label": "Malinao", "value": "C82252"},
           {"label": "Malinta", "value": "C82253"},
           {"label": "Mambulo", "value": "C82254"},
           {"label": "Mampurog", "value": "C82255"},
           {"label": "Manamrag", "value": "C82256"},
           {"label": "Mandaon", "value": "C82257"},
           {"label": "Manga", "value": "C82258"},
           {"label": "Manito", "value": "C82259"},
           {"label": "Manquiring", "value": "C82260"},
           {"label": "Maonon", "value": "C82261"},
           {"label": "Marintoc", "value": "C82262"},
           {"label": "Marupit", "value": "C82263"},
           {"label": "Masaraway", "value": "C82264"},
           {"label": "Masbate", "value": "C82265"},
           {"label": "Maslog", "value": "C82266"},
           {"label": "Masoli", "value": "C82267"},
           {"label": "Matacon", "value": "C82268"},
           {"label": "Matnog", "value": "C82269"},
           {"label": "Mauraro", "value": "C82270"},
           {"label": "Mayngaran", "value": "C82271"},
           {"label": "Mercedes", "value": "C82272"},
           {"label": "Miaga", "value": "C82273"},
           {"label": "Milagros", "value": "C82274"},
           {"label": "Milaor", "value": "C82275"},
           {"label": "Miliroc", "value": "C82276"},
           {"label": "Minalabac", "value": "C82277"},
           {"label": "Mobo", "value": "C82278"},
           {"label": "Monbon", "value": "C82279"},
           {"label": "Monreal", "value": "C82280"},
           {"label": "Muladbucad", "value": "C82281"},
           {"label": "Naagas", "value": "C82282"},
           {"label": "Nabangig", "value": "C82283"},
           {"label": "Nabua", "value": "C82284"},
           {"label": "Naga", "value": "C82285"},
           {"label": "Naro", "value": "C82286"},
           {"label": "Nato", "value": "C82287"},
           {"label": "Ocampo", "value": "C82288"},
           {"label": "Odicon", "value": "C82289"},
           {"label": "Ogod", "value": "C82290"},
           {"label": "Osiao", "value": "C82291"},
           {"label": "Osmena", "value": "C82292"},
           {"label": "Padang", "value": "C82293"},
           {"label": "Palali", "value": "C82294"},
           {"label": "Palanas", "value": "C82295"},
           {"label": "Palestina", "value": "C82296"},
           {"label": "Palsong", "value": "C82297"},
           {"label": "Pambuhan", "value": "C82298"},
           {"label": "Pamplona", "value": "C82299"},
           {"label": "Pandan", "value": "C82300"},
           {"label": "Panganiban", "value": "C82301"},
           {"label": "Panguiranan", "value": "C82302"},
           {"label": "Paniqui", "value": "C82303"},
           {"label": "Pantao", "value": "C82304"},
           {"label": "Parabcan", "value": "C82305"},
           {"label": "Paracale", "value": "C82306"},
           {"label": "Pasacao", "value": "C82307"},
           {"label": "Paulba", "value": "C82308"},
           {"label": "Pawa", "value": "C82309"},
           {"label": "Pawican", "value": "C82310"},
           {"label": "Pawili", "value": "C82311"},
           {"label": "Pena", "value": "C82312"},
           {"label": "Pilar", "value": "C82313"},
           {"label": "Pili", "value": "C82314"},
           {"label": "Pinit", "value": "C82315"},
           {"label": "Pio Duran", "value": "C82316"},
           {"label": "Placer", "value": "C82317"},
           {"label": "Polangui", "value": "C82318"},
           {"label": "Ponso", "value": "C82319"},
           {"label": "Potot", "value": "C82320"},
           {"label": "Prieto Diaz", "value": "C82321"},
           {"label": "Province of Albay", "value": "C82322"},
           {"label": "Province of Camarines Norte", "value": "C82323"},
           {"label": "Province of Camarines Sur", "value": "C82324"},
           {"label": "Province of Catanduanes", "value": "C82325"},
           {"label": "Province of Masbate", "value": "C82326"},
           {"label": "Province of Sorsogon", "value": "C82327"},
           {"label": "Puro", "value": "C82328"},
           {"label": "Putiao", "value": "C82329"},
           {"label": "Quezon", "value": "C82330"},
           {"label": "Quitang", "value": "C82331"},
           {"label": "Ragay", "value": "C82332"},
           {"label": "Rapu-Rapu", "value": "C82333"},
           {"label": "Recodo", "value": "C82334"},
           {"label": "Rizal", "value": "C82335"},
           {"label": "Sabang", "value": "C82336"},
           {"label": "Sabang Indan", "value": "C82337"},
           {"label": "Sagnay", "value": "C82338"},
           {"label": "Sagpon", "value": "C82339"},
           {"label": "Sagrada", "value": "C82340"},
           {"label": "Sagrada Familia", "value": "C82341"},
           {"label": "Sagurong", "value": "C82342"},
           {"label": "Salingogan", "value": "C82343"},
           {"label": "Salogon", "value": "C82344"},
           {"label": "Salvacion", "value": "C82345"},
           {"label": "San Agustin", "value": "C82346"},
           {"label": "San Andres", "value": "C82347"},
           {"label": "San Antonio", "value": "C82348"},
           {"label": "San Felipe", "value": "C82349"},
           {"label": "San Fernando", "value": "C82350"},
           {"label": "San Francisco", "value": "C82351"},
           {"label": "San Gabriel", "value": "C82352"},
           {"label": "San Isidro", "value": "C82353"},
           {"label": "San Jacinto", "value": "C82354"},
           {"label": "San Jose", "value": "C82355"},
           {"label": "San Lucas", "value": "C82356"},
           {"label": "San Miguel", "value": "C82357"},
           {"label": "San Pascual", "value": "C82358"},
           {"label": "San Pedro", "value": "C82359"},
           {"label": "San Rafael", "value": "C82360"},
           {"label": "San Ramon", "value": "C82361"},
           {"label": "San Roque", "value": "C82362"},
           {"label": "San Sebastian", "value": "C82363"},
           {"label": "San Vicente", "value": "C82364"},
           {"label": "Santa Cruz", "value": "C82365"},
           {"label": "Santa Elena", "value": "C82366"},
           {"label": "Santa Justina", "value": "C82367"},
           {"label": "Santa Magdalena", "value": "C82368"},
           {"label": "Santa Rosa Sur", "value": "C82369"},
           {"label": "Santa Teresita", "value": "C82370"},
           {"label": "Santo Domingo", "value": "C82371"},
           {"label": "Santo Nino", "value": "C82372"},
           {"label": "Sinuknipan", "value": "C82373"},
           {"label": "Sipocot", "value": "C82374"},
           {"label": "Siruma", "value": "C82375"},
           {"label": "Sogod", "value": "C82376"},
           {"label": "Sorsogon", "value": "C82377"},
           {"label": "Sugcad", "value": "C82378"},
           {"label": "Sugod", "value": "C82379"},
           {"label": "Tabaco", "value": "C82380"},
           {"label": "Tagas", "value": "C82381"},
           {"label": "Tagoytoy", "value": "C82382"},
           {"label": "Talisay", "value": "C82383"},
           {"label": "Talubatib", "value": "C82384"},
           {"label": "Tambo", "value": "C82385"},
           {"label": "Tara", "value": "C82386"},
           {"label": "Tariric", "value": "C82387"},
           {"label": "Tigaon", "value": "C82388"},
           {"label": "Tigbao", "value": "C82389"},
           {"label": "Tigbaw", "value": "C82390"},
           {"label": "Tigbinan", "value": "C82391"},
           {"label": "Tinago", "value": "C82392"},
           {"label": "Tinalmud", "value": "C82393"},
           {"label": "Tinambac", "value": "C82394"},
           {"label": "Tinampo", "value": "C82395"},
           {"label": "Tinawagan", "value": "C82396"},
           {"label": "Tiwi", "value": "C82397"},
           {"label": "Tubli", "value": "C82398"},
           {"label": "Tuburan", "value": "C82399"},
           {"label": "Tugos", "value": "C82400"},
           {"label": "Tulay na Lupa", "value": "C82401"},
           {"label": "Tumalaytay", "value": "C82402"},
           {"label": "Umabay", "value": "C82403"},
           {"label": "Usab", "value": "C82404"},
           {"label": "Uson", "value": "C82405"},
           {"label": "Utabi", "value": "C82406"},
           {"label": "Viga", "value": "C82407"},
           {"label": "Villahermosa", "value": "C82408"},
           {"label": "Vinzons", "value": "C82409"},
           {"label": "Virac", "value": "C82410"}],
 "Alberta": [{"label": "Airdrie", "value": "C16704"},
             {"label": "Athabasca", "value": "C16705"},
             {"label": "Banff", "value": "C16706"},
             {"label": "Barrhead", "value": "C16707"},
             {"label": "Bassano", "value": "C16708"},
             {"label": "Beaumont", "value": "C16709"},
             {"label": "Beaverlodge", "value": "C16710"},
             {"label": "Black Diamond", "value": "C16711"},
             {"label": "Blackfalds", "value": "C16712"},
             {"label": "Bon Accord", "value": "C16713"},
             {"label": "Bonnyville", "value": "C16714"},
             {"label": "Bow Island", "value": "C16715"},
             {"label": "Brooks", "value": "C16716"},
             {"label": "Calgary", "value": "C16717"},
             {"label": "Calmar", "value": "C16718"},
             {"label": "Camrose", "value": "C16719"},
             {"label": "Canmore", "value": "C16720"},
             {"label": "Cardston", "value": "C16721"},
             {"label": "Carstairs", "value": "C16722"},
             {"label": "Chestermere", "value": "C16723"},
             {"label": "Claresholm", "value": "C16724"},
             {"label": "Coaldale", "value": "C16725"},
             {"label": "Coalhurst", "value": "C16726"},
             {"label": "Cochrane", "value": "C16727"},
             {"label": "Cold Lake", "value": "C16728"},
             {"label": "Crossfield", "value": "C16729"},
             {"label": "Devon", "value": "C16730"},
             {"label": "Didsbury", "value": "C16731"},
             {"label": "Drayton Valley", "value": "C16732"},
             {"label": "Edmonton", "value": "C16733"},
             {"label": "Edson", "value": "C16734"},
             {"label": "Elk Point", "value": "C16735"},
             {"label": "Fairview", "value": "C16736"},
             {"label": "Falher", "value": "C16737"},
             {"label": "Fort Macleod", "value": "C16738"},
             {"label": "Fort McMurray", "value": "C16739"},
             {"label": "Fort Saskatchewan", "value": "C16740"},
             {"label": "Fox Creek", "value": "C16741"},
             {"label": "Gibbons", "value": "C16742"},
             {"label": "Grand Centre", "value": "C16743"},
             {"label": "Grande Cache", "value": "C16744"},
             {"label": "Grande Prairie", "value": "C16745"},
             {"label": "Grimshaw", "value": "C16746"},
             {"label": "Hanna", "value": "C16747"},
             {"label": "Heritage Pointe", "value": "C16748"},
             {"label": "High Level", "value": "C16749"},
             {"label": "High Prairie", "value": "C16750"},
             {"label": "High River", "value": "C16751"},
             {"label": "Hinton", "value": "C16752"},
             {"label": "Irricana", "value": "C16753"},
             {"label": "Jasper Park Lodge", "value": "C16754"},
             {"label": "Killam", "value": "C16755"},
             {"label": "Lac La Biche", "value": "C16756"},
             {"label": "Lacombe", "value": "C16757"},
             {"label": "Lamont", "value": "C16758"},
             {"label": "Larkspur", "value": "C16759"},
             {"label": "Laurel", "value": "C16760"},
             {"label": "Leduc", "value": "C16761"},
             {"label": "Lethbridge", "value": "C16762"},
             {"label": "Lloydminster", "value": "C16763"},
             {"label": "Magrath", "value": "C16764"},
             {"label": "Manning", "value": "C16765"},
             {"label": "Mannville", "value": "C16766"},
             {"label": "Maple Ridge", "value": "C16767"},
             {"label": "Mayerthorpe", "value": "C16768"},
             {"label": "Medicine Hat", "value": "C16769"},
             {"label": "Mill Woods Town Centre", "value": "C16770"},
             {"label": "Millet", "value": "C16771"},
             {"label": "Morinville", "value": "C16772"},
             {"label": "Nanton", "value": "C16773"},
             {"label": "Okotoks", "value": "C16774"},
             {"label": "Olds", "value": "C16775"},
             {"label": "Peace River", "value": "C16776"},
             {"label": "Penhold", "value": "C16777"},
             {"label": "Picture Butte", "value": "C16778"},
             {"label": "Pincher Creek", "value": "C16779"},
             {"label": "Ponoka", "value": "C16780"},
             {"label": "Provost", "value": "C16781"},
             {"label": "Raymond", "value": "C16782"},
             {"label": "Red Deer", "value": "C16783"},
             {"label": "Rideau Park", "value": "C16784"},
             {"label": "Rimbey", "value": "C16785"},
             {"label": "Rocky Mountain House", "value": "C16786"},
             {"label": "Sexsmith", "value": "C16787"},
             {"label": "Sherwood Park", "value": "C16788"},
             {"label": "Silver Berry", "value": "C16789"},
             {"label": "Slave Lake", "value": "C16790"},
             {"label": "Smoky Lake", "value": "C16791"},
             {"label": "Spirit River", "value": "C16792"},
             {"label": "Springbrook", "value": "C16793"},
             {"label": "Spruce Grove", "value": "C16794"},
             {"label": "St. Albert", "value": "C16795"},
             {"label": "Stettler", "value": "C16796"},
             {"label": "Stony Plain", "value": "C16797"},
             {"label": "Strathmore", "value": "C16798"},
             {"label": "Sundre", "value": "C16799"},
             {"label": "Swan Hills", "value": "C16800"},
             {"label": "Sylvan Lake", "value": "C16801"},
             {"label": "Taber", "value": "C16802"},
             {"label": "Tamarack", "value": "C16803"},
             {"label": "Three Hills", "value": "C16804"},
             {"label": "Tofield", "value": "C16805"},
             {"label": "Two Hills", "value": "C16806"},
             {"label": "Valleyview", "value": "C16807"},
             {"label": "Vegreville", "value": "C16808"},
             {"label": "Vermilion", "value": "C16809"},
             {"label": "Viking", "value": "C16810"},
             {"label": "Vulcan", "value": "C16811"},
             {"label": "Wainwright", "value": "C16812"},
             {"label": "Wembley", "value": "C16813"},
             {"label": "Westlake", "value": "C16814"},
             {"label": "Westlock", "value": "C16815"},
             {"label": "Wetaskiwin", "value": "C16816"},
             {"label": "Whitecourt", "value": "C16817"},
             {"label": "Wild Rose", "value": "C16818"}],
 "Alborz": [{"label": "Fardis", "value": "C50043"},
            {"label": "Karaj", "value": "C50044"},
            {"label": "Nazarabad", "value": "C50045"},
            {"label": "Shahrestan-e Eshtehard", "value": "C50046"},
            {"label": "Shahrestan-e Nazarabad", "value": "C50047"},
            {"label": "Shahrestan-e Savojbolagh", "value": "C50048"},
            {"label": "Shahrestan-e Taleqan", "value": "C50049"}],
 "Aleppo Governorate": [{"label": "Afrin", "value": "C117825"},
                        {"label": "Ayn al Arab", "value": "C117826"},
                        {"label": "Afrin District", "value": "C117827"},
                        {"label": "Al Atarib", "value": "C117828"},
                        {"label": "Al Bab", "value": "C117829"},
                        {"label": "Al-Bab District", "value": "C117830"},
                        {"label": "Aleppo", "value": "C117831"},
                        {"label": "As Safirah", "value": "C117832"},
                        {"label": "Azaz District", "value": "C117833"},
                        {"label": "Dayr Hafir", "value": "C117834"},
                        {"label": "Izaz", "value": "C117835"},
                        {"label": "Jarabulus", "value": "C117836"},
                        {"label": "Kafr Saghir", "value": "C117837"},
                        {"label": "Khanasir", "value": "C117838"},
                        {"label": "Manbij", "value": "C117839"},
                        {"label": "Manbij District", "value": "C117840"},
                        {"label": "Mount Simeon District", "value": "C117841"},
                        {"label": "Nubl", "value": "C117842"},
                        {"label": "Suran", "value": "C117843"},
                        {"label": "Tadif", "value": "C117844"},
                        {"label": "Tall Rifat", "value": "C117845"}],
 "Alexandria": [{"label": "Abu Qir", "value": "C24207"},
                {"label": "Agami", "value": "C24208"},
                {"label": "Alexandria", "value": "C24209"},
                {"label": "Ar-Raml", "value": "C24210"},
                {"label": "Borg El Arab", "value": "C24211"},
                {"label": "Montaza", "value": "C24212"},
                {"label": "New Borg El Arab", "value": "C24213"},
                {"label": "Sidi Bishr", "value": "C24214"}],
 "Algiers": [{"label": "Ain Taya", "value": "C237"},
             {"label": "Algiers", "value": "C238"},
             {"label": "Bab Ezzouar", "value": "C239"},
             {"label": "Birkhadem", "value": "C240"},
             {"label": "Bordj el Kiffan", "value": "C241"},
             {"label": "Dar el Beida", "value": "C242"},
             {"label": "Rouiba", "value": "C243"}],
 "Ali Sabieh Region": [{"label": "Ali Sabieh", "value": "C23806"},
                       {"label": "Goubetto", "value": "C23807"},
                       {"label": "Holhol", "value": "C23808"}],
 "Alibori Department": [{"label": "Banikoara", "value": "C9594"},
                        {"label": "Kandi", "value": "C9595"},
                        {"label": "Malanville", "value": "C9596"}],
 "Almaty": [{"label": "Almaty", "value": "C64311"}],
 "Almaty Region": [{"label": "Bakanas", "value": "C64312"},
                   {"label": "Balpyk Bi", "value": "C64313"},
                   {"label": "Burunday", "value": "C64314"},
                   {"label": "Chemolgan", "value": "C64315"},
                   {"label": "Druzhba", "value": "C64316"},
                   {"label": "Esik", "value": "C64317"},
                   {"label": "Kapshagay", "value": "C64318"},
                   {"label": "Kegen", "value": "C64319"},
                   {"label": "Lepsy", "value": "C64320"},
                   {"label": "Matay", "value": "C64321"},
                   {"label": "Otegen Batyra", "value": "C64322"},
                   {"label": "Pervomayka", "value": "C64323"},
                   {"label": "Sarkand", "value": "C64324"},
                   {"label": "Saryozek", "value": "C64325"},
                   {"label": "Taldykorgan", "value": "C64326"},
                   {"label": "Talghar", "value": "C64327"},
                   {"label": "Tekeli", "value": "C64328"},
                   {"label": "Turgen", "value": "C64329"},
                   {"label": "ulken", "value": "C64330"},
                   {"label": "Ush-Tyube", "value": "C64331"},
                   {"label": "Zharkent", "value": "C64332"}],
 "Aloja Municipality": [{"label": "Aloja", "value": "C64843"},
                        {"label": "Staicele", "value": "C64844"}],
 "Alsunga Municipality": [{"label": "Alsunga", "value": "C64845"}],
 "Alta Verapaz Department": [{"label": "Cahabon", "value": "C42615"},
                             {"label": "Chahal Guatemala", "value": "C42616"},
                             {"label": "Chisec", "value": "C42617"},
                             {"label": "Coban", "value": "C42618"},
                             {"label": "La Tinta", "value": "C42619"},
                             {"label": "Lanquin", "value": "C42620"},
                             {"label": "Panzos", "value": "C42621"},
                             {"label": "San Cristobal Verapaz",
                              "value": "C42622"},
                             {"label": "San Juan Chamelco", "value": "C42623"},
                             {"label": "San Pedro Carcha", "value": "C42624"},
                             {"label": "Santa Cruz Verapaz", "value": "C42625"},
                             {"label": "Senahu", "value": "C42626"},
                             {"label": "Tactic", "value": "C42627"},
                             {"label": "Tamahu", "value": "C42628"},
                             {"label": "Tucuru", "value": "C42629"}],
 "Altai Krai": [{"label": "Aleysk", "value": "C100165"},
                {"label": "Aleyskiy Rayon", "value": "C100166"},
                {"label": "Altayskoye", "value": "C100167"},
                {"label": "Antonyevka", "value": "C100168"},
                {"label": "Aya", "value": "C100169"},
                {"label": "Barnaul", "value": "C100170"},
                {"label": "Barnaul Urban Okrug", "value": "C100171"},
                {"label": "Bastan", "value": "C100172"},
                {"label": "Bayevo", "value": "C100173"},
                {"label": "Bayunovskiye Klyuchi", "value": "C100174"},
                {"label": "Belokurikha", "value": "C100175"},
                {"label": "Beloyarsk", "value": "C100176"},
                {"label": "Berezovka", "value": "C100177"},
                {"label": "Biysk", "value": "C100178"},
                {"label": "Blagoveshchenka", "value": "C100179"},
                {"label": "Bobrovka", "value": "C100180"},
                {"label": "Borovikha", "value": "C100181"},
                {"label": "Burla", "value": "C100182"},
                {"label": "Bystryanka", "value": "C100183"},
                {"label": "Bystryy Istok", "value": "C100184"},
                {"label": "Charyshskoye", "value": "C100185"},
                {"label": "Cheremnoye", "value": "C100186"},
                {"label": "Galbshtadt", "value": "C100187"},
                {"label": "Gonba", "value": "C100188"},
                {"label": "Gornyak", "value": "C100189"},
                {"label": "Grishkovka", "value": "C100190"},
                {"label": "Inya", "value": "C100191"},
                {"label": "Kalmanka", "value": "C100192"},
                {"label": "Kalmanskiy Rayon", "value": "C100193"},
                {"label": "Kamen-na-Obi", "value": "C100194"},
                {"label": "Khabarskiy Rayon", "value": "C100195"},
                {"label": "Khabary", "value": "C100196"},
                {"label": "Klyuchi", "value": "C100197"},
                {"label": "Kosikha", "value": "C100198"},
                {"label": "Kosikhinskiy Rayon", "value": "C100199"},
                {"label": "Krasnogorskoye", "value": "C100200"},
                {"label": "Krasnoshchekovo", "value": "C100201"},
                {"label": "Krasnyy Yar", "value": "C100202"},
                {"label": "Krutikha", "value": "C100203"},
                {"label": "Kulunda", "value": "C100204"},
                {"label": "Kulundinskiy Rayon", "value": "C100205"},
                {"label": "Kurinskiy Rayon", "value": "C100206"},
                {"label": "Kusak", "value": "C100207"},
                {"label": "Kytmanovo", "value": "C100208"},
                {"label": "Lebyazhye", "value": "C100209"},
                {"label": "Lesnoye", "value": "C100210"},
                {"label": "Logovskoye", "value": "C100211"},
                {"label": "Loktevskiy Rayon", "value": "C100212"},
                {"label": "Malinovoye Ozero", "value": "C100213"},
                {"label": "Malougrenevo", "value": "C100214"},
                {"label": "Mamontovo", "value": "C100215"},
                {"label": "Mamontovskiy Rayon", "value": "C100216"},
                {"label": "Martynovo", "value": "C100217"},
                {"label": "Mikhaylovskiy Rayon", "value": "C100218"},
                {"label": "Mikhaylovskoye", "value": "C100219"},
                {"label": "Nagornyy", "value": "C100220"},
                {"label": "Nalobikha", "value": "C100221"},
                {"label": "Natsionalnyy Rayon Nemetskiy", "value": "C100222"},
                {"label": "Nauchnyy Gorodok", "value": "C100223"},
                {"label": "Nikolayevka", "value": "C100224"},
                {"label": "Novichikha", "value": "C100225"},
                {"label": "Novichikhinskiy Rayon", "value": "C100226"},
                {"label": "Novikovo", "value": "C100227"},
                {"label": "Novoaltaysk", "value": "C100228"},
                {"label": "Novosilikatnyy", "value": "C100229"},
                {"label": "Novotyryshkino", "value": "C100230"},
                {"label": "Novyye Zori", "value": "C100231"},
                {"label": "Pankrushikha", "value": "C100232"},
                {"label": "Pankrushikhinskiy Rayon", "value": "C100233"},
                {"label": "Pavlovsk", "value": "C100234"},
                {"label": "Pavlovskiy Rayon", "value": "C100235"},
                {"label": "Pervomayskiy", "value": "C100236"},
                {"label": "Pervomayskiy Rayon", "value": "C100237"},
                {"label": "Pervomayskoye", "value": "C100238"},
                {"label": "Petropavlovskiy Rayon", "value": "C100239"},
                {"label": "Petropavlovskoye", "value": "C100240"},
                {"label": "Podsosnovo", "value": "C100241"},
                {"label": "Pospelikha", "value": "C100242"},
                {"label": "Pospelikhinskiy Rayon", "value": "C100243"},
                {"label": "Rebrikha", "value": "C100244"},
                {"label": "Rebrikhinskiy Rayon", "value": "C100245"},
                {"label": "Rodino", "value": "C100246"},
                {"label": "Rodinskiy Rayon", "value": "C100247"},
                {"label": "Romanovo", "value": "C100248"},
                {"label": "Romanovskiy Rayon", "value": "C100249"},
                {"label": "Rubtsovsk", "value": "C100250"},
                {"label": "Rubtsovskiy Rayon", "value": "C100251"},
                {"label": "Sannikovo", "value": "C100252"},
                {"label": "Severka", "value": "C100253"},
                {"label": "Shakhi", "value": "C100254"},
                {"label": "Shelabolikha", "value": "C100255"},
                {"label": "Shelabolikhinskiy Rayon", "value": "C100256"},
                {"label": "Shipunovo", "value": "C100257"},
                {"label": "Shipunovskiy Rayon", "value": "C100258"},
                {"label": "Shubenka", "value": "C100259"},
                {"label": "Shulgin Log", "value": "C100260"},
                {"label": "Sibirskiy", "value": "C100261"},
                {"label": "Slavgorod", "value": "C100262"},
                {"label": "Slavgorodskoye", "value": "C100263"},
                {"label": "Smolenskiy Rayon", "value": "C100264"},
                {"label": "Smolenskoye", "value": "C100265"},
                {"label": "Sokolovo", "value": "C100266"},
                {"label": "Soloneshenskiy Rayon", "value": "C100267"},
                {"label": "Soloneshnoye", "value": "C100268"},
                {"label": "Solton", "value": "C100269"},
                {"label": "Soltonskiy Rayon", "value": "C100270"},
                {"label": "Sorokino", "value": "C100271"},
                {"label": "Sovetskoye", "value": "C100272"},
                {"label": "Srostki", "value": "C100273"},
                {"label": "Stan-Bekhtemir", "value": "C100274"},
                {"label": "Staroaleyskoye", "value": "C100275"},
                {"label": "Starobelokurikha", "value": "C100276"},
                {"label": "Staryy Togul", "value": "C100277"},
                {"label": "Stukovo", "value": "C100278"},
                {"label": "Sychevka", "value": "C100279"},
                {"label": "Tabuny", "value": "C100280"},
                {"label": "Talmenka", "value": "C100281"},
                {"label": "Talmenskiy Rayon", "value": "C100282"},
                {"label": "Togul", "value": "C100283"},
                {"label": "Togulskiy Rayon", "value": "C100284"},
                {"label": "Topchikha", "value": "C100285"},
                {"label": "Topolnoye", "value": "C100286"},
                {"label": "Troitskoye", "value": "C100287"},
                {"label": "Tselinnoye", "value": "C100288"},
                {"label": "Tyumentsevo", "value": "C100289"},
                {"label": "Tyumentsevskiy Rayon", "value": "C100290"},
                {"label": "Uglovskoye", "value": "C100291"},
                {"label": "Ust-Charyshskaya Pristan", "value": "C100292"},
                {"label": "Ust-Isha", "value": "C100293"},
                {"label": "Ust-Kalmanka", "value": "C100294"},
                {"label": "Ustyanka", "value": "C100295"},
                {"label": "Usyatskoye", "value": "C100296"},
                {"label": "Verkh-Katunskoye", "value": "C100297"},
                {"label": "Verkh-Suetka", "value": "C100298"},
                {"label": "Verkhniy Bekhtemir", "value": "C100299"},
                {"label": "Veseloyarsk", "value": "C100300"},
                {"label": "Vlasikha", "value": "C100301"},
                {"label": "Volchikha", "value": "C100302"},
                {"label": "Volchikhinskiy Rayon", "value": "C100303"},
                {"label": "Yarovoye", "value": "C100304"},
                {"label": "Yegoryevskiy Rayon", "value": "C100305"},
                {"label": "Yeltsovskiy Rayon", "value": "C100306"},
                {"label": "Yuzhnyy", "value": "C100307"},
                {"label": "Zalesovo", "value": "C100308"},
                {"label": "Zarinsk", "value": "C100309"},
                {"label": "Zarinskiy Rayon", "value": "C100310"},
                {"label": "Zarya", "value": "C100311"},
                {"label": "Zaton", "value": "C100312"},
                {"label": "Zavyalovo", "value": "C100313"},
                {"label": "Zavyalovskiy Rayon", "value": "C100314"},
                {"label": "Zmeinogorsk", "value": "C100315"},
                {"label": "Zonalnoye", "value": "C100316"},
                {"label": "Zudilovo", "value": "C100317"}],
 "Altai Republic": [{"label": "Aktash", "value": "C100318"},
                    {"label": "Artybash", "value": "C100319"},
                    {"label": "Belyashi", "value": "C100320"},
                    {"label": "Chemal", "value": "C100321"},
                    {"label": "Cherga", "value": "C100322"},
                    {"label": "Choya", "value": "C100323"},
                    {"label": "Elekmonar", "value": "C100324"},
                    {"label": "Gorno-Altaysk", "value": "C100325"},
                    {"label": "Inya", "value": "C100326"},
                    {"label": "Iogach", "value": "C100327"},
                    {"label": "Kosh-Agach", "value": "C100328"},
                    {"label": "Manzherok", "value": "C100329"},
                    {"label": "Mayma", "value": "C100330"},
                    {"label": "Mayminskiy Rayon", "value": "C100331"},
                    {"label": "Onguday", "value": "C100332"},
                    {"label": "Ongudayskiy Rayon", "value": "C100333"},
                    {"label": "Shebalino", "value": "C100334"},
                    {"label": "Shebalinskiy Rayon", "value": "C100335"},
                    {"label": "Souzga", "value": "C100336"},
                    {"label": "Tashanta", "value": "C100337"},
                    {"label": "Turochak", "value": "C100338"},
                    {"label": "Ust-Kan", "value": "C100339"},
                    {"label": "Ust-Koksa", "value": "C100340"},
                    {"label": "Ust-Koksinskiy Rayon", "value": "C100341"},
                    {"label": "Ust-Ulagan", "value": "C100342"}],
 "Alto Paraguay Department": [{"label": "Capitan Pablo Lagerenza",
                               "value": "C80487"},
                              {"label": "Fuerte Olimpo", "value": "C80488"}],
 "Alto Parana Department": [{"label": "Cedrales", "value": "C80489"},
                            {"label": "Ciudad del Este", "value": "C80490"},
                            {"label": "Colonia Minga Pora", "value": "C80491"},
                            {"label": "Doctor Juan Leon Mallorquin",
                             "value": "C80492"},
                            {"label": "Itakyry", "value": "C80493"},
                            {"label": "Naranjal", "value": "C80494"},
                            {"label": "Presidente Franco", "value": "C80495"},
                            {"label": "San Alberto", "value": "C80496"},
                            {"label": "Santa Rita", "value": "C80497"},
                            {"label": "Yguazu", "value": "C80498"}],
 "Aluksne Municipality": [{"label": "Aluksne", "value": "C64846"}],
 "Alytus City Municipality": [{"label": "Alytus", "value": "C65082"},
                              {"label": "Daugai", "value": "C65083"},
                              {"label": "Druskininkai", "value": "C65084"},
                              {"label": "Lazdijai", "value": "C65085"},
                              {"label": "Simnas", "value": "C65086"},
                              {"label": "Varena", "value": "C65087"},
                              {"label": "Veisiejai", "value": "C65088"}],
 "Amambay Department": [{"label": "Bella Vista", "value": "C80499"},
                        {"label": "Capitan Bado", "value": "C80500"},
                        {"label": "Pedro Juan Caballero", "value": "C80501"}],
 "Amapa": [{"label": "Amapa", "value": "C10321"},
           {"label": "Calcoene", "value": "C10322"},
           {"label": "Cutias", "value": "C10323"},
           {"label": "Ferreira Gomes", "value": "C10324"},
           {"label": "Itaubal", "value": "C10325"},
           {"label": "Laranjal do Jari", "value": "C10326"},
           {"label": "Macapa", "value": "C10327"},
           {"label": "Mazagao", "value": "C10328"},
           {"label": "Oiapoque", "value": "C10329"},
           {"label": "Pedra Branca do Amapari", "value": "C10330"},
           {"label": "Porto Grande", "value": "C10331"},
           {"label": "Pracuuba", "value": "C10332"},
           {"label": "Santana", "value": "C10333"},
           {"label": "Serra do Navio", "value": "C10334"},
           {"label": "Tartarugalzinho", "value": "C10335"},
           {"label": "Vitoria do Jari", "value": "C10336"}],
 "Amasya": [{"label": "Amasya", "value": "C119879"},
            {"label": "Dedekoy", "value": "C119880"},
            {"label": "Goynucek", "value": "C119881"},
            {"label": "Gumushacikoy", "value": "C119882"},
            {"label": "Hamamozu Ilcesi", "value": "C119883"},
            {"label": "Merkez", "value": "C119884"},
            {"label": "Merzifon Ilcesi", "value": "C119885"},
            {"label": "Suluova", "value": "C119886"},
            {"label": "Tasova", "value": "C119887"}],
 "Amazonas": [{"label": "Alvaraes", "value": "C10337"},
              {"label": "Amatura", "value": "C10338"},
              {"label": "Anama", "value": "C10339"},
              {"label": "Anori", "value": "C10340"},
              {"label": "Apui", "value": "C10341"},
              {"label": "Atalaia do Norte", "value": "C10342"},
              {"label": "Autazes", "value": "C10343"},
              {"label": "Barcelos", "value": "C10344"},
              {"label": "Barreirinha", "value": "C10345"},
              {"label": "Benjamin Constant", "value": "C10346"},
              {"label": "Beruri", "value": "C10347"},
              {"label": "Boa Vista do Ramos", "value": "C10348"},
              {"label": "Boca do Acre", "value": "C10349"},
              {"label": "Borba", "value": "C10350"},
              {"label": "Caapiranga", "value": "C10351"},
              {"label": "Canutama", "value": "C10352"},
              {"label": "Carauari", "value": "C10353"},
              {"label": "Careiro", "value": "C10354"},
              {"label": "Careiro da Varzea", "value": "C10355"},
              {"label": "Coari", "value": "C10356"},
              {"label": "Codajas", "value": "C10357"},
              {"label": "Eirunepe", "value": "C10358"},
              {"label": "Envira", "value": "C10359"},
              {"label": "Fonte Boa", "value": "C10360"},
              {"label": "Guajara", "value": "C10361"},
              {"label": "Humaita", "value": "C10362"},
              {"label": "Ipixuna", "value": "C10363"},
              {"label": "Iranduba", "value": "C10364"},
              {"label": "Itacoatiara", "value": "C10365"},
              {"label": "Itamarati", "value": "C10366"},
              {"label": "Itapiranga", "value": "C10367"},
              {"label": "Japura", "value": "C10368"},
              {"label": "Jurua", "value": "C10369"},
              {"label": "Jutai", "value": "C10370"},
              {"label": "Labrea", "value": "C10371"},
              {"label": "Manacapuru", "value": "C10372"},
              {"label": "Manaquiri", "value": "C10373"},
              {"label": "Manaus", "value": "C10374"},
              {"label": "Manicore", "value": "C10375"},
              {"label": "Maraa", "value": "C10376"},
              {"label": "Maues", "value": "C10377"},
              {"label": "Nhamunda", "value": "C10378"},
              {"label": "Nova Olinda do Norte", "value": "C10379"},
              {"label": "Novo Airao", "value": "C10380"},
              {"label": "Novo Aripuana", "value": "C10381"},
              {"label": "Parintins", "value": "C10382"},
              {"label": "Pauini", "value": "C10383"},
              {"label": "Presidente Figueiredo", "value": "C10384"},
              {"label": "Rio Preto da Eva", "value": "C10385"},
              {"label": "Santa Isabel do Rio Negro", "value": "C10386"},
              {"label": "Santo Antonio do Ica", "value": "C10387"},
              {"label": "Sao Gabriel da Cachoeira", "value": "C10388"},
              {"label": "Sao Paulo de Olivenca", "value": "C10389"},
              {"label": "Sao Sebastiao do Uatuma", "value": "C10390"},
              {"label": "Silves", "value": "C10391"},
              {"label": "Tabatinga", "value": "C10392"},
              {"label": "Tapaua", "value": "C10393"},
              {"label": "Tefe", "value": "C10394"},
              {"label": "Tonantins", "value": "C10395"},
              {"label": "Uarini", "value": "C10396"},
              {"label": "Urucara", "value": "C10397"},
              {"label": "Urucurituba", "value": "C10398"},
              {"label": "El Encanto", "value": "C19535"},
              {"label": "La Chorrera", "value": "C19536"},
              {"label": "La Pedrera", "value": "C19537"},
              {"label": "La Victoria", "value": "C19538"},
              {"label": "Leticia", "value": "C19539"},
              {"label": "Miriti - Parana", "value": "C19540"},
              {"label": "Puerto Alegria", "value": "C19541"},
              {"label": "Puerto Arica", "value": "C19542"},
              {"label": "Puerto Narino", "value": "C19543"},
              {"label": "Puerto Santander", "value": "C19544"},
              {"label": "Tarapaca", "value": "C19545"},
              {"label": "Bagua Grande", "value": "C80627"},
              {"label": "Cajaruro", "value": "C80628"},
              {"label": "Chachapoyas", "value": "C80629"},
              {"label": "Condorcanqui", "value": "C80630"},
              {"label": "La Peca", "value": "C80631"},
              {"label": "Provincia de Bagua", "value": "C80632"},
              {"label": "Provincia de Bongara", "value": "C80633"},
              {"label": "Provincia de Chachapoyas", "value": "C80634"},
              {"label": "Provincia de Luya", "value": "C80635"},
              {"label": "Provincia de Rodriguez de Mendoza", "value": "C80636"},
              {"label": "Utcubamba", "value": "C80637"},
              {"label": "Maroa", "value": "C147123"},
              {"label": "Municipio Autonomo Alto Orinoco", "value": "C147124"},
              {"label": "Puerto Ayacucho", "value": "C147125"},
              {"label": "San Carlos de Rio Negro", "value": "C147126"},
              {"label": "San Fernando de Atabapo", "value": "C147127"},
              {"label": "San Juan de Manapiare", "value": "C147128"}],
 "Amhara Region": [{"label": "Abomsa", "value": "C24669"},
                   {"label": "Addiet Canna", "value": "C24670"},
                   {"label": "adis Zemen", "value": "C24671"},
                   {"label": "Bahir Dar", "value": "C24672"},
                   {"label": "Bati", "value": "C24673"},
                   {"label": "Bichena", "value": "C24674"},
                   {"label": "Bure", "value": "C24675"},
                   {"label": "Dabat", "value": "C24676"},
                   {"label": "Debark", "value": "C24677"},
                   {"label": "Debre Birhan", "value": "C24678"},
                   {"label": "Debre Markos", "value": "C24679"},
                   {"label": "Debre Sina", "value": "C24680"},
                   {"label": "Debre Tabor", "value": "C24681"},
                   {"label": "Debre Werk", "value": "C24682"},
                   {"label": "Dejen", "value": "C24683"},
                   {"label": "Dese", "value": "C24684"},
                   {"label": "Finote Selam", "value": "C24685"},
                   {"label": "Gondar", "value": "C24686"},
                   {"label": "Kemise", "value": "C24687"},
                   {"label": "Kombolcha", "value": "C24688"},
                   {"label": "Lalibela", "value": "C24689"},
                   {"label": "North Shewa Zone", "value": "C24690"},
                   {"label": "North Wollo Zone", "value": "C24691"},
                   {"label": "Robit", "value": "C24692"},
                   {"label": "South Gondar Zone", "value": "C24693"},
                   {"label": "South Wollo Zone", "value": "C24694"},
                   {"label": "Wag Hemra Zone", "value": "C24695"},
                   {"label": "Were ilu", "value": "C24696"},
                   {"label": "Werota", "value": "C24697"}],
 "Amman Governorate": [{"label": "Al Jizah", "value": "C64200"},
                       {"label": "Al Jubayhah", "value": "C64201"},
                       {"label": "Amman", "value": "C64202"},
                       {"label": "Hayy al Bunayyat", "value": "C64203"},
                       {"label": "Hayy al Quwaysimah", "value": "C64204"},
                       {"label": "Jawa", "value": "C64205"},
                       {"label": "Sahab", "value": "C64206"},
                       {"label": "Umm as Summaq", "value": "C64207"},
                       {"label": "Wadi as Sir", "value": "C64208"}],
 "Amnat Charoen": [{"label": "Amnat Charoen", "value": "C118364"},
                   {"label": "Amphoe Chanuman", "value": "C118365"},
                   {"label": "Amphoe Hua Taphan", "value": "C118366"},
                   {"label": "Amphoe Lue Amnat", "value": "C118367"},
                   {"label": "Amphoe Mueang Amnat Charoen", "value": "C118368"},
                   {"label": "Amphoe Pathum Ratchawongsa", "value": "C118369"},
                   {"label": "Amphoe Phana", "value": "C118370"},
                   {"label": "Amphoe Senangkhanikhom", "value": "C118371"}],
 "Amran Governorate": [{"label": "Amran", "value": "C147753"},
                       {"label": "Al Ashah", "value": "C147754"},
                       {"label": "Al Madan", "value": "C147755"},
                       {"label": "Al Qaflah", "value": "C147756"},
                       {"label": "As Sawd", "value": "C147757"},
                       {"label": "As Sudah", "value": "C147758"},
                       {"label": "Bani Suraim", "value": "C147759"},
                       {"label": "Dhi Bin", "value": "C147760"},
                       {"label": "Habur Zulaymah", "value": "C147761"},
                       {"label": "Harf Sufyan", "value": "C147762"},
                       {"label": "Hooth", "value": "C147763"},
                       {"label": "Iyal Surayh", "value": "C147764"},
                       {"label": "Jabal Iyal Yazid", "value": "C147765"},
                       {"label": "Khamir", "value": "C147766"},
                       {"label": "Kharif", "value": "C147767"},
                       {"label": "Maswar", "value": "C147768"},
                       {"label": "Raydah", "value": "C147769"},
                       {"label": "Shaharah", "value": "C147770"},
                       {"label": "Suwayr", "value": "C147771"},
                       {"label": "Thula", "value": "C147772"}],
 "Amur Oblast": [{"label": "Arkhara", "value": "C100343"},
                 {"label": "Arkharinskiy Rayon", "value": "C100344"},
                 {"label": "Belogorsk", "value": "C100345"},
                 {"label": "Belogorskiy Rayon", "value": "C100346"},
                 {"label": "Blagoveshchensk", "value": "C100347"},
                 {"label": "Blagoveshchenskiy Rayon", "value": "C100348"},
                 {"label": "Bureya", "value": "C100349"},
                 {"label": "Bureyskiy Rayon", "value": "C100350"},
                 {"label": "Ekimchan", "value": "C100351"},
                 {"label": "Gorod Blagoveshchensk", "value": "C100352"},
                 {"label": "Gorod Raychikhinsk", "value": "C100353"},
                 {"label": "Ivanovskiy Rayon", "value": "C100354"},
                 {"label": "Konstantinovka", "value": "C100355"},
                 {"label": "Konstantinovskiy Rayon", "value": "C100356"},
                 {"label": "Magdagachi", "value": "C100357"},
                 {"label": "Magdagachinskiy Rayon", "value": "C100358"},
                 {"label": "Mazanovskiy Rayon", "value": "C100359"},
                 {"label": "Mikhaylovskiy Rayon", "value": "C100360"},
                 {"label": "Never", "value": "C100361"},
                 {"label": "Novobureyskiy", "value": "C100362"},
                 {"label": "Novoraychikhinsk", "value": "C100363"},
                 {"label": "Oktyabrskiy Rayon", "value": "C100364"},
                 {"label": "Ovsyanka", "value": "C100365"},
                 {"label": "Poyarkovo", "value": "C100366"},
                 {"label": "Raychikhinsk", "value": "C100367"},
                 {"label": "Romnenskiy Rayon", "value": "C100368"},
                 {"label": "Romny", "value": "C100369"},
                 {"label": "Selemdzhinskiy Rayon", "value": "C100370"},
                 {"label": "Seryshevo", "value": "C100371"},
                 {"label": "Seryshevskiy Rayon", "value": "C100372"},
                 {"label": "Shimanovsk", "value": "C100373"},
                 {"label": "Shimanovskiy Rayon", "value": "C100374"},
                 {"label": "Shirokiy", "value": "C100375"},
                 {"label": "Sivaki", "value": "C100376"},
                 {"label": "Skovorodino", "value": "C100377"},
                 {"label": "Skovorodinskiy Rayon", "value": "C100378"},
                 {"label": "Solovjevsk", "value": "C100379"},
                 {"label": "Srednebelaya", "value": "C100380"},
                 {"label": "Svobodnenskiy Rayon", "value": "C100381"},
                 {"label": "Svobodnyy", "value": "C100382"},
                 {"label": "Takhtamygda", "value": "C100383"},
                 {"label": "Talakan", "value": "C100384"},
                 {"label": "Taldan", "value": "C100385"},
                 {"label": "Tambovka", "value": "C100386"},
                 {"label": "Tambovskiy Rayon", "value": "C100387"},
                 {"label": "Tokur", "value": "C100388"},
                 {"label": "Tsiolkovskiy", "value": "C100389"},
                 {"label": "Tygda", "value": "C100390"},
                 {"label": "Tynda", "value": "C100391"},
                 {"label": "Tyndinskiy Rayon", "value": "C100392"},
                 {"label": "Urusha", "value": "C100393"},
                 {"label": "Ushumun", "value": "C100394"},
                 {"label": "Yekaterinoslavka", "value": "C100395"},
                 {"label": "Yerofey Pavlovich", "value": "C100396"},
                 {"label": "Zavitinsk", "value": "C100397"},
                 {"label": "Zavitinskiy Rayon", "value": "C100398"},
                 {"label": "Zeya", "value": "C100399"},
                 {"label": "Zeyskiy Rayon", "value": "C100400"}],
 "An Giang": [{"label": "Cho Dok", "value": "C147258"},
              {"label": "Huyen An Phu", "value": "C147259"},
              {"label": "Huyen Chau Phu", "value": "C147260"},
              {"label": "Huyen Cho Moi", "value": "C147261"},
              {"label": "Huyen Phu Tan", "value": "C147262"},
              {"label": "Huyen Tri Ton", "value": "C147263"},
              {"label": "Long Xuyen", "value": "C147264"},
              {"label": "Long Xuyen City", "value": "C147265"}],
 "Anabar District": [{"label": "Anabar", "value": "C76013"}],
 "Anambra": [{"label": "Agulu", "value": "C78138"},
             {"label": "Atani", "value": "C78139"},
             {"label": "Awka", "value": "C78140"},
             {"label": "Enugu-Ukwu", "value": "C78141"},
             {"label": "Igbo-Ukwu", "value": "C78142"},
             {"label": "Ihiala", "value": "C78143"},
             {"label": "Nkpor", "value": "C78144"},
             {"label": "Nnewi", "value": "C78145"},
             {"label": "Onitsha", "value": "C78146"},
             {"label": "Ozubulu", "value": "C78147"},
             {"label": "Uga", "value": "C78148"},
             {"label": "Uruobo-Okija", "value": "C78149"}],
 "Andalusia": [{"label": "Abla", "value": "C108550"},
               {"label": "Abrucena", "value": "C108551"},
               {"label": "Adamuz", "value": "C108552"},
               {"label": "Adra", "value": "C108553"},
               {"label": "Agron", "value": "C108554"},
               {"label": "Aguadulce", "value": "C108555"},
               {"label": "Aguilar", "value": "C108556"},
               {"label": "Alajar", "value": "C108557"},
               {"label": "Alameda", "value": "C108558"},
               {"label": "Alamedilla", "value": "C108559"},
               {"label": "Alanis", "value": "C108560"},
               {"label": "Albaida del Aljarafe", "value": "C108561"},
               {"label": "Albanchez", "value": "C108562"},
               {"label": "Albolote", "value": "C108563"},
               {"label": "Albondon", "value": "C108564"},
               {"label": "Albox", "value": "C108565"},
               {"label": "Albunan", "value": "C108566"},
               {"label": "Albunol", "value": "C108567"},
               {"label": "Albunuelas", "value": "C108568"},
               {"label": "Alcala de Guadaira", "value": "C108569"},
               {"label": "Alcala de los Gazules", "value": "C108570"},
               {"label": "Alcala del Rio", "value": "C108571"},
               {"label": "Alcala del Valle", "value": "C108572"},
               {"label": "Alcala la Real", "value": "C108573"},
               {"label": "Alcaracejos", "value": "C108574"},
               {"label": "Alcaucin", "value": "C108575"},
               {"label": "Alcaudete", "value": "C108576"},
               {"label": "Alcolea", "value": "C108577"},
               {"label": "Alcolea del Rio", "value": "C108578"},
               {"label": "Alcontar", "value": "C108579"},
               {"label": "Alcudia de Monteagud", "value": "C108580"},
               {"label": "Aldeaquemada", "value": "C108581"},
               {"label": "Aldeire", "value": "C108582"},
               {"label": "Alfacar", "value": "C108583"},
               {"label": "Alfarnate", "value": "C108584"},
               {"label": "Alfarnatejo", "value": "C108585"},
               {"label": "Algamitas", "value": "C108586"},
               {"label": "Algar", "value": "C108587"},
               {"label": "Algarinejo", "value": "C108588"},
               {"label": "Algarrobo", "value": "C108589"},
               {"label": "Algatocin", "value": "C108590"},
               {"label": "Algeciras", "value": "C108591"},
               {"label": "Algodonales", "value": "C108592"},
               {"label": "Alhabia", "value": "C108593"},
               {"label": "Alhama de Almeria", "value": "C108594"},
               {"label": "Alhama de Granada", "value": "C108595"},
               {"label": "Alhaurin de la Torre", "value": "C108596"},
               {"label": "Alhaurin el Grande", "value": "C108597"},
               {"label": "Alhendin", "value": "C108598"},
               {"label": "Alicun", "value": "C108599"},
               {"label": "Alicun de Ortega", "value": "C108600"},
               {"label": "Aljaraque", "value": "C108601"},
               {"label": "Almachar", "value": "C108602"},
               {"label": "Almaden de la Plata", "value": "C108603"},
               {"label": "Almargen", "value": "C108604"},
               {"label": "Almedinilla", "value": "C108605"},
               {"label": "Almegijar", "value": "C108606"},
               {"label": "Almensilla", "value": "C108607"},
               {"label": "Almeria", "value": "C108608"},
               {"label": "Almocita", "value": "C108609"},
               {"label": "Almodovar del Rio", "value": "C108610"},
               {"label": "Almogia", "value": "C108611"},
               {"label": "Almonte", "value": "C108612"},
               {"label": "Almunecar", "value": "C108613"},
               {"label": "Alora", "value": "C108614"},
               {"label": "Alosno", "value": "C108615"},
               {"label": "Alozaina", "value": "C108616"},
               {"label": "Alpandeire", "value": "C108617"},
               {"label": "Alquife", "value": "C108618"},
               {"label": "Alsodux", "value": "C108619"},
               {"label": "Andujar", "value": "C108620"},
               {"label": "Anora", "value": "C108621"},
               {"label": "Antas", "value": "C108622"},
               {"label": "Antequera", "value": "C108623"},
               {"label": "Aracena", "value": "C108624"},
               {"label": "Arboleas", "value": "C108625"},
               {"label": "archez", "value": "C108626"},
               {"label": "Archidona", "value": "C108627"},
               {"label": "Arcos de la Frontera", "value": "C108628"},
               {"label": "Ardales", "value": "C108629"},
               {"label": "Arenas", "value": "C108630"},
               {"label": "Arenas del Rey", "value": "C108631"},
               {"label": "Arjona", "value": "C108632"},
               {"label": "Arjonilla", "value": "C108633"},
               {"label": "Armilla", "value": "C108634"},
               {"label": "Armuna de Almanzora", "value": "C108635"},
               {"label": "Aroche", "value": "C108636"},
               {"label": "Arquillos", "value": "C108637"},
               {"label": "Arriate", "value": "C108638"},
               {"label": "Arroyo del Ojanco", "value": "C108639"},
               {"label": "Arroyomolinos de Leon", "value": "C108640"},
               {"label": "Atajate", "value": "C108641"},
               {"label": "Atarfe", "value": "C108642"},
               {"label": "Ayamonte", "value": "C108643"},
               {"label": "Aznalcazar", "value": "C108644"},
               {"label": "Aznalcollar", "value": "C108645"},
               {"label": "Badolatosa", "value": "C108646"},
               {"label": "Baena", "value": "C108647"},
               {"label": "Baeza", "value": "C108648"},
               {"label": "Bailen", "value": "C108649"},
               {"label": "Banos de la Encina", "value": "C108650"},
               {"label": "Barbate", "value": "C108651"},
               {"label": "Bayarcal", "value": "C108652"},
               {"label": "Bayarque", "value": "C108653"},
               {"label": "Baza", "value": "C108654"},
               {"label": "Beas", "value": "C108655"},
               {"label": "Beas de Granada", "value": "C108656"},
               {"label": "Beas de Guadix", "value": "C108657"},
               {"label": "Beas de Segura", "value": "C108658"},
               {"label": "Bedar", "value": "C108659"},
               {"label": "Begijar", "value": "C108660"},
               {"label": "Beires", "value": "C108661"},
               {"label": "Belalcazar", "value": "C108662"},
               {"label": "Belmez", "value": "C108663"},
               {"label": "Belmez de la Moraleda", "value": "C108664"},
               {"label": "Benacazon", "value": "C108665"},
               {"label": "Benahadux", "value": "C108666"},
               {"label": "Benahavis", "value": "C108667"},
               {"label": "Benalauria", "value": "C108668"},
               {"label": "Benalmadena", "value": "C108669"},
               {"label": "Benalua de Guadix", "value": "C108670"},
               {"label": "Benalua de las Villas", "value": "C108671"},
               {"label": "Benalup-Casas Viejas", "value": "C108672"},
               {"label": "Benamargosa", "value": "C108673"},
               {"label": "Benamaurel", "value": "C108674"},
               {"label": "Benameji", "value": "C108675"},
               {"label": "Benamocarra", "value": "C108676"},
               {"label": "Benaocaz", "value": "C108677"},
               {"label": "Benaojan", "value": "C108678"},
               {"label": "Benarraba", "value": "C108679"},
               {"label": "Benatae", "value": "C108680"},
               {"label": "Benitagla", "value": "C108681"},
               {"label": "Benizalon", "value": "C108682"},
               {"label": "Bentarique", "value": "C108683"},
               {"label": "Berchules", "value": "C108684"},
               {"label": "Berja", "value": "C108685"},
               {"label": "Berrocal", "value": "C108686"},
               {"label": "Bollullos de la Mitacion", "value": "C108687"},
               {"label": "Bollullos par del Condado", "value": "C108688"},
               {"label": "Bonares", "value": "C108689"},
               {"label": "Bormujos", "value": "C108690"},
               {"label": "Bornos", "value": "C108691"},
               {"label": "Brenes", "value": "C108692"},
               {"label": "Bubion", "value": "C108693"},
               {"label": "Bujalance", "value": "C108694"},
               {"label": "Burguillos", "value": "C108695"},
               {"label": "Busquistar", "value": "C108696"},
               {"label": "Cabezas Rubias", "value": "C108697"},
               {"label": "Cabra", "value": "C108698"},
               {"label": "Cabra del Santo Cristo", "value": "C108699"},
               {"label": "Cadiar", "value": "C108700"},
               {"label": "Cadiz", "value": "C108701"},
               {"label": "Cajar", "value": "C108702"},
               {"label": "Cala", "value": "C108703"},
               {"label": "Calanas", "value": "C108704"},
               {"label": "Calicasas", "value": "C108705"},
               {"label": "Camas", "value": "C108706"},
               {"label": "Cambil", "value": "C108707"},
               {"label": "Campillo de Arenas", "value": "C108708"},
               {"label": "Campillos", "value": "C108709"},
               {"label": "Campina", "value": "C108710"},
               {"label": "Campotejar", "value": "C108711"},
               {"label": "Canada Rosal", "value": "C108712"},
               {"label": "Canar", "value": "C108713"},
               {"label": "Canaveral de Leon", "value": "C108714"},
               {"label": "Canena", "value": "C108715"},
               {"label": "Canete de las Torres", "value": "C108716"},
               {"label": "Canete la Real", "value": "C108717"},
               {"label": "Caniles", "value": "C108718"},
               {"label": "Canillas de Aceituno", "value": "C108719"},
               {"label": "Canillas de Albaida", "value": "C108720"},
               {"label": "Canjayar", "value": "C108721"},
               {"label": "Cantillana", "value": "C108722"},
               {"label": "Cantoria", "value": "C108723"},
               {"label": "Capileira", "value": "C108724"},
               {"label": "Carataunas", "value": "C108725"},
               {"label": "Carboneras", "value": "C108726"},
               {"label": "Carboneros", "value": "C108727"},
               {"label": "Carcabuey", "value": "C108728"},
               {"label": "Cardena", "value": "C108729"},
               {"label": "Carmona", "value": "C108730"},
               {"label": "Carratraca", "value": "C108731"},
               {"label": "Carrion de los Cespedes", "value": "C108732"},
               {"label": "Cartajima", "value": "C108733"},
               {"label": "Cartama", "value": "C108734"},
               {"label": "Cartaya", "value": "C108735"},
               {"label": "Casabermeja", "value": "C108736"},
               {"label": "Casarabonela", "value": "C108737"},
               {"label": "Casares", "value": "C108738"},
               {"label": "Casariche", "value": "C108739"},
               {"label": "Castaras", "value": "C108740"},
               {"label": "Castell de Ferro", "value": "C108741"},
               {"label": "Castellar de la Frontera", "value": "C108742"},
               {"label": "Castellar de Santisteban", "value": "C108743"},
               {"label": "Castilblanco de los Arroyos", "value": "C108744"},
               {"label": "Castilleja de Guzman", "value": "C108745"},
               {"label": "Castilleja de la Cuesta", "value": "C108746"},
               {"label": "Castilleja del Campo", "value": "C108747"},
               {"label": "Castillejar", "value": "C108748"},
               {"label": "Castillo de Locubin", "value": "C108749"},
               {"label": "Castril", "value": "C108750"},
               {"label": "Castro de Filabres", "value": "C108751"},
               {"label": "Castro del Rio", "value": "C108752"},
               {"label": "Cazalilla", "value": "C108753"},
               {"label": "Cazalla de la Sierra", "value": "C108754"},
               {"label": "Cazorla", "value": "C108755"},
               {"label": "Cenes de la Vega", "value": "C108756"},
               {"label": "Chauchina", "value": "C108757"},
               {"label": "Chercos", "value": "C108758"},
               {"label": "Chiclana de la Frontera", "value": "C108759"},
               {"label": "Chiclana de Segura", "value": "C108760"},
               {"label": "Chilluevar", "value": "C108761"},
               {"label": "Chimeneas", "value": "C108762"},
               {"label": "Chipiona", "value": "C108763"},
               {"label": "Chirivel", "value": "C108764"},
               {"label": "Chucena", "value": "C108765"},
               {"label": "Churriana de la Vega", "value": "C108766"},
               {"label": "Cijuela", "value": "C108767"},
               {"label": "Cobdar", "value": "C108768"},
               {"label": "Cogollos de Guadix", "value": "C108769"},
               {"label": "Coin", "value": "C108770"},
               {"label": "Colmenar", "value": "C108771"},
               {"label": "Colomera", "value": "C108772"},
               {"label": "Comares", "value": "C108773"},
               {"label": "Competa", "value": "C108774"},
               {"label": "Conil de la Frontera", "value": "C108775"},
               {"label": "Conquista", "value": "C108776"},
               {"label": "Constantina", "value": "C108777"},
               {"label": "Cordoba", "value": "C108778"},
               {"label": "Coria del Rio", "value": "C108779"},
               {"label": "Coripe", "value": "C108780"},
               {"label": "Corteconcepcion", "value": "C108781"},
               {"label": "Cortelazor", "value": "C108782"},
               {"label": "Cortes de Baza", "value": "C108783"},
               {"label": "Cortes de la Frontera", "value": "C108784"},
               {"label": "Costacabana", "value": "C108785"},
               {"label": "Cuevas Bajas", "value": "C108786"},
               {"label": "Cuevas de San Marcos", "value": "C108787"},
               {"label": "Cuevas del Almanzora", "value": "C108788"},
               {"label": "Cuevas del Becerro", "value": "C108789"},
               {"label": "Cuevas del Campo", "value": "C108790"},
               {"label": "Cullar-Vega", "value": "C108791"},
               {"label": "Cumbres Mayores", "value": "C108792"},
               {"label": "Cutar", "value": "C108793"},
               {"label": "Dalias", "value": "C108794"},
               {"label": "Darro", "value": "C108795"},
               {"label": "Dehesas de Guadix", "value": "C108796"},
               {"label": "Deifontes", "value": "C108797"},
               {"label": "Diezma", "value": "C108798"},
               {"label": "Dilar", "value": "C108799"},
               {"label": "Domingo Perez", "value": "C108800"},
               {"label": "Dona Mencia", "value": "C108801"},
               {"label": "Dos Hermanas", "value": "C108802"},
               {"label": "Dos Torres", "value": "C108803"},
               {"label": "Dudar", "value": "C108804"},
               {"label": "Durcal", "value": "C108805"},
               {"label": "ecija", "value": "C108806"},
               {"label": "El Arahal", "value": "C108807"},
               {"label": "El Bosque", "value": "C108808"},
               {"label": "El Carpio", "value": "C108809"},
               {"label": "El Castillo de las Guardas", "value": "C108810"},
               {"label": "El Cerro de Andevalo", "value": "C108811"},
               {"label": "El Coronil", "value": "C108812"},
               {"label": "El Cuervo", "value": "C108813"},
               {"label": "El Ejido", "value": "C108814"},
               {"label": "El Gastor", "value": "C108815"},
               {"label": "El Puerto de Santa Maria", "value": "C108816"},
               {"label": "El Rompido", "value": "C108817"},
               {"label": "El Ronquillo", "value": "C108818"},
               {"label": "El Rubio", "value": "C108819"},
               {"label": "El Saucejo", "value": "C108820"},
               {"label": "El Varadero", "value": "C108821"},
               {"label": "El Viso", "value": "C108822"},
               {"label": "El Viso del Alcor", "value": "C108823"},
               {"label": "Encinas Reales", "value": "C108824"},
               {"label": "Encinasola", "value": "C108825"},
               {"label": "Enix", "value": "C108826"},
               {"label": "Escacena del Campo", "value": "C108827"},
               {"label": "Escanuela", "value": "C108828"},
               {"label": "Escuzar", "value": "C108829"},
               {"label": "Espartinas", "value": "C108830"},
               {"label": "Espejo", "value": "C108831"},
               {"label": "Espeluy", "value": "C108832"},
               {"label": "Espera", "value": "C108833"},
               {"label": "Espiel", "value": "C108834"},
               {"label": "Estacion de Cartama", "value": "C108835"},
               {"label": "Estepa", "value": "C108836"},
               {"label": "Estepona", "value": "C108837"},
               {"label": "Farajan", "value": "C108838"},
               {"label": "Fernan-Nunez", "value": "C108839"},
               {"label": "Ferreira", "value": "C108840"},
               {"label": "Finana", "value": "C108841"},
               {"label": "Fines", "value": "C108842"},
               {"label": "Fondon", "value": "C108843"},
               {"label": "Fonelas", "value": "C108844"},
               {"label": "Frailes", "value": "C108845"},
               {"label": "Freila", "value": "C108846"},
               {"label": "Frigiliana", "value": "C108847"},
               {"label": "Fuengirola", "value": "C108848"},
               {"label": "Fuensanta de Martos", "value": "C108849"},
               {"label": "Fuente de Piedra", "value": "C108850"},
               {"label": "Fuente la Lancha", "value": "C108851"},
               {"label": "Fuente Obejuna", "value": "C108852"},
               {"label": "Fuente Palmera", "value": "C108853"},
               {"label": "Fuente Vaqueros", "value": "C108854"},
               {"label": "Fuente-Tojar", "value": "C108855"},
               {"label": "Fuenteheridos", "value": "C108856"},
               {"label": "Fuentes de Andalucia", "value": "C108857"},
               {"label": "Fuerte del Rey", "value": "C108858"},
               {"label": "Gador", "value": "C108859"},
               {"label": "Galaroza", "value": "C108860"},
               {"label": "Galera", "value": "C108861"},
               {"label": "Garrucha", "value": "C108862"},
               {"label": "Gaucin", "value": "C108863"},
               {"label": "Gelves", "value": "C108864"},
               {"label": "Genalguacil", "value": "C108865"},
               {"label": "Genave", "value": "C108866"},
               {"label": "Gerena", "value": "C108867"},
               {"label": "Gergal", "value": "C108868"},
               {"label": "Gibraleon", "value": "C108869"},
               {"label": "Gilena", "value": "C108870"},
               {"label": "Gines", "value": "C108871"},
               {"label": "Gobernador", "value": "C108872"},
               {"label": "Gojar", "value": "C108873"},
               {"label": "Gor", "value": "C108874"},
               {"label": "Granada", "value": "C108875"},
               {"label": "Grazalema", "value": "C108876"},
               {"label": "Guadahortuna", "value": "C108877"},
               {"label": "Guadalcanal", "value": "C108878"},
               {"label": "Guadalcazar", "value": "C108879"},
               {"label": "Guadix", "value": "C108880"},
               {"label": "Gualchos", "value": "C108881"},
               {"label": "Guaro", "value": "C108882"},
               {"label": "Guarroman", "value": "C108883"},
               {"label": "Guejar-Sierra", "value": "C108884"},
               {"label": "Guevejar", "value": "C108885"},
               {"label": "Guillena", "value": "C108886"},
               {"label": "Herrera", "value": "C108887"},
               {"label": "Higuera de Arjona", "value": "C108888"},
               {"label": "Higuera de Calatrava", "value": "C108889"},
               {"label": "Higuera de la Sierra", "value": "C108890"},
               {"label": "Hinojales", "value": "C108891"},
               {"label": "Hinojares", "value": "C108892"},
               {"label": "Hinojos", "value": "C108893"},
               {"label": "Hinojosa del Duque", "value": "C108894"},
               {"label": "Hornachuelos", "value": "C108895"},
               {"label": "Huecija", "value": "C108896"},
               {"label": "Huelago", "value": "C108897"},
               {"label": "Huelma", "value": "C108898"},
               {"label": "Huelva", "value": "C108899"},
               {"label": "Hueneja", "value": "C108900"},
               {"label": "Huercal de Almeria", "value": "C108901"},
               {"label": "Huercal-Overa", "value": "C108902"},
               {"label": "Huesa", "value": "C108903"},
               {"label": "Huescar", "value": "C108904"},
               {"label": "Huetor Santillan", "value": "C108905"},
               {"label": "Huetor Vega", "value": "C108906"},
               {"label": "Huetor-Tajar", "value": "C108907"},
               {"label": "Humilladero", "value": "C108908"},
               {"label": "Ibros", "value": "C108909"},
               {"label": "Igualeja", "value": "C108910"},
               {"label": "Illar", "value": "C108911"},
               {"label": "Illora", "value": "C108912"},
               {"label": "Instincion", "value": "C108913"},
               {"label": "Isla Cristina", "value": "C108914"},
               {"label": "Islantilla", "value": "C108915"},
               {"label": "Istan", "value": "C108916"},
               {"label": "itrabo", "value": "C108917"},
               {"label": "Iznajar", "value": "C108918"},
               {"label": "Iznalloz", "value": "C108919"},
               {"label": "Iznate", "value": "C108920"},
               {"label": "Iznatoraf", "value": "C108921"},
               {"label": "Jabalquinto", "value": "C108922"},
               {"label": "Jabugo", "value": "C108923"},
               {"label": "Jaen", "value": "C108924"},
               {"label": "Jamilena", "value": "C108925"},
               {"label": "Jayena", "value": "C108926"},
               {"label": "Jerez de la Frontera", "value": "C108927"},
               {"label": "Jerez del Marquesado", "value": "C108928"},
               {"label": "Jimena", "value": "C108929"},
               {"label": "Jimena de la Frontera", "value": "C108930"},
               {"label": "Jimera de Libar", "value": "C108931"},
               {"label": "Jodar", "value": "C108932"},
               {"label": "Jubrique", "value": "C108933"},
               {"label": "Jun", "value": "C108934"},
               {"label": "Juviles", "value": "C108935"},
               {"label": "Juzcar", "value": "C108936"},
               {"label": "La Algaba", "value": "C108937"},
               {"label": "La Antilla", "value": "C108938"},
               {"label": "La Campana", "value": "C108939"},
               {"label": "La Carlota", "value": "C108940"},
               {"label": "La Carolina", "value": "C108941"},
               {"label": "La Gangosa Vistasol", "value": "C108942"},
               {"label": "La Guardia de Jaen", "value": "C108943"},
               {"label": "La Herradura", "value": "C108944"},
               {"label": "La Iruela", "value": "C108945"},
               {"label": "La Lantejuela", "value": "C108946"},
               {"label": "La Linea de la Concepcion", "value": "C108947"},
               {"label": "La Luisiana", "value": "C108948"},
               {"label": "La Mojonera", "value": "C108949"},
               {"label": "La Palma del Condado", "value": "C108950"},
               {"label": "La Puebla de Cazalla", "value": "C108951"},
               {"label": "La Puebla de los Infantes", "value": "C108952"},
               {"label": "La Puebla del Rio", "value": "C108953"},
               {"label": "La Puerta de Segura", "value": "C108954"},
               {"label": "La Rambla", "value": "C108955"},
               {"label": "La Rinconada", "value": "C108956"},
               {"label": "La Roda de Andalucia", "value": "C108957"},
               {"label": "La Victoria", "value": "C108958"},
               {"label": "Lachar", "value": "C108959"},
               {"label": "Lanjaron", "value": "C108960"},
               {"label": "Lanteira", "value": "C108961"},
               {"label": "Laroya", "value": "C108962"},
               {"label": "Larva", "value": "C108963"},
               {"label": "Las Cabezas de San Juan", "value": "C108964"},
               {"label": "Las Gabias", "value": "C108965"},
               {"label": "Laujar de Andarax", "value": "C108966"},
               {"label": "Lebrija", "value": "C108967"},
               {"label": "Lentegi", "value": "C108968"},
               {"label": "Lepe", "value": "C108969"},
               {"label": "Linares", "value": "C108970"},
               {"label": "Linares de la Sierra", "value": "C108971"},
               {"label": "Lobras", "value": "C108972"},
               {"label": "Loja", "value": "C108973"},
               {"label": "Lopera", "value": "C108974"},
               {"label": "Lora de Estepa", "value": "C108975"},
               {"label": "Lora del Rio", "value": "C108976"},
               {"label": "Los Barrios", "value": "C108977"},
               {"label": "Los Corrales", "value": "C108978"},
               {"label": "Los Molares", "value": "C108979"},
               {"label": "Los Palacios y Villafranca", "value": "C108980"},
               {"label": "Los Villares", "value": "C108981"},
               {"label": "Lubrin", "value": "C108982"},
               {"label": "Lucainena de las Torres", "value": "C108983"},
               {"label": "Lucar", "value": "C108984"},
               {"label": "Lucena", "value": "C108985"},
               {"label": "Lucena del Puerto", "value": "C108986"},
               {"label": "Lugros", "value": "C108987"},
               {"label": "Lujar", "value": "C108988"},
               {"label": "Lupion", "value": "C108989"},
               {"label": "Luque", "value": "C108990"},
               {"label": "Macael", "value": "C108991"},
               {"label": "Mairena del Alcor", "value": "C108992"},
               {"label": "Mairena del Aljarafe", "value": "C108993"},
               {"label": "Malaga", "value": "C108994"},
               {"label": "Mancha Real", "value": "C108995"},
               {"label": "Manilva", "value": "C108996"},
               {"label": "Manzanilla", "value": "C108997"},
               {"label": "Maracena", "value": "C108998"},
               {"label": "Marbella", "value": "C108999"},
               {"label": "Marchal", "value": "C109000"},
               {"label": "Marchena", "value": "C109001"},
               {"label": "Maria", "value": "C109002"},
               {"label": "Marinaleda", "value": "C109003"},
               {"label": "Marmolejo", "value": "C109004"},
               {"label": "Martin de la Jara", "value": "C109005"},
               {"label": "Martos", "value": "C109006"},
               {"label": "Mazagon", "value": "C109007"},
               {"label": "Medina Sidonia", "value": "C109008"},
               {"label": "Mengibar", "value": "C109009"},
               {"label": "Mijas", "value": "C109010"},
               {"label": "Moclin", "value": "C109011"},
               {"label": "Moclinejo", "value": "C109012"},
               {"label": "Moguer", "value": "C109013"},
               {"label": "Mojacar", "value": "C109014"},
               {"label": "Mollina", "value": "C109015"},
               {"label": "Molvizar", "value": "C109016"},
               {"label": "Monachil", "value": "C109017"},
               {"label": "Monda", "value": "C109018"},
               {"label": "Montalban de Cordoba", "value": "C109019"},
               {"label": "Montefrio", "value": "C109020"},
               {"label": "Montejaque", "value": "C109021"},
               {"label": "Montejicar", "value": "C109022"},
               {"label": "Montellano", "value": "C109023"},
               {"label": "Montemayor", "value": "C109024"},
               {"label": "Montilla", "value": "C109025"},
               {"label": "Montillana", "value": "C109026"},
               {"label": "Montizon", "value": "C109027"},
               {"label": "Montoro", "value": "C109028"},
               {"label": "Monturque", "value": "C109029"},
               {"label": "Moraleda de Zafayona", "value": "C109030"},
               {"label": "Moriles", "value": "C109031"},
               {"label": "Moron de la Frontera", "value": "C109032"},
               {"label": "Motril", "value": "C109033"},
               {"label": "Murtas", "value": "C109034"},
               {"label": "Navas de San Juan", "value": "C109035"},
               {"label": "Nerja", "value": "C109036"},
               {"label": "Nerva", "value": "C109037"},
               {"label": "Niebla", "value": "C109038"},
               {"label": "Niguelas", "value": "C109039"},
               {"label": "Nijar", "value": "C109040"},
               {"label": "Nivar", "value": "C109041"},
               {"label": "Noalejo", "value": "C109042"},
               {"label": "Nueva-Carteya", "value": "C109043"},
               {"label": "Obejo", "value": "C109044"},
               {"label": "Ogijares", "value": "C109045"},
               {"label": "Ohanes", "value": "C109046"},
               {"label": "Ojen", "value": "C109047"},
               {"label": "Olivares", "value": "C109048"},
               {"label": "Olula de Castro", "value": "C109049"},
               {"label": "Olula del Rio", "value": "C109050"},
               {"label": "Olvera", "value": "C109051"},
               {"label": "Orce", "value": "C109052"},
               {"label": "Orcera", "value": "C109053"},
               {"label": "orgiva", "value": "C109054"},
               {"label": "Oria", "value": "C109055"},
               {"label": "Osuna", "value": "C109056"},
               {"label": "Otivar", "value": "C109057"},
               {"label": "Otura", "value": "C109058"},
               {"label": "Padul", "value": "C109059"},
               {"label": "Padules", "value": "C109060"},
               {"label": "Palenciana", "value": "C109061"},
               {"label": "Palma del Rio", "value": "C109062"},
               {"label": "Palomares del Rio", "value": "C109063"},
               {"label": "Palos de la Frontera", "value": "C109064"},
               {"label": "Pampaneira", "value": "C109065"},
               {"label": "Paradas", "value": "C109066"},
               {"label": "Parauta", "value": "C109067"},
               {"label": "Partaloa", "value": "C109068"},
               {"label": "Paterna de Rivera", "value": "C109069"},
               {"label": "Paterna del Campo", "value": "C109070"},
               {"label": "Paterna del Rio", "value": "C109071"},
               {"label": "Paymogo", "value": "C109072"},
               {"label": "Peal de Becerro", "value": "C109073"},
               {"label": "Pedrera", "value": "C109074"},
               {"label": "Pedro Abad", "value": "C109075"},
               {"label": "Pedro Martinez", "value": "C109076"},
               {"label": "Pedroche", "value": "C109077"},
               {"label": "Pegalajar", "value": "C109078"},
               {"label": "Peligros", "value": "C109079"},
               {"label": "Penaflor", "value": "C109080"},
               {"label": "Penarroya-Pueblonuevo", "value": "C109081"},
               {"label": "Periana", "value": "C109082"},
               {"label": "Pilas", "value": "C109083"},
               {"label": "Pinar", "value": "C109084"},
               {"label": "Pinos Genil", "value": "C109085"},
               {"label": "Pinos Puente", "value": "C109086"},
               {"label": "Pizarra", "value": "C109087"},
               {"label": "Policar", "value": "C109088"},
               {"label": "Polopos", "value": "C109089"},
               {"label": "Porcuna", "value": "C109090"},
               {"label": "Portugos", "value": "C109091"},
               {"label": "Posadas", "value": "C109092"},
               {"label": "Pozo Alcon", "value": "C109093"},
               {"label": "Pozoblanco", "value": "C109094"},
               {"label": "Prado del Rey", "value": "C109095"},
               {"label": "Priego de Cordoba", "value": "C109096"},
               {"label": "Province of Cordoba", "value": "C109097"},
               {"label": "Provincia de Cadiz", "value": "C109098"},
               {"label": "Provincia de Granada", "value": "C109099"},
               {"label": "Provincia de Huelva", "value": "C109100"},
               {"label": "Provincia de Jaen", "value": "C109101"},
               {"label": "Provincia de Malaga", "value": "C109102"},
               {"label": "Provincia de Sevilla", "value": "C109103"},
               {"label": "Pruna", "value": "C109104"},
               {"label": "Puebla de Don Fadrique", "value": "C109105"},
               {"label": "Puebla de Guzman", "value": "C109106"},
               {"label": "Puente de Genave", "value": "C109107"},
               {"label": "Puente-Genil", "value": "C109108"},
               {"label": "Puerto Real", "value": "C109109"},
               {"label": "Puerto Serrano", "value": "C109110"},
               {"label": "Pujerra", "value": "C109111"},
               {"label": "Pulianas", "value": "C109112"},
               {"label": "Pulpi", "value": "C109113"},
               {"label": "Punta Umbria", "value": "C109114"},
               {"label": "Purchena", "value": "C109115"},
               {"label": "Purullena", "value": "C109116"},
               {"label": "Quentar", "value": "C109117"},
               {"label": "Quesada", "value": "C109118"},
               {"label": "Ragol", "value": "C109119"},
               {"label": "Retamar", "value": "C109120"},
               {"label": "Rincon de la Victoria", "value": "C109121"},
               {"label": "Riogordo", "value": "C109122"},
               {"label": "Rioja", "value": "C109123"},
               {"label": "Ronda", "value": "C109124"},
               {"label": "Roquetas de Mar", "value": "C109125"},
               {"label": "Rosal de la Frontera", "value": "C109126"},
               {"label": "Rota", "value": "C109127"},
               {"label": "Rubite", "value": "C109128"},
               {"label": "Rus", "value": "C109129"},
               {"label": "Rute", "value": "C109130"},
               {"label": "Sabiote", "value": "C109131"},
               {"label": "Salar", "value": "C109132"},
               {"label": "Salares", "value": "C109133"},
               {"label": "Salobrena", "value": "C109134"},
               {"label": "Salteras", "value": "C109135"},
               {"label": "San Bartolome de la Torre", "value": "C109136"},
               {"label": "San Enrique de Guadiaro", "value": "C109137"},
               {"label": "San Fernando", "value": "C109138"},
               {"label": "San Jose", "value": "C109139"},
               {"label": "San Jose del Valle", "value": "C109140"},
               {"label": "San Juan de Aznalfarache", "value": "C109141"},
               {"label": "San Juan del Puerto", "value": "C109142"},
               {"label": "San Nicolas del Puerto", "value": "C109143"},
               {"label": "San Pedro de Alcantara", "value": "C109144"},
               {"label": "San Roque", "value": "C109145"},
               {"label": "San Sebastian de los Ballesteros",
                "value": "C109146"},
               {"label": "San Silvestre de Guzman", "value": "C109147"},
               {"label": "Sanlucar de Barrameda", "value": "C109148"},
               {"label": "Sanlucar de Guadiana", "value": "C109149"},
               {"label": "Sanlucar la Mayor", "value": "C109150"},
               {"label": "Santa Ana la Real", "value": "C109151"},
               {"label": "Santa Barbara de Casa", "value": "C109152"},
               {"label": "Santa Elena", "value": "C109153"},
               {"label": "Santa Eufemia", "value": "C109154"},
               {"label": "Santa Fe de Mondujar", "value": "C109155"},
               {"label": "Santa Olalla del Cala", "value": "C109156"},
               {"label": "Santaella", "value": "C109157"},
               {"label": "Santafe", "value": "C109158"},
               {"label": "Santiago de Calatrava", "value": "C109159"},
               {"label": "Santiponce", "value": "C109160"},
               {"label": "Santisteban del Puerto", "value": "C109161"},
               {"label": "Santo Tome", "value": "C109162"},
               {"label": "Sayalonga", "value": "C109163"},
               {"label": "Sedella", "value": "C109164"},
               {"label": "Segura de la Sierra", "value": "C109165"},
               {"label": "Seron", "value": "C109166"},
               {"label": "Setenil de las Bodegas", "value": "C109167"},
               {"label": "Sevilla", "value": "C109168"},
               {"label": "Sierra de Yeguas", "value": "C109169"},
               {"label": "Sierro", "value": "C109170"},
               {"label": "Siles", "value": "C109171"},
               {"label": "Somontin", "value": "C109172"},
               {"label": "Soportujar", "value": "C109173"},
               {"label": "Sorbas", "value": "C109174"},
               {"label": "Sorihuela del Guadalimar", "value": "C109175"},
               {"label": "Sorvilan", "value": "C109176"},
               {"label": "Sufli", "value": "C109177"},
               {"label": "Tabernas", "value": "C109178"},
               {"label": "Tahal", "value": "C109179"},
               {"label": "Tarifa", "value": "C109180"},
               {"label": "Teba", "value": "C109181"},
               {"label": "Terque", "value": "C109182"},
               {"label": "Tijola", "value": "C109183"},
               {"label": "Tocina", "value": "C109184"},
               {"label": "Tolox", "value": "C109185"},
               {"label": "Tomares", "value": "C109186"},
               {"label": "Torre Alhaquime", "value": "C109187"},
               {"label": "Torre del Campo", "value": "C109188"},
               {"label": "Torre del Mar", "value": "C109189"},
               {"label": "Torre-Cardela", "value": "C109190"},
               {"label": "Torreblascopedro", "value": "C109191"},
               {"label": "Torrecampo", "value": "C109192"},
               {"label": "Torredonjimeno", "value": "C109193"},
               {"label": "Torremolinos", "value": "C109194"},
               {"label": "Torrenueva", "value": "C109195"},
               {"label": "Torreperogil", "value": "C109196"},
               {"label": "Torres", "value": "C109197"},
               {"label": "Torrox", "value": "C109198"},
               {"label": "Totalan", "value": "C109199"},
               {"label": "Trebujena", "value": "C109200"},
               {"label": "Trevelez", "value": "C109201"},
               {"label": "Trigueros", "value": "C109202"},
               {"label": "Turre", "value": "C109203"},
               {"label": "Turrillas", "value": "C109204"},
               {"label": "Ubeda", "value": "C109205"},
               {"label": "Ubrique", "value": "C109206"},
               {"label": "Ugijar", "value": "C109207"},
               {"label": "Uleila del Campo", "value": "C109208"},
               {"label": "Umbrete", "value": "C109209"},
               {"label": "Urracal", "value": "C109210"},
               {"label": "Utrera", "value": "C109211"},
               {"label": "Valdelarco", "value": "C109212"},
               {"label": "Valdepenas de Jaen", "value": "C109213"},
               {"label": "Valderrubio", "value": "C109214"},
               {"label": "Valdes", "value": "C109215"},
               {"label": "Valdezorras", "value": "C109216"},
               {"label": "Valencina de la Concepcion", "value": "C109217"},
               {"label": "Valenzuela", "value": "C109218"},
               {"label": "Valor", "value": "C109219"},
               {"label": "Valverde del Camino", "value": "C109220"},
               {"label": "Vejer de la Frontera", "value": "C109221"},
               {"label": "Velefique", "value": "C109222"},
               {"label": "Velez de Benaudalla", "value": "C109223"},
               {"label": "Velez Rubio", "value": "C109224"},
               {"label": "Velez-Blanco", "value": "C109225"},
               {"label": "Velez-Malaga", "value": "C109226"},
               {"label": "Ventas de Huelma", "value": "C109227"},
               {"label": "Vera", "value": "C109228"},
               {"label": "Viator", "value": "C109229"},
               {"label": "Vicar", "value": "C109230"},
               {"label": "Vilches", "value": "C109231"},
               {"label": "Villa del Rio", "value": "C109232"},
               {"label": "Villablanca", "value": "C109233"},
               {"label": "Villacarrillo", "value": "C109234"},
               {"label": "Villafranca de Cordoba", "value": "C109235"},
               {"label": "Villaharta", "value": "C109236"},
               {"label": "Villalba del Alcor", "value": "C109237"},
               {"label": "Villaluenga del Rosario", "value": "C109238"},
               {"label": "Villamanrique de la Condesa", "value": "C109239"},
               {"label": "Villamartin", "value": "C109240"},
               {"label": "Villanueva de Algaidas", "value": "C109241"},
               {"label": "Villanueva de Cordoba", "value": "C109242"},
               {"label": "Villanueva de la Reina", "value": "C109243"},
               {"label": "Villanueva de las Cruces", "value": "C109244"},
               {"label": "Villanueva de las Torres", "value": "C109245"},
               {"label": "Villanueva de los Castillejos", "value": "C109246"},
               {"label": "Villanueva de San Juan", "value": "C109247"},
               {"label": "Villanueva de Tapia", "value": "C109248"},
               {"label": "Villanueva del Ariscal", "value": "C109249"},
               {"label": "Villanueva del Arzobispo", "value": "C109250"},
               {"label": "Villanueva del Duque", "value": "C109251"},
               {"label": "Villanueva del Rio y Minas", "value": "C109252"},
               {"label": "Villanueva del Rosario", "value": "C109253"},
               {"label": "Villanueva del Trabuco", "value": "C109254"},
               {"label": "Villaralto", "value": "C109255"},
               {"label": "Villardompardo", "value": "C109256"},
               {"label": "Villarrasa", "value": "C109257"},
               {"label": "Villarrubia", "value": "C109258"},
               {"label": "Villaverde del Rio", "value": "C109259"},
               {"label": "Villaviciosa de Cordoba", "value": "C109260"},
               {"label": "Vinuela", "value": "C109261"},
               {"label": "Viznar", "value": "C109262"},
               {"label": "Yunquera", "value": "C109263"},
               {"label": "Zafarraya", "value": "C109264"},
               {"label": "Zagra", "value": "C109265"},
               {"label": "Zahara", "value": "C109266"},
               {"label": "Zahara de los Atunes", "value": "C109267"},
               {"label": "Zalamea la Real", "value": "C109268"},
               {"label": "Zubia", "value": "C109269"},
               {"label": "Zufre", "value": "C109270"},
               {"label": "Zuheros", "value": "C109271"},
               {"label": "Zujar", "value": "C109272"},
               {"label": "Zurgena", "value": "C109273"}],
 "Andaman and Nicobar Islands": [{"label": "Bamboo Flat", "value": "C44896"},
                                 {"label": "Nicobar", "value": "C44897"},
                                 {"label": "Port Blair", "value": "C44898"},
                                 {"label": "South Andaman", "value": "C44899"}],
 "Andhra Pradesh": [{"label": "Addanki", "value": "C44900"},
                    {"label": "Adoni", "value": "C44901"},
                    {"label": "Akasahebpet", "value": "C44902"},
                    {"label": "Akividu", "value": "C44903"},
                    {"label": "Akkarampalle", "value": "C44904"},
                    {"label": "Amalapuram", "value": "C44905"},
                    {"label": "Amudalavalasa", "value": "C44906"},
                    {"label": "Anakapalle", "value": "C44907"},
                    {"label": "Anantapur", "value": "C44908"},
                    {"label": "Atmakur", "value": "C44909"},
                    {"label": "Attili", "value": "C44910"},
                    {"label": "Avanigadda", "value": "C44911"},
                    {"label": "Badvel", "value": "C44912"},
                    {"label": "Banganapalle", "value": "C44913"},
                    {"label": "Bapatla", "value": "C44914"},
                    {"label": "Betamcherla", "value": "C44915"},
                    {"label": "Bhattiprolu", "value": "C44916"},
                    {"label": "Bhimavaram", "value": "C44917"},
                    {"label": "Bhimunipatnam", "value": "C44918"},
                    {"label": "Bobbili", "value": "C44919"},
                    {"label": "Challapalle", "value": "C44920"},
                    {"label": "Chemmumiahpet", "value": "C44921"},
                    {"label": "Chilakalurupet", "value": "C44922"},
                    {"label": "Chinnachowk", "value": "C44923"},
                    {"label": "Chipurupalle", "value": "C44924"},
                    {"label": "Chirala", "value": "C44925"},
                    {"label": "Chittoor", "value": "C44926"},
                    {"label": "Chodavaram", "value": "C44927"},
                    {"label": "Cuddapah", "value": "C44928"},
                    {"label": "Cumbum", "value": "C44929"},
                    {"label": "Darsi", "value": "C44930"},
                    {"label": "Dharmavaram", "value": "C44931"},
                    {"label": "Dhone", "value": "C44932"},
                    {"label": "Diguvametta", "value": "C44933"},
                    {"label": "East Godavari", "value": "C44934"},
                    {"label": "Elamanchili", "value": "C44935"},
                    {"label": "Ellore", "value": "C44936"},
                    {"label": "Emmiganur", "value": "C44937"},
                    {"label": "Erraguntla", "value": "C44938"},
                    {"label": "Etikoppaka", "value": "C44939"},
                    {"label": "Gajuwaka", "value": "C44940"},
                    {"label": "Ganguvada", "value": "C44941"},
                    {"label": "Gannavaram", "value": "C44942"},
                    {"label": "Giddalur", "value": "C44943"},
                    {"label": "Gokavaram", "value": "C44944"},
                    {"label": "Gorantla", "value": "C44945"},
                    {"label": "Govindapuram,Chilakaluripet,Guntur",
                     "value": "C44946"},
                    {"label": "Gudivada", "value": "C44947"},
                    {"label": "Gudlavalleru", "value": "C44948"},
                    {"label": "Gudur", "value": "C44949"},
                    {"label": "Guntakal Junction", "value": "C44950"},
                    {"label": "Guntur", "value": "C44951"},
                    {"label": "Hindupur", "value": "C44952"},
                    {"label": "Ichchapuram", "value": "C44953"},
                    {"label": "Jaggayyapeta", "value": "C44954"},
                    {"label": "Jammalamadugu", "value": "C44955"},
                    {"label": "Kadiri", "value": "C44956"},
                    {"label": "Kaikalur", "value": "C44957"},
                    {"label": "Kakinada", "value": "C44958"},
                    {"label": "Kalyandurg", "value": "C44959"},
                    {"label": "Kamalapuram", "value": "C44960"},
                    {"label": "Kandukur", "value": "C44961"},
                    {"label": "Kanigiri", "value": "C44962"},
                    {"label": "Kankipadu", "value": "C44963"},
                    {"label": "Kanuru", "value": "C44964"},
                    {"label": "Kavali", "value": "C44965"},
                    {"label": "Kolanukonda", "value": "C44966"},
                    {"label": "Kondapalle", "value": "C44967"},
                    {"label": "Korukollu", "value": "C44968"},
                    {"label": "Kosigi", "value": "C44969"},
                    {"label": "Kovvur", "value": "C44970"},
                    {"label": "Krishna", "value": "C44971"},
                    {"label": "Kuppam", "value": "C44972"},
                    {"label": "Kurnool", "value": "C44973"},
                    {"label": "Macherla", "value": "C44974"},
                    {"label": "Machilipatnam", "value": "C44975"},
                    {"label": "Madanapalle", "value": "C44976"},
                    {"label": "Madugula", "value": "C44977"},
                    {"label": "Mandapeta", "value": "C44978"},
                    {"label": "Mandasa", "value": "C44979"},
                    {"label": "Mangalagiri", "value": "C44980"},
                    {"label": "Markapur", "value": "C44981"},
                    {"label": "Nagari", "value": "C44982"},
                    {"label": "Nagireddipalli", "value": "C44983"},
                    {"label": "Nandigama", "value": "C44984"},
                    {"label": "Nandikotkur", "value": "C44985"},
                    {"label": "Nandyal", "value": "C44986"},
                    {"label": "Narasannapeta", "value": "C44987"},
                    {"label": "Narasapur", "value": "C44988"},
                    {"label": "Narasaraopet", "value": "C44989"},
                    {"label": "Narasingapuram", "value": "C44990"},
                    {"label": "Narayanavanam", "value": "C44991"},
                    {"label": "Narsipatnam", "value": "C44992"},
                    {"label": "Nayudupet", "value": "C44993"},
                    {"label": "Nellore", "value": "C44994"},
                    {"label": "Nidadavole", "value": "C44995"},
                    {"label": "Nuzvid", "value": "C44996"},
                    {"label": "Ongole", "value": "C44997"},
                    {"label": "Pakala", "value": "C44998"},
                    {"label": "Palakollu", "value": "C44999"},
                    {"label": "Palasa", "value": "C45000"},
                    {"label": "Palkonda", "value": "C45001"},
                    {"label": "Pallevada", "value": "C45002"},
                    {"label": "Palmaner", "value": "C45003"},
                    {"label": "Parlakimidi", "value": "C45004"},
                    {"label": "Parvatipuram", "value": "C45005"},
                    {"label": "Pavuluru", "value": "C45006"},
                    {"label": "Pedana", "value": "C45007"},
                    {"label": "pedda nakkalapalem", "value": "C45008"},
                    {"label": "Peddapuram", "value": "C45009"},
                    {"label": "Penugonda", "value": "C45010"},
                    {"label": "Penukonda", "value": "C45011"},
                    {"label": "Phirangipuram", "value": "C45012"},
                    {"label": "Pippara", "value": "C45013"},
                    {"label": "Pithapuram", "value": "C45014"},
                    {"label": "Polavaram", "value": "C45015"},
                    {"label": "Ponnur", "value": "C45016"},
                    {"label": "Ponnuru", "value": "C45017"},
                    {"label": "Prakasam", "value": "C45018"},
                    {"label": "Proddatur", "value": "C45019"},
                    {"label": "Pulivendla", "value": "C45020"},
                    {"label": "Punganuru", "value": "C45021"},
                    {"label": "Puttaparthi", "value": "C45022"},
                    {"label": "Puttur", "value": "C45023"},
                    {"label": "Rajahmundry", "value": "C45024"},
                    {"label": "Ramachandrapuram", "value": "C45025"},
                    {"label": "Ramanayyapeta", "value": "C45026"},
                    {"label": "Ramapuram", "value": "C45027"},
                    {"label": "Rampachodavaram", "value": "C45028"},
                    {"label": "Rayachoti", "value": "C45029"},
                    {"label": "Rayadrug", "value": "C45030"},
                    {"label": "Razam", "value": "C45031"},
                    {"label": "Razampeta", "value": "C45032"},
                    {"label": "Razole", "value": "C45033"},
                    {"label": "Renigunta", "value": "C45034"},
                    {"label": "Repalle", "value": "C45035"},
                    {"label": "Salur", "value": "C45036"},
                    {"label": "Samalkot", "value": "C45037"},
                    {"label": "Sattenapalle", "value": "C45038"},
                    {"label": "Singarayakonda", "value": "C45039"},
                    {"label": "Sompeta", "value": "C45040"},
                    {"label": "Srikakulam", "value": "C45041"},
                    {"label": "Srisailain", "value": "C45042"},
                    {"label": "Suluru", "value": "C45043"},
                    {"label": "Tadepalle", "value": "C45044"},
                    {"label": "Tadepallegudem", "value": "C45045"},
                    {"label": "Tadpatri", "value": "C45046"},
                    {"label": "Tanuku", "value": "C45047"},
                    {"label": "Tekkali", "value": "C45048"},
                    {"label": "Tirumala", "value": "C45049"},
                    {"label": "Tirupati", "value": "C45050"},
                    {"label": "Tuni", "value": "C45051"},
                    {"label": "Uravakonda", "value": "C45052"},
                    {"label": "vadlamuru", "value": "C45053"},
                    {"label": "Vadlapudi", "value": "C45054"},
                    {"label": "Venkatagiri", "value": "C45055"},
                    {"label": "Vepagunta", "value": "C45056"},
                    {"label": "Vetapalem", "value": "C45057"},
                    {"label": "Vijayawada", "value": "C45058"},
                    {"label": "Vinukonda", "value": "C45059"},
                    {"label": "Visakhapatnam", "value": "C45060"},
                    {"label": "Vizianagaram", "value": "C45061"},
                    {"label": "Vizianagaram District", "value": "C45062"},
                    {"label": "Vuyyuru", "value": "C45063"},
                    {"label": "West Godavari", "value": "C45064"},
                    {"label": "Yanam", "value": "C45065"},
                    {"label": "Yanamalakuduru", "value": "C45066"},
                    {"label": "Yarada", "value": "C45067"}],
 "Andijan Region": [{"label": "Andijon", "value": "C146981"},
                    {"label": "Andijon Tumani", "value": "C146982"},
                    {"label": "Asaka", "value": "C146983"},
                    {"label": "Asaka Tumani", "value": "C146984"},
                    {"label": "Baliqchi Tumani", "value": "C146985"},
                    {"label": "Boz Tumani", "value": "C146986"},
                    {"label": "Buloqboshi Tumani", "value": "C146987"},
                    {"label": "Izboskan Tumani", "value": "C146988"},
                    {"label": "Jalolkuduk Tumani", "value": "C146989"},
                    {"label": "Khujaobod Tumani", "value": "C146990"},
                    {"label": "Marhamat", "value": "C146991"},
                    {"label": "Marhamat Tumani", "value": "C146992"},
                    {"label": "Oltinkul Tumani", "value": "C146993"},
                    {"label": "Pakhtaobod Tumani", "value": "C146994"},
                    {"label": "Paxtaobod", "value": "C146995"},
                    {"label": "Qorgontepa", "value": "C146996"},
                    {"label": "Qurghontepa Tumani", "value": "C146997"},
                    {"label": "Shahrikhon Tumani", "value": "C146998"},
                    {"label": "Shahrixon", "value": "C146999"},
                    {"label": "Ulugnor Tumani", "value": "C147000"},
                    {"label": "Xojaobod", "value": "C147001"}],
 "Andorra la Vella": [{"label": "Andorra la Vella", "value": "C516"}],
 "Andrijevica Municipality": [{"label": "Andrijevica", "value": "C75594"}],
 "Anenii Noi District": [{"label": "Anenii Noi", "value": "C75484"},
                         {"label": "Varnita", "value": "C75485"}],
 "Ang Thong": [{"label": "Amphoe Chaiyo", "value": "C118372"},
               {"label": "Amphoe Mueang Ang Thong", "value": "C118373"},
               {"label": "Amphoe Pa Mok", "value": "C118374"},
               {"label": "Amphoe Pho Thong", "value": "C118375"},
               {"label": "Amphoe Sawaeng Ha", "value": "C118376"},
               {"label": "Amphoe Wiset Chai Chan", "value": "C118377"},
               {"label": "Ang Thong", "value": "C118378"},
               {"label": "Ban Thai Tan", "value": "C118379"},
               {"label": "Chaiyo", "value": "C118380"},
               {"label": "Pho Thong", "value": "C118381"},
               {"label": "Wiset Chaichan", "value": "C118382"}],
 "Angaur": [{"label": "Angaur State", "value": "C79772"}],
 "Anhui": [{"label": "Anqing", "value": "C18238"},
           {"label": "Anqing Shi", "value": "C18239"},
           {"label": "Bengbu", "value": "C18240"},
           {"label": "Bozhou", "value": "C18241"},
           {"label": "Chaohu", "value": "C18242"},
           {"label": "Chizhou", "value": "C18243"},
           {"label": "Chizhou Shi", "value": "C18244"},
           {"label": "Chuzhou", "value": "C18245"},
           {"label": "Chuzhou Shi", "value": "C18246"},
           {"label": "Datong", "value": "C18247"},
           {"label": "Fuyang", "value": "C18248"},
           {"label": "Fuyang Shi", "value": "C18249"},
           {"label": "Gushu", "value": "C18250"},
           {"label": "Hefei", "value": "C18251"},
           {"label": "Hefei Shi", "value": "C18252"},
           {"label": "Huaibei", "value": "C18253"},
           {"label": "Huainan", "value": "C18254"},
           {"label": "Huainan Shi", "value": "C18255"},
           {"label": "Huaiyuan Chengguanzhen", "value": "C18256"},
           {"label": "Huangshan", "value": "C18257"},
           {"label": "Huangshan Shi", "value": "C18258"},
           {"label": "Huoqiu Chengguanzhen", "value": "C18259"},
           {"label": "Jieshou", "value": "C18260"},
           {"label": "Luan", "value": "C18261"},
           {"label": "Lucheng", "value": "C18262"},
           {"label": "Mengcheng Chengguanzhen", "value": "C18263"},
           {"label": "Mingguang", "value": "C18264"},
           {"label": "Suixi", "value": "C18265"},
           {"label": "Suzhou", "value": "C18266"},
           {"label": "Suzhou Shi", "value": "C18267"},
           {"label": "Tangzhai", "value": "C18268"},
           {"label": "Wucheng", "value": "C18269"},
           {"label": "Wuhu", "value": "C18270"},
           {"label": "Wusong", "value": "C18271"},
           {"label": "Wuyang", "value": "C18272"},
           {"label": "Xuanzhou", "value": "C18273"},
           {"label": "Yingshang Chengguanzhen", "value": "C18274"}],
 "Anjouan": [{"label": "Adda-Doueni", "value": "C20658"},
             {"label": "Antsahe", "value": "C20659"},
             {"label": "Assimpao", "value": "C20660"},
             {"label": "Bambao", "value": "C20661"},
             {"label": "Bandajou", "value": "C20662"},
             {"label": "Barakani", "value": "C20663"},
             {"label": "Bimbini", "value": "C20664"},
             {"label": "Boungoueni", "value": "C20665"},
             {"label": "Chandra", "value": "C20666"},
             {"label": "Chironkamba", "value": "C20667"},
             {"label": "Chitrouni", "value": "C20668"},
             {"label": "Daji", "value": "C20669"},
             {"label": "Domoni", "value": "C20670"},
             {"label": "Dziani", "value": "C20671"},
             {"label": "Hajoho", "value": "C20672"},
             {"label": "Harembo", "value": "C20673"},
             {"label": "Kangani", "value": "C20674"},
             {"label": "Kavani", "value": "C20675"},
             {"label": "Koki", "value": "C20676"},
             {"label": "Koni-Djodjo", "value": "C20677"},
             {"label": "Koni-Ngani", "value": "C20678"},
             {"label": "Kyo", "value": "C20679"},
             {"label": "Limbi", "value": "C20680"},
             {"label": "Lingoni", "value": "C20681"},
             {"label": "Magnassini-Nindri", "value": "C20682"},
             {"label": "Marahare", "value": "C20683"},
             {"label": "Mirontsi", "value": "C20684"},
             {"label": "Mjamaoue", "value": "C20685"},
             {"label": "Mjimandra", "value": "C20686"},
             {"label": "Moutsamoudou", "value": "C20687"},
             {"label": "Moya", "value": "C20688"},
             {"label": "Mramani", "value": "C20689"},
             {"label": "Mremani", "value": "C20690"},
             {"label": "Ongoni", "value": "C20691"},
             {"label": "Ouani", "value": "C20692"},
             {"label": "Ouzini", "value": "C20693"},
             {"label": "Paje", "value": "C20694"},
             {"label": "Patsi", "value": "C20695"},
             {"label": "Sima", "value": "C20696"},
             {"label": "Tsimbeo", "value": "C20697"},
             {"label": "Vouani", "value": "C20698"}],
 "Ankara": [{"label": "Akyurt", "value": "C119888"},
            {"label": "Altindag", "value": "C119889"},
            {"label": "Altpinar", "value": "C119890"},
            {"label": "Ankara", "value": "C119891"},
            {"label": "Ayas", "value": "C119892"},
            {"label": "Bala Ilcesi", "value": "C119893"},
            {"label": "Batikent", "value": "C119894"},
            {"label": "Beypazari", "value": "C119895"},
            {"label": "Beypazari", "value": "C119896"},
            {"label": "Camlidere", "value": "C119897"},
            {"label": "Cankaya", "value": "C119898"},
            {"label": "Cubuk", "value": "C119899"},
            {"label": "Elmadag", "value": "C119900"},
            {"label": "Etimesgut Ilcesi", "value": "C119901"},
            {"label": "Evren", "value": "C119902"},
            {"label": "Golbasi", "value": "C119903"},
            {"label": "Gudul", "value": "C119904"},
            {"label": "Haymana", "value": "C119905"},
            {"label": "Kahramankazan", "value": "C119906"},
            {"label": "Kalecik", "value": "C119907"},
            {"label": "Kazan", "value": "C119908"},
            {"label": "Kecioren", "value": "C119909"},
            {"label": "Kizilcahamam", "value": "C119910"},
            {"label": "Mamak Ilcesi", "value": "C119911"},
            {"label": "Nallihan", "value": "C119912"},
            {"label": "Polatli", "value": "C119913"},
            {"label": "Pursaklar", "value": "C119914"},
            {"label": "Sereflikochisar", "value": "C119915"},
            {"label": "Sincan Ilcesi", "value": "C119916"},
            {"label": "Yenimahalle", "value": "C119917"}],
 "Ankaran Municipality": [{"label": "Ankaran", "value": "C107565"}],
 "Annaba": [{"label": "Annaba", "value": "C244"},
            {"label": "Berrahal", "value": "C245"},
            {"label": "Drean", "value": "C246"},
            {"label": "El Hadjar", "value": "C247"}],
 "Annobon Province": [{"label": "San Antonio de Pale", "value": "C24464"}],
 "Anse Boileau": [{"label": "Anse Boileau", "value": "C107229"}],
 "Anse Royale": [{"label": "Anse Royale", "value": "C107230"}],
 "Anse la Raye Quarter": [{"label": "Anse La Raye", "value": "C105735"},
                          {"label": "Au Tabor", "value": "C105736"},
                          {"label": "Au Tabor Hill", "value": "C105737"},
                          {"label": "Bois DInde", "value": "C105738"},
                          {"label": "CaicoMillet", "value": "C105739"},
                          {"label": "Canaries", "value": "C105740"},
                          {"label": "Champen Estate", "value": "C105741"},
                          {"label": "Derriere Lagoon", "value": "C105742"},
                          {"label": "Durandeau", "value": "C105743"},
                          {"label": "Enbar Pwin", "value": "C105744"},
                          {"label": "Jacmel", "value": "C105745"},
                          {"label": "Jean Baptiste", "value": "C105746"},
                          {"label": "La Trielle", "value": "C105747"},
                          {"label": "Massacre", "value": "C105748"},
                          {"label": "Millet", "value": "C105749"},
                          {"label": "Morne Ciseaux", "value": "C105750"},
                          {"label": "Morne DOr", "value": "C105751"},
                          {"label": "Roseau Valley", "value": "C105752"},
                          {"label": "St Lawrence", "value": "C105753"},
                          {"label": "St Lawrence Estate", "value": "C105754"},
                          {"label": "Tete Chemin", "value": "C105755"},
                          {"label": "Vanard", "value": "C105756"},
                          {"label": "Venus", "value": "C105757"},
                          {"label": "Village", "value": "C105758"},
                          {"label": "VillagePetite Bourgh",
                           "value": "C105759"}],
 "Anseba Region": [{"label": "Keren", "value": "C24488"}],
 "Antalya": [{"label": "Akseki", "value": "C119918"},
             {"label": "Aksu", "value": "C119919"},
             {"label": "Alanya", "value": "C119920"},
             {"label": "Antalya", "value": "C119921"},
             {"label": "Avsallar", "value": "C119922"},
             {"label": "Belek", "value": "C119923"},
             {"label": "Beykonak", "value": "C119924"},
             {"label": "Bogazkent", "value": "C119925"},
             {"label": "Demre", "value": "C119926"},
             {"label": "Dosemealti Ilcesi", "value": "C119927"},
             {"label": "Eksere", "value": "C119928"},
             {"label": "Elmali", "value": "C119929"},
             {"label": "Evrenseki", "value": "C119930"},
             {"label": "Finike", "value": "C119931"},
             {"label": "Gazipasa", "value": "C119932"},
             {"label": "Goynuk", "value": "C119933"},
             {"label": "Gundogmus Ilcesi", "value": "C119934"},
             {"label": "Ibradi", "value": "C119935"},
             {"label": "Kalkan", "value": "C119936"},
             {"label": "Kas", "value": "C119937"},
             {"label": "Kemer", "value": "C119938"},
             {"label": "Kepez Ilcesi", "value": "C119939"},
             {"label": "Kizilagac", "value": "C119940"},
             {"label": "Konyaalti", "value": "C119941"},
             {"label": "Korkuteli", "value": "C119942"},
             {"label": "Kumkoy", "value": "C119943"},
             {"label": "Kumluca", "value": "C119944"},
             {"label": "Mahmutlar", "value": "C119945"},
             {"label": "Manavgat", "value": "C119946"},
             {"label": "Muratpasa", "value": "C119947"},
             {"label": "Okurcalar", "value": "C119948"},
             {"label": "Payallar", "value": "C119949"},
             {"label": "Serik", "value": "C119950"},
             {"label": "Side", "value": "C119951"},
             {"label": "Tekirova", "value": "C119952"},
             {"label": "Turkler", "value": "C119953"},
             {"label": "Turuncova", "value": "C119954"}],
 "Antioquia": [{"label": "Abejorral", "value": "C19546"},
               {"label": "Abriaqui", "value": "C19547"},
               {"label": "Alejandria", "value": "C19548"},
               {"label": "Amaga", "value": "C19549"},
               {"label": "Amalfi", "value": "C19550"},
               {"label": "Andes", "value": "C19551"},
               {"label": "Angelopolis", "value": "C19552"},
               {"label": "Angostura", "value": "C19553"},
               {"label": "Anori", "value": "C19554"},
               {"label": "Anza", "value": "C19555"},
               {"label": "Apartado", "value": "C19556"},
               {"label": "Arboletes", "value": "C19557"},
               {"label": "Argelia", "value": "C19558"},
               {"label": "Armenia", "value": "C19559"},
               {"label": "Barbosa", "value": "C19560"},
               {"label": "Bello", "value": "C19561"},
               {"label": "Belmira", "value": "C19562"},
               {"label": "Betania", "value": "C19563"},
               {"label": "Betulia", "value": "C19564"},
               {"label": "Briceno", "value": "C19565"},
               {"label": "Buritica", "value": "C19566"},
               {"label": "Caceres", "value": "C19567"},
               {"label": "Caicedo", "value": "C19568"},
               {"label": "Caldas", "value": "C19569"},
               {"label": "Campamento", "value": "C19570"},
               {"label": "Canasgordas", "value": "C19571"},
               {"label": "Caracoli", "value": "C19572"},
               {"label": "Caramanta", "value": "C19573"},
               {"label": "Carepa", "value": "C19574"},
               {"label": "Carolina", "value": "C19575"},
               {"label": "Caucasia", "value": "C19576"},
               {"label": "Chigorodo", "value": "C19577"},
               {"label": "Cisneros", "value": "C19578"},
               {"label": "Ciudad Bolivar", "value": "C19579"},
               {"label": "Cocorna", "value": "C19580"},
               {"label": "Concepcion", "value": "C19581"},
               {"label": "Concordia", "value": "C19582"},
               {"label": "Copacabana", "value": "C19583"},
               {"label": "Dabeiba", "value": "C19584"},
               {"label": "Donmatias", "value": "C19585"},
               {"label": "Ebejico", "value": "C19586"},
               {"label": "El Bagre", "value": "C19587"},
               {"label": "El Carmen de Viboral", "value": "C19588"},
               {"label": "El Santuario", "value": "C19589"},
               {"label": "Entrerrios", "value": "C19590"},
               {"label": "Envigado", "value": "C19591"},
               {"label": "Fredonia", "value": "C19592"},
               {"label": "Frontino", "value": "C19593"},
               {"label": "Giraldo", "value": "C19594"},
               {"label": "Girardota", "value": "C19595"},
               {"label": "Gomez Plata", "value": "C19596"},
               {"label": "Granada", "value": "C19597"},
               {"label": "Guadalupe", "value": "C19598"},
               {"label": "Guarne", "value": "C19599"},
               {"label": "Guatape", "value": "C19600"},
               {"label": "Heliconia", "value": "C19601"},
               {"label": "Hispania", "value": "C19602"},
               {"label": "Itagui", "value": "C19603"},
               {"label": "Ituango", "value": "C19604"},
               {"label": "Jardin", "value": "C19605"},
               {"label": "Jerico", "value": "C19606"},
               {"label": "La Ceja", "value": "C19607"},
               {"label": "La Estrella", "value": "C19608"},
               {"label": "La Pintada", "value": "C19609"},
               {"label": "La Union", "value": "C19610"},
               {"label": "Liborina", "value": "C19611"},
               {"label": "Maceo", "value": "C19612"},
               {"label": "Marinilla", "value": "C19613"},
               {"label": "Medellin", "value": "C19614"},
               {"label": "Montebello", "value": "C19615"},
               {"label": "Murindo", "value": "C19616"},
               {"label": "Mutata", "value": "C19617"},
               {"label": "Narino", "value": "C19618"},
               {"label": "Nechi", "value": "C19619"},
               {"label": "Necocli", "value": "C19620"},
               {"label": "Olaya", "value": "C19621"},
               {"label": "Penol", "value": "C19622"},
               {"label": "Peque", "value": "C19623"},
               {"label": "Pueblorrico", "value": "C19624"},
               {"label": "Puerto Berrio", "value": "C19625"},
               {"label": "Puerto Nare", "value": "C19626"},
               {"label": "Puerto Triunfo", "value": "C19627"},
               {"label": "Remedios", "value": "C19628"},
               {"label": "Retiro", "value": "C19629"},
               {"label": "Rionegro", "value": "C19630"},
               {"label": "Sabanalarga", "value": "C19631"},
               {"label": "Sabaneta", "value": "C19632"},
               {"label": "Salgar", "value": "C19633"},
               {"label": "San Andres de Cuerquia", "value": "C19634"},
               {"label": "San Carlos", "value": "C19635"},
               {"label": "San Francisco", "value": "C19636"},
               {"label": "San Jeronimo", "value": "C19637"},
               {"label": "San Jose de la Montana", "value": "C19638"},
               {"label": "San Juan de Uraba", "value": "C19639"},
               {"label": "San Luis", "value": "C19640"},
               {"label": "San Pedro de los Milagros", "value": "C19641"},
               {"label": "San Pedro de Uraba", "value": "C19642"},
               {"label": "San Rafael", "value": "C19643"},
               {"label": "San Roque", "value": "C19644"},
               {"label": "San Vicente Ferrer", "value": "C19645"},
               {"label": "Santa Barbara", "value": "C19646"},
               {"label": "Santa Fe de Antioquia", "value": "C19647"},
               {"label": "Santa Rosa de Osos", "value": "C19648"},
               {"label": "Santo Domingo", "value": "C19649"},
               {"label": "Segovia", "value": "C19650"},
               {"label": "Sonson", "value": "C19651"},
               {"label": "Sopetran", "value": "C19652"},
               {"label": "Tamesis", "value": "C19653"},
               {"label": "Taraza", "value": "C19654"},
               {"label": "Tarso", "value": "C19655"},
               {"label": "Titiribi", "value": "C19656"},
               {"label": "Toledo", "value": "C19657"},
               {"label": "Turbo", "value": "C19658"},
               {"label": "Uramita", "value": "C19659"},
               {"label": "Urrao", "value": "C19660"},
               {"label": "Valdivia", "value": "C19661"},
               {"label": "Valparaiso", "value": "C19662"},
               {"label": "Vegachi", "value": "C19663"},
               {"label": "Venecia", "value": "C19664"},
               {"label": "Vigia del Fuerte", "value": "C19665"},
               {"label": "Yali", "value": "C19666"},
               {"label": "Yarumal", "value": "C19667"},
               {"label": "Yolombo", "value": "C19668"},
               {"label": "Yondo", "value": "C19669"},
               {"label": "Zaragoza", "value": "C19670"}],
 "Antique": [{"label": "Abaca", "value": "C82411"},
             {"label": "Abangay", "value": "C82412"},
             {"label": "Abiera", "value": "C82413"},
             {"label": "Abilay", "value": "C82414"},
             {"label": "Ag-ambulong", "value": "C82415"},
             {"label": "Aganan", "value": "C82416"},
             {"label": "Aglalana", "value": "C82417"},
             {"label": "Agpangi", "value": "C82418"},
             {"label": "Aguisan", "value": "C82419"},
             {"label": "Ajuy", "value": "C82420"},
             {"label": "Alacaygan", "value": "C82421"},
             {"label": "Alegria", "value": "C82422"},
             {"label": "Alibunan", "value": "C82423"},
             {"label": "Alicante", "value": "C82424"},
             {"label": "Alijis", "value": "C82425"},
             {"label": "Alim", "value": "C82426"},
             {"label": "Alimodian", "value": "C82427"},
             {"label": "Alimono", "value": "C82428"},
             {"label": "Altavas", "value": "C82429"},
             {"label": "Ambulong", "value": "C82430"},
             {"label": "Andres Bonifacio", "value": "C82431"},
             {"label": "Anini-y", "value": "C82432"},
             {"label": "Anoring", "value": "C82433"},
             {"label": "Antipolo", "value": "C82434"},
             {"label": "Aquino", "value": "C82435"},
             {"label": "Araal", "value": "C82436"},
             {"label": "Aranas Sur", "value": "C82437"},
             {"label": "Aranda", "value": "C82438"},
             {"label": "Arcangel", "value": "C82439"},
             {"label": "Asia", "value": "C82440"},
             {"label": "Asturga", "value": "C82441"},
             {"label": "Atabayan", "value": "C82442"},
             {"label": "Atipuluhan", "value": "C82443"},
             {"label": "Aurelliana", "value": "C82444"},
             {"label": "Avila", "value": "C82445"},
             {"label": "Bacalan", "value": "C82446"},
             {"label": "Bacolod City", "value": "C82447"},
             {"label": "Bacuyangan", "value": "C82448"},
             {"label": "Badiangan", "value": "C82449"},
             {"label": "Badlan", "value": "C82450"},
             {"label": "Bago City", "value": "C82451"},
             {"label": "Bagroy", "value": "C82452"},
             {"label": "Bagumbayan", "value": "C82453"},
             {"label": "Bailan", "value": "C82454"},
             {"label": "Balabag", "value": "C82455"},
             {"label": "Balasan", "value": "C82456"},
             {"label": "Balete", "value": "C82457"},
             {"label": "Balibagan Oeste", "value": "C82458"},
             {"label": "Baliwagan", "value": "C82459"},
             {"label": "Bancal", "value": "C82460"},
             {"label": "Banga", "value": "C82461"},
             {"label": "Barbaza", "value": "C82462"},
             {"label": "Barotac Nuevo", "value": "C82463"},
             {"label": "Barotac Viejo", "value": "C82464"},
             {"label": "Basiao", "value": "C82465"},
             {"label": "Batad", "value": "C82466"},
             {"label": "Batan", "value": "C82467"},
             {"label": "Bato", "value": "C82468"},
             {"label": "Bay-ang", "value": "C82469"},
             {"label": "Bayas", "value": "C82470"},
             {"label": "Belison", "value": "C82471"},
             {"label": "Biao", "value": "C82472"},
             {"label": "Bilao", "value": "C82473"},
             {"label": "Binabaan", "value": "C82474"},
             {"label": "Binalbagan", "value": "C82475"},
             {"label": "Binantocan", "value": "C82476"},
             {"label": "Bingawan", "value": "C82477"},
             {"label": "Binon-an", "value": "C82478"},
             {"label": "Binonga", "value": "C82479"},
             {"label": "Bitadtun", "value": "C82480"},
             {"label": "Bocana", "value": "C82481"},
             {"label": "Bolanon", "value": "C82482"},
             {"label": "Bolilao", "value": "C82483"},
             {"label": "Bolo", "value": "C82484"},
             {"label": "Bolong", "value": "C82485"},
             {"label": "Brgy. Bachaw Norte, Kalibo", "value": "C82486"},
             {"label": "Brgy. Bulwang, Numancia", "value": "C82487"},
             {"label": "Brgy. Mabilo, New Washington", "value": "C82488"},
             {"label": "Brgy. Nalook, kalibo", "value": "C82489"},
             {"label": "Brgy. New Buswang, Kalibo", "value": "C82490"},
             {"label": "Brgy. Tinigao, Kalibo", "value": "C82491"},
             {"label": "Buenavista", "value": "C82492"},
             {"label": "Buga", "value": "C82493"},
             {"label": "Bugang", "value": "C82494"},
             {"label": "Bugasong", "value": "C82495"},
             {"label": "Bula", "value": "C82496"},
             {"label": "Bulad", "value": "C82497"},
             {"label": "Bulata", "value": "C82498"},
             {"label": "Buluangan", "value": "C82499"},
             {"label": "Bungsuan", "value": "C82500"},
             {"label": "Buray", "value": "C82501"},
             {"label": "Burias", "value": "C82502"},
             {"label": "Buruanga", "value": "C82503"},
             {"label": "Busay", "value": "C82504"},
             {"label": "Buyuan", "value": "C82505"},
             {"label": "Cabacungan", "value": "C82506"},
             {"label": "Cabadiangan", "value": "C82507"},
             {"label": "Cabanbanan", "value": "C82508"},
             {"label": "Cabano", "value": "C82509"},
             {"label": "Cabatuan", "value": "C82510"},
             {"label": "Cabilao", "value": "C82511"},
             {"label": "Cabilauan", "value": "C82512"},
             {"label": "Cabugao", "value": "C82513"},
             {"label": "Cadagmayan Norte", "value": "C82514"},
             {"label": "Cadiz", "value": "C82515"},
             {"label": "Cagbang", "value": "C82516"},
             {"label": "Calampisauan", "value": "C82517"},
             {"label": "Calape", "value": "C82518"},
             {"label": "Calatrava", "value": "C82519"},
             {"label": "Calaya", "value": "C82520"},
             {"label": "Calinog", "value": "C82521"},
             {"label": "Calizo", "value": "C82522"},
             {"label": "Caluya", "value": "C82523"},
             {"label": "Camalobalo", "value": "C82524"},
             {"label": "Camandag", "value": "C82525"},
             {"label": "Camangcamang", "value": "C82526"},
             {"label": "Camindangan", "value": "C82527"},
             {"label": "Camingawan", "value": "C82528"},
             {"label": "Candelaria", "value": "C82529"},
             {"label": "Candoni", "value": "C82530"},
             {"label": "Caningay", "value": "C82531"},
             {"label": "Canroma", "value": "C82532"},
             {"label": "Cansilayan", "value": "C82533"},
             {"label": "Cansolungon", "value": "C82534"},
             {"label": "Canturay", "value": "C82535"},
             {"label": "Capaga", "value": "C82536"},
             {"label": "Capitan Ramon", "value": "C82537"},
             {"label": "Carabalan", "value": "C82538"},
             {"label": "Caridad", "value": "C82539"},
             {"label": "Carles", "value": "C82540"},
             {"label": "Carmelo", "value": "C82541"},
             {"label": "Carmen Grande", "value": "C82542"},
             {"label": "Cartagena", "value": "C82543"},
             {"label": "Cassanayan", "value": "C82544"},
             {"label": "Caticlan", "value": "C82545"},
             {"label": "Catungan", "value": "C82546"},
             {"label": "Cawayan", "value": "C82547"},
             {"label": "Cayanguan", "value": "C82548"},
             {"label": "Cayhagan", "value": "C82549"},
             {"label": "Cervantes", "value": "C82550"},
             {"label": "Chambrey", "value": "C82551"},
             {"label": "Codcod", "value": "C82552"},
             {"label": "Cogon", "value": "C82553"},
             {"label": "Colipapa", "value": "C82554"},
             {"label": "Concepcion", "value": "C82555"},
             {"label": "Concordia", "value": "C82556"},
             {"label": "Consolacion", "value": "C82557"},
             {"label": "Constancia", "value": "C82558"},
             {"label": "Consuelo", "value": "C82559"},
             {"label": "Cordova", "value": "C82560"},
             {"label": "Cortez", "value": "C82561"},
             {"label": "Cuartero", "value": "C82562"},
             {"label": "Culasi", "value": "C82563"},
             {"label": "Da-an Sur", "value": "C82564"},
             {"label": "Daliciasao", "value": "C82565"},
             {"label": "Damayan", "value": "C82566"},
             {"label": "Dancalan", "value": "C82567"},
             {"label": "Dao", "value": "C82568"},
             {"label": "Dapdapan", "value": "C82569"},
             {"label": "De la Paz", "value": "C82570"},
             {"label": "Dian-ay", "value": "C82571"},
             {"label": "Dingle", "value": "C82572"},
             {"label": "Dos Hermanas", "value": "C82573"},
             {"label": "Duenas", "value": "C82574"},
             {"label": "Dulangan", "value": "C82575"},
             {"label": "Dulao", "value": "C82576"},
             {"label": "Dumalag", "value": "C82577"},
             {"label": "Dumangas", "value": "C82578"},
             {"label": "Dumarao", "value": "C82579"},
             {"label": "Dungon", "value": "C82580"},
             {"label": "Duran", "value": "C82581"},
             {"label": "East Valencia", "value": "C82582"},
             {"label": "Egana", "value": "C82583"},
             {"label": "Ermita", "value": "C82584"},
             {"label": "Escalante", "value": "C82585"},
             {"label": "Estancia", "value": "C82586"},
             {"label": "Eustaquio Lopez", "value": "C82587"},
             {"label": "Feliciano", "value": "C82588"},
             {"label": "Gabi", "value": "C82589"},
             {"label": "Getulio", "value": "C82590"},
             {"label": "Gibato", "value": "C82591"},
             {"label": "Gibong", "value": "C82592"},
             {"label": "Gines-Patay", "value": "C82593"},
             {"label": "Granada", "value": "C82594"},
             {"label": "Guadalupe", "value": "C82595"},
             {"label": "Guiljungan", "value": "C82596"},
             {"label": "Guimbal", "value": "C82597"},
             {"label": "Guinoaliuan", "value": "C82598"},
             {"label": "Guinticgan", "value": "C82599"},
             {"label": "Guintubhan", "value": "C82600"},
             {"label": "Guisijan", "value": "C82601"},
             {"label": "Hacienda Refugio", "value": "C82602"},
             {"label": "Hacienda Santa Rosa", "value": "C82603"},
             {"label": "Haguimit", "value": "C82604"},
             {"label": "Hamtic", "value": "C82605"},
             {"label": "Himamaylan", "value": "C82606"},
             {"label": "Himaya", "value": "C82607"},
             {"label": "Hinigaran", "value": "C82608"},
             {"label": "Hipona", "value": "C82609"},
             {"label": "Ibajay", "value": "C82610"},
             {"label": "Idio", "value": "C82611"},
             {"label": "Igang", "value": "C82612"},
             {"label": "Igbaras", "value": "C82613"},
             {"label": "Igbon", "value": "C82614"},
             {"label": "Igcocolo", "value": "C82615"},
             {"label": "Igmaya-an", "value": "C82616"},
             {"label": "Ilog", "value": "C82617"},
             {"label": "Iloilo", "value": "C82618"},
             {"label": "Imbang", "value": "C82619"},
             {"label": "Inayauan", "value": "C82620"},
             {"label": "Intampilan", "value": "C82621"},
             {"label": "Isabela", "value": "C82622"},
             {"label": "Ivisan", "value": "C82623"},
             {"label": "Jaena", "value": "C82624"},
             {"label": "Jaguimitan", "value": "C82625"},
             {"label": "Jalaud", "value": "C82626"},
             {"label": "Jamabalod", "value": "C82627"},
             {"label": "Jamindan", "value": "C82628"},
             {"label": "Janiuay", "value": "C82629"},
             {"label": "Japitan", "value": "C82630"},
             {"label": "Jarigue", "value": "C82631"},
             {"label": "Jayubo", "value": "C82632"},
             {"label": "Jibao-an", "value": "C82633"},
             {"label": "Jordan", "value": "C82634"},
             {"label": "Kabankalan", "value": "C82635"},
             {"label": "Kabilauan", "value": "C82636"},
             {"label": "Kalibo (poblacion)", "value": "C82637"},
             {"label": "Kaliling", "value": "C82638"},
             {"label": "Kumalisquis", "value": "C82639"},
             {"label": "La Carlota", "value": "C82640"},
             {"label": "La Castellana", "value": "C82641"},
             {"label": "La Granja", "value": "C82642"},
             {"label": "La Paz", "value": "C82643"},
             {"label": "Lacaron", "value": "C82644"},
             {"label": "Lalab", "value": "C82645"},
             {"label": "Lalagsan", "value": "C82646"},
             {"label": "Lambunao", "value": "C82647"},
             {"label": "Langub", "value": "C82648"},
             {"label": "Lanot", "value": "C82649"},
             {"label": "Lantangan", "value": "C82650"},
             {"label": "Lawigan", "value": "C82651"},
             {"label": "Leganes", "value": "C82652"},
             {"label": "Lemery", "value": "C82653"},
             {"label": "Leon", "value": "C82654"},
             {"label": "Libacao", "value": "C82655"},
             {"label": "Libertad", "value": "C82656"},
             {"label": "Linabuan", "value": "C82657"},
             {"label": "Linabuan Sur", "value": "C82658"},
             {"label": "Linaon", "value": "C82659"},
             {"label": "Locmayan", "value": "C82660"},
             {"label": "Lono", "value": "C82661"},
             {"label": "Lonoy", "value": "C82662"},
             {"label": "Lopez Jaena", "value": "C82663"},
             {"label": "Lucena", "value": "C82664"},
             {"label": "Lucero", "value": "C82665"},
             {"label": "Luna", "value": "C82666"},
             {"label": "Lupo", "value": "C82667"},
             {"label": "Maao", "value": "C82668"},
             {"label": "Maasin", "value": "C82669"},
             {"label": "Maayon", "value": "C82670"},
             {"label": "Mabini", "value": "C82671"},
             {"label": "Madalag", "value": "C82672"},
             {"label": "Magallon Cadre", "value": "C82673"},
             {"label": "Magdalena", "value": "C82674"},
             {"label": "Makato", "value": "C82675"},
             {"label": "Malabonot", "value": "C82676"},
             {"label": "Malabor", "value": "C82677"},
             {"label": "Malangabang", "value": "C82678"},
             {"label": "Malay", "value": "C82679"},
             {"label": "Malayo-an", "value": "C82680"},
             {"label": "Malinao", "value": "C82681"},
             {"label": "Malocloc", "value": "C82682"},
             {"label": "Maloco", "value": "C82683"},
             {"label": "Mambagatan", "value": "C82684"},
             {"label": "Mambusao", "value": "C82685"},
             {"label": "Manalad", "value": "C82686"},
             {"label": "Manapla", "value": "C82687"},
             {"label": "Mangoso", "value": "C82688"},
             {"label": "Manika", "value": "C82689"},
             {"label": "Manjoy", "value": "C82690"},
             {"label": "Manlucahoc", "value": "C82691"},
             {"label": "Manoc-Manoc", "value": "C82692"},
             {"label": "Mansilingan", "value": "C82693"},
             {"label": "Manup", "value": "C82694"},
             {"label": "Mapili", "value": "C82695"},
             {"label": "Maquiling", "value": "C82696"},
             {"label": "Marawis", "value": "C82697"},
             {"label": "Maribong", "value": "C82698"},
             {"label": "Maricalom", "value": "C82699"},
             {"label": "Masaling", "value": "C82700"},
             {"label": "Masonogan", "value": "C82701"},
             {"label": "Miagao", "value": "C82702"},
             {"label": "Mianay", "value": "C82703"},
             {"label": "Mina", "value": "C82704"},
             {"label": "Minapasoc", "value": "C82705"},
             {"label": "Minuyan", "value": "C82706"},
             {"label": "Miranda", "value": "C82707"},
             {"label": "Moises Padilla", "value": "C82708"},
             {"label": "Monpon", "value": "C82709"},
             {"label": "Montilla", "value": "C82710"},
             {"label": "Morales", "value": "C82711"},
             {"label": "Morobuan", "value": "C82712"},
             {"label": "Murcia", "value": "C82713"},
             {"label": "Nabas", "value": "C82714"},
             {"label": "Nabulao", "value": "C82715"},
             {"label": "Naili", "value": "C82716"},
             {"label": "Naisud", "value": "C82717"},
             {"label": "Nangka", "value": "C82718"},
             {"label": "Napnapan", "value": "C82719"},
             {"label": "Napoles", "value": "C82720"},
             {"label": "Nato", "value": "C82721"},
             {"label": "New Pandanon", "value": "C82722"},
             {"label": "New Washington", "value": "C82723"},
             {"label": "Nueva Valencia", "value": "C82724"},
             {"label": "Numancia", "value": "C82725"},
             {"label": "Ochanado", "value": "C82726"},
             {"label": "Odiong", "value": "C82727"},
             {"label": "Ogtongon", "value": "C82728"},
             {"label": "Ondoy", "value": "C82729"},
             {"label": "Oracon", "value": "C82730"},
             {"label": "Orong", "value": "C82731"},
             {"label": "Oton", "value": "C82732"},
             {"label": "Pacol", "value": "C82733"},
             {"label": "Pakiad", "value": "C82734"},
             {"label": "Palampas", "value": "C82735"},
             {"label": "Panay", "value": "C82736"},
             {"label": "Panayacan", "value": "C82737"},
             {"label": "Pandan", "value": "C82738"},
             {"label": "Panitan", "value": "C82739"},
             {"label": "Paraiso", "value": "C82740"},
             {"label": "Parion", "value": "C82741"},
             {"label": "Pasil", "value": "C82742"},
             {"label": "Passi", "value": "C82743"},
             {"label": "Patique", "value": "C82744"},
             {"label": "Patnongon", "value": "C82745"},
             {"label": "Patonan", "value": "C82746"},
             {"label": "Patria", "value": "C82747"},
             {"label": "Pavia", "value": "C82748"},
             {"label": "Pawa", "value": "C82749"},
             {"label": "Payao", "value": "C82750"},
             {"label": "Piape I", "value": "C82751"},
             {"label": "Pilar", "value": "C82752"},
             {"label": "Pili", "value": "C82753"},
             {"label": "Pina", "value": "C82754"},
             {"label": "Plaridel", "value": "C82755"},
             {"label": "Platagata", "value": "C82756"},
             {"label": "Polo", "value": "C82757"},
             {"label": "Polopina", "value": "C82758"},
             {"label": "Ponong", "value": "C82759"},
             {"label": "Pontevedra", "value": "C82760"},
             {"label": "Pototan", "value": "C82761"},
             {"label": "President Roxas", "value": "C82762"},
             {"label": "Prosperidad", "value": "C82763"},
             {"label": "Province of Aklan", "value": "C82764"},
             {"label": "Province of Antique", "value": "C82765"},
             {"label": "Province of Capiz", "value": "C82766"},
             {"label": "Province of Guimaras", "value": "C82767"},
             {"label": "Province of Iloilo", "value": "C82768"},
             {"label": "Province of Negros Occidental", "value": "C82769"},
             {"label": "Pulupandan", "value": "C82770"},
             {"label": "Punao", "value": "C82771"},
             {"label": "Quezon", "value": "C82772"},
             {"label": "Quinagaringan", "value": "C82773"},
             {"label": "Quipot", "value": "C82774"},
             {"label": "Rizal", "value": "C82775"},
             {"label": "Rosario", "value": "C82776"},
             {"label": "Roxas City", "value": "C82777"},
             {"label": "Sagang", "value": "C82778"},
             {"label": "Sagasa", "value": "C82779"},
             {"label": "Sagay", "value": "C82780"},
             {"label": "Salamanca", "value": "C82781"},
             {"label": "Salvacion", "value": "C82782"},
             {"label": "San Antonio", "value": "C82783"},
             {"label": "San Dionisio", "value": "C82784"},
             {"label": "San Enrique", "value": "C82785"},
             {"label": "San Fernando", "value": "C82786"},
             {"label": "San Francisco", "value": "C82787"},
             {"label": "San Joaquin", "value": "C82788"},
             {"label": "San Jose", "value": "C82789"},
             {"label": "San Juan", "value": "C82790"},
             {"label": "San Miguel", "value": "C82791"},
             {"label": "San Nicolas", "value": "C82792"},
             {"label": "San Pablo", "value": "C82793"},
             {"label": "San Pedro", "value": "C82794"},
             {"label": "San Rafael", "value": "C82795"},
             {"label": "San Remigio", "value": "C82796"},
             {"label": "San Salvador", "value": "C82797"},
             {"label": "Santa Angel", "value": "C82798"},
             {"label": "Santa Barbara", "value": "C82799"},
             {"label": "Santa Cruz", "value": "C82800"},
             {"label": "Santa Monica", "value": "C82801"},
             {"label": "Santa Teresa", "value": "C82802"},
             {"label": "Santiago", "value": "C82803"},
             {"label": "Santol", "value": "C82804"},
             {"label": "Sapian", "value": "C82805"},
             {"label": "Sara", "value": "C82806"},
             {"label": "Saravia", "value": "C82807"},
             {"label": "Sebaste", "value": "C82808"},
             {"label": "Semirara", "value": "C82809"},
             {"label": "Sibaguan", "value": "C82810"},
             {"label": "Sibalom", "value": "C82811"},
             {"label": "Sibucao", "value": "C82812"},
             {"label": "Sigma", "value": "C82813"},
             {"label": "Sipalay", "value": "C82814"},
             {"label": "Suay", "value": "C82815"},
             {"label": "Sulangan", "value": "C82816"},
             {"label": "Sumag", "value": "C82817"},
             {"label": "Tabu", "value": "C82818"},
             {"label": "Tabuc Pontevedra", "value": "C82819"},
             {"label": "Talaban", "value": "C82820"},
             {"label": "Talisay", "value": "C82821"},
             {"label": "Taloc", "value": "C82822"},
             {"label": "Talokgangan", "value": "C82823"},
             {"label": "Talon", "value": "C82824"},
             {"label": "Tambac", "value": "C82825"},
             {"label": "Tambalisa", "value": "C82826"},
             {"label": "Tamlang", "value": "C82827"},
             {"label": "Tangalan", "value": "C82828"},
             {"label": "Tanza", "value": "C82829"},
             {"label": "Tapas", "value": "C82830"},
             {"label": "Tarong", "value": "C82831"},
             {"label": "Tibiao", "value": "C82832"},
             {"label": "Tigbauan", "value": "C82833"},
             {"label": "Tiglauigan", "value": "C82834"},
             {"label": "Tigum", "value": "C82835"},
             {"label": "Tiling", "value": "C82836"},
             {"label": "Timpas", "value": "C82837"},
             {"label": "Tinogboc", "value": "C82838"},
             {"label": "Tinongan", "value": "C82839"},
             {"label": "Tiring", "value": "C82840"},
             {"label": "Tiwi", "value": "C82841"},
             {"label": "Tobias Fornier", "value": "C82842"},
             {"label": "Toboso", "value": "C82843"},
             {"label": "Tortosa", "value": "C82844"},
             {"label": "Trapiche", "value": "C82845"},
             {"label": "Tugas", "value": "C82846"},
             {"label": "Tumcon Ilawod", "value": "C82847"},
             {"label": "Tuyum", "value": "C82848"},
             {"label": "Ualog", "value": "C82849"},
             {"label": "Ungca", "value": "C82850"},
             {"label": "Unidos", "value": "C82851"},
             {"label": "Union", "value": "C82852"},
             {"label": "Valderrama", "value": "C82853"},
             {"label": "Valladolid", "value": "C82854"},
             {"label": "Victorias", "value": "C82855"},
             {"label": "Viejo Daan Banua", "value": "C82856"},
             {"label": "Vista Alegre", "value": "C82857"},
             {"label": "Vito", "value": "C82858"},
             {"label": "Yapak", "value": "C82859"},
             {"label": "Yubo", "value": "C82860"},
             {"label": "Zarraga", "value": "C82861"}],
 "Antofagasta": [{"label": "Antofagasta", "value": "C17902"},
                 {"label": "Calama", "value": "C17903"},
                 {"label": "Maria Elena", "value": "C17904"},
                 {"label": "Mejillones", "value": "C17905"},
                 {"label": "Ollague", "value": "C17906"},
                 {"label": "San Pedro de Atacama", "value": "C17907"},
                 {"label": "Sierra Gorda", "value": "C17908"},
                 {"label": "Taltal", "value": "C17909"},
                 {"label": "Tocopilla", "value": "C17910"}],
 "Antsiranana Province": [{"label": "Ampasimanolotra", "value": "C65552"},
                          {"label": "Andovoranto", "value": "C65553"},
                          {"label": "Mahanoro", "value": "C65554"},
                          {"label": "Mahavelona", "value": "C65555"},
                          {"label": "Marolambo", "value": "C65556"},
                          {"label": "Toamasina", "value": "C65557"},
                          {"label": "Toamasina I", "value": "C65558"},
                          {"label": "Toamasina II", "value": "C65559"},
                          {"label": "Vohibinany", "value": "C65560"}],
 "Anzoategui": [{"label": "Anaco", "value": "C147129"},
                {"label": "Aragua de Barcelona", "value": "C147130"},
                {"label": "Barcelona", "value": "C147131"},
                {"label": "Cantaura", "value": "C147132"},
                {"label": "El Tigre", "value": "C147133"},
                {"label": "Municipio Jose Gregorio Monagas",
                 "value": "C147134"},
                {"label": "Onoto", "value": "C147135"},
                {"label": "Puerto La Cruz", "value": "C147136"},
                {"label": "Puerto Piritu", "value": "C147137"},
                {"label": "San Jose de Guanipa", "value": "C147138"}],
 "Aomori Prefecture": [{"label": "Aomori", "value": "C62621"},
                       {"label": "Aomori Shi", "value": "C62622"},
                       {"label": "Goshogawara", "value": "C62623"},
                       {"label": "Goshogawara Shi", "value": "C62624"},
                       {"label": "Hachinohe", "value": "C62625"},
                       {"label": "Hachinohe Shi", "value": "C62626"},
                       {"label": "Hirakawa", "value": "C62627"},
                       {"label": "Hirakawa Shi", "value": "C62628"},
                       {"label": "Hirosaki", "value": "C62629"},
                       {"label": "Hirosaki Shi", "value": "C62630"},
                       {"label": "Kuroishi", "value": "C62631"},
                       {"label": "Kuroishi Shi", "value": "C62632"},
                       {"label": "Misawa", "value": "C62633"},
                       {"label": "Misawa Shi", "value": "C62634"},
                       {"label": "Mutsu", "value": "C62635"},
                       {"label": "Mutsu-shi", "value": "C62636"},
                       {"label": "Namioka", "value": "C62637"},
                       {"label": "Shimokizukuri", "value": "C62638"},
                       {"label": "Towada Shi", "value": "C62639"},
                       {"label": "Tsugaru", "value": "C62640"},
                       {"label": "Tsugaru Shi", "value": "C62641"}],
 "Aosta Valley": [{"label": "Allein", "value": "C52087"},
                  {"label": "Antagnod", "value": "C52088"},
                  {"label": "Antey-Saint-Andre", "value": "C52089"},
                  {"label": "Aosta", "value": "C52090"},
                  {"label": "Arnad", "value": "C52091"},
                  {"label": "Arvier", "value": "C52092"},
                  {"label": "Avise", "value": "C52093"},
                  {"label": "Ayas", "value": "C52094"},
                  {"label": "Aymavilles", "value": "C52095"},
                  {"label": "Bard", "value": "C52096"},
                  {"label": "Berriat", "value": "C52097"},
                  {"label": "Bionaz", "value": "C52098"},
                  {"label": "Brusson", "value": "C52099"},
                  {"label": "Challand-Saint-Anselme", "value": "C52100"},
                  {"label": "Challand-Saint-Victor", "value": "C52101"},
                  {"label": "Chambave", "value": "C52102"},
                  {"label": "Chamois", "value": "C52103"},
                  {"label": "Champdepraz", "value": "C52104"},
                  {"label": "Champorcher", "value": "C52105"},
                  {"label": "Charvensod", "value": "C52106"},
                  {"label": "Chatillon", "value": "C52107"},
                  {"label": "Chef-Lieu", "value": "C52108"},
                  {"label": "Cogne", "value": "C52109"},
                  {"label": "Courmayeur", "value": "C52110"},
                  {"label": "Donnas", "value": "C52111"},
                  {"label": "Doues", "value": "C52112"},
                  {"label": "Emarese", "value": "C52113"},
                  {"label": "Etroubles", "value": "C52114"},
                  {"label": "Fenis", "value": "C52115"},
                  {"label": "Fontainemore", "value": "C52116"},
                  {"label": "Gaby", "value": "C52117"},
                  {"label": "Gignod", "value": "C52118"},
                  {"label": "Grand Brissogne", "value": "C52119"},
                  {"label": "Gressan", "value": "C52120"},
                  {"label": "Gressoney-La-Trinite", "value": "C52121"},
                  {"label": "Gressoney-Saint-Jean", "value": "C52122"},
                  {"label": "Hone", "value": "C52123"},
                  {"label": "Introd", "value": "C52124"},
                  {"label": "Issime", "value": "C52125"},
                  {"label": "Issogne", "value": "C52126"},
                  {"label": "Jovencan", "value": "C52127"},
                  {"label": "La Cretaz-Roisan", "value": "C52128"},
                  {"label": "La Magdeleine", "value": "C52129"},
                  {"label": "La Place", "value": "C52130"},
                  {"label": "La Salle", "value": "C52131"},
                  {"label": "La Thuile", "value": "C52132"},
                  {"label": "Lassolaz", "value": "C52133"},
                  {"label": "Lillianes", "value": "C52134"},
                  {"label": "Mongnod", "value": "C52135"},
                  {"label": "Montan-Angelin-Arensod", "value": "C52136"},
                  {"label": "Montjovet", "value": "C52137"},
                  {"label": "Morgex", "value": "C52138"},
                  {"label": "Nus", "value": "C52139"},
                  {"label": "Ollomont", "value": "C52140"},
                  {"label": "Oyace", "value": "C52141"},
                  {"label": "Paquier", "value": "C52142"},
                  {"label": "Perloz", "value": "C52143"},
                  {"label": "Petit Fenis", "value": "C52144"},
                  {"label": "Plan dIntrod", "value": "C52145"},
                  {"label": "Pollein", "value": "C52146"},
                  {"label": "Pont-Bozet", "value": "C52147"},
                  {"label": "Pont-Saint-Martin", "value": "C52148"},
                  {"label": "Pontey", "value": "C52149"},
                  {"label": "Pre Saint Didier", "value": "C52150"},
                  {"label": "Quart", "value": "C52151"},
                  {"label": "Quincod", "value": "C52152"},
                  {"label": "Rhemes-Notre-Dame", "value": "C52153"},
                  {"label": "Rhemes-Saint-Georges", "value": "C52154"},
                  {"label": "Saint Marcel", "value": "C52155"},
                  {"label": "Saint Maurice", "value": "C52156"},
                  {"label": "Saint-Christophe", "value": "C52157"},
                  {"label": "Saint-Denis", "value": "C52158"},
                  {"label": "Saint-Nicolas", "value": "C52159"},
                  {"label": "Saint-Oyen", "value": "C52160"},
                  {"label": "Saint-Pierre", "value": "C52161"},
                  {"label": "Saint-Rhemy", "value": "C52162"},
                  {"label": "Saint-Vincent", "value": "C52163"},
                  {"label": "San Leonardo", "value": "C52164"},
                  {"label": "Torgnon", "value": "C52165"},
                  {"label": "Valgrisenche", "value": "C52166"},
                  {"label": "Valle dAosta", "value": "C52167"},
                  {"label": "Valpelline", "value": "C52168"},
                  {"label": "Valsavarenche", "value": "C52169"},
                  {"label": "Valtournenche", "value": "C52170"},
                  {"label": "Verrayes", "value": "C52171"},
                  {"label": "Verres", "value": "C52172"},
                  {"label": "Villa-Nabian", "value": "C52173"},
                  {"label": "Villair-Amerique", "value": "C52174"},
                  {"label": "Ville Sur Sarre", "value": "C52175"},
                  {"label": "Villefranche", "value": "C52176"},
                  {"label": "Villeneuve", "value": "C52177"}],
 "Ape Municipality": [{"label": "Ape", "value": "C64847"}],
 "Appenzell Ausserrhoden": [{"label": "Bezirk Hinterland", "value": "C116425"},
                            {"label": "Bezirk Mittelland", "value": "C116426"},
                            {"label": "Bezirk Vorderland", "value": "C116427"},
                            {"label": "Buhler", "value": "C116428"},
                            {"label": "Gais", "value": "C116429"},
                            {"label": "Heiden", "value": "C116430"},
                            {"label": "Herisau", "value": "C116431"},
                            {"label": "Rehetobel", "value": "C116432"},
                            {"label": "Schwellbrunn", "value": "C116433"},
                            {"label": "Speicher", "value": "C116434"},
                            {"label": "Teufen", "value": "C116435"},
                            {"label": "Trogen", "value": "C116436"},
                            {"label": "Urnasch", "value": "C116437"},
                            {"label": "Waldstatt", "value": "C116438"},
                            {"label": "Walzenhausen", "value": "C116439"}],
 "Appenzell Innerrhoden": [{"label": "Appenzell", "value": "C116440"},
                           {"label": "Gonten", "value": "C116441"},
                           {"label": "Haslen", "value": "C116442"},
                           {"label": "Oberegg", "value": "C116443"}],
 "Apulia": [{"label": "Accadia", "value": "C52178"},
            {"label": "Acquarica del Capo", "value": "C52179"},
            {"label": "Acquaviva delle Fonti", "value": "C52180"},
            {"label": "Adelfia", "value": "C52181"},
            {"label": "Alberobello", "value": "C52182"},
            {"label": "Alberona", "value": "C52183"},
            {"label": "Alessano", "value": "C52184"},
            {"label": "Alezio", "value": "C52185"},
            {"label": "Alliste", "value": "C52186"},
            {"label": "Altamura", "value": "C52187"},
            {"label": "Andrano", "value": "C52188"},
            {"label": "Andria", "value": "C52189"},
            {"label": "Anzano di Puglia", "value": "C52190"},
            {"label": "Apricena", "value": "C52191"},
            {"label": "Aradeo", "value": "C52192"},
            {"label": "Arnesano", "value": "C52193"},
            {"label": "Ascoli Satriano", "value": "C52194"},
            {"label": "Avetrana", "value": "C52195"},
            {"label": "Bagnolo del Salento", "value": "C52196"},
            {"label": "Bari", "value": "C52197"},
            {"label": "Barletta", "value": "C52198"},
            {"label": "Biccari", "value": "C52199"},
            {"label": "Binetto", "value": "C52200"},
            {"label": "Bisceglie", "value": "C52201"},
            {"label": "Bitetto", "value": "C52202"},
            {"label": "Bitonto", "value": "C52203"},
            {"label": "Bitritto", "value": "C52204"},
            {"label": "Borgagne", "value": "C52205"},
            {"label": "Botrugno", "value": "C52206"},
            {"label": "Bovino", "value": "C52207"},
            {"label": "Brindisi", "value": "C52208"},
            {"label": "Cagnano Varano", "value": "C52209"},
            {"label": "Calimera", "value": "C52210"},
            {"label": "Campi Salentina", "value": "C52211"},
            {"label": "Candela", "value": "C52212"},
            {"label": "Cannole", "value": "C52213"},
            {"label": "Canosa di Puglia", "value": "C52214"},
            {"label": "Capirro", "value": "C52215"},
            {"label": "Caprarica di Lecce", "value": "C52216"},
            {"label": "Capurso", "value": "C52217"},
            {"label": "Carapelle", "value": "C52218"},
            {"label": "Carlantino", "value": "C52219"},
            {"label": "Carmiano", "value": "C52220"},
            {"label": "Carosino", "value": "C52221"},
            {"label": "Carovigno", "value": "C52222"},
            {"label": "Carpignano Salentino", "value": "C52223"},
            {"label": "Carpino", "value": "C52224"},
            {"label": "Casalini", "value": "C52225"},
            {"label": "Casalnuovo Monterotaro", "value": "C52226"},
            {"label": "Casalvecchio di Puglia", "value": "C52227"},
            {"label": "Casamassella", "value": "C52228"},
            {"label": "Casamassima", "value": "C52229"},
            {"label": "Casarano", "value": "C52230"},
            {"label": "Cassano delle Murge", "value": "C52231"},
            {"label": "Castellana", "value": "C52232"},
            {"label": "Castellaneta", "value": "C52233"},
            {"label": "Castelluccio dei Sauri", "value": "C52234"},
            {"label": "Castelluccio Valmaggiore", "value": "C52235"},
            {"label": "Castelnuovo della Daunia", "value": "C52236"},
            {"label": "Castiglione", "value": "C52237"},
            {"label": "Castri di Lecce", "value": "C52238"},
            {"label": "Castrignano De Greci", "value": "C52239"},
            {"label": "Castrignano del Capo", "value": "C52240"},
            {"label": "Castro", "value": "C52241"},
            {"label": "Castromediano", "value": "C52242"},
            {"label": "Cavallino", "value": "C52243"},
            {"label": "Ceglie Messapica", "value": "C52244"},
            {"label": "Celenza Valfortore", "value": "C52245"},
            {"label": "Cellamare", "value": "C52246"},
            {"label": "Celle di San Vito", "value": "C52247"},
            {"label": "Cellino San Marco", "value": "C52248"},
            {"label": "Cerfignano", "value": "C52249"},
            {"label": "Cerignola", "value": "C52250"},
            {"label": "Chieuti", "value": "C52251"},
            {"label": "Cisternino", "value": "C52252"},
            {"label": "Collemeto", "value": "C52253"},
            {"label": "Collepasso", "value": "C52254"},
            {"label": "Conversano", "value": "C52255"},
            {"label": "Copertino", "value": "C52256"},
            {"label": "Corato", "value": "C52257"},
            {"label": "Corigliano dOtranto", "value": "C52258"},
            {"label": "Corsano", "value": "C52259"},
            {"label": "Cozzana", "value": "C52260"},
            {"label": "Crispiano", "value": "C52261"},
            {"label": "Cursi", "value": "C52262"},
            {"label": "Cutrofiano", "value": "C52263"},
            {"label": "Deliceto", "value": "C52264"},
            {"label": "Depressa", "value": "C52265"},
            {"label": "Diso", "value": "C52266"},
            {"label": "Erchie", "value": "C52267"},
            {"label": "Faeto", "value": "C52268"},
            {"label": "Faggiano", "value": "C52269"},
            {"label": "Fasano", "value": "C52270"},
            {"label": "Felline", "value": "C52271"},
            {"label": "Foggia", "value": "C52272"},
            {"label": "Fragagnano", "value": "C52273"},
            {"label": "Francavilla Fontana", "value": "C52274"},
            {"label": "Gagliano del Capo", "value": "C52275"},
            {"label": "Galatina", "value": "C52276"},
            {"label": "Galatone", "value": "C52277"},
            {"label": "Gallipoli", "value": "C52278"},
            {"label": "Galugnano", "value": "C52279"},
            {"label": "Gemini", "value": "C52280"},
            {"label": "Ginosa", "value": "C52281"},
            {"label": "Gioia del Colle", "value": "C52282"},
            {"label": "Giorgilorio", "value": "C52283"},
            {"label": "Giovinazzo", "value": "C52284"},
            {"label": "Giuggianello", "value": "C52285"},
            {"label": "Giurdignano", "value": "C52286"},
            {"label": "Gravina in Puglia", "value": "C52287"},
            {"label": "Grottaglie", "value": "C52288"},
            {"label": "Grumo Appula", "value": "C52289"},
            {"label": "Guagnano", "value": "C52290"},
            {"label": "Ischitella", "value": "C52291"},
            {"label": "Isole Tremiti", "value": "C52292"},
            {"label": "La Rosa", "value": "C52293"},
            {"label": "Lamie", "value": "C52294"},
            {"label": "Lamie di Olimpie-Selva", "value": "C52295"},
            {"label": "Laterza", "value": "C52296"},
            {"label": "Latiano", "value": "C52297"},
            {"label": "Lecce", "value": "C52298"},
            {"label": "Leporano", "value": "C52299"},
            {"label": "Leporano Marina", "value": "C52300"},
            {"label": "Lequile", "value": "C52301"},
            {"label": "Lesina", "value": "C52302"},
            {"label": "Leuca", "value": "C52303"},
            {"label": "Leverano", "value": "C52304"},
            {"label": "Lizzanello", "value": "C52305"},
            {"label": "Lizzano", "value": "C52306"},
            {"label": "Locorotondo", "value": "C52307"},
            {"label": "Loseto", "value": "C52308"},
            {"label": "Lucera", "value": "C52309"},
            {"label": "Lucugnano", "value": "C52310"},
            {"label": "Magliano", "value": "C52311"},
            {"label": "Maglie", "value": "C52312"},
            {"label": "Manduria", "value": "C52313"},
            {"label": "Manfredonia", "value": "C52314"},
            {"label": "Margherita di Savoia", "value": "C52315"},
            {"label": "Marina di Ginosa", "value": "C52316"},
            {"label": "Mariotto", "value": "C52317"},
            {"label": "Marittima", "value": "C52318"},
            {"label": "Martano", "value": "C52319"},
            {"label": "Martignano", "value": "C52320"},
            {"label": "Martina Franca", "value": "C52321"},
            {"label": "Maruggio", "value": "C52322"},
            {"label": "Massafra", "value": "C52323"},
            {"label": "Materdomini", "value": "C52324"},
            {"label": "Matino", "value": "C52325"},
            {"label": "Mattinata", "value": "C52326"},
            {"label": "Melendugno", "value": "C52327"},
            {"label": "Melissano", "value": "C52328"},
            {"label": "Melpignano", "value": "C52329"},
            {"label": "Merine", "value": "C52330"},
            {"label": "Mesagne", "value": "C52331"},
            {"label": "Miggiano", "value": "C52332"},
            {"label": "Minervino di Lecce", "value": "C52333"},
            {"label": "Minervino Murge", "value": "C52334"},
            {"label": "Modugno", "value": "C52335"},
            {"label": "Mola di Bari", "value": "C52336"},
            {"label": "Molfetta", "value": "C52337"},
            {"label": "Monopoli", "value": "C52338"},
            {"label": "Montalbano", "value": "C52339"},
            {"label": "Monte SantAngelo", "value": "C52340"},
            {"label": "Monteiasi", "value": "C52341"},
            {"label": "Monteleone di Puglia", "value": "C52342"},
            {"label": "Montemesola", "value": "C52343"},
            {"label": "Monteparano", "value": "C52344"},
            {"label": "Monteroni di Lecce", "value": "C52345"},
            {"label": "Montesano Salentino", "value": "C52346"},
            {"label": "Montesardo", "value": "C52347"},
            {"label": "Monti dArena-Bosco Caggione", "value": "C52348"},
            {"label": "Morciano di Leuca", "value": "C52349"},
            {"label": "Motta Montecorvino", "value": "C52350"},
            {"label": "Mottola", "value": "C52351"},
            {"label": "Muro Leccese", "value": "C52352"},
            {"label": "Nardo", "value": "C52353"},
            {"label": "Neviano", "value": "C52354"},
            {"label": "Noci", "value": "C52355"},
            {"label": "Nociglia", "value": "C52356"},
            {"label": "Noha", "value": "C52357"},
            {"label": "Noicattaro", "value": "C52358"},
            {"label": "Novoli", "value": "C52359"},
            {"label": "Ordona", "value": "C52360"},
            {"label": "Oria", "value": "C52361"},
            {"label": "Orsara di Puglia", "value": "C52362"},
            {"label": "Orta Nova", "value": "C52363"},
            {"label": "Ortelle", "value": "C52364"},
            {"label": "Ostuni", "value": "C52365"},
            {"label": "Otranto", "value": "C52366"},
            {"label": "Palagianello", "value": "C52367"},
            {"label": "Palagiano", "value": "C52368"},
            {"label": "Palmariggi", "value": "C52369"},
            {"label": "Palo del Colle", "value": "C52370"},
            {"label": "Palombaio", "value": "C52371"},
            {"label": "Panni", "value": "C52372"},
            {"label": "Paolo VI", "value": "C52373"},
            {"label": "Parabita", "value": "C52374"},
            {"label": "Parco Scizzo-Parchitello", "value": "C52375"},
            {"label": "Patu", "value": "C52376"},
            {"label": "Peschici", "value": "C52377"},
            {"label": "Pezze di Greco", "value": "C52378"},
            {"label": "Pietramontecorvino", "value": "C52379"},
            {"label": "Pisignano", "value": "C52380"},
            {"label": "Poggiardo", "value": "C52381"},
            {"label": "Poggio Imperiale", "value": "C52382"},
            {"label": "Poggiorsini", "value": "C52383"},
            {"label": "Polignano a Mare", "value": "C52384"},
            {"label": "Porto Cesareo", "value": "C52385"},
            {"label": "Presicce", "value": "C52386"},
            {"label": "Provincia di Barletta - Andria - Trani",
             "value": "C52387"},
            {"label": "Provincia di Brindisi", "value": "C52388"},
            {"label": "Provincia di Foggia", "value": "C52389"},
            {"label": "Provincia di Lecce", "value": "C52390"},
            {"label": "Provincia di Taranto", "value": "C52391"},
            {"label": "Pulsano", "value": "C52392"},
            {"label": "Putignano", "value": "C52393"},
            {"label": "Quasano", "value": "C52394"},
            {"label": "Racale", "value": "C52395"},
            {"label": "Rignano Garganico", "value": "C52396"},
            {"label": "Roccaforzata", "value": "C52397"},
            {"label": "Rocchetta SantAntonio", "value": "C52398"},
            {"label": "Rodi Garganico", "value": "C52399"},
            {"label": "Roseto Valfortore", "value": "C52400"},
            {"label": "Ruffano", "value": "C52401"},
            {"label": "Rutigliano", "value": "C52402"},
            {"label": "Ruvo di Puglia", "value": "C52403"},
            {"label": "Salice Salentino", "value": "C52404"},
            {"label": "Salve", "value": "C52405"},
            {"label": "Sammichele di Bari", "value": "C52406"},
            {"label": "San Cassiano", "value": "C52407"},
            {"label": "San Cesario di Lecce", "value": "C52408"},
            {"label": "San Donaci", "value": "C52409"},
            {"label": "San Donato di Lecce", "value": "C52410"},
            {"label": "San Ferdinando di Puglia", "value": "C52411"},
            {"label": "San Giorgio Ionico", "value": "C52412"},
            {"label": "San Giovanni Rotondo", "value": "C52413"},
            {"label": "San Marco in Lamis", "value": "C52414"},
            {"label": "San Marco la Catola", "value": "C52415"},
            {"label": "San Marzano di San Giuseppe", "value": "C52416"},
            {"label": "San Michele Salentino", "value": "C52417"},
            {"label": "San Pancrazio Salentino", "value": "C52418"},
            {"label": "San Paolo", "value": "C52419"},
            {"label": "San Paolo di Civitate", "value": "C52420"},
            {"label": "San Pietro in Lama", "value": "C52421"},
            {"label": "San Pietro Vernotico", "value": "C52422"},
            {"label": "San Severo", "value": "C52423"},
            {"label": "San Vito dei Normanni", "value": "C52424"},
            {"label": "Sanarica", "value": "C52425"},
            {"label": "Sannicandro di Bari", "value": "C52426"},
            {"label": "Sannicandro Garganico", "value": "C52427"},
            {"label": "Sannicola", "value": "C52428"},
            {"label": "SantAgata di Puglia", "value": "C52429"},
            {"label": "Santa Cesarea Terme", "value": "C52430"},
            {"label": "Santeramo in Colle", "value": "C52431"},
            {"label": "Sava", "value": "C52432"},
            {"label": "Scorrano", "value": "C52433"},
            {"label": "Secli", "value": "C52434"},
            {"label": "Serracapriola", "value": "C52435"},
            {"label": "Serrano", "value": "C52436"},
            {"label": "Sogliano Cavour", "value": "C52437"},
            {"label": "Soleto", "value": "C52438"},
            {"label": "Specchia", "value": "C52439"},
            {"label": "Spinazzola", "value": "C52440"},
            {"label": "Spongano", "value": "C52441"},
            {"label": "Squinzano", "value": "C52442"},
            {"label": "Statte", "value": "C52443"},
            {"label": "Sternatia", "value": "C52444"},
            {"label": "Stornara", "value": "C52445"},
            {"label": "Stornarella", "value": "C52446"},
            {"label": "Struda", "value": "C52447"},
            {"label": "Supersano", "value": "C52448"},
            {"label": "Surano", "value": "C52449"},
            {"label": "Surbo", "value": "C52450"},
            {"label": "Taranto", "value": "C52451"},
            {"label": "Taurisano", "value": "C52452"},
            {"label": "Taviano", "value": "C52453"},
            {"label": "Terlizzi", "value": "C52454"},
            {"label": "Tiggiano", "value": "C52455"},
            {"label": "Torchiarolo", "value": "C52456"},
            {"label": "Toritto", "value": "C52457"},
            {"label": "Torre San Giovanni", "value": "C52458"},
            {"label": "Torre Santa Susanna", "value": "C52459"},
            {"label": "Torremaggiore", "value": "C52460"},
            {"label": "Torricella", "value": "C52461"},
            {"label": "Trani", "value": "C52462"},
            {"label": "Trepuzzi", "value": "C52463"},
            {"label": "Tricase", "value": "C52464"},
            {"label": "Triggiano", "value": "C52465"},
            {"label": "Trinitapoli", "value": "C52466"},
            {"label": "Troia", "value": "C52467"},
            {"label": "Tuglie", "value": "C52468"},
            {"label": "Turi", "value": "C52469"},
            {"label": "Tuturano", "value": "C52470"},
            {"label": "Ugento", "value": "C52471"},
            {"label": "Uggiano la Chiesa", "value": "C52472"},
            {"label": "Uggiano Montefusco", "value": "C52473"},
            {"label": "Valenzano", "value": "C52474"},
            {"label": "Veglie", "value": "C52475"},
            {"label": "Vernole", "value": "C52476"},
            {"label": "Vico del Gargano", "value": "C52477"},
            {"label": "Vieste", "value": "C52478"},
            {"label": "Vignacastrisi", "value": "C52479"},
            {"label": "Villa Castelli", "value": "C52480"},
            {"label": "Vitigliano", "value": "C52481"},
            {"label": "Volturara Appula", "value": "C52482"},
            {"label": "Volturino", "value": "C52483"},
            {"label": "Zapponeta", "value": "C52484"},
            {"label": "Zollino", "value": "C52485"}],
 "Apurimac": [{"label": "Abancay", "value": "C80667"},
              {"label": "Andahuaylas", "value": "C80668"},
              {"label": "Provincia de Abancay", "value": "C80669"},
              {"label": "Provincia de Andahuaylas", "value": "C80670"},
              {"label": "Provincia de Antabamba", "value": "C80671"},
              {"label": "Provincia de Aymaraes", "value": "C80672"},
              {"label": "Provincia de Cotabambas", "value": "C80673"},
              {"label": "Provincia de Grau", "value": "C80674"},
              {"label": "San Jeronimo", "value": "C80675"},
              {"label": "Talavera", "value": "C80676"}],
 "Aqaba Governorate": [{"label": "Aqaba", "value": "C64209"},
                       {"label": "Tala Bay", "value": "C64210"}],
 "Aracinovo Municipality": [{"label": "Arachinovo", "value": "C65355"}],
 "Arad County": [{"label": "Adea", "value": "C92270"},
                 {"label": "Agrisu Mare", "value": "C92271"},
                 {"label": "Almas", "value": "C92272"},
                 {"label": "Andrei Saguna", "value": "C92273"},
                 {"label": "Apateu", "value": "C92274"},
                 {"label": "Arad", "value": "C92275"},
                 {"label": "Archis", "value": "C92276"},
                 {"label": "Barsa", "value": "C92277"},
                 {"label": "Barzava", "value": "C92278"},
                 {"label": "Bata", "value": "C92279"},
                 {"label": "Beliu", "value": "C92280"},
                 {"label": "Birchis", "value": "C92281"},
                 {"label": "Bocsig", "value": "C92282"},
                 {"label": "Brazii", "value": "C92283"},
                 {"label": "Buteni", "value": "C92284"},
                 {"label": "Caporal Alexa", "value": "C92285"},
                 {"label": "Carand", "value": "C92286"},
                 {"label": "Cermei", "value": "C92287"},
                 {"label": "Cherelus", "value": "C92288"},
                 {"label": "Chesint", "value": "C92289"},
                 {"label": "Chier", "value": "C92290"},
                 {"label": "Chisindia", "value": "C92291"},
                 {"label": "Chisineu-Cris", "value": "C92292"},
                 {"label": "Cintei", "value": "C92293"},
                 {"label": "Comuna Almas", "value": "C92294"},
                 {"label": "Comuna Apateu", "value": "C92295"},
                 {"label": "Comuna Archis", "value": "C92296"},
                 {"label": "Comuna Barsa", "value": "C92297"},
                 {"label": "Comuna Barzava", "value": "C92298"},
                 {"label": "Comuna Bata", "value": "C92299"},
                 {"label": "Comuna Beliu", "value": "C92300"},
                 {"label": "Comuna Birchis", "value": "C92301"},
                 {"label": "Comuna Bocsig", "value": "C92302"},
                 {"label": "Comuna Brazii", "value": "C92303"},
                 {"label": "Comuna Buteni", "value": "C92304"},
                 {"label": "Comuna Carand", "value": "C92305"},
                 {"label": "Comuna Cermei", "value": "C92306"},
                 {"label": "Comuna Chisindia", "value": "C92307"},
                 {"label": "Comuna Conop", "value": "C92308"},
                 {"label": "Comuna Covasint", "value": "C92309"},
                 {"label": "Comuna Craiva", "value": "C92310"},
                 {"label": "Comuna Dezna", "value": "C92311"},
                 {"label": "Comuna Dieci", "value": "C92312"},
                 {"label": "Comuna Dorobanti", "value": "C92313"},
                 {"label": "Comuna Fantanele", "value": "C92314"},
                 {"label": "Comuna Felnac", "value": "C92315"},
                 {"label": "Comuna Frumuseni", "value": "C92316"},
                 {"label": "Comuna Ghioroc", "value": "C92317"},
                 {"label": "Comuna Graniceri", "value": "C92318"},
                 {"label": "Comuna Gurahont", "value": "C92319"},
                 {"label": "Comuna Halmagel", "value": "C92320"},
                 {"label": "Comuna Halmagiu", "value": "C92321"},
                 {"label": "Comuna Hasmas", "value": "C92322"},
                 {"label": "Comuna Ignesti", "value": "C92323"},
                 {"label": "Comuna Iratosu", "value": "C92324"},
                 {"label": "Comuna Livada", "value": "C92325"},
                 {"label": "Comuna Macea", "value": "C92326"},
                 {"label": "Comuna Misca", "value": "C92327"},
                 {"label": "Comuna Moneasa", "value": "C92328"},
                 {"label": "Comuna Olari", "value": "C92329"},
                 {"label": "Comuna Paulis", "value": "C92330"},
                 {"label": "Comuna Peregu Mare", "value": "C92331"},
                 {"label": "Comuna Petris", "value": "C92332"},
                 {"label": "Comuna Pilu", "value": "C92333"},
                 {"label": "Comuna Plescuta", "value": "C92334"},
                 {"label": "Comuna Sagu", "value": "C92335"},
                 {"label": "Comuna Savarsin", "value": "C92336"},
                 {"label": "Comuna Secusigiu", "value": "C92337"},
                 {"label": "Comuna Seitin", "value": "C92338"},
                 {"label": "Comuna Seleus", "value": "C92339"},
                 {"label": "Comuna Semlac", "value": "C92340"},
                 {"label": "Comuna Sepreus", "value": "C92341"},
                 {"label": "Comuna Sicula", "value": "C92342"},
                 {"label": "Comuna Silindia", "value": "C92343"},
                 {"label": "Comuna Simand", "value": "C92344"},
                 {"label": "Comuna Sintea Mare", "value": "C92345"},
                 {"label": "Comuna Siria", "value": "C92346"},
                 {"label": "Comuna Sistarovat", "value": "C92347"},
                 {"label": "Comuna Socodor", "value": "C92348"},
                 {"label": "Comuna Sofronea", "value": "C92349"},
                 {"label": "Comuna Tarnova", "value": "C92350"},
                 {"label": "Comuna Taut", "value": "C92351"},
                 {"label": "Comuna Ususau", "value": "C92352"},
                 {"label": "Comuna Varadia de Mures", "value": "C92353"},
                 {"label": "Comuna Varfurile", "value": "C92354"},
                 {"label": "Comuna Vinga", "value": "C92355"},
                 {"label": "Comuna Vladimirescu", "value": "C92356"},
                 {"label": "Comuna Zabrani", "value": "C92357"},
                 {"label": "Comuna Zadareni", "value": "C92358"},
                 {"label": "Comuna Zarand", "value": "C92359"},
                 {"label": "Comuna Zerind", "value": "C92360"},
                 {"label": "Comuna Zimandu Nou", "value": "C92361"},
                 {"label": "Conop", "value": "C92362"},
                 {"label": "Covasint", "value": "C92363"},
                 {"label": "Craiva", "value": "C92364"},
                 {"label": "Curtici", "value": "C92365"},
                 {"label": "Cuvin", "value": "C92366"},
                 {"label": "Dezna", "value": "C92367"},
                 {"label": "Dieci", "value": "C92368"},
                 {"label": "Dorgos", "value": "C92369"},
                 {"label": "Dorobanti", "value": "C92370"},
                 {"label": "Draut", "value": "C92371"},
                 {"label": "Fantanele", "value": "C92372"},
                 {"label": "Felnac", "value": "C92373"},
                 {"label": "Frumuseni", "value": "C92374"},
                 {"label": "Galsa", "value": "C92375"},
                 {"label": "Ghioroc", "value": "C92376"},
                 {"label": "Graniceri", "value": "C92377"},
                 {"label": "Gurahont", "value": "C92378"},
                 {"label": "Gurba", "value": "C92379"},
                 {"label": "Halmagel", "value": "C92380"},
                 {"label": "Halmagiu", "value": "C92381"},
                 {"label": "Hasmas", "value": "C92382"},
                 {"label": "Horia", "value": "C92383"},
                 {"label": "Ignesti", "value": "C92384"},
                 {"label": "Ineu", "value": "C92385"},
                 {"label": "Iratosu", "value": "C92386"},
                 {"label": "Lipova", "value": "C92387"},
                 {"label": "Livada", "value": "C92388"},
                 {"label": "Macea", "value": "C92389"},
                 {"label": "Maderat", "value": "C92390"},
                 {"label": "Mailat", "value": "C92391"},
                 {"label": "Manastur", "value": "C92392"},
                 {"label": "Mandruloc", "value": "C92393"},
                 {"label": "Masca", "value": "C92394"},
                 {"label": "Misca", "value": "C92395"},
                 {"label": "Moneasa", "value": "C92396"},
                 {"label": "Municipiul Arad", "value": "C92397"},
                 {"label": "Nadab", "value": "C92398"},
                 {"label": "Nadas", "value": "C92399"},
                 {"label": "Nadlac", "value": "C92400"},
                 {"label": "Neudorf", "value": "C92401"},
                 {"label": "Olari", "value": "C92402"},
                 {"label": "Oras Chisineu-Cris", "value": "C92403"},
                 {"label": "Oras Curtici", "value": "C92404"},
                 {"label": "Oras Ineu", "value": "C92405"},
                 {"label": "Oras Lipova", "value": "C92406"},
                 {"label": "Oras Nadlac", "value": "C92407"},
                 {"label": "Oras Pancota", "value": "C92408"},
                 {"label": "Oras Santana", "value": "C92409"},
                 {"label": "Oras Sebis", "value": "C92410"},
                 {"label": "Pancota", "value": "C92411"},
                 {"label": "Paulis", "value": "C92412"},
                 {"label": "Pecica", "value": "C92413"},
                 {"label": "Peregu Mare", "value": "C92414"},
                 {"label": "Peregu Mic", "value": "C92415"},
                 {"label": "Petris", "value": "C92416"},
                 {"label": "Pilu", "value": "C92417"},
                 {"label": "Plescuta", "value": "C92418"},
                 {"label": "Radna", "value": "C92419"},
                 {"label": "Sagu", "value": "C92420"},
                 {"label": "Sambateni", "value": "C92421"},
                 {"label": "Sanmartin", "value": "C92422"},
                 {"label": "Sanpetru German", "value": "C92423"},
                 {"label": "Santana", "value": "C92424"},
                 {"label": "Satu Mare", "value": "C92425"},
                 {"label": "Savarsin", "value": "C92426"},
                 {"label": "Sebis", "value": "C92427"},
                 {"label": "Secusigiu", "value": "C92428"},
                 {"label": "Seitin", "value": "C92429"},
                 {"label": "Seleus", "value": "C92430"},
                 {"label": "Semlac", "value": "C92431"},
                 {"label": "Sepreus", "value": "C92432"},
                 {"label": "Siclau", "value": "C92433"},
                 {"label": "Sicula", "value": "C92434"},
                 {"label": "Silindia", "value": "C92435"},
                 {"label": "Simand", "value": "C92436"},
                 {"label": "Sintea Mare", "value": "C92437"},
                 {"label": "Siria", "value": "C92438"},
                 {"label": "Sistarovat", "value": "C92439"},
                 {"label": "Socodor", "value": "C92440"},
                 {"label": "Sofronea", "value": "C92441"},
                 {"label": "Tarnova", "value": "C92442"},
                 {"label": "Taut", "value": "C92443"},
                 {"label": "Tipar", "value": "C92444"},
                 {"label": "Turnu", "value": "C92445"},
                 {"label": "Ususau", "value": "C92446"},
                 {"label": "Vanatori", "value": "C92447"},
                 {"label": "Varadia de Mures", "value": "C92448"},
                 {"label": "Varfurile", "value": "C92449"},
                 {"label": "Vinga", "value": "C92450"},
                 {"label": "Vladimirescu", "value": "C92451"},
                 {"label": "Zabrani", "value": "C92452"},
                 {"label": "Zadareni", "value": "C92453"},
                 {"label": "Zarand", "value": "C92454"},
                 {"label": "Zerind", "value": "C92455"},
                 {"label": "Zimandu Nou", "value": "C92456"}],
 "Aragatsotn Region": [{"label": "Agarakavan", "value": "C1546"},
                       {"label": "Aparan", "value": "C1547"},
                       {"label": "Aragats", "value": "C1548"},
                       {"label": "Arteni", "value": "C1549"},
                       {"label": "Ashnak", "value": "C1550"},
                       {"label": "Ashtarak", "value": "C1551"},
                       {"label": "Byurakan", "value": "C1552"},
                       {"label": "Hnaberd", "value": "C1553"},
                       {"label": "Karbi", "value": "C1554"},
                       {"label": "Kasakh", "value": "C1555"},
                       {"label": "Kosh", "value": "C1556"},
                       {"label": "Nor Yerznka", "value": "C1557"},
                       {"label": "Oshakan", "value": "C1558"},
                       {"label": "Sasunik", "value": "C1559"},
                       {"label": "Shenavan", "value": "C1560"},
                       {"label": "Talin", "value": "C1561"},
                       {"label": "Tsaghkahovit", "value": "C1562"},
                       {"label": "Ushi", "value": "C1563"},
                       {"label": "Voskevaz", "value": "C1564"},
                       {"label": "Zovuni", "value": "C1565"}],
 "Aragon": [{"label": "Ababuj", "value": "C109274"},
            {"label": "Abanto", "value": "C109275"},
            {"label": "Abiego", "value": "C109276"},
            {"label": "Abizanda", "value": "C109277"},
            {"label": "Adahuesca", "value": "C109278"},
            {"label": "Agon", "value": "C109279"},
            {"label": "Aguaron", "value": "C109280"},
            {"label": "Aguaton", "value": "C109281"},
            {"label": "Aguaviva", "value": "C109282"},
            {"label": "Aguero", "value": "C109283"},
            {"label": "Aguilar del Alfambra", "value": "C109284"},
            {"label": "Aguilon", "value": "C109285"},
            {"label": "Ainsa", "value": "C109286"},
            {"label": "Ainzon", "value": "C109287"},
            {"label": "Alacon", "value": "C109288"},
            {"label": "Aladren", "value": "C109289"},
            {"label": "Alagon", "value": "C109290"},
            {"label": "Alarba", "value": "C109291"},
            {"label": "Alba", "value": "C109292"},
            {"label": "Albalate de Cinca", "value": "C109293"},
            {"label": "Albalate del Arzobispo", "value": "C109294"},
            {"label": "Albalatillo", "value": "C109295"},
            {"label": "Albarracin", "value": "C109296"},
            {"label": "Albelda", "value": "C109297"},
            {"label": "Alberite de San Juan", "value": "C109298"},
            {"label": "Albero Alto", "value": "C109299"},
            {"label": "Albero Bajo", "value": "C109300"},
            {"label": "Alberuela de Tubo", "value": "C109301"},
            {"label": "Albeta", "value": "C109302"},
            {"label": "Alborge", "value": "C109303"},
            {"label": "Alcaine", "value": "C109304"},
            {"label": "Alcala de Ebro", "value": "C109305"},
            {"label": "Alcala de Gurrea", "value": "C109306"},
            {"label": "Alcala de la Selva", "value": "C109307"},
            {"label": "Alcala de Moncayo", "value": "C109308"},
            {"label": "Alcala del Obispo", "value": "C109309"},
            {"label": "Alcaniz", "value": "C109310"},
            {"label": "Alcolea de Cinca", "value": "C109311"},
            {"label": "Alconchel de Ariza", "value": "C109312"},
            {"label": "Alcorisa", "value": "C109313"},
            {"label": "Alcubierre", "value": "C109314"},
            {"label": "Aldehuela de Liestos", "value": "C109315"},
            {"label": "Alerre", "value": "C109316"},
            {"label": "Alfajarin", "value": "C109317"},
            {"label": "Alfambra", "value": "C109318"},
            {"label": "Alfamen", "value": "C109319"},
            {"label": "Alfantega", "value": "C109320"},
            {"label": "Alforque", "value": "C109321"},
            {"label": "Alhama de Aragon", "value": "C109322"},
            {"label": "Aliaga", "value": "C109323"},
            {"label": "Allepuz", "value": "C109324"},
            {"label": "Alloza", "value": "C109325"},
            {"label": "Allueva", "value": "C109326"},
            {"label": "Almochuel", "value": "C109327"},
            {"label": "Almohaja", "value": "C109328"},
            {"label": "Almonacid de la Cuba", "value": "C109329"},
            {"label": "Almonacid de la Sierra", "value": "C109330"},
            {"label": "Almozara", "value": "C109331"},
            {"label": "Almudevar", "value": "C109332"},
            {"label": "Almuniente", "value": "C109333"},
            {"label": "Alobras", "value": "C109334"},
            {"label": "Alpartir", "value": "C109335"},
            {"label": "Alpenes", "value": "C109336"},
            {"label": "Alquezar", "value": "C109337"},
            {"label": "Ambel", "value": "C109338"},
            {"label": "Anadon", "value": "C109339"},
            {"label": "Andorra", "value": "C109340"},
            {"label": "Anento", "value": "C109341"},
            {"label": "Angues", "value": "C109342"},
            {"label": "Anso", "value": "C109343"},
            {"label": "Antillon", "value": "C109344"},
            {"label": "Aragues del Puerto", "value": "C109345"},
            {"label": "Aranda de Moncayo", "value": "C109346"},
            {"label": "Arandiga", "value": "C109347"},
            {"label": "Arcos de las Salinas", "value": "C109348"},
            {"label": "Arenys de Lledo  Arens de Lledo", "value": "C109349"},
            {"label": "Argavieso", "value": "C109350"},
            {"label": "Argente", "value": "C109351"},
            {"label": "Arguis", "value": "C109352"},
            {"label": "Arino", "value": "C109353"},
            {"label": "Ariza", "value": "C109354"},
            {"label": "Artieda", "value": "C109355"},
            {"label": "Atea", "value": "C109356"},
            {"label": "Ateca", "value": "C109357"},
            {"label": "Ayerbe", "value": "C109358"},
            {"label": "Azara", "value": "C109359"},
            {"label": "Azlor", "value": "C109360"},
            {"label": "Azuara", "value": "C109361"},
            {"label": "Badenas", "value": "C109362"},
            {"label": "Badules", "value": "C109363"},
            {"label": "Baells", "value": "C109364"},
            {"label": "Baguena", "value": "C109365"},
            {"label": "Bagues", "value": "C109366"},
            {"label": "Bailo", "value": "C109367"},
            {"label": "Balconchan", "value": "C109368"},
            {"label": "Ballobar", "value": "C109369"},
            {"label": "Banastas", "value": "C109370"},
            {"label": "Banon", "value": "C109371"},
            {"label": "Barbastro", "value": "C109372"},
            {"label": "Barboles", "value": "C109373"},
            {"label": "Barbues", "value": "C109374"},
            {"label": "Barbunales", "value": "C109375"},
            {"label": "Barcabo", "value": "C109376"},
            {"label": "Bardallur", "value": "C109377"},
            {"label": "Barrachina", "value": "C109378"},
            {"label": "Bea", "value": "C109379"},
            {"label": "Belchite", "value": "C109380"},
            {"label": "Bello", "value": "C109381"},
            {"label": "Belver de Cinca", "value": "C109382"},
            {"label": "Benasque", "value": "C109383"},
            {"label": "Benavarri  Benabarre", "value": "C109384"},
            {"label": "Berbegal", "value": "C109385"},
            {"label": "Berdejo", "value": "C109386"},
            {"label": "Berge", "value": "C109387"},
            {"label": "Bezas", "value": "C109388"},
            {"label": "Biel", "value": "C109389"},
            {"label": "Bielsa", "value": "C109390"},
            {"label": "Bierge", "value": "C109391"},
            {"label": "Bijuesca", "value": "C109392"},
            {"label": "Binaced", "value": "C109393"},
            {"label": "Binefar", "value": "C109394"},
            {"label": "Biota", "value": "C109395"},
            {"label": "Bisaurri", "value": "C109396"},
            {"label": "Biscarrues", "value": "C109397"},
            {"label": "Bisimbre", "value": "C109398"},
            {"label": "Blancas", "value": "C109399"},
            {"label": "Blesa", "value": "C109400"},
            {"label": "Boltana", "value": "C109401"},
            {"label": "Bonansa", "value": "C109402"},
            {"label": "Boquineni", "value": "C109403"},
            {"label": "Borau", "value": "C109404"},
            {"label": "Bordalba", "value": "C109405"},
            {"label": "Bordon", "value": "C109406"},
            {"label": "Borja", "value": "C109407"},
            {"label": "Botorrita", "value": "C109408"},
            {"label": "Brea de Aragon", "value": "C109409"},
            {"label": "Bronchales", "value": "C109410"},
            {"label": "Broto", "value": "C109411"},
            {"label": "Bubierca", "value": "C109412"},
            {"label": "Buena", "value": "C109413"},
            {"label": "Bujaraloz", "value": "C109414"},
            {"label": "Bulbuente", "value": "C109415"},
            {"label": "Burbaguena", "value": "C109416"},
            {"label": "Bureta", "value": "C109417"},
            {"label": "Cabanas de Ebro", "value": "C109418"},
            {"label": "Cabolafuente", "value": "C109419"},
            {"label": "Cabra de Mora", "value": "C109420"},
            {"label": "Cadrete", "value": "C109421"},
            {"label": "Calaceite", "value": "C109422"},
            {"label": "Calamocha", "value": "C109423"},
            {"label": "Calanda", "value": "C109424"},
            {"label": "Calatayud", "value": "C109425"},
            {"label": "Calatorao", "value": "C109426"},
            {"label": "Calcena", "value": "C109427"},
            {"label": "Calmarza", "value": "C109428"},
            {"label": "Calomarde", "value": "C109429"},
            {"label": "Camanas", "value": "C109430"},
            {"label": "Camarena de la Sierra", "value": "C109431"},
            {"label": "Camarillas", "value": "C109432"},
            {"label": "Caminreal", "value": "C109433"},
            {"label": "Campillo de Aragon", "value": "C109434"},
            {"label": "Camporrells", "value": "C109435"},
            {"label": "Canada de Benatanduz", "value": "C109436"},
            {"label": "Canada Vellida", "value": "C109437"},
            {"label": "Candasnos", "value": "C109438"},
            {"label": "Canfranc", "value": "C109439"},
            {"label": "Canizar del Olivar", "value": "C109440"},
            {"label": "Cantavieja", "value": "C109441"},
            {"label": "Capdesaso", "value": "C109442"},
            {"label": "Capella", "value": "C109443"},
            {"label": "Carenas", "value": "C109444"},
            {"label": "Carinena", "value": "C109445"},
            {"label": "Casbas de Huesca", "value": "C109446"},
            {"label": "Cascante del Rio", "value": "C109447"},
            {"label": "Caspe", "value": "C109448"},
            {"label": "Castejon de Alarba", "value": "C109449"},
            {"label": "Castejon de las Armas", "value": "C109450"},
            {"label": "Castejon de Monegros", "value": "C109451"},
            {"label": "Castejon de Sos", "value": "C109452"},
            {"label": "Castejon de Tornos", "value": "C109453"},
            {"label": "Castejon de Valdejasa", "value": "C109454"},
            {"label": "Castejon del Puente", "value": "C109455"},
            {"label": "Castel de Cabra", "value": "C109456"},
            {"label": "Castelflorite", "value": "C109457"},
            {"label": "Castellote", "value": "C109458"},
            {"label": "Castelnou", "value": "C109459"},
            {"label": "Castelseras", "value": "C109460"},
            {"label": "Castiello de Jaca", "value": "C109461"},
            {"label": "Castigaleu", "value": "C109462"},
            {"label": "Castiliscar", "value": "C109463"},
            {"label": "Castillazuelo", "value": "C109464"},
            {"label": "Cedrillas", "value": "C109465"},
            {"label": "Cella", "value": "C109466"},
            {"label": "Cervera de la Canada", "value": "C109467"},
            {"label": "Cerveruela", "value": "C109468"},
            {"label": "Cetina", "value": "C109469"},
            {"label": "Chalamera", "value": "C109470"},
            {"label": "Chia", "value": "C109471"},
            {"label": "Chimillas", "value": "C109472"},
            {"label": "Chiprana", "value": "C109473"},
            {"label": "Chodes", "value": "C109474"},
            {"label": "Cimballa", "value": "C109475"},
            {"label": "Cinco Olivas", "value": "C109476"},
            {"label": "Clares de Ribota", "value": "C109477"},
            {"label": "Codos", "value": "C109478"},
            {"label": "Colungo", "value": "C109479"},
            {"label": "Contamina", "value": "C109480"},
            {"label": "Corbalan", "value": "C109481"},
            {"label": "Cortes de Aragon", "value": "C109482"},
            {"label": "Cosa", "value": "C109483"},
            {"label": "Cosuenda", "value": "C109484"},
            {"label": "Crivillen", "value": "C109485"},
            {"label": "Cuarte de Huerva", "value": "C109486"},
            {"label": "Cubel", "value": "C109487"},
            {"label": "Cubla", "value": "C109488"},
            {"label": "Cucalon", "value": "C109489"},
            {"label": "Cuevas de Almuden", "value": "C109490"},
            {"label": "Cuevas Labradas", "value": "C109491"},
            {"label": "Daroca", "value": "C109492"},
            {"label": "Delicias", "value": "C109493"},
            {"label": "Echo", "value": "C109494"},
            {"label": "Ejea de los Caballeros", "value": "C109495"},
            {"label": "Ejulve", "value": "C109496"},
            {"label": "El Burgo de Ebro", "value": "C109497"},
            {"label": "El Castellar", "value": "C109498"},
            {"label": "el Torrico  Altorricon", "value": "C109499"},
            {"label": "Embid de Ariza", "value": "C109500"},
            {"label": "Encinacorba", "value": "C109501"},
            {"label": "epila", "value": "C109502"},
            {"label": "Erla", "value": "C109503"},
            {"label": "Escatron", "value": "C109504"},
            {"label": "Escorihuela", "value": "C109505"},
            {"label": "Escucha", "value": "C109506"},
            {"label": "Esplus", "value": "C109507"},
            {"label": "Estada", "value": "C109508"},
            {"label": "Estadilla", "value": "C109509"},
            {"label": "Estercuel", "value": "C109510"},
            {"label": "Fabara", "value": "C109511"},
            {"label": "Fago", "value": "C109512"},
            {"label": "Farlete", "value": "C109513"},
            {"label": "Fayon", "value": "C109514"},
            {"label": "Ferreruela de Huerva", "value": "C109515"},
            {"label": "Figueruelas", "value": "C109516"},
            {"label": "Fiscal", "value": "C109517"},
            {"label": "Fombuena", "value": "C109518"},
            {"label": "Fonfria", "value": "C109519"},
            {"label": "Fonz", "value": "C109520"},
            {"label": "Formiche Alto", "value": "C109521"},
            {"label": "Fornoles", "value": "C109522"},
            {"label": "Fortanete", "value": "C109523"},
            {"label": "Foz-Calanda", "value": "C109524"},
            {"label": "Fraga", "value": "C109525"},
            {"label": "Frescano", "value": "C109526"},
            {"label": "Frias de Albarracin", "value": "C109527"},
            {"label": "Fuendejalon", "value": "C109528"},
            {"label": "Fuendetodos", "value": "C109529"},
            {"label": "Fuenferrada", "value": "C109530"},
            {"label": "Fuentes Calientes", "value": "C109531"},
            {"label": "Fuentes Claras", "value": "C109532"},
            {"label": "Fuentes de Ebro", "value": "C109533"},
            {"label": "Fuentes de Jiloca", "value": "C109534"},
            {"label": "Fuentes de Rubielos", "value": "C109535"},
            {"label": "Fuentespalda", "value": "C109536"},
            {"label": "Gallocanta", "value": "C109537"},
            {"label": "Gallur", "value": "C109538"},
            {"label": "Galve", "value": "C109539"},
            {"label": "Gargallo", "value": "C109540"},
            {"label": "Gea de Albarracin", "value": "C109541"},
            {"label": "Gelsa", "value": "C109542"},
            {"label": "Gistain", "value": "C109543"},
            {"label": "Godojos", "value": "C109544"},
            {"label": "Gotor", "value": "C109545"},
            {"label": "Granen", "value": "C109546"},
            {"label": "Graus", "value": "C109547"},
            {"label": "Griegos", "value": "C109548"},
            {"label": "Grisel", "value": "C109549"},
            {"label": "Grisen", "value": "C109550"},
            {"label": "Guadalaviar", "value": "C109551"},
            {"label": "Gudar", "value": "C109552"},
            {"label": "Gurrea de Gallego", "value": "C109553"},
            {"label": "Herrera de los Navarros", "value": "C109554"},
            {"label": "Hijar", "value": "C109555"},
            {"label": "Hinojosa de Jarque", "value": "C109556"},
            {"label": "Hoz de Jaca", "value": "C109557"},
            {"label": "Huerto", "value": "C109558"},
            {"label": "Huesa del Comun", "value": "C109559"},
            {"label": "Huesca", "value": "C109560"},
            {"label": "Ibdes", "value": "C109561"},
            {"label": "Ibieca", "value": "C109562"},
            {"label": "Igries", "value": "C109563"},
            {"label": "Ilche", "value": "C109564"},
            {"label": "Illueca", "value": "C109565"},
            {"label": "Isuerre", "value": "C109566"},
            {"label": "Jabaloyas", "value": "C109567"},
            {"label": "Jaca", "value": "C109568"},
            {"label": "Jaraba", "value": "C109569"},
            {"label": "Jarque", "value": "C109570"},
            {"label": "Jarque de la Val", "value": "C109571"},
            {"label": "Jasa", "value": "C109572"},
            {"label": "Jatiel", "value": "C109573"},
            {"label": "Jaulin", "value": "C109574"},
            {"label": "Jorcas", "value": "C109575"},
            {"label": "Josa", "value": "C109576"},
            {"label": "La Almunia de Dona Godina", "value": "C109577"},
            {"label": "La Ginebrosa", "value": "C109578"},
            {"label": "La Iglesuela del Cid", "value": "C109579"},
            {"label": "Labuerda", "value": "C109580"},
            {"label": "Lagata", "value": "C109581"},
            {"label": "Lagueruela", "value": "C109582"},
            {"label": "Laluenga", "value": "C109583"},
            {"label": "Lalueza", "value": "C109584"},
            {"label": "Lanaja", "value": "C109585"},
            {"label": "Langa del Castillo", "value": "C109586"},
            {"label": "Lanzuela", "value": "C109587"},
            {"label": "Laperdiguera", "value": "C109588"},
            {"label": "Lascuarre", "value": "C109589"},
            {"label": "Laspaules", "value": "C109590"},
            {"label": "Laspuna", "value": "C109591"},
            {"label": "Layana", "value": "C109592"},
            {"label": "Lecera", "value": "C109593"},
            {"label": "Lechon", "value": "C109594"},
            {"label": "Lecinena", "value": "C109595"},
            {"label": "Letux", "value": "C109596"},
            {"label": "Libros", "value": "C109597"},
            {"label": "Lidon", "value": "C109598"},
            {"label": "Linares de Mora", "value": "C109599"},
            {"label": "Litago", "value": "C109600"},
            {"label": "Lituenigo", "value": "C109601"},
            {"label": "Lledo", "value": "C109602"},
            {"label": "Loarre", "value": "C109603"},
            {"label": "Lobera de Onsella", "value": "C109604"},
            {"label": "Longares", "value": "C109605"},
            {"label": "Longas", "value": "C109606"},
            {"label": "Loporzano", "value": "C109607"},
            {"label": "Loscorrales", "value": "C109608"},
            {"label": "Loscos", "value": "C109609"},
            {"label": "Lucena de Jalon", "value": "C109610"},
            {"label": "Luceni", "value": "C109611"},
            {"label": "Luesia", "value": "C109612"},
            {"label": "Luesma", "value": "C109613"},
            {"label": "Lumpiaque", "value": "C109614"},
            {"label": "Luna", "value": "C109615"},
            {"label": "Maella", "value": "C109616"},
            {"label": "Magallon", "value": "C109617"},
            {"label": "Maicas", "value": "C109618"},
            {"label": "Mainar", "value": "C109619"},
            {"label": "Malanquilla", "value": "C109620"},
            {"label": "Malejan", "value": "C109621"},
            {"label": "Mallen", "value": "C109622"},
            {"label": "Malon", "value": "C109623"},
            {"label": "Maluenda", "value": "C109624"},
            {"label": "Manchones", "value": "C109625"},
            {"label": "Manzanera", "value": "C109626"},
            {"label": "Mara", "value": "C109627"},
            {"label": "Maria de Huerva", "value": "C109628"},
            {"label": "Marracos", "value": "C109629"},
            {"label": "Martin del Rio", "value": "C109630"},
            {"label": "Mas de las Matas", "value": "C109631"},
            {"label": "Mazaleon", "value": "C109632"},
            {"label": "Mequinensa  Mequinenza", "value": "C109633"},
            {"label": "Mesones de Isuela", "value": "C109634"},
            {"label": "Mezalocha", "value": "C109635"},
            {"label": "Mezquita de Jarque", "value": "C109636"},
            {"label": "Mianos", "value": "C109637"},
            {"label": "Mirambel", "value": "C109638"},
            {"label": "Molinos", "value": "C109639"},
            {"label": "Monegrillo", "value": "C109640"},
            {"label": "Moneva", "value": "C109641"},
            {"label": "Monforte de Moyuela", "value": "C109642"},
            {"label": "Monreal de Ariza", "value": "C109643"},
            {"label": "Monreal del Campo", "value": "C109644"},
            {"label": "Monroyo", "value": "C109645"},
            {"label": "Montalban", "value": "C109646"},
            {"label": "Monteagudo del Castillo", "value": "C109647"},
            {"label": "Montecanal", "value": "C109648"},
            {"label": "Monterde", "value": "C109649"},
            {"label": "Monterde de Albarracin", "value": "C109650"},
            {"label": "Monton", "value": "C109651"},
            {"label": "Monzon", "value": "C109652"},
            {"label": "Mora de Rubielos", "value": "C109653"},
            {"label": "Morata de Jalon", "value": "C109654"},
            {"label": "Morata de Jiloca", "value": "C109655"},
            {"label": "Mores", "value": "C109656"},
            {"label": "Moros", "value": "C109657"},
            {"label": "Moscardon", "value": "C109658"},
            {"label": "Mosqueruela", "value": "C109659"},
            {"label": "Moyuela", "value": "C109660"},
            {"label": "Mozota", "value": "C109661"},
            {"label": "Muel", "value": "C109662"},
            {"label": "Munebrega", "value": "C109663"},
            {"label": "Muniesa", "value": "C109664"},
            {"label": "Murero", "value": "C109665"},
            {"label": "Naval", "value": "C109666"},
            {"label": "Navardun", "value": "C109667"},
            {"label": "Niguella", "value": "C109668"},
            {"label": "Nogueras", "value": "C109669"},
            {"label": "Nogueruelas", "value": "C109670"},
            {"label": "Nombrevilla", "value": "C109671"},
            {"label": "Nonaspe", "value": "C109672"},
            {"label": "Novales", "value": "C109673"},
            {"label": "Novallas", "value": "C109674"},
            {"label": "Novillas", "value": "C109675"},
            {"label": "Nueno", "value": "C109676"},
            {"label": "Nuevalos", "value": "C109677"},
            {"label": "Nuez de Ebro", "value": "C109678"},
            {"label": "Obon", "value": "C109679"},
            {"label": "Odon", "value": "C109680"},
            {"label": "Ojos Negros", "value": "C109681"},
            {"label": "Olba", "value": "C109682"},
            {"label": "Oliete", "value": "C109683"},
            {"label": "Oliver-Valdefierro", "value": "C109684"},
            {"label": "Olves", "value": "C109685"},
            {"label": "Ontinena", "value": "C109686"},
            {"label": "Orcajo", "value": "C109687"},
            {"label": "Orera", "value": "C109688"},
            {"label": "Ores", "value": "C109689"},
            {"label": "Orihuela del Tremedal", "value": "C109690"},
            {"label": "Orrios", "value": "C109691"},
            {"label": "Oseja", "value": "C109692"},
            {"label": "Palo", "value": "C109693"},
            {"label": "Palomar de Arroyos", "value": "C109694"},
            {"label": "Pancrudo", "value": "C109695"},
            {"label": "Paniza", "value": "C109696"},
            {"label": "Paracuellos de Jiloca", "value": "C109697"},
            {"label": "Paracuellos de la Ribera", "value": "C109698"},
            {"label": "Pedrola", "value": "C109699"},
            {"label": "Penalba", "value": "C109700"},
            {"label": "Peracense", "value": "C109701"},
            {"label": "Peralejos", "value": "C109702"},
            {"label": "Perales del Alfambra", "value": "C109703"},
            {"label": "Peraltilla", "value": "C109704"},
            {"label": "Perdiguera", "value": "C109705"},
            {"label": "Pertusa", "value": "C109706"},
            {"label": "Piedratajada", "value": "C109707"},
            {"label": "Pina de Ebro", "value": "C109708"},
            {"label": "Pinseque", "value": "C109709"},
            {"label": "Piraces", "value": "C109710"},
            {"label": "Pitarque", "value": "C109711"},
            {"label": "Plan", "value": "C109712"},
            {"label": "Plasencia de Jalon", "value": "C109713"},
            {"label": "Pleitas", "value": "C109714"},
            {"label": "Plenas", "value": "C109715"},
            {"label": "Plou", "value": "C109716"},
            {"label": "Polenino", "value": "C109717"},
            {"label": "Pomer", "value": "C109718"},
            {"label": "Pozan de Vero", "value": "C109719"},
            {"label": "Pozondon", "value": "C109720"},
            {"label": "Pozuel de Ariza", "value": "C109721"},
            {"label": "Pozuel del Campo", "value": "C109722"},
            {"label": "Pozuelo de Aragon", "value": "C109723"},
            {"label": "Pradilla de Ebro", "value": "C109724"},
            {"label": "Provincia de Huesca", "value": "C109725"},
            {"label": "Provincia de Teruel", "value": "C109726"},
            {"label": "Provincia de Zaragoza", "value": "C109727"},
            {"label": "Puebla de Alborton", "value": "C109728"},
            {"label": "Puebla de Alfinden", "value": "C109729"},
            {"label": "Puente la Reina de Jaca", "value": "C109730"},
            {"label": "Puertolas", "value": "C109731"},
            {"label": "Puertomingalvo", "value": "C109732"},
            {"label": "Pueyo de Santa Cruz", "value": "C109733"},
            {"label": "Purujosa", "value": "C109734"},
            {"label": "Quicena", "value": "C109735"},
            {"label": "Quinto", "value": "C109736"},
            {"label": "Rafales", "value": "C109737"},
            {"label": "Remolinos", "value": "C109738"},
            {"label": "Retascon", "value": "C109739"},
            {"label": "Ricla", "value": "C109740"},
            {"label": "Rillo", "value": "C109741"},
            {"label": "Riodeva", "value": "C109742"},
            {"label": "Robres", "value": "C109743"},
            {"label": "Rodenas", "value": "C109744"},
            {"label": "Romanos", "value": "C109745"},
            {"label": "Royuela", "value": "C109746"},
            {"label": "Rubiales", "value": "C109747"},
            {"label": "Rubielos de la Cerida", "value": "C109748"},
            {"label": "Rubielos de Mora", "value": "C109749"},
            {"label": "Ruesca", "value": "C109750"},
            {"label": "Sabinanigo", "value": "C109751"},
            {"label": "Sadaba", "value": "C109752"},
            {"label": "Sahun", "value": "C109753"},
            {"label": "Salas Altas", "value": "C109754"},
            {"label": "Salas Bajas", "value": "C109755"},
            {"label": "Salcedillo", "value": "C109756"},
            {"label": "Saldon", "value": "C109757"},
            {"label": "Salillas", "value": "C109758"},
            {"label": "Salillas de Jalon", "value": "C109759"},
            {"label": "Sallent de Gallego", "value": "C109760"},
            {"label": "Salvatierra de Esca", "value": "C109761"},
            {"label": "Samper de Calanda", "value": "C109762"},
            {"label": "Samper del Salz", "value": "C109763"},
            {"label": "San Agustin", "value": "C109764"},
            {"label": "San Martin del Rio", "value": "C109765"},
            {"label": "San Mateo de Gallego", "value": "C109766"},
            {"label": "Sangarren", "value": "C109767"},
            {"label": "Santa Cruz de Grio", "value": "C109768"},
            {"label": "Santa Cruz de la Seros", "value": "C109769"},
            {"label": "Santa Cruz de Moncayo", "value": "C109770"},
            {"label": "Santa Cruz de Nogueras", "value": "C109771"},
            {"label": "Santa Eulalia", "value": "C109772"},
            {"label": "Santa Eulalia de Gallego", "value": "C109773"},
            {"label": "Santed", "value": "C109774"},
            {"label": "Sarinena", "value": "C109775"},
            {"label": "Sarrion", "value": "C109776"},
            {"label": "Sastago", "value": "C109777"},
            {"label": "Secastilla", "value": "C109778"},
            {"label": "Sediles", "value": "C109779"},
            {"label": "Segura de los Banos", "value": "C109780"},
            {"label": "Seira", "value": "C109781"},
            {"label": "Senes de Alcubierre", "value": "C109782"},
            {"label": "Seno", "value": "C109783"},
            {"label": "Sesa", "value": "C109784"},
            {"label": "Sestrica", "value": "C109785"},
            {"label": "Sesue", "value": "C109786"},
            {"label": "Sierra de Luna", "value": "C109787"},
            {"label": "Sietamo", "value": "C109788"},
            {"label": "Sigues", "value": "C109789"},
            {"label": "Singra", "value": "C109790"},
            {"label": "Sisamon", "value": "C109791"},
            {"label": "Sobradiel", "value": "C109792"},
            {"label": "Sos del Rey Catolico", "value": "C109793"},
            {"label": "Tabuenca", "value": "C109794"},
            {"label": "Talamantes", "value": "C109795"},
            {"label": "Tamarit de Llitera  Tamarite de Litera",
             "value": "C109796"},
            {"label": "Tarazona", "value": "C109797"},
            {"label": "Tardienta", "value": "C109798"},
            {"label": "Tauste", "value": "C109799"},
            {"label": "Terrer", "value": "C109800"},
            {"label": "Terriente", "value": "C109801"},
            {"label": "Teruel", "value": "C109802"},
            {"label": "Tierz", "value": "C109803"},
            {"label": "Tobed", "value": "C109804"},
            {"label": "Tormon", "value": "C109805"},
            {"label": "Tornos", "value": "C109806"},
            {"label": "Torralba de Aragon", "value": "C109807"},
            {"label": "Torralba de los Frailes", "value": "C109808"},
            {"label": "Torralba de los Sisones", "value": "C109809"},
            {"label": "Torralba de Ribota", "value": "C109810"},
            {"label": "Torralbilla", "value": "C109811"},
            {"label": "Torre de Arcas", "value": "C109812"},
            {"label": "Torre de las Arcas", "value": "C109813"},
            {"label": "Torre del Compte", "value": "C109814"},
            {"label": "Torre los Negros", "value": "C109815"},
            {"label": "Torrecilla de Alcaniz", "value": "C109816"},
            {"label": "Torrecilla del Rebollar", "value": "C109817"},
            {"label": "Torrehermosa", "value": "C109818"},
            {"label": "Torrelapaja", "value": "C109819"},
            {"label": "Torrellas", "value": "C109820"},
            {"label": "Torres de Albarracin", "value": "C109821"},
            {"label": "Torres de Alcanadre", "value": "C109822"},
            {"label": "Torres de Barbues", "value": "C109823"},
            {"label": "Torres de Berrellen", "value": "C109824"},
            {"label": "Torrevelilla", "value": "C109825"},
            {"label": "Torrijas", "value": "C109826"},
            {"label": "Torrijo de la Canada", "value": "C109827"},
            {"label": "Torrijo del Campo", "value": "C109828"},
            {"label": "Tosos", "value": "C109829"},
            {"label": "Tramacastiel", "value": "C109830"},
            {"label": "Tramacastilla", "value": "C109831"},
            {"label": "Tramaced", "value": "C109832"},
            {"label": "Trasmoz", "value": "C109833"},
            {"label": "Trasobares", "value": "C109834"},
            {"label": "Tronchon", "value": "C109835"},
            {"label": "Uncastillo", "value": "C109836"},
            {"label": "Undues de Lerda", "value": "C109837"},
            {"label": "Urrea de Jalon", "value": "C109838"},
            {"label": "Urries", "value": "C109839"},
            {"label": "Used", "value": "C109840"},
            {"label": "Utebo", "value": "C109841"},
            {"label": "Utrillas", "value": "C109842"},
            {"label": "Val de San Martin", "value": "C109843"},
            {"label": "Valacloche", "value": "C109844"},
            {"label": "Valbona", "value": "C109845"},
            {"label": "Valdealgorfa", "value": "C109846"},
            {"label": "Valdecuenca", "value": "C109847"},
            {"label": "Valdehorna", "value": "C109848"},
            {"label": "Valdelinares", "value": "C109849"},
            {"label": "Valdeltormo", "value": "C109850"},
            {"label": "Valderrobres", "value": "C109851"},
            {"label": "Valfarta", "value": "C109852"},
            {"label": "Valjunquera", "value": "C109853"},
            {"label": "Valmadrid", "value": "C109854"},
            {"label": "Valpalmas", "value": "C109855"},
            {"label": "Valtorres", "value": "C109856"},
            {"label": "Veguillas de la Sierra", "value": "C109857"},
            {"label": "Velilla de Ebro", "value": "C109858"},
            {"label": "Velilla de Jiloca", "value": "C109859"},
            {"label": "Vera de Moncayo", "value": "C109860"},
            {"label": "Vierlas", "value": "C109861"},
            {"label": "Villadoz", "value": "C109862"},
            {"label": "Villafeliche", "value": "C109863"},
            {"label": "Villafranca de Ebro", "value": "C109864"},
            {"label": "Villafranca del Campo", "value": "C109865"},
            {"label": "Villahermosa del Campo", "value": "C109866"},
            {"label": "Villalba de Perejil", "value": "C109867"},
            {"label": "Villalengua", "value": "C109868"},
            {"label": "Villamayor de Gallego", "value": "C109869"},
            {"label": "Villanua", "value": "C109870"},
            {"label": "Villanueva de Gallego", "value": "C109871"},
            {"label": "Villanueva de Jiloca", "value": "C109872"},
            {"label": "Villanueva de Sigena", "value": "C109873"},
            {"label": "Villanueva del Rebollar de la Sierra",
             "value": "C109874"},
            {"label": "Villar de los Navarros", "value": "C109875"},
            {"label": "Villar del Cobo", "value": "C109876"},
            {"label": "Villar del Salz", "value": "C109877"},
            {"label": "Villarluengo", "value": "C109878"},
            {"label": "Villarquemado", "value": "C109879"},
            {"label": "Villarreal de Huerva", "value": "C109880"},
            {"label": "Villarroya de la Sierra", "value": "C109881"},
            {"label": "Villarroya de los Pinares", "value": "C109882"},
            {"label": "Villarroya del Campo", "value": "C109883"},
            {"label": "Villastar", "value": "C109884"},
            {"label": "Villel", "value": "C109885"},
            {"label": "Visiedo", "value": "C109886"},
            {"label": "Vistabella", "value": "C109887"},
            {"label": "Vivel del Rio Martin", "value": "C109888"},
            {"label": "Yebra de Basa", "value": "C109889"},
            {"label": "Yesero", "value": "C109890"},
            {"label": "Zaidin", "value": "C109891"},
            {"label": "Zaragoza", "value": "C109892"},
            {"label": "Zuera", "value": "C109893"}],
 "Aragua": [{"label": "Cagua", "value": "C147139"},
            {"label": "El Limon", "value": "C147140"},
            {"label": "La Victoria", "value": "C147141"},
            {"label": "Las Tejerias", "value": "C147142"},
            {"label": "Maracay", "value": "C147143"},
            {"label": "Palo Negro", "value": "C147144"},
            {"label": "San Mateo", "value": "C147145"},
            {"label": "Santa Rita", "value": "C147146"},
            {"label": "Turmero", "value": "C147147"},
            {"label": "Villa de Cura", "value": "C147148"}],
 "Ararat Province": [{"label": "Abovyan", "value": "C1566"},
                     {"label": "Aralez", "value": "C1567"},
                     {"label": "Ararat", "value": "C1568"},
                     {"label": "Arevabuyr", "value": "C1569"},
                     {"label": "Arevshat", "value": "C1570"},
                     {"label": "Armash", "value": "C1571"},
                     {"label": "Artashat", "value": "C1572"},
                     {"label": "Avshar", "value": "C1573"},
                     {"label": "Aygavan", "value": "C1574"},
                     {"label": "Aygepat", "value": "C1575"},
                     {"label": "Aygestan", "value": "C1576"},
                     {"label": "Aygezard", "value": "C1577"},
                     {"label": "Bardzrashen", "value": "C1578"},
                     {"label": "Berkanush", "value": "C1579"},
                     {"label": "Burastan", "value": "C1580"},
                     {"label": "Byuravan", "value": "C1581"},
                     {"label": "Dalar", "value": "C1582"},
                     {"label": "Darakert", "value": "C1583"},
                     {"label": "Dashtavan", "value": "C1584"},
                     {"label": "Dimitrov", "value": "C1585"},
                     {"label": "Dvin", "value": "C1586"},
                     {"label": "Getazat", "value": "C1587"},
                     {"label": "Ghukasavan", "value": "C1588"},
                     {"label": "Goravan", "value": "C1589"},
                     {"label": "Hayanist", "value": "C1590"},
                     {"label": "Hovtashat", "value": "C1591"},
                     {"label": "Hovtashen", "value": "C1592"},
                     {"label": "Jrahovit", "value": "C1593"},
                     {"label": "Lusarrat", "value": "C1594"},
                     {"label": "Marmarashen", "value": "C1595"},
                     {"label": "Masis", "value": "C1596"},
                     {"label": "Mrganush", "value": "C1597"},
                     {"label": "Mrgavan", "value": "C1598"},
                     {"label": "Mrgavet", "value": "C1599"},
                     {"label": "Nizami", "value": "C1600"},
                     {"label": "Norabats", "value": "C1601"},
                     {"label": "Noramarg", "value": "C1602"},
                     {"label": "Norashen", "value": "C1603"},
                     {"label": "Noyakert", "value": "C1604"},
                     {"label": "Nshavan", "value": "C1605"},
                     {"label": "Sayat-Nova", "value": "C1606"},
                     {"label": "Shahumyan", "value": "C1607"},
                     {"label": "Sis", "value": "C1608"},
                     {"label": "Sisavan", "value": "C1609"},
                     {"label": "Surenavan", "value": "C1610"},
                     {"label": "Vedi", "value": "C1611"},
                     {"label": "Verin Artashat", "value": "C1612"},
                     {"label": "Verin Dvin", "value": "C1613"},
                     {"label": "Vosketap", "value": "C1614"},
                     {"label": "Vostan", "value": "C1615"},
                     {"label": "Yeghegnavan", "value": "C1616"},
                     {"label": "Zangakatun", "value": "C1617"},
                     {"label": "Zorak", "value": "C1618"}],
 "Arauca": [{"label": "Arauca", "value": "C19671"},
            {"label": "Arauquita", "value": "C19672"},
            {"label": "Cravo Norte", "value": "C19673"},
            {"label": "Fortul", "value": "C19674"},
            {"label": "Puerto Rondon", "value": "C19675"},
            {"label": "Saravena", "value": "C19676"},
            {"label": "Tame", "value": "C19677"}],
 "Archipielago de San Andres": [{"label": "Providencia", "value": "C19678"},
                                {"label": "San Andres", "value": "C19679"}],
 "Ardabil": [{"label": "Ardabil", "value": "C50050"},
             {"label": "Bileh Savar", "value": "C50051"},
             {"label": "Khalkhal", "value": "C50052"},
             {"label": "Omidcheh", "value": "C50053"},
             {"label": "Parsabad", "value": "C50054"},
             {"label": "Shahrestan-e Ardabil", "value": "C50055"},
             {"label": "Shahrestan-e Bileh Savar", "value": "C50056"},
             {"label": "Shahrestan-e Germi", "value": "C50057"},
             {"label": "Shahrestan-e Khalkhal", "value": "C50058"},
             {"label": "Shahrestan-e Kowsar", "value": "C50059"},
             {"label": "Shahrestan-e Meshgin Shahr", "value": "C50060"},
             {"label": "Shahrestan-e Namin", "value": "C50061"},
             {"label": "Shahrestan-e Nir", "value": "C50062"},
             {"label": "Shahrestan-e Parsabad", "value": "C50063"},
             {"label": "Shahrestan-e Sareyn", "value": "C50064"}],
 "Ardahan": [{"label": "Ardahan", "value": "C119955"},
             {"label": "Cildir Ilcesi", "value": "C119956"},
             {"label": "Damal", "value": "C119957"},
             {"label": "Gole", "value": "C119958"},
             {"label": "Hanak", "value": "C119959"},
             {"label": "Posof", "value": "C119960"}],
 "Arequipa": [{"label": "Acari", "value": "C80677"},
              {"label": "Arequipa", "value": "C80678"},
              {"label": "Camana", "value": "C80679"},
              {"label": "Ccolo", "value": "C80680"},
              {"label": "Chivay", "value": "C80681"},
              {"label": "Cocachacra", "value": "C80682"},
              {"label": "Cotahuasi", "value": "C80683"},
              {"label": "Huarancante", "value": "C80684"},
              {"label": "Huarichancara", "value": "C80685"},
              {"label": "Jatun Orcochiri", "value": "C80686"},
              {"label": "Jayune", "value": "C80687"},
              {"label": "Llongasora", "value": "C80688"},
              {"label": "Lluta", "value": "C80689"},
              {"label": "Mollendo", "value": "C80690"},
              {"label": "Orcopampa", "value": "C80691"},
              {"label": "Provincia de Arequipa", "value": "C80692"},
              {"label": "Provincia de Camana", "value": "C80693"},
              {"label": "Provincia de Caraveli", "value": "C80694"},
              {"label": "Provincia de Castilla", "value": "C80695"},
              {"label": "Provincia de Caylloma", "value": "C80696"},
              {"label": "Provincia de Condesuyos", "value": "C80697"},
              {"label": "Provincia de Islay", "value": "C80698"},
              {"label": "Provincia de La Union", "value": "C80699"},
              {"label": "Pucara", "value": "C80700"},
              {"label": "Punta de Bombon", "value": "C80701"},
              {"label": "Vizcachane", "value": "C80702"},
              {"label": "Yura", "value": "C80703"}],
 "Arges": [{"label": "Albesti", "value": "C92457"},
           {"label": "Albestii Pamanteni", "value": "C92458"},
           {"label": "Albestii Ungureni", "value": "C92459"},
           {"label": "Albota", "value": "C92460"},
           {"label": "Albota de Jos", "value": "C92461"},
           {"label": "Aninoasa", "value": "C92462"},
           {"label": "Arefu", "value": "C92463"},
           {"label": "Argeselu", "value": "C92464"},
           {"label": "Babana", "value": "C92465"},
           {"label": "Badesti", "value": "C92466"},
           {"label": "Baiculesti", "value": "C92467"},
           {"label": "Bajesti", "value": "C92468"},
           {"label": "Balilesti", "value": "C92469"},
           {"label": "Barasti", "value": "C92470"},
           {"label": "Barla", "value": "C92471"},
           {"label": "Barlogu", "value": "C92472"},
           {"label": "Bascov", "value": "C92473"},
           {"label": "Beleti", "value": "C92474"},
           {"label": "Berevoesti", "value": "C92475"},
           {"label": "Bogati", "value": "C92476"},
           {"label": "Boteni", "value": "C92477"},
           {"label": "Botesti", "value": "C92478"},
           {"label": "Bradu", "value": "C92479"},
           {"label": "Bradulet", "value": "C92480"},
           {"label": "Bucsenesti", "value": "C92481"},
           {"label": "Bucsenesti-Lotasi", "value": "C92482"},
           {"label": "Budeasa Mica", "value": "C92483"},
           {"label": "Bughea de Jos", "value": "C92484"},
           {"label": "Bughea de Sus", "value": "C92485"},
           {"label": "Buzoesti", "value": "C92486"},
           {"label": "Caldararu", "value": "C92487"},
           {"label": "Calinesti", "value": "C92488"},
           {"label": "Campulung", "value": "C92489"},
           {"label": "Capatanenii Pamanteni", "value": "C92490"},
           {"label": "Capu Piscului", "value": "C92491"},
           {"label": "Cateasca", "value": "C92492"},
           {"label": "Ceparii Pamanteni", "value": "C92493"},
           {"label": "Cetateni", "value": "C92494"},
           {"label": "Cicanesti", "value": "C92495"},
           {"label": "Ciofrangeni", "value": "C92496"},
           {"label": "Ciomagesti", "value": "C92497"},
           {"label": "Ciulnita", "value": "C92498"},
           {"label": "Cocu", "value": "C92499"},
           {"label": "Colibasi", "value": "C92500"},
           {"label": "Comuna Albestii de Arges", "value": "C92501"},
           {"label": "Comuna Albestii de Muscel", "value": "C92502"},
           {"label": "Comuna Albota", "value": "C92503"},
           {"label": "Comuna Aninoasa", "value": "C92504"},
           {"label": "Comuna Arefu", "value": "C92505"},
           {"label": "Comuna Babana", "value": "C92506"},
           {"label": "Comuna Baiculesti", "value": "C92507"},
           {"label": "Comuna Balilesti", "value": "C92508"},
           {"label": "Comuna Barla", "value": "C92509"},
           {"label": "Comuna Bascov", "value": "C92510"},
           {"label": "Comuna Beleti-Negresti", "value": "C92511"},
           {"label": "Comuna Berevoesti", "value": "C92512"},
           {"label": "Comuna Bogati", "value": "C92513"},
           {"label": "Comuna Boteni", "value": "C92514"},
           {"label": "Comuna Botesti", "value": "C92515"},
           {"label": "Comuna Bradu", "value": "C92516"},
           {"label": "Comuna Bradulet", "value": "C92517"},
           {"label": "Comuna Budeasa", "value": "C92518"},
           {"label": "Comuna Bughea de Jos", "value": "C92519"},
           {"label": "Comuna Bughea de Sus", "value": "C92520"},
           {"label": "Comuna Buzoesti", "value": "C92521"},
           {"label": "Comuna Caldararu", "value": "C92522"},
           {"label": "Comuna Calinesti", "value": "C92523"},
           {"label": "Comuna Cateasca", "value": "C92524"},
           {"label": "Comuna Cepari", "value": "C92525"},
           {"label": "Comuna Cetateni", "value": "C92526"},
           {"label": "Comuna Cicanesti", "value": "C92527"},
           {"label": "Comuna Ciofrangeni", "value": "C92528"},
           {"label": "Comuna Ciomagesti", "value": "C92529"},
           {"label": "Comuna Cocu", "value": "C92530"},
           {"label": "Comuna Corbeni", "value": "C92531"},
           {"label": "Comuna Corbi", "value": "C92532"},
           {"label": "Comuna Cosesti", "value": "C92533"},
           {"label": "Comuna Cotmeana", "value": "C92534"},
           {"label": "Comuna Cuca", "value": "C92535"},
           {"label": "Comuna Dambovicioara", "value": "C92536"},
           {"label": "Comuna Darmanesti", "value": "C92537"},
           {"label": "Comuna Davidesti", "value": "C92538"},
           {"label": "Comuna Dobresti", "value": "C92539"},
           {"label": "Comuna Domnesti", "value": "C92540"},
           {"label": "Comuna Draganu", "value": "C92541"},
           {"label": "Comuna Dragoslavele", "value": "C92542"},
           {"label": "Comuna Godeni", "value": "C92543"},
           {"label": "Comuna Harsesti", "value": "C92544"},
           {"label": "Comuna Hartiesti", "value": "C92545"},
           {"label": "Comuna Izvoru", "value": "C92546"},
           {"label": "Comuna Leordeni", "value": "C92547"},
           {"label": "Comuna Leresti", "value": "C92548"},
           {"label": "Comuna Lunca Corbului", "value": "C92549"},
           {"label": "Comuna Malureni", "value": "C92550"},
           {"label": "Comuna Maracineni", "value": "C92551"},
           {"label": "Comuna Merisani", "value": "C92552"},
           {"label": "Comuna Micesti", "value": "C92553"},
           {"label": "Comuna Mihaesti", "value": "C92554"},
           {"label": "Comuna Mioarele", "value": "C92555"},
           {"label": "Comuna Mirosi", "value": "C92556"},
           {"label": "Comuna Moraresti", "value": "C92557"},
           {"label": "Comuna Mosoaia", "value": "C92558"},
           {"label": "Comuna Mozaceni", "value": "C92559"},
           {"label": "Comuna Musatesti", "value": "C92560"},
           {"label": "Comuna Negrasi", "value": "C92561"},
           {"label": "Comuna Nucsoara", "value": "C92562"},
           {"label": "Comuna Oarja", "value": "C92563"},
           {"label": "Comuna Pietrosani", "value": "C92564"},
           {"label": "Comuna Poiana Lacului", "value": "C92565"},
           {"label": "Comuna Poienarii de Arges", "value": "C92566"},
           {"label": "Comuna Poienarii de Muscel", "value": "C92567"},
           {"label": "Comuna Popesti", "value": "C92568"},
           {"label": "Comuna Priboieni", "value": "C92569"},
           {"label": "Comuna Raca", "value": "C92570"},
           {"label": "Comuna Ratesti", "value": "C92571"},
           {"label": "Comuna Recea", "value": "C92572"},
           {"label": "Comuna Rociu", "value": "C92573"},
           {"label": "Comuna Rucar", "value": "C92574"},
           {"label": "Comuna Salatrucu", "value": "C92575"},
           {"label": "Comuna Sapata", "value": "C92576"},
           {"label": "Comuna Schitu-Golesti", "value": "C92577"},
           {"label": "Comuna Slobozia", "value": "C92578"},
           {"label": "Comuna Stalpeni", "value": "C92579"},
           {"label": "Comuna Stefan cel Mare", "value": "C92580"},
           {"label": "Comuna Stoenesti", "value": "C92581"},
           {"label": "Comuna Stolnici", "value": "C92582"},
           {"label": "Comuna Suici", "value": "C92583"},
           {"label": "Comuna Suseni", "value": "C92584"},
           {"label": "Comuna Teiu", "value": "C92585"},
           {"label": "Comuna Tigveni", "value": "C92586"},
           {"label": "Comuna Titesti", "value": "C92587"},
           {"label": "Comuna Uda", "value": "C92588"},
           {"label": "Comuna Ungheni", "value": "C92589"},
           {"label": "Comuna Valea Danului", "value": "C92590"},
           {"label": "Comuna Valea Iasului", "value": "C92591"},
           {"label": "Comuna Valea Mare-Pravat", "value": "C92592"},
           {"label": "Comuna Vedea", "value": "C92593"},
           {"label": "Comuna Vladesti", "value": "C92594"},
           {"label": "Comuna Vulturesti", "value": "C92595"},
           {"label": "Contesti", "value": "C92596"},
           {"label": "Corbeni", "value": "C92597"},
           {"label": "Corbi", "value": "C92598"},
           {"label": "Cornatel", "value": "C92599"},
           {"label": "Cosesti", "value": "C92600"},
           {"label": "Costesti", "value": "C92601"},
           {"label": "Cotenesti", "value": "C92602"},
           {"label": "Cotmeana", "value": "C92603"},
           {"label": "Cuca", "value": "C92604"},
           {"label": "Curtea de Arges", "value": "C92605"},
           {"label": "Dambovicioara", "value": "C92606"},
           {"label": "Darmanesti", "value": "C92607"},
           {"label": "Davidesti", "value": "C92608"},
           {"label": "Dobresti", "value": "C92609"},
           {"label": "Dobrotu", "value": "C92610"},
           {"label": "Domnesti", "value": "C92611"},
           {"label": "Draganu-Olteni", "value": "C92612"},
           {"label": "Draghici", "value": "C92613"},
           {"label": "Dragoslavele", "value": "C92614"},
           {"label": "Fagetu", "value": "C92615"},
           {"label": "Galasesti", "value": "C92616"},
           {"label": "Geamana", "value": "C92617"},
           {"label": "Godeni", "value": "C92618"},
           {"label": "Golesti", "value": "C92619"},
           {"label": "Gorganu", "value": "C92620"},
           {"label": "Harsesti", "value": "C92621"},
           {"label": "Hartiesti", "value": "C92622"},
           {"label": "Humele", "value": "C92623"},
           {"label": "Izvoru", "value": "C92624"},
           {"label": "Izvoru de Sus", "value": "C92625"},
           {"label": "Jugur", "value": "C92626"},
           {"label": "Jupanesti", "value": "C92627"},
           {"label": "Lazaresti", "value": "C92628"},
           {"label": "Leicesti", "value": "C92629"},
           {"label": "Leordeni", "value": "C92630"},
           {"label": "Leresti", "value": "C92631"},
           {"label": "Livezeni", "value": "C92632"},
           {"label": "Lunca Corbului", "value": "C92633"},
           {"label": "Malureni", "value": "C92634"},
           {"label": "Maracineni", "value": "C92635"},
           {"label": "Mares", "value": "C92636"},
           {"label": "Martesti", "value": "C92637"},
           {"label": "Matau", "value": "C92638"},
           {"label": "Merisani", "value": "C92639"},
           {"label": "Micesti", "value": "C92640"},
           {"label": "Mihaesti", "value": "C92641"},
           {"label": "Mioveni", "value": "C92642"},
           {"label": "Mirosi", "value": "C92643"},
           {"label": "Moraresti", "value": "C92644"},
           {"label": "Mosoaia", "value": "C92645"},
           {"label": "Mozaceni", "value": "C92646"},
           {"label": "Municipiul Campulung", "value": "C92647"},
           {"label": "Municipiul Curtea de Arges", "value": "C92648"},
           {"label": "Municipiul Pitesti", "value": "C92649"},
           {"label": "Musatesti", "value": "C92650"},
           {"label": "Negrasi", "value": "C92651"},
           {"label": "Nucsoara", "value": "C92652"},
           {"label": "Oarja", "value": "C92653"},
           {"label": "Oarja Sat", "value": "C92654"},
           {"label": "Oestii Pamanteni", "value": "C92655"},
           {"label": "Oras Costesti", "value": "C92656"},
           {"label": "Oras Mioveni", "value": "C92657"},
           {"label": "Oras Stefanesti", "value": "C92658"},
           {"label": "Oras Topoloveni", "value": "C92659"},
           {"label": "Patuleni", "value": "C92660"},
           {"label": "Petresti", "value": "C92661"},
           {"label": "Pietrosani", "value": "C92662"},
           {"label": "Pitesti", "value": "C92663"},
           {"label": "Poiana Lacului", "value": "C92664"},
           {"label": "Poienari", "value": "C92665"},
           {"label": "Popesti", "value": "C92666"},
           {"label": "Priboieni", "value": "C92667"},
           {"label": "Purcareni", "value": "C92668"},
           {"label": "Raca", "value": "C92669"},
           {"label": "Racovita", "value": "C92670"},
           {"label": "Radesti", "value": "C92671"},
           {"label": "Ratesti", "value": "C92672"},
           {"label": "Recea", "value": "C92673"},
           {"label": "Retevoiesti", "value": "C92674"},
           {"label": "Rociu", "value": "C92675"},
           {"label": "Rucar", "value": "C92676"},
           {"label": "Salatrucu", "value": "C92677"},
           {"label": "Schitu-Golesti", "value": "C92678"},
           {"label": "Serbanesti", "value": "C92679"},
           {"label": "Serboeni", "value": "C92680"},
           {"label": "Slanic", "value": "C92681"},
           {"label": "Slobozia", "value": "C92682"},
           {"label": "Stalpeni", "value": "C92683"},
           {"label": "Stefan cel Mare", "value": "C92684"},
           {"label": "Stefanesti", "value": "C92685"},
           {"label": "Stoenesti", "value": "C92686"},
           {"label": "Stolnici", "value": "C92687"},
           {"label": "Strambeni", "value": "C92688"},
           {"label": "Stroesti", "value": "C92689"},
           {"label": "Suici", "value": "C92690"},
           {"label": "Surdulesti", "value": "C92691"},
           {"label": "Suseni", "value": "C92692"},
           {"label": "Teiu", "value": "C92693"},
           {"label": "Tigveni", "value": "C92694"},
           {"label": "Titesti", "value": "C92695"},
           {"label": "Toplita", "value": "C92696"},
           {"label": "Topoloveni", "value": "C92697"},
           {"label": "Tutana", "value": "C92698"},
           {"label": "Uda", "value": "C92699"},
           {"label": "Ungheni", "value": "C92700"},
           {"label": "Valea Caselor", "value": "C92701"},
           {"label": "Valea Danului", "value": "C92702"},
           {"label": "Valea Iasului", "value": "C92703"},
           {"label": "Valea Mare Pravat", "value": "C92704"},
           {"label": "Valea Mare-Podgoria", "value": "C92705"},
           {"label": "Valea Popii", "value": "C92706"},
           {"label": "Valeni-Podgoria", "value": "C92707"},
           {"label": "Vedea", "value": "C92708"},
           {"label": "Vladesti", "value": "C92709"},
           {"label": "Voinesti", "value": "C92710"},
           {"label": "Vranesti", "value": "C92711"},
           {"label": "Vulturesti", "value": "C92712"}],
 "Ariana Governorate": [{"label": "Ariana", "value": "C119680"},
                        {"label": "Galaat el Andeless", "value": "C119681"}],
 "Arica y Parinacota": [{"label": "Arica", "value": "C17911"},
                        {"label": "Camarones", "value": "C17912"},
                        {"label": "General Lagos", "value": "C17913"},
                        {"label": "Putre", "value": "C17914"}],
 "Arima": [{"label": "Arima", "value": "C119655"}],
 "Arizona": [{"label": "Ahwatukee Foothills", "value": "C127478"},
             {"label": "Ajo", "value": "C127479"},
             {"label": "Alhambra", "value": "C127480"},
             {"label": "Anthem", "value": "C127481"},
             {"label": "Apache County", "value": "C127482"},
             {"label": "Apache Junction", "value": "C127483"},
             {"label": "Arivaca Junction", "value": "C127484"},
             {"label": "Arizona City", "value": "C127485"},
             {"label": "Avenue B and C", "value": "C127486"},
             {"label": "Avondale", "value": "C127487"},
             {"label": "Avra Valley", "value": "C127488"},
             {"label": "Bagdad", "value": "C127489"},
             {"label": "Beaver Dam", "value": "C127490"},
             {"label": "Benson", "value": "C127491"},
             {"label": "Big Park", "value": "C127492"},
             {"label": "Bisbee", "value": "C127493"},
             {"label": "Black Canyon City", "value": "C127494"},
             {"label": "Blackwater", "value": "C127495"},
             {"label": "Buckeye", "value": "C127496"},
             {"label": "Bullhead City", "value": "C127497"},
             {"label": "Bylas", "value": "C127498"},
             {"label": "Cactus Flat", "value": "C127499"},
             {"label": "Camp Verde", "value": "C127500"},
             {"label": "Canyon Day", "value": "C127501"},
             {"label": "Carefree", "value": "C127502"},
             {"label": "Casa Blanca", "value": "C127503"},
             {"label": "Casa Grande", "value": "C127504"},
             {"label": "Casas Adobes", "value": "C127505"},
             {"label": "Catalina", "value": "C127506"},
             {"label": "Catalina Foothills", "value": "C127507"},
             {"label": "Cave Creek", "value": "C127508"},
             {"label": "Centennial Park", "value": "C127509"},
             {"label": "Central City", "value": "C127510"},
             {"label": "Central Heights-Midland City", "value": "C127511"},
             {"label": "Chandler", "value": "C127512"},
             {"label": "Chinle", "value": "C127513"},
             {"label": "Chino Valley", "value": "C127514"},
             {"label": "Cibecue", "value": "C127515"},
             {"label": "Cienega Springs", "value": "C127516"},
             {"label": "Citrus Park", "value": "C127517"},
             {"label": "Clarkdale", "value": "C127518"},
             {"label": "Claypool", "value": "C127519"},
             {"label": "Clifton", "value": "C127520"},
             {"label": "Cochise County", "value": "C127521"},
             {"label": "Coconino County", "value": "C127522"},
             {"label": "Colorado City", "value": "C127523"},
             {"label": "Congress", "value": "C127524"},
             {"label": "Coolidge", "value": "C127525"},
             {"label": "Cordes Lakes", "value": "C127526"},
             {"label": "Cornville", "value": "C127527"},
             {"label": "Corona de Tucson", "value": "C127528"},
             {"label": "Cottonwood", "value": "C127529"},
             {"label": "Deer Valley", "value": "C127530"},
             {"label": "Desert Hills", "value": "C127531"},
             {"label": "Dewey-Humboldt", "value": "C127532"},
             {"label": "Dilkon", "value": "C127533"},
             {"label": "Dolan Springs", "value": "C127534"},
             {"label": "Donovan Estates", "value": "C127535"},
             {"label": "Douglas", "value": "C127536"},
             {"label": "Drexel Heights", "value": "C127537"},
             {"label": "Eagar", "value": "C127538"},
             {"label": "East Sahuarita", "value": "C127539"},
             {"label": "Ehrenberg", "value": "C127540"},
             {"label": "El Mirage", "value": "C127541"},
             {"label": "Eloy", "value": "C127542"},
             {"label": "Encanto", "value": "C127543"},
             {"label": "First Mesa", "value": "C127544"},
             {"label": "Flagstaff", "value": "C127545"},
             {"label": "Florence", "value": "C127546"},
             {"label": "Flowing Wells", "value": "C127547"},
             {"label": "Fort Defiance", "value": "C127548"},
             {"label": "Fortuna Foothills", "value": "C127549"},
             {"label": "Fountain Hills", "value": "C127550"},
             {"label": "Fredonia", "value": "C127551"},
             {"label": "Ganado", "value": "C127552"},
             {"label": "Gila Bend", "value": "C127553"},
             {"label": "Gila County", "value": "C127554"},
             {"label": "Gilbert", "value": "C127555"},
             {"label": "Glendale", "value": "C127556"},
             {"label": "Globe", "value": "C127557"},
             {"label": "Gold Camp", "value": "C127558"},
             {"label": "Gold Canyon", "value": "C127559"},
             {"label": "Golden Shores", "value": "C127560"},
             {"label": "Golden Valley", "value": "C127561"},
             {"label": "Goodyear", "value": "C127562"},
             {"label": "Graham County", "value": "C127563"},
             {"label": "Grand Canyon", "value": "C127564"},
             {"label": "Grand Canyon Village", "value": "C127565"},
             {"label": "Green Valley", "value": "C127566"},
             {"label": "Greenlee County", "value": "C127567"},
             {"label": "Guadalupe", "value": "C127568"},
             {"label": "Heber-Overgaard", "value": "C127569"},
             {"label": "Holbrook", "value": "C127570"},
             {"label": "Houck", "value": "C127571"},
             {"label": "Huachuca City", "value": "C127572"},
             {"label": "Joseph City", "value": "C127573"},
             {"label": "Kachina Village", "value": "C127574"},
             {"label": "Kaibito", "value": "C127575"},
             {"label": "Kayenta", "value": "C127576"},
             {"label": "Kearny", "value": "C127577"},
             {"label": "Kingman", "value": "C127578"},
             {"label": "La Paz County", "value": "C127579"},
             {"label": "Lake Havasu City", "value": "C127580"},
             {"label": "Lake Montezuma", "value": "C127581"},
             {"label": "Lake of the Woods", "value": "C127582"},
             {"label": "Laveen", "value": "C127583"},
             {"label": "LeChee", "value": "C127584"},
             {"label": "Linden", "value": "C127585"},
             {"label": "Litchfield Park", "value": "C127586"},
             {"label": "Lukachukai", "value": "C127587"},
             {"label": "Mammoth", "value": "C127588"},
             {"label": "Many Farms", "value": "C127589"},
             {"label": "Marana", "value": "C127590"},
             {"label": "Maricopa", "value": "C127591"},
             {"label": "Maricopa County", "value": "C127592"},
             {"label": "Maryvale", "value": "C127593"},
             {"label": "Mayer", "value": "C127594"},
             {"label": "Meadview", "value": "C127595"},
             {"label": "Mesa", "value": "C127596"},
             {"label": "Mescal", "value": "C127597"},
             {"label": "Miami", "value": "C127598"},
             {"label": "Mohave County", "value": "C127599"},
             {"label": "Mohave Valley", "value": "C127600"},
             {"label": "Morenci", "value": "C127601"},
             {"label": "Mountainaire", "value": "C127602"},
             {"label": "Naco", "value": "C127603"},
             {"label": "Navajo County", "value": "C127604"},
             {"label": "New Kingman-Butler", "value": "C127605"},
             {"label": "New River", "value": "C127606"},
             {"label": "Nogales", "value": "C127607"},
             {"label": "North Fork", "value": "C127608"},
             {"label": "Oracle", "value": "C127609"},
             {"label": "Oro Valley", "value": "C127610"},
             {"label": "Page", "value": "C127611"},
             {"label": "Paradise Valley", "value": "C127612"},
             {"label": "Parker", "value": "C127613"},
             {"label": "Parks", "value": "C127614"},
             {"label": "Paulden", "value": "C127615"},
             {"label": "Payson", "value": "C127616"},
             {"label": "Peach Springs", "value": "C127617"},
             {"label": "Peoria", "value": "C127618"},
             {"label": "Peridot", "value": "C127619"},
             {"label": "Phoenix", "value": "C127620"},
             {"label": "Picture Rocks", "value": "C127621"},
             {"label": "Pima", "value": "C127622"},
             {"label": "Pima County", "value": "C127623"},
             {"label": "Pinal County", "value": "C127624"},
             {"label": "Pine", "value": "C127625"},
             {"label": "Pinetop-Lakeside", "value": "C127626"},
             {"label": "Pirtleville", "value": "C127627"},
             {"label": "Prescott", "value": "C127628"},
             {"label": "Prescott Valley", "value": "C127629"},
             {"label": "Quartzsite", "value": "C127630"},
             {"label": "Queen Creek", "value": "C127631"},
             {"label": "Rio Rico", "value": "C127632"},
             {"label": "Rio Verde", "value": "C127633"},
             {"label": "Sacaton", "value": "C127634"},
             {"label": "Saddle Brooke", "value": "C127635"},
             {"label": "Safford", "value": "C127636"},
             {"label": "Sahuarita", "value": "C127637"},
             {"label": "Saint David", "value": "C127638"},
             {"label": "Saint Johns", "value": "C127639"},
             {"label": "Saint Michaels", "value": "C127640"},
             {"label": "Salome", "value": "C127641"},
             {"label": "San Carlos", "value": "C127642"},
             {"label": "San Luis", "value": "C127643"},
             {"label": "San Manuel", "value": "C127644"},
             {"label": "San Tan Valley", "value": "C127645"},
             {"label": "Santa Cruz County", "value": "C127646"},
             {"label": "Scenic", "value": "C127647"},
             {"label": "Scottsdale", "value": "C127648"},
             {"label": "Sedona", "value": "C127649"},
             {"label": "Sells", "value": "C127650"},
             {"label": "Show Low", "value": "C127651"},
             {"label": "Sierra Vista", "value": "C127652"},
             {"label": "Sierra Vista Southeast", "value": "C127653"},
             {"label": "Six Shooter Canyon", "value": "C127654"},
             {"label": "Snowflake", "value": "C127655"},
             {"label": "Somerton", "value": "C127656"},
             {"label": "South Tucson", "value": "C127657"},
             {"label": "Spring Valley", "value": "C127658"},
             {"label": "Springerville", "value": "C127659"},
             {"label": "Star Valley", "value": "C127660"},
             {"label": "Summit", "value": "C127661"},
             {"label": "Sun City", "value": "C127662"},
             {"label": "Sun City West", "value": "C127663"},
             {"label": "Sun Lakes", "value": "C127664"},
             {"label": "Sun Valley", "value": "C127665"},
             {"label": "Superior", "value": "C127666"},
             {"label": "Surprise", "value": "C127667"},
             {"label": "Swift Trail Junction", "value": "C127668"},
             {"label": "Tanque Verde", "value": "C127669"},
             {"label": "Taylor", "value": "C127670"},
             {"label": "Tempe", "value": "C127671"},
             {"label": "Tempe Junction", "value": "C127672"},
             {"label": "Thatcher", "value": "C127673"},
             {"label": "Three Points", "value": "C127674"},
             {"label": "Tolleson", "value": "C127675"},
             {"label": "Tombstone", "value": "C127676"},
             {"label": "Tonto Basin", "value": "C127677"},
             {"label": "Tortolita", "value": "C127678"},
             {"label": "Tsaile", "value": "C127679"},
             {"label": "Tuba City", "value": "C127680"},
             {"label": "Tubac", "value": "C127681"},
             {"label": "Tucson", "value": "C127682"},
             {"label": "Tucson Estates", "value": "C127683"},
             {"label": "Vail", "value": "C127684"},
             {"label": "Valencia West", "value": "C127685"},
             {"label": "Valle Vista", "value": "C127686"},
             {"label": "Verde Village", "value": "C127687"},
             {"label": "Village of Oak Creek (Big Park)", "value": "C127688"},
             {"label": "Wellton", "value": "C127689"},
             {"label": "West Sedona", "value": "C127690"},
             {"label": "Whetstone", "value": "C127691"},
             {"label": "White Mountain Lake", "value": "C127692"},
             {"label": "Whiteriver", "value": "C127693"},
             {"label": "Wickenburg", "value": "C127694"},
             {"label": "Willcox", "value": "C127695"},
             {"label": "Williams", "value": "C127696"},
             {"label": "Williamson", "value": "C127697"},
             {"label": "Willow Valley", "value": "C127698"},
             {"label": "Window Rock", "value": "C127699"},
             {"label": "Winslow", "value": "C127700"},
             {"label": "Yavapai County", "value": "C127701"},
             {"label": "Youngtown", "value": "C127702"},
             {"label": "Yuma", "value": "C127703"},
             {"label": "Yuma County", "value": "C127704"}],
 "Arkansas": [{"label": "Alexander", "value": "C127705"},
              {"label": "Alma", "value": "C127706"},
              {"label": "Arkadelphia", "value": "C127707"},
              {"label": "Arkansas City", "value": "C127708"},
              {"label": "Arkansas County", "value": "C127709"},
              {"label": "Ash Flat", "value": "C127710"},
              {"label": "Ashdown", "value": "C127711"},
              {"label": "Ashley County", "value": "C127712"},
              {"label": "Atkins", "value": "C127713"},
              {"label": "Augusta", "value": "C127714"},
              {"label": "Austin", "value": "C127715"},
              {"label": "Bald Knob", "value": "C127716"},
              {"label": "Barling", "value": "C127717"},
              {"label": "Batesville", "value": "C127718"},
              {"label": "Baxter County", "value": "C127719"},
              {"label": "Bay", "value": "C127720"},
              {"label": "Beebe", "value": "C127721"},
              {"label": "Bella Vista", "value": "C127722"},
              {"label": "Benton", "value": "C127723"},
              {"label": "Benton County", "value": "C127724"},
              {"label": "Bentonville", "value": "C127725"},
              {"label": "Berryville", "value": "C127726"},
              {"label": "Bethel Heights", "value": "C127727"},
              {"label": "Blytheville", "value": "C127728"},
              {"label": "Bono", "value": "C127729"},
              {"label": "Boone County", "value": "C127730"},
              {"label": "Booneville", "value": "C127731"},
              {"label": "Bradley County", "value": "C127732"},
              {"label": "Brinkley", "value": "C127733"},
              {"label": "Brookland", "value": "C127734"},
              {"label": "Bryant", "value": "C127735"},
              {"label": "Bull Shoals", "value": "C127736"},
              {"label": "Cabot", "value": "C127737"},
              {"label": "Calhoun County", "value": "C127738"},
              {"label": "Calico Rock", "value": "C127739"},
              {"label": "Camden", "value": "C127740"},
              {"label": "Caraway", "value": "C127741"},
              {"label": "Carlisle", "value": "C127742"},
              {"label": "Carroll County", "value": "C127743"},
              {"label": "Cave City", "value": "C127744"},
              {"label": "Cave Springs", "value": "C127745"},
              {"label": "Cedarville", "value": "C127746"},
              {"label": "Centerton", "value": "C127747"},
              {"label": "Charleston", "value": "C127748"},
              {"label": "Cherokee Village", "value": "C127749"},
              {"label": "Chicot County", "value": "C127750"},
              {"label": "Clarendon", "value": "C127751"},
              {"label": "Clark County", "value": "C127752"},
              {"label": "Clarksville", "value": "C127753"},
              {"label": "Clay County", "value": "C127754"},
              {"label": "Cleburne County", "value": "C127755"},
              {"label": "Cleveland County", "value": "C127756"},
              {"label": "Clinton", "value": "C127757"},
              {"label": "Coal Hill", "value": "C127758"},
              {"label": "Columbia County", "value": "C127759"},
              {"label": "Conway", "value": "C127760"},
              {"label": "Conway County", "value": "C127761"},
              {"label": "Corning", "value": "C127762"},
              {"label": "Craighead County", "value": "C127763"},
              {"label": "Crawford County", "value": "C127764"},
              {"label": "Crittenden County", "value": "C127765"},
              {"label": "Cross County", "value": "C127766"},
              {"label": "Crossett", "value": "C127767"},
              {"label": "Dallas County", "value": "C127768"},
              {"label": "Danville", "value": "C127769"},
              {"label": "Dardanelle", "value": "C127770"},
              {"label": "De Queen", "value": "C127771"},
              {"label": "De Witt", "value": "C127772"},
              {"label": "Decatur", "value": "C127773"},
              {"label": "Dermott", "value": "C127774"},
              {"label": "Des Arc", "value": "C127775"},
              {"label": "Desha County", "value": "C127776"},
              {"label": "Diaz", "value": "C127777"},
              {"label": "Dierks", "value": "C127778"},
              {"label": "Dover", "value": "C127779"},
              {"label": "Drew County", "value": "C127780"},
              {"label": "Dumas", "value": "C127781"},
              {"label": "Earle", "value": "C127782"},
              {"label": "East End", "value": "C127783"},
              {"label": "El Dorado", "value": "C127784"},
              {"label": "Elkins", "value": "C127785"},
              {"label": "Elm Springs", "value": "C127786"},
              {"label": "England", "value": "C127787"},
              {"label": "Eudora", "value": "C127788"},
              {"label": "Eureka Springs", "value": "C127789"},
              {"label": "Fairfield Bay", "value": "C127790"},
              {"label": "Farmington", "value": "C127791"},
              {"label": "Faulkner County", "value": "C127792"},
              {"label": "Fayetteville", "value": "C127793"},
              {"label": "Flippin", "value": "C127794"},
              {"label": "Fordyce", "value": "C127795"},
              {"label": "Forrest City", "value": "C127796"},
              {"label": "Fort Smith", "value": "C127797"},
              {"label": "Franklin County", "value": "C127798"},
              {"label": "Fulton County", "value": "C127799"},
              {"label": "Garland County", "value": "C127800"},
              {"label": "Gassville", "value": "C127801"},
              {"label": "Gentry", "value": "C127802"},
              {"label": "Gibson", "value": "C127803"},
              {"label": "Glenwood", "value": "C127804"},
              {"label": "Goshen", "value": "C127805"},
              {"label": "Gosnell", "value": "C127806"},
              {"label": "Grant County", "value": "C127807"},
              {"label": "Gravel Ridge", "value": "C127808"},
              {"label": "Gravette", "value": "C127809"},
              {"label": "Green Forest", "value": "C127810"},
              {"label": "Greenbrier", "value": "C127811"},
              {"label": "Greene County", "value": "C127812"},
              {"label": "Greenland", "value": "C127813"},
              {"label": "Greenwood", "value": "C127814"},
              {"label": "Gurdon", "value": "C127815"},
              {"label": "Hamburg", "value": "C127816"},
              {"label": "Hampton", "value": "C127817"},
              {"label": "Harrisburg", "value": "C127818"},
              {"label": "Harrison", "value": "C127819"},
              {"label": "Haskell", "value": "C127820"},
              {"label": "Hazen", "value": "C127821"},
              {"label": "Heber Springs", "value": "C127822"},
              {"label": "Helena", "value": "C127823"},
              {"label": "Helena-West Helena", "value": "C127824"},
              {"label": "Hempstead County", "value": "C127825"},
              {"label": "Highland", "value": "C127826"},
              {"label": "Holiday Island", "value": "C127827"},
              {"label": "Hope", "value": "C127828"},
              {"label": "Horatio", "value": "C127829"},
              {"label": "Horseshoe Bend", "value": "C127830"},
              {"label": "Hot Spring County", "value": "C127831"},
              {"label": "Hot Springs", "value": "C127832"},
              {"label": "Hot Springs National Park", "value": "C127833"},
              {"label": "Hot Springs Village", "value": "C127834"},
              {"label": "Howard County", "value": "C127835"},
              {"label": "Hoxie", "value": "C127836"},
              {"label": "Hughes", "value": "C127837"},
              {"label": "Huntsville", "value": "C127838"},
              {"label": "Independence County", "value": "C127839"},
              {"label": "Izard County", "value": "C127840"},
              {"label": "Jackson County", "value": "C127841"},
              {"label": "Jacksonville", "value": "C127842"},
              {"label": "Jasper", "value": "C127843"},
              {"label": "Jefferson County", "value": "C127844"},
              {"label": "Johnson", "value": "C127845"},
              {"label": "Johnson County", "value": "C127846"},
              {"label": "Jonesboro", "value": "C127847"},
              {"label": "Judsonia", "value": "C127848"},
              {"label": "Kensett", "value": "C127849"},
              {"label": "Lafayette County", "value": "C127850"},
              {"label": "Lake City", "value": "C127851"},
              {"label": "Lake Hamilton", "value": "C127852"},
              {"label": "Lake Village", "value": "C127853"},
              {"label": "Lamar", "value": "C127854"},
              {"label": "Landmark", "value": "C127855"},
              {"label": "Lavaca", "value": "C127856"},
              {"label": "Lawrence County", "value": "C127857"},
              {"label": "Leachville", "value": "C127858"},
              {"label": "Lee County", "value": "C127859"},
              {"label": "Lepanto", "value": "C127860"},
              {"label": "Lewisville", "value": "C127861"},
              {"label": "Lincoln", "value": "C127862"},
              {"label": "Lincoln County", "value": "C127863"},
              {"label": "Little Flock", "value": "C127864"},
              {"label": "Little River County", "value": "C127865"},
              {"label": "Little Rock", "value": "C127866"},
              {"label": "Little Rock Air Force Base", "value": "C127867"},
              {"label": "Logan County", "value": "C127868"},
              {"label": "London", "value": "C127869"},
              {"label": "Lonoke", "value": "C127870"},
              {"label": "Lonoke County", "value": "C127871"},
              {"label": "Lowell", "value": "C127872"},
              {"label": "Luxora", "value": "C127873"},
              {"label": "Madison County", "value": "C127874"},
              {"label": "Magnolia", "value": "C127875"},
              {"label": "Malvern", "value": "C127876"},
              {"label": "Manila", "value": "C127877"},
              {"label": "Mansfield", "value": "C127878"},
              {"label": "Marianna", "value": "C127879"},
              {"label": "Marion", "value": "C127880"},
              {"label": "Marion County", "value": "C127881"},
              {"label": "Marked Tree", "value": "C127882"},
              {"label": "Marmaduke", "value": "C127883"},
              {"label": "Marshall", "value": "C127884"},
              {"label": "Marvell", "value": "C127885"},
              {"label": "Maumelle", "value": "C127886"},
              {"label": "Mayflower", "value": "C127887"},
              {"label": "McAlmont", "value": "C127888"},
              {"label": "McCrory", "value": "C127889"},
              {"label": "McGehee", "value": "C127890"},
              {"label": "Melbourne", "value": "C127891"},
              {"label": "Mena", "value": "C127892"},
              {"label": "Midway", "value": "C127893"},
              {"label": "Miller County", "value": "C127894"},
              {"label": "Mineral Springs", "value": "C127895"},
              {"label": "Mississippi County", "value": "C127896"},
              {"label": "Monette", "value": "C127897"},
              {"label": "Monroe County", "value": "C127898"},
              {"label": "Montgomery County", "value": "C127899"},
              {"label": "Monticello", "value": "C127900"},
              {"label": "Morrilton", "value": "C127901"},
              {"label": "Mount Ida", "value": "C127902"},
              {"label": "Mountain Home", "value": "C127903"},
              {"label": "Mountain View", "value": "C127904"},
              {"label": "Mulberry", "value": "C127905"},
              {"label": "Murfreesboro", "value": "C127906"},
              {"label": "Nashville", "value": "C127907"},
              {"label": "Nevada County", "value": "C127908"},
              {"label": "Newark", "value": "C127909"},
              {"label": "Newport", "value": "C127910"},
              {"label": "Newton County", "value": "C127911"},
              {"label": "North Crossett", "value": "C127912"},
              {"label": "North Little Rock", "value": "C127913"},
              {"label": "Ola", "value": "C127914"},
              {"label": "Osceola", "value": "C127915"},
              {"label": "Ouachita County", "value": "C127916"},
              {"label": "Ozark", "value": "C127917"},
              {"label": "Paragould", "value": "C127918"},
              {"label": "Paris", "value": "C127919"},
              {"label": "Parkin", "value": "C127920"},
              {"label": "Pea Ridge", "value": "C127921"},
              {"label": "Perry County", "value": "C127922"},
              {"label": "Perryville", "value": "C127923"},
              {"label": "Phillips County", "value": "C127924"},
              {"label": "Piggott", "value": "C127925"},
              {"label": "Pike County", "value": "C127926"},
              {"label": "Pine Bluff", "value": "C127927"},
              {"label": "Piney", "value": "C127928"},
              {"label": "Pocahontas", "value": "C127929"},
              {"label": "Poinsett County", "value": "C127930"},
              {"label": "Polk County", "value": "C127931"},
              {"label": "Pope County", "value": "C127932"},
              {"label": "Pottsville", "value": "C127933"},
              {"label": "Prairie County", "value": "C127934"},
              {"label": "Prairie Creek", "value": "C127935"},
              {"label": "Prairie Grove", "value": "C127936"},
              {"label": "Prescott", "value": "C127937"},
              {"label": "Pulaski County", "value": "C127938"},
              {"label": "Randolph County", "value": "C127939"},
              {"label": "Rector", "value": "C127940"},
              {"label": "Redfield", "value": "C127941"},
              {"label": "Rison", "value": "C127942"},
              {"label": "Rockwell", "value": "C127943"},
              {"label": "Rogers", "value": "C127944"},
              {"label": "Russellville", "value": "C127945"},
              {"label": "Saint Francis County", "value": "C127946"},
              {"label": "Salem", "value": "C127947"},
              {"label": "Saline County", "value": "C127948"},
              {"label": "Scott County", "value": "C127949"},
              {"label": "Searcy", "value": "C127950"},
              {"label": "Searcy County", "value": "C127951"},
              {"label": "Sebastian County", "value": "C127952"},
              {"label": "Sevier County", "value": "C127953"},
              {"label": "Shannon Hills", "value": "C127954"},
              {"label": "Sharp County", "value": "C127955"},
              {"label": "Sheridan", "value": "C127956"},
              {"label": "Sherwood", "value": "C127957"},
              {"label": "Siloam Springs", "value": "C127958"},
              {"label": "Smackover", "value": "C127959"},
              {"label": "Southside", "value": "C127960"},
              {"label": "Springdale", "value": "C127961"},
              {"label": "Stamps", "value": "C127962"},
              {"label": "Star City", "value": "C127963"},
              {"label": "Stone County", "value": "C127964"},
              {"label": "Stuttgart", "value": "C127965"},
              {"label": "Sulphur Springs", "value": "C127966"},
              {"label": "Texarkana", "value": "C127967"},
              {"label": "Tontitown", "value": "C127968"},
              {"label": "Trumann", "value": "C127969"},
              {"label": "Tuckerman", "value": "C127970"},
              {"label": "Union County", "value": "C127971"},
              {"label": "Van Buren", "value": "C127972"},
              {"label": "Van Buren County", "value": "C127973"},
              {"label": "Vilonia", "value": "C127974"},
              {"label": "Waldo", "value": "C127975"},
              {"label": "Waldron", "value": "C127976"},
              {"label": "Walnut Ridge", "value": "C127977"},
              {"label": "Ward", "value": "C127978"},
              {"label": "Warren", "value": "C127979"},
              {"label": "Washington County", "value": "C127980"},
              {"label": "West Crossett", "value": "C127981"},
              {"label": "West Fork", "value": "C127982"},
              {"label": "West Helena", "value": "C127983"},
              {"label": "West Memphis", "value": "C127984"},
              {"label": "White County", "value": "C127985"},
              {"label": "White Hall", "value": "C127986"},
              {"label": "Woodruff County", "value": "C127987"},
              {"label": "Wrightsville", "value": "C127988"},
              {"label": "Wynne", "value": "C127989"},
              {"label": "Yell County", "value": "C127990"},
              {"label": "Yellville", "value": "C127991"}],
 "Arkhangai Province": [{"label": "Tsetserleg", "value": "C75556"}],
 "Arkhangelsk": [{"label": "Arkhangelsk", "value": "C100401"},
                 {"label": "Bereznik", "value": "C100402"},
                 {"label": "Cheremushskiy", "value": "C100403"},
                 {"label": "Dvinskoy", "value": "C100404"},
                 {"label": "Ilinsko-Podomskoye", "value": "C100405"},
                 {"label": "Ileza", "value": "C100406"},
                 {"label": "Isakogorka", "value": "C100407"},
                 {"label": "Kamenka", "value": "C100408"},
                 {"label": "Kargopol", "value": "C100409"},
                 {"label": "Kargopolskiy Rayon", "value": "C100410"},
                 {"label": "Karpogory", "value": "C100411"},
                 {"label": "Katunino", "value": "C100412"},
                 {"label": "Kharitonovo", "value": "C100413"},
                 {"label": "Kholmogorskiy Rayon", "value": "C100414"},
                 {"label": "Kholmogory", "value": "C100415"},
                 {"label": "Kizema", "value": "C100416"},
                 {"label": "Kodino", "value": "C100417"},
                 {"label": "Konevo", "value": "C100418"},
                 {"label": "Konosha", "value": "C100419"},
                 {"label": "Koryazhma", "value": "C100420"},
                 {"label": "Kotlas", "value": "C100421"},
                 {"label": "Kotlasskiy Rayon", "value": "C100422"},
                 {"label": "Krasnoborsk", "value": "C100423"},
                 {"label": "Kuloy", "value": "C100424"},
                 {"label": "Lenskiy Rayon", "value": "C100425"},
                 {"label": "Leshukonskiy Rayon", "value": "C100426"},
                 {"label": "Leshukonskoye", "value": "C100427"},
                 {"label": "Loyga", "value": "C100428"},
                 {"label": "Lukovetskiy", "value": "C100429"},
                 {"label": "Mezen", "value": "C100430"},
                 {"label": "Mezenskiy Rayon", "value": "C100431"},
                 {"label": "Mirnyy", "value": "C100432"},
                 {"label": "Novodvinsk", "value": "C100433"},
                 {"label": "Nyandoma", "value": "C100434"},
                 {"label": "Nyandomskiy Rayon", "value": "C100435"},
                 {"label": "Oksovskiy", "value": "C100436"},
                 {"label": "Onega", "value": "C100437"},
                 {"label": "Onezhskiy Rayon", "value": "C100438"},
                 {"label": "Pinega", "value": "C100439"},
                 {"label": "Pinezhskiy Rayon", "value": "C100440"},
                 {"label": "Plesetsk", "value": "C100441"},
                 {"label": "Podyuga", "value": "C100442"},
                 {"label": "Primorskiy Rayon", "value": "C100443"},
                 {"label": "Privodino", "value": "C100444"},
                 {"label": "Puksoozero", "value": "C100445"},
                 {"label": "Rochegda", "value": "C100446"},
                 {"label": "Samoded", "value": "C100447"},
                 {"label": "Severodvinsk", "value": "C100448"},
                 {"label": "Shalakusha", "value": "C100449"},
                 {"label": "Shangaly", "value": "C100450"},
                 {"label": "Shenkursk", "value": "C100451"},
                 {"label": "Shenkurskiy Rayon", "value": "C100452"},
                 {"label": "Shipitsyno", "value": "C100453"},
                 {"label": "Solvychegodsk", "value": "C100454"},
                 {"label": "Solginskiy", "value": "C100455"},
                 {"label": "Udimskiy", "value": "C100456"},
                 {"label": "Urdoma", "value": "C100457"},
                 {"label": "Ust-Shonosha", "value": "C100458"},
                 {"label": "Ustyanskiy Rayon", "value": "C100459"},
                 {"label": "Uyemskiy", "value": "C100460"},
                 {"label": "Vaskovo", "value": "C100461"},
                 {"label": "Velsk", "value": "C100462"},
                 {"label": "Velskiy Rayon", "value": "C100463"},
                 {"label": "Verkhnyaya Toyma", "value": "C100464"},
                 {"label": "Voloshka", "value": "C100465"},
                 {"label": "Vychegodskiy", "value": "C100466"},
                 {"label": "Yagry", "value": "C100467"},
                 {"label": "Yarensk", "value": "C100468"},
                 {"label": "Yemetsk", "value": "C100469"},
                 {"label": "Yemtsa", "value": "C100470"},
                 {"label": "Yertsevo", "value": "C100471"}],
 "Armavir Region": [{"label": "Aghavnatun", "value": "C1619"},
                    {"label": "Aknalich", "value": "C1620"},
                    {"label": "Aknashen", "value": "C1621"},
                    {"label": "Alashkert", "value": "C1622"},
                    {"label": "Apaga", "value": "C1623"},
                    {"label": "Araks", "value": "C1624"},
                    {"label": "Arazap", "value": "C1625"},
                    {"label": "Arbat", "value": "C1626"},
                    {"label": "Arevashat", "value": "C1627"},
                    {"label": "Arevik", "value": "C1628"},
                    {"label": "Argavand", "value": "C1629"},
                    {"label": "Armavir", "value": "C1630"},
                    {"label": "Arshaluys", "value": "C1631"},
                    {"label": "Artimet", "value": "C1632"},
                    {"label": "Aygek", "value": "C1633"},
                    {"label": "Aygeshat", "value": "C1634"},
                    {"label": "Baghramyan", "value": "C1635"},
                    {"label": "Bambakashat", "value": "C1636"},
                    {"label": "Dalarik", "value": "C1637"},
                    {"label": "Doghs", "value": "C1638"},
                    {"label": "Gay", "value": "C1639"},
                    {"label": "Geghakert", "value": "C1640"},
                    {"label": "Geghanist", "value": "C1641"},
                    {"label": "Getashen", "value": "C1642"},
                    {"label": "Gmbet", "value": "C1643"},
                    {"label": "Griboyedov", "value": "C1644"},
                    {"label": "Haykashen", "value": "C1645"},
                    {"label": "Hovtamej", "value": "C1646"},
                    {"label": "Janfida", "value": "C1647"},
                    {"label": "Khoronk", "value": "C1648"},
                    {"label": "Lenughi", "value": "C1649"},
                    {"label": "Lukashin", "value": "C1650"},
                    {"label": "Margara", "value": "C1651"},
                    {"label": "Mayisyan", "value": "C1652"},
                    {"label": "Merdzavan", "value": "C1653"},
                    {"label": "Metsamor", "value": "C1654"},
                    {"label": "Mrgashat", "value": "C1655"},
                    {"label": "Musalerr", "value": "C1656"},
                    {"label": "Myasnikyan", "value": "C1657"},
                    {"label": "Nalbandyan", "value": "C1658"},
                    {"label": "Nor Armavir", "value": "C1659"},
                    {"label": "Norakert", "value": "C1660"},
                    {"label": "Pshatavan", "value": "C1661"},
                    {"label": "Ptghunk", "value": "C1662"},
                    {"label": "Sardarapat", "value": "C1663"},
                    {"label": "Shenavan", "value": "C1664"},
                    {"label": "Tandzut", "value": "C1665"},
                    {"label": "Taronik", "value": "C1666"},
                    {"label": "Tsaghkunk", "value": "C1667"},
                    {"label": "Tsiatsan", "value": "C1668"},
                    {"label": "Vagharshapat", "value": "C1669"},
                    {"label": "Voskehat", "value": "C1670"},
                    {"label": "Yeghegnut", "value": "C1671"},
                    {"label": "Yeraskhahun", "value": "C1672"}],
 "Arta Region": [{"label": "Arta", "value": "C23809"}],
 "Artemisa Province": [{"label": "Alquizar", "value": "C21671"},
                       {"label": "Artemisa", "value": "C21672"},
                       {"label": "Bahia Honda", "value": "C21673"},
                       {"label": "Bauta", "value": "C21674"},
                       {"label": "Cabanas", "value": "C21675"},
                       {"label": "Candelaria", "value": "C21676"},
                       {"label": "Guanajay", "value": "C21677"},
                       {"label": "Guira de Melena", "value": "C21678"},
                       {"label": "Mariel", "value": "C21679"},
                       {"label": "Municipio de Artemisa", "value": "C21680"},
                       {"label": "Municipio de Bauta", "value": "C21681"},
                       {"label": "Municipio de Guanajay", "value": "C21682"},
                       {"label": "Municipio de Mariel", "value": "C21683"},
                       {"label": "Municipio de San Cristobal",
                        "value": "C21684"},
                       {"label": "San Antonio de los Banos", "value": "C21685"},
                       {"label": "San Cristobal", "value": "C21686"},
                       {"label": "Soroa", "value": "C21687"}],
 "Artibonite": [{"label": "Anse Rouge", "value": "C43079"},
                {"label": "Arrondissement de Saint-Marc", "value": "C43080"},
                {"label": "Desarmes", "value": "C43081"},
                {"label": "Dessalines", "value": "C43082"},
                {"label": "Ennery", "value": "C43083"},
                {"label": "Gonaives", "value": "C43084"},
                {"label": "Grande Saline", "value": "C43085"},
                {"label": "Gros Morne", "value": "C43086"},
                {"label": "Marmelade", "value": "C43087"},
                {"label": "Saint-Marc", "value": "C43088"},
                {"label": "Verrettes", "value": "C43089"}],
 "Artigas Department": [{"label": "Artigas", "value": "C146826"},
                        {"label": "Baltasar Brum", "value": "C146827"},
                        {"label": "Bella Union", "value": "C146828"},
                        {"label": "Las Piedras", "value": "C146829"},
                        {"label": "Tomas Gomensoro", "value": "C146830"}],
 "Artvin": [{"label": "Ardanuc", "value": "C119961"},
            {"label": "Arhavi", "value": "C119962"},
            {"label": "Artvin", "value": "C119963"},
            {"label": "Borcka", "value": "C119964"},
            {"label": "Hopa", "value": "C119965"},
            {"label": "Kemalpasa", "value": "C119966"},
            {"label": "Murgul", "value": "C119967"},
            {"label": "Savsat", "value": "C119968"},
            {"label": "Seyitler", "value": "C119969"},
            {"label": "Yusufeli", "value": "C119970"}],
 "Arunachal Pradesh": [{"label": "Along", "value": "C45068"},
                       {"label": "Anjaw", "value": "C45069"},
                       {"label": "Basar", "value": "C45070"},
                       {"label": "Bomdila", "value": "C45071"},
                       {"label": "Changlang", "value": "C45072"},
                       {"label": "Dibang Valley", "value": "C45073"},
                       {"label": "East Kameng", "value": "C45074"},
                       {"label": "East Siang", "value": "C45075"},
                       {"label": "Hayuliang", "value": "C45076"},
                       {"label": "Itanagar", "value": "C45077"},
                       {"label": "Khonsa", "value": "C45078"},
                       {"label": "Kurung Kumey", "value": "C45079"},
                       {"label": "Lohit District", "value": "C45080"},
                       {"label": "Lower Dibang Valley", "value": "C45081"},
                       {"label": "Lower Subansiri", "value": "C45082"},
                       {"label": "Margherita", "value": "C45083"},
                       {"label": "Naharlagun", "value": "C45084"},
                       {"label": "Pasighat", "value": "C45085"},
                       {"label": "Tawang", "value": "C45086"},
                       {"label": "Tezu", "value": "C45087"},
                       {"label": "Tirap", "value": "C45088"},
                       {"label": "Upper Siang", "value": "C45089"},
                       {"label": "Upper Subansiri", "value": "C45090"},
                       {"label": "West Kameng", "value": "C45091"},
                       {"label": "West Siang", "value": "C45092"},
                       {"label": "Ziro", "value": "C45093"}],
 "Arusha": [{"label": "Arusha", "value": "C118065"},
            {"label": "Kingori", "value": "C118066"},
            {"label": "Kiratu", "value": "C118067"},
            {"label": "Longido", "value": "C118068"},
            {"label": "Mbuguni", "value": "C118069"},
            {"label": "Merelani", "value": "C118070"},
            {"label": "Meru", "value": "C118071"},
            {"label": "Monduli", "value": "C118072"},
            {"label": "Mto wa Mbu", "value": "C118073"},
            {"label": "Namanga", "value": "C118074"},
            {"label": "Ngorongoro", "value": "C118075"},
            {"label": "Nkoaranga", "value": "C118076"},
            {"label": "Poli", "value": "C118077"},
            {"label": "Usa River", "value": "C118078"}],
 "As-Suwayda Governorate": [{"label": "As-Suwayda", "value": "C117846"},
                            {"label": "As-Suwayda District",
                             "value": "C117847"},
                            {"label": "Salakhid", "value": "C117848"},
                            {"label": "Salkhad", "value": "C117849"},
                            {"label": "Salkhad District", "value": "C117850"},
                            {"label": "Shahba", "value": "C117851"},
                            {"label": "Shahba District", "value": "C117852"}],
 "Ash Sharqiyah Region": [{"label": "Sur", "value": "C79304"}],
 "Ashanti": [{"label": "Agogo", "value": "C41468"},
             {"label": "Bekwai", "value": "C41469"},
             {"label": "Ejura", "value": "C41470"},
             {"label": "Konongo", "value": "C41471"},
             {"label": "Kumasi", "value": "C41472"},
             {"label": "Mampong", "value": "C41473"},
             {"label": "Obuase", "value": "C41474"},
             {"label": "Tafo", "value": "C41475"}],
 "Ashgabat": [{"label": "Ashgabat", "value": "C121167"}],
 "Asir": [{"label": "Abha", "value": "C106259"},
          {"label": "Al Bahah", "value": "C106260"},
          {"label": "Al Majaridah", "value": "C106261"},
          {"label": "Al Mindak", "value": "C106262"},
          {"label": "Al Qahab", "value": "C106263"},
          {"label": "An Nimas", "value": "C106264"},
          {"label": "Hajrah", "value": "C106265"},
          {"label": "Khamis Mushait", "value": "C106266"},
          {"label": "Ma`riyah", "value": "C106267"},
          {"label": "Mifa", "value": "C106268"},
          {"label": "Munayzir", "value": "C106269"},
          {"label": "Qalat Bishah", "value": "C106270"},
          {"label": "Sabt Al Alayah", "value": "C106271"},
          {"label": "Tabalah", "value": "C106272"}],
 "Assa-Zag (EH-partial)": [{"label": "Agadir", "value": "C75628"},
                           {"label": "Agadir Melloul", "value": "C75629"},
                           {"label": "Agadir-Ida-ou-Tnan", "value": "C75630"},
                           {"label": "Aoulouz", "value": "C75631"},
                           {"label": "Aourir", "value": "C75632"},
                           {"label": "Arazane", "value": "C75633"},
                           {"label": "Argana", "value": "C75634"},
                           {"label": "Bigoudine", "value": "C75635"},
                           {"label": "Chtouka-Ait-Baha", "value": "C75636"},
                           {"label": "Inezgane", "value": "C75637"},
                           {"label": "Inezgane-Ait Melloul", "value": "C75638"},
                           {"label": "Ouijjane", "value": "C75639"},
                           {"label": "Oulad Teima", "value": "C75640"},
                           {"label": "Reggada", "value": "C75641"},
                           {"label": "Sidi Ifni", "value": "C75642"},
                           {"label": "Tadrart", "value": "C75643"},
                           {"label": "Tafraout", "value": "C75644"},
                           {"label": "Taghazout", "value": "C75645"},
                           {"label": "Taliouine", "value": "C75646"},
                           {"label": "Tamri", "value": "C75647"},
                           {"label": "Tanalt", "value": "C75648"},
                           {"label": "Taroudannt", "value": "C75649"},
                           {"label": "Taroudant", "value": "C75650"},
                           {"label": "Tarsouat", "value": "C75651"},
                           {"label": "Tata", "value": "C75652"},
                           {"label": "Tiznit", "value": "C75653"}],
 "Assaba": [{"label": "Barkewol", "value": "C65941"},
            {"label": "Kiffa", "value": "C65942"}],
 "Assam": [{"label": "Abhayapuri", "value": "C45094"},
           {"label": "Amguri", "value": "C45095"},
           {"label": "Badarpur", "value": "C45096"},
           {"label": "Baksa", "value": "C45097"},
           {"label": "Barpathar", "value": "C45098"},
           {"label": "Barpeta", "value": "C45099"},
           {"label": "Barpeta Road", "value": "C45100"},
           {"label": "Basugaon", "value": "C45101"},
           {"label": "Bihpuriagaon", "value": "C45102"},
           {"label": "Bijni", "value": "C45103"},
           {"label": "Bilasipara", "value": "C45104"},
           {"label": "Bokajan", "value": "C45105"},
           {"label": "Bokakhat", "value": "C45106"},
           {"label": "Bongaigaon", "value": "C45107"},
           {"label": "Cachar", "value": "C45108"},
           {"label": "Chabua", "value": "C45109"},
           {"label": "Chapar", "value": "C45110"},
           {"label": "Chirang", "value": "C45111"},
           {"label": "Darrang", "value": "C45112"},
           {"label": "Dergaon", "value": "C45113"},
           {"label": "Dhekiajuli", "value": "C45114"},
           {"label": "Dhemaji", "value": "C45115"},
           {"label": "Dhing", "value": "C45116"},
           {"label": "Dhubri", "value": "C45117"},
           {"label": "Dibrugarh", "value": "C45118"},
           {"label": "Digboi", "value": "C45119"},
           {"label": "Dima Hasao District", "value": "C45120"},
           {"label": "Diphu", "value": "C45121"},
           {"label": "Dispur", "value": "C45122"},
           {"label": "Duliagaon", "value": "C45123"},
           {"label": "Dum Duma", "value": "C45124"},
           {"label": "Gauripur", "value": "C45125"},
           {"label": "Goalpara", "value": "C45126"},
           {"label": "Gohpur", "value": "C45127"},
           {"label": "Golaghat", "value": "C45128"},
           {"label": "Golakganj", "value": "C45129"},
           {"label": "Goshaingaon", "value": "C45130"},
           {"label": "Guwahati", "value": "C45131"},
           {"label": "Haflong", "value": "C45132"},
           {"label": "Hailakandi", "value": "C45133"},
           {"label": "Hajo", "value": "C45134"},
           {"label": "Hojai", "value": "C45135"},
           {"label": "Howli", "value": "C45136"},
           {"label": "Jogighopa", "value": "C45137"},
           {"label": "Jorhat", "value": "C45138"},
           {"label": "Kamrup", "value": "C45139"},
           {"label": "Kamrup Metropolitan", "value": "C45140"},
           {"label": "Karbi Anglong", "value": "C45141"},
           {"label": "Karimganj", "value": "C45142"},
           {"label": "Kharupatia", "value": "C45143"},
           {"label": "Kokrajhar", "value": "C45144"},
           {"label": "Lakhimpur", "value": "C45145"},
           {"label": "Lakhipur", "value": "C45146"},
           {"label": "Lala", "value": "C45147"},
           {"label": "Lumding Railway Colony", "value": "C45148"},
           {"label": "Mahur", "value": "C45149"},
           {"label": "Maibong", "value": "C45150"},
           {"label": "Makum", "value": "C45151"},
           {"label": "Mangaldai", "value": "C45152"},
           {"label": "Mariani", "value": "C45153"},
           {"label": "Moranha", "value": "C45154"},
           {"label": "Morigaon", "value": "C45155"},
           {"label": "Nagaon", "value": "C45156"},
           {"label": "Nahorkatiya", "value": "C45157"},
           {"label": "Nalbari", "value": "C45158"},
           {"label": "Namrup", "value": "C45159"},
           {"label": "Nazira", "value": "C45160"},
           {"label": "North Guwahati", "value": "C45161"},
           {"label": "North Lakhimpur", "value": "C45162"},
           {"label": "Numaligarh", "value": "C45163"},
           {"label": "Palasbari", "value": "C45164"},
           {"label": "Raha", "value": "C45165"},
           {"label": "Rangapara", "value": "C45166"},
           {"label": "Rangia", "value": "C45167"},
           {"label": "Sapatgram", "value": "C45168"},
           {"label": "Sarupathar", "value": "C45169"},
           {"label": "Sibsagar", "value": "C45170"},
           {"label": "Silapathar", "value": "C45171"},
           {"label": "Silchar", "value": "C45172"},
           {"label": "Soalkuchi", "value": "C45173"},
           {"label": "Sonari", "value": "C45174"},
           {"label": "Sonitpur", "value": "C45175"},
           {"label": "Sorbhog", "value": "C45176"},
           {"label": "Tezpur", "value": "C45177"},
           {"label": "Tinsukia", "value": "C45178"},
           {"label": "Titabar", "value": "C45179"},
           {"label": "Udalguri", "value": "C45180"}],
 "Astara District": [{"label": "Astara", "value": "C8381"},
                     {"label": "Kizhaba", "value": "C8382"}],
 "Astrakhan Oblast": [{"label": "Akhtubinsk", "value": "C100472"},
                      {"label": "Akhtubinskiy Rayon", "value": "C100473"},
                      {"label": "Aksarayskiy", "value": "C100474"},
                      {"label": "Astrakhan", "value": "C100475"},
                      {"label": "Chyorny Yar", "value": "C100476"},
                      {"label": "Ikryanoye", "value": "C100477"},
                      {"label": "Kamyzyak", "value": "C100478"},
                      {"label": "Kapustin Yar", "value": "C100479"},
                      {"label": "Karalat", "value": "C100480"},
                      {"label": "Kharabali", "value": "C100481"},
                      {"label": "Kirovskiy", "value": "C100482"},
                      {"label": "Krasnoyarskiy Rayon", "value": "C100483"},
                      {"label": "Krasnyy Yar", "value": "C100484"},
                      {"label": "Krasnyye Barrikady", "value": "C100485"},
                      {"label": "Liman", "value": "C100486"},
                      {"label": "Limanskiy Rayon", "value": "C100487"},
                      {"label": "Marfino", "value": "C100488"},
                      {"label": "Mumra", "value": "C100489"},
                      {"label": "Nachalovo", "value": "C100490"},
                      {"label": "Narimanov", "value": "C100491"},
                      {"label": "Nizhniy Baskunchak", "value": "C100492"},
                      {"label": "Oranzherei", "value": "C100493"},
                      {"label": "Poldnevoye", "value": "C100494"},
                      {"label": "Privolzhskiy Rayon", "value": "C100495"},
                      {"label": "Sasykoli", "value": "C100496"},
                      {"label": "Sizyy Bugor", "value": "C100497"},
                      {"label": "Solyanka", "value": "C100498"},
                      {"label": "Starokucherganovka", "value": "C100499"},
                      {"label": "Tambovka", "value": "C100500"},
                      {"label": "Trudfront", "value": "C100501"},
                      {"label": "Tumak", "value": "C100502"},
                      {"label": "Uvary", "value": "C100503"},
                      {"label": "Verkhniy Baskunchak", "value": "C100504"},
                      {"label": "Volgo-Kaspiyskiy", "value": "C100505"},
                      {"label": "Volodarskiy", "value": "C100506"},
                      {"label": "Yaksatovo", "value": "C100507"},
                      {"label": "Yandyki", "value": "C100508"},
                      {"label": "Yenotayevka", "value": "C100509"},
                      {"label": "Zenzeli", "value": "C100510"},
                      {"label": "Znamensk", "value": "C100511"}],
 "Aswan": [{"label": "Abu Simbel", "value": "C24215"},
           {"label": "Aswan", "value": "C24216"},
           {"label": "Idfu", "value": "C24217"},
           {"label": "Kawm Umbu", "value": "C24218"}],
 "Asyut": [{"label": "Abnub", "value": "C24219"},
           {"label": "Abu Tij", "value": "C24220"},
           {"label": "Al Badari", "value": "C24221"},
           {"label": "Al Qusiyah", "value": "C24222"},
           {"label": "Asyut", "value": "C24223"},
           {"label": "Dayrut", "value": "C24224"},
           {"label": "Manfalut", "value": "C24225"}],
 "Atacama": [{"label": "Alto del Carmen", "value": "C17915"},
             {"label": "Caldera", "value": "C17916"},
             {"label": "Chanaral", "value": "C17917"},
             {"label": "Copiapo", "value": "C17918"},
             {"label": "Diego de Almagro", "value": "C17919"},
             {"label": "Freirina", "value": "C17920"},
             {"label": "Huasco", "value": "C17921"},
             {"label": "Tierra Amarilla", "value": "C17922"},
             {"label": "Vallenar", "value": "C17923"}],
 "Atakora Department": [{"label": "Guilmaro", "value": "C9597"},
                        {"label": "Natitingou", "value": "C9598"},
                        {"label": "Tanguieta", "value": "C9599"}],
 "Atlantico": [{"label": "Baranoa", "value": "C19680"},
               {"label": "Barranquilla", "value": "C19681"},
               {"label": "Campo de la Cruz", "value": "C19682"},
               {"label": "Candelaria", "value": "C19683"},
               {"label": "Galapa", "value": "C19684"},
               {"label": "Juan de Acosta", "value": "C19685"},
               {"label": "Luruaco", "value": "C19686"},
               {"label": "Malambo", "value": "C19687"},
               {"label": "Manati", "value": "C19688"},
               {"label": "Palmar de Varela", "value": "C19689"},
               {"label": "Piojo", "value": "C19690"},
               {"label": "Polonuevo", "value": "C19691"},
               {"label": "Ponedera", "value": "C19692"},
               {"label": "Puerto Colombia", "value": "C19693"},
               {"label": "Repelon", "value": "C19694"},
               {"label": "Sabanagrande", "value": "C19695"},
               {"label": "Sabanalarga", "value": "C19696"},
               {"label": "Santa Lucia", "value": "C19697"},
               {"label": "Santo Tomas", "value": "C19698"},
               {"label": "Soledad", "value": "C19699"},
               {"label": "Suan", "value": "C19700"},
               {"label": "Tubara", "value": "C19701"},
               {"label": "Usiacuri", "value": "C19702"}],
 "Atlantida Department": [{"label": "Arizona", "value": "C43203"},
                          {"label": "Atenas de San Cristobal",
                           "value": "C43204"},
                          {"label": "Corozal", "value": "C43205"},
                          {"label": "El Pino", "value": "C43206"},
                          {"label": "El Porvenir", "value": "C43207"},
                          {"label": "El Triunfo de la Cruz", "value": "C43208"},
                          {"label": "Esparta", "value": "C43209"},
                          {"label": "Jutiapa", "value": "C43210"},
                          {"label": "La Ceiba", "value": "C43211"},
                          {"label": "La Masica", "value": "C43212"},
                          {"label": "La Union", "value": "C43213"},
                          {"label": "Mezapa", "value": "C43214"},
                          {"label": "Nueva Armenia", "value": "C43215"},
                          {"label": "Sambo Creek", "value": "C43216"},
                          {"label": "San Antonio", "value": "C43217"},
                          {"label": "San Francisco", "value": "C43218"},
                          {"label": "San Juan Pueblo", "value": "C43219"},
                          {"label": "Santa Ana", "value": "C43220"},
                          {"label": "Tela", "value": "C43221"},
                          {"label": "Tornabe", "value": "C43222"}],
 "Atlantique Department": [{"label": "Abomey-Calavi", "value": "C9600"},
                           {"label": "Allada", "value": "C9601"},
                           {"label": "Hevie", "value": "C9602"},
                           {"label": "Hinvi", "value": "C9603"},
                           {"label": "Ouidah", "value": "C9604"}],
 "Attapeu Province": [{"label": "Attapeu", "value": "C64763"},
                      {"label": "Muang Phouvong", "value": "C64764"},
                      {"label": "Muang Samakhixai", "value": "C64765"},
                      {"label": "Muang Sanamxai", "value": "C64766"},
                      {"label": "Muang Sanxai", "value": "C64767"},
                      {"label": "Muang Xaisettha", "value": "C64768"}],
 "Attard": [{"label": "Attard", "value": "C65869"}],
 "Attica Region": [{"label": "Acharnes", "value": "C41583"},
                   {"label": "Aegina", "value": "C41584"},
                   {"label": "Afidnes", "value": "C41585"},
                   {"label": "Aghios Panteleimon", "value": "C41586"},
                   {"label": "Agia Marina", "value": "C41587"},
                   {"label": "Agia Paraskevi", "value": "C41588"},
                   {"label": "Agia Varvara", "value": "C41589"},
                   {"label": "agioi Anargyroi", "value": "C41590"},
                   {"label": "Agios Dimitrios", "value": "C41591"},
                   {"label": "Agios Dimitrios Kropias", "value": "C41592"},
                   {"label": "Agios Ioannis Rentis", "value": "C41593"},
                   {"label": "agios Stefanos", "value": "C41594"},
                   {"label": "Aianteio", "value": "C41595"},
                   {"label": "Aigaleo", "value": "C41596"},
                   {"label": "alimos", "value": "C41597"},
                   {"label": "Ampelakia", "value": "C41598"},
                   {"label": "Anavyssos", "value": "C41599"},
                   {"label": "ano Liosia", "value": "C41600"},
                   {"label": "Anoixi", "value": "C41601"},
                   {"label": "Anthousa", "value": "C41602"},
                   {"label": "Argithea", "value": "C41603"},
                   {"label": "Argyroupoli", "value": "C41604"},
                   {"label": "Artemida", "value": "C41605"},
                   {"label": "Aspropyrgos", "value": "C41606"},
                   {"label": "Athens", "value": "C41607"},
                   {"label": "Avlonas", "value": "C41608"},
                   {"label": "ayioi Apostoloi", "value": "C41609"},
                   {"label": "Chaidari", "value": "C41610"},
                   {"label": "Cholargos", "value": "C41611"},
                   {"label": "Dhafni", "value": "C41612"},
                   {"label": "Dhrafi", "value": "C41613"},
                   {"label": "Dioni", "value": "C41614"},
                   {"label": "Dionysos", "value": "C41615"},
                   {"label": "Drapetsona", "value": "C41616"},
                   {"label": "Drosia", "value": "C41617"},
                   {"label": "Ekali", "value": "C41618"},
                   {"label": "Elefsina", "value": "C41619"},
                   {"label": "Elliniko", "value": "C41620"},
                   {"label": "Erythres", "value": "C41621"},
                   {"label": "Filothei", "value": "C41622"},
                   {"label": "Fyli", "value": "C41623"},
                   {"label": "Galatas", "value": "C41624"},
                   {"label": "Galatsi", "value": "C41625"},
                   {"label": "Gerakas", "value": "C41626"},
                   {"label": "Glyfada", "value": "C41627"},
                   {"label": "Grammatiko", "value": "C41628"},
                   {"label": "ilion", "value": "C41629"},
                   {"label": "Ilioupoli", "value": "C41630"},
                   {"label": "Irakleio", "value": "C41631"},
                   {"label": "Kaisariani", "value": "C41632"},
                   {"label": "Kalamos", "value": "C41633"},
                   {"label": "Kallithea", "value": "C41634"},
                   {"label": "Kalyvia Thorikou", "value": "C41635"},
                   {"label": "Kamateron", "value": "C41636"},
                   {"label": "Kapandriti", "value": "C41637"},
                   {"label": "Karellas", "value": "C41638"},
                   {"label": "Kato Soulion", "value": "C41639"},
                   {"label": "Keratea", "value": "C41640"},
                   {"label": "Keratsini", "value": "C41641"},
                   {"label": "Khalandrion", "value": "C41642"},
                   {"label": "Khalkoutsion", "value": "C41643"},
                   {"label": "Kifisia", "value": "C41644"},
                   {"label": "Kineta", "value": "C41645"},
                   {"label": "Kipseli", "value": "C41646"},
                   {"label": "Kitsi", "value": "C41647"},
                   {"label": "Koropi", "value": "C41648"},
                   {"label": "Korydallos", "value": "C41649"},
                   {"label": "Kouvaras", "value": "C41650"},
                   {"label": "Kryoneri", "value": "C41651"},
                   {"label": "Kypseli", "value": "C41652"},
                   {"label": "Kythira", "value": "C41653"},
                   {"label": "Lavrio", "value": "C41654"},
                   {"label": "Leondarion", "value": "C41655"},
                   {"label": "Limin Mesoyaias", "value": "C41656"},
                   {"label": "Lykovrysi", "value": "C41657"},
                   {"label": "Magoula", "value": "C41658"},
                   {"label": "Mandra", "value": "C41659"},
                   {"label": "Marathonas", "value": "C41660"},
                   {"label": "Markopoulo", "value": "C41661"},
                   {"label": "Markopoulo Oropou", "value": "C41662"},
                   {"label": "Marousi", "value": "C41663"},
                   {"label": "Megalochori", "value": "C41664"},
                   {"label": "Megara", "value": "C41665"},
                   {"label": "Melissia", "value": "C41666"},
                   {"label": "Metamorfosi", "value": "C41667"},
                   {"label": "Moskhaton", "value": "C41668"},
                   {"label": "Nea Chalkidona", "value": "C41669"},
                   {"label": "Nea Erythraia", "value": "C41670"},
                   {"label": "Nea Filadelfeia", "value": "C41671"},
                   {"label": "Nea Ionia", "value": "C41672"},
                   {"label": "Nea Makri", "value": "C41673"},
                   {"label": "Nea Palatia", "value": "C41674"},
                   {"label": "Nea Penteli", "value": "C41675"},
                   {"label": "Nea Peramos", "value": "C41676"},
                   {"label": "Nea Smyrni", "value": "C41677"},
                   {"label": "Neo Psychiko", "value": "C41678"},
                   {"label": "Neos Voutzas", "value": "C41679"},
                   {"label": "Nikaia", "value": "C41680"},
                   {"label": "Oropos", "value": "C41681"},
                   {"label": "Paiania", "value": "C41682"},
                   {"label": "Palaia Fokaia", "value": "C41683"},
                   {"label": "Palaio Faliro", "value": "C41684"},
                   {"label": "Pallini", "value": "C41685"},
                   {"label": "Papagou", "value": "C41686"},
                   {"label": "Pefki", "value": "C41687"},
                   {"label": "Penteli", "value": "C41688"},
                   {"label": "Perama", "value": "C41689"},
                   {"label": "Peristeri", "value": "C41690"},
                   {"label": "Petroupolis", "value": "C41691"},
                   {"label": "Pikermi", "value": "C41692"},
                   {"label": "Piraeus", "value": "C41693"},
                   {"label": "Polydendri", "value": "C41694"},
                   {"label": "Poros", "value": "C41695"},
                   {"label": "Psychiko", "value": "C41696"},
                   {"label": "Rafina", "value": "C41697"},
                   {"label": "Rodopoli", "value": "C41698"},
                   {"label": "Salamina", "value": "C41699"},
                   {"label": "Saronida", "value": "C41700"},
                   {"label": "Selinia", "value": "C41701"},
                   {"label": "Skala Oropou", "value": "C41702"},
                   {"label": "Skarmagkas", "value": "C41703"},
                   {"label": "Spata", "value": "C41704"},
                   {"label": "Spetses", "value": "C41705"},
                   {"label": "Stamata", "value": "C41706"},
                   {"label": "Tavros", "value": "C41707"},
                   {"label": "Thrakomakedones", "value": "C41708"},
                   {"label": "Vari", "value": "C41709"},
                   {"label": "Varnavas", "value": "C41710"},
                   {"label": "Varybobi", "value": "C41711"},
                   {"label": "Vathy", "value": "C41712"},
                   {"label": "Vilia", "value": "C41713"},
                   {"label": "Vlychada", "value": "C41714"},
                   {"label": "Voula", "value": "C41715"},
                   {"label": "Vouliagmeni", "value": "C41716"},
                   {"label": "Vrana", "value": "C41717"},
                   {"label": "Vrilissia", "value": "C41718"},
                   {"label": "Vyronas", "value": "C41719"},
                   {"label": "Ydra", "value": "C41720"},
                   {"label": "Ymittos", "value": "C41721"},
                   {"label": "Zefyri", "value": "C41722"},
                   {"label": "Zografos", "value": "C41723"}],
 "Atua": [{"label": "Falefa", "value": "C106230"},
          {"label": "Lotofaga", "value": "C106231"},
          {"label": "Lufilufi", "value": "C106232"},
          {"label": "Solosolo", "value": "C106233"}],
 "Atyrau Region": [{"label": "Akkol", "value": "C64333"},
                   {"label": "Atyrau", "value": "C64334"},
                   {"label": "Balykshi", "value": "C64335"},
                   {"label": "Bayshonas", "value": "C64336"},
                   {"label": "Dossor", "value": "C64337"},
                   {"label": "Inderbor", "value": "C64338"},
                   {"label": "Makhambet", "value": "C64339"},
                   {"label": "Maloye Ganyushkino", "value": "C64340"},
                   {"label": "Maqat", "value": "C64341"},
                   {"label": "Miyaly", "value": "C64342"},
                   {"label": "Qaraton", "value": "C64343"},
                   {"label": "Qulsary", "value": "C64344"},
                   {"label": "Shalkar", "value": "C64345"}],
 "Auce Municipality": [{"label": "Auce", "value": "C64848"}],
 "Auckland Region": [{"label": "Auckland", "value": "C77729"},
                     {"label": "Mangere", "value": "C77730"},
                     {"label": "Manukau City", "value": "C77731"},
                     {"label": "Muriwai Beach", "value": "C77732"},
                     {"label": "Murrays Bay", "value": "C77733"},
                     {"label": "North Shore", "value": "C77734"},
                     {"label": "Pakuranga", "value": "C77735"},
                     {"label": "Papakura", "value": "C77736"},
                     {"label": "Parakai", "value": "C77737"},
                     {"label": "Pukekohe East", "value": "C77738"},
                     {"label": "Red Hill", "value": "C77739"},
                     {"label": "Rosebank", "value": "C77740"},
                     {"label": "Rothesay Bay", "value": "C77741"},
                     {"label": "Takanini", "value": "C77742"},
                     {"label": "Tamaki", "value": "C77743"},
                     {"label": "Titirangi", "value": "C77744"},
                     {"label": "Waitakere", "value": "C77745"},
                     {"label": "Waiuku", "value": "C77746"},
                     {"label": "Warkworth", "value": "C77747"},
                     {"label": "Wellsford", "value": "C77748"},
                     {"label": "Wiri", "value": "C77749"}],
 "Australian Capital Territory": [{"label": "Acton", "value": "C1854"},
                                  {"label": "Ainslie", "value": "C1855"},
                                  {"label": "Amaroo", "value": "C1856"},
                                  {"label": "Aranda", "value": "C1857"},
                                  {"label": "Banks", "value": "C1858"},
                                  {"label": "Barton", "value": "C1859"},
                                  {"label": "Belconnen", "value": "C1860"},
                                  {"label": "Bonner", "value": "C1861"},
                                  {"label": "Bonython", "value": "C1862"},
                                  {"label": "Braddon", "value": "C1863"},
                                  {"label": "Bruce", "value": "C1864"},
                                  {"label": "Calwell", "value": "C1865"},
                                  {"label": "Campbell", "value": "C1866"},
                                  {"label": "Canberra", "value": "C1867"},
                                  {"label": "Casey", "value": "C1868"},
                                  {"label": "Chapman", "value": "C1869"},
                                  {"label": "Charnwood", "value": "C1870"},
                                  {"label": "Chifley", "value": "C1871"},
                                  {"label": "Chisholm", "value": "C1872"},
                                  {"label": "City", "value": "C1873"},
                                  {"label": "Conder", "value": "C1874"},
                                  {"label": "Cook", "value": "C1875"},
                                  {"label": "Coombs", "value": "C1876"},
                                  {"label": "Crace", "value": "C1877"},
                                  {"label": "Curtin", "value": "C1878"},
                                  {"label": "Deakin", "value": "C1879"},
                                  {"label": "Dickson", "value": "C1880"},
                                  {"label": "Downer", "value": "C1881"},
                                  {"label": "Duffy", "value": "C1882"},
                                  {"label": "Dunlop", "value": "C1883"},
                                  {"label": "Evatt", "value": "C1884"},
                                  {"label": "Fadden", "value": "C1885"},
                                  {"label": "Farrer", "value": "C1886"},
                                  {"label": "Fisher", "value": "C1887"},
                                  {"label": "Florey", "value": "C1888"},
                                  {"label": "Flynn", "value": "C1889"},
                                  {"label": "Forde", "value": "C1890"},
                                  {"label": "Forrest", "value": "C1891"},
                                  {"label": "Franklin", "value": "C1892"},
                                  {"label": "Fraser", "value": "C1893"},
                                  {"label": "Garran", "value": "C1894"},
                                  {"label": "Gilmore", "value": "C1895"},
                                  {"label": "Giralang", "value": "C1896"},
                                  {"label": "Gordon", "value": "C1897"},
                                  {"label": "Gowrie", "value": "C1898"},
                                  {"label": "Greenway", "value": "C1899"},
                                  {"label": "Griffith", "value": "C1900"},
                                  {"label": "Gungahlin", "value": "C1901"},
                                  {"label": "Hackett", "value": "C1902"},
                                  {"label": "Harrison", "value": "C1903"},
                                  {"label": "Hawker", "value": "C1904"},
                                  {"label": "Higgins", "value": "C1905"},
                                  {"label": "Holder", "value": "C1906"},
                                  {"label": "Holt", "value": "C1907"},
                                  {"label": "Hughes", "value": "C1908"},
                                  {"label": "Isaacs", "value": "C1909"},
                                  {"label": "Isabella Plains",
                                   "value": "C1910"},
                                  {"label": "Kaleen", "value": "C1911"},
                                  {"label": "Kambah", "value": "C1912"},
                                  {"label": "Kingston", "value": "C1913"},
                                  {"label": "Latham", "value": "C1914"},
                                  {"label": "Lyneham", "value": "C1915"},
                                  {"label": "Lyons", "value": "C1916"},
                                  {"label": "Macarthur", "value": "C1917"},
                                  {"label": "Macgregor", "value": "C1918"},
                                  {"label": "Macquarie", "value": "C1919"},
                                  {"label": "Mawson", "value": "C1920"},
                                  {"label": "McKellar", "value": "C1921"},
                                  {"label": "Melba", "value": "C1922"},
                                  {"label": "Monash", "value": "C1923"},
                                  {"label": "Narrabundah", "value": "C1924"},
                                  {"label": "Ngunnawal", "value": "C1925"},
                                  {"label": "Nicholls", "value": "C1926"},
                                  {"label": "OConnor", "value": "C1927"},
                                  {"label": "Oxley", "value": "C1928"},
                                  {"label": "Page", "value": "C1929"},
                                  {"label": "Palmerston", "value": "C1930"},
                                  {"label": "Pearce", "value": "C1931"},
                                  {"label": "Phillip", "value": "C1932"},
                                  {"label": "Red Hill", "value": "C1933"},
                                  {"label": "Reid", "value": "C1934"},
                                  {"label": "Richardson", "value": "C1935"},
                                  {"label": "Rivett", "value": "C1936"},
                                  {"label": "Scullin", "value": "C1937"},
                                  {"label": "Spence", "value": "C1938"},
                                  {"label": "Stirling", "value": "C1939"},
                                  {"label": "Theodore", "value": "C1940"},
                                  {"label": "Torrens", "value": "C1941"},
                                  {"label": "Turner", "value": "C1942"},
                                  {"label": "Wanniassa", "value": "C1943"},
                                  {"label": "Waramanga", "value": "C1944"},
                                  {"label": "Watson", "value": "C1945"},
                                  {"label": "Weetangera", "value": "C1946"},
                                  {"label": "Weston", "value": "C1947"},
                                  {"label": "Wright", "value": "C1948"},
                                  {"label": "Yarralumla", "value": "C1949"}],
 "Autonomous Region in Muslim Mindanao": [{"label": "Ampatuan",
                                           "value": "C82862"},
                                          {"label": "Andalan",
                                           "value": "C82863"},
                                          {"label": "Anuling",
                                           "value": "C82864"},
                                          {"label": "Awang", "value": "C82865"},
                                          {"label": "Bacayawan",
                                           "value": "C82866"},
                                          {"label": "Badak", "value": "C82867"},
                                          {"label": "Bagan", "value": "C82868"},
                                          {"label": "Baka", "value": "C82869"},
                                          {"label": "Bakung",
                                           "value": "C82870"},
                                          {"label": "Balabagan",
                                           "value": "C82871"},
                                          {"label": "Balas", "value": "C82872"},
                                          {"label": "Balimbing",
                                           "value": "C82873"},
                                          {"label": "Balindong",
                                           "value": "C82874"},
                                          {"label": "Bangkal",
                                           "value": "C82875"},
                                          {"label": "Bankaw",
                                           "value": "C82876"},
                                          {"label": "Barira",
                                           "value": "C82877"},
                                          {"label": "Barurao",
                                           "value": "C82878"},
                                          {"label": "Bato Bato",
                                           "value": "C82879"},
                                          {"label": "Baunu-Timbangan",
                                           "value": "C82880"},
                                          {"label": "Bawison",
                                           "value": "C82881"},
                                          {"label": "Bayang",
                                           "value": "C82882"},
                                          {"label": "Bayanga",
                                           "value": "C82883"},
                                          {"label": "Begang",
                                           "value": "C82884"},
                                          {"label": "Binidayan",
                                           "value": "C82885"},
                                          {"label": "Binuang",
                                           "value": "C82886"},
                                          {"label": "Blinsung",
                                           "value": "C82887"},
                                          {"label": "Bongao",
                                           "value": "C82888"},
                                          {"label": "Bongued",
                                           "value": "C82889"},
                                          {"label": "Buadiposo-Buntong",
                                           "value": "C82890"},
                                          {"label": "Bualan",
                                           "value": "C82891"},
                                          {"label": "Buan", "value": "C82892"},
                                          {"label": "Buansa",
                                           "value": "C82893"},
                                          {"label": "Budta", "value": "C82894"},
                                          {"label": "Bugasan",
                                           "value": "C82895"},
                                          {"label": "Buldon",
                                           "value": "C82896"},
                                          {"label": "Buliok",
                                           "value": "C82897"},
                                          {"label": "Bulit", "value": "C82898"},
                                          {"label": "Buluan",
                                           "value": "C82899"},
                                          {"label": "Bumbaran",
                                           "value": "C82900"},
                                          {"label": "Butig", "value": "C82901"},
                                          {"label": "Calanogas",
                                           "value": "C82902"},
                                          {"label": "City of Isabela",
                                           "value": "C82903"},
                                          {"label": "Colonia",
                                           "value": "C82904"},
                                          {"label": "Cotabato",
                                           "value": "C82905"},
                                          {"label": "Dado", "value": "C82906"},
                                          {"label": "Dadus", "value": "C82907"},
                                          {"label": "Dalican",
                                           "value": "C82908"},
                                          {"label": "Dalumangcob",
                                           "value": "C82909"},
                                          {"label": "Damabalas",
                                           "value": "C82910"},
                                          {"label": "Damatulan",
                                           "value": "C82911"},
                                          {"label": "Datu Paglas",
                                           "value": "C82912"},
                                          {"label": "Datu Piang",
                                           "value": "C82913"},
                                          {"label": "Digal", "value": "C82914"},
                                          {"label": "Dinaig",
                                           "value": "C82915"},
                                          {"label": "Dinganen",
                                           "value": "C82916"},
                                          {"label": "Ebcor Town",
                                           "value": "C82917"},
                                          {"label": "Gadung",
                                           "value": "C82918"},
                                          {"label": "Ganassi",
                                           "value": "C82919"},
                                          {"label": "Gang", "value": "C82920"},
                                          {"label": "Guiong",
                                           "value": "C82921"},
                                          {"label": "Idtig", "value": "C82922"},
                                          {"label": "Jolo", "value": "C82923"},
                                          {"label": "Kabasalan",
                                           "value": "C82924"},
                                          {"label": "Kagay", "value": "C82925"},
                                          {"label": "Kajatian",
                                           "value": "C82926"},
                                          {"label": "Kalang",
                                           "value": "C82927"},
                                          {"label": "Kalbugan",
                                           "value": "C82928"},
                                          {"label": "Kambing",
                                           "value": "C82929"},
                                          {"label": "Kanlagay",
                                           "value": "C82930"},
                                          {"label": "Kansipati",
                                           "value": "C82931"},
                                          {"label": "Karungdong",
                                           "value": "C82932"},
                                          {"label": "Katico",
                                           "value": "C82933"},
                                          {"label": "Katidtuan",
                                           "value": "C82934"},
                                          {"label": "Katuli",
                                           "value": "C82935"},
                                          {"label": "Kauran",
                                           "value": "C82936"},
                                          {"label": "Kitango",
                                           "value": "C82937"},
                                          {"label": "Kitapak",
                                           "value": "C82938"},
                                          {"label": "Kolape",
                                           "value": "C82939"},
                                          {"label": "Kulase",
                                           "value": "C82940"},
                                          {"label": "Kulay-Kulay",
                                           "value": "C82941"},
                                          {"label": "Kulempang",
                                           "value": "C82942"},
                                          {"label": "Kungtad",
                                           "value": "C82943"},
                                          {"label": "Labungan",
                                           "value": "C82944"},
                                          {"label": "Laminusa",
                                           "value": "C82945"},
                                          {"label": "Lamitan City",
                                           "value": "C82946"},
                                          {"label": "Langpas",
                                           "value": "C82947"},
                                          {"label": "Languyan",
                                           "value": "C82948"},
                                          {"label": "Larap", "value": "C82949"},
                                          {"label": "Latung",
                                           "value": "C82950"},
                                          {"label": "Layog", "value": "C82951"},
                                          {"label": "Ligayan",
                                           "value": "C82952"},
                                          {"label": "Limbo", "value": "C82953"},
                                          {"label": "Litayan",
                                           "value": "C82954"},
                                          {"label": "Lookan",
                                           "value": "C82955"},
                                          {"label": "Lu-uk", "value": "C82956"},
                                          {"label": "Lugus", "value": "C82957"},
                                          {"label": "Lumbac",
                                           "value": "C82958"},
                                          {"label": "Lumbatan",
                                           "value": "C82959"},
                                          {"label": "Lumbayanague",
                                           "value": "C82960"},
                                          {"label": "Lutayan",
                                           "value": "C82961"},
                                          {"label": "Luuk Datan",
                                           "value": "C82962"},
                                          {"label": "Madalum",
                                           "value": "C82963"},
                                          {"label": "Madamba",
                                           "value": "C82964"},
                                          {"label": "Maganoy",
                                           "value": "C82965"},
                                          {"label": "Mahala",
                                           "value": "C82966"},
                                          {"label": "Maimbung",
                                           "value": "C82967"},
                                          {"label": "Makir", "value": "C82968"},
                                          {"label": "Malabang",
                                           "value": "C82969"},
                                          {"label": "Maluso",
                                           "value": "C82970"},
                                          {"label": "Manubul",
                                           "value": "C82971"},
                                          {"label": "Manuk Mangkaw",
                                           "value": "C82972"},
                                          {"label": "Marantao",
                                           "value": "C82973"},
                                          {"label": "Marawi City",
                                           "value": "C82974"},
                                          {"label": "Marogong",
                                           "value": "C82975"},
                                          {"label": "Marsada",
                                           "value": "C82976"},
                                          {"label": "Masiu", "value": "C82977"},
                                          {"label": "Matanog",
                                           "value": "C82978"},
                                          {"label": "Mataya",
                                           "value": "C82979"},
                                          {"label": "Mauboh",
                                           "value": "C82980"},
                                          {"label": "Mileb", "value": "C82981"},
                                          {"label": "New Batu Batu",
                                           "value": "C82982"},
                                          {"label": "Nuyo", "value": "C82983"},
                                          {"label": "Pagalungan",
                                           "value": "C82984"},
                                          {"label": "Pagatin",
                                           "value": "C82985"},
                                          {"label": "Paitan",
                                           "value": "C82986"},
                                          {"label": "Panabuan",
                                           "value": "C82987"},
                                          {"label": "Panadtaban",
                                           "value": "C82988"},
                                          {"label": "Pandakan",
                                           "value": "C82989"},
                                          {"label": "Pandan Niog",
                                           "value": "C82990"},
                                          {"label": "Pang", "value": "C82991"},
                                          {"label": "Parang",
                                           "value": "C82992"},
                                          {"label": "Parangan",
                                           "value": "C82993"},
                                          {"label": "Parian Dakula",
                                           "value": "C82994"},
                                          {"label": "Patikul",
                                           "value": "C82995"},
                                          {"label": "Pawak", "value": "C82996"},
                                          {"label": "Payuhan",
                                           "value": "C82997"},
                                          {"label": "Pidsandawan",
                                           "value": "C82998"},
                                          {"label": "Pinaring",
                                           "value": "C82999"},
                                          {"label": "Pitogo",
                                           "value": "C83000"},
                                          {"label": "Polloc",
                                           "value": "C83001"},
                                          {"label": "Province of Basilan",
                                           "value": "C83002"},
                                          {"label": "Province of Lanao del Sur",
                                           "value": "C83003"},
                                          {"label": "Province of Maguindanao",
                                           "value": "C83004"},
                                          {"label": "Province of Sulu",
                                           "value": "C83005"},
                                          {"label": "Province of Tawi-Tawi",
                                           "value": "C83006"},
                                          {"label": "Pualas",
                                           "value": "C83007"},
                                          {"label": "Punay", "value": "C83008"},
                                          {"label": "Ramain",
                                           "value": "C83009"},
                                          {"label": "Rimpeso",
                                           "value": "C83010"},
                                          {"label": "Rominimbang",
                                           "value": "C83011"},
                                          {"label": "Sagasa",
                                           "value": "C83012"},
                                          {"label": "Saguiaran",
                                           "value": "C83013"},
                                          {"label": "Sambuluan",
                                           "value": "C83014"},
                                          {"label": "Sanga-Sanga",
                                           "value": "C83015"},
                                          {"label": "Santa Clara",
                                           "value": "C83016"},
                                          {"label": "Sapa", "value": "C83017"},
                                          {"label": "Sapadun",
                                           "value": "C83018"},
                                          {"label": "Satan", "value": "C83019"},
                                          {"label": "Semut", "value": "C83020"},
                                          {"label": "Siasi", "value": "C83021"},
                                          {"label": "Simbahan",
                                           "value": "C83022"},
                                          {"label": "Simuay",
                                           "value": "C83023"},
                                          {"label": "Simunul",
                                           "value": "C83024"},
                                          {"label": "Sionogan",
                                           "value": "C83025"},
                                          {"label": "Sitangkai",
                                           "value": "C83026"},
                                          {"label": "South Upi",
                                           "value": "C83027"},
                                          {"label": "Sultan Kudarat",
                                           "value": "C83028"},
                                          {"label": "Sumisip",
                                           "value": "C83029"},
                                          {"label": "Tabiauan",
                                           "value": "C83030"},
                                          {"label": "Tablas",
                                           "value": "C83031"},
                                          {"label": "Taganak",
                                           "value": "C83032"},
                                          {"label": "Tairan Camp",
                                           "value": "C83033"},
                                          {"label": "Talayan",
                                           "value": "C83034"},
                                          {"label": "Talipao",
                                           "value": "C83035"},
                                          {"label": "Talipaw",
                                           "value": "C83036"},
                                          {"label": "Talitay",
                                           "value": "C83037"},
                                          {"label": "Tampakan",
                                           "value": "C83038"},
                                          {"label": "Tamparan",
                                           "value": "C83039"},
                                          {"label": "Tapayan",
                                           "value": "C83040"},
                                          {"label": "Tapikan",
                                           "value": "C83041"},
                                          {"label": "Taungoh",
                                           "value": "C83042"},
                                          {"label": "Taviran",
                                           "value": "C83043"},
                                          {"label": "Tinutulan",
                                           "value": "C83044"},
                                          {"label": "Tipo-Tipo",
                                           "value": "C83045"},
                                          {"label": "Tongouson",
                                           "value": "C83046"},
                                          {"label": "Tubaran",
                                           "value": "C83047"},
                                          {"label": "Tuburan",
                                           "value": "C83048"},
                                          {"label": "Tugaya",
                                           "value": "C83049"},
                                          {"label": "Tumbagaan",
                                           "value": "C83050"},
                                          {"label": "Tunggol",
                                           "value": "C83051"},
                                          {"label": "Tungol",
                                           "value": "C83052"},
                                          {"label": "Ungus-Ungus",
                                           "value": "C83053"},
                                          {"label": "Upi", "value": "C83054"},
                                          {"label": "Uyaan", "value": "C83055"},
                                          {"label": "Wao", "value": "C83056"}],
 "Autonomous Republic of Abkhazia": [{"label": "Bichvinta", "value": "C34264"},
                                     {"label": "Dranda", "value": "C34265"},
                                     {"label": "Gagra", "value": "C34266"},
                                     {"label": "Gali", "value": "C34267"},
                                     {"label": "Gantiadi", "value": "C34268"},
                                     {"label": "Gudauta", "value": "C34269"},
                                     {"label": "Kelasuri", "value": "C34270"},
                                     {"label": "Ochamchire", "value": "C34271"},
                                     {"label": "Primorskoe", "value": "C34272"},
                                     {"label": "Sokhumi", "value": "C34273"},
                                     {"label": "Stantsiya Novyy Afon",
                                      "value": "C34274"},
                                     {"label": "Tqvarcheli",
                                      "value": "C34275"}],
 "Autonomous Republic of Crimea": [{"label": "Abrikosovka", "value": "C121291"},
                                   {"label": "Abrikosovo", "value": "C121292"},
                                   {"label": "Aeroflotskiy",
                                    "value": "C121293"},
                                   {"label": "Agrarnoye", "value": "C121294"},
                                   {"label": "Akimovka", "value": "C121295"},
                                   {"label": "Aleksandrovka",
                                    "value": "C121296"},
                                   {"label": "Alekseyevka", "value": "C121297"},
                                   {"label": "Alupka", "value": "C121298"},
                                   {"label": "Alushta", "value": "C121299"},
                                   {"label": "Amurskoye", "value": "C121300"},
                                   {"label": "Armyansk", "value": "C121301"},
                                   {"label": "Aromatnoye", "value": "C121302"},
                                   {"label": "Azovskoye", "value": "C121303"},
                                   {"label": "Baherove", "value": "C121304"},
                                   {"label": "Bakhchysarai",
                                    "value": "C121305"},
                                   {"label": "Bakhchysarai Raion",
                                    "value": "C121306"},
                                   {"label": "Batalnoye", "value": "C121307"},
                                   {"label": "Belinskoye", "value": "C121308"},
                                   {"label": "Beloglinka", "value": "C121309"},
                                   {"label": "Belogorskiy rayon",
                                    "value": "C121310"},
                                   {"label": "Beregovoye", "value": "C121311"},
                                   {"label": "Berezovka", "value": "C121312"},
                                   {"label": "Bilohirsk", "value": "C121313"},
                                   {"label": "Blizhneye", "value": "C121314"},
                                   {"label": "Bogatoye", "value": "C121315"},
                                   {"label": "Botanicheskoye",
                                    "value": "C121316"},
                                   {"label": "Bratskoye", "value": "C121317"},
                                   {"label": "Chapayevka", "value": "C121318"},
                                   {"label": "Chaykino", "value": "C121319"},
                                   {"label": "Chelyadinovo",
                                    "value": "C121320"},
                                   {"label": "Chernomorskiy rayon",
                                    "value": "C121321"},
                                   {"label": "Chernomorskoye",
                                    "value": "C121322"},
                                   {"label": "Chernopolye", "value": "C121323"},
                                   {"label": "Chernovo", "value": "C121324"},
                                   {"label": "Chernozemnoye",
                                    "value": "C121325"},
                                   {"label": "Chernyshevo", "value": "C121326"},
                                   {"label": "Chervonoye", "value": "C121327"},
                                   {"label": "Chistenkoye", "value": "C121328"},
                                   {"label": "Chistopolye", "value": "C121329"},
                                   {"label": "Chkalovo", "value": "C121330"},
                                   {"label": "Dachnoye", "value": "C121331"},
                                   {"label": "Dalekoye", "value": "C121332"},
                                   {"label": "Dmitrovka", "value": "C121333"},
                                   {"label": "Dneprovka", "value": "C121334"},
                                   {"label": "Dobroye", "value": "C121335"},
                                   {"label": "Dobrushino", "value": "C121336"},
                                   {"label": "Dolinnoye", "value": "C121337"},
                                   {"label": "Donskoye", "value": "C121338"},
                                   {"label": "Drofino", "value": "C121339"},
                                   {"label": "Dubki", "value": "C121340"},
                                   {"label": "Dzhankoy", "value": "C121341"},
                                   {"label": "Dzhankoyskiy rayon",
                                    "value": "C121342"},
                                   {"label": "Feodosiya", "value": "C121343"},
                                   {"label": "Filatovka", "value": "C121344"},
                                   {"label": "Fontany", "value": "C121345"},
                                   {"label": "Foros", "value": "C121346"},
                                   {"label": "Frunze", "value": "C121347"},
                                   {"label": "Gaspra", "value": "C121348"},
                                   {"label": "Geroyskoye", "value": "C121349"},
                                   {"label": "Glazovka", "value": "C121350"},
                                   {"label": "Golubinka", "value": "C121351"},
                                   {"label": "Gornostayevka",
                                    "value": "C121352"},
                                   {"label": "Gorodskoy okrug Alushta",
                                    "value": "C121353"},
                                   {"label": "Gorodskoy okrug Armyansk",
                                    "value": "C121354"},
                                   {"label": "Gorodskoy okrug Dzhankoy",
                                    "value": "C121355"},
                                   {"label": "Gorodskoy okrug Feodosiya",
                                    "value": "C121356"},
                                   {"label": "Gorodskoy okrug Krasnoperekopsk",
                                    "value": "C121357"},
                                   {"label": "Gorodskoy okrug Saki",
                                    "value": "C121358"},
                                   {"label": "Gorodskoy okrug Simferopol",
                                    "value": "C121359"},
                                   {"label": "Gorodskoy okrug Sudak",
                                    "value": "C121360"},
                                   {"label": "Gorodskoy okrug Yalta",
                                    "value": "C121361"},
                                   {"label": "Gorodskoy okrug Yevpatoriya",
                                    "value": "C121362"},
                                   {"label": "Gresovskiy", "value": "C121363"},
                                   {"label": "Grishino", "value": "C121364"},
                                   {"label": "Grushevka", "value": "C121365"},
                                   {"label": "Gurzuf", "value": "C121366"},
                                   {"label": "Hvardiiske", "value": "C121367"},
                                   {"label": "Ilychyovo", "value": "C121368"},
                                   {"label": "Ilyichevo", "value": "C121369"},
                                   {"label": "Ilyinka", "value": "C121370"},
                                   {"label": "Ishun", "value": "C121371"},
                                   {"label": "Ivanovka", "value": "C121372"},
                                   {"label": "Izobilnoye", "value": "C121373"},
                                   {"label": "Izumrudnoye", "value": "C121374"},
                                   {"label": "Izyumovka", "value": "C121375"},
                                   {"label": "Kalinino", "value": "C121376"},
                                   {"label": "Kalinovka", "value": "C121377"},
                                   {"label": "Kamenolomnya",
                                    "value": "C121378"},
                                   {"label": "Kashtanovoye",
                                    "value": "C121379"},
                                   {"label": "Kashtany", "value": "C121380"},
                                   {"label": "Kerch", "value": "C121381"},
                                   {"label": "Kerchenska Miska Rada",
                                    "value": "C121382"},
                                   {"label": "Kholmovka", "value": "C121383"},
                                   {"label": "Kirovo", "value": "C121384"},
                                   {"label": "Kirovske Raion",
                                    "value": "C121385"},
                                   {"label": "Kirovskoye", "value": "C121386"},
                                   {"label": "Klepinino", "value": "C121387"},
                                   {"label": "Koktebel", "value": "C121388"},
                                   {"label": "Kolchugino", "value": "C121389"},
                                   {"label": "Koltsovo", "value": "C121390"},
                                   {"label": "Kolodeznoye", "value": "C121391"},
                                   {"label": "Koloski", "value": "C121392"},
                                   {"label": "Komsomolskoye",
                                    "value": "C121393"},
                                   {"label": "Kondratyevo", "value": "C121394"},
                                   {"label": "Konstantinovka",
                                    "value": "C121395"},
                                   {"label": "Koreiz", "value": "C121396"},
                                   {"label": "Kormovoye", "value": "C121397"},
                                   {"label": "Kostochkovka",
                                    "value": "C121398"},
                                   {"label": "Kotelnikovo", "value": "C121399"},
                                   {"label": "Kovylnoye", "value": "C121400"},
                                   {"label": "Krasna Zorka",
                                    "value": "C121401"},
                                   {"label": "Krasnaya Polyana",
                                    "value": "C121402"},
                                   {"label": "Krasnoarmeyskoye",
                                    "value": "C121403"},
                                   {"label": "Krasnoflotskoye",
                                    "value": "C121404"},
                                   {"label": "Krasnogorka", "value": "C121405"},
                                   {"label": "Krasnogvardeyskoye",
                                    "value": "C121406"},
                                   {"label": "Krasnohvardiiske Raion",
                                    "value": "C121407"},
                                   {"label": "Krasnokamenka",
                                    "value": "C121408"},
                                   {"label": "Krasnolesye", "value": "C121409"},
                                   {"label": "Krasnoperekopsk",
                                    "value": "C121410"},
                                   {"label": "Krasnoperekopsk Raion",
                                    "value": "C121411"},
                                   {"label": "Krasnoyarskoye",
                                    "value": "C121412"},
                                   {"label": "Krasnoye", "value": "C121413"},
                                   {"label": "Krasnoznamenka",
                                    "value": "C121414"},
                                   {"label": "Krasnyi Mak", "value": "C121415"},
                                   {"label": "Krayneye", "value": "C121416"},
                                   {"label": "Krestyanovka",
                                    "value": "C121417"},
                                   {"label": "Krinichnoye", "value": "C121418"},
                                   {"label": "Krymka", "value": "C121419"},
                                   {"label": "Krymskaya Roza",
                                    "value": "C121420"},
                                   {"label": "Krymskoye", "value": "C121421"},
                                   {"label": "Kuibyshevo", "value": "C121422"},
                                   {"label": "Kukushkino", "value": "C121423"},
                                   {"label": "Kurskoye", "value": "C121424"},
                                   {"label": "Lgovskoye", "value": "C121425"},
                                   {"label": "Lekarstvennoye",
                                    "value": "C121426"},
                                   {"label": "Lenine Raion",
                                    "value": "C121427"},
                                   {"label": "Lenino", "value": "C121428"},
                                   {"label": "Leninskoye", "value": "C121429"},
                                   {"label": "Lesnovka", "value": "C121430"},
                                   {"label": "Levadki", "value": "C121431"},
                                   {"label": "Listvennoye", "value": "C121432"},
                                   {"label": "Litvinenkovo",
                                    "value": "C121433"},
                                   {"label": "Livadia", "value": "C121434"},
                                   {"label": "Lobanovo", "value": "C121435"},
                                   {"label": "Lozovoye", "value": "C121436"},
                                   {"label": "Luchistoye", "value": "C121437"},
                                   {"label": "Luganskoye", "value": "C121438"},
                                   {"label": "Lugovoye", "value": "C121439"},
                                   {"label": "Magazinka", "value": "C121440"},
                                   {"label": "Malenkoye", "value": "C121441"},
                                   {"label": "Malorechenskoye",
                                    "value": "C121442"},
                                   {"label": "Maly Mayak", "value": "C121443"},
                                   {"label": "Maryanovka", "value": "C121444"},
                                   {"label": "Marfovka", "value": "C121445"},
                                   {"label": "Maryevka", "value": "C121446"},
                                   {"label": "Maslovo", "value": "C121447"},
                                   {"label": "Massandra", "value": "C121448"},
                                   {"label": "Mayskoye", "value": "C121449"},
                                   {"label": "Mazanka", "value": "C121450"},
                                   {"label": "Medvedevka", "value": "C121451"},
                                   {"label": "Medvedevo", "value": "C121452"},
                                   {"label": "Melnichnoye", "value": "C121453"},
                                   {"label": "Mezhvodnoye", "value": "C121454"},
                                   {"label": "Michurinskoye",
                                    "value": "C121455"},
                                   {"label": "Mikhaylovka", "value": "C121456"},
                                   {"label": "Mirnovka", "value": "C121457"},
                                   {"label": "Mirnoye", "value": "C121458"},
                                   {"label": "Mirny", "value": "C121459"},
                                   {"label": "Mitrofanovka",
                                    "value": "C121460"},
                                   {"label": "Mityayevo", "value": "C121461"},
                                   {"label": "Molochnoye", "value": "C121462"},
                                   {"label": "Molodyozhnoye",
                                    "value": "C121463"},
                                   {"label": "Morskoye", "value": "C121464"},
                                   {"label": "Muromskoye", "value": "C121465"},
                                   {"label": "Muskatnoye", "value": "C121466"},
                                   {"label": "Mysovoe", "value": "C121467"},
                                   {"label": "Nasypnoe", "value": "C121468"},
                                   {"label": "Naydenovka", "value": "C121469"},
                                   {"label": "Nekrasovka", "value": "C121470"},
                                   {"label": "Nekrasovo", "value": "C121471"},
                                   {"label": "Nikita", "value": "C121472"},
                                   {"label": "Nikolayevka", "value": "C121473"},
                                   {"label": "Nizhnegorskiy",
                                    "value": "C121474"},
                                   {"label": "Nizhnegorskiy rayon",
                                    "value": "C121475"},
                                   {"label": "Novoandreyevka",
                                    "value": "C121476"},
                                   {"label": "Novoestoniya",
                                    "value": "C121477"},
                                   {"label": "Novofedorovka",
                                    "value": "C121478"},
                                   {"label": "Novogrigoryevka",
                                    "value": "C121479"},
                                   {"label": "Novoivanovka",
                                    "value": "C121480"},
                                   {"label": "Novokrymskoye",
                                    "value": "C121481"},
                                   {"label": "Novonikolayevka",
                                    "value": "C121482"},
                                   {"label": "Novoozyornoye",
                                    "value": "C121483"},
                                   {"label": "Novopavlovka",
                                    "value": "C121484"},
                                   {"label": "Novopokrovka",
                                    "value": "C121485"},
                                   {"label": "Novoselskoye",
                                    "value": "C121486"},
                                   {"label": "Novoselovskoye",
                                    "value": "C121487"},
                                   {"label": "Novostepnoye",
                                    "value": "C121488"},
                                   {"label": "Novosyolovka",
                                    "value": "C121489"},
                                   {"label": "Novozhilovka",
                                    "value": "C121490"},
                                   {"label": "Novy Svet", "value": "C121491"},
                                   {"label": "Okhotnikovo", "value": "C121492"},
                                   {"label": "Okhotskoye", "value": "C121493"},
                                   {"label": "Oktyabrskoye",
                                    "value": "C121494"},
                                   {"label": "Oktyabrskoye",
                                    "value": "C121495"},
                                   {"label": "Okunevka", "value": "C121496"},
                                   {"label": "Olenevka", "value": "C121497"},
                                   {"label": "Ordzhonikidze",
                                    "value": "C121498"},
                                   {"label": "Orekhovo", "value": "C121499"},
                                   {"label": "Orlovskoye", "value": "C121500"},
                                   {"label": "Ostanino", "value": "C121501"},
                                   {"label": "Ostrovskoye", "value": "C121502"},
                                   {"label": "Pakharevka", "value": "C121503"},
                                   {"label": "Partenit", "value": "C121504"},
                                   {"label": "Partizanskoye",
                                    "value": "C121505"},
                                   {"label": "Partizany", "value": "C121506"},
                                   {"label": "Perevalnoye", "value": "C121507"},
                                   {"label": "Perovo", "value": "C121508"},
                                   {"label": "Pervomayskiy rayon",
                                    "value": "C121509"},
                                   {"label": "Pervomayskoye",
                                    "value": "C121510"},
                                   {"label": "Peschanoye", "value": "C121511"},
                                   {"label": "Petrovka", "value": "C121512"},
                                   {"label": "Pionerskoye", "value": "C121513"},
                                   {"label": "Plodovoye", "value": "C121514"},
                                   {"label": "Pobednoye", "value": "C121515"},
                                   {"label": "Pochetnoye", "value": "C121516"},
                                   {"label": "Poltavka", "value": "C121517"},
                                   {"label": "Poshtove", "value": "C121518"},
                                   {"label": "Pozharskoye", "value": "C121519"},
                                   {"label": "Pravda", "value": "C121520"},
                                   {"label": "Primorskiy", "value": "C121521"},
                                   {"label": "Priozyornoye",
                                    "value": "C121522"},
                                   {"label": "Privetnoye", "value": "C121523"},
                                   {"label": "Prostornoye", "value": "C121524"},
                                   {"label": "Prudovoye", "value": "C121525"},
                                   {"label": "Prudy", "value": "C121526"},
                                   {"label": "Pshenichnoye",
                                    "value": "C121527"},
                                   {"label": "Pushkino", "value": "C121528"},
                                   {"label": "Pyatikhatka", "value": "C121529"},
                                   {"label": "Razdolnenskiy rayon",
                                    "value": "C121530"},
                                   {"label": "Razdolnoye", "value": "C121531"},
                                   {"label": "Rodnikovo", "value": "C121532"},
                                   {"label": "Romashkino", "value": "C121533"},
                                   {"label": "Roshchino", "value": "C121534"},
                                   {"label": "Roskoshnoye", "value": "C121535"},
                                   {"label": "Rovnoye", "value": "C121536"},
                                   {"label": "Ruchi", "value": "C121537"},
                                   {"label": "Rusakovka", "value": "C121538"},
                                   {"label": "Rybache", "value": "C121539"},
                                   {"label": "Sadovoye", "value": "C121540"},
                                   {"label": "Saki", "value": "C121541"},
                                   {"label": "Sakskiy rayon",
                                    "value": "C121542"},
                                   {"label": "Sary-Bash", "value": "C121543"},
                                   {"label": "Semisotka", "value": "C121544"},
                                   {"label": "Senokosnoye", "value": "C121545"},
                                   {"label": "Serebryanka", "value": "C121546"},
                                   {"label": "Shchebetovka",
                                    "value": "C121547"},
                                   {"label": "Shchyolkino", "value": "C121548"},
                                   {"label": "Shelkovichnoye",
                                    "value": "C121549"},
                                   {"label": "Shirokoye", "value": "C121550"},
                                   {"label": "Shkolnoye", "value": "C121551"},
                                   {"label": "Shtormovoye", "value": "C121552"},
                                   {"label": "Simeiz", "value": "C121553"},
                                   {"label": "Simferopol", "value": "C121554"},
                                   {"label": "Simferopol Raion",
                                    "value": "C121555"},
                                   {"label": "Sinitsyno", "value": "C121556"},
                                   {"label": "Sizovka", "value": "C121557"},
                                   {"label": "Skalistoye", "value": "C121558"},
                                   {"label": "Skvortsovo", "value": "C121559"},
                                   {"label": "Slavnoye", "value": "C121560"},
                                   {"label": "Slavyanskoye",
                                    "value": "C121561"},
                                   {"label": "Sofiivka", "value": "C121562"},
                                   {"label": "Sokolinoye", "value": "C121563"},
                                   {"label": "Solnechnaya Dolina",
                                    "value": "C121564"},
                                   {"label": "Solnechnogorskoye",
                                    "value": "C121565"},
                                   {"label": "Solnechnoye", "value": "C121566"},
                                   {"label": "Sovetskiy", "value": "C121567"},
                                   {"label": "Sovietskyi Raion",
                                    "value": "C121568"},
                                   {"label": "Sovkhoznoye", "value": "C121569"},
                                   {"label": "Stakhanovka", "value": "C121570"},
                                   {"label": "Stalnoye", "value": "C121571"},
                                   {"label": "Stary Krym", "value": "C121572"},
                                   {"label": "Stepnoye", "value": "C121573"},
                                   {"label": "Stolbovoye", "value": "C121574"},
                                   {"label": "Strogonovka", "value": "C121575"},
                                   {"label": "Sudak", "value": "C121576"},
                                   {"label": "Susanino", "value": "C121577"},
                                   {"label": "Suvorovo", "value": "C121578"},
                                   {"label": "Suvorovskoye",
                                    "value": "C121579"},
                                   {"label": "Svetloye", "value": "C121580"},
                                   {"label": "Tabachnoye", "value": "C121581"},
                                   {"label": "Tankovoye", "value": "C121582"},
                                   {"label": "Tenistoye", "value": "C121583"},
                                   {"label": "Teplovka", "value": "C121584"},
                                   {"label": "Tokarevo", "value": "C121585"},
                                   {"label": "Trudovoye", "value": "C121586"},
                                   {"label": "Tselinnoye", "value": "C121587"},
                                   {"label": "Tsvetochnoye",
                                    "value": "C121588"},
                                   {"label": "Turgenevka", "value": "C121589"},
                                   {"label": "Uglovoye", "value": "C121590"},
                                   {"label": "Ukrainka", "value": "C121591"},
                                   {"label": "Ukromnoye", "value": "C121592"},
                                   {"label": "Urozhaynoye", "value": "C121593"},
                                   {"label": "Uvarovka", "value": "C121594"},
                                   {"label": "Uvarovo", "value": "C121595"},
                                   {"label": "Uyutnoye", "value": "C121596"},
                                   {"label": "Vasilyevka", "value": "C121597"},
                                   {"label": "Veresayevo", "value": "C121598"},
                                   {"label": "Verkhorechye",
                                    "value": "C121599"},
                                   {"label": "Vesele", "value": "C121600"},
                                   {"label": "Veselovka", "value": "C121601"},
                                   {"label": "Vilino", "value": "C121602"},
                                   {"label": "Vinnitskoye", "value": "C121603"},
                                   {"label": "Vinogradnoye",
                                    "value": "C121604"},
                                   {"label": "Vinogradovo", "value": "C121605"},
                                   {"label": "Vishennoye", "value": "C121606"},
                                   {"label": "Vishnyovka", "value": "C121607"},
                                   {"label": "Vladimirovka",
                                    "value": "C121608"},
                                   {"label": "Vladislavovka",
                                    "value": "C121609"},
                                   {"label": "Voinka", "value": "C121610"},
                                   {"label": "Volnoye", "value": "C121611"},
                                   {"label": "Vorobyovo", "value": "C121612"},
                                   {"label": "Voskhod", "value": "C121613"},
                                   {"label": "Voykovo", "value": "C121614"},
                                   {"label": "Yalta", "value": "C121615"},
                                   {"label": "Yantarnoye", "value": "C121616"},
                                   {"label": "Yarkoye", "value": "C121617"},
                                   {"label": "Yarkoye Pole",
                                    "value": "C121618"},
                                   {"label": "Yasnopolyanskoye",
                                    "value": "C121619"},
                                   {"label": "Yemelyanovka",
                                    "value": "C121620"},
                                   {"label": "Yermakovo", "value": "C121621"},
                                   {"label": "Yevpatoriya", "value": "C121622"},
                                   {"label": "Zalesye", "value": "C121623"},
                                   {"label": "Zaozyornoye", "value": "C121624"},
                                   {"label": "Zarechnoye", "value": "C121625"},
                                   {"label": "Zavet-Leninskiy",
                                    "value": "C121626"},
                                   {"label": "Zavetnoye", "value": "C121627"},
                                   {"label": "Zelenogorskoye",
                                    "value": "C121628"},
                                   {"label": "Zelyonoye", "value": "C121629"},
                                   {"label": "Zemlyanichnoye",
                                    "value": "C121630"},
                                   {"label": "Zernovoye", "value": "C121631"},
                                   {"label": "Zheleznodorozhnoye",
                                    "value": "C121632"},
                                   {"label": "Zhelyabovka", "value": "C121633"},
                                   {"label": "Zhemchuzhina",
                                    "value": "C121634"},
                                   {"label": "Zhuravki", "value": "C121635"},
                                   {"label": "Zhuravli", "value": "C121636"},
                                   {"label": "Zhuravlyovka",
                                    "value": "C121637"},
                                   {"label": "Zimino", "value": "C121638"},
                                   {"label": "Zolotoye Pole",
                                    "value": "C121639"},
                                   {"label": "Zorkino", "value": "C121640"},
                                   {"label": "Zuya", "value": "C121641"},
                                   {"label": "Zybiny", "value": "C121642"}],
 "Auvergne-Rhone-Alpes": [{"label": "Abondance", "value": "C25227"},
                          {"label": "Abrest", "value": "C25228"},
                          {"label": "Aigueblanche", "value": "C25229"},
                          {"label": "Aigueperse", "value": "C25230"},
                          {"label": "Aime", "value": "C25231"},
                          {"label": "Ainay-le-Chateau", "value": "C25232"},
                          {"label": "Aiton", "value": "C25233"},
                          {"label": "Aix-les-Bains", "value": "C25234"},
                          {"label": "Alba-la-Romaine", "value": "C25235"},
                          {"label": "Albens", "value": "C25236"},
                          {"label": "Albertville", "value": "C25237"},
                          {"label": "Albigny-sur-Saone", "value": "C25238"},
                          {"label": "Alby-sur-Cheran", "value": "C25239"},
                          {"label": "Alissas", "value": "C25240"},
                          {"label": "Alixan", "value": "C25241"},
                          {"label": "Allan", "value": "C25242"},
                          {"label": "Allanche", "value": "C25243"},
                          {"label": "Allegre", "value": "C25244"},
                          {"label": "Allevard", "value": "C25245"},
                          {"label": "Allex", "value": "C25246"},
                          {"label": "Allieres-et-Risset", "value": "C25247"},
                          {"label": "Allinges", "value": "C25248"},
                          {"label": "Allonzier-la-Caille", "value": "C25249"},
                          {"label": "Amancy", "value": "C25250"},
                          {"label": "Amberieu-en-Bugey", "value": "C25251"},
                          {"label": "Amberieux-en-Dombes", "value": "C25252"},
                          {"label": "Ambert", "value": "C25253"},
                          {"label": "Ambierle", "value": "C25254"},
                          {"label": "Ambronay", "value": "C25255"},
                          {"label": "Amplepuis", "value": "C25256"},
                          {"label": "Ampuis", "value": "C25257"},
                          {"label": "Ancone", "value": "C25258"},
                          {"label": "Andance", "value": "C25259"},
                          {"label": "Andancette", "value": "C25260"},
                          {"label": "Andrezieux-Boutheon", "value": "C25261"},
                          {"label": "Annecy", "value": "C25262"},
                          {"label": "Annecy-le-Vieux", "value": "C25263"},
                          {"label": "Annemasse", "value": "C25264"},
                          {"label": "Anneyron", "value": "C25265"},
                          {"label": "Annonay", "value": "C25266"},
                          {"label": "Anse", "value": "C25267"},
                          {"label": "Anthy-sur-Leman", "value": "C25268"},
                          {"label": "Aoste", "value": "C25269"},
                          {"label": "Aouste-sur-Sye", "value": "C25270"},
                          {"label": "Apprieu", "value": "C25271"},
                          {"label": "Araches-la-Frasse", "value": "C25272"},
                          {"label": "Arbent", "value": "C25273"},
                          {"label": "Archamps", "value": "C25274"},
                          {"label": "Arenthon", "value": "C25275"},
                          {"label": "Arlanc", "value": "C25276"},
                          {"label": "Armoy", "value": "C25277"},
                          {"label": "Arnas", "value": "C25278"},
                          {"label": "Arpajon-sur-Cere", "value": "C25279"},
                          {"label": "Ars-sur-Formans", "value": "C25280"},
                          {"label": "Artas", "value": "C25281"},
                          {"label": "Artemare", "value": "C25282"},
                          {"label": "Arthaz-Pont-Notre-Dame",
                           "value": "C25283"},
                          {"label": "Attignat", "value": "C25284"},
                          {"label": "Aubenas", "value": "C25285"},
                          {"label": "Auberives-sur-Vareze", "value": "C25286"},
                          {"label": "Aubiere", "value": "C25287"},
                          {"label": "Aulnat", "value": "C25288"},
                          {"label": "Aurec-sur-Loire", "value": "C25289"},
                          {"label": "Aurillac", "value": "C25290"},
                          {"label": "Autrans", "value": "C25291"},
                          {"label": "Auzat-la-Combelle", "value": "C25292"},
                          {"label": "Aveize", "value": "C25293"},
                          {"label": "Aveizieux", "value": "C25294"},
                          {"label": "Avermes", "value": "C25295"},
                          {"label": "Aydat", "value": "C25296"},
                          {"label": "Ayse", "value": "C25297"},
                          {"label": "Bage-la-Ville", "value": "C25298"},
                          {"label": "Bains", "value": "C25299"},
                          {"label": "Balan", "value": "C25300"},
                          {"label": "Balbigny", "value": "C25301"},
                          {"label": "Ballaison", "value": "C25302"},
                          {"label": "Barberaz", "value": "C25303"},
                          {"label": "Barby", "value": "C25304"},
                          {"label": "Barraux", "value": "C25305"},
                          {"label": "Bas-en-Basset", "value": "C25306"},
                          {"label": "Bassens", "value": "C25307"},
                          {"label": "Beauchastel", "value": "C25308"},
                          {"label": "Beaucroissant", "value": "C25309"},
                          {"label": "Beaufort", "value": "C25310"},
                          {"label": "Beaujeu", "value": "C25311"},
                          {"label": "Beaulon", "value": "C25312"},
                          {"label": "Beaumont", "value": "C25313"},
                          {"label": "Beaumont-les-Valence", "value": "C25314"},
                          {"label": "Beaumont-Monteux", "value": "C25315"},
                          {"label": "Beaurepaire", "value": "C25316"},
                          {"label": "Beausemblant", "value": "C25317"},
                          {"label": "Beauvallon", "value": "C25318"},
                          {"label": "Beauvoir-de-Marc", "value": "C25319"},
                          {"label": "Beauzac", "value": "C25320"},
                          {"label": "Beligneux", "value": "C25321"},
                          {"label": "Belle-Plagne", "value": "C25322"},
                          {"label": "Bellegarde-en-Forez", "value": "C25323"},
                          {"label": "Bellegarde-sur-Valserine",
                           "value": "C25324"},
                          {"label": "Bellenaves", "value": "C25325"},
                          {"label": "Bellerive-sur-Allier", "value": "C25326"},
                          {"label": "Bellevaux", "value": "C25327"},
                          {"label": "Belleville", "value": "C25328"},
                          {"label": "Belley", "value": "C25329"},
                          {"label": "Bellignat", "value": "C25330"},
                          {"label": "Belmont-de-la-Loire", "value": "C25331"},
                          {"label": "Bernin", "value": "C25332"},
                          {"label": "Bessay-sur-Allier", "value": "C25333"},
                          {"label": "Besse-et-Saint-Anastaise",
                           "value": "C25334"},
                          {"label": "Bessenay", "value": "C25335"},
                          {"label": "Beynost", "value": "C25336"},
                          {"label": "Bilieu", "value": "C25337"},
                          {"label": "Billom", "value": "C25338"},
                          {"label": "Biol", "value": "C25339"},
                          {"label": "Biviers", "value": "C25340"},
                          {"label": "Blace", "value": "C25341"},
                          {"label": "Blanzat", "value": "C25342"},
                          {"label": "Blavozy", "value": "C25343"},
                          {"label": "Boege", "value": "C25344"},
                          {"label": "Boen-sur-Lignon", "value": "C25345"},
                          {"label": "Bonne", "value": "C25346"},
                          {"label": "Bonnefamille", "value": "C25347"},
                          {"label": "Bonneville", "value": "C25348"},
                          {"label": "Bons-en-Chablais", "value": "C25349"},
                          {"label": "Bonson", "value": "C25350"},
                          {"label": "Boulieu-les-Annonay", "value": "C25351"},
                          {"label": "Bourbon-lArchambault", "value": "C25352"},
                          {"label": "Bourg-Argental", "value": "C25353"},
                          {"label": "Bourg-de-Peage", "value": "C25354"},
                          {"label": "Bourg-de-Thizy", "value": "C25355"},
                          {"label": "Bourg-en-Bresse", "value": "C25356"},
                          {"label": "Bourg-les-Valence", "value": "C25357"},
                          {"label": "Bourg-Saint-Andeol", "value": "C25358"},
                          {"label": "Bourg-Saint-Maurice", "value": "C25359"},
                          {"label": "Bourgoin-Jallieu", "value": "C25360"},
                          {"label": "Bournoncle-Saint-Pierre",
                           "value": "C25361"},
                          {"label": "Bouvesse-Quirieu", "value": "C25362"},
                          {"label": "Bozel", "value": "C25363"},
                          {"label": "Brassac-les-Mines", "value": "C25364"},
                          {"label": "Brezins", "value": "C25365"},
                          {"label": "Brie-et-Angonnes", "value": "C25366"},
                          {"label": "Briennon", "value": "C25367"},
                          {"label": "Brignais", "value": "C25368"},
                          {"label": "Brindas", "value": "C25369"},
                          {"label": "Brioude", "value": "C25370"},
                          {"label": "Brison-Saint-Innocent", "value": "C25371"},
                          {"label": "Brives-Charensac", "value": "C25372"},
                          {"label": "Bron", "value": "C25373"},
                          {"label": "Brout-Vernet", "value": "C25374"},
                          {"label": "Brugheas", "value": "C25375"},
                          {"label": "Buellas", "value": "C25376"},
                          {"label": "Buis-les-Baronnies", "value": "C25377"},
                          {"label": "Bully", "value": "C25378"},
                          {"label": "Bussieres", "value": "C25379"},
                          {"label": "Buxieres-les-Mines", "value": "C25380"},
                          {"label": "Cailloux-sur-Fontaines",
                           "value": "C25381"},
                          {"label": "Caluire-et-Cuire", "value": "C25382"},
                          {"label": "Cantal", "value": "C25383"},
                          {"label": "Cebazat", "value": "C25384"},
                          {"label": "Celles-sur-Durolle", "value": "C25385"},
                          {"label": "Cellieu", "value": "C25386"},
                          {"label": "Cerilly", "value": "C25387"},
                          {"label": "Certines", "value": "C25388"},
                          {"label": "Cessieu", "value": "C25389"},
                          {"label": "Cessy", "value": "C25390"},
                          {"label": "Ceyrat", "value": "C25391"},
                          {"label": "Ceyzeriat", "value": "C25392"},
                          {"label": "Chabeuil", "value": "C25393"},
                          {"label": "Chabons", "value": "C25394"},
                          {"label": "Chabreloche", "value": "C25395"},
                          {"label": "Chadrac", "value": "C25396"},
                          {"label": "Chalamont", "value": "C25397"},
                          {"label": "Chaleins", "value": "C25398"},
                          {"label": "Challes-les-Eaux", "value": "C25399"},
                          {"label": "Challex", "value": "C25400"},
                          {"label": "Chamagnieu", "value": "C25401"},
                          {"label": "Chamalieres", "value": "C25402"},
                          {"label": "Chambery", "value": "C25403"},
                          {"label": "Chamboeuf", "value": "C25404"},
                          {"label": "Chamonix-Mont-Blanc", "value": "C25405"},
                          {"label": "Champ-sur-Drac", "value": "C25406"},
                          {"label": "Champagnac", "value": "C25407"},
                          {"label": "Champagne-au-Mont-dOr", "value": "C25408"},
                          {"label": "Champagnier", "value": "C25409"},
                          {"label": "Champdieu", "value": "C25410"},
                          {"label": "Champeix", "value": "C25411"},
                          {"label": "Champier", "value": "C25412"},
                          {"label": "Champs-sur-Tarentaine-Marchal",
                           "value": "C25413"},
                          {"label": "Chanas", "value": "C25414"},
                          {"label": "Chandon", "value": "C25415"},
                          {"label": "Chanonat", "value": "C25416"},
                          {"label": "Chanos-Curson", "value": "C25417"},
                          {"label": "Chantelle", "value": "C25418"},
                          {"label": "Chapareillan", "value": "C25419"},
                          {"label": "Chapdes-Beaufort", "value": "C25420"},
                          {"label": "Chaponnay", "value": "C25421"},
                          {"label": "Chaponost", "value": "C25422"},
                          {"label": "Chappes", "value": "C25423"},
                          {"label": "Charantonnay", "value": "C25424"},
                          {"label": "Charavines", "value": "C25425"},
                          {"label": "Charbonnieres-les-Bains",
                           "value": "C25426"},
                          {"label": "Charbonnieres-les-Varennes",
                           "value": "C25427"},
                          {"label": "Charentay", "value": "C25428"},
                          {"label": "Charlieu", "value": "C25429"},
                          {"label": "Charly", "value": "C25430"},
                          {"label": "Charmes-sur-Rhone", "value": "C25431"},
                          {"label": "Charnay", "value": "C25432"},
                          {"label": "Charnecles", "value": "C25433"},
                          {"label": "Charvieu-Chavagneux", "value": "C25434"},
                          {"label": "Chassagny", "value": "C25435"},
                          {"label": "Chasse-sur-Rhone", "value": "C25436"},
                          {"label": "Chasselay", "value": "C25437"},
                          {"label": "Chassieu", "value": "C25438"},
                          {"label": "Chateau-Gaillard", "value": "C25439"},
                          {"label": "Chateaugay", "value": "C25440"},
                          {"label": "Chateauneuf", "value": "C25441"},
                          {"label": "Chateauneuf-de-Galaure",
                           "value": "C25442"},
                          {"label": "Chateauneuf-du-Rhone", "value": "C25443"},
                          {"label": "Chateauneuf-sur-Isere", "value": "C25444"},
                          {"label": "Chatel", "value": "C25445"},
                          {"label": "Chatel-Guyon", "value": "C25446"},
                          {"label": "Chatillon", "value": "C25447"},
                          {"label": "Chatillon-en-Michaille",
                           "value": "C25448"},
                          {"label": "Chatillon-la-Palud", "value": "C25449"},
                          {"label": "Chatillon-sur-Chalaronne",
                           "value": "C25450"},
                          {"label": "Chatillon-sur-Cluses", "value": "C25451"},
                          {"label": "Chatonnay", "value": "C25452"},
                          {"label": "Chatte", "value": "C25453"},
                          {"label": "Chatuzange-le-Goubet", "value": "C25454"},
                          {"label": "Chaudes-Aigues", "value": "C25455"},
                          {"label": "Chauriat", "value": "C25456"},
                          {"label": "Chavanay", "value": "C25457"},
                          {"label": "Chavanod", "value": "C25458"},
                          {"label": "Chavanoz", "value": "C25459"},
                          {"label": "Chazelles-sur-Lyon", "value": "C25460"},
                          {"label": "Chazey-sur-Ain", "value": "C25461"},
                          {"label": "Chens-sur-Leman", "value": "C25462"},
                          {"label": "Chessy", "value": "C25463"},
                          {"label": "Chevry", "value": "C25464"},
                          {"label": "Chilly", "value": "C25465"},
                          {"label": "Chimilin", "value": "C25466"},
                          {"label": "Chindrieux", "value": "C25467"},
                          {"label": "Chirens", "value": "C25468"},
                          {"label": "Choisy", "value": "C25469"},
                          {"label": "Chomerac", "value": "C25470"},
                          {"label": "Chuzelles", "value": "C25471"},
                          {"label": "Civens", "value": "C25472"},
                          {"label": "Civrieux", "value": "C25473"},
                          {"label": "Claix", "value": "C25474"},
                          {"label": "Clerieux", "value": "C25475"},
                          {"label": "Clermont-Ferrand", "value": "C25476"},
                          {"label": "Clonas-sur-Vareze", "value": "C25477"},
                          {"label": "Cluses", "value": "C25478"},
                          {"label": "Cognin", "value": "C25479"},
                          {"label": "Cogny", "value": "C25480"},
                          {"label": "Coise", "value": "C25481"},
                          {"label": "Coligny", "value": "C25482"},
                          {"label": "Collonges", "value": "C25483"},
                          {"label": "Collonges-sous-Saleve", "value": "C25484"},
                          {"label": "Colombe", "value": "C25485"},
                          {"label": "Combloux", "value": "C25486"},
                          {"label": "Combronde", "value": "C25487"},
                          {"label": "Commelle", "value": "C25488"},
                          {"label": "Commentry", "value": "C25489"},
                          {"label": "Communay", "value": "C25490"},
                          {"label": "Condat", "value": "C25491"},
                          {"label": "Condrieu", "value": "C25492"},
                          {"label": "Contamine-sur-Arve", "value": "C25493"},
                          {"label": "Corbas", "value": "C25494"},
                          {"label": "Corbelin", "value": "C25495"},
                          {"label": "Cordon", "value": "C25496"},
                          {"label": "Corenc", "value": "C25497"},
                          {"label": "Cornas", "value": "C25498"},
                          {"label": "Cornier", "value": "C25499"},
                          {"label": "Coublevie", "value": "C25500"},
                          {"label": "Coubon", "value": "C25501"},
                          {"label": "Courchevel", "value": "C25502"},
                          {"label": "Cournon-dAuvergne", "value": "C25503"},
                          {"label": "Courpiere", "value": "C25504"},
                          {"label": "Cours-la-Ville", "value": "C25505"},
                          {"label": "Courzieu", "value": "C25506"},
                          {"label": "Coutouvre", "value": "C25507"},
                          {"label": "Coux", "value": "C25508"},
                          {"label": "Couzon-au-Mont-dOr", "value": "C25509"},
                          {"label": "Cran-Gevrier", "value": "C25510"},
                          {"label": "Cranves-Sales", "value": "C25511"},
                          {"label": "Craponne", "value": "C25512"},
                          {"label": "Craponne-sur-Arzon", "value": "C25513"},
                          {"label": "Cremieu", "value": "C25514"},
                          {"label": "Crest", "value": "C25515"},
                          {"label": "Creuzier-le-Vieux", "value": "C25516"},
                          {"label": "Creys-Mepieu", "value": "C25517"},
                          {"label": "Crolles", "value": "C25518"},
                          {"label": "Crottet", "value": "C25519"},
                          {"label": "Cruas", "value": "C25520"},
                          {"label": "Cruet", "value": "C25521"},
                          {"label": "Cruseilles", "value": "C25522"},
                          {"label": "Cublize", "value": "C25523"},
                          {"label": "Cuisiat", "value": "C25524"},
                          {"label": "Culhat", "value": "C25525"},
                          {"label": "Culoz", "value": "C25526"},
                          {"label": "Cunlhat", "value": "C25527"},
                          {"label": "Curtin", "value": "C25528"},
                          {"label": "Cussac-sur-Loire", "value": "C25529"},
                          {"label": "Cusset", "value": "C25530"},
                          {"label": "Cusy", "value": "C25531"},
                          {"label": "Cuzieu", "value": "C25532"},
                          {"label": "Dagneux", "value": "C25533"},
                          {"label": "Dallet", "value": "C25534"},
                          {"label": "Dardilly", "value": "C25535"},
                          {"label": "Davezieux", "value": "C25536"},
                          {"label": "Decines-Charpieu", "value": "C25537"},
                          {"label": "Denice", "value": "C25538"},
                          {"label": "Departement de lAin", "value": "C25539"},
                          {"label": "Departement de lAllier",
                           "value": "C25540"},
                          {"label": "Departement de lArdeche",
                           "value": "C25541"},
                          {"label": "Departement de lIsere", "value": "C25542"},
                          {"label": "Departement de la Drome",
                           "value": "C25543"},
                          {"label": "Departement de la Loire",
                           "value": "C25544"},
                          {"label": "Departement du Puy-de-Dome",
                           "value": "C25545"},
                          {"label": "Departement du Rhone", "value": "C25546"},
                          {"label": "Desaignes", "value": "C25547"},
                          {"label": "Desertines", "value": "C25548"},
                          {"label": "Die", "value": "C25549"},
                          {"label": "Diemoz", "value": "C25550"},
                          {"label": "Dieulefit", "value": "C25551"},
                          {"label": "Dingy-Saint-Clair", "value": "C25552"},
                          {"label": "Diou", "value": "C25553"},
                          {"label": "Divonne-les-Bains", "value": "C25554"},
                          {"label": "Dolomieu", "value": "C25555"},
                          {"label": "Domancy", "value": "C25556"},
                          {"label": "Domarin", "value": "C25557"},
                          {"label": "Domene", "value": "C25558"},
                          {"label": "Domerat", "value": "C25559"},
                          {"label": "Domessin", "value": "C25560"},
                          {"label": "Dompierre-sur-Besbre", "value": "C25561"},
                          {"label": "Dompierre-sur-Veyle", "value": "C25562"},
                          {"label": "Donzere", "value": "C25563"},
                          {"label": "Dortan", "value": "C25564"},
                          {"label": "Doussard", "value": "C25565"},
                          {"label": "Douvaine", "value": "C25566"},
                          {"label": "Doyet", "value": "C25567"},
                          {"label": "Drumettaz", "value": "C25568"},
                          {"label": "Dunieres", "value": "C25569"},
                          {"label": "Durtol", "value": "C25570"},
                          {"label": "ebreuil", "value": "C25571"},
                          {"label": "echalas", "value": "C25572"},
                          {"label": "echenevex", "value": "C25573"},
                          {"label": "echirolles", "value": "C25574"},
                          {"label": "ecully", "value": "C25575"},
                          {"label": "Ennezat", "value": "C25576"},
                          {"label": "Entre-Deux-Guiers", "value": "C25577"},
                          {"label": "Enval", "value": "C25578"},
                          {"label": "epagny", "value": "C25579"},
                          {"label": "epinouze", "value": "C25580"},
                          {"label": "Escoutoux", "value": "C25581"},
                          {"label": "Espaly-Saint-Marcel", "value": "C25582"},
                          {"label": "Estivareilles", "value": "C25583"},
                          {"label": "Estrablin", "value": "C25584"},
                          {"label": "Etaux", "value": "C25585"},
                          {"label": "etoile-sur-Rhone", "value": "C25586"},
                          {"label": "etrembieres", "value": "C25587"},
                          {"label": "Eurre", "value": "C25588"},
                          {"label": "evian-les-Bains", "value": "C25589"},
                          {"label": "evires", "value": "C25590"},
                          {"label": "Eybens", "value": "C25591"},
                          {"label": "Eyzin-Pinet", "value": "C25592"},
                          {"label": "Fareins", "value": "C25593"},
                          {"label": "Farnay", "value": "C25594"},
                          {"label": "Faverges", "value": "C25595"},
                          {"label": "Faverges-de-la-Tour", "value": "C25596"},
                          {"label": "Feigeres", "value": "C25597"},
                          {"label": "Feillens", "value": "C25598"},
                          {"label": "Felines", "value": "C25599"},
                          {"label": "Ferney-Voltaire", "value": "C25600"},
                          {"label": "Feternes", "value": "C25601"},
                          {"label": "Feurs", "value": "C25602"},
                          {"label": "Feyzin", "value": "C25603"},
                          {"label": "Fillinges", "value": "C25604"},
                          {"label": "Firminy", "value": "C25605"},
                          {"label": "Fitilieu", "value": "C25606"},
                          {"label": "Flaviac", "value": "C25607"},
                          {"label": "Fleurie", "value": "C25608"},
                          {"label": "Fleurieu-sur-Saone", "value": "C25609"},
                          {"label": "Foissiat", "value": "C25610"},
                          {"label": "Fontaine", "value": "C25611"},
                          {"label": "Fontaines-Saint-Martin",
                           "value": "C25612"},
                          {"label": "Fontaines-sur-Saone", "value": "C25613"},
                          {"label": "Fontanil-Cornillon", "value": "C25614"},
                          {"label": "Fontannes", "value": "C25615"},
                          {"label": "Fraisses", "value": "C25616"},
                          {"label": "Francheleins", "value": "C25617"},
                          {"label": "Francheville", "value": "C25618"},
                          {"label": "Frangy", "value": "C25619"},
                          {"label": "Frans", "value": "C25620"},
                          {"label": "Froges", "value": "C25621"},
                          {"label": "Frontenex", "value": "C25622"},
                          {"label": "Frontonas", "value": "C25623"},
                          {"label": "Gaillard", "value": "C25624"},
                          {"label": "Gannat", "value": "C25625"},
                          {"label": "Gelles", "value": "C25626"},
                          {"label": "Genas", "value": "C25627"},
                          {"label": "Genay", "value": "C25628"},
                          {"label": "Genissieux", "value": "C25629"},
                          {"label": "Gerzat", "value": "C25630"},
                          {"label": "Gex", "value": "C25631"},
                          {"label": "Gieres", "value": "C25632"},
                          {"label": "Gillonnay", "value": "C25633"},
                          {"label": "Gilly-sur-Isere", "value": "C25634"},
                          {"label": "Givors", "value": "C25635"},
                          {"label": "Gleize", "value": "C25636"},
                          {"label": "Goncelin", "value": "C25637"},
                          {"label": "Grandris", "value": "C25638"},
                          {"label": "Grane", "value": "C25639"},
                          {"label": "Grenay", "value": "C25640"},
                          {"label": "Grenoble", "value": "C25641"},
                          {"label": "Gresy-sur-Aix", "value": "C25642"},
                          {"label": "Gresy-sur-Isere", "value": "C25643"},
                          {"label": "Grezieu-la-Varenne", "value": "C25644"},
                          {"label": "Grieges", "value": "C25645"},
                          {"label": "Grignan", "value": "C25646"},
                          {"label": "Grignon", "value": "C25647"},
                          {"label": "Grigny", "value": "C25648"},
                          {"label": "Groissiat", "value": "C25649"},
                          {"label": "Groisy", "value": "C25650"},
                          {"label": "Gruffy", "value": "C25651"},
                          {"label": "Guereins", "value": "C25652"},
                          {"label": "Guilherand-Granges", "value": "C25653"},
                          {"label": "Haute-Loire", "value": "C25654"},
                          {"label": "Haute-Rivoire", "value": "C25655"},
                          {"label": "Haute-Savoie", "value": "C25656"},
                          {"label": "Hauterive", "value": "C25657"},
                          {"label": "Hauterives", "value": "C25658"},
                          {"label": "Hauteville-Lompnes", "value": "C25659"},
                          {"label": "Herbeys", "value": "C25660"},
                          {"label": "Heyrieux", "value": "C25661"},
                          {"label": "Hieres-sur-Amby", "value": "C25662"},
                          {"label": "Huez", "value": "C25663"},
                          {"label": "Huriel", "value": "C25664"},
                          {"label": "Irigny", "value": "C25665"},
                          {"label": "Issoire", "value": "C25666"},
                          {"label": "Izeaux", "value": "C25667"},
                          {"label": "Izernore", "value": "C25668"},
                          {"label": "Jacob-Bellecombette", "value": "C25669"},
                          {"label": "Janneyrias", "value": "C25670"},
                          {"label": "Jardin", "value": "C25671"},
                          {"label": "Jarrie", "value": "C25672"},
                          {"label": "Jassans-Riottier", "value": "C25673"},
                          {"label": "Jasseron", "value": "C25674"},
                          {"label": "Jaujac", "value": "C25675"},
                          {"label": "Job", "value": "C25676"},
                          {"label": "Jonage", "value": "C25677"},
                          {"label": "Jons", "value": "C25678"},
                          {"label": "Jonzieux", "value": "C25679"},
                          {"label": "Joyeuse", "value": "C25680"},
                          {"label": "Jujurieux", "value": "C25681"},
                          {"label": "Jussac", "value": "C25682"},
                          {"label": "Letrat", "value": "C25683"},
                          {"label": "LIsle-dAbeau", "value": "C25684"},
                          {"label": "La Balme-de-Sillingy", "value": "C25685"},
                          {"label": "La Bathie", "value": "C25686"},
                          {"label": "La Batie-Montgascon", "value": "C25687"},
                          {"label": "La Begude-de-Mazenc", "value": "C25688"},
                          {"label": "La Biolle", "value": "C25689"},
                          {"label": "La Boisse", "value": "C25690"},
                          {"label": "La Bourboule", "value": "C25691"},
                          {"label": "La Bridoire", "value": "C25692"},
                          {"label": "La Buisse", "value": "C25693"},
                          {"label": "La Chambre", "value": "C25694"},
                          {"label": "La Chapelle-de-la-Tour",
                           "value": "C25695"},
                          {"label": "La Clusaz", "value": "C25696"},
                          {"label": "La Cote-Saint-Andre", "value": "C25697"},
                          {"label": "La Fouillouse", "value": "C25698"},
                          {"label": "La Garde-Adhemar", "value": "C25699"},
                          {"label": "La Grand-Croix", "value": "C25700"},
                          {"label": "La Monnerie-le-Montel", "value": "C25701"},
                          {"label": "La Motte-dAveillans", "value": "C25702"},
                          {"label": "La Motte-Saint-Martin", "value": "C25703"},
                          {"label": "La Motte-Servolex", "value": "C25704"},
                          {"label": "La Mulatiere", "value": "C25705"},
                          {"label": "La Mure", "value": "C25706"},
                          {"label": "La Murette", "value": "C25707"},
                          {"label": "La Pacaudiere", "value": "C25708"},
                          {"label": "La Ravoire", "value": "C25709"},
                          {"label": "La Ricamarie", "value": "C25710"},
                          {"label": "La Roche-Blanche", "value": "C25711"},
                          {"label": "La Roche-de-Glun", "value": "C25712"},
                          {"label": "La Roche-sur-Foron", "value": "C25713"},
                          {"label": "La Rochette", "value": "C25714"},
                          {"label": "La Seauve-sur-Semene", "value": "C25715"},
                          {"label": "La Talaudiere", "value": "C25716"},
                          {"label": "La Terrasse", "value": "C25717"},
                          {"label": "La Tour", "value": "C25718"},
                          {"label": "La Tour-de-Salvagny", "value": "C25719"},
                          {"label": "La Tour-du-Pin", "value": "C25720"},
                          {"label": "La Tour-en-Jarez", "value": "C25721"},
                          {"label": "La Tronche", "value": "C25722"},
                          {"label": "La Verpilliere", "value": "C25723"},
                          {"label": "La Voulte-sur-Rhone", "value": "C25724"},
                          {"label": "Labegude", "value": "C25725"},
                          {"label": "Lablachere", "value": "C25726"},
                          {"label": "Lachapelle-sous-Aubenas",
                           "value": "C25727"},
                          {"label": "Lagnieu", "value": "C25728"},
                          {"label": "Laiz", "value": "C25729"},
                          {"label": "Lalevade-dArdeche", "value": "C25730"},
                          {"label": "Lamastre", "value": "C25731"},
                          {"label": "Lamure-sur-Azergues", "value": "C25732"},
                          {"label": "Lancrans", "value": "C25733"},
                          {"label": "Langeac", "value": "C25734"},
                          {"label": "Lanobre", "value": "C25735"},
                          {"label": "Lans-en-Vercors", "value": "C25736"},
                          {"label": "Lantriac", "value": "C25737"},
                          {"label": "Lapalisse", "value": "C25738"},
                          {"label": "Lapte", "value": "C25739"},
                          {"label": "Larajasse", "value": "C25740"},
                          {"label": "Larequille", "value": "C25741"},
                          {"label": "Largentiere", "value": "C25742"},
                          {"label": "Laroquebrou", "value": "C25743"},
                          {"label": "Larringes", "value": "C25744"},
                          {"label": "Laussonne", "value": "C25745"},
                          {"label": "Lavault-Sainte-Anne", "value": "C25746"},
                          {"label": "Lavilledieu", "value": "C25747"},
                          {"label": "Le Bois-dOingt", "value": "C25748"},
                          {"label": "Le Bourg-dOisans", "value": "C25749"},
                          {"label": "Le Bourget-du-Lac", "value": "C25750"},
                          {"label": "Le Breuil-sur-Couze", "value": "C25751"},
                          {"label": "Le Cendre", "value": "C25752"},
                          {"label": "Le Chambon-Feugerolles",
                           "value": "C25753"},
                          {"label": "Le Chambon-sur-Lignon", "value": "C25754"},
                          {"label": "Le Champ-pres-Froges", "value": "C25755"},
                          {"label": "Le Cheylard", "value": "C25756"},
                          {"label": "Le Cheylas", "value": "C25757"},
                          {"label": "Le Coteau", "value": "C25758"},
                          {"label": "Le Crest", "value": "C25759"},
                          {"label": "Le Donjon", "value": "C25760"},
                          {"label": "Le Grand-Bornand", "value": "C25761"},
                          {"label": "Le Grand-Lemps", "value": "C25762"},
                          {"label": "Le Gua", "value": "C25763"},
                          {"label": "Le Mayet-de-Montagne", "value": "C25764"},
                          {"label": "Le Monastier-sur-Gazeille",
                           "value": "C25765"},
                          {"label": "Le Peage-de-Roussillon",
                           "value": "C25766"},
                          {"label": "Le Perreon", "value": "C25767"},
                          {"label": "Le Pin", "value": "C25768"},
                          {"label": "Le Pont-de-Beauvoisin", "value": "C25769"},
                          {"label": "Le Pont-de-Claix", "value": "C25770"},
                          {"label": "Le Pouzin", "value": "C25771"},
                          {"label": "Le Puy-en-Velay", "value": "C25772"},
                          {"label": "Le Sappey-en-Chartreuse",
                           "value": "C25773"},
                          {"label": "Le Teil", "value": "C25774"},
                          {"label": "Le Touvet", "value": "C25775"},
                          {"label": "Le Versoud", "value": "C25776"},
                          {"label": "Lempdes-sur-Allagnon", "value": "C25777"},
                          {"label": "Lent", "value": "C25778"},
                          {"label": "Lentigny", "value": "C25779"},
                          {"label": "Lentilly", "value": "C25780"},
                          {"label": "Les Abrets", "value": "C25781"},
                          {"label": "Les Allues", "value": "C25782"},
                          {"label": "Les Ancizes", "value": "C25783"},
                          {"label": "Les Avenieres", "value": "C25784"},
                          {"label": "Les Cheres", "value": "C25785"},
                          {"label": "Les Contamines-Montjoie",
                           "value": "C25786"},
                          {"label": "Les Cotes-dArey", "value": "C25787"},
                          {"label": "Les Deux Alpes", "value": "C25788"},
                          {"label": "Les echelles", "value": "C25789"},
                          {"label": "Les Gets", "value": "C25790"},
                          {"label": "Les Houches", "value": "C25791"},
                          {"label": "Les Marches", "value": "C25792"},
                          {"label": "Les Martres-dArtiere", "value": "C25793"},
                          {"label": "Les Roches-de-Condrieu",
                           "value": "C25794"},
                          {"label": "Les Vans", "value": "C25795"},
                          {"label": "Leyment", "value": "C25796"},
                          {"label": "Lezigneux", "value": "C25797"},
                          {"label": "Lezoux", "value": "C25798"},
                          {"label": "Liergues", "value": "C25799"},
                          {"label": "Limas", "value": "C25800"},
                          {"label": "Limonest", "value": "C25801"},
                          {"label": "Lissieu", "value": "C25802"},
                          {"label": "Livet-et-Gavet", "value": "C25803"},
                          {"label": "Livron-sur-Drome", "value": "C25804"},
                          {"label": "Loire-sur-Rhone", "value": "C25805"},
                          {"label": "Loisin", "value": "C25806"},
                          {"label": "Lorette", "value": "C25807"},
                          {"label": "Loriol-sur-Drome", "value": "C25808"},
                          {"label": "Lovagny", "value": "C25809"},
                          {"label": "Loyettes", "value": "C25810"},
                          {"label": "Lozanne", "value": "C25811"},
                          {"label": "Lucenay", "value": "C25812"},
                          {"label": "Lucinges", "value": "C25813"},
                          {"label": "Lugrin", "value": "C25814"},
                          {"label": "Lumbin", "value": "C25815"},
                          {"label": "Lurcy-Levis", "value": "C25816"},
                          {"label": "Lusigny", "value": "C25817"},
                          {"label": "Luzinay", "value": "C25818"},
                          {"label": "Lyaud", "value": "C25819"},
                          {"label": "Lyon", "value": "C25820"},
                          {"label": "Mably", "value": "C25821"},
                          {"label": "Maclas", "value": "C25822"},
                          {"label": "Macot-la-Plagne", "value": "C25823"},
                          {"label": "Magland", "value": "C25824"},
                          {"label": "Malataverne", "value": "C25825"},
                          {"label": "Malintrat", "value": "C25826"},
                          {"label": "Malissard", "value": "C25827"},
                          {"label": "Manzat", "value": "C25828"},
                          {"label": "Manziat", "value": "C25829"},
                          {"label": "Marboz", "value": "C25830"},
                          {"label": "Marcellaz-Albanais", "value": "C25831"},
                          {"label": "Marcilly-le-Chatel", "value": "C25832"},
                          {"label": "Marennes", "value": "C25833"},
                          {"label": "Margencel", "value": "C25834"},
                          {"label": "Marignier", "value": "C25835"},
                          {"label": "Marin", "value": "C25836"},
                          {"label": "Maringues", "value": "C25837"},
                          {"label": "Marlhes", "value": "C25838"},
                          {"label": "Marnaz", "value": "C25839"},
                          {"label": "Marsac-en-Livradois", "value": "C25840"},
                          {"label": "Marsanne", "value": "C25841"},
                          {"label": "Marsat", "value": "C25842"},
                          {"label": "Marthod", "value": "C25843"},
                          {"label": "Martignat", "value": "C25844"},
                          {"label": "Massiac", "value": "C25845"},
                          {"label": "Massieux", "value": "C25846"},
                          {"label": "Massongy", "value": "C25847"},
                          {"label": "Maubec", "value": "C25848"},
                          {"label": "Mauriac", "value": "C25849"},
                          {"label": "Maurs", "value": "C25850"},
                          {"label": "Mauves", "value": "C25851"},
                          {"label": "Maxilly-sur-Leman", "value": "C25852"},
                          {"label": "Mazet-Saint-Voy", "value": "C25853"},
                          {"label": "Meaudre", "value": "C25854"},
                          {"label": "Megeve", "value": "C25855"},
                          {"label": "Meillonnas", "value": "C25856"},
                          {"label": "Menetrol", "value": "C25857"},
                          {"label": "Mens", "value": "C25858"},
                          {"label": "Menthon-Saint-Bernard", "value": "C25859"},
                          {"label": "Mercurol-Veaunes", "value": "C25860"},
                          {"label": "Mercury", "value": "C25861"},
                          {"label": "Meribel", "value": "C25862"},
                          {"label": "Mery", "value": "C25863"},
                          {"label": "Messeix", "value": "C25864"},
                          {"label": "Messery", "value": "C25865"},
                          {"label": "Messimy", "value": "C25866"},
                          {"label": "Metz", "value": "C25867"},
                          {"label": "Meximieux", "value": "C25868"},
                          {"label": "Meylan", "value": "C25869"},
                          {"label": "Meysse", "value": "C25870"},
                          {"label": "Meythet", "value": "C25871"},
                          {"label": "Meyzieu", "value": "C25872"},
                          {"label": "Mezel", "value": "C25873"},
                          {"label": "Mezeriat", "value": "C25874"},
                          {"label": "Mieussy", "value": "C25875"},
                          {"label": "Millery", "value": "C25876"},
                          {"label": "Mionnay", "value": "C25877"},
                          {"label": "Mions", "value": "C25878"},
                          {"label": "Mirabel-aux-Baronnies", "value": "C25879"},
                          {"label": "Mirefleurs", "value": "C25880"},
                          {"label": "Miribel", "value": "C25881"},
                          {"label": "Miribel-les-echelles", "value": "C25882"},
                          {"label": "Miserieux", "value": "C25883"},
                          {"label": "Modane", "value": "C25884"},
                          {"label": "Moidieu", "value": "C25885"},
                          {"label": "Moirans", "value": "C25886"},
                          {"label": "Molinet", "value": "C25887"},
                          {"label": "Monistrol-sur-Loire", "value": "C25888"},
                          {"label": "Monnetier-Mornex", "value": "C25889"},
                          {"label": "Mont-de-Lans", "value": "C25890"},
                          {"label": "Mont-Dore", "value": "C25891"},
                          {"label": "Mont-Saxonnex", "value": "C25892"},
                          {"label": "Montagnat", "value": "C25893"},
                          {"label": "Montagny", "value": "C25894"},
                          {"label": "Montaigut", "value": "C25895"},
                          {"label": "Montalieu", "value": "C25896"},
                          {"label": "Montanay", "value": "C25897"},
                          {"label": "Montbonnot-Saint-Martin",
                           "value": "C25898"},
                          {"label": "Montboucher-sur-Jabron",
                           "value": "C25899"},
                          {"label": "Montbrison", "value": "C25900"},
                          {"label": "Monteleger", "value": "C25901"},
                          {"label": "Montelier", "value": "C25902"},
                          {"label": "Montelimar", "value": "C25903"},
                          {"label": "Montfaucon-en-Velay", "value": "C25904"},
                          {"label": "Montferrat", "value": "C25905"},
                          {"label": "Montlucon", "value": "C25906"},
                          {"label": "Montluel", "value": "C25907"},
                          {"label": "Montmarault", "value": "C25908"},
                          {"label": "Montmelian", "value": "C25909"},
                          {"label": "Montmerle-sur-Saone", "value": "C25910"},
                          {"label": "Montmeyran", "value": "C25911"},
                          {"label": "Montoison", "value": "C25912"},
                          {"label": "Montreal-la-Cluse", "value": "C25913"},
                          {"label": "Montrevel-en-Bresse", "value": "C25914"},
                          {"label": "Montrond-les-Bains", "value": "C25915"},
                          {"label": "Montrottier", "value": "C25916"},
                          {"label": "Montsegur-sur-Lauzon", "value": "C25917"},
                          {"label": "Morance", "value": "C25918"},
                          {"label": "Morestel", "value": "C25919"},
                          {"label": "Mornant", "value": "C25920"},
                          {"label": "Morzine", "value": "C25921"},
                          {"label": "Moulins", "value": "C25922"},
                          {"label": "Mours-Saint-Eusebe", "value": "C25923"},
                          {"label": "Moutiers", "value": "C25924"},
                          {"label": "Mouxy", "value": "C25925"},
                          {"label": "Moye", "value": "C25926"},
                          {"label": "Mozac", "value": "C25927"},
                          {"label": "Murat", "value": "C25928"},
                          {"label": "Nantua", "value": "C25929"},
                          {"label": "Naucelles", "value": "C25930"},
                          {"label": "Neris-les-Bains", "value": "C25931"},
                          {"label": "Neuilly-le-Real", "value": "C25932"},
                          {"label": "Neulise", "value": "C25933"},
                          {"label": "Neussargues", "value": "C25934"},
                          {"label": "Neuvecelle", "value": "C25935"},
                          {"label": "Neuveglise", "value": "C25936"},
                          {"label": "Neuville-les-Dames", "value": "C25937"},
                          {"label": "Neuville-sur-Ain", "value": "C25938"},
                          {"label": "Neuville-sur-Saone", "value": "C25939"},
                          {"label": "Neuvy", "value": "C25940"},
                          {"label": "Neydens", "value": "C25941"},
                          {"label": "Nievroz", "value": "C25942"},
                          {"label": "Nivolas-Vermelle", "value": "C25943"},
                          {"label": "Nohanent", "value": "C25944"},
                          {"label": "Noiretable", "value": "C25945"},
                          {"label": "Notre-Dame-de-Mesage", "value": "C25946"},
                          {"label": "Novalaise", "value": "C25947"},
                          {"label": "Noyarey", "value": "C25948"},
                          {"label": "Nyons", "value": "C25949"},
                          {"label": "Orcet", "value": "C25950"},
                          {"label": "Orcines", "value": "C25951"},
                          {"label": "Orleat", "value": "C25952"},
                          {"label": "Orlienas", "value": "C25953"},
                          {"label": "Ornex", "value": "C25954"},
                          {"label": "Ouches", "value": "C25955"},
                          {"label": "Oullins", "value": "C25956"},
                          {"label": "Oyonnax", "value": "C25957"},
                          {"label": "Oytier-Saint-Oblas", "value": "C25958"},
                          {"label": "Panissieres", "value": "C25959"},
                          {"label": "Paslieres", "value": "C25960"},
                          {"label": "Passy", "value": "C25961"},
                          {"label": "Paulhaguet", "value": "C25962"},
                          {"label": "Peaugres", "value": "C25963"},
                          {"label": "Peillonnex", "value": "C25964"},
                          {"label": "Pelussin", "value": "C25965"},
                          {"label": "Perignat-les-Sarlieve", "value": "C25966"},
                          {"label": "Perignat-sur-Allier", "value": "C25967"},
                          {"label": "Perigneux", "value": "C25968"},
                          {"label": "Peron", "value": "C25969"},
                          {"label": "Peronnas", "value": "C25970"},
                          {"label": "Perouges", "value": "C25971"},
                          {"label": "Perreux", "value": "C25972"},
                          {"label": "Perrignier", "value": "C25973"},
                          {"label": "Pers-Jussy", "value": "C25974"},
                          {"label": "Peschadoires", "value": "C25975"},
                          {"label": "Peyrins", "value": "C25976"},
                          {"label": "Pierre-Benite", "value": "C25977"},
                          {"label": "Pierre-Chatel", "value": "C25978"},
                          {"label": "Pierrefort", "value": "C25979"},
                          {"label": "Pierrelatte", "value": "C25980"},
                          {"label": "Pionsat", "value": "C25981"},
                          {"label": "Plauzat", "value": "C25982"},
                          {"label": "Pleaux", "value": "C25983"},
                          {"label": "Poisat", "value": "C25984"},
                          {"label": "Poisy", "value": "C25985"},
                          {"label": "Polignac", "value": "C25986"},
                          {"label": "Polliat", "value": "C25987"},
                          {"label": "Pollionnay", "value": "C25988"},
                          {"label": "Polminhac", "value": "C25989"},
                          {"label": "Pommiers", "value": "C25990"},
                          {"label": "Poncin", "value": "C25991"},
                          {"label": "Pont-dAin", "value": "C25992"},
                          {"label": "Pont-de-Cheruy", "value": "C25993"},
                          {"label": "Pont-de-Vaux", "value": "C25994"},
                          {"label": "Pont-de-Veyle", "value": "C25995"},
                          {"label": "Pont-du-Chateau", "value": "C25996"},
                          {"label": "Pont-en-Royans", "value": "C25997"},
                          {"label": "Pont-eveque", "value": "C25998"},
                          {"label": "Pont-Salomon", "value": "C25999"},
                          {"label": "Pontcharra", "value": "C26000"},
                          {"label": "Pontcharra-sur-Turdine",
                           "value": "C26001"},
                          {"label": "Porcieu", "value": "C26002"},
                          {"label": "Portes-les-Valence", "value": "C26003"},
                          {"label": "Pouilly-les-Feurs", "value": "C26004"},
                          {"label": "Pouilly-les-Nonains", "value": "C26005"},
                          {"label": "Pouilly-sous-Charlieu", "value": "C26006"},
                          {"label": "Pragoulin", "value": "C26007"},
                          {"label": "Praz-sur-Arly", "value": "C26008"},
                          {"label": "Precieux", "value": "C26009"},
                          {"label": "Premilhat", "value": "C26010"},
                          {"label": "Priay", "value": "C26011"},
                          {"label": "Pringy", "value": "C26012"},
                          {"label": "Privas", "value": "C26013"},
                          {"label": "Publier", "value": "C26014"},
                          {"label": "Pusignan", "value": "C26015"},
                          {"label": "Puy-Guillaume", "value": "C26016"},
                          {"label": "Quincie-en-Beaujolais", "value": "C26017"},
                          {"label": "Quincieux", "value": "C26018"},
                          {"label": "Quinssaines", "value": "C26019"},
                          {"label": "Quintenas", "value": "C26020"},
                          {"label": "Randan", "value": "C26021"},
                          {"label": "Regny", "value": "C26022"},
                          {"label": "Reignier-esery", "value": "C26023"},
                          {"label": "Renage", "value": "C26024"},
                          {"label": "Renaison", "value": "C26025"},
                          {"label": "Replonges", "value": "C26026"},
                          {"label": "Retournac", "value": "C26027"},
                          {"label": "Revel", "value": "C26028"},
                          {"label": "Reventin-Vaugris", "value": "C26029"},
                          {"label": "Reyrieux", "value": "C26030"},
                          {"label": "Rillieux-la-Pape", "value": "C26031"},
                          {"label": "Riom", "value": "C26032"},
                          {"label": "Riom-es-Montagnes", "value": "C26033"},
                          {"label": "Riorges", "value": "C26034"},
                          {"label": "Riotord", "value": "C26035"},
                          {"label": "Rive-de-Gier", "value": "C26036"},
                          {"label": "Rives", "value": "C26037"},
                          {"label": "Roanne", "value": "C26038"},
                          {"label": "Roche", "value": "C26039"},
                          {"label": "Roche-la-Moliere", "value": "C26040"},
                          {"label": "Rochegude", "value": "C26041"},
                          {"label": "Rochemaure", "value": "C26042"},
                          {"label": "Rochetaillee-sur-Saone",
                           "value": "C26043"},
                          {"label": "Roiffieux", "value": "C26044"},
                          {"label": "Romagnat", "value": "C26045"},
                          {"label": "Romagnieu", "value": "C26046"},
                          {"label": "Romans-sur-Isere", "value": "C26047"},
                          {"label": "Rosieres", "value": "C26048"},
                          {"label": "Roussillon", "value": "C26049"},
                          {"label": "Royat", "value": "C26050"},
                          {"label": "Roybon", "value": "C26051"},
                          {"label": "Rozier-en-Donzy", "value": "C26052"},
                          {"label": "Rumilly", "value": "C26053"},
                          {"label": "Ruoms", "value": "C26054"},
                          {"label": "Ruy", "value": "C26055"},
                          {"label": "Sablons", "value": "C26056"},
                          {"label": "Sail-sous-Couzan", "value": "C26057"},
                          {"label": "Sain-Bel", "value": "C26058"},
                          {"label": "Saint-Agreve", "value": "C26059"},
                          {"label": "Saint-Alban-de-Roche", "value": "C26060"},
                          {"label": "Saint-Alban-Leysse", "value": "C26061"},
                          {"label": "Saint-Amant-Tallende", "value": "C26062"},
                          {"label": "Saint-Andeol-le-Chateau",
                           "value": "C26063"},
                          {"label": "Saint-Andre-de-Corcy", "value": "C26064"},
                          {"label": "Saint-Andre-le-Gaz", "value": "C26065"},
                          {"label": "Saint-Andre-le-Puy", "value": "C26066"},
                          {"label": "Saint-Andre-sur-Vieux-Jonc",
                           "value": "C26067"},
                          {"label": "Saint-Antoine-lAbbaye", "value": "C26068"},
                          {"label": "Saint-Aupre", "value": "C26069"},
                          {"label": "Saint-Baldoph", "value": "C26070"},
                          {"label": "Saint-Barthelemy-de-Vals",
                           "value": "C26071"},
                          {"label": "Saint-Beauzire", "value": "C26072"},
                          {"label": "Saint-Beron", "value": "C26073"},
                          {"label": "Saint-Bon-Tarentaise", "value": "C26074"},
                          {"label": "Saint-Bonnet-de-Mure", "value": "C26075"},
                          {"label": "Saint-Bonnet-le-Chateau",
                           "value": "C26076"},
                          {"label": "Saint-Bonnet-les-Oules",
                           "value": "C26077"},
                          {"label": "Saint-Bonnet-pres-Riom",
                           "value": "C26078"},
                          {"label": "Saint-Cassien", "value": "C26079"},
                          {"label": "Saint-Cergues", "value": "C26080"},
                          {"label": "Saint-Cernin", "value": "C26081"},
                          {"label": "Saint-Chamond", "value": "C26082"},
                          {"label": "Saint-Chef", "value": "C26083"},
                          {"label": "Saint-Christo-en-Jarez",
                           "value": "C26084"},
                          {"label": "Saint-Clair-de-la-Tour",
                           "value": "C26085"},
                          {"label": "Saint-Clair-du-Rhone", "value": "C26086"},
                          {"label": "Saint-Cyprien", "value": "C26087"},
                          {"label": "Saint-Cyr", "value": "C26088"},
                          {"label": "Saint-Cyr-sur-le-Rhone",
                           "value": "C26089"},
                          {"label": "Saint-Cyr-sur-Menthon", "value": "C26090"},
                          {"label": "Saint-Denis-de-Cabanne",
                           "value": "C26091"},
                          {"label": "Saint-Denis-en-Bugey", "value": "C26092"},
                          {"label": "Saint-Denis-les-Bourg", "value": "C26093"},
                          {"label": "Saint-Didier-de-Formans",
                           "value": "C26094"},
                          {"label": "Saint-Didier-de-la-Tour",
                           "value": "C26095"},
                          {"label": "Saint-Didier-en-Velay", "value": "C26096"},
                          {"label": "Saint-Didier-sous-Riverie",
                           "value": "C26097"},
                          {"label": "Saint-Didier-sur-Chalaronne",
                           "value": "C26098"},
                          {"label": "Saint-egreve", "value": "C26099"},
                          {"label": "Saint-eloy-les-Mines", "value": "C26100"},
                          {"label": "Saint-etienne", "value": "C26101"},
                          {"label": "Saint-etienne-de-Crossey",
                           "value": "C26102"},
                          {"label": "Saint-Etienne-de-Cuines",
                           "value": "C26103"},
                          {"label": "Saint-etienne-de-Fontbellon",
                           "value": "C26104"},
                          {"label": "Saint-etienne-de-Saint-Geoirs",
                           "value": "C26105"},
                          {"label": "Saint-etienne-des-Oullieres",
                           "value": "C26106"},
                          {"label": "Saint-etienne-du-Bois", "value": "C26107"},
                          {"label": "Saint-etienne-sur-Chalaronne",
                           "value": "C26108"},
                          {"label": "Saint-Felicien", "value": "C26109"},
                          {"label": "Saint-Felix", "value": "C26110"},
                          {"label": "Saint-Flour", "value": "C26111"},
                          {"label": "Saint-Fons", "value": "C26112"},
                          {"label": "Saint-Forgeux", "value": "C26113"},
                          {"label": "Saint-Francois", "value": "C26114"},
                          {"label": "Saint-Galmier", "value": "C26115"},
                          {"label": "Saint-Genes-Champanelle",
                           "value": "C26116"},
                          {"label": "Saint-Genest-Lerpt", "value": "C26117"},
                          {"label": "Saint-Genest-Malifaux", "value": "C26118"},
                          {"label": "Saint-Genis-Laval", "value": "C26119"},
                          {"label": "Saint-Genis-les-Ollieres",
                           "value": "C26120"},
                          {"label": "Saint-Genis-Pouilly", "value": "C26121"},
                          {"label": "Saint-Genix-sur-Guiers",
                           "value": "C26122"},
                          {"label": "Saint-Geoire-en-Valdaine",
                           "value": "C26123"},
                          {"label": "Saint-Georges-de-Commiers",
                           "value": "C26124"},
                          {"label": "Saint-Georges-de-Mons", "value": "C26125"},
                          {"label": "Saint-Georges-de-Reneins",
                           "value": "C26126"},
                          {"label": "Saint-Georges-Haute-Ville",
                           "value": "C26127"},
                          {"label": "Saint-Georges-les-Bains",
                           "value": "C26128"},
                          {"label": "Saint-Georges-sur-Allier",
                           "value": "C26129"},
                          {"label": "Saint-Gerand-le-Puy", "value": "C26130"},
                          {"label": "Saint-Germain-des-Fosses",
                           "value": "C26131"},
                          {"label": "Saint-Germain-Laprade", "value": "C26132"},
                          {"label": "Saint-Germain-Laval", "value": "C26133"},
                          {"label": "Saint-Germain-Lembron", "value": "C26134"},
                          {"label": "Saint-Germain-Lespinasse",
                           "value": "C26135"},
                          {"label": "Saint-Germain-Nuelles", "value": "C26136"},
                          {"label": "Saint-Gervais-dAuvergne",
                           "value": "C26137"},
                          {"label": "Saint-Gervais-les-Bains",
                           "value": "C26138"},
                          {"label": "Saint-Heand", "value": "C26139"},
                          {"label": "Saint-Hilaire", "value": "C26140"},
                          {"label": "Saint-Hilaire-de-la-Cote",
                           "value": "C26141"},
                          {"label": "Saint-Hilaire-du-Rosier",
                           "value": "C26142"},
                          {"label": "Saint-Ismier", "value": "C26143"},
                          {"label": "Saint-Jean-Bonnefonds", "value": "C26144"},
                          {"label": "Saint-Jean-dAulps", "value": "C26145"},
                          {"label": "Saint-Jean-de-Bournay", "value": "C26146"},
                          {"label": "Saint-Jean-de-Gonville",
                           "value": "C26147"},
                          {"label": "Saint-Jean-de-Maurienne",
                           "value": "C26148"},
                          {"label": "Saint-Jean-de-Moirans", "value": "C26149"},
                          {"label": "Saint-Jean-de-Muzols", "value": "C26150"},
                          {"label": "Saint-Jean-de-Niost", "value": "C26151"},
                          {"label": "Saint-Jean-de-Sixt", "value": "C26152"},
                          {"label": "Saint-Jean-de-Soudain", "value": "C26153"},
                          {"label": "Saint-Jean-en-Royans", "value": "C26154"},
                          {"label": "Saint-Jean-le-Vieux", "value": "C26155"},
                          {"label": "Saint-Jean-sur-Veyle", "value": "C26156"},
                          {"label": "Saint-Jeoire", "value": "C26157"},
                          {"label": "Saint-Jorioz", "value": "C26158"},
                          {"label": "Saint-Joseph", "value": "C26159"},
                          {"label": "Saint-Joseph-de-Riviere",
                           "value": "C26160"},
                          {"label": "Saint-Julien-Chapteuil",
                           "value": "C26161"},
                          {"label": "Saint-Julien-en-Genevois",
                           "value": "C26162"},
                          {"label": "Saint-Julien-en-Saint-Alban",
                           "value": "C26163"},
                          {"label": "Saint-Julien-Molin-Molette",
                           "value": "C26164"},
                          {"label": "Saint-Just-Chaleyssin", "value": "C26165"},
                          {"label": "Saint-Just-dArdeche", "value": "C26166"},
                          {"label": "Saint-Just-de-Claix", "value": "C26167"},
                          {"label": "Saint-Just-en-Chevalet",
                           "value": "C26168"},
                          {"label": "Saint-Just-la-Pendue", "value": "C26169"},
                          {"label": "Saint-Just-Malmont", "value": "C26170"},
                          {"label": "Saint-Just-Saint-Rambert",
                           "value": "C26171"},
                          {"label": "Saint-Lattier", "value": "C26172"},
                          {"label": "Saint-Laurent-de-Chamousset",
                           "value": "C26173"},
                          {"label": "Saint-Laurent-de-Mure", "value": "C26174"},
                          {"label": "Saint-Laurent-du-Pape", "value": "C26175"},
                          {"label": "Saint-Laurent-du-Pont", "value": "C26176"},
                          {"label": "Saint-Laurent-en-Royans",
                           "value": "C26177"},
                          {"label": "Saint-Laurent-sur-Saone",
                           "value": "C26178"},
                          {"label": "Saint-Leger-sur-Roanne",
                           "value": "C26179"},
                          {"label": "Saint-Mamet-la-Salvetat",
                           "value": "C26180"},
                          {"label": "Saint-Marcel-dArdeche", "value": "C26181"},
                          {"label": "Saint-Marcel-les-Annonay",
                           "value": "C26182"},
                          {"label": "Saint-Marcel-les-Sauzet",
                           "value": "C26183"},
                          {"label": "Saint-Marcel-les-Valence",
                           "value": "C26184"},
                          {"label": "Saint-Marcellin", "value": "C26185"},
                          {"label": "Saint-Marcellin-en-Forez",
                           "value": "C26186"},
                          {"label": "Saint-Martin-Bellevue", "value": "C26187"},
                          {"label": "Saint-Martin-dHeres", "value": "C26188"},
                          {"label": "Saint-Martin-de-Belleville",
                           "value": "C26189"},
                          {"label": "Saint-Martin-de-Valamas",
                           "value": "C26190"},
                          {"label": "Saint-Martin-du-Frene", "value": "C26191"},
                          {"label": "Saint-Martin-du-Mont", "value": "C26192"},
                          {"label": "Saint-Martin-en-Haut", "value": "C26193"},
                          {"label": "Saint-Martin-la-Plaine",
                           "value": "C26194"},
                          {"label": "Saint-Martin-le-Vinoux",
                           "value": "C26195"},
                          {"label": "Saint-Maurice-de-Beynost",
                           "value": "C26196"},
                          {"label": "Saint-Maurice-de-Gourdans",
                           "value": "C26197"},
                          {"label": "Saint-Maurice-de-Lignon",
                           "value": "C26198"},
                          {"label": "Saint-Maurice-en-Gourgois",
                           "value": "C26199"},
                          {"label": "Saint-Maurice-sur-Dargoire",
                           "value": "C26200"},
                          {"label": "Saint-Michel-de-Maurienne",
                           "value": "C26201"},
                          {"label": "Saint-Nazaire-les-Eymes",
                           "value": "C26202"},
                          {"label": "Saint-Nizier-sous-Charlieu",
                           "value": "C26203"},
                          {"label": "Saint-Ours", "value": "C26204"},
                          {"label": "Saint-Pal-de-Mons", "value": "C26205"},
                          {"label": "Saint-Paul", "value": "C26206"},
                          {"label": "Saint-Paul-de-Varax", "value": "C26207"},
                          {"label": "Saint-Paul-de-Varces", "value": "C26208"},
                          {"label": "Saint-Paul-des-Landes", "value": "C26209"},
                          {"label": "Saint-Paul-en-Chablais",
                           "value": "C26210"},
                          {"label": "Saint-Paul-en-Jarez", "value": "C26211"},
                          {"label": "Saint-Paul-les-Romans", "value": "C26212"},
                          {"label": "Saint-Paul-Trois-Chateaux",
                           "value": "C26213"},
                          {"label": "Saint-Paulien", "value": "C26214"},
                          {"label": "Saint-Peray", "value": "C26215"},
                          {"label": "Saint-Pierre-de-Boeuf", "value": "C26216"},
                          {"label": "Saint-Pierre-de-Chandieu",
                           "value": "C26217"},
                          {"label": "Saint-Pierre-la-Palud", "value": "C26218"},
                          {"label": "Saint-Pourcain-sur-Sioule",
                           "value": "C26219"},
                          {"label": "Saint-Priest", "value": "C26220"},
                          {"label": "Saint-Priest-en-Jarez", "value": "C26221"},
                          {"label": "Saint-Privat", "value": "C26222"},
                          {"label": "Saint-Quentin-Fallavier",
                           "value": "C26223"},
                          {"label": "Saint-Quentin-sur-Isere",
                           "value": "C26224"},
                          {"label": "Saint-Rambert-en-Bugey",
                           "value": "C26225"},
                          {"label": "Saint-Remy-de-Maurienne",
                           "value": "C26226"},
                          {"label": "Saint-Remy-en-Rollat", "value": "C26227"},
                          {"label": "Saint-Remy-sur-Durolle",
                           "value": "C26228"},
                          {"label": "Saint-Restitut", "value": "C26229"},
                          {"label": "Saint-Romain-de-Jalionas",
                           "value": "C26230"},
                          {"label": "Saint-Romain-de-Popey", "value": "C26231"},
                          {"label": "Saint-Romain-en-Gal", "value": "C26232"},
                          {"label": "Saint-Romain-la-Motte", "value": "C26233"},
                          {"label": "Saint-Romain-le-Puy", "value": "C26234"},
                          {"label": "Saint-Romans", "value": "C26235"},
                          {"label": "Saint-Saturnin", "value": "C26236"},
                          {"label": "Saint-Sauves-dAuvergne",
                           "value": "C26237"},
                          {"label": "Saint-Sauveur", "value": "C26238"},
                          {"label": "Saint-Sauveur-de-Montagut",
                           "value": "C26239"},
                          {"label": "Saint-Sauveur-en-Rue", "value": "C26240"},
                          {"label": "Saint-Savin", "value": "C26241"},
                          {"label": "Saint-Sernin", "value": "C26242"},
                          {"label": "Saint-Simeon-de-Bressieux",
                           "value": "C26243"},
                          {"label": "Saint-Simon", "value": "C26244"},
                          {"label": "Saint-Sorlin-en-Bugey", "value": "C26245"},
                          {"label": "Saint-Sorlin-en-Valloire",
                           "value": "C26246"},
                          {"label": "Saint-Symphorien-de-Lay",
                           "value": "C26247"},
                          {"label": "Saint-Symphorien-sur-Coise",
                           "value": "C26248"},
                          {"label": "Saint-Trivier-de-Courtes",
                           "value": "C26249"},
                          {"label": "Saint-Trivier-sur-Moignans",
                           "value": "C26250"},
                          {"label": "Saint-Uze", "value": "C26251"},
                          {"label": "Saint-Vallier", "value": "C26252"},
                          {"label": "Saint-Verand", "value": "C26253"},
                          {"label": "Saint-Victor", "value": "C26254"},
                          {"label": "Saint-Victor-de-Cessieu",
                           "value": "C26255"},
                          {"label": "Saint-Vincent-de-Mercuze",
                           "value": "C26256"},
                          {"label": "Saint-Yorre", "value": "C26257"},
                          {"label": "Sainte-Colombe", "value": "C26258"},
                          {"label": "Sainte-Consorce", "value": "C26259"},
                          {"label": "Sainte-Euphemie", "value": "C26260"},
                          {"label": "Sainte-Florine", "value": "C26261"},
                          {"label": "Sainte-Foy-lArgentiere",
                           "value": "C26262"},
                          {"label": "Sainte-Foy-les-Lyon", "value": "C26263"},
                          {"label": "Sainte-Helene-sur-Isere",
                           "value": "C26264"},
                          {"label": "Sainte-Sigolene", "value": "C26265"},
                          {"label": "Salagnon", "value": "C26266"},
                          {"label": "Salaise-sur-Sanne", "value": "C26267"},
                          {"label": "Sales", "value": "C26268"},
                          {"label": "Salins-les-Thermes", "value": "C26269"},
                          {"label": "Sallanches", "value": "C26270"},
                          {"label": "Samoens", "value": "C26271"},
                          {"label": "Sansac-de-Marmiesse", "value": "C26272"},
                          {"label": "Sarras", "value": "C26273"},
                          {"label": "Sassenage", "value": "C26274"},
                          {"label": "Sathonay-Camp", "value": "C26275"},
                          {"label": "Sathonay-Village", "value": "C26276"},
                          {"label": "Satillieu", "value": "C26277"},
                          {"label": "Satolas-et-Bonce", "value": "C26278"},
                          {"label": "Saugues", "value": "C26279"},
                          {"label": "Saulce-sur-Rhone", "value": "C26280"},
                          {"label": "Sault-Brenaz", "value": "C26281"},
                          {"label": "Sauverny", "value": "C26282"},
                          {"label": "Sauxillanges", "value": "C26283"},
                          {"label": "Sauzet", "value": "C26284"},
                          {"label": "Savasse", "value": "C26285"},
                          {"label": "Savigneux", "value": "C26286"},
                          {"label": "Savigny", "value": "C26287"},
                          {"label": "Savoie", "value": "C26288"},
                          {"label": "Sayat", "value": "C26289"},
                          {"label": "Sciez", "value": "C26290"},
                          {"label": "Scionzier", "value": "C26291"},
                          {"label": "Seez", "value": "C26292"},
                          {"label": "Segny", "value": "C26293"},
                          {"label": "Septeme", "value": "C26294"},
                          {"label": "Serezin-du-Rhone", "value": "C26295"},
                          {"label": "Sergy", "value": "C26296"},
                          {"label": "Sermerieu", "value": "C26297"},
                          {"label": "Serpaize", "value": "C26298"},
                          {"label": "Serrieres", "value": "C26299"},
                          {"label": "Serrieres-de-Briord", "value": "C26300"},
                          {"label": "Servas", "value": "C26301"},
                          {"label": "Sevrier", "value": "C26302"},
                          {"label": "Seynod", "value": "C26303"},
                          {"label": "Seyssinet-Pariset", "value": "C26304"},
                          {"label": "Seyssins", "value": "C26305"},
                          {"label": "Seyssuel", "value": "C26306"},
                          {"label": "Sillans", "value": "C26307"},
                          {"label": "Sillingy", "value": "C26308"},
                          {"label": "Simandres", "value": "C26309"},
                          {"label": "Solaize", "value": "C26310"},
                          {"label": "Solignac-sur-Loire", "value": "C26311"},
                          {"label": "Sonnay", "value": "C26312"},
                          {"label": "Sonnaz", "value": "C26313"},
                          {"label": "Sorbiers", "value": "C26314"},
                          {"label": "Soucieu-en-Jarrest", "value": "C26315"},
                          {"label": "Sourcieux-les-Mines", "value": "C26316"},
                          {"label": "Souvigny", "value": "C26317"},
                          {"label": "Soyons", "value": "C26318"},
                          {"label": "Sury-le-Comtal", "value": "C26319"},
                          {"label": "Suze-la-Rousse", "value": "C26320"},
                          {"label": "Tain-lHermitage", "value": "C26321"},
                          {"label": "Tallende", "value": "C26322"},
                          {"label": "Talloires", "value": "C26323"},
                          {"label": "Taluyers", "value": "C26324"},
                          {"label": "Taninges", "value": "C26325"},
                          {"label": "Tarare", "value": "C26326"},
                          {"label": "Tassin-la-Demi-Lune", "value": "C26327"},
                          {"label": "Taulignan", "value": "C26328"},
                          {"label": "Tenay", "value": "C26329"},
                          {"label": "Tence", "value": "C26330"},
                          {"label": "Tencin", "value": "C26331"},
                          {"label": "Ternay", "value": "C26332"},
                          {"label": "Theize", "value": "C26333"},
                          {"label": "Theys", "value": "C26334"},
                          {"label": "Thiers", "value": "C26335"},
                          {"label": "Thil", "value": "C26336"},
                          {"label": "Thizy-les-Bourgs", "value": "C26337"},
                          {"label": "Thoiry", "value": "C26338"},
                          {"label": "Thoissey", "value": "C26339"},
                          {"label": "Thones", "value": "C26340"},
                          {"label": "Thonon-les-Bains", "value": "C26341"},
                          {"label": "Thorens-Glieres", "value": "C26342"},
                          {"label": "Thuellin", "value": "C26343"},
                          {"label": "Thueyts", "value": "C26344"},
                          {"label": "Thurins", "value": "C26345"},
                          {"label": "Thyez", "value": "C26346"},
                          {"label": "Tignes", "value": "C26347"},
                          {"label": "Tignieu", "value": "C26348"},
                          {"label": "Tossiat", "value": "C26349"},
                          {"label": "Toulaud", "value": "C26350"},
                          {"label": "Toulon-sur-Allier", "value": "C26351"},
                          {"label": "Tournon-sur-Rhone", "value": "C26352"},
                          {"label": "Toussieu", "value": "C26353"},
                          {"label": "Tramoyes", "value": "C26354"},
                          {"label": "Treffort-Cuisiat", "value": "C26355"},
                          {"label": "Trept", "value": "C26356"},
                          {"label": "Tresserve", "value": "C26357"},
                          {"label": "Trevol", "value": "C26358"},
                          {"label": "Trevoux", "value": "C26359"},
                          {"label": "Tulette", "value": "C26360"},
                          {"label": "Tullins", "value": "C26361"},
                          {"label": "Ugine", "value": "C26362"},
                          {"label": "Unieux", "value": "C26363"},
                          {"label": "Upie", "value": "C26364"},
                          {"label": "Usson-en-Forez", "value": "C26365"},
                          {"label": "Val Thorens", "value": "C26366"},
                          {"label": "Val-dIsere", "value": "C26367"},
                          {"label": "Valence", "value": "C26368"},
                          {"label": "Valencin", "value": "C26369"},
                          {"label": "Valleiry", "value": "C26370"},
                          {"label": "Vallieres", "value": "C26371"},
                          {"label": "Valloire", "value": "C26372"},
                          {"label": "Vallon-en-Sully", "value": "C26373"},
                          {"label": "Vallon-Pont-dArc", "value": "C26374"},
                          {"label": "Vals-les-Bains", "value": "C26375"},
                          {"label": "Vals-pres-le-Puy", "value": "C26376"},
                          {"label": "Varennes-sur-Allier", "value": "C26377"},
                          {"label": "Vaugneray", "value": "C26378"},
                          {"label": "Vaulnaveys-le-Bas", "value": "C26379"},
                          {"label": "Vaulnaveys-le-Haut", "value": "C26380"},
                          {"label": "Vaulx-en-Velin", "value": "C26381"},
                          {"label": "Vaulx-Milieu", "value": "C26382"},
                          {"label": "Vaux-en-Bugey", "value": "C26383"},
                          {"label": "Veauche", "value": "C26384"},
                          {"label": "Veigy", "value": "C26385"},
                          {"label": "Vendat", "value": "C26386"},
                          {"label": "Venissieux", "value": "C26387"},
                          {"label": "Vergongheon", "value": "C26388"},
                          {"label": "Vernaison", "value": "C26389"},
                          {"label": "Vernioz", "value": "C26390"},
                          {"label": "Vernosc-les-Annonay", "value": "C26391"},
                          {"label": "Vernoux-en-Vivarais", "value": "C26392"},
                          {"label": "Versonnex", "value": "C26393"},
                          {"label": "Vertaizon", "value": "C26394"},
                          {"label": "Vesseaux", "value": "C26395"},
                          {"label": "Vetraz-Monthoux", "value": "C26396"},
                          {"label": "Veurey-Voroize", "value": "C26397"},
                          {"label": "Veyras", "value": "C26398"},
                          {"label": "Veyre-Monton", "value": "C26399"},
                          {"label": "Veyrier-du-Lac", "value": "C26400"},
                          {"label": "Vic-le-Comte", "value": "C26401"},
                          {"label": "Vic-sur-Cere", "value": "C26402"},
                          {"label": "Vichy", "value": "C26403"},
                          {"label": "Vieille-Brioude", "value": "C26404"},
                          {"label": "Vienne", "value": "C26405"},
                          {"label": "Vif", "value": "C26406"},
                          {"label": "Villard-Bonnot", "value": "C26407"},
                          {"label": "Villard-de-Lans", "value": "C26408"},
                          {"label": "Villargondran", "value": "C26409"},
                          {"label": "Villars", "value": "C26410"},
                          {"label": "Villars-les-Dombes", "value": "C26411"},
                          {"label": "Villaz", "value": "C26412"},
                          {"label": "Ville-la-Grand", "value": "C26413"},
                          {"label": "Ville-sous-Anjou", "value": "C26414"},
                          {"label": "Villebois", "value": "C26415"},
                          {"label": "Villebret", "value": "C26416"},
                          {"label": "Villefontaine", "value": "C26417"},
                          {"label": "Villefranche-dAllier", "value": "C26418"},
                          {"label": "Villefranche-sur-Saone",
                           "value": "C26419"},
                          {"label": "Villemoirieu", "value": "C26420"},
                          {"label": "Villeneuve", "value": "C26421"},
                          {"label": "Villeneuve-de-Berg", "value": "C26422"},
                          {"label": "Villerest", "value": "C26423"},
                          {"label": "Villereversure", "value": "C26424"},
                          {"label": "Villette-de-Vienne", "value": "C26425"},
                          {"label": "Villeurbanne", "value": "C26426"},
                          {"label": "Villevocance", "value": "C26427"},
                          {"label": "Villie-Morgon", "value": "C26428"},
                          {"label": "Vimines", "value": "C26429"},
                          {"label": "Vinay", "value": "C26430"},
                          {"label": "Vinsobres", "value": "C26431"},
                          {"label": "Violay", "value": "C26432"},
                          {"label": "Viriat", "value": "C26433"},
                          {"label": "Virieu", "value": "C26434"},
                          {"label": "Virieu-le-Grand", "value": "C26435"},
                          {"label": "Viriville", "value": "C26436"},
                          {"label": "Viry", "value": "C26437"},
                          {"label": "Viuz-en-Sallaz", "value": "C26438"},
                          {"label": "Viuz-la-Chiesaz", "value": "C26439"},
                          {"label": "Viviers", "value": "C26440"},
                          {"label": "Viviers-du-Lac", "value": "C26441"},
                          {"label": "Vizille", "value": "C26442"},
                          {"label": "Voglans", "value": "C26443"},
                          {"label": "Voiron", "value": "C26444"},
                          {"label": "Volvic", "value": "C26445"},
                          {"label": "Vonnas", "value": "C26446"},
                          {"label": "Voreppe", "value": "C26447"},
                          {"label": "Vorey", "value": "C26448"},
                          {"label": "Vougy", "value": "C26449"},
                          {"label": "Vourey", "value": "C26450"},
                          {"label": "Vourles", "value": "C26451"},
                          {"label": "Ydes", "value": "C26452"},
                          {"label": "Yenne", "value": "C26453"},
                          {"label": "Youx", "value": "C26454"},
                          {"label": "Yssingeaux", "value": "C26455"},
                          {"label": "Ytrac", "value": "C26456"},
                          {"label": "Yzeure", "value": "C26457"}],
 "Aveiro": [{"label": "Aguada de Cima", "value": "C90807"},
            {"label": "agueda", "value": "C90808"},
            {"label": "Albergaria-a-Velha", "value": "C90809"},
            {"label": "Anadia", "value": "C90810"},
            {"label": "Aradas", "value": "C90811"},
            {"label": "Arcos", "value": "C90812"},
            {"label": "Argoncilhe", "value": "C90813"},
            {"label": "Arouca", "value": "C90814"},
            {"label": "Arrifana", "value": "C90815"},
            {"label": "Avanca", "value": "C90816"},
            {"label": "Aveiro", "value": "C90817"},
            {"label": "Beduido", "value": "C90818"},
            {"label": "Branca", "value": "C90819"},
            {"label": "Castelo de Paiva", "value": "C90820"},
            {"label": "Cortegaca", "value": "C90821"},
            {"label": "Cucujaes", "value": "C90822"},
            {"label": "Eixo", "value": "C90823"},
            {"label": "Esgueira", "value": "C90824"},
            {"label": "Esmoriz", "value": "C90825"},
            {"label": "Espargo", "value": "C90826"},
            {"label": "Espinho", "value": "C90827"},
            {"label": "Estarreja", "value": "C90828"},
            {"label": "Feira", "value": "C90829"},
            {"label": "Fiaes", "value": "C90830"},
            {"label": "Gafanha da Encarnacao", "value": "C90831"},
            {"label": "Gafanha da Nazare", "value": "C90832"},
            {"label": "ilhavo", "value": "C90833"},
            {"label": "Lamas", "value": "C90834"},
            {"label": "Lobao", "value": "C90835"},
            {"label": "Lourosa", "value": "C90836"},
            {"label": "Luso", "value": "C90837"},
            {"label": "Macieira de Cambra", "value": "C90838"},
            {"label": "Mealhada", "value": "C90839"},
            {"label": "Milheiros de Poiares", "value": "C90840"},
            {"label": "Mosteiro", "value": "C90841"},
            {"label": "Murtosa", "value": "C90842"},
            {"label": "Nogueira da Regedoura", "value": "C90843"},
            {"label": "Oia", "value": "C90844"},
            {"label": "Oliveira de Azemeis", "value": "C90845"},
            {"label": "Oliveira do Bairro", "value": "C90846"},
            {"label": "Oliveirinha", "value": "C90847"},
            {"label": "Ovar", "value": "C90848"},
            {"label": "Pacos de Brandao", "value": "C90849"},
            {"label": "Pampilhosa do Botao", "value": "C90850"},
            {"label": "Pardilho", "value": "C90851"},
            {"label": "Rio Meao", "value": "C90852"},
            {"label": "Salreu", "value": "C90853"},
            {"label": "Sanfins", "value": "C90854"},
            {"label": "Sangalhos", "value": "C90855"},
            {"label": "Santa Maria da Feira", "value": "C90856"},
            {"label": "Sao Joao", "value": "C90857"},
            {"label": "Sao Joao da Madeira", "value": "C90858"},
            {"label": "Sao Joao de Ver", "value": "C90859"},
            {"label": "Sao Roque", "value": "C90860"},
            {"label": "Sever do Vouga", "value": "C90861"},
            {"label": "Silvalde", "value": "C90862"},
            {"label": "Souto", "value": "C90863"},
            {"label": "Vagos", "value": "C90864"},
            {"label": "Vale de Cambra", "value": "C90865"},
            {"label": "Valega", "value": "C90866"},
            {"label": "Valongo", "value": "C90867"},
            {"label": "Vila Cha", "value": "C90868"}],
 "Ayacucho": [{"label": "Ayacucho", "value": "C80704"},
              {"label": "Ayna", "value": "C80705"},
              {"label": "Coracora", "value": "C80706"},
              {"label": "Huanta", "value": "C80707"},
              {"label": "Paucar Del Sara Sara", "value": "C80708"},
              {"label": "Provincia de Cangallo", "value": "C80709"},
              {"label": "Provincia de Huamanga", "value": "C80710"},
              {"label": "Provincia de Huanca Sancos", "value": "C80711"},
              {"label": "Provincia de Huanta", "value": "C80712"},
              {"label": "Provincia de La Mar", "value": "C80713"},
              {"label": "Provincia de Lucanas", "value": "C80714"},
              {"label": "Provincia de Parinacochas", "value": "C80715"},
              {"label": "Provincia de Sucre", "value": "C80716"},
              {"label": "Provincia de Victor Fajardo", "value": "C80717"},
              {"label": "Provincia de Vilcas Huaman", "value": "C80718"},
              {"label": "Puquio", "value": "C80719"},
              {"label": "San Miguel", "value": "C80720"},
              {"label": "Tambo", "value": "C80721"}],
 "Aydin": [{"label": "Acarlar", "value": "C119971"},
           {"label": "Atca", "value": "C119972"},
           {"label": "Aydin", "value": "C119973"},
           {"label": "Bozdogan", "value": "C119974"},
           {"label": "Buharkent", "value": "C119975"},
           {"label": "Cine", "value": "C119976"},
           {"label": "Dalama", "value": "C119977"},
           {"label": "Davutlar", "value": "C119978"},
           {"label": "Didim", "value": "C119979"},
           {"label": "Efeler", "value": "C119980"},
           {"label": "Germencik", "value": "C119981"},
           {"label": "Incirliova", "value": "C119982"},
           {"label": "Isabeyli", "value": "C119983"},
           {"label": "Karacasu", "value": "C119984"},
           {"label": "Karpuzlu", "value": "C119985"},
           {"label": "Kocarli", "value": "C119986"},
           {"label": "Kosk", "value": "C119987"},
           {"label": "Kusadasi", "value": "C119988"},
           {"label": "Kuyucak", "value": "C119989"},
           {"label": "Nazilli", "value": "C119990"},
           {"label": "Sarikemer", "value": "C119991"},
           {"label": "Soke", "value": "C119992"},
           {"label": "Sultanhisar", "value": "C119993"},
           {"label": "Tepecik", "value": "C119994"},
           {"label": "Yenipazar", "value": "C119995"}],
 "Ayeyarwady Region": [{"label": "Bogale", "value": "C75890"},
                       {"label": "Hinthada", "value": "C75891"},
                       {"label": "Kyaiklat", "value": "C75892"},
                       {"label": "Maubin", "value": "C75893"},
                       {"label": "Mawlamyinegyunn", "value": "C75894"},
                       {"label": "Myanaung", "value": "C75895"},
                       {"label": "Nyaungdon", "value": "C75896"},
                       {"label": "Pathein", "value": "C75897"},
                       {"label": "Pyapon", "value": "C75898"},
                       {"label": "Wakema", "value": "C75899"}],
 "Azad Kashmir": [{"label": "Bhimbar", "value": "C79312"},
                  {"label": "Kotli", "value": "C79313"},
                  {"label": "Kotli District", "value": "C79314"},
                  {"label": "Mirpur District", "value": "C79315"},
                  {"label": "Muzaffarabad", "value": "C79316"},
                  {"label": "New Mirpur", "value": "C79317"},
                  {"label": "Rawala Kot", "value": "C79318"}],
 "Azua Province": [{"label": "Azua", "value": "C23835"},
                   {"label": "El Guayabal", "value": "C23836"},
                   {"label": "Estebania", "value": "C23837"},
                   {"label": "Las Charcas", "value": "C23838"},
                   {"label": "Padre Las Casas", "value": "C23839"},
                   {"label": "Palmar de Ocoa", "value": "C23840"},
                   {"label": "Peralta", "value": "C23841"},
                   {"label": "Pueblo Viejo", "value": "C23842"},
                   {"label": "Sabana Yegua", "value": "C23843"},
                   {"label": "Tabara Arriba", "value": "C23844"},
                   {"label": "Villarpando", "value": "C23845"},
                   {"label": "Yayas de Viajama", "value": "C23846"}],
 "Azuay": [{"label": "Canton San Fernando", "value": "C24092"},
           {"label": "Cuenca", "value": "C24093"},
           {"label": "Gualaceo", "value": "C24094"},
           {"label": "La Union", "value": "C24095"},
           {"label": "Llacao", "value": "C24096"},
           {"label": "Nulti", "value": "C24097"}],
 "Ba Ria-Vung Tau": [{"label": "Con Son", "value": "C147266"},
                     {"label": "Huyen Chau Duc", "value": "C147267"},
                     {"label": "Huyen Dat Do", "value": "C147268"},
                     {"label": "Huyen Tan Thanh", "value": "C147269"},
                     {"label": "Huyen Xuyen Moc", "value": "C147270"},
                     {"label": "Thanh Pho Vung Tau", "value": "C147271"},
                     {"label": "Thi Xa Phu My", "value": "C147272"},
                     {"label": "Vung Tau", "value": "C147273"}],
 "Baalbek-Hermel Governorate": [{"label": "Baalbek", "value": "C64965"},
                                {"label": "Caza de Baalbek",
                                 "value": "C64966"}],
 "Babite Municipality": [{"label": "Pinki", "value": "C64849"}],
 "Babylon Governorate": [{"label": "Al Hillah", "value": "C51149"},
                         {"label": "Al Musayyib", "value": "C51150"},
                         {"label": "Imam Qasim", "value": "C51151"},
                         {"label": "Nahiyat Saddat al Hindiyah",
                          "value": "C51152"}],
 "Bac Giang": [{"label": "Bac Giang", "value": "C147274"},
               {"label": "Huyen Luc Nam", "value": "C147275"},
               {"label": "Huyen Luc Ngan", "value": "C147276"},
               {"label": "Huyen Yen The", "value": "C147277"}],
 "Bac Kan": [{"label": "Bac Kan", "value": "C147278"},
             {"label": "Huyen Ba Be", "value": "C147279"},
             {"label": "Huyen Bach Thong", "value": "C147280"},
             {"label": "Huyen Cho Don", "value": "C147281"},
             {"label": "Huyen Na Ri", "value": "C147282"},
             {"label": "Huyen Ngan Son", "value": "C147283"},
             {"label": "Thi Xa Bac Kan", "value": "C147284"}],
 "Bac Lieu": [{"label": "Bac Lieu", "value": "C147285"},
              {"label": "Huyen Gia Rai", "value": "C147286"},
              {"label": "Huyen Hong Dan", "value": "C147287"}],
 "Bac Ninh": [{"label": "Bac Ninh", "value": "C147288"},
              {"label": "Cung Kiem", "value": "C147289"},
              {"label": "Huyen Gia Binh", "value": "C147290"},
              {"label": "Huyen Thuan Thanh", "value": "C147291"},
              {"label": "Huyen Tien Du", "value": "C147292"},
              {"label": "Huyen Yen Phong", "value": "C147293"}],
 "Bacau County": [{"label": "Agas", "value": "C92713"},
                  {"label": "Apa Asau", "value": "C92714"},
                  {"label": "Ardeoani", "value": "C92715"},
                  {"label": "Arini", "value": "C92716"},
                  {"label": "Asau", "value": "C92717"},
                  {"label": "Bacau", "value": "C92718"},
                  {"label": "Bacioiu", "value": "C92719"},
                  {"label": "Balcani", "value": "C92720"},
                  {"label": "Barati", "value": "C92721"},
                  {"label": "Barsanesti", "value": "C92722"},
                  {"label": "Beresti", "value": "C92723"},
                  {"label": "Beresti-Bistrita", "value": "C92724"},
                  {"label": "Beresti-Tazlau", "value": "C92725"},
                  {"label": "Berzunti", "value": "C92726"},
                  {"label": "Bijghir", "value": "C92727"},
                  {"label": "Blagesti", "value": "C92728"},
                  {"label": "Bogdanesti", "value": "C92729"},
                  {"label": "Bolatau", "value": "C92730"},
                  {"label": "Bolovanis", "value": "C92731"},
                  {"label": "Bratesti", "value": "C92732"},
                  {"label": "Bratila", "value": "C92733"},
                  {"label": "Brusturoasa", "value": "C92734"},
                  {"label": "Buciumi", "value": "C92735"},
                  {"label": "Bucsesti", "value": "C92736"},
                  {"label": "Buda", "value": "C92737"},
                  {"label": "Buhoci", "value": "C92738"},
                  {"label": "Buhusi", "value": "C92739"},
                  {"label": "Caiuti", "value": "C92740"},
                  {"label": "Caiuti-Sat", "value": "C92741"},
                  {"label": "Caraclau", "value": "C92742"},
                  {"label": "Carligi", "value": "C92743"},
                  {"label": "Casin", "value": "C92744"},
                  {"label": "Cerdac", "value": "C92745"},
                  {"label": "Cernu", "value": "C92746"},
                  {"label": "Ciresoaia", "value": "C92747"},
                  {"label": "Ciughes", "value": "C92748"},
                  {"label": "Cleja", "value": "C92749"},
                  {"label": "Colonesti", "value": "C92750"},
                  {"label": "Comanesti", "value": "C92751"},
                  {"label": "Comuna Agas", "value": "C92752"},
                  {"label": "Comuna Ardeoani", "value": "C92753"},
                  {"label": "Comuna Asau", "value": "C92754"},
                  {"label": "Comuna Balcani", "value": "C92755"},
                  {"label": "Comuna Barsanesti", "value": "C92756"},
                  {"label": "Comuna Beresti-Bistrita", "value": "C92757"},
                  {"label": "Comuna Beresti-Tazlau", "value": "C92758"},
                  {"label": "Comuna Berzunti", "value": "C92759"},
                  {"label": "Comuna Blagesti", "value": "C92760"},
                  {"label": "Comuna Bogdanesti", "value": "C92761"},
                  {"label": "Comuna Brusturoasa", "value": "C92762"},
                  {"label": "Comuna Buciumi", "value": "C92763"},
                  {"label": "Comuna Buhoci", "value": "C92764"},
                  {"label": "Comuna Caiuti", "value": "C92765"},
                  {"label": "Comuna Cleja", "value": "C92766"},
                  {"label": "Comuna Colonesti", "value": "C92767"},
                  {"label": "Comuna Corbasca", "value": "C92768"},
                  {"label": "Comuna Cotofanesti", "value": "C92769"},
                  {"label": "Comuna Damienesti", "value": "C92770"},
                  {"label": "Comuna Dealu Morii", "value": "C92771"},
                  {"label": "Comuna Dofteana", "value": "C92772"},
                  {"label": "Comuna Faraoani", "value": "C92773"},
                  {"label": "Comuna Filipeni", "value": "C92774"},
                  {"label": "Comuna Filipesti", "value": "C92775"},
                  {"label": "Comuna Gaiceana", "value": "C92776"},
                  {"label": "Comuna Garleni", "value": "C92777"},
                  {"label": "Comuna Ghimes-Faget", "value": "C92778"},
                  {"label": "Comuna Gioseni", "value": "C92779"},
                  {"label": "Comuna Glavanesti", "value": "C92780"},
                  {"label": "Comuna Gura Vaii", "value": "C92781"},
                  {"label": "Comuna Helegiu", "value": "C92782"},
                  {"label": "Comuna Hemeiusi", "value": "C92783"},
                  {"label": "Comuna Horgesti", "value": "C92784"},
                  {"label": "Comuna Huruiesti", "value": "C92785"},
                  {"label": "Comuna Itesti", "value": "C92786"},
                  {"label": "Comuna Izvoru Berheciului", "value": "C92787"},
                  {"label": "Comuna Letea Veche", "value": "C92788"},
                  {"label": "Comuna Lipova", "value": "C92789"},
                  {"label": "Comuna Livezi", "value": "C92790"},
                  {"label": "Comuna Luizi-Calugara", "value": "C92791"},
                  {"label": "Comuna Magiresti", "value": "C92792"},
                  {"label": "Comuna Magura", "value": "C92793"},
                  {"label": "Comuna Manastirea Casin", "value": "C92794"},
                  {"label": "Comuna Margineni", "value": "C92795"},
                  {"label": "Comuna Motoseni", "value": "C92796"},
                  {"label": "Comuna Negri", "value": "C92797"},
                  {"label": "Comuna Nicolae Balcescu", "value": "C92798"},
                  {"label": "Comuna Odobesti", "value": "C92799"},
                  {"label": "Comuna Oituz", "value": "C92800"},
                  {"label": "Comuna Oncesti", "value": "C92801"},
                  {"label": "Comuna Orbeni", "value": "C92802"},
                  {"label": "Comuna Palanca", "value": "C92803"},
                  {"label": "Comuna Pancesti", "value": "C92804"},
                  {"label": "Comuna Parava", "value": "C92805"},
                  {"label": "Comuna Pargaresti", "value": "C92806"},
                  {"label": "Comuna Parincea", "value": "C92807"},
                  {"label": "Comuna Parjol", "value": "C92808"},
                  {"label": "Comuna Plopana", "value": "C92809"},
                  {"label": "Comuna Podu Turcului", "value": "C92810"},
                  {"label": "Comuna Poduri", "value": "C92811"},
                  {"label": "Comuna Prajesti", "value": "C92812"},
                  {"label": "Comuna Racaciuni", "value": "C92813"},
                  {"label": "Comuna Rachitoasa", "value": "C92814"},
                  {"label": "Comuna Racova", "value": "C92815"},
                  {"label": "Comuna Rosiori", "value": "C92816"},
                  {"label": "Comuna Sanduleni", "value": "C92817"},
                  {"label": "Comuna Sarata", "value": "C92818"},
                  {"label": "Comuna Sascut", "value": "C92819"},
                  {"label": "Comuna Saucesti", "value": "C92820"},
                  {"label": "Comuna Scorteni", "value": "C92821"},
                  {"label": "Comuna Secuieni", "value": "C92822"},
                  {"label": "Comuna Solont", "value": "C92823"},
                  {"label": "Comuna Stanisesti", "value": "C92824"},
                  {"label": "Comuna Stefan Cel Mare", "value": "C92825"},
                  {"label": "Comuna Strugari", "value": "C92826"},
                  {"label": "Comuna Tamasi", "value": "C92827"},
                  {"label": "Comuna Targu Trotus", "value": "C92828"},
                  {"label": "Comuna Tatarasti", "value": "C92829"},
                  {"label": "Comuna Traian", "value": "C92830"},
                  {"label": "Comuna Ungureni", "value": "C92831"},
                  {"label": "Comuna Urechesti", "value": "C92832"},
                  {"label": "Comuna Valea Seaca", "value": "C92833"},
                  {"label": "Comuna Vultureni", "value": "C92834"},
                  {"label": "Comuna Zemes", "value": "C92835"},
                  {"label": "Corbasca", "value": "C92836"},
                  {"label": "Cotofanesti", "value": "C92837"},
                  {"label": "Cotumba", "value": "C92838"},
                  {"label": "Cucuieti", "value": "C92839"},
                  {"label": "Damienesti", "value": "C92840"},
                  {"label": "Damoc", "value": "C92841"},
                  {"label": "Darmanesti", "value": "C92842"},
                  {"label": "Dealu Morii", "value": "C92843"},
                  {"label": "Deleni", "value": "C92844"},
                  {"label": "Dofteana", "value": "C92845"},
                  {"label": "Dragomir", "value": "C92846"},
                  {"label": "Dragugesti", "value": "C92847"},
                  {"label": "Dumbrava", "value": "C92848"},
                  {"label": "Faget", "value": "C92849"},
                  {"label": "Faraoani", "value": "C92850"},
                  {"label": "Ferestrau-Oituz", "value": "C92851"},
                  {"label": "Filipeni", "value": "C92852"},
                  {"label": "Filipesti", "value": "C92853"},
                  {"label": "Frumoasa", "value": "C92854"},
                  {"label": "Frumuselu", "value": "C92855"},
                  {"label": "Fundu Racaciuni", "value": "C92856"},
                  {"label": "Gaiceana", "value": "C92857"},
                  {"label": "Galbeni", "value": "C92858"},
                  {"label": "Garleni", "value": "C92859"},
                  {"label": "Garlenii de Sus", "value": "C92860"},
                  {"label": "Gazarie", "value": "C92861"},
                  {"label": "Gheorghe Doja", "value": "C92862"},
                  {"label": "Ghimes", "value": "C92863"},
                  {"label": "Ghimes-Faget", "value": "C92864"},
                  {"label": "Gioseni", "value": "C92865"},
                  {"label": "Glavanesti", "value": "C92866"},
                  {"label": "Gura Vaii", "value": "C92867"},
                  {"label": "Haghiac", "value": "C92868"},
                  {"label": "Helegiu", "value": "C92869"},
                  {"label": "Hemeius", "value": "C92870"},
                  {"label": "Holt", "value": "C92871"},
                  {"label": "Horgesti", "value": "C92872"},
                  {"label": "Huruiesti", "value": "C92873"},
                  {"label": "Itesti", "value": "C92874"},
                  {"label": "Izvoru Berheciului", "value": "C92875"},
                  {"label": "Lapos", "value": "C92876"},
                  {"label": "Larga", "value": "C92877"},
                  {"label": "Lespezi", "value": "C92878"},
                  {"label": "Letea Veche", "value": "C92879"},
                  {"label": "Lilieci", "value": "C92880"},
                  {"label": "Lipova", "value": "C92881"},
                  {"label": "Livezi", "value": "C92882"},
                  {"label": "Livezi-Vale", "value": "C92883"},
                  {"label": "Ludasi", "value": "C92884"},
                  {"label": "Luizi-Calugara", "value": "C92885"},
                  {"label": "Magiresti", "value": "C92886"},
                  {"label": "Magura", "value": "C92887"},
                  {"label": "Manastirea Casin", "value": "C92888"},
                  {"label": "Margineni", "value": "C92889"},
                  {"label": "Margineni-Munteni", "value": "C92890"},
                  {"label": "Moinesti", "value": "C92891"},
                  {"label": "Motoseni", "value": "C92892"},
                  {"label": "Municipiul Bacau", "value": "C92893"},
                  {"label": "Municipiul Moinesti", "value": "C92894"},
                  {"label": "Municipiul Onesti", "value": "C92895"},
                  {"label": "Negri", "value": "C92896"},
                  {"label": "Nicolae Balcescu", "value": "C92897"},
                  {"label": "Odobesti", "value": "C92898"},
                  {"label": "Oituz", "value": "C92899"},
                  {"label": "Oncesti", "value": "C92900"},
                  {"label": "Onesti", "value": "C92901"},
                  {"label": "Oras Buhusi", "value": "C92902"},
                  {"label": "Oras Comanesti", "value": "C92903"},
                  {"label": "Oras Darmanesti", "value": "C92904"},
                  {"label": "Oras Slanic-Moldova", "value": "C92905"},
                  {"label": "Oras Targu Ocna", "value": "C92906"},
                  {"label": "Orasa", "value": "C92907"},
                  {"label": "Orbeni", "value": "C92908"},
                  {"label": "Osebiti", "value": "C92909"},
                  {"label": "Palanca", "value": "C92910"},
                  {"label": "Pancesti", "value": "C92911"},
                  {"label": "Parava", "value": "C92912"},
                  {"label": "Pargaresti", "value": "C92913"},
                  {"label": "Parincea", "value": "C92914"},
                  {"label": "Parjol", "value": "C92915"},
                  {"label": "Plopana", "value": "C92916"},
                  {"label": "Plopu", "value": "C92917"},
                  {"label": "Podu Turcului", "value": "C92918"},
                  {"label": "Poduri", "value": "C92919"},
                  {"label": "Poiana", "value": "C92920"},
                  {"label": "Prajesti", "value": "C92921"},
                  {"label": "Prohozesti", "value": "C92922"},
                  {"label": "Pustiana", "value": "C92923"},
                  {"label": "Racaciuni", "value": "C92924"},
                  {"label": "Racauti", "value": "C92925"},
                  {"label": "Rachitoasa", "value": "C92926"},
                  {"label": "Racova", "value": "C92927"},
                  {"label": "Rosiori", "value": "C92928"},
                  {"label": "Sanduleni", "value": "C92929"},
                  {"label": "Sarata", "value": "C92930"},
                  {"label": "Sascut", "value": "C92931"},
                  {"label": "Sascut-Sat", "value": "C92932"},
                  {"label": "Satu Nou", "value": "C92933"},
                  {"label": "Saucesti", "value": "C92934"},
                  {"label": "Schitu Frumoasa", "value": "C92935"},
                  {"label": "Scorteni", "value": "C92936"},
                  {"label": "Scurta", "value": "C92937"},
                  {"label": "Secuieni", "value": "C92938"},
                  {"label": "Slanic-Moldova", "value": "C92939"},
                  {"label": "Slobozia", "value": "C92940"},
                  {"label": "Solont", "value": "C92941"},
                  {"label": "Somusca", "value": "C92942"},
                  {"label": "Stanisesti", "value": "C92943"},
                  {"label": "Stefan Cel Mare", "value": "C92944"},
                  {"label": "Stefan Voda", "value": "C92945"},
                  {"label": "Straja", "value": "C92946"},
                  {"label": "Strugari", "value": "C92947"},
                  {"label": "Tamasi", "value": "C92948"},
                  {"label": "Targu Ocna", "value": "C92949"},
                  {"label": "Targu Trotus", "value": "C92950"},
                  {"label": "Tatarasti", "value": "C92951"},
                  {"label": "Traian", "value": "C92952"},
                  {"label": "Turluianu", "value": "C92953"},
                  {"label": "Tuta", "value": "C92954"},
                  {"label": "Ungureni", "value": "C92955"},
                  {"label": "Urechesti", "value": "C92956"},
                  {"label": "Valcele", "value": "C92957"},
                  {"label": "Valea Arinilor", "value": "C92958"},
                  {"label": "Valea lui Ion", "value": "C92959"},
                  {"label": "Valea Seaca", "value": "C92960"},
                  {"label": "Valea Sosii", "value": "C92961"},
                  {"label": "Vermesti", "value": "C92962"},
                  {"label": "Versesti", "value": "C92963"},
                  {"label": "Viisoara", "value": "C92964"},
                  {"label": "Vultureni", "value": "C92965"},
                  {"label": "Zemes", "value": "C92966"}],
 "Bacs-Kiskun": [{"label": "agasegyhaza", "value": "C43748"},
                 {"label": "Akaszto", "value": "C43749"},
                 {"label": "Apostag", "value": "C43750"},
                 {"label": "Bacsalmas", "value": "C43751"},
                 {"label": "Bacsalmasi Jaras", "value": "C43752"},
                 {"label": "Bacsbokod", "value": "C43753"},
                 {"label": "Baja", "value": "C43754"},
                 {"label": "Bajai Jaras", "value": "C43755"},
                 {"label": "Balloszog", "value": "C43756"},
                 {"label": "Batya", "value": "C43757"},
                 {"label": "Bugac", "value": "C43758"},
                 {"label": "Csaszartoltes", "value": "C43759"},
                 {"label": "Csavoly", "value": "C43760"},
                 {"label": "Csengod", "value": "C43761"},
                 {"label": "Davod", "value": "C43762"},
                 {"label": "Dunapataj", "value": "C43763"},
                 {"label": "Dunavecse", "value": "C43764"},
                 {"label": "Dusnok", "value": "C43765"},
                 {"label": "ersekcsanad", "value": "C43766"},
                 {"label": "Felsoszentivan", "value": "C43767"},
                 {"label": "Fulopjakab", "value": "C43768"},
                 {"label": "Fulopszallas", "value": "C43769"},
                 {"label": "Gara", "value": "C43770"},
                 {"label": "Hajos", "value": "C43771"},
                 {"label": "Harta", "value": "C43772"},
                 {"label": "Helvecia", "value": "C43773"},
                 {"label": "Hercegszanto", "value": "C43774"},
                 {"label": "Izsak", "value": "C43775"},
                 {"label": "Janoshalma", "value": "C43776"},
                 {"label": "Janoshalmai Jaras", "value": "C43777"},
                 {"label": "Jaszszentlaszlo", "value": "C43778"},
                 {"label": "Kalocsa", "value": "C43779"},
                 {"label": "Kalocsai Jaras", "value": "C43780"},
                 {"label": "Katymar", "value": "C43781"},
                 {"label": "Kecel", "value": "C43782"},
                 {"label": "Kecskemet", "value": "C43783"},
                 {"label": "Kecskemeti Jaras", "value": "C43784"},
                 {"label": "Kelebia", "value": "C43785"},
                 {"label": "Kerekegyhaza", "value": "C43786"},
                 {"label": "Kiskoros", "value": "C43787"},
                 {"label": "Kiskorosi Jaras", "value": "C43788"},
                 {"label": "Kiskunfelegyhaza", "value": "C43789"},
                 {"label": "Kiskunfelegyhazi Jaras", "value": "C43790"},
                 {"label": "Kiskunhalas", "value": "C43791"},
                 {"label": "Kiskunhalasi Jaras", "value": "C43792"},
                 {"label": "Kiskunmajsa", "value": "C43793"},
                 {"label": "Kiskunmajsai Jaras", "value": "C43794"},
                 {"label": "Kisszallas", "value": "C43795"},
                 {"label": "Kunfeherto", "value": "C43796"},
                 {"label": "Kunszentmiklos", "value": "C43797"},
                 {"label": "Kunszentmiklosi Jaras", "value": "C43798"},
                 {"label": "Lajosmizse", "value": "C43799"},
                 {"label": "Lakitelek", "value": "C43800"},
                 {"label": "Madaras", "value": "C43801"},
                 {"label": "Melykut", "value": "C43802"},
                 {"label": "Nagybaracska", "value": "C43803"},
                 {"label": "Nemesnadudvar", "value": "C43804"},
                 {"label": "Nyarlorinc", "value": "C43805"},
                 {"label": "Orgovany", "value": "C43806"},
                 {"label": "Palmonostora", "value": "C43807"},
                 {"label": "Solt", "value": "C43808"},
                 {"label": "Soltvadkert", "value": "C43809"},
                 {"label": "Sukosd", "value": "C43810"},
                 {"label": "Szabadszallas", "value": "C43811"},
                 {"label": "Szalkszentmarton", "value": "C43812"},
                 {"label": "Szank", "value": "C43813"},
                 {"label": "Szentkiraly", "value": "C43814"},
                 {"label": "Tass", "value": "C43815"},
                 {"label": "Tazlar", "value": "C43816"},
                 {"label": "Tiszaalpar", "value": "C43817"},
                 {"label": "Tiszakecske", "value": "C43818"},
                 {"label": "Tiszakecskei Jaras", "value": "C43819"},
                 {"label": "Tompa", "value": "C43820"},
                 {"label": "Varosfold", "value": "C43821"},
                 {"label": "Vaskut", "value": "C43822"}],
 "Badakhshan": [{"label": "Ashkasham", "value": "C1"},
                {"label": "Fayzabad", "value": "C2"},
                {"label": "Jurm", "value": "C3"},
                {"label": "Khandud", "value": "C4"},
                {"label": "Raghistan", "value": "C5"},
                {"label": "Wakhan", "value": "C6"}],
 "Baden-Wurttemberg": [{"label": "Aach", "value": "C34365"},
                       {"label": "Aalen", "value": "C34366"},
                       {"label": "Abstatt", "value": "C34367"},
                       {"label": "Abtsgmund", "value": "C34368"},
                       {"label": "Achern", "value": "C34369"},
                       {"label": "Achstetten", "value": "C34370"},
                       {"label": "Adelberg", "value": "C34371"},
                       {"label": "Adelmannsfelden", "value": "C34372"},
                       {"label": "Adelsheim", "value": "C34373"},
                       {"label": "Affalterbach", "value": "C34374"},
                       {"label": "Aglasterhausen", "value": "C34375"},
                       {"label": "Aichelberg", "value": "C34376"},
                       {"label": "Aichhalden", "value": "C34377"},
                       {"label": "Aichstetten", "value": "C34378"},
                       {"label": "Aidlingen", "value": "C34379"},
                       {"label": "Aitrach", "value": "C34380"},
                       {"label": "Albbruck", "value": "C34381"},
                       {"label": "Albershausen", "value": "C34382"},
                       {"label": "Albstadt", "value": "C34383"},
                       {"label": "Aldingen", "value": "C34384"},
                       {"label": "Alfdorf", "value": "C34385"},
                       {"label": "Allensbach", "value": "C34386"},
                       {"label": "Allmendingen", "value": "C34387"},
                       {"label": "Allmersbach im Tal", "value": "C34388"},
                       {"label": "Alpirsbach", "value": "C34389"},
                       {"label": "Altbach", "value": "C34390"},
                       {"label": "Altdorf", "value": "C34391"},
                       {"label": "Altenriet", "value": "C34392"},
                       {"label": "Altensteig", "value": "C34393"},
                       {"label": "Altes Lager", "value": "C34394"},
                       {"label": "Althengstett", "value": "C34395"},
                       {"label": "Althutte", "value": "C34396"},
                       {"label": "Altlussheim", "value": "C34397"},
                       {"label": "Altshausen", "value": "C34398"},
                       {"label": "Amstetten", "value": "C34399"},
                       {"label": "Amtzell", "value": "C34400"},
                       {"label": "Aspach", "value": "C34401"},
                       {"label": "Asperg", "value": "C34402"},
                       {"label": "Assamstadt", "value": "C34403"},
                       {"label": "Asselfingen", "value": "C34404"},
                       {"label": "Attenweiler", "value": "C34405"},
                       {"label": "Au", "value": "C34406"},
                       {"label": "Au am Rhein", "value": "C34407"},
                       {"label": "Auggen", "value": "C34408"},
                       {"label": "Aulendorf", "value": "C34409"},
                       {"label": "Backnang", "value": "C34410"},
                       {"label": "Bad Bellingen", "value": "C34411"},
                       {"label": "Bad Buchau", "value": "C34412"},
                       {"label": "Bad Ditzenbach", "value": "C34413"},
                       {"label": "Bad Durrheim", "value": "C34414"},
                       {"label": "Bad Herrenalb", "value": "C34415"},
                       {"label": "Bad Liebenzell", "value": "C34416"},
                       {"label": "Bad Mergentheim", "value": "C34417"},
                       {"label": "Bad Peterstal-Griesbach", "value": "C34418"},
                       {"label": "Bad Rappenau", "value": "C34419"},
                       {"label": "Bad Rippoldsau-Schapbach", "value": "C34420"},
                       {"label": "Bad Sackingen", "value": "C34421"},
                       {"label": "Bad Schussenried", "value": "C34422"},
                       {"label": "Bad Teinach-Zavelstein", "value": "C34423"},
                       {"label": "Bad uberkingen", "value": "C34424"},
                       {"label": "Bad Urach", "value": "C34425"},
                       {"label": "Bad Waldsee", "value": "C34426"},
                       {"label": "Bad Wildbad", "value": "C34427"},
                       {"label": "Bad Wimpfen", "value": "C34428"},
                       {"label": "Bad Wurzach", "value": "C34429"},
                       {"label": "Baden-Baden", "value": "C34430"},
                       {"label": "Badenweiler", "value": "C34431"},
                       {"label": "Bahlingen", "value": "C34432"},
                       {"label": "Baienfurt", "value": "C34433"},
                       {"label": "Baiersbronn", "value": "C34434"},
                       {"label": "Baindt", "value": "C34435"},
                       {"label": "Balingen", "value": "C34436"},
                       {"label": "Baltmannsweiler", "value": "C34437"},
                       {"label": "Balzfeld", "value": "C34438"},
                       {"label": "Bammental", "value": "C34439"},
                       {"label": "Bartholoma", "value": "C34440"},
                       {"label": "Beilstein", "value": "C34441"},
                       {"label": "Beimerstetten", "value": "C34442"},
                       {"label": "Bempflingen", "value": "C34443"},
                       {"label": "Benningen am Neckar", "value": "C34444"},
                       {"label": "Bergatreute", "value": "C34445"},
                       {"label": "Berghaupten", "value": "C34446"},
                       {"label": "Berghulen", "value": "C34447"},
                       {"label": "Berkheim", "value": "C34448"},
                       {"label": "Bermatingen", "value": "C34449"},
                       {"label": "Bernau", "value": "C34450"},
                       {"label": "Bernstadt", "value": "C34451"},
                       {"label": "Besigheim", "value": "C34452"},
                       {"label": "Beuren", "value": "C34453"},
                       {"label": "Biberach", "value": "C34454"},
                       {"label": "Biberach an der Riss", "value": "C34455"},
                       {"label": "Bietigheim", "value": "C34456"},
                       {"label": "Bietigheim-Bissingen", "value": "C34457"},
                       {"label": "Billigheim", "value": "C34458"},
                       {"label": "Binau", "value": "C34459"},
                       {"label": "Bingen", "value": "C34460"},
                       {"label": "Binzen", "value": "C34461"},
                       {"label": "Birenbach", "value": "C34462"},
                       {"label": "Birkenfeld", "value": "C34463"},
                       {"label": "Bischweier", "value": "C34464"},
                       {"label": "Bisingen", "value": "C34465"},
                       {"label": "Bissingen an der Teck", "value": "C34466"},
                       {"label": "Bitz", "value": "C34467"},
                       {"label": "Blaubeuren", "value": "C34468"},
                       {"label": "Blaufelden", "value": "C34469"},
                       {"label": "Blumberg", "value": "C34470"},
                       {"label": "Bobingen an der Rems", "value": "C34471"},
                       {"label": "Boblingen", "value": "C34472"},
                       {"label": "Bodelshausen", "value": "C34473"},
                       {"label": "Bodman-Ludwigshafen", "value": "C34474"},
                       {"label": "Bodnegg", "value": "C34475"},
                       {"label": "Bohmenkirch", "value": "C34476"},
                       {"label": "Bolheim", "value": "C34477"},
                       {"label": "Boll", "value": "C34478"},
                       {"label": "Bollschweil", "value": "C34479"},
                       {"label": "Bondorf", "value": "C34480"},
                       {"label": "Bonndorf", "value": "C34481"},
                       {"label": "Bonnigheim", "value": "C34482"},
                       {"label": "Bopfingen", "value": "C34483"},
                       {"label": "Bortlingen", "value": "C34484"},
                       {"label": "Bosingen", "value": "C34485"},
                       {"label": "Bottingen", "value": "C34486"},
                       {"label": "Botzingen", "value": "C34487"},
                       {"label": "Boxberg", "value": "C34488"},
                       {"label": "Brackenheim", "value": "C34489"},
                       {"label": "Braunlingen", "value": "C34490"},
                       {"label": "Braunsbach", "value": "C34491"},
                       {"label": "Breisach am Rhein", "value": "C34492"},
                       {"label": "Breitnau", "value": "C34493"},
                       {"label": "Bretten", "value": "C34494"},
                       {"label": "Bretzfeld", "value": "C34495"},
                       {"label": "Bruchsal", "value": "C34496"},
                       {"label": "Bruhl", "value": "C34497"},
                       {"label": "Bubsheim", "value": "C34498"},
                       {"label": "Buch am Ahorn", "value": "C34499"},
                       {"label": "Buchen in Odenwald", "value": "C34500"},
                       {"label": "Buchenbach", "value": "C34501"},
                       {"label": "Buggingen", "value": "C34502"},
                       {"label": "Buhl", "value": "C34503"},
                       {"label": "Buhlertal", "value": "C34504"},
                       {"label": "Buhlertann", "value": "C34505"},
                       {"label": "Buhlerzell", "value": "C34506"},
                       {"label": "Burgrieden", "value": "C34507"},
                       {"label": "Burgstetten", "value": "C34508"},
                       {"label": "Burladingen", "value": "C34509"},
                       {"label": "Busingen", "value": "C34510"},
                       {"label": "Calw", "value": "C34511"},
                       {"label": "Cleebronn", "value": "C34512"},
                       {"label": "Crailsheim", "value": "C34513"},
                       {"label": "Creglingen", "value": "C34514"},
                       {"label": "Daisendorf", "value": "C34515"},
                       {"label": "Dauchingen", "value": "C34516"},
                       {"label": "Deckenpfronn", "value": "C34517"},
                       {"label": "Deggingen", "value": "C34518"},
                       {"label": "Deilingen", "value": "C34519"},
                       {"label": "Deisslingen", "value": "C34520"},
                       {"label": "Deizisau", "value": "C34521"},
                       {"label": "Denkendorf", "value": "C34522"},
                       {"label": "Denkingen", "value": "C34523"},
                       {"label": "Denzlingen", "value": "C34524"},
                       {"label": "Dettenhausen", "value": "C34525"},
                       {"label": "Dettighofen", "value": "C34526"},
                       {"label": "Dettingen", "value": "C34527"},
                       {"label": "Dettingen an der Erms", "value": "C34528"},
                       {"label": "Dettingen unter Teck", "value": "C34529"},
                       {"label": "Dielheim", "value": "C34530"},
                       {"label": "Dietenheim", "value": "C34531"},
                       {"label": "Dietingen", "value": "C34532"},
                       {"label": "Dischingen", "value": "C34533"},
                       {"label": "Ditzingen", "value": "C34534"},
                       {"label": "Dobel", "value": "C34535"},
                       {"label": "Dogern", "value": "C34536"},
                       {"label": "Donaueschingen", "value": "C34537"},
                       {"label": "Donzdorf", "value": "C34538"},
                       {"label": "Dormettingen", "value": "C34539"},
                       {"label": "Dornhan", "value": "C34540"},
                       {"label": "Dornstadt", "value": "C34541"},
                       {"label": "Dornstetten", "value": "C34542"},
                       {"label": "Dorzbach", "value": "C34543"},
                       {"label": "Dossenheim", "value": "C34544"},
                       {"label": "Dotternhausen", "value": "C34545"},
                       {"label": "Dunningen", "value": "C34546"},
                       {"label": "Durbach", "value": "C34547"},
                       {"label": "Durbheim", "value": "C34548"},
                       {"label": "Durlangen", "value": "C34549"},
                       {"label": "Durmentingen", "value": "C34550"},
                       {"label": "Durmersheim", "value": "C34551"},
                       {"label": "Durnau", "value": "C34552"},
                       {"label": "Dusslingen", "value": "C34553"},
                       {"label": "Ebenweiler", "value": "C34554"},
                       {"label": "Eberbach", "value": "C34555"},
                       {"label": "Eberdingen", "value": "C34556"},
                       {"label": "Eberhardzell", "value": "C34557"},
                       {"label": "Ebersbach an der Fils", "value": "C34558"},
                       {"label": "Ebersbach-Musbach", "value": "C34559"},
                       {"label": "Eberstadt", "value": "C34560"},
                       {"label": "Ebhausen", "value": "C34561"},
                       {"label": "Ebringen", "value": "C34562"},
                       {"label": "Edingen-Neckarhausen", "value": "C34563"},
                       {"label": "Efringen-Kirchen", "value": "C34564"},
                       {"label": "Egenhausen", "value": "C34565"},
                       {"label": "Eggenstein-Leopoldshafen", "value": "C34566"},
                       {"label": "Eggingen", "value": "C34567"},
                       {"label": "Ehingen", "value": "C34568"},
                       {"label": "Ehningen", "value": "C34569"},
                       {"label": "Eichstetten", "value": "C34570"},
                       {"label": "Eigeltingen", "value": "C34571"},
                       {"label": "Eimeldingen", "value": "C34572"},
                       {"label": "Eisenbach", "value": "C34573"},
                       {"label": "Eisingen", "value": "C34574"},
                       {"label": "Eislingen", "value": "C34575"},
                       {"label": "Ellenberg", "value": "C34576"},
                       {"label": "Ellhofen", "value": "C34577"},
                       {"label": "Ellwangen", "value": "C34578"},
                       {"label": "Elzach", "value": "C34579"},
                       {"label": "Emmendingen", "value": "C34580"},
                       {"label": "Emmingen-Liptingen", "value": "C34581"},
                       {"label": "Empfingen", "value": "C34582"},
                       {"label": "Endingen", "value": "C34583"},
                       {"label": "Engelsbrand", "value": "C34584"},
                       {"label": "Engen", "value": "C34585"},
                       {"label": "Eningen unter Achalm", "value": "C34586"},
                       {"label": "Ennetach", "value": "C34587"},
                       {"label": "Enzklosterle", "value": "C34588"},
                       {"label": "Epfenbach", "value": "C34589"},
                       {"label": "Epfendorf", "value": "C34590"},
                       {"label": "Eppelheim", "value": "C34591"},
                       {"label": "Eppingen", "value": "C34592"},
                       {"label": "Erbach", "value": "C34593"},
                       {"label": "Erdmannhausen", "value": "C34594"},
                       {"label": "Eriskirch", "value": "C34595"},
                       {"label": "Erkenbrechtsweiler", "value": "C34596"},
                       {"label": "Erlenbach", "value": "C34597"},
                       {"label": "Erlenmoos", "value": "C34598"},
                       {"label": "Erligheim", "value": "C34599"},
                       {"label": "Erolzheim", "value": "C34600"},
                       {"label": "Ersingen", "value": "C34601"},
                       {"label": "Ertingen", "value": "C34602"},
                       {"label": "Eschach", "value": "C34603"},
                       {"label": "Eschelbronn", "value": "C34604"},
                       {"label": "Eschenbach", "value": "C34605"},
                       {"label": "Essingen", "value": "C34606"},
                       {"label": "Esslingen", "value": "C34607"},
                       {"label": "Ettenheim", "value": "C34608"},
                       {"label": "Ettlingen", "value": "C34609"},
                       {"label": "Eutingen an der Enz", "value": "C34610"},
                       {"label": "Fahrenbach", "value": "C34611"},
                       {"label": "Feldberg", "value": "C34612"},
                       {"label": "Fellbach", "value": "C34613"},
                       {"label": "Fichtenberg", "value": "C34614"},
                       {"label": "Filderstadt", "value": "C34615"},
                       {"label": "Fischerbach", "value": "C34616"},
                       {"label": "Flein", "value": "C34617"},
                       {"label": "Forbach", "value": "C34618"},
                       {"label": "Forchheim", "value": "C34619"},
                       {"label": "Forchtenberg", "value": "C34620"},
                       {"label": "Forst", "value": "C34621"},
                       {"label": "Freiberg am Neckar", "value": "C34622"},
                       {"label": "Freiburg", "value": "C34623"},
                       {"label": "Freiburg Region", "value": "C34624"},
                       {"label": "Freudenberg", "value": "C34625"},
                       {"label": "Freudenstadt", "value": "C34626"},
                       {"label": "Freudental", "value": "C34627"},
                       {"label": "Frickenhausen", "value": "C34628"},
                       {"label": "Frickingen", "value": "C34629"},
                       {"label": "Fridingen an der Donau", "value": "C34630"},
                       {"label": "Friedenweiler", "value": "C34631"},
                       {"label": "Friedrichshafen", "value": "C34632"},
                       {"label": "Friolzheim", "value": "C34633"},
                       {"label": "Frittlingen", "value": "C34634"},
                       {"label": "Fronreute", "value": "C34635"},
                       {"label": "Furstenberg", "value": "C34636"},
                       {"label": "Furtwangen", "value": "C34637"},
                       {"label": "Gaggenau", "value": "C34638"},
                       {"label": "Gaiberg", "value": "C34639"},
                       {"label": "Gaienhofen", "value": "C34640"},
                       {"label": "Gaildorf", "value": "C34641"},
                       {"label": "Gailingen", "value": "C34642"},
                       {"label": "Gammelshausen", "value": "C34643"},
                       {"label": "Gammertingen", "value": "C34644"},
                       {"label": "Gartringen", "value": "C34645"},
                       {"label": "Gechingen", "value": "C34646"},
                       {"label": "Geisingen", "value": "C34647"},
                       {"label": "Geislingen", "value": "C34648"},
                       {"label": "Geislingen an der Steige", "value": "C34649"},
                       {"label": "Gemmingen", "value": "C34650"},
                       {"label": "Gemmrigheim", "value": "C34651"},
                       {"label": "Gengenbach", "value": "C34652"},
                       {"label": "Gerabronn", "value": "C34653"},
                       {"label": "Gerlingen", "value": "C34654"},
                       {"label": "Gernsbach", "value": "C34655"},
                       {"label": "Gerstetten", "value": "C34656"},
                       {"label": "Giengen an der Brenz", "value": "C34657"},
                       {"label": "Gingen an der Fils", "value": "C34658"},
                       {"label": "Glatten", "value": "C34659"},
                       {"label": "Goggingen", "value": "C34660"},
                       {"label": "Gomadingen", "value": "C34661"},
                       {"label": "Gomaringen", "value": "C34662"},
                       {"label": "Gondelsheim", "value": "C34663"},
                       {"label": "Goppingen", "value": "C34664"},
                       {"label": "Gorwihl", "value": "C34665"},
                       {"label": "Gosheim", "value": "C34666"},
                       {"label": "Gottenheim", "value": "C34667"},
                       {"label": "Gottmadingen", "value": "C34668"},
                       {"label": "Graben-Neudorf", "value": "C34669"},
                       {"label": "Grabenstetten", "value": "C34670"},
                       {"label": "Grafenau", "value": "C34671"},
                       {"label": "Grafenhausen", "value": "C34672"},
                       {"label": "Greffern", "value": "C34673"},
                       {"label": "Grenzach-Wyhlen", "value": "C34674"},
                       {"label": "Grossbettlingen", "value": "C34675"},
                       {"label": "Grossbottwar", "value": "C34676"},
                       {"label": "Grosselfingen", "value": "C34677"},
                       {"label": "Grosserlach", "value": "C34678"},
                       {"label": "Grossrinderfeld", "value": "C34679"},
                       {"label": "Gruibingen", "value": "C34680"},
                       {"label": "Grunkraut", "value": "C34681"},
                       {"label": "Grunsfeld", "value": "C34682"},
                       {"label": "Gschwend", "value": "C34683"},
                       {"label": "Guglingen", "value": "C34684"},
                       {"label": "Gundelfingen", "value": "C34685"},
                       {"label": "Gundelsheim", "value": "C34686"},
                       {"label": "Gutach", "value": "C34687"},
                       {"label": "Gutach im Breisgau", "value": "C34688"},
                       {"label": "Gutenbach", "value": "C34689"},
                       {"label": "Gutenzell-Hurbel", "value": "C34690"},
                       {"label": "Hagnau", "value": "C34691"},
                       {"label": "Haigerloch", "value": "C34692"},
                       {"label": "Haiterbach", "value": "C34693"},
                       {"label": "Hambrucken", "value": "C34694"},
                       {"label": "Hardheim", "value": "C34695"},
                       {"label": "Hardt", "value": "C34696"},
                       {"label": "Hartheim", "value": "C34697"},
                       {"label": "Hasel", "value": "C34698"},
                       {"label": "Haslach", "value": "C34699"},
                       {"label": "Hassmersheim", "value": "C34700"},
                       {"label": "Hattenhofen", "value": "C34701"},
                       {"label": "Hausach", "value": "C34702"},
                       {"label": "Hausen", "value": "C34703"},
                       {"label": "Hausern", "value": "C34704"},
                       {"label": "Hayingen", "value": "C34705"},
                       {"label": "Hechingen", "value": "C34706"},
                       {"label": "Heddesheim", "value": "C34707"},
                       {"label": "Heidelberg", "value": "C34708"},
                       {"label": "Heidenheim an der Brenz", "value": "C34709"},
                       {"label": "Heilbronn", "value": "C34710"},
                       {"label": "Heiligenberg", "value": "C34711"},
                       {"label": "Heiligkreuzsteinach", "value": "C34712"},
                       {"label": "Heimsheim", "value": "C34713"},
                       {"label": "Heiningen", "value": "C34714"},
                       {"label": "Heitersheim", "value": "C34715"},
                       {"label": "Helmstadt-Bargen", "value": "C34716"},
                       {"label": "Hemmingen", "value": "C34717"},
                       {"label": "Hemsbach", "value": "C34718"},
                       {"label": "Herbertingen", "value": "C34719"},
                       {"label": "Herbolzheim", "value": "C34720"},
                       {"label": "Herbrechtingen", "value": "C34721"},
                       {"label": "Herdwangen-Schonach", "value": "C34722"},
                       {"label": "Hermaringen", "value": "C34723"},
                       {"label": "Herrenberg", "value": "C34724"},
                       {"label": "Herrischried", "value": "C34725"},
                       {"label": "Hessigheim", "value": "C34726"},
                       {"label": "Hettingen", "value": "C34727"},
                       {"label": "Heubach", "value": "C34728"},
                       {"label": "Heuchlingen", "value": "C34729"},
                       {"label": "Hildrizhausen", "value": "C34730"},
                       {"label": "Hilzingen", "value": "C34731"},
                       {"label": "Hinterzarten", "value": "C34732"},
                       {"label": "Hirrlingen", "value": "C34733"},
                       {"label": "Hirschberg an der Bergstrasse",
                        "value": "C34734"},
                       {"label": "Hochdorf", "value": "C34735"},
                       {"label": "Hochenschwand", "value": "C34736"},
                       {"label": "Hockenheim", "value": "C34737"},
                       {"label": "Hofen an der Enz", "value": "C34738"},
                       {"label": "Hofstetten", "value": "C34739"},
                       {"label": "Hohberg", "value": "C34740"},
                       {"label": "Hohentengen", "value": "C34741"},
                       {"label": "Holzgerlingen", "value": "C34742"},
                       {"label": "Holzmaden", "value": "C34743"},
                       {"label": "Hopfingen", "value": "C34744"},
                       {"label": "Horb am Neckar", "value": "C34745"},
                       {"label": "Horben", "value": "C34746"},
                       {"label": "Horgenzell", "value": "C34747"},
                       {"label": "Hornberg", "value": "C34748"},
                       {"label": "Horrenberg", "value": "C34749"},
                       {"label": "Huffenhardt", "value": "C34750"},
                       {"label": "Hufingen", "value": "C34751"},
                       {"label": "Hugelsheim", "value": "C34752"},
                       {"label": "Hulben", "value": "C34753"},
                       {"label": "Huttisheim", "value": "C34754"},
                       {"label": "Huttlingen", "value": "C34755"},
                       {"label": "Iffezheim", "value": "C34756"},
                       {"label": "Igersheim", "value": "C34757"},
                       {"label": "Iggingen", "value": "C34758"},
                       {"label": "Ihringen", "value": "C34759"},
                       {"label": "Illerrieden", "value": "C34760"},
                       {"label": "Illingen", "value": "C34761"},
                       {"label": "Illmensee", "value": "C34762"},
                       {"label": "Ilsfeld", "value": "C34763"},
                       {"label": "Ilshofen", "value": "C34764"},
                       {"label": "Ilvesheim", "value": "C34765"},
                       {"label": "Immendingen", "value": "C34766"},
                       {"label": "Immenstaad am Bodensee", "value": "C34767"},
                       {"label": "Ingelfingen", "value": "C34768"},
                       {"label": "Ingoldingen", "value": "C34769"},
                       {"label": "Inzigkofen", "value": "C34770"},
                       {"label": "Inzlingen", "value": "C34771"},
                       {"label": "Isny", "value": "C34772"},
                       {"label": "Ispringen", "value": "C34773"},
                       {"label": "Ittlingen", "value": "C34774"},
                       {"label": "Jagsthausen", "value": "C34775"},
                       {"label": "Jagstzell", "value": "C34776"},
                       {"label": "Jestetten", "value": "C34777"},
                       {"label": "Johlingen", "value": "C34778"},
                       {"label": "Jungingen", "value": "C34779"},
                       {"label": "Kaisersbach", "value": "C34780"},
                       {"label": "Kandern", "value": "C34781"},
                       {"label": "Kappel-Grafenhausen", "value": "C34782"},
                       {"label": "Kappelrodeck", "value": "C34783"},
                       {"label": "Karlsdorf-Neuthard", "value": "C34784"},
                       {"label": "Karlsruhe", "value": "C34785"},
                       {"label": "Karlsruhe Region", "value": "C34786"},
                       {"label": "Kehl", "value": "C34787"},
                       {"label": "Kenzingen", "value": "C34788"},
                       {"label": "Ketsch", "value": "C34789"},
                       {"label": "Kieselbronn", "value": "C34790"},
                       {"label": "Kippenheim", "value": "C34791"},
                       {"label": "Kirchardt", "value": "C34792"},
                       {"label": "Kirchberg an der Iller", "value": "C34793"},
                       {"label": "Kirchberg an der Jagst", "value": "C34794"},
                       {"label": "Kirchberg an der Murr", "value": "C34795"},
                       {"label": "Kirchdorf", "value": "C34796"},
                       {"label": "Kirchentellinsfurt", "value": "C34797"},
                       {"label": "Kirchheim am Neckar", "value": "C34798"},
                       {"label": "Kirchheim am Ries", "value": "C34799"},
                       {"label": "Kirchheim unter Teck", "value": "C34800"},
                       {"label": "Kirchzarten", "value": "C34801"},
                       {"label": "Kisslegg", "value": "C34802"},
                       {"label": "Klingenstein", "value": "C34803"},
                       {"label": "Knittlingen", "value": "C34804"},
                       {"label": "Kohlberg", "value": "C34805"},
                       {"label": "Kolbingen", "value": "C34806"},
                       {"label": "Kongen", "value": "C34807"},
                       {"label": "Konigheim", "value": "C34808"},
                       {"label": "Konigsbach-Stein", "value": "C34809"},
                       {"label": "Konigsbronn", "value": "C34810"},
                       {"label": "Konigsfeld im Schwarzwald",
                        "value": "C34811"},
                       {"label": "Konstanz", "value": "C34812"},
                       {"label": "Korb", "value": "C34813"},
                       {"label": "Korntal", "value": "C34814"},
                       {"label": "Kornwestheim", "value": "C34815"},
                       {"label": "Kraichtal", "value": "C34816"},
                       {"label": "Krauchenwies", "value": "C34817"},
                       {"label": "Krautheim", "value": "C34818"},
                       {"label": "Kressbronn am Bodensee", "value": "C34819"},
                       {"label": "Kronau", "value": "C34820"},
                       {"label": "Kuchen", "value": "C34821"},
                       {"label": "Kulsheim", "value": "C34822"},
                       {"label": "Kunzelsau", "value": "C34823"},
                       {"label": "Kupferzell", "value": "C34824"},
                       {"label": "Kuppenheim", "value": "C34825"},
                       {"label": "Kurnbach", "value": "C34826"},
                       {"label": "Kusterdingen", "value": "C34827"},
                       {"label": "Ladenburg", "value": "C34828"},
                       {"label": "Lahr", "value": "C34829"},
                       {"label": "Laichingen", "value": "C34830"},
                       {"label": "Langenargen", "value": "C34831"},
                       {"label": "Langenau", "value": "C34832"},
                       {"label": "Langenbrettach", "value": "C34833"},
                       {"label": "Langenburg", "value": "C34834"},
                       {"label": "Langenenslingen", "value": "C34835"},
                       {"label": "Lauchheim", "value": "C34836"},
                       {"label": "Lauchringen", "value": "C34837"},
                       {"label": "Lauda-Konigshofen", "value": "C34838"},
                       {"label": "Laudenbach", "value": "C34839"},
                       {"label": "Lauf", "value": "C34840"},
                       {"label": "Laufenburg", "value": "C34841"},
                       {"label": "Lauffen am Neckar", "value": "C34842"},
                       {"label": "Laupheim", "value": "C34843"},
                       {"label": "Lautenbach", "value": "C34844"},
                       {"label": "LauterbachSchwarzwald", "value": "C34845"},
                       {"label": "Lehrensteinsfeld", "value": "C34846"},
                       {"label": "Leibertingen", "value": "C34847"},
                       {"label": "Leimen", "value": "C34848"},
                       {"label": "Leinfelden-Echterdingen", "value": "C34849"},
                       {"label": "Leingarten", "value": "C34850"},
                       {"label": "Leinzell", "value": "C34851"},
                       {"label": "Lenningen", "value": "C34852"},
                       {"label": "Lenzkirch", "value": "C34853"},
                       {"label": "Leonberg", "value": "C34854"},
                       {"label": "Leutenbach", "value": "C34855"},
                       {"label": "Leutkirch", "value": "C34856"},
                       {"label": "Lichtenau", "value": "C34857"},
                       {"label": "Linkenheim-Hochstetten", "value": "C34858"},
                       {"label": "Lobbach", "value": "C34859"},
                       {"label": "Lochgau", "value": "C34860"},
                       {"label": "Loffenau", "value": "C34861"},
                       {"label": "Loffingen", "value": "C34862"},
                       {"label": "Lonsee", "value": "C34863"},
                       {"label": "Lorch", "value": "C34864"},
                       {"label": "Lorrach", "value": "C34865"},
                       {"label": "Lossburg", "value": "C34866"},
                       {"label": "Lottstetten", "value": "C34867"},
                       {"label": "Lowenstein", "value": "C34868"},
                       {"label": "Ludwigsburg", "value": "C34869"},
                       {"label": "Magstadt", "value": "C34870"},
                       {"label": "Mahlberg", "value": "C34871"},
                       {"label": "Mainhardt", "value": "C34872"},
                       {"label": "Malsch", "value": "C34873"},
                       {"label": "Malterdingen", "value": "C34874"},
                       {"label": "Mannheim", "value": "C34875"},
                       {"label": "Marbach am Neckar", "value": "C34876"},
                       {"label": "Markdorf", "value": "C34877"},
                       {"label": "Markgroningen", "value": "C34878"},
                       {"label": "Maselheim", "value": "C34879"},
                       {"label": "Massenbachhausen", "value": "C34880"},
                       {"label": "Mauer", "value": "C34881"},
                       {"label": "Maulbronn", "value": "C34882"},
                       {"label": "Maulburg", "value": "C34883"},
                       {"label": "Meckenbeuren", "value": "C34884"},
                       {"label": "Meckesheim", "value": "C34885"},
                       {"label": "Meersburg", "value": "C34886"},
                       {"label": "Mehrstetten", "value": "C34887"},
                       {"label": "Meissenheim", "value": "C34888"},
                       {"label": "Mengen", "value": "C34889"},
                       {"label": "Merdingen", "value": "C34890"},
                       {"label": "Merklingen", "value": "C34891"},
                       {"label": "Merzhausen", "value": "C34892"},
                       {"label": "Messkirch", "value": "C34893"},
                       {"label": "Messstetten", "value": "C34894"},
                       {"label": "Metzingen", "value": "C34895"},
                       {"label": "Michelbach an der Bilz", "value": "C34896"},
                       {"label": "Michelfeld", "value": "C34897"},
                       {"label": "Mietingen", "value": "C34898"},
                       {"label": "Mittelbiberach", "value": "C34899"},
                       {"label": "Mittelschontal", "value": "C34900"},
                       {"label": "Mockmuhl", "value": "C34901"},
                       {"label": "Mogglingen", "value": "C34902"},
                       {"label": "Moglingen", "value": "C34903"},
                       {"label": "Monchweiler", "value": "C34904"},
                       {"label": "Monsheim", "value": "C34905"},
                       {"label": "Moos", "value": "C34906"},
                       {"label": "Mosbach", "value": "C34907"},
                       {"label": "Mossingen", "value": "C34908"},
                       {"label": "Motzingen", "value": "C34909"},
                       {"label": "Mudau", "value": "C34910"},
                       {"label": "Muggensturm", "value": "C34911"},
                       {"label": "Muhlacker", "value": "C34912"},
                       {"label": "Muhlenbach", "value": "C34913"},
                       {"label": "Muhlhausen", "value": "C34914"},
                       {"label": "Muhlhausen-Ehingen", "value": "C34915"},
                       {"label": "Muhlheim am Bach", "value": "C34916"},
                       {"label": "Muhlingen", "value": "C34917"},
                       {"label": "Mulfingen", "value": "C34918"},
                       {"label": "Mullheim", "value": "C34919"},
                       {"label": "Mundelsheim", "value": "C34920"},
                       {"label": "Munderkingen", "value": "C34921"},
                       {"label": "Munsingen", "value": "C34922"},
                       {"label": "MunstertalSchwarzwald", "value": "C34923"},
                       {"label": "Murg", "value": "C34924"},
                       {"label": "Murr", "value": "C34925"},
                       {"label": "Murrhardt", "value": "C34926"},
                       {"label": "Mutlangen", "value": "C34927"},
                       {"label": "Nagold", "value": "C34928"},
                       {"label": "Nattheim", "value": "C34929"},
                       {"label": "Neckarbischofsheim", "value": "C34930"},
                       {"label": "Neckargemund", "value": "C34931"},
                       {"label": "Neckargerach", "value": "C34932"},
                       {"label": "Neckarsulm", "value": "C34933"},
                       {"label": "Neckartailfingen", "value": "C34934"},
                       {"label": "Neckartenzlingen", "value": "C34935"},
                       {"label": "Neckarwestheim", "value": "C34936"},
                       {"label": "Neckarzimmern", "value": "C34937"},
                       {"label": "Nehren", "value": "C34938"},
                       {"label": "Neidenstein", "value": "C34939"},
                       {"label": "Neidlingen", "value": "C34940"},
                       {"label": "Nellingen", "value": "C34941"},
                       {"label": "Neresheim", "value": "C34942"},
                       {"label": "Neubulach", "value": "C34943"},
                       {"label": "Neudenau", "value": "C34944"},
                       {"label": "Neuenburg", "value": "C34945"},
                       {"label": "Neuenburg am Rhein", "value": "C34946"},
                       {"label": "Neuenstadt am Kocher", "value": "C34947"},
                       {"label": "Neuenstein", "value": "C34948"},
                       {"label": "Neuffen", "value": "C34949"},
                       {"label": "Neufra", "value": "C34950"},
                       {"label": "Neuhausen", "value": "C34951"},
                       {"label": "Neuhausen auf den Fildern",
                        "value": "C34952"},
                       {"label": "Neukirch", "value": "C34953"},
                       {"label": "Neuler", "value": "C34954"},
                       {"label": "Neulussheim", "value": "C34955"},
                       {"label": "Neunkirchen", "value": "C34956"},
                       {"label": "Neuweiler", "value": "C34957"},
                       {"label": "Niedereschach", "value": "C34958"},
                       {"label": "Niedernhall", "value": "C34959"},
                       {"label": "Niederstetten", "value": "C34960"},
                       {"label": "Niederstotzingen", "value": "C34961"},
                       {"label": "Niederwangen", "value": "C34962"},
                       {"label": "Niefern-Oschelbronn", "value": "C34963"},
                       {"label": "Nordheim", "value": "C34964"},
                       {"label": "Nordrach", "value": "C34965"},
                       {"label": "Notzingen", "value": "C34966"},
                       {"label": "Nufringen", "value": "C34967"},
                       {"label": "Nurtingen", "value": "C34968"},
                       {"label": "Nussloch", "value": "C34969"},
                       {"label": "Oberboihingen", "value": "C34970"},
                       {"label": "Oberderdingen", "value": "C34971"},
                       {"label": "Oberdischingen", "value": "C34972"},
                       {"label": "Oberharmersbach", "value": "C34973"},
                       {"label": "Oberhausen-Rheinhausen", "value": "C34974"},
                       {"label": "Oberjettingen", "value": "C34975"},
                       {"label": "Oberkirch", "value": "C34976"},
                       {"label": "Oberkochen", "value": "C34977"},
                       {"label": "Obermarchtal", "value": "C34978"},
                       {"label": "Oberndorf", "value": "C34979"},
                       {"label": "Obernheim", "value": "C34980"},
                       {"label": "Oberreichenbach", "value": "C34981"},
                       {"label": "Oberried", "value": "C34982"},
                       {"label": "Oberriexingen", "value": "C34983"},
                       {"label": "Oberrot", "value": "C34984"},
                       {"label": "Oberrotweil", "value": "C34985"},
                       {"label": "Obersontheim", "value": "C34986"},
                       {"label": "Oberstadion", "value": "C34987"},
                       {"label": "Oberstenfeld", "value": "C34988"},
                       {"label": "Oberteuringen", "value": "C34989"},
                       {"label": "Oberwolfach", "value": "C34990"},
                       {"label": "Obrigheim", "value": "C34991"},
                       {"label": "Ochsenhausen", "value": "C34992"},
                       {"label": "Oedheim", "value": "C34993"},
                       {"label": "Offenau", "value": "C34994"},
                       {"label": "Offenburg", "value": "C34995"},
                       {"label": "Ofterdingen", "value": "C34996"},
                       {"label": "Oftersheim", "value": "C34997"},
                       {"label": "Ohlsbach", "value": "C34998"},
                       {"label": "Ohmden", "value": "C34999"},
                       {"label": "Ohningen", "value": "C35000"},
                       {"label": "Ohringen", "value": "C35001"},
                       {"label": "Olbronn-Durrn", "value": "C35002"},
                       {"label": "Opfingen", "value": "C35003"},
                       {"label": "Oppenau", "value": "C35004"},
                       {"label": "Oppenweiler", "value": "C35005"},
                       {"label": "Orsingen-Nenzingen", "value": "C35006"},
                       {"label": "Ortenberg", "value": "C35007"},
                       {"label": "Ostelsheim", "value": "C35008"},
                       {"label": "Osterburken", "value": "C35009"},
                       {"label": "Ostfildern", "value": "C35010"},
                       {"label": "Ostrach", "value": "C35011"},
                       {"label": "Ostringen", "value": "C35012"},
                       {"label": "Otigheim", "value": "C35013"},
                       {"label": "Otisheim", "value": "C35014"},
                       {"label": "Ottenbach", "value": "C35015"},
                       {"label": "Ottenhofen", "value": "C35016"},
                       {"label": "Ottersweier", "value": "C35017"},
                       {"label": "Owen", "value": "C35018"},
                       {"label": "Owingen", "value": "C35019"},
                       {"label": "Pfaffenhofen", "value": "C35020"},
                       {"label": "Pfaffenweiler", "value": "C35021"},
                       {"label": "Pfalzgrafenweiler", "value": "C35022"},
                       {"label": "Pfedelbach", "value": "C35023"},
                       {"label": "Pforzheim", "value": "C35024"},
                       {"label": "Pfronstetten", "value": "C35025"},
                       {"label": "Pfullendorf", "value": "C35026"},
                       {"label": "Pfullingen", "value": "C35027"},
                       {"label": "Philippsburg", "value": "C35028"},
                       {"label": "Plankstadt", "value": "C35029"},
                       {"label": "Pleidelsheim", "value": "C35030"},
                       {"label": "Pliezhausen", "value": "C35031"},
                       {"label": "Plochingen", "value": "C35032"},
                       {"label": "Pluderhausen", "value": "C35033"},
                       {"label": "Radolfzell", "value": "C35034"},
                       {"label": "Rammingen", "value": "C35035"},
                       {"label": "Rangendingen", "value": "C35036"},
                       {"label": "Rastatt", "value": "C35037"},
                       {"label": "Rauenberg", "value": "C35038"},
                       {"label": "Ravensburg", "value": "C35039"},
                       {"label": "Rechberghausen", "value": "C35040"},
                       {"label": "Regierungsbezirk Stuttgart",
                        "value": "C35041"},
                       {"label": "Reichartshausen", "value": "C35042"},
                       {"label": "Reichenau", "value": "C35043"},
                       {"label": "Reichenbach an der Fils", "value": "C35044"},
                       {"label": "Reilingen", "value": "C35045"},
                       {"label": "Renchen", "value": "C35046"},
                       {"label": "Renningen", "value": "C35047"},
                       {"label": "Reutlingen", "value": "C35048"},
                       {"label": "Rheinau", "value": "C35049"},
                       {"label": "Rheinfelden", "value": "C35050"},
                       {"label": "Rheinstetten", "value": "C35051"},
                       {"label": "Rickenbach", "value": "C35052"},
                       {"label": "Riederich", "value": "C35053"},
                       {"label": "Riedlingen", "value": "C35054"},
                       {"label": "Riegel", "value": "C35055"},
                       {"label": "Rielasingen-Worblingen", "value": "C35056"},
                       {"label": "Rietheim-Weilheim", "value": "C35057"},
                       {"label": "Ringsheim", "value": "C35058"},
                       {"label": "Rohrdorf", "value": "C35059"},
                       {"label": "Roigheim", "value": "C35060"},
                       {"label": "Rosenberg", "value": "C35061"},
                       {"label": "Rosenfeld", "value": "C35062"},
                       {"label": "Rot", "value": "C35063"},
                       {"label": "Rot am See", "value": "C35064"},
                       {"label": "Rottenacker", "value": "C35065"},
                       {"label": "Rottenburg", "value": "C35066"},
                       {"label": "Rottweil", "value": "C35067"},
                       {"label": "Rudersberg", "value": "C35068"},
                       {"label": "Rummingen", "value": "C35069"},
                       {"label": "Ruppertshofen", "value": "C35070"},
                       {"label": "Rust", "value": "C35071"},
                       {"label": "Rutesheim", "value": "C35072"},
                       {"label": "Sachsenheim", "value": "C35073"},
                       {"label": "Salach", "value": "C35074"},
                       {"label": "Salem", "value": "C35075"},
                       {"label": "Sandhausen", "value": "C35076"},
                       {"label": "Sankt Blasien", "value": "C35077"},
                       {"label": "Sankt Georgen im Schwarzwald",
                        "value": "C35078"},
                       {"label": "Sankt Johann", "value": "C35079"},
                       {"label": "Sankt Leon-Rot", "value": "C35080"},
                       {"label": "Sankt Margen", "value": "C35081"},
                       {"label": "Sankt Peter", "value": "C35082"},
                       {"label": "Sasbach", "value": "C35083"},
                       {"label": "Sasbachwalden", "value": "C35084"},
                       {"label": "Satteldorf", "value": "C35085"},
                       {"label": "Sauldorf", "value": "C35086"},
                       {"label": "Saulgau", "value": "C35087"},
                       {"label": "Schallstadt", "value": "C35088"},
                       {"label": "Schechingen", "value": "C35089"},
                       {"label": "Scheer", "value": "C35090"},
                       {"label": "Schelklingen", "value": "C35091"},
                       {"label": "Schenkenzell", "value": "C35092"},
                       {"label": "Schiltach", "value": "C35093"},
                       {"label": "Schlaitdorf", "value": "C35094"},
                       {"label": "Schlat", "value": "C35095"},
                       {"label": "Schliengen", "value": "C35096"},
                       {"label": "Schlier", "value": "C35097"},
                       {"label": "Schlierbach", "value": "C35098"},
                       {"label": "Schluchsee", "value": "C35099"},
                       {"label": "Schnurpflingen", "value": "C35100"},
                       {"label": "Schomberg", "value": "C35101"},
                       {"label": "Schonach im Schwarzwald", "value": "C35102"},
                       {"label": "Schonaich", "value": "C35103"},
                       {"label": "Schonau", "value": "C35104"},
                       {"label": "Schonau im Schwarzwald", "value": "C35105"},
                       {"label": "Schonwald", "value": "C35106"},
                       {"label": "Schopfheim", "value": "C35107"},
                       {"label": "Schopfloch", "value": "C35108"},
                       {"label": "Schorndorf", "value": "C35109"},
                       {"label": "Schramberg", "value": "C35110"},
                       {"label": "Schriesheim", "value": "C35111"},
                       {"label": "Schrozberg", "value": "C35112"},
                       {"label": "Schuttertal", "value": "C35113"},
                       {"label": "Schutterwald", "value": "C35114"},
                       {"label": "Schwabisch Gmund", "value": "C35115"},
                       {"label": "Schwabisch Hall", "value": "C35116"},
                       {"label": "Schwaigern", "value": "C35117"},
                       {"label": "Schwaikheim", "value": "C35118"},
                       {"label": "Schwanau", "value": "C35119"},
                       {"label": "Schwarzach", "value": "C35120"},
                       {"label": "Schwendi", "value": "C35121"},
                       {"label": "Schwenningen", "value": "C35122"},
                       {"label": "Schwetzingen", "value": "C35123"},
                       {"label": "Schwieberdingen", "value": "C35124"},
                       {"label": "Schworstadt", "value": "C35125"},
                       {"label": "Seckach", "value": "C35126"},
                       {"label": "Seebach", "value": "C35127"},
                       {"label": "Seedorf", "value": "C35128"},
                       {"label": "Seelbach", "value": "C35129"},
                       {"label": "Seitingen-Oberflacht", "value": "C35130"},
                       {"label": "Sersheim", "value": "C35131"},
                       {"label": "Sexau", "value": "C35132"},
                       {"label": "Sickenhausen", "value": "C35133"},
                       {"label": "Siegelsbach", "value": "C35134"},
                       {"label": "Sigmaringen", "value": "C35135"},
                       {"label": "Sigmaringendorf", "value": "C35136"},
                       {"label": "Simmersfeld", "value": "C35137"},
                       {"label": "Simmozheim", "value": "C35138"},
                       {"label": "Sindelfingen", "value": "C35139"},
                       {"label": "Singen", "value": "C35140"},
                       {"label": "Sinsheim", "value": "C35141"},
                       {"label": "Sinzheim", "value": "C35142"},
                       {"label": "Sipplingen", "value": "C35143"},
                       {"label": "Solden", "value": "C35144"},
                       {"label": "Sontheim an der Brenz", "value": "C35145"},
                       {"label": "Spaichingen", "value": "C35146"},
                       {"label": "Spechbach", "value": "C35147"},
                       {"label": "Spiegelberg", "value": "C35148"},
                       {"label": "Spraitbach", "value": "C35149"},
                       {"label": "Staig", "value": "C35150"},
                       {"label": "Staufen", "value": "C35151"},
                       {"label": "Stegen", "value": "C35152"},
                       {"label": "Steinach", "value": "C35153"},
                       {"label": "Steinen", "value": "C35154"},
                       {"label": "Steinenbronn", "value": "C35155"},
                       {"label": "Steinheim am Albuch", "value": "C35156"},
                       {"label": "Steinheim am der Murr", "value": "C35157"},
                       {"label": "Steinmauern", "value": "C35158"},
                       {"label": "Steisslingen", "value": "C35159"},
                       {"label": "Sternenfels", "value": "C35160"},
                       {"label": "Stetten am Kalten Markt", "value": "C35161"},
                       {"label": "Stimpfach", "value": "C35162"},
                       {"label": "Stockach", "value": "C35163"},
                       {"label": "Stodtlen", "value": "C35164"},
                       {"label": "Strassberg", "value": "C35165"},
                       {"label": "Stuhlingen", "value": "C35166"},
                       {"label": "Stuttgart", "value": "C35167"},
                       {"label": "Stuttgart Feuerbach", "value": "C35168"},
                       {"label": "Stuttgart Muhlhausen", "value": "C35169"},
                       {"label": "Stuttgart-Ost", "value": "C35170"},
                       {"label": "Sulz am Neckar", "value": "C35171"},
                       {"label": "Sulzbach an der Murr", "value": "C35172"},
                       {"label": "Sulzburg", "value": "C35173"},
                       {"label": "Sulzfeld", "value": "C35174"},
                       {"label": "Sussen", "value": "C35175"},
                       {"label": "Taferrot", "value": "C35176"},
                       {"label": "Talheim", "value": "C35177"},
                       {"label": "Tamm", "value": "C35178"},
                       {"label": "Tannhausen", "value": "C35179"},
                       {"label": "Tannheim", "value": "C35180"},
                       {"label": "Tauberbischofsheim", "value": "C35181"},
                       {"label": "Tengen", "value": "C35182"},
                       {"label": "Teningen", "value": "C35183"},
                       {"label": "Tennenbronn", "value": "C35184"},
                       {"label": "Tettnang", "value": "C35185"},
                       {"label": "Tiefenbronn", "value": "C35186"},
                       {"label": "Titisee-Neustadt", "value": "C35187"},
                       {"label": "Todtmoos", "value": "C35188"},
                       {"label": "Todtnau", "value": "C35189"},
                       {"label": "Triberg", "value": "C35190"},
                       {"label": "Trochtelfingen", "value": "C35191"},
                       {"label": "Trossingen", "value": "C35192"},
                       {"label": "Tubingen", "value": "C35193"},
                       {"label": "Tubingen Region", "value": "C35194"},
                       {"label": "Tuningen", "value": "C35195"},
                       {"label": "Tuttlingen", "value": "C35196"},
                       {"label": "uberlingen", "value": "C35197"},
                       {"label": "Ubstadt-Weiher", "value": "C35198"},
                       {"label": "Uhingen", "value": "C35199"},
                       {"label": "Uhldingen-Muhlhofen", "value": "C35200"},
                       {"label": "uhlingen-Birkendorf", "value": "C35201"},
                       {"label": "Ulm", "value": "C35202"},
                       {"label": "Umkirch", "value": "C35203"},
                       {"label": "Ummendorf", "value": "C35204"},
                       {"label": "Unlingen", "value": "C35205"},
                       {"label": "Untereisesheim", "value": "C35206"},
                       {"label": "Unterensingen", "value": "C35207"},
                       {"label": "Untergruppenbach", "value": "C35208"},
                       {"label": "Unterhausen", "value": "C35209"},
                       {"label": "Unterjettingen", "value": "C35210"},
                       {"label": "Unterkirnach", "value": "C35211"},
                       {"label": "Unterkrozingen", "value": "C35212"},
                       {"label": "Untermunkheim", "value": "C35213"},
                       {"label": "Unterreichenbach", "value": "C35214"},
                       {"label": "Unterschneidheim", "value": "C35215"},
                       {"label": "Urbach", "value": "C35216"},
                       {"label": "Uttenweiler", "value": "C35217"},
                       {"label": "Vaihingen an der Enz", "value": "C35218"},
                       {"label": "Vellberg", "value": "C35219"},
                       {"label": "Veringenstadt", "value": "C35220"},
                       {"label": "Villingen-Schwenningen", "value": "C35221"},
                       {"label": "Villingendorf", "value": "C35222"},
                       {"label": "Vogt", "value": "C35223"},
                       {"label": "Vogtsburg", "value": "C35224"},
                       {"label": "Vohrenbach", "value": "C35225"},
                       {"label": "Vohringen", "value": "C35226"},
                       {"label": "Volkertshausen", "value": "C35227"},
                       {"label": "Vorstetten", "value": "C35228"},
                       {"label": "Waghausel", "value": "C35229"},
                       {"label": "Waiblingen", "value": "C35230"},
                       {"label": "Waibstadt", "value": "C35231"},
                       {"label": "Wain", "value": "C35232"},
                       {"label": "Wald", "value": "C35233"},
                       {"label": "Waldburg", "value": "C35234"},
                       {"label": "Waldenbuch", "value": "C35235"},
                       {"label": "Waldenburg", "value": "C35236"},
                       {"label": "Waldkirch", "value": "C35237"},
                       {"label": "Waldshut-Tiengen", "value": "C35238"},
                       {"label": "Waldstetten", "value": "C35239"},
                       {"label": "Walheim", "value": "C35240"},
                       {"label": "Walldorf", "value": "C35241"},
                       {"label": "Walldurn", "value": "C35242"},
                       {"label": "Wallhausen", "value": "C35243"},
                       {"label": "Wangen", "value": "C35244"},
                       {"label": "Wannweil", "value": "C35245"},
                       {"label": "Warthausen", "value": "C35246"},
                       {"label": "Waschenbeuren", "value": "C35247"},
                       {"label": "Wehingen", "value": "C35248"},
                       {"label": "Wehr", "value": "C35249"},
                       {"label": "Weidenstetten", "value": "C35250"},
                       {"label": "Weikersheim", "value": "C35251"},
                       {"label": "Weil am Rhein", "value": "C35252"},
                       {"label": "Weil der Stadt", "value": "C35253"},
                       {"label": "Weil im Schonbuch", "value": "C35254"},
                       {"label": "Weilheim", "value": "C35255"},
                       {"label": "Weilheim an der Teck", "value": "C35256"},
                       {"label": "Weingarten", "value": "C35257"},
                       {"label": "Weinheim", "value": "C35258"},
                       {"label": "Weinsberg", "value": "C35259"},
                       {"label": "Weinstadt-Endersbach", "value": "C35260"},
                       {"label": "Weisenbach", "value": "C35261"},
                       {"label": "Weissach", "value": "C35262"},
                       {"label": "Weissbach", "value": "C35263"},
                       {"label": "Weisweil", "value": "C35264"},
                       {"label": "Wellendingen", "value": "C35265"},
                       {"label": "Welzheim", "value": "C35266"},
                       {"label": "Wendlingen am Neckar", "value": "C35267"},
                       {"label": "Werbach", "value": "C35268"},
                       {"label": "Wernau", "value": "C35269"},
                       {"label": "Wertheim", "value": "C35270"},
                       {"label": "Westerheim", "value": "C35271"},
                       {"label": "Westerstetten", "value": "C35272"},
                       {"label": "Westhausen", "value": "C35273"},
                       {"label": "Widdern", "value": "C35274"},
                       {"label": "Wiernsheim", "value": "C35275"},
                       {"label": "Wiesenbach", "value": "C35276"},
                       {"label": "Wiesensteig", "value": "C35277"},
                       {"label": "Wiesloch", "value": "C35278"},
                       {"label": "Wildberg", "value": "C35279"},
                       {"label": "Wilhelmsdorf", "value": "C35280"},
                       {"label": "Wilhelmsfeld", "value": "C35281"},
                       {"label": "Willstatt", "value": "C35282"},
                       {"label": "Wimsheim", "value": "C35283"},
                       {"label": "Winnenden", "value": "C35284"},
                       {"label": "Winterbach", "value": "C35285"},
                       {"label": "Winterlingen", "value": "C35286"},
                       {"label": "Wittnau", "value": "C35287"},
                       {"label": "Wolfach", "value": "C35288"},
                       {"label": "Wolfegg", "value": "C35289"},
                       {"label": "Wolfschlugen", "value": "C35290"},
                       {"label": "Wolpertshausen", "value": "C35291"},
                       {"label": "Wolpertswende", "value": "C35292"},
                       {"label": "Wort", "value": "C35293"},
                       {"label": "Wossingen", "value": "C35294"},
                       {"label": "Wurmberg", "value": "C35295"},
                       {"label": "Wurmlingen", "value": "C35296"},
                       {"label": "Wustenrot", "value": "C35297"},
                       {"label": "Wutoschingen", "value": "C35298"},
                       {"label": "Wyhl", "value": "C35299"},
                       {"label": "Zaberfeld", "value": "C35300"},
                       {"label": "Zaisenhausen", "value": "C35301"},
                       {"label": "Zell", "value": "C35302"},
                       {"label": "Zell im Wiesental", "value": "C35303"},
                       {"label": "Zell unter Aichelberg", "value": "C35304"},
                       {"label": "Zimmern ob Rottweil", "value": "C35305"},
                       {"label": "Zuzenhausen", "value": "C35306"},
                       {"label": "Zweiflingen", "value": "C35307"},
                       {"label": "Zwiefalten", "value": "C35308"}],
 "Badghis": [{"label": "Ghormach", "value": "C7"},
             {"label": "Qala i Naw", "value": "C8"}],
 "Bafata": [{"label": "Bafata", "value": "C43051"},
            {"label": "Contuboel Sector", "value": "C43052"}],
 "Baghdad Governorate": [{"label": "Abu Ghraib District", "value": "C51153"},
                         {"label": "Abu Ghurayb", "value": "C51154"},
                         {"label": "Baghdad", "value": "C51155"}],
 "Baghlan": [{"label": "Baghlan", "value": "C9"},
             {"label": "Hukumati Dahanah-ye Ghori", "value": "C10"},
             {"label": "Nahrin", "value": "C11"},
             {"label": "Pul-e Khumri", "value": "C12"}],
 "Bago": [{"label": "Bago", "value": "C75900"},
          {"label": "Letpandan", "value": "C75901"},
          {"label": "Nyaunglebin", "value": "C75902"},
          {"label": "Paungde", "value": "C75903"},
          {"label": "Pyay", "value": "C75904"},
          {"label": "Pyu", "value": "C75905"},
          {"label": "Taungoo", "value": "C75906"},
          {"label": "Thanatpin", "value": "C75907"},
          {"label": "Tharyarwady", "value": "C75908"}],
 "Bahia": [{"label": "Abaira", "value": "C10399"},
           {"label": "Abare", "value": "C10400"},
           {"label": "Acajutiba", "value": "C10401"},
           {"label": "Adustina", "value": "C10402"},
           {"label": "agua Fria", "value": "C10403"},
           {"label": "Aiquara", "value": "C10404"},
           {"label": "Alagoinhas", "value": "C10405"},
           {"label": "Alcobaca", "value": "C10406"},
           {"label": "Almadina", "value": "C10407"},
           {"label": "Amargosa", "value": "C10408"},
           {"label": "Amelia Rodrigues", "value": "C10409"},
           {"label": "America Dourada", "value": "C10410"},
           {"label": "Anage", "value": "C10411"},
           {"label": "Andarai", "value": "C10412"},
           {"label": "Andorinha", "value": "C10413"},
           {"label": "Angical", "value": "C10414"},
           {"label": "Anguera", "value": "C10415"},
           {"label": "Antas", "value": "C10416"},
           {"label": "Antonio Cardoso", "value": "C10417"},
           {"label": "Antonio Goncalves", "value": "C10418"},
           {"label": "Apora", "value": "C10419"},
           {"label": "Apuarema", "value": "C10420"},
           {"label": "Aracas", "value": "C10421"},
           {"label": "Aracatu", "value": "C10422"},
           {"label": "Araci", "value": "C10423"},
           {"label": "Aramari", "value": "C10424"},
           {"label": "Arataca", "value": "C10425"},
           {"label": "Aratuipe", "value": "C10426"},
           {"label": "Aurelino Leal", "value": "C10427"},
           {"label": "Baianopolis", "value": "C10428"},
           {"label": "Baixa Grande", "value": "C10429"},
           {"label": "Banzae", "value": "C10430"},
           {"label": "Barra", "value": "C10431"},
           {"label": "Barra da Estiva", "value": "C10432"},
           {"label": "Barra do Choca", "value": "C10433"},
           {"label": "Barra do Mendes", "value": "C10434"},
           {"label": "Barra do Rocha", "value": "C10435"},
           {"label": "Barreiras", "value": "C10436"},
           {"label": "Barro Alto", "value": "C10437"},
           {"label": "Barro Preto", "value": "C10438"},
           {"label": "Barrocas", "value": "C10439"},
           {"label": "Beira Rio", "value": "C10440"},
           {"label": "Belmonte", "value": "C10441"},
           {"label": "Belo Campo", "value": "C10442"},
           {"label": "Biritinga", "value": "C10443"},
           {"label": "Boa Nova", "value": "C10444"},
           {"label": "Boa Vista do Tupim", "value": "C10445"},
           {"label": "Bom Jesus da Lapa", "value": "C10446"},
           {"label": "Bom Jesus da Serra", "value": "C10447"},
           {"label": "Boninal", "value": "C10448"},
           {"label": "Bonito", "value": "C10449"},
           {"label": "Boquira", "value": "C10450"},
           {"label": "Botupora", "value": "C10451"},
           {"label": "Brejoes", "value": "C10452"},
           {"label": "Brejolandia", "value": "C10453"},
           {"label": "Brotas de Macaubas", "value": "C10454"},
           {"label": "Brumado", "value": "C10455"},
           {"label": "Buerarema", "value": "C10456"},
           {"label": "Buritirama", "value": "C10457"},
           {"label": "Caatiba", "value": "C10458"},
           {"label": "Cabaceiras do Paraguacu", "value": "C10459"},
           {"label": "Cachoeira", "value": "C10460"},
           {"label": "Cacule", "value": "C10461"},
           {"label": "Caem", "value": "C10462"},
           {"label": "Caetanos", "value": "C10463"},
           {"label": "Caetite", "value": "C10464"},
           {"label": "Cafarnaum", "value": "C10465"},
           {"label": "Cairu", "value": "C10466"},
           {"label": "Caldeirao Grande", "value": "C10467"},
           {"label": "Camacan", "value": "C10468"},
           {"label": "Camacari", "value": "C10469"},
           {"label": "Camamu", "value": "C10470"},
           {"label": "Campo Alegre de Lourdes", "value": "C10471"},
           {"label": "Campo Formoso", "value": "C10472"},
           {"label": "Canapolis", "value": "C10473"},
           {"label": "Canarana", "value": "C10474"},
           {"label": "Canavieiras", "value": "C10475"},
           {"label": "Candeal", "value": "C10476"},
           {"label": "Candeias", "value": "C10477"},
           {"label": "Candiba", "value": "C10478"},
           {"label": "Candido Sales", "value": "C10479"},
           {"label": "Cansancao", "value": "C10480"},
           {"label": "Canudos", "value": "C10481"},
           {"label": "Capela do Alto Alegre", "value": "C10482"},
           {"label": "Capim Grosso", "value": "C10483"},
           {"label": "Caraibas", "value": "C10484"},
           {"label": "Caravelas", "value": "C10485"},
           {"label": "Cardeal da Silva", "value": "C10486"},
           {"label": "Carinhanha", "value": "C10487"},
           {"label": "Casa Nova", "value": "C10488"},
           {"label": "Castro Alves", "value": "C10489"},
           {"label": "Catolandia", "value": "C10490"},
           {"label": "Catu", "value": "C10491"},
           {"label": "Caturama", "value": "C10492"},
           {"label": "Central", "value": "C10493"},
           {"label": "Chorrocho", "value": "C10494"},
           {"label": "Cicero Dantas", "value": "C10495"},
           {"label": "Cipo", "value": "C10496"},
           {"label": "Coaraci", "value": "C10497"},
           {"label": "Cocos", "value": "C10498"},
           {"label": "Conceicao da Feira", "value": "C10499"},
           {"label": "Conceicao do Almeida", "value": "C10500"},
           {"label": "Conceicao do Coite", "value": "C10501"},
           {"label": "Conceicao do Jacuipe", "value": "C10502"},
           {"label": "Conde", "value": "C10503"},
           {"label": "Condeuba", "value": "C10504"},
           {"label": "Contendas do Sincora", "value": "C10505"},
           {"label": "Coracao de Maria", "value": "C10506"},
           {"label": "Cordeiros", "value": "C10507"},
           {"label": "Coribe", "value": "C10508"},
           {"label": "Coronel Joao Sa", "value": "C10509"},
           {"label": "Correntina", "value": "C10510"},
           {"label": "Cotegipe", "value": "C10511"},
           {"label": "Cravolandia", "value": "C10512"},
           {"label": "Crisopolis", "value": "C10513"},
           {"label": "Cristopolis", "value": "C10514"},
           {"label": "Cruz das Almas", "value": "C10515"},
           {"label": "Curaca", "value": "C10516"},
           {"label": "Dario Meira", "value": "C10517"},
           {"label": "Dias davila", "value": "C10518"},
           {"label": "Dom Basilio", "value": "C10519"},
           {"label": "Dom Macedo Costa", "value": "C10520"},
           {"label": "Elisio Medrado", "value": "C10521"},
           {"label": "Encruzilhada", "value": "C10522"},
           {"label": "Entre Rios", "value": "C10523"},
           {"label": "erico Cardoso", "value": "C10524"},
           {"label": "Esplanada", "value": "C10525"},
           {"label": "Euclides da Cunha", "value": "C10526"},
           {"label": "Eunapolis", "value": "C10527"},
           {"label": "Fatima", "value": "C10528"},
           {"label": "Feira da Mata", "value": "C10529"},
           {"label": "Feira de Santana", "value": "C10530"},
           {"label": "Filadelfia", "value": "C10531"},
           {"label": "Firmino Alves", "value": "C10532"},
           {"label": "Floresta Azul", "value": "C10533"},
           {"label": "Formosa do Rio Preto", "value": "C10534"},
           {"label": "Gandu", "value": "C10535"},
           {"label": "Gaviao", "value": "C10536"},
           {"label": "Gentio do Ouro", "value": "C10537"},
           {"label": "Gloria", "value": "C10538"},
           {"label": "Gongogi", "value": "C10539"},
           {"label": "Governador Mangabeira", "value": "C10540"},
           {"label": "Guajeru", "value": "C10541"},
           {"label": "Guanambi", "value": "C10542"},
           {"label": "Guaratinga", "value": "C10543"},
           {"label": "Heliopolis", "value": "C10544"},
           {"label": "Iacu", "value": "C10545"},
           {"label": "Ibiassuce", "value": "C10546"},
           {"label": "Ibicarai", "value": "C10547"},
           {"label": "Ibicoara", "value": "C10548"},
           {"label": "Ibicui", "value": "C10549"},
           {"label": "Ibipeba", "value": "C10550"},
           {"label": "Ibipitanga", "value": "C10551"},
           {"label": "Ibiquera", "value": "C10552"},
           {"label": "Ibirapitanga", "value": "C10553"},
           {"label": "Ibirapua", "value": "C10554"},
           {"label": "Ibirataia", "value": "C10555"},
           {"label": "Ibitiara", "value": "C10556"},
           {"label": "Ibitita", "value": "C10557"},
           {"label": "Ibotirama", "value": "C10558"},
           {"label": "Ichu", "value": "C10559"},
           {"label": "Igapora", "value": "C10560"},
           {"label": "Igrapiuna", "value": "C10561"},
           {"label": "Iguai", "value": "C10562"},
           {"label": "Ilheus", "value": "C10563"},
           {"label": "Inhambupe", "value": "C10564"},
           {"label": "Ipecaeta", "value": "C10565"},
           {"label": "Ipiau", "value": "C10566"},
           {"label": "Ipira", "value": "C10567"},
           {"label": "Ipupiara", "value": "C10568"},
           {"label": "Irajuba", "value": "C10569"},
           {"label": "Iramaia", "value": "C10570"},
           {"label": "Iraquara", "value": "C10571"},
           {"label": "Irara", "value": "C10572"},
           {"label": "Irece", "value": "C10573"},
           {"label": "Itabela", "value": "C10574"},
           {"label": "Itaberaba", "value": "C10575"},
           {"label": "Itabuna", "value": "C10576"},
           {"label": "Itacare", "value": "C10577"},
           {"label": "Itaete", "value": "C10578"},
           {"label": "Itagi", "value": "C10579"},
           {"label": "Itagiba", "value": "C10580"},
           {"label": "Itagimirim", "value": "C10581"},
           {"label": "Itaguacu da Bahia", "value": "C10582"},
           {"label": "Itaju do Colonia", "value": "C10583"},
           {"label": "Itajuipe", "value": "C10584"},
           {"label": "Itamaraju", "value": "C10585"},
           {"label": "Itamari", "value": "C10586"},
           {"label": "Itambe", "value": "C10587"},
           {"label": "Itanagra", "value": "C10588"},
           {"label": "Itanhem", "value": "C10589"},
           {"label": "Itaparica", "value": "C10590"},
           {"label": "Itape", "value": "C10591"},
           {"label": "Itapebi", "value": "C10592"},
           {"label": "Itapetinga", "value": "C10593"},
           {"label": "Itapicuru", "value": "C10594"},
           {"label": "Itapitanga", "value": "C10595"},
           {"label": "Itaquara", "value": "C10596"},
           {"label": "Itarantim", "value": "C10597"},
           {"label": "Itatim", "value": "C10598"},
           {"label": "Itirucu", "value": "C10599"},
           {"label": "Itiuba", "value": "C10600"},
           {"label": "Itororo", "value": "C10601"},
           {"label": "Ituacu", "value": "C10602"},
           {"label": "Itubera", "value": "C10603"},
           {"label": "Iuiu", "value": "C10604"},
           {"label": "Jaborandi", "value": "C10605"},
           {"label": "Jacaraci", "value": "C10606"},
           {"label": "Jacobina", "value": "C10607"},
           {"label": "Jaguaquara", "value": "C10608"},
           {"label": "Jaguarari", "value": "C10609"},
           {"label": "Jaguaripe", "value": "C10610"},
           {"label": "Jandaira", "value": "C10611"},
           {"label": "Jequie", "value": "C10612"},
           {"label": "Jeremoabo", "value": "C10613"},
           {"label": "Jiquirica", "value": "C10614"},
           {"label": "Jitauna", "value": "C10615"},
           {"label": "Joao Dourado", "value": "C10616"},
           {"label": "Juazeiro", "value": "C10617"},
           {"label": "Jucurucu", "value": "C10618"},
           {"label": "Jussara", "value": "C10619"},
           {"label": "Jussari", "value": "C10620"},
           {"label": "Jussiape", "value": "C10621"},
           {"label": "Lafaiete Coutinho", "value": "C10622"},
           {"label": "Lagoa Real", "value": "C10623"},
           {"label": "Laje", "value": "C10624"},
           {"label": "Lajedao", "value": "C10625"},
           {"label": "Lajedinho", "value": "C10626"},
           {"label": "Lajedo do Tabocal", "value": "C10627"},
           {"label": "Lamarao", "value": "C10628"},
           {"label": "Lapao", "value": "C10629"},
           {"label": "Lauro de Freitas", "value": "C10630"},
           {"label": "Lencois", "value": "C10631"},
           {"label": "Licinio de Almeida", "value": "C10632"},
           {"label": "Livramento de Nossa Senhora", "value": "C10633"},
           {"label": "Livramento do Brumado", "value": "C10634"},
           {"label": "Luis Eduardo Magalhaes", "value": "C10635"},
           {"label": "Macajuba", "value": "C10636"},
           {"label": "Macarani", "value": "C10637"},
           {"label": "Macaubas", "value": "C10638"},
           {"label": "Macurure", "value": "C10639"},
           {"label": "Madre de Deus", "value": "C10640"},
           {"label": "Maetinga", "value": "C10641"},
           {"label": "Maiquinique", "value": "C10642"},
           {"label": "Mairi", "value": "C10643"},
           {"label": "Malhada", "value": "C10644"},
           {"label": "Malhada de Pedras", "value": "C10645"},
           {"label": "Manoel Vitorino", "value": "C10646"},
           {"label": "Mansidao", "value": "C10647"},
           {"label": "Maracas", "value": "C10648"},
           {"label": "Maragogipe", "value": "C10649"},
           {"label": "Marau", "value": "C10650"},
           {"label": "Marcionilio Souza", "value": "C10651"},
           {"label": "Mascote", "value": "C10652"},
           {"label": "Mata de Sao Joao", "value": "C10653"},
           {"label": "Matina", "value": "C10654"},
           {"label": "Medeiros Neto", "value": "C10655"},
           {"label": "Miguel Calmon", "value": "C10656"},
           {"label": "Milagres", "value": "C10657"},
           {"label": "Mirangaba", "value": "C10658"},
           {"label": "Mirante", "value": "C10659"},
           {"label": "Monte Santo", "value": "C10660"},
           {"label": "Morpara", "value": "C10661"},
           {"label": "Morro do Chapeu", "value": "C10662"},
           {"label": "Mortugaba", "value": "C10663"},
           {"label": "Mucuge", "value": "C10664"},
           {"label": "Mucuri", "value": "C10665"},
           {"label": "Mulungu do Morro", "value": "C10666"},
           {"label": "Mundo Novo", "value": "C10667"},
           {"label": "Muniz Ferreira", "value": "C10668"},
           {"label": "Muquem do Sao Francisco", "value": "C10669"},
           {"label": "Muritiba", "value": "C10670"},
           {"label": "Mutuipe", "value": "C10671"},
           {"label": "Nazare", "value": "C10672"},
           {"label": "Nilo Pecanha", "value": "C10673"},
           {"label": "Nordestina", "value": "C10674"},
           {"label": "Nova Canaa", "value": "C10675"},
           {"label": "Nova Fatima", "value": "C10676"},
           {"label": "Nova Ibia", "value": "C10677"},
           {"label": "Nova Itarana", "value": "C10678"},
           {"label": "Nova Redencao", "value": "C10679"},
           {"label": "Nova Soure", "value": "C10680"},
           {"label": "Nova Vicosa", "value": "C10681"},
           {"label": "Novo Horizonte", "value": "C10682"},
           {"label": "Novo Triunfo", "value": "C10683"},
           {"label": "Olindina", "value": "C10684"},
           {"label": "Oliveira dos Brejinhos", "value": "C10685"},
           {"label": "Ouricangas", "value": "C10686"},
           {"label": "Ourolandia", "value": "C10687"},
           {"label": "Palmas de Monte Alto", "value": "C10688"},
           {"label": "Palmeiras", "value": "C10689"},
           {"label": "Paramirim", "value": "C10690"},
           {"label": "Paratinga", "value": "C10691"},
           {"label": "Paripiranga", "value": "C10692"},
           {"label": "Pau Brasil", "value": "C10693"},
           {"label": "Paulo Afonso", "value": "C10694"},
           {"label": "Pe de Serra", "value": "C10695"},
           {"label": "Pedrao", "value": "C10696"},
           {"label": "Pedro Alexandre", "value": "C10697"},
           {"label": "Piata", "value": "C10698"},
           {"label": "Pilao Arcado", "value": "C10699"},
           {"label": "Pindai", "value": "C10700"},
           {"label": "Pindobacu", "value": "C10701"},
           {"label": "Pintadas", "value": "C10702"},
           {"label": "Pirai do Norte", "value": "C10703"},
           {"label": "Piripa", "value": "C10704"},
           {"label": "Piritiba", "value": "C10705"},
           {"label": "Planaltino", "value": "C10706"},
           {"label": "Planalto", "value": "C10707"},
           {"label": "Pocoes", "value": "C10708"},
           {"label": "Pojuca", "value": "C10709"},
           {"label": "Ponto Novo", "value": "C10710"},
           {"label": "Porto Seguro", "value": "C10711"},
           {"label": "Posto da Mata", "value": "C10712"},
           {"label": "Potiragua", "value": "C10713"},
           {"label": "Prado", "value": "C10714"},
           {"label": "Presidente Dutra", "value": "C10715"},
           {"label": "Presidente Janio Quadros", "value": "C10716"},
           {"label": "Presidente Tancredo Neves", "value": "C10717"},
           {"label": "Queimadas", "value": "C10718"},
           {"label": "Quijingue", "value": "C10719"},
           {"label": "Quixabeira", "value": "C10720"},
           {"label": "Rafael Jambeiro", "value": "C10721"},
           {"label": "Remanso", "value": "C10722"},
           {"label": "Retirolandia", "value": "C10723"},
           {"label": "Riachao das Neves", "value": "C10724"},
           {"label": "Riachao do Jacuipe", "value": "C10725"},
           {"label": "Riacho de Santana", "value": "C10726"},
           {"label": "Ribeira do Amparo", "value": "C10727"},
           {"label": "Ribeira do Pombal", "value": "C10728"},
           {"label": "Ribeirao do Largo", "value": "C10729"},
           {"label": "Rio de Contas", "value": "C10730"},
           {"label": "Rio do Antonio", "value": "C10731"},
           {"label": "Rio do Pires", "value": "C10732"},
           {"label": "Rio Real", "value": "C10733"},
           {"label": "Rodelas", "value": "C10734"},
           {"label": "Ruy Barbosa", "value": "C10735"},
           {"label": "Salinas da Margarida", "value": "C10736"},
           {"label": "Salvador", "value": "C10737"},
           {"label": "Santa Barbara", "value": "C10738"},
           {"label": "Santa Brigida", "value": "C10739"},
           {"label": "Santa Cruz Cabralia", "value": "C10740"},
           {"label": "Santa Cruz da Vitoria", "value": "C10741"},
           {"label": "Santa Ines", "value": "C10742"},
           {"label": "Santa Luzia", "value": "C10743"},
           {"label": "Santa Maria da Vitoria", "value": "C10744"},
           {"label": "Santa Rita de Cassia", "value": "C10745"},
           {"label": "Santa Terezinha", "value": "C10746"},
           {"label": "Santaluz", "value": "C10747"},
           {"label": "Santana", "value": "C10748"},
           {"label": "Santanopolis", "value": "C10749"},
           {"label": "Santo Amaro", "value": "C10750"},
           {"label": "Santo Antonio de Jesus", "value": "C10751"},
           {"label": "Santo Estevao", "value": "C10752"},
           {"label": "Sao Desiderio", "value": "C10753"},
           {"label": "Sao Domingos", "value": "C10754"},
           {"label": "Sao Felipe", "value": "C10755"},
           {"label": "Sao Felix", "value": "C10756"},
           {"label": "Sao Felix do Coribe", "value": "C10757"},
           {"label": "Sao Francisco do Conde", "value": "C10758"},
           {"label": "Sao Gabriel", "value": "C10759"},
           {"label": "Sao Goncalo dos Campos", "value": "C10760"},
           {"label": "Sao Jose da Vitoria", "value": "C10761"},
           {"label": "Sao Jose do Jacuipe", "value": "C10762"},
           {"label": "Sao Miguel das Matas", "value": "C10763"},
           {"label": "Sao Sebastiao do Passe", "value": "C10764"},
           {"label": "Sapeacu", "value": "C10765"},
           {"label": "Satiro Dias", "value": "C10766"},
           {"label": "Saubara", "value": "C10767"},
           {"label": "Saude", "value": "C10768"},
           {"label": "Seabra", "value": "C10769"},
           {"label": "Sebastiao Laranjeiras", "value": "C10770"},
           {"label": "Senhor do Bonfim", "value": "C10771"},
           {"label": "Sento Se", "value": "C10772"},
           {"label": "Serra do Ramalho", "value": "C10773"},
           {"label": "Serra Dourada", "value": "C10774"},
           {"label": "Serra Preta", "value": "C10775"},
           {"label": "Serrinha", "value": "C10776"},
           {"label": "Serrolandia", "value": "C10777"},
           {"label": "Simoes Filho", "value": "C10778"},
           {"label": "Sitio do Mato", "value": "C10779"},
           {"label": "Sitio do Quinto", "value": "C10780"},
           {"label": "Sobradinho", "value": "C10781"},
           {"label": "Souto Soares", "value": "C10782"},
           {"label": "Tabocas do Brejo Velho", "value": "C10783"},
           {"label": "Tanhacu", "value": "C10784"},
           {"label": "Tanque Novo", "value": "C10785"},
           {"label": "Tanquinho", "value": "C10786"},
           {"label": "Taperoa", "value": "C10787"},
           {"label": "Tapiramuta", "value": "C10788"},
           {"label": "Teixeira de Freitas", "value": "C10789"},
           {"label": "Teodoro Sampaio", "value": "C10790"},
           {"label": "Teofilandia", "value": "C10791"},
           {"label": "Teolandia", "value": "C10792"},
           {"label": "Terra Nova", "value": "C10793"},
           {"label": "Tremedal", "value": "C10794"},
           {"label": "Tucano", "value": "C10795"},
           {"label": "Uaua", "value": "C10796"},
           {"label": "Ubaira", "value": "C10797"},
           {"label": "Ubaitaba", "value": "C10798"},
           {"label": "Ubata", "value": "C10799"},
           {"label": "Uibai", "value": "C10800"},
           {"label": "Umburanas", "value": "C10801"},
           {"label": "Una", "value": "C10802"},
           {"label": "Urandi", "value": "C10803"},
           {"label": "Urucuca", "value": "C10804"},
           {"label": "Utinga", "value": "C10805"},
           {"label": "Valenca", "value": "C10806"},
           {"label": "Valente", "value": "C10807"},
           {"label": "Varzea da Roca", "value": "C10808"},
           {"label": "Varzea do Poco", "value": "C10809"},
           {"label": "Varzea Nova", "value": "C10810"},
           {"label": "Varzedo", "value": "C10811"},
           {"label": "Vera Cruz", "value": "C10812"},
           {"label": "Vereda", "value": "C10813"},
           {"label": "Vitoria da Conquista", "value": "C10814"},
           {"label": "Wagner", "value": "C10815"},
           {"label": "Wanderley", "value": "C10816"},
           {"label": "Wenceslau Guimaraes", "value": "C10817"},
           {"label": "Xique Xique", "value": "C10818"},
           {"label": "Xique-Xique", "value": "C10819"}],
 "Bahr el Gazel": [{"label": "Moussoro", "value": "C17851"}],
 "Baikonur": [{"label": "Baikonur", "value": "C64346"}],
 "Baiti District": [{"label": "Baiti", "value": "C76014"}],
 "Baja California": [{"label": "Alfonso Garzon [Granjas Familiares]",
                      "value": "C66164"},
                     {"label": "Benito Garcia (El Zorrillo)",
                      "value": "C66165"},
                     {"label": "Benito Juarez", "value": "C66166"},
                     {"label": "Buenos Aires", "value": "C66167"},
                     {"label": "Camalu", "value": "C66168"},
                     {"label": "Cereso del Hongo", "value": "C66169"},
                     {"label": "Ciudad Coahuila (Kilometro Cincuenta y Siete)",
                      "value": "C66170"},
                     {"label": "Ciudad Morelos", "value": "C66171"},
                     {"label": "Colinas del Sol", "value": "C66172"},
                     {"label": "Colonia Gomez Morin", "value": "C66173"},
                     {"label": "Colonia Lomas de San Ramon (Triquis)",
                      "value": "C66174"},
                     {"label": "Colonia Nueva Era", "value": "C66175"},
                     {"label": "Colonia Venustiano Carranza",
                      "value": "C66176"},
                     {"label": "Delta", "value": "C66177"},
                     {"label": "Doctor Alberto Oviedo Mota", "value": "C66178"},
                     {"label": "Durango", "value": "C66179"},
                     {"label": "Ejido Doctor Alberto Oviedo Mota (El Indiviso)",
                      "value": "C66180"},
                     {"label": "Ejido General Leandro Valle",
                      "value": "C66181"},
                     {"label": "Ejido Javier Rojo Gomez", "value": "C66182"},
                     {"label": "Ejido Jiquilpan", "value": "C66183"},
                     {"label": "Ejido Lazaro Cardenas", "value": "C66184"},
                     {"label": "Ejido Michoacan de Ocampo", "value": "C66185"},
                     {"label": "Ejido Netzahualcoyotl", "value": "C66186"},
                     {"label": "Ejido Nuevo Leon", "value": "C66187"},
                     {"label": "Ejido Ojo de Agua", "value": "C66188"},
                     {"label": "Ejido Patzcuaro", "value": "C66189"},
                     {"label": "Ejido Plan de Ayala", "value": "C66190"},
                     {"label": "Ejido Quintana Roo", "value": "C66191"},
                     {"label": "Ejido Saltillo", "value": "C66192"},
                     {"label": "Ejido Sinaloa", "value": "C66193"},
                     {"label": "Ejido Sonora", "value": "C66194"},
                     {"label": "Ejido Tabasco", "value": "C66195"},
                     {"label": "Ejido Toluca", "value": "C66196"},
                     {"label": "Ejido Vicente Guerrero", "value": "C66197"},
                     {"label": "Ejido Yucatan", "value": "C66198"},
                     {"label": "El Nino", "value": "C66199"},
                     {"label": "El Porvenir", "value": "C66200"},
                     {"label": "El Rosario de Arriba", "value": "C66201"},
                     {"label": "El Sauzal de Rodriguez", "value": "C66202"},
                     {"label": "Emiliano Zapata", "value": "C66203"},
                     {"label": "Ensenada", "value": "C66204"},
                     {"label": "Estacion Coahuila", "value": "C66205"},
                     {"label": "Estacion Pescaderos", "value": "C66206"},
                     {"label": "Fraccionamiento del Valle", "value": "C66207"},
                     {"label": "Francisco Zarco", "value": "C66208"},
                     {"label": "Guadalupe Victoria", "value": "C66209"},
                     {"label": "Guerrero Negro", "value": "C66210"},
                     {"label": "Hacienda Tecate", "value": "C66211"},
                     {"label": "Hermosillo", "value": "C66212"},
                     {"label": "Islas Agrarias Grupo A", "value": "C66213"},
                     {"label": "Islas Agrarias Grupo B", "value": "C66214"},
                     {"label": "La Esperanza [Granjas Familiares]",
                      "value": "C66215"},
                     {"label": "La Providencia", "value": "C66216"},
                     {"label": "Las Brisas", "value": "C66217"},
                     {"label": "Las Delicias", "value": "C66218"},
                     {"label": "Lazaro Cardenas", "value": "C66219"},
                     {"label": "Licenciado Gustavo Diaz Ordaz",
                      "value": "C66220"},
                     {"label": "Lomas de Santa Anita", "value": "C66221"},
                     {"label": "Lomas de Tlatelolco", "value": "C66222"},
                     {"label": "Los Algodones", "value": "C66223"},
                     {"label": "Los Valles", "value": "C66224"},
                     {"label": "Luis Rodriguez (El Vergel)", "value": "C66225"},
                     {"label": "Maclovio Herrera (Colonia Aviacion)",
                      "value": "C66226"},
                     {"label": "Maclovio Rojas", "value": "C66227"},
                     {"label": "Mexicali", "value": "C66228"},
                     {"label": "Playas de Rosarito", "value": "C66229"},
                     {"label": "Poblado Chulavista (El Chorizo)",
                      "value": "C66230"},
                     {"label": "Poblado Lazaro Cardenas (La Veintiocho)",
                      "value": "C66231"},
                     {"label": "Porticos de San Antonio", "value": "C66232"},
                     {"label": "Primo Tapia", "value": "C66233"},
                     {"label": "Progreso", "value": "C66234"},
                     {"label": "Puebla", "value": "C66235"},
                     {"label": "Pueblo Benito Garcia", "value": "C66236"},
                     {"label": "Quinta del Cedro", "value": "C66237"},
                     {"label": "Rancho La Gloria", "value": "C66238"},
                     {"label": "Rancho Verde", "value": "C66239"},
                     {"label": "Rodolfo Sanchez Taboada", "value": "C66240"},
                     {"label": "Rosarito", "value": "C66241"},
                     {"label": "Rumorosa", "value": "C66242"},
                     {"label": "Salvador Rosas Magallon", "value": "C66243"},
                     {"label": "San Felipe", "value": "C66244"},
                     {"label": "San Luis", "value": "C66245"},
                     {"label": "San Quintin", "value": "C66246"},
                     {"label": "San Vicente", "value": "C66247"},
                     {"label": "Santa Anita", "value": "C66248"},
                     {"label": "Santa Fe", "value": "C66249"},
                     {"label": "Santa Isabel", "value": "C66250"},
                     {"label": "Tecate", "value": "C66251"},
                     {"label": "Tecolots", "value": "C66252"},
                     {"label": "Terrazas del Valle", "value": "C66253"},
                     {"label": "Tijuana", "value": "C66254"},
                     {"label": "Vicente Guerrero", "value": "C66255"},
                     {"label": "Villa del Campo", "value": "C66256"},
                     {"label": "Villa del Prado 2da Seccion",
                      "value": "C66257"},
                     {"label": "Vinas del Sol", "value": "C66258"}],
 "Baja California Sur": [{"label": "Bahia Asuncion", "value": "C66259"},
                         {"label": "Bahia Tortugas", "value": "C66260"},
                         {"label": "Cabo San Lucas", "value": "C66261"},
                         {"label": "Chametla", "value": "C66262"},
                         {"label": "Ciudad Constitucion", "value": "C66263"},
                         {"label": "Ciudad Insurgentes", "value": "C66264"},
                         {"label": "Colonia del Sol", "value": "C66265"},
                         {"label": "Comondu", "value": "C66266"},
                         {"label": "El Centenario", "value": "C66267"},
                         {"label": "El Pescadero", "value": "C66268"},
                         {"label": "El Silencio", "value": "C66269"},
                         {"label": "La Paz", "value": "C66270"},
                         {"label": "La Playa", "value": "C66271"},
                         {"label": "La Rivera", "value": "C66272"},
                         {"label": "Las Margaritas", "value": "C66273"},
                         {"label": "Las Palmas", "value": "C66274"},
                         {"label": "Las Veredas", "value": "C66275"},
                         {"label": "Loreto", "value": "C66276"},
                         {"label": "Los Barriles", "value": "C66277"},
                         {"label": "Los Cabos", "value": "C66278"},
                         {"label": "Meliton Albanez Dominguez",
                          "value": "C66279"},
                         {"label": "Miraflores", "value": "C66280"},
                         {"label": "Mulege", "value": "C66281"},
                         {"label": "Puerto Adolfo Lopez Mateos",
                          "value": "C66282"},
                         {"label": "Puerto San Carlos", "value": "C66283"},
                         {"label": "San Francisco", "value": "C66284"},
                         {"label": "San Jose del Cabo", "value": "C66285"},
                         {"label": "Santa Rosalia", "value": "C66286"},
                         {"label": "Todos Santos", "value": "C66287"},
                         {"label": "Villa Alberto Andres Alvarado Aramburo",
                          "value": "C66288"},
                         {"label": "Villa Morelos", "value": "C66289"}],
 "Baja Verapaz Department": [{"label": "Cubulco", "value": "C42630"},
                             {"label": "El Chol", "value": "C42631"},
                             {"label": "Granados", "value": "C42632"},
                             {"label": "Purulha", "value": "C42633"},
                             {"label": "Rabinal", "value": "C42634"},
                             {"label": "Salama", "value": "C42635"},
                             {"label": "San Jeronimo", "value": "C42636"},
                             {"label": "San Miguel Chicaj", "value": "C42637"}],
 "Bakool": [{"label": "Tayeeglow", "value": "C107880"},
            {"label": "Waajid", "value": "C107881"},
            {"label": "Xuddur", "value": "C107882"},
            {"label": "Yeed", "value": "C107883"}],
 "Baku": [{"label": "Amirdzhan", "value": "C8383"},
          {"label": "Badamdar", "value": "C8384"},
          {"label": "Bakixanov", "value": "C8385"},
          {"label": "Baku", "value": "C8386"},
          {"label": "Balakhani", "value": "C8387"},
          {"label": "Bilajari", "value": "C8388"},
          {"label": "Bilajer", "value": "C8389"},
          {"label": "Binagadi", "value": "C8390"},
          {"label": "Biny Selo", "value": "C8391"},
          {"label": "Buzovna", "value": "C8392"},
          {"label": "Hovsan", "value": "C8393"},
          {"label": "Khodzhi-Gasan", "value": "C8394"},
          {"label": "Korgoz", "value": "C8395"},
          {"label": "Lokbatan", "value": "C8396"},
          {"label": "Mardakan", "value": "C8397"},
          {"label": "Mastaga", "value": "C8398"},
          {"label": "Nardaran", "value": "C8399"},
          {"label": "Nizami Rayonu", "value": "C8400"},
          {"label": "Pirallahi", "value": "C8401"},
          {"label": "Puta", "value": "C8402"},
          {"label": "Qala", "value": "C8403"},
          {"label": "Qaracuxur", "value": "C8404"},
          {"label": "Qobustan", "value": "C8405"},
          {"label": "Ramana", "value": "C8406"},
          {"label": "Sabuncu", "value": "C8407"},
          {"label": "Sanqacal", "value": "C8408"},
          {"label": "Turkan", "value": "C8409"},
          {"label": "Yeni Suraxani", "value": "C8410"},
          {"label": "Zabrat", "value": "C8411"},
          {"label": "Zyrya", "value": "C8412"}],
 "Balakan District": [{"label": "Belokany", "value": "C8413"},
                      {"label": "Qabaqcol", "value": "C8414"}],
 "Baldone Municipality": [{"label": "Baldone", "value": "C64850"}],
 "Balearic Islands": [{"label": "Alaior", "value": "C109894"},
                      {"label": "Alaro", "value": "C109895"},
                      {"label": "Alcudia", "value": "C109896"},
                      {"label": "Algaida", "value": "C109897"},
                      {"label": "Andratx", "value": "C109898"},
                      {"label": "Ariany", "value": "C109899"},
                      {"label": "Arta", "value": "C109900"},
                      {"label": "Banyalbufar", "value": "C109901"},
                      {"label": "Binissalem", "value": "C109902"},
                      {"label": "Buger", "value": "C109903"},
                      {"label": "Bunyola", "value": "C109904"},
                      {"label": "Cala dOr", "value": "C109905"},
                      {"label": "Cala Rajada", "value": "C109906"},
                      {"label": "Calonge", "value": "C109907"},
                      {"label": "Calvia", "value": "C109908"},
                      {"label": "Camp de Mar", "value": "C109909"},
                      {"label": "Campanet", "value": "C109910"},
                      {"label": "Campos", "value": "C109911"},
                      {"label": "Can Pastilla", "value": "C109912"},
                      {"label": "Can Picafort", "value": "C109913"},
                      {"label": "Capdepera", "value": "C109914"},
                      {"label": "Ciutadella", "value": "C109915"},
                      {"label": "Coll den Rabassa", "value": "C109916"},
                      {"label": "Colonia de Sant Jordi", "value": "C109917"},
                      {"label": "Consell", "value": "C109918"},
                      {"label": "Costitx", "value": "C109919"},
                      {"label": "Deia", "value": "C109920"},
                      {"label": "El Toro", "value": "C109921"},
                      {"label": "Es Castell", "value": "C109922"},
                      {"label": "Es Molinar", "value": "C109923"},
                      {"label": "Escorca", "value": "C109924"},
                      {"label": "Esporles", "value": "C109925"},
                      {"label": "Estellencs", "value": "C109926"},
                      {"label": "Felanitx", "value": "C109927"},
                      {"label": "Ferreries", "value": "C109928"},
                      {"label": "Fornalutx", "value": "C109929"},
                      {"label": "Ibiza", "value": "C109930"},
                      {"label": "Illes Balears", "value": "C109931"},
                      {"label": "Inca", "value": "C109932"},
                      {"label": "Lloret de Vistalegre", "value": "C109933"},
                      {"label": "Lloseta", "value": "C109934"},
                      {"label": "Llubi", "value": "C109935"},
                      {"label": "Llucmajor", "value": "C109936"},
                      {"label": "Magaluf", "value": "C109937"},
                      {"label": "Manacor", "value": "C109938"},
                      {"label": "Mancor de la Vall", "value": "C109939"},
                      {"label": "Mao", "value": "C109940"},
                      {"label": "Maria de la Salut", "value": "C109941"},
                      {"label": "Marratxi", "value": "C109942"},
                      {"label": "Mercadal", "value": "C109943"},
                      {"label": "Montuiri", "value": "C109944"},
                      {"label": "Muro", "value": "C109945"},
                      {"label": "Palma", "value": "C109946"},
                      {"label": "Palmanova", "value": "C109947"},
                      {"label": "Peguera", "value": "C109948"},
                      {"label": "Petra", "value": "C109949"},
                      {"label": "Pollenca", "value": "C109950"},
                      {"label": "Porreres", "value": "C109951"},
                      {"label": "Port dAlcudia", "value": "C109952"},
                      {"label": "Port de Pollenca", "value": "C109953"},
                      {"label": "Port de Soller", "value": "C109954"},
                      {"label": "Porto Cristo", "value": "C109955"},
                      {"label": "Portocolom", "value": "C109956"},
                      {"label": "Puigpunyent", "value": "C109957"},
                      {"label": "sArenal", "value": "C109958"},
                      {"label": "sa Pobla", "value": "C109959"},
                      {"label": "Sant Antoni de Portmany", "value": "C109960"},
                      {"label": "Sant Francesc de Formentera",
                       "value": "C109961"},
                      {"label": "Sant Joan", "value": "C109962"},
                      {"label": "Sant Joan de Labritja", "value": "C109963"},
                      {"label": "Sant Josep de sa Talaia", "value": "C109964"},
                      {"label": "Sant Llorenc des Cardassar",
                       "value": "C109965"},
                      {"label": "Sant Lluis", "value": "C109966"},
                      {"label": "Santa Eugenia", "value": "C109967"},
                      {"label": "Santa Eularia des Riu", "value": "C109968"},
                      {"label": "Santa Margalida", "value": "C109969"},
                      {"label": "Santa Ponsa", "value": "C109970"},
                      {"label": "Santanyi", "value": "C109971"},
                      {"label": "Selva", "value": "C109972"},
                      {"label": "Sencelles", "value": "C109973"},
                      {"label": "Ses Salines", "value": "C109974"},
                      {"label": "Sineu", "value": "C109975"},
                      {"label": "Soller", "value": "C109976"},
                      {"label": "Son Ferrer", "value": "C109977"},
                      {"label": "Son Servera", "value": "C109978"},
                      {"label": "Valldemossa", "value": "C109979"},
                      {"label": "Vilafranca de Bonany", "value": "C109980"}],
 "Bali": [{"label": "Amlapura", "value": "C49170"},
          {"label": "Amlapura city", "value": "C49171"},
          {"label": "Banjar", "value": "C49172"},
          {"label": "Banjar Wangsian", "value": "C49173"},
          {"label": "Bedugul", "value": "C49174"},
          {"label": "Denpasar", "value": "C49175"},
          {"label": "Jimbaran", "value": "C49176"},
          {"label": "Kabupaten Badung", "value": "C49177"},
          {"label": "Kabupaten Bangli", "value": "C49178"},
          {"label": "Kabupaten Buleleng", "value": "C49179"},
          {"label": "Kabupaten Gianyar", "value": "C49180"},
          {"label": "Kabupaten Jembrana", "value": "C49181"},
          {"label": "Kabupaten Karang Asem", "value": "C49182"},
          {"label": "Kabupaten Klungkung", "value": "C49183"},
          {"label": "Kabupaten Tabanan", "value": "C49184"},
          {"label": "Klungkung", "value": "C49185"},
          {"label": "Kota Denpasar", "value": "C49186"},
          {"label": "Kuta", "value": "C49187"},
          {"label": "Legian", "value": "C49188"},
          {"label": "Lovina", "value": "C49189"},
          {"label": "Munduk", "value": "C49190"},
          {"label": "Negara", "value": "C49191"},
          {"label": "Nusa Dua", "value": "C49192"},
          {"label": "Seririt", "value": "C49193"},
          {"label": "Singaraja", "value": "C49194"},
          {"label": "Tabanan", "value": "C49195"},
          {"label": "Ubud", "value": "C49196"}],
 "Balikesir": [{"label": "Altieylul Ilcesi", "value": "C119996"},
               {"label": "Altinoluk", "value": "C119997"},
               {"label": "Ayvalik", "value": "C119998"},
               {"label": "Balikesir", "value": "C119999"},
               {"label": "Balya", "value": "C120000"},
               {"label": "Bandirma", "value": "C120001"},
               {"label": "Bigadic", "value": "C120002"},
               {"label": "Burhaniye", "value": "C120003"},
               {"label": "Dursunbey", "value": "C120004"},
               {"label": "Edremit", "value": "C120005"},
               {"label": "Erdek", "value": "C120006"},
               {"label": "Gomec", "value": "C120007"},
               {"label": "Gonen", "value": "C120008"},
               {"label": "Havran", "value": "C120009"},
               {"label": "Ivrindi", "value": "C120010"},
               {"label": "Kepsut", "value": "C120011"},
               {"label": "Manyas", "value": "C120012"},
               {"label": "Marmara", "value": "C120013"},
               {"label": "Savastepe", "value": "C120014"},
               {"label": "Sindirgi", "value": "C120015"},
               {"label": "Susurluk", "value": "C120016"}],
 "Balkan Region": [{"label": "Balkanabat", "value": "C121168"},
                   {"label": "Bereket", "value": "C121169"},
                   {"label": "Gumdag", "value": "C121170"},
                   {"label": "Magtymguly", "value": "C121171"},
                   {"label": "Serdar", "value": "C121172"},
                   {"label": "Turkmenbasy", "value": "C121173"}],
 "Balkh": [{"label": "Balkh", "value": "C13"},
           {"label": "Dowlatabad", "value": "C14"},
           {"label": "Khulm", "value": "C15"},
           {"label": "Lab-Sar", "value": "C16"},
           {"label": "Mazar-e Sharif", "value": "C17"},
           {"label": "Qarchi Gak", "value": "C18"}],
 "Balochistan": [{"label": "Alik Ghund", "value": "C79319"},
                 {"label": "Awaran District", "value": "C79320"},
                 {"label": "Barkhan", "value": "C79321"},
                 {"label": "Barkhan District", "value": "C79322"},
                 {"label": "Bela", "value": "C79323"},
                 {"label": "Bhag", "value": "C79324"},
                 {"label": "Chagai District", "value": "C79325"},
                 {"label": "Chaman", "value": "C79326"},
                 {"label": "Chowki Jamali", "value": "C79327"},
                 {"label": "Dadhar", "value": "C79328"},
                 {"label": "Dalbandin", "value": "C79329"},
                 {"label": "Dera Bugti", "value": "C79330"},
                 {"label": "Dera Bugti District", "value": "C79331"},
                 {"label": "Duki", "value": "C79332"},
                 {"label": "Gadani", "value": "C79333"},
                 {"label": "Garhi Khairo", "value": "C79334"},
                 {"label": "Gwadar", "value": "C79335"},
                 {"label": "Harnai", "value": "C79336"},
                 {"label": "Jafarabad District", "value": "C79337"},
                 {"label": "Jhal Magsi District", "value": "C79338"},
                 {"label": "Jiwani", "value": "C79339"},
                 {"label": "Kalat", "value": "C79340"},
                 {"label": "Kalat District", "value": "C79341"},
                 {"label": "Khadan Khak", "value": "C79342"},
                 {"label": "Kharan", "value": "C79343"},
                 {"label": "Kharan District", "value": "C79344"},
                 {"label": "Khuzdar", "value": "C79345"},
                 {"label": "Khuzdar District", "value": "C79346"},
                 {"label": "Kohlu", "value": "C79347"},
                 {"label": "Kot Malik Barkhurdar", "value": "C79348"},
                 {"label": "Lasbela District", "value": "C79349"},
                 {"label": "Loralai", "value": "C79350"},
                 {"label": "Loralai District", "value": "C79351"},
                 {"label": "Mach", "value": "C79352"},
                 {"label": "Mastung", "value": "C79353"},
                 {"label": "Mastung District", "value": "C79354"},
                 {"label": "Mehrabpur", "value": "C79355"},
                 {"label": "Musa Khel District", "value": "C79356"},
                 {"label": "Nasirabad District", "value": "C79357"},
                 {"label": "Nushki", "value": "C79358"},
                 {"label": "Ormara", "value": "C79359"},
                 {"label": "Panjgur District", "value": "C79360"},
                 {"label": "Pasni", "value": "C79361"},
                 {"label": "Pishin", "value": "C79362"},
                 {"label": "Qila Saifullah District", "value": "C79363"},
                 {"label": "Quetta", "value": "C79364"},
                 {"label": "Quetta District", "value": "C79365"},
                 {"label": "Sibi", "value": "C79366"},
                 {"label": "Sohbatpur", "value": "C79367"},
                 {"label": "Surab", "value": "C79368"},
                 {"label": "Turbat", "value": "C79369"},
                 {"label": "Usta Muhammad", "value": "C79370"},
                 {"label": "Uthal", "value": "C79371"},
                 {"label": "Zhob", "value": "C79372"},
                 {"label": "Zhob District", "value": "C79373"},
                 {"label": "Ziarat", "value": "C79374"},
                 {"label": "Ziarat District", "value": "C79375"}],
 "Balqa Governorate": [{"label": "Al Karamah", "value": "C64211"},
                       {"label": "As Salt", "value": "C64212"},
                       {"label": "Yarqa", "value": "C64213"}],
 "Balti Municipality": [{"label": "Balti", "value": "C75486"}],
 "Baltinava Municipality": [{"label": "Baltinava", "value": "C64851"}],
 "Balvi Municipality": [{"label": "Balvi", "value": "C64852"}],
 "Balzan": [{"label": "Balzan", "value": "C65870"}],
 "Balzers": [{"label": "Balzers", "value": "C65071"}],
 "Bamako": [{"label": "Bamako", "value": "C65822"}],
 "Bamingui-Bangoran Prefecture": [{"label": "Bamingui", "value": "C17808"},
                                  {"label": "Ndele", "value": "C17809"}],
 "Bamyan": [{"label": "Bamyan", "value": "C19"},
            {"label": "Panjab", "value": "C20"}],
 "Banaadir": [{"label": "Mogadishu", "value": "C107884"}],
 "Bangkok": [{"label": "Bang Bon", "value": "C118383"},
             {"label": "Bang Kapi", "value": "C118384"},
             {"label": "Bang Khae", "value": "C118385"},
             {"label": "Bang Khen", "value": "C118386"},
             {"label": "Bang Kho laen", "value": "C118387"},
             {"label": "Bang Khun thain", "value": "C118388"},
             {"label": "Bang Na", "value": "C118389"},
             {"label": "Bang Phlat", "value": "C118390"},
             {"label": "Bang Rak", "value": "C118391"},
             {"label": "Bang Sue", "value": "C118392"},
             {"label": "Bangkok", "value": "C118393"},
             {"label": "Bangkok Noi", "value": "C118394"},
             {"label": "Bangkok Yai", "value": "C118395"},
             {"label": "Bueng Kum", "value": "C118396"},
             {"label": "Chatu Chak", "value": "C118397"},
             {"label": "Chom Thong", "value": "C118398"},
             {"label": "Din Daeng", "value": "C118399"},
             {"label": "Don Mueang", "value": "C118400"},
             {"label": "Dusit", "value": "C118401"},
             {"label": "Huai Khwang", "value": "C118402"},
             {"label": "Khan Na Yao", "value": "C118403"},
             {"label": "Khlong Sam Wa", "value": "C118404"},
             {"label": "Khlong San", "value": "C118405"},
             {"label": "Khlong Toei", "value": "C118406"},
             {"label": "Lak Si", "value": "C118407"},
             {"label": "Lat Krabang", "value": "C118408"},
             {"label": "Lat Phrao", "value": "C118409"},
             {"label": "Min Buri", "value": "C118410"},
             {"label": "Nong Chok", "value": "C118411"},
             {"label": "Nong Khaem", "value": "C118412"},
             {"label": "Parthum Wan", "value": "C118413"},
             {"label": "Phasi Charoen", "value": "C118414"},
             {"label": "Phaya Thai", "value": "C118415"},
             {"label": "Phra Khanong", "value": "C118416"},
             {"label": "Phra Nakhon", "value": "C118417"},
             {"label": "Pom Prap Sattru Phai", "value": "C118418"},
             {"label": "Pra Wet", "value": "C118419"},
             {"label": "Rat Burana", "value": "C118420"},
             {"label": "Ratchathewi", "value": "C118421"},
             {"label": "Sai Mai", "value": "C118422"},
             {"label": "Samphanthawong", "value": "C118423"},
             {"label": "Saphan Sung", "value": "C118424"},
             {"label": "Sathon", "value": "C118425"},
             {"label": "Suanluang", "value": "C118426"},
             {"label": "Taling Chan", "value": "C118427"},
             {"label": "Thawi Watthana", "value": "C118428"},
             {"label": "Thon buri", "value": "C118429"},
             {"label": "Thung khru", "value": "C118430"},
             {"label": "Vadhana", "value": "C118431"},
             {"label": "Wang Thong Lang", "value": "C118432"},
             {"label": "Yan na wa", "value": "C118433"}],
 "Bangui": [{"label": "Bangui", "value": "C17810"}],
 "Banjul": [{"label": "Bakau", "value": "C34149"},
            {"label": "Banjul", "value": "C34150"},
            {"label": "Kombo Saint Mary District", "value": "C34151"},
            {"label": "Serekunda", "value": "C34152"}],
 "Banska Bystrica Region": [{"label": "Banska Bystrica", "value": "C107329"},
                            {"label": "Banska Stiavnica", "value": "C107330"},
                            {"label": "Brezno", "value": "C107331"},
                            {"label": "Cierny Balog", "value": "C107332"},
                            {"label": "Detva", "value": "C107333"},
                            {"label": "Dudince", "value": "C107334"},
                            {"label": "Filakovo", "value": "C107335"},
                            {"label": "Hrinova", "value": "C107336"},
                            {"label": "Hrochot,Slovakia", "value": "C107337"},
                            {"label": "Kovacova", "value": "C107338"},
                            {"label": "Kremnica", "value": "C107339"},
                            {"label": "Krupina", "value": "C107340"},
                            {"label": "Lucenec", "value": "C107341"},
                            {"label": "Nova Bana", "value": "C107342"},
                            {"label": "Okres Banska Bystrica",
                             "value": "C107343"},
                            {"label": "Okres Banska Stiavnica",
                             "value": "C107344"},
                            {"label": "Okres Brezno", "value": "C107345"},
                            {"label": "Okres Detva", "value": "C107346"},
                            {"label": "Okres Krupina", "value": "C107347"},
                            {"label": "Okres Lucenec", "value": "C107348"},
                            {"label": "Okres Poltar", "value": "C107349"},
                            {"label": "Okres Revuca", "value": "C107350"},
                            {"label": "Okres Velky Krtis", "value": "C107351"},
                            {"label": "Okres Zarnovica", "value": "C107352"},
                            {"label": "Okres Ziar nad Hronom",
                             "value": "C107353"},
                            {"label": "Okres Zvolen", "value": "C107354"},
                            {"label": "Poltar", "value": "C107355"},
                            {"label": "Revuca", "value": "C107356"},
                            {"label": "Rimavska Sobota", "value": "C107357"},
                            {"label": "Svaty Anton", "value": "C107358"},
                            {"label": "Tisovec", "value": "C107359"},
                            {"label": "Velky Krtis", "value": "C107360"},
                            {"label": "Zarnovica", "value": "C107361"},
                            {"label": "Ziar nad Hronom", "value": "C107362"},
                            {"label": "Zvolen", "value": "C107363"}],
 "Banteay Meanchey": [{"label": "Mongkol Borei", "value": "C16459"},
                      {"label": "Paoy Paet", "value": "C16460"},
                      {"label": "Sisophon", "value": "C16461"},
                      {"label": "Srok Malai", "value": "C16462"},
                      {"label": "Srok Svay Chek", "value": "C16463"}],
 "Banten": [{"label": "Curug", "value": "C49197"},
            {"label": "Kabupaten Lebak", "value": "C49198"},
            {"label": "Kabupaten Pandeglang", "value": "C49199"},
            {"label": "Kabupaten Serang", "value": "C49200"},
            {"label": "Kabupaten Tangerang", "value": "C49201"},
            {"label": "Kota Cilegon", "value": "C49202"},
            {"label": "Kota Serang", "value": "C49203"},
            {"label": "Kota Tangerang", "value": "C49204"},
            {"label": "Kota Tangerang Selatan", "value": "C49205"},
            {"label": "Labuan", "value": "C49206"},
            {"label": "Pandeglang", "value": "C49207"},
            {"label": "Rangkasbitung", "value": "C49208"},
            {"label": "Serang", "value": "C49209"},
            {"label": "South Tangerang", "value": "C49210"},
            {"label": "Tangerang", "value": "C49211"}],
 "Baoruco Province": [{"label": "El Palmar", "value": "C23847"},
                      {"label": "Galvan", "value": "C23848"},
                      {"label": "La Uvilla", "value": "C23849"},
                      {"label": "Los Rios", "value": "C23850"},
                      {"label": "Neiba", "value": "C23851"},
                      {"label": "Tamayo", "value": "C23852"},
                      {"label": "Villa Jaragua", "value": "C23853"}],
 "Bar Municipality": [{"label": "Bar", "value": "C75595"},
                      {"label": "Stari Bar", "value": "C75596"},
                      {"label": "Susanj", "value": "C75597"},
                      {"label": "Sutomore", "value": "C75598"}],
 "Barahona Province": [{"label": "Cabral", "value": "C23854"},
                       {"label": "Cachon", "value": "C23855"},
                       {"label": "Canoa", "value": "C23856"},
                       {"label": "El Penon", "value": "C23857"},
                       {"label": "Enriquillo", "value": "C23858"},
                       {"label": "Fundacion", "value": "C23859"},
                       {"label": "Jaquimeyes", "value": "C23860"},
                       {"label": "La Cienaga", "value": "C23861"},
                       {"label": "Las Salinas", "value": "C23862"},
                       {"label": "Paraiso", "value": "C23863"},
                       {"label": "Pescaderia", "value": "C23864"},
                       {"label": "Polo", "value": "C23865"},
                       {"label": "Santa Cruz de Barahona", "value": "C23866"},
                       {"label": "Vicente Noble", "value": "C23867"}],
 "Baranya": [{"label": "Beremend", "value": "C43823"},
             {"label": "Boly", "value": "C43824"},
             {"label": "Bolyi Jaras", "value": "C43825"},
             {"label": "Bukkosd", "value": "C43826"},
             {"label": "Dunaszekcso", "value": "C43827"},
             {"label": "Harkany", "value": "C43828"},
             {"label": "Hegyhati Jaras", "value": "C43829"},
             {"label": "Hidas", "value": "C43830"},
             {"label": "Hosszuheteny", "value": "C43831"},
             {"label": "Komlo", "value": "C43832"},
             {"label": "Komloi Jaras", "value": "C43833"},
             {"label": "Kozarmisleny", "value": "C43834"},
             {"label": "Lanycsok", "value": "C43835"},
             {"label": "Magocs", "value": "C43836"},
             {"label": "Mecseknadasd", "value": "C43837"},
             {"label": "Mohacs", "value": "C43838"},
             {"label": "Mohacsi Jaras", "value": "C43839"},
             {"label": "Pecs", "value": "C43840"},
             {"label": "Pecsi Jaras", "value": "C43841"},
             {"label": "Pecsvarad", "value": "C43842"},
             {"label": "Pecsvaradi Jaras", "value": "C43843"},
             {"label": "Pellerd", "value": "C43844"},
             {"label": "Sasd", "value": "C43845"},
             {"label": "Sellye", "value": "C43846"},
             {"label": "Sellyei Jaras", "value": "C43847"},
             {"label": "Siklos", "value": "C43848"},
             {"label": "Siklosi Jaras", "value": "C43849"},
             {"label": "Szaszvar", "value": "C43850"},
             {"label": "Szentlorinc", "value": "C43851"},
             {"label": "Szentlorinci Jaras", "value": "C43852"},
             {"label": "Szigetvar", "value": "C43853"},
             {"label": "Szigetvari Jaras", "value": "C43854"},
             {"label": "Vajszlo", "value": "C43855"},
             {"label": "Villany", "value": "C43856"}],
 "Barbuda": [{"label": "Codrington", "value": "C574"}],
 "Barda District": [{"label": "Barda", "value": "C8415"},
                    {"label": "Samuxlu", "value": "C8416"}],
 "Bari": [{"label": "Bandarbeyla", "value": "C107885"},
          {"label": "Bargaal", "value": "C107886"},
          {"label": "Bereeda", "value": "C107887"},
          {"label": "Bosaso", "value": "C107888"},
          {"label": "Caluula", "value": "C107889"},
          {"label": "Iskushuban", "value": "C107890"},
          {"label": "Qandala", "value": "C107891"}],
 "Barima-Waini": [{"label": "Mabaruma", "value": "C43065"}],
 "Barinas": [{"label": "Alto Barinas", "value": "C147149"},
             {"label": "Barinas", "value": "C147150"},
             {"label": "Barinitas", "value": "C147151"},
             {"label": "Municipio Barinas", "value": "C147152"}],
 "Baringo": [{"label": "Baringo", "value": "C64537"},
             {"label": "Eldama Ravine", "value": "C64538"},
             {"label": "Kabarnet", "value": "C64539"}],
 "Barisal District": [{"label": "Barguna", "value": "C8554"},
                      {"label": "Barisal", "value": "C8555"},
                      {"label": "Bhandaria", "value": "C8556"},
                      {"label": "Bhola", "value": "C8557"},
                      {"label": "Burhanuddin", "value": "C8558"},
                      {"label": "Gaurnadi", "value": "C8559"},
                      {"label": "Jhalokati", "value": "C8560"},
                      {"label": "Lalmohan", "value": "C8561"},
                      {"label": "Mathba", "value": "C8562"},
                      {"label": "Mehendiganj", "value": "C8563"},
                      {"label": "Nalchiti", "value": "C8564"},
                      {"label": "Patuakhali", "value": "C8565"},
                      {"label": "Pirojpur", "value": "C8566"}],
 "Bartin": [{"label": "Amasra", "value": "C120017"},
            {"label": "Bartin", "value": "C120018"},
            {"label": "Kurucasile", "value": "C120019"},
            {"label": "Ulus Ilcesi", "value": "C120020"}],
 "Bas-Sassandra District": [{"label": "Gbokle", "value": "C20926"},
                            {"label": "Nawa", "value": "C20927"},
                            {"label": "San-Pedro", "value": "C20928"},
                            {"label": "Sassandra", "value": "C20929"},
                            {"label": "Tabou", "value": "C20930"}],
 "Bas-Uele": [{"label": "Aketi", "value": "C23308"},
              {"label": "Bondo", "value": "C23309"},
              {"label": "Buta", "value": "C23310"}],
 "Basarabeasca District": [{"label": "Basarabeasca", "value": "C75487"}],
 "Basel-Land": [{"label": "Aesch", "value": "C116444"},
                {"label": "Allschwil", "value": "C116445"},
                {"label": "Arisdorf", "value": "C116446"},
                {"label": "Arlesheim", "value": "C116447"},
                {"label": "Bezirk Arlesheim", "value": "C116448"},
                {"label": "Bezirk Laufen", "value": "C116449"},
                {"label": "Bezirk Liestal", "value": "C116450"},
                {"label": "Bezirk Sissach", "value": "C116451"},
                {"label": "Bezirk Waldenburg", "value": "C116452"},
                {"label": "Binningen", "value": "C116453"},
                {"label": "Birsfelden", "value": "C116454"},
                {"label": "Bottmingen", "value": "C116455"},
                {"label": "Brislach", "value": "C116456"},
                {"label": "Bubendorf", "value": "C116457"},
                {"label": "Buus", "value": "C116458"},
                {"label": "Diegten", "value": "C116459"},
                {"label": "Ettingen", "value": "C116460"},
                {"label": "Frenkendorf", "value": "C116461"},
                {"label": "Fullinsdorf", "value": "C116462"},
                {"label": "Gelterkinden", "value": "C116463"},
                {"label": "Grellingen", "value": "C116464"},
                {"label": "Holstein", "value": "C116465"},
                {"label": "Itingen", "value": "C116466"},
                {"label": "Langenbruck", "value": "C116467"},
                {"label": "Laufelfingen", "value": "C116468"},
                {"label": "Laufen", "value": "C116469"},
                {"label": "Lausen", "value": "C116470"},
                {"label": "Liesberg", "value": "C116471"},
                {"label": "Liestal", "value": "C116472"},
                {"label": "Munchenstein", "value": "C116473"},
                {"label": "Muttenz", "value": "C116474"},
                {"label": "Oberdorf", "value": "C116475"},
                {"label": "Oberwil", "value": "C116476"},
                {"label": "Ormalingen", "value": "C116477"},
                {"label": "Pfeffingen", "value": "C116478"},
                {"label": "Pratteln", "value": "C116479"},
                {"label": "Reigoldswil", "value": "C116480"},
                {"label": "Roschenz", "value": "C116481"},
                {"label": "Schonenbuch", "value": "C116482"},
                {"label": "Seltisberg", "value": "C116483"},
                {"label": "Sissach", "value": "C116484"},
                {"label": "Therwil", "value": "C116485"},
                {"label": "Wahlen", "value": "C116486"},
                {"label": "Waldenburg", "value": "C116487"},
                {"label": "Zunzgen", "value": "C116488"},
                {"label": "Zwingen", "value": "C116489"}],
 "Basel-Stadt": [{"label": "Basel", "value": "C116490"},
                 {"label": "Bettingen", "value": "C116491"},
                 {"label": "Riehen", "value": "C116492"}],
 "Basilicata": [{"label": "Abriola", "value": "C52486"},
                {"label": "Accettura", "value": "C52487"},
                {"label": "Acerenza", "value": "C52488"},
                {"label": "Albano di Lucania", "value": "C52489"},
                {"label": "Aliano", "value": "C52490"},
                {"label": "Anzi", "value": "C52491"},
                {"label": "Armento", "value": "C52492"},
                {"label": "Atella", "value": "C52493"},
                {"label": "Avigliano", "value": "C52494"},
                {"label": "Balvano", "value": "C52495"},
                {"label": "Banzi", "value": "C52496"},
                {"label": "Baragiano", "value": "C52497"},
                {"label": "Barile", "value": "C52498"},
                {"label": "Bella", "value": "C52499"},
                {"label": "Bernalda", "value": "C52500"},
                {"label": "Brienza", "value": "C52501"},
                {"label": "Brindisi Montagna", "value": "C52502"},
                {"label": "Calciano", "value": "C52503"},
                {"label": "Calvello", "value": "C52504"},
                {"label": "Calvera", "value": "C52505"},
                {"label": "Campomaggiore", "value": "C52506"},
                {"label": "Cancellara", "value": "C52507"},
                {"label": "Carbone", "value": "C52508"},
                {"label": "Castelgrande", "value": "C52509"},
                {"label": "Castelluccio Inferiore", "value": "C52510"},
                {"label": "Castelluccio Superiore", "value": "C52511"},
                {"label": "Castelmezzano", "value": "C52512"},
                {"label": "Castelsaraceno", "value": "C52513"},
                {"label": "Castronuovo di SantAndrea", "value": "C52514"},
                {"label": "Cersosimo", "value": "C52515"},
                {"label": "Chiaromonte", "value": "C52516"},
                {"label": "Cirigliano", "value": "C52517"},
                {"label": "Colobraro", "value": "C52518"},
                {"label": "Corleto Perticara", "value": "C52519"},
                {"label": "Craco-SantAngelo", "value": "C52520"},
                {"label": "Episcopia", "value": "C52521"},
                {"label": "Fardella", "value": "C52522"},
                {"label": "Ferrandina", "value": "C52523"},
                {"label": "Filiano", "value": "C52524"},
                {"label": "Forenza", "value": "C52525"},
                {"label": "Francavilla in Sinni", "value": "C52526"},
                {"label": "Gallicchio", "value": "C52527"},
                {"label": "Garaguso", "value": "C52528"},
                {"label": "Genzano di Lucania", "value": "C52529"},
                {"label": "Ginestra", "value": "C52530"},
                {"label": "Gorgoglione", "value": "C52531"},
                {"label": "Grassano", "value": "C52532"},
                {"label": "Grottole", "value": "C52533"},
                {"label": "Grumento Nova", "value": "C52534"},
                {"label": "Guardia Perticara", "value": "C52535"},
                {"label": "Irsina", "value": "C52536"},
                {"label": "La Martella", "value": "C52537"},
                {"label": "Lagonegro", "value": "C52538"},
                {"label": "Latronico", "value": "C52539"},
                {"label": "Laurenzana", "value": "C52540"},
                {"label": "Lauria", "value": "C52541"},
                {"label": "Lavello", "value": "C52542"},
                {"label": "Lido", "value": "C52543"},
                {"label": "Maratea", "value": "C52544"},
                {"label": "Marconia", "value": "C52545"},
                {"label": "Marsico Nuovo", "value": "C52546"},
                {"label": "Marsicovetere", "value": "C52547"},
                {"label": "Maschito", "value": "C52548"},
                {"label": "Matera", "value": "C52549"},
                {"label": "Melfi", "value": "C52550"},
                {"label": "Miglionico", "value": "C52551"},
                {"label": "Missanello", "value": "C52552"},
                {"label": "Moliterno", "value": "C52553"},
                {"label": "Montalbano Jonico", "value": "C52554"},
                {"label": "Montemilone", "value": "C52555"},
                {"label": "Montemurro", "value": "C52556"},
                {"label": "Montescaglioso", "value": "C52557"},
                {"label": "Muro Lucano", "value": "C52558"},
                {"label": "Nemoli", "value": "C52559"},
                {"label": "Noepoli", "value": "C52560"},
                {"label": "Nova Siri", "value": "C52561"},
                {"label": "Nova Siri Scalo", "value": "C52562"},
                {"label": "Oliveto Lucano", "value": "C52563"},
                {"label": "Oppido Lucano", "value": "C52564"},
                {"label": "Palazzo San Gervasio", "value": "C52565"},
                {"label": "Paterno", "value": "C52566"},
                {"label": "Pescopagano", "value": "C52567"},
                {"label": "Picerno", "value": "C52568"},
                {"label": "Pietragalla", "value": "C52569"},
                {"label": "Pietrapertosa", "value": "C52570"},
                {"label": "Pignola", "value": "C52571"},
                {"label": "Pisticci", "value": "C52572"},
                {"label": "Policoro", "value": "C52573"},
                {"label": "Pomarico", "value": "C52574"},
                {"label": "Potenza", "value": "C52575"},
                {"label": "Provincia di Matera", "value": "C52576"},
                {"label": "Provincia di Potenza", "value": "C52577"},
                {"label": "Rapolla", "value": "C52578"},
                {"label": "Rapone", "value": "C52579"},
                {"label": "Rionero in Vulture", "value": "C52580"},
                {"label": "Ripacandida", "value": "C52581"},
                {"label": "Rivello", "value": "C52582"},
                {"label": "Roccanova", "value": "C52583"},
                {"label": "Rotonda", "value": "C52584"},
                {"label": "Rotondella", "value": "C52585"},
                {"label": "Ruoti", "value": "C52586"},
                {"label": "Ruvo del Monte", "value": "C52587"},
                {"label": "Salandra", "value": "C52588"},
                {"label": "San Brancato", "value": "C52589"},
                {"label": "San Chirico Nuovo", "value": "C52590"},
                {"label": "San Chirico Raparo", "value": "C52591"},
                {"label": "San Costantino Albanese", "value": "C52592"},
                {"label": "San Fele", "value": "C52593"},
                {"label": "San Giorgio Lucano", "value": "C52594"},
                {"label": "San Martino dAgri", "value": "C52595"},
                {"label": "San Mauro Forte", "value": "C52596"},
                {"label": "San Paolo Albanese", "value": "C52597"},
                {"label": "San Severino Lucano", "value": "C52598"},
                {"label": "SantAngelo le Fratte", "value": "C52599"},
                {"label": "SantArcangelo", "value": "C52600"},
                {"label": "Sarconi", "value": "C52601"},
                {"label": "Sasso di Castalda", "value": "C52602"},
                {"label": "Satriano di Lucania", "value": "C52603"},
                {"label": "Savoia di Lucania", "value": "C52604"},
                {"label": "Scalo di Baragiano", "value": "C52605"},
                {"label": "Scanzano Jonico", "value": "C52606"},
                {"label": "Senise", "value": "C52607"},
                {"label": "Spinoso", "value": "C52608"},
                {"label": "Stigliano", "value": "C52609"},
                {"label": "Teana", "value": "C52610"},
                {"label": "Terranova di Pollino", "value": "C52611"},
                {"label": "Tito", "value": "C52612"},
                {"label": "Tolve", "value": "C52613"},
                {"label": "Tramutola", "value": "C52614"},
                {"label": "Trecchina", "value": "C52615"},
                {"label": "Tricarico", "value": "C52616"},
                {"label": "Trivigno", "value": "C52617"},
                {"label": "Tursi", "value": "C52618"},
                {"label": "Vaglio Basilicata", "value": "C52619"},
                {"label": "Valsinni", "value": "C52620"},
                {"label": "Venosa", "value": "C52621"},
                {"label": "Vietri di Potenza", "value": "C52622"},
                {"label": "Viggianello", "value": "C52623"},
                {"label": "Viggiano", "value": "C52624"},
                {"label": "Villa dAgri", "value": "C52625"},
                {"label": "Zona 179", "value": "C52626"}],
 "Basque Country": [{"label": "Aduna", "value": "C109981"},
                    {"label": "Agurain  Salvatierra", "value": "C109982"},
                    {"label": "Aia", "value": "C109983"},
                    {"label": "Aizarnazabal", "value": "C109984"},
                    {"label": "Albiztur", "value": "C109985"},
                    {"label": "Algorta", "value": "C109986"},
                    {"label": "Alkiza", "value": "C109987"},
                    {"label": "Alonsotegi", "value": "C109988"},
                    {"label": "Amezketa", "value": "C109989"},
                    {"label": "Amorebieta", "value": "C109990"},
                    {"label": "Amoroto", "value": "C109991"},
                    {"label": "Amurrio", "value": "C109992"},
                    {"label": "Andoain", "value": "C109993"},
                    {"label": "Anoeta", "value": "C109994"},
                    {"label": "Antzuola", "value": "C109995"},
                    {"label": "Araba  alava", "value": "C109996"},
                    {"label": "Araia", "value": "C109997"},
                    {"label": "Arama", "value": "C109998"},
                    {"label": "Aretxabaleta", "value": "C109999"},
                    {"label": "Arminon", "value": "C110000"},
                    {"label": "Arrasate  Mondragon", "value": "C110001"},
                    {"label": "Arrigorriaga", "value": "C110002"},
                    {"label": "Asteasu", "value": "C110003"},
                    {"label": "Astigarraga", "value": "C110004"},
                    {"label": "Ataun", "value": "C110005"},
                    {"label": "Aulesti", "value": "C110006"},
                    {"label": "Avellaneda", "value": "C110007"},
                    {"label": "Axpe de Busturia", "value": "C110008"},
                    {"label": "Azkoitia", "value": "C110009"},
                    {"label": "Azpeitia", "value": "C110010"},
                    {"label": "Bakio", "value": "C110011"},
                    {"label": "Baliarrain", "value": "C110012"},
                    {"label": "Barakaldo", "value": "C110013"},
                    {"label": "Basauri", "value": "C110014"},
                    {"label": "Bastida  Labastida", "value": "C110015"},
                    {"label": "Beasain", "value": "C110016"},
                    {"label": "Beizama", "value": "C110017"},
                    {"label": "Berango", "value": "C110018"},
                    {"label": "Berantevilla", "value": "C110019"},
                    {"label": "Bergara", "value": "C110020"},
                    {"label": "Bermeo", "value": "C110021"},
                    {"label": "Berriatua", "value": "C110022"},
                    {"label": "Berriz", "value": "C110023"},
                    {"label": "Berrobi", "value": "C110024"},
                    {"label": "Bilbao", "value": "C110025"},
                    {"label": "Bizkaia", "value": "C110026"},
                    {"label": "Deba", "value": "C110027"},
                    {"label": "Derio", "value": "C110028"},
                    {"label": "Donostia  San Sebastian", "value": "C110029"},
                    {"label": "Durango", "value": "C110030"},
                    {"label": "Ea", "value": "C110031"},
                    {"label": "Eibar", "value": "C110032"},
                    {"label": "Elciego", "value": "C110033"},
                    {"label": "Elexalde", "value": "C110034"},
                    {"label": "Elgoibar", "value": "C110035"},
                    {"label": "Elorrio", "value": "C110036"},
                    {"label": "Erandio", "value": "C110037"},
                    {"label": "Ermua", "value": "C110038"},
                    {"label": "Errenteria", "value": "C110039"},
                    {"label": "Errigoiti", "value": "C110040"},
                    {"label": "Eskoriatza", "value": "C110041"},
                    {"label": "Eskuernaga  Villabuena de alava",
                     "value": "C110042"},
                    {"label": "Forua", "value": "C110043"},
                    {"label": "Galdakao", "value": "C110044"},
                    {"label": "Gasteiz  Vitoria", "value": "C110045"},
                    {"label": "Gatika", "value": "C110046"},
                    {"label": "Gaztelu", "value": "C110047"},
                    {"label": "Gernika-Lumo", "value": "C110048"},
                    {"label": "Getaria", "value": "C110049"},
                    {"label": "Getxo", "value": "C110050"},
                    {"label": "Gipuzkoa", "value": "C110051"},
                    {"label": "Guenes", "value": "C110052"},
                    {"label": "Hernani", "value": "C110053"},
                    {"label": "Hernialde", "value": "C110054"},
                    {"label": "Hondarribia", "value": "C110055"},
                    {"label": "Ibarrangelu", "value": "C110056"},
                    {"label": "Idiazabal", "value": "C110057"},
                    {"label": "Irun", "value": "C110058"},
                    {"label": "Irura", "value": "C110059"},
                    {"label": "Izurtza", "value": "C110060"},
                    {"label": "Lagran", "value": "C110061"},
                    {"label": "Laguardia", "value": "C110062"},
                    {"label": "Landa", "value": "C110063"},
                    {"label": "Lanestosa", "value": "C110064"},
                    {"label": "Lapuebla de Labarca", "value": "C110065"},
                    {"label": "Larraul", "value": "C110066"},
                    {"label": "Lasarte", "value": "C110067"},
                    {"label": "Laudio  Llodio", "value": "C110068"},
                    {"label": "Leaburu", "value": "C110069"},
                    {"label": "Legorreta", "value": "C110070"},
                    {"label": "Leintz-Gatzaga", "value": "C110071"},
                    {"label": "Leioa", "value": "C110072"},
                    {"label": "Lekeitio", "value": "C110073"},
                    {"label": "Leza", "value": "C110074"},
                    {"label": "Lezama", "value": "C110075"},
                    {"label": "Lezo", "value": "C110076"},
                    {"label": "Lizartza", "value": "C110077"},
                    {"label": "Manaria", "value": "C110078"},
                    {"label": "Markina-Xemein", "value": "C110079"},
                    {"label": "Mendaro", "value": "C110080"},
                    {"label": "Mendexa", "value": "C110081"},
                    {"label": "Moreda Araba  Moreda de alava",
                     "value": "C110082"},
                    {"label": "Mundaka", "value": "C110083"},
                    {"label": "Mungia", "value": "C110084"},
                    {"label": "Murgia", "value": "C110085"},
                    {"label": "Mutiloa", "value": "C110086"},
                    {"label": "Mutriku", "value": "C110087"},
                    {"label": "Navaridas", "value": "C110088"},
                    {"label": "Oion  Oyon", "value": "C110089"},
                    {"label": "Olaberria", "value": "C110090"},
                    {"label": "Onati", "value": "C110091"},
                    {"label": "Ondarroa", "value": "C110092"},
                    {"label": "Ordizia", "value": "C110093"},
                    {"label": "Orendain", "value": "C110094"},
                    {"label": "Orexa", "value": "C110095"},
                    {"label": "Oria", "value": "C110096"},
                    {"label": "Orio", "value": "C110097"},
                    {"label": "Ormaiztegi", "value": "C110098"},
                    {"label": "Ortuella", "value": "C110099"},
                    {"label": "Pasaia", "value": "C110100"},
                    {"label": "Plentzia", "value": "C110101"},
                    {"label": "Portugalete", "value": "C110102"},
                    {"label": "Samaniego", "value": "C110103"},
                    {"label": "Santurtzi", "value": "C110104"},
                    {"label": "Santutxu", "value": "C110105"},
                    {"label": "Segura", "value": "C110106"},
                    {"label": "Sestao", "value": "C110107"},
                    {"label": "Sondika", "value": "C110108"},
                    {"label": "Sopela", "value": "C110109"},
                    {"label": "Sopuerta", "value": "C110110"},
                    {"label": "Tolosa", "value": "C110111"},
                    {"label": "Urduna  Orduna", "value": "C110112"},
                    {"label": "Urnieta", "value": "C110113"},
                    {"label": "Usurbil", "value": "C110114"},
                    {"label": "Villabona", "value": "C110115"},
                    {"label": "Villanueva de Valdegovia", "value": "C110116"},
                    {"label": "Zaldibar", "value": "C110117"},
                    {"label": "Zalla", "value": "C110118"},
                    {"label": "Zambrana", "value": "C110119"},
                    {"label": "Zamudio", "value": "C110120"},
                    {"label": "Zaratamo", "value": "C110121"},
                    {"label": "Zarautz", "value": "C110122"},
                    {"label": "Zeanuri", "value": "C110123"},
                    {"label": "Zegama", "value": "C110124"},
                    {"label": "Zestoa", "value": "C110125"},
                    {"label": "Zierbena", "value": "C110126"},
                    {"label": "Zizurkil", "value": "C110127"},
                    {"label": "Zumaia", "value": "C110128"},
                    {"label": "Zumarraga", "value": "C110129"}],
 "Basra Governorate": [{"label": "Al Basrah al Qadimah", "value": "C51156"},
                       {"label": "Al Faw", "value": "C51157"},
                       {"label": "Al Harithah", "value": "C51158"},
                       {"label": "Az Zubayr", "value": "C51159"},
                       {"label": "Basrah", "value": "C51160"},
                       {"label": "Umm Qasr", "value": "C51161"}],
 "Basse-Kotto Prefecture": [{"label": "Alindao", "value": "C17811"},
                            {"label": "Kembe", "value": "C17812"},
                            {"label": "Mobaye", "value": "C17813"}],
 "Bataan": [{"label": "Abaca", "value": "C83057"},
            {"label": "Abis", "value": "C83058"},
            {"label": "Abucayan", "value": "C83059"},
            {"label": "Adlaon", "value": "C83060"},
            {"label": "Agsungot", "value": "C83061"},
            {"label": "Aguining", "value": "C83062"},
            {"label": "Alangilan", "value": "C83063"},
            {"label": "Alangilanan", "value": "C83064"},
            {"label": "Alburquerque", "value": "C83065"},
            {"label": "Alcantara", "value": "C83066"},
            {"label": "Alcoy", "value": "C83067"},
            {"label": "Alegria", "value": "C83068"},
            {"label": "Alicia", "value": "C83069"},
            {"label": "Aloguinsan", "value": "C83070"},
            {"label": "Alpaco", "value": "C83071"},
            {"label": "Amdos", "value": "C83072"},
            {"label": "Amio", "value": "C83073"},
            {"label": "Anda", "value": "C83074"},
            {"label": "Anonang", "value": "C83075"},
            {"label": "Anopog", "value": "C83076"},
            {"label": "Antequera", "value": "C83077"},
            {"label": "Antipolo", "value": "C83078"},
            {"label": "Apas", "value": "C83079"},
            {"label": "Apoya", "value": "C83080"},
            {"label": "Argao", "value": "C83081"},
            {"label": "Asturias", "value": "C83082"},
            {"label": "Atop-atop", "value": "C83083"},
            {"label": "Ayungon", "value": "C83084"},
            {"label": "Azagra", "value": "C83085"},
            {"label": "Bachauan", "value": "C83086"},
            {"label": "Baclayon", "value": "C83087"},
            {"label": "Bacong", "value": "C83088"},
            {"label": "Badian", "value": "C83089"},
            {"label": "Bagacay", "value": "C83090"},
            {"label": "Bagay", "value": "C83091"},
            {"label": "Bagtic", "value": "C83092"},
            {"label": "Bairan", "value": "C83093"},
            {"label": "Bais", "value": "C83094"},
            {"label": "Bal-os", "value": "C83095"},
            {"label": "Balamban", "value": "C83096"},
            {"label": "Balayong", "value": "C83097"},
            {"label": "Balilihan", "value": "C83098"},
            {"label": "Balogo", "value": "C83099"},
            {"label": "Balud", "value": "C83100"},
            {"label": "Banhigan", "value": "C83101"},
            {"label": "Banilad", "value": "C83102"},
            {"label": "Bantayan", "value": "C83103"},
            {"label": "Barili", "value": "C83104"},
            {"label": "Basak", "value": "C83105"},
            {"label": "Basay", "value": "C83106"},
            {"label": "Basdiot", "value": "C83107"},
            {"label": "Bateria", "value": "C83108"},
            {"label": "Batuan", "value": "C83109"},
            {"label": "Baud", "value": "C83110"},
            {"label": "Baugo", "value": "C83111"},
            {"label": "Bayawan", "value": "C83112"},
            {"label": "Becerril", "value": "C83113"},
            {"label": "Biabas", "value": "C83114"},
            {"label": "Biasong", "value": "C83115"},
            {"label": "Bien Unido", "value": "C83116"},
            {"label": "Biking", "value": "C83117"},
            {"label": "Bilar", "value": "C83118"},
            {"label": "Binlod", "value": "C83119"},
            {"label": "Biton", "value": "C83120"},
            {"label": "Bitoon", "value": "C83121"},
            {"label": "Bogo", "value": "C83122"},
            {"label": "Bohol", "value": "C83123"},
            {"label": "Bolisong", "value": "C83124"},
            {"label": "Boljoon", "value": "C83125"},
            {"label": "Bonawon", "value": "C83126"},
            {"label": "Bonbon", "value": "C83127"},
            {"label": "Bood", "value": "C83128"},
            {"label": "Borbon", "value": "C83129"},
            {"label": "Botigues", "value": "C83130"},
            {"label": "Buagsong", "value": "C83131"},
            {"label": "Buanoy", "value": "C83132"},
            {"label": "Buenavista", "value": "C83133"},
            {"label": "Bugas", "value": "C83134"},
            {"label": "Bugsoc", "value": "C83135"},
            {"label": "Bulasa", "value": "C83136"},
            {"label": "Bulod", "value": "C83137"},
            {"label": "Cabalawan", "value": "C83138"},
            {"label": "Cabangahan", "value": "C83139"},
            {"label": "Cabul-an", "value": "C83140"},
            {"label": "Calamba", "value": "C83141"},
            {"label": "Calape", "value": "C83142"},
            {"label": "Calero", "value": "C83143"},
            {"label": "Calidngan", "value": "C83144"},
            {"label": "Calituban", "value": "C83145"},
            {"label": "Calumboyan", "value": "C83146"},
            {"label": "Camambugan", "value": "C83147"},
            {"label": "Cambanay", "value": "C83148"},
            {"label": "Campoyo", "value": "C83149"},
            {"label": "Campusong", "value": "C83150"},
            {"label": "Can-asujan", "value": "C83151"},
            {"label": "Canauay", "value": "C83152"},
            {"label": "Candabong", "value": "C83153"},
            {"label": "Candijay", "value": "C83154"},
            {"label": "Canhaway", "value": "C83155"},
            {"label": "Canjulao", "value": "C83156"},
            {"label": "Canlaon", "value": "C83157"},
            {"label": "Canmaya Diot", "value": "C83158"},
            {"label": "Cansuje", "value": "C83159"},
            {"label": "Cantao-an", "value": "C83160"},
            {"label": "Carcar", "value": "C83161"},
            {"label": "Carmelo", "value": "C83162"},
            {"label": "Carmen", "value": "C83163"},
            {"label": "Casala-an", "value": "C83164"},
            {"label": "Casay", "value": "C83165"},
            {"label": "Catarman", "value": "C83166"},
            {"label": "Caticugan", "value": "C83167"},
            {"label": "Catigbian", "value": "C83168"},
            {"label": "Catmon", "value": "C83169"},
            {"label": "Catmondaan", "value": "C83170"},
            {"label": "Catungawan Sur", "value": "C83171"},
            {"label": "Cawayan", "value": "C83172"},
            {"label": "Cayang", "value": "C83173"},
            {"label": "Cebu City", "value": "C83174"},
            {"label": "Clarin", "value": "C83175"},
            {"label": "Cogan", "value": "C83176"},
            {"label": "Cogon", "value": "C83177"},
            {"label": "Cogon Cruz", "value": "C83178"},
            {"label": "Cogtong", "value": "C83179"},
            {"label": "Colonia", "value": "C83180"},
            {"label": "Compostela", "value": "C83181"},
            {"label": "Consolacion", "value": "C83182"},
            {"label": "Consuelo", "value": "C83183"},
            {"label": "Cordova", "value": "C83184"},
            {"label": "Corella", "value": "C83185"},
            {"label": "Cortes", "value": "C83186"},
            {"label": "Daanbantayan", "value": "C83187"},
            {"label": "Dagohoy", "value": "C83188"},
            {"label": "Dalaguete", "value": "C83189"},
            {"label": "Damolog", "value": "C83190"},
            {"label": "Danao", "value": "C83191"},
            {"label": "Dapitan", "value": "C83192"},
            {"label": "Datagon", "value": "C83193"},
            {"label": "Dauin", "value": "C83194"},
            {"label": "Dauis", "value": "C83195"},
            {"label": "De la Paz", "value": "C83196"},
            {"label": "Dimiao", "value": "C83197"},
            {"label": "Doljo", "value": "C83198"},
            {"label": "Doong", "value": "C83199"},
            {"label": "Duero", "value": "C83200"},
            {"label": "Dumaguete", "value": "C83201"},
            {"label": "Dumanjog", "value": "C83202"},
            {"label": "El Pardo", "value": "C83203"},
            {"label": "Enrique Villanueva", "value": "C83204"},
            {"label": "Esperanza", "value": "C83205"},
            {"label": "Estaca", "value": "C83206"},
            {"label": "Gabi", "value": "C83207"},
            {"label": "Garcia Hernandez", "value": "C83208"},
            {"label": "Giawang", "value": "C83209"},
            {"label": "Ginatilan", "value": "C83210"},
            {"label": "Guadalupe", "value": "C83211"},
            {"label": "Guba", "value": "C83212"},
            {"label": "Guibodangan", "value": "C83213"},
            {"label": "Guihulngan", "value": "C83214"},
            {"label": "Guindarohan", "value": "C83215"},
            {"label": "Guindulman", "value": "C83216"},
            {"label": "Guiwanon", "value": "C83217"},
            {"label": "Hagdan", "value": "C83218"},
            {"label": "Hagnaya", "value": "C83219"},
            {"label": "Hibaiyo", "value": "C83220"},
            {"label": "Hilantagaan", "value": "C83221"},
            {"label": "Hilotongan", "value": "C83222"},
            {"label": "Himensulan", "value": "C83223"},
            {"label": "Hinlayagan Ilaud", "value": "C83224"},
            {"label": "Ilihan", "value": "C83225"},
            {"label": "Inabanga", "value": "C83226"},
            {"label": "Inayagan", "value": "C83227"},
            {"label": "Jaclupan", "value": "C83228"},
            {"label": "Jagna", "value": "C83229"},
            {"label": "Jampang", "value": "C83230"},
            {"label": "Jandayan Norte", "value": "C83231"},
            {"label": "Jantianon", "value": "C83232"},
            {"label": "Japitan", "value": "C83233"},
            {"label": "Jetafe", "value": "C83234"},
            {"label": "Jimalalud", "value": "C83235"},
            {"label": "Jugno", "value": "C83236"},
            {"label": "Kabac", "value": "C83237"},
            {"label": "Kabungahan", "value": "C83238"},
            {"label": "Kandabong", "value": "C83239"},
            {"label": "Kaongkod", "value": "C83240"},
            {"label": "Kauit", "value": "C83241"},
            {"label": "Kotkot", "value": "C83242"},
            {"label": "Kuanos", "value": "C83243"},
            {"label": "La Hacienda", "value": "C83244"},
            {"label": "La Libertad", "value": "C83245"},
            {"label": "Lanao", "value": "C83246"},
            {"label": "Lanas", "value": "C83247"},
            {"label": "Langob", "value": "C83248"},
            {"label": "Langtad", "value": "C83249"},
            {"label": "Lapaz", "value": "C83250"},
            {"label": "Lapu-Lapu City", "value": "C83251"},
            {"label": "Lazi", "value": "C83252"},
            {"label": "Lepanto", "value": "C83253"},
            {"label": "Libertad", "value": "C83254"},
            {"label": "Lila", "value": "C83255"},
            {"label": "Liloan", "value": "C83256"},
            {"label": "Lipayran", "value": "C83257"},
            {"label": "Loay", "value": "C83258"},
            {"label": "Loboc", "value": "C83259"},
            {"label": "Logon", "value": "C83260"},
            {"label": "Lombog", "value": "C83261"},
            {"label": "Loon", "value": "C83262"},
            {"label": "Lugo", "value": "C83263"},
            {"label": "Lunas", "value": "C83264"},
            {"label": "Lut-od", "value": "C83265"},
            {"label": "Maayong Tubig", "value": "C83266"},
            {"label": "Mabinay", "value": "C83267"},
            {"label": "Mabini", "value": "C83268"},
            {"label": "Macaas", "value": "C83269"},
            {"label": "Madridejos", "value": "C83270"},
            {"label": "Magay", "value": "C83271"},
            {"label": "Mahayag", "value": "C83272"},
            {"label": "Mainit", "value": "C83273"},
            {"label": "Malabugas", "value": "C83274"},
            {"label": "Malabuyoc", "value": "C83275"},
            {"label": "Malaiba", "value": "C83276"},
            {"label": "Malbug", "value": "C83277"},
            {"label": "Malhiao", "value": "C83278"},
            {"label": "Malingin", "value": "C83279"},
            {"label": "Maloh", "value": "C83280"},
            {"label": "Malusay", "value": "C83281"},
            {"label": "Malway", "value": "C83282"},
            {"label": "Manalongon", "value": "C83283"},
            {"label": "Mancilang", "value": "C83284"},
            {"label": "Mandaue City", "value": "C83285"},
            {"label": "Maninihon", "value": "C83286"},
            {"label": "Mano", "value": "C83287"},
            {"label": "Mantalongon", "value": "C83288"},
            {"label": "Mantiquil", "value": "C83289"},
            {"label": "Maravilla", "value": "C83290"},
            {"label": "Maribojoc", "value": "C83291"},
            {"label": "Maricaban", "value": "C83292"},
            {"label": "Masaba", "value": "C83293"},
            {"label": "Maya", "value": "C83294"},
            {"label": "Mayabon", "value": "C83295"},
            {"label": "Mayana", "value": "C83296"},
            {"label": "Mayapusi", "value": "C83297"},
            {"label": "McKinley", "value": "C83298"},
            {"label": "Medellin", "value": "C83299"},
            {"label": "Mercedes", "value": "C83300"},
            {"label": "Minglanilla", "value": "C83301"},
            {"label": "Minolos", "value": "C83302"},
            {"label": "Moalboal", "value": "C83303"},
            {"label": "Montaneza", "value": "C83304"},
            {"label": "Naga", "value": "C83305"},
            {"label": "Nagbalaye", "value": "C83306"},
            {"label": "Nahawan", "value": "C83307"},
            {"label": "Nailong", "value": "C83308"},
            {"label": "Nalundan", "value": "C83309"},
            {"label": "Nangka", "value": "C83310"},
            {"label": "Novallas", "value": "C83311"},
            {"label": "Nueva Fuerza", "value": "C83312"},
            {"label": "Nueva Vida Sur", "value": "C83313"},
            {"label": "Nugas", "value": "C83314"},
            {"label": "Obong", "value": "C83315"},
            {"label": "Ocana", "value": "C83316"},
            {"label": "Ocoy", "value": "C83317"},
            {"label": "Okiot", "value": "C83318"},
            {"label": "Oslob", "value": "C83319"},
            {"label": "Owak", "value": "C83320"},
            {"label": "Padre Zamora", "value": "C83321"},
            {"label": "Pajo", "value": "C83322"},
            {"label": "Pamplona", "value": "C83323"},
            {"label": "Panalipan", "value": "C83324"},
            {"label": "Panaytayon", "value": "C83325"},
            {"label": "Pangdan", "value": "C83326"},
            {"label": "Panglao", "value": "C83327"},
            {"label": "Panognawan", "value": "C83328"},
            {"label": "Patao", "value": "C83329"},
            {"label": "Payabon", "value": "C83330"},
            {"label": "Paypay", "value": "C83331"},
            {"label": "Perrelos", "value": "C83332"},
            {"label": "Pilar", "value": "C83333"},
            {"label": "Pinamungahan", "value": "C83334"},
            {"label": "Pinayagan Norte", "value": "C83335"},
            {"label": "Pinokawan", "value": "C83336"},
            {"label": "Pitogo", "value": "C83337"},
            {"label": "Polo", "value": "C83338"},
            {"label": "Poro", "value": "C83339"},
            {"label": "Province of Cebu", "value": "C83340"},
            {"label": "Province of Negros Oriental", "value": "C83341"},
            {"label": "Province of Siquijor", "value": "C83342"},
            {"label": "Putat", "value": "C83343"},
            {"label": "Ronda", "value": "C83344"},
            {"label": "Saavedra", "value": "C83345"},
            {"label": "Sagbayan", "value": "C83346"},
            {"label": "Samboan", "value": "C83347"},
            {"label": "San Agustin", "value": "C83348"},
            {"label": "San Fernando", "value": "C83349"},
            {"label": "San Francisco", "value": "C83350"},
            {"label": "San Isidro", "value": "C83351"},
            {"label": "San Jose", "value": "C83352"},
            {"label": "San Miguel", "value": "C83353"},
            {"label": "San Pascual", "value": "C83354"},
            {"label": "San Remigio", "value": "C83355"},
            {"label": "San Roque", "value": "C83356"},
            {"label": "Sandayong Sur", "value": "C83357"},
            {"label": "Sandolot", "value": "C83358"},
            {"label": "Sangat", "value": "C83359"},
            {"label": "Santa Catalina", "value": "C83360"},
            {"label": "Santa Cruz", "value": "C83361"},
            {"label": "Santa Fe", "value": "C83362"},
            {"label": "Santa Filomena", "value": "C83363"},
            {"label": "Santa Nino", "value": "C83364"},
            {"label": "Santander Poblacion", "value": "C83365"},
            {"label": "Santiago", "value": "C83366"},
            {"label": "Sevilla", "value": "C83367"},
            {"label": "Siaton", "value": "C83368"},
            {"label": "Sibonga", "value": "C83369"},
            {"label": "Sibulan", "value": "C83370"},
            {"label": "Sierra Bullones", "value": "C83371"},
            {"label": "Sikatuna", "value": "C83372"},
            {"label": "Silab", "value": "C83373"},
            {"label": "Sillon", "value": "C83374"},
            {"label": "Simala", "value": "C83375"},
            {"label": "Siquijor", "value": "C83376"},
            {"label": "Sogod", "value": "C83377"},
            {"label": "Songculan", "value": "C83378"},
            {"label": "Sulangan", "value": "C83379"},
            {"label": "Tabalong", "value": "C83380"},
            {"label": "Tabogon", "value": "C83381"},
            {"label": "Tabon", "value": "C83382"},
            {"label": "Tabonok", "value": "C83383"},
            {"label": "Tabuan", "value": "C83384"},
            {"label": "Tabuelan", "value": "C83385"},
            {"label": "Tabunok", "value": "C83386"},
            {"label": "Tagbilaran City", "value": "C83387"},
            {"label": "Tagum Norte", "value": "C83388"},
            {"label": "Tajao", "value": "C83389"},
            {"label": "Talangnan", "value": "C83390"},
            {"label": "Talibon", "value": "C83391"},
            {"label": "Talisay", "value": "C83392"},
            {"label": "Tambalan", "value": "C83393"},
            {"label": "Tambo", "value": "C83394"},
            {"label": "Tambongon", "value": "C83395"},
            {"label": "Tamiso", "value": "C83396"},
            {"label": "Tampocon", "value": "C83397"},
            {"label": "Tandayag", "value": "C83398"},
            {"label": "Tangke", "value": "C83399"},
            {"label": "Tangnan", "value": "C83400"},
            {"label": "Tanjay", "value": "C83401"},
            {"label": "Tapilon", "value": "C83402"},
            {"label": "Tapon", "value": "C83403"},
            {"label": "Tawala", "value": "C83404"},
            {"label": "Tayasan", "value": "C83405"},
            {"label": "Taytayan", "value": "C83406"},
            {"label": "Tayud", "value": "C83407"},
            {"label": "Tibigan", "value": "C83408"},
            {"label": "Tiguib", "value": "C83409"},
            {"label": "Tinaan", "value": "C83410"},
            {"label": "Tinaogan", "value": "C83411"},
            {"label": "Tindog", "value": "C83412"},
            {"label": "Tinubuan", "value": "C83413"},
            {"label": "Tipolo", "value": "C83414"},
            {"label": "Toledo", "value": "C83415"},
            {"label": "Tominhao", "value": "C83416"},
            {"label": "Totolan", "value": "C83417"},
            {"label": "Trinidad", "value": "C83418"},
            {"label": "Tubigagmanoc", "value": "C83419"},
            {"label": "Tubod", "value": "C83420"},
            {"label": "Tubod-dugoan", "value": "C83421"},
            {"label": "Tuburan", "value": "C83422"},
            {"label": "Tudela", "value": "C83423"},
            {"label": "Tutay", "value": "C83424"},
            {"label": "Ubay", "value": "C83425"},
            {"label": "Uling", "value": "C83426"},
            {"label": "Union", "value": "C83427"},
            {"label": "Valencia", "value": "C83428"},
            {"label": "Valle Hermoso", "value": "C83429"},
            {"label": "Vallehermoso", "value": "C83430"},
            {"label": "Zamboanguita", "value": "C83431"}],
 "Batanes": [{"label": "Abuyog", "value": "C83432"},
             {"label": "Alangalang", "value": "C83433"},
             {"label": "Albuera", "value": "C83434"},
             {"label": "Alegria", "value": "C83435"},
             {"label": "Allen", "value": "C83436"},
             {"label": "Almagro", "value": "C83437"},
             {"label": "Almeria", "value": "C83438"},
             {"label": "Alugan", "value": "C83439"},
             {"label": "Anahawan", "value": "C83440"},
             {"label": "Anito", "value": "C83441"},
             {"label": "Arteche", "value": "C83442"},
             {"label": "Babatngon", "value": "C83443"},
             {"label": "Balagtas", "value": "C83444"},
             {"label": "Balagui", "value": "C83445"},
             {"label": "Balangiga", "value": "C83446"},
             {"label": "Balangkayan", "value": "C83447"},
             {"label": "Balinsacayao", "value": "C83448"},
             {"label": "Balocawehay", "value": "C83449"},
             {"label": "Balogo", "value": "C83450"},
             {"label": "Balud", "value": "C83451"},
             {"label": "Bantayan", "value": "C83452"},
             {"label": "Bantiqui", "value": "C83453"},
             {"label": "Baras", "value": "C83454"},
             {"label": "Barugo", "value": "C83455"},
             {"label": "Basey", "value": "C83456"},
             {"label": "Basud", "value": "C83457"},
             {"label": "Bato", "value": "C83458"},
             {"label": "Baybay", "value": "C83459"},
             {"label": "Biliran", "value": "C83460"},
             {"label": "Bilwang", "value": "C83461"},
             {"label": "Biri", "value": "C83462"},
             {"label": "Bislig", "value": "C83463"},
             {"label": "Bitanjuan", "value": "C83464"},
             {"label": "Bobon", "value": "C83465"},
             {"label": "Bontoc", "value": "C83466"},
             {"label": "Borongan", "value": "C83467"},
             {"label": "Bugho", "value": "C83468"},
             {"label": "Bugko", "value": "C83469"},
             {"label": "Bunga", "value": "C83470"},
             {"label": "Burauen", "value": "C83471"},
             {"label": "Butazon", "value": "C83472"},
             {"label": "Cabacungan", "value": "C83473"},
             {"label": "Cabatuan", "value": "C83474"},
             {"label": "Cabay", "value": "C83475"},
             {"label": "Cabodiongan", "value": "C83476"},
             {"label": "Cabucgayan", "value": "C83477"},
             {"label": "Cagamotan", "value": "C83478"},
             {"label": "Caibiran", "value": "C83479"},
             {"label": "Calape", "value": "C83480"},
             {"label": "Calbayog City", "value": "C83481"},
             {"label": "Calbiga", "value": "C83482"},
             {"label": "Calubian", "value": "C83483"},
             {"label": "Can-Avid", "value": "C83484"},
             {"label": "Canhandugan", "value": "C83485"},
             {"label": "Capoocan", "value": "C83486"},
             {"label": "Capul", "value": "C83487"},
             {"label": "Caraycaray", "value": "C83488"},
             {"label": "Caridad", "value": "C83489"},
             {"label": "Carigara", "value": "C83490"},
             {"label": "Catarman", "value": "C83491"},
             {"label": "Catbalogan", "value": "C83492"},
             {"label": "Catmon", "value": "C83493"},
             {"label": "Catubig", "value": "C83494"},
             {"label": "Cervantes", "value": "C83495"},
             {"label": "Concepcion", "value": "C83496"},
             {"label": "Consuegra", "value": "C83497"},
             {"label": "Culaba", "value": "C83498"},
             {"label": "Culasian", "value": "C83499"},
             {"label": "Dagami", "value": "C83500"},
             {"label": "Dao", "value": "C83501"},
             {"label": "Dapdap", "value": "C83502"},
             {"label": "Daram", "value": "C83503"},
             {"label": "Dolores", "value": "C83504"},
             {"label": "Doos", "value": "C83505"},
             {"label": "Dulag", "value": "C83506"},
             {"label": "Erenas", "value": "C83507"},
             {"label": "Gabas", "value": "C83508"},
             {"label": "Gamay", "value": "C83509"},
             {"label": "Gandara", "value": "C83510"},
             {"label": "General MacArthur", "value": "C83511"},
             {"label": "Ginabuyan", "value": "C83512"},
             {"label": "Giporlos", "value": "C83513"},
             {"label": "Guindapunan", "value": "C83514"},
             {"label": "Guirang", "value": "C83515"},
             {"label": "Guiuan", "value": "C83516"},
             {"label": "Hernani", "value": "C83517"},
             {"label": "Hilongos", "value": "C83518"},
             {"label": "Hindang", "value": "C83519"},
             {"label": "Hingatungan", "value": "C83520"},
             {"label": "Hinunangan", "value": "C83521"},
             {"label": "Hinundayan", "value": "C83522"},
             {"label": "Hipadpad", "value": "C83523"},
             {"label": "Hipasngo", "value": "C83524"},
             {"label": "Ibarra", "value": "C83525"},
             {"label": "Ichon", "value": "C83526"},
             {"label": "Inangatan", "value": "C83527"},
             {"label": "Ipil", "value": "C83528"},
             {"label": "Isabel", "value": "C83529"},
             {"label": "Jaro", "value": "C83530"},
             {"label": "Jiabong", "value": "C83531"},
             {"label": "Jubasan", "value": "C83532"},
             {"label": "Julita", "value": "C83533"},
             {"label": "Kabuynan", "value": "C83534"},
             {"label": "Kampokpok", "value": "C83535"},
             {"label": "Kananya", "value": "C83536"},
             {"label": "Kilim", "value": "C83537"},
             {"label": "La Paz", "value": "C83538"},
             {"label": "Lalauigan", "value": "C83539"},
             {"label": "Lamak", "value": "C83540"},
             {"label": "Lao", "value": "C83541"},
             {"label": "Laoang", "value": "C83542"},
             {"label": "Lapinig", "value": "C83543"},
             {"label": "Las Navas", "value": "C83544"},
             {"label": "Lavezares", "value": "C83545"},
             {"label": "Leyte", "value": "C83546"},
             {"label": "Libagon", "value": "C83547"},
             {"label": "Libas", "value": "C83548"},
             {"label": "Libertad", "value": "C83549"},
             {"label": "Liberty", "value": "C83550"},
             {"label": "Liloan", "value": "C83551"},
             {"label": "Lim-oo", "value": "C83552"},
             {"label": "Limon", "value": "C83553"},
             {"label": "Llorente", "value": "C83554"},
             {"label": "Looc", "value": "C83555"},
             {"label": "Lope de Vega", "value": "C83556"},
             {"label": "Maasin", "value": "C83557"},
             {"label": "Mabini", "value": "C83558"},
             {"label": "MacArthur", "value": "C83559"},
             {"label": "Macrohon", "value": "C83560"},
             {"label": "Mahaplag", "value": "C83561"},
             {"label": "Makiwalo", "value": "C83562"},
             {"label": "Malaga", "value": "C83563"},
             {"label": "Malajog", "value": "C83564"},
             {"label": "Malilinao", "value": "C83565"},
             {"label": "Malitbog", "value": "C83566"},
             {"label": "Mantang", "value": "C83567"},
             {"label": "Mapanas", "value": "C83568"},
             {"label": "Margen", "value": "C83569"},
             {"label": "Maripipi", "value": "C83570"},
             {"label": "Masarayao", "value": "C83571"},
             {"label": "Maslog", "value": "C83572"},
             {"label": "Matalom", "value": "C83573"},
             {"label": "Matlang", "value": "C83574"},
             {"label": "Maydolong", "value": "C83575"},
             {"label": "Mayorga", "value": "C83576"},
             {"label": "Maypangdan", "value": "C83577"},
             {"label": "Merida", "value": "C83578"},
             {"label": "Mondragon", "value": "C83579"},
             {"label": "Motiong", "value": "C83580"},
             {"label": "Naghalin", "value": "C83581"},
             {"label": "Napuro", "value": "C83582"},
             {"label": "Naval", "value": "C83583"},
             {"label": "Nena", "value": "C83584"},
             {"label": "Nenita", "value": "C83585"},
             {"label": "Oras", "value": "C83586"},
             {"label": "Ormoc", "value": "C83587"},
             {"label": "Padre Burgos", "value": "C83588"},
             {"label": "Pagsanghan", "value": "C83589"},
             {"label": "Palanit", "value": "C83590"},
             {"label": "Palapag", "value": "C83591"},
             {"label": "Palaroo", "value": "C83592"},
             {"label": "Palhi", "value": "C83593"},
             {"label": "Palo", "value": "C83594"},
             {"label": "Palompon", "value": "C83595"},
             {"label": "Pambujan", "value": "C83596"},
             {"label": "Panalanoy", "value": "C83597"},
             {"label": "Pangdan", "value": "C83598"},
             {"label": "Pangpang", "value": "C83599"},
             {"label": "Pastrana", "value": "C83600"},
             {"label": "Patong", "value": "C83601"},
             {"label": "Pawing", "value": "C83602"},
             {"label": "Pina", "value": "C83603"},
             {"label": "Pinamopoan", "value": "C83604"},
             {"label": "Pintuyan", "value": "C83605"},
             {"label": "Plaridel", "value": "C83606"},
             {"label": "Polahongon", "value": "C83607"},
             {"label": "Polange", "value": "C83608"},
             {"label": "Province of Eastern Samar", "value": "C83609"},
             {"label": "Province of Leyte", "value": "C83610"},
             {"label": "Province of Northern Samar", "value": "C83611"},
             {"label": "Province of Samar", "value": "C83612"},
             {"label": "Province of Southern Leyte", "value": "C83613"},
             {"label": "Puerto Bello", "value": "C83614"},
             {"label": "Quinapundan", "value": "C83615"},
             {"label": "Rizal", "value": "C83616"},
             {"label": "Rosario", "value": "C83617"},
             {"label": "Sabang", "value": "C83618"},
             {"label": "Saint Bernard", "value": "C83619"},
             {"label": "Salcedo", "value": "C83620"},
             {"label": "Salvacion", "value": "C83621"},
             {"label": "San Antonio", "value": "C83622"},
             {"label": "San Eduardo", "value": "C83623"},
             {"label": "San Francisco", "value": "C83624"},
             {"label": "San Isidro", "value": "C83625"},
             {"label": "San Joaquin", "value": "C83626"},
             {"label": "San Jorge", "value": "C83627"},
             {"label": "San Jose", "value": "C83628"},
             {"label": "San Jose de Buan", "value": "C83629"},
             {"label": "San Juan", "value": "C83630"},
             {"label": "San Julian", "value": "C83631"},
             {"label": "San Miguel", "value": "C83632"},
             {"label": "San Pedro", "value": "C83633"},
             {"label": "San Policarpio", "value": "C83634"},
             {"label": "San Policarpo", "value": "C83635"},
             {"label": "San Ricardo", "value": "C83636"},
             {"label": "San Roque", "value": "C83637"},
             {"label": "San Sebastian", "value": "C83638"},
             {"label": "San Vicente", "value": "C83639"},
             {"label": "Santa Fe", "value": "C83640"},
             {"label": "Santa Margarita", "value": "C83641"},
             {"label": "Santa Paz", "value": "C83642"},
             {"label": "Santa Rita", "value": "C83643"},
             {"label": "Santo Nino", "value": "C83644"},
             {"label": "Siguinon", "value": "C83645"},
             {"label": "Silago", "value": "C83646"},
             {"label": "Silanga", "value": "C83647"},
             {"label": "Silvino Lobos", "value": "C83648"},
             {"label": "Sogod", "value": "C83649"},
             {"label": "Sulangan", "value": "C83650"},
             {"label": "Sulat", "value": "C83651"},
             {"label": "Tabango", "value": "C83652"},
             {"label": "Tabing", "value": "C83653"},
             {"label": "Tabonoc", "value": "C83654"},
             {"label": "Tabontabon", "value": "C83655"},
             {"label": "Tacloban", "value": "C83656"},
             {"label": "Taft", "value": "C83657"},
             {"label": "Tagapul-an", "value": "C83658"},
             {"label": "Tagbubungang Diot", "value": "C83659"},
             {"label": "Talalora", "value": "C83660"},
             {"label": "Talisayan", "value": "C83661"},
             {"label": "Tanauan", "value": "C83662"},
             {"label": "Tarangnan", "value": "C83663"},
             {"label": "Tinambacan", "value": "C83664"},
             {"label": "Tolosa", "value": "C83665"},
             {"label": "Tomas Oppus", "value": "C83666"},
             {"label": "Tucdao", "value": "C83667"},
             {"label": "Tugbong", "value": "C83668"},
             {"label": "Tunga", "value": "C83669"},
             {"label": "Tutubigan", "value": "C83670"},
             {"label": "Umaganhan", "value": "C83671"},
             {"label": "Valencia", "value": "C83672"},
             {"label": "Victoria", "value": "C83673"},
             {"label": "Viga", "value": "C83674"},
             {"label": "Villaba", "value": "C83675"},
             {"label": "Villareal", "value": "C83676"},
             {"label": "Viriato", "value": "C83677"},
             {"label": "Wright", "value": "C83678"},
             {"label": "Zumarraga", "value": "C83679"}],
 "Batha Region": [{"label": "Ati", "value": "C17852"},
                  {"label": "Oum Hadjer", "value": "C17853"}],
 "Batken Region": [{"label": "Aydarken", "value": "C64710"},
                   {"label": "Batken", "value": "C64711"},
                   {"label": "Iradan", "value": "C64712"},
                   {"label": "Isfana", "value": "C64713"},
                   {"label": "Karavan", "value": "C64714"},
                   {"label": "Kyzyl-Kyya", "value": "C64715"},
                   {"label": "Suluktu", "value": "C64716"}],
 "Batman": [{"label": "Aviski", "value": "C120021"},
            {"label": "Aydinkonak", "value": "C120022"},
            {"label": "Balpinar", "value": "C120023"},
            {"label": "Batman", "value": "C120024"},
            {"label": "Besiri", "value": "C120025"},
            {"label": "Bespinar", "value": "C120026"},
            {"label": "Binatli", "value": "C120027"},
            {"label": "Cevrimova", "value": "C120028"},
            {"label": "Demiryol", "value": "C120029"},
            {"label": "Dogankavak", "value": "C120030"},
            {"label": "Erkoklu", "value": "C120031"},
            {"label": "Gercus Ilcesi", "value": "C120032"},
            {"label": "Gerdzhyush", "value": "C120033"},
            {"label": "Hasankeyf", "value": "C120034"},
            {"label": "Hisar", "value": "C120035"},
            {"label": "Kayapinar", "value": "C120036"},
            {"label": "Kozluk Ilcesi", "value": "C120037"},
            {"label": "Merkez", "value": "C120038"},
            {"label": "Oymatas", "value": "C120039"},
            {"label": "Sason", "value": "C120040"},
            {"label": "Yenikoy", "value": "C120041"},
            {"label": "Yenipinar", "value": "C120042"},
            {"label": "Yolagzi", "value": "C120043"}],
 "Batna": [{"label": "Ain Touta", "value": "C248"},
           {"label": "Arris", "value": "C249"},
           {"label": "Barika", "value": "C250"},
           {"label": "Batna", "value": "C251"},
           {"label": "Boumagueur", "value": "C252"},
           {"label": "Merouana", "value": "C253"},
           {"label": "Ras el Aioun", "value": "C254"},
           {"label": "Tazoult-Lambese", "value": "C255"}],
 "Battambang": [{"label": "Battambang", "value": "C16464"},
                {"label": "Srok Ak Phnum", "value": "C16465"},
                {"label": "Srok Banan", "value": "C16466"},
                {"label": "Srok Bavil", "value": "C16467"},
                {"label": "Srok Rotanak Mondol", "value": "C16468"}],
 "Baucau Municipality": [{"label": "Baguia", "value": "C24047"},
                         {"label": "Baucau", "value": "C24048"},
                         {"label": "Baukau", "value": "C24049"},
                         {"label": "Laga", "value": "C24050"},
                         {"label": "Quelicai", "value": "C24051"},
                         {"label": "Vemasse", "value": "C24052"},
                         {"label": "Venilale", "value": "C24053"}],
 "Bauchi": [{"label": "Azare", "value": "C78150"},
            {"label": "Bauchi", "value": "C78151"},
            {"label": "Boi", "value": "C78152"},
            {"label": "Bununu", "value": "C78153"},
            {"label": "Darazo", "value": "C78154"},
            {"label": "Dass", "value": "C78155"},
            {"label": "Dindima", "value": "C78156"},
            {"label": "Disina", "value": "C78157"},
            {"label": "Gabarin", "value": "C78158"},
            {"label": "Gwaram", "value": "C78159"},
            {"label": "Kari", "value": "C78160"},
            {"label": "Lame", "value": "C78161"},
            {"label": "Lere", "value": "C78162"},
            {"label": "Madara", "value": "C78163"},
            {"label": "Misau", "value": "C78164"},
            {"label": "Sade", "value": "C78165"},
            {"label": "Yamrat", "value": "C78166"},
            {"label": "Yanda Bayo", "value": "C78167"},
            {"label": "Yuli", "value": "C78168"},
            {"label": "Zadawa", "value": "C78169"},
            {"label": "Zalanga", "value": "C78170"}],
 "Bauska Municipality": [{"label": "Bauska", "value": "C64853"}],
 "Bavaria": [{"label": "Abenberg", "value": "C35309"},
             {"label": "Abensberg", "value": "C35310"},
             {"label": "Absberg", "value": "C35311"},
             {"label": "Achslach", "value": "C35312"},
             {"label": "Adelsdorf", "value": "C35313"},
             {"label": "Adelshofen", "value": "C35314"},
             {"label": "Adelsried", "value": "C35315"},
             {"label": "Adelzhausen", "value": "C35316"},
             {"label": "Adlkofen", "value": "C35317"},
             {"label": "Affing", "value": "C35318"},
             {"label": "Aham", "value": "C35319"},
             {"label": "Aholfing", "value": "C35320"},
             {"label": "Aholming", "value": "C35321"},
             {"label": "Ahorn", "value": "C35322"},
             {"label": "Aichach", "value": "C35323"},
             {"label": "Aichen", "value": "C35324"},
             {"label": "Aidenbach", "value": "C35325"},
             {"label": "Aidhausen", "value": "C35326"},
             {"label": "Aiglsbach", "value": "C35327"},
             {"label": "Aindling", "value": "C35328"},
             {"label": "Ainring", "value": "C35329"},
             {"label": "Aislingen", "value": "C35330"},
             {"label": "Aitrang", "value": "C35331"},
             {"label": "Albaching", "value": "C35332"},
             {"label": "Albertshofen", "value": "C35333"},
             {"label": "Aldersbach", "value": "C35334"},
             {"label": "Alerheim", "value": "C35335"},
             {"label": "Alesheim", "value": "C35336"},
             {"label": "Aletshausen", "value": "C35337"},
             {"label": "Alfeld", "value": "C35338"},
             {"label": "Allersberg", "value": "C35339"},
             {"label": "Allershausen", "value": "C35340"},
             {"label": "Alling", "value": "C35341"},
             {"label": "Altdorf", "value": "C35342"},
             {"label": "Alteglofsheim", "value": "C35343"},
             {"label": "Altenbuch", "value": "C35344"},
             {"label": "Altendorf", "value": "C35345"},
             {"label": "Altenkunstadt", "value": "C35346"},
             {"label": "Altenmarkt", "value": "C35347"},
             {"label": "Altenmunster", "value": "C35348"},
             {"label": "Altenstadt", "value": "C35349"},
             {"label": "Altenthann", "value": "C35350"},
             {"label": "Alterhofen", "value": "C35351"},
             {"label": "Altfraunhofen", "value": "C35352"},
             {"label": "Althegnenberg", "value": "C35353"},
             {"label": "Altomunster", "value": "C35354"},
             {"label": "Altotting", "value": "C35355"},
             {"label": "Altusried", "value": "C35356"},
             {"label": "Alzenau in Unterfranken", "value": "C35357"},
             {"label": "Amberg", "value": "C35358"},
             {"label": "Amendingen", "value": "C35359"},
             {"label": "Amerang", "value": "C35360"},
             {"label": "Ammerndorf", "value": "C35361"},
             {"label": "Ammerthal", "value": "C35362"},
             {"label": "Ampfing", "value": "C35363"},
             {"label": "Andechs", "value": "C35364"},
             {"label": "Ansbach", "value": "C35365"},
             {"label": "Antdorf", "value": "C35366"},
             {"label": "Anzing", "value": "C35367"},
             {"label": "Apfeldorf", "value": "C35368"},
             {"label": "Arberg", "value": "C35369"},
             {"label": "Aresing", "value": "C35370"},
             {"label": "Arnbruck", "value": "C35371"},
             {"label": "Arnschwang", "value": "C35372"},
             {"label": "Arnstein", "value": "C35373"},
             {"label": "Arnstorf", "value": "C35374"},
             {"label": "Arrach", "value": "C35375"},
             {"label": "Arzberg", "value": "C35376"},
             {"label": "Asbach-Baumenheim", "value": "C35377"},
             {"label": "Ascha", "value": "C35378"},
             {"label": "Aschaffenburg", "value": "C35379"},
             {"label": "Aschau am Inn", "value": "C35380"},
             {"label": "Aschau im Chiemgau", "value": "C35381"},
             {"label": "Aschheim", "value": "C35382"},
             {"label": "Assling", "value": "C35383"},
             {"label": "Attenhofen", "value": "C35384"},
             {"label": "Attenkirchen", "value": "C35385"},
             {"label": "Atting", "value": "C35386"},
             {"label": "Au in der Hallertau", "value": "C35387"},
             {"label": "Aub", "value": "C35388"},
             {"label": "Auerbach", "value": "C35389"},
             {"label": "Aufhausen", "value": "C35390"},
             {"label": "Aufsess", "value": "C35391"},
             {"label": "Augsburg", "value": "C35392"},
             {"label": "Auhausen", "value": "C35393"},
             {"label": "Aura im Sinngrund", "value": "C35394"},
             {"label": "Aussernzell", "value": "C35395"},
             {"label": "Aying", "value": "C35396"},
             {"label": "Aystetten", "value": "C35397"},
             {"label": "Baar-Ebenhausen", "value": "C35398"},
             {"label": "Babenhausen", "value": "C35399"},
             {"label": "Babensham", "value": "C35400"},
             {"label": "Bach", "value": "C35401"},
             {"label": "Bachhagel", "value": "C35402"},
             {"label": "Bachingen an der Brenz", "value": "C35403"},
             {"label": "Bad Abbach", "value": "C35404"},
             {"label": "Bad Aibling", "value": "C35405"},
             {"label": "Bad Alexandersbad", "value": "C35406"},
             {"label": "Bad Berneck im Fichtelgebirge", "value": "C35407"},
             {"label": "Bad Birnbach", "value": "C35408"},
             {"label": "Bad Bocklet", "value": "C35409"},
             {"label": "Bad Bruckenau", "value": "C35410"},
             {"label": "Bad Endorf", "value": "C35411"},
             {"label": "Bad Feilnbach", "value": "C35412"},
             {"label": "Bad Fussing", "value": "C35413"},
             {"label": "Bad Griesbach", "value": "C35414"},
             {"label": "Bad Heilbrunn", "value": "C35415"},
             {"label": "Bad Kissingen", "value": "C35416"},
             {"label": "Bad Kohlgrub", "value": "C35417"},
             {"label": "Bad Konigshofen im Grabfeld", "value": "C35418"},
             {"label": "Bad Neustadt an der Saale", "value": "C35419"},
             {"label": "Bad Reichenhall", "value": "C35420"},
             {"label": "Bad Staffelstein", "value": "C35421"},
             {"label": "Bad Steben", "value": "C35422"},
             {"label": "Bad Tolz", "value": "C35423"},
             {"label": "Bad Wiessee", "value": "C35424"},
             {"label": "Bad Windsheim", "value": "C35425"},
             {"label": "Bad Worishofen", "value": "C35426"},
             {"label": "Baierbrunn", "value": "C35427"},
             {"label": "Baiersdorf", "value": "C35428"},
             {"label": "Baisweil", "value": "C35429"},
             {"label": "Balzhausen", "value": "C35430"},
             {"label": "Bamberg", "value": "C35431"},
             {"label": "Barbing", "value": "C35432"},
             {"label": "Barnau", "value": "C35433"},
             {"label": "Bastheim", "value": "C35434"},
             {"label": "Baudenbach", "value": "C35435"},
             {"label": "Baunach", "value": "C35436"},
             {"label": "Bayerbach", "value": "C35437"},
             {"label": "Bayerisch Eisenstein", "value": "C35438"},
             {"label": "Bayerisch Gmain", "value": "C35439"},
             {"label": "Bayreuth", "value": "C35440"},
             {"label": "Bayrischzell", "value": "C35441"},
             {"label": "Bechtsrieth", "value": "C35442"},
             {"label": "Bellenberg", "value": "C35443"},
             {"label": "Benediktbeuern", "value": "C35444"},
             {"label": "Benningen", "value": "C35445"},
             {"label": "Beratzhausen", "value": "C35446"},
             {"label": "Berching", "value": "C35447"},
             {"label": "Berchtesgaden", "value": "C35448"},
             {"label": "Berg", "value": "C35449"},
             {"label": "Berg im Gau", "value": "C35450"},
             {"label": "Bergen", "value": "C35451"},
             {"label": "Bergkirchen", "value": "C35452"},
             {"label": "Berglern", "value": "C35453"},
             {"label": "Bergrheinfeld", "value": "C35454"},
             {"label": "Bergtheim", "value": "C35455"},
             {"label": "Bernau am Chiemsee", "value": "C35456"},
             {"label": "Bernbeuren", "value": "C35457"},
             {"label": "Berngau", "value": "C35458"},
             {"label": "Bernhardswald", "value": "C35459"},
             {"label": "Bernried", "value": "C35460"},
             {"label": "Betzigau", "value": "C35461"},
             {"label": "Beutelsbach", "value": "C35462"},
             {"label": "Biberbach", "value": "C35463"},
             {"label": "Biburg", "value": "C35464"},
             {"label": "Bichl", "value": "C35465"},
             {"label": "Bidingen", "value": "C35466"},
             {"label": "Biebelried", "value": "C35467"},
             {"label": "Biessenhofen", "value": "C35468"},
             {"label": "Bindlach", "value": "C35469"},
             {"label": "Binswangen", "value": "C35470"},
             {"label": "Birkenfeld", "value": "C35471"},
             {"label": "Bischberg", "value": "C35472"},
             {"label": "Bischbrunn", "value": "C35473"},
             {"label": "Bischofsgrun", "value": "C35474"},
             {"label": "Bischofsheim an der Rhon", "value": "C35475"},
             {"label": "Bischofsmais", "value": "C35476"},
             {"label": "Bischofswiesen", "value": "C35477"},
             {"label": "Bissingen", "value": "C35478"},
             {"label": "Blaibach", "value": "C35479"},
             {"label": "Blaichach", "value": "C35480"},
             {"label": "Blankenbach", "value": "C35481"},
             {"label": "Blindheim", "value": "C35482"},
             {"label": "Bobing", "value": "C35483"},
             {"label": "Bobingen", "value": "C35484"},
             {"label": "Bobrach", "value": "C35485"},
             {"label": "Bockhorn", "value": "C35486"},
             {"label": "Bodenkirchen", "value": "C35487"},
             {"label": "Bodenmais", "value": "C35488"},
             {"label": "Bodenwohr", "value": "C35489"},
             {"label": "Bodolz", "value": "C35490"},
             {"label": "Bogen", "value": "C35491"},
             {"label": "Bogenhausen", "value": "C35492"},
             {"label": "Bolsterlang", "value": "C35493"},
             {"label": "Bonstetten", "value": "C35494"},
             {"label": "Boos", "value": "C35495"},
             {"label": "Brand", "value": "C35496"},
             {"label": "Brannenburg", "value": "C35497"},
             {"label": "Breitbrunn", "value": "C35498"},
             {"label": "Breitenberg", "value": "C35499"},
             {"label": "Breitenbrunn", "value": "C35500"},
             {"label": "Breitengussbach", "value": "C35501"},
             {"label": "Breitenthal", "value": "C35502"},
             {"label": "Brennberg", "value": "C35503"},
             {"label": "Bruck", "value": "C35504"},
             {"label": "Bruck in der Oberpfalz", "value": "C35505"},
             {"label": "Bruckberg", "value": "C35506"},
             {"label": "Bruckmuhl", "value": "C35507"},
             {"label": "Brunn", "value": "C35508"},
             {"label": "Brunnen", "value": "C35509"},
             {"label": "Brunnthal", "value": "C35510"},
             {"label": "Bubenreuth", "value": "C35511"},
             {"label": "Bubesheim", "value": "C35512"},
             {"label": "Buch", "value": "C35513"},
             {"label": "Buch am Buchrain", "value": "C35514"},
             {"label": "Buchbach", "value": "C35515"},
             {"label": "Buchbrunn", "value": "C35516"},
             {"label": "Buchdorf", "value": "C35517"},
             {"label": "Buchenbach", "value": "C35518"},
             {"label": "Buchenberg", "value": "C35519"},
             {"label": "Buchlberg", "value": "C35520"},
             {"label": "Buchloe", "value": "C35521"},
             {"label": "Buckenhof", "value": "C35522"},
             {"label": "Burgau", "value": "C35523"},
             {"label": "Burgberg", "value": "C35524"},
             {"label": "Burgbernheim", "value": "C35525"},
             {"label": "Burgebrach", "value": "C35526"},
             {"label": "Burggen", "value": "C35527"},
             {"label": "Burghaslach", "value": "C35528"},
             {"label": "Burghausen", "value": "C35529"},
             {"label": "Burgheim", "value": "C35530"},
             {"label": "Burgkirchen an der Alz", "value": "C35531"},
             {"label": "Burgkunstadt", "value": "C35532"},
             {"label": "Burglauer", "value": "C35533"},
             {"label": "Burglengenfeld", "value": "C35534"},
             {"label": "Burgoberbach", "value": "C35535"},
             {"label": "Burgpreppach", "value": "C35536"},
             {"label": "Burgsalach", "value": "C35537"},
             {"label": "Burgsinn", "value": "C35538"},
             {"label": "Burgstadt", "value": "C35539"},
             {"label": "Burgthann", "value": "C35540"},
             {"label": "Burgwindheim", "value": "C35541"},
             {"label": "Burk", "value": "C35542"},
             {"label": "Burkardroth", "value": "C35543"},
             {"label": "Burtenbach", "value": "C35544"},
             {"label": "Buttenheim", "value": "C35545"},
             {"label": "Buttenwiesen", "value": "C35546"},
             {"label": "Butthard", "value": "C35547"},
             {"label": "Buxheim", "value": "C35548"},
             {"label": "Cadolzburg", "value": "C35549"},
             {"label": "Cham", "value": "C35550"},
             {"label": "Chamerau", "value": "C35551"},
             {"label": "Chieming", "value": "C35552"},
             {"label": "Chostlarn", "value": "C35553"},
             {"label": "Coburg", "value": "C35554"},
             {"label": "Colmberg", "value": "C35555"},
             {"label": "Creussen", "value": "C35556"},
             {"label": "Dachau", "value": "C35557"},
             {"label": "Dasing", "value": "C35558"},
             {"label": "Deggendorf", "value": "C35559"},
             {"label": "Deining", "value": "C35560"},
             {"label": "Deiningen", "value": "C35561"},
             {"label": "Deisenhausen", "value": "C35562"},
             {"label": "Denklingen", "value": "C35563"},
             {"label": "Dentlein am Forst", "value": "C35564"},
             {"label": "Dettelbach", "value": "C35565"},
             {"label": "Deuerling", "value": "C35566"},
             {"label": "Diebach", "value": "C35567"},
             {"label": "Diedorf", "value": "C35568"},
             {"label": "Diespeck", "value": "C35569"},
             {"label": "Diessen am Ammersee", "value": "C35570"},
             {"label": "Dietenhofen", "value": "C35571"},
             {"label": "Dietersburg", "value": "C35572"},
             {"label": "Dietersheim", "value": "C35573"},
             {"label": "Dieterskirchen", "value": "C35574"},
             {"label": "Dietfurt", "value": "C35575"},
             {"label": "Dietmannsried", "value": "C35576"},
             {"label": "Dietramszell", "value": "C35577"},
             {"label": "Dillingen an der Donau", "value": "C35578"},
             {"label": "Dingolfing", "value": "C35579"},
             {"label": "Dingolshausen", "value": "C35580"},
             {"label": "Dinkelsbuhl", "value": "C35581"},
             {"label": "Dinkelscherben", "value": "C35582"},
             {"label": "Dirlewang", "value": "C35583"},
             {"label": "Dittelbrunn", "value": "C35584"},
             {"label": "Dittenheim", "value": "C35585"},
             {"label": "Dohlau", "value": "C35586"},
             {"label": "Dombuhl", "value": "C35587"},
             {"label": "Donaustauf", "value": "C35588"},
             {"label": "Donauworth", "value": "C35589"},
             {"label": "Donnersdorf", "value": "C35590"},
             {"label": "Dorfen", "value": "C35591"},
             {"label": "Dorfprozelten", "value": "C35592"},
             {"label": "Dormitz", "value": "C35593"},
             {"label": "Drachselsried", "value": "C35594"},
             {"label": "Duggendorf", "value": "C35595"},
             {"label": "Durach", "value": "C35596"},
             {"label": "Durrlauingen", "value": "C35597"},
             {"label": "Durrwangen", "value": "C35598"},
             {"label": "Ebelsbach", "value": "C35599"},
             {"label": "Ebensfeld", "value": "C35600"},
             {"label": "Ebermannsdorf", "value": "C35601"},
             {"label": "Ebermannstadt", "value": "C35602"},
             {"label": "Ebersberg", "value": "C35603"},
             {"label": "Ebersdorf", "value": "C35604"},
             {"label": "Ebnath", "value": "C35605"},
             {"label": "Eching", "value": "C35606"},
             {"label": "Eckersdorf", "value": "C35607"},
             {"label": "Edelsfeld", "value": "C35608"},
             {"label": "Ederheim", "value": "C35609"},
             {"label": "Effeltrich", "value": "C35610"},
             {"label": "Egenhofen", "value": "C35611"},
             {"label": "Egg an der Gunz", "value": "C35612"},
             {"label": "Eggenfelden", "value": "C35613"},
             {"label": "Eggenthal", "value": "C35614"},
             {"label": "Egglham", "value": "C35615"},
             {"label": "Egglkofen", "value": "C35616"},
             {"label": "Eggolsheim", "value": "C35617"},
             {"label": "Eggstatt", "value": "C35618"},
             {"label": "Eging", "value": "C35619"},
             {"label": "Egling", "value": "C35620"},
             {"label": "Egloffstein", "value": "C35621"},
             {"label": "Egmating", "value": "C35622"},
             {"label": "Ehekirchen", "value": "C35623"},
             {"label": "Ehingen", "value": "C35624"},
             {"label": "Eibelstadt", "value": "C35625"},
             {"label": "Eichenau", "value": "C35626"},
             {"label": "Eichenbuhl", "value": "C35627"},
             {"label": "Eichendorf", "value": "C35628"},
             {"label": "Eichenried", "value": "C35629"},
             {"label": "Eichstatt", "value": "C35630"},
             {"label": "Eiselfing", "value": "C35631"},
             {"label": "Eisenburg", "value": "C35632"},
             {"label": "Eisingen", "value": "C35633"},
             {"label": "Eitting", "value": "C35634"},
             {"label": "Elfershausen", "value": "C35635"},
             {"label": "Ellgau", "value": "C35636"},
             {"label": "Ellingen", "value": "C35637"},
             {"label": "Ellzee", "value": "C35638"},
             {"label": "Elsendorf", "value": "C35639"},
             {"label": "Elsenfeld", "value": "C35640"},
             {"label": "Eltmann", "value": "C35641"},
             {"label": "Emersacker", "value": "C35642"},
             {"label": "Emmering", "value": "C35643"},
             {"label": "Emskirchen", "value": "C35644"},
             {"label": "Emtmannsberg", "value": "C35645"},
             {"label": "Engelsberg", "value": "C35646"},
             {"label": "Engelthal", "value": "C35647"},
             {"label": "Ensdorf", "value": "C35648"},
             {"label": "Eppishausen", "value": "C35649"},
             {"label": "Erbendorf", "value": "C35650"},
             {"label": "Erding", "value": "C35651"},
             {"label": "Erdweg", "value": "C35652"},
             {"label": "Eresing", "value": "C35653"},
             {"label": "Ergersheim", "value": "C35654"},
             {"label": "Ergolding", "value": "C35655"},
             {"label": "Ergoldsbach", "value": "C35656"},
             {"label": "Ering", "value": "C35657"},
             {"label": "Erkheim", "value": "C35658"},
             {"label": "Erlabrunn", "value": "C35659"},
             {"label": "Erlangen", "value": "C35660"},
             {"label": "Erlbach", "value": "C35661"},
             {"label": "Erlenbach", "value": "C35662"},
             {"label": "Erlenbach am Main", "value": "C35663"},
             {"label": "Ernsgaden", "value": "C35664"},
             {"label": "Eschau", "value": "C35665"},
             {"label": "Eschenlohe", "value": "C35666"},
             {"label": "Eschlkam", "value": "C35667"},
             {"label": "Eslarn", "value": "C35668"},
             {"label": "Esselbach", "value": "C35669"},
             {"label": "Essenbach", "value": "C35670"},
             {"label": "Essing", "value": "C35671"},
             {"label": "Estenfeld", "value": "C35672"},
             {"label": "Ettringen", "value": "C35673"},
             {"label": "Etzelwang", "value": "C35674"},
             {"label": "Etzenricht", "value": "C35675"},
             {"label": "Euerbach", "value": "C35676"},
             {"label": "Euerdorf", "value": "C35677"},
             {"label": "Eurasburg", "value": "C35678"},
             {"label": "Eussenheim", "value": "C35679"},
             {"label": "Fahrenzhausen", "value": "C35680"},
             {"label": "Falkenberg", "value": "C35681"},
             {"label": "Falkenfels", "value": "C35682"},
             {"label": "Falkenstein", "value": "C35683"},
             {"label": "Farchant", "value": "C35684"},
             {"label": "Faulbach", "value": "C35685"},
             {"label": "Feilitzsch", "value": "C35686"},
             {"label": "Feldafing", "value": "C35687"},
             {"label": "Feldkirchen", "value": "C35688"},
             {"label": "Feldkirchen-Westerham", "value": "C35689"},
             {"label": "Fellheim", "value": "C35690"},
             {"label": "Feucht", "value": "C35691"},
             {"label": "Feuchtwangen", "value": "C35692"},
             {"label": "Fichtelberg", "value": "C35693"},
             {"label": "Finningen", "value": "C35694"},
             {"label": "Finsing", "value": "C35695"},
             {"label": "Fischach", "value": "C35696"},
             {"label": "Fischbachau", "value": "C35697"},
             {"label": "Flachslanden", "value": "C35698"},
             {"label": "Fladungen", "value": "C35699"},
             {"label": "Flintsbach", "value": "C35700"},
             {"label": "Floss", "value": "C35701"},
             {"label": "Flossenburg", "value": "C35702"},
             {"label": "Forchheim", "value": "C35703"},
             {"label": "Forstinning", "value": "C35704"},
             {"label": "Frammersbach", "value": "C35705"},
             {"label": "Frankenwinheim", "value": "C35706"},
             {"label": "Frasdorf", "value": "C35707"},
             {"label": "Frauenau", "value": "C35708"},
             {"label": "Fraueneuharting", "value": "C35709"},
             {"label": "Fraunberg", "value": "C35710"},
             {"label": "Freihung", "value": "C35711"},
             {"label": "Freilassing", "value": "C35712"},
             {"label": "Freising", "value": "C35713"},
             {"label": "Fremdingen", "value": "C35714"},
             {"label": "Frensdorf", "value": "C35715"},
             {"label": "Freudenberg", "value": "C35716"},
             {"label": "Freystadt", "value": "C35717"},
             {"label": "Freyung", "value": "C35718"},
             {"label": "Frickenhausen", "value": "C35719"},
             {"label": "Fridolfing", "value": "C35720"},
             {"label": "Friedberg", "value": "C35721"},
             {"label": "Friedenfels", "value": "C35722"},
             {"label": "Friesenried", "value": "C35723"},
             {"label": "Frontenhausen", "value": "C35724"},
             {"label": "Fuchsmuhl", "value": "C35725"},
             {"label": "Fuchsstadt", "value": "C35726"},
             {"label": "Funfstetten", "value": "C35727"},
             {"label": "Fursteneck", "value": "C35728"},
             {"label": "Furstenfeldbruck", "value": "C35729"},
             {"label": "Furstenstein", "value": "C35730"},
             {"label": "Furstenzell", "value": "C35731"},
             {"label": "Furth", "value": "C35732"},
             {"label": "Furth im Wald", "value": "C35733"},
             {"label": "Fussen", "value": "C35734"},
             {"label": "Gablingen", "value": "C35735"},
             {"label": "Gachenbach", "value": "C35736"},
             {"label": "Gadheim", "value": "C35737"},
             {"label": "Gaimersheim", "value": "C35738"},
             {"label": "Gaissach", "value": "C35739"},
             {"label": "Gammelsdorf", "value": "C35740"},
             {"label": "Gangkofen", "value": "C35741"},
             {"label": "Garching an der Alz", "value": "C35742"},
             {"label": "Garching bei Munchen", "value": "C35743"},
             {"label": "Garmisch-Partenkirchen", "value": "C35744"},
             {"label": "Gars", "value": "C35745"},
             {"label": "Gattendorf", "value": "C35746"},
             {"label": "Gaukonigshofen", "value": "C35747"},
             {"label": "Gauting", "value": "C35748"},
             {"label": "Gebsattel", "value": "C35749"},
             {"label": "Gefrees", "value": "C35750"},
             {"label": "Geiersthal", "value": "C35751"},
             {"label": "Geiselbach", "value": "C35752"},
             {"label": "Geiselhoring", "value": "C35753"},
             {"label": "Geiselwind", "value": "C35754"},
             {"label": "Geisenfeld", "value": "C35755"},
             {"label": "Geisenhausen", "value": "C35756"},
             {"label": "Geldersheim", "value": "C35757"},
             {"label": "Geltendorf", "value": "C35758"},
             {"label": "Gemunden am Main", "value": "C35759"},
             {"label": "Genderkingen", "value": "C35760"},
             {"label": "Georgenberg", "value": "C35761"},
             {"label": "Georgensgmund", "value": "C35762"},
             {"label": "Gerach", "value": "C35763"},
             {"label": "Gerbrunn", "value": "C35764"},
             {"label": "Geretsried", "value": "C35765"},
             {"label": "Gerhardshofen", "value": "C35766"},
             {"label": "Germering", "value": "C35767"},
             {"label": "Geroldsgrun", "value": "C35768"},
             {"label": "Geroldshausen", "value": "C35769"},
             {"label": "Gerolfingen", "value": "C35770"},
             {"label": "Gerolsbach", "value": "C35771"},
             {"label": "Gerolzhofen", "value": "C35772"},
             {"label": "Gersthofen", "value": "C35773"},
             {"label": "Gerzen", "value": "C35774"},
             {"label": "Gesees", "value": "C35775"},
             {"label": "Geslau", "value": "C35776"},
             {"label": "Gessertshausen", "value": "C35777"},
             {"label": "Gestratz", "value": "C35778"},
             {"label": "Giebelstadt", "value": "C35779"},
             {"label": "Gilching", "value": "C35780"},
             {"label": "Glashutten", "value": "C35781"},
             {"label": "Glattbach", "value": "C35782"},
             {"label": "Glonn", "value": "C35783"},
             {"label": "Glott", "value": "C35784"},
             {"label": "Gmund am Tegernsee", "value": "C35785"},
             {"label": "Gochsheim", "value": "C35786"},
             {"label": "Goldbach", "value": "C35787"},
             {"label": "Goldkronach", "value": "C35788"},
             {"label": "Gorisried", "value": "C35789"},
             {"label": "Gossenheim", "value": "C35790"},
             {"label": "Gossweinstein", "value": "C35791"},
             {"label": "Gotteszell", "value": "C35792"},
             {"label": "Gottfrieding", "value": "C35793"},
             {"label": "Graben", "value": "C35794"},
             {"label": "Grabenstatt", "value": "C35795"},
             {"label": "Grafelfing", "value": "C35796"},
             {"label": "Grafenau", "value": "C35797"},
             {"label": "Grafenberg", "value": "C35798"},
             {"label": "Grafendorf", "value": "C35799"},
             {"label": "Grafengehaig", "value": "C35800"},
             {"label": "Grafenrheinfeld", "value": "C35801"},
             {"label": "Grafenwiesen", "value": "C35802"},
             {"label": "Grafenwohr", "value": "C35803"},
             {"label": "Grafing bei Munchen", "value": "C35804"},
             {"label": "Grafrath", "value": "C35805"},
             {"label": "Grainau", "value": "C35806"},
             {"label": "Grainet", "value": "C35807"},
             {"label": "Grasbrunn", "value": "C35808"},
             {"label": "Grassau", "value": "C35809"},
             {"label": "Grattersdorf", "value": "C35810"},
             {"label": "Greding", "value": "C35811"},
             {"label": "Greifenberg", "value": "C35812"},
             {"label": "Greiling", "value": "C35813"},
             {"label": "Gremsdorf", "value": "C35814"},
             {"label": "Grettstadt", "value": "C35815"},
             {"label": "Greussenheim", "value": "C35816"},
             {"label": "Griesstatt", "value": "C35817"},
             {"label": "Grobenzell", "value": "C35818"},
             {"label": "Grossaitingen", "value": "C35819"},
             {"label": "Grossbardorf", "value": "C35820"},
             {"label": "Grosseibstadt", "value": "C35821"},
             {"label": "Grossenseebach", "value": "C35822"},
             {"label": "Grosshabersdorf", "value": "C35823"},
             {"label": "Grossheirath", "value": "C35824"},
             {"label": "Grossheubach", "value": "C35825"},
             {"label": "Grosskarolinenfeld", "value": "C35826"},
             {"label": "Grosslangheim", "value": "C35827"},
             {"label": "Grossostheim", "value": "C35828"},
             {"label": "Grossreuth bei Schweinau", "value": "C35829"},
             {"label": "Grosswallstadt", "value": "C35830"},
             {"label": "Grossweil", "value": "C35831"},
             {"label": "Grub", "value": "C35832"},
             {"label": "Grunenbach", "value": "C35833"},
             {"label": "Grunwald", "value": "C35834"},
             {"label": "Gstadt am Chiemsee", "value": "C35835"},
             {"label": "Gundelfingen", "value": "C35836"},
             {"label": "Gundelsheim", "value": "C35837"},
             {"label": "Gundremmingen", "value": "C35838"},
             {"label": "Guntersleben", "value": "C35839"},
             {"label": "Gunzach", "value": "C35840"},
             {"label": "Gunzburg", "value": "C35841"},
             {"label": "Gunzenhausen", "value": "C35842"},
             {"label": "Gutenstetten", "value": "C35843"},
             {"label": "Haag an der Amper", "value": "C35844"},
             {"label": "Haag in Oberbayern", "value": "C35845"},
             {"label": "Haar", "value": "C35846"},
             {"label": "Haarbach", "value": "C35847"},
             {"label": "Habach", "value": "C35848"},
             {"label": "Hafenlohr", "value": "C35849"},
             {"label": "Hagelstadt", "value": "C35850"},
             {"label": "Hagenbuchach", "value": "C35851"},
             {"label": "Hahnbach", "value": "C35852"},
             {"label": "Haibach", "value": "C35853"},
             {"label": "Haidmuhle", "value": "C35854"},
             {"label": "Haimhausen", "value": "C35855"},
             {"label": "Haiming", "value": "C35856"},
             {"label": "Hainsfarth", "value": "C35857"},
             {"label": "Halblech", "value": "C35858"},
             {"label": "Haldenwang", "value": "C35859"},
             {"label": "Halfing", "value": "C35860"},
             {"label": "Hallbergmoos", "value": "C35861"},
             {"label": "Hallerndorf", "value": "C35862"},
             {"label": "Hallstadt", "value": "C35863"},
             {"label": "Hammelburg", "value": "C35864"},
             {"label": "Happurg", "value": "C35865"},
             {"label": "Harburg", "value": "C35866"},
             {"label": "Harsdorf", "value": "C35867"},
             {"label": "Hartenstein", "value": "C35868"},
             {"label": "Haselbach", "value": "C35869"},
             {"label": "Hasloch", "value": "C35870"},
             {"label": "Hassfurt", "value": "C35871"},
             {"label": "Hattenhofen", "value": "C35872"},
             {"label": "Haundorf", "value": "C35873"},
             {"label": "Haunsheim", "value": "C35874"},
             {"label": "Hausen", "value": "C35875"},
             {"label": "Hausham", "value": "C35876"},
             {"label": "Hauzenberg", "value": "C35877"},
             {"label": "Hawangen", "value": "C35878"},
             {"label": "Hebertsfelden", "value": "C35879"},
             {"label": "Hebertshausen", "value": "C35880"},
             {"label": "Heideck", "value": "C35881"},
             {"label": "Heidenheim", "value": "C35882"},
             {"label": "Heigenbrucken", "value": "C35883"},
             {"label": "Heiligenstadt", "value": "C35884"},
             {"label": "Heilsbronn", "value": "C35885"},
             {"label": "Heimbuchenthal", "value": "C35886"},
             {"label": "Heimenkirch", "value": "C35887"},
             {"label": "Heimertingen", "value": "C35888"},
             {"label": "Helmbrechts", "value": "C35889"},
             {"label": "Helmstadt", "value": "C35890"},
             {"label": "Hemau", "value": "C35891"},
             {"label": "Hemhofen", "value": "C35892"},
             {"label": "Hendungen", "value": "C35893"},
             {"label": "Henfenfeld", "value": "C35894"},
             {"label": "Hengersberg", "value": "C35895"},
             {"label": "Heretsried", "value": "C35896"},
             {"label": "Hergensweiler", "value": "C35897"},
             {"label": "Heroldsbach", "value": "C35898"},
             {"label": "Heroldsberg", "value": "C35899"},
             {"label": "Herrieden", "value": "C35900"},
             {"label": "Herrngiersdorf", "value": "C35901"},
             {"label": "Herrsching am Ammersee", "value": "C35902"},
             {"label": "Hersbruck", "value": "C35903"},
             {"label": "Herzogenaurach", "value": "C35904"},
             {"label": "Hessdorf", "value": "C35905"},
             {"label": "Hettenshausen", "value": "C35906"},
             {"label": "Hettstadt", "value": "C35907"},
             {"label": "Hetzles", "value": "C35908"},
             {"label": "Heustreu", "value": "C35909"},
             {"label": "Hilgertshausen-Tandern", "value": "C35910"},
             {"label": "Hilpoltstein", "value": "C35911"},
             {"label": "Hiltenfingen", "value": "C35912"},
             {"label": "Hiltpoltstein", "value": "C35913"},
             {"label": "Himmelkron", "value": "C35914"},
             {"label": "Himmelstadt", "value": "C35915"},
             {"label": "Hinterschmiding", "value": "C35916"},
             {"label": "Hirschaid", "value": "C35917"},
             {"label": "Hirschau", "value": "C35918"},
             {"label": "Hirschbach", "value": "C35919"},
             {"label": "Hochberg", "value": "C35920"},
             {"label": "Hochheim", "value": "C35921"},
             {"label": "Hochstadt am Main", "value": "C35922"},
             {"label": "Hochstadt an der Aisch", "value": "C35923"},
             {"label": "Hochstadt an der Donau", "value": "C35924"},
             {"label": "Hochstadt bei Thiersheim", "value": "C35925"},
             {"label": "Hof", "value": "C35926"},
             {"label": "Hofheim in Unterfranken", "value": "C35927"},
             {"label": "Hofkirchen", "value": "C35928"},
             {"label": "Hofstetten", "value": "C35929"},
             {"label": "Hohenau", "value": "C35930"},
             {"label": "Hohenberg an der Eger", "value": "C35931"},
             {"label": "Hohenbrunn", "value": "C35932"},
             {"label": "Hohenburg", "value": "C35933"},
             {"label": "Hohenfels", "value": "C35934"},
             {"label": "Hohenfurch", "value": "C35935"},
             {"label": "Hohenkammer", "value": "C35936"},
             {"label": "Hohenkirchen-Siegertsbrunn", "value": "C35937"},
             {"label": "Hohenlinden", "value": "C35938"},
             {"label": "Hohenpeissenberg", "value": "C35939"},
             {"label": "Hohenpolding", "value": "C35940"},
             {"label": "Hohenthann", "value": "C35941"},
             {"label": "Hohenwarth", "value": "C35942"},
             {"label": "Hollenbach", "value": "C35943"},
             {"label": "Hollfeld", "value": "C35944"},
             {"label": "Hollstadt", "value": "C35945"},
             {"label": "Holzgunz", "value": "C35946"},
             {"label": "Holzheim", "value": "C35947"},
             {"label": "Holzkirchen", "value": "C35948"},
             {"label": "Hopferau", "value": "C35949"},
             {"label": "Horgau", "value": "C35950"},
             {"label": "Horgertshausen", "value": "C35951"},
             {"label": "Hosbach", "value": "C35952"},
             {"label": "Hoslwang", "value": "C35953"},
             {"label": "Hottingen", "value": "C35954"},
             {"label": "Huglfing", "value": "C35955"},
             {"label": "Huisheim", "value": "C35956"},
             {"label": "Hunderdorf", "value": "C35957"},
             {"label": "Hunding", "value": "C35958"},
             {"label": "Hurlach", "value": "C35959"},
             {"label": "Hutthurm", "value": "C35960"},
             {"label": "Ichenhausen", "value": "C35961"},
             {"label": "Icking", "value": "C35962"},
             {"label": "Iffeldorf", "value": "C35963"},
             {"label": "Igensdorf", "value": "C35964"},
             {"label": "Iggensbach", "value": "C35965"},
             {"label": "Ihrlerstein", "value": "C35966"},
             {"label": "Illertissen", "value": "C35967"},
             {"label": "Illschwang", "value": "C35968"},
             {"label": "Ilmmunster", "value": "C35969"},
             {"label": "Immenreuth", "value": "C35970"},
             {"label": "Immenstadt im Allgau", "value": "C35971"},
             {"label": "Inchenhofen", "value": "C35972"},
             {"label": "Ingolstadt", "value": "C35973"},
             {"label": "Innernzell", "value": "C35974"},
             {"label": "Inning am Ammersee", "value": "C35975"},
             {"label": "Inning am Holz", "value": "C35976"},
             {"label": "Insingen", "value": "C35977"},
             {"label": "Inzell", "value": "C35978"},
             {"label": "Iphofen", "value": "C35979"},
             {"label": "Ippesheim", "value": "C35980"},
             {"label": "Ipsheim", "value": "C35981"},
             {"label": "Irchenrieth", "value": "C35982"},
             {"label": "Irlbach", "value": "C35983"},
             {"label": "Irschenberg", "value": "C35984"},
             {"label": "Irsee", "value": "C35985"},
             {"label": "Isen", "value": "C35986"},
             {"label": "Ismaning", "value": "C35987"},
             {"label": "Jandelsbrunn", "value": "C35988"},
             {"label": "Jengen", "value": "C35989"},
             {"label": "Jesenwang", "value": "C35990"},
             {"label": "Jettingen-Scheppach", "value": "C35991"},
             {"label": "Jetzendorf", "value": "C35992"},
             {"label": "Johannesberg", "value": "C35993"},
             {"label": "Johanniskirchen", "value": "C35994"},
             {"label": "Julbach", "value": "C35995"},
             {"label": "Kahl am Main", "value": "C35996"},
             {"label": "Kaisheim", "value": "C35997"},
             {"label": "Kalchreuth", "value": "C35998"},
             {"label": "Kallmunz", "value": "C35999"},
             {"label": "Kammerstein", "value": "C36000"},
             {"label": "Karbach", "value": "C36001"},
             {"label": "Karlsfeld", "value": "C36002"},
             {"label": "Karlshuld", "value": "C36003"},
             {"label": "Karlskron", "value": "C36004"},
             {"label": "Karlstadt", "value": "C36005"},
             {"label": "Karsbach", "value": "C36006"},
             {"label": "Kasendorf", "value": "C36007"},
             {"label": "Kastl", "value": "C36008"},
             {"label": "Kaufbeuren", "value": "C36009"},
             {"label": "Kaufering", "value": "C36010"},
             {"label": "Kelheim", "value": "C36011"},
             {"label": "Kellmunz", "value": "C36012"},
             {"label": "Kemmern", "value": "C36013"},
             {"label": "Kemnath", "value": "C36014"},
             {"label": "Kempten (Allgau)", "value": "C36015"},
             {"label": "Kettershausen", "value": "C36016"},
             {"label": "Kiefersfelden", "value": "C36017"},
             {"label": "Kienberg", "value": "C36018"},
             {"label": "Kirchanschoring", "value": "C36019"},
             {"label": "Kirchberg", "value": "C36020"},
             {"label": "Kirchdorf", "value": "C36021"},
             {"label": "Kirchdorf am Inn", "value": "C36022"},
             {"label": "Kirchdorf im Wald", "value": "C36023"},
             {"label": "Kirchehrenbach", "value": "C36024"},
             {"label": "Kirchenlamitz", "value": "C36025"},
             {"label": "Kirchenpingarten", "value": "C36026"},
             {"label": "Kirchensittenbach", "value": "C36027"},
             {"label": "Kirchenthumbach", "value": "C36028"},
             {"label": "Kirchham", "value": "C36029"},
             {"label": "Kirchhaslach", "value": "C36030"},
             {"label": "Kirchheim", "value": "C36031"},
             {"label": "Kirchheim bei Munchen", "value": "C36032"},
             {"label": "Kirchlauter", "value": "C36033"},
             {"label": "Kirchroth", "value": "C36034"},
             {"label": "Kirchseeon", "value": "C36035"},
             {"label": "Kirchweidach", "value": "C36036"},
             {"label": "Kirchzell", "value": "C36037"},
             {"label": "Kissing", "value": "C36038"},
             {"label": "Kist", "value": "C36039"},
             {"label": "Kitzingen", "value": "C36040"},
             {"label": "Kleinaitingen", "value": "C36041"},
             {"label": "Kleinheubach", "value": "C36042"},
             {"label": "Kleinkahl", "value": "C36043"},
             {"label": "Kleinlangheim", "value": "C36044"},
             {"label": "Kleinostheim", "value": "C36045"},
             {"label": "Kleinrinderfeld", "value": "C36046"},
             {"label": "Kleinsendelbach", "value": "C36047"},
             {"label": "Kleinwallstadt", "value": "C36048"},
             {"label": "Klingenberg am Main", "value": "C36049"},
             {"label": "Klosterlechfeld", "value": "C36050"},
             {"label": "Knetzgau", "value": "C36051"},
             {"label": "Kochel", "value": "C36052"},
             {"label": "Koditz", "value": "C36053"},
             {"label": "Kodnitz", "value": "C36054"},
             {"label": "Kofering", "value": "C36055"},
             {"label": "Kohlberg", "value": "C36056"},
             {"label": "Kolbermoor", "value": "C36057"},
             {"label": "Kolitzheim", "value": "C36058"},
             {"label": "Kollnburg", "value": "C36059"},
             {"label": "Konigsbrunn", "value": "C36060"},
             {"label": "Konigsdorf", "value": "C36061"},
             {"label": "Konigsfeld", "value": "C36062"},
             {"label": "Konigstein", "value": "C36063"},
             {"label": "Konradsreuth", "value": "C36064"},
             {"label": "Konzell", "value": "C36065"},
             {"label": "Kottgeisering", "value": "C36066"},
             {"label": "Kotzting", "value": "C36067"},
             {"label": "Kraiburg am Inn", "value": "C36068"},
             {"label": "Krailling", "value": "C36069"},
             {"label": "Kreut", "value": "C36070"},
             {"label": "Kreuzwertheim", "value": "C36071"},
             {"label": "Krombach", "value": "C36072"},
             {"label": "Kronach", "value": "C36073"},
             {"label": "Kronburg", "value": "C36074"},
             {"label": "Krumbach", "value": "C36075"},
             {"label": "Krummennaab", "value": "C36076"},
             {"label": "Krun", "value": "C36077"},
             {"label": "Kueps Oberfranken", "value": "C36078"},
             {"label": "Kuhbach", "value": "C36079"},
             {"label": "Kulmain", "value": "C36080"},
             {"label": "Kulmbach", "value": "C36081"},
             {"label": "Kumhausen", "value": "C36082"},
             {"label": "Kummersbruck", "value": "C36083"},
             {"label": "Kunreuth", "value": "C36084"},
             {"label": "Kunzing", "value": "C36085"},
             {"label": "Kupferberg", "value": "C36086"},
             {"label": "Kurnach", "value": "C36087"},
             {"label": "Kutzenhausen", "value": "C36088"},
             {"label": "Laberweinting", "value": "C36089"},
             {"label": "Lachen", "value": "C36090"},
             {"label": "Lalling", "value": "C36091"},
             {"label": "Lam", "value": "C36092"},
             {"label": "Lamerdingen", "value": "C36093"},
             {"label": "Landau an der Isar", "value": "C36094"},
             {"label": "Landsberg am Lech", "value": "C36095"},
             {"label": "Landsberied", "value": "C36096"},
             {"label": "Landshut", "value": "C36097"},
             {"label": "Langenaltheim", "value": "C36098"},
             {"label": "Langenbach", "value": "C36099"},
             {"label": "Langenfeld", "value": "C36100"},
             {"label": "Langenmosen", "value": "C36101"},
             {"label": "Langenneufnach", "value": "C36102"},
             {"label": "Langenpreising", "value": "C36103"},
             {"label": "Langensendelbach", "value": "C36104"},
             {"label": "Langenzenn", "value": "C36105"},
             {"label": "Langerringen", "value": "C36106"},
             {"label": "Langfurth", "value": "C36107"},
             {"label": "Langquaid", "value": "C36108"},
             {"label": "Langweid", "value": "C36109"},
             {"label": "Lappersdorf", "value": "C36110"},
             {"label": "Lauben", "value": "C36111"},
             {"label": "Laudenbach", "value": "C36112"},
             {"label": "Lauf an der Pegnitz", "value": "C36113"},
             {"label": "Laufach", "value": "C36114"},
             {"label": "Laufen", "value": "C36115"},
             {"label": "Laugna", "value": "C36116"},
             {"label": "Lauingen", "value": "C36117"},
             {"label": "Lauter", "value": "C36118"},
             {"label": "Lauterhofen", "value": "C36119"},
             {"label": "Lautertal", "value": "C36120"},
             {"label": "Lautrach", "value": "C36121"},
             {"label": "Lechbruck", "value": "C36122"},
             {"label": "Legau", "value": "C36123"},
             {"label": "Lehrberg", "value": "C36124"},
             {"label": "Leiblfing", "value": "C36125"},
             {"label": "Leidersbach", "value": "C36126"},
             {"label": "Leinburg", "value": "C36127"},
             {"label": "Leipheim", "value": "C36128"},
             {"label": "Lengdorf", "value": "C36129"},
             {"label": "Lengenwang", "value": "C36130"},
             {"label": "Lenggries", "value": "C36131"},
             {"label": "Leonberg", "value": "C36132"},
             {"label": "Leuchtenberg", "value": "C36133"},
             {"label": "Leupoldsgrun", "value": "C36134"},
             {"label": "Leutenbach", "value": "C36135"},
             {"label": "Leutershausen", "value": "C36136"},
             {"label": "Lichtenau", "value": "C36137"},
             {"label": "Lichtenberg", "value": "C36138"},
             {"label": "Lichtenfels", "value": "C36139"},
             {"label": "Lindau", "value": "C36140"},
             {"label": "Lindberg", "value": "C36141"},
             {"label": "Litzendorf", "value": "C36142"},
             {"label": "Lohberg", "value": "C36143"},
             {"label": "Lohr am Main", "value": "C36144"},
             {"label": "Loiching", "value": "C36145"},
             {"label": "Lonnerstadt", "value": "C36146"},
             {"label": "Lower Bavaria", "value": "C36147"},
             {"label": "Ludwigsstadt", "value": "C36148"},
             {"label": "Luhe-Wildenau", "value": "C36149"},
             {"label": "Lupburg", "value": "C36150"},
             {"label": "Mahring", "value": "C36151"},
             {"label": "Maierhofen", "value": "C36152"},
             {"label": "Maihingen", "value": "C36153"},
             {"label": "Mainaschaff", "value": "C36154"},
             {"label": "Mainbernheim", "value": "C36155"},
             {"label": "Mainburg", "value": "C36156"},
             {"label": "Mainleus", "value": "C36157"},
             {"label": "Mainstockheim", "value": "C36158"},
             {"label": "Maisach", "value": "C36159"},
             {"label": "Maitenbeth", "value": "C36160"},
             {"label": "Malching", "value": "C36161"},
             {"label": "Malgersdorf", "value": "C36162"},
             {"label": "Mallersdorf-Pfaffenberg", "value": "C36163"},
             {"label": "Mammendorf", "value": "C36164"},
             {"label": "Mamming", "value": "C36165"},
             {"label": "Manching", "value": "C36166"},
             {"label": "Mantel", "value": "C36167"},
             {"label": "Margetshochheim", "value": "C36168"},
             {"label": "Mariaposching", "value": "C36169"},
             {"label": "Marklkofen", "value": "C36170"},
             {"label": "Markt Berolzheim", "value": "C36171"},
             {"label": "Markt Bibart", "value": "C36172"},
             {"label": "Markt Einersheim", "value": "C36173"},
             {"label": "Markt Erlbach", "value": "C36174"},
             {"label": "Markt Indersdorf", "value": "C36175"},
             {"label": "Markt Nordheim", "value": "C36176"},
             {"label": "Markt Rettenbach", "value": "C36177"},
             {"label": "Markt Schwaben", "value": "C36178"},
             {"label": "Markt Taschendorf", "value": "C36179"},
             {"label": "Markt Wald", "value": "C36180"},
             {"label": "Marktbergel", "value": "C36181"},
             {"label": "Marktbreit", "value": "C36182"},
             {"label": "Marktgraitz", "value": "C36183"},
             {"label": "Marktheidenfeld", "value": "C36184"},
             {"label": "Marktl", "value": "C36185"},
             {"label": "Marktleugast", "value": "C36186"},
             {"label": "Marktleuthen", "value": "C36187"},
             {"label": "Marktoberdorf", "value": "C36188"},
             {"label": "Marktoffingen", "value": "C36189"},
             {"label": "Marktredwitz", "value": "C36190"},
             {"label": "Marktrodach", "value": "C36191"},
             {"label": "Marktschellenberg", "value": "C36192"},
             {"label": "Marktschorgast", "value": "C36193"},
             {"label": "Marktsteft", "value": "C36194"},
             {"label": "Marktzeuln", "value": "C36195"},
             {"label": "Marloffstein", "value": "C36196"},
             {"label": "Maroldsweisach", "value": "C36197"},
             {"label": "Marquartstein", "value": "C36198"},
             {"label": "Martinsheim", "value": "C36199"},
             {"label": "Marxheim", "value": "C36200"},
             {"label": "Marzling", "value": "C36201"},
             {"label": "Massbach", "value": "C36202"},
             {"label": "Massing", "value": "C36203"},
             {"label": "Mauern", "value": "C36204"},
             {"label": "Mauerstetten", "value": "C36205"},
             {"label": "Mauth", "value": "C36206"},
             {"label": "Maxhutte-Haidhof", "value": "C36207"},
             {"label": "Medlingen", "value": "C36208"},
             {"label": "Meeder", "value": "C36209"},
             {"label": "Mehlmeisel", "value": "C36210"},
             {"label": "Mehring", "value": "C36211"},
             {"label": "Meitingen", "value": "C36212"},
             {"label": "Mellrichstadt", "value": "C36213"},
             {"label": "Memmelsdorf", "value": "C36214"},
             {"label": "Memmingen", "value": "C36215"},
             {"label": "Memmingerberg", "value": "C36216"},
             {"label": "Mengkofen", "value": "C36217"},
             {"label": "Merching", "value": "C36218"},
             {"label": "Mering", "value": "C36219"},
             {"label": "Merkendorf", "value": "C36220"},
             {"label": "Mertingen", "value": "C36221"},
             {"label": "Mespelbrunn", "value": "C36222"},
             {"label": "Metten", "value": "C36223"},
             {"label": "Mettenheim", "value": "C36224"},
             {"label": "Michelau", "value": "C36225"},
             {"label": "Michelsneukirchen", "value": "C36226"},
             {"label": "Mickhausen", "value": "C36227"},
             {"label": "Miesbach", "value": "C36228"},
             {"label": "Miltach", "value": "C36229"},
             {"label": "Miltenberg", "value": "C36230"},
             {"label": "Mindelheim", "value": "C36231"},
             {"label": "Mintraching", "value": "C36232"},
             {"label": "Missen-Wilhams", "value": "C36233"},
             {"label": "Mistelgau", "value": "C36234"},
             {"label": "Mitteleschenbach", "value": "C36235"},
             {"label": "Mittelneufnach", "value": "C36236"},
             {"label": "Mittelstetten", "value": "C36237"},
             {"label": "Mittenwald", "value": "C36238"},
             {"label": "Mitterfels", "value": "C36239"},
             {"label": "Mitterskirchen", "value": "C36240"},
             {"label": "Mitterteich", "value": "C36241"},
             {"label": "Mitwitz", "value": "C36242"},
             {"label": "Modingen", "value": "C36243"},
             {"label": "Mohrendorf", "value": "C36244"},
             {"label": "Mombris", "value": "C36245"},
             {"label": "Momlingen", "value": "C36246"},
             {"label": "Monchberg", "value": "C36247"},
             {"label": "Monchsdeggingen", "value": "C36248"},
             {"label": "Monchsroth", "value": "C36249"},
             {"label": "Monheim", "value": "C36250"},
             {"label": "Moorenweis", "value": "C36251"},
             {"label": "Moosach", "value": "C36252"},
             {"label": "Moosbach", "value": "C36253"},
             {"label": "Moosburg", "value": "C36254"},
             {"label": "Moosinning", "value": "C36255"},
             {"label": "Moosthenning", "value": "C36256"},
             {"label": "Motten", "value": "C36257"},
             {"label": "Mottingen", "value": "C36258"},
             {"label": "Motzing", "value": "C36259"},
             {"label": "Muhldorf", "value": "C36260"},
             {"label": "Muhlhausen", "value": "C36261"},
             {"label": "Muhr am See", "value": "C36262"},
             {"label": "Munchberg", "value": "C36263"},
             {"label": "Munchsmunster", "value": "C36264"},
             {"label": "Munchsteinach", "value": "C36265"},
             {"label": "Munich", "value": "C36266"},
             {"label": "Munnerstadt", "value": "C36267"},
             {"label": "Munningen", "value": "C36268"},
             {"label": "Munsing", "value": "C36269"},
             {"label": "Munster", "value": "C36270"},
             {"label": "Munsterhausen", "value": "C36271"},
             {"label": "Murnau am Staffelsee", "value": "C36272"},
             {"label": "Nabburg", "value": "C36273"},
             {"label": "Nagel", "value": "C36274"},
             {"label": "Naila", "value": "C36275"},
             {"label": "Nandlstadt", "value": "C36276"},
             {"label": "Nennslingen", "value": "C36277"},
             {"label": "Nersingen", "value": "C36278"},
             {"label": "Nesselwang", "value": "C36279"},
             {"label": "Neu-Ulm", "value": "C36280"},
             {"label": "Neualbenreuth", "value": "C36281"},
             {"label": "Neubeuern", "value": "C36282"},
             {"label": "Neubiberg", "value": "C36283"},
             {"label": "Neubrunn", "value": "C36284"},
             {"label": "Neuburg", "value": "C36285"},
             {"label": "Neuburg an der Donau", "value": "C36286"},
             {"label": "Neudrossenfeld", "value": "C36287"},
             {"label": "Neuendettelsau", "value": "C36288"},
             {"label": "Neuenmarkt", "value": "C36289"},
             {"label": "Neufahrn", "value": "C36290"},
             {"label": "Neufahrn bei Freising", "value": "C36291"},
             {"label": "Neufraunhofen", "value": "C36292"},
             {"label": "Neuhaus am Inn", "value": "C36293"},
             {"label": "Neuhaus an der Pegnitz", "value": "C36294"},
             {"label": "Neuhof an der Zenn", "value": "C36295"},
             {"label": "Neuhutten", "value": "C36296"},
             {"label": "Neukirchen", "value": "C36297"},
             {"label": "Neukirchen-Balbini", "value": "C36298"},
             {"label": "Neumarkt in der Oberpfalz", "value": "C36299"},
             {"label": "Neumarkt-Sankt Veit", "value": "C36300"},
             {"label": "Neunburg vorm Wald", "value": "C36301"},
             {"label": "Neunkirchen am Brand", "value": "C36302"},
             {"label": "Neunkirchen am Main", "value": "C36303"},
             {"label": "Neunkirchen am Sand", "value": "C36304"},
             {"label": "Neuotting", "value": "C36305"},
             {"label": "Neureichenau", "value": "C36306"},
             {"label": "Neuried", "value": "C36307"},
             {"label": "Neuschonau", "value": "C36308"},
             {"label": "Neusitz", "value": "C36309"},
             {"label": "Neusorg", "value": "C36310"},
             {"label": "Neustadt am Main", "value": "C36311"},
             {"label": "Neustadt an der Aisch", "value": "C36312"},
             {"label": "Neustadt an der Donau", "value": "C36313"},
             {"label": "Neustadt an der Waldnaab", "value": "C36314"},
             {"label": "Neustadt bei Coburg", "value": "C36315"},
             {"label": "Neutraubling", "value": "C36316"},
             {"label": "Niederaichbach", "value": "C36317"},
             {"label": "Niederalteich", "value": "C36318"},
             {"label": "Niederbergkirchen", "value": "C36319"},
             {"label": "Niederfullbach", "value": "C36320"},
             {"label": "Niederlauer", "value": "C36321"},
             {"label": "Niedermurach", "value": "C36322"},
             {"label": "Niedernberg", "value": "C36323"},
             {"label": "Niederrieden", "value": "C36324"},
             {"label": "Niederschonenfeld", "value": "C36325"},
             {"label": "Niedertaufkirchen", "value": "C36326"},
             {"label": "Niederviehbach", "value": "C36327"},
             {"label": "Niederwerrn", "value": "C36328"},
             {"label": "Niederwinkling", "value": "C36329"},
             {"label": "Nittenau", "value": "C36330"},
             {"label": "Nittendorf", "value": "C36331"},
             {"label": "Nonnenhorn", "value": "C36332"},
             {"label": "Nordendorf", "value": "C36333"},
             {"label": "Nordhalben", "value": "C36334"},
             {"label": "Nordheim", "value": "C36335"},
             {"label": "Nordlingen", "value": "C36336"},
             {"label": "Nudlingen", "value": "C36337"},
             {"label": "Nurnberg", "value": "C36338"},
             {"label": "Nussdorf", "value": "C36339"},
             {"label": "Nussdorf am Inn", "value": "C36340"},
             {"label": "Oberammergau", "value": "C36341"},
             {"label": "Oberasbach", "value": "C36342"},
             {"label": "Oberaudorf", "value": "C36343"},
             {"label": "Oberbergkirchen", "value": "C36344"},
             {"label": "Oberdachstetten", "value": "C36345"},
             {"label": "Oberding", "value": "C36346"},
             {"label": "Oberelsbach", "value": "C36347"},
             {"label": "Obergriesbach", "value": "C36348"},
             {"label": "Obergunzburg", "value": "C36349"},
             {"label": "Oberhaching", "value": "C36350"},
             {"label": "Oberhaid", "value": "C36351"},
             {"label": "Oberhausen", "value": "C36352"},
             {"label": "Oberkotzau", "value": "C36353"},
             {"label": "Oberleichtersbach", "value": "C36354"},
             {"label": "Obermeitingen", "value": "C36355"},
             {"label": "Obermichelbach", "value": "C36356"},
             {"label": "Obernbreit", "value": "C36357"},
             {"label": "Obernburg am Main", "value": "C36358"},
             {"label": "Oberndorf", "value": "C36359"},
             {"label": "Obernzell", "value": "C36360"},
             {"label": "Obernzenn", "value": "C36361"},
             {"label": "Oberostendorf", "value": "C36362"},
             {"label": "Oberottmarshausen", "value": "C36363"},
             {"label": "Oberpframmern", "value": "C36364"},
             {"label": "Oberpleichfeld", "value": "C36365"},
             {"label": "Oberporing", "value": "C36366"},
             {"label": "Oberreichenbach", "value": "C36367"},
             {"label": "Oberreute", "value": "C36368"},
             {"label": "Oberrieden", "value": "C36369"},
             {"label": "Oberscheinfeld", "value": "C36370"},
             {"label": "Oberschleissheim", "value": "C36371"},
             {"label": "Oberschneiding", "value": "C36372"},
             {"label": "Oberschwarzach", "value": "C36373"},
             {"label": "Oberschweinbach", "value": "C36374"},
             {"label": "Obersinn", "value": "C36375"},
             {"label": "Obersochering", "value": "C36376"},
             {"label": "Oberstaufen", "value": "C36377"},
             {"label": "Oberstdorf", "value": "C36378"},
             {"label": "Oberstreu", "value": "C36379"},
             {"label": "Obersussbach", "value": "C36380"},
             {"label": "Obertaufkirchen", "value": "C36381"},
             {"label": "Oberthulba", "value": "C36382"},
             {"label": "Obertraubling", "value": "C36383"},
             {"label": "Obertrubach", "value": "C36384"},
             {"label": "Oberviechtach", "value": "C36385"},
             {"label": "Obing", "value": "C36386"},
             {"label": "Ochsenfurt", "value": "C36387"},
             {"label": "Odelzhausen", "value": "C36388"},
             {"label": "Oerlenbach", "value": "C36389"},
             {"label": "Oettingen in Bayern", "value": "C36390"},
             {"label": "Offenberg", "value": "C36391"},
             {"label": "Offenhausen", "value": "C36392"},
             {"label": "Offingen", "value": "C36393"},
             {"label": "Ofterschwang", "value": "C36394"},
             {"label": "Ohlstadt", "value": "C36395"},
             {"label": "Olching", "value": "C36396"},
             {"label": "Opfenbach", "value": "C36397"},
             {"label": "Ornbau", "value": "C36398"},
             {"label": "Ortenburg", "value": "C36399"},
             {"label": "Osterhofen", "value": "C36400"},
             {"label": "Ostheim vor der Rhon", "value": "C36401"},
             {"label": "Ottenhofen", "value": "C36402"},
             {"label": "Ottensoos", "value": "C36403"},
             {"label": "Otterfing", "value": "C36404"},
             {"label": "Ottobeuren", "value": "C36405"},
             {"label": "Ottobrunn", "value": "C36406"},
             {"label": "Otzing", "value": "C36407"},
             {"label": "Oy-Mittelberg", "value": "C36408"},
             {"label": "Pahl", "value": "C36409"},
             {"label": "Painten", "value": "C36410"},
             {"label": "Palling", "value": "C36411"},
             {"label": "Pappenheim", "value": "C36412"},
             {"label": "Parkstein", "value": "C36413"},
             {"label": "Parkstetten", "value": "C36414"},
             {"label": "Parsberg", "value": "C36415"},
             {"label": "Partenstein", "value": "C36416"},
             {"label": "Pasing", "value": "C36417"},
             {"label": "Passau", "value": "C36418"},
             {"label": "Pastetten", "value": "C36419"},
             {"label": "Patersdorf", "value": "C36420"},
             {"label": "Paunzhausen", "value": "C36421"},
             {"label": "Pechbrunn", "value": "C36422"},
             {"label": "Pegnitz", "value": "C36423"},
             {"label": "Peissenberg", "value": "C36424"},
             {"label": "Peiting", "value": "C36425"},
             {"label": "Pemfling", "value": "C36426"},
             {"label": "Pentling", "value": "C36427"},
             {"label": "Penzberg", "value": "C36428"},
             {"label": "Penzing", "value": "C36429"},
             {"label": "Perach", "value": "C36430"},
             {"label": "Perkam", "value": "C36431"},
             {"label": "Perlesreut", "value": "C36432"},
             {"label": "Petersaurach", "value": "C36433"},
             {"label": "Petersdorf", "value": "C36434"},
             {"label": "Petershausen", "value": "C36435"},
             {"label": "Pettendorf", "value": "C36436"},
             {"label": "Petting", "value": "C36437"},
             {"label": "Pettstadt", "value": "C36438"},
             {"label": "Pfaffenhausen", "value": "C36439"},
             {"label": "Pfaffenhofen", "value": "C36440"},
             {"label": "Pfaffenhofen an der Ilm", "value": "C36441"},
             {"label": "Pfaffenhofen an der Roth", "value": "C36442"},
             {"label": "Pfaffing", "value": "C36443"},
             {"label": "Pfakofen", "value": "C36444"},
             {"label": "Pfarrkirchen", "value": "C36445"},
             {"label": "Pfarrweisach", "value": "C36446"},
             {"label": "Pfatter", "value": "C36447"},
             {"label": "Pfeffenhausen", "value": "C36448"},
             {"label": "Pfofeld", "value": "C36449"},
             {"label": "Pforzen", "value": "C36450"},
             {"label": "Pfreimd", "value": "C36451"},
             {"label": "Pfronten", "value": "C36452"},
             {"label": "Piding", "value": "C36453"},
             {"label": "Pielenhofen", "value": "C36454"},
             {"label": "Pilsach", "value": "C36455"},
             {"label": "Pilsting", "value": "C36456"},
             {"label": "Pinzberg", "value": "C36457"},
             {"label": "Pirk", "value": "C36458"},
             {"label": "Pittenhart", "value": "C36459"},
             {"label": "Planegg", "value": "C36460"},
             {"label": "Plattling", "value": "C36461"},
             {"label": "Pleinfeld", "value": "C36462"},
             {"label": "Pleiskirchen", "value": "C36463"},
             {"label": "Pleystein", "value": "C36464"},
             {"label": "Pliening", "value": "C36465"},
             {"label": "Plossberg", "value": "C36466"},
             {"label": "Pocking", "value": "C36467"},
             {"label": "Poing", "value": "C36468"},
             {"label": "Polling", "value": "C36469"},
             {"label": "Polsingen", "value": "C36470"},
             {"label": "Pommelsbrunn", "value": "C36471"},
             {"label": "Pommersfelden", "value": "C36472"},
             {"label": "Poppenhausen", "value": "C36473"},
             {"label": "Poppenricht", "value": "C36474"},
             {"label": "Pornbach", "value": "C36475"},
             {"label": "Postau", "value": "C36476"},
             {"label": "Postbauer-Heng", "value": "C36477"},
             {"label": "Postmunster", "value": "C36478"},
             {"label": "Pottenstein", "value": "C36479"},
             {"label": "Pottmes", "value": "C36480"},
             {"label": "Poxdorf", "value": "C36481"},
             {"label": "Prackenbach", "value": "C36482"},
             {"label": "Prebitz", "value": "C36483"},
             {"label": "Pressath", "value": "C36484"},
             {"label": "Presseck", "value": "C36485"},
             {"label": "Pressig", "value": "C36486"},
             {"label": "Pretzfeld", "value": "C36487"},
             {"label": "Prichsenstadt", "value": "C36488"},
             {"label": "Prien am Chiemsee", "value": "C36489"},
             {"label": "Priesendorf", "value": "C36490"},
             {"label": "Prittriching", "value": "C36491"},
             {"label": "Prosselsheim", "value": "C36492"},
             {"label": "Prutting", "value": "C36493"},
             {"label": "Puchersreuth", "value": "C36494"},
             {"label": "Puchheim", "value": "C36495"},
             {"label": "Pullach im Isartal", "value": "C36496"},
             {"label": "Pullenreuth", "value": "C36497"},
             {"label": "Purgen", "value": "C36498"},
             {"label": "Puschendorf", "value": "C36499"},
             {"label": "Putzbrunn", "value": "C36500"},
             {"label": "Pyrbaum", "value": "C36501"},
             {"label": "Rain", "value": "C36502"},
             {"label": "Raisting", "value": "C36503"},
             {"label": "Raitenbuch", "value": "C36504"},
             {"label": "Ramerberg", "value": "C36505"},
             {"label": "Ramsau", "value": "C36506"},
             {"label": "Ramsthal", "value": "C36507"},
             {"label": "Randersacker", "value": "C36508"},
             {"label": "Rannungen", "value": "C36509"},
             {"label": "Rattelsdorf", "value": "C36510"},
             {"label": "Rattenberg", "value": "C36511"},
             {"label": "Rattiszell", "value": "C36512"},
             {"label": "Raubling", "value": "C36513"},
             {"label": "Rechtenbach", "value": "C36514"},
             {"label": "Rechtmehring", "value": "C36515"},
             {"label": "Rednitzhembach", "value": "C36516"},
             {"label": "Redwitz an der Rodach", "value": "C36517"},
             {"label": "Regen", "value": "C36518"},
             {"label": "Regensburg", "value": "C36519"},
             {"label": "Regenstauf", "value": "C36520"},
             {"label": "Regierungsbezirk Mittelfranken", "value": "C36521"},
             {"label": "Regierungsbezirk Unterfranken", "value": "C36522"},
             {"label": "Regnitzlosau", "value": "C36523"},
             {"label": "Rehau", "value": "C36524"},
             {"label": "Rehling", "value": "C36525"},
             {"label": "Reichenbach", "value": "C36526"},
             {"label": "Reichenberg", "value": "C36527"},
             {"label": "Reichenschwand", "value": "C36528"},
             {"label": "Reichersbeuern", "value": "C36529"},
             {"label": "Reichertshausen", "value": "C36530"},
             {"label": "Reichertsheim", "value": "C36531"},
             {"label": "Reichertshofen", "value": "C36532"},
             {"label": "Reichling", "value": "C36533"},
             {"label": "Reimlingen", "value": "C36534"},
             {"label": "Reinhausen", "value": "C36535"},
             {"label": "Reisbach", "value": "C36536"},
             {"label": "Reischach", "value": "C36537"},
             {"label": "Reit im Winkl", "value": "C36538"},
             {"label": "Remlingen", "value": "C36539"},
             {"label": "Rennertshofen", "value": "C36540"},
             {"label": "Rentweinsdorf", "value": "C36541"},
             {"label": "Rettenbach", "value": "C36542"},
             {"label": "Rettenberg", "value": "C36543"},
             {"label": "Retzstadt", "value": "C36544"},
             {"label": "Reut", "value": "C36545"},
             {"label": "Reuth", "value": "C36546"},
             {"label": "Ried", "value": "C36547"},
             {"label": "Rieden", "value": "C36548"},
             {"label": "Rieden an der Kotz", "value": "C36549"},
             {"label": "Riedenberg", "value": "C36550"},
             {"label": "Riedenburg", "value": "C36551"},
             {"label": "Riedering", "value": "C36552"},
             {"label": "Riegsee", "value": "C36553"},
             {"label": "Rieneck", "value": "C36554"},
             {"label": "Rimbach", "value": "C36555"},
             {"label": "Rimpar", "value": "C36556"},
             {"label": "Rimsting", "value": "C36557"},
             {"label": "Rinchnach", "value": "C36558"},
             {"label": "Ringelai", "value": "C36559"},
             {"label": "Rodelsee", "value": "C36560"},
             {"label": "Roden", "value": "C36561"},
             {"label": "Rodental", "value": "C36562"},
             {"label": "Roding", "value": "C36563"},
             {"label": "Rofingen", "value": "C36564"},
             {"label": "Roggenburg", "value": "C36565"},
             {"label": "Rohr", "value": "C36566"},
             {"label": "Rohrbach", "value": "C36567"},
             {"label": "Rohrdorf", "value": "C36568"},
             {"label": "Rohrenfels", "value": "C36569"},
             {"label": "Rohrmoos", "value": "C36570"},
             {"label": "Rohrnbach", "value": "C36571"},
             {"label": "Rollbach", "value": "C36572"},
             {"label": "Ronsberg", "value": "C36573"},
             {"label": "Rosenheim", "value": "C36574"},
             {"label": "Roslau", "value": "C36575"},
             {"label": "Rossbach", "value": "C36576"},
             {"label": "Rosshaupten", "value": "C36577"},
             {"label": "Rosstal", "value": "C36578"},
             {"label": "Roth", "value": "C36579"},
             {"label": "Rothenbach", "value": "C36580"},
             {"label": "Rothenbach an der Pegnitz", "value": "C36581"},
             {"label": "Rothenbuch", "value": "C36582"},
             {"label": "Rothenburg ob der Tauber", "value": "C36583"},
             {"label": "Rothenfels", "value": "C36584"},
             {"label": "Rothlein", "value": "C36585"},
             {"label": "Rott", "value": "C36586"},
             {"label": "Rottach-Egern", "value": "C36587"},
             {"label": "Rottenbach", "value": "C36588"},
             {"label": "Rottenbuch", "value": "C36589"},
             {"label": "Rottenburg an der Laaber", "value": "C36590"},
             {"label": "Rottendorf", "value": "C36591"},
             {"label": "Rotthalmunster", "value": "C36592"},
             {"label": "Rottingen", "value": "C36593"},
             {"label": "Rotz", "value": "C36594"},
             {"label": "Ruckersdorf", "value": "C36595"},
             {"label": "Rudelzhausen", "value": "C36596"},
             {"label": "Ruderatshofen", "value": "C36597"},
             {"label": "Ruderting", "value": "C36598"},
             {"label": "Rugendorf", "value": "C36599"},
             {"label": "Rugland", "value": "C36600"},
             {"label": "Ruhmannsfelden", "value": "C36601"},
             {"label": "Ruhpolding", "value": "C36602"},
             {"label": "Ruhstorf", "value": "C36603"},
             {"label": "Runding", "value": "C36604"},
             {"label": "Saal", "value": "C36605"},
             {"label": "Saal an der Saale", "value": "C36606"},
             {"label": "Sachsen", "value": "C36607"},
             {"label": "Sachsenkam", "value": "C36608"},
             {"label": "Sailauf", "value": "C36609"},
             {"label": "Salching", "value": "C36610"},
             {"label": "Saldenburg", "value": "C36611"},
             {"label": "Salgen", "value": "C36612"},
             {"label": "Salz", "value": "C36613"},
             {"label": "Salzweg", "value": "C36614"},
             {"label": "Samerberg", "value": "C36615"},
             {"label": "Sand", "value": "C36616"},
             {"label": "Sandberg", "value": "C36617"},
             {"label": "Sankt Englmar", "value": "C36618"},
             {"label": "Sankt Leonhard am Wonneberg", "value": "C36619"},
             {"label": "Sankt Wolfgang", "value": "C36620"},
             {"label": "Sauerlach", "value": "C36621"},
             {"label": "Saulgrub", "value": "C36622"},
             {"label": "Schaftlarn", "value": "C36623"},
             {"label": "Schauenstein", "value": "C36624"},
             {"label": "Schaufling", "value": "C36625"},
             {"label": "Schechen", "value": "C36626"},
             {"label": "Scheidegg", "value": "C36627"},
             {"label": "Scheinfeld", "value": "C36628"},
             {"label": "Scherstetten", "value": "C36629"},
             {"label": "Schesslitz", "value": "C36630"},
             {"label": "Scheuring", "value": "C36631"},
             {"label": "Scheyern", "value": "C36632"},
             {"label": "Schierling", "value": "C36633"},
             {"label": "Schillingsfurst", "value": "C36634"},
             {"label": "Schiltberg", "value": "C36635"},
             {"label": "Schirmitz", "value": "C36636"},
             {"label": "Schirnding", "value": "C36637"},
             {"label": "Schleching", "value": "C36638"},
             {"label": "Schlehdorf", "value": "C36639"},
             {"label": "Schliersee", "value": "C36640"},
             {"label": "Schlusselfeld", "value": "C36641"},
             {"label": "Schmidgaden", "value": "C36642"},
             {"label": "Schmidmuhlen", "value": "C36643"},
             {"label": "Schmiechen", "value": "C36644"},
             {"label": "Schnaitsee", "value": "C36645"},
             {"label": "Schnaittach", "value": "C36646"},
             {"label": "Schnaittenbach", "value": "C36647"},
             {"label": "Schneckenlohe", "value": "C36648"},
             {"label": "Schneizlreuth", "value": "C36649"},
             {"label": "Schnelldorf", "value": "C36650"},
             {"label": "Schofweg", "value": "C36651"},
             {"label": "Schollkrippen", "value": "C36652"},
             {"label": "Schollnach", "value": "C36653"},
             {"label": "Schonau", "value": "C36654"},
             {"label": "Schonau am Konigssee", "value": "C36655"},
             {"label": "Schondorf am Ammersee", "value": "C36656"},
             {"label": "Schondra", "value": "C36657"},
             {"label": "Schongau", "value": "C36658"},
             {"label": "Schongeising", "value": "C36659"},
             {"label": "Schonsee", "value": "C36660"},
             {"label": "Schonstett", "value": "C36661"},
             {"label": "Schonthal", "value": "C36662"},
             {"label": "Schonungen", "value": "C36663"},
             {"label": "Schonwald", "value": "C36664"},
             {"label": "Schopfloch", "value": "C36665"},
             {"label": "Schorndorf", "value": "C36666"},
             {"label": "Schrobenhausen", "value": "C36667"},
             {"label": "Schwabach", "value": "C36668"},
             {"label": "Schwabhausen", "value": "C36669"},
             {"label": "Schwabmunchen", "value": "C36670"},
             {"label": "Schwabsoien", "value": "C36671"},
             {"label": "Schwaig", "value": "C36672"},
             {"label": "Schwandorf in Bayern", "value": "C36673"},
             {"label": "Schwanfeld", "value": "C36674"},
             {"label": "Schwangau", "value": "C36675"},
             {"label": "Schwarzach", "value": "C36676"},
             {"label": "Schwarzenbach", "value": "C36677"},
             {"label": "Schwarzenbach an der Saale", "value": "C36678"},
             {"label": "Schwarzenbruck", "value": "C36679"},
             {"label": "Schwarzenfeld", "value": "C36680"},
             {"label": "Schwarzhofen", "value": "C36681"},
             {"label": "Schwebheim", "value": "C36682"},
             {"label": "Schweinfurt", "value": "C36683"},
             {"label": "Schweitenkirchen", "value": "C36684"},
             {"label": "Schwenningen", "value": "C36685"},
             {"label": "Schwindegg", "value": "C36686"},
             {"label": "Seefeld", "value": "C36687"},
             {"label": "Seeg", "value": "C36688"},
             {"label": "Seehausen am Staffelsee", "value": "C36689"},
             {"label": "Seeon-Seebruck", "value": "C36690"},
             {"label": "Seeshaupt", "value": "C36691"},
             {"label": "Seinsheim", "value": "C36692"},
             {"label": "Selb", "value": "C36693"},
             {"label": "Selbitz", "value": "C36694"},
             {"label": "Senden", "value": "C36695"},
             {"label": "Sengenthal", "value": "C36696"},
             {"label": "Sennfeld", "value": "C36697"},
             {"label": "Sesslach", "value": "C36698"},
             {"label": "Seubersdorf", "value": "C36699"},
             {"label": "Seukendorf", "value": "C36700"},
             {"label": "Seybothenreuth", "value": "C36701"},
             {"label": "Siegenburg", "value": "C36702"},
             {"label": "Siegsdorf", "value": "C36703"},
             {"label": "Sielenbach", "value": "C36704"},
             {"label": "Sigmarszell", "value": "C36705"},
             {"label": "Simbach", "value": "C36706"},
             {"label": "Simbach am Inn", "value": "C36707"},
             {"label": "Simmelsdorf", "value": "C36708"},
             {"label": "Sindelsdorf", "value": "C36709"},
             {"label": "Sinzing", "value": "C36710"},
             {"label": "Sochtenau", "value": "C36711"},
             {"label": "Solnhofen", "value": "C36712"},
             {"label": "Sommerach", "value": "C36713"},
             {"label": "Sommerhausen", "value": "C36714"},
             {"label": "Sommerkahl", "value": "C36715"},
             {"label": "Sondheim vor der Rhon", "value": "C36716"},
             {"label": "Sonnefeld", "value": "C36717"},
             {"label": "Sonnen", "value": "C36718"},
             {"label": "Sontheim", "value": "C36719"},
             {"label": "Sonthofen", "value": "C36720"},
             {"label": "Soyen", "value": "C36721"},
             {"label": "Spalt", "value": "C36722"},
             {"label": "Spardorf", "value": "C36723"},
             {"label": "Sparneck", "value": "C36724"},
             {"label": "Speichersdorf", "value": "C36725"},
             {"label": "Speinshart", "value": "C36726"},
             {"label": "Spiegelau", "value": "C36727"},
             {"label": "Stadelhofen", "value": "C36728"},
             {"label": "Stadtbergen", "value": "C36729"},
             {"label": "Stadtlauringen", "value": "C36730"},
             {"label": "Stadtprozelten", "value": "C36731"},
             {"label": "Stadtsteinach", "value": "C36732"},
             {"label": "Stallwang", "value": "C36733"},
             {"label": "Stammbach", "value": "C36734"},
             {"label": "Stammham", "value": "C36735"},
             {"label": "Stamsried", "value": "C36736"},
             {"label": "Starnberg", "value": "C36737"},
             {"label": "Staudach-Egerndach", "value": "C36738"},
             {"label": "Stegaurach", "value": "C36739"},
             {"label": "Stein", "value": "C36740"},
             {"label": "Steinach", "value": "C36741"},
             {"label": "Steinbach", "value": "C36742"},
             {"label": "Steinfeld", "value": "C36743"},
             {"label": "Steingaden", "value": "C36744"},
             {"label": "Steinheim", "value": "C36745"},
             {"label": "Steinhoring", "value": "C36746"},
             {"label": "Steinkirchen", "value": "C36747"},
             {"label": "Steinsfeld", "value": "C36748"},
             {"label": "Steinwiesen", "value": "C36749"},
             {"label": "Stephanskirchen", "value": "C36750"},
             {"label": "Stephansposching", "value": "C36751"},
             {"label": "Stetten", "value": "C36752"},
             {"label": "Stettfeld", "value": "C36753"},
             {"label": "Stiefenhofen", "value": "C36754"},
             {"label": "Stockheim", "value": "C36755"},
             {"label": "Stockstadt am Main", "value": "C36756"},
             {"label": "Stornstein", "value": "C36757"},
             {"label": "Stotten am Auerberg", "value": "C36758"},
             {"label": "Stottwang", "value": "C36759"},
             {"label": "Strasskirchen", "value": "C36760"},
             {"label": "Strasslach-Dingharting", "value": "C36761"},
             {"label": "Straubing", "value": "C36762"},
             {"label": "Strullendorf", "value": "C36763"},
             {"label": "Stubenberg", "value": "C36764"},
             {"label": "Stulln", "value": "C36765"},
             {"label": "Sugenheim", "value": "C36766"},
             {"label": "Sulzbach am Main", "value": "C36767"},
             {"label": "Sulzbach-Rosenberg", "value": "C36768"},
             {"label": "Sulzberg", "value": "C36769"},
             {"label": "Sulzdorf", "value": "C36770"},
             {"label": "Sulzemoos", "value": "C36771"},
             {"label": "Sulzfeld", "value": "C36772"},
             {"label": "Sulzfeld am Main", "value": "C36773"},
             {"label": "Sulzheim", "value": "C36774"},
             {"label": "Sunching", "value": "C36775"},
             {"label": "Surberg", "value": "C36776"},
             {"label": "Swabia", "value": "C36777"},
             {"label": "Tacherting", "value": "C36778"},
             {"label": "Taching am See", "value": "C36779"},
             {"label": "Tagmersheim", "value": "C36780"},
             {"label": "Tann", "value": "C36781"},
             {"label": "Tannesberg", "value": "C36782"},
             {"label": "Tapfheim", "value": "C36783"},
             {"label": "Taufkirchen", "value": "C36784"},
             {"label": "Tegernheim", "value": "C36785"},
             {"label": "Tegernsee", "value": "C36786"},
             {"label": "Teisendorf", "value": "C36787"},
             {"label": "Teising", "value": "C36788"},
             {"label": "Teisnach", "value": "C36789"},
             {"label": "Tettau", "value": "C36790"},
             {"label": "Tettenweis", "value": "C36791"},
             {"label": "Teublitz", "value": "C36792"},
             {"label": "Teugn", "value": "C36793"},
             {"label": "Teunz", "value": "C36794"},
             {"label": "Teuschnitz", "value": "C36795"},
             {"label": "Thalmassing", "value": "C36796"},
             {"label": "Thannhausen", "value": "C36797"},
             {"label": "Thanstein", "value": "C36798"},
             {"label": "Theilheim", "value": "C36799"},
             {"label": "Theisseil", "value": "C36800"},
             {"label": "Thierhaupten", "value": "C36801"},
             {"label": "Thiersheim", "value": "C36802"},
             {"label": "Thierstein", "value": "C36803"},
             {"label": "Thundorf in Unterfranken", "value": "C36804"},
             {"label": "Thungen", "value": "C36805"},
             {"label": "Thungersheim", "value": "C36806"},
             {"label": "Thurmansbang", "value": "C36807"},
             {"label": "Thurnau", "value": "C36808"},
             {"label": "Thyrnau", "value": "C36809"},
             {"label": "Tiefenbach", "value": "C36810"},
             {"label": "Tirschenreuth", "value": "C36811"},
             {"label": "Tittling", "value": "C36812"},
             {"label": "Tittmoning", "value": "C36813"},
             {"label": "Todtenweis", "value": "C36814"},
             {"label": "Toging am Inn", "value": "C36815"},
             {"label": "Topen", "value": "C36816"},
             {"label": "Trabitz", "value": "C36817"},
             {"label": "Train", "value": "C36818"},
             {"label": "Traitsching", "value": "C36819"},
             {"label": "Trappstadt", "value": "C36820"},
             {"label": "Traunreut", "value": "C36821"},
             {"label": "Traunstein", "value": "C36822"},
             {"label": "Trausnitz", "value": "C36823"},
             {"label": "Trautskirchen", "value": "C36824"},
             {"label": "Trebgast", "value": "C36825"},
             {"label": "Treffelstein", "value": "C36826"},
             {"label": "Treuchtlingen", "value": "C36827"},
             {"label": "Triftern", "value": "C36828"},
             {"label": "Trogen", "value": "C36829"},
             {"label": "Trostau", "value": "C36830"},
             {"label": "Trostberg an der Alz", "value": "C36831"},
             {"label": "Trunkelsberg", "value": "C36832"},
             {"label": "Tuchenbach", "value": "C36833"},
             {"label": "Tuntenhausen", "value": "C36834"},
             {"label": "Turkenfeld", "value": "C36835"},
             {"label": "Turkheim", "value": "C36836"},
             {"label": "Tussenhausen", "value": "C36837"},
             {"label": "Tussling", "value": "C36838"},
             {"label": "Tutzing", "value": "C36839"},
             {"label": "Tyrlaching", "value": "C36840"},
             {"label": "ubersee", "value": "C36841"},
             {"label": "uchtelhausen", "value": "C36842"},
             {"label": "Uehlfeld", "value": "C36843"},
             {"label": "Uettingen", "value": "C36844"},
             {"label": "Uffenheim", "value": "C36845"},
             {"label": "Uffing", "value": "C36846"},
             {"label": "Ungerhausen", "value": "C36847"},
             {"label": "Unterammergau", "value": "C36848"},
             {"label": "Unterdiessen", "value": "C36849"},
             {"label": "Unterdietfurt", "value": "C36850"},
             {"label": "Unteregg", "value": "C36851"},
             {"label": "Unterfohring", "value": "C36852"},
             {"label": "Untergriesbach", "value": "C36853"},
             {"label": "Unterhaching", "value": "C36854"},
             {"label": "Unterleinleiter", "value": "C36855"},
             {"label": "Untermeitingen", "value": "C36856"},
             {"label": "Untermerzbach", "value": "C36857"},
             {"label": "Unterneukirchen", "value": "C36858"},
             {"label": "Unterpleichfeld", "value": "C36859"},
             {"label": "Unterreit", "value": "C36860"},
             {"label": "Unterschleissheim", "value": "C36861"},
             {"label": "Untersiemau", "value": "C36862"},
             {"label": "Untersteinach", "value": "C36863"},
             {"label": "Unterthingau", "value": "C36864"},
             {"label": "Unterwossen", "value": "C36865"},
             {"label": "Untrasried", "value": "C36866"},
             {"label": "Upper Bavaria", "value": "C36867"},
             {"label": "Upper Franconia", "value": "C36868"},
             {"label": "Upper Palatinate", "value": "C36869"},
             {"label": "Ursberg", "value": "C36870"},
             {"label": "Ursensollen", "value": "C36871"},
             {"label": "Urspringen", "value": "C36872"},
             {"label": "Ustersbach", "value": "C36873"},
             {"label": "Uttenreuth", "value": "C36874"},
             {"label": "Utting am Ammersee", "value": "C36875"},
             {"label": "Vachendorf", "value": "C36876"},
             {"label": "Vagen", "value": "C36877"},
             {"label": "Valley", "value": "C36878"},
             {"label": "Vaterstetten", "value": "C36879"},
             {"label": "Veitsbronn", "value": "C36880"},
             {"label": "Veitshochheim", "value": "C36881"},
             {"label": "Velburg", "value": "C36882"},
             {"label": "Velden", "value": "C36883"},
             {"label": "Vestenbergsgreuth", "value": "C36884"},
             {"label": "Viechtach", "value": "C36885"},
             {"label": "Viereth-Trunstadt", "value": "C36886"},
             {"label": "Vierkirchen", "value": "C36887"},
             {"label": "Vilgertshofen", "value": "C36888"},
             {"label": "Villenbach", "value": "C36889"},
             {"label": "Vilsbiburg", "value": "C36890"},
             {"label": "Vilseck", "value": "C36891"},
             {"label": "Vilsheim", "value": "C36892"},
             {"label": "Vilshofen", "value": "C36893"},
             {"label": "Vogtareuth", "value": "C36894"},
             {"label": "Vohburg an der Donau", "value": "C36895"},
             {"label": "Vohenstrauss", "value": "C36896"},
             {"label": "Vohringen", "value": "C36897"},
             {"label": "Volkach", "value": "C36898"},
             {"label": "Volkenschwand", "value": "C36899"},
             {"label": "Vorbach", "value": "C36900"},
             {"label": "Vorra", "value": "C36901"},
             {"label": "Waakirchen", "value": "C36902"},
             {"label": "Waal", "value": "C36903"},
             {"label": "Wachenroth", "value": "C36904"},
             {"label": "Wackersberg", "value": "C36905"},
             {"label": "Wackersdorf", "value": "C36906"},
             {"label": "Waffenbrunn", "value": "C36907"},
             {"label": "Waging am See", "value": "C36908"},
             {"label": "Waidhaus", "value": "C36909"},
             {"label": "Waidhofen", "value": "C36910"},
             {"label": "Waigolshausen", "value": "C36911"},
             {"label": "Waischenfeld", "value": "C36912"},
             {"label": "Wald", "value": "C36913"},
             {"label": "Waldaschaff", "value": "C36914"},
             {"label": "Waldbrunn", "value": "C36915"},
             {"label": "Waldbuttelbrunn", "value": "C36916"},
             {"label": "Walderbach", "value": "C36917"},
             {"label": "Waldershof", "value": "C36918"},
             {"label": "Waldkirchen", "value": "C36919"},
             {"label": "Waldkraiburg", "value": "C36920"},
             {"label": "Waldmunchen", "value": "C36921"},
             {"label": "Waldsassen", "value": "C36922"},
             {"label": "Waldstetten", "value": "C36923"},
             {"label": "Waldthurn", "value": "C36924"},
             {"label": "Walkertshofen", "value": "C36925"},
             {"label": "Wallenfels", "value": "C36926"},
             {"label": "Wallerfing", "value": "C36927"},
             {"label": "Wallersdorf", "value": "C36928"},
             {"label": "Wallerstein", "value": "C36929"},
             {"label": "Wallgau", "value": "C36930"},
             {"label": "Walpertskirchen", "value": "C36931"},
             {"label": "Walsdorf", "value": "C36932"},
             {"label": "Waltenhofen", "value": "C36933"},
             {"label": "Wang", "value": "C36934"},
             {"label": "Warmensteinach", "value": "C36935"},
             {"label": "Warngau", "value": "C36936"},
             {"label": "Wartenberg", "value": "C36937"},
             {"label": "Wartmannsroth", "value": "C36938"},
             {"label": "Wasserburg", "value": "C36939"},
             {"label": "Wasserburg am Inn", "value": "C36940"},
             {"label": "Wasserlosen", "value": "C36941"},
             {"label": "Wassertrudingen", "value": "C36942"},
             {"label": "Wechingen", "value": "C36943"},
             {"label": "Wegscheid", "value": "C36944"},
             {"label": "Wehringen", "value": "C36945"},
             {"label": "Weibersbrunn", "value": "C36946"},
             {"label": "Weichering", "value": "C36947"},
             {"label": "Weichs", "value": "C36948"},
             {"label": "Weiden", "value": "C36949"},
             {"label": "Weidenbach", "value": "C36950"},
             {"label": "Weidhausen bei Coburg", "value": "C36951"},
             {"label": "Weiding", "value": "C36952"},
             {"label": "Weigendorf", "value": "C36953"},
             {"label": "Weihenzell", "value": "C36954"},
             {"label": "Weiherhammer", "value": "C36955"},
             {"label": "Weihmichl", "value": "C36956"},
             {"label": "Weil", "value": "C36957"},
             {"label": "Weilbach", "value": "C36958"},
             {"label": "Weiler-Simmerberg", "value": "C36959"},
             {"label": "Weilersbach", "value": "C36960"},
             {"label": "Weilheim", "value": "C36961"},
             {"label": "Weiltingen", "value": "C36962"},
             {"label": "Weisendorf", "value": "C36963"},
             {"label": "Weismain", "value": "C36964"},
             {"label": "Weissdorf", "value": "C36965"},
             {"label": "Weissenbrunn", "value": "C36966"},
             {"label": "Weissenburg in Bayern", "value": "C36967"},
             {"label": "Weissenhorn", "value": "C36968"},
             {"label": "Weissenohe", "value": "C36969"},
             {"label": "Weissensberg", "value": "C36970"},
             {"label": "Weissenstadt", "value": "C36971"},
             {"label": "Weitnau", "value": "C36972"},
             {"label": "Weitramsdorf", "value": "C36973"},
             {"label": "Welden", "value": "C36974"},
             {"label": "Wemding", "value": "C36975"},
             {"label": "Wendelstein", "value": "C36976"},
             {"label": "Weng", "value": "C36977"},
             {"label": "Wenzenbach", "value": "C36978"},
             {"label": "Wernberg-Koblitz", "value": "C36979"},
             {"label": "Werneck", "value": "C36980"},
             {"label": "Wertach", "value": "C36981"},
             {"label": "Wertingen", "value": "C36982"},
             {"label": "Wessling", "value": "C36983"},
             {"label": "Wessobrunn", "value": "C36984"},
             {"label": "Westendorf", "value": "C36985"},
             {"label": "Westerheim", "value": "C36986"},
             {"label": "Westheim", "value": "C36987"},
             {"label": "Wetzendorf", "value": "C36988"},
             {"label": "Weyarn", "value": "C36989"},
             {"label": "Wiedergeltingen", "value": "C36990"},
             {"label": "Wiesau", "value": "C36991"},
             {"label": "Wiesen", "value": "C36992"},
             {"label": "Wiesenfelden", "value": "C36993"},
             {"label": "Wiesent", "value": "C36994"},
             {"label": "Wiesenthau", "value": "C36995"},
             {"label": "Wiesentheid", "value": "C36996"},
             {"label": "Wiesthal", "value": "C36997"},
             {"label": "Wiggensbach", "value": "C36998"},
             {"label": "Wilburgstetten", "value": "C36999"},
             {"label": "Wildenberg", "value": "C37000"},
             {"label": "Wildflecken", "value": "C37001"},
             {"label": "Wildpoldsried", "value": "C37002"},
             {"label": "Wildsteig", "value": "C37003"},
             {"label": "Wilhelmsdorf", "value": "C37004"},
             {"label": "Wilhelmsthal", "value": "C37005"},
             {"label": "Wilhermsdorf", "value": "C37006"},
             {"label": "Willanzheim", "value": "C37007"},
             {"label": "Willmering", "value": "C37008"},
             {"label": "Windach", "value": "C37009"},
             {"label": "Windberg", "value": "C37010"},
             {"label": "Windelsbach", "value": "C37011"},
             {"label": "Windischeschenbach", "value": "C37012"},
             {"label": "Windsbach", "value": "C37013"},
             {"label": "Winhoring", "value": "C37014"},
             {"label": "Winkelhaid", "value": "C37015"},
             {"label": "Winklarn", "value": "C37016"},
             {"label": "Winterhausen", "value": "C37017"},
             {"label": "Winzer", "value": "C37018"},
             {"label": "Wipfeld", "value": "C37019"},
             {"label": "Wirsberg", "value": "C37020"},
             {"label": "Wittelshofen", "value": "C37021"},
             {"label": "Wittislingen", "value": "C37022"},
             {"label": "Witzmannsberg", "value": "C37023"},
             {"label": "Wolfersdorf", "value": "C37024"},
             {"label": "Wolferstadt", "value": "C37025"},
             {"label": "Wolfertschwenden", "value": "C37026"},
             {"label": "Wolframs-Eschenbach", "value": "C37027"},
             {"label": "Wolfratshausen", "value": "C37028"},
             {"label": "Wolfsegg", "value": "C37029"},
             {"label": "Wollbach", "value": "C37030"},
             {"label": "Wolnzach", "value": "C37031"},
             {"label": "Wonfurt", "value": "C37032"},
             {"label": "Wonsees", "value": "C37033"},
             {"label": "Woringen", "value": "C37034"},
             {"label": "Worth", "value": "C37035"},
             {"label": "Worth am Main", "value": "C37036"},
             {"label": "Worth an der Donau", "value": "C37037"},
             {"label": "Worth an der Isar", "value": "C37038"},
             {"label": "Worthsee", "value": "C37039"},
             {"label": "Wulfershausen", "value": "C37040"},
             {"label": "Wunsiedel", "value": "C37041"},
             {"label": "Wurmannsquick", "value": "C37042"},
             {"label": "Wurmsham", "value": "C37043"},
             {"label": "Wurzburg", "value": "C37044"},
             {"label": "Zachenberg", "value": "C37045"},
             {"label": "Zandt", "value": "C37046"},
             {"label": "Zangberg", "value": "C37047"},
             {"label": "Zapfendorf", "value": "C37048"},
             {"label": "Zeil", "value": "C37049"},
             {"label": "Zeilarn", "value": "C37050"},
             {"label": "Zeitlarn", "value": "C37051"},
             {"label": "Zeitlofs", "value": "C37052"},
             {"label": "Zell am Main", "value": "C37053"},
             {"label": "Zell im Fichtelgebirge", "value": "C37054"},
             {"label": "Zellingen", "value": "C37055"},
             {"label": "Zenting", "value": "C37056"},
             {"label": "Ziemetshausen", "value": "C37057"},
             {"label": "Ziertheim", "value": "C37058"},
             {"label": "Zirndorf", "value": "C37059"},
             {"label": "Zolling", "value": "C37060"},
             {"label": "Zorneding", "value": "C37061"},
             {"label": "Zusamaltheim", "value": "C37062"},
             {"label": "Zusmarshausen", "value": "C37063"},
             {"label": "Zwiesel", "value": "C37064"}],
 "Bay": [{"label": "Baidoa", "value": "C107892"},
         {"label": "Buurhakaba", "value": "C107893"}],
 "Bay Islands Department": [{"label": "Coxen Hole", "value": "C43223"},
                            {"label": "French Harbor", "value": "C43224"},
                            {"label": "Guanaja", "value": "C43225"},
                            {"label": "Jose Santos Guardiola",
                             "value": "C43226"},
                            {"label": "Roatan", "value": "C43227"},
                            {"label": "Sandy Bay", "value": "C43228"},
                            {"label": "Savannah Bight", "value": "C43229"},
                            {"label": "Utila", "value": "C43230"}],
 "Bay of Plenty Region": [{"label": "Edgecumbe", "value": "C77750"},
                          {"label": "Katikati", "value": "C77751"},
                          {"label": "Kawerau", "value": "C77752"},
                          {"label": "Maketu", "value": "C77753"},
                          {"label": "Murupara", "value": "C77754"},
                          {"label": "Opotiki", "value": "C77755"},
                          {"label": "Rotorua", "value": "C77756"},
                          {"label": "Tauranga", "value": "C77757"},
                          {"label": "Waihi Beach", "value": "C77758"},
                          {"label": "Whakatane", "value": "C77759"}],
 "Bayan-Olgii Province": [{"label": "Altay", "value": "C75557"},
                          {"label": "Olgii", "value": "C75558"},
                          {"label": "Tsengel", "value": "C75559"}],
 "Bayankhongor Province": [{"label": "Bayanhongor", "value": "C75560"}],
 "Bayburt": [{"label": "Aydintepe", "value": "C120044"},
             {"label": "Bayburt", "value": "C120045"},
             {"label": "Demirozu Ilcesi", "value": "C120046"},
             {"label": "Pulur", "value": "C120047"}],
 "Bayelsa": [{"label": "Amassoma", "value": "C78171"},
             {"label": "Twon-Brass", "value": "C78172"},
             {"label": "Yenagoa", "value": "C78173"}],
 "Beau Vallon": [{"label": "Beau Vallon", "value": "C107231"}],
 "Bechar": [{"label": "Bechar", "value": "C256"}],
 "Beheira": [{"label": "Abu al Matamir", "value": "C24226"},
             {"label": "Ad Dilinjat", "value": "C24227"},
             {"label": "Damanhur", "value": "C24228"},
             {"label": "Hawsh isa", "value": "C24229"},
             {"label": "Idku", "value": "C24230"},
             {"label": "Kafr ad Dawwar", "value": "C24231"},
             {"label": "Kawm Hamadah", "value": "C24232"},
             {"label": "Rosetta", "value": "C24233"}],
 "Beijing": [{"label": "Beijing", "value": "C18275"},
             {"label": "Changping", "value": "C18276"},
             {"label": "Daxing", "value": "C18277"},
             {"label": "Fangshan", "value": "C18278"},
             {"label": "Liangxiang", "value": "C18279"},
             {"label": "Mentougou", "value": "C18280"},
             {"label": "Shunyi", "value": "C18281"},
             {"label": "Tongzhou", "value": "C18282"}],
 "Beirut Governorate": [{"label": "Beirut", "value": "C64967"},
                        {"label": "Ras Bayrut", "value": "C64968"}],
 "Beja": [{"label": "Aldeia Nova de Sao Bento", "value": "C90869"},
          {"label": "Aljustrel", "value": "C90870"},
          {"label": "Almodovar", "value": "C90871"},
          {"label": "Almograve", "value": "C90872"},
          {"label": "Alvito", "value": "C90873"},
          {"label": "Barrancos", "value": "C90874"},
          {"label": "Beja", "value": "C90875"},
          {"label": "Beringel", "value": "C90876"},
          {"label": "Boavista dos Pinheiros", "value": "C90877"},
          {"label": "Cabeca Gorda", "value": "C90878"},
          {"label": "Castro Verde", "value": "C90879"},
          {"label": "Cuba", "value": "C90880"},
          {"label": "Ferreira do Alentejo", "value": "C90881"},
          {"label": "Mertola", "value": "C90882"},
          {"label": "Minas de Sao Domingos", "value": "C90883"},
          {"label": "Moura", "value": "C90884"},
          {"label": "Neves", "value": "C90885"},
          {"label": "Odemira", "value": "C90886"},
          {"label": "Ourique", "value": "C90887"},
          {"label": "Penedo Gordo", "value": "C90888"},
          {"label": "Salvada", "value": "C90889"},
          {"label": "Sao Luis", "value": "C90890"},
          {"label": "Sao Teotonio", "value": "C90891"},
          {"label": "Serpa", "value": "C90892"},
          {"label": "Vidigueira", "value": "C90893"},
          {"label": "Vila Nova de Milfontes", "value": "C90894"},
          {"label": "Zambujeira do Mar", "value": "C90895"}],
 "Bejaia": [{"label": "Akbou", "value": "C257"},
            {"label": "Amizour", "value": "C258"},
            {"label": "Barbacha", "value": "C259"},
            {"label": "Bejaia", "value": "C260"},
            {"label": "el hed", "value": "C261"},
            {"label": "El Kseur", "value": "C262"},
            {"label": "Feraoun", "value": "C263"},
            {"label": "Seddouk", "value": "C264"}],
 "Bekes": [{"label": "Battonya", "value": "C43857"},
           {"label": "Bekes", "value": "C43858"},
           {"label": "Bekescsaba", "value": "C43859"},
           {"label": "Bekescsabai Jaras", "value": "C43860"},
           {"label": "Bekesi Jaras", "value": "C43861"},
           {"label": "Bekessamson", "value": "C43862"},
           {"label": "Bekesszentandras", "value": "C43863"},
           {"label": "Bucsa", "value": "C43864"},
           {"label": "Csanadapaca", "value": "C43865"},
           {"label": "Csorvas", "value": "C43866"},
           {"label": "Devavanya", "value": "C43867"},
           {"label": "Doboz", "value": "C43868"},
           {"label": "Dombegyhaz", "value": "C43869"},
           {"label": "Elek", "value": "C43870"},
           {"label": "Fuzesgyarmat", "value": "C43871"},
           {"label": "Gadoros", "value": "C43872"},
           {"label": "Gyomaendrod", "value": "C43873"},
           {"label": "Gyomaendrodi Jaras", "value": "C43874"},
           {"label": "Gyula", "value": "C43875"},
           {"label": "Gyulai Jaras", "value": "C43876"},
           {"label": "Kaszaper", "value": "C43877"},
           {"label": "Ketegyhaza", "value": "C43878"},
           {"label": "Kevermes", "value": "C43879"},
           {"label": "Kondoros", "value": "C43880"},
           {"label": "Korosladany", "value": "C43881"},
           {"label": "Korostarcsa", "value": "C43882"},
           {"label": "Kunagota", "value": "C43883"},
           {"label": "Lokoshaza", "value": "C43884"},
           {"label": "Magyarbanhegyes", "value": "C43885"},
           {"label": "Medgyesegyhaza", "value": "C43886"},
           {"label": "Mehkerek", "value": "C43887"},
           {"label": "Mezobereny", "value": "C43888"},
           {"label": "Mezohegyes", "value": "C43889"},
           {"label": "Mezokovacshaza", "value": "C43890"},
           {"label": "Mezokovacshazai Jaras", "value": "C43891"},
           {"label": "Nagyszenas", "value": "C43892"},
           {"label": "Okany", "value": "C43893"},
           {"label": "Oroshaza", "value": "C43894"},
           {"label": "Oroshazi Jaras", "value": "C43895"},
           {"label": "Pusztafoldvar", "value": "C43896"},
           {"label": "Sarkad", "value": "C43897"},
           {"label": "Sarkadi Jaras", "value": "C43898"},
           {"label": "Szabadkigyos", "value": "C43899"},
           {"label": "Szarvas", "value": "C43900"},
           {"label": "Szarvasi Jaras", "value": "C43901"},
           {"label": "Szeghalmi Jaras", "value": "C43902"},
           {"label": "Szeghalom", "value": "C43903"},
           {"label": "Totkomlos", "value": "C43904"},
           {"label": "Ujkigyos", "value": "C43905"},
           {"label": "Veszto", "value": "C43906"}],
 "Bel Ombre": [{"label": "Bel Ombre", "value": "C107232"}],
 "Belait District": [{"label": "Kuala Belait", "value": "C15837"},
                     {"label": "Seria", "value": "C15838"}],
 "Belgorod Oblast": [{"label": "Alekseyevka", "value": "C100512"},
                     {"label": "Bekhteyevka", "value": "C100513"},
                     {"label": "Belgorod", "value": "C100514"},
                     {"label": "Belgorodskiy Rayon", "value": "C100515"},
                     {"label": "Bessonovka", "value": "C100516"},
                     {"label": "Borisovka", "value": "C100517"},
                     {"label": "Borisovskiy Rayon", "value": "C100518"},
                     {"label": "Chernyanka", "value": "C100519"},
                     {"label": "Chernyanskiy Rayon", "value": "C100520"},
                     {"label": "Golovchino", "value": "C100521"},
                     {"label": "Grayvoron", "value": "C100522"},
                     {"label": "Grayvoronskiy Rayon", "value": "C100523"},
                     {"label": "Gubkin", "value": "C100524"},
                     {"label": "Ilovka", "value": "C100525"},
                     {"label": "Ivnya", "value": "C100526"},
                     {"label": "Ivnyanskiy Rayon", "value": "C100527"},
                     {"label": "Korocha", "value": "C100528"},
                     {"label": "Korochanskiy Rayon", "value": "C100529"},
                     {"label": "Krasnaya Yaruga", "value": "C100530"},
                     {"label": "Krasnenskiy Rayon", "value": "C100531"},
                     {"label": "Krasnogvardeyskiy Rayon", "value": "C100532"},
                     {"label": "Krasnogvardeyskoye", "value": "C100533"},
                     {"label": "Krasnoyaruzhskiy Rayon", "value": "C100534"},
                     {"label": "Krasnoye", "value": "C100535"},
                     {"label": "Livenka", "value": "C100536"},
                     {"label": "Maslova Pristan", "value": "C100537"},
                     {"label": "Mayskiy", "value": "C100538"},
                     {"label": "Novaya Tavolzhanka", "value": "C100539"},
                     {"label": "Novooskolskiy Rayon", "value": "C100540"},
                     {"label": "Novyy Oskol", "value": "C100541"},
                     {"label": "Prokhorovka", "value": "C100542"},
                     {"label": "Prokhorovskiy Rayon", "value": "C100543"},
                     {"label": "Proletarskiy", "value": "C100544"},
                     {"label": "Pyatnitskoye", "value": "C100545"},
                     {"label": "Rakitnoye", "value": "C100546"},
                     {"label": "Rakityanskiy Rayon", "value": "C100547"},
                     {"label": "Razumnoye", "value": "C100548"},
                     {"label": "Rogovatoye", "value": "C100549"},
                     {"label": "Rovenskiy Rayon", "value": "C100550"},
                     {"label": "Severnyy", "value": "C100551"},
                     {"label": "Shebekino", "value": "C100552"},
                     {"label": "Shebekinskiy Rayon", "value": "C100553"},
                     {"label": "Skorodnoye", "value": "C100554"},
                     {"label": "Staryy Oskol", "value": "C100555"},
                     {"label": "Stroitel", "value": "C100556"},
                     {"label": "Tomarovka", "value": "C100557"},
                     {"label": "Troitskiy", "value": "C100558"},
                     {"label": "Urazovo", "value": "C100559"},
                     {"label": "Valuyki", "value": "C100560"},
                     {"label": "Valuyskiy Rayon", "value": "C100561"},
                     {"label": "Veydelevka", "value": "C100562"},
                     {"label": "Volokonovka", "value": "C100563"},
                     {"label": "Volokonovskiy Rayon", "value": "C100564"},
                     {"label": "Yakovlevo", "value": "C100565"},
                     {"label": "Yakovlevskiy Rayon", "value": "C100566"},
                     {"label": "Zasosna", "value": "C100567"}],
 "Belize District": [{"label": "Belize City", "value": "C9581"},
                     {"label": "San Pedro", "value": "C9582"}],
 "Beltinci Municipality": [{"label": "Beltinci", "value": "C107566"},
                           {"label": "Gancani", "value": "C107567"},
                           {"label": "Lipovci", "value": "C107568"},
                           {"label": "Zgornje Gorje", "value": "C107569"}],
 "Ben Arous Governorate": [{"label": "Ben Arous", "value": "C119682"},
                           {"label": "Hammam Lif", "value": "C119683"},
                           {"label": "Hammam-Lif", "value": "C119684"},
                           {"label": "La Sebala du Mornag", "value": "C119685"},
                           {"label": "Rades", "value": "C119686"}],
 "Ben Tre": [{"label": "Ap Tan Ngai", "value": "C147294"},
             {"label": "Ben Tre", "value": "C147295"},
             {"label": "Huyen Ba Tri", "value": "C147296"},
             {"label": "Huyen Binh Dai", "value": "C147297"},
             {"label": "Huyen Chau Thanh", "value": "C147298"},
             {"label": "Huyen Cho Lach", "value": "C147299"},
             {"label": "Huyen Giong Trom", "value": "C147300"},
             {"label": "Huyen Thanh Phu", "value": "C147301"}],
 "Bender Municipality": [{"label": "Bender", "value": "C75488"}],
 "Benedikt Municipality": [{"label": "Benedikt", "value": "C107570"}],
 "Benghazi": [{"label": "Benghazi", "value": "C65026"},
              {"label": "Qaryat Suluq", "value": "C65027"}],
 "Bengkulu": [{"label": "Bengkulu", "value": "C49212"},
              {"label": "Curup", "value": "C49213"},
              {"label": "Kabupaten Bengkulu Selatan", "value": "C49214"},
              {"label": "Kabupaten Bengkulu Tengah", "value": "C49215"},
              {"label": "Kabupaten Bengkulu Utara", "value": "C49216"},
              {"label": "Kabupaten Kaur", "value": "C49217"},
              {"label": "Kabupaten Kepahiang", "value": "C49218"},
              {"label": "Kabupaten Lebong", "value": "C49219"},
              {"label": "Kabupaten Mukomuko", "value": "C49220"},
              {"label": "Kabupaten Rejang Lebong", "value": "C49221"},
              {"label": "Kabupaten Seluma", "value": "C49222"},
              {"label": "Kota Bengkulu", "value": "C49223"}],
 "Bengo Province": [{"label": "Caxito", "value": "C526"}],
 "Benguela Province": [{"label": "Benguela", "value": "C527"},
                       {"label": "Catumbela", "value": "C528"},
                       {"label": "Lobito", "value": "C529"}],
 "Benguet": [{"label": "Adtugan", "value": "C83680"},
             {"label": "Aglayan", "value": "C83681"},
             {"label": "Agusan", "value": "C83682"},
             {"label": "Alae", "value": "C83683"},
             {"label": "Alanib", "value": "C83684"},
             {"label": "Alubijid", "value": "C83685"},
             {"label": "Anakan", "value": "C83686"},
             {"label": "Ani-e", "value": "C83687"},
             {"label": "Aplaya", "value": "C83688"},
             {"label": "Aumbay", "value": "C83689"},
             {"label": "Bacolod", "value": "C83690"},
             {"label": "Bagakay", "value": "C83691"},
             {"label": "Baikingon", "value": "C83692"},
             {"label": "Balila", "value": "C83693"},
             {"label": "Balili", "value": "C83694"},
             {"label": "Balingasag", "value": "C83695"},
             {"label": "Balingoan", "value": "C83696"},
             {"label": "Baliwagan", "value": "C83697"},
             {"label": "Bangahan", "value": "C83698"},
             {"label": "Bantuanon", "value": "C83699"},
             {"label": "Baroy", "value": "C83700"},
             {"label": "Barra", "value": "C83701"},
             {"label": "Basak", "value": "C83702"},
             {"label": "Bato", "value": "C83703"},
             {"label": "Biga", "value": "C83704"},
             {"label": "Binitinan", "value": "C83705"},
             {"label": "Binuangan", "value": "C83706"},
             {"label": "Bolo Bolo", "value": "C83707"},
             {"label": "Bonbon", "value": "C83708"},
             {"label": "Bonifacio", "value": "C83709"},
             {"label": "Boroon", "value": "C83710"},
             {"label": "Bugcaon", "value": "C83711"},
             {"label": "Bugo", "value": "C83712"},
             {"label": "Busdi", "value": "C83713"},
             {"label": "Cabangahan", "value": "C83714"},
             {"label": "Cabanglasan", "value": "C83715"},
             {"label": "Cagayan de Oro", "value": "C83716"},
             {"label": "Calabugao", "value": "C83717"},
             {"label": "Calamba", "value": "C83718"},
             {"label": "Canayan", "value": "C83719"},
             {"label": "Candiis", "value": "C83720"},
             {"label": "Caromatan", "value": "C83721"},
             {"label": "Casisang", "value": "C83722"},
             {"label": "Catarman", "value": "C83723"},
             {"label": "Clarin", "value": "C83724"},
             {"label": "Claveria", "value": "C83725"},
             {"label": "Consuelo", "value": "C83726"},
             {"label": "Cosina", "value": "C83727"},
             {"label": "Dagumba-an", "value": "C83728"},
             {"label": "Dalipuga", "value": "C83729"},
             {"label": "Dalirig", "value": "C83730"},
             {"label": "Dalorong", "value": "C83731"},
             {"label": "Dalwangan", "value": "C83732"},
             {"label": "Damilag", "value": "C83733"},
             {"label": "Damulog", "value": "C83734"},
             {"label": "Dancagan", "value": "C83735"},
             {"label": "Dimaluna", "value": "C83736"},
             {"label": "Dimayon", "value": "C83737"},
             {"label": "Dologon", "value": "C83738"},
             {"label": "Don Carlos", "value": "C83739"},
             {"label": "Dorsalanam", "value": "C83740"},
             {"label": "Dumalaguing", "value": "C83741"},
             {"label": "El Salvador", "value": "C83742"},
             {"label": "Esperanza", "value": "C83743"},
             {"label": "Gimampang", "value": "C83744"},
             {"label": "Gitagum", "value": "C83745"},
             {"label": "Guinisiliban", "value": "C83746"},
             {"label": "Halapitan", "value": "C83747"},
             {"label": "Hinapalanan", "value": "C83748"},
             {"label": "Igpit", "value": "C83749"},
             {"label": "Imbatug", "value": "C83750"},
             {"label": "Imelda", "value": "C83751"},
             {"label": "Impalutao", "value": "C83752"},
             {"label": "Indulang", "value": "C83753"},
             {"label": "Initao", "value": "C83754"},
             {"label": "Inobulan", "value": "C83755"},
             {"label": "Jasaan", "value": "C83756"},
             {"label": "Jimenez", "value": "C83757"},
             {"label": "Kabalantian", "value": "C83758"},
             {"label": "Kabulohan", "value": "C83759"},
             {"label": "Kadingilan", "value": "C83760"},
             {"label": "Kalanganan", "value": "C83761"},
             {"label": "Kalilangan", "value": "C83762"},
             {"label": "Kalugmanan", "value": "C83763"},
             {"label": "Kapatagan", "value": "C83764"},
             {"label": "Kauswagan", "value": "C83765"},
             {"label": "Kawit", "value": "C83766"},
             {"label": "Kibangay", "value": "C83767"},
             {"label": "Kibawe", "value": "C83768"},
             {"label": "Kibonsod", "value": "C83769"},
             {"label": "Kibureau", "value": "C83770"},
             {"label": "Kimanuit", "value": "C83771"},
             {"label": "Kimaya", "value": "C83772"},
             {"label": "Kisolon", "value": "C83773"},
             {"label": "Kitaotao", "value": "C83774"},
             {"label": "Kitobo", "value": "C83775"},
             {"label": "Kolambugan", "value": "C83776"},
             {"label": "La Fortuna", "value": "C83777"},
             {"label": "La Roxas", "value": "C83778"},
             {"label": "Lagindingan", "value": "C83779"},
             {"label": "Lagonglong", "value": "C83780"},
             {"label": "Laguitas", "value": "C83781"},
             {"label": "Langcangan", "value": "C83782"},
             {"label": "Lanipao", "value": "C83783"},
             {"label": "Lantapan", "value": "C83784"},
             {"label": "Lapase", "value": "C83785"},
             {"label": "Lapining", "value": "C83786"},
             {"label": "Libertad", "value": "C83787"},
             {"label": "Libona", "value": "C83788"},
             {"label": "Liboran", "value": "C83789"},
             {"label": "Limbaan", "value": "C83790"},
             {"label": "Linabo", "value": "C83791"},
             {"label": "Linamon", "value": "C83792"},
             {"label": "Lingating", "value": "C83793"},
             {"label": "Lingion", "value": "C83794"},
             {"label": "Little Baguio", "value": "C83795"},
             {"label": "Looc", "value": "C83796"},
             {"label": "Lopez Jaena", "value": "C83797"},
             {"label": "Lourdes", "value": "C83798"},
             {"label": "Lugait", "value": "C83799"},
             {"label": "Lumbayao", "value": "C83800"},
             {"label": "Lumbia", "value": "C83801"},
             {"label": "Lunao", "value": "C83802"},
             {"label": "Lurugan", "value": "C83803"},
             {"label": "Maanas", "value": "C83804"},
             {"label": "Maglamin", "value": "C83805"},
             {"label": "Magsaysay", "value": "C83806"},
             {"label": "Mahinog", "value": "C83807"},
             {"label": "Maigo", "value": "C83808"},
             {"label": "Mailag", "value": "C83809"},
             {"label": "Malaybalay", "value": "C83810"},
             {"label": "Malinaw", "value": "C83811"},
             {"label": "Malitbog", "value": "C83812"},
             {"label": "Maluko", "value": "C83813"},
             {"label": "Mambajao", "value": "C83814"},
             {"label": "Mambatangan", "value": "C83815"},
             {"label": "Mambayaan", "value": "C83816"},
             {"label": "Mamungan", "value": "C83817"},
             {"label": "Managok", "value": "C83818"},
             {"label": "Mananum", "value": "C83819"},
             {"label": "Mandangoa", "value": "C83820"},
             {"label": "Manolo Fortich", "value": "C83821"},
             {"label": "Mantampay", "value": "C83822"},
             {"label": "Manticao", "value": "C83823"},
             {"label": "Maputi", "value": "C83824"},
             {"label": "Maramag", "value": "C83825"},
             {"label": "Maranding", "value": "C83826"},
             {"label": "Maria Cristina", "value": "C83827"},
             {"label": "Mariano", "value": "C83828"},
             {"label": "Mat-i", "value": "C83829"},
             {"label": "Matangad", "value": "C83830"},
             {"label": "Matungao", "value": "C83831"},
             {"label": "Medina", "value": "C83832"},
             {"label": "Miaray", "value": "C83833"},
             {"label": "Minlagas", "value": "C83834"},
             {"label": "Molugan", "value": "C83835"},
             {"label": "Moog", "value": "C83836"},
             {"label": "Munai", "value": "C83837"},
             {"label": "Naawan", "value": "C83838"},
             {"label": "Nangka", "value": "C83839"},
             {"label": "Napalitan", "value": "C83840"},
             {"label": "Natalungan", "value": "C83841"},
             {"label": "NIA Valencia", "value": "C83842"},
             {"label": "Odiongan", "value": "C83843"},
             {"label": "Opol", "value": "C83844"},
             {"label": "Oroquieta", "value": "C83845"},
             {"label": "Ozamiz City", "value": "C83846"},
             {"label": "Pan-an", "value": "C83847"},
             {"label": "Panalo-on", "value": "C83848"},
             {"label": "Pandan", "value": "C83849"},
             {"label": "Pangabuan", "value": "C83850"},
             {"label": "Pantao-Ragat", "value": "C83851"},
             {"label": "Pantar", "value": "C83852"},
             {"label": "Patrocinio", "value": "C83853"},
             {"label": "Pines", "value": "C83854"},
             {"label": "Plaridel", "value": "C83855"},
             {"label": "Pongol", "value": "C83856"},
             {"label": "Pontian", "value": "C83857"},
             {"label": "Province of Bukidnon", "value": "C83858"},
             {"label": "Province of Camiguin", "value": "C83859"},
             {"label": "Province of Lanao del Norte", "value": "C83860"},
             {"label": "Province of Misamis Occidental", "value": "C83861"},
             {"label": "Province of Misamis Oriental", "value": "C83862"},
             {"label": "Pualas", "value": "C83863"},
             {"label": "Punta Silum", "value": "C83864"},
             {"label": "Quezon", "value": "C83865"},
             {"label": "Rebe", "value": "C83866"},
             {"label": "Sagay", "value": "C83867"},
             {"label": "Salawagan", "value": "C83868"},
             {"label": "Salay", "value": "C83869"},
             {"label": "Salimbalan", "value": "C83870"},
             {"label": "Sampagar", "value": "C83871"},
             {"label": "San Carlos", "value": "C83872"},
             {"label": "San Isidro", "value": "C83873"},
             {"label": "San Jose", "value": "C83874"},
             {"label": "San Luis", "value": "C83875"},
             {"label": "San Martin", "value": "C83876"},
             {"label": "San Miguel", "value": "C83877"},
             {"label": "San Vicente", "value": "C83878"},
             {"label": "Sankanan", "value": "C83879"},
             {"label": "Santa Ana", "value": "C83880"},
             {"label": "Santa Fe", "value": "C83881"},
             {"label": "Sapang Dalaga", "value": "C83882"},
             {"label": "Silae", "value": "C83883"},
             {"label": "Sinacaban", "value": "C83884"},
             {"label": "Sinonoc", "value": "C83885"},
             {"label": "Solana", "value": "C83886"},
             {"label": "Sugbongkogon", "value": "C83887"},
             {"label": "Sumilao", "value": "C83888"},
             {"label": "Sumpong", "value": "C83889"},
             {"label": "Sungai", "value": "C83890"},
             {"label": "Tabid", "value": "C83891"},
             {"label": "Taboc", "value": "C83892"},
             {"label": "Tacub", "value": "C83893"},
             {"label": "Tagoloan", "value": "C83894"},
             {"label": "Talakag", "value": "C83895"},
             {"label": "Talisayan", "value": "C83896"},
             {"label": "Tangub", "value": "C83897"},
             {"label": "Taypano", "value": "C83898"},
             {"label": "Taytay", "value": "C83899"},
             {"label": "Ticala-an", "value": "C83900"},
             {"label": "Tignapalan", "value": "C83901"},
             {"label": "Tubao", "value": "C83902"},
             {"label": "Tubigan", "value": "C83903"},
             {"label": "Tubod", "value": "C83904"},
             {"label": "Tudela", "value": "C83905"},
             {"label": "Tuod", "value": "C83906"},
             {"label": "Tupsan", "value": "C83907"},
             {"label": "Unidos", "value": "C83908"},
             {"label": "Villanueva", "value": "C83909"},
             {"label": "Yumbing", "value": "C83910"}],
 "Beni Department": [{"label": "Guayaramerin", "value": "C9654"},
                     {"label": "Provincia Cercado", "value": "C9655"},
                     {"label": "Provincia General Jose Ballivian",
                      "value": "C9656"},
                     {"label": "Provincia Itenez", "value": "C9657"},
                     {"label": "Provincia Mamore", "value": "C9658"},
                     {"label": "Provincia Marban", "value": "C9659"},
                     {"label": "Provincia Moxos", "value": "C9660"},
                     {"label": "Provincia Vaca Diez", "value": "C9661"},
                     {"label": "Provincia Yacuma", "value": "C9662"},
                     {"label": "Reyes", "value": "C9663"},
                     {"label": "Riberalta", "value": "C9664"},
                     {"label": "Rurrenabaque", "value": "C9665"},
                     {"label": "San Borja", "value": "C9666"},
                     {"label": "San Ramon", "value": "C9667"},
                     {"label": "Santa Ana de Yacuma", "value": "C9668"},
                     {"label": "Santa Rosa", "value": "C9669"},
                     {"label": "Trinidad", "value": "C9670"}],
 "Beni Suef": [{"label": "Al Fashn", "value": "C24234"},
               {"label": "Bani Suwayf", "value": "C24235"},
               {"label": "Bush", "value": "C24236"},
               {"label": "Sumusta as Sultani", "value": "C24237"}],
 "Benishangul-Gumuz Region": [{"label": "Asosa", "value": "C24698"},
                              {"label": "Metekel", "value": "C24699"}],
 "Benue": [{"label": "Aliade", "value": "C78174"},
           {"label": "Boju", "value": "C78175"},
           {"label": "Gboko", "value": "C78176"},
           {"label": "Igbor", "value": "C78177"},
           {"label": "Makurdi", "value": "C78178"},
           {"label": "Ochobo", "value": "C78179"},
           {"label": "Otukpa", "value": "C78180"},
           {"label": "Takum", "value": "C78181"},
           {"label": "Ugbokpo", "value": "C78182"},
           {"label": "Yandev", "value": "C78183"},
           {"label": "Zaki Biam", "value": "C78184"}],
 "Beqaa Governorate": [{"label": "Aanjar", "value": "C64969"},
                       {"label": "Zahle", "value": "C64970"}],
 "Berane Municipality": [{"label": "Berane", "value": "C75599"}],
 "Berat District": [{"label": "Banaj", "value": "C93"},
                    {"label": "Bashkia Berat", "value": "C94"},
                    {"label": "Bashkia Kucove", "value": "C95"},
                    {"label": "Bashkia Polican", "value": "C96"},
                    {"label": "Bashkia Skrapar", "value": "C97"},
                    {"label": "Berat", "value": "C98"},
                    {"label": "Corovode", "value": "C99"},
                    {"label": "Kucove", "value": "C100"},
                    {"label": "Polican", "value": "C101"},
                    {"label": "Rrethi i Beratit", "value": "C102"},
                    {"label": "Rrethi i Kucoves", "value": "C103"},
                    {"label": "Rrethi i Skraparit", "value": "C104"},
                    {"label": "Ura Vajgurore", "value": "C105"}],
 "Berea District": [{"label": "Teyateyaneng", "value": "C64990"}],
 "Berlin": [{"label": "Adlershof", "value": "C37065"},
            {"label": "Alt-Hohenschonhausen", "value": "C37066"},
            {"label": "Alt-Treptow", "value": "C37067"},
            {"label": "Altglienicke", "value": "C37068"},
            {"label": "Baumschulenweg", "value": "C37069"},
            {"label": "Berlin", "value": "C37070"},
            {"label": "Berlin Kopenick", "value": "C37071"},
            {"label": "Berlin Treptow", "value": "C37072"},
            {"label": "Biesdorf", "value": "C37073"},
            {"label": "Blankenburg", "value": "C37074"},
            {"label": "Blankenfelde", "value": "C37075"},
            {"label": "Bohnsdorf", "value": "C37076"},
            {"label": "Britz", "value": "C37077"},
            {"label": "Buch", "value": "C37078"},
            {"label": "Buckow", "value": "C37079"},
            {"label": "Charlottenburg", "value": "C37080"},
            {"label": "Charlottenburg-Nord", "value": "C37081"},
            {"label": "Dahlem", "value": "C37082"},
            {"label": "Falkenberg", "value": "C37083"},
            {"label": "Falkenhagener Feld", "value": "C37084"},
            {"label": "Fennpfuhl", "value": "C37085"},
            {"label": "Franzosisch Buchholz", "value": "C37086"},
            {"label": "Friedenau", "value": "C37087"},
            {"label": "Friedrichsfelde", "value": "C37088"},
            {"label": "Friedrichshagen", "value": "C37089"},
            {"label": "Friedrichshain", "value": "C37090"},
            {"label": "Frohnau", "value": "C37091"},
            {"label": "Gatow", "value": "C37092"},
            {"label": "Gesundbrunnen", "value": "C37093"},
            {"label": "Gropiusstadt", "value": "C37094"},
            {"label": "Grunau", "value": "C37095"},
            {"label": "Grunewald", "value": "C37096"},
            {"label": "Hakenfelde", "value": "C37097"},
            {"label": "Halensee", "value": "C37098"},
            {"label": "Hansaviertel", "value": "C37099"},
            {"label": "Haselhorst", "value": "C37100"},
            {"label": "Heiligensee", "value": "C37101"},
            {"label": "Heinersdorf", "value": "C37102"},
            {"label": "Hellersdorf", "value": "C37103"},
            {"label": "Hermsdorf", "value": "C37104"},
            {"label": "Johannisthal", "value": "C37105"},
            {"label": "Karlshorst", "value": "C37106"},
            {"label": "Karow", "value": "C37107"},
            {"label": "Kaulsdorf", "value": "C37108"},
            {"label": "Kladow", "value": "C37109"},
            {"label": "Konradshohe", "value": "C37110"},
            {"label": "Kopenick", "value": "C37111"},
            {"label": "Kreuzberg", "value": "C37112"},
            {"label": "Lankwitz", "value": "C37113"},
            {"label": "Lichtenberg", "value": "C37114"},
            {"label": "Lichtenrade", "value": "C37115"},
            {"label": "Lichterfelde", "value": "C37116"},
            {"label": "Lubars", "value": "C37117"},
            {"label": "Mahlsdorf", "value": "C37118"},
            {"label": "Mariendorf", "value": "C37119"},
            {"label": "Marienfelde", "value": "C37120"},
            {"label": "Markisches Viertel", "value": "C37121"},
            {"label": "Marzahn", "value": "C37122"},
            {"label": "Mitte", "value": "C37123"},
            {"label": "Moabit", "value": "C37124"},
            {"label": "Muggelheim", "value": "C37125"},
            {"label": "Neu-Hohenschonhausen", "value": "C37126"},
            {"label": "Neukolln", "value": "C37127"},
            {"label": "Niederschoneweide", "value": "C37128"},
            {"label": "Niederschonhausen", "value": "C37129"},
            {"label": "Nikolassee", "value": "C37130"},
            {"label": "Oberschoneweide", "value": "C37131"},
            {"label": "Pankow", "value": "C37132"},
            {"label": "Planterwald", "value": "C37133"},
            {"label": "Prenzlauer Berg", "value": "C37134"},
            {"label": "Rahnsdorf", "value": "C37135"},
            {"label": "Reinickendorf", "value": "C37136"},
            {"label": "Rosenthal", "value": "C37137"},
            {"label": "Rudow", "value": "C37138"},
            {"label": "Rummelsburg", "value": "C37139"},
            {"label": "Schmargendorf", "value": "C37140"},
            {"label": "Schmockwitz", "value": "C37141"},
            {"label": "Schoneberg", "value": "C37142"},
            {"label": "Siemensstadt", "value": "C37143"},
            {"label": "Spandau", "value": "C37144"},
            {"label": "Staaken", "value": "C37145"},
            {"label": "Stadtrandsiedlung Malchow", "value": "C37146"},
            {"label": "Steglitz", "value": "C37147"},
            {"label": "Tegel", "value": "C37148"},
            {"label": "Tempelhof", "value": "C37149"},
            {"label": "Tiergarten", "value": "C37150"},
            {"label": "Waidmannslust", "value": "C37151"},
            {"label": "Wannsee", "value": "C37152"},
            {"label": "Wartenberg", "value": "C37153"},
            {"label": "Wedding", "value": "C37154"},
            {"label": "Weissensee", "value": "C37155"},
            {"label": "Westend", "value": "C37156"},
            {"label": "Wilhelmsruh", "value": "C37157"},
            {"label": "Wilhelmstadt", "value": "C37158"},
            {"label": "Wilmersdorf", "value": "C37159"},
            {"label": "Wittenau", "value": "C37160"},
            {"label": "Zehlendorf", "value": "C37161"}],
 "Bern": [{"label": "Aarberg", "value": "C116493"},
          {"label": "Aarwangen", "value": "C116494"},
          {"label": "Adelboden", "value": "C116495"},
          {"label": "Aeschi b. Spiez", "value": "C116496"},
          {"label": "Arch", "value": "C116497"},
          {"label": "Attiswil", "value": "C116498"},
          {"label": "Bariswil", "value": "C116499"},
          {"label": "Batterkinden", "value": "C116500"},
          {"label": "Beatenberg", "value": "C116501"},
          {"label": "Bellmund", "value": "C116502"},
          {"label": "Belp", "value": "C116503"},
          {"label": "Bern", "value": "C116504"},
          {"label": "Bern-Mittelland District", "value": "C116505"},
          {"label": "Bevilard", "value": "C116506"},
          {"label": "BielBienne", "value": "C116507"},
          {"label": "BielBienne District", "value": "C116508"},
          {"label": "Biglen", "value": "C116509"},
          {"label": "Blumenstein", "value": "C116510"},
          {"label": "Bolligen", "value": "C116511"},
          {"label": "Boltigen", "value": "C116512"},
          {"label": "Bonigen", "value": "C116513"},
          {"label": "Bowil", "value": "C116514"},
          {"label": "Brienz", "value": "C116515"},
          {"label": "Brugg", "value": "C116516"},
          {"label": "Buchholterberg", "value": "C116517"},
          {"label": "Burgdorf", "value": "C116518"},
          {"label": "Burgistein", "value": "C116519"},
          {"label": "Corgemont", "value": "C116520"},
          {"label": "Court", "value": "C116521"},
          {"label": "Courtelary", "value": "C116522"},
          {"label": "Darligen", "value": "C116523"},
          {"label": "Diemtigen", "value": "C116524"},
          {"label": "Durrenroth", "value": "C116525"},
          {"label": "Eggiwil", "value": "C116526"},
          {"label": "Emmental District", "value": "C116527"},
          {"label": "Eriswil", "value": "C116528"},
          {"label": "Erlach", "value": "C116529"},
          {"label": "Erlenbach im Simmental", "value": "C116530"},
          {"label": "Ersigen", "value": "C116531"},
          {"label": "Evilard", "value": "C116532"},
          {"label": "Ferenbalm", "value": "C116533"},
          {"label": "Fraubrunnen", "value": "C116534"},
          {"label": "Frauenkappelen", "value": "C116535"},
          {"label": "Frutigen", "value": "C116536"},
          {"label": "Frutigen-Niedersimmental District", "value": "C116537"},
          {"label": "Grindelwald", "value": "C116538"},
          {"label": "Grossaffoltern", "value": "C116539"},
          {"label": "Gstaad", "value": "C116540"},
          {"label": "Guggisberg", "value": "C116541"},
          {"label": "Heimberg", "value": "C116542"},
          {"label": "Heimiswil", "value": "C116543"},
          {"label": "Hermiswil", "value": "C116544"},
          {"label": "Herzogenbuchsee", "value": "C116545"},
          {"label": "Hilterfingen", "value": "C116546"},
          {"label": "Hindelbank", "value": "C116547"},
          {"label": "Huttwil", "value": "C116548"},
          {"label": "Ins", "value": "C116549"},
          {"label": "Interlaken", "value": "C116550"},
          {"label": "Interlaken-Oberhasli District", "value": "C116551"},
          {"label": "Jegenstorf", "value": "C116552"},
          {"label": "Jura bernois", "value": "C116553"},
          {"label": "Kallnach", "value": "C116554"},
          {"label": "Kandersteg", "value": "C116555"},
          {"label": "Kappelen", "value": "C116556"},
          {"label": "Kehrsatz", "value": "C116557"},
          {"label": "Kirchberg", "value": "C116558"},
          {"label": "Kirchlindach", "value": "C116559"},
          {"label": "Koniz", "value": "C116560"},
          {"label": "Konolfingen", "value": "C116561"},
          {"label": "Koppigen", "value": "C116562"},
          {"label": "Krauchthal", "value": "C116563"},
          {"label": "La Neuveville", "value": "C116564"},
          {"label": "Langenthal", "value": "C116565"},
          {"label": "Langnau", "value": "C116566"},
          {"label": "Laupen", "value": "C116567"},
          {"label": "Lauperswil", "value": "C116568"},
          {"label": "Lauterbrunnen", "value": "C116569"},
          {"label": "Lengnau", "value": "C116570"},
          {"label": "Lenk", "value": "C116571"},
          {"label": "Leuzigen", "value": "C116572"},
          {"label": "Linden", "value": "C116573"},
          {"label": "Lotzwil", "value": "C116574"},
          {"label": "Lutzelfluh", "value": "C116575"},
          {"label": "Lyss", "value": "C116576"},
          {"label": "Lyssach", "value": "C116577"},
          {"label": "Madiswil", "value": "C116578"},
          {"label": "Malleray", "value": "C116579"},
          {"label": "Matten", "value": "C116580"},
          {"label": "Meinisberg", "value": "C116581"},
          {"label": "Meiringen", "value": "C116582"},
          {"label": "Melchnau", "value": "C116583"},
          {"label": "Moutier", "value": "C116584"},
          {"label": "Muhleberg", "value": "C116585"},
          {"label": "Muhlethurnen", "value": "C116586"},
          {"label": "Munchenbuchsee", "value": "C116587"},
          {"label": "Munchenwiler", "value": "C116588"},
          {"label": "Munsingen", "value": "C116589"},
          {"label": "Muntschemier", "value": "C116590"},
          {"label": "Muri", "value": "C116591"},
          {"label": "Nidau", "value": "C116592"},
          {"label": "Niederbipp", "value": "C116593"},
          {"label": "Oberaargau", "value": "C116594"},
          {"label": "Oberbipp", "value": "C116595"},
          {"label": "Oberburg", "value": "C116596"},
          {"label": "Oberdiessbach", "value": "C116597"},
          {"label": "Obersimmental-Saanen District", "value": "C116598"},
          {"label": "Orpund", "value": "C116599"},
          {"label": "Orvin", "value": "C116600"},
          {"label": "Pery", "value": "C116601"},
          {"label": "Pieterlen", "value": "C116602"},
          {"label": "Radelfingen", "value": "C116603"},
          {"label": "Reconvilier", "value": "C116604"},
          {"label": "Riggisberg", "value": "C116605"},
          {"label": "Ringgenberg", "value": "C116606"},
          {"label": "Roggwil", "value": "C116607"},
          {"label": "Rohrbach", "value": "C116608"},
          {"label": "Rubigen", "value": "C116609"},
          {"label": "Ruderswil", "value": "C116610"},
          {"label": "Rueggisberg", "value": "C116611"},
          {"label": "Ruschegg", "value": "C116612"},
          {"label": "Saanen", "value": "C116613"},
          {"label": "Saint-Imier", "value": "C116614"},
          {"label": "Sankt Stephan", "value": "C116615"},
          {"label": "Schupfen", "value": "C116616"},
          {"label": "Seeberg", "value": "C116617"},
          {"label": "Seedorf", "value": "C116618"},
          {"label": "Seeland District", "value": "C116619"},
          {"label": "Seftigen", "value": "C116620"},
          {"label": "Signau", "value": "C116621"},
          {"label": "Sigriswil", "value": "C116622"},
          {"label": "Spiez", "value": "C116623"},
          {"label": "Steffisburg", "value": "C116624"},
          {"label": "Stettlen", "value": "C116625"},
          {"label": "Sumiswald", "value": "C116626"},
          {"label": "Tauffelen", "value": "C116627"},
          {"label": "Tavannes", "value": "C116628"},
          {"label": "Thierachern", "value": "C116629"},
          {"label": "Thun", "value": "C116630"},
          {"label": "Thun District", "value": "C116631"},
          {"label": "Toffen", "value": "C116632"},
          {"label": "Trachselwald", "value": "C116633"},
          {"label": "Tramelan", "value": "C116634"},
          {"label": "Trub", "value": "C116635"},
          {"label": "Trubschachen", "value": "C116636"},
          {"label": "Uetendorf", "value": "C116637"},
          {"label": "Unterseen", "value": "C116638"},
          {"label": "Urtenen", "value": "C116639"},
          {"label": "Uttigen", "value": "C116640"},
          {"label": "Utzenstorf", "value": "C116641"},
          {"label": "Vechigen", "value": "C116642"},
          {"label": "Walkringen", "value": "C116643"},
          {"label": "Wangen an der Aare", "value": "C116644"},
          {"label": "Wattenwil", "value": "C116645"},
          {"label": "Wichtrach", "value": "C116646"},
          {"label": "Wiedlisbach", "value": "C116647"},
          {"label": "Wilderswil", "value": "C116648"},
          {"label": "Wimmis", "value": "C116649"},
          {"label": "Wohlen", "value": "C116650"},
          {"label": "Worb", "value": "C116651"},
          {"label": "Worben", "value": "C116652"},
          {"label": "Wynau", "value": "C116653"},
          {"label": "Wynigen", "value": "C116654"},
          {"label": "Wyssachen", "value": "C116655"},
          {"label": "Zaziwil", "value": "C116656"},
          {"label": "Zollikofen", "value": "C116657"},
          {"label": "Zweisimmen", "value": "C116658"}],
 "Berovo Municipality": [{"label": "Berovo", "value": "C65356"},
                         {"label": "Rusinovo", "value": "C65357"},
                         {"label": "Vladimirovo", "value": "C65358"}],
 "Beverina Municipality": [{"label": "Murmuiza", "value": "C64854"}],
 "Beylagan District": [{"label": "Beylagan", "value": "C8417"},
                       {"label": "Birinci Asiqli", "value": "C8418"},
                       {"label": "Dunyamalilar", "value": "C8419"},
                       {"label": "Orjonikidze", "value": "C8420"},
                       {"label": "Yuxari Aran", "value": "C8421"}],
 "Bie Province": [{"label": "Camacupa", "value": "C530"},
                  {"label": "Catabola", "value": "C531"},
                  {"label": "Chissamba", "value": "C532"},
                  {"label": "Cuito", "value": "C533"}],
 "Bihar": [{"label": "Amarpur", "value": "C45181"},
           {"label": "Araria", "value": "C45182"},
           {"label": "Arrah", "value": "C45183"},
           {"label": "Arwal", "value": "C45184"},
           {"label": "Asarganj", "value": "C45185"},
           {"label": "Aurangabad", "value": "C45186"},
           {"label": "Bagaha", "value": "C45187"},
           {"label": "Bahadurganj", "value": "C45188"},
           {"label": "Bairagnia", "value": "C45189"},
           {"label": "Baisi", "value": "C45190"},
           {"label": "Bakhtiyarpur", "value": "C45191"},
           {"label": "Bangaon", "value": "C45192"},
           {"label": "Banka", "value": "C45193"},
           {"label": "Banmankhi", "value": "C45194"},
           {"label": "Bar Bigha", "value": "C45195"},
           {"label": "Barauli", "value": "C45196"},
           {"label": "Barh", "value": "C45197"},
           {"label": "Barhiya", "value": "C45198"},
           {"label": "Bariarpur", "value": "C45199"},
           {"label": "Baruni", "value": "C45200"},
           {"label": "Begusarai", "value": "C45201"},
           {"label": "Belsand", "value": "C45202"},
           {"label": "Bettiah", "value": "C45203"},
           {"label": "Bhabhua", "value": "C45204"},
           {"label": "Bhagalpur", "value": "C45205"},
           {"label": "Bhagirathpur", "value": "C45206"},
           {"label": "Bhawanipur", "value": "C45207"},
           {"label": "Bhojpur", "value": "C45208"},
           {"label": "Bihar Sharif", "value": "C45209"},
           {"label": "Bihariganj", "value": "C45210"},
           {"label": "Bikramganj", "value": "C45211"},
           {"label": "Birpur", "value": "C45212"},
           {"label": "Buddh Gaya", "value": "C45213"},
           {"label": "Buxar", "value": "C45214"},
           {"label": "Chakia", "value": "C45215"},
           {"label": "Chapra", "value": "C45216"},
           {"label": "Chhatapur", "value": "C45217"},
           {"label": "Colgong", "value": "C45218"},
           {"label": "Dalsingh Sarai", "value": "C45219"},
           {"label": "Darbhanga", "value": "C45220"},
           {"label": "Daudnagar", "value": "C45221"},
           {"label": "Dehri", "value": "C45222"},
           {"label": "Dhaka", "value": "C45223"},
           {"label": "Dighwara", "value": "C45224"},
           {"label": "Dinapore", "value": "C45225"},
           {"label": "Dumra", "value": "C45226"},
           {"label": "Dumraon", "value": "C45227"},
           {"label": "Fatwa", "value": "C45228"},
           {"label": "Forbesganj", "value": "C45229"},
           {"label": "Gaya", "value": "C45230"},
           {"label": "Ghoga", "value": "C45231"},
           {"label": "Gopalganj", "value": "C45232"},
           {"label": "Hajipur", "value": "C45233"},
           {"label": "Hilsa", "value": "C45234"},
           {"label": "Hisua", "value": "C45235"},
           {"label": "Islampur", "value": "C45236"},
           {"label": "Jagdispur", "value": "C45237"},
           {"label": "Jahanabad", "value": "C45238"},
           {"label": "Jamalpur", "value": "C45239"},
           {"label": "Jamui", "value": "C45240"},
           {"label": "Jaynagar", "value": "C45241"},
           {"label": "Jehanabad", "value": "C45242"},
           {"label": "Jha-Jha", "value": "C45243"},
           {"label": "Jhanjharpur", "value": "C45244"},
           {"label": "Jogbani", "value": "C45245"},
           {"label": "Kaimur District", "value": "C45246"},
           {"label": "Kasba", "value": "C45247"},
           {"label": "Katihar", "value": "C45248"},
           {"label": "Khagaria", "value": "C45249"},
           {"label": "Khagaul", "value": "C45250"},
           {"label": "Kharagpur", "value": "C45251"},
           {"label": "Khusropur", "value": "C45252"},
           {"label": "Kishanganj", "value": "C45253"},
           {"label": "Koath", "value": "C45254"},
           {"label": "Koelwar", "value": "C45255"},
           {"label": "Lakhisarai", "value": "C45256"},
           {"label": "Lalganj", "value": "C45257"},
           {"label": "Luckeesarai", "value": "C45258"},
           {"label": "Madhepura", "value": "C45259"},
           {"label": "Madhipura", "value": "C45260"},
           {"label": "Madhubani", "value": "C45261"},
           {"label": "Maharajgani", "value": "C45262"},
           {"label": "Mairwa", "value": "C45263"},
           {"label": "Maner", "value": "C45264"},
           {"label": "Manihari", "value": "C45265"},
           {"label": "Marhaura", "value": "C45266"},
           {"label": "Masaurhi Buzurg", "value": "C45267"},
           {"label": "Mohiuddinnagar", "value": "C45268"},
           {"label": "Mokameh", "value": "C45269"},
           {"label": "Monghyr", "value": "C45270"},
           {"label": "Mothihari", "value": "C45271"},
           {"label": "Munger", "value": "C45272"},
           {"label": "Murliganj", "value": "C45273"},
           {"label": "Muzaffarpur", "value": "C45274"},
           {"label": "Nabinagar", "value": "C45275"},
           {"label": "Nalanda", "value": "C45276"},
           {"label": "Nasriganj", "value": "C45277"},
           {"label": "Naugachhia", "value": "C45278"},
           {"label": "Nawada", "value": "C45279"},
           {"label": "Nirmali", "value": "C45280"},
           {"label": "Pashchim Champaran", "value": "C45281"},
           {"label": "Patna", "value": "C45282"},
           {"label": "Piro", "value": "C45283"},
           {"label": "Pupri", "value": "C45284"},
           {"label": "Purba Champaran", "value": "C45285"},
           {"label": "Purnia", "value": "C45286"},
           {"label": "Rafiganj", "value": "C45287"},
           {"label": "Raghunathpur", "value": "C45288"},
           {"label": "Rajgir", "value": "C45289"},
           {"label": "Ramnagar", "value": "C45290"},
           {"label": "Raxaul", "value": "C45291"},
           {"label": "Revelganj", "value": "C45292"},
           {"label": "Rohtas", "value": "C45293"},
           {"label": "Rusera", "value": "C45294"},
           {"label": "Sagauli", "value": "C45295"},
           {"label": "Saharsa", "value": "C45296"},
           {"label": "Samastipur", "value": "C45297"},
           {"label": "Saran", "value": "C45298"},
           {"label": "Shahbazpur", "value": "C45299"},
           {"label": "Shahpur", "value": "C45300"},
           {"label": "Sheikhpura", "value": "C45301"},
           {"label": "Sheohar", "value": "C45302"},
           {"label": "Sherghati", "value": "C45303"},
           {"label": "Silao", "value": "C45304"},
           {"label": "Sitamarhi", "value": "C45305"},
           {"label": "Siwan", "value": "C45306"},
           {"label": "Supaul", "value": "C45307"},
           {"label": "Teghra", "value": "C45308"},
           {"label": "Tekari", "value": "C45309"},
           {"label": "Thakurganj", "value": "C45310"},
           {"label": "Vaishali", "value": "C45311"},
           {"label": "Waris Aliganj", "value": "C45312"}],
 "Bihor County": [{"label": "Abram", "value": "C92967"},
                  {"label": "Abramut", "value": "C92968"},
                  {"label": "Albis", "value": "C92969"},
                  {"label": "Alesd", "value": "C92970"},
                  {"label": "Alparea", "value": "C92971"},
                  {"label": "Astileu", "value": "C92972"},
                  {"label": "Auseu", "value": "C92973"},
                  {"label": "Avram Iancu", "value": "C92974"},
                  {"label": "Balc", "value": "C92975"},
                  {"label": "Balnaca", "value": "C92976"},
                  {"label": "Batar", "value": "C92977"},
                  {"label": "Beius", "value": "C92978"},
                  {"label": "Beznea", "value": "C92979"},
                  {"label": "Biharia", "value": "C92980"},
                  {"label": "Bistra", "value": "C92981"},
                  {"label": "Bogei", "value": "C92982"},
                  {"label": "Boianu Mare", "value": "C92983"},
                  {"label": "Borod", "value": "C92984"},
                  {"label": "Bors", "value": "C92985"},
                  {"label": "Bratca", "value": "C92986"},
                  {"label": "Brusturi", "value": "C92987"},
                  {"label": "Budureasa", "value": "C92988"},
                  {"label": "Buduslau", "value": "C92989"},
                  {"label": "Bulz", "value": "C92990"},
                  {"label": "Buntesti", "value": "C92991"},
                  {"label": "Burzuc", "value": "C92992"},
                  {"label": "Cabesti", "value": "C92993"},
                  {"label": "Cadea", "value": "C92994"},
                  {"label": "Calacea", "value": "C92995"},
                  {"label": "Campani", "value": "C92996"},
                  {"label": "Campani de Pomezeu", "value": "C92997"},
                  {"label": "Capalna", "value": "C92998"},
                  {"label": "Carpinet", "value": "C92999"},
                  {"label": "Cefa", "value": "C93000"},
                  {"label": "Ceica", "value": "C93001"},
                  {"label": "Cetariu", "value": "C93002"},
                  {"label": "Cherechiu", "value": "C93003"},
                  {"label": "Chesereu", "value": "C93004"},
                  {"label": "Chet", "value": "C93005"},
                  {"label": "Chislaz", "value": "C93006"},
                  {"label": "Ciumeghiu", "value": "C93007"},
                  {"label": "Ciutelec", "value": "C93008"},
                  {"label": "Cociuba Mare", "value": "C93009"},
                  {"label": "Comuna Abram", "value": "C93010"},
                  {"label": "Comuna Abramut", "value": "C93011"},
                  {"label": "Comuna Astileu", "value": "C93012"},
                  {"label": "Comuna Auseu", "value": "C93013"},
                  {"label": "Comuna Avram Iancu", "value": "C93014"},
                  {"label": "Comuna Balc", "value": "C93015"},
                  {"label": "Comuna Batar", "value": "C93016"},
                  {"label": "Comuna Biharia", "value": "C93017"},
                  {"label": "Comuna Boianu Mare", "value": "C93018"},
                  {"label": "Comuna Borod", "value": "C93019"},
                  {"label": "Comuna Bors", "value": "C93020"},
                  {"label": "Comuna Bratca", "value": "C93021"},
                  {"label": "Comuna Brusturi", "value": "C93022"},
                  {"label": "Comuna Budureasa", "value": "C93023"},
                  {"label": "Comuna Buduslau", "value": "C93024"},
                  {"label": "Comuna Bulz", "value": "C93025"},
                  {"label": "Comuna Buntesti", "value": "C93026"},
                  {"label": "Comuna Cabesti", "value": "C93027"},
                  {"label": "Comuna Campani", "value": "C93028"},
                  {"label": "Comuna Capalna", "value": "C93029"},
                  {"label": "Comuna Carpinet", "value": "C93030"},
                  {"label": "Comuna Cefa", "value": "C93031"},
                  {"label": "Comuna Ceica", "value": "C93032"},
                  {"label": "Comuna Cetariu", "value": "C93033"},
                  {"label": "Comuna Cherechiu", "value": "C93034"},
                  {"label": "Comuna Chislaz", "value": "C93035"},
                  {"label": "Comuna Ciumeghiu", "value": "C93036"},
                  {"label": "Comuna Cociuba Mare", "value": "C93037"},
                  {"label": "Comuna Copacel", "value": "C93038"},
                  {"label": "Comuna Cristioru de Jos", "value": "C93039"},
                  {"label": "Comuna Curatele", "value": "C93040"},
                  {"label": "Comuna Curtuiseni", "value": "C93041"},
                  {"label": "Comuna Derna", "value": "C93042"},
                  {"label": "Comuna Diosig", "value": "C93043"},
                  {"label": "Comuna Dobresti", "value": "C93044"},
                  {"label": "Comuna Draganesti", "value": "C93045"},
                  {"label": "Comuna Dragesti", "value": "C93046"},
                  {"label": "Comuna Finis", "value": "C93047"},
                  {"label": "Comuna Gepiu", "value": "C93048"},
                  {"label": "Comuna Girisu de Cris", "value": "C93049"},
                  {"label": "Comuna Hidiselu De Sus", "value": "C93050"},
                  {"label": "Comuna Holod", "value": "C93051"},
                  {"label": "Comuna Husasau de Tinca", "value": "C93052"},
                  {"label": "Comuna Ineu", "value": "C93053"},
                  {"label": "Comuna Lazareni", "value": "C93054"},
                  {"label": "Comuna Lazuri de Beius", "value": "C93055"},
                  {"label": "Comuna Lugasu De Jos", "value": "C93056"},
                  {"label": "Comuna Lunca", "value": "C93057"},
                  {"label": "Comuna Madaras", "value": "C93058"},
                  {"label": "Comuna Magesti", "value": "C93059"},
                  {"label": "Comuna Nojorid", "value": "C93060"},
                  {"label": "Comuna Olcea", "value": "C93061"},
                  {"label": "Comuna Osorhei", "value": "C93062"},
                  {"label": "Comuna Paleu", "value": "C93063"},
                  {"label": "Comuna Pietroasa", "value": "C93064"},
                  {"label": "Comuna Pocola", "value": "C93065"},
                  {"label": "Comuna Pomezeu", "value": "C93066"},
                  {"label": "Comuna Popesti", "value": "C93067"},
                  {"label": "Comuna Rabagani", "value": "C93068"},
                  {"label": "Comuna Remetea", "value": "C93069"},
                  {"label": "Comuna Rieni", "value": "C93070"},
                  {"label": "Comuna Rosia", "value": "C93071"},
                  {"label": "Comuna Rosiori", "value": "C93072"},
                  {"label": "Comuna Sacadat", "value": "C93073"},
                  {"label": "Comuna Salacea", "value": "C93074"},
                  {"label": "Comuna Salard", "value": "C93075"},
                  {"label": "Comuna Sambata", "value": "C93076"},
                  {"label": "Comuna Saniob", "value": "C93077"},
                  {"label": "Comuna Sanmartin", "value": "C93078"},
                  {"label": "Comuna Sannicolau-Roman", "value": "C93079"},
                  {"label": "Comuna Santandrei", "value": "C93080"},
                  {"label": "Comuna Sarbi", "value": "C93081"},
                  {"label": "Comuna Simian", "value": "C93082"},
                  {"label": "Comuna Sinteu", "value": "C93083"},
                  {"label": "Comuna Soimi", "value": "C93084"},
                  {"label": "Comuna Spinus", "value": "C93085"},
                  {"label": "Comuna Suncuius", "value": "C93086"},
                  {"label": "Comuna Suplacu de Barcau", "value": "C93087"},
                  {"label": "Comuna Tamaseu", "value": "C93088"},
                  {"label": "Comuna Tarcaia", "value": "C93089"},
                  {"label": "Comuna Tarcea", "value": "C93090"},
                  {"label": "Comuna Tauteu", "value": "C93091"},
                  {"label": "Comuna Tetchea", "value": "C93092"},
                  {"label": "Comuna Tileagd", "value": "C93093"},
                  {"label": "Comuna Tinca", "value": "C93094"},
                  {"label": "Comuna Toboliu", "value": "C93095"},
                  {"label": "Comuna Tulca", "value": "C93096"},
                  {"label": "Comuna Uileacu de Beius", "value": "C93097"},
                  {"label": "Comuna Vadu Crisului", "value": "C93098"},
                  {"label": "Comuna Varciorog", "value": "C93099"},
                  {"label": "Comuna Viisoara", "value": "C93100"},
                  {"label": "Copacel", "value": "C93101"},
                  {"label": "Cordau", "value": "C93102"},
                  {"label": "Cristioru de Jos", "value": "C93103"},
                  {"label": "Cubulcut", "value": "C93104"},
                  {"label": "Curatele", "value": "C93105"},
                  {"label": "Curtuiseni", "value": "C93106"},
                  {"label": "Cuzap", "value": "C93107"},
                  {"label": "Derna", "value": "C93108"},
                  {"label": "Diosig", "value": "C93109"},
                  {"label": "Dobresti", "value": "C93110"},
                  {"label": "Draganesti", "value": "C93111"},
                  {"label": "Dragesti", "value": "C93112"},
                  {"label": "Finis", "value": "C93113"},
                  {"label": "Foglas", "value": "C93114"},
                  {"label": "Forau", "value": "C93115"},
                  {"label": "Galospetreu", "value": "C93116"},
                  {"label": "Gepiu", "value": "C93117"},
                  {"label": "Ghighiseni", "value": "C93118"},
                  {"label": "Ghiorac", "value": "C93119"},
                  {"label": "Girisu de Cris", "value": "C93120"},
                  {"label": "Grosi", "value": "C93121"},
                  {"label": "Gurbediu", "value": "C93122"},
                  {"label": "Hidiselu de Sus", "value": "C93123"},
                  {"label": "Holod", "value": "C93124"},
                  {"label": "Hotar", "value": "C93125"},
                  {"label": "Husasau de Tinca", "value": "C93126"},
                  {"label": "Ianosda", "value": "C93127"},
                  {"label": "Ineu", "value": "C93128"},
                  {"label": "Lazareni", "value": "C93129"},
                  {"label": "Lazuri de Beius", "value": "C93130"},
                  {"label": "Livada de Bihor", "value": "C93131"},
                  {"label": "Lugasu de Jos", "value": "C93132"},
                  {"label": "Lunca", "value": "C93133"},
                  {"label": "Luncsoara", "value": "C93134"},
                  {"label": "Madaras", "value": "C93135"},
                  {"label": "Magesti", "value": "C93136"},
                  {"label": "Marghita", "value": "C93137"},
                  {"label": "Meziad", "value": "C93138"},
                  {"label": "Mihai Bravu", "value": "C93139"},
                  {"label": "Misca", "value": "C93140"},
                  {"label": "Municipiul Beius", "value": "C93141"},
                  {"label": "Municipiul Marghita", "value": "C93142"},
                  {"label": "Municipiul Oradea", "value": "C93143"},
                  {"label": "Municipiul Salonta", "value": "C93144"},
                  {"label": "Nojorid", "value": "C93145"},
                  {"label": "Nucet", "value": "C93146"},
                  {"label": "Olcea", "value": "C93147"},
                  {"label": "Oradea", "value": "C93148"},
                  {"label": "Oras Alesd", "value": "C93149"},
                  {"label": "Oras Nucet", "value": "C93150"},
                  {"label": "Oras Sacueni", "value": "C93151"},
                  {"label": "Oras Stei", "value": "C93152"},
                  {"label": "Oras Valea Lui Mihai", "value": "C93153"},
                  {"label": "Oras Vascau", "value": "C93154"},
                  {"label": "Osorhei", "value": "C93155"},
                  {"label": "Padurea Neagra", "value": "C93156"},
                  {"label": "Paleu", "value": "C93157"},
                  {"label": "Pestis", "value": "C93158"},
                  {"label": "Petreu", "value": "C93159"},
                  {"label": "Pietroasa", "value": "C93160"},
                  {"label": "Pocola", "value": "C93161"},
                  {"label": "Pomezeu", "value": "C93162"},
                  {"label": "Popesti", "value": "C93163"},
                  {"label": "Rabagani", "value": "C93164"},
                  {"label": "Remetea", "value": "C93165"},
                  {"label": "Rieni", "value": "C93166"},
                  {"label": "Rosia", "value": "C93167"},
                  {"label": "Rosiori", "value": "C93168"},
                  {"label": "Sacadat", "value": "C93169"},
                  {"label": "Sacueni", "value": "C93170"},
                  {"label": "Salacea", "value": "C93171"},
                  {"label": "Salard", "value": "C93172"},
                  {"label": "Salonta", "value": "C93173"},
                  {"label": "Sambata", "value": "C93174"},
                  {"label": "Saniob", "value": "C93175"},
                  {"label": "Sannicolau Roman", "value": "C93176"},
                  {"label": "Santandrei", "value": "C93177"},
                  {"label": "Santion", "value": "C93178"},
                  {"label": "Sarbi", "value": "C93179"},
                  {"label": "Silindru", "value": "C93180"},
                  {"label": "Simian", "value": "C93181"},
                  {"label": "Sinmartin", "value": "C93182"},
                  {"label": "Sinteu", "value": "C93183"},
                  {"label": "Soimi", "value": "C93184"},
                  {"label": "Spinus", "value": "C93185"},
                  {"label": "Stei", "value": "C93186"},
                  {"label": "Suncuius", "value": "C93187"},
                  {"label": "Suplacu de Barcau", "value": "C93188"},
                  {"label": "Talpos", "value": "C93189"},
                  {"label": "Tamasda", "value": "C93190"},
                  {"label": "Tamaseu", "value": "C93191"},
                  {"label": "Tarcaia", "value": "C93192"},
                  {"label": "Tarcea", "value": "C93193"},
                  {"label": "Tasad", "value": "C93194"},
                  {"label": "Taut", "value": "C93195"},
                  {"label": "Tauteu", "value": "C93196"},
                  {"label": "Tileagd", "value": "C93197"},
                  {"label": "Tinca", "value": "C93198"},
                  {"label": "Toboliu", "value": "C93199"},
                  {"label": "Tulca", "value": "C93200"},
                  {"label": "Uileacu de Beius", "value": "C93201"},
                  {"label": "Vadu Crisului", "value": "C93202"},
                  {"label": "Valea lui Mihai", "value": "C93203"},
                  {"label": "Varciorog", "value": "C93204"},
                  {"label": "Vasad", "value": "C93205"},
                  {"label": "Vascau", "value": "C93206"},
                  {"label": "Viisoara", "value": "C93207"},
                  {"label": "Vintere", "value": "C93208"},
                  {"label": "Voivozi", "value": "C93209"}],
 "Bijelo Polje Municipality": [{"label": "Bijelo Polje", "value": "C75600"}],
 "Bilasuvar District": [{"label": "Pushkino", "value": "C8422"}],
 "Bilecik": [{"label": "Bilecik", "value": "C120048"},
             {"label": "Bozuyuk", "value": "C120049"},
             {"label": "Dodurga", "value": "C120050"},
             {"label": "Golpazari", "value": "C120051"},
             {"label": "Inhisar", "value": "C120052"},
             {"label": "Kuplu", "value": "C120053"},
             {"label": "Osmaneli", "value": "C120054"},
             {"label": "Pazaryeri", "value": "C120055"},
             {"label": "Sogut Ilcesi", "value": "C120056"},
             {"label": "Vezirhan", "value": "C120057"},
             {"label": "Yenipazar", "value": "C120058"}],
 "Bimini": [{"label": "Alice Town", "value": "C119604"}],
 "Bingol": [{"label": "Adakli Ilcesi", "value": "C120059"},
            {"label": "Bingol", "value": "C120060"},
            {"label": "Genc", "value": "C120061"},
            {"label": "Karliova", "value": "C120062"},
            {"label": "Kigi Ilcesi", "value": "C120063"},
            {"label": "Merkez", "value": "C120064"},
            {"label": "Solhan", "value": "C120065"},
            {"label": "Yayladere", "value": "C120066"},
            {"label": "Yedisu", "value": "C120067"}],
 "Binh Dinh": [{"label": "Huyen An Lao", "value": "C147304"},
               {"label": "Huyen Hoai Nhon", "value": "C147305"},
               {"label": "Huyen Phu My", "value": "C147306"},
               {"label": "Huyen Tay Son", "value": "C147307"},
               {"label": "Huyen Tuy Phuoc", "value": "C147308"},
               {"label": "Huyen Vinh Thanh", "value": "C147309"},
               {"label": "Qui Nhon", "value": "C147310"}],
 "Binh Duong": [{"label": "Di An", "value": "C147302"},
                {"label": "Thu Dau Mot", "value": "C147303"}],
 "Binh Phuoc": [{"label": "Binh Long", "value": "C147311"},
                {"label": "Don Luan", "value": "C147312"},
                {"label": "Huyen Bu Dop", "value": "C147313"},
                {"label": "Huyen Chon Thanh", "value": "C147314"},
                {"label": "Huyen Dong Phu", "value": "C147315"},
                {"label": "Huyen Hon Quan", "value": "C147316"},
                {"label": "Thi Xa Phuoc Long", "value": "C147317"}],
 "Binh Thuan": [{"label": "Huyen Bac Binh", "value": "C147318"},
                {"label": "Huyen Ham Tan", "value": "C147319"},
                {"label": "Huyen Ham Thuan Bac", "value": "C147320"},
                {"label": "Huyen Ham Thuan Nam", "value": "C147321"},
                {"label": "Huyen Tanh Linh", "value": "C147322"},
                {"label": "Huyen Tuy Phong", "value": "C147323"},
                {"label": "La Gi", "value": "C147324"},
                {"label": "Phan Thiet", "value": "C147325"},
                {"label": "Thanh Pho Phan Thiet", "value": "C147326"}],
 "Biobio": [{"label": "Alto Biobio", "value": "C17924"},
            {"label": "Antuco", "value": "C17925"},
            {"label": "Arauco", "value": "C17926"},
            {"label": "Cabrero", "value": "C17927"},
            {"label": "Canete", "value": "C17928"},
            {"label": "Chiguayante", "value": "C17929"},
            {"label": "Concepcion", "value": "C17930"},
            {"label": "Contulmo", "value": "C17931"},
            {"label": "Coronel", "value": "C17932"},
            {"label": "Curanilahue", "value": "C17933"},
            {"label": "Florida", "value": "C17934"},
            {"label": "Hualpen", "value": "C17935"},
            {"label": "Hualqui", "value": "C17936"},
            {"label": "Laja", "value": "C17937"},
            {"label": "Lebu", "value": "C17938"},
            {"label": "Los alamos", "value": "C17939"},
            {"label": "Los angeles", "value": "C17940"},
            {"label": "Lota", "value": "C17941"},
            {"label": "Mulchen", "value": "C17942"},
            {"label": "Nacimiento", "value": "C17943"},
            {"label": "Negrete", "value": "C17944"},
            {"label": "Penco", "value": "C17945"},
            {"label": "Quilaco", "value": "C17946"},
            {"label": "Quilleco", "value": "C17947"},
            {"label": "San Pedro de la Paz", "value": "C17948"},
            {"label": "San Rosendo", "value": "C17949"},
            {"label": "Santa Barbara", "value": "C17950"},
            {"label": "Santa Juana", "value": "C17951"},
            {"label": "Talcahuano", "value": "C17952"},
            {"label": "Tirua", "value": "C17953"},
            {"label": "Tome", "value": "C17954"},
            {"label": "Tucapel", "value": "C17955"},
            {"label": "Yumbel", "value": "C17956"}],
 "Bioko Norte Province": [{"label": "Malabo", "value": "C24465"},
                          {"label": "Rebola", "value": "C24466"},
                          {"label": "Santiago de Baney", "value": "C24467"}],
 "Bioko Sur Province": [{"label": "Luba", "value": "C24468"}],
 "Biombo Region": [{"label": "Quinhamel", "value": "C43053"}],
 "Birgu": [{"label": "Vittoriosa", "value": "C65871"}],
 "Birkirkara": [{"label": "Birkirkara", "value": "C65872"}],
 "Birzebbuga": [{"label": "Birzebbuga", "value": "C65873"}],
 "Bishkek": [{"label": "Bishkek", "value": "C64717"}],
 "Biskra": [{"label": "Biskra", "value": "C265"},
            {"label": "Oumache", "value": "C266"},
            {"label": "Sidi Khaled", "value": "C267"},
            {"label": "Sidi Okba", "value": "C268"},
            {"label": "Tolga", "value": "C269"},
            {"label": "Zeribet el Oued", "value": "C270"}],
 "Bistrica ob Sotli Municipality": [{"label": "Bistrica ob Sotli",
                                     "value": "C107571"}],
 "Bistrita-Nasaud County": [{"label": "Agries", "value": "C93210"},
                            {"label": "Anies", "value": "C93211"},
                            {"label": "Beclean", "value": "C93212"},
                            {"label": "Bichigiu", "value": "C93213"},
                            {"label": "Bistrita", "value": "C93214"},
                            {"label": "Bistrita Bargaului", "value": "C93215"},
                            {"label": "Branistea", "value": "C93216"},
                            {"label": "Breaza", "value": "C93217"},
                            {"label": "Budacu de Jos", "value": "C93218"},
                            {"label": "Budacu de Sus", "value": "C93219"},
                            {"label": "Budesti", "value": "C93220"},
                            {"label": "Caianu Mic", "value": "C93221"},
                            {"label": "Chiochis", "value": "C93222"},
                            {"label": "Chiuza", "value": "C93223"},
                            {"label": "Ciceu-Giurgesti", "value": "C93224"},
                            {"label": "Ciceu-Mihaiesti", "value": "C93225"},
                            {"label": "Ciresoaia", "value": "C93226"},
                            {"label": "Cociu", "value": "C93227"},
                            {"label": "Comuna Bistrita Bargaului",
                             "value": "C93228"},
                            {"label": "Comuna Branistea", "value": "C93229"},
                            {"label": "Comuna Budacu de Jos",
                             "value": "C93230"},
                            {"label": "Comuna Budesti", "value": "C93231"},
                            {"label": "Comuna Caianu", "value": "C93232"},
                            {"label": "Comuna Chiochis", "value": "C93233"},
                            {"label": "Comuna Chiuza", "value": "C93234"},
                            {"label": "Comuna Ciceu-Giurgesti",
                             "value": "C93235"},
                            {"label": "Comuna Ciceu-Mihaiesti",
                             "value": "C93236"},
                            {"label": "Comuna Cosbuc", "value": "C93237"},
                            {"label": "Comuna Dumitra", "value": "C93238"},
                            {"label": "Comuna Dumitrita", "value": "C93239"},
                            {"label": "Comuna Feldru", "value": "C93240"},
                            {"label": "Comuna Galatii Bistritei",
                             "value": "C93241"},
                            {"label": "Comuna Ilva Mare", "value": "C93242"},
                            {"label": "Comuna Ilva Mica", "value": "C93243"},
                            {"label": "Comuna Josenii Bargaului",
                             "value": "C93244"},
                            {"label": "Comuna Lechinta", "value": "C93245"},
                            {"label": "Comuna Lesu", "value": "C93246"},
                            {"label": "Comuna Livezile", "value": "C93247"},
                            {"label": "Comuna Lunca Ilvei", "value": "C93248"},
                            {"label": "Comuna Magura Ilvei", "value": "C93249"},
                            {"label": "Comuna Maieru", "value": "C93250"},
                            {"label": "Comuna Mariselu", "value": "C93251"},
                            {"label": "Comuna Matei", "value": "C93252"},
                            {"label": "Comuna Micestii de Campie",
                             "value": "C93253"},
                            {"label": "Comuna Milas", "value": "C93254"},
                            {"label": "Comuna Monor", "value": "C93255"},
                            {"label": "Comuna Negrilesti", "value": "C93256"},
                            {"label": "Comuna Nimigea", "value": "C93257"},
                            {"label": "Comuna Nuseni", "value": "C93258"},
                            {"label": "Comuna Parva", "value": "C93259"},
                            {"label": "Comuna Petru Rares", "value": "C93260"},
                            {"label": "Comuna Poiana  Ilvei",
                             "value": "C93261"},
                            {"label": "Comuna Prundu Bargaului",
                             "value": "C93262"},
                            {"label": "Comuna Razboeni-Cetate",
                             "value": "C93263"},
                            {"label": "Comuna Rebra", "value": "C93264"},
                            {"label": "Comuna Rebrisoara", "value": "C93265"},
                            {"label": "Comuna Rodna", "value": "C93266"},
                            {"label": "Comuna Romuli", "value": "C93267"},
                            {"label": "Comuna Runcu Salvei", "value": "C93268"},
                            {"label": "Comuna Salva", "value": "C93269"},
                            {"label": "Comuna Sanmihaiu de Campie",
                             "value": "C93270"},
                            {"label": "Comuna Sant", "value": "C93271"},
                            {"label": "Comuna Sieu", "value": "C93272"},
                            {"label": "Comuna Sieu-Magherus",
                             "value": "C93273"},
                            {"label": "Comuna Sieu-Odorhei", "value": "C93274"},
                            {"label": "Comuna Sieut", "value": "C93275"},
                            {"label": "Comuna Silivasu De Campie",
                             "value": "C93276"},
                            {"label": "Comuna Sintereag", "value": "C93277"},
                            {"label": "Comuna Spermezeu", "value": "C93278"},
                            {"label": "Comuna Tarlisua", "value": "C93279"},
                            {"label": "Comuna Teaca", "value": "C93280"},
                            {"label": "Comuna Telciu", "value": "C93281"},
                            {"label": "Comuna Tiha Bargaului",
                             "value": "C93282"},
                            {"label": "Comuna Uriu", "value": "C93283"},
                            {"label": "Comuna Urmenis", "value": "C93284"},
                            {"label": "Comuna Zagra", "value": "C93285"},
                            {"label": "Cormaia", "value": "C93286"},
                            {"label": "Cosbuc", "value": "C93287"},
                            {"label": "Cristestii Ciceului", "value": "C93288"},
                            {"label": "Dobric", "value": "C93289"},
                            {"label": "Dumitra", "value": "C93290"},
                            {"label": "Dumitrita", "value": "C93291"},
                            {"label": "Feldru", "value": "C93292"},
                            {"label": "Frunzi", "value": "C93293"},
                            {"label": "Galatii Bistritei", "value": "C93294"},
                            {"label": "Ilva Mare", "value": "C93295"},
                            {"label": "Ilva Mica", "value": "C93296"},
                            {"label": "Josenii Bargaului", "value": "C93297"},
                            {"label": "Lechinta", "value": "C93298"},
                            {"label": "Lesu", "value": "C93299"},
                            {"label": "Livezile", "value": "C93300"},
                            {"label": "Lunca Ilvei", "value": "C93301"},
                            {"label": "Lunca Lesului", "value": "C93302"},
                            {"label": "Magura Ilvei", "value": "C93303"},
                            {"label": "Maieru", "value": "C93304"},
                            {"label": "Mariselu", "value": "C93305"},
                            {"label": "Matei", "value": "C93306"},
                            {"label": "Micestii de Campie", "value": "C93307"},
                            {"label": "Mijlocenii Bargaului",
                             "value": "C93308"},
                            {"label": "Milas", "value": "C93309"},
                            {"label": "Monor", "value": "C93310"},
                            {"label": "Municipiul Bistrita", "value": "C93311"},
                            {"label": "Nasaud", "value": "C93312"},
                            {"label": "Negrilesti", "value": "C93313"},
                            {"label": "Nepos", "value": "C93314"},
                            {"label": "Nimigea de Jos", "value": "C93315"},
                            {"label": "Nimigea de Sus", "value": "C93316"},
                            {"label": "Nuseni", "value": "C93317"},
                            {"label": "Ocnita", "value": "C93318"},
                            {"label": "Oras Beclean", "value": "C93319"},
                            {"label": "Oras Nasaud", "value": "C93320"},
                            {"label": "Oras Sangeorz-Bai", "value": "C93321"},
                            {"label": "Parva", "value": "C93322"},
                            {"label": "Petris", "value": "C93323"},
                            {"label": "Poiana Ilvei", "value": "C93324"},
                            {"label": "Prundu Bargaului", "value": "C93325"},
                            {"label": "Rapanasu", "value": "C93326"},
                            {"label": "Rebra", "value": "C93327"},
                            {"label": "Rebrisoara", "value": "C93328"},
                            {"label": "Reteag", "value": "C93329"},
                            {"label": "Rodna", "value": "C93330"},
                            {"label": "Romuli", "value": "C93331"},
                            {"label": "Runcu Salvei", "value": "C93332"},
                            {"label": "Salva", "value": "C93333"},
                            {"label": "Sangeorz-Bai", "value": "C93334"},
                            {"label": "Sanmihaiu de Campie", "value": "C93335"},
                            {"label": "Sant", "value": "C93336"},
                            {"label": "Sieu", "value": "C93337"},
                            {"label": "Sieu-Magherus", "value": "C93338"},
                            {"label": "Sieu-Odorhei", "value": "C93339"},
                            {"label": "Sieut", "value": "C93340"},
                            {"label": "Silivasu de Campie", "value": "C93341"},
                            {"label": "Sintereag", "value": "C93342"},
                            {"label": "Spermezeu", "value": "C93343"},
                            {"label": "Susenii Bargaului", "value": "C93344"},
                            {"label": "Tarlisua", "value": "C93345"},
                            {"label": "Teaca", "value": "C93346"},
                            {"label": "Telcisor", "value": "C93347"},
                            {"label": "Telciu", "value": "C93348"},
                            {"label": "Tiha Bargaului", "value": "C93349"},
                            {"label": "Tureac", "value": "C93350"},
                            {"label": "Unirea", "value": "C93351"},
                            {"label": "Uriu", "value": "C93352"},
                            {"label": "Urmenis", "value": "C93353"},
                            {"label": "Valea Borcutului", "value": "C93354"},
                            {"label": "Viile Tecii", "value": "C93355"},
                            {"label": "Viisoara", "value": "C93356"},
                            {"label": "Zagra", "value": "C93357"}],
 "Bitlis": [{"label": "Adilcevaz", "value": "C120068"},
            {"label": "Ahlat", "value": "C120069"},
            {"label": "Begendik", "value": "C120070"},
            {"label": "Bitlis", "value": "C120071"},
            {"label": "Guroymak", "value": "C120072"},
            {"label": "Hizan", "value": "C120073"},
            {"label": "Merkez", "value": "C120074"},
            {"label": "Mutki", "value": "C120075"},
            {"label": "Tatvan", "value": "C120076"}],
 "Bitola Municipality": [{"label": "Bistrica", "value": "C65359"},
                         {"label": "Bitola", "value": "C65360"},
                         {"label": "Capari", "value": "C65361"},
                         {"label": "Dolno Orizari", "value": "C65362"},
                         {"label": "Gorno Orizari", "value": "C65363"},
                         {"label": "Kukurecani", "value": "C65364"},
                         {"label": "Logovardi", "value": "C65365"}],
 "Bizerte Governorate": [{"label": "Al Matlin", "value": "C119687"},
                         {"label": "Bizerte", "value": "C119688"},
                         {"label": "Bizerte Sud", "value": "C119689"},
                         {"label": "Douar Tindja", "value": "C119690"},
                         {"label": "El Alia", "value": "C119691"},
                         {"label": "Mateur", "value": "C119692"},
                         {"label": "Menzel Abderhaman", "value": "C119693"},
                         {"label": "Menzel Bourguiba", "value": "C119694"},
                         {"label": "Menzel Jemil", "value": "C119695"},
                         {"label": "Rafraf", "value": "C119696"},
                         {"label": "Rhar el Melah", "value": "C119697"},
                         {"label": "Sejenane", "value": "C119698"},
                         {"label": "Zahanah", "value": "C119699"}],
 "Bjelovar-Bilogora": [{"label": "Bjelovar", "value": "C21007"},
                       {"label": "Brezovac", "value": "C21008"},
                       {"label": "Cazma", "value": "C21009"},
                       {"label": "Daruvar", "value": "C21010"},
                       {"label": "Dezanovac", "value": "C21011"},
                       {"label": "Dulovac", "value": "C21012"},
                       {"label": "Garesnica", "value": "C21013"},
                       {"label": "Grad Bjelovar", "value": "C21014"},
                       {"label": "Grad Cazma", "value": "C21015"},
                       {"label": "Grad Daruvar", "value": "C21016"},
                       {"label": "Grad Garesnica", "value": "C21017"},
                       {"label": "Grad Grubisno Polje", "value": "C21018"},
                       {"label": "Grubisno Polje", "value": "C21019"},
                       {"label": "Gudovac", "value": "C21020"},
                       {"label": "Hercegovac", "value": "C21021"},
                       {"label": "Ivanska", "value": "C21022"},
                       {"label": "Kapela", "value": "C21023"},
                       {"label": "Koncanica", "value": "C21024"},
                       {"label": "Predavac", "value": "C21025"},
                       {"label": "Rovisce", "value": "C21026"},
                       {"label": "Sandrovac", "value": "C21027"},
                       {"label": "Severin", "value": "C21028"},
                       {"label": "Sirac", "value": "C21029"},
                       {"label": "Velika Pisanica", "value": "C21030"},
                       {"label": "Veliki Grdevac", "value": "C21031"},
                       {"label": "Zdralovi", "value": "C21032"},
                       {"label": "Zrinski Topolovac", "value": "C21033"}],
 "Blagoevgrad Province": [{"label": "Bansko", "value": "C15846"},
                          {"label": "Belitsa", "value": "C15847"},
                          {"label": "Blagoevgrad", "value": "C15848"},
                          {"label": "Garmen", "value": "C15849"},
                          {"label": "Gotse Delchev", "value": "C15850"},
                          {"label": "Hadzhidimovo", "value": "C15851"},
                          {"label": "Kolarovo", "value": "C15852"},
                          {"label": "Kresna", "value": "C15853"},
                          {"label": "Obshtina Bansko", "value": "C15854"},
                          {"label": "Obshtina Belitsa", "value": "C15855"},
                          {"label": "Obshtina Blagoevgrad", "value": "C15856"},
                          {"label": "Obshtina Garmen", "value": "C15857"},
                          {"label": "Obshtina Gotse Delchev",
                           "value": "C15858"},
                          {"label": "Obshtina Kresna", "value": "C15859"},
                          {"label": "Obshtina Petrich", "value": "C15860"},
                          {"label": "Obshtina Razlog", "value": "C15861"},
                          {"label": "Obshtina Sandanski", "value": "C15862"},
                          {"label": "Obshtina Satovcha", "value": "C15863"},
                          {"label": "Obshtina Simitli", "value": "C15864"},
                          {"label": "Obshtina Strumyani", "value": "C15865"},
                          {"label": "Obshtina Yakoruda", "value": "C15866"},
                          {"label": "Petrich", "value": "C15867"},
                          {"label": "Razlog", "value": "C15868"},
                          {"label": "Sandanski", "value": "C15869"},
                          {"label": "Satovcha", "value": "C15870"},
                          {"label": "Simitli", "value": "C15871"},
                          {"label": "Stara Kresna", "value": "C15872"},
                          {"label": "Strumyani", "value": "C15873"},
                          {"label": "Yakoruda", "value": "C15874"}],
 "Bled Municipality": [{"label": "Bled", "value": "C107572"},
                       {"label": "Kostanjevica na Krki", "value": "C107573"},
                       {"label": "Zasip", "value": "C107574"}],
 "Blekinge": [{"label": "Brakne-Hoby", "value": "C115507"},
              {"label": "Hallevik", "value": "C115508"},
              {"label": "Hasslo", "value": "C115509"},
              {"label": "Jamjo", "value": "C115510"},
              {"label": "Jamshog", "value": "C115511"},
              {"label": "Kallinge", "value": "C115512"},
              {"label": "Karlshamn", "value": "C115513"},
              {"label": "Karlskrona", "value": "C115514"},
              {"label": "Mjallby", "value": "C115515"},
              {"label": "Morrum", "value": "C115516"},
              {"label": "Nattraby", "value": "C115517"},
              {"label": "Olofstrom", "value": "C115518"},
              {"label": "Rodeby", "value": "C115519"},
              {"label": "Ronneby", "value": "C115520"},
              {"label": "Solvesborg", "value": "C115521"},
              {"label": "Sturko", "value": "C115522"},
              {"label": "Svangsta", "value": "C115523"}],
 "Blida": [{"label": "Beni Mered", "value": "C271"},
           {"label": "Blida", "value": "C272"},
           {"label": "Bou Arfa", "value": "C273"},
           {"label": "Boufarik", "value": "C274"},
           {"label": "Bougara", "value": "C275"},
           {"label": "Bouinan", "value": "C276"},
           {"label": "Chebli", "value": "C277"},
           {"label": "Chiffa", "value": "C278"},
           {"label": "Larbaa", "value": "C279"},
           {"label": "Meftah", "value": "C280"},
           {"label": "Sidi Moussa", "value": "C281"},
           {"label": "Souma", "value": "C282"}],
 "Bloke Municipality": [{"label": "Nova Vas", "value": "C107575"}],
 "Blue Nile": [{"label": "Ad-Damazin", "value": "C115399"},
               {"label": "Ar Ruseris", "value": "C115400"},
               {"label": "Kurmuk", "value": "C115401"}],
 "Boa Vista": [{"label": "Sal Rei", "value": "C17784"}],
 "Boaco": [{"label": "Boaco", "value": "C77887"},
           {"label": "Camoapa", "value": "C77888"},
           {"label": "San Jose de los Remates", "value": "C77889"},
           {"label": "San Lorenzo", "value": "C77890"},
           {"label": "Santa Lucia", "value": "C77891"},
           {"label": "Teustepe", "value": "C77892"}],
 "Bobonaro Municipality": [{"label": "Maliana", "value": "C24054"}],
 "Bocas del Toro Province": [{"label": "Almirante", "value": "C79786"},
                             {"label": "Barranco", "value": "C79787"},
                             {"label": "Barranco Adentro", "value": "C79788"},
                             {"label": "Bastimentos", "value": "C79789"},
                             {"label": "Bocas del Toro", "value": "C79790"},
                             {"label": "Cauchero", "value": "C79791"},
                             {"label": "Changuinola", "value": "C79792"},
                             {"label": "Chiriqui Grande", "value": "C79793"},
                             {"label": "Distrito Chiriqui Grande",
                              "value": "C79794"},
                             {"label": "Distrito de Bocas del Toro",
                              "value": "C79795"},
                             {"label": "Distrito de Changuinola",
                              "value": "C79796"},
                             {"label": "El Empalme", "value": "C79797"},
                             {"label": "El Silencio", "value": "C79798"},
                             {"label": "Guabito", "value": "C79799"},
                             {"label": "Isla Bastimentos", "value": "C79800"},
                             {"label": "La Mesa", "value": "C79801"},
                             {"label": "Miramar", "value": "C79802"},
                             {"label": "Punta Laurel", "value": "C79803"},
                             {"label": "Punta Pena", "value": "C79804"},
                             {"label": "Punta Robalo", "value": "C79805"},
                             {"label": "Valle del Risco", "value": "C79806"}],
 "Bogdanci Municipality": [{"label": "Bogdanci", "value": "C65366"},
                           {"label": "Stojakovo", "value": "C65367"}],
 "Bogota D.C.": [{"label": "Bogota D.C.", "value": "C19703"}],
 "Bogovinje Municipality": [{"label": "Bogovinje", "value": "C65368"},
                            {"label": "Dolno Palciste", "value": "C65369"},
                            {"label": "Gradec", "value": "C65370"},
                            {"label": "Kamenjane", "value": "C65371"}],
 "Bohinj Municipality": [{"label": "Bohinjska Bistrica", "value": "C107576"},
                         {"label": "Dragomer", "value": "C107577"},
                         {"label": "Log pri Brezovici", "value": "C107578"}],
 "Bohol": [{"label": "Andili", "value": "C83911"},
           {"label": "Andop", "value": "C83912"},
           {"label": "Anibongan", "value": "C83913"},
           {"label": "Astorga", "value": "C83914"},
           {"label": "Asuncion", "value": "C83915"},
           {"label": "Babag", "value": "C83916"},
           {"label": "Baculin", "value": "C83917"},
           {"label": "Baganga", "value": "C83918"},
           {"label": "Balagunan", "value": "C83919"},
           {"label": "Balangonan", "value": "C83920"},
           {"label": "Balutakay", "value": "C83921"},
           {"label": "Banaybanay", "value": "C83922"},
           {"label": "Bansalan", "value": "C83923"},
           {"label": "Bantacan", "value": "C83924"},
           {"label": "Baon", "value": "C83925"},
           {"label": "Baracatan", "value": "C83926"},
           {"label": "Basiawan", "value": "C83927"},
           {"label": "Batiano", "value": "C83928"},
           {"label": "Bato", "value": "C83929"},
           {"label": "Batobato", "value": "C83930"},
           {"label": "Baylo", "value": "C83931"},
           {"label": "Biao", "value": "C83932"},
           {"label": "Bincongan", "value": "C83933"},
           {"label": "Bitaogan", "value": "C83934"},
           {"label": "Bobon", "value": "C83935"},
           {"label": "Bolila", "value": "C83936"},
           {"label": "Boston", "value": "C83937"},
           {"label": "Buclad", "value": "C83938"},
           {"label": "Buhangin", "value": "C83939"},
           {"label": "Bukid", "value": "C83940"},
           {"label": "Bulacan", "value": "C83941"},
           {"label": "Bungabon", "value": "C83942"},
           {"label": "Butulan", "value": "C83943"},
           {"label": "Cabayangan", "value": "C83944"},
           {"label": "Cabinuangan", "value": "C83945"},
           {"label": "Caburan", "value": "C83946"},
           {"label": "Cambanugoy", "value": "C83947"},
           {"label": "Camudmud", "value": "C83948"},
           {"label": "Caraga", "value": "C83949"},
           {"label": "Carmen", "value": "C83950"},
           {"label": "Cateel", "value": "C83951"},
           {"label": "City of Mati", "value": "C83952"},
           {"label": "City of Panabo", "value": "C83953"},
           {"label": "City of Tagum", "value": "C83954"},
           {"label": "Cogon", "value": "C83955"},
           {"label": "Concepcion", "value": "C83956"},
           {"label": "Corocotan", "value": "C83957"},
           {"label": "Coronon", "value": "C83958"},
           {"label": "Cuambog", "value": "C83959"},
           {"label": "Culaman", "value": "C83960"},
           {"label": "Dacudao", "value": "C83961"},
           {"label": "Dakbayan sa Digos", "value": "C83962"},
           {"label": "Davan", "value": "C83963"},
           {"label": "Davao City", "value": "C83964"},
           {"label": "Del Pilar", "value": "C83965"},
           {"label": "Digos", "value": "C83966"},
           {"label": "Dolo", "value": "C83967"},
           {"label": "Don Marcelino", "value": "C83968"},
           {"label": "Dumlan", "value": "C83969"},
           {"label": "Esperanza", "value": "C83970"},
           {"label": "Gabi", "value": "C83971"},
           {"label": "Gabuyan", "value": "C83972"},
           {"label": "Goma", "value": "C83973"},
           {"label": "Governor Generoso", "value": "C83974"},
           {"label": "Guihing Proper", "value": "C83975"},
           {"label": "Gumalang", "value": "C83976"},
           {"label": "Gupitan", "value": "C83977"},
           {"label": "Hagonoy", "value": "C83978"},
           {"label": "Ignit", "value": "C83979"},
           {"label": "Ilangay", "value": "C83980"},
           {"label": "Inawayan", "value": "C83981"},
           {"label": "Island Garden City of Samal", "value": "C83982"},
           {"label": "Jose Abad Santos", "value": "C83983"},
           {"label": "Jovellar", "value": "C83984"},
           {"label": "Kalbay", "value": "C83985"},
           {"label": "Kalian", "value": "C83986"},
           {"label": "Kaligutan", "value": "C83987"},
           {"label": "Kapalong", "value": "C83988"},
           {"label": "Katipunan", "value": "C83989"},
           {"label": "Kiblawan", "value": "C83990"},
           {"label": "Kinablangan", "value": "C83991"},
           {"label": "Kinamayan", "value": "C83992"},
           {"label": "Kinangan", "value": "C83993"},
           {"label": "La Libertad", "value": "C83994"},
           {"label": "La Paz", "value": "C83995"},
           {"label": "La Union", "value": "C83996"},
           {"label": "Lacaron", "value": "C83997"},
           {"label": "Lacson", "value": "C83998"},
           {"label": "Lais", "value": "C83999"},
           {"label": "Lamitan", "value": "C84000"},
           {"label": "Lapuan", "value": "C84001"},
           {"label": "Lasang", "value": "C84002"},
           {"label": "Libuganon", "value": "C84003"},
           {"label": "Limao", "value": "C84004"},
           {"label": "Limot", "value": "C84005"},
           {"label": "Linao", "value": "C84006"},
           {"label": "Linoan", "value": "C84007"},
           {"label": "Lukatan", "value": "C84008"},
           {"label": "Luna", "value": "C84009"},
           {"label": "Lungaog", "value": "C84010"},
           {"label": "Lupon", "value": "C84011"},
           {"label": "Luzon", "value": "C84012"},
           {"label": "Mabini", "value": "C84013"},
           {"label": "Mabuhay", "value": "C84014"},
           {"label": "Maco", "value": "C84015"},
           {"label": "Maduao", "value": "C84016"},
           {"label": "Magatos", "value": "C84017"},
           {"label": "Magdug", "value": "C84018"},
           {"label": "Magnaga", "value": "C84019"},
           {"label": "Magsaysay", "value": "C84020"},
           {"label": "Mahanob", "value": "C84021"},
           {"label": "Mahayag", "value": "C84022"},
           {"label": "Malagos", "value": "C84023"},
           {"label": "Malalag", "value": "C84024"},
           {"label": "Malinao", "value": "C84025"},
           {"label": "Malita", "value": "C84026"},
           {"label": "Mambago", "value": "C84027"},
           {"label": "Managa", "value": "C84028"},
           {"label": "Manaloal", "value": "C84029"},
           {"label": "Manat", "value": "C84030"},
           {"label": "Manay", "value": "C84031"},
           {"label": "Mangili", "value": "C84032"},
           {"label": "Manikling", "value": "C84033"},
           {"label": "Matanao", "value": "C84034"},
           {"label": "Mati", "value": "C84035"},
           {"label": "Matiao", "value": "C84036"},
           {"label": "Matti", "value": "C84037"},
           {"label": "Mawab", "value": "C84038"},
           {"label": "Mayo", "value": "C84039"},
           {"label": "Monkayo", "value": "C84040"},
           {"label": "Montevista", "value": "C84041"},
           {"label": "Nabunturan", "value": "C84042"},
           {"label": "Nangan", "value": "C84043"},
           {"label": "Nanyo", "value": "C84044"},
           {"label": "New Baclayon", "value": "C84045"},
           {"label": "New Bataan", "value": "C84046"},
           {"label": "New Bohol", "value": "C84047"},
           {"label": "New Corella", "value": "C84048"},
           {"label": "New Leyte", "value": "C84049"},
           {"label": "New Sibonga", "value": "C84050"},
           {"label": "New Visayas", "value": "C84051"},
           {"label": "Nuing", "value": "C84052"},
           {"label": "Padada", "value": "C84053"},
           {"label": "Pag-asa", "value": "C84054"},
           {"label": "Pagsabangan", "value": "C84055"},
           {"label": "Palma Gil", "value": "C84056"},
           {"label": "Panabo", "value": "C84057"},
           {"label": "Pandasan", "value": "C84058"},
           {"label": "Pangian", "value": "C84059"},
           {"label": "Panikian", "value": "C84060"},
           {"label": "Pantukan", "value": "C84061"},
           {"label": "Pasian", "value": "C84062"},
           {"label": "Pondaguitan", "value": "C84063"},
           {"label": "Pung-Pang", "value": "C84064"},
           {"label": "Samal", "value": "C84065"},
           {"label": "Sampao", "value": "C84066"},
           {"label": "San Alfonso", "value": "C84067"},
           {"label": "San Antonio", "value": "C84068"},
           {"label": "San Ignacio", "value": "C84069"},
           {"label": "San Isidro", "value": "C84070"},
           {"label": "San Luis", "value": "C84071"},
           {"label": "San Mariano", "value": "C84072"},
           {"label": "San Miguel", "value": "C84073"},
           {"label": "San Pedro", "value": "C84074"},
           {"label": "San Rafael", "value": "C84075"},
           {"label": "San Remigio", "value": "C84076"},
           {"label": "San Vicente", "value": "C84077"},
           {"label": "Santa Cruz", "value": "C84078"},
           {"label": "Santa Maria", "value": "C84079"},
           {"label": "Santiago", "value": "C84080"},
           {"label": "Santo Nino", "value": "C84081"},
           {"label": "Santo Tomas", "value": "C84082"},
           {"label": "Sarangani", "value": "C84083"},
           {"label": "Sibulan", "value": "C84084"},
           {"label": "Sigaboy", "value": "C84085"},
           {"label": "Simod", "value": "C84086"},
           {"label": "Sinawilan", "value": "C84087"},
           {"label": "Sinayawan", "value": "C84088"},
           {"label": "Sirib", "value": "C84089"},
           {"label": "Sugal", "value": "C84090"},
           {"label": "Sulop", "value": "C84091"},
           {"label": "Surup", "value": "C84092"},
           {"label": "Suz-on", "value": "C84093"},
           {"label": "Tagakpan", "value": "C84094"},
           {"label": "Tagdanua", "value": "C84095"},
           {"label": "Tagnanan", "value": "C84096"},
           {"label": "Tagum", "value": "C84097"},
           {"label": "Takub", "value": "C84098"},
           {"label": "Talagutong", "value": "C84099"},
           {"label": "Talisay", "value": "C84100"},
           {"label": "Talomo", "value": "C84101"},
           {"label": "Tamayong", "value": "C84102"},
           {"label": "Tambo", "value": "C84103"},
           {"label": "Tamisan", "value": "C84104"},
           {"label": "Tamugan", "value": "C84105"},
           {"label": "Tanlad", "value": "C84106"},
           {"label": "Tapia", "value": "C84107"},
           {"label": "Tarragona", "value": "C84108"},
           {"label": "Tawan tawan", "value": "C84109"},
           {"label": "Taytayan", "value": "C84110"},
           {"label": "Tibagon", "value": "C84111"},
           {"label": "Tibanbang", "value": "C84112"},
           {"label": "Tiblawan", "value": "C84113"},
           {"label": "Tombongon", "value": "C84114"},
           {"label": "Tubalan", "value": "C84115"},
           {"label": "Tuban", "value": "C84116"},
           {"label": "Tubod", "value": "C84117"},
           {"label": "Tuganay", "value": "C84118"},
           {"label": "Tuli", "value": "C84119"},
           {"label": "Ula", "value": "C84120"},
           {"label": "Wangan", "value": "C84121"},
           {"label": "Wines", "value": "C84122"}],
 "Boke Region": [{"label": "Boffa", "value": "C42997"},
                 {"label": "Boke", "value": "C42998"},
                 {"label": "Boke Prefecture", "value": "C42999"},
                 {"label": "Fria", "value": "C43000"},
                 {"label": "Gaoual", "value": "C43001"},
                 {"label": "Gaoual Prefecture", "value": "C43002"},
                 {"label": "Kimbo", "value": "C43003"},
                 {"label": "Koundara", "value": "C43004"},
                 {"label": "Koundara Prefecture", "value": "C43005"},
                 {"label": "Sangueya", "value": "C43006"},
                 {"label": "Youkounkoun", "value": "C43007"}],
 "Bokeo Province": [{"label": "Ban Houakhoua", "value": "C64769"},
                    {"label": "Ban Houayxay", "value": "C64770"},
                    {"label": "Muang Houayxay", "value": "C64771"},
                    {"label": "Muang Meung", "value": "C64772"},
                    {"label": "Muang Paktha", "value": "C64773"},
                    {"label": "Muang Pha Oudom", "value": "C64774"},
                    {"label": "Muang Tonpheung", "value": "C64775"}],
 "Bolama Region": [{"label": "Bolama", "value": "C43054"},
                   {"label": "Bubaque", "value": "C43055"}],
 "Bolikhamsai Province": [{"label": "Ban Nahin", "value": "C64776"},
                          {"label": "Pakxan", "value": "C64777"}],
 "Bolivar": [{"label": "Achi", "value": "C19704"},
             {"label": "Altos del Rosario", "value": "C19705"},
             {"label": "Arenal", "value": "C19706"},
             {"label": "Arjona", "value": "C19707"},
             {"label": "Arroyohondo", "value": "C19708"},
             {"label": "Barranco de Loba", "value": "C19709"},
             {"label": "Calamar", "value": "C19710"},
             {"label": "Cantagallo", "value": "C19711"},
             {"label": "Cartagena de Indias", "value": "C19712"},
             {"label": "Cicuco", "value": "C19713"},
             {"label": "Clemencia", "value": "C19714"},
             {"label": "Cordoba", "value": "C19715"},
             {"label": "El Carmen de Bolivar", "value": "C19716"},
             {"label": "El Guamo", "value": "C19717"},
             {"label": "El Penon", "value": "C19718"},
             {"label": "Hatillo de Loba", "value": "C19719"},
             {"label": "Magangue", "value": "C19720"},
             {"label": "Mahates", "value": "C19721"},
             {"label": "Margarita", "value": "C19722"},
             {"label": "Maria la Baja", "value": "C19723"},
             {"label": "Mompos", "value": "C19724"},
             {"label": "Montecristo", "value": "C19725"},
             {"label": "Morales", "value": "C19726"},
             {"label": "Norosi", "value": "C19727"},
             {"label": "Pinillos", "value": "C19728"},
             {"label": "Regidor", "value": "C19729"},
             {"label": "Rio Viejo", "value": "C19730"},
             {"label": "San Cristobal", "value": "C19731"},
             {"label": "San Estanislao", "value": "C19732"},
             {"label": "San Fernando", "value": "C19733"},
             {"label": "San Jacinto", "value": "C19734"},
             {"label": "San Jacinto del Cauca", "value": "C19735"},
             {"label": "San Juan Nepomuceno", "value": "C19736"},
             {"label": "San Martin de Loba", "value": "C19737"},
             {"label": "San Pablo", "value": "C19738"},
             {"label": "Santa Catalina", "value": "C19739"},
             {"label": "Santa Rosa", "value": "C19740"},
             {"label": "Santa Rosa del Sur", "value": "C19741"},
             {"label": "Simiti", "value": "C19742"},
             {"label": "Soplaviento", "value": "C19743"},
             {"label": "Talaigua Nuevo", "value": "C19744"},
             {"label": "Tiquisio", "value": "C19745"},
             {"label": "Turbaco", "value": "C19746"},
             {"label": "Turbana", "value": "C19747"},
             {"label": "Villanueva", "value": "C19748"},
             {"label": "Zambrano", "value": "C19749"},
             {"label": "Guaranda", "value": "C24098"},
             {"label": "San Miguel", "value": "C24099"},
             {"label": "Ciudad Bolivar", "value": "C147153"},
             {"label": "Ciudad Guayana", "value": "C147154"},
             {"label": "Municipio Padre Pedro Chien", "value": "C147155"},
             {"label": "Santa Elena de Uairen", "value": "C147156"},
             {"label": "Upata", "value": "C147157"}],
 "Bolu": [{"label": "Bolu", "value": "C120077"},
          {"label": "Dortdivan", "value": "C120078"},
          {"label": "Gerede", "value": "C120079"},
          {"label": "Goynuk", "value": "C120080"},
          {"label": "Kibriscik", "value": "C120081"},
          {"label": "Mengen", "value": "C120082"},
          {"label": "Mudurnu", "value": "C120083"},
          {"label": "Seben", "value": "C120084"},
          {"label": "Yenicaga", "value": "C120085"}],
 "Bomet": [{"label": "Sotik", "value": "C64540"},
           {"label": "Sotik Post", "value": "C64541"}],
 "Bomi County": [{"label": "Tubmanburg", "value": "C65002"}],
 "Bonaire": [{"label": "Boven Bolivia", "value": "C9882"},
             {"label": "Dorp Tera Kora", "value": "C9883"},
             {"label": "Oranjestad", "value": "C9884"},
             {"label": "Rincon", "value": "C9885"},
             {"label": "St. Johns", "value": "C9886"},
             {"label": "The Bottom", "value": "C9887"},
             {"label": "Windwardside", "value": "C9888"},
             {"label": "Zions Hill", "value": "C9889"},
             {"label": "Boven Bolivia", "value": "C76074"},
             {"label": "Dorp Tera Kora", "value": "C76075"},
             {"label": "Oranjestad", "value": "C76076"},
             {"label": "Rincon", "value": "C76077"},
             {"label": "St. Johns", "value": "C76078"},
             {"label": "The Bottom", "value": "C76079"},
             {"label": "Windwardside", "value": "C76080"},
             {"label": "Zions Hill", "value": "C76081"}],
 "Bong County": [{"label": "Gbarnga", "value": "C65003"}],
 "Bono": [{"label": "Banda", "value": "C41476"},
          {"label": "Berekum East", "value": "C41477"},
          {"label": "Berekum West", "value": "C41478"},
          {"label": "Dormaa Central", "value": "C41479"},
          {"label": "Dormaa East", "value": "C41480"},
          {"label": "Dormaa West", "value": "C41481"},
          {"label": "Jaman North", "value": "C41482"},
          {"label": "Jaman South", "value": "C41483"},
          {"label": "Sunyani", "value": "C41484"},
          {"label": "Sunyani West", "value": "C41485"},
          {"label": "Tain", "value": "C41486"},
          {"label": "Wenchi", "value": "C41487"}],
 "Bono East": [{"label": "Atebubu-Amantin", "value": "C41488"},
               {"label": "Kintampo North", "value": "C41489"},
               {"label": "Kintampo South", "value": "C41490"},
               {"label": "Nkoranza North", "value": "C41491"},
               {"label": "Nkoranza South", "value": "C41492"},
               {"label": "Pru East", "value": "C41493"},
               {"label": "Pru West", "value": "C41494"},
               {"label": "Sene East", "value": "C41495"},
               {"label": "Sene West", "value": "C41496"},
               {"label": "Techiman", "value": "C41497"},
               {"label": "Techiman North", "value": "C41498"}],
 "Boqueron Department": [{"label": "Colonia Menno", "value": "C80502"},
                         {"label": "Colonia Neuland", "value": "C80503"},
                         {"label": "Filadelfia", "value": "C80504"}],
 "Bordj Bou Arreridj": [{"label": "Bordj Bou Arreridj", "value": "C283"},
                        {"label": "Bordj Ghdir", "value": "C284"},
                        {"label": "Bordj Zemoura", "value": "C285"},
                        {"label": "El Achir", "value": "C286"},
                        {"label": "Mansourah", "value": "C287"},
                        {"label": "Melouza", "value": "C288"},
                        {"label": "Ras el Oued", "value": "C289"}],
 "Borgo Maggiore": [{"label": "Borgo Maggiore", "value": "C106246"}],
 "Borgou Department": [{"label": "Bembereke", "value": "C9605"},
                       {"label": "Beterou", "value": "C9606"},
                       {"label": "Nikki", "value": "C9607"},
                       {"label": "Parakou", "value": "C9608"},
                       {"label": "Tchaourou", "value": "C9609"}],
 "Borkou": [{"label": "Faya-Largeau", "value": "C17854"}],
 "Borno": [{"label": "Bama", "value": "C78185"},
           {"label": "Benisheikh", "value": "C78186"},
           {"label": "Biu", "value": "C78187"},
           {"label": "Bornu Yassu", "value": "C78188"},
           {"label": "Damasak", "value": "C78189"},
           {"label": "Damboa", "value": "C78190"},
           {"label": "Dikwa", "value": "C78191"},
           {"label": "Gamboru", "value": "C78192"},
           {"label": "Gwoza", "value": "C78193"},
           {"label": "Kukawa", "value": "C78194"},
           {"label": "Magumeri", "value": "C78195"},
           {"label": "Maiduguri", "value": "C78196"},
           {"label": "Marte", "value": "C78197"},
           {"label": "Miringa", "value": "C78198"},
           {"label": "Monguno", "value": "C78199"},
           {"label": "Ngala", "value": "C78200"},
           {"label": "Shaffa", "value": "C78201"},
           {"label": "Shani", "value": "C78202"},
           {"label": "Tokombere", "value": "C78203"},
           {"label": "Uba", "value": "C78204"},
           {"label": "Wuyo", "value": "C78205"},
           {"label": "Yajiwa", "value": "C78206"}],
 "Borovnica Municipality": [{"label": "Borovnica", "value": "C107579"},
                            {"label": "Makole", "value": "C107580"}],
 "Borsod-Abauj-Zemplen": [{"label": "Abaujszanto", "value": "C43907"},
                          {"label": "Alsozsolca", "value": "C43908"},
                          {"label": "Arlo", "value": "C43909"},
                          {"label": "Arnot", "value": "C43910"},
                          {"label": "Aszalo", "value": "C43911"},
                          {"label": "Bekecs", "value": "C43912"},
                          {"label": "Bocs", "value": "C43913"},
                          {"label": "Bogacs", "value": "C43914"},
                          {"label": "Boldva", "value": "C43915"},
                          {"label": "Borsodnadasd", "value": "C43916"},
                          {"label": "Cigand", "value": "C43917"},
                          {"label": "Cigandi Jaras", "value": "C43918"},
                          {"label": "Edeleny", "value": "C43919"},
                          {"label": "Edelenyi Jaras", "value": "C43920"},
                          {"label": "Emod", "value": "C43921"},
                          {"label": "Encs", "value": "C43922"},
                          {"label": "Encsi Jaras", "value": "C43923"},
                          {"label": "Farkaslyuk", "value": "C43924"},
                          {"label": "Felsozsolca", "value": "C43925"},
                          {"label": "Gesztely", "value": "C43926"},
                          {"label": "Gonc", "value": "C43927"},
                          {"label": "Gonci Jaras", "value": "C43928"},
                          {"label": "Halmaj", "value": "C43929"},
                          {"label": "Harsany", "value": "C43930"},
                          {"label": "Hejobaba", "value": "C43931"},
                          {"label": "Hernadnemeti", "value": "C43932"},
                          {"label": "Izsofalva", "value": "C43933"},
                          {"label": "Jardanhaza", "value": "C43934"},
                          {"label": "Karcsa", "value": "C43935"},
                          {"label": "Kazincbarcika", "value": "C43936"},
                          {"label": "Kazincbarcikai Jaras", "value": "C43937"},
                          {"label": "Mad", "value": "C43938"},
                          {"label": "Malyi", "value": "C43939"},
                          {"label": "Megyaszo", "value": "C43940"},
                          {"label": "Mezocsat", "value": "C43941"},
                          {"label": "Mezocsati Jaras", "value": "C43942"},
                          {"label": "Mezokeresztes", "value": "C43943"},
                          {"label": "Mezokovesd", "value": "C43944"},
                          {"label": "Mezokovesdi Jaras", "value": "C43945"},
                          {"label": "Mezozombor", "value": "C43946"},
                          {"label": "Miskolc", "value": "C43947"},
                          {"label": "Miskolci Jaras", "value": "C43948"},
                          {"label": "Monok", "value": "C43949"},
                          {"label": "Mucsony", "value": "C43950"},
                          {"label": "Nyekladhaza", "value": "C43951"},
                          {"label": "Olaszliszka", "value": "C43952"},
                          {"label": "Onga", "value": "C43953"},
                          {"label": "onod", "value": "C43954"},
                          {"label": "ozd", "value": "C43955"},
                          {"label": "ozdi Jaras", "value": "C43956"},
                          {"label": "Prugy", "value": "C43957"},
                          {"label": "Putnok", "value": "C43958"},
                          {"label": "Putnoki Jaras", "value": "C43959"},
                          {"label": "Ricse", "value": "C43960"},
                          {"label": "Rudabanya", "value": "C43961"},
                          {"label": "Sajobabony", "value": "C43962"},
                          {"label": "Sajokaza", "value": "C43963"},
                          {"label": "Sajolad", "value": "C43964"},
                          {"label": "Sajooros", "value": "C43965"},
                          {"label": "Sajoszentpeter", "value": "C43966"},
                          {"label": "Sajoszoged", "value": "C43967"},
                          {"label": "Sajovamos", "value": "C43968"},
                          {"label": "Saly", "value": "C43969"},
                          {"label": "Sarospatak", "value": "C43970"},
                          {"label": "Sarospataki Jaras", "value": "C43971"},
                          {"label": "Satoraljaujhely", "value": "C43972"},
                          {"label": "Satoraljaujhelyi Jaras",
                           "value": "C43973"},
                          {"label": "Szendro", "value": "C43974"},
                          {"label": "Szentistvan", "value": "C43975"},
                          {"label": "Szerencs", "value": "C43976"},
                          {"label": "Szerencsi Jaras", "value": "C43977"},
                          {"label": "Szikszo", "value": "C43978"},
                          {"label": "Szikszoi Jaras", "value": "C43979"},
                          {"label": "Szirmabesenyo", "value": "C43980"},
                          {"label": "Taktaharkany", "value": "C43981"},
                          {"label": "Taktaszada", "value": "C43982"},
                          {"label": "Tallya", "value": "C43983"},
                          {"label": "Tarcal", "value": "C43984"},
                          {"label": "Tiszakarad", "value": "C43985"},
                          {"label": "Tiszakeszi", "value": "C43986"},
                          {"label": "Tiszaluc", "value": "C43987"},
                          {"label": "Tiszaujvaros", "value": "C43988"},
                          {"label": "Tiszaujvarosi Jaras", "value": "C43989"},
                          {"label": "Tokaj", "value": "C43990"},
                          {"label": "Tokaji Jaras", "value": "C43991"},
                          {"label": "Tolcsva", "value": "C43992"}],
 "Bosilovo Municipality": [{"label": "Bosilovo", "value": "C65372"},
                           {"label": "Ilovica", "value": "C65373"},
                           {"label": "Sekirnik", "value": "C65374"}],
 "Botosani County": [{"label": "Adaseni", "value": "C93358"},
                     {"label": "Alba", "value": "C93359"},
                     {"label": "Albesti", "value": "C93360"},
                     {"label": "Avrameni", "value": "C93361"},
                     {"label": "Bajura", "value": "C93362"},
                     {"label": "Baluseni", "value": "C93363"},
                     {"label": "Baranca", "value": "C93364"},
                     {"label": "Blandesti", "value": "C93365"},
                     {"label": "Bobulesti", "value": "C93366"},
                     {"label": "Botosani", "value": "C93367"},
                     {"label": "Braesti", "value": "C93368"},
                     {"label": "Brehuiesti", "value": "C93369"},
                     {"label": "Broscauti", "value": "C93370"},
                     {"label": "Bucecea", "value": "C93371"},
                     {"label": "Bucovineni", "value": "C93372"},
                     {"label": "Calarasi", "value": "C93373"},
                     {"label": "Candesti", "value": "C93374"},
                     {"label": "Catamaresti-Deal", "value": "C93375"},
                     {"label": "Comuna Adaseni", "value": "C93376"},
                     {"label": "Comuna Albesti", "value": "C93377"},
                     {"label": "Comuna Avrameni", "value": "C93378"},
                     {"label": "Comuna Baluseni", "value": "C93379"},
                     {"label": "Comuna Blandesti", "value": "C93380"},
                     {"label": "Comuna Braesti", "value": "C93381"},
                     {"label": "Comuna Broscauti", "value": "C93382"},
                     {"label": "Comuna Calarasi", "value": "C93383"},
                     {"label": "Comuna Candesti", "value": "C93384"},
                     {"label": "Comuna Concesti", "value": "C93385"},
                     {"label": "Comuna Copalau", "value": "C93386"},
                     {"label": "Comuna Cordareni", "value": "C93387"},
                     {"label": "Comuna Corlateni", "value": "C93388"},
                     {"label": "Comuna Corni", "value": "C93389"},
                     {"label": "Comuna Cosula", "value": "C93390"},
                     {"label": "Comuna Cotusca", "value": "C93391"},
                     {"label": "Comuna Cristesti", "value": "C93392"},
                     {"label": "Comuna Cristinesti", "value": "C93393"},
                     {"label": "Comuna Curtesti", "value": "C93394"},
                     {"label": "Comuna Dangeni", "value": "C93395"},
                     {"label": "Comuna Dersca", "value": "C93396"},
                     {"label": "Comuna Dimacheni", "value": "C93397"},
                     {"label": "Comuna Dobarceni", "value": "C93398"},
                     {"label": "Comuna Draguseni", "value": "C93399"},
                     {"label": "Comuna Durnesti", "value": "C93400"},
                     {"label": "Comuna Frumusica", "value": "C93401"},
                     {"label": "Comuna George Enescu", "value": "C93402"},
                     {"label": "Comuna Gorbanesti", "value": "C93403"},
                     {"label": "Comuna Hanesti", "value": "C93404"},
                     {"label": "Comuna Havarna", "value": "C93405"},
                     {"label": "Comuna Hiliseu-Horia", "value": "C93406"},
                     {"label": "Comuna Hlipiceni", "value": "C93407"},
                     {"label": "Comuna Hudesti", "value": "C93408"},
                     {"label": "Comuna Ibanesti", "value": "C93409"},
                     {"label": "Comuna Leorda", "value": "C93410"},
                     {"label": "Comuna Lozna", "value": "C93411"},
                     {"label": "Comuna Lunca", "value": "C93412"},
                     {"label": "Comuna Manoleasa", "value": "C93413"},
                     {"label": "Comuna Mihai Eminescu", "value": "C93414"},
                     {"label": "Comuna Mihaileni", "value": "C93415"},
                     {"label": "Comuna Mihalaseni", "value": "C93416"},
                     {"label": "Comuna Mileanca", "value": "C93417"},
                     {"label": "Comuna Mitoc", "value": "C93418"},
                     {"label": "Comuna Nicseni", "value": "C93419"},
                     {"label": "Comuna Paltinis", "value": "C93420"},
                     {"label": "Comuna Pomarla", "value": "C93421"},
                     {"label": "Comuna Prajeni", "value": "C93422"},
                     {"label": "Comuna Rachiti", "value": "C93423"},
                     {"label": "Comuna Radauti-Prut", "value": "C93424"},
                     {"label": "Comuna Rauseni", "value": "C93425"},
                     {"label": "Comuna Ripiceni", "value": "C93426"},
                     {"label": "Comuna Roma", "value": "C93427"},
                     {"label": "Comuna Romanesti", "value": "C93428"},
                     {"label": "Comuna Santa Mare", "value": "C93429"},
                     {"label": "Comuna Sendriceni", "value": "C93430"},
                     {"label": "Comuna Stauceni", "value": "C93431"},
                     {"label": "Comuna Stiubieni", "value": "C93432"},
                     {"label": "Comuna Suharau", "value": "C93433"},
                     {"label": "Comuna Sulita", "value": "C93434"},
                     {"label": "Comuna Todireni", "value": "C93435"},
                     {"label": "Comuna Trusesti", "value": "C93436"},
                     {"label": "Comuna Tudora", "value": "C93437"},
                     {"label": "Comuna Ungureni", "value": "C93438"},
                     {"label": "Comuna Unteni", "value": "C93439"},
                     {"label": "Comuna Vaculesti", "value": "C93440"},
                     {"label": "Comuna Varfu Campului", "value": "C93441"},
                     {"label": "Comuna Viisoara", "value": "C93442"},
                     {"label": "Comuna Vladeni", "value": "C93443"},
                     {"label": "Comuna Vlasinesti", "value": "C93444"},
                     {"label": "Comuna Vorniceni", "value": "C93445"},
                     {"label": "Comuna Vorona", "value": "C93446"},
                     {"label": "Concesti", "value": "C93447"},
                     {"label": "Copalau", "value": "C93448"},
                     {"label": "Cordareni", "value": "C93449"},
                     {"label": "Corlateni", "value": "C93450"},
                     {"label": "Corni", "value": "C93451"},
                     {"label": "Costesti", "value": "C93452"},
                     {"label": "Cosula", "value": "C93453"},
                     {"label": "Cotusca", "value": "C93454"},
                     {"label": "Cristesti", "value": "C93455"},
                     {"label": "Cristinesti", "value": "C93456"},
                     {"label": "Curtesti", "value": "C93457"},
                     {"label": "Dangeni", "value": "C93458"},
                     {"label": "Darabani", "value": "C93459"},
                     {"label": "Dersca", "value": "C93460"},
                     {"label": "Dimacheni", "value": "C93461"},
                     {"label": "Dobarceni", "value": "C93462"},
                     {"label": "Dorobanti", "value": "C93463"},
                     {"label": "Dorohoi", "value": "C93464"},
                     {"label": "Dracsani", "value": "C93465"},
                     {"label": "Draguseni", "value": "C93466"},
                     {"label": "Draxini", "value": "C93467"},
                     {"label": "Dumbravita", "value": "C93468"},
                     {"label": "Dumeni", "value": "C93469"},
                     {"label": "Durnesti", "value": "C93470"},
                     {"label": "Flamanzi", "value": "C93471"},
                     {"label": "Frumusica", "value": "C93472"},
                     {"label": "George Enescu", "value": "C93473"},
                     {"label": "Gorbanesti", "value": "C93474"},
                     {"label": "Hanesti", "value": "C93475"},
                     {"label": "Havarna", "value": "C93476"},
                     {"label": "Hiliseu-Horia", "value": "C93477"},
                     {"label": "Hlipiceni", "value": "C93478"},
                     {"label": "Hudesti", "value": "C93479"},
                     {"label": "Iacobeni", "value": "C93480"},
                     {"label": "Ibanesti", "value": "C93481"},
                     {"label": "Icuseni", "value": "C93482"},
                     {"label": "Ionaseni", "value": "C93483"},
                     {"label": "Joldesti", "value": "C93484"},
                     {"label": "Leorda", "value": "C93485"},
                     {"label": "Lozna", "value": "C93486"},
                     {"label": "Lunca", "value": "C93487"},
                     {"label": "Mandresti", "value": "C93488"},
                     {"label": "Manoleasa", "value": "C93489"},
                     {"label": "Mihaileni", "value": "C93490"},
                     {"label": "Mihalaseni", "value": "C93491"},
                     {"label": "Mileanca", "value": "C93492"},
                     {"label": "Miorcani", "value": "C93493"},
                     {"label": "Mitoc", "value": "C93494"},
                     {"label": "Mlenauti", "value": "C93495"},
                     {"label": "Municipiul Botosani", "value": "C93496"},
                     {"label": "Municipiul Dorohoi", "value": "C93497"},
                     {"label": "Nicolae Balcescu", "value": "C93498"},
                     {"label": "Nicseni", "value": "C93499"},
                     {"label": "Oneaga", "value": "C93500"},
                     {"label": "Oras Bucecea", "value": "C93501"},
                     {"label": "Oras Darabani", "value": "C93502"},
                     {"label": "Oras Flamanzi", "value": "C93503"},
                     {"label": "Oras Saveni", "value": "C93504"},
                     {"label": "Oras Stefanesti", "value": "C93505"},
                     {"label": "Oraseni-Deal", "value": "C93506"},
                     {"label": "Oroftiana", "value": "C93507"},
                     {"label": "Padureni", "value": "C93508"},
                     {"label": "Paltinis", "value": "C93509"},
                     {"label": "Poiana", "value": "C93510"},
                     {"label": "Pomarla", "value": "C93511"},
                     {"label": "Prajeni", "value": "C93512"},
                     {"label": "Rachiti", "value": "C93513"},
                     {"label": "Radauti-Prut", "value": "C93514"},
                     {"label": "Radeni", "value": "C93515"},
                     {"label": "Rauseni", "value": "C93516"},
                     {"label": "Rediu", "value": "C93517"},
                     {"label": "Ripiceni", "value": "C93518"},
                     {"label": "Roma", "value": "C93519"},
                     {"label": "Romanesti", "value": "C93520"},
                     {"label": "Rosiori", "value": "C93521"},
                     {"label": "Santa Mare", "value": "C93522"},
                     {"label": "Sarafinesti", "value": "C93523"},
                     {"label": "Sarbi", "value": "C93524"},
                     {"label": "Saveni", "value": "C93525"},
                     {"label": "Sendriceni", "value": "C93526"},
                     {"label": "Stancesti", "value": "C93527"},
                     {"label": "Stauceni", "value": "C93528"},
                     {"label": "Stefanesti", "value": "C93529"},
                     {"label": "Stefanesti-Sat", "value": "C93530"},
                     {"label": "Stiubieni", "value": "C93531"},
                     {"label": "Storesti", "value": "C93532"},
                     {"label": "Strateni", "value": "C93533"},
                     {"label": "Stroiesti", "value": "C93534"},
                     {"label": "Suharau", "value": "C93535"},
                     {"label": "Sulita", "value": "C93536"},
                     {"label": "Supitca", "value": "C93537"},
                     {"label": "Tocileni", "value": "C93538"},
                     {"label": "Todireni", "value": "C93539"},
                     {"label": "Trusesti", "value": "C93540"},
                     {"label": "Tudor Vladimirescu", "value": "C93541"},
                     {"label": "Tudora", "value": "C93542"},
                     {"label": "Ungureni", "value": "C93543"},
                     {"label": "Ungureni-Jianu", "value": "C93544"},
                     {"label": "Unteni", "value": "C93545"},
                     {"label": "Vaculesti", "value": "C93546"},
                     {"label": "Varfu Campului", "value": "C93547"},
                     {"label": "Victoria", "value": "C93548"},
                     {"label": "Viisoara", "value": "C93549"},
                     {"label": "Vladeni", "value": "C93550"},
                     {"label": "Vladeni-Deal", "value": "C93551"},
                     {"label": "Vlasinesti", "value": "C93552"},
                     {"label": "Vorniceni", "value": "C93553"},
                     {"label": "Vorona", "value": "C93554"},
                     {"label": "Vorona Teodoru", "value": "C93555"},
                     {"label": "Zlatunoaia", "value": "C93556"}],
 "Boucle du Mouhoun Region": [{"label": "Barani", "value": "C16350"},
                              {"label": "Boromo", "value": "C16351"},
                              {"label": "Dedougou", "value": "C16352"},
                              {"label": "Nouna", "value": "C16353"},
                              {"label": "Province de la Kossi",
                               "value": "C16354"},
                              {"label": "Province des Bale", "value": "C16355"},
                              {"label": "Province des Banwa",
                               "value": "C16356"},
                              {"label": "Province du Mouhoun",
                               "value": "C16357"},
                              {"label": "Province du Nayala",
                               "value": "C16358"},
                              {"label": "Province du Sourou",
                               "value": "C16359"},
                              {"label": "Salanso", "value": "C16360"},
                              {"label": "Toma", "value": "C16361"},
                              {"label": "Tougan", "value": "C16362"}],
 "Bouenza Department": [{"label": "Kayes", "value": "C20746"},
                        {"label": "Madingou", "value": "C20747"}],
 "Bougainville": [{"label": "Arawa", "value": "C80386"},
                  {"label": "Central Bougainville", "value": "C80387"},
                  {"label": "Kieta", "value": "C80388"},
                  {"label": "North Bougainville", "value": "C80389"},
                  {"label": "Panguna", "value": "C80390"},
                  {"label": "South Bougainville", "value": "C80391"}],
 "Bouira": [{"label": "Ain Bessem", "value": "C290"},
            {"label": "Bouira", "value": "C291"},
            {"label": "Chorfa", "value": "C292"},
            {"label": "Draa el Mizan", "value": "C293"},
            {"label": "Lakhdaria", "value": "C294"},
            {"label": "Sour el Ghozlane", "value": "C295"}],
 "Boumerdes": [{"label": "Arbatache", "value": "C296"},
               {"label": "Beni Amrane", "value": "C297"},
               {"label": "Boudouaou", "value": "C298"},
               {"label": "Boumerdas", "value": "C299"},
               {"label": "Chabet el Ameur", "value": "C300"},
               {"label": "Dellys", "value": "C301"},
               {"label": "Khemis el Khechna", "value": "C302"},
               {"label": "Makouda", "value": "C303"},
               {"label": "Naciria", "value": "C304"},
               {"label": "Ouled Moussa", "value": "C305"},
               {"label": "Reghaia", "value": "C306"},
               {"label": "Tadmait", "value": "C307"},
               {"label": "Thenia", "value": "C308"},
               {"label": "Tizi Gheniff", "value": "C309"}],
 "Bourgogne-Franche-Comte": [{"label": "Ahuy", "value": "C26458"},
                             {"label": "Aillant-sur-Tholon", "value": "C26459"},
                             {"label": "Aillevillers-et-Lyaumont",
                              "value": "C26460"},
                             {"label": "Aiserey", "value": "C26461"},
                             {"label": "Ancy-le-Franc", "value": "C26462"},
                             {"label": "Andelnans", "value": "C26463"},
                             {"label": "Appoigny", "value": "C26464"},
                             {"label": "Arbois", "value": "C26465"},
                             {"label": "Arbouans", "value": "C26466"},
                             {"label": "Arc-et-Senans", "value": "C26467"},
                             {"label": "Arc-les-Gray", "value": "C26468"},
                             {"label": "Arc-sur-Tille", "value": "C26469"},
                             {"label": "Arcey", "value": "C26470"},
                             {"label": "Arinthod", "value": "C26471"},
                             {"label": "Arnay-le-Duc", "value": "C26472"},
                             {"label": "Audincourt", "value": "C26473"},
                             {"label": "Augy", "value": "C26474"},
                             {"label": "Autun", "value": "C26475"},
                             {"label": "Auxerre", "value": "C26476"},
                             {"label": "Auxon-Dessous", "value": "C26477"},
                             {"label": "Auxonne", "value": "C26478"},
                             {"label": "Auxy", "value": "C26479"},
                             {"label": "Avallon", "value": "C26480"},
                             {"label": "Avanne-Aveney", "value": "C26481"},
                             {"label": "Bart", "value": "C26482"},
                             {"label": "Baume-les-Dames", "value": "C26483"},
                             {"label": "Bavans", "value": "C26484"},
                             {"label": "Bavilliers", "value": "C26485"},
                             {"label": "Beaucourt", "value": "C26486"},
                             {"label": "Beaune", "value": "C26487"},
                             {"label": "Belfort", "value": "C26488"},
                             {"label": "Belleneuve", "value": "C26489"},
                             {"label": "Besancon", "value": "C26490"},
                             {"label": "Bethoncourt", "value": "C26491"},
                             {"label": "Beure", "value": "C26492"},
                             {"label": "Blamont", "value": "C26493"},
                             {"label": "Blanzy", "value": "C26494"},
                             {"label": "Bleneau", "value": "C26495"},
                             {"label": "Bletterans", "value": "C26496"},
                             {"label": "Bligny-les-Beaune", "value": "C26497"},
                             {"label": "Bois-dAmont", "value": "C26498"},
                             {"label": "Bourbon-Lancy", "value": "C26499"},
                             {"label": "Bourogne", "value": "C26500"},
                             {"label": "Boussieres", "value": "C26501"},
                             {"label": "Branges", "value": "C26502"},
                             {"label": "Brazey-en-Plaine", "value": "C26503"},
                             {"label": "Brienon-sur-Armancon",
                              "value": "C26504"},
                             {"label": "Buxy", "value": "C26505"},
                             {"label": "Cercy-la-Tour", "value": "C26506"},
                             {"label": "Cezy", "value": "C26507"},
                             {"label": "Chablis", "value": "C26508"},
                             {"label": "Chagny", "value": "C26509"},
                             {"label": "Challuy", "value": "C26510"},
                             {"label": "Chalon-sur-Saone", "value": "C26511"},
                             {"label": "Chalonvillars", "value": "C26512"},
                             {"label": "Champagney", "value": "C26513"},
                             {"label": "Champagnole", "value": "C26514"},
                             {"label": "Champforgeuil", "value": "C26515"},
                             {"label": "Champignelles", "value": "C26516"},
                             {"label": "Champigny", "value": "C26517"},
                             {"label": "Champlitte", "value": "C26518"},
                             {"label": "Champlitte-la-Ville",
                              "value": "C26519"},
                             {"label": "Champs-sur-Yonne", "value": "C26520"},
                             {"label": "Champvans", "value": "C26521"},
                             {"label": "Chantenay-Saint-Imbert",
                              "value": "C26522"},
                             {"label": "Charbuy", "value": "C26523"},
                             {"label": "Charnay-les-Macon", "value": "C26524"},
                             {"label": "Charny", "value": "C26525"},
                             {"label": "Charolles", "value": "C26526"},
                             {"label": "Charquemont", "value": "C26527"},
                             {"label": "Chateau-Chinon(Ville)",
                              "value": "C26528"},
                             {"label": "Chatenois-les-Forges",
                              "value": "C26529"},
                             {"label": "Chatenoy-le-Royal", "value": "C26530"},
                             {"label": "Chatillon-en-Bazois",
                              "value": "C26531"},
                             {"label": "Chatillon-le-Duc", "value": "C26532"},
                             {"label": "Chatillon-sur-Seine",
                              "value": "C26533"},
                             {"label": "Chauffailles", "value": "C26534"},
                             {"label": "Chaulgnes", "value": "C26535"},
                             {"label": "Chaussin", "value": "C26536"},
                             {"label": "Chemaudin", "value": "C26537"},
                             {"label": "Chenove", "value": "C26538"},
                             {"label": "Cheny", "value": "C26539"},
                             {"label": "Cheroy", "value": "C26540"},
                             {"label": "Chevigny-Saint-Sauveur",
                              "value": "C26541"},
                             {"label": "Chevremont", "value": "C26542"},
                             {"label": "Choisey", "value": "C26543"},
                             {"label": "Ciry-le-Noble", "value": "C26544"},
                             {"label": "Clairvaux-les-Lacs", "value": "C26545"},
                             {"label": "Clamecy", "value": "C26546"},
                             {"label": "Clerval", "value": "C26547"},
                             {"label": "Cluny", "value": "C26548"},
                             {"label": "Colombier-Fontaine", "value": "C26549"},
                             {"label": "Corbenay", "value": "C26550"},
                             {"label": "Corbigny", "value": "C26551"},
                             {"label": "Corpeau", "value": "C26552"},
                             {"label": "Cosne-Cours-sur-Loire",
                              "value": "C26553"},
                             {"label": "Couches", "value": "C26554"},
                             {"label": "Couchey", "value": "C26555"},
                             {"label": "Coulanges-les-Nevers",
                              "value": "C26556"},
                             {"label": "Courcelles-les-Montbeliard",
                              "value": "C26557"},
                             {"label": "Courlon-sur-Yonne", "value": "C26558"},
                             {"label": "Cousance", "value": "C26559"},
                             {"label": "Couternon", "value": "C26560"},
                             {"label": "Cravanche", "value": "C26561"},
                             {"label": "Creches-sur-Saone", "value": "C26562"},
                             {"label": "Crissey", "value": "C26563"},
                             {"label": "Cuiseaux", "value": "C26564"},
                             {"label": "Cuisery", "value": "C26565"},
                             {"label": "Curgy", "value": "C26566"},
                             {"label": "Daix", "value": "C26567"},
                             {"label": "Damparis", "value": "C26568"},
                             {"label": "Dampierre", "value": "C26569"},
                             {"label": "Dampierre-les-Bois", "value": "C26570"},
                             {"label": "Dampierre-sur-Salon",
                              "value": "C26571"},
                             {"label": "Damprichard", "value": "C26572"},
                             {"label": "Danjoutin", "value": "C26573"},
                             {"label": "Dannemarie-sur-Crete",
                              "value": "C26574"},
                             {"label": "Dasle", "value": "C26575"},
                             {"label": "Decize", "value": "C26576"},
                             {"label": "Delle", "value": "C26577"},
                             {"label": "Demigny", "value": "C26578"},
                             {"label": "Departement de lYonne",
                              "value": "C26579"},
                             {"label": "Departement de la Cote-dOr",
                              "value": "C26580"},
                             {"label": "Departement de la Haute-Saone",
                              "value": "C26581"},
                             {"label": "Departement de la Nievre",
                              "value": "C26582"},
                             {"label": "Departement de Saone-et-Loire",
                              "value": "C26583"},
                             {"label": "Devecey", "value": "C26584"},
                             {"label": "Diges", "value": "C26585"},
                             {"label": "Digoin", "value": "C26586"},
                             {"label": "Dijon", "value": "C26587"},
                             {"label": "Dole", "value": "C26588"},
                             {"label": "Donzy", "value": "C26589"},
                             {"label": "Dornes", "value": "C26590"},
                             {"label": "Doubs", "value": "C26591"},
                             {"label": "Dracy-le-Fort", "value": "C26592"},
                             {"label": "echenoz-la-Meline", "value": "C26593"},
                             {"label": "ecole-Valentin", "value": "C26594"},
                             {"label": "ecuisses", "value": "C26595"},
                             {"label": "egriselles-le-Bocage",
                              "value": "C26596"},
                             {"label": "epervans", "value": "C26597"},
                             {"label": "epinac", "value": "C26598"},
                             {"label": "Essert", "value": "C26599"},
                             {"label": "etalans", "value": "C26600"},
                             {"label": "etang-sur-Arroux", "value": "C26601"},
                             {"label": "etupes", "value": "C26602"},
                             {"label": "Exincourt", "value": "C26603"},
                             {"label": "Faverney", "value": "C26604"},
                             {"label": "Fenay", "value": "C26605"},
                             {"label": "Fesches-le-Chatel", "value": "C26606"},
                             {"label": "Fleurey-sur-Ouche", "value": "C26607"},
                             {"label": "Fleury-la-Vallee", "value": "C26608"},
                             {"label": "Flogny-la-Chapelle", "value": "C26609"},
                             {"label": "Fontaine-les-Dijon", "value": "C26610"},
                             {"label": "Fontaine-les-Luxeuil",
                              "value": "C26611"},
                             {"label": "Fontaines", "value": "C26612"},
                             {"label": "Foucherans", "value": "C26613"},
                             {"label": "Fougerolles", "value": "C26614"},
                             {"label": "Fourchambault", "value": "C26615"},
                             {"label": "Frahier-et-Chatebier",
                              "value": "C26616"},
                             {"label": "Fraisans", "value": "C26617"},
                             {"label": "Franois", "value": "C26618"},
                             {"label": "Frasne", "value": "C26619"},
                             {"label": "Froideconche", "value": "C26620"},
                             {"label": "Frotey-les-Vesoul", "value": "C26621"},
                             {"label": "Garchizy", "value": "C26622"},
                             {"label": "Genelard", "value": "C26623"},
                             {"label": "Genlis", "value": "C26624"},
                             {"label": "Gergy", "value": "C26625"},
                             {"label": "Gevrey-Chambertin", "value": "C26626"},
                             {"label": "Gilley", "value": "C26627"},
                             {"label": "Giromagny", "value": "C26628"},
                             {"label": "Givry", "value": "C26629"},
                             {"label": "Grand-Charmont", "value": "C26630"},
                             {"label": "Grandfontaine", "value": "C26631"},
                             {"label": "Grandvillars", "value": "C26632"},
                             {"label": "Gray", "value": "C26633"},
                             {"label": "Gray-la-Ville", "value": "C26634"},
                             {"label": "Gron", "value": "C26635"},
                             {"label": "Guerigny", "value": "C26636"},
                             {"label": "Gueugnon", "value": "C26637"},
                             {"label": "Gurgy", "value": "C26638"},
                             {"label": "Gy", "value": "C26639"},
                             {"label": "Hauteville-les-Dijon",
                              "value": "C26640"},
                             {"label": "Hericourt", "value": "C26641"},
                             {"label": "Herimoncourt", "value": "C26642"},
                             {"label": "Hery", "value": "C26643"},
                             {"label": "Hurigny", "value": "C26644"},
                             {"label": "Imphy", "value": "C26645"},
                             {"label": "Is-sur-Tille", "value": "C26646"},
                             {"label": "Joigny", "value": "C26647"},
                             {"label": "Joncherey", "value": "C26648"},
                             {"label": "Jougne", "value": "C26649"},
                             {"label": "Joux-la-Ville", "value": "C26650"},
                             {"label": "Jura", "value": "C26651"},
                             {"label": "Jussey", "value": "C26652"},
                             {"label": "La Chapelle-de-Guinchay",
                              "value": "C26653"},
                             {"label": "La Charite-sur-Loire",
                              "value": "C26654"},
                             {"label": "La Clayette", "value": "C26655"},
                             {"label": "La Cluse-et-Mijoux", "value": "C26656"},
                             {"label": "La Machine", "value": "C26657"},
                             {"label": "La Motte-Saint-Jean",
                              "value": "C26658"},
                             {"label": "La Roche-Vineuse", "value": "C26659"},
                             {"label": "Ladoix-Serrigny", "value": "C26660"},
                             {"label": "Lamarche-sur-Saone", "value": "C26661"},
                             {"label": "Laroche-Saint-Cydroine",
                              "value": "C26662"},
                             {"label": "Lavans-les-Saint-Claude",
                              "value": "C26663"},
                             {"label": "Le Breuil", "value": "C26664"},
                             {"label": "Le Creusot", "value": "C26665"},
                             {"label": "Le Russey", "value": "C26666"},
                             {"label": "Lepuix", "value": "C26667"},
                             {"label": "Les Fins", "value": "C26668"},
                             {"label": "Les Fourgs", "value": "C26669"},
                             {"label": "Les Rousses", "value": "C26670"},
                             {"label": "Levier", "value": "C26671"},
                             {"label": "Ligny-le-Chatel", "value": "C26672"},
                             {"label": "Longchamp", "value": "C26673"},
                             {"label": "Longchaumois", "value": "C26674"},
                             {"label": "Longecourt-en-Plaine",
                              "value": "C26675"},
                             {"label": "Longvic", "value": "C26676"},
                             {"label": "Lons-le-Saunier", "value": "C26677"},
                             {"label": "Lormes", "value": "C26678"},
                             {"label": "Losne", "value": "C26679"},
                             {"label": "Louhans", "value": "C26680"},
                             {"label": "Lucenay-les-Aix", "value": "C26681"},
                             {"label": "Lure", "value": "C26682"},
                             {"label": "Luxeuil-les-Bains", "value": "C26683"},
                             {"label": "Luzy", "value": "C26684"},
                             {"label": "Macon", "value": "C26685"},
                             {"label": "Magny-Cours", "value": "C26686"},
                             {"label": "Magny-Vernois", "value": "C26687"},
                             {"label": "Maiche", "value": "C26688"},
                             {"label": "Maillot", "value": "C26689"},
                             {"label": "Malay-le-Grand", "value": "C26690"},
                             {"label": "Mamirolle", "value": "C26691"},
                             {"label": "Mandeure", "value": "C26692"},
                             {"label": "Marcigny", "value": "C26693"},
                             {"label": "Marcilly-sur-Tille", "value": "C26694"},
                             {"label": "Marmagne", "value": "C26695"},
                             {"label": "Marnay", "value": "C26696"},
                             {"label": "Marsannay-la-Cote", "value": "C26697"},
                             {"label": "Marzy", "value": "C26698"},
                             {"label": "Mathay", "value": "C26699"},
                             {"label": "Matour", "value": "C26700"},
                             {"label": "Melisey", "value": "C26701"},
                             {"label": "Mellecey", "value": "C26702"},
                             {"label": "Mercurey", "value": "C26703"},
                             {"label": "Mervans", "value": "C26704"},
                             {"label": "Messigny-et-Vantoux",
                              "value": "C26705"},
                             {"label": "Meursault", "value": "C26706"},
                             {"label": "Mezire", "value": "C26707"},
                             {"label": "Migennes", "value": "C26708"},
                             {"label": "Mirebeau-sur-Beze", "value": "C26709"},
                             {"label": "Miserey-Salines", "value": "C26710"},
                             {"label": "Moirans-en-Montagne",
                              "value": "C26711"},
                             {"label": "Moneteau", "value": "C26712"},
                             {"label": "Mont-sous-Vaudrey", "value": "C26713"},
                             {"label": "Montbard", "value": "C26714"},
                             {"label": "Montbeliard", "value": "C26715"},
                             {"label": "Montceau-les-Mines", "value": "C26716"},
                             {"label": "Montcenis", "value": "C26717"},
                             {"label": "Montchanin", "value": "C26718"},
                             {"label": "Montenois", "value": "C26719"},
                             {"label": "Montfaucon", "value": "C26720"},
                             {"label": "Montferrand-le-Chateau",
                              "value": "C26721"},
                             {"label": "Montlebon", "value": "C26722"},
                             {"label": "Montmorot", "value": "C26723"},
                             {"label": "Montpont-en-Bresse", "value": "C26724"},
                             {"label": "Montreux-Chateau", "value": "C26725"},
                             {"label": "Morbier", "value": "C26726"},
                             {"label": "Morez", "value": "C26727"},
                             {"label": "Morre", "value": "C26728"},
                             {"label": "Morteau", "value": "C26729"},
                             {"label": "Morvillars", "value": "C26730"},
                             {"label": "Mouchard", "value": "C26731"},
                             {"label": "Moulins-Engilbert", "value": "C26732"},
                             {"label": "Nancray", "value": "C26733"},
                             {"label": "Navenne", "value": "C26734"},
                             {"label": "Neuilly-les-Dijon", "value": "C26735"},
                             {"label": "Neuvy-Sautour", "value": "C26736"},
                             {"label": "Neuvy-sur-Loire", "value": "C26737"},
                             {"label": "Nevers", "value": "C26738"},
                             {"label": "Noidans-les-Vesoul", "value": "C26739"},
                             {"label": "Nolay", "value": "C26740"},
                             {"label": "Nommay", "value": "C26741"},
                             {"label": "Novillars", "value": "C26742"},
                             {"label": "Nuits-Saint-Georges",
                              "value": "C26743"},
                             {"label": "Offemont", "value": "C26744"},
                             {"label": "Orchamps", "value": "C26745"},
                             {"label": "Orchamps-Vennes", "value": "C26746"},
                             {"label": "Orgelet", "value": "C26747"},
                             {"label": "Ornans", "value": "C26748"},
                             {"label": "Ouges", "value": "C26749"},
                             {"label": "Ouroux-sur-Saone", "value": "C26750"},
                             {"label": "Palinges", "value": "C26751"},
                             {"label": "Paray-le-Monial", "value": "C26752"},
                             {"label": "Paron", "value": "C26753"},
                             {"label": "Pelousey", "value": "C26754"},
                             {"label": "Perrecy-les-Forges", "value": "C26755"},
                             {"label": "Perrigny", "value": "C26756"},
                             {"label": "Perrigny-les-Dijon", "value": "C26757"},
                             {"label": "Pesmes", "value": "C26758"},
                             {"label": "Petit-Noir", "value": "C26759"},
                             {"label": "Pierre-de-Bresse", "value": "C26760"},
                             {"label": "Pierrefontaine-les-Varans",
                              "value": "C26761"},
                             {"label": "Pirey", "value": "C26762"},
                             {"label": "Plancher-Bas", "value": "C26763"},
                             {"label": "Plancher-les-Mines", "value": "C26764"},
                             {"label": "Plombieres-les-Dijon",
                              "value": "C26765"},
                             {"label": "Poligny", "value": "C26766"},
                             {"label": "Pont-de-Roide", "value": "C26767"},
                             {"label": "Pont-sur-Yonne", "value": "C26768"},
                             {"label": "Pontailler-sur-Saone",
                              "value": "C26769"},
                             {"label": "Pontarlier", "value": "C26770"},
                             {"label": "Port-sur-Saone", "value": "C26771"},
                             {"label": "Pougues-les-Eaux", "value": "C26772"},
                             {"label": "Pouilley-les-Vignes",
                              "value": "C26773"},
                             {"label": "Pouilly-en-Auxois", "value": "C26774"},
                             {"label": "Pouilly-sur-Loire", "value": "C26775"},
                             {"label": "Pourrain", "value": "C26776"},
                             {"label": "Premery", "value": "C26777"},
                             {"label": "Prisse", "value": "C26778"},
                             {"label": "Pusey", "value": "C26779"},
                             {"label": "Quetigny", "value": "C26780"},
                             {"label": "Quincey", "value": "C26781"},
                             {"label": "Quingey", "value": "C26782"},
                             {"label": "Ravieres", "value": "C26783"},
                             {"label": "Rioz", "value": "C26784"},
                             {"label": "Roche-lez-Beaupre", "value": "C26785"},
                             {"label": "Romaneche-Thorins", "value": "C26786"},
                             {"label": "Romenay", "value": "C26787"},
                             {"label": "Ronchamp", "value": "C26788"},
                             {"label": "Rougemont", "value": "C26789"},
                             {"label": "Rougemont-le-Chateau",
                              "value": "C26790"},
                             {"label": "Roulans", "value": "C26791"},
                             {"label": "Roye", "value": "C26792"},
                             {"label": "Ruffey-les-Echirey", "value": "C26793"},
                             {"label": "Rully", "value": "C26794"},
                             {"label": "Sagy", "value": "C26795"},
                             {"label": "Saint-Amand-en-Puisaye",
                              "value": "C26796"},
                             {"label": "Saint-Amour", "value": "C26797"},
                             {"label": "Saint-Apollinaire", "value": "C26798"},
                             {"label": "Saint-Aubin", "value": "C26799"},
                             {"label": "Saint-Benin-dAzy", "value": "C26800"},
                             {"label": "Saint-Berain-sous-Sanvignes",
                              "value": "C26801"},
                             {"label": "Saint-Bris-le-Vineux",
                              "value": "C26802"},
                             {"label": "Saint-Claude", "value": "C26803"},
                             {"label": "Saint-Clement", "value": "C26804"},
                             {"label": "Saint-eloi", "value": "C26805"},
                             {"label": "Saint-Eusebe", "value": "C26806"},
                             {"label": "Saint-Fargeau", "value": "C26807"},
                             {"label": "Saint-Florentin", "value": "C26808"},
                             {"label": "Saint-Gengoux-le-National",
                              "value": "C26809"},
                             {"label": "Saint-Georges-sur-Baulche",
                              "value": "C26810"},
                             {"label": "Saint-Germain", "value": "C26811"},
                             {"label": "Saint-Germain-du-Bois",
                              "value": "C26812"},
                             {"label": "Saint-Germain-du-Plain",
                              "value": "C26813"},
                             {"label": "Saint-Hippolyte", "value": "C26814"},
                             {"label": "Saint-Jean-de-Losne",
                              "value": "C26815"},
                             {"label": "Saint-Julien", "value": "C26816"},
                             {"label": "Saint-Julien-du-Sault",
                              "value": "C26817"},
                             {"label": "Saint-Laurent-en-Grandvaux",
                              "value": "C26818"},
                             {"label": "Saint-Leger-des-Vignes",
                              "value": "C26819"},
                             {"label": "Saint-Leger-sur-Dheune",
                              "value": "C26820"},
                             {"label": "Saint-Leu", "value": "C26821"},
                             {"label": "Saint-Loup-sur-Semouse",
                              "value": "C26822"},
                             {"label": "Saint-Lupicin", "value": "C26823"},
                             {"label": "Saint-Marcel", "value": "C26824"},
                             {"label": "Saint-Martin-Belle-Roche",
                              "value": "C26825"},
                             {"label": "Saint-Martin-du-Tertre",
                              "value": "C26826"},
                             {"label": "Saint-Martin-en-Bresse",
                              "value": "C26827"},
                             {"label": "Saint-Parize-le-Chatel",
                              "value": "C26828"},
                             {"label": "Saint-Pierre-le-Moutier",
                              "value": "C26829"},
                             {"label": "Saint-Remy", "value": "C26830"},
                             {"label": "Saint-Sauveur", "value": "C26831"},
                             {"label": "Saint-Sernin-du-Bois",
                              "value": "C26832"},
                             {"label": "Saint-Usage", "value": "C26833"},
                             {"label": "Saint-Usuge", "value": "C26834"},
                             {"label": "Saint-Valerien", "value": "C26835"},
                             {"label": "Saint-Vallier", "value": "C26836"},
                             {"label": "Saint-Vit", "value": "C26837"},
                             {"label": "Saint-Yan", "value": "C26838"},
                             {"label": "Sainte-Suzanne", "value": "C26839"},
                             {"label": "Salbert", "value": "C26840"},
                             {"label": "Salins-les-Bains", "value": "C26841"},
                             {"label": "Sance", "value": "C26842"},
                             {"label": "Sancey-le-Grand", "value": "C26843"},
                             {"label": "Sanvignes-les-Mines",
                              "value": "C26844"},
                             {"label": "Saone", "value": "C26845"},
                             {"label": "Sassenay", "value": "C26846"},
                             {"label": "Saulieu", "value": "C26847"},
                             {"label": "Sauvigny-les-Bois", "value": "C26848"},
                             {"label": "Savigny-les-Beaune", "value": "C26849"},
                             {"label": "Scey-sur-Saone-et-Saint-Albin",
                              "value": "C26850"},
                             {"label": "Seignelay", "value": "C26851"},
                             {"label": "Seloncourt", "value": "C26852"},
                             {"label": "Selongey", "value": "C26853"},
                             {"label": "Semur-en-Auxois", "value": "C26854"},
                             {"label": "Sennecey-le-Grand", "value": "C26855"},
                             {"label": "Sennecey-les-Dijon", "value": "C26856"},
                             {"label": "Sens", "value": "C26857"},
                             {"label": "Sergines", "value": "C26858"},
                             {"label": "Sermoise-sur-Loire", "value": "C26859"},
                             {"label": "Serre-les-Sapins", "value": "C26860"},
                             {"label": "Seurre", "value": "C26861"},
                             {"label": "Sevrey", "value": "C26862"},
                             {"label": "Simandre", "value": "C26863"},
                             {"label": "Sochaux", "value": "C26864"},
                             {"label": "Sornay", "value": "C26865"},
                             {"label": "Soucy", "value": "C26866"},
                             {"label": "Taillecourt", "value": "C26867"},
                             {"label": "Talant", "value": "C26868"},
                             {"label": "Tanlay", "value": "C26869"},
                             {"label": "Tavaux", "value": "C26870"},
                             {"label": "Territoire de Belfort",
                              "value": "C26871"},
                             {"label": "Thise", "value": "C26872"},
                             {"label": "Thorigny-sur-Oreuse",
                              "value": "C26873"},
                             {"label": "Tonnerre", "value": "C26874"},
                             {"label": "Torcy", "value": "C26875"},
                             {"label": "Toucy", "value": "C26876"},
                             {"label": "Toulon-sur-Arroux", "value": "C26877"},
                             {"label": "Tournus", "value": "C26878"},
                             {"label": "Urzy", "value": "C26879"},
                             {"label": "Vaivre-et-Montoille",
                              "value": "C26880"},
                             {"label": "Valdahon", "value": "C26881"},
                             {"label": "Valdoie", "value": "C26882"},
                             {"label": "Valentigney", "value": "C26883"},
                             {"label": "Valfin-les-Saint-Claude",
                              "value": "C26884"},
                             {"label": "Varennes-le-Grand", "value": "C26885"},
                             {"label": "Varennes-Saint-Sauveur",
                              "value": "C26886"},
                             {"label": "Varennes-Vauzelles", "value": "C26887"},
                             {"label": "Varois-et-Chaignot", "value": "C26888"},
                             {"label": "Varzy", "value": "C26889"},
                             {"label": "Velars-sur-Ouche", "value": "C26890"},
                             {"label": "Venarey-les-Laumes", "value": "C26891"},
                             {"label": "Venoy", "value": "C26892"},
                             {"label": "Vercel-Villedieu-le-Camp",
                              "value": "C26893"},
                             {"label": "Verdun-sur-le-Doubs",
                              "value": "C26894"},
                             {"label": "Vergigny", "value": "C26895"},
                             {"label": "Vermenton", "value": "C26896"},
                             {"label": "Veron", "value": "C26897"},
                             {"label": "Vesoul", "value": "C26898"},
                             {"label": "Vieux-Charmont", "value": "C26899"},
                             {"label": "Villeblevin", "value": "C26900"},
                             {"label": "Villeneuve-la-Guyard",
                              "value": "C26901"},
                             {"label": "Villeneuve-sur-Yonne",
                              "value": "C26902"},
                             {"label": "Villers-le-Lac", "value": "C26903"},
                             {"label": "Villersexel", "value": "C26904"},
                             {"label": "Vinneuf", "value": "C26905"},
                             {"label": "Virey-le-Grand", "value": "C26906"},
                             {"label": "Vitteaux", "value": "C26907"},
                             {"label": "Voujeaucourt", "value": "C26908"}],
 "Bovec Municipality": [{"label": "Bovec", "value": "C107581"},
                        {"label": "Mirna", "value": "C107582"}],
 "Boyaca": [{"label": "Almeida", "value": "C19750"},
            {"label": "Aquitania", "value": "C19751"},
            {"label": "Arcabuco", "value": "C19752"},
            {"label": "Belen", "value": "C19753"},
            {"label": "Berbeo", "value": "C19754"},
            {"label": "Beteitiva", "value": "C19755"},
            {"label": "Boavita", "value": "C19756"},
            {"label": "Boyaca", "value": "C19757"},
            {"label": "Briceno", "value": "C19758"},
            {"label": "Buenavista", "value": "C19759"},
            {"label": "Busbanza", "value": "C19760"},
            {"label": "Caldas", "value": "C19761"},
            {"label": "Campohermoso", "value": "C19762"},
            {"label": "Cerinza", "value": "C19763"},
            {"label": "Chinavita", "value": "C19764"},
            {"label": "Chiquinquira", "value": "C19765"},
            {"label": "Chiquiza", "value": "C19766"},
            {"label": "Chiscas", "value": "C19767"},
            {"label": "Chita", "value": "C19768"},
            {"label": "Chitaraque", "value": "C19769"},
            {"label": "Chivata", "value": "C19770"},
            {"label": "Chivor", "value": "C19771"},
            {"label": "Cienega", "value": "C19772"},
            {"label": "Combita", "value": "C19773"},
            {"label": "Coper", "value": "C19774"},
            {"label": "Corrales", "value": "C19775"},
            {"label": "Covarachia", "value": "C19776"},
            {"label": "Cubara", "value": "C19777"},
            {"label": "Cucaita", "value": "C19778"},
            {"label": "Cuitiva", "value": "C19779"},
            {"label": "Duitama", "value": "C19780"},
            {"label": "El Cocuy", "value": "C19781"},
            {"label": "El Espino", "value": "C19782"},
            {"label": "Firavitoba", "value": "C19783"},
            {"label": "Floresta", "value": "C19784"},
            {"label": "Gachantiva", "value": "C19785"},
            {"label": "Gameza", "value": "C19786"},
            {"label": "Garagoa", "value": "C19787"},
            {"label": "Guacamayas", "value": "C19788"},
            {"label": "Guateque", "value": "C19789"},
            {"label": "Guayata", "value": "C19790"},
            {"label": "Guican", "value": "C19791"},
            {"label": "Iza", "value": "C19792"},
            {"label": "Jenesano", "value": "C19793"},
            {"label": "Jerico", "value": "C19794"},
            {"label": "La Capilla", "value": "C19795"},
            {"label": "La Uvita", "value": "C19796"},
            {"label": "La Victoria", "value": "C19797"},
            {"label": "Labranzagrande", "value": "C19798"},
            {"label": "Macanal", "value": "C19799"},
            {"label": "Maripi", "value": "C19800"},
            {"label": "Miraflores", "value": "C19801"},
            {"label": "Mongua", "value": "C19802"},
            {"label": "Mongui", "value": "C19803"},
            {"label": "Moniquira", "value": "C19804"},
            {"label": "Motavita", "value": "C19805"},
            {"label": "Muzo", "value": "C19806"},
            {"label": "Nobsa", "value": "C19807"},
            {"label": "Nuevo Colon", "value": "C19808"},
            {"label": "Oicata", "value": "C19809"},
            {"label": "Otanche", "value": "C19810"},
            {"label": "Pachavita", "value": "C19811"},
            {"label": "Paez", "value": "C19812"},
            {"label": "Paipa", "value": "C19813"},
            {"label": "Pajarito", "value": "C19814"},
            {"label": "Panqueba", "value": "C19815"},
            {"label": "Pauna", "value": "C19816"},
            {"label": "Paya", "value": "C19817"},
            {"label": "Paz de Rio", "value": "C19818"},
            {"label": "Pesca", "value": "C19819"},
            {"label": "Pisba", "value": "C19820"},
            {"label": "Puerto Boyaca", "value": "C19821"},
            {"label": "Quipama", "value": "C19822"},
            {"label": "Ramiriqui", "value": "C19823"},
            {"label": "Raquira", "value": "C19824"},
            {"label": "Rondon", "value": "C19825"},
            {"label": "Saboya", "value": "C19826"},
            {"label": "Sachica", "value": "C19827"},
            {"label": "Samaca", "value": "C19828"},
            {"label": "San Eduardo", "value": "C19829"},
            {"label": "San Jose de Pare", "value": "C19830"},
            {"label": "San Luis de Gaceno", "value": "C19831"},
            {"label": "San Mateo", "value": "C19832"},
            {"label": "San Miguel de Sema", "value": "C19833"},
            {"label": "San Pablo de Borbur", "value": "C19834"},
            {"label": "Santa Maria", "value": "C19835"},
            {"label": "Santa Rosa de Viterbo", "value": "C19836"},
            {"label": "Santa Sofia", "value": "C19837"},
            {"label": "Santana", "value": "C19838"},
            {"label": "Sativanorte", "value": "C19839"},
            {"label": "Sativasur", "value": "C19840"},
            {"label": "Siachoque", "value": "C19841"},
            {"label": "Soata", "value": "C19842"},
            {"label": "Socha", "value": "C19843"},
            {"label": "Socota", "value": "C19844"},
            {"label": "Sogamoso", "value": "C19845"},
            {"label": "Somondoco", "value": "C19846"},
            {"label": "Sora", "value": "C19847"},
            {"label": "Soraca", "value": "C19848"},
            {"label": "Sotaquira", "value": "C19849"},
            {"label": "Susacon", "value": "C19850"},
            {"label": "Sutamarchan", "value": "C19851"},
            {"label": "Sutatenza", "value": "C19852"},
            {"label": "Tasco", "value": "C19853"},
            {"label": "Tenza", "value": "C19854"},
            {"label": "Tibana", "value": "C19855"},
            {"label": "Tibasosa", "value": "C19856"},
            {"label": "Tinjaca", "value": "C19857"},
            {"label": "Tipacoque", "value": "C19858"},
            {"label": "Toca", "value": "C19859"},
            {"label": "Togui", "value": "C19860"},
            {"label": "Topaga", "value": "C19861"},
            {"label": "Tota", "value": "C19862"},
            {"label": "Tunja", "value": "C19863"},
            {"label": "Tunungua", "value": "C19864"},
            {"label": "Turmeque", "value": "C19865"},
            {"label": "Tuta", "value": "C19866"},
            {"label": "Tutaza", "value": "C19867"},
            {"label": "Umbita", "value": "C19868"},
            {"label": "Ventaquemada", "value": "C19869"},
            {"label": "Villa de Leyva", "value": "C19870"},
            {"label": "Viracacha", "value": "C19871"},
            {"label": "Zetaquira", "value": "C19872"}],
 "Braga": [{"label": "Adaufe", "value": "C90896"},
           {"label": "Amares", "value": "C90897"},
           {"label": "Apulia", "value": "C90898"},
           {"label": "Apulia e Fao", "value": "C90899"},
           {"label": "Arcos", "value": "C90900"},
           {"label": "Aroes", "value": "C90901"},
           {"label": "Aveleda", "value": "C90902"},
           {"label": "Barcelos", "value": "C90903"},
           {"label": "Barqueiros", "value": "C90904"},
           {"label": "Braga", "value": "C90905"},
           {"label": "Brito", "value": "C90906"},
           {"label": "Cabeceiras de Basto", "value": "C90907"},
           {"label": "Caldas das Taipas", "value": "C90908"},
           {"label": "Caldas de Vizela", "value": "C90909"},
           {"label": "Caldelas", "value": "C90910"},
           {"label": "Calendario", "value": "C90911"},
           {"label": "Candoso", "value": "C90912"},
           {"label": "Celorico de Basto", "value": "C90913"},
           {"label": "Chavao", "value": "C90914"},
           {"label": "Cristelo", "value": "C90915"},
           {"label": "Esporoes", "value": "C90916"},
           {"label": "Esposende", "value": "C90917"},
           {"label": "Fafe", "value": "C90918"},
           {"label": "Faria", "value": "C90919"},
           {"label": "Ferreiros", "value": "C90920"},
           {"label": "Fradelos", "value": "C90921"},
           {"label": "Frossos", "value": "C90922"},
           {"label": "Galegos", "value": "C90923"},
           {"label": "Gandra", "value": "C90924"},
           {"label": "Gondizalves", "value": "C90925"},
           {"label": "Gueral", "value": "C90926"},
           {"label": "Guimaraes", "value": "C90927"},
           {"label": "Guisande", "value": "C90928"},
           {"label": "Joane", "value": "C90929"},
           {"label": "Lago", "value": "C90930"},
           {"label": "Lordelo", "value": "C90931"},
           {"label": "Macieira de Rates", "value": "C90932"},
           {"label": "Marinhas", "value": "C90933"},
           {"label": "Mariz", "value": "C90934"},
           {"label": "Merelim", "value": "C90935"},
           {"label": "Moreira de Conegos", "value": "C90936"},
           {"label": "Negreiros", "value": "C90937"},
           {"label": "Oliveira", "value": "C90938"},
           {"label": "Perelhal", "value": "C90939"},
           {"label": "Ponte", "value": "C90940"},
           {"label": "Povoa de Lanhoso", "value": "C90941"},
           {"label": "Prado", "value": "C90942"},
           {"label": "Real", "value": "C90943"},
           {"label": "Refojos de Basto", "value": "C90944"},
           {"label": "Rendufe", "value": "C90945"},
           {"label": "Riba de Ave", "value": "C90946"},
           {"label": "Ribeirao", "value": "C90947"},
           {"label": "Ronfe", "value": "C90948"},
           {"label": "Sande", "value": "C90949"},
           {"label": "Selho", "value": "C90950"},
           {"label": "Semelhe", "value": "C90951"},
           {"label": "Serzedelo", "value": "C90952"},
           {"label": "Serzedo", "value": "C90953"},
           {"label": "Tebosa", "value": "C90954"},
           {"label": "Terras de Bouro", "value": "C90955"},
           {"label": "Urgeses", "value": "C90956"},
           {"label": "Varzea", "value": "C90957"},
           {"label": "Vieira do Minho", "value": "C90958"},
           {"label": "Vila Cova", "value": "C90959"},
           {"label": "Vila Frescainha", "value": "C90960"},
           {"label": "Vila Nova de Famalicao", "value": "C90961"},
           {"label": "Vila Seca", "value": "C90962"},
           {"label": "Vila Verde", "value": "C90963"},
           {"label": "Vilaca", "value": "C90964"},
           {"label": "Vilar de Figos", "value": "C90965"},
           {"label": "Vizela", "value": "C90966"}],
 "Braganca": [{"label": "Alfandega da Fe", "value": "C90967"},
              {"label": "Belver", "value": "C90968"},
              {"label": "Braganca Municipality", "value": "C90969"},
              {"label": "Carrazeda de Anciaes", "value": "C90970"},
              {"label": "Carrazeda de Ansiaes", "value": "C90971"},
              {"label": "Freixo de Espada a Cinta", "value": "C90972"},
              {"label": "Macedo de Cavaleiros", "value": "C90973"},
              {"label": "Miranda do Douro", "value": "C90974"},
              {"label": "Mirandela", "value": "C90975"},
              {"label": "Mogadouro", "value": "C90976"},
              {"label": "Samil", "value": "C90977"},
              {"label": "Sendim", "value": "C90978"},
              {"label": "Torre de Moncorvo", "value": "C90979"},
              {"label": "Vila Flor", "value": "C90980"},
              {"label": "Vimioso", "value": "C90981"},
              {"label": "Vinhais", "value": "C90982"}],
 "Braila": [{"label": "Baldovinesti", "value": "C93557"},
            {"label": "Baraganul", "value": "C93558"},
            {"label": "Bertestii de Jos", "value": "C93559"},
            {"label": "Bordei Verde", "value": "C93560"},
            {"label": "Braila", "value": "C93561"},
            {"label": "Cazasu", "value": "C93562"},
            {"label": "Chiscani", "value": "C93563"},
            {"label": "Ciocile", "value": "C93564"},
            {"label": "Ciresu", "value": "C93565"},
            {"label": "Comuna Baraganul", "value": "C93566"},
            {"label": "Comuna Bertestii de Jos", "value": "C93567"},
            {"label": "Comuna Bordei Verde", "value": "C93568"},
            {"label": "Comuna Cazasu", "value": "C93569"},
            {"label": "Comuna Chiscani", "value": "C93570"},
            {"label": "Comuna Ciocile", "value": "C93571"},
            {"label": "Comuna Ciresu", "value": "C93572"},
            {"label": "Comuna Dudesti", "value": "C93573"},
            {"label": "Comuna Frecatei", "value": "C93574"},
            {"label": "Comuna Galbenu", "value": "C93575"},
            {"label": "Comuna Gemenele", "value": "C93576"},
            {"label": "Comuna Gradistea", "value": "C93577"},
            {"label": "Comuna Gropeni", "value": "C93578"},
            {"label": "Comuna Jirlau", "value": "C93579"},
            {"label": "Comuna Marasu", "value": "C93580"},
            {"label": "Comuna Maxineni", "value": "C93581"},
            {"label": "Comuna Mircea Voda", "value": "C93582"},
            {"label": "Comuna Movila Miresii", "value": "C93583"},
            {"label": "Comuna Racovita", "value": "C93584"},
            {"label": "Comuna Ramnicelu", "value": "C93585"},
            {"label": "Comuna Romanu", "value": "C93586"},
            {"label": "Comuna Rosiori", "value": "C93587"},
            {"label": "Comuna Salcia Tudor", "value": "C93588"},
            {"label": "Comuna Scortaru Nou", "value": "C93589"},
            {"label": "Comuna Silistea", "value": "C93590"},
            {"label": "Comuna Stancuta", "value": "C93591"},
            {"label": "Comuna Surdila-Gaiseanca", "value": "C93592"},
            {"label": "Comuna Surdila-Greci", "value": "C93593"},
            {"label": "Comuna Sutesti", "value": "C93594"},
            {"label": "Comuna Tichilesti", "value": "C93595"},
            {"label": "Comuna Traian", "value": "C93596"},
            {"label": "Comuna Tudor Vladimirescu", "value": "C93597"},
            {"label": "Comuna Tufesti", "value": "C93598"},
            {"label": "Comuna Ulmu", "value": "C93599"},
            {"label": "Comuna Unirea", "value": "C93600"},
            {"label": "Comuna Vadeni", "value": "C93601"},
            {"label": "Comuna Victoria", "value": "C93602"},
            {"label": "Comuna Visani", "value": "C93603"},
            {"label": "Comuna Viziru", "value": "C93604"},
            {"label": "Comuna Zavoaia", "value": "C93605"},
            {"label": "Corbu Nou", "value": "C93606"},
            {"label": "Cuza Voda", "value": "C93607"},
            {"label": "Dedulesti", "value": "C93608"},
            {"label": "Dudesti", "value": "C93609"},
            {"label": "Faurei", "value": "C93610"},
            {"label": "Frecatei", "value": "C93611"},
            {"label": "Galbenu", "value": "C93612"},
            {"label": "Gemenele", "value": "C93613"},
            {"label": "Gradistea", "value": "C93614"},
            {"label": "Gropeni", "value": "C93615"},
            {"label": "Ianca", "value": "C93616"},
            {"label": "Insuratei", "value": "C93617"},
            {"label": "Jirlau", "value": "C93618"},
            {"label": "Jugureanu", "value": "C93619"},
            {"label": "Lacu Sarat", "value": "C93620"},
            {"label": "Lanurile", "value": "C93621"},
            {"label": "Marasu", "value": "C93622"},
            {"label": "Maxineni", "value": "C93623"},
            {"label": "Mihai Bravu", "value": "C93624"},
            {"label": "Mircea Voda", "value": "C93625"},
            {"label": "Movila Miresii", "value": "C93626"},
            {"label": "Municipiul Braila", "value": "C93627"},
            {"label": "Oras Faurei", "value": "C93628"},
            {"label": "Oras Ianca", "value": "C93629"},
            {"label": "Oras Insuratei", "value": "C93630"},
            {"label": "Perisoru", "value": "C93631"},
            {"label": "Plopu", "value": "C93632"},
            {"label": "Racovita", "value": "C93633"},
            {"label": "Ramnicelu", "value": "C93634"},
            {"label": "Romanu", "value": "C93635"},
            {"label": "Rosiori", "value": "C93636"},
            {"label": "Salcia Tudor", "value": "C93637"},
            {"label": "Scortaru Nou", "value": "C93638"},
            {"label": "Scortaru Vechi", "value": "C93639"},
            {"label": "Silistea", "value": "C93640"},
            {"label": "Spiru Haret", "value": "C93641"},
            {"label": "Stancuta", "value": "C93642"},
            {"label": "Surdila-Gaiseanca", "value": "C93643"},
            {"label": "Surdila-Greci", "value": "C93644"},
            {"label": "Sutesti", "value": "C93645"},
            {"label": "Tataru", "value": "C93646"},
            {"label": "Tichilesti", "value": "C93647"},
            {"label": "Traian", "value": "C93648"},
            {"label": "Tudor Vladimirescu", "value": "C93649"},
            {"label": "Tufesti", "value": "C93650"},
            {"label": "Ulmu", "value": "C93651"},
            {"label": "Unirea", "value": "C93652"},
            {"label": "Urleasca", "value": "C93653"},
            {"label": "Vadeni", "value": "C93654"},
            {"label": "Valea Canepii", "value": "C93655"},
            {"label": "Victoria", "value": "C93656"},
            {"label": "Visani", "value": "C93657"},
            {"label": "Viziru", "value": "C93658"},
            {"label": "Zavoaia", "value": "C93659"}],
 "Brakna": [{"label": "Elb el Jmel", "value": "C65943"},
            {"label": "Aleg", "value": "C65944"},
            {"label": "Bofal", "value": "C65945"},
            {"label": "Sori Male", "value": "C65946"}],
 "Brandenburg": [{"label": "Alt Tucheband", "value": "C37162"},
                 {"label": "Altdobern", "value": "C37163"},
                 {"label": "Altlandsberg", "value": "C37164"},
                 {"label": "Angermunde", "value": "C37165"},
                 {"label": "Bad Belzig", "value": "C37166"},
                 {"label": "Bad Freienwalde", "value": "C37167"},
                 {"label": "Bad Liebenwerda", "value": "C37168"},
                 {"label": "Bad Saarow", "value": "C37169"},
                 {"label": "Bad Wilsnack", "value": "C37170"},
                 {"label": "Baruth", "value": "C37171"},
                 {"label": "Beelitz", "value": "C37172"},
                 {"label": "Beeskow", "value": "C37173"},
                 {"label": "Bensdorf", "value": "C37174"},
                 {"label": "Berkenbruck", "value": "C37175"},
                 {"label": "Bernau bei Berlin", "value": "C37176"},
                 {"label": "Bestensee", "value": "C37177"},
                 {"label": "Biesenthal", "value": "C37178"},
                 {"label": "Birkenwerder", "value": "C37179"},
                 {"label": "Bliesdorf", "value": "C37180"},
                 {"label": "Borkheide", "value": "C37181"},
                 {"label": "Borkwalde", "value": "C37182"},
                 {"label": "Brandenburg an der Havel", "value": "C37183"},
                 {"label": "Breddin", "value": "C37184"},
                 {"label": "Breese", "value": "C37185"},
                 {"label": "Brieselang", "value": "C37186"},
                 {"label": "Briesen", "value": "C37187"},
                 {"label": "Brieskow-Finkenheerd", "value": "C37188"},
                 {"label": "Britz", "value": "C37189"},
                 {"label": "Bruck", "value": "C37190"},
                 {"label": "Brussow", "value": "C37191"},
                 {"label": "Buckow", "value": "C37192"},
                 {"label": "Burg", "value": "C37193"},
                 {"label": "Calau", "value": "C37194"},
                 {"label": "Casekow", "value": "C37195"},
                 {"label": "Chorin", "value": "C37196"},
                 {"label": "Cottbus", "value": "C37197"},
                 {"label": "Crinitz", "value": "C37198"},
                 {"label": "Dahme", "value": "C37199"},
                 {"label": "Dallgow-Doberitz", "value": "C37200"},
                 {"label": "Doberlug-Kirchhain", "value": "C37201"},
                 {"label": "Drebkau", "value": "C37202"},
                 {"label": "Dreetz", "value": "C37203"},
                 {"label": "Eberswalde", "value": "C37204"},
                 {"label": "Eichwalde", "value": "C37205"},
                 {"label": "Eisenhuttenstadt", "value": "C37206"},
                 {"label": "Elsterwerda", "value": "C37207"},
                 {"label": "Erkner", "value": "C37208"},
                 {"label": "Falkenberg", "value": "C37209"},
                 {"label": "Falkensee", "value": "C37210"},
                 {"label": "Fehrbellin", "value": "C37211"},
                 {"label": "Fichtenwalde", "value": "C37212"},
                 {"label": "Finsterwalde", "value": "C37213"},
                 {"label": "Forst", "value": "C37214"},
                 {"label": "Frankfurt (Oder)", "value": "C37215"},
                 {"label": "Friedland", "value": "C37216"},
                 {"label": "Friesack", "value": "C37217"},
                 {"label": "Furstenberg", "value": "C37218"},
                 {"label": "Furstenwalde", "value": "C37219"},
                 {"label": "Gartz", "value": "C37220"},
                 {"label": "Gerswalde", "value": "C37221"},
                 {"label": "Glienicke", "value": "C37222"},
                 {"label": "Golssen", "value": "C37223"},
                 {"label": "Golzow", "value": "C37224"},
                 {"label": "Gorzke", "value": "C37225"},
                 {"label": "Gramzow", "value": "C37226"},
                 {"label": "Gransee", "value": "C37227"},
                 {"label": "Groden", "value": "C37228"},
                 {"label": "Gross Koris", "value": "C37229"},
                 {"label": "Gross Kreutz", "value": "C37230"},
                 {"label": "Gross Lindow", "value": "C37231"},
                 {"label": "Gross Pankow", "value": "C37232"},
                 {"label": "Grossbeeren", "value": "C37233"},
                 {"label": "Grosskmehlen", "value": "C37234"},
                 {"label": "Grossraschen", "value": "C37235"},
                 {"label": "Grossthiemig", "value": "C37236"},
                 {"label": "Grosswoltersdorf", "value": "C37237"},
                 {"label": "Grunheide", "value": "C37238"},
                 {"label": "Guben", "value": "C37239"},
                 {"label": "Gumtow", "value": "C37240"},
                 {"label": "Halbe", "value": "C37241"},
                 {"label": "Heiligengrabe", "value": "C37242"},
                 {"label": "Hennigsdorf", "value": "C37243"},
                 {"label": "Herzberg", "value": "C37244"},
                 {"label": "Hirschfeld", "value": "C37245"},
                 {"label": "Hohen Neuendorf", "value": "C37246"},
                 {"label": "Hohenbocka", "value": "C37247"},
                 {"label": "Hohenleipisch", "value": "C37248"},
                 {"label": "Jacobsdorf", "value": "C37249"},
                 {"label": "Janschwalde", "value": "C37250"},
                 {"label": "Joachimsthal", "value": "C37251"},
                 {"label": "Juterbog", "value": "C37252"},
                 {"label": "Karstadt", "value": "C37253"},
                 {"label": "Ketzin", "value": "C37254"},
                 {"label": "Kleinmachnow", "value": "C37255"},
                 {"label": "Kloster Lehnin", "value": "C37256"},
                 {"label": "Kolkwitz", "value": "C37257"},
                 {"label": "Konigs Wusterhausen", "value": "C37258"},
                 {"label": "Kremmen", "value": "C37259"},
                 {"label": "Kyritz", "value": "C37260"},
                 {"label": "Lauchhammer", "value": "C37261"},
                 {"label": "Lebus", "value": "C37262"},
                 {"label": "Leegebruch", "value": "C37263"},
                 {"label": "Lenzen", "value": "C37264"},
                 {"label": "Letschin", "value": "C37265"},
                 {"label": "Liebenwalde", "value": "C37266"},
                 {"label": "Lieberose", "value": "C37267"},
                 {"label": "Lindow", "value": "C37268"},
                 {"label": "Lubben", "value": "C37269"},
                 {"label": "Lubbenau", "value": "C37270"},
                 {"label": "Luckau", "value": "C37271"},
                 {"label": "Luckenwalde", "value": "C37272"},
                 {"label": "Ludwigsfelde", "value": "C37273"},
                 {"label": "Lychen", "value": "C37274"},
                 {"label": "Manschnow", "value": "C37275"},
                 {"label": "Marienwerder", "value": "C37276"},
                 {"label": "Melchow", "value": "C37277"},
                 {"label": "Meyenburg", "value": "C37278"},
                 {"label": "Michendorf", "value": "C37279"},
                 {"label": "Milmersdorf", "value": "C37280"},
                 {"label": "Mittenwalde", "value": "C37281"},
                 {"label": "Mixdorf", "value": "C37282"},
                 {"label": "Muhlberg", "value": "C37283"},
                 {"label": "Mullrose", "value": "C37284"},
                 {"label": "Muncheberg", "value": "C37285"},
                 {"label": "Nauen", "value": "C37286"},
                 {"label": "Nennhausen", "value": "C37287"},
                 {"label": "Neu Zauche", "value": "C37288"},
                 {"label": "Neuenhagen", "value": "C37289"},
                 {"label": "Neuhardenberg", "value": "C37290"},
                 {"label": "Neulewin", "value": "C37291"},
                 {"label": "Neuruppin", "value": "C37292"},
                 {"label": "Neutrebbin", "value": "C37293"},
                 {"label": "Neuzelle", "value": "C37294"},
                 {"label": "Niedergorsdorf", "value": "C37295"},
                 {"label": "Niemegk", "value": "C37296"},
                 {"label": "Oderberg", "value": "C37297"},
                 {"label": "Oranienburg", "value": "C37298"},
                 {"label": "Ortrand", "value": "C37299"},
                 {"label": "Paulinenaue", "value": "C37300"},
                 {"label": "Peitz", "value": "C37301"},
                 {"label": "Perleberg", "value": "C37302"},
                 {"label": "Petershagen", "value": "C37303"},
                 {"label": "Plattenburg", "value": "C37304"},
                 {"label": "Plessa", "value": "C37305"},
                 {"label": "Podelzig", "value": "C37306"},
                 {"label": "Potsdam", "value": "C37307"},
                 {"label": "Premnitz", "value": "C37308"},
                 {"label": "Prenzlau", "value": "C37309"},
                 {"label": "Pritzwalk", "value": "C37310"},
                 {"label": "Protzel", "value": "C37311"},
                 {"label": "Putlitz", "value": "C37312"},
                 {"label": "Rangsdorf", "value": "C37313"},
                 {"label": "Rathenow", "value": "C37314"},
                 {"label": "Rauen", "value": "C37315"},
                 {"label": "Rehfelde", "value": "C37316"},
                 {"label": "Reichenwalde", "value": "C37317"},
                 {"label": "Rheinsberg", "value": "C37318"},
                 {"label": "Rhinow", "value": "C37319"},
                 {"label": "Rietz Neuendorf", "value": "C37320"},
                 {"label": "Roskow", "value": "C37321"},
                 {"label": "Ruckersdorf", "value": "C37322"},
                 {"label": "Rudnitz", "value": "C37323"},
                 {"label": "Ruhland", "value": "C37324"},
                 {"label": "Sallgast", "value": "C37325"},
                 {"label": "Schenkendobern", "value": "C37326"},
                 {"label": "Schipkau", "value": "C37327"},
                 {"label": "Schlieben", "value": "C37328"},
                 {"label": "Schonborn", "value": "C37329"},
                 {"label": "Schonefeld", "value": "C37330"},
                 {"label": "Schoneiche", "value": "C37331"},
                 {"label": "Schonewalde", "value": "C37332"},
                 {"label": "Schulzendorf", "value": "C37333"},
                 {"label": "Schwarzheide", "value": "C37334"},
                 {"label": "Schwedt (Oder)", "value": "C37335"},
                 {"label": "Seelow", "value": "C37336"},
                 {"label": "Senftenberg", "value": "C37337"},
                 {"label": "Sonnewalde", "value": "C37338"},
                 {"label": "Sperenberg", "value": "C37339"},
                 {"label": "Spreenhagen", "value": "C37340"},
                 {"label": "Stahnsdorf", "value": "C37341"},
                 {"label": "Steinhofel", "value": "C37342"},
                 {"label": "Storkow", "value": "C37343"},
                 {"label": "Straupitz", "value": "C37344"},
                 {"label": "Strausberg", "value": "C37345"},
                 {"label": "Tauche", "value": "C37346"},
                 {"label": "Teltow", "value": "C37347"},
                 {"label": "Templin", "value": "C37348"},
                 {"label": "Teupitz", "value": "C37349"},
                 {"label": "Trebbin", "value": "C37350"},
                 {"label": "Treuenbrietzen", "value": "C37351"},
                 {"label": "Tschernitz", "value": "C37352"},
                 {"label": "Uebigau", "value": "C37353"},
                 {"label": "Velten", "value": "C37354"},
                 {"label": "Vetschau", "value": "C37355"},
                 {"label": "Waldsieversdorf", "value": "C37356"},
                 {"label": "Wandlitz", "value": "C37357"},
                 {"label": "Weisen", "value": "C37358"},
                 {"label": "Welzow", "value": "C37359"},
                 {"label": "Wendisch Rietz", "value": "C37360"},
                 {"label": "Werben", "value": "C37361"},
                 {"label": "Werder", "value": "C37362"},
                 {"label": "Werftpfuhl", "value": "C37363"},
                 {"label": "Werneuchen", "value": "C37364"},
                 {"label": "Wiesenau", "value": "C37365"},
                 {"label": "Wiesenburg", "value": "C37366"},
                 {"label": "Wittenberge", "value": "C37367"},
                 {"label": "Wittstock", "value": "C37368"},
                 {"label": "Woltersdorf", "value": "C37369"},
                 {"label": "Wriezen", "value": "C37370"},
                 {"label": "Wusterhausen", "value": "C37371"},
                 {"label": "Wustermark", "value": "C37372"},
                 {"label": "Wusterwitz", "value": "C37373"},
                 {"label": "Zehdenick", "value": "C37374"},
                 {"label": "Zeuthen", "value": "C37375"},
                 {"label": "Ziesar", "value": "C37376"},
                 {"label": "Ziltendorf", "value": "C37377"},
                 {"label": "Zossen", "value": "C37378"}],
 "Braslovce Municipality": [{"label": "Braslovce", "value": "C107583"}],
 "Brasov County": [{"label": "Acris", "value": "C93660"},
                   {"label": "Apata", "value": "C93661"},
                   {"label": "Augustin", "value": "C93662"},
                   {"label": "Beclean", "value": "C93663"},
                   {"label": "Bod", "value": "C93664"},
                   {"label": "Bran", "value": "C93665"},
                   {"label": "Brasov", "value": "C93666"},
                   {"label": "Budila", "value": "C93667"},
                   {"label": "Bunesti", "value": "C93668"},
                   {"label": "Cata", "value": "C93669"},
                   {"label": "Cincu", "value": "C93670"},
                   {"label": "Codlea", "value": "C93671"},
                   {"label": "Colonia Bod", "value": "C93672"},
                   {"label": "Comana de Jos", "value": "C93673"},
                   {"label": "Comana de Sus", "value": "C93674"},
                   {"label": "Comuna Apata", "value": "C93675"},
                   {"label": "Comuna Augustin", "value": "C93676"},
                   {"label": "Comuna Beclean", "value": "C93677"},
                   {"label": "Comuna Bod", "value": "C93678"},
                   {"label": "Comuna Bran", "value": "C93679"},
                   {"label": "Comuna Budila", "value": "C93680"},
                   {"label": "Comuna Bunesti", "value": "C93681"},
                   {"label": "Comuna Cata", "value": "C93682"},
                   {"label": "Comuna Cincu", "value": "C93683"},
                   {"label": "Comuna Comana", "value": "C93684"},
                   {"label": "Comuna Cristian", "value": "C93685"},
                   {"label": "Comuna Crizbav", "value": "C93686"},
                   {"label": "Comuna Dragus", "value": "C93687"},
                   {"label": "Comuna Dumbravita", "value": "C93688"},
                   {"label": "Comuna Feldioara", "value": "C93689"},
                   {"label": "Comuna Fundata", "value": "C93690"},
                   {"label": "Comuna Halchiu", "value": "C93691"},
                   {"label": "Comuna Harman", "value": "C93692"},
                   {"label": "Comuna Harseni", "value": "C93693"},
                   {"label": "Comuna Hoghiz", "value": "C93694"},
                   {"label": "Comuna Holbav", "value": "C93695"},
                   {"label": "Comuna Homorod", "value": "C93696"},
                   {"label": "Comuna Jibert", "value": "C93697"},
                   {"label": "Comuna Lisa", "value": "C93698"},
                   {"label": "Comuna Maierus", "value": "C93699"},
                   {"label": "Comuna Mandra", "value": "C93700"},
                   {"label": "Comuna Moieciu", "value": "C93701"},
                   {"label": "Comuna Ormenis", "value": "C93702"},
                   {"label": "Comuna Parau", "value": "C93703"},
                   {"label": "Comuna Poiana Marului (Brasov)",
                    "value": "C93704"},
                   {"label": "Comuna Prejmer", "value": "C93705"},
                   {"label": "Comuna Racos", "value": "C93706"},
                   {"label": "Comuna Recea", "value": "C93707"},
                   {"label": "Comuna Sambata de Sus", "value": "C93708"},
                   {"label": "Comuna Sanpetru", "value": "C93709"},
                   {"label": "Comuna Sercaia", "value": "C93710"},
                   {"label": "Comuna Sinca Noua", "value": "C93711"},
                   {"label": "Comuna Sinca Veche", "value": "C93712"},
                   {"label": "Comuna Soars", "value": "C93713"},
                   {"label": "Comuna Tarlungeni", "value": "C93714"},
                   {"label": "Comuna Teliu", "value": "C93715"},
                   {"label": "Comuna Ticusu Vechi", "value": "C93716"},
                   {"label": "Comuna Ucea de Jos", "value": "C93717"},
                   {"label": "Comuna Ungra", "value": "C93718"},
                   {"label": "Comuna Vama Buzaului", "value": "C93719"},
                   {"label": "Comuna Vistea de Jos", "value": "C93720"},
                   {"label": "Comuna Voila", "value": "C93721"},
                   {"label": "Comuna Vulcan", "value": "C93722"},
                   {"label": "Cristian", "value": "C93723"},
                   {"label": "Crivina", "value": "C93724"},
                   {"label": "Crizbav", "value": "C93725"},
                   {"label": "Cuciulata", "value": "C93726"},
                   {"label": "Dragus", "value": "C93727"},
                   {"label": "Dumbravita", "value": "C93728"},
                   {"label": "Fagaras", "value": "C93729"},
                   {"label": "Feldioara", "value": "C93730"},
                   {"label": "Fiser", "value": "C93731"},
                   {"label": "Fundata", "value": "C93732"},
                   {"label": "Ghimbav", "value": "C93733"},
                   {"label": "Halchiu", "value": "C93734"},
                   {"label": "Harman", "value": "C93735"},
                   {"label": "Harseni", "value": "C93736"},
                   {"label": "Hoghiz", "value": "C93737"},
                   {"label": "Holbav", "value": "C93738"},
                   {"label": "Homorod", "value": "C93739"},
                   {"label": "Jibert", "value": "C93740"},
                   {"label": "Lisa", "value": "C93741"},
                   {"label": "Maierus", "value": "C93742"},
                   {"label": "Mandra", "value": "C93743"},
                   {"label": "Moeciu de Jos", "value": "C93744"},
                   {"label": "Moieciu de Jos", "value": "C93745"},
                   {"label": "Municipiul  Codlea", "value": "C93746"},
                   {"label": "Municipiul Brasov", "value": "C93747"},
                   {"label": "Municipiul Fagaras", "value": "C93748"},
                   {"label": "Municipiul Sacele", "value": "C93749"},
                   {"label": "Oras Ghimbav", "value": "C93750"},
                   {"label": "Oras Predeal", "value": "C93751"},
                   {"label": "Oras Rasnov", "value": "C93752"},
                   {"label": "Oras Rupea", "value": "C93753"},
                   {"label": "Oras Victoria", "value": "C93754"},
                   {"label": "Oras Zarnesti", "value": "C93755"},
                   {"label": "Ormenis", "value": "C93756"},
                   {"label": "Parau", "value": "C93757"},
                   {"label": "Persani", "value": "C93758"},
                   {"label": "Poiana Marului (Brasov)", "value": "C93759"},
                   {"label": "Predeal", "value": "C93760"},
                   {"label": "Prejmer", "value": "C93761"},
                   {"label": "Purcareni", "value": "C93762"},
                   {"label": "Racos", "value": "C93763"},
                   {"label": "Rasnov", "value": "C93764"},
                   {"label": "Recea", "value": "C93765"},
                   {"label": "Rupea", "value": "C93766"},
                   {"label": "Sacele", "value": "C93767"},
                   {"label": "Sambata de Sus", "value": "C93768"},
                   {"label": "Sanpetru", "value": "C93769"},
                   {"label": "Sercaia", "value": "C93770"},
                   {"label": "Simon", "value": "C93771"},
                   {"label": "Sinca Noua", "value": "C93772"},
                   {"label": "Sinca Veche", "value": "C93773"},
                   {"label": "Soars", "value": "C93774"},
                   {"label": "Sohodol", "value": "C93775"},
                   {"label": "Tarlungeni", "value": "C93776"},
                   {"label": "Teliu", "value": "C93777"},
                   {"label": "Ticusu Vechi", "value": "C93778"},
                   {"label": "Tohanu Nou", "value": "C93779"},
                   {"label": "Tunelu-Teliu", "value": "C93780"},
                   {"label": "Ucea de Jos", "value": "C93781"},
                   {"label": "Ucea de Sus", "value": "C93782"},
                   {"label": "Ungra", "value": "C93783"},
                   {"label": "Vama Buzaului", "value": "C93784"},
                   {"label": "Victoria", "value": "C93785"},
                   {"label": "Viscri", "value": "C93786"},
                   {"label": "Vistea de Jos", "value": "C93787"},
                   {"label": "Voila", "value": "C93788"},
                   {"label": "Vulcan", "value": "C93789"},
                   {"label": "Zarnesti", "value": "C93790"},
                   {"label": "Zizin", "value": "C93791"}],
 "Bratislava Region": [{"label": "Bratislava", "value": "C107364"},
                       {"label": "Bratislava - Vajnory", "value": "C107365"},
                       {"label": "Dunajska Luzna", "value": "C107366"},
                       {"label": "Ivanka pri Dunaji", "value": "C107367"},
                       {"label": "Malacky", "value": "C107368"},
                       {"label": "Marianka", "value": "C107369"},
                       {"label": "Modra", "value": "C107370"},
                       {"label": "Okres Bratislava I", "value": "C107371"},
                       {"label": "Okres Bratislava II", "value": "C107372"},
                       {"label": "Okres Bratislava III", "value": "C107373"},
                       {"label": "Okres Bratislava IV", "value": "C107374"},
                       {"label": "Okres Bratislava V", "value": "C107375"},
                       {"label": "Okres Malacky", "value": "C107376"},
                       {"label": "Okres Pezinok", "value": "C107377"},
                       {"label": "Okres Senec", "value": "C107378"},
                       {"label": "Pezinok", "value": "C107379"},
                       {"label": "Senec", "value": "C107380"},
                       {"label": "Stupava", "value": "C107381"},
                       {"label": "Svaty Jur", "value": "C107382"},
                       {"label": "Vinosady", "value": "C107383"}],
 "Brava": [{"label": "Nova Sintra", "value": "C17785"}],
 "Brazzaville": [{"label": "Brazzaville", "value": "C20748"}],
 "Brcko District": [{"label": "Brcko", "value": "C9890"},
                    {"label": "Brka", "value": "C9891"}],
 "Brda Municipality": [{"label": "Dobrovo", "value": "C107584"},
                       {"label": "Mokronog", "value": "C107585"}],
 "Bremen": [{"label": "Bremen", "value": "C37379"},
            {"label": "Bremerhaven", "value": "C37380"},
            {"label": "Burglesum", "value": "C37381"},
            {"label": "Vegesack", "value": "C37382"}],
 "Brest Region": [{"label": "Antopal", "value": "C8702"},
                  {"label": "Asnyezhytsy", "value": "C8703"},
                  {"label": "Baranovichi", "value": "C8704"},
                  {"label": "Baranovichskiy Rayon", "value": "C8705"},
                  {"label": "Brest", "value": "C8706"},
                  {"label": "Brestski Rayon", "value": "C8707"},
                  {"label": "Byaroza", "value": "C8708"},
                  {"label": "Byarozawski Rayon", "value": "C8709"},
                  {"label": "Byelaazyorsk", "value": "C8710"},
                  {"label": "Charnawchytsy", "value": "C8711"},
                  {"label": "Damachava", "value": "C8712"},
                  {"label": "Davyd-Haradok", "value": "C8713"},
                  {"label": "Drahichyn", "value": "C8714"},
                  {"label": "Drahichynski Rayon", "value": "C8715"},
                  {"label": "Hantsavichy", "value": "C8716"},
                  {"label": "Hantsavitski Rayon", "value": "C8717"},
                  {"label": "Haradzishcha", "value": "C8718"},
                  {"label": "Horad Baranavichy", "value": "C8719"},
                  {"label": "Horad Brest", "value": "C8720"},
                  {"label": "Ivanava", "value": "C8721"},
                  {"label": "Ivanawski Rayon", "value": "C8722"},
                  {"label": "Ivatsevichy", "value": "C8723"},
                  {"label": "Kamyanyets", "value": "C8724"},
                  {"label": "Kamyanyetski Rayon", "value": "C8725"},
                  {"label": "Kamyanyuki", "value": "C8726"},
                  {"label": "Kobryn", "value": "C8727"},
                  {"label": "Kosava", "value": "C8728"},
                  {"label": "Lahishyn", "value": "C8729"},
                  {"label": "Luninyets", "value": "C8730"},
                  {"label": "Lyakhavichy", "value": "C8731"},
                  {"label": "Malaryta", "value": "C8732"},
                  {"label": "Mikashevichy", "value": "C8733"},
                  {"label": "Motal", "value": "C8734"},
                  {"label": "Nyakhachava", "value": "C8735"},
                  {"label": "Pinsk", "value": "C8736"},
                  {"label": "Pruzhanski Rayon", "value": "C8737"},
                  {"label": "Pruzhany", "value": "C8738"},
                  {"label": "Ruzhany", "value": "C8739"},
                  {"label": "Stolin", "value": "C8740"},
                  {"label": "Stolinski Rayon", "value": "C8741"},
                  {"label": "Tsyelyakhany", "value": "C8742"},
                  {"label": "Vysokaye", "value": "C8743"},
                  {"label": "Zhabinka", "value": "C8744"},
                  {"label": "Zhabinkawski Rayon", "value": "C8745"},
                  {"label": "Znamenka", "value": "C8746"}],
 "Bretagne": [{"label": "Acigne", "value": "C26909"},
              {"label": "Allaire", "value": "C26910"},
              {"label": "Amanlis", "value": "C26911"},
              {"label": "Ambon", "value": "C26912"},
              {"label": "Antrain", "value": "C26913"},
              {"label": "Argentre-du-Plessis", "value": "C26914"},
              {"label": "Arradon", "value": "C26915"},
              {"label": "Arzano", "value": "C26916"},
              {"label": "Arzon", "value": "C26917"},
              {"label": "Audierne", "value": "C26918"},
              {"label": "Augan", "value": "C26919"},
              {"label": "Auray", "value": "C26920"},
              {"label": "Baden", "value": "C26921"},
              {"label": "Baguer-Morvan", "value": "C26922"},
              {"label": "Baguer-Pican", "value": "C26923"},
              {"label": "Bain-de-Bretagne", "value": "C26924"},
              {"label": "Bains-sur-Oust", "value": "C26925"},
              {"label": "Bais", "value": "C26926"},
              {"label": "Balaze", "value": "C26927"},
              {"label": "Bannalec", "value": "C26928"},
              {"label": "Baud", "value": "C26929"},
              {"label": "Baulon", "value": "C26930"},
              {"label": "Bazouges-la-Perouse", "value": "C26931"},
              {"label": "Beauce", "value": "C26932"},
              {"label": "Bedee", "value": "C26933"},
              {"label": "Beganne", "value": "C26934"},
              {"label": "Begard", "value": "C26935"},
              {"label": "Beignon", "value": "C26936"},
              {"label": "Belle-Isle-en-Terre", "value": "C26937"},
              {"label": "Belz", "value": "C26938"},
              {"label": "Benodet", "value": "C26939"},
              {"label": "Berne", "value": "C26940"},
              {"label": "Berric", "value": "C26941"},
              {"label": "Berrien", "value": "C26942"},
              {"label": "Betton", "value": "C26943"},
              {"label": "Beuzec-Cap-Sizun", "value": "C26944"},
              {"label": "Bignan", "value": "C26945"},
              {"label": "Binic", "value": "C26946"},
              {"label": "Bodilis", "value": "C26947"},
              {"label": "Bohars", "value": "C26948"},
              {"label": "Boisgervilly", "value": "C26949"},
              {"label": "Bonnemain", "value": "C26950"},
              {"label": "Bourbriac", "value": "C26951"},
              {"label": "Bourg-Blanc", "value": "C26952"},
              {"label": "Bourg-des-Comptes", "value": "C26953"},
              {"label": "Bourgbarre", "value": "C26954"},
              {"label": "Branderion", "value": "C26955"},
              {"label": "Brasparts", "value": "C26956"},
              {"label": "Breal-sous-Montfort", "value": "C26957"},
              {"label": "Brece", "value": "C26958"},
              {"label": "Brech", "value": "C26959"},
              {"label": "Brehand", "value": "C26960"},
              {"label": "Brest", "value": "C26961"},
              {"label": "Breteil", "value": "C26962"},
              {"label": "Briec", "value": "C26963"},
              {"label": "Broons", "value": "C26964"},
              {"label": "Bruz", "value": "C26965"},
              {"label": "Bubry", "value": "C26966"},
              {"label": "Caden", "value": "C26967"},
              {"label": "Callac", "value": "C26968"},
              {"label": "Camaret-sur-Mer", "value": "C26969"},
              {"label": "Camors", "value": "C26970"},
              {"label": "Campeneac", "value": "C26971"},
              {"label": "Cancale", "value": "C26972"},
              {"label": "Carantec", "value": "C26973"},
              {"label": "Carentoir", "value": "C26974"},
              {"label": "Carhaix-Plouguer", "value": "C26975"},
              {"label": "Carnac", "value": "C26976"},
              {"label": "Caro", "value": "C26977"},
              {"label": "Cast", "value": "C26978"},
              {"label": "Caudan", "value": "C26979"},
              {"label": "Caulnes", "value": "C26980"},
              {"label": "Cavan", "value": "C26981"},
              {"label": "Cesson-Sevigne", "value": "C26982"},
              {"label": "Chanteloup", "value": "C26983"},
              {"label": "Chantepie", "value": "C26984"},
              {"label": "Chartres-de-Bretagne", "value": "C26985"},
              {"label": "Chasne-sur-Illet", "value": "C26986"},
              {"label": "Chateaubourg", "value": "C26987"},
              {"label": "Chateaugiron", "value": "C26988"},
              {"label": "Chateaulin", "value": "C26989"},
              {"label": "Chateauneuf-dIlle-et-Vilaine", "value": "C26990"},
              {"label": "Chateauneuf-du-Faou", "value": "C26991"},
              {"label": "Chatillon-en-Vendelais", "value": "C26992"},
              {"label": "Chatillon-sur-Seiche", "value": "C26993"},
              {"label": "Chavagne", "value": "C26994"},
              {"label": "Cherrueix", "value": "C26995"},
              {"label": "Chevaigne", "value": "C26996"},
              {"label": "Cintre", "value": "C26997"},
              {"label": "Cleden-Poher", "value": "C26998"},
              {"label": "Cleder", "value": "C26999"},
              {"label": "Cleguer", "value": "C27000"},
              {"label": "Cleguerec", "value": "C27001"},
              {"label": "Clohars-Carnoet", "value": "C27002"},
              {"label": "Clohars-Fouesnant", "value": "C27003"},
              {"label": "Coesmes", "value": "C27004"},
              {"label": "Coetmieux", "value": "C27005"},
              {"label": "Colpo", "value": "C27006"},
              {"label": "Combourg", "value": "C27007"},
              {"label": "Combrit", "value": "C27008"},
              {"label": "Concarneau", "value": "C27009"},
              {"label": "Coray", "value": "C27010"},
              {"label": "Corlay", "value": "C27011"},
              {"label": "Corps-Nuds", "value": "C27012"},
              {"label": "Corseul", "value": "C27013"},
              {"label": "Crach", "value": "C27014"},
              {"label": "Credin", "value": "C27015"},
              {"label": "Crehen", "value": "C27016"},
              {"label": "Crevin", "value": "C27017"},
              {"label": "Crozon", "value": "C27018"},
              {"label": "Damgan", "value": "C27019"},
              {"label": "Daoulas", "value": "C27020"},
              {"label": "Departement dIlle-et-Vilaine", "value": "C27021"},
              {"label": "Departement des Cotes-dArmor", "value": "C27022"},
              {"label": "Departement du Finistere", "value": "C27023"},
              {"label": "Dinan", "value": "C27024"},
              {"label": "Dinard", "value": "C27025"},
              {"label": "Dineault", "value": "C27026"},
              {"label": "Dinge", "value": "C27027"},
              {"label": "Dirinon", "value": "C27028"},
              {"label": "Dol-de-Bretagne", "value": "C27029"},
              {"label": "Domagne", "value": "C27030"},
              {"label": "Domalain", "value": "C27031"},
              {"label": "Douarnenez", "value": "C27032"},
              {"label": "Edern", "value": "C27033"},
              {"label": "Elliant", "value": "C27034"},
              {"label": "Elven", "value": "C27035"},
              {"label": "Epiniac", "value": "C27036"},
              {"label": "Erbree", "value": "C27037"},
              {"label": "Erce-en-Lamee", "value": "C27038"},
              {"label": "Erce-pres-Liffre", "value": "C27039"},
              {"label": "Erdeven", "value": "C27040"},
              {"label": "Ergue-Gaberic", "value": "C27041"},
              {"label": "Erquy", "value": "C27042"},
              {"label": "Esquibien", "value": "C27043"},
              {"label": "etables-sur-Mer", "value": "C27044"},
              {"label": "etel", "value": "C27045"},
              {"label": "etrelles", "value": "C27046"},
              {"label": "evran", "value": "C27047"},
              {"label": "Ferel", "value": "C27048"},
              {"label": "Fouesnant", "value": "C27049"},
              {"label": "Fougeres", "value": "C27050"},
              {"label": "Fouillard", "value": "C27051"},
              {"label": "Frehel", "value": "C27052"},
              {"label": "Gael", "value": "C27053"},
              {"label": "Gestel", "value": "C27054"},
              {"label": "Geveze", "value": "C27055"},
              {"label": "Glomel", "value": "C27056"},
              {"label": "Gosne", "value": "C27057"},
              {"label": "Gouarec", "value": "C27058"},
              {"label": "Goudelin", "value": "C27059"},
              {"label": "Gouesnach", "value": "C27060"},
              {"label": "Gouesnou", "value": "C27061"},
              {"label": "Gouezec", "value": "C27062"},
              {"label": "Gourin", "value": "C27063"},
              {"label": "Goven", "value": "C27064"},
              {"label": "Graces", "value": "C27065"},
              {"label": "Grand-Champ", "value": "C27066"},
              {"label": "Grand-Fougeray", "value": "C27067"},
              {"label": "Groix", "value": "C27068"},
              {"label": "Guegon", "value": "C27069"},
              {"label": "Guemene-sur-Scorff", "value": "C27070"},
              {"label": "Guengat", "value": "C27071"},
              {"label": "Guenin", "value": "C27072"},
              {"label": "Guer", "value": "C27073"},
              {"label": "Guerlesquin", "value": "C27074"},
              {"label": "Guern", "value": "C27075"},
              {"label": "Guichen", "value": "C27076"},
              {"label": "Guiclan", "value": "C27077"},
              {"label": "Guidel-Plage", "value": "C27078"},
              {"label": "Guignen", "value": "C27079"},
              {"label": "Guilers", "value": "C27080"},
              {"label": "Guillac", "value": "C27081"},
              {"label": "Guilliers", "value": "C27082"},
              {"label": "Guilvinec", "value": "C27083"},
              {"label": "Guingamp", "value": "C27084"},
              {"label": "Guipavas", "value": "C27085"},
              {"label": "Guipel", "value": "C27086"},
              {"label": "Guipry", "value": "C27087"},
              {"label": "Guiscriff", "value": "C27088"},
              {"label": "Guisseny", "value": "C27089"},
              {"label": "Hanvec", "value": "C27090"},
              {"label": "Hede-Bazouges", "value": "C27091"},
              {"label": "Henanbihen", "value": "C27092"},
              {"label": "Hennebont", "value": "C27093"},
              {"label": "Henon", "value": "C27094"},
              {"label": "Henvic", "value": "C27095"},
              {"label": "Hillion", "value": "C27096"},
              {"label": "Hirel", "value": "C27097"},
              {"label": "Hopital-Camfrout", "value": "C27098"},
              {"label": "Huelgoat", "value": "C27099"},
              {"label": "Iffendic", "value": "C27100"},
              {"label": "Inguiniel", "value": "C27101"},
              {"label": "Irodouer", "value": "C27102"},
              {"label": "Irvillac", "value": "C27103"},
              {"label": "Janze", "value": "C27104"},
              {"label": "Javene", "value": "C27105"},
              {"label": "Josselin", "value": "C27106"},
              {"label": "Jugon-les-Lacs", "value": "C27107"},
              {"label": "Kerlouan", "value": "C27108"},
              {"label": "Kernilis", "value": "C27109"},
              {"label": "Kersaint-Plabennec", "value": "C27110"},
              {"label": "Kervignac", "value": "C27111"},
              {"label": "La Bouexiere", "value": "C27112"},
              {"label": "La Boussac", "value": "C27113"},
              {"label": "La Chapelle-des-Fougeretz", "value": "C27114"},
              {"label": "La Chapelle-Janson", "value": "C27115"},
              {"label": "La Chapelle-Thouarault", "value": "C27116"},
              {"label": "La Dominelais", "value": "C27117"},
              {"label": "La Forest-Landerneau", "value": "C27118"},
              {"label": "La Foret-Fouesnant", "value": "C27119"},
              {"label": "La Fresnais", "value": "C27120"},
              {"label": "La Gacilly", "value": "C27121"},
              {"label": "La Gouesniere", "value": "C27122"},
              {"label": "La Guerche-de-Bretagne", "value": "C27123"},
              {"label": "La Meaugon", "value": "C27124"},
              {"label": "La Meziere", "value": "C27125"},
              {"label": "La Motte", "value": "C27126"},
              {"label": "La Richardais", "value": "C27127"},
              {"label": "La Roche-Derrien", "value": "C27128"},
              {"label": "La Roche-Maurice", "value": "C27129"},
              {"label": "La Trinite-sur-Mer", "value": "C27130"},
              {"label": "La Vraie-Croix", "value": "C27131"},
              {"label": "Laille", "value": "C27132"},
              {"label": "Lamballe", "value": "C27133"},
              {"label": "Lampaul-Guimiliau", "value": "C27134"},
              {"label": "Lampaul-Plouarzel", "value": "C27135"},
              {"label": "Lancieux", "value": "C27136"},
              {"label": "Landaul", "value": "C27137"},
              {"label": "Landean", "value": "C27138"},
              {"label": "Landeda", "value": "C27139"},
              {"label": "Landehen", "value": "C27140"},
              {"label": "Landeleau", "value": "C27141"},
              {"label": "Landerneau", "value": "C27142"},
              {"label": "Landevant", "value": "C27143"},
              {"label": "Landivisiau", "value": "C27144"},
              {"label": "Landrevarzec", "value": "C27145"},
              {"label": "Landudec", "value": "C27146"},
              {"label": "Landunvez", "value": "C27147"},
              {"label": "Lanester", "value": "C27148"},
              {"label": "Langoat", "value": "C27149"},
              {"label": "Langon", "value": "C27150"},
              {"label": "Langonnet", "value": "C27151"},
              {"label": "Langueux", "value": "C27152"},
              {"label": "Languidic", "value": "C27153"},
              {"label": "Lanmeur", "value": "C27154"},
              {"label": "Lannilis", "value": "C27155"},
              {"label": "Lannion", "value": "C27156"},
              {"label": "Lanouee", "value": "C27157"},
              {"label": "Lanrivoare", "value": "C27158"},
              {"label": "Lanrodec", "value": "C27159"},
              {"label": "Lantic", "value": "C27160"},
              {"label": "Lanvallay", "value": "C27161"},
              {"label": "Lanvenegen", "value": "C27162"},
              {"label": "Lanveoc", "value": "C27163"},
              {"label": "Lanvollon", "value": "C27164"},
              {"label": "Larmor-Baden", "value": "C27165"},
              {"label": "Larmor-Plage", "value": "C27166"},
              {"label": "Lassy", "value": "C27167"},
              {"label": "Le Bono", "value": "C27168"},
              {"label": "Le Conquet", "value": "C27169"},
              {"label": "Le Drennec", "value": "C27170"},
              {"label": "Le Faou", "value": "C27171"},
              {"label": "Le Faouet", "value": "C27172"},
              {"label": "Le Foeil", "value": "C27173"},
              {"label": "Le Folgoet", "value": "C27174"},
              {"label": "Le Minihic-sur-Rance", "value": "C27175"},
              {"label": "Le Palais", "value": "C27176"},
              {"label": "Le Pertre", "value": "C27177"},
              {"label": "Le Relecq-Kerhuon", "value": "C27178"},
              {"label": "Le Rheu", "value": "C27179"},
              {"label": "Le Sourn", "value": "C27180"},
              {"label": "Le Theil-de-Bretagne", "value": "C27181"},
              {"label": "Le Trevoux", "value": "C27182"},
              {"label": "Le Vieux-Marche", "value": "C27183"},
              {"label": "Le Vivier-sur-Mer", "value": "C27184"},
              {"label": "Lecousse", "value": "C27185"},
              {"label": "Lehon", "value": "C27186"},
              {"label": "Les Champs-Geraux", "value": "C27187"},
              {"label": "Lesneven", "value": "C27188"},
              {"label": "Lezardrieux", "value": "C27189"},
              {"label": "Liffre", "value": "C27190"},
              {"label": "Limerzel", "value": "C27191"},
              {"label": "Livre-sur-Changeon", "value": "C27192"},
              {"label": "Locmariaquer", "value": "C27193"},
              {"label": "Locmine", "value": "C27194"},
              {"label": "Locmiquelic", "value": "C27195"},
              {"label": "Locoal-Mendon", "value": "C27196"},
              {"label": "Locquemeau", "value": "C27197"},
              {"label": "Locquirec", "value": "C27198"},
              {"label": "Loctudy", "value": "C27199"},
              {"label": "Logonna-Daoulas", "value": "C27200"},
              {"label": "Loperec", "value": "C27201"},
              {"label": "Loperhet", "value": "C27202"},
              {"label": "Lorient", "value": "C27203"},
              {"label": "Louannec", "value": "C27204"},
              {"label": "Louargat", "value": "C27205"},
              {"label": "Loudeac", "value": "C27206"},
              {"label": "Louvigne-de-Bais", "value": "C27207"},
              {"label": "Louvigne-du-Desert", "value": "C27208"},
              {"label": "Loyat", "value": "C27209"},
              {"label": "Luitre", "value": "C27210"},
              {"label": "Mael-Carhaix", "value": "C27211"},
              {"label": "Malansac", "value": "C27212"},
              {"label": "Malestroit", "value": "C27213"},
              {"label": "Malguenac", "value": "C27214"},
              {"label": "Martigne-Ferchaud", "value": "C27215"},
              {"label": "Marzan", "value": "C27216"},
              {"label": "Matignon", "value": "C27217"},
              {"label": "Maure-de-Bretagne", "value": "C27218"},
              {"label": "Mauron", "value": "C27219"},
              {"label": "Maxent", "value": "C27220"},
              {"label": "Medreac", "value": "C27221"},
              {"label": "Meillac", "value": "C27222"},
              {"label": "Melesse", "value": "C27223"},
              {"label": "Melgven", "value": "C27224"},
              {"label": "Mellac", "value": "C27225"},
              {"label": "Melrand", "value": "C27226"},
              {"label": "Meneac", "value": "C27227"},
              {"label": "Merdrignac", "value": "C27228"},
              {"label": "Merlevenez", "value": "C27229"},
              {"label": "Meslan", "value": "C27230"},
              {"label": "Messac", "value": "C27231"},
              {"label": "Meucon", "value": "C27232"},
              {"label": "Milizac", "value": "C27233"},
              {"label": "Miniac-Morvan", "value": "C27234"},
              {"label": "Minihy-Treguier", "value": "C27235"},
              {"label": "Moelan-sur-Mer", "value": "C27236"},
              {"label": "Molac", "value": "C27237"},
              {"label": "Montauban-de-Bretagne", "value": "C27238"},
              {"label": "Monterblanc", "value": "C27239"},
              {"label": "Monterfil", "value": "C27240"},
              {"label": "Montfort-sur-Meu", "value": "C27241"},
              {"label": "Montgermont", "value": "C27242"},
              {"label": "Montreuil-le-Gast", "value": "C27243"},
              {"label": "Montreuil-sous-Perouse", "value": "C27244"},
              {"label": "Montreuil-sur-Ille", "value": "C27245"},
              {"label": "Morbihan", "value": "C27246"},
              {"label": "Mordelles", "value": "C27247"},
              {"label": "Moreac", "value": "C27248"},
              {"label": "Morlaix", "value": "C27249"},
              {"label": "Moustoir-Ac", "value": "C27250"},
              {"label": "Mur-de-Bretagne", "value": "C27251"},
              {"label": "Muzillac", "value": "C27252"},
              {"label": "Naizin", "value": "C27253"},
              {"label": "Neulliac", "value": "C27254"},
              {"label": "Nevez", "value": "C27255"},
              {"label": "Nivillac", "value": "C27256"},
              {"label": "Nostang", "value": "C27257"},
              {"label": "Nouvoitou", "value": "C27258"},
              {"label": "Noyal-Muzillac", "value": "C27259"},
              {"label": "Noyal-Pontivy", "value": "C27260"},
              {"label": "Noyal-sur-Vilaine", "value": "C27261"},
              {"label": "Orgeres", "value": "C27262"},
              {"label": "Osse", "value": "C27263"},
              {"label": "Pabu", "value": "C27264"},
              {"label": "Pace", "value": "C27265"},
              {"label": "Paimpol", "value": "C27266"},
              {"label": "Paimpont", "value": "C27267"},
              {"label": "Pance", "value": "C27268"},
              {"label": "Parigne", "value": "C27269"},
              {"label": "Peaule", "value": "C27270"},
              {"label": "Pedernec", "value": "C27271"},
              {"label": "Peillac", "value": "C27272"},
              {"label": "Pencran", "value": "C27273"},
              {"label": "Penestin", "value": "C27274"},
              {"label": "Penvenan", "value": "C27275"},
              {"label": "Perros-Guirec", "value": "C27276"},
              {"label": "Pipriac", "value": "C27277"},
              {"label": "Pire-sur-Seiche", "value": "C27278"},
              {"label": "Plabennec", "value": "C27279"},
              {"label": "Plaine-Haute", "value": "C27280"},
              {"label": "Plaintel", "value": "C27281"},
              {"label": "Plancoet", "value": "C27282"},
              {"label": "Planguenoual", "value": "C27283"},
              {"label": "Plaudren", "value": "C27284"},
              {"label": "Plechatel", "value": "C27285"},
              {"label": "Pledeliac", "value": "C27286"},
              {"label": "Pledran", "value": "C27287"},
              {"label": "Pleguien", "value": "C27288"},
              {"label": "Plehedel", "value": "C27289"},
              {"label": "Pleine-Fougeres", "value": "C27290"},
              {"label": "Plelan-le-Grand", "value": "C27291"},
              {"label": "Plelan-le-Petit", "value": "C27292"},
              {"label": "Plelo", "value": "C27293"},
              {"label": "Plemet", "value": "C27294"},
              {"label": "Plemy", "value": "C27295"},
              {"label": "Plenee-Jugon", "value": "C27296"},
              {"label": "Pleneuf-Val-Andre", "value": "C27297"},
              {"label": "Plerguer", "value": "C27298"},
              {"label": "Plerin", "value": "C27299"},
              {"label": "Plescop", "value": "C27300"},
              {"label": "Pleslin-Trigavou", "value": "C27301"},
              {"label": "Plessala", "value": "C27302"},
              {"label": "Plestan", "value": "C27303"},
              {"label": "Plestin-les-Greves", "value": "C27304"},
              {"label": "Pleubian", "value": "C27305"},
              {"label": "Pleucadeuc", "value": "C27306"},
              {"label": "Pleudaniel", "value": "C27307"},
              {"label": "Pleudihen-sur-Rance", "value": "C27308"},
              {"label": "Pleugriffet", "value": "C27309"},
              {"label": "Pleugueneuc", "value": "C27310"},
              {"label": "Pleumeleuc", "value": "C27311"},
              {"label": "Pleumeur-Bodou", "value": "C27312"},
              {"label": "Pleumeur-Gautier", "value": "C27313"},
              {"label": "Pleurtuit", "value": "C27314"},
              {"label": "Pleuven", "value": "C27315"},
              {"label": "Pleyben", "value": "C27316"},
              {"label": "Pleyber-Christ", "value": "C27317"},
              {"label": "Plobannalec-Lesconil", "value": "C27318"},
              {"label": "Ploemel", "value": "C27319"},
              {"label": "Ploemeur", "value": "C27320"},
              {"label": "Ploerdut", "value": "C27321"},
              {"label": "Ploeren", "value": "C27322"},
              {"label": "Ploermel", "value": "C27323"},
              {"label": "Ploeuc-sur-Lie", "value": "C27324"},
              {"label": "Ploezal", "value": "C27325"},
              {"label": "Plogastel-Saint-Germain", "value": "C27326"},
              {"label": "Plogoff", "value": "C27327"},
              {"label": "Plogonnec", "value": "C27328"},
              {"label": "Plomelin", "value": "C27329"},
              {"label": "Plomeur", "value": "C27330"},
              {"label": "Plomodiern", "value": "C27331"},
              {"label": "Ploneis", "value": "C27332"},
              {"label": "Ploneour-Lanvern", "value": "C27333"},
              {"label": "Plonevez-du-Faou", "value": "C27334"},
              {"label": "Plonevez-Porzay", "value": "C27335"},
              {"label": "Plouagat", "value": "C27336"},
              {"label": "Plouaret", "value": "C27337"},
              {"label": "Plouarzel", "value": "C27338"},
              {"label": "Plouasne", "value": "C27339"},
              {"label": "Plouay", "value": "C27340"},
              {"label": "Ploubalay", "value": "C27341"},
              {"label": "Ploubazlanec", "value": "C27342"},
              {"label": "Ploubezre", "value": "C27343"},
              {"label": "Ploudalmezeau", "value": "C27344"},
              {"label": "Ploudaniel", "value": "C27345"},
              {"label": "Plouec-du-Trieux", "value": "C27346"},
              {"label": "Plouedern", "value": "C27347"},
              {"label": "Plouenan", "value": "C27348"},
              {"label": "Plouer-sur-Rance", "value": "C27349"},
              {"label": "Plouescat", "value": "C27350"},
              {"label": "Plouezec", "value": "C27351"},
              {"label": "Plouezoch", "value": "C27352"},
              {"label": "Ploufragan", "value": "C27353"},
              {"label": "Plougasnou", "value": "C27354"},
              {"label": "Plougastel-Daoulas", "value": "C27355"},
              {"label": "Plougonvelin", "value": "C27356"},
              {"label": "Plougonven", "value": "C27357"},
              {"label": "Plougoulm", "value": "C27358"},
              {"label": "Plougoumelen", "value": "C27359"},
              {"label": "Plougourvest", "value": "C27360"},
              {"label": "Plougrescant", "value": "C27361"},
              {"label": "Plouguenast", "value": "C27362"},
              {"label": "Plouguerneau", "value": "C27363"},
              {"label": "Plouguernevel", "value": "C27364"},
              {"label": "Plouguiel", "value": "C27365"},
              {"label": "Plouguin", "value": "C27366"},
              {"label": "Plouha", "value": "C27367"},
              {"label": "Plouharnel", "value": "C27368"},
              {"label": "Plouhinec", "value": "C27369"},
              {"label": "Plouider", "value": "C27370"},
              {"label": "Plouigneau", "value": "C27371"},
              {"label": "Plouisy", "value": "C27372"},
              {"label": "Ploumagoar", "value": "C27373"},
              {"label": "Ploumilliau", "value": "C27374"},
              {"label": "Ploumoguer", "value": "C27375"},
              {"label": "Plouneour-Menez", "value": "C27376"},
              {"label": "Plouneour-Trez", "value": "C27377"},
              {"label": "Plouneventer", "value": "C27378"},
              {"label": "Plounevez-Lochrist", "value": "C27379"},
              {"label": "Plounevez-Moedec", "value": "C27380"},
              {"label": "Plounevez-Quintin", "value": "C27381"},
              {"label": "Plounevezel", "value": "C27382"},
              {"label": "Plouray", "value": "C27383"},
              {"label": "Plourin-les-Morlaix", "value": "C27384"},
              {"label": "Plourivo", "value": "C27385"},
              {"label": "Plouvien", "value": "C27386"},
              {"label": "Plouvorn", "value": "C27387"},
              {"label": "Plouzane", "value": "C27388"},
              {"label": "Plouzevede", "value": "C27389"},
              {"label": "Plozevet", "value": "C27390"},
              {"label": "Pluduno", "value": "C27391"},
              {"label": "Pluguffan", "value": "C27392"},
              {"label": "Pluherlin", "value": "C27393"},
              {"label": "Plumaugat", "value": "C27394"},
              {"label": "Plumelec", "value": "C27395"},
              {"label": "Plumeliau", "value": "C27396"},
              {"label": "Plumelin", "value": "C27397"},
              {"label": "Plumergat", "value": "C27398"},
              {"label": "Plumieux", "value": "C27399"},
              {"label": "Pluneret", "value": "C27400"},
              {"label": "Plurien", "value": "C27401"},
              {"label": "Pluvigner", "value": "C27402"},
              {"label": "Pluzunet", "value": "C27403"},
              {"label": "Poce-les-Bois", "value": "C27404"},
              {"label": "Pommeret", "value": "C27405"},
              {"label": "Pommerit-Jaudy", "value": "C27406"},
              {"label": "Pommerit-le-Vicomte", "value": "C27407"},
              {"label": "Pont-Aven", "value": "C27408"},
              {"label": "Pont-Croix", "value": "C27409"},
              {"label": "Pont-lAbbe", "value": "C27410"},
              {"label": "Pont-Scorff", "value": "C27411"},
              {"label": "Pontivy", "value": "C27412"},
              {"label": "Pontrieux", "value": "C27413"},
              {"label": "Pordic", "value": "C27414"},
              {"label": "Porspoder", "value": "C27415"},
              {"label": "Port-Louis", "value": "C27416"},
              {"label": "Pouldergat", "value": "C27417"},
              {"label": "Pouldreuzic", "value": "C27418"},
              {"label": "Poullan-sur-Mer", "value": "C27419"},
              {"label": "Poullaouen", "value": "C27420"},
              {"label": "Prat", "value": "C27421"},
              {"label": "Priziac", "value": "C27422"},
              {"label": "Quebriac", "value": "C27423"},
              {"label": "Quedillac", "value": "C27424"},
              {"label": "Quemeneven", "value": "C27425"},
              {"label": "Quemper-Guezennec", "value": "C27426"},
              {"label": "Querrien", "value": "C27427"},
              {"label": "Quessoy", "value": "C27428"},
              {"label": "Questembert", "value": "C27429"},
              {"label": "Queven", "value": "C27430"},
              {"label": "Quevert", "value": "C27431"},
              {"label": "Quiberon", "value": "C27432"},
              {"label": "Quimper", "value": "C27433"},
              {"label": "Quimperle", "value": "C27434"},
              {"label": "Quintin", "value": "C27435"},
              {"label": "Quistinic", "value": "C27436"},
              {"label": "Rannee", "value": "C27437"},
              {"label": "Redene", "value": "C27438"},
              {"label": "Redon", "value": "C27439"},
              {"label": "Reguiny", "value": "C27440"},
              {"label": "Rennes", "value": "C27441"},
              {"label": "Retiers", "value": "C27442"},
              {"label": "Riantec", "value": "C27443"},
              {"label": "Riec-sur-Belon", "value": "C27444"},
              {"label": "Rieux", "value": "C27445"},
              {"label": "Rohan", "value": "C27446"},
              {"label": "Romagne", "value": "C27447"},
              {"label": "Romille", "value": "C27448"},
              {"label": "Roscanvel", "value": "C27449"},
              {"label": "Roscoff", "value": "C27450"},
              {"label": "Rospez", "value": "C27451"},
              {"label": "Rosporden", "value": "C27452"},
              {"label": "Rostrenen", "value": "C27453"},
              {"label": "Roz-Landrieux", "value": "C27454"},
              {"label": "Roz-sur-Couesnon", "value": "C27455"},
              {"label": "Ruffiac", "value": "C27456"},
              {"label": "Saint-Agathon", "value": "C27457"},
              {"label": "Saint-Alban", "value": "C27458"},
              {"label": "Saint-Armel", "value": "C27459"},
              {"label": "Saint-Aubin-du-Cormier", "value": "C27460"},
              {"label": "Saint-Ave", "value": "C27461"},
              {"label": "Saint-Barnabe", "value": "C27462"},
              {"label": "Saint-Brandan", "value": "C27463"},
              {"label": "Saint-Briac-sur-Mer", "value": "C27464"},
              {"label": "Saint-Brice-en-Cogles", "value": "C27465"},
              {"label": "Saint-Brieuc", "value": "C27466"},
              {"label": "Saint-Broladre", "value": "C27467"},
              {"label": "Saint-Caradec", "value": "C27468"},
              {"label": "Saint-Carreuc", "value": "C27469"},
              {"label": "Saint-Cast-le-Guildo", "value": "C27470"},
              {"label": "Saint-Coulomb", "value": "C27471"},
              {"label": "Saint-Didier", "value": "C27472"},
              {"label": "Saint-Dolay", "value": "C27473"},
              {"label": "Saint-Domineuc", "value": "C27474"},
              {"label": "Saint-Donan", "value": "C27475"},
              {"label": "Saint-Erblon", "value": "C27476"},
              {"label": "Saint-etienne-en-Cogles", "value": "C27477"},
              {"label": "Saint-evarzec", "value": "C27478"},
              {"label": "Saint-Georges-de-Reintembault", "value": "C27479"},
              {"label": "Saint-Germain-en-Cogles", "value": "C27480"},
              {"label": "Saint-Gildas-de-Rhuys", "value": "C27481"},
              {"label": "Saint-Gilles", "value": "C27482"},
              {"label": "Saint-Gonnery", "value": "C27483"},
              {"label": "Saint-Gregoire", "value": "C27484"},
              {"label": "Saint-Helen", "value": "C27485"},
              {"label": "Saint-Jacques-de-la-Lande", "value": "C27486"},
              {"label": "Saint-Jacut-les-Pins", "value": "C27487"},
              {"label": "Saint-Jean-Brevelay", "value": "C27488"},
              {"label": "Saint-Jean-la-Poterie", "value": "C27489"},
              {"label": "Saint-Jean-sur-Couesnon", "value": "C27490"},
              {"label": "Saint-Jouan-des-Guerets", "value": "C27491"},
              {"label": "Saint-Julien", "value": "C27492"},
              {"label": "Saint-Just", "value": "C27493"},
              {"label": "Saint-Lunaire", "value": "C27494"},
              {"label": "Saint-Malo", "value": "C27495"},
              {"label": "Saint-Marc-le-Blanc", "value": "C27496"},
              {"label": "Saint-Martin-des-Champs", "value": "C27497"},
              {"label": "Saint-Martin-sur-Oust", "value": "C27498"},
              {"label": "Saint-Medard-sur-Ille", "value": "C27499"},
              {"label": "Saint-Meen-le-Grand", "value": "C27500"},
              {"label": "Saint-Meloir-des-Ondes", "value": "C27501"},
              {"label": "Saint-Nicolas-du-Pelem", "value": "C27502"},
              {"label": "Saint-Nolff", "value": "C27503"},
              {"label": "Saint-Ouen-des-Alleux", "value": "C27504"},
              {"label": "Saint-Pabu", "value": "C27505"},
              {"label": "Saint-Pere", "value": "C27506"},
              {"label": "Saint-Perreux", "value": "C27507"},
              {"label": "Saint-Philibert", "value": "C27508"},
              {"label": "Saint-Pierre-de-Plesguen", "value": "C27509"},
              {"label": "Saint-Pierre-Quiberon", "value": "C27510"},
              {"label": "Saint-Pol-de-Leon", "value": "C27511"},
              {"label": "Saint-Quay-Perros", "value": "C27512"},
              {"label": "Saint-Quay-Portrieux", "value": "C27513"},
              {"label": "Saint-Renan", "value": "C27514"},
              {"label": "Saint-Samson-sur-Rance", "value": "C27515"},
              {"label": "Saint-Sauveur-des-Landes", "value": "C27516"},
              {"label": "Saint-Senoux", "value": "C27517"},
              {"label": "Saint-Sulpice-la-Foret", "value": "C27518"},
              {"label": "Saint-Thegonnec", "value": "C27519"},
              {"label": "Saint-Thonan", "value": "C27520"},
              {"label": "Saint-Thurial", "value": "C27521"},
              {"label": "Saint-Thuriau", "value": "C27522"},
              {"label": "Saint-Urbain", "value": "C27523"},
              {"label": "Saint-Vincent-sur-Oust", "value": "C27524"},
              {"label": "Saint-Yvi", "value": "C27525"},
              {"label": "Sainte-Anne-dAuray", "value": "C27526"},
              {"label": "Sainte-Helene", "value": "C27527"},
              {"label": "Sainte-Marie", "value": "C27528"},
              {"label": "Santec", "value": "C27529"},
              {"label": "Sarzeau", "value": "C27530"},
              {"label": "Scaer", "value": "C27531"},
              {"label": "Sene", "value": "C27532"},
              {"label": "Sens-de-Bretagne", "value": "C27533"},
              {"label": "Serent", "value": "C27534"},
              {"label": "Servon-sur-Vilaine", "value": "C27535"},
              {"label": "Sevignac", "value": "C27536"},
              {"label": "Sibiril", "value": "C27537"},
              {"label": "Sixt-sur-Aff", "value": "C27538"},
              {"label": "Sizun", "value": "C27539"},
              {"label": "Spezet", "value": "C27540"},
              {"label": "Sulniac", "value": "C27541"},
              {"label": "Surzur", "value": "C27542"},
              {"label": "Taden", "value": "C27543"},
              {"label": "Talensac", "value": "C27544"},
              {"label": "Taule", "value": "C27545"},
              {"label": "Taupont", "value": "C27546"},
              {"label": "Telgruc-sur-Mer", "value": "C27547"},
              {"label": "Theix", "value": "C27548"},
              {"label": "Tinteniac", "value": "C27549"},
              {"label": "Tonquedec", "value": "C27550"},
              {"label": "Torce", "value": "C27551"},
              {"label": "Trebeurden", "value": "C27552"},
              {"label": "Tredarzec", "value": "C27553"},
              {"label": "Tredrez-Locquemeau", "value": "C27554"},
              {"label": "Treffiagat", "value": "C27555"},
              {"label": "Trefflean", "value": "C27556"},
              {"label": "Tregastel", "value": "C27557"},
              {"label": "Treguier", "value": "C27558"},
              {"label": "Tregunc", "value": "C27559"},
              {"label": "Trelevern", "value": "C27560"},
              {"label": "Trelivan", "value": "C27561"},
              {"label": "Tremblay", "value": "C27562"},
              {"label": "Tremeven", "value": "C27563"},
              {"label": "Tremuson", "value": "C27564"},
              {"label": "Tresboeuf", "value": "C27565"},
              {"label": "Treve", "value": "C27566"},
              {"label": "Trevou-Treguignec", "value": "C27567"},
              {"label": "Vannes", "value": "C27568"},
              {"label": "Vern-sur-Seiche", "value": "C27569"},
              {"label": "Vezin-le-Coquet", "value": "C27570"},
              {"label": "Vignoc", "value": "C27571"},
              {"label": "Vitre", "value": "C27572"},
              {"label": "Yffiniac", "value": "C27573"},
              {"label": "Yvignac-la-Tour", "value": "C27574"}],
 "Brezice Municipality": [{"label": "Brezice", "value": "C107586"},
                          {"label": "Poljcane", "value": "C107587"}],
 "Brezovica Municipality": [{"label": "Brezovica pri Ljubljani",
                             "value": "C107588"},
                            {"label": "Notranje Gorice", "value": "C107589"},
                            {"label": "Opstina Ljubljana-Vic-Rudnik",
                             "value": "C107590"},
                            {"label": "Recica ob Savinji", "value": "C107591"},
                            {"label": "Vnanje Gorice", "value": "C107592"}],
 "Briceni District": [{"label": "Briceni", "value": "C75489"}],
 "British Columbia": [{"label": "Abbotsford", "value": "C16819"},
                      {"label": "Agassiz", "value": "C16820"},
                      {"label": "Aldergrove", "value": "C16821"},
                      {"label": "Aldergrove East", "value": "C16822"},
                      {"label": "Anmore", "value": "C16823"},
                      {"label": "Arbutus Ridge", "value": "C16824"},
                      {"label": "Armstrong", "value": "C16825"},
                      {"label": "Ashcroft", "value": "C16826"},
                      {"label": "Barriere", "value": "C16827"},
                      {"label": "Bowen Island", "value": "C16828"},
                      {"label": "Burnaby", "value": "C16829"},
                      {"label": "Burns Lake", "value": "C16830"},
                      {"label": "Cache Creek", "value": "C16831"},
                      {"label": "Campbell River", "value": "C16832"},
                      {"label": "Castlegar", "value": "C16833"},
                      {"label": "Cedar", "value": "C16834"},
                      {"label": "Central Coast Regional District",
                       "value": "C16835"},
                      {"label": "Chase", "value": "C16836"},
                      {"label": "Chemainus", "value": "C16837"},
                      {"label": "Chetwynd", "value": "C16838"},
                      {"label": "Chilliwack", "value": "C16839"},
                      {"label": "Colwood", "value": "C16840"},
                      {"label": "Coombs", "value": "C16841"},
                      {"label": "Coquitlam", "value": "C16842"},
                      {"label": "Courtenay", "value": "C16843"},
                      {"label": "Cowichan Bay", "value": "C16844"},
                      {"label": "Cranbrook", "value": "C16845"},
                      {"label": "Creston", "value": "C16846"},
                      {"label": "Cumberland", "value": "C16847"},
                      {"label": "Dawson Creek", "value": "C16848"},
                      {"label": "Delta", "value": "C16849"},
                      {"label": "Denman Island", "value": "C16850"},
                      {"label": "Denman Island Trust Area", "value": "C16851"},
                      {"label": "Duck Lake", "value": "C16852"},
                      {"label": "Duncan", "value": "C16853"},
                      {"label": "East Wellington", "value": "C16854"},
                      {"label": "Elkford", "value": "C16855"},
                      {"label": "Ellison", "value": "C16856"},
                      {"label": "Enderby", "value": "C16857"},
                      {"label": "Fairwinds", "value": "C16858"},
                      {"label": "Fernie", "value": "C16859"},
                      {"label": "Fort Nelson", "value": "C16860"},
                      {"label": "Fort St. John", "value": "C16861"},
                      {"label": "Fraser Valley Regional District",
                       "value": "C16862"},
                      {"label": "French Creek", "value": "C16863"},
                      {"label": "Fruitvale", "value": "C16864"},
                      {"label": "Gibsons", "value": "C16865"},
                      {"label": "Golden", "value": "C16866"},
                      {"label": "Grand Forks", "value": "C16867"},
                      {"label": "Hanceville", "value": "C16868"},
                      {"label": "Hope", "value": "C16869"},
                      {"label": "Hornby Island", "value": "C16870"},
                      {"label": "Houston", "value": "C16871"},
                      {"label": "Invermere", "value": "C16872"},
                      {"label": "Kamloops", "value": "C16873"},
                      {"label": "Kelowna", "value": "C16874"},
                      {"label": "Kimberley", "value": "C16875"},
                      {"label": "Kitimat", "value": "C16876"},
                      {"label": "Ladner", "value": "C16877"},
                      {"label": "Ladysmith", "value": "C16878"},
                      {"label": "Lake Cowichan", "value": "C16879"},
                      {"label": "Langford", "value": "C16880"},
                      {"label": "Langley", "value": "C16881"},
                      {"label": "Lillooet", "value": "C16882"},
                      {"label": "Lions Bay", "value": "C16883"},
                      {"label": "Logan Lake", "value": "C16884"},
                      {"label": "Lumby", "value": "C16885"},
                      {"label": "Mackenzie", "value": "C16886"},
                      {"label": "Maple Ridge", "value": "C16887"},
                      {"label": "Merritt", "value": "C16888"},
                      {"label": "Metchosin", "value": "C16889"},
                      {"label": "Metro Vancouver Regional District",
                       "value": "C16890"},
                      {"label": "Mission", "value": "C16891"},
                      {"label": "Nakusp", "value": "C16892"},
                      {"label": "Nanaimo", "value": "C16893"},
                      {"label": "Nelson", "value": "C16894"},
                      {"label": "New Westminster", "value": "C16895"},
                      {"label": "North Cowichan", "value": "C16896"},
                      {"label": "North OysterYellow Point", "value": "C16897"},
                      {"label": "North Saanich", "value": "C16898"},
                      {"label": "North Vancouver", "value": "C16899"},
                      {"label": "Oak Bay", "value": "C16900"},
                      {"label": "Okanagan", "value": "C16901"},
                      {"label": "Okanagan Falls", "value": "C16902"},
                      {"label": "Oliver", "value": "C16903"},
                      {"label": "Osoyoos", "value": "C16904"},
                      {"label": "Parksville", "value": "C16905"},
                      {"label": "Peace River Regional District",
                       "value": "C16906"},
                      {"label": "Peachland", "value": "C16907"},
                      {"label": "Pemberton", "value": "C16908"},
                      {"label": "Penticton", "value": "C16909"},
                      {"label": "Pitt Meadows", "value": "C16910"},
                      {"label": "Port Alberni", "value": "C16911"},
                      {"label": "Port Coquitlam", "value": "C16912"},
                      {"label": "Port McNeill", "value": "C16913"},
                      {"label": "Port Moody", "value": "C16914"},
                      {"label": "Powell River", "value": "C16915"},
                      {"label": "Prince George", "value": "C16916"},
                      {"label": "Prince Rupert", "value": "C16917"},
                      {"label": "Princeton", "value": "C16918"},
                      {"label": "Puntledge", "value": "C16919"},
                      {"label": "Quesnel", "value": "C16920"},
                      {"label": "Regional District of Alberni-Clayoquot",
                       "value": "C16921"},
                      {"label": "Regional District of Central Okanagan",
                       "value": "C16922"},
                      {"label": "Revelstoke", "value": "C16923"},
                      {"label": "Richmond", "value": "C16924"},
                      {"label": "Rossland", "value": "C16925"},
                      {"label": "Royston", "value": "C16926"},
                      {"label": "Salmo", "value": "C16927"},
                      {"label": "Salmon Arm", "value": "C16928"},
                      {"label": "Salt Spring Island", "value": "C16929"},
                      {"label": "Saltair", "value": "C16930"},
                      {"label": "Sechelt", "value": "C16931"},
                      {"label": "Sicamous", "value": "C16932"},
                      {"label": "Six Mile", "value": "C16933"},
                      {"label": "Smithers", "value": "C16934"},
                      {"label": "Sooke", "value": "C16935"},
                      {"label": "South Pender Harbour", "value": "C16936"},
                      {"label": "Sparwood", "value": "C16937"},
                      {"label": "Summerland", "value": "C16938"},
                      {"label": "Surrey", "value": "C16939"},
                      {"label": "Terrace", "value": "C16940"},
                      {"label": "Tofino", "value": "C16941"},
                      {"label": "Trail", "value": "C16942"},
                      {"label": "Tsawwassen", "value": "C16943"},
                      {"label": "Tumbler Ridge", "value": "C16944"},
                      {"label": "Ucluelet", "value": "C16945"},
                      {"label": "Vancouver", "value": "C16946"},
                      {"label": "Vanderhoof", "value": "C16947"},
                      {"label": "Vernon", "value": "C16948"},
                      {"label": "Victoria", "value": "C16949"},
                      {"label": "Walnut Grove", "value": "C16950"},
                      {"label": "Welcome Beach", "value": "C16951"},
                      {"label": "West End", "value": "C16952"},
                      {"label": "West Kelowna", "value": "C16953"},
                      {"label": "West Vancouver", "value": "C16954"},
                      {"label": "Whistler", "value": "C16955"},
                      {"label": "White Rock", "value": "C16956"},
                      {"label": "Williams Lake", "value": "C16957"}],
 "Broceni Municipality": [{"label": "Broceni", "value": "C64855"}],
 "Brod-Posavina": [{"label": "Batrina", "value": "C21034"},
                   {"label": "Brodski Varos", "value": "C21035"},
                   {"label": "Bukovlje", "value": "C21036"},
                   {"label": "Cernik", "value": "C21037"},
                   {"label": "Davor", "value": "C21038"},
                   {"label": "Donji Andrijevci", "value": "C21039"},
                   {"label": "Garcin", "value": "C21040"},
                   {"label": "Gornji Bogicevci", "value": "C21041"},
                   {"label": "Grad Nova Gradiska", "value": "C21042"},
                   {"label": "Grad Slavonski Brod", "value": "C21043"},
                   {"label": "Gundinci", "value": "C21044"},
                   {"label": "Korenica", "value": "C21045"},
                   {"label": "Krusevica", "value": "C21046"},
                   {"label": "Luzani", "value": "C21047"},
                   {"label": "Nova Gradiska", "value": "C21048"},
                   {"label": "Okucani", "value": "C21049"},
                   {"label": "Oprisavci", "value": "C21050"},
                   {"label": "Oriovac", "value": "C21051"},
                   {"label": "Podvinje", "value": "C21052"},
                   {"label": "Resetari", "value": "C21053"},
                   {"label": "Ruscica", "value": "C21054"},
                   {"label": "Sibinj", "value": "C21055"},
                   {"label": "Sikirevci", "value": "C21056"},
                   {"label": "Slavonski Brod", "value": "C21057"},
                   {"label": "Slobodnica", "value": "C21058"},
                   {"label": "Stari Perkovci", "value": "C21059"},
                   {"label": "Velika Kopanica", "value": "C21060"},
                   {"label": "Vrpolje", "value": "C21061"}],
 "Brokopondo District": [{"label": "Brokopondo", "value": "C115460"},
                         {"label": "Brownsweg", "value": "C115461"}],
 "Brunei-Muara District": [{"label": "Bandar Seri Begawan", "value": "C15839"},
                           {"label": "Berakas A", "value": "C15840"},
                           {"label": "Kapok", "value": "C15841"},
                           {"label": "Mentiri", "value": "C15842"},
                           {"label": "Serasa", "value": "C15843"}],
 "Brussels-Capital Region": [{"label": "Brussels", "value": "C9031"}],
 "Brvenica Municipality": [{"label": "Brvenica", "value": "C65375"},
                           {"label": "Celopek", "value": "C65376"},
                           {"label": "Gurgurnica", "value": "C65377"},
                           {"label": "Miletino", "value": "C65378"}],
 "Bryansk Oblast": [{"label": "Altukhovo", "value": "C100568"},
                    {"label": "Ardon", "value": "C100569"},
                    {"label": "Belyye Berega", "value": "C100570"},
                    {"label": "Brasovskiy Rayon", "value": "C100571"},
                    {"label": "Bryansk", "value": "C100572"},
                    {"label": "Bryanskiy Rayon", "value": "C100573"},
                    {"label": "Bytosh", "value": "C100574"},
                    {"label": "Churovichi", "value": "C100575"},
                    {"label": "Dobrun", "value": "C100576"},
                    {"label": "Dubrovka", "value": "C100577"},
                    {"label": "Dubrovskiy Rayon", "value": "C100578"},
                    {"label": "Dyatkovo", "value": "C100579"},
                    {"label": "Dyatkovskiy Rayon", "value": "C100580"},
                    {"label": "Glinishchevo", "value": "C100581"},
                    {"label": "Gordeyevka", "value": "C100582"},
                    {"label": "Gordeyevskiy Rayon", "value": "C100583"},
                    {"label": "Gorod Bryansk", "value": "C100584"},
                    {"label": "Gorod Dyatkovo", "value": "C100585"},
                    {"label": "Ivanovka", "value": "C100586"},
                    {"label": "Ivot", "value": "C100587"},
                    {"label": "Karachev", "value": "C100588"},
                    {"label": "Kletnya", "value": "C100589"},
                    {"label": "Kletnyanskiy Rayon", "value": "C100590"},
                    {"label": "Klimovo", "value": "C100591"},
                    {"label": "Klimovskiy Rayon", "value": "C100592"},
                    {"label": "Klintsovskiy Rayon", "value": "C100593"},
                    {"label": "Klintsy", "value": "C100594"},
                    {"label": "Kokino", "value": "C100595"},
                    {"label": "Kokorevka", "value": "C100596"},
                    {"label": "Komarichi", "value": "C100597"},
                    {"label": "Komarichskiy Rayon", "value": "C100598"},
                    {"label": "Korzhovka-Golubovka", "value": "C100599"},
                    {"label": "Krasnaya Gora", "value": "C100600"},
                    {"label": "Krasnogorskiy Rayon", "value": "C100601"},
                    {"label": "Lokot", "value": "C100602"},
                    {"label": "Lopandino", "value": "C100603"},
                    {"label": "Lyubokhna", "value": "C100604"},
                    {"label": "Lyubovsho", "value": "C100605"},
                    {"label": "Mglin", "value": "C100606"},
                    {"label": "Mglinskiy Rayon", "value": "C100607"},
                    {"label": "Navlinskiy Rayon", "value": "C100608"},
                    {"label": "Navlya", "value": "C100609"},
                    {"label": "Netinka", "value": "C100610"},
                    {"label": "Novozybkov", "value": "C100611"},
                    {"label": "Novozybkovskiy Rayon", "value": "C100612"},
                    {"label": "Novyy Ropsk", "value": "C100613"},
                    {"label": "Pochep", "value": "C100614"},
                    {"label": "Pochepskiy Rayon", "value": "C100615"},
                    {"label": "Pogar", "value": "C100616"},
                    {"label": "Pogarskiy Rayon", "value": "C100617"},
                    {"label": "Putevka", "value": "C100618"},
                    {"label": "Raditsa-Krylovka", "value": "C100619"},
                    {"label": "Rognedino", "value": "C100620"},
                    {"label": "Rognedinskiy Rayon", "value": "C100621"},
                    {"label": "Rzhanitsa", "value": "C100622"},
                    {"label": "Sachkovichi", "value": "C100623"},
                    {"label": "Seltso", "value": "C100624"},
                    {"label": "Seshcha", "value": "C100625"},
                    {"label": "Sevsk", "value": "C100626"},
                    {"label": "Sevskiy Rayon", "value": "C100627"},
                    {"label": "Star", "value": "C100628"},
                    {"label": "Starodub", "value": "C100629"},
                    {"label": "Starodubskiy Rayon", "value": "C100630"},
                    {"label": "Suponevo", "value": "C100631"},
                    {"label": "Surazh", "value": "C100632"},
                    {"label": "Surazhskiy Rayon", "value": "C100633"},
                    {"label": "Suzemka", "value": "C100634"},
                    {"label": "Suzemskiy Rayon", "value": "C100635"},
                    {"label": "Sven", "value": "C100636"},
                    {"label": "Trubchevsk", "value": "C100637"},
                    {"label": "Trubchevskiy Rayon", "value": "C100638"},
                    {"label": "Unecha", "value": "C100639"},
                    {"label": "Unechskiy Rayon", "value": "C100640"},
                    {"label": "Vygonichi", "value": "C100641"},
                    {"label": "Vygonichskiy Rayon", "value": "C100642"},
                    {"label": "Vyshkov", "value": "C100643"},
                    {"label": "Zamishevo", "value": "C100644"},
                    {"label": "Zaymishche", "value": "C100645"},
                    {"label": "Zhiryatino", "value": "C100646"},
                    {"label": "Zhiryatinskiy Rayon", "value": "C100647"},
                    {"label": "Zhukovka", "value": "C100648"},
                    {"label": "Zhukovskiy Rayon", "value": "C100649"},
                    {"label": "Zlynka", "value": "C100650"}],
 "Bubanza Province": [{"label": "Bubanza", "value": "C16442"}],
 "Bucharest": [{"label": "Bucharest", "value": "C93792"},
               {"label": "Sector 1", "value": "C93793"},
               {"label": "Sector 2", "value": "C93794"},
               {"label": "Sector 3", "value": "C93795"},
               {"label": "Sector 4", "value": "C93796"},
               {"label": "Sector 5", "value": "C93797"},
               {"label": "Sector 6", "value": "C93798"}],
 "Budapest": [{"label": "Budapest", "value": "C43993"},
              {"label": "Budapest I. kerulet", "value": "C43994"},
              {"label": "Budapest II. kerulet", "value": "C43995"},
              {"label": "Budapest III. kerulet", "value": "C43996"},
              {"label": "Budapest IV. kerulet", "value": "C43997"},
              {"label": "Budapest VI. kerulet", "value": "C43998"},
              {"label": "Budapest VIII. kerulet", "value": "C43999"},
              {"label": "Budapest X. kerulet", "value": "C44000"},
              {"label": "Budapest XI. kerulet", "value": "C44001"},
              {"label": "Budapest XII. kerulet", "value": "C44002"},
              {"label": "Budapest XIII. kerulet", "value": "C44003"},
              {"label": "Budapest XV. kerulet", "value": "C44004"},
              {"label": "Budapest XVI. kerulet", "value": "C44005"},
              {"label": "Budapest XVII. kerulet", "value": "C44006"},
              {"label": "Budapest XVIII. kerulet", "value": "C44007"},
              {"label": "Budapest XX. kerulet", "value": "C44008"},
              {"label": "Budapest XXI. kerulet", "value": "C44009"},
              {"label": "Budapest XXII. kerulet", "value": "C44010"},
              {"label": "Budapest XXIII. kerulet", "value": "C44011"},
              {"label": "Erzsebetvaros", "value": "C44012"},
              {"label": "Jozsefvaros", "value": "C44013"},
              {"label": "Kispest", "value": "C44014"},
              {"label": "Zuglo", "value": "C44015"}],
 "Budva Municipality": [{"label": "Budva", "value": "C75601"},
                        {"label": "Petrovac na Moru", "value": "C75602"}],
 "Bueng Kan": [{"label": "Amphoe Bueng Khong Long", "value": "C118434"},
               {"label": "Amphoe Bung Khla", "value": "C118435"},
               {"label": "Amphoe Mueang Bueng Kan", "value": "C118436"},
               {"label": "Amphoe Pak Khat", "value": "C118437"},
               {"label": "Amphoe Phon Charoen", "value": "C118438"},
               {"label": "Amphoe Seka", "value": "C118439"},
               {"label": "Amphoe Si Wilai", "value": "C118440"},
               {"label": "Amphoe So Phisai", "value": "C118441"},
               {"label": "Bueng Kan", "value": "C118442"},
               {"label": "Seka", "value": "C118443"}],
 "Buenos Aires": [{"label": "Balvanera", "value": "C583"},
                  {"label": "Barracas", "value": "C584"},
                  {"label": "Belgrano", "value": "C585"},
                  {"label": "Boedo", "value": "C586"},
                  {"label": "Buenos Aires", "value": "C587"},
                  {"label": "Colegiales", "value": "C588"},
                  {"label": "Retiro", "value": "C589"},
                  {"label": "Villa Lugano", "value": "C590"},
                  {"label": "Villa Ortuzar", "value": "C591"},
                  {"label": "Villa Santa Rita", "value": "C592"}],
 "Bujumbura Mairie Province": [{"label": "Bujumbura", "value": "C16443"}],
 "Bukhara Region": [{"label": "Bukhara", "value": "C147002"},
                    {"label": "Galaosiyo", "value": "C147003"},
                    {"label": "Gazli", "value": "C147004"},
                    {"label": "Ghijduwon", "value": "C147005"},
                    {"label": "Karakul", "value": "C147006"},
                    {"label": "Kogon", "value": "C147007"},
                    {"label": "Olot", "value": "C147008"},
                    {"label": "Peshku Tumani", "value": "C147009"},
                    {"label": "Qorakol", "value": "C147010"},
                    {"label": "Qorovulbozor", "value": "C147011"},
                    {"label": "Romiton", "value": "C147012"},
                    {"label": "Shofirkon", "value": "C147013"},
                    {"label": "Wobkent", "value": "C147014"},
                    {"label": "Wobkent Tumani", "value": "C147015"}],
 "Bukidnon": [{"label": "Alamada", "value": "C84123"},
              {"label": "Amas", "value": "C84124"},
              {"label": "Bagontapay", "value": "C84125"},
              {"label": "Baguer", "value": "C84126"},
              {"label": "Bagumbayan", "value": "C84127"},
              {"label": "Baliton", "value": "C84128"},
              {"label": "Balogo", "value": "C84129"},
              {"label": "Banawa", "value": "C84130"},
              {"label": "Banga", "value": "C84131"},
              {"label": "Banisilan", "value": "C84132"},
              {"label": "Bantogon", "value": "C84133"},
              {"label": "Barongis", "value": "C84134"},
              {"label": "Basak", "value": "C84135"},
              {"label": "Batasan", "value": "C84136"},
              {"label": "Batutitik", "value": "C84137"},
              {"label": "Bau", "value": "C84138"},
              {"label": "Bayasong", "value": "C84139"},
              {"label": "Bialong", "value": "C84140"},
              {"label": "Biwang", "value": "C84141"},
              {"label": "Blingkong", "value": "C84142"},
              {"label": "Buadtasan", "value": "C84143"},
              {"label": "Bual", "value": "C84144"},
              {"label": "Buayan", "value": "C84145"},
              {"label": "Bucay Pait", "value": "C84146"},
              {"label": "Bulatukan", "value": "C84147"},
              {"label": "Burias", "value": "C84148"},
              {"label": "Busok", "value": "C84149"},
              {"label": "Carmen", "value": "C84150"},
              {"label": "Carpenter", "value": "C84151"},
              {"label": "Cebuano", "value": "C84152"},
              {"label": "City of Kidapawan", "value": "C84153"},
              {"label": "City of Koronadal", "value": "C84154"},
              {"label": "City of Tacurong", "value": "C84155"},
              {"label": "Colongolo", "value": "C84156"},
              {"label": "Columbio", "value": "C84157"},
              {"label": "Cotabato City", "value": "C84158"},
              {"label": "Dahay", "value": "C84159"},
              {"label": "Daliao", "value": "C84160"},
              {"label": "Damawato", "value": "C84161"},
              {"label": "Dansuli", "value": "C84162"},
              {"label": "Datu Montawal", "value": "C84163"},
              {"label": "Dualing", "value": "C84164"},
              {"label": "Dukay", "value": "C84165"},
              {"label": "Dunguan", "value": "C84166"},
              {"label": "Esperanza", "value": "C84167"},
              {"label": "Gansing", "value": "C84168"},
              {"label": "General Santos", "value": "C84169"},
              {"label": "Glad", "value": "C84170"},
              {"label": "Glamang", "value": "C84171"},
              {"label": "Glan", "value": "C84172"},
              {"label": "Glan Peidu", "value": "C84173"},
              {"label": "Gocoton", "value": "C84174"},
              {"label": "Guinsang-an", "value": "C84175"},
              {"label": "Ilaya", "value": "C84176"},
              {"label": "Isulan", "value": "C84177"},
              {"label": "Kabacan", "value": "C84178"},
              {"label": "Kabalen", "value": "C84179"},
              {"label": "Kablalan", "value": "C84180"},
              {"label": "Kalaisan", "value": "C84181"},
              {"label": "Kalamangog", "value": "C84182"},
              {"label": "Kalamansig", "value": "C84183"},
              {"label": "Kamanga", "value": "C84184"},
              {"label": "Kapatan", "value": "C84185"},
              {"label": "Kapaya", "value": "C84186"},
              {"label": "Kapinkong", "value": "C84187"},
              {"label": "Katubao", "value": "C84188"},
              {"label": "Kiamba", "value": "C84189"},
              {"label": "Kidapawan", "value": "C84190"},
              {"label": "Kipalbig", "value": "C84191"},
              {"label": "Kisante", "value": "C84192"},
              {"label": "Kiupo", "value": "C84193"},
              {"label": "Klinan", "value": "C84194"},
              {"label": "Kling", "value": "C84195"},
              {"label": "Koronadal", "value": "C84196"},
              {"label": "Kudanding", "value": "C84197"},
              {"label": "Kulaman", "value": "C84198"},
              {"label": "Labu-o", "value": "C84199"},
              {"label": "Laguilayan", "value": "C84200"},
              {"label": "Lambayong", "value": "C84201"},
              {"label": "Lambo", "value": "C84202"},
              {"label": "Lambontong", "value": "C84203"},
              {"label": "Lamian", "value": "C84204"},
              {"label": "Lampari", "value": "C84205"},
              {"label": "Lampitak", "value": "C84206"},
              {"label": "Lapuz", "value": "C84207"},
              {"label": "Lebak", "value": "C84208"},
              {"label": "Libas", "value": "C84209"},
              {"label": "Liberty", "value": "C84210"},
              {"label": "Libungan", "value": "C84211"},
              {"label": "Liliongan", "value": "C84212"},
              {"label": "Limbalod", "value": "C84213"},
              {"label": "Limulan", "value": "C84214"},
              {"label": "Linan (town)", "value": "C84215"},
              {"label": "Linao", "value": "C84216"},
              {"label": "Lopez Jaena", "value": "C84217"},
              {"label": "Lumatil", "value": "C84218"},
              {"label": "Lumazal", "value": "C84219"},
              {"label": "Lumuyon", "value": "C84220"},
              {"label": "Lun Pequeno", "value": "C84221"},
              {"label": "Lunen", "value": "C84222"},
              {"label": "Lutayan", "value": "C84223"},
              {"label": "Mlang", "value": "C84224"},
              {"label": "Maan", "value": "C84225"},
              {"label": "Maasim", "value": "C84226"},
              {"label": "Mabay", "value": "C84227"},
              {"label": "Mabini", "value": "C84228"},
              {"label": "Magpet", "value": "C84229"},
              {"label": "Maguling", "value": "C84230"},
              {"label": "Maibu", "value": "C84231"},
              {"label": "Maindang", "value": "C84232"},
              {"label": "Maitum", "value": "C84233"},
              {"label": "Makilala", "value": "C84234"},
              {"label": "Malamote", "value": "C84235"},
              {"label": "Malandag", "value": "C84236"},
              {"label": "Malapag", "value": "C84237"},
              {"label": "Malapatan", "value": "C84238"},
              {"label": "Malasila", "value": "C84239"},
              {"label": "Malbang", "value": "C84240"},
              {"label": "Malingao", "value": "C84241"},
              {"label": "Malisbeng", "value": "C84242"},
              {"label": "Malitubog", "value": "C84243"},
              {"label": "Maltana", "value": "C84244"},
              {"label": "Malungon", "value": "C84245"},
              {"label": "Mamali", "value": "C84246"},
              {"label": "Manaulanan", "value": "C84247"},
              {"label": "Manga", "value": "C84248"},
              {"label": "Manuangan", "value": "C84249"},
              {"label": "Manuel Roxas", "value": "C84250"},
              {"label": "Marbel", "value": "C84251"},
              {"label": "Mariano Marcos", "value": "C84252"},
              {"label": "Matalam", "value": "C84253"},
              {"label": "Matinao", "value": "C84254"},
              {"label": "Midsayap", "value": "C84255"},
              {"label": "Minapan", "value": "C84256"},
              {"label": "Mindupok", "value": "C84257"},
              {"label": "Nalus", "value": "C84258"},
              {"label": "New Cebu", "value": "C84259"},
              {"label": "New Iloilo", "value": "C84260"},
              {"label": "New Lagao", "value": "C84261"},
              {"label": "New Panay", "value": "C84262"},
              {"label": "Noling", "value": "C84263"},
              {"label": "Norala", "value": "C84264"},
              {"label": "Nunguan", "value": "C84265"},
              {"label": "Osias", "value": "C84266"},
              {"label": "Paatan", "value": "C84267"},
              {"label": "Pagalungan", "value": "C84268"},
              {"label": "Pagangan", "value": "C84269"},
              {"label": "Palian", "value": "C84270"},
              {"label": "Palimbang", "value": "C84271"},
              {"label": "Palkan", "value": "C84272"},
              {"label": "Pamantingan", "value": "C84273"},
              {"label": "Panay", "value": "C84274"},
              {"label": "Pangyan", "value": "C84275"},
              {"label": "Paraiso", "value": "C84276"},
              {"label": "Patindeguen", "value": "C84277"},
              {"label": "Pedtad", "value": "C84278"},
              {"label": "Pigcawayan", "value": "C84279"},
              {"label": "Pikit", "value": "C84280"},
              {"label": "Pimbalayan", "value": "C84281"},
              {"label": "Polo", "value": "C84282"},
              {"label": "Polomolok", "value": "C84283"},
              {"label": "Polonoling", "value": "C84284"},
              {"label": "President Quirino", "value": "C84285"},
              {"label": "President Roxas", "value": "C84286"},
              {"label": "Puloypuloy", "value": "C84287"},
              {"label": "Punolu", "value": "C84288"},
              {"label": "Puricay", "value": "C84289"},
              {"label": "Ragandang", "value": "C84290"},
              {"label": "Rotunda", "value": "C84291"},
              {"label": "Sadsalan", "value": "C84292"},
              {"label": "Saguing", "value": "C84293"},
              {"label": "Salimbao", "value": "C84294"},
              {"label": "Salunayan", "value": "C84295"},
              {"label": "Sampao", "value": "C84296"},
              {"label": "San Miguel", "value": "C84297"},
              {"label": "San Vicente", "value": "C84298"},
              {"label": "Sangay", "value": "C84299"},
              {"label": "Santo Nino", "value": "C84300"},
              {"label": "Sapad", "value": "C84301"},
              {"label": "Sapu Padidu", "value": "C84302"},
              {"label": "Sebu", "value": "C84303"},
              {"label": "Silway 7", "value": "C84304"},
              {"label": "Sinolon", "value": "C84305"},
              {"label": "Sulit", "value": "C84306"},
              {"label": "Surallah", "value": "C84307"},
              {"label": "Suyan", "value": "C84308"},
              {"label": "Tboli", "value": "C84309"},
              {"label": "Tacurong", "value": "C84310"},
              {"label": "Taguisa", "value": "C84311"},
              {"label": "Taluya", "value": "C84312"},
              {"label": "Tambak", "value": "C84313"},
              {"label": "Tambilil", "value": "C84314"},
              {"label": "Tamnag", "value": "C84315"},
              {"label": "Tampakan", "value": "C84316"},
              {"label": "Tango", "value": "C84317"},
              {"label": "Tantangan", "value": "C84318"},
              {"label": "Telafas", "value": "C84319"},
              {"label": "Teresita", "value": "C84320"},
              {"label": "Tinoto", "value": "C84321"},
              {"label": "Tomado", "value": "C84322"},
              {"label": "Tran", "value": "C84323"},
              {"label": "Tuka", "value": "C84324"},
              {"label": "Tulunan", "value": "C84325"},
              {"label": "Tupi", "value": "C84326"},
              {"label": "Tuyan", "value": "C84327"},
              {"label": "Upper Klinan", "value": "C84328"},
              {"label": "Upper San Mateo", "value": "C84329"},
              {"label": "Villamor", "value": "C84330"}],
 "Bulacan": [{"label": "Agay", "value": "C84331"},
             {"label": "Alegria", "value": "C84332"},
             {"label": "Alubijid", "value": "C84333"},
             {"label": "Amaga", "value": "C84334"},
             {"label": "Anticala", "value": "C84335"},
             {"label": "Aras-asan", "value": "C84336"},
             {"label": "Aurora", "value": "C84337"},
             {"label": "Bacolod", "value": "C84338"},
             {"label": "Bacuag", "value": "C84339"},
             {"label": "Bah-Bah", "value": "C84340"},
             {"label": "Balangbalang", "value": "C84341"},
             {"label": "Bancasi", "value": "C84342"},
             {"label": "Bangonay", "value": "C84343"},
             {"label": "Barcelona", "value": "C84344"},
             {"label": "Barobo", "value": "C84345"},
             {"label": "Basa", "value": "C84346"},
             {"label": "Basilisa", "value": "C84347"},
             {"label": "Bayabas", "value": "C84348"},
             {"label": "Bayugan", "value": "C84349"},
             {"label": "Bigaan", "value": "C84350"},
             {"label": "Binucayan", "value": "C84351"},
             {"label": "Bislig", "value": "C84352"},
             {"label": "Borbon", "value": "C84353"},
             {"label": "Buenavista", "value": "C84354"},
             {"label": "Bunawan", "value": "C84355"},
             {"label": "Burgos", "value": "C84356"},
             {"label": "Butuan", "value": "C84357"},
             {"label": "Cabadbaran", "value": "C84358"},
             {"label": "Cagdianao", "value": "C84359"},
             {"label": "Cagwait", "value": "C84360"},
             {"label": "Calamba", "value": "C84361"},
             {"label": "Caloc-an", "value": "C84362"},
             {"label": "Cantapoy", "value": "C84363"},
             {"label": "Cantilan", "value": "C84364"},
             {"label": "Capalayan", "value": "C84365"},
             {"label": "Carmen", "value": "C84366"},
             {"label": "Carrascal", "value": "C84367"},
             {"label": "Causwagan", "value": "C84368"},
             {"label": "City of Cabadbaran", "value": "C84369"},
             {"label": "Claver", "value": "C84370"},
             {"label": "Comagascas", "value": "C84371"},
             {"label": "Cortes", "value": "C84372"},
             {"label": "Cuevas", "value": "C84373"},
             {"label": "Culit", "value": "C84374"},
             {"label": "Dakbayan sa Bislig", "value": "C84375"},
             {"label": "Dapa", "value": "C84376"},
             {"label": "Del Carmen", "value": "C84377"},
             {"label": "Del Pilar", "value": "C84378"},
             {"label": "Dinagat", "value": "C84379"},
             {"label": "Esperanza", "value": "C84380"},
             {"label": "Gamut", "value": "C84381"},
             {"label": "General Luna", "value": "C84382"},
             {"label": "Gigaquit", "value": "C84383"},
             {"label": "Guadalupe", "value": "C84384"},
             {"label": "Guinabsan", "value": "C84385"},
             {"label": "Hinatuan", "value": "C84386"},
             {"label": "Ipil", "value": "C84387"},
             {"label": "Jabonga", "value": "C84388"},
             {"label": "Jagupit", "value": "C84389"},
             {"label": "Javier", "value": "C84390"},
             {"label": "Kauswagan", "value": "C84391"},
             {"label": "Kinabhangan", "value": "C84392"},
             {"label": "Kitcharao", "value": "C84393"},
             {"label": "La Paz", "value": "C84394"},
             {"label": "La Union", "value": "C84395"},
             {"label": "Lanuza", "value": "C84396"},
             {"label": "Lapinigan", "value": "C84397"},
             {"label": "Las Nieves", "value": "C84398"},
             {"label": "Lianga", "value": "C84399"},
             {"label": "Libas", "value": "C84400"},
             {"label": "Libertad", "value": "C84401"},
             {"label": "Libjo", "value": "C84402"},
             {"label": "Lingig", "value": "C84403"},
             {"label": "Loreto", "value": "C84404"},
             {"label": "Los Angeles", "value": "C84405"},
             {"label": "Los Arcos", "value": "C84406"},
             {"label": "Loyola", "value": "C84407"},
             {"label": "Luna", "value": "C84408"},
             {"label": "Mabahin", "value": "C84409"},
             {"label": "Mabua", "value": "C84410"},
             {"label": "Madrid", "value": "C84411"},
             {"label": "Magallanes", "value": "C84412"},
             {"label": "Mainit", "value": "C84413"},
             {"label": "Malimono", "value": "C84414"},
             {"label": "Manapa", "value": "C84415"},
             {"label": "Marihatag", "value": "C84416"},
             {"label": "Matabao", "value": "C84417"},
             {"label": "Mati", "value": "C84418"},
             {"label": "Maygatasan", "value": "C84419"},
             {"label": "Nasipit", "value": "C84420"},
             {"label": "Panikian", "value": "C84421"},
             {"label": "Parang", "value": "C84422"},
             {"label": "Patin-ay", "value": "C84423"},
             {"label": "Pilar", "value": "C84424"},
             {"label": "Placer", "value": "C84425"},
             {"label": "Prosperidad", "value": "C84426"},
             {"label": "Punta", "value": "C84427"},
             {"label": "Rizal", "value": "C84428"},
             {"label": "Rosario", "value": "C84429"},
             {"label": "Salvacion", "value": "C84430"},
             {"label": "San Agustin", "value": "C84431"},
             {"label": "San Benito", "value": "C84432"},
             {"label": "San Francisco", "value": "C84433"},
             {"label": "San Isidro", "value": "C84434"},
             {"label": "San Luis", "value": "C84435"},
             {"label": "San Miguel", "value": "C84436"},
             {"label": "Sanghan", "value": "C84437"},
             {"label": "Santa Ana", "value": "C84438"},
             {"label": "Santa Josefa", "value": "C84439"},
             {"label": "Santa Maria", "value": "C84440"},
             {"label": "Santa Monica", "value": "C84441"},
             {"label": "Santiago", "value": "C84442"},
             {"label": "Santo Tomas", "value": "C84443"},
             {"label": "Sibagat", "value": "C84444"},
             {"label": "Sinubong", "value": "C84445"},
             {"label": "Sison", "value": "C84446"},
             {"label": "Socorro", "value": "C84447"},
             {"label": "Surigao City", "value": "C84448"},
             {"label": "Tagana-an", "value": "C84449"},
             {"label": "Tagbina", "value": "C84450"},
             {"label": "Tagcatong", "value": "C84451"},
             {"label": "Tago", "value": "C84452"},
             {"label": "Talacogon", "value": "C84453"},
             {"label": "Taligaman", "value": "C84454"},
             {"label": "Talisay", "value": "C84455"},
             {"label": "Tandag", "value": "C84456"},
             {"label": "Tidman", "value": "C84457"},
             {"label": "Tigao", "value": "C84458"},
             {"label": "Trento", "value": "C84459"},
             {"label": "Tubajon", "value": "C84460"},
             {"label": "Tubay", "value": "C84461"},
             {"label": "Tubod", "value": "C84462"},
             {"label": "Unidad", "value": "C84463"},
             {"label": "Union", "value": "C84464"},
             {"label": "Veruela", "value": "C84465"}],
 "Bulawayo Province": [{"label": "Bulawayo", "value": "C148158"}],
 "Bulgan Province": [{"label": "Bulgan", "value": "C75561"}],
 "Bumthang District": [{"label": "Jakar", "value": "C9630"}],
 "Bungoma": [{"label": "Bungoma", "value": "C64542"},
             {"label": "Malikisi", "value": "C64543"},
             {"label": "Webuye", "value": "C64544"}],
 "Burdur": [{"label": "Aglasun", "value": "C120086"},
            {"label": "Altinyayla", "value": "C120087"},
            {"label": "Bucak", "value": "C120088"},
            {"label": "Burdur", "value": "C120089"},
            {"label": "Cavdir Ilcesi", "value": "C120090"},
            {"label": "Celtikci Ilcesi", "value": "C120091"},
            {"label": "Golhisar", "value": "C120092"},
            {"label": "Karamanli", "value": "C120093"},
            {"label": "Kemer", "value": "C120094"},
            {"label": "Kizilkaya", "value": "C120095"},
            {"label": "Kocaaliler", "value": "C120096"},
            {"label": "Merkez", "value": "C120097"},
            {"label": "Tefenni", "value": "C120098"},
            {"label": "Yesilova", "value": "C120099"}],
 "Burgas Province": [{"label": "Aheloy", "value": "C15875"},
                     {"label": "Ahtopol", "value": "C15876"},
                     {"label": "Aytos", "value": "C15877"},
                     {"label": "Bata", "value": "C15878"},
                     {"label": "Burgas", "value": "C15879"},
                     {"label": "Chernomorets", "value": "C15880"},
                     {"label": "Kameno", "value": "C15881"},
                     {"label": "Karnobat", "value": "C15882"},
                     {"label": "Kiten", "value": "C15883"},
                     {"label": "Malko Tarnovo", "value": "C15884"},
                     {"label": "Nesebar", "value": "C15885"},
                     {"label": "Obshtina Aytos", "value": "C15886"},
                     {"label": "Obshtina Burgas", "value": "C15887"},
                     {"label": "Obshtina Kameno", "value": "C15888"},
                     {"label": "Obshtina Karnobat", "value": "C15889"},
                     {"label": "Obshtina Malko Tarnovo", "value": "C15890"},
                     {"label": "Obshtina Nesebar", "value": "C15891"},
                     {"label": "Obshtina Pomorie", "value": "C15892"},
                     {"label": "Obshtina Primorsko", "value": "C15893"},
                     {"label": "Obshtina Sozopol", "value": "C15894"},
                     {"label": "Obshtina Sungurlare", "value": "C15895"},
                     {"label": "Obzor", "value": "C15896"},
                     {"label": "Pomorie", "value": "C15897"},
                     {"label": "Primorsko", "value": "C15898"},
                     {"label": "Ravda", "value": "C15899"},
                     {"label": "Ruen", "value": "C15900"},
                     {"label": "Sarafovo", "value": "C15901"},
                     {"label": "Sozopol", "value": "C15902"},
                     {"label": "Sredets", "value": "C15903"},
                     {"label": "Sungurlare", "value": "C15904"},
                     {"label": "Sveti Vlas", "value": "C15905"},
                     {"label": "Tsarevo", "value": "C15906"}],
 "Burgenland": [{"label": "Andau", "value": "C6006"},
                {"label": "Antau", "value": "C6007"},
                {"label": "Apetlon", "value": "C6008"},
                {"label": "Bad Sauerbrunn", "value": "C6009"},
                {"label": "Bad Tatzmannsdorf", "value": "C6010"},
                {"label": "Badersdorf", "value": "C6011"},
                {"label": "Bernstein", "value": "C6012"},
                {"label": "Bocksdorf", "value": "C6013"},
                {"label": "Breitenbrunn", "value": "C6014"},
                {"label": "Bruckneudorf", "value": "C6015"},
                {"label": "Deutsch Jahrndorf", "value": "C6016"},
                {"label": "Deutsch Kaltenbrunn", "value": "C6017"},
                {"label": "Deutschkreutz", "value": "C6018"},
                {"label": "Donnerskirchen", "value": "C6019"},
                {"label": "Drassburg", "value": "C6020"},
                {"label": "Eberau", "value": "C6021"},
                {"label": "Edelstal", "value": "C6022"},
                {"label": "Eisenstadt", "value": "C6023"},
                {"label": "Eisenstadt Stadt", "value": "C6024"},
                {"label": "Eisenstadt-Umgebung", "value": "C6025"},
                {"label": "Eltendorf", "value": "C6026"},
                {"label": "Forchtenstein", "value": "C6027"},
                {"label": "Frauenkirchen", "value": "C6028"},
                {"label": "Gattendorf", "value": "C6029"},
                {"label": "Gols", "value": "C6030"},
                {"label": "Grafenschachen", "value": "C6031"},
                {"label": "Grosshoflein", "value": "C6032"},
                {"label": "Grosspetersdorf", "value": "C6033"},
                {"label": "Gussing", "value": "C6034"},
                {"label": "Guttenbach", "value": "C6035"},
                {"label": "Hackerberg", "value": "C6036"},
                {"label": "Halbturn", "value": "C6037"},
                {"label": "Hannersdorf", "value": "C6038"},
                {"label": "Heiligenbrunn", "value": "C6039"},
                {"label": "Heiligenkreuz im Lafnitztal", "value": "C6040"},
                {"label": "Heugraben", "value": "C6041"},
                {"label": "Hirm", "value": "C6042"},
                {"label": "Horitschon", "value": "C6043"},
                {"label": "Hornstein", "value": "C6044"},
                {"label": "Illmitz", "value": "C6045"},
                {"label": "Inzenhof", "value": "C6046"},
                {"label": "Jabing", "value": "C6047"},
                {"label": "Jennersdorf", "value": "C6048"},
                {"label": "Jois", "value": "C6049"},
                {"label": "Kaisersdorf", "value": "C6050"},
                {"label": "Kemeten", "value": "C6051"},
                {"label": "Kittsee", "value": "C6052"},
                {"label": "Kleinhoflein im Burgenland", "value": "C6053"},
                {"label": "Kleinmurbisch", "value": "C6054"},
                {"label": "Klingenbach", "value": "C6055"},
                {"label": "Kobersdorf", "value": "C6056"},
                {"label": "Kohfidisch", "value": "C6057"},
                {"label": "Konigsdorf", "value": "C6058"},
                {"label": "Krensdorf", "value": "C6059"},
                {"label": "Kukmirn", "value": "C6060"},
                {"label": "Lackenbach", "value": "C6061"},
                {"label": "Lackendorf", "value": "C6062"},
                {"label": "Leithaprodersdorf", "value": "C6063"},
                {"label": "Litzelsdorf", "value": "C6064"},
                {"label": "Lockenhaus", "value": "C6065"},
                {"label": "Loipersbach im Burgenland", "value": "C6066"},
                {"label": "Loretto", "value": "C6067"},
                {"label": "Lutzmannsburg", "value": "C6068"},
                {"label": "Mannersdorf an der Rabnitz", "value": "C6069"},
                {"label": "Mariasdorf", "value": "C6070"},
                {"label": "Markt Allhau", "value": "C6071"},
                {"label": "Markt Neuhodis", "value": "C6072"},
                {"label": "Markt Sankt Martin", "value": "C6073"},
                {"label": "Marz", "value": "C6074"},
                {"label": "Mattersburg", "value": "C6075"},
                {"label": "Mischendorf", "value": "C6076"},
                {"label": "Mitterpullendorf", "value": "C6077"},
                {"label": "Mogersdorf", "value": "C6078"},
                {"label": "Monchhof", "value": "C6079"},
                {"label": "Morbisch am See", "value": "C6080"},
                {"label": "Moschendorf", "value": "C6081"},
                {"label": "Muhlgraben", "value": "C6082"},
                {"label": "Mullendorf", "value": "C6083"},
                {"label": "Neckenmarkt", "value": "C6084"},
                {"label": "Neuberg im Burgenland", "value": "C6085"},
                {"label": "Neudorfl", "value": "C6086"},
                {"label": "Neufeld an der Leitha", "value": "C6087"},
                {"label": "Neuhaus am Klausenbach", "value": "C6088"},
                {"label": "Neusiedl am See", "value": "C6089"},
                {"label": "Neustift an der Lafnitz", "value": "C6090"},
                {"label": "Neustift bei Gussing", "value": "C6091"},
                {"label": "Neutal", "value": "C6092"},
                {"label": "Nickelsdorf", "value": "C6093"},
                {"label": "Nikitsch", "value": "C6094"},
                {"label": "Oberdorf im Burgenland", "value": "C6095"},
                {"label": "Oberloisdorf", "value": "C6096"},
                {"label": "Oberpullendorf", "value": "C6097"},
                {"label": "Oberschutzen", "value": "C6098"},
                {"label": "Oberwart", "value": "C6099"},
                {"label": "Oggau", "value": "C6100"},
                {"label": "Olbendorf", "value": "C6101"},
                {"label": "Ollersdorf im Burgenland", "value": "C6102"},
                {"label": "Oslip", "value": "C6103"},
                {"label": "Pama", "value": "C6104"},
                {"label": "Pamhagen", "value": "C6105"},
                {"label": "Parndorf", "value": "C6106"},
                {"label": "Pilgersdorf", "value": "C6107"},
                {"label": "Pinkafeld", "value": "C6108"},
                {"label": "Piringsdorf", "value": "C6109"},
                {"label": "Podersdorf am See", "value": "C6110"},
                {"label": "Politischer Bezirk Gussing", "value": "C6111"},
                {"label": "Politischer Bezirk Jennersdorf", "value": "C6112"},
                {"label": "Politischer Bezirk Mattersburg", "value": "C6113"},
                {"label": "Politischer Bezirk Neusiedl am See",
                 "value": "C6114"},
                {"label": "Politischer Bezirk Oberpullendorf",
                 "value": "C6115"},
                {"label": "Politischer Bezirk Oberwart", "value": "C6116"},
                {"label": "Pottelsdorf", "value": "C6117"},
                {"label": "Pottsching", "value": "C6118"},
                {"label": "Potzneusiedl", "value": "C6119"},
                {"label": "Purbach am Neusiedler See", "value": "C6120"},
                {"label": "Raiding", "value": "C6121"},
                {"label": "Rechnitz", "value": "C6122"},
                {"label": "Riedlingsdorf", "value": "C6123"},
                {"label": "Ritzing", "value": "C6124"},
                {"label": "Rohr im Burgenland", "value": "C6125"},
                {"label": "Rohrbach bei Mattersburg", "value": "C6126"},
                {"label": "Rotenturm an der Pinka", "value": "C6127"},
                {"label": "Rudersdorf", "value": "C6128"},
                {"label": "Rust", "value": "C6129"},
                {"label": "Rust Stadt", "value": "C6130"},
                {"label": "Sankt Andra am Zicksee", "value": "C6131"},
                {"label": "Sankt Georgen am Leithagebirge", "value": "C6132"},
                {"label": "Sankt Margarethen im Burgenland", "value": "C6133"},
                {"label": "Sankt Martin an der Raab", "value": "C6134"},
                {"label": "Sankt Michael im Burgenland", "value": "C6135"},
                {"label": "Schachendorf", "value": "C6136"},
                {"label": "Schandorf", "value": "C6137"},
                {"label": "Schattendorf", "value": "C6138"},
                {"label": "Schutzen am Gebirge", "value": "C6139"},
                {"label": "Siegendorf im Burgenland", "value": "C6140"},
                {"label": "Sieggraben", "value": "C6141"},
                {"label": "Sigless", "value": "C6142"},
                {"label": "Stadtschlaining", "value": "C6143"},
                {"label": "Stegersbach", "value": "C6144"},
                {"label": "Steinbrunn", "value": "C6145"},
                {"label": "Stinatz", "value": "C6146"},
                {"label": "Stoob", "value": "C6147"},
                {"label": "Stotzing", "value": "C6148"},
                {"label": "Strem", "value": "C6149"},
                {"label": "Tadten", "value": "C6150"},
                {"label": "Tobaj", "value": "C6151"},
                {"label": "Trausdorf an der Wulka", "value": "C6152"},
                {"label": "Tschanigraben", "value": "C6153"},
                {"label": "Unterfrauenhaid", "value": "C6154"},
                {"label": "Unterkohlstatten", "value": "C6155"},
                {"label": "Unterwart", "value": "C6156"},
                {"label": "Wallern im Burgenland", "value": "C6157"},
                {"label": "Weichselbaum", "value": "C6158"},
                {"label": "Weiden am See", "value": "C6159"},
                {"label": "Weiden bei Rechnitz", "value": "C6160"},
                {"label": "Weingraben", "value": "C6161"},
                {"label": "Weppersdorf", "value": "C6162"},
                {"label": "Wiesen", "value": "C6163"},
                {"label": "Wiesfleck", "value": "C6164"},
                {"label": "Wimpassing an der Leitha", "value": "C6165"},
                {"label": "Winden am See", "value": "C6166"},
                {"label": "Wolfau", "value": "C6167"},
                {"label": "Worterberg", "value": "C6168"},
                {"label": "Wulkaprodersdorf", "value": "C6169"},
                {"label": "Zagersdorf", "value": "C6170"},
                {"label": "Zillingtal", "value": "C6171"},
                {"label": "Zurndorf", "value": "C6172"}],
 "Buri Ram": [{"label": "Amphoe Ban Dan", "value": "C118444"},
              {"label": "Amphoe Ban Kruat", "value": "C118445"},
              {"label": "Amphoe Ban Mai Chaiyaphot", "value": "C118446"},
              {"label": "Amphoe Chaloem Phra Kiat", "value": "C118447"},
              {"label": "Amphoe Chamni", "value": "C118448"},
              {"label": "Amphoe Huai Rat", "value": "C118449"},
              {"label": "Amphoe Khaen Dong", "value": "C118450"},
              {"label": "Amphoe Khu Mueang", "value": "C118451"},
              {"label": "Amphoe Krasang", "value": "C118452"},
              {"label": "Amphoe Lahan Sai", "value": "C118453"},
              {"label": "Amphoe Lam Plai Mat", "value": "C118454"},
              {"label": "Amphoe Mueang Buriram", "value": "C118455"},
              {"label": "Amphoe Na Pho", "value": "C118456"},
              {"label": "Amphoe Nang Rong", "value": "C118457"},
              {"label": "Amphoe Non Din Daeng", "value": "C118458"},
              {"label": "Amphoe Non Suwan", "value": "C118459"},
              {"label": "Amphoe Nong Hong", "value": "C118460"},
              {"label": "Amphoe Nong Ki", "value": "C118461"},
              {"label": "Amphoe Pa Kham", "value": "C118462"},
              {"label": "Amphoe Phlapphla Chai", "value": "C118463"},
              {"label": "Amphoe Phutthaisong", "value": "C118464"},
              {"label": "Amphoe Prakhon Chai", "value": "C118465"},
              {"label": "Amphoe Satuek", "value": "C118466"},
              {"label": "Buri Ram", "value": "C118467"},
              {"label": "Nang Rong", "value": "C118468"},
              {"label": "Nong Ki", "value": "C118469"},
              {"label": "Prakhon Chai", "value": "C118470"},
              {"label": "Satuek", "value": "C118471"}],
 "Bursa": [{"label": "Alanyurt", "value": "C120100"},
           {"label": "Balarim", "value": "C120101"},
           {"label": "Boyalica", "value": "C120102"},
           {"label": "Bursa", "value": "C120103"},
           {"label": "Buyukorhan Ilcesi", "value": "C120104"},
           {"label": "Cakirca", "value": "C120105"},
           {"label": "Cakirli", "value": "C120106"},
           {"label": "Cerrah", "value": "C120107"},
           {"label": "Demirtas", "value": "C120108"},
           {"label": "Elbeyli", "value": "C120109"},
           {"label": "Gemlik", "value": "C120110"},
           {"label": "Gursu", "value": "C120111"},
           {"label": "Hamzabey", "value": "C120112"},
           {"label": "Harmancik Ilcesi", "value": "C120113"},
           {"label": "Inegol", "value": "C120114"},
           {"label": "Iznik", "value": "C120115"},
           {"label": "Karacabey", "value": "C120116"},
           {"label": "Karincali", "value": "C120117"},
           {"label": "Keles", "value": "C120118"},
           {"label": "Kestel", "value": "C120119"},
           {"label": "Kirazli", "value": "C120120"},
           {"label": "Kucukkumla", "value": "C120121"},
           {"label": "Kursunlu", "value": "C120122"},
           {"label": "Mudanya", "value": "C120123"},
           {"label": "Mustafakemalpasa", "value": "C120124"},
           {"label": "Narlica", "value": "C120125"},
           {"label": "Nilufer", "value": "C120126"},
           {"label": "Orhaneli", "value": "C120127"},
           {"label": "Orhangazi", "value": "C120128"},
           {"label": "Osmangazi", "value": "C120129"},
           {"label": "Soloz", "value": "C120130"},
           {"label": "Tacir", "value": "C120131"},
           {"label": "Tahtakopru", "value": "C120132"},
           {"label": "Tatkavakli", "value": "C120133"},
           {"label": "Umurbey", "value": "C120134"},
           {"label": "Yenice", "value": "C120135"},
           {"label": "Yenikoy", "value": "C120136"},
           {"label": "Yenisehir", "value": "C120137"},
           {"label": "Yildirim Ilcesi", "value": "C120138"}],
 "Bururi Province": [{"label": "Bururi", "value": "C16444"}],
 "Busan": [{"label": "Buk-gu", "value": "C108240"},
           {"label": "Busan", "value": "C108241"},
           {"label": "Dongnae-gu", "value": "C108242"},
           {"label": "Gangseo-gu", "value": "C108243"},
           {"label": "Geumjeong-gu", "value": "C108244"},
           {"label": "Gijang", "value": "C108245"},
           {"label": "Gijang-gun", "value": "C108246"},
           {"label": "Haeundae-gu", "value": "C108247"},
           {"label": "Jung-gu", "value": "C108248"},
           {"label": "Nam-gu", "value": "C108249"},
           {"label": "Saha-gu", "value": "C108250"},
           {"label": "Sasang-gu", "value": "C108251"},
           {"label": "Seo-gu", "value": "C108252"},
           {"label": "Suyeong-gu", "value": "C108253"},
           {"label": "Yeongdo-gu", "value": "C108254"}],
 "Bushehr": [{"label": "Ahram", "value": "C50065"},
             {"label": "Bandar-e Genaveh", "value": "C50066"},
             {"label": "Borazjan", "value": "C50067"},
             {"label": "Bushehr", "value": "C50068"},
             {"label": "Deylam", "value": "C50069"},
             {"label": "Khark", "value": "C50070"},
             {"label": "Shahrestan-e Asaluyeh", "value": "C50071"},
             {"label": "Shahrestan-e Bushehr", "value": "C50072"},
             {"label": "Shahrestan-e Dashtestan", "value": "C50073"},
             {"label": "Shahrestan-e Dashti", "value": "C50074"},
             {"label": "Shahrestan-e Genaveh", "value": "C50075"},
             {"label": "Shahrestan-e Kangan", "value": "C50076"},
             {"label": "Shahrestan-e Tangestan", "value": "C50077"}],
 "Busia": [{"label": "Busia", "value": "C64545"},
           {"label": "Luanda", "value": "C64546"},
           {"label": "Lugulu", "value": "C64547"},
           {"label": "Malaba", "value": "C64548"},
           {"label": "Nambare", "value": "C64549"},
           {"label": "Port Victoria", "value": "C64550"}],
 "Buskerud": [{"label": "Al", "value": "C78664"},
              {"label": "Aros", "value": "C78665"},
              {"label": "Drammen", "value": "C78666"},
              {"label": "Fla", "value": "C78667"},
              {"label": "Flesberg", "value": "C78668"},
              {"label": "Geilo", "value": "C78669"},
              {"label": "Gol", "value": "C78670"},
              {"label": "Hemsedal", "value": "C78671"},
              {"label": "Hol", "value": "C78672"},
              {"label": "Hole", "value": "C78673"},
              {"label": "Honefoss", "value": "C78674"},
              {"label": "Hurum", "value": "C78675"},
              {"label": "Hvittingfoss", "value": "C78676"},
              {"label": "Kongsberg", "value": "C78677"},
              {"label": "Krodsherad", "value": "C78678"},
              {"label": "Lier", "value": "C78679"},
              {"label": "Modum", "value": "C78680"},
              {"label": "Nedre Eiker", "value": "C78681"},
              {"label": "Nes", "value": "C78682"},
              {"label": "Nesbyen", "value": "C78683"},
              {"label": "Nore og Uvdal", "value": "C78684"},
              {"label": "Noresund", "value": "C78685"},
              {"label": "Ovre Eiker", "value": "C78686"},
              {"label": "Prestfoss", "value": "C78687"},
              {"label": "Ringerike", "value": "C78688"},
              {"label": "Rollag", "value": "C78689"},
              {"label": "Royken", "value": "C78690"},
              {"label": "Saetre", "value": "C78691"},
              {"label": "Sigdal", "value": "C78692"},
              {"label": "Skoger", "value": "C78693"},
              {"label": "Tofte", "value": "C78694"},
              {"label": "Tranby", "value": "C78695"},
              {"label": "Vikersund", "value": "C78696"}],
 "Butel Municipality": [{"label": "Butel", "value": "C65379"},
                        {"label": "Radishani", "value": "C65380"}],
 "Butha-Buthe District": [{"label": "Butha-Buthe", "value": "C64991"}],
 "Buzau County": [{"label": "Amara", "value": "C93799"},
                  {"label": "Amaru", "value": "C93800"},
                  {"label": "Babeni", "value": "C93801"},
                  {"label": "Babeti", "value": "C93802"},
                  {"label": "Balaceanu", "value": "C93803"},
                  {"label": "Balta Alba", "value": "C93804"},
                  {"label": "Basca Chiojdului", "value": "C93805"},
                  {"label": "Basca Rozilei", "value": "C93806"},
                  {"label": "Bascenii de Jos", "value": "C93807"},
                  {"label": "Bascenii de Sus", "value": "C93808"},
                  {"label": "Beceni", "value": "C93809"},
                  {"label": "Berca", "value": "C93810"},
                  {"label": "Bisoca", "value": "C93811"},
                  {"label": "Blajani", "value": "C93812"},
                  {"label": "Boboc", "value": "C93813"},
                  {"label": "Boldu", "value": "C93814"},
                  {"label": "Bozioru", "value": "C93815"},
                  {"label": "Bradeanu", "value": "C93816"},
                  {"label": "Braesti", "value": "C93817"},
                  {"label": "Breaza", "value": "C93818"},
                  {"label": "Buda", "value": "C93819"},
                  {"label": "Buruenesti", "value": "C93820"},
                  {"label": "Buzau", "value": "C93821"},
                  {"label": "C.a. Rosetti", "value": "C93822"},
                  {"label": "Caldarasti", "value": "C93823"},
                  {"label": "Calvini", "value": "C93824"},
                  {"label": "Canesti", "value": "C93825"},
                  {"label": "Capatanesti", "value": "C93826"},
                  {"label": "Casoca", "value": "C93827"},
                  {"label": "Catina", "value": "C93828"},
                  {"label": "Cernatesti", "value": "C93829"},
                  {"label": "Chiliile", "value": "C93830"},
                  {"label": "Chiojdu", "value": "C93831"},
                  {"label": "Cilibia", "value": "C93832"},
                  {"label": "Cislau", "value": "C93833"},
                  {"label": "Cochirleanca", "value": "C93834"},
                  {"label": "Colti", "value": "C93835"},
                  {"label": "Comuna Amaru", "value": "C93836"},
                  {"label": "Comuna Balaceanu", "value": "C93837"},
                  {"label": "Comuna Balta Alba", "value": "C93838"},
                  {"label": "Comuna Beceni", "value": "C93839"},
                  {"label": "Comuna Berca", "value": "C93840"},
                  {"label": "Comuna Bisoca", "value": "C93841"},
                  {"label": "Comuna Blajani", "value": "C93842"},
                  {"label": "Comuna Boldu", "value": "C93843"},
                  {"label": "Comuna Bozioru", "value": "C93844"},
                  {"label": "Comuna Bradeanu", "value": "C93845"},
                  {"label": "Comuna Braesti", "value": "C93846"},
                  {"label": "Comuna Breaza", "value": "C93847"},
                  {"label": "Comuna Buda", "value": "C93848"},
                  {"label": "Comuna C.A. Rosetti", "value": "C93849"},
                  {"label": "Comuna Calvini", "value": "C93850"},
                  {"label": "Comuna Canesti", "value": "C93851"},
                  {"label": "Comuna Catina", "value": "C93852"},
                  {"label": "Comuna Cernatesti", "value": "C93853"},
                  {"label": "Comuna Chiliile", "value": "C93854"},
                  {"label": "Comuna Chiojdu", "value": "C93855"},
                  {"label": "Comuna Cilibia", "value": "C93856"},
                  {"label": "Comuna Cislau", "value": "C93857"},
                  {"label": "Comuna Cochirleanca", "value": "C93858"},
                  {"label": "Comuna Colti", "value": "C93859"},
                  {"label": "Comuna Costesti", "value": "C93860"},
                  {"label": "Comuna Cozieni", "value": "C93861"},
                  {"label": "Comuna Florica", "value": "C93862"},
                  {"label": "Comuna Galbinasi", "value": "C93863"},
                  {"label": "Comuna Gheraseni", "value": "C93864"},
                  {"label": "Comuna Ghergheasa", "value": "C93865"},
                  {"label": "Comuna Glodeanu-Sarat", "value": "C93866"},
                  {"label": "Comuna Glodeanu-Silistea", "value": "C93867"},
                  {"label": "Comuna Greabanu", "value": "C93868"},
                  {"label": "Comuna Gura Teghii", "value": "C93869"},
                  {"label": "Comuna Largu", "value": "C93870"},
                  {"label": "Comuna Lopatari", "value": "C93871"},
                  {"label": "Comuna Luciu", "value": "C93872"},
                  {"label": "Comuna Magura", "value": "C93873"},
                  {"label": "Comuna Manzalesti", "value": "C93874"},
                  {"label": "Comuna Maracineni", "value": "C93875"},
                  {"label": "Comuna Margaritesti", "value": "C93876"},
                  {"label": "Comuna Merei", "value": "C93877"},
                  {"label": "Comuna Mihailesti", "value": "C93878"},
                  {"label": "Comuna Movila Banului", "value": "C93879"},
                  {"label": "Comuna Murgesti", "value": "C93880"},
                  {"label": "Comuna Naeni", "value": "C93881"},
                  {"label": "Comuna Odaile", "value": "C93882"},
                  {"label": "Comuna Padina", "value": "C93883"},
                  {"label": "Comuna Panatau", "value": "C93884"},
                  {"label": "Comuna Pardosi", "value": "C93885"},
                  {"label": "Comuna Parscov", "value": "C93886"},
                  {"label": "Comuna Pietroasele", "value": "C93887"},
                  {"label": "Comuna Podgoria", "value": "C93888"},
                  {"label": "Comuna Posta Calnau", "value": "C93889"},
                  {"label": "Comuna Puiesti", "value": "C93890"},
                  {"label": "Comuna Racoviteni", "value": "C93891"},
                  {"label": "Comuna Ramnicelu", "value": "C93892"},
                  {"label": "Comuna Robeasca", "value": "C93893"},
                  {"label": "Comuna Rusetu", "value": "C93894"},
                  {"label": "Comuna Sageata", "value": "C93895"},
                  {"label": "Comuna Sahateni", "value": "C93896"},
                  {"label": "Comuna Sapoca", "value": "C93897"},
                  {"label": "Comuna Sarulesti", "value": "C93898"},
                  {"label": "Comuna Scortoasa", "value": "C93899"},
                  {"label": "Comuna Scutelnici", "value": "C93900"},
                  {"label": "Comuna Siriu", "value": "C93901"},
                  {"label": "Comuna Smeeni", "value": "C93902"},
                  {"label": "Comuna Stalpu", "value": "C93903"},
                  {"label": "Comuna Tintesti", "value": "C93904"},
                  {"label": "Comuna Tisau", "value": "C93905"},
                  {"label": "Comuna Topliceni", "value": "C93906"},
                  {"label": "Comuna Ulmeni", "value": "C93907"},
                  {"label": "Comuna Unguriu", "value": "C93908"},
                  {"label": "Comuna Vadu Pasii", "value": "C93909"},
                  {"label": "Comuna Valcelele", "value": "C93910"},
                  {"label": "Comuna Valea Ramnicului", "value": "C93911"},
                  {"label": "Comuna Valea Salciei", "value": "C93912"},
                  {"label": "Comuna Vernesti", "value": "C93913"},
                  {"label": "Comuna Vintila Voda", "value": "C93914"},
                  {"label": "Comuna Viperesti", "value": "C93915"},
                  {"label": "Comuna Zarnesti", "value": "C93916"},
                  {"label": "Comuna Ziduri", "value": "C93917"},
                  {"label": "Corbu", "value": "C93918"},
                  {"label": "Costesti", "value": "C93919"},
                  {"label": "Cotatcu", "value": "C93920"},
                  {"label": "Cozieni", "value": "C93921"},
                  {"label": "Dambroca", "value": "C93922"},
                  {"label": "Dascalesti", "value": "C93923"},
                  {"label": "Dedulesti", "value": "C93924"},
                  {"label": "Florica", "value": "C93925"},
                  {"label": "Fundeni", "value": "C93926"},
                  {"label": "Furtunesti", "value": "C93927"},
                  {"label": "Galbinasi", "value": "C93928"},
                  {"label": "Gavanesti", "value": "C93929"},
                  {"label": "Gheraseni", "value": "C93930"},
                  {"label": "Ghergheasa", "value": "C93931"},
                  {"label": "Glodeanu-Sarat", "value": "C93932"},
                  {"label": "Glodeanu-Silistea", "value": "C93933"},
                  {"label": "Grajdana", "value": "C93934"},
                  {"label": "Grebanu", "value": "C93935"},
                  {"label": "Greceanca", "value": "C93936"},
                  {"label": "Grunji", "value": "C93937"},
                  {"label": "Gura Teghii", "value": "C93938"},
                  {"label": "Izvoru", "value": "C93939"},
                  {"label": "Izvoru Dulce", "value": "C93940"},
                  {"label": "Largu", "value": "C93941"},
                  {"label": "Limpezis", "value": "C93942"},
                  {"label": "Lipia", "value": "C93943"},
                  {"label": "Lopatari", "value": "C93944"},
                  {"label": "Luciu", "value": "C93945"},
                  {"label": "Lunca", "value": "C93946"},
                  {"label": "Lunca Jaristei", "value": "C93947"},
                  {"label": "Lunca Priporului", "value": "C93948"},
                  {"label": "Magura", "value": "C93949"},
                  {"label": "Manzalesti", "value": "C93950"},
                  {"label": "Maracineni", "value": "C93951"},
                  {"label": "Margaritesti", "value": "C93952"},
                  {"label": "Maruntisu", "value": "C93953"},
                  {"label": "Matesti", "value": "C93954"},
                  {"label": "Maxenu", "value": "C93955"},
                  {"label": "Merei", "value": "C93956"},
                  {"label": "Mihailesti", "value": "C93957"},
                  {"label": "Movila Banului", "value": "C93958"},
                  {"label": "Municipiul Buzau", "value": "C93959"},
                  {"label": "Municipiul Ramnicu Sarat", "value": "C93960"},
                  {"label": "Murgesti", "value": "C93961"},
                  {"label": "Naeni", "value": "C93962"},
                  {"label": "Nehoiu", "value": "C93963"},
                  {"label": "Odaile", "value": "C93964"},
                  {"label": "Oras Nehoiu", "value": "C93965"},
                  {"label": "Oras Patarlagele", "value": "C93966"},
                  {"label": "Oras Pogoanele", "value": "C93967"},
                  {"label": "Oreavu", "value": "C93968"},
                  {"label": "Padina", "value": "C93969"},
                  {"label": "Paltineni", "value": "C93970"},
                  {"label": "Panatau", "value": "C93971"},
                  {"label": "Pardosi", "value": "C93972"},
                  {"label": "Parscov", "value": "C93973"},
                  {"label": "Patarlagele", "value": "C93974"},
                  {"label": "Pietroasele", "value": "C93975"},
                  {"label": "Pietrosu", "value": "C93976"},
                  {"label": "Plevna", "value": "C93977"},
                  {"label": "Podgoria", "value": "C93978"},
                  {"label": "Pogoanele", "value": "C93979"},
                  {"label": "Pogonele", "value": "C93980"},
                  {"label": "Posta Calnau", "value": "C93981"},
                  {"label": "Potoceni", "value": "C93982"},
                  {"label": "Puiestii de Jos", "value": "C93983"},
                  {"label": "Racoviteni", "value": "C93984"},
                  {"label": "Ramnicelu", "value": "C93985"},
                  {"label": "Ramnicu Sarat", "value": "C93986"},
                  {"label": "Robeasca", "value": "C93987"},
                  {"label": "Rubla", "value": "C93988"},
                  {"label": "Rusetu", "value": "C93989"},
                  {"label": "Sageata", "value": "C93990"},
                  {"label": "Sahateni", "value": "C93991"},
                  {"label": "Salcioara", "value": "C93992"},
                  {"label": "Sapoca", "value": "C93993"},
                  {"label": "Sarulesti", "value": "C93994"},
                  {"label": "Scortoasa", "value": "C93995"},
                  {"label": "Scurtesti", "value": "C93996"},
                  {"label": "Scutelnici", "value": "C93997"},
                  {"label": "Smeeni", "value": "C93998"},
                  {"label": "Stalpu", "value": "C93999"},
                  {"label": "Stancesti", "value": "C94000"},
                  {"label": "Suditi", "value": "C94001"},
                  {"label": "Tabarasti", "value": "C94002"},
                  {"label": "Tintesti", "value": "C94003"},
                  {"label": "Topliceni", "value": "C94004"},
                  {"label": "Ulmeni", "value": "C94005"},
                  {"label": "Unguriu", "value": "C94006"},
                  {"label": "Vadu Pasii", "value": "C94007"},
                  {"label": "Vadu Soresti", "value": "C94008"},
                  {"label": "Valcele", "value": "C94009"},
                  {"label": "Valcelele", "value": "C94010"},
                  {"label": "Valea Parului", "value": "C94011"},
                  {"label": "Valea Ramnicului", "value": "C94012"},
                  {"label": "Valea Salciei", "value": "C94013"},
                  {"label": "Vernesti", "value": "C94014"},
                  {"label": "Vintila Voda", "value": "C94015"},
                  {"label": "Vintileanca", "value": "C94016"},
                  {"label": "Viperesti", "value": "C94017"},
                  {"label": "Zarnesti", "value": "C94018"},
                  {"label": "Zarnestii de Slanic", "value": "C94019"},
                  {"label": "Ziduri", "value": "C94020"},
                  {"label": "Zoresti", "value": "C94021"}],
 "Ca Mau": [{"label": "Ca Mau", "value": "C147327"},
            {"label": "Huyen Cai Nuoc", "value": "C147328"},
            {"label": "Huyen Dam Doi", "value": "C147329"},
            {"label": "Huyen Nam Can", "value": "C147330"},
            {"label": "Huyen Thoi Binh", "value": "C147331"}],
 "Caaguazu": [{"label": "Caaguazu", "value": "C80505"},
              {"label": "Carayao", "value": "C80506"},
              {"label": "Cecilio Baez", "value": "C80507"},
              {"label": "Colonia General Alfredo Stroessner",
               "value": "C80508"},
              {"label": "Coronel Oviedo", "value": "C80509"},
              {"label": "Doctor Juan Manuel Frutos", "value": "C80510"},
              {"label": "Mbutuy", "value": "C80511"},
              {"label": "Repatriacion", "value": "C80512"},
              {"label": "San Joaquin", "value": "C80513"},
              {"label": "San Jose", "value": "C80514"},
              {"label": "Yhu", "value": "C80515"}],
 "Caazapa": [{"label": "Abai", "value": "C80516"},
             {"label": "Buena Vista", "value": "C80517"},
             {"label": "Caazapa", "value": "C80518"},
             {"label": "General Higinio Morinigo", "value": "C80519"},
             {"label": "San Juan Nepomuceno", "value": "C80520"},
             {"label": "Yegros", "value": "C80521"},
             {"label": "Yuty", "value": "C80522"}],
 "Cabanas Department": [{"label": "Sensuntepeque", "value": "C24371"},
                        {"label": "Victoria", "value": "C24372"}],
 "Cabinda Province": [{"label": "Cabinda", "value": "C534"}],
 "Cabo Delgado Province": [{"label": "Chiure", "value": "C75852"},
                           {"label": "Mocimboa", "value": "C75853"},
                           {"label": "Montepuez", "value": "C75854"},
                           {"label": "Pemba", "value": "C75855"}],
 "Cacheu Region": [{"label": "Cacheu", "value": "C43056"},
                   {"label": "Canchungo", "value": "C43057"}],
 "Cagayan": [{"label": "Ampatuan", "value": "C84466"},
             {"label": "Andalan", "value": "C84467"},
             {"label": "Anuling", "value": "C84468"},
             {"label": "Awang", "value": "C84469"},
             {"label": "Bacayawan", "value": "C84470"},
             {"label": "Bacolod Grande", "value": "C84471"},
             {"label": "Bacolod-Kalawi", "value": "C84472"},
             {"label": "Badak", "value": "C84473"},
             {"label": "Bagan", "value": "C84474"},
             {"label": "Baka", "value": "C84475"},
             {"label": "Bakung", "value": "C84476"},
             {"label": "Balabagan", "value": "C84477"},
             {"label": "Balas", "value": "C84478"},
             {"label": "Balindong", "value": "C84479"},
             {"label": "Bangkal", "value": "C84480"},
             {"label": "Banguingui", "value": "C84481"},
             {"label": "Bankaw", "value": "C84482"},
             {"label": "Barira", "value": "C84483"},
             {"label": "Barurao", "value": "C84484"},
             {"label": "Bato Bato", "value": "C84485"},
             {"label": "Baunu-Timbangan", "value": "C84486"},
             {"label": "Bawison", "value": "C84487"},
             {"label": "Bayang", "value": "C84488"},
             {"label": "Bayanga", "value": "C84489"},
             {"label": "Begang", "value": "C84490"},
             {"label": "Binidayan", "value": "C84491"},
             {"label": "Binuang", "value": "C84492"},
             {"label": "Blinsung", "value": "C84493"},
             {"label": "Bongao", "value": "C84494"},
             {"label": "Bongued", "value": "C84495"},
             {"label": "Buadiposo-Buntong", "value": "C84496"},
             {"label": "Bualan", "value": "C84497"},
             {"label": "Buan", "value": "C84498"},
             {"label": "Buansa", "value": "C84499"},
             {"label": "Bubong", "value": "C84500"},
             {"label": "Budta", "value": "C84501"},
             {"label": "Bugasan", "value": "C84502"},
             {"label": "Buldon", "value": "C84503"},
             {"label": "Buliok", "value": "C84504"},
             {"label": "Bulit", "value": "C84505"},
             {"label": "Buluan", "value": "C84506"},
             {"label": "Butig", "value": "C84507"},
             {"label": "Cagayan de Tawi-Tawi", "value": "C84508"},
             {"label": "Calanogas", "value": "C84509"},
             {"label": "Colonia", "value": "C84510"},
             {"label": "Dado", "value": "C84511"},
             {"label": "Dadus", "value": "C84512"},
             {"label": "Dalican", "value": "C84513"},
             {"label": "Dalumangcob", "value": "C84514"},
             {"label": "Damabalas", "value": "C84515"},
             {"label": "Damatulan", "value": "C84516"},
             {"label": "Datu Blah T. Sinsuat", "value": "C84517"},
             {"label": "Datu Odin Sinsuat", "value": "C84518"},
             {"label": "Datu Paglas", "value": "C84519"},
             {"label": "Datu Piang", "value": "C84520"},
             {"label": "Digal", "value": "C84521"},
             {"label": "Dinaig", "value": "C84522"},
             {"label": "Dinganen", "value": "C84523"},
             {"label": "Ditsaan-Ramain", "value": "C84524"},
             {"label": "Ebcor Town", "value": "C84525"},
             {"label": "Gadung", "value": "C84526"},
             {"label": "Ganassi", "value": "C84527"},
             {"label": "Gang", "value": "C84528"},
             {"label": "Guiong", "value": "C84529"},
             {"label": "Idtig", "value": "C84530"},
             {"label": "Indanan", "value": "C84531"},
             {"label": "Isabela", "value": "C84532"},
             {"label": "Jolo", "value": "C84533"},
             {"label": "Kabasalan", "value": "C84534"},
             {"label": "Kabuntalan", "value": "C84535"},
             {"label": "Kagay", "value": "C84536"},
             {"label": "Kajatian", "value": "C84537"},
             {"label": "Kalang", "value": "C84538"},
             {"label": "Kalbugan", "value": "C84539"},
             {"label": "Kalingalan Caluang", "value": "C84540"},
             {"label": "Kambing", "value": "C84541"},
             {"label": "Kanlagay", "value": "C84542"},
             {"label": "Kansipati", "value": "C84543"},
             {"label": "Kapai", "value": "C84544"},
             {"label": "Karungdong", "value": "C84545"},
             {"label": "Katico", "value": "C84546"},
             {"label": "Katidtuan", "value": "C84547"},
             {"label": "Katuli", "value": "C84548"},
             {"label": "Kauran", "value": "C84549"},
             {"label": "Kitango", "value": "C84550"},
             {"label": "Kitapak", "value": "C84551"},
             {"label": "Kolape", "value": "C84552"},
             {"label": "Kulase", "value": "C84553"},
             {"label": "Kulay-Kulay", "value": "C84554"},
             {"label": "Kulempang", "value": "C84555"},
             {"label": "Kungtad", "value": "C84556"},
             {"label": "Labungan", "value": "C84557"},
             {"label": "Laminusa", "value": "C84558"},
             {"label": "Lamitan", "value": "C84559"},
             {"label": "Lamitan City", "value": "C84560"},
             {"label": "Langpas", "value": "C84561"},
             {"label": "Languyan", "value": "C84562"},
             {"label": "Larap", "value": "C84563"},
             {"label": "Latung", "value": "C84564"},
             {"label": "Layog", "value": "C84565"},
             {"label": "Ligayan", "value": "C84566"},
             {"label": "Limbo", "value": "C84567"},
             {"label": "Litayan", "value": "C84568"},
             {"label": "Lookan", "value": "C84569"},
             {"label": "Lugus", "value": "C84570"},
             {"label": "Lumba-a-Bayabao", "value": "C84571"},
             {"label": "Lumbac", "value": "C84572"},
             {"label": "Lumbatan", "value": "C84573"},
             {"label": "Lumbayanague", "value": "C84574"},
             {"label": "Lutayan", "value": "C84575"},
             {"label": "Luuk", "value": "C84576"},
             {"label": "Luuk Datan", "value": "C84577"},
             {"label": "Madalum", "value": "C84578"},
             {"label": "Madamba", "value": "C84579"},
             {"label": "Maguing", "value": "C84580"},
             {"label": "Mahala", "value": "C84581"},
             {"label": "Maimbung", "value": "C84582"},
             {"label": "Makir", "value": "C84583"},
             {"label": "Malabang", "value": "C84584"},
             {"label": "Maluso", "value": "C84585"},
             {"label": "Manubul", "value": "C84586"},
             {"label": "Manuk Mangkaw", "value": "C84587"},
             {"label": "Marantao", "value": "C84588"},
             {"label": "Marawi", "value": "C84589"},
             {"label": "Marogong", "value": "C84590"},
             {"label": "Marsada", "value": "C84591"},
             {"label": "Marunggas", "value": "C84592"},
             {"label": "Masiu", "value": "C84593"},
             {"label": "Matanog", "value": "C84594"},
             {"label": "Mataya", "value": "C84595"},
             {"label": "Mauboh", "value": "C84596"},
             {"label": "Mileb", "value": "C84597"},
             {"label": "Molundo", "value": "C84598"},
             {"label": "Municipality of Indanan", "value": "C84599"},
             {"label": "Municipality of Lantawan", "value": "C84600"},
             {"label": "Municipality of Pangutaran", "value": "C84601"},
             {"label": "Municipality of Sultan Gumander", "value": "C84602"},
             {"label": "Municipality of Tongkil", "value": "C84603"},
             {"label": "New Batu Batu", "value": "C84604"},
             {"label": "New Panamao", "value": "C84605"},
             {"label": "Nuyo", "value": "C84606"},
             {"label": "Old Panamao", "value": "C84607"},
             {"label": "Pagalungan", "value": "C84608"},
             {"label": "Pagatin", "value": "C84609"},
             {"label": "Pagayawan", "value": "C84610"},
             {"label": "Paglat", "value": "C84611"},
             {"label": "Paitan", "value": "C84612"},
             {"label": "Panabuan", "value": "C84613"},
             {"label": "Panadtaban", "value": "C84614"},
             {"label": "Pandag", "value": "C84615"},
             {"label": "Pandakan", "value": "C84616"},
             {"label": "Pandami", "value": "C84617"},
             {"label": "Pandan Niog", "value": "C84618"},
             {"label": "Pang", "value": "C84619"},
             {"label": "Panglima Sugala", "value": "C84620"},
             {"label": "Pangutaran", "value": "C84621"},
             {"label": "Parang", "value": "C84622"},
             {"label": "Parangan", "value": "C84623"},
             {"label": "Parian Dakula", "value": "C84624"},
             {"label": "Pata", "value": "C84625"},
             {"label": "Patikul", "value": "C84626"},
             {"label": "Pawak", "value": "C84627"},
             {"label": "Payuhan", "value": "C84628"},
             {"label": "Piagapo", "value": "C84629"},
             {"label": "Pidsandawan", "value": "C84630"},
             {"label": "Pinaring", "value": "C84631"},
             {"label": "Pitogo", "value": "C84632"},
             {"label": "Poon-a-Bayabao", "value": "C84633"},
             {"label": "Pualas", "value": "C84634"},
             {"label": "Punay", "value": "C84635"},
             {"label": "Rimpeso", "value": "C84636"},
             {"label": "Rominimbang", "value": "C84637"},
             {"label": "Sagasa", "value": "C84638"},
             {"label": "Saguiaran", "value": "C84639"},
             {"label": "Sambuluan", "value": "C84640"},
             {"label": "Sanga-Sanga", "value": "C84641"},
             {"label": "Santa Clara", "value": "C84642"},
             {"label": "Sapa", "value": "C84643"},
             {"label": "Sapa-Sapa", "value": "C84644"},
             {"label": "Sapadun", "value": "C84645"},
             {"label": "Satan", "value": "C84646"},
             {"label": "Semut", "value": "C84647"},
             {"label": "Shariff Aguak", "value": "C84648"},
             {"label": "Siasi", "value": "C84649"},
             {"label": "Simuay", "value": "C84650"},
             {"label": "Simunul", "value": "C84651"},
             {"label": "Sionogan", "value": "C84652"},
             {"label": "Sitangkai", "value": "C84653"},
             {"label": "South Ubian", "value": "C84654"},
             {"label": "South Upi", "value": "C84655"},
             {"label": "Sultan Kudarat", "value": "C84656"},
             {"label": "Sultan sa Barongis", "value": "C84657"},
             {"label": "Sultan Sumagka", "value": "C84658"},
             {"label": "Sumisip", "value": "C84659"},
             {"label": "Tabiauan", "value": "C84660"},
             {"label": "Tablas", "value": "C84661"},
             {"label": "Tairan Camp", "value": "C84662"},
             {"label": "Talayan", "value": "C84663"},
             {"label": "Talipao", "value": "C84664"},
             {"label": "Talipaw", "value": "C84665"},
             {"label": "Tampakan", "value": "C84666"},
             {"label": "Tamparan", "value": "C84667"},
             {"label": "Tandubas", "value": "C84668"},
             {"label": "Tapayan", "value": "C84669"},
             {"label": "Tapikan", "value": "C84670"},
             {"label": "Tapul", "value": "C84671"},
             {"label": "Taraka", "value": "C84672"},
             {"label": "Taungoh", "value": "C84673"},
             {"label": "Taviran", "value": "C84674"},
             {"label": "Tinutulan", "value": "C84675"},
             {"label": "Tipo-Tipo", "value": "C84676"},
             {"label": "Tongouson", "value": "C84677"},
             {"label": "Tubaran", "value": "C84678"},
             {"label": "Tuburan", "value": "C84679"},
             {"label": "Tugaya", "value": "C84680"},
             {"label": "Tumbagaan", "value": "C84681"},
             {"label": "Tunggol", "value": "C84682"},
             {"label": "Tungol", "value": "C84683"},
             {"label": "Turtle Islands", "value": "C84684"},
             {"label": "Ungus-Ungus", "value": "C84685"},
             {"label": "Upi", "value": "C84686"},
             {"label": "Uyaan", "value": "C84687"},
             {"label": "Wao", "value": "C84688"}],
 "Cagayan Valley": [{"label": "Abulug", "value": "C84689"},
                    {"label": "Abut", "value": "C84690"},
                    {"label": "Accusilian", "value": "C84691"},
                    {"label": "Afusing Centro", "value": "C84692"},
                    {"label": "Aggugaddah", "value": "C84693"},
                    {"label": "Alabug", "value": "C84694"},
                    {"label": "Alannay", "value": "C84695"},
                    {"label": "Alcala", "value": "C84696"},
                    {"label": "Alibago", "value": "C84697"},
                    {"label": "Alicia", "value": "C84698"},
                    {"label": "Allacapan", "value": "C84699"},
                    {"label": "Almaguer North", "value": "C84700"},
                    {"label": "Amulung", "value": "C84701"},
                    {"label": "Angadanan", "value": "C84702"},
                    {"label": "Antagan Segunda", "value": "C84703"},
                    {"label": "Aparri", "value": "C84704"},
                    {"label": "Aritao", "value": "C84705"},
                    {"label": "Atulayan", "value": "C84706"},
                    {"label": "Aurora", "value": "C84707"},
                    {"label": "Awallan", "value": "C84708"},
                    {"label": "Bacnor East", "value": "C84709"},
                    {"label": "Baggabag B", "value": "C84710"},
                    {"label": "Bagong Tanza", "value": "C84711"},
                    {"label": "Bagu", "value": "C84712"},
                    {"label": "Bagumbayan", "value": "C84713"},
                    {"label": "Ballesteros", "value": "C84714"},
                    {"label": "Bambang", "value": "C84715"},
                    {"label": "Bangad", "value": "C84716"},
                    {"label": "Banganan", "value": "C84717"},
                    {"label": "Banquero", "value": "C84718"},
                    {"label": "Barucboc Norte", "value": "C84719"},
                    {"label": "Basco", "value": "C84720"},
                    {"label": "Batal", "value": "C84721"},
                    {"label": "Battung", "value": "C84722"},
                    {"label": "Bauan", "value": "C84723"},
                    {"label": "Bayombong", "value": "C84724"},
                    {"label": "Belance", "value": "C84725"},
                    {"label": "Benito Soliven", "value": "C84726"},
                    {"label": "Binalan", "value": "C84727"},
                    {"label": "Binguang", "value": "C84728"},
                    {"label": "Bintawan", "value": "C84729"},
                    {"label": "Bitag Grande", "value": "C84730"},
                    {"label": "Bone South", "value": "C84731"},
                    {"label": "Buguey", "value": "C84732"},
                    {"label": "Buliwao", "value": "C84733"},
                    {"label": "Bulu", "value": "C84734"},
                    {"label": "Burgos", "value": "C84735"},
                    {"label": "Busilak", "value": "C84736"},
                    {"label": "Cabagan", "value": "C84737"},
                    {"label": "Cabannungan Second", "value": "C84738"},
                    {"label": "Cabaritan East", "value": "C84739"},
                    {"label": "Cabarroguis", "value": "C84740"},
                    {"label": "Cabatuan", "value": "C84741"},
                    {"label": "Cabiraoan", "value": "C84742"},
                    {"label": "Cabulay", "value": "C84743"},
                    {"label": "Calamagui East", "value": "C84744"},
                    {"label": "Calantac", "value": "C84745"},
                    {"label": "Calaoagan", "value": "C84746"},
                    {"label": "Calinaoan Malasin", "value": "C84747"},
                    {"label": "Calog Norte", "value": "C84748"},
                    {"label": "Camalaniugan", "value": "C84749"},
                    {"label": "Capissayan Sur", "value": "C84750"},
                    {"label": "Carig", "value": "C84751"},
                    {"label": "Casambalangan", "value": "C84752"},
                    {"label": "Catayauan", "value": "C84753"},
                    {"label": "Claveria", "value": "C84754"},
                    {"label": "Cordon", "value": "C84755"},
                    {"label": "Cullalabo del Sur", "value": "C84756"},
                    {"label": "Dagupan", "value": "C84757"},
                    {"label": "Dalaoig", "value": "C84758"},
                    {"label": "Daragutan", "value": "C84759"},
                    {"label": "Dassun", "value": "C84760"},
                    {"label": "Diadi", "value": "C84761"},
                    {"label": "Diamantina", "value": "C84762"},
                    {"label": "Dibuluan", "value": "C84763"},
                    {"label": "Dicabisagan", "value": "C84764"},
                    {"label": "Dicamay", "value": "C84765"},
                    {"label": "Diffun", "value": "C84766"},
                    {"label": "Divisoria", "value": "C84767"},
                    {"label": "Dodan", "value": "C84768"},
                    {"label": "Dumabato", "value": "C84769"},
                    {"label": "Echague", "value": "C84770"},
                    {"label": "Eden", "value": "C84771"},
                    {"label": "Enrile", "value": "C84772"},
                    {"label": "Esperanza East", "value": "C84773"},
                    {"label": "Estefania", "value": "C84774"},
                    {"label": "Furao", "value": "C84775"},
                    {"label": "Gadu", "value": "C84776"},
                    {"label": "Gammad", "value": "C84777"},
                    {"label": "Gamu", "value": "C84778"},
                    {"label": "Ganapi", "value": "C84779"},
                    {"label": "Gappal", "value": "C84780"},
                    {"label": "Gattaran", "value": "C84781"},
                    {"label": "Gonzaga", "value": "C84782"},
                    {"label": "Guiddam", "value": "C84783"},
                    {"label": "Ibung", "value": "C84784"},
                    {"label": "Iguig", "value": "C84785"},
                    {"label": "Ilagan", "value": "C84786"},
                    {"label": "Ineangan", "value": "C84787"},
                    {"label": "Iraga", "value": "C84788"},
                    {"label": "Itbayat", "value": "C84789"},
                    {"label": "Ivana", "value": "C84790"},
                    {"label": "Jones", "value": "C84791"},
                    {"label": "Kasibu", "value": "C84792"},
                    {"label": "La Paz", "value": "C84793"},
                    {"label": "Lallayug", "value": "C84794"},
                    {"label": "Lanna", "value": "C84795"},
                    {"label": "Lapi", "value": "C84796"},
                    {"label": "Larion Alto", "value": "C84797"},
                    {"label": "Lasam", "value": "C84798"},
                    {"label": "Lucban", "value": "C84799"},
                    {"label": "Luna", "value": "C84800"},
                    {"label": "Mabasa", "value": "C84801"},
                    {"label": "Mabini", "value": "C84802"},
                    {"label": "Mabuttal East", "value": "C84803"},
                    {"label": "Maddarulug Norte", "value": "C84804"},
                    {"label": "Maddela", "value": "C84805"},
                    {"label": "Magalalag", "value": "C84806"},
                    {"label": "Magapit", "value": "C84807"},
                    {"label": "Magapit, Aguiguican", "value": "C84808"},
                    {"label": "Magdalena", "value": "C84809"},
                    {"label": "Magsaysay", "value": "C84810"},
                    {"label": "Maguilling", "value": "C84811"},
                    {"label": "Mahatao", "value": "C84812"},
                    {"label": "Malasin", "value": "C84813"},
                    {"label": "Mallig", "value": "C84814"},
                    {"label": "Maluno Sur", "value": "C84815"},
                    {"label": "Manaring", "value": "C84816"},
                    {"label": "Manga", "value": "C84817"},
                    {"label": "Masaya Sur", "value": "C84818"},
                    {"label": "Masipi West", "value": "C84819"},
                    {"label": "Maxingal", "value": "C84820"},
                    {"label": "Minallo", "value": "C84821"},
                    {"label": "Minanga Norte", "value": "C84822"},
                    {"label": "Minante Segundo", "value": "C84823"},
                    {"label": "Minuri", "value": "C84824"},
                    {"label": "Mozzozzin Sur", "value": "C84825"},
                    {"label": "Mungo", "value": "C84826"},
                    {"label": "Munoz East", "value": "C84827"},
                    {"label": "Nabannagan West", "value": "C84828"},
                    {"label": "Nagrumbuan", "value": "C84829"},
                    {"label": "Nagtipunan", "value": "C84830"},
                    {"label": "Naguilian", "value": "C84831"},
                    {"label": "Namuac", "value": "C84832"},
                    {"label": "Nattapian", "value": "C84833"},
                    {"label": "Paddaya", "value": "C84834"},
                    {"label": "Palagao Norte", "value": "C84835"},
                    {"label": "Pamplona", "value": "C84836"},
                    {"label": "Pangal Sur", "value": "C84837"},
                    {"label": "Pata", "value": "C84838"},
                    {"label": "Pattao", "value": "C84839"},
                    {"label": "Penablanca", "value": "C84840"},
                    {"label": "Piat", "value": "C84841"},
                    {"label": "Pilig", "value": "C84842"},
                    {"label": "Pinoma", "value": "C84843"},
                    {"label": "Province of Batanes", "value": "C84844"},
                    {"label": "Province of Cagayan", "value": "C84845"},
                    {"label": "Province of Isabela", "value": "C84846"},
                    {"label": "Province of Nueva Vizcaya", "value": "C84847"},
                    {"label": "Province of Quirino", "value": "C84848"},
                    {"label": "Quezon", "value": "C84849"},
                    {"label": "Quibal", "value": "C84850"},
                    {"label": "Quirino", "value": "C84851"},
                    {"label": "Ragan Norte", "value": "C84852"},
                    {"label": "Ramon", "value": "C84853"},
                    {"label": "Ramos West", "value": "C84854"},
                    {"label": "Reina Mercedes", "value": "C84855"},
                    {"label": "Rizal", "value": "C84856"},
                    {"label": "Roxas", "value": "C84857"},
                    {"label": "Sabtang", "value": "C84858"},
                    {"label": "Saguday", "value": "C84859"},
                    {"label": "Salinas", "value": "C84860"},
                    {"label": "Salinungan Proper", "value": "C84861"},
                    {"label": "San Antonio", "value": "C84862"},
                    {"label": "San Bernardo", "value": "C84863"},
                    {"label": "San Fernando", "value": "C84864"},
                    {"label": "San Guillermo", "value": "C84865"},
                    {"label": "San Jose", "value": "C84866"},
                    {"label": "San Juan", "value": "C84867"},
                    {"label": "San Luis", "value": "C84868"},
                    {"label": "San Manuel", "value": "C84869"},
                    {"label": "San Mariano", "value": "C84870"},
                    {"label": "San Mateo", "value": "C84871"},
                    {"label": "San Pedro", "value": "C84872"},
                    {"label": "San Vicente", "value": "C84873"},
                    {"label": "Sandiat Centro", "value": "C84874"},
                    {"label": "Santa Ana", "value": "C84875"},
                    {"label": "Santa Cruz", "value": "C84876"},
                    {"label": "Santa Fe", "value": "C84877"},
                    {"label": "Santa Maria", "value": "C84878"},
                    {"label": "Santa Praxedes", "value": "C84879"},
                    {"label": "Santa Teresita", "value": "C84880"},
                    {"label": "Santiago", "value": "C84881"},
                    {"label": "Santo Domingo", "value": "C84882"},
                    {"label": "Santo Nino", "value": "C84883"},
                    {"label": "Santo Tomas", "value": "C84884"},
                    {"label": "Siempre Viva", "value": "C84885"},
                    {"label": "Sillawit", "value": "C84886"},
                    {"label": "Simanu Sur", "value": "C84887"},
                    {"label": "Simimbaan", "value": "C84888"},
                    {"label": "Sinamar", "value": "C84889"},
                    {"label": "Sindon", "value": "C84890"},
                    {"label": "Solana", "value": "C84891"},
                    {"label": "Solano", "value": "C84892"},
                    {"label": "Soyung", "value": "C84893"},
                    {"label": "Taguing", "value": "C84894"},
                    {"label": "Tapel", "value": "C84895"},
                    {"label": "Tuao", "value": "C84896"},
                    {"label": "Tuguegarao", "value": "C84897"},
                    {"label": "Tumauini", "value": "C84898"},
                    {"label": "Tupang", "value": "C84899"},
                    {"label": "Uddiawan", "value": "C84900"},
                    {"label": "Ugac Sur", "value": "C84901"},
                    {"label": "Ugad", "value": "C84902"},
                    {"label": "Upi", "value": "C84903"},
                    {"label": "Uyugan", "value": "C84904"},
                    {"label": "Yeban Norte", "value": "C84905"}],
 "Cahul District": [{"label": "Cahul", "value": "C75490"},
                    {"label": "Giurgiulesti", "value": "C75491"}],
 "Cair Municipality": [{"label": "Cair", "value": "C65381"}],
 "Cairo": [{"label": "Badr", "value": "C24238"},
           {"label": "Bulaq", "value": "C24239"},
           {"label": "Cairo", "value": "C24240"},
           {"label": "El Mataria", "value": "C24241"},
           {"label": "Fustat", "value": "C24242"},
           {"label": "Hadayek El Kobba", "value": "C24243"},
           {"label": "Heliopolis", "value": "C24244"},
           {"label": "Helwan", "value": "C24245"},
           {"label": "Maadi", "value": "C24246"},
           {"label": "Musturud", "value": "C24247"},
           {"label": "New Administrative Capital of Egypt", "value": "C24248"},
           {"label": "Shubra", "value": "C24249"},
           {"label": "Tura", "value": "C24250"}],
 "Cajamarca": [{"label": "Bambamarca", "value": "C80722"},
               {"label": "Bellavista", "value": "C80723"},
               {"label": "Cajabamba", "value": "C80724"},
               {"label": "Cajamarca", "value": "C80725"},
               {"label": "Celendin", "value": "C80726"},
               {"label": "Chota", "value": "C80727"},
               {"label": "Jaen", "value": "C80728"},
               {"label": "Provincia de Cajabamba", "value": "C80729"},
               {"label": "Provincia de Cajamarca", "value": "C80730"},
               {"label": "Provincia de Celendin", "value": "C80731"},
               {"label": "Provincia de Chota", "value": "C80732"},
               {"label": "Provincia de Contumaza", "value": "C80733"},
               {"label": "Provincia de Cutervo", "value": "C80734"},
               {"label": "Provincia de Hualgayoc", "value": "C80735"},
               {"label": "Provincia de Jaen", "value": "C80736"},
               {"label": "Provincia de San Ignacio", "value": "C80737"},
               {"label": "Provincia de San Miguel", "value": "C80738"},
               {"label": "Provincia de Santa Cruz", "value": "C80739"},
               {"label": "San Ignacio", "value": "C80740"}],
 "Calabria": [{"label": "Acconia", "value": "C52627"},
              {"label": "Acquaformosa", "value": "C52628"},
              {"label": "Acquappesa", "value": "C52629"},
              {"label": "Acquaro", "value": "C52630"},
              {"label": "Acri", "value": "C52631"},
              {"label": "Africo Nuovo", "value": "C52632"},
              {"label": "Africo Vecchio", "value": "C52633"},
              {"label": "Agnana Calabra", "value": "C52634"},
              {"label": "Aiello Calabro", "value": "C52635"},
              {"label": "Aieta", "value": "C52636"},
              {"label": "Albi", "value": "C52637"},
              {"label": "Albidona", "value": "C52638"},
              {"label": "Alessandria del Carretto", "value": "C52639"},
              {"label": "Altilia", "value": "C52640"},
              {"label": "Altomonte", "value": "C52641"},
              {"label": "Amantea", "value": "C52642"},
              {"label": "Amaroni", "value": "C52643"},
              {"label": "Amato", "value": "C52644"},
              {"label": "Amendolara", "value": "C52645"},
              {"label": "Amica", "value": "C52646"},
              {"label": "Andali", "value": "C52647"},
              {"label": "Andreotta", "value": "C52648"},
              {"label": "Anoia Inferiore", "value": "C52649"},
              {"label": "Anoia Superiore", "value": "C52650"},
              {"label": "Antonimina", "value": "C52651"},
              {"label": "Aprigliano", "value": "C52652"},
              {"label": "Arcavacata", "value": "C52653"},
              {"label": "Ardore", "value": "C52654"},
              {"label": "Ardore Marina", "value": "C52655"},
              {"label": "Arena", "value": "C52656"},
              {"label": "Argusto", "value": "C52657"},
              {"label": "Badolato", "value": "C52658"},
              {"label": "Badolato Marina", "value": "C52659"},
              {"label": "Bagaladi", "value": "C52660"},
              {"label": "Bagnara Calabra", "value": "C52661"},
              {"label": "Barone", "value": "C52662"},
              {"label": "Belcastro", "value": "C52663"},
              {"label": "Belmonte Calabro", "value": "C52664"},
              {"label": "Belsito", "value": "C52665"},
              {"label": "Belvedere Marittimo", "value": "C52666"},
              {"label": "Belvedere Spinello", "value": "C52667"},
              {"label": "Benestare", "value": "C52668"},
              {"label": "Bianchi", "value": "C52669"},
              {"label": "Bianco", "value": "C52670"},
              {"label": "Bisignano", "value": "C52671"},
              {"label": "Bivongi", "value": "C52672"},
              {"label": "Bocchigliero", "value": "C52673"},
              {"label": "Bonifati", "value": "C52674"},
              {"label": "Borgata Marina", "value": "C52675"},
              {"label": "Borgia", "value": "C52676"},
              {"label": "Botricello", "value": "C52677"},
              {"label": "Bova", "value": "C52678"},
              {"label": "Bova Marina", "value": "C52679"},
              {"label": "Bovalino", "value": "C52680"},
              {"label": "Bovalino Superiore", "value": "C52681"},
              {"label": "Brancaleone", "value": "C52682"},
              {"label": "Brancaleone-Marina", "value": "C52683"},
              {"label": "Briatico", "value": "C52684"},
              {"label": "Brognaturo", "value": "C52685"},
              {"label": "Bruzzano Zeffirio", "value": "C52686"},
              {"label": "Buonvicino", "value": "C52687"},
              {"label": "Caccuri", "value": "C52688"},
              {"label": "Calanna", "value": "C52689"},
              {"label": "Caloppezzati", "value": "C52690"},
              {"label": "Caloveto", "value": "C52691"},
              {"label": "Calvisi", "value": "C52692"},
              {"label": "Camini", "value": "C52693"},
              {"label": "Campana", "value": "C52694"},
              {"label": "Campo Calabro", "value": "C52695"},
              {"label": "Campora San Giovanni", "value": "C52696"},
              {"label": "Candidoni", "value": "C52697"},
              {"label": "Canna", "value": "C52698"},
              {"label": "Canolo", "value": "C52699"},
              {"label": "Cantinella", "value": "C52700"},
              {"label": "Capistrano", "value": "C52701"},
              {"label": "Capo Rizzuto", "value": "C52702"},
              {"label": "Caraffa del Bianco", "value": "C52703"},
              {"label": "Caraffa di Catanzaro", "value": "C52704"},
              {"label": "Cardeto", "value": "C52705"},
              {"label": "Cardinale", "value": "C52706"},
              {"label": "Careri", "value": "C52707"},
              {"label": "Carfizzi", "value": "C52708"},
              {"label": "Cariati", "value": "C52709"},
              {"label": "Carlopoli", "value": "C52710"},
              {"label": "Carolei", "value": "C52711"},
              {"label": "Carpanzano", "value": "C52712"},
              {"label": "Casabona", "value": "C52713"},
              {"label": "Casignana", "value": "C52714"},
              {"label": "Casole Bruzio", "value": "C52715"},
              {"label": "Cassano Allo Ionio", "value": "C52716"},
              {"label": "Castelsilano", "value": "C52717"},
              {"label": "Castiglione Cosentino", "value": "C52718"},
              {"label": "Castrolibero", "value": "C52719"},
              {"label": "Castroregio", "value": "C52720"},
              {"label": "Castrovillari", "value": "C52721"},
              {"label": "Catanzaro", "value": "C52722"},
              {"label": "Caulonia", "value": "C52723"},
              {"label": "Caulonia Marina", "value": "C52724"},
              {"label": "Cava-Cuculera Nobile", "value": "C52725"},
              {"label": "Cavoni-Ginestreto", "value": "C52726"},
              {"label": "Celico", "value": "C52727"},
              {"label": "Cellara", "value": "C52728"},
              {"label": "Cenadi", "value": "C52729"},
              {"label": "Centrache", "value": "C52730"},
              {"label": "Ceramida-Pellegrina", "value": "C52731"},
              {"label": "Cerchiara di Calabria", "value": "C52732"},
              {"label": "Cerenzia", "value": "C52733"},
              {"label": "Cerisano", "value": "C52734"},
              {"label": "Cerva", "value": "C52735"},
              {"label": "Cervicati", "value": "C52736"},
              {"label": "Cerzeto", "value": "C52737"},
              {"label": "Cessaniti", "value": "C52738"},
              {"label": "Cetraro", "value": "C52739"},
              {"label": "Cetraro Marina", "value": "C52740"},
              {"label": "Chiaravalle Centrale", "value": "C52741"},
              {"label": "Cicala", "value": "C52742"},
              {"label": "Cimina", "value": "C52743"},
              {"label": "Cinque Frondi", "value": "C52744"},
              {"label": "Ciro", "value": "C52745"},
              {"label": "Ciro Marina", "value": "C52746"},
              {"label": "Cittadella del Capo", "value": "C52747"},
              {"label": "Cittanova", "value": "C52748"},
              {"label": "Civita", "value": "C52749"},
              {"label": "Cleto", "value": "C52750"},
              {"label": "Colosimi", "value": "C52751"},
              {"label": "Condofuri", "value": "C52752"},
              {"label": "Conflenti", "value": "C52753"},
              {"label": "Corigliano Calabro", "value": "C52754"},
              {"label": "Corigliano Scalo", "value": "C52755"},
              {"label": "Cortale", "value": "C52756"},
              {"label": "Cosenza", "value": "C52757"},
              {"label": "Cosoleto", "value": "C52758"},
              {"label": "Cotronei", "value": "C52759"},
              {"label": "Crichi", "value": "C52760"},
              {"label": "Cropalati", "value": "C52761"},
              {"label": "Cropani", "value": "C52762"},
              {"label": "Cropani Marina", "value": "C52763"},
              {"label": "Crosia", "value": "C52764"},
              {"label": "Crotone", "value": "C52765"},
              {"label": "Crucoli", "value": "C52766"},
              {"label": "Curinga", "value": "C52767"},
              {"label": "Cutro", "value": "C52768"},
              {"label": "Dasa", "value": "C52769"},
              {"label": "Davoli", "value": "C52770"},
              {"label": "Decollatura", "value": "C52771"},
              {"label": "Delianuova", "value": "C52772"},
              {"label": "Diamante", "value": "C52773"},
              {"label": "Dinami", "value": "C52774"},
              {"label": "Dipignano", "value": "C52775"},
              {"label": "Domanico", "value": "C52776"},
              {"label": "Donnici Inferiore", "value": "C52777"},
              {"label": "Doria", "value": "C52778"},
              {"label": "Drapia", "value": "C52779"},
              {"label": "Fabrizia", "value": "C52780"},
              {"label": "Fabrizio", "value": "C52781"},
              {"label": "Fagnano Castello", "value": "C52782"},
              {"label": "Falconara Albanese", "value": "C52783"},
              {"label": "Falerna", "value": "C52784"},
              {"label": "Falerna Scalo", "value": "C52785"},
              {"label": "Feroleto Antico", "value": "C52786"},
              {"label": "Feroleto della Chiesa", "value": "C52787"},
              {"label": "Ferruzzano", "value": "C52788"},
              {"label": "Figline Vegliaturo", "value": "C52789"},
              {"label": "Filadelfia", "value": "C52790"},
              {"label": "Filandari", "value": "C52791"},
              {"label": "Filogaso", "value": "C52792"},
              {"label": "Firmo", "value": "C52793"},
              {"label": "Fiumara", "value": "C52794"},
              {"label": "Fiumefreddo Bruzio", "value": "C52795"},
              {"label": "Foresta", "value": "C52796"},
              {"label": "Fossato Ionico-Fossatello-San Luca Marcelluzzo",
               "value": "C52797"},
              {"label": "Fossato Serralta", "value": "C52798"},
              {"label": "Francavilla Angitola", "value": "C52799"},
              {"label": "Francavilla Marittima", "value": "C52800"},
              {"label": "Francica", "value": "C52801"},
              {"label": "Frascineto", "value": "C52802"},
              {"label": "Fuscaldo", "value": "C52803"},
              {"label": "Gagliato", "value": "C52804"},
              {"label": "Galatro", "value": "C52805"},
              {"label": "Gasperina", "value": "C52806"},
              {"label": "Gerace", "value": "C52807"},
              {"label": "Gerocarne", "value": "C52808"},
              {"label": "Giffone", "value": "C52809"},
              {"label": "Gimigliano", "value": "C52810"},
              {"label": "Gioia Tauro", "value": "C52811"},
              {"label": "Gioiosa Ionica", "value": "C52812"},
              {"label": "Girifalco", "value": "C52813"},
              {"label": "Gizzeria", "value": "C52814"},
              {"label": "Grimaldi", "value": "C52815"},
              {"label": "Grisolia", "value": "C52816"},
              {"label": "Grotteria", "value": "C52817"},
              {"label": "Guardavalle", "value": "C52818"},
              {"label": "Guardavalle Marina", "value": "C52819"},
              {"label": "Guardia Piemontese", "value": "C52820"},
              {"label": "Isca Marina", "value": "C52821"},
              {"label": "Isca sullo Ionio", "value": "C52822"},
              {"label": "Isola di Capo Rizzuto", "value": "C52823"},
              {"label": "Jacurso", "value": "C52824"},
              {"label": "Jonadi", "value": "C52825"},
              {"label": "Joppolo", "value": "C52826"},
              {"label": "Laganadi", "value": "C52827"},
              {"label": "Lago", "value": "C52828"},
              {"label": "Laino Borgo", "value": "C52829"},
              {"label": "Laino Castello-Nuovo Centro", "value": "C52830"},
              {"label": "Lamezia Terme", "value": "C52831"},
              {"label": "Lappano", "value": "C52832"},
              {"label": "Lattarico", "value": "C52833"},
              {"label": "Laureana di Borrello", "value": "C52834"},
              {"label": "Laurignano", "value": "C52835"},
              {"label": "Lazzaro", "value": "C52836"},
              {"label": "Le Castella", "value": "C52837"},
              {"label": "Limbadi-Caroni", "value": "C52838"},
              {"label": "Locri", "value": "C52839"},
              {"label": "Longobardi", "value": "C52840"},
              {"label": "Longobucco", "value": "C52841"},
              {"label": "Lungro", "value": "C52842"},
              {"label": "Luzzi", "value": "C52843"},
              {"label": "Magisano", "value": "C52844"},
              {"label": "Maida", "value": "C52845"},
              {"label": "Maiera", "value": "C52846"},
              {"label": "Maierato", "value": "C52847"},
              {"label": "Malito", "value": "C52848"},
              {"label": "Malvito", "value": "C52849"},
              {"label": "Mammola", "value": "C52850"},
              {"label": "Mandatoriccio", "value": "C52851"},
              {"label": "Mangone", "value": "C52852"},
              {"label": "Marano Marchesato", "value": "C52853"},
              {"label": "Marano Principato", "value": "C52854"},
              {"label": "Marcedusa", "value": "C52855"},
              {"label": "Marcellina", "value": "C52856"},
              {"label": "Marcellinara", "value": "C52857"},
              {"label": "Marina", "value": "C52858"},
              {"label": "Marina di Davoli", "value": "C52859"},
              {"label": "Marina di Fuscaldo", "value": "C52860"},
              {"label": "Marina di Gioiosa Ionica", "value": "C52861"},
              {"label": "Marina di Schiavonea", "value": "C52862"},
              {"label": "Maropati", "value": "C52863"},
              {"label": "Martelli-Laganosa", "value": "C52864"},
              {"label": "Martirano", "value": "C52865"},
              {"label": "Martirano Lombardo", "value": "C52866"},
              {"label": "Martone", "value": "C52867"},
              {"label": "Marzi", "value": "C52868"},
              {"label": "Melicucca", "value": "C52869"},
              {"label": "Melicucco", "value": "C52870"},
              {"label": "Melissa", "value": "C52871"},
              {"label": "Melito di Porto Salvo", "value": "C52872"},
              {"label": "Mendicino", "value": "C52873"},
              {"label": "Mesoraca", "value": "C52874"},
              {"label": "Messignadi", "value": "C52875"},
              {"label": "Miglierina", "value": "C52876"},
              {"label": "Mileto", "value": "C52877"},
              {"label": "Mirto", "value": "C52878"},
              {"label": "Molochio", "value": "C52879"},
              {"label": "Monasterace", "value": "C52880"},
              {"label": "Monasterace Marina", "value": "C52881"},
              {"label": "Mongiana", "value": "C52882"},
              {"label": "Mongrassano", "value": "C52883"},
              {"label": "Montalto Uffugo", "value": "C52884"},
              {"label": "Montauro", "value": "C52885"},
              {"label": "Montebello Jonico", "value": "C52886"},
              {"label": "Montegiordano", "value": "C52887"},
              {"label": "Montepaone", "value": "C52888"},
              {"label": "Montepaone Lido", "value": "C52889"},
              {"label": "Monterosso Calabro", "value": "C52890"},
              {"label": "Morano Calabro", "value": "C52891"},
              {"label": "Mormanno", "value": "C52892"},
              {"label": "Mosorrofa", "value": "C52893"},
              {"label": "Motta San Giovanni", "value": "C52894"},
              {"label": "Motta Santa Lucia", "value": "C52895"},
              {"label": "Mottafollone", "value": "C52896"},
              {"label": "Nardodipace", "value": "C52897"},
              {"label": "Natile Nuovo", "value": "C52898"},
              {"label": "Nicastro", "value": "C52899"},
              {"label": "Nicotera", "value": "C52900"},
              {"label": "Nocara", "value": "C52901"},
              {"label": "Nocera Scalo", "value": "C52902"},
              {"label": "Nocera Terinese", "value": "C52903"},
              {"label": "Olivadi", "value": "C52904"},
              {"label": "Oppido Mamertina", "value": "C52905"},
              {"label": "Oriolo", "value": "C52906"},
              {"label": "Orsomarso", "value": "C52907"},
              {"label": "Pagliarelle", "value": "C52908"},
              {"label": "Palermiti", "value": "C52909"},
              {"label": "Palizzi", "value": "C52910"},
              {"label": "Palizzi Marina", "value": "C52911"},
              {"label": "Pallagorio", "value": "C52912"},
              {"label": "Palmi", "value": "C52913"},
              {"label": "Paludi", "value": "C52914"},
              {"label": "Panettieri", "value": "C52915"},
              {"label": "Pannaconi", "value": "C52916"},
              {"label": "Paola", "value": "C52917"},
              {"label": "Papanice", "value": "C52918"},
              {"label": "Papasidero", "value": "C52919"},
              {"label": "Paravati", "value": "C52920"},
              {"label": "Parenti", "value": "C52921"},
              {"label": "Parghelia", "value": "C52922"},
              {"label": "Paterno Calabro", "value": "C52923"},
              {"label": "Pavigliana", "value": "C52924"},
              {"label": "Pazzano", "value": "C52925"},
              {"label": "Pedace-Perito", "value": "C52926"},
              {"label": "Pedivigliano", "value": "C52927"},
              {"label": "Pellaro", "value": "C52928"},
              {"label": "Pentone", "value": "C52929"},
              {"label": "Pernocari-Presinaci", "value": "C52930"},
              {"label": "Petilia Policastro", "value": "C52931"},
              {"label": "Petrizzi", "value": "C52932"},
              {"label": "Petrona", "value": "C52933"},
              {"label": "Piane Crati", "value": "C52934"},
              {"label": "Pianopoli", "value": "C52935"},
              {"label": "Pietrafitta", "value": "C52936"},
              {"label": "Pietrapaola", "value": "C52937"},
              {"label": "Piscopio", "value": "C52938"},
              {"label": "Pizzo", "value": "C52939"},
              {"label": "Pizzoni", "value": "C52940"},
              {"label": "Placanica", "value": "C52941"},
              {"label": "Plataci", "value": "C52942"},
              {"label": "Platania", "value": "C52943"},
              {"label": "Plati", "value": "C52944"},
              {"label": "Polia", "value": "C52945"},
              {"label": "Polistena", "value": "C52946"},
              {"label": "Portigliola", "value": "C52947"},
              {"label": "Praia a Mare", "value": "C52948"},
              {"label": "Provincia di Catanzaro", "value": "C52949"},
              {"label": "Provincia di Cosenza", "value": "C52950"},
              {"label": "Provincia di Crotone", "value": "C52951"},
              {"label": "Provincia di Reggio Calabria", "value": "C52952"},
              {"label": "Provincia di Vibo-Valentia", "value": "C52953"},
              {"label": "Quattromiglia", "value": "C52954"},
              {"label": "Reggio Calabria", "value": "C52955"},
              {"label": "Rende", "value": "C52956"},
              {"label": "Riace", "value": "C52957"},
              {"label": "Riace Marina", "value": "C52958"},
              {"label": "Ricadi", "value": "C52959"},
              {"label": "Rizziconi", "value": "C52960"},
              {"label": "Rocca di Neto", "value": "C52961"},
              {"label": "Rocca Imperiale", "value": "C52962"},
              {"label": "Rocca Imperiale Marina", "value": "C52963"},
              {"label": "Roccabernarda", "value": "C52964"},
              {"label": "Roccaforte del Greco", "value": "C52965"},
              {"label": "Roccella Ionica", "value": "C52966"},
              {"label": "Roccelletta", "value": "C52967"},
              {"label": "Roggiano Gravina", "value": "C52968"},
              {"label": "Roghudi", "value": "C52969"},
              {"label": "Rogliano", "value": "C52970"},
              {"label": "Rombiolo", "value": "C52971"},
              {"label": "Rosario", "value": "C52972"},
              {"label": "Rosarno", "value": "C52973"},
              {"label": "Rose", "value": "C52974"},
              {"label": "Roseto Capo Spulico", "value": "C52975"},
              {"label": "Rossano", "value": "C52976"},
              {"label": "Rossano Stazione", "value": "C52977"},
              {"label": "Rota Greca", "value": "C52978"},
              {"label": "Rovito", "value": "C52979"},
              {"label": "Saline Ioniche", "value": "C52980"},
              {"label": "Sambiase", "value": "C52981"},
              {"label": "Samo", "value": "C52982"},
              {"label": "San Basile", "value": "C52983"},
              {"label": "San Benedetto Ullano", "value": "C52984"},
              {"label": "San Calogero", "value": "C52985"},
              {"label": "San Carlo-Condofuri Marina", "value": "C52986"},
              {"label": "San Cosmo Albanese", "value": "C52987"},
              {"label": "San Costantino Calabro", "value": "C52988"},
              {"label": "San Demetrio Corone", "value": "C52989"},
              {"label": "San Donato di Ninea", "value": "C52990"},
              {"label": "San Ferdinando", "value": "C52991"},
              {"label": "San Fili", "value": "C52992"},
              {"label": "San Floro", "value": "C52993"},
              {"label": "San Giorgio Albanese", "value": "C52994"},
              {"label": "San Giorgio Morgeto", "value": "C52995"},
              {"label": "San Giovanni di Gerace", "value": "C52996"},
              {"label": "San Giovanni in Fiore", "value": "C52997"},
              {"label": "San Gregorio dIppona", "value": "C52998"},
              {"label": "San Lorenzo", "value": "C52999"},
              {"label": "San Lorenzo Bellizzi", "value": "C53000"},
              {"label": "San Lorenzo del Vallo", "value": "C53001"},
              {"label": "San Luca", "value": "C53002"},
              {"label": "San Lucido", "value": "C53003"},
              {"label": "San Mango dAquino", "value": "C53004"},
              {"label": "San Marco Argentano", "value": "C53005"},
              {"label": "San Martino", "value": "C53006"},
              {"label": "San Martino di Finita", "value": "C53007"},
              {"label": "San Mauro Marchesato", "value": "C53008"},
              {"label": "San Nico", "value": "C53009"},
              {"label": "San Nicola", "value": "C53010"},
              {"label": "San Nicola Arcella", "value": "C53011"},
              {"label": "San Nicola da Crissa", "value": "C53012"},
              {"label": "San Nicola dellAlto", "value": "C53013"},
              {"label": "San Nicolo", "value": "C53014"},
              {"label": "San Pietro a Maida", "value": "C53015"},
              {"label": "San Pietro Apostolo", "value": "C53016"},
              {"label": "San Pietro di Carida", "value": "C53017"},
              {"label": "San Pietro in Amantea", "value": "C53018"},
              {"label": "San Pietro in Guarano", "value": "C53019"},
              {"label": "San Procopio", "value": "C53020"},
              {"label": "San Roberto", "value": "C53021"},
              {"label": "San Sostene", "value": "C53022"},
              {"label": "San Sosti", "value": "C53023"},
              {"label": "San Vincenzo la Costa", "value": "C53024"},
              {"label": "San Vito Sullo Ionio", "value": "C53025"},
              {"label": "Sangineto", "value": "C53026"},
              {"label": "SantAgata del Bianco", "value": "C53027"},
              {"label": "SantAgata di Esaro", "value": "C53028"},
              {"label": "SantAlessio in Aspromonte", "value": "C53029"},
              {"label": "SantAndrea Apostolo dello Ionio", "value": "C53030"},
              {"label": "SantAndrea Ionio Marina", "value": "C53031"},
              {"label": "SantElia", "value": "C53032"},
              {"label": "SantEufemia dAspromonte", "value": "C53033"},
              {"label": "SantIlario dello Ionio", "value": "C53034"},
              {"label": "SantOnofrio", "value": "C53035"},
              {"label": "Santa Caterina Albanese", "value": "C53036"},
              {"label": "Santa Caterina dello Ionio", "value": "C53037"},
              {"label": "Santa Caterina dello Ionio Marina", "value": "C53038"},
              {"label": "Santa Cristina dAspromonte", "value": "C53039"},
              {"label": "Santa Domenica", "value": "C53040"},
              {"label": "Santa Domenica Talao", "value": "C53041"},
              {"label": "Santa Eufemia Lamezia", "value": "C53042"},
              {"label": "Santa Maria del Cedro", "value": "C53043"},
              {"label": "Santa Severina", "value": "C53044"},
              {"label": "Santa Sofia dEpiro", "value": "C53045"},
              {"label": "Santo Stefano", "value": "C53046"},
              {"label": "Santo Stefano di Rogliano", "value": "C53047"},
              {"label": "Santo Stefano in Aspromonte", "value": "C53048"},
              {"label": "Saracena", "value": "C53049"},
              {"label": "Sartano", "value": "C53050"},
              {"label": "Satriano", "value": "C53051"},
              {"label": "Savelli", "value": "C53052"},
              {"label": "Scala Coeli", "value": "C53053"},
              {"label": "Scalea", "value": "C53054"},
              {"label": "Scandale", "value": "C53055"},
              {"label": "Scarcelli", "value": "C53056"},
              {"label": "Scido", "value": "C53057"},
              {"label": "Scilla", "value": "C53058"},
              {"label": "Sellia", "value": "C53059"},
              {"label": "Sellia Marina", "value": "C53060"},
              {"label": "Seminara", "value": "C53061"},
              {"label": "Serra dAiello", "value": "C53062"},
              {"label": "Serra Pedace", "value": "C53063"},
              {"label": "Serra San Bruno", "value": "C53064"},
              {"label": "Serrastretta", "value": "C53065"},
              {"label": "Serrata", "value": "C53066"},
              {"label": "Sersale", "value": "C53067"},
              {"label": "Settimo", "value": "C53068"},
              {"label": "Settingiano", "value": "C53069"},
              {"label": "Siano", "value": "C53070"},
              {"label": "Sibari", "value": "C53071"},
              {"label": "Siderno", "value": "C53072"},
              {"label": "Simbario", "value": "C53073"},
              {"label": "Sinopoli", "value": "C53074"},
              {"label": "Sorbo San Basile", "value": "C53075"},
              {"label": "Sorianello", "value": "C53076"},
              {"label": "Soriano Calabro", "value": "C53077"},
              {"label": "Soverato Marina", "value": "C53078"},
              {"label": "Soverato Superiore", "value": "C53079"},
              {"label": "Soveria Mannelli", "value": "C53080"},
              {"label": "Soveria Simeri", "value": "C53081"},
              {"label": "Spadola", "value": "C53082"},
              {"label": "Spezzano Albanese", "value": "C53083"},
              {"label": "Spezzano della Sila", "value": "C53084"},
              {"label": "Spezzano Piccolo", "value": "C53085"},
              {"label": "Spilinga", "value": "C53086"},
              {"label": "Squillace", "value": "C53087"},
              {"label": "Squillace Lido", "value": "C53088"},
              {"label": "Staiti", "value": "C53089"},
              {"label": "Staletti", "value": "C53090"},
              {"label": "Stazione Montalto-Coretto", "value": "C53091"},
              {"label": "Stefanaconi", "value": "C53092"},
              {"label": "Stignano", "value": "C53093"},
              {"label": "Stilo", "value": "C53094"},
              {"label": "Strongoli", "value": "C53095"},
              {"label": "Surdo", "value": "C53096"},
              {"label": "Tarsia", "value": "C53097"},
              {"label": "Taurianova", "value": "C53098"},
              {"label": "Taverna", "value": "C53099"},
              {"label": "Terranova da Sibari", "value": "C53100"},
              {"label": "Terranova Sappo Minulio", "value": "C53101"},
              {"label": "Terravecchia", "value": "C53102"},
              {"label": "Tiriolo", "value": "C53103"},
              {"label": "Tivolille Pasquali-Merenzata", "value": "C53104"},
              {"label": "Torano Castello", "value": "C53105"},
              {"label": "Torre di Ruggiero", "value": "C53106"},
              {"label": "Torre Melissa", "value": "C53107"},
              {"label": "Torretta", "value": "C53108"},
              {"label": "Tortora", "value": "C53109"},
              {"label": "Tortora Marina", "value": "C53110"},
              {"label": "Trebisacce", "value": "C53111"},
              {"label": "Trenta", "value": "C53112"},
              {"label": "Tronca", "value": "C53113"},
              {"label": "Tropea", "value": "C53114"},
              {"label": "Umbriatico", "value": "C53115"},
              {"label": "Vaccarizzo Albanese", "value": "C53116"},
              {"label": "Vadue", "value": "C53117"},
              {"label": "Vallefiorita", "value": "C53118"},
              {"label": "Vallelonga", "value": "C53119"},
              {"label": "Varapodio", "value": "C53120"},
              {"label": "Vazzano", "value": "C53121"},
              {"label": "Verbicaro", "value": "C53122"},
              {"label": "Verzino", "value": "C53123"},
              {"label": "Vibo Valentia", "value": "C53124"},
              {"label": "Villa San Giovanni", "value": "C53125"},
              {"label": "Villapiana", "value": "C53126"},
              {"label": "Villapiana Lido", "value": "C53127"},
              {"label": "Weather Station", "value": "C53128"},
              {"label": "Zaccanopoli", "value": "C53129"},
              {"label": "Zagarise", "value": "C53130"},
              {"label": "Zambrone", "value": "C53131"},
              {"label": "Zumpano", "value": "C53132"},
              {"label": "Zungri", "value": "C53133"}],
 "Calarasi County": [{"label": "Alexandru Odobescu", "value": "C94022"},
                     {"label": "Aprozi", "value": "C94023"},
                     {"label": "Belciugatele", "value": "C94024"},
                     {"label": "Borcea", "value": "C94025"},
                     {"label": "Budesti", "value": "C94026"},
                     {"label": "Calarasi", "value": "C94027"},
                     {"label": "Cascioarele", "value": "C94028"},
                     {"label": "Ceacu", "value": "C94029"},
                     {"label": "Chirnogi", "value": "C94030"},
                     {"label": "Chiselet", "value": "C94031"},
                     {"label": "Ciocanesti", "value": "C94032"},
                     {"label": "Coconi", "value": "C94033"},
                     {"label": "Comuna Alexandru Odobescu", "value": "C94034"},
                     {"label": "Comuna Belciugatele", "value": "C94035"},
                     {"label": "Comuna Borcea", "value": "C94036"},
                     {"label": "Comuna Cascioarele", "value": "C94037"},
                     {"label": "Comuna Chirnogi", "value": "C94038"},
                     {"label": "Comuna Chiselet", "value": "C94039"},
                     {"label": "Comuna Ciocanesti", "value": "C94040"},
                     {"label": "Comuna Crivat", "value": "C94041"},
                     {"label": "Comuna Curcani", "value": "C94042"},
                     {"label": "Comuna Cuza Voda", "value": "C94043"},
                     {"label": "Comuna Dichiseni", "value": "C94044"},
                     {"label": "Comuna Dor Marunt", "value": "C94045"},
                     {"label": "Comuna Dorobantu", "value": "C94046"},
                     {"label": "Comuna Dragalina", "value": "C94047"},
                     {"label": "Comuna Dragos Voda", "value": "C94048"},
                     {"label": "Comuna Frasinet", "value": "C94049"},
                     {"label": "Comuna Frumusani", "value": "C94050"},
                     {"label": "Comuna Fundeni", "value": "C94051"},
                     {"label": "Comuna Galbinasi", "value": "C94052"},
                     {"label": "Comuna Gradistea", "value": "C94053"},
                     {"label": "Comuna Gurbanesti", "value": "C94054"},
                     {"label": "Comuna Ileana", "value": "C94055"},
                     {"label": "Comuna Independenta", "value": "C94056"},
                     {"label": "Comuna Jegalia", "value": "C94057"},
                     {"label": "Comuna Lehliu", "value": "C94058"},
                     {"label": "Comuna Luica", "value": "C94059"},
                     {"label": "Comuna Lupsanu", "value": "C94060"},
                     {"label": "Comuna Manastirea", "value": "C94061"},
                     {"label": "Comuna Mitreni", "value": "C94062"},
                     {"label": "Comuna Modelu", "value": "C94063"},
                     {"label": "Comuna Nana", "value": "C94064"},
                     {"label": "Comuna Nicolae Balcescu", "value": "C94065"},
                     {"label": "Comuna Perisoru", "value": "C94066"},
                     {"label": "Comuna Plataresti", "value": "C94067"},
                     {"label": "Comuna Radovanu", "value": "C94068"},
                     {"label": "Comuna Roseti", "value": "C94069"},
                     {"label": "Comuna Sarulesti", "value": "C94070"},
                     {"label": "Comuna Sohatu", "value": "C94071"},
                     {"label": "Comuna Soldanu", "value": "C94072"},
                     {"label": "Comuna Spantov", "value": "C94073"},
                     {"label": "Comuna Stefan Cel Mare", "value": "C94074"},
                     {"label": "Comuna Stefan Voda", "value": "C94075"},
                     {"label": "Comuna Tamadau Mare", "value": "C94076"},
                     {"label": "Comuna Ulmeni", "value": "C94077"},
                     {"label": "Comuna Ulmu", "value": "C94078"},
                     {"label": "Comuna Unirea", "value": "C94079"},
                     {"label": "Comuna Valcelele", "value": "C94080"},
                     {"label": "Comuna Valea Argovei", "value": "C94081"},
                     {"label": "Comuna Vasilati", "value": "C94082"},
                     {"label": "Comuna Vlad Tepes", "value": "C94083"},
                     {"label": "Constantin Brancoveanu", "value": "C94084"},
                     {"label": "Crivat", "value": "C94085"},
                     {"label": "Curcani", "value": "C94086"},
                     {"label": "Cuza Voda", "value": "C94087"},
                     {"label": "Dalga-Gara", "value": "C94088"},
                     {"label": "Dichiseni", "value": "C94089"},
                     {"label": "Dor Marunt", "value": "C94090"},
                     {"label": "Dorobantu", "value": "C94091"},
                     {"label": "Dragalina", "value": "C94092"},
                     {"label": "Dragos Voda", "value": "C94093"},
                     {"label": "Drajna Noua", "value": "C94094"},
                     {"label": "Frasinet", "value": "C94095"},
                     {"label": "Frumusani", "value": "C94096"},
                     {"label": "Fundeni", "value": "C94097"},
                     {"label": "Fundulea", "value": "C94098"},
                     {"label": "Galbinasi", "value": "C94099"},
                     {"label": "Galdau", "value": "C94100"},
                     {"label": "Gradistea", "value": "C94101"},
                     {"label": "Gurbanesti", "value": "C94102"},
                     {"label": "Iezeru", "value": "C94103"},
                     {"label": "Ileana", "value": "C94104"},
                     {"label": "Independenta", "value": "C94105"},
                     {"label": "Jegalia", "value": "C94106"},
                     {"label": "Lehliu", "value": "C94107"},
                     {"label": "Lehliu-Gara", "value": "C94108"},
                     {"label": "Luica", "value": "C94109"},
                     {"label": "Lupsanu", "value": "C94110"},
                     {"label": "Manastirea", "value": "C94111"},
                     {"label": "Mihai Viteazu", "value": "C94112"},
                     {"label": "Mitreni", "value": "C94113"},
                     {"label": "Modelu", "value": "C94114"},
                     {"label": "Municipiul Calarasi", "value": "C94115"},
                     {"label": "Municipiul Oltenita", "value": "C94116"},
                     {"label": "Nana", "value": "C94117"},
                     {"label": "Negoesti", "value": "C94118"},
                     {"label": "Nicolae Balcescu", "value": "C94119"},
                     {"label": "Oltenita", "value": "C94120"},
                     {"label": "Oras Budesti", "value": "C94121"},
                     {"label": "Oras Fundulea", "value": "C94122"},
                     {"label": "Oras Lehliu Gara", "value": "C94123"},
                     {"label": "Perisoru", "value": "C94124"},
                     {"label": "Plataresti", "value": "C94125"},
                     {"label": "Potcoava", "value": "C94126"},
                     {"label": "Progresu", "value": "C94127"},
                     {"label": "Radovanu", "value": "C94128"},
                     {"label": "Radu Voda", "value": "C94129"},
                     {"label": "Rasa", "value": "C94130"},
                     {"label": "Razvani", "value": "C94131"},
                     {"label": "Roseti", "value": "C94132"},
                     {"label": "Sapunari", "value": "C94133"},
                     {"label": "Sarulesti", "value": "C94134"},
                     {"label": "Sarulesti-Gara", "value": "C94135"},
                     {"label": "Sohatu", "value": "C94136"},
                     {"label": "Soldanu", "value": "C94137"},
                     {"label": "Spantov", "value": "C94138"},
                     {"label": "Stancea", "value": "C94139"},
                     {"label": "Stefan cel Mare", "value": "C94140"},
                     {"label": "Stefan Voda", "value": "C94141"},
                     {"label": "Sultana", "value": "C94142"},
                     {"label": "Tamadau Mare", "value": "C94143"},
                     {"label": "Ulmeni", "value": "C94144"},
                     {"label": "Ulmu", "value": "C94145"},
                     {"label": "Unirea", "value": "C94146"},
                     {"label": "Valcelele", "value": "C94147"},
                     {"label": "Valea Argovei", "value": "C94148"},
                     {"label": "Valea Rosie", "value": "C94149"},
                     {"label": "Varasti", "value": "C94150"},
                     {"label": "Vasilati", "value": "C94151"},
                     {"label": "Vlad Tepes", "value": "C94152"}],
 "Calarasi District": [{"label": "Calarasi", "value": "C75492"}],
 "Caldas": [{"label": "Aguadas", "value": "C19873"},
            {"label": "Anserma", "value": "C19874"},
            {"label": "Aranzazu", "value": "C19875"},
            {"label": "Belalcazar", "value": "C19876"},
            {"label": "Chinchina", "value": "C19877"},
            {"label": "Filadelfia", "value": "C19878"},
            {"label": "La Dorada", "value": "C19879"},
            {"label": "La Merced", "value": "C19880"},
            {"label": "Manizales", "value": "C19881"},
            {"label": "Manzanares", "value": "C19882"},
            {"label": "Marmato", "value": "C19883"},
            {"label": "Marquetalia", "value": "C19884"},
            {"label": "Marulanda", "value": "C19885"},
            {"label": "Neira", "value": "C19886"},
            {"label": "Norcasia", "value": "C19887"},
            {"label": "Pacora", "value": "C19888"},
            {"label": "Palestina", "value": "C19889"},
            {"label": "Pensilvania", "value": "C19890"},
            {"label": "Riosucio", "value": "C19891"},
            {"label": "Risaralda", "value": "C19892"},
            {"label": "Salamina", "value": "C19893"},
            {"label": "Samana", "value": "C19894"},
            {"label": "San Jose", "value": "C19895"},
            {"label": "Supia", "value": "C19896"},
            {"label": "Victoria", "value": "C19897"},
            {"label": "Villamaria", "value": "C19898"},
            {"label": "Viterbo", "value": "C19899"}],
 "California": [{"label": "Acalanes Ridge", "value": "C127992"},
                {"label": "Acton", "value": "C127993"},
                {"label": "Adelanto", "value": "C127994"},
                {"label": "Agoura", "value": "C127995"},
                {"label": "Agoura Hills", "value": "C127996"},
                {"label": "Agua Dulce", "value": "C127997"},
                {"label": "Aguanga", "value": "C127998"},
                {"label": "Ahwahnee", "value": "C127999"},
                {"label": "Alameda", "value": "C128000"},
                {"label": "Alameda County", "value": "C128001"},
                {"label": "Alamo", "value": "C128002"},
                {"label": "Albany", "value": "C128003"},
                {"label": "Alhambra", "value": "C128004"},
                {"label": "Aliso Viejo", "value": "C128005"},
                {"label": "Allendale", "value": "C128006"},
                {"label": "Alondra Park", "value": "C128007"},
                {"label": "Alpaugh", "value": "C128008"},
                {"label": "Alpine", "value": "C128009"},
                {"label": "Alpine County", "value": "C128010"},
                {"label": "Alta Sierra", "value": "C128011"},
                {"label": "Altadena", "value": "C128012"},
                {"label": "Alturas", "value": "C128013"},
                {"label": "Alum Rock", "value": "C128014"},
                {"label": "Amador County", "value": "C128015"},
                {"label": "American Canyon", "value": "C128016"},
                {"label": "Amesti", "value": "C128017"},
                {"label": "Anaheim", "value": "C128018"},
                {"label": "Anderson", "value": "C128019"},
                {"label": "Angels Camp", "value": "C128020"},
                {"label": "Angwin", "value": "C128021"},
                {"label": "Antelope", "value": "C128022"},
                {"label": "Antioch", "value": "C128023"},
                {"label": "Anza", "value": "C128024"},
                {"label": "Apple Valley", "value": "C128025"},
                {"label": "Aptos", "value": "C128026"},
                {"label": "Aptos Hills-Larkin Valley", "value": "C128027"},
                {"label": "Arbuckle", "value": "C128028"},
                {"label": "Arcadia", "value": "C128029"},
                {"label": "Arcata", "value": "C128030"},
                {"label": "Arden-Arcade", "value": "C128031"},
                {"label": "Armona", "value": "C128032"},
                {"label": "Arnold", "value": "C128033"},
                {"label": "Aromas", "value": "C128034"},
                {"label": "Arroyo Grande", "value": "C128035"},
                {"label": "Artesia", "value": "C128036"},
                {"label": "Arvin", "value": "C128037"},
                {"label": "Ashland", "value": "C128038"},
                {"label": "Atascadero", "value": "C128039"},
                {"label": "Atherton", "value": "C128040"},
                {"label": "Atwater", "value": "C128041"},
                {"label": "Auberry", "value": "C128042"},
                {"label": "Auburn", "value": "C128043"},
                {"label": "Auburn Lake Trails", "value": "C128044"},
                {"label": "August", "value": "C128045"},
                {"label": "Avalon", "value": "C128046"},
                {"label": "Avenal", "value": "C128047"},
                {"label": "Avila Beach", "value": "C128048"},
                {"label": "Avocado Heights", "value": "C128049"},
                {"label": "Azusa", "value": "C128050"},
                {"label": "Bakersfield", "value": "C128051"},
                {"label": "Baldwin Park", "value": "C128052"},
                {"label": "Banning", "value": "C128053"},
                {"label": "Barstow", "value": "C128054"},
                {"label": "Barstow Heights", "value": "C128055"},
                {"label": "Bay Point", "value": "C128056"},
                {"label": "Bayside", "value": "C128057"},
                {"label": "Bayview", "value": "C128058"},
                {"label": "Beale Air Force Base", "value": "C128059"},
                {"label": "Bear Valley Springs", "value": "C128060"},
                {"label": "Beaumont", "value": "C128061"},
                {"label": "Bell", "value": "C128062"},
                {"label": "Bell Gardens", "value": "C128063"},
                {"label": "Bella Vista", "value": "C128064"},
                {"label": "Bellflower", "value": "C128065"},
                {"label": "Belmont", "value": "C128066"},
                {"label": "Belvedere", "value": "C128067"},
                {"label": "Ben Lomond", "value": "C128068"},
                {"label": "Benicia", "value": "C128069"},
                {"label": "Berkeley", "value": "C128070"},
                {"label": "Bermuda Dunes", "value": "C128071"},
                {"label": "Berry Creek", "value": "C128072"},
                {"label": "Bertsch-Oceanview", "value": "C128073"},
                {"label": "Bethel Island", "value": "C128074"},
                {"label": "Beverly Hills", "value": "C128075"},
                {"label": "Big Bear City", "value": "C128076"},
                {"label": "Big Bear Lake", "value": "C128077"},
                {"label": "Big Pine", "value": "C128078"},
                {"label": "Big River", "value": "C128079"},
                {"label": "Biggs", "value": "C128080"},
                {"label": "Biola", "value": "C128081"},
                {"label": "Bishop", "value": "C128082"},
                {"label": "Black Point-Green Point", "value": "C128083"},
                {"label": "Blackhawk", "value": "C128084"},
                {"label": "Bloomington", "value": "C128085"},
                {"label": "Blue Lake", "value": "C128086"},
                {"label": "Blythe", "value": "C128087"},
                {"label": "Bodega Bay", "value": "C128088"},
                {"label": "Bodfish", "value": "C128089"},
                {"label": "Bolinas", "value": "C128090"},
                {"label": "Bonadelle Ranchos-Madera Ranchos",
                 "value": "C128091"},
                {"label": "Bonita", "value": "C128092"},
                {"label": "Bonny Doon", "value": "C128093"},
                {"label": "Bonsall", "value": "C128094"},
                {"label": "Boonville", "value": "C128095"},
                {"label": "Boron", "value": "C128096"},
                {"label": "Boronda", "value": "C128097"},
                {"label": "Borrego Springs", "value": "C128098"},
                {"label": "Bostonia", "value": "C128099"},
                {"label": "Boulder Creek", "value": "C128100"},
                {"label": "Boyes Hot Springs", "value": "C128101"},
                {"label": "Boyle Heights", "value": "C128102"},
                {"label": "Bradbury", "value": "C128103"},
                {"label": "Brawley", "value": "C128104"},
                {"label": "Brea", "value": "C128105"},
                {"label": "Brentwood", "value": "C128106"},
                {"label": "Bret Harte", "value": "C128107"},
                {"label": "Bridgeport", "value": "C128108"},
                {"label": "Brisbane", "value": "C128109"},
                {"label": "Broadmoor", "value": "C128110"},
                {"label": "Brookdale", "value": "C128111"},
                {"label": "Brooktrails", "value": "C128112"},
                {"label": "Buckhorn", "value": "C128113"},
                {"label": "Buellton", "value": "C128114"},
                {"label": "Buena Park", "value": "C128115"},
                {"label": "Buena Vista", "value": "C128116"},
                {"label": "Burbank", "value": "C128117"},
                {"label": "Burlingame", "value": "C128118"},
                {"label": "Burney", "value": "C128119"},
                {"label": "Butte County", "value": "C128120"},
                {"label": "Buttonwillow", "value": "C128121"},
                {"label": "Byron", "value": "C128122"},
                {"label": "Bystrom", "value": "C128123"},
                {"label": "Cabazon", "value": "C128124"},
                {"label": "Calabasas", "value": "C128125"},
                {"label": "Calaveras County", "value": "C128126"},
                {"label": "Calexico", "value": "C128127"},
                {"label": "California City", "value": "C128128"},
                {"label": "Calimesa", "value": "C128129"},
                {"label": "Calipatria", "value": "C128130"},
                {"label": "Calistoga", "value": "C128131"},
                {"label": "Callender", "value": "C128132"},
                {"label": "Camarillo", "value": "C128133"},
                {"label": "Cambria", "value": "C128134"},
                {"label": "Cambrian Park", "value": "C128135"},
                {"label": "Cameron Park", "value": "C128136"},
                {"label": "Camino", "value": "C128137"},
                {"label": "Camp Meeker", "value": "C128138"},
                {"label": "Camp Pendleton North", "value": "C128139"},
                {"label": "Camp Pendleton South", "value": "C128140"},
                {"label": "Campbell", "value": "C128141"},
                {"label": "Campo", "value": "C128142"},
                {"label": "Canoga Park", "value": "C128143"},
                {"label": "Canyon Lake", "value": "C128144"},
                {"label": "Capitola", "value": "C128145"},
                {"label": "Carlsbad", "value": "C128146"},
                {"label": "Carmel Valley Village", "value": "C128147"},
                {"label": "Carmel-by-the-Sea", "value": "C128148"},
                {"label": "Carmichael", "value": "C128149"},
                {"label": "Carpinteria", "value": "C128150"},
                {"label": "Carson", "value": "C128151"},
                {"label": "Caruthers", "value": "C128152"},
                {"label": "Casa Conejo", "value": "C128153"},
                {"label": "Casa de Oro-Mount Helix", "value": "C128154"},
                {"label": "Castaic", "value": "C128155"},
                {"label": "Castro Valley", "value": "C128156"},
                {"label": "Castroville", "value": "C128157"},
                {"label": "Cathedral City", "value": "C128158"},
                {"label": "Cayucos", "value": "C128159"},
                {"label": "Cedar Ridge", "value": "C128160"},
                {"label": "Central Valley (historical)", "value": "C128161"},
                {"label": "Century City", "value": "C128162"},
                {"label": "Ceres", "value": "C128163"},
                {"label": "Cerritos", "value": "C128164"},
                {"label": "Challenge-Brownsville", "value": "C128165"},
                {"label": "Channel Islands Beach", "value": "C128166"},
                {"label": "Charter Oak", "value": "C128167"},
                {"label": "Chatsworth", "value": "C128168"},
                {"label": "Cherry Valley", "value": "C128169"},
                {"label": "Cherryland", "value": "C128170"},
                {"label": "Chester", "value": "C128171"},
                {"label": "Chico", "value": "C128172"},
                {"label": "China Lake Acres", "value": "C128173"},
                {"label": "Chinatown", "value": "C128174"},
                {"label": "Chino", "value": "C128175"},
                {"label": "Chino Hills", "value": "C128176"},
                {"label": "Chowchilla", "value": "C128177"},
                {"label": "Chualar", "value": "C128178"},
                {"label": "Chula Vista", "value": "C128179"},
                {"label": "Citrus", "value": "C128180"},
                {"label": "Citrus Heights", "value": "C128181"},
                {"label": "City and County of San Francisco",
                 "value": "C128182"},
                {"label": "Claremont", "value": "C128183"},
                {"label": "Clay", "value": "C128184"},
                {"label": "Clayton", "value": "C128185"},
                {"label": "Clear Lake Riviera", "value": "C128186"},
                {"label": "Clearlake", "value": "C128187"},
                {"label": "Clearlake Oaks", "value": "C128188"},
                {"label": "Cloverdale", "value": "C128189"},
                {"label": "Clovis", "value": "C128190"},
                {"label": "Coachella", "value": "C128191"},
                {"label": "Coalinga", "value": "C128192"},
                {"label": "Coarsegold", "value": "C128193"},
                {"label": "Cobb", "value": "C128194"},
                {"label": "Colfax", "value": "C128195"},
                {"label": "Collierville", "value": "C128196"},
                {"label": "Colma", "value": "C128197"},
                {"label": "Colton", "value": "C128198"},
                {"label": "Columbia", "value": "C128199"},
                {"label": "Colusa", "value": "C128200"},
                {"label": "Colusa County", "value": "C128201"},
                {"label": "Commerce", "value": "C128202"},
                {"label": "Compton", "value": "C128203"},
                {"label": "Concord", "value": "C128204"},
                {"label": "Contra Costa Centre", "value": "C128205"},
                {"label": "Contra Costa County", "value": "C128206"},
                {"label": "Cool", "value": "C128207"},
                {"label": "Copperopolis", "value": "C128208"},
                {"label": "Corcoran", "value": "C128209"},
                {"label": "Corning", "value": "C128210"},
                {"label": "Corona", "value": "C128211"},
                {"label": "Coronado", "value": "C128212"},
                {"label": "Corralitos", "value": "C128213"},
                {"label": "Corte Madera", "value": "C128214"},
                {"label": "Costa Mesa", "value": "C128215"},
                {"label": "Cotati", "value": "C128216"},
                {"label": "Coto De Caza", "value": "C128217"},
                {"label": "Cottonwood", "value": "C128218"},
                {"label": "Country Club", "value": "C128219"},
                {"label": "Covelo", "value": "C128220"},
                {"label": "Covina", "value": "C128221"},
                {"label": "Crescent City", "value": "C128222"},
                {"label": "Crest", "value": "C128223"},
                {"label": "Crestline", "value": "C128224"},
                {"label": "Crockett", "value": "C128225"},
                {"label": "Cudahy", "value": "C128226"},
                {"label": "Culver City", "value": "C128227"},
                {"label": "Cupertino", "value": "C128228"},
                {"label": "Cutler", "value": "C128229"},
                {"label": "Cutten", "value": "C128230"},
                {"label": "Cypress", "value": "C128231"},
                {"label": "Daly City", "value": "C128232"},
                {"label": "Dana Point", "value": "C128233"},
                {"label": "Danville", "value": "C128234"},
                {"label": "Davis", "value": "C128235"},
                {"label": "Day Valley", "value": "C128236"},
                {"label": "Deer Park", "value": "C128237"},
                {"label": "Del Aire", "value": "C128238"},
                {"label": "Del Mar", "value": "C128239"},
                {"label": "Del Monte Forest", "value": "C128240"},
                {"label": "Del Norte County", "value": "C128241"},
                {"label": "Del Rey", "value": "C128242"},
                {"label": "Del Rey Oaks", "value": "C128243"},
                {"label": "Del Rio", "value": "C128244"},
                {"label": "Delano", "value": "C128245"},
                {"label": "Delhi", "value": "C128246"},
                {"label": "Denair", "value": "C128247"},
                {"label": "Descanso", "value": "C128248"},
                {"label": "Desert Edge", "value": "C128249"},
                {"label": "Desert Hot Springs", "value": "C128250"},
                {"label": "Desert Shores", "value": "C128251"},
                {"label": "Desert View Highlands", "value": "C128252"},
                {"label": "Diablo", "value": "C128253"},
                {"label": "Diamond Bar", "value": "C128254"},
                {"label": "Diamond Springs", "value": "C128255"},
                {"label": "Dinuba", "value": "C128256"},
                {"label": "Discovery Bay", "value": "C128257"},
                {"label": "Dixon", "value": "C128258"},
                {"label": "Dixon Lane-Meadow Creek", "value": "C128259"},
                {"label": "Dogtown", "value": "C128260"},
                {"label": "Dollar Point", "value": "C128261"},
                {"label": "Dos Palos", "value": "C128262"},
                {"label": "Downey", "value": "C128263"},
                {"label": "Downieville", "value": "C128264"},
                {"label": "Duarte", "value": "C128265"},
                {"label": "Dublin", "value": "C128266"},
                {"label": "Dunnigan", "value": "C128267"},
                {"label": "Dunsmuir", "value": "C128268"},
                {"label": "Durham", "value": "C128269"},
                {"label": "Earlimart", "value": "C128270"},
                {"label": "East Foothills", "value": "C128271"},
                {"label": "East Hemet", "value": "C128272"},
                {"label": "East La Mirada", "value": "C128273"},
                {"label": "East Los Angeles", "value": "C128274"},
                {"label": "East Oakdale", "value": "C128275"},
                {"label": "East Palo Alto", "value": "C128276"},
                {"label": "East Pasadena", "value": "C128277"},
                {"label": "East Porterville", "value": "C128278"},
                {"label": "East Quincy", "value": "C128279"},
                {"label": "East Rancho Dominguez", "value": "C128280"},
                {"label": "East Richmond Heights", "value": "C128281"},
                {"label": "East San Gabriel", "value": "C128282"},
                {"label": "East Sonora", "value": "C128283"},
                {"label": "Easton", "value": "C128284"},
                {"label": "Eastvale", "value": "C128285"},
                {"label": "Echo Park", "value": "C128286"},
                {"label": "Edwards Air Force Base", "value": "C128287"},
                {"label": "El Cajon", "value": "C128288"},
                {"label": "El Centro", "value": "C128289"},
                {"label": "El Cerrito", "value": "C128290"},
                {"label": "El Cerrito Corona", "value": "C128291"},
                {"label": "El Dorado County", "value": "C128292"},
                {"label": "El Dorado Hills", "value": "C128293"},
                {"label": "El Granada", "value": "C128294"},
                {"label": "El Monte", "value": "C128295"},
                {"label": "El Rio", "value": "C128296"},
                {"label": "El Segundo", "value": "C128297"},
                {"label": "El Sobrante", "value": "C128298"},
                {"label": "El Verano", "value": "C128299"},
                {"label": "Eldridge", "value": "C128300"},
                {"label": "Elk Grove", "value": "C128301"},
                {"label": "Elkhorn", "value": "C128302"},
                {"label": "Elverta", "value": "C128303"},
                {"label": "Emerald Lake Hills", "value": "C128304"},
                {"label": "Emeryville", "value": "C128305"},
                {"label": "Empire", "value": "C128306"},
                {"label": "Encinitas", "value": "C128307"},
                {"label": "Encino", "value": "C128308"},
                {"label": "Escalon", "value": "C128309"},
                {"label": "Escondido", "value": "C128310"},
                {"label": "Esparto", "value": "C128311"},
                {"label": "Eucalyptus Hills", "value": "C128312"},
                {"label": "Eureka", "value": "C128313"},
                {"label": "Exeter", "value": "C128314"},
                {"label": "Fair Oaks", "value": "C128315"},
                {"label": "Fairbanks Ranch", "value": "C128316"},
                {"label": "Fairfax", "value": "C128317"},
                {"label": "Fairfield", "value": "C128318"},
                {"label": "Fairmead", "value": "C128319"},
                {"label": "Fairview", "value": "C128320"},
                {"label": "Fallbrook", "value": "C128321"},
                {"label": "Farmersville", "value": "C128322"},
                {"label": "Felton", "value": "C128323"},
                {"label": "Ferndale", "value": "C128324"},
                {"label": "Fetters Hot Springs-Agua Caliente",
                 "value": "C128325"},
                {"label": "Fillmore", "value": "C128326"},
                {"label": "Firebaugh", "value": "C128327"},
                {"label": "Florence-Graham", "value": "C128328"},
                {"label": "Florin", "value": "C128329"},
                {"label": "Folsom", "value": "C128330"},
                {"label": "Fontana", "value": "C128331"},
                {"label": "Foothill Farms", "value": "C128332"},
                {"label": "Foothill Ranch", "value": "C128333"},
                {"label": "Ford City", "value": "C128334"},
                {"label": "Forest Meadows", "value": "C128335"},
                {"label": "Forest Ranch", "value": "C128336"},
                {"label": "Foresthill", "value": "C128337"},
                {"label": "Forestville", "value": "C128338"},
                {"label": "Fort Bragg", "value": "C128339"},
                {"label": "Fort Irwin", "value": "C128340"},
                {"label": "Fortuna", "value": "C128341"},
                {"label": "Foster City", "value": "C128342"},
                {"label": "Fountain Valley", "value": "C128343"},
                {"label": "Fowler", "value": "C128344"},
                {"label": "Frazier Park", "value": "C128345"},
                {"label": "Freedom", "value": "C128346"},
                {"label": "Fremont", "value": "C128347"},
                {"label": "French Camp", "value": "C128348"},
                {"label": "Fresno", "value": "C128349"},
                {"label": "Fresno County", "value": "C128350"},
                {"label": "Fruitridge Pocket", "value": "C128351"},
                {"label": "Fullerton", "value": "C128352"},
                {"label": "Galt", "value": "C128353"},
                {"label": "Garden Acres", "value": "C128354"},
                {"label": "Garden Grove", "value": "C128355"},
                {"label": "Gardena", "value": "C128356"},
                {"label": "Garnet", "value": "C128357"},
                {"label": "Georgetown", "value": "C128358"},
                {"label": "Gerber", "value": "C128359"},
                {"label": "Gilroy", "value": "C128360"},
                {"label": "Glen Avon", "value": "C128361"},
                {"label": "Glendale", "value": "C128362"},
                {"label": "Glendora", "value": "C128363"},
                {"label": "Glenn County", "value": "C128364"},
                {"label": "Gold River", "value": "C128365"},
                {"label": "Golden Hills", "value": "C128366"},
                {"label": "Goleta", "value": "C128367"},
                {"label": "Gonzales", "value": "C128368"},
                {"label": "Good Hope", "value": "C128369"},
                {"label": "Goshen", "value": "C128370"},
                {"label": "Grand Terrace", "value": "C128371"},
                {"label": "Granite Bay", "value": "C128372"},
                {"label": "Granite Hills", "value": "C128373"},
                {"label": "Grass Valley", "value": "C128374"},
                {"label": "Graton", "value": "C128375"},
                {"label": "Green Acres", "value": "C128376"},
                {"label": "Green Valley", "value": "C128377"},
                {"label": "Greenacres", "value": "C128378"},
                {"label": "Greenfield", "value": "C128379"},
                {"label": "Greenville", "value": "C128380"},
                {"label": "Gridley", "value": "C128381"},
                {"label": "Grover Beach", "value": "C128382"},
                {"label": "Guadalupe", "value": "C128383"},
                {"label": "Guerneville", "value": "C128384"},
                {"label": "Gustine", "value": "C128385"},
                {"label": "Hacienda Heights", "value": "C128386"},
                {"label": "Half Moon Bay", "value": "C128387"},
                {"label": "Hamilton City", "value": "C128388"},
                {"label": "Hanford", "value": "C128389"},
                {"label": "Happy Camp", "value": "C128390"},
                {"label": "Harbison Canyon", "value": "C128391"},
                {"label": "Hartley", "value": "C128392"},
                {"label": "Hawaiian Gardens", "value": "C128393"},
                {"label": "Hawthorne", "value": "C128394"},
                {"label": "Hayfork", "value": "C128395"},
                {"label": "Hayward", "value": "C128396"},
                {"label": "Healdsburg", "value": "C128397"},
                {"label": "Heber", "value": "C128398"},
                {"label": "Hemet", "value": "C128399"},
                {"label": "Herald", "value": "C128400"},
                {"label": "Hercules", "value": "C128401"},
                {"label": "Hermosa Beach", "value": "C128402"},
                {"label": "Hesperia", "value": "C128403"},
                {"label": "Hidden Hills", "value": "C128404"},
                {"label": "Hidden Meadows", "value": "C128405"},
                {"label": "Hidden Valley Lake", "value": "C128406"},
                {"label": "Highgrove", "value": "C128407"},
                {"label": "Highland", "value": "C128408"},
                {"label": "Highlands-Baywood Park", "value": "C128409"},
                {"label": "Hillsborough", "value": "C128410"},
                {"label": "Hilmar-Irwin", "value": "C128411"},
                {"label": "Hollister", "value": "C128412"},
                {"label": "Hollywood", "value": "C128413"},
                {"label": "Holtville", "value": "C128414"},
                {"label": "Home Garden", "value": "C128415"},
                {"label": "Home Gardens", "value": "C128416"},
                {"label": "Homeland", "value": "C128417"},
                {"label": "Hughson", "value": "C128418"},
                {"label": "Humboldt County", "value": "C128419"},
                {"label": "Humboldt Hill", "value": "C128420"},
                {"label": "Huntington Beach", "value": "C128421"},
                {"label": "Huntington Park", "value": "C128422"},
                {"label": "Huron", "value": "C128423"},
                {"label": "Hydesville", "value": "C128424"},
                {"label": "Idyllwild", "value": "C128425"},
                {"label": "Idyllwild-Pine Cove", "value": "C128426"},
                {"label": "Imperial", "value": "C128427"},
                {"label": "Imperial Beach", "value": "C128428"},
                {"label": "Imperial County", "value": "C128429"},
                {"label": "Indian Wells", "value": "C128430"},
                {"label": "Indio", "value": "C128431"},
                {"label": "Inglewood", "value": "C128432"},
                {"label": "Interlaken", "value": "C128433"},
                {"label": "Inverness", "value": "C128434"},
                {"label": "Inyo County", "value": "C128435"},
                {"label": "Inyokern", "value": "C128436"},
                {"label": "Ione", "value": "C128437"},
                {"label": "Irvine", "value": "C128438"},
                {"label": "Irwindale", "value": "C128439"},
                {"label": "Isla Vista", "value": "C128440"},
                {"label": "Ivanhoe", "value": "C128441"},
                {"label": "Jackson", "value": "C128442"},
                {"label": "Jamestown", "value": "C128443"},
                {"label": "Jamul", "value": "C128444"},
                {"label": "Janesville", "value": "C128445"},
                {"label": "Johnstonville", "value": "C128446"},
                {"label": "Joshua Tree", "value": "C128447"},
                {"label": "Julian", "value": "C128448"},
                {"label": "Jurupa Valley", "value": "C128449"},
                {"label": "Kelseyville", "value": "C128450"},
                {"label": "Kennedy", "value": "C128451"},
                {"label": "Kensington", "value": "C128452"},
                {"label": "Kentfield", "value": "C128453"},
                {"label": "Kenwood", "value": "C128454"},
                {"label": "Kerman", "value": "C128455"},
                {"label": "Kern County", "value": "C128456"},
                {"label": "Kernville", "value": "C128457"},
                {"label": "Kettleman City", "value": "C128458"},
                {"label": "Keyes", "value": "C128459"},
                {"label": "King City", "value": "C128460"},
                {"label": "Kings Beach", "value": "C128461"},
                {"label": "Kings County", "value": "C128462"},
                {"label": "Kingsburg", "value": "C128463"},
                {"label": "Knightsen", "value": "C128464"},
                {"label": "Koreatown", "value": "C128465"},
                {"label": "La Canada Flintridge", "value": "C128466"},
                {"label": "La Crescenta-Montrose", "value": "C128467"},
                {"label": "La Habra", "value": "C128468"},
                {"label": "La Habra Heights", "value": "C128469"},
                {"label": "La Jolla", "value": "C128470"},
                {"label": "La Mesa", "value": "C128471"},
                {"label": "La Mirada", "value": "C128472"},
                {"label": "La Palma", "value": "C128473"},
                {"label": "La Presa", "value": "C128474"},
                {"label": "La Puente", "value": "C128475"},
                {"label": "La Quinta", "value": "C128476"},
                {"label": "La Riviera", "value": "C128477"},
                {"label": "La Selva Beach", "value": "C128478"},
                {"label": "La Verne", "value": "C128479"},
                {"label": "Ladera", "value": "C128480"},
                {"label": "Ladera Heights", "value": "C128481"},
                {"label": "Ladera Ranch", "value": "C128482"},
                {"label": "Lafayette", "value": "C128483"},
                {"label": "Laguna", "value": "C128484"},
                {"label": "Laguna Beach", "value": "C128485"},
                {"label": "Laguna Hills", "value": "C128486"},
                {"label": "Laguna Niguel", "value": "C128487"},
                {"label": "Laguna Woods", "value": "C128488"},
                {"label": "Lagunitas-Forest Knolls", "value": "C128489"},
                {"label": "Lake Arrowhead", "value": "C128490"},
                {"label": "Lake County", "value": "C128491"},
                {"label": "Lake Elsinore", "value": "C128492"},
                {"label": "Lake Forest", "value": "C128493"},
                {"label": "Lake Isabella", "value": "C128494"},
                {"label": "Lake Los Angeles", "value": "C128495"},
                {"label": "Lake Nacimiento", "value": "C128496"},
                {"label": "Lake of the Pines", "value": "C128497"},
                {"label": "Lake San Marcos", "value": "C128498"},
                {"label": "Lake Wildwood", "value": "C128499"},
                {"label": "Lakeland Village", "value": "C128500"},
                {"label": "Lakeport", "value": "C128501"},
                {"label": "Lakeside", "value": "C128502"},
                {"label": "Lakeview", "value": "C128503"},
                {"label": "Lakewood", "value": "C128504"},
                {"label": "Lamont", "value": "C128505"},
                {"label": "Lancaster", "value": "C128506"},
                {"label": "Larkfield-Wikiup", "value": "C128507"},
                {"label": "Larkspur", "value": "C128508"},
                {"label": "Las Flores", "value": "C128509"},
                {"label": "Las Lomas", "value": "C128510"},
                {"label": "Lassen County", "value": "C128511"},
                {"label": "Lathrop", "value": "C128512"},
                {"label": "Laton", "value": "C128513"},
                {"label": "Lawndale", "value": "C128514"},
                {"label": "Laytonville", "value": "C128515"},
                {"label": "Le Grand", "value": "C128516"},
                {"label": "Lebec", "value": "C128517"},
                {"label": "Lemon Grove", "value": "C128518"},
                {"label": "Lemoore", "value": "C128519"},
                {"label": "Lemoore Station", "value": "C128520"},
                {"label": "Lennox", "value": "C128521"},
                {"label": "Lenwood", "value": "C128522"},
                {"label": "Leona Valley", "value": "C128523"},
                {"label": "Lewiston", "value": "C128524"},
                {"label": "Lexington Hills", "value": "C128525"},
                {"label": "Lincoln", "value": "C128526"},
                {"label": "Lincoln Village", "value": "C128527"},
                {"label": "Linda", "value": "C128528"},
                {"label": "Linden", "value": "C128529"},
                {"label": "Lindsay", "value": "C128530"},
                {"label": "Littlerock", "value": "C128531"},
                {"label": "Live Oak", "value": "C128532"},
                {"label": "Livermore", "value": "C128533"},
                {"label": "Livingston", "value": "C128534"},
                {"label": "Lockeford", "value": "C128535"},
                {"label": "Lodi", "value": "C128536"},
                {"label": "Loma Linda", "value": "C128537"},
                {"label": "Loma Rica", "value": "C128538"},
                {"label": "Lomita", "value": "C128539"},
                {"label": "Lompico", "value": "C128540"},
                {"label": "Lompoc", "value": "C128541"},
                {"label": "London", "value": "C128542"},
                {"label": "Lone Pine", "value": "C128543"},
                {"label": "Long Beach", "value": "C128544"},
                {"label": "Loomis", "value": "C128545"},
                {"label": "Los Alamitos", "value": "C128546"},
                {"label": "Los Alamos", "value": "C128547"},
                {"label": "Los Altos", "value": "C128548"},
                {"label": "Los Altos Hills", "value": "C128549"},
                {"label": "Los Angeles", "value": "C128550"},
                {"label": "Los Angeles County", "value": "C128551"},
                {"label": "Los Banos", "value": "C128552"},
                {"label": "Los Gatos", "value": "C128553"},
                {"label": "Los Molinos", "value": "C128554"},
                {"label": "Los Olivos", "value": "C128555"},
                {"label": "Los Osos", "value": "C128556"},
                {"label": "Los Serranos", "value": "C128557"},
                {"label": "Lost Hills", "value": "C128558"},
                {"label": "Lower Lake", "value": "C128559"},
                {"label": "Loyola", "value": "C128560"},
                {"label": "Lucas Valley-Marinwood", "value": "C128561"},
                {"label": "Lucerne", "value": "C128562"},
                {"label": "Lucerne Valley", "value": "C128563"},
                {"label": "Lynwood", "value": "C128564"},
                {"label": "Madera", "value": "C128565"},
                {"label": "Madera Acres", "value": "C128566"},
                {"label": "Madera County", "value": "C128567"},
                {"label": "Magalia", "value": "C128568"},
                {"label": "Malibu", "value": "C128569"},
                {"label": "Mammoth Lakes", "value": "C128570"},
                {"label": "Manhattan Beach", "value": "C128571"},
                {"label": "Manteca", "value": "C128572"},
                {"label": "March Air Force Base", "value": "C128573"},
                {"label": "Maricopa", "value": "C128574"},
                {"label": "Marin City", "value": "C128575"},
                {"label": "Marin County", "value": "C128576"},
                {"label": "Marina", "value": "C128577"},
                {"label": "Marina del Rey", "value": "C128578"},
                {"label": "Mariposa", "value": "C128579"},
                {"label": "Mariposa County", "value": "C128580"},
                {"label": "Martinez", "value": "C128581"},
                {"label": "Marysville", "value": "C128582"},
                {"label": "Matheny", "value": "C128583"},
                {"label": "Maxwell", "value": "C128584"},
                {"label": "Mayflower Village", "value": "C128585"},
                {"label": "Maywood", "value": "C128586"},
                {"label": "McCloud", "value": "C128587"},
                {"label": "McFarland", "value": "C128588"},
                {"label": "McKinleyville", "value": "C128589"},
                {"label": "Mead Valley", "value": "C128590"},
                {"label": "Meadow Vista", "value": "C128591"},
                {"label": "Meadowbrook", "value": "C128592"},
                {"label": "Mecca", "value": "C128593"},
                {"label": "Meiners Oaks", "value": "C128594"},
                {"label": "Mendocino County", "value": "C128595"},
                {"label": "Mendota", "value": "C128596"},
                {"label": "Menifee", "value": "C128597"},
                {"label": "Menlo Park", "value": "C128598"},
                {"label": "Mentone", "value": "C128599"},
                {"label": "Merced", "value": "C128600"},
                {"label": "Merced County", "value": "C128601"},
                {"label": "Mesa Verde", "value": "C128602"},
                {"label": "Middletown", "value": "C128603"},
                {"label": "Midpines", "value": "C128604"},
                {"label": "Midway City", "value": "C128605"},
                {"label": "Mill Valley", "value": "C128606"},
                {"label": "Millbrae", "value": "C128607"},
                {"label": "Milpitas", "value": "C128608"},
                {"label": "Minkler", "value": "C128609"},
                {"label": "Mira Mesa", "value": "C128610"},
                {"label": "Mira Monte", "value": "C128611"},
                {"label": "Mission Canyon", "value": "C128612"},
                {"label": "Mission District", "value": "C128613"},
                {"label": "Mission Hills", "value": "C128614"},
                {"label": "Mission Viejo", "value": "C128615"},
                {"label": "Modesto", "value": "C128616"},
                {"label": "Modoc County", "value": "C128617"},
                {"label": "Mojave", "value": "C128618"},
                {"label": "Mono County", "value": "C128619"},
                {"label": "Mono Vista", "value": "C128620"},
                {"label": "Monrovia", "value": "C128621"},
                {"label": "Montague", "value": "C128622"},
                {"label": "Montalvin", "value": "C128623"},
                {"label": "Montara", "value": "C128624"},
                {"label": "Montclair", "value": "C128625"},
                {"label": "Monte Rio", "value": "C128626"},
                {"label": "Monte Sereno", "value": "C128627"},
                {"label": "Montebello", "value": "C128628"},
                {"label": "Montecito", "value": "C128629"},
                {"label": "Monterey", "value": "C128630"},
                {"label": "Monterey County", "value": "C128631"},
                {"label": "Monterey Park", "value": "C128632"},
                {"label": "Monument Hills", "value": "C128633"},
                {"label": "Moorpark", "value": "C128634"},
                {"label": "Morada", "value": "C128635"},
                {"label": "Moraga", "value": "C128636"},
                {"label": "Moreno Valley", "value": "C128637"},
                {"label": "Morgan Hill", "value": "C128638"},
                {"label": "Morongo Valley", "value": "C128639"},
                {"label": "Morro Bay", "value": "C128640"},
                {"label": "Moss Beach", "value": "C128641"},
                {"label": "Mount Hermon", "value": "C128642"},
                {"label": "Mount Shasta", "value": "C128643"},
                {"label": "Mountain House", "value": "C128644"},
                {"label": "Mountain Ranch", "value": "C128645"},
                {"label": "Mountain View", "value": "C128646"},
                {"label": "Mountain View Acres", "value": "C128647"},
                {"label": "Murphys", "value": "C128648"},
                {"label": "Murrieta", "value": "C128649"},
                {"label": "Murrieta Hot Springs", "value": "C128650"},
                {"label": "Muscoy", "value": "C128651"},
                {"label": "Myrtletown", "value": "C128652"},
                {"label": "Napa", "value": "C128653"},
                {"label": "Napa County", "value": "C128654"},
                {"label": "National City", "value": "C128655"},
                {"label": "Needles", "value": "C128656"},
                {"label": "Nevada City", "value": "C128657"},
                {"label": "Nevada County", "value": "C128658"},
                {"label": "Newark", "value": "C128659"},
                {"label": "Newcastle", "value": "C128660"},
                {"label": "Newman", "value": "C128661"},
                {"label": "Newport Beach", "value": "C128662"},
                {"label": "Nice", "value": "C128663"},
                {"label": "Niland", "value": "C128664"},
                {"label": "Nipomo", "value": "C128665"},
                {"label": "Noe Valley", "value": "C128666"},
                {"label": "Norco", "value": "C128667"},
                {"label": "North Auburn", "value": "C128668"},
                {"label": "North Edwards", "value": "C128669"},
                {"label": "North El Monte", "value": "C128670"},
                {"label": "North Fair Oaks", "value": "C128671"},
                {"label": "North Highlands", "value": "C128672"},
                {"label": "North Hills", "value": "C128673"},
                {"label": "North Hollywood", "value": "C128674"},
                {"label": "North Lakeport", "value": "C128675"},
                {"label": "North Richmond", "value": "C128676"},
                {"label": "North Tustin", "value": "C128677"},
                {"label": "Northridge", "value": "C128678"},
                {"label": "Norwalk", "value": "C128679"},
                {"label": "Novato", "value": "C128680"},
                {"label": "Nuevo", "value": "C128681"},
                {"label": "Oak Hills", "value": "C128682"},
                {"label": "Oak Park", "value": "C128683"},
                {"label": "Oak View", "value": "C128684"},
                {"label": "Oakdale", "value": "C128685"},
                {"label": "Oakhurst", "value": "C128686"},
                {"label": "Oakland", "value": "C128687"},
                {"label": "Oakley", "value": "C128688"},
                {"label": "Oasis", "value": "C128689"},
                {"label": "Occidental", "value": "C128690"},
                {"label": "Oceano", "value": "C128691"},
                {"label": "Oceanside", "value": "C128692"},
                {"label": "Oildale", "value": "C128693"},
                {"label": "Ojai", "value": "C128694"},
                {"label": "Old Fig Garden", "value": "C128695"},
                {"label": "Olivehurst", "value": "C128696"},
                {"label": "Ontario", "value": "C128697"},
                {"label": "Opal Cliffs", "value": "C128698"},
                {"label": "Orange", "value": "C128699"},
                {"label": "Orange County", "value": "C128700"},
                {"label": "Orange Cove", "value": "C128701"},
                {"label": "Orangevale", "value": "C128702"},
                {"label": "Orcutt", "value": "C128703"},
                {"label": "Orinda", "value": "C128704"},
                {"label": "Orland", "value": "C128705"},
                {"label": "Orosi", "value": "C128706"},
                {"label": "Oroville", "value": "C128707"},
                {"label": "Oroville East", "value": "C128708"},
                {"label": "Oxnard", "value": "C128709"},
                {"label": "Pacheco", "value": "C128710"},
                {"label": "Pacific Grove", "value": "C128711"},
                {"label": "Pacifica", "value": "C128712"},
                {"label": "Pajaro", "value": "C128713"},
                {"label": "Palermo", "value": "C128714"},
                {"label": "Palm Desert", "value": "C128715"},
                {"label": "Palm Springs", "value": "C128716"},
                {"label": "Palmdale", "value": "C128717"},
                {"label": "Palo Alto", "value": "C128718"},
                {"label": "Palo Cedro", "value": "C128719"},
                {"label": "Palos Verdes Estates", "value": "C128720"},
                {"label": "Paradise", "value": "C128721"},
                {"label": "Paramount", "value": "C128722"},
                {"label": "Parksdale", "value": "C128723"},
                {"label": "Parkway", "value": "C128724"},
                {"label": "Parkwood", "value": "C128725"},
                {"label": "Parlier", "value": "C128726"},
                {"label": "Pasadena", "value": "C128727"},
                {"label": "Pasatiempo", "value": "C128728"},
                {"label": "Paso Robles", "value": "C128729"},
                {"label": "Patterson", "value": "C128730"},
                {"label": "Patterson Tract", "value": "C128731"},
                {"label": "Pedley", "value": "C128732"},
                {"label": "Penn Valley", "value": "C128733"},
                {"label": "Penngrove", "value": "C128734"},
                {"label": "Perris", "value": "C128735"},
                {"label": "Petaluma", "value": "C128736"},
                {"label": "Phelan", "value": "C128737"},
                {"label": "Phoenix Lake", "value": "C128738"},
                {"label": "Pico Rivera", "value": "C128739"},
                {"label": "Piedmont", "value": "C128740"},
                {"label": "Pine Grove", "value": "C128741"},
                {"label": "Pine Hills", "value": "C128742"},
                {"label": "Pine Mountain Club", "value": "C128743"},
                {"label": "Pine Valley", "value": "C128744"},
                {"label": "Pinole", "value": "C128745"},
                {"label": "Pinon Hills", "value": "C128746"},
                {"label": "Pioneer", "value": "C128747"},
                {"label": "Piru", "value": "C128748"},
                {"label": "Pismo Beach", "value": "C128749"},
                {"label": "Pittsburg", "value": "C128750"},
                {"label": "Pixley", "value": "C128751"},
                {"label": "Placentia", "value": "C128752"},
                {"label": "Placer County", "value": "C128753"},
                {"label": "Placerville", "value": "C128754"},
                {"label": "Planada", "value": "C128755"},
                {"label": "Pleasant Hill", "value": "C128756"},
                {"label": "Pleasanton", "value": "C128757"},
                {"label": "Plumas County", "value": "C128758"},
                {"label": "Plumas Lake", "value": "C128759"},
                {"label": "Pollock Pines", "value": "C128760"},
                {"label": "Pomona", "value": "C128761"},
                {"label": "Poplar-Cotton Center", "value": "C128762"},
                {"label": "Port Hueneme", "value": "C128763"},
                {"label": "Porterville", "value": "C128764"},
                {"label": "Portola", "value": "C128765"},
                {"label": "Portola Hills", "value": "C128766"},
                {"label": "Portola Valley", "value": "C128767"},
                {"label": "Poway", "value": "C128768"},
                {"label": "Prunedale", "value": "C128769"},
                {"label": "Quail Valley", "value": "C128770"},
                {"label": "Quartz Hill", "value": "C128771"},
                {"label": "Quincy", "value": "C128772"},
                {"label": "Rainbow", "value": "C128773"},
                {"label": "Ramona", "value": "C128774"},
                {"label": "Rancho Calaveras", "value": "C128775"},
                {"label": "Rancho Cordova", "value": "C128776"},
                {"label": "Rancho Cucamonga", "value": "C128777"},
                {"label": "Rancho Mirage", "value": "C128778"},
                {"label": "Rancho Murieta", "value": "C128779"},
                {"label": "Rancho Palos Verdes", "value": "C128780"},
                {"label": "Rancho Penasquitos", "value": "C128781"},
                {"label": "Rancho San Diego", "value": "C128782"},
                {"label": "Rancho Santa Fe", "value": "C128783"},
                {"label": "Rancho Santa Margarita", "value": "C128784"},
                {"label": "Rancho Tehama Reserve", "value": "C128785"},
                {"label": "Red Bluff", "value": "C128786"},
                {"label": "Red Corral", "value": "C128787"},
                {"label": "Redding", "value": "C128788"},
                {"label": "Redlands", "value": "C128789"},
                {"label": "Redondo Beach", "value": "C128790"},
                {"label": "Redway", "value": "C128791"},
                {"label": "Redwood City", "value": "C128792"},
                {"label": "Redwood Shores", "value": "C128793"},
                {"label": "Redwood Valley", "value": "C128794"},
                {"label": "Reedley", "value": "C128795"},
                {"label": "Rialto", "value": "C128796"},
                {"label": "Richgrove", "value": "C128797"},
                {"label": "Richmond", "value": "C128798"},
                {"label": "Ridgecrest", "value": "C128799"},
                {"label": "Ridgemark", "value": "C128800"},
                {"label": "Rio Del Mar", "value": "C128801"},
                {"label": "Rio Dell", "value": "C128802"},
                {"label": "Rio Linda", "value": "C128803"},
                {"label": "Rio Vista", "value": "C128804"},
                {"label": "Ripon", "value": "C128805"},
                {"label": "Riverbank", "value": "C128806"},
                {"label": "Riverdale", "value": "C128807"},
                {"label": "Riverdale Park", "value": "C128808"},
                {"label": "Riverside", "value": "C128809"},
                {"label": "Riverside County", "value": "C128810"},
                {"label": "Rocklin", "value": "C128811"},
                {"label": "Rodeo", "value": "C128812"},
                {"label": "Rohnert Park", "value": "C128813"},
                {"label": "Rolling Hills", "value": "C128814"},
                {"label": "Rolling Hills Estates", "value": "C128815"},
                {"label": "Rollingwood", "value": "C128816"},
                {"label": "Romoland", "value": "C128817"},
                {"label": "Rosamond", "value": "C128818"},
                {"label": "Rosedale", "value": "C128819"},
                {"label": "Roseland", "value": "C128820"},
                {"label": "Rosemead", "value": "C128821"},
                {"label": "Rosemont", "value": "C128822"},
                {"label": "Roseville", "value": "C128823"},
                {"label": "Ross", "value": "C128824"},
                {"label": "Rossmoor", "value": "C128825"},
                {"label": "Rowland Heights", "value": "C128826"},
                {"label": "Rubidoux", "value": "C128827"},
                {"label": "Running Springs", "value": "C128828"},
                {"label": "Sacramento", "value": "C128829"},
                {"label": "Sacramento County", "value": "C128830"},
                {"label": "Saint Helena", "value": "C128831"},
                {"label": "Salida", "value": "C128832"},
                {"label": "Salinas", "value": "C128833"},
                {"label": "Salton City", "value": "C128834"},
                {"label": "San Andreas", "value": "C128835"},
                {"label": "San Anselmo", "value": "C128836"},
                {"label": "San Antonio Heights", "value": "C128837"},
                {"label": "San Benito County", "value": "C128838"},
                {"label": "San Bernardino", "value": "C128839"},
                {"label": "San Bernardino County", "value": "C128840"},
                {"label": "San Bruno", "value": "C128841"},
                {"label": "San Carlos", "value": "C128842"},
                {"label": "San Clemente", "value": "C128843"},
                {"label": "San Diego", "value": "C128844"},
                {"label": "San Diego Country Estates", "value": "C128845"},
                {"label": "San Diego County", "value": "C128846"},
                {"label": "San Dimas", "value": "C128847"},
                {"label": "San Fernando", "value": "C128848"},
                {"label": "San Francisco", "value": "C128849"},
                {"label": "San Gabriel", "value": "C128850"},
                {"label": "San Jacinto", "value": "C128851"},
                {"label": "San Joaquin", "value": "C128852"},
                {"label": "San Joaquin County", "value": "C128853"},
                {"label": "San Joaquin Hills", "value": "C128854"},
                {"label": "San Jose", "value": "C128855"},
                {"label": "San Juan Bautista", "value": "C128856"},
                {"label": "San Juan Capistrano", "value": "C128857"},
                {"label": "San Leandro", "value": "C128858"},
                {"label": "San Lorenzo", "value": "C128859"},
                {"label": "San Luis Obispo", "value": "C128860"},
                {"label": "San Luis Obispo County", "value": "C128861"},
                {"label": "San Marcos", "value": "C128862"},
                {"label": "San Marino", "value": "C128863"},
                {"label": "San Martin", "value": "C128864"},
                {"label": "San Mateo", "value": "C128865"},
                {"label": "San Mateo County", "value": "C128866"},
                {"label": "San Miguel", "value": "C128867"},
                {"label": "San Pablo", "value": "C128868"},
                {"label": "San Pasqual", "value": "C128869"},
                {"label": "San Pedro", "value": "C128870"},
                {"label": "San Rafael", "value": "C128871"},
                {"label": "San Ramon", "value": "C128872"},
                {"label": "Sanger", "value": "C128873"},
                {"label": "Santa Ana", "value": "C128874"},
                {"label": "Santa Barbara", "value": "C128875"},
                {"label": "Santa Barbara County", "value": "C128876"},
                {"label": "Santa Clara", "value": "C128877"},
                {"label": "Santa Clara County", "value": "C128878"},
                {"label": "Santa Clarita", "value": "C128879"},
                {"label": "Santa Cruz", "value": "C128880"},
                {"label": "Santa Cruz County", "value": "C128881"},
                {"label": "Santa Fe Springs", "value": "C128882"},
                {"label": "Santa Margarita", "value": "C128883"},
                {"label": "Santa Maria", "value": "C128884"},
                {"label": "Santa Monica", "value": "C128885"},
                {"label": "Santa Paula", "value": "C128886"},
                {"label": "Santa Rosa", "value": "C128887"},
                {"label": "Santa Susana", "value": "C128888"},
                {"label": "Santa Venetia", "value": "C128889"},
                {"label": "Santa Ynez", "value": "C128890"},
                {"label": "Santee", "value": "C128891"},
                {"label": "Saranap", "value": "C128892"},
                {"label": "Saratoga", "value": "C128893"},
                {"label": "Saticoy", "value": "C128894"},
                {"label": "Sausalito", "value": "C128895"},
                {"label": "Scotts Valley", "value": "C128896"},
                {"label": "Sea Ranch", "value": "C128897"},
                {"label": "Seacliff", "value": "C128898"},
                {"label": "Seal Beach", "value": "C128899"},
                {"label": "Searles Valley", "value": "C128900"},
                {"label": "Seaside", "value": "C128901"},
                {"label": "Sebastopol", "value": "C128902"},
                {"label": "Sedco Hills", "value": "C128903"},
                {"label": "Seeley", "value": "C128904"},
                {"label": "Selma", "value": "C128905"},
                {"label": "Seven Trees", "value": "C128906"},
                {"label": "Shackelford", "value": "C128907"},
                {"label": "Shadow Hills", "value": "C128908"},
                {"label": "Shafter", "value": "C128909"},
                {"label": "Shandon", "value": "C128910"},
                {"label": "Shasta", "value": "C128911"},
                {"label": "Shasta County", "value": "C128912"},
                {"label": "Shasta Lake", "value": "C128913"},
                {"label": "Sheridan", "value": "C128914"},
                {"label": "Sherman Oaks", "value": "C128915"},
                {"label": "Shingle Springs", "value": "C128916"},
                {"label": "Shingletown", "value": "C128917"},
                {"label": "Sierra County", "value": "C128918"},
                {"label": "Sierra Madre", "value": "C128919"},
                {"label": "Signal Hill", "value": "C128920"},
                {"label": "Silver Lake", "value": "C128921"},
                {"label": "Silver Lakes", "value": "C128922"},
                {"label": "Simi Valley", "value": "C128923"},
                {"label": "Siskiyou County", "value": "C128924"},
                {"label": "Sky Valley", "value": "C128925"},
                {"label": "Sleepy Hollow", "value": "C128926"},
                {"label": "Soda Bay", "value": "C128927"},
                {"label": "Solana Beach", "value": "C128928"},
                {"label": "Solano County", "value": "C128929"},
                {"label": "Soledad", "value": "C128930"},
                {"label": "Solvang", "value": "C128931"},
                {"label": "Sonoma", "value": "C128932"},
                {"label": "Sonoma County", "value": "C128933"},
                {"label": "Sonora", "value": "C128934"},
                {"label": "Soquel", "value": "C128935"},
                {"label": "Sorrento Valley", "value": "C128936"},
                {"label": "Soulsbyville", "value": "C128937"},
                {"label": "South Dos Palos", "value": "C128938"},
                {"label": "South El Monte", "value": "C128939"},
                {"label": "South Gate", "value": "C128940"},
                {"label": "South Lake Tahoe", "value": "C128941"},
                {"label": "South Oroville", "value": "C128942"},
                {"label": "South Pasadena", "value": "C128943"},
                {"label": "South San Francisco", "value": "C128944"},
                {"label": "South San Gabriel", "value": "C128945"},
                {"label": "South San Jose Hills", "value": "C128946"},
                {"label": "South Taft", "value": "C128947"},
                {"label": "South Whittier", "value": "C128948"},
                {"label": "South Yuba City", "value": "C128949"},
                {"label": "Spring Valley", "value": "C128950"},
                {"label": "Spring Valley Lake", "value": "C128951"},
                {"label": "Squaw Valley", "value": "C128952"},
                {"label": "Stallion Springs", "value": "C128953"},
                {"label": "Stanford", "value": "C128954"},
                {"label": "Stanislaus County", "value": "C128955"},
                {"label": "Stanton", "value": "C128956"},
                {"label": "Stevenson Ranch", "value": "C128957"},
                {"label": "Stockton", "value": "C128958"},
                {"label": "Stratford", "value": "C128959"},
                {"label": "Strathmore", "value": "C128960"},
                {"label": "Strawberry", "value": "C128961"},
                {"label": "Studio City", "value": "C128962"},
                {"label": "Suisun", "value": "C128963"},
                {"label": "Summerland", "value": "C128964"},
                {"label": "Sun City", "value": "C128965"},
                {"label": "Sun Village", "value": "C128966"},
                {"label": "Sunland", "value": "C128967"},
                {"label": "Sunnyside", "value": "C128968"},
                {"label": "Sunnyside-Tahoe City", "value": "C128969"},
                {"label": "Sunnyslope", "value": "C128970"},
                {"label": "Sunnyvale", "value": "C128971"},
                {"label": "Susanville", "value": "C128972"},
                {"label": "Sutter", "value": "C128973"},
                {"label": "Sutter County", "value": "C128974"},
                {"label": "Sutter Creek", "value": "C128975"},
                {"label": "Taft", "value": "C128976"},
                {"label": "Taft Heights", "value": "C128977"},
                {"label": "Taft Mosswood", "value": "C128978"},
                {"label": "Tahoe Vista", "value": "C128979"},
                {"label": "Tahoma", "value": "C128980"},
                {"label": "Talmage", "value": "C128981"},
                {"label": "Tamalpais Valley", "value": "C128982"},
                {"label": "Tamalpais-Homestead Valley", "value": "C128983"},
                {"label": "Tara Hills", "value": "C128984"},
                {"label": "Tarpey Village", "value": "C128985"},
                {"label": "Tehachapi", "value": "C128986"},
                {"label": "Tehama County", "value": "C128987"},
                {"label": "Temecula", "value": "C128988"},
                {"label": "Temelec", "value": "C128989"},
                {"label": "Temple City", "value": "C128990"},
                {"label": "Templeton", "value": "C128991"},
                {"label": "Terra Bella", "value": "C128992"},
                {"label": "Teviston", "value": "C128993"},
                {"label": "Thermal", "value": "C128994"},
                {"label": "Thermalito", "value": "C128995"},
                {"label": "Thornton", "value": "C128996"},
                {"label": "Thousand Oaks", "value": "C128997"},
                {"label": "Thousand Palms", "value": "C128998"},
                {"label": "Three Rivers", "value": "C128999"},
                {"label": "Tiburon", "value": "C129000"},
                {"label": "Tierra Buena", "value": "C129001"},
                {"label": "Tipton", "value": "C129002"},
                {"label": "Topanga", "value": "C129003"},
                {"label": "Toro Canyon", "value": "C129004"},
                {"label": "Torrance", "value": "C129005"},
                {"label": "Trabuco Canyon", "value": "C129006"},
                {"label": "Tracy", "value": "C129007"},
                {"label": "Trinity County", "value": "C129008"},
                {"label": "Truckee", "value": "C129009"},
                {"label": "Tujunga", "value": "C129010"},
                {"label": "Tulare", "value": "C129011"},
                {"label": "Tulare County", "value": "C129012"},
                {"label": "Tuolumne City", "value": "C129013"},
                {"label": "Tuolumne County", "value": "C129014"},
                {"label": "Turlock", "value": "C129015"},
                {"label": "Tustin", "value": "C129016"},
                {"label": "Twain Harte", "value": "C129017"},
                {"label": "Twentynine Palms", "value": "C129018"},
                {"label": "Twin Lakes", "value": "C129019"},
                {"label": "Ukiah", "value": "C129020"},
                {"label": "Union City", "value": "C129021"},
                {"label": "Universal City", "value": "C129022"},
                {"label": "Upland", "value": "C129023"},
                {"label": "Upper Lake", "value": "C129024"},
                {"label": "Vacaville", "value": "C129025"},
                {"label": "Val Verde", "value": "C129026"},
                {"label": "Valencia", "value": "C129027"},
                {"label": "Valinda", "value": "C129028"},
                {"label": "Valle Vista", "value": "C129029"},
                {"label": "Vallejo", "value": "C129030"},
                {"label": "Valley Center", "value": "C129031"},
                {"label": "Valley Glen", "value": "C129032"},
                {"label": "Valley Springs", "value": "C129033"},
                {"label": "Van Nuys", "value": "C129034"},
                {"label": "Vandenberg Air Force Base", "value": "C129035"},
                {"label": "Vandenberg Village", "value": "C129036"},
                {"label": "Venice", "value": "C129037"},
                {"label": "Ventura", "value": "C129038"},
                {"label": "Ventura County", "value": "C129039"},
                {"label": "Victorville", "value": "C129040"},
                {"label": "View Park-Windsor Hills", "value": "C129041"},
                {"label": "Villa Park", "value": "C129042"},
                {"label": "Vincent", "value": "C129043"},
                {"label": "Vine Hill", "value": "C129044"},
                {"label": "Vineyard", "value": "C129045"},
                {"label": "Visalia", "value": "C129046"},
                {"label": "Visitacion Valley", "value": "C129047"},
                {"label": "Vista", "value": "C129048"},
                {"label": "Vista Santa Rosa", "value": "C129049"},
                {"label": "Waldon", "value": "C129050"},
                {"label": "Walnut", "value": "C129051"},
                {"label": "Walnut Creek", "value": "C129052"},
                {"label": "Walnut Grove", "value": "C129053"},
                {"label": "Walnut Park", "value": "C129054"},
                {"label": "Wasco", "value": "C129055"},
                {"label": "Waterford", "value": "C129056"},
                {"label": "Watsonville", "value": "C129057"},
                {"label": "Weaverville", "value": "C129058"},
                {"label": "Weed", "value": "C129059"},
                {"label": "Weedpatch", "value": "C129060"},
                {"label": "Weldon", "value": "C129061"},
                {"label": "West Athens", "value": "C129062"},
                {"label": "West Bishop", "value": "C129063"},
                {"label": "West Carson", "value": "C129064"},
                {"label": "West Covina", "value": "C129065"},
                {"label": "West Hills", "value": "C129066"},
                {"label": "West Hollywood", "value": "C129067"},
                {"label": "West Menlo Park", "value": "C129068"},
                {"label": "West Modesto", "value": "C129069"},
                {"label": "West Park", "value": "C129070"},
                {"label": "West Puente Valley", "value": "C129071"},
                {"label": "West Rancho Dominguez", "value": "C129072"},
                {"label": "West Sacramento", "value": "C129073"},
                {"label": "West Whittier-Los Nietos", "value": "C129074"},
                {"label": "Westhaven-Moonstone", "value": "C129075"},
                {"label": "Westlake Village", "value": "C129076"},
                {"label": "Westminster", "value": "C129077"},
                {"label": "Westmont", "value": "C129078"},
                {"label": "Westmorland", "value": "C129079"},
                {"label": "Westwood", "value": "C129080"},
                {"label": "Wheatland", "value": "C129081"},
                {"label": "Whittier", "value": "C129082"},
                {"label": "Wildomar", "value": "C129083"},
                {"label": "Williams", "value": "C129084"},
                {"label": "Willits", "value": "C129085"},
                {"label": "Willow Creek", "value": "C129086"},
                {"label": "Willowbrook", "value": "C129087"},
                {"label": "Willows", "value": "C129088"},
                {"label": "Wilton", "value": "C129089"},
                {"label": "Winchester", "value": "C129090"},
                {"label": "Windsor", "value": "C129091"},
                {"label": "Winter Gardens", "value": "C129092"},
                {"label": "Winters", "value": "C129093"},
                {"label": "Winton", "value": "C129094"},
                {"label": "Wofford Heights", "value": "C129095"},
                {"label": "Woodacre", "value": "C129096"},
                {"label": "Woodbridge", "value": "C129097"},
                {"label": "Woodcrest", "value": "C129098"},
                {"label": "Woodlake", "value": "C129099"},
                {"label": "Woodland", "value": "C129100"},
                {"label": "Woodland Hills", "value": "C129101"},
                {"label": "Woodside", "value": "C129102"},
                {"label": "Woodville", "value": "C129103"},
                {"label": "Wrightwood", "value": "C129104"},
                {"label": "Yolo County", "value": "C129105"},
                {"label": "Yorba Linda", "value": "C129106"},
                {"label": "Yosemite Lakes", "value": "C129107"},
                {"label": "Yosemite Valley", "value": "C129108"},
                {"label": "Yountville", "value": "C129109"},
                {"label": "Yreka", "value": "C129110"},
                {"label": "Yuba City", "value": "C129111"},
                {"label": "Yuba County", "value": "C129112"},
                {"label": "Yucaipa", "value": "C129113"},
                {"label": "Yucca Valley", "value": "C129114"}],
 "Callao": [{"label": "Callao", "value": "C80741"}],
 "Camaguey Province": [{"label": "Camaguey", "value": "C21688"},
                       {"label": "El Caney", "value": "C21689"},
                       {"label": "Esmeralda", "value": "C21690"},
                       {"label": "Florida", "value": "C21691"},
                       {"label": "Guaimaro", "value": "C21692"},
                       {"label": "Jimaguayu", "value": "C21693"},
                       {"label": "Minas", "value": "C21694"},
                       {"label": "Municipio de Florida", "value": "C21695"},
                       {"label": "Municipio de Nuevitas", "value": "C21696"},
                       {"label": "Nuevitas", "value": "C21697"},
                       {"label": "Santa Cruz del Sur", "value": "C21698"},
                       {"label": "Sibanicu", "value": "C21699"},
                       {"label": "Vertientes", "value": "C21700"}],
 "Camarines Norte": [{"label": "Ambuclao", "value": "C84906"},
                     {"label": "Amlimay", "value": "C84907"},
                     {"label": "Ampusungan", "value": "C84908"},
                     {"label": "Angad", "value": "C84909"},
                     {"label": "Atok", "value": "C84910"},
                     {"label": "Baculongan", "value": "C84911"},
                     {"label": "Baguinge", "value": "C84912"},
                     {"label": "Baguio", "value": "C84913"},
                     {"label": "Bakun", "value": "C84914"},
                     {"label": "Balbalan", "value": "C84915"},
                     {"label": "Banaue", "value": "C84916"},
                     {"label": "Bangao", "value": "C84917"},
                     {"label": "Bangued", "value": "C84918"},
                     {"label": "Bantay", "value": "C84919"},
                     {"label": "Barlig", "value": "C84920"},
                     {"label": "Bauko", "value": "C84921"},
                     {"label": "Bayabas", "value": "C84922"},
                     {"label": "Besao", "value": "C84923"},
                     {"label": "Betwagan", "value": "C84924"},
                     {"label": "Bocos", "value": "C84925"},
                     {"label": "Bokod", "value": "C84926"},
                     {"label": "Boliney", "value": "C84927"},
                     {"label": "Bontoc", "value": "C84928"},
                     {"label": "Bucay", "value": "C84929"},
                     {"label": "Bucloc", "value": "C84930"},
                     {"label": "Buguias", "value": "C84931"},
                     {"label": "Bulalacao", "value": "C84932"},
                     {"label": "Butigui", "value": "C84933"},
                     {"label": "Calaba", "value": "C84934"},
                     {"label": "Calanasan", "value": "C84935"},
                     {"label": "Conner", "value": "C84936"},
                     {"label": "Daguioman", "value": "C84937"},
                     {"label": "Dalipey", "value": "C84938"},
                     {"label": "Dalupirip", "value": "C84939"},
                     {"label": "Danglas", "value": "C84940"},
                     {"label": "Dolores", "value": "C84941"},
                     {"label": "Flora", "value": "C84942"},
                     {"label": "Gambang", "value": "C84943"},
                     {"label": "Guinsadan", "value": "C84944"},
                     {"label": "Hapao", "value": "C84945"},
                     {"label": "Hingyon", "value": "C84946"},
                     {"label": "Hungduan", "value": "C84947"},
                     {"label": "Itogon", "value": "C84948"},
                     {"label": "Kabayan", "value": "C84949"},
                     {"label": "Kabugao", "value": "C84950"},
                     {"label": "Kapangan", "value": "C84951"},
                     {"label": "Kiangan", "value": "C84952"},
                     {"label": "Kibungan", "value": "C84953"},
                     {"label": "La Paz", "value": "C84954"},
                     {"label": "La Trinidad", "value": "C84955"},
                     {"label": "Lacub", "value": "C84956"},
                     {"label": "Lagangilang", "value": "C84957"},
                     {"label": "Lagawe", "value": "C84958"},
                     {"label": "Lagayan", "value": "C84959"},
                     {"label": "Lamut", "value": "C84960"},
                     {"label": "Langiden", "value": "C84961"},
                     {"label": "Laya", "value": "C84962"},
                     {"label": "Licuan", "value": "C84963"},
                     {"label": "Licuan-Baay", "value": "C84964"},
                     {"label": "Liwan", "value": "C84965"},
                     {"label": "Loacan", "value": "C84966"},
                     {"label": "Luba", "value": "C84967"},
                     {"label": "Lubuagan", "value": "C84968"},
                     {"label": "Luna", "value": "C84969"},
                     {"label": "Malibcong", "value": "C84970"},
                     {"label": "Manabo", "value": "C84971"},
                     {"label": "Mankayan", "value": "C84972"},
                     {"label": "Mayoyao", "value": "C84973"},
                     {"label": "Monamon", "value": "C84974"},
                     {"label": "Nangalisan", "value": "C84975"},
                     {"label": "Natonin", "value": "C84976"},
                     {"label": "Natubleng", "value": "C84977"},
                     {"label": "Paracelis", "value": "C84978"},
                     {"label": "Pasil", "value": "C84979"},
                     {"label": "Penarrubia", "value": "C84980"},
                     {"label": "Pidigan", "value": "C84981"},
                     {"label": "Pilar", "value": "C84982"},
                     {"label": "Pinukpuk", "value": "C84983"},
                     {"label": "Potia", "value": "C84984"},
                     {"label": "Pudtol", "value": "C84985"},
                     {"label": "Rizal", "value": "C84986"},
                     {"label": "Sabangan", "value": "C84987"},
                     {"label": "Sablan", "value": "C84988"},
                     {"label": "Sadanga", "value": "C84989"},
                     {"label": "Sadsadan", "value": "C84990"},
                     {"label": "Sagada", "value": "C84991"},
                     {"label": "Sal-Lapadan", "value": "C84992"},
                     {"label": "San Isidro", "value": "C84993"},
                     {"label": "San Juan", "value": "C84994"},
                     {"label": "San Quintin", "value": "C84995"},
                     {"label": "San Ramon", "value": "C84996"},
                     {"label": "Santa Marcela", "value": "C84997"},
                     {"label": "Tabaan", "value": "C84998"},
                     {"label": "Tabio", "value": "C84999"},
                     {"label": "Tabuk", "value": "C85000"},
                     {"label": "Tacadang", "value": "C85001"},
                     {"label": "Tadian", "value": "C85002"},
                     {"label": "Taloy", "value": "C85003"},
                     {"label": "Tanudan", "value": "C85004"},
                     {"label": "Tayum", "value": "C85005"},
                     {"label": "Tineg", "value": "C85006"},
                     {"label": "Tinglayan", "value": "C85007"},
                     {"label": "Tinoc", "value": "C85008"},
                     {"label": "Topdac", "value": "C85009"},
                     {"label": "Tuba", "value": "C85010"},
                     {"label": "Tublay", "value": "C85011"},
                     {"label": "Tubo", "value": "C85012"},
                     {"label": "Tuding", "value": "C85013"},
                     {"label": "Villarosa", "value": "C85014"},
                     {"label": "Villaviciosa", "value": "C85015"}],
 "Campania": [{"label": "Acerno", "value": "C53134"},
              {"label": "Acerra", "value": "C53135"},
              {"label": "Afragola", "value": "C53136"},
              {"label": "Agerola", "value": "C53137"},
              {"label": "Agropoli", "value": "C53138"},
              {"label": "Aiello del Sabato", "value": "C53139"},
              {"label": "Ailano", "value": "C53140"},
              {"label": "Airola", "value": "C53141"},
              {"label": "Albanella", "value": "C53142"},
              {"label": "Alfano", "value": "C53143"},
              {"label": "Alife", "value": "C53144"},
              {"label": "Altavilla Irpina", "value": "C53145"},
              {"label": "Altavilla Silentina", "value": "C53146"},
              {"label": "Alvignano", "value": "C53147"},
              {"label": "Amalfi", "value": "C53148"},
              {"label": "Amodio-Massariola", "value": "C53149"},
              {"label": "Amorosi", "value": "C53150"},
              {"label": "Anacapri", "value": "C53151"},
              {"label": "Andretta-Mattinella", "value": "C53152"},
              {"label": "Angri", "value": "C53153"},
              {"label": "Annunziata", "value": "C53154"},
              {"label": "Antessano", "value": "C53155"},
              {"label": "Apice Vecchio", "value": "C53156"},
              {"label": "Apollosa", "value": "C53157"},
              {"label": "Aquara", "value": "C53158"},
              {"label": "Aquilonia", "value": "C53159"},
              {"label": "Arenella", "value": "C53160"},
              {"label": "Ariano", "value": "C53161"},
              {"label": "Ariano Irpino-Martiri", "value": "C53162"},
              {"label": "Arienzo", "value": "C53163"},
              {"label": "Ariola", "value": "C53164"},
              {"label": "Arola-Preazzano", "value": "C53165"},
              {"label": "Arpaia", "value": "C53166"},
              {"label": "Arpaise", "value": "C53167"},
              {"label": "Arpino", "value": "C53168"},
              {"label": "Arzano", "value": "C53169"},
              {"label": "Ascea", "value": "C53170"},
              {"label": "Atena Lucana", "value": "C53171"},
              {"label": "Atrani", "value": "C53172"},
              {"label": "Atripalda", "value": "C53173"},
              {"label": "Auletta", "value": "C53174"},
              {"label": "Avella", "value": "C53175"},
              {"label": "Avellino", "value": "C53176"},
              {"label": "Aversa", "value": "C53177"},
              {"label": "Avvocata", "value": "C53178"},
              {"label": "Bacoli", "value": "C53179"},
              {"label": "Bagnoli", "value": "C53180"},
              {"label": "Bagnoli Irpino", "value": "C53181"},
              {"label": "Baia", "value": "C53182"},
              {"label": "Baiano", "value": "C53183"},
              {"label": "Banzano", "value": "C53184"},
              {"label": "Barano dIschia", "value": "C53185"},
              {"label": "Baronissi", "value": "C53186"},
              {"label": "Barra", "value": "C53187"},
              {"label": "Baselice", "value": "C53188"},
              {"label": "Battipaglia", "value": "C53189"},
              {"label": "Bellizzi", "value": "C53190"},
              {"label": "Bellona", "value": "C53191"},
              {"label": "Bellosguardo", "value": "C53192"},
              {"label": "Beltiglio-San Giovanni", "value": "C53193"},
              {"label": "Benevento", "value": "C53194"},
              {"label": "Bisaccia", "value": "C53195"},
              {"label": "Bisaccia Nuova", "value": "C53196"},
              {"label": "Bivio Mortola", "value": "C53197"},
              {"label": "Bivio Santa Cecilia", "value": "C53198"},
              {"label": "Bonea", "value": "C53199"},
              {"label": "Bonito", "value": "C53200"},
              {"label": "Borgo", "value": "C53201"},
              {"label": "Boscoreale", "value": "C53202"},
              {"label": "Boscotrecase", "value": "C53203"},
              {"label": "Bracigliano", "value": "C53204"},
              {"label": "Brezza", "value": "C53205"},
              {"label": "Brusciano", "value": "C53206"},
              {"label": "Bucciano", "value": "C53207"},
              {"label": "Buccino", "value": "C53208"},
              {"label": "Buonabitacolo", "value": "C53209"},
              {"label": "Buonalbergo", "value": "C53210"},
              {"label": "Caggiano", "value": "C53211"},
              {"label": "Caianello", "value": "C53212"},
              {"label": "Caiazzo", "value": "C53213"},
              {"label": "Cairano", "value": "C53214"},
              {"label": "Caivano", "value": "C53215"},
              {"label": "Calabritto", "value": "C53216"},
              {"label": "Calitri", "value": "C53217"},
              {"label": "Calvanico", "value": "C53218"},
              {"label": "Calvi", "value": "C53219"},
              {"label": "Calvi Risorta", "value": "C53220"},
              {"label": "Calvizzano", "value": "C53221"},
              {"label": "Camerota", "value": "C53222"},
              {"label": "Camigliano", "value": "C53223"},
              {"label": "Campagna", "value": "C53224"},
              {"label": "Campanarello", "value": "C53225"},
              {"label": "Campolattaro", "value": "C53226"},
              {"label": "Campoli del Monte Taburno", "value": "C53227"},
              {"label": "Campora", "value": "C53228"},
              {"label": "Camposano", "value": "C53229"},
              {"label": "Cancello-Arnone", "value": "C53230"},
              {"label": "Candida", "value": "C53231"},
              {"label": "Cannalonga", "value": "C53232"},
              {"label": "Capaccio", "value": "C53233"},
              {"label": "Capaccio Scalo", "value": "C53234"},
              {"label": "Capezzano Inferiore", "value": "C53235"},
              {"label": "Capezzano-Cologna", "value": "C53236"},
              {"label": "Capitignano", "value": "C53237"},
              {"label": "Capodrise", "value": "C53238"},
              {"label": "Caposele", "value": "C53239"},
              {"label": "Capri", "value": "C53240"},
              {"label": "Capriati A Volturno", "value": "C53241"},
              {"label": "Capriglia", "value": "C53242"},
              {"label": "Capriglia Irpina", "value": "C53243"},
              {"label": "Capua", "value": "C53244"},
              {"label": "Carano", "value": "C53245"},
              {"label": "Carbonara di Nola", "value": "C53246"},
              {"label": "Cardito", "value": "C53247"},
              {"label": "Carife", "value": "C53248"},
              {"label": "Carifi-Torello-Priscoli", "value": "C53249"},
              {"label": "Carinaro", "value": "C53250"},
              {"label": "Carinola", "value": "C53251"},
              {"label": "Casagiove", "value": "C53252"},
              {"label": "Casal di Principe", "value": "C53253"},
              {"label": "Casal Velino", "value": "C53254"},
              {"label": "Casalbore", "value": "C53255"},
              {"label": "Casalbuono", "value": "C53256"},
              {"label": "Casalduni", "value": "C53257"},
              {"label": "Casale", "value": "C53258"},
              {"label": "Casaletto Spartano", "value": "C53259"},
              {"label": "Casali-San Potito", "value": "C53260"},
              {"label": "Casalnuovo di Napoli", "value": "C53261"},
              {"label": "Casaluce", "value": "C53262"},
              {"label": "Casamarciano", "value": "C53263"},
              {"label": "Casamicciola Terme", "value": "C53264"},
              {"label": "Casandrino", "value": "C53265"},
              {"label": "Casanova", "value": "C53266"},
              {"label": "Casapesenna", "value": "C53267"},
              {"label": "Casapulla", "value": "C53268"},
              {"label": "Casarea", "value": "C53269"},
              {"label": "Casavatore", "value": "C53270"},
              {"label": "Cascano", "value": "C53271"},
              {"label": "Caselle in Pittari", "value": "C53272"},
              {"label": "Caserta", "value": "C53273"},
              {"label": "Casola", "value": "C53274"},
              {"label": "Casola di Napoli", "value": "C53275"},
              {"label": "Casoria", "value": "C53276"},
              {"label": "Cassano Irpino", "value": "C53277"},
              {"label": "Castel Baronia", "value": "C53278"},
              {"label": "Castel Campagnano", "value": "C53279"},
              {"label": "Castel di Sasso", "value": "C53280"},
              {"label": "Castel Morrone", "value": "C53281"},
              {"label": "Castel San Giorgio", "value": "C53282"},
              {"label": "Castel San Lorenzo", "value": "C53283"},
              {"label": "Castel Volturno", "value": "C53284"},
              {"label": "Castelcivita", "value": "C53285"},
              {"label": "Castelfranci", "value": "C53286"},
              {"label": "Castelfranco in Miscano", "value": "C53287"},
              {"label": "Castellammare di Stabia", "value": "C53288"},
              {"label": "Castello del Matese", "value": "C53289"},
              {"label": "Castello di Cisterna", "value": "C53290"},
              {"label": "Castelnuovo Cilento", "value": "C53291"},
              {"label": "Castelnuovo di Conza", "value": "C53292"},
              {"label": "Castelpagano", "value": "C53293"},
              {"label": "Castelpoto", "value": "C53294"},
              {"label": "Castelvenere", "value": "C53295"},
              {"label": "Castelvetere in Val Fortore", "value": "C53296"},
              {"label": "Castelvetere sul Calore", "value": "C53297"},
              {"label": "Castiglione del Genovesi", "value": "C53298"},
              {"label": "Cautano", "value": "C53299"},
              {"label": "Cava De Tirreni", "value": "C53300"},
              {"label": "Celle di Bulgheria", "value": "C53301"},
              {"label": "Cellole", "value": "C53302"},
              {"label": "Centola", "value": "C53303"},
              {"label": "Centro Urbano", "value": "C53304"},
              {"label": "Ceppaloni", "value": "C53305"},
              {"label": "Ceraso", "value": "C53306"},
              {"label": "Cercola", "value": "C53307"},
              {"label": "Cerreto Sannita", "value": "C53308"},
              {"label": "Cervinara", "value": "C53309"},
              {"label": "Cervino", "value": "C53310"},
              {"label": "Cesa", "value": "C53311"},
              {"label": "Cesinali", "value": "C53312"},
              {"label": "Cetara", "value": "C53313"},
              {"label": "Chiaia", "value": "C53314"},
              {"label": "Chiaiano", "value": "C53315"},
              {"label": "Chianche", "value": "C53316"},
              {"label": "Chiusano di San Domenico", "value": "C53317"},
              {"label": "Cicciano", "value": "C53318"},
              {"label": "Cicerale", "value": "C53319"},
              {"label": "Cimitile", "value": "C53320"},
              {"label": "Ciorlano", "value": "C53321"},
              {"label": "Circello", "value": "C53322"},
              {"label": "Colle Sannita", "value": "C53323"},
              {"label": "Colliano", "value": "C53324"},
              {"label": "Comiziano", "value": "C53325"},
              {"label": "Conca dei Marini", "value": "C53326"},
              {"label": "Conca della Campania", "value": "C53327"},
              {"label": "Contrada", "value": "C53328"},
              {"label": "Controne", "value": "C53329"},
              {"label": "Contursi Terme", "value": "C53330"},
              {"label": "Coperchia", "value": "C53331"},
              {"label": "Corbara", "value": "C53332"},
              {"label": "Corleto Monforte", "value": "C53333"},
              {"label": "Country Park", "value": "C53334"},
              {"label": "Crispano", "value": "C53335"},
              {"label": "Cuccaro Vetere", "value": "C53336"},
              {"label": "Curti", "value": "C53337"},
              {"label": "Cusano Mutri", "value": "C53338"},
              {"label": "Domicella", "value": "C53339"},
              {"label": "Dragonea", "value": "C53340"},
              {"label": "Dragoni", "value": "C53341"},
              {"label": "Dugenta", "value": "C53342"},
              {"label": "Durazzano", "value": "C53343"},
              {"label": "Eboli", "value": "C53344"},
              {"label": "Ercolano", "value": "C53345"},
              {"label": "Faiano", "value": "C53346"},
              {"label": "Faicchio", "value": "C53347"},
              {"label": "Falciano del Massico", "value": "C53348"},
              {"label": "Faraldo-Nocelleto", "value": "C53349"},
              {"label": "Felitto", "value": "C53350"},
              {"label": "Fisciano", "value": "C53351"},
              {"label": "Flumeri", "value": "C53352"},
              {"label": "Foglianise", "value": "C53353"},
              {"label": "Foiano di Val Fortore", "value": "C53354"},
              {"label": "Fontanarosa", "value": "C53355"},
              {"label": "Fontegreca", "value": "C53356"},
              {"label": "Forchia", "value": "C53357"},
              {"label": "Forino", "value": "C53358"},
              {"label": "Forio", "value": "C53359"},
              {"label": "Formicola", "value": "C53360"},
              {"label": "Fragneto LAbate", "value": "C53361"},
              {"label": "Fragneto Monforte", "value": "C53362"},
              {"label": "Franche", "value": "C53363"},
              {"label": "Francolise", "value": "C53364"},
              {"label": "Frasso Telesino", "value": "C53365"},
              {"label": "Frattamaggiore", "value": "C53366"},
              {"label": "Frattaminore", "value": "C53367"},
              {"label": "Frigento", "value": "C53368"},
              {"label": "Frignano", "value": "C53369"},
              {"label": "Fuorigrotta", "value": "C53370"},
              {"label": "Fuorni", "value": "C53371"},
              {"label": "Furore", "value": "C53372"},
              {"label": "Futani", "value": "C53373"},
              {"label": "Gallo", "value": "C53374"},
              {"label": "Gallo Matese", "value": "C53375"},
              {"label": "Galluccio", "value": "C53376"},
              {"label": "Gesualdo", "value": "C53377"},
              {"label": "Giano Vetusto", "value": "C53378"},
              {"label": "Giffoni Valle Piana", "value": "C53379"},
              {"label": "Ginestra degli Schiavoni", "value": "C53380"},
              {"label": "Gioi", "value": "C53381"},
              {"label": "Gioia Sannitica", "value": "C53382"},
              {"label": "Giugliano in Campania", "value": "C53383"},
              {"label": "Giungano", "value": "C53384"},
              {"label": "Gragnano", "value": "C53385"},
              {"label": "Grazzanise", "value": "C53386"},
              {"label": "Greci", "value": "C53387"},
              {"label": "Gricignano di Aversa", "value": "C53388"},
              {"label": "Grottaminarda", "value": "C53389"},
              {"label": "Grottola", "value": "C53390"},
              {"label": "Grottolella", "value": "C53391"},
              {"label": "Grumo Nevano", "value": "C53392"},
              {"label": "Guardia Lombardi", "value": "C53393"},
              {"label": "Guardia Sanframondi", "value": "C53394"},
              {"label": "Ischia", "value": "C53395"},
              {"label": "Ischia Porto", "value": "C53396"},
              {"label": "Ispani", "value": "C53397"},
              {"label": "Lacco Ameno", "value": "C53398"},
              {"label": "Lacedonia", "value": "C53399"},
              {"label": "Lancusi-Penta-Bolano", "value": "C53400"},
              {"label": "Lanzara", "value": "C53401"},
              {"label": "Lapio", "value": "C53402"},
              {"label": "Laura", "value": "C53403"},
              {"label": "Laureana Cilento", "value": "C53404"},
              {"label": "Laurino", "value": "C53405"},
              {"label": "Laurito", "value": "C53406"},
              {"label": "Lauro", "value": "C53407"},
              {"label": "Laviano", "value": "C53408"},
              {"label": "Letino", "value": "C53409"},
              {"label": "Lettere", "value": "C53410"},
              {"label": "Liberi", "value": "C53411"},
              {"label": "Licinella-Torre di Paestum", "value": "C53412"},
              {"label": "Licusati", "value": "C53413"},
              {"label": "Limatola", "value": "C53414"},
              {"label": "Lioni", "value": "C53415"},
              {"label": "Liveri", "value": "C53416"},
              {"label": "Luogosano", "value": "C53417"},
              {"label": "Lusciano", "value": "C53418"},
              {"label": "Lustra", "value": "C53419"},
              {"label": "Luzzano", "value": "C53420"},
              {"label": "Macchia", "value": "C53421"},
              {"label": "Maccoli-Perrillo", "value": "C53422"},
              {"label": "Macerata Campania", "value": "C53423"},
              {"label": "Maddaloni", "value": "C53424"},
              {"label": "Magliano Vetere", "value": "C53425"},
              {"label": "Maiori", "value": "C53426"},
              {"label": "Malche-Santa Croce-Serroni", "value": "C53427"},
              {"label": "Manocalzati", "value": "C53428"},
              {"label": "Marano di Napoli", "value": "C53429"},
              {"label": "Marcianise", "value": "C53430"},
              {"label": "Mariglianella", "value": "C53431"},
              {"label": "Marigliano", "value": "C53432"},
              {"label": "Marina di Camerota", "value": "C53433"},
              {"label": "Marina di Casal Velino", "value": "C53434"},
              {"label": "Marzanello", "value": "C53435"},
              {"label": "Marzano Appio", "value": "C53436"},
              {"label": "Marzano di Nola", "value": "C53437"},
              {"label": "Massa di Somma", "value": "C53438"},
              {"label": "Massa Lubrense", "value": "C53439"},
              {"label": "Masseria Vecchia Ovest", "value": "C53440"},
              {"label": "Matinella", "value": "C53441"},
              {"label": "Melito di Napoli", "value": "C53442"},
              {"label": "Melito Irpino", "value": "C53443"},
              {"label": "Melizzano", "value": "C53444"},
              {"label": "Mercato", "value": "C53445"},
              {"label": "Mercato San Severino", "value": "C53446"},
              {"label": "Mercogliano", "value": "C53447"},
              {"label": "Meta", "value": "C53448"},
              {"label": "Miano", "value": "C53449"},
              {"label": "Mignano Monte Lungo", "value": "C53450"},
              {"label": "Minori", "value": "C53451"},
              {"label": "Mirabella Eclano", "value": "C53452"},
              {"label": "Moiano", "value": "C53453"},
              {"label": "Moio della Civitella-Pellare", "value": "C53454"},
              {"label": "Molinara", "value": "C53455"},
              {"label": "Mondragone", "value": "C53456"},
              {"label": "Montaguto", "value": "C53457"},
              {"label": "Montano Antilia", "value": "C53458"},
              {"label": "Monte di Procida", "value": "C53459"},
              {"label": "Monte San Giacomo", "value": "C53460"},
              {"label": "Montecalvario", "value": "C53461"},
              {"label": "Montecalvo Irpino", "value": "C53462"},
              {"label": "Montecorice", "value": "C53463"},
              {"label": "Montecorvino Pugliano", "value": "C53464"},
              {"label": "Montecorvino Rovella", "value": "C53465"},
              {"label": "Montedecoro", "value": "C53466"},
              {"label": "Montefalcione", "value": "C53467"},
              {"label": "Montefalcone di Val Fortore", "value": "C53468"},
              {"label": "Monteforte Cilento", "value": "C53469"},
              {"label": "Monteforte Irpino", "value": "C53470"},
              {"label": "Montefredane", "value": "C53471"},
              {"label": "Montefusco", "value": "C53472"},
              {"label": "Montella", "value": "C53473"},
              {"label": "Montemarano", "value": "C53474"},
              {"label": "Montemiletto", "value": "C53475"},
              {"label": "Monterusciello", "value": "C53476"},
              {"label": "Montesano sulla Marcellana", "value": "C53477"},
              {"label": "Montesarchio", "value": "C53478"},
              {"label": "Monteverde", "value": "C53479"},
              {"label": "Monticelli", "value": "C53480"},
              {"label": "Montoro Superiore", "value": "C53481"},
              {"label": "Morcone", "value": "C53482"},
              {"label": "Morigerati", "value": "C53483"},
              {"label": "Morra de Sanctis", "value": "C53484"},
              {"label": "Moschiano", "value": "C53485"},
              {"label": "Mugnano del Cardinale", "value": "C53486"},
              {"label": "Mugnano di Napoli", "value": "C53487"},
              {"label": "Musci", "value": "C53488"},
              {"label": "Naples", "value": "C53489"},
              {"label": "Napoli", "value": "C53490"},
              {"label": "Nocelleto", "value": "C53491"},
              {"label": "Nocera Inferiore", "value": "C53492"},
              {"label": "Nocera Superiore", "value": "C53493"},
              {"label": "Nola", "value": "C53494"},
              {"label": "Novi Velia", "value": "C53495"},
              {"label": "Nuova Conza della Campania", "value": "C53496"},
              {"label": "Nusco", "value": "C53497"},
              {"label": "Ogliastro Cilento", "value": "C53498"},
              {"label": "Olevano sul Tusciano", "value": "C53499"},
              {"label": "Oliveto Citra", "value": "C53500"},
              {"label": "Omignano", "value": "C53501"},
              {"label": "Orria", "value": "C53502"},
              {"label": "Orta di Atella", "value": "C53503"},
              {"label": "Ospedaletto dAlpinolo", "value": "C53504"},
              {"label": "Ottati", "value": "C53505"},
              {"label": "Ottaviano", "value": "C53506"},
              {"label": "Padula", "value": "C53507"},
              {"label": "Paduli", "value": "C53508"},
              {"label": "Pagani", "value": "C53509"},
              {"label": "Pagliarone", "value": "C53510"},
              {"label": "Pago del Vallo di Lauro", "value": "C53511"},
              {"label": "Pago Veiano", "value": "C53512"},
              {"label": "Palinuro", "value": "C53513"},
              {"label": "Palma Campania", "value": "C53514"},
              {"label": "Palomonte", "value": "C53515"},
              {"label": "Pannarano", "value": "C53516"},
              {"label": "Paolisi", "value": "C53517"},
              {"label": "Parete", "value": "C53518"},
              {"label": "Parolise", "value": "C53519"},
              {"label": "Pascarola", "value": "C53520"},
              {"label": "Passo di Mirabella-Pianopantano", "value": "C53521"},
              {"label": "Pastorano", "value": "C53522"},
              {"label": "Paternopoli", "value": "C53523"},
              {"label": "Paupisi", "value": "C53524"},
              {"label": "Pellezzano", "value": "C53525"},
              {"label": "Pendino", "value": "C53526"},
              {"label": "Perdifumo", "value": "C53527"},
              {"label": "Perito", "value": "C53528"},
              {"label": "Pertosa", "value": "C53529"},
              {"label": "Pesco Sannita", "value": "C53530"},
              {"label": "Petina", "value": "C53531"},
              {"label": "Petruro Irpino", "value": "C53532"},
              {"label": "Pezzano-Filetta", "value": "C53533"},
              {"label": "Piaggine", "value": "C53534"},
              {"label": "Piana di Monte Verna", "value": "C53535"},
              {"label": "Pianillo", "value": "C53536"},
              {"label": "Piano", "value": "C53537"},
              {"label": "Piano di Sorrento", "value": "C53538"},
              {"label": "Pianura", "value": "C53539"},
              {"label": "Piazza del Galdo-SantAngelo", "value": "C53540"},
              {"label": "Piazza di Pandola", "value": "C53541"},
              {"label": "Piazza Roma", "value": "C53542"},
              {"label": "Piazza-Tralia-Pendolo", "value": "C53543"},
              {"label": "Piazzola", "value": "C53544"},
              {"label": "Piazzolla", "value": "C53545"},
              {"label": "Piedimonte", "value": "C53546"},
              {"label": "Piedimonte Matese", "value": "C53547"},
              {"label": "Piegolelle-San Bartolomeo", "value": "C53548"},
              {"label": "Pietradefusi", "value": "C53549"},
              {"label": "Pietramelara", "value": "C53550"},
              {"label": "Pietraroja", "value": "C53551"},
              {"label": "Pietrastornina", "value": "C53552"},
              {"label": "Pietravairano", "value": "C53553"},
              {"label": "Pietre", "value": "C53554"},
              {"label": "Pietrelcina", "value": "C53555"},
              {"label": "Pignataro Maggiore", "value": "C53556"},
              {"label": "Pimonte", "value": "C53557"},
              {"label": "Piscinola", "value": "C53558"},
              {"label": "Pisciotta", "value": "C53559"},
              {"label": "Poggiomarino", "value": "C53560"},
              {"label": "Poggioreale", "value": "C53561"},
              {"label": "Policastro Bussentino", "value": "C53562"},
              {"label": "Polla", "value": "C53563"},
              {"label": "Pollena Trocchia", "value": "C53564"},
              {"label": "Pollica", "value": "C53565"},
              {"label": "Polvica", "value": "C53566"},
              {"label": "Pomigliano dArco", "value": "C53567"},
              {"label": "Pompei", "value": "C53568"},
              {"label": "Ponte", "value": "C53569"},
              {"label": "Pontecagnano", "value": "C53570"},
              {"label": "Pontelandolfo", "value": "C53571"},
              {"label": "Pontelatone", "value": "C53572"},
              {"label": "Ponticelli", "value": "C53573"},
              {"label": "Portici", "value": "C53574"},
              {"label": "Portico di Caserta", "value": "C53575"},
              {"label": "Porto", "value": "C53576"},
              {"label": "Posillipo", "value": "C53577"},
              {"label": "Positano", "value": "C53578"},
              {"label": "Postiglione", "value": "C53579"},
              {"label": "Pozzillo", "value": "C53580"},
              {"label": "Pozzuoli", "value": "C53581"},
              {"label": "Praiano", "value": "C53582"},
              {"label": "Prata di Principato Ultra", "value": "C53583"},
              {"label": "Prata Sannita Centro", "value": "C53584"},
              {"label": "Pratella", "value": "C53585"},
              {"label": "Prato Perillo", "value": "C53586"},
              {"label": "Pratola Serra", "value": "C53587"},
              {"label": "Pratole", "value": "C53588"},
              {"label": "Prepezzano", "value": "C53589"},
              {"label": "Presenzano", "value": "C53590"},
              {"label": "Prignano Cilento", "value": "C53591"},
              {"label": "Procida", "value": "C53592"},
              {"label": "Provincia di Avellino", "value": "C53593"},
              {"label": "Provincia di Benevento", "value": "C53594"},
              {"label": "Provincia di Caserta", "value": "C53595"},
              {"label": "Provincia di Salerno", "value": "C53596"},
              {"label": "Puglianello", "value": "C53597"},
              {"label": "Pugliano", "value": "C53598"},
              {"label": "Quadrelle", "value": "C53599"},
              {"label": "Quadrivio", "value": "C53600"},
              {"label": "Qualiano", "value": "C53601"},
              {"label": "Quarto", "value": "C53602"},
              {"label": "Quindici", "value": "C53603"},
              {"label": "Ravello", "value": "C53604"},
              {"label": "Raviscanina", "value": "C53605"},
              {"label": "Recale", "value": "C53606"},
              {"label": "Reino", "value": "C53607"},
              {"label": "Rettifilo-Vannullo", "value": "C53608"},
              {"label": "Riardo", "value": "C53609"},
              {"label": "Ricigliano", "value": "C53610"},
              {"label": "Rocca San Felice", "value": "C53611"},
              {"label": "Roccabascerana", "value": "C53612"},
              {"label": "Roccadaspide", "value": "C53613"},
              {"label": "Roccagloriosa", "value": "C53614"},
              {"label": "Roccamonfina", "value": "C53615"},
              {"label": "Roccapiemonte", "value": "C53616"},
              {"label": "Roccarainola", "value": "C53617"},
              {"label": "Roccaromana", "value": "C53618"},
              {"label": "Rocchetta", "value": "C53619"},
              {"label": "Rofrano", "value": "C53620"},
              {"label": "Roscigno", "value": "C53621"},
              {"label": "Rotondi", "value": "C53622"},
              {"label": "Rutino", "value": "C53623"},
              {"label": "Ruviano", "value": "C53624"},
              {"label": "Sacco", "value": "C53625"},
              {"label": "Sala", "value": "C53626"},
              {"label": "Sala Consilina", "value": "C53627"},
              {"label": "Salento", "value": "C53628"},
              {"label": "Salerno", "value": "C53629"},
              {"label": "Salitto-Valle", "value": "C53630"},
              {"label": "Salvitelle", "value": "C53631"},
              {"label": "Salza Irpina", "value": "C53632"},
              {"label": "San Bartolomeo in Galdo", "value": "C53633"},
              {"label": "San Carlo AllArena", "value": "C53634"},
              {"label": "San Castrese", "value": "C53635"},
              {"label": "San Cipriano dAversa", "value": "C53636"},
              {"label": "San Cipriano Picentino", "value": "C53637"},
              {"label": "San Clemente", "value": "C53638"},
              {"label": "San Felice A Cancello", "value": "C53639"},
              {"label": "San Ferdinando", "value": "C53640"},
              {"label": "San Gennaro Vesuviano", "value": "C53641"},
              {"label": "San Giorgio", "value": "C53642"},
              {"label": "San Giorgio a Cremano", "value": "C53643"},
              {"label": "San Giorgio del Sannio", "value": "C53644"},
              {"label": "San Giorgio la Molara", "value": "C53645"},
              {"label": "San Giovanni A Piro", "value": "C53646"},
              {"label": "San Giovanni a Teduccio", "value": "C53647"},
              {"label": "San Giuseppe", "value": "C53648"},
              {"label": "San Giuseppe Vesuviano", "value": "C53649"},
              {"label": "San Gregorio Magno", "value": "C53650"},
              {"label": "San Gregorio Matese", "value": "C53651"},
              {"label": "San Leucio del Sannio-Cavuoti", "value": "C53652"},
              {"label": "San Lorenzello", "value": "C53653"},
              {"label": "San Lorenzo", "value": "C53654"},
              {"label": "San Lorenzo Maggiore", "value": "C53655"},
              {"label": "San Lupo", "value": "C53656"},
              {"label": "San Mango Piemonte", "value": "C53657"},
              {"label": "San Mango sul Calore", "value": "C53658"},
              {"label": "San Marcellino", "value": "C53659"},
              {"label": "San Marco", "value": "C53660"},
              {"label": "San Marco dei Cavoti", "value": "C53661"},
              {"label": "San Marco Evangelista", "value": "C53662"},
              {"label": "San Martino Sannita", "value": "C53663"},
              {"label": "San Martino Valle Caudina", "value": "C53664"},
              {"label": "San Marzano sul Sarno", "value": "C53665"},
              {"label": "San Mauro Cilento", "value": "C53666"},
              {"label": "San Mauro la Bruca", "value": "C53667"},
              {"label": "San Michele", "value": "C53668"},
              {"label": "San Michele di Serino", "value": "C53669"},
              {"label": "San Nazzaro", "value": "C53670"},
              {"label": "San Nicola Baronia", "value": "C53671"},
              {"label": "San Nicola la Strada", "value": "C53672"},
              {"label": "San Nicola Manfredi", "value": "C53673"},
              {"label": "San Paolo Bel Sito", "value": "C53674"},
              {"label": "San Pietro", "value": "C53675"},
              {"label": "San Pietro a Patierno", "value": "C53676"},
              {"label": "San Pietro al Tanagro", "value": "C53677"},
              {"label": "San Pietro Infine", "value": "C53678"},
              {"label": "San Potito Sannitico", "value": "C53679"},
              {"label": "San Potito Ultra", "value": "C53680"},
              {"label": "San Prisco", "value": "C53681"},
              {"label": "San Rufo", "value": "C53682"},
              {"label": "San Salvatore Telesino", "value": "C53683"},
              {"label": "San Sebastiano al Vesuvio", "value": "C53684"},
              {"label": "San Sossio Baronia", "value": "C53685"},
              {"label": "San Tammaro", "value": "C53686"},
              {"label": "San Valentino Torio", "value": "C53687"},
              {"label": "San Vitaliano", "value": "C53688"},
              {"label": "SantAgata deGoti", "value": "C53689"},
              {"label": "SantAgata sui Due Golfi", "value": "C53690"},
              {"label": "SantAgnello", "value": "C53691"},
              {"label": "SantAnastasia", "value": "C53692"},
              {"label": "SantAndrea di Conza", "value": "C53693"},
              {"label": "SantAndrea-Pizzone-Ciamprisco", "value": "C53694"},
              {"label": "SantAngelo", "value": "C53695"},
              {"label": "SantAngelo A Cupolo", "value": "C53696"},
              {"label": "SantAngelo A Fasanella", "value": "C53697"},
              {"label": "SantAngelo A Scala", "value": "C53698"},
              {"label": "SantAngelo AllEsca", "value": "C53699"},
              {"label": "SantAngelo dAlife", "value": "C53700"},
              {"label": "SantAngelo dei Lombardi", "value": "C53701"},
              {"label": "SantAngelo in Formis", "value": "C53702"},
              {"label": "SantAntimo", "value": "C53703"},
              {"label": "SantAntonio", "value": "C53704"},
              {"label": "SantAntonio Abate", "value": "C53705"},
              {"label": "SantArcangelo Trimonte", "value": "C53706"},
              {"label": "SantArpino", "value": "C53707"},
              {"label": "SantArsenio", "value": "C53708"},
              {"label": "SantEgidio del Monte Albino", "value": "C53709"},
              {"label": "SantElena Irpina", "value": "C53710"},
              {"label": "Santa Croce del Sannio", "value": "C53711"},
              {"label": "Santa Lucia", "value": "C53712"},
              {"label": "Santa Lucia di Serino", "value": "C53713"},
              {"label": "Santa Maria", "value": "C53714"},
              {"label": "Santa Maria A Vico", "value": "C53715"},
              {"label": "Santa Maria Capua Vetere", "value": "C53716"},
              {"label": "Santa Maria La Carita", "value": "C53717"},
              {"label": "Santa Maria la Fossa", "value": "C53718"},
              {"label": "Santa Marina", "value": "C53719"},
              {"label": "Santa Paolina", "value": "C53720"},
              {"label": "Santa Tecla-Castelpagano", "value": "C53721"},
              {"label": "Santo Stefano del Sole", "value": "C53722"},
              {"label": "Santomenna", "value": "C53723"},
              {"label": "Sanza", "value": "C53724"},
              {"label": "Sapri", "value": "C53725"},
              {"label": "Sarno", "value": "C53726"},
              {"label": "Sassano", "value": "C53727"},
              {"label": "Sassinoro", "value": "C53728"},
              {"label": "Saviano", "value": "C53729"},
              {"label": "Savignano Irpino", "value": "C53730"},
              {"label": "Scafati", "value": "C53731"},
              {"label": "Scala", "value": "C53732"},
              {"label": "Scalo Romagnano al Monte", "value": "C53733"},
              {"label": "Scampia", "value": "C53734"},
              {"label": "Scampitella", "value": "C53735"},
              {"label": "Scario", "value": "C53736"},
              {"label": "Scisciano", "value": "C53737"},
              {"label": "Secondigliano", "value": "C53738"},
              {"label": "Senerchia", "value": "C53739"},
              {"label": "Serino", "value": "C53740"},
              {"label": "Serramezzana", "value": "C53741"},
              {"label": "Serrara Fontana", "value": "C53742"},
              {"label": "Serre", "value": "C53743"},
              {"label": "Sessa Aurunca", "value": "C53744"},
              {"label": "Sessa Cilento", "value": "C53745"},
              {"label": "Siano", "value": "C53746"},
              {"label": "Sicignano degli Alburni", "value": "C53747"},
              {"label": "Sirignano", "value": "C53748"},
              {"label": "Soccavo", "value": "C53749"},
              {"label": "Solofra", "value": "C53750"},
              {"label": "Solopaca", "value": "C53751"},
              {"label": "Somma Vesuviana", "value": "C53752"},
              {"label": "Sorbo Serpico", "value": "C53753"},
              {"label": "Sorrento", "value": "C53754"},
              {"label": "Sparanise", "value": "C53755"},
              {"label": "Spartimento", "value": "C53756"},
              {"label": "Sperone", "value": "C53757"},
              {"label": "Starza Vecchia", "value": "C53758"},
              {"label": "Stella", "value": "C53759"},
              {"label": "Stella Cilento", "value": "C53760"},
              {"label": "Stio", "value": "C53761"},
              {"label": "Striano", "value": "C53762"},
              {"label": "Sturno", "value": "C53763"},
              {"label": "Succivo", "value": "C53764"},
              {"label": "Summonte", "value": "C53765"},
              {"label": "Taurano", "value": "C53766"},
              {"label": "Taurasi", "value": "C53767"},
              {"label": "Teano", "value": "C53768"},
              {"label": "Teggiano-Macchiaroli", "value": "C53769"},
              {"label": "Telese", "value": "C53770"},
              {"label": "Teora", "value": "C53771"},
              {"label": "Terzigno", "value": "C53772"},
              {"label": "Teverola", "value": "C53773"},
              {"label": "Tocco Caudio", "value": "C53774"},
              {"label": "Tora", "value": "C53775"},
              {"label": "Torchiara", "value": "C53776"},
              {"label": "Torchiati", "value": "C53777"},
              {"label": "Torella dei Lombardi", "value": "C53778"},
              {"label": "Torelli-Torrette", "value": "C53779"},
              {"label": "Torraca", "value": "C53780"},
              {"label": "Torre Annunziata", "value": "C53781"},
              {"label": "Torre Caracciolo", "value": "C53782"},
              {"label": "Torre del Greco", "value": "C53783"},
              {"label": "Torre le Nocelle", "value": "C53784"},
              {"label": "Torre Orsaia", "value": "C53785"},
              {"label": "Torrecuso", "value": "C53786"},
              {"label": "Torretta-Scalzapecora", "value": "C53787"},
              {"label": "Torrioni", "value": "C53788"},
              {"label": "Tortorella", "value": "C53789"},
              {"label": "Tramonti", "value": "C53790"},
              {"label": "Trecase", "value": "C53791"},
              {"label": "Trentinara", "value": "C53792"},
              {"label": "Trentola-Ducenta", "value": "C53793"},
              {"label": "Trescine", "value": "C53794"},
              {"label": "Trevico", "value": "C53795"},
              {"label": "Tufino", "value": "C53796"},
              {"label": "Tufo", "value": "C53797"},
              {"label": "Vairano-Patenora", "value": "C53798"},
              {"label": "Vallata", "value": "C53799"},
              {"label": "Valle Agricola", "value": "C53800"},
              {"label": "Valle dellAngelo", "value": "C53801"},
              {"label": "Valle di Maddaloni", "value": "C53802"},
              {"label": "Vallesaccarda", "value": "C53803"},
              {"label": "Vallo della Lucania", "value": "C53804"},
              {"label": "Valva", "value": "C53805"},
              {"label": "Venticano", "value": "C53806"},
              {"label": "Vibonati", "value": "C53807"},
              {"label": "Vicaria", "value": "C53808"},
              {"label": "Vico Equense", "value": "C53809"},
              {"label": "Vietri sul Mare", "value": "C53810"},
              {"label": "Villa di Briano", "value": "C53811"},
              {"label": "Villa Literno", "value": "C53812"},
              {"label": "Villamaina", "value": "C53813"},
              {"label": "Villammare", "value": "C53814"},
              {"label": "Villanova del Battista", "value": "C53815"},
              {"label": "Villaricca", "value": "C53816"},
              {"label": "Visciano", "value": "C53817"},
              {"label": "Vitulano", "value": "C53818"},
              {"label": "Vitulazio", "value": "C53819"},
              {"label": "Volla", "value": "C53820"},
              {"label": "Volturara Irpina", "value": "C53821"},
              {"label": "Vomero", "value": "C53822"},
              {"label": "Zona Industriale", "value": "C53823"},
              {"label": "Zungoli", "value": "C53824"},
              {"label": "Zuni", "value": "C53825"}],
 "Campeche": [{"label": "Abelardo L. Rodriguez", "value": "C66290"},
              {"label": "Alfredo V. Bonfil", "value": "C66291"},
              {"label": "Altamira de Zinaparo", "value": "C66292"},
              {"label": "Atasta", "value": "C66293"},
              {"label": "Bacabchen", "value": "C66294"},
              {"label": "Becal", "value": "C66295"},
              {"label": "Benito Juarez Uno", "value": "C66296"},
              {"label": "Bolonchen de Rejon", "value": "C66297"},
              {"label": "Calakmul", "value": "C66298"},
              {"label": "Calkini", "value": "C66299"},
              {"label": "Campeche", "value": "C66300"},
              {"label": "Candelaria", "value": "C66301"},
              {"label": "Carmen", "value": "C66302"},
              {"label": "Carrillo Puerto", "value": "C66303"},
              {"label": "Castamay", "value": "C66304"},
              {"label": "Champoton", "value": "C66305"},
              {"label": "Chicbul", "value": "C66306"},
              {"label": "China", "value": "C66307"},
              {"label": "Chunchintok", "value": "C66308"},
              {"label": "Ciudad del Carmen", "value": "C66309"},
              {"label": "Colonia Emiliano Zapata", "value": "C66310"},
              {"label": "Constitucion", "value": "C66311"},
              {"label": "Cumpich", "value": "C66312"},
              {"label": "Division del Norte", "value": "C66313"},
              {"label": "Don Samuel", "value": "C66314"},
              {"label": "Dzibalchen", "value": "C66315"},
              {"label": "Dzitbalche", "value": "C66316"},
              {"label": "El Aguacatal", "value": "C66317"},
              {"label": "El Naranjo", "value": "C66318"},
              {"label": "Escarcega", "value": "C66319"},
              {"label": "Hampolol", "value": "C66320"},
              {"label": "Haro", "value": "C66321"},
              {"label": "Hecelchakan", "value": "C66322"},
              {"label": "Hool", "value": "C66323"},
              {"label": "Hopelchen", "value": "C66324"},
              {"label": "Isla Aguada", "value": "C66325"},
              {"label": "Iturbide", "value": "C66326"},
              {"label": "La Joya", "value": "C66327"},
              {"label": "La Libertad", "value": "C66328"},
              {"label": "Lerma", "value": "C66329"},
              {"label": "Licenciado Gustavo Diaz Ordaz (18 de Marzo)",
               "value": "C66330"},
              {"label": "Los Laureles", "value": "C66331"},
              {"label": "Mamantel", "value": "C66332"},
              {"label": "Matamoros", "value": "C66333"},
              {"label": "Maya Tecun I", "value": "C66334"},
              {"label": "Nuevo Progreso", "value": "C66335"},
              {"label": "Nunkini", "value": "C66336"},
              {"label": "Palizada", "value": "C66337"},
              {"label": "Pich", "value": "C66338"},
              {"label": "Pocboc", "value": "C66339"},
              {"label": "Pomuch", "value": "C66340"},
              {"label": "Sabancuy", "value": "C66341"},
              {"label": "San Antonio Cardenas", "value": "C66342"},
              {"label": "San Antonio Sahcabchen", "value": "C66343"},
              {"label": "San Francisco Koben", "value": "C66344"},
              {"label": "San Pablo Pixtun", "value": "C66345"},
              {"label": "Santa Cruz", "value": "C66346"},
              {"label": "Santa Cruz Ex-Hacienda", "value": "C66347"},
              {"label": "Santa Cruz Pueblo", "value": "C66348"},
              {"label": "Santo Domingo Keste", "value": "C66349"},
              {"label": "Seybaplaya", "value": "C66350"},
              {"label": "Sihochac", "value": "C66351"},
              {"label": "Suc-Tuc", "value": "C66352"},
              {"label": "Tankuche", "value": "C66353"},
              {"label": "Tenabo", "value": "C66354"},
              {"label": "Tepakan", "value": "C66355"},
              {"label": "Tikinmul", "value": "C66356"},
              {"label": "Ukum", "value": "C66357"},
              {"label": "Villa Madero", "value": "C66358"},
              {"label": "Xbacab", "value": "C66359"},
              {"label": "Xmaben", "value": "C66360"},
              {"label": "Xpujil", "value": "C66361"},
              {"label": "Zoh-Laguna", "value": "C66362"}],
 "Can Tho": [{"label": "Can Tho", "value": "C147332"},
             {"label": "Co Do", "value": "C147333"},
             {"label": "Thoi Lai", "value": "C147334"},
             {"label": "Vinh Thanh", "value": "C147335"}],
 "Canakkale": [{"label": "Ayvacik", "value": "C120139"},
               {"label": "Bayramic Ilcesi", "value": "C120140"},
               {"label": "Behram", "value": "C120141"},
               {"label": "Biga", "value": "C120142"},
               {"label": "Bozcaada", "value": "C120143"},
               {"label": "Can", "value": "C120144"},
               {"label": "Canakkale", "value": "C120145"},
               {"label": "Eceabat", "value": "C120146"},
               {"label": "Ezine", "value": "C120147"},
               {"label": "Gelibolu", "value": "C120148"},
               {"label": "Geyikli", "value": "C120149"},
               {"label": "Gokceada", "value": "C120150"},
               {"label": "Hamdibey", "value": "C120151"},
               {"label": "Kalkim", "value": "C120152"},
               {"label": "Lapseki", "value": "C120153"},
               {"label": "Lapseki Ilcesi", "value": "C120154"},
               {"label": "Merkez", "value": "C120155"},
               {"label": "Yenice", "value": "C120156"}],
 "Canar": [{"label": "Azogues", "value": "C24100"},
           {"label": "Canar", "value": "C24101"},
           {"label": "La Troncal", "value": "C24102"}],
 "Canaries": [{"label": "Anse Cochon", "value": "C105760"},
              {"label": "Anse Galet", "value": "C105761"},
              {"label": "Anse La Verdue", "value": "C105762"},
              {"label": "Belvedere", "value": "C105763"},
              {"label": "Chalon", "value": "C105764"},
              {"label": "Riverside Road", "value": "C105765"},
              {"label": "Theodrine", "value": "C105766"},
              {"label": "Village", "value": "C105767"}],
 "Canary Islands": [{"label": "Adeje", "value": "C110130"},
                    {"label": "Agaete", "value": "C110131"},
                    {"label": "Aguimes", "value": "C110132"},
                    {"label": "Agulo", "value": "C110133"},
                    {"label": "Alajero", "value": "C110134"},
                    {"label": "Antigua", "value": "C110135"},
                    {"label": "Arafo", "value": "C110136"},
                    {"label": "Arona", "value": "C110137"},
                    {"label": "Arrecife", "value": "C110138"},
                    {"label": "Artenara", "value": "C110139"},
                    {"label": "Arucas", "value": "C110140"},
                    {"label": "Barlovento", "value": "C110141"},
                    {"label": "Brena Alta", "value": "C110142"},
                    {"label": "Brena Baja", "value": "C110143"},
                    {"label": "Buenavista del Norte", "value": "C110144"},
                    {"label": "Candelaria", "value": "C110145"},
                    {"label": "Carrizal", "value": "C110146"},
                    {"label": "Corralejo", "value": "C110147"},
                    {"label": "Costa Calma", "value": "C110148"},
                    {"label": "Costa Teguise", "value": "C110149"},
                    {"label": "Cruce de Arinaga", "value": "C110150"},
                    {"label": "El Cotillo", "value": "C110151"},
                    {"label": "El Paso", "value": "C110152"},
                    {"label": "Fasnia", "value": "C110153"},
                    {"label": "Firgas", "value": "C110154"},
                    {"label": "Frontera", "value": "C110155"},
                    {"label": "Fuencaliente de la Palma", "value": "C110156"},
                    {"label": "Galdar", "value": "C110157"},
                    {"label": "Garachico", "value": "C110158"},
                    {"label": "Garafia", "value": "C110159"},
                    {"label": "Granadilla de Abona", "value": "C110160"},
                    {"label": "Guia", "value": "C110161"},
                    {"label": "Guia de Isora", "value": "C110162"},
                    {"label": "Guimar", "value": "C110163"},
                    {"label": "Haria", "value": "C110164"},
                    {"label": "Hermigua", "value": "C110165"},
                    {"label": "Icod de los Vinos", "value": "C110166"},
                    {"label": "Ingenio", "value": "C110167"},
                    {"label": "La Guancha", "value": "C110168"},
                    {"label": "La Laguna", "value": "C110169"},
                    {"label": "La Matanza de Acentejo", "value": "C110170"},
                    {"label": "La Oliva", "value": "C110171"},
                    {"label": "La Orotava", "value": "C110172"},
                    {"label": "La Victoria de Acentejo", "value": "C110173"},
                    {"label": "Las Palmas de Gran Canaria", "value": "C110174"},
                    {"label": "Las Rosas", "value": "C110175"},
                    {"label": "Lomo de Arico", "value": "C110176"},
                    {"label": "Los Gigantes", "value": "C110177"},
                    {"label": "Los Llanos de Aridane", "value": "C110178"},
                    {"label": "Los Realejos", "value": "C110179"},
                    {"label": "Los Silos", "value": "C110180"},
                    {"label": "Macher", "value": "C110181"},
                    {"label": "Maspalomas", "value": "C110182"},
                    {"label": "Mazo", "value": "C110183"},
                    {"label": "Mogan", "value": "C110184"},
                    {"label": "Moya", "value": "C110185"},
                    {"label": "Nazaret", "value": "C110186"},
                    {"label": "Pajara", "value": "C110187"},
                    {"label": "Playa Blanca", "value": "C110188"},
                    {"label": "Playa de las Americas", "value": "C110189"},
                    {"label": "Playa del Ingles", "value": "C110190"},
                    {"label": "Provincia de Las Palmas", "value": "C110191"},
                    {"label": "Provincia de Santa Cruz de Tenerife",
                     "value": "C110192"},
                    {"label": "Puerto de la Cruz", "value": "C110193"},
                    {"label": "Puerto del Carmen", "value": "C110194"},
                    {"label": "Puerto del Rosario", "value": "C110195"},
                    {"label": "Puerto Rico", "value": "C110196"},
                    {"label": "Punta de Mujeres", "value": "C110197"},
                    {"label": "Puntagorda", "value": "C110198"},
                    {"label": "Puntallana", "value": "C110199"},
                    {"label": "Realejo Alto", "value": "C110200"},
                    {"label": "San Bartolome", "value": "C110201"},
                    {"label": "San Bartolome de Tirajana", "value": "C110202"},
                    {"label": "San Isidro", "value": "C110203"},
                    {"label": "San Juan de la Rambla", "value": "C110204"},
                    {"label": "San Miguel De Abona", "value": "C110205"},
                    {"label": "San Nicolas", "value": "C110206"},
                    {"label": "San Sebastian de la Gomera", "value": "C110207"},
                    {"label": "Santa Brigida", "value": "C110208"},
                    {"label": "Santa Cruz de la Palma", "value": "C110209"},
                    {"label": "Santa Cruz de Tenerife", "value": "C110210"},
                    {"label": "Santa Lucia", "value": "C110211"},
                    {"label": "Santa Ursula", "value": "C110212"},
                    {"label": "Santiago del Teide", "value": "C110213"},
                    {"label": "Sauzal", "value": "C110214"},
                    {"label": "Tacoronte", "value": "C110215"},
                    {"label": "Tanque", "value": "C110216"},
                    {"label": "Tazacorte", "value": "C110217"},
                    {"label": "Tegueste", "value": "C110218"},
                    {"label": "Teguise", "value": "C110219"},
                    {"label": "Tejeda", "value": "C110220"},
                    {"label": "Telde", "value": "C110221"},
                    {"label": "Teror", "value": "C110222"},
                    {"label": "Tias", "value": "C110223"},
                    {"label": "Tijarafe", "value": "C110224"},
                    {"label": "Tinajo", "value": "C110225"},
                    {"label": "Tuineje", "value": "C110226"},
                    {"label": "Vallehermosa", "value": "C110227"},
                    {"label": "Valleseco", "value": "C110228"},
                    {"label": "Valsequillo de Gran Canaria",
                     "value": "C110229"},
                    {"label": "Valverde", "value": "C110230"},
                    {"label": "Vecindario", "value": "C110231"},
                    {"label": "Vega de San Mateo", "value": "C110232"},
                    {"label": "Vilaflor", "value": "C110233"},
                    {"label": "Yaiza", "value": "C110234"}],
 "Canelones Department": [{"label": "Aguas Corrientes", "value": "C146831"},
                          {"label": "Atlantida", "value": "C146832"},
                          {"label": "Barra de Carrasco", "value": "C146833"},
                          {"label": "Barros Blancos", "value": "C146834"},
                          {"label": "Canelones", "value": "C146835"},
                          {"label": "Colonia Nicolich", "value": "C146836"},
                          {"label": "Empalme Olmos", "value": "C146837"},
                          {"label": "Joaquin Suarez", "value": "C146838"},
                          {"label": "Juanico", "value": "C146839"},
                          {"label": "La Floresta", "value": "C146840"},
                          {"label": "La Paz", "value": "C146841"},
                          {"label": "Las Piedras", "value": "C146842"},
                          {"label": "Las Toscas", "value": "C146843"},
                          {"label": "Los Cerrillos", "value": "C146844"},
                          {"label": "Migues", "value": "C146845"},
                          {"label": "Montes", "value": "C146846"},
                          {"label": "Pando", "value": "C146847"},
                          {"label": "Paso de Carrasco", "value": "C146848"},
                          {"label": "Progreso", "value": "C146849"},
                          {"label": "San Antonio", "value": "C146850"},
                          {"label": "San Bautista", "value": "C146851"},
                          {"label": "San Jacinto", "value": "C146852"},
                          {"label": "San Ramon", "value": "C146853"},
                          {"label": "Santa Lucia", "value": "C146854"},
                          {"label": "Santa Rosa", "value": "C146855"},
                          {"label": "Sauce", "value": "C146856"},
                          {"label": "Soca", "value": "C146857"},
                          {"label": "Tala", "value": "C146858"},
                          {"label": "Toledo", "value": "C146859"}],
 "Canillo": [{"label": "Canillo", "value": "C517"},
             {"label": "El Tarter", "value": "C518"}],
 "Canindeyu": [{"label": "Colonia Catuete", "value": "C80523"},
               {"label": "Corpus Christi", "value": "C80524"},
               {"label": "La Paloma", "value": "C80525"},
               {"label": "Nueva Esperanza", "value": "C80526"},
               {"label": "Salto del Guaira", "value": "C80527"},
               {"label": "San Isidro de Curuguaty", "value": "C80528"},
               {"label": "Ygatimi", "value": "C80529"}],
 "Cankiri": [{"label": "Atkaracalar", "value": "C120157"},
             {"label": "Bayramoren", "value": "C120158"},
             {"label": "Cerkes", "value": "C120159"},
             {"label": "Eldivan", "value": "C120160"},
             {"label": "Ilgaz", "value": "C120161"},
             {"label": "Khanjarah", "value": "C120162"},
             {"label": "Kizilirmak", "value": "C120163"},
             {"label": "Korgun", "value": "C120164"},
             {"label": "Kursunlu", "value": "C120165"},
             {"label": "Orta", "value": "C120166"},
             {"label": "Sabanozu", "value": "C120167"},
             {"label": "Yaprakli", "value": "C120168"}],
 "Cankova Municipality": [{"label": "Cankova", "value": "C107593"}],
 "Cankuzo Province": [{"label": "Cankuzo", "value": "C16445"}],
 "Cantabria": [{"label": "Ampuero", "value": "C110235"},
               {"label": "Arenas de Iguna", "value": "C110236"},
               {"label": "Argonos", "value": "C110237"},
               {"label": "Arnuero", "value": "C110238"},
               {"label": "Arredondo", "value": "C110239"},
               {"label": "Barcena de Cicero", "value": "C110240"},
               {"label": "Barcena de Pie de Concha", "value": "C110241"},
               {"label": "Bareyo", "value": "C110242"},
               {"label": "Cabezon de la Sal", "value": "C110243"},
               {"label": "Cabezon de Liebana", "value": "C110244"},
               {"label": "Camargo", "value": "C110245"},
               {"label": "Cartes", "value": "C110246"},
               {"label": "Castro-Urdiales", "value": "C110247"},
               {"label": "Colindres", "value": "C110248"},
               {"label": "Comillas", "value": "C110249"},
               {"label": "El Astillero", "value": "C110250"},
               {"label": "Escalante", "value": "C110251"},
               {"label": "Hazas de Cesto", "value": "C110252"},
               {"label": "Laredo", "value": "C110253"},
               {"label": "Limpias", "value": "C110254"},
               {"label": "Los Corrales de Buelna", "value": "C110255"},
               {"label": "Luzmela", "value": "C110256"},
               {"label": "Mataporquera", "value": "C110257"},
               {"label": "Miengo", "value": "C110258"},
               {"label": "Molledo", "value": "C110259"},
               {"label": "Noja", "value": "C110260"},
               {"label": "Penagos", "value": "C110261"},
               {"label": "Pesquera", "value": "C110262"},
               {"label": "Polanco", "value": "C110263"},
               {"label": "Potes", "value": "C110264"},
               {"label": "Provincia de Cantabria", "value": "C110265"},
               {"label": "Puente Viesgo", "value": "C110266"},
               {"label": "Ramales de la Victoria", "value": "C110267"},
               {"label": "Rasines", "value": "C110268"},
               {"label": "Reinosa", "value": "C110269"},
               {"label": "Reocin", "value": "C110270"},
               {"label": "Ruente", "value": "C110271"},
               {"label": "San Martin de Elines", "value": "C110272"},
               {"label": "San Miguel de Aguayo", "value": "C110273"},
               {"label": "San Miguel de Meruelo", "value": "C110274"},
               {"label": "San Pedro del Romeral", "value": "C110275"},
               {"label": "San Vicente de la Barquera", "value": "C110276"},
               {"label": "Santa Cruz de Bezana", "value": "C110277"},
               {"label": "Santa Maria de Cayon", "value": "C110278"},
               {"label": "Santander", "value": "C110279"},
               {"label": "Santillana", "value": "C110280"},
               {"label": "Santiurde de Reinosa", "value": "C110281"},
               {"label": "Santiurde de Toranzo", "value": "C110282"},
               {"label": "Santona", "value": "C110283"},
               {"label": "Saro", "value": "C110284"},
               {"label": "Selaya", "value": "C110285"},
               {"label": "Solorzano", "value": "C110286"},
               {"label": "Suances", "value": "C110287"},
               {"label": "Torrelavega", "value": "C110288"},
               {"label": "Vega de Pas", "value": "C110289"},
               {"label": "Villacarriedo", "value": "C110290"},
               {"label": "Villaescusa", "value": "C110291"},
               {"label": "Villafufre", "value": "C110292"}],
 "Cantemir District": [{"label": "Cantemir", "value": "C75493"},
                       {"label": "Iargara", "value": "C75494"},
                       {"label": "Visniovca", "value": "C75495"}],
 "Canterbury Region": [{"label": "Amberley", "value": "C77760"},
                       {"label": "Ashburton", "value": "C77761"},
                       {"label": "Ashburton District", "value": "C77762"},
                       {"label": "Burnham", "value": "C77763"},
                       {"label": "Christchurch", "value": "C77764"},
                       {"label": "Christchurch City", "value": "C77765"},
                       {"label": "Darfield", "value": "C77766"},
                       {"label": "Geraldine", "value": "C77767"},
                       {"label": "Kaiapoi", "value": "C77768"},
                       {"label": "Leeston", "value": "C77769"},
                       {"label": "Lincoln", "value": "C77770"},
                       {"label": "Mackenzie District", "value": "C77771"},
                       {"label": "Methven", "value": "C77772"},
                       {"label": "Oxford", "value": "C77773"},
                       {"label": "Pleasant Point", "value": "C77774"},
                       {"label": "Prebbleton", "value": "C77775"},
                       {"label": "Rakaia", "value": "C77776"},
                       {"label": "Rolleston", "value": "C77777"},
                       {"label": "Selwyn District", "value": "C77778"},
                       {"label": "Timaru", "value": "C77779"},
                       {"label": "Timaru District", "value": "C77780"},
                       {"label": "Tinwald", "value": "C77781"},
                       {"label": "Waimakariri District", "value": "C77782"},
                       {"label": "Woodend", "value": "C77783"}],
 "Canton of Capellen": [{"label": "Bascharage", "value": "C65211"},
                        {"label": "Bridel", "value": "C65212"},
                        {"label": "Capellen", "value": "C65213"},
                        {"label": "Clemency", "value": "C65214"},
                        {"label": "Dippach", "value": "C65215"},
                        {"label": "Eischen", "value": "C65216"},
                        {"label": "Garnich", "value": "C65217"},
                        {"label": "Hautcharage", "value": "C65218"},
                        {"label": "Hobscheid", "value": "C65219"},
                        {"label": "Kaerjeng", "value": "C65220"},
                        {"label": "Kehlen", "value": "C65221"},
                        {"label": "Koerich", "value": "C65222"},
                        {"label": "Kopstal", "value": "C65223"},
                        {"label": "Mamer", "value": "C65224"},
                        {"label": "Schouweiler", "value": "C65225"},
                        {"label": "Septfontaines", "value": "C65226"},
                        {"label": "Steinfort", "value": "C65227"}],
 "Canton of Clervaux": [{"label": "Clervaux", "value": "C65228"},
                        {"label": "Hosingen", "value": "C65229"},
                        {"label": "Parc Hosingen", "value": "C65230"},
                        {"label": "Troisvierges", "value": "C65231"},
                        {"label": "Weiswampach", "value": "C65232"},
                        {"label": "Wincrange", "value": "C65233"}],
 "Canton of Diekirch": [{"label": "Bettendorf", "value": "C65234"},
                        {"label": "Bourscheid", "value": "C65235"},
                        {"label": "Commune de la Vallee de lErnz",
                         "value": "C65236"},
                        {"label": "Diekirch", "value": "C65237"},
                        {"label": "Erpeldange", "value": "C65238"},
                        {"label": "Ettelbruck", "value": "C65239"},
                        {"label": "Feulen", "value": "C65240"},
                        {"label": "Medernach", "value": "C65241"},
                        {"label": "Mertzig", "value": "C65242"},
                        {"label": "Niederfeulen", "value": "C65243"},
                        {"label": "Reisdorf", "value": "C65244"},
                        {"label": "Schieren", "value": "C65245"},
                        {"label": "Warken", "value": "C65246"}],
 "Canton of Echternach": [{"label": "Beaufort", "value": "C65247"},
                          {"label": "Bech", "value": "C65248"},
                          {"label": "Berdorf", "value": "C65249"},
                          {"label": "Consdorf", "value": "C65250"},
                          {"label": "Echternach", "value": "C65251"},
                          {"label": "Mompach", "value": "C65252"},
                          {"label": "Rosport", "value": "C65253"},
                          {"label": "Waldbillig", "value": "C65254"}],
 "Canton of Esch-sur-Alzette": [{"label": "Aspelt", "value": "C65255"},
                                {"label": "Belvaux", "value": "C65256"},
                                {"label": "Bergem", "value": "C65257"},
                                {"label": "Bettembourg", "value": "C65258"},
                                {"label": "Crauthem", "value": "C65259"},
                                {"label": "Differdange", "value": "C65260"},
                                {"label": "Dudelange", "value": "C65261"},
                                {"label": "Esch-sur-Alzette",
                                 "value": "C65262"},
                                {"label": "Frisange", "value": "C65263"},
                                {"label": "Kayl", "value": "C65264"},
                                {"label": "Lamadelaine", "value": "C65265"},
                                {"label": "Leudelange", "value": "C65266"},
                                {"label": "Mondercange", "value": "C65267"},
                                {"label": "Niedercorn", "value": "C65268"},
                                {"label": "Obercorn", "value": "C65269"},
                                {"label": "Petange", "value": "C65270"},
                                {"label": "Pontpierre", "value": "C65271"},
                                {"label": "Reckange-sur-Mess",
                                 "value": "C65272"},
                                {"label": "Rodange", "value": "C65273"},
                                {"label": "Roeser", "value": "C65274"},
                                {"label": "Rumelange", "value": "C65275"},
                                {"label": "Sanem", "value": "C65276"},
                                {"label": "Schifflange", "value": "C65277"},
                                {"label": "Soleuvre", "value": "C65278"},
                                {"label": "Tetange", "value": "C65279"}],
 "Canton of Grevenmacher": [{"label": "Betzdorf", "value": "C65280"},
                            {"label": "Biwer", "value": "C65281"},
                            {"label": "Flaxweiler", "value": "C65282"},
                            {"label": "Gonderange", "value": "C65283"},
                            {"label": "Grevenmacher", "value": "C65284"},
                            {"label": "Junglinster", "value": "C65285"},
                            {"label": "Manternach", "value": "C65286"},
                            {"label": "Mertert", "value": "C65287"},
                            {"label": "Wasserbillig", "value": "C65288"},
                            {"label": "Wormeldange", "value": "C65289"}],
 "Canton of Luxembourg": [{"label": "Alzingen", "value": "C65290"},
                          {"label": "Bereldange", "value": "C65291"},
                          {"label": "Bertrange", "value": "C65292"},
                          {"label": "Contern", "value": "C65293"},
                          {"label": "Fentange", "value": "C65294"},
                          {"label": "Heisdorf", "value": "C65295"},
                          {"label": "Helmsange", "value": "C65296"},
                          {"label": "Hesperange", "value": "C65297"},
                          {"label": "Itzig", "value": "C65298"},
                          {"label": "Kirchberg", "value": "C65299"},
                          {"label": "Luxembourg", "value": "C65300"},
                          {"label": "Moutfort", "value": "C65301"},
                          {"label": "Mullendorf", "value": "C65302"},
                          {"label": "Niederanven", "value": "C65303"},
                          {"label": "Olm", "value": "C65304"},
                          {"label": "Sandweiler", "value": "C65305"},
                          {"label": "Schrassig", "value": "C65306"},
                          {"label": "Schuttrange", "value": "C65307"},
                          {"label": "Steinsel", "value": "C65308"},
                          {"label": "Strassen", "value": "C65309"},
                          {"label": "Walferdange", "value": "C65310"},
                          {"label": "Weiler-la-Tour", "value": "C65311"}],
 "Canton of Mersch": [{"label": "Bissen", "value": "C65312"},
                      {"label": "Boevange-sur-Attert", "value": "C65313"},
                      {"label": "Colmar", "value": "C65314"},
                      {"label": "Fischbach", "value": "C65315"},
                      {"label": "Heffingen", "value": "C65316"},
                      {"label": "Larochette", "value": "C65317"},
                      {"label": "Lintgen", "value": "C65318"},
                      {"label": "Lorentzweiler", "value": "C65319"},
                      {"label": "Mersch", "value": "C65320"},
                      {"label": "Nommern", "value": "C65321"},
                      {"label": "Rollingen", "value": "C65322"},
                      {"label": "Tuntange", "value": "C65323"}],
 "Canton of Redange": [{"label": "Beckerich", "value": "C65324"},
                       {"label": "Bettborn", "value": "C65325"},
                       {"label": "Commune de Preizerdaul", "value": "C65326"},
                       {"label": "Ell", "value": "C65327"},
                       {"label": "Grosbous", "value": "C65328"},
                       {"label": "Rambrouch", "value": "C65329"},
                       {"label": "Redange-sur-Attert", "value": "C65330"},
                       {"label": "Saeul", "value": "C65331"},
                       {"label": "Useldange", "value": "C65332"},
                       {"label": "Vichten", "value": "C65333"},
                       {"label": "Wahl", "value": "C65334"}],
 "Canton of Remich": [{"label": "Bous", "value": "C65335"},
                      {"label": "Dalheim", "value": "C65336"},
                      {"label": "Lenningen", "value": "C65337"},
                      {"label": "Mondorf-les-Bains", "value": "C65338"},
                      {"label": "Remich", "value": "C65339"},
                      {"label": "Schengen", "value": "C65340"},
                      {"label": "Stadtbredimus", "value": "C65341"},
                      {"label": "Waldbredimus", "value": "C65342"}],
 "Canton of Vianden": [{"label": "Putscheid", "value": "C65343"},
                       {"label": "Tandel", "value": "C65344"},
                       {"label": "Vianden", "value": "C65345"}],
 "Canton of Wiltz": [{"label": "Bavigne", "value": "C65346"},
                     {"label": "Boulaide", "value": "C65347"},
                     {"label": "Esch-sur-Sure", "value": "C65348"},
                     {"label": "Goesdorf", "value": "C65349"},
                     {"label": "Kiischpelt", "value": "C65350"},
                     {"label": "Lac de la Haute-Sure", "value": "C65351"},
                     {"label": "Wiltz", "value": "C65352"},
                     {"label": "Wilwerwiltz", "value": "C65353"},
                     {"label": "Winseler", "value": "C65354"}],
 "Cao Bang": [{"label": "Cao Bang", "value": "C147336"},
              {"label": "Huyen Bao Lac", "value": "C147337"},
              {"label": "Huyen Bao Lam", "value": "C147338"},
              {"label": "Huyen Ha Lang", "value": "C147339"},
              {"label": "Huyen Ha Quang", "value": "C147340"},
              {"label": "Huyen Nguyen Binh", "value": "C147341"},
              {"label": "Huyen Phuc Hoa", "value": "C147342"},
              {"label": "Huyen Thach An", "value": "C147343"},
              {"label": "Huyen Thong Nong", "value": "C147344"},
              {"label": "Huyen Tra Linh", "value": "C147345"},
              {"label": "Huyen Trung Khanh", "value": "C147346"}],
 "Capital Governorate": [{"label": "Jidd Hafs", "value": "C8545"},
                         {"label": "Manama", "value": "C8546"},
                         {"label": "Sitrah", "value": "C8547"},
                         {"label": "Ad Dasmah", "value": "C64695"},
                         {"label": "Ar Rabiyah", "value": "C64696"},
                         {"label": "Ash Shamiyah", "value": "C64697"},
                         {"label": "Az Zawr", "value": "C64698"},
                         {"label": "Kuwait City", "value": "C64699"}],
 "Capital Region": [{"label": "alftanes", "value": "C44822"},
                    {"label": "Gardabaer", "value": "C44823"},
                    {"label": "Hafnarfjordur", "value": "C44824"},
                    {"label": "Kjosarhreppur", "value": "C44825"},
                    {"label": "Kopavogur", "value": "C44826"},
                    {"label": "Mosfellsbaer", "value": "C44827"},
                    {"label": "Mosfellsbaer", "value": "C44828"},
                    {"label": "Reykjavik", "value": "C44829"},
                    {"label": "Seltjarnarnes", "value": "C44830"}],
 "Capital Region of Denmark": [{"label": "Akirkeby", "value": "C23377"},
                               {"label": "Albertslund", "value": "C23378"},
                               {"label": "Albertslund Kommune",
                                "value": "C23379"},
                               {"label": "Allerod Kommune", "value": "C23380"},
                               {"label": "Ballerup", "value": "C23381"},
                               {"label": "Ballerup Kommune", "value": "C23382"},
                               {"label": "Birkerod", "value": "C23383"},
                               {"label": "Blovstrod", "value": "C23384"},
                               {"label": "Bornholm Kommune", "value": "C23385"},
                               {"label": "Brondby Kommune", "value": "C23386"},
                               {"label": "Charlottenlund", "value": "C23387"},
                               {"label": "Christianshavn", "value": "C23388"},
                               {"label": "Christianso", "value": "C23389"},
                               {"label": "Copenhagen", "value": "C23390"},
                               {"label": "Dragor", "value": "C23391"},
                               {"label": "Dragor Kommune", "value": "C23392"},
                               {"label": "Egedal Kommune", "value": "C23393"},
                               {"label": "Espergaerde", "value": "C23394"},
                               {"label": "Farum", "value": "C23395"},
                               {"label": "Flong", "value": "C23396"},
                               {"label": "Fredensborg", "value": "C23397"},
                               {"label": "Fredensborg Kommune",
                                "value": "C23398"},
                               {"label": "Frederiksberg", "value": "C23399"},
                               {"label": "Frederiksberg Kommune",
                                "value": "C23400"},
                               {"label": "Frederikssund", "value": "C23401"},
                               {"label": "Frederikssund Kommune",
                                "value": "C23402"},
                               {"label": "Frederiksvaerk", "value": "C23403"},
                               {"label": "Fureso Kommune", "value": "C23404"},
                               {"label": "Ganlose", "value": "C23405"},
                               {"label": "Gentofte Kommune", "value": "C23406"},
                               {"label": "Gilleleje", "value": "C23407"},
                               {"label": "Gladsaxe Municipality",
                                "value": "C23408"},
                               {"label": "Glostrup", "value": "C23409"},
                               {"label": "Glostrup Kommune", "value": "C23410"},
                               {"label": "Graested", "value": "C23411"},
                               {"label": "Gribskov Kommune", "value": "C23412"},
                               {"label": "Halsnaes Kommune", "value": "C23413"},
                               {"label": "Hellebaek", "value": "C23414"},
                               {"label": "Helsinge", "value": "C23415"},
                               {"label": "Helsingor", "value": "C23416"},
                               {"label": "Helsingor Kommune",
                                "value": "C23417"},
                               {"label": "Herlev Kommune", "value": "C23418"},
                               {"label": "Hillerod", "value": "C23419"},
                               {"label": "Hillerod Kommune", "value": "C23420"},
                               {"label": "Hornbaek", "value": "C23421"},
                               {"label": "Hoje-Taastrup Kommune",
                                "value": "C23422"},
                               {"label": "Horsholm", "value": "C23423"},
                               {"label": "Horsholm Kommune", "value": "C23424"},
                               {"label": "Humlebaek", "value": "C23425"},
                               {"label": "Hundested", "value": "C23426"},
                               {"label": "Hvidovre", "value": "C23427"},
                               {"label": "Hvidovre Kommune", "value": "C23428"},
                               {"label": "Ishoj", "value": "C23429"},
                               {"label": "Ishoj Kommune", "value": "C23430"},
                               {"label": "Jaegerspris", "value": "C23431"},
                               {"label": "Kokkedal", "value": "C23432"},
                               {"label": "Kongens Lyngby", "value": "C23433"},
                               {"label": "Kobenhavn", "value": "C23434"},
                               {"label": "Lillerod", "value": "C23435"},
                               {"label": "Liseleje", "value": "C23436"},
                               {"label": "Lyngby-Tarbaek Kommune",
                                "value": "C23437"},
                               {"label": "Lynge", "value": "C23438"},
                               {"label": "Malov", "value": "C23439"},
                               {"label": "Nexo", "value": "C23440"},
                               {"label": "Niva", "value": "C23441"},
                               {"label": "Nodebo", "value": "C23442"},
                               {"label": "Olstykke", "value": "C23443"},
                               {"label": "Rodovre", "value": "C23444"},
                               {"label": "Rodovre Kommune", "value": "C23445"},
                               {"label": "Ronne", "value": "C23446"},
                               {"label": "Rudersdal Kommune",
                                "value": "C23447"},
                               {"label": "Skaevinge", "value": "C23448"},
                               {"label": "Skibby", "value": "C23449"},
                               {"label": "Slangerup", "value": "C23450"},
                               {"label": "Smorumnedre", "value": "C23451"},
                               {"label": "Stavnsholt", "value": "C23452"},
                               {"label": "Stenlose", "value": "C23453"},
                               {"label": "Taastrup", "value": "C23454"},
                               {"label": "Tarnby", "value": "C23455"},
                               {"label": "Tarnby Kommune", "value": "C23456"},
                               {"label": "Trorod", "value": "C23457"},
                               {"label": "Vallensbaek", "value": "C23458"},
                               {"label": "Vallensbaek Kommune",
                                "value": "C23459"},
                               {"label": "Vanlose", "value": "C23460"},
                               {"label": "Vaerlose", "value": "C23461"},
                               {"label": "Vekso", "value": "C23462"}],
 "Caqueta": [{"label": "Albania", "value": "C19900"},
             {"label": "Belen de Los Andaquies", "value": "C19901"},
             {"label": "Cartagena del Chaira", "value": "C19902"},
             {"label": "Curillo", "value": "C19903"},
             {"label": "El Doncello", "value": "C19904"},
             {"label": "El Paujil", "value": "C19905"},
             {"label": "Florencia", "value": "C19906"},
             {"label": "La Montanita", "value": "C19907"},
             {"label": "Milan", "value": "C19908"},
             {"label": "Morelia", "value": "C19909"},
             {"label": "Puerto Rico", "value": "C19910"},
             {"label": "San Jose del Fragua", "value": "C19911"},
             {"label": "San Vicente del Caguan", "value": "C19912"},
             {"label": "Solano", "value": "C19913"},
             {"label": "Solita", "value": "C19914"},
             {"label": "Valparaiso", "value": "C19915"}],
 "Carabobo": [{"label": "Guacara", "value": "C147158"},
              {"label": "Guigue", "value": "C147159"},
              {"label": "Los Guayos", "value": "C147160"},
              {"label": "Mariara", "value": "C147161"},
              {"label": "Moron", "value": "C147162"},
              {"label": "Puerto Cabello", "value": "C147163"},
              {"label": "San Joaquin", "value": "C147164"},
              {"label": "Tacarigua", "value": "C147165"},
              {"label": "Tocuyito", "value": "C147166"},
              {"label": "Valencia", "value": "C147167"}],
 "Caraga": [{"label": "Adlay", "value": "C85016"},
            {"label": "Agay", "value": "C85017"},
            {"label": "Alegria", "value": "C85018"},
            {"label": "Alubijid", "value": "C85019"},
            {"label": "Amaga", "value": "C85020"},
            {"label": "Anticala", "value": "C85021"},
            {"label": "Aras-asan", "value": "C85022"},
            {"label": "Aurora", "value": "C85023"},
            {"label": "Bacolod", "value": "C85024"},
            {"label": "Bacuag", "value": "C85025"},
            {"label": "Bah-Bah", "value": "C85026"},
            {"label": "Balangbalang", "value": "C85027"},
            {"label": "Bancasi", "value": "C85028"},
            {"label": "Bangonay", "value": "C85029"},
            {"label": "Barcelona", "value": "C85030"},
            {"label": "Barobo", "value": "C85031"},
            {"label": "Basa", "value": "C85032"},
            {"label": "Basag", "value": "C85033"},
            {"label": "Basilisa", "value": "C85034"},
            {"label": "Bayabas", "value": "C85035"},
            {"label": "Bayugan", "value": "C85036"},
            {"label": "Bigaan", "value": "C85037"},
            {"label": "Binucayan", "value": "C85038"},
            {"label": "Bislig", "value": "C85039"},
            {"label": "Borbon", "value": "C85040"},
            {"label": "Buenavista", "value": "C85041"},
            {"label": "Bunawan", "value": "C85042"},
            {"label": "Burgos", "value": "C85043"},
            {"label": "Butuan", "value": "C85044"},
            {"label": "Cabadbaran", "value": "C85045"},
            {"label": "Cagdianao", "value": "C85046"},
            {"label": "Cagwait", "value": "C85047"},
            {"label": "Calamba", "value": "C85048"},
            {"label": "Caloc-an", "value": "C85049"},
            {"label": "Cantapoy", "value": "C85050"},
            {"label": "Cantilan", "value": "C85051"},
            {"label": "Capalayan", "value": "C85052"},
            {"label": "Carmen", "value": "C85053"},
            {"label": "Carrascal", "value": "C85054"},
            {"label": "Causwagan", "value": "C85055"},
            {"label": "Claver", "value": "C85056"},
            {"label": "Comagascas", "value": "C85057"},
            {"label": "Cortes", "value": "C85058"},
            {"label": "Cuevas", "value": "C85059"},
            {"label": "Culit", "value": "C85060"},
            {"label": "Dapa", "value": "C85061"},
            {"label": "Del Carmen, Surigao del Norte", "value": "C85062"},
            {"label": "Del Pilar", "value": "C85063"},
            {"label": "Dinagat", "value": "C85064"},
            {"label": "Dinagat Islands", "value": "C85065"},
            {"label": "Esperanza", "value": "C85066"},
            {"label": "Gamut", "value": "C85067"},
            {"label": "General Luna", "value": "C85068"},
            {"label": "Gigaquit", "value": "C85069"},
            {"label": "Guadalupe", "value": "C85070"},
            {"label": "Guinabsan", "value": "C85071"},
            {"label": "Hinatuan", "value": "C85072"},
            {"label": "Ipil", "value": "C85073"},
            {"label": "Jabonga", "value": "C85074"},
            {"label": "Jagupit", "value": "C85075"},
            {"label": "Javier", "value": "C85076"},
            {"label": "Kauswagan", "value": "C85077"},
            {"label": "Kinabhangan", "value": "C85078"},
            {"label": "Kitcharao", "value": "C85079"},
            {"label": "La Paz", "value": "C85080"},
            {"label": "La Union", "value": "C85081"},
            {"label": "Lanuza", "value": "C85082"},
            {"label": "Lapinigan", "value": "C85083"},
            {"label": "Lianga", "value": "C85084"},
            {"label": "Libas", "value": "C85085"},
            {"label": "Libertad", "value": "C85086"},
            {"label": "Libjo", "value": "C85087"},
            {"label": "Lingig", "value": "C85088"},
            {"label": "Lombocan", "value": "C85089"},
            {"label": "Loreto", "value": "C85090"},
            {"label": "Los Angeles", "value": "C85091"},
            {"label": "Los Arcos", "value": "C85092"},
            {"label": "Loyola", "value": "C85093"},
            {"label": "Luna", "value": "C85094"},
            {"label": "Mabahin", "value": "C85095"},
            {"label": "Mabua", "value": "C85096"},
            {"label": "Madrid", "value": "C85097"},
            {"label": "Magallanes", "value": "C85098"},
            {"label": "Mainit", "value": "C85099"},
            {"label": "Malimono", "value": "C85100"},
            {"label": "Manapa", "value": "C85101"},
            {"label": "Marihatag", "value": "C85102"},
            {"label": "Matabao", "value": "C85103"},
            {"label": "Mati", "value": "C85104"},
            {"label": "Maygatasan", "value": "C85105"},
            {"label": "Nasipit", "value": "C85106"},
            {"label": "Panikian", "value": "C85107"},
            {"label": "Parang", "value": "C85108"},
            {"label": "Patin-ay", "value": "C85109"},
            {"label": "Pilar", "value": "C85110"},
            {"label": "Placer", "value": "C85111"},
            {"label": "Prosperidad", "value": "C85112"},
            {"label": "Province of Agusan del Norte", "value": "C85113"},
            {"label": "Province of Agusan del Sur", "value": "C85114"},
            {"label": "Province of Surigao del Norte", "value": "C85115"},
            {"label": "Province of Surigao del Sur", "value": "C85116"},
            {"label": "Punta", "value": "C85117"},
            {"label": "Rizal", "value": "C85118"},
            {"label": "Salvacion", "value": "C85119"},
            {"label": "San Benito", "value": "C85120"},
            {"label": "San Francisco", "value": "C85121"},
            {"label": "San Isidro", "value": "C85122"},
            {"label": "San Luis", "value": "C85123"},
            {"label": "San Miguel", "value": "C85124"},
            {"label": "Sanghan", "value": "C85125"},
            {"label": "Santa Ana", "value": "C85126"},
            {"label": "Santa Josefa", "value": "C85127"},
            {"label": "Santa Maria", "value": "C85128"},
            {"label": "Santa Monica", "value": "C85129"},
            {"label": "Santo Tomas", "value": "C85130"},
            {"label": "Sibagat", "value": "C85131"},
            {"label": "Sinubong", "value": "C85132"},
            {"label": "Sison", "value": "C85133"},
            {"label": "Socorro", "value": "C85134"},
            {"label": "Surigao", "value": "C85135"},
            {"label": "Tagana-an", "value": "C85136"},
            {"label": "Tagbina", "value": "C85137"},
            {"label": "Tagcatong", "value": "C85138"},
            {"label": "Tago", "value": "C85139"},
            {"label": "Talacogon", "value": "C85140"},
            {"label": "Taligaman", "value": "C85141"},
            {"label": "Talisay", "value": "C85142"},
            {"label": "Tandag", "value": "C85143"},
            {"label": "Tidman", "value": "C85144"},
            {"label": "Tigao", "value": "C85145"},
            {"label": "Trento", "value": "C85146"},
            {"label": "Tubajon", "value": "C85147"},
            {"label": "Tubay", "value": "C85148"},
            {"label": "Tubod", "value": "C85149"},
            {"label": "Tungao", "value": "C85150"},
            {"label": "Unidad", "value": "C85151"},
            {"label": "Union", "value": "C85152"},
            {"label": "Veruela", "value": "C85153"}],
 "Caras-Severin County": [{"label": "Anina", "value": "C94153"},
                          {"label": "Armenis", "value": "C94154"},
                          {"label": "Baile Herculane", "value": "C94155"},
                          {"label": "Bania", "value": "C94156"},
                          {"label": "Bautar", "value": "C94157"},
                          {"label": "Berliste", "value": "C94158"},
                          {"label": "Berzasca", "value": "C94159"},
                          {"label": "Berzovia", "value": "C94160"},
                          {"label": "Bocsa", "value": "C94161"},
                          {"label": "Bolvasnita", "value": "C94162"},
                          {"label": "Borlova", "value": "C94163"},
                          {"label": "Bozovici", "value": "C94164"},
                          {"label": "Brebu", "value": "C94165"},
                          {"label": "Brebu Nou", "value": "C94166"},
                          {"label": "Buchin", "value": "C94167"},
                          {"label": "Bucosnita", "value": "C94168"},
                          {"label": "Bucova", "value": "C94169"},
                          {"label": "Calnic", "value": "C94170"},
                          {"label": "Caransebes", "value": "C94171"},
                          {"label": "Carasova", "value": "C94172"},
                          {"label": "Carbunari", "value": "C94173"},
                          {"label": "Ciclova-Romana", "value": "C94174"},
                          {"label": "Ciuchici", "value": "C94175"},
                          {"label": "Ciudanovita", "value": "C94176"},
                          {"label": "Clocotici", "value": "C94177"},
                          {"label": "Comuna Armenis", "value": "C94178"},
                          {"label": "Comuna Bania", "value": "C94179"},
                          {"label": "Comuna Bautar", "value": "C94180"},
                          {"label": "Comuna Berliste", "value": "C94181"},
                          {"label": "Comuna Berzasca", "value": "C94182"},
                          {"label": "Comuna Berzovia", "value": "C94183"},
                          {"label": "Comuna Bolvasnita", "value": "C94184"},
                          {"label": "Comuna Bozovici", "value": "C94185"},
                          {"label": "Comuna Brebu", "value": "C94186"},
                          {"label": "Comuna Brebu Nou", "value": "C94187"},
                          {"label": "Comuna Buchin", "value": "C94188"},
                          {"label": "Comuna Bucosnita", "value": "C94189"},
                          {"label": "Comuna Carasova", "value": "C94190"},
                          {"label": "Comuna Carbunari", "value": "C94191"},
                          {"label": "Comuna Ciclova Romana", "value": "C94192"},
                          {"label": "Comuna Ciuchici", "value": "C94193"},
                          {"label": "Comuna Ciudanovita", "value": "C94194"},
                          {"label": "Comuna Constantin Daicoviciu",
                           "value": "C94195"},
                          {"label": "Comuna Copacele", "value": "C94196"},
                          {"label": "Comuna Cornea", "value": "C94197"},
                          {"label": "Comuna Cornereva", "value": "C94198"},
                          {"label": "Comuna Dalboset", "value": "C94199"},
                          {"label": "Comuna Doclin", "value": "C94200"},
                          {"label": "Comuna Dognecea", "value": "C94201"},
                          {"label": "Comuna Domasnea", "value": "C94202"},
                          {"label": "Comuna Eftimie Murgu", "value": "C94203"},
                          {"label": "Comuna Ezeris", "value": "C94204"},
                          {"label": "Comuna Farliug", "value": "C94205"},
                          {"label": "Comuna Forotic", "value": "C94206"},
                          {"label": "Comuna Garnic", "value": "C94207"},
                          {"label": "Comuna Glimboca", "value": "C94208"},
                          {"label": "Comuna Goruia", "value": "C94209"},
                          {"label": "Comuna Gradinari", "value": "C94210"},
                          {"label": "Comuna Iablanita", "value": "C94211"},
                          {"label": "Comuna Lapusnicel", "value": "C94212"},
                          {"label": "Comuna Lapusnicu Mare", "value": "C94213"},
                          {"label": "Comuna Luncavita", "value": "C94214"},
                          {"label": "Comuna Lupac", "value": "C94215"},
                          {"label": "Comuna Marga", "value": "C94216"},
                          {"label": "Comuna Maureni", "value": "C94217"},
                          {"label": "Comuna Mehadia", "value": "C94218"},
                          {"label": "Comuna Mehadica", "value": "C94219"},
                          {"label": "Comuna Naidas", "value": "C94220"},
                          {"label": "Comuna Obreja", "value": "C94221"},
                          {"label": "Comuna Ocna De Fier", "value": "C94222"},
                          {"label": "Comuna Paltinis", "value": "C94223"},
                          {"label": "Comuna Pescari", "value": "C94224"},
                          {"label": "Comuna Pojejena", "value": "C94225"},
                          {"label": "Comuna Prigor", "value": "C94226"},
                          {"label": "Comuna Racasdia", "value": "C94227"},
                          {"label": "Comuna Ramna", "value": "C94228"},
                          {"label": "Comuna Rusca Montana", "value": "C94229"},
                          {"label": "Comuna Sacu", "value": "C94230"},
                          {"label": "Comuna Sasca Montana", "value": "C94231"},
                          {"label": "Comuna Sichevita", "value": "C94232"},
                          {"label": "Comuna Slatina-Timis", "value": "C94233"},
                          {"label": "Comuna Socol", "value": "C94234"},
                          {"label": "Comuna Sopotu Nou", "value": "C94235"},
                          {"label": "Comuna Tarnova", "value": "C94236"},
                          {"label": "Comuna Teregova", "value": "C94237"},
                          {"label": "Comuna Ticvaniu Mare", "value": "C94238"},
                          {"label": "Comuna Toplet", "value": "C94239"},
                          {"label": "Comuna Turnu Rueni", "value": "C94240"},
                          {"label": "Comuna Valiug", "value": "C94241"},
                          {"label": "Comuna Varadia", "value": "C94242"},
                          {"label": "Comuna Vermes", "value": "C94243"},
                          {"label": "Comuna Vrani", "value": "C94244"},
                          {"label": "Comuna Zavoi", "value": "C94245"},
                          {"label": "Comuna Zorlentu Mare", "value": "C94246"},
                          {"label": "Constantin Daicoviciu", "value": "C94247"},
                          {"label": "Copacele", "value": "C94248"},
                          {"label": "Cornea", "value": "C94249"},
                          {"label": "Cornereva", "value": "C94250"},
                          {"label": "Cornutel", "value": "C94251"},
                          {"label": "Coronini", "value": "C94252"},
                          {"label": "Dalboset", "value": "C94253"},
                          {"label": "Doclin", "value": "C94254"},
                          {"label": "Dognecea", "value": "C94255"},
                          {"label": "Domasnea", "value": "C94256"},
                          {"label": "Eftimie Murgu", "value": "C94257"},
                          {"label": "Ezeris", "value": "C94258"},
                          {"label": "Farliug", "value": "C94259"},
                          {"label": "Fizes", "value": "C94260"},
                          {"label": "Forotic", "value": "C94261"},
                          {"label": "Garnic", "value": "C94262"},
                          {"label": "Ghertenis", "value": "C94263"},
                          {"label": "Glimboca", "value": "C94264"},
                          {"label": "Goruia", "value": "C94265"},
                          {"label": "Gradinari", "value": "C94266"},
                          {"label": "Greoni", "value": "C94267"},
                          {"label": "Iablanita", "value": "C94268"},
                          {"label": "Lapusnicel", "value": "C94269"},
                          {"label": "Lapusnicu Mare", "value": "C94270"},
                          {"label": "Liubcova", "value": "C94271"},
                          {"label": "Luncavita", "value": "C94272"},
                          {"label": "Lupac", "value": "C94273"},
                          {"label": "Marga", "value": "C94274"},
                          {"label": "Maru", "value": "C94275"},
                          {"label": "Maureni", "value": "C94276"},
                          {"label": "Mehadia", "value": "C94277"},
                          {"label": "Mehadica", "value": "C94278"},
                          {"label": "Moldova Noua", "value": "C94279"},
                          {"label": "Municipiul Caransebes", "value": "C94280"},
                          {"label": "Municipiul Resita", "value": "C94281"},
                          {"label": "Muntele Mic", "value": "C94282"},
                          {"label": "Naidas", "value": "C94283"},
                          {"label": "Obreja", "value": "C94284"},
                          {"label": "Oras Anina", "value": "C94285"},
                          {"label": "Oras Baile Herculane", "value": "C94286"},
                          {"label": "Oras Bocsa", "value": "C94287"},
                          {"label": "Oras Oravita", "value": "C94288"},
                          {"label": "Oras Otelu Rosu", "value": "C94289"},
                          {"label": "Oravita", "value": "C94290"},
                          {"label": "Otelu Rosu", "value": "C94291"},
                          {"label": "Padina Matei", "value": "C94292"},
                          {"label": "Paltinis", "value": "C94293"},
                          {"label": "Petrosnita", "value": "C94294"},
                          {"label": "Plugova", "value": "C94295"},
                          {"label": "Pojejena", "value": "C94296"},
                          {"label": "Prigor", "value": "C94297"},
                          {"label": "Racasdia", "value": "C94298"},
                          {"label": "Ramna", "value": "C94299"},
                          {"label": "Resita", "value": "C94300"},
                          {"label": "Rusca", "value": "C94301"},
                          {"label": "Rusca Montana", "value": "C94302"},
                          {"label": "Sacu", "value": "C94303"},
                          {"label": "Sasca Montana", "value": "C94304"},
                          {"label": "Sichevita", "value": "C94305"},
                          {"label": "Slatina-Timis", "value": "C94306"},
                          {"label": "Socol", "value": "C94307"},
                          {"label": "Sopotu Nou", "value": "C94308"},
                          {"label": "Steierdorf", "value": "C94309"},
                          {"label": "Tarnova", "value": "C94310"},
                          {"label": "Teregova", "value": "C94311"},
                          {"label": "Ticvaniu Mare", "value": "C94312"},
                          {"label": "Toplet", "value": "C94313"},
                          {"label": "Turnu Ruieni", "value": "C94314"},
                          {"label": "Valea Bolvasnita", "value": "C94315"},
                          {"label": "Valiug", "value": "C94316"},
                          {"label": "Varadia", "value": "C94317"},
                          {"label": "Varciorova", "value": "C94318"},
                          {"label": "Verendin", "value": "C94319"},
                          {"label": "Vermes", "value": "C94320"},
                          {"label": "Vrani", "value": "C94321"},
                          {"label": "Zavoi", "value": "C94322"},
                          {"label": "Zorlentu Mare", "value": "C94323"}],
 "Carazo": [{"label": "Diriamba", "value": "C77893"},
            {"label": "Dolores", "value": "C77894"},
            {"label": "El Rosario", "value": "C77895"},
            {"label": "Jinotepe", "value": "C77896"},
            {"label": "La Conquista", "value": "C77897"},
            {"label": "La Paz de Carazo", "value": "C77898"},
            {"label": "Municipio de San Marcos", "value": "C77899"},
            {"label": "San Marcos", "value": "C77900"},
            {"label": "Santa Teresa", "value": "C77901"}],
 "Carchi": [{"label": "El angel", "value": "C24103"},
            {"label": "San Gabriel", "value": "C24104"},
            {"label": "Tulcan", "value": "C24105"}],
 "Cargados Carajos": [{"label": "Cargados Carajos", "value": "C65977"}],
 "Carinthia": [{"label": "Albeck", "value": "C6173"},
               {"label": "Althofen", "value": "C6174"},
               {"label": "Annabichl", "value": "C6175"},
               {"label": "Arnoldstein", "value": "C6176"},
               {"label": "Arriach", "value": "C6177"},
               {"label": "Auen", "value": "C6178"},
               {"label": "Bad Bleiberg", "value": "C6179"},
               {"label": "Bad Eisenkappel", "value": "C6180"},
               {"label": "Bad Kleinkirchheim", "value": "C6181"},
               {"label": "Bad Sankt Leonhard im Lavanttal", "value": "C6182"},
               {"label": "Baldramsdorf", "value": "C6183"},
               {"label": "Berg im Drautal", "value": "C6184"},
               {"label": "Bleiburg", "value": "C6185"},
               {"label": "Bodensdorf", "value": "C6186"},
               {"label": "Bruckl", "value": "C6187"},
               {"label": "Dellach", "value": "C6188"},
               {"label": "Dellach im Drautal", "value": "C6189"},
               {"label": "Deutsch Griffen", "value": "C6190"},
               {"label": "Deutsch-Griffen", "value": "C6191"},
               {"label": "Diex", "value": "C6192"},
               {"label": "Dobriach", "value": "C6193"},
               {"label": "Draschen", "value": "C6194"},
               {"label": "Ebenthal", "value": "C6195"},
               {"label": "Eberndorf", "value": "C6196"},
               {"label": "Eberstein", "value": "C6197"},
               {"label": "Ebriach", "value": "C6198"},
               {"label": "Eisenkappel-Vellach", "value": "C6199"},
               {"label": "Faak am See", "value": "C6200"},
               {"label": "Feffernitz", "value": "C6201"},
               {"label": "Feistritz an der Drau", "value": "C6202"},
               {"label": "Feistritz an der Gail", "value": "C6203"},
               {"label": "Feistritz im Rosental", "value": "C6204"},
               {"label": "Feistritz ob Bleiburg", "value": "C6205"},
               {"label": "Feld am See", "value": "C6206"},
               {"label": "Feldkirchen District", "value": "C6207"},
               {"label": "Feldkirchen in Karnten", "value": "C6208"},
               {"label": "Ferlach", "value": "C6209"},
               {"label": "Ferndorf", "value": "C6210"},
               {"label": "Finkenstein", "value": "C6211"},
               {"label": "Finkenstein am Faaker See", "value": "C6212"},
               {"label": "Flattach", "value": "C6213"},
               {"label": "Foderlach I", "value": "C6214"},
               {"label": "Frantschach-Sankt Gertraud", "value": "C6215"},
               {"label": "Frauenstein", "value": "C6216"},
               {"label": "Fresach", "value": "C6217"},
               {"label": "Fresen", "value": "C6218"},
               {"label": "Friesach", "value": "C6219"},
               {"label": "Furnitz", "value": "C6220"},
               {"label": "Gailitz", "value": "C6221"},
               {"label": "Gallizien", "value": "C6222"},
               {"label": "Gemmersdorf", "value": "C6223"},
               {"label": "Gerlamoos", "value": "C6224"},
               {"label": "Gitschtal", "value": "C6225"},
               {"label": "Glanegg", "value": "C6226"},
               {"label": "Globasnitz", "value": "C6227"},
               {"label": "Glodnitz", "value": "C6228"},
               {"label": "Gmund", "value": "C6229"},
               {"label": "Gmund in Karnten", "value": "C6230"},
               {"label": "Gnesau", "value": "C6231"},
               {"label": "Gortschach", "value": "C6232"},
               {"label": "Grafenstein", "value": "C6233"},
               {"label": "Greifenburg", "value": "C6234"},
               {"label": "Gries", "value": "C6235"},
               {"label": "Griffen", "value": "C6236"},
               {"label": "Grosskirchheim", "value": "C6237"},
               {"label": "Gurk", "value": "C6238"},
               {"label": "Guttaring", "value": "C6239"},
               {"label": "Heiligenblut", "value": "C6240"},
               {"label": "Hermagor", "value": "C6241"},
               {"label": "Hermagor District", "value": "C6242"},
               {"label": "Hermagor-Pressegger See", "value": "C6243"},
               {"label": "Himmelberg", "value": "C6244"},
               {"label": "Hohenthurn", "value": "C6245"},
               {"label": "Hortendorf", "value": "C6246"},
               {"label": "Huttenberg", "value": "C6247"},
               {"label": "Irschen", "value": "C6248"},
               {"label": "Kappel am Krappfeld", "value": "C6249"},
               {"label": "Keutschach am See", "value": "C6250"},
               {"label": "Kirchbach", "value": "C6251"},
               {"label": "Klagenfurt", "value": "C6252"},
               {"label": "Klagenfurt am Worthersee", "value": "C6253"},
               {"label": "Klagenfurt-Land District", "value": "C6254"},
               {"label": "Kleblach-Lind", "value": "C6255"},
               {"label": "Klein Sankt Paul", "value": "C6256"},
               {"label": "Kleinedling", "value": "C6257"},
               {"label": "Kotschach", "value": "C6258"},
               {"label": "Kotschach-Mauthen", "value": "C6259"},
               {"label": "Kottmannsdorf", "value": "C6260"},
               {"label": "Krems", "value": "C6261"},
               {"label": "Krumpendorf", "value": "C6262"},
               {"label": "Kuhnsdorf", "value": "C6263"},
               {"label": "Lamnitz", "value": "C6264"},
               {"label": "Landskron", "value": "C6265"},
               {"label": "Launsdorf", "value": "C6266"},
               {"label": "Lavamund", "value": "C6267"},
               {"label": "Ledenitzen", "value": "C6268"},
               {"label": "Lendorf", "value": "C6269"},
               {"label": "Leppen", "value": "C6270"},
               {"label": "Lesachtal", "value": "C6271"},
               {"label": "Liebenfels", "value": "C6272"},
               {"label": "Lind", "value": "C6273"},
               {"label": "Lobersberg", "value": "C6274"},
               {"label": "Ludmannsdorf", "value": "C6275"},
               {"label": "Lurnfeld", "value": "C6276"},
               {"label": "Magdalensberg", "value": "C6277"},
               {"label": "Mallnitz", "value": "C6278"},
               {"label": "Malta", "value": "C6279"},
               {"label": "Maria Rain", "value": "C6280"},
               {"label": "Maria Saal", "value": "C6281"},
               {"label": "Maria Worth", "value": "C6282"},
               {"label": "Mautbrucken", "value": "C6283"},
               {"label": "Metnitz", "value": "C6284"},
               {"label": "Micheldorf", "value": "C6285"},
               {"label": "Millstatt", "value": "C6286"},
               {"label": "Molbling", "value": "C6287"},
               {"label": "Mollbrucke", "value": "C6288"},
               {"label": "Moosburg", "value": "C6289"},
               {"label": "Mortschach", "value": "C6290"},
               {"label": "Muhldorf", "value": "C6291"},
               {"label": "Neu-Feffernitz", "value": "C6292"},
               {"label": "Neuhaus", "value": "C6293"},
               {"label": "Niederdorf", "value": "C6294"},
               {"label": "Notsch im Gailtal", "value": "C6295"},
               {"label": "Oberdorfl", "value": "C6296"},
               {"label": "Oberdrauburg", "value": "C6297"},
               {"label": "Obermillstatt", "value": "C6298"},
               {"label": "Obervellach", "value": "C6299"},
               {"label": "Ossiach", "value": "C6300"},
               {"label": "Paternion", "value": "C6301"},
               {"label": "Pischeldorf", "value": "C6302"},
               {"label": "Pockau", "value": "C6303"},
               {"label": "Poggersdorf", "value": "C6304"},
               {"label": "Portschach am Worthersee", "value": "C6305"},
               {"label": "Preitenegg", "value": "C6306"},
               {"label": "Priel", "value": "C6307"},
               {"label": "Pusarnitz", "value": "C6308"},
               {"label": "Radenthein", "value": "C6309"},
               {"label": "Rain", "value": "C6310"},
               {"label": "Rangersdorf", "value": "C6311"},
               {"label": "Reding", "value": "C6312"},
               {"label": "Reichenau", "value": "C6313"},
               {"label": "Reichenfels", "value": "C6314"},
               {"label": "Reisseck", "value": "C6315"},
               {"label": "Rennweg am Katschberg", "value": "C6316"},
               {"label": "Rittersdorf", "value": "C6317"},
               {"label": "Rosegg", "value": "C6318"},
               {"label": "Ruden", "value": "C6319"},
               {"label": "Sachsenburg", "value": "C6320"},
               {"label": "Sankt Andra", "value": "C6321"},
               {"label": "Sankt Egyden an der Drau", "value": "C6322"},
               {"label": "Sankt Georgen am Langsee", "value": "C6323"},
               {"label": "Sankt Georgen im Lavanttal", "value": "C6324"},
               {"label": "Sankt Jakob", "value": "C6325"},
               {"label": "Sankt Jakob im Rosental", "value": "C6326"},
               {"label": "Sankt Kanzian am Klopeiner See", "value": "C6327"},
               {"label": "Sankt Leonhard", "value": "C6328"},
               {"label": "Sankt Magdalen", "value": "C6329"},
               {"label": "Sankt Margareten im Rosental", "value": "C6330"},
               {"label": "Sankt Martin", "value": "C6331"},
               {"label": "Sankt Paul im Lavanttal", "value": "C6332"},
               {"label": "Sankt Peter", "value": "C6333"},
               {"label": "Sankt Ruprecht", "value": "C6334"},
               {"label": "Sankt Stefan", "value": "C6335"},
               {"label": "Sankt Stefan im Gailtal", "value": "C6336"},
               {"label": "Sankt Urban", "value": "C6337"},
               {"label": "Sankt Veit an der Glan", "value": "C6338"},
               {"label": "Sankt Veit an der Glan District", "value": "C6339"},
               {"label": "Schiefling am See", "value": "C6340"},
               {"label": "Schleben", "value": "C6341"},
               {"label": "Seebach", "value": "C6342"},
               {"label": "Seeboden", "value": "C6343"},
               {"label": "Seitenberg", "value": "C6344"},
               {"label": "Sirnitz", "value": "C6345"},
               {"label": "Sittersdorf", "value": "C6346"},
               {"label": "Spittal an der Drau", "value": "C6347"},
               {"label": "Spittal an der Drau District", "value": "C6348"},
               {"label": "St. Agathen und Perau", "value": "C6349"},
               {"label": "St. Andra", "value": "C6350"},
               {"label": "St. Margareten im Rosental", "value": "C6351"},
               {"label": "St. Paul im Lavanttal", "value": "C6352"},
               {"label": "St. Stefan im Gailtal", "value": "C6353"},
               {"label": "St. Urban", "value": "C6354"},
               {"label": "St. Veit an der Glan", "value": "C6355"},
               {"label": "St. Veiter Vorstadt", "value": "C6356"},
               {"label": "Stall", "value": "C6357"},
               {"label": "Steindorf am Ossiacher See", "value": "C6358"},
               {"label": "Steinfeld", "value": "C6359"},
               {"label": "Steuerberg", "value": "C6360"},
               {"label": "Stockenboi", "value": "C6361"},
               {"label": "Strassburg", "value": "C6362"},
               {"label": "Strassburg-Stadt", "value": "C6363"},
               {"label": "Suetschach", "value": "C6364"},
               {"label": "Techelsberg", "value": "C6365"},
               {"label": "Timenitz", "value": "C6366"},
               {"label": "Trebesing", "value": "C6367"},
               {"label": "Treffen", "value": "C6368"},
               {"label": "Treibach", "value": "C6369"},
               {"label": "Tschachoritsch", "value": "C6370"},
               {"label": "Umberg", "value": "C6371"},
               {"label": "Untere Fellach", "value": "C6372"},
               {"label": "Velden am Worther See", "value": "C6373"},
               {"label": "Velden am Worthersee", "value": "C6374"},
               {"label": "Viktring", "value": "C6375"},
               {"label": "Viktringer Vorstadt", "value": "C6376"},
               {"label": "Villach", "value": "C6377"},
               {"label": "Villach-Innere Stadt", "value": "C6378"},
               {"label": "Villach-Land District", "value": "C6379"},
               {"label": "Villacher Vorstadt", "value": "C6380"},
               {"label": "Volkendorf", "value": "C6381"},
               {"label": "Volkermarkt", "value": "C6382"},
               {"label": "Volkermarkt District", "value": "C6383"},
               {"label": "Volkermarkter Vorstadt", "value": "C6384"},
               {"label": "Waiern", "value": "C6385"},
               {"label": "Warmbad-Judendorf", "value": "C6386"},
               {"label": "Weissensee", "value": "C6387"},
               {"label": "Weissenstein", "value": "C6388"},
               {"label": "Weitensfeld", "value": "C6389"},
               {"label": "Weitensfeld im Gurktal", "value": "C6390"},
               {"label": "Wernberg", "value": "C6391"},
               {"label": "Winklern", "value": "C6392"},
               {"label": "Wolfnitz", "value": "C6393"},
               {"label": "Wolfsberg", "value": "C6394"},
               {"label": "Wolfsberg District", "value": "C6395"},
               {"label": "Wudmath", "value": "C6396"},
               {"label": "Zell", "value": "C6397"}],
 "Carnikava Municipality": [{"label": "Carnikava", "value": "C64856"}],
 "Carriacou and Petite Martinique": [{"label": "Hillsborough",
                                      "value": "C42608"}],
 "Casablanca-Settat": [{"label": "Azemmour", "value": "C75654"},
                       {"label": "Benslimane", "value": "C75655"},
                       {"label": "Berrechid", "value": "C75656"},
                       {"label": "Berrechid Province", "value": "C75657"},
                       {"label": "Boulaouane", "value": "C75658"},
                       {"label": "Bouskoura", "value": "C75659"},
                       {"label": "Bouznika", "value": "C75660"},
                       {"label": "Casablanca", "value": "C75661"},
                       {"label": "El Jadid", "value": "C75662"},
                       {"label": "El-Jadida", "value": "C75663"},
                       {"label": "Mediouna", "value": "C75664"},
                       {"label": "Mohammedia", "value": "C75665"},
                       {"label": "Nouaceur", "value": "C75666"},
                       {"label": "Oualidia", "value": "C75667"},
                       {"label": "Oulad Frej", "value": "C75668"},
                       {"label": "Settat", "value": "C75669"},
                       {"label": "Settat Province", "value": "C75670"},
                       {"label": "Sidi Bennour", "value": "C75671"},
                       {"label": "Sidi Smaiil", "value": "C75672"},
                       {"label": "Tit Mellil", "value": "C75673"},
                       {"label": "Zawyat an Nwacer", "value": "C75674"}],
 "Casanare": [{"label": "Aguazul", "value": "C19916"},
              {"label": "Chameza", "value": "C19917"},
              {"label": "Hato Corozal", "value": "C19918"},
              {"label": "La Salina", "value": "C19919"},
              {"label": "Mani", "value": "C19920"},
              {"label": "Monterrey", "value": "C19921"},
              {"label": "Nunchia", "value": "C19922"},
              {"label": "Orocue", "value": "C19923"},
              {"label": "Paz de Ariporo", "value": "C19924"},
              {"label": "Pore", "value": "C19925"},
              {"label": "Recetor", "value": "C19926"},
              {"label": "Sabanalarga", "value": "C19927"},
              {"label": "Sacama", "value": "C19928"},
              {"label": "San Luis de Palenque", "value": "C19929"},
              {"label": "Tamara", "value": "C19930"},
              {"label": "Tauramena", "value": "C19931"},
              {"label": "Trinidad", "value": "C19932"},
              {"label": "Villanueva", "value": "C19933"},
              {"label": "Yopal", "value": "C19934"}],
 "Cascade": [{"label": "Cascade", "value": "C107233"}],
 "Cascades Region": [{"label": "Banfora", "value": "C16363"},
                     {"label": "Province de la Comoe", "value": "C16364"},
                     {"label": "Province de la Leraba", "value": "C16365"},
                     {"label": "Sindou", "value": "C16366"}],
 "Caska Municipality": [{"label": "Bogomila", "value": "C65382"},
                        {"label": "Caska", "value": "C65383"}],
 "Castelo Branco": [{"label": "Aldeia de Joanes", "value": "C90983"},
                    {"label": "Belmonte", "value": "C90984"},
                    {"label": "Castelo Branco", "value": "C90985"},
                    {"label": "Covilha", "value": "C90986"},
                    {"label": "Fundao", "value": "C90987"},
                    {"label": "Idanha-A-Nova", "value": "C90988"},
                    {"label": "Oleiros", "value": "C90989"},
                    {"label": "Penamacor", "value": "C90990"},
                    {"label": "Proenca-a-Nova", "value": "C90991"},
                    {"label": "Serta", "value": "C90992"},
                    {"label": "Teixoso", "value": "C90993"},
                    {"label": "Vila de Rei", "value": "C90994"},
                    {"label": "Vila Velha de Rodao", "value": "C90995"}],
 "Castilla La Mancha": [{"label": "Abanades", "value": "C110293"},
                        {"label": "Abengibre", "value": "C110294"},
                        {"label": "Abenojar", "value": "C110295"},
                        {"label": "Ablanque", "value": "C110296"},
                        {"label": "Adobes", "value": "C110297"},
                        {"label": "Agudo", "value": "C110298"},
                        {"label": "Ajofrin", "value": "C110299"},
                        {"label": "Alamillo", "value": "C110300"},
                        {"label": "Alaminos", "value": "C110301"},
                        {"label": "Alarcon", "value": "C110302"},
                        {"label": "Alarilla", "value": "C110303"},
                        {"label": "Alatoz", "value": "C110304"},
                        {"label": "Albacete", "value": "C110305"},
                        {"label": "Albaladejo", "value": "C110306"},
                        {"label": "Albaladejo del Cuende", "value": "C110307"},
                        {"label": "Albalate de las Nogueras",
                         "value": "C110308"},
                        {"label": "Albalate de Zorita", "value": "C110309"},
                        {"label": "Albares", "value": "C110310"},
                        {"label": "Albarreal de Tajo", "value": "C110311"},
                        {"label": "Albatana", "value": "C110312"},
                        {"label": "Albendea", "value": "C110313"},
                        {"label": "Albendiego", "value": "C110314"},
                        {"label": "Alborea", "value": "C110315"},
                        {"label": "Alcabon", "value": "C110316"},
                        {"label": "Alcadozo", "value": "C110317"},
                        {"label": "Alcala de la Vega", "value": "C110318"},
                        {"label": "Alcala del Jucar", "value": "C110319"},
                        {"label": "Alcanizo", "value": "C110320"},
                        {"label": "Alcantud", "value": "C110321"},
                        {"label": "Alcaraz", "value": "C110322"},
                        {"label": "Alcazar de San Juan", "value": "C110323"},
                        {"label": "Alcazar del Rey", "value": "C110324"},
                        {"label": "Alcoba", "value": "C110325"},
                        {"label": "Alcocer", "value": "C110326"},
                        {"label": "Alcohujate", "value": "C110327"},
                        {"label": "Alcolea de Calatrava", "value": "C110328"},
                        {"label": "Alcolea de las Penas", "value": "C110329"},
                        {"label": "Alcolea de Tajo", "value": "C110330"},
                        {"label": "Alcolea del Pinar", "value": "C110331"},
                        {"label": "Alconchel de la Estrella",
                         "value": "C110332"},
                        {"label": "Alcoroches", "value": "C110333"},
                        {"label": "Alcubillas", "value": "C110334"},
                        {"label": "Aldea del Rey", "value": "C110335"},
                        {"label": "Aldeanueva de Barbarroya",
                         "value": "C110336"},
                        {"label": "Aldeanueva de Guadalajara",
                         "value": "C110337"},
                        {"label": "Aldeanueva de San Bartolome",
                         "value": "C110338"},
                        {"label": "Algar de Mesa", "value": "C110339"},
                        {"label": "Algarra", "value": "C110340"},
                        {"label": "Algora", "value": "C110341"},
                        {"label": "Alhambra", "value": "C110342"},
                        {"label": "Alhondiga", "value": "C110343"},
                        {"label": "Aliaguilla", "value": "C110344"},
                        {"label": "Alique", "value": "C110345"},
                        {"label": "Almaden", "value": "C110346"},
                        {"label": "Almadrones", "value": "C110347"},
                        {"label": "Almagro", "value": "C110348"},
                        {"label": "Almansa", "value": "C110349"},
                        {"label": "Almedina", "value": "C110350"},
                        {"label": "Almendral de la Canada", "value": "C110351"},
                        {"label": "Almodovar del Campo", "value": "C110352"},
                        {"label": "Almodovar del Pinar", "value": "C110353"},
                        {"label": "Almoguera", "value": "C110354"},
                        {"label": "Almonacid de Zorita", "value": "C110355"},
                        {"label": "Almonacid del Marquesado",
                         "value": "C110356"},
                        {"label": "Almorox", "value": "C110357"},
                        {"label": "Alocen", "value": "C110358"},
                        {"label": "Alovera", "value": "C110359"},
                        {"label": "Alpera", "value": "C110360"},
                        {"label": "Altarejos", "value": "C110361"},
                        {"label": "Alustante", "value": "C110362"},
                        {"label": "Anchuras", "value": "C110363"},
                        {"label": "Anguita", "value": "C110364"},
                        {"label": "Anover de Tajo", "value": "C110365"},
                        {"label": "Anquela del Ducado", "value": "C110366"},
                        {"label": "Anquela del Pedregal", "value": "C110367"},
                        {"label": "Arandilla del Arroyo", "value": "C110368"},
                        {"label": "Aranzueque", "value": "C110369"},
                        {"label": "Arbancon", "value": "C110370"},
                        {"label": "Arbeteta", "value": "C110371"},
                        {"label": "Arcicollar", "value": "C110372"},
                        {"label": "Arcos de la Sierra", "value": "C110373"},
                        {"label": "Arenas de San Juan", "value": "C110374"},
                        {"label": "Argamasilla de Alba", "value": "C110375"},
                        {"label": "Argamasilla de Calatrava",
                         "value": "C110376"},
                        {"label": "Argecilla", "value": "C110377"},
                        {"label": "Arguisuelas", "value": "C110378"},
                        {"label": "Armallones", "value": "C110379"},
                        {"label": "Armuna de Tajuna", "value": "C110380"},
                        {"label": "Arrancacepas", "value": "C110381"},
                        {"label": "Arroyo de las Fraguas", "value": "C110382"},
                        {"label": "Atalaya del Canavate", "value": "C110383"},
                        {"label": "Atanzon", "value": "C110384"},
                        {"label": "Atienza", "value": "C110385"},
                        {"label": "Aunon", "value": "C110386"},
                        {"label": "Ayna", "value": "C110387"},
                        {"label": "Azuqueca de Henares", "value": "C110388"},
                        {"label": "Azutan", "value": "C110389"},
                        {"label": "Baides", "value": "C110390"},
                        {"label": "Balazote", "value": "C110391"},
                        {"label": "Ballesteros de Calatrava",
                         "value": "C110392"},
                        {"label": "Balsa de Ves", "value": "C110393"},
                        {"label": "Banos de Tajo", "value": "C110394"},
                        {"label": "Banuelos", "value": "C110395"},
                        {"label": "Barajas de Melo", "value": "C110396"},
                        {"label": "Barchin del Hoyo", "value": "C110397"},
                        {"label": "Barcience", "value": "C110398"},
                        {"label": "Bargas", "value": "C110399"},
                        {"label": "Barrax", "value": "C110400"},
                        {"label": "Barriopedro", "value": "C110401"},
                        {"label": "Bascunana de San Pedro", "value": "C110402"},
                        {"label": "Beamud", "value": "C110403"},
                        {"label": "Belinchon", "value": "C110404"},
                        {"label": "Belmonte", "value": "C110405"},
                        {"label": "Belmontejo", "value": "C110406"},
                        {"label": "Belvis de la Jara", "value": "C110407"},
                        {"label": "Berninches", "value": "C110408"},
                        {"label": "Beteta", "value": "C110409"},
                        {"label": "Bienservida", "value": "C110410"},
                        {"label": "Bogarra", "value": "C110411"},
                        {"label": "Bolanos de Calatrava", "value": "C110412"},
                        {"label": "Bonete", "value": "C110413"},
                        {"label": "Boniches", "value": "C110414"},
                        {"label": "Borox", "value": "C110415"},
                        {"label": "Brazatortas", "value": "C110416"},
                        {"label": "Brihuega", "value": "C110417"},
                        {"label": "Buciegas", "value": "C110418"},
                        {"label": "Budia", "value": "C110419"},
                        {"label": "Buenache de Alarcon", "value": "C110420"},
                        {"label": "Buenaventura", "value": "C110421"},
                        {"label": "Buendia", "value": "C110422"},
                        {"label": "Bujalaro", "value": "C110423"},
                        {"label": "Burguillos de Toledo", "value": "C110424"},
                        {"label": "Burujon", "value": "C110425"},
                        {"label": "Bustares", "value": "C110426"},
                        {"label": "Cabanas de la Sagra", "value": "C110427"},
                        {"label": "Cabanas de Yepes", "value": "C110428"},
                        {"label": "Cabanillas del Campo", "value": "C110429"},
                        {"label": "Cabezamesada", "value": "C110430"},
                        {"label": "Cabezarados", "value": "C110431"},
                        {"label": "Calera y Chozas", "value": "C110432"},
                        {"label": "Caleruela", "value": "C110433"},
                        {"label": "Camarena", "value": "C110434"},
                        {"label": "Camarenilla", "value": "C110435"},
                        {"label": "Campillo de Altobuey", "value": "C110436"},
                        {"label": "Campillo de Duenas", "value": "C110437"},
                        {"label": "Campillo de Ranas", "value": "C110438"},
                        {"label": "Campillos-Paravientos", "value": "C110439"},
                        {"label": "Campillos-Sierra", "value": "C110440"},
                        {"label": "Campisabalos", "value": "C110441"},
                        {"label": "Campo de Criptana", "value": "C110442"},
                        {"label": "Camunas", "value": "C110443"},
                        {"label": "Canada de Calatrava", "value": "C110444"},
                        {"label": "Canada del Hoyo", "value": "C110445"},
                        {"label": "Canalejas del Arroyo", "value": "C110446"},
                        {"label": "Canamares", "value": "C110447"},
                        {"label": "Canaveras", "value": "C110448"},
                        {"label": "Canaveruelas", "value": "C110449"},
                        {"label": "Canete", "value": "C110450"},
                        {"label": "Canizar", "value": "C110451"},
                        {"label": "Canizares", "value": "C110452"},
                        {"label": "Canredondo", "value": "C110453"},
                        {"label": "Cantalojas", "value": "C110454"},
                        {"label": "Carboneras de Guadazaon",
                         "value": "C110455"},
                        {"label": "Carcelen", "value": "C110456"},
                        {"label": "Cardenete", "value": "C110457"},
                        {"label": "Cardiel de los Montes", "value": "C110458"},
                        {"label": "Carmena", "value": "C110459"},
                        {"label": "Carranque", "value": "C110460"},
                        {"label": "Carrascosa", "value": "C110461"},
                        {"label": "Carrascosa de Haro", "value": "C110462"},
                        {"label": "Carriches", "value": "C110463"},
                        {"label": "Carrion de Calatrava", "value": "C110464"},
                        {"label": "Carrizosa", "value": "C110465"},
                        {"label": "Casa de Uceda", "value": "C110466"},
                        {"label": "Casarrubios del Monte", "value": "C110467"},
                        {"label": "Casas de Benitez", "value": "C110468"},
                        {"label": "Casas de Fernando Alonso",
                         "value": "C110469"},
                        {"label": "Casas de Garcimolina", "value": "C110470"},
                        {"label": "Casas de Guijarro", "value": "C110471"},
                        {"label": "Casas de Haro", "value": "C110472"},
                        {"label": "Casas de Juan Nunez", "value": "C110473"},
                        {"label": "Casas de Lazaro", "value": "C110474"},
                        {"label": "Casas de los Pinos", "value": "C110475"},
                        {"label": "Casas de San Galindo", "value": "C110476"},
                        {"label": "Casas de Ves", "value": "C110477"},
                        {"label": "Casas Ibanez", "value": "C110478"},
                        {"label": "Casasbuenas", "value": "C110479"},
                        {"label": "Casasimarro", "value": "C110480"},
                        {"label": "Caspuenas", "value": "C110481"},
                        {"label": "Castejon de Henares", "value": "C110482"},
                        {"label": "Castellar de la Muela", "value": "C110483"},
                        {"label": "Castellar de Santiago", "value": "C110484"},
                        {"label": "Castilforte", "value": "C110485"},
                        {"label": "Castillejo de Iniesta", "value": "C110486"},
                        {"label": "Castillejo-Sierra", "value": "C110487"},
                        {"label": "Castillo de Bayuela", "value": "C110488"},
                        {"label": "Castillo de Garcimunoz", "value": "C110489"},
                        {"label": "Castillo-Albaranez", "value": "C110490"},
                        {"label": "Castilnuevo", "value": "C110491"},
                        {"label": "Caudete", "value": "C110492"},
                        {"label": "Cazalegas", "value": "C110493"},
                        {"label": "Cebolla", "value": "C110494"},
                        {"label": "Cedillo del Condado", "value": "C110495"},
                        {"label": "Cendejas de la Torre", "value": "C110496"},
                        {"label": "Cenizate", "value": "C110497"},
                        {"label": "Centenera", "value": "C110498"},
                        {"label": "Cervera de los Montes", "value": "C110499"},
                        {"label": "Cervera del Llano", "value": "C110500"},
                        {"label": "Checa", "value": "C110501"},
                        {"label": "Chequilla", "value": "C110502"},
                        {"label": "Chillaron de Cuenca", "value": "C110503"},
                        {"label": "Chillaron del Rey", "value": "C110504"},
                        {"label": "Chiloeches", "value": "C110505"},
                        {"label": "Chinchilla de Monte Aragon",
                         "value": "C110506"},
                        {"label": "Chozas de Canales", "value": "C110507"},
                        {"label": "Chueca", "value": "C110508"},
                        {"label": "Chumillas", "value": "C110509"},
                        {"label": "Cifuentes", "value": "C110510"},
                        {"label": "Cincovillas", "value": "C110511"},
                        {"label": "Ciruelas", "value": "C110512"},
                        {"label": "Ciruelos", "value": "C110513"},
                        {"label": "Ciudad Real", "value": "C110514"},
                        {"label": "Cobeja", "value": "C110515"},
                        {"label": "Cobeta", "value": "C110516"},
                        {"label": "Cobisa", "value": "C110517"},
                        {"label": "Cogollor", "value": "C110518"},
                        {"label": "Cogolludo", "value": "C110519"},
                        {"label": "Condemios de Abajo", "value": "C110520"},
                        {"label": "Condemios de Arriba", "value": "C110521"},
                        {"label": "Congostrina", "value": "C110522"},
                        {"label": "Consuegra", "value": "C110523"},
                        {"label": "Copernal", "value": "C110524"},
                        {"label": "Corduente", "value": "C110525"},
                        {"label": "Corral de Almaguer", "value": "C110526"},
                        {"label": "Corral de Calatrava", "value": "C110527"},
                        {"label": "Corral-Rubio", "value": "C110528"},
                        {"label": "Cotillas", "value": "C110529"},
                        {"label": "Cozar", "value": "C110530"},
                        {"label": "Cuenca", "value": "C110531"},
                        {"label": "Cuerva", "value": "C110532"},
                        {"label": "Cueva del Hierro", "value": "C110533"},
                        {"label": "Daimiel", "value": "C110534"},
                        {"label": "Domingo Perez", "value": "C110535"},
                        {"label": "Dosbarrios", "value": "C110536"},
                        {"label": "Driebes", "value": "C110537"},
                        {"label": "Duron", "value": "C110538"},
                        {"label": "El Bonillo", "value": "C110539"},
                        {"label": "El Carpio de Tajo", "value": "C110540"},
                        {"label": "El Casar de Escalona", "value": "C110541"},
                        {"label": "El Pedernoso", "value": "C110542"},
                        {"label": "El Provencio", "value": "C110543"},
                        {"label": "El Puente del Arzobispo",
                         "value": "C110544"},
                        {"label": "El Real de San Vicente", "value": "C110545"},
                        {"label": "El Robledo", "value": "C110546"},
                        {"label": "El Toboso", "value": "C110547"},
                        {"label": "El Viso de San Juan", "value": "C110548"},
                        {"label": "Elche de la Sierra", "value": "C110549"},
                        {"label": "Embid", "value": "C110550"},
                        {"label": "Enguidanos", "value": "C110551"},
                        {"label": "Erustes", "value": "C110552"},
                        {"label": "Escalona", "value": "C110553"},
                        {"label": "Escalonilla", "value": "C110554"},
                        {"label": "Escamilla", "value": "C110555"},
                        {"label": "Escariche", "value": "C110556"},
                        {"label": "Escopete", "value": "C110557"},
                        {"label": "Espinosa de Henares", "value": "C110558"},
                        {"label": "Espinoso del Rey", "value": "C110559"},
                        {"label": "Esplegares", "value": "C110560"},
                        {"label": "Esquivias", "value": "C110561"},
                        {"label": "Estables", "value": "C110562"},
                        {"label": "Estriegana", "value": "C110563"},
                        {"label": "Ferez", "value": "C110564"},
                        {"label": "Fontanar", "value": "C110565"},
                        {"label": "Fontanarejo", "value": "C110566"},
                        {"label": "Fresneda de Altarejos", "value": "C110567"},
                        {"label": "Fresneda de la Sierra", "value": "C110568"},
                        {"label": "Fuembellida", "value": "C110569"},
                        {"label": "Fuencaliente", "value": "C110570"},
                        {"label": "Fuencemillan", "value": "C110571"},
                        {"label": "Fuenllana", "value": "C110572"},
                        {"label": "Fuensalida", "value": "C110573"},
                        {"label": "Fuensanta", "value": "C110574"},
                        {"label": "Fuente de Pedro Naharro",
                         "value": "C110575"},
                        {"label": "Fuente el Fresno", "value": "C110576"},
                        {"label": "Fuente-alamo", "value": "C110577"},
                        {"label": "Fuentealbilla", "value": "C110578"},
                        {"label": "Fuentelahiguera de Albatages",
                         "value": "C110579"},
                        {"label": "Fuentelencina", "value": "C110580"},
                        {"label": "Fuentelespino de Haro", "value": "C110581"},
                        {"label": "Fuentelespino de Moya", "value": "C110582"},
                        {"label": "Fuentelsaz", "value": "C110583"},
                        {"label": "Fuentelviejo", "value": "C110584"},
                        {"label": "Fuentenovilla", "value": "C110585"},
                        {"label": "Fuentes", "value": "C110586"},
                        {"label": "Fuertescusa", "value": "C110587"},
                        {"label": "Gabaldon", "value": "C110588"},
                        {"label": "Gajanejos", "value": "C110589"},
                        {"label": "Galapagos", "value": "C110590"},
                        {"label": "Galve de Sorbe", "value": "C110591"},
                        {"label": "Galvez", "value": "C110592"},
                        {"label": "Garaballa", "value": "C110593"},
                        {"label": "Gascuena", "value": "C110594"},
                        {"label": "Gascuena de Bornova", "value": "C110595"},
                        {"label": "Gerindote", "value": "C110596"},
                        {"label": "Golosalvo", "value": "C110597"},
                        {"label": "Graja de Campalbo", "value": "C110598"},
                        {"label": "Graja de Iniesta", "value": "C110599"},
                        {"label": "Guadalajara", "value": "C110600"},
                        {"label": "Guadalmez", "value": "C110601"},
                        {"label": "Guadamur", "value": "C110602"},
                        {"label": "Hellin", "value": "C110603"},
                        {"label": "Henarejos", "value": "C110604"},
                        {"label": "Henche", "value": "C110605"},
                        {"label": "Herencia", "value": "C110606"},
                        {"label": "Herreria", "value": "C110607"},
                        {"label": "Herreruela de Oropesa", "value": "C110608"},
                        {"label": "Hiendelaencina", "value": "C110609"},
                        {"label": "Higueruela", "value": "C110610"},
                        {"label": "Hijes", "value": "C110611"},
                        {"label": "Hinojosa de San Vicente",
                         "value": "C110612"},
                        {"label": "Hinojosas de Calatrava", "value": "C110613"},
                        {"label": "Hita", "value": "C110614"},
                        {"label": "Hombrados", "value": "C110615"},
                        {"label": "Honrubia", "value": "C110616"},
                        {"label": "Hontanar", "value": "C110617"},
                        {"label": "Hontanaya", "value": "C110618"},
                        {"label": "Hontecillas", "value": "C110619"},
                        {"label": "Hontoba", "value": "C110620"},
                        {"label": "Horcajo de Santiago", "value": "C110621"},
                        {"label": "Horche", "value": "C110622"},
                        {"label": "Hormigos", "value": "C110623"},
                        {"label": "Hoya-Gonzalo", "value": "C110624"},
                        {"label": "Huecas", "value": "C110625"},
                        {"label": "Huelamo", "value": "C110626"},
                        {"label": "Huelves", "value": "C110627"},
                        {"label": "Huerguina", "value": "C110628"},
                        {"label": "Huermeces del Cerro", "value": "C110629"},
                        {"label": "Huerta de la Obispalia", "value": "C110630"},
                        {"label": "Huerta de Valdecarabanos",
                         "value": "C110631"},
                        {"label": "Huerta del Marquesado", "value": "C110632"},
                        {"label": "Huertahernando", "value": "C110633"},
                        {"label": "Huete", "value": "C110634"},
                        {"label": "Hueva", "value": "C110635"},
                        {"label": "Humanes", "value": "C110636"},
                        {"label": "Illan de Vacas", "value": "C110637"},
                        {"label": "Illana", "value": "C110638"},
                        {"label": "Illescas", "value": "C110639"},
                        {"label": "Infantes", "value": "C110640"},
                        {"label": "Iniesta", "value": "C110641"},
                        {"label": "Iniestola", "value": "C110642"},
                        {"label": "Irueste", "value": "C110643"},
                        {"label": "Jadraque", "value": "C110644"},
                        {"label": "Jirueque", "value": "C110645"},
                        {"label": "Jorquera", "value": "C110646"},
                        {"label": "La Alameda de la Sagra", "value": "C110647"},
                        {"label": "La Alberca de Zancara", "value": "C110648"},
                        {"label": "La Calzada de Calatrava",
                         "value": "C110649"},
                        {"label": "La Frontera", "value": "C110650"},
                        {"label": "La Gineta", "value": "C110651"},
                        {"label": "La Guardia", "value": "C110652"},
                        {"label": "La Puebla de Almoradiel",
                         "value": "C110653"},
                        {"label": "La Puebla de Montalban", "value": "C110654"},
                        {"label": "La Pueblanueva", "value": "C110655"},
                        {"label": "La Roda", "value": "C110656"},
                        {"label": "La Solana", "value": "C110657"},
                        {"label": "La Torre de Esteban Hambran",
                         "value": "C110658"},
                        {"label": "Lagartera", "value": "C110659"},
                        {"label": "Laguna del Marquesado", "value": "C110660"},
                        {"label": "Lagunaseca", "value": "C110661"},
                        {"label": "Landete", "value": "C110662"},
                        {"label": "Las Mesas", "value": "C110663"},
                        {"label": "Las Pedroneras", "value": "C110664"},
                        {"label": "Las Ventas de Retamosa", "value": "C110665"},
                        {"label": "Layos", "value": "C110666"},
                        {"label": "Ledana", "value": "C110667"},
                        {"label": "Ledanca", "value": "C110668"},
                        {"label": "Leganiel", "value": "C110669"},
                        {"label": "Letur", "value": "C110670"},
                        {"label": "Lezuza", "value": "C110671"},
                        {"label": "Lietor", "value": "C110672"},
                        {"label": "Lillo", "value": "C110673"},
                        {"label": "Lominchar", "value": "C110674"},
                        {"label": "Loranca de Tajuna", "value": "C110675"},
                        {"label": "Los Hinojosos", "value": "C110676"},
                        {"label": "Los Navalmorales", "value": "C110677"},
                        {"label": "Los Navalucillos", "value": "C110678"},
                        {"label": "Los Yebenes", "value": "C110679"},
                        {"label": "Lucillos", "value": "C110680"},
                        {"label": "Lupiana", "value": "C110681"},
                        {"label": "Luzaga", "value": "C110682"},
                        {"label": "Luzon", "value": "C110683"},
                        {"label": "Madridejos", "value": "C110684"},
                        {"label": "Madrigueras", "value": "C110685"},
                        {"label": "Magan", "value": "C110686"},
                        {"label": "Mahora", "value": "C110687"},
                        {"label": "Majaelrayo", "value": "C110688"},
                        {"label": "Malaga del Fresno", "value": "C110689"},
                        {"label": "Malagon", "value": "C110690"},
                        {"label": "Malaguilla", "value": "C110691"},
                        {"label": "Malpica", "value": "C110692"},
                        {"label": "Mandayona", "value": "C110693"},
                        {"label": "Mantiel", "value": "C110694"},
                        {"label": "Manzanares", "value": "C110695"},
                        {"label": "Manzaneque", "value": "C110696"},
                        {"label": "Maqueda", "value": "C110697"},
                        {"label": "Maranchon", "value": "C110698"},
                        {"label": "Marchamalo", "value": "C110699"},
                        {"label": "Mariana", "value": "C110700"},
                        {"label": "Marjaliza", "value": "C110701"},
                        {"label": "Mascaraque", "value": "C110702"},
                        {"label": "Masegosa", "value": "C110703"},
                        {"label": "Masegoso", "value": "C110704"},
                        {"label": "Masegoso de Tajuna", "value": "C110705"},
                        {"label": "Matarrubia", "value": "C110706"},
                        {"label": "Matillas", "value": "C110707"},
                        {"label": "Mazarambroz", "value": "C110708"},
                        {"label": "Mazarete", "value": "C110709"},
                        {"label": "Mazuecos", "value": "C110710"},
                        {"label": "Medranda", "value": "C110711"},
                        {"label": "Megina", "value": "C110712"},
                        {"label": "Mejorada", "value": "C110713"},
                        {"label": "Membrilla", "value": "C110714"},
                        {"label": "Membrillera", "value": "C110715"},
                        {"label": "Menasalbas", "value": "C110716"},
                        {"label": "Mentrida", "value": "C110717"},
                        {"label": "Mestanza", "value": "C110718"},
                        {"label": "Miedes de Atienza", "value": "C110719"},
                        {"label": "Miguel Esteban", "value": "C110720"},
                        {"label": "Miguelturra", "value": "C110721"},
                        {"label": "Millana", "value": "C110722"},
                        {"label": "Milmarcos", "value": "C110723"},
                        {"label": "Minaya", "value": "C110724"},
                        {"label": "Minglanilla", "value": "C110725"},
                        {"label": "Mira", "value": "C110726"},
                        {"label": "Mirabueno", "value": "C110727"},
                        {"label": "Miralrio", "value": "C110728"},
                        {"label": "Mocejon", "value": "C110729"},
                        {"label": "Mochales", "value": "C110730"},
                        {"label": "Mohedas de la Jara", "value": "C110731"},
                        {"label": "Mohernando", "value": "C110732"},
                        {"label": "Molina de Aragon", "value": "C110733"},
                        {"label": "Molinicos", "value": "C110734"},
                        {"label": "Monasterio", "value": "C110735"},
                        {"label": "Mondejar", "value": "C110736"},
                        {"label": "Monreal del Llano", "value": "C110737"},
                        {"label": "Montalbanejo", "value": "C110738"},
                        {"label": "Montalbo", "value": "C110739"},
                        {"label": "Montalvos", "value": "C110740"},
                        {"label": "Montarron", "value": "C110741"},
                        {"label": "Monteagudo de las Salinas",
                         "value": "C110742"},
                        {"label": "Montealegre del Castillo",
                         "value": "C110743"},
                        {"label": "Montearagon", "value": "C110744"},
                        {"label": "Montesclaros", "value": "C110745"},
                        {"label": "Montiel", "value": "C110746"},
                        {"label": "Mora", "value": "C110747"},
                        {"label": "Moral de Calatrava", "value": "C110748"},
                        {"label": "Moratilla de los Meleros",
                         "value": "C110749"},
                        {"label": "Morenilla", "value": "C110750"},
                        {"label": "Mota de Altarejos", "value": "C110751"},
                        {"label": "Mota del Cuervo", "value": "C110752"},
                        {"label": "Motilla del Palancar", "value": "C110753"},
                        {"label": "Motilleja", "value": "C110754"},
                        {"label": "Moya", "value": "C110755"},
                        {"label": "Muduex", "value": "C110756"},
                        {"label": "Munera", "value": "C110757"},
                        {"label": "Nambroca", "value": "C110758"},
                        {"label": "Narboneta", "value": "C110759"},
                        {"label": "Navahermosa", "value": "C110760"},
                        {"label": "Navalcan", "value": "C110761"},
                        {"label": "Navalmoralejo", "value": "C110762"},
                        {"label": "Navalpino", "value": "C110763"},
                        {"label": "Navamorcuende", "value": "C110764"},
                        {"label": "Navas de Estena", "value": "C110765"},
                        {"label": "Navas de Jorquera", "value": "C110766"},
                        {"label": "Negredo", "value": "C110767"},
                        {"label": "Nerpio", "value": "C110768"},
                        {"label": "Noblejas", "value": "C110769"},
                        {"label": "Noez", "value": "C110770"},
                        {"label": "Nombela", "value": "C110771"},
                        {"label": "Noves", "value": "C110772"},
                        {"label": "Numancia de la Sagra", "value": "C110773"},
                        {"label": "Nuno Gomez", "value": "C110774"},
                        {"label": "Ocana", "value": "C110775"},
                        {"label": "Ocentejo", "value": "C110776"},
                        {"label": "Olias del Rey", "value": "C110777"},
                        {"label": "Olivares de Jucar", "value": "C110778"},
                        {"label": "Olmeda de Cobeta", "value": "C110779"},
                        {"label": "Olmeda de la Cuesta", "value": "C110780"},
                        {"label": "Olmeda del Rey", "value": "C110781"},
                        {"label": "Olmedilla de Alarcon", "value": "C110782"},
                        {"label": "Olmedilla de Eliz", "value": "C110783"},
                        {"label": "Ontigola", "value": "C110784"},
                        {"label": "Ontur", "value": "C110785"},
                        {"label": "Orea", "value": "C110786"},
                        {"label": "Orgaz", "value": "C110787"},
                        {"label": "Oropesa", "value": "C110788"},
                        {"label": "Osa de la Vega", "value": "C110789"},
                        {"label": "Ossa de Montiel", "value": "C110790"},
                        {"label": "Otero", "value": "C110791"},
                        {"label": "Pajaron", "value": "C110792"},
                        {"label": "Pajaroncillo", "value": "C110793"},
                        {"label": "Palmaces de Jadraque", "value": "C110794"},
                        {"label": "Palomares del Campo", "value": "C110795"},
                        {"label": "Palomeque", "value": "C110796"},
                        {"label": "Pantoja", "value": "C110797"},
                        {"label": "Paracuellos", "value": "C110798"},
                        {"label": "Paredes", "value": "C110799"},
                        {"label": "Paredes de Escalona", "value": "C110800"},
                        {"label": "Paredes de Siguenza", "value": "C110801"},
                        {"label": "Pareja", "value": "C110802"},
                        {"label": "Parrillas", "value": "C110803"},
                        {"label": "Pastrana", "value": "C110804"},
                        {"label": "Paterna del Madera", "value": "C110805"},
                        {"label": "Pedro Munoz", "value": "C110806"},
                        {"label": "Pelahustan", "value": "C110807"},
                        {"label": "Penalen", "value": "C110808"},
                        {"label": "Penalver", "value": "C110809"},
                        {"label": "Penas de San Pedro", "value": "C110810"},
                        {"label": "Peralejos de las Truchas",
                         "value": "C110811"},
                        {"label": "Peralveche", "value": "C110812"},
                        {"label": "Petrola", "value": "C110813"},
                        {"label": "Picon", "value": "C110814"},
                        {"label": "Piedrabuena", "value": "C110815"},
                        {"label": "Pinarejo", "value": "C110816"},
                        {"label": "Pineda de Giguela", "value": "C110817"},
                        {"label": "Pinilla de Jadraque", "value": "C110818"},
                        {"label": "Pinilla de Molina", "value": "C110819"},
                        {"label": "Pioz", "value": "C110820"},
                        {"label": "Piqueras", "value": "C110821"},
                        {"label": "Piqueras del Castillo", "value": "C110822"},
                        {"label": "Poblete", "value": "C110823"},
                        {"label": "Polan", "value": "C110824"},
                        {"label": "Portilla", "value": "C110825"},
                        {"label": "Portillo de Toledo", "value": "C110826"},
                        {"label": "Porzuna", "value": "C110827"},
                        {"label": "Poveda de la Sierra", "value": "C110828"},
                        {"label": "Povedilla", "value": "C110829"},
                        {"label": "Pozo de Almoguera", "value": "C110830"},
                        {"label": "Pozo de Guadalajara", "value": "C110831"},
                        {"label": "Pozo-Canada", "value": "C110832"},
                        {"label": "Pozoamargo", "value": "C110833"},
                        {"label": "Pozohondo", "value": "C110834"},
                        {"label": "Pozorrubio", "value": "C110835"},
                        {"label": "Pozuelo", "value": "C110836"},
                        {"label": "Pozuelo de Calatrava", "value": "C110837"},
                        {"label": "Pradena de Atienza", "value": "C110838"},
                        {"label": "Prados Redondos", "value": "C110839"},
                        {"label": "Priego", "value": "C110840"},
                        {"label": "Province of Toledo", "value": "C110841"},
                        {"label": "Provincia de Albacete", "value": "C110842"},
                        {"label": "Provincia de Ciudad Real",
                         "value": "C110843"},
                        {"label": "Provincia de Cuenca", "value": "C110844"},
                        {"label": "Provincia de Guadalajara",
                         "value": "C110845"},
                        {"label": "Puebla de Almenara", "value": "C110846"},
                        {"label": "Puebla de Belena", "value": "C110847"},
                        {"label": "Puebla de Don Rodrigo", "value": "C110848"},
                        {"label": "Puebla del Principe", "value": "C110849"},
                        {"label": "Puebla del Salvador", "value": "C110850"},
                        {"label": "Puerto de San Vicente", "value": "C110851"},
                        {"label": "Puerto Lapice", "value": "C110852"},
                        {"label": "Puertollano", "value": "C110853"},
                        {"label": "Pulgar", "value": "C110854"},
                        {"label": "Quer", "value": "C110855"},
                        {"label": "Quero", "value": "C110856"},
                        {"label": "Quintanar de la Orden", "value": "C110857"},
                        {"label": "Quintanar del Rey", "value": "C110858"},
                        {"label": "Quismondo", "value": "C110859"},
                        {"label": "Rada de Haro", "value": "C110860"},
                        {"label": "Rebollosa de Jadraque", "value": "C110861"},
                        {"label": "Recas", "value": "C110862"},
                        {"label": "Reillo", "value": "C110863"},
                        {"label": "Renera", "value": "C110864"},
                        {"label": "Retiendas", "value": "C110865"},
                        {"label": "Retuerta de Bullaque", "value": "C110866"},
                        {"label": "Riba de Saelices", "value": "C110867"},
                        {"label": "Rielves", "value": "C110868"},
                        {"label": "Rillo de Gallo", "value": "C110869"},
                        {"label": "Riopar", "value": "C110870"},
                        {"label": "Robledillo de Mohernando",
                         "value": "C110871"},
                        {"label": "Robledo", "value": "C110872"},
                        {"label": "Robledo de Corpes", "value": "C110873"},
                        {"label": "Romanillos de Atienza", "value": "C110874"},
                        {"label": "Romanones", "value": "C110875"},
                        {"label": "Rozalen del Monte", "value": "C110876"},
                        {"label": "Rueda de la Sierra", "value": "C110877"},
                        {"label": "Ruidera", "value": "C110878"},
                        {"label": "Sacecorbo", "value": "C110879"},
                        {"label": "Saceda-Trasierra", "value": "C110880"},
                        {"label": "Sacedon", "value": "C110881"},
                        {"label": "Saceruela", "value": "C110882"},
                        {"label": "Saelices", "value": "C110883"},
                        {"label": "Saelices de la Sal", "value": "C110884"},
                        {"label": "Salinas del Manzano", "value": "C110885"},
                        {"label": "Salmeron", "value": "C110886"},
                        {"label": "Salobre", "value": "C110887"},
                        {"label": "Salvacanete", "value": "C110888"},
                        {"label": "San Andres del Congosto",
                         "value": "C110889"},
                        {"label": "San Andres del Rey", "value": "C110890"},
                        {"label": "San Bartolome de las Abiertas",
                         "value": "C110891"},
                        {"label": "San Carlos del Valle", "value": "C110892"},
                        {"label": "San Clemente", "value": "C110893"},
                        {"label": "San Lorenzo de Calatrava",
                         "value": "C110894"},
                        {"label": "San Lorenzo de la Parrilla",
                         "value": "C110895"},
                        {"label": "San Martin de Boniches", "value": "C110896"},
                        {"label": "San Martin de Montalban",
                         "value": "C110897"},
                        {"label": "San Martin de Pusa", "value": "C110898"},
                        {"label": "San Pedro", "value": "C110899"},
                        {"label": "San Pedro Palmiches", "value": "C110900"},
                        {"label": "Santa Ana de Pusa", "value": "C110901"},
                        {"label": "Santa Cruz de la Zarza", "value": "C110902"},
                        {"label": "Santa Cruz de los Canamos",
                         "value": "C110903"},
                        {"label": "Santa Cruz de Moya", "value": "C110904"},
                        {"label": "Santa Cruz de Mudela", "value": "C110905"},
                        {"label": "Santa Cruz del Retamar", "value": "C110906"},
                        {"label": "Santa Maria del Campo Rus",
                         "value": "C110907"},
                        {"label": "Santa Maria del Val", "value": "C110908"},
                        {"label": "Santa Olalla", "value": "C110909"},
                        {"label": "Santiuste", "value": "C110910"},
                        {"label": "Sartajada", "value": "C110911"},
                        {"label": "Sauca", "value": "C110912"},
                        {"label": "Sayaton", "value": "C110913"},
                        {"label": "Segurilla", "value": "C110914"},
                        {"label": "Selas", "value": "C110915"},
                        {"label": "Semillas", "value": "C110916"},
                        {"label": "Sesena", "value": "C110917"},
                        {"label": "Setiles", "value": "C110918"},
                        {"label": "Sevilleja de la Jara", "value": "C110919"},
                        {"label": "Sienes", "value": "C110920"},
                        {"label": "Siguenza", "value": "C110921"},
                        {"label": "Sisante", "value": "C110922"},
                        {"label": "Socovos", "value": "C110923"},
                        {"label": "Socuellamos", "value": "C110924"},
                        {"label": "Solanillos del Extremo", "value": "C110925"},
                        {"label": "Somolinos", "value": "C110926"},
                        {"label": "Sonseca", "value": "C110927"},
                        {"label": "Sotillo de las Palomas", "value": "C110928"},
                        {"label": "Sotodosos", "value": "C110929"},
                        {"label": "Talavera de la Reina", "value": "C110930"},
                        {"label": "Tamajon", "value": "C110931"},
                        {"label": "Taragudo", "value": "C110932"},
                        {"label": "Tarancon", "value": "C110933"},
                        {"label": "Taravilla", "value": "C110934"},
                        {"label": "Tarazona de la Mancha", "value": "C110935"},
                        {"label": "Tartanedo", "value": "C110936"},
                        {"label": "Tebar", "value": "C110937"},
                        {"label": "Tejadillos", "value": "C110938"},
                        {"label": "Tembleque", "value": "C110939"},
                        {"label": "Tendilla", "value": "C110940"},
                        {"label": "Terrinches", "value": "C110941"},
                        {"label": "Terzaga", "value": "C110942"},
                        {"label": "Tierzo", "value": "C110943"},
                        {"label": "Tinajas", "value": "C110944"},
                        {"label": "Tobarra", "value": "C110945"},
                        {"label": "Toledo", "value": "C110946"},
                        {"label": "Tomelloso", "value": "C110947"},
                        {"label": "Tordellego", "value": "C110948"},
                        {"label": "Tordelrabano", "value": "C110949"},
                        {"label": "Tordesilos", "value": "C110950"},
                        {"label": "Torija", "value": "C110951"},
                        {"label": "Torralba", "value": "C110952"},
                        {"label": "Torralba de Calatrava", "value": "C110953"},
                        {"label": "Torralba de Oropesa", "value": "C110954"},
                        {"label": "Torre de Juan Abad", "value": "C110955"},
                        {"label": "Torre del Burgo", "value": "C110956"},
                        {"label": "Torrecilla de la Jara", "value": "C110957"},
                        {"label": "Torrecuadrada de Molina",
                         "value": "C110958"},
                        {"label": "Torrecuadradilla", "value": "C110959"},
                        {"label": "Torrejon del Rey", "value": "C110960"},
                        {"label": "Torrejoncillo del Rey", "value": "C110961"},
                        {"label": "Torremocha de Jadraque", "value": "C110962"},
                        {"label": "Torremocha del Campo", "value": "C110963"},
                        {"label": "Torremocha del Pinar", "value": "C110964"},
                        {"label": "Torremochuela", "value": "C110965"},
                        {"label": "Torrenueva", "value": "C110966"},
                        {"label": "Torrico", "value": "C110967"},
                        {"label": "Torrijos", "value": "C110968"},
                        {"label": "Torrubia", "value": "C110969"},
                        {"label": "Torrubia del Campo", "value": "C110970"},
                        {"label": "Torrubia del Castillo", "value": "C110971"},
                        {"label": "Tortola de Henares", "value": "C110972"},
                        {"label": "Tortuera", "value": "C110973"},
                        {"label": "Tortuero", "value": "C110974"},
                        {"label": "Totanes", "value": "C110975"},
                        {"label": "Tresjuncos", "value": "C110976"},
                        {"label": "Tribaldos", "value": "C110977"},
                        {"label": "Trijueque", "value": "C110978"},
                        {"label": "Trillo", "value": "C110979"},
                        {"label": "Turleque", "value": "C110980"},
                        {"label": "Uceda", "value": "C110981"},
                        {"label": "Ucles", "value": "C110982"},
                        {"label": "Ugena", "value": "C110983"},
                        {"label": "Ujados", "value": "C110984"},
                        {"label": "Una", "value": "C110985"},
                        {"label": "Urda", "value": "C110986"},
                        {"label": "Utande", "value": "C110987"},
                        {"label": "Valdarachas", "value": "C110988"},
                        {"label": "Valdearenas", "value": "C110989"},
                        {"label": "Valdeavellano", "value": "C110990"},
                        {"label": "Valdeaveruelo", "value": "C110991"},
                        {"label": "Valdeconcha", "value": "C110992"},
                        {"label": "Valdeganga", "value": "C110993"},
                        {"label": "Valdegrudas", "value": "C110994"},
                        {"label": "Valdelcubo", "value": "C110995"},
                        {"label": "Valdemanco del Esteras", "value": "C110996"},
                        {"label": "Valdemeca", "value": "C110997"},
                        {"label": "Valdemorillo de la Sierra",
                         "value": "C110998"},
                        {"label": "Valdemoro-Sierra", "value": "C110999"},
                        {"label": "Valdeolivas", "value": "C111000"},
                        {"label": "Valdepenas", "value": "C111001"},
                        {"label": "Valdepenas de la Sierra",
                         "value": "C111002"},
                        {"label": "Valderrebollo", "value": "C111003"},
                        {"label": "Valdesotos", "value": "C111004"},
                        {"label": "Valdeverdeja", "value": "C111005"},
                        {"label": "Valenzuela de Calatrava",
                         "value": "C111006"},
                        {"label": "Valfermoso de Tajuna", "value": "C111007"},
                        {"label": "Valhermoso", "value": "C111008"},
                        {"label": "Valhermoso de la Fuente",
                         "value": "C111009"},
                        {"label": "Valmojado", "value": "C111010"},
                        {"label": "Valsalobre", "value": "C111011"},
                        {"label": "Valtablado del Rio", "value": "C111012"},
                        {"label": "Valverde de Jucar", "value": "C111013"},
                        {"label": "Valverde de los Arroyos",
                         "value": "C111014"},
                        {"label": "Valverdejo", "value": "C111015"},
                        {"label": "Vara de Rey", "value": "C111016"},
                        {"label": "Vega del Codorno", "value": "C111017"},
                        {"label": "Velada", "value": "C111018"},
                        {"label": "Vellisca", "value": "C111019"},
                        {"label": "Ventas con Pena Aguilera",
                         "value": "C111020"},
                        {"label": "Viana de Jadraque", "value": "C111021"},
                        {"label": "Villa de Don Fadrique", "value": "C111022"},
                        {"label": "Villa de Ves", "value": "C111023"},
                        {"label": "Villacanas", "value": "C111024"},
                        {"label": "Villaconejos de Trabaque",
                         "value": "C111025"},
                        {"label": "Villafranca de los Caballeros",
                         "value": "C111026"},
                        {"label": "Villagarcia del Llano", "value": "C111027"},
                        {"label": "Villagordo del Jucar", "value": "C111028"},
                        {"label": "Villahermosa", "value": "C111029"},
                        {"label": "Villalba de la Sierra", "value": "C111030"},
                        {"label": "Villalba del Rey", "value": "C111031"},
                        {"label": "Villalgordo del Marquesado",
                         "value": "C111032"},
                        {"label": "Villalpardo", "value": "C111033"},
                        {"label": "Villamalea", "value": "C111034"},
                        {"label": "Villamanrique", "value": "C111035"},
                        {"label": "Villamayor de Calatrava",
                         "value": "C111036"},
                        {"label": "Villamayor de Santiago", "value": "C111037"},
                        {"label": "Villamiel de Toledo", "value": "C111038"},
                        {"label": "Villaminaya", "value": "C111039"},
                        {"label": "Villamuelas", "value": "C111040"},
                        {"label": "Villanueva de Alcardete",
                         "value": "C111041"},
                        {"label": "Villanueva de Alcoron", "value": "C111042"},
                        {"label": "Villanueva de Argecilla",
                         "value": "C111043"},
                        {"label": "Villanueva de Bogas", "value": "C111044"},
                        {"label": "Villanueva de la Fuente",
                         "value": "C111045"},
                        {"label": "Villanueva de la Jara", "value": "C111046"},
                        {"label": "Villanueva de la Torre", "value": "C111047"},
                        {"label": "Villanueva de San Carlos",
                         "value": "C111048"},
                        {"label": "Villapalacios", "value": "C111049"},
                        {"label": "Villar de Canas", "value": "C111050"},
                        {"label": "Villar de Domingo Garcia",
                         "value": "C111051"},
                        {"label": "Villar de la Encina", "value": "C111052"},
                        {"label": "Villar de Olalla", "value": "C111053"},
                        {"label": "Villar del Humo", "value": "C111054"},
                        {"label": "Villar del Infantado", "value": "C111055"},
                        {"label": "Villar del Pozo", "value": "C111056"},
                        {"label": "Villarejo de Fuentes", "value": "C111057"},
                        {"label": "Villarejo de la Penuela",
                         "value": "C111058"},
                        {"label": "Villarejo de Montalban", "value": "C111059"},
                        {"label": "Villarejo-Periesteban", "value": "C111060"},
                        {"label": "Villares de Jadraque", "value": "C111061"},
                        {"label": "Villares del Saz", "value": "C111062"},
                        {"label": "Villarrobledo", "value": "C111063"},
                        {"label": "Villarrubia de los Ojos",
                         "value": "C111064"},
                        {"label": "Villarrubia de Santiago",
                         "value": "C111065"},
                        {"label": "Villarrubio", "value": "C111066"},
                        {"label": "Villarta", "value": "C111067"},
                        {"label": "Villarta de San Juan", "value": "C111068"},
                        {"label": "Villaseca de Henares", "value": "C111069"},
                        {"label": "Villaseca de la Sagra", "value": "C111070"},
                        {"label": "Villaseca de Uceda", "value": "C111071"},
                        {"label": "Villasequilla de Yepes", "value": "C111072"},
                        {"label": "Villatobas", "value": "C111073"},
                        {"label": "Villatoya", "value": "C111074"},
                        {"label": "Villavaliente", "value": "C111075"},
                        {"label": "Villaverde de Guadalimar",
                         "value": "C111076"},
                        {"label": "Villaverde y Pasaconsol",
                         "value": "C111077"},
                        {"label": "Villel de Mesa", "value": "C111078"},
                        {"label": "Vindel", "value": "C111079"},
                        {"label": "Vinuelas", "value": "C111080"},
                        {"label": "Viso del Marques", "value": "C111081"},
                        {"label": "Viveros", "value": "C111082"},
                        {"label": "Yebes", "value": "C111083"},
                        {"label": "Yebra", "value": "C111084"},
                        {"label": "Yelamos de Abajo", "value": "C111085"},
                        {"label": "Yelamos de Arriba", "value": "C111086"},
                        {"label": "Yeles", "value": "C111087"},
                        {"label": "Yemeda", "value": "C111088"},
                        {"label": "Yepes", "value": "C111089"},
                        {"label": "Yeste", "value": "C111090"},
                        {"label": "Yuncler", "value": "C111091"},
                        {"label": "Yuncos", "value": "C111092"},
                        {"label": "Yunquera de Henares", "value": "C111093"},
                        {"label": "Zafra de Zancara", "value": "C111094"},
                        {"label": "Zafrilla", "value": "C111095"},
                        {"label": "Zaorejas", "value": "C111096"},
                        {"label": "Zarza de Tajo", "value": "C111097"},
                        {"label": "Zarzuela", "value": "C111098"},
                        {"label": "Zarzuela de Jadraque", "value": "C111099"},
                        {"label": "Zorita de los Canes", "value": "C111100"}],
 "Castries Quarter": [{"label": "Active Hill", "value": "C105768"},
                      {"label": "Agard LandsMorne Dudon", "value": "C105769"},
                      {"label": "Almondale", "value": "C105770"},
                      {"label": "Aurendel Hill", "value": "C105771"},
                      {"label": "Babonneau Proper", "value": "C105772"},
                      {"label": "Bagatelle", "value": "C105773"},
                      {"label": "Balata", "value": "C105774"},
                      {"label": "Banannes Bay", "value": "C105775"},
                      {"label": "Barnard Hill", "value": "C105776"},
                      {"label": "Barre Denis", "value": "C105777"},
                      {"label": "Barre Duchaussee", "value": "C105778"},
                      {"label": "Barre St.Joseph", "value": "C105779"},
                      {"label": "Belair", "value": "C105780"},
                      {"label": "Bella Rosa", "value": "C105781"},
                      {"label": "Bexon", "value": "C105782"},
                      {"label": "Bisee", "value": "C105783"},
                      {"label": "BishopS Gap", "value": "C105784"},
                      {"label": "Bissee", "value": "C105785"},
                      {"label": "Black Mallet", "value": "C105786"},
                      {"label": "Bocage", "value": "C105787"},
                      {"label": "Bois Catchet", "value": "C105788"},
                      {"label": "Bois Patat", "value": "C105789"},
                      {"label": "CabicheBabonneau", "value": "C105790"},
                      {"label": "CacoaBabonneau", "value": "C105791"},
                      {"label": "Calvary", "value": "C105792"},
                      {"label": "Capital Hill", "value": "C105793"},
                      {"label": "Carellie", "value": "C105794"},
                      {"label": "Castries", "value": "C105795"},
                      {"label": "Cedars", "value": "C105796"},
                      {"label": "Chase Gardens", "value": "C105797"},
                      {"label": "ChassinBabonneau", "value": "C105798"},
                      {"label": "Choppin RidgeSarot", "value": "C105799"},
                      {"label": "Ciceron", "value": "C105800"},
                      {"label": "City", "value": "C105801"},
                      {"label": "City GateLa Clery", "value": "C105802"},
                      {"label": "ConwayWaterfront", "value": "C105803"},
                      {"label": "Coolie Town", "value": "C105804"},
                      {"label": "Coubaril", "value": "C105805"},
                      {"label": "CrownlandsMarc", "value": "C105806"},
                      {"label": "Cul De Sac", "value": "C105807"},
                      {"label": "Darling Road", "value": "C105808"},
                      {"label": "Deglos", "value": "C105809"},
                      {"label": "Derierre FortOld Victoria Road",
                       "value": "C105810"},
                      {"label": "Dubrassay", "value": "C105811"},
                      {"label": "En Pois DouxBabonneau", "value": "C105812"},
                      {"label": "Entrepot", "value": "C105813"},
                      {"label": "Faux A Chaud", "value": "C105814"},
                      {"label": "Ferrand", "value": "C105815"},
                      {"label": "FloissacMarc", "value": "C105816"},
                      {"label": "Fond AssauBabonneau", "value": "C105817"},
                      {"label": "Fond Canie", "value": "C105818"},
                      {"label": "Forestiere", "value": "C105819"},
                      {"label": "George Charles Boulevard", "value": "C105820"},
                      {"label": "Girard", "value": "C105821"},
                      {"label": "Goergeville", "value": "C105822"},
                      {"label": "Goodlands", "value": "C105823"},
                      {"label": "Grass Street", "value": "C105824"},
                      {"label": "Green Gold", "value": "C105825"},
                      {"label": "Guesneau", "value": "C105826"},
                      {"label": "Hill 20Babonneau", "value": "C105827"},
                      {"label": "Hillcrest Gardens", "value": "C105828"},
                      {"label": "Hospital Road", "value": "C105829"},
                      {"label": "Independence City", "value": "C105830"},
                      {"label": "John Compton Highway", "value": "C105831"},
                      {"label": "LAnse Road", "value": "C105832"},
                      {"label": "La Carierre", "value": "C105833"},
                      {"label": "La Clery", "value": "C105834"},
                      {"label": "La Croix Maingot", "value": "C105835"},
                      {"label": "La Pansee", "value": "C105836"},
                      {"label": "La Toc", "value": "C105837"},
                      {"label": "Labayee", "value": "C105838"},
                      {"label": "Lastic Hill", "value": "C105839"},
                      {"label": "Leslie Land", "value": "C105840"},
                      {"label": "Marc", "value": "C105841"},
                      {"label": "Marchand", "value": "C105842"},
                      {"label": "Marigot", "value": "C105843"},
                      {"label": "Maynard Hill", "value": "C105844"},
                      {"label": "MiamiBexon", "value": "C105845"},
                      {"label": "Monkey Town Ciceron", "value": "C105846"},
                      {"label": "Morne AssauBabonneau", "value": "C105847"},
                      {"label": "Morne Dudon", "value": "C105848"},
                      {"label": "Morne Road", "value": "C105849"},
                      {"label": "Morne RougeMarc", "value": "C105850"},
                      {"label": "Mount Pleasant", "value": "C105851"},
                      {"label": "New Village", "value": "C105852"},
                      {"label": "Odsan", "value": "C105853"},
                      {"label": "ParkerS Hill", "value": "C105854"},
                      {"label": "PattersonS Gap", "value": "C105855"},
                      {"label": "Pavee", "value": "C105856"},
                      {"label": "PeartS Gap", "value": "C105857"},
                      {"label": "Perou", "value": "C105858"},
                      {"label": "Pointe Seraphine", "value": "C105859"},
                      {"label": "Quarte Chemins", "value": "C105860"},
                      {"label": "Ravine Chabot", "value": "C105861"},
                      {"label": "Ravine Poisson", "value": "C105862"},
                      {"label": "Ravine Touterelle", "value": "C105863"},
                      {"label": "ResinardBabonneau", "value": "C105864"},
                      {"label": "Rock Hall", "value": "C105865"},
                      {"label": "Rose Hill", "value": "C105866"},
                      {"label": "San Soucis", "value": "C105867"},
                      {"label": "Sand De Feu", "value": "C105868"},
                      {"label": "Sarot", "value": "C105869"},
                      {"label": "Summersdale", "value": "C105870"},
                      {"label": "Sunbilt", "value": "C105871"},
                      {"label": "Sunny Acres", "value": "C105872"},
                      {"label": "Talvern", "value": "C105873"},
                      {"label": "Tapion", "value": "C105874"},
                      {"label": "The Morne", "value": "C105875"},
                      {"label": "Ti Colon", "value": "C105876"},
                      {"label": "Ti Rocher", "value": "C105877"},
                      {"label": "Trois Piton", "value": "C105878"},
                      {"label": "Trou CochanMarc", "value": "C105879"},
                      {"label": "Trou FlorentMarc", "value": "C105880"},
                      {"label": "Trou Rouge", "value": "C105881"},
                      {"label": "Vide Bouteille", "value": "C105882"},
                      {"label": "Vigie", "value": "C105883"},
                      {"label": "Waterworks", "value": "C105884"},
                      {"label": "WiltonS YardGrave Yard", "value": "C105885"},
                      {"label": "Yorke Hill", "value": "C105886"}],
 "Cat Island": [{"label": "Arthurs Town", "value": "C119605"}],
 "Catalonia": [{"label": "Abrera", "value": "C111101"},
               {"label": "Ager", "value": "C111102"},
               {"label": "Agramunt", "value": "C111103"},
               {"label": "Aguilar de Segarra", "value": "C111104"},
               {"label": "Agullana", "value": "C111105"},
               {"label": "Aiguafreda", "value": "C111106"},
               {"label": "Aiguaviva", "value": "C111107"},
               {"label": "Aitona", "value": "C111108"},
               {"label": "Alas i Cerc", "value": "C111109"},
               {"label": "Albesa", "value": "C111110"},
               {"label": "Albons", "value": "C111111"},
               {"label": "Alcanar", "value": "C111112"},
               {"label": "Alcano", "value": "C111113"},
               {"label": "Alcarras", "value": "C111114"},
               {"label": "Alcoletge", "value": "C111115"},
               {"label": "Alcover", "value": "C111116"},
               {"label": "Alella", "value": "C111117"},
               {"label": "Alfarras", "value": "C111118"},
               {"label": "Alfes", "value": "C111119"},
               {"label": "Alforja", "value": "C111120"},
               {"label": "Algerri", "value": "C111121"},
               {"label": "Alguaire", "value": "C111122"},
               {"label": "Alio", "value": "C111123"},
               {"label": "Almacelles", "value": "C111124"},
               {"label": "Almenar", "value": "C111125"},
               {"label": "Almoster", "value": "C111126"},
               {"label": "Alpens", "value": "C111127"},
               {"label": "Alpicat", "value": "C111128"},
               {"label": "Altafulla", "value": "C111129"},
               {"label": "Amposta", "value": "C111130"},
               {"label": "Angles", "value": "C111131"},
               {"label": "Anglesola", "value": "C111132"},
               {"label": "Arbeca", "value": "C111133"},
               {"label": "Arbucies", "value": "C111134"},
               {"label": "Arenys de Mar", "value": "C111135"},
               {"label": "Arenys de Munt", "value": "C111136"},
               {"label": "Argelaguer", "value": "C111137"},
               {"label": "Argentona", "value": "C111138"},
               {"label": "Artes", "value": "C111139"},
               {"label": "Artesa de Segre", "value": "C111140"},
               {"label": "Asco", "value": "C111141"},
               {"label": "Aspa", "value": "C111142"},
               {"label": "Avinyo", "value": "C111143"},
               {"label": "Badalona", "value": "C111144"},
               {"label": "Baga", "value": "C111145"},
               {"label": "Balaguer", "value": "C111146"},
               {"label": "Balsareny", "value": "C111147"},
               {"label": "Banyoles", "value": "C111148"},
               {"label": "Barbera del Valles", "value": "C111149"},
               {"label": "Barcelona", "value": "C111150"},
               {"label": "Baro de Viver", "value": "C111151"},
               {"label": "Barri de les Corts", "value": "C111152"},
               {"label": "Barri de Sant Andreu", "value": "C111153"},
               {"label": "Barri Gotic", "value": "C111154"},
               {"label": "Bascara", "value": "C111155"},
               {"label": "Batea", "value": "C111156"},
               {"label": "Begues", "value": "C111157"},
               {"label": "Begur", "value": "C111158"},
               {"label": "Bellcaire dUrgell", "value": "C111159"},
               {"label": "Bellprat", "value": "C111160"},
               {"label": "Bellpuig", "value": "C111161"},
               {"label": "Bellver de Cerdanya", "value": "C111162"},
               {"label": "Bellvis", "value": "C111163"},
               {"label": "Benifallet", "value": "C111164"},
               {"label": "Berga", "value": "C111165"},
               {"label": "Besalu", "value": "C111166"},
               {"label": "Bescano", "value": "C111167"},
               {"label": "Beuda", "value": "C111168"},
               {"label": "Bigues i Riells", "value": "C111169"},
               {"label": "Blancafort", "value": "C111170"},
               {"label": "Blanes", "value": "C111171"},
               {"label": "Bolvir", "value": "C111172"},
               {"label": "Bonastre", "value": "C111173"},
               {"label": "Bordils", "value": "C111174"},
               {"label": "Borrassa", "value": "C111175"},
               {"label": "Bot", "value": "C111176"},
               {"label": "Botarell", "value": "C111177"},
               {"label": "Breda", "value": "C111178"},
               {"label": "Cabanelles", "value": "C111179"},
               {"label": "Cabrera de Mar", "value": "C111180"},
               {"label": "Cabrils", "value": "C111181"},
               {"label": "Cadaques", "value": "C111182"},
               {"label": "Calaf", "value": "C111183"},
               {"label": "Calafell", "value": "C111184"},
               {"label": "Caldes de Montbui", "value": "C111185"},
               {"label": "Calella", "value": "C111186"},
               {"label": "Calonge", "value": "C111187"},
               {"label": "Camarasa", "value": "C111188"},
               {"label": "Cambrils", "value": "C111189"},
               {"label": "Camos", "value": "C111190"},
               {"label": "Campdevanol", "value": "C111191"},
               {"label": "Campins", "value": "C111192"},
               {"label": "Camprodon", "value": "C111193"},
               {"label": "Can Baro", "value": "C111194"},
               {"label": "Can Peguera", "value": "C111195"},
               {"label": "Canet de Mar", "value": "C111196"},
               {"label": "Canovelles", "value": "C111197"},
               {"label": "Canoves i Samalus", "value": "C111198"},
               {"label": "Cantallops", "value": "C111199"},
               {"label": "Canyelles", "value": "C111200"},
               {"label": "Capafonts", "value": "C111201"},
               {"label": "Capellades", "value": "C111202"},
               {"label": "Capmany", "value": "C111203"},
               {"label": "Cardedeu", "value": "C111204"},
               {"label": "Cardona", "value": "C111205"},
               {"label": "Cassa de la Selva", "value": "C111206"},
               {"label": "Castell-Platja dAro", "value": "C111207"},
               {"label": "Castellar del Valles", "value": "C111208"},
               {"label": "Castellbisbal", "value": "C111209"},
               {"label": "Castellcir", "value": "C111210"},
               {"label": "Castelldefels", "value": "C111211"},
               {"label": "Castellet", "value": "C111212"},
               {"label": "Castellfollit de Riubregos", "value": "C111213"},
               {"label": "Castellnou de Seana", "value": "C111214"},
               {"label": "Castello dEmpuries", "value": "C111215"},
               {"label": "Castelloli", "value": "C111216"},
               {"label": "Castellsera", "value": "C111217"},
               {"label": "Castellvi de Rosanes", "value": "C111218"},
               {"label": "Celra", "value": "C111219"},
               {"label": "Centelles", "value": "C111220"},
               {"label": "Cerdanyola del Valles", "value": "C111221"},
               {"label": "Cervello", "value": "C111222"},
               {"label": "Cervera", "value": "C111223"},
               {"label": "Cervia de Ter", "value": "C111224"},
               {"label": "Cistella", "value": "C111225"},
               {"label": "Ciutadilla", "value": "C111226"},
               {"label": "Ciutat Meridiana", "value": "C111227"},
               {"label": "Ciutat Vella", "value": "C111228"},
               {"label": "Colera", "value": "C111229"},
               {"label": "Coll de Nargo", "value": "C111230"},
               {"label": "Collbato", "value": "C111231"},
               {"label": "Colldejou", "value": "C111232"},
               {"label": "Collsuspina", "value": "C111233"},
               {"label": "Coma-ruga", "value": "C111234"},
               {"label": "Conesa", "value": "C111235"},
               {"label": "Constanti", "value": "C111236"},
               {"label": "Corbera de Llobregat", "value": "C111237"},
               {"label": "Corbins", "value": "C111238"},
               {"label": "Cornella de Llobregat", "value": "C111239"},
               {"label": "Cornella del Terri", "value": "C111240"},
               {"label": "Creixell", "value": "C111241"},
               {"label": "Crespia", "value": "C111242"},
               {"label": "Cruilles", "value": "C111243"},
               {"label": "Cubelles", "value": "C111244"},
               {"label": "Cubells", "value": "C111245"},
               {"label": "Cunit", "value": "C111246"},
               {"label": "Darnius", "value": "C111247"},
               {"label": "Deltebre", "value": "C111248"},
               {"label": "Diagonal Mar", "value": "C111249"},
               {"label": "Dosrius", "value": "C111250"},
               {"label": "Dreta de lEixample", "value": "C111251"},
               {"label": "Eixample", "value": "C111252"},
               {"label": "el Baix Guinardo", "value": "C111253"},
               {"label": "el Besos i el Maresme", "value": "C111254"},
               {"label": "el Bon Pastor", "value": "C111255"},
               {"label": "el Camp den Grassot i Gracia Nova",
                "value": "C111256"},
               {"label": "el Camp de lArpa del Clot", "value": "C111257"},
               {"label": "El Carmel", "value": "C111258"},
               {"label": "el Catllar", "value": "C111259"},
               {"label": "el Clot", "value": "C111260"},
               {"label": "el Cogul", "value": "C111261"},
               {"label": "el Coll", "value": "C111262"},
               {"label": "el Congres i els Indians", "value": "C111263"},
               {"label": "el Guinardo", "value": "C111264"},
               {"label": "El Masnou", "value": "C111265"},
               {"label": "el Masroig", "value": "C111266"},
               {"label": "el Parc i la Llacuna del Poblenou",
                "value": "C111267"},
               {"label": "El Perello", "value": "C111268"},
               {"label": "El Pla de Santa Maria", "value": "C111269"},
               {"label": "el Poblenou", "value": "C111270"},
               {"label": "El Prat de Llobregat", "value": "C111271"},
               {"label": "el Putxet i el Farro", "value": "C111272"},
               {"label": "el Raval", "value": "C111273"},
               {"label": "el Turo de la Peira", "value": "C111274"},
               {"label": "El Vendrell", "value": "C111275"},
               {"label": "eller", "value": "C111276"},
               {"label": "els Pallaresos", "value": "C111277"},
               {"label": "Empuriabrava", "value": "C111278"},
               {"label": "Esparreguera", "value": "C111279"},
               {"label": "Esplugues de Llobregat", "value": "C111280"},
               {"label": "Espolla", "value": "C111281"},
               {"label": "Esponella", "value": "C111282"},
               {"label": "Espot", "value": "C111283"},
               {"label": "Falset", "value": "C111284"},
               {"label": "Farrera", "value": "C111285"},
               {"label": "Figaro", "value": "C111286"},
               {"label": "Figueres", "value": "C111287"},
               {"label": "Flix", "value": "C111288"},
               {"label": "Foixa", "value": "C111289"},
               {"label": "Fonollosa", "value": "C111290"},
               {"label": "Fores", "value": "C111291"},
               {"label": "Fornells de la Selva", "value": "C111292"},
               {"label": "Fort Pienc", "value": "C111293"},
               {"label": "Fortia", "value": "C111294"},
               {"label": "Fulleda", "value": "C111295"},
               {"label": "Gallifa", "value": "C111296"},
               {"label": "Gandesa", "value": "C111297"},
               {"label": "Garcia", "value": "C111298"},
               {"label": "Garrigas", "value": "C111299"},
               {"label": "Garriguella", "value": "C111300"},
               {"label": "Gava", "value": "C111301"},
               {"label": "Gelida", "value": "C111302"},
               {"label": "Ginestar", "value": "C111303"},
               {"label": "Girona", "value": "C111304"},
               {"label": "Gironella", "value": "C111305"},
               {"label": "Golmes", "value": "C111306"},
               {"label": "Gosol", "value": "C111307"},
               {"label": "Gracia", "value": "C111308"},
               {"label": "Granera", "value": "C111309"},
               {"label": "Granollers", "value": "C111310"},
               {"label": "Gualta", "value": "C111311"},
               {"label": "Guimera", "value": "C111312"},
               {"label": "Gurb", "value": "C111313"},
               {"label": "Horta", "value": "C111314"},
               {"label": "Horta-Guinardo", "value": "C111315"},
               {"label": "Hostafrancs", "value": "C111316"},
               {"label": "Hostalric", "value": "C111317"},
               {"label": "Igualada", "value": "C111318"},
               {"label": "Ivorra", "value": "C111319"},
               {"label": "Jafre", "value": "C111320"},
               {"label": "Jorba", "value": "C111321"},
               {"label": "Juia", "value": "C111322"},
               {"label": "Juncosa", "value": "C111323"},
               {"label": "Juneda", "value": "C111324"},
               {"label": "LAmetlla del Valles", "value": "C111325"},
               {"label": "LAmpolla", "value": "C111326"},
               {"label": "lAntiga Esquerra de lEixample", "value": "C111327"},
               {"label": "lEscala", "value": "C111328"},
               {"label": "lEstartit", "value": "C111329"},
               {"label": "LHospitalet de Llobregat", "value": "C111330"},
               {"label": "la Barceloneta", "value": "C111331"},
               {"label": "la Bisbal dEmporda", "value": "C111332"},
               {"label": "La Bonanova", "value": "C111333"},
               {"label": "La Bordeta", "value": "C111334"},
               {"label": "La Canonja", "value": "C111335"},
               {"label": "la Cellera de Ter", "value": "C111336"},
               {"label": "la Fatarella", "value": "C111337"},
               {"label": "la Font den Fargues", "value": "C111338"},
               {"label": "la Font de la Guatlla", "value": "C111339"},
               {"label": "La Fuliola", "value": "C111340"},
               {"label": "la Garriga", "value": "C111341"},
               {"label": "La Granada", "value": "C111342"},
               {"label": "la Granadella", "value": "C111343"},
               {"label": "la Granja dEscarp", "value": "C111344"},
               {"label": "la Guineueta", "value": "C111345"},
               {"label": "La Llagosta", "value": "C111346"},
               {"label": "la Marina de Port", "value": "C111347"},
               {"label": "la Marina del Prat Vermell", "value": "C111348"},
               {"label": "la Maternitat i Sant Ramon", "value": "C111349"},
               {"label": "la Morera de Montsant", "value": "C111350"},
               {"label": "la Nova Esquerra de lEixample", "value": "C111351"},
               {"label": "La Pineda", "value": "C111352"},
               {"label": "La Pobla de Claramunt", "value": "C111353"},
               {"label": "la Pobla de Mafumet", "value": "C111354"},
               {"label": "la Prosperitat", "value": "C111355"},
               {"label": "La Roca del Valles", "value": "C111356"},
               {"label": "La Sagrera", "value": "C111357"},
               {"label": "la Salut", "value": "C111358"},
               {"label": "La Secuita", "value": "C111359"},
               {"label": "La Selva del Camp", "value": "C111360"},
               {"label": "La Seu dUrgell", "value": "C111361"},
               {"label": "la Teixonera", "value": "C111362"},
               {"label": "la Trinitat Nova", "value": "C111363"},
               {"label": "la Trinitat Vella", "value": "C111364"},
               {"label": "la Vall dHebron", "value": "C111365"},
               {"label": "la Verneda i la Pau", "value": "C111366"},
               {"label": "la Vila de Gracia", "value": "C111367"},
               {"label": "la Vila Olimpica del Poblenou", "value": "C111368"},
               {"label": "Las Tres Torres", "value": "C111369"},
               {"label": "Les", "value": "C111370"},
               {"label": "les Borges del Camp", "value": "C111371"},
               {"label": "Les Cases dAlcanar", "value": "C111372"},
               {"label": "Les Corts", "value": "C111373"},
               {"label": "les Llosses", "value": "C111374"},
               {"label": "les Planes dHostoles", "value": "C111375"},
               {"label": "les Roquetes", "value": "C111376"},
               {"label": "Linyola", "value": "C111377"},
               {"label": "Llado", "value": "C111378"},
               {"label": "Llagostera", "value": "C111379"},
               {"label": "Llambilles", "value": "C111380"},
               {"label": "Llanca", "value": "C111381"},
               {"label": "Llefia", "value": "C111382"},
               {"label": "Lleida", "value": "C111383"},
               {"label": "Llers", "value": "C111384"},
               {"label": "Lles de Cerdanya", "value": "C111385"},
               {"label": "Llica dAmunt", "value": "C111386"},
               {"label": "Llimiana", "value": "C111387"},
               {"label": "Llinars del Valles", "value": "C111388"},
               {"label": "Lloret de Mar", "value": "C111389"},
               {"label": "Madremanya", "value": "C111390"},
               {"label": "Malgrat de Mar", "value": "C111391"},
               {"label": "Manlleu", "value": "C111392"},
               {"label": "Manresa", "value": "C111393"},
               {"label": "Margalef", "value": "C111394"},
               {"label": "Martorell", "value": "C111395"},
               {"label": "Mas de Barberans", "value": "C111396"},
               {"label": "Masdenverge", "value": "C111397"},
               {"label": "Masquefa", "value": "C111398"},
               {"label": "Massanes", "value": "C111399"},
               {"label": "Matadepera", "value": "C111400"},
               {"label": "Mataro", "value": "C111401"},
               {"label": "Mediona", "value": "C111402"},
               {"label": "Miralcamp", "value": "C111403"},
               {"label": "Miravet", "value": "C111404"},
               {"label": "Moia", "value": "C111405"},
               {"label": "Molins de Rei", "value": "C111406"},
               {"label": "Mollerussa", "value": "C111407"},
               {"label": "Mollet de Peralada", "value": "C111408"},
               {"label": "Mollet del Valles", "value": "C111409"},
               {"label": "Monistrol de Montserrat", "value": "C111410"},
               {"label": "Mont-roig del Camp", "value": "C111411"},
               {"label": "Montbau", "value": "C111412"},
               {"label": "Montblanc", "value": "C111413"},
               {"label": "Montcada i Reixac", "value": "C111414"},
               {"label": "Montferri", "value": "C111415"},
               {"label": "Montgat", "value": "C111416"},
               {"label": "Montmelo", "value": "C111417"},
               {"label": "Montornes del Valles", "value": "C111418"},
               {"label": "Mura", "value": "C111419"},
               {"label": "Navarcles", "value": "C111420"},
               {"label": "Navas", "value": "C111421"},
               {"label": "Navata", "value": "C111422"},
               {"label": "Nou Barris", "value": "C111423"},
               {"label": "Odena", "value": "C111424"},
               {"label": "Olesa de Bonesvalls", "value": "C111425"},
               {"label": "Olesa de Montserrat", "value": "C111426"},
               {"label": "Oliana", "value": "C111427"},
               {"label": "Olius", "value": "C111428"},
               {"label": "Olivella", "value": "C111429"},
               {"label": "Olost", "value": "C111430"},
               {"label": "Olot", "value": "C111431"},
               {"label": "Ordis", "value": "C111432"},
               {"label": "Oris", "value": "C111433"},
               {"label": "Orista", "value": "C111434"},
               {"label": "Orpi", "value": "C111435"},
               {"label": "Orrius", "value": "C111436"},
               {"label": "Os de Balaguer", "value": "C111437"},
               {"label": "Osso de Sio", "value": "C111438"},
               {"label": "Palafrugell", "value": "C111439"},
               {"label": "Palamos", "value": "C111440"},
               {"label": "Palau-sator", "value": "C111441"},
               {"label": "Palleja", "value": "C111442"},
               {"label": "Palol de Revardit", "value": "C111443"},
               {"label": "Pals", "value": "C111444"},
               {"label": "Parets del Valles", "value": "C111445"},
               {"label": "Pau", "value": "C111446"},
               {"label": "Pedralbes", "value": "C111447"},
               {"label": "Piera", "value": "C111448"},
               {"label": "Pineda de Mar", "value": "C111449"},
               {"label": "Planoles", "value": "C111450"},
               {"label": "Poble Sec", "value": "C111451"},
               {"label": "Polinya", "value": "C111452"},
               {"label": "Pont de Molins", "value": "C111453"},
               {"label": "Pontils", "value": "C111454"},
               {"label": "Pontos", "value": "C111455"},
               {"label": "Ponts", "value": "C111456"},
               {"label": "Porta", "value": "C111457"},
               {"label": "Portbou", "value": "C111458"},
               {"label": "Premia de Mar", "value": "C111459"},
               {"label": "Provenals del Poblenou", "value": "C111460"},
               {"label": "Provincia de Barcelona", "value": "C111461"},
               {"label": "Provincia de Girona", "value": "C111462"},
               {"label": "Provincia de Lleida", "value": "C111463"},
               {"label": "Provincia de Tarragona", "value": "C111464"},
               {"label": "Puigcerda", "value": "C111465"},
               {"label": "Puigpelat", "value": "C111466"},
               {"label": "Pujalt", "value": "C111467"},
               {"label": "Quart dOnyar", "value": "C111468"},
               {"label": "Querol", "value": "C111469"},
               {"label": "Rabos", "value": "C111470"},
               {"label": "Rajadell", "value": "C111471"},
               {"label": "Rasquera", "value": "C111472"},
               {"label": "Regencos", "value": "C111473"},
               {"label": "Renau", "value": "C111474"},
               {"label": "Reus", "value": "C111475"},
               {"label": "Ribes de Freser", "value": "C111476"},
               {"label": "Ripoll", "value": "C111477"},
               {"label": "Ripollet", "value": "C111478"},
               {"label": "Riudecanyes", "value": "C111479"},
               {"label": "Riudecols", "value": "C111480"},
               {"label": "Riudellots de la Selva", "value": "C111481"},
               {"label": "Riudoms", "value": "C111482"},
               {"label": "Riumors", "value": "C111483"},
               {"label": "Roda de Bara", "value": "C111484"},
               {"label": "Roses", "value": "C111485"},
               {"label": "Rubi", "value": "C111486"},
               {"label": "Rupia", "value": "C111487"},
               {"label": "SAgaro", "value": "C111488"},
               {"label": "Sabadell", "value": "C111489"},
               {"label": "Sagas", "value": "C111490"},
               {"label": "Sagrada Familia", "value": "C111491"},
               {"label": "Sales de Llierca", "value": "C111492"},
               {"label": "Sallent", "value": "C111493"},
               {"label": "Salomo", "value": "C111494"},
               {"label": "Salou", "value": "C111495"},
               {"label": "Salt", "value": "C111496"},
               {"label": "Sant Adria de Besos", "value": "C111497"},
               {"label": "Sant Andreu", "value": "C111498"},
               {"label": "Sant Andreu de la Barca", "value": "C111499"},
               {"label": "Sant Andreu de Llavaneres", "value": "C111500"},
               {"label": "Sant Andreu Salou", "value": "C111501"},
               {"label": "Sant Antoni", "value": "C111502"},
               {"label": "Sant Boi de Llobregat", "value": "C111503"},
               {"label": "Sant Carles de la Rapita", "value": "C111504"},
               {"label": "Sant Celoni", "value": "C111505"},
               {"label": "Sant Cristofol de les Fonts", "value": "C111506"},
               {"label": "Sant Cugat del Valles", "value": "C111507"},
               {"label": "Sant Esteve den Bas", "value": "C111508"},
               {"label": "Sant Feliu de Guixols", "value": "C111509"},
               {"label": "Sant Feliu de Llobregat", "value": "C111510"},
               {"label": "Sant Ferriol", "value": "C111511"},
               {"label": "Sant Genis dels Agudells", "value": "C111512"},
               {"label": "Sant Gervasi - Galvany", "value": "C111513"},
               {"label": "Sant Hilari Sacalm", "value": "C111514"},
               {"label": "Sant Joan de les Abadesses", "value": "C111515"},
               {"label": "Sant Joan de Mediona", "value": "C111516"},
               {"label": "Sant Joan de Vilatorrada", "value": "C111517"},
               {"label": "Sant Joan Despi", "value": "C111518"},
               {"label": "Sant Joan les Fonts", "value": "C111519"},
               {"label": "Sant Jordi Desvalls", "value": "C111520"},
               {"label": "Sant Julia de Cerdanyola", "value": "C111521"},
               {"label": "Sant Just Desvern", "value": "C111522"},
               {"label": "Sant Marti", "value": "C111523"},
               {"label": "Sant Marti de Centelles", "value": "C111524"},
               {"label": "Sant Marti de Provencals", "value": "C111525"},
               {"label": "Sant Marti de Tous", "value": "C111526"},
               {"label": "Sant Marti Sarroca", "value": "C111527"},
               {"label": "Sant Marti Vell", "value": "C111528"},
               {"label": "Sant Miquel de Campmajor", "value": "C111529"},
               {"label": "Sant Pere de Ribes", "value": "C111530"},
               {"label": "Sant Pere de Riudebitlles", "value": "C111531"},
               {"label": "Sant Pere Pescador", "value": "C111532"},
               {"label": "Sant Pere, Santa Caterina i La Ribera",
                "value": "C111533"},
               {"label": "Sant Pol de Mar", "value": "C111534"},
               {"label": "Sant Quirze del Valles", "value": "C111535"},
               {"label": "Sant Sadurni dAnoia", "value": "C111536"},
               {"label": "Sant Salvador de Guardiola", "value": "C111537"},
               {"label": "Sant Vicenc de Castellet", "value": "C111538"},
               {"label": "Sant Vicenc de Montalt", "value": "C111539"},
               {"label": "Sant Vicenc dels Horts", "value": "C111540"},
               {"label": "Santa Barbara", "value": "C111541"},
               {"label": "Santa Cecilia de Voltrega", "value": "C111542"},
               {"label": "Santa Coloma de Cervello", "value": "C111543"},
               {"label": "Santa Coloma de Farners", "value": "C111544"},
               {"label": "Santa Coloma de Gramenet", "value": "C111545"},
               {"label": "Santa Cristina dAro", "value": "C111546"},
               {"label": "Santa Eugenia de Berga", "value": "C111547"},
               {"label": "Santa Eulalia de Roncana", "value": "C111548"},
               {"label": "Santa Maria dOlo", "value": "C111549"},
               {"label": "Santa Maria de Corco", "value": "C111550"},
               {"label": "Santa Maria de Palautordera", "value": "C111551"},
               {"label": "Santa Oliva", "value": "C111552"},
               {"label": "Santa Pau", "value": "C111553"},
               {"label": "Santa Perpetua de Mogoda", "value": "C111554"},
               {"label": "Santa Susanna", "value": "C111555"},
               {"label": "Sants", "value": "C111556"},
               {"label": "Sants - Badal", "value": "C111557"},
               {"label": "Sants-Montjuic", "value": "C111558"},
               {"label": "Sarria", "value": "C111559"},
               {"label": "Sarria de Ter", "value": "C111560"},
               {"label": "Sarria-Sant Gervasi", "value": "C111561"},
               {"label": "Sentmenat", "value": "C111562"},
               {"label": "Seros", "value": "C111563"},
               {"label": "Serra de Daro", "value": "C111564"},
               {"label": "Seva", "value": "C111565"},
               {"label": "Sidamon", "value": "C111566"},
               {"label": "Sils", "value": "C111567"},
               {"label": "Sitges", "value": "C111568"},
               {"label": "Siurana", "value": "C111569"},
               {"label": "Solsona", "value": "C111570"},
               {"label": "Sort", "value": "C111571"},
               {"label": "Soses", "value": "C111572"},
               {"label": "Subirats", "value": "C111573"},
               {"label": "Suria", "value": "C111574"},
               {"label": "Susqueda", "value": "C111575"},
               {"label": "Tagamanent", "value": "C111576"},
               {"label": "Talamanca", "value": "C111577"},
               {"label": "Taradell", "value": "C111578"},
               {"label": "Tarragona", "value": "C111579"},
               {"label": "Tarrega", "value": "C111580"},
               {"label": "Tavertet", "value": "C111581"},
               {"label": "Teia", "value": "C111582"},
               {"label": "Termens", "value": "C111583"},
               {"label": "Terrassa", "value": "C111584"},
               {"label": "Tiana", "value": "C111585"},
               {"label": "Tiurana", "value": "C111586"},
               {"label": "Tivenys", "value": "C111587"},
               {"label": "Toloriu", "value": "C111588"},
               {"label": "Tona", "value": "C111589"},
               {"label": "Tora de Riubregos", "value": "C111590"},
               {"label": "Tordera", "value": "C111591"},
               {"label": "Torello", "value": "C111592"},
               {"label": "Tornabous", "value": "C111593"},
               {"label": "Torre Baro", "value": "C111594"},
               {"label": "Torredembarra", "value": "C111595"},
               {"label": "Torrefarrera", "value": "C111596"},
               {"label": "Torrelles de Llobregat", "value": "C111597"},
               {"label": "Torrent", "value": "C111598"},
               {"label": "Torres de Segre", "value": "C111599"},
               {"label": "Torroella de Fluvia", "value": "C111600"},
               {"label": "Torroella de Montgri", "value": "C111601"},
               {"label": "Tortosa", "value": "C111602"},
               {"label": "Tossa de Mar", "value": "C111603"},
               {"label": "Tremp", "value": "C111604"},
               {"label": "Ulla", "value": "C111605"},
               {"label": "Ullastrell", "value": "C111606"},
               {"label": "Ulldecona", "value": "C111607"},
               {"label": "Ulldemolins", "value": "C111608"},
               {"label": "Ultramort", "value": "C111609"},
               {"label": "Urus", "value": "C111610"},
               {"label": "Vallbona", "value": "C111611"},
               {"label": "Vallbona de les Monges", "value": "C111612"},
               {"label": "Vallcarca", "value": "C111613"},
               {"label": "Vallclara", "value": "C111614"},
               {"label": "Vallfogona de Balaguer", "value": "C111615"},
               {"label": "Vallgorguina", "value": "C111616"},
               {"label": "Vallirana", "value": "C111617"},
               {"label": "Vallmoll", "value": "C111618"},
               {"label": "Valls", "value": "C111619"},
               {"label": "Vallvidrera, el Tibidabo i les Planes",
                "value": "C111620"},
               {"label": "Ventallo", "value": "C111621"},
               {"label": "Verdu", "value": "C111622"},
               {"label": "Verdun", "value": "C111623"},
               {"label": "Verges", "value": "C111624"},
               {"label": "Vic", "value": "C111625"},
               {"label": "Vidra", "value": "C111626"},
               {"label": "Vidreres", "value": "C111627"},
               {"label": "Vielha", "value": "C111628"},
               {"label": "Vila-seca", "value": "C111629"},
               {"label": "Vilabella", "value": "C111630"},
               {"label": "Vilabertran", "value": "C111631"},
               {"label": "Vilablareix", "value": "C111632"},
               {"label": "Viladasens", "value": "C111633"},
               {"label": "Viladecans", "value": "C111634"},
               {"label": "Vilademuls", "value": "C111635"},
               {"label": "Viladrau", "value": "C111636"},
               {"label": "Vilafant", "value": "C111637"},
               {"label": "Vilafranca del Penedes", "value": "C111638"},
               {"label": "Vilajuiga", "value": "C111639"},
               {"label": "Vilamacolum", "value": "C111640"},
               {"label": "Vilamalla", "value": "C111641"},
               {"label": "Vilamaniscle", "value": "C111642"},
               {"label": "Vilanant", "value": "C111643"},
               {"label": "Vilanova dEscornalbou", "value": "C111644"},
               {"label": "Vilanova de Bellpuig", "value": "C111645"},
               {"label": "Vilanova de Prades", "value": "C111646"},
               {"label": "Vilanova de Sau", "value": "C111647"},
               {"label": "Vilanova del Cami", "value": "C111648"},
               {"label": "Vilanova i la Geltru", "value": "C111649"},
               {"label": "Vilapicina i la Torre Llobeta", "value": "C111650"},
               {"label": "Vilaplana", "value": "C111651"},
               {"label": "Vilaseca", "value": "C111652"},
               {"label": "Vilassar de Mar", "value": "C111653"},
               {"label": "Vilopriu", "value": "C111654"},
               {"label": "Vimbodi", "value": "C111655"},
               {"label": "Vinaixa", "value": "C111656"},
               {"label": "Vinebre", "value": "C111657"}],
 "Catamarca": [{"label": "Ancasti", "value": "C593"},
               {"label": "Andalgala", "value": "C594"},
               {"label": "Antofagasta de la Sierra", "value": "C595"},
               {"label": "Capayan", "value": "C596"},
               {"label": "Departamento de Ambato", "value": "C597"},
               {"label": "Departamento de Ancasti", "value": "C598"},
               {"label": "Departamento de Andalgala", "value": "C599"},
               {"label": "Departamento de Antofagasta de la Sierra",
                "value": "C600"},
               {"label": "Departamento de Capayan", "value": "C601"},
               {"label": "Departamento de Capital", "value": "C602"},
               {"label": "Departamento de El Alto", "value": "C603"},
               {"label": "Departamento de Fray Mamerto Esquiu",
                "value": "C604"},
               {"label": "Departamento de La Paz", "value": "C605"},
               {"label": "Departamento de Poman", "value": "C606"},
               {"label": "Departamento de Santa Maria", "value": "C607"},
               {"label": "Departamento de Santa Rosa", "value": "C608"},
               {"label": "Departamento de Tinogasta", "value": "C609"},
               {"label": "Departamento de Valle Viejo", "value": "C610"},
               {"label": "El Rodeo", "value": "C611"},
               {"label": "Fiambala", "value": "C612"},
               {"label": "Hualfin", "value": "C613"},
               {"label": "Huillapima", "value": "C614"},
               {"label": "Icano", "value": "C615"},
               {"label": "La Puerta de San Jose", "value": "C616"},
               {"label": "Londres", "value": "C617"},
               {"label": "Los Altos", "value": "C618"},
               {"label": "Los Varela", "value": "C619"},
               {"label": "Mutquin", "value": "C620"},
               {"label": "Poman", "value": "C621"},
               {"label": "Puerta de Corral Quemado", "value": "C622"},
               {"label": "Recreo", "value": "C623"},
               {"label": "San Antonio", "value": "C624"},
               {"label": "San Fernando del Valle de Catamarca",
                "value": "C625"},
               {"label": "Santa Maria", "value": "C626"},
               {"label": "Tinogasta", "value": "C627"}],
 "Cauca": [{"label": "Almaguer", "value": "C19935"},
           {"label": "Argelia", "value": "C19936"},
           {"label": "Balboa", "value": "C19937"},
           {"label": "Bolivar", "value": "C19938"},
           {"label": "Buenos Aires", "value": "C19939"},
           {"label": "Cajibio", "value": "C19940"},
           {"label": "Caldono", "value": "C19941"},
           {"label": "Caloto", "value": "C19942"},
           {"label": "Corinto", "value": "C19943"},
           {"label": "El Tambo", "value": "C19944"},
           {"label": "Florencia", "value": "C19945"},
           {"label": "Guachene", "value": "C19946"},
           {"label": "Guapi", "value": "C19947"},
           {"label": "Inza", "value": "C19948"},
           {"label": "Jambalo", "value": "C19949"},
           {"label": "La Sierra", "value": "C19950"},
           {"label": "La Vega", "value": "C19951"},
           {"label": "Lopez de Micay", "value": "C19952"},
           {"label": "Mercaderes", "value": "C19953"},
           {"label": "Miranda", "value": "C19954"},
           {"label": "Morales", "value": "C19955"},
           {"label": "Padilla", "value": "C19956"},
           {"label": "Paez", "value": "C19957"},
           {"label": "Patia", "value": "C19958"},
           {"label": "Piamonte", "value": "C19959"},
           {"label": "Piendamo", "value": "C19960"},
           {"label": "Popayan", "value": "C19961"},
           {"label": "Puerto Tejada", "value": "C19962"},
           {"label": "Purace", "value": "C19963"},
           {"label": "Rosas", "value": "C19964"},
           {"label": "San Sebastian", "value": "C19965"},
           {"label": "Santa Rosa", "value": "C19966"},
           {"label": "Santander de Quilichao", "value": "C19967"},
           {"label": "Silvia", "value": "C19968"},
           {"label": "Sotara", "value": "C19969"},
           {"label": "Suarez", "value": "C19970"},
           {"label": "Sucre", "value": "C19971"},
           {"label": "Timbio", "value": "C19972"},
           {"label": "Timbiqui", "value": "C19973"},
           {"label": "Toribio", "value": "C19974"},
           {"label": "Totoro", "value": "C19975"},
           {"label": "Villa Rica", "value": "C19976"}],
 "Causeni District": [{"label": "Causeni", "value": "C75496"},
                      {"label": "Chitcani", "value": "C75497"}],
 "Cayo District": [{"label": "Belmopan", "value": "C9583"},
                   {"label": "Benque Viejo el Carmen", "value": "C9584"},
                   {"label": "San Ignacio", "value": "C9585"},
                   {"label": "Valley of Peace", "value": "C9586"}],
 "Ceara": [{"label": "Abaiara", "value": "C10820"},
           {"label": "Acarape", "value": "C10821"},
           {"label": "Acarau", "value": "C10822"},
           {"label": "Acopiara", "value": "C10823"},
           {"label": "Aiuaba", "value": "C10824"},
           {"label": "Alcantaras", "value": "C10825"},
           {"label": "Altaneira", "value": "C10826"},
           {"label": "Alto Santo", "value": "C10827"},
           {"label": "Amontada", "value": "C10828"},
           {"label": "Antonina do Norte", "value": "C10829"},
           {"label": "Apuiares", "value": "C10830"},
           {"label": "Aquiraz", "value": "C10831"},
           {"label": "Aracati", "value": "C10832"},
           {"label": "Aracoiaba", "value": "C10833"},
           {"label": "Ararenda", "value": "C10834"},
           {"label": "Araripe", "value": "C10835"},
           {"label": "Aratuba", "value": "C10836"},
           {"label": "Arneiroz", "value": "C10837"},
           {"label": "Assare", "value": "C10838"},
           {"label": "Aurora", "value": "C10839"},
           {"label": "Baixio", "value": "C10840"},
           {"label": "Banabuiu", "value": "C10841"},
           {"label": "Barbalha", "value": "C10842"},
           {"label": "Barreira", "value": "C10843"},
           {"label": "Barro", "value": "C10844"},
           {"label": "Barroquinha", "value": "C10845"},
           {"label": "Baturite", "value": "C10846"},
           {"label": "Beberibe", "value": "C10847"},
           {"label": "Bela Cruz", "value": "C10848"},
           {"label": "Boa Viagem", "value": "C10849"},
           {"label": "Brejo Santo", "value": "C10850"},
           {"label": "Camocim", "value": "C10851"},
           {"label": "Campos Sales", "value": "C10852"},
           {"label": "Caninde", "value": "C10853"},
           {"label": "Capistrano", "value": "C10854"},
           {"label": "Caridade", "value": "C10855"},
           {"label": "Carire", "value": "C10856"},
           {"label": "Caririacu", "value": "C10857"},
           {"label": "Carius", "value": "C10858"},
           {"label": "Carnaubal", "value": "C10859"},
           {"label": "Cascavel", "value": "C10860"},
           {"label": "Catarina", "value": "C10861"},
           {"label": "Catunda", "value": "C10862"},
           {"label": "Caucaia", "value": "C10863"},
           {"label": "Cedro", "value": "C10864"},
           {"label": "Chaval", "value": "C10865"},
           {"label": "Choro", "value": "C10866"},
           {"label": "Chorozinho", "value": "C10867"},
           {"label": "Coreau", "value": "C10868"},
           {"label": "Crateus", "value": "C10869"},
           {"label": "Crato", "value": "C10870"},
           {"label": "Croata", "value": "C10871"},
           {"label": "Cruz", "value": "C10872"},
           {"label": "Deputado Irapuan Pinheiro", "value": "C10873"},
           {"label": "Erere", "value": "C10874"},
           {"label": "Eusebio", "value": "C10875"},
           {"label": "Farias Brito", "value": "C10876"},
           {"label": "Forquilha", "value": "C10877"},
           {"label": "Fortaleza", "value": "C10878"},
           {"label": "Fortim", "value": "C10879"},
           {"label": "Frecheirinha", "value": "C10880"},
           {"label": "General Sampaio", "value": "C10881"},
           {"label": "Graca", "value": "C10882"},
           {"label": "Granja", "value": "C10883"},
           {"label": "Granjeiro", "value": "C10884"},
           {"label": "Groairas", "value": "C10885"},
           {"label": "Guaiuba", "value": "C10886"},
           {"label": "Guaraciaba do Norte", "value": "C10887"},
           {"label": "Guaramiranga", "value": "C10888"},
           {"label": "Hidrolandia", "value": "C10889"},
           {"label": "Horizonte", "value": "C10890"},
           {"label": "Ibaretama", "value": "C10891"},
           {"label": "Ibiapina", "value": "C10892"},
           {"label": "Ibicuitinga", "value": "C10893"},
           {"label": "Icapui", "value": "C10894"},
           {"label": "Ico", "value": "C10895"},
           {"label": "Iguatu", "value": "C10896"},
           {"label": "Independencia", "value": "C10897"},
           {"label": "Ipaporanga", "value": "C10898"},
           {"label": "Ipaumirim", "value": "C10899"},
           {"label": "Ipu", "value": "C10900"},
           {"label": "Ipueiras", "value": "C10901"},
           {"label": "Iracema", "value": "C10902"},
           {"label": "Iraucuba", "value": "C10903"},
           {"label": "Itaicaba", "value": "C10904"},
           {"label": "Itaitinga", "value": "C10905"},
           {"label": "Itapage", "value": "C10906"},
           {"label": "Itapaje", "value": "C10907"},
           {"label": "Itapipoca", "value": "C10908"},
           {"label": "Itapiuna", "value": "C10909"},
           {"label": "Itarema", "value": "C10910"},
           {"label": "Itatira", "value": "C10911"},
           {"label": "Jaguaretama", "value": "C10912"},
           {"label": "Jaguaribara", "value": "C10913"},
           {"label": "Jaguaribe", "value": "C10914"},
           {"label": "Jaguaruana", "value": "C10915"},
           {"label": "Jardim", "value": "C10916"},
           {"label": "Jati", "value": "C10917"},
           {"label": "Jijoca de Jericoacoara", "value": "C10918"},
           {"label": "Jua dos Vieiras", "value": "C10919"},
           {"label": "Juazeiro do Norte", "value": "C10920"},
           {"label": "Jucas", "value": "C10921"},
           {"label": "Lavras da Mangabeira", "value": "C10922"},
           {"label": "Limoeiro do Norte", "value": "C10923"},
           {"label": "Madalena", "value": "C10924"},
           {"label": "Maracanau", "value": "C10925"},
           {"label": "Maranguape", "value": "C10926"},
           {"label": "Marco", "value": "C10927"},
           {"label": "Martinopole", "value": "C10928"},
           {"label": "Massape", "value": "C10929"},
           {"label": "Mauriti", "value": "C10930"},
           {"label": "Meruoca", "value": "C10931"},
           {"label": "Milagres", "value": "C10932"},
           {"label": "Milha", "value": "C10933"},
           {"label": "Miraima", "value": "C10934"},
           {"label": "Missao Velha", "value": "C10935"},
           {"label": "Mombaca", "value": "C10936"},
           {"label": "Monsenhor Tabosa", "value": "C10937"},
           {"label": "Morada Nova", "value": "C10938"},
           {"label": "Moraujo", "value": "C10939"},
           {"label": "Morrinhos", "value": "C10940"},
           {"label": "Mucambo", "value": "C10941"},
           {"label": "Mulungu", "value": "C10942"},
           {"label": "Nova Olinda", "value": "C10943"},
           {"label": "Nova Russas", "value": "C10944"},
           {"label": "Novo Oriente", "value": "C10945"},
           {"label": "Ocara", "value": "C10946"},
           {"label": "Oros", "value": "C10947"},
           {"label": "Pacajus", "value": "C10948"},
           {"label": "Pacatuba", "value": "C10949"},
           {"label": "Pacoti", "value": "C10950"},
           {"label": "Pacuja", "value": "C10951"},
           {"label": "Palhano", "value": "C10952"},
           {"label": "Palmacia", "value": "C10953"},
           {"label": "Paracuru", "value": "C10954"},
           {"label": "Paraipaba", "value": "C10955"},
           {"label": "Parambu", "value": "C10956"},
           {"label": "Paramoti", "value": "C10957"},
           {"label": "Pedra Branca", "value": "C10958"},
           {"label": "Penaforte", "value": "C10959"},
           {"label": "Pentecoste", "value": "C10960"},
           {"label": "Pereiro", "value": "C10961"},
           {"label": "Pindoretama", "value": "C10962"},
           {"label": "Piquet Carneiro", "value": "C10963"},
           {"label": "Pires Ferreira", "value": "C10964"},
           {"label": "Poranga", "value": "C10965"},
           {"label": "Porteiras", "value": "C10966"},
           {"label": "Potengi", "value": "C10967"},
           {"label": "Potiretama", "value": "C10968"},
           {"label": "Quiterianopolis", "value": "C10969"},
           {"label": "Quixada", "value": "C10970"},
           {"label": "Quixelo", "value": "C10971"},
           {"label": "Quixeramobim", "value": "C10972"},
           {"label": "Quixere", "value": "C10973"},
           {"label": "Redencao", "value": "C10974"},
           {"label": "Reriutaba", "value": "C10975"},
           {"label": "Russas", "value": "C10976"},
           {"label": "Saboeiro", "value": "C10977"},
           {"label": "Salitre", "value": "C10978"},
           {"label": "Santa Quiteria", "value": "C10979"},
           {"label": "Santana do Acarau", "value": "C10980"},
           {"label": "Santana do Cariri", "value": "C10981"},
           {"label": "Sao Benedito", "value": "C10982"},
           {"label": "Sao Goncalo do Amarante", "value": "C10983"},
           {"label": "Sao Joao do Jaguaribe", "value": "C10984"},
           {"label": "Sao Joao dos Inhamuns", "value": "C10985"},
           {"label": "Sao Luis do Curu", "value": "C10986"},
           {"label": "Senador Pompeu", "value": "C10987"},
           {"label": "Senador Sa", "value": "C10988"},
           {"label": "Sobral", "value": "C10989"},
           {"label": "Solonopole", "value": "C10990"},
           {"label": "Tabuleiro do Norte", "value": "C10991"},
           {"label": "Tamboril", "value": "C10992"},
           {"label": "Tarrafas", "value": "C10993"},
           {"label": "Taua", "value": "C10994"},
           {"label": "Tejucuoca", "value": "C10995"},
           {"label": "Tiangua", "value": "C10996"},
           {"label": "Trairi", "value": "C10997"},
           {"label": "Tururu", "value": "C10998"},
           {"label": "Ubajara", "value": "C10999"},
           {"label": "Umari", "value": "C11000"},
           {"label": "Umirim", "value": "C11001"},
           {"label": "Uruburetama", "value": "C11002"},
           {"label": "Uruoca", "value": "C11003"},
           {"label": "Varjota", "value": "C11004"},
           {"label": "Varzea Alegre", "value": "C11005"},
           {"label": "Vicosa do Ceara", "value": "C11006"}],
 "Centar Zupa Municipality": [{"label": "Centar Zupa", "value": "C65384"}],
 "Central": [{"label": "Apam", "value": "C41499"},
             {"label": "Cape Coast", "value": "C41500"},
             {"label": "Dunkwa", "value": "C41501"},
             {"label": "Elmina", "value": "C41502"},
             {"label": "Foso", "value": "C41503"},
             {"label": "Kasoa", "value": "C41504"},
             {"label": "Mumford", "value": "C41505"},
             {"label": "Saltpond", "value": "C41506"},
             {"label": "Swedru", "value": "C41507"},
             {"label": "Winneba", "value": "C41508"}],
 "Central Abaco": [{"label": "Marsh Harbour", "value": "C119606"}],
 "Central Banat District": [{"label": "Arandelovac", "value": "C106895"},
                            {"label": "Arilje", "value": "C106896"},
                            {"label": "Badovinci", "value": "C106897"},
                            {"label": "Bajina Basta", "value": "C106898"},
                            {"label": "Banovo Polje", "value": "C106899"},
                            {"label": "Baric", "value": "C106900"},
                            {"label": "Becmen", "value": "C106901"},
                            {"label": "Belgrade", "value": "C106902"},
                            {"label": "Belotic", "value": "C106903"},
                            {"label": "Biljaca", "value": "C106904"},
                            {"label": "Bogatic", "value": "C106905"},
                            {"label": "Bogosavac", "value": "C106906"},
                            {"label": "Boljevci", "value": "C106907"},
                            {"label": "Bor", "value": "C106908"},
                            {"label": "Brdarica", "value": "C106909"},
                            {"label": "Bukor", "value": "C106910"},
                            {"label": "Cacak", "value": "C106911"},
                            {"label": "Cajetina", "value": "C106912"},
                            {"label": "Cicevac", "value": "C106913"},
                            {"label": "Cokesina", "value": "C106914"},
                            {"label": "Crna Bara", "value": "C106915"},
                            {"label": "Cuprija", "value": "C106916"},
                            {"label": "Dobanovci", "value": "C106917"},
                            {"label": "Dobric", "value": "C106918"},
                            {"label": "Donji Dobric", "value": "C106919"},
                            {"label": "Donji Milanovac", "value": "C106920"},
                            {"label": "Draginje", "value": "C106921"},
                            {"label": "Drenovac", "value": "C106922"},
                            {"label": "Dublje", "value": "C106923"},
                            {"label": "Duboka", "value": "C106924"},
                            {"label": "Durici", "value": "C106925"},
                            {"label": "Glusci", "value": "C106926"},
                            {"label": "Golubac", "value": "C106927"},
                            {"label": "Gornja Bukovica", "value": "C106928"},
                            {"label": "Gornji Milanovac", "value": "C106929"},
                            {"label": "Grabovac", "value": "C106930"},
                            {"label": "Jablanica", "value": "C106931"},
                            {"label": "Jadranska Lesnica", "value": "C106932"},
                            {"label": "Jagodina", "value": "C106933"},
                            {"label": "Jarebice", "value": "C106934"},
                            {"label": "Jelenca", "value": "C106935"},
                            {"label": "Jevremovac", "value": "C106936"},
                            {"label": "Joseva", "value": "C106937"},
                            {"label": "Kamenica", "value": "C106938"},
                            {"label": "Klenje", "value": "C106939"},
                            {"label": "Knjazevac", "value": "C106940"},
                            {"label": "Kolubara", "value": "C106941"},
                            {"label": "Kozjak", "value": "C106942"},
                            {"label": "Kragujevac", "value": "C106943"},
                            {"label": "Kraljevo", "value": "C106944"},
                            {"label": "Krivaja", "value": "C106945"},
                            {"label": "Krupanj", "value": "C106946"},
                            {"label": "Krusevac", "value": "C106947"},
                            {"label": "Lagja e Poshtme", "value": "C106948"},
                            {"label": "Lapovo", "value": "C106949"},
                            {"label": "Lazarevac", "value": "C106950"},
                            {"label": "Leskovac", "value": "C106951"},
                            {"label": "Lesnica", "value": "C106952"},
                            {"label": "Lipnicki Sor", "value": "C106953"},
                            {"label": "Lipolist", "value": "C106954"},
                            {"label": "Ljig", "value": "C106955"},
                            {"label": "Ljubovija", "value": "C106956"},
                            {"label": "Lugavcina", "value": "C106957"},
                            {"label": "Macva", "value": "C106958"},
                            {"label": "Majdanpek", "value": "C106959"},
                            {"label": "Majur", "value": "C106960"},
                            {"label": "Mala Mostanica", "value": "C106961"},
                            {"label": "Mali Zvornik", "value": "C106962"},
                            {"label": "Metkovic", "value": "C106963"},
                            {"label": "Miratovac", "value": "C106964"},
                            {"label": "Morava", "value": "C106965"},
                            {"label": "Negotin", "value": "C106966"},
                            {"label": "Nis", "value": "C106967"},
                            {"label": "Nisava", "value": "C106968"},
                            {"label": "Novi Pazar", "value": "C106969"},
                            {"label": "Novo Selo", "value": "C106970"},
                            {"label": "Obrenovac", "value": "C106971"},
                            {"label": "Osecina", "value": "C106972"},
                            {"label": "Osjecenik", "value": "C106973"},
                            {"label": "Ostruznica", "value": "C106974"},
                            {"label": "Ovca", "value": "C106975"},
                            {"label": "Paracin", "value": "C106976"},
                            {"label": "Pcinja", "value": "C106977"},
                            {"label": "Petkovica", "value": "C106978"},
                            {"label": "Pirot", "value": "C106979"},
                            {"label": "Pocerski Pricinovic",
                             "value": "C106980"},
                            {"label": "Podunavlje District",
                             "value": "C106981"},
                            {"label": "Pomoravlje", "value": "C106982"},
                            {"label": "Pozarevac", "value": "C106983"},
                            {"label": "Priboj", "value": "C106984"},
                            {"label": "Prijepolje", "value": "C106985"},
                            {"label": "Prislonica", "value": "C106986"},
                            {"label": "Prnjavor", "value": "C106987"},
                            {"label": "Prokuplje", "value": "C106988"},
                            {"label": "Radenka", "value": "C106989"},
                            {"label": "Radovnica", "value": "C106990"},
                            {"label": "Rajince", "value": "C106991"},
                            {"label": "Rasina", "value": "C106992"},
                            {"label": "Raska", "value": "C106993"},
                            {"label": "Ribari", "value": "C106994"},
                            {"label": "Rumska", "value": "C106995"},
                            {"label": "Rusanj", "value": "C106996"},
                            {"label": "Sabac", "value": "C106997"},
                            {"label": "Salas Crnobarski", "value": "C106998"},
                            {"label": "Samoljica", "value": "C106999"},
                            {"label": "Sevarice", "value": "C107000"},
                            {"label": "Sevica", "value": "C107001"},
                            {"label": "Sinosevic", "value": "C107002"},
                            {"label": "Sjenica", "value": "C107003"},
                            {"label": "Smederevo", "value": "C107004"},
                            {"label": "Smederevska Palanka",
                             "value": "C107005"},
                            {"label": "Sokolovica", "value": "C107006"},
                            {"label": "Sokolovo Brdo", "value": "C107007"},
                            {"label": "Sremcica", "value": "C107008"},
                            {"label": "Stepojevac", "value": "C107009"},
                            {"label": "Stitar", "value": "C107010"},
                            {"label": "Stubline", "value": "C107011"},
                            {"label": "Sumadija", "value": "C107012"},
                            {"label": "Sumulice", "value": "C107013"},
                            {"label": "Surcin", "value": "C107014"},
                            {"label": "Tabanovic", "value": "C107015"},
                            {"label": "Toplica", "value": "C107016"},
                            {"label": "Trsic", "value": "C107017"},
                            {"label": "Trstenik", "value": "C107018"},
                            {"label": "Turija", "value": "C107019"},
                            {"label": "Tutin", "value": "C107020"},
                            {"label": "Ugrinovci", "value": "C107021"},
                            {"label": "Umka", "value": "C107022"},
                            {"label": "Uzice", "value": "C107023"},
                            {"label": "Uzvece", "value": "C107024"},
                            {"label": "Valjevo", "value": "C107025"},
                            {"label": "Varna", "value": "C107026"},
                            {"label": "Velika Mostanica", "value": "C107027"},
                            {"label": "Vladimirci", "value": "C107028"},
                            {"label": "Vranic", "value": "C107029"},
                            {"label": "Vranje", "value": "C107030"},
                            {"label": "Vrnjacka Banja", "value": "C107031"},
                            {"label": "Zajecar", "value": "C107032"},
                            {"label": "Zemun", "value": "C107033"},
                            {"label": "Zlatibor", "value": "C107034"},
                            {"label": "Zminjak", "value": "C107035"},
                            {"label": "Zujince", "value": "C107036"},
                            {"label": "Zvecka", "value": "C107037"}],
 "Central Darfur": [{"label": "Zalingei", "value": "C115402"}],
 "Central Denmark Region": [{"label": "Allingabro", "value": "C23463"},
                            {"label": "Arhus", "value": "C23464"},
                            {"label": "Arhus Kommune", "value": "C23465"},
                            {"label": "Assentoft", "value": "C23466"},
                            {"label": "Auning", "value": "C23467"},
                            {"label": "Avlum", "value": "C23468"},
                            {"label": "Beder", "value": "C23469"},
                            {"label": "Bjerringbro", "value": "C23470"},
                            {"label": "Bording Kirkeby", "value": "C23471"},
                            {"label": "Brande", "value": "C23472"},
                            {"label": "Braedstrup", "value": "C23473"},
                            {"label": "Ebeltoft", "value": "C23474"},
                            {"label": "Favrskov Kommune", "value": "C23475"},
                            {"label": "Framlev", "value": "C23476"},
                            {"label": "Galten", "value": "C23477"},
                            {"label": "Gjellerup", "value": "C23478"},
                            {"label": "Grenaa", "value": "C23479"},
                            {"label": "Hadsten", "value": "C23480"},
                            {"label": "Hammel", "value": "C23481"},
                            {"label": "Hammerum", "value": "C23482"},
                            {"label": "Harboore", "value": "C23483"},
                            {"label": "Hedensted", "value": "C23484"},
                            {"label": "Hedensted Kommune", "value": "C23485"},
                            {"label": "Herning", "value": "C23486"},
                            {"label": "Herning Kommune", "value": "C23487"},
                            {"label": "Hinnerup", "value": "C23488"},
                            {"label": "Hjortshoj", "value": "C23489"},
                            {"label": "Holstebro", "value": "C23490"},
                            {"label": "Holstebro Kommune", "value": "C23491"},
                            {"label": "Hornslet", "value": "C23492"},
                            {"label": "Hornsyld", "value": "C23493"},
                            {"label": "Horsens", "value": "C23494"},
                            {"label": "Horsens Kommune", "value": "C23495"},
                            {"label": "Horning", "value": "C23496"},
                            {"label": "Hvide Sande", "value": "C23497"},
                            {"label": "Ikast", "value": "C23498"},
                            {"label": "Ikast-Brande Kommune",
                             "value": "C23499"},
                            {"label": "Juelsminde", "value": "C23500"},
                            {"label": "Karup", "value": "C23501"},
                            {"label": "Kibaek", "value": "C23502"},
                            {"label": "Kjellerup", "value": "C23503"},
                            {"label": "Kolt", "value": "C23504"},
                            {"label": "Langa", "value": "C23505"},
                            {"label": "Lemvig", "value": "C23506"},
                            {"label": "Lemvig Kommune", "value": "C23507"},
                            {"label": "Lind", "value": "C23508"},
                            {"label": "Logten", "value": "C23509"},
                            {"label": "Lystrup", "value": "C23510"},
                            {"label": "Malling", "value": "C23511"},
                            {"label": "Marslet", "value": "C23512"},
                            {"label": "Norddjurs Kommune", "value": "C23513"},
                            {"label": "Odder", "value": "C23514"},
                            {"label": "Odder Kommune", "value": "C23515"},
                            {"label": "Randers", "value": "C23516"},
                            {"label": "Randers Kommune", "value": "C23517"},
                            {"label": "Ringkobing", "value": "C23518"},
                            {"label": "Ringkobing-Skjern Kommune",
                             "value": "C23519"},
                            {"label": "Ronde", "value": "C23520"},
                            {"label": "Ry", "value": "C23521"},
                            {"label": "Ryomgard", "value": "C23522"},
                            {"label": "Sabro", "value": "C23523"},
                            {"label": "Samso Kommune", "value": "C23524"},
                            {"label": "Silkeborg", "value": "C23525"},
                            {"label": "Silkeborg Kommune", "value": "C23526"},
                            {"label": "Skanderborg", "value": "C23527"},
                            {"label": "Skanderborg Kommune", "value": "C23528"},
                            {"label": "Skive", "value": "C23529"},
                            {"label": "Skive Kommune", "value": "C23530"},
                            {"label": "Skjern", "value": "C23531"},
                            {"label": "Skovby", "value": "C23532"},
                            {"label": "Snejbjerg", "value": "C23533"},
                            {"label": "Solbjerg", "value": "C23534"},
                            {"label": "Soften", "value": "C23535"},
                            {"label": "Spentrup", "value": "C23536"},
                            {"label": "Stavtrup", "value": "C23537"},
                            {"label": "Stilling", "value": "C23538"},
                            {"label": "Stoholm", "value": "C23539"},
                            {"label": "Struer", "value": "C23540"},
                            {"label": "Struer Kommune", "value": "C23541"},
                            {"label": "Sunds", "value": "C23542"},
                            {"label": "Svejbaek", "value": "C23543"},
                            {"label": "Syddjurs Kommune", "value": "C23544"},
                            {"label": "Tarm", "value": "C23545"},
                            {"label": "Thyboron", "value": "C23546"},
                            {"label": "Torring", "value": "C23547"},
                            {"label": "Tranbjerg", "value": "C23548"},
                            {"label": "Trige", "value": "C23549"},
                            {"label": "Ulfborg", "value": "C23550"},
                            {"label": "Ulstrup", "value": "C23551"},
                            {"label": "Viborg", "value": "C23552"},
                            {"label": "Viborg Kommune", "value": "C23553"},
                            {"label": "Videbaek", "value": "C23554"},
                            {"label": "Vildbjerg", "value": "C23555"},
                            {"label": "Vinderup", "value": "C23556"},
                            {"label": "Virklund", "value": "C23557"}],
 "Central Department": [{"label": "Aregua", "value": "C80530"},
                        {"label": "Capiata", "value": "C80531"},
                        {"label": "Fernando de la Mora", "value": "C80532"},
                        {"label": "Guarambare", "value": "C80533"},
                        {"label": "Ita", "value": "C80534"},
                        {"label": "Itaugua", "value": "C80535"},
                        {"label": "Lambare", "value": "C80536"},
                        {"label": "Limpio", "value": "C80537"},
                        {"label": "Mariano Roque Alonso", "value": "C80538"},
                        {"label": "Nemby", "value": "C80539"},
                        {"label": "Nueva Italia", "value": "C80540"},
                        {"label": "San Antonio", "value": "C80541"},
                        {"label": "San Lorenzo", "value": "C80542"},
                        {"label": "Villa Elisa", "value": "C80543"},
                        {"label": "Ypacarai", "value": "C80544"}],
 "Central District": [{"label": "Gobojango", "value": "C10122"},
                      {"label": "Gweta", "value": "C10123"},
                      {"label": "Kalamare", "value": "C10124"},
                      {"label": "Letlhakane", "value": "C10125"},
                      {"label": "Letsheng", "value": "C10126"},
                      {"label": "Maapi", "value": "C10127"},
                      {"label": "Machaneng", "value": "C10128"},
                      {"label": "Mahalapye", "value": "C10129"},
                      {"label": "Makobeng", "value": "C10130"},
                      {"label": "Makwata", "value": "C10131"},
                      {"label": "Mathakola", "value": "C10132"},
                      {"label": "Mathambgwane", "value": "C10133"},
                      {"label": "Mathathane", "value": "C10134"},
                      {"label": "Maunatlala", "value": "C10135"},
                      {"label": "Mogapi", "value": "C10136"},
                      {"label": "Moijabana", "value": "C10137"},
                      {"label": "Mookane", "value": "C10138"},
                      {"label": "Mopipi", "value": "C10139"},
                      {"label": "Mosetse", "value": "C10140"},
                      {"label": "Nata", "value": "C10141"},
                      {"label": "Orapa", "value": "C10142"},
                      {"label": "Palapye", "value": "C10143"},
                      {"label": "Pilikwe", "value": "C10144"},
                      {"label": "Rakops", "value": "C10145"},
                      {"label": "Ramokgonami", "value": "C10146"},
                      {"label": "Ratholo", "value": "C10147"},
                      {"label": "Sefophe", "value": "C10148"},
                      {"label": "Serowe", "value": "C10149"},
                      {"label": "Sua", "value": "C10150"},
                      {"label": "Tamasane", "value": "C10151"},
                      {"label": "Tobane", "value": "C10152"},
                      {"label": "Tonota", "value": "C10153"},
                      {"label": "Bet Dagan", "value": "C51586"},
                      {"label": "Bet Yizhaq", "value": "C51587"},
                      {"label": "Bnei Ayish", "value": "C51588"},
                      {"label": "Elyakhin", "value": "C51589"},
                      {"label": "Et Taiyiba", "value": "C51590"},
                      {"label": "Even Yehuda", "value": "C51591"},
                      {"label": "Gan Yavne", "value": "C51592"},
                      {"label": "Ganei Tikva", "value": "C51593"},
                      {"label": "Gedera", "value": "C51594"},
                      {"label": "Hod HaSharon", "value": "C51595"},
                      {"label": "Jaljulya", "value": "C51596"},
                      {"label": "Kafr Qasim", "value": "C51597"},
                      {"label": "Kefar Habad", "value": "C51598"},
                      {"label": "Kefar Yona", "value": "C51599"},
                      {"label": "Kfar Saba", "value": "C51600"},
                      {"label": "Lapid", "value": "C51601"},
                      {"label": "Lod", "value": "C51602"},
                      {"label": "Mazkeret Batya", "value": "C51603"},
                      {"label": "Modiin Makkabbim Reut", "value": "C51604"},
                      {"label": "Nehalim", "value": "C51605"},
                      {"label": "Ness Ziona", "value": "C51606"},
                      {"label": "Netanya", "value": "C51607"},
                      {"label": "Nirit", "value": "C51608"},
                      {"label": "Nof Ayalon", "value": "C51609"},
                      {"label": "Nordiyya", "value": "C51610"},
                      {"label": "Pardesiyya", "value": "C51611"},
                      {"label": "Petah Tiqwa", "value": "C51612"},
                      {"label": "Qalansuwa", "value": "C51613"},
                      {"label": "Raanana", "value": "C51614"},
                      {"label": "Ramla", "value": "C51615"},
                      {"label": "Rehovot", "value": "C51616"},
                      {"label": "Rishon LeZiyyon", "value": "C51617"},
                      {"label": "Rosh HaAyin", "value": "C51618"},
                      {"label": "Savyon", "value": "C51619"},
                      {"label": "Shoham", "value": "C51620"},
                      {"label": "Tel Mond", "value": "C51621"},
                      {"label": "Tirah", "value": "C51622"},
                      {"label": "Yavne", "value": "C51623"},
                      {"label": "Yehud", "value": "C51624"},
                      {"label": "Zur Moshe", "value": "C51625"}],
 "Central Division": [{"label": "Naitasiri Province", "value": "C24805"},
                      {"label": "Namosi Province", "value": "C24806"},
                      {"label": "Rewa Province", "value": "C24807"},
                      {"label": "Serua Province", "value": "C24808"},
                      {"label": "Suva", "value": "C24809"},
                      {"label": "Tailevu Province", "value": "C24810"}],
 "Central Finland": [{"label": "Aanekoski", "value": "C24825"},
                     {"label": "Hankasalmi", "value": "C24826"},
                     {"label": "Jamsa", "value": "C24827"},
                     {"label": "Jamsankoski", "value": "C24828"},
                     {"label": "Joutsa", "value": "C24829"},
                     {"label": "Jyvaskyla", "value": "C24830"},
                     {"label": "Kannonkoski", "value": "C24831"},
                     {"label": "Karstula", "value": "C24832"},
                     {"label": "Keuruu", "value": "C24833"},
                     {"label": "Kinnula", "value": "C24834"},
                     {"label": "Kivijarvi", "value": "C24835"},
                     {"label": "Konnevesi", "value": "C24836"},
                     {"label": "Korpilahti", "value": "C24837"},
                     {"label": "Kuhmoinen", "value": "C24838"},
                     {"label": "Kyyjarvi", "value": "C24839"},
                     {"label": "Laukaa", "value": "C24840"},
                     {"label": "Leivonmaki", "value": "C24841"},
                     {"label": "Luhanka", "value": "C24842"},
                     {"label": "Multia", "value": "C24843"},
                     {"label": "Muurame", "value": "C24844"},
                     {"label": "Petajavesi", "value": "C24845"},
                     {"label": "Pihtipudas", "value": "C24846"},
                     {"label": "Pylkonmaki", "value": "C24847"},
                     {"label": "Saarijarvi", "value": "C24848"},
                     {"label": "Saynatsalo", "value": "C24849"},
                     {"label": "Sumiainen", "value": "C24850"},
                     {"label": "Suolahti", "value": "C24851"},
                     {"label": "Toivakka", "value": "C24852"},
                     {"label": "Uurainen", "value": "C24853"},
                     {"label": "Viitasaari", "value": "C24854"}],
 "Central Governorate": [{"label": "Madinat Hamad", "value": "C8548"}],
 "Central Greece Region": [{"label": "Afration", "value": "C41724"},
                           {"label": "Agia Triada", "value": "C41725"},
                           {"label": "agios Georgios", "value": "C41726"},
                           {"label": "Aidipsos", "value": "C41727"},
                           {"label": "Akraifnia", "value": "C41728"},
                           {"label": "Aliartos", "value": "C41729"},
                           {"label": "Aliveri", "value": "C41730"},
                           {"label": "Amarynthos", "value": "C41731"},
                           {"label": "Amfikleia", "value": "C41732"},
                           {"label": "amfissa", "value": "C41733"},
                           {"label": "Anthili", "value": "C41734"},
                           {"label": "Antikyra", "value": "C41735"},
                           {"label": "Arachova", "value": "C41736"},
                           {"label": "arma", "value": "C41737"},
                           {"label": "Asopia", "value": "C41738"},
                           {"label": "Atalanti", "value": "C41739"},
                           {"label": "ayios Konstandinos", "value": "C41740"},
                           {"label": "ayios Nikolaos", "value": "C41741"},
                           {"label": "ayios Thomas", "value": "C41742"},
                           {"label": "Chaironeia", "value": "C41743"},
                           {"label": "Chalkida", "value": "C41744"},
                           {"label": "Delphi", "value": "C41745"},
                           {"label": "Dhrosia", "value": "C41746"},
                           {"label": "Dilesi", "value": "C41747"},
                           {"label": "Distomo", "value": "C41748"},
                           {"label": "Domokos", "value": "C41749"},
                           {"label": "Domvraina", "value": "C41750"},
                           {"label": "Elateia", "value": "C41751"},
                           {"label": "Eretria", "value": "C41752"},
                           {"label": "Faros", "value": "C41753"},
                           {"label": "Filla", "value": "C41754"},
                           {"label": "Galaxidhion", "value": "C41755"},
                           {"label": "Istiaia", "value": "C41756"},
                           {"label": "Itea", "value": "C41757"},
                           {"label": "Kainouryion", "value": "C41758"},
                           {"label": "Kamena Vourla", "value": "C41759"},
                           {"label": "Kaparellion", "value": "C41760"},
                           {"label": "Karpenisi", "value": "C41761"},
                           {"label": "Karystos", "value": "C41762"},
                           {"label": "Kastella", "value": "C41763"},
                           {"label": "Kato Tithorea", "value": "C41764"},
                           {"label": "Kerasochori", "value": "C41765"},
                           {"label": "Kirra", "value": "C41766"},
                           {"label": "Kymi", "value": "C41767"},
                           {"label": "Kyriaki", "value": "C41768"},
                           {"label": "Lamia", "value": "C41769"},
                           {"label": "Lianokladhion", "value": "C41770"},
                           {"label": "Lidoriki", "value": "C41771"},
                           {"label": "Limni", "value": "C41772"},
                           {"label": "Livadeia", "value": "C41773"},
                           {"label": "Livanates", "value": "C41774"},
                           {"label": "Loukisia", "value": "C41775"},
                           {"label": "Loutra Aidhipsou", "value": "C41776"},
                           {"label": "Magoula", "value": "C41777"},
                           {"label": "Makrakomi", "value": "C41778"},
                           {"label": "Malakonta", "value": "C41779"},
                           {"label": "Malesina", "value": "C41780"},
                           {"label": "Mantoudi", "value": "C41781"},
                           {"label": "Marmarion", "value": "C41782"},
                           {"label": "Martinon", "value": "C41783"},
                           {"label": "Molos", "value": "C41784"},
                           {"label": "Mytikas", "value": "C41785"},
                           {"label": "Nea Artaki", "value": "C41786"},
                           {"label": "Nea Lampsakos", "value": "C41787"},
                           {"label": "Nea Stira", "value": "C41788"},
                           {"label": "Neon Monastirion", "value": "C41789"},
                           {"label": "Nomos Evrytanias", "value": "C41790"},
                           {"label": "Nomos Fokidos", "value": "C41791"},
                           {"label": "Oinofyta", "value": "C41792"},
                           {"label": "Omvriaki", "value": "C41793"},
                           {"label": "Orchomenos", "value": "C41794"},
                           {"label": "Oreoi", "value": "C41795"},
                           {"label": "Oxilithos", "value": "C41796"},
                           {"label": "Paralia Avlidhos", "value": "C41797"},
                           {"label": "Pelasgia", "value": "C41798"},
                           {"label": "Plaka Dilesi", "value": "C41799"},
                           {"label": "Politika", "value": "C41800"},
                           {"label": "Prokopi", "value": "C41801"},
                           {"label": "Psachna", "value": "C41802"},
                           {"label": "Rodhitsa", "value": "C41803"},
                           {"label": "Rovies", "value": "C41804"},
                           {"label": "Schimatari", "value": "C41805"},
                           {"label": "Skyros", "value": "C41806"},
                           {"label": "Spercheiada", "value": "C41807"},
                           {"label": "Stavros", "value": "C41808"},
                           {"label": "Steiri", "value": "C41809"},
                           {"label": "Stylida", "value": "C41810"},
                           {"label": "Thespies", "value": "C41811"},
                           {"label": "Thivai", "value": "C41812"},
                           {"label": "Vagia", "value": "C41813"},
                           {"label": "Vasilikon", "value": "C41814"},
                           {"label": "Vathi", "value": "C41815"},
                           {"label": "Yimnon", "value": "C41816"}],
 "Central Luzon": [{"label": "Abucay", "value": "C85154"},
                   {"label": "Acli", "value": "C85155"},
                   {"label": "Agbannawag", "value": "C85156"},
                   {"label": "Akle", "value": "C85157"},
                   {"label": "Aliaga", "value": "C85158"},
                   {"label": "Almendras", "value": "C85159"},
                   {"label": "Alua", "value": "C85160"},
                   {"label": "Amacalan", "value": "C85161"},
                   {"label": "Amucao", "value": "C85162"},
                   {"label": "Amungan", "value": "C85163"},
                   {"label": "Anao", "value": "C85164"},
                   {"label": "Angat", "value": "C85165"},
                   {"label": "Angeles City", "value": "C85166"},
                   {"label": "Antipolo", "value": "C85167"},
                   {"label": "Apalit", "value": "C85168"},
                   {"label": "Arayat", "value": "C85169"},
                   {"label": "Arenas", "value": "C85170"},
                   {"label": "Arminia", "value": "C85171"},
                   {"label": "Babo-Pangulo", "value": "C85172"},
                   {"label": "Bacabac", "value": "C85173"},
                   {"label": "Bacolor", "value": "C85174"},
                   {"label": "Bacsay", "value": "C85175"},
                   {"label": "Bagac", "value": "C85176"},
                   {"label": "Bagong Barrio", "value": "C85177"},
                   {"label": "Bagong-Sikat", "value": "C85178"},
                   {"label": "Bahay Pare", "value": "C85179"},
                   {"label": "Bakulong", "value": "C85180"},
                   {"label": "Balagtas", "value": "C85181"},
                   {"label": "Balanga", "value": "C85182"},
                   {"label": "Balaoang", "value": "C85183"},
                   {"label": "Balas", "value": "C85184"},
                   {"label": "Balasing", "value": "C85185"},
                   {"label": "Balayang", "value": "C85186"},
                   {"label": "Baler", "value": "C85187"},
                   {"label": "Balibago", "value": "C85188"},
                   {"label": "Balingcanaway", "value": "C85189"},
                   {"label": "Balite", "value": "C85190"},
                   {"label": "Baliuag", "value": "C85191"},
                   {"label": "Baloc", "value": "C85192"},
                   {"label": "Baloy", "value": "C85193"},
                   {"label": "Balsic", "value": "C85194"},
                   {"label": "Balucuc", "value": "C85195"},
                   {"label": "Balut", "value": "C85196"},
                   {"label": "Balutu", "value": "C85197"},
                   {"label": "Bamban", "value": "C85198"},
                   {"label": "Banawang", "value": "C85199"},
                   {"label": "Bangad", "value": "C85200"},
                   {"label": "Bani", "value": "C85201"},
                   {"label": "Baquero Norte", "value": "C85202"},
                   {"label": "Batasan Bata", "value": "C85203"},
                   {"label": "Batitang", "value": "C85204"},
                   {"label": "Bayanan", "value": "C85205"},
                   {"label": "Beddeng", "value": "C85206"},
                   {"label": "Biay", "value": "C85207"},
                   {"label": "Bibiclat", "value": "C85208"},
                   {"label": "Bicos", "value": "C85209"},
                   {"label": "Biga", "value": "C85210"},
                   {"label": "Bilad", "value": "C85211"},
                   {"label": "Bitungol", "value": "C85212"},
                   {"label": "Bobon Second", "value": "C85213"},
                   {"label": "Bocaue", "value": "C85214"},
                   {"label": "Bodega", "value": "C85215"},
                   {"label": "Bolitoc", "value": "C85216"},
                   {"label": "Bongabon", "value": "C85217"},
                   {"label": "Botolan", "value": "C85218"},
                   {"label": "Buenlag", "value": "C85219"},
                   {"label": "Buensuseso", "value": "C85220"},
                   {"label": "Bulaon", "value": "C85221"},
                   {"label": "Bularit", "value": "C85222"},
                   {"label": "Bulawin", "value": "C85223"},
                   {"label": "Bulihan", "value": "C85224"},
                   {"label": "Buliran", "value": "C85225"},
                   {"label": "Buliran Segundo", "value": "C85226"},
                   {"label": "Bulualto", "value": "C85227"},
                   {"label": "Bundoc", "value": "C85228"},
                   {"label": "Bunol", "value": "C85229"},
                   {"label": "Burgos", "value": "C85230"},
                   {"label": "Bustos", "value": "C85231"},
                   {"label": "Cabanatuan City", "value": "C85232"},
                   {"label": "Cabangan", "value": "C85233"},
                   {"label": "Cabayaoasan", "value": "C85234"},
                   {"label": "Cabcaben", "value": "C85235"},
                   {"label": "Cabiao", "value": "C85236"},
                   {"label": "Cabog", "value": "C85237"},
                   {"label": "Cafe", "value": "C85238"},
                   {"label": "Calaba", "value": "C85239"},
                   {"label": "Calancuasan Norte", "value": "C85240"},
                   {"label": "Calangain", "value": "C85241"},
                   {"label": "Calantas", "value": "C85242"},
                   {"label": "Calayaan", "value": "C85243"},
                   {"label": "Calibungan", "value": "C85244"},
                   {"label": "Calibutbut", "value": "C85245"},
                   {"label": "Calingcuan", "value": "C85246"},
                   {"label": "Calumpang", "value": "C85247"},
                   {"label": "Calumpit", "value": "C85248"},
                   {"label": "Cama Juan", "value": "C85249"},
                   {"label": "Camachile", "value": "C85250"},
                   {"label": "Camias", "value": "C85251"},
                   {"label": "Camiling", "value": "C85252"},
                   {"label": "Candaba", "value": "C85253"},
                   {"label": "Candating", "value": "C85254"},
                   {"label": "Capas", "value": "C85255"},
                   {"label": "Cardona", "value": "C85256"},
                   {"label": "Carmen", "value": "C85257"},
                   {"label": "Carranglan", "value": "C85258"},
                   {"label": "Castillejos", "value": "C85259"},
                   {"label": "Cauayan", "value": "C85260"},
                   {"label": "Cavite", "value": "C85261"},
                   {"label": "Cawayan Bugtong", "value": "C85262"},
                   {"label": "Comillas", "value": "C85263"},
                   {"label": "Communal", "value": "C85264"},
                   {"label": "Concepcion", "value": "C85265"},
                   {"label": "Conversion", "value": "C85266"},
                   {"label": "Culianin", "value": "C85267"},
                   {"label": "Culubasa", "value": "C85268"},
                   {"label": "Cut-cut Primero", "value": "C85269"},
                   {"label": "Cuyapo", "value": "C85270"},
                   {"label": "Dampol", "value": "C85271"},
                   {"label": "Del Carmen", "value": "C85272"},
                   {"label": "Del Pilar", "value": "C85273"},
                   {"label": "Digdig", "value": "C85274"},
                   {"label": "Diliman Primero", "value": "C85275"},
                   {"label": "Dinalupihan", "value": "C85276"},
                   {"label": "Dingalan", "value": "C85277"},
                   {"label": "Dolores", "value": "C85278"},
                   {"label": "Dona Remedios Trinidad", "value": "C85279"},
                   {"label": "Dumarais", "value": "C85280"},
                   {"label": "Entablado", "value": "C85281"},
                   {"label": "Estacion", "value": "C85282"},
                   {"label": "Estipona", "value": "C85283"},
                   {"label": "Estrella", "value": "C85284"},
                   {"label": "Floridablanca", "value": "C85285"},
                   {"label": "Gabaldon", "value": "C85286"},
                   {"label": "Gapan", "value": "C85287"},
                   {"label": "General Luna", "value": "C85288"},
                   {"label": "General Mamerto Natividad", "value": "C85289"},
                   {"label": "General Tinio", "value": "C85290"},
                   {"label": "Gerona", "value": "C85291"},
                   {"label": "Guagua", "value": "C85292"},
                   {"label": "Gueset", "value": "C85293"},
                   {"label": "Guiguinto", "value": "C85294"},
                   {"label": "Guimba", "value": "C85295"},
                   {"label": "Guisguis", "value": "C85296"},
                   {"label": "Gutad", "value": "C85297"},
                   {"label": "Guyong", "value": "C85298"},
                   {"label": "Hagonoy", "value": "C85299"},
                   {"label": "Hermosa", "value": "C85300"},
                   {"label": "Iba", "value": "C85301"},
                   {"label": "Jaen", "value": "C85302"},
                   {"label": "La Paz", "value": "C85303"},
                   {"label": "Lambakin", "value": "C85304"},
                   {"label": "Lanat", "value": "C85305"},
                   {"label": "Laug", "value": "C85306"},
                   {"label": "Laur", "value": "C85307"},
                   {"label": "Lawang Kupang", "value": "C85308"},
                   {"label": "Lennec", "value": "C85309"},
                   {"label": "Licab", "value": "C85310"},
                   {"label": "Liciada", "value": "C85311"},
                   {"label": "Ligaya", "value": "C85312"},
                   {"label": "Limay", "value": "C85313"},
                   {"label": "Liozon", "value": "C85314"},
                   {"label": "Lipay", "value": "C85315"},
                   {"label": "Llanera", "value": "C85316"},
                   {"label": "Loma de Gato", "value": "C85317"},
                   {"label": "Lomboy", "value": "C85318"},
                   {"label": "Lourdes", "value": "C85319"},
                   {"label": "Lubao", "value": "C85320"},
                   {"label": "Lucapon", "value": "C85321"},
                   {"label": "Lupao", "value": "C85322"},
                   {"label": "Maasim", "value": "C85323"},
                   {"label": "Mababanaba", "value": "C85324"},
                   {"label": "Mabalacat City", "value": "C85325"},
                   {"label": "Mabayo", "value": "C85326"},
                   {"label": "Mabilang", "value": "C85327"},
                   {"label": "Mabilog", "value": "C85328"},
                   {"label": "Mabini", "value": "C85329"},
                   {"label": "Macabebe", "value": "C85330"},
                   {"label": "Macapsing", "value": "C85331"},
                   {"label": "Macarse", "value": "C85332"},
                   {"label": "Macatbong", "value": "C85333"},
                   {"label": "Magalang", "value": "C85334"},
                   {"label": "Magliman", "value": "C85335"},
                   {"label": "Magtangol", "value": "C85336"},
                   {"label": "Maguinao", "value": "C85337"},
                   {"label": "Malabon", "value": "C85338"},
                   {"label": "Malacampa", "value": "C85339"},
                   {"label": "Maligaya", "value": "C85340"},
                   {"label": "Malino", "value": "C85341"},
                   {"label": "Malolos", "value": "C85342"},
                   {"label": "Maloma", "value": "C85343"},
                   {"label": "Maluid", "value": "C85344"},
                   {"label": "Malusac", "value": "C85345"},
                   {"label": "Mambog", "value": "C85346"},
                   {"label": "Mamonit", "value": "C85347"},
                   {"label": "Manacsac", "value": "C85348"},
                   {"label": "Manatal", "value": "C85349"},
                   {"label": "Mandili", "value": "C85350"},
                   {"label": "Mangga", "value": "C85351"},
                   {"label": "Manibaug Pasig", "value": "C85352"},
                   {"label": "Manogpi", "value": "C85353"},
                   {"label": "Mapalacsiao", "value": "C85354"},
                   {"label": "Mapalad", "value": "C85355"},
                   {"label": "Mapaniqui", "value": "C85356"},
                   {"label": "Maquiapo", "value": "C85357"},
                   {"label": "Marawa", "value": "C85358"},
                   {"label": "Maria Aurora", "value": "C85359"},
                   {"label": "Marilao", "value": "C85360"},
                   {"label": "Mariveles", "value": "C85361"},
                   {"label": "Masalipit", "value": "C85362"},
                   {"label": "Masantol", "value": "C85363"},
                   {"label": "Masinloc", "value": "C85364"},
                   {"label": "Matayumtayum", "value": "C85365"},
                   {"label": "Maturanoc", "value": "C85366"},
                   {"label": "Mayantoc", "value": "C85367"},
                   {"label": "Mexico", "value": "C85368"},
                   {"label": "Meycauayan", "value": "C85369"},
                   {"label": "Minalin", "value": "C85370"},
                   {"label": "Moncada", "value": "C85371"},
                   {"label": "Moriones", "value": "C85372"},
                   {"label": "Morong", "value": "C85373"},
                   {"label": "Motrico", "value": "C85374"},
                   {"label": "Munoz", "value": "C85375"},
                   {"label": "Murcia", "value": "C85376"},
                   {"label": "Nagpandayan", "value": "C85377"},
                   {"label": "Nambalan", "value": "C85378"},
                   {"label": "Nampicuan", "value": "C85379"},
                   {"label": "Nancamarinan", "value": "C85380"},
                   {"label": "Nieves", "value": "C85381"},
                   {"label": "Niugan", "value": "C85382"},
                   {"label": "Norzagaray", "value": "C85383"},
                   {"label": "Obando", "value": "C85384"},
                   {"label": "Olongapo", "value": "C85385"},
                   {"label": "Orani", "value": "C85386"},
                   {"label": "Orion", "value": "C85387"},
                   {"label": "Paco Roman", "value": "C85388"},
                   {"label": "Padapada", "value": "C85389"},
                   {"label": "Paitan Norte", "value": "C85390"},
                   {"label": "Palauig", "value": "C85391"},
                   {"label": "Palayan City", "value": "C85392"},
                   {"label": "Palusapis", "value": "C85393"},
                   {"label": "Pamatawan", "value": "C85394"},
                   {"label": "Panabingan", "value": "C85395"},
                   {"label": "Panan", "value": "C85396"},
                   {"label": "Pance", "value": "C85397"},
                   {"label": "Pandacaqui", "value": "C85398"},
                   {"label": "Pandi", "value": "C85399"},
                   {"label": "Pando", "value": "C85400"},
                   {"label": "Paniqui", "value": "C85401"},
                   {"label": "Panlinlang", "value": "C85402"},
                   {"label": "Pantabangan", "value": "C85403"},
                   {"label": "Pantubig", "value": "C85404"},
                   {"label": "Paombong", "value": "C85405"},
                   {"label": "Papaya", "value": "C85406"},
                   {"label": "Parang", "value": "C85407"},
                   {"label": "Parista", "value": "C85408"},
                   {"label": "Pau", "value": "C85409"},
                   {"label": "Penaranda", "value": "C85410"},
                   {"label": "Pias", "value": "C85411"},
                   {"label": "Pilar", "value": "C85412"},
                   {"label": "Pinahan", "value": "C85413"},
                   {"label": "Pinambaran", "value": "C85414"},
                   {"label": "Pio", "value": "C85415"},
                   {"label": "Plaridel", "value": "C85416"},
                   {"label": "Poblacion, San Felipe", "value": "C85417"},
                   {"label": "Porac", "value": "C85418"},
                   {"label": "Porais", "value": "C85419"},
                   {"label": "Prado Siongco", "value": "C85420"},
                   {"label": "Province of Aurora", "value": "C85421"},
                   {"label": "Province of Bataan", "value": "C85422"},
                   {"label": "Province of Bulacan", "value": "C85423"},
                   {"label": "Province of Nueva Ecija", "value": "C85424"},
                   {"label": "Province of Pampanga", "value": "C85425"},
                   {"label": "Province of Tarlac", "value": "C85426"},
                   {"label": "Province of Zambales", "value": "C85427"},
                   {"label": "Pulilan", "value": "C85428"},
                   {"label": "Pulo", "value": "C85429"},
                   {"label": "Pulong Gubat", "value": "C85430"},
                   {"label": "Pulong Sampalok", "value": "C85431"},
                   {"label": "Pulung Santol", "value": "C85432"},
                   {"label": "Pulungmasle", "value": "C85433"},
                   {"label": "Puncan", "value": "C85434"},
                   {"label": "Pura", "value": "C85435"},
                   {"label": "Purac", "value": "C85436"},
                   {"label": "Putlod", "value": "C85437"},
                   {"label": "Quezon", "value": "C85438"},
                   {"label": "Rajal Norte", "value": "C85439"},
                   {"label": "Ramos", "value": "C85440"},
                   {"label": "Rizal", "value": "C85441"},
                   {"label": "Sabang", "value": "C85442"},
                   {"label": "Sagana", "value": "C85443"},
                   {"label": "Salapungan", "value": "C85444"},
                   {"label": "Salaza", "value": "C85445"},
                   {"label": "Salcedo", "value": "C85446"},
                   {"label": "Salvacion I", "value": "C85447"},
                   {"label": "Samal", "value": "C85448"},
                   {"label": "Sampaloc", "value": "C85449"},
                   {"label": "San Agustin", "value": "C85450"},
                   {"label": "San Alejandro", "value": "C85451"},
                   {"label": "San Andres", "value": "C85452"},
                   {"label": "San Anton", "value": "C85453"},
                   {"label": "San Antonio", "value": "C85454"},
                   {"label": "San Basilio", "value": "C85455"},
                   {"label": "San Benito", "value": "C85456"},
                   {"label": "San Carlos", "value": "C85457"},
                   {"label": "San Casimiro", "value": "C85458"},
                   {"label": "San Clemente", "value": "C85459"},
                   {"label": "San Cristobal", "value": "C85460"},
                   {"label": "San Fabian", "value": "C85461"},
                   {"label": "San Felipe", "value": "C85462"},
                   {"label": "San Felipe Old", "value": "C85463"},
                   {"label": "San Fernando", "value": "C85464"},
                   {"label": "San Francisco", "value": "C85465"},
                   {"label": "San Ildefonso", "value": "C85466"},
                   {"label": "San Isidro", "value": "C85467"},
                   {"label": "San Jacinto", "value": "C85468"},
                   {"label": "San Jose", "value": "C85469"},
                   {"label": "San Jose del Monte", "value": "C85470"},
                   {"label": "San Juan", "value": "C85471"},
                   {"label": "San Juan de Mata", "value": "C85472"},
                   {"label": "San Leonardo", "value": "C85473"},
                   {"label": "San Lorenzo", "value": "C85474"},
                   {"label": "San Luis", "value": "C85475"},
                   {"label": "San Manuel", "value": "C85476"},
                   {"label": "San Marcelino", "value": "C85477"},
                   {"label": "San Mariano", "value": "C85478"},
                   {"label": "San Mateo", "value": "C85479"},
                   {"label": "San Miguel", "value": "C85480"},
                   {"label": "San Narciso", "value": "C85481"},
                   {"label": "San Nicolas", "value": "C85482"},
                   {"label": "San Pascual", "value": "C85483"},
                   {"label": "San Patricio", "value": "C85484"},
                   {"label": "San Rafael", "value": "C85485"},
                   {"label": "San Ricardo", "value": "C85486"},
                   {"label": "San Roque", "value": "C85487"},
                   {"label": "San Roque Dau First", "value": "C85488"},
                   {"label": "San Simon", "value": "C85489"},
                   {"label": "San Vicente", "value": "C85490"},
                   {"label": "San Vincente", "value": "C85491"},
                   {"label": "Santa Ana", "value": "C85492"},
                   {"label": "Santa Barbara", "value": "C85493"},
                   {"label": "Santa Cruz", "value": "C85494"},
                   {"label": "Santa Fe", "value": "C85495"},
                   {"label": "Santa Ignacia", "value": "C85496"},
                   {"label": "Santa Ines West", "value": "C85497"},
                   {"label": "Santa Juliana", "value": "C85498"},
                   {"label": "Santa Lucia", "value": "C85499"},
                   {"label": "Santa Maria", "value": "C85500"},
                   {"label": "Santa Monica", "value": "C85501"},
                   {"label": "Santa Rita", "value": "C85502"},
                   {"label": "Santa Rosa", "value": "C85503"},
                   {"label": "Santa Teresa First", "value": "C85504"},
                   {"label": "Santiago", "value": "C85505"},
                   {"label": "Santo Cristo", "value": "C85506"},
                   {"label": "Santo Domingo", "value": "C85507"},
                   {"label": "Santo Nino", "value": "C85508"},
                   {"label": "Santo Rosario", "value": "C85509"},
                   {"label": "Santo Tomas", "value": "C85510"},
                   {"label": "Santol", "value": "C85511"},
                   {"label": "Sapang", "value": "C85512"},
                   {"label": "Sapang Buho", "value": "C85513"},
                   {"label": "Sapol", "value": "C85514"},
                   {"label": "Saysain", "value": "C85515"},
                   {"label": "Sexmoan", "value": "C85516"},
                   {"label": "Sibul", "value": "C85517"},
                   {"label": "Siclong", "value": "C85518"},
                   {"label": "Sinait", "value": "C85519"},
                   {"label": "Sinilian First", "value": "C85520"},
                   {"label": "Soledad", "value": "C85521"},
                   {"label": "Subic", "value": "C85522"},
                   {"label": "Suklayin", "value": "C85523"},
                   {"label": "Sula", "value": "C85524"},
                   {"label": "Sulucan", "value": "C85525"},
                   {"label": "Tabacao", "value": "C85526"},
                   {"label": "Tabon", "value": "C85527"},
                   {"label": "Tabuating", "value": "C85528"},
                   {"label": "Tal I Mun Doc", "value": "C85529"},
                   {"label": "Talaga", "value": "C85530"},
                   {"label": "Talang", "value": "C85531"},
                   {"label": "Talavera", "value": "C85532"},
                   {"label": "Taltal", "value": "C85533"},
                   {"label": "Talugtug", "value": "C85534"},
                   {"label": "Tariji", "value": "C85535"},
                   {"label": "Tarlac City", "value": "C85536"},
                   {"label": "Tartaro", "value": "C85537"},
                   {"label": "Tayabo", "value": "C85538"},
                   {"label": "Telabastagan", "value": "C85539"},
                   {"label": "Tikiw", "value": "C85540"},
                   {"label": "Tinang", "value": "C85541"},
                   {"label": "Tondod", "value": "C85542"},
                   {"label": "Uacon", "value": "C85543"},
                   {"label": "Umiray", "value": "C85544"},
                   {"label": "Upig", "value": "C85545"},
                   {"label": "Vargas", "value": "C85546"},
                   {"label": "Villa Aglipay", "value": "C85547"},
                   {"label": "Villa Isla", "value": "C85548"},
                   {"label": "Vizal San Pablo", "value": "C85549"},
                   {"label": "Vizal Santo Nino", "value": "C85550"}],
 "Central Macedonia": [{"label": "adendro", "value": "C41817"},
                       {"label": "afytos", "value": "C41818"},
                       {"label": "Agia Paraskevi", "value": "C41819"},
                       {"label": "Agia Triada", "value": "C41820"},
                       {"label": "agio Pnevma", "value": "C41821"},
                       {"label": "agios Athanasios", "value": "C41822"},
                       {"label": "agios Georgios", "value": "C41823"},
                       {"label": "agios Loukas", "value": "C41824"},
                       {"label": "agios Nikolaos", "value": "C41825"},
                       {"label": "agios Pavlos", "value": "C41826"},
                       {"label": "agios Petros", "value": "C41827"},
                       {"label": "agios Spyridon", "value": "C41828"},
                       {"label": "agios Vasileios", "value": "C41829"},
                       {"label": "Agkathia", "value": "C41830"},
                       {"label": "Aiginio", "value": "C41831"},
                       {"label": "Akrolimni", "value": "C41832"},
                       {"label": "Alexandreia", "value": "C41833"},
                       {"label": "Alistrati", "value": "C41834"},
                       {"label": "Ampeleies", "value": "C41835"},
                       {"label": "Ampelokipoi", "value": "C41836"},
                       {"label": "Anatoliko", "value": "C41837"},
                       {"label": "Angelochori", "value": "C41838"},
                       {"label": "apsalos", "value": "C41839"},
                       {"label": "Aravissos", "value": "C41840"},
                       {"label": "Aridaia", "value": "C41841"},
                       {"label": "Arnaia", "value": "C41842"},
                       {"label": "arnissa", "value": "C41843"},
                       {"label": "Arsenio", "value": "C41844"},
                       {"label": "Askos", "value": "C41845"},
                       {"label": "Asprovalta", "value": "C41846"},
                       {"label": "assiros", "value": "C41847"},
                       {"label": "Asvestochori", "value": "C41848"},
                       {"label": "athyra", "value": "C41849"},
                       {"label": "Axioupoli", "value": "C41850"},
                       {"label": "Axos", "value": "C41851"},
                       {"label": "Chalastra", "value": "C41852"},
                       {"label": "Chalkidona", "value": "C41853"},
                       {"label": "Charopo", "value": "C41854"},
                       {"label": "Chortiatis", "value": "C41855"},
                       {"label": "Chryso", "value": "C41856"},
                       {"label": "Chrysochorafa", "value": "C41857"},
                       {"label": "Diavata", "value": "C41858"},
                       {"label": "Diavatos", "value": "C41859"},
                       {"label": "Dion", "value": "C41860"},
                       {"label": "Draviskos", "value": "C41861"},
                       {"label": "Drymos", "value": "C41862"},
                       {"label": "edessa", "value": "C41863"},
                       {"label": "Efkarpia", "value": "C41864"},
                       {"label": "Eirinoupoli", "value": "C41865"},
                       {"label": "Epanomi", "value": "C41866"},
                       {"label": "Episkopi", "value": "C41867"},
                       {"label": "evosmos", "value": "C41868"},
                       {"label": "Evropos", "value": "C41869"},
                       {"label": "Exaplatanos", "value": "C41870"},
                       {"label": "Filiro", "value": "C41871"},
                       {"label": "Galatades", "value": "C41872"},
                       {"label": "Galatista", "value": "C41873"},
                       {"label": "Gazoros", "value": "C41874"},
                       {"label": "Gefyra", "value": "C41875"},
                       {"label": "Gerakarou", "value": "C41876"},
                       {"label": "Giannitsa", "value": "C41877"},
                       {"label": "Goumenissa", "value": "C41878"},
                       {"label": "Ierissos", "value": "C41879"},
                       {"label": "Irakleia", "value": "C41880"},
                       {"label": "Kala Dendra", "value": "C41881"},
                       {"label": "Kalamaria", "value": "C41882"},
                       {"label": "Kali", "value": "C41883"},
                       {"label": "Kallithea", "value": "C41884"},
                       {"label": "Kalochori", "value": "C41885"},
                       {"label": "Kalyves Polygyrou", "value": "C41886"},
                       {"label": "Kalyvia", "value": "C41887"},
                       {"label": "Kampanis", "value": "C41888"},
                       {"label": "Kardia", "value": "C41889"},
                       {"label": "Karitsa", "value": "C41890"},
                       {"label": "Karyotissa", "value": "C41891"},
                       {"label": "Kassandreia", "value": "C41892"},
                       {"label": "Katerini", "value": "C41893"},
                       {"label": "Kato Kamila", "value": "C41894"},
                       {"label": "Kato Lipochori", "value": "C41895"},
                       {"label": "Kato Milia", "value": "C41896"},
                       {"label": "Kato Scholari", "value": "C41897"},
                       {"label": "Kavallari", "value": "C41898"},
                       {"label": "Kilkis", "value": "C41899"},
                       {"label": "Kitros", "value": "C41900"},
                       {"label": "Kleidi", "value": "C41901"},
                       {"label": "Koimisi", "value": "C41902"},
                       {"label": "Kolchikon", "value": "C41903"},
                       {"label": "Kolindros", "value": "C41904"},
                       {"label": "Kontariotissa", "value": "C41905"},
                       {"label": "Kopanos", "value": "C41906"},
                       {"label": "Korinos", "value": "C41907"},
                       {"label": "Koryfi", "value": "C41908"},
                       {"label": "Koufalia", "value": "C41909"},
                       {"label": "Kouloura", "value": "C41910"},
                       {"label": "Krithia", "value": "C41911"},
                       {"label": "Krya Vrysi", "value": "C41912"},
                       {"label": "Kymina", "value": "C41913"},
                       {"label": "Lagkadas", "value": "C41914"},
                       {"label": "Lagyna", "value": "C41915"},
                       {"label": "Lakkoma", "value": "C41916"},
                       {"label": "Lefkonas", "value": "C41917"},
                       {"label": "Leptokarya", "value": "C41918"},
                       {"label": "Lianovergi", "value": "C41919"},
                       {"label": "Liti", "value": "C41920"},
                       {"label": "Litochoro", "value": "C41921"},
                       {"label": "Lofos", "value": "C41922"},
                       {"label": "Loutraki", "value": "C41923"},
                       {"label": "Loutros", "value": "C41924"},
                       {"label": "Makrochori", "value": "C41925"},
                       {"label": "Makrygialos", "value": "C41926"},
                       {"label": "Mandalo", "value": "C41927"},
                       {"label": "Marina", "value": "C41928"},
                       {"label": "Mavrothalassa", "value": "C41929"},
                       {"label": "Mavrovouni", "value": "C41930"},
                       {"label": "Megali Panagia", "value": "C41931"},
                       {"label": "Meliki", "value": "C41932"},
                       {"label": "Melissi", "value": "C41933"},
                       {"label": "Melissochori", "value": "C41934"},
                       {"label": "Menemeni", "value": "C41935"},
                       {"label": "Mesimeri", "value": "C41936"},
                       {"label": "Mikro Monastiri", "value": "C41937"},
                       {"label": "Mitrousi", "value": "C41938"},
                       {"label": "Naousa", "value": "C41939"},
                       {"label": "Nea Apollonia", "value": "C41940"},
                       {"label": "Nea efesos", "value": "C41941"},
                       {"label": "Nea Flogita", "value": "C41942"},
                       {"label": "Nea Fokaia", "value": "C41943"},
                       {"label": "Nea Kallikrateia", "value": "C41944"},
                       {"label": "Nea Magnisia", "value": "C41945"},
                       {"label": "Nea Malgara", "value": "C41946"},
                       {"label": "Nea Mesimvria", "value": "C41947"},
                       {"label": "Nea Michaniona", "value": "C41948"},
                       {"label": "Nea Moudhania", "value": "C41949"},
                       {"label": "Nea Pella", "value": "C41950"},
                       {"label": "Nea Plagia", "value": "C41951"},
                       {"label": "Nea Poteidaia", "value": "C41952"},
                       {"label": "Nea Potidhaia", "value": "C41953"},
                       {"label": "Nea Roda", "value": "C41954"},
                       {"label": "Nea Santa", "value": "C41955"},
                       {"label": "Nea Triglia", "value": "C41956"},
                       {"label": "Nea Vrasna", "value": "C41957"},
                       {"label": "Nea Zichni", "value": "C41958"},
                       {"label": "Neapoli", "value": "C41959"},
                       {"label": "Neo Agioneri", "value": "C41960"},
                       {"label": "Neo Petritsi", "value": "C41961"},
                       {"label": "Neo Rysi", "value": "C41962"},
                       {"label": "Neo Souli", "value": "C41963"},
                       {"label": "Neochori", "value": "C41964"},
                       {"label": "Neochorouda", "value": "C41965"},
                       {"label": "Neoi Epivates", "value": "C41966"},
                       {"label": "Neos Marmaras", "value": "C41967"},
                       {"label": "Neos Mylotopos", "value": "C41968"},
                       {"label": "Neos Skopos", "value": "C41969"},
                       {"label": "Nigrita", "value": "C41970"},
                       {"label": "Nikiti", "value": "C41971"},
                       {"label": "Nisi", "value": "C41972"},
                       {"label": "Nomos Chalkidikis", "value": "C41973"},
                       {"label": "Nomos Pellis", "value": "C41974"},
                       {"label": "Nomos Thessalonikis", "value": "C41975"},
                       {"label": "olynthos", "value": "C41976"},
                       {"label": "Oraiokastro", "value": "C41977"},
                       {"label": "Ormylia", "value": "C41978"},
                       {"label": "Ouranoupolis", "value": "C41979"},
                       {"label": "Palaifyto", "value": "C41980"},
                       {"label": "Palaiochori", "value": "C41981"},
                       {"label": "Palaiokomi", "value": "C41982"},
                       {"label": "Panorama", "value": "C41983"},
                       {"label": "Paralia", "value": "C41984"},
                       {"label": "Patrida", "value": "C41985"},
                       {"label": "Pefka", "value": "C41986"},
                       {"label": "Pefkochori", "value": "C41987"},
                       {"label": "Pella", "value": "C41988"},
                       {"label": "Pentalofos", "value": "C41989"},
                       {"label": "Pentaplatano", "value": "C41990"},
                       {"label": "Peraia", "value": "C41991"},
                       {"label": "Peristasi", "value": "C41992"},
                       {"label": "Peristera", "value": "C41993"},
                       {"label": "Plagiari", "value": "C41994"},
                       {"label": "Platy", "value": "C41995"},
                       {"label": "Polichni", "value": "C41996"},
                       {"label": "Polygyros", "value": "C41997"},
                       {"label": "Polykarpi", "value": "C41998"},
                       {"label": "Polykastro", "value": "C41999"},
                       {"label": "Pontismeno", "value": "C42000"},
                       {"label": "Portaria", "value": "C42001"},
                       {"label": "Prochoma", "value": "C42002"},
                       {"label": "Profitis Ilias", "value": "C42003"},
                       {"label": "Promachoi", "value": "C42004"},
                       {"label": "Proti", "value": "C42005"},
                       {"label": "Provatas", "value": "C42006"},
                       {"label": "Pylaia", "value": "C42007"},
                       {"label": "Ritini", "value": "C42008"},
                       {"label": "Rizari", "value": "C42009"},
                       {"label": "Rizo", "value": "C42010"},
                       {"label": "Rizomata", "value": "C42011"},
                       {"label": "Rodolivos", "value": "C42012"},
                       {"label": "Sarti", "value": "C42013"},
                       {"label": "Serres", "value": "C42014"},
                       {"label": "Sevastiana", "value": "C42015"},
                       {"label": "Sfendami", "value": "C42016"},
                       {"label": "Sidirokastro", "value": "C42017"},
                       {"label": "Simantra", "value": "C42018"},
                       {"label": "Sindos", "value": "C42019"},
                       {"label": "Skotoussa", "value": "C42020"},
                       {"label": "Skoutari", "value": "C42021"},
                       {"label": "Skydra", "value": "C42022"},
                       {"label": "Sochos", "value": "C42023"},
                       {"label": "Sosandra", "value": "C42024"},
                       {"label": "Souroti", "value": "C42025"},
                       {"label": "Stathmos Mourion", "value": "C42026"},
                       {"label": "Stavros", "value": "C42027"},
                       {"label": "Stavroupoli", "value": "C42028"},
                       {"label": "Stratonion", "value": "C42029"},
                       {"label": "Strymoniko", "value": "C42030"},
                       {"label": "Svoronos", "value": "C42031"},
                       {"label": "Sykia", "value": "C42032"},
                       {"label": "Sykies", "value": "C42033"},
                       {"label": "Terpni", "value": "C42034"},
                       {"label": "Thermi", "value": "C42035"},
                       {"label": "Thessaloniki", "value": "C42036"},
                       {"label": "Triandria", "value": "C42037"},
                       {"label": "Trikala", "value": "C42038"},
                       {"label": "Trilofos", "value": "C42039"},
                       {"label": "Valtero", "value": "C42040"},
                       {"label": "Vamvakofyto", "value": "C42041"},
                       {"label": "Vasilika", "value": "C42042"},
                       {"label": "Vathylakkos", "value": "C42043"},
                       {"label": "Vergina", "value": "C42044"},
                       {"label": "Veroia", "value": "C42045"},
                       {"label": "Vrontou", "value": "C42046"},
                       {"label": "Xilopolis", "value": "C42047"}],
 "Central Ostrobothnia": [{"label": "Halsua", "value": "C24855"},
                          {"label": "Kalvia", "value": "C24856"},
                          {"label": "Kannus", "value": "C24857"},
                          {"label": "Kaustinen", "value": "C24858"},
                          {"label": "Kokkola", "value": "C24859"},
                          {"label": "Lestijarvi", "value": "C24860"},
                          {"label": "Lohtaja", "value": "C24861"},
                          {"label": "Perho", "value": "C24862"},
                          {"label": "Toholampi", "value": "C24863"},
                          {"label": "Ullava", "value": "C24864"},
                          {"label": "Veteli", "value": "C24865"}],
 "Central Province": [{"label": "Abau", "value": "C80392"},
                      {"label": "Goilala", "value": "C80393"},
                      {"label": "Kairuku-Hiri", "value": "C80394"},
                      {"label": "Rigo", "value": "C80395"},
                      {"label": "Tulagi", "value": "C107873"},
                      {"label": "Dambulla", "value": "C115242"},
                      {"label": "Hatton", "value": "C115243"},
                      {"label": "Kandy", "value": "C115244"},
                      {"label": "Kandy District", "value": "C115245"},
                      {"label": "Matale", "value": "C115246"},
                      {"label": "Matale District", "value": "C115247"},
                      {"label": "Nuwara Eliya", "value": "C115248"},
                      {"label": "Nuwara Eliya District", "value": "C115249"},
                      {"label": "Sigiriya", "value": "C115250"},
                      {"label": "Talawakele", "value": "C115251"},
                      {"label": "Chibombo", "value": "C148087"},
                      {"label": "Chibombo District", "value": "C148088"},
                      {"label": "Kabwe", "value": "C148089"},
                      {"label": "Kapiri Mposhi", "value": "C148090"},
                      {"label": "Mkushi", "value": "C148091"},
                      {"label": "Mumbwa", "value": "C148092"},
                      {"label": "Serenje", "value": "C148093"}],
 "Central Region": [{"label": "Chipoka", "value": "C65561"},
                    {"label": "Dedza", "value": "C65562"},
                    {"label": "Dedza District", "value": "C65563"},
                    {"label": "Dowa", "value": "C65564"},
                    {"label": "Dowa District", "value": "C65565"},
                    {"label": "Kasungu", "value": "C65566"},
                    {"label": "Kasungu District", "value": "C65567"},
                    {"label": "Lilongwe", "value": "C65568"},
                    {"label": "Lilongwe District", "value": "C65569"},
                    {"label": "Mchinji", "value": "C65570"},
                    {"label": "Mchinji District", "value": "C65571"},
                    {"label": "Mponela", "value": "C65572"},
                    {"label": "Nkhotakota", "value": "C65573"},
                    {"label": "Nkhotakota District", "value": "C65574"},
                    {"label": "Ntcheu", "value": "C65575"},
                    {"label": "Ntcheu District", "value": "C65576"},
                    {"label": "Ntchisi", "value": "C65577"},
                    {"label": "Ntchisi District", "value": "C65578"},
                    {"label": "Salima", "value": "C65579"},
                    {"label": "Salima District", "value": "C65580"},
                    {"label": "Banepa", "value": "C76019"},
                    {"label": "Bharatpur", "value": "C76020"},
                    {"label": "Birganj", "value": "C76021"},
                    {"label": "Dhulikhel", "value": "C76022"},
                    {"label": "Gaur", "value": "C76023"},
                    {"label": "Hari Bdr Tamang House", "value": "C76024"},
                    {"label": "Hetauda", "value": "C76025"},
                    {"label": "Jaleshwar", "value": "C76026"},
                    {"label": "Janakpur", "value": "C76027"},
                    {"label": "kankrabari Dovan", "value": "C76028"},
                    {"label": "Kathmandu", "value": "C76029"},
                    {"label": "Kirtipur", "value": "C76030"},
                    {"label": "Madhyapur Thimi", "value": "C76031"},
                    {"label": "Malangawa", "value": "C76032"},
                    {"label": "Nagarkot", "value": "C76033"},
                    {"label": "Panauti", "value": "C76034"},
                    {"label": "Patan", "value": "C76035"},
                    {"label": "Ramechhap", "value": "C76036"},
                    {"label": "Bukomansimbi District", "value": "C121200"},
                    {"label": "Buvuma District", "value": "C121201"},
                    {"label": "Bweyogerere", "value": "C121202"},
                    {"label": "Byakabanda", "value": "C121203"},
                    {"label": "Entebbe", "value": "C121204"},
                    {"label": "Gomba District", "value": "C121205"},
                    {"label": "Kajansi", "value": "C121206"},
                    {"label": "Kampala", "value": "C121207"},
                    {"label": "Kampala District", "value": "C121208"},
                    {"label": "Kanoni", "value": "C121209"},
                    {"label": "Kayunga", "value": "C121210"},
                    {"label": "Kiboga", "value": "C121211"},
                    {"label": "Kireka", "value": "C121212"},
                    {"label": "Kyotera", "value": "C121213"},
                    {"label": "Lugazi", "value": "C121214"},
                    {"label": "Luwero", "value": "C121215"},
                    {"label": "Lyantonde", "value": "C121216"},
                    {"label": "Masaka", "value": "C121217"},
                    {"label": "Mityana", "value": "C121218"},
                    {"label": "Mpigi", "value": "C121219"},
                    {"label": "Mubende", "value": "C121220"},
                    {"label": "Mubende District", "value": "C121221"},
                    {"label": "Mukono", "value": "C121222"},
                    {"label": "Nakasongola", "value": "C121223"},
                    {"label": "Namasuba", "value": "C121224"},
                    {"label": "Njeru", "value": "C121225"},
                    {"label": "Sembabule", "value": "C121226"},
                    {"label": "Wakiso", "value": "C121227"},
                    {"label": "Wakiso District", "value": "C121228"},
                    {"label": "Wobulenzi", "value": "C121229"}],
 "Central River Division": [{"label": "Bansang", "value": "C34153"},
                            {"label": "Brikama Nding", "value": "C34154"},
                            {"label": "Dankunku", "value": "C34155"},
                            {"label": "Denton", "value": "C34156"},
                            {"label": "Fulladu West", "value": "C34157"},
                            {"label": "Galleh Manda", "value": "C34158"},
                            {"label": "Georgetown", "value": "C34159"},
                            {"label": "Jakhaly", "value": "C34160"},
                            {"label": "Janjanbureh", "value": "C34161"},
                            {"label": "Jarreng", "value": "C34162"},
                            {"label": "Karantaba", "value": "C34163"},
                            {"label": "Kass Wollof", "value": "C34164"},
                            {"label": "Kuntaur", "value": "C34165"},
                            {"label": "Kunting", "value": "C34166"},
                            {"label": "Lower Saloum", "value": "C34167"},
                            {"label": "Niamina East District",
                             "value": "C34168"},
                            {"label": "Niamina West District",
                             "value": "C34169"},
                            {"label": "Niani", "value": "C34170"},
                            {"label": "Nianija District", "value": "C34171"},
                            {"label": "Pateh Sam", "value": "C34172"},
                            {"label": "Sami", "value": "C34173"},
                            {"label": "Sami District", "value": "C34174"},
                            {"label": "Saruja", "value": "C34175"},
                            {"label": "Sukuta", "value": "C34176"},
                            {"label": "Upper Saloum", "value": "C34177"},
                            {"label": "Wassu", "value": "C34178"}],
 "Central Singapore Community Development Council": [{"label": "Singapore",
                                                      "value": "C107327"}],
 "Centrale Region": [{"label": "Sokode", "value": "C119625"},
                     {"label": "Sotouboua", "value": "C119626"},
                     {"label": "Tchamba", "value": "C119627"}],
 "Centre": [{"label": "Kadiogo Province", "value": "C16367"},
            {"label": "Ouagadougou", "value": "C16368"},
            {"label": "Akono", "value": "C16577"},
            {"label": "Akonolinga", "value": "C16578"},
            {"label": "Bafia", "value": "C16579"},
            {"label": "Eseka", "value": "C16580"},
            {"label": "Esse", "value": "C16581"},
            {"label": "evodoula", "value": "C16582"},
            {"label": "Mbalmayo", "value": "C16583"},
            {"label": "Mbam-Et-Inoubou", "value": "C16584"},
            {"label": "Mbandjok", "value": "C16585"},
            {"label": "Mbankomo", "value": "C16586"},
            {"label": "Mefou-et-Akono", "value": "C16587"},
            {"label": "Mfoundi", "value": "C16588"},
            {"label": "Minta", "value": "C16589"},
            {"label": "Nanga Eboko", "value": "C16590"},
            {"label": "Ndikinimeki", "value": "C16591"},
            {"label": "Ngomedzap", "value": "C16592"},
            {"label": "Ngoro", "value": "C16593"},
            {"label": "Nkoteng", "value": "C16594"},
            {"label": "Ntui", "value": "C16595"},
            {"label": "Obala", "value": "C16596"},
            {"label": "Okoa", "value": "C16597"},
            {"label": "Okola", "value": "C16598"},
            {"label": "Ombesa", "value": "C16599"},
            {"label": "Saa", "value": "C16600"},
            {"label": "Yaounde", "value": "C16601"},
            {"label": "Yoko", "value": "C16602"},
            {"label": "Arrondissement de Cerca La Source", "value": "C43090"},
            {"label": "Cerca la Source", "value": "C43091"},
            {"label": "Hinche", "value": "C43092"},
            {"label": "Lascahobas", "value": "C43093"},
            {"label": "Mayisad", "value": "C43094"},
            {"label": "Mirebalais", "value": "C43095"},
            {"label": "Thomassique", "value": "C43096"},
            {"label": "Thomonde", "value": "C43097"}],
 "Centre-Est Region": [{"label": "Garango", "value": "C16369"},
                       {"label": "Koupela", "value": "C16370"},
                       {"label": "Kouritenga Province", "value": "C16371"},
                       {"label": "Ouargaye", "value": "C16372"},
                       {"label": "Province du Boulgou", "value": "C16373"},
                       {"label": "Province du Koulpelogo", "value": "C16374"},
                       {"label": "Tenkodogo", "value": "C16375"}],
 "Centre-Nord Region": [{"label": "Boulsa", "value": "C16376"},
                        {"label": "Kaya", "value": "C16377"},
                        {"label": "Kongoussi", "value": "C16378"},
                        {"label": "Province du Bam", "value": "C16379"},
                        {"label": "Province du Namentenga", "value": "C16380"},
                        {"label": "Province du Sanmatenga", "value": "C16381"}],
 "Centre-Ouest Region": [{"label": "Gouloure", "value": "C16382"},
                         {"label": "Kokologo", "value": "C16383"},
                         {"label": "Koudougou", "value": "C16384"},
                         {"label": "Leo", "value": "C16385"},
                         {"label": "Pitmoaga", "value": "C16386"},
                         {"label": "Province de la Sissili", "value": "C16387"},
                         {"label": "Province du Boulkiemde", "value": "C16388"},
                         {"label": "Province du Sanguie", "value": "C16389"},
                         {"label": "Province du Ziro", "value": "C16390"},
                         {"label": "Reo", "value": "C16391"},
                         {"label": "Sapouy", "value": "C16392"}],
 "Centre-Sud Region": [{"label": "Bazega Province", "value": "C16393"},
                       {"label": "Kombissiri", "value": "C16394"},
                       {"label": "Manga", "value": "C16395"},
                       {"label": "Nahouri Province", "value": "C16396"},
                       {"label": "Po", "value": "C16397"},
                       {"label": "Zoundweogo Province", "value": "C16398"}],
 "Centre-Val de Loire": [{"label": "Abilly", "value": "C27575"},
                         {"label": "Abondant", "value": "C27576"},
                         {"label": "Aigurande", "value": "C27577"},
                         {"label": "Ambillou", "value": "C27578"},
                         {"label": "Amboise", "value": "C27579"},
                         {"label": "Amilly", "value": "C27580"},
                         {"label": "Anet", "value": "C27581"},
                         {"label": "Ardentes", "value": "C27582"},
                         {"label": "Argent-sur-Sauldre", "value": "C27583"},
                         {"label": "Argenton-sur-Creuse", "value": "C27584"},
                         {"label": "Armenonville-les-Gatineaux",
                          "value": "C27585"},
                         {"label": "Arrou", "value": "C27586"},
                         {"label": "Artannes-sur-Indre", "value": "C27587"},
                         {"label": "Artenay", "value": "C27588"},
                         {"label": "Arthon", "value": "C27589"},
                         {"label": "Ascheres-le-Marche", "value": "C27590"},
                         {"label": "Athee-sur-Cher", "value": "C27591"},
                         {"label": "Aubigny-sur-Nere", "value": "C27592"},
                         {"label": "Aunay-sous-Auneau", "value": "C27593"},
                         {"label": "Auneau", "value": "C27594"},
                         {"label": "Authon-du-Perche", "value": "C27595"},
                         {"label": "Autry-le-Chatel", "value": "C27596"},
                         {"label": "Auzouer-en-Touraine", "value": "C27597"},
                         {"label": "Avoine", "value": "C27598"},
                         {"label": "Avord", "value": "C27599"},
                         {"label": "Azay-le-Ferron", "value": "C27600"},
                         {"label": "Azay-le-Rideau", "value": "C27601"},
                         {"label": "Azay-sur-Cher", "value": "C27602"},
                         {"label": "Aze", "value": "C27603"},
                         {"label": "Bailleau-leveque", "value": "C27604"},
                         {"label": "Bailleau-le-Pin", "value": "C27605"},
                         {"label": "Ballan-Mire", "value": "C27606"},
                         {"label": "Barjouville", "value": "C27607"},
                         {"label": "Baugy", "value": "C27608"},
                         {"label": "Baule", "value": "C27609"},
                         {"label": "Bazoches-les-Gallerandes",
                          "value": "C27610"},
                         {"label": "Beaugency", "value": "C27611"},
                         {"label": "Beaulieu-les-Loches", "value": "C27612"},
                         {"label": "Beaumont-en-Veron", "value": "C27613"},
                         {"label": "Beaumont-la-Ronce", "value": "C27614"},
                         {"label": "Beaune-la-Rolande", "value": "C27615"},
                         {"label": "Belabre", "value": "C27616"},
                         {"label": "Bellegarde", "value": "C27617"},
                         {"label": "Belleville-sur-Loire", "value": "C27618"},
                         {"label": "Beville-le-Comte", "value": "C27619"},
                         {"label": "Blancafort", "value": "C27620"},
                         {"label": "Blere", "value": "C27621"},
                         {"label": "Blois", "value": "C27622"},
                         {"label": "Boigny-sur-Bionne", "value": "C27623"},
                         {"label": "Boiscommun", "value": "C27624"},
                         {"label": "Bonneval", "value": "C27625"},
                         {"label": "Bonny-sur-Loire", "value": "C27626"},
                         {"label": "Boulleret", "value": "C27627"},
                         {"label": "Bourges", "value": "C27628"},
                         {"label": "Bourgueil", "value": "C27629"},
                         {"label": "Bouzy-la-Foret", "value": "C27630"},
                         {"label": "Boynes", "value": "C27631"},
                         {"label": "Bracieux", "value": "C27632"},
                         {"label": "Bray-en-Val", "value": "C27633"},
                         {"label": "Brezolles", "value": "C27634"},
                         {"label": "Briare", "value": "C27635"},
                         {"label": "Bricy", "value": "C27636"},
                         {"label": "Brinon-sur-Sauldre", "value": "C27637"},
                         {"label": "Brou", "value": "C27638"},
                         {"label": "Bu", "value": "C27639"},
                         {"label": "Buzancais", "value": "C27640"},
                         {"label": "Cande-sur-Beuvron", "value": "C27641"},
                         {"label": "Cangey", "value": "C27642"},
                         {"label": "Cellettes", "value": "C27643"},
                         {"label": "Cepoy", "value": "C27644"},
                         {"label": "Cerdon", "value": "C27645"},
                         {"label": "Cerelles", "value": "C27646"},
                         {"label": "Chabris", "value": "C27647"},
                         {"label": "Chaillac", "value": "C27648"},
                         {"label": "Chailles", "value": "C27649"},
                         {"label": "Chaingy", "value": "C27650"},
                         {"label": "Chalette-sur-Loing", "value": "C27651"},
                         {"label": "Chambourg-sur-Indre", "value": "C27652"},
                         {"label": "Chambray-les-Tours", "value": "C27653"},
                         {"label": "Champhol", "value": "C27654"},
                         {"label": "Chanceaux-sur-Choisille",
                          "value": "C27655"},
                         {"label": "Changy-les-Bois", "value": "C27656"},
                         {"label": "Chanteau", "value": "C27657"},
                         {"label": "Chantome", "value": "C27658"},
                         {"label": "Charentilly", "value": "C27659"},
                         {"label": "Charenton-du-Cher", "value": "C27660"},
                         {"label": "Charge", "value": "C27661"},
                         {"label": "Charost", "value": "C27662"},
                         {"label": "Chartres", "value": "C27663"},
                         {"label": "Chateau-la-Valliere", "value": "C27664"},
                         {"label": "Chateau-Renard", "value": "C27665"},
                         {"label": "Chateau-Renault", "value": "C27666"},
                         {"label": "Chateaudun", "value": "C27667"},
                         {"label": "Chateaumeillant", "value": "C27668"},
                         {"label": "Chateauneuf-en-Thymerais",
                          "value": "C27669"},
                         {"label": "Chateauneuf-sur-Cher", "value": "C27670"},
                         {"label": "Chateauneuf-sur-Loire", "value": "C27671"},
                         {"label": "Chateauroux", "value": "C27672"},
                         {"label": "Chatillon-Coligny", "value": "C27673"},
                         {"label": "Chatillon-sur-Cher", "value": "C27674"},
                         {"label": "Chatillon-sur-Indre", "value": "C27675"},
                         {"label": "Chatres-sur-Cher", "value": "C27676"},
                         {"label": "Chaudon", "value": "C27677"},
                         {"label": "Chaumont-sur-Loire", "value": "C27678"},
                         {"label": "Chaumont-sur-Tharonne", "value": "C27679"},
                         {"label": "Checy", "value": "C27680"},
                         {"label": "Cheille", "value": "C27681"},
                         {"label": "Cher", "value": "C27682"},
                         {"label": "Cherisy", "value": "C27683"},
                         {"label": "Cheverny", "value": "C27684"},
                         {"label": "Chevillon-sur-Huillard", "value": "C27685"},
                         {"label": "Chevilly", "value": "C27686"},
                         {"label": "Chilleurs-aux-Bois", "value": "C27687"},
                         {"label": "Chinon", "value": "C27688"},
                         {"label": "Chissay-en-Touraine", "value": "C27689"},
                         {"label": "Chitenay", "value": "C27690"},
                         {"label": "Chouze-sur-Loire", "value": "C27691"},
                         {"label": "Chouzy-sur-Cisse", "value": "C27692"},
                         {"label": "Cinq-Mars-la-Pile", "value": "C27693"},
                         {"label": "Civray-de-Touraine", "value": "C27694"},
                         {"label": "Clere-les-Pins", "value": "C27695"},
                         {"label": "Clery-Saint-Andre", "value": "C27696"},
                         {"label": "Clion", "value": "C27697"},
                         {"label": "Cloyes-sur-le-Loir", "value": "C27698"},
                         {"label": "Cluis", "value": "C27699"},
                         {"label": "Contres", "value": "C27700"},
                         {"label": "Corbeilles", "value": "C27701"},
                         {"label": "Cormeray", "value": "C27702"},
                         {"label": "Cormery", "value": "C27703"},
                         {"label": "Corquilleroy", "value": "C27704"},
                         {"label": "Coullons", "value": "C27705"},
                         {"label": "Coulombs", "value": "C27706"},
                         {"label": "Cour-Cheverny", "value": "C27707"},
                         {"label": "Cours-les-Barres", "value": "C27708"},
                         {"label": "Courtenay", "value": "C27709"},
                         {"label": "Courville-sur-Eure", "value": "C27710"},
                         {"label": "Cuffy", "value": "C27711"},
                         {"label": "Dadonville", "value": "C27712"},
                         {"label": "Dammarie", "value": "C27713"},
                         {"label": "Dampierre-en-Burly", "value": "C27714"},
                         {"label": "Darvoy", "value": "C27715"},
                         {"label": "Deols", "value": "C27716"},
                         {"label": "Departement dEure-et-Loir",
                          "value": "C27717"},
                         {"label": "Departement dIndre-et-Loire",
                          "value": "C27718"},
                         {"label": "Departement de lIndre", "value": "C27719"},
                         {"label": "Departement du Loir-et-Cher",
                          "value": "C27720"},
                         {"label": "Descartes", "value": "C27721"},
                         {"label": "Dhuizon", "value": "C27722"},
                         {"label": "Donnery", "value": "C27723"},
                         {"label": "Dordives", "value": "C27724"},
                         {"label": "Douchy", "value": "C27725"},
                         {"label": "Dreux", "value": "C27726"},
                         {"label": "Droue", "value": "C27727"},
                         {"label": "Droue-sur-Drouette", "value": "C27728"},
                         {"label": "Dry", "value": "C27729"},
                         {"label": "Dun-sur-Auron", "value": "C27730"},
                         {"label": "ecueille", "value": "C27731"},
                         {"label": "eguzon-Chantome", "value": "C27732"},
                         {"label": "epernon", "value": "C27733"},
                         {"label": "epieds-en-Beauce", "value": "C27734"},
                         {"label": "Esvres", "value": "C27735"},
                         {"label": "Faverolles-sur-Cher", "value": "C27736"},
                         {"label": "Fay-aux-Loges", "value": "C27737"},
                         {"label": "Ferolles", "value": "C27738"},
                         {"label": "Fleury-les-Aubrais", "value": "C27739"},
                         {"label": "Foecy", "value": "C27740"},
                         {"label": "Fondettes", "value": "C27741"},
                         {"label": "Fontaine-la-Guyon", "value": "C27742"},
                         {"label": "Fontenay-sur-Loing", "value": "C27743"},
                         {"label": "Fussy", "value": "C27744"},
                         {"label": "Gallardon", "value": "C27745"},
                         {"label": "Garnay", "value": "C27746"},
                         {"label": "Gasville-Oiseme", "value": "C27747"},
                         {"label": "Genille", "value": "C27748"},
                         {"label": "Gidy", "value": "C27749"},
                         {"label": "Gien", "value": "C27750"},
                         {"label": "Gievres", "value": "C27751"},
                         {"label": "Gracay", "value": "C27752"},
                         {"label": "Hanches", "value": "C27753"},
                         {"label": "Henrichemont", "value": "C27754"},
                         {"label": "Herbault", "value": "C27755"},
                         {"label": "Herry", "value": "C27756"},
                         {"label": "Huismes", "value": "C27757"},
                         {"label": "Huisseau-sur-Cosson", "value": "C27758"},
                         {"label": "Huisseau-sur-Mauves", "value": "C27759"},
                         {"label": "Ingre", "value": "C27760"},
                         {"label": "Issoudun", "value": "C27761"},
                         {"label": "Janville", "value": "C27762"},
                         {"label": "Jargeau", "value": "C27763"},
                         {"label": "Joue-les-Tours", "value": "C27764"},
                         {"label": "Jouet-sur-lAubois", "value": "C27765"},
                         {"label": "Jouy", "value": "C27766"},
                         {"label": "Jouy-le-Potier", "value": "C27767"},
                         {"label": "LIle-Bouchard", "value": "C27768"},
                         {"label": "La Bazoche-Gouet", "value": "C27769"},
                         {"label": "La Celle-Saint-Avant", "value": "C27770"},
                         {"label": "La Chapelle-du-Noyer", "value": "C27771"},
                         {"label": "La Chapelle-Saint-Mesmin",
                          "value": "C27772"},
                         {"label": "La Chapelle-Saint-Ursin",
                          "value": "C27773"},
                         {"label": "La Chapelle-sur-Loire", "value": "C27774"},
                         {"label": "La Chatre", "value": "C27775"},
                         {"label": "La Chaussee-Saint-Victor",
                          "value": "C27776"},
                         {"label": "La Croix-en-Touraine", "value": "C27777"},
                         {"label": "La Ferte-Imbault", "value": "C27778"},
                         {"label": "La Ferte-Saint-Aubin", "value": "C27779"},
                         {"label": "La Loupe", "value": "C27780"},
                         {"label": "La Membrolle-sur-Choisille",
                          "value": "C27781"},
                         {"label": "La Riche", "value": "C27782"},
                         {"label": "La Ville-aux-Clercs", "value": "C27783"},
                         {"label": "La Ville-aux-Dames", "value": "C27784"},
                         {"label": "Ladon", "value": "C27785"},
                         {"label": "Lailly-en-Val", "value": "C27786"},
                         {"label": "Lamotte-Beuvron", "value": "C27787"},
                         {"label": "Langeais", "value": "C27788"},
                         {"label": "Larcay", "value": "C27789"},
                         {"label": "Le Blanc", "value": "C27790"},
                         {"label": "Le Chatelet", "value": "C27791"},
                         {"label": "Le Coudray", "value": "C27792"},
                         {"label": "Le Grand-Pressigny", "value": "C27793"},
                         {"label": "Le Pechereau", "value": "C27794"},
                         {"label": "Le Poinconnet", "value": "C27795"},
                         {"label": "Lere", "value": "C27796"},
                         {"label": "Les Aix-dAngillon", "value": "C27797"},
                         {"label": "Les Montils", "value": "C27798"},
                         {"label": "Leves", "value": "C27799"},
                         {"label": "Levet", "value": "C27800"},
                         {"label": "Levroux", "value": "C27801"},
                         {"label": "Lignieres", "value": "C27802"},
                         {"label": "Ligny-le-Ribault", "value": "C27803"},
                         {"label": "Ligre", "value": "C27804"},
                         {"label": "Ligueil", "value": "C27805"},
                         {"label": "Loches", "value": "C27806"},
                         {"label": "Loiret", "value": "C27807"},
                         {"label": "Lorris", "value": "C27808"},
                         {"label": "Loury", "value": "C27809"},
                         {"label": "Luant", "value": "C27810"},
                         {"label": "Lucay-le-Male", "value": "C27811"},
                         {"label": "Luce", "value": "C27812"},
                         {"label": "Luisant", "value": "C27813"},
                         {"label": "Lunay", "value": "C27814"},
                         {"label": "Lunery", "value": "C27815"},
                         {"label": "Luray", "value": "C27816"},
                         {"label": "Luynes", "value": "C27817"},
                         {"label": "Maintenon", "value": "C27818"},
                         {"label": "Mainvilliers", "value": "C27819"},
                         {"label": "Malesherbes", "value": "C27820"},
                         {"label": "Manthelan", "value": "C27821"},
                         {"label": "Marboue", "value": "C27822"},
                         {"label": "Marcilly-en-Villette", "value": "C27823"},
                         {"label": "Mardie", "value": "C27824"},
                         {"label": "Mareau-aux-Pres", "value": "C27825"},
                         {"label": "Mareuil-sur-Cher", "value": "C27826"},
                         {"label": "Margon", "value": "C27827"},
                         {"label": "Marigny-les-Usages", "value": "C27828"},
                         {"label": "Marmagne", "value": "C27829"},
                         {"label": "Martizay", "value": "C27830"},
                         {"label": "Massay", "value": "C27831"},
                         {"label": "Mazieres-de-Touraine", "value": "C27832"},
                         {"label": "Mehun-sur-Yevre", "value": "C27833"},
                         {"label": "Menestreau-en-Villette", "value": "C27834"},
                         {"label": "Menetou-Salon", "value": "C27835"},
                         {"label": "Mer", "value": "C27836"},
                         {"label": "Mereau", "value": "C27837"},
                         {"label": "Mettray", "value": "C27838"},
                         {"label": "Meung-sur-Loire", "value": "C27839"},
                         {"label": "Mezieres-en-Brenne", "value": "C27840"},
                         {"label": "Mezieres-en-Drouais", "value": "C27841"},
                         {"label": "Mondoubleau", "value": "C27842"},
                         {"label": "Monnaie", "value": "C27843"},
                         {"label": "Mont-pres-Chambord", "value": "C27844"},
                         {"label": "Montargis", "value": "C27845"},
                         {"label": "Montbazon", "value": "C27846"},
                         {"label": "Montcresson", "value": "C27847"},
                         {"label": "Montgivray", "value": "C27848"},
                         {"label": "Montierchaume", "value": "C27849"},
                         {"label": "Montlivault", "value": "C27850"},
                         {"label": "Montlouis-sur-Loire", "value": "C27851"},
                         {"label": "Montoire-sur-le-Loir", "value": "C27852"},
                         {"label": "Montrichard", "value": "C27853"},
                         {"label": "Monts", "value": "C27854"},
                         {"label": "Morancez", "value": "C27855"},
                         {"label": "Moree", "value": "C27856"},
                         {"label": "Muides-sur-Loire", "value": "C27857"},
                         {"label": "Mur-de-Sologne", "value": "C27858"},
                         {"label": "Nargis", "value": "C27859"},
                         {"label": "Naveil", "value": "C27860"},
                         {"label": "Nazelles-Negron", "value": "C27861"},
                         {"label": "Nerondes", "value": "C27862"},
                         {"label": "Neuille-Pont-Pierre", "value": "C27863"},
                         {"label": "Neung-sur-Beuvron", "value": "C27864"},
                         {"label": "Neuville-aux-Bois", "value": "C27865"},
                         {"label": "Neuvy-le-Roi", "value": "C27866"},
                         {"label": "Neuvy-Pailloux", "value": "C27867"},
                         {"label": "Neuvy-Saint-Sepulchre", "value": "C27868"},
                         {"label": "Neuvy-sur-Barangeon", "value": "C27869"},
                         {"label": "Nevoy", "value": "C27870"},
                         {"label": "Niherne", "value": "C27871"},
                         {"label": "Nogent-le-Phaye", "value": "C27872"},
                         {"label": "Nogent-le-Roi", "value": "C27873"},
                         {"label": "Nogent-le-Rotrou", "value": "C27874"},
                         {"label": "Nogent-sur-Vernisson", "value": "C27875"},
                         {"label": "Noizay", "value": "C27876"},
                         {"label": "Nouan-le-Fuzelier", "value": "C27877"},
                         {"label": "Nouzilly", "value": "C27878"},
                         {"label": "Noyers-sur-Cher", "value": "C27879"},
                         {"label": "Olivet", "value": "C27880"},
                         {"label": "Onzain", "value": "C27881"},
                         {"label": "Orgeres-en-Beauce", "value": "C27882"},
                         {"label": "Orleans", "value": "C27883"},
                         {"label": "Ormes", "value": "C27884"},
                         {"label": "Orval", "value": "C27885"},
                         {"label": "Oucques", "value": "C27886"},
                         {"label": "Oulins", "value": "C27887"},
                         {"label": "Outarville", "value": "C27888"},
                         {"label": "Ouzouer-le-Marche", "value": "C27889"},
                         {"label": "Ouzouer-sur-Loire", "value": "C27890"},
                         {"label": "Ouzouer-sur-Trezee", "value": "C27891"},
                         {"label": "Pannes", "value": "C27892"},
                         {"label": "Parcay-Meslay", "value": "C27893"},
                         {"label": "Patay", "value": "C27894"},
                         {"label": "Perrusson", "value": "C27895"},
                         {"label": "Pierres", "value": "C27896"},
                         {"label": "Pithiviers", "value": "C27897"},
                         {"label": "Pithiviers-le-Vieil", "value": "C27898"},
                         {"label": "Plaimpied-Givaudins", "value": "C27899"},
                         {"label": "Poce-sur-Cisse", "value": "C27900"},
                         {"label": "Poilly-lez-Gien", "value": "C27901"},
                         {"label": "Pontlevoy", "value": "C27902"},
                         {"label": "Pouligny-Saint-Pierre", "value": "C27903"},
                         {"label": "Preuilly-sur-Claise", "value": "C27904"},
                         {"label": "Pruniers-en-Sologne", "value": "C27905"},
                         {"label": "Puiseaux", "value": "C27906"},
                         {"label": "Quiers-sur-Bezonde", "value": "C27907"},
                         {"label": "Rebrechien", "value": "C27908"},
                         {"label": "Reignac-sur-Indre", "value": "C27909"},
                         {"label": "Restigne", "value": "C27910"},
                         {"label": "Reugny", "value": "C27911"},
                         {"label": "Reuilly", "value": "C27912"},
                         {"label": "Rians", "value": "C27913"},
                         {"label": "Richelieu", "value": "C27914"},
                         {"label": "Rochecorbon", "value": "C27915"},
                         {"label": "Romorantin-Lanthenay", "value": "C27916"},
                         {"label": "Rouziers-de-Touraine", "value": "C27917"},
                         {"label": "Sache", "value": "C27918"},
                         {"label": "Saint-Aignan", "value": "C27919"},
                         {"label": "Saint-Amand-Longpre", "value": "C27920"},
                         {"label": "Saint-Amand-Montrond", "value": "C27921"},
                         {"label": "Saint-Antoine-du-Rocher",
                          "value": "C27922"},
                         {"label": "Saint-Aubin-des-Bois", "value": "C27923"},
                         {"label": "Saint-Avertin", "value": "C27924"},
                         {"label": "Saint-Ay", "value": "C27925"},
                         {"label": "Saint-Benoit-sur-Loire", "value": "C27926"},
                         {"label": "Saint-Branchs", "value": "C27927"},
                         {"label": "Saint-Brisson-sur-Loire",
                          "value": "C27928"},
                         {"label": "Saint-Christophe-sur-le-Nais",
                          "value": "C27929"},
                         {"label": "Saint-Claude-de-Diray", "value": "C27930"},
                         {"label": "Saint-Cyr-en-Val", "value": "C27931"},
                         {"label": "Saint-Cyr-sur-Loire", "value": "C27932"},
                         {"label": "Saint-Denis-en-Val", "value": "C27933"},
                         {"label": "Saint-Denis-les-Ponts", "value": "C27934"},
                         {"label": "Saint-Doulchard", "value": "C27935"},
                         {"label": "Saint-Dye-sur-Loire", "value": "C27936"},
                         {"label": "Saint-eloy-de-Gy", "value": "C27937"},
                         {"label": "Saint-epain", "value": "C27938"},
                         {"label": "Saint-etienne-de-Chigny",
                          "value": "C27939"},
                         {"label": "Saint-Florent-sur-Cher", "value": "C27940"},
                         {"label": "Saint-Gaultier", "value": "C27941"},
                         {"label": "Saint-Georges-sur-Cher", "value": "C27942"},
                         {"label": "Saint-Georges-sur-Eure", "value": "C27943"},
                         {"label": "Saint-Germain-des-Pres", "value": "C27944"},
                         {"label": "Saint-Germain-du-Puy", "value": "C27945"},
                         {"label": "Saint-Gervais-la-Foret", "value": "C27946"},
                         {"label": "Saint-Hilaire-Saint-Mesmin",
                          "value": "C27947"},
                         {"label": "Saint-Jean-de-Braye", "value": "C27948"},
                         {"label": "Saint-Jean-de-la-Ruelle",
                          "value": "C27949"},
                         {"label": "Saint-Jean-le-Blanc", "value": "C27950"},
                         {"label": "Saint-Laurent-Nouan", "value": "C27951"},
                         {"label": "Saint-Lubin-des-Joncherets",
                          "value": "C27952"},
                         {"label": "Saint-Marcel", "value": "C27953"},
                         {"label": "Saint-Martin-dAuxigny", "value": "C27954"},
                         {"label": "Saint-Martin-de-Nigelles",
                          "value": "C27955"},
                         {"label": "Saint-Martin-le-Beau", "value": "C27956"},
                         {"label": "Saint-Martin-sur-Ocre", "value": "C27957"},
                         {"label": "Saint-Maur", "value": "C27958"},
                         {"label": "Saint-Maurice-sur-Fessard",
                          "value": "C27959"},
                         {"label": "Saint-Nicolas-de-Bourgueil",
                          "value": "C27960"},
                         {"label": "Saint-Ouen", "value": "C27961"},
                         {"label": "Saint-Paterne-Racan", "value": "C27962"},
                         {"label": "Saint-Pere-sur-Loire", "value": "C27963"},
                         {"label": "Saint-Piat", "value": "C27964"},
                         {"label": "Saint-Pierre-des-Corps", "value": "C27965"},
                         {"label": "Saint-Prest", "value": "C27966"},
                         {"label": "Saint-Pryve-Saint-Mesmin",
                          "value": "C27967"},
                         {"label": "Saint-Remy-sur-Avre", "value": "C27968"},
                         {"label": "Saint-Romain-sur-Cher", "value": "C27969"},
                         {"label": "Saint-Satur", "value": "C27970"},
                         {"label": "Saint-Sulpice-de-Pommeray",
                          "value": "C27971"},
                         {"label": "Saint-Viatre", "value": "C27972"},
                         {"label": "Sainte-Genevieve-des-Bois",
                          "value": "C27973"},
                         {"label": "Sainte-Lizaigne", "value": "C27974"},
                         {"label": "Sainte-Maure-de-Touraine",
                          "value": "C27975"},
                         {"label": "Sainte-Solange", "value": "C27976"},
                         {"label": "Salbris", "value": "C27977"},
                         {"label": "Sancerre", "value": "C27978"},
                         {"label": "Sancoins", "value": "C27979"},
                         {"label": "Sandillon", "value": "C27980"},
                         {"label": "Saran", "value": "C27981"},
                         {"label": "Sarge-sur-Braye", "value": "C27982"},
                         {"label": "Saussay", "value": "C27983"},
                         {"label": "Savigne-sur-Lathan", "value": "C27984"},
                         {"label": "Savigny-en-Sancerre", "value": "C27985"},
                         {"label": "Savigny-en-Veron", "value": "C27986"},
                         {"label": "Savigny-sur-Braye", "value": "C27987"},
                         {"label": "Savonnieres", "value": "C27988"},
                         {"label": "Seigy", "value": "C27989"},
                         {"label": "Selles-Saint-Denis", "value": "C27990"},
                         {"label": "Selles-sur-Cher", "value": "C27991"},
                         {"label": "Semblancay", "value": "C27992"},
                         {"label": "Semoy", "value": "C27993"},
                         {"label": "Senonches", "value": "C27994"},
                         {"label": "Sermaises", "value": "C27995"},
                         {"label": "Soings-en-Sologne", "value": "C27996"},
                         {"label": "Sonzay", "value": "C27997"},
                         {"label": "Sorel-Moussel", "value": "C27998"},
                         {"label": "Sorigny", "value": "C27999"},
                         {"label": "Souesmes", "value": "C28000"},
                         {"label": "Sours", "value": "C28001"},
                         {"label": "Suevres", "value": "C28002"},
                         {"label": "Sully-sur-Loire", "value": "C28003"},
                         {"label": "Tauxigny", "value": "C28004"},
                         {"label": "Tavers", "value": "C28005"},
                         {"label": "Theillay", "value": "C28006"},
                         {"label": "Thesee", "value": "C28007"},
                         {"label": "Thilouze", "value": "C28008"},
                         {"label": "Thiron Gardais", "value": "C28009"},
                         {"label": "Tigy", "value": "C28010"},
                         {"label": "Tournon-Saint-Martin", "value": "C28011"},
                         {"label": "Tours", "value": "C28012"},
                         {"label": "Toury", "value": "C28013"},
                         {"label": "Trainou", "value": "C28014"},
                         {"label": "Treon", "value": "C28015"},
                         {"label": "Trigueres", "value": "C28016"},
                         {"label": "Trouy", "value": "C28017"},
                         {"label": "Truyes", "value": "C28018"},
                         {"label": "Unverre", "value": "C28019"},
                         {"label": "Valencay", "value": "C28020"},
                         {"label": "Vasselay", "value": "C28021"},
                         {"label": "Vatan", "value": "C28022"},
                         {"label": "Veigne", "value": "C28023"},
                         {"label": "Vendoeuvres", "value": "C28024"},
                         {"label": "Vendome", "value": "C28025"},
                         {"label": "Vennecy", "value": "C28026"},
                         {"label": "Veretz", "value": "C28027"},
                         {"label": "Vernou-sur-Brenne", "value": "C28028"},
                         {"label": "Vernouillet", "value": "C28029"},
                         {"label": "Vert-en-Drouais", "value": "C28030"},
                         {"label": "Vienne-en-Val", "value": "C28031"},
                         {"label": "Vierzon", "value": "C28032"},
                         {"label": "Vignoux-sur-Barangeon", "value": "C28033"},
                         {"label": "Villandry", "value": "C28034"},
                         {"label": "Villebarou", "value": "C28035"},
                         {"label": "Villedieu-sur-Indre", "value": "C28036"},
                         {"label": "Villedomer", "value": "C28037"},
                         {"label": "Villefranche-sur-Cher", "value": "C28038"},
                         {"label": "Villemandeur", "value": "C28039"},
                         {"label": "Villemeux-sur-Eure", "value": "C28040"},
                         {"label": "Villiers-le-Morhier", "value": "C28041"},
                         {"label": "Villiers-sur-Loir", "value": "C28042"},
                         {"label": "Vimory", "value": "C28043"},
                         {"label": "Vineuil", "value": "C28044"},
                         {"label": "Vitry-aux-Loges", "value": "C28045"},
                         {"label": "Vouvray", "value": "C28046"},
                         {"label": "Vouzon", "value": "C28047"},
                         {"label": "Voves", "value": "C28048"},
                         {"label": "Yevres", "value": "C28049"},
                         {"label": "Yzeures-sur-Creuse", "value": "C28050"}],
 "Centro Sur Province": [{"label": "Acurenam", "value": "C24469"},
                         {"label": "Bicurga", "value": "C24470"},
                         {"label": "Evinayong", "value": "C24471"}],
 "Cerklje na Gorenjskem Municipality": [{"label": "Cerklje na Gorenjskem",
                                         "value": "C107594"}],
 "Cerknica Municipality": [{"label": "Cerknica", "value": "C107595"},
                           {"label": "Rakek", "value": "C107596"}],
 "Cerkno Municipality": [{"label": "Cerkno", "value": "C107597"}],
 "Cerkvenjak Municipality": [{"label": "Cerkvenjak", "value": "C107598"}],
 "Cerro Largo Department": [{"label": "Acegua", "value": "C146860"},
                            {"label": "Isidoro Noblia", "value": "C146861"},
                            {"label": "Melo", "value": "C146862"},
                            {"label": "Rio Branco", "value": "C146863"},
                            {"label": "Tupambae", "value": "C146864"}],
 "Cesar": [{"label": "Aguachica", "value": "C19977"},
           {"label": "Agustin Codazzi", "value": "C19978"},
           {"label": "Astrea", "value": "C19979"},
           {"label": "Becerril", "value": "C19980"},
           {"label": "Bosconia", "value": "C19981"},
           {"label": "Chimichagua", "value": "C19982"},
           {"label": "Chiriguana", "value": "C19983"},
           {"label": "Curumani", "value": "C19984"},
           {"label": "El Copey", "value": "C19985"},
           {"label": "El Paso", "value": "C19986"},
           {"label": "Gamarra", "value": "C19987"},
           {"label": "Gonzalez", "value": "C19988"},
           {"label": "La Gloria", "value": "C19989"},
           {"label": "La Jagua de Ibirico", "value": "C19990"},
           {"label": "La Paz", "value": "C19991"},
           {"label": "Manaure Balcon del Cesar", "value": "C19992"},
           {"label": "Pailitas", "value": "C19993"},
           {"label": "Pelaya", "value": "C19994"},
           {"label": "Pueblo Bello", "value": "C19995"},
           {"label": "Rio de Oro", "value": "C19996"},
           {"label": "San Alberto", "value": "C19997"},
           {"label": "San Diego", "value": "C19998"},
           {"label": "San Martin", "value": "C19999"},
           {"label": "Tamalameque", "value": "C20000"},
           {"label": "Valledupar", "value": "C20001"}],
 "Cesinovo-Oblesevo Municipality": [{"label": "Cesinovo", "value": "C65385"},
                                    {"label": "Oblesevo", "value": "C65386"}],
 "Cesis Municipality": [{"label": "Cesis", "value": "C64857"}],
 "Cesvaine Municipality": [{"label": "Cesvaine", "value": "C64858"}],
 "Ceuta": [{"label": "Benzu", "value": "C111658"},
           {"label": "Ceuta", "value": "C111659"}],
 "Chachoengsao": [{"label": "Amphoe Ban Pho", "value": "C118472"},
                  {"label": "Amphoe Bang Khla", "value": "C118473"},
                  {"label": "Amphoe Bang Nam Priao", "value": "C118474"},
                  {"label": "Amphoe Bang Pakong", "value": "C118475"},
                  {"label": "Amphoe Khlong Khuean", "value": "C118476"},
                  {"label": "Amphoe Mueang Chachoengsao", "value": "C118477"},
                  {"label": "Amphoe Phanom Sarakham", "value": "C118478"},
                  {"label": "Amphoe Plaeng Yao", "value": "C118479"},
                  {"label": "Amphoe Ratchasan", "value": "C118480"},
                  {"label": "Amphoe Sanam Chai Khet", "value": "C118481"},
                  {"label": "Amphoe Tha Takiap", "value": "C118482"},
                  {"label": "Bang Khla", "value": "C118483"},
                  {"label": "Bang Nam Priao", "value": "C118484"},
                  {"label": "Bang Pakong", "value": "C118485"},
                  {"label": "Chachoengsao", "value": "C118486"},
                  {"label": "Khlong Khuean", "value": "C118487"},
                  {"label": "Phanom Sarakham", "value": "C118488"}],
 "Chaco": [{"label": "Avia Terai", "value": "C628"},
           {"label": "Barranqueras", "value": "C629"},
           {"label": "Basail", "value": "C630"},
           {"label": "Campo Largo", "value": "C631"},
           {"label": "Capitan Solari", "value": "C632"},
           {"label": "Castelli", "value": "C633"},
           {"label": "Charadai", "value": "C634"},
           {"label": "Charata", "value": "C635"},
           {"label": "Chorotis", "value": "C636"},
           {"label": "Ciervo Petiso", "value": "C637"},
           {"label": "Colonia Benitez", "value": "C638"},
           {"label": "Colonia Elisa", "value": "C639"},
           {"label": "Colonias Unidas", "value": "C640"},
           {"label": "Concepcion del Bermejo", "value": "C641"},
           {"label": "Coronel Du Graty", "value": "C642"},
           {"label": "Corzuela", "value": "C643"},
           {"label": "Cote-Lai", "value": "C644"},
           {"label": "Departamento de Almirante Brown", "value": "C645"},
           {"label": "Departamento de Bermejo", "value": "C646"},
           {"label": "Departamento de Comandante Fernandez", "value": "C647"},
           {"label": "Departamento de Doce de Octubre", "value": "C648"},
           {"label": "Departamento de Dos de Abril", "value": "C649"},
           {"label": "Departamento de General Donovan", "value": "C650"},
           {"label": "Departamento de General Guemes", "value": "C651"},
           {"label": "Departamento de Independencia", "value": "C652"},
           {"label": "Departamento de Libertad", "value": "C653"},
           {"label": "Departamento de Maipu", "value": "C654"},
           {"label": "Departamento de Nueve de Julio", "value": "C655"},
           {"label": "Departamento de OHiggins", "value": "C656"},
           {"label": "Departamento de Presidencia de la Plaza",
            "value": "C657"},
           {"label": "Departamento de Quitilipi", "value": "C658"},
           {"label": "Departamento de San Fernando", "value": "C659"},
           {"label": "Departamento de San Lorenzo", "value": "C660"},
           {"label": "Departamento de Sargento Cabral", "value": "C661"},
           {"label": "Departamento de Tapenaga", "value": "C662"},
           {"label": "Fontana", "value": "C663"},
           {"label": "Gancedo", "value": "C664"},
           {"label": "General Jose de San Martin", "value": "C665"},
           {"label": "General Pinedo", "value": "C666"},
           {"label": "General Vedia", "value": "C667"},
           {"label": "Hermoso Campo", "value": "C668"},
           {"label": "La Clotilde", "value": "C669"},
           {"label": "La Eduvigis", "value": "C670"},
           {"label": "La Escondida", "value": "C671"},
           {"label": "La Leonesa", "value": "C672"},
           {"label": "La Tigra", "value": "C673"},
           {"label": "La Verde", "value": "C674"},
           {"label": "Laguna Limpia", "value": "C675"},
           {"label": "Lapachito", "value": "C676"},
           {"label": "Las Brenas", "value": "C677"},
           {"label": "Las Garcitas", "value": "C678"},
           {"label": "Los Frentones", "value": "C679"},
           {"label": "Machagai", "value": "C680"},
           {"label": "Makalle", "value": "C681"},
           {"label": "Margarita Belen", "value": "C682"},
           {"label": "Napenay", "value": "C683"},
           {"label": "Pampa Almiron", "value": "C684"},
           {"label": "Pampa del Indio", "value": "C685"},
           {"label": "Pampa del Infierno", "value": "C686"},
           {"label": "Presidencia de la Plaza", "value": "C687"},
           {"label": "Presidencia Roca", "value": "C688"},
           {"label": "Presidencia Roque Saenz Pena", "value": "C689"},
           {"label": "Puerto Bermejo", "value": "C690"},
           {"label": "Puerto Tirol", "value": "C691"},
           {"label": "Puerto Vilelas", "value": "C692"},
           {"label": "Quitilipi", "value": "C693"},
           {"label": "Resistencia", "value": "C694"},
           {"label": "Samuhu", "value": "C695"},
           {"label": "San Bernardo", "value": "C696"},
           {"label": "Santa Sylvina", "value": "C697"},
           {"label": "Taco Pozo", "value": "C698"},
           {"label": "Tres Isletas", "value": "C699"},
           {"label": "Villa angela", "value": "C700"},
           {"label": "Villa Berthet", "value": "C701"}],
 "Chagang Province": [{"label": "Changgang-gun", "value": "C78536"},
                      {"label": "Chasong", "value": "C78537"},
                      {"label": "Kanggye", "value": "C78538"},
                      {"label": "Manpo", "value": "C78539"}],
 "Chaguanas": [{"label": "Chaguanas", "value": "C119656"},
               {"label": "Ward of Chaguanas", "value": "C119657"}],
 "Chaharmahal and Bakhtiari": [{"label": "Ben", "value": "C50078"},
                               {"label": "Borujen", "value": "C50079"},
                               {"label": "Chelgard", "value": "C50080"},
                               {"label": "Farrokh Shahr", "value": "C50081"},
                               {"label": "Farsan", "value": "C50082"},
                               {"label": "Saman", "value": "C50083"},
                               {"label": "Shahr-e Kord", "value": "C50084"},
                               {"label": "Shahrekord", "value": "C50085"},
                               {"label": "Shahrestan-e Borujen",
                                "value": "C50086"},
                               {"label": "Shahrestan-e Farsan",
                                "value": "C50087"},
                               {"label": "Shahrestan-e Kiar",
                                "value": "C50088"},
                               {"label": "Shahrestan-e Kuhrang",
                                "value": "C50089"},
                               {"label": "Shahrestan-e Lordegan",
                                "value": "C50090"}],
 "Chai Nat": [{"label": "Amphoe Hankha", "value": "C118489"},
              {"label": "Amphoe Manorom", "value": "C118490"},
              {"label": "Amphoe Mueang Chainat", "value": "C118491"},
              {"label": "Amphoe Noen Kham", "value": "C118492"},
              {"label": "Amphoe Nong Mamong", "value": "C118493"},
              {"label": "Amphoe Sankhaburi", "value": "C118494"},
              {"label": "Amphoe Sapphaya", "value": "C118495"},
              {"label": "Amphoe Wat Sing", "value": "C118496"},
              {"label": "Chai Nat", "value": "C118497"}],
 "Chaiyaphum": [{"label": "Bamnet Narong", "value": "C118498"},
                {"label": "Chaiyaphum", "value": "C118499"},
                {"label": "Kaeng Khro", "value": "C118500"},
                {"label": "Kaset Sombun", "value": "C118501"},
                {"label": "Nakhon Nayok", "value": "C118502"},
                {"label": "Phu Khiao", "value": "C118503"}],
 "Chalatenango Department": [{"label": "Chalatenango", "value": "C24373"},
                             {"label": "Nueva Concepcion", "value": "C24374"}],
 "Champasak Province": [{"label": "Champasak", "value": "C64778"},
                        {"label": "Muang Bachiangchaleunsook",
                         "value": "C64779"},
                        {"label": "Muang Champasak", "value": "C64780"},
                        {"label": "Muang Khong", "value": "C64781"},
                        {"label": "Muang Mounlapamok", "value": "C64782"},
                        {"label": "Muang Pakxe", "value": "C64783"},
                        {"label": "Muang Pakxong", "value": "C64784"},
                        {"label": "Muang Pathoumphon", "value": "C64785"},
                        {"label": "Muang Phonthong", "value": "C64786"},
                        {"label": "Muang Soukhouma", "value": "C64787"},
                        {"label": "Muang Xanasomboun", "value": "C64788"},
                        {"label": "Pakse", "value": "C64789"},
                        {"label": "Pakxong", "value": "C64790"}],
 "Chandigarh": [{"label": "Chandigarh", "value": "C45313"}],
 "Changhua": [{"label": "Changhua", "value": "C117955"},
              {"label": "Yuanlin", "value": "C117956"}],
 "Chanthaburi": [{"label": "Amphoe Kaeng Hang Maeo", "value": "C118504"},
                 {"label": "Amphoe Khao Khitchakut", "value": "C118505"},
                 {"label": "Amphoe Khlung", "value": "C118506"},
                 {"label": "Amphoe Laem Sing", "value": "C118507"},
                 {"label": "Amphoe Makham", "value": "C118508"},
                 {"label": "Amphoe Mueang Chanthaburi", "value": "C118509"},
                 {"label": "Amphoe Na Yai Am", "value": "C118510"},
                 {"label": "Amphoe Pong Nam Ron", "value": "C118511"},
                 {"label": "Amphoe Soi Dao", "value": "C118512"},
                 {"label": "Amphoe Tha Mai", "value": "C118513"},
                 {"label": "Chanthaburi", "value": "C118514"},
                 {"label": "Khlung", "value": "C118515"},
                 {"label": "Laem Sing", "value": "C118516"},
                 {"label": "Pong Nam Ron", "value": "C118517"},
                 {"label": "Tha Mai", "value": "C118518"}],
 "Charlotte Parish": [{"label": "Biabou", "value": "C106216"},
                      {"label": "Byera Village", "value": "C106217"},
                      {"label": "Georgetown", "value": "C106218"}],
 "Chatham Islands": [{"label": "Waitangi", "value": "C77784"}],
 "Chechen Republic": [{"label": "Achkhoy-Martan", "value": "C100651"},
                      {"label": "Achkhoy-Martanovskiy Rayon",
                       "value": "C100652"},
                      {"label": "Alkhan-Kala", "value": "C100653"},
                      {"label": "Alkhan-Yurt", "value": "C100654"},
                      {"label": "Alkhazurovo", "value": "C100655"},
                      {"label": "Alleroy", "value": "C100656"},
                      {"label": "Alpatovo", "value": "C100657"},
                      {"label": "Argun", "value": "C100658"},
                      {"label": "Assinovskaya", "value": "C100659"},
                      {"label": "Avtury", "value": "C100660"},
                      {"label": "Bachi-Yurt", "value": "C100661"},
                      {"label": "Bamut", "value": "C100662"},
                      {"label": "Belgatoy", "value": "C100663"},
                      {"label": "Benoy-Yurt", "value": "C100664"},
                      {"label": "Borzoy", "value": "C100665"},
                      {"label": "Chechen-Aul", "value": "C100666"},
                      {"label": "Chiri-Yurt", "value": "C100667"},
                      {"label": "Duba-Yurt", "value": "C100668"},
                      {"label": "Dyshne-Vedeno", "value": "C100669"},
                      {"label": "Dzhalka", "value": "C100670"},
                      {"label": "Elin-Yurt", "value": "C100671"},
                      {"label": "Engel-Yurt", "value": "C100672"},
                      {"label": "Gekhi", "value": "C100673"},
                      {"label": "Germenchuk", "value": "C100674"},
                      {"label": "Gerzel-Aul", "value": "C100675"},
                      {"label": "Goragorskiy", "value": "C100676"},
                      {"label": "Goyty", "value": "C100677"},
                      {"label": "Grebenskaya", "value": "C100678"},
                      {"label": "Groznenskiy Rayon", "value": "C100679"},
                      {"label": "Groznyy", "value": "C100680"},
                      {"label": "Gudermes", "value": "C100681"},
                      {"label": "Gudermesskiy Rayon", "value": "C100682"},
                      {"label": "Ilaskhan-Yurt", "value": "C100683"},
                      {"label": "Ishcherskaya", "value": "C100684"},
                      {"label": "Ishkhoy-Yurt", "value": "C100685"},
                      {"label": "Itum-Kali", "value": "C100686"},
                      {"label": "Itum-Kalinskiy Rayon", "value": "C100687"},
                      {"label": "Kalinovskaya", "value": "C100688"},
                      {"label": "Kargalinskaya", "value": "C100689"},
                      {"label": "Katyr-Yurt", "value": "C100690"},
                      {"label": "Khambi-Irze", "value": "C100691"},
                      {"label": "Khankala", "value": "C100692"},
                      {"label": "Kulary", "value": "C100693"},
                      {"label": "Kurchaloy", "value": "C100694"},
                      {"label": "Lakha Nevre", "value": "C100695"},
                      {"label": "Lakkha Nevre", "value": "C100696"},
                      {"label": "Martan-Chu", "value": "C100697"},
                      {"label": "Mayrtup", "value": "C100698"},
                      {"label": "Mesker-Yurt", "value": "C100699"},
                      {"label": "Nadterechnyy Rayon", "value": "C100700"},
                      {"label": "Naurskaya", "value": "C100701"},
                      {"label": "Naurskiy Rayon", "value": "C100702"},
                      {"label": "Nogamerzin-Yurt", "value": "C100703"},
                      {"label": "Novyye Atagi", "value": "C100704"},
                      {"label": "Nozhay-Yurt", "value": "C100705"},
                      {"label": "Nozhay-Yurtovskiy Rayon", "value": "C100706"},
                      {"label": "Oyskhara", "value": "C100707"},
                      {"label": "Pervomayskaya", "value": "C100708"},
                      {"label": "Petropavlovskaya", "value": "C100709"},
                      {"label": "Prigorodnoye", "value": "C100710"},
                      {"label": "Roshni-Chu", "value": "C100711"},
                      {"label": "Samashki", "value": "C100712"},
                      {"label": "Sernovodsk", "value": "C100713"},
                      {"label": "Serzhen-Yurt", "value": "C100714"},
                      {"label": "Shaami-Yurt", "value": "C100715"},
                      {"label": "Shalazhi", "value": "C100716"},
                      {"label": "Shali", "value": "C100717"},
                      {"label": "Shalinskiy Rayon", "value": "C100718"},
                      {"label": "Shatoy", "value": "C100719"},
                      {"label": "Shelkovskaya", "value": "C100720"},
                      {"label": "Shelkovskiy Rayon", "value": "C100721"},
                      {"label": "Staraya Sunzha", "value": "C100722"},
                      {"label": "Staryye Atagi", "value": "C100723"},
                      {"label": "Tolstoy-Yurt", "value": "C100724"},
                      {"label": "Tsentoroy", "value": "C100725"},
                      {"label": "Tsotsin-Yurt", "value": "C100726"},
                      {"label": "Urus-Martan", "value": "C100727"},
                      {"label": "Urus-Martanovskiy Rayon", "value": "C100728"},
                      {"label": "Valerik", "value": "C100729"},
                      {"label": "Vedeno", "value": "C100730"},
                      {"label": "Vedenskiy Rayon", "value": "C100731"},
                      {"label": "Yalkhoy-Mokhk", "value": "C100732"},
                      {"label": "Zakan-Yurt", "value": "C100733"},
                      {"label": "Zandak", "value": "C100734"},
                      {"label": "Znamenskoye", "value": "C100735"}],
 "Chelyabinsk Oblast": [{"label": "Agapovka", "value": "C100736"},
                        {"label": "Agapovskiy Rayon", "value": "C100737"},
                        {"label": "Argayash", "value": "C100738"},
                        {"label": "Asha", "value": "C100739"},
                        {"label": "Ashinskiy Rayon", "value": "C100740"},
                        {"label": "Bakal", "value": "C100741"},
                        {"label": "Bazhovo", "value": "C100742"},
                        {"label": "Berdyaush", "value": "C100743"},
                        {"label": "Bobrovka", "value": "C100744"},
                        {"label": "Bredy", "value": "C100745"},
                        {"label": "Brodokalmak", "value": "C100746"},
                        {"label": "Chebarkul", "value": "C100747"},
                        {"label": "Chebarkulskiy Rayon", "value": "C100748"},
                        {"label": "Chelyabinsk", "value": "C100749"},
                        {"label": "Cheremshanka", "value": "C100750"},
                        {"label": "Chesma", "value": "C100751"},
                        {"label": "Dolgoderevenskoye", "value": "C100752"},
                        {"label": "Fershampenuaz", "value": "C100753"},
                        {"label": "Gornyak", "value": "C100754"},
                        {"label": "Gorod Chelyabinsk", "value": "C100755"},
                        {"label": "Kanashevo", "value": "C100756"},
                        {"label": "Karabash", "value": "C100757"},
                        {"label": "Kartalinskiy Rayon", "value": "C100758"},
                        {"label": "Kartaly", "value": "C100759"},
                        {"label": "Kasli", "value": "C100760"},
                        {"label": "Katav-Ivanovsk", "value": "C100761"},
                        {"label": "Katav-Ivanovskiy Rayon", "value": "C100762"},
                        {"label": "Kizilskiy Rayon", "value": "C100763"},
                        {"label": "Kopeysk", "value": "C100764"},
                        {"label": "Korkino", "value": "C100765"},
                        {"label": "Koyelga", "value": "C100766"},
                        {"label": "Krasnogorskiy", "value": "C100767"},
                        {"label": "Kropachevo", "value": "C100768"},
                        {"label": "Kunashak", "value": "C100769"},
                        {"label": "Kusa", "value": "C100770"},
                        {"label": "Kyshtym", "value": "C100771"},
                        {"label": "Leninsk", "value": "C100772"},
                        {"label": "Lokomotivnyy", "value": "C100773"},
                        {"label": "Magnitka", "value": "C100774"},
                        {"label": "Magnitogorsk", "value": "C100775"},
                        {"label": "Mezhevoy", "value": "C100776"},
                        {"label": "Miass", "value": "C100777"},
                        {"label": "Miasskoye", "value": "C100778"},
                        {"label": "Muslyumovo", "value": "C100779"},
                        {"label": "Nagaybakskiy Rayon", "value": "C100780"},
                        {"label": "Nizhniy Ufaley", "value": "C100781"},
                        {"label": "Novogornyy", "value": "C100782"},
                        {"label": "Novosineglazovskiy", "value": "C100783"},
                        {"label": "Nyazepetrovsk", "value": "C100784"},
                        {"label": "Nyazepetrovskiy Rayon", "value": "C100785"},
                        {"label": "Oktyabrskiy Rayon", "value": "C100786"},
                        {"label": "Ozersk", "value": "C100787"},
                        {"label": "Parizh", "value": "C100788"},
                        {"label": "Plast", "value": "C100789"},
                        {"label": "Poletayevo", "value": "C100790"},
                        {"label": "Potanino", "value": "C100791"},
                        {"label": "Roshchino", "value": "C100792"},
                        {"label": "Roza", "value": "C100793"},
                        {"label": "Sargazy", "value": "C100794"},
                        {"label": "Satka", "value": "C100795"},
                        {"label": "Satkinskiy Rayon", "value": "C100796"},
                        {"label": "Selezyan", "value": "C100797"},
                        {"label": "Sim", "value": "C100798"},
                        {"label": "Snezhinsk", "value": "C100799"},
                        {"label": "Sosnovskiy Rayon", "value": "C100800"},
                        {"label": "Starokamyshinsk", "value": "C100801"},
                        {"label": "Syrostan", "value": "C100802"},
                        {"label": "Tayginka", "value": "C100803"},
                        {"label": "Timiryazevskiy", "value": "C100804"},
                        {"label": "Trekhgornyy", "value": "C100805"},
                        {"label": "Troitsk", "value": "C100806"},
                        {"label": "Turgoyak", "value": "C100807"},
                        {"label": "Tyubuk", "value": "C100808"},
                        {"label": "Ust-Bagaryak", "value": "C100809"},
                        {"label": "Ust-Katav", "value": "C100810"},
                        {"label": "Uvelskiy", "value": "C100811"},
                        {"label": "Uvelskiy Rayon", "value": "C100812"},
                        {"label": "Uyskiy Rayon", "value": "C100813"},
                        {"label": "Uyskoye", "value": "C100814"},
                        {"label": "Vakhrushevo", "value": "C100815"},
                        {"label": "Varna", "value": "C100816"},
                        {"label": "Varnenskiy Rayon", "value": "C100817"},
                        {"label": "Velikopetrovka", "value": "C100818"},
                        {"label": "Verkhneuralsk", "value": "C100819"},
                        {"label": "Verkhniy Ufaley", "value": "C100820"},
                        {"label": "Vishnevogorsk", "value": "C100821"},
                        {"label": "Vyazovaya", "value": "C100822"},
                        {"label": "Yemanzhelinka", "value": "C100823"},
                        {"label": "Yemanzhelinsk", "value": "C100824"},
                        {"label": "Yetkul", "value": "C100825"},
                        {"label": "Yuryuzan", "value": "C100826"},
                        {"label": "Yuzhnouralsk", "value": "C100827"},
                        {"label": "Zheleznodorozhnyy", "value": "C100828"},
                        {"label": "Zlatoust", "value": "C100829"}],
 "Cherkaska oblast": [{"label": "Babanka", "value": "C121643"},
                      {"label": "Buky", "value": "C121644"},
                      {"label": "Cherkasy", "value": "C121645"},
                      {"label": "Chervona Sloboda", "value": "C121646"},
                      {"label": "Chornobay", "value": "C121647"},
                      {"label": "Chyhyryn", "value": "C121648"},
                      {"label": "Drabiv", "value": "C121649"},
                      {"label": "Horodyshche", "value": "C121650"},
                      {"label": "Kamianka", "value": "C121651"},
                      {"label": "Kaniv", "value": "C121652"},
                      {"label": "Khrystynivka", "value": "C121653"},
                      {"label": "Korsun-Shevchenkivskyi", "value": "C121654"},
                      {"label": "Lebedyn", "value": "C121655"},
                      {"label": "Lysianka", "value": "C121656"},
                      {"label": "Mankivka", "value": "C121657"},
                      {"label": "Mliiv", "value": "C121658"},
                      {"label": "Monastyryshche", "value": "C121659"},
                      {"label": "Moshny", "value": "C121660"},
                      {"label": "Nove-Misto", "value": "C121661"},
                      {"label": "Ruska Poliana", "value": "C121662"},
                      {"label": "Shpola", "value": "C121663"},
                      {"label": "Smila", "value": "C121664"},
                      {"label": "Stebliv", "value": "C121665"},
                      {"label": "Talne", "value": "C121666"},
                      {"label": "Tsibulev", "value": "C121667"},
                      {"label": "Tsvitkove", "value": "C121668"},
                      {"label": "Uman", "value": "C121669"},
                      {"label": "Vatutine", "value": "C121670"},
                      {"label": "Verkhniachka", "value": "C121671"},
                      {"label": "Yerky", "value": "C121672"},
                      {"label": "Yurkivka", "value": "C121673"},
                      {"label": "Zhashkiv", "value": "C121674"},
                      {"label": "Zolotonosha", "value": "C121675"},
                      {"label": "Zvenihorodka", "value": "C121676"}],
 "Chernihivska oblast": [{"label": "Avdiyivka", "value": "C121677"},
                         {"label": "Bakhmach", "value": "C121678"},
                         {"label": "Baturyn", "value": "C121679"},
                         {"label": "Berezna", "value": "C121680"},
                         {"label": "Bobrovytsya", "value": "C121681"},
                         {"label": "Borzna", "value": "C121682"},
                         {"label": "Chernihiv", "value": "C121683"},
                         {"label": "Chernihiv Raion", "value": "C121684"},
                         {"label": "Chernihivska Miskrada", "value": "C121685"},
                         {"label": "Desna", "value": "C121686"},
                         {"label": "Dihtyari", "value": "C121687"},
                         {"label": "Dobryanka", "value": "C121688"},
                         {"label": "Horodnya", "value": "C121689"},
                         {"label": "Ichnya", "value": "C121690"},
                         {"label": "Kholmy", "value": "C121691"},
                         {"label": "Kobyzhcha", "value": "C121692"},
                         {"label": "Korop", "value": "C121693"},
                         {"label": "Koryukivka", "value": "C121694"},
                         {"label": "Kozelets", "value": "C121695"},
                         {"label": "Kruty", "value": "C121696"},
                         {"label": "Kulykivka", "value": "C121697"},
                         {"label": "Ladan", "value": "C121698"},
                         {"label": "Lisovi Sorochyntsi", "value": "C121699"},
                         {"label": "Lyubech", "value": "C121700"},
                         {"label": "Mena", "value": "C121701"},
                         {"label": "Menskyy Rayon", "value": "C121702"},
                         {"label": "Nizhyn", "value": "C121703"},
                         {"label": "Nosivka", "value": "C121704"},
                         {"label": "Novhorod-Siverskyy", "value": "C121705"},
                         {"label": "Olyshivka", "value": "C121706"},
                         {"label": "Oster", "value": "C121707"},
                         {"label": "Pryluky", "value": "C121708"},
                         {"label": "Ripky", "value": "C121709"},
                         {"label": "Sedniv", "value": "C121710"},
                         {"label": "Semenivka", "value": "C121711"},
                         {"label": "Shchors", "value": "C121712"},
                         {"label": "Snovskyy Rayon", "value": "C121713"},
                         {"label": "Sosnytsya", "value": "C121714"},
                         {"label": "Sribne", "value": "C121715"},
                         {"label": "Varva", "value": "C121716"},
                         {"label": "Zhuravka", "value": "C121717"}],
 "Chernivetska oblast": [{"label": "Banyliv", "value": "C121718"},
                         {"label": "Berehomet", "value": "C121719"},
                         {"label": "Boyany", "value": "C121720"},
                         {"label": "Chernivtsi", "value": "C121721"},
                         {"label": "Chernivtsi Municipality",
                          "value": "C121722"},
                         {"label": "Chudey", "value": "C121723"},
                         {"label": "Davydivka", "value": "C121724"},
                         {"label": "Hlyboka", "value": "C121725"},
                         {"label": "Ispas", "value": "C121726"},
                         {"label": "Kadubivtsi", "value": "C121727"},
                         {"label": "Khotyn", "value": "C121728"},
                         {"label": "Klishkivtsi", "value": "C121729"},
                         {"label": "Kostryzhivka", "value": "C121730"},
                         {"label": "Luzhany", "value": "C121731"},
                         {"label": "Marshintsy", "value": "C121732"},
                         {"label": "Molodiya", "value": "C121733"},
                         {"label": "Myhove", "value": "C121734"},
                         {"label": "Novodnistrovsk", "value": "C121735"},
                         {"label": "Novoselytsya", "value": "C121736"},
                         {"label": "Nyzhni Petrivtsi", "value": "C121737"},
                         {"label": "Putyla Raion", "value": "C121738"},
                         {"label": "Ropcha", "value": "C121739"},
                         {"label": "Shypyntsi", "value": "C121740"},
                         {"label": "Sokyryany", "value": "C121741"},
                         {"label": "Vashkivtsi", "value": "C121742"},
                         {"label": "Verenchanka", "value": "C121743"},
                         {"label": "Voloka", "value": "C121744"},
                         {"label": "Vyzhnytsya", "value": "C121745"},
                         {"label": "Yizhivtsi", "value": "C121746"},
                         {"label": "Zastavna", "value": "C121747"}],
 "Chhattisgarh": [{"label": "Akaltara", "value": "C45314"},
                  {"label": "Ambagarh Chauki", "value": "C45315"},
                  {"label": "Ambikapur", "value": "C45316"},
                  {"label": "Arang", "value": "C45317"},
                  {"label": "Baikunthpur", "value": "C45318"},
                  {"label": "Balod", "value": "C45319"},
                  {"label": "Baloda", "value": "C45320"},
                  {"label": "Baloda Bazar", "value": "C45321"},
                  {"label": "Basna", "value": "C45322"},
                  {"label": "Bastar", "value": "C45323"},
                  {"label": "Bemetara", "value": "C45324"},
                  {"label": "Bhanpuri", "value": "C45325"},
                  {"label": "Bhatapara", "value": "C45326"},
                  {"label": "Bhatgaon", "value": "C45327"},
                  {"label": "Bhilai", "value": "C45328"},
                  {"label": "Bijapur", "value": "C45329"},
                  {"label": "Bilaspur", "value": "C45330"},
                  {"label": "Champa", "value": "C45331"},
                  {"label": "Chhuikhadan", "value": "C45332"},
                  {"label": "Deori", "value": "C45333"},
                  {"label": "Dhamtari", "value": "C45334"},
                  {"label": "Dongargaon", "value": "C45335"},
                  {"label": "Dongargarh", "value": "C45336"},
                  {"label": "Durg", "value": "C45337"},
                  {"label": "Gandai", "value": "C45338"},
                  {"label": "Gariaband", "value": "C45339"},
                  {"label": "Gaurela", "value": "C45340"},
                  {"label": "Gharghoda", "value": "C45341"},
                  {"label": "Gidam", "value": "C45342"},
                  {"label": "Jagdalpur", "value": "C45343"},
                  {"label": "Janjgir", "value": "C45344"},
                  {"label": "Janjgir-Champa", "value": "C45345"},
                  {"label": "Jashpur", "value": "C45346"},
                  {"label": "Jashpurnagar", "value": "C45347"},
                  {"label": "Junagarh", "value": "C45348"},
                  {"label": "Kabeerdham", "value": "C45349"},
                  {"label": "Kanker", "value": "C45350"},
                  {"label": "Katghora", "value": "C45351"},
                  {"label": "Kawardha", "value": "C45352"},
                  {"label": "Khairagarh", "value": "C45353"},
                  {"label": "Khamharia", "value": "C45354"},
                  {"label": "Kharod", "value": "C45355"},
                  {"label": "Kharsia", "value": "C45356"},
                  {"label": "Kirandul", "value": "C45357"},
                  {"label": "Kondagaon", "value": "C45358"},
                  {"label": "Korba", "value": "C45359"},
                  {"label": "Koriya", "value": "C45360"},
                  {"label": "Kota", "value": "C45361"},
                  {"label": "Kotaparh", "value": "C45362"},
                  {"label": "Kumhari", "value": "C45363"},
                  {"label": "Kurud", "value": "C45364"},
                  {"label": "Lormi", "value": "C45365"},
                  {"label": "Mahasamund", "value": "C45366"},
                  {"label": "Mungeli", "value": "C45367"},
                  {"label": "Narayanpur", "value": "C45368"},
                  {"label": "Narharpur", "value": "C45369"},
                  {"label": "Pandaria", "value": "C45370"},
                  {"label": "Pandatarai", "value": "C45371"},
                  {"label": "Pasan", "value": "C45372"},
                  {"label": "Patan", "value": "C45373"},
                  {"label": "Pathalgaon", "value": "C45374"},
                  {"label": "Pendra", "value": "C45375"},
                  {"label": "Pithora", "value": "C45376"},
                  {"label": "Raigarh", "value": "C45377"},
                  {"label": "Raipur", "value": "C45378"},
                  {"label": "Raj Nandgaon", "value": "C45379"},
                  {"label": "Raj-Nandgaon", "value": "C45380"},
                  {"label": "Ramanuj Ganj", "value": "C45381"},
                  {"label": "Ratanpur", "value": "C45382"},
                  {"label": "Sakti", "value": "C45383"},
                  {"label": "Saraipali", "value": "C45384"},
                  {"label": "Sarangarh", "value": "C45385"},
                  {"label": "Seorinarayan", "value": "C45386"},
                  {"label": "Simga", "value": "C45387"},
                  {"label": "Surguja", "value": "C45388"},
                  {"label": "Takhatpur", "value": "C45389"},
                  {"label": "Umarkot", "value": "C45390"},
                  {"label": "Uttar Bastar Kanker", "value": "C45391"}],
 "Chiang Mai": [{"label": "Amphoe Chai Prakan", "value": "C118519"},
                {"label": "Amphoe Chiang Dao", "value": "C118520"},
                {"label": "Amphoe Chom Thong", "value": "C118521"},
                {"label": "Amphoe Doi Lo", "value": "C118522"},
                {"label": "Amphoe Doi Saket", "value": "C118523"},
                {"label": "Amphoe Doi Tao", "value": "C118524"},
                {"label": "Amphoe Fang", "value": "C118525"},
                {"label": "Amphoe Galyani Vadhana", "value": "C118526"},
                {"label": "Amphoe Hang Dong", "value": "C118527"},
                {"label": "Amphoe Hot", "value": "C118528"},
                {"label": "Amphoe Mae Ai", "value": "C118529"},
                {"label": "Amphoe Mae Chaem", "value": "C118530"},
                {"label": "Amphoe Mae On", "value": "C118531"},
                {"label": "Amphoe Mae Rim", "value": "C118532"},
                {"label": "Amphoe Mae Taeng", "value": "C118533"},
                {"label": "Amphoe Mae Wang", "value": "C118534"},
                {"label": "Amphoe Mueang Chiang Mai", "value": "C118535"},
                {"label": "Amphoe Om Koi", "value": "C118536"},
                {"label": "Amphoe Phrao", "value": "C118537"},
                {"label": "Amphoe Samoeng", "value": "C118538"},
                {"label": "Amphoe San Kamphaeng", "value": "C118539"},
                {"label": "Amphoe San Pa Tong", "value": "C118540"},
                {"label": "Amphoe San Sai", "value": "C118541"},
                {"label": "Amphoe Saraphi", "value": "C118542"},
                {"label": "Amphoe Wiang Haeng", "value": "C118543"},
                {"label": "Chai Prakan", "value": "C118544"},
                {"label": "Chiang Mai", "value": "C118545"},
                {"label": "Chom Thong", "value": "C118546"},
                {"label": "Fang", "value": "C118547"},
                {"label": "Hang Dong", "value": "C118548"},
                {"label": "Pai", "value": "C118549"},
                {"label": "San Kamphaeng", "value": "C118550"},
                {"label": "San Pa Tong", "value": "C118551"}],
 "Chiang Rai": [{"label": "Amphoe Chiang Khong", "value": "C118552"},
                {"label": "Amphoe Chiang Saen", "value": "C118553"},
                {"label": "Amphoe Doi Luang", "value": "C118554"},
                {"label": "Amphoe Khun Tan", "value": "C118555"},
                {"label": "Amphoe Mae Chan", "value": "C118556"},
                {"label": "Amphoe Mae Fa Luang", "value": "C118557"},
                {"label": "Amphoe Mae Lao", "value": "C118558"},
                {"label": "Amphoe Mae Sai", "value": "C118559"},
                {"label": "Amphoe Mae Suai", "value": "C118560"},
                {"label": "Amphoe Mueang Chiang Rai", "value": "C118561"},
                {"label": "Amphoe Pa Daet", "value": "C118562"},
                {"label": "Amphoe Phan", "value": "C118563"},
                {"label": "Amphoe Phaya Mengrai", "value": "C118564"},
                {"label": "Amphoe Thoeng", "value": "C118565"},
                {"label": "Amphoe Wiang Chai", "value": "C118566"},
                {"label": "Amphoe Wiang Chiang Rung", "value": "C118567"},
                {"label": "Amphoe Wiang Kaen", "value": "C118568"},
                {"label": "Amphoe Wiang Pa Pao", "value": "C118569"},
                {"label": "Chiang Khong", "value": "C118570"},
                {"label": "Chiang Rai", "value": "C118571"},
                {"label": "Chiang Saen", "value": "C118572"},
                {"label": "Mae Chan", "value": "C118573"},
                {"label": "Mae Sai", "value": "C118574"},
                {"label": "Pa Daet", "value": "C118575"}],
 "Chiapas": [{"label": "1ra. Seccion de Izapa", "value": "C66363"},
             {"label": "2da. Seccion de Medio Monte", "value": "C66364"},
             {"label": "Abasolo", "value": "C66365"},
             {"label": "Acacoyagua", "value": "C66366"},
             {"label": "Acala", "value": "C66367"},
             {"label": "Acapetahua", "value": "C66368"},
             {"label": "Adolfo Lopez Mateos", "value": "C66369"},
             {"label": "Agronomos Mexicanos", "value": "C66370"},
             {"label": "Agua Azul", "value": "C66371"},
             {"label": "Agua Blanca Serrania", "value": "C66372"},
             {"label": "Agua Zarca", "value": "C66373"},
             {"label": "Aguacatenango", "value": "C66374"},
             {"label": "Agustin de Iturbide", "value": "C66375"},
             {"label": "Ahuacatlan", "value": "C66376"},
             {"label": "Ajilho", "value": "C66377"},
             {"label": "Aldama", "value": "C66378"},
             {"label": "Alfonso Moguel", "value": "C66379"},
             {"label": "Altamirano", "value": "C66380"},
             {"label": "alvaro Obregon", "value": "C66381"},
             {"label": "Amado Nervo", "value": "C66382"},
             {"label": "Amatan", "value": "C66383"},
             {"label": "Amatenango de la Frontera", "value": "C66384"},
             {"label": "Amatenango del Valle", "value": "C66385"},
             {"label": "America Libre", "value": "C66386"},
             {"label": "angel Albino Corzo", "value": "C66387"},
             {"label": "angel Albino Corzo (Guadalupe)", "value": "C66388"},
             {"label": "Apas", "value": "C66389"},
             {"label": "Aquiles Serdan", "value": "C66390"},
             {"label": "Arimatea", "value": "C66391"},
             {"label": "Arriaga", "value": "C66392"},
             {"label": "Arroyo Grande", "value": "C66393"},
             {"label": "Arroyo Granizo", "value": "C66394"},
             {"label": "Arroyo Palenque", "value": "C66395"},
             {"label": "Arvenza Uno", "value": "C66396"},
             {"label": "Aurora Ermita", "value": "C66397"},
             {"label": "Aurora Esquipulas", "value": "C66398"},
             {"label": "Azteca", "value": "C66399"},
             {"label": "Aztlan", "value": "C66400"},
             {"label": "Bachajon", "value": "C66401"},
             {"label": "Bajucu", "value": "C66402"},
             {"label": "Bautista Chico", "value": "C66403"},
             {"label": "Bejucal de Ocampo", "value": "C66404"},
             {"label": "Belisario Dominguez", "value": "C66405"},
             {"label": "Bella Vista", "value": "C66406"},
             {"label": "Benemerito de las Americas", "value": "C66407"},
             {"label": "Benito Juarez", "value": "C66408"},
             {"label": "Berriozabal", "value": "C66409"},
             {"label": "Betania", "value": "C66410"},
             {"label": "Bochil", "value": "C66411"},
             {"label": "Bochojbo Alto", "value": "C66412"},
             {"label": "Brisas Barra de Suchiate", "value": "C66413"},
             {"label": "Buenavista", "value": "C66414"},
             {"label": "Buenavista (Matasanos)", "value": "C66415"},
             {"label": "Buenos Aires", "value": "C66416"},
             {"label": "Cabeza de Toro", "value": "C66417"},
             {"label": "Cacahoatan", "value": "C66418"},
             {"label": "Cacate", "value": "C66419"},
             {"label": "Calido", "value": "C66420"},
             {"label": "Calzada Larga", "value": "C66421"},
             {"label": "Cancuc", "value": "C66422"},
             {"label": "Cantioc", "value": "C66423"},
             {"label": "Canton las Delicias", "value": "C66424"},
             {"label": "Canton Rancho Nuevo", "value": "C66425"},
             {"label": "Canton Villaflor", "value": "C66426"},
             {"label": "Carmen Yalchuch", "value": "C66427"},
             {"label": "Carmen Zacatal", "value": "C66428"},
             {"label": "Carrillo Puerto", "value": "C66429"},
             {"label": "Cash", "value": "C66430"},
             {"label": "Catazaja", "value": "C66431"},
             {"label": "Catishtic", "value": "C66432"},
             {"label": "Cebadilla 1ra. Seccion", "value": "C66433"},
             {"label": "Cenobio Aguilar (La Trinidad)", "value": "C66434"},
             {"label": "Cereso 14 (El Amate)", "value": "C66435"},
             {"label": "Chacaljocom", "value": "C66436"},
             {"label": "Chacoma", "value": "C66437"},
             {"label": "Chalam", "value": "C66438"},
             {"label": "Chalchihuitan", "value": "C66439"},
             {"label": "Chamula", "value": "C66440"},
             {"label": "Chamulapita", "value": "C66441"},
             {"label": "Chanal", "value": "C66442"},
             {"label": "Chapallal Grande", "value": "C66443"},
             {"label": "Chapultenango", "value": "C66444"},
             {"label": "Chiapa de Corzo", "value": "C66445"},
             {"label": "Chiapilla", "value": "C66446"},
             {"label": "Chicoasen", "value": "C66447"},
             {"label": "Chicomuselo", "value": "C66448"},
             {"label": "Chicumtantic", "value": "C66449"},
             {"label": "Chihuahua", "value": "C66450"},
             {"label": "Chilolja", "value": "C66451"},
             {"label": "Chilon", "value": "C66452"},
             {"label": "Chimhucum", "value": "C66453"},
             {"label": "Chiquinival", "value": "C66454"},
             {"label": "Chiquinshulum", "value": "C66455"},
             {"label": "Chixtontic", "value": "C66456"},
             {"label": "Chulum Cardenas", "value": "C66457"},
             {"label": "Chulum Juarez", "value": "C66458"},
             {"label": "Cintalapa", "value": "C66459"},
             {"label": "Cintalapa de Figueroa", "value": "C66460"},
             {"label": "Ciudad Cuauhtemoc", "value": "C66461"},
             {"label": "Ciudad Hidalgo", "value": "C66462"},
             {"label": "Coapilla", "value": "C66463"},
             {"label": "Colonia Obrera", "value": "C66464"},
             {"label": "Comalapa", "value": "C66465"},
             {"label": "Comitan", "value": "C66466"},
             {"label": "Concepcion", "value": "C66467"},
             {"label": "Congregacion Reforma", "value": "C66468"},
             {"label": "Constitucion", "value": "C66469"},
             {"label": "Copainala", "value": "C66470"},
             {"label": "Copoya", "value": "C66471"},
             {"label": "Cristobal Colon", "value": "C66472"},
             {"label": "Cristobal Obregon", "value": "C66473"},
             {"label": "Cruzton", "value": "C66474"},
             {"label": "Cuauhtemoc", "value": "C66475"},
             {"label": "Cuchulumtic", "value": "C66476"},
             {"label": "Damasco", "value": "C66477"},
             {"label": "Diamante de Echeverria", "value": "C66478"},
             {"label": "Dieciseis de Septiembre", "value": "C66479"},
             {"label": "Doctor Belisario Dominguez (La Barra)",
              "value": "C66480"},
             {"label": "Doctor Domingo Chanona", "value": "C66481"},
             {"label": "Doctor Rodulfo Figueroa (Tierra Blanca)",
              "value": "C66482"},
             {"label": "Doctor Samuel Leon Brindis", "value": "C66483"},
             {"label": "Dolores Jaltenango", "value": "C66484"},
             {"label": "Efrain A. Gutierrez", "value": "C66485"},
             {"label": "Egipto", "value": "C66486"},
             {"label": "El aguila", "value": "C66487"},
             {"label": "El ambar", "value": "C66488"},
             {"label": "El ambar (El ambar de Echeverria)", "value": "C66489"},
             {"label": "El Arenal", "value": "C66490"},
             {"label": "El Bosque", "value": "C66491"},
             {"label": "El Calvario", "value": "C66492"},
             {"label": "El Carmen", "value": "C66493"},
             {"label": "El Carmen (El Limon)", "value": "C66494"},
             {"label": "El Censo", "value": "C66495"},
             {"label": "El Consuelo Ulapa", "value": "C66496"},
             {"label": "El Copal", "value": "C66497"},
             {"label": "El Copalar", "value": "C66498"},
             {"label": "El Eden", "value": "C66499"},
             {"label": "El Encanto", "value": "C66500"},
             {"label": "El Espinal", "value": "C66501"},
             {"label": "El Jardin", "value": "C66502"},
             {"label": "El Jobo", "value": "C66503"},
             {"label": "El Limar", "value": "C66504"},
             {"label": "El Limonar", "value": "C66505"},
             {"label": "El Mango", "value": "C66506"},
             {"label": "El Nopal", "value": "C66507"},
             {"label": "El Pacayal", "value": "C66508"},
             {"label": "El Palmar (San Gabriel)", "value": "C66509"},
             {"label": "El Palmar Grande", "value": "C66510"},
             {"label": "El Palmarcito", "value": "C66511"},
             {"label": "El Paraiso", "value": "C66512"},
             {"label": "El Parral", "value": "C66513"},
             {"label": "El Portal", "value": "C66514"},
             {"label": "El Porvenir", "value": "C66515"},
             {"label": "El Porvenir Agrarista", "value": "C66516"},
             {"label": "El Porvenir de Velasco Suarez", "value": "C66517"},
             {"label": "El Pozo", "value": "C66518"},
             {"label": "El Progreso", "value": "C66519"},
             {"label": "El Puerto", "value": "C66520"},
             {"label": "El Ramal (Porvenir)", "value": "C66521"},
             {"label": "El Sacrificio", "value": "C66522"},
             {"label": "El Sibal", "value": "C66523"},
             {"label": "El Triunfo", "value": "C66524"},
             {"label": "El Triunfo 1ra. Seccion (Cardona)", "value": "C66525"},
             {"label": "El Triunfo de las Tres Maravillas", "value": "C66526"},
             {"label": "El Tumbo", "value": "C66527"},
             {"label": "El Tzay", "value": "C66528"},
             {"label": "El Vergel", "value": "C66529"},
             {"label": "El Zapotal", "value": "C66530"},
             {"label": "El Zapotillo", "value": "C66531"},
             {"label": "Emiliano Zapata", "value": "C66532"},
             {"label": "Escuintla", "value": "C66533"},
             {"label": "Estacion Huehuetan", "value": "C66534"},
             {"label": "Estacion San Manuel", "value": "C66535"},
             {"label": "Estacion Tuzantan", "value": "C66536"},
             {"label": "Estrella de Belen", "value": "C66537"},
             {"label": "Faja de Oro", "value": "C66538"},
             {"label": "Flor de Cacao", "value": "C66539"},
             {"label": "Francisco I. Madero", "value": "C66540"},
             {"label": "Francisco Leon", "value": "C66541"},
             {"label": "Francisco Sarabia", "value": "C66542"},
             {"label": "Francisco Villa", "value": "C66543"},
             {"label": "Frontera Corozal", "value": "C66544"},
             {"label": "Frontera Hidalgo", "value": "C66545"},
             {"label": "Gabriel Esquinca", "value": "C66546"},
             {"label": "Galecio Narcia", "value": "C66547"},
             {"label": "Guadalupe", "value": "C66548"},
             {"label": "Guadalupe Grijalva", "value": "C66549"},
             {"label": "Guadalupe Victoria", "value": "C66550"},
             {"label": "Guaquitepec", "value": "C66551"},
             {"label": "Hermenegildo Galeana", "value": "C66552"},
             {"label": "Heroes de Chapultepec", "value": "C66553"},
             {"label": "Hidalgo", "value": "C66554"},
             {"label": "Hidalgo Joshil", "value": "C66555"},
             {"label": "Huehuetan", "value": "C66556"},
             {"label": "Huitiupan", "value": "C66557"},
             {"label": "Huixtla", "value": "C66558"},
             {"label": "Huizachal", "value": "C66559"},
             {"label": "Ignacio Allende", "value": "C66560"},
             {"label": "Ignacio Lopez Rayon", "value": "C66561"},
             {"label": "Ignacio Ramirez", "value": "C66562"},
             {"label": "Ignacio Zaragoza", "value": "C66563"},
             {"label": "Ignacio Zaragoza (El Morro)", "value": "C66564"},
             {"label": "Independencia", "value": "C66565"},
             {"label": "Ixhuatan", "value": "C66566"},
             {"label": "Ixtacomitan", "value": "C66567"},
             {"label": "Ixtapa", "value": "C66568"},
             {"label": "Ixtapangajoya", "value": "C66569"},
             {"label": "Jalisco", "value": "C66570"},
             {"label": "Jaltenango de la Paz", "value": "C66571"},
             {"label": "Jardines del Grijalva", "value": "C66572"},
             {"label": "Jerico", "value": "C66573"},
             {"label": "Jerusalen", "value": "C66574"},
             {"label": "Jesus Maria Garza", "value": "C66575"},
             {"label": "Jiquilpan (Estacion Bonanza)", "value": "C66576"},
             {"label": "Jiquipilas", "value": "C66577"},
             {"label": "Jitotol", "value": "C66578"},
             {"label": "Joaquin Miguel Gutierrez", "value": "C66579"},
             {"label": "Joaquin Miguel Gutierrez (Margaritas)",
              "value": "C66580"},
             {"label": "Jolja", "value": "C66581"},
             {"label": "Jolsibaquil", "value": "C66582"},
             {"label": "Joltealal", "value": "C66583"},
             {"label": "Jomanichim", "value": "C66584"},
             {"label": "Jose Maria Morelos", "value": "C66585"},
             {"label": "Jose Maria Morelos y Pavon", "value": "C66586"},
             {"label": "Jose Maria Pino Suarez", "value": "C66587"},
             {"label": "Joshil", "value": "C66588"},
             {"label": "Juan Aldama", "value": "C66589"},
             {"label": "Juan del Grijalva", "value": "C66590"},
             {"label": "Juarez", "value": "C66591"},
             {"label": "Julian Grajales", "value": "C66592"},
             {"label": "Justo Sierra", "value": "C66593"},
             {"label": "Kotolte", "value": "C66594"},
             {"label": "La Candelaria", "value": "C66595"},
             {"label": "La Ceiba", "value": "C66596"},
             {"label": "La Competencia", "value": "C66597"},
             {"label": "La Concordia", "value": "C66598"},
             {"label": "La Esperanza", "value": "C66599"},
             {"label": "La Esperanza (El Zapotal)", "value": "C66600"},
             {"label": "La Floresta", "value": "C66601"},
             {"label": "La Gloria", "value": "C66602"},
             {"label": "La Grandeza", "value": "C66603"},
             {"label": "La Independencia", "value": "C66604"},
             {"label": "La Independencia (Las Pilas)", "value": "C66605"},
             {"label": "La Laguna", "value": "C66606"},
             {"label": "La Libertad", "value": "C66607"},
             {"label": "La Linea", "value": "C66608"},
             {"label": "La Patria", "value": "C66609"},
             {"label": "La Pimienta", "value": "C66610"},
             {"label": "La Rinconada", "value": "C66611"},
             {"label": "La Tigrilla", "value": "C66612"},
             {"label": "La Trinitaria", "value": "C66613"},
             {"label": "Lacandon", "value": "C66614"},
             {"label": "Laguna del Cofre", "value": "C66615"},
             {"label": "Larrainzar", "value": "C66616"},
             {"label": "Las Brisas", "value": "C66617"},
             {"label": "Las Chicharras", "value": "C66618"},
             {"label": "Las Delicias", "value": "C66619"},
             {"label": "Las Flechas", "value": "C66620"},
             {"label": "Las Maravillas", "value": "C66621"},
             {"label": "Las Margaritas", "value": "C66622"},
             {"label": "Las Ollas", "value": "C66623"},
             {"label": "Las Rosas", "value": "C66624"},
             {"label": "Las Tazas", "value": "C66625"},
             {"label": "Lazaro Cardenas", "value": "C66626"},
             {"label": "Lazaro Cardenas (Chilil)", "value": "C66627"},
             {"label": "Libertad Melchor Ocampo", "value": "C66628"},
             {"label": "Libertad Ventanas", "value": "C66629"},
             {"label": "Llano de la Lima", "value": "C66630"},
             {"label": "Loma Bonita", "value": "C66631"},
             {"label": "Lomantan", "value": "C66632"},
             {"label": "Los Cafetales", "value": "C66633"},
             {"label": "Los Corazones", "value": "C66634"},
             {"label": "Los Naranjos", "value": "C66635"},
             {"label": "Los Palacios", "value": "C66636"},
             {"label": "Los Pinos", "value": "C66637"},
             {"label": "Los Platanos", "value": "C66638"},
             {"label": "Los Pozos", "value": "C66639"},
             {"label": "Los Riegos", "value": "C66640"},
             {"label": "Luis Espinoza", "value": "C66641"},
             {"label": "Macvilho", "value": "C66642"},
             {"label": "Majastic", "value": "C66643"},
             {"label": "Majomut", "value": "C66644"},
             {"label": "Manuel avila Camacho", "value": "C66645"},
             {"label": "Manuel avila Camacho (Ponte Duro)", "value": "C66646"},
             {"label": "Manuel Lazos", "value": "C66647"},
             {"label": "Mapastepec", "value": "C66648"},
             {"label": "Maravilla Tenejapa", "value": "C66649"},
             {"label": "Maravillas", "value": "C66650"},
             {"label": "Mariano Matamoros", "value": "C66651"},
             {"label": "Mariscal Subikuski", "value": "C66652"},
             {"label": "Marques de Comillas", "value": "C66653"},
             {"label": "Marte R. Gomez", "value": "C66654"},
             {"label": "Matzam", "value": "C66655"},
             {"label": "Mazapa", "value": "C66656"},
             {"label": "Mazapa de Madero", "value": "C66657"},
             {"label": "Mazatan", "value": "C66658"},
             {"label": "Merida", "value": "C66659"},
             {"label": "Metapa", "value": "C66660"},
             {"label": "Miguel Hidalgo", "value": "C66661"},
             {"label": "Miguel Utrilla (Los Chorros)", "value": "C66662"},
             {"label": "Misija", "value": "C66663"},
             {"label": "Mitontic", "value": "C66664"},
             {"label": "Mitziton", "value": "C66665"},
             {"label": "Mixcum", "value": "C66666"},
             {"label": "Monte Redondo", "value": "C66667"},
             {"label": "Montecristo", "value": "C66668"},
             {"label": "Montecristo de Guerrero", "value": "C66669"},
             {"label": "Monterrey", "value": "C66670"},
             {"label": "Morelia (Victorico Rodolfo Grajales)",
              "value": "C66671"},
             {"label": "Motozintla", "value": "C66672"},
             {"label": "Moyos", "value": "C66673"},
             {"label": "Muquen", "value": "C66674"},
             {"label": "Nachig", "value": "C66675"},
             {"label": "Narciso Mendoza", "value": "C66676"},
             {"label": "Navenchauc", "value": "C66677"},
             {"label": "Nicolas Bravo", "value": "C66678"},
             {"label": "Nicolas Ruiz", "value": "C66679"},
             {"label": "Nueva America", "value": "C66680"},
             {"label": "Nueva Colombia", "value": "C66681"},
             {"label": "Nueva Esperanza", "value": "C66682"},
             {"label": "Nueva Independencia", "value": "C66683"},
             {"label": "Nueva Libertad", "value": "C66684"},
             {"label": "Nueva Libertad (El Colorado)", "value": "C66685"},
             {"label": "Nueva Morelia", "value": "C66686"},
             {"label": "Nueva Palestina", "value": "C66687"},
             {"label": "Nueva Sesecapa", "value": "C66688"},
             {"label": "Nueva Tenochtitlan (Rizo de Oro)", "value": "C66689"},
             {"label": "Nuevo Amatenango", "value": "C66690"},
             {"label": "Nuevo Carmen Tonapac", "value": "C66691"},
             {"label": "Nuevo Francisco Leon", "value": "C66692"},
             {"label": "Nuevo Juan del Grijalva", "value": "C66693"},
             {"label": "Nuevo Leon", "value": "C66694"},
             {"label": "Nuevo Limar", "value": "C66695"},
             {"label": "Nuevo Mexico", "value": "C66696"},
             {"label": "Nuevo Milenio Valdivia", "value": "C66697"},
             {"label": "Nuevo Naranjo", "value": "C66698"},
             {"label": "Nuevo Nicapa", "value": "C66699"},
             {"label": "Nuevo Pacayal", "value": "C66700"},
             {"label": "Nuevo Pumpuapa (Cereso)", "value": "C66701"},
             {"label": "Nuevo San Juan Chamula", "value": "C66702"},
             {"label": "Nuevo Sitala", "value": "C66703"},
             {"label": "Nuevo Vicente Guerrero", "value": "C66704"},
             {"label": "Nuevo Vicente Guerrero (El Chichonal)",
              "value": "C66705"},
             {"label": "Nuevo Volcan Chichonal", "value": "C66706"},
             {"label": "Nuevo Xochimilco", "value": "C66707"},
             {"label": "Ochusjob", "value": "C66708"},
             {"label": "Ococh", "value": "C66709"},
             {"label": "Ocosingo", "value": "C66710"},
             {"label": "Ocotepec", "value": "C66711"},
             {"label": "Ocozocoautla de Espinosa", "value": "C66712"},
             {"label": "Octavio Paz", "value": "C66713"},
             {"label": "Ojo de Agua", "value": "C66714"},
             {"label": "Omoa", "value": "C66715"},
             {"label": "Once de Abril", "value": "C66716"},
             {"label": "Ostuacan", "value": "C66717"},
             {"label": "Osumacinta", "value": "C66718"},
             {"label": "Oxchuc", "value": "C66719"},
             {"label": "Oxinam", "value": "C66720"},
             {"label": "Pablo L. Sidar", "value": "C66721"},
             {"label": "Pacu", "value": "C66722"},
             {"label": "Palenque", "value": "C66723"},
             {"label": "Pantelho", "value": "C66724"},
             {"label": "Pantepec", "value": "C66725"},
             {"label": "Paraiso del Grijalva", "value": "C66726"},
             {"label": "Paredon", "value": "C66727"},
             {"label": "Paso Hondo", "value": "C66728"},
             {"label": "Paste", "value": "C66729"},
             {"label": "Patosil", "value": "C66730"},
             {"label": "Patria Nueva (San Jose el Contento)",
              "value": "C66731"},
             {"label": "Pavencul", "value": "C66732"},
             {"label": "Perla de Acapulco", "value": "C66733"},
             {"label": "Petalcingo", "value": "C66734"},
             {"label": "Pichucalco", "value": "C66735"},
             {"label": "Piedra Labrada", "value": "C66736"},
             {"label": "Pijijiapan", "value": "C66737"},
             {"label": "Plan de Agua Prieta", "value": "C66738"},
             {"label": "Plan de Ayala", "value": "C66739"},
             {"label": "Pomposo Castellanos", "value": "C66740"},
             {"label": "Potrerillo", "value": "C66741"},
             {"label": "Presidente Echeverria (Laja Tendida)",
              "value": "C66742"},
             {"label": "Primer Canton", "value": "C66743"},
             {"label": "Profresor Roberto Barrios", "value": "C66744"},
             {"label": "Puebla", "value": "C66745"},
             {"label": "Pueblo Nuevo", "value": "C66746"},
             {"label": "Puerto Madero", "value": "C66747"},
             {"label": "Pugchen Mumuntic", "value": "C66748"},
             {"label": "Punta Arena", "value": "C66749"},
             {"label": "Queretaro", "value": "C66750"},
             {"label": "Quintana Roo", "value": "C66751"},
             {"label": "Rafael Pascacio Gamboa", "value": "C66752"},
             {"label": "Rafael Ramirez", "value": "C66753"},
             {"label": "Ramon F. Balboa", "value": "C66754"},
             {"label": "Raudales Malpaso", "value": "C66755"},
             {"label": "Raymundo Enriquez", "value": "C66756"},
             {"label": "Rayon", "value": "C66757"},
             {"label": "Reforma", "value": "C66758"},
             {"label": "Reforma y Planada", "value": "C66759"},
             {"label": "Revolucion Mexicana", "value": "C66760"},
             {"label": "Ricardo Flores Magon", "value": "C66761"},
             {"label": "Rincon Chamula", "value": "C66762"},
             {"label": "Rio Blanco", "value": "C66763"},
             {"label": "Rio Chancala", "value": "C66764"},
             {"label": "Rio Jordan", "value": "C66765"},
             {"label": "Rizo de Oro", "value": "C66766"},
             {"label": "Roblada Grande", "value": "C66767"},
             {"label": "Rodulfo Figueroa", "value": "C66768"},
             {"label": "Romerillo", "value": "C66769"},
             {"label": "Sabanilla", "value": "C66770"},
             {"label": "Sabinalito", "value": "C66771"},
             {"label": "Saclamanton", "value": "C66772"},
             {"label": "Saltillo", "value": "C66773"},
             {"label": "Salto de Agua", "value": "C66774"},
             {"label": "Salvador Urbina", "value": "C66775"},
             {"label": "San Andres Duraznal", "value": "C66776"},
             {"label": "San Antonio Buenavista", "value": "C66777"},
             {"label": "San Antonio Bulujib", "value": "C66778"},
             {"label": "San Antonio del Monte", "value": "C66779"},
             {"label": "San Caralampio", "value": "C66780"},
             {"label": "San Cayetano", "value": "C66781"},
             {"label": "San Cristobal de las Casas", "value": "C66782"},
             {"label": "San Felipe Tizapa", "value": "C66783"},
             {"label": "San Fernando", "value": "C66784"},
             {"label": "San Francisco (El Calvito)", "value": "C66785"},
             {"label": "San Francisco Jacona", "value": "C66786"},
             {"label": "San Francisco Pujiltic", "value": "C66787"},
             {"label": "San Isidro", "value": "C66788"},
             {"label": "San Isidro las Banderas", "value": "C66789"},
             {"label": "San Jeronimo Tulija", "value": "C66790"},
             {"label": "San Jose Chapayal", "value": "C66791"},
             {"label": "San Jose las Chicharras", "value": "C66792"},
             {"label": "San Jose Yashitinin", "value": "C66793"},
             {"label": "San Jose Yocnajab", "value": "C66794"},
             {"label": "San Lucas", "value": "C66795"},
             {"label": "San Luqueno", "value": "C66796"},
             {"label": "San Miguel", "value": "C66797"},
             {"label": "San Miguel la Sardina", "value": "C66798"},
             {"label": "San Pablo Huacano", "value": "C66799"},
             {"label": "San Pedro Buenavista", "value": "C66800"},
             {"label": "San Pedro Nichtalucum", "value": "C66801"},
             {"label": "San Quintin", "value": "C66802"},
             {"label": "San Vicente la Mesilla", "value": "C66803"},
             {"label": "Santa Elena", "value": "C66804"},
             {"label": "Santa Maria", "value": "C66805"},
             {"label": "Santa Rita", "value": "C66806"},
             {"label": "Santiago el Pinar", "value": "C66807"},
             {"label": "Santiago Pojcol", "value": "C66808"},
             {"label": "Santo Domingo", "value": "C66809"},
             {"label": "Santo Domingo de las Palmas", "value": "C66810"},
             {"label": "Senor del Pozo", "value": "C66811"},
             {"label": "Shoctic", "value": "C66812"},
             {"label": "Sibaca", "value": "C66813"},
             {"label": "Sibanilja Pocolum", "value": "C66814"},
             {"label": "Siltepec", "value": "C66815"},
             {"label": "Simojovel", "value": "C66816"},
             {"label": "Simojovel de Allende", "value": "C66817"},
             {"label": "Sinaloa", "value": "C66818"},
             {"label": "Sitala", "value": "C66819"},
             {"label": "Socoltenango", "value": "C66820"},
             {"label": "Soconusco", "value": "C66821"},
             {"label": "Solosuchiapa", "value": "C66822"},
             {"label": "Soyalo", "value": "C66823"},
             {"label": "Soyatitan", "value": "C66824"},
             {"label": "Suchiapa", "value": "C66825"},
             {"label": "Suchiate", "value": "C66826"},
             {"label": "Tacuba Nueva", "value": "C66827"},
             {"label": "Talisman", "value": "C66828"},
             {"label": "Tamaulipas", "value": "C66829"},
             {"label": "Taniperla", "value": "C66830"},
             {"label": "Tapachula", "value": "C66831"},
             {"label": "Tapalapa", "value": "C66832"},
             {"label": "Tapilula", "value": "C66833"},
             {"label": "Tecpatan", "value": "C66834"},
             {"label": "Tenango", "value": "C66835"},
             {"label": "Tentic", "value": "C66836"},
             {"label": "Teopisca", "value": "C66837"},
             {"label": "Texcaltic", "value": "C66838"},
             {"label": "Tierra y Libertad", "value": "C66839"},
             {"label": "Tila", "value": "C66840"},
             {"label": "Tiltepec", "value": "C66841"},
             {"label": "Tinajas 1ra. Seccion", "value": "C66842"},
             {"label": "Tocob Leglemal", "value": "C66843"},
             {"label": "Tonala", "value": "C66844"},
             {"label": "Totolapa", "value": "C66845"},
             {"label": "Tres Cerros", "value": "C66846"},
             {"label": "Tres Picos", "value": "C66847"},
             {"label": "Tumbala", "value": "C66848"},
             {"label": "Tuxtla", "value": "C66849"},
             {"label": "Tuxtla Chico", "value": "C66850"},
             {"label": "Tuzantan", "value": "C66851"},
             {"label": "TzAquiviljok", "value": "C66852"},
             {"label": "Tzajala", "value": "C66853"},
             {"label": "Tzajalchen", "value": "C66854"},
             {"label": "Tzeltal", "value": "C66855"},
             {"label": "Tzimol", "value": "C66856"},
             {"label": "Tzinil", "value": "C66857"},
             {"label": "Tziscao", "value": "C66858"},
             {"label": "Tzoeptic", "value": "C66859"},
             {"label": "Tzontehuitz", "value": "C66860"},
             {"label": "Tzopilja", "value": "C66861"},
             {"label": "Ubilio Garcia", "value": "C66862"},
             {"label": "Union Buena Vista", "value": "C66863"},
             {"label": "Union Buenavista", "value": "C66864"},
             {"label": "Union Juarez", "value": "C66865"},
             {"label": "Union Roja", "value": "C66866"},
             {"label": "Usipa", "value": "C66867"},
             {"label": "Vega del Rosario", "value": "C66868"},
             {"label": "Veinte de Noviembre", "value": "C66869"},
             {"label": "Venustiano Carranza", "value": "C66870"},
             {"label": "Veracruz", "value": "C66871"},
             {"label": "Verapaz", "value": "C66872"},
             {"label": "Vicente Guerrero", "value": "C66873"},
             {"label": "Vida Mejor I", "value": "C66874"},
             {"label": "Villa Comaltitlan", "value": "C66875"},
             {"label": "Villa Corzo", "value": "C66876"},
             {"label": "Villa Hidalgo", "value": "C66877"},
             {"label": "Villaflores", "value": "C66878"},
             {"label": "Villahermosa Yaluma", "value": "C66879"},
             {"label": "Villamorelos", "value": "C66880"},
             {"label": "Viva Cardenas", "value": "C66881"},
             {"label": "Viva Mexico", "value": "C66882"},
             {"label": "Xochiltepec", "value": "C66883"},
             {"label": "Yajalon", "value": "C66884"},
             {"label": "Yaltem", "value": "C66885"},
             {"label": "Yasha", "value": "C66886"},
             {"label": "Yashanal", "value": "C66887"},
             {"label": "Yibeljoj", "value": "C66888"},
             {"label": "Yoshib", "value": "C66889"},
             {"label": "Zacatonal de Juarez", "value": "C66890"},
             {"label": "Zacualpa", "value": "C66891"},
             {"label": "Zamora Pico de Oro", "value": "C66892"},
             {"label": "Zaragoza", "value": "C66893"},
             {"label": "Zaragoza la Montana", "value": "C66894"},
             {"label": "Zequentic", "value": "C66895"},
             {"label": "Zinacantan", "value": "C66896"}],
 "Chiayi": [{"label": "Chiayi", "value": "C117957"},
            {"label": "Pizitou", "value": "C117958"},
            {"label": "Chiayi County", "value": "C117959"}],
 "Chiba Prefecture": [{"label": "Abiko", "value": "C62642"},
                      {"label": "Abiko-shi", "value": "C62643"},
                      {"label": "Asahi-shi", "value": "C62644"},
                      {"label": "Chiba", "value": "C62645"},
                      {"label": "Choshi-shi", "value": "C62646"},
                      {"label": "Funabashi-shi", "value": "C62647"},
                      {"label": "Futtsu", "value": "C62648"},
                      {"label": "Hasaki", "value": "C62649"},
                      {"label": "Honcho", "value": "C62650"},
                      {"label": "Ichihara", "value": "C62651"},
                      {"label": "Ichihara Shi", "value": "C62652"},
                      {"label": "Ichikawa Shi", "value": "C62653"},
                      {"label": "Inzai-shi", "value": "C62654"},
                      {"label": "Isumi", "value": "C62655"},
                      {"label": "Isumi Shi", "value": "C62656"},
                      {"label": "Kamagaya Shi", "value": "C62657"},
                      {"label": "Kamogawa", "value": "C62658"},
                      {"label": "Kamogawa-shi", "value": "C62659"},
                      {"label": "Kashiwa", "value": "C62660"},
                      {"label": "Kashiwa Shi", "value": "C62661"},
                      {"label": "Katori-shi", "value": "C62662"},
                      {"label": "Katsuura", "value": "C62663"},
                      {"label": "Katsuura-shi", "value": "C62664"},
                      {"label": "Kimitsu", "value": "C62665"},
                      {"label": "Kisarazu", "value": "C62666"},
                      {"label": "Kisarazu Shi", "value": "C62667"},
                      {"label": "Matsudo Shi", "value": "C62668"},
                      {"label": "Minamiboso Shi", "value": "C62669"},
                      {"label": "Mobara", "value": "C62670"},
                      {"label": "Mobara Shi", "value": "C62671"},
                      {"label": "Nagareyama", "value": "C62672"},
                      {"label": "Nagareyama-shi", "value": "C62673"},
                      {"label": "Narashino-shi", "value": "C62674"},
                      {"label": "Narita", "value": "C62675"},
                      {"label": "Narita-shi", "value": "C62676"},
                      {"label": "Naruto", "value": "C62677"},
                      {"label": "Noda", "value": "C62678"},
                      {"label": "Noda-shi", "value": "C62679"},
                      {"label": "Oami", "value": "C62680"},
                      {"label": "Oamishirasato-shi", "value": "C62681"},
                      {"label": "Ohara", "value": "C62682"},
                      {"label": "Omigawa", "value": "C62683"},
                      {"label": "Sakura", "value": "C62684"},
                      {"label": "Sakura-shi", "value": "C62685"},
                      {"label": "Sambu-gun", "value": "C62686"},
                      {"label": "Sanmu", "value": "C62687"},
                      {"label": "Sanmu-shi", "value": "C62688"},
                      {"label": "Sawara", "value": "C62689"},
                      {"label": "Shiroi", "value": "C62690"},
                      {"label": "Shiroi-shi", "value": "C62691"},
                      {"label": "Shisui", "value": "C62692"},
                      {"label": "Sodegaura-shi", "value": "C62693"},
                      {"label": "Sosa-shi", "value": "C62694"},
                      {"label": "Tateyama", "value": "C62695"},
                      {"label": "Tateyama-shi", "value": "C62696"},
                      {"label": "Togane", "value": "C62697"},
                      {"label": "Togane-shi", "value": "C62698"},
                      {"label": "Tomisato-shi", "value": "C62699"},
                      {"label": "Urayasu-shi", "value": "C62700"},
                      {"label": "Yachimata", "value": "C62701"},
                      {"label": "Yachimata-shi", "value": "C62702"},
                      {"label": "Yachiyo-shi", "value": "C62703"},
                      {"label": "Yokaichiba", "value": "C62704"},
                      {"label": "Yotsukaido", "value": "C62705"},
                      {"label": "Yotsukaido-shi", "value": "C62706"}],
 "Chiesanuova": [{"label": "Poggio di Chiesanuova", "value": "C106247"}],
 "Chihuahua": [{"label": "Abdenago C. Garcia", "value": "C66897"},
               {"label": "Adolfo Lopez Mateos", "value": "C66898"},
               {"label": "Ahumada", "value": "C66899"},
               {"label": "Aldama", "value": "C66900"},
               {"label": "Allende", "value": "C66901"},
               {"label": "Ampliacion Colonia Lazaro Cardenas",
                "value": "C66902"},
               {"label": "Anahuac", "value": "C66903"},
               {"label": "Aquiles Serdan", "value": "C66904"},
               {"label": "Ascension", "value": "C66905"},
               {"label": "Baborigame", "value": "C66906"},
               {"label": "Bachiniva", "value": "C66907"},
               {"label": "Bahuichivo", "value": "C66908"},
               {"label": "Balleza", "value": "C66909"},
               {"label": "Basaseachic", "value": "C66910"},
               {"label": "Basuchil", "value": "C66911"},
               {"label": "Batopilas", "value": "C66912"},
               {"label": "Benito Juarez", "value": "C66913"},
               {"label": "Bocoyna", "value": "C66914"},
               {"label": "Boquilla de Babisas (La Boquilla de Conchos)",
                "value": "C66915"},
               {"label": "Buenaventura", "value": "C66916"},
               {"label": "Camargo", "value": "C66917"},
               {"label": "Carichi", "value": "C66918"},
               {"label": "Carretas", "value": "C66919"},
               {"label": "Casas Grandes", "value": "C66920"},
               {"label": "Cerocahui", "value": "C66921"},
               {"label": "Chihuahua", "value": "C66922"},
               {"label": "Chinipas", "value": "C66923"},
               {"label": "Ciudad Delicias", "value": "C66924"},
               {"label": "Colonia Abraham Gonzalez (La Quemada)",
                "value": "C66925"},
               {"label": "Colonia Campesina", "value": "C66926"},
               {"label": "Colonia Felipe angeles", "value": "C66927"},
               {"label": "Colonia Nicolas Bravo (Kilometro Noventa y Dos)",
                "value": "C66928"},
               {"label": "Colonia Revolucion", "value": "C66929"},
               {"label": "Colonia Terrazas", "value": "C66930"},
               {"label": "Conchos", "value": "C66931"},
               {"label": "Congregacion Ortiz", "value": "C66932"},
               {"label": "Coyame", "value": "C66933"},
               {"label": "Coyame del Sotol", "value": "C66934"},
               {"label": "Creel", "value": "C66935"},
               {"label": "Cuauhtemoc", "value": "C66936"},
               {"label": "Cusihuiriachi", "value": "C66937"},
               {"label": "Delicias", "value": "C66938"},
               {"label": "Dr. Belisario Dominguez", "value": "C66939"},
               {"label": "Ejido Benito Juarez", "value": "C66940"},
               {"label": "Ejido El Largo", "value": "C66941"},
               {"label": "Ejido El Vergel", "value": "C66942"},
               {"label": "Ejido La Quemada", "value": "C66943"},
               {"label": "El Molino", "value": "C66944"},
               {"label": "El Oro", "value": "C66945"},
               {"label": "El Sauz", "value": "C66946"},
               {"label": "El Terrero", "value": "C66947"},
               {"label": "El Tule", "value": "C66948"},
               {"label": "Estacion Conchos", "value": "C66949"},
               {"label": "Estacion Consuelo", "value": "C66950"},
               {"label": "Galeana", "value": "C66951"},
               {"label": "Gomez Farias", "value": "C66952"},
               {"label": "Gran Morelos", "value": "C66953"},
               {"label": "Guachochi", "value": "C66954"},
               {"label": "Guadalupe", "value": "C66955"},
               {"label": "Guadalupe Victoria", "value": "C66956"},
               {"label": "Guadalupe y Calvo", "value": "C66957"},
               {"label": "Guazapares", "value": "C66958"},
               {"label": "Guerrero", "value": "C66959"},
               {"label": "Hidalgo del Parral", "value": "C66960"},
               {"label": "Huejotitan", "value": "C66961"},
               {"label": "Ignacio Zaragoza", "value": "C66962"},
               {"label": "Independencia", "value": "C66963"},
               {"label": "Janos", "value": "C66964"},
               {"label": "Jimenez", "value": "C66965"},
               {"label": "Jose Esteban Coronado", "value": "C66966"},
               {"label": "Jose Mariano Jimenez", "value": "C66967"},
               {"label": "Juarez", "value": "C66968"},
               {"label": "Julimes", "value": "C66969"},
               {"label": "Kilometro Noventa y Nueve", "value": "C66970"},
               {"label": "La Constitucion", "value": "C66971"},
               {"label": "La Cruz", "value": "C66972"},
               {"label": "La Junta", "value": "C66973"},
               {"label": "Las Cruces", "value": "C66974"},
               {"label": "Las Varas", "value": "C66975"},
               {"label": "Las Varas (Estacion Babicora)", "value": "C66976"},
               {"label": "Las Yerbitas [Aserradero]", "value": "C66977"},
               {"label": "Lazaro Cardenas", "value": "C66978"},
               {"label": "Loma Blanca", "value": "C66979"},
               {"label": "Madera", "value": "C66980"},
               {"label": "Maguarichi", "value": "C66981"},
               {"label": "Manuel Benavides", "value": "C66982"},
               {"label": "Manuel Ojinaga", "value": "C66983"},
               {"label": "Mariano Balleza", "value": "C66984"},
               {"label": "Matachi", "value": "C66985"},
               {"label": "Matamoros", "value": "C66986"},
               {"label": "Meoqui", "value": "C66987"},
               {"label": "Mesa del Huracan", "value": "C66988"},
               {"label": "Miguel Ahumada", "value": "C66989"},
               {"label": "Miguel Hidalgo", "value": "C66990"},
               {"label": "Monte Verde", "value": "C66991"},
               {"label": "Morelos", "value": "C66992"},
               {"label": "Moris", "value": "C66993"},
               {"label": "Naica", "value": "C66994"},
               {"label": "Namiquipa", "value": "C66995"},
               {"label": "Nicolas Bravo", "value": "C66996"},
               {"label": "Ninguno [CERESO]", "value": "C66997"},
               {"label": "Nonoava", "value": "C66998"},
               {"label": "Nuevo Casas Grandes", "value": "C66999"},
               {"label": "Ocampo", "value": "C67000"},
               {"label": "Octaviano Lopez", "value": "C67001"},
               {"label": "Ojinaga", "value": "C67002"},
               {"label": "Orranteno", "value": "C67003"},
               {"label": "Oscar Soto Maynez", "value": "C67004"},
               {"label": "Parral", "value": "C67005"},
               {"label": "Pena Blanca", "value": "C67006"},
               {"label": "Porvenir", "value": "C67007"},
               {"label": "Praxedis G. Guerrero", "value": "C67008"},
               {"label": "Praxedis Guerrero", "value": "C67009"},
               {"label": "Pueblito de Allende", "value": "C67010"},
               {"label": "Puerto Palomas", "value": "C67011"},
               {"label": "Riva Palacio", "value": "C67012"},
               {"label": "Rodrigo M. Quevedo", "value": "C67013"},
               {"label": "Rosales", "value": "C67014"},
               {"label": "Rosario", "value": "C67015"},
               {"label": "Samachique", "value": "C67016"},
               {"label": "Samalayuca", "value": "C67017"},
               {"label": "San Agustin", "value": "C67018"},
               {"label": "San Andres", "value": "C67019"},
               {"label": "San Buenaventura", "value": "C67020"},
               {"label": "San Carlos", "value": "C67021"},
               {"label": "San Francisco de Borja", "value": "C67022"},
               {"label": "San Francisco de Conchos", "value": "C67023"},
               {"label": "San Francisco del Oro", "value": "C67024"},
               {"label": "San Isidro", "value": "C67025"},
               {"label": "San Juanito", "value": "C67026"},
               {"label": "San Lorenzo", "value": "C67027"},
               {"label": "San Rafael", "value": "C67028"},
               {"label": "Santa Barbara", "value": "C67029"},
               {"label": "Santa Eulalia", "value": "C67030"},
               {"label": "Santa Isabel", "value": "C67031"},
               {"label": "Saucillo", "value": "C67032"},
               {"label": "Sisoguichi", "value": "C67033"},
               {"label": "Temoris", "value": "C67034"},
               {"label": "Temosachic", "value": "C67035"},
               {"label": "Tomochic", "value": "C67036"},
               {"label": "Turuachi", "value": "C67037"},
               {"label": "Urique", "value": "C67038"},
               {"label": "Uruachi", "value": "C67039"},
               {"label": "Valentin Gomez Farias", "value": "C67040"},
               {"label": "Valle de Allende", "value": "C67041"},
               {"label": "Valle de Zaragoza", "value": "C67042"},
               {"label": "Valle del Rosario", "value": "C67043"},
               {"label": "Yecora", "value": "C67044"}],
 "Chimaltenango Department": [{"label": "Acatenango", "value": "C42638"},
                              {"label": "Chimaltenango", "value": "C42639"},
                              {"label": "Comalapa", "value": "C42640"},
                              {"label": "El Tejar", "value": "C42641"},
                              {"label": "Parramos", "value": "C42642"},
                              {"label": "Patzicia", "value": "C42643"},
                              {"label": "Patzun", "value": "C42644"},
                              {"label": "Pochuta", "value": "C42645"},
                              {"label": "San Andres Itzapa", "value": "C42646"},
                              {"label": "San Jose Poaquil", "value": "C42647"},
                              {"label": "San Martin Jilotepeque",
                               "value": "C42648"},
                              {"label": "Santa Apolonia", "value": "C42649"},
                              {"label": "Santa Cruz Balanya",
                               "value": "C42650"},
                              {"label": "Tecpan Guatemala", "value": "C42651"},
                              {"label": "Yepocapa", "value": "C42652"},
                              {"label": "Zaragoza", "value": "C42653"}],
 "Chimborazo": [{"label": "Alausi", "value": "C24106"},
                {"label": "Guano", "value": "C24107"},
                {"label": "Riobamba", "value": "C24108"}],
 "Chimbu Province": [{"label": "Chuave", "value": "C80396"},
                     {"label": "Gumine", "value": "C80397"},
                     {"label": "Karimui Nomane", "value": "C80398"},
                     {"label": "Kerowagi", "value": "C80399"},
                     {"label": "Kundiawa", "value": "C80400"},
                     {"label": "Sinasina Yonggamugl", "value": "C80401"}],
 "Chin State": [{"label": "Falam", "value": "C75909"},
                {"label": "Hakha", "value": "C75910"}],
 "Chinandega": [{"label": "Chichigalpa", "value": "C77902"},
                {"label": "Chinandega", "value": "C77903"},
                {"label": "Cinco Pinos", "value": "C77904"},
                {"label": "Corinto", "value": "C77905"},
                {"label": "El Realejo", "value": "C77906"},
                {"label": "El Viejo", "value": "C77907"},
                {"label": "Jiquilillo", "value": "C77908"},
                {"label": "Municipio de San Francisco del Norte",
                 "value": "C77909"},
                {"label": "Posoltega", "value": "C77910"},
                {"label": "Puerto Morazan", "value": "C77911"},
                {"label": "Santo Tomas del Norte", "value": "C77912"},
                {"label": "Somotillo", "value": "C77913"}],
 "Chiquimula Department": [{"label": "Camotan", "value": "C42654"},
                           {"label": "Chiquimula", "value": "C42655"},
                           {"label": "Concepcion Las Minas", "value": "C42656"},
                           {"label": "Esquipulas", "value": "C42657"},
                           {"label": "Ipala", "value": "C42658"},
                           {"label": "Jocotan", "value": "C42659"},
                           {"label": "Olopa", "value": "C42660"},
                           {"label": "Quezaltepeque", "value": "C42661"},
                           {"label": "San Jacinto", "value": "C42662"},
                           {"label": "San Jose La Arada", "value": "C42663"},
                           {"label": "San Juan Ermita", "value": "C42664"}],
 "Chiriqui Province": [{"label": "Alanje", "value": "C79807"},
                       {"label": "Algarrobos Arriba", "value": "C79808"},
                       {"label": "Alto Boquete", "value": "C79809"},
                       {"label": "Aserrio de Gariche", "value": "C79810"},
                       {"label": "Bagala", "value": "C79811"},
                       {"label": "Bajo Boquete", "value": "C79812"},
                       {"label": "Barrio Guadalupe", "value": "C79813"},
                       {"label": "Bijagual", "value": "C79814"},
                       {"label": "Boca Chica", "value": "C79815"},
                       {"label": "Boca del Monte", "value": "C79816"},
                       {"label": "Boqueron", "value": "C79817"},
                       {"label": "Boquete", "value": "C79818"},
                       {"label": "Brenon", "value": "C79819"},
                       {"label": "Bugaba", "value": "C79820"},
                       {"label": "Bugabita Arriba", "value": "C79821"},
                       {"label": "Caimito", "value": "C79822"},
                       {"label": "Caldera", "value": "C79823"},
                       {"label": "Canas Gordas", "value": "C79824"},
                       {"label": "Celmira", "value": "C79825"},
                       {"label": "Cerro Punta", "value": "C79826"},
                       {"label": "Cerro Viejo", "value": "C79827"},
                       {"label": "Chiriqui", "value": "C79828"},
                       {"label": "Cochea Abajo", "value": "C79829"},
                       {"label": "Cordillera", "value": "C79830"},
                       {"label": "David", "value": "C79831"},
                       {"label": "Distrito de Alanje", "value": "C79832"},
                       {"label": "Distrito de Baru", "value": "C79833"},
                       {"label": "Distrito de Boqueron", "value": "C79834"},
                       {"label": "Distrito de Boquete", "value": "C79835"},
                       {"label": "Distrito de Bugaba", "value": "C79836"},
                       {"label": "Distrito de David", "value": "C79837"},
                       {"label": "Distrito de Dolega", "value": "C79838"},
                       {"label": "Distrito de Gualaca", "value": "C79839"},
                       {"label": "Distrito de Remedios", "value": "C79840"},
                       {"label": "Distrito de Renacimiento", "value": "C79841"},
                       {"label": "Distrito de San Felix", "value": "C79842"},
                       {"label": "Distrito de San Lorenzo", "value": "C79843"},
                       {"label": "Distrito de Tole", "value": "C79844"},
                       {"label": "Divala", "value": "C79845"},
                       {"label": "Dolega District", "value": "C79846"},
                       {"label": "El Nancito", "value": "C79847"},
                       {"label": "El Palmar", "value": "C79848"},
                       {"label": "El Porvenir", "value": "C79849"},
                       {"label": "El Tejar", "value": "C79850"},
                       {"label": "Finca Blanco", "value": "C79851"},
                       {"label": "Gomez", "value": "C79852"},
                       {"label": "Guabal", "value": "C79853"},
                       {"label": "Guaca Arriba", "value": "C79854"},
                       {"label": "Gualaca", "value": "C79855"},
                       {"label": "Guarumal", "value": "C79856"},
                       {"label": "Guayabal", "value": "C79857"},
                       {"label": "Horconcitos", "value": "C79858"},
                       {"label": "La Concepcion", "value": "C79859"},
                       {"label": "La Esperanza", "value": "C79860"},
                       {"label": "La Estrella", "value": "C79861"},
                       {"label": "Lajas Adentro", "value": "C79862"},
                       {"label": "Lajas de Tole", "value": "C79863"},
                       {"label": "Las Lajas", "value": "C79864"},
                       {"label": "Las Lomas", "value": "C79865"},
                       {"label": "Limones", "value": "C79866"},
                       {"label": "Los Algarrobos", "value": "C79867"},
                       {"label": "Los Anastacios", "value": "C79868"},
                       {"label": "Los angeles", "value": "C79869"},
                       {"label": "Los Naranjos", "value": "C79870"},
                       {"label": "Manaca Civil", "value": "C79871"},
                       {"label": "Manaca Norte", "value": "C79872"},
                       {"label": "Mata del Nance", "value": "C79873"},
                       {"label": "Monte Lirio", "value": "C79874"},
                       {"label": "Nuevo San Carlitos", "value": "C79875"},
                       {"label": "Paja de Sombrero", "value": "C79876"},
                       {"label": "Palmira", "value": "C79877"},
                       {"label": "Palmira Centro", "value": "C79878"},
                       {"label": "Paraiso", "value": "C79879"},
                       {"label": "Paso Canoas Arriba", "value": "C79880"},
                       {"label": "Pedregal", "value": "C79881"},
                       {"label": "Plaza de Caisan", "value": "C79882"},
                       {"label": "Potrerillos Abajo", "value": "C79883"},
                       {"label": "Potrerillos Arriba", "value": "C79884"},
                       {"label": "Progreso", "value": "C79885"},
                       {"label": "Pueblo Viejo", "value": "C79886"},
                       {"label": "Puerto Armuelles", "value": "C79887"},
                       {"label": "Punta de Burica", "value": "C79888"},
                       {"label": "Quebrada de Piedra", "value": "C79889"},
                       {"label": "Querevalo", "value": "C79890"},
                       {"label": "Quinteno", "value": "C79891"},
                       {"label": "Remedios", "value": "C79892"},
                       {"label": "Rincon", "value": "C79893"},
                       {"label": "Rio Sereno", "value": "C79894"},
                       {"label": "Rovira Arriba", "value": "C79895"},
                       {"label": "San Andres", "value": "C79896"},
                       {"label": "San Carlos", "value": "C79897"},
                       {"label": "San Felix", "value": "C79898"},
                       {"label": "San Isidro", "value": "C79899"},
                       {"label": "San Lorenzo", "value": "C79900"},
                       {"label": "San Pablo Nuevo Abajo", "value": "C79901"},
                       {"label": "San Pablo Viejo Abajo", "value": "C79902"},
                       {"label": "Santa Clara", "value": "C79903"},
                       {"label": "Santa Cruz", "value": "C79904"},
                       {"label": "Santa Marta", "value": "C79905"},
                       {"label": "Santa Rosa", "value": "C79906"},
                       {"label": "Santo Domingo", "value": "C79907"},
                       {"label": "Santo Tomas", "value": "C79908"},
                       {"label": "Siogui Abajo", "value": "C79909"},
                       {"label": "Siogui Arriba", "value": "C79910"},
                       {"label": "Sortova", "value": "C79911"},
                       {"label": "Tijeras", "value": "C79912"},
                       {"label": "Tinajas", "value": "C79913"},
                       {"label": "Tole", "value": "C79914"},
                       {"label": "Veladero", "value": "C79915"},
                       {"label": "Volcan", "value": "C79916"}],
 "Chisinau Municipality": [{"label": "Chisinau", "value": "C75498"},
                           {"label": "Ciorescu", "value": "C75499"},
                           {"label": "Cricova", "value": "C75500"},
                           {"label": "Singera", "value": "C75501"},
                           {"label": "Stauceni", "value": "C75502"},
                           {"label": "Vadul lui Voda", "value": "C75503"},
                           {"label": "Vatra", "value": "C75504"}],
 "Chittagong Division": [{"label": "Bandarban", "value": "C8567"},
                         {"label": "Bibir Hat", "value": "C8568"},
                         {"label": "Brahmanbaria", "value": "C8569"},
                         {"label": "Chandpur", "value": "C8570"},
                         {"label": "Chhagalnaiya", "value": "C8571"},
                         {"label": "Chittagong", "value": "C8572"},
                         {"label": "Comilla", "value": "C8573"},
                         {"label": "Coxs Bazar", "value": "C8574"},
                         {"label": "Coxs Bazar", "value": "C8575"},
                         {"label": "Feni", "value": "C8576"},
                         {"label": "Hajiganj", "value": "C8577"},
                         {"label": "Khagrachhari", "value": "C8578"},
                         {"label": "Laksham", "value": "C8579"},
                         {"label": "Lakshmipur", "value": "C8580"},
                         {"label": "Manikchari", "value": "C8581"},
                         {"label": "Nabinagar", "value": "C8582"},
                         {"label": "Noakhali", "value": "C8583"},
                         {"label": "Patiya", "value": "C8584"},
                         {"label": "Raipur", "value": "C8585"},
                         {"label": "Ramganj", "value": "C8586"},
                         {"label": "Rangamati", "value": "C8587"},
                         {"label": "Raojan", "value": "C8588"},
                         {"label": "Sandwip", "value": "C8589"},
                         {"label": "Satkania", "value": "C8590"},
                         {"label": "Teknaf", "value": "C8591"}],
 "Chlef": [{"label": "Abou el Hassan", "value": "C310"},
           {"label": "Boukadir", "value": "C311"},
           {"label": "Chlef", "value": "C312"},
           {"label": "Ech Chettia", "value": "C313"},
           {"label": "Oued Fodda", "value": "C314"},
           {"label": "Oued Sly", "value": "C315"},
           {"label": "Sidi Akkacha", "value": "C316"}],
 "Choco": [{"label": "Acandi", "value": "C20002"},
           {"label": "Alto Baudo", "value": "C20003"},
           {"label": "Atrato", "value": "C20004"},
           {"label": "Bagado", "value": "C20005"},
           {"label": "Bahia Solano", "value": "C20006"},
           {"label": "Bajo Baudo", "value": "C20007"},
           {"label": "Bojaya", "value": "C20008"},
           {"label": "Carmen del Darien", "value": "C20009"},
           {"label": "Certegui", "value": "C20010"},
           {"label": "Condoto", "value": "C20011"},
           {"label": "El Canton de San Pablo", "value": "C20012"},
           {"label": "El Carmen de Atrato", "value": "C20013"},
           {"label": "Istmina", "value": "C20014"},
           {"label": "Jurado", "value": "C20015"},
           {"label": "Litoral del San Juan", "value": "C20016"},
           {"label": "Lloro", "value": "C20017"},
           {"label": "Medio Atrato", "value": "C20018"},
           {"label": "Medio Baudo", "value": "C20019"},
           {"label": "Medio San Juan", "value": "C20020"},
           {"label": "Novita", "value": "C20021"},
           {"label": "Nuqui", "value": "C20022"},
           {"label": "Quibdo", "value": "C20023"},
           {"label": "Rio Iro", "value": "C20024"},
           {"label": "Rio Quito", "value": "C20025"},
           {"label": "Riosucio", "value": "C20026"},
           {"label": "San Jose del Palmar", "value": "C20027"},
           {"label": "Sipi", "value": "C20028"},
           {"label": "Tado", "value": "C20029"},
           {"label": "Unguia", "value": "C20030"},
           {"label": "Union Panamericana", "value": "C20031"}],
 "Choiseul Quarter": [{"label": "Belle Vue", "value": "C105887"},
                      {"label": "Bois Dinde", "value": "C105888"},
                      {"label": "Caffiere", "value": "C105889"},
                      {"label": "CedarsChu Tigre", "value": "C105890"},
                      {"label": "Choiseul", "value": "C105891"},
                      {"label": "Christian Hill", "value": "C105892"},
                      {"label": "Dacretin", "value": "C105893"},
                      {"label": "Debreuil", "value": "C105894"},
                      {"label": "Delcer", "value": "C105895"},
                      {"label": "Derriere Morne", "value": "C105896"},
                      {"label": "Dugard", "value": "C105897"},
                      {"label": "Dupre", "value": "C105898"},
                      {"label": "Esperance", "value": "C105899"},
                      {"label": "Franciou", "value": "C105900"},
                      {"label": "Industry", "value": "C105901"},
                      {"label": "La Fargue", "value": "C105902"},
                      {"label": "La Pointe", "value": "C105903"},
                      {"label": "Lamaze", "value": "C105904"},
                      {"label": "Le Riche", "value": "C105905"},
                      {"label": "Mailly Motete", "value": "C105906"},
                      {"label": "Martin", "value": "C105907"},
                      {"label": "Mongouge", "value": "C105908"},
                      {"label": "Monzie", "value": "C105909"},
                      {"label": "Morne Jacques", "value": "C105910"},
                      {"label": "Morne Sion", "value": "C105911"},
                      {"label": "Motete", "value": "C105912"},
                      {"label": "New FieldFiette", "value": "C105913"},
                      {"label": "Ponyon", "value": "C105914"},
                      {"label": "Raveneau", "value": "C105915"},
                      {"label": "Reunion", "value": "C105916"},
                      {"label": "River Doree", "value": "C105917"},
                      {"label": "Roblot", "value": "C105918"},
                      {"label": "Sauzay", "value": "C105919"},
                      {"label": "SavannesgeorgeConstitution",
                       "value": "C105920"},
                      {"label": "Victoria", "value": "C105921"},
                      {"label": "Village", "value": "C105922"}],
 "Choluteca Department": [{"label": "Apacilagua", "value": "C43231"},
                          {"label": "Choluteca", "value": "C43232"},
                          {"label": "Ciudad Choluteca", "value": "C43233"},
                          {"label": "Concepcion de Maria", "value": "C43234"},
                          {"label": "Corpus", "value": "C43235"},
                          {"label": "Duyure", "value": "C43236"},
                          {"label": "El Corpus", "value": "C43237"},
                          {"label": "El Obraje", "value": "C43238"},
                          {"label": "El Puente", "value": "C43239"},
                          {"label": "El Triunfo", "value": "C43240"},
                          {"label": "Los Llanitos", "value": "C43241"},
                          {"label": "Marcovia", "value": "C43242"},
                          {"label": "Monjaras", "value": "C43243"},
                          {"label": "Morolica", "value": "C43244"},
                          {"label": "Namasigue", "value": "C43245"},
                          {"label": "Orocuina", "value": "C43246"},
                          {"label": "Pespire", "value": "C43247"},
                          {"label": "San Antonio de Flores", "value": "C43248"},
                          {"label": "San Isidro", "value": "C43249"},
                          {"label": "San Jeronimo", "value": "C43250"},
                          {"label": "San Jose", "value": "C43251"},
                          {"label": "San Jose de Las Conchas",
                           "value": "C43252"},
                          {"label": "San Marcos de Colon", "value": "C43253"},
                          {"label": "Santa Ana de Yusguare", "value": "C43254"},
                          {"label": "Santa Cruz", "value": "C43255"}],
 "Chon Buri": [{"label": "Amphoe Ban Bueng", "value": "C118576"},
               {"label": "Amphoe Bo Thong", "value": "C118577"},
               {"label": "Amphoe Ko Chan", "value": "C118578"},
               {"label": "Amphoe Ko Si Chang", "value": "C118579"},
               {"label": "Amphoe Nong Yai", "value": "C118580"},
               {"label": "Amphoe Phan Thong", "value": "C118581"},
               {"label": "Amphoe Phanat Nikhom", "value": "C118582"},
               {"label": "Amphoe Sattahip", "value": "C118583"},
               {"label": "Amphoe Si Racha", "value": "C118584"},
               {"label": "Ban Bueng", "value": "C118585"},
               {"label": "Ban Ko Lan", "value": "C118586"},
               {"label": "Ban Talat Bueng", "value": "C118587"},
               {"label": "Bang Lamung", "value": "C118588"},
               {"label": "Bang Lamung District", "value": "C118589"},
               {"label": "Chon Buri", "value": "C118590"},
               {"label": "Ko Si Chang", "value": "C118591"},
               {"label": "Mueang Chonburi District", "value": "C118592"},
               {"label": "Pattaya", "value": "C118593"},
               {"label": "Phan Thong", "value": "C118594"},
               {"label": "Phanat Nikhom", "value": "C118595"},
               {"label": "Sattahip", "value": "C118596"},
               {"label": "Si Racha", "value": "C118597"}],
 "Chongqing": [{"label": "Beibei", "value": "C18283"},
               {"label": "Caijia", "value": "C18284"},
               {"label": "Chongqing", "value": "C18285"},
               {"label": "Dongxi", "value": "C18286"},
               {"label": "Fuling", "value": "C18287"},
               {"label": "Ganshui", "value": "C18288"},
               {"label": "Guofuchang", "value": "C18289"},
               {"label": "Hechuan", "value": "C18290"},
               {"label": "Jijiang", "value": "C18291"},
               {"label": "Liangping District", "value": "C18292"},
               {"label": "Puhechang", "value": "C18293"},
               {"label": "Shapingba District", "value": "C18294"},
               {"label": "Shijiaochang", "value": "C18295"},
               {"label": "Wanxian", "value": "C18296"},
               {"label": "Wanzhou District", "value": "C18297"},
               {"label": "Yongchuan", "value": "C18298"},
               {"label": "Yudong", "value": "C18299"},
               {"label": "Yuzhong District", "value": "C18300"}],
 "Chontales": [{"label": "Acoyapa", "value": "C77914"},
               {"label": "Comalapa", "value": "C77915"},
               {"label": "Cuapa", "value": "C77916"},
               {"label": "El Ayote", "value": "C77917"},
               {"label": "Juigalpa", "value": "C77918"},
               {"label": "La Libertad", "value": "C77919"},
               {"label": "Santo Domingo", "value": "C77920"},
               {"label": "Santo Tomas", "value": "C77921"},
               {"label": "Villa Sandino", "value": "C77922"}],
 "Christ Church": [{"label": "Oistins", "value": "C8695"}],
 "Christ Church Nichola Town Parish": [{"label": "Nicola Town",
                                        "value": "C105722"}],
 "Chubut": [{"label": "Alto Rio Senguer", "value": "C702"},
            {"label": "Camarones", "value": "C703"},
            {"label": "Comodoro Rivadavia", "value": "C704"},
            {"label": "Departamento de Biedma", "value": "C705"},
            {"label": "Departamento de Cushamen", "value": "C706"},
            {"label": "Departamento de Escalante", "value": "C707"},
            {"label": "Departamento de Florentino Ameghino", "value": "C708"},
            {"label": "Departamento de Futaleufu", "value": "C709"},
            {"label": "Departamento de Gaiman", "value": "C710"},
            {"label": "Departamento de Gastre", "value": "C711"},
            {"label": "Departamento de Languineo", "value": "C712"},
            {"label": "Departamento de Martires", "value": "C713"},
            {"label": "Departamento de Paso de Indios", "value": "C714"},
            {"label": "Departamento de Rawson", "value": "C715"},
            {"label": "Departamento de Rio Senguerr", "value": "C716"},
            {"label": "Departamento de Sarmiento", "value": "C717"},
            {"label": "Departamento de Tehuelches", "value": "C718"},
            {"label": "Departamento de Telsen", "value": "C719"},
            {"label": "Dolavon", "value": "C720"},
            {"label": "El Maiten", "value": "C721"},
            {"label": "Esquel", "value": "C722"},
            {"label": "Gaiman", "value": "C723"},
            {"label": "Gastre", "value": "C724"},
            {"label": "Gobernador Costa", "value": "C725"},
            {"label": "Hoyo de Epuyen", "value": "C726"},
            {"label": "Jose de San Martin", "value": "C727"},
            {"label": "Lago Puelo", "value": "C728"},
            {"label": "Las Plumas", "value": "C729"},
            {"label": "Puerto Madryn", "value": "C730"},
            {"label": "Rada Tilly", "value": "C731"},
            {"label": "Rawson", "value": "C732"},
            {"label": "Rio Mayo", "value": "C733"},
            {"label": "Rio Pico", "value": "C734"},
            {"label": "Sarmiento", "value": "C735"},
            {"label": "Tecka", "value": "C736"},
            {"label": "Trelew", "value": "C737"},
            {"label": "Trevelin", "value": "C738"}],
 "Chukha District": [{"label": "Daphu", "value": "C9631"},
                     {"label": "Phuntsholing", "value": "C9632"},
                     {"label": "Tsimasham", "value": "C9633"}],
 "Chukotka Autonomous Okrug": [{"label": "Anadyr", "value": "C100830"},
                               {"label": "Anadyrskiy Rayon",
                                "value": "C100831"},
                               {"label": "Beringovskiy", "value": "C100832"},
                               {"label": "Bilibino", "value": "C100833"},
                               {"label": "Egvekinot", "value": "C100834"},
                               {"label": "Lavrentiya", "value": "C100835"},
                               {"label": "Lorino", "value": "C100836"},
                               {"label": "Pevek", "value": "C100837"},
                               {"label": "Provideniya", "value": "C100838"},
                               {"label": "Providenskiy Rayon",
                                "value": "C100839"}],
 "Chumphon": [{"label": "Amphoe Lamae", "value": "C118598"},
              {"label": "Amphoe Lang Suan", "value": "C118599"},
              {"label": "Amphoe Mueang Chumphon", "value": "C118600"},
              {"label": "Amphoe Pathio", "value": "C118601"},
              {"label": "Amphoe Phato", "value": "C118602"},
              {"label": "Amphoe Sawi", "value": "C118603"},
              {"label": "Amphoe Tha Sae", "value": "C118604"},
              {"label": "Amphoe Thung Tako", "value": "C118605"},
              {"label": "Ban Nam Yuen", "value": "C118606"},
              {"label": "Chumphon", "value": "C118607"},
              {"label": "Lang Suan", "value": "C118608"}],
 "Chuquisaca Department": [{"label": "Camargo", "value": "C9671"},
                           {"label": "Monteagudo", "value": "C9672"},
                           {"label": "Padilla", "value": "C9673"},
                           {"label": "Provincia Azurduy", "value": "C9674"},
                           {"label": "Provincia Belisario Boeto",
                            "value": "C9675"},
                           {"label": "Provincia Hernando Siles",
                            "value": "C9676"},
                           {"label": "Provincia Luis Calvo", "value": "C9677"},
                           {"label": "Provincia Nor Cinti", "value": "C9678"},
                           {"label": "Provincia Oropeza", "value": "C9679"},
                           {"label": "Provincia Sud Cinti", "value": "C9680"},
                           {"label": "Provincia Tomina", "value": "C9681"},
                           {"label": "Provincia Yamparaez", "value": "C9682"},
                           {"label": "Provincia Zudanez", "value": "C9683"},
                           {"label": "Sucre", "value": "C9684"},
                           {"label": "Tarabuco", "value": "C9685"}],
 "Chuuk State": [{"label": "Eot Municipality", "value": "C75403"},
                 {"label": "Ettal Municipality", "value": "C75404"},
                 {"label": "Fananu Municipality", "value": "C75405"},
                 {"label": "Fanapanges Municipality", "value": "C75406"},
                 {"label": "Fefen Municipality", "value": "C75407"},
                 {"label": "Fonoton Municipality", "value": "C75408"},
                 {"label": "Houk Municipality", "value": "C75409"},
                 {"label": "Kuttu Municipality", "value": "C75410"},
                 {"label": "Lekinioch Municipality", "value": "C75411"},
                 {"label": "Losap Municipality", "value": "C75412"},
                 {"label": "Makur Municipality", "value": "C75413"},
                 {"label": "Moch Municipality", "value": "C75414"},
                 {"label": "Murilo", "value": "C75415"},
                 {"label": "Murilo Municipality", "value": "C75416"},
                 {"label": "Namoluk Municipality", "value": "C75417"},
                 {"label": "Nema", "value": "C75418"},
                 {"label": "Nema Municipality", "value": "C75419"},
                 {"label": "Nomwin Municipality", "value": "C75420"},
                 {"label": "Oneop Municipality", "value": "C75421"},
                 {"label": "Onou Municipality", "value": "C75422"},
                 {"label": "Onoun Municipality", "value": "C75423"},
                 {"label": "Paata-Tupunion Municipality", "value": "C75424"},
                 {"label": "Parem Municipality", "value": "C75425"},
                 {"label": "Piherarh Municipality", "value": "C75426"},
                 {"label": "Piis-Emwar Municipality", "value": "C75427"},
                 {"label": "Piis-Panewu Municipality", "value": "C75428"},
                 {"label": "Pollap Municipality", "value": "C75429"},
                 {"label": "Polowat Municipality", "value": "C75430"},
                 {"label": "Pwene Municipality", "value": "C75431"},
                 {"label": "Ramanum Municipality", "value": "C75432"},
                 {"label": "Ruo Municipality", "value": "C75433"},
                 {"label": "Satowan Municipality", "value": "C75434"},
                 {"label": "Siis Municipality", "value": "C75435"},
                 {"label": "Ta Municipality", "value": "C75436"},
                 {"label": "Tamatam Municipality", "value": "C75437"},
                 {"label": "Tolensom Municipality", "value": "C75438"},
                 {"label": "Tonoas Municipality", "value": "C75439"},
                 {"label": "Udot-Fonuweisom Municipality", "value": "C75440"},
                 {"label": "Uman-Fonuweisom Municipality", "value": "C75441"},
                 {"label": "Unanu Municipality", "value": "C75442"},
                 {"label": "Weno", "value": "C75443"},
                 {"label": "Weno Municipality", "value": "C75444"},
                 {"label": "Wonei Municipality", "value": "C75445"}],
 "Chuvash Republic": [{"label": "Alatyr", "value": "C100840"},
                      {"label": "Alatyrskiy Rayon", "value": "C100841"},
                      {"label": "Alikovo", "value": "C100842"},
                      {"label": "Alikovskiy Rayon", "value": "C100843"},
                      {"label": "Atlashevo", "value": "C100844"},
                      {"label": "Batyrevo", "value": "C100845"},
                      {"label": "Batyrevskiy Rayon", "value": "C100846"},
                      {"label": "Buinsk", "value": "C100847"},
                      {"label": "Cheboksarskiy Rayon", "value": "C100848"},
                      {"label": "Cheboksary", "value": "C100849"},
                      {"label": "Ibresi", "value": "C100850"},
                      {"label": "Ibresinskiy Rayon", "value": "C100851"},
                      {"label": "Ishley", "value": "C100852"},
                      {"label": "Kanash", "value": "C100853"},
                      {"label": "Kanashskiy Rayon", "value": "C100854"},
                      {"label": "Kirya", "value": "C100855"},
                      {"label": "Komsomolskoye", "value": "C100856"},
                      {"label": "Komsomolskiy Rayon", "value": "C100857"},
                      {"label": "Kozlovka", "value": "C100858"},
                      {"label": "Kozlovskiy Rayon", "value": "C100859"},
                      {"label": "Krasnoarmeyskiy Rayon", "value": "C100860"},
                      {"label": "Krasnoarmeyskoye", "value": "C100861"},
                      {"label": "Krasnochetayskiy Rayon", "value": "C100862"},
                      {"label": "Krasnyye Chetai", "value": "C100863"},
                      {"label": "Kugesi", "value": "C100864"},
                      {"label": "Mariinskiy Posad", "value": "C100865"},
                      {"label": "Mariinsko-Posadskiy Rayon",
                       "value": "C100866"},
                      {"label": "Morgaushi", "value": "C100867"},
                      {"label": "Morgaushskiy Rayon", "value": "C100868"},
                      {"label": "Novocheboksarsk", "value": "C100869"},
                      {"label": "Novyye Lapsary", "value": "C100870"},
                      {"label": "Poretskiy Rayon", "value": "C100871"},
                      {"label": "Poretskoye", "value": "C100872"},
                      {"label": "Shemursha", "value": "C100873"},
                      {"label": "Shemurshinskiy Rayon", "value": "C100874"},
                      {"label": "Shikhazany", "value": "C100875"},
                      {"label": "Shumerlinskiy Rayon", "value": "C100876"},
                      {"label": "Shumerlya", "value": "C100877"},
                      {"label": "Tsivilsk", "value": "C100878"},
                      {"label": "Tsivilskiy Rayon", "value": "C100879"},
                      {"label": "Urmarskiy Rayon", "value": "C100880"},
                      {"label": "Urmary", "value": "C100881"},
                      {"label": "Vurnarskiy Rayon", "value": "C100882"},
                      {"label": "Vurnary", "value": "C100883"},
                      {"label": "Yadrin", "value": "C100884"},
                      {"label": "Yadrinskiy Rayon", "value": "C100885"},
                      {"label": "Yalchikskiy Rayon", "value": "C100886"},
                      {"label": "Yantikovo", "value": "C100887"},
                      {"label": "Yantikovskiy Rayon", "value": "C100888"}],
 "Chuy Region": [{"label": "Alamudunskiy Rayon", "value": "C64718"},
                 {"label": "Belovodskoye", "value": "C64719"},
                 {"label": "Chuyskiy Rayon", "value": "C64720"},
                 {"label": "Ivanovka", "value": "C64721"},
                 {"label": "Kaindy", "value": "C64722"},
                 {"label": "Kant", "value": "C64723"},
                 {"label": "Kara-Balta", "value": "C64724"},
                 {"label": "Kemin", "value": "C64725"},
                 {"label": "Lebedinovka", "value": "C64726"},
                 {"label": "Sokulukskiy Rayon", "value": "C64727"},
                 {"label": "Sosnovka", "value": "C64728"},
                 {"label": "Tokmok", "value": "C64729"},
                 {"label": "Ysyk-Ata", "value": "C64730"}],
 "Cibitoke Province": [{"label": "Cibitoke", "value": "C16446"}],
 "Cibla Municipality": [{"label": "Cibla", "value": "C64859"}],
 "Ciego de avila Province": [{"label": "Baragua", "value": "C21701"},
                             {"label": "Chambas", "value": "C21702"},
                             {"label": "Ciego de avila", "value": "C21703"},
                             {"label": "Ciro Redondo", "value": "C21704"},
                             {"label": "Florencia", "value": "C21705"},
                             {"label": "Moron", "value": "C21706"},
                             {"label": "Municipio de Ciego de avila",
                              "value": "C21707"},
                             {"label": "Municipio de Moron", "value": "C21708"},
                             {"label": "Primero de Enero", "value": "C21709"},
                             {"label": "Venezuela", "value": "C21710"}],
 "Cienfuegos Province": [{"label": "Abreus", "value": "C21711"},
                         {"label": "Aguada de Pasajeros", "value": "C21712"},
                         {"label": "Cienfuegos", "value": "C21713"},
                         {"label": "Cruces", "value": "C21714"},
                         {"label": "Cumanayagua", "value": "C21715"},
                         {"label": "Lajas", "value": "C21716"},
                         {"label": "Municipio de Abreus", "value": "C21717"},
                         {"label": "Municipio de Cienfuegos",
                          "value": "C21718"},
                         {"label": "Palmira", "value": "C21719"},
                         {"label": "Rodas", "value": "C21720"}],
 "Cimislia District": [{"label": "Cimislia", "value": "C75505"}],
 "City Municipality of Celje": [{"label": "Celje", "value": "C107599"},
                                {"label": "Ljubecna", "value": "C107600"},
                                {"label": "Trnovlje pri Celju",
                                 "value": "C107601"}],
 "City Municipality of Novo Mesto": [{"label": "Novo Mesto",
                                      "value": "C107602"}],
 "Ciudad de Mexico": [{"label": "alvaro Obregon", "value": "C67045"},
                      {"label": "Azcapotzalco", "value": "C67046"},
                      {"label": "Benito Juarez", "value": "C67047"},
                      {"label": "Ciudad de Mexico", "value": "C67048"},
                      {"label": "Col. Bosques de las Lomas", "value": "C67049"},
                      {"label": "Colonia del Valle", "value": "C67050"},
                      {"label": "Colonia Nativitas", "value": "C67051"},
                      {"label": "Coyoacan", "value": "C67052"},
                      {"label": "Cuauhtemoc", "value": "C67053"},
                      {"label": "Delegacion Cuajimalpa de Morelos",
                       "value": "C67054"},
                      {"label": "Gustavo A. Madero", "value": "C67055"},
                      {"label": "Gustavo Adolfo Madero", "value": "C67056"},
                      {"label": "Iztacalco", "value": "C67057"},
                      {"label": "Iztapalapa", "value": "C67058"},
                      {"label": "Lomas de Tepemecatl", "value": "C67059"},
                      {"label": "Magdalena Contreras", "value": "C67060"},
                      {"label": "Miguel Hidalgo", "value": "C67061"},
                      {"label": "Milpa Alta", "value": "C67062"},
                      {"label": "Polanco", "value": "C67063"},
                      {"label": "San Andres Mixquic", "value": "C67064"},
                      {"label": "San Antonio Tecomitl", "value": "C67065"},
                      {"label": "San Bartolome Xicomulco", "value": "C67066"},
                      {"label": "San Francisco Tecoxpa", "value": "C67067"},
                      {"label": "San Juan Ixtayopan", "value": "C67068"},
                      {"label": "San Lorenzo Acopilco", "value": "C67069"},
                      {"label": "San Lorenzo Tlacoyucan", "value": "C67070"},
                      {"label": "San Miguel Ajusco", "value": "C67071"},
                      {"label": "San Miguel Topilejo", "value": "C67072"},
                      {"label": "San Pablo Oztotepec", "value": "C67073"},
                      {"label": "San Pedro Atocpan", "value": "C67074"},
                      {"label": "San Salvador Cuauhtenco", "value": "C67075"},
                      {"label": "Santa Ana Tlacotenco", "value": "C67076"},
                      {"label": "Santa Catarina Yecahuizotl",
                       "value": "C67077"},
                      {"label": "Tetelco", "value": "C67078"},
                      {"label": "Tlahuac", "value": "C67079"},
                      {"label": "Tlalpan", "value": "C67080"},
                      {"label": "Venustiano Carranza", "value": "C67081"},
                      {"label": "Xochimilco", "value": "C67082"}],
 "Clarendon Parish": [{"label": "Aenon Town", "value": "C61684"},
                      {"label": "Alley", "value": "C61685"},
                      {"label": "Alston", "value": "C61686"},
                      {"label": "Ashley", "value": "C61687"},
                      {"label": "Banks", "value": "C61688"},
                      {"label": "Beckford Kraal", "value": "C61689"},
                      {"label": "Brandon Hill", "value": "C61690"},
                      {"label": "Brixton Hill", "value": "C61691"},
                      {"label": "Bucknor", "value": "C61692"},
                      {"label": "Bucks Common", "value": "C61693"},
                      {"label": "Bushy Park", "value": "C61694"},
                      {"label": "Chapelton", "value": "C61695"},
                      {"label": "Chateau", "value": "C61696"},
                      {"label": "Cockpit", "value": "C61697"},
                      {"label": "Coffee Piece", "value": "C61698"},
                      {"label": "Colonels Ridge", "value": "C61699"},
                      {"label": "Coxswain", "value": "C61700"},
                      {"label": "Crofts Hill", "value": "C61701"},
                      {"label": "Crooked River", "value": "C61702"},
                      {"label": "Cumberland", "value": "C61703"},
                      {"label": "Curatoe Hill", "value": "C61704"},
                      {"label": "Dawkins", "value": "C61705"},
                      {"label": "Effortville", "value": "C61706"},
                      {"label": "Four Paths", "value": "C61707"},
                      {"label": "Frankfield", "value": "C61708"},
                      {"label": "Freetown", "value": "C61709"},
                      {"label": "Gimme-me-bit", "value": "C61710"},
                      {"label": "Glenmuir", "value": "C61711"},
                      {"label": "Grantham", "value": "C61712"},
                      {"label": "Gravel Hill", "value": "C61713"},
                      {"label": "Hayes", "value": "C61714"},
                      {"label": "Hazard", "value": "C61715"},
                      {"label": "Inverness", "value": "C61716"},
                      {"label": "James Hill", "value": "C61717"},
                      {"label": "Johns Hall", "value": "C61718"},
                      {"label": "Kellits", "value": "C61719"},
                      {"label": "Limit", "value": "C61720"},
                      {"label": "Lionel Town", "value": "C61721"},
                      {"label": "Longville Park", "value": "C61722"},
                      {"label": "Longwood", "value": "C61723"},
                      {"label": "May Pen", "value": "C61724"},
                      {"label": "May Pen Proper", "value": "C61725"},
                      {"label": "Milk River", "value": "C61726"},
                      {"label": "Mineral Heights", "value": "C61727"},
                      {"label": "Mitchell Town", "value": "C61728"},
                      {"label": "Moores", "value": "C61729"},
                      {"label": "Morgans Forest", "value": "C61730"},
                      {"label": "Morgans Pass", "value": "C61731"},
                      {"label": "Mount Airy", "value": "C61732"},
                      {"label": "New Denbigh", "value": "C61733"},
                      {"label": "New Longsville", "value": "C61734"},
                      {"label": "Old Denbigh", "value": "C61735"},
                      {"label": "Orange Hill", "value": "C61736"},
                      {"label": "Osbourne Store", "value": "C61737"},
                      {"label": "Paisley", "value": "C61738"},
                      {"label": "Palmers Cross", "value": "C61739"},
                      {"label": "Part of Banana Ground", "value": "C61740"},
                      {"label": "Part of Douglas Castle", "value": "C61741"},
                      {"label": "Part of Kellits", "value": "C61742"},
                      {"label": "Peckham", "value": "C61743"},
                      {"label": "Pennants", "value": "C61744"},
                      {"label": "Pleasant Valley", "value": "C61745"},
                      {"label": "Portland Cottage", "value": "C61746"},
                      {"label": "Porus", "value": "C61747"},
                      {"label": "Race Course", "value": "C61748"},
                      {"label": "Richmond Park", "value": "C61749"},
                      {"label": "Ritchies", "value": "C61750"},
                      {"label": "Rock", "value": "C61751"},
                      {"label": "Rock River", "value": "C61752"},
                      {"label": "Rocky Point", "value": "C61753"},
                      {"label": "Salt River", "value": "C61754"},
                      {"label": "Sandy Bay", "value": "C61755"},
                      {"label": "Sanguinetti", "value": "C61756"},
                      {"label": "Sedgepond", "value": "C61757"},
                      {"label": "Silent Hill", "value": "C61758"},
                      {"label": "Smithville", "value": "C61759"},
                      {"label": "Spaldings", "value": "C61760"},
                      {"label": "Summerfield", "value": "C61761"},
                      {"label": "Thompson Town", "value": "C61762"},
                      {"label": "Tollgate", "value": "C61763"},
                      {"label": "Treadlight", "value": "C61764"},
                      {"label": "Trout Hall", "value": "C61765"},
                      {"label": "Turners", "value": "C61766"},
                      {"label": "Water Lane", "value": "C61767"},
                      {"label": "Woodhall", "value": "C61768"},
                      {"label": "York Town", "value": "C61769"}],
 "Cluj County": [{"label": "Aghiresu", "value": "C94324"},
                 {"label": "Aghiresu-Fabrici", "value": "C94325"},
                 {"label": "Aiton", "value": "C94326"},
                 {"label": "Alunis", "value": "C94327"},
                 {"label": "Apahida", "value": "C94328"},
                 {"label": "Aschileu Dorna", "value": "C94329"},
                 {"label": "Baciu", "value": "C94330"},
                 {"label": "Baisoara", "value": "C94331"},
                 {"label": "Belis", "value": "C94332"},
                 {"label": "Bobalna", "value": "C94333"},
                 {"label": "Bogdanesti", "value": "C94334"},
                 {"label": "Bontida", "value": "C94335"},
                 {"label": "Borsa", "value": "C94336"},
                 {"label": "Buza", "value": "C94337"},
                 {"label": "Caianu", "value": "C94338"},
                 {"label": "Caianu Mic", "value": "C94339"},
                 {"label": "Calarasi", "value": "C94340"},
                 {"label": "Calatele", "value": "C94341"},
                 {"label": "Camarasu", "value": "C94342"},
                 {"label": "Campia Turzii", "value": "C94343"},
                 {"label": "Capusu Mare", "value": "C94344"},
                 {"label": "Caseiu", "value": "C94345"},
                 {"label": "Catcau", "value": "C94346"},
                 {"label": "Catina", "value": "C94347"},
                 {"label": "Ceanu Mare", "value": "C94348"},
                 {"label": "Chinteni", "value": "C94349"},
                 {"label": "Chiuiesti", "value": "C94350"},
                 {"label": "Ciucea", "value": "C94351"},
                 {"label": "Ciurila", "value": "C94352"},
                 {"label": "Cluj-Napoca", "value": "C94353"},
                 {"label": "Cojocna", "value": "C94354"},
                 {"label": "Comuna Aghiresu", "value": "C94355"},
                 {"label": "Comuna Aiton", "value": "C94356"},
                 {"label": "Comuna Alunis", "value": "C94357"},
                 {"label": "Comuna Apahida", "value": "C94358"},
                 {"label": "Comuna Aschileu", "value": "C94359"},
                 {"label": "Comuna Baciu", "value": "C94360"},
                 {"label": "Comuna Baisoara", "value": "C94361"},
                 {"label": "Comuna Belis", "value": "C94362"},
                 {"label": "Comuna Bobalna", "value": "C94363"},
                 {"label": "Comuna Bontida", "value": "C94364"},
                 {"label": "Comuna Borsa", "value": "C94365"},
                 {"label": "Comuna Buza", "value": "C94366"},
                 {"label": "Comuna Caianu", "value": "C94367"},
                 {"label": "Comuna Calarasi", "value": "C94368"},
                 {"label": "Comuna Calatele", "value": "C94369"},
                 {"label": "Comuna Camarasu", "value": "C94370"},
                 {"label": "Comuna Capusu Mare", "value": "C94371"},
                 {"label": "Comuna Caseiu", "value": "C94372"},
                 {"label": "Comuna Catcau", "value": "C94373"},
                 {"label": "Comuna Catina", "value": "C94374"},
                 {"label": "Comuna Ceanu Mare", "value": "C94375"},
                 {"label": "Comuna Chinteni", "value": "C94376"},
                 {"label": "Comuna Chiuiesti", "value": "C94377"},
                 {"label": "Comuna Ciucea", "value": "C94378"},
                 {"label": "Comuna Ciurila", "value": "C94379"},
                 {"label": "Comuna Cojocna", "value": "C94380"},
                 {"label": "Comuna Cornesti", "value": "C94381"},
                 {"label": "Comuna Cuzdrioara", "value": "C94382"},
                 {"label": "Comuna Dabaca", "value": "C94383"},
                 {"label": "Comuna Feleacu", "value": "C94384"},
                 {"label": "Comuna Fizesu Gherlii", "value": "C94385"},
                 {"label": "Comuna Floresti", "value": "C94386"},
                 {"label": "Comuna Frata", "value": "C94387"},
                 {"label": "Comuna Garbau", "value": "C94388"},
                 {"label": "Comuna Geaca", "value": "C94389"},
                 {"label": "Comuna Gilau", "value": "C94390"},
                 {"label": "Comuna Iara", "value": "C94391"},
                 {"label": "Comuna Iclod", "value": "C94392"},
                 {"label": "Comuna Izvoru Crisului", "value": "C94393"},
                 {"label": "Comuna Jichisu De Jos", "value": "C94394"},
                 {"label": "Comuna Jucu", "value": "C94395"},
                 {"label": "Comuna Luna", "value": "C94396"},
                 {"label": "Comuna Maguri-Racatau", "value": "C94397"},
                 {"label": "Comuna Manastireni", "value": "C94398"},
                 {"label": "Comuna Margau", "value": "C94399"},
                 {"label": "Comuna Marisel", "value": "C94400"},
                 {"label": "Comuna Mica", "value": "C94401"},
                 {"label": "Comuna Mihai Viteazu", "value": "C94402"},
                 {"label": "Comuna Mintiu Gherlii", "value": "C94403"},
                 {"label": "Comuna Mociu", "value": "C94404"},
                 {"label": "Comuna Moldovenesti", "value": "C94405"},
                 {"label": "Comuna Negreni", "value": "C94406"},
                 {"label": "Comuna Palatca", "value": "C94407"},
                 {"label": "Comuna Panticeu", "value": "C94408"},
                 {"label": "Comuna Petrestii De Jos", "value": "C94409"},
                 {"label": "Comuna Ploscos", "value": "C94410"},
                 {"label": "Comuna Poieni", "value": "C94411"},
                 {"label": "Comuna Rasca", "value": "C94412"},
                 {"label": "Comuna Recea Cristur", "value": "C94413"},
                 {"label": "Comuna Sacuieu", "value": "C94414"},
                 {"label": "Comuna Sancraiu", "value": "C94415"},
                 {"label": "Comuna Sandulesti", "value": "C94416"},
                 {"label": "Comuna Sanmartin", "value": "C94417"},
                 {"label": "Comuna Sanpaul", "value": "C94418"},
                 {"label": "Comuna Savadisla", "value": "C94419"},
                 {"label": "Comuna Sic", "value": "C94420"},
                 {"label": "Comuna Suatu", "value": "C94421"},
                 {"label": "Comuna Taga", "value": "C94422"},
                 {"label": "Comuna Tritenii De Jos", "value": "C94423"},
                 {"label": "Comuna Tureni", "value": "C94424"},
                 {"label": "Comuna Unguras", "value": "C94425"},
                 {"label": "Comuna Vad", "value": "C94426"},
                 {"label": "Comuna Valea Ierii", "value": "C94427"},
                 {"label": "Comuna Viisoara", "value": "C94428"},
                 {"label": "Comuna Vultureni", "value": "C94429"},
                 {"label": "Copaceni", "value": "C94430"},
                 {"label": "Cornesti", "value": "C94431"},
                 {"label": "Cuzdrioara", "value": "C94432"},
                 {"label": "Dabaca", "value": "C94433"},
                 {"label": "Dej", "value": "C94434"},
                 {"label": "Dezmir", "value": "C94435"},
                 {"label": "Feleacu", "value": "C94436"},
                 {"label": "Fizesu Gherlii", "value": "C94437"},
                 {"label": "Floresti", "value": "C94438"},
                 {"label": "Frata", "value": "C94439"},
                 {"label": "Garbau", "value": "C94440"},
                 {"label": "Geaca", "value": "C94441"},
                 {"label": "Gheorghieni", "value": "C94442"},
                 {"label": "Gherla", "value": "C94443"},
                 {"label": "Gilau", "value": "C94444"},
                 {"label": "Huedin", "value": "C94445"},
                 {"label": "Iara", "value": "C94446"},
                 {"label": "Iclod", "value": "C94447"},
                 {"label": "Izvoru Crisului", "value": "C94448"},
                 {"label": "Jichisu de Jos", "value": "C94449"},
                 {"label": "Luna", "value": "C94450"},
                 {"label": "Luna de Sus", "value": "C94451"},
                 {"label": "Luncani", "value": "C94452"},
                 {"label": "Maguri", "value": "C94453"},
                 {"label": "Maguri-Racatau", "value": "C94454"},
                 {"label": "Manastireni", "value": "C94455"},
                 {"label": "Margau", "value": "C94456"},
                 {"label": "Marisel", "value": "C94457"},
                 {"label": "Mera", "value": "C94458"},
                 {"label": "Mica", "value": "C94459"},
                 {"label": "Mihai Viteazu", "value": "C94460"},
                 {"label": "Mintiu Gherlii", "value": "C94461"},
                 {"label": "Mociu", "value": "C94462"},
                 {"label": "Moldovenesti", "value": "C94463"},
                 {"label": "Municipiul Campia Turzii", "value": "C94464"},
                 {"label": "Municipiul Cluj-Napoca", "value": "C94465"},
                 {"label": "Municipiul Dej", "value": "C94466"},
                 {"label": "Municipiul Gherla", "value": "C94467"},
                 {"label": "Municipiul Turda", "value": "C94468"},
                 {"label": "Negreni", "value": "C94469"},
                 {"label": "Nires", "value": "C94470"},
                 {"label": "Ocna Dejului", "value": "C94471"},
                 {"label": "Oras Huedin", "value": "C94472"},
                 {"label": "Padureni", "value": "C94473"},
                 {"label": "Palatca", "value": "C94474"},
                 {"label": "Panticeu", "value": "C94475"},
                 {"label": "Petrestii de Jos", "value": "C94476"},
                 {"label": "Ploscos", "value": "C94477"},
                 {"label": "Poieni", "value": "C94478"},
                 {"label": "Rasca", "value": "C94479"},
                 {"label": "Rascruci", "value": "C94480"},
                 {"label": "Recea Cristur", "value": "C94481"},
                 {"label": "Rugasesti", "value": "C94482"},
                 {"label": "Sacuieu", "value": "C94483"},
                 {"label": "Sancraiu", "value": "C94484"},
                 {"label": "Sandulesti", "value": "C94485"},
                 {"label": "Sanmarghita", "value": "C94486"},
                 {"label": "Sanmartin", "value": "C94487"},
                 {"label": "Sannicoara", "value": "C94488"},
                 {"label": "Sanpaul", "value": "C94489"},
                 {"label": "Savadisla", "value": "C94490"},
                 {"label": "Sic", "value": "C94491"},
                 {"label": "Soporu de Campie", "value": "C94492"},
                 {"label": "Suatu", "value": "C94493"},
                 {"label": "Suceagu", "value": "C94494"},
                 {"label": "Taga", "value": "C94495"},
                 {"label": "Tritenii de Jos", "value": "C94496"},
                 {"label": "Tritenii de Sus", "value": "C94497"},
                 {"label": "Turda", "value": "C94498"},
                 {"label": "Tureni", "value": "C94499"},
                 {"label": "Unguras", "value": "C94500"},
                 {"label": "Urca", "value": "C94501"},
                 {"label": "Vad", "value": "C94502"},
                 {"label": "Vaida-Camaras", "value": "C94503"},
                 {"label": "Valea Ierii", "value": "C94504"},
                 {"label": "Viisoara", "value": "C94505"},
                 {"label": "Vultureni", "value": "C94506"}],
 "Coahuila de Zaragoza": [{"label": "Abasolo", "value": "C67083"},
                          {"label": "Agua Nueva", "value": "C67084"},
                          {"label": "Albia", "value": "C67085"},
                          {"label": "Alejo Gonzalez (Bilbao)",
                           "value": "C67086"},
                          {"label": "Arteaga", "value": "C67087"},
                          {"label": "Boquilla de las Perlas",
                           "value": "C67088"},
                          {"label": "Candela", "value": "C67089"},
                          {"label": "Castanos", "value": "C67090"},
                          {"label": "Chula Vista", "value": "C67091"},
                          {"label": "Ciudad Acuna", "value": "C67092"},
                          {"label": "Ciudad Melchor Muzquiz",
                           "value": "C67093"},
                          {"label": "Cloete", "value": "C67094"},
                          {"label": "Colonia Diana Laura Riojas de Colosio",
                           "value": "C67095"},
                          {"label": "Colonia Venustiano Carranza",
                           "value": "C67096"},
                          {"label": "Compuertas", "value": "C67097"},
                          {"label": "Concordia", "value": "C67098"},
                          {"label": "Coyote", "value": "C67099"},
                          {"label": "Cuatro Cienegas de Carranza",
                           "value": "C67100"},
                          {"label": "Ejido Venustiano Carranza",
                           "value": "C67101"},
                          {"label": "El Cambio", "value": "C67102"},
                          {"label": "El Consuelo", "value": "C67103"},
                          {"label": "El Cuije", "value": "C67104"},
                          {"label": "El Lequeitio", "value": "C67105"},
                          {"label": "El Nilo", "value": "C67106"},
                          {"label": "El Peru", "value": "C67107"},
                          {"label": "El Porvenir (El Porvenir de Arriba)",
                           "value": "C67108"},
                          {"label": "El Retiro", "value": "C67109"},
                          {"label": "Escuadron Doscientos Uno",
                           "value": "C67110"},
                          {"label": "Fraccionamiento la Noria",
                           "value": "C67111"},
                          {"label": "Frontera", "value": "C67112"},
                          {"label": "General Cepeda", "value": "C67113"},
                          {"label": "Gilita", "value": "C67114"},
                          {"label": "Granada", "value": "C67115"},
                          {"label": "Guerrero", "value": "C67116"},
                          {"label": "Hercules", "value": "C67117"},
                          {"label": "Hidalgo", "value": "C67118"},
                          {"label": "Hormiguero", "value": "C67119"},
                          {"label": "Huachichil", "value": "C67120"},
                          {"label": "Huitron", "value": "C67121"},
                          {"label": "Jaboncillo", "value": "C67122"},
                          {"label": "Jimenez", "value": "C67123"},
                          {"label": "La Concha", "value": "C67124"},
                          {"label": "La Cruz", "value": "C67125"},
                          {"label": "La Esmeralda", "value": "C67126"},
                          {"label": "La Esperanza", "value": "C67127"},
                          {"label": "La Fe", "value": "C67128"},
                          {"label": "La Florida", "value": "C67129"},
                          {"label": "La Luz", "value": "C67130"},
                          {"label": "La Palma", "value": "C67131"},
                          {"label": "La Partida", "value": "C67132"},
                          {"label": "La Paz", "value": "C67133"},
                          {"label": "La Perla", "value": "C67134"},
                          {"label": "La Pinta", "value": "C67135"},
                          {"label": "Laguna del Rey (Quimicas del Rey)",
                           "value": "C67136"},
                          {"label": "Lamadrid", "value": "C67137"},
                          {"label": "Las Mieleras", "value": "C67138"},
                          {"label": "Los alamos", "value": "C67139"},
                          {"label": "Luchanas", "value": "C67140"},
                          {"label": "Maravillas", "value": "C67141"},
                          {"label": "Matamoros", "value": "C67142"},
                          {"label": "Mayran", "value": "C67143"},
                          {"label": "Minas de Barroteran", "value": "C67144"},
                          {"label": "Monclova", "value": "C67145"},
                          {"label": "Nadadores", "value": "C67146"},
                          {"label": "Nava", "value": "C67147"},
                          {"label": "Nueva Rosita", "value": "C67148"},
                          {"label": "Nuevo Leon", "value": "C67149"},
                          {"label": "Ocampo", "value": "C67150"},
                          {"label": "Ocho de Enero", "value": "C67151"},
                          {"label": "Palau", "value": "C67152"},
                          {"label": "Paredon", "value": "C67153"},
                          {"label": "Parras de la Fuente", "value": "C67154"},
                          {"label": "Piedras Negras", "value": "C67155"},
                          {"label": "Primero de Mayo", "value": "C67156"},
                          {"label": "Progreso", "value": "C67157"},
                          {"label": "Purisima", "value": "C67158"},
                          {"label": "Ramos Arizpe", "value": "C67159"},
                          {"label": "Rancho Alegre", "value": "C67160"},
                          {"label": "Rio Bravo", "value": "C67161"},
                          {"label": "Sabinas", "value": "C67162"},
                          {"label": "Sacramento", "value": "C67163"},
                          {"label": "Saltillo", "value": "C67164"},
                          {"label": "San Antonio de las Alazanas",
                           "value": "C67165"},
                          {"label": "San Antonio del Coyote",
                           "value": "C67166"},
                          {"label": "San Buenaventura", "value": "C67167"},
                          {"label": "San Carlos", "value": "C67168"},
                          {"label": "San Esteban de Abajo", "value": "C67169"},
                          {"label": "San Francisco de Arriba",
                           "value": "C67170"},
                          {"label": "San Ignacio", "value": "C67171"},
                          {"label": "San Jose de Aura", "value": "C67172"},
                          {"label": "San Juan de la Vaqueria",
                           "value": "C67173"},
                          {"label": "San Juan de Sabinas", "value": "C67174"},
                          {"label": "San Lorenzo", "value": "C67175"},
                          {"label": "San Marcos", "value": "C67176"},
                          {"label": "San Miguel", "value": "C67177"},
                          {"label": "Santa Ana del Pilar", "value": "C67178"},
                          {"label": "Santa Fe", "value": "C67179"},
                          {"label": "Santo Nino Aguanaval", "value": "C67180"},
                          {"label": "Seis de Octubre (Santo Nino)",
                           "value": "C67181"},
                          {"label": "Sierra Mojada", "value": "C67182"},
                          {"label": "Solima", "value": "C67183"},
                          {"label": "Tacubaya", "value": "C67184"},
                          {"label": "Torreon", "value": "C67185"},
                          {"label": "Veinte de Noviembre", "value": "C67186"},
                          {"label": "Veinte de Noviembre (Santa Lucia)",
                           "value": "C67187"},
                          {"label": "Ventana", "value": "C67188"},
                          {"label": "Viesca", "value": "C67189"},
                          {"label": "Villa Las Esperanzas", "value": "C67190"},
                          {"label": "Villa Union", "value": "C67191"},
                          {"label": "Virginias", "value": "C67192"},
                          {"label": "Zaragoza", "value": "C67193"}],
 "Cochabamba Department": [{"label": "Aiquile", "value": "C9686"},
                           {"label": "Arani", "value": "C9687"},
                           {"label": "Bolivar", "value": "C9688"},
                           {"label": "Capinota", "value": "C9689"},
                           {"label": "Chimore", "value": "C9690"},
                           {"label": "Cliza", "value": "C9691"},
                           {"label": "Cochabamba", "value": "C9692"},
                           {"label": "Colchani", "value": "C9693"},
                           {"label": "Colomi", "value": "C9694"},
                           {"label": "Independencia", "value": "C9695"},
                           {"label": "Irpa Irpa", "value": "C9696"},
                           {"label": "Mizque", "value": "C9697"},
                           {"label": "Provincia Arani", "value": "C9698"},
                           {"label": "Provincia Arque", "value": "C9699"},
                           {"label": "Provincia Ayopaya", "value": "C9700"},
                           {"label": "Provincia Campero", "value": "C9701"},
                           {"label": "Provincia Capinota", "value": "C9702"},
                           {"label": "Provincia Carrasco", "value": "C9703"},
                           {"label": "Provincia Cercado", "value": "C9704"},
                           {"label": "Provincia Chapare", "value": "C9705"},
                           {"label": "Provincia Esteban Arce",
                            "value": "C9706"},
                           {"label": "Provincia German Jordan",
                            "value": "C9707"},
                           {"label": "Provincia Mizque", "value": "C9708"},
                           {"label": "Provincia Punata", "value": "C9709"},
                           {"label": "Provincia Quillacollo", "value": "C9710"},
                           {"label": "Provincia Tapacari", "value": "C9711"},
                           {"label": "Punata", "value": "C9712"},
                           {"label": "Quillacollo", "value": "C9713"},
                           {"label": "Sacaba", "value": "C9714"},
                           {"label": "Sipe Sipe", "value": "C9715"},
                           {"label": "Tarata", "value": "C9716"},
                           {"label": "Tiquipaya", "value": "C9717"},
                           {"label": "Tiraque Province", "value": "C9718"},
                           {"label": "Totora", "value": "C9719"}],
 "Cocle Province": [{"label": "Aguadulce", "value": "C79917"},
                    {"label": "Aguas Blancas", "value": "C79918"},
                    {"label": "Alto de La Estancia", "value": "C79919"},
                    {"label": "Anton", "value": "C79920"},
                    {"label": "Barranco Colorado", "value": "C79921"},
                    {"label": "Caballero", "value": "C79922"},
                    {"label": "Cabuya", "value": "C79923"},
                    {"label": "Caimito", "value": "C79924"},
                    {"label": "Canaveral", "value": "C79925"},
                    {"label": "Capellania", "value": "C79926"},
                    {"label": "Chigore", "value": "C79927"},
                    {"label": "Chiguiri Arriba", "value": "C79928"},
                    {"label": "Churuquita Chiquita", "value": "C79929"},
                    {"label": "Churuquita Grande", "value": "C79930"},
                    {"label": "Cocle", "value": "C79931"},
                    {"label": "Distrito de Aguadulce", "value": "C79932"},
                    {"label": "Distrito de Anton", "value": "C79933"},
                    {"label": "Distrito de La Pintada", "value": "C79934"},
                    {"label": "Distrito de Nata", "value": "C79935"},
                    {"label": "Distrito de Ola", "value": "C79936"},
                    {"label": "Distrito Penonome", "value": "C79937"},
                    {"label": "El Cano", "value": "C79938"},
                    {"label": "El Chiru", "value": "C79939"},
                    {"label": "El Coco", "value": "C79940"},
                    {"label": "El Cope", "value": "C79941"},
                    {"label": "El Cortezo", "value": "C79942"},
                    {"label": "El Cristo", "value": "C79943"},
                    {"label": "El Potrero", "value": "C79944"},
                    {"label": "El Retiro", "value": "C79945"},
                    {"label": "El Roble", "value": "C79946"},
                    {"label": "Entradero", "value": "C79947"},
                    {"label": "Farallon", "value": "C79948"},
                    {"label": "Guzman", "value": "C79949"},
                    {"label": "Juan Diaz", "value": "C79950"},
                    {"label": "La Loma", "value": "C79951"},
                    {"label": "La Pintada", "value": "C79952"},
                    {"label": "Las Huacas del Quije", "value": "C79953"},
                    {"label": "Las Marias", "value": "C79954"},
                    {"label": "Las Minas", "value": "C79955"},
                    {"label": "Las Sabanas", "value": "C79956"},
                    {"label": "Llano Grande", "value": "C79957"},
                    {"label": "Llano Marin", "value": "C79958"},
                    {"label": "Los Pollos", "value": "C79959"},
                    {"label": "Nata", "value": "C79960"},
                    {"label": "Nuestro Amo", "value": "C79961"},
                    {"label": "Ola", "value": "C79962"},
                    {"label": "Penonome", "value": "C79963"},
                    {"label": "Piedras Gordas", "value": "C79964"},
                    {"label": "Pocri", "value": "C79965"},
                    {"label": "Rio Grande", "value": "C79966"},
                    {"label": "Rio Hato", "value": "C79967"},
                    {"label": "San Juan de Dios", "value": "C79968"},
                    {"label": "Toabre", "value": "C79969"},
                    {"label": "Toza", "value": "C79970"},
                    {"label": "Vista Hermosa", "value": "C79971"}],
 "Coimbra": [{"label": "Alfarelos", "value": "C90996"},
             {"label": "Alhadas", "value": "C90997"},
             {"label": "Anca", "value": "C90998"},
             {"label": "Arazede", "value": "C90999"},
             {"label": "Arganil", "value": "C91000"},
             {"label": "Assafarge", "value": "C91001"},
             {"label": "Buarcos", "value": "C91002"},
             {"label": "Cantanhede", "value": "C91003"},
             {"label": "Carapinheira", "value": "C91004"},
             {"label": "Coimbra", "value": "C91005"},
             {"label": "Condeixa-a-Nova", "value": "C91006"},
             {"label": "Figueira da Foz", "value": "C91007"},
             {"label": "Gois", "value": "C91008"},
             {"label": "Lavos", "value": "C91009"},
             {"label": "Lorvao", "value": "C91010"},
             {"label": "Lousa", "value": "C91011"},
             {"label": "Mira", "value": "C91012"},
             {"label": "Miranda do Corvo", "value": "C91013"},
             {"label": "Montemor-o-Velho", "value": "C91014"},
             {"label": "Oliveira do Hospital", "value": "C91015"},
             {"label": "Oliveira do Mondego", "value": "C91016"},
             {"label": "Pampilhosa da Serra", "value": "C91017"},
             {"label": "Penacova", "value": "C91018"},
             {"label": "Penela", "value": "C91019"},
             {"label": "Poiares", "value": "C91020"},
             {"label": "Santa Clara", "value": "C91021"},
             {"label": "Santo Antonio dos Olivais", "value": "C91022"},
             {"label": "Sao Pedro de Alva", "value": "C91023"},
             {"label": "Soure", "value": "C91024"},
             {"label": "Tabua", "value": "C91025"},
             {"label": "Tavarede", "value": "C91026"},
             {"label": "Tocha", "value": "C91027"},
             {"label": "Travanca", "value": "C91028"},
             {"label": "Vila Nova de Poiares", "value": "C91029"}],
 "Cojedes": [{"label": "San Carlos", "value": "C147168"},
             {"label": "Tinaquillo", "value": "C147169"}],
 "Colima": [{"label": "Alcaraces", "value": "C67194"},
            {"label": "Armeria", "value": "C67195"},
            {"label": "Augusto Gomez Villanueva", "value": "C67196"},
            {"label": "Caleras", "value": "C67197"},
            {"label": "Camotlan de Miraflores", "value": "C67198"},
            {"label": "Cerro de Ortega", "value": "C67199"},
            {"label": "Ciudad de Armeria", "value": "C67200"},
            {"label": "Ciudad de Villa de alvarez", "value": "C67201"},
            {"label": "Cofradia", "value": "C67202"},
            {"label": "Cofradia de Juarez", "value": "C67203"},
            {"label": "Cofradia de Morelos", "value": "C67204"},
            {"label": "Cofradia de Suchitlan", "value": "C67205"},
            {"label": "Comala", "value": "C67206"},
            {"label": "Coquimatlan", "value": "C67207"},
            {"label": "Cuauhtemoc", "value": "C67208"},
            {"label": "Cuyutlan", "value": "C67209"},
            {"label": "El Colomo", "value": "C67210"},
            {"label": "El Naranjo", "value": "C67211"},
            {"label": "El Trapiche", "value": "C67212"},
            {"label": "Ixtlahuacan", "value": "C67213"},
            {"label": "Jalipa", "value": "C67214"},
            {"label": "La Central", "value": "C67215"},
            {"label": "Los Tepames", "value": "C67216"},
            {"label": "Madrid", "value": "C67217"},
            {"label": "Minatitlan", "value": "C67218"},
            {"label": "Piscila", "value": "C67219"},
            {"label": "Pueblo Juarez (La Magdalena)", "value": "C67220"},
            {"label": "Queseria", "value": "C67221"},
            {"label": "Rincon de Lopez", "value": "C67222"},
            {"label": "Suchitlan", "value": "C67223"},
            {"label": "Tecoman", "value": "C67224"},
            {"label": "Venustiano Carranza", "value": "C67225"},
            {"label": "Zacualpan", "value": "C67226"}],
 "Collines Department": [{"label": "Come", "value": "C9610"},
                         {"label": "Dassa-Zoume", "value": "C9611"},
                         {"label": "Savalou", "value": "C9612"},
                         {"label": "Save", "value": "C9613"}],
 "Colombo District": [{"label": "Dehiwala-Mount Lavinia", "value": "C115252"},
                      {"label": "Homagama", "value": "C115253"},
                      {"label": "Kaduwela", "value": "C115254"},
                      {"label": "Kesbewa", "value": "C115255"},
                      {"label": "Kolonnawa", "value": "C115256"},
                      {"label": "Kotte", "value": "C115257"},
                      {"label": "Maharagama", "value": "C115258"},
                      {"label": "Moratuwa", "value": "C115259"},
                      {"label": "Padukka", "value": "C115260"},
                      {"label": "Ratmalana", "value": "C115261"},
                      {"label": "Seethawaka", "value": "C115262"},
                      {"label": "Thimbirigasyaya", "value": "C115263"}],
 "Colon Department": [{"label": "Balfate", "value": "C43256"},
                      {"label": "Bonito Oriental", "value": "C43257"},
                      {"label": "Corocito", "value": "C43258"},
                      {"label": "Cusuna", "value": "C43259"},
                      {"label": "Elixir", "value": "C43260"},
                      {"label": "Francia", "value": "C43261"},
                      {"label": "Iriona", "value": "C43262"},
                      {"label": "Jerico", "value": "C43263"},
                      {"label": "La Brea", "value": "C43264"},
                      {"label": "La Esperanza", "value": "C43265"},
                      {"label": "Limon", "value": "C43266"},
                      {"label": "Municipio de Saba", "value": "C43267"},
                      {"label": "Prieta", "value": "C43268"},
                      {"label": "Puerto Castilla", "value": "C43269"},
                      {"label": "Punta Piedra", "value": "C43270"},
                      {"label": "Quebrada de Arena", "value": "C43271"},
                      {"label": "Rio Esteban", "value": "C43272"},
                      {"label": "Saba", "value": "C43273"},
                      {"label": "Salama", "value": "C43274"},
                      {"label": "Santa Fe", "value": "C43275"},
                      {"label": "Santa Rosa de Aguan", "value": "C43276"},
                      {"label": "Sonaguera", "value": "C43277"},
                      {"label": "Taujica", "value": "C43278"},
                      {"label": "Tocoa", "value": "C43279"},
                      {"label": "Trujillo", "value": "C43280"},
                      {"label": "Zamora", "value": "C43281"}],
 "Colon Province": [{"label": "Buena Vista", "value": "C79972"},
                    {"label": "Cativa", "value": "C79973"},
                    {"label": "Cocle del Norte", "value": "C79974"},
                    {"label": "Colon", "value": "C79975"},
                    {"label": "Cristobal", "value": "C79976"},
                    {"label": "Distrito de Chagres", "value": "C79977"},
                    {"label": "Distrito de Colon", "value": "C79978"},
                    {"label": "Distrito de Donoso", "value": "C79979"},
                    {"label": "Distrito de Portobelo", "value": "C79980"},
                    {"label": "Distrito de Santa Isabel", "value": "C79981"},
                    {"label": "El Giral", "value": "C79982"},
                    {"label": "El Guabo", "value": "C79983"},
                    {"label": "El Valle de la Union", "value": "C79984"},
                    {"label": "Escobal", "value": "C79985"},
                    {"label": "Gatun", "value": "C79986"},
                    {"label": "Gatuncillo", "value": "C79987"},
                    {"label": "Las Margaritas", "value": "C79988"},
                    {"label": "Maria Chiquita", "value": "C79989"},
                    {"label": "Miramar", "value": "C79990"},
                    {"label": "Nombre de Dios", "value": "C79991"},
                    {"label": "Nuevo Chagres", "value": "C79992"},
                    {"label": "Nuevo San Juan", "value": "C79993"},
                    {"label": "Nuevo Vigia", "value": "C79994"},
                    {"label": "Palenque", "value": "C79995"},
                    {"label": "Palmas Bellas", "value": "C79996"},
                    {"label": "Palmira", "value": "C79997"},
                    {"label": "Playa Chiquita", "value": "C79998"},
                    {"label": "Portobelo", "value": "C79999"},
                    {"label": "Puerto Escondido", "value": "C80000"},
                    {"label": "Puerto Pilon", "value": "C80001"},
                    {"label": "Quebrada Bonita Adentro", "value": "C80002"},
                    {"label": "Rio Alejandro", "value": "C80003"},
                    {"label": "Rio Duque", "value": "C80004"},
                    {"label": "Rio Rita", "value": "C80005"},
                    {"label": "Sabanitas", "value": "C80006"},
                    {"label": "Santa Isabel", "value": "C80007"},
                    {"label": "Santa Rita Arriba", "value": "C80008"},
                    {"label": "Viento Frio", "value": "C80009"}],
 "Colonia Department": [{"label": "Carmelo", "value": "C146865"},
                        {"label": "Colonia del Sacramento", "value": "C146866"},
                        {"label": "Florencio Sanchez", "value": "C146867"},
                        {"label": "Juan L. Lacaze", "value": "C146868"},
                        {"label": "Nueva Helvecia", "value": "C146869"},
                        {"label": "Nueva Palmira", "value": "C146870"},
                        {"label": "Ombues de Lavalle", "value": "C146871"},
                        {"label": "Rosario", "value": "C146872"},
                        {"label": "Tarariras", "value": "C146873"}],
 "Colorado": [{"label": "Acres Green", "value": "C129115"},
              {"label": "Adams County", "value": "C129116"},
              {"label": "Air Force Academy", "value": "C129117"},
              {"label": "Akron", "value": "C129118"},
              {"label": "Alamosa", "value": "C129119"},
              {"label": "Alamosa County", "value": "C129120"},
              {"label": "Alamosa East", "value": "C129121"},
              {"label": "Applewood", "value": "C129122"},
              {"label": "Arapahoe County", "value": "C129123"},
              {"label": "Archuleta County", "value": "C129124"},
              {"label": "Aristocrat Ranchettes", "value": "C129125"},
              {"label": "Arvada", "value": "C129126"},
              {"label": "Aspen", "value": "C129127"},
              {"label": "Ault", "value": "C129128"},
              {"label": "Aurora", "value": "C129129"},
              {"label": "Avon", "value": "C129130"},
              {"label": "Baca County", "value": "C129131"},
              {"label": "Basalt", "value": "C129132"},
              {"label": "Battlement Mesa", "value": "C129133"},
              {"label": "Bayfield", "value": "C129134"},
              {"label": "Bennett", "value": "C129135"},
              {"label": "Bent County", "value": "C129136"},
              {"label": "Berkley", "value": "C129137"},
              {"label": "Berthoud", "value": "C129138"},
              {"label": "Black Forest", "value": "C129139"},
              {"label": "Boulder", "value": "C129140"},
              {"label": "Boulder County", "value": "C129141"},
              {"label": "Breckenridge", "value": "C129142"},
              {"label": "Brighton", "value": "C129143"},
              {"label": "Broomfield", "value": "C129144"},
              {"label": "Broomfield County", "value": "C129145"},
              {"label": "Brush", "value": "C129146"},
              {"label": "Buena Vista", "value": "C129147"},
              {"label": "Burlington", "value": "C129148"},
              {"label": "Byers", "value": "C129149"},
              {"label": "Campion", "value": "C129150"},
              {"label": "Canon City", "value": "C129151"},
              {"label": "Carbondale", "value": "C129152"},
              {"label": "Carriage Club", "value": "C129153"},
              {"label": "Cascade-Chipita Park", "value": "C129154"},
              {"label": "Castle Pines", "value": "C129155"},
              {"label": "Castle Pines North", "value": "C129156"},
              {"label": "Castle Rock", "value": "C129157"},
              {"label": "Castlewood", "value": "C129158"},
              {"label": "Cedaredge", "value": "C129159"},
              {"label": "Centennial", "value": "C129160"},
              {"label": "Center", "value": "C129161"},
              {"label": "Central City", "value": "C129162"},
              {"label": "Chaffee County", "value": "C129163"},
              {"label": "Cherry Creek", "value": "C129164"},
              {"label": "Cherry Hills Village", "value": "C129165"},
              {"label": "Cheyenne County", "value": "C129166"},
              {"label": "Cheyenne Wells", "value": "C129167"},
              {"label": "Cimarron Hills", "value": "C129168"},
              {"label": "Clear Creek County", "value": "C129169"},
              {"label": "Clifton", "value": "C129170"},
              {"label": "Coal Creek", "value": "C129171"},
              {"label": "Colorado City", "value": "C129172"},
              {"label": "Colorado Springs", "value": "C129173"},
              {"label": "Columbine", "value": "C129174"},
              {"label": "Columbine Valley", "value": "C129175"},
              {"label": "Commerce City", "value": "C129176"},
              {"label": "Conejos", "value": "C129177"},
              {"label": "Conejos County", "value": "C129178"},
              {"label": "Cortez", "value": "C129179"},
              {"label": "Costilla County", "value": "C129180"},
              {"label": "Craig", "value": "C129181"},
              {"label": "Creede", "value": "C129182"},
              {"label": "Crested Butte", "value": "C129183"},
              {"label": "Cripple Creek", "value": "C129184"},
              {"label": "Crowley County", "value": "C129185"},
              {"label": "Custer County", "value": "C129186"},
              {"label": "Dacono", "value": "C129187"},
              {"label": "Del Norte", "value": "C129188"},
              {"label": "Delta", "value": "C129189"},
              {"label": "Delta County", "value": "C129190"},
              {"label": "Denver", "value": "C129191"},
              {"label": "Denver County", "value": "C129192"},
              {"label": "Derby", "value": "C129193"},
              {"label": "Dolores County", "value": "C129194"},
              {"label": "Douglas County", "value": "C129195"},
              {"label": "Dove Creek", "value": "C129196"},
              {"label": "Dove Valley", "value": "C129197"},
              {"label": "Durango", "value": "C129198"},
              {"label": "Eads", "value": "C129199"},
              {"label": "Eagle", "value": "C129200"},
              {"label": "Eagle County", "value": "C129201"},
              {"label": "Eaton", "value": "C129202"},
              {"label": "Edgewater", "value": "C129203"},
              {"label": "Edwards", "value": "C129204"},
              {"label": "El Jebel", "value": "C129205"},
              {"label": "El Paso County", "value": "C129206"},
              {"label": "Elbert County", "value": "C129207"},
              {"label": "Elizabeth", "value": "C129208"},
              {"label": "Ellicott", "value": "C129209"},
              {"label": "Englewood", "value": "C129210"},
              {"label": "Erie", "value": "C129211"},
              {"label": "Estes Park", "value": "C129212"},
              {"label": "Evans", "value": "C129213"},
              {"label": "Evergreen", "value": "C129214"},
              {"label": "Fairplay", "value": "C129215"},
              {"label": "Federal Heights", "value": "C129216"},
              {"label": "Firestone", "value": "C129217"},
              {"label": "Florence", "value": "C129218"},
              {"label": "Fort Carson", "value": "C129219"},
              {"label": "Fort Collins", "value": "C129220"},
              {"label": "Fort Lupton", "value": "C129221"},
              {"label": "Fort Morgan", "value": "C129222"},
              {"label": "Fountain", "value": "C129223"},
              {"label": "Fowler", "value": "C129224"},
              {"label": "Fraser", "value": "C129225"},
              {"label": "Frederick", "value": "C129226"},
              {"label": "Fremont County", "value": "C129227"},
              {"label": "Frisco", "value": "C129228"},
              {"label": "Fruita", "value": "C129229"},
              {"label": "Fruitvale", "value": "C129230"},
              {"label": "Garfield County", "value": "C129231"},
              {"label": "Genesee", "value": "C129232"},
              {"label": "Georgetown", "value": "C129233"},
              {"label": "Gilcrest", "value": "C129234"},
              {"label": "Gilpin County", "value": "C129235"},
              {"label": "Glendale", "value": "C129236"},
              {"label": "Gleneagle", "value": "C129237"},
              {"label": "Glenwood Springs", "value": "C129238"},
              {"label": "Golden", "value": "C129239"},
              {"label": "Granby", "value": "C129240"},
              {"label": "Grand County", "value": "C129241"},
              {"label": "Grand Junction", "value": "C129242"},
              {"label": "Greeley", "value": "C129243"},
              {"label": "Greenwood Village", "value": "C129244"},
              {"label": "Gunbarrel", "value": "C129245"},
              {"label": "Gunnison", "value": "C129246"},
              {"label": "Gunnison County", "value": "C129247"},
              {"label": "Gypsum", "value": "C129248"},
              {"label": "Hayden", "value": "C129249"},
              {"label": "Highlands Ranch", "value": "C129250"},
              {"label": "Hinsdale County", "value": "C129251"},
              {"label": "Holly Hills", "value": "C129252"},
              {"label": "Holyoke", "value": "C129253"},
              {"label": "Hot Sulphur Springs", "value": "C129254"},
              {"label": "Hudson", "value": "C129255"},
              {"label": "Huerfano County", "value": "C129256"},
              {"label": "Hugo", "value": "C129257"},
              {"label": "Idaho Springs", "value": "C129258"},
              {"label": "Indian Hills", "value": "C129259"},
              {"label": "Inverness", "value": "C129260"},
              {"label": "Jackson County", "value": "C129261"},
              {"label": "Jefferson County", "value": "C129262"},
              {"label": "Johnstown", "value": "C129263"},
              {"label": "Julesburg", "value": "C129264"},
              {"label": "Keenesburg", "value": "C129265"},
              {"label": "Ken Caryl", "value": "C129266"},
              {"label": "Kersey", "value": "C129267"},
              {"label": "Keystone", "value": "C129268"},
              {"label": "Kiowa", "value": "C129269"},
              {"label": "Kiowa County", "value": "C129270"},
              {"label": "Kit Carson County", "value": "C129271"},
              {"label": "Kittredge", "value": "C129272"},
              {"label": "Kremmling", "value": "C129273"},
              {"label": "La Junta", "value": "C129274"},
              {"label": "La Plata County", "value": "C129275"},
              {"label": "La Salle", "value": "C129276"},
              {"label": "Lafayette", "value": "C129277"},
              {"label": "Lake City", "value": "C129278"},
              {"label": "Lake County", "value": "C129279"},
              {"label": "Lakewood", "value": "C129280"},
              {"label": "Lamar", "value": "C129281"},
              {"label": "Laporte", "value": "C129282"},
              {"label": "Larimer County", "value": "C129283"},
              {"label": "Las Animas", "value": "C129284"},
              {"label": "Las Animas County", "value": "C129285"},
              {"label": "Leadville", "value": "C129286"},
              {"label": "Leadville North", "value": "C129287"},
              {"label": "Limon", "value": "C129288"},
              {"label": "Lincoln County", "value": "C129289"},
              {"label": "Lincoln Park", "value": "C129290"},
              {"label": "Littleton", "value": "C129291"},
              {"label": "Lochbuie", "value": "C129292"},
              {"label": "Logan County", "value": "C129293"},
              {"label": "Loma", "value": "C129294"},
              {"label": "Lone Tree", "value": "C129295"},
              {"label": "Longmont", "value": "C129296"},
              {"label": "Louisville", "value": "C129297"},
              {"label": "Loveland", "value": "C129298"},
              {"label": "Lyons", "value": "C129299"},
              {"label": "Mancos", "value": "C129300"},
              {"label": "Manitou Springs", "value": "C129301"},
              {"label": "Mead", "value": "C129302"},
              {"label": "Meeker", "value": "C129303"},
              {"label": "Meridian", "value": "C129304"},
              {"label": "Mesa County", "value": "C129305"},
              {"label": "Milliken", "value": "C129306"},
              {"label": "Mineral County", "value": "C129307"},
              {"label": "Minturn", "value": "C129308"},
              {"label": "Moffat County", "value": "C129309"},
              {"label": "Monte Vista", "value": "C129310"},
              {"label": "Montezuma County", "value": "C129311"},
              {"label": "Montrose", "value": "C129312"},
              {"label": "Montrose County", "value": "C129313"},
              {"label": "Monument", "value": "C129314"},
              {"label": "Morgan County", "value": "C129315"},
              {"label": "Mountain Village", "value": "C129316"},
              {"label": "Nederland", "value": "C129317"},
              {"label": "New Castle", "value": "C129318"},
              {"label": "Niwot", "value": "C129319"},
              {"label": "Northglenn", "value": "C129320"},
              {"label": "Olathe", "value": "C129321"},
              {"label": "Orchard City", "value": "C129322"},
              {"label": "Orchard Mesa", "value": "C129323"},
              {"label": "Ordway", "value": "C129324"},
              {"label": "Otero County", "value": "C129325"},
              {"label": "Ouray", "value": "C129326"},
              {"label": "Ouray County", "value": "C129327"},
              {"label": "Pagosa Springs", "value": "C129328"},
              {"label": "Palisade", "value": "C129329"},
              {"label": "Palmer Lake", "value": "C129330"},
              {"label": "Paonia", "value": "C129331"},
              {"label": "Parachute", "value": "C129332"},
              {"label": "Park County", "value": "C129333"},
              {"label": "Parker", "value": "C129334"},
              {"label": "Penrose", "value": "C129335"},
              {"label": "Perry Park", "value": "C129336"},
              {"label": "Phillips County", "value": "C129337"},
              {"label": "Pitkin County", "value": "C129338"},
              {"label": "Platteville", "value": "C129339"},
              {"label": "Ponderosa Park", "value": "C129340"},
              {"label": "Prowers County", "value": "C129341"},
              {"label": "Pueblo", "value": "C129342"},
              {"label": "Pueblo County", "value": "C129343"},
              {"label": "Pueblo West", "value": "C129344"},
              {"label": "Rangely", "value": "C129345"},
              {"label": "Redlands", "value": "C129346"},
              {"label": "Rifle", "value": "C129347"},
              {"label": "Rio Blanco County", "value": "C129348"},
              {"label": "Rio Grande County", "value": "C129349"},
              {"label": "Rocky Ford", "value": "C129350"},
              {"label": "Routt County", "value": "C129351"},
              {"label": "Roxborough Park", "value": "C129352"},
              {"label": "Saguache", "value": "C129353"},
              {"label": "Saguache County", "value": "C129354"},
              {"label": "Salida", "value": "C129355"},
              {"label": "San Juan County", "value": "C129356"},
              {"label": "San Luis", "value": "C129357"},
              {"label": "San Miguel County", "value": "C129358"},
              {"label": "Security-Widefield", "value": "C129359"},
              {"label": "Sedgwick County", "value": "C129360"},
              {"label": "Severance", "value": "C129361"},
              {"label": "Shaw Heights", "value": "C129362"},
              {"label": "Sheridan", "value": "C129363"},
              {"label": "Sherrelwood", "value": "C129364"},
              {"label": "Silt", "value": "C129365"},
              {"label": "Silverthorne", "value": "C129366"},
              {"label": "Silverton", "value": "C129367"},
              {"label": "Snowmass Village", "value": "C129368"},
              {"label": "Southglenn", "value": "C129369"},
              {"label": "Springfield", "value": "C129370"},
              {"label": "Steamboat Springs", "value": "C129371"},
              {"label": "Sterling", "value": "C129372"},
              {"label": "Stonegate", "value": "C129373"},
              {"label": "Strasburg", "value": "C129374"},
              {"label": "Stratmoor", "value": "C129375"},
              {"label": "Summit County", "value": "C129376"},
              {"label": "Superior", "value": "C129377"},
              {"label": "Teller County", "value": "C129378"},
              {"label": "Telluride", "value": "C129379"},
              {"label": "The Pinery", "value": "C129380"},
              {"label": "Thornton", "value": "C129381"},
              {"label": "Todd Creek", "value": "C129382"},
              {"label": "Towaoc", "value": "C129383"},
              {"label": "Trinidad", "value": "C129384"},
              {"label": "Twin Lakes", "value": "C129385"},
              {"label": "Upper Bear Creek", "value": "C129386"},
              {"label": "Vail", "value": "C129387"},
              {"label": "Walden", "value": "C129388"},
              {"label": "Walsenburg", "value": "C129389"},
              {"label": "Washington County", "value": "C129390"},
              {"label": "Welby", "value": "C129391"},
              {"label": "Weld County", "value": "C129392"},
              {"label": "Wellington", "value": "C129393"},
              {"label": "West Pleasant View", "value": "C129394"},
              {"label": "Westcliffe", "value": "C129395"},
              {"label": "Westminster", "value": "C129396"},
              {"label": "Wheat Ridge", "value": "C129397"},
              {"label": "Windsor", "value": "C129398"},
              {"label": "Woodland Park", "value": "C129399"},
              {"label": "Woodmoor", "value": "C129400"},
              {"label": "Wray", "value": "C129401"},
              {"label": "Yuma", "value": "C129402"},
              {"label": "Yuma County", "value": "C129403"}],
 "Comayagua Department": [{"label": "Aguas del Padre", "value": "C43282"},
                          {"label": "Ajuterique", "value": "C43283"},
                          {"label": "Cerro Blanco", "value": "C43284"},
                          {"label": "Comayagua", "value": "C43285"},
                          {"label": "Concepcion de Guasistagua",
                           "value": "C43286"},
                          {"label": "El Agua Dulcita", "value": "C43287"},
                          {"label": "El Porvenir", "value": "C43288"},
                          {"label": "El Rancho", "value": "C43289"},
                          {"label": "El Rincon", "value": "C43290"},
                          {"label": "El Rosario", "value": "C43291"},
                          {"label": "El Sauce", "value": "C43292"},
                          {"label": "El Socorro", "value": "C43293"},
                          {"label": "Esquias", "value": "C43294"},
                          {"label": "Flores", "value": "C43295"},
                          {"label": "Humuya", "value": "C43296"},
                          {"label": "Jamalteca", "value": "C43297"},
                          {"label": "La Libertad", "value": "C43298"},
                          {"label": "La Trinidad", "value": "C43299"},
                          {"label": "Lamani", "value": "C43300"},
                          {"label": "Las Lajas", "value": "C43301"},
                          {"label": "Lejamani", "value": "C43302"},
                          {"label": "Meambar", "value": "C43303"},
                          {"label": "Minas de Oro", "value": "C43304"},
                          {"label": "Ojos de Agua", "value": "C43305"},
                          {"label": "Potrerillos", "value": "C43306"},
                          {"label": "Rio Bonito", "value": "C43307"},
                          {"label": "San Antonio de la Cuesta",
                           "value": "C43308"},
                          {"label": "San Jeronimo", "value": "C43309"},
                          {"label": "San Jose de Comayagua", "value": "C43310"},
                          {"label": "San Jose del Potrero", "value": "C43311"},
                          {"label": "San Luis", "value": "C43312"},
                          {"label": "San Sebastian", "value": "C43313"},
                          {"label": "Siguatepeque", "value": "C43314"},
                          {"label": "Taulabe", "value": "C43315"},
                          {"label": "Valle de angeles", "value": "C43316"},
                          {"label": "Villa de San Antonio", "value": "C43317"}],
 "Commewijne District": [{"label": "Marienburg", "value": "C115462"},
                         {"label": "Nieuw Amsterdam", "value": "C115463"}],
 "Comoe District": [{"label": "Abengourou", "value": "C20931"},
                    {"label": "Aboisso", "value": "C20932"},
                    {"label": "Adiake", "value": "C20933"},
                    {"label": "Agnibilekrou", "value": "C20934"},
                    {"label": "Ayame", "value": "C20935"},
                    {"label": "Bonoua", "value": "C20936"},
                    {"label": "Grand-Bassam", "value": "C20937"},
                    {"label": "Indenie-Djuablin", "value": "C20938"},
                    {"label": "Sud-Comoe", "value": "C20939"}],
 "Conakry": [{"label": "Camayenne", "value": "C43008"},
             {"label": "Conakry", "value": "C43009"}],
 "Concepcion Department": [{"label": "Belen", "value": "C80545"},
                           {"label": "Concepcion", "value": "C80546"},
                           {"label": "Horqueta", "value": "C80547"}],
 "Connacht": [{"label": "Athenry", "value": "C51214"},
              {"label": "Ballaghaderreen", "value": "C51215"},
              {"label": "Ballina", "value": "C51216"},
              {"label": "Ballinasloe", "value": "C51217"},
              {"label": "Ballinrobe", "value": "C51218"},
              {"label": "Ballisodare", "value": "C51219"},
              {"label": "Ballyhaunis", "value": "C51220"},
              {"label": "Ballymote", "value": "C51221"},
              {"label": "Bearna", "value": "C51222"},
              {"label": "Belmullet", "value": "C51223"},
              {"label": "Boyle", "value": "C51224"},
              {"label": "Carrick-on-Shannon", "value": "C51225"},
              {"label": "Castlebar", "value": "C51226"},
              {"label": "Castlerea", "value": "C51227"},
              {"label": "Claregalway", "value": "C51228"},
              {"label": "Claremorris", "value": "C51229"},
              {"label": "Clifden", "value": "C51230"},
              {"label": "Collooney", "value": "C51231"},
              {"label": "County Galway", "value": "C51232"},
              {"label": "County Leitrim", "value": "C51233"},
              {"label": "Crossmolina", "value": "C51234"},
              {"label": "Foxford", "value": "C51235"},
              {"label": "Gaillimh", "value": "C51236"},
              {"label": "Galway City", "value": "C51237"},
              {"label": "Gort", "value": "C51238"},
              {"label": "Inishcrone", "value": "C51239"},
              {"label": "Kiltamagh", "value": "C51240"},
              {"label": "Kinlough", "value": "C51241"},
              {"label": "Loughrea", "value": "C51242"},
              {"label": "Manorhamilton", "value": "C51243"},
              {"label": "Mayo County", "value": "C51244"},
              {"label": "Moycullen", "value": "C51245"},
              {"label": "Oranmore", "value": "C51246"},
              {"label": "Oughterard", "value": "C51247"},
              {"label": "Portumna", "value": "C51248"},
              {"label": "Roscommon", "value": "C51249"},
              {"label": "Sligo", "value": "C51250"},
              {"label": "Strandhill", "value": "C51251"},
              {"label": "Swinford", "value": "C51252"},
              {"label": "Tobercurry", "value": "C51253"},
              {"label": "Tuam", "value": "C51254"},
              {"label": "Westport", "value": "C51255"}],
 "Connecticut": [{"label": "Ansonia", "value": "C129404"},
                 {"label": "Baltic", "value": "C129405"},
                 {"label": "Bethel", "value": "C129406"},
                 {"label": "Bethlehem Village", "value": "C129407"},
                 {"label": "Blue Hills", "value": "C129408"},
                 {"label": "Branford", "value": "C129409"},
                 {"label": "Branford Center", "value": "C129410"},
                 {"label": "Bridgeport", "value": "C129411"},
                 {"label": "Bristol", "value": "C129412"},
                 {"label": "Byram", "value": "C129413"},
                 {"label": "Canaan", "value": "C129414"},
                 {"label": "Canton Valley", "value": "C129415"},
                 {"label": "Central Waterford", "value": "C129416"},
                 {"label": "Cheshire", "value": "C129417"},
                 {"label": "Cheshire Village", "value": "C129418"},
                 {"label": "Chester Center", "value": "C129419"},
                 {"label": "City of Milford (balance)", "value": "C129420"},
                 {"label": "Clinton", "value": "C129421"},
                 {"label": "Colchester", "value": "C129422"},
                 {"label": "Collinsville", "value": "C129423"},
                 {"label": "Conning Towers-Nautilus Park", "value": "C129424"},
                 {"label": "Cos Cob", "value": "C129425"},
                 {"label": "Coventry Lake", "value": "C129426"},
                 {"label": "Cromwell", "value": "C129427"},
                 {"label": "Crystal Lake", "value": "C129428"},
                 {"label": "Danbury", "value": "C129429"},
                 {"label": "Danielson", "value": "C129430"},
                 {"label": "Darien", "value": "C129431"},
                 {"label": "Deep River Center", "value": "C129432"},
                 {"label": "Derby", "value": "C129433"},
                 {"label": "Durham", "value": "C129434"},
                 {"label": "East Brooklyn", "value": "C129435"},
                 {"label": "East Haddam", "value": "C129436"},
                 {"label": "East Hampton", "value": "C129437"},
                 {"label": "East Hartford", "value": "C129438"},
                 {"label": "East Haven", "value": "C129439"},
                 {"label": "East Norwalk", "value": "C129440"},
                 {"label": "East Windsor", "value": "C129441"},
                 {"label": "Easton", "value": "C129442"},
                 {"label": "Ellington", "value": "C129443"},
                 {"label": "Enfield", "value": "C129444"},
                 {"label": "Essex Village", "value": "C129445"},
                 {"label": "Fairfield", "value": "C129446"},
                 {"label": "Fairfield County", "value": "C129447"},
                 {"label": "Farmington", "value": "C129448"},
                 {"label": "Gales Ferry", "value": "C129449"},
                 {"label": "Georgetown", "value": "C129450"},
                 {"label": "Glastonbury", "value": "C129451"},
                 {"label": "Glastonbury Center", "value": "C129452"},
                 {"label": "Glenville", "value": "C129453"},
                 {"label": "Greenwich", "value": "C129454"},
                 {"label": "Groton", "value": "C129455"},
                 {"label": "Guilford", "value": "C129456"},
                 {"label": "Guilford Center", "value": "C129457"},
                 {"label": "Hamden", "value": "C129458"},
                 {"label": "Hartford", "value": "C129459"},
                 {"label": "Hartford County", "value": "C129460"},
                 {"label": "Hazardville", "value": "C129461"},
                 {"label": "Hebron", "value": "C129462"},
                 {"label": "Heritage Village", "value": "C129463"},
                 {"label": "Higganum", "value": "C129464"},
                 {"label": "Jewett City", "value": "C129465"},
                 {"label": "Kensington", "value": "C129466"},
                 {"label": "Kent", "value": "C129467"},
                 {"label": "Killingly Center", "value": "C129468"},
                 {"label": "Lake Pocotopaug", "value": "C129469"},
                 {"label": "Ledyard", "value": "C129470"},
                 {"label": "Lisbon", "value": "C129471"},
                 {"label": "Litchfield", "value": "C129472"},
                 {"label": "Litchfield County", "value": "C129473"},
                 {"label": "Long Hill", "value": "C129474"},
                 {"label": "Madison", "value": "C129475"},
                 {"label": "Madison Center", "value": "C129476"},
                 {"label": "Manchester", "value": "C129477"},
                 {"label": "Mansfield City", "value": "C129478"},
                 {"label": "Meriden", "value": "C129479"},
                 {"label": "Middlebury", "value": "C129480"},
                 {"label": "Middlesex County", "value": "C129481"},
                 {"label": "Middletown", "value": "C129482"},
                 {"label": "Milford", "value": "C129483"},
                 {"label": "Montville Center", "value": "C129484"},
                 {"label": "Moodus", "value": "C129485"},
                 {"label": "Moosup", "value": "C129486"},
                 {"label": "Mystic", "value": "C129487"},
                 {"label": "Naugatuck", "value": "C129488"},
                 {"label": "New Britain", "value": "C129489"},
                 {"label": "New Canaan", "value": "C129490"},
                 {"label": "New Fairfield", "value": "C129491"},
                 {"label": "New Hartford Center", "value": "C129492"},
                 {"label": "New Haven", "value": "C129493"},
                 {"label": "New Haven County", "value": "C129494"},
                 {"label": "New London", "value": "C129495"},
                 {"label": "New London County", "value": "C129496"},
                 {"label": "New Milford", "value": "C129497"},
                 {"label": "New Preston", "value": "C129498"},
                 {"label": "Newington", "value": "C129499"},
                 {"label": "Newtown", "value": "C129500"},
                 {"label": "Niantic", "value": "C129501"},
                 {"label": "Noank", "value": "C129502"},
                 {"label": "North Branford", "value": "C129503"},
                 {"label": "North Granby", "value": "C129504"},
                 {"label": "North Grosvenor Dale", "value": "C129505"},
                 {"label": "North Haven", "value": "C129506"},
                 {"label": "North Stamford", "value": "C129507"},
                 {"label": "Northwest Harwinton", "value": "C129508"},
                 {"label": "Norwalk", "value": "C129509"},
                 {"label": "Norwich", "value": "C129510"},
                 {"label": "Oakville", "value": "C129511"},
                 {"label": "Old Greenwich", "value": "C129512"},
                 {"label": "Old Mystic", "value": "C129513"},
                 {"label": "Old Saybrook", "value": "C129514"},
                 {"label": "Old Saybrook Center", "value": "C129515"},
                 {"label": "Orange", "value": "C129516"},
                 {"label": "Oxford", "value": "C129517"},
                 {"label": "Oxoboxo River", "value": "C129518"},
                 {"label": "Pawcatuck", "value": "C129519"},
                 {"label": "Pemberwick", "value": "C129520"},
                 {"label": "Plainfield", "value": "C129521"},
                 {"label": "Plainfield Village", "value": "C129522"},
                 {"label": "Plainville", "value": "C129523"},
                 {"label": "Plymouth", "value": "C129524"},
                 {"label": "Poquonock Bridge", "value": "C129525"},
                 {"label": "Portland", "value": "C129526"},
                 {"label": "Preston City", "value": "C129527"},
                 {"label": "Prospect", "value": "C129528"},
                 {"label": "Putnam", "value": "C129529"},
                 {"label": "Quinebaug", "value": "C129530"},
                 {"label": "Ridgefield", "value": "C129531"},
                 {"label": "Riverside", "value": "C129532"},
                 {"label": "Rockville", "value": "C129533"},
                 {"label": "Salem", "value": "C129534"},
                 {"label": "Salmon Brook", "value": "C129535"},
                 {"label": "Saybrook Manor", "value": "C129536"},
                 {"label": "Seymour", "value": "C129537"},
                 {"label": "Shelton", "value": "C129538"},
                 {"label": "Sherman", "value": "C129539"},
                 {"label": "Sherwood Manor", "value": "C129540"},
                 {"label": "Simsbury Center", "value": "C129541"},
                 {"label": "Somers", "value": "C129542"},
                 {"label": "South Coventry", "value": "C129543"},
                 {"label": "South Windham", "value": "C129544"},
                 {"label": "South Windsor", "value": "C129545"},
                 {"label": "South Woodstock", "value": "C129546"},
                 {"label": "Southbury", "value": "C129547"},
                 {"label": "Southport", "value": "C129548"},
                 {"label": "Southwood Acres", "value": "C129549"},
                 {"label": "Stafford", "value": "C129550"},
                 {"label": "Stafford Springs", "value": "C129551"},
                 {"label": "Stamford", "value": "C129552"},
                 {"label": "Storrs", "value": "C129553"},
                 {"label": "Stratford", "value": "C129554"},
                 {"label": "Suffield Depot", "value": "C129555"},
                 {"label": "Tariffville", "value": "C129556"},
                 {"label": "Terramuggus", "value": "C129557"},
                 {"label": "Terryville", "value": "C129558"},
                 {"label": "Thomaston", "value": "C129559"},
                 {"label": "Thompson", "value": "C129560"},
                 {"label": "Thompsonville", "value": "C129561"},
                 {"label": "Tolland", "value": "C129562"},
                 {"label": "Tolland County", "value": "C129563"},
                 {"label": "Torrington", "value": "C129564"},
                 {"label": "Trumbull", "value": "C129565"},
                 {"label": "Uncasville", "value": "C129566"},
                 {"label": "Wallingford", "value": "C129567"},
                 {"label": "Wallingford Center", "value": "C129568"},
                 {"label": "Washington", "value": "C129569"},
                 {"label": "Waterbury", "value": "C129570"},
                 {"label": "Waterford", "value": "C129571"},
                 {"label": "Watertown", "value": "C129572"},
                 {"label": "Wauregan", "value": "C129573"},
                 {"label": "Weatogue", "value": "C129574"},
                 {"label": "West Hartford", "value": "C129575"},
                 {"label": "West Haven", "value": "C129576"},
                 {"label": "West Simsbury", "value": "C129577"},
                 {"label": "West Torrington", "value": "C129578"},
                 {"label": "Westbrook Center", "value": "C129579"},
                 {"label": "Westport", "value": "C129580"},
                 {"label": "Wethersfield", "value": "C129581"},
                 {"label": "Willimantic", "value": "C129582"},
                 {"label": "Wilton", "value": "C129583"},
                 {"label": "Winchester Center", "value": "C129584"},
                 {"label": "Windham", "value": "C129585"},
                 {"label": "Windham County", "value": "C129586"},
                 {"label": "Windsor", "value": "C129587"},
                 {"label": "Windsor Locks", "value": "C129588"},
                 {"label": "Winsted", "value": "C129589"},
                 {"label": "Wolcott", "value": "C129590"},
                 {"label": "Woodbridge", "value": "C129591"},
                 {"label": "Woodbury", "value": "C129592"},
                 {"label": "Woodbury Center", "value": "C129593"},
                 {"label": "Woodmont", "value": "C129594"}],
 "Constanta County": [{"label": "23 August", "value": "C94507"},
                      {"label": "Adamclisi", "value": "C94508"},
                      {"label": "Agigea", "value": "C94509"},
                      {"label": "Albesti", "value": "C94510"},
                      {"label": "Aliman", "value": "C94511"},
                      {"label": "Amzacea", "value": "C94512"},
                      {"label": "Baneasa", "value": "C94513"},
                      {"label": "Baraganu", "value": "C94514"},
                      {"label": "Castelu", "value": "C94515"},
                      {"label": "Cerchezu", "value": "C94516"},
                      {"label": "Cernavoda", "value": "C94517"},
                      {"label": "Chirnogeni", "value": "C94518"},
                      {"label": "Ciobanu", "value": "C94519"},
                      {"label": "Ciocarlia", "value": "C94520"},
                      {"label": "Ciocarlia de Sus", "value": "C94521"},
                      {"label": "Cobadin", "value": "C94522"},
                      {"label": "Cogealac", "value": "C94523"},
                      {"label": "Comana", "value": "C94524"},
                      {"label": "Comuna 23 August", "value": "C94525"},
                      {"label": "Comuna Adamclisi", "value": "C94526"},
                      {"label": "Comuna Agigea", "value": "C94527"},
                      {"label": "Comuna Albesti", "value": "C94528"},
                      {"label": "Comuna Aliman", "value": "C94529"},
                      {"label": "Comuna Amzacea", "value": "C94530"},
                      {"label": "Comuna Baraganu", "value": "C94531"},
                      {"label": "Comuna Castelu", "value": "C94532"},
                      {"label": "Comuna Cerchezu", "value": "C94533"},
                      {"label": "Comuna Chirnogeni", "value": "C94534"},
                      {"label": "Comuna Ciobanu", "value": "C94535"},
                      {"label": "Comuna Ciocarlia", "value": "C94536"},
                      {"label": "Comuna Cobadin", "value": "C94537"},
                      {"label": "Comuna Cogealac", "value": "C94538"},
                      {"label": "Comuna Comana", "value": "C94539"},
                      {"label": "Comuna Corbu", "value": "C94540"},
                      {"label": "Comuna Costinesti", "value": "C94541"},
                      {"label": "Comuna Crucea", "value": "C94542"},
                      {"label": "Comuna Cumpana", "value": "C94543"},
                      {"label": "Comuna Cuza Voda", "value": "C94544"},
                      {"label": "Comuna Deleni", "value": "C94545"},
                      {"label": "Comuna Dobromir", "value": "C94546"},
                      {"label": "Comuna Dumbraveni", "value": "C94547"},
                      {"label": "Comuna Fantanele", "value": "C94548"},
                      {"label": "Comuna Garliciu", "value": "C94549"},
                      {"label": "Comuna Ghindaresti", "value": "C94550"},
                      {"label": "Comuna Gradina", "value": "C94551"},
                      {"label": "Comuna Horia", "value": "C94552"},
                      {"label": "Comuna Independenta", "value": "C94553"},
                      {"label": "Comuna Ion Corvin", "value": "C94554"},
                      {"label": "Comuna Istria", "value": "C94555"},
                      {"label": "Comuna Limanu", "value": "C94556"},
                      {"label": "Comuna Lipnita", "value": "C94557"},
                      {"label": "Comuna Lumina", "value": "C94558"},
                      {"label": "Comuna Mereni", "value": "C94559"},
                      {"label": "Comuna Mihai Viteazu", "value": "C94560"},
                      {"label": "Comuna Mihail Kogalniceanu",
                       "value": "C94561"},
                      {"label": "Comuna Mircea Voda", "value": "C94562"},
                      {"label": "Comuna Nicolae Balcescu", "value": "C94563"},
                      {"label": "Comuna Oltina", "value": "C94564"},
                      {"label": "Comuna Ostrov", "value": "C94565"},
                      {"label": "Comuna Pantelimon", "value": "C94566"},
                      {"label": "Comuna Pecineaga", "value": "C94567"},
                      {"label": "Comuna Pestera", "value": "C94568"},
                      {"label": "Comuna Poarta Alba", "value": "C94569"},
                      {"label": "Comuna Rasova", "value": "C94570"},
                      {"label": "Comuna Sacele", "value": "C94571"},
                      {"label": "Comuna Saligny", "value": "C94572"},
                      {"label": "Comuna Saraiu", "value": "C94573"},
                      {"label": "Comuna Seimeni", "value": "C94574"},
                      {"label": "Comuna Silistea", "value": "C94575"},
                      {"label": "Comuna Targusor", "value": "C94576"},
                      {"label": "Comuna Topalu", "value": "C94577"},
                      {"label": "Comuna Topraisar", "value": "C94578"},
                      {"label": "Comuna Tortoman", "value": "C94579"},
                      {"label": "Comuna Tuzla", "value": "C94580"},
                      {"label": "Comuna Valu lui Traian", "value": "C94581"},
                      {"label": "Comuna Vulturu", "value": "C94582"},
                      {"label": "Constanta", "value": "C94583"},
                      {"label": "Corbu", "value": "C94584"},
                      {"label": "Costinesti", "value": "C94585"},
                      {"label": "Crucea", "value": "C94586"},
                      {"label": "Culmea", "value": "C94587"},
                      {"label": "Cumpana", "value": "C94588"},
                      {"label": "Cuza Voda", "value": "C94589"},
                      {"label": "Deleni", "value": "C94590"},
                      {"label": "Dobromir", "value": "C94591"},
                      {"label": "Dorobantu", "value": "C94592"},
                      {"label": "Dulcesti", "value": "C94593"},
                      {"label": "Dumbraveni", "value": "C94594"},
                      {"label": "Dunareni", "value": "C94595"},
                      {"label": "Eforie Nord", "value": "C94596"},
                      {"label": "Eforie Sud", "value": "C94597"},
                      {"label": "Faclia", "value": "C94598"},
                      {"label": "Fantanele", "value": "C94599"},
                      {"label": "Garliciu", "value": "C94600"},
                      {"label": "Ghindaresti", "value": "C94601"},
                      {"label": "Gradina", "value": "C94602"},
                      {"label": "Harsova", "value": "C94603"},
                      {"label": "Horia", "value": "C94604"},
                      {"label": "Independenta", "value": "C94605"},
                      {"label": "Ion Corvin", "value": "C94606"},
                      {"label": "Istria", "value": "C94607"},
                      {"label": "Limanu", "value": "C94608"},
                      {"label": "Lipnita", "value": "C94609"},
                      {"label": "Lumina", "value": "C94610"},
                      {"label": "Mamaia-Sat", "value": "C94611"},
                      {"label": "Mangalia", "value": "C94612"},
                      {"label": "Medgidia", "value": "C94613"},
                      {"label": "Mereni", "value": "C94614"},
                      {"label": "Mihai Viteazu", "value": "C94615"},
                      {"label": "Mihail Kogalniceanu", "value": "C94616"},
                      {"label": "Mircea Voda", "value": "C94617"},
                      {"label": "Mosneni", "value": "C94618"},
                      {"label": "Municipiul Constanta", "value": "C94619"},
                      {"label": "Municipiul Mangalia", "value": "C94620"},
                      {"label": "Municipiul Medgidia", "value": "C94621"},
                      {"label": "Murfatlar", "value": "C94622"},
                      {"label": "Navodari", "value": "C94623"},
                      {"label": "Negru Voda", "value": "C94624"},
                      {"label": "Nicolae Balcescu", "value": "C94625"},
                      {"label": "Nisipari", "value": "C94626"},
                      {"label": "Nuntasi", "value": "C94627"},
                      {"label": "Oltina", "value": "C94628"},
                      {"label": "Oras Baneasa", "value": "C94629"},
                      {"label": "Oras Cernavoda", "value": "C94630"},
                      {"label": "Oras Eforie", "value": "C94631"},
                      {"label": "Oras Harsova", "value": "C94632"},
                      {"label": "Oras Murfatlar", "value": "C94633"},
                      {"label": "Oras Navodari", "value": "C94634"},
                      {"label": "Oras Negru Voda", "value": "C94635"},
                      {"label": "Oras Ovidiu", "value": "C94636"},
                      {"label": "Oras Techirghiol", "value": "C94637"},
                      {"label": "Ostrov", "value": "C94638"},
                      {"label": "Ovidiu", "value": "C94639"},
                      {"label": "Palazu Mare", "value": "C94640"},
                      {"label": "Pantelimon", "value": "C94641"},
                      {"label": "Pantelimon de Jos", "value": "C94642"},
                      {"label": "Pecineaga", "value": "C94643"},
                      {"label": "Pestera", "value": "C94644"},
                      {"label": "Piatra", "value": "C94645"},
                      {"label": "Pietreni", "value": "C94646"},
                      {"label": "Plopeni", "value": "C94647"},
                      {"label": "Poarta Alba", "value": "C94648"},
                      {"label": "Rasova", "value": "C94649"},
                      {"label": "Sacele", "value": "C94650"},
                      {"label": "Saligny", "value": "C94651"},
                      {"label": "Saraiu", "value": "C94652"},
                      {"label": "Satu Nou", "value": "C94653"},
                      {"label": "Schitu", "value": "C94654"},
                      {"label": "Seimeni", "value": "C94655"},
                      {"label": "Silistea", "value": "C94656"},
                      {"label": "Siminoc", "value": "C94657"},
                      {"label": "Targusor", "value": "C94658"},
                      {"label": "Tariverde", "value": "C94659"},
                      {"label": "Techirghiol", "value": "C94660"},
                      {"label": "Topalu", "value": "C94661"},
                      {"label": "Topraisar", "value": "C94662"},
                      {"label": "Tortoman", "value": "C94663"},
                      {"label": "Tuzla", "value": "C94664"},
                      {"label": "Valea Dacilor", "value": "C94665"},
                      {"label": "Valu lui Traian", "value": "C94666"},
                      {"label": "Viile", "value": "C94667"},
                      {"label": "Viisoara", "value": "C94668"},
                      {"label": "Vulturu", "value": "C94669"}],
 "Constantine": [{"label": "Ain Abid", "value": "C317"},
                 {"label": "Ain Smara", "value": "C318"},
                 {"label": "Constantine", "value": "C319"},
                 {"label": "Didouche Mourad", "value": "C320"},
                 {"label": "El Khroub", "value": "C321"},
                 {"label": "Hamma Bouziane", "value": "C322"}],
 "Copan Department": [{"label": "Agua Caliente", "value": "C43318"},
                      {"label": "Buenos Aires", "value": "C43319"},
                      {"label": "Cabanas", "value": "C43320"},
                      {"label": "Chalmeca", "value": "C43321"},
                      {"label": "Concepcion", "value": "C43322"},
                      {"label": "Concepcion de la Barranca", "value": "C43323"},
                      {"label": "Copan", "value": "C43324"},
                      {"label": "Copan Ruinas", "value": "C43325"},
                      {"label": "Corquin", "value": "C43326"},
                      {"label": "Cucuyagua", "value": "C43327"},
                      {"label": "Dolores", "value": "C43328"},
                      {"label": "Dulce Nombre", "value": "C43329"},
                      {"label": "El Corpus", "value": "C43330"},
                      {"label": "El Ocoton", "value": "C43331"},
                      {"label": "El Paraiso", "value": "C43332"},
                      {"label": "Florida", "value": "C43333"},
                      {"label": "La Entrada", "value": "C43334"},
                      {"label": "La Jigua", "value": "C43335"},
                      {"label": "La Playona", "value": "C43336"},
                      {"label": "La Union", "value": "C43337"},
                      {"label": "La Zumbadora", "value": "C43338"},
                      {"label": "Los Tangos", "value": "C43339"},
                      {"label": "Nueva Arcadia", "value": "C43340"},
                      {"label": "Ojos de Agua", "value": "C43341"},
                      {"label": "Pueblo Nuevo", "value": "C43342"},
                      {"label": "Quezailica", "value": "C43343"},
                      {"label": "San Agustin", "value": "C43344"},
                      {"label": "San Antonio", "value": "C43345"},
                      {"label": "San Jeronimo", "value": "C43346"},
                      {"label": "San Joaquin", "value": "C43347"},
                      {"label": "San Jose", "value": "C43348"},
                      {"label": "San Jose de Copan", "value": "C43349"},
                      {"label": "San Juan de Opoa", "value": "C43350"},
                      {"label": "San Juan de Planes", "value": "C43351"},
                      {"label": "San Nicolas", "value": "C43352"},
                      {"label": "San Pedro de Copan", "value": "C43353"},
                      {"label": "Santa Rita", "value": "C43354"},
                      {"label": "Santa Rita, Copan", "value": "C43355"},
                      {"label": "Santa Rosa de Copan", "value": "C43356"},
                      {"label": "Trinidad de Copan", "value": "C43357"},
                      {"label": "Veracruz", "value": "C43358"}],
 "Copperbelt Province": [{"label": "Chambishi", "value": "C148094"},
                         {"label": "Chililabombwe", "value": "C148095"},
                         {"label": "Chingola", "value": "C148096"},
                         {"label": "Chingola District", "value": "C148097"},
                         {"label": "Kalulushi", "value": "C148098"},
                         {"label": "Kataba", "value": "C148099"},
                         {"label": "Kitwe", "value": "C148100"},
                         {"label": "Luanshya", "value": "C148101"},
                         {"label": "Mpongwe", "value": "C148102"},
                         {"label": "Mufulira", "value": "C148103"},
                         {"label": "Ndola", "value": "C148104"}],
 "Coquimbo": [{"label": "Andacollo", "value": "C17957"},
              {"label": "Canela", "value": "C17958"},
              {"label": "Combarbala", "value": "C17959"},
              {"label": "Coquimbo", "value": "C17960"},
              {"label": "Illapel", "value": "C17961"},
              {"label": "La Higuera", "value": "C17962"},
              {"label": "La Serena", "value": "C17963"},
              {"label": "Los Vilos", "value": "C17964"},
              {"label": "Monte Patria", "value": "C17965"},
              {"label": "Ovalle", "value": "C17966"},
              {"label": "Paihuano", "value": "C17967"},
              {"label": "Punitaqui", "value": "C17968"},
              {"label": "Rio Hurtado", "value": "C17969"},
              {"label": "Salamanca", "value": "C17970"},
              {"label": "Vicuna", "value": "C17971"}],
 "Cordillera Administrative Region": [{"label": "Agbannawag",
                                       "value": "C85551"},
                                      {"label": "Ambuclao", "value": "C85552"},
                                      {"label": "Amlimay", "value": "C85553"},
                                      {"label": "Ampusungan",
                                       "value": "C85554"},
                                      {"label": "Angad", "value": "C85555"},
                                      {"label": "Apayao", "value": "C85556"},
                                      {"label": "Atok", "value": "C85557"},
                                      {"label": "Baculongan",
                                       "value": "C85558"},
                                      {"label": "Baguinge", "value": "C85559"},
                                      {"label": "Baguio", "value": "C85560"},
                                      {"label": "Bakun", "value": "C85561"},
                                      {"label": "Balbalan", "value": "C85562"},
                                      {"label": "Banaue", "value": "C85563"},
                                      {"label": "Bangao", "value": "C85564"},
                                      {"label": "Bangued", "value": "C85565"},
                                      {"label": "Bantay", "value": "C85566"},
                                      {"label": "Barlig", "value": "C85567"},
                                      {"label": "Bauko", "value": "C85568"},
                                      {"label": "Bayabas", "value": "C85569"},
                                      {"label": "Besao", "value": "C85570"},
                                      {"label": "Betwagan", "value": "C85571"},
                                      {"label": "Bocos", "value": "C85572"},
                                      {"label": "Bokod", "value": "C85573"},
                                      {"label": "Boliney", "value": "C85574"},
                                      {"label": "Bontoc", "value": "C85575"},
                                      {"label": "Bucay", "value": "C85576"},
                                      {"label": "Buguias", "value": "C85577"},
                                      {"label": "Bulalacao", "value": "C85578"},
                                      {"label": "Butigui", "value": "C85579"},
                                      {"label": "Calaba", "value": "C85580"},
                                      {"label": "Calanasan", "value": "C85581"},
                                      {"label": "Conner", "value": "C85582"},
                                      {"label": "Daguioman", "value": "C85583"},
                                      {"label": "Dalipey", "value": "C85584"},
                                      {"label": "Dalupirip", "value": "C85585"},
                                      {"label": "Danglas", "value": "C85586"},
                                      {"label": "Dolores", "value": "C85587"},
                                      {"label": "Flora", "value": "C85588"},
                                      {"label": "Gambang", "value": "C85589"},
                                      {"label": "Guinsadan", "value": "C85590"},
                                      {"label": "Hapao", "value": "C85591"},
                                      {"label": "Itogon", "value": "C85592"},
                                      {"label": "Kabugao", "value": "C85593"},
                                      {"label": "Kalinga", "value": "C85594"},
                                      {"label": "Kapangan", "value": "C85595"},
                                      {"label": "Kiangan", "value": "C85596"},
                                      {"label": "Kibungan", "value": "C85597"},
                                      {"label": "La Paz", "value": "C85598"},
                                      {"label": "La Trinidad",
                                       "value": "C85599"},
                                      {"label": "Lagangilang",
                                       "value": "C85600"},
                                      {"label": "Lagawe", "value": "C85601"},
                                      {"label": "Lagayan", "value": "C85602"},
                                      {"label": "Lamut", "value": "C85603"},
                                      {"label": "Laya", "value": "C85604"},
                                      {"label": "Licuan", "value": "C85605"},
                                      {"label": "Liwan", "value": "C85606"},
                                      {"label": "Loacan", "value": "C85607"},
                                      {"label": "Luba", "value": "C85608"},
                                      {"label": "Lubuagan", "value": "C85609"},
                                      {"label": "Luna", "value": "C85610"},
                                      {"label": "Manabo", "value": "C85611"},
                                      {"label": "Mankayan", "value": "C85612"},
                                      {"label": "Monamon", "value": "C85613"},
                                      {"label": "Mountain Province",
                                       "value": "C85614"},
                                      {"label": "Nangalisan",
                                       "value": "C85615"},
                                      {"label": "Natonin", "value": "C85616"},
                                      {"label": "Natubleng", "value": "C85617"},
                                      {"label": "Paracelis", "value": "C85618"},
                                      {"label": "Pasil", "value": "C85619"},
                                      {"label": "Penarrubia",
                                       "value": "C85620"},
                                      {"label": "Pidigan", "value": "C85621"},
                                      {"label": "Pilar", "value": "C85622"},
                                      {"label": "Pinukpuk", "value": "C85623"},
                                      {"label": "Province of Abra",
                                       "value": "C85624"},
                                      {"label": "Province of Benguet",
                                       "value": "C85625"},
                                      {"label": "Province of Ifugao",
                                       "value": "C85626"},
                                      {"label": "Sablan", "value": "C85627"},
                                      {"label": "Sadanga", "value": "C85628"},
                                      {"label": "Sadsadan", "value": "C85629"},
                                      {"label": "Sagada", "value": "C85630"},
                                      {"label": "San Juan", "value": "C85631"},
                                      {"label": "San Ramon", "value": "C85632"},
                                      {"label": "Santa Marcela",
                                       "value": "C85633"},
                                      {"label": "Tabaan", "value": "C85634"},
                                      {"label": "Tabio", "value": "C85635"},
                                      {"label": "Tabuk", "value": "C85636"},
                                      {"label": "Tacadang", "value": "C85637"},
                                      {"label": "Tadian", "value": "C85638"},
                                      {"label": "Taloy", "value": "C85639"},
                                      {"label": "Tanudan", "value": "C85640"},
                                      {"label": "Tayum", "value": "C85641"},
                                      {"label": "Tinglayan", "value": "C85642"},
                                      {"label": "Topdac", "value": "C85643"},
                                      {"label": "Tublay", "value": "C85644"},
                                      {"label": "Tuding", "value": "C85645"},
                                      {"label": "Villarosa", "value": "C85646"},
                                      {"label": "Villaviciosa",
                                       "value": "C85647"}],
 "Cordillera Department": [{"label": "Altos", "value": "C80548"},
                           {"label": "Arroyos y Esteros", "value": "C80549"},
                           {"label": "Atyra", "value": "C80550"},
                           {"label": "Caacupe", "value": "C80551"},
                           {"label": "Caraguatay", "value": "C80552"},
                           {"label": "Emboscada", "value": "C80553"},
                           {"label": "Eusebio Ayala", "value": "C80554"},
                           {"label": "Isla Pucu", "value": "C80555"},
                           {"label": "Itacurubi de la Cordillera",
                            "value": "C80556"},
                           {"label": "Piribebuy", "value": "C80557"},
                           {"label": "San Bernardino", "value": "C80558"},
                           {"label": "Santa Elena", "value": "C80559"},
                           {"label": "Tobati", "value": "C80560"}],
 "Cordoba": [{"label": "Achiras", "value": "C739"},
             {"label": "Adelia Maria", "value": "C740"},
             {"label": "Agua de Oro", "value": "C741"},
             {"label": "Alejandro Roca", "value": "C742"},
             {"label": "Alejo Ledesma", "value": "C743"},
             {"label": "Almafuerte", "value": "C744"},
             {"label": "Alta Gracia", "value": "C745"},
             {"label": "Altos de Chipion", "value": "C746"},
             {"label": "Arias", "value": "C747"},
             {"label": "Arroyito", "value": "C748"},
             {"label": "Arroyo Cabral", "value": "C749"},
             {"label": "Balnearia", "value": "C750"},
             {"label": "Bell Ville", "value": "C751"},
             {"label": "Berrotaran", "value": "C752"},
             {"label": "Brinkmann", "value": "C753"},
             {"label": "Buchardo", "value": "C754"},
             {"label": "Camilo Aldao", "value": "C755"},
             {"label": "Canada de Luque", "value": "C756"},
             {"label": "Canals", "value": "C757"},
             {"label": "Capilla del Monte", "value": "C758"},
             {"label": "Carnerillo", "value": "C759"},
             {"label": "Carrilobo", "value": "C760"},
             {"label": "Cavanagh", "value": "C761"},
             {"label": "Charras", "value": "C762"},
             {"label": "Chazon", "value": "C763"},
             {"label": "Cintra", "value": "C764"},
             {"label": "Colonia La Tordilla", "value": "C765"},
             {"label": "Colonia San Bartolome", "value": "C766"},
             {"label": "Cordoba", "value": "C767"},
             {"label": "Coronel Baigorria", "value": "C768"},
             {"label": "Coronel Moldes", "value": "C769"},
             {"label": "Corral de Bustos", "value": "C770"},
             {"label": "Corralito", "value": "C771"},
             {"label": "Cosquin", "value": "C772"},
             {"label": "Costa Sacate", "value": "C773"},
             {"label": "Cruz Alta", "value": "C774"},
             {"label": "Cruz del Eje", "value": "C775"},
             {"label": "Cuesta Blanca", "value": "C776"},
             {"label": "Dalmacio Velez Sarsfield", "value": "C777"},
             {"label": "Dean Funes", "value": "C778"},
             {"label": "Del Campillo", "value": "C779"},
             {"label": "Departamento de Calamuchita", "value": "C780"},
             {"label": "Departamento de Colon", "value": "C781"},
             {"label": "Departamento de Cruz del Eje", "value": "C782"},
             {"label": "Departamento de General Roca", "value": "C783"},
             {"label": "Departamento de General San Martin", "value": "C784"},
             {"label": "Departamento de Juarez Celman", "value": "C785"},
             {"label": "Departamento de Marcos Juarez", "value": "C786"},
             {"label": "Departamento de Minas", "value": "C787"},
             {"label": "Departamento de Presidente Roque Saenz Pena",
              "value": "C788"},
             {"label": "Departamento de Rio Cuarto", "value": "C789"},
             {"label": "Departamento de Rio Primero", "value": "C790"},
             {"label": "Departamento de Rio Seco", "value": "C791"},
             {"label": "Departamento de Rio Segundo", "value": "C792"},
             {"label": "Departamento de San Alberto", "value": "C793"},
             {"label": "Departamento de San Javier", "value": "C794"},
             {"label": "Departamento de San Justo", "value": "C795"},
             {"label": "Departamento de Sobremonte", "value": "C796"},
             {"label": "Departamento de Totoral", "value": "C797"},
             {"label": "Departamento de Tulumba", "value": "C798"},
             {"label": "Departamento de Union", "value": "C799"},
             {"label": "Despenaderos", "value": "C800"},
             {"label": "Devoto", "value": "C801"},
             {"label": "El Aranado", "value": "C802"},
             {"label": "El Tio", "value": "C803"},
             {"label": "Elena", "value": "C804"},
             {"label": "Embalse", "value": "C805"},
             {"label": "Etruria", "value": "C806"},
             {"label": "General Baldissera", "value": "C807"},
             {"label": "General Cabrera", "value": "C808"},
             {"label": "General Levalle", "value": "C809"},
             {"label": "General Roca", "value": "C810"},
             {"label": "Guatimozin", "value": "C811"},
             {"label": "Hernando", "value": "C812"},
             {"label": "Huanchillas", "value": "C813"},
             {"label": "Huerta Grande", "value": "C814"},
             {"label": "Huinca Renanco", "value": "C815"},
             {"label": "Idiazabal", "value": "C816"},
             {"label": "Inriville", "value": "C817"},
             {"label": "Isla Verde", "value": "C818"},
             {"label": "Italo", "value": "C819"},
             {"label": "James Craik", "value": "C820"},
             {"label": "Jesus Maria", "value": "C821"},
             {"label": "Justiniano Posse", "value": "C822"},
             {"label": "La Calera", "value": "C823"},
             {"label": "La Carlota", "value": "C824"},
             {"label": "La Cesira", "value": "C825"},
             {"label": "La Cumbre", "value": "C826"},
             {"label": "La Falda", "value": "C827"},
             {"label": "La Francia", "value": "C828"},
             {"label": "La Granja", "value": "C829"},
             {"label": "La Para", "value": "C830"},
             {"label": "La Playosa", "value": "C831"},
             {"label": "Laborde", "value": "C832"},
             {"label": "Laboulaye", "value": "C833"},
             {"label": "Laguna Larga", "value": "C834"},
             {"label": "Las Acequias", "value": "C835"},
             {"label": "Las Higueras", "value": "C836"},
             {"label": "Las Junturas", "value": "C837"},
             {"label": "Las Perdices", "value": "C838"},
             {"label": "Las Varas", "value": "C839"},
             {"label": "Las Varillas", "value": "C840"},
             {"label": "Leones", "value": "C841"},
             {"label": "Los Cocos", "value": "C842"},
             {"label": "Los Condores", "value": "C843"},
             {"label": "Los Surgentes", "value": "C844"},
             {"label": "Malagueno", "value": "C845"},
             {"label": "Malvinas Argentinas", "value": "C846"},
             {"label": "Marcos Juarez", "value": "C847"},
             {"label": "Marull", "value": "C848"},
             {"label": "Mattaldi", "value": "C849"},
             {"label": "Mendiolaza", "value": "C850"},
             {"label": "Mina Clavero", "value": "C851"},
             {"label": "Miramar", "value": "C852"},
             {"label": "Monte Buey", "value": "C853"},
             {"label": "Monte Cristo", "value": "C854"},
             {"label": "Monte Maiz", "value": "C855"},
             {"label": "Morrison", "value": "C856"},
             {"label": "Morteros", "value": "C857"},
             {"label": "Noetinger", "value": "C858"},
             {"label": "Obispo Trejo", "value": "C859"},
             {"label": "Oliva", "value": "C860"},
             {"label": "Oncativo", "value": "C861"},
             {"label": "Ordonez", "value": "C862"},
             {"label": "Pascanas", "value": "C863"},
             {"label": "Pasco", "value": "C864"},
             {"label": "Pilar", "value": "C865"},
             {"label": "Piquillin", "value": "C866"},
             {"label": "Portena", "value": "C867"},
             {"label": "Pozo del Molle", "value": "C868"},
             {"label": "Quilino", "value": "C869"},
             {"label": "Rio Ceballos", "value": "C870"},
             {"label": "Rio Cuarto", "value": "C871"},
             {"label": "Rio Segundo", "value": "C872"},
             {"label": "Rio Tercero", "value": "C873"},
             {"label": "Sacanta", "value": "C874"},
             {"label": "Saldan", "value": "C875"},
             {"label": "Salsacate", "value": "C876"},
             {"label": "Salsipuedes", "value": "C877"},
             {"label": "Sampacho", "value": "C878"},
             {"label": "San Agustin", "value": "C879"},
             {"label": "San Antonio de Litin", "value": "C880"},
             {"label": "San Basilio", "value": "C881"},
             {"label": "San Carlos", "value": "C882"},
             {"label": "San Francisco", "value": "C883"},
             {"label": "San Francisco del Chanar", "value": "C884"},
             {"label": "San Jose de la Dormida", "value": "C885"},
             {"label": "Santa Eufemia", "value": "C886"},
             {"label": "Santa Magdalena", "value": "C887"},
             {"label": "Santa Rosa de Calamuchita", "value": "C888"},
             {"label": "Santa Rosa de Rio Primero", "value": "C889"},
             {"label": "Santiago Temple", "value": "C890"},
             {"label": "Saturnino M. Laspiur", "value": "C891"},
             {"label": "Sebastian Elcano", "value": "C892"},
             {"label": "Serrano", "value": "C893"},
             {"label": "Serrezuela", "value": "C894"},
             {"label": "Tancacha", "value": "C895"},
             {"label": "Ticino", "value": "C896"},
             {"label": "Tio Pujio", "value": "C897"},
             {"label": "Toledo", "value": "C898"},
             {"label": "Ucacha", "value": "C899"},
             {"label": "Unquillo", "value": "C900"},
             {"label": "Valle Hermoso", "value": "C901"},
             {"label": "Viamonte", "value": "C902"},
             {"label": "Vicuna Mackenna", "value": "C903"},
             {"label": "Villa Allende", "value": "C904"},
             {"label": "Villa Ascasubi", "value": "C905"},
             {"label": "Villa Berna", "value": "C906"},
             {"label": "Villa Carlos Paz", "value": "C907"},
             {"label": "Villa Concepcion del Tio", "value": "C908"},
             {"label": "Villa Cura Brochero", "value": "C909"},
             {"label": "Villa de Soto", "value": "C910"},
             {"label": "Villa del Dique", "value": "C911"},
             {"label": "Villa del Rosario", "value": "C912"},
             {"label": "Villa del Totoral", "value": "C913"},
             {"label": "Villa Dolores", "value": "C914"},
             {"label": "Villa General Belgrano", "value": "C915"},
             {"label": "Villa Giardino", "value": "C916"},
             {"label": "Villa Huidobro", "value": "C917"},
             {"label": "Villa Las Rosas", "value": "C918"},
             {"label": "Villa Maria", "value": "C919"},
             {"label": "Villa Nueva", "value": "C920"},
             {"label": "Villa Reduccion", "value": "C921"},
             {"label": "Villa Rumipal", "value": "C922"},
             {"label": "Villa Tulumba", "value": "C923"},
             {"label": "Villa Valeria", "value": "C924"},
             {"label": "Wenceslao Escalante", "value": "C925"},
             {"label": "Ayapel", "value": "C20032"},
             {"label": "Buenavista", "value": "C20033"},
             {"label": "Canalete", "value": "C20034"},
             {"label": "Cerete", "value": "C20035"},
             {"label": "Chima", "value": "C20036"},
             {"label": "Chinu", "value": "C20037"},
             {"label": "Cienaga de Oro", "value": "C20038"},
             {"label": "Cotorra", "value": "C20039"},
             {"label": "La Apartada", "value": "C20040"},
             {"label": "Lorica", "value": "C20041"},
             {"label": "Los Cordobas", "value": "C20042"},
             {"label": "Momil", "value": "C20043"},
             {"label": "Monitos", "value": "C20044"},
             {"label": "Montelibano", "value": "C20045"},
             {"label": "Monteria", "value": "C20046"},
             {"label": "Planeta Rica", "value": "C20047"},
             {"label": "Pueblo Nuevo", "value": "C20048"},
             {"label": "Puerto Escondido", "value": "C20049"},
             {"label": "Puerto Libertador", "value": "C20050"},
             {"label": "Purisima", "value": "C20051"},
             {"label": "Sahagun", "value": "C20052"},
             {"label": "San Andres de Sotavento", "value": "C20053"},
             {"label": "San Antero", "value": "C20054"},
             {"label": "San Bernardo del Viento", "value": "C20055"},
             {"label": "San Carlos", "value": "C20056"},
             {"label": "San Jose de Ure", "value": "C20057"},
             {"label": "San Pelayo", "value": "C20058"},
             {"label": "Tierralta", "value": "C20059"},
             {"label": "Tuchin", "value": "C20060"},
             {"label": "Valencia", "value": "C20061"}],
 "Corfu Prefecture": [{"label": "Farkadona", "value": "C42048"},
                      {"label": "Fiki", "value": "C42049"},
                      {"label": "Gomfoi", "value": "C42050"},
                      {"label": "Grizano", "value": "C42051"},
                      {"label": "Kalampaka", "value": "C42052"},
                      {"label": "Kastraki", "value": "C42053"},
                      {"label": "Megala Kalyvia", "value": "C42054"},
                      {"label": "Megalochori", "value": "C42055"},
                      {"label": "Oichalia", "value": "C42056"},
                      {"label": "Palaiomonastiro", "value": "C42057"},
                      {"label": "Palaiopyrgos", "value": "C42058"},
                      {"label": "Pigi", "value": "C42059"},
                      {"label": "Pyli", "value": "C42060"},
                      {"label": "Pyrgetos", "value": "C42061"},
                      {"label": "Rizoma", "value": "C42062"},
                      {"label": "Taxiarches", "value": "C42063"},
                      {"label": "Trikala", "value": "C42064"},
                      {"label": "Vasiliki", "value": "C42065"},
                      {"label": "Zarkos", "value": "C42066"}],
 "Coronie District": [{"label": "Totness", "value": "C115464"}],
 "Corozal District": [{"label": "Corozal", "value": "C9587"}],
 "Corrientes": [{"label": "Alvear", "value": "C926"},
                {"label": "Beron de Astrada", "value": "C927"},
                {"label": "Bonpland", "value": "C928"},
                {"label": "Chavarria", "value": "C929"},
                {"label": "Concepcion", "value": "C930"},
                {"label": "Corrientes", "value": "C931"},
                {"label": "Cruz de los Milagros", "value": "C932"},
                {"label": "Curuzu Cuatia", "value": "C933"},
                {"label": "Departamento de Bella Vista", "value": "C934"},
                {"label": "Departamento de Beron de Astrada", "value": "C935"},
                {"label": "Departamento de Capital", "value": "C936"},
                {"label": "Departamento de Concepcion", "value": "C937"},
                {"label": "Departamento de Curuzu Cuatia", "value": "C938"},
                {"label": "Departamento de Empedrado", "value": "C939"},
                {"label": "Departamento de Esquina", "value": "C940"},
                {"label": "Departamento de General Alvear", "value": "C941"},
                {"label": "Departamento de General Paz", "value": "C942"},
                {"label": "Departamento de Goya", "value": "C943"},
                {"label": "Departamento de Itati", "value": "C944"},
                {"label": "Departamento de Ituzaingo", "value": "C945"},
                {"label": "Departamento de Lavalle", "value": "C946"},
                {"label": "Departamento de Mburucuya", "value": "C947"},
                {"label": "Departamento de Mercedes", "value": "C948"},
                {"label": "Departamento de Monte Caseros", "value": "C949"},
                {"label": "Departamento de Paso de los Libres",
                 "value": "C950"},
                {"label": "Departamento de Saladas", "value": "C951"},
                {"label": "Departamento de San Cosme", "value": "C952"},
                {"label": "Departamento de San Luis del Palmar",
                 "value": "C953"},
                {"label": "Departamento de San Martin", "value": "C954"},
                {"label": "Departamento de San Miguel", "value": "C955"},
                {"label": "Departamento de San Roque", "value": "C956"},
                {"label": "Departamento de Santo Tome", "value": "C957"},
                {"label": "Departamento de Sauce", "value": "C958"},
                {"label": "Empedrado", "value": "C959"},
                {"label": "Esquina", "value": "C960"},
                {"label": "Felipe Yofre", "value": "C961"},
                {"label": "Garruchos", "value": "C962"},
                {"label": "Gobernador Juan E. Martinez", "value": "C963"},
                {"label": "Gobernador Virasora", "value": "C964"},
                {"label": "Goya", "value": "C965"},
                {"label": "Herlitzka", "value": "C966"},
                {"label": "Ita Ibate", "value": "C967"},
                {"label": "Itati", "value": "C968"},
                {"label": "Ituzaingo", "value": "C969"},
                {"label": "Juan Pujol", "value": "C970"},
                {"label": "La Cruz", "value": "C971"},
                {"label": "Libertad", "value": "C972"},
                {"label": "Lomas de Vallejos", "value": "C973"},
                {"label": "Loreto", "value": "C974"},
                {"label": "Mariano I. Loza", "value": "C975"},
                {"label": "Mburucuya", "value": "C976"},
                {"label": "Mercedes", "value": "C977"},
                {"label": "Mocoreta", "value": "C978"},
                {"label": "Monte Caseros", "value": "C979"},
                {"label": "Nuestra Senora del Rosario de Caa Cati",
                 "value": "C980"},
                {"label": "Nueve de Julio", "value": "C981"},
                {"label": "Palmar Grande", "value": "C982"},
                {"label": "Paso de la Patria", "value": "C983"},
                {"label": "Paso de los Libres", "value": "C984"},
                {"label": "Pedro R. Fernandez", "value": "C985"},
                {"label": "Perugorria", "value": "C986"},
                {"label": "Pueblo Libertador", "value": "C987"},
                {"label": "Riachuelo", "value": "C988"},
                {"label": "Saladas", "value": "C989"},
                {"label": "San Carlos", "value": "C990"},
                {"label": "San Cosme", "value": "C991"},
                {"label": "San Lorenzo", "value": "C992"},
                {"label": "San Luis del Palmar", "value": "C993"},
                {"label": "San Miguel", "value": "C994"},
                {"label": "Santa Lucia", "value": "C995"},
                {"label": "Santa Rosa", "value": "C996"},
                {"label": "Santo Tome", "value": "C997"},
                {"label": "Yapeyu", "value": "C998"},
                {"label": "Yataity Calle", "value": "C999"}],
 "Corse": [{"label": "Afa", "value": "C28051"},
           {"label": "Ajaccio", "value": "C28052"},
           {"label": "Alata", "value": "C28053"},
           {"label": "Albitreccia", "value": "C28054"},
           {"label": "Aleria", "value": "C28055"},
           {"label": "Appietto", "value": "C28056"},
           {"label": "Bastia", "value": "C28057"},
           {"label": "Biguglia", "value": "C28058"},
           {"label": "Bonifacio", "value": "C28059"},
           {"label": "Borgo", "value": "C28060"},
           {"label": "Brando", "value": "C28061"},
           {"label": "Calenzana", "value": "C28062"},
           {"label": "Calvi", "value": "C28063"},
           {"label": "Cargese", "value": "C28064"},
           {"label": "Cauro", "value": "C28065"},
           {"label": "Cervione", "value": "C28066"},
           {"label": "Corte", "value": "C28067"},
           {"label": "Cuttoli-Corticchiato", "value": "C28068"},
           {"label": "Departement de la Corse-du-Sud", "value": "C28069"},
           {"label": "Departement de la Haute-Corse", "value": "C28070"},
           {"label": "Figari", "value": "C28071"},
           {"label": "Furiani", "value": "C28072"},
           {"label": "Ghisonaccia", "value": "C28073"},
           {"label": "Grosseto-Prugna", "value": "C28074"},
           {"label": "LIle-Rousse", "value": "C28075"},
           {"label": "Linguizzetta", "value": "C28076"},
           {"label": "Lucciana", "value": "C28077"},
           {"label": "Lumio", "value": "C28078"},
           {"label": "Monticello", "value": "C28079"},
           {"label": "Morosaglia", "value": "C28080"},
           {"label": "Oletta", "value": "C28081"},
           {"label": "Olmeto", "value": "C28082"},
           {"label": "Penta-di-Casinca", "value": "C28083"},
           {"label": "Peri", "value": "C28084"},
           {"label": "Pietranera", "value": "C28085"},
           {"label": "Pietrosella", "value": "C28086"},
           {"label": "Porto-Vecchio", "value": "C28087"},
           {"label": "Propriano", "value": "C28088"},
           {"label": "Prunelli-di-Fiumorbo", "value": "C28089"},
           {"label": "Saint-Florent", "value": "C28090"},
           {"label": "San-Martino-di-Lota", "value": "C28091"},
           {"label": "San-Nicolao", "value": "C28092"},
           {"label": "Santa-Lucia-di-Moriani", "value": "C28093"},
           {"label": "Santa-Maria-di-Lota", "value": "C28094"},
           {"label": "Sari-Solenzara", "value": "C28095"},
           {"label": "Sarrola", "value": "C28096"},
           {"label": "Sartene", "value": "C28097"},
           {"label": "Sisco", "value": "C28098"},
           {"label": "Ventiseri", "value": "C28099"},
           {"label": "Vescovato", "value": "C28100"},
           {"label": "Zonza", "value": "C28101"}],
 "Cortes Department": [{"label": "Agua Azul", "value": "C43359"},
                       {"label": "Agua Azul Rancho", "value": "C43360"},
                       {"label": "Armenta", "value": "C43361"},
                       {"label": "Baja Mar", "value": "C43362"},
                       {"label": "Baracoa", "value": "C43363"},
                       {"label": "Bejuco", "value": "C43364"},
                       {"label": "Canaveral", "value": "C43365"},
                       {"label": "Casa Quemada", "value": "C43366"},
                       {"label": "Chivana", "value": "C43367"},
                       {"label": "Choloma", "value": "C43368"},
                       {"label": "Chotepe", "value": "C43369"},
                       {"label": "Cofradia", "value": "C43370"},
                       {"label": "Cuyamel", "value": "C43371"},
                       {"label": "El Llano", "value": "C43372"},
                       {"label": "El Maranon", "value": "C43373"},
                       {"label": "El Milagro", "value": "C43374"},
                       {"label": "El Olivar", "value": "C43375"},
                       {"label": "El Perico", "value": "C43376"},
                       {"label": "El Plan", "value": "C43377"},
                       {"label": "El Porvenir", "value": "C43378"},
                       {"label": "El Rancho", "value": "C43379"},
                       {"label": "El Tigre", "value": "C43380"},
                       {"label": "El Zapotal del Norte", "value": "C43381"},
                       {"label": "La Guama", "value": "C43382"},
                       {"label": "La Huesa", "value": "C43383"},
                       {"label": "La Jutosa", "value": "C43384"},
                       {"label": "La Lima", "value": "C43385"},
                       {"label": "La Sabana", "value": "C43386"},
                       {"label": "Los Caminos", "value": "C43387"},
                       {"label": "Los Naranjos", "value": "C43388"},
                       {"label": "Monterrey", "value": "C43389"},
                       {"label": "Nuevo Chamelecon", "value": "C43390"},
                       {"label": "Omoa", "value": "C43391"},
                       {"label": "Oropendolas", "value": "C43392"},
                       {"label": "Pena Blanca", "value": "C43393"},
                       {"label": "Pimienta", "value": "C43394"},
                       {"label": "Pimienta Vieja", "value": "C43395"},
                       {"label": "Potrerillos", "value": "C43396"},
                       {"label": "Pueblo Nuevo", "value": "C43397"},
                       {"label": "Puerto Alto", "value": "C43398"},
                       {"label": "Puerto Cortes", "value": "C43399"},
                       {"label": "Puerto Cortez", "value": "C43400"},
                       {"label": "Quebrada Seca", "value": "C43401"},
                       {"label": "Rio Blanquito", "value": "C43402"},
                       {"label": "Rio Chiquito", "value": "C43403"},
                       {"label": "Rio Lindo", "value": "C43404"},
                       {"label": "San Antonio de Cortes", "value": "C43405"},
                       {"label": "San Buenaventura", "value": "C43406"},
                       {"label": "San Francisco de Yojoa", "value": "C43407"},
                       {"label": "San Jose del Boqueron", "value": "C43408"},
                       {"label": "San Manuel", "value": "C43409"},
                       {"label": "San Pedro Sula", "value": "C43410"},
                       {"label": "Santa Cruz de Yojoa", "value": "C43411"},
                       {"label": "Santa Elena", "value": "C43412"},
                       {"label": "Travesia", "value": "C43413"},
                       {"label": "Villanueva", "value": "C43414"}],
 "Corum": [{"label": "Alaca", "value": "C120169"},
           {"label": "Bayat", "value": "C120170"},
           {"label": "Bogazkale Ilcesi", "value": "C120171"},
           {"label": "Corum", "value": "C120172"},
           {"label": "Dodurga", "value": "C120173"},
           {"label": "Iskilip", "value": "C120174"},
           {"label": "Kargi Ilcesi", "value": "C120175"},
           {"label": "Lacin", "value": "C120176"},
           {"label": "Mecitozu", "value": "C120177"},
           {"label": "Merkez", "value": "C120178"},
           {"label": "Oguzlar Ilcesi", "value": "C120179"},
           {"label": "Ortakoy", "value": "C120180"},
           {"label": "Osmancik", "value": "C120181"},
           {"label": "Sungurlu", "value": "C120182"},
           {"label": "Ugurludag", "value": "C120183"}],
 "Cospicua": [{"label": "Cospicua", "value": "C65874"}],
 "Cotopaxi": [{"label": "La Mana", "value": "C24109"},
              {"label": "Latacunga", "value": "C24110"},
              {"label": "Pujili", "value": "C24111"},
              {"label": "San Miguel de Salcedo", "value": "C24112"},
              {"label": "Saquisili", "value": "C24113"}],
 "Couva-Tabaquite-Talparo Regional Corporation": [{"label": "Couva",
                                                   "value": "C119658"},
                                                  {"label": "Tabaquite",
                                                   "value": "C119659"}],
 "Cova Lima Municipality": [{"label": "Fatumean", "value": "C24055"},
                            {"label": "Fohorem", "value": "C24056"},
                            {"label": "Maucatar", "value": "C24057"},
                            {"label": "Suai", "value": "C24058"},
                            {"label": "Tilomar", "value": "C24059"}],
 "Covasna County": [{"label": "Aita Mare", "value": "C94670"},
                    {"label": "Araci", "value": "C94671"},
                    {"label": "Arcus", "value": "C94672"},
                    {"label": "Baraolt", "value": "C94673"},
                    {"label": "Barcani", "value": "C94674"},
                    {"label": "Batanii Mari", "value": "C94675"},
                    {"label": "Belin", "value": "C94676"},
                    {"label": "Belin-Vale", "value": "C94677"},
                    {"label": "Bixad", "value": "C94678"},
                    {"label": "Bodoc", "value": "C94679"},
                    {"label": "Borosneu Mare", "value": "C94680"},
                    {"label": "Bradut", "value": "C94681"},
                    {"label": "Brates", "value": "C94682"},
                    {"label": "Bretcu", "value": "C94683"},
                    {"label": "Capeni", "value": "C94684"},
                    {"label": "Catalina", "value": "C94685"},
                    {"label": "Cernat", "value": "C94686"},
                    {"label": "Chichis", "value": "C94687"},
                    {"label": "Comandau", "value": "C94688"},
                    {"label": "Comuna Aita Mare", "value": "C94689"},
                    {"label": "Comuna Arcus", "value": "C94690"},
                    {"label": "Comuna Barcani", "value": "C94691"},
                    {"label": "Comuna Batani", "value": "C94692"},
                    {"label": "Comuna Belin", "value": "C94693"},
                    {"label": "Comuna Bixad", "value": "C94694"},
                    {"label": "Comuna Bodoc", "value": "C94695"},
                    {"label": "Comuna Borosneu Mare", "value": "C94696"},
                    {"label": "Comuna Bradut", "value": "C94697"},
                    {"label": "Comuna Brates", "value": "C94698"},
                    {"label": "Comuna Bretcu", "value": "C94699"},
                    {"label": "Comuna Catalina", "value": "C94700"},
                    {"label": "Comuna Cernat", "value": "C94701"},
                    {"label": "Comuna Chichis", "value": "C94702"},
                    {"label": "Comuna Comandau", "value": "C94703"},
                    {"label": "Comuna Dalnic", "value": "C94704"},
                    {"label": "Comuna Dobarlau", "value": "C94705"},
                    {"label": "Comuna Estelnic", "value": "C94706"},
                    {"label": "Comuna Ghelinta", "value": "C94707"},
                    {"label": "Comuna Ghidfalau", "value": "C94708"},
                    {"label": "Comuna Haghig", "value": "C94709"},
                    {"label": "Comuna Ilieni", "value": "C94710"},
                    {"label": "Comuna Lemnia", "value": "C94711"},
                    {"label": "Comuna Malnas", "value": "C94712"},
                    {"label": "Comuna Mereni", "value": "C94713"},
                    {"label": "Comuna Micfalau", "value": "C94714"},
                    {"label": "Comuna Moacsa", "value": "C94715"},
                    {"label": "Comuna Ojdula", "value": "C94716"},
                    {"label": "Comuna Ozun", "value": "C94717"},
                    {"label": "Comuna Poian", "value": "C94718"},
                    {"label": "Comuna Reci", "value": "C94719"},
                    {"label": "Comuna Sanzieni", "value": "C94720"},
                    {"label": "Comuna Sita Buzaului", "value": "C94721"},
                    {"label": "Comuna Turia", "value": "C94722"},
                    {"label": "Comuna Valcele", "value": "C94723"},
                    {"label": "Comuna Valea Crisului", "value": "C94724"},
                    {"label": "Comuna Valea Mare", "value": "C94725"},
                    {"label": "Comuna Varghis", "value": "C94726"},
                    {"label": "Comuna Zabala", "value": "C94727"},
                    {"label": "Comuna Zagon", "value": "C94728"},
                    {"label": "Covasna", "value": "C94729"},
                    {"label": "Dalnic", "value": "C94730"},
                    {"label": "Dobarlau", "value": "C94731"},
                    {"label": "Doboseni", "value": "C94732"},
                    {"label": "Estelnic", "value": "C94733"},
                    {"label": "Filia", "value": "C94734"},
                    {"label": "Floroaia", "value": "C94735"},
                    {"label": "Ghelinta", "value": "C94736"},
                    {"label": "Ghidfalau", "value": "C94737"},
                    {"label": "Haghig", "value": "C94738"},
                    {"label": "Herculian", "value": "C94739"},
                    {"label": "Ilieni", "value": "C94740"},
                    {"label": "Intorsura Buzaului", "value": "C94741"},
                    {"label": "Lemnia", "value": "C94742"},
                    {"label": "Lunga", "value": "C94743"},
                    {"label": "Malnas", "value": "C94744"},
                    {"label": "Mereni", "value": "C94745"},
                    {"label": "Micfalau", "value": "C94746"},
                    {"label": "Moacsa", "value": "C94747"},
                    {"label": "Municipiul Sfantu Gheorghe", "value": "C94748"},
                    {"label": "Municipiul Targu Secuiesc", "value": "C94749"},
                    {"label": "Ojdula", "value": "C94750"},
                    {"label": "Oras Baraolt", "value": "C94751"},
                    {"label": "Oras Covasna", "value": "C94752"},
                    {"label": "Oras Intorsura Buzaului", "value": "C94753"},
                    {"label": "Ozun", "value": "C94754"},
                    {"label": "Papauti", "value": "C94755"},
                    {"label": "Poian", "value": "C94756"},
                    {"label": "Reci", "value": "C94757"},
                    {"label": "Sanzieni", "value": "C94758"},
                    {"label": "Sfantu Gheorghe", "value": "C94759"},
                    {"label": "Sita Buzaului", "value": "C94760"},
                    {"label": "Targu Secuiesc", "value": "C94761"},
                    {"label": "Turia", "value": "C94762"},
                    {"label": "Valcele", "value": "C94763"},
                    {"label": "Valea Crisului", "value": "C94764"},
                    {"label": "Valea Mare", "value": "C94765"},
                    {"label": "Varghis", "value": "C94766"},
                    {"label": "Zabala", "value": "C94767"},
                    {"label": "Zagon", "value": "C94768"}],
 "Crensovci Municipality": [{"label": "Crensovci", "value": "C107603"}],
 "Crete Region": [{"label": "Agia Foteini", "value": "C42067"},
                  {"label": "Agia Galini", "value": "C42068"},
                  {"label": "Agia Marina", "value": "C42069"},
                  {"label": "Agia Varvara", "value": "C42070"},
                  {"label": "agioi Deka", "value": "C42071"},
                  {"label": "agios Nikolaos", "value": "C42072"},
                  {"label": "Ano Arhanes", "value": "C42073"},
                  {"label": "Anogeia", "value": "C42074"},
                  {"label": "Arkalochori", "value": "C42075"},
                  {"label": "Asimion", "value": "C42076"},
                  {"label": "Atsipopoulo", "value": "C42077"},
                  {"label": "Chania", "value": "C42078"},
                  {"label": "Chora Sfakion", "value": "C42079"},
                  {"label": "Daratsos", "value": "C42080"},
                  {"label": "Elounda", "value": "C42081"},
                  {"label": "Galatas", "value": "C42082"},
                  {"label": "Gazi", "value": "C42083"},
                  {"label": "Georgioupolis", "value": "C42084"},
                  {"label": "Gerani", "value": "C42085"},
                  {"label": "Gergeri", "value": "C42086"},
                  {"label": "Gournes", "value": "C42087"},
                  {"label": "Gra Liyia", "value": "C42088"},
                  {"label": "Ierapetra", "value": "C42089"},
                  {"label": "Irakleion", "value": "C42090"},
                  {"label": "Kalyves", "value": "C42091"},
                  {"label": "Kastelli", "value": "C42092"},
                  {"label": "Kastri", "value": "C42093"},
                  {"label": "Kato Asitai", "value": "C42094"},
                  {"label": "Kato Gouves", "value": "C42095"},
                  {"label": "Kentri", "value": "C42096"},
                  {"label": "Kissamos", "value": "C42097"},
                  {"label": "Kokkini Chani", "value": "C42098"},
                  {"label": "Kolympari", "value": "C42099"},
                  {"label": "Kritsa", "value": "C42100"},
                  {"label": "Krouson", "value": "C42101"},
                  {"label": "Limin Khersonisou", "value": "C42102"},
                  {"label": "Malia", "value": "C42103"},
                  {"label": "Moires", "value": "C42104"},
                  {"label": "Mokhos", "value": "C42105"},
                  {"label": "Mournies", "value": "C42106"},
                  {"label": "Mouzouras", "value": "C42107"},
                  {"label": "Nea Alikarnassos", "value": "C42108"},
                  {"label": "Nea Anatoli", "value": "C42109"},
                  {"label": "Neapoli", "value": "C42110"},
                  {"label": "Nerokouros", "value": "C42111"},
                  {"label": "Nomos Irakleiou", "value": "C42112"},
                  {"label": "Nomos Rethymnis", "value": "C42113"},
                  {"label": "Palaiochora", "value": "C42114"},
                  {"label": "Palekastro", "value": "C42115"},
                  {"label": "Panormos", "value": "C42116"},
                  {"label": "Perama", "value": "C42117"},
                  {"label": "Perivolia", "value": "C42118"},
                  {"label": "Pithari", "value": "C42119"},
                  {"label": "Profitis Ilias", "value": "C42120"},
                  {"label": "Pyrgos", "value": "C42121"},
                  {"label": "Rethymno", "value": "C42122"},
                  {"label": "Schisma Eloundas", "value": "C42123"},
                  {"label": "Sision", "value": "C42124"},
                  {"label": "Sitia", "value": "C42125"},
                  {"label": "Skalanion", "value": "C42126"},
                  {"label": "Souda", "value": "C42127"},
                  {"label": "Stalis", "value": "C42128"},
                  {"label": "Thrapsanon", "value": "C42129"},
                  {"label": "Tilisos", "value": "C42130"},
                  {"label": "Tsikalaria", "value": "C42131"},
                  {"label": "Tympaki", "value": "C42132"},
                  {"label": "Violi Charaki", "value": "C42133"},
                  {"label": "Vryses", "value": "C42134"},
                  {"label": "Zaros", "value": "C42135"},
                  {"label": "Zoniana", "value": "C42136"}],
 "Criuleni District": [{"label": "Criuleni", "value": "C75506"}],
 "Crna na Koroskem Municipality": [{"label": "Crna na Koroskem",
                                    "value": "C107604"}],
 "Crnomelj Municipality": [{"label": "Crnomelj", "value": "C107605"}],
 "Crooked Island": [{"label": "Colonel Hill", "value": "C119607"}],
 "Cross River": [{"label": "Akankpa", "value": "C78207"},
                 {"label": "Calabar", "value": "C78208"},
                 {"label": "Gakem", "value": "C78209"},
                 {"label": "Ikang", "value": "C78210"},
                 {"label": "Ugep", "value": "C78211"}],
 "Csongrad County": [{"label": "Algyo", "value": "C44016"},
                     {"label": "Apatfalva", "value": "C44017"},
                     {"label": "asotthalom", "value": "C44018"},
                     {"label": "Baks", "value": "C44019"},
                     {"label": "Balastya", "value": "C44020"},
                     {"label": "Bordany", "value": "C44021"},
                     {"label": "Csanadpalota", "value": "C44022"},
                     {"label": "Csanytelek", "value": "C44023"},
                     {"label": "Csengele", "value": "C44024"},
                     {"label": "Csongrad", "value": "C44025"},
                     {"label": "Csongradi Jaras", "value": "C44026"},
                     {"label": "Deszk", "value": "C44027"},
                     {"label": "Domaszek", "value": "C44028"},
                     {"label": "Fabiansebestyen", "value": "C44029"},
                     {"label": "Foldeak", "value": "C44030"},
                     {"label": "Forraskut", "value": "C44031"},
                     {"label": "Hodmezovasarhely", "value": "C44032"},
                     {"label": "Hodmezovasarhelyi Jaras", "value": "C44033"},
                     {"label": "Kistelek", "value": "C44034"},
                     {"label": "Kisteleki Jaras", "value": "C44035"},
                     {"label": "Kiszombor", "value": "C44036"},
                     {"label": "Mako", "value": "C44037"},
                     {"label": "Makoi Jaras", "value": "C44038"},
                     {"label": "Maroslele", "value": "C44039"},
                     {"label": "Mindszent", "value": "C44040"},
                     {"label": "Morahalmi Jaras", "value": "C44041"},
                     {"label": "Morahalom", "value": "C44042"},
                     {"label": "opusztaszer", "value": "C44043"},
                     {"label": "Pusztaszer", "value": "C44044"},
                     {"label": "Roszke", "value": "C44045"},
                     {"label": "Ruzsa", "value": "C44046"},
                     {"label": "Sandorfalva", "value": "C44047"},
                     {"label": "Szatymaz", "value": "C44048"},
                     {"label": "Szeged", "value": "C44049"},
                     {"label": "Szegedi Jaras", "value": "C44050"},
                     {"label": "Szegvar", "value": "C44051"},
                     {"label": "Szekkutas", "value": "C44052"},
                     {"label": "Szentes", "value": "C44053"},
                     {"label": "Szentesi Jaras", "value": "C44054"},
                     {"label": "Tomorkeny", "value": "C44055"},
                     {"label": "ulles", "value": "C44056"},
                     {"label": "Zakanyszek", "value": "C44057"},
                     {"label": "Zsombo", "value": "C44058"}],
 "Cuando Cubango Province": [{"label": "Menongue", "value": "C535"}],
 "Cuanza Norte Province": [{"label": "Camabatela", "value": "C536"},
                           {"label": "Ndalatando", "value": "C537"}],
 "Cuanza Sul": [{"label": "Quibala", "value": "C538"},
                {"label": "Sumbe", "value": "C539"},
                {"label": "Uacu Cungo", "value": "C540"}],
 "Cucer-Sandevo Municipality": [{"label": "Chucher - Sandevo",
                                 "value": "C65387"}],
 "Cundinamarca": [{"label": "Agua de Dios", "value": "C20062"},
                  {"label": "Alban", "value": "C20063"},
                  {"label": "Anapoima", "value": "C20064"},
                  {"label": "Anolaima", "value": "C20065"},
                  {"label": "Apulo", "value": "C20066"},
                  {"label": "Arbelaez", "value": "C20067"},
                  {"label": "Beltran", "value": "C20068"},
                  {"label": "Bituima", "value": "C20069"},
                  {"label": "Bogota D.C.", "value": "C20070"},
                  {"label": "Bojaca", "value": "C20071"},
                  {"label": "Cabrera", "value": "C20072"},
                  {"label": "Cachipay", "value": "C20073"},
                  {"label": "Cajica", "value": "C20074"},
                  {"label": "Caparrapi", "value": "C20075"},
                  {"label": "Caqueza", "value": "C20076"},
                  {"label": "Carmen de Carupa", "value": "C20077"},
                  {"label": "Chaguani", "value": "C20078"},
                  {"label": "Chia", "value": "C20079"},
                  {"label": "Chipaque", "value": "C20080"},
                  {"label": "Choachi", "value": "C20081"},
                  {"label": "Choconta", "value": "C20082"},
                  {"label": "Cogua", "value": "C20083"},
                  {"label": "Cota", "value": "C20084"},
                  {"label": "Cucunuba", "value": "C20085"},
                  {"label": "El Colegio", "value": "C20086"},
                  {"label": "El Penon", "value": "C20087"},
                  {"label": "El Rosal", "value": "C20088"},
                  {"label": "Facatativa", "value": "C20089"},
                  {"label": "Fomeque", "value": "C20090"},
                  {"label": "Fosca", "value": "C20091"},
                  {"label": "Funza", "value": "C20092"},
                  {"label": "Fuquene", "value": "C20093"},
                  {"label": "Fusagasuga", "value": "C20094"},
                  {"label": "Gachala", "value": "C20095"},
                  {"label": "Gachancipa", "value": "C20096"},
                  {"label": "Gacheta", "value": "C20097"},
                  {"label": "Gama", "value": "C20098"},
                  {"label": "Girardot", "value": "C20099"},
                  {"label": "Granada", "value": "C20100"},
                  {"label": "Guacheta", "value": "C20101"},
                  {"label": "Guaduas", "value": "C20102"},
                  {"label": "Guasca", "value": "C20103"},
                  {"label": "Guataqui", "value": "C20104"},
                  {"label": "Guatavita", "value": "C20105"},
                  {"label": "Guayabal de Siquima", "value": "C20106"},
                  {"label": "Guayabetal", "value": "C20107"},
                  {"label": "Gutierrez", "value": "C20108"},
                  {"label": "Jerusalen", "value": "C20109"},
                  {"label": "Junin", "value": "C20110"},
                  {"label": "La Calera", "value": "C20111"},
                  {"label": "La Mesa", "value": "C20112"},
                  {"label": "La Palma", "value": "C20113"},
                  {"label": "La Pena", "value": "C20114"},
                  {"label": "La Vega", "value": "C20115"},
                  {"label": "Lenguazaque", "value": "C20116"},
                  {"label": "Macheta", "value": "C20117"},
                  {"label": "Madrid", "value": "C20118"},
                  {"label": "Manta", "value": "C20119"},
                  {"label": "Medina", "value": "C20120"},
                  {"label": "Mosquera", "value": "C20121"},
                  {"label": "Narino", "value": "C20122"},
                  {"label": "Nemocon", "value": "C20123"},
                  {"label": "Nilo", "value": "C20124"},
                  {"label": "Nimaima", "value": "C20125"},
                  {"label": "Nocaima", "value": "C20126"},
                  {"label": "Pacho", "value": "C20127"},
                  {"label": "Paime", "value": "C20128"},
                  {"label": "Pandi", "value": "C20129"},
                  {"label": "Paratebueno", "value": "C20130"},
                  {"label": "Pasca", "value": "C20131"},
                  {"label": "Puerto Salgar", "value": "C20132"},
                  {"label": "Puli", "value": "C20133"},
                  {"label": "Quebradanegra", "value": "C20134"},
                  {"label": "Quetame", "value": "C20135"},
                  {"label": "Quipile", "value": "C20136"},
                  {"label": "Ricaurte", "value": "C20137"},
                  {"label": "San Antonio del Tequendama", "value": "C20138"},
                  {"label": "San Bernardo", "value": "C20139"},
                  {"label": "San Cayetano", "value": "C20140"},
                  {"label": "San Francisco", "value": "C20141"},
                  {"label": "San Juan de Rio Seco", "value": "C20142"},
                  {"label": "Sasaima", "value": "C20143"},
                  {"label": "Sesquile", "value": "C20144"},
                  {"label": "Sibate", "value": "C20145"},
                  {"label": "Silvania", "value": "C20146"},
                  {"label": "Simijaca", "value": "C20147"},
                  {"label": "Soacha", "value": "C20148"},
                  {"label": "Sopo", "value": "C20149"},
                  {"label": "Subachoque", "value": "C20150"},
                  {"label": "Suesca", "value": "C20151"},
                  {"label": "Supata", "value": "C20152"},
                  {"label": "Susa", "value": "C20153"},
                  {"label": "Sutatausa", "value": "C20154"},
                  {"label": "Tabio", "value": "C20155"},
                  {"label": "Tausa", "value": "C20156"},
                  {"label": "Tena", "value": "C20157"},
                  {"label": "Tenjo", "value": "C20158"},
                  {"label": "Tibacuy", "value": "C20159"},
                  {"label": "Tibirita", "value": "C20160"},
                  {"label": "Tocaima", "value": "C20161"},
                  {"label": "Tocancipa", "value": "C20162"},
                  {"label": "Topaipi", "value": "C20163"},
                  {"label": "Ubala", "value": "C20164"},
                  {"label": "Ubaque", "value": "C20165"},
                  {"label": "Une", "value": "C20166"},
                  {"label": "Utica", "value": "C20167"},
                  {"label": "Venecia", "value": "C20168"},
                  {"label": "Vergara", "value": "C20169"},
                  {"label": "Viani", "value": "C20170"},
                  {"label": "Villa de San Diego de Ubate", "value": "C20171"},
                  {"label": "Villagomez", "value": "C20172"},
                  {"label": "Villapinzon", "value": "C20173"},
                  {"label": "Villeta", "value": "C20174"},
                  {"label": "Viota", "value": "C20175"},
                  {"label": "Yacopi", "value": "C20176"},
                  {"label": "Zipacon", "value": "C20177"},
                  {"label": "Zipaquira", "value": "C20178"}],
 "Cunene Province": [{"label": "Ondjiva", "value": "C541"}],
 "Cuscatlan Department": [{"label": "Cojutepeque", "value": "C24375"},
                          {"label": "San Martin", "value": "C24376"},
                          {"label": "Suchitoto", "value": "C24377"},
                          {"label": "Tecoluca", "value": "C24378"},
                          {"label": "Tenancingo", "value": "C24379"}],
 "Cusco": [{"label": "Anta", "value": "C80742"},
           {"label": "Cahuanuyo", "value": "C80743"},
           {"label": "Calca", "value": "C80744"},
           {"label": "Callanca", "value": "C80745"},
           {"label": "Ccaquiracunca", "value": "C80746"},
           {"label": "Ccuntuma", "value": "C80747"},
           {"label": "Checacupe", "value": "C80748"},
           {"label": "Checca", "value": "C80749"},
           {"label": "Chignayhua", "value": "C80750"},
           {"label": "Chinchero", "value": "C80751"},
           {"label": "Combapata", "value": "C80752"},
           {"label": "Conchopata", "value": "C80753"},
           {"label": "Cullcuyre", "value": "C80754"},
           {"label": "Cusco", "value": "C80755"},
           {"label": "Huayna Alcalde", "value": "C80756"},
           {"label": "Jayobamba", "value": "C80757"},
           {"label": "Langui", "value": "C80758"},
           {"label": "Layo", "value": "C80759"},
           {"label": "Lima Pampa", "value": "C80760"},
           {"label": "Marangani", "value": "C80761"},
           {"label": "Maras", "value": "C80762"},
           {"label": "Mosoc Cancha", "value": "C80763"},
           {"label": "Mosoc Llacta", "value": "C80764"},
           {"label": "Ollantaytambo", "value": "C80765"},
           {"label": "Oropesa", "value": "C80766"},
           {"label": "Pampamarca", "value": "C80767"},
           {"label": "Pangoa", "value": "C80768"},
           {"label": "Paucartambo", "value": "C80769"},
           {"label": "Pisac", "value": "C80770"},
           {"label": "Provincia de Acomayo", "value": "C80771"},
           {"label": "Provincia de Anta", "value": "C80772"},
           {"label": "Provincia de Calca", "value": "C80773"},
           {"label": "Provincia de Canas", "value": "C80774"},
           {"label": "Provincia de Canchis", "value": "C80775"},
           {"label": "Provincia de Chumbivilcas", "value": "C80776"},
           {"label": "Provincia de Cusco", "value": "C80777"},
           {"label": "Provincia de Espinar", "value": "C80778"},
           {"label": "Provincia de La Convencion", "value": "C80779"},
           {"label": "Provincia de Paruro", "value": "C80780"},
           {"label": "Provincia de Paucartambo", "value": "C80781"},
           {"label": "Provincia de Quispicanchis", "value": "C80782"},
           {"label": "Provincia de Urubamba", "value": "C80783"},
           {"label": "Qquea", "value": "C80784"},
           {"label": "Queromarca", "value": "C80785"},
           {"label": "San Pablo", "value": "C80786"},
           {"label": "Santa Ana", "value": "C80787"},
           {"label": "Santo Tomas", "value": "C80788"},
           {"label": "Sicuani", "value": "C80789"},
           {"label": "Tinta", "value": "C80790"},
           {"label": "Tungasuca", "value": "C80791"},
           {"label": "Urcos", "value": "C80792"},
           {"label": "Urubamba", "value": "C80793"},
           {"label": "Yanaoca", "value": "C80794"}],
 "Cuvette Department": [{"label": "Makoua", "value": "C20749"},
                        {"label": "Owando", "value": "C20750"}],
 "Cuvette-Ouest Department": [{"label": "Ewo", "value": "C20751"}],
 "Cuyuni-Mazaruni": [{"label": "Bartica", "value": "C43066"}],
 "DI Yogyakarta": [{"label": "Bambanglipuro", "value": "C49224"},
                   {"label": "Bantul", "value": "C49225"},
                   {"label": "Depok", "value": "C49226"},
                   {"label": "Gamping Lor", "value": "C49227"},
                   {"label": "Godean", "value": "C49228"},
                   {"label": "Kabupaten Bantul", "value": "C49229"},
                   {"label": "Kabupaten Gunung Kidul", "value": "C49230"},
                   {"label": "Kabupaten Kulon Progo", "value": "C49231"},
                   {"label": "Kabupaten Sleman", "value": "C49232"},
                   {"label": "Kasihan", "value": "C49233"},
                   {"label": "Kota Yogyakarta", "value": "C49234"},
                   {"label": "Melati", "value": "C49235"},
                   {"label": "Pandak", "value": "C49236"},
                   {"label": "Pundong", "value": "C49237"},
                   {"label": "Sewon", "value": "C49238"},
                   {"label": "Sleman", "value": "C49239"},
                   {"label": "Srandakan", "value": "C49240"},
                   {"label": "Yogyakarta", "value": "C49241"}],
 "DKI Jakarta": [{"label": "Jakarta", "value": "C49242"},
                 {"label": "Kota Administrasi Jakarta Barat",
                  "value": "C49243"},
                 {"label": "Kota Administrasi Jakarta Pusat",
                  "value": "C49244"},
                 {"label": "Kota Administrasi Jakarta Selatan",
                  "value": "C49245"},
                 {"label": "Kota Administrasi Jakarta Timur",
                  "value": "C49246"},
                 {"label": "Kota Administrasi Jakarta Utara",
                  "value": "C49247"}],
 "Da Nang": [{"label": "Da Nang", "value": "C147347"}],
 "Dadra and Nagar Haveli and Daman and Diu": [{"label": "Amli",
                                               "value": "C45392"},
                                              {"label": "Dadra",
                                               "value": "C45393"},
                                              {"label": "Dadra & Nagar Haveli",
                                               "value": "C45394"},
                                              {"label": "Daman",
                                               "value": "C45395"},
                                              {"label": "Daman District",
                                               "value": "C45396"},
                                              {"label": "Diu",
                                               "value": "C45397"},
                                              {"label": "Silvassa",
                                               "value": "C45398"}],
 "Daegu": [{"label": "Daegu", "value": "C108255"},
           {"label": "Dalseo-gu", "value": "C108256"},
           {"label": "Dalseong-gun", "value": "C108257"},
           {"label": "Dong-gu", "value": "C108258"},
           {"label": "Hwawon", "value": "C108259"},
           {"label": "Jung-gu", "value": "C108260"},
           {"label": "Seo-gu", "value": "C108261"},
           {"label": "Suseong-gu", "value": "C108262"}],
 "Daejeon": [{"label": "Daejeon", "value": "C108263"},
             {"label": "Seo-gu", "value": "C108264"},
             {"label": "Sintansin", "value": "C108265"},
             {"label": "Songgangdong", "value": "C108266"},
             {"label": "Yuseong-gu", "value": "C108267"}],
 "Dagana District": [{"label": "Daga", "value": "C9634"},
                     {"label": "Wangdue Phodrang", "value": "C9635"}],
 "Dagda Municipality": [{"label": "Dagda", "value": "C64860"}],
 "Dajabon Province": [{"label": "Dajabon", "value": "C23868"},
                      {"label": "El Pino", "value": "C23869"},
                      {"label": "Loma de Cabrera", "value": "C23870"},
                      {"label": "Partido", "value": "C23871"},
                      {"label": "Restauracion", "value": "C23872"}],
 "Dak Lak": [{"label": "Buon Ma Thuot", "value": "C147348"},
             {"label": "Huyen Buon Don", "value": "C147349"},
             {"label": "Huyen Ea HLeo", "value": "C147350"},
             {"label": "Huyen Ea Sup", "value": "C147351"}],
 "Dak Nong": [{"label": "Huyen Cu Jut", "value": "C147352"},
              {"label": "Huyen Dak RLap", "value": "C147353"},
              {"label": "Huyen Krong No", "value": "C147354"}],
 "Dakahlia": [{"label": "Izbat al Burj", "value": "C24251"},
              {"label": "Aja", "value": "C24252"},
              {"label": "Al Jammaliyah", "value": "C24253"},
              {"label": "Al Mansurah", "value": "C24254"},
              {"label": "Al Manzalah", "value": "C24255"},
              {"label": "Al Matariyah", "value": "C24256"},
              {"label": "Bilqas", "value": "C24257"},
              {"label": "Dikirnis", "value": "C24258"},
              {"label": "Minyat an Nasr", "value": "C24259"},
              {"label": "Shirbin", "value": "C24260"},
              {"label": "Talkha", "value": "C24261"}],
 "Dakar": [{"label": "Dakar", "value": "C106822"},
           {"label": "Dakar Department", "value": "C106823"},
           {"label": "Guediawaye Department", "value": "C106824"},
           {"label": "Mermoz Boabab", "value": "C106825"},
           {"label": "Ndiareme limamoulaye", "value": "C106826"},
           {"label": "Pikine", "value": "C106827"},
           {"label": "Pikine Department", "value": "C106828"},
           {"label": "Rufisque Department", "value": "C106829"}],
 "Dakhlet Nouadhibou": [{"label": "Cansado", "value": "C65947"},
                        {"label": "Iouik", "value": "C65948"},
                        {"label": "Nouadhibou", "value": "C65949"}],
 "Dalarna County": [{"label": "Abborrberget", "value": "C115524"},
                    {"label": "Alvdalen", "value": "C115525"},
                    {"label": "Avesta", "value": "C115526"},
                    {"label": "Bjursas", "value": "C115527"},
                    {"label": "Borlange", "value": "C115528"},
                    {"label": "Brunna", "value": "C115529"},
                    {"label": "Djuras", "value": "C115530"},
                    {"label": "Enbacka", "value": "C115531"},
                    {"label": "Falun", "value": "C115532"},
                    {"label": "Gagnef", "value": "C115533"},
                    {"label": "Grangesberg", "value": "C115534"},
                    {"label": "Grycksbo", "value": "C115535"},
                    {"label": "Hedemora", "value": "C115536"},
                    {"label": "Horndal", "value": "C115537"},
                    {"label": "Insjon", "value": "C115538"},
                    {"label": "Jarna", "value": "C115539"},
                    {"label": "Krylbo", "value": "C115540"},
                    {"label": "Langshyttan", "value": "C115541"},
                    {"label": "Leksand", "value": "C115542"},
                    {"label": "Ludvika", "value": "C115543"},
                    {"label": "Malung", "value": "C115544"},
                    {"label": "Mockfjard", "value": "C115545"},
                    {"label": "Mora", "value": "C115546"},
                    {"label": "Nyhammar", "value": "C115547"},
                    {"label": "Ornas", "value": "C115548"},
                    {"label": "Orsa", "value": "C115549"},
                    {"label": "Rattvik", "value": "C115550"},
                    {"label": "Romme", "value": "C115551"},
                    {"label": "Sater", "value": "C115552"},
                    {"label": "Siljansnas", "value": "C115553"},
                    {"label": "Smedby", "value": "C115554"},
                    {"label": "Smedjebacken", "value": "C115555"},
                    {"label": "Svardsjo", "value": "C115556"},
                    {"label": "Vansbro", "value": "C115557"},
                    {"label": "Vikarbyn", "value": "C115558"}],
 "Damascus Governorate": [{"label": "Damascus", "value": "C117853"}],
 "Dambovita County": [{"label": "Adanca", "value": "C94769"},
                      {"label": "Aninoasa", "value": "C94770"},
                      {"label": "Badeni", "value": "C94771"},
                      {"label": "Baldana", "value": "C94772"},
                      {"label": "Baleni Sarbi", "value": "C94773"},
                      {"label": "Balteni", "value": "C94774"},
                      {"label": "Barbuletu", "value": "C94775"},
                      {"label": "Bezdead", "value": "C94776"},
                      {"label": "Bilciuresti", "value": "C94777"},
                      {"label": "Branesti", "value": "C94778"},
                      {"label": "Branistea", "value": "C94779"},
                      {"label": "Brezoaele", "value": "C94780"},
                      {"label": "Brezoaia", "value": "C94781"},
                      {"label": "Brosteni", "value": "C94782"},
                      {"label": "Buciumeni", "value": "C94783"},
                      {"label": "Bucsani", "value": "C94784"},
                      {"label": "Bungetu", "value": "C94785"},
                      {"label": "Butimanu", "value": "C94786"},
                      {"label": "Candesti Vale", "value": "C94787"},
                      {"label": "Caprioru", "value": "C94788"},
                      {"label": "Cazaci", "value": "C94789"},
                      {"label": "Ciocanari", "value": "C94790"},
                      {"label": "Ciocanesti", "value": "C94791"},
                      {"label": "Cojasca", "value": "C94792"},
                      {"label": "Colacu", "value": "C94793"},
                      {"label": "Comisani", "value": "C94794"},
                      {"label": "Comuna Aninoasa", "value": "C94795"},
                      {"label": "Comuna Baleni Sarbi", "value": "C94796"},
                      {"label": "Comuna Barbuletu", "value": "C94797"},
                      {"label": "Comuna Bezdead", "value": "C94798"},
                      {"label": "Comuna Bilciuresti", "value": "C94799"},
                      {"label": "Comuna Branesti", "value": "C94800"},
                      {"label": "Comuna Branistea", "value": "C94801"},
                      {"label": "Comuna Brezoaele", "value": "C94802"},
                      {"label": "Comuna Buciumeni", "value": "C94803"},
                      {"label": "Comuna Bucsani", "value": "C94804"},
                      {"label": "Comuna Butimanu", "value": "C94805"},
                      {"label": "Comuna Candesti Vale", "value": "C94806"},
                      {"label": "Comuna Ciocanesti", "value": "C94807"},
                      {"label": "Comuna Cobia de Sus", "value": "C94808"},
                      {"label": "Comuna Cojasca", "value": "C94809"},
                      {"label": "Comuna Comisani", "value": "C94810"},
                      {"label": "Comuna Contesti", "value": "C94811"},
                      {"label": "Comuna Corbii Mari", "value": "C94812"},
                      {"label": "Comuna Cornatelu", "value": "C94813"},
                      {"label": "Comuna Cornesti", "value": "C94814"},
                      {"label": "Comuna Costestii din Vale", "value": "C94815"},
                      {"label": "Comuna Crangurile", "value": "C94816"},
                      {"label": "Comuna Crevedia", "value": "C94817"},
                      {"label": "Comuna Darmanesti", "value": "C94818"},
                      {"label": "Comuna Dobra", "value": "C94819"},
                      {"label": "Comuna Doicesti", "value": "C94820"},
                      {"label": "Comuna Dragodana", "value": "C94821"},
                      {"label": "Comuna Dragomiresti", "value": "C94822"},
                      {"label": "Comuna Finta", "value": "C94823"},
                      {"label": "Comuna Glodeni", "value": "C94824"},
                      {"label": "Comuna Gura Foii", "value": "C94825"},
                      {"label": "Comuna Gura Ocnitei", "value": "C94826"},
                      {"label": "Comuna Gura Sutii", "value": "C94827"},
                      {"label": "Comuna Hulubesti", "value": "C94828"},
                      {"label": "Comuna I. L. Caragiale", "value": "C94829"},
                      {"label": "Comuna Iedera", "value": "C94830"},
                      {"label": "Comuna Lucieni", "value": "C94831"},
                      {"label": "Comuna Ludesti", "value": "C94832"},
                      {"label": "Comuna Lunguletu", "value": "C94833"},
                      {"label": "Comuna Malu cu Flori", "value": "C94834"},
                      {"label": "Comuna Manesti", "value": "C94835"},
                      {"label": "Comuna Matasaru", "value": "C94836"},
                      {"label": "Comuna Mogosani", "value": "C94837"},
                      {"label": "Comuna Moroeni", "value": "C94838"},
                      {"label": "Comuna Morteni", "value": "C94839"},
                      {"label": "Comuna Motaieni", "value": "C94840"},
                      {"label": "Comuna Niculesti", "value": "C94841"},
                      {"label": "Comuna Nucet", "value": "C94842"},
                      {"label": "Comuna Ocnita", "value": "C94843"},
                      {"label": "Comuna Odobesti", "value": "C94844"},
                      {"label": "Comuna Persinari", "value": "C94845"},
                      {"label": "Comuna Petresti", "value": "C94846"},
                      {"label": "Comuna Pietrari", "value": "C94847"},
                      {"label": "Comuna Pietrosita", "value": "C94848"},
                      {"label": "Comuna Poiana", "value": "C94849"},
                      {"label": "Comuna Potlogi", "value": "C94850"},
                      {"label": "Comuna Produlesti", "value": "C94851"},
                      {"label": "Comuna Pucheni", "value": "C94852"},
                      {"label": "Comuna Raciu", "value": "C94853"},
                      {"label": "Comuna Rascaeti", "value": "C94854"},
                      {"label": "Comuna Rau Alb", "value": "C94855"},
                      {"label": "Comuna Razvad", "value": "C94856"},
                      {"label": "Comuna Runcu", "value": "C94857"},
                      {"label": "Comuna Salcioara", "value": "C94858"},
                      {"label": "Comuna Selaru", "value": "C94859"},
                      {"label": "Comuna Slobozia Moara", "value": "C94860"},
                      {"label": "Comuna Sotanga", "value": "C94861"},
                      {"label": "Comuna Tartasesti", "value": "C94862"},
                      {"label": "Comuna Tatarani", "value": "C94863"},
                      {"label": "Comuna Uliesti", "value": "C94864"},
                      {"label": "Comuna Ulmi", "value": "C94865"},
                      {"label": "Comuna Vacaresti", "value": "C94866"},
                      {"label": "Comuna Valea Lunga", "value": "C94867"},
                      {"label": "Comuna Valea Mare", "value": "C94868"},
                      {"label": "Comuna Valeni-Dambovita", "value": "C94869"},
                      {"label": "Comuna Varfuri", "value": "C94870"},
                      {"label": "Comuna Visina", "value": "C94871"},
                      {"label": "Comuna Visinesti", "value": "C94872"},
                      {"label": "Comuna Vladeni", "value": "C94873"},
                      {"label": "Comuna Voinesti", "value": "C94874"},
                      {"label": "Comuna Vulcana Bai", "value": "C94875"},
                      {"label": "Comuna Vulcana-Pandele", "value": "C94876"},
                      {"label": "Contesti", "value": "C94877"},
                      {"label": "Corbii Mari", "value": "C94878"},
                      {"label": "Cornatelu", "value": "C94879"},
                      {"label": "Cornesti", "value": "C94880"},
                      {"label": "Costestii din Deal", "value": "C94881"},
                      {"label": "Costestii din Vale", "value": "C94882"},
                      {"label": "Crangurile de Sus", "value": "C94883"},
                      {"label": "Crevedia", "value": "C94884"},
                      {"label": "Croitori", "value": "C94885"},
                      {"label": "Crovu", "value": "C94886"},
                      {"label": "Dambovicioara", "value": "C94887"},
                      {"label": "Darmanesti", "value": "C94888"},
                      {"label": "Darza", "value": "C94889"},
                      {"label": "Dealu Frumos", "value": "C94890"},
                      {"label": "Dealu Mare", "value": "C94891"},
                      {"label": "Decindeni", "value": "C94892"},
                      {"label": "Dobra", "value": "C94893"},
                      {"label": "Doicesti", "value": "C94894"},
                      {"label": "Dragaesti Ungureni", "value": "C94895"},
                      {"label": "Dragodana", "value": "C94896"},
                      {"label": "Dragodanesti", "value": "C94897"},
                      {"label": "Dragomiresti", "value": "C94898"},
                      {"label": "Fantanele", "value": "C94899"},
                      {"label": "Fieni", "value": "C94900"},
                      {"label": "Fierbinti", "value": "C94901"},
                      {"label": "Finta Mare", "value": "C94902"},
                      {"label": "Gaesti", "value": "C94903"},
                      {"label": "Gemenea Bratulesti", "value": "C94904"},
                      {"label": "Gheboaia", "value": "C94905"},
                      {"label": "Gheboieni", "value": "C94906"},
                      {"label": "Ghinesti", "value": "C94907"},
                      {"label": "Ghirdoveni", "value": "C94908"},
                      {"label": "Glod", "value": "C94909"},
                      {"label": "Glodeni", "value": "C94910"},
                      {"label": "Gorgota", "value": "C94911"},
                      {"label": "Greci", "value": "C94912"},
                      {"label": "Grozavesti", "value": "C94913"},
                      {"label": "Gulia", "value": "C94914"},
                      {"label": "Gura Foii", "value": "C94915"},
                      {"label": "Gura Ocnitei", "value": "C94916"},
                      {"label": "Gura Sutii", "value": "C94917"},
                      {"label": "Gura Vulcanei", "value": "C94918"},
                      {"label": "Habeni", "value": "C94919"},
                      {"label": "Hulubesti", "value": "C94920"},
                      {"label": "I. L. Caragiale", "value": "C94921"},
                      {"label": "Iazu", "value": "C94922"},
                      {"label": "Ibrianu", "value": "C94923"},
                      {"label": "Iedera de Jos", "value": "C94924"},
                      {"label": "Iedera de Sus", "value": "C94925"},
                      {"label": "Ionesti", "value": "C94926"},
                      {"label": "Izvoarele", "value": "C94927"},
                      {"label": "Laculete", "value": "C94928"},
                      {"label": "Lazuri", "value": "C94929"},
                      {"label": "Lucieni", "value": "C94930"},
                      {"label": "Ludesti", "value": "C94931"},
                      {"label": "Lunca", "value": "C94932"},
                      {"label": "Lunguletu", "value": "C94933"},
                      {"label": "Malu cu Flori", "value": "C94934"},
                      {"label": "Manesti", "value": "C94935"},
                      {"label": "Marcesti", "value": "C94936"},
                      {"label": "Marginenii de Sus", "value": "C94937"},
                      {"label": "Matasaru", "value": "C94938"},
                      {"label": "Mavrodin", "value": "C94939"},
                      {"label": "Mija", "value": "C94940"},
                      {"label": "Mogosani", "value": "C94941"},
                      {"label": "Moreni", "value": "C94942"},
                      {"label": "Moroeni", "value": "C94943"},
                      {"label": "Morteni", "value": "C94944"},
                      {"label": "Motaieni", "value": "C94945"},
                      {"label": "Municipiul Moreni", "value": "C94946"},
                      {"label": "Municipiul Targoviste", "value": "C94947"},
                      {"label": "Niculesti", "value": "C94948"},
                      {"label": "Nucet", "value": "C94949"},
                      {"label": "Ocnita", "value": "C94950"},
                      {"label": "Odaia Turcului", "value": "C94951"},
                      {"label": "Odobesti", "value": "C94952"},
                      {"label": "Oras Fieni", "value": "C94953"},
                      {"label": "Oras Gaesti", "value": "C94954"},
                      {"label": "Oras Pucioasa", "value": "C94955"},
                      {"label": "Oras Racari", "value": "C94956"},
                      {"label": "Oras Titu", "value": "C94957"},
                      {"label": "Persinari", "value": "C94958"},
                      {"label": "Petresti", "value": "C94959"},
                      {"label": "Picior de Munte", "value": "C94960"},
                      {"label": "Pietrari", "value": "C94961"},
                      {"label": "Pietrosita", "value": "C94962"},
                      {"label": "Pitaru", "value": "C94963"},
                      {"label": "Poiana", "value": "C94964"},
                      {"label": "Potlogi", "value": "C94965"},
                      {"label": "Priboiu", "value": "C94966"},
                      {"label": "Produlesti", "value": "C94967"},
                      {"label": "Pucheni", "value": "C94968"},
                      {"label": "Pucioasa", "value": "C94969"},
                      {"label": "Racari", "value": "C94970"},
                      {"label": "Raciu", "value": "C94971"},
                      {"label": "Racovita", "value": "C94972"},
                      {"label": "Rancaciov", "value": "C94973"},
                      {"label": "Rascaeti", "value": "C94974"},
                      {"label": "Rau Alb de Jos", "value": "C94975"},
                      {"label": "Razvad", "value": "C94976"},
                      {"label": "Romanesti", "value": "C94977"},
                      {"label": "Runcu", "value": "C94978"},
                      {"label": "Sacueni", "value": "C94979"},
                      {"label": "Salcioara", "value": "C94980"},
                      {"label": "Salcuta", "value": "C94981"},
                      {"label": "Sateni", "value": "C94982"},
                      {"label": "Scheiu de Jos", "value": "C94983"},
                      {"label": "Scheiu de Sus", "value": "C94984"},
                      {"label": "Selaru", "value": "C94985"},
                      {"label": "Serdanu", "value": "C94986"},
                      {"label": "Slobozia Moara", "value": "C94987"},
                      {"label": "Sotanga", "value": "C94988"},
                      {"label": "Sperieteni", "value": "C94989"},
                      {"label": "Suseni Socetu", "value": "C94990"},
                      {"label": "Targoviste", "value": "C94991"},
                      {"label": "Tartasesti", "value": "C94992"},
                      {"label": "Tatarani", "value": "C94993"},
                      {"label": "Teis", "value": "C94994"},
                      {"label": "Tetcoiu", "value": "C94995"},
                      {"label": "Titu", "value": "C94996"},
                      {"label": "Uliesti", "value": "C94997"},
                      {"label": "Ulmi", "value": "C94998"},
                      {"label": "Ungureni", "value": "C94999"},
                      {"label": "Urziceanca", "value": "C95000"},
                      {"label": "Vacaresti", "value": "C95001"},
                      {"label": "Valea Leurzii", "value": "C95002"},
                      {"label": "Valea Lunga Ogrea", "value": "C95003"},
                      {"label": "Valea Lunga-Cricov", "value": "C95004"},
                      {"label": "Valea Mare", "value": "C95005"},
                      {"label": "Valea Voievozilor", "value": "C95006"},
                      {"label": "Valeni-Dambovita", "value": "C95007"},
                      {"label": "Varfuri", "value": "C95008"},
                      {"label": "Viforata", "value": "C95009"},
                      {"label": "Viisoara", "value": "C95010"},
                      {"label": "Visina", "value": "C95011"},
                      {"label": "Visinesti", "value": "C95012"},
                      {"label": "Vizuresti", "value": "C95013"},
                      {"label": "Vladeni", "value": "C95014"},
                      {"label": "Vlasceni", "value": "C95015"},
                      {"label": "Voinesti", "value": "C95016"},
                      {"label": "Vulcana Bai", "value": "C95017"},
                      {"label": "Vulcana de Sus", "value": "C95018"},
                      {"label": "Vulcana-Pandele", "value": "C95019"},
                      {"label": "Zavoiu", "value": "C95020"},
                      {"label": "Zidurile", "value": "C95021"}],
 "Damietta": [{"label": "Az Zarqa", "value": "C24262"},
              {"label": "Damietta", "value": "C24263"},
              {"label": "Faraskur", "value": "C24264"}],
 "Danilovgrad Municipality": [{"label": "Danilovgrad", "value": "C75603"},
                              {"label": "Spuz", "value": "C75604"}],
 "Dar es Salaam": [{"label": "Dar es Salaam", "value": "C118079"},
                   {"label": "Magomeni", "value": "C118080"}],
 "Daraa Governorate": [{"label": "Al Harak", "value": "C117854"},
                       {"label": "Al Muzayrib", "value": "C117855"},
                       {"label": "Al-Sanamayn District", "value": "C117856"},
                       {"label": "As Sanamayn", "value": "C117857"},
                       {"label": "Ash Shaykh Miskin", "value": "C117858"},
                       {"label": "Busra ash Sham", "value": "C117859"},
                       {"label": "Dara", "value": "C117860"},
                       {"label": "Ghabaghib", "value": "C117861"},
                       {"label": "Inkhil", "value": "C117862"},
                       {"label": "Izra District", "value": "C117863"},
                       {"label": "Izra", "value": "C117864"},
                       {"label": "Jasim", "value": "C117865"},
                       {"label": "Nawa", "value": "C117866"},
                       {"label": "Tafas", "value": "C117867"},
                       {"label": "Tasil", "value": "C117868"}],
 "Darien Province": [{"label": "Boca de Cupe", "value": "C80010"},
                     {"label": "Camoganti", "value": "C80011"},
                     {"label": "Cucunati", "value": "C80012"},
                     {"label": "Distrito de Chepigana", "value": "C80013"},
                     {"label": "Distrito de Pinogana", "value": "C80014"},
                     {"label": "Garachine", "value": "C80015"},
                     {"label": "Jaque", "value": "C80016"},
                     {"label": "La Palma", "value": "C80017"},
                     {"label": "Meteti", "value": "C80018"},
                     {"label": "Morti", "value": "C80019"},
                     {"label": "Pucuro", "value": "C80020"},
                     {"label": "Santa Fe", "value": "C80021"},
                     {"label": "Yaviza", "value": "C80022"}],
 "Darkhan-Uul Province": [{"label": "Darhan", "value": "C75562"}],
 "Dashkasan District": [{"label": "Verkhniy Dashkesan", "value": "C8423"},
                        {"label": "Yukhary-Dashkesan", "value": "C8424"}],
 "Dasoguz Region": [{"label": "Akdepe", "value": "C121174"},
                    {"label": "Boldumsaz", "value": "C121175"},
                    {"label": "Dasoguz", "value": "C121176"},
                    {"label": "Koneurgench", "value": "C121177"},
                    {"label": "Tagta", "value": "C121178"},
                    {"label": "Yylanly", "value": "C121179"}],
 "Daugavpils Municipality": [{"label": "Daugavpils", "value": "C64861"}],
 "Davao Occidental": [{"label": "Andili", "value": "C85648"},
                      {"label": "Andop", "value": "C85649"},
                      {"label": "Anibongan", "value": "C85650"},
                      {"label": "Astorga", "value": "C85651"},
                      {"label": "Asuncion", "value": "C85652"},
                      {"label": "Babag", "value": "C85653"},
                      {"label": "Baculin", "value": "C85654"},
                      {"label": "Baganga", "value": "C85655"},
                      {"label": "Balagunan", "value": "C85656"},
                      {"label": "Balangonan", "value": "C85657"},
                      {"label": "Balutakay", "value": "C85658"},
                      {"label": "Banaybanay", "value": "C85659"},
                      {"label": "Bansalan", "value": "C85660"},
                      {"label": "Bantacan", "value": "C85661"},
                      {"label": "Baon", "value": "C85662"},
                      {"label": "Baracatan", "value": "C85663"},
                      {"label": "Basiawan", "value": "C85664"},
                      {"label": "Batiano", "value": "C85665"},
                      {"label": "Bato", "value": "C85666"},
                      {"label": "Batobato", "value": "C85667"},
                      {"label": "Baylo", "value": "C85668"},
                      {"label": "Biao", "value": "C85669"},
                      {"label": "Bincongan", "value": "C85670"},
                      {"label": "Bitaogan", "value": "C85671"},
                      {"label": "Bobon", "value": "C85672"},
                      {"label": "Bolila", "value": "C85673"},
                      {"label": "Boston", "value": "C85674"},
                      {"label": "Buclad", "value": "C85675"},
                      {"label": "Buhangin", "value": "C85676"},
                      {"label": "Bukid", "value": "C85677"},
                      {"label": "Bulacan", "value": "C85678"},
                      {"label": "Bungabon", "value": "C85679"},
                      {"label": "Butulan", "value": "C85680"},
                      {"label": "Cabayangan", "value": "C85681"},
                      {"label": "Cabinuangan", "value": "C85682"},
                      {"label": "Caburan", "value": "C85683"},
                      {"label": "Cambanugoy", "value": "C85684"},
                      {"label": "Camudmud", "value": "C85685"},
                      {"label": "Caraga", "value": "C85686"},
                      {"label": "Carmen", "value": "C85687"},
                      {"label": "Cateel", "value": "C85688"},
                      {"label": "City of Mati", "value": "C85689"},
                      {"label": "City of Panabo", "value": "C85690"},
                      {"label": "City of Tagum", "value": "C85691"},
                      {"label": "Cogon", "value": "C85692"},
                      {"label": "Concepcion", "value": "C85693"},
                      {"label": "Corocotan", "value": "C85694"},
                      {"label": "Coronon", "value": "C85695"},
                      {"label": "Cuambog", "value": "C85696"},
                      {"label": "Culaman", "value": "C85697"},
                      {"label": "Dacudao", "value": "C85698"},
                      {"label": "Dakbayan sa Digos", "value": "C85699"},
                      {"label": "Davan", "value": "C85700"},
                      {"label": "Davao City", "value": "C85701"},
                      {"label": "Del Pilar", "value": "C85702"},
                      {"label": "Digos", "value": "C85703"},
                      {"label": "Dolo", "value": "C85704"},
                      {"label": "Don Marcelino", "value": "C85705"},
                      {"label": "Dumlan", "value": "C85706"},
                      {"label": "Esperanza", "value": "C85707"},
                      {"label": "Gabi", "value": "C85708"},
                      {"label": "Gabuyan", "value": "C85709"},
                      {"label": "Goma", "value": "C85710"},
                      {"label": "Governor Generoso", "value": "C85711"},
                      {"label": "Guihing Proper", "value": "C85712"},
                      {"label": "Gumalang", "value": "C85713"},
                      {"label": "Gupitan", "value": "C85714"},
                      {"label": "Hagonoy", "value": "C85715"},
                      {"label": "Ignit", "value": "C85716"},
                      {"label": "Ilangay", "value": "C85717"},
                      {"label": "Inawayan", "value": "C85718"},
                      {"label": "Island Garden City of Samal",
                       "value": "C85719"},
                      {"label": "Jose Abad Santos", "value": "C85720"},
                      {"label": "Jovellar", "value": "C85721"},
                      {"label": "Kalbay", "value": "C85722"},
                      {"label": "Kalian", "value": "C85723"},
                      {"label": "Kaligutan", "value": "C85724"},
                      {"label": "Kapalong", "value": "C85725"},
                      {"label": "Katipunan", "value": "C85726"},
                      {"label": "Kiblawan", "value": "C85727"},
                      {"label": "Kinablangan", "value": "C85728"},
                      {"label": "Kinamayan", "value": "C85729"},
                      {"label": "Kinangan", "value": "C85730"},
                      {"label": "La Libertad", "value": "C85731"},
                      {"label": "La Paz", "value": "C85732"},
                      {"label": "La Union", "value": "C85733"},
                      {"label": "Lacaron", "value": "C85734"},
                      {"label": "Lacson", "value": "C85735"},
                      {"label": "Lais", "value": "C85736"},
                      {"label": "Lamitan", "value": "C85737"},
                      {"label": "Lapuan", "value": "C85738"},
                      {"label": "Lasang", "value": "C85739"},
                      {"label": "Libuganon", "value": "C85740"},
                      {"label": "Limao", "value": "C85741"},
                      {"label": "Limot", "value": "C85742"},
                      {"label": "Linao", "value": "C85743"},
                      {"label": "Linoan", "value": "C85744"},
                      {"label": "Lukatan", "value": "C85745"},
                      {"label": "Luna", "value": "C85746"},
                      {"label": "Lungaog", "value": "C85747"},
                      {"label": "Lupon", "value": "C85748"},
                      {"label": "Luzon", "value": "C85749"},
                      {"label": "Mabini", "value": "C85750"},
                      {"label": "Mabuhay", "value": "C85751"},
                      {"label": "Maco", "value": "C85752"},
                      {"label": "Maduao", "value": "C85753"},
                      {"label": "Magatos", "value": "C85754"},
                      {"label": "Magdug", "value": "C85755"},
                      {"label": "Magnaga", "value": "C85756"},
                      {"label": "Magsaysay", "value": "C85757"},
                      {"label": "Mahanob", "value": "C85758"},
                      {"label": "Mahayag", "value": "C85759"},
                      {"label": "Malagos", "value": "C85760"},
                      {"label": "Malalag", "value": "C85761"},
                      {"label": "Malinao", "value": "C85762"},
                      {"label": "Malita", "value": "C85763"},
                      {"label": "Mambago", "value": "C85764"},
                      {"label": "Managa", "value": "C85765"},
                      {"label": "Manaloal", "value": "C85766"},
                      {"label": "Manat", "value": "C85767"},
                      {"label": "Manay", "value": "C85768"},
                      {"label": "Mangili", "value": "C85769"},
                      {"label": "Manikling", "value": "C85770"},
                      {"label": "Matanao", "value": "C85771"},
                      {"label": "Mati", "value": "C85772"},
                      {"label": "Matiao", "value": "C85773"},
                      {"label": "Matti", "value": "C85774"},
                      {"label": "Mawab", "value": "C85775"},
                      {"label": "Mayo", "value": "C85776"},
                      {"label": "Monkayo", "value": "C85777"},
                      {"label": "Montevista", "value": "C85778"},
                      {"label": "Nabunturan", "value": "C85779"},
                      {"label": "Nangan", "value": "C85780"},
                      {"label": "Nanyo", "value": "C85781"},
                      {"label": "New Baclayon", "value": "C85782"},
                      {"label": "New Bataan", "value": "C85783"},
                      {"label": "New Bohol", "value": "C85784"},
                      {"label": "New Corella", "value": "C85785"},
                      {"label": "New Leyte", "value": "C85786"},
                      {"label": "New Sibonga", "value": "C85787"},
                      {"label": "New Visayas", "value": "C85788"},
                      {"label": "Nuing", "value": "C85789"},
                      {"label": "Padada", "value": "C85790"},
                      {"label": "Pag-asa", "value": "C85791"},
                      {"label": "Pagsabangan", "value": "C85792"},
                      {"label": "Palma Gil", "value": "C85793"},
                      {"label": "Panabo", "value": "C85794"},
                      {"label": "Pandasan", "value": "C85795"},
                      {"label": "Pangian", "value": "C85796"},
                      {"label": "Panikian", "value": "C85797"},
                      {"label": "Pantukan", "value": "C85798"},
                      {"label": "Pasian", "value": "C85799"},
                      {"label": "Pondaguitan", "value": "C85800"},
                      {"label": "Pung-Pang", "value": "C85801"},
                      {"label": "Samal", "value": "C85802"},
                      {"label": "Sampao", "value": "C85803"},
                      {"label": "San Alfonso", "value": "C85804"},
                      {"label": "San Antonio", "value": "C85805"},
                      {"label": "San Ignacio", "value": "C85806"},
                      {"label": "San Isidro", "value": "C85807"},
                      {"label": "San Luis", "value": "C85808"},
                      {"label": "San Mariano", "value": "C85809"},
                      {"label": "San Miguel", "value": "C85810"},
                      {"label": "San Pedro", "value": "C85811"},
                      {"label": "San Rafael", "value": "C85812"},
                      {"label": "San Remigio", "value": "C85813"},
                      {"label": "San Vicente", "value": "C85814"},
                      {"label": "Santa Cruz", "value": "C85815"},
                      {"label": "Santa Maria", "value": "C85816"},
                      {"label": "Santiago", "value": "C85817"},
                      {"label": "Santo Nino", "value": "C85818"},
                      {"label": "Santo Tomas", "value": "C85819"},
                      {"label": "Sarangani", "value": "C85820"},
                      {"label": "Sibulan", "value": "C85821"},
                      {"label": "Sigaboy", "value": "C85822"},
                      {"label": "Simod", "value": "C85823"},
                      {"label": "Sinawilan", "value": "C85824"},
                      {"label": "Sinayawan", "value": "C85825"},
                      {"label": "Sirib", "value": "C85826"},
                      {"label": "Sugal", "value": "C85827"},
                      {"label": "Sulop", "value": "C85828"},
                      {"label": "Surup", "value": "C85829"},
                      {"label": "Suz-on", "value": "C85830"},
                      {"label": "Tagakpan", "value": "C85831"},
                      {"label": "Tagdanua", "value": "C85832"},
                      {"label": "Tagnanan", "value": "C85833"},
                      {"label": "Tagum", "value": "C85834"},
                      {"label": "Takub", "value": "C85835"},
                      {"label": "Talagutong", "value": "C85836"},
                      {"label": "Talisay", "value": "C85837"},
                      {"label": "Talomo", "value": "C85838"},
                      {"label": "Tamayong", "value": "C85839"},
                      {"label": "Tambo", "value": "C85840"},
                      {"label": "Tamisan", "value": "C85841"},
                      {"label": "Tamugan", "value": "C85842"},
                      {"label": "Tanlad", "value": "C85843"},
                      {"label": "Tapia", "value": "C85844"},
                      {"label": "Tarragona", "value": "C85845"},
                      {"label": "Tawan tawan", "value": "C85846"},
                      {"label": "Taytayan", "value": "C85847"},
                      {"label": "Tibagon", "value": "C85848"},
                      {"label": "Tibanbang", "value": "C85849"},
                      {"label": "Tiblawan", "value": "C85850"},
                      {"label": "Tombongon", "value": "C85851"},
                      {"label": "Tubalan", "value": "C85852"},
                      {"label": "Tuban", "value": "C85853"},
                      {"label": "Tubod", "value": "C85854"},
                      {"label": "Tuganay", "value": "C85855"},
                      {"label": "Tuli", "value": "C85856"},
                      {"label": "Ula", "value": "C85857"},
                      {"label": "Wangan", "value": "C85858"},
                      {"label": "Wines", "value": "C85859"}],
 "Davao Region": [{"label": "Alejal", "value": "C85860"},
                  {"label": "Andili", "value": "C85861"},
                  {"label": "Andop", "value": "C85862"},
                  {"label": "Anibongan", "value": "C85863"},
                  {"label": "Astorga", "value": "C85864"},
                  {"label": "Babag", "value": "C85865"},
                  {"label": "Baculin", "value": "C85866"},
                  {"label": "Baganga", "value": "C85867"},
                  {"label": "Balagunan", "value": "C85868"},
                  {"label": "Balangonan", "value": "C85869"},
                  {"label": "Balutakay", "value": "C85870"},
                  {"label": "Bansalan", "value": "C85871"},
                  {"label": "Bantacan", "value": "C85872"},
                  {"label": "Baon", "value": "C85873"},
                  {"label": "Baracatan", "value": "C85874"},
                  {"label": "Basiawan", "value": "C85875"},
                  {"label": "Batiano", "value": "C85876"},
                  {"label": "Bato", "value": "C85877"},
                  {"label": "Batobato", "value": "C85878"},
                  {"label": "Baylo", "value": "C85879"},
                  {"label": "Biao", "value": "C85880"},
                  {"label": "Bincongan", "value": "C85881"},
                  {"label": "Bitaogan", "value": "C85882"},
                  {"label": "Bobon", "value": "C85883"},
                  {"label": "Bolila", "value": "C85884"},
                  {"label": "Boston", "value": "C85885"},
                  {"label": "Buclad", "value": "C85886"},
                  {"label": "Buhangin", "value": "C85887"},
                  {"label": "Bukid", "value": "C85888"},
                  {"label": "Bulacan", "value": "C85889"},
                  {"label": "Bungabon", "value": "C85890"},
                  {"label": "Butulan", "value": "C85891"},
                  {"label": "Cabayangan", "value": "C85892"},
                  {"label": "Cabinuangan", "value": "C85893"},
                  {"label": "Caburan", "value": "C85894"},
                  {"label": "Cambanugoy", "value": "C85895"},
                  {"label": "Camudmud", "value": "C85896"},
                  {"label": "Caraga", "value": "C85897"},
                  {"label": "Carmen", "value": "C85898"},
                  {"label": "Cateel", "value": "C85899"},
                  {"label": "Cogon", "value": "C85900"},
                  {"label": "Compostela", "value": "C85901"},
                  {"label": "Compostela Valley", "value": "C85902"},
                  {"label": "Concepcion", "value": "C85903"},
                  {"label": "Corocotan", "value": "C85904"},
                  {"label": "Coronon", "value": "C85905"},
                  {"label": "Cuambog", "value": "C85906"},
                  {"label": "Culaman", "value": "C85907"},
                  {"label": "Dacudao", "value": "C85908"},
                  {"label": "Davan", "value": "C85909"},
                  {"label": "Davao", "value": "C85910"},
                  {"label": "Del Pilar", "value": "C85911"},
                  {"label": "Digos", "value": "C85912"},
                  {"label": "Dolo", "value": "C85913"},
                  {"label": "Dumlan", "value": "C85914"},
                  {"label": "Esperanza", "value": "C85915"},
                  {"label": "Gabi", "value": "C85916"},
                  {"label": "Gabuyan", "value": "C85917"},
                  {"label": "Goma", "value": "C85918"},
                  {"label": "Guihing Proper", "value": "C85919"},
                  {"label": "Gumalang", "value": "C85920"},
                  {"label": "Gupitan", "value": "C85921"},
                  {"label": "Hagonoy", "value": "C85922"},
                  {"label": "Hiju, Maco", "value": "C85923"},
                  {"label": "Ignit", "value": "C85924"},
                  {"label": "Ilangay", "value": "C85925"},
                  {"label": "Inawayan", "value": "C85926"},
                  {"label": "Jovellar", "value": "C85927"},
                  {"label": "Kalbay", "value": "C85928"},
                  {"label": "Kalian", "value": "C85929"},
                  {"label": "Kaligutan", "value": "C85930"},
                  {"label": "Kapalong", "value": "C85931"},
                  {"label": "Katipunan", "value": "C85932"},
                  {"label": "Kiblawan", "value": "C85933"},
                  {"label": "Kinablangan", "value": "C85934"},
                  {"label": "Kinamayan", "value": "C85935"},
                  {"label": "Kinangan", "value": "C85936"},
                  {"label": "La Libertad", "value": "C85937"},
                  {"label": "La Paz", "value": "C85938"},
                  {"label": "La Union", "value": "C85939"},
                  {"label": "Lacaron", "value": "C85940"},
                  {"label": "Lacson", "value": "C85941"},
                  {"label": "Lais", "value": "C85942"},
                  {"label": "Lamitan", "value": "C85943"},
                  {"label": "Lapuan", "value": "C85944"},
                  {"label": "Lasang", "value": "C85945"},
                  {"label": "Libuganon", "value": "C85946"},
                  {"label": "Limao", "value": "C85947"},
                  {"label": "Limot", "value": "C85948"},
                  {"label": "Linao", "value": "C85949"},
                  {"label": "Linoan", "value": "C85950"},
                  {"label": "Lukatan", "value": "C85951"},
                  {"label": "Luna", "value": "C85952"},
                  {"label": "Lungaog", "value": "C85953"},
                  {"label": "Lupon", "value": "C85954"},
                  {"label": "Luzon", "value": "C85955"},
                  {"label": "Mabini", "value": "C85956"},
                  {"label": "Mabuhay", "value": "C85957"},
                  {"label": "Maco", "value": "C85958"},
                  {"label": "Maduao", "value": "C85959"},
                  {"label": "Magatos", "value": "C85960"},
                  {"label": "Magdug", "value": "C85961"},
                  {"label": "Magnaga", "value": "C85962"},
                  {"label": "Magsaysay", "value": "C85963"},
                  {"label": "Magugpo Poblacion", "value": "C85964"},
                  {"label": "Mahanob", "value": "C85965"},
                  {"label": "Mahayag", "value": "C85966"},
                  {"label": "Malagos", "value": "C85967"},
                  {"label": "Malalag", "value": "C85968"},
                  {"label": "Malinao", "value": "C85969"},
                  {"label": "Malita", "value": "C85970"},
                  {"label": "Mambago", "value": "C85971"},
                  {"label": "Managa", "value": "C85972"},
                  {"label": "Manaloal", "value": "C85973"},
                  {"label": "Manat", "value": "C85974"},
                  {"label": "Manay", "value": "C85975"},
                  {"label": "Mangili", "value": "C85976"},
                  {"label": "Manikling", "value": "C85977"},
                  {"label": "Matanao", "value": "C85978"},
                  {"label": "Mati", "value": "C85979"},
                  {"label": "Matiao", "value": "C85980"},
                  {"label": "Matti", "value": "C85981"},
                  {"label": "Mawab", "value": "C85982"},
                  {"label": "Mayo", "value": "C85983"},
                  {"label": "Monkayo", "value": "C85984"},
                  {"label": "Montevista", "value": "C85985"},
                  {"label": "Nabunturan", "value": "C85986"},
                  {"label": "Nangan", "value": "C85987"},
                  {"label": "Nanyo", "value": "C85988"},
                  {"label": "New Baclayon", "value": "C85989"},
                  {"label": "New Bohol", "value": "C85990"},
                  {"label": "New Corella", "value": "C85991"},
                  {"label": "New Leyte", "value": "C85992"},
                  {"label": "New Sibonga", "value": "C85993"},
                  {"label": "New Visayas", "value": "C85994"},
                  {"label": "Nuing", "value": "C85995"},
                  {"label": "Padada", "value": "C85996"},
                  {"label": "Pag-asa", "value": "C85997"},
                  {"label": "Pagsabangan", "value": "C85998"},
                  {"label": "Palma Gil", "value": "C85999"},
                  {"label": "Panabo", "value": "C86000"},
                  {"label": "Pandasan", "value": "C86001"},
                  {"label": "Pangian", "value": "C86002"},
                  {"label": "Panikian", "value": "C86003"},
                  {"label": "Pantukan", "value": "C86004"},
                  {"label": "Pasian", "value": "C86005"},
                  {"label": "Pondaguitan", "value": "C86006"},
                  {"label": "Province of Davao del Norte", "value": "C86007"},
                  {"label": "Province of Davao del Sur", "value": "C86008"},
                  {"label": "Province of Davao Oriental", "value": "C86009"},
                  {"label": "Pung-Pang", "value": "C86010"},
                  {"label": "Samal", "value": "C86011"},
                  {"label": "Sampao", "value": "C86012"},
                  {"label": "San Alfonso", "value": "C86013"},
                  {"label": "San Antonio", "value": "C86014"},
                  {"label": "San Ignacio", "value": "C86015"},
                  {"label": "San Luis", "value": "C86016"},
                  {"label": "San Mariano", "value": "C86017"},
                  {"label": "San Miguel", "value": "C86018"},
                  {"label": "San Pedro", "value": "C86019"},
                  {"label": "San Rafael", "value": "C86020"},
                  {"label": "San Remigio", "value": "C86021"},
                  {"label": "Santa Cruz", "value": "C86022"},
                  {"label": "Santa Maria", "value": "C86023"},
                  {"label": "Santiago", "value": "C86024"},
                  {"label": "Santo Nino", "value": "C86025"},
                  {"label": "Sarangani", "value": "C86026"},
                  {"label": "Sibulan", "value": "C86027"},
                  {"label": "Sigaboy", "value": "C86028"},
                  {"label": "Simod", "value": "C86029"},
                  {"label": "Sinawilan", "value": "C86030"},
                  {"label": "Sinayawan", "value": "C86031"},
                  {"label": "Sirib", "value": "C86032"},
                  {"label": "Sugal", "value": "C86033"},
                  {"label": "Sulop", "value": "C86034"},
                  {"label": "Surup", "value": "C86035"},
                  {"label": "Suz-on", "value": "C86036"},
                  {"label": "Tagakpan", "value": "C86037"},
                  {"label": "Tagdanua", "value": "C86038"},
                  {"label": "Tagnanan", "value": "C86039"},
                  {"label": "Takub", "value": "C86040"},
                  {"label": "Talagutong", "value": "C86041"},
                  {"label": "Talisay", "value": "C86042"},
                  {"label": "Talomo", "value": "C86043"},
                  {"label": "Tamayong", "value": "C86044"},
                  {"label": "Tambo", "value": "C86045"},
                  {"label": "Tamisan", "value": "C86046"},
                  {"label": "Tamugan", "value": "C86047"},
                  {"label": "Tanlad", "value": "C86048"},
                  {"label": "Tapia", "value": "C86049"},
                  {"label": "Tarragona", "value": "C86050"},
                  {"label": "Tawan tawan", "value": "C86051"},
                  {"label": "Taytayan", "value": "C86052"},
                  {"label": "Tibagon", "value": "C86053"},
                  {"label": "Tibanbang", "value": "C86054"},
                  {"label": "Tiblawan", "value": "C86055"},
                  {"label": "Tombongon", "value": "C86056"},
                  {"label": "Tubalan", "value": "C86057"},
                  {"label": "Tuban", "value": "C86058"},
                  {"label": "Tubod", "value": "C86059"},
                  {"label": "Tuganay", "value": "C86060"},
                  {"label": "Tuli", "value": "C86061"},
                  {"label": "Ula", "value": "C86062"},
                  {"label": "Wangan", "value": "C86063"},
                  {"label": "Wines", "value": "C86064"}],
 "Daykundi": [{"label": "Nili", "value": "C21"}],
 "Debarca Municipality": [{"label": "Belcista", "value": "C65388"},
                          {"label": "Meseista", "value": "C65389"}],
 "Debub Region": [{"label": "Adi Keyh", "value": "C24489"},
                  {"label": "Dekemhare", "value": "C24490"},
                  {"label": "Mendefera", "value": "C24491"}],
 "Deir ez-Zor Governorate": [{"label": "Al Mayadin", "value": "C117869"},
                             {"label": "albu Kamal", "value": "C117870"},
                             {"label": "Deir ez-Zor", "value": "C117871"},
                             {"label": "Hajin", "value": "C117872"},
                             {"label": "Subaykhan", "value": "C117873"}],
 "Delaware": [{"label": "Bear", "value": "C129595"},
              {"label": "Bellefonte", "value": "C129596"},
              {"label": "Bethany Beach", "value": "C129597"},
              {"label": "Blades", "value": "C129598"},
              {"label": "Bridgeville", "value": "C129599"},
              {"label": "Brookside", "value": "C129600"},
              {"label": "Camden", "value": "C129601"},
              {"label": "Cheswold", "value": "C129602"},
              {"label": "Claymont", "value": "C129603"},
              {"label": "Clayton", "value": "C129604"},
              {"label": "Delaware City", "value": "C129605"},
              {"label": "Delmar", "value": "C129606"},
              {"label": "Dover", "value": "C129607"},
              {"label": "Dover Base Housing", "value": "C129608"},
              {"label": "Edgemoor", "value": "C129609"},
              {"label": "Elsmere", "value": "C129610"},
              {"label": "Felton", "value": "C129611"},
              {"label": "Georgetown", "value": "C129612"},
              {"label": "Glasgow", "value": "C129613"},
              {"label": "Greenville", "value": "C129614"},
              {"label": "Greenwood", "value": "C129615"},
              {"label": "Harrington", "value": "C129616"},
              {"label": "Highland Acres", "value": "C129617"},
              {"label": "Hockessin", "value": "C129618"},
              {"label": "Kent Acres", "value": "C129619"},
              {"label": "Kent County", "value": "C129620"},
              {"label": "Laurel", "value": "C129621"},
              {"label": "Lewes", "value": "C129622"},
              {"label": "Long Neck", "value": "C129623"},
              {"label": "Middletown", "value": "C129624"},
              {"label": "Milford", "value": "C129625"},
              {"label": "Millsboro", "value": "C129626"},
              {"label": "Milton", "value": "C129627"},
              {"label": "New Castle", "value": "C129628"},
              {"label": "New Castle County", "value": "C129629"},
              {"label": "Newark", "value": "C129630"},
              {"label": "Newport", "value": "C129631"},
              {"label": "North Star", "value": "C129632"},
              {"label": "Ocean View", "value": "C129633"},
              {"label": "Pike Creek", "value": "C129634"},
              {"label": "Pike Creek Valley", "value": "C129635"},
              {"label": "Rehoboth Beach", "value": "C129636"},
              {"label": "Rising Sun-Lebanon", "value": "C129637"},
              {"label": "Riverview", "value": "C129638"},
              {"label": "Rodney Village", "value": "C129639"},
              {"label": "Seaford", "value": "C129640"},
              {"label": "Selbyville", "value": "C129641"},
              {"label": "Smyrna", "value": "C129642"},
              {"label": "Sussex County", "value": "C129643"},
              {"label": "Townsend", "value": "C129644"},
              {"label": "Wilmington", "value": "C129645"},
              {"label": "Wilmington Manor", "value": "C129646"},
              {"label": "Woodside East", "value": "C129647"},
              {"label": "Wyoming", "value": "C129648"}],
 "Delcevo Municipality": [{"label": "Delcevo", "value": "C65390"}],
 "Delhi": [{"label": "Alipur", "value": "C45399"},
           {"label": "Bawana", "value": "C45400"},
           {"label": "Central Delhi", "value": "C45401"},
           {"label": "Delhi", "value": "C45402"},
           {"label": "Deoli", "value": "C45403"},
           {"label": "East Delhi", "value": "C45404"},
           {"label": "Karol Bagh", "value": "C45405"},
           {"label": "Najafgarh", "value": "C45406"},
           {"label": "Nangloi Jat", "value": "C45407"},
           {"label": "Narela", "value": "C45408"},
           {"label": "New Delhi", "value": "C45409"},
           {"label": "North Delhi", "value": "C45410"},
           {"label": "North East Delhi", "value": "C45411"},
           {"label": "North West Delhi", "value": "C45412"},
           {"label": "Pitampura", "value": "C45413"},
           {"label": "Rohini", "value": "C45414"},
           {"label": "South Delhi", "value": "C45415"},
           {"label": "South West Delhi", "value": "C45416"},
           {"label": "West Delhi", "value": "C45417"}],
 "Delta": [{"label": "Abraka", "value": "C78212"},
           {"label": "Agbor", "value": "C78213"},
           {"label": "Asaba", "value": "C78214"},
           {"label": "Bomadi", "value": "C78215"},
           {"label": "Burutu", "value": "C78216"},
           {"label": "Kwale", "value": "C78217"},
           {"label": "Obiaruku", "value": "C78218"},
           {"label": "Ogwashi-Uku", "value": "C78219"},
           {"label": "Orerokpe", "value": "C78220"},
           {"label": "Patani", "value": "C78221"},
           {"label": "Sapele", "value": "C78222"},
           {"label": "Ughelli", "value": "C78223"},
           {"label": "Umunede", "value": "C78224"},
           {"label": "Warri", "value": "C78225"}],
 "Delta Amacuro": [{"label": "Tucupita", "value": "C147170"}],
 "Demerara-Mahaica": [{"label": "Georgetown", "value": "C43067"},
                      {"label": "Mahaica Village", "value": "C43068"}],
 "Demir Hisar Municipality": [{"label": "Demir Hisar", "value": "C65391"},
                              {"label": "Slepce", "value": "C65392"},
                              {"label": "Sopotnica", "value": "C65393"}],
 "Demir Kapija Municipality": [{"label": "Demir Kapija", "value": "C65394"}],
 "Denguele District": [{"label": "Folon", "value": "C20940"},
                       {"label": "Kabadougou", "value": "C20941"},
                       {"label": "Odienne", "value": "C20942"}],
 "Denizli": [{"label": "Acipayam", "value": "C120184"},
             {"label": "Akkent", "value": "C120185"},
             {"label": "Akkoy", "value": "C120186"},
             {"label": "Babadag", "value": "C120187"},
             {"label": "Baklan", "value": "C120188"},
             {"label": "Bekilli", "value": "C120189"},
             {"label": "Beyagac Ilcesi", "value": "C120190"},
             {"label": "Bozkurt", "value": "C120191"},
             {"label": "Buldan", "value": "C120192"},
             {"label": "Cal Ilcesi", "value": "C120193"},
             {"label": "Cameli Ilcesi", "value": "C120194"},
             {"label": "Cardak", "value": "C120195"},
             {"label": "Civril", "value": "C120196"},
             {"label": "Denizli", "value": "C120197"},
             {"label": "Gozler", "value": "C120198"},
             {"label": "Guney Ilcesi", "value": "C120199"},
             {"label": "Honaz", "value": "C120200"},
             {"label": "Kale", "value": "C120201"},
             {"label": "Merkezefendi", "value": "C120202"},
             {"label": "Nikfer", "value": "C120203"},
             {"label": "Pamukkale", "value": "C120204"},
             {"label": "Saraykoy", "value": "C120205"},
             {"label": "Serinhisar", "value": "C120206"},
             {"label": "Tavas", "value": "C120207"}],
 "Dennery Quarter": [{"label": "Anse Canot", "value": "C105923"},
                     {"label": "Athens", "value": "C105924"},
                     {"label": "Au Leon", "value": "C105925"},
                     {"label": "Bara Bara", "value": "C105926"},
                     {"label": "Bazile", "value": "C105927"},
                     {"label": "Belmont", "value": "C105928"},
                     {"label": "Bois Joli", "value": "C105929"},
                     {"label": "Bordelais", "value": "C105930"},
                     {"label": "Bosquet DOr", "value": "C105931"},
                     {"label": "Delaide", "value": "C105932"},
                     {"label": "Dennery", "value": "C105933"},
                     {"label": "Dennery By Pass", "value": "C105934"},
                     {"label": "Dennery By PassGreen Mountain",
                      "value": "C105935"},
                     {"label": "Dennery By PassRocky Lane", "value": "C105936"},
                     {"label": "Dennery By PassWhite Rock Gardens",
                      "value": "C105937"},
                     {"label": "Dennery Village", "value": "C105938"},
                     {"label": "Derniere Riviere", "value": "C105939"},
                     {"label": "Derniere RiviereFond Maricient",
                      "value": "C105940"},
                     {"label": "Derniere RiviereMardi GrasMorne Caca Cochon",
                      "value": "C105941"},
                     {"label": "Derniere RiviereMorne Panache",
                      "value": "C105942"},
                     {"label": "Despinoze", "value": "C105943"},
                     {"label": "Dubonnaire", "value": "C105944"},
                     {"label": "Gadette", "value": "C105945"},
                     {"label": "Grande Ravine", "value": "C105946"},
                     {"label": "Grande Riviere", "value": "C105947"},
                     {"label": "Grande RiviereDes Branch", "value": "C105948"},
                     {"label": "Grande RiviereEn Leur MorneDiscompere",
                      "value": "C105949"},
                     {"label": "Grande RiviereFunier", "value": "C105950"},
                     {"label": "Grande RiviereMorne Caca Cochon",
                      "value": "C105951"},
                     {"label": "La Caye", "value": "C105952"},
                     {"label": "La Pelle", "value": "C105953"},
                     {"label": "La Pointe", "value": "C105954"},
                     {"label": "La Ressource", "value": "C105955"},
                     {"label": "Lumiere", "value": "C105956"},
                     {"label": "Morne Panache", "value": "C105957"},
                     {"label": "Riche Fond", "value": "C105958"},
                     {"label": "Riche FondLa Belle Vie", "value": "C105959"},
                     {"label": "Riche FondNew Village", "value": "C105960"},
                     {"label": "St. Joseph Estate", "value": "C105961"},
                     {"label": "Thamazo", "value": "C105962"}],
 "Derna District": [{"label": "Al Qubbah", "value": "C65028"},
                    {"label": "Darnah", "value": "C65029"}],
 "Destrnik Municipality": [{"label": "Destrnik", "value": "C107606"}],
 "Dhaalu Atoll": [{"label": "Kudahuvadhoo", "value": "C65803"}],
 "Dhaka District": [{"label": "Azimpur", "value": "C8592"},
                    {"label": "Bajitpur", "value": "C8593"},
                    {"label": "Bhairab Bazar", "value": "C8594"},
                    {"label": "Char Bhadrasan", "value": "C8595"},
                    {"label": "Char Golora", "value": "C8596"},
                    {"label": "Dhaka", "value": "C8597"},
                    {"label": "Dohar", "value": "C8598"},
                    {"label": "Faridpur", "value": "C8599"},
                    {"label": "Gazipur", "value": "C8600"},
                    {"label": "Gopalganj", "value": "C8601"},
                    {"label": "Joymontop", "value": "C8602"},
                    {"label": "Khanbaniara", "value": "C8603"},
                    {"label": "Kishoregonj", "value": "C8604"},
                    {"label": "Kishorganj", "value": "C8605"},
                    {"label": "Madaripur", "value": "C8606"},
                    {"label": "Manikganj", "value": "C8607"},
                    {"label": "Mirzapur", "value": "C8608"},
                    {"label": "Munshiganj", "value": "C8609"},
                    {"label": "Nagarpur", "value": "C8610"},
                    {"label": "Narayanganj", "value": "C8611"},
                    {"label": "Narsingdi", "value": "C8612"},
                    {"label": "Palang", "value": "C8613"},
                    {"label": "Paltan", "value": "C8614"},
                    {"label": "Parvez Ali", "value": "C8615"},
                    {"label": "Parvez Ali Hossain", "value": "C8616"},
                    {"label": "Rajbari", "value": "C8617"},
                    {"label": "Ramnagar", "value": "C8618"},
                    {"label": "Sakhipur", "value": "C8619"},
                    {"label": "Sayani", "value": "C8620"},
                    {"label": "Shariatpur", "value": "C8621"},
                    {"label": "Sonargaon", "value": "C8622"},
                    {"label": "Tangail", "value": "C8623"},
                    {"label": "Tungi", "value": "C8624"},
                    {"label": "Tungipara", "value": "C8625"}],
 "Dhamar Governorate": [{"label": "Ans", "value": "C147865"},
                        {"label": "Al Hada", "value": "C147866"},
                        {"label": "Al Manar", "value": "C147867"},
                        {"label": "Al-Medy Village, qry@ lmdy",
                         "value": "C147868"},
                        {"label": "Dawran Aness", "value": "C147869"},
                        {"label": "Dhamar", "value": "C147870"},
                        {"label": "Jabal Ash sharq", "value": "C147871"},
                        {"label": "Jahran", "value": "C147872"},
                        {"label": "Maghirib Ans", "value": "C147873"},
                        {"label": "Mayfaat Anss", "value": "C147874"},
                        {"label": "Utmah", "value": "C147875"},
                        {"label": "Wusab Al Ali", "value": "C147876"},
                        {"label": "Wusab As Safil", "value": "C147877"}],
 "Dhi Qar Governorate": [{"label": "Ash Shatrah", "value": "C51162"},
                         {"label": "Nahiyat al Fuhud", "value": "C51163"},
                         {"label": "Nasiriyah", "value": "C51164"}],
 "Dhofar Governorate": [{"label": "Salalah", "value": "C79305"}],
 "Diber County": [{"label": "Bashkia Bulqize", "value": "C106"},
                  {"label": "Bashkia Klos", "value": "C107"},
                  {"label": "Bashkia Mat", "value": "C108"},
                  {"label": "Bulqize", "value": "C109"},
                  {"label": "Burrel", "value": "C110"},
                  {"label": "Klos", "value": "C111"},
                  {"label": "Peshkopi", "value": "C112"},
                  {"label": "Rrethi i Bulqizes", "value": "C113"},
                  {"label": "Rrethi i Dibres", "value": "C114"},
                  {"label": "Rrethi i Matit", "value": "C115"},
                  {"label": "Ulez", "value": "C116"}],
 "Diego Martin Regional Corporation": [{"label": "Petit Valley",
                                        "value": "C119660"},
                                       {"label": "Ward of Diego Martin",
                                        "value": "C119661"}],
 "Dien Bien": [{"label": "Dien Bien Phu", "value": "C147355"},
               {"label": "Huyen Dien Bien Dong", "value": "C147356"},
               {"label": "Huyen Muong Nhe", "value": "C147357"},
               {"label": "Huyen Tua Chua", "value": "C147358"},
               {"label": "Huyen Tuan Giao", "value": "C147359"},
               {"label": "Thi Xa Muong Lay", "value": "C147360"}],
 "Diffa Region": [{"label": "Departement de Diffa", "value": "C78048"},
                  {"label": "Departement de Maine-Soroa", "value": "C78049"},
                  {"label": "Departement de Nguigmi", "value": "C78050"},
                  {"label": "Diffa", "value": "C78051"},
                  {"label": "Maine Soroa", "value": "C78052"},
                  {"label": "Nguigmi", "value": "C78053"}],
 "Dikhil Region": [{"label": "Dikhil", "value": "C23810"},
                   {"label": "Galafi", "value": "C23811"}],
 "Dili municipality": [{"label": "Atauro Island", "value": "C24060"},
                       {"label": "Cristo Rei", "value": "C24061"},
                       {"label": "Dili", "value": "C24062"},
                       {"label": "Metinaro", "value": "C24063"}],
 "Dingli": [{"label": "Dingli", "value": "C65875"}],
 "Diourbel Region": [{"label": "Mbacke", "value": "C106830"},
                     {"label": "Mbake", "value": "C106831"},
                     {"label": "Tiebo", "value": "C106832"},
                     {"label": "Touba", "value": "C106833"}],
 "Dire Dawa": [{"label": "Dire Dawa", "value": "C24700"}],
 "District of Columbia": [{"label": "Adams Morgan", "value": "C129649"},
                          {"label": "Bloomingdale", "value": "C129650"},
                          {"label": "Chevy Chase", "value": "C129651"},
                          {"label": "Shaw", "value": "C129652"},
                          {"label": "Washington, D.C.", "value": "C129653"}],
 "Distrito Capital": [{"label": "Caracas", "value": "C147171"}],
 "Distrito Federal": [{"label": "Brasilia", "value": "C11007"},
                      {"label": "Planaltina", "value": "C11008"}],
 "Distrito Nacional": [{"label": "Bella Vista", "value": "C23873"},
                       {"label": "Ciudad Nueva", "value": "C23874"},
                       {"label": "Cristo Rey", "value": "C23875"},
                       {"label": "Ensanche Luperon", "value": "C23876"},
                       {"label": "La Agustina", "value": "C23877"},
                       {"label": "La Julia", "value": "C23878"},
                       {"label": "San Carlos", "value": "C23879"},
                       {"label": "Santo Domingo", "value": "C23880"},
                       {"label": "Villa Consuelo", "value": "C23881"},
                       {"label": "Villa Francisca", "value": "C23882"}],
 "Divaca Municipality": [{"label": "Divaca", "value": "C107607"}],
 "Dix-Huit Montagnes": [{"label": "Bangolo", "value": "C20943"},
                        {"label": "Biankouma", "value": "C20944"},
                        {"label": "Cavally", "value": "C20945"},
                        {"label": "Danane", "value": "C20946"},
                        {"label": "Duekoue", "value": "C20947"},
                        {"label": "Guemon", "value": "C20948"},
                        {"label": "Guiglo", "value": "C20949"},
                        {"label": "Man", "value": "C20950"},
                        {"label": "Tonkpi", "value": "C20951"},
                        {"label": "Toulepleu Guere", "value": "C20952"}],
 "Diyala Governorate": [{"label": "Al Miqdadiyah", "value": "C51165"},
                        {"label": "Baladruz", "value": "C51166"},
                        {"label": "Baqubah", "value": "C51167"},
                        {"label": "Khalis", "value": "C51168"},
                        {"label": "Kifri", "value": "C51169"},
                        {"label": "Mandali", "value": "C51170"},
                        {"label": "Qada Kifri", "value": "C51171"}],
 "Diyarbakir": [{"label": "Alacakaya Ilcesi", "value": "C120208"},
                {"label": "Altinkum", "value": "C120209"},
                {"label": "Ambar", "value": "C120210"},
                {"label": "Aralik", "value": "C120211"},
                {"label": "Baglar", "value": "C120212"},
                {"label": "Bismil", "value": "C120213"},
                {"label": "Cermik", "value": "C120214"},
                {"label": "Cinar Ilcesi", "value": "C120215"},
                {"label": "Cungus", "value": "C120216"},
                {"label": "Dicle", "value": "C120217"},
                {"label": "Diyarbakir", "value": "C120218"},
                {"label": "Egil", "value": "C120219"},
                {"label": "Ergani", "value": "C120220"},
                {"label": "Hani", "value": "C120221"},
                {"label": "Hazro", "value": "C120222"},
                {"label": "Kayapinar", "value": "C120223"},
                {"label": "Kazanci", "value": "C120224"},
                {"label": "Kerh", "value": "C120225"},
                {"label": "Kocakoy", "value": "C120226"},
                {"label": "Koseli", "value": "C120227"},
                {"label": "Kulp", "value": "C120228"},
                {"label": "Lice", "value": "C120229"},
                {"label": "Salat", "value": "C120230"},
                {"label": "Silvan", "value": "C120231"},
                {"label": "Sinankoy", "value": "C120232"},
                {"label": "Sur", "value": "C120233"},
                {"label": "Yaprakbasi", "value": "C120234"},
                {"label": "Yenisehir", "value": "C120235"}],
 "Djelfa": [{"label": "Ain el Bell", "value": "C323"},
            {"label": "Ain Oussera", "value": "C324"},
            {"label": "Birine", "value": "C325"},
            {"label": "Charef", "value": "C326"},
            {"label": "Dar Chioukh", "value": "C327"},
            {"label": "Djelfa", "value": "C328"},
            {"label": "El Idrissia", "value": "C329"},
            {"label": "Messaad", "value": "C330"}],
 "Djibouti": [{"label": "Djibouti", "value": "C23812"},
              {"label": "Loyada", "value": "C23813"}],
 "Dnipropetrovska oblast": [{"label": "Apostolove", "value": "C121748"},
                            {"label": "Auly", "value": "C121749"},
                            {"label": "Aviatorske", "value": "C121750"},
                            {"label": "Chervonohryhorivka", "value": "C121751"},
                            {"label": "Chortomlyk", "value": "C121752"},
                            {"label": "Dnipro", "value": "C121753"},
                            {"label": "Dnipro Raion", "value": "C121754"},
                            {"label": "Dniprovska Miska Rada",
                             "value": "C121755"},
                            {"label": "Hubynykha", "value": "C121756"},
                            {"label": "Hupalivka", "value": "C121757"},
                            {"label": "Kamianske", "value": "C121758"},
                            {"label": "Kaydaki", "value": "C121759"},
                            {"label": "Krynychky", "value": "C121760"},
                            {"label": "Kryvorizka Miskrada",
                             "value": "C121761"},
                            {"label": "Kryvyi Rih", "value": "C121762"},
                            {"label": "Lykhivka", "value": "C121763"},
                            {"label": "Mahdalynivka", "value": "C121764"},
                            {"label": "Mahdalynivskyy Rayon",
                             "value": "C121765"},
                            {"label": "Marhanets", "value": "C121766"},
                            {"label": "Melioratyvne", "value": "C121767"},
                            {"label": "Mezhova", "value": "C121768"},
                            {"label": "Nikopol", "value": "C121769"},
                            {"label": "Novomoskovsk", "value": "C121770"},
                            {"label": "Novomoskovsk Raion", "value": "C121771"},
                            {"label": "Novomykolayivka", "value": "C121772"},
                            {"label": "Novopokrovka", "value": "C121773"},
                            {"label": "Ordzhonikidze", "value": "C121774"},
                            {"label": "Partyzanske", "value": "C121775"},
                            {"label": "Pavlohrad", "value": "C121776"},
                            {"label": "Petropavlivka", "value": "C121777"},
                            {"label": "Petrykivka", "value": "C121778"},
                            {"label": "Petrykivskyy Rayon", "value": "C121779"},
                            {"label": "Piatykhatky", "value": "C121780"},
                            {"label": "Pidhorodne", "value": "C121781"},
                            {"label": "Sholokhove", "value": "C121782"},
                            {"label": "Shyroke", "value": "C121783"},
                            {"label": "Shyroke Raion", "value": "C121784"},
                            {"label": "Sofiyivka", "value": "C121785"},
                            {"label": "Solone", "value": "C121786"},
                            {"label": "Solone Raion", "value": "C121787"},
                            {"label": "Spaske", "value": "C121788"},
                            {"label": "Sursko-Mykhaylivka", "value": "C121789"},
                            {"label": "Synelnykove", "value": "C121790"},
                            {"label": "Tomakivka", "value": "C121791"},
                            {"label": "Tsarychanka", "value": "C121792"},
                            {"label": "Tsarychanskyy Rayon",
                             "value": "C121793"},
                            {"label": "Verkhnodniprovsk", "value": "C121794"},
                            {"label": "Vilne", "value": "C121795"},
                            {"label": "Vyshchetarasivka", "value": "C121796"},
                            {"label": "Vyshneve", "value": "C121797"},
                            {"label": "Yuryivskyy Rayon", "value": "C121798"},
                            {"label": "Zhovti Vody", "value": "C121799"}],
 "Dobele Municipality": [{"label": "Dobele", "value": "C64862"}],
 "Dobje Municipality": [{"label": "Dobje pri Planini", "value": "C107608"}],
 "Dobrepolje Municipality": [{"label": "Videm", "value": "C107609"}],
 "Dobrich Province": [{"label": "Balchik", "value": "C15907"},
                      {"label": "Dobrich", "value": "C15908"},
                      {"label": "General Toshevo", "value": "C15909"},
                      {"label": "Kavarna", "value": "C15910"},
                      {"label": "Krushari", "value": "C15911"},
                      {"label": "Obshtina Balchik", "value": "C15912"},
                      {"label": "Obshtina Dobrich", "value": "C15913"},
                      {"label": "Obshtina Dobrich-Selska", "value": "C15914"},
                      {"label": "Obshtina General Toshevo", "value": "C15915"},
                      {"label": "Obshtina Kavarna", "value": "C15916"},
                      {"label": "Obshtina Krushari", "value": "C15917"},
                      {"label": "Obshtina Shabla", "value": "C15918"},
                      {"label": "Obshtina Tervel", "value": "C15919"},
                      {"label": "Shabla", "value": "C15920"},
                      {"label": "Tervel", "value": "C15921"}],
 "Dobrna Municipality": [{"label": "Dobrna", "value": "C107610"}],
 "Dobrova-Polhov Gradec Municipality": [{"label": "Dobrova",
                                         "value": "C107611"}],
 "Dobrovnik Municipality": [{"label": "Dobrovnik", "value": "C107612"}],
 "Dodoma": [{"label": "Dodoma", "value": "C118081"},
            {"label": "Kibakwe", "value": "C118082"},
            {"label": "Kisasa", "value": "C118083"},
            {"label": "Kondoa", "value": "C118084"},
            {"label": "Kongwa", "value": "C118085"},
            {"label": "Mpwapwa", "value": "C118086"},
            {"label": "Msanga", "value": "C118087"}],
 "Doha": [{"label": "Doha", "value": "C92079"}],
 "Dohuk Governorate": [{"label": "Al Amadiyah", "value": "C51172"},
                       {"label": "Batifa", "value": "C51173"},
                       {"label": "Dihok", "value": "C51174"},
                       {"label": "Sinah", "value": "C51175"},
                       {"label": "Zaxo", "value": "C51176"}],
 "Dojran Municipality": [{"label": "Star Dojran", "value": "C65395"}],
 "Dol pri Ljubljani Municipality": [{"label": "Dol pri Ljubljani",
                                     "value": "C107613"}],
 "Dolenjske Toplice Municipality": [{"label": "Dolenjske Toplice",
                                     "value": "C107614"}],
 "Dolj County": [{"label": "Afumati", "value": "C95022"},
                 {"label": "Almaj", "value": "C95023"},
                 {"label": "Amarastii de Jos", "value": "C95024"},
                 {"label": "Amarastii de Sus", "value": "C95025"},
                 {"label": "Apele Vii", "value": "C95026"},
                 {"label": "Argetoaia", "value": "C95027"},
                 {"label": "Badosi", "value": "C95028"},
                 {"label": "Bailesti", "value": "C95029"},
                 {"label": "Balta", "value": "C95030"},
                 {"label": "Barca", "value": "C95031"},
                 {"label": "Basarabi", "value": "C95032"},
                 {"label": "Bechet", "value": "C95033"},
                 {"label": "Bistret", "value": "C95034"},
                 {"label": "Botosesti-Paia", "value": "C95035"},
                 {"label": "Boureni", "value": "C95036"},
                 {"label": "Brabeti", "value": "C95037"},
                 {"label": "Brabova", "value": "C95038"},
                 {"label": "Bradesti", "value": "C95039"},
                 {"label": "Bralostita", "value": "C95040"},
                 {"label": "Bratovoesti", "value": "C95041"},
                 {"label": "Breasta", "value": "C95042"},
                 {"label": "Bucovat", "value": "C95043"},
                 {"label": "Bulzesti", "value": "C95044"},
                 {"label": "Calafat", "value": "C95045"},
                 {"label": "Calarasi", "value": "C95046"},
                 {"label": "Calopar", "value": "C95047"},
                 {"label": "Caraula", "value": "C95048"},
                 {"label": "Carcea", "value": "C95049"},
                 {"label": "Carna", "value": "C95050"},
                 {"label": "Carpen", "value": "C95051"},
                 {"label": "Castranova", "value": "C95052"},
                 {"label": "Castrele Traiane", "value": "C95053"},
                 {"label": "Catane", "value": "C95054"},
                 {"label": "Celaru", "value": "C95055"},
                 {"label": "Cerat", "value": "C95056"},
                 {"label": "Cernatesti", "value": "C95057"},
                 {"label": "Cernele", "value": "C95058"},
                 {"label": "Cetate", "value": "C95059"},
                 {"label": "Cioroiasi", "value": "C95060"},
                 {"label": "Ciupercenii Noi", "value": "C95061"},
                 {"label": "Ciupercenii Vechi", "value": "C95062"},
                 {"label": "Cleanov", "value": "C95063"},
                 {"label": "Comosteni", "value": "C95064"},
                 {"label": "Comuna Afumati", "value": "C95065"},
                 {"label": "Comuna Almaj", "value": "C95066"},
                 {"label": "Comuna Amarastii de Jos", "value": "C95067"},
                 {"label": "Comuna Amarastii de Sus", "value": "C95068"},
                 {"label": "Comuna Apele Vii", "value": "C95069"},
                 {"label": "Comuna Argetoaia", "value": "C95070"},
                 {"label": "Comuna Barca", "value": "C95071"},
                 {"label": "Comuna Bistret", "value": "C95072"},
                 {"label": "Comuna Botosesti-Paia", "value": "C95073"},
                 {"label": "Comuna Brabova", "value": "C95074"},
                 {"label": "Comuna Bradesti", "value": "C95075"},
                 {"label": "Comuna Bralostita", "value": "C95076"},
                 {"label": "Comuna Bratovoesti", "value": "C95077"},
                 {"label": "Comuna Breasta", "value": "C95078"},
                 {"label": "Comuna Bucovat", "value": "C95079"},
                 {"label": "Comuna Bulzesti", "value": "C95080"},
                 {"label": "Comuna Calarasi", "value": "C95081"},
                 {"label": "Comuna Caloparu", "value": "C95082"},
                 {"label": "Comuna Caraula", "value": "C95083"},
                 {"label": "Comuna Carcea", "value": "C95084"},
                 {"label": "Comuna Carna", "value": "C95085"},
                 {"label": "Comuna Carpen", "value": "C95086"},
                 {"label": "Comuna Castranova", "value": "C95087"},
                 {"label": "Comuna Catane", "value": "C95088"},
                 {"label": "Comuna Celaru", "value": "C95089"},
                 {"label": "Comuna Cerat", "value": "C95090"},
                 {"label": "Comuna Cernatesti", "value": "C95091"},
                 {"label": "Comuna Cetate", "value": "C95092"},
                 {"label": "Comuna Cioroiasi", "value": "C95093"},
                 {"label": "Comuna Ciupercenii Noi", "value": "C95094"},
                 {"label": "Comuna Cosoveni", "value": "C95095"},
                 {"label": "Comuna Cotofenii din Dos", "value": "C95096"},
                 {"label": "Comuna Cotofenii din Fata", "value": "C95097"},
                 {"label": "Comuna Daneti", "value": "C95098"},
                 {"label": "Comuna Desa", "value": "C95099"},
                 {"label": "Comuna Diosti", "value": "C95100"},
                 {"label": "Comuna Dobresti", "value": "C95101"},
                 {"label": "Comuna Dobrotesti", "value": "C95102"},
                 {"label": "Comuna Dragotesti", "value": "C95103"},
                 {"label": "Comuna Dranic", "value": "C95104"},
                 {"label": "Comuna Farcas", "value": "C95105"},
                 {"label": "Comuna Galicea Mare", "value": "C95106"},
                 {"label": "Comuna Galiciuica", "value": "C95107"},
                 {"label": "Comuna Gangiova", "value": "C95108"},
                 {"label": "Comuna Ghercesti", "value": "C95109"},
                 {"label": "Comuna Ghidici", "value": "C95110"},
                 {"label": "Comuna Ghindeni", "value": "C95111"},
                 {"label": "Comuna Gighera", "value": "C95112"},
                 {"label": "Comuna Giubega", "value": "C95113"},
                 {"label": "Comuna Giurgita", "value": "C95114"},
                 {"label": "Comuna Gogosu", "value": "C95115"},
                 {"label": "Comuna Goicea", "value": "C95116"},
                 {"label": "Comuna Goiesti", "value": "C95117"},
                 {"label": "Comuna Grecesti", "value": "C95118"},
                 {"label": "Comuna Intorsura", "value": "C95119"},
                 {"label": "Comuna Isalnita", "value": "C95120"},
                 {"label": "Comuna Izvoare", "value": "C95121"},
                 {"label": "Comuna Leu", "value": "C95122"},
                 {"label": "Comuna Lipovu", "value": "C95123"},
                 {"label": "Comuna Macesu de Jos", "value": "C95124"},
                 {"label": "Comuna Macesu de Sus", "value": "C95125"},
                 {"label": "Comuna Maglavit", "value": "C95126"},
                 {"label": "Comuna Malu Mare", "value": "C95127"},
                 {"label": "Comuna Marsani", "value": "C95128"},
                 {"label": "Comuna Melinesti", "value": "C95129"},
                 {"label": "Comuna Mischii", "value": "C95130"},
                 {"label": "Comuna Motatei", "value": "C95131"},
                 {"label": "Comuna Murgasi", "value": "C95132"},
                 {"label": "Comuna Negoi", "value": "C95133"},
                 {"label": "Comuna Orodel", "value": "C95134"},
                 {"label": "Comuna Ostroveni", "value": "C95135"},
                 {"label": "Comuna Perisor", "value": "C95136"},
                 {"label": "Comuna Pielesti", "value": "C95137"},
                 {"label": "Comuna Piscu Vechi", "value": "C95138"},
                 {"label": "Comuna Plenita", "value": "C95139"},
                 {"label": "Comuna Plesoi", "value": "C95140"},
                 {"label": "Comuna Podari", "value": "C95141"},
                 {"label": "Comuna Poiana Mare", "value": "C95142"},
                 {"label": "Comuna Predesti", "value": "C95143"},
                 {"label": "Comuna Radovan", "value": "C95144"},
                 {"label": "Comuna Rast", "value": "C95145"},
                 {"label": "Comuna Robanesti", "value": "C95146"},
                 {"label": "Comuna Rojiste", "value": "C95147"},
                 {"label": "Comuna Sadova", "value": "C95148"},
                 {"label": "Comuna Salcuta", "value": "C95149"},
                 {"label": "Comuna Scaesti", "value": "C95150"},
                 {"label": "Comuna Seaca de Camp", "value": "C95151"},
                 {"label": "Comuna Seaca de Padure", "value": "C95152"},
                 {"label": "Comuna Secu", "value": "C95153"},
                 {"label": "Comuna Silistea Crucii", "value": "C95154"},
                 {"label": "Comuna Simnicu de Sus", "value": "C95155"},
                 {"label": "Comuna Sopot", "value": "C95156"},
                 {"label": "Comuna Talpas", "value": "C95157"},
                 {"label": "Comuna Teasc", "value": "C95158"},
                 {"label": "Comuna Terpezita", "value": "C95159"},
                 {"label": "Comuna Teslui", "value": "C95160"},
                 {"label": "Comuna Tuglui", "value": "C95161"},
                 {"label": "Comuna Unirea", "value": "C95162"},
                 {"label": "Comuna Urzicuta", "value": "C95163"},
                 {"label": "Comuna Valea Stanciului", "value": "C95164"},
                 {"label": "Comuna Vartop", "value": "C95165"},
                 {"label": "Comuna Varvoru de Jos", "value": "C95166"},
                 {"label": "Comuna Vela", "value": "C95167"},
                 {"label": "Comuna Verbita", "value": "C95168"},
                 {"label": "Cosoveni", "value": "C95169"},
                 {"label": "Cotofenii din Dos", "value": "C95170"},
                 {"label": "Cotofenii din Fata", "value": "C95171"},
                 {"label": "Craiova", "value": "C95172"},
                 {"label": "Dabuleni", "value": "C95173"},
                 {"label": "Daneti", "value": "C95174"},
                 {"label": "Desa", "value": "C95175"},
                 {"label": "Diosti", "value": "C95176"},
                 {"label": "Dobresti", "value": "C95177"},
                 {"label": "Dobridor", "value": "C95178"},
                 {"label": "Dobrotesti", "value": "C95179"},
                 {"label": "Dragotesti", "value": "C95180"},
                 {"label": "Dranic", "value": "C95181"},
                 {"label": "Facai", "value": "C95182"},
                 {"label": "Farcas", "value": "C95183"},
                 {"label": "Filiasi", "value": "C95184"},
                 {"label": "Fratostita", "value": "C95185"},
                 {"label": "Galicea Mare", "value": "C95186"},
                 {"label": "Galiciuica", "value": "C95187"},
                 {"label": "Gangiova", "value": "C95188"},
                 {"label": "Ghidici", "value": "C95189"},
                 {"label": "Ghindeni", "value": "C95190"},
                 {"label": "Ghizdavesti", "value": "C95191"},
                 {"label": "Gighera", "value": "C95192"},
                 {"label": "Giubega", "value": "C95193"},
                 {"label": "Giurgita", "value": "C95194"},
                 {"label": "Gogosu", "value": "C95195"},
                 {"label": "Goicea", "value": "C95196"},
                 {"label": "Goiesti", "value": "C95197"},
                 {"label": "Grecesti", "value": "C95198"},
                 {"label": "Hunia", "value": "C95199"},
                 {"label": "Intorsura", "value": "C95200"},
                 {"label": "Isalnita", "value": "C95201"},
                 {"label": "Izvoare", "value": "C95202"},
                 {"label": "Leu", "value": "C95203"},
                 {"label": "Lipovu", "value": "C95204"},
                 {"label": "Listeava", "value": "C95205"},
                 {"label": "Macesu de Jos", "value": "C95206"},
                 {"label": "Macesu de Sus", "value": "C95207"},
                 {"label": "Maglavit", "value": "C95208"},
                 {"label": "Malu Mare", "value": "C95209"},
                 {"label": "Marsani", "value": "C95210"},
                 {"label": "Melinesti", "value": "C95211"},
                 {"label": "Mischii", "value": "C95212"},
                 {"label": "Mofleni", "value": "C95213"},
                 {"label": "Motatei", "value": "C95214"},
                 {"label": "Municipiul Bailesti", "value": "C95215"},
                 {"label": "Municipiul Calafat", "value": "C95216"},
                 {"label": "Municipiul Craiova", "value": "C95217"},
                 {"label": "Murgasi", "value": "C95218"},
                 {"label": "Nedeia", "value": "C95219"},
                 {"label": "Negoi", "value": "C95220"},
                 {"label": "Negoiesti", "value": "C95221"},
                 {"label": "Ocolna", "value": "C95222"},
                 {"label": "Oras Bechet", "value": "C95223"},
                 {"label": "Oras Dabuleni", "value": "C95224"},
                 {"label": "Oras Filiasi", "value": "C95225"},
                 {"label": "Oras Segarcea", "value": "C95226"},
                 {"label": "Orodel", "value": "C95227"},
                 {"label": "Ostroveni", "value": "C95228"},
                 {"label": "Padea", "value": "C95229"},
                 {"label": "Perisor", "value": "C95230"},
                 {"label": "Pielesti", "value": "C95231"},
                 {"label": "Piscu Nou", "value": "C95232"},
                 {"label": "Piscu Sadovei", "value": "C95233"},
                 {"label": "Piscu Vechi", "value": "C95234"},
                 {"label": "Plenita", "value": "C95235"},
                 {"label": "Plesoi", "value": "C95236"},
                 {"label": "Podari", "value": "C95237"},
                 {"label": "Poiana Mare", "value": "C95238"},
                 {"label": "Popoveni", "value": "C95239"},
                 {"label": "Prapor", "value": "C95240"},
                 {"label": "Preajba", "value": "C95241"},
                 {"label": "Predesti", "value": "C95242"},
                 {"label": "Puturi", "value": "C95243"},
                 {"label": "Racarii de Sus", "value": "C95244"},
                 {"label": "Radomir", "value": "C95245"},
                 {"label": "Radovan", "value": "C95246"},
                 {"label": "Rast", "value": "C95247"},
                 {"label": "Rojiste", "value": "C95248"},
                 {"label": "Sadova", "value": "C95249"},
                 {"label": "Salcia", "value": "C95250"},
                 {"label": "Salcuta", "value": "C95251"},
                 {"label": "Sarata", "value": "C95252"},
                 {"label": "Satu Nou Calopar", "value": "C95253"},
                 {"label": "Scaesti", "value": "C95254"},
                 {"label": "Seaca de Camp", "value": "C95255"},
                 {"label": "Seaca de Padure", "value": "C95256"},
                 {"label": "Secu", "value": "C95257"},
                 {"label": "Secui", "value": "C95258"},
                 {"label": "Segarcea", "value": "C95259"},
                 {"label": "Sfarcea", "value": "C95260"},
                 {"label": "Silistea Crucii", "value": "C95261"},
                 {"label": "Simnicu de Sus", "value": "C95262"},
                 {"label": "Smardan", "value": "C95263"},
                 {"label": "Sopot", "value": "C95264"},
                 {"label": "Talpas", "value": "C95265"},
                 {"label": "Teasc", "value": "C95266"},
                 {"label": "Terpezita", "value": "C95267"},
                 {"label": "Teslui", "value": "C95268"},
                 {"label": "Tuglui", "value": "C95269"},
                 {"label": "Unirea", "value": "C95270"},
                 {"label": "Urzicuta", "value": "C95271"},
                 {"label": "Valea Stanciului", "value": "C95272"},
                 {"label": "Vartop", "value": "C95273"},
                 {"label": "Varvoru de Jos", "value": "C95274"},
                 {"label": "Vela", "value": "C95275"},
                 {"label": "Verbita", "value": "C95276"},
                 {"label": "Zanoaga", "value": "C95277"},
                 {"label": "Zaval", "value": "C95278"}],
 "Dolneni Municipality": [{"label": "Crniliste", "value": "C65396"},
                          {"label": "Desovo", "value": "C65397"},
                          {"label": "Dolneni", "value": "C65398"},
                          {"label": "Lazani", "value": "C65399"},
                          {"label": "Zitose", "value": "C65400"}],
 "Domagnano": [{"label": "Domagnano", "value": "C106248"}],
 "Domzale Municipality": [{"label": "Dob", "value": "C107615"},
                          {"label": "Domzale", "value": "C107616"},
                          {"label": "Radomlje", "value": "C107617"},
                          {"label": "Vir", "value": "C107618"}],
 "Donduseni District": [{"label": "Briceni", "value": "C75507"},
                        {"label": "Donduseni", "value": "C75508"}],
 "Donetska oblast": [{"label": "Amvrosiivka Raion", "value": "C121800"},
                     {"label": "Amvrosiyivka", "value": "C121801"},
                     {"label": "Avdiyivka", "value": "C121802"},
                     {"label": "Bakhmut", "value": "C121803"},
                     {"label": "Bakhmut Raion", "value": "C121804"},
                     {"label": "Bezimenne", "value": "C121805"},
                     {"label": "Bilytske", "value": "C121806"},
                     {"label": "Blahodatne", "value": "C121807"},
                     {"label": "Boykivske", "value": "C121808"},
                     {"label": "Boykivske Raion", "value": "C121809"},
                     {"label": "Chasiv Yar", "value": "C121810"},
                     {"label": "Chystyakove", "value": "C121811"},
                     {"label": "Debaltseve", "value": "C121812"},
                     {"label": "Dmytrivka", "value": "C121813"},
                     {"label": "Dobropillia Raion", "value": "C121814"},
                     {"label": "Dobropillya", "value": "C121815"},
                     {"label": "Dokuchayevsk", "value": "C121816"},
                     {"label": "Donetsk", "value": "C121817"},
                     {"label": "Druzhkivka", "value": "C121818"},
                     {"label": "Horlivka", "value": "C121819"},
                     {"label": "Ilovaysk", "value": "C121820"},
                     {"label": "Khartsyzk", "value": "C121821"},
                     {"label": "Kostiantynivka Raion", "value": "C121822"},
                     {"label": "Kostyantynivka", "value": "C121823"},
                     {"label": "Kramatorsk", "value": "C121824"},
                     {"label": "Krasnotorka", "value": "C121825"},
                     {"label": "Krinichnaya", "value": "C121826"},
                     {"label": "Kurakhovo", "value": "C121827"},
                     {"label": "Kuteynykove", "value": "C121828"},
                     {"label": "Luhanske", "value": "C121829"},
                     {"label": "Lyman", "value": "C121830"},
                     {"label": "Lyman Raion", "value": "C121831"},
                     {"label": "Makiyivka", "value": "C121832"},
                     {"label": "Makiyivska Miskrada", "value": "C121833"},
                     {"label": "Manhush", "value": "C121834"},
                     {"label": "Maryinka", "value": "C121835"},
                     {"label": "Mariupol", "value": "C121836"},
                     {"label": "Melekyne", "value": "C121837"},
                     {"label": "Mospyne", "value": "C121838"},
                     {"label": "Myrnohrad", "value": "C121839"},
                     {"label": "Nikolske Raion", "value": "C121840"},
                     {"label": "Novoazovsk", "value": "C121841"},
                     {"label": "Novodonetske", "value": "C121842"},
                     {"label": "Novyy Svit", "value": "C121843"},
                     {"label": "Nyzhnya Krynka", "value": "C121844"},
                     {"label": "Pyatypillya", "value": "C121845"},
                     {"label": "Pokrovsk", "value": "C121846"},
                     {"label": "Pokrovsk Raion", "value": "C121847"},
                     {"label": "Rodynske", "value": "C121848"},
                     {"label": "Rozsypne", "value": "C121849"},
                     {"label": "Sartana", "value": "C121850"},
                     {"label": "Selydivska Miskrada", "value": "C121851"},
                     {"label": "Selydove", "value": "C121852"},
                     {"label": "Serebryanka", "value": "C121853"},
                     {"label": "Shakhtarsk", "value": "C121854"},
                     {"label": "Shcherbynivka", "value": "C121855"},
                     {"label": "Shyroke", "value": "C121856"},
                     {"label": "Siversk", "value": "C121857"},
                     {"label": "Sloviansk", "value": "C121858"},
                     {"label": "Sloviansk Raion", "value": "C121859"},
                     {"label": "Snizhne", "value": "C121860"},
                     {"label": "Soledar", "value": "C121861"},
                     {"label": "Starobesheve", "value": "C121862"},
                     {"label": "Staryy Krym", "value": "C121863"},
                     {"label": "Stizhkivske", "value": "C121864"},
                     {"label": "Svetlodarsk", "value": "C121865"},
                     {"label": "Svyatogorsk", "value": "C121866"},
                     {"label": "Syedove", "value": "C121867"},
                     {"label": "Temriuk", "value": "C121868"},
                     {"label": "Toretsk", "value": "C121869"},
                     {"label": "Urzuf", "value": "C121870"},
                     {"label": "Volnovakha", "value": "C121871"},
                     {"label": "Vuhlehirsk", "value": "C121872"},
                     {"label": "Yalta", "value": "C121873"},
                     {"label": "Yasynuvata", "value": "C121874"},
                     {"label": "Yasynuvata Raion", "value": "C121875"},
                     {"label": "Yenakiyeve", "value": "C121876"},
                     {"label": "Zuhres", "value": "C121877"}],
 "Dong Nai": [{"label": "Bien Hoa", "value": "C147361"},
              {"label": "Huyen Dinh Quan", "value": "C147362"},
              {"label": "Huyen Long Thanh", "value": "C147363"},
              {"label": "Huyen Tan Phu", "value": "C147364"},
              {"label": "Huyen Thong Nhat", "value": "C147365"},
              {"label": "Huyen Trang Bom", "value": "C147366"},
              {"label": "Huyen Vinh Cuu", "value": "C147367"}],
 "Dong Thap": [{"label": "Cao Lanh", "value": "C147368"},
               {"label": "Huyen Chau Thanh", "value": "C147369"},
               {"label": "Huyen Lai Vung", "value": "C147370"},
               {"label": "Huyen Lap Vo", "value": "C147371"},
               {"label": "Huyen Tam Nong", "value": "C147372"},
               {"label": "Huyen Tan Hong", "value": "C147373"},
               {"label": "Huyen Thanh Binh", "value": "C147374"},
               {"label": "Huyen Thap Muoi", "value": "C147375"},
               {"label": "Sa Dec", "value": "C147376"},
               {"label": "Sa Dec city", "value": "C147377"},
               {"label": "Thi Tran Tan Thanh", "value": "C147378"}],
 "Donga Department": [{"label": "Bassila", "value": "C9614"},
                      {"label": "Commune of Djougou", "value": "C9615"},
                      {"label": "Djougou", "value": "C9616"}],
 "Dornava Municipality": [{"label": "Dornava", "value": "C107619"}],
 "Dornod Province": [{"label": "Choibalsan", "value": "C75563"},
                     {"label": "Ereencav", "value": "C75564"}],
 "Dosso Region": [{"label": "Boboye Department", "value": "C78054"},
                  {"label": "Departement de Dogondoutchi", "value": "C78055"},
                  {"label": "Departement de Dosso", "value": "C78056"},
                  {"label": "Departement de Gaya", "value": "C78057"},
                  {"label": "Departement de Loga", "value": "C78058"},
                  {"label": "Dogondoutchi", "value": "C78059"},
                  {"label": "Dosso", "value": "C78060"},
                  {"label": "Gaya", "value": "C78061"}],
 "Draa-Tafilalet": [{"label": "Agdz", "value": "C75675"},
                    {"label": "Alnif", "value": "C75676"},
                    {"label": "Aoufous", "value": "C75677"},
                    {"label": "Arfoud", "value": "C75678"},
                    {"label": "Errachidia", "value": "C75679"},
                    {"label": "Imilchil", "value": "C75680"},
                    {"label": "Jebel Tiskaouine", "value": "C75681"},
                    {"label": "Jorf", "value": "C75682"},
                    {"label": "Kelaat Mgouna", "value": "C75683"},
                    {"label": "Mhamid", "value": "C75684"},
                    {"label": "Midelt", "value": "C75685"},
                    {"label": "Ouarzazat", "value": "C75686"},
                    {"label": "Ouarzazate", "value": "C75687"},
                    {"label": "Recani", "value": "C75688"},
                    {"label": "Taznakht", "value": "C75689"},
                    {"label": "Telouet", "value": "C75690"},
                    {"label": "Tinghir", "value": "C75691"},
                    {"label": "Tinghir Province", "value": "C75692"},
                    {"label": "Zagora", "value": "C75693"}],
 "Dravograd Municipality": [{"label": "Dravograd", "value": "C107620"}],
 "Drenthe": [{"label": "Aalden", "value": "C76082"},
             {"label": "Angelslo", "value": "C76083"},
             {"label": "Annen", "value": "C76084"},
             {"label": "Assen", "value": "C76085"},
             {"label": "Ballast", "value": "C76086"},
             {"label": "Barger-Oosterveld", "value": "C76087"},
             {"label": "Bargeres", "value": "C76088"},
             {"label": "Beilen", "value": "C76089"},
             {"label": "Borger", "value": "C76090"},
             {"label": "Coevorden", "value": "C76091"},
             {"label": "Dalen", "value": "C76092"},
             {"label": "De Loo", "value": "C76093"},
             {"label": "De Wijk", "value": "C76094"},
             {"label": "Diever", "value": "C76095"},
             {"label": "Dwingeloo", "value": "C76096"},
             {"label": "Eelde", "value": "C76097"},
             {"label": "Eext", "value": "C76098"},
             {"label": "Emmen", "value": "C76099"},
             {"label": "Emmer-Compascuum", "value": "C76100"},
             {"label": "Emmer-Erfscheidenveen", "value": "C76101"},
             {"label": "Emmerhout", "value": "C76102"},
             {"label": "Emmermeer", "value": "C76103"},
             {"label": "Emmerschans", "value": "C76104"},
             {"label": "Exloo", "value": "C76105"},
             {"label": "Gasselte", "value": "C76106"},
             {"label": "Gasselternijveen", "value": "C76107"},
             {"label": "Gieten", "value": "C76108"},
             {"label": "Havelte", "value": "C76109"},
             {"label": "Hoogeveen", "value": "C76110"},
             {"label": "Klazienaveen", "value": "C76111"},
             {"label": "Koekange", "value": "C76112"},
             {"label": "Krakeel", "value": "C76113"},
             {"label": "Marsdijk", "value": "C76114"},
             {"label": "Meppel", "value": "C76115"},
             {"label": "Nieuw-Buinen", "value": "C76116"},
             {"label": "Nieuw-Dordrecht", "value": "C76117"},
             {"label": "Nieuw-Roden", "value": "C76118"},
             {"label": "Noordbarge", "value": "C76119"},
             {"label": "Norg", "value": "C76120"},
             {"label": "Odoorn", "value": "C76121"},
             {"label": "Oosterhesselen", "value": "C76122"},
             {"label": "Paterswolde", "value": "C76123"},
             {"label": "Peelo", "value": "C76124"},
             {"label": "Peize", "value": "C76125"},
             {"label": "Roden", "value": "C76126"},
             {"label": "Rolde", "value": "C76127"},
             {"label": "Ruinen", "value": "C76128"},
             {"label": "Ruinerwold", "value": "C76129"},
             {"label": "Schoonebeek", "value": "C76130"},
             {"label": "Schoonoord", "value": "C76131"},
             {"label": "Sleen", "value": "C76132"},
             {"label": "Tweede Exloermond", "value": "C76133"},
             {"label": "Tynaarlo", "value": "C76134"},
             {"label": "Uffelte", "value": "C76135"},
             {"label": "Valthe", "value": "C76136"},
             {"label": "Valthermond", "value": "C76137"},
             {"label": "Veenoord", "value": "C76138"},
             {"label": "Vledder", "value": "C76139"},
             {"label": "Vries", "value": "C76140"},
             {"label": "Weiteveen", "value": "C76141"},
             {"label": "Westerbork", "value": "C76142"},
             {"label": "Westlaren", "value": "C76143"},
             {"label": "Wolfsbos", "value": "C76144"},
             {"label": "Zuidlaren", "value": "C76145"}],
 "Drochia District": [{"label": "Drochia", "value": "C75509"}],
 "Duarte Province": [{"label": "Agua Santa del Yuna", "value": "C23883"},
                     {"label": "Arenoso", "value": "C23884"},
                     {"label": "Castillo", "value": "C23885"},
                     {"label": "Hostos", "value": "C23886"},
                     {"label": "Las Guaranas", "value": "C23887"},
                     {"label": "Pimentel", "value": "C23888"},
                     {"label": "San Francisco de Macoris", "value": "C23889"},
                     {"label": "Villa Riva", "value": "C23890"}],
 "Dubai": [{"label": "Dubai", "value": "C123111"}],
 "Dubasari District": [{"label": "Cocieri", "value": "C75510"},
                       {"label": "Ustia", "value": "C75511"}],
 "Dubrovnik-Neretva": [{"label": "Cavtat", "value": "C21062"},
                       {"label": "Cibaca", "value": "C21063"},
                       {"label": "Dubrovacko primorje", "value": "C21064"},
                       {"label": "Grad Dubrovnik", "value": "C21065"},
                       {"label": "Grad Korcula", "value": "C21066"},
                       {"label": "Grad Ploce", "value": "C21067"},
                       {"label": "Komin", "value": "C21068"},
                       {"label": "Konavle", "value": "C21069"},
                       {"label": "Korcula", "value": "C21070"},
                       {"label": "Lastovo", "value": "C21071"},
                       {"label": "Lumbarda", "value": "C21072"},
                       {"label": "Metkovic", "value": "C21073"},
                       {"label": "Mljet", "value": "C21074"},
                       {"label": "Mokosica", "value": "C21075"},
                       {"label": "Opcina Lastovo", "value": "C21076"},
                       {"label": "Opuzen", "value": "C21077"},
                       {"label": "Orebic", "value": "C21078"},
                       {"label": "Podgora", "value": "C21079"},
                       {"label": "Pojezerje", "value": "C21080"},
                       {"label": "Slivno", "value": "C21081"},
                       {"label": "Smokvica", "value": "C21082"},
                       {"label": "Ston", "value": "C21083"},
                       {"label": "Vela Luka", "value": "C21084"},
                       {"label": "Zazablje", "value": "C21085"},
                       {"label": "Zrnovo", "value": "C21086"},
                       {"label": "Zupa dubrovacka", "value": "C21087"}],
 "Dundaga Municipality": [{"label": "Dundaga", "value": "C64863"}],
 "Dundgovi Province": [{"label": "Mandalgovi", "value": "C75565"}],
 "Duplek Municipality": [{"label": "Spodnji Duplek", "value": "C107621"},
                         {"label": "Zgornji Duplek", "value": "C107622"}],
 "Durango": [{"label": "Abasolo", "value": "C67227"},
             {"label": "alvaro Obregon", "value": "C67228"},
             {"label": "Antonio Amaro", "value": "C67229"},
             {"label": "Arcinas", "value": "C67230"},
             {"label": "Arturo Martinez Adame", "value": "C67231"},
             {"label": "Banco Nacional", "value": "C67232"},
             {"label": "Banderas del aguila", "value": "C67233"},
             {"label": "Bermejillo", "value": "C67234"},
             {"label": "California", "value": "C67235"},
             {"label": "Canatlan", "value": "C67236"},
             {"label": "Ceballos", "value": "C67237"},
             {"label": "Cienega de Nuestra Senora de Guadalupe",
              "value": "C67238"},
             {"label": "Cieneguillas", "value": "C67239"},
             {"label": "Cinco de Febrero", "value": "C67240"},
             {"label": "Cinco de Mayo", "value": "C67241"},
             {"label": "Ciudad Guadalupe Victoria", "value": "C67242"},
             {"label": "Ciudad Lerdo", "value": "C67243"},
             {"label": "Cuauhtemoc", "value": "C67244"},
             {"label": "Cuencame", "value": "C67245"},
             {"label": "Cuencame de Ceniceros", "value": "C67246"},
             {"label": "Diez de Octubre", "value": "C67247"},
             {"label": "El Arenal", "value": "C67248"},
             {"label": "El Durazno", "value": "C67249"},
             {"label": "El Huarache (El Guarache)", "value": "C67250"},
             {"label": "El Lucero", "value": "C67251"},
             {"label": "El Nayar", "value": "C67252"},
             {"label": "El Rayo", "value": "C67253"},
             {"label": "El Salto", "value": "C67254"},
             {"label": "El Vergel", "value": "C67255"},
             {"label": "Emiliano Zapata", "value": "C67256"},
             {"label": "Esfuerzos Unidos", "value": "C67257"},
             {"label": "Esmeralda", "value": "C67258"},
             {"label": "Eureka de Media Luna (Eureka)", "value": "C67259"},
             {"label": "Felipe Carrillo Puerto", "value": "C67260"},
             {"label": "Francisco I. Madero", "value": "C67261"},
             {"label": "Gabriel Hernandez (Mancinas)", "value": "C67262"},
             {"label": "General Calixto Contreras", "value": "C67263"},
             {"label": "General Lazaro Cardenas", "value": "C67264"},
             {"label": "Gomez Palacio", "value": "C67265"},
             {"label": "Guanacevi", "value": "C67266"},
             {"label": "Guatimape", "value": "C67267"},
             {"label": "Hidalgo", "value": "C67268"},
             {"label": "Ignacio Allende", "value": "C67269"},
             {"label": "Ignacio Ramirez", "value": "C67270"},
             {"label": "Ignacio Zaragoza", "value": "C67271"},
             {"label": "Inde", "value": "C67272"},
             {"label": "Jauja", "value": "C67273"},
             {"label": "Jimenez", "value": "C67274"},
             {"label": "Jose Guadalupe Aguilera (Santa Lucia)",
              "value": "C67275"},
             {"label": "Jose Guadalupe Rodriguez", "value": "C67276"},
             {"label": "Jose Maria Morelos", "value": "C67277"},
             {"label": "Jose Maria Pino Suarez", "value": "C67278"},
             {"label": "Jose Refugio Salcido", "value": "C67279"},
             {"label": "Juan E. Garcia", "value": "C67280"},
             {"label": "La Ciudad", "value": "C67281"},
             {"label": "La Flor", "value": "C67282"},
             {"label": "La Goma", "value": "C67283"},
             {"label": "La Joya", "value": "C67284"},
             {"label": "La Loma", "value": "C67285"},
             {"label": "La Luz", "value": "C67286"},
             {"label": "La Popular", "value": "C67287"},
             {"label": "Las Cuevas", "value": "C67288"},
             {"label": "Leon Guzman", "value": "C67289"},
             {"label": "Llano Grande", "value": "C67290"},
             {"label": "Los Angeles", "value": "C67291"},
             {"label": "Mapimi", "value": "C67292"},
             {"label": "Mezquital", "value": "C67293"},
             {"label": "Narciso Mendoza", "value": "C67294"},
             {"label": "Nazareno", "value": "C67295"},
             {"label": "Nazas", "value": "C67296"},
             {"label": "Nicolas Bravo", "value": "C67297"},
             {"label": "Nogales", "value": "C67298"},
             {"label": "Nombre de Dios", "value": "C67299"},
             {"label": "Nuevo Ideal", "value": "C67300"},
             {"label": "Ocampo", "value": "C67301"},
             {"label": "Orizaba", "value": "C67302"},
             {"label": "Panuco de Coronado", "value": "C67303"},
             {"label": "Pasaje", "value": "C67304"},
             {"label": "Paso Nacional", "value": "C67305"},
             {"label": "Pastor Rovaix", "value": "C67306"},
             {"label": "Pedricena", "value": "C67307"},
             {"label": "Picardias", "value": "C67308"},
             {"label": "Pueblo Nuevo", "value": "C67309"},
             {"label": "Ramon Corona", "value": "C67310"},
             {"label": "Ricardo Flores Magon", "value": "C67311"},
             {"label": "Rodeo", "value": "C67312"},
             {"label": "San Atenogenes (La Villita)", "value": "C67313"},
             {"label": "San Bernardino de Milpillas Chico", "value": "C67314"},
             {"label": "San Bernardo", "value": "C67315"},
             {"label": "San Felipe", "value": "C67316"},
             {"label": "San Francisco de Horizonte (Horizonte)",
              "value": "C67317"},
             {"label": "San Francisco del Mezquital", "value": "C67318"},
             {"label": "San Francisco Javier", "value": "C67319"},
             {"label": "San Jacinto", "value": "C67320"},
             {"label": "San Jose de Gracia", "value": "C67321"},
             {"label": "San Jose de Vinedo", "value": "C67322"},
             {"label": "San Jose de Zaragoza", "value": "C67323"},
             {"label": "San Juan de Guadalupe", "value": "C67324"},
             {"label": "San Juan del Rio del Centauro del Norte",
              "value": "C67325"},
             {"label": "San Julio", "value": "C67326"},
             {"label": "San Miguel de Cruces", "value": "C67327"},
             {"label": "San Pedro del Gallo", "value": "C67328"},
             {"label": "Santa Catarina de Tepehuanes", "value": "C67329"},
             {"label": "Santa Clara", "value": "C67330"},
             {"label": "Santa Cruz Lujan", "value": "C67331"},
             {"label": "Santa Maria del Oro", "value": "C67332"},
             {"label": "Santiago Bayacora", "value": "C67333"},
             {"label": "Santiago Papasquiaro", "value": "C67334"},
             {"label": "Sapioris", "value": "C67335"},
             {"label": "Sebastian Lerdo de Tejada", "value": "C67336"},
             {"label": "Seis de Enero", "value": "C67337"},
             {"label": "Seis de Octubre", "value": "C67338"},
             {"label": "Suchil", "value": "C67339"},
             {"label": "Tamazula", "value": "C67340"},
             {"label": "Tamazula de Victoria", "value": "C67341"},
             {"label": "Tayoltita", "value": "C67342"},
             {"label": "Tepehuanes", "value": "C67343"},
             {"label": "Tlahualilo de Zaragoza", "value": "C67344"},
             {"label": "Topia", "value": "C67345"},
             {"label": "Transporte", "value": "C67346"},
             {"label": "Velardena", "value": "C67347"},
             {"label": "Venecia", "value": "C67348"},
             {"label": "Venustiano Carranza", "value": "C67349"},
             {"label": "Vicente Guerrero", "value": "C67350"},
             {"label": "Victoria de Durango", "value": "C67351"},
             {"label": "Villa de Guadalupe", "value": "C67352"},
             {"label": "Villa Gregorio Garcia", "value": "C67353"},
             {"label": "Villa Hidalgo", "value": "C67354"},
             {"label": "Villa Juarez", "value": "C67355"},
             {"label": "Villa Montemorelos", "value": "C67356"},
             {"label": "Villa Ocampo", "value": "C67357"},
             {"label": "Villa Union", "value": "C67358"}],
 "Durazno Department": [{"label": "Blanquillo", "value": "C146874"},
                        {"label": "Carlos Reyles", "value": "C146875"},
                        {"label": "Durazno", "value": "C146876"},
                        {"label": "La Paloma", "value": "C146877"},
                        {"label": "Santa Bernardina", "value": "C146878"},
                        {"label": "Sarandi del Yi", "value": "C146879"},
                        {"label": "Villa del Carmen", "value": "C146880"}],
 "Durbe Municipality": [{"label": "Liegi", "value": "C64864"}],
 "Durres District": [{"label": "Bashkia Durres", "value": "C117"},
                     {"label": "Bashkia Kruje", "value": "C118"},
                     {"label": "Bashkia Shijak", "value": "C119"},
                     {"label": "Durres", "value": "C120"},
                     {"label": "Durres District", "value": "C121"},
                     {"label": "Fushe-Kruje", "value": "C122"},
                     {"label": "Kruje", "value": "C123"},
                     {"label": "Rrethi i Krujes", "value": "C124"},
                     {"label": "Shijak", "value": "C125"},
                     {"label": "Sukth", "value": "C126"}],
 "Duzce": [{"label": "Akcakoca", "value": "C120236"},
           {"label": "Cilimli Ilcesi", "value": "C120237"},
           {"label": "Cumayeri Ilcesi", "value": "C120238"},
           {"label": "Duzce", "value": "C120239"},
           {"label": "Golyaka Ilcesi", "value": "C120240"},
           {"label": "Gumusova Ilcesi", "value": "C120241"},
           {"label": "Kaynasli Ilcesi", "value": "C120242"},
           {"label": "Yigilca Ilcesi", "value": "C120243"}],
 "East": [{"label": "Abong Mbang", "value": "C16603"},
          {"label": "Batouri", "value": "C16604"},
          {"label": "Belabo", "value": "C16605"},
          {"label": "Bertoua", "value": "C16606"},
          {"label": "Betare Oya", "value": "C16607"},
          {"label": "Dimako", "value": "C16608"},
          {"label": "Doume", "value": "C16609"},
          {"label": "Garoua Boulai", "value": "C16610"},
          {"label": "Mbang", "value": "C16611"},
          {"label": "Ndelele", "value": "C16612"},
          {"label": "Yokadouma", "value": "C16613"}],
 "East Attica Regional Unit": [{"label": "agios Athanasios", "value": "C42137"},
                               {"label": "Alexandroupoli", "value": "C42138"},
                               {"label": "Amygdaleonas", "value": "C42139"},
                               {"label": "aratos", "value": "C42140"},
                               {"label": "Arisvi", "value": "C42141"},
                               {"label": "Arriana", "value": "C42142"},
                               {"label": "avato", "value": "C42143"},
                               {"label": "Choristi", "value": "C42144"},
                               {"label": "Chrysochori", "value": "C42145"},
                               {"label": "Chrysoupolis", "value": "C42146"},
                               {"label": "Didymoteicho", "value": "C42147"},
                               {"label": "Drama", "value": "C42148"},
                               {"label": "Echinos", "value": "C42149"},
                               {"label": "Elaiochori", "value": "C42150"},
                               {"label": "Eleftheres", "value": "C42151"},
                               {"label": "Eleftheroupolis", "value": "C42152"},
                               {"label": "evlalo", "value": "C42153"},
                               {"label": "Feres", "value": "C42154"},
                               {"label": "Fillyra", "value": "C42155"},
                               {"label": "Fotolivos", "value": "C42156"},
                               {"label": "Ftelia", "value": "C42157"},
                               {"label": "Genissea", "value": "C42158"},
                               {"label": "iasmos", "value": "C42159"},
                               {"label": "Iliokentima", "value": "C42160"},
                               {"label": "Kalampaki", "value": "C42161"},
                               {"label": "Kali Vrysi", "value": "C42162"},
                               {"label": "Kallifytos", "value": "C42163"},
                               {"label": "Kalos Agros", "value": "C42164"},
                               {"label": "Kamariotissa", "value": "C42165"},
                               {"label": "Kastanies", "value": "C42166"},
                               {"label": "Kato Nevrokopi", "value": "C42167"},
                               {"label": "Kavala", "value": "C42168"},
                               {"label": "Kavyli", "value": "C42169"},
                               {"label": "Keramoti", "value": "C42170"},
                               {"label": "Kimmeria", "value": "C42171"},
                               {"label": "Kokkinochoma", "value": "C42172"},
                               {"label": "Komotini", "value": "C42173"},
                               {"label": "Krinides", "value": "C42174"},
                               {"label": "Kyprinos", "value": "C42175"},
                               {"label": "Kyria", "value": "C42176"},
                               {"label": "Lagos", "value": "C42177"},
                               {"label": "Lavara", "value": "C42178"},
                               {"label": "Limenaria", "value": "C42179"},
                               {"label": "Lykeio", "value": "C42180"},
                               {"label": "Mikropolis", "value": "C42181"},
                               {"label": "Myki", "value": "C42182"},
                               {"label": "Nea Iraklitsa", "value": "C42183"},
                               {"label": "Nea Karvali", "value": "C42184"},
                               {"label": "Nea Karya", "value": "C42185"},
                               {"label": "Nea Peramos", "value": "C42186"},
                               {"label": "Nea Vyssa", "value": "C42187"},
                               {"label": "Neochori", "value": "C42188"},
                               {"label": "Nikisiani", "value": "C42189"},
                               {"label": "Orestiada", "value": "C42190"},
                               {"label": "Pachni", "value": "C42191"},
                               {"label": "Palaio Tsifliki", "value": "C42192"},
                               {"label": "Palaiochori", "value": "C42193"},
                               {"label": "Paralia Ofryniou", "value": "C42194"},
                               {"label": "Paranesti", "value": "C42195"},
                               {"label": "Peplos", "value": "C42196"},
                               {"label": "Peteinos", "value": "C42197"},
                               {"label": "Petrochori", "value": "C42198"},
                               {"label": "Petroussa", "value": "C42199"},
                               {"label": "Potamia", "value": "C42200"},
                               {"label": "Prinos", "value": "C42201"},
                               {"label": "Prosotsani", "value": "C42202"},
                               {"label": "Rizia", "value": "C42203"},
                               {"label": "Samothraki", "value": "C42204"},
                               {"label": "Sapes", "value": "C42205"},
                               {"label": "Selero", "value": "C42206"},
                               {"label": "Sitagroi", "value": "C42207"},
                               {"label": "Sminthi", "value": "C42208"},
                               {"label": "Soufli", "value": "C42209"},
                               {"label": "Thasos", "value": "C42210"},
                               {"label": "Tychero", "value": "C42211"},
                               {"label": "Volakas", "value": "C42212"},
                               {"label": "Xanthi", "value": "C42213"},
                               {"label": "Xiropotamos", "value": "C42214"},
                               {"label": "Xylagani", "value": "C42215"},
                               {"label": "Zygos", "value": "C42216"}],
 "East Azerbaijan": [{"label": "Ajab Shir", "value": "C50091"},
                     {"label": "Ahar", "value": "C50092"},
                     {"label": "Bonab", "value": "C50093"},
                     {"label": "Hashtrud", "value": "C50094"},
                     {"label": "Marand", "value": "C50095"},
                     {"label": "Sarab", "value": "C50096"},
                     {"label": "Shahrestan-e Ajab Shir", "value": "C50097"},
                     {"label": "Shahrestan-e azarshahr", "value": "C50098"},
                     {"label": "Shahrestan-e Bonab", "value": "C50099"},
                     {"label": "Shahrestan-e Bostanabad", "value": "C50100"},
                     {"label": "Shahrestan-e Charavimaq", "value": "C50101"},
                     {"label": "Shahrestan-e Hashtrud", "value": "C50102"},
                     {"label": "Shahrestan-e Heris", "value": "C50103"},
                     {"label": "Shahrestan-e Jolfa", "value": "C50104"},
                     {"label": "Shahrestan-e Khoda afarin", "value": "C50105"},
                     {"label": "Shahrestan-e Malekan", "value": "C50106"},
                     {"label": "Shahrestan-e Maragheh", "value": "C50107"},
                     {"label": "Shahrestan-e Mianeh", "value": "C50108"},
                     {"label": "Shahrestan-e Osku", "value": "C50109"},
                     {"label": "Shahrestan-e Sarab", "value": "C50110"},
                     {"label": "Shahrestan-e Tabriz", "value": "C50111"},
                     {"label": "Shahrestan-e Varzaqan", "value": "C50112"},
                     {"label": "Tabriz", "value": "C50113"}],
 "East Berbice-Corentyne": [{"label": "New Amsterdam", "value": "C43069"},
                            {"label": "Skeldon", "value": "C43070"}],
 "East Darfur": [{"label": "El Daein", "value": "C115403"}],
 "East Grand Bahama": [{"label": "High Rock", "value": "C119608"}],
 "East Kazakhstan Region": [{"label": "Altayskiy", "value": "C64347"},
                            {"label": "Aqtoghay", "value": "C64348"},
                            {"label": "Asubulaq", "value": "C64349"},
                            {"label": "Auezov", "value": "C64350"},
                            {"label": "Ayagoz", "value": "C64351"},
                            {"label": "Belogorskiy", "value": "C64352"},
                            {"label": "Belousovka", "value": "C64353"},
                            {"label": "Borodulikha", "value": "C64354"},
                            {"label": "Georgievka", "value": "C64355"},
                            {"label": "Glubokoye", "value": "C64356"},
                            {"label": "Kurchatov", "value": "C64357"},
                            {"label": "Kurchum", "value": "C64358"},
                            {"label": "Maleyevsk", "value": "C64359"},
                            {"label": "Ognevka", "value": "C64360"},
                            {"label": "Priisk Boko", "value": "C64361"},
                            {"label": "Qarauyl", "value": "C64362"},
                            {"label": "Ridder", "value": "C64363"},
                            {"label": "Semey", "value": "C64364"},
                            {"label": "Shar", "value": "C64365"},
                            {"label": "Shemonaikha", "value": "C64366"},
                            {"label": "Suykbulak", "value": "C64367"},
                            {"label": "Tughyl", "value": "C64368"},
                            {"label": "Urzhar", "value": "C64369"},
                            {"label": "Ust-Kamenogorsk", "value": "C64370"},
                            {"label": "ust-Talovka", "value": "C64371"},
                            {"label": "Zaysan", "value": "C64372"},
                            {"label": "Zhalghyztobe", "value": "C64373"},
                            {"label": "Zhanga Buqtyrma", "value": "C64374"},
                            {"label": "Zhezkent", "value": "C64375"},
                            {"label": "Zyryanovsk", "value": "C64376"}],
 "East New Britain": [{"label": "Gazelle", "value": "C80402"},
                      {"label": "Kokopo", "value": "C80403"},
                      {"label": "Pomio", "value": "C80404"},
                      {"label": "Rabaul", "value": "C80405"}],
 "Eastern": [{"label": "Aburi", "value": "C41509"},
             {"label": "Akim Oda", "value": "C41510"},
             {"label": "Akim Swedru", "value": "C41511"},
             {"label": "Akropong", "value": "C41512"},
             {"label": "Akwatia", "value": "C41513"},
             {"label": "Asamankese", "value": "C41514"},
             {"label": "Begoro", "value": "C41515"},
             {"label": "Kibi", "value": "C41516"},
             {"label": "Koforidua", "value": "C41517"},
             {"label": "Mpraeso", "value": "C41518"},
             {"label": "Nsawam", "value": "C41519"},
             {"label": "Suhum", "value": "C41520"}],
 "Eastern Cape": [{"label": "Adelaide", "value": "C107926"},
                  {"label": "Alfred Nzo District Municipality",
                   "value": "C107927"},
                  {"label": "Alice", "value": "C107928"},
                  {"label": "Aliwal North", "value": "C107929"},
                  {"label": "Amathole District Municipality",
                   "value": "C107930"},
                  {"label": "Bhisho", "value": "C107931"},
                  {"label": "Buffalo City Metropolitan Municipality",
                   "value": "C107932"},
                  {"label": "Burgersdorp", "value": "C107933"},
                  {"label": "Butterworth", "value": "C107934"},
                  {"label": "Cacadu District Municipality", "value": "C107935"},
                  {"label": "Chris Hani District Municipality",
                   "value": "C107936"},
                  {"label": "Cradock", "value": "C107937"},
                  {"label": "Dordrecht", "value": "C107938"},
                  {"label": "East London", "value": "C107939"},
                  {"label": "Elliot", "value": "C107940"},
                  {"label": "Fort Beaufort", "value": "C107941"},
                  {"label": "Graaff-Reinet", "value": "C107942"},
                  {"label": "Grahamstown", "value": "C107943"},
                  {"label": "Ilinge", "value": "C107944"},
                  {"label": "Joe Gqabi District Municipality",
                   "value": "C107945"},
                  {"label": "Kirkwood", "value": "C107946"},
                  {"label": "Kruisfontein", "value": "C107947"},
                  {"label": "Lady Frere", "value": "C107948"},
                  {"label": "Middelburg", "value": "C107949"},
                  {"label": "Molteno", "value": "C107950"},
                  {"label": "Mthatha", "value": "C107951"},
                  {"label": "Nelson Mandela Bay Metropolitan Municipality",
                   "value": "C107952"},
                  {"label": "OR Tambo District Municipality",
                   "value": "C107953"},
                  {"label": "Port Alfred", "value": "C107954"},
                  {"label": "Port Elizabeth", "value": "C107955"},
                  {"label": "Port Saint Johns", "value": "C107956"},
                  {"label": "Queensdale", "value": "C107957"},
                  {"label": "Queenstown", "value": "C107958"},
                  {"label": "Somerset East", "value": "C107959"},
                  {"label": "Stutterheim", "value": "C107960"},
                  {"label": "Uitenhage", "value": "C107961"},
                  {"label": "Whittlesea", "value": "C107962"},
                  {"label": "Willowmore", "value": "C107963"}],
 "Eastern Development Region": [{"label": "Bhadrapur", "value": "C76037"},
                                {"label": "Bhojpur", "value": "C76038"},
                                {"label": "Biratnagar", "value": "C76039"},
                                {"label": "Dhankuta", "value": "C76040"},
                                {"label": "Dharan", "value": "C76041"},
                                {"label": "Ilam", "value": "C76042"},
                                {"label": "Inaruwa", "value": "C76043"},
                                {"label": "Khanbari", "value": "C76044"},
                                {"label": "Lahan", "value": "C76045"},
                                {"label": "Lobujya", "value": "C76046"},
                                {"label": "Namche Bazar", "value": "C76047"},
                                {"label": "Rajbiraj", "value": "C76048"},
                                {"label": "Siraha", "value": "C76049"},
                                {"label": "Titahari", "value": "C76050"},
                                {"label": "Triyuga", "value": "C76051"}],
 "Eastern Division": [{"label": "Kadavu Province", "value": "C24811"},
                      {"label": "Lau Province", "value": "C24812"},
                      {"label": "Levuka", "value": "C24813"},
                      {"label": "Lomaiviti Province", "value": "C24814"}],
 "Eastern Highlands Province": [{"label": "Daulo", "value": "C80406"},
                                {"label": "Goroka", "value": "C80407"},
                                {"label": "Henganofi", "value": "C80408"},
                                {"label": "Kainantu", "value": "C80409"},
                                {"label": "Lufa", "value": "C80410"},
                                {"label": "Obura Wonenara", "value": "C80411"},
                                {"label": "Okapa", "value": "C80412"},
                                {"label": "Unggai Bena", "value": "C80413"}],
 "Eastern Province": [{"label": "Kibungo", "value": "C105710"},
                      {"label": "Rwamagana", "value": "C105711"},
                      {"label": "Abqaiq", "value": "C106517"},
                      {"label": "Al Awjam", "value": "C106518"},
                      {"label": "Al Battaliyah", "value": "C106519"},
                      {"label": "Al Hufuf", "value": "C106520"},
                      {"label": "Al Jafr", "value": "C106521"},
                      {"label": "Al Jubayl", "value": "C106522"},
                      {"label": "Al Khafji", "value": "C106523"},
                      {"label": "Al Markaz", "value": "C106524"},
                      {"label": "Al Mubarraz", "value": "C106525"},
                      {"label": "Al Munayzilah", "value": "C106526"},
                      {"label": "Al Mutayrifi", "value": "C106527"},
                      {"label": "Al Qarah", "value": "C106528"},
                      {"label": "Al Qatif", "value": "C106529"},
                      {"label": "Al Qurayn", "value": "C106530"},
                      {"label": "Al Ubaylah", "value": "C106531"},
                      {"label": "Al-Awamiyah", "value": "C106532"},
                      {"label": "Al-Awjam", "value": "C106533"},
                      {"label": "Al-Mubarraz", "value": "C106534"},
                      {"label": "As Saffaniyah", "value": "C106535"},
                      {"label": "At Taraf", "value": "C106536"},
                      {"label": "At Tubi", "value": "C106537"},
                      {"label": "Dammam", "value": "C106538"},
                      {"label": "Dhahran", "value": "C106539"},
                      {"label": "Hail ", "value": "C106540"},
                      {"label": "Hafar Al-Batin", "value": "C106541"},
                      {"label": "Haradh", "value": "C106542"},
                      {"label": "Jubbah", "value": "C106543"},
                      {"label": "Julayjilah", "value": "C106544"},
                      {"label": "Khobar", "value": "C106545"},
                      {"label": "Mawqaq", "value": "C106546"},
                      {"label": "Mulayjah", "value": "C106547"},
                      {"label": "Nariyah", "value": "C106548"},
                      {"label": "Qaisumah", "value": "C106549"},
                      {"label": "Qufar", "value": "C106550"},
                      {"label": "Rahimah", "value": "C106551"},
                      {"label": "Safwa", "value": "C106552"},
                      {"label": "Sayhat", "value": "C106553"},
                      {"label": "Simira", "value": "C106554"},
                      {"label": "Tarut", "value": "C106555"},
                      {"label": "Udhailiyah", "value": "C106556"},
                      {"label": "Umm as Sahik", "value": "C106557"},
                      {"label": "Uqair", "value": "C106558"},
                      {"label": "Barma", "value": "C107237"},
                      {"label": "Blama", "value": "C107238"},
                      {"label": "Boajibu", "value": "C107239"},
                      {"label": "Buedu", "value": "C107240"},
                      {"label": "Bunumbu", "value": "C107241"},
                      {"label": "Daru", "value": "C107242"},
                      {"label": "Giehun", "value": "C107243"},
                      {"label": "Gorahun", "value": "C107244"},
                      {"label": "Hangha", "value": "C107245"},
                      {"label": "Jojoima", "value": "C107246"},
                      {"label": "Kailahun", "value": "C107247"},
                      {"label": "Kailahun District", "value": "C107248"},
                      {"label": "Kayima", "value": "C107249"},
                      {"label": "Kenema", "value": "C107250"},
                      {"label": "Kenema District", "value": "C107251"},
                      {"label": "Koidu", "value": "C107252"},
                      {"label": "Kono District", "value": "C107253"},
                      {"label": "Koyima", "value": "C107254"},
                      {"label": "Manowa", "value": "C107255"},
                      {"label": "Mobai", "value": "C107256"},
                      {"label": "Motema", "value": "C107257"},
                      {"label": "Panguma", "value": "C107258"},
                      {"label": "Pendembu", "value": "C107259"},
                      {"label": "Segbwema", "value": "C107260"},
                      {"label": "Simbakoro", "value": "C107261"},
                      {"label": "Tefeya", "value": "C107262"},
                      {"label": "Tombodu", "value": "C107263"},
                      {"label": "Tombu", "value": "C107264"},
                      {"label": "Wima", "value": "C107265"},
                      {"label": "Yengema", "value": "C107266"},
                      {"label": "Ampara", "value": "C115264"},
                      {"label": "Ampara District", "value": "C115265"},
                      {"label": "Batticaloa", "value": "C115266"},
                      {"label": "Batticaloa District", "value": "C115267"},
                      {"label": "Eravur Town", "value": "C115268"},
                      {"label": "Kalmunai", "value": "C115269"},
                      {"label": "Trincomalee", "value": "C115270"},
                      {"label": "Trincomalee District", "value": "C115271"},
                      {"label": "Vakarai", "value": "C115272"},
                      {"label": "Chadiza", "value": "C148105"},
                      {"label": "Chipata", "value": "C148106"},
                      {"label": "Lundazi", "value": "C148107"},
                      {"label": "Nyimba", "value": "C148108"},
                      {"label": "Petauke", "value": "C148109"}],
 "Eastern Region": [{"label": "Borgarfjardarhreppur", "value": "C44831"},
                    {"label": "Breiddalshreppur", "value": "C44832"},
                    {"label": "Egilsstadir", "value": "C44833"},
                    {"label": "Eskifjordur", "value": "C44834"},
                    {"label": "Fjardabyggd", "value": "C44835"},
                    {"label": "Fljotsdalsherad", "value": "C44836"},
                    {"label": "Fljotsdalshreppur", "value": "C44837"},
                    {"label": "Hofn", "value": "C44838"},
                    {"label": "Neskaupstadur", "value": "C44839"},
                    {"label": "Reydarfjordur", "value": "C44840"},
                    {"label": "Bugembe", "value": "C121230"},
                    {"label": "Bugiri", "value": "C121231"},
                    {"label": "Bukwa District", "value": "C121232"},
                    {"label": "Bulambuli District", "value": "C121233"},
                    {"label": "Busembatia", "value": "C121234"},
                    {"label": "Busia", "value": "C121235"},
                    {"label": "Buwenge", "value": "C121236"},
                    {"label": "Iganga", "value": "C121237"},
                    {"label": "Jinja", "value": "C121238"},
                    {"label": "Kamuli", "value": "C121239"},
                    {"label": "Kapchorwa", "value": "C121240"},
                    {"label": "Kibuku District", "value": "C121241"},
                    {"label": "Kumi", "value": "C121242"},
                    {"label": "Mayuge", "value": "C121243"},
                    {"label": "Mbale", "value": "C121244"},
                    {"label": "Pallisa", "value": "C121245"},
                    {"label": "Sironko", "value": "C121246"},
                    {"label": "Soroti", "value": "C121247"},
                    {"label": "Tororo", "value": "C121248"}],
 "Eastern Tobago": [{"label": "Scarborough", "value": "C119662"}],
 "Eastern Visayas": [{"label": "Abuyog", "value": "C86065"},
                     {"label": "Alangalang", "value": "C86066"},
                     {"label": "Albuera", "value": "C86067"},
                     {"label": "Allen", "value": "C86068"},
                     {"label": "Almagro", "value": "C86069"},
                     {"label": "Almeria", "value": "C86070"},
                     {"label": "Alugan", "value": "C86071"},
                     {"label": "Anahawan", "value": "C86072"},
                     {"label": "Anito", "value": "C86073"},
                     {"label": "Arteche", "value": "C86074"},
                     {"label": "Babatngon", "value": "C86075"},
                     {"label": "Balagtas", "value": "C86076"},
                     {"label": "Balagui", "value": "C86077"},
                     {"label": "Balangiga", "value": "C86078"},
                     {"label": "Balangkayan", "value": "C86079"},
                     {"label": "Balinsacayao", "value": "C86080"},
                     {"label": "Balite", "value": "C86081"},
                     {"label": "Balocawehay", "value": "C86082"},
                     {"label": "Balogo", "value": "C86083"},
                     {"label": "Balud", "value": "C86084"},
                     {"label": "Bantayan", "value": "C86085"},
                     {"label": "Bantiqui", "value": "C86086"},
                     {"label": "Baras", "value": "C86087"},
                     {"label": "Barugo", "value": "C86088"},
                     {"label": "Basey", "value": "C86089"},
                     {"label": "Basud", "value": "C86090"},
                     {"label": "Bato", "value": "C86091"},
                     {"label": "Baybay", "value": "C86092"},
                     {"label": "Bilwang", "value": "C86093"},
                     {"label": "Biri", "value": "C86094"},
                     {"label": "Bislig", "value": "C86095"},
                     {"label": "Bitanjuan", "value": "C86096"},
                     {"label": "Bobon", "value": "C86097"},
                     {"label": "Bontoc", "value": "C86098"},
                     {"label": "Borongan", "value": "C86099"},
                     {"label": "Bugho", "value": "C86100"},
                     {"label": "Bugko", "value": "C86101"},
                     {"label": "Bunga", "value": "C86102"},
                     {"label": "Burauen", "value": "C86103"},
                     {"label": "Butazon", "value": "C86104"},
                     {"label": "Cabacungan", "value": "C86105"},
                     {"label": "Cabay", "value": "C86106"},
                     {"label": "Cabodiongan", "value": "C86107"},
                     {"label": "Cabucgayan", "value": "C86108"},
                     {"label": "Cagamotan", "value": "C86109"},
                     {"label": "Caibiran", "value": "C86110"},
                     {"label": "Calape", "value": "C86111"},
                     {"label": "Calbayog", "value": "C86112"},
                     {"label": "Calbiga", "value": "C86113"},
                     {"label": "Calubian", "value": "C86114"},
                     {"label": "Can-avid", "value": "C86115"},
                     {"label": "Canhandugan", "value": "C86116"},
                     {"label": "Capoocan", "value": "C86117"},
                     {"label": "Capul", "value": "C86118"},
                     {"label": "Caraycaray", "value": "C86119"},
                     {"label": "Caridad", "value": "C86120"},
                     {"label": "Carigara", "value": "C86121"},
                     {"label": "Catarman", "value": "C86122"},
                     {"label": "Catbalogan", "value": "C86123"},
                     {"label": "Catmon", "value": "C86124"},
                     {"label": "Catubig", "value": "C86125"},
                     {"label": "Cavite", "value": "C86126"},
                     {"label": "Cervantes", "value": "C86127"},
                     {"label": "Concepcion", "value": "C86128"},
                     {"label": "Consuegra", "value": "C86129"},
                     {"label": "Culaba", "value": "C86130"},
                     {"label": "Culasian", "value": "C86131"},
                     {"label": "Dagami", "value": "C86132"},
                     {"label": "Dao", "value": "C86133"},
                     {"label": "Dapdap", "value": "C86134"},
                     {"label": "Daram", "value": "C86135"},
                     {"label": "Dolores", "value": "C86136"},
                     {"label": "Doos", "value": "C86137"},
                     {"label": "Dulag", "value": "C86138"},
                     {"label": "Erenas", "value": "C86139"},
                     {"label": "Gabas", "value": "C86140"},
                     {"label": "Gamay", "value": "C86141"},
                     {"label": "Gandara", "value": "C86142"},
                     {"label": "General MacArthur", "value": "C86143"},
                     {"label": "Ginabuyan", "value": "C86144"},
                     {"label": "Giporlos", "value": "C86145"},
                     {"label": "Gubang", "value": "C86146"},
                     {"label": "Guindapunan", "value": "C86147"},
                     {"label": "Guirang", "value": "C86148"},
                     {"label": "Guiuan", "value": "C86149"},
                     {"label": "Hernani", "value": "C86150"},
                     {"label": "Hilongos", "value": "C86151"},
                     {"label": "Hinabangan", "value": "C86152"},
                     {"label": "Hindang", "value": "C86153"},
                     {"label": "Hingatungan", "value": "C86154"},
                     {"label": "Hinunangan", "value": "C86155"},
                     {"label": "Hinundayan", "value": "C86156"},
                     {"label": "Hipadpad", "value": "C86157"},
                     {"label": "Hipasngo", "value": "C86158"},
                     {"label": "Ibarra", "value": "C86159"},
                     {"label": "Inangatan", "value": "C86160"},
                     {"label": "Inopacan", "value": "C86161"},
                     {"label": "Ipil", "value": "C86162"},
                     {"label": "Isabel", "value": "C86163"},
                     {"label": "Jaro", "value": "C86164"},
                     {"label": "Javier", "value": "C86165"},
                     {"label": "Jiabong", "value": "C86166"},
                     {"label": "Jipapad", "value": "C86167"},
                     {"label": "Jubasan", "value": "C86168"},
                     {"label": "Julita", "value": "C86169"},
                     {"label": "Kabuynan", "value": "C86170"},
                     {"label": "Kampokpok", "value": "C86171"},
                     {"label": "Kananga", "value": "C86172"},
                     {"label": "Kawayan", "value": "C86173"},
                     {"label": "Kilim", "value": "C86174"},
                     {"label": "La Paz", "value": "C86175"},
                     {"label": "Lalauigan", "value": "C86176"},
                     {"label": "Lamak", "value": "C86177"},
                     {"label": "Lao", "value": "C86178"},
                     {"label": "Laoang", "value": "C86179"},
                     {"label": "Lapinig", "value": "C86180"},
                     {"label": "Las Navas", "value": "C86181"},
                     {"label": "Lavezares", "value": "C86182"},
                     {"label": "Leyte", "value": "C86183"},
                     {"label": "Libagon", "value": "C86184"},
                     {"label": "Libas", "value": "C86185"},
                     {"label": "Libertad", "value": "C86186"},
                     {"label": "Liberty", "value": "C86187"},
                     {"label": "Liloan", "value": "C86188"},
                     {"label": "Lim-oo", "value": "C86189"},
                     {"label": "Limasawa", "value": "C86190"},
                     {"label": "Limon", "value": "C86191"},
                     {"label": "Llorente", "value": "C86192"},
                     {"label": "Looc", "value": "C86193"},
                     {"label": "Lope de Vega", "value": "C86194"},
                     {"label": "Lubi", "value": "C86195"},
                     {"label": "Maasin", "value": "C86196"},
                     {"label": "Mabini", "value": "C86197"},
                     {"label": "MacArthur", "value": "C86198"},
                     {"label": "Macrohon", "value": "C86199"},
                     {"label": "Mahagnao", "value": "C86200"},
                     {"label": "Mahaplag", "value": "C86201"},
                     {"label": "Makiwalo", "value": "C86202"},
                     {"label": "Malaga", "value": "C86203"},
                     {"label": "Malajog", "value": "C86204"},
                     {"label": "Mantang", "value": "C86205"},
                     {"label": "Mapanas", "value": "C86206"},
                     {"label": "Marabut", "value": "C86207"},
                     {"label": "Margen", "value": "C86208"},
                     {"label": "Maripipi", "value": "C86209"},
                     {"label": "Masarayao", "value": "C86210"},
                     {"label": "Maslog", "value": "C86211"},
                     {"label": "Matag-ob", "value": "C86212"},
                     {"label": "Matalom", "value": "C86213"},
                     {"label": "Matlang", "value": "C86214"},
                     {"label": "Matuguinao", "value": "C86215"},
                     {"label": "Maydolong", "value": "C86216"},
                     {"label": "Mayorga", "value": "C86217"},
                     {"label": "Maypangdan", "value": "C86218"},
                     {"label": "Mercedes", "value": "C86219"},
                     {"label": "Merida", "value": "C86220"},
                     {"label": "Mondragon", "value": "C86221"},
                     {"label": "Motiong", "value": "C86222"},
                     {"label": "Naghalin", "value": "C86223"},
                     {"label": "Napuro", "value": "C86224"},
                     {"label": "Naval", "value": "C86225"},
                     {"label": "Nena", "value": "C86226"},
                     {"label": "Nenita", "value": "C86227"},
                     {"label": "Oras", "value": "C86228"},
                     {"label": "Ormoc", "value": "C86229"},
                     {"label": "Padre Burgos", "value": "C86230"},
                     {"label": "Pagsanghan", "value": "C86231"},
                     {"label": "Palanit", "value": "C86232"},
                     {"label": "Palapag", "value": "C86233"},
                     {"label": "Palaroo", "value": "C86234"},
                     {"label": "Palhi", "value": "C86235"},
                     {"label": "Palo", "value": "C86236"},
                     {"label": "Palompon", "value": "C86237"},
                     {"label": "Pambujan", "value": "C86238"},
                     {"label": "Panalanoy", "value": "C86239"},
                     {"label": "Pangdan", "value": "C86240"},
                     {"label": "Pangpang", "value": "C86241"},
                     {"label": "Pasay", "value": "C86242"},
                     {"label": "Pastrana", "value": "C86243"},
                     {"label": "Patong", "value": "C86244"},
                     {"label": "Pawing", "value": "C86245"},
                     {"label": "Pina", "value": "C86246"},
                     {"label": "Pinabacdao", "value": "C86247"},
                     {"label": "Pinamopoan", "value": "C86248"},
                     {"label": "Pinangomhan", "value": "C86249"},
                     {"label": "Pintuyan", "value": "C86250"},
                     {"label": "Plaridel", "value": "C86251"},
                     {"label": "Polahongon", "value": "C86252"},
                     {"label": "Polange", "value": "C86253"},
                     {"label": "Puerto Bello", "value": "C86254"},
                     {"label": "Quinapondan", "value": "C86255"},
                     {"label": "Rizal", "value": "C86256"},
                     {"label": "Rosario", "value": "C86257"},
                     {"label": "Sabang", "value": "C86258"},
                     {"label": "Saint Bernard", "value": "C86259"},
                     {"label": "Salcedo", "value": "C86260"},
                     {"label": "Salvacion", "value": "C86261"},
                     {"label": "San Antonio", "value": "C86262"},
                     {"label": "San Eduardo", "value": "C86263"},
                     {"label": "San Francisco", "value": "C86264"},
                     {"label": "San Isidro", "value": "C86265"},
                     {"label": "San Joaquin", "value": "C86266"},
                     {"label": "San Jorge", "value": "C86267"},
                     {"label": "San Jose", "value": "C86268"},
                     {"label": "San Jose de Buan", "value": "C86269"},
                     {"label": "San Juan", "value": "C86270"},
                     {"label": "San Julian", "value": "C86271"},
                     {"label": "San Miguel", "value": "C86272"},
                     {"label": "San Pedro", "value": "C86273"},
                     {"label": "San Policarpio", "value": "C86274"},
                     {"label": "San Policarpo", "value": "C86275"},
                     {"label": "San Ricardo", "value": "C86276"},
                     {"label": "San Roque", "value": "C86277"},
                     {"label": "San Sebastian", "value": "C86278"},
                     {"label": "San Vicente", "value": "C86279"},
                     {"label": "Santa Cruz", "value": "C86280"},
                     {"label": "Santa Fe", "value": "C86281"},
                     {"label": "Santa Margarita", "value": "C86282"},
                     {"label": "Santa Rita", "value": "C86283"},
                     {"label": "Santo Nino", "value": "C86284"},
                     {"label": "Siguinon", "value": "C86285"},
                     {"label": "Silago", "value": "C86286"},
                     {"label": "Silanga", "value": "C86287"},
                     {"label": "Silvino Lobos", "value": "C86288"},
                     {"label": "Sogod", "value": "C86289"},
                     {"label": "Sulangan", "value": "C86290"},
                     {"label": "Sulat", "value": "C86291"},
                     {"label": "Tabango", "value": "C86292"},
                     {"label": "Tabing", "value": "C86293"},
                     {"label": "Tabontabon", "value": "C86294"},
                     {"label": "Tacloban", "value": "C86295"},
                     {"label": "Taft", "value": "C86296"},
                     {"label": "Tagapul-an", "value": "C86297"},
                     {"label": "Tagbubungang Diot", "value": "C86298"},
                     {"label": "Talalora", "value": "C86299"},
                     {"label": "Talisayan", "value": "C86300"},
                     {"label": "Tanauan", "value": "C86301"},
                     {"label": "Tarangnan", "value": "C86302"},
                     {"label": "Tibur", "value": "C86303"},
                     {"label": "Tinambacan", "value": "C86304"},
                     {"label": "Tolosa", "value": "C86305"},
                     {"label": "Tomas Oppus", "value": "C86306"},
                     {"label": "Tucdao", "value": "C86307"},
                     {"label": "Tugbong", "value": "C86308"},
                     {"label": "Tunga", "value": "C86309"},
                     {"label": "Tutubigan", "value": "C86310"},
                     {"label": "Umaganhan", "value": "C86311"},
                     {"label": "Valencia", "value": "C86312"},
                     {"label": "Victoria", "value": "C86313"},
                     {"label": "Viga", "value": "C86314"},
                     {"label": "Villaba", "value": "C86315"},
                     {"label": "Villareal", "value": "C86316"},
                     {"label": "Viriato", "value": "C86317"},
                     {"label": "Wright", "value": "C86318"},
                     {"label": "Zumarraga", "value": "C86319"}],
 "Ebonyi": [{"label": "Abakaliki", "value": "C78226"},
            {"label": "Afikpo", "value": "C78227"},
            {"label": "Effium", "value": "C78228"},
            {"label": "Ezza-Ohu", "value": "C78229"},
            {"label": "Isieke", "value": "C78230"}],
 "Edinet District": [{"label": "Edinet", "value": "C75512"}],
 "Edirne": [{"label": "Edirne", "value": "C120244"},
            {"label": "Enez", "value": "C120245"},
            {"label": "Havsa", "value": "C120246"},
            {"label": "Ipsala", "value": "C120247"},
            {"label": "Kesan", "value": "C120248"},
            {"label": "Kuplu", "value": "C120249"},
            {"label": "Lalapasa", "value": "C120250"},
            {"label": "Meric", "value": "C120251"},
            {"label": "Suloglu Ilcesi", "value": "C120252"},
            {"label": "Uzunkopru", "value": "C120253"}],
 "Edo": [{"label": "Agenebode", "value": "C78231"},
         {"label": "Auchi", "value": "C78232"},
         {"label": "Benin City", "value": "C78233"},
         {"label": "Ekpoma", "value": "C78234"},
         {"label": "Igarra", "value": "C78235"},
         {"label": "Illushi", "value": "C78236"},
         {"label": "Siluko", "value": "C78237"},
         {"label": "Ubiaja", "value": "C78238"},
         {"label": "Uromi", "value": "C78239"}],
 "Ehime Prefecture": [{"label": "Hojo", "value": "C62707"},
                      {"label": "Imabari-shi", "value": "C62708"},
                      {"label": "Iyo", "value": "C62709"},
                      {"label": "Iyo-shi", "value": "C62710"},
                      {"label": "Kawanoecho", "value": "C62711"},
                      {"label": "Kihoku-cho", "value": "C62712"},
                      {"label": "Masaki-cho", "value": "C62713"},
                      {"label": "Matsuyama-shi", "value": "C62714"},
                      {"label": "Niihama", "value": "C62715"},
                      {"label": "Niihama-shi", "value": "C62716"},
                      {"label": "Ozu", "value": "C62717"},
                      {"label": "Ozu-shi", "value": "C62718"},
                      {"label": "Saijo", "value": "C62719"},
                      {"label": "Saijo-shi", "value": "C62720"},
                      {"label": "Seiyo-shi", "value": "C62721"},
                      {"label": "Shikoku-chuo Shi", "value": "C62722"},
                      {"label": "Toon-shi", "value": "C62723"},
                      {"label": "Uwajima", "value": "C62724"},
                      {"label": "Uwajima-shi", "value": "C62725"},
                      {"label": "Yawatahama-shi", "value": "C62726"}],
 "Ekiti": [{"label": "Ado-Ekiti", "value": "C78240"},
           {"label": "Aramoko-Ekiti", "value": "C78241"},
           {"label": "Efon-Alaaye", "value": "C78242"},
           {"label": "Emure-Ekiti", "value": "C78243"},
           {"label": "Ifaki", "value": "C78244"},
           {"label": "Igbara-Odo", "value": "C78245"},
           {"label": "Igede-Ekiti", "value": "C78246"},
           {"label": "Ijero-Ekiti", "value": "C78247"},
           {"label": "Ikere-Ekiti", "value": "C78248"},
           {"label": "Ipoti", "value": "C78249"},
           {"label": "Ise-Ekiti", "value": "C78250"},
           {"label": "Oke Ila", "value": "C78251"},
           {"label": "Omuo-Ekiti", "value": "C78252"}],
 "El Bayadh": [{"label": "Brezina", "value": "C331"},
               {"label": "El Abiodh Sidi Cheikh", "value": "C332"},
               {"label": "El Bayadh", "value": "C333"}],
 "El Oro": [{"label": "Huaquillas", "value": "C24114"},
            {"label": "Machala", "value": "C24115"},
            {"label": "Pasaje", "value": "C24116"},
            {"label": "Pinas", "value": "C24117"},
            {"label": "Portovelo", "value": "C24118"},
            {"label": "Puerto Bolivar", "value": "C24119"},
            {"label": "Santa Rosa", "value": "C24120"},
            {"label": "Zaruma", "value": "C24121"}],
 "El Oued": [{"label": "Debila", "value": "C334"},
             {"label": "El Oued", "value": "C335"},
             {"label": "Reguiba", "value": "C336"},
             {"label": "Robbah", "value": "C337"}],
 "El Paraiso Department": [{"label": "Alauca", "value": "C43415"},
                           {"label": "Arauli", "value": "C43416"},
                           {"label": "Cuyali", "value": "C43417"},
                           {"label": "Danli", "value": "C43418"},
                           {"label": "El Benque", "value": "C43419"},
                           {"label": "El Chichicaste", "value": "C43420"},
                           {"label": "El Obraje", "value": "C43421"},
                           {"label": "El Paraiso", "value": "C43422"},
                           {"label": "Guinope", "value": "C43423"},
                           {"label": "Jacaleapa", "value": "C43424"},
                           {"label": "Jutiapa", "value": "C43425"},
                           {"label": "Las animas", "value": "C43426"},
                           {"label": "Las Trojes", "value": "C43427"},
                           {"label": "Liure", "value": "C43428"},
                           {"label": "Moroceli", "value": "C43429"},
                           {"label": "Municipio de Texiguat",
                            "value": "C43430"},
                           {"label": "Ojo de Agua", "value": "C43431"},
                           {"label": "Oropoli", "value": "C43432"},
                           {"label": "Potrerillos", "value": "C43433"},
                           {"label": "Quebrada Larga", "value": "C43434"},
                           {"label": "San Antonio de Flores",
                            "value": "C43435"},
                           {"label": "San Diego", "value": "C43436"},
                           {"label": "San Lucas", "value": "C43437"},
                           {"label": "San Matias", "value": "C43438"},
                           {"label": "Santa Cruz", "value": "C43439"},
                           {"label": "Soledad", "value": "C43440"},
                           {"label": "Teupasenti", "value": "C43441"},
                           {"label": "Texiguat", "value": "C43442"},
                           {"label": "Trojes", "value": "C43443"},
                           {"label": "Vado Ancho", "value": "C43444"},
                           {"label": "Yauyupe", "value": "C43445"},
                           {"label": "Yuscaran", "value": "C43446"}],
 "El Progreso Department": [{"label": "El Jicaro", "value": "C42665"},
                            {"label": "Guastatoya", "value": "C42666"},
                            {"label": "Morazan", "value": "C42667"},
                            {"label": "San Agustin Acasaguastlan",
                             "value": "C42668"},
                            {"label": "San Antonio La Paz", "value": "C42669"},
                            {"label": "San Cristobal Acasaguastlan",
                             "value": "C42670"},
                            {"label": "Sanarate", "value": "C42671"},
                            {"label": "Sansare", "value": "C42672"}],
 "El Seibo Province": [{"label": "Miches", "value": "C23891"},
                       {"label": "Pedro Sanchez", "value": "C23892"},
                       {"label": "Santa Cruz de El Seibo", "value": "C23893"}],
 "El Tarf": [{"label": "Ben Mehidi", "value": "C338"},
             {"label": "Besbes", "value": "C339"},
             {"label": "El Kala", "value": "C340"},
             {"label": "El Tarf", "value": "C341"}],
 "Elazig": [{"label": "Agin", "value": "C120254"},
            {"label": "Aricak", "value": "C120255"},
            {"label": "Baskil", "value": "C120256"},
            {"label": "Elazig", "value": "C120257"},
            {"label": "Karakocan", "value": "C120258"},
            {"label": "Karakocan  Elazig", "value": "C120259"},
            {"label": "Keban", "value": "C120260"},
            {"label": "Kovancilar", "value": "C120261"},
            {"label": "Maden Ilcesi", "value": "C120262"},
            {"label": "Merkez", "value": "C120263"},
            {"label": "Palu", "value": "C120264"},
            {"label": "Sivrice", "value": "C120265"},
            {"label": "Turluk", "value": "C120266"}],
 "Embera-Wounaan Comarca": [{"label": "Bayamon", "value": "C80023"},
                            {"label": "Cemaco", "value": "C80024"},
                            {"label": "Corozal", "value": "C80025"},
                            {"label": "Sambu", "value": "C80026"},
                            {"label": "Union Choco", "value": "C80027"},
                            {"label": "Yape", "value": "C80028"}],
 "Embu": [{"label": "Embu", "value": "C64551"}],
 "Emilia-Romagna": [{"label": "Agazzano", "value": "C53826"},
                    {"label": "Albareto", "value": "C53827"},
                    {"label": "Alberi", "value": "C53828"},
                    {"label": "Albinea", "value": "C53829"},
                    {"label": "Alseno", "value": "C53830"},
                    {"label": "Altedo", "value": "C53831"},
                    {"label": "Anzola dellEmilia", "value": "C53832"},
                    {"label": "Arceto", "value": "C53833"},
                    {"label": "Argelato", "value": "C53834"},
                    {"label": "Argenta", "value": "C53835"},
                    {"label": "Argine", "value": "C53836"},
                    {"label": "Baganzola", "value": "C53837"},
                    {"label": "Baggiovara", "value": "C53838"},
                    {"label": "Bagnarola", "value": "C53839"},
                    {"label": "Bagno", "value": "C53840"},
                    {"label": "Bagnolo in Piano", "value": "C53841"},
                    {"label": "Baiso", "value": "C53842"},
                    {"label": "Barbiano", "value": "C53843"},
                    {"label": "Barco", "value": "C53844"},
                    {"label": "Bardi", "value": "C53845"},
                    {"label": "Baricella", "value": "C53846"},
                    {"label": "Basilicagoiano", "value": "C53847"},
                    {"label": "Basilicanova", "value": "C53848"},
                    {"label": "Bastiglia", "value": "C53849"},
                    {"label": "Bazzano", "value": "C53850"},
                    {"label": "Bedonia", "value": "C53851"},
                    {"label": "Bellaria-Igea Marina", "value": "C53852"},
                    {"label": "Bentivoglio", "value": "C53853"},
                    {"label": "Berceto", "value": "C53854"},
                    {"label": "Berra", "value": "C53855"},
                    {"label": "Bertinoro", "value": "C53856"},
                    {"label": "Berzantina", "value": "C53857"},
                    {"label": "Besenzone", "value": "C53858"},
                    {"label": "Bettola", "value": "C53859"},
                    {"label": "Bibbiano", "value": "C53860"},
                    {"label": "Bobbio", "value": "C53861"},
                    {"label": "Bologna", "value": "C53862"},
                    {"label": "Bomporto", "value": "C53863"},
                    {"label": "Bondeno", "value": "C53864"},
                    {"label": "Bora Bassa", "value": "C53865"},
                    {"label": "Bore", "value": "C53866"},
                    {"label": "Borello", "value": "C53867"},
                    {"label": "Boretto", "value": "C53868"},
                    {"label": "Borghi", "value": "C53869"},
                    {"label": "Borgo Tossignano", "value": "C53870"},
                    {"label": "Borgo Val di Taro", "value": "C53871"},
                    {"label": "Borgonovo Valtidone", "value": "C53872"},
                    {"label": "Borgonuovo", "value": "C53873"},
                    {"label": "Borzano", "value": "C53874"},
                    {"label": "Bosco Mesola", "value": "C53875"},
                    {"label": "Brescello", "value": "C53876"},
                    {"label": "Bubano", "value": "C53877"},
                    {"label": "Budrio", "value": "C53878"},
                    {"label": "Busana", "value": "C53879"},
                    {"label": "Busseto", "value": "C53880"},
                    {"label": "Ca De Fabbri", "value": "C53881"},
                    {"label": "Ca di Sola", "value": "C53882"},
                    {"label": "Cade-Gaida", "value": "C53883"},
                    {"label": "Cadelbosco di Sopra", "value": "C53884"},
                    {"label": "Cadelbosco di Sotto", "value": "C53885"},
                    {"label": "Calcara", "value": "C53886"},
                    {"label": "Calderara di Reno", "value": "C53887"},
                    {"label": "Calderino", "value": "C53888"},
                    {"label": "Calendasco", "value": "C53889"},
                    {"label": "Calerno", "value": "C53890"},
                    {"label": "Calestano", "value": "C53891"},
                    {"label": "Caminata", "value": "C53892"},
                    {"label": "Campagnola Emilia", "value": "C53893"},
                    {"label": "Campegine", "value": "C53894"},
                    {"label": "Campogalliano", "value": "C53895"},
                    {"label": "Camposanto", "value": "C53896"},
                    {"label": "Camugnano", "value": "C53897"},
                    {"label": "Caorso", "value": "C53898"},
                    {"label": "Caprara", "value": "C53899"},
                    {"label": "Carpaneto Piacentino", "value": "C53900"},
                    {"label": "Carpi Centro", "value": "C53901"},
                    {"label": "Carpineti", "value": "C53902"},
                    {"label": "Casale", "value": "C53903"},
                    {"label": "Casalecchio di Reno", "value": "C53904"},
                    {"label": "Casalfiumanese", "value": "C53905"},
                    {"label": "Casalgrande", "value": "C53906"},
                    {"label": "Casina", "value": "C53907"},
                    {"label": "Castel dAiano", "value": "C53908"},
                    {"label": "Castel del Rio", "value": "C53909"},
                    {"label": "Castel di Casio", "value": "C53910"},
                    {"label": "Castel Guelfo di Bologna", "value": "C53911"},
                    {"label": "Castel Maggiore", "value": "C53912"},
                    {"label": "Castel San Giovanni", "value": "C53913"},
                    {"label": "Castel San Pietro Terme", "value": "C53914"},
                    {"label": "Casteldelci", "value": "C53915"},
                    {"label": "Castelfranco Emilia", "value": "C53916"},
                    {"label": "CastellArquato", "value": "C53917"},
                    {"label": "Castellarano", "value": "C53918"},
                    {"label": "Castelletto", "value": "C53919"},
                    {"label": "Castello dArgile", "value": "C53920"},
                    {"label": "Castelnovo di Sotto", "value": "C53921"},
                    {"label": "Castelnovo neMonti", "value": "C53922"},
                    {"label": "Castelnuovo Rangone", "value": "C53923"},
                    {"label": "Castelvetro di Modena", "value": "C53924"},
                    {"label": "Castelvetro Piacentino", "value": "C53925"},
                    {"label": "Castenaso", "value": "C53926"},
                    {"label": "Castiglione dei Pepoli", "value": "C53927"},
                    {"label": "Castrocaro Terme e Terra del Sole",
                     "value": "C53928"},
                    {"label": "Cattolica", "value": "C53929"},
                    {"label": "Cavazzona", "value": "C53930"},
                    {"label": "Cavezzo", "value": "C53931"},
                    {"label": "Cavriago", "value": "C53932"},
                    {"label": "Cento", "value": "C53933"},
                    {"label": "Cerasolo", "value": "C53934"},
                    {"label": "Cerignale", "value": "C53935"},
                    {"label": "Cesena", "value": "C53936"},
                    {"label": "Cesenatico", "value": "C53937"},
                    {"label": "Ciano dEnza", "value": "C53938"},
                    {"label": "Civitella di Romagna", "value": "C53939"},
                    {"label": "Classe", "value": "C53940"},
                    {"label": "Coccanile-Cesta", "value": "C53941"},
                    {"label": "Codemondo-Quaresimo", "value": "C53942"},
                    {"label": "Codigoro", "value": "C53943"},
                    {"label": "Codisotto", "value": "C53944"},
                    {"label": "Coli", "value": "C53945"},
                    {"label": "Collagna", "value": "C53946"},
                    {"label": "Collecchio", "value": "C53947"},
                    {"label": "Colombaro", "value": "C53948"},
                    {"label": "Colorno", "value": "C53949"},
                    {"label": "Comacchio", "value": "C53950"},
                    {"label": "Compiano", "value": "C53951"},
                    {"label": "Cona", "value": "C53952"},
                    {"label": "Concordia sulla Secchia", "value": "C53953"},
                    {"label": "Consandolo", "value": "C53954"},
                    {"label": "Copparo", "value": "C53955"},
                    {"label": "Corcagnano", "value": "C53956"},
                    {"label": "Coriano", "value": "C53957"},
                    {"label": "Corniglio", "value": "C53958"},
                    {"label": "Corpo Reno", "value": "C53959"},
                    {"label": "Corpolo", "value": "C53960"},
                    {"label": "Correggio", "value": "C53961"},
                    {"label": "Cortemaggiore", "value": "C53962"},
                    {"label": "Crespellano", "value": "C53963"},
                    {"label": "Crevalcore", "value": "C53964"},
                    {"label": "Cusercoli", "value": "C53965"},
                    {"label": "Decima", "value": "C53966"},
                    {"label": "Dodici Morelli", "value": "C53967"},
                    {"label": "Dosso", "value": "C53968"},
                    {"label": "Dovadola", "value": "C53969"},
                    {"label": "Dozza", "value": "C53970"},
                    {"label": "Fabbrico", "value": "C53971"},
                    {"label": "Fanano", "value": "C53972"},
                    {"label": "Farini", "value": "C53973"},
                    {"label": "Felegara", "value": "C53974"},
                    {"label": "Felina", "value": "C53975"},
                    {"label": "Felino", "value": "C53976"},
                    {"label": "Fellegara", "value": "C53977"},
                    {"label": "Ferrara", "value": "C53978"},
                    {"label": "Ferriere", "value": "C53979"},
                    {"label": "Fidenza", "value": "C53980"},
                    {"label": "Finale Emilia", "value": "C53981"},
                    {"label": "Fiorano", "value": "C53982"},
                    {"label": "Fiorenzuola dArda", "value": "C53983"},
                    {"label": "Fiumalbo-Dogana", "value": "C53984"},
                    {"label": "Fiumana", "value": "C53985"},
                    {"label": "Fogliano", "value": "C53986"},
                    {"label": "Fognano", "value": "C53987"},
                    {"label": "Fontanelice", "value": "C53988"},
                    {"label": "Fontanellato", "value": "C53989"},
                    {"label": "Fontevivo", "value": "C53990"},
                    {"label": "Forche", "value": "C53991"},
                    {"label": "Forli", "value": "C53992"},
                    {"label": "Forlimpopoli", "value": "C53993"},
                    {"label": "Formica", "value": "C53994"},
                    {"label": "Formigine", "value": "C53995"},
                    {"label": "Formignana", "value": "C53996"},
                    {"label": "Fornace Zarattini", "value": "C53997"},
                    {"label": "Fornovo di Taro", "value": "C53998"},
                    {"label": "Fossa", "value": "C53999"},
                    {"label": "Fosso Ghiaia", "value": "C54000"},
                    {"label": "Fossoli", "value": "C54001"},
                    {"label": "Francolino", "value": "C54002"},
                    {"label": "Frassinoro", "value": "C54003"},
                    {"label": "Fratta Terme", "value": "C54004"},
                    {"label": "Gaggio", "value": "C54005"},
                    {"label": "Gaggio Montano", "value": "C54006"},
                    {"label": "Gaiano", "value": "C54007"},
                    {"label": "Gaibanella-SantEdigio", "value": "C54008"},
                    {"label": "Galeata", "value": "C54009"},
                    {"label": "Gallo", "value": "C54010"},
                    {"label": "Gambettola", "value": "C54011"},
                    {"label": "Gatteo a Mare", "value": "C54012"},
                    {"label": "Gatteo-SantAngelo", "value": "C54013"},
                    {"label": "Gazzola", "value": "C54014"},
                    {"label": "Gemmano", "value": "C54015"},
                    {"label": "Glorie", "value": "C54016"},
                    {"label": "Godo", "value": "C54017"},
                    {"label": "Goro", "value": "C54018"},
                    {"label": "Gorzano", "value": "C54019"},
                    {"label": "Gossolengo", "value": "C54020"},
                    {"label": "Gragnano Trebbiense", "value": "C54021"},
                    {"label": "Granarolo", "value": "C54022"},
                    {"label": "Granarolo dellEmilia e Viadagola",
                     "value": "C54023"},
                    {"label": "Grizzana", "value": "C54024"},
                    {"label": "Grizzana Morandi", "value": "C54025"},
                    {"label": "Gropparello", "value": "C54026"},
                    {"label": "Gualtieri", "value": "C54027"},
                    {"label": "Guastalla", "value": "C54028"},
                    {"label": "Guiglia", "value": "C54029"},
                    {"label": "Imola", "value": "C54030"},
                    {"label": "Jolanda di Savoia", "value": "C54031"},
                    {"label": "La Saletta-Tamara", "value": "C54032"},
                    {"label": "Lagosanto", "value": "C54033"},
                    {"label": "Lama", "value": "C54034"},
                    {"label": "Langhirano", "value": "C54035"},
                    {"label": "Lavezzola", "value": "C54036"},
                    {"label": "Lentigione-Sorbolo a Mane", "value": "C54037"},
                    {"label": "Lesignano deBagni", "value": "C54038"},
                    {"label": "Lido Adriano", "value": "C54039"},
                    {"label": "Lido degli Estensi", "value": "C54040"},
                    {"label": "Lido di Pomposa-Lido degli Scacchi",
                     "value": "C54041"},
                    {"label": "Ligonchio", "value": "C54042"},
                    {"label": "Limidi", "value": "C54043"},
                    {"label": "Lizzano in Belvedere", "value": "C54044"},
                    {"label": "Loiano", "value": "C54045"},
                    {"label": "Longara", "value": "C54046"},
                    {"label": "Longastrino", "value": "C54047"},
                    {"label": "Longiano", "value": "C54048"},
                    {"label": "Lugagnano Val dArda", "value": "C54049"},
                    {"label": "Luzzara", "value": "C54050"},
                    {"label": "Madonna DellAlbero", "value": "C54051"},
                    {"label": "Magreta", "value": "C54052"},
                    {"label": "Malalbergo", "value": "C54053"},
                    {"label": "Manzolino", "value": "C54054"},
                    {"label": "Maranello", "value": "C54055"},
                    {"label": "Marano sul Panaro", "value": "C54056"},
                    {"label": "Marina di Ravenna", "value": "C54057"},
                    {"label": "Marina Romea", "value": "C54058"},
                    {"label": "Marsaglia", "value": "C54059"},
                    {"label": "Marzabotto", "value": "C54060"},
                    {"label": "Masi-Torello", "value": "C54061"},
                    {"label": "Massa Finalese", "value": "C54062"},
                    {"label": "Massa Fiscaglia", "value": "C54063"},
                    {"label": "Massenzatico", "value": "C54064"},
                    {"label": "Medesano", "value": "C54065"},
                    {"label": "Medicina-Buda", "value": "C54066"},
                    {"label": "Medolla", "value": "C54067"},
                    {"label": "Meldola", "value": "C54068"},
                    {"label": "Mercato Saraceno", "value": "C54069"},
                    {"label": "Mesola", "value": "C54070"},
                    {"label": "Mezzano", "value": "C54071"},
                    {"label": "Mezzano Inferiore", "value": "C54072"},
                    {"label": "Mezzogoro", "value": "C54073"},
                    {"label": "Mezzolara", "value": "C54074"},
                    {"label": "Migliarina", "value": "C54075"},
                    {"label": "Migliarino", "value": "C54076"},
                    {"label": "Migliaro", "value": "C54077"},
                    {"label": "Milano Marittima", "value": "C54078"},
                    {"label": "Minerbio", "value": "C54079"},
                    {"label": "Mirabello", "value": "C54080"},
                    {"label": "Mirandola", "value": "C54081"},
                    {"label": "Misano Adriatico", "value": "C54082"},
                    {"label": "Modena", "value": "C54083"},
                    {"label": "Modigliana", "value": "C54084"},
                    {"label": "Molinella", "value": "C54085"},
                    {"label": "Molino del Pallone", "value": "C54086"},
                    {"label": "Monchio delle Corti", "value": "C54087"},
                    {"label": "Mondaino", "value": "C54088"},
                    {"label": "Monghidoro", "value": "C54089"},
                    {"label": "Montalbano", "value": "C54090"},
                    {"label": "Montale", "value": "C54091"},
                    {"label": "Monte Colombo", "value": "C54092"},
                    {"label": "Monte San Giovanni", "value": "C54093"},
                    {"label": "Montecavolo", "value": "C54094"},
                    {"label": "Montecchio Emilia", "value": "C54095"},
                    {"label": "Montechiarugolo", "value": "C54096"},
                    {"label": "Montecreto", "value": "C54097"},
                    {"label": "Montefiore Conca", "value": "C54098"},
                    {"label": "Montefiorino", "value": "C54099"},
                    {"label": "Montegridolfo", "value": "C54100"},
                    {"label": "Monterenzio", "value": "C54101"},
                    {"label": "Montescudo", "value": "C54102"},
                    {"label": "Montese", "value": "C54103"},
                    {"label": "Montiano", "value": "C54104"},
                    {"label": "Monticelli dOngina", "value": "C54105"},
                    {"label": "Monticelli Terme", "value": "C54106"},
                    {"label": "Monzuno", "value": "C54107"},
                    {"label": "Morciano di Romagna", "value": "C54108"},
                    {"label": "Mordano", "value": "C54109"},
                    {"label": "Morfasso", "value": "C54110"},
                    {"label": "Mulino", "value": "C54111"},
                    {"label": "Neviano degli Arduini", "value": "C54112"},
                    {"label": "Nibbiano", "value": "C54113"},
                    {"label": "Niviano", "value": "C54114"},
                    {"label": "Noceto", "value": "C54115"},
                    {"label": "Nonantola", "value": "C54116"},
                    {"label": "Novafeltria", "value": "C54117"},
                    {"label": "Novellara", "value": "C54118"},
                    {"label": "Novi di Modena", "value": "C54119"},
                    {"label": "Ospedaletto", "value": "C54120"},
                    {"label": "Ostellato", "value": "C54121"},
                    {"label": "Osteria Grande", "value": "C54122"},
                    {"label": "Osteria Nuova", "value": "C54123"},
                    {"label": "Ottone", "value": "C54124"},
                    {"label": "Ozzano dellEmilia", "value": "C54125"},
                    {"label": "Ozzano Taro", "value": "C54126"},
                    {"label": "Padulle", "value": "C54127"},
                    {"label": "Palagano", "value": "C54128"},
                    {"label": "Palanzano", "value": "C54129"},
                    {"label": "Panighina", "value": "C54130"},
                    {"label": "Parma", "value": "C54131"},
                    {"label": "Pavullo nel Frignano", "value": "C54132"},
                    {"label": "Pecorara", "value": "C54133"},
                    {"label": "Pellegrino Parmense", "value": "C54134"},
                    {"label": "Pennabilli", "value": "C54135"},
                    {"label": "Piacenza", "value": "C54136"},
                    {"label": "Pianello Val Tidone", "value": "C54137"},
                    {"label": "Piangipane", "value": "C54138"},
                    {"label": "Pianoro", "value": "C54139"},
                    {"label": "Pietracuta", "value": "C54140"},
                    {"label": "Pieve di Cento", "value": "C54141"},
                    {"label": "Pievepelago", "value": "C54142"},
                    {"label": "Pilastro", "value": "C54143"},
                    {"label": "Pinarella", "value": "C54144"},
                    {"label": "Piozzano", "value": "C54145"},
                    {"label": "Pisignano", "value": "C54146"},
                    {"label": "Piumazzo", "value": "C54147"},
                    {"label": "Pizzano", "value": "C54148"},
                    {"label": "Podenzano", "value": "C54149"},
                    {"label": "Poggio Berni", "value": "C54150"},
                    {"label": "Poggio Renatico", "value": "C54151"},
                    {"label": "Polesine Parmense", "value": "C54152"},
                    {"label": "Polinago", "value": "C54153"},
                    {"label": "Ponte dellOlio", "value": "C54154"},
                    {"label": "Ponte della Venturina", "value": "C54155"},
                    {"label": "Ponte Ronca", "value": "C54156"},
                    {"label": "Ponte Taro", "value": "C54157"},
                    {"label": "Pontegradella", "value": "C54158"},
                    {"label": "Pontelangorino", "value": "C54159"},
                    {"label": "Pontenure", "value": "C54160"},
                    {"label": "Ponticella", "value": "C54161"},
                    {"label": "Porotto-Cassama", "value": "C54162"},
                    {"label": "Porporano", "value": "C54163"},
                    {"label": "Porretta Terme", "value": "C54164"},
                    {"label": "Portico di Romagna", "value": "C54165"},
                    {"label": "Portico e San Benedetto", "value": "C54166"},
                    {"label": "Porto Corsini", "value": "C54167"},
                    {"label": "Porto Fuori", "value": "C54168"},
                    {"label": "Porto Garibaldi", "value": "C54169"},
                    {"label": "Portomaggiore", "value": "C54170"},
                    {"label": "Poviglio", "value": "C54171"},
                    {"label": "Pozza", "value": "C54172"},
                    {"label": "Praticello", "value": "C54173"},
                    {"label": "Pratissolo", "value": "C54174"},
                    {"label": "Prato", "value": "C54175"},
                    {"label": "Predappio", "value": "C54176"},
                    {"label": "Premilcuore", "value": "C54177"},
                    {"label": "Prignano", "value": "C54178"},
                    {"label": "Prignano sulla Secchia", "value": "C54179"},
                    {"label": "Progresso", "value": "C54180"},
                    {"label": "Provincia di Ferrara", "value": "C54181"},
                    {"label": "Provincia di Forli-Cesena", "value": "C54182"},
                    {"label": "Provincia di Modena", "value": "C54183"},
                    {"label": "Provincia di Parma", "value": "C54184"},
                    {"label": "Provincia di Piacenza", "value": "C54185"},
                    {"label": "Provincia di Ravenna", "value": "C54186"},
                    {"label": "Provincia di Reggio Emilia", "value": "C54187"},
                    {"label": "Provincia di Rimini", "value": "C54188"},
                    {"label": "Puianello", "value": "C54189"},
                    {"label": "Pulce", "value": "C54190"},
                    {"label": "Punta Marina", "value": "C54191"},
                    {"label": "Quarantoli", "value": "C54192"},
                    {"label": "Quartesana", "value": "C54193"},
                    {"label": "Quarto Inferiore", "value": "C54194"},
                    {"label": "Quattro Castella", "value": "C54195"},
                    {"label": "Ramiola", "value": "C54196"},
                    {"label": "Ramiseto", "value": "C54197"},
                    {"label": "Rastignano-Carteria di Sesto",
                     "value": "C54198"},
                    {"label": "Ravarino", "value": "C54199"},
                    {"label": "Reggio nellEmilia", "value": "C54200"},
                    {"label": "Reggiolo", "value": "C54201"},
                    {"label": "Renazzo", "value": "C54202"},
                    {"label": "Riale", "value": "C54203"},
                    {"label": "Riccione", "value": "C54204"},
                    {"label": "Ricco", "value": "C54205"},
                    {"label": "Rimini", "value": "C54206"},
                    {"label": "Rio Saliceto", "value": "C54207"},
                    {"label": "Riolunato", "value": "C54208"},
                    {"label": "Rivazzurra", "value": "C54209"},
                    {"label": "Rivergaro", "value": "C54210"},
                    {"label": "Ro", "value": "C54211"},
                    {"label": "Rocca San Casciano", "value": "C54212"},
                    {"label": "Roccabianca", "value": "C54213"},
                    {"label": "Rolo", "value": "C54214"},
                    {"label": "Roncocesi", "value": "C54215"},
                    {"label": "Roncofreddo", "value": "C54216"},
                    {"label": "Roncofreddo-Santa Paola", "value": "C54217"},
                    {"label": "Roteglia", "value": "C54218"},
                    {"label": "Rottofreno", "value": "C54219"},
                    {"label": "Roveleto", "value": "C54220"},
                    {"label": "Rovereto", "value": "C54221"},
                    {"label": "Rubiera", "value": "C54222"},
                    {"label": "Sala", "value": "C54223"},
                    {"label": "Sala Baganza", "value": "C54224"},
                    {"label": "Sala Bolognese", "value": "C54225"},
                    {"label": "Salsomaggiore Terme", "value": "C54226"},
                    {"label": "Saludecio", "value": "C54227"},
                    {"label": "Salvaterra", "value": "C54228"},
                    {"label": "Sam Marino", "value": "C54229"},
                    {"label": "San Bartolomeo", "value": "C54230"},
                    {"label": "San Bartolomeo In Bosco", "value": "C54231"},
                    {"label": "San Benedetto Val di Sambro", "value": "C54232"},
                    {"label": "San Biagio", "value": "C54233"},
                    {"label": "San Carlo", "value": "C54234"},
                    {"label": "San Cesario sul Panaro", "value": "C54235"},
                    {"label": "San Clemente", "value": "C54236"},
                    {"label": "San Damaso", "value": "C54237"},
                    {"label": "San Felice sul Panaro", "value": "C54238"},
                    {"label": "San Gabriele-Mondonuovo", "value": "C54239"},
                    {"label": "San Giorgio di Piano", "value": "C54240"},
                    {"label": "San Giorgio Piacentino", "value": "C54241"},
                    {"label": "San Giovanni in Marignano", "value": "C54242"},
                    {"label": "San Giovanni in Persiceto", "value": "C54243"},
                    {"label": "San Giovanni-San Bernardino", "value": "C54244"},
                    {"label": "San Giuseppe", "value": "C54245"},
                    {"label": "San Lazzaro", "value": "C54246"},
                    {"label": "San Leo", "value": "C54247"},
                    {"label": "San Martino", "value": "C54248"},
                    {"label": "San Martino dei Mulini", "value": "C54249"},
                    {"label": "San Martino in Argine", "value": "C54250"},
                    {"label": "San Martino in Rio", "value": "C54251"},
                    {"label": "San Mauro a Mare", "value": "C54252"},
                    {"label": "San Mauro Pascoli", "value": "C54253"},
                    {"label": "San Michele dei Mucchietti", "value": "C54254"},
                    {"label": "San Michele Tiorre", "value": "C54255"},
                    {"label": "San Nicolo", "value": "C54256"},
                    {"label": "San Pancrazio", "value": "C54257"},
                    {"label": "San Piero in Bagno", "value": "C54258"},
                    {"label": "San Pietro Capofiume", "value": "C54259"},
                    {"label": "San Pietro in Casale", "value": "C54260"},
                    {"label": "San Pietro in Cerro", "value": "C54261"},
                    {"label": "San Pietro In Vincoli", "value": "C54262"},
                    {"label": "San Polo", "value": "C54263"},
                    {"label": "San Polo dEnza", "value": "C54264"},
                    {"label": "San Possidonio", "value": "C54265"},
                    {"label": "San Prospero", "value": "C54266"},
                    {"label": "San Secondo Parmense", "value": "C54267"},
                    {"label": "San Venanzio", "value": "C54268"},
                    {"label": "San Vincenzo", "value": "C54269"},
                    {"label": "San Vito", "value": "C54270"},
                    {"label": "SantAgata Bolognese", "value": "C54271"},
                    {"label": "SantAgata Feltria", "value": "C54272"},
                    {"label": "SantAgostino", "value": "C54273"},
                    {"label": "SantAlberto", "value": "C54274"},
                    {"label": "SantAndrea", "value": "C54275"},
                    {"label": "SantAndrea in Casale", "value": "C54276"},
                    {"label": "SantAntonio", "value": "C54277"},
                    {"label": "SantIlario dEnza", "value": "C54278"},
                    {"label": "Santa Croce Scuole", "value": "C54279"},
                    {"label": "Santa Giustina", "value": "C54280"},
                    {"label": "Santa Maria Codifiume", "value": "C54281"},
                    {"label": "Santa Maria Nuova", "value": "C54282"},
                    {"label": "Santa Monica-Cella", "value": "C54283"},
                    {"label": "Santa Sofia", "value": "C54284"},
                    {"label": "Santa Vittoria", "value": "C54285"},
                    {"label": "Santarcangelo", "value": "C54286"},
                    {"label": "Santerno", "value": "C54287"},
                    {"label": "Santo Stefano-Carraie", "value": "C54288"},
                    {"label": "Sarmato", "value": "C54289"},
                    {"label": "Sarsina", "value": "C54290"},
                    {"label": "Sasso Marconi", "value": "C54291"},
                    {"label": "Sassuolo", "value": "C54292"},
                    {"label": "Savarna-Conventello", "value": "C54293"},
                    {"label": "Savignano sul Panaro", "value": "C54294"},
                    {"label": "Savignano sul Rubicone", "value": "C54295"},
                    {"label": "Savigno", "value": "C54296"},
                    {"label": "Savio", "value": "C54297"},
                    {"label": "Scandiano", "value": "C54298"},
                    {"label": "Scortichino", "value": "C54299"},
                    {"label": "Secchiano", "value": "C54300"},
                    {"label": "Serra di Maiolo", "value": "C54301"},
                    {"label": "Serramazzoni", "value": "C54302"},
                    {"label": "Serravalle", "value": "C54303"},
                    {"label": "Sesto Imolese", "value": "C54304"},
                    {"label": "Sestola", "value": "C54305"},
                    {"label": "Sissa", "value": "C54306"},
                    {"label": "Sogliano al Rubicone", "value": "C54307"},
                    {"label": "Solara", "value": "C54308"},
                    {"label": "Soliera", "value": "C54309"},
                    {"label": "Solignano", "value": "C54310"},
                    {"label": "Solignano Nuovo", "value": "C54311"},
                    {"label": "Soragna", "value": "C54312"},
                    {"label": "Sorbara", "value": "C54313"},
                    {"label": "Sorbolo", "value": "C54314"},
                    {"label": "Spilamberto", "value": "C54315"},
                    {"label": "Stazione Valmozzola", "value": "C54316"},
                    {"label": "Talamello", "value": "C54317"},
                    {"label": "Taneto", "value": "C54318"},
                    {"label": "Terenzo", "value": "C54319"},
                    {"label": "Tizzano Val Parma", "value": "C54320"},
                    {"label": "Toano", "value": "C54321"},
                    {"label": "Tornolo", "value": "C54322"},
                    {"label": "Torriana", "value": "C54323"},
                    {"label": "Torrile", "value": "C54324"},
                    {"label": "Toscanella", "value": "C54325"},
                    {"label": "Trarivi", "value": "C54326"},
                    {"label": "Traversetolo", "value": "C54327"},
                    {"label": "Travo", "value": "C54328"},
                    {"label": "Trebbo", "value": "C54329"},
                    {"label": "Trecasali", "value": "C54330"},
                    {"label": "Tredozio", "value": "C54331"},
                    {"label": "Tresigallo-Final di Rero", "value": "C54332"},
                    {"label": "Vado", "value": "C54333"},
                    {"label": "Varano De Melegari", "value": "C54334"},
                    {"label": "Varsi", "value": "C54335"},
                    {"label": "Veggia-Villalunga", "value": "C54336"},
                    {"label": "Venezzano", "value": "C54337"},
                    {"label": "Ventoso", "value": "C54338"},
                    {"label": "Vergato", "value": "C54339"},
                    {"label": "Verghereto", "value": "C54340"},
                    {"label": "Vernasca", "value": "C54341"},
                    {"label": "Verucchio", "value": "C54342"},
                    {"label": "Vetto", "value": "C54343"},
                    {"label": "Vezzano sul Crostolo", "value": "C54344"},
                    {"label": "Viano", "value": "C54345"},
                    {"label": "Vicofertile", "value": "C54346"},
                    {"label": "Vigarano Mainarda", "value": "C54347"},
                    {"label": "Vigarano Pieve", "value": "C54348"},
                    {"label": "Vigatto", "value": "C54349"},
                    {"label": "Vignola", "value": "C54350"},
                    {"label": "Vigolzone", "value": "C54351"},
                    {"label": "Villa Fontana", "value": "C54352"},
                    {"label": "Villa Minozzo", "value": "C54353"},
                    {"label": "Villa Verucchio", "value": "C54354"},
                    {"label": "Villanova", "value": "C54355"},
                    {"label": "Villarotta", "value": "C54356"},
                    {"label": "Voghiera", "value": "C54357"},
                    {"label": "Voltana", "value": "C54358"},
                    {"label": "Zerba", "value": "C54359"},
                    {"label": "Ziano Piacentino", "value": "C54360"},
                    {"label": "Zibello", "value": "C54361"},
                    {"label": "Zocca", "value": "C54362"},
                    {"label": "Zola Predosa", "value": "C54363"}],
 "Encamp": [{"label": "Encamp", "value": "C519"},
            {"label": "Pas de la Casa", "value": "C520"}],
 "Enga Province": [{"label": "Kandep", "value": "C80414"},
                   {"label": "Kompiam Ambum", "value": "C80415"},
                   {"label": "Lagaip Porgera", "value": "C80416"},
                   {"label": "Porgera", "value": "C80417"},
                   {"label": "Wabag", "value": "C80418"},
                   {"label": "Wapenamanda", "value": "C80419"}],
 "England": [{"label": "Abbey Wood", "value": "C123134"},
             {"label": "Abbots Bromley", "value": "C123135"},
             {"label": "Abbots Langley", "value": "C123136"},
             {"label": "Abbotskerswell", "value": "C123137"},
             {"label": "Abbotts Ann", "value": "C123138"},
             {"label": "Aberford", "value": "C123139"},
             {"label": "Abingdon", "value": "C123140"},
             {"label": "Abram", "value": "C123141"},
             {"label": "Abridge", "value": "C123142"},
             {"label": "Accrington", "value": "C123143"},
             {"label": "Acklington", "value": "C123144"},
             {"label": "Acle", "value": "C123145"},
             {"label": "Acocks Green", "value": "C123146"},
             {"label": "Acomb", "value": "C123147"},
             {"label": "Acton", "value": "C123148"},
             {"label": "Adderbury", "value": "C123149"},
             {"label": "Addingham", "value": "C123150"},
             {"label": "Addlestone", "value": "C123151"},
             {"label": "Adlington", "value": "C123152"},
             {"label": "Adwick le Street", "value": "C123153"},
             {"label": "Airmyn", "value": "C123154"},
             {"label": "Albrighton", "value": "C123155"},
             {"label": "Alcester", "value": "C123156"},
             {"label": "Alconbury", "value": "C123157"},
             {"label": "Aldbourne", "value": "C123158"},
             {"label": "Aldbrough", "value": "C123159"},
             {"label": "Aldeburgh", "value": "C123160"},
             {"label": "Alderbury", "value": "C123161"},
             {"label": "Alderholt", "value": "C123162"},
             {"label": "Alderley Edge", "value": "C123163"},
             {"label": "Aldershot", "value": "C123164"},
             {"label": "Aldford", "value": "C123165"},
             {"label": "Aldridge", "value": "C123166"},
             {"label": "Alford", "value": "C123167"},
             {"label": "Alfreton", "value": "C123168"},
             {"label": "Allhallows", "value": "C123169"},
             {"label": "Almondsbury", "value": "C123170"},
             {"label": "Alnwick", "value": "C123171"},
             {"label": "Alresford", "value": "C123172"},
             {"label": "Alrewas", "value": "C123173"},
             {"label": "Alsager", "value": "C123174"},
             {"label": "Alston", "value": "C123175"},
             {"label": "Althorne", "value": "C123176"},
             {"label": "Alton", "value": "C123177"},
             {"label": "Altrincham", "value": "C123178"},
             {"label": "Alvechurch", "value": "C123179"},
             {"label": "Alveley", "value": "C123180"},
             {"label": "Alveston", "value": "C123181"},
             {"label": "Amble", "value": "C123182"},
             {"label": "Ambleside", "value": "C123183"},
             {"label": "Ambrosden", "value": "C123184"},
             {"label": "Amersham", "value": "C123185"},
             {"label": "Amersham on the Hill", "value": "C123186"},
             {"label": "Amesbury", "value": "C123187"},
             {"label": "Ampthill", "value": "C123188"},
             {"label": "Ancaster", "value": "C123189"},
             {"label": "Andover", "value": "C123190"},
             {"label": "Anna Valley", "value": "C123191"},
             {"label": "Annfield Plain", "value": "C123192"},
             {"label": "Anstey", "value": "C123193"},
             {"label": "Appleby", "value": "C123194"},
             {"label": "Appleby-in-Westmorland", "value": "C123195"},
             {"label": "Appledore", "value": "C123196"},
             {"label": "Appleton Thorn", "value": "C123197"},
             {"label": "Appley Bridge", "value": "C123198"},
             {"label": "Archway", "value": "C123199"},
             {"label": "Ardingly", "value": "C123200"},
             {"label": "Arlesey", "value": "C123201"},
             {"label": "Armitage", "value": "C123202"},
             {"label": "Armthorpe", "value": "C123203"},
             {"label": "Arnold", "value": "C123204"},
             {"label": "Arnside", "value": "C123205"},
             {"label": "Arundel", "value": "C123206"},
             {"label": "Ascot", "value": "C123207"},
             {"label": "Asfordby", "value": "C123208"},
             {"label": "Ash", "value": "C123209"},
             {"label": "Ashbourne", "value": "C123210"},
             {"label": "Ashburton", "value": "C123211"},
             {"label": "Ashby de la Zouch", "value": "C123212"},
             {"label": "Ashford", "value": "C123213"},
             {"label": "Ashill", "value": "C123214"},
             {"label": "Ashington", "value": "C123215"},
             {"label": "Ashtead", "value": "C123216"},
             {"label": "Ashton in Makerfield", "value": "C123217"},
             {"label": "Ashton Keynes", "value": "C123218"},
             {"label": "Ashton-under-Lyne", "value": "C123219"},
             {"label": "Ashurst", "value": "C123220"},
             {"label": "Ashwell", "value": "C123221"},
             {"label": "Askam in Furness", "value": "C123222"},
             {"label": "Askern", "value": "C123223"},
             {"label": "Aslockton", "value": "C123224"},
             {"label": "Aspatria", "value": "C123225"},
             {"label": "Aston", "value": "C123226"},
             {"label": "Aston Clinton", "value": "C123227"},
             {"label": "Aston-on-Trent", "value": "C123228"},
             {"label": "Astwood Bank", "value": "C123229"},
             {"label": "Atherstone", "value": "C123230"},
             {"label": "Atherton", "value": "C123231"},
             {"label": "Attleborough", "value": "C123232"},
             {"label": "Atworth", "value": "C123233"},
             {"label": "Auckley", "value": "C123234"},
             {"label": "Audlem", "value": "C123235"},
             {"label": "Audley", "value": "C123236"},
             {"label": "Aveley", "value": "C123237"},
             {"label": "Avening", "value": "C123238"},
             {"label": "Awsworth", "value": "C123239"},
             {"label": "Axbridge", "value": "C123240"},
             {"label": "Axminster", "value": "C123241"},
             {"label": "Aylesbury", "value": "C123242"},
             {"label": "Aylesford", "value": "C123243"},
             {"label": "Aylesham", "value": "C123244"},
             {"label": "Aylsham", "value": "C123245"},
             {"label": "Babworth", "value": "C123246"},
             {"label": "Backworth", "value": "C123247"},
             {"label": "Bacton", "value": "C123248"},
             {"label": "Bacup", "value": "C123249"},
             {"label": "Badsey", "value": "C123250"},
             {"label": "Badsworth", "value": "C123251"},
             {"label": "Bagshot", "value": "C123252"},
             {"label": "Bagworth", "value": "C123253"},
             {"label": "Baildon", "value": "C123254"},
             {"label": "Bakewell", "value": "C123255"},
             {"label": "Balcombe", "value": "C123256"},
             {"label": "Baldock", "value": "C123257"},
             {"label": "Balsall Common", "value": "C123258"},
             {"label": "Balsham", "value": "C123259"},
             {"label": "Bamburgh", "value": "C123260"},
             {"label": "Bampton", "value": "C123261"},
             {"label": "Banbury", "value": "C123262"},
             {"label": "Banham", "value": "C123263"},
             {"label": "Banks", "value": "C123264"},
             {"label": "Banstead", "value": "C123265"},
             {"label": "Banwell", "value": "C123266"},
             {"label": "Bar Hill", "value": "C123267"},
             {"label": "Barbican", "value": "C123268"},
             {"label": "Bardney", "value": "C123269"},
             {"label": "Bardsey", "value": "C123270"},
             {"label": "Barham", "value": "C123271"},
             {"label": "Barking", "value": "C123272"},
             {"label": "Barkisland", "value": "C123273"},
             {"label": "Barlaston", "value": "C123274"},
             {"label": "Barlborough", "value": "C123275"},
             {"label": "Barlby", "value": "C123276"},
             {"label": "Barlestone", "value": "C123277"},
             {"label": "Barmby on the Marsh", "value": "C123278"},
             {"label": "Barnack", "value": "C123279"},
             {"label": "Barnard Castle", "value": "C123280"},
             {"label": "Barnburgh", "value": "C123281"},
             {"label": "Barnet", "value": "C123282"},
             {"label": "Barnetby le Wold", "value": "C123283"},
             {"label": "Barnham", "value": "C123284"},
             {"label": "Barnoldswick", "value": "C123285"},
             {"label": "Barnsbury", "value": "C123286"},
             {"label": "Barnsley", "value": "C123287"},
             {"label": "Barnstaple", "value": "C123288"},
             {"label": "Barnt Green", "value": "C123289"},
             {"label": "Barnwood", "value": "C123290"},
             {"label": "Barrow in Furness", "value": "C123291"},
             {"label": "Barrow upon Humber", "value": "C123292"},
             {"label": "Barrow upon Soar", "value": "C123293"},
             {"label": "Barrowby", "value": "C123294"},
             {"label": "Barrowford", "value": "C123295"},
             {"label": "Bartley Green", "value": "C123296"},
             {"label": "Barton under Needwood", "value": "C123297"},
             {"label": "Barton upon Humber", "value": "C123298"},
             {"label": "Barton-le-Clay", "value": "C123299"},
             {"label": "Baschurch", "value": "C123300"},
             {"label": "Basford", "value": "C123301"},
             {"label": "Basildon", "value": "C123302"},
             {"label": "Basingstoke", "value": "C123303"},
             {"label": "Baslow", "value": "C123304"},
             {"label": "Bassingbourn", "value": "C123305"},
             {"label": "Bassingham", "value": "C123306"},
             {"label": "Baston", "value": "C123307"},
             {"label": "Bath", "value": "C123308"},
             {"label": "Bath and North East Somerset", "value": "C123309"},
             {"label": "Batley", "value": "C123310"},
             {"label": "Battersea", "value": "C123311"},
             {"label": "Battle", "value": "C123312"},
             {"label": "Bawtry", "value": "C123313"},
             {"label": "Bay Horse", "value": "C123314"},
             {"label": "Bayston Hill", "value": "C123315"},
             {"label": "Bayswater", "value": "C123316"},
             {"label": "Beaconsfield", "value": "C123317"},
             {"label": "Beadnell", "value": "C123318"},
             {"label": "Beaminster", "value": "C123319"},
             {"label": "Bebington", "value": "C123320"},
             {"label": "Beccles", "value": "C123321"},
             {"label": "Beckenham", "value": "C123322"},
             {"label": "Beckingham", "value": "C123323"},
             {"label": "Becontree", "value": "C123324"},
             {"label": "Bedale", "value": "C123325"},
             {"label": "Bedford", "value": "C123326"},
             {"label": "Bedlington", "value": "C123327"},
             {"label": "Bedworth", "value": "C123328"},
             {"label": "Beeford", "value": "C123329"},
             {"label": "Beighton", "value": "C123330"},
             {"label": "Belbroughton", "value": "C123331"},
             {"label": "Belford", "value": "C123332"},
             {"label": "Bellingham", "value": "C123333"},
             {"label": "Belmont", "value": "C123334"},
             {"label": "Belper", "value": "C123335"},
             {"label": "Belsize Park", "value": "C123336"},
             {"label": "Belton", "value": "C123337"},
             {"label": "Belvedere", "value": "C123338"},
             {"label": "Bembridge", "value": "C123339"},
             {"label": "Bempton", "value": "C123340"},
             {"label": "Benson", "value": "C123341"},
             {"label": "Bentley", "value": "C123342"},
             {"label": "Benwell", "value": "C123343"},
             {"label": "Bere Alston", "value": "C123344"},
             {"label": "Bere Regis", "value": "C123345"},
             {"label": "Berkeley", "value": "C123346"},
             {"label": "Berkhamsted", "value": "C123347"},
             {"label": "Berwick-Upon-Tweed", "value": "C123348"},
             {"label": "Bethnal Green", "value": "C123349"},
             {"label": "Betley", "value": "C123350"},
             {"label": "Beverley", "value": "C123351"},
             {"label": "Bewbush", "value": "C123352"},
             {"label": "Bewdley", "value": "C123353"},
             {"label": "Bexhill-on-Sea", "value": "C123354"},
             {"label": "Bexley", "value": "C123355"},
             {"label": "Bicester", "value": "C123356"},
             {"label": "Bicknacre", "value": "C123357"},
             {"label": "Bicton", "value": "C123358"},
             {"label": "Biddenden", "value": "C123359"},
             {"label": "Biddestone", "value": "C123360"},
             {"label": "Biddulph", "value": "C123361"},
             {"label": "Bideford", "value": "C123362"},
             {"label": "Bidford-on-Avon", "value": "C123363"},
             {"label": "Biggin Hill", "value": "C123364"},
             {"label": "Biggleswade", "value": "C123365"},
             {"label": "Bildeston", "value": "C123366"},
             {"label": "Billericay", "value": "C123367"},
             {"label": "Billingborough", "value": "C123368"},
             {"label": "Billinge", "value": "C123369"},
             {"label": "Billingham", "value": "C123370"},
             {"label": "Billinghay", "value": "C123371"},
             {"label": "Billingshurst", "value": "C123372"},
             {"label": "Billington", "value": "C123373"},
             {"label": "Bilsdale", "value": "C123374"},
             {"label": "Bilsthorpe", "value": "C123375"},
             {"label": "Bilston", "value": "C123376"},
             {"label": "Bingham", "value": "C123377"},
             {"label": "Bingley", "value": "C123378"},
             {"label": "Birchington-on-Sea", "value": "C123379"},
             {"label": "Bircotes", "value": "C123380"},
             {"label": "Birdham", "value": "C123381"},
             {"label": "Birdwell", "value": "C123382"},
             {"label": "Birkenhead", "value": "C123383"},
             {"label": "Birmingham", "value": "C123384"},
             {"label": "Bishop Auckland", "value": "C123385"},
             {"label": "Bishop Middleham", "value": "C123386"},
             {"label": "Bishop Sutton", "value": "C123387"},
             {"label": "Bishops Castle", "value": "C123388"},
             {"label": "Bishops Cleeve", "value": "C123389"},
             {"label": "Bishops Lydeard", "value": "C123390"},
             {"label": "Bishops Stortford", "value": "C123391"},
             {"label": "Bishops Waltham", "value": "C123392"},
             {"label": "Bishopsteignton", "value": "C123393"},
             {"label": "Bishopstoke", "value": "C123394"},
             {"label": "Bishopstone", "value": "C123395"},
             {"label": "Bishopsworth", "value": "C123396"},
             {"label": "Bishopthorpe", "value": "C123397"},
             {"label": "Bishopton", "value": "C123398"},
             {"label": "Bitton", "value": "C123399"},
             {"label": "Blaby", "value": "C123400"},
             {"label": "Black Notley", "value": "C123401"},
             {"label": "Blackburn", "value": "C123402"},
             {"label": "Blackburn with Darwen", "value": "C123403"},
             {"label": "Blackheath", "value": "C123404"},
             {"label": "Blackley", "value": "C123405"},
             {"label": "Blackmoorfoot", "value": "C123406"},
             {"label": "Blackpool", "value": "C123407"},
             {"label": "Blackrod", "value": "C123408"},
             {"label": "Blackwell", "value": "C123409"},
             {"label": "Blacon", "value": "C123410"},
             {"label": "Blagdon", "value": "C123411"},
             {"label": "Blandford Forum", "value": "C123412"},
             {"label": "Blaydon-on-Tyne", "value": "C123413"},
             {"label": "Bleadon", "value": "C123414"},
             {"label": "Blean", "value": "C123415"},
             {"label": "Bletchingley", "value": "C123416"},
             {"label": "Bletchley", "value": "C123417"},
             {"label": "Blewbury", "value": "C123418"},
             {"label": "Blidworth", "value": "C123419"},
             {"label": "Blindley Heath", "value": "C123420"},
             {"label": "Blisworth", "value": "C123421"},
             {"label": "Blockley", "value": "C123422"},
             {"label": "Bloxham", "value": "C123423"},
             {"label": "Bloxwich", "value": "C123424"},
             {"label": "Blunham", "value": "C123425"},
             {"label": "Bluntisham", "value": "C123426"},
             {"label": "Blyth", "value": "C123427"},
             {"label": "Blyton", "value": "C123428"},
             {"label": "Bodle Street", "value": "C123429"},
             {"label": "Bodmin", "value": "C123430"},
             {"label": "Bognor Regis", "value": "C123431"},
             {"label": "Bollington", "value": "C123432"},
             {"label": "Bolsover", "value": "C123433"},
             {"label": "Bolton", "value": "C123434"},
             {"label": "Bolton le Sands", "value": "C123435"},
             {"label": "Bolton upon Dearne", "value": "C123436"},
             {"label": "Boosbeck", "value": "C123437"},
             {"label": "Bootle", "value": "C123438"},
             {"label": "Bordon", "value": "C123439"},
             {"label": "Boreham", "value": "C123440"},
             {"label": "Borehamwood", "value": "C123441"},
             {"label": "Borough Green", "value": "C123442"},
             {"label": "Borough of Bolton", "value": "C123443"},
             {"label": "Borough of Bury", "value": "C123444"},
             {"label": "Borough of Halton", "value": "C123445"},
             {"label": "Borough of North Tyneside", "value": "C123446"},
             {"label": "Borough of Oldham", "value": "C123447"},
             {"label": "Borough of Rochdale", "value": "C123448"},
             {"label": "Borough of Stockport", "value": "C123449"},
             {"label": "Borough of Swindon", "value": "C123450"},
             {"label": "Borough of Tameside", "value": "C123451"},
             {"label": "Borough of Thurrock", "value": "C123452"},
             {"label": "Borough of Torbay", "value": "C123453"},
             {"label": "Borough of Wigan", "value": "C123454"},
             {"label": "Boroughbridge", "value": "C123455"},
             {"label": "Borrowash", "value": "C123456"},
             {"label": "Bosham", "value": "C123457"},
             {"label": "Boston", "value": "C123458"},
             {"label": "Boston Spa", "value": "C123459"},
             {"label": "Botesdale", "value": "C123460"},
             {"label": "Botley", "value": "C123461"},
             {"label": "Bottesford", "value": "C123462"},
             {"label": "Bottisham", "value": "C123463"},
             {"label": "Boughton", "value": "C123464"},
             {"label": "Bourne", "value": "C123465"},
             {"label": "Bourne End", "value": "C123466"},
             {"label": "Bournemouth", "value": "C123467"},
             {"label": "Bourton", "value": "C123468"},
             {"label": "Bourton on the Water", "value": "C123469"},
             {"label": "Bovey Tracey", "value": "C123470"},
             {"label": "Bovingdon", "value": "C123471"},
             {"label": "Bovington Camp", "value": "C123472"},
             {"label": "Bow", "value": "C123473"},
             {"label": "Bow Brickhill", "value": "C123474"},
             {"label": "Bowburn", "value": "C123475"},
             {"label": "Bowdon", "value": "C123476"},
             {"label": "Bowthorpe", "value": "C123477"},
             {"label": "Box", "value": "C123478"},
             {"label": "Boxgrove", "value": "C123479"},
             {"label": "Boxted", "value": "C123480"},
             {"label": "Bozeat", "value": "C123481"},
             {"label": "Bracebridge Heath", "value": "C123482"},
             {"label": "Brackley", "value": "C123483"},
             {"label": "Bracknell", "value": "C123484"},
             {"label": "Bracknell Forest", "value": "C123485"},
             {"label": "Bradfield", "value": "C123486"},
             {"label": "Bradford", "value": "C123487"},
             {"label": "Bradford-on-Avon", "value": "C123488"},
             {"label": "Brading", "value": "C123489"},
             {"label": "Bradley Cross", "value": "C123490"},
             {"label": "Bradninch", "value": "C123491"},
             {"label": "Bradwell", "value": "C123492"},
             {"label": "Braintree", "value": "C123493"},
             {"label": "Bramford", "value": "C123494"},
             {"label": "Bramhall", "value": "C123495"},
             {"label": "Bramham", "value": "C123496"},
             {"label": "Bramhope", "value": "C123497"},
             {"label": "Bramley", "value": "C123498"},
             {"label": "Brampton", "value": "C123499"},
             {"label": "Brandesburton", "value": "C123500"},
             {"label": "Brandon", "value": "C123501"},
             {"label": "Bransgore", "value": "C123502"},
             {"label": "Branston", "value": "C123503"},
             {"label": "Bratton", "value": "C123504"},
             {"label": "Braunston", "value": "C123505"},
             {"label": "Braunton", "value": "C123506"},
             {"label": "Brayton", "value": "C123507"},
             {"label": "Bream", "value": "C123508"},
             {"label": "Bredbury", "value": "C123509"},
             {"label": "Bredon", "value": "C123510"},
             {"label": "Brenchley", "value": "C123511"},
             {"label": "Brent", "value": "C123512"},
             {"label": "Brent Knoll", "value": "C123513"},
             {"label": "Brentford", "value": "C123514"},
             {"label": "Brentwood", "value": "C123515"},
             {"label": "Brewood", "value": "C123516"},
             {"label": "Bridge", "value": "C123517"},
             {"label": "Bridgnorth", "value": "C123518"},
             {"label": "Bridgwater", "value": "C123519"},
             {"label": "Bridlington", "value": "C123520"},
             {"label": "Bridport", "value": "C123521"},
             {"label": "Brierfield", "value": "C123522"},
             {"label": "Brierley Hill", "value": "C123523"},
             {"label": "Brigg", "value": "C123524"},
             {"label": "Brighouse", "value": "C123525"},
             {"label": "Brighstone", "value": "C123526"},
             {"label": "Brightlingsea", "value": "C123527"},
             {"label": "Brighton", "value": "C123528"},
             {"label": "Brighton and Hove", "value": "C123529"},
             {"label": "Brigstock", "value": "C123530"},
             {"label": "Brill", "value": "C123531"},
             {"label": "Brimscombe", "value": "C123532"},
             {"label": "Brinklow", "value": "C123533"},
             {"label": "Brinscall", "value": "C123534"},
             {"label": "Bristol", "value": "C123535"},
             {"label": "Briston", "value": "C123536"},
             {"label": "Brixham", "value": "C123537"},
             {"label": "Brixton", "value": "C123538"},
             {"label": "Brixton Hill", "value": "C123539"},
             {"label": "Brixworth", "value": "C123540"},
             {"label": "Broad Blunsdon", "value": "C123541"},
             {"label": "Broadfield", "value": "C123542"},
             {"label": "Broadstairs", "value": "C123543"},
             {"label": "Broadstone", "value": "C123544"},
             {"label": "Broadwater", "value": "C123545"},
             {"label": "Broadway", "value": "C123546"},
             {"label": "Brockenhurst", "value": "C123547"},
             {"label": "Brockley", "value": "C123548"},
             {"label": "Bromborough", "value": "C123549"},
             {"label": "Bromham", "value": "C123550"},
             {"label": "Brompton", "value": "C123551"},
             {"label": "Bromsgrove", "value": "C123552"},
             {"label": "Bromyard", "value": "C123553"},
             {"label": "Brooke", "value": "C123554"},
             {"label": "Broseley", "value": "C123555"},
             {"label": "Brotton", "value": "C123556"},
             {"label": "Brough", "value": "C123557"},
             {"label": "Broughton", "value": "C123558"},
             {"label": "Broughton Astley", "value": "C123559"},
             {"label": "Brownhills", "value": "C123560"},
             {"label": "Broxbourne", "value": "C123561"},
             {"label": "Brundall", "value": "C123562"},
             {"label": "Bruton", "value": "C123563"},
             {"label": "Bubwith", "value": "C123564"},
             {"label": "Buckden", "value": "C123565"},
             {"label": "Buckfastleigh", "value": "C123566"},
             {"label": "Buckhurst Hill", "value": "C123567"},
             {"label": "Buckingham", "value": "C123568"},
             {"label": "Buckinghamshire", "value": "C123569"},
             {"label": "Bucknell", "value": "C123570"},
             {"label": "Bude", "value": "C123571"},
             {"label": "Budleigh Salterton", "value": "C123572"},
             {"label": "Bugbrooke", "value": "C123573"},
             {"label": "Bugle", "value": "C123574"},
             {"label": "Bulford", "value": "C123575"},
             {"label": "Bulkington", "value": "C123576"},
             {"label": "Bulphan", "value": "C123577"},
             {"label": "Bunbury", "value": "C123578"},
             {"label": "Bungay", "value": "C123579"},
             {"label": "Buntingford", "value": "C123580"},
             {"label": "Burbage", "value": "C123581"},
             {"label": "Bures Saint Mary", "value": "C123582"},
             {"label": "Burford", "value": "C123583"},
             {"label": "Burgess Hill", "value": "C123584"},
             {"label": "Burgh le Marsh", "value": "C123585"},
             {"label": "Burham", "value": "C123586"},
             {"label": "Burley", "value": "C123587"},
             {"label": "Burley in Wharfedale", "value": "C123588"},
             {"label": "Burnage", "value": "C123589"},
             {"label": "Burneside", "value": "C123590"},
             {"label": "Burngreave", "value": "C123591"},
             {"label": "Burnham-on-Crouch", "value": "C123592"},
             {"label": "Burnham-on-Sea", "value": "C123593"},
             {"label": "Burniston", "value": "C123594"},
             {"label": "Burnley", "value": "C123595"},
             {"label": "Burnopfield", "value": "C123596"},
             {"label": "Burntwood", "value": "C123597"},
             {"label": "Burringham", "value": "C123598"},
             {"label": "Burrington", "value": "C123599"},
             {"label": "Burscough", "value": "C123600"},
             {"label": "Bursledon", "value": "C123601"},
             {"label": "Burstwick", "value": "C123602"},
             {"label": "Burton", "value": "C123603"},
             {"label": "Burton Joyce", "value": "C123604"},
             {"label": "Burton Latimer", "value": "C123605"},
             {"label": "Burton on the Wolds", "value": "C123606"},
             {"label": "Burton Pidsea", "value": "C123607"},
             {"label": "Burton upon Stather", "value": "C123608"},
             {"label": "Burton upon Trent", "value": "C123609"},
             {"label": "Burtonwood", "value": "C123610"},
             {"label": "Burwash", "value": "C123611"},
             {"label": "Burwell", "value": "C123612"},
             {"label": "Bury", "value": "C123613"},
             {"label": "Bury St Edmunds", "value": "C123614"},
             {"label": "Bushey", "value": "C123615"},
             {"label": "Butterwick", "value": "C123616"},
             {"label": "Buxted", "value": "C123617"},
             {"label": "Buxton", "value": "C123618"},
             {"label": "Byfield", "value": "C123619"},
             {"label": "Byram", "value": "C123620"},
             {"label": "Caddington", "value": "C123621"},
             {"label": "Cadnam", "value": "C123622"},
             {"label": "Caister-on-Sea", "value": "C123623"},
             {"label": "Caistor", "value": "C123624"},
             {"label": "Calcot", "value": "C123625"},
             {"label": "Calderdale", "value": "C123626"},
             {"label": "Callington", "value": "C123627"},
             {"label": "Calne", "value": "C123628"},
             {"label": "Calverton", "value": "C123629"},
             {"label": "Camber", "value": "C123630"},
             {"label": "Camberley", "value": "C123631"},
             {"label": "Camblesforth", "value": "C123632"},
             {"label": "Camborne", "value": "C123633"},
             {"label": "Cambourne", "value": "C123634"},
             {"label": "Cambridge", "value": "C123635"},
             {"label": "Cambridgeshire", "value": "C123636"},
             {"label": "Camden Town", "value": "C123637"},
             {"label": "Cameley", "value": "C123638"},
             {"label": "Camelford", "value": "C123639"},
             {"label": "Campsall", "value": "C123640"},
             {"label": "Canary Wharf", "value": "C123641"},
             {"label": "Canewdon", "value": "C123642"},
             {"label": "Canford Heath", "value": "C123643"},
             {"label": "Cannock", "value": "C123644"},
             {"label": "Canterbury", "value": "C123645"},
             {"label": "Canvey Island", "value": "C123646"},
             {"label": "Capel", "value": "C123647"},
             {"label": "Capel le Ferne", "value": "C123648"},
             {"label": "Capel Saint Mary", "value": "C123649"},
             {"label": "Carcroft", "value": "C123650"},
             {"label": "Cardington", "value": "C123651"},
             {"label": "Carlisle", "value": "C123652"},
             {"label": "Carlton", "value": "C123653"},
             {"label": "Carnforth", "value": "C123654"},
             {"label": "Carshalton", "value": "C123655"},
             {"label": "Carterton", "value": "C123656"},
             {"label": "Castle Cary", "value": "C123657"},
             {"label": "Castle Donington", "value": "C123658"},
             {"label": "Castle Hedingham", "value": "C123659"},
             {"label": "Castle Vale", "value": "C123660"},
             {"label": "Castleford", "value": "C123661"},
             {"label": "Castleside", "value": "C123662"},
             {"label": "Castor", "value": "C123663"},
             {"label": "Catcliffe", "value": "C123664"},
             {"label": "Caterham", "value": "C123665"},
             {"label": "Caton", "value": "C123666"},
             {"label": "Catterall", "value": "C123667"},
             {"label": "Catterick", "value": "C123668"},
             {"label": "Catterick Garrison", "value": "C123669"},
             {"label": "Cawood", "value": "C123670"},
             {"label": "Cawston", "value": "C123671"},
             {"label": "Cawthorne", "value": "C123672"},
             {"label": "Caythorpe", "value": "C123673"},
             {"label": "Central Bedfordshire", "value": "C123674"},
             {"label": "Chacewater", "value": "C123675"},
             {"label": "Chadwell Heath", "value": "C123676"},
             {"label": "Chadwell St Mary", "value": "C123677"},
             {"label": "Chafford Hundred", "value": "C123678"},
             {"label": "Chagford", "value": "C123679"},
             {"label": "Chalfont Saint Peter", "value": "C123680"},
             {"label": "Chalfont St Giles", "value": "C123681"},
             {"label": "Chalford", "value": "C123682"},
             {"label": "Chalgrove", "value": "C123683"},
             {"label": "Chalton", "value": "C123684"},
             {"label": "Chapel Allerton", "value": "C123685"},
             {"label": "Chapel en le Frith", "value": "C123686"},
             {"label": "Chapel Saint Leonards", "value": "C123687"},
             {"label": "Chapeltown", "value": "C123688"},
             {"label": "Chapmanslade", "value": "C123689"},
             {"label": "Chard", "value": "C123690"},
             {"label": "Charfield", "value": "C123691"},
             {"label": "Charing", "value": "C123692"},
             {"label": "Charlbury", "value": "C123693"},
             {"label": "Charlton Kings", "value": "C123694"},
             {"label": "Charlton Marshall", "value": "C123695"},
             {"label": "Charminster", "value": "C123696"},
             {"label": "Charmouth", "value": "C123697"},
             {"label": "Chartham", "value": "C123698"},
             {"label": "Charvil", "value": "C123699"},
             {"label": "Chasetown", "value": "C123700"},
             {"label": "Chatburn", "value": "C123701"},
             {"label": "Chatham", "value": "C123702"},
             {"label": "Chatteris", "value": "C123703"},
             {"label": "Cheadle", "value": "C123704"},
             {"label": "Cheadle Heath", "value": "C123705"},
             {"label": "Cheadle Hulme", "value": "C123706"},
             {"label": "Cheam", "value": "C123707"},
             {"label": "Cheddar", "value": "C123708"},
             {"label": "Cheddington", "value": "C123709"},
             {"label": "Cheddleton", "value": "C123710"},
             {"label": "Cheetham Hill", "value": "C123711"},
             {"label": "Chelford", "value": "C123712"},
             {"label": "Chelmsford", "value": "C123713"},
             {"label": "Chelmsley Wood", "value": "C123714"},
             {"label": "Chelsea", "value": "C123715"},
             {"label": "Cheltenham", "value": "C123716"},
             {"label": "Cherry Burton", "value": "C123717"},
             {"label": "Chertsey", "value": "C123718"},
             {"label": "Chesham", "value": "C123719"},
             {"label": "Cheshire East", "value": "C123720"},
             {"label": "Cheshire West and Chester", "value": "C123721"},
             {"label": "Cheshunt", "value": "C123722"},
             {"label": "Chessington", "value": "C123723"},
             {"label": "Chester", "value": "C123724"},
             {"label": "Chester-le-Street", "value": "C123725"},
             {"label": "Chesterfield", "value": "C123726"},
             {"label": "Chew Magna", "value": "C123727"},
             {"label": "Chichester", "value": "C123728"},
             {"label": "Chickerell", "value": "C123729"},
             {"label": "Chicksands", "value": "C123730"},
             {"label": "Chiddingfold", "value": "C123731"},
             {"label": "Chigwell", "value": "C123732"},
             {"label": "Chilcompton", "value": "C123733"},
             {"label": "Child Okeford", "value": "C123734"},
             {"label": "Chilton Foliat", "value": "C123735"},
             {"label": "Chilworth", "value": "C123736"},
             {"label": "Chinley", "value": "C123737"},
             {"label": "Chinnor", "value": "C123738"},
             {"label": "Chippenham", "value": "C123739"},
             {"label": "Chipping Campden", "value": "C123740"},
             {"label": "Chipping Norton", "value": "C123741"},
             {"label": "Chipping Ongar", "value": "C123742"},
             {"label": "Chipping Sodbury", "value": "C123743"},
             {"label": "Chiseldon", "value": "C123744"},
             {"label": "Chislehurst", "value": "C123745"},
             {"label": "Chobham", "value": "C123746"},
             {"label": "Cholsey", "value": "C123747"},
             {"label": "Choppington", "value": "C123748"},
             {"label": "Chopwell", "value": "C123749"},
             {"label": "Chorley", "value": "C123750"},
             {"label": "Chorleywood", "value": "C123751"},
             {"label": "Chorlton", "value": "C123752"},
             {"label": "Chorlton cum Hardy", "value": "C123753"},
             {"label": "Christchurch", "value": "C123754"},
             {"label": "Chudleigh", "value": "C123755"},
             {"label": "Chudleigh Knighton", "value": "C123756"},
             {"label": "Chulmleigh", "value": "C123757"},
             {"label": "Church", "value": "C123758"},
             {"label": "Church Fenton", "value": "C123759"},
             {"label": "Church Stretton", "value": "C123760"},
             {"label": "Churchdown", "value": "C123761"},
             {"label": "Churchill", "value": "C123762"},
             {"label": "Churt", "value": "C123763"},
             {"label": "Cinderford", "value": "C123764"},
             {"label": "Cirencester", "value": "C123765"},
             {"label": "City and Borough of Birmingham", "value": "C123766"},
             {"label": "City and Borough of Leeds", "value": "C123767"},
             {"label": "City and Borough of Salford", "value": "C123768"},
             {"label": "City and Borough of Wakefield", "value": "C123769"},
             {"label": "City of Bristol", "value": "C123770"},
             {"label": "City of Kingston upon Hull", "value": "C123771"},
             {"label": "City of Leicester", "value": "C123772"},
             {"label": "City of London", "value": "C123773"},
             {"label": "City of Westminster", "value": "C123774"},
             {"label": "City of York", "value": "C123775"},
             {"label": "Clacton-on-Sea", "value": "C123776"},
             {"label": "Clapham", "value": "C123777"},
             {"label": "Clarborough", "value": "C123778"},
             {"label": "Clare", "value": "C123779"},
             {"label": "Claydon", "value": "C123780"},
             {"label": "Claypole", "value": "C123781"},
             {"label": "Clayton le Moors", "value": "C123782"},
             {"label": "Clayton West", "value": "C123783"},
             {"label": "Clayton-le-Woods", "value": "C123784"},
             {"label": "Cleator Moor", "value": "C123785"},
             {"label": "Cleckheaton", "value": "C123786"},
             {"label": "Cleethorpes", "value": "C123787"},
             {"label": "Clenchwarton", "value": "C123788"},
             {"label": "Cleobury Mortimer", "value": "C123789"},
             {"label": "Clerkenwell", "value": "C123790"},
             {"label": "Clevedon", "value": "C123791"},
             {"label": "Cleveleys", "value": "C123792"},
             {"label": "Cliffe", "value": "C123793"},
             {"label": "Clifton", "value": "C123794"},
             {"label": "Clitheroe", "value": "C123795"},
             {"label": "Clive", "value": "C123796"},
             {"label": "Clophill", "value": "C123797"},
             {"label": "Clowne", "value": "C123798"},
             {"label": "Clutton", "value": "C123799"},
             {"label": "Coalville", "value": "C123800"},
             {"label": "Coates", "value": "C123801"},
             {"label": "Cobham", "value": "C123802"},
             {"label": "Cockermouth", "value": "C123803"},
             {"label": "Cockfield", "value": "C123804"},
             {"label": "Cockington", "value": "C123805"},
             {"label": "Codicote", "value": "C123806"},
             {"label": "Codsall", "value": "C123807"},
             {"label": "Cogenhoe", "value": "C123808"},
             {"label": "Coggeshall", "value": "C123809"},
             {"label": "Colchester", "value": "C123810"},
             {"label": "Cold Ash", "value": "C123811"},
             {"label": "Cold Norton", "value": "C123812"},
             {"label": "Colden Common", "value": "C123813"},
             {"label": "Coleford", "value": "C123814"},
             {"label": "Colerne", "value": "C123815"},
             {"label": "Collier Row", "value": "C123816"},
             {"label": "Collingbourne Kingston", "value": "C123817"},
             {"label": "Collingham", "value": "C123818"},
             {"label": "Colnbrook", "value": "C123819"},
             {"label": "Colne", "value": "C123820"},
             {"label": "Colsterworth", "value": "C123821"},
             {"label": "Coltishall", "value": "C123822"},
             {"label": "Colwich", "value": "C123823"},
             {"label": "Colyton", "value": "C123824"},
             {"label": "Combe Martin", "value": "C123825"},
             {"label": "Comberton", "value": "C123826"},
             {"label": "Compton", "value": "C123827"},
             {"label": "Compton Martin", "value": "C123828"},
             {"label": "Congleton", "value": "C123829"},
             {"label": "Congresbury", "value": "C123830"},
             {"label": "Coningsby", "value": "C123831"},
             {"label": "Conisbrough", "value": "C123832"},
             {"label": "Consett", "value": "C123833"},
             {"label": "Cookham", "value": "C123834"},
             {"label": "Cookley", "value": "C123835"},
             {"label": "Cople", "value": "C123836"},
             {"label": "Copmanthorpe", "value": "C123837"},
             {"label": "Copplestone", "value": "C123838"},
             {"label": "Coppull", "value": "C123839"},
             {"label": "Copthorne", "value": "C123840"},
             {"label": "Corbridge", "value": "C123841"},
             {"label": "Corby", "value": "C123842"},
             {"label": "Corby Glen", "value": "C123843"},
             {"label": "Corfe Castle", "value": "C123844"},
             {"label": "Cornholme", "value": "C123845"},
             {"label": "Cornwall", "value": "C123846"},
             {"label": "Corse", "value": "C123847"},
             {"label": "Corsham", "value": "C123848"},
             {"label": "Corston", "value": "C123849"},
             {"label": "Cosby", "value": "C123850"},
             {"label": "Cosham", "value": "C123851"},
             {"label": "Costessey", "value": "C123852"},
             {"label": "Cotgrave", "value": "C123853"},
             {"label": "Cottenham", "value": "C123854"},
             {"label": "Cottesmore", "value": "C123855"},
             {"label": "Cottingham", "value": "C123856"},
             {"label": "Coulsdon", "value": "C123857"},
             {"label": "Coundon", "value": "C123858"},
             {"label": "Countesthorpe", "value": "C123859"},
             {"label": "County Durham", "value": "C123860"},
             {"label": "Coven", "value": "C123861"},
             {"label": "Coventry", "value": "C123862"},
             {"label": "Cowbit", "value": "C123863"},
             {"label": "Cowes", "value": "C123864"},
             {"label": "Cowfold", "value": "C123865"},
             {"label": "Cowley", "value": "C123866"},
             {"label": "Cowplain", "value": "C123867"},
             {"label": "Coxhoe", "value": "C123868"},
             {"label": "Cradley Heath", "value": "C123869"},
             {"label": "Cramlington", "value": "C123870"},
             {"label": "Cranbrook", "value": "C123871"},
             {"label": "Cranfield", "value": "C123872"},
             {"label": "Cranham", "value": "C123873"},
             {"label": "Cranleigh", "value": "C123874"},
             {"label": "Cranwell", "value": "C123875"},
             {"label": "Craven Arms", "value": "C123876"},
             {"label": "Crawley", "value": "C123877"},
             {"label": "Crawley Down", "value": "C123878"},
             {"label": "Credenhill", "value": "C123879"},
             {"label": "Crediton", "value": "C123880"},
             {"label": "Creech Saint Michael", "value": "C123881"},
             {"label": "Creswell", "value": "C123882"},
             {"label": "Crewe", "value": "C123883"},
             {"label": "Crewkerne", "value": "C123884"},
             {"label": "Crick", "value": "C123885"},
             {"label": "Cricklade", "value": "C123886"},
             {"label": "Cringleford", "value": "C123887"},
             {"label": "Croft", "value": "C123888"},
             {"label": "Crofton", "value": "C123889"},
             {"label": "Cromer", "value": "C123890"},
             {"label": "Cromford", "value": "C123891"},
             {"label": "Crondall", "value": "C123892"},
             {"label": "Crook", "value": "C123893"},
             {"label": "Cropwell Bishop", "value": "C123894"},
             {"label": "Crosby", "value": "C123895"},
             {"label": "Cross Hills", "value": "C123896"},
             {"label": "Croston", "value": "C123897"},
             {"label": "Crouch End", "value": "C123898"},
             {"label": "Crowborough", "value": "C123899"},
             {"label": "Crowland", "value": "C123900"},
             {"label": "Crowle", "value": "C123901"},
             {"label": "Crowthorne", "value": "C123902"},
             {"label": "Croydon", "value": "C123903"},
             {"label": "Crumpsall", "value": "C123904"},
             {"label": "Cuckfield", "value": "C123905"},
             {"label": "Cuddington", "value": "C123906"},
             {"label": "Cudworth", "value": "C123907"},
             {"label": "Cuffley", "value": "C123908"},
             {"label": "Culcheth", "value": "C123909"},
             {"label": "Cullingworth", "value": "C123910"},
             {"label": "Cullompton", "value": "C123911"},
             {"label": "Culverstone Green", "value": "C123912"},
             {"label": "Cumbria", "value": "C123913"},
             {"label": "Curdworth", "value": "C123914"},
             {"label": "Curry Rivel", "value": "C123915"},
             {"label": "Cuxton", "value": "C123916"},
             {"label": "Dagenham", "value": "C123917"},
             {"label": "Dalston", "value": "C123918"},
             {"label": "Dalton in Furness", "value": "C123919"},
             {"label": "Danbury", "value": "C123920"},
             {"label": "Danby", "value": "C123921"},
             {"label": "Darenth", "value": "C123922"},
             {"label": "Daresbury", "value": "C123923"},
             {"label": "Darfield", "value": "C123924"},
             {"label": "Darlaston", "value": "C123925"},
             {"label": "Darlington", "value": "C123926"},
             {"label": "Darras Hall", "value": "C123927"},
             {"label": "Darrington", "value": "C123928"},
             {"label": "Dartford", "value": "C123929"},
             {"label": "Dartmouth", "value": "C123930"},
             {"label": "Darton", "value": "C123931"},
             {"label": "Darwen", "value": "C123932"},
             {"label": "Datchet", "value": "C123933"},
             {"label": "Datchworth", "value": "C123934"},
             {"label": "Daventry", "value": "C123935"},
             {"label": "Dawlish", "value": "C123936"},
             {"label": "Deal", "value": "C123937"},
             {"label": "Deanshanger", "value": "C123938"},
             {"label": "Dearham", "value": "C123939"},
             {"label": "Debenham", "value": "C123940"},
             {"label": "Deddington", "value": "C123941"},
             {"label": "Dedham", "value": "C123942"},
             {"label": "Delabole", "value": "C123943"},
             {"label": "Delph", "value": "C123944"},
             {"label": "Denby Dale", "value": "C123945"},
             {"label": "Denham", "value": "C123946"},
             {"label": "Denholme", "value": "C123947"},
             {"label": "Denmead", "value": "C123948"},
             {"label": "Denton", "value": "C123949"},
             {"label": "Denton Holme", "value": "C123950"},
             {"label": "Derby", "value": "C123951"},
             {"label": "Derbyshire", "value": "C123952"},
             {"label": "Dersingham", "value": "C123953"},
             {"label": "Desborough", "value": "C123954"},
             {"label": "Desford", "value": "C123955"},
             {"label": "Devizes", "value": "C123956"},
             {"label": "Devon", "value": "C123957"},
             {"label": "Dewsbury", "value": "C123958"},
             {"label": "Dickens Heath", "value": "C123959"},
             {"label": "Dickleburgh", "value": "C123960"},
             {"label": "Didcot", "value": "C123961"},
             {"label": "Didsbury", "value": "C123962"},
             {"label": "Diggle", "value": "C123963"},
             {"label": "Dinnington", "value": "C123964"},
             {"label": "Dinton", "value": "C123965"},
             {"label": "Disley", "value": "C123966"},
             {"label": "Diss", "value": "C123967"},
             {"label": "Distington", "value": "C123968"},
             {"label": "District of Rutland", "value": "C123969"},
             {"label": "Ditchingham", "value": "C123970"},
             {"label": "Ditchling", "value": "C123971"},
             {"label": "Ditton Hill", "value": "C123972"},
             {"label": "Dobwalls", "value": "C123973"},
             {"label": "Doddington", "value": "C123974"},
             {"label": "Dodworth", "value": "C123975"},
             {"label": "Doncaster", "value": "C123976"},
             {"label": "Donington", "value": "C123977"},
             {"label": "Donisthorpe", "value": "C123978"},
             {"label": "Donnington", "value": "C123979"},
             {"label": "Dorchester", "value": "C123980"},
             {"label": "Dorking", "value": "C123981"},
             {"label": "Dormansland", "value": "C123982"},
             {"label": "Dorridge", "value": "C123983"},
             {"label": "Dorset", "value": "C123984"},
             {"label": "Dorstone", "value": "C123985"},
             {"label": "Dove Holes", "value": "C123986"},
             {"label": "Dover", "value": "C123987"},
             {"label": "Dovercourt", "value": "C123988"},
             {"label": "Doveridge", "value": "C123989"},
             {"label": "Downham Market", "value": "C123990"},
             {"label": "Downton", "value": "C123991"},
             {"label": "Draycott", "value": "C123992"},
             {"label": "Driffield", "value": "C123993"},
             {"label": "Droitwich", "value": "C123994"},
             {"label": "Dronfield", "value": "C123995"},
             {"label": "Droylsden", "value": "C123996"},
             {"label": "Drybrook", "value": "C123997"},
             {"label": "Ducklington", "value": "C123998"},
             {"label": "Dudley", "value": "C123999"},
             {"label": "Duffield", "value": "C124000"},
             {"label": "Dukinfield", "value": "C124001"},
             {"label": "Dulverton", "value": "C124002"},
             {"label": "Dunchurch", "value": "C124003"},
             {"label": "Dundry", "value": "C124004"},
             {"label": "Dunholme", "value": "C124005"},
             {"label": "Dunkeswell", "value": "C124006"},
             {"label": "Dunnington", "value": "C124007"},
             {"label": "Dunstable", "value": "C124008"},
             {"label": "Dunswell", "value": "C124009"},
             {"label": "Durham", "value": "C124010"},
             {"label": "Dursley", "value": "C124011"},
             {"label": "Duxford", "value": "C124012"},
             {"label": "Dymchurch", "value": "C124013"},
             {"label": "Eaglescliffe", "value": "C124014"},
             {"label": "Earby", "value": "C124015"},
             {"label": "Earith", "value": "C124016"},
             {"label": "Earl Shilton", "value": "C124017"},
             {"label": "Earls Barton", "value": "C124018"},
             {"label": "Earls Colne", "value": "C124019"},
             {"label": "Earlsfield", "value": "C124020"},
             {"label": "Easington", "value": "C124021"},
             {"label": "Easingwold", "value": "C124022"},
             {"label": "East Ayton", "value": "C124023"},
             {"label": "East Bergholt", "value": "C124024"},
             {"label": "East Boldon", "value": "C124025"},
             {"label": "East Bridgford", "value": "C124026"},
             {"label": "East Chevington", "value": "C124027"},
             {"label": "East Cowes", "value": "C124028"},
             {"label": "East Dean", "value": "C124029"},
             {"label": "East Dereham", "value": "C124030"},
             {"label": "East Grinstead", "value": "C124031"},
             {"label": "East Hanney", "value": "C124032"},
             {"label": "East Harling", "value": "C124033"},
             {"label": "East Harptree", "value": "C124034"},
             {"label": "East Horsley", "value": "C124035"},
             {"label": "East Keswick", "value": "C124036"},
             {"label": "East Leake", "value": "C124037"},
             {"label": "East Markham", "value": "C124038"},
             {"label": "East Molesey", "value": "C124039"},
             {"label": "East Peckham", "value": "C124040"},
             {"label": "East Rainton", "value": "C124041"},
             {"label": "East Riding of Yorkshire", "value": "C124042"},
             {"label": "East Sussex", "value": "C124043"},
             {"label": "East Tilbury", "value": "C124044"},
             {"label": "East Wittering", "value": "C124045"},
             {"label": "Eastbourne", "value": "C124046"},
             {"label": "Eastchurch", "value": "C124047"},
             {"label": "Eastington", "value": "C124048"},
             {"label": "Eastleigh", "value": "C124049"},
             {"label": "Eastoft", "value": "C124050"},
             {"label": "Easton", "value": "C124051"},
             {"label": "Easton on the Hill", "value": "C124052"},
             {"label": "Easton-in-Gordano", "value": "C124053"},
             {"label": "Eastrington", "value": "C124054"},
             {"label": "Eastry", "value": "C124055"},
             {"label": "Eastwood", "value": "C124056"},
             {"label": "Eaton Bray", "value": "C124057"},
             {"label": "Eaton Socon", "value": "C124058"},
             {"label": "Eattington", "value": "C124059"},
             {"label": "Eccles", "value": "C124060"},
             {"label": "Eccleshall", "value": "C124061"},
             {"label": "Eccleston", "value": "C124062"},
             {"label": "Eckington", "value": "C124063"},
             {"label": "Edenbridge", "value": "C124064"},
             {"label": "Edenfield", "value": "C124065"},
             {"label": "Edgmond", "value": "C124066"},
             {"label": "Edgware", "value": "C124067"},
             {"label": "Edgworth", "value": "C124068"},
             {"label": "Edington", "value": "C124069"},
             {"label": "Edith Weston", "value": "C124070"},
             {"label": "Edwinstowe", "value": "C124071"},
             {"label": "Egham", "value": "C124072"},
             {"label": "Egremont", "value": "C124073"},
             {"label": "Eight Ash Green", "value": "C124074"},
             {"label": "Elland", "value": "C124075"},
             {"label": "Ellerker", "value": "C124076"},
             {"label": "Ellerton", "value": "C124077"},
             {"label": "Ellesmere", "value": "C124078"},
             {"label": "Ellesmere Port", "value": "C124079"},
             {"label": "Elm Park", "value": "C124080"},
             {"label": "Elmstead Market", "value": "C124081"},
             {"label": "Elmswell", "value": "C124082"},
             {"label": "Elsenham", "value": "C124083"},
             {"label": "Elstead", "value": "C124084"},
             {"label": "Elstree", "value": "C124085"},
             {"label": "Elswick", "value": "C124086"},
             {"label": "Elvington", "value": "C124087"},
             {"label": "Elwick", "value": "C124088"},
             {"label": "Ely", "value": "C124089"},
             {"label": "Emberton", "value": "C124090"},
             {"label": "Embleton", "value": "C124091"},
             {"label": "Embsay", "value": "C124092"},
             {"label": "Emley", "value": "C124093"},
             {"label": "Emneth", "value": "C124094"},
             {"label": "Empingham", "value": "C124095"},
             {"label": "Emsworth", "value": "C124096"},
             {"label": "Enderby", "value": "C124097"},
             {"label": "Enfield Town", "value": "C124098"},
             {"label": "Epping", "value": "C124099"},
             {"label": "Epsom", "value": "C124100"},
             {"label": "Epworth", "value": "C124101"},
             {"label": "Erith", "value": "C124102"},
             {"label": "Esher", "value": "C124103"},
             {"label": "Essendine", "value": "C124104"},
             {"label": "Essex", "value": "C124105"},
             {"label": "Essington", "value": "C124106"},
             {"label": "Eton", "value": "C124107"},
             {"label": "Eton Wick", "value": "C124108"},
             {"label": "Etton", "value": "C124109"},
             {"label": "Etwall", "value": "C124110"},
             {"label": "Euxton", "value": "C124111"},
             {"label": "Evenwood", "value": "C124112"},
             {"label": "Evercreech", "value": "C124113"},
             {"label": "Eversholt", "value": "C124114"},
             {"label": "Eversley", "value": "C124115"},
             {"label": "Everton", "value": "C124116"},
             {"label": "Evesham", "value": "C124117"},
             {"label": "Ewell", "value": "C124118"},
             {"label": "Ewhurst", "value": "C124119"},
             {"label": "Ewyas Harold", "value": "C124120"},
             {"label": "Exeter", "value": "C124121"},
             {"label": "Exhall", "value": "C124122"},
             {"label": "Exminster", "value": "C124123"},
             {"label": "Exmouth", "value": "C124124"},
             {"label": "Exning", "value": "C124125"},
             {"label": "Exton", "value": "C124126"},
             {"label": "Eye", "value": "C124127"},
             {"label": "Eynsford", "value": "C124128"},
             {"label": "Eynsham", "value": "C124129"},
             {"label": "Eythorne", "value": "C124130"},
             {"label": "Failsworth", "value": "C124131"},
             {"label": "Fairford", "value": "C124132"},
             {"label": "Fairlands", "value": "C124133"},
             {"label": "Fairlight", "value": "C124134"},
             {"label": "Fakenham", "value": "C124135"},
             {"label": "Fallowfield", "value": "C124136"},
             {"label": "Falmouth", "value": "C124137"},
             {"label": "Fareham", "value": "C124138"},
             {"label": "Faringdon", "value": "C124139"},
             {"label": "Farnborough", "value": "C124140"},
             {"label": "Farndon", "value": "C124141"},
             {"label": "Farnham", "value": "C124142"},
             {"label": "Farnham Royal", "value": "C124143"},
             {"label": "Farnsfield", "value": "C124144"},
             {"label": "Farnworth", "value": "C124145"},
             {"label": "Faversham", "value": "C124146"},
             {"label": "Fazeley", "value": "C124147"},
             {"label": "Featherstone", "value": "C124148"},
             {"label": "Felixstowe", "value": "C124149"},
             {"label": "Felling", "value": "C124150"},
             {"label": "Feltham", "value": "C124151"},
             {"label": "Felton", "value": "C124152"},
             {"label": "Feltwell", "value": "C124153"},
             {"label": "Fenstanton", "value": "C124154"},
             {"label": "Ferndown", "value": "C124155"},
             {"label": "Fernhill Heath", "value": "C124156"},
             {"label": "Fernhurst", "value": "C124157"},
             {"label": "Ferrybridge", "value": "C124158"},
             {"label": "Ferryhill", "value": "C124159"},
             {"label": "Filey", "value": "C124160"},
             {"label": "Finchampstead", "value": "C124161"},
             {"label": "Findern", "value": "C124162"},
             {"label": "Findon", "value": "C124163"},
             {"label": "Finedon", "value": "C124164"},
             {"label": "Finningley", "value": "C124165"},
             {"label": "Fishburn", "value": "C124166"},
             {"label": "Fishtoft", "value": "C124167"},
             {"label": "Fitzwilliam", "value": "C124168"},
             {"label": "Five Oak Green", "value": "C124169"},
             {"label": "Flamborough", "value": "C124170"},
             {"label": "Fleckney", "value": "C124171"},
             {"label": "Fleet", "value": "C124172"},
             {"label": "Fleetwood", "value": "C124173"},
             {"label": "Flexbury", "value": "C124174"},
             {"label": "Flimby", "value": "C124175"},
             {"label": "Flimwell", "value": "C124176"},
             {"label": "Flitwick", "value": "C124177"},
             {"label": "Flockton", "value": "C124178"},
             {"label": "Flookburgh", "value": "C124179"},
             {"label": "Flore", "value": "C124180"},
             {"label": "Folkestone", "value": "C124181"},
             {"label": "Fontwell", "value": "C124182"},
             {"label": "Ford", "value": "C124183"},
             {"label": "Fordham", "value": "C124184"},
             {"label": "Fordingbridge", "value": "C124185"},
             {"label": "Forest Row", "value": "C124186"},
             {"label": "Formby", "value": "C124187"},
             {"label": "Fortuneswell", "value": "C124188"},
             {"label": "Foulridge", "value": "C124189"},
             {"label": "Foulsham", "value": "C124190"},
             {"label": "Four Lanes", "value": "C124191"},
             {"label": "Four Marks", "value": "C124192"},
             {"label": "Fowey", "value": "C124193"},
             {"label": "Fowlmere", "value": "C124194"},
             {"label": "Framlingham", "value": "C124195"},
             {"label": "Frampton on Severn", "value": "C124196"},
             {"label": "Freckleton", "value": "C124197"},
             {"label": "Fremington", "value": "C124198"},
             {"label": "Freshwater", "value": "C124199"},
             {"label": "Frimley", "value": "C124200"},
             {"label": "Frinton-on-Sea", "value": "C124201"},
             {"label": "Friston", "value": "C124202"},
             {"label": "Frizington", "value": "C124203"},
             {"label": "Frodsham", "value": "C124204"},
             {"label": "Frome", "value": "C124205"},
             {"label": "Fulbourn", "value": "C124206"},
             {"label": "Full Sutton", "value": "C124207"},
             {"label": "Furnace Green", "value": "C124208"},
             {"label": "Fylde", "value": "C124209"},
             {"label": "Gainford", "value": "C124210"},
             {"label": "Gainsborough", "value": "C124211"},
             {"label": "Galgate", "value": "C124212"},
             {"label": "Gamlingay", "value": "C124213"},
             {"label": "Garforth", "value": "C124214"},
             {"label": "Gargrave", "value": "C124215"},
             {"label": "Garsington", "value": "C124216"},
             {"label": "Garstang", "value": "C124217"},
             {"label": "Gateshead", "value": "C124218"},
             {"label": "Geddington", "value": "C124219"},
             {"label": "Gedney Hill", "value": "C124220"},
             {"label": "Germoe", "value": "C124221"},
             {"label": "Gerrards Cross", "value": "C124222"},
             {"label": "Gilberdyke", "value": "C124223"},
             {"label": "Gillingham", "value": "C124224"},
             {"label": "Girton", "value": "C124225"},
             {"label": "Glapwell", "value": "C124226"},
             {"label": "Glastonbury", "value": "C124227"},
             {"label": "Glazebury", "value": "C124228"},
             {"label": "Glemsford", "value": "C124229"},
             {"label": "Glenfield", "value": "C124230"},
             {"label": "Glinton", "value": "C124231"},
             {"label": "Glossop", "value": "C124232"},
             {"label": "Gloucester", "value": "C124233"},
             {"label": "Gloucestershire", "value": "C124234"},
             {"label": "Glusburn", "value": "C124235"},
             {"label": "Gnosall", "value": "C124236"},
             {"label": "Gobowen", "value": "C124237"},
             {"label": "Godalming", "value": "C124238"},
             {"label": "Godmanchester", "value": "C124239"},
             {"label": "Godshill", "value": "C124240"},
             {"label": "Godstone", "value": "C124241"},
             {"label": "Golborne", "value": "C124242"},
             {"label": "Goole", "value": "C124243"},
             {"label": "Goosnargh", "value": "C124244"},
             {"label": "Goostrey", "value": "C124245"},
             {"label": "Goring", "value": "C124246"},
             {"label": "Goring-by-Sea", "value": "C124247"},
             {"label": "Gorleston-on-Sea", "value": "C124248"},
             {"label": "Gosberton", "value": "C124249"},
             {"label": "Gosfield", "value": "C124250"},
             {"label": "Gosforth", "value": "C124251"},
             {"label": "Gosport", "value": "C124252"},
             {"label": "Gossops Green", "value": "C124253"},
             {"label": "Gotham", "value": "C124254"},
             {"label": "Goudhurst", "value": "C124255"},
             {"label": "Goxhill", "value": "C124256"},
             {"label": "Grain", "value": "C124257"},
             {"label": "Grange Hill", "value": "C124258"},
             {"label": "Grange-over-Sands", "value": "C124259"},
             {"label": "Grantham", "value": "C124260"},
             {"label": "Grappenhall", "value": "C124261"},
             {"label": "Grassington", "value": "C124262"},
             {"label": "Gravesend", "value": "C124263"},
             {"label": "Grays", "value": "C124264"},
             {"label": "Greasby", "value": "C124265"},
             {"label": "Great Amwell", "value": "C124266"},
             {"label": "Great Ayton", "value": "C124267"},
             {"label": "Great Bardfield", "value": "C124268"},
             {"label": "Great Barford", "value": "C124269"},
             {"label": "Great Barton", "value": "C124270"},
             {"label": "Great Bedwyn", "value": "C124271"},
             {"label": "Great Bentley", "value": "C124272"},
             {"label": "Great Bookham", "value": "C124273"},
             {"label": "Great Chesterford", "value": "C124274"},
             {"label": "Great Dunmow", "value": "C124275"},
             {"label": "Great Eccleston", "value": "C124276"},
             {"label": "Great Glen", "value": "C124277"},
             {"label": "Great Gonerby", "value": "C124278"},
             {"label": "Great Gransden", "value": "C124279"},
             {"label": "Great Hanwood", "value": "C124280"},
             {"label": "Great Harwood", "value": "C124281"},
             {"label": "Great Haywood", "value": "C124282"},
             {"label": "Great Horkesley", "value": "C124283"},
             {"label": "Great Horwood", "value": "C124284"},
             {"label": "Great Houghton", "value": "C124285"},
             {"label": "Great Leighs", "value": "C124286"},
             {"label": "Great Malvern", "value": "C124287"},
             {"label": "Great Marton", "value": "C124288"},
             {"label": "Great Missenden", "value": "C124289"},
             {"label": "Great Paxton", "value": "C124290"},
             {"label": "Great Sankey", "value": "C124291"},
             {"label": "Great Torrington", "value": "C124292"},
             {"label": "Great Wakering", "value": "C124293"},
             {"label": "Great Waldingfield", "value": "C124294"},
             {"label": "Great Wyrley", "value": "C124295"},
             {"label": "Great Yarmouth", "value": "C124296"},
             {"label": "Great Yeldham", "value": "C124297"},
             {"label": "Greater London", "value": "C124298"},
             {"label": "Greatham", "value": "C124299"},
             {"label": "Greenfield", "value": "C124300"},
             {"label": "Greenford", "value": "C124301"},
             {"label": "Greenhill", "value": "C124302"},
             {"label": "Greenhithe", "value": "C124303"},
             {"label": "Greetham", "value": "C124304"},
             {"label": "Grimethorpe", "value": "C124305"},
             {"label": "Grimsby", "value": "C124306"},
             {"label": "Grimston", "value": "C124307"},
             {"label": "Griston", "value": "C124308"},
             {"label": "Groombridge", "value": "C124309"},
             {"label": "Grove", "value": "C124310"},
             {"label": "Grundisburgh", "value": "C124311"},
             {"label": "Guilden Sutton", "value": "C124312"},
             {"label": "Guildford", "value": "C124313"},
             {"label": "Guisborough", "value": "C124314"},
             {"label": "Guiseley", "value": "C124315"},
             {"label": "Gunness", "value": "C124316"},
             {"label": "Gunnislake", "value": "C124317"},
             {"label": "Hackleton", "value": "C124318"},
             {"label": "Haddenham", "value": "C124319"},
             {"label": "Hadleigh", "value": "C124320"},
             {"label": "Hadley", "value": "C124321"},
             {"label": "Hadley Wood", "value": "C124322"},
             {"label": "Hadlow", "value": "C124323"},
             {"label": "Hadston", "value": "C124324"},
             {"label": "Hagley", "value": "C124325"},
             {"label": "Hailsham", "value": "C124326"},
             {"label": "Hainault", "value": "C124327"},
             {"label": "Hale", "value": "C124328"},
             {"label": "Halesowen", "value": "C124329"},
             {"label": "Halesworth", "value": "C124330"},
             {"label": "Halifax", "value": "C124331"},
             {"label": "Halling", "value": "C124332"},
             {"label": "Hallow", "value": "C124333"},
             {"label": "Halstead", "value": "C124334"},
             {"label": "Halton", "value": "C124335"},
             {"label": "Haltwhistle", "value": "C124336"},
             {"label": "Hamble-le-Rice", "value": "C124337"},
             {"label": "Hambleton", "value": "C124338"},
             {"label": "Hameldon Hill", "value": "C124339"},
             {"label": "Hampshire", "value": "C124340"},
             {"label": "Hampton", "value": "C124341"},
             {"label": "Hampton in Arden", "value": "C124342"},
             {"label": "Handcross", "value": "C124343"},
             {"label": "Hannington", "value": "C124344"},
             {"label": "Hanslope", "value": "C124345"},
             {"label": "Hapton", "value": "C124346"},
             {"label": "Harbury", "value": "C124347"},
             {"label": "Hardingstone", "value": "C124348"},
             {"label": "Hardwick Village", "value": "C124349"},
             {"label": "Harefield", "value": "C124350"},
             {"label": "Harleston", "value": "C124351"},
             {"label": "Harlington", "value": "C124352"},
             {"label": "Harlow", "value": "C124353"},
             {"label": "Harold Wood", "value": "C124354"},
             {"label": "Harpenden", "value": "C124355"},
             {"label": "Harpole", "value": "C124356"},
             {"label": "Harrietsham", "value": "C124357"},
             {"label": "Harringay", "value": "C124358"},
             {"label": "Harrogate", "value": "C124359"},
             {"label": "Harrold", "value": "C124360"},
             {"label": "Harrow on the Hill", "value": "C124361"},
             {"label": "Harston", "value": "C124362"},
             {"label": "Harthill", "value": "C124363"},
             {"label": "Hartlebury", "value": "C124364"},
             {"label": "Hartlepool", "value": "C124365"},
             {"label": "Hartley", "value": "C124366"},
             {"label": "Hartley Wintney", "value": "C124367"},
             {"label": "Hartshill", "value": "C124368"},
             {"label": "Hartwell", "value": "C124369"},
             {"label": "Harvington", "value": "C124370"},
             {"label": "Harwell", "value": "C124371"},
             {"label": "Harwich", "value": "C124372"},
             {"label": "Haslemere", "value": "C124373"},
             {"label": "Haslingden", "value": "C124374"},
             {"label": "Haslingfield", "value": "C124375"},
             {"label": "Hassocks", "value": "C124376"},
             {"label": "Hastings", "value": "C124377"},
             {"label": "Haswell", "value": "C124378"},
             {"label": "Hatfield", "value": "C124379"},
             {"label": "Hatfield Heath", "value": "C124380"},
             {"label": "Hatfield Peverel", "value": "C124381"},
             {"label": "Hatherleigh", "value": "C124382"},
             {"label": "Hathern", "value": "C124383"},
             {"label": "Hathersage", "value": "C124384"},
             {"label": "Hatton", "value": "C124385"},
             {"label": "Haughley", "value": "C124386"},
             {"label": "Haughton Green", "value": "C124387"},
             {"label": "Havant", "value": "C124388"},
             {"label": "Haverhill", "value": "C124389"},
             {"label": "Haverigg", "value": "C124390"},
             {"label": "Hawkhurst", "value": "C124391"},
             {"label": "Hawkinge", "value": "C124392"},
             {"label": "Haworth", "value": "C124393"},
             {"label": "Hawthorn", "value": "C124394"},
             {"label": "Haxby", "value": "C124395"},
             {"label": "Haxey", "value": "C124396"},
             {"label": "Haydock", "value": "C124397"},
             {"label": "Haydon Bridge", "value": "C124398"},
             {"label": "Hayes", "value": "C124399"},
             {"label": "Hayfield", "value": "C124400"},
             {"label": "Hayle", "value": "C124401"},
             {"label": "Hayling Island", "value": "C124402"},
             {"label": "Haynes", "value": "C124403"},
             {"label": "Hayton", "value": "C124404"},
             {"label": "Haywards Heath", "value": "C124405"},
             {"label": "Hazel Grove", "value": "C124406"},
             {"label": "Hazlerigg", "value": "C124407"},
             {"label": "Heacham", "value": "C124408"},
             {"label": "Headcorn", "value": "C124409"},
             {"label": "Heage", "value": "C124410"},
             {"label": "Healing", "value": "C124411"},
             {"label": "Heanor", "value": "C124412"},
             {"label": "Heath and Reach", "value": "C124413"},
             {"label": "Heathfield", "value": "C124414"},
             {"label": "Heaton Chapel", "value": "C124415"},
             {"label": "Heavitree", "value": "C124416"},
             {"label": "Hebburn", "value": "C124417"},
             {"label": "Hebden Bridge", "value": "C124418"},
             {"label": "Heckington", "value": "C124419"},
             {"label": "Heckmondwike", "value": "C124420"},
             {"label": "Heddon on the Wall", "value": "C124421"},
             {"label": "Hedge End", "value": "C124422"},
             {"label": "Hedon", "value": "C124423"},
             {"label": "Heighington", "value": "C124424"},
             {"label": "Hellaby", "value": "C124425"},
             {"label": "Hellifield", "value": "C124426"},
             {"label": "Helmsley", "value": "C124427"},
             {"label": "Helpston", "value": "C124428"},
             {"label": "Helsby", "value": "C124429"},
             {"label": "Helston", "value": "C124430"},
             {"label": "Hemel Hempstead", "value": "C124431"},
             {"label": "Hemingbrough", "value": "C124432"},
             {"label": "Hemingford Grey", "value": "C124433"},
             {"label": "Hemsby", "value": "C124434"},
             {"label": "Hemsworth", "value": "C124435"},
             {"label": "Hemyock", "value": "C124436"},
             {"label": "Henfield", "value": "C124437"},
             {"label": "Henley in Arden", "value": "C124438"},
             {"label": "Henley-on-Thames", "value": "C124439"},
             {"label": "Henlow", "value": "C124440"},
             {"label": "Henstridge", "value": "C124441"},
             {"label": "Hereford", "value": "C124442"},
             {"label": "Herefordshire", "value": "C124443"},
             {"label": "Hermitage", "value": "C124444"},
             {"label": "Herne Bay", "value": "C124445"},
             {"label": "Herstmonceux", "value": "C124446"},
             {"label": "Hertford", "value": "C124447"},
             {"label": "Hertfordshire", "value": "C124448"},
             {"label": "Heswall", "value": "C124449"},
             {"label": "Hethersett", "value": "C124450"},
             {"label": "Hetton-Le-Hole", "value": "C124451"},
             {"label": "Hexham", "value": "C124452"},
             {"label": "Heysham", "value": "C124453"},
             {"label": "Heywood", "value": "C124454"},
             {"label": "Hibaldstow", "value": "C124455"},
             {"label": "High Barnet", "value": "C124456"},
             {"label": "High Bentham", "value": "C124457"},
             {"label": "High Etherley", "value": "C124458"},
             {"label": "High Halden", "value": "C124459"},
             {"label": "High Halstow", "value": "C124460"},
             {"label": "High Legh", "value": "C124461"},
             {"label": "High Ongar", "value": "C124462"},
             {"label": "High Peak", "value": "C124463"},
             {"label": "High Wycombe", "value": "C124464"},
             {"label": "Higham Ferrers", "value": "C124465"},
             {"label": "Highbridge", "value": "C124466"},
             {"label": "Highclere", "value": "C124467"},
             {"label": "Highley", "value": "C124468"},
             {"label": "Hightown", "value": "C124469"},
             {"label": "Highworth", "value": "C124470"},
             {"label": "Hilton", "value": "C124471"},
             {"label": "Hinchley Wood", "value": "C124472"},
             {"label": "Hinckley", "value": "C124473"},
             {"label": "Hindhead", "value": "C124474"},
             {"label": "Hindley", "value": "C124475"},
             {"label": "Hindon", "value": "C124476"},
             {"label": "Hingham", "value": "C124477"},
             {"label": "Hinton", "value": "C124478"},
             {"label": "Histon", "value": "C124479"},
             {"label": "Hitchin", "value": "C124480"},
             {"label": "Hockley", "value": "C124481"},
             {"label": "Hockley Heath", "value": "C124482"},
             {"label": "Hockliffe", "value": "C124483"},
             {"label": "Hockwold cum Wilton", "value": "C124484"},
             {"label": "Hoddesdon", "value": "C124485"},
             {"label": "Holbeach", "value": "C124486"},
             {"label": "Holbeck", "value": "C124487"},
             {"label": "Holbrook", "value": "C124488"},
             {"label": "Hollingworth", "value": "C124489"},
             {"label": "Holloway", "value": "C124490"},
             {"label": "Hollym", "value": "C124491"},
             {"label": "Holmes Chapel", "value": "C124492"},
             {"label": "Holmfirth", "value": "C124493"},
             {"label": "Holmwood", "value": "C124494"},
             {"label": "Holsworthy", "value": "C124495"},
             {"label": "Holt", "value": "C124496"},
             {"label": "Holtby", "value": "C124497"},
             {"label": "Holton le Clay", "value": "C124498"},
             {"label": "Holywell Green", "value": "C124499"},
             {"label": "Honeybourne", "value": "C124500"},
             {"label": "Honiton", "value": "C124501"},
             {"label": "Hoo", "value": "C124502"},
             {"label": "Hook", "value": "C124503"},
             {"label": "Hook Norton", "value": "C124504"},
             {"label": "Hoole", "value": "C124505"},
             {"label": "Hope Valley", "value": "C124506"},
             {"label": "Hopton on Sea", "value": "C124507"},
             {"label": "Horam", "value": "C124508"},
             {"label": "Horbury", "value": "C124509"},
             {"label": "Horley", "value": "C124510"},
             {"label": "Horncastle", "value": "C124511"},
             {"label": "Hornchurch", "value": "C124512"},
             {"label": "Horndon on the Hill", "value": "C124513"},
             {"label": "Horning", "value": "C124514"},
             {"label": "Hornsea", "value": "C124515"},
             {"label": "Horrabridge", "value": "C124516"},
             {"label": "Horsford", "value": "C124517"},
             {"label": "Horsforth", "value": "C124518"},
             {"label": "Horsham", "value": "C124519"},
             {"label": "Horsmonden", "value": "C124520"},
             {"label": "Horsted Keynes", "value": "C124521"},
             {"label": "Horton", "value": "C124522"},
             {"label": "Horton Kirby", "value": "C124523"},
             {"label": "Horwich", "value": "C124524"},
             {"label": "Hotham", "value": "C124525"},
             {"label": "Houghton", "value": "C124526"},
             {"label": "Houghton Conquest", "value": "C124527"},
             {"label": "Houghton on the Hill", "value": "C124528"},
             {"label": "Houghton-Le-Spring", "value": "C124529"},
             {"label": "Hounslow", "value": "C124530"},
             {"label": "Hove", "value": "C124531"},
             {"label": "Hoveton", "value": "C124532"},
             {"label": "Howden", "value": "C124533"},
             {"label": "Hoylake", "value": "C124534"},
             {"label": "Hoyland Nether", "value": "C124535"},
             {"label": "Hucknall", "value": "C124536"},
             {"label": "Huddersfield", "value": "C124537"},
             {"label": "Hugh Town", "value": "C124538"},
             {"label": "Hulme", "value": "C124539"},
             {"label": "Humberston", "value": "C124540"},
             {"label": "Hungerford", "value": "C124541"},
             {"label": "Hunmanby", "value": "C124542"},
             {"label": "Hunstanton", "value": "C124543"},
             {"label": "Huntingdon", "value": "C124544"},
             {"label": "Huntley", "value": "C124545"},
             {"label": "Huntspill", "value": "C124546"},
             {"label": "Hunwick", "value": "C124547"},
             {"label": "Hurst", "value": "C124548"},
             {"label": "Hurstpierpoint", "value": "C124549"},
             {"label": "Husbands Bosworth", "value": "C124550"},
             {"label": "Husborne Crawley", "value": "C124551"},
             {"label": "Huyton", "value": "C124552"},
             {"label": "Hyde", "value": "C124553"},
             {"label": "Hyde Heath", "value": "C124554"},
             {"label": "Hythe", "value": "C124555"},
             {"label": "Ibstock", "value": "C124556"},
             {"label": "Ilchester", "value": "C124557"},
             {"label": "Ilfracombe", "value": "C124558"},
             {"label": "Ilkeston", "value": "C124559"},
             {"label": "Ilkley", "value": "C124560"},
             {"label": "Ilminster", "value": "C124561"},
             {"label": "Immingham", "value": "C124562"},
             {"label": "Ince Blundell", "value": "C124563"},
             {"label": "Ince-in-Makerfield", "value": "C124564"},
             {"label": "Ingatestone", "value": "C124565"},
             {"label": "Ingleby Greenhow", "value": "C124566"},
             {"label": "Ingleton", "value": "C124567"},
             {"label": "Ingoldmells", "value": "C124568"},
             {"label": "Ingrave", "value": "C124569"},
             {"label": "Inkberrow", "value": "C124570"},
             {"label": "Ipplepen", "value": "C124571"},
             {"label": "Ipswich", "value": "C124572"},
             {"label": "Irchester", "value": "C124573"},
             {"label": "Irlam", "value": "C124574"},
             {"label": "Ironbridge", "value": "C124575"},
             {"label": "Irthlingborough", "value": "C124576"},
             {"label": "Isle of Wight", "value": "C124577"},
             {"label": "Isleham", "value": "C124578"},
             {"label": "Isles of Scilly", "value": "C124579"},
             {"label": "Isleworth", "value": "C124580"},
             {"label": "Islington", "value": "C124581"},
             {"label": "Iver", "value": "C124582"},
             {"label": "Iver Heath", "value": "C124583"},
             {"label": "Ivinghoe", "value": "C124584"},
             {"label": "Ivybridge", "value": "C124585"},
             {"label": "Iwade", "value": "C124586"},
             {"label": "Ixworth", "value": "C124587"},
             {"label": "Jarrow", "value": "C124588"},
             {"label": "Keadby", "value": "C124589"},
             {"label": "Kearsley", "value": "C124590"},
             {"label": "Kedington", "value": "C124591"},
             {"label": "Keelby", "value": "C124592"},
             {"label": "Keele", "value": "C124593"},
             {"label": "Kegworth", "value": "C124594"},
             {"label": "Keighley", "value": "C124595"},
             {"label": "Kelloe", "value": "C124596"},
             {"label": "Kelsall", "value": "C124597"},
             {"label": "Kelvedon", "value": "C124598"},
             {"label": "Kelvedon Hatch", "value": "C124599"},
             {"label": "Kempsey", "value": "C124600"},
             {"label": "Kempston", "value": "C124601"},
             {"label": "Kempston Hardwick", "value": "C124602"},
             {"label": "Kemsing", "value": "C124603"},
             {"label": "Kendal", "value": "C124604"},
             {"label": "Kenilworth", "value": "C124605"},
             {"label": "Kennington", "value": "C124606"},
             {"label": "Kensworth", "value": "C124607"},
             {"label": "Kent", "value": "C124608"},
             {"label": "Kenton", "value": "C124609"},
             {"label": "Keresley", "value": "C124610"},
             {"label": "Kesgrave", "value": "C124611"},
             {"label": "Kessingland", "value": "C124612"},
             {"label": "Keswick", "value": "C124613"},
             {"label": "Kettering", "value": "C124614"},
             {"label": "Ketton", "value": "C124615"},
             {"label": "Keyingham", "value": "C124616"},
             {"label": "Keynsham", "value": "C124617"},
             {"label": "Keyworth", "value": "C124618"},
             {"label": "Kibworth Harcourt", "value": "C124619"},
             {"label": "Kidderminster", "value": "C124620"},
             {"label": "Kidlington", "value": "C124621"},
             {"label": "Kidsgrove", "value": "C124622"},
             {"label": "Kilburn", "value": "C124623"},
             {"label": "Kilham", "value": "C124624"},
             {"label": "Killamarsh", "value": "C124625"},
             {"label": "Kilsby", "value": "C124626"},
             {"label": "Kimberley", "value": "C124627"},
             {"label": "Kimbolton", "value": "C124628"},
             {"label": "Kimpton", "value": "C124629"},
             {"label": "Kineton", "value": "C124630"},
             {"label": "Kings Clipstone", "value": "C124631"},
             {"label": "Kings Lynn", "value": "C124632"},
             {"label": "Kings Hill", "value": "C124633"},
             {"label": "Kings Langley", "value": "C124634"},
             {"label": "Kings Sutton", "value": "C124635"},
             {"label": "Kings Worthy", "value": "C124636"},
             {"label": "Kingsbridge", "value": "C124637"},
             {"label": "Kingsbury", "value": "C124638"},
             {"label": "Kingsclere", "value": "C124639"},
             {"label": "Kingskerswell", "value": "C124640"},
             {"label": "Kingsland", "value": "C124641"},
             {"label": "Kingsley", "value": "C124642"},
             {"label": "Kingsteignton", "value": "C124643"},
             {"label": "Kingston Bagpuize", "value": "C124644"},
             {"label": "Kingston Seymour", "value": "C124645"},
             {"label": "Kingston upon Hull", "value": "C124646"},
             {"label": "Kingston upon Thames", "value": "C124647"},
             {"label": "Kingswinford", "value": "C124648"},
             {"label": "Kingswood", "value": "C124649"},
             {"label": "Kington", "value": "C124650"},
             {"label": "Kintbury", "value": "C124651"},
             {"label": "Kinvere", "value": "C124652"},
             {"label": "Kippax", "value": "C124653"},
             {"label": "Kirby Muxloe", "value": "C124654"},
             {"label": "Kirk Sandall", "value": "C124655"},
             {"label": "Kirkburton", "value": "C124656"},
             {"label": "Kirkby", "value": "C124657"},
             {"label": "Kirkby in Ashfield", "value": "C124658"},
             {"label": "Kirkby Lonsdale", "value": "C124659"},
             {"label": "Kirkby Stephen", "value": "C124660"},
             {"label": "Kirkbymoorside", "value": "C124661"},
             {"label": "Kirkham", "value": "C124662"},
             {"label": "Kirklees", "value": "C124663"},
             {"label": "Kirton", "value": "C124664"},
             {"label": "Kirton in Lindsey", "value": "C124665"},
             {"label": "Kislingbury", "value": "C124666"},
             {"label": "Kiveton Park", "value": "C124667"},
             {"label": "Knaphill", "value": "C124668"},
             {"label": "Knaresborough", "value": "C124669"},
             {"label": "Knebworth", "value": "C124670"},
             {"label": "Knottingley", "value": "C124671"},
             {"label": "Knowle", "value": "C124672"},
             {"label": "Knowsley", "value": "C124673"},
             {"label": "Knutsford", "value": "C124674"},
             {"label": "Laceby", "value": "C124675"},
             {"label": "Lacock", "value": "C124676"},
             {"label": "Lakenheath", "value": "C124677"},
             {"label": "Lamberhurst", "value": "C124678"},
             {"label": "Lambeth", "value": "C124679"},
             {"label": "Lambourn", "value": "C124680"},
             {"label": "Lamesley", "value": "C124681"},
             {"label": "Lancashire", "value": "C124682"},
             {"label": "Lancaster", "value": "C124683"},
             {"label": "Lanchester", "value": "C124684"},
             {"label": "Lancing", "value": "C124685"},
             {"label": "Landrake", "value": "C124686"},
             {"label": "Langford", "value": "C124687"},
             {"label": "Langham", "value": "C124688"},
             {"label": "Langho", "value": "C124689"},
             {"label": "Langley Green", "value": "C124690"},
             {"label": "Langley Park", "value": "C124691"},
             {"label": "Langport", "value": "C124692"},
             {"label": "Langtoft", "value": "C124693"},
             {"label": "Lark Hill", "value": "C124694"},
             {"label": "Larkfield", "value": "C124695"},
             {"label": "Latchingdon and Snoreham", "value": "C124696"},
             {"label": "Launceston", "value": "C124697"},
             {"label": "Lavendon", "value": "C124698"},
             {"label": "Lavenham", "value": "C124699"},
             {"label": "Layer de la Haye", "value": "C124700"},
             {"label": "Leasingham", "value": "C124701"},
             {"label": "Leatherhead", "value": "C124702"},
             {"label": "Lechlade", "value": "C124703"},
             {"label": "Leconfield", "value": "C124704"},
             {"label": "Ledbury", "value": "C124705"},
             {"label": "Ledsham", "value": "C124706"},
             {"label": "Lee-on-the-Solent", "value": "C124707"},
             {"label": "Leeds", "value": "C124708"},
             {"label": "Leek", "value": "C124709"},
             {"label": "Leek Wootton", "value": "C124710"},
             {"label": "Leicester", "value": "C124711"},
             {"label": "Leicestershire", "value": "C124712"},
             {"label": "Leigh", "value": "C124713"},
             {"label": "Leighton Buzzard", "value": "C124714"},
             {"label": "Leiston", "value": "C124715"},
             {"label": "Lenham", "value": "C124716"},
             {"label": "Leominster", "value": "C124717"},
             {"label": "Lesbury", "value": "C124718"},
             {"label": "Letchworth", "value": "C124719"},
             {"label": "Letchworth Garden City", "value": "C124720"},
             {"label": "Leven", "value": "C124721"},
             {"label": "Lewes", "value": "C124722"},
             {"label": "Leyburn", "value": "C124723"},
             {"label": "Leyland", "value": "C124724"},
             {"label": "Leysdown-on-Sea", "value": "C124725"},
             {"label": "Lichfield", "value": "C124726"},
             {"label": "Lidlington", "value": "C124727"},
             {"label": "Lifton", "value": "C124728"},
             {"label": "Lightwater", "value": "C124729"},
             {"label": "Limpley Stoke", "value": "C124730"},
             {"label": "Lincoln", "value": "C124731"},
             {"label": "Lincolnshire", "value": "C124732"},
             {"label": "Lingdale", "value": "C124733"},
             {"label": "Lingfield", "value": "C124734"},
             {"label": "Lingwood", "value": "C124735"},
             {"label": "Linthwaite", "value": "C124736"},
             {"label": "Linton", "value": "C124737"},
             {"label": "Linton upon Ouse", "value": "C124738"},
             {"label": "Liphook", "value": "C124739"},
             {"label": "Liskeard", "value": "C124740"},
             {"label": "Liss", "value": "C124741"},
             {"label": "Litherland", "value": "C124742"},
             {"label": "Little Amwell", "value": "C124743"},
             {"label": "Little Chalfont", "value": "C124744"},
             {"label": "Little Clacton", "value": "C124745"},
             {"label": "Little Dunmow", "value": "C124746"},
             {"label": "Little Eaton", "value": "C124747"},
             {"label": "Little Hallingbury", "value": "C124748"},
             {"label": "Little Hulton", "value": "C124749"},
             {"label": "Little Lever", "value": "C124750"},
             {"label": "Little Paxton", "value": "C124751"},
             {"label": "Little Weighton", "value": "C124752"},
             {"label": "Littleborough", "value": "C124753"},
             {"label": "Littlebourne", "value": "C124754"},
             {"label": "Littlehampton", "value": "C124755"},
             {"label": "Littleport", "value": "C124756"},
             {"label": "Liverpool", "value": "C124757"},
             {"label": "Liversedge", "value": "C124758"},
             {"label": "Locking", "value": "C124759"},
             {"label": "Lockington", "value": "C124760"},
             {"label": "Loddon", "value": "C124761"},
             {"label": "Lofthouse", "value": "C124762"},
             {"label": "Loftus", "value": "C124763"},
             {"label": "London", "value": "C124764"},
             {"label": "Long Ashton", "value": "C124765"},
             {"label": "Long Bennington", "value": "C124766"},
             {"label": "Long Buckby", "value": "C124767"},
             {"label": "Long Clawson", "value": "C124768"},
             {"label": "Long Crendon", "value": "C124769"},
             {"label": "Long Eaton", "value": "C124770"},
             {"label": "Long Itchington", "value": "C124771"},
             {"label": "Long Lawford", "value": "C124772"},
             {"label": "Long Melford", "value": "C124773"},
             {"label": "Long Stratton", "value": "C124774"},
             {"label": "Long Sutton", "value": "C124775"},
             {"label": "Long Whatton", "value": "C124776"},
             {"label": "Longdendale", "value": "C124777"},
             {"label": "Longfield", "value": "C124778"},
             {"label": "Longhope", "value": "C124779"},
             {"label": "Longhorsley", "value": "C124780"},
             {"label": "Longhoughton", "value": "C124781"},
             {"label": "Longridge", "value": "C124782"},
             {"label": "Longsight", "value": "C124783"},
             {"label": "Longstanton", "value": "C124784"},
             {"label": "Longton", "value": "C124785"},
             {"label": "Longtown", "value": "C124786"},
             {"label": "Longwick", "value": "C124787"},
             {"label": "Looe", "value": "C124788"},
             {"label": "Lostwithiel", "value": "C124789"},
             {"label": "Loughborough", "value": "C124790"},
             {"label": "Louth", "value": "C124791"},
             {"label": "Low Ackworth", "value": "C124792"},
             {"label": "Low Bradley", "value": "C124793"},
             {"label": "Low Etherley", "value": "C124794"},
             {"label": "Lowdham", "value": "C124795"},
             {"label": "Lower Brailes", "value": "C124796"},
             {"label": "Lower Broadheath", "value": "C124797"},
             {"label": "Lower Earley", "value": "C124798"},
             {"label": "Lower Halstow", "value": "C124799"},
             {"label": "Lower Kingswood", "value": "C124800"},
             {"label": "Lowestoft", "value": "C124801"},
             {"label": "Lowick", "value": "C124802"},
             {"label": "Luckington", "value": "C124803"},
             {"label": "Luddenden Foot", "value": "C124804"},
             {"label": "Ludgershall", "value": "C124805"},
             {"label": "Ludlow", "value": "C124806"},
             {"label": "Lugwardine", "value": "C124807"},
             {"label": "Lund", "value": "C124808"},
             {"label": "Luton", "value": "C124809"},
             {"label": "Lutterworth", "value": "C124810"},
             {"label": "Lydbrook", "value": "C124811"},
             {"label": "Lydd", "value": "C124812"},
             {"label": "Lydiard Millicent", "value": "C124813"},
             {"label": "Lydney", "value": "C124814"},
             {"label": "Lyme Regis", "value": "C124815"},
             {"label": "Lyminge", "value": "C124816"},
             {"label": "Lymington", "value": "C124817"},
             {"label": "Lymm", "value": "C124818"},
             {"label": "Lympne", "value": "C124819"},
             {"label": "Lympstone", "value": "C124820"},
             {"label": "Lyndhurst", "value": "C124821"},
             {"label": "Lyneham", "value": "C124822"},
             {"label": "Lynemouth", "value": "C124823"},
             {"label": "Lynton", "value": "C124824"},
             {"label": "Lytchett Matravers", "value": "C124825"},
             {"label": "Lytham St Annes", "value": "C124826"},
             {"label": "Mablethorpe", "value": "C124827"},
             {"label": "Macclesfield", "value": "C124828"},
             {"label": "Madeley", "value": "C124829"},
             {"label": "Maghull", "value": "C124830"},
             {"label": "Maiden Newton", "value": "C124831"},
             {"label": "Maidenbower", "value": "C124832"},
             {"label": "Maidenhead", "value": "C124833"},
             {"label": "Maidstone", "value": "C124834"},
             {"label": "Maldon", "value": "C124835"},
             {"label": "Malmesbury", "value": "C124836"},
             {"label": "Malpas", "value": "C124837"},
             {"label": "Maltby", "value": "C124838"},
             {"label": "Malton", "value": "C124839"},
             {"label": "Manby", "value": "C124840"},
             {"label": "Manchester", "value": "C124841"},
             {"label": "Manea", "value": "C124842"},
             {"label": "Mangotsfield", "value": "C124843"},
             {"label": "Manningtree", "value": "C124844"},
             {"label": "Mansfield", "value": "C124845"},
             {"label": "Mansfield Woodhouse", "value": "C124846"},
             {"label": "Manston", "value": "C124847"},
             {"label": "Manton", "value": "C124848"},
             {"label": "Maple Cross", "value": "C124849"},
             {"label": "Marazion", "value": "C124850"},
             {"label": "March", "value": "C124851"},
             {"label": "Marcham", "value": "C124852"},
             {"label": "Marchwood", "value": "C124853"},
             {"label": "Marden", "value": "C124854"},
             {"label": "Margate", "value": "C124855"},
             {"label": "Market Bosworth", "value": "C124856"},
             {"label": "Market Deeping", "value": "C124857"},
             {"label": "Market Drayton", "value": "C124858"},
             {"label": "Market Harborough", "value": "C124859"},
             {"label": "Market Lavington", "value": "C124860"},
             {"label": "Market Overton", "value": "C124861"},
             {"label": "Market Rasen", "value": "C124862"},
             {"label": "Market Warsop", "value": "C124863"},
             {"label": "Market Weighton", "value": "C124864"},
             {"label": "Markfield", "value": "C124865"},
             {"label": "Marks Tey", "value": "C124866"},
             {"label": "Markyate", "value": "C124867"},
             {"label": "Marlborough", "value": "C124868"},
             {"label": "Marldon", "value": "C124869"},
             {"label": "Marlow", "value": "C124870"},
             {"label": "Marnhull", "value": "C124871"},
             {"label": "Marple", "value": "C124872"},
             {"label": "Marr", "value": "C124873"},
             {"label": "Marsden", "value": "C124874"},
             {"label": "Marshfield", "value": "C124875"},
             {"label": "Marske-by-the-Sea", "value": "C124876"},
             {"label": "Marston Moretaine", "value": "C124877"},
             {"label": "Martham", "value": "C124878"},
             {"label": "Martock", "value": "C124879"},
             {"label": "Maryport", "value": "C124880"},
             {"label": "Masham", "value": "C124881"},
             {"label": "Matlock", "value": "C124882"},
             {"label": "Mattishall", "value": "C124883"},
             {"label": "Maulden", "value": "C124884"},
             {"label": "Mayfield", "value": "C124885"},
             {"label": "Mayland", "value": "C124886"},
             {"label": "Measham", "value": "C124887"},
             {"label": "Medway", "value": "C124888"},
             {"label": "Melbourn", "value": "C124889"},
             {"label": "Melbourne", "value": "C124890"},
             {"label": "Meldreth", "value": "C124891"},
             {"label": "Melksham", "value": "C124892"},
             {"label": "Meltham", "value": "C124893"},
             {"label": "Meltham Mills", "value": "C124894"},
             {"label": "Melton Mowbray", "value": "C124895"},
             {"label": "Mendip", "value": "C124896"},
             {"label": "Menston", "value": "C124897"},
             {"label": "Meopham", "value": "C124898"},
             {"label": "Meppershall", "value": "C124899"},
             {"label": "Mere", "value": "C124900"},
             {"label": "Meriden", "value": "C124901"},
             {"label": "Merriott", "value": "C124902"},
             {"label": "Messingham", "value": "C124903"},
             {"label": "Metheringham", "value": "C124904"},
             {"label": "Methley", "value": "C124905"},
             {"label": "Metropolitan Borough of Wirral", "value": "C124906"},
             {"label": "Mevagissey", "value": "C124907"},
             {"label": "Mexborough", "value": "C124908"},
             {"label": "Mickle Trafford", "value": "C124909"},
             {"label": "Mickleton", "value": "C124910"},
             {"label": "Middle Rasen", "value": "C124911"},
             {"label": "Middle Winterslow", "value": "C124912"},
             {"label": "Middlesbrough", "value": "C124913"},
             {"label": "Middlestown", "value": "C124914"},
             {"label": "Middleton", "value": "C124915"},
             {"label": "Middlewich", "value": "C124916"},
             {"label": "Midhurst", "value": "C124917"},
             {"label": "Midsomer Norton", "value": "C124918"},
             {"label": "Milborne Port", "value": "C124919"},
             {"label": "Milborne St Andrew", "value": "C124920"},
             {"label": "Mildenhall", "value": "C124921"},
             {"label": "Milford", "value": "C124922"},
             {"label": "Milford on Sea", "value": "C124923"},
             {"label": "Millbrook", "value": "C124924"},
             {"label": "Millom", "value": "C124925"},
             {"label": "Milnrow", "value": "C124926"},
             {"label": "Milnthorpe", "value": "C124927"},
             {"label": "Milton Keynes", "value": "C124928"},
             {"label": "Milverton", "value": "C124929"},
             {"label": "Minchinhampton", "value": "C124930"},
             {"label": "Minehead", "value": "C124931"},
             {"label": "Minety", "value": "C124932"},
             {"label": "Minster Lovell", "value": "C124933"},
             {"label": "Minsterley", "value": "C124934"},
             {"label": "Mirfield", "value": "C124935"},
             {"label": "Misterton", "value": "C124936"},
             {"label": "Mistley", "value": "C124937"},
             {"label": "Mitcham", "value": "C124938"},
             {"label": "Mitcheldean", "value": "C124939"},
             {"label": "Mobberley", "value": "C124940"},
             {"label": "Modbury", "value": "C124941"},
             {"label": "Moira", "value": "C124942"},
             {"label": "Mollington", "value": "C124943"},
             {"label": "Monk Fryston", "value": "C124944"},
             {"label": "Morchard Bishop", "value": "C124945"},
             {"label": "Morcott", "value": "C124946"},
             {"label": "Morden", "value": "C124947"},
             {"label": "Morecambe", "value": "C124948"},
             {"label": "Moreton", "value": "C124949"},
             {"label": "Moreton in Marsh", "value": "C124950"},
             {"label": "Moretonhampstead", "value": "C124951"},
             {"label": "Morley", "value": "C124952"},
             {"label": "Morpeth", "value": "C124953"},
             {"label": "Moss", "value": "C124954"},
             {"label": "Mossley", "value": "C124955"},
             {"label": "Motcombe", "value": "C124956"},
             {"label": "Mouldsworth", "value": "C124957"},
             {"label": "Moulton Chapel", "value": "C124958"},
             {"label": "Mount Hawke", "value": "C124959"},
             {"label": "Mountsorrel", "value": "C124960"},
             {"label": "Much Hadham", "value": "C124961"},
             {"label": "Much Wenlock", "value": "C124962"},
             {"label": "Mulbarton", "value": "C124963"},
             {"label": "Mullion", "value": "C124964"},
             {"label": "Mundesley", "value": "C124965"},
             {"label": "Mundford", "value": "C124966"},
             {"label": "Murton", "value": "C124967"},
             {"label": "Mylor Bridge", "value": "C124968"},
             {"label": "Mytholmroyd", "value": "C124969"},
             {"label": "Nafferton", "value": "C124970"},
             {"label": "Nailsea", "value": "C124971"},
             {"label": "Nailsworth", "value": "C124972"},
             {"label": "Nanpean", "value": "C124973"},
             {"label": "Nantwich", "value": "C124974"},
             {"label": "Narborough", "value": "C124975"},
             {"label": "Navenby", "value": "C124976"},
             {"label": "Necton", "value": "C124977"},
             {"label": "Needham Market", "value": "C124978"},
             {"label": "Needingworth", "value": "C124979"},
             {"label": "Nelson", "value": "C124980"},
             {"label": "Neston", "value": "C124981"},
             {"label": "Nether Heyford", "value": "C124982"},
             {"label": "Nether Poppleton", "value": "C124983"},
             {"label": "Nether Stowey", "value": "C124984"},
             {"label": "Netheravon", "value": "C124985"},
             {"label": "Netherton", "value": "C124986"},
             {"label": "Netley", "value": "C124987"},
             {"label": "Nettleham", "value": "C124988"},
             {"label": "New Alresford", "value": "C124989"},
             {"label": "New Basford", "value": "C124990"},
             {"label": "New Ferry", "value": "C124991"},
             {"label": "New Malden", "value": "C124992"},
             {"label": "New Marske", "value": "C124993"},
             {"label": "New Mills", "value": "C124994"},
             {"label": "New Milton", "value": "C124995"},
             {"label": "New Romney", "value": "C124996"},
             {"label": "Newark on Trent", "value": "C124997"},
             {"label": "Newbiggin-by-the-Sea", "value": "C124998"},
             {"label": "Newbold Verdon", "value": "C124999"},
             {"label": "Newburn", "value": "C125000"},
             {"label": "Newbury", "value": "C125001"},
             {"label": "Newcastle under Lyme", "value": "C125002"},
             {"label": "Newcastle upon Tyne", "value": "C125003"},
             {"label": "Newchurch", "value": "C125004"},
             {"label": "Newent", "value": "C125005"},
             {"label": "Newhaven", "value": "C125006"},
             {"label": "Newick", "value": "C125007"},
             {"label": "Newington", "value": "C125008"},
             {"label": "Newmarket", "value": "C125009"},
             {"label": "Newport", "value": "C125010"},
             {"label": "Newport Pagnell", "value": "C125011"},
             {"label": "Newquay", "value": "C125012"},
             {"label": "Newton Abbot", "value": "C125013"},
             {"label": "Newton Aycliffe", "value": "C125014"},
             {"label": "Newton Ferrers", "value": "C125015"},
             {"label": "Newton Longville", "value": "C125016"},
             {"label": "Newton Poppleford", "value": "C125017"},
             {"label": "Newton-le-Willows", "value": "C125018"},
             {"label": "Ninfield", "value": "C125019"},
             {"label": "Niton", "value": "C125020"},
             {"label": "Norfolk", "value": "C125021"},
             {"label": "Normandy", "value": "C125022"},
             {"label": "North Baddesley", "value": "C125023"},
             {"label": "North Cave", "value": "C125024"},
             {"label": "North Collingham", "value": "C125025"},
             {"label": "North Duffield", "value": "C125026"},
             {"label": "North East Lincolnshire", "value": "C125027"},
             {"label": "North Elmham", "value": "C125028"},
             {"label": "North Ferriby", "value": "C125029"},
             {"label": "North Leigh", "value": "C125030"},
             {"label": "North Lincolnshire", "value": "C125031"},
             {"label": "North Luffenham", "value": "C125032"},
             {"label": "North Newbald", "value": "C125033"},
             {"label": "North Petherton", "value": "C125034"},
             {"label": "North Shields", "value": "C125035"},
             {"label": "North Somercotes", "value": "C125036"},
             {"label": "North Somerset", "value": "C125037"},
             {"label": "North Sunderland", "value": "C125038"},
             {"label": "North Tawton", "value": "C125039"},
             {"label": "North Thoresby", "value": "C125040"},
             {"label": "North Walsham", "value": "C125041"},
             {"label": "North Yorkshire", "value": "C125042"},
             {"label": "Northallerton", "value": "C125043"},
             {"label": "Northam", "value": "C125044"},
             {"label": "Northampton", "value": "C125045"},
             {"label": "Northamptonshire", "value": "C125046"},
             {"label": "Northborough", "value": "C125047"},
             {"label": "Northiam", "value": "C125048"},
             {"label": "Northleach", "value": "C125049"},
             {"label": "Northolt", "value": "C125050"},
             {"label": "Northorpe", "value": "C125051"},
             {"label": "Northumberland", "value": "C125052"},
             {"label": "Northwich", "value": "C125053"},
             {"label": "Norton", "value": "C125054"},
             {"label": "Norton Canes", "value": "C125055"},
             {"label": "Norwich", "value": "C125056"},
             {"label": "Nottingham", "value": "C125057"},
             {"label": "Nottinghamshire", "value": "C125058"},
             {"label": "Notton", "value": "C125059"},
             {"label": "Nuneaton", "value": "C125060"},
             {"label": "Oadby", "value": "C125061"},
             {"label": "Oakengates", "value": "C125062"},
             {"label": "Oakham", "value": "C125063"},
             {"label": "Oakington", "value": "C125064"},
             {"label": "Oakley", "value": "C125065"},
             {"label": "Odiham", "value": "C125066"},
             {"label": "Okehampton", "value": "C125067"},
             {"label": "Old Basing", "value": "C125068"},
             {"label": "Old Harlow", "value": "C125069"},
             {"label": "Old Leake", "value": "C125070"},
             {"label": "Old Windsor", "value": "C125071"},
             {"label": "Oldbury", "value": "C125072"},
             {"label": "Oldham", "value": "C125073"},
             {"label": "Olney", "value": "C125074"},
             {"label": "Olveston", "value": "C125075"},
             {"label": "Orleton", "value": "C125076"},
             {"label": "Ormesby St Margaret", "value": "C125077"},
             {"label": "Ormskirk", "value": "C125078"},
             {"label": "Orpington", "value": "C125079"},
             {"label": "Orsett", "value": "C125080"},
             {"label": "Orwell", "value": "C125081"},
             {"label": "Ossett", "value": "C125082"},
             {"label": "Oswestry", "value": "C125083"},
             {"label": "Otford", "value": "C125084"},
             {"label": "Otley", "value": "C125085"},
             {"label": "Otterburn", "value": "C125086"},
             {"label": "Ottershaw", "value": "C125087"},
             {"label": "Ottery St Mary", "value": "C125088"},
             {"label": "Ottringham", "value": "C125089"},
             {"label": "Oughtibridge", "value": "C125090"},
             {"label": "Oundle", "value": "C125091"},
             {"label": "Outwell", "value": "C125092"},
             {"label": "Over", "value": "C125093"},
             {"label": "Overcombe", "value": "C125094"},
             {"label": "Overstrand", "value": "C125095"},
             {"label": "Overton", "value": "C125096"},
             {"label": "Owston Ferry", "value": "C125097"},
             {"label": "Oxenhope", "value": "C125098"},
             {"label": "Oxford", "value": "C125099"},
             {"label": "Oxfordshire", "value": "C125100"},
             {"label": "Oxted", "value": "C125101"},
             {"label": "Paddock Wood", "value": "C125102"},
             {"label": "Padiham", "value": "C125103"},
             {"label": "Padstow", "value": "C125104"},
             {"label": "Paignton", "value": "C125105"},
             {"label": "Painswick", "value": "C125106"},
             {"label": "Pangbourne", "value": "C125107"},
             {"label": "Pannal", "value": "C125108"},
             {"label": "Pant", "value": "C125109"},
             {"label": "Papworth Everard", "value": "C125110"},
             {"label": "Par", "value": "C125111"},
             {"label": "Parbold", "value": "C125112"},
             {"label": "Parkstone", "value": "C125113"},
             {"label": "Partington", "value": "C125114"},
             {"label": "Partridge Green", "value": "C125115"},
             {"label": "Pateley Bridge", "value": "C125116"},
             {"label": "Patrington", "value": "C125117"},
             {"label": "Pattingham", "value": "C125118"},
             {"label": "Paulton", "value": "C125119"},
             {"label": "Peacehaven", "value": "C125120"},
             {"label": "Peasedown Saint John", "value": "C125121"},
             {"label": "Peaslake", "value": "C125122"},
             {"label": "Peckham", "value": "C125123"},
             {"label": "Pegswood", "value": "C125124"},
             {"label": "Pelsall", "value": "C125125"},
             {"label": "Pelton", "value": "C125126"},
             {"label": "Pembury", "value": "C125127"},
             {"label": "Penistone", "value": "C125128"},
             {"label": "Penkridge", "value": "C125129"},
             {"label": "Penrith", "value": "C125130"},
             {"label": "Penryn", "value": "C125131"},
             {"label": "Pensilva", "value": "C125132"},
             {"label": "Penzance", "value": "C125133"},
             {"label": "Perranporth", "value": "C125134"},
             {"label": "Perranwell", "value": "C125135"},
             {"label": "Pershore", "value": "C125136"},
             {"label": "Peterborough", "value": "C125137"},
             {"label": "Peterlee", "value": "C125138"},
             {"label": "Petersfield", "value": "C125139"},
             {"label": "Petworth", "value": "C125140"},
             {"label": "Pevensey", "value": "C125141"},
             {"label": "Pevensey Bay", "value": "C125142"},
             {"label": "Pewsey", "value": "C125143"},
             {"label": "Pickering", "value": "C125144"},
             {"label": "Pilning", "value": "C125145"},
             {"label": "Pilsley", "value": "C125146"},
             {"label": "Pimperne", "value": "C125147"},
             {"label": "Pinchbeck", "value": "C125148"},
             {"label": "Pinner", "value": "C125149"},
             {"label": "Pinxton", "value": "C125150"},
             {"label": "Pirton", "value": "C125151"},
             {"label": "Pitsea", "value": "C125152"},
             {"label": "Pitstone", "value": "C125153"},
             {"label": "Plumpton Green", "value": "C125154"},
             {"label": "Plymouth", "value": "C125155"},
             {"label": "Plympton", "value": "C125156"},
             {"label": "Plymstock", "value": "C125157"},
             {"label": "Pocklington", "value": "C125158"},
             {"label": "Podington", "value": "C125159"},
             {"label": "Polesworth", "value": "C125160"},
             {"label": "Pollington", "value": "C125161"},
             {"label": "Polperro", "value": "C125162"},
             {"label": "Polzeath", "value": "C125163"},
             {"label": "Pontefract", "value": "C125164"},
             {"label": "Ponteland", "value": "C125165"},
             {"label": "Pontesbury", "value": "C125166"},
             {"label": "Pool", "value": "C125167"},
             {"label": "Poole", "value": "C125168"},
             {"label": "Poringland", "value": "C125169"},
             {"label": "Porlock", "value": "C125170"},
             {"label": "Porthleven", "value": "C125171"},
             {"label": "Portishead", "value": "C125172"},
             {"label": "Portland", "value": "C125173"},
             {"label": "Portscatho", "value": "C125174"},
             {"label": "Portslade", "value": "C125175"},
             {"label": "Portsmouth", "value": "C125176"},
             {"label": "Potterne", "value": "C125177"},
             {"label": "Potters Bar", "value": "C125178"},
             {"label": "Potterspury", "value": "C125179"},
             {"label": "Potton", "value": "C125180"},
             {"label": "Poulton-le-Fylde", "value": "C125181"},
             {"label": "Poynton", "value": "C125182"},
             {"label": "Prees", "value": "C125183"},
             {"label": "Preesall", "value": "C125184"},
             {"label": "Prenton", "value": "C125185"},
             {"label": "Prescot", "value": "C125186"},
             {"label": "Prestbury", "value": "C125187"},
             {"label": "Preston", "value": "C125188"},
             {"label": "Prestwich", "value": "C125189"},
             {"label": "Princes Risborough", "value": "C125190"},
             {"label": "Princetown", "value": "C125191"},
             {"label": "Priston", "value": "C125192"},
             {"label": "Probus", "value": "C125193"},
             {"label": "Prudhoe", "value": "C125194"},
             {"label": "Publow", "value": "C125195"},
             {"label": "Puckeridge", "value": "C125196"},
             {"label": "Pucklechurch", "value": "C125197"},
             {"label": "Puddletown", "value": "C125198"},
             {"label": "Pudsey", "value": "C125199"},
             {"label": "Pulborough", "value": "C125200"},
             {"label": "Pulloxhill", "value": "C125201"},
             {"label": "Purfleet", "value": "C125202"},
             {"label": "Puriton", "value": "C125203"},
             {"label": "Purley", "value": "C125204"},
             {"label": "Purton", "value": "C125205"},
             {"label": "Queenborough", "value": "C125206"},
             {"label": "Queensbury", "value": "C125207"},
             {"label": "Queniborough", "value": "C125208"},
             {"label": "Quorndon", "value": "C125209"},
             {"label": "Rackheath", "value": "C125210"},
             {"label": "Radcliffe", "value": "C125211"},
             {"label": "Radcliffe on Trent", "value": "C125212"},
             {"label": "Radlett", "value": "C125213"},
             {"label": "Radley", "value": "C125214"},
             {"label": "Radstock", "value": "C125215"},
             {"label": "Rainford", "value": "C125216"},
             {"label": "Rainham", "value": "C125217"},
             {"label": "Rainworth", "value": "C125218"},
             {"label": "Ramsbottom", "value": "C125219"},
             {"label": "Ramsbury", "value": "C125220"},
             {"label": "Ramsey", "value": "C125221"},
             {"label": "Ramsgate", "value": "C125222"},
             {"label": "Ranskill", "value": "C125223"},
             {"label": "Rastrick", "value": "C125224"},
             {"label": "Ratby", "value": "C125225"},
             {"label": "Raunds", "value": "C125226"},
             {"label": "Ravenshead", "value": "C125227"},
             {"label": "Ravenstone", "value": "C125228"},
             {"label": "Rawcliffe", "value": "C125229"},
             {"label": "Rawmarsh", "value": "C125230"},
             {"label": "Rawtenstall", "value": "C125231"},
             {"label": "Rayleigh", "value": "C125232"},
             {"label": "Rayne", "value": "C125233"},
             {"label": "Reading", "value": "C125234"},
             {"label": "Redbourn", "value": "C125235"},
             {"label": "Redbourne", "value": "C125236"},
             {"label": "Redcar", "value": "C125237"},
             {"label": "Redcar and Cleveland", "value": "C125238"},
             {"label": "Redditch", "value": "C125239"},
             {"label": "Redhill", "value": "C125240"},
             {"label": "Redlynch", "value": "C125241"},
             {"label": "Redruth", "value": "C125242"},
             {"label": "Reedham", "value": "C125243"},
             {"label": "Reepham", "value": "C125244"},
             {"label": "Reigate", "value": "C125245"},
             {"label": "Rendlesham", "value": "C125246"},
             {"label": "Repton", "value": "C125247"},
             {"label": "Retford", "value": "C125248"},
             {"label": "Riccall", "value": "C125249"},
             {"label": "Richmond", "value": "C125250"},
             {"label": "Rickinghall", "value": "C125251"},
             {"label": "Rickmansworth", "value": "C125252"},
             {"label": "Ridgmont", "value": "C125253"},
             {"label": "Rillington", "value": "C125254"},
             {"label": "Ringmer", "value": "C125255"},
             {"label": "Ringstead", "value": "C125256"},
             {"label": "Ringwood", "value": "C125257"},
             {"label": "Ripley", "value": "C125258"},
             {"label": "Ripon", "value": "C125259"},
             {"label": "Ripponden", "value": "C125260"},
             {"label": "Riseley", "value": "C125261"},
             {"label": "Rishton", "value": "C125262"},
             {"label": "Roade", "value": "C125263"},
             {"label": "Robertsbridge", "value": "C125264"},
             {"label": "Rochdale", "value": "C125265"},
             {"label": "Roche", "value": "C125266"},
             {"label": "Rochester", "value": "C125267"},
             {"label": "Rochford", "value": "C125268"},
             {"label": "Rock", "value": "C125269"},
             {"label": "Rode", "value": "C125270"},
             {"label": "Rode Heath", "value": "C125271"},
             {"label": "Romford", "value": "C125272"},
             {"label": "Romney Marsh", "value": "C125273"},
             {"label": "Romsey", "value": "C125274"},
             {"label": "Romsley", "value": "C125275"},
             {"label": "Roos", "value": "C125276"},
             {"label": "Ross on Wye", "value": "C125277"},
             {"label": "Rossendale", "value": "C125278"},
             {"label": "Rothbury", "value": "C125279"},
             {"label": "Rotherfield Peppard", "value": "C125280"},
             {"label": "Rotherham", "value": "C125281"},
             {"label": "Rothley", "value": "C125282"},
             {"label": "Rothwell", "value": "C125283"},
             {"label": "Rottingdean", "value": "C125284"},
             {"label": "Rowde", "value": "C125285"},
             {"label": "Rowhedge", "value": "C125286"},
             {"label": "Rowlands Gill", "value": "C125287"},
             {"label": "Rowley Regis", "value": "C125288"},
             {"label": "Roxton", "value": "C125289"},
             {"label": "Royal Borough of Windsor and Maidenhead",
              "value": "C125290"},
             {"label": "Royal Leamington Spa", "value": "C125291"},
             {"label": "Royal Tunbridge Wells", "value": "C125292"},
             {"label": "Royal Wootton Bassett", "value": "C125293"},
             {"label": "Roydon", "value": "C125294"},
             {"label": "Royston", "value": "C125295"},
             {"label": "Royton", "value": "C125296"},
             {"label": "Ruardean", "value": "C125297"},
             {"label": "Ruddington", "value": "C125298"},
             {"label": "Rudgwick", "value": "C125299"},
             {"label": "Rufford", "value": "C125300"},
             {"label": "Rugby", "value": "C125301"},
             {"label": "Rugeley", "value": "C125302"},
             {"label": "Ruislip", "value": "C125303"},
             {"label": "Runcorn", "value": "C125304"},
             {"label": "Rushden", "value": "C125305"},
             {"label": "Ruskington", "value": "C125306"},
             {"label": "Rusthall", "value": "C125307"},
             {"label": "Rustington", "value": "C125308"},
             {"label": "Ruyton-XI-Towns", "value": "C125309"},
             {"label": "Ryde", "value": "C125310"},
             {"label": "Rye", "value": "C125311"},
             {"label": "Ryhall", "value": "C125312"},
             {"label": "Ryhill", "value": "C125313"},
             {"label": "Ryhope", "value": "C125314"},
             {"label": "Ryton", "value": "C125315"},
             {"label": "Ryton on Dunsmore", "value": "C125316"},
             {"label": "Sabden", "value": "C125317"},
             {"label": "Sacriston", "value": "C125318"},
             {"label": "Saffron Walden", "value": "C125319"},
             {"label": "Saint Agnes", "value": "C125320"},
             {"label": "Saint Bees", "value": "C125321"},
             {"label": "Saint Columb Major", "value": "C125322"},
             {"label": "Saint Dennis", "value": "C125323"},
             {"label": "Saint Leonards-on-Sea", "value": "C125324"},
             {"label": "Saint Neots", "value": "C125325"},
             {"label": "Saint Osyth", "value": "C125326"},
             {"label": "Saint Peters", "value": "C125327"},
             {"label": "Saint Stephen", "value": "C125328"},
             {"label": "Salcombe", "value": "C125329"},
             {"label": "Sale", "value": "C125330"},
             {"label": "Salford", "value": "C125331"},
             {"label": "Salfords", "value": "C125332"},
             {"label": "Salisbury", "value": "C125333"},
             {"label": "Saltash", "value": "C125334"},
             {"label": "Saltburn-by-the-Sea", "value": "C125335"},
             {"label": "Saltford", "value": "C125336"},
             {"label": "Sampford Peverell", "value": "C125337"},
             {"label": "Sandbach", "value": "C125338"},
             {"label": "Sandford", "value": "C125339"},
             {"label": "Sandown", "value": "C125340"},
             {"label": "Sandwell", "value": "C125341"},
             {"label": "Sandwich", "value": "C125342"},
             {"label": "Sandy", "value": "C125343"},
             {"label": "Sapcote", "value": "C125344"},
             {"label": "Saughall", "value": "C125345"},
             {"label": "Sawbridgeworth", "value": "C125346"},
             {"label": "Sawston", "value": "C125347"},
             {"label": "Sawtry", "value": "C125348"},
             {"label": "Saxilby", "value": "C125349"},
             {"label": "Saxmundham", "value": "C125350"},
             {"label": "Scalby", "value": "C125351"},
             {"label": "Scarborough", "value": "C125352"},
             {"label": "Scarcroft", "value": "C125353"},
             {"label": "Scawby", "value": "C125354"},
             {"label": "Scholes", "value": "C125355"},
             {"label": "Scole", "value": "C125356"},
             {"label": "Scorton", "value": "C125357"},
             {"label": "Scotby", "value": "C125358"},
             {"label": "Scotter", "value": "C125359"},
             {"label": "Scunthorpe", "value": "C125360"},
             {"label": "Seaford", "value": "C125361"},
             {"label": "Seaham", "value": "C125362"},
             {"label": "Seahouses", "value": "C125363"},
             {"label": "Seascale", "value": "C125364"},
             {"label": "Seaton", "value": "C125365"},
             {"label": "Seaton Delaval", "value": "C125366"},
             {"label": "Seaview", "value": "C125367"},
             {"label": "Sedbergh", "value": "C125368"},
             {"label": "Sedgefield", "value": "C125369"},
             {"label": "Seend", "value": "C125370"},
             {"label": "Seer Green", "value": "C125371"},
             {"label": "Sefton", "value": "C125372"},
             {"label": "Seghill", "value": "C125373"},
             {"label": "Selby", "value": "C125374"},
             {"label": "Selsey", "value": "C125375"},
             {"label": "Send", "value": "C125376"},
             {"label": "Settle", "value": "C125377"},
             {"label": "Sevenoaks", "value": "C125378"},
             {"label": "Severn Beach", "value": "C125379"},
             {"label": "Shadoxhurst", "value": "C125380"},
             {"label": "Shadwell", "value": "C125381"},
             {"label": "Shaftesbury", "value": "C125382"},
             {"label": "Shalbourne", "value": "C125383"},
             {"label": "Shalfleet", "value": "C125384"},
             {"label": "Shanklin", "value": "C125385"},
             {"label": "Shap", "value": "C125386"},
             {"label": "Sharlston", "value": "C125387"},
             {"label": "Sharnbrook", "value": "C125388"},
             {"label": "Sharpness", "value": "C125389"},
             {"label": "Shaw", "value": "C125390"},
             {"label": "Shawbury", "value": "C125391"},
             {"label": "Sheerness", "value": "C125392"},
             {"label": "Sheffield", "value": "C125393"},
             {"label": "Shefford", "value": "C125394"},
             {"label": "Shelley", "value": "C125395"},
             {"label": "Shenley", "value": "C125396"},
             {"label": "Shenstone", "value": "C125397"},
             {"label": "Shepherdswell", "value": "C125398"},
             {"label": "Shepley", "value": "C125399"},
             {"label": "Shepperton", "value": "C125400"},
             {"label": "Shepshed", "value": "C125401"},
             {"label": "Shepton Mallet", "value": "C125402"},
             {"label": "Sherborne", "value": "C125403"},
             {"label": "Sherborne St John", "value": "C125404"},
             {"label": "Sherburn", "value": "C125405"},
             {"label": "Sherburn Hill", "value": "C125406"},
             {"label": "Sherburn in Elmet", "value": "C125407"},
             {"label": "Sheriff Hutton", "value": "C125408"},
             {"label": "Sheringham", "value": "C125409"},
             {"label": "Sherington", "value": "C125410"},
             {"label": "Sherston", "value": "C125411"},
             {"label": "Shevington", "value": "C125412"},
             {"label": "Shifnal", "value": "C125413"},
             {"label": "Shilbottle", "value": "C125414"},
             {"label": "Shildon", "value": "C125415"},
             {"label": "Shillingstone", "value": "C125416"},
             {"label": "Shillington", "value": "C125417"},
             {"label": "Shipdham", "value": "C125418"},
             {"label": "Shipham", "value": "C125419"},
             {"label": "Shipley", "value": "C125420"},
             {"label": "Shipston on Stour", "value": "C125421"},
             {"label": "Shipton under Wychwood", "value": "C125422"},
             {"label": "Shirebrook", "value": "C125423"},
             {"label": "Shiremoor", "value": "C125424"},
             {"label": "Shirland", "value": "C125425"},
             {"label": "Shirley", "value": "C125426"},
             {"label": "Shoreham-by-Sea", "value": "C125427"},
             {"label": "Shortlands", "value": "C125428"},
             {"label": "Shotley Gate", "value": "C125429"},
             {"label": "Shrewsbury", "value": "C125430"},
             {"label": "Shrewton", "value": "C125431"},
             {"label": "Shrivenham", "value": "C125432"},
             {"label": "Shropshire", "value": "C125433"},
             {"label": "Shurdington", "value": "C125434"},
             {"label": "Sible Hedingham", "value": "C125435"},
             {"label": "Sibsey", "value": "C125436"},
             {"label": "Sidcup", "value": "C125437"},
             {"label": "Sidmouth", "value": "C125438"},
             {"label": "Sileby", "value": "C125439"},
             {"label": "Silkstone", "value": "C125440"},
             {"label": "Silloth", "value": "C125441"},
             {"label": "Silsden", "value": "C125442"},
             {"label": "Silsoe", "value": "C125443"},
             {"label": "Silver End", "value": "C125444"},
             {"label": "Silverdale", "value": "C125445"},
             {"label": "Silverstone", "value": "C125446"},
             {"label": "Silverton", "value": "C125447"},
             {"label": "Sinfin", "value": "C125448"},
             {"label": "Sissinghurst", "value": "C125449"},
             {"label": "Sittingbourne", "value": "C125450"},
             {"label": "Skegness", "value": "C125451"},
             {"label": "Skellingthorpe", "value": "C125452"},
             {"label": "Skelmersdale", "value": "C125453"},
             {"label": "Skelton", "value": "C125454"},
             {"label": "Skidby", "value": "C125455"},
             {"label": "Skinningrove", "value": "C125456"},
             {"label": "Skipsea", "value": "C125457"},
             {"label": "Skipton", "value": "C125458"},
             {"label": "Slaley", "value": "C125459"},
             {"label": "Sleaford", "value": "C125460"},
             {"label": "Sleights", "value": "C125461"},
             {"label": "Slinfold", "value": "C125462"},
             {"label": "Slough", "value": "C125463"},
             {"label": "Smethwick", "value": "C125464"},
             {"label": "Snaith", "value": "C125465"},
             {"label": "Snettisham", "value": "C125466"},
             {"label": "Snodland", "value": "C125467"},
             {"label": "Soham", "value": "C125468"},
             {"label": "Solihull", "value": "C125469"},
             {"label": "Somerset", "value": "C125470"},
             {"label": "Somersham", "value": "C125471"},
             {"label": "Somerton", "value": "C125472"},
             {"label": "Sonning Common", "value": "C125473"},
             {"label": "South Benfleet", "value": "C125474"},
             {"label": "South Brent", "value": "C125475"},
             {"label": "South Cave", "value": "C125476"},
             {"label": "South Cerney", "value": "C125477"},
             {"label": "South Chailey", "value": "C125478"},
             {"label": "South Collingham", "value": "C125479"},
             {"label": "South Croydon", "value": "C125480"},
             {"label": "South Elmsall", "value": "C125481"},
             {"label": "South Gloucestershire", "value": "C125482"},
             {"label": "South Harting", "value": "C125483"},
             {"label": "South Hayling", "value": "C125484"},
             {"label": "South Hetton", "value": "C125485"},
             {"label": "South Littleton", "value": "C125486"},
             {"label": "South Luffenham", "value": "C125487"},
             {"label": "South Milford", "value": "C125488"},
             {"label": "South Molton", "value": "C125489"},
             {"label": "South Nutfield", "value": "C125490"},
             {"label": "South Ockendon", "value": "C125491"},
             {"label": "South Petherton", "value": "C125492"},
             {"label": "South Shields", "value": "C125493"},
             {"label": "South Tyneside", "value": "C125494"},
             {"label": "South Wingfield", "value": "C125495"},
             {"label": "South Witham", "value": "C125496"},
             {"label": "Southall", "value": "C125497"},
             {"label": "Southam", "value": "C125498"},
             {"label": "Southampton", "value": "C125499"},
             {"label": "Southchurch Village", "value": "C125500"},
             {"label": "Southend-on-Sea", "value": "C125501"},
             {"label": "Southery", "value": "C125502"},
             {"label": "Southminster", "value": "C125503"},
             {"label": "Southowram", "value": "C125504"},
             {"label": "Southport", "value": "C125505"},
             {"label": "Southsea", "value": "C125506"},
             {"label": "Southwater", "value": "C125507"},
             {"label": "Southwell", "value": "C125508"},
             {"label": "Southwick", "value": "C125509"},
             {"label": "Southwold", "value": "C125510"},
             {"label": "Sowerby Bridge", "value": "C125511"},
             {"label": "Spalding", "value": "C125512"},
             {"label": "Speldhurst", "value": "C125513"},
             {"label": "Spennymoor", "value": "C125514"},
             {"label": "Spilsby", "value": "C125515"},
             {"label": "Spixworth", "value": "C125516"},
             {"label": "Spofforth", "value": "C125517"},
             {"label": "Spratton", "value": "C125518"},
             {"label": "Sproatley", "value": "C125519"},
             {"label": "St Albans", "value": "C125520"},
             {"label": "St Austell", "value": "C125521"},
             {"label": "St Helens", "value": "C125522"},
             {"label": "St Ives", "value": "C125523"},
             {"label": "St Just", "value": "C125524"},
             {"label": "St Leonards", "value": "C125525"},
             {"label": "St Marys", "value": "C125526"},
             {"label": "St Marys Bay", "value": "C125527"},
             {"label": "St. Day", "value": "C125528"},
             {"label": "St. Helens", "value": "C125529"},
             {"label": "Stafford", "value": "C125530"},
             {"label": "Staffordshire", "value": "C125531"},
             {"label": "Stagsden", "value": "C125532"},
             {"label": "Stainburn", "value": "C125533"},
             {"label": "Staindrop", "value": "C125534"},
             {"label": "Staines", "value": "C125535"},
             {"label": "Stainforth", "value": "C125536"},
             {"label": "Stakeford", "value": "C125537"},
             {"label": "Stalbridge", "value": "C125538"},
             {"label": "Stalham", "value": "C125539"},
             {"label": "Stallingborough", "value": "C125540"},
             {"label": "Stalybridge", "value": "C125541"},
             {"label": "Stamford", "value": "C125542"},
             {"label": "Stamford Bridge", "value": "C125543"},
             {"label": "Stanbridge", "value": "C125544"},
             {"label": "Standlake", "value": "C125545"},
             {"label": "Standon", "value": "C125546"},
             {"label": "Stanford in the Vale", "value": "C125547"},
             {"label": "Stanford-le-Hope", "value": "C125548"},
             {"label": "Stanhope", "value": "C125549"},
             {"label": "Stanley", "value": "C125550"},
             {"label": "Stanmore", "value": "C125551"},
             {"label": "Stannington", "value": "C125552"},
             {"label": "Stansted Mountfitchet", "value": "C125553"},
             {"label": "Stanwick", "value": "C125554"},
             {"label": "Staplehurst", "value": "C125555"},
             {"label": "Starcross", "value": "C125556"},
             {"label": "Startforth", "value": "C125557"},
             {"label": "Staveley", "value": "C125558"},
             {"label": "Steeple Bumpstead", "value": "C125559"},
             {"label": "Steeple Claydon", "value": "C125560"},
             {"label": "Steeton", "value": "C125561"},
             {"label": "Stevenage", "value": "C125562"},
             {"label": "Steventon", "value": "C125563"},
             {"label": "Stevington", "value": "C125564"},
             {"label": "Stewartby", "value": "C125565"},
             {"label": "Stewkley", "value": "C125566"},
             {"label": "Steyning", "value": "C125567"},
             {"label": "Stickney", "value": "C125568"},
             {"label": "Stillington", "value": "C125569"},
             {"label": "Stilton", "value": "C125570"},
             {"label": "Stithians", "value": "C125571"},
             {"label": "Stock", "value": "C125572"},
             {"label": "Stockport", "value": "C125573"},
             {"label": "Stocksbridge", "value": "C125574"},
             {"label": "Stocksfield", "value": "C125575"},
             {"label": "Stockton", "value": "C125576"},
             {"label": "Stockton Heath", "value": "C125577"},
             {"label": "Stockton-on-Tees", "value": "C125578"},
             {"label": "Stoke Ferry", "value": "C125579"},
             {"label": "Stoke Gabriel", "value": "C125580"},
             {"label": "Stoke Gifford", "value": "C125581"},
             {"label": "Stoke Golding", "value": "C125582"},
             {"label": "Stoke Goldington", "value": "C125583"},
             {"label": "Stoke Poges", "value": "C125584"},
             {"label": "Stoke Prior", "value": "C125585"},
             {"label": "Stoke-on-Trent", "value": "C125586"},
             {"label": "Stoke-sub-Hamdon", "value": "C125587"},
             {"label": "Stokenchurch", "value": "C125588"},
             {"label": "Stokesley", "value": "C125589"},
             {"label": "Stone", "value": "C125590"},
             {"label": "Stonehouse", "value": "C125591"},
             {"label": "Stonesfield", "value": "C125592"},
             {"label": "Stoney Stanton", "value": "C125593"},
             {"label": "Storrington", "value": "C125594"},
             {"label": "Stotfold", "value": "C125595"},
             {"label": "Stourbridge", "value": "C125596"},
             {"label": "Stourport-on-Severn", "value": "C125597"},
             {"label": "Stow on the Wold", "value": "C125598"},
             {"label": "Stowmarket", "value": "C125599"},
             {"label": "Stradbroke", "value": "C125600"},
             {"label": "Stratfield Mortimer", "value": "C125601"},
             {"label": "Stratford-upon-Avon", "value": "C125602"},
             {"label": "Stratton", "value": "C125603"},
             {"label": "Streatley", "value": "C125604"},
             {"label": "Street", "value": "C125605"},
             {"label": "Streetly", "value": "C125606"},
             {"label": "Strensall", "value": "C125607"},
             {"label": "Stretford", "value": "C125608"},
             {"label": "Stretham", "value": "C125609"},
             {"label": "Strood", "value": "C125610"},
             {"label": "Stroud", "value": "C125611"},
             {"label": "Studley", "value": "C125612"},
             {"label": "Sturminster Marshall", "value": "C125613"},
             {"label": "Sturminster Newton", "value": "C125614"},
             {"label": "Sturry", "value": "C125615"},
             {"label": "Sudbrooke", "value": "C125616"},
             {"label": "Sudbury", "value": "C125617"},
             {"label": "Suffolk", "value": "C125618"},
             {"label": "Sunbury-on-Thames", "value": "C125619"},
             {"label": "Sunderland", "value": "C125620"},
             {"label": "Sundridge", "value": "C125621"},
             {"label": "Surbiton", "value": "C125622"},
             {"label": "Surrey", "value": "C125623"},
             {"label": "Sutterton", "value": "C125624"},
             {"label": "Sutton", "value": "C125625"},
             {"label": "Sutton Benger", "value": "C125626"},
             {"label": "Sutton Bonington", "value": "C125627"},
             {"label": "Sutton Bridge", "value": "C125628"},
             {"label": "Sutton Coldfield", "value": "C125629"},
             {"label": "Sutton Courtenay", "value": "C125630"},
             {"label": "Sutton in Ashfield", "value": "C125631"},
             {"label": "Sutton on Trent", "value": "C125632"},
             {"label": "Sutton upon Derwent", "value": "C125633"},
             {"label": "Swadlincote", "value": "C125634"},
             {"label": "Swaffham", "value": "C125635"},
             {"label": "Swanage", "value": "C125636"},
             {"label": "Swanley", "value": "C125637"},
             {"label": "Swanmore", "value": "C125638"},
             {"label": "Swanscombe", "value": "C125639"},
             {"label": "Swarthmoor", "value": "C125640"},
             {"label": "Swavesey", "value": "C125641"},
             {"label": "Sway", "value": "C125642"},
             {"label": "Swillington", "value": "C125643"},
             {"label": "Swindon", "value": "C125644"},
             {"label": "Swineshead", "value": "C125645"},
             {"label": "Swinton", "value": "C125646"},
             {"label": "Syston", "value": "C125647"},
             {"label": "Sywell", "value": "C125648"},
             {"label": "Tadcaster", "value": "C125649"},
             {"label": "Tadley", "value": "C125650"},
             {"label": "Tadworth", "value": "C125651"},
             {"label": "Takeley", "value": "C125652"},
             {"label": "Tamworth", "value": "C125653"},
             {"label": "Tanfield", "value": "C125654"},
             {"label": "Tangmere", "value": "C125655"},
             {"label": "Tankerton", "value": "C125656"},
             {"label": "Tansley", "value": "C125657"},
             {"label": "Tarleton", "value": "C125658"},
             {"label": "Tarporley", "value": "C125659"},
             {"label": "Tarvin", "value": "C125660"},
             {"label": "Tattenhall", "value": "C125661"},
             {"label": "Taunton", "value": "C125662"},
             {"label": "Tavistock", "value": "C125663"},
             {"label": "Teddington", "value": "C125664"},
             {"label": "Teignmouth", "value": "C125665"},
             {"label": "Telford", "value": "C125666"},
             {"label": "Telford and Wrekin", "value": "C125667"},
             {"label": "Templecombe", "value": "C125668"},
             {"label": "Tempsford", "value": "C125669"},
             {"label": "Tenbury Wells", "value": "C125670"},
             {"label": "Tenterden", "value": "C125671"},
             {"label": "Terrington Saint John", "value": "C125672"},
             {"label": "Terrington St Clement", "value": "C125673"},
             {"label": "Tetbury", "value": "C125674"},
             {"label": "Tetney", "value": "C125675"},
             {"label": "Tewkesbury", "value": "C125676"},
             {"label": "Teynham", "value": "C125677"},
             {"label": "Thame", "value": "C125678"},
             {"label": "Thames Ditton", "value": "C125679"},
             {"label": "Thatcham", "value": "C125680"},
             {"label": "Thaxted", "value": "C125681"},
             {"label": "The Boldons", "value": "C125682"},
             {"label": "Theale", "value": "C125683"},
             {"label": "Thetford", "value": "C125684"},
             {"label": "Theydon Bois", "value": "C125685"},
             {"label": "Thirsk", "value": "C125686"},
             {"label": "Thornaby-on-Tees", "value": "C125687"},
             {"label": "Thornbury", "value": "C125688"},
             {"label": "Thorne", "value": "C125689"},
             {"label": "Thorner", "value": "C125690"},
             {"label": "Thorney", "value": "C125691"},
             {"label": "Thorngumbald", "value": "C125692"},
             {"label": "Thornley", "value": "C125693"},
             {"label": "Thornton Dale", "value": "C125694"},
             {"label": "Thornton Heath", "value": "C125695"},
             {"label": "Thornton-Cleveleys", "value": "C125696"},
             {"label": "Thorp Arch", "value": "C125697"},
             {"label": "Thorpe Hamlet", "value": "C125698"},
             {"label": "Thorpe le Soken", "value": "C125699"},
             {"label": "Thrapston", "value": "C125700"},
             {"label": "Three Legged Cross", "value": "C125701"},
             {"label": "Thurlby", "value": "C125702"},
             {"label": "Thurlton", "value": "C125703"},
             {"label": "Thurston", "value": "C125704"},
             {"label": "Tibshelf", "value": "C125705"},
             {"label": "Ticehurst", "value": "C125706"},
             {"label": "Tickhill", "value": "C125707"},
             {"label": "Tickton", "value": "C125708"},
             {"label": "Tideswell", "value": "C125709"},
             {"label": "Tidworth", "value": "C125710"},
             {"label": "Tilbury", "value": "C125711"},
             {"label": "Timperley", "value": "C125712"},
             {"label": "Timsbury", "value": "C125713"},
             {"label": "Tingewick", "value": "C125714"},
             {"label": "Tintagel", "value": "C125715"},
             {"label": "Tipton", "value": "C125716"},
             {"label": "Tiptree", "value": "C125717"},
             {"label": "Tisbury", "value": "C125718"},
             {"label": "Tiverton", "value": "C125719"},
             {"label": "Toddington", "value": "C125720"},
             {"label": "Todmorden", "value": "C125721"},
             {"label": "Todwick", "value": "C125722"},
             {"label": "Tollesbury", "value": "C125723"},
             {"label": "Tolleshunt Knights", "value": "C125724"},
             {"label": "Tonbridge", "value": "C125725"},
             {"label": "Topsham", "value": "C125726"},
             {"label": "Torpoint", "value": "C125727"},
             {"label": "Torquay", "value": "C125728"},
             {"label": "Totnes", "value": "C125729"},
             {"label": "Totternhoe", "value": "C125730"},
             {"label": "Tottington", "value": "C125731"},
             {"label": "Totton", "value": "C125732"},
             {"label": "Tow Law", "value": "C125733"},
             {"label": "Towcester", "value": "C125734"},
             {"label": "Town Row", "value": "C125735"},
             {"label": "Trafford", "value": "C125736"},
             {"label": "Trafford Park", "value": "C125737"},
             {"label": "Treeton", "value": "C125738"},
             {"label": "Trimdon", "value": "C125739"},
             {"label": "Trimdon Grange", "value": "C125740"},
             {"label": "Tring", "value": "C125741"},
             {"label": "Trowbridge", "value": "C125742"},
             {"label": "Truro", "value": "C125743"},
             {"label": "Tunstall", "value": "C125744"},
             {"label": "Tuxford", "value": "C125745"},
             {"label": "Twyford", "value": "C125746"},
             {"label": "Tyldesley", "value": "C125747"},
             {"label": "Tynemouth", "value": "C125748"},
             {"label": "Tytherington", "value": "C125749"},
             {"label": "Uckfield", "value": "C125750"},
             {"label": "Uffculme", "value": "C125751"},
             {"label": "Ulceby", "value": "C125752"},
             {"label": "Ulrome", "value": "C125753"},
             {"label": "Ulverston", "value": "C125754"},
             {"label": "Uny Lelant", "value": "C125755"},
             {"label": "Upchurch", "value": "C125756"},
             {"label": "Upminster", "value": "C125757"},
             {"label": "Upper Basildon", "value": "C125758"},
             {"label": "Upper Langwith", "value": "C125759"},
             {"label": "Upper Poppleton", "value": "C125760"},
             {"label": "Uppingham", "value": "C125761"},
             {"label": "Upton", "value": "C125762"},
             {"label": "Upton Scudamore", "value": "C125763"},
             {"label": "Upton upon Severn", "value": "C125764"},
             {"label": "Upwell", "value": "C125765"},
             {"label": "Urmston", "value": "C125766"},
             {"label": "Ushaw Moor", "value": "C125767"},
             {"label": "Uttoxeter", "value": "C125768"},
             {"label": "Ventnor", "value": "C125769"},
             {"label": "Verwood", "value": "C125770"},
             {"label": "Virginia Water", "value": "C125771"},
             {"label": "Waddesdon", "value": "C125772"},
             {"label": "Waddington", "value": "C125773"},
             {"label": "Wadebridge", "value": "C125774"},
             {"label": "Wadhurst", "value": "C125775"},
             {"label": "Wadworth", "value": "C125776"},
             {"label": "Wainfleet All Saints", "value": "C125777"},
             {"label": "Wakefield", "value": "C125778"},
             {"label": "Walberton", "value": "C125779"},
             {"label": "Wales", "value": "C125780"},
             {"label": "Walkden", "value": "C125781"},
             {"label": "Walkern", "value": "C125782"},
             {"label": "Walkington", "value": "C125783"},
             {"label": "Wallasey", "value": "C125784"},
             {"label": "Wallingford", "value": "C125785"},
             {"label": "Wallsend", "value": "C125786"},
             {"label": "Walsall", "value": "C125787"},
             {"label": "Walsden", "value": "C125788"},
             {"label": "Waltham", "value": "C125789"},
             {"label": "Waltham Abbey", "value": "C125790"},
             {"label": "Waltham Cross", "value": "C125791"},
             {"label": "Walton", "value": "C125792"},
             {"label": "Walton-on-Thames", "value": "C125793"},
             {"label": "Walton-on-the-Naze", "value": "C125794"},
             {"label": "Wanborough", "value": "C125795"},
             {"label": "Wansford", "value": "C125796"},
             {"label": "Wantage", "value": "C125797"},
             {"label": "Warboys", "value": "C125798"},
             {"label": "Wardle", "value": "C125799"},
             {"label": "Ware", "value": "C125800"},
             {"label": "Wareham", "value": "C125801"},
             {"label": "Wargrave", "value": "C125802"},
             {"label": "Warkworth", "value": "C125803"},
             {"label": "Warlingham", "value": "C125804"},
             {"label": "Warminster", "value": "C125805"},
             {"label": "Warnham", "value": "C125806"},
             {"label": "Warrington", "value": "C125807"},
             {"label": "Warsop", "value": "C125808"},
             {"label": "Warton", "value": "C125809"},
             {"label": "Warwick", "value": "C125810"},
             {"label": "Warwickshire", "value": "C125811"},
             {"label": "Washingborough", "value": "C125812"},
             {"label": "Washington", "value": "C125813"},
             {"label": "Washwood Heath", "value": "C125814"},
             {"label": "Watchet", "value": "C125815"},
             {"label": "Water Eaton", "value": "C125816"},
             {"label": "Water Orton", "value": "C125817"},
             {"label": "Waterbeach", "value": "C125818"},
             {"label": "Wateringbury", "value": "C125819"},
             {"label": "Waterloo", "value": "C125820"},
             {"label": "Waterlooville", "value": "C125821"},
             {"label": "Watford", "value": "C125822"},
             {"label": "Wath upon Dearne", "value": "C125823"},
             {"label": "Watlington", "value": "C125824"},
             {"label": "Wattisham", "value": "C125825"},
             {"label": "Watton", "value": "C125826"},
             {"label": "Watton at Stone", "value": "C125827"},
             {"label": "Weaverham", "value": "C125828"},
             {"label": "Wedmore", "value": "C125829"},
             {"label": "Wednesbury", "value": "C125830"},
             {"label": "Wednesfield", "value": "C125831"},
             {"label": "Weedon Beck", "value": "C125832"},
             {"label": "Weeting", "value": "C125833"},
             {"label": "Welford", "value": "C125834"},
             {"label": "Wellesbourne", "value": "C125835"},
             {"label": "Wellesbourne Mountford", "value": "C125836"},
             {"label": "Welling", "value": "C125837"},
             {"label": "Wellingborough", "value": "C125838"},
             {"label": "Wellington", "value": "C125839"},
             {"label": "Wellow", "value": "C125840"},
             {"label": "Wells", "value": "C125841"},
             {"label": "Wells-next-the-Sea", "value": "C125842"},
             {"label": "Welwyn", "value": "C125843"},
             {"label": "Welwyn Garden City", "value": "C125844"},
             {"label": "Wem", "value": "C125845"},
             {"label": "Wembley", "value": "C125846"},
             {"label": "Wembury", "value": "C125847"},
             {"label": "Wendover", "value": "C125848"},
             {"label": "Wentworth", "value": "C125849"},
             {"label": "Weobley", "value": "C125850"},
             {"label": "West Bergholt", "value": "C125851"},
             {"label": "West Berkshire", "value": "C125852"},
             {"label": "West Bridgford", "value": "C125853"},
             {"label": "West Bromwich", "value": "C125854"},
             {"label": "West Byfleet", "value": "C125855"},
             {"label": "West Clandon", "value": "C125856"},
             {"label": "West Coker", "value": "C125857"},
             {"label": "West Cornforth", "value": "C125858"},
             {"label": "West Drayton", "value": "C125859"},
             {"label": "West End", "value": "C125860"},
             {"label": "West End of London", "value": "C125861"},
             {"label": "West Haddon", "value": "C125862"},
             {"label": "West Hallam", "value": "C125863"},
             {"label": "West Horsley", "value": "C125864"},
             {"label": "West Ilsley", "value": "C125865"},
             {"label": "West Kingsdown", "value": "C125866"},
             {"label": "West Kirby", "value": "C125867"},
             {"label": "West Malling", "value": "C125868"},
             {"label": "West Mersea", "value": "C125869"},
             {"label": "West Molesey", "value": "C125870"},
             {"label": "West Rainton", "value": "C125871"},
             {"label": "West Sussex", "value": "C125872"},
             {"label": "West Thurrock", "value": "C125873"},
             {"label": "West Walton", "value": "C125874"},
             {"label": "West Wellow", "value": "C125875"},
             {"label": "West Wickham", "value": "C125876"},
             {"label": "Westbury", "value": "C125877"},
             {"label": "Westcliff-on-Sea", "value": "C125878"},
             {"label": "Westcott", "value": "C125879"},
             {"label": "Westergate", "value": "C125880"},
             {"label": "Westerham", "value": "C125881"},
             {"label": "Westfield", "value": "C125882"},
             {"label": "Westgate on Sea", "value": "C125883"},
             {"label": "Westhoughton", "value": "C125884"},
             {"label": "Weston", "value": "C125885"},
             {"label": "Weston Turville", "value": "C125886"},
             {"label": "Weston-super-Mare", "value": "C125887"},
             {"label": "Westoning", "value": "C125888"},
             {"label": "Westonzoyland", "value": "C125889"},
             {"label": "Westwood", "value": "C125890"},
             {"label": "Wetheral", "value": "C125891"},
             {"label": "Wetherby", "value": "C125892"},
             {"label": "Wetwang", "value": "C125893"},
             {"label": "Weybridge", "value": "C125894"},
             {"label": "Weymouth", "value": "C125895"},
             {"label": "Whaley Bridge", "value": "C125896"},
             {"label": "Whalley", "value": "C125897"},
             {"label": "Whaplode", "value": "C125898"},
             {"label": "Wheathampstead", "value": "C125899"},
             {"label": "Wheatley", "value": "C125900"},
             {"label": "Wheaton Aston", "value": "C125901"},
             {"label": "Wheldrake", "value": "C125902"},
             {"label": "Whickham", "value": "C125903"},
             {"label": "Whimple", "value": "C125904"},
             {"label": "Whitburn", "value": "C125905"},
             {"label": "Whitby", "value": "C125906"},
             {"label": "Whitchurch", "value": "C125907"},
             {"label": "White Waltham", "value": "C125908"},
             {"label": "Whitefield", "value": "C125909"},
             {"label": "Whitehaven", "value": "C125910"},
             {"label": "Whiteparish", "value": "C125911"},
             {"label": "Whitley", "value": "C125912"},
             {"label": "Whitley Bay", "value": "C125913"},
             {"label": "Whitstable", "value": "C125914"},
             {"label": "Whittingham", "value": "C125915"},
             {"label": "Whittington", "value": "C125916"},
             {"label": "Whittlesey", "value": "C125917"},
             {"label": "Whittlesford", "value": "C125918"},
             {"label": "Whitwell", "value": "C125919"},
             {"label": "Whitworth", "value": "C125920"},
             {"label": "Whyteleafe", "value": "C125921"},
             {"label": "Wick", "value": "C125922"},
             {"label": "Wickford", "value": "C125923"},
             {"label": "Wickham", "value": "C125924"},
             {"label": "Wickham Bishops", "value": "C125925"},
             {"label": "Wickham Market", "value": "C125926"},
             {"label": "Wickwar", "value": "C125927"},
             {"label": "Wideopen", "value": "C125928"},
             {"label": "Widnes", "value": "C125929"},
             {"label": "Wigan", "value": "C125930"},
             {"label": "Wigmore", "value": "C125931"},
             {"label": "Wigston Magna", "value": "C125932"},
             {"label": "Wigton", "value": "C125933"},
             {"label": "Wilberfoss", "value": "C125934"},
             {"label": "Wilburton", "value": "C125935"},
             {"label": "Willand", "value": "C125936"},
             {"label": "Willaston", "value": "C125937"},
             {"label": "Willenhall", "value": "C125938"},
             {"label": "Willingham", "value": "C125939"},
             {"label": "Willington", "value": "C125940"},
             {"label": "Williton", "value": "C125941"},
             {"label": "Wilmcote", "value": "C125942"},
             {"label": "Wilmslow", "value": "C125943"},
             {"label": "Wilsden", "value": "C125944"},
             {"label": "Wilstead", "value": "C125945"},
             {"label": "Wilton", "value": "C125946"},
             {"label": "Wiltshire", "value": "C125947"},
             {"label": "Wimblington", "value": "C125948"},
             {"label": "Wimborne Minster", "value": "C125949"},
             {"label": "Wincanton", "value": "C125950"},
             {"label": "Winchcombe", "value": "C125951"},
             {"label": "Winchelsea Beach", "value": "C125952"},
             {"label": "Winchester", "value": "C125953"},
             {"label": "Windermere", "value": "C125954"},
             {"label": "Windlesham", "value": "C125955"},
             {"label": "Windsor", "value": "C125956"},
             {"label": "Winford", "value": "C125957"},
             {"label": "Wing", "value": "C125958"},
             {"label": "Wingate", "value": "C125959"},
             {"label": "Wingerworth", "value": "C125960"},
             {"label": "Wingham", "value": "C125961"},
             {"label": "Wingrave", "value": "C125962"},
             {"label": "Winkleigh", "value": "C125963"},
             {"label": "Winscombe", "value": "C125964"},
             {"label": "Winsford", "value": "C125965"},
             {"label": "Winslow", "value": "C125966"},
             {"label": "Winterbourne", "value": "C125967"},
             {"label": "Winterton", "value": "C125968"},
             {"label": "Winwick", "value": "C125969"},
             {"label": "Wirksworth", "value": "C125970"},
             {"label": "Wisbech", "value": "C125971"},
             {"label": "Witchford", "value": "C125972"},
             {"label": "Witham", "value": "C125973"},
             {"label": "Witheridge", "value": "C125974"},
             {"label": "Withernsea", "value": "C125975"},
             {"label": "Witley", "value": "C125976"},
             {"label": "Witney", "value": "C125977"},
             {"label": "Wittering", "value": "C125978"},
             {"label": "Witton Gilbert", "value": "C125979"},
             {"label": "Wiveliscombe", "value": "C125980"},
             {"label": "Wivelsfield Green", "value": "C125981"},
             {"label": "Wivenhoe", "value": "C125982"},
             {"label": "Woburn", "value": "C125983"},
             {"label": "Woburn Sands", "value": "C125984"},
             {"label": "Woking", "value": "C125985"},
             {"label": "Wokingham", "value": "C125986"},
             {"label": "Wold Newton", "value": "C125987"},
             {"label": "Woldingham", "value": "C125988"},
             {"label": "Wollaston", "value": "C125989"},
             {"label": "Wolsingham", "value": "C125990"},
             {"label": "Wolston", "value": "C125991"},
             {"label": "Wolvercote", "value": "C125992"},
             {"label": "Wolverhampton", "value": "C125993"},
             {"label": "Wombourn", "value": "C125994"},
             {"label": "Wombwell", "value": "C125995"},
             {"label": "Wood Street Village", "value": "C125996"},
             {"label": "Woodborough", "value": "C125997"},
             {"label": "Woodbridge", "value": "C125998"},
             {"label": "Woodbury", "value": "C125999"},
             {"label": "Woodchurch", "value": "C126000"},
             {"label": "Woodcote", "value": "C126001"},
             {"label": "Woodford", "value": "C126002"},
             {"label": "Woodford Green", "value": "C126003"},
             {"label": "Woodhall Spa", "value": "C126004"},
             {"label": "Woodsetts", "value": "C126005"},
             {"label": "Woodstock", "value": "C126006"},
             {"label": "Wool", "value": "C126007"},
             {"label": "Woolavington", "value": "C126008"},
             {"label": "Wooler", "value": "C126009"},
             {"label": "Woolley", "value": "C126010"},
             {"label": "Woolpit", "value": "C126011"},
             {"label": "Wootton", "value": "C126012"},
             {"label": "Worcester", "value": "C126013"},
             {"label": "Worcester Park", "value": "C126014"},
             {"label": "Worcestershire", "value": "C126015"},
             {"label": "Workington", "value": "C126016"},
             {"label": "Worksop", "value": "C126017"},
             {"label": "Worlaby", "value": "C126018"},
             {"label": "Wormley", "value": "C126019"},
             {"label": "Worthing", "value": "C126020"},
             {"label": "Worton", "value": "C126021"},
             {"label": "Wotton-under-Edge", "value": "C126022"},
             {"label": "Wouldham", "value": "C126023"},
             {"label": "Wragby", "value": "C126024"},
             {"label": "Wrawby", "value": "C126025"},
             {"label": "Wrea Green", "value": "C126026"},
             {"label": "Wrington", "value": "C126027"},
             {"label": "Writtle", "value": "C126028"},
             {"label": "Wrotham", "value": "C126029"},
             {"label": "Wroughton", "value": "C126030"},
             {"label": "Wroxall", "value": "C126031"},
             {"label": "Wychbold", "value": "C126032"},
             {"label": "Wye", "value": "C126033"},
             {"label": "Wylam", "value": "C126034"},
             {"label": "Wymeswold", "value": "C126035"},
             {"label": "Yalding", "value": "C126036"},
             {"label": "Yapton", "value": "C126037"},
             {"label": "Yarm", "value": "C126038"},
             {"label": "Yarmouth", "value": "C126039"},
             {"label": "Yarnton", "value": "C126040"},
             {"label": "Yate", "value": "C126041"},
             {"label": "Yateley", "value": "C126042"},
             {"label": "Yatton", "value": "C126043"},
             {"label": "Yaxley", "value": "C126044"},
             {"label": "Yeadon", "value": "C126045"},
             {"label": "Yealmpton", "value": "C126046"},
             {"label": "Yelverton", "value": "C126047"},
             {"label": "Yeovil", "value": "C126048"},
             {"label": "Yetminster", "value": "C126049"},
             {"label": "York", "value": "C126050"},
             {"label": "Youlgreave", "value": "C126051"},
             {"label": "Yoxall", "value": "C126052"}],
 "Engure Municipality": [{"label": "Smarde", "value": "C64865"}],
 "Ennedi-Ouest": [{"label": "Fada", "value": "C17855"}],
 "Entre Rios": [{"label": "Aldea San Antonio", "value": "C1000"},
                {"label": "Aranguren", "value": "C1001"},
                {"label": "Bovril", "value": "C1002"},
                {"label": "Caseros", "value": "C1003"},
                {"label": "Ceibas", "value": "C1004"},
                {"label": "Chajari", "value": "C1005"},
                {"label": "Colon", "value": "C1006"},
                {"label": "Colonia Elia", "value": "C1007"},
                {"label": "Concepcion del Uruguay", "value": "C1008"},
                {"label": "Concordia", "value": "C1009"},
                {"label": "Conscripto Bernardi", "value": "C1010"},
                {"label": "Crespo", "value": "C1011"},
                {"label": "Departamento de Gualeguaychu", "value": "C1012"},
                {"label": "Departamento de Parana", "value": "C1013"},
                {"label": "Diamante", "value": "C1014"},
                {"label": "Dominguez", "value": "C1015"},
                {"label": "Federacion", "value": "C1016"},
                {"label": "Federal", "value": "C1017"},
                {"label": "General Campos", "value": "C1018"},
                {"label": "General Galarza", "value": "C1019"},
                {"label": "General Ramirez", "value": "C1020"},
                {"label": "Gobernador Mansilla", "value": "C1021"},
                {"label": "Gualeguay", "value": "C1022"},
                {"label": "Gualeguaychu", "value": "C1023"},
                {"label": "Hasenkamp", "value": "C1024"},
                {"label": "Hernandez", "value": "C1025"},
                {"label": "Herrera", "value": "C1026"},
                {"label": "La Criolla", "value": "C1027"},
                {"label": "La Paz", "value": "C1028"},
                {"label": "Larroque", "value": "C1029"},
                {"label": "Los Charruas", "value": "C1030"},
                {"label": "Los Conquistadores", "value": "C1031"},
                {"label": "Lucas Gonzalez", "value": "C1032"},
                {"label": "Macia", "value": "C1033"},
                {"label": "Nogoya", "value": "C1034"},
                {"label": "Oro Verde", "value": "C1035"},
                {"label": "Parana", "value": "C1036"},
                {"label": "Piedras Blancas", "value": "C1037"},
                {"label": "Pronunciamiento", "value": "C1038"},
                {"label": "Puerto Ibicuy", "value": "C1039"},
                {"label": "Puerto Yerua", "value": "C1040"},
                {"label": "Rosario del Tala", "value": "C1041"},
                {"label": "San Benito", "value": "C1042"},
                {"label": "San Gustavo", "value": "C1043"},
                {"label": "San Jose de Feliciano", "value": "C1044"},
                {"label": "San Justo", "value": "C1045"},
                {"label": "San Salvador", "value": "C1046"},
                {"label": "Santa Ana", "value": "C1047"},
                {"label": "Santa Anita", "value": "C1048"},
                {"label": "Santa Elena", "value": "C1049"},
                {"label": "Sauce de Luna", "value": "C1050"},
                {"label": "Segui", "value": "C1051"},
                {"label": "Tabossi", "value": "C1052"},
                {"label": "Ubajay", "value": "C1053"},
                {"label": "Urdinarrain", "value": "C1054"},
                {"label": "Viale", "value": "C1055"},
                {"label": "Victoria", "value": "C1056"},
                {"label": "Villa del Rosario", "value": "C1057"},
                {"label": "Villa Elisa", "value": "C1058"},
                {"label": "Villa Hernandarias", "value": "C1059"},
                {"label": "Villa Mantero", "value": "C1060"},
                {"label": "Villa Maria Grande", "value": "C1061"},
                {"label": "Villa Paranacito", "value": "C1062"},
                {"label": "Villa Urquiza", "value": "C1063"},
                {"label": "Villaguay", "value": "C1064"}],
 "Enugu": [{"label": "Adani", "value": "C78253"},
           {"label": "Ake-Eze", "value": "C78254"},
           {"label": "Aku", "value": "C78255"},
           {"label": "Amagunze", "value": "C78256"},
           {"label": "Awgu", "value": "C78257"},
           {"label": "Eha Amufu", "value": "C78258"},
           {"label": "Enugu", "value": "C78259"},
           {"label": "Enugu-Ezike", "value": "C78260"},
           {"label": "Ete", "value": "C78261"},
           {"label": "Ikem", "value": "C78262"},
           {"label": "Mberubu", "value": "C78263"},
           {"label": "Nsukka", "value": "C78264"},
           {"label": "Obolo-Eke (1)", "value": "C78265"},
           {"label": "Opi", "value": "C78266"},
           {"label": "Udi", "value": "C78267"}],
 "Epirus Region": [{"label": "Agia Kyriaki", "value": "C42217"},
                   {"label": "Anatoli", "value": "C42218"},
                   {"label": "Aneza", "value": "C42219"},
                   {"label": "ano Kalentini", "value": "C42220"},
                   {"label": "arta", "value": "C42221"},
                   {"label": "Asprangeloi", "value": "C42222"},
                   {"label": "Chalkiades", "value": "C42223"},
                   {"label": "Eksochi", "value": "C42224"},
                   {"label": "Eleousa", "value": "C42225"},
                   {"label": "Filiates", "value": "C42226"},
                   {"label": "Filippiada", "value": "C42227"},
                   {"label": "Graikochori", "value": "C42228"},
                   {"label": "Grammenitsa", "value": "C42229"},
                   {"label": "Igoumenitsa", "value": "C42230"},
                   {"label": "Ioannina", "value": "C42231"},
                   {"label": "Kalamia", "value": "C42232"},
                   {"label": "Kalpaki", "value": "C42233"},
                   {"label": "Kanalaki", "value": "C42234"},
                   {"label": "Kardamitsia", "value": "C42235"},
                   {"label": "Katsikas", "value": "C42236"},
                   {"label": "Kompoti", "value": "C42237"},
                   {"label": "Konitsa", "value": "C42238"},
                   {"label": "Kostakioi", "value": "C42239"},
                   {"label": "Koutselio", "value": "C42240"},
                   {"label": "Louros", "value": "C42241"},
                   {"label": "Metsovo", "value": "C42242"},
                   {"label": "Nea Selefkeia", "value": "C42243"},
                   {"label": "Neochori", "value": "C42244"},
                   {"label": "Neochoropoulo", "value": "C42245"},
                   {"label": "Neos Oropos", "value": "C42246"},
                   {"label": "Nomos Ioanninon", "value": "C42247"},
                   {"label": "Pappadates", "value": "C42248"},
                   {"label": "Paramythia", "value": "C42249"},
                   {"label": "Parapotamos", "value": "C42250"},
                   {"label": "Parga", "value": "C42251"},
                   {"label": "Pedini", "value": "C42252"},
                   {"label": "Perama", "value": "C42253"},
                   {"label": "Perdika", "value": "C42254"},
                   {"label": "Peta", "value": "C42255"},
                   {"label": "Plataria", "value": "C42256"},
                   {"label": "Pramanta", "value": "C42257"},
                   {"label": "Preveza", "value": "C42258"},
                   {"label": "Rodotopi", "value": "C42259"},
                   {"label": "Stavraki", "value": "C42260"},
                   {"label": "Thesprotiko", "value": "C42261"},
                   {"label": "Tsiflikopoulo", "value": "C42262"},
                   {"label": "Voulgareli", "value": "C42263"},
                   {"label": "Vounoplagia", "value": "C42264"}],
 "Erbil Governorate": [{"label": "Arbil", "value": "C51177"},
                       {"label": "Erbil", "value": "C51178"},
                       {"label": "Koysinceq", "value": "C51179"},
                       {"label": "Ruwandiz", "value": "C51180"},
                       {"label": "Shaqlawah", "value": "C51181"},
                       {"label": "Soran", "value": "C51182"}],
 "Ergli Municipality": [{"label": "Ergli", "value": "C64866"}],
 "Ermera District": [{"label": "Ermera Villa", "value": "C24064"},
                     {"label": "Gleno", "value": "C24065"},
                     {"label": "Hatulia", "value": "C24066"},
                     {"label": "Letefoho", "value": "C24067"},
                     {"label": "Railaco", "value": "C24068"}],
 "Erongo Region": [{"label": "Arandis", "value": "C75964"},
                   {"label": "Hentiesbaai", "value": "C75965"},
                   {"label": "Karibib", "value": "C75966"},
                   {"label": "Omaruru", "value": "C75967"},
                   {"label": "Otjimbingwe", "value": "C75968"},
                   {"label": "Swakopmund", "value": "C75969"},
                   {"label": "Swakopmund Constituency", "value": "C75970"},
                   {"label": "Usakos", "value": "C75971"},
                   {"label": "Walvis Bay", "value": "C75972"}],
 "Erzincan": [{"label": "Cayirli Ilcesi", "value": "C120267"},
              {"label": "Cimin", "value": "C120268"},
              {"label": "Erzincan", "value": "C120269"},
              {"label": "Ilic Ilcesi", "value": "C120270"},
              {"label": "Kemah", "value": "C120271"},
              {"label": "Kemaliye", "value": "C120272"},
              {"label": "Otlukbeli", "value": "C120273"},
              {"label": "Refahiye", "value": "C120274"},
              {"label": "Tercan", "value": "C120275"},
              {"label": "uzumlu Ilcesi", "value": "C120276"}],
 "Erzurum": [{"label": "Askale", "value": "C120277"},
             {"label": "Aziziye", "value": "C120278"},
             {"label": "Cat Ilcesi", "value": "C120279"},
             {"label": "Erzurum", "value": "C120280"},
             {"label": "Hinis", "value": "C120281"},
             {"label": "Horasan", "value": "C120282"},
             {"label": "Ilica", "value": "C120283"},
             {"label": "Ispir", "value": "C120284"},
             {"label": "Karacoban", "value": "C120285"},
             {"label": "Karayazi", "value": "C120286"},
             {"label": "Koprukoy Ilcesi", "value": "C120287"},
             {"label": "Narman", "value": "C120288"},
             {"label": "Oltu", "value": "C120289"},
             {"label": "Olur", "value": "C120290"},
             {"label": "Palandoken", "value": "C120291"},
             {"label": "Pasinler", "value": "C120292"},
             {"label": "Pazaryolu", "value": "C120293"},
             {"label": "Senkaya", "value": "C120294"},
             {"label": "Tekman", "value": "C120295"},
             {"label": "Tortum", "value": "C120296"},
             {"label": "Uzundere", "value": "C120297"},
             {"label": "Yakutiye", "value": "C120298"}],
 "Escaldes-Engordany": [{"label": "les Escaldes", "value": "C521"}],
 "Eschen": [{"label": "Eschen", "value": "C65072"}],
 "Escuintla Department": [{"label": "Escuintla", "value": "C42673"},
                          {"label": "Guanagazapa", "value": "C42674"},
                          {"label": "Iztapa", "value": "C42675"},
                          {"label": "La Democracia", "value": "C42676"},
                          {"label": "La Gomera", "value": "C42677"},
                          {"label": "Masagua", "value": "C42678"},
                          {"label": "Nueva Concepcion", "value": "C42679"},
                          {"label": "Palin", "value": "C42680"},
                          {"label": "Puerto San Jose", "value": "C42681"},
                          {"label": "San Vicente Pacaya", "value": "C42682"},
                          {"label": "Santa Lucia Cotzumalguapa",
                           "value": "C42683"},
                          {"label": "Siquinala", "value": "C42684"},
                          {"label": "Tiquisate", "value": "C42685"}],
 "Eskisehir": [{"label": "Alpu", "value": "C120299"},
               {"label": "Beylikova", "value": "C120300"},
               {"label": "Bozan", "value": "C120301"},
               {"label": "Cifteler", "value": "C120302"},
               {"label": "Eskisehir", "value": "C120303"},
               {"label": "Gunyuzu Ilcesi", "value": "C120304"},
               {"label": "Han", "value": "C120305"},
               {"label": "Inonu", "value": "C120306"},
               {"label": "Kirka", "value": "C120307"},
               {"label": "Mahmudiye Ilcesi", "value": "C120308"},
               {"label": "Mihalgazi", "value": "C120309"},
               {"label": "Mihaliccik Ilcesi", "value": "C120310"},
               {"label": "Odunpazari", "value": "C120311"},
               {"label": "Saricakaya Ilcesi", "value": "C120312"},
               {"label": "Sevinc", "value": "C120313"},
               {"label": "Seyitgazi", "value": "C120314"},
               {"label": "Sivrihisar", "value": "C120315"},
               {"label": "Tepebasi", "value": "C120316"}],
 "Esmeraldas": [{"label": "Esmeraldas", "value": "C24122"},
                {"label": "Muisne", "value": "C24123"},
                {"label": "Pampanal de Bolivar", "value": "C24124"},
                {"label": "Rio Verde", "value": "C24125"},
                {"label": "Rosa Zarate", "value": "C24126"},
                {"label": "San Lorenzo de Esmeraldas", "value": "C24127"},
                {"label": "Valdez", "value": "C24128"}],
 "Espaillat Province": [{"label": "Cayetano Germosen", "value": "C23894"},
                        {"label": "Gaspar Hernandez", "value": "C23895"},
                        {"label": "Jamao al Norte", "value": "C23896"},
                        {"label": "Joba Arriba", "value": "C23897"},
                        {"label": "Juan Lopez Abajo", "value": "C23898"},
                        {"label": "Moca", "value": "C23899"},
                        {"label": "San Victor Arriba", "value": "C23900"},
                        {"label": "Veragua Arriba", "value": "C23901"}],
 "Espirito Santo": [{"label": "Afonso Claudio", "value": "C11009"},
                    {"label": "agua Doce do Norte", "value": "C11010"},
                    {"label": "aguia Branca", "value": "C11011"},
                    {"label": "Alegre", "value": "C11012"},
                    {"label": "Alfredo Chaves", "value": "C11013"},
                    {"label": "Alto Rio Novo", "value": "C11014"},
                    {"label": "Anchieta", "value": "C11015"},
                    {"label": "Apiaca", "value": "C11016"},
                    {"label": "Aracruz", "value": "C11017"},
                    {"label": "Atilio Vivacqua", "value": "C11018"},
                    {"label": "Baixo Guandu", "value": "C11019"},
                    {"label": "Barra de Sao Francisco", "value": "C11020"},
                    {"label": "Boa Esperanca", "value": "C11021"},
                    {"label": "Bom Jesus do Norte", "value": "C11022"},
                    {"label": "Brejetuba", "value": "C11023"},
                    {"label": "Cachoeiro de Itapemirim", "value": "C11024"},
                    {"label": "Cariacica", "value": "C11025"},
                    {"label": "Castelo", "value": "C11026"},
                    {"label": "Colatina", "value": "C11027"},
                    {"label": "Conceicao da Barra", "value": "C11028"},
                    {"label": "Conceicao do Castelo", "value": "C11029"},
                    {"label": "Divino de Sao Lourenco", "value": "C11030"},
                    {"label": "Domingos Martins", "value": "C11031"},
                    {"label": "Dores do Rio Preto", "value": "C11032"},
                    {"label": "Ecoporanga", "value": "C11033"},
                    {"label": "Fundao", "value": "C11034"},
                    {"label": "Governador Lindenberg", "value": "C11035"},
                    {"label": "Guacui", "value": "C11036"},
                    {"label": "Guarapari", "value": "C11037"},
                    {"label": "Ibatiba", "value": "C11038"},
                    {"label": "Ibiracu", "value": "C11039"},
                    {"label": "Ibitirama", "value": "C11040"},
                    {"label": "Iconha", "value": "C11041"},
                    {"label": "Irupi", "value": "C11042"},
                    {"label": "Itaguacu", "value": "C11043"},
                    {"label": "Itapemirim", "value": "C11044"},
                    {"label": "Itarana", "value": "C11045"},
                    {"label": "Iuna", "value": "C11046"},
                    {"label": "Jaguare", "value": "C11047"},
                    {"label": "Jeronimo Monteiro", "value": "C11048"},
                    {"label": "Jetiba", "value": "C11049"},
                    {"label": "Joao Neiva", "value": "C11050"},
                    {"label": "Laranja da Terra", "value": "C11051"},
                    {"label": "Linhares", "value": "C11052"},
                    {"label": "Mantenopolis", "value": "C11053"},
                    {"label": "Marataizes", "value": "C11054"},
                    {"label": "Marechal Floriano", "value": "C11055"},
                    {"label": "Marilandia", "value": "C11056"},
                    {"label": "Mimoso do Sul", "value": "C11057"},
                    {"label": "Montanha", "value": "C11058"},
                    {"label": "Mucurici", "value": "C11059"},
                    {"label": "Muniz Freire", "value": "C11060"},
                    {"label": "Muqui", "value": "C11061"},
                    {"label": "Nova Venecia", "value": "C11062"},
                    {"label": "Pancas", "value": "C11063"},
                    {"label": "Pedro Canario", "value": "C11064"},
                    {"label": "Pinheiros", "value": "C11065"},
                    {"label": "Piuma", "value": "C11066"},
                    {"label": "Ponto Belo", "value": "C11067"},
                    {"label": "Presidente Kennedy", "value": "C11068"},
                    {"label": "Rio Bananal", "value": "C11069"},
                    {"label": "Rio Novo do Sul", "value": "C11070"},
                    {"label": "Santa Leopoldina", "value": "C11071"},
                    {"label": "Santa Maria de Jetiba", "value": "C11072"},
                    {"label": "Santa Teresa", "value": "C11073"},
                    {"label": "Sao Domingos do Norte", "value": "C11074"},
                    {"label": "Sao Gabriel da Palha", "value": "C11075"},
                    {"label": "Sao Jose do Calcado", "value": "C11076"},
                    {"label": "Sao Mateus", "value": "C11077"},
                    {"label": "Sao Roque do Canaa", "value": "C11078"},
                    {"label": "Serra", "value": "C11079"},
                    {"label": "Sooretama", "value": "C11080"},
                    {"label": "Vargem Alta", "value": "C11081"},
                    {"label": "Venda Nova do Imigrante", "value": "C11082"},
                    {"label": "Viana", "value": "C11083"},
                    {"label": "Vila Pavao", "value": "C11084"},
                    {"label": "Vila Valerio", "value": "C11085"},
                    {"label": "Vila Velha", "value": "C11086"},
                    {"label": "Vitoria", "value": "C11087"}],
 "Essequibo Islands-West Demerara": [{"label": "Parika", "value": "C43071"},
                                     {"label": "Vreed-en-Hoop",
                                      "value": "C43072"}],
 "Est Region": [{"label": "Bogande", "value": "C16399"},
                {"label": "Diapaga", "value": "C16400"},
                {"label": "Fada Ngourma", "value": "C16401"},
                {"label": "Gayeri", "value": "C16402"},
                {"label": "Gnagna Province", "value": "C16403"},
                {"label": "Pama", "value": "C16404"},
                {"label": "Province de la Komandjoari", "value": "C16405"},
                {"label": "Province de la Kompienga", "value": "C16406"},
                {"label": "Province de la Tapoa", "value": "C16407"},
                {"label": "Province du Gourma", "value": "C16408"}],
 "Estado de Mexico": [{"label": "Acachuen", "value": "C67359"},
                      {"label": "Acalpican de Morelos", "value": "C67360"},
                      {"label": "Acambay", "value": "C67361"},
                      {"label": "Acatzingo (Acatzingo de la Piedra)",
                       "value": "C67362"},
                      {"label": "Acolman de Netzahualcoyotl",
                       "value": "C67363"},
                      {"label": "Acuitlapilco", "value": "C67364"},
                      {"label": "Aculco de Espinoza", "value": "C67365"},
                      {"label": "Agua Blanca", "value": "C67366"},
                      {"label": "Agua Caliente", "value": "C67367"},
                      {"label": "Agua Escondida", "value": "C67368"},
                      {"label": "Ahuacatitlan", "value": "C67369"},
                      {"label": "Ahuacatitlan Cuarto y Quinto Cuartel",
                       "value": "C67370"},
                      {"label": "Alborada Jaltenco", "value": "C67371"},
                      {"label": "Almoloya de Alquisiras", "value": "C67372"},
                      {"label": "Almoloya del Rio", "value": "C67373"},
                      {"label": "Amanalco de Becerra", "value": "C67374"},
                      {"label": "Amatepec", "value": "C67375"},
                      {"label": "Amecameca", "value": "C67376"},
                      {"label": "Ampliacion San Mateo (Colonia Solidaridad)",
                       "value": "C67377"},
                      {"label": "Ampliacion Tezoyuca", "value": "C67378"},
                      {"label": "Apaxco de Ocampo", "value": "C67379"},
                      {"label": "Arbolada los Sauces", "value": "C67380"},
                      {"label": "Arenal", "value": "C67381"},
                      {"label": "Atenguillo", "value": "C67382"},
                      {"label": "Atizapan", "value": "C67383"},
                      {"label": "Atlacomulco de Fabela", "value": "C67384"},
                      {"label": "Atlatongo", "value": "C67385"},
                      {"label": "Atlautla", "value": "C67386"},
                      {"label": "Axapusco", "value": "C67387"},
                      {"label": "Axotlan", "value": "C67388"},
                      {"label": "Ayapango", "value": "C67389"},
                      {"label": "Barrio Bordo Nuevo", "value": "C67390"},
                      {"label": "Barrio Chiquichuca", "value": "C67391"},
                      {"label": "Barrio Cuarto (La Loma)", "value": "C67392"},
                      {"label": "Barrio de Arriba de San Juan Xoconusco",
                       "value": "C67393"},
                      {"label": "Barrio de Boyecha", "value": "C67394"},
                      {"label": "Barrio de Canales", "value": "C67395"},
                      {"label": "Barrio de Centro del Cerrillo",
                       "value": "C67396"},
                      {"label": "Barrio de Ensido", "value": "C67397"},
                      {"label": "Barrio de Espana", "value": "C67398"},
                      {"label": "Barrio de Guadalupe", "value": "C67399"},
                      {"label": "Barrio de Jesus Fraccion Primera",
                       "value": "C67400"},
                      {"label": "Barrio de la Barranca", "value": "C67401"},
                      {"label": "Barrio de Mexico", "value": "C67402"},
                      {"label": "Barrio de Puentecillas", "value": "C67403"},
                      {"label": "Barrio de San Isidro", "value": "C67404"},
                      {"label": "Barrio de San Juan", "value": "C67405"},
                      {"label": "Barrio de San Miguel", "value": "C67406"},
                      {"label": "Barrio de San Pedro la Cabecera",
                       "value": "C67407"},
                      {"label": "Barrio de San Ramon", "value": "C67408"},
                      {"label": "Barrio del Cajon", "value": "C67409"},
                      {"label": "Barrio el Boncho", "value": "C67410"},
                      {"label": "Barrio el Vivero", "value": "C67411"},
                      {"label": "Barrio la Joya", "value": "C67412"},
                      {"label": "Barrio la Teneria", "value": "C67413"},
                      {"label": "Barrio los Tules", "value": "C67414"},
                      {"label": "Barrio San Joaquin el Junco",
                       "value": "C67415"},
                      {"label": "Barrio San Miguel Dorami", "value": "C67416"},
                      {"label": "Barrio Santa Cruz", "value": "C67417"},
                      {"label": "Barrio Tepetitlan Emilio Portes Gil",
                       "value": "C67418"},
                      {"label": "Barrio Tlatenco", "value": "C67419"},
                      {"label": "Bejucos", "value": "C67420"},
                      {"label": "Belem", "value": "C67421"},
                      {"label": "Benito Juarez", "value": "C67422"},
                      {"label": "Bobashi de Guadalupe", "value": "C67423"},
                      {"label": "Boshindo", "value": "C67424"},
                      {"label": "Bosques de la Magdalena", "value": "C67425"},
                      {"label": "Buenavista", "value": "C67426"},
                      {"label": "Buenos Aires", "value": "C67427"},
                      {"label": "Cabecera de Indigenas", "value": "C67428"},
                      {"label": "Cacalomacan", "value": "C67429"},
                      {"label": "Caja de Agua", "value": "C67430"},
                      {"label": "Calimaya", "value": "C67431"},
                      {"label": "Calixtlahuaca", "value": "C67432"},
                      {"label": "Calvario Buenavista", "value": "C67433"},
                      {"label": "Calvario del Carmen", "value": "C67434"},
                      {"label": "Canada de Cisneros", "value": "C67435"},
                      {"label": "Canada de Guadarrama", "value": "C67436"},
                      {"label": "Canalejas", "value": "C67437"},
                      {"label": "Capula", "value": "C67438"},
                      {"label": "Capulhuac de Mirafuentes", "value": "C67439"},
                      {"label": "Carapan", "value": "C67440"},
                      {"label": "Casa Nueva", "value": "C67441"},
                      {"label": "Casas Viejas", "value": "C67442"},
                      {"label": "Caserio de Cortes", "value": "C67443"},
                      {"label": "Cedro de la Manzana", "value": "C67444"},
                      {"label": "Celayita", "value": "C67445"},
                      {"label": "Cerritos del Pilar", "value": "C67446"},
                      {"label": "Cerro de San Francisco", "value": "C67447"},
                      {"label": "Cerro del Murcielago", "value": "C67448"},
                      {"label": "Cerro La Calera", "value": "C67449"},
                      {"label": "Chalchihuapan", "value": "C67450"},
                      {"label": "Chalco", "value": "C67451"},
                      {"label": "Chalma", "value": "C67452"},
                      {"label": "Chalmita", "value": "C67453"},
                      {"label": "Chapa de Mota", "value": "C67454"},
                      {"label": "Chaparaco", "value": "C67455"},
                      {"label": "Chapultepec", "value": "C67456"},
                      {"label": "Chiautla", "value": "C67457"},
                      {"label": "Chicoloapan", "value": "C67458"},
                      {"label": "Chiconcuac", "value": "C67459"},
                      {"label": "Chilchota", "value": "C67460"},
                      {"label": "Chiltepec de Hidalgo", "value": "C67461"},
                      {"label": "Chimalpa Viejo", "value": "C67462"},
                      {"label": "Chosto de los Jarros", "value": "C67463"},
                      {"label": "Ciudad Lopez Mateos", "value": "C67464"},
                      {"label": "Ciudad Nezahualcoyotl", "value": "C67465"},
                      {"label": "Coacalco", "value": "C67466"},
                      {"label": "Coamilpa de Juarez", "value": "C67467"},
                      {"label": "Coatepec", "value": "C67468"},
                      {"label": "Coatepec Harinas", "value": "C67469"},
                      {"label": "Cocotitlan", "value": "C67470"},
                      {"label": "Colonia 2 de Septiembre", "value": "C67471"},
                      {"label": "Colonia 3 de Mayo", "value": "C67472"},
                      {"label": "Colonia 3 de Mayo (La Cruz)",
                       "value": "C67473"},
                      {"label": "Colonia Adolfo Lopez Mateos",
                       "value": "C67474"},
                      {"label": "Colonia Agricola alvaro Obregon",
                       "value": "C67475"},
                      {"label": "Colonia Agricola Analco", "value": "C67476"},
                      {"label": "Colonia alvaro Obregon", "value": "C67477"},
                      {"label": "Colonia Arboledas (San Andres)",
                       "value": "C67478"},
                      {"label": "Colonia Aviacion Autopan", "value": "C67479"},
                      {"label": "Colonia Azteca", "value": "C67480"},
                      {"label": "Colonia Bellavista", "value": "C67481"},
                      {"label": "Colonia Cuauhtemoc", "value": "C67482"},
                      {"label": "Colonia Doctor Gustavo Baz",
                       "value": "C67483"},
                      {"label": "Colonia el Mirador", "value": "C67484"},
                      {"label": "Colonia el Pirame", "value": "C67485"},
                      {"label": "Colonia el Refugio", "value": "C67486"},
                      {"label": "Colonia el Salado", "value": "C67487"},
                      {"label": "Colonia Emiliano Zapata", "value": "C67488"},
                      {"label": "Colonia Emiliano Zapata Ejido de Tenancingo",
                       "value": "C67489"},
                      {"label": "Colonia Guadalupe", "value": "C67490"},
                      {"label": "Colonia Guadalupe Victoria",
                       "value": "C67491"},
                      {"label": "Colonia Guadalupe Victoria (La Capilla)",
                       "value": "C67492"},
                      {"label": "Colonia Gustavo Baz Prada", "value": "C67493"},
                      {"label": "Colonia Isidro Fabela", "value": "C67494"},
                      {"label": "Colonia Juarez", "value": "C67495"},
                      {"label": "Colonia la Asuncion", "value": "C67496"},
                      {"label": "Colonia la Libertad", "value": "C67497"},
                      {"label": "Colonia Lazaro Cardenas", "value": "C67498"},
                      {"label": "Colonia Lazaro Cardenas (Los Hornos)",
                       "value": "C67499"},
                      {"label": "Colonia Lindavista", "value": "C67500"},
                      {"label": "Colonia los Aguiluchos", "value": "C67501"},
                      {"label": "Colonia los Cedros", "value": "C67502"},
                      {"label": "Colonia los Remedios", "value": "C67503"},
                      {"label": "Colonia Morelos", "value": "C67504"},
                      {"label": "Colonia Palma", "value": "C67505"},
                      {"label": "Colonia Reforma Tlalmimilolpan",
                       "value": "C67506"},
                      {"label": "Colonia Ricardo Flores Magon",
                       "value": "C67507"},
                      {"label": "Colonia Rincon Villa del Valle",
                       "value": "C67508"},
                      {"label": "Colonia Rio Lerma (Tic Ti)",
                       "value": "C67509"},
                      {"label": "Colonia San Francisco de Asis",
                       "value": "C67510"},
                      {"label": "Colonia San Ramon", "value": "C67511"},
                      {"label": "Colonia Tulteca Teopan", "value": "C67512"},
                      {"label": "Colonia Wenceslao Labra", "value": "C67513"},
                      {"label": "Colorines", "value": "C67514"},
                      {"label": "Concepcion Caro", "value": "C67515"},
                      {"label": "Concepcion del Monte", "value": "C67516"},
                      {"label": "Concepcion Jolalpan", "value": "C67517"},
                      {"label": "Concepcion la Venta", "value": "C67518"},
                      {"label": "Conejeras", "value": "C67519"},
                      {"label": "Conjunto Habitacional Ecologico SUTEYM",
                       "value": "C67520"},
                      {"label": "Conjunto Urbano la Loma I", "value": "C67521"},
                      {"label": "Contepec", "value": "C67522"},
                      {"label": "Coscomate del Progreso", "value": "C67523"},
                      {"label": "Coyotepec", "value": "C67524"},
                      {"label": "Cozotlan Norte", "value": "C67525"},
                      {"label": "Cuadrilla de Dolores", "value": "C67526"},
                      {"label": "Cuadrilla Vieja", "value": "C67527"},
                      {"label": "Cuautitlan", "value": "C67528"},
                      {"label": "Cuautlacingo", "value": "C67529"},
                      {"label": "Cuecuecuatitla", "value": "C67530"},
                      {"label": "Cuendo", "value": "C67531"},
                      {"label": "Denjhi", "value": "C67532"},
                      {"label": "Detina (San Antonio Detina)",
                       "value": "C67533"},
                      {"label": "Dios Padre", "value": "C67534"},
                      {"label": "Dolores", "value": "C67535"},
                      {"label": "Dolores Hidalgo", "value": "C67536"},
                      {"label": "Dongu", "value": "C67537"},
                      {"label": "Dotegiare", "value": "C67538"},
                      {"label": "Doxhicho", "value": "C67539"},
                      {"label": "Doxteje Barrio Primero", "value": "C67540"},
                      {"label": "Doxteje Centro", "value": "C67541"},
                      {"label": "Ecatepec de Morelos", "value": "C67542"},
                      {"label": "Ecatzingo de Hidalgo", "value": "C67543"},
                      {"label": "Ejido de Coscomate del Progreso",
                       "value": "C67544"},
                      {"label": "Ejido de Dolores", "value": "C67545"},
                      {"label": "Ejido de Guadalupe", "value": "C67546"},
                      {"label": "Ejido de la Finca", "value": "C67547"},
                      {"label": "Ejido de Mozoquilpan", "value": "C67548"},
                      {"label": "Ejido de San Mateo Coapexco",
                       "value": "C67549"},
                      {"label": "Ejido del Tejocote", "value": "C67550"},
                      {"label": "Ejido del Tunal Nenaxi", "value": "C67551"},
                      {"label": "Ejido el Castillo", "value": "C67552"},
                      {"label": "Ejido la Soledad", "value": "C67553"},
                      {"label": "Ejido Loma de Malacota", "value": "C67554"},
                      {"label": "Ejido Miraflores", "value": "C67555"},
                      {"label": "Ejido Palma (Ejido San Francisco)",
                       "value": "C67556"},
                      {"label": "Ejido San Cristobal", "value": "C67557"},
                      {"label": "Ejido San Diego", "value": "C67558"},
                      {"label": "Ejido San Lorenzo Cuauhtenco",
                       "value": "C67559"},
                      {"label": "El aguila (La Mesa)", "value": "C67560"},
                      {"label": "El Arco", "value": "C67561"},
                      {"label": "El Cabi", "value": "C67562"},
                      {"label": "El Carmen (El Desierto del Carmen)",
                       "value": "C67563"},
                      {"label": "El Carmen Ocotepec", "value": "C67564"},
                      {"label": "El Cerrillo Vista Hermosa", "value": "C67565"},
                      {"label": "El Cerrito", "value": "C67566"},
                      {"label": "El Colorado", "value": "C67567"},
                      {"label": "El Coloso", "value": "C67568"},
                      {"label": "El Contadero de Matamoros", "value": "C67569"},
                      {"label": "El Coporo", "value": "C67570"},
                      {"label": "El Curtidor", "value": "C67571"},
                      {"label": "El Esclavo", "value": "C67572"},
                      {"label": "El Espinal", "value": "C67573"},
                      {"label": "El Habillal", "value": "C67574"},
                      {"label": "El Hielo", "value": "C67575"},
                      {"label": "El Jacal", "value": "C67576"},
                      {"label": "El Llano del Compromiso", "value": "C67577"},
                      {"label": "El Llano Santa Maria", "value": "C67578"},
                      {"label": "El Magueyal", "value": "C67579"},
                      {"label": "El Mogote", "value": "C67580"},
                      {"label": "El Obraje", "value": "C67581"},
                      {"label": "El Ocotal", "value": "C67582"},
                      {"label": "El Oro de Hidalgo", "value": "C67583"},
                      {"label": "El Palacio", "value": "C67584"},
                      {"label": "El Palmito", "value": "C67585"},
                      {"label": "El Pedregal de Guadalupe Hidalgo",
                       "value": "C67586"},
                      {"label": "El Pilar", "value": "C67587"},
                      {"label": "El Pino", "value": "C67588"},
                      {"label": "El Plan", "value": "C67589"},
                      {"label": "El Porvenir I", "value": "C67590"},
                      {"label": "El Potrero", "value": "C67591"},
                      {"label": "El Potrero de San Diego", "value": "C67592"},
                      {"label": "El Progreso Hidalgo", "value": "C67593"},
                      {"label": "El Puerto Magu", "value": "C67594"},
                      {"label": "El Recodo de San Jose Axalco",
                       "value": "C67595"},
                      {"label": "El Rincon de la Candelaria",
                       "value": "C67596"},
                      {"label": "El Rincon de los Perales", "value": "C67597"},
                      {"label": "El Rosal", "value": "C67598"},
                      {"label": "El Salitre", "value": "C67599"},
                      {"label": "El Tepetatal", "value": "C67600"},
                      {"label": "El Terrero", "value": "C67601"},
                      {"label": "Emiliano Zapata", "value": "C67602"},
                      {"label": "Emiliano Zapata (San Jose Bata)",
                       "value": "C67603"},
                      {"label": "Emiliano Zapata (Santo Domingo)",
                       "value": "C67604"},
                      {"label": "Emilio Portes Gil", "value": "C67605"},
                      {"label": "Epitacio Huerta", "value": "C67606"},
                      {"label": "Etucuaro", "value": "C67607"},
                      {"label": "Ex-Hacienda de Guadalupe", "value": "C67608"},
                      {"label": "Ex-hacienda de Xalpa", "value": "C67609"},
                      {"label": "Ex-Hacienda Santa Ines", "value": "C67610"},
                      {"label": "Ex-Rancho San Dimas", "value": "C67611"},
                      {"label": "Fabrica Concepcion", "value": "C67612"},
                      {"label": "Fraccion San Roque (El Prieto)",
                       "value": "C67613"},
                      {"label": "Fraccionamiento Colinas del Sol",
                       "value": "C67614"},
                      {"label": "Fraccionamiento Hacienda del Bosque",
                       "value": "C67615"},
                      {"label": "Fraccionamiento la Trinidad",
                       "value": "C67616"},
                      {"label": "Fraccionamiento Real de San Pablo",
                       "value": "C67617"},
                      {"label": "Fraccionamiento Rinconada del Valle",
                       "value": "C67618"},
                      {"label": "Fraccionamiento y Club de Golf los Encinos",
                       "value": "C67619"},
                      {"label": "Fresno Nichi", "value": "C67620"},
                      {"label": "Fuentes del Valle", "value": "C67621"},
                      {"label": "Galaxias Toluca", "value": "C67622"},
                      {"label": "Galeana", "value": "C67623"},
                      {"label": "Ganzda", "value": "C67624"},
                      {"label": "Granjas Ampliacion Santa Rosa",
                       "value": "C67625"},
                      {"label": "Guadalupe Buenavista", "value": "C67626"},
                      {"label": "Guadalupe Cachi", "value": "C67627"},
                      {"label": "Guadalupe Cote", "value": "C67628"},
                      {"label": "Guadalupe Totoltepec", "value": "C67629"},
                      {"label": "Gunyo Poniente (San Jose Gunyo)",
                       "value": "C67630"},
                      {"label": "Huancito", "value": "C67631"},
                      {"label": "Huehuetoca", "value": "C67632"},
                      {"label": "Huemetla", "value": "C67633"},
                      {"label": "Hueypoxtla", "value": "C67634"},
                      {"label": "Huilango", "value": "C67635"},
                      {"label": "Huitzoltepec", "value": "C67636"},
                      {"label": "Huixquilucan", "value": "C67637"},
                      {"label": "Huixquilucan de Degollado", "value": "C67638"},
                      {"label": "Ignacio Allende", "value": "C67639"},
                      {"label": "Ixtapaluca", "value": "C67640"},
                      {"label": "Ixtapan de la Sal", "value": "C67641"},
                      {"label": "Ixtapan del Oro", "value": "C67642"},
                      {"label": "Ixtlahuaca de Cuauhtemoc", "value": "C67643"},
                      {"label": "Ixtlahuaca de Rayon", "value": "C67644"},
                      {"label": "Jacona de Plancarte", "value": "C67645"},
                      {"label": "Jalmolonga (La Hacienda)", "value": "C67646"},
                      {"label": "Jalpa de los Banos", "value": "C67647"},
                      {"label": "Jaltenco", "value": "C67648"},
                      {"label": "Janambo", "value": "C67649"},
                      {"label": "Janamuato", "value": "C67650"},
                      {"label": "Jesus Carranza (Rancho de Jesus)",
                       "value": "C67651"},
                      {"label": "Jesus del Monte", "value": "C67652"},
                      {"label": "Jesus Maria", "value": "C67653"},
                      {"label": "Jicaltepec Autopan", "value": "C67654"},
                      {"label": "Jicaltepec Cuexcontitlan", "value": "C67655"},
                      {"label": "Jilotepec de Molina Enriquez",
                       "value": "C67656"},
                      {"label": "Jilotzingo", "value": "C67657"},
                      {"label": "Jiquipilco", "value": "C67658"},
                      {"label": "Jocotitlan", "value": "C67659"},
                      {"label": "Joquicingo", "value": "C67660"},
                      {"label": "Jorge Jimenez Cantu", "value": "C67661"},
                      {"label": "Jorobas", "value": "C67662"},
                      {"label": "Juchitepec", "value": "C67663"},
                      {"label": "La Albarrada (San Francisco la Albarrada)",
                       "value": "C67664"},
                      {"label": "La Cabecera", "value": "C67665"},
                      {"label": "La Cabecera Concepcion", "value": "C67666"},
                      {"label": "La Calle", "value": "C67667"},
                      {"label": "La Campanilla", "value": "C67668"},
                      {"label": "La Canada", "value": "C67669"},
                      {"label": "La Candelaria Tlapala", "value": "C67670"},
                      {"label": "La Cantera", "value": "C67671"},
                      {"label": "La Caridad", "value": "C67672"},
                      {"label": "La Cienega", "value": "C67673"},
                      {"label": "La Colonia", "value": "C67674"},
                      {"label": "La Compania", "value": "C67675"},
                      {"label": "La Comunidad", "value": "C67676"},
                      {"label": "La Concepcion", "value": "C67677"},
                      {"label": "La Concepcion Coatipac (La Conchita)",
                       "value": "C67678"},
                      {"label": "La Concepcion de Hidalgo", "value": "C67679"},
                      {"label": "La Concepcion de los Banos",
                       "value": "C67680"},
                      {"label": "La Concepcion Enyege", "value": "C67681"},
                      {"label": "La Concepcion Xochicuautla",
                       "value": "C67682"},
                      {"label": "La Constitucion Toltepec", "value": "C67683"},
                      {"label": "La Cruz y Carrizal", "value": "C67684"},
                      {"label": "La Esperanza", "value": "C67685"},
                      {"label": "La Estancia Sector Uno (La Estancia)",
                       "value": "C67686"},
                      {"label": "La Finca", "value": "C67687"},
                      {"label": "La Glorieta", "value": "C67688"},
                      {"label": "La Guadalupana", "value": "C67689"},
                      {"label": "La Herradura", "value": "C67690"},
                      {"label": "La Joya", "value": "C67691"},
                      {"label": "La Loma", "value": "C67692"},
                      {"label": "La Loma Cuexcontitlan", "value": "C67693"},
                      {"label": "La Magdalena", "value": "C67694"},
                      {"label": "La Magdalena Chichicaspa", "value": "C67695"},
                      {"label": "La Magdalena de los Reyes (La Magdalena)",
                       "value": "C67696"},
                      {"label": "La Magdalena Tenexpan", "value": "C67697"},
                      {"label": "La Mesa", "value": "C67698"},
                      {"label": "La Mesa de Chosto", "value": "C67699"},
                      {"label": "La Pastoria", "value": "C67700"},
                      {"label": "La Planada", "value": "C67701"},
                      {"label": "La Planada (El Arenal)", "value": "C67702"},
                      {"label": "La Presita Segundo Cuartel",
                       "value": "C67703"},
                      {"label": "La Providencia", "value": "C67704"},
                      {"label": "La Puerta del Pilar", "value": "C67705"},
                      {"label": "La Purificacion", "value": "C67706"},
                      {"label": "La Rosa", "value": "C67707"},
                      {"label": "La Soledad Barrio", "value": "C67708"},
                      {"label": "La Trinidad", "value": "C67709"},
                      {"label": "La Y", "value": "C67710"},
                      {"label": "Laguna de Tabernillas (El Resbaloso)",
                       "value": "C67711"},
                      {"label": "Las Huertas", "value": "C67712"},
                      {"label": "Las Manzanas", "value": "C67713"},
                      {"label": "Las Penas", "value": "C67714"},
                      {"label": "Las Pintas", "value": "C67715"},
                      {"label": "Las Ranas", "value": "C67716"},
                      {"label": "Las Rosas", "value": "C67717"},
                      {"label": "Lerma de Villada", "value": "C67718"},
                      {"label": "Llano de la Y", "value": "C67719"},
                      {"label": "Llano de las Flores (Barrio del Hueso)",
                       "value": "C67720"},
                      {"label": "Llano de Zacapexco", "value": "C67721"},
                      {"label": "Llano Grande", "value": "C67722"},
                      {"label": "Loma Alta", "value": "C67723"},
                      {"label": "Loma Alta Taxhimay", "value": "C67724"},
                      {"label": "Loma Bonita", "value": "C67725"},
                      {"label": "Loma de Guadalupe (La Biznaga)",
                       "value": "C67726"},
                      {"label": "Loma de Juarez", "value": "C67727"},
                      {"label": "Loma de San Francisco", "value": "C67728"},
                      {"label": "Loma de San Jose", "value": "C67729"},
                      {"label": "Loma de San Miguel", "value": "C67730"},
                      {"label": "Loma del Rio", "value": "C67731"},
                      {"label": "Loma Larga", "value": "C67732"},
                      {"label": "Loma Larga (Barrio de Loma Larga)",
                       "value": "C67733"},
                      {"label": "Lomas de Altavista", "value": "C67734"},
                      {"label": "Lomas de San Pablo", "value": "C67735"},
                      {"label": "Lomas de San Sebastian", "value": "C67736"},
                      {"label": "Lomas de Tenopalco", "value": "C67737"},
                      {"label": "Los Arana", "value": "C67738"},
                      {"label": "Los Berros", "value": "C67739"},
                      {"label": "Los Hucuares", "value": "C67740"},
                      {"label": "Los Nogales", "value": "C67741"},
                      {"label": "Los Remedios", "value": "C67742"},
                      {"label": "Los Reyes", "value": "C67743"},
                      {"label": "Los Reyes Acaquilpan", "value": "C67744"},
                      {"label": "Los Reyes Acatlixhuayan", "value": "C67745"},
                      {"label": "Los Saucos", "value": "C67746"},
                      {"label": "Luvianos", "value": "C67747"},
                      {"label": "Magdalena Atlicpac", "value": "C67748"},
                      {"label": "Malinalco", "value": "C67749"},
                      {"label": "Manto del Rio Ejido", "value": "C67750"},
                      {"label": "Manzana Quinta (La Canada)",
                       "value": "C67751"},
                      {"label": "Manzana Segunda", "value": "C67752"},
                      {"label": "Manzana Sexta Parte Centro",
                       "value": "C67753"},
                      {"label": "Manzana Tercera de Santa Cruz Tepexpan",
                       "value": "C67754"},
                      {"label": "Mavoro", "value": "C67755"},
                      {"label": "Maxtleca de Galeana", "value": "C67756"},
                      {"label": "Mayorazgo de Leon", "value": "C67757"},
                      {"label": "Melchor Ocampo", "value": "C67758"},
                      {"label": "Metepec", "value": "C67759"},
                      {"label": "Mexicaltzingo", "value": "C67760"},
                      {"label": "Mextepec", "value": "C67761"},
                      {"label": "Mezapa la Fabrica", "value": "C67762"},
                      {"label": "Mina Mexico", "value": "C67763"},
                      {"label": "Mina Vieja", "value": "C67764"},
                      {"label": "Miraflores", "value": "C67765"},
                      {"label": "Molino Abajo", "value": "C67766"},
                      {"label": "Monte Calvario", "value": "C67767"},
                      {"label": "Montecillo", "value": "C67768"},
                      {"label": "Morelos", "value": "C67769"},
                      {"label": "Naucalpan de Juarez", "value": "C67770"},
                      {"label": "Nepantla de Sor Juana Ines de la Cruz",
                       "value": "C67771"},
                      {"label": "Nicolas Romero", "value": "C67772"},
                      {"label": "Nopaltepec", "value": "C67773"},
                      {"label": "Nueva Ameyalco", "value": "C67774"},
                      {"label": "Nueva Santa Rosa", "value": "C67775"},
                      {"label": "Ocopulco", "value": "C67776"},
                      {"label": "Ocoyoacac", "value": "C67777"},
                      {"label": "Octeyuco Dos Mil", "value": "C67778"},
                      {"label": "Ocuilan", "value": "C67779"},
                      {"label": "Ocuilan de Arteaga", "value": "C67780"},
                      {"label": "Ocumicho", "value": "C67781"},
                      {"label": "Ojo de Agua", "value": "C67782"},
                      {"label": "Otumba", "value": "C67783"},
                      {"label": "Otzoloapan", "value": "C67784"},
                      {"label": "Otzolotepec", "value": "C67785"},
                      {"label": "Oxtotipac", "value": "C67786"},
                      {"label": "Ozumba de Alzate", "value": "C67787"},
                      {"label": "Palizada", "value": "C67788"},
                      {"label": "Palmar Chico", "value": "C67789"},
                      {"label": "Palmillas", "value": "C67790"},
                      {"label": "Palos Amarillos (Palos Amarillos Yebucivi)",
                       "value": "C67791"},
                      {"label": "Papalotla", "value": "C67792"},
                      {"label": "Paraje el Mirador", "value": "C67793"},
                      {"label": "Paraje la Pera", "value": "C67794"},
                      {"label": "Paraje Trejo (El Chaparral)",
                       "value": "C67795"},
                      {"label": "Paseos de San Juan", "value": "C67796"},
                      {"label": "Pathe", "value": "C67797"},
                      {"label": "Playa Azul", "value": "C67798"},
                      {"label": "Plaza Nueva", "value": "C67799"},
                      {"label": "Poligonos", "value": "C67800"},
                      {"label": "Polotitlan", "value": "C67801"},
                      {"label": "Polotitlan de la Ilustracion",
                       "value": "C67802"},
                      {"label": "Polvillos (San Bartolo Quinta Seccion)",
                       "value": "C67803"},
                      {"label": "Popo Park", "value": "C67804"},
                      {"label": "Porfirio Diaz", "value": "C67805"},
                      {"label": "Pothe", "value": "C67806"},
                      {"label": "Potreros", "value": "C67807"},
                      {"label": "Prados de San Juan", "value": "C67808"},
                      {"label": "Prados San Francisco", "value": "C67809"},
                      {"label": "Primera de Analco", "value": "C67810"},
                      {"label": "Profesor Carlos Hank Gonzalez",
                       "value": "C67811"},
                      {"label": "Progreso Industrial", "value": "C67812"},
                      {"label": "Pueblo Nuevo", "value": "C67813"},
                      {"label": "Pueblo Nuevo Tlalmimilolpan",
                       "value": "C67814"},
                      {"label": "Puentecillas", "value": "C67815"},
                      {"label": "Puerto Escondido (Tepeolulco Puerto Escondido)",
                       "value": "C67816"},
                      {"label": "Purisima Concepcion Mayorazgo",
                       "value": "C67817"},
                      {"label": "Quetzalapa", "value": "C67818"},
                      {"label": "Quinto Barrio (Ejido Cahuacan)",
                       "value": "C67819"},
                      {"label": "Rancho Alegre", "value": "C67820"},
                      {"label": "Rincon de Aguirre", "value": "C67821"},
                      {"label": "Rincon de Guadalupe", "value": "C67822"},
                      {"label": "Rincon de Jaimes", "value": "C67823"},
                      {"label": "Rincon de los Pirules", "value": "C67824"},
                      {"label": "Rincon Verde", "value": "C67825"},
                      {"label": "Rio Frio de Juarez", "value": "C67826"},
                      {"label": "Salitrillo", "value": "C67827"},
                      {"label": "San Agustin", "value": "C67828"},
                      {"label": "San Agustin Berros", "value": "C67829"},
                      {"label": "San Agustin Buenavista", "value": "C67830"},
                      {"label": "San Agustin Citlali", "value": "C67831"},
                      {"label": "San Agustin de las Palmas", "value": "C67832"},
                      {"label": "San Agustin Huitzizilapan", "value": "C67833"},
                      {"label": "San Agustin Mextepec", "value": "C67834"},
                      {"label": "San Agustin Mimbres", "value": "C67835"},
                      {"label": "San Alejo", "value": "C67836"},
                      {"label": "San Andres de las Peras", "value": "C67837"},
                      {"label": "San Andres de los Gama", "value": "C67838"},
                      {"label": "San Andres del Pedregal", "value": "C67839"},
                      {"label": "San Andres Metla", "value": "C67840"},
                      {"label": "San Andres Nicolas Bravo", "value": "C67841"},
                      {"label": "San Andres Ocotepec", "value": "C67842"},
                      {"label": "San Andres Tepetitlan", "value": "C67843"},
                      {"label": "San Andres Timilpan", "value": "C67844"},
                      {"label": "San Andres Tlalamac", "value": "C67845"},
                      {"label": "San Antonio Acahualco", "value": "C67846"},
                      {"label": "San Antonio Bonixi", "value": "C67847"},
                      {"label": "San Antonio Buenavista", "value": "C67848"},
                      {"label": "San Antonio de la Laguna", "value": "C67849"},
                      {"label": "San Antonio de las Huertas",
                       "value": "C67850"},
                      {"label": "San Antonio de las Palmas", "value": "C67851"},
                      {"label": "San Antonio del Rosario", "value": "C67852"},
                      {"label": "San Antonio el Llanito", "value": "C67853"},
                      {"label": "San Antonio Enchisi", "value": "C67854"},
                      {"label": "San Antonio Guaracha", "value": "C67855"},
                      {"label": "San Antonio la Isla", "value": "C67856"},
                      {"label": "San Antonio Nixini", "value": "C67857"},
                      {"label": "San Antonio Solis", "value": "C67858"},
                      {"label": "San Antonio Tlaltecahuacan",
                       "value": "C67859"},
                      {"label": "San Antonio Xahuento", "value": "C67860"},
                      {"label": "San Bartolito Tlaltelolco", "value": "C67861"},
                      {"label": "San Bartolo", "value": "C67862"},
                      {"label": "San Bartolo Cuautlalpan", "value": "C67863"},
                      {"label": "San Bartolo del Llano", "value": "C67864"},
                      {"label": "San Bartolo del Llano (San Isidro)",
                       "value": "C67865"},
                      {"label": "San Bartolo del Progreso", "value": "C67866"},
                      {"label": "San Bartolo el Viejo", "value": "C67867"},
                      {"label": "San Bartolo Lanzados", "value": "C67868"},
                      {"label": "San Bartolo Morelos", "value": "C67869"},
                      {"label": "San Bartolo Oxtotitlan", "value": "C67870"},
                      {"label": "San Bartolome", "value": "C67871"},
                      {"label": "San Bartolome Atlatlahuca", "value": "C67872"},
                      {"label": "San Bartolome Coatepec", "value": "C67873"},
                      {"label": "San Bernardino", "value": "C67874"},
                      {"label": "San Bernardo Tlalmimilolpan",
                       "value": "C67875"},
                      {"label": "San Buenaventura", "value": "C67876"},
                      {"label": "San Carlos Autopan", "value": "C67877"},
                      {"label": "San Cristobal Nexquipayac", "value": "C67878"},
                      {"label": "San Diego", "value": "C67879"},
                      {"label": "San Diego del Cerrito", "value": "C67880"},
                      {"label": "San Diego Huehuecalco", "value": "C67881"},
                      {"label": "San Diego la Huerta", "value": "C67882"},
                      {"label": "San Diego los Padres Cuexcontitlan Seccion 5 B",
                       "value": "C67883"},
                      {"label": "San Felipe Coamango", "value": "C67884"},
                      {"label": "San Felipe del Progreso", "value": "C67885"},
                      {"label": "San Felipe el Mirasol", "value": "C67886"},
                      {"label": "San Felipe Pueblo Nuevo", "value": "C67887"},
                      {"label": "San Felipe Santiago", "value": "C67888"},
                      {"label": "San Felipe Tlalmimilolpan", "value": "C67889"},
                      {"label": "San Francisco", "value": "C67890"},
                      {"label": "San Francisco Acuautla", "value": "C67891"},
                      {"label": "San Francisco Ayotuzco", "value": "C67892"},
                      {"label": "San Francisco Chalchihuapan",
                       "value": "C67893"},
                      {"label": "San Francisco Cheje", "value": "C67894"},
                      {"label": "San Francisco Cuaxusco", "value": "C67895"},
                      {"label": "San Francisco de Guzman", "value": "C67896"},
                      {"label": "San Francisco Magu", "value": "C67897"},
                      {"label": "San Francisco Mazapa", "value": "C67898"},
                      {"label": "San Francisco Mihualtepec", "value": "C67899"},
                      {"label": "San Francisco Oxtotilpan", "value": "C67900"},
                      {"label": "San Francisco Putla", "value": "C67901"},
                      {"label": "San Francisco Solis", "value": "C67902"},
                      {"label": "San Francisco Tenopalco", "value": "C67903"},
                      {"label": "San Francisco Tepeolulco", "value": "C67904"},
                      {"label": "San Francisco Tepexoxica", "value": "C67905"},
                      {"label": "San Francisco Tlalcilalcalpan",
                       "value": "C67906"},
                      {"label": "San Francisco Tlaltica", "value": "C67907"},
                      {"label": "San Francisco Xochicuautla",
                       "value": "C67908"},
                      {"label": "San Gabriel Ixtla", "value": "C67909"},
                      {"label": "San Gabriel Zepayautla", "value": "C67910"},
                      {"label": "San Gaspar", "value": "C67911"},
                      {"label": "San Gaspar Tonatico", "value": "C67912"},
                      {"label": "San Gregorio Cuautzingo", "value": "C67913"},
                      {"label": "San Ildefonso", "value": "C67914"},
                      {"label": "San Isidro", "value": "C67915"},
                      {"label": "San Isidro (El Reservado)", "value": "C67916"},
                      {"label": "San Isidro Boxipe", "value": "C67917"},
                      {"label": "San Jacinto", "value": "C67918"},
                      {"label": "San Jeronimo", "value": "C67919"},
                      {"label": "San Jeronimo Amanalco", "value": "C67920"},
                      {"label": "San Jeronimo Bonchete", "value": "C67921"},
                      {"label": "San Jeronimo Cuatro Vientos",
                       "value": "C67922"},
                      {"label": "San Jeronimo de los Jarros",
                       "value": "C67923"},
                      {"label": "San Jeronimo Ixtapantongo", "value": "C67924"},
                      {"label": "San Jeronimo Totoltepec", "value": "C67925"},
                      {"label": "San Joaquin Coapango", "value": "C67926"},
                      {"label": "San Joaquin del Monte", "value": "C67927"},
                      {"label": "San Jorge Pueblo Nuevo", "value": "C67928"},
                      {"label": "San Jose Barbabosa", "value": "C67929"},
                      {"label": "San Jose Boqui", "value": "C67930"},
                      {"label": "San Jose Chalmita", "value": "C67931"},
                      {"label": "San Jose del Progreso", "value": "C67932"},
                      {"label": "San Jose del Puente", "value": "C67933"},
                      {"label": "San Jose del Rincon Centro",
                       "value": "C67934"},
                      {"label": "San Jose del Sitio", "value": "C67935"},
                      {"label": "San Jose del Tunal", "value": "C67936"},
                      {"label": "San Jose el Cuartel", "value": "C67937"},
                      {"label": "San Jose el Vidrio", "value": "C67938"},
                      {"label": "San Jose Guadalupe Otzacatipan",
                       "value": "C67939"},
                      {"label": "San Jose las Lomas", "value": "C67940"},
                      {"label": "San Jose las Palmas", "value": "C67941"},
                      {"label": "San Jose Mezapa Seccion I", "value": "C67942"},
                      {"label": "San Jose Pathe", "value": "C67943"},
                      {"label": "San Jose Tejamanil", "value": "C67944"},
                      {"label": "San Jose Teneria (Teneria)",
                       "value": "C67945"},
                      {"label": "San Jose Tlacotitlan", "value": "C67946"},
                      {"label": "San Jose Toxi", "value": "C67947"},
                      {"label": "San Jose Villa de Allende", "value": "C67948"},
                      {"label": "San Juan", "value": "C67949"},
                      {"label": "San Juan Atezcapan", "value": "C67950"},
                      {"label": "San Juan Coajomulco", "value": "C67951"},
                      {"label": "San Juan Cote Ejido", "value": "C67952"},
                      {"label": "San Juan Coxtocan", "value": "C67953"},
                      {"label": "San Juan Daxthi", "value": "C67954"},
                      {"label": "San Juan de las Huertas", "value": "C67955"},
                      {"label": "San Juan de las Manzanas", "value": "C67956"},
                      {"label": "San Juan de los Jarros", "value": "C67957"},
                      {"label": "San Juan Jalpa Centro", "value": "C67958"},
                      {"label": "San Juan Tehuixtitlan", "value": "C67959"},
                      {"label": "San Juan Tepecoculco", "value": "C67960"},
                      {"label": "San Juan Tlacotompa (Tlacotompa)",
                       "value": "C67961"},
                      {"label": "San Juan Tomasquillo Herradura",
                       "value": "C67962"},
                      {"label": "San Juan Totolapan", "value": "C67963"},
                      {"label": "San Juan Tuxtepec", "value": "C67964"},
                      {"label": "San Juan Xoconusco", "value": "C67965"},
                      {"label": "San Juan y San Pedro Tezompa",
                       "value": "C67966"},
                      {"label": "San Juanico Sector Uno", "value": "C67967"},
                      {"label": "San Lorenzo Huehuetitlan", "value": "C67968"},
                      {"label": "San Lorenzo Malacota", "value": "C67969"},
                      {"label": "San Lorenzo Tlacotepec", "value": "C67970"},
                      {"label": "San Lorenzo Tlalmimilolpan",
                       "value": "C67971"},
                      {"label": "San Lorenzo Toxico", "value": "C67972"},
                      {"label": "San Lucas", "value": "C67973"},
                      {"label": "San Lucas Amalinalco", "value": "C67974"},
                      {"label": "San Lucas del Pulque", "value": "C67975"},
                      {"label": "San Lucas Huitzilhuacan", "value": "C67976"},
                      {"label": "San Lucas Ocotepec", "value": "C67977"},
                      {"label": "San Lucas Texcaltitlan", "value": "C67978"},
                      {"label": "San Lucas Totolmaloya", "value": "C67979"},
                      {"label": "San Luis Anahuac (Toriles)",
                       "value": "C67980"},
                      {"label": "San Luis Boro", "value": "C67981"},
                      {"label": "San Luis Taxhimay", "value": "C67982"},
                      {"label": "San Marcos de la Loma", "value": "C67983"},
                      {"label": "San Marcos Huixtoco", "value": "C67984"},
                      {"label": "San Marcos Nepantla", "value": "C67985"},
                      {"label": "San Marcos Tecomaxusco", "value": "C67986"},
                      {"label": "San Marcos Tlazalpan", "value": "C67987"},
                      {"label": "San Marcos Yachihuacaltepec",
                       "value": "C67988"},
                      {"label": "San Martin", "value": "C67989"},
                      {"label": "San Martin Ahuatepec", "value": "C67990"},
                      {"label": "San Martin Azcatepec", "value": "C67991"},
                      {"label": "San Martin Cachihuapan", "value": "C67992"},
                      {"label": "San Martin Coapaxtongo", "value": "C67993"},
                      {"label": "San Martin Cuautlalpan", "value": "C67994"},
                      {"label": "San Martin de las Piramides",
                       "value": "C67995"},
                      {"label": "San Martin Ejido", "value": "C67996"},
                      {"label": "San Martin Obispo (San Martin San Pedro)",
                       "value": "C67997"},
                      {"label": "San Martin Tequesquipan (Tequesquipan)",
                       "value": "C67998"},
                      {"label": "San Martin Toltepec", "value": "C67999"},
                      {"label": "San Martin Tuchicuitlapilco",
                       "value": "C68000"},
                      {"label": "San Mateo", "value": "C68001"},
                      {"label": "San Mateo Almomoloha", "value": "C68002"},
                      {"label": "San Mateo Atenco", "value": "C68003"},
                      {"label": "San Mateo Capulhuac", "value": "C68004"},
                      {"label": "San Mateo Coapexco", "value": "C68005"},
                      {"label": "San Mateo el Viejo", "value": "C68006"},
                      {"label": "San Mateo Huitzilzingo", "value": "C68007"},
                      {"label": "San Mateo Ixtacalco", "value": "C68008"},
                      {"label": "San Mateo Ixtlahuaca", "value": "C68009"},
                      {"label": "San Mateo Mozoquilpan", "value": "C68010"},
                      {"label": "San Mateo Tecalco", "value": "C68011"},
                      {"label": "San Mateo Texcalyacac", "value": "C68012"},
                      {"label": "San Mateo Tlalchichilpan", "value": "C68013"},
                      {"label": "San Mateo Xoloc", "value": "C68014"},
                      {"label": "San Matias Cuijingo", "value": "C68015"},
                      {"label": "San Miguel", "value": "C68016"},
                      {"label": "San Miguel Agua Bendita", "value": "C68017"},
                      {"label": "San Miguel Almoloyan", "value": "C68018"},
                      {"label": "San Miguel Atlamajac", "value": "C68019"},
                      {"label": "San Miguel Coatlinchan", "value": "C68020"},
                      {"label": "San Miguel del Centro", "value": "C68021"},
                      {"label": "San Miguel Enyege", "value": "C68022"},
                      {"label": "San Miguel Hila", "value": "C68023"},
                      {"label": "San Miguel Ixtapan", "value": "C68024"},
                      {"label": "San Miguel Jaltocan", "value": "C68025"},
                      {"label": "San Miguel Laderas", "value": "C68026"},
                      {"label": "San Miguel Mimlapan", "value": "C68027"},
                      {"label": "San Miguel Oxtotilpan", "value": "C68028"},
                      {"label": "San Miguel Tecomatlan", "value": "C68029"},
                      {"label": "San Miguel Tecpan", "value": "C68030"},
                      {"label": "San Miguel Tenochtitlan", "value": "C68031"},
                      {"label": "San Miguel Tlaixpan", "value": "C68032"},
                      {"label": "San Miguel Totocuitlapilco",
                       "value": "C68033"},
                      {"label": "San Miguel Xoltepec", "value": "C68034"},
                      {"label": "San Miguel Yuxtepec", "value": "C68035"},
                      {"label": "San Nicolas", "value": "C68036"},
                      {"label": "San Nicolas Amealco", "value": "C68037"},
                      {"label": "San Nicolas el Oro", "value": "C68038"},
                      {"label": "San Nicolas Guadalupe", "value": "C68039"},
                      {"label": "San Nicolas Tolentino", "value": "C68040"},
                      {"label": "San Pablo Atlazalpan", "value": "C68041"},
                      {"label": "San Pablo Atotonilco", "value": "C68042"},
                      {"label": "San Pablo de las Salinas", "value": "C68043"},
                      {"label": "San Pablo de los Gallos", "value": "C68044"},
                      {"label": "San Pablo de los Remedios", "value": "C68045"},
                      {"label": "San Pablo Huantepec", "value": "C68046"},
                      {"label": "San Pablo Ixayoc", "value": "C68047"},
                      {"label": "San Pablo Ixquitlan", "value": "C68048"},
                      {"label": "San Pablo Tecalco", "value": "C68049"},
                      {"label": "San Pablo Tejalpa", "value": "C68050"},
                      {"label": "San Pedro Abajo", "value": "C68051"},
                      {"label": "San Pedro Arriba", "value": "C68052"},
                      {"label": "San Pedro Arriba 3ra. Seccion",
                       "value": "C68053"},
                      {"label": "San Pedro Chiautzingo", "value": "C68054"},
                      {"label": "San Pedro Cholula", "value": "C68055"},
                      {"label": "San Pedro de la Hortaliza (Ejido Almoloyan)",
                       "value": "C68056"},
                      {"label": "San Pedro de los Banos", "value": "C68057"},
                      {"label": "San Pedro de los Metates", "value": "C68058"},
                      {"label": "San Pedro del Rincon", "value": "C68059"},
                      {"label": "San Pedro del Rosal", "value": "C68060"},
                      {"label": "San Pedro Denxhi Centro", "value": "C68061"},
                      {"label": "San Pedro el Alto", "value": "C68062"},
                      {"label": "San Pedro Huitzizilapan", "value": "C68063"},
                      {"label": "San Pedro Limon", "value": "C68064"},
                      {"label": "San Pedro Los Banos", "value": "C68065"},
                      {"label": "San Pedro Nexapa", "value": "C68066"},
                      {"label": "San Pedro Tarimbaro", "value": "C68067"},
                      {"label": "San Pedro Tejalpa", "value": "C68068"},
                      {"label": "San Pedro Tenayac", "value": "C68069"},
                      {"label": "San Pedro Tepetitlan", "value": "C68070"},
                      {"label": "San Rafael", "value": "C68071"},
                      {"label": "San Roque", "value": "C68072"},
                      {"label": "San Salvador Atenco", "value": "C68073"},
                      {"label": "San Salvador Tizatlalli", "value": "C68074"},
                      {"label": "San Sebastian", "value": "C68075"},
                      {"label": "San Sebastian Buenos Aires",
                       "value": "C68076"},
                      {"label": "San Sebastian Chimalpa", "value": "C68077"},
                      {"label": "San Sebastian Xolalpa", "value": "C68078"},
                      {"label": "San Simon de Guerrero", "value": "C68079"},
                      {"label": "San Simon de la Laguna", "value": "C68080"},
                      {"label": "San Simon el Alto", "value": "C68081"},
                      {"label": "San Simonito", "value": "C68082"},
                      {"label": "San Vicente (Calpulalpan Tercera Manzana)",
                       "value": "C68083"},
                      {"label": "San Vicente Chimalhuacan", "value": "C68084"},
                      {"label": "Sanata Lucia", "value": "C68085"},
                      {"label": "Santa Ana", "value": "C68086"},
                      {"label": "Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)",
                       "value": "C68087"},
                      {"label": "Santa Ana Ixtlahuatzingo (Santa Ana)",
                       "value": "C68088"},
                      {"label": "Santa Ana Jilotzingo", "value": "C68089"},
                      {"label": "Santa Ana la Ladera", "value": "C68090"},
                      {"label": "Santa Ana Mayorazgo", "value": "C68091"},
                      {"label": "Santa Ana Nextlalpan", "value": "C68092"},
                      {"label": "Santa Ana Nichi Ejido", "value": "C68093"},
                      {"label": "Santa Ana Zicatecoyan", "value": "C68094"},
                      {"label": "Santa Catarina", "value": "C68095"},
                      {"label": "Santa Catarina Ayotzingo", "value": "C68096"},
                      {"label": "Santa Catarina del Monte", "value": "C68097"},
                      {"label": "Santa Clara de Juarez", "value": "C68098"},
                      {"label": "Santa Cruz", "value": "C68099"},
                      {"label": "Santa Cruz Atizapan", "value": "C68100"},
                      {"label": "Santa Cruz Bombatevi", "value": "C68101"},
                      {"label": "Santa Cruz Cuauhtenco", "value": "C68102"},
                      {"label": "Santa Cruz del Monte", "value": "C68103"},
                      {"label": "Santa Cruz Huitzizilapan", "value": "C68104"},
                      {"label": "Santa Cruz Mextepec", "value": "C68105"},
                      {"label": "Santa Cruz Pueblo Nuevo (Pueblo Nuevo)",
                       "value": "C68106"},
                      {"label": "Santa Gertrudis", "value": "C68107"},
                      {"label": "Santa Isabel Chalma", "value": "C68108"},
                      {"label": "Santa Isabel Ixtapan", "value": "C68109"},
                      {"label": "Santa Juana Centro", "value": "C68110"},
                      {"label": "Santa Juana Primera Seccion",
                       "value": "C68111"},
                      {"label": "Santa Lucia", "value": "C68112"},
                      {"label": "Santa Maria", "value": "C68113"},
                      {"label": "Santa Maria Ajoloapan", "value": "C68114"},
                      {"label": "Santa Maria Aranzazu (Santa Maria)",
                       "value": "C68115"},
                      {"label": "Santa Maria Canchesda", "value": "C68116"},
                      {"label": "Santa Maria Chimalhuacan", "value": "C68117"},
                      {"label": "Santa Maria Citendeje", "value": "C68118"},
                      {"label": "Santa Maria Cuevas", "value": "C68119"},
                      {"label": "Santa Maria de Guadalupe", "value": "C68120"},
                      {"label": "Santa Maria del Llano", "value": "C68121"},
                      {"label": "Santa Maria del Monte", "value": "C68122"},
                      {"label": "Santa Maria Endare", "value": "C68123"},
                      {"label": "Santa Maria Huecatitla", "value": "C68124"},
                      {"label": "Santa Maria Huexoculco", "value": "C68125"},
                      {"label": "Santa Maria Jajalpa", "value": "C68126"},
                      {"label": "Santa Maria la Asuncion", "value": "C68127"},
                      {"label": "Santa Maria Magdalena Cahuacan",
                       "value": "C68128"},
                      {"label": "Santa Maria Mazatla", "value": "C68129"},
                      {"label": "Santa Maria Nativitas", "value": "C68130"},
                      {"label": "Santa Maria Palapa", "value": "C68131"},
                      {"label": "Santa Maria Pipioltepec (Pipioltepec)",
                       "value": "C68132"},
                      {"label": "Santa Maria Rayon", "value": "C68133"},
                      {"label": "Santa Maria Tecuanulco", "value": "C68134"},
                      {"label": "Santa Maria Tlalmimilolpan",
                       "value": "C68135"},
                      {"label": "Santa Maria Totoltepec", "value": "C68136"},
                      {"label": "Santa Maria Zolotepec", "value": "C68137"},
                      {"label": "Santa Martha", "value": "C68138"},
                      {"label": "Santa Monica", "value": "C68139"},
                      {"label": "Santa Rosa de Lima", "value": "C68140"},
                      {"label": "Santa Teresa", "value": "C68141"},
                      {"label": "Santiago Acutzilapan", "value": "C68142"},
                      {"label": "Santiago Casandeje", "value": "C68143"},
                      {"label": "Santiago Chimalpa (Chimalpa)",
                       "value": "C68144"},
                      {"label": "Santiago Citendeje", "value": "C68145"},
                      {"label": "Santiago Coachochitlan", "value": "C68146"},
                      {"label": "Santiago Cuautenco", "value": "C68147"},
                      {"label": "Santiago Cuautlalpan", "value": "C68148"},
                      {"label": "Santiago del Monte", "value": "C68149"},
                      {"label": "Santiago Huitlapaltepec", "value": "C68150"},
                      {"label": "Santiago Mamalhuazuca", "value": "C68151"},
                      {"label": "Santiago Oxtempan", "value": "C68152"},
                      {"label": "Santiago Oxtotitlan", "value": "C68153"},
                      {"label": "Santiago Tejocotillos", "value": "C68154"},
                      {"label": "Santiago Tepetitlan", "value": "C68155"},
                      {"label": "Santiago Tepopula", "value": "C68156"},
                      {"label": "Santiago Teyahualco", "value": "C68157"},
                      {"label": "Santiago Tianguistenco de Galeana",
                       "value": "C68158"},
                      {"label": "Santiago Tlacotepec", "value": "C68159"},
                      {"label": "Santiago Tolman", "value": "C68160"},
                      {"label": "Santiago Yancuitlalpan", "value": "C68161"},
                      {"label": "Santiago Yeche", "value": "C68162"},
                      {"label": "Santiago Zacualuca", "value": "C68163"},
                      {"label": "Santiaguito Tlalcilalcalli",
                       "value": "C68164"},
                      {"label": "Santo Domingo de Guzman", "value": "C68165"},
                      {"label": "Santo Domingo Shomege (Shomege)",
                       "value": "C68166"},
                      {"label": "Santo Tomas", "value": "C68167"},
                      {"label": "Santo Tomas Atzingo", "value": "C68168"},
                      {"label": "Santo Tomas Chiconautla", "value": "C68169"},
                      {"label": "Santo Tomas de los Platanos",
                       "value": "C68170"},
                      {"label": "Sebastian Lerdo de Tejada", "value": "C68171"},
                      {"label": "Seccion del Cerrito", "value": "C68172"},
                      {"label": "Sierra de Guadalupe", "value": "C68173"},
                      {"label": "Sitio Ejido", "value": "C68174"},
                      {"label": "Solalpan 1ra. Seccion", "value": "C68175"},
                      {"label": "Soyaniquilpan", "value": "C68176"},
                      {"label": "Sultepec", "value": "C68177"},
                      {"label": "Tamandaro", "value": "C68178"},
                      {"label": "Tanaquillo", "value": "C68179"},
                      {"label": "Tangancicuaro de Arista", "value": "C68180"},
                      {"label": "Tarecuato", "value": "C68181"},
                      {"label": "Tecamac", "value": "C68182"},
                      {"label": "Tecamac de Felipe Villanueva",
                       "value": "C68183"},
                      {"label": "Tecamachalco", "value": "C68184"},
                      {"label": "Tecaxic", "value": "C68185"},
                      {"label": "Techachaltitla", "value": "C68186"},
                      {"label": "Techichili", "value": "C68187"},
                      {"label": "Tecoac (Santa Maria Nativitas)",
                       "value": "C68188"},
                      {"label": "Tecomatepec", "value": "C68189"},
                      {"label": "Tejalpa", "value": "C68190"},
                      {"label": "Tejupilco", "value": "C68191"},
                      {"label": "Tejupilco de Hidalgo", "value": "C68192"},
                      {"label": "Temamatla", "value": "C68193"},
                      {"label": "Temascalapa", "value": "C68194"},
                      {"label": "Temascalcingo", "value": "C68195"},
                      {"label": "Temascaltepec", "value": "C68196"},
                      {"label": "Temascaltepec de Gonzalez", "value": "C68197"},
                      {"label": "Temoaya", "value": "C68198"},
                      {"label": "Tenancingo", "value": "C68199"},
                      {"label": "Tenancingo de Degollado", "value": "C68200"},
                      {"label": "Tenango de Arista", "value": "C68201"},
                      {"label": "Tenango del Aire", "value": "C68202"},
                      {"label": "Teneria", "value": "C68203"},
                      {"label": "Tenguecho", "value": "C68204"},
                      {"label": "Tenjay", "value": "C68205"},
                      {"label": "Teoloyucan", "value": "C68206"},
                      {"label": "Teotihuacan", "value": "C68207"},
                      {"label": "Teotihuacan de Arista", "value": "C68208"},
                      {"label": "Tepalcatepec", "value": "C68209"},
                      {"label": "Tepetitlan", "value": "C68210"},
                      {"label": "Tepetlaoxtoc", "value": "C68211"},
                      {"label": "Tepetlaoxtoc de Hidalgo", "value": "C68212"},
                      {"label": "Tepetlixpa", "value": "C68213"},
                      {"label": "Tepetongo", "value": "C68214"},
                      {"label": "Tepetzingo", "value": "C68215"},
                      {"label": "Tepexpan", "value": "C68216"},
                      {"label": "Tepotzotlan", "value": "C68217"},
                      {"label": "Tepoxtepec", "value": "C68218"},
                      {"label": "Tequexquinahuac", "value": "C68219"},
                      {"label": "Tequisistlan", "value": "C68220"},
                      {"label": "Tequisistlan Primero", "value": "C68221"},
                      {"label": "Tequixquiac", "value": "C68222"},
                      {"label": "Tercera Manzana de Zaragoza",
                       "value": "C68223"},
                      {"label": "Teremendo", "value": "C68224"},
                      {"label": "Texcaltitlan", "value": "C68225"},
                      {"label": "Texcalyacac", "value": "C68226"},
                      {"label": "Texcapilla", "value": "C68227"},
                      {"label": "Texcoco de Mora", "value": "C68228"},
                      {"label": "Tezoyuca", "value": "C68229"},
                      {"label": "Tierra Blanca", "value": "C68230"},
                      {"label": "Timilpan", "value": "C68231"},
                      {"label": "Tixmadeje Chiquito", "value": "C68232"},
                      {"label": "Tlacomulco", "value": "C68233"},
                      {"label": "Tlalmanalco", "value": "C68234"},
                      {"label": "Tlalnepantla", "value": "C68235"},
                      {"label": "Tlalnepantla de Baz", "value": "C68236"},
                      {"label": "Tlaltecahuacan", "value": "C68237"},
                      {"label": "Tlaminca", "value": "C68238"},
                      {"label": "Tlatlaya", "value": "C68239"},
                      {"label": "Tlazala de Fabela", "value": "C68240"},
                      {"label": "Tlazazalca", "value": "C68241"},
                      {"label": "Toluca", "value": "C68242"},
                      {"label": "Tonanitla", "value": "C68243"},
                      {"label": "Tonatico", "value": "C68244"},
                      {"label": "Totolmajac", "value": "C68245"},
                      {"label": "Transfiguracion", "value": "C68246"},
                      {"label": "Tres Estrellas", "value": "C68247"},
                      {"label": "Tres Mezquites", "value": "C68248"},
                      {"label": "Tulantongo", "value": "C68249"},
                      {"label": "Tultepec", "value": "C68250"},
                      {"label": "Tultitlan de Mariano Escobedo",
                       "value": "C68251"},
                      {"label": "Tupataro", "value": "C68252"},
                      {"label": "Turcio Segunda Seccion", "value": "C68253"},
                      {"label": "Unidad Acaquilpan", "value": "C68254"},
                      {"label": "Unidad Habitacional Santa Teresa",
                       "value": "C68255"},
                      {"label": "Unidad San Miguel Jagueyes",
                       "value": "C68256"},
                      {"label": "Uren", "value": "C68257"},
                      {"label": "Valle de Bravo", "value": "C68258"},
                      {"label": "Vare Chiquichuca", "value": "C68259"},
                      {"label": "Veintidos de Febrero", "value": "C68260"},
                      {"label": "Venta de Bravo", "value": "C68261"},
                      {"label": "Venta de Ocotillos", "value": "C68262"},
                      {"label": "Villa Cuauhtemoc", "value": "C68263"},
                      {"label": "Villa de Almoloya de Juarez",
                       "value": "C68264"},
                      {"label": "Villa del Carbon", "value": "C68265"},
                      {"label": "Villa Guerrero", "value": "C68266"},
                      {"label": "Villa Luvianos", "value": "C68267"},
                      {"label": "Villa Morelos", "value": "C68268"},
                      {"label": "Villa URBI del Rey", "value": "C68269"},
                      {"label": "Villa Victoria", "value": "C68270"},
                      {"label": "Villachuato", "value": "C68271"},
                      {"label": "Villas de la Laguna", "value": "C68272"},
                      {"label": "Villas del Campo", "value": "C68273"},
                      {"label": "Vista Hermosa", "value": "C68274"},
                      {"label": "Xalatlaco", "value": "C68275"},
                      {"label": "Xhimojay", "value": "C68276"},
                      {"label": "Xhitey", "value": "C68277"},
                      {"label": "Xhixhata", "value": "C68278"},
                      {"label": "Xico", "value": "C68279"},
                      {"label": "Xochimanca", "value": "C68280"},
                      {"label": "Xocotlan", "value": "C68281"},
                      {"label": "Xometla", "value": "C68282"},
                      {"label": "Xonacatlan", "value": "C68283"},
                      {"label": "Zacamulpa", "value": "C68284"},
                      {"label": "Zacamulpa Tlalmimilolpan", "value": "C68285"},
                      {"label": "Zacango", "value": "C68286"},
                      {"label": "Zacazonapan", "value": "C68287"},
                      {"label": "Zacualpan", "value": "C68288"},
                      {"label": "Zapotlan", "value": "C68289"},
                      {"label": "Zentlalpan", "value": "C68290"},
                      {"label": "Zinacantepec", "value": "C68291"},
                      {"label": "Zipiajo", "value": "C68292"},
                      {"label": "Zopoco", "value": "C68293"},
                      {"label": "Zoquiapan", "value": "C68294"},
                      {"label": "Zoyatzingo", "value": "C68295"},
                      {"label": "Zula", "value": "C68296"},
                      {"label": "Zumpahuacan", "value": "C68297"},
                      {"label": "Zumpango", "value": "C68298"}],
 "Esteli": [{"label": "Condega", "value": "C77923"},
            {"label": "Esteli", "value": "C77924"},
            {"label": "La Trinidad", "value": "C77925"},
            {"label": "Pueblo Nuevo", "value": "C77926"},
            {"label": "San Juan de Limay", "value": "C77927"}],
 "Estuaire Province": [{"label": "Cocobeach", "value": "C34121"},
                       {"label": "Libreville", "value": "C34122"},
                       {"label": "Ntoum", "value": "C34123"}],
 "Extremadura": [{"label": "Abadia", "value": "C111660"},
                 {"label": "Abertura", "value": "C111661"},
                 {"label": "Acebo", "value": "C111662"},
                 {"label": "Acedera", "value": "C111663"},
                 {"label": "Aceituna", "value": "C111664"},
                 {"label": "Aceuchal", "value": "C111665"},
                 {"label": "Ahigal", "value": "C111666"},
                 {"label": "Ahillones", "value": "C111667"},
                 {"label": "Alange", "value": "C111668"},
                 {"label": "Albala", "value": "C111669"},
                 {"label": "Alburquerque", "value": "C111670"},
                 {"label": "Alcantara", "value": "C111671"},
                 {"label": "Alcollarin", "value": "C111672"},
                 {"label": "Alconchel", "value": "C111673"},
                 {"label": "Alconera", "value": "C111674"},
                 {"label": "Alcuescar", "value": "C111675"},
                 {"label": "Aldea del Cano", "value": "C111676"},
                 {"label": "Aldeacentenera", "value": "C111677"},
                 {"label": "Aldeanueva de la Vera", "value": "C111678"},
                 {"label": "Aldeanueva del Camino", "value": "C111679"},
                 {"label": "Alia", "value": "C111680"},
                 {"label": "Aliseda", "value": "C111681"},
                 {"label": "Aljucen", "value": "C111682"},
                 {"label": "Almaraz", "value": "C111683"},
                 {"label": "Almendral", "value": "C111684"},
                 {"label": "Almendralejo", "value": "C111685"},
                 {"label": "Almoharin", "value": "C111686"},
                 {"label": "Arroyo de la Luz", "value": "C111687"},
                 {"label": "Arroyo de San Servan", "value": "C111688"},
                 {"label": "Arroyomolinos de la Vera", "value": "C111689"},
                 {"label": "Atalaya", "value": "C111690"},
                 {"label": "Azuaga", "value": "C111691"},
                 {"label": "Badajoz", "value": "C111692"},
                 {"label": "Banos de Montemayor", "value": "C111693"},
                 {"label": "Barcarrota", "value": "C111694"},
                 {"label": "Barrado", "value": "C111695"},
                 {"label": "Baterno", "value": "C111696"},
                 {"label": "Benquerencia", "value": "C111697"},
                 {"label": "Berlanga", "value": "C111698"},
                 {"label": "Berrocalejo", "value": "C111699"},
                 {"label": "Bienvenida", "value": "C111700"},
                 {"label": "Bodonal de la Sierra", "value": "C111701"},
                 {"label": "Bohonal de Ibor", "value": "C111702"},
                 {"label": "Botija", "value": "C111703"},
                 {"label": "Brozas", "value": "C111704"},
                 {"label": "Burguillos del Cerro", "value": "C111705"},
                 {"label": "Cabanas del Castillo", "value": "C111706"},
                 {"label": "Cabeza del Buey", "value": "C111707"},
                 {"label": "Cabeza la Vaca", "value": "C111708"},
                 {"label": "Cabezabellosa", "value": "C111709"},
                 {"label": "Cabezuela del Valle", "value": "C111710"},
                 {"label": "Cabrero", "value": "C111711"},
                 {"label": "Caceres", "value": "C111712"},
                 {"label": "Cachorrilla", "value": "C111713"},
                 {"label": "Cadalso", "value": "C111714"},
                 {"label": "Calamonte", "value": "C111715"},
                 {"label": "Calera de Leon", "value": "C111716"},
                 {"label": "Calzadilla", "value": "C111717"},
                 {"label": "Caminomorisco", "value": "C111718"},
                 {"label": "Campanario", "value": "C111719"},
                 {"label": "Campillo de Deleitosa", "value": "C111720"},
                 {"label": "Campillo de Llerena", "value": "C111721"},
                 {"label": "Canamero", "value": "C111722"},
                 {"label": "Canaveral", "value": "C111723"},
                 {"label": "Capilla", "value": "C111724"},
                 {"label": "Carbajo", "value": "C111725"},
                 {"label": "Carcaboso", "value": "C111726"},
                 {"label": "Carmonita", "value": "C111727"},
                 {"label": "Carrascalejo", "value": "C111728"},
                 {"label": "Casar de Caceres", "value": "C111729"},
                 {"label": "Casar de Palomero", "value": "C111730"},
                 {"label": "Casares de las Hurdes", "value": "C111731"},
                 {"label": "Casas de Don Antonio", "value": "C111732"},
                 {"label": "Casas de Don Gomez", "value": "C111733"},
                 {"label": "Casas de Don Pedro", "value": "C111734"},
                 {"label": "Casas de Millan", "value": "C111735"},
                 {"label": "Casas de Miravete", "value": "C111736"},
                 {"label": "Casas de Reina", "value": "C111737"},
                 {"label": "Casas del Castanar", "value": "C111738"},
                 {"label": "Casas del Monte", "value": "C111739"},
                 {"label": "Casatejada", "value": "C111740"},
                 {"label": "Casillas de Coria", "value": "C111741"},
                 {"label": "Castanar de Ibor", "value": "C111742"},
                 {"label": "Castilblanco", "value": "C111743"},
                 {"label": "Castuera", "value": "C111744"},
                 {"label": "Ceclavin", "value": "C111745"},
                 {"label": "Cedillo", "value": "C111746"},
                 {"label": "Cerezo", "value": "C111747"},
                 {"label": "Cheles", "value": "C111748"},
                 {"label": "Cilleros", "value": "C111749"},
                 {"label": "Collado", "value": "C111750"},
                 {"label": "Conquista de la Sierra", "value": "C111751"},
                 {"label": "Cordobilla de Lacara", "value": "C111752"},
                 {"label": "Coria", "value": "C111753"},
                 {"label": "Corte de Peleas", "value": "C111754"},
                 {"label": "Cristina", "value": "C111755"},
                 {"label": "Deleitosa", "value": "C111756"},
                 {"label": "Descargamaria", "value": "C111757"},
                 {"label": "Don alvaro", "value": "C111758"},
                 {"label": "Don Benito", "value": "C111759"},
                 {"label": "El Casar", "value": "C111760"},
                 {"label": "Eljas", "value": "C111761"},
                 {"label": "Entrin Bajo", "value": "C111762"},
                 {"label": "Escurial", "value": "C111763"},
                 {"label": "Esparragalejo", "value": "C111764"},
                 {"label": "Esparragosa de la Serena", "value": "C111765"},
                 {"label": "Esparragosa de Lares", "value": "C111766"},
                 {"label": "Feria", "value": "C111767"},
                 {"label": "Fregenal de la Sierra", "value": "C111768"},
                 {"label": "Fresnedoso de Ibor", "value": "C111769"},
                 {"label": "Fuenlabrada de los Montes", "value": "C111770"},
                 {"label": "Fuente de Cantos", "value": "C111771"},
                 {"label": "Fuente del Maestre", "value": "C111772"},
                 {"label": "Fuentes de Leon", "value": "C111773"},
                 {"label": "Galisteo", "value": "C111774"},
                 {"label": "Garbayuela", "value": "C111775"},
                 {"label": "Garganta la Olla", "value": "C111776"},
                 {"label": "Gargantilla", "value": "C111777"},
                 {"label": "Garguera", "value": "C111778"},
                 {"label": "Garlitos", "value": "C111779"},
                 {"label": "Garrovillas", "value": "C111780"},
                 {"label": "Garvin", "value": "C111781"},
                 {"label": "Gata", "value": "C111782"},
                 {"label": "Granja de Torrehermosa", "value": "C111783"},
                 {"label": "Guadalupe", "value": "C111784"},
                 {"label": "Guarena", "value": "C111785"},
                 {"label": "Guijo de Coria", "value": "C111786"},
                 {"label": "Guijo de Galisteo", "value": "C111787"},
                 {"label": "Guijo de Granadilla", "value": "C111788"},
                 {"label": "Guijo de Santa Barbara", "value": "C111789"},
                 {"label": "Herguijuela", "value": "C111790"},
                 {"label": "Hernan-Perez", "value": "C111791"},
                 {"label": "Herrera de Alcantara", "value": "C111792"},
                 {"label": "Herrera del Duque", "value": "C111793"},
                 {"label": "Hervas", "value": "C111794"},
                 {"label": "Higuera", "value": "C111795"},
                 {"label": "Higuera de la Serena", "value": "C111796"},
                 {"label": "Higuera de Vargas", "value": "C111797"},
                 {"label": "Higuera la Real", "value": "C111798"},
                 {"label": "Hinojal", "value": "C111799"},
                 {"label": "Hinojosa del Valle", "value": "C111800"},
                 {"label": "Holguera", "value": "C111801"},
                 {"label": "Hornachos", "value": "C111802"},
                 {"label": "Hoyos", "value": "C111803"},
                 {"label": "Huelaga", "value": "C111804"},
                 {"label": "Ibahernando", "value": "C111805"},
                 {"label": "Jaraicejo", "value": "C111806"},
                 {"label": "Jaraiz de la Vera", "value": "C111807"},
                 {"label": "Jarandilla de la Vera", "value": "C111808"},
                 {"label": "Jerez de los Caballeros", "value": "C111809"},
                 {"label": "Jerte", "value": "C111810"},
                 {"label": "La Albuera", "value": "C111811"},
                 {"label": "La Codosera", "value": "C111812"},
                 {"label": "La Coronada", "value": "C111813"},
                 {"label": "La Cumbre", "value": "C111814"},
                 {"label": "La Garrovilla", "value": "C111815"},
                 {"label": "La Haba", "value": "C111816"},
                 {"label": "La Nava de Santiago", "value": "C111817"},
                 {"label": "La Parra", "value": "C111818"},
                 {"label": "La Pesga", "value": "C111819"},
                 {"label": "La Roca de la Sierra", "value": "C111820"},
                 {"label": "Ladrillar", "value": "C111821"},
                 {"label": "Llera", "value": "C111822"},
                 {"label": "Llerena", "value": "C111823"},
                 {"label": "Lobon", "value": "C111824"},
                 {"label": "Logrosan", "value": "C111825"},
                 {"label": "Los Santos de Maimona", "value": "C111826"},
                 {"label": "Losar de la Vera", "value": "C111827"},
                 {"label": "Madrigal de la Vera", "value": "C111828"},
                 {"label": "Madrigalejo", "value": "C111829"},
                 {"label": "Madronera", "value": "C111830"},
                 {"label": "Magacela", "value": "C111831"},
                 {"label": "Maguilla", "value": "C111832"},
                 {"label": "Majadas", "value": "C111833"},
                 {"label": "Malcocinado", "value": "C111834"},
                 {"label": "Malpartida de Caceres", "value": "C111835"},
                 {"label": "Malpartida de la Serena", "value": "C111836"},
                 {"label": "Malpartida de Plasencia", "value": "C111837"},
                 {"label": "Manchita", "value": "C111838"},
                 {"label": "Marchagaz", "value": "C111839"},
                 {"label": "Mata de Alcantara", "value": "C111840"},
                 {"label": "Medellin", "value": "C111841"},
                 {"label": "Medina de las Torres", "value": "C111842"},
                 {"label": "Mengabril", "value": "C111843"},
                 {"label": "Merida", "value": "C111844"},
                 {"label": "Mesas de Ibor", "value": "C111845"},
                 {"label": "Miajadas", "value": "C111846"},
                 {"label": "Millanes", "value": "C111847"},
                 {"label": "Mirabel", "value": "C111848"},
                 {"label": "Mirandilla", "value": "C111849"},
                 {"label": "Monesterio", "value": "C111850"},
                 {"label": "Montanchez", "value": "C111851"},
                 {"label": "Montehermoso", "value": "C111852"},
                 {"label": "Montemolin", "value": "C111853"},
                 {"label": "Monterrubio de la Serena", "value": "C111854"},
                 {"label": "Montijo", "value": "C111855"},
                 {"label": "Moraleja", "value": "C111856"},
                 {"label": "Morcillo", "value": "C111857"},
                 {"label": "Navaconcejo", "value": "C111858"},
                 {"label": "Navalmoral de la Mata", "value": "C111859"},
                 {"label": "Navalvillar de Ibor", "value": "C111860"},
                 {"label": "Navalvillar de Pela", "value": "C111861"},
                 {"label": "Navas del Madrono", "value": "C111862"},
                 {"label": "Navezuelas", "value": "C111863"},
                 {"label": "Nogales", "value": "C111864"},
                 {"label": "Nunomoral", "value": "C111865"},
                 {"label": "Oliva de la Frontera", "value": "C111866"},
                 {"label": "Oliva de Merida", "value": "C111867"},
                 {"label": "Oliva de Plasencia", "value": "C111868"},
                 {"label": "Olivenza", "value": "C111869"},
                 {"label": "Orellana la Vieja", "value": "C111870"},
                 {"label": "Palomas", "value": "C111871"},
                 {"label": "Palomero", "value": "C111872"},
                 {"label": "Pedroso de Acim", "value": "C111873"},
                 {"label": "Penalsordo", "value": "C111874"},
                 {"label": "Peraleda de la Mata", "value": "C111875"},
                 {"label": "Peraleda de San Roman", "value": "C111876"},
                 {"label": "Perales del Puerto", "value": "C111877"},
                 {"label": "Pescueza", "value": "C111878"},
                 {"label": "Piedras Albas", "value": "C111879"},
                 {"label": "Pinofranqueado", "value": "C111880"},
                 {"label": "Piornal", "value": "C111881"},
                 {"label": "Plasencia", "value": "C111882"},
                 {"label": "Plasenzuela", "value": "C111883"},
                 {"label": "Portaje", "value": "C111884"},
                 {"label": "Portezuelo", "value": "C111885"},
                 {"label": "Pozuelo de Zarzon", "value": "C111886"},
                 {"label": "Provincia de Badajoz", "value": "C111887"},
                 {"label": "Provincia de Caceres", "value": "C111888"},
                 {"label": "Puebla de Alcocer", "value": "C111889"},
                 {"label": "Puebla de la Calzada", "value": "C111890"},
                 {"label": "Puebla de la Reina", "value": "C111891"},
                 {"label": "Puebla de Obando", "value": "C111892"},
                 {"label": "Puebla de Sancho Perez", "value": "C111893"},
                 {"label": "Puebla del Maestre", "value": "C111894"},
                 {"label": "Puebla del Prior", "value": "C111895"},
                 {"label": "Puerto de Santa Cruz", "value": "C111896"},
                 {"label": "Quintana de la Serena", "value": "C111897"},
                 {"label": "Reina", "value": "C111898"},
                 {"label": "Rena", "value": "C111899"},
                 {"label": "Ribera del Fresno", "value": "C111900"},
                 {"label": "Riolobos", "value": "C111901"},
                 {"label": "Robledillo de Gata", "value": "C111902"},
                 {"label": "Robledillo de la Vera", "value": "C111903"},
                 {"label": "Robledillo de Trujillo", "value": "C111904"},
                 {"label": "Robledollano", "value": "C111905"},
                 {"label": "Romangordo", "value": "C111906"},
                 {"label": "Rosalejo", "value": "C111907"},
                 {"label": "Ruanes", "value": "C111908"},
                 {"label": "Salorino", "value": "C111909"},
                 {"label": "Salvaleon", "value": "C111910"},
                 {"label": "Salvatierra de los Barros", "value": "C111911"},
                 {"label": "Salvatierra de Santiago", "value": "C111912"},
                 {"label": "San Martin de Trevejo", "value": "C111913"},
                 {"label": "San Pedro de Merida", "value": "C111914"},
                 {"label": "San Vicente de Alcantara", "value": "C111915"},
                 {"label": "Santa Amalia", "value": "C111916"},
                 {"label": "Santa Ana", "value": "C111917"},
                 {"label": "Santa Cruz de la Sierra", "value": "C111918"},
                 {"label": "Santa Cruz de Paniagua", "value": "C111919"},
                 {"label": "Santa Marta", "value": "C111920"},
                 {"label": "Santa Marta de Magasca", "value": "C111921"},
                 {"label": "Santiago del Campo", "value": "C111922"},
                 {"label": "Santibanez el Alto", "value": "C111923"},
                 {"label": "Santibanez el Bajo", "value": "C111924"},
                 {"label": "Saucedilla", "value": "C111925"},
                 {"label": "Segura de Leon", "value": "C111926"},
                 {"label": "Segura de Toro", "value": "C111927"},
                 {"label": "Serradilla", "value": "C111928"},
                 {"label": "Serrejon", "value": "C111929"},
                 {"label": "Sierra de Fuentes", "value": "C111930"},
                 {"label": "Siruela", "value": "C111931"},
                 {"label": "Solana de los Barros", "value": "C111932"},
                 {"label": "Talarrubias", "value": "C111933"},
                 {"label": "Talavan", "value": "C111934"},
                 {"label": "Talavera La Real", "value": "C111935"},
                 {"label": "Talayuela", "value": "C111936"},
                 {"label": "Taliga", "value": "C111937"},
                 {"label": "Tamurejo", "value": "C111938"},
                 {"label": "Tejeda de Tietar", "value": "C111939"},
                 {"label": "Toril", "value": "C111940"},
                 {"label": "Tornavacas", "value": "C111941"},
                 {"label": "Torre de Don Miguel", "value": "C111942"},
                 {"label": "Torre de Miguel Sesmero", "value": "C111943"},
                 {"label": "Torre de Santa Maria", "value": "C111944"},
                 {"label": "Torrecilla de los angeles", "value": "C111945"},
                 {"label": "Torrecillas de la Tiesa", "value": "C111946"},
                 {"label": "Torrejon el Rubio", "value": "C111947"},
                 {"label": "Torrejoncillo", "value": "C111948"},
                 {"label": "Torremayor", "value": "C111949"},
                 {"label": "Torremegia", "value": "C111950"},
                 {"label": "Torremenga", "value": "C111951"},
                 {"label": "Torremocha", "value": "C111952"},
                 {"label": "Torreorgaz", "value": "C111953"},
                 {"label": "Torrequemada", "value": "C111954"},
                 {"label": "Trasierra", "value": "C111955"},
                 {"label": "Trujillanos", "value": "C111956"},
                 {"label": "Trujillo", "value": "C111957"},
                 {"label": "Usagre", "value": "C111958"},
                 {"label": "Valdastillas", "value": "C111959"},
                 {"label": "Valdecaballeros", "value": "C111960"},
                 {"label": "Valdecanas de Tajo", "value": "C111961"},
                 {"label": "Valdefuentes", "value": "C111962"},
                 {"label": "Valdehuncar", "value": "C111963"},
                 {"label": "Valdelacalzada", "value": "C111964"},
                 {"label": "Valdelacasa de Tajo", "value": "C111965"},
                 {"label": "Valdemorales", "value": "C111966"},
                 {"label": "Valdeobispo", "value": "C111967"},
                 {"label": "Valdetorres", "value": "C111968"},
                 {"label": "Valencia de Alcantara", "value": "C111969"},
                 {"label": "Valencia de las Torres", "value": "C111970"},
                 {"label": "Valencia del Mombuey", "value": "C111971"},
                 {"label": "Valencia del Ventoso", "value": "C111972"},
                 {"label": "Valle de la Serena", "value": "C111973"},
                 {"label": "Valle de Matamoros", "value": "C111974"},
                 {"label": "Valle de Santa Ana", "value": "C111975"},
                 {"label": "Valverde de Burguillos", "value": "C111976"},
                 {"label": "Valverde de la Vera", "value": "C111977"},
                 {"label": "Valverde de Leganes", "value": "C111978"},
                 {"label": "Valverde de Llerena", "value": "C111979"},
                 {"label": "Valverde de Merida", "value": "C111980"},
                 {"label": "Valverde del Fresno", "value": "C111981"},
                 {"label": "Viandar de la Vera", "value": "C111982"},
                 {"label": "Villa del Campo", "value": "C111983"},
                 {"label": "Villa del Rey", "value": "C111984"},
                 {"label": "Villafranca de los Barros", "value": "C111985"},
                 {"label": "Villagarcia de la Torre", "value": "C111986"},
                 {"label": "Villagonzalo", "value": "C111987"},
                 {"label": "Villalba de los Barros", "value": "C111988"},
                 {"label": "Villamesias", "value": "C111989"},
                 {"label": "Villamiel", "value": "C111990"},
                 {"label": "Villanueva de la Serena", "value": "C111991"},
                 {"label": "Villanueva de la Sierra", "value": "C111992"},
                 {"label": "Villanueva de la Vera", "value": "C111993"},
                 {"label": "Villanueva del Fresno", "value": "C111994"},
                 {"label": "Villar de Plasencia", "value": "C111995"},
                 {"label": "Villar de Rena", "value": "C111996"},
                 {"label": "Villar del Pedroso", "value": "C111997"},
                 {"label": "Villar del Rey", "value": "C111998"},
                 {"label": "Villarta de los Montes", "value": "C111999"},
                 {"label": "Villasbuenas de Gata", "value": "C112000"},
                 {"label": "Zafra", "value": "C112001"},
                 {"label": "Zahinos", "value": "C112002"},
                 {"label": "Zalamea de la Serena", "value": "C112003"},
                 {"label": "Zarza de Alange", "value": "C112004"},
                 {"label": "Zarza de Granadilla", "value": "C112005"},
                 {"label": "Zarza de Montanchez", "value": "C112006"},
                 {"label": "Zarza la Mayor", "value": "C112007"},
                 {"label": "Zorita", "value": "C112008"}],
 "Exuma": [{"label": "George Town", "value": "C119609"}],
 "Faetano": [{"label": "Faetano", "value": "C106249"}],
 "Faiyum": [{"label": "Al Fayyum", "value": "C24265"},
            {"label": "Al Wasitah", "value": "C24266"},
            {"label": "Ibshaway", "value": "C24267"},
            {"label": "Itsa", "value": "C24268"},
            {"label": "Tamiyah", "value": "C24269"}],
 "Falcon": [{"label": "Chichiriviche", "value": "C147172"},
            {"label": "Coro", "value": "C147173"},
            {"label": "Municipio Los Taques", "value": "C147174"},
            {"label": "Municipio Miranda", "value": "C147175"},
            {"label": "Punta Cardon", "value": "C147176"},
            {"label": "Punto Fijo", "value": "C147177"},
            {"label": "Tucacas", "value": "C147178"}],
 "Falesti District": [{"label": "Falesti", "value": "C75513"}],
 "Famagusta District (Magusa)": [{"label": "Acheritou", "value": "C21858"},
                                 {"label": "achna", "value": "C21859"},
                                 {"label": "Ammochostos Municipality",
                                  "value": "C21860"},
                                 {"label": "Avgorou", "value": "C21861"},
                                 {"label": "Ayia Napa", "value": "C21862"},
                                 {"label": "Deryneia", "value": "C21863"},
                                 {"label": "Famagusta", "value": "C21864"},
                                 {"label": "Frenaros", "value": "C21865"},
                                 {"label": "Lefkonoiko", "value": "C21866"},
                                 {"label": "Leonarisso", "value": "C21867"},
                                 {"label": "Liopetri", "value": "C21868"},
                                 {"label": "Paralimni", "value": "C21869"},
                                 {"label": "Protaras", "value": "C21870"},
                                 {"label": "Rizokarpaso", "value": "C21871"},
                                 {"label": "Trikomo", "value": "C21872"}],
 "Far North": [{"label": "Bogo", "value": "C16614"},
               {"label": "Kaele", "value": "C16615"},
               {"label": "Kousseri", "value": "C16616"},
               {"label": "Koza", "value": "C16617"},
               {"label": "Makary", "value": "C16618"},
               {"label": "Maroua", "value": "C16619"},
               {"label": "Mayo-Sava", "value": "C16620"},
               {"label": "Mayo-Tsanaga", "value": "C16621"},
               {"label": "Mindif", "value": "C16622"},
               {"label": "Mokolo", "value": "C16623"},
               {"label": "Mora", "value": "C16624"},
               {"label": "Yagoua", "value": "C16625"}],
 "Far-Western Development Region": [{"label": "Dadeldhura", "value": "C76052"},
                                    {"label": "Dhangadhi", "value": "C76053"},
                                    {"label": "Dipayal", "value": "C76054"},
                                    {"label": "Mahendranagar",
                                     "value": "C76055"},
                                    {"label": "Tikapur", "value": "C76056"}],
 "Farah": [{"label": "Farah", "value": "C22"}],
 "Faro": [{"label": "Albufeira", "value": "C91044"},
          {"label": "Alcantarilha", "value": "C91045"},
          {"label": "Alcoutim", "value": "C91046"},
          {"label": "Algoz", "value": "C91047"},
          {"label": "Aljezur", "value": "C91048"},
          {"label": "Almancil", "value": "C91049"},
          {"label": "Altura", "value": "C91050"},
          {"label": "Alvor", "value": "C91051"},
          {"label": "Armacao de Pera", "value": "C91052"},
          {"label": "Bensafrim", "value": "C91053"},
          {"label": "Boliqueime", "value": "C91054"},
          {"label": "Cabanas de Tavira", "value": "C91055"},
          {"label": "Carvoeiro", "value": "C91056"},
          {"label": "Castro Marim", "value": "C91057"},
          {"label": "Conceicao", "value": "C91058"},
          {"label": "Estoi", "value": "C91059"},
          {"label": "Estombar", "value": "C91060"},
          {"label": "Faro", "value": "C91061"},
          {"label": "Ferragudo", "value": "C91062"},
          {"label": "Ferreiras", "value": "C91063"},
          {"label": "Fuzeta", "value": "C91064"},
          {"label": "Guia", "value": "C91065"},
          {"label": "Lagoa", "value": "C91066"},
          {"label": "Lagos", "value": "C91067"},
          {"label": "Laranjeiro", "value": "C91068"},
          {"label": "Loule", "value": "C91069"},
          {"label": "Luz", "value": "C91070"},
          {"label": "Manta Rota", "value": "C91071"},
          {"label": "Marmelete", "value": "C91072"},
          {"label": "Mexilhoeira Grande", "value": "C91073"},
          {"label": "Moncarapacho", "value": "C91074"},
          {"label": "Monchique", "value": "C91075"},
          {"label": "Monte Gordo", "value": "C91076"},
          {"label": "Olhao", "value": "C91077"},
          {"label": "Olhos de agua", "value": "C91078"},
          {"label": "Paderne", "value": "C91079"},
          {"label": "Parchal", "value": "C91080"},
          {"label": "Pera", "value": "C91081"},
          {"label": "Porches", "value": "C91082"},
          {"label": "Portimao", "value": "C91083"},
          {"label": "Quarteira", "value": "C91084"},
          {"label": "Sagres", "value": "C91085"},
          {"label": "Santa Barbara de Nexe", "value": "C91086"},
          {"label": "Santa Luzia", "value": "C91087"},
          {"label": "Sao Bartolomeu de Messines", "value": "C91088"},
          {"label": "Sao Bras de Alportel", "value": "C91089"},
          {"label": "Sao Marcos da Serra", "value": "C91090"},
          {"label": "Senhora da Luz", "value": "C91091"},
          {"label": "Silves", "value": "C91092"},
          {"label": "Tavira", "value": "C91093"},
          {"label": "Tunes", "value": "C91094"},
          {"label": "Vila do Bispo", "value": "C91095"},
          {"label": "Vila Nova De Cacela", "value": "C91096"},
          {"label": "Vila Real de Santo Antonio", "value": "C91097"},
          {"label": "Vilamoura", "value": "C91098"}],
 "Fars": [{"label": "abadeh", "value": "C50114"},
          {"label": "Akbarabad", "value": "C50115"},
          {"label": "Darab", "value": "C50116"},
          {"label": "Fasa", "value": "C50117"},
          {"label": "Firuzabad", "value": "C50118"},
          {"label": "Gerash", "value": "C50119"},
          {"label": "Kazerun", "value": "C50120"},
          {"label": "Mamasani", "value": "C50121"},
          {"label": "Marvdasht", "value": "C50122"},
          {"label": "Mohr", "value": "C50123"},
          {"label": "Neyriz", "value": "C50124"},
          {"label": "Nurabad", "value": "C50125"},
          {"label": "Pasargad", "value": "C50126"},
          {"label": "Rostam", "value": "C50127"},
          {"label": "Shahr-e Qadim-e Lar", "value": "C50128"},
          {"label": "Shahrestan-e abadeh", "value": "C50129"},
          {"label": "Shahrestan-e Arsanjan", "value": "C50130"},
          {"label": "Shahrestan-e Bavanat", "value": "C50131"},
          {"label": "Shahrestan-e Darab", "value": "C50132"},
          {"label": "Shahrestan-e Eqlid", "value": "C50133"},
          {"label": "Shahrestan-e Estahban", "value": "C50134"},
          {"label": "Shahrestan-e Farashband", "value": "C50135"},
          {"label": "Shahrestan-e Fasa", "value": "C50136"},
          {"label": "Shahrestan-e Firuzabad", "value": "C50137"},
          {"label": "Shahrestan-e Gerash", "value": "C50138"},
          {"label": "Shahrestan-e Kavar", "value": "C50139"},
          {"label": "Shahrestan-e Kazerun", "value": "C50140"},
          {"label": "Shahrestan-e Kherameh", "value": "C50141"},
          {"label": "Shahrestan-e Khorrambid", "value": "C50142"},
          {"label": "Shahrestan-e Lamerd", "value": "C50143"},
          {"label": "Shahrestan-e Larestan", "value": "C50144"},
          {"label": "Shahrestan-e Neyriz", "value": "C50145"},
          {"label": "Shahrestan-e Pasargad", "value": "C50146"},
          {"label": "Shahrestan-e Qir va Karzin", "value": "C50147"},
          {"label": "Shahrestan-e Sarvestan", "value": "C50148"},
          {"label": "Shahrestan-e Sepidan", "value": "C50149"},
          {"label": "Shahrestan-e Shiraz", "value": "C50150"},
          {"label": "Shahrestan-e Zarrin Dasht", "value": "C50151"},
          {"label": "Shiraz", "value": "C50152"}],
 "Faryab": [{"label": "Andkhoy", "value": "C23"},
            {"label": "Maymana", "value": "C24"}],
 "Fatick": [{"label": "Diofior", "value": "C106834"},
            {"label": "Fatick Department", "value": "C106835"},
            {"label": "Foundiougne", "value": "C106836"},
            {"label": "Guinguineo", "value": "C106837"},
            {"label": "Passi", "value": "C106838"},
            {"label": "Pourham", "value": "C106839"},
            {"label": "Sokone", "value": "C106840"}],
 "Federally Administered Tribal Areas": [{"label": "Alizai", "value": "C79376"},
                                         {"label": "Gulishah Kach",
                                          "value": "C79377"},
                                         {"label": "Landi Kotal",
                                          "value": "C79378"},
                                         {"label": "Miran Shah",
                                          "value": "C79379"},
                                         {"label": "North Waziristan Agency",
                                          "value": "C79380"},
                                         {"label": "Shinpokh",
                                          "value": "C79381"},
                                         {"label": "South Waziristan Agency",
                                          "value": "C79382"},
                                         {"label": "Wana", "value": "C79383"}],
 "Federation of Bosnia and Herzegovina": [{"label": "Banovici",
                                           "value": "C9892"},
                                          {"label": "Barice", "value": "C9893"},
                                          {"label": "Bihac", "value": "C9894"},
                                          {"label": "Bijela", "value": "C9895"},
                                          {"label": "Bila", "value": "C9896"},
                                          {"label": "Blagaj", "value": "C9897"},
                                          {"label": "Bosanska Krupa",
                                           "value": "C9898"},
                                          {"label": "Bosanski Petrovac",
                                           "value": "C9899"},
                                          {"label": "Bosansko Grahovo",
                                           "value": "C9900"},
                                          {"label": "Breza", "value": "C9901"},
                                          {"label": "Bugojno",
                                           "value": "C9902"},
                                          {"label": "Busovaca",
                                           "value": "C9903"},
                                          {"label": "Buzim", "value": "C9904"},
                                          {"label": "Capljina",
                                           "value": "C9905"},
                                          {"label": "Cazin", "value": "C9906"},
                                          {"label": "Celic", "value": "C9907"},
                                          {"label": "Cim", "value": "C9908"},
                                          {"label": "Citluk", "value": "C9909"},
                                          {"label": "Coralici",
                                           "value": "C9910"},
                                          {"label": "Crnici", "value": "C9911"},
                                          {"label": "Divicani",
                                           "value": "C9912"},
                                          {"label": "Dobrinje",
                                           "value": "C9913"},
                                          {"label": "Domaljevac",
                                           "value": "C9914"},
                                          {"label": "Donja Dubica",
                                           "value": "C9915"},
                                          {"label": "Donja Mahala",
                                           "value": "C9916"},
                                          {"label": "Donja Medida",
                                           "value": "C9917"},
                                          {"label": "Donji Vakuf",
                                           "value": "C9918"},
                                          {"label": "Dreznica",
                                           "value": "C9919"},
                                          {"label": "Drinovci",
                                           "value": "C9920"},
                                          {"label": "Drvar", "value": "C9921"},
                                          {"label": "Dubrave Donje",
                                           "value": "C9922"},
                                          {"label": "Dubrave Gornje",
                                           "value": "C9923"},
                                          {"label": "Dubravica",
                                           "value": "C9924"},
                                          {"label": "Fojnica",
                                           "value": "C9925"},
                                          {"label": "Glamoc", "value": "C9926"},
                                          {"label": "Gnojnica",
                                           "value": "C9927"},
                                          {"label": "Gorazde",
                                           "value": "C9928"},
                                          {"label": "Gorica", "value": "C9929"},
                                          {"label": "Gornja Breza",
                                           "value": "C9930"},
                                          {"label": "Gornja Koprivna",
                                           "value": "C9931"},
                                          {"label": "Gornja Tuzla",
                                           "value": "C9932"},
                                          {"label": "Gornje Mostre",
                                           "value": "C9933"},
                                          {"label": "Gornje Zivinice",
                                           "value": "C9934"},
                                          {"label": "Gornji Vakuf",
                                           "value": "C9935"},
                                          {"label": "Gostovici",
                                           "value": "C9936"},
                                          {"label": "Gracanica",
                                           "value": "C9937"},
                                          {"label": "Gradacac",
                                           "value": "C9938"},
                                          {"label": "Gromiljak",
                                           "value": "C9939"},
                                          {"label": "Grude", "value": "C9940"},
                                          {"label": "Hadzici",
                                           "value": "C9941"},
                                          {"label": "Hercegovacko-Neretvanski Kanton"
                                                    ,
                                           "value": "C9942"},
                                          {"label": "Hotonj", "value": "C9943"},
                                          {"label": "Ilici", "value": "C9944"},
                                          {"label": "Ilijas", "value": "C9945"},
                                          {"label": "Izacic", "value": "C9946"},
                                          {"label": "Jablanica",
                                           "value": "C9947"},
                                          {"label": "Jajce", "value": "C9948"},
                                          {"label": "Jelah", "value": "C9949"},
                                          {"label": "Jezerski",
                                           "value": "C9950"},
                                          {"label": "Kacuni", "value": "C9951"},
                                          {"label": "Kakanj", "value": "C9952"},
                                          {"label": "Kanton Sarajevo",
                                           "value": "C9953"},
                                          {"label": "Karadaglije",
                                           "value": "C9954"},
                                          {"label": "Kiseljak",
                                           "value": "C9955"},
                                          {"label": "Kladanj",
                                           "value": "C9956"},
                                          {"label": "Kljuc", "value": "C9957"},
                                          {"label": "Kobilja Glava",
                                           "value": "C9958"},
                                          {"label": "Kocerin",
                                           "value": "C9959"},
                                          {"label": "Konjic", "value": "C9960"},
                                          {"label": "Kovaci", "value": "C9961"},
                                          {"label": "Lijesnica",
                                           "value": "C9962"},
                                          {"label": "Livno", "value": "C9963"},
                                          {"label": "Ljubuski",
                                           "value": "C9964"},
                                          {"label": "Lokvine",
                                           "value": "C9965"},
                                          {"label": "Lukavac",
                                           "value": "C9966"},
                                          {"label": "Lukavica",
                                           "value": "C9967"},
                                          {"label": "Maglaj", "value": "C9968"},
                                          {"label": "Mahala", "value": "C9969"},
                                          {"label": "Mala Kladusa",
                                           "value": "C9970"},
                                          {"label": "Malesici",
                                           "value": "C9971"},
                                          {"label": "Mionica",
                                           "value": "C9972"},
                                          {"label": "Mostar", "value": "C9973"},
                                          {"label": "Mramor", "value": "C9974"},
                                          {"label": "Neum", "value": "C9975"},
                                          {"label": "Novi Seher",
                                           "value": "C9976"},
                                          {"label": "Novi Travnik",
                                           "value": "C9977"},
                                          {"label": "Odzak", "value": "C9978"},
                                          {"label": "Olovo", "value": "C9979"},
                                          {"label": "Omanjska",
                                           "value": "C9980"},
                                          {"label": "Orahovica Donja",
                                           "value": "C9981"},
                                          {"label": "Orasac", "value": "C9982"},
                                          {"label": "Orasje", "value": "C9983"},
                                          {"label": "Orguz", "value": "C9984"},
                                          {"label": "Ostrozac",
                                           "value": "C9985"},
                                          {"label": "Otoka", "value": "C9986"},
                                          {"label": "Pajic Polje",
                                           "value": "C9987"},
                                          {"label": "Pazaric",
                                           "value": "C9988"},
                                          {"label": "Peci", "value": "C9989"},
                                          {"label": "Pecigrad",
                                           "value": "C9990"},
                                          {"label": "Pjanici",
                                           "value": "C9991"},
                                          {"label": "Podhum", "value": "C9992"},
                                          {"label": "Podzvizd",
                                           "value": "C9993"},
                                          {"label": "Polje", "value": "C9994"},
                                          {"label": "Polje-Bijela",
                                           "value": "C9995"},
                                          {"label": "Potoci", "value": "C9996"},
                                          {"label": "Prozor", "value": "C9997"},
                                          {"label": "Puracic",
                                           "value": "C9998"},
                                          {"label": "Radisici",
                                           "value": "C9999"},
                                          {"label": "Rodoc", "value": "C10000"},
                                          {"label": "Rumboci",
                                           "value": "C10001"},
                                          {"label": "Sanica",
                                           "value": "C10002"},
                                          {"label": "Sanski Most",
                                           "value": "C10003"},
                                          {"label": "Sapna", "value": "C10004"},
                                          {"label": "Sarajevo",
                                           "value": "C10005"},
                                          {"label": "Serici",
                                           "value": "C10006"},
                                          {"label": "Siroki Brijeg",
                                           "value": "C10007"},
                                          {"label": "Skokovi",
                                           "value": "C10008"},
                                          {"label": "Sladna",
                                           "value": "C10009"},
                                          {"label": "Solina",
                                           "value": "C10010"},
                                          {"label": "Srebrenik",
                                           "value": "C10011"},
                                          {"label": "Stijena",
                                           "value": "C10012"},
                                          {"label": "Stjepan-Polje",
                                           "value": "C10013"},
                                          {"label": "Stolac",
                                           "value": "C10014"},
                                          {"label": "Sturlic",
                                           "value": "C10015"},
                                          {"label": "Sumatac",
                                           "value": "C10016"},
                                          {"label": "Tasovcici",
                                           "value": "C10017"},
                                          {"label": "Tesanj",
                                           "value": "C10018"},
                                          {"label": "Tesanjka",
                                           "value": "C10019"},
                                          {"label": "Todorovo",
                                           "value": "C10020"},
                                          {"label": "Tojsici",
                                           "value": "C10021"},
                                          {"label": "Tomislavgrad",
                                           "value": "C10022"},
                                          {"label": "Travnik",
                                           "value": "C10023"},
                                          {"label": "Trzacka Rastela",
                                           "value": "C10024"},
                                          {"label": "Turbe", "value": "C10025"},
                                          {"label": "Tuzla", "value": "C10026"},
                                          {"label": "Ustikolina",
                                           "value": "C10027"},
                                          {"label": "Vares", "value": "C10028"},
                                          {"label": "Varoska Rijeka",
                                           "value": "C10029"},
                                          {"label": "Velagici",
                                           "value": "C10030"},
                                          {"label": "Velika Kladusa",
                                           "value": "C10031"},
                                          {"label": "Vidosi",
                                           "value": "C10032"},
                                          {"label": "Visoko",
                                           "value": "C10033"},
                                          {"label": "Vitez", "value": "C10034"},
                                          {"label": "Vitina",
                                           "value": "C10035"},
                                          {"label": "Vogosca",
                                           "value": "C10036"},
                                          {"label": "Voljevac",
                                           "value": "C10037"},
                                          {"label": "Vrnograc",
                                           "value": "C10038"},
                                          {"label": "Vukovije Donje",
                                           "value": "C10039"},
                                          {"label": "Zabrisce",
                                           "value": "C10040"},
                                          {"label": "Zavidovici",
                                           "value": "C10041"},
                                          {"label": "Zboriste",
                                           "value": "C10042"},
                                          {"label": "Zeljezno Polje",
                                           "value": "C10043"},
                                          {"label": "Zenica",
                                           "value": "C10044"},
                                          {"label": "Zepce", "value": "C10045"},
                                          {"label": "Zivinice",
                                           "value": "C10046"}],
 "Fejer County": [{"label": "Aba", "value": "C44059"},
                  {"label": "Adony", "value": "C44060"},
                  {"label": "Alap", "value": "C44061"},
                  {"label": "Bakonycsernye", "value": "C44062"},
                  {"label": "Baracs", "value": "C44063"},
                  {"label": "Baracska", "value": "C44064"},
                  {"label": "Bicske", "value": "C44065"},
                  {"label": "Bicskei Jaras", "value": "C44066"},
                  {"label": "Bodajk", "value": "C44067"},
                  {"label": "Cece", "value": "C44068"},
                  {"label": "Csakvar", "value": "C44069"},
                  {"label": "Deg", "value": "C44070"},
                  {"label": "dunaujvaros", "value": "C44071"},
                  {"label": "Dunaujvarosi Jaras", "value": "C44072"},
                  {"label": "Eloszallas", "value": "C44073"},
                  {"label": "Enying", "value": "C44074"},
                  {"label": "Enyingi Jaras", "value": "C44075"},
                  {"label": "Ercsi", "value": "C44076"},
                  {"label": "Etyek", "value": "C44077"},
                  {"label": "Fehervarcsurgo", "value": "C44078"},
                  {"label": "Gardony", "value": "C44079"},
                  {"label": "Gardonyi Jaras", "value": "C44080"},
                  {"label": "Ivancsa", "value": "C44081"},
                  {"label": "Kaloz", "value": "C44082"},
                  {"label": "Kapolnasnyek", "value": "C44083"},
                  {"label": "Kincsesbanya", "value": "C44084"},
                  {"label": "Kislang", "value": "C44085"},
                  {"label": "Lajoskomarom", "value": "C44086"},
                  {"label": "Lepseny", "value": "C44087"},
                  {"label": "Lovasbereny", "value": "C44088"},
                  {"label": "Many", "value": "C44089"},
                  {"label": "Martonvasar", "value": "C44090"},
                  {"label": "Martonvasari Jaras", "value": "C44091"},
                  {"label": "Mezofalva", "value": "C44092"},
                  {"label": "Mezoszilas", "value": "C44093"},
                  {"label": "Mor", "value": "C44094"},
                  {"label": "Mori Jaras", "value": "C44095"},
                  {"label": "Pakozd", "value": "C44096"},
                  {"label": "Pazmand", "value": "C44097"},
                  {"label": "Perkata", "value": "C44098"},
                  {"label": "Polgardi", "value": "C44099"},
                  {"label": "Pusztaszabolcs", "value": "C44100"},
                  {"label": "Pusztavam", "value": "C44101"},
                  {"label": "Racalmas", "value": "C44102"},
                  {"label": "Rackeresztur", "value": "C44103"},
                  {"label": "Sarbogard", "value": "C44104"},
                  {"label": "Sarbogardi Jaras", "value": "C44105"},
                  {"label": "Sarkeresztur", "value": "C44106"},
                  {"label": "Sarosd", "value": "C44107"},
                  {"label": "Sarszentmihaly", "value": "C44108"},
                  {"label": "Seregelyes", "value": "C44109"},
                  {"label": "Soponya", "value": "C44110"},
                  {"label": "Szabadbattyan", "value": "C44111"},
                  {"label": "Szarliget", "value": "C44112"},
                  {"label": "Szekesfehervar", "value": "C44113"},
                  {"label": "Szekesfehervari Jaras", "value": "C44114"},
                  {"label": "Val", "value": "C44115"},
                  {"label": "Velence", "value": "C44116"},
                  {"label": "Zamoly", "value": "C44117"}],
 "Fergana Region": [{"label": "Beshariq", "value": "C147016"},
                    {"label": "Fergana", "value": "C147017"},
                    {"label": "Hamza", "value": "C147018"},
                    {"label": "Kirguli", "value": "C147019"},
                    {"label": "Margilon", "value": "C147020"},
                    {"label": "Oltiariq", "value": "C147021"},
                    {"label": "Qoqon", "value": "C147022"},
                    {"label": "Quva", "value": "C147023"},
                    {"label": "Quvasoy", "value": "C147024"},
                    {"label": "Rishton", "value": "C147025"},
                    {"label": "Shohimardon", "value": "C147026"},
                    {"label": "Sox Tumani", "value": "C147027"},
                    {"label": "Toshloq", "value": "C147028"},
                    {"label": "Yangi Margilon", "value": "C147029"},
                    {"label": "Yaypan", "value": "C147030"}],
 "Fes-Meknes": [{"label": "Ain Leuh", "value": "C75694"},
                {"label": "Aknoul", "value": "C75695"},
                {"label": "Almis Marmoucha", "value": "C75696"},
                {"label": "Azrou", "value": "C75697"},
                {"label": "Bhalil", "value": "C75698"},
                {"label": "Bouarouss", "value": "C75699"},
                {"label": "Boulemane", "value": "C75700"},
                {"label": "El Hajeb", "value": "C75701"},
                {"label": "El-Hajeb", "value": "C75702"},
                {"label": "Fes", "value": "C75703"},
                {"label": "Fes al Bali", "value": "C75704"},
                {"label": "Galaz", "value": "C75705"},
                {"label": "Ghouazi", "value": "C75706"},
                {"label": "Guercif", "value": "C75707"},
                {"label": "Ifrane", "value": "C75708"},
                {"label": "Meknes", "value": "C75709"},
                {"label": "Missour", "value": "C75710"},
                {"label": "Moulay Bouchta", "value": "C75711"},
                {"label": "Moulay-Yacoub", "value": "C75712"},
                {"label": "Oued Amlil", "value": "C75713"},
                {"label": "Oulad Tayeb", "value": "C75714"},
                {"label": "Ourtzagh", "value": "C75715"},
                {"label": "Sefrou", "value": "C75716"},
                {"label": "Tahla", "value": "C75717"},
                {"label": "Talzemt", "value": "C75718"},
                {"label": "Taounate", "value": "C75719"},
                {"label": "Taza", "value": "C75720"},
                {"label": "Tmourghout", "value": "C75721"}],
 "Fgura": [{"label": "Fgura", "value": "C65876"}],
 "Fier County": [{"label": "Ballsh", "value": "C127"},
                 {"label": "Bashkia Divjake", "value": "C128"},
                 {"label": "Bashkia Fier", "value": "C129"},
                 {"label": "Bashkia Mallakaster", "value": "C130"},
                 {"label": "Bashkia Patos", "value": "C131"},
                 {"label": "Divjake", "value": "C132"},
                 {"label": "Fier", "value": "C133"},
                 {"label": "Fier-Cifci", "value": "C134"},
                 {"label": "Lushnje", "value": "C135"},
                 {"label": "Patos", "value": "C136"},
                 {"label": "Patos Fshat", "value": "C137"},
                 {"label": "Roskovec", "value": "C138"},
                 {"label": "Rrethi i Mallakastres", "value": "C139"}],
 "Finland Proper": [{"label": "Alastaro", "value": "C24866"},
                    {"label": "Askainen", "value": "C24867"},
                    {"label": "Aura", "value": "C24868"},
                    {"label": "Dragsfjard", "value": "C24869"},
                    {"label": "Halikko", "value": "C24870"},
                    {"label": "Houtskar", "value": "C24871"},
                    {"label": "Inio", "value": "C24872"},
                    {"label": "Kaarina", "value": "C24873"},
                    {"label": "Karinainen", "value": "C24874"},
                    {"label": "Kiikala", "value": "C24875"},
                    {"label": "Kimito", "value": "C24876"},
                    {"label": "Kisko", "value": "C24877"},
                    {"label": "Kustavi", "value": "C24878"},
                    {"label": "Kuusjoki", "value": "C24879"},
                    {"label": "Laitila", "value": "C24880"},
                    {"label": "Lemu", "value": "C24881"},
                    {"label": "Lieto", "value": "C24882"},
                    {"label": "Marttila", "value": "C24883"},
                    {"label": "Masku", "value": "C24884"},
                    {"label": "Mellila", "value": "C24885"},
                    {"label": "Merimasku", "value": "C24886"},
                    {"label": "Mietoinen", "value": "C24887"},
                    {"label": "Muurla", "value": "C24888"},
                    {"label": "Mynamaki", "value": "C24889"},
                    {"label": "Naantali", "value": "C24890"},
                    {"label": "Nagu", "value": "C24891"},
                    {"label": "Nousiainen", "value": "C24892"},
                    {"label": "Oripaa", "value": "C24893"},
                    {"label": "Paimio", "value": "C24894"},
                    {"label": "Pargas", "value": "C24895"},
                    {"label": "Pernio", "value": "C24896"},
                    {"label": "Pertteli", "value": "C24897"},
                    {"label": "Piikkio", "value": "C24898"},
                    {"label": "Poytya", "value": "C24899"},
                    {"label": "Pyharanta", "value": "C24900"},
                    {"label": "Raisio", "value": "C24901"},
                    {"label": "Rusko", "value": "C24902"},
                    {"label": "Rymattyla", "value": "C24903"},
                    {"label": "Salo", "value": "C24904"},
                    {"label": "Sarkisalo", "value": "C24905"},
                    {"label": "Sauvo", "value": "C24906"},
                    {"label": "Somero", "value": "C24907"},
                    {"label": "Suomusjarvi", "value": "C24908"},
                    {"label": "Taivassalo", "value": "C24909"},
                    {"label": "Tarvasjoki", "value": "C24910"},
                    {"label": "Turku", "value": "C24911"},
                    {"label": "Uusikaupunki", "value": "C24912"},
                    {"label": "Vahto", "value": "C24913"},
                    {"label": "Vastaboland", "value": "C24914"},
                    {"label": "Vastanfjard", "value": "C24915"},
                    {"label": "Vehmaa", "value": "C24916"},
                    {"label": "Velkua", "value": "C24917"},
                    {"label": "Ylane", "value": "C24918"}],
 "Finnmark": [{"label": "Alta", "value": "C78697"},
              {"label": "avanuorri", "value": "C78698"},
              {"label": "Batsfjord", "value": "C78699"},
              {"label": "Berlevag", "value": "C78700"},
              {"label": "Bjornevatn", "value": "C78701"},
              {"label": "Gamvik", "value": "C78702"},
              {"label": "Hammerfest", "value": "C78703"},
              {"label": "Hasvik", "value": "C78704"},
              {"label": "Honningsvag", "value": "C78705"},
              {"label": "Karasjohka", "value": "C78706"},
              {"label": "Karasjok", "value": "C78707"},
              {"label": "Kautokeino", "value": "C78708"},
              {"label": "Kirkenes", "value": "C78709"},
              {"label": "Kjollefjord", "value": "C78710"},
              {"label": "Kvalsund", "value": "C78711"},
              {"label": "Lakselv", "value": "C78712"},
              {"label": "Lebesby", "value": "C78713"},
              {"label": "Loppa", "value": "C78714"},
              {"label": "Masoy", "value": "C78715"},
              {"label": "Mehamn", "value": "C78716"},
              {"label": "Nesseby", "value": "C78717"},
              {"label": "Nordkapp", "value": "C78718"},
              {"label": "Oksfjord", "value": "C78719"},
              {"label": "Porsanger", "value": "C78720"},
              {"label": "Rypefjord", "value": "C78721"},
              {"label": "Sor-Varanger", "value": "C78722"},
              {"label": "Tana", "value": "C78723"},
              {"label": "Vadso", "value": "C78724"},
              {"label": "Vardo", "value": "C78725"}],
 "Fiorentino": [{"label": "Fiorentino", "value": "C106250"}],
 "Fizuli District": [{"label": "Fizuli", "value": "C8425"},
                     {"label": "Horadiz", "value": "C8426"}],
 "Flacq District": [{"label": "Bel Air Riviere Seche", "value": "C65978"},
                    {"label": "Bon Accueil", "value": "C65979"},
                    {"label": "Brisee Verdiere", "value": "C65980"},
                    {"label": "Camp de Masque", "value": "C65981"},
                    {"label": "Camp Ithier", "value": "C65982"},
                    {"label": "Centre de Flacq", "value": "C65983"},
                    {"label": "Clemencia", "value": "C65984"},
                    {"label": "Ecroignard", "value": "C65985"},
                    {"label": "Grande Riviere Sud Est", "value": "C65986"},
                    {"label": "Lalmatie", "value": "C65987"},
                    {"label": "Laventure", "value": "C65988"},
                    {"label": "Mare La Chaux", "value": "C65989"},
                    {"label": "Olivia", "value": "C65990"},
                    {"label": "Poste de Flacq", "value": "C65991"},
                    {"label": "Quatre Cocos", "value": "C65992"},
                    {"label": "Quatre Soeurs", "value": "C65993"},
                    {"label": "Queen Victoria", "value": "C65994"},
                    {"label": "Saint Julien", "value": "C65995"},
                    {"label": "Sebastopol", "value": "C65996"}],
 "Flanders": [{"label": "Aalst", "value": "C9032"},
              {"label": "Aalter", "value": "C9033"},
              {"label": "Aarschot", "value": "C9034"},
              {"label": "Aartselaar", "value": "C9035"},
              {"label": "Alken", "value": "C9036"},
              {"label": "Alveringem", "value": "C9037"},
              {"label": "Antwerpen", "value": "C9038"},
              {"label": "Anzegem", "value": "C9039"},
              {"label": "Ardooie", "value": "C9040"},
              {"label": "Arendonk", "value": "C9041"},
              {"label": "As", "value": "C9042"},
              {"label": "Asse", "value": "C9043"},
              {"label": "Assenede", "value": "C9044"},
              {"label": "Avelgem", "value": "C9045"},
              {"label": "Baarle-Hertog", "value": "C9046"},
              {"label": "Balen", "value": "C9047"},
              {"label": "Beernem", "value": "C9048"},
              {"label": "Beerse", "value": "C9049"},
              {"label": "Beersel", "value": "C9050"},
              {"label": "Begijnendijk", "value": "C9051"},
              {"label": "Bekkevoort", "value": "C9052"},
              {"label": "Beringen", "value": "C9053"},
              {"label": "Berlaar", "value": "C9054"},
              {"label": "Berlare", "value": "C9055"},
              {"label": "Bertem", "value": "C9056"},
              {"label": "Bever", "value": "C9057"},
              {"label": "Beveren", "value": "C9058"},
              {"label": "Bierbeek", "value": "C9059"},
              {"label": "Bilzen", "value": "C9060"},
              {"label": "Blankenberge", "value": "C9061"},
              {"label": "Bocholt", "value": "C9062"},
              {"label": "Boechout", "value": "C9063"},
              {"label": "Bonheiden", "value": "C9064"},
              {"label": "Boom", "value": "C9065"},
              {"label": "Boortmeerbeek", "value": "C9066"},
              {"label": "Borgloon", "value": "C9067"},
              {"label": "Bornem", "value": "C9068"},
              {"label": "Borsbeek", "value": "C9069"},
              {"label": "Boutersem", "value": "C9070"},
              {"label": "Brasschaat", "value": "C9071"},
              {"label": "Brecht", "value": "C9072"},
              {"label": "Bredene", "value": "C9073"},
              {"label": "Bree", "value": "C9074"},
              {"label": "Brugge", "value": "C9075"},
              {"label": "Buggenhout", "value": "C9076"},
              {"label": "Damme", "value": "C9077"},
              {"label": "De Haan", "value": "C9078"},
              {"label": "De Panne", "value": "C9079"},
              {"label": "De Pinte", "value": "C9080"},
              {"label": "Deerlijk", "value": "C9081"},
              {"label": "Deinze", "value": "C9082"},
              {"label": "Denderleeuw", "value": "C9083"},
              {"label": "Dendermonde", "value": "C9084"},
              {"label": "Dentergem", "value": "C9085"},
              {"label": "Dessel", "value": "C9086"},
              {"label": "Destelbergen", "value": "C9087"},
              {"label": "Deurne", "value": "C9088"},
              {"label": "Diegem", "value": "C9089"},
              {"label": "Diepenbeek", "value": "C9090"},
              {"label": "Diest", "value": "C9091"},
              {"label": "Diksmuide", "value": "C9092"},
              {"label": "Dilbeek", "value": "C9093"},
              {"label": "Drogenbos", "value": "C9094"},
              {"label": "Duffel", "value": "C9095"},
              {"label": "Edegem", "value": "C9096"},
              {"label": "Eeklo", "value": "C9097"},
              {"label": "Essen", "value": "C9098"},
              {"label": "Evergem", "value": "C9099"},
              {"label": "Galmaarden", "value": "C9100"},
              {"label": "Gavere", "value": "C9101"},
              {"label": "Geel", "value": "C9102"},
              {"label": "Geetbets", "value": "C9103"},
              {"label": "Genk", "value": "C9104"},
              {"label": "Gent", "value": "C9105"},
              {"label": "Geraardsbergen", "value": "C9106"},
              {"label": "Gingelom", "value": "C9107"},
              {"label": "Gistel", "value": "C9108"},
              {"label": "Glabbeek", "value": "C9109"},
              {"label": "Gooik", "value": "C9110"},
              {"label": "Grimbergen", "value": "C9111"},
              {"label": "Grobbendonk", "value": "C9112"},
              {"label": "Haacht", "value": "C9113"},
              {"label": "Haaltert", "value": "C9114"},
              {"label": "Halen", "value": "C9115"},
              {"label": "Halle", "value": "C9116"},
              {"label": "Hamme", "value": "C9117"},
              {"label": "Harelbeke", "value": "C9118"},
              {"label": "Hasselt", "value": "C9119"},
              {"label": "Heers", "value": "C9120"},
              {"label": "Heist-op-den-Berg", "value": "C9121"},
              {"label": "Helchteren", "value": "C9122"},
              {"label": "Hemiksem", "value": "C9123"},
              {"label": "Herent", "value": "C9124"},
              {"label": "Herentals", "value": "C9125"},
              {"label": "Herenthout", "value": "C9126"},
              {"label": "Herk-de-Stad", "value": "C9127"},
              {"label": "Herne", "value": "C9128"},
              {"label": "Herselt", "value": "C9129"},
              {"label": "Herzele", "value": "C9130"},
              {"label": "Heusden", "value": "C9131"},
              {"label": "Hoboken", "value": "C9132"},
              {"label": "Hoegaarden", "value": "C9133"},
              {"label": "Hoeilaart", "value": "C9134"},
              {"label": "Hoeselt", "value": "C9135"},
              {"label": "Holsbeek", "value": "C9136"},
              {"label": "Hooglede", "value": "C9137"},
              {"label": "Hoogstraten", "value": "C9138"},
              {"label": "Houthalen", "value": "C9139"},
              {"label": "Houthulst", "value": "C9140"},
              {"label": "Hove", "value": "C9141"},
              {"label": "Huldenberg", "value": "C9142"},
              {"label": "Hulshout", "value": "C9143"},
              {"label": "Ichtegem", "value": "C9144"},
              {"label": "Ieper", "value": "C9145"},
              {"label": "Ingelmunster", "value": "C9146"},
              {"label": "Izegem", "value": "C9147"},
              {"label": "Jabbeke", "value": "C9148"},
              {"label": "Kalmthout", "value": "C9149"},
              {"label": "Kampenhout", "value": "C9150"},
              {"label": "Kapelle-op-den-Bos", "value": "C9151"},
              {"label": "Kapellen", "value": "C9152"},
              {"label": "Kaprijke", "value": "C9153"},
              {"label": "Kasterlee", "value": "C9154"},
              {"label": "Keerbergen", "value": "C9155"},
              {"label": "Kinrooi", "value": "C9156"},
              {"label": "Knesselare", "value": "C9157"},
              {"label": "Knokke-Heist", "value": "C9158"},
              {"label": "Koekelare", "value": "C9159"},
              {"label": "Koksijde", "value": "C9160"},
              {"label": "Kontich", "value": "C9161"},
              {"label": "Kortemark", "value": "C9162"},
              {"label": "Kortenaken", "value": "C9163"},
              {"label": "Kortenberg", "value": "C9164"},
              {"label": "Kortessem", "value": "C9165"},
              {"label": "Kortrijk", "value": "C9166"},
              {"label": "Kraainem", "value": "C9167"},
              {"label": "Kruibeke", "value": "C9168"},
              {"label": "Kruishoutem", "value": "C9169"},
              {"label": "Kuurne", "value": "C9170"},
              {"label": "Laarne", "value": "C9171"},
              {"label": "Lanaken", "value": "C9172"},
              {"label": "Landen", "value": "C9173"},
              {"label": "Lebbeke", "value": "C9174"},
              {"label": "Lede", "value": "C9175"},
              {"label": "Ledeberg", "value": "C9176"},
              {"label": "Ledegem", "value": "C9177"},
              {"label": "Lendelede", "value": "C9178"},
              {"label": "Leopoldsburg", "value": "C9179"},
              {"label": "Leuven", "value": "C9180"},
              {"label": "Lichtervelde", "value": "C9181"},
              {"label": "Liedekerke", "value": "C9182"},
              {"label": "Lier", "value": "C9183"},
              {"label": "Lille", "value": "C9184"},
              {"label": "Linkebeek", "value": "C9185"},
              {"label": "Lint", "value": "C9186"},
              {"label": "Lochristi", "value": "C9187"},
              {"label": "Lokeren", "value": "C9188"},
              {"label": "Lommel", "value": "C9189"},
              {"label": "Londerzeel", "value": "C9190"},
              {"label": "Lovendegem", "value": "C9191"},
              {"label": "Lubbeek", "value": "C9192"},
              {"label": "Lummen", "value": "C9193"},
              {"label": "Maaseik", "value": "C9194"},
              {"label": "Maasmechelen", "value": "C9195"},
              {"label": "Machelen", "value": "C9196"},
              {"label": "Maldegem", "value": "C9197"},
              {"label": "Mechelen", "value": "C9198"},
              {"label": "Meerhout", "value": "C9199"},
              {"label": "Meise", "value": "C9200"},
              {"label": "Melle", "value": "C9201"},
              {"label": "Menen", "value": "C9202"},
              {"label": "Merchtem", "value": "C9203"},
              {"label": "Merelbeke", "value": "C9204"},
              {"label": "Merksplas", "value": "C9205"},
              {"label": "Meulebeke", "value": "C9206"},
              {"label": "Middelkerke", "value": "C9207"},
              {"label": "Moerbeke", "value": "C9208"},
              {"label": "Mol", "value": "C9209"},
              {"label": "Moorslede", "value": "C9210"},
              {"label": "Mortsel", "value": "C9211"},
              {"label": "Nazareth", "value": "C9212"},
              {"label": "Neerpelt", "value": "C9213"},
              {"label": "Nevele", "value": "C9214"},
              {"label": "Niel", "value": "C9215"},
              {"label": "Nieuwerkerken", "value": "C9216"},
              {"label": "Nieuwpoort", "value": "C9217"},
              {"label": "Nijlen", "value": "C9218"},
              {"label": "Ninove", "value": "C9219"},
              {"label": "Olen", "value": "C9220"},
              {"label": "Oostduinkerke", "value": "C9221"},
              {"label": "Oosterzele", "value": "C9222"},
              {"label": "Oostkamp", "value": "C9223"},
              {"label": "Oostmalle", "value": "C9224"},
              {"label": "Oostrozebeke", "value": "C9225"},
              {"label": "Opglabbeek", "value": "C9226"},
              {"label": "Opwijk", "value": "C9227"},
              {"label": "Ostend", "value": "C9228"},
              {"label": "Oud-Heverlee", "value": "C9229"},
              {"label": "Oud-Turnhout", "value": "C9230"},
              {"label": "Oudenaarde", "value": "C9231"},
              {"label": "Oudenburg", "value": "C9232"},
              {"label": "Overijse", "value": "C9233"},
              {"label": "Overpelt", "value": "C9234"},
              {"label": "Peer", "value": "C9235"},
              {"label": "Pepingen", "value": "C9236"},
              {"label": "Perre", "value": "C9237"},
              {"label": "Pittem", "value": "C9238"},
              {"label": "Poperinge", "value": "C9239"},
              {"label": "Provincie Antwerpen", "value": "C9240"},
              {"label": "Provincie Limburg", "value": "C9241"},
              {"label": "Provincie Oost-Vlaanderen", "value": "C9242"},
              {"label": "Provincie Vlaams-Brabant", "value": "C9243"},
              {"label": "Provincie West-Vlaanderen", "value": "C9244"},
              {"label": "Putte", "value": "C9245"},
              {"label": "Puurs", "value": "C9246"},
              {"label": "Ranst", "value": "C9247"},
              {"label": "Ravels", "value": "C9248"},
              {"label": "Retie", "value": "C9249"},
              {"label": "Riemst", "value": "C9250"},
              {"label": "Rijkevorsel", "value": "C9251"},
              {"label": "Roeselare", "value": "C9252"},
              {"label": "Ronse", "value": "C9253"},
              {"label": "Rotselaar", "value": "C9254"},
              {"label": "Ruiselede", "value": "C9255"},
              {"label": "Rumst", "value": "C9256"},
              {"label": "Schelle", "value": "C9257"},
              {"label": "Schilde", "value": "C9258"},
              {"label": "Schoten", "value": "C9259"},
              {"label": "Sint-Amands", "value": "C9260"},
              {"label": "Sint-Genesius-Rode", "value": "C9261"},
              {"label": "Sint-Gillis-Waas", "value": "C9262"},
              {"label": "Sint-Joris", "value": "C9263"},
              {"label": "Sint-Katelijne-Waver", "value": "C9264"},
              {"label": "Sint-Kruis", "value": "C9265"},
              {"label": "Sint-Laureins", "value": "C9266"},
              {"label": "Sint-Lievens-Houtem", "value": "C9267"},
              {"label": "Sint-Maria-Lierde", "value": "C9268"},
              {"label": "Sint-Martens-Latem", "value": "C9269"},
              {"label": "Sint-Martens-Lennik", "value": "C9270"},
              {"label": "Sint-Niklaas", "value": "C9271"},
              {"label": "Sint-Pieters-Leeuw", "value": "C9272"},
              {"label": "Sint-Pieters-Voeren", "value": "C9273"},
              {"label": "Sint-Truiden", "value": "C9274"},
              {"label": "Stabroek", "value": "C9275"},
              {"label": "Staden", "value": "C9276"},
              {"label": "Steenokkerzeel", "value": "C9277"},
              {"label": "Stekene", "value": "C9278"},
              {"label": "Temse", "value": "C9279"},
              {"label": "Terkoest", "value": "C9280"},
              {"label": "Ternat", "value": "C9281"},
              {"label": "Tervuren", "value": "C9282"},
              {"label": "Tessenderlo", "value": "C9283"},
              {"label": "Tielt", "value": "C9284"},
              {"label": "Tienen", "value": "C9285"},
              {"label": "Tongeren", "value": "C9286"},
              {"label": "Torhout", "value": "C9287"},
              {"label": "Tremelo", "value": "C9288"},
              {"label": "Turnhout", "value": "C9289"},
              {"label": "Veurne", "value": "C9290"},
              {"label": "Vilvoorde", "value": "C9291"},
              {"label": "Vorselaar", "value": "C9292"},
              {"label": "Vosselaar", "value": "C9293"},
              {"label": "Waarschoot", "value": "C9294"},
              {"label": "Waasmunster", "value": "C9295"},
              {"label": "Wachtebeke", "value": "C9296"},
              {"label": "Waregem", "value": "C9297"},
              {"label": "Wellen", "value": "C9298"},
              {"label": "Wemmel", "value": "C9299"},
              {"label": "Wenduine", "value": "C9300"},
              {"label": "Wervik", "value": "C9301"},
              {"label": "Westerlo", "value": "C9302"},
              {"label": "Wetteren", "value": "C9303"},
              {"label": "Wevelgem", "value": "C9304"},
              {"label": "Wezembeek-Oppem", "value": "C9305"},
              {"label": "Wichelen", "value": "C9306"},
              {"label": "Wielsbeke", "value": "C9307"},
              {"label": "Wijnegem", "value": "C9308"},
              {"label": "Willebroek", "value": "C9309"},
              {"label": "Wingene", "value": "C9310"},
              {"label": "Wommelgem", "value": "C9311"},
              {"label": "Wuustwezel", "value": "C9312"},
              {"label": "Zandhoven", "value": "C9313"},
              {"label": "Zaventem", "value": "C9314"},
              {"label": "Zedelgem", "value": "C9315"},
              {"label": "Zeebrugge", "value": "C9316"},
              {"label": "Zele", "value": "C9317"},
              {"label": "Zelzate", "value": "C9318"},
              {"label": "Zemst", "value": "C9319"},
              {"label": "Zingem", "value": "C9320"},
              {"label": "Zoersel", "value": "C9321"},
              {"label": "Zomergem", "value": "C9322"},
              {"label": "Zonhoven", "value": "C9323"},
              {"label": "Zonnebeke", "value": "C9324"},
              {"label": "Zottegem", "value": "C9325"},
              {"label": "Zoutleeuw", "value": "C9326"},
              {"label": "Zuienkerke", "value": "C9327"},
              {"label": "Zulte", "value": "C9328"},
              {"label": "Zutendaal", "value": "C9329"},
              {"label": "Zwevegem", "value": "C9330"},
              {"label": "Zwijndrecht", "value": "C9331"}],
 "Flevoland": [{"label": "Almere Stad", "value": "C76146"},
               {"label": "Biddinghuizen", "value": "C76147"},
               {"label": "Dronten", "value": "C76148"},
               {"label": "Emmeloord", "value": "C76149"},
               {"label": "Ens", "value": "C76150"},
               {"label": "Lelystad", "value": "C76151"},
               {"label": "Urk", "value": "C76152"},
               {"label": "Zeewolde", "value": "C76153"}],
 "Flores Department": [{"label": "Trinidad", "value": "C146881"}],
 "Floresti District": [{"label": "Floresti", "value": "C75514"},
                       {"label": "Ghindesti", "value": "C75515"},
                       {"label": "Marculesti", "value": "C75516"}],
 "Floriana": [{"label": "Floriana", "value": "C65877"}],
 "Florida": [{"label": "Aberdeen", "value": "C129654"},
             {"label": "Alachua", "value": "C129655"},
             {"label": "Alachua County", "value": "C129656"},
             {"label": "Alafaya", "value": "C129657"},
             {"label": "Allapattah", "value": "C129658"},
             {"label": "Altamonte Springs", "value": "C129659"},
             {"label": "Alturas", "value": "C129660"},
             {"label": "Alva", "value": "C129661"},
             {"label": "Andover", "value": "C129662"},
             {"label": "Anna Maria", "value": "C129663"},
             {"label": "Apalachicola", "value": "C129664"},
             {"label": "Apollo Beach", "value": "C129665"},
             {"label": "Apopka", "value": "C129666"},
             {"label": "Arcadia", "value": "C129667"},
             {"label": "Archer", "value": "C129668"},
             {"label": "Asbury Lake", "value": "C129669"},
             {"label": "Astatula", "value": "C129670"},
             {"label": "Astor", "value": "C129671"},
             {"label": "Atlantic Beach", "value": "C129672"},
             {"label": "Atlantis", "value": "C129673"},
             {"label": "Auburndale", "value": "C129674"},
             {"label": "Aventura", "value": "C129675"},
             {"label": "Avon Park", "value": "C129676"},
             {"label": "Azalea Park", "value": "C129677"},
             {"label": "Babson Park", "value": "C129678"},
             {"label": "Bagdad", "value": "C129679"},
             {"label": "Baker County", "value": "C129680"},
             {"label": "Bal Harbour", "value": "C129681"},
             {"label": "Baldwin", "value": "C129682"},
             {"label": "Balm", "value": "C129683"},
             {"label": "Bartow", "value": "C129684"},
             {"label": "Bay County", "value": "C129685"},
             {"label": "Bay Harbor Islands", "value": "C129686"},
             {"label": "Bay Hill", "value": "C129687"},
             {"label": "Bay Pines", "value": "C129688"},
             {"label": "Bayonet Point", "value": "C129689"},
             {"label": "Bayshore Gardens", "value": "C129690"},
             {"label": "Beacon Square", "value": "C129691"},
             {"label": "Bee Ridge", "value": "C129692"},
             {"label": "Bellair-Meadowbrook Terrace", "value": "C129693"},
             {"label": "Belle Glade", "value": "C129694"},
             {"label": "Belle Glade Camp", "value": "C129695"},
             {"label": "Belle Isle", "value": "C129696"},
             {"label": "Belleair", "value": "C129697"},
             {"label": "Belleair Beach", "value": "C129698"},
             {"label": "Belleair Bluffs", "value": "C129699"},
             {"label": "Belleview", "value": "C129700"},
             {"label": "Bellview", "value": "C129701"},
             {"label": "Beverly Hills", "value": "C129702"},
             {"label": "Big Coppitt Key", "value": "C129703"},
             {"label": "Big Pine Key", "value": "C129704"},
             {"label": "Biscayne Park", "value": "C129705"},
             {"label": "Bithlo", "value": "C129706"},
             {"label": "Black Diamond", "value": "C129707"},
             {"label": "Bloomingdale", "value": "C129708"},
             {"label": "Blountstown", "value": "C129709"},
             {"label": "Boca Del Mar", "value": "C129710"},
             {"label": "Boca Pointe", "value": "C129711"},
             {"label": "Boca Raton", "value": "C129712"},
             {"label": "Bokeelia", "value": "C129713"},
             {"label": "Bonifay", "value": "C129714"},
             {"label": "Bonita Springs", "value": "C129715"},
             {"label": "Boulevard Gardens", "value": "C129716"},
             {"label": "Bowling Green", "value": "C129717"},
             {"label": "Boyette", "value": "C129718"},
             {"label": "Boynton Beach", "value": "C129719"},
             {"label": "Bradenton", "value": "C129720"},
             {"label": "Bradenton Beach", "value": "C129721"},
             {"label": "Bradford County", "value": "C129722"},
             {"label": "Brandon", "value": "C129723"},
             {"label": "Brent", "value": "C129724"},
             {"label": "Brevard County", "value": "C129725"},
             {"label": "Bristol", "value": "C129726"},
             {"label": "Broadview Park", "value": "C129727"},
             {"label": "Bronson", "value": "C129728"},
             {"label": "Brookridge", "value": "C129729"},
             {"label": "Brooksville", "value": "C129730"},
             {"label": "Broward County", "value": "C129731"},
             {"label": "Broward Estates", "value": "C129732"},
             {"label": "Brownsville", "value": "C129733"},
             {"label": "Buckhead Ridge", "value": "C129734"},
             {"label": "Buckingham", "value": "C129735"},
             {"label": "Buenaventura Lakes", "value": "C129736"},
             {"label": "Bunche Park", "value": "C129737"},
             {"label": "Bunnell", "value": "C129738"},
             {"label": "Burnt Store Marina", "value": "C129739"},
             {"label": "Bushnell", "value": "C129740"},
             {"label": "Butler Beach", "value": "C129741"},
             {"label": "Calhoun County", "value": "C129742"},
             {"label": "Callahan", "value": "C129743"},
             {"label": "Callaway", "value": "C129744"},
             {"label": "Campbell", "value": "C129745"},
             {"label": "Cantonment", "value": "C129746"},
             {"label": "Cape Canaveral", "value": "C129747"},
             {"label": "Cape Coral", "value": "C129748"},
             {"label": "Carol City", "value": "C129749"},
             {"label": "Carrabelle", "value": "C129750"},
             {"label": "Carrollwood", "value": "C129751"},
             {"label": "Carrollwood Village", "value": "C129752"},
             {"label": "Carver Ranches", "value": "C129753"},
             {"label": "Casselberry", "value": "C129754"},
             {"label": "Cedar Grove", "value": "C129755"},
             {"label": "Celebration", "value": "C129756"},
             {"label": "Center Hill", "value": "C129757"},
             {"label": "Century", "value": "C129758"},
             {"label": "Charlotte County", "value": "C129759"},
             {"label": "Charlotte Harbor", "value": "C129760"},
             {"label": "Charlotte Park", "value": "C129761"},
             {"label": "Chattahoochee", "value": "C129762"},
             {"label": "Cheval", "value": "C129763"},
             {"label": "Chiefland", "value": "C129764"},
             {"label": "Chipley", "value": "C129765"},
             {"label": "Christmas", "value": "C129766"},
             {"label": "Chuluota", "value": "C129767"},
             {"label": "Citra", "value": "C129768"},
             {"label": "Citrus County", "value": "C129769"},
             {"label": "Citrus Hills", "value": "C129770"},
             {"label": "Citrus Park", "value": "C129771"},
             {"label": "Citrus Ridge", "value": "C129772"},
             {"label": "Citrus Springs", "value": "C129773"},
             {"label": "Clarcona", "value": "C129774"},
             {"label": "Clay County", "value": "C129775"},
             {"label": "Clearwater", "value": "C129776"},
             {"label": "Clermont", "value": "C129777"},
             {"label": "Cleveland", "value": "C129778"},
             {"label": "Clewiston", "value": "C129779"},
             {"label": "Cocoa", "value": "C129780"},
             {"label": "Cocoa Beach", "value": "C129781"},
             {"label": "Cocoa West", "value": "C129782"},
             {"label": "Coconut Creek", "value": "C129783"},
             {"label": "Coconut Grove", "value": "C129784"},
             {"label": "Collier County", "value": "C129785"},
             {"label": "Columbia County", "value": "C129786"},
             {"label": "Combee Settlement", "value": "C129787"},
             {"label": "Connerton", "value": "C129788"},
             {"label": "Conway", "value": "C129789"},
             {"label": "Cooper City", "value": "C129790"},
             {"label": "Coral Gables", "value": "C129791"},
             {"label": "Coral Springs", "value": "C129792"},
             {"label": "Coral Terrace", "value": "C129793"},
             {"label": "Cortez", "value": "C129794"},
             {"label": "Country Club", "value": "C129795"},
             {"label": "Country Walk", "value": "C129796"},
             {"label": "Crawfordville", "value": "C129797"},
             {"label": "Crescent City", "value": "C129798"},
             {"label": "Crestview", "value": "C129799"},
             {"label": "Crooked Lake Park", "value": "C129800"},
             {"label": "Cross City", "value": "C129801"},
             {"label": "Crystal Lake", "value": "C129802"},
             {"label": "Crystal River", "value": "C129803"},
             {"label": "Crystal Springs", "value": "C129804"},
             {"label": "Cudjoe Key", "value": "C129805"},
             {"label": "Cutler", "value": "C129806"},
             {"label": "Cutler Bay", "value": "C129807"},
             {"label": "Cutler Ridge", "value": "C129808"},
             {"label": "Cypress Gardens", "value": "C129809"},
             {"label": "Cypress Lake", "value": "C129810"},
             {"label": "Cypress Quarters", "value": "C129811"},
             {"label": "Dade City", "value": "C129812"},
             {"label": "Dade City North", "value": "C129813"},
             {"label": "Dania Beach", "value": "C129814"},
             {"label": "Davenport", "value": "C129815"},
             {"label": "Davie", "value": "C129816"},
             {"label": "Daytona Beach", "value": "C129817"},
             {"label": "Daytona Beach Shores", "value": "C129818"},
             {"label": "De Land Southwest", "value": "C129819"},
             {"label": "De Leon Springs", "value": "C129820"},
             {"label": "DeBary", "value": "C129821"},
             {"label": "Deerfield Beach", "value": "C129822"},
             {"label": "DeFuniak Springs", "value": "C129823"},
             {"label": "DeLand", "value": "C129824"},
             {"label": "Delray Beach", "value": "C129825"},
             {"label": "Deltona", "value": "C129826"},
             {"label": "DeSoto County", "value": "C129827"},
             {"label": "Desoto Lakes", "value": "C129828"},
             {"label": "Destin", "value": "C129829"},
             {"label": "Dixie County", "value": "C129830"},
             {"label": "Doctor Phillips", "value": "C129831"},
             {"label": "Doral", "value": "C129832"},
             {"label": "Dover", "value": "C129833"},
             {"label": "Dundee", "value": "C129834"},
             {"label": "Dunedin", "value": "C129835"},
             {"label": "Dunnellon", "value": "C129836"},
             {"label": "Duval County", "value": "C129837"},
             {"label": "Eagle Lake", "value": "C129838"},
             {"label": "East Bronson", "value": "C129839"},
             {"label": "East Lake", "value": "C129840"},
             {"label": "East Lake-Orient Park", "value": "C129841"},
             {"label": "East Milton", "value": "C129842"},
             {"label": "East Naples", "value": "C129843"},
             {"label": "East Palatka", "value": "C129844"},
             {"label": "East Pensacola Heights", "value": "C129845"},
             {"label": "East Perrine", "value": "C129846"},
             {"label": "Eastpoint", "value": "C129847"},
             {"label": "Eatonville", "value": "C129848"},
             {"label": "Edgewater", "value": "C129849"},
             {"label": "Edgewood", "value": "C129850"},
             {"label": "Eglin Air Force Base", "value": "C129851"},
             {"label": "Eglin Village", "value": "C129852"},
             {"label": "Egypt Lake-Leto", "value": "C129853"},
             {"label": "El Portal", "value": "C129854"},
             {"label": "Elfers", "value": "C129855"},
             {"label": "Ellenton", "value": "C129856"},
             {"label": "Englewood", "value": "C129857"},
             {"label": "Ensley", "value": "C129858"},
             {"label": "Escambia County", "value": "C129859"},
             {"label": "Estero", "value": "C129860"},
             {"label": "Eustis", "value": "C129861"},
             {"label": "Fairview Shores", "value": "C129862"},
             {"label": "Feather Sound", "value": "C129863"},
             {"label": "Fellsmere", "value": "C129864"},
             {"label": "Fern Park", "value": "C129865"},
             {"label": "Fernandina Beach", "value": "C129866"},
             {"label": "Ferry Pass", "value": "C129867"},
             {"label": "Fish Hawk", "value": "C129868"},
             {"label": "Five Points", "value": "C129869"},
             {"label": "Flagami", "value": "C129870"},
             {"label": "Flagler Beach", "value": "C129871"},
             {"label": "Flagler County", "value": "C129872"},
             {"label": "Flagler Estates", "value": "C129873"},
             {"label": "Fleming Island", "value": "C129874"},
             {"label": "Floral City", "value": "C129875"},
             {"label": "Florida City", "value": "C129876"},
             {"label": "Florida Ridge", "value": "C129877"},
             {"label": "Forest City", "value": "C129878"},
             {"label": "Fort Lauderdale", "value": "C129879"},
             {"label": "Fort Meade", "value": "C129880"},
             {"label": "Fort Myers", "value": "C129881"},
             {"label": "Fort Myers Beach", "value": "C129882"},
             {"label": "Fort Myers Shores", "value": "C129883"},
             {"label": "Fort Pierce", "value": "C129884"},
             {"label": "Fort Pierce North", "value": "C129885"},
             {"label": "Fort Pierce South", "value": "C129886"},
             {"label": "Fort Walton Beach", "value": "C129887"},
             {"label": "Fountainebleau", "value": "C129888"},
             {"label": "Four Corners", "value": "C129889"},
             {"label": "Franklin County", "value": "C129890"},
             {"label": "Freeport", "value": "C129891"},
             {"label": "Frostproof", "value": "C129892"},
             {"label": "Fruit Cove", "value": "C129893"},
             {"label": "Fruitland Park", "value": "C129894"},
             {"label": "Fruitville", "value": "C129895"},
             {"label": "Fuller Heights", "value": "C129896"},
             {"label": "Fussels Corner", "value": "C129897"},
             {"label": "Gadsden County", "value": "C129898"},
             {"label": "Gainesville", "value": "C129899"},
             {"label": "Gandy", "value": "C129900"},
             {"label": "Gateway", "value": "C129901"},
             {"label": "Geneva", "value": "C129902"},
             {"label": "Gibsonia", "value": "C129903"},
             {"label": "Gibsonton", "value": "C129904"},
             {"label": "Gifford", "value": "C129905"},
             {"label": "Gilchrist County", "value": "C129906"},
             {"label": "Glades County", "value": "C129907"},
             {"label": "Gladeview", "value": "C129908"},
             {"label": "Glencoe", "value": "C129909"},
             {"label": "Glenvar Heights", "value": "C129910"},
             {"label": "Golden Gate", "value": "C129911"},
             {"label": "Golden Glades", "value": "C129912"},
             {"label": "Goldenrod", "value": "C129913"},
             {"label": "Gonzalez", "value": "C129914"},
             {"label": "Gotha", "value": "C129915"},
             {"label": "Goulding", "value": "C129916"},
             {"label": "Goulds", "value": "C129917"},
             {"label": "Graceville", "value": "C129918"},
             {"label": "Grant-Valkaria", "value": "C129919"},
             {"label": "Greater Northdale", "value": "C129920"},
             {"label": "Green Cove Springs", "value": "C129921"},
             {"label": "Greenacres City", "value": "C129922"},
             {"label": "Greenbriar", "value": "C129923"},
             {"label": "Gretna", "value": "C129924"},
             {"label": "Grove City", "value": "C129925"},
             {"label": "Groveland", "value": "C129926"},
             {"label": "Gulf Breeze", "value": "C129927"},
             {"label": "Gulf County", "value": "C129928"},
             {"label": "Gulf Gate Estates", "value": "C129929"},
             {"label": "Gulfport", "value": "C129930"},
             {"label": "Haines City", "value": "C129931"},
             {"label": "Hallandale Beach", "value": "C129932"},
             {"label": "Hamilton County", "value": "C129933"},
             {"label": "Harbor Bluffs", "value": "C129934"},
             {"label": "Harbour Heights", "value": "C129935"},
             {"label": "Hardee County", "value": "C129936"},
             {"label": "Harlem", "value": "C129937"},
             {"label": "Harlem Heights", "value": "C129938"},
             {"label": "Havana", "value": "C129939"},
             {"label": "Haverhill", "value": "C129940"},
             {"label": "Hawthorne", "value": "C129941"},
             {"label": "Heathrow", "value": "C129942"},
             {"label": "Hendry County", "value": "C129943"},
             {"label": "Heritage Pines", "value": "C129944"},
             {"label": "Hernando", "value": "C129945"},
             {"label": "Hernando Beach", "value": "C129946"},
             {"label": "Hernando County", "value": "C129947"},
             {"label": "Hialeah", "value": "C129948"},
             {"label": "Hialeah Gardens", "value": "C129949"},
             {"label": "High Point", "value": "C129950"},
             {"label": "High Springs", "value": "C129951"},
             {"label": "Highland Beach", "value": "C129952"},
             {"label": "Highland City", "value": "C129953"},
             {"label": "Highlands County", "value": "C129954"},
             {"label": "Hiland Park", "value": "C129955"},
             {"label": "Hill n Dale", "value": "C129956"},
             {"label": "Hilliard", "value": "C129957"},
             {"label": "Hillsboro Beach", "value": "C129958"},
             {"label": "Hillsborough County", "value": "C129959"},
             {"label": "Hobe Sound", "value": "C129960"},
             {"label": "Holden Heights", "value": "C129961"},
             {"label": "Holiday", "value": "C129962"},
             {"label": "Holley", "value": "C129963"},
             {"label": "Holly Hill", "value": "C129964"},
             {"label": "Hollywood", "value": "C129965"},
             {"label": "Holmes Beach", "value": "C129966"},
             {"label": "Holmes County", "value": "C129967"},
             {"label": "Homestead", "value": "C129968"},
             {"label": "Homosassa", "value": "C129969"},
             {"label": "Homosassa Springs", "value": "C129970"},
             {"label": "Horizon West", "value": "C129971"},
             {"label": "Howey-in-the-Hills", "value": "C129972"},
             {"label": "Hudson", "value": "C129973"},
             {"label": "Hunters Creek", "value": "C129974"},
             {"label": "Hutchinson Island South", "value": "C129975"},
             {"label": "Hypoluxo", "value": "C129976"},
             {"label": "Immokalee", "value": "C129977"},
             {"label": "Indialantic", "value": "C129978"},
             {"label": "Indian Harbour Beach", "value": "C129979"},
             {"label": "Indian River County", "value": "C129980"},
             {"label": "Indian River Estates", "value": "C129981"},
             {"label": "Indian River Shores", "value": "C129982"},
             {"label": "Indian Rocks Beach", "value": "C129983"},
             {"label": "Indian Shores", "value": "C129984"},
             {"label": "Indiantown", "value": "C129985"},
             {"label": "Inglis", "value": "C129986"},
             {"label": "Interlachen", "value": "C129987"},
             {"label": "Inverness", "value": "C129988"},
             {"label": "Inverness Highlands North", "value": "C129989"},
             {"label": "Inverness Highlands South", "value": "C129990"},
             {"label": "Inwood", "value": "C129991"},
             {"label": "Iona", "value": "C129992"},
             {"label": "Islamorada", "value": "C129993"},
             {"label": "Island Walk", "value": "C129994"},
             {"label": "Isle of Normandy", "value": "C129995"},
             {"label": "Ives Estates", "value": "C129996"},
             {"label": "Jackson County", "value": "C129997"},
             {"label": "Jacksonville", "value": "C129998"},
             {"label": "Jacksonville Beach", "value": "C129999"},
             {"label": "Jan-Phyl Village", "value": "C130000"},
             {"label": "Jasmine Estates", "value": "C130001"},
             {"label": "Jasper", "value": "C130002"},
             {"label": "Jefferson County", "value": "C130003"},
             {"label": "Jensen Beach", "value": "C130004"},
             {"label": "June Park", "value": "C130005"},
             {"label": "Juno Beach", "value": "C130006"},
             {"label": "Jupiter", "value": "C130007"},
             {"label": "Kathleen", "value": "C130008"},
             {"label": "Kendale Lakes", "value": "C130009"},
             {"label": "Kendall", "value": "C130010"},
             {"label": "Kendall Green", "value": "C130011"},
             {"label": "Kendall West", "value": "C130012"},
             {"label": "Kenneth City", "value": "C130013"},
             {"label": "Kensington Park", "value": "C130014"},
             {"label": "Key Biscayne", "value": "C130015"},
             {"label": "Key Largo", "value": "C130016"},
             {"label": "Key Vista", "value": "C130017"},
             {"label": "Key West", "value": "C130018"},
             {"label": "Keystone", "value": "C130019"},
             {"label": "Keystone Heights", "value": "C130020"},
             {"label": "Kings Point", "value": "C130021"},
             {"label": "Kissimmee", "value": "C130022"},
             {"label": "LaBelle", "value": "C130023"},
             {"label": "Lacoochee", "value": "C130024"},
             {"label": "Lady Lake", "value": "C130025"},
             {"label": "Lafayette County", "value": "C130026"},
             {"label": "Laguna Beach", "value": "C130027"},
             {"label": "Lake Alfred", "value": "C130028"},
             {"label": "Lake Belvedere Estates", "value": "C130029"},
             {"label": "Lake Butler", "value": "C130030"},
             {"label": "Lake City", "value": "C130031"},
             {"label": "Lake Clarke Shores", "value": "C130032"},
             {"label": "Lake County", "value": "C130033"},
             {"label": "Lake Forest", "value": "C130034"},
             {"label": "Lake Hamilton", "value": "C130035"},
             {"label": "Lake Helen", "value": "C130036"},
             {"label": "Lake Lorraine", "value": "C130037"},
             {"label": "Lake Lucerne", "value": "C130038"},
             {"label": "Lake Mack-Forest Hills", "value": "C130039"},
             {"label": "Lake Magdalene", "value": "C130040"},
             {"label": "Lake Mary", "value": "C130041"},
             {"label": "Lake Panasoffkee", "value": "C130042"},
             {"label": "Lake Park", "value": "C130043"},
             {"label": "Lake Placid", "value": "C130044"},
             {"label": "Lake Sarasota", "value": "C130045"},
             {"label": "Lake Wales", "value": "C130046"},
             {"label": "Lake Worth", "value": "C130047"},
             {"label": "Lake Worth Corridor", "value": "C130048"},
             {"label": "Lakeland", "value": "C130049"},
             {"label": "Lakeland Highlands", "value": "C130050"},
             {"label": "Lakes by the Bay", "value": "C130051"},
             {"label": "Lakeside", "value": "C130052"},
             {"label": "Lakewood Park", "value": "C130053"},
             {"label": "Land O Lakes", "value": "C130054"},
             {"label": "Lantana", "value": "C130055"},
             {"label": "Largo", "value": "C130056"},
             {"label": "Lauderdale Lakes", "value": "C130057"},
             {"label": "Lauderdale-by-the-Sea", "value": "C130058"},
             {"label": "Lauderhill", "value": "C130059"},
             {"label": "Laurel", "value": "C130060"},
             {"label": "Lealman", "value": "C130061"},
             {"label": "Lecanto", "value": "C130062"},
             {"label": "Lee County", "value": "C130063"},
             {"label": "Leesburg", "value": "C130064"},
             {"label": "Lehigh Acres", "value": "C130065"},
             {"label": "Leisure City", "value": "C130066"},
             {"label": "Lely", "value": "C130067"},
             {"label": "Lely Resort", "value": "C130068"},
             {"label": "Leon County", "value": "C130069"},
             {"label": "Levy County", "value": "C130070"},
             {"label": "Liberty County", "value": "C130071"},
             {"label": "Lighthouse Point", "value": "C130072"},
             {"label": "Limestone Creek", "value": "C130073"},
             {"label": "Live Oak", "value": "C130074"},
             {"label": "Lochmoor Waterway Estates", "value": "C130075"},
             {"label": "Lockhart", "value": "C130076"},
             {"label": "Longboat Key", "value": "C130077"},
             {"label": "Longwood", "value": "C130078"},
             {"label": "Loughman", "value": "C130079"},
             {"label": "Lower Grand Lagoon", "value": "C130080"},
             {"label": "Loxahatchee Groves", "value": "C130081"},
             {"label": "Lutz", "value": "C130082"},
             {"label": "Lynn Haven", "value": "C130083"},
             {"label": "Macclenny", "value": "C130084"},
             {"label": "Madeira Beach", "value": "C130085"},
             {"label": "Madison", "value": "C130086"},
             {"label": "Madison County", "value": "C130087"},
             {"label": "Maitland", "value": "C130088"},
             {"label": "Malabar", "value": "C130089"},
             {"label": "Malone", "value": "C130090"},
             {"label": "Manasota Key", "value": "C130091"},
             {"label": "Manatee County", "value": "C130092"},
             {"label": "Manatee Road", "value": "C130093"},
             {"label": "Mango", "value": "C130094"},
             {"label": "Mangonia Park", "value": "C130095"},
             {"label": "Marathon", "value": "C130096"},
             {"label": "Marco", "value": "C130097"},
             {"label": "Marco Island", "value": "C130098"},
             {"label": "Margate", "value": "C130099"},
             {"label": "Marianna", "value": "C130100"},
             {"label": "Marion County", "value": "C130101"},
             {"label": "Martin County", "value": "C130102"},
             {"label": "Mary Esther", "value": "C130103"},
             {"label": "Masaryktown", "value": "C130104"},
             {"label": "Mascotte", "value": "C130105"},
             {"label": "Mayo", "value": "C130106"},
             {"label": "McGregor", "value": "C130107"},
             {"label": "Meadow Oaks", "value": "C130108"},
             {"label": "Meadow Woods", "value": "C130109"},
             {"label": "Medulla", "value": "C130110"},
             {"label": "Melbourne", "value": "C130111"},
             {"label": "Melbourne Beach", "value": "C130112"},
             {"label": "Melrose Park", "value": "C130113"},
             {"label": "Memphis", "value": "C130114"},
             {"label": "Merritt Island", "value": "C130115"},
             {"label": "Mexico Beach", "value": "C130116"},
             {"label": "Miami", "value": "C130117"},
             {"label": "Miami Beach", "value": "C130118"},
             {"label": "Miami Gardens", "value": "C130119"},
             {"label": "Miami Lakes", "value": "C130120"},
             {"label": "Miami Shores", "value": "C130121"},
             {"label": "Miami Springs", "value": "C130122"},
             {"label": "Miami-Dade County", "value": "C130123"},
             {"label": "Micco", "value": "C130124"},
             {"label": "Middleburg", "value": "C130125"},
             {"label": "Midway", "value": "C130126"},
             {"label": "Milton", "value": "C130127"},
             {"label": "Mims", "value": "C130128"},
             {"label": "Minneola", "value": "C130129"},
             {"label": "Miramar", "value": "C130130"},
             {"label": "Miramar Beach", "value": "C130131"},
             {"label": "Molino", "value": "C130132"},
             {"label": "Monroe County", "value": "C130133"},
             {"label": "Monticello", "value": "C130134"},
             {"label": "Montverde", "value": "C130135"},
             {"label": "Moore Haven", "value": "C130136"},
             {"label": "Mount Dora", "value": "C130137"},
             {"label": "Mount Plymouth", "value": "C130138"},
             {"label": "Mulberry", "value": "C130139"},
             {"label": "Myrtle Grove", "value": "C130140"},
             {"label": "Naples", "value": "C130141"},
             {"label": "Naples Manor", "value": "C130142"},
             {"label": "Naples Park", "value": "C130143"},
             {"label": "Naranja", "value": "C130144"},
             {"label": "Nassau County", "value": "C130145"},
             {"label": "Nassau Village-Ratliff", "value": "C130146"},
             {"label": "Navarre", "value": "C130147"},
             {"label": "Neptune Beach", "value": "C130148"},
             {"label": "New Port Richey", "value": "C130149"},
             {"label": "New Port Richey East", "value": "C130150"},
             {"label": "New Smyrna Beach", "value": "C130151"},
             {"label": "Newberry", "value": "C130152"},
             {"label": "Niceville", "value": "C130153"},
             {"label": "Nocatee", "value": "C130154"},
             {"label": "Nokomis", "value": "C130155"},
             {"label": "Norland", "value": "C130156"},
             {"label": "North Andrews Gardens", "value": "C130157"},
             {"label": "North Bay Village", "value": "C130158"},
             {"label": "North Brooksville", "value": "C130159"},
             {"label": "North DeLand", "value": "C130160"},
             {"label": "North Fort Myers", "value": "C130161"},
             {"label": "North Key Largo", "value": "C130162"},
             {"label": "North Lauderdale", "value": "C130163"},
             {"label": "North Miami", "value": "C130164"},
             {"label": "North Miami Beach", "value": "C130165"},
             {"label": "North Palm Beach", "value": "C130166"},
             {"label": "North Port", "value": "C130167"},
             {"label": "North Redington Beach", "value": "C130168"},
             {"label": "North River Shores", "value": "C130169"},
             {"label": "North Sarasota", "value": "C130170"},
             {"label": "North Weeki Wachee", "value": "C130171"},
             {"label": "Northdale", "value": "C130172"},
             {"label": "Oak Hill", "value": "C130173"},
             {"label": "Oak Ridge", "value": "C130174"},
             {"label": "Oakland", "value": "C130175"},
             {"label": "Oakland Park", "value": "C130176"},
             {"label": "Oakleaf Plantation", "value": "C130177"},
             {"label": "Ocala", "value": "C130178"},
             {"label": "Ocean City", "value": "C130179"},
             {"label": "Ocean Ridge", "value": "C130180"},
             {"label": "Ocoee", "value": "C130181"},
             {"label": "Odessa", "value": "C130182"},
             {"label": "Ojus", "value": "C130183"},
             {"label": "Okaloosa County", "value": "C130184"},
             {"label": "Okeechobee", "value": "C130185"},
             {"label": "Okeechobee County", "value": "C130186"},
             {"label": "Oldsmar", "value": "C130187"},
             {"label": "Olga", "value": "C130188"},
             {"label": "Olympia Heights", "value": "C130189"},
             {"label": "Opa-locka", "value": "C130190"},
             {"label": "Orange City", "value": "C130191"},
             {"label": "Orange County", "value": "C130192"},
             {"label": "Orange Park", "value": "C130193"},
             {"label": "Orangetree", "value": "C130194"},
             {"label": "Oriole Beach", "value": "C130195"},
             {"label": "Orlando", "value": "C130196"},
             {"label": "Orlovista", "value": "C130197"},
             {"label": "Ormond Beach", "value": "C130198"},
             {"label": "Ormond-by-the-Sea", "value": "C130199"},
             {"label": "Osceola County", "value": "C130200"},
             {"label": "Osprey", "value": "C130201"},
             {"label": "Oviedo", "value": "C130202"},
             {"label": "Pace", "value": "C130203"},
             {"label": "Pahokee", "value": "C130204"},
             {"label": "Palatka", "value": "C130205"},
             {"label": "Palm Aire", "value": "C130206"},
             {"label": "Palm Bay", "value": "C130207"},
             {"label": "Palm Beach", "value": "C130208"},
             {"label": "Palm Beach County", "value": "C130209"},
             {"label": "Palm Beach Gardens", "value": "C130210"},
             {"label": "Palm Beach Shores", "value": "C130211"},
             {"label": "Palm City", "value": "C130212"},
             {"label": "Palm Coast", "value": "C130213"},
             {"label": "Palm Harbor", "value": "C130214"},
             {"label": "Palm River-Clair Mel", "value": "C130215"},
             {"label": "Palm Springs", "value": "C130216"},
             {"label": "Palm Springs North", "value": "C130217"},
             {"label": "Palm Valley", "value": "C130218"},
             {"label": "Palmetto", "value": "C130219"},
             {"label": "Palmetto Bay", "value": "C130220"},
             {"label": "Palmetto Estates", "value": "C130221"},
             {"label": "Palmona Park", "value": "C130222"},
             {"label": "Panama City", "value": "C130223"},
             {"label": "Panama City Beach", "value": "C130224"},
             {"label": "Paradise Heights", "value": "C130225"},
             {"label": "Parker", "value": "C130226"},
             {"label": "Parkland", "value": "C130227"},
             {"label": "Pasadena Hills", "value": "C130228"},
             {"label": "Pasco County", "value": "C130229"},
             {"label": "Pebble Creek", "value": "C130230"},
             {"label": "Pelican Bay", "value": "C130231"},
             {"label": "Pembroke Park", "value": "C130232"},
             {"label": "Pembroke Pines", "value": "C130233"},
             {"label": "Pensacola", "value": "C130234"},
             {"label": "Perry", "value": "C130235"},
             {"label": "Pierson", "value": "C130236"},
             {"label": "Pine Castle", "value": "C130237"},
             {"label": "Pine Hills", "value": "C130238"},
             {"label": "Pine Island Center", "value": "C130239"},
             {"label": "Pine Island Ridge", "value": "C130240"},
             {"label": "Pine Manor", "value": "C130241"},
             {"label": "Pine Ridge", "value": "C130242"},
             {"label": "Pinecrest", "value": "C130243"},
             {"label": "Pinellas County", "value": "C130244"},
             {"label": "Pinellas Park", "value": "C130245"},
             {"label": "Pinewood", "value": "C130246"},
             {"label": "Placid Lakes", "value": "C130247"},
             {"label": "Plant City", "value": "C130248"},
             {"label": "Plantation", "value": "C130249"},
             {"label": "Plantation Mobile Home Park", "value": "C130250"},
             {"label": "Poinciana", "value": "C130251"},
             {"label": "Point Baker", "value": "C130252"},
             {"label": "Polk City", "value": "C130253"},
             {"label": "Polk County", "value": "C130254"},
             {"label": "Pompano Beach", "value": "C130255"},
             {"label": "Pompano Beach Highlands", "value": "C130256"},
             {"label": "Ponce Inlet", "value": "C130257"},
             {"label": "Ponte Vedra Beach", "value": "C130258"},
             {"label": "Port Charlotte", "value": "C130259"},
             {"label": "Port LaBelle", "value": "C130260"},
             {"label": "Port Orange", "value": "C130261"},
             {"label": "Port Richey", "value": "C130262"},
             {"label": "Port Saint Joe", "value": "C130263"},
             {"label": "Port Saint John", "value": "C130264"},
             {"label": "Port Saint Lucie", "value": "C130265"},
             {"label": "Port Salerno", "value": "C130266"},
             {"label": "Pretty Bayou", "value": "C130267"},
             {"label": "Princeton", "value": "C130268"},
             {"label": "Progress Village", "value": "C130269"},
             {"label": "Punta Gorda", "value": "C130270"},
             {"label": "Punta Gorda Isles", "value": "C130271"},
             {"label": "Punta Rassa", "value": "C130272"},
             {"label": "Putnam County", "value": "C130273"},
             {"label": "Quail Ridge", "value": "C130274"},
             {"label": "Quincy", "value": "C130275"},
             {"label": "Redington Beach", "value": "C130276"},
             {"label": "Redington Shores", "value": "C130277"},
             {"label": "Richmond Heights", "value": "C130278"},
             {"label": "Richmond West", "value": "C130279"},
             {"label": "Ridge Manor", "value": "C130280"},
             {"label": "Ridge Wood Heights", "value": "C130281"},
             {"label": "Ridgecrest", "value": "C130282"},
             {"label": "River Park", "value": "C130283"},
             {"label": "Riverview", "value": "C130284"},
             {"label": "Riviera Beach", "value": "C130285"},
             {"label": "Rock Island", "value": "C130286"},
             {"label": "Rockledge", "value": "C130287"},
             {"label": "Roosevelt Gardens", "value": "C130288"},
             {"label": "Roseland", "value": "C130289"},
             {"label": "Rotonda West", "value": "C130290"},
             {"label": "Royal Palm Beach", "value": "C130291"},
             {"label": "Royal Palm Estates", "value": "C130292"},
             {"label": "Ruskin", "value": "C130293"},
             {"label": "Safety Harbor", "value": "C130294"},
             {"label": "Saint Augustine", "value": "C130295"},
             {"label": "Saint Augustine Beach", "value": "C130296"},
             {"label": "Saint Augustine Shores", "value": "C130297"},
             {"label": "Saint Augustine South", "value": "C130298"},
             {"label": "Saint Cloud", "value": "C130299"},
             {"label": "Saint George", "value": "C130300"},
             {"label": "Saint James City", "value": "C130301"},
             {"label": "Saint Johns County", "value": "C130302"},
             {"label": "Saint Leo", "value": "C130303"},
             {"label": "Saint Lucie County", "value": "C130304"},
             {"label": "Saint Pete Beach", "value": "C130305"},
             {"label": "Samoset", "value": "C130306"},
             {"label": "Samsula-Spruce Creek", "value": "C130307"},
             {"label": "San Antonio", "value": "C130308"},
             {"label": "San Carlos Park", "value": "C130309"},
             {"label": "Sandalfoot Cove", "value": "C130310"},
             {"label": "Sanford", "value": "C130311"},
             {"label": "Sanibel", "value": "C130312"},
             {"label": "Santa Rosa County", "value": "C130313"},
             {"label": "Sarasota", "value": "C130314"},
             {"label": "Sarasota County", "value": "C130315"},
             {"label": "Sarasota Springs", "value": "C130316"},
             {"label": "Satellite Beach", "value": "C130317"},
             {"label": "Sawgrass", "value": "C130318"},
             {"label": "Schall Circle", "value": "C130319"},
             {"label": "Scott Lake", "value": "C130320"},
             {"label": "Seaside", "value": "C130321"},
             {"label": "Sebastian", "value": "C130322"},
             {"label": "Sebring", "value": "C130323"},
             {"label": "Seffner", "value": "C130324"},
             {"label": "Seminole", "value": "C130325"},
             {"label": "Seminole County", "value": "C130326"},
             {"label": "Seminole Manor", "value": "C130327"},
             {"label": "Sewalls Point", "value": "C130328"},
             {"label": "Shady Hills", "value": "C130329"},
             {"label": "Sharpes", "value": "C130330"},
             {"label": "Siesta Key", "value": "C130331"},
             {"label": "Silver Lake", "value": "C130332"},
             {"label": "Silver Springs", "value": "C130333"},
             {"label": "Silver Springs Shores", "value": "C130334"},
             {"label": "Sky Lake", "value": "C130335"},
             {"label": "Sneads", "value": "C130336"},
             {"label": "South Apopka", "value": "C130337"},
             {"label": "South Bay", "value": "C130338"},
             {"label": "South Beach", "value": "C130339"},
             {"label": "South Bradenton", "value": "C130340"},
             {"label": "South Brooksville", "value": "C130341"},
             {"label": "South Daytona", "value": "C130342"},
             {"label": "South Gate Ridge", "value": "C130343"},
             {"label": "South Highpoint", "value": "C130344"},
             {"label": "South Miami", "value": "C130345"},
             {"label": "South Miami Heights", "value": "C130346"},
             {"label": "South Palm Beach", "value": "C130347"},
             {"label": "South Pasadena", "value": "C130348"},
             {"label": "South Patrick Shores", "value": "C130349"},
             {"label": "South Sarasota", "value": "C130350"},
             {"label": "South Venice", "value": "C130351"},
             {"label": "Southchase", "value": "C130352"},
             {"label": "Southeast Arcadia", "value": "C130353"},
             {"label": "Southgate", "value": "C130354"},
             {"label": "Southwest Ranches", "value": "C130355"},
             {"label": "Spring Hill", "value": "C130356"},
             {"label": "Springfield", "value": "C130357"},
             {"label": "St. Johns", "value": "C130358"},
             {"label": "St. Petersburg", "value": "C130359"},
             {"label": "Starke", "value": "C130360"},
             {"label": "Steinhatchee", "value": "C130361"},
             {"label": "Stock Island", "value": "C130362"},
             {"label": "Stuart", "value": "C130363"},
             {"label": "Sugarmill Woods", "value": "C130364"},
             {"label": "Sumter County", "value": "C130365"},
             {"label": "Sun City Center", "value": "C130366"},
             {"label": "Suncoast Estates", "value": "C130367"},
             {"label": "Sunny Isles Beach", "value": "C130368"},
             {"label": "Sunrise", "value": "C130369"},
             {"label": "Sunset", "value": "C130370"},
             {"label": "Sunshine Ranches", "value": "C130371"},
             {"label": "Surfside", "value": "C130372"},
             {"label": "Suwannee County", "value": "C130373"},
             {"label": "Sweetwater", "value": "C130374"},
             {"label": "Taft", "value": "C130375"},
             {"label": "Tallahassee", "value": "C130376"},
             {"label": "Tamarac", "value": "C130377"},
             {"label": "Tamiami", "value": "C130378"},
             {"label": "Tampa", "value": "C130379"},
             {"label": "Tangelo Park", "value": "C130380"},
             {"label": "Tangerine", "value": "C130381"},
             {"label": "Tarpon Springs", "value": "C130382"},
             {"label": "Tavares", "value": "C130383"},
             {"label": "Tavernier", "value": "C130384"},
             {"label": "Taylor County", "value": "C130385"},
             {"label": "Taylor Creek", "value": "C130386"},
             {"label": "Tedder", "value": "C130387"},
             {"label": "Temple Terrace", "value": "C130388"},
             {"label": "Tequesta", "value": "C130389"},
             {"label": "Terra Mar", "value": "C130390"},
             {"label": "The Acreage", "value": "C130391"},
             {"label": "The Crossings", "value": "C130392"},
             {"label": "The Hammocks", "value": "C130393"},
             {"label": "The Meadows", "value": "C130394"},
             {"label": "The Villages", "value": "C130395"},
             {"label": "Thonotosassa", "value": "C130396"},
             {"label": "Three Lakes", "value": "C130397"},
             {"label": "Three Oaks", "value": "C130398"},
             {"label": "Tice", "value": "C130399"},
             {"label": "Tierra Verde", "value": "C130400"},
             {"label": "Tiger Point", "value": "C130401"},
             {"label": "Timber Pines", "value": "C130402"},
             {"label": "Titusville", "value": "C130403"},
             {"label": "Town n Country", "value": "C130404"},
             {"label": "Treasure Island", "value": "C130405"},
             {"label": "Trenton", "value": "C130406"},
             {"label": "Trinity", "value": "C130407"},
             {"label": "Twin Lakes", "value": "C130408"},
             {"label": "Tyndall Air Force Base", "value": "C130409"},
             {"label": "Umatilla", "value": "C130410"},
             {"label": "Union County", "value": "C130411"},
             {"label": "Union Park", "value": "C130412"},
             {"label": "University", "value": "C130413"},
             {"label": "University Park", "value": "C130414"},
             {"label": "Upper Grand Lagoon", "value": "C130415"},
             {"label": "Valparaiso", "value": "C130416"},
             {"label": "Valrico", "value": "C130417"},
             {"label": "Vamo", "value": "C130418"},
             {"label": "Venice", "value": "C130419"},
             {"label": "Venice Gardens", "value": "C130420"},
             {"label": "Vero Beach", "value": "C130421"},
             {"label": "Vero Beach South", "value": "C130422"},
             {"label": "Verona Walk", "value": "C130423"},
             {"label": "Viera East", "value": "C130424"},
             {"label": "Viera West", "value": "C130425"},
             {"label": "Villages of Oriole", "value": "C130426"},
             {"label": "Villano Beach", "value": "C130427"},
             {"label": "Villas", "value": "C130428"},
             {"label": "Vineyards", "value": "C130429"},
             {"label": "Virginia Gardens", "value": "C130430"},
             {"label": "Volusia County", "value": "C130431"},
             {"label": "Wabasso Beach", "value": "C130432"},
             {"label": "Wahneta", "value": "C130433"},
             {"label": "Wakulla County", "value": "C130434"},
             {"label": "Waldo", "value": "C130435"},
             {"label": "Wallace", "value": "C130436"},
             {"label": "Walton County", "value": "C130437"},
             {"label": "Warm Mineral Springs", "value": "C130438"},
             {"label": "Warrington", "value": "C130439"},
             {"label": "Washington County", "value": "C130440"},
             {"label": "Washington Park", "value": "C130441"},
             {"label": "Watertown", "value": "C130442"},
             {"label": "Wauchula", "value": "C130443"},
             {"label": "Wedgefield", "value": "C130444"},
             {"label": "Weeki Wachee Gardens", "value": "C130445"},
             {"label": "Wekiwa Springs", "value": "C130446"},
             {"label": "Wellborn", "value": "C130447"},
             {"label": "Wellington", "value": "C130448"},
             {"label": "Wesley Chapel", "value": "C130449"},
             {"label": "West and East Lealman", "value": "C130450"},
             {"label": "West Bradenton", "value": "C130451"},
             {"label": "West DeLand", "value": "C130452"},
             {"label": "West Gate", "value": "C130453"},
             {"label": "West Hollywood", "value": "C130454"},
             {"label": "West Little River", "value": "C130455"},
             {"label": "West Melbourne", "value": "C130456"},
             {"label": "West Miami", "value": "C130457"},
             {"label": "West Palm Beach", "value": "C130458"},
             {"label": "West Park", "value": "C130459"},
             {"label": "West Pensacola", "value": "C130460"},
             {"label": "West Perrine", "value": "C130461"},
             {"label": "West Samoset", "value": "C130462"},
             {"label": "West Vero Corridor", "value": "C130463"},
             {"label": "Westchase", "value": "C130464"},
             {"label": "Westchester", "value": "C130465"},
             {"label": "Weston", "value": "C130466"},
             {"label": "Westview", "value": "C130467"},
             {"label": "Westwood Lake", "value": "C130468"},
             {"label": "Wewahitchka", "value": "C130469"},
             {"label": "Whiskey Creek", "value": "C130470"},
             {"label": "White City", "value": "C130471"},
             {"label": "Whitfield", "value": "C130472"},
             {"label": "Wildwood", "value": "C130473"},
             {"label": "Williamsburg", "value": "C130474"},
             {"label": "Williston", "value": "C130475"},
             {"label": "Williston Highlands", "value": "C130476"},
             {"label": "Willow Oak", "value": "C130477"},
             {"label": "Wilton Manors", "value": "C130478"},
             {"label": "Wimauma", "value": "C130479"},
             {"label": "Windermere", "value": "C130480"},
             {"label": "Winston", "value": "C130481"},
             {"label": "Winter Beach", "value": "C130482"},
             {"label": "Winter Garden", "value": "C130483"},
             {"label": "Winter Haven", "value": "C130484"},
             {"label": "Winter Park", "value": "C130485"},
             {"label": "Winter Springs", "value": "C130486"},
             {"label": "Woodlawn Beach", "value": "C130487"},
             {"label": "Woodville", "value": "C130488"},
             {"label": "Wright", "value": "C130489"},
             {"label": "Yalaha", "value": "C130490"},
             {"label": "Youngstown", "value": "C130491"},
             {"label": "Yulee", "value": "C130492"},
             {"label": "Zellwood", "value": "C130493"},
             {"label": "Zephyrhills", "value": "C130494"},
             {"label": "Zephyrhills North", "value": "C130495"},
             {"label": "Zephyrhills South", "value": "C130496"},
             {"label": "Zephyrhills West", "value": "C130497"},
             {"label": "Zolfo Springs", "value": "C130498"}],
 "Florida Department": [{"label": "25 de Agosto", "value": "C146882"},
                        {"label": "25 de Mayo", "value": "C146883"},
                        {"label": "Alejandro Gallinal", "value": "C146884"},
                        {"label": "Cardal", "value": "C146885"},
                        {"label": "Casupa", "value": "C146886"},
                        {"label": "Florida", "value": "C146887"},
                        {"label": "Sarandi Grande", "value": "C146888"}],
 "Fontana": [{"label": "Fontana", "value": "C65878"}],
 "Formosa": [{"label": "Clorinda", "value": "C1065"},
             {"label": "Comandante Fontana", "value": "C1066"},
             {"label": "Departamento de Pilcomayo", "value": "C1067"},
             {"label": "El Colorado", "value": "C1068"},
             {"label": "Estanislao del Campo", "value": "C1069"},
             {"label": "Formosa", "value": "C1070"},
             {"label": "General Enrique Mosconi", "value": "C1071"},
             {"label": "Herradura", "value": "C1072"},
             {"label": "Ibarreta", "value": "C1073"},
             {"label": "Ingeniero Guillermo N. Juarez", "value": "C1074"},
             {"label": "Laguna Naick-Neck", "value": "C1075"},
             {"label": "Laguna Yema", "value": "C1076"},
             {"label": "Las Lomitas", "value": "C1077"},
             {"label": "Palo Santo", "value": "C1078"},
             {"label": "Pirane", "value": "C1079"},
             {"label": "Pozo del Tigre", "value": "C1080"},
             {"label": "Riacho Eh-Eh", "value": "C1081"},
             {"label": "San Francisco de Laishi", "value": "C1082"},
             {"label": "Villa Escolar", "value": "C1083"},
             {"label": "Villa General Guemes", "value": "C1084"}],
 "Francisco Morazan Department": [{"label": "Agalteca", "value": "C43447"},
                                  {"label": "Alubaren", "value": "C43448"},
                                  {"label": "Cedros", "value": "C43449"},
                                  {"label": "Cerro Grande", "value": "C43450"},
                                  {"label": "Cofradia", "value": "C43451"},
                                  {"label": "Curaren", "value": "C43452"},
                                  {"label": "Distrito Central",
                                   "value": "C43453"},
                                  {"label": "El Chimbo", "value": "C43454"},
                                  {"label": "El Escanito", "value": "C43455"},
                                  {"label": "El Escano de Tepale",
                                   "value": "C43456"},
                                  {"label": "El Guante", "value": "C43457"},
                                  {"label": "El Guantillo", "value": "C43458"},
                                  {"label": "El Guapinol", "value": "C43459"},
                                  {"label": "El Lolo", "value": "C43460"},
                                  {"label": "El Pedernal", "value": "C43461"},
                                  {"label": "El Porvenir", "value": "C43462"},
                                  {"label": "El Suyatal", "value": "C43463"},
                                  {"label": "El Tablon", "value": "C43464"},
                                  {"label": "El Terrero", "value": "C43465"},
                                  {"label": "Guaimaca", "value": "C43466"},
                                  {"label": "La Ermita", "value": "C43467"},
                                  {"label": "La Libertad", "value": "C43468"},
                                  {"label": "La Venta", "value": "C43469"},
                                  {"label": "Lepaterique", "value": "C43470"},
                                  {"label": "Maraita", "value": "C43471"},
                                  {"label": "Marale", "value": "C43472"},
                                  {"label": "Mata de Platano",
                                   "value": "C43473"},
                                  {"label": "Mateo", "value": "C43474"},
                                  {"label": "Nueva Armenia", "value": "C43475"},
                                  {"label": "Ojojona", "value": "C43476"},
                                  {"label": "Orica", "value": "C43477"},
                                  {"label": "Quebradas", "value": "C43478"},
                                  {"label": "Reitoca", "value": "C43479"},
                                  {"label": "Rio Abajo", "value": "C43480"},
                                  {"label": "Sabanagrande", "value": "C43481"},
                                  {"label": "San Antonio de Oriente",
                                   "value": "C43482"},
                                  {"label": "San Buenaventura",
                                   "value": "C43483"},
                                  {"label": "San Ignacio", "value": "C43484"},
                                  {"label": "San Juan de Flores",
                                   "value": "C43485"},
                                  {"label": "San Miguelito", "value": "C43486"},
                                  {"label": "Santa Ana", "value": "C43487"},
                                  {"label": "Santa Lucia", "value": "C43488"},
                                  {"label": "Talanga", "value": "C43489"},
                                  {"label": "Tamara", "value": "C43490"},
                                  {"label": "Tatumbla", "value": "C43491"},
                                  {"label": "Tegucigalpa", "value": "C43492"},
                                  {"label": "Valle de angeles",
                                   "value": "C43493"},
                                  {"label": "Vallecillo", "value": "C43494"},
                                  {"label": "Villa de San Francisco",
                                   "value": "C43495"},
                                  {"label": "Villa Nueva", "value": "C43496"},
                                  {"label": "Yaguacire", "value": "C43497"},
                                  {"label": "Zambrano", "value": "C43498"}],
 "Free State": [{"label": "Allanridge", "value": "C107964"},
                {"label": "Bethlehem", "value": "C107965"},
                {"label": "Bloemfontein", "value": "C107966"},
                {"label": "Bothaville", "value": "C107967"},
                {"label": "Botshabelo", "value": "C107968"},
                {"label": "Brandfort", "value": "C107969"},
                {"label": "Clocolan", "value": "C107970"},
                {"label": "Deneysville", "value": "C107971"},
                {"label": "Fezile Dabi District Municipality",
                 "value": "C107972"},
                {"label": "Frankfort", "value": "C107973"},
                {"label": "Harrismith", "value": "C107974"},
                {"label": "Heilbron", "value": "C107975"},
                {"label": "Hennenman", "value": "C107976"},
                {"label": "Hoopstad", "value": "C107977"},
                {"label": "Koppies", "value": "C107978"},
                {"label": "Kroonstad", "value": "C107979"},
                {"label": "Kutloanong", "value": "C107980"},
                {"label": "Ladybrand", "value": "C107981"},
                {"label": "Lejweleputswa District Municipality",
                 "value": "C107982"},
                {"label": "Lindley", "value": "C107983"},
                {"label": "Mangaung Metropolitan Municipality",
                 "value": "C107984"},
                {"label": "Marquard", "value": "C107985"},
                {"label": "Parys", "value": "C107986"},
                {"label": "Phuthaditjhaba", "value": "C107987"},
                {"label": "Reitz", "value": "C107988"},
                {"label": "Sasolburg", "value": "C107989"},
                {"label": "Senekal", "value": "C107990"},
                {"label": "Thaba Nchu", "value": "C107991"},
                {"label": "Thabo Mofutsanyana District Municipality",
                 "value": "C107992"},
                {"label": "Theunissen", "value": "C107993"},
                {"label": "Ventersburg", "value": "C107994"},
                {"label": "Viljoenskroon", "value": "C107995"},
                {"label": "Villiers", "value": "C107996"},
                {"label": "Virginia", "value": "C107997"},
                {"label": "Vrede", "value": "C107998"},
                {"label": "Vredefort", "value": "C107999"},
                {"label": "Welkom", "value": "C108000"},
                {"label": "Wesselsbron", "value": "C108001"},
                {"label": "Winburg", "value": "C108002"},
                {"label": "Xhariep District Municipality", "value": "C108003"},
                {"label": "Zastron", "value": "C108004"}],
 "Freeport": [{"label": "Freeport", "value": "C119610"},
              {"label": "Lucaya", "value": "C119611"}],
 "Fribourg": [{"label": "Alterswil", "value": "C116659"},
              {"label": "Attalens", "value": "C116660"},
              {"label": "Avry-sur-Matran", "value": "C116661"},
              {"label": "Bas-Vully", "value": "C116662"},
              {"label": "Belfaux", "value": "C116663"},
              {"label": "Bosingen", "value": "C116664"},
              {"label": "Broc", "value": "C116665"},
              {"label": "Broye District", "value": "C116666"},
              {"label": "Bulle", "value": "C116667"},
              {"label": "Charmey", "value": "C116668"},
              {"label": "Chatel-Saint-Denis", "value": "C116669"},
              {"label": "Corminboeuf", "value": "C116670"},
              {"label": "Courgevaux", "value": "C116671"},
              {"label": "Cugy", "value": "C116672"},
              {"label": "Domdidier", "value": "C116673"},
              {"label": "Dudingen", "value": "C116674"},
              {"label": "Ecublens", "value": "C116675"},
              {"label": "Ependes", "value": "C116676"},
              {"label": "Estavayer-le-Lac", "value": "C116677"},
              {"label": "Flamatt", "value": "C116678"},
              {"label": "Fribourg", "value": "C116679"},
              {"label": "Giffers", "value": "C116680"},
              {"label": "Givisiez", "value": "C116681"},
              {"label": "Glane District", "value": "C116682"},
              {"label": "Grolley", "value": "C116683"},
              {"label": "Gruyere District", "value": "C116684"},
              {"label": "Gruyeres", "value": "C116685"},
              {"label": "Heitenried", "value": "C116686"},
              {"label": "Kerzers", "value": "C116687"},
              {"label": "La Roche", "value": "C116688"},
              {"label": "La Tour-de-Treme", "value": "C116689"},
              {"label": "Lake District", "value": "C116690"},
              {"label": "Marly", "value": "C116691"},
              {"label": "Marsens", "value": "C116692"},
              {"label": "Mezieres", "value": "C116693"},
              {"label": "MurtenMorat", "value": "C116694"},
              {"label": "Oberschrot", "value": "C116695"},
              {"label": "Plaffeien", "value": "C116696"},
              {"label": "Praroman", "value": "C116697"},
              {"label": "Rechthalten", "value": "C116698"},
              {"label": "Riaz", "value": "C116699"},
              {"label": "Romont", "value": "C116700"},
              {"label": "Rue", "value": "C116701"},
              {"label": "Sales", "value": "C116702"},
              {"label": "Sankt Antoni", "value": "C116703"},
              {"label": "Sarine District", "value": "C116704"},
              {"label": "Schmitten", "value": "C116705"},
              {"label": "Sense District", "value": "C116706"},
              {"label": "Tafers", "value": "C116707"},
              {"label": "Treyvaux", "value": "C116708"},
              {"label": "Ueberstorf", "value": "C116709"},
              {"label": "Veveyse District", "value": "C116710"},
              {"label": "Villars-sur-Glane", "value": "C116711"},
              {"label": "Villaz-Saint-Pierre", "value": "C116712"},
              {"label": "Vuadens", "value": "C116713"}],
 "Friesland": [{"label": "Akkrum", "value": "C76154"},
               {"label": "Aldeboarn", "value": "C76155"},
               {"label": "Aldlan-Oost", "value": "C76156"},
               {"label": "Appelscha", "value": "C76157"},
               {"label": "Augustinusga", "value": "C76158"},
               {"label": "Bakhuizen", "value": "C76159"},
               {"label": "Bakkeveen", "value": "C76160"},
               {"label": "Balk", "value": "C76161"},
               {"label": "Beetsterzwaag", "value": "C76162"},
               {"label": "Berltsum", "value": "C76163"},
               {"label": "Bilgaard", "value": "C76164"},
               {"label": "Bolsward", "value": "C76165"},
               {"label": "Boornbergum", "value": "C76166"},
               {"label": "Broeksterwald", "value": "C76167"},
               {"label": "Buitenpost", "value": "C76168"},
               {"label": "Burdaard", "value": "C76169"},
               {"label": "Burgum", "value": "C76170"},
               {"label": "Camminghaburen", "value": "C76171"},
               {"label": "Damwald", "value": "C76172"},
               {"label": "De Domp", "value": "C76173"},
               {"label": "De Drait", "value": "C76174"},
               {"label": "De Fryske Marren", "value": "C76175"},
               {"label": "De Greiden", "value": "C76176"},
               {"label": "De Knipe", "value": "C76177"},
               {"label": "De Westereen", "value": "C76178"},
               {"label": "Dokkum", "value": "C76179"},
               {"label": "Donkerbroek", "value": "C76180"},
               {"label": "Drachten", "value": "C76181"},
               {"label": "Drachtstercompagnie", "value": "C76182"},
               {"label": "Drogeham", "value": "C76183"},
               {"label": "Dronryp", "value": "C76184"},
               {"label": "Eastermar", "value": "C76185"},
               {"label": "Echtenerbrug", "value": "C76186"},
               {"label": "Feanwalden", "value": "C76187"},
               {"label": "Ferwert", "value": "C76188"},
               {"label": "Franeker", "value": "C76189"},
               {"label": "Garyp", "value": "C76190"},
               {"label": "Gorredijk", "value": "C76191"},
               {"label": "Goutum", "value": "C76192"},
               {"label": "Grou", "value": "C76193"},
               {"label": "Gytsjerk", "value": "C76194"},
               {"label": "Hallum", "value": "C76195"},
               {"label": "Harkema", "value": "C76196"},
               {"label": "Harlingen", "value": "C76197"},
               {"label": "Haulerwijk", "value": "C76198"},
               {"label": "Heechterp", "value": "C76199"},
               {"label": "Heeg", "value": "C76200"},
               {"label": "Heerenveen", "value": "C76201"},
               {"label": "Hoek", "value": "C76202"},
               {"label": "Hollum", "value": "C76203"},
               {"label": "Holwerd", "value": "C76204"},
               {"label": "Huizum", "value": "C76205"},
               {"label": "Hurdegaryp", "value": "C76206"},
               {"label": "IJlst", "value": "C76207"},
               {"label": "Jirnsum", "value": "C76208"},
               {"label": "Joure", "value": "C76209"},
               {"label": "Jubbega", "value": "C76210"},
               {"label": "Kollum", "value": "C76211"},
               {"label": "Kollumerzwaag", "value": "C76212"},
               {"label": "Kootstertille", "value": "C76213"},
               {"label": "Koudum", "value": "C76214"},
               {"label": "Langweer", "value": "C76215"},
               {"label": "Leeuwarden", "value": "C76216"},
               {"label": "Lemmer", "value": "C76217"},
               {"label": "Makkum", "value": "C76218"},
               {"label": "Mantgum", "value": "C76219"},
               {"label": "Marrum", "value": "C76220"},
               {"label": "Marsum", "value": "C76221"},
               {"label": "Menaam", "value": "C76222"},
               {"label": "Minnertsga", "value": "C76223"},
               {"label": "Nes", "value": "C76224"},
               {"label": "Nieuwehorne", "value": "C76225"},
               {"label": "Noardburgum", "value": "C76226"},
               {"label": "Noordwolde", "value": "C76227"},
               {"label": "Oentsjerk", "value": "C76228"},
               {"label": "Oldeberkoop", "value": "C76229"},
               {"label": "Oost-Vlieland", "value": "C76230"},
               {"label": "Oosterwolde", "value": "C76231"},
               {"label": "Opeinde", "value": "C76232"},
               {"label": "Oppenhuizen", "value": "C76233"},
               {"label": "Oranjewoud", "value": "C76234"},
               {"label": "Oudega", "value": "C76235"},
               {"label": "Oudehaske", "value": "C76236"},
               {"label": "Oudemirdum", "value": "C76237"},
               {"label": "Oudeschoot", "value": "C76238"},
               {"label": "Rottevalle", "value": "C76239"},
               {"label": "Scharnegoutum", "value": "C76240"},
               {"label": "Schiermonnikoog", "value": "C76241"},
               {"label": "Sexbierum", "value": "C76242"},
               {"label": "Sint Annaparochie", "value": "C76243"},
               {"label": "Sint Jacobiparochie", "value": "C76244"},
               {"label": "Sint Nicolaasga", "value": "C76245"},
               {"label": "Sintjohannesga", "value": "C76246"},
               {"label": "Sneek", "value": "C76247"},
               {"label": "Stadsfenne", "value": "C76248"},
               {"label": "Stiens", "value": "C76249"},
               {"label": "Sudwest Fryslan", "value": "C76250"},
               {"label": "Surhuisterveen", "value": "C76251"},
               {"label": "Ternaard", "value": "C76252"},
               {"label": "Tijnje", "value": "C76253"},
               {"label": "Tinga", "value": "C76254"},
               {"label": "Twijzel", "value": "C76255"},
               {"label": "Twijzelerheide", "value": "C76256"},
               {"label": "Tytsjerk", "value": "C76257"},
               {"label": "Tzummarum", "value": "C76258"},
               {"label": "Ureterp", "value": "C76259"},
               {"label": "Waadhoeke", "value": "C76260"},
               {"label": "Warga", "value": "C76261"},
               {"label": "West-Terschelling", "value": "C76262"},
               {"label": "Westeinde", "value": "C76263"},
               {"label": "Wijnjewoude", "value": "C76264"},
               {"label": "Wirdum", "value": "C76265"},
               {"label": "Witmarsum", "value": "C76266"},
               {"label": "Wolvega", "value": "C76267"},
               {"label": "Wommels", "value": "C76268"},
               {"label": "Workum", "value": "C76269"},
               {"label": "Woudsend", "value": "C76270"},
               {"label": "Zuiderburen", "value": "C76271"}],
 "Friuli-Venezia Giulia": [{"label": "Aiello del Friuli", "value": "C54364"},
                           {"label": "Amaro", "value": "C54365"},
                           {"label": "Ampezzo", "value": "C54366"},
                           {"label": "Andreis", "value": "C54367"},
                           {"label": "Anduins", "value": "C54368"},
                           {"label": "Aquileia", "value": "C54369"},
                           {"label": "Arba", "value": "C54370"},
                           {"label": "Arta Terme", "value": "C54371"},
                           {"label": "Artegna", "value": "C54372"},
                           {"label": "Arzene", "value": "C54373"},
                           {"label": "Attimis", "value": "C54374"},
                           {"label": "Aurisina", "value": "C54375"},
                           {"label": "Aviano", "value": "C54376"},
                           {"label": "Aviano-Castello", "value": "C54377"},
                           {"label": "Azzano Decimo", "value": "C54378"},
                           {"label": "Bagnaria Arsa", "value": "C54379"},
                           {"label": "Bagnarola", "value": "C54380"},
                           {"label": "Barcis", "value": "C54381"},
                           {"label": "Basaldella", "value": "C54382"},
                           {"label": "Basiliano", "value": "C54383"},
                           {"label": "Basiliano-Vissandone", "value": "C54384"},
                           {"label": "Begliano", "value": "C54385"},
                           {"label": "Bertiolo", "value": "C54386"},
                           {"label": "Bicinicco", "value": "C54387"},
                           {"label": "Bordano", "value": "C54388"},
                           {"label": "Bressa", "value": "C54389"},
                           {"label": "Brugnera", "value": "C54390"},
                           {"label": "Budoia", "value": "C54391"},
                           {"label": "Buia", "value": "C54392"},
                           {"label": "Buttrio", "value": "C54393"},
                           {"label": "Camino al Tagliamento",
                            "value": "C54394"},
                           {"label": "Campoformido", "value": "C54395"},
                           {"label": "Campolongo al Torre", "value": "C54396"},
                           {"label": "Campolongo al Torre-Cavenzano",
                            "value": "C54397"},
                           {"label": "Caneva", "value": "C54398"},
                           {"label": "Capriva del Friuli", "value": "C54399"},
                           {"label": "Carlino", "value": "C54400"},
                           {"label": "Casarsa della Delizia",
                            "value": "C54401"},
                           {"label": "Cassacco", "value": "C54402"},
                           {"label": "Castions", "value": "C54403"},
                           {"label": "Castions di Strada", "value": "C54404"},
                           {"label": "Cavasso Nuovo", "value": "C54405"},
                           {"label": "Cavazzo Carnico", "value": "C54406"},
                           {"label": "Cavolano-Schiavoi", "value": "C54407"},
                           {"label": "Cecchini", "value": "C54408"},
                           {"label": "Cercivento", "value": "C54409"},
                           {"label": "Cervignano del Friuli",
                            "value": "C54410"},
                           {"label": "Chiaulis", "value": "C54411"},
                           {"label": "Chions", "value": "C54412"},
                           {"label": "Chiopris", "value": "C54413"},
                           {"label": "Chiusaforte", "value": "C54414"},
                           {"label": "Cimolais", "value": "C54415"},
                           {"label": "Cividale del Friuli", "value": "C54416"},
                           {"label": "Claut", "value": "C54417"},
                           {"label": "Clauzetto", "value": "C54418"},
                           {"label": "Clodig", "value": "C54419"},
                           {"label": "Codroipo", "value": "C54420"},
                           {"label": "Colloredo di Monte Albano",
                            "value": "C54421"},
                           {"label": "Colloredo di Monte Albano-Lauzzana",
                            "value": "C54422"},
                           {"label": "Colloredo di Prato", "value": "C54423"},
                           {"label": "Colugna", "value": "C54424"},
                           {"label": "Comeglians", "value": "C54425"},
                           {"label": "Cordenons", "value": "C54426"},
                           {"label": "Cordovado", "value": "C54427"},
                           {"label": "Cormons", "value": "C54428"},
                           {"label": "Corno di Rosazzo", "value": "C54429"},
                           {"label": "Coseano", "value": "C54430"},
                           {"label": "Cras", "value": "C54431"},
                           {"label": "Dignano", "value": "C54432"},
                           {"label": "Doberdo del Lago", "value": "C54433"},
                           {"label": "Dogna", "value": "C54434"},
                           {"label": "Dolegna del Collio", "value": "C54435"},
                           {"label": "Dolina", "value": "C54436"},
                           {"label": "Domio", "value": "C54437"},
                           {"label": "Duino", "value": "C54438"},
                           {"label": "Enemonzo-Quinis", "value": "C54439"},
                           {"label": "Erto", "value": "C54440"},
                           {"label": "Faedis", "value": "C54441"},
                           {"label": "Fagagna", "value": "C54442"},
                           {"label": "Fanna", "value": "C54443"},
                           {"label": "Farra dIsonzo", "value": "C54444"},
                           {"label": "Fiume Veneto", "value": "C54445"},
                           {"label": "Fiumicello", "value": "C54446"},
                           {"label": "Flaibano", "value": "C54447"},
                           {"label": "Fogliano", "value": "C54448"},
                           {"label": "Forgaria nel Friuli", "value": "C54449"},
                           {"label": "Forni Avoltri", "value": "C54450"},
                           {"label": "Forni di Sopra", "value": "C54451"},
                           {"label": "Forni di Sotto", "value": "C54452"},
                           {"label": "Frisanco", "value": "C54453"},
                           {"label": "Gemona", "value": "C54454"},
                           {"label": "Gonars", "value": "C54455"},
                           {"label": "Gorizia", "value": "C54456"},
                           {"label": "Gradisca dIsonzo", "value": "C54457"},
                           {"label": "Grado", "value": "C54458"},
                           {"label": "Granvilla", "value": "C54459"},
                           {"label": "Ialmicco", "value": "C54460"},
                           {"label": "Isola", "value": "C54461"},
                           {"label": "Latisana", "value": "C54462"},
                           {"label": "Lauco", "value": "C54463"},
                           {"label": "Lauzacco", "value": "C54464"},
                           {"label": "Lestans", "value": "C54465"},
                           {"label": "Lestizza", "value": "C54466"},
                           {"label": "Lignano Sabbiadoro", "value": "C54467"},
                           {"label": "Ligosullo", "value": "C54468"},
                           {"label": "Lusevera", "value": "C54469"},
                           {"label": "Magnano in Riviera", "value": "C54470"},
                           {"label": "Majano", "value": "C54471"},
                           {"label": "Malborghetto", "value": "C54472"},
                           {"label": "Maniago", "value": "C54473"},
                           {"label": "Manzano", "value": "C54474"},
                           {"label": "Marano Lagunare", "value": "C54475"},
                           {"label": "Mariano del Friuli", "value": "C54476"},
                           {"label": "Marsure", "value": "C54477"},
                           {"label": "Martignacco", "value": "C54478"},
                           {"label": "Medea", "value": "C54479"},
                           {"label": "Mediis", "value": "C54480"},
                           {"label": "Meduno", "value": "C54481"},
                           {"label": "Mereto di Tomba", "value": "C54482"},
                           {"label": "Merso di Sopra", "value": "C54483"},
                           {"label": "Moggio di Sotto", "value": "C54484"},
                           {"label": "Moggio Udinese", "value": "C54485"},
                           {"label": "Moimacco", "value": "C54486"},
                           {"label": "Monfalcone", "value": "C54487"},
                           {"label": "Montenars", "value": "C54488"},
                           {"label": "Montereale Valcellina",
                            "value": "C54489"},
                           {"label": "Moraro", "value": "C54490"},
                           {"label": "Morsano", "value": "C54491"},
                           {"label": "Mortegliano", "value": "C54492"},
                           {"label": "Moruzzo", "value": "C54493"},
                           {"label": "Mossa", "value": "C54494"},
                           {"label": "Muggia", "value": "C54495"},
                           {"label": "Muzzana del Turgnano", "value": "C54496"},
                           {"label": "Nimis", "value": "C54497"},
                           {"label": "Orcenico Inferiore", "value": "C54498"},
                           {"label": "Orsaria", "value": "C54499"},
                           {"label": "Osoppo", "value": "C54500"},
                           {"label": "Ovaro", "value": "C54501"},
                           {"label": "Pagnacco", "value": "C54502"},
                           {"label": "Palazzolo dello Stella",
                            "value": "C54503"},
                           {"label": "Palmanova", "value": "C54504"},
                           {"label": "Paludea", "value": "C54505"},
                           {"label": "Paluzza", "value": "C54506"},
                           {"label": "Pasian di Prato", "value": "C54507"},
                           {"label": "Pasiano", "value": "C54508"},
                           {"label": "Passons", "value": "C54509"},
                           {"label": "Paularo", "value": "C54510"},
                           {"label": "Pavia di Udine", "value": "C54511"},
                           {"label": "Percoto", "value": "C54512"},
                           {"label": "Pertegada", "value": "C54513"},
                           {"label": "Pieria-Prato Carnico", "value": "C54514"},
                           {"label": "Pieris", "value": "C54515"},
                           {"label": "Pinzano al Tagliamento",
                            "value": "C54516"},
                           {"label": "Pocenia", "value": "C54517"},
                           {"label": "Polcenigo", "value": "C54518"},
                           {"label": "Pontebba", "value": "C54519"},
                           {"label": "Porcia", "value": "C54520"},
                           {"label": "Pordenone", "value": "C54521"},
                           {"label": "Porpetto", "value": "C54522"},
                           {"label": "Povoletto", "value": "C54523"},
                           {"label": "Pozzuolo del Friuli", "value": "C54524"},
                           {"label": "Pradamano", "value": "C54525"},
                           {"label": "Prata di Pordenone", "value": "C54526"},
                           {"label": "Prato", "value": "C54527"},
                           {"label": "Prato Carnico", "value": "C54528"},
                           {"label": "Pravisdomini", "value": "C54529"},
                           {"label": "Precenicco", "value": "C54530"},
                           {"label": "Premariacco", "value": "C54531"},
                           {"label": "Preone", "value": "C54532"},
                           {"label": "Prepotto", "value": "C54533"},
                           {"label": "Prosecco-Contovello", "value": "C54534"},
                           {"label": "Provincia di Gorizia", "value": "C54535"},
                           {"label": "Provincia di Pordenone",
                            "value": "C54536"},
                           {"label": "Provincia di Trieste", "value": "C54537"},
                           {"label": "Provincia di Udine", "value": "C54538"},
                           {"label": "Puia-Villanova", "value": "C54539"},
                           {"label": "Pulfero", "value": "C54540"},
                           {"label": "Ramuscello", "value": "C54541"},
                           {"label": "Rauscedo-Domanins", "value": "C54542"},
                           {"label": "Ravascletto", "value": "C54543"},
                           {"label": "Raveo", "value": "C54544"},
                           {"label": "Reana del Roiale", "value": "C54545"},
                           {"label": "Reana del Rojale", "value": "C54546"},
                           {"label": "Remanzacco", "value": "C54547"},
                           {"label": "Resiutta", "value": "C54548"},
                           {"label": "Rigolato", "value": "C54549"},
                           {"label": "Rive dArcano", "value": "C54550"},
                           {"label": "Rivignano", "value": "C54551"},
                           {"label": "Rodeano", "value": "C54552"},
                           {"label": "Romans dIsonzo", "value": "C54553"},
                           {"label": "Ronchi dei Legionari", "value": "C54554"},
                           {"label": "Ronchis", "value": "C54555"},
                           {"label": "Rosa", "value": "C54556"},
                           {"label": "Roveredo in Piano", "value": "C54557"},
                           {"label": "Ruda", "value": "C54558"},
                           {"label": "Sacile", "value": "C54559"},
                           {"label": "Sagrado", "value": "C54560"},
                           {"label": "San Canzian dIsonzo", "value": "C54561"},
                           {"label": "San Daniele del Friuli",
                            "value": "C54562"},
                           {"label": "San Floriano del Collio",
                            "value": "C54563"},
                           {"label": "San Giacomo", "value": "C54564"},
                           {"label": "San Giorgio della Richinvelda",
                            "value": "C54565"},
                           {"label": "San Giorgio di Nogaro",
                            "value": "C54566"},
                           {"label": "San Giovanni", "value": "C54567"},
                           {"label": "San Giovanni al Natisone",
                            "value": "C54568"},
                           {"label": "San Leonardo", "value": "C54569"},
                           {"label": "San Lorenzo Isontino", "value": "C54570"},
                           {"label": "San Martino al Tagliamento",
                            "value": "C54571"},
                           {"label": "San Pier dIsonzo", "value": "C54572"},
                           {"label": "San Pietro al Natisone",
                            "value": "C54573"},
                           {"label": "San Quirino", "value": "C54574"},
                           {"label": "San Valentino", "value": "C54575"},
                           {"label": "San Vito al Tagliamento",
                            "value": "C54576"},
                           {"label": "San Vito al Torre", "value": "C54577"},
                           {"label": "San Vito di Fagagna", "value": "C54578"},
                           {"label": "Santa Croce", "value": "C54579"},
                           {"label": "Santa Maria la Longa", "value": "C54580"},
                           {"label": "Sappada", "value": "C54581"},
                           {"label": "Sauris di Sotto", "value": "C54582"},
                           {"label": "Savogna", "value": "C54583"},
                           {"label": "Savogna dIsonzo", "value": "C54584"},
                           {"label": "Sedegliano", "value": "C54585"},
                           {"label": "Sequals", "value": "C54586"},
                           {"label": "Sesto al Reghena", "value": "C54587"},
                           {"label": "Sevegliano", "value": "C54588"},
                           {"label": "Sgonico", "value": "C54589"},
                           {"label": "Sistiana-Visogliano", "value": "C54590"},
                           {"label": "Sottoselva", "value": "C54591"},
                           {"label": "Spilimbergo", "value": "C54592"},
                           {"label": "Staranzano", "value": "C54593"},
                           {"label": "Stregna", "value": "C54594"},
                           {"label": "Sutrio", "value": "C54595"},
                           {"label": "Taipana", "value": "C54596"},
                           {"label": "Talmassons", "value": "C54597"},
                           {"label": "Tamai", "value": "C54598"},
                           {"label": "Tarcento", "value": "C54599"},
                           {"label": "Tarvisio", "value": "C54600"},
                           {"label": "Tavagnacco", "value": "C54601"},
                           {"label": "Teor", "value": "C54602"},
                           {"label": "Terzo dAquileia", "value": "C54603"},
                           {"label": "Tolmezzo", "value": "C54604"},
                           {"label": "Torreano", "value": "C54605"},
                           {"label": "Torviscosa", "value": "C54606"},
                           {"label": "Tramonti di Sopra", "value": "C54607"},
                           {"label": "Tramonti di Sotto", "value": "C54608"},
                           {"label": "Trasaghis", "value": "C54609"},
                           {"label": "Travesio", "value": "C54610"},
                           {"label": "Treppo Carnico", "value": "C54611"},
                           {"label": "Treppo Grande", "value": "C54612"},
                           {"label": "Tricesimo", "value": "C54613"},
                           {"label": "Trieste", "value": "C54614"},
                           {"label": "Trivignano Udinese", "value": "C54615"},
                           {"label": "Turriaco", "value": "C54616"},
                           {"label": "Udine", "value": "C54617"},
                           {"label": "Vajont", "value": "C54618"},
                           {"label": "Valvasone", "value": "C54619"},
                           {"label": "Varmo", "value": "C54620"},
                           {"label": "Venzone", "value": "C54621"},
                           {"label": "Vigonovo-Fontanafredda",
                            "value": "C54622"},
                           {"label": "Villa Opicina", "value": "C54623"},
                           {"label": "Villa Santina", "value": "C54624"},
                           {"label": "Villa Vicentina", "value": "C54625"},
                           {"label": "Villanova", "value": "C54626"},
                           {"label": "Villesse", "value": "C54627"},
                           {"label": "Villotta", "value": "C54628"},
                           {"label": "Visco", "value": "C54629"},
                           {"label": "Vivaro", "value": "C54630"},
                           {"label": "Zolla", "value": "C54631"},
                           {"label": "Zoppola", "value": "C54632"},
                           {"label": "Zugliano-Terenzano-Cargnacco",
                            "value": "C54633"},
                           {"label": "Zuglio", "value": "C54634"}],
 "Fujairah": [{"label": "Al Fujairah City", "value": "C123112"},
              {"label": "Al Fujairah Municipality", "value": "C123113"},
              {"label": "Dibba Al Fujairah Municipality", "value": "C123114"},
              {"label": "Dibba Al-Fujairah", "value": "C123115"},
              {"label": "Dibba Al-Hisn", "value": "C123116"},
              {"label": "Reef Al Fujairah City", "value": "C123117"}],
 "Fujian": [{"label": "Badu", "value": "C18301"},
            {"label": "Baiqi", "value": "C18302"},
            {"label": "Baiyun", "value": "C18303"},
            {"label": "Beishancun", "value": "C18304"},
            {"label": "Changqiao", "value": "C18305"},
            {"label": "Chengmen", "value": "C18306"},
            {"label": "Chixi", "value": "C18307"},
            {"label": "Chongru", "value": "C18308"},
            {"label": "Dadeng", "value": "C18309"},
            {"label": "Daixi", "value": "C18310"},
            {"label": "Danyang", "value": "C18311"},
            {"label": "Daqiao", "value": "C18312"},
            {"label": "Dazuo", "value": "C18313"},
            {"label": "Dinghaicun", "value": "C18314"},
            {"label": "Dingtoucun", "value": "C18315"},
            {"label": "Dongchongcun", "value": "C18316"},
            {"label": "Dongdai", "value": "C18317"},
            {"label": "Donghu", "value": "C18318"},
            {"label": "Dongling", "value": "C18319"},
            {"label": "Dongyuan", "value": "C18320"},
            {"label": "Feiluan", "value": "C18321"},
            {"label": "Fengpu", "value": "C18322"},
            {"label": "Fengzhou", "value": "C18323"},
            {"label": "Fuan", "value": "C18324"},
            {"label": "Fuding", "value": "C18325"},
            {"label": "Fuqing", "value": "C18326"},
            {"label": "Fuzhou", "value": "C18327"},
            {"label": "Gantang", "value": "C18328"},
            {"label": "Guantou", "value": "C18329"},
            {"label": "Gufeng", "value": "C18330"},
            {"label": "Hetang", "value": "C18331"},
            {"label": "Hongtang", "value": "C18332"},
            {"label": "Hongyang", "value": "C18333"},
            {"label": "Houyu", "value": "C18334"},
            {"label": "Huaian", "value": "C18335"},
            {"label": "Huangtian", "value": "C18336"},
            {"label": "Huotong", "value": "C18337"},
            {"label": "Jianou", "value": "C18338"},
            {"label": "Jiangkou", "value": "C18339"},
            {"label": "Jianjiang", "value": "C18340"},
            {"label": "Jingfeng", "value": "C18341"},
            {"label": "Jinjiang", "value": "C18342"},
            {"label": "Jinjing", "value": "C18343"},
            {"label": "Jitoucun", "value": "C18344"},
            {"label": "Kengyuan", "value": "C18345"},
            {"label": "Kerencun", "value": "C18346"},
            {"label": "Kuaian", "value": "C18347"},
            {"label": "Lianhecun", "value": "C18348"},
            {"label": "Liuwudiancun", "value": "C18349"},
            {"label": "Longmen", "value": "C18350"},
            {"label": "Longyan", "value": "C18351"},
            {"label": "Luoqiao", "value": "C18352"},
            {"label": "Luoyang", "value": "C18353"},
            {"label": "Luxia", "value": "C18354"},
            {"label": "Maping", "value": "C18355"},
            {"label": "Meipu", "value": "C18356"},
            {"label": "Minan", "value": "C18357"},
            {"label": "Nanping", "value": "C18358"},
            {"label": "Neikeng", "value": "C18359"},
            {"label": "Ningde", "value": "C18360"},
            {"label": "Pandu", "value": "C18361"},
            {"label": "Pucheng", "value": "C18362"},
            {"label": "Putian", "value": "C18363"},
            {"label": "Qibu", "value": "C18364"},
            {"label": "Qidu", "value": "C18365"},
            {"label": "Quanzhou", "value": "C18366"},
            {"label": "Rongan", "value": "C18367"},
            {"label": "Sanming", "value": "C18368"},
            {"label": "Shajiang", "value": "C18369"},
            {"label": "Shangjie", "value": "C18370"},
            {"label": "Shanxia", "value": "C18371"},
            {"label": "Shanyang", "value": "C18372"},
            {"label": "Shaowu", "value": "C18373"},
            {"label": "Shijing", "value": "C18374"},
            {"label": "Shima", "value": "C18375"},
            {"label": "Shoushan", "value": "C18376"},
            {"label": "Shuangxi", "value": "C18377"},
            {"label": "Shuangzhu", "value": "C18378"},
            {"label": "Shuikou", "value": "C18379"},
            {"label": "Tangkou", "value": "C18380"},
            {"label": "Tantou", "value": "C18381"},
            {"label": "Tatou", "value": "C18382"},
            {"label": "Tingjiang", "value": "C18383"},
            {"label": "Tuzhai", "value": "C18384"},
            {"label": "Wubao", "value": "C18385"},
            {"label": "Wuyishan", "value": "C18386"},
            {"label": "Wuyucun", "value": "C18387"},
            {"label": "Xiabaishi", "value": "C18388"},
            {"label": "Xiahu", "value": "C18389"},
            {"label": "Xiamen", "value": "C18390"},
            {"label": "Xiancun", "value": "C18391"},
            {"label": "Xiangyun", "value": "C18392"},
            {"label": "Xibing", "value": "C18393"},
            {"label": "Xiling", "value": "C18394"},
            {"label": "Ximei", "value": "C18395"},
            {"label": "Xinan", "value": "C18396"},
            {"label": "Xindian", "value": "C18397"},
            {"label": "Yakou", "value": "C18398"},
            {"label": "Yanghou", "value": "C18399"},
            {"label": "Yangzhong", "value": "C18400"},
            {"label": "Yantian", "value": "C18401"},
            {"label": "Yingdu", "value": "C18402"},
            {"label": "Yinglin", "value": "C18403"},
            {"label": "Yongning", "value": "C18404"},
            {"label": "Yushan", "value": "C18405"},
            {"label": "Zhangwan", "value": "C18406"},
            {"label": "Zhangzhou", "value": "C18407"},
            {"label": "Zhenhaicun", "value": "C18408"},
            {"label": "Zhongfang", "value": "C18409"},
            {"label": "Zhuoyang", "value": "C18410"},
            {"label": "Zhuqi", "value": "C18411"}],
 "Fukui Prefecture": [{"label": "Asahi", "value": "C62727"},
                      {"label": "Awara-shi", "value": "C62728"},
                      {"label": "Echizen-shi", "value": "C62729"},
                      {"label": "Fukui-shi", "value": "C62730"},
                      {"label": "Katsuyama", "value": "C62731"},
                      {"label": "Katsuyama Shi", "value": "C62732"},
                      {"label": "Maruoka", "value": "C62733"},
                      {"label": "Mikuni", "value": "C62734"},
                      {"label": "Obama", "value": "C62735"},
                      {"label": "Obama-shi", "value": "C62736"},
                      {"label": "Oi-gun", "value": "C62737"},
                      {"label": "Ono", "value": "C62738"},
                      {"label": "Ono-shi", "value": "C62739"},
                      {"label": "Sabae", "value": "C62740"},
                      {"label": "Sabae-shi", "value": "C62741"},
                      {"label": "Sakai-shi", "value": "C62742"},
                      {"label": "Takefu", "value": "C62743"},
                      {"label": "Tsuruga", "value": "C62744"},
                      {"label": "Tsuruga-shi", "value": "C62745"}],
 "Fukuoka Prefecture": [{"label": "Amagi", "value": "C62746"},
                        {"label": "Asakura Shi", "value": "C62747"},
                        {"label": "Buzen", "value": "C62748"},
                        {"label": "Buzen-shi", "value": "C62749"},
                        {"label": "Chikugo Shi", "value": "C62750"},
                        {"label": "Chikushino-shi", "value": "C62751"},
                        {"label": "Dazaifu-shi", "value": "C62752"},
                        {"label": "Fukuoka", "value": "C62753"},
                        {"label": "Fukuoka-shi", "value": "C62754"},
                        {"label": "Fukutsu Shi", "value": "C62755"},
                        {"label": "Iizuka", "value": "C62756"},
                        {"label": "Iizuka Shi", "value": "C62757"},
                        {"label": "Itoshima-shi", "value": "C62758"},
                        {"label": "Kama Shi", "value": "C62759"},
                        {"label": "Kanda", "value": "C62760"},
                        {"label": "Kasuga Shi", "value": "C62761"},
                        {"label": "Kawasaki", "value": "C62762"},
                        {"label": "Kitakyushu", "value": "C62763"},
                        {"label": "Koga", "value": "C62764"},
                        {"label": "Koga-shi", "value": "C62765"},
                        {"label": "Kurume", "value": "C62766"},
                        {"label": "Kurume Shi", "value": "C62767"},
                        {"label": "Maebaru-chuo", "value": "C62768"},
                        {"label": "Miyama Shi", "value": "C62769"},
                        {"label": "Miyata", "value": "C62770"},
                        {"label": "Miyawaka Shi", "value": "C62771"},
                        {"label": "Munakata-shi", "value": "C62772"},
                        {"label": "Nakama", "value": "C62773"},
                        {"label": "Nakama Shi", "value": "C62774"},
                        {"label": "Nishifukuma", "value": "C62775"},
                        {"label": "Nogata", "value": "C62776"},
                        {"label": "Nogata Shi", "value": "C62777"},
                        {"label": "Ogori-shi", "value": "C62778"},
                        {"label": "Okawa", "value": "C62779"},
                        {"label": "Okawa-shi", "value": "C62780"},
                        {"label": "Omuta", "value": "C62781"},
                        {"label": "Omuta Shi", "value": "C62782"},
                        {"label": "Onga-gun", "value": "C62783"},
                        {"label": "Onojo", "value": "C62784"},
                        {"label": "Onojo Shi", "value": "C62785"},
                        {"label": "Sasaguri", "value": "C62786"},
                        {"label": "Setakamachi-takayanagi", "value": "C62787"},
                        {"label": "Shiida", "value": "C62788"},
                        {"label": "Shingu", "value": "C62789"},
                        {"label": "Tagawa", "value": "C62790"},
                        {"label": "Tagawa Shi", "value": "C62791"},
                        {"label": "Tanushimarumachi-toyoki", "value": "C62792"},
                        {"label": "Tsuiki", "value": "C62793"},
                        {"label": "Ukiha Shi", "value": "C62794"},
                        {"label": "Umi", "value": "C62795"},
                        {"label": "Yame Shi", "value": "C62796"},
                        {"label": "Yanagawa", "value": "C62797"},
                        {"label": "Yanagawa Shi", "value": "C62798"},
                        {"label": "Yoshitomi", "value": "C62799"},
                        {"label": "Yukuhashi", "value": "C62800"},
                        {"label": "Yukuhashi Shi", "value": "C62801"}],
 "Fukushima Prefecture": [{"label": "Aizu-wakamatsu Shi", "value": "C62802"},
                          {"label": "Date-shi", "value": "C62803"},
                          {"label": "Fukushima", "value": "C62804"},
                          {"label": "Fukushima Shi", "value": "C62805"},
                          {"label": "Funehikimachi-funehiki",
                           "value": "C62806"},
                          {"label": "Hobaramachi", "value": "C62807"},
                          {"label": "Inawashiro", "value": "C62808"},
                          {"label": "Ishikawa", "value": "C62809"},
                          {"label": "Iwaki", "value": "C62810"},
                          {"label": "Iwaki-shi", "value": "C62811"},
                          {"label": "Kitakata", "value": "C62812"},
                          {"label": "Kitakata-shi", "value": "C62813"},
                          {"label": "Koriyama", "value": "C62814"},
                          {"label": "Koriyama Shi", "value": "C62815"},
                          {"label": "Miharu", "value": "C62816"},
                          {"label": "Minami-Soma", "value": "C62817"},
                          {"label": "Minamisoma Shi", "value": "C62818"},
                          {"label": "Motomiya", "value": "C62819"},
                          {"label": "Motomiya-shi", "value": "C62820"},
                          {"label": "Namie", "value": "C62821"},
                          {"label": "Nihommatsu", "value": "C62822"},
                          {"label": "Nihonmatsu Shi", "value": "C62823"},
                          {"label": "Shirakawa Shi", "value": "C62824"},
                          {"label": "Soma", "value": "C62825"},
                          {"label": "Soma Shi", "value": "C62826"},
                          {"label": "Sukagawa", "value": "C62827"},
                          {"label": "Sukagawa Shi", "value": "C62828"},
                          {"label": "Tamura", "value": "C62829"},
                          {"label": "Tamura-shi", "value": "C62830"},
                          {"label": "Yanagawamachi-saiwaicho",
                           "value": "C62831"}],
 "Funafuti": [{"label": "Alapi Village", "value": "C121191"},
              {"label": "Fakaifou Village", "value": "C121192"},
              {"label": "Funafuti", "value": "C121193"}],
 "G@d@b@y": [{"label": "Ariqdam", "value": "C8428"},
             {"label": "Ariqiran", "value": "C8429"},
             {"label": "Boyuk Qaramurad", "value": "C8430"},
             {"label": "Kyadabek", "value": "C8431"},
             {"label": "Novosaratovka", "value": "C8432"}],
 "Gaafu Alif Atoll": [{"label": "Viligili", "value": "C65804"}],
 "Gaafu Dhaalu Atoll": [{"label": "Thinadhoo", "value": "C65805"}],
 "Gabes Governorate": [{"label": "Bou Attouche", "value": "C119700"},
                       {"label": "El Hamma", "value": "C119701"},
                       {"label": "Gabes", "value": "C119702"},
                       {"label": "Matmata", "value": "C119703"}],
 "Gabrovo Province": [{"label": "Dryanovo", "value": "C15922"},
                      {"label": "Gabrovo", "value": "C15923"},
                      {"label": "Obshtina Dryanovo", "value": "C15924"},
                      {"label": "Obshtina Gabrovo", "value": "C15925"},
                      {"label": "Obshtina Sevlievo", "value": "C15926"},
                      {"label": "Obshtina Tryavna", "value": "C15927"},
                      {"label": "Sevlievo", "value": "C15928"},
                      {"label": "Tryavna", "value": "C15929"}],
 "Gabu Region": [{"label": "Gabu", "value": "C43058"}],
 "Gafsa Governorate": [{"label": "Ar Rudayyif", "value": "C119704"},
                       {"label": "As Sanad", "value": "C119705"},
                       {"label": "Gafsa", "value": "C119706"},
                       {"label": "Metlaoui", "value": "C119707"},
                       {"label": "Mutamadiyat ar Rudayyif",
                        "value": "C119708"}],
 "Gagaifomauga": [{"label": "Matavai", "value": "C106234"},
                  {"label": "Safotu", "value": "C106235"}],
 "Gagauzia": [{"label": "Bugeac", "value": "C75517"},
              {"label": "Ceadir-Lunga", "value": "C75518"},
              {"label": "Comrat", "value": "C75519"},
              {"label": "Vulcanesti", "value": "C75520"}],
 "Galapagos": [{"label": "Puerto Ayora", "value": "C24129"},
               {"label": "Puerto Baquerizo Moreno", "value": "C24130"},
               {"label": "Puerto Villamil", "value": "C24131"}],
 "Galati County": [{"label": "Balabanesti", "value": "C95279"},
                   {"label": "Balasesti", "value": "C95280"},
                   {"label": "Baleni", "value": "C95281"},
                   {"label": "Balintesti", "value": "C95282"},
                   {"label": "Baneasa", "value": "C95283"},
                   {"label": "Barcea", "value": "C95284"},
                   {"label": "Beresti", "value": "C95285"},
                   {"label": "Beresti-Sat", "value": "C95286"},
                   {"label": "Blanzi", "value": "C95287"},
                   {"label": "Brahasesti", "value": "C95288"},
                   {"label": "Brahasestii de Sus", "value": "C95289"},
                   {"label": "Branistea", "value": "C95290"},
                   {"label": "Bucesti", "value": "C95291"},
                   {"label": "Buciumeni", "value": "C95292"},
                   {"label": "Cavadinesti", "value": "C95293"},
                   {"label": "Certesti", "value": "C95294"},
                   {"label": "Chiraftei", "value": "C95295"},
                   {"label": "Ciorasti", "value": "C95296"},
                   {"label": "Cismele", "value": "C95297"},
                   {"label": "Comuna Balabanesti", "value": "C95298"},
                   {"label": "Comuna Balasesti", "value": "C95299"},
                   {"label": "Comuna Baleni", "value": "C95300"},
                   {"label": "Comuna Baneasa", "value": "C95301"},
                   {"label": "Comuna Barcea", "value": "C95302"},
                   {"label": "Comuna Beresti-Meria", "value": "C95303"},
                   {"label": "Comuna Brahasesti", "value": "C95304"},
                   {"label": "Comuna Branistea", "value": "C95305"},
                   {"label": "Comuna Buciumeni", "value": "C95306"},
                   {"label": "Comuna Cavadinesti", "value": "C95307"},
                   {"label": "Comuna Certesti", "value": "C95308"},
                   {"label": "Comuna Corni", "value": "C95309"},
                   {"label": "Comuna Corod", "value": "C95310"},
                   {"label": "Comuna Cosmesti", "value": "C95311"},
                   {"label": "Comuna Costache Negri", "value": "C95312"},
                   {"label": "Comuna Cuca", "value": "C95313"},
                   {"label": "Comuna Cudalbi", "value": "C95314"},
                   {"label": "Comuna Cuza Voda", "value": "C95315"},
                   {"label": "Comuna Draganesti", "value": "C95316"},
                   {"label": "Comuna Draguseni", "value": "C95317"},
                   {"label": "Comuna Fartanesti", "value": "C95318"},
                   {"label": "Comuna Foltesti", "value": "C95319"},
                   {"label": "Comuna Frumusita", "value": "C95320"},
                   {"label": "Comuna Fundeni", "value": "C95321"},
                   {"label": "Comuna Ghidigeni", "value": "C95322"},
                   {"label": "Comuna Gohor", "value": "C95323"},
                   {"label": "Comuna Grivita", "value": "C95324"},
                   {"label": "Comuna Independenta", "value": "C95325"},
                   {"label": "Comuna Ivesti", "value": "C95326"},
                   {"label": "Comuna Jorasti", "value": "C95327"},
                   {"label": "Comuna Liesti", "value": "C95328"},
                   {"label": "Comuna Mastacani", "value": "C95329"},
                   {"label": "Comuna Matca", "value": "C95330"},
                   {"label": "Comuna Movileni", "value": "C95331"},
                   {"label": "Comuna Munteni", "value": "C95332"},
                   {"label": "Comuna Namoloasa", "value": "C95333"},
                   {"label": "Comuna Negrilesti", "value": "C95334"},
                   {"label": "Comuna Nicoresti", "value": "C95335"},
                   {"label": "Comuna Oancea", "value": "C95336"},
                   {"label": "Comuna Pechea", "value": "C95337"},
                   {"label": "Comuna Piscu", "value": "C95338"},
                   {"label": "Comuna Poiana", "value": "C95339"},
                   {"label": "Comuna Priponesti", "value": "C95340"},
                   {"label": "Comuna Radesti", "value": "C95341"},
                   {"label": "Comuna Rediu", "value": "C95342"},
                   {"label": "Comuna Scanteiesti", "value": "C95343"},
                   {"label": "Comuna Schela", "value": "C95344"},
                   {"label": "Comuna Sendreni", "value": "C95345"},
                   {"label": "Comuna Slobozia Conachi", "value": "C95346"},
                   {"label": "Comuna Smardan", "value": "C95347"},
                   {"label": "Comuna Smulti", "value": "C95348"},
                   {"label": "Comuna Suceveni", "value": "C95349"},
                   {"label": "Comuna Suhurlui", "value": "C95350"},
                   {"label": "Comuna Tepu", "value": "C95351"},
                   {"label": "Comuna Tudor Vladimirescu", "value": "C95352"},
                   {"label": "Comuna Tulucesti", "value": "C95353"},
                   {"label": "Comuna Umbraresti", "value": "C95354"},
                   {"label": "Comuna Valea Marului", "value": "C95355"},
                   {"label": "Comuna Vanatori", "value": "C95356"},
                   {"label": "Comuna Varlezi", "value": "C95357"},
                   {"label": "Comuna Vladesti", "value": "C95358"},
                   {"label": "Corni", "value": "C95359"},
                   {"label": "Corod", "value": "C95360"},
                   {"label": "Cosmesti", "value": "C95361"},
                   {"label": "Cosmestii-Vale", "value": "C95362"},
                   {"label": "Costache Negri", "value": "C95363"},
                   {"label": "Craiesti", "value": "C95364"},
                   {"label": "Cuca", "value": "C95365"},
                   {"label": "Cudalbi", "value": "C95366"},
                   {"label": "Cuza Voda", "value": "C95367"},
                   {"label": "Draganesti", "value": "C95368"},
                   {"label": "Draguseni", "value": "C95369"},
                   {"label": "Fantanele", "value": "C95370"},
                   {"label": "Fartanesti", "value": "C95371"},
                   {"label": "Foltesti", "value": "C95372"},
                   {"label": "Frumusita", "value": "C95373"},
                   {"label": "Fundeni", "value": "C95374"},
                   {"label": "Furcenii Noi", "value": "C95375"},
                   {"label": "Galati", "value": "C95376"},
                   {"label": "Ganesti", "value": "C95377"},
                   {"label": "Ghidigeni", "value": "C95378"},
                   {"label": "Gohor", "value": "C95379"},
                   {"label": "Grivita", "value": "C95380"},
                   {"label": "Hanu Conachi", "value": "C95381"},
                   {"label": "Independenta", "value": "C95382"},
                   {"label": "Ivesti", "value": "C95383"},
                   {"label": "Jorasti", "value": "C95384"},
                   {"label": "Liesti", "value": "C95385"},
                   {"label": "Mandresti", "value": "C95386"},
                   {"label": "Mastacani", "value": "C95387"},
                   {"label": "Matca", "value": "C95388"},
                   {"label": "Moscu", "value": "C95389"},
                   {"label": "Movileni", "value": "C95390"},
                   {"label": "Municipiul Galati", "value": "C95391"},
                   {"label": "Municipiul Tecuci", "value": "C95392"},
                   {"label": "Munteni", "value": "C95393"},
                   {"label": "Namoloasa", "value": "C95394"},
                   {"label": "Namoloasa-Sat", "value": "C95395"},
                   {"label": "Negrilesti", "value": "C95396"},
                   {"label": "Nicoresti", "value": "C95397"},
                   {"label": "Oancea", "value": "C95398"},
                   {"label": "Odaia Manolache", "value": "C95399"},
                   {"label": "Oras Beresti", "value": "C95400"},
                   {"label": "Oras Targu Bujor", "value": "C95401"},
                   {"label": "Pechea", "value": "C95402"},
                   {"label": "Piscu", "value": "C95403"},
                   {"label": "Podoleni", "value": "C95404"},
                   {"label": "Poiana", "value": "C95405"},
                   {"label": "Priponesti", "value": "C95406"},
                   {"label": "Radesti", "value": "C95407"},
                   {"label": "Rediu", "value": "C95408"},
                   {"label": "Scanteiesti", "value": "C95409"},
                   {"label": "Schela", "value": "C95410"},
                   {"label": "Sendreni", "value": "C95411"},
                   {"label": "Sivita", "value": "C95412"},
                   {"label": "Slobozia Conachi", "value": "C95413"},
                   {"label": "Smardan", "value": "C95414"},
                   {"label": "Smulti", "value": "C95415"},
                   {"label": "Suceveni", "value": "C95416"},
                   {"label": "Suhurlui", "value": "C95417"},
                   {"label": "Talpigi", "value": "C95418"},
                   {"label": "Targu Bujor", "value": "C95419"},
                   {"label": "Tecuci", "value": "C95420"},
                   {"label": "Tepu", "value": "C95421"},
                   {"label": "Toflea", "value": "C95422"},
                   {"label": "Tudor Vladimirescu", "value": "C95423"},
                   {"label": "Tulucesti", "value": "C95424"},
                   {"label": "Umbraresti", "value": "C95425"},
                   {"label": "Umbraresti-Deal", "value": "C95426"},
                   {"label": "Ungureni", "value": "C95427"},
                   {"label": "Valea Marului", "value": "C95428"},
                   {"label": "Vanatori", "value": "C95429"},
                   {"label": "Varlezi", "value": "C95430"},
                   {"label": "Viile", "value": "C95431"},
                   {"label": "Vladesti", "value": "C95432"}],
 "Galguduud": [{"label": "Ceelbuur", "value": "C107894"},
               {"label": "Ceeldheer", "value": "C107895"},
               {"label": "Dhuusamarreeb", "value": "C107896"}],
 "Galicia": [{"label": "A Bana", "value": "C112009"},
             {"label": "A Coruna", "value": "C112010"},
             {"label": "A Estrada", "value": "C112011"},
             {"label": "A Pobra do Brollon", "value": "C112012"},
             {"label": "A Pobra do Caraminal", "value": "C112013"},
             {"label": "A Rua", "value": "C112014"},
             {"label": "Abadin", "value": "C112015"},
             {"label": "Abegondo", "value": "C112016"},
             {"label": "Allariz", "value": "C112017"},
             {"label": "Ambia", "value": "C112018"},
             {"label": "Ames", "value": "C112019"},
             {"label": "Amoeiro", "value": "C112020"},
             {"label": "Aranga", "value": "C112021"},
             {"label": "Arbo", "value": "C112022"},
             {"label": "Ares", "value": "C112023"},
             {"label": "Arteixo", "value": "C112024"},
             {"label": "Arzua", "value": "C112025"},
             {"label": "As Pontes de Garcia Rodriguez", "value": "C112026"},
             {"label": "Avion", "value": "C112027"},
             {"label": "Baiona", "value": "C112028"},
             {"label": "Baltar", "value": "C112029"},
             {"label": "Bande", "value": "C112030"},
             {"label": "Banos de Molgas", "value": "C112031"},
             {"label": "Baralla", "value": "C112032"},
             {"label": "Barreiros", "value": "C112033"},
             {"label": "Beade", "value": "C112034"},
             {"label": "Becerrea", "value": "C112035"},
             {"label": "Begonte", "value": "C112036"},
             {"label": "Bergondo", "value": "C112037"},
             {"label": "Betanzos", "value": "C112038"},
             {"label": "Blancos", "value": "C112039"},
             {"label": "Boboras", "value": "C112040"},
             {"label": "Boimorto", "value": "C112041"},
             {"label": "Boiro", "value": "C112042"},
             {"label": "Bueu", "value": "C112043"},
             {"label": "Burela de Cabo", "value": "C112044"},
             {"label": "Caldas de Reis", "value": "C112045"},
             {"label": "Calvos", "value": "C112046"},
             {"label": "Camarinas", "value": "C112047"},
             {"label": "Cambados", "value": "C112048"},
             {"label": "Cambre", "value": "C112049"},
             {"label": "Cangas do Morrazo", "value": "C112050"},
             {"label": "Carballeda de Avia", "value": "C112051"},
             {"label": "Carballo", "value": "C112052"},
             {"label": "Carino", "value": "C112053"},
             {"label": "Carnota", "value": "C112054"},
             {"label": "Carral", "value": "C112055"},
             {"label": "Cartelle", "value": "C112056"},
             {"label": "Castrelo de Mino", "value": "C112057"},
             {"label": "Castro", "value": "C112058"},
             {"label": "Castro Caldelas", "value": "C112059"},
             {"label": "Castro de Rei", "value": "C112060"},
             {"label": "Castroverde", "value": "C112061"},
             {"label": "Catoira", "value": "C112062"},
             {"label": "Cea", "value": "C112063"},
             {"label": "Cedeira", "value": "C112064"},
             {"label": "Cee", "value": "C112065"},
             {"label": "Celanova", "value": "C112066"},
             {"label": "Cenlle", "value": "C112067"},
             {"label": "Cerdido", "value": "C112068"},
             {"label": "Cervo", "value": "C112069"},
             {"label": "Cesuras", "value": "C112070"},
             {"label": "Chantada", "value": "C112071"},
             {"label": "Coiros", "value": "C112072"},
             {"label": "Coles", "value": "C112073"},
             {"label": "Combarro", "value": "C112074"},
             {"label": "Corcubion", "value": "C112075"},
             {"label": "Corgo", "value": "C112076"},
             {"label": "Coristanco", "value": "C112077"},
             {"label": "Covelo", "value": "C112078"},
             {"label": "Cualedro", "value": "C112079"},
             {"label": "Culleredo", "value": "C112080"},
             {"label": "Cuntis", "value": "C112081"},
             {"label": "Curtis", "value": "C112082"},
             {"label": "Dozon", "value": "C112083"},
             {"label": "Dumbria", "value": "C112084"},
             {"label": "Entrimo", "value": "C112085"},
             {"label": "Esgos", "value": "C112086"},
             {"label": "Fene", "value": "C112087"},
             {"label": "Ferrol", "value": "C112088"},
             {"label": "Fisterra", "value": "C112089"},
             {"label": "Fonsagrada", "value": "C112090"},
             {"label": "Fornelos de Montes", "value": "C112091"},
             {"label": "Foz", "value": "C112092"},
             {"label": "Friol", "value": "C112093"},
             {"label": "Gondomar", "value": "C112094"},
             {"label": "Guitiriz", "value": "C112095"},
             {"label": "Illa de Arousa", "value": "C112096"},
             {"label": "Irixoa", "value": "C112097"},
             {"label": "Lalin", "value": "C112098"},
             {"label": "Lama", "value": "C112099"},
             {"label": "Lancara", "value": "C112100"},
             {"label": "Laracha", "value": "C112101"},
             {"label": "Laza", "value": "C112102"},
             {"label": "Lobios", "value": "C112103"},
             {"label": "Lousame", "value": "C112104"},
             {"label": "Lugo", "value": "C112105"},
             {"label": "Luintra", "value": "C112106"},
             {"label": "Maceda", "value": "C112107"},
             {"label": "Malpica", "value": "C112108"},
             {"label": "Manzaneda", "value": "C112109"},
             {"label": "Marin", "value": "C112110"},
             {"label": "Maside", "value": "C112111"},
             {"label": "Mazaricos", "value": "C112112"},
             {"label": "Meano", "value": "C112113"},
             {"label": "Meira", "value": "C112114"},
             {"label": "Meis", "value": "C112115"},
             {"label": "Melon", "value": "C112116"},
             {"label": "Mesia", "value": "C112117"},
             {"label": "Mino", "value": "C112118"},
             {"label": "Moana", "value": "C112119"},
             {"label": "Moeche", "value": "C112120"},
             {"label": "Mondariz", "value": "C112121"},
             {"label": "Mondariz-Balneario", "value": "C112122"},
             {"label": "Mondonedo", "value": "C112123"},
             {"label": "Monfero", "value": "C112124"},
             {"label": "Monforte de Lemos", "value": "C112125"},
             {"label": "Montederramo", "value": "C112126"},
             {"label": "Monterroso", "value": "C112127"},
             {"label": "Morana", "value": "C112128"},
             {"label": "Mos", "value": "C112129"},
             {"label": "Mugardos", "value": "C112130"},
             {"label": "Mugia", "value": "C112131"},
             {"label": "Muinos", "value": "C112132"},
             {"label": "Muras", "value": "C112133"},
             {"label": "Muros", "value": "C112134"},
             {"label": "Naron", "value": "C112135"},
             {"label": "Navia de Suarna", "value": "C112136"},
             {"label": "Negreira", "value": "C112137"},
             {"label": "Nigran", "value": "C112138"},
             {"label": "Nogueira de Ramuin", "value": "C112139"},
             {"label": "O Barco de Valdeorras", "value": "C112140"},
             {"label": "O Carballino", "value": "C112141"},
             {"label": "O Grove", "value": "C112142"},
             {"label": "O Incio", "value": "C112143"},
             {"label": "O Paramo", "value": "C112144"},
             {"label": "O Rosal", "value": "C112145"},
             {"label": "Oia", "value": "C112146"},
             {"label": "Oimbra", "value": "C112147"},
             {"label": "Oleiros", "value": "C112148"},
             {"label": "Ordes", "value": "C112149"},
             {"label": "Oroso", "value": "C112150"},
             {"label": "Ourense", "value": "C112151"},
             {"label": "Ourol", "value": "C112152"},
             {"label": "Outeiro", "value": "C112153"},
             {"label": "Outeiro de Rei", "value": "C112154"},
             {"label": "Outes", "value": "C112155"},
             {"label": "Padrenda", "value": "C112156"},
             {"label": "Padron", "value": "C112157"},
             {"label": "Palmeira", "value": "C112158"},
             {"label": "Panton", "value": "C112159"},
             {"label": "Paradela", "value": "C112160"},
             {"label": "Pastoriza", "value": "C112161"},
             {"label": "Pereiro de Aguiar", "value": "C112162"},
             {"label": "Petin", "value": "C112163"},
             {"label": "Pinor", "value": "C112164"},
             {"label": "Pobra de Trives", "value": "C112165"},
             {"label": "Poio", "value": "C112166"},
             {"label": "Ponteareas", "value": "C112167"},
             {"label": "Pontevedra", "value": "C112168"},
             {"label": "Porqueira", "value": "C112169"},
             {"label": "Porrino", "value": "C112170"},
             {"label": "Portas", "value": "C112171"},
             {"label": "Porto do Son", "value": "C112172"},
             {"label": "Portomarin", "value": "C112173"},
             {"label": "Portonovo", "value": "C112174"},
             {"label": "Provincia da Coruna", "value": "C112175"},
             {"label": "Provincia de Lugo", "value": "C112176"},
             {"label": "Provincia de Ourense", "value": "C112177"},
             {"label": "Provincia de Pontevedra", "value": "C112178"},
             {"label": "Puente Nuevo", "value": "C112179"},
             {"label": "Quintela de Leirado", "value": "C112180"},
             {"label": "Quiroga", "value": "C112181"},
             {"label": "Rabade", "value": "C112182"},
             {"label": "Rairiz de Veiga", "value": "C112183"},
             {"label": "Redondela", "value": "C112184"},
             {"label": "Rianxo", "value": "C112185"},
             {"label": "Ribadavia", "value": "C112186"},
             {"label": "Ribadeo", "value": "C112187"},
             {"label": "Ribadumia", "value": "C112188"},
             {"label": "Ribeira", "value": "C112189"},
             {"label": "Rios", "value": "C112190"},
             {"label": "Riotorto", "value": "C112191"},
             {"label": "Rodeiro", "value": "C112192"},
             {"label": "Sada", "value": "C112193"},
             {"label": "Salvatierra de Mino", "value": "C112194"},
             {"label": "Samos", "value": "C112195"},
             {"label": "San Amaro", "value": "C112196"},
             {"label": "San Roman", "value": "C112197"},
             {"label": "Santa Comba", "value": "C112198"},
             {"label": "Santa Marta de Ortigueira", "value": "C112199"},
             {"label": "Santa Uxia de Ribeira", "value": "C112200"},
             {"label": "Santiago de Compostela", "value": "C112201"},
             {"label": "Santiso", "value": "C112202"},
             {"label": "Sanxenxo", "value": "C112203"},
             {"label": "Sarreaus", "value": "C112204"},
             {"label": "Sarria", "value": "C112205"},
             {"label": "Silleda", "value": "C112206"},
             {"label": "Sober", "value": "C112207"},
             {"label": "Sobradelo", "value": "C112208"},
             {"label": "Sobrado", "value": "C112209"},
             {"label": "Somozas", "value": "C112210"},
             {"label": "Taboada", "value": "C112211"},
             {"label": "Taboadela", "value": "C112212"},
             {"label": "Teo", "value": "C112213"},
             {"label": "Toen", "value": "C112214"},
             {"label": "Tomino", "value": "C112215"},
             {"label": "Touro", "value": "C112216"},
             {"label": "Trabada", "value": "C112217"},
             {"label": "Trasmiras", "value": "C112218"},
             {"label": "Trazo", "value": "C112219"},
             {"label": "Tui", "value": "C112220"},
             {"label": "Valdovino", "value": "C112221"},
             {"label": "Valga", "value": "C112222"},
             {"label": "Vedra", "value": "C112223"},
             {"label": "Verea", "value": "C112224"},
             {"label": "Verin", "value": "C112225"},
             {"label": "Vigo", "value": "C112226"},
             {"label": "Vilagarcia de Arousa", "value": "C112227"},
             {"label": "Vilalba", "value": "C112228"},
             {"label": "Vilanova de Arousa", "value": "C112229"},
             {"label": "Vilasantar", "value": "C112230"},
             {"label": "Vilaxoan", "value": "C112231"},
             {"label": "Vimianzo", "value": "C112232"},
             {"label": "Viveiro", "value": "C112233"},
             {"label": "Xinzo de Limia", "value": "C112234"},
             {"label": "Zas", "value": "C112235"}],
 "Gambela Region": [{"label": "Administrative Zone 1", "value": "C24701"},
                    {"label": "Gambela", "value": "C24702"}],
 "Gamprin": [{"label": "Gamprin", "value": "C65073"}],
 "Gangwon Province": [{"label": "Cheorwon-gun", "value": "C108268"},
                      {"label": "Chuncheon", "value": "C108269"},
                      {"label": "Chuncheon-si", "value": "C108270"},
                      {"label": "Donghae-si", "value": "C108271"},
                      {"label": "Gangneung", "value": "C108272"},
                      {"label": "Gangneung-si", "value": "C108273"},
                      {"label": "Goseong-gun", "value": "C108274"},
                      {"label": "Hoengseong-gun", "value": "C108275"},
                      {"label": "Hongchon", "value": "C108276"},
                      {"label": "Hongcheon-gun", "value": "C108277"},
                      {"label": "Hwacheon", "value": "C108278"},
                      {"label": "Hwacheon-gun", "value": "C108279"},
                      {"label": "Inje-gun", "value": "C108280"},
                      {"label": "Jeongseon-gun", "value": "C108281"},
                      {"label": "Kosong", "value": "C108282"},
                      {"label": "Neietsu", "value": "C108283"},
                      {"label": "Pyeongchang", "value": "C108284"},
                      {"label": "Pyeongchang-gun", "value": "C108285"},
                      {"label": "Samcheok-si", "value": "C108286"},
                      {"label": "Santyoku", "value": "C108287"},
                      {"label": "Sokcho", "value": "C108288"},
                      {"label": "Sokcho-si", "value": "C108289"},
                      {"label": "Taebaek", "value": "C108290"},
                      {"label": "Taebaek-si", "value": "C108291"},
                      {"label": "Tonghae", "value": "C108292"},
                      {"label": "Wonju", "value": "C108293"},
                      {"label": "Wonju-si", "value": "C108294"},
                      {"label": "Yanggu", "value": "C108295"},
                      {"label": "Yanggu-gun", "value": "C108296"},
                      {"label": "Yangyang-gun", "value": "C108297"}],
 "Ganja": [{"label": "Ganja", "value": "C8427"}],
 "Gansu": [{"label": "Baiyin", "value": "C18412"},
           {"label": "Beidao", "value": "C18413"},
           {"label": "Dingxi Shi", "value": "C18414"},
           {"label": "Hezuo", "value": "C18415"},
           {"label": "Jiayuguan", "value": "C18416"},
           {"label": "Jinchang", "value": "C18417"},
           {"label": "Jiuquan", "value": "C18418"},
           {"label": "Lanzhou", "value": "C18419"},
           {"label": "Laojunmiao", "value": "C18420"},
           {"label": "Linxia Chengguanzhen", "value": "C18421"},
           {"label": "Linxia Huizu Zizhizhou", "value": "C18422"},
           {"label": "Longnan Shi", "value": "C18423"},
           {"label": "Mawu", "value": "C18424"},
           {"label": "Pingliang", "value": "C18425"},
           {"label": "Qincheng", "value": "C18426"},
           {"label": "Qingyang Shi", "value": "C18427"},
           {"label": "Tianshui", "value": "C18428"},
           {"label": "Wuwei", "value": "C18429"},
           {"label": "Zhangye", "value": "C18430"},
           {"label": "Zhangye Shi", "value": "C18431"}],
 "Gao Region": [{"label": "Ansongo", "value": "C65823"},
                {"label": "Cercle de Bourem", "value": "C65824"},
                {"label": "Gao", "value": "C65825"},
                {"label": "Gao Cercle", "value": "C65826"},
                {"label": "Inekar", "value": "C65827"}],
 "Garissa": [{"label": "Garissa", "value": "C64552"}],
 "Garkalne Municipality": [{"label": "Garkalne", "value": "C64867"}],
 "Gasa District": [{"label": "Gasa", "value": "C9636"}],
 "Gash-Barka Region": [{"label": "Akordat", "value": "C24492"},
                       {"label": "Barentu", "value": "C24493"},
                       {"label": "Teseney", "value": "C24494"}],
 "Gauteng": [{"label": "Alberton", "value": "C108005"},
             {"label": "Benoni", "value": "C108006"},
             {"label": "Boksburg", "value": "C108007"},
             {"label": "Brakpan", "value": "C108008"},
             {"label": "Bronkhorstspruit", "value": "C108009"},
             {"label": "Carletonville", "value": "C108010"},
             {"label": "Centurion", "value": "C108011"},
             {"label": "City of Johannesburg Metropolitan Municipality",
              "value": "C108012"},
             {"label": "City of Tshwane Metropolitan Municipality",
              "value": "C108013"},
             {"label": "Cullinan", "value": "C108014"},
             {"label": "Diepsloot", "value": "C108015"},
             {"label": "Eastleigh", "value": "C108016"},
             {"label": "Eden Glen", "value": "C108017"},
             {"label": "Eden Glen Ext 60", "value": "C108018"},
             {"label": "Edenvale", "value": "C108019"},
             {"label": "Ekangala", "value": "C108020"},
             {"label": "Ekurhuleni Metropolitan Municipality",
              "value": "C108021"},
             {"label": "Heidelberg", "value": "C108022"},
             {"label": "Johannesburg", "value": "C108023"},
             {"label": "Krugersdorp", "value": "C108024"},
             {"label": "Mabopane", "value": "C108025"},
             {"label": "Midrand", "value": "C108026"},
             {"label": "Midstream", "value": "C108027"},
             {"label": "Modderfontein", "value": "C108028"},
             {"label": "Muldersdriseloop", "value": "C108029"},
             {"label": "Nigel", "value": "C108030"},
             {"label": "Orange Farm", "value": "C108031"},
             {"label": "Pretoria", "value": "C108032"},
             {"label": "Randburg", "value": "C108033"},
             {"label": "Randfontein", "value": "C108034"},
             {"label": "Roodepoort", "value": "C108035"},
             {"label": "Sedibeng District Municipality", "value": "C108036"},
             {"label": "Soweto", "value": "C108037"},
             {"label": "Springs", "value": "C108038"},
             {"label": "Tembisa", "value": "C108039"},
             {"label": "Vanderbijlpark", "value": "C108040"},
             {"label": "Vereeniging", "value": "C108041"},
             {"label": "West Rand District Municipality", "value": "C108042"},
             {"label": "Westonaria", "value": "C108043"}],
 "Gavleborg County": [{"label": "Alfta", "value": "C115559"},
                      {"label": "Arbra", "value": "C115560"},
                      {"label": "Arsunda", "value": "C115561"},
                      {"label": "Bergby", "value": "C115562"},
                      {"label": "Bergsjo", "value": "C115563"},
                      {"label": "Bollnas", "value": "C115564"},
                      {"label": "Delsbo", "value": "C115565"},
                      {"label": "Edsbyn", "value": "C115566"},
                      {"label": "Farila", "value": "C115567"},
                      {"label": "Forsbacka", "value": "C115568"},
                      {"label": "Gavle", "value": "C115569"},
                      {"label": "Gnarp", "value": "C115570"},
                      {"label": "Hedesunda", "value": "C115571"},
                      {"label": "Hofors", "value": "C115572"},
                      {"label": "Hudiksvall", "value": "C115573"},
                      {"label": "Iggesund", "value": "C115574"},
                      {"label": "Jarbo", "value": "C115575"},
                      {"label": "Jarvso", "value": "C115576"},
                      {"label": "Kilafors", "value": "C115577"},
                      {"label": "Ljusdal", "value": "C115578"},
                      {"label": "Ljusne", "value": "C115579"},
                      {"label": "Ockelbo", "value": "C115580"},
                      {"label": "Sandarne", "value": "C115581"},
                      {"label": "Sandviken", "value": "C115582"},
                      {"label": "Soderhamn", "value": "C115583"},
                      {"label": "Sorforsa", "value": "C115584"},
                      {"label": "Storvik", "value": "C115585"},
                      {"label": "Valbo", "value": "C115586"}],
 "Gaza Province": [{"label": "Chibuto", "value": "C75856"},
                   {"label": "Chokwe", "value": "C75857"},
                   {"label": "Macia", "value": "C75858"},
                   {"label": "Xai-Xai", "value": "C75859"}],
 "Gazi Baba Municipality": [{"label": "Cresevo", "value": "C65401"}],
 "Gaziantep": [{"label": "Araban", "value": "C120317"},
               {"label": "Doganpinar", "value": "C120318"},
               {"label": "Gaziantep", "value": "C120319"},
               {"label": "Ikizce", "value": "C120320"},
               {"label": "Islahiye Ilcesi", "value": "C120321"},
               {"label": "Karkamis", "value": "C120322"},
               {"label": "Nizip", "value": "C120323"},
               {"label": "Nurdagi", "value": "C120324"},
               {"label": "Oguzeli", "value": "C120325"},
               {"label": "Sahinbey Ilcesi", "value": "C120326"},
               {"label": "Sehitkamil", "value": "C120327"},
               {"label": "Sekili", "value": "C120328"},
               {"label": "Uluyatir", "value": "C120329"},
               {"label": "Yavuzeli", "value": "C120330"},
               {"label": "Yesildere", "value": "C120331"}],
 "Gbarpolu County": [{"label": "Bopolu", "value": "C65004"}],
 "Gedo": [{"label": "Baardheere", "value": "C107897"},
          {"label": "Garbahaarrey", "value": "C107898"},
          {"label": "Luuq", "value": "C107899"}],
 "Gegharkunik Province": [{"label": "Akunk", "value": "C1673"},
                          {"label": "Astghadzor", "value": "C1674"},
                          {"label": "Chambarak", "value": "C1675"},
                          {"label": "Ddmashen", "value": "C1676"},
                          {"label": "Drakhtik", "value": "C1677"},
                          {"label": "Dzoragyugh", "value": "C1678"},
                          {"label": "Gagarin", "value": "C1679"},
                          {"label": "Gandzak", "value": "C1680"},
                          {"label": "Gavarr", "value": "C1681"},
                          {"label": "Geghamasar", "value": "C1682"},
                          {"label": "Geghamavan", "value": "C1683"},
                          {"label": "Karanlukh", "value": "C1684"},
                          {"label": "Karchaghbyur", "value": "C1685"},
                          {"label": "Lanjaghbyur", "value": "C1686"},
                          {"label": "Lchap", "value": "C1687"},
                          {"label": "Lchashen", "value": "C1688"},
                          {"label": "Lichk", "value": "C1689"},
                          {"label": "Madina", "value": "C1690"},
                          {"label": "Martuni", "value": "C1691"},
                          {"label": "Mets Masrik", "value": "C1692"},
                          {"label": "Nerkin Getashen", "value": "C1693"},
                          {"label": "Noratus", "value": "C1694"},
                          {"label": "Sarukhan", "value": "C1695"},
                          {"label": "Sevan", "value": "C1696"},
                          {"label": "Tsovagyugh", "value": "C1697"},
                          {"label": "Tsovak", "value": "C1698"},
                          {"label": "Tsovasar", "value": "C1699"},
                          {"label": "Tsovazard", "value": "C1700"},
                          {"label": "Tsovinar", "value": "C1701"},
                          {"label": "Vaghashen", "value": "C1702"},
                          {"label": "Vahan", "value": "C1703"},
                          {"label": "Vardenik", "value": "C1704"},
                          {"label": "Vardenis", "value": "C1705"},
                          {"label": "Varser", "value": "C1706"},
                          {"label": "Verin Getashen", "value": "C1707"},
                          {"label": "Yeranos", "value": "C1708"}],
 "Geita": [{"label": "Buseresere", "value": "C118088"},
           {"label": "Chato", "value": "C118089"},
           {"label": "Geita", "value": "C118090"},
           {"label": "Kasamwa", "value": "C118091"},
           {"label": "Katoro", "value": "C118092"},
           {"label": "Masumbwe", "value": "C118093"},
           {"label": "Ushirombo", "value": "C118094"},
           {"label": "Uyovu", "value": "C118095"}],
 "Gelderland": [{"label": "s-Heerenberg", "value": "C76272"},
                {"label": "Aalst", "value": "C76273"},
                {"label": "Aalten", "value": "C76274"},
                {"label": "Afferden", "value": "C76275"},
                {"label": "Alverna", "value": "C76276"},
                {"label": "Ammerzoden", "value": "C76277"},
                {"label": "Andelst", "value": "C76278"},
                {"label": "Angeren", "value": "C76279"},
                {"label": "Angerlo", "value": "C76280"},
                {"label": "Anklaar", "value": "C76281"},
                {"label": "Apeldoorn", "value": "C76282"},
                {"label": "Arnhem", "value": "C76283"},
                {"label": "Babberich", "value": "C76284"},
                {"label": "Bakenberg", "value": "C76285"},
                {"label": "Barneveld", "value": "C76286"},
                {"label": "Beek gem Montferland", "value": "C76287"},
                {"label": "Beekbergen", "value": "C76288"},
                {"label": "Beesd", "value": "C76289"},
                {"label": "Beltrum", "value": "C76290"},
                {"label": "Bemmel", "value": "C76291"},
                {"label": "Bennekom", "value": "C76292"},
                {"label": "Berg en Bos", "value": "C76293"},
                {"label": "Berg en Dal", "value": "C76294"},
                {"label": "Bergharen", "value": "C76295"},
                {"label": "Berkelland", "value": "C76296"},
                {"label": "Beuningen", "value": "C76297"},
                {"label": "Beusichem", "value": "C76298"},
                {"label": "Binnenstad", "value": "C76299"},
                {"label": "Borculo", "value": "C76300"},
                {"label": "Brakel", "value": "C76301"},
                {"label": "Brakkenstein", "value": "C76302"},
                {"label": "Bredevoort", "value": "C76303"},
                {"label": "Bredeweg", "value": "C76304"},
                {"label": "Brinkhorst", "value": "C76305"},
                {"label": "Bruchem", "value": "C76306"},
                {"label": "Brummelhof", "value": "C76307"},
                {"label": "Brummen", "value": "C76308"},
                {"label": "Burgemeesterswijk", "value": "C76309"},
                {"label": "Componistenkwartier", "value": "C76310"},
                {"label": "Corlaer", "value": "C76311"},
                {"label": "Culemborg", "value": "C76312"},
                {"label": "De Bouwhof", "value": "C76313"},
                {"label": "De Haven", "value": "C76314"},
                {"label": "De Heeze", "value": "C76315"},
                {"label": "De Mheen", "value": "C76316"},
                {"label": "Deest", "value": "C76317"},
                {"label": "Deil", "value": "C76318"},
                {"label": "Dieren", "value": "C76319"},
                {"label": "Doesburg", "value": "C76320"},
                {"label": "Doetinchem", "value": "C76321"},
                {"label": "Doornenburg", "value": "C76322"},
                {"label": "Doornspijk", "value": "C76323"},
                {"label": "Doornsteeg", "value": "C76324"},
                {"label": "Doorwerth", "value": "C76325"},
                {"label": "Driehuizen", "value": "C76326"},
                {"label": "Driel", "value": "C76327"},
                {"label": "Drumpt", "value": "C76328"},
                {"label": "Druten", "value": "C76329"},
                {"label": "Duiven", "value": "C76330"},
                {"label": "Ede", "value": "C76331"},
                {"label": "Ederveen", "value": "C76332"},
                {"label": "Eefde", "value": "C76333"},
                {"label": "Eerbeek", "value": "C76334"},
                {"label": "Eibergen", "value": "C76335"},
                {"label": "Elburg", "value": "C76336"},
                {"label": "Elden", "value": "C76337"},
                {"label": "Ellecom", "value": "C76338"},
                {"label": "Elspeet", "value": "C76339"},
                {"label": "Elst", "value": "C76340"},
                {"label": "Emst", "value": "C76341"},
                {"label": "Epe", "value": "C76342"},
                {"label": "Epse", "value": "C76343"},
                {"label": "Ermelo", "value": "C76344"},
                {"label": "Etten", "value": "C76345"},
                {"label": "Gameren", "value": "C76346"},
                {"label": "Garderen", "value": "C76347"},
                {"label": "Geitenkamp", "value": "C76348"},
                {"label": "Geldermalsen", "value": "C76349"},
                {"label": "Geldermalsen-West", "value": "C76350"},
                {"label": "Gendringen", "value": "C76351"},
                {"label": "Gendt", "value": "C76352"},
                {"label": "Giesbeek", "value": "C76353"},
                {"label": "Gorssel", "value": "C76354"},
                {"label": "Groesbeek", "value": "C76355"},
                {"label": "Groessen", "value": "C76356"},
                {"label": "Gulden Bodem", "value": "C76357"},
                {"label": "Haaften", "value": "C76358"},
                {"label": "Haalderen", "value": "C76359"},
                {"label": "Harderwijk", "value": "C76360"},
                {"label": "Harskamp", "value": "C76361"},
                {"label": "Hatert", "value": "C76362"},
                {"label": "Hattem", "value": "C76363"},
                {"label": "Hattemerbroek", "value": "C76364"},
                {"label": "Hedel", "value": "C76365"},
                {"label": "Heelsum", "value": "C76366"},
                {"label": "Heerde", "value": "C76367"},
                {"label": "Heerewaarden", "value": "C76368"},
                {"label": "Hees", "value": "C76369"},
                {"label": "Hengelo", "value": "C76370"},
                {"label": "Hengstdal", "value": "C76371"},
                {"label": "Herwijnen", "value": "C76372"},
                {"label": "Het Loo", "value": "C76373"},
                {"label": "Heteren", "value": "C76374"},
                {"label": "Heumen", "value": "C76375"},
                {"label": "Hoogkamp", "value": "C76376"},
                {"label": "Horssen", "value": "C76377"},
                {"label": "Hummelo", "value": "C76378"},
                {"label": "Hunnerberg", "value": "C76379"},
                {"label": "Ingen", "value": "C76380"},
                {"label": "Keijenborg", "value": "C76381"},
                {"label": "Kerkdriel", "value": "C76382"},
                {"label": "Kerschoten", "value": "C76383"},
                {"label": "Kesteren", "value": "C76384"},
                {"label": "Kootwijkerbroek", "value": "C76385"},
                {"label": "Laren", "value": "C76386"},
                {"label": "Leesten", "value": "C76387"},
                {"label": "Lent", "value": "C76388"},
                {"label": "Leuth", "value": "C76389"},
                {"label": "Lichtenvoorde", "value": "C76390"},
                {"label": "Lienden", "value": "C76391"},
                {"label": "Lindenholt", "value": "C76392"},
                {"label": "Lobith", "value": "C76393"},
                {"label": "Lochem", "value": "C76394"},
                {"label": "Loenen", "value": "C76395"},
                {"label": "Lunteren", "value": "C76396"},
                {"label": "Malburgen West", "value": "C76397"},
                {"label": "Matendonk", "value": "C76398"},
                {"label": "Matendreef", "value": "C76399"},
                {"label": "Matengaarde", "value": "C76400"},
                {"label": "Matenhoeve", "value": "C76401"},
                {"label": "Matenhorst", "value": "C76402"},
                {"label": "Matenveld", "value": "C76403"},
                {"label": "Maurik", "value": "C76404"},
                {"label": "Meteren", "value": "C76405"},
                {"label": "Nederhemert", "value": "C76406"},
                {"label": "Nederhemert-Noord", "value": "C76407"},
                {"label": "Nederwoud", "value": "C76408"},
                {"label": "Neede", "value": "C76409"},
                {"label": "Nijkerk", "value": "C76410"},
                {"label": "Nijkerkerveen", "value": "C76411"},
                {"label": "Nijmegen", "value": "C76412"},
                {"label": "Nunspeet", "value": "C76413"},
                {"label": "Ochten", "value": "C76414"},
                {"label": "Oldebroek", "value": "C76415"},
                {"label": "Ooij", "value": "C76416"},
                {"label": "Oost Gelre", "value": "C76417"},
                {"label": "Oostendorp", "value": "C76418"},
                {"label": "Oosterbeek", "value": "C76419"},
                {"label": "Oosterhout", "value": "C76420"},
                {"label": "Ooy", "value": "C76421"},
                {"label": "Ooyerhoek", "value": "C76422"},
                {"label": "Ophemert", "value": "C76423"},
                {"label": "Opheusden", "value": "C76424"},
                {"label": "Opijnen", "value": "C76425"},
                {"label": "Orden", "value": "C76426"},
                {"label": "Otterlo", "value": "C76427"},
                {"label": "Oude IJsselstreek", "value": "C76428"},
                {"label": "Overasselt", "value": "C76429"},
                {"label": "Pannerden", "value": "C76430"},
                {"label": "Puiflijk", "value": "C76431"},
                {"label": "Putten", "value": "C76432"},
                {"label": "Renkum", "value": "C76433"},
                {"label": "Rheden", "value": "C76434"},
                {"label": "Rivierenkwartier", "value": "C76435"},
                {"label": "Rossum", "value": "C76436"},
                {"label": "Rozendaal", "value": "C76437"},
                {"label": "Ruurlo", "value": "C76438"},
                {"label": "Scherpenzeel", "value": "C76439"},
                {"label": "Silvolde", "value": "C76440"},
                {"label": "Sluisoord", "value": "C76441"},
                {"label": "Spainkbos", "value": "C76442"},
                {"label": "Sprengenbos", "value": "C76443"},
                {"label": "Sprengenweg-Noord", "value": "C76444"},
                {"label": "Sprenkelaar", "value": "C76445"},
                {"label": "Staatsliedenkwartier", "value": "C76446"},
                {"label": "Steenderen", "value": "C76447"},
                {"label": "Stegeslag", "value": "C76448"},
                {"label": "Sterrenberg", "value": "C76449"},
                {"label": "Terborg", "value": "C76450"},
                {"label": "Terschuur", "value": "C76451"},
                {"label": "Terwolde", "value": "C76452"},
                {"label": "Tiel", "value": "C76453"},
                {"label": "Tolkamer", "value": "C76454"},
                {"label": "Tricht", "value": "C76455"},
                {"label": "Twello", "value": "C76456"},
                {"label": "Uddel", "value": "C76457"},
                {"label": "Ugchelen", "value": "C76458"},
                {"label": "Vaassen", "value": "C76459"},
                {"label": "Valburg", "value": "C76460"},
                {"label": "Valendries", "value": "C76461"},
                {"label": "Varsseveld", "value": "C76462"},
                {"label": "Velp", "value": "C76463"},
                {"label": "Vogelkwartier", "value": "C76464"},
                {"label": "Voorst", "value": "C76465"},
                {"label": "Voorthuizen", "value": "C76466"},
                {"label": "Vorden", "value": "C76467"},
                {"label": "Vredenburg", "value": "C76468"},
                {"label": "Vuren", "value": "C76469"},
                {"label": "Waardenburg", "value": "C76470"},
                {"label": "Wageningen", "value": "C76471"},
                {"label": "Wapenveld", "value": "C76472"},
                {"label": "Warnsveld", "value": "C76473"},
                {"label": "Wekerom", "value": "C76474"},
                {"label": "Welgelegen", "value": "C76475"},
                {"label": "West Betuwe", "value": "C76476"},
                {"label": "Westeneng", "value": "C76477"},
                {"label": "Westenenk", "value": "C76478"},
                {"label": "Westervoort", "value": "C76479"},
                {"label": "Wijchen", "value": "C76480"},
                {"label": "Winkewijert", "value": "C76481"},
                {"label": "Winterswijk", "value": "C76482"},
                {"label": "Wisch", "value": "C76483"},
                {"label": "Wolfheze", "value": "C76484"},
                {"label": "Woudhuis", "value": "C76485"},
                {"label": "Zaltbommel", "value": "C76486"},
                {"label": "Zeddam", "value": "C76487"},
                {"label": "Zelhem", "value": "C76488"},
                {"label": "Zetten", "value": "C76489"},
                {"label": "Zevenaar", "value": "C76490"},
                {"label": "Zevenhuizen", "value": "C76491"},
                {"label": "Zoelen", "value": "C76492"},
                {"label": "Zonnemaat", "value": "C76493"},
                {"label": "Zuilichem", "value": "C76494"},
                {"label": "Zutphen", "value": "C76495"}],
 "Geneva": [{"label": "Aire-la-Ville", "value": "C116714"},
            {"label": "Anieres", "value": "C116715"},
            {"label": "Bellevue", "value": "C116716"},
            {"label": "Bernex", "value": "C116717"},
            {"label": "Carouge", "value": "C116718"},
            {"label": "Chancy", "value": "C116719"},
            {"label": "Chene-Bougeries", "value": "C116720"},
            {"label": "Chene-Bourg", "value": "C116721"},
            {"label": "Confignon", "value": "C116722"},
            {"label": "Corsier", "value": "C116723"},
            {"label": "Dardagny", "value": "C116724"},
            {"label": "Geneve", "value": "C116725"},
            {"label": "Jussy", "value": "C116726"},
            {"label": "Lancy", "value": "C116727"},
            {"label": "Le Grand-Saconnex", "value": "C116728"},
            {"label": "Les Avanchets", "value": "C116729"},
            {"label": "Meinier", "value": "C116730"},
            {"label": "Meyrin", "value": "C116731"},
            {"label": "Onex", "value": "C116732"},
            {"label": "Plan-les-Ouates", "value": "C116733"},
            {"label": "Puplinge", "value": "C116734"},
            {"label": "Satigny", "value": "C116735"},
            {"label": "Thonex", "value": "C116736"},
            {"label": "Troinex", "value": "C116737"},
            {"label": "Vandoeuvres", "value": "C116738"},
            {"label": "Vernier", "value": "C116739"},
            {"label": "Versoix", "value": "C116740"},
            {"label": "Veyrier", "value": "C116741"}],
 "Georgia": [{"label": "Abbeville", "value": "C130499"},
             {"label": "Acworth", "value": "C130500"},
             {"label": "Adairsville", "value": "C130501"},
             {"label": "Adel", "value": "C130502"},
             {"label": "Alamo", "value": "C130503"},
             {"label": "Albany", "value": "C130504"},
             {"label": "Alma", "value": "C130505"},
             {"label": "Alpharetta", "value": "C130506"},
             {"label": "Alto", "value": "C130507"},
             {"label": "Americus", "value": "C130508"},
             {"label": "Appling County", "value": "C130509"},
             {"label": "Aragon", "value": "C130510"},
             {"label": "Arcade", "value": "C130511"},
             {"label": "Arlington", "value": "C130512"},
             {"label": "Ashburn", "value": "C130513"},
             {"label": "Athens", "value": "C130514"},
             {"label": "Atkinson County", "value": "C130515"},
             {"label": "Atlanta", "value": "C130516"},
             {"label": "Auburn", "value": "C130517"},
             {"label": "Augusta", "value": "C130518"},
             {"label": "Austell", "value": "C130519"},
             {"label": "Avondale Estates", "value": "C130520"},
             {"label": "Bacon County", "value": "C130521"},
             {"label": "Bainbridge", "value": "C130522"},
             {"label": "Baker County", "value": "C130523"},
             {"label": "Baldwin", "value": "C130524"},
             {"label": "Baldwin County", "value": "C130525"},
             {"label": "Ball Ground", "value": "C130526"},
             {"label": "Banks County", "value": "C130527"},
             {"label": "Barnesville", "value": "C130528"},
             {"label": "Barrow County", "value": "C130529"},
             {"label": "Bartow County", "value": "C130530"},
             {"label": "Baxley", "value": "C130531"},
             {"label": "Belvedere Park", "value": "C130532"},
             {"label": "Ben Hill County", "value": "C130533"},
             {"label": "Berkeley Lake", "value": "C130534"},
             {"label": "Berrien County", "value": "C130535"},
             {"label": "Bibb County", "value": "C130536"},
             {"label": "Blackshear", "value": "C130537"},
             {"label": "Blairsville", "value": "C130538"},
             {"label": "Blakely", "value": "C130539"},
             {"label": "Bleckley County", "value": "C130540"},
             {"label": "Bloomingdale", "value": "C130541"},
             {"label": "Blue Ridge", "value": "C130542"},
             {"label": "Bogart", "value": "C130543"},
             {"label": "Bonanza", "value": "C130544"},
             {"label": "Boston", "value": "C130545"},
             {"label": "Bowdon", "value": "C130546"},
             {"label": "Brantley County", "value": "C130547"},
             {"label": "Braselton", "value": "C130548"},
             {"label": "Bremen", "value": "C130549"},
             {"label": "Brookhaven", "value": "C130550"},
             {"label": "Brooklet", "value": "C130551"},
             {"label": "Brooks County", "value": "C130552"},
             {"label": "Broxton", "value": "C130553"},
             {"label": "Brunswick", "value": "C130554"},
             {"label": "Bryan County", "value": "C130555"},
             {"label": "Buchanan", "value": "C130556"},
             {"label": "Buena Vista", "value": "C130557"},
             {"label": "Buford", "value": "C130558"},
             {"label": "Bulloch County", "value": "C130559"},
             {"label": "Burke County", "value": "C130560"},
             {"label": "Butler", "value": "C130561"},
             {"label": "Butts County", "value": "C130562"},
             {"label": "Byron", "value": "C130563"},
             {"label": "Cairo", "value": "C130564"},
             {"label": "Calhoun", "value": "C130565"},
             {"label": "Calhoun County", "value": "C130566"},
             {"label": "Camden County", "value": "C130567"},
             {"label": "Camilla", "value": "C130568"},
             {"label": "Candler County", "value": "C130569"},
             {"label": "Candler-McAfee", "value": "C130570"},
             {"label": "Canton", "value": "C130571"},
             {"label": "Carnesville", "value": "C130572"},
             {"label": "Carroll County", "value": "C130573"},
             {"label": "Carrollton", "value": "C130574"},
             {"label": "Cartersville", "value": "C130575"},
             {"label": "Catoosa County", "value": "C130576"},
             {"label": "Cave Spring", "value": "C130577"},
             {"label": "Cedartown", "value": "C130578"},
             {"label": "Centerville", "value": "C130579"},
             {"label": "Chamblee", "value": "C130580"},
             {"label": "Charlton County", "value": "C130581"},
             {"label": "Chatham County", "value": "C130582"},
             {"label": "Chatsworth", "value": "C130583"},
             {"label": "Chattahoochee County", "value": "C130584"},
             {"label": "Chattahoochee Hills", "value": "C130585"},
             {"label": "Chattanooga Valley", "value": "C130586"},
             {"label": "Chattooga County", "value": "C130587"},
             {"label": "Cherokee County", "value": "C130588"},
             {"label": "Chester", "value": "C130589"},
             {"label": "Chickamauga", "value": "C130590"},
             {"label": "Clarke County", "value": "C130591"},
             {"label": "Clarkesville", "value": "C130592"},
             {"label": "Clarkston", "value": "C130593"},
             {"label": "Claxton", "value": "C130594"},
             {"label": "Clay County", "value": "C130595"},
             {"label": "Clayton", "value": "C130596"},
             {"label": "Clayton County", "value": "C130597"},
             {"label": "Cleveland", "value": "C130598"},
             {"label": "Clinch County", "value": "C130599"},
             {"label": "Cobb County", "value": "C130600"},
             {"label": "Cochran", "value": "C130601"},
             {"label": "Coffee County", "value": "C130602"},
             {"label": "College Park", "value": "C130603"},
             {"label": "Colquitt", "value": "C130604"},
             {"label": "Colquitt County", "value": "C130605"},
             {"label": "Columbia County", "value": "C130606"},
             {"label": "Columbus", "value": "C130607"},
             {"label": "Comer", "value": "C130608"},
             {"label": "Commerce", "value": "C130609"},
             {"label": "Conley", "value": "C130610"},
             {"label": "Conyers", "value": "C130611"},
             {"label": "Cook County", "value": "C130612"},
             {"label": "Cordele", "value": "C130613"},
             {"label": "Cornelia", "value": "C130614"},
             {"label": "Country Club Estates", "value": "C130615"},
             {"label": "Covington", "value": "C130616"},
             {"label": "Coweta County", "value": "C130617"},
             {"label": "Crawford County", "value": "C130618"},
             {"label": "Crawfordville", "value": "C130619"},
             {"label": "Crisp County", "value": "C130620"},
             {"label": "Cumming", "value": "C130621"},
             {"label": "Cusseta", "value": "C130622"},
             {"label": "Cuthbert", "value": "C130623"},
             {"label": "Dacula", "value": "C130624"},
             {"label": "Dade County", "value": "C130625"},
             {"label": "Dahlonega", "value": "C130626"},
             {"label": "Dallas", "value": "C130627"},
             {"label": "Dalton", "value": "C130628"},
             {"label": "Danielsville", "value": "C130629"},
             {"label": "Darien", "value": "C130630"},
             {"label": "Davisboro", "value": "C130631"},
             {"label": "Dawson", "value": "C130632"},
             {"label": "Dawson County", "value": "C130633"},
             {"label": "Dawsonville", "value": "C130634"},
             {"label": "Decatur", "value": "C130635"},
             {"label": "Decatur County", "value": "C130636"},
             {"label": "Deenwood", "value": "C130637"},
             {"label": "DeKalb County", "value": "C130638"},
             {"label": "Demorest", "value": "C130639"},
             {"label": "Dock Junction", "value": "C130640"},
             {"label": "Dodge County", "value": "C130641"},
             {"label": "Donalsonville", "value": "C130642"},
             {"label": "Dooly County", "value": "C130643"},
             {"label": "Doraville", "value": "C130644"},
             {"label": "Dougherty County", "value": "C130645"},
             {"label": "Douglas", "value": "C130646"},
             {"label": "Douglas County", "value": "C130647"},
             {"label": "Douglasville", "value": "C130648"},
             {"label": "Druid Hills", "value": "C130649"},
             {"label": "Dublin", "value": "C130650"},
             {"label": "Duluth", "value": "C130651"},
             {"label": "Dunwoody", "value": "C130652"},
             {"label": "Early County", "value": "C130653"},
             {"label": "East Dublin", "value": "C130654"},
             {"label": "East Griffin", "value": "C130655"},
             {"label": "East Newnan", "value": "C130656"},
             {"label": "East Point", "value": "C130657"},
             {"label": "Eastman", "value": "C130658"},
             {"label": "Eatonton", "value": "C130659"},
             {"label": "Echols County", "value": "C130660"},
             {"label": "Edison", "value": "C130661"},
             {"label": "Effingham County", "value": "C130662"},
             {"label": "Elbert County", "value": "C130663"},
             {"label": "Elberton", "value": "C130664"},
             {"label": "Ellaville", "value": "C130665"},
             {"label": "Ellijay", "value": "C130666"},
             {"label": "Emanuel County", "value": "C130667"},
             {"label": "Emerson", "value": "C130668"},
             {"label": "Enigma", "value": "C130669"},
             {"label": "Euharlee", "value": "C130670"},
             {"label": "Evans", "value": "C130671"},
             {"label": "Evans County", "value": "C130672"},
             {"label": "Experiment", "value": "C130673"},
             {"label": "Fair Oaks", "value": "C130674"},
             {"label": "Fairburn", "value": "C130675"},
             {"label": "Fairview", "value": "C130676"},
             {"label": "Fannin County", "value": "C130677"},
             {"label": "Fayette County", "value": "C130678"},
             {"label": "Fayetteville", "value": "C130679"},
             {"label": "Firing Range", "value": "C130680"},
             {"label": "Fitzgerald", "value": "C130681"},
             {"label": "Flowery Branch", "value": "C130682"},
             {"label": "Floyd County", "value": "C130683"},
             {"label": "Folkston", "value": "C130684"},
             {"label": "Forest Park", "value": "C130685"},
             {"label": "Forsyth", "value": "C130686"},
             {"label": "Forsyth County", "value": "C130687"},
             {"label": "Fort Gaines", "value": "C130688"},
             {"label": "Fort Oglethorpe", "value": "C130689"},
             {"label": "Fort Stewart", "value": "C130690"},
             {"label": "Fort Valley", "value": "C130691"},
             {"label": "Franklin", "value": "C130692"},
             {"label": "Franklin County", "value": "C130693"},
             {"label": "Franklin Springs", "value": "C130694"},
             {"label": "Fulton County", "value": "C130695"},
             {"label": "Gainesville", "value": "C130696"},
             {"label": "Garden City", "value": "C130697"},
             {"label": "Georgetown", "value": "C130698"},
             {"label": "Gibson", "value": "C130699"},
             {"label": "Gilmer County", "value": "C130700"},
             {"label": "Glascock County", "value": "C130701"},
             {"label": "Glennville", "value": "C130702"},
             {"label": "Glynn County", "value": "C130703"},
             {"label": "Gordon", "value": "C130704"},
             {"label": "Gordon County", "value": "C130705"},
             {"label": "Grady County", "value": "C130706"},
             {"label": "Grantville", "value": "C130707"},
             {"label": "Gray", "value": "C130708"},
             {"label": "Grayson", "value": "C130709"},
             {"label": "Greene County", "value": "C130710"},
             {"label": "Greensboro", "value": "C130711"},
             {"label": "Greenville", "value": "C130712"},
             {"label": "Gresham Park", "value": "C130713"},
             {"label": "Griffin", "value": "C130714"},
             {"label": "Grovetown", "value": "C130715"},
             {"label": "Gumlog", "value": "C130716"},
             {"label": "Guyton", "value": "C130717"},
             {"label": "Gwinnett County", "value": "C130718"},
             {"label": "Habersham County", "value": "C130719"},
             {"label": "Hahira", "value": "C130720"},
             {"label": "Hall County", "value": "C130721"},
             {"label": "Hamilton", "value": "C130722"},
             {"label": "Hampton", "value": "C130723"},
             {"label": "Hancock County", "value": "C130724"},
             {"label": "Hannahs Mill", "value": "C130725"},
             {"label": "Hapeville", "value": "C130726"},
             {"label": "Haralson County", "value": "C130727"},
             {"label": "Hardwick", "value": "C130728"},
             {"label": "Harlem", "value": "C130729"},
             {"label": "Harris County", "value": "C130730"},
             {"label": "Hart County", "value": "C130731"},
             {"label": "Hartwell", "value": "C130732"},
             {"label": "Hawkinsville", "value": "C130733"},
             {"label": "Hazlehurst", "value": "C130734"},
             {"label": "Heard County", "value": "C130735"},
             {"label": "Helena", "value": "C130736"},
             {"label": "Henderson", "value": "C130737"},
             {"label": "Henry County", "value": "C130738"},
             {"label": "Hephzibah", "value": "C130739"},
             {"label": "Hiawassee", "value": "C130740"},
             {"label": "Hinesville", "value": "C130741"},
             {"label": "Hiram", "value": "C130742"},
             {"label": "Hogansville", "value": "C130743"},
             {"label": "Holly Springs", "value": "C130744"},
             {"label": "Homer", "value": "C130745"},
             {"label": "Homerville", "value": "C130746"},
             {"label": "Hoschton", "value": "C130747"},
             {"label": "Houston County", "value": "C130748"},
             {"label": "Indian Springs", "value": "C130749"},
             {"label": "Irondale", "value": "C130750"},
             {"label": "Irwin County", "value": "C130751"},
             {"label": "Irwinton", "value": "C130752"},
             {"label": "Isle of Hope", "value": "C130753"},
             {"label": "Jackson", "value": "C130754"},
             {"label": "Jackson County", "value": "C130755"},
             {"label": "Jasper", "value": "C130756"},
             {"label": "Jasper County", "value": "C130757"},
             {"label": "Jeff Davis County", "value": "C130758"},
             {"label": "Jefferson", "value": "C130759"},
             {"label": "Jefferson County", "value": "C130760"},
             {"label": "Jeffersonville", "value": "C130761"},
             {"label": "Jenkins County", "value": "C130762"},
             {"label": "Jesup", "value": "C130763"},
             {"label": "Johns Creek", "value": "C130764"},
             {"label": "Johnson County", "value": "C130765"},
             {"label": "Jones County", "value": "C130766"},
             {"label": "Jonesboro", "value": "C130767"},
             {"label": "Kennesaw", "value": "C130768"},
             {"label": "Kings Bay Base", "value": "C130769"},
             {"label": "Kingsland", "value": "C130770"},
             {"label": "Knoxville", "value": "C130771"},
             {"label": "LaFayette", "value": "C130772"},
             {"label": "LaGrange", "value": "C130773"},
             {"label": "Lake City", "value": "C130774"},
             {"label": "Lakeland", "value": "C130775"},
             {"label": "Lakeview", "value": "C130776"},
             {"label": "Lakeview Estates", "value": "C130777"},
             {"label": "Lamar County", "value": "C130778"},
             {"label": "Lanier County", "value": "C130779"},
             {"label": "Laurens County", "value": "C130780"},
             {"label": "Lavonia", "value": "C130781"},
             {"label": "Lawrenceville", "value": "C130782"},
             {"label": "Lee County", "value": "C130783"},
             {"label": "Leesburg", "value": "C130784"},
             {"label": "Lexington", "value": "C130785"},
             {"label": "Liberty County", "value": "C130786"},
             {"label": "Lilburn", "value": "C130787"},
             {"label": "Lincoln County", "value": "C130788"},
             {"label": "Lincolnton", "value": "C130789"},
             {"label": "Lindale", "value": "C130790"},
             {"label": "Lithia Springs", "value": "C130791"},
             {"label": "Lithonia", "value": "C130792"},
             {"label": "Locust Grove", "value": "C130793"},
             {"label": "Loganville", "value": "C130794"},
             {"label": "Long County", "value": "C130795"},
             {"label": "Lookout Mountain", "value": "C130796"},
             {"label": "Louisville", "value": "C130797"},
             {"label": "Lovejoy", "value": "C130798"},
             {"label": "Lowndes County", "value": "C130799"},
             {"label": "Ludowici", "value": "C130800"},
             {"label": "Lula", "value": "C130801"},
             {"label": "Lumber City", "value": "C130802"},
             {"label": "Lumpkin", "value": "C130803"},
             {"label": "Lumpkin County", "value": "C130804"},
             {"label": "Lyons", "value": "C130805"},
             {"label": "Mableton", "value": "C130806"},
             {"label": "Macon", "value": "C130807"},
             {"label": "Macon County", "value": "C130808"},
             {"label": "Madison", "value": "C130809"},
             {"label": "Madison County", "value": "C130810"},
             {"label": "Manchester", "value": "C130811"},
             {"label": "Marietta", "value": "C130812"},
             {"label": "Marion County", "value": "C130813"},
             {"label": "Marshallville", "value": "C130814"},
             {"label": "Martinez", "value": "C130815"},
             {"label": "Maysville", "value": "C130816"},
             {"label": "McCaysville", "value": "C130817"},
             {"label": "McDonough", "value": "C130818"},
             {"label": "McDuffie County", "value": "C130819"},
             {"label": "McIntosh County", "value": "C130820"},
             {"label": "McRae", "value": "C130821"},
             {"label": "Meigs", "value": "C130822"},
             {"label": "Meriwether County", "value": "C130823"},
             {"label": "Metter", "value": "C130824"},
             {"label": "Midway", "value": "C130825"},
             {"label": "Milledgeville", "value": "C130826"},
             {"label": "Millen", "value": "C130827"},
             {"label": "Miller County", "value": "C130828"},
             {"label": "Milton", "value": "C130829"},
             {"label": "Mitchell County", "value": "C130830"},
             {"label": "Monroe", "value": "C130831"},
             {"label": "Monroe County", "value": "C130832"},
             {"label": "Montezuma", "value": "C130833"},
             {"label": "Montgomery", "value": "C130834"},
             {"label": "Montgomery County", "value": "C130835"},
             {"label": "Monticello", "value": "C130836"},
             {"label": "Morgan", "value": "C130837"},
             {"label": "Morgan County", "value": "C130838"},
             {"label": "Morrow", "value": "C130839"},
             {"label": "Moultrie", "value": "C130840"},
             {"label": "Mount Airy", "value": "C130841"},
             {"label": "Mount Vernon", "value": "C130842"},
             {"label": "Mount Zion", "value": "C130843"},
             {"label": "Mountain City", "value": "C130844"},
             {"label": "Mountain Park", "value": "C130845"},
             {"label": "Murray County", "value": "C130846"},
             {"label": "Muscogee County", "value": "C130847"},
             {"label": "Nahunta", "value": "C130848"},
             {"label": "Nashville", "value": "C130849"},
             {"label": "Nelson", "value": "C130850"},
             {"label": "Newnan", "value": "C130851"},
             {"label": "Newton", "value": "C130852"},
             {"label": "Newton County", "value": "C130853"},
             {"label": "Nicholls", "value": "C130854"},
             {"label": "Nicholson", "value": "C130855"},
             {"label": "Norcross", "value": "C130856"},
             {"label": "North Atlanta", "value": "C130857"},
             {"label": "North Decatur", "value": "C130858"},
             {"label": "North Druid Hills", "value": "C130859"},
             {"label": "Oakwood", "value": "C130860"},
             {"label": "Ocilla", "value": "C130861"},
             {"label": "Oconee County", "value": "C130862"},
             {"label": "Oglethorpe", "value": "C130863"},
             {"label": "Oglethorpe County", "value": "C130864"},
             {"label": "Omega", "value": "C130865"},
             {"label": "Oxford", "value": "C130866"},
             {"label": "Palmetto", "value": "C130867"},
             {"label": "Panthersville", "value": "C130868"},
             {"label": "Paulding County", "value": "C130869"},
             {"label": "Peach County", "value": "C130870"},
             {"label": "Peachtree City", "value": "C130871"},
             {"label": "Peachtree Corners", "value": "C130872"},
             {"label": "Pearson", "value": "C130873"},
             {"label": "Pelham", "value": "C130874"},
             {"label": "Pembroke", "value": "C130875"},
             {"label": "Perry", "value": "C130876"},
             {"label": "Pickens County", "value": "C130877"},
             {"label": "Pierce County", "value": "C130878"},
             {"label": "Pike County", "value": "C130879"},
             {"label": "Pine Mountain", "value": "C130880"},
             {"label": "Polk County", "value": "C130881"},
             {"label": "Pooler", "value": "C130882"},
             {"label": "Port Wentworth", "value": "C130883"},
             {"label": "Porterdale", "value": "C130884"},
             {"label": "Powder Springs", "value": "C130885"},
             {"label": "Preston", "value": "C130886"},
             {"label": "Pulaski County", "value": "C130887"},
             {"label": "Putnam County", "value": "C130888"},
             {"label": "Putney", "value": "C130889"},
             {"label": "Quitman", "value": "C130890"},
             {"label": "Quitman County", "value": "C130891"},
             {"label": "Rabun County", "value": "C130892"},
             {"label": "Randolph County", "value": "C130893"},
             {"label": "Raoul", "value": "C130894"},
             {"label": "Ray City", "value": "C130895"},
             {"label": "Redan", "value": "C130896"},
             {"label": "Reed Creek", "value": "C130897"},
             {"label": "Reidsville", "value": "C130898"},
             {"label": "Remerton", "value": "C130899"},
             {"label": "Reynolds", "value": "C130900"},
             {"label": "Richland", "value": "C130901"},
             {"label": "Richmond County", "value": "C130902"},
             {"label": "Richmond Hill", "value": "C130903"},
             {"label": "Rincon", "value": "C130904"},
             {"label": "Ringgold", "value": "C130905"},
             {"label": "Riverdale", "value": "C130906"},
             {"label": "Robins Air Force Base", "value": "C130907"},
             {"label": "Rochelle", "value": "C130908"},
             {"label": "Rockdale County", "value": "C130909"},
             {"label": "Rockmart", "value": "C130910"},
             {"label": "Rome", "value": "C130911"},
             {"label": "Rossville", "value": "C130912"},
             {"label": "Roswell", "value": "C130913"},
             {"label": "Royston", "value": "C130914"},
             {"label": "Russell", "value": "C130915"},
             {"label": "Rydal", "value": "C130916"},
             {"label": "Saint Simon Mills", "value": "C130917"},
             {"label": "Saint Simons Island", "value": "C130918"},
             {"label": "Sandersville", "value": "C130919"},
             {"label": "Sandy Springs", "value": "C130920"},
             {"label": "Sardis", "value": "C130921"},
             {"label": "Savannah", "value": "C130922"},
             {"label": "Schley County", "value": "C130923"},
             {"label": "Scottdale", "value": "C130924"},
             {"label": "Screven County", "value": "C130925"},
             {"label": "Seminole County", "value": "C130926"},
             {"label": "Senoia", "value": "C130927"},
             {"label": "Shannon", "value": "C130928"},
             {"label": "Skidaway Island", "value": "C130929"},
             {"label": "Smyrna", "value": "C130930"},
             {"label": "Snellville", "value": "C130931"},
             {"label": "Social Circle", "value": "C130932"},
             {"label": "Soperton", "value": "C130933"},
             {"label": "Spalding County", "value": "C130934"},
             {"label": "Sparks", "value": "C130935"},
             {"label": "Sparta", "value": "C130936"},
             {"label": "Springfield", "value": "C130937"},
             {"label": "St. Marys", "value": "C130938"},
             {"label": "Statenville", "value": "C130939"},
             {"label": "Statesboro", "value": "C130940"},
             {"label": "Statham", "value": "C130941"},
             {"label": "Stephens County", "value": "C130942"},
             {"label": "Stewart County", "value": "C130943"},
             {"label": "Stockbridge", "value": "C130944"},
             {"label": "Stone Mountain", "value": "C130945"},
             {"label": "Stonecrest", "value": "C130946"},
             {"label": "Sugar Hill", "value": "C130947"},
             {"label": "Summerville", "value": "C130948"},
             {"label": "Sumter County", "value": "C130949"},
             {"label": "Sunnyside", "value": "C130950"},
             {"label": "Suwanee", "value": "C130951"},
             {"label": "Swainsboro", "value": "C130952"},
             {"label": "Sylvania", "value": "C130953"},
             {"label": "Sylvester", "value": "C130954"},
             {"label": "Talbot County", "value": "C130955"},
             {"label": "Talbotton", "value": "C130956"},
             {"label": "Taliaferro County", "value": "C130957"},
             {"label": "Tallapoosa", "value": "C130958"},
             {"label": "Tattnall County", "value": "C130959"},
             {"label": "Taylor County", "value": "C130960"},
             {"label": "Telfair County", "value": "C130961"},
             {"label": "Temple", "value": "C130962"},
             {"label": "Tennille", "value": "C130963"},
             {"label": "Terrell County", "value": "C130964"},
             {"label": "Thomas County", "value": "C130965"},
             {"label": "Thomaston", "value": "C130966"},
             {"label": "Thomasville", "value": "C130967"},
             {"label": "Thomson", "value": "C130968"},
             {"label": "Thunderbolt", "value": "C130969"},
             {"label": "Tift County", "value": "C130970"},
             {"label": "Tifton", "value": "C130971"},
             {"label": "Toccoa", "value": "C130972"},
             {"label": "Toombs County", "value": "C130973"},
             {"label": "Towns County", "value": "C130974"},
             {"label": "Trenton", "value": "C130975"},
             {"label": "Treutlen County", "value": "C130976"},
             {"label": "Trion", "value": "C130977"},
             {"label": "Troup County", "value": "C130978"},
             {"label": "Tucker", "value": "C130979"},
             {"label": "Turner County", "value": "C130980"},
             {"label": "Twiggs County", "value": "C130981"},
             {"label": "Twin City", "value": "C130982"},
             {"label": "Tybee Island", "value": "C130983"},
             {"label": "Tyrone", "value": "C130984"},
             {"label": "Unadilla", "value": "C130985"},
             {"label": "Union City", "value": "C130986"},
             {"label": "Union County", "value": "C130987"},
             {"label": "Union Point", "value": "C130988"},
             {"label": "Unionville", "value": "C130989"},
             {"label": "Upson County", "value": "C130990"},
             {"label": "Valdosta", "value": "C130991"},
             {"label": "Varnell", "value": "C130992"},
             {"label": "Vidalia", "value": "C130993"},
             {"label": "Vienna", "value": "C130994"},
             {"label": "Villa Rica", "value": "C130995"},
             {"label": "Vinings", "value": "C130996"},
             {"label": "Wadley", "value": "C130997"},
             {"label": "Walker County", "value": "C130998"},
             {"label": "Walnut Grove", "value": "C130999"},
             {"label": "Walthourville", "value": "C131000"},
             {"label": "Walton County", "value": "C131001"},
             {"label": "Ware County", "value": "C131002"},
             {"label": "Warner Robins", "value": "C131003"},
             {"label": "Warren County", "value": "C131004"},
             {"label": "Warrenton", "value": "C131005"},
             {"label": "Washington", "value": "C131006"},
             {"label": "Washington County", "value": "C131007"},
             {"label": "Watkinsville", "value": "C131008"},
             {"label": "Waycross", "value": "C131009"},
             {"label": "Wayne County", "value": "C131010"},
             {"label": "Waynesboro", "value": "C131011"},
             {"label": "Webster County", "value": "C131012"},
             {"label": "West Point", "value": "C131013"},
             {"label": "West Warrenton", "value": "C131014"},
             {"label": "Wheeler County", "value": "C131015"},
             {"label": "White County", "value": "C131016"},
             {"label": "Whitemarsh Island", "value": "C131017"},
             {"label": "Whitfield County", "value": "C131018"},
             {"label": "Wilcox County", "value": "C131019"},
             {"label": "Wilkes County", "value": "C131020"},
             {"label": "Wilkinson County", "value": "C131021"},
             {"label": "Willacoochee", "value": "C131022"},
             {"label": "Wilmington Island", "value": "C131023"},
             {"label": "Winder", "value": "C131024"},
             {"label": "Winterville", "value": "C131025"},
             {"label": "Woodbine", "value": "C131026"},
             {"label": "Woodstock", "value": "C131027"},
             {"label": "Worth County", "value": "C131028"},
             {"label": "Wrens", "value": "C131029"},
             {"label": "Wrightsville", "value": "C131030"},
             {"label": "Young Harris", "value": "C131031"},
             {"label": "Zebulon", "value": "C131032"}],
 "Gevgelija Municipality": [{"label": "Gevgelija", "value": "C65402"},
                            {"label": "Miravci", "value": "C65403"}],
 "Ghajnsielem": [{"label": "Ghajnsielem", "value": "C65879"},
                 {"label": "Hal Gharghur", "value": "C65880"}],
 "Ghanzi District": [{"label": "Dekar", "value": "C10154"},
                     {"label": "Ghanzi", "value": "C10155"}],
 "Gharbia": [{"label": "Al Mahallah al Kubra", "value": "C24270"},
             {"label": "Basyun", "value": "C24271"},
             {"label": "Kafr az Zayyat", "value": "C24272"},
             {"label": "Qutur", "value": "C24273"},
             {"label": "Samannud", "value": "C24274"},
             {"label": "Tanda", "value": "C24275"},
             {"label": "Zefta", "value": "C24276"}],
 "Ghardaia": [{"label": "Berriane", "value": "C342"},
              {"label": "Ghardaia", "value": "C343"},
              {"label": "Metlili Chaamba", "value": "C344"}],
 "Gharghur": [{"label": "Ghaxaq", "value": "C65881"}],
 "Ghasri": [{"label": "Gudja", "value": "C65882"}],
 "Ghat District": [{"label": "Ghat", "value": "C65030"}],
 "Ghaxaq": [{"label": "Gzira", "value": "C65883"}],
 "Ghazni": [{"label": "Ghazni", "value": "C25"}],
 "Ghor": [{"label": "Fayroz Koh", "value": "C26"},
          {"label": "Shahrak", "value": "C27"}],
 "Gia Lai": [{"label": "Huyen Chu Pah", "value": "C147379"},
             {"label": "Huyen Chu Prong", "value": "C147380"},
             {"label": "Huyen Chu Se", "value": "C147381"},
             {"label": "Huyen Duc Co", "value": "C147382"},
             {"label": "Huyen Ia Grai", "value": "C147383"},
             {"label": "Huyen KBang", "value": "C147384"},
             {"label": "Huyen Kong Chro", "value": "C147385"},
             {"label": "Huyen Krong Pa", "value": "C147386"},
             {"label": "Huyen Mang Yang", "value": "C147387"},
             {"label": "Pleiku", "value": "C147388"}],
 "Gifu Prefecture": [{"label": "Ena-shi", "value": "C62832"},
                     {"label": "Gero", "value": "C62833"},
                     {"label": "Gero-shi", "value": "C62834"},
                     {"label": "Gifu-shi", "value": "C62835"},
                     {"label": "Godo", "value": "C62836"},
                     {"label": "Gujo", "value": "C62837"},
                     {"label": "Gujo-shi", "value": "C62838"},
                     {"label": "Hashima", "value": "C62839"},
                     {"label": "Hashima-shi", "value": "C62840"},
                     {"label": "Hida", "value": "C62841"},
                     {"label": "Hida Shi", "value": "C62842"},
                     {"label": "Kaizu-shi", "value": "C62843"},
                     {"label": "Kakamigahara-shi", "value": "C62844"},
                     {"label": "Kani-shi", "value": "C62845"},
                     {"label": "Kasamatsucho", "value": "C62846"},
                     {"label": "Mino", "value": "C62847"},
                     {"label": "Mino-shi", "value": "C62848"},
                     {"label": "Minokamo", "value": "C62849"},
                     {"label": "Minokamo-shi", "value": "C62850"},
                     {"label": "Mitake", "value": "C62851"},
                     {"label": "Mizuho-shi", "value": "C62852"},
                     {"label": "Mizunami", "value": "C62853"},
                     {"label": "Mizunami-shi", "value": "C62854"},
                     {"label": "Motosu-shi", "value": "C62855"},
                     {"label": "Nakatsugawa", "value": "C62856"},
                     {"label": "Nakatsugawa-shi", "value": "C62857"},
                     {"label": "Ogaki-shi", "value": "C62858"},
                     {"label": "Seki-shi", "value": "C62859"},
                     {"label": "Tajimi", "value": "C62860"},
                     {"label": "Tajimi-shi", "value": "C62861"},
                     {"label": "Takayama Shi", "value": "C62862"},
                     {"label": "Tarui", "value": "C62863"},
                     {"label": "Toki", "value": "C62864"},
                     {"label": "Toki-shi", "value": "C62865"},
                     {"label": "Yamagata-shi", "value": "C62866"}],
 "Gilan": [{"label": "astaneh-ye Ashrafiyeh", "value": "C50153"},
           {"label": "astara", "value": "C50154"},
           {"label": "Bandar-e Anzali", "value": "C50155"},
           {"label": "Fuman", "value": "C50156"},
           {"label": "Hashtpar", "value": "C50157"},
           {"label": "Langarud", "value": "C50158"},
           {"label": "Padegan-e Manjil", "value": "C50159"},
           {"label": "Rasht", "value": "C50160"},
           {"label": "Rezvanshahr", "value": "C50161"},
           {"label": "Rudsar", "value": "C50162"},
           {"label": "Shahrestan-e astaneh-ye Ashrafiyeh", "value": "C50163"},
           {"label": "Shahrestan-e astara", "value": "C50164"},
           {"label": "Shahrestan-e Bandar-e Anzali", "value": "C50165"},
           {"label": "Shahrestan-e Fuman", "value": "C50166"},
           {"label": "Shahrestan-e Lahijan", "value": "C50167"},
           {"label": "Shahrestan-e Langarud", "value": "C50168"},
           {"label": "Shahrestan-e Masal", "value": "C50169"},
           {"label": "Shahrestan-e Rezvanshahr", "value": "C50170"},
           {"label": "Shahrestan-e Rudbar", "value": "C50171"},
           {"label": "Shahrestan-e Rudsar", "value": "C50172"},
           {"label": "Shahrestan-e Siahkal", "value": "C50173"},
           {"label": "Shahrestan-e Sowmeeh Sara", "value": "C50174"},
           {"label": "Shahrestan-e Talesh", "value": "C50175"},
           {"label": "Ziabar (Gaskar)", "value": "C50176"}],
 "Gilbert Islands": [{"label": "Abaiang", "value": "C64647"},
                     {"label": "Abemama", "value": "C64648"},
                     {"label": "Ambo Village", "value": "C64649"},
                     {"label": "Aranuka", "value": "C64650"},
                     {"label": "Arorae", "value": "C64651"},
                     {"label": "Bairiki Village", "value": "C64652"},
                     {"label": "Banaba", "value": "C64653"},
                     {"label": "Banraeaba Village", "value": "C64654"},
                     {"label": "Beru", "value": "C64655"},
                     {"label": "Betio Village", "value": "C64656"},
                     {"label": "Bikenibeu Village", "value": "C64657"},
                     {"label": "Bonriki Village", "value": "C64658"},
                     {"label": "Buota Village", "value": "C64659"},
                     {"label": "Butaritari", "value": "C64660"},
                     {"label": "Eita Village", "value": "C64661"},
                     {"label": "Kuria", "value": "C64662"},
                     {"label": "Maiana", "value": "C64663"},
                     {"label": "Makin", "value": "C64664"},
                     {"label": "Makin Village", "value": "C64665"},
                     {"label": "Marakei", "value": "C64666"},
                     {"label": "Nawerewere Village", "value": "C64667"},
                     {"label": "Nikunau", "value": "C64668"},
                     {"label": "Nonouti", "value": "C64669"},
                     {"label": "Onotoa", "value": "C64670"},
                     {"label": "Rawannawi Village", "value": "C64671"},
                     {"label": "Tabiteuea", "value": "C64672"},
                     {"label": "Tamana", "value": "C64673"},
                     {"label": "Tarawa", "value": "C64674"},
                     {"label": "Teaoraereke Village", "value": "C64675"},
                     {"label": "Temaiku Village", "value": "C64676"}],
 "Gilgit-Baltistan": [{"label": "Barishal", "value": "C79384"},
                      {"label": "Gilgit", "value": "C79385"},
                      {"label": "Skardu", "value": "C79386"}],
 "Giresun": [{"label": "Alucra Ilcesi", "value": "C120332"},
             {"label": "Bulancak", "value": "C120333"},
             {"label": "Camoluk Ilcesi", "value": "C120334"},
             {"label": "Canakci", "value": "C120335"},
             {"label": "Dereli", "value": "C120336"},
             {"label": "Dogankent", "value": "C120337"},
             {"label": "Espiye", "value": "C120338"},
             {"label": "Eynesil", "value": "C120339"},
             {"label": "Giresun", "value": "C120340"},
             {"label": "Gorele", "value": "C120341"},
             {"label": "Guce Ilcesi", "value": "C120342"},
             {"label": "Kesap", "value": "C120343"},
             {"label": "Merkez", "value": "C120344"},
             {"label": "Piraziz", "value": "C120345"},
             {"label": "Sebin Karahisar", "value": "C120346"},
             {"label": "Tirebolu", "value": "C120347"},
             {"label": "Yaglidere Ilcesi", "value": "C120348"}],
 "Gisborne District": [{"label": "Gisborne", "value": "C77785"}],
 "Gitega Province": [{"label": "Gitega", "value": "C16447"}],
 "Giurgiu County": [{"label": "Adunatii-Copaceni", "value": "C95433"},
                    {"label": "Bacu", "value": "C95434"},
                    {"label": "Baneasa", "value": "C95435"},
                    {"label": "Bolintin Deal", "value": "C95436"},
                    {"label": "Bolintin Vale", "value": "C95437"},
                    {"label": "Branistari", "value": "C95438"},
                    {"label": "Branistea", "value": "C95439"},
                    {"label": "Bucsani", "value": "C95440"},
                    {"label": "Bulbucata", "value": "C95441"},
                    {"label": "Buturugeni", "value": "C95442"},
                    {"label": "Calugareni", "value": "C95443"},
                    {"label": "Campurelu", "value": "C95444"},
                    {"label": "Cartojani", "value": "C95445"},
                    {"label": "Cascioarele", "value": "C95446"},
                    {"label": "Cetatea", "value": "C95447"},
                    {"label": "Chiriacu", "value": "C95448"},
                    {"label": "Clejani", "value": "C95449"},
                    {"label": "Colibasi", "value": "C95450"},
                    {"label": "Comana", "value": "C95451"},
                    {"label": "Comuna Adunatii-Copaceni", "value": "C95452"},
                    {"label": "Comuna Baneasa", "value": "C95453"},
                    {"label": "Comuna Bolintin Deal", "value": "C95454"},
                    {"label": "Comuna Bucsani", "value": "C95455"},
                    {"label": "Comuna Bulbucata", "value": "C95456"},
                    {"label": "Comuna Buturugeni", "value": "C95457"},
                    {"label": "Comuna Calugareni", "value": "C95458"},
                    {"label": "Comuna Clejani", "value": "C95459"},
                    {"label": "Comuna Colibasi", "value": "C95460"},
                    {"label": "Comuna Comana", "value": "C95461"},
                    {"label": "Comuna Cosoba", "value": "C95462"},
                    {"label": "Comuna Crevedia Mare", "value": "C95463"},
                    {"label": "Comuna Daia", "value": "C95464"},
                    {"label": "Comuna Floresti-Stoenesti", "value": "C95465"},
                    {"label": "Comuna Fratesti", "value": "C95466"},
                    {"label": "Comuna Gaiseni", "value": "C95467"},
                    {"label": "Comuna Gaujani", "value": "C95468"},
                    {"label": "Comuna Ghimpati", "value": "C95469"},
                    {"label": "Comuna Gogosari", "value": "C95470"},
                    {"label": "Comuna Gostinari", "value": "C95471"},
                    {"label": "Comuna Gostinu", "value": "C95472"},
                    {"label": "Comuna Gradinari", "value": "C95473"},
                    {"label": "Comuna Greaca", "value": "C95474"},
                    {"label": "Comuna Herasti", "value": "C95475"},
                    {"label": "Comuna Hotarele", "value": "C95476"},
                    {"label": "Comuna Iepuresti", "value": "C95477"},
                    {"label": "Comuna Isvoarele", "value": "C95478"},
                    {"label": "Comuna Izvoarele", "value": "C95479"},
                    {"label": "Comuna Joita", "value": "C95480"},
                    {"label": "Comuna Letca Noua", "value": "C95481"},
                    {"label": "Comuna Malu", "value": "C95482"},
                    {"label": "Comuna Marsa", "value": "C95483"},
                    {"label": "Comuna Mihai Bravu", "value": "C95484"},
                    {"label": "Comuna Ogrezeni", "value": "C95485"},
                    {"label": "Comuna Oinacu", "value": "C95486"},
                    {"label": "Comuna Prundu", "value": "C95487"},
                    {"label": "Comuna Putineiu", "value": "C95488"},
                    {"label": "Comuna Rasuceni", "value": "C95489"},
                    {"label": "Comuna Roata De Jos", "value": "C95490"},
                    {"label": "Comuna Sabareni", "value": "C95491"},
                    {"label": "Comuna Schitu", "value": "C95492"},
                    {"label": "Comuna Singureni", "value": "C95493"},
                    {"label": "Comuna Slobozia", "value": "C95494"},
                    {"label": "Comuna Stanesti", "value": "C95495"},
                    {"label": "Comuna Stoenesti", "value": "C95496"},
                    {"label": "Comuna Toporu", "value": "C95497"},
                    {"label": "Comuna Ulmi", "value": "C95498"},
                    {"label": "Comuna Valea Dragului", "value": "C95499"},
                    {"label": "Comuna Vanatorii Mici", "value": "C95500"},
                    {"label": "Comuna Varasti", "value": "C95501"},
                    {"label": "Comuna Vedea", "value": "C95502"},
                    {"label": "Copaciu", "value": "C95503"},
                    {"label": "Cosoba", "value": "C95504"},
                    {"label": "Crevedia Mare", "value": "C95505"},
                    {"label": "Cucuruzu", "value": "C95506"},
                    {"label": "Daia", "value": "C95507"},
                    {"label": "Darasti-Vlasca", "value": "C95508"},
                    {"label": "Dealu", "value": "C95509"},
                    {"label": "Dobreni", "value": "C95510"},
                    {"label": "Falastoaca", "value": "C95511"},
                    {"label": "Floresti", "value": "C95512"},
                    {"label": "Fratesti", "value": "C95513"},
                    {"label": "Gaiseni", "value": "C95514"},
                    {"label": "Gaujani", "value": "C95515"},
                    {"label": "Ghimpati", "value": "C95516"},
                    {"label": "Giurgiu", "value": "C95517"},
                    {"label": "Gogosari", "value": "C95518"},
                    {"label": "Gostinari", "value": "C95519"},
                    {"label": "Gostinari-Vacaresti", "value": "C95520"},
                    {"label": "Gostinu", "value": "C95521"},
                    {"label": "Gradinari", "value": "C95522"},
                    {"label": "Gradistea", "value": "C95523"},
                    {"label": "Greaca", "value": "C95524"},
                    {"label": "Herasti", "value": "C95525"},
                    {"label": "Hotarele", "value": "C95526"},
                    {"label": "Hulubesti", "value": "C95527"},
                    {"label": "Icoana", "value": "C95528"},
                    {"label": "Iepuresti", "value": "C95529"},
                    {"label": "Izvoarele", "value": "C95530"},
                    {"label": "Joita", "value": "C95531"},
                    {"label": "Letca Noua", "value": "C95532"},
                    {"label": "Letca Veche", "value": "C95533"},
                    {"label": "Malu", "value": "C95534"},
                    {"label": "Malu Spart", "value": "C95535"},
                    {"label": "Marsa", "value": "C95536"},
                    {"label": "Mihai Bravu", "value": "C95537"},
                    {"label": "Mihai Voda", "value": "C95538"},
                    {"label": "Mihailesti", "value": "C95539"},
                    {"label": "Milcovatu", "value": "C95540"},
                    {"label": "Municipiul Giurgiu", "value": "C95541"},
                    {"label": "Naipu", "value": "C95542"},
                    {"label": "Novaci", "value": "C95543"},
                    {"label": "Ogrezeni", "value": "C95544"},
                    {"label": "Oinacu", "value": "C95545"},
                    {"label": "Oras Bolintin-Vale", "value": "C95546"},
                    {"label": "Oras Mihailesti", "value": "C95547"},
                    {"label": "Padureni", "value": "C95548"},
                    {"label": "Palanca", "value": "C95549"},
                    {"label": "Pietrele", "value": "C95550"},
                    {"label": "Pietrisu", "value": "C95551"},
                    {"label": "Plopsoru", "value": "C95552"},
                    {"label": "Popesti", "value": "C95553"},
                    {"label": "Prundu", "value": "C95554"},
                    {"label": "Puieni", "value": "C95555"},
                    {"label": "Putineiu", "value": "C95556"},
                    {"label": "Rasuceni", "value": "C95557"},
                    {"label": "Remus", "value": "C95558"},
                    {"label": "Roata de Jos", "value": "C95559"},
                    {"label": "Sabareni", "value": "C95560"},
                    {"label": "Sadina", "value": "C95561"},
                    {"label": "Schitu", "value": "C95562"},
                    {"label": "Singureni", "value": "C95563"},
                    {"label": "Slobozia", "value": "C95564"},
                    {"label": "Stanesti", "value": "C95565"},
                    {"label": "Stoenesti", "value": "C95566"},
                    {"label": "Tantava", "value": "C95567"},
                    {"label": "Toporu", "value": "C95568"},
                    {"label": "Trestieni", "value": "C95569"},
                    {"label": "Ulmi", "value": "C95570"},
                    {"label": "Uzunu", "value": "C95571"},
                    {"label": "Valea Dragului", "value": "C95572"},
                    {"label": "Vanatorii Mari", "value": "C95573"},
                    {"label": "Vanatorii Mici", "value": "C95574"},
                    {"label": "Varasti", "value": "C95575"},
                    {"label": "Vedea", "value": "C95576"},
                    {"label": "Vieru", "value": "C95577"},
                    {"label": "Vlad Tepes", "value": "C95578"},
                    {"label": "Zorile", "value": "C95579"}],
 "Giza": [{"label": "Al Ayyat", "value": "C24277"},
          {"label": "Al Bawiti", "value": "C24278"},
          {"label": "Al Hawamidiyah", "value": "C24279"},
          {"label": "As Saff", "value": "C24280"},
          {"label": "Awsim", "value": "C24281"},
          {"label": "Giza", "value": "C24282"},
          {"label": "Madinat Sittah Uktubar", "value": "C24283"}],
 "Gjirokaster District": [{"label": "Bashkia Dropull", "value": "C140"},
                          {"label": "Bashkia Kelcyre", "value": "C141"},
                          {"label": "Bashkia Libohove", "value": "C142"},
                          {"label": "Bashkia Memaliaj", "value": "C143"},
                          {"label": "Bashkia Permet", "value": "C144"},
                          {"label": "Bashkia Tepelene", "value": "C145"},
                          {"label": "Gjinkar", "value": "C146"},
                          {"label": "Gjirokaster", "value": "C147"},
                          {"label": "Kelcyre", "value": "C148"},
                          {"label": "Lazarat", "value": "C149"},
                          {"label": "Libohove", "value": "C150"},
                          {"label": "Memaliaj", "value": "C151"},
                          {"label": "Permet", "value": "C152"},
                          {"label": "Tepelene", "value": "C153"}],
 "Gjorce Petrov Municipality": [{"label": "Gjorce Petro", "value": "C65404"}],
 "Glarus": [{"label": "Bilten", "value": "C116742"},
            {"label": "Ennenda", "value": "C116743"},
            {"label": "Glarus", "value": "C116744"},
            {"label": "Linthal", "value": "C116745"},
            {"label": "Luchsingen", "value": "C116746"},
            {"label": "Mollis", "value": "C116747"},
            {"label": "Nafels", "value": "C116748"},
            {"label": "Netstal", "value": "C116749"},
            {"label": "Niederurnen", "value": "C116750"},
            {"label": "Oberurnen", "value": "C116751"},
            {"label": "Schwanden", "value": "C116752"}],
 "Glodeni District": [{"label": "Glodeni", "value": "C75521"}],
 "Gnaviyani Atoll": [{"label": "Fuvahmulah", "value": "C65806"}],
 "Goa": [{"label": "Aldona", "value": "C45418"},
         {"label": "Arambol", "value": "C45419"},
         {"label": "Baga", "value": "C45420"},
         {"label": "Bambolim", "value": "C45421"},
         {"label": "Bandora", "value": "C45422"},
         {"label": "Benaulim", "value": "C45423"},
         {"label": "Calangute", "value": "C45424"},
         {"label": "Candolim", "value": "C45425"},
         {"label": "Carapur", "value": "C45426"},
         {"label": "Cavelossim", "value": "C45427"},
         {"label": "Chicalim", "value": "C45428"},
         {"label": "Chinchinim", "value": "C45429"},
         {"label": "Colovale", "value": "C45430"},
         {"label": "Colva", "value": "C45431"},
         {"label": "Cortalim", "value": "C45432"},
         {"label": "Cuncolim", "value": "C45433"},
         {"label": "Curchorem", "value": "C45434"},
         {"label": "Curti", "value": "C45435"},
         {"label": "Davorlim", "value": "C45436"},
         {"label": "Dicholi", "value": "C45437"},
         {"label": "Goa Velha", "value": "C45438"},
         {"label": "Guirim", "value": "C45439"},
         {"label": "Jua", "value": "C45440"},
         {"label": "Kankon", "value": "C45441"},
         {"label": "Madgaon", "value": "C45442"},
         {"label": "Mapuca", "value": "C45443"},
         {"label": "Morjim", "value": "C45444"},
         {"label": "Mormugao", "value": "C45445"},
         {"label": "Navelim", "value": "C45446"},
         {"label": "North Goa", "value": "C45447"},
         {"label": "Palle", "value": "C45448"},
         {"label": "Panaji", "value": "C45449"},
         {"label": "Pernem", "value": "C45450"},
         {"label": "Ponda", "value": "C45451"},
         {"label": "Quepem", "value": "C45452"},
         {"label": "Queula", "value": "C45453"},
         {"label": "Raia", "value": "C45454"},
         {"label": "Saligao", "value": "C45455"},
         {"label": "Sancoale", "value": "C45456"},
         {"label": "Sanguem", "value": "C45457"},
         {"label": "Sanquelim", "value": "C45458"},
         {"label": "Sanvordem", "value": "C45459"},
         {"label": "Serula", "value": "C45460"},
         {"label": "Solim", "value": "C45461"},
         {"label": "South Goa", "value": "C45462"},
         {"label": "Taleigao", "value": "C45463"},
         {"label": "Vagator", "value": "C45464"},
         {"label": "Valpoy", "value": "C45465"},
         {"label": "Varca", "value": "C45466"},
         {"label": "Vasco da Gama", "value": "C45467"}],
 "Gobustan District": [{"label": "Qobustan", "value": "C8433"}],
 "Goh-Djiboua District": [{"label": "Divo", "value": "C20953"},
                          {"label": "Gagnoa", "value": "C20954"},
                          {"label": "Goh", "value": "C20955"},
                          {"label": "Guiberoua", "value": "C20956"},
                          {"label": "Lakota", "value": "C20957"},
                          {"label": "Loh-Djiboua", "value": "C20958"},
                          {"label": "Oume", "value": "C20959"}],
 "Goias": [{"label": "Abadia de Goias", "value": "C11088"},
           {"label": "Abadiania", "value": "C11089"},
           {"label": "Acreuna", "value": "C11090"},
           {"label": "Adelandia", "value": "C11091"},
           {"label": "agua Fria de Goias", "value": "C11092"},
           {"label": "agua Limpa", "value": "C11093"},
           {"label": "aguas Lindas de Goias", "value": "C11094"},
           {"label": "Alexania", "value": "C11095"},
           {"label": "Aloandia", "value": "C11096"},
           {"label": "Alto Horizonte", "value": "C11097"},
           {"label": "Alto Paraiso de Goias", "value": "C11098"},
           {"label": "Alvorada do Norte", "value": "C11099"},
           {"label": "Amaralina", "value": "C11100"},
           {"label": "Americano do Brasil", "value": "C11101"},
           {"label": "Amorinopolis", "value": "C11102"},
           {"label": "Anapolis", "value": "C11103"},
           {"label": "Anhanguera", "value": "C11104"},
           {"label": "Anicuns", "value": "C11105"},
           {"label": "Aparecida de Goiania", "value": "C11106"},
           {"label": "Aparecida do Rio Doce", "value": "C11107"},
           {"label": "Apore", "value": "C11108"},
           {"label": "Aracu", "value": "C11109"},
           {"label": "Aragarcas", "value": "C11110"},
           {"label": "Aragoiania", "value": "C11111"},
           {"label": "Araguapaz", "value": "C11112"},
           {"label": "Arenopolis", "value": "C11113"},
           {"label": "Aruana", "value": "C11114"},
           {"label": "Aurilandia", "value": "C11115"},
           {"label": "Avelinopolis", "value": "C11116"},
           {"label": "Baliza", "value": "C11117"},
           {"label": "Barro Alto", "value": "C11118"},
           {"label": "Bela Vista de Goias", "value": "C11119"},
           {"label": "Bom Jardim de Goias", "value": "C11120"},
           {"label": "Bom Jesus de Goias", "value": "C11121"},
           {"label": "Bonfinopolis", "value": "C11122"},
           {"label": "Bonopolis", "value": "C11123"},
           {"label": "Brazabrantes", "value": "C11124"},
           {"label": "Britania", "value": "C11125"},
           {"label": "Buriti Alegre", "value": "C11126"},
           {"label": "Buriti de Goias", "value": "C11127"},
           {"label": "Buritinopolis", "value": "C11128"},
           {"label": "Cabeceiras", "value": "C11129"},
           {"label": "Cachoeira Alta", "value": "C11130"},
           {"label": "Cachoeira de Goias", "value": "C11131"},
           {"label": "Cachoeira Dourada", "value": "C11132"},
           {"label": "Cacu", "value": "C11133"},
           {"label": "Caiaponia", "value": "C11134"},
           {"label": "Caldas Novas", "value": "C11135"},
           {"label": "Caldazinha", "value": "C11136"},
           {"label": "Campestre de Goias", "value": "C11137"},
           {"label": "Campinacu", "value": "C11138"},
           {"label": "Campinorte", "value": "C11139"},
           {"label": "Campo Alegre de Goias", "value": "C11140"},
           {"label": "Campo Limpo de Goias", "value": "C11141"},
           {"label": "Campos Belos", "value": "C11142"},
           {"label": "Campos Verdes", "value": "C11143"},
           {"label": "Carmo do Rio Verde", "value": "C11144"},
           {"label": "Castelandia", "value": "C11145"},
           {"label": "Catalao", "value": "C11146"},
           {"label": "Caturai", "value": "C11147"},
           {"label": "Cavalcante", "value": "C11148"},
           {"label": "Ceres", "value": "C11149"},
           {"label": "Cezarina", "value": "C11150"},
           {"label": "Chapadao do Ceu", "value": "C11151"},
           {"label": "Cidade Ocidental", "value": "C11152"},
           {"label": "Cocalzinho de Goias", "value": "C11153"},
           {"label": "Colinas do Sul", "value": "C11154"},
           {"label": "Corrego do Ouro", "value": "C11155"},
           {"label": "Corumba de Goias", "value": "C11156"},
           {"label": "Corumbaiba", "value": "C11157"},
           {"label": "Cristalina", "value": "C11158"},
           {"label": "Cristianopolis", "value": "C11159"},
           {"label": "Crixas", "value": "C11160"},
           {"label": "Crominia", "value": "C11161"},
           {"label": "Cumari", "value": "C11162"},
           {"label": "Damianopolis", "value": "C11163"},
           {"label": "Damolandia", "value": "C11164"},
           {"label": "Davinopolis", "value": "C11165"},
           {"label": "Diorama", "value": "C11166"},
           {"label": "Divinopolis de Goias", "value": "C11167"},
           {"label": "Doverlandia", "value": "C11168"},
           {"label": "Edealina", "value": "C11169"},
           {"label": "Edeia", "value": "C11170"},
           {"label": "Estrela do Norte", "value": "C11171"},
           {"label": "Faina", "value": "C11172"},
           {"label": "Fazenda Nova", "value": "C11173"},
           {"label": "Firminopolis", "value": "C11174"},
           {"label": "Flores de Goias", "value": "C11175"},
           {"label": "Formosa", "value": "C11176"},
           {"label": "Formoso", "value": "C11177"},
           {"label": "Gameleira de Goias", "value": "C11178"},
           {"label": "Goianapolis", "value": "C11179"},
           {"label": "Goiandira", "value": "C11180"},
           {"label": "Goianesia", "value": "C11181"},
           {"label": "Goiania", "value": "C11182"},
           {"label": "Goianira", "value": "C11183"},
           {"label": "Goias", "value": "C11184"},
           {"label": "Goiatuba", "value": "C11185"},
           {"label": "Gouvelandia", "value": "C11186"},
           {"label": "Guapo", "value": "C11187"},
           {"label": "Guaraita", "value": "C11188"},
           {"label": "Guarani de Goias", "value": "C11189"},
           {"label": "Guarinos", "value": "C11190"},
           {"label": "Heitorai", "value": "C11191"},
           {"label": "Hidrolandia", "value": "C11192"},
           {"label": "Hidrolina", "value": "C11193"},
           {"label": "Iaciara", "value": "C11194"},
           {"label": "Inaciolandia", "value": "C11195"},
           {"label": "Indiara", "value": "C11196"},
           {"label": "Inhumas", "value": "C11197"},
           {"label": "Ipameri", "value": "C11198"},
           {"label": "Ipiranga de Goias", "value": "C11199"},
           {"label": "Ipora", "value": "C11200"},
           {"label": "Israelandia", "value": "C11201"},
           {"label": "Itaberai", "value": "C11202"},
           {"label": "Itaguari", "value": "C11203"},
           {"label": "Itaguaru", "value": "C11204"},
           {"label": "Itaja", "value": "C11205"},
           {"label": "Itapaci", "value": "C11206"},
           {"label": "Itapirapua", "value": "C11207"},
           {"label": "Itapuranga", "value": "C11208"},
           {"label": "Itaruma", "value": "C11209"},
           {"label": "Itaucu", "value": "C11210"},
           {"label": "Itumbiara", "value": "C11211"},
           {"label": "Ivolandia", "value": "C11212"},
           {"label": "Jandaia", "value": "C11213"},
           {"label": "Jaragua", "value": "C11214"},
           {"label": "Jatai", "value": "C11215"},
           {"label": "Jaupaci", "value": "C11216"},
           {"label": "Jesupolis", "value": "C11217"},
           {"label": "Joviania", "value": "C11218"},
           {"label": "Jussara", "value": "C11219"},
           {"label": "Lagoa Santa", "value": "C11220"},
           {"label": "Leopoldo de Bulhoes", "value": "C11221"},
           {"label": "Luziania", "value": "C11222"},
           {"label": "Mairipotaba", "value": "C11223"},
           {"label": "Mambai", "value": "C11224"},
           {"label": "Mara Rosa", "value": "C11225"},
           {"label": "Marzagao", "value": "C11226"},
           {"label": "Matrincha", "value": "C11227"},
           {"label": "Maurilandia", "value": "C11228"},
           {"label": "Mimoso de Goias", "value": "C11229"},
           {"label": "Minacu", "value": "C11230"},
           {"label": "Mineiros", "value": "C11231"},
           {"label": "Moipora", "value": "C11232"},
           {"label": "Monte Alegre de Goias", "value": "C11233"},
           {"label": "Montes Claros de Goias", "value": "C11234"},
           {"label": "Montividiu", "value": "C11235"},
           {"label": "Montividiu do Norte", "value": "C11236"},
           {"label": "Morrinhos", "value": "C11237"},
           {"label": "Morro Agudo de Goias", "value": "C11238"},
           {"label": "Mossamedes", "value": "C11239"},
           {"label": "Mozarlandia", "value": "C11240"},
           {"label": "Mundo Novo", "value": "C11241"},
           {"label": "Mutunopolis", "value": "C11242"},
           {"label": "Nazario", "value": "C11243"},
           {"label": "Neropolis", "value": "C11244"},
           {"label": "Niquelandia", "value": "C11245"},
           {"label": "Nova America", "value": "C11246"},
           {"label": "Nova Aurora", "value": "C11247"},
           {"label": "Nova Crixas", "value": "C11248"},
           {"label": "Nova Gloria", "value": "C11249"},
           {"label": "Nova Iguacu de Goias", "value": "C11250"},
           {"label": "Nova Roma", "value": "C11251"},
           {"label": "Nova Veneza", "value": "C11252"},
           {"label": "Novo Brasil", "value": "C11253"},
           {"label": "Novo Gama", "value": "C11254"},
           {"label": "Novo Planalto", "value": "C11255"},
           {"label": "Orizona", "value": "C11256"},
           {"label": "Ouro Verde de Goias", "value": "C11257"},
           {"label": "Ouvidor", "value": "C11258"},
           {"label": "Padre Bernardo", "value": "C11259"},
           {"label": "Palestina de Goias", "value": "C11260"},
           {"label": "Palmeiras de Goias", "value": "C11261"},
           {"label": "Palmelo", "value": "C11262"},
           {"label": "Palminopolis", "value": "C11263"},
           {"label": "Panama", "value": "C11264"},
           {"label": "Paranaiguara", "value": "C11265"},
           {"label": "Parauna", "value": "C11266"},
           {"label": "Perolandia", "value": "C11267"},
           {"label": "Petrolina de Goias", "value": "C11268"},
           {"label": "Pilar de Goias", "value": "C11269"},
           {"label": "Piracanjuba", "value": "C11270"},
           {"label": "Piranhas", "value": "C11271"},
           {"label": "Pirenopolis", "value": "C11272"},
           {"label": "Pires do Rio", "value": "C11273"},
           {"label": "Planaltina", "value": "C11274"},
           {"label": "Pontalina", "value": "C11275"},
           {"label": "Porangatu", "value": "C11276"},
           {"label": "Porteirao", "value": "C11277"},
           {"label": "Portelandia", "value": "C11278"},
           {"label": "Posse", "value": "C11279"},
           {"label": "Professor Jamil", "value": "C11280"},
           {"label": "Quirinopolis", "value": "C11281"},
           {"label": "Rialma", "value": "C11282"},
           {"label": "Rianapolis", "value": "C11283"},
           {"label": "Rio Quente", "value": "C11284"},
           {"label": "Rio Verde", "value": "C11285"},
           {"label": "Rubiataba", "value": "C11286"},
           {"label": "Sanclerlandia", "value": "C11287"},
           {"label": "Santa Barbara de Goias", "value": "C11288"},
           {"label": "Santa Cruz de Goias", "value": "C11289"},
           {"label": "Santa Fe de Goias", "value": "C11290"},
           {"label": "Santa Helena de Goias", "value": "C11291"},
           {"label": "Santa Isabel", "value": "C11292"},
           {"label": "Santa Rita do Araguaia", "value": "C11293"},
           {"label": "Santa Rita do Novo Destino", "value": "C11294"},
           {"label": "Santa Rosa de Goias", "value": "C11295"},
           {"label": "Santa Tereza de Goias", "value": "C11296"},
           {"label": "Santa Terezinha de Goias", "value": "C11297"},
           {"label": "Santo Antonio da Barra", "value": "C11298"},
           {"label": "Santo Antonio de Goias", "value": "C11299"},
           {"label": "Santo Antonio do Descoberto", "value": "C11300"},
           {"label": "Sao Domingos", "value": "C11301"},
           {"label": "Sao Francisco de Goias", "value": "C11302"},
           {"label": "Sao Joao dAlianca", "value": "C11303"},
           {"label": "Sao Joao da Parauna", "value": "C11304"},
           {"label": "Sao Luis de Montes Belos", "value": "C11305"},
           {"label": "Sao Luiz do Norte", "value": "C11306"},
           {"label": "Sao Miguel do Araguaia", "value": "C11307"},
           {"label": "Sao Miguel do Passa Quatro", "value": "C11308"},
           {"label": "Sao Patricio", "value": "C11309"},
           {"label": "Sao Simao", "value": "C11310"},
           {"label": "Senador Canedo", "value": "C11311"},
           {"label": "Serranopolis", "value": "C11312"},
           {"label": "Silvania", "value": "C11313"},
           {"label": "Simolandia", "value": "C11314"},
           {"label": "Sitio dAbadia", "value": "C11315"},
           {"label": "Taquaral de Goias", "value": "C11316"},
           {"label": "Teresina de Goias", "value": "C11317"},
           {"label": "Terezopolis de Goias", "value": "C11318"},
           {"label": "Tres Ranchos", "value": "C11319"},
           {"label": "Trindade", "value": "C11320"},
           {"label": "Trombas", "value": "C11321"},
           {"label": "Turvania", "value": "C11322"},
           {"label": "Turvelandia", "value": "C11323"},
           {"label": "Uirapuru", "value": "C11324"},
           {"label": "Uruacu", "value": "C11325"},
           {"label": "Uruana", "value": "C11326"},
           {"label": "Urutai", "value": "C11327"},
           {"label": "Valparaiso de Goias", "value": "C11328"},
           {"label": "Varjao", "value": "C11329"},
           {"label": "Vianopolis", "value": "C11330"},
           {"label": "Vicentinopolis", "value": "C11331"},
           {"label": "Vila Boa", "value": "C11332"},
           {"label": "Vila Propicio", "value": "C11333"}],
 "Golestan": [{"label": "aq Qayeh", "value": "C50177"},
              {"label": "azadshahr", "value": "C50178"},
              {"label": "Gonbad-e Kavus", "value": "C50179"},
              {"label": "Gorgan", "value": "C50180"},
              {"label": "Kalaleh", "value": "C50181"},
              {"label": "Qarnabad", "value": "C50182"},
              {"label": "Shahrestan-e Aliabad", "value": "C50183"},
              {"label": "Shahrestan-e aq Qala", "value": "C50184"},
              {"label": "Shahrestan-e azadshahr", "value": "C50185"},
              {"label": "Shahrestan-e Galikesh", "value": "C50186"},
              {"label": "Shahrestan-e Gomishan", "value": "C50187"},
              {"label": "Shahrestan-e Gonbad-e Kavus", "value": "C50188"},
              {"label": "Shahrestan-e Gorgan", "value": "C50189"},
              {"label": "Shahrestan-e Kalaleh", "value": "C50190"},
              {"label": "Shahrestan-e Kordkuy", "value": "C50191"},
              {"label": "Shahrestan-e Maraveh Tappeh", "value": "C50192"},
              {"label": "Shahrestan-e Minudasht", "value": "C50193"},
              {"label": "Shahrestan-e Ramian", "value": "C50194"},
              {"label": "Torkaman", "value": "C50195"}],
 "Gombe": [{"label": "Akko", "value": "C78268"},
           {"label": "Bara", "value": "C78269"},
           {"label": "Billiri", "value": "C78270"},
           {"label": "Dadiya", "value": "C78271"},
           {"label": "Deba", "value": "C78272"},
           {"label": "Dukku", "value": "C78273"},
           {"label": "Garko", "value": "C78274"},
           {"label": "Gombe", "value": "C78275"},
           {"label": "Hinna", "value": "C78276"},
           {"label": "Kafarati", "value": "C78277"},
           {"label": "Kaltungo", "value": "C78278"},
           {"label": "Kumo", "value": "C78279"},
           {"label": "Nafada", "value": "C78280"},
           {"label": "Pindiga", "value": "C78281"}],
 "Gomel Region": [{"label": "Aktsyabrski", "value": "C8747"},
                  {"label": "Brahin", "value": "C8748"},
                  {"label": "Brahinski Rayon", "value": "C8749"},
                  {"label": "Buda-Kashalyova", "value": "C8750"},
                  {"label": "Chachersk", "value": "C8751"},
                  {"label": "Chacherski Rayon", "value": "C8752"},
                  {"label": "Dobrush", "value": "C8753"},
                  {"label": "Dowsk", "value": "C8754"},
                  {"label": "Homyel", "value": "C8755"},
                  {"label": "Homyelski Rayon", "value": "C8756"},
                  {"label": "Kalinkavichy", "value": "C8757"},
                  {"label": "Karanyowka", "value": "C8758"},
                  {"label": "Karma", "value": "C8759"},
                  {"label": "Kastsyukowka", "value": "C8760"},
                  {"label": "Khalch", "value": "C8761"},
                  {"label": "Khoyniki", "value": "C8762"},
                  {"label": "Loyew", "value": "C8763"},
                  {"label": "Lyelchytski Rayon", "value": "C8764"},
                  {"label": "Lyelchytsy", "value": "C8765"},
                  {"label": "Mazyr", "value": "C8766"},
                  {"label": "Mazyrski Rayon", "value": "C8767"},
                  {"label": "Narowlya", "value": "C8768"},
                  {"label": "Novaya Huta", "value": "C8769"},
                  {"label": "Parychy", "value": "C8770"},
                  {"label": "Peramoga", "value": "C8771"},
                  {"label": "Pyetrykaw", "value": "C8772"},
                  {"label": "Rahachow", "value": "C8773"},
                  {"label": "Rahachowski Rayon", "value": "C8774"},
                  {"label": "Rechytsa", "value": "C8775"},
                  {"label": "Sasnovy Bor", "value": "C8776"},
                  {"label": "Svyetlahorsk", "value": "C8777"},
                  {"label": "Turaw", "value": "C8778"},
                  {"label": "Vasilyevichy", "value": "C8779"},
                  {"label": "Vyetka", "value": "C8780"},
                  {"label": "Vyetkawski Rayon", "value": "C8781"},
                  {"label": "Yelsk", "value": "C8782"},
                  {"label": "Zhlobin", "value": "C8783"},
                  {"label": "Zhlobinski Rayon", "value": "C8784"},
                  {"label": "Zhytkavichy", "value": "C8785"}],
 "Goranboy District": [{"label": "Goranboy", "value": "C8434"},
                       {"label": "Qizilhacili", "value": "C8435"}],
 "Gorenja Vas-Poljane Municipality": [{"label": "Gorenja Vas",
                                       "value": "C107623"}],
 "Gorgol": [{"label": "Kaedi", "value": "C65950"}],
 "Gorisnica Municipality": [{"label": "Gorisnica", "value": "C107624"}],
 "Gorj County": [{"label": "Albeni", "value": "C95580"},
                 {"label": "Alimpesti", "value": "C95581"},
                 {"label": "Andreesti", "value": "C95582"},
                 {"label": "Aninoasa", "value": "C95583"},
                 {"label": "Arcani", "value": "C95584"},
                 {"label": "Baia de Fier", "value": "C95585"},
                 {"label": "Balcesti", "value": "C95586"},
                 {"label": "Balesti", "value": "C95587"},
                 {"label": "Balta", "value": "C95588"},
                 {"label": "Balteni", "value": "C95589"},
                 {"label": "Barbatesti", "value": "C95590"},
                 {"label": "Bengesti", "value": "C95591"},
                 {"label": "Berlesti", "value": "C95592"},
                 {"label": "Bolbosi", "value": "C95593"},
                 {"label": "Borascu", "value": "C95594"},
                 {"label": "Branesti", "value": "C95595"},
                 {"label": "Bumbesti-Jiu", "value": "C95596"},
                 {"label": "Bustuchin", "value": "C95597"},
                 {"label": "Calnic", "value": "C95598"},
                 {"label": "Capreni", "value": "C95599"},
                 {"label": "Carpinis", "value": "C95600"},
                 {"label": "Catunele", "value": "C95601"},
                 {"label": "Ceauru", "value": "C95602"},
                 {"label": "Ciuperceni", "value": "C95603"},
                 {"label": "Closani", "value": "C95604"},
                 {"label": "Cocoreni", "value": "C95605"},
                 {"label": "Comuna Albeni", "value": "C95606"},
                 {"label": "Comuna Alimpesti", "value": "C95607"},
                 {"label": "Comuna Aninoasa", "value": "C95608"},
                 {"label": "Comuna Arcani", "value": "C95609"},
                 {"label": "Comuna Baia de Fier", "value": "C95610"},
                 {"label": "Comuna Balanesti", "value": "C95611"},
                 {"label": "Comuna Balesti", "value": "C95612"},
                 {"label": "Comuna Balteni", "value": "C95613"},
                 {"label": "Comuna Barbatesti", "value": "C95614"},
                 {"label": "Comuna Bengesti-Ciocadia", "value": "C95615"},
                 {"label": "Comuna Bolbosi", "value": "C95616"},
                 {"label": "Comuna Borascu", "value": "C95617"},
                 {"label": "Comuna Branesti", "value": "C95618"},
                 {"label": "Comuna Bumbesti-Pitic", "value": "C95619"},
                 {"label": "Comuna Bustuchin", "value": "C95620"},
                 {"label": "Comuna Calnic", "value": "C95621"},
                 {"label": "Comuna Capreni", "value": "C95622"},
                 {"label": "Comuna Catunele", "value": "C95623"},
                 {"label": "Comuna Ciuperceni", "value": "C95624"},
                 {"label": "Comuna Crasna", "value": "C95625"},
                 {"label": "Comuna Cruset", "value": "C95626"},
                 {"label": "Comuna Danciulesti", "value": "C95627"},
                 {"label": "Comuna Danesti", "value": "C95628"},
                 {"label": "Comuna Dragotesti", "value": "C95629"},
                 {"label": "Comuna Dragutesti", "value": "C95630"},
                 {"label": "Comuna Farcasesti", "value": "C95631"},
                 {"label": "Comuna Glogova", "value": "C95632"},
                 {"label": "Comuna Godinesti", "value": "C95633"},
                 {"label": "Comuna Hurezani", "value": "C95634"},
                 {"label": "Comuna Ionesti", "value": "C95635"},
                 {"label": "Comuna Jupanesti", "value": "C95636"},
                 {"label": "Comuna Lelesti", "value": "C95637"},
                 {"label": "Comuna Licurici", "value": "C95638"},
                 {"label": "Comuna Logresti-Mosteni", "value": "C95639"},
                 {"label": "Comuna Matasari", "value": "C95640"},
                 {"label": "Comuna Musetesti", "value": "C95641"},
                 {"label": "Comuna Negomir", "value": "C95642"},
                 {"label": "Comuna Pades", "value": "C95643"},
                 {"label": "Comuna Pestisani", "value": "C95644"},
                 {"label": "Comuna Plopsoru", "value": "C95645"},
                 {"label": "Comuna Polovragi", "value": "C95646"},
                 {"label": "Comuna Prigoria", "value": "C95647"},
                 {"label": "Comuna Rosia De Amaradia", "value": "C95648"},
                 {"label": "Comuna Runcu", "value": "C95649"},
                 {"label": "Comuna Sacelu", "value": "C95650"},
                 {"label": "Comuna Samarinesti", "value": "C95651"},
                 {"label": "Comuna Saulesti", "value": "C95652"},
                 {"label": "Comuna Schela", "value": "C95653"},
                 {"label": "Comuna Scoarta", "value": "C95654"},
                 {"label": "Comuna Slivilesti", "value": "C95655"},
                 {"label": "Comuna Stanesti", "value": "C95656"},
                 {"label": "Comuna Stejari", "value": "C95657"},
                 {"label": "Comuna Stoina", "value": "C95658"},
                 {"label": "Comuna Tantareni", "value": "C95659"},
                 {"label": "Comuna Telesti", "value": "C95660"},
                 {"label": "Comuna Turburea", "value": "C95661"},
                 {"label": "Comuna Turcinesti", "value": "C95662"},
                 {"label": "Comuna Urdari", "value": "C95663"},
                 {"label": "Comuna Vagiulesti", "value": "C95664"},
                 {"label": "Comuna Vladimir", "value": "C95665"},
                 {"label": "Costesti", "value": "C95666"},
                 {"label": "Covrigi", "value": "C95667"},
                 {"label": "Crasna", "value": "C95668"},
                 {"label": "Cruset", "value": "C95669"},
                 {"label": "Danciulesti", "value": "C95670"},
                 {"label": "Danesti", "value": "C95671"},
                 {"label": "Dobrita", "value": "C95672"},
                 {"label": "Dragotesti", "value": "C95673"},
                 {"label": "Dragutesti", "value": "C95674"},
                 {"label": "Farcasesti", "value": "C95675"},
                 {"label": "Floresti", "value": "C95676"},
                 {"label": "Glogova", "value": "C95677"},
                 {"label": "Godinesti", "value": "C95678"},
                 {"label": "Hurezani", "value": "C95679"},
                 {"label": "Ionesti", "value": "C95680"},
                 {"label": "Jupanesti", "value": "C95681"},
                 {"label": "Lelesti", "value": "C95682"},
                 {"label": "Licurici", "value": "C95683"},
                 {"label": "Logresti", "value": "C95684"},
                 {"label": "Logresti Mosteni", "value": "C95685"},
                 {"label": "Matasari", "value": "C95686"},
                 {"label": "Motru", "value": "C95687"},
                 {"label": "Municipiul Motru", "value": "C95688"},
                 {"label": "Municipiul Targu Jiu", "value": "C95689"},
                 {"label": "Musetesti", "value": "C95690"},
                 {"label": "Negomir", "value": "C95691"},
                 {"label": "Novaci", "value": "C95692"},
                 {"label": "Oras Bumbesti-Jiu", "value": "C95693"},
                 {"label": "Oras Rovinari", "value": "C95694"},
                 {"label": "Oras Targu Carbunesti", "value": "C95695"},
                 {"label": "Oras Ticleni", "value": "C95696"},
                 {"label": "Oras Tismana", "value": "C95697"},
                 {"label": "Oras Turceni", "value": "C95698"},
                 {"label": "Pades", "value": "C95699"},
                 {"label": "Pesteana de Jos", "value": "C95700"},
                 {"label": "Pesteana Jiu", "value": "C95701"},
                 {"label": "Pestisani", "value": "C95702"},
                 {"label": "Piscoiu", "value": "C95703"},
                 {"label": "Plopsoru", "value": "C95704"},
                 {"label": "Plostina", "value": "C95705"},
                 {"label": "Pociovalistea", "value": "C95706"},
                 {"label": "Pocruia", "value": "C95707"},
                 {"label": "Poiana", "value": "C95708"},
                 {"label": "Pojogeni", "value": "C95709"},
                 {"label": "Polovragi", "value": "C95710"},
                 {"label": "Prigoria", "value": "C95711"},
                 {"label": "Rosia de Amaradia", "value": "C95712"},
                 {"label": "Rovinari", "value": "C95713"},
                 {"label": "Runcu", "value": "C95714"},
                 {"label": "Sacelu", "value": "C95715"},
                 {"label": "Samarinesti", "value": "C95716"},
                 {"label": "Sambotin", "value": "C95717"},
                 {"label": "Saulesti", "value": "C95718"},
                 {"label": "Scoarta", "value": "C95719"},
                 {"label": "Slivilesti", "value": "C95720"},
                 {"label": "Sohodol", "value": "C95721"},
                 {"label": "Stanesti", "value": "C95722"},
                 {"label": "Stejari", "value": "C95723"},
                 {"label": "Sterpoaia", "value": "C95724"},
                 {"label": "Stoina", "value": "C95725"},
                 {"label": "Tantareni", "value": "C95726"},
                 {"label": "Targu Carbunesti", "value": "C95727"},
                 {"label": "Targu Jiu", "value": "C95728"},
                 {"label": "Telesti", "value": "C95729"},
                 {"label": "Ticleni", "value": "C95730"},
                 {"label": "Tismana", "value": "C95731"},
                 {"label": "Turburea", "value": "C95732"},
                 {"label": "Turceni", "value": "C95733"},
                 {"label": "Turcinesti", "value": "C95734"},
                 {"label": "Urdari", "value": "C95735"},
                 {"label": "Vagiulesti", "value": "C95736"},
                 {"label": "Valea Mare", "value": "C95737"},
                 {"label": "Viersani", "value": "C95738"},
                 {"label": "Vladuleni", "value": "C95739"},
                 {"label": "Voitestii din Vale", "value": "C95740"}],
 "Gornja Radgona Municipality": [{"label": "Gornja Radgona",
                                  "value": "C107625"}],
 "Gornji Grad Municipality": [{"label": "Gornji Grad", "value": "C107626"}],
 "Gornji Petrovci Municipality": [{"label": "Gornji Petrovci",
                                   "value": "C107627"}],
 "Gorno-Badakhshan Autonomous Province": [{"label": "Ishqoshim",
                                           "value": "C118010"},
                                          {"label": "Khorugh",
                                           "value": "C118011"},
                                          {"label": "Murghob",
                                           "value": "C118012"},
                                          {"label": "Nohiyai Shughnon",
                                           "value": "C118013"}],
 "Gorontalo": [{"label": "Gorontalo", "value": "C49248"},
               {"label": "Kabupaten Boalemo", "value": "C49249"},
               {"label": "Kabupaten Bone Bolango", "value": "C49250"},
               {"label": "Kabupaten Gorontalo", "value": "C49251"},
               {"label": "Kabupaten Gorontalo Utara", "value": "C49252"},
               {"label": "Kabupaten Pohuwato", "value": "C49253"},
               {"label": "Kota Gorontalo", "value": "C49254"}],
 "Gostivar Municipality": [{"label": "Cegrane", "value": "C65405"},
                           {"label": "Dolna Banjica", "value": "C65406"},
                           {"label": "Forino", "value": "C65407"},
                           {"label": "Gostivar", "value": "C65408"},
                           {"label": "Srbinovo", "value": "C65409"},
                           {"label": "Vrutok", "value": "C65410"}],
 "Gotland County": [{"label": "Gotland", "value": "C115587"},
                    {"label": "Hemse", "value": "C115588"},
                    {"label": "Klintehamn", "value": "C115589"},
                    {"label": "Vibble", "value": "C115590"},
                    {"label": "Visby", "value": "C115591"}],
 "Govi-Altai Province": [{"label": "Altai", "value": "C75566"}],
 "Govisumber Province": [{"label": "Choyr", "value": "C75567"}],
 "Goychay": [{"label": "Geoktschai", "value": "C8436"}],
 "Goygol District": [{"label": "Yelenendorf", "value": "C8437"}],
 "Gracias a Dios Department": [{"label": "Ahuas", "value": "C43499"},
                               {"label": "Auas", "value": "C43500"},
                               {"label": "Auka", "value": "C43501"},
                               {"label": "Barra Patuca", "value": "C43502"},
                               {"label": "Brus Laguna", "value": "C43503"},
                               {"label": "Iralaya", "value": "C43504"},
                               {"label": "Juan Francisco Bulnes",
                                "value": "C43505"},
                               {"label": "Paptalaya", "value": "C43506"},
                               {"label": "Puerto Lempira", "value": "C43507"},
                               {"label": "Villeda Morales", "value": "C43508"},
                               {"label": "Wampusirpi", "value": "C43509"},
                               {"label": "Wawina", "value": "C43510"}],
 "Grad Municipality": [{"label": "Grad", "value": "C107628"}],
 "Gradsko Municipality": [{"label": "Gradsko", "value": "C65411"}],
 "Granada": [{"label": "Diria", "value": "C77928"},
             {"label": "Diriomo", "value": "C77929"},
             {"label": "Granada", "value": "C77930"},
             {"label": "Nandaime", "value": "C77931"}],
 "Grand Bassa County": [{"label": "Buchanan", "value": "C65005"}],
 "Grand Cape Mount County": [{"label": "Robertsport", "value": "C65006"}],
 "Grand Gedeh County": [{"label": "Zwedru", "value": "C65007"}],
 "Grand Kru County": [{"label": "Barclayville", "value": "C65008"}],
 "Grand Port District": [{"label": "Bambous Virieux", "value": "C65997"},
                         {"label": "Beau Vallon", "value": "C65998"},
                         {"label": "Bois des Amourettes", "value": "C65999"},
                         {"label": "Cluny", "value": "C66000"},
                         {"label": "Grand Sable", "value": "C66001"},
                         {"label": "Mahebourg", "value": "C66002"},
                         {"label": "New Grove", "value": "C66003"},
                         {"label": "Nouvelle France", "value": "C66004"},
                         {"label": "Plaine Magnien", "value": "C66005"},
                         {"label": "Rose Belle", "value": "C66006"},
                         {"label": "Saint Hubert", "value": "C66007"}],
 "Grand-Est": [{"label": "Abreschviller", "value": "C28102"},
               {"label": "Achenheim", "value": "C28103"},
               {"label": "Aiglemont", "value": "C28104"},
               {"label": "Aix-en-Othe", "value": "C28105"},
               {"label": "Algolsheim", "value": "C28106"},
               {"label": "Algrange", "value": "C28107"},
               {"label": "Alsting", "value": "C28108"},
               {"label": "Altkirch", "value": "C28109"},
               {"label": "Altorf", "value": "C28110"},
               {"label": "Amanvillers", "value": "C28111"},
               {"label": "Ammerschwihr", "value": "C28112"},
               {"label": "Amneville", "value": "C28113"},
               {"label": "Ancerville", "value": "C28114"},
               {"label": "Ancy-sur-Moselle", "value": "C28115"},
               {"label": "Andlau", "value": "C28116"},
               {"label": "Andolsheim", "value": "C28117"},
               {"label": "Angevillers", "value": "C28118"},
               {"label": "Anould", "value": "C28119"},
               {"label": "Arches", "value": "C28120"},
               {"label": "Archettes", "value": "C28121"},
               {"label": "Arcis-sur-Aube", "value": "C28122"},
               {"label": "Ardennes", "value": "C28123"},
               {"label": "Argancy", "value": "C28124"},
               {"label": "Ars-sur-Moselle", "value": "C28125"},
               {"label": "Art-sur-Meurthe", "value": "C28126"},
               {"label": "Aspach", "value": "C28127"},
               {"label": "Aspach-le-Bas", "value": "C28128"},
               {"label": "Aspach-le-Haut", "value": "C28129"},
               {"label": "Attigny", "value": "C28130"},
               {"label": "Auboue", "value": "C28131"},
               {"label": "Audun-le-Roman", "value": "C28132"},
               {"label": "Audun-le-Tiche", "value": "C28133"},
               {"label": "Augny", "value": "C28134"},
               {"label": "Aumetz", "value": "C28135"},
               {"label": "Avize", "value": "C28136"},
               {"label": "Ay", "value": "C28137"},
               {"label": "Ay-sur-Moselle", "value": "C28138"},
               {"label": "Aydoilles", "value": "C28139"},
               {"label": "Baccarat", "value": "C28140"},
               {"label": "Badonviller", "value": "C28141"},
               {"label": "Bains-les-Bains", "value": "C28142"},
               {"label": "Bainville-sur-Madon", "value": "C28143"},
               {"label": "Balan", "value": "C28144"},
               {"label": "Baldersheim", "value": "C28145"},
               {"label": "Ban-de-Laveline", "value": "C28146"},
               {"label": "Bantzenheim", "value": "C28147"},
               {"label": "Bar-le-Duc", "value": "C28148"},
               {"label": "Bar-sur-Aube", "value": "C28149"},
               {"label": "Bar-sur-Seine", "value": "C28150"},
               {"label": "Barr", "value": "C28151"},
               {"label": "Bartenheim", "value": "C28152"},
               {"label": "Bas-Rhin", "value": "C28153"},
               {"label": "Basse-Ham", "value": "C28154"},
               {"label": "Batilly", "value": "C28155"},
               {"label": "Battenheim", "value": "C28156"},
               {"label": "Bayard-sur-Marne", "value": "C28157"},
               {"label": "Bayon", "value": "C28158"},
               {"label": "Bazancourt", "value": "C28159"},
               {"label": "Bazeilles", "value": "C28160"},
               {"label": "Behren-les-Forbach", "value": "C28161"},
               {"label": "Belleville", "value": "C28162"},
               {"label": "Belleville-sur-Meuse", "value": "C28163"},
               {"label": "Benfeld", "value": "C28164"},
               {"label": "Bening-les-Saint-Avold", "value": "C28165"},
               {"label": "Bennwihr", "value": "C28166"},
               {"label": "Bergheim", "value": "C28167"},
               {"label": "Bergholtz", "value": "C28168"},
               {"label": "Bernardswiller", "value": "C28169"},
               {"label": "Berrwiller", "value": "C28170"},
               {"label": "Berstett", "value": "C28171"},
               {"label": "Bertrange", "value": "C28172"},
               {"label": "Betheny", "value": "C28173"},
               {"label": "Betschdorf", "value": "C28174"},
               {"label": "Bettancourt-la-Ferree", "value": "C28175"},
               {"label": "Bezannes", "value": "C28176"},
               {"label": "Bienville", "value": "C28177"},
               {"label": "Biesheim", "value": "C28178"},
               {"label": "Biesles", "value": "C28179"},
               {"label": "Bining", "value": "C28180"},
               {"label": "Bischheim", "value": "C28181"},
               {"label": "Bischoffsheim", "value": "C28182"},
               {"label": "Bischwiller", "value": "C28183"},
               {"label": "Bitche", "value": "C28184"},
               {"label": "Bitschwiller-les-Thann", "value": "C28185"},
               {"label": "Blaesheim", "value": "C28186"},
               {"label": "Blagny", "value": "C28187"},
               {"label": "Blenod-les-Pont-a-Mousson", "value": "C28188"},
               {"label": "Blenod-les-Toul", "value": "C28189"},
               {"label": "Bliesbruck", "value": "C28190"},
               {"label": "Blodelsheim", "value": "C28191"},
               {"label": "Blotzheim", "value": "C28192"},
               {"label": "Boersch", "value": "C28193"},
               {"label": "Bollwiller", "value": "C28194"},
               {"label": "Bologne", "value": "C28195"},
               {"label": "Boofzheim", "value": "C28196"},
               {"label": "Bouilly", "value": "C28197"},
               {"label": "Boulange", "value": "C28198"},
               {"label": "Boulay-Moselle", "value": "C28199"},
               {"label": "Bouligny", "value": "C28200"},
               {"label": "Boult-sur-Suippe", "value": "C28201"},
               {"label": "Bourbonne-les-Bains", "value": "C28202"},
               {"label": "Bourgogne", "value": "C28203"},
               {"label": "Bousse", "value": "C28204"},
               {"label": "Bouxieres-aux-Chenes", "value": "C28205"},
               {"label": "Bouxieres-aux-Dames", "value": "C28206"},
               {"label": "Bouxwiller", "value": "C28207"},
               {"label": "Bouzonville", "value": "C28208"},
               {"label": "Breuschwickersheim", "value": "C28209"},
               {"label": "Breviandes", "value": "C28210"},
               {"label": "Brienne-le-Chateau", "value": "C28211"},
               {"label": "Briey", "value": "C28212"},
               {"label": "Brumath", "value": "C28213"},
               {"label": "Brunstatt", "value": "C28214"},
               {"label": "Bruyeres", "value": "C28215"},
               {"label": "Bucheres", "value": "C28216"},
               {"label": "Buhl", "value": "C28217"},
               {"label": "Bulgneville", "value": "C28218"},
               {"label": "Burnhaupt-le-Bas", "value": "C28219"},
               {"label": "Burnhaupt-le-Haut", "value": "C28220"},
               {"label": "Bussang", "value": "C28221"},
               {"label": "Carignan", "value": "C28222"},
               {"label": "Carling", "value": "C28223"},
               {"label": "Carspach", "value": "C28224"},
               {"label": "Cattenom", "value": "C28225"},
               {"label": "Cernay", "value": "C28226"},
               {"label": "Cernay-les-Reims", "value": "C28227"},
               {"label": "Chalampe", "value": "C28228"},
               {"label": "Chaligny", "value": "C28229"},
               {"label": "Chalindrey", "value": "C28230"},
               {"label": "Chalons-en-Champagne", "value": "C28231"},
               {"label": "Chamarandes-Choignes", "value": "C28232"},
               {"label": "Champenoux", "value": "C28233"},
               {"label": "Champigneulles", "value": "C28234"},
               {"label": "Chancenay", "value": "C28235"},
               {"label": "Chanteheux", "value": "C28236"},
               {"label": "Chantraine", "value": "C28237"},
               {"label": "Chaource", "value": "C28238"},
               {"label": "Charleville-Mezieres", "value": "C28239"},
               {"label": "Charmes", "value": "C28240"},
               {"label": "Chateau-Porcien", "value": "C28241"},
               {"label": "Chateau-Salins", "value": "C28242"},
               {"label": "Chateauvillain", "value": "C28243"},
               {"label": "Chatel-Saint-Germain", "value": "C28244"},
               {"label": "Chatel-sur-Moselle", "value": "C28245"},
               {"label": "Chatenois", "value": "C28246"},
               {"label": "Chatillon-sur-Marne", "value": "C28247"},
               {"label": "Chaumont", "value": "C28248"},
               {"label": "Chavelot", "value": "C28249"},
               {"label": "Chavigny", "value": "C28250"},
               {"label": "Chenimenil", "value": "C28251"},
               {"label": "Chevillon", "value": "C28252"},
               {"label": "Cirey-sur-Vezouze", "value": "C28253"},
               {"label": "Clermont-en-Argonne", "value": "C28254"},
               {"label": "Clouange", "value": "C28255"},
               {"label": "Cocheren", "value": "C28256"},
               {"label": "Colmar", "value": "C28257"},
               {"label": "Colombey-les-Belles", "value": "C28258"},
               {"label": "Commercy", "value": "C28259"},
               {"label": "Compertrix", "value": "C28260"},
               {"label": "Conflans-en-Jarnisy", "value": "C28261"},
               {"label": "Connantre", "value": "C28262"},
               {"label": "Contrexeville", "value": "C28263"},
               {"label": "Corcieux", "value": "C28264"},
               {"label": "Cormicy", "value": "C28265"},
               {"label": "Cormontreuil", "value": "C28266"},
               {"label": "Cornimont", "value": "C28267"},
               {"label": "Corny-sur-Moselle", "value": "C28268"},
               {"label": "Cosnes", "value": "C28269"},
               {"label": "Courcelles-Chaussy", "value": "C28270"},
               {"label": "Courcy", "value": "C28271"},
               {"label": "Courtisols", "value": "C28272"},
               {"label": "Crehange", "value": "C28273"},
               {"label": "Creney-pres-Troyes", "value": "C28274"},
               {"label": "Creutzwald", "value": "C28275"},
               {"label": "Crusnes", "value": "C28276"},
               {"label": "Custines", "value": "C28277"},
               {"label": "Dabo", "value": "C28278"},
               {"label": "Dachstein", "value": "C28279"},
               {"label": "Dambach-la-Ville", "value": "C28280"},
               {"label": "Damelevieres", "value": "C28281"},
               {"label": "Damery", "value": "C28282"},
               {"label": "Dannemarie", "value": "C28283"},
               {"label": "Darney", "value": "C28284"},
               {"label": "Darnieulles", "value": "C28285"},
               {"label": "Dauendorf", "value": "C28286"},
               {"label": "Departement de lAube", "value": "C28287"},
               {"label": "Dessenheim", "value": "C28288"},
               {"label": "Dettwiller", "value": "C28289"},
               {"label": "Deville", "value": "C28290"},
               {"label": "Deyvillers", "value": "C28291"},
               {"label": "Didenheim", "value": "C28292"},
               {"label": "Diebling", "value": "C28293"},
               {"label": "Diemeringen", "value": "C28294"},
               {"label": "Diesen", "value": "C28295"},
               {"label": "Dietwiller", "value": "C28296"},
               {"label": "Dieue-sur-Meuse", "value": "C28297"},
               {"label": "Dieulouard", "value": "C28298"},
               {"label": "Dieuze", "value": "C28299"},
               {"label": "Dingsheim", "value": "C28300"},
               {"label": "Dinsheim-sur-Bruche", "value": "C28301"},
               {"label": "Distroff", "value": "C28302"},
               {"label": "Dizy", "value": "C28303"},
               {"label": "Docelles", "value": "C28304"},
               {"label": "Dogneville", "value": "C28305"},
               {"label": "Dom-le-Mesnil", "value": "C28306"},
               {"label": "Dombasle-sur-Meurthe", "value": "C28307"},
               {"label": "Domgermain", "value": "C28308"},
               {"label": "Dommartin-les-Remiremont", "value": "C28309"},
               {"label": "Dommartin-les-Toul", "value": "C28310"},
               {"label": "Donchery", "value": "C28311"},
               {"label": "Doncourt-les-Conflans", "value": "C28312"},
               {"label": "Dorlisheim", "value": "C28313"},
               {"label": "Dormans", "value": "C28314"},
               {"label": "Dossenheim-sur-Zinsel", "value": "C28315"},
               {"label": "Doulaincourt-Saucourt", "value": "C28316"},
               {"label": "Douzy", "value": "C28317"},
               {"label": "Drulingen", "value": "C28318"},
               {"label": "Drusenheim", "value": "C28319"},
               {"label": "Dugny-sur-Meuse", "value": "C28320"},
               {"label": "Duppigheim", "value": "C28321"},
               {"label": "Durrenbach", "value": "C28322"},
               {"label": "Duttlenheim", "value": "C28323"},
               {"label": "Ebersheim", "value": "C28324"},
               {"label": "Eckbolsheim", "value": "C28325"},
               {"label": "Eckwersheim", "value": "C28326"},
               {"label": "ecrouves", "value": "C28327"},
               {"label": "Eguisheim", "value": "C28328"},
               {"label": "Einville-au-Jard", "value": "C28329"},
               {"label": "eloyes", "value": "C28330"},
               {"label": "Enchenberg", "value": "C28331"},
               {"label": "Ennery", "value": "C28332"},
               {"label": "Ensisheim", "value": "C28333"},
               {"label": "Entrange", "value": "C28334"},
               {"label": "Entzheim", "value": "C28335"},
               {"label": "epernay", "value": "C28336"},
               {"label": "Epfig", "value": "C28337"},
               {"label": "epinal", "value": "C28338"},
               {"label": "Ernolsheim-Bruche", "value": "C28339"},
               {"label": "Erstein", "value": "C28340"},
               {"label": "Ervy-le-Chatel", "value": "C28341"},
               {"label": "Eschau", "value": "C28342"},
               {"label": "Eschbach", "value": "C28343"},
               {"label": "Eschentzwiller", "value": "C28344"},
               {"label": "Essey-les-Nancy", "value": "C28345"},
               {"label": "Esternay", "value": "C28346"},
               {"label": "Estissac", "value": "C28347"},
               {"label": "etain", "value": "C28348"},
               {"label": "etival-Clairefontaine", "value": "C28349"},
               {"label": "Etzling", "value": "C28350"},
               {"label": "Eulmont", "value": "C28351"},
               {"label": "Eurville-Bienville", "value": "C28352"},
               {"label": "Euville", "value": "C28353"},
               {"label": "Fagnieres", "value": "C28354"},
               {"label": "Fains-Veel", "value": "C28355"},
               {"label": "Falck", "value": "C28356"},
               {"label": "Fameck", "value": "C28357"},
               {"label": "Farebersviller", "value": "C28358"},
               {"label": "Farschviller", "value": "C28359"},
               {"label": "Faulquemont", "value": "C28360"},
               {"label": "Faulx", "value": "C28361"},
               {"label": "Fayl-Billot", "value": "C28362"},
               {"label": "Fegersheim", "value": "C28363"},
               {"label": "Fellering", "value": "C28364"},
               {"label": "Fere-Champenoise", "value": "C28365"},
               {"label": "Ferrette", "value": "C28366"},
               {"label": "Fessenheim", "value": "C28367"},
               {"label": "Fismes", "value": "C28368"},
               {"label": "Flavigny-sur-Moselle", "value": "C28369"},
               {"label": "Flaxlanden", "value": "C28370"},
               {"label": "Fleury", "value": "C28371"},
               {"label": "Fleville-devant-Nancy", "value": "C28372"},
               {"label": "Flize", "value": "C28373"},
               {"label": "Floing", "value": "C28374"},
               {"label": "Florange", "value": "C28375"},
               {"label": "Folkling", "value": "C28376"},
               {"label": "Folschviller", "value": "C28377"},
               {"label": "Fontoy", "value": "C28378"},
               {"label": "Forbach", "value": "C28379"},
               {"label": "Fortschwihr", "value": "C28380"},
               {"label": "Foug", "value": "C28381"},
               {"label": "Fraize", "value": "C28382"},
               {"label": "Freland", "value": "C28383"},
               {"label": "Fresse-sur-Moselle", "value": "C28384"},
               {"label": "Freyming-Merlebach", "value": "C28385"},
               {"label": "Frignicourt", "value": "C28386"},
               {"label": "Fromelennes", "value": "C28387"},
               {"label": "Froncles", "value": "C28388"},
               {"label": "Frouard", "value": "C28389"},
               {"label": "Fumay", "value": "C28390"},
               {"label": "Furdenheim", "value": "C28391"},
               {"label": "Gambsheim", "value": "C28392"},
               {"label": "Gandrange", "value": "C28393"},
               {"label": "Geispolsheim", "value": "C28394"},
               {"label": "Gerardmer", "value": "C28395"},
               {"label": "Gerbeviller", "value": "C28396"},
               {"label": "Gerstheim", "value": "C28397"},
               {"label": "Gespunsart", "value": "C28398"},
               {"label": "Geudertheim", "value": "C28399"},
               {"label": "Giraumont", "value": "C28400"},
               {"label": "Givet", "value": "C28401"},
               {"label": "Goersdorf", "value": "C28402"},
               {"label": "Goetzenbruck", "value": "C28403"},
               {"label": "Golbey", "value": "C28404"},
               {"label": "Gondrecourt-le-Chateau", "value": "C28405"},
               {"label": "Gondreville", "value": "C28406"},
               {"label": "Gorcy", "value": "C28407"},
               {"label": "Gorze", "value": "C28408"},
               {"label": "Granges-sur-Vologne", "value": "C28409"},
               {"label": "Grendelbruch", "value": "C28410"},
               {"label": "Gresswiller", "value": "C28411"},
               {"label": "Gries", "value": "C28412"},
               {"label": "Griesheim-pres-Molsheim", "value": "C28413"},
               {"label": "Griesheim-sur-Souffel", "value": "C28414"},
               {"label": "Gros-Rederching", "value": "C28415"},
               {"label": "Grosbliederstroff", "value": "C28416"},
               {"label": "Guebwiller", "value": "C28417"},
               {"label": "Guemar", "value": "C28418"},
               {"label": "Guenange", "value": "C28419"},
               {"label": "Gueux", "value": "C28420"},
               {"label": "Guewenheim", "value": "C28421"},
               {"label": "Gumbrechtshoffen", "value": "C28422"},
               {"label": "Gundershoffen", "value": "C28423"},
               {"label": "Habsheim", "value": "C28424"},
               {"label": "Hadol", "value": "C28425"},
               {"label": "Hagenthal-le-Bas", "value": "C28426"},
               {"label": "Hagondange", "value": "C28427"},
               {"label": "Haguenau", "value": "C28428"},
               {"label": "Ham-sous-Varsberg", "value": "C28429"},
               {"label": "Hambach", "value": "C28430"},
               {"label": "Hangenbieten", "value": "C28431"},
               {"label": "Hargarten-aux-Mines", "value": "C28432"},
               {"label": "Hatten", "value": "C28433"},
               {"label": "Haucourt", "value": "C28434"},
               {"label": "Haut-Rhin", "value": "C28435"},
               {"label": "Haute-Marne", "value": "C28436"},
               {"label": "Hayange", "value": "C28437"},
               {"label": "Haybes", "value": "C28438"},
               {"label": "Hegenheim", "value": "C28439"},
               {"label": "Heillecourt", "value": "C28440"},
               {"label": "Heimsbrunn", "value": "C28441"},
               {"label": "Herbitzheim", "value": "C28442"},
               {"label": "Hermonville", "value": "C28443"},
               {"label": "Herrlisheim", "value": "C28444"},
               {"label": "Herrlisheim-pres-Colmar", "value": "C28445"},
               {"label": "Herserange", "value": "C28446"},
               {"label": "Hesingue", "value": "C28447"},
               {"label": "Hettange-Grande", "value": "C28448"},
               {"label": "Hilsenheim", "value": "C28449"},
               {"label": "Hindisheim", "value": "C28450"},
               {"label": "Hirsingue", "value": "C28451"},
               {"label": "Hirtzbach", "value": "C28452"},
               {"label": "Hirtzfelden", "value": "C28453"},
               {"label": "Hochfelden", "value": "C28454"},
               {"label": "Hochstatt", "value": "C28455"},
               {"label": "Hoenheim", "value": "C28456"},
               {"label": "Hoerdt", "value": "C28457"},
               {"label": "Holtzheim", "value": "C28458"},
               {"label": "Holtzwihr", "value": "C28459"},
               {"label": "Holving", "value": "C28460"},
               {"label": "Hombourg-Haut", "value": "C28461"},
               {"label": "Homecourt", "value": "C28462"},
               {"label": "Horbourg-Wihr", "value": "C28463"},
               {"label": "Houdemont", "value": "C28464"},
               {"label": "Houssen", "value": "C28465"},
               {"label": "Hundling", "value": "C28466"},
               {"label": "Huningue", "value": "C28467"},
               {"label": "Husseren-Wesserling", "value": "C28468"},
               {"label": "Hussigny-Godbrange", "value": "C28469"},
               {"label": "Huttenheim", "value": "C28470"},
               {"label": "Igney", "value": "C28471"},
               {"label": "Illange", "value": "C28472"},
               {"label": "Illfurth", "value": "C28473"},
               {"label": "Illkirch-Graffenstaden", "value": "C28474"},
               {"label": "Illzach", "value": "C28475"},
               {"label": "Ingersheim", "value": "C28476"},
               {"label": "Ingwiller", "value": "C28477"},
               {"label": "Innenheim", "value": "C28478"},
               {"label": "Irmstett", "value": "C28479"},
               {"label": "Issenheim", "value": "C28480"},
               {"label": "Ittenheim", "value": "C28481"},
               {"label": "Jarny", "value": "C28482"},
               {"label": "Jarville-la-Malgrange", "value": "C28483"},
               {"label": "Jeanmenil", "value": "C28484"},
               {"label": "Jebsheim", "value": "C28485"},
               {"label": "Joeuf", "value": "C28486"},
               {"label": "Joinville", "value": "C28487"},
               {"label": "Jonchery-sur-Vesle", "value": "C28488"},
               {"label": "Joudreville", "value": "C28489"},
               {"label": "Jouy-aux-Arches", "value": "C28490"},
               {"label": "Kaltenhouse", "value": "C28491"},
               {"label": "Kaysersberg", "value": "C28492"},
               {"label": "Kedange-sur-Canner", "value": "C28493"},
               {"label": "Kembs", "value": "C28494"},
               {"label": "Kerbach", "value": "C28495"},
               {"label": "Kertzfeld", "value": "C28496"},
               {"label": "Keskastel", "value": "C28497"},
               {"label": "Kilstett", "value": "C28498"},
               {"label": "Kingersheim", "value": "C28499"},
               {"label": "Kintzheim", "value": "C28500"},
               {"label": "Knutange", "value": "C28501"},
               {"label": "Koenigsmacker", "value": "C28502"},
               {"label": "Krautergersheim", "value": "C28503"},
               {"label": "Kruth", "value": "C28504"},
               {"label": "Kunheim", "value": "C28505"},
               {"label": "Kuntzig", "value": "C28506"},
               {"label": "La Bresse", "value": "C28507"},
               {"label": "La Broque", "value": "C28508"},
               {"label": "La Chapelle-Saint-Luc", "value": "C28509"},
               {"label": "La Francheville", "value": "C28510"},
               {"label": "La Riviere-de-Corps", "value": "C28511"},
               {"label": "La Wantzenau", "value": "C28512"},
               {"label": "Labaroche", "value": "C28513"},
               {"label": "Labry", "value": "C28514"},
               {"label": "Lamarche", "value": "C28515"},
               {"label": "Lampertheim", "value": "C28516"},
               {"label": "Landres", "value": "C28517"},
               {"label": "Landser", "value": "C28518"},
               {"label": "Laneuveville-devant-Nancy", "value": "C28519"},
               {"label": "Langres", "value": "C28520"},
               {"label": "Lapoutroie", "value": "C28521"},
               {"label": "Lautenbach", "value": "C28522"},
               {"label": "Lautenbachzell", "value": "C28523"},
               {"label": "Lauterbourg", "value": "C28524"},
               {"label": "Laxou", "value": "C28525"},
               {"label": "Lay-Saint-Christophe", "value": "C28526"},
               {"label": "Le Ban Saint-Martin", "value": "C28527"},
               {"label": "Le Menil", "value": "C28528"},
               {"label": "Le Mesnil-sur-Oger", "value": "C28529"},
               {"label": "Le Syndicat", "value": "C28530"},
               {"label": "Le Thillot", "value": "C28531"},
               {"label": "Le Tholy", "value": "C28532"},
               {"label": "Lembach", "value": "C28533"},
               {"label": "Lemberg", "value": "C28534"},
               {"label": "Lerouville", "value": "C28535"},
               {"label": "Les Forges", "value": "C28536"},
               {"label": "Les Hautes-Rivieres", "value": "C28537"},
               {"label": "Les Noes-pres-Troyes", "value": "C28538"},
               {"label": "Les Riceys", "value": "C28539"},
               {"label": "Lexy", "value": "C28540"},
               {"label": "Leymen", "value": "C28541"},
               {"label": "Liepvre", "value": "C28542"},
               {"label": "Liffol-le-Grand", "value": "C28543"},
               {"label": "Ligny-en-Barrois", "value": "C28544"},
               {"label": "Lingolsheim", "value": "C28545"},
               {"label": "Lipsheim", "value": "C28546"},
               {"label": "Liverdun", "value": "C28547"},
               {"label": "Loivre", "value": "C28548"},
               {"label": "Longeville-en-Barrois", "value": "C28549"},
               {"label": "Longeville-les-Metz", "value": "C28550"},
               {"label": "Longeville-les-Saint-Avold", "value": "C28551"},
               {"label": "Longlaville", "value": "C28552"},
               {"label": "Longuyon", "value": "C28553"},
               {"label": "Longwy", "value": "C28554"},
               {"label": "Lorquin", "value": "C28555"},
               {"label": "Ludres", "value": "C28556"},
               {"label": "Lumes", "value": "C28557"},
               {"label": "Luneville", "value": "C28558"},
               {"label": "Lusigny-sur-Barse", "value": "C28559"},
               {"label": "Lutterbach", "value": "C28560"},
               {"label": "Lutzelhouse", "value": "C28561"},
               {"label": "Macheren", "value": "C28562"},
               {"label": "Magenta", "value": "C28563"},
               {"label": "Maidieres", "value": "C28564"},
               {"label": "Mailly-le-Camp", "value": "C28565"},
               {"label": "Maizieres-la-Grande-Paroisse", "value": "C28566"},
               {"label": "Maizieres-les-Metz", "value": "C28567"},
               {"label": "Malzeville", "value": "C28568"},
               {"label": "Mancieulles", "value": "C28569"},
               {"label": "Manom", "value": "C28570"},
               {"label": "Marange-Silvange", "value": "C28571"},
               {"label": "Marbache", "value": "C28572"},
               {"label": "Marckolsheim", "value": "C28573"},
               {"label": "Mardeuil", "value": "C28574"},
               {"label": "Mareuil-le-Port", "value": "C28575"},
               {"label": "Mareuil-sur-Ay", "value": "C28576"},
               {"label": "Marigny-le-Chatel", "value": "C28577"},
               {"label": "Marlenheim", "value": "C28578"},
               {"label": "Marly", "value": "C28579"},
               {"label": "Marmoutier", "value": "C28580"},
               {"label": "Marne", "value": "C28581"},
               {"label": "Masevaux", "value": "C28582"},
               {"label": "Matzenheim", "value": "C28583"},
               {"label": "Maxeville", "value": "C28584"},
               {"label": "Mecleuves", "value": "C28585"},
               {"label": "Meistratzheim", "value": "C28586"},
               {"label": "Mercy-le-Bas", "value": "C28587"},
               {"label": "Mereville", "value": "C28588"},
               {"label": "Merten", "value": "C28589"},
               {"label": "Mertzwiller", "value": "C28590"},
               {"label": "Merxheim", "value": "C28591"},
               {"label": "Mery-sur-Seine", "value": "C28592"},
               {"label": "Messein", "value": "C28593"},
               {"label": "Metz", "value": "C28594"},
               {"label": "Metzeral", "value": "C28595"},
               {"label": "Metzervisse", "value": "C28596"},
               {"label": "Meurthe-et-Moselle", "value": "C28597"},
               {"label": "Meuse", "value": "C28598"},
               {"label": "Mexy", "value": "C28599"},
               {"label": "Meyenheim", "value": "C28600"},
               {"label": "Mirecourt", "value": "C28601"},
               {"label": "Mittelhausbergen", "value": "C28602"},
               {"label": "Molsheim", "value": "C28603"},
               {"label": "Mommenheim", "value": "C28604"},
               {"label": "Mondelange", "value": "C28605"},
               {"label": "Monswiller", "value": "C28606"},
               {"label": "Mont-Saint-Martin", "value": "C28607"},
               {"label": "Montauville", "value": "C28608"},
               {"label": "Montbronn", "value": "C28609"},
               {"label": "Montcy-Notre-Dame", "value": "C28610"},
               {"label": "Montherme", "value": "C28611"},
               {"label": "Montier-en-Der", "value": "C28612"},
               {"label": "Montigny-les-Metz", "value": "C28613"},
               {"label": "Montmedy", "value": "C28614"},
               {"label": "Montmirail", "value": "C28615"},
               {"label": "Montois-la-Montagne", "value": "C28616"},
               {"label": "Moosch", "value": "C28617"},
               {"label": "Morhange", "value": "C28618"},
               {"label": "Morsbach", "value": "C28619"},
               {"label": "Morschwiller-le-Bas", "value": "C28620"},
               {"label": "Moselle", "value": "C28621"},
               {"label": "Moulins-les-Metz", "value": "C28622"},
               {"label": "Mourmelon-le-Grand", "value": "C28623"},
               {"label": "Moutiers", "value": "C28624"},
               {"label": "Mouzon", "value": "C28625"},
               {"label": "Moyenmoutier", "value": "C28626"},
               {"label": "Moyeuvre-Grande", "value": "C28627"},
               {"label": "Muizon", "value": "C28628"},
               {"label": "Mulhouse", "value": "C28629"},
               {"label": "Munchhouse", "value": "C28630"},
               {"label": "Mundolsheim", "value": "C28631"},
               {"label": "Munster", "value": "C28632"},
               {"label": "Mussig", "value": "C28633"},
               {"label": "Mussy-sur-Seine", "value": "C28634"},
               {"label": "Muttersholtz", "value": "C28635"},
               {"label": "Mutzig", "value": "C28636"},
               {"label": "Nancy", "value": "C28637"},
               {"label": "Neuf-Brisach", "value": "C28638"},
               {"label": "Neufchateau", "value": "C28639"},
               {"label": "Neufchef", "value": "C28640"},
               {"label": "Neufgrange", "value": "C28641"},
               {"label": "Neufmanil", "value": "C28642"},
               {"label": "Neuves-Maisons", "value": "C28643"},
               {"label": "Neuwiller-les-Saverne", "value": "C28644"},
               {"label": "Niderviller", "value": "C28645"},
               {"label": "Niederbronn-les-Bains", "value": "C28646"},
               {"label": "Niederhaslach", "value": "C28647"},
               {"label": "Niederhausbergen", "value": "C28648"},
               {"label": "Niedernai", "value": "C28649"},
               {"label": "Niederschaeffolsheim", "value": "C28650"},
               {"label": "Nilvange", "value": "C28651"},
               {"label": "Nogent-le-Bas", "value": "C28652"},
               {"label": "Nogent-sur-Seine", "value": "C28653"},
               {"label": "Nomeny", "value": "C28654"},
               {"label": "Nomexy", "value": "C28655"},
               {"label": "Nordhouse", "value": "C28656"},
               {"label": "Norroy-les-Pont-a-Mousson", "value": "C28657"},
               {"label": "Nouvion-sur-Meuse", "value": "C28658"},
               {"label": "Nouzonville", "value": "C28659"},
               {"label": "Noveant-sur-Moselle", "value": "C28660"},
               {"label": "Obenheim", "value": "C28661"},
               {"label": "Oberbronn", "value": "C28662"},
               {"label": "Oberhaslach", "value": "C28663"},
               {"label": "Oberhausbergen", "value": "C28664"},
               {"label": "Oberhergheim", "value": "C28665"},
               {"label": "Oberhoffen-sur-Moder", "value": "C28666"},
               {"label": "Obermodern-Zutzendorf", "value": "C28667"},
               {"label": "Obernai", "value": "C28668"},
               {"label": "Oberschaeffolsheim", "value": "C28669"},
               {"label": "Oderen", "value": "C28670"},
               {"label": "Oermingen", "value": "C28671"},
               {"label": "OEting", "value": "C28672"},
               {"label": "Offenheim", "value": "C28673"},
               {"label": "Ohlungen", "value": "C28674"},
               {"label": "Orbey", "value": "C28675"},
               {"label": "Ostheim", "value": "C28676"},
               {"label": "Ostwald", "value": "C28677"},
               {"label": "Ottange", "value": "C28678"},
               {"label": "Otterswiller", "value": "C28679"},
               {"label": "Ottmarsheim", "value": "C28680"},
               {"label": "Ottrott", "value": "C28681"},
               {"label": "Pagny-sur-Moselle", "value": "C28682"},
               {"label": "Pargny-sur-Saulx", "value": "C28683"},
               {"label": "Payns", "value": "C28684"},
               {"label": "Peltre", "value": "C28685"},
               {"label": "Petit-Rederching", "value": "C28686"},
               {"label": "Petite-Rosselle", "value": "C28687"},
               {"label": "Pfaffenheim", "value": "C28688"},
               {"label": "Pfaffenhoffen", "value": "C28689"},
               {"label": "Pfastatt", "value": "C28690"},
               {"label": "Pfetterhouse", "value": "C28691"},
               {"label": "Pfulgriesheim", "value": "C28692"},
               {"label": "Phalsbourg", "value": "C28693"},
               {"label": "Piennes", "value": "C28694"},
               {"label": "Pierrepont", "value": "C28695"},
               {"label": "Pierrevillers", "value": "C28696"},
               {"label": "Pierry", "value": "C28697"},
               {"label": "Piney", "value": "C28698"},
               {"label": "Plainfaing", "value": "C28699"},
               {"label": "Plappeville", "value": "C28700"},
               {"label": "Plobsheim", "value": "C28701"},
               {"label": "Plombieres-les-Bains", "value": "C28702"},
               {"label": "Pompey", "value": "C28703"},
               {"label": "Pont-a-Mousson", "value": "C28704"},
               {"label": "Pont-Saint-Vincent", "value": "C28705"},
               {"label": "Pont-Sainte-Marie", "value": "C28706"},
               {"label": "Pontfaverger-Moronvilliers", "value": "C28707"},
               {"label": "Porcelette", "value": "C28708"},
               {"label": "Port a Binson", "value": "C28709"},
               {"label": "Portieux", "value": "C28710"},
               {"label": "Pouru-Saint-Remy", "value": "C28711"},
               {"label": "Pouxeux", "value": "C28712"},
               {"label": "Prix-les-Mezieres", "value": "C28713"},
               {"label": "Pulligny", "value": "C28714"},
               {"label": "Pulnoy", "value": "C28715"},
               {"label": "Pulversheim", "value": "C28716"},
               {"label": "Raedersheim", "value": "C28717"},
               {"label": "Rambervillers", "value": "C28718"},
               {"label": "Ramonchamp", "value": "C28719"},
               {"label": "Raon-aux-Bois", "value": "C28720"},
               {"label": "Reding", "value": "C28721"},
               {"label": "Reguisheim", "value": "C28722"},
               {"label": "Rehon", "value": "C28723"},
               {"label": "Reichshoffen", "value": "C28724"},
               {"label": "Reichstett", "value": "C28725"},
               {"label": "Reims", "value": "C28726"},
               {"label": "Reiningue", "value": "C28727"},
               {"label": "Remelfing", "value": "C28728"},
               {"label": "Remilly", "value": "C28729"},
               {"label": "Remiremont", "value": "C28730"},
               {"label": "Renwez", "value": "C28731"},
               {"label": "Rethel", "value": "C28732"},
               {"label": "Retonfey", "value": "C28733"},
               {"label": "Revigny-sur-Ornain", "value": "C28734"},
               {"label": "Revin", "value": "C28735"},
               {"label": "Rhinau", "value": "C28736"},
               {"label": "Ribeauville", "value": "C28737"},
               {"label": "Richardmenil", "value": "C28738"},
               {"label": "Richemont", "value": "C28739"},
               {"label": "Richwiller", "value": "C28740"},
               {"label": "Riedisheim", "value": "C28741"},
               {"label": "Rilly-la-Montagne", "value": "C28742"},
               {"label": "Rimogne", "value": "C28743"},
               {"label": "Riquewihr", "value": "C28744"},
               {"label": "Rixheim", "value": "C28745"},
               {"label": "Rocroi", "value": "C28746"},
               {"label": "Roeschwoog", "value": "C28747"},
               {"label": "Rohrbach-les-Bitche", "value": "C28748"},
               {"label": "Rolampont", "value": "C28749"},
               {"label": "Romanswiller", "value": "C28750"},
               {"label": "Rombas", "value": "C28751"},
               {"label": "Romilly-sur-Seine", "value": "C28752"},
               {"label": "Rosenau", "value": "C28753"},
               {"label": "Rosheim", "value": "C28754"},
               {"label": "Rosieres-aux-Salines", "value": "C28755"},
               {"label": "Rosieres-pres-Troyes", "value": "C28756"},
               {"label": "Rosselange", "value": "C28757"},
               {"label": "Rothau", "value": "C28758"},
               {"label": "Rouffach", "value": "C28759"},
               {"label": "Rouhling", "value": "C28760"},
               {"label": "Rozerieulles", "value": "C28761"},
               {"label": "Ruelisheim", "value": "C28762"},
               {"label": "Rumersheim-le-Haut", "value": "C28763"},
               {"label": "Rupt-sur-Moselle", "value": "C28764"},
               {"label": "Rurange-les-Thionville", "value": "C28765"},
               {"label": "Russ", "value": "C28766"},
               {"label": "Russange", "value": "C28767"},
               {"label": "Saint-Amarin", "value": "C28768"},
               {"label": "Saint-Ame", "value": "C28769"},
               {"label": "Saint-Andre-les-Vergers", "value": "C28770"},
               {"label": "Saint-Avold", "value": "C28771"},
               {"label": "Saint-Brice-Courcelles", "value": "C28772"},
               {"label": "Saint-Die-des-Vosges", "value": "C28773"},
               {"label": "Saint-Dizier", "value": "C28774"},
               {"label": "Saint-etienne-les-Remiremont", "value": "C28775"},
               {"label": "Saint-Germain", "value": "C28776"},
               {"label": "Saint-Hippolyte", "value": "C28777"},
               {"label": "Saint-Julien-les-Metz", "value": "C28778"},
               {"label": "Saint-Julien-les-Villas", "value": "C28779"},
               {"label": "Saint-Just-Sauvage", "value": "C28780"},
               {"label": "Saint-Laurent", "value": "C28781"},
               {"label": "Saint-Leonard", "value": "C28782"},
               {"label": "Saint-Louis", "value": "C28783"},
               {"label": "Saint-Lye", "value": "C28784"},
               {"label": "Saint-Maurice-sur-Moselle", "value": "C28785"},
               {"label": "Saint-Max", "value": "C28786"},
               {"label": "Saint-Memmie", "value": "C28787"},
               {"label": "Saint-Menges", "value": "C28788"},
               {"label": "Saint-Michel-sur-Meurthe", "value": "C28789"},
               {"label": "Saint-Mihiel", "value": "C28790"},
               {"label": "Saint-Nabord", "value": "C28791"},
               {"label": "Saint-Nicolas-de-Port", "value": "C28792"},
               {"label": "Saint-Parres-aux-Tertres", "value": "C28793"},
               {"label": "Saint-Pouange", "value": "C28794"},
               {"label": "Saint-Privat-la-Montagne", "value": "C28795"},
               {"label": "Sainte-Croix-aux-Mines", "value": "C28796"},
               {"label": "Sainte-Croix-en-Plaine", "value": "C28797"},
               {"label": "Sainte-Marguerite", "value": "C28798"},
               {"label": "Sainte-Marie-aux-Chenes", "value": "C28799"},
               {"label": "Sainte-Marie-aux-Mines", "value": "C28800"},
               {"label": "Sainte-Maure", "value": "C28801"},
               {"label": "Sainte-Menehould", "value": "C28802"},
               {"label": "Sainte-Savine", "value": "C28803"},
               {"label": "Saizerais", "value": "C28804"},
               {"label": "Sand", "value": "C28805"},
               {"label": "Sarralbe", "value": "C28806"},
               {"label": "Sarre-Union", "value": "C28807"},
               {"label": "Sarrebourg", "value": "C28808"},
               {"label": "Sarreguemines", "value": "C28809"},
               {"label": "Sarreinsming", "value": "C28810"},
               {"label": "Sarrewerden", "value": "C28811"},
               {"label": "Sarry", "value": "C28812"},
               {"label": "Saulcy-sur-Meurthe", "value": "C28813"},
               {"label": "Saulnes", "value": "C28814"},
               {"label": "Saulny", "value": "C28815"},
               {"label": "Sault-les-Rethel", "value": "C28816"},
               {"label": "Saulxures-les-Nancy", "value": "C28817"},
               {"label": "Saulxures-sur-Moselotte", "value": "C28818"},
               {"label": "Sausheim", "value": "C28819"},
               {"label": "Saverne", "value": "C28820"},
               {"label": "Scherwiller", "value": "C28821"},
               {"label": "Schiltigheim", "value": "C28822"},
               {"label": "Schirmeck", "value": "C28823"},
               {"label": "Schirrhein", "value": "C28824"},
               {"label": "Schnersheim", "value": "C28825"},
               {"label": "Schoeneck", "value": "C28826"},
               {"label": "Schweighouse-sur-Moder", "value": "C28827"},
               {"label": "Schwindratzheim", "value": "C28828"},
               {"label": "Scy-Chazelles", "value": "C28829"},
               {"label": "Sedan", "value": "C28830"},
               {"label": "Seichamps", "value": "C28831"},
               {"label": "Seingbouse", "value": "C28832"},
               {"label": "Selestat", "value": "C28833"},
               {"label": "Seltz", "value": "C28834"},
               {"label": "Senones", "value": "C28835"},
               {"label": "Sentheim", "value": "C28836"},
               {"label": "Seremange-Erzange", "value": "C28837"},
               {"label": "Sermaize-les-Bains", "value": "C28838"},
               {"label": "Sessenheim", "value": "C28839"},
               {"label": "Sezanne", "value": "C28840"},
               {"label": "Sierck-les-Bains", "value": "C28841"},
               {"label": "Sierentz", "value": "C28842"},
               {"label": "Signy-le-Petit", "value": "C28843"},
               {"label": "Sigolsheim", "value": "C28844"},
               {"label": "Sillery", "value": "C28845"},
               {"label": "Solgne", "value": "C28846"},
               {"label": "Soucht", "value": "C28847"},
               {"label": "Souffelweyersheim", "value": "C28848"},
               {"label": "Soufflenheim", "value": "C28849"},
               {"label": "Soultz-Haut-Rhin", "value": "C28850"},
               {"label": "Soultz-sous-Forets", "value": "C28851"},
               {"label": "Soultzeren", "value": "C28852"},
               {"label": "Soultzmatt", "value": "C28853"},
               {"label": "Spicheren", "value": "C28854"},
               {"label": "Staffelfelden", "value": "C28855"},
               {"label": "Steinbach", "value": "C28856"},
               {"label": "Steinbourg", "value": "C28857"},
               {"label": "Stenay", "value": "C28858"},
               {"label": "Still", "value": "C28859"},
               {"label": "Stiring-Wendel", "value": "C28860"},
               {"label": "Stosswihr", "value": "C28861"},
               {"label": "Stotzheim", "value": "C28862"},
               {"label": "Strasbourg", "value": "C28863"},
               {"label": "Suippes", "value": "C28864"},
               {"label": "Sundhoffen", "value": "C28865"},
               {"label": "Sundhouse", "value": "C28866"},
               {"label": "Surbourg", "value": "C28867"},
               {"label": "Taintrux", "value": "C28868"},
               {"label": "Taissy", "value": "C28869"},
               {"label": "Talange", "value": "C28870"},
               {"label": "Tenteling", "value": "C28871"},
               {"label": "Terville", "value": "C28872"},
               {"label": "Teting-sur-Nied", "value": "C28873"},
               {"label": "Thann", "value": "C28874"},
               {"label": "Thaon-les-Vosges", "value": "C28875"},
               {"label": "Theding", "value": "C28876"},
               {"label": "Thierville-sur-Meuse", "value": "C28877"},
               {"label": "Thil", "value": "C28878"},
               {"label": "Thilay", "value": "C28879"},
               {"label": "Thionville", "value": "C28880"},
               {"label": "Tinqueux", "value": "C28881"},
               {"label": "Tomblaine", "value": "C28882"},
               {"label": "Toul", "value": "C28883"},
               {"label": "Tournes", "value": "C28884"},
               {"label": "Tours-sur-Marne", "value": "C28885"},
               {"label": "Tremery", "value": "C28886"},
               {"label": "Tressange", "value": "C28887"},
               {"label": "Trieux", "value": "C28888"},
               {"label": "Tronville-en-Barrois", "value": "C28889"},
               {"label": "Troyes", "value": "C28890"},
               {"label": "Truchtersheim", "value": "C28891"},
               {"label": "Tucquegnieux", "value": "C28892"},
               {"label": "Turckheim", "value": "C28893"},
               {"label": "Uberach", "value": "C28894"},
               {"label": "Uckange", "value": "C28895"},
               {"label": "Uffholtz", "value": "C28896"},
               {"label": "Ungersheim", "value": "C28897"},
               {"label": "Urimenil", "value": "C28898"},
               {"label": "Urmatt", "value": "C28899"},
               {"label": "Uxegney", "value": "C28900"},
               {"label": "Uzemain", "value": "C28901"},
               {"label": "Vacon", "value": "C28902"},
               {"label": "Vagney", "value": "C28903"},
               {"label": "Val-de-Meuse", "value": "C28904"},
               {"label": "Valff", "value": "C28905"},
               {"label": "Valleroy", "value": "C28906"},
               {"label": "Valmont", "value": "C28907"},
               {"label": "Vandoeuvre-les-Nancy", "value": "C28908"},
               {"label": "Varangeville", "value": "C28909"},
               {"label": "Vaucouleurs", "value": "C28910"},
               {"label": "Vecoux", "value": "C28911"},
               {"label": "Velaine-en-Haye", "value": "C28912"},
               {"label": "Velaines", "value": "C28913"},
               {"label": "Vendenheim", "value": "C28914"},
               {"label": "Vendeuvre-sur-Barse", "value": "C28915"},
               {"label": "Verdun", "value": "C28916"},
               {"label": "Verny", "value": "C28917"},
               {"label": "Verrieres", "value": "C28918"},
               {"label": "Vertus", "value": "C28919"},
               {"label": "Verzenay", "value": "C28920"},
               {"label": "Verzy", "value": "C28921"},
               {"label": "Vezelise", "value": "C28922"},
               {"label": "Vic-sur-Seille", "value": "C28923"},
               {"label": "Vieux-Thann", "value": "C28924"},
               {"label": "Vigneulles-les-Hattonchatel", "value": "C28925"},
               {"label": "Vignot", "value": "C28926"},
               {"label": "Vigy", "value": "C28927"},
               {"label": "Village-Neuf", "value": "C28928"},
               {"label": "Ville", "value": "C28929"},
               {"label": "Ville-sous-la-Ferte", "value": "C28930"},
               {"label": "Villenauxe-la-Grande", "value": "C28931"},
               {"label": "Villers-la-Montagne", "value": "C28932"},
               {"label": "Villers-les-Nancy", "value": "C28933"},
               {"label": "Villers-Semeuse", "value": "C28934"},
               {"label": "Villerupt", "value": "C28935"},
               {"label": "Villey-Saint-etienne", "value": "C28936"},
               {"label": "Villiers-en-Lieu", "value": "C28937"},
               {"label": "Vincey", "value": "C28938"},
               {"label": "Vireux-Molhain", "value": "C28939"},
               {"label": "Vireux-Wallerand", "value": "C28940"},
               {"label": "Vitry-le-Francois", "value": "C28941"},
               {"label": "Vitry-sur-Orne", "value": "C28942"},
               {"label": "Vittel", "value": "C28943"},
               {"label": "Vivier-au-Court", "value": "C28944"},
               {"label": "Void-Vacon", "value": "C28945"},
               {"label": "Volgelsheim", "value": "C28946"},
               {"label": "Volmerange-les-Mines", "value": "C28947"},
               {"label": "Volstroff", "value": "C28948"},
               {"label": "Vosges", "value": "C28949"},
               {"label": "Vouziers", "value": "C28950"},
               {"label": "Vrigne-aux-Bois", "value": "C28951"},
               {"label": "Walbourg", "value": "C28952"},
               {"label": "Waldighofen", "value": "C28953"},
               {"label": "Walscheid", "value": "C28954"},
               {"label": "Wangenbourg-Engenthal", "value": "C28955"},
               {"label": "Warcq", "value": "C28956"},
               {"label": "Warmeriville", "value": "C28957"},
               {"label": "Wasselonne", "value": "C28958"},
               {"label": "Wassy", "value": "C28959"},
               {"label": "Wattwiller", "value": "C28960"},
               {"label": "Weitbruch", "value": "C28961"},
               {"label": "Westhoffen", "value": "C28962"},
               {"label": "Wettolsheim", "value": "C28963"},
               {"label": "Weyersheim", "value": "C28964"},
               {"label": "Widensolen", "value": "C28965"},
               {"label": "Wiesviller", "value": "C28966"},
               {"label": "Wihr-au-Val", "value": "C28967"},
               {"label": "Willerwald", "value": "C28968"},
               {"label": "Wimmenau", "value": "C28969"},
               {"label": "Wingen-sur-Moder", "value": "C28970"},
               {"label": "Wingersheim", "value": "C28971"},
               {"label": "Wintzenheim", "value": "C28972"},
               {"label": "Wisches", "value": "C28973"},
               {"label": "Wissembourg", "value": "C28974"},
               {"label": "Witry-les-Reims", "value": "C28975"},
               {"label": "Wittelsheim", "value": "C28976"},
               {"label": "Wittenheim", "value": "C28977"},
               {"label": "Wittisheim", "value": "C28978"},
               {"label": "Woerth", "value": "C28979"},
               {"label": "Woippy", "value": "C28980"},
               {"label": "Wolfgantzen", "value": "C28981"},
               {"label": "Wolfisheim", "value": "C28982"},
               {"label": "Woustviller", "value": "C28983"},
               {"label": "Xertigny", "value": "C28984"},
               {"label": "Xonrupt-Longemer", "value": "C28985"},
               {"label": "Yutz", "value": "C28986"},
               {"label": "Zillisheim", "value": "C28987"},
               {"label": "Zimmersheim", "value": "C28988"}],
 "GrandAnse": [{"label": "Anse-a-Veau", "value": "C43098"},
               {"label": "Chambellan", "value": "C43099"},
               {"label": "Corail", "value": "C43100"},
               {"label": "Dame-Marie", "value": "C43101"},
               {"label": "Jeremi", "value": "C43102"},
               {"label": "Jeremie", "value": "C43103"},
               {"label": "Les Abricots", "value": "C43104"},
               {"label": "Les Irois", "value": "C43105"},
               {"label": "Moron", "value": "C43106"},
               {"label": "Petite Riviere de Nippes", "value": "C43107"}],
 "Grande Comore": [{"label": "Bahani", "value": "C20699"},
                   {"label": "Bambadjani", "value": "C20700"},
                   {"label": "Bouni", "value": "C20701"},
                   {"label": "Chezani", "value": "C20702"},
                   {"label": "Chindini", "value": "C20703"},
                   {"label": "Chouani", "value": "C20704"},
                   {"label": "Dembeni", "value": "C20705"},
                   {"label": "Douniani", "value": "C20706"},
                   {"label": "Dzahadjou", "value": "C20707"},
                   {"label": "Foumbouni", "value": "C20708"},
                   {"label": "Hantsindzi", "value": "C20709"},
                   {"label": "Heroumbili", "value": "C20710"},
                   {"label": "Itsandra", "value": "C20711"},
                   {"label": "Itsandzeni", "value": "C20712"},
                   {"label": "Ivouani", "value": "C20713"},
                   {"label": "Koua", "value": "C20714"},
                   {"label": "Madjeoueni", "value": "C20715"},
                   {"label": "Mandza", "value": "C20716"},
                   {"label": "Mavingouni", "value": "C20717"},
                   {"label": "Mbeni", "value": "C20718"},
                   {"label": "Mitsamiouli", "value": "C20719"},
                   {"label": "Mitsoudje", "value": "C20720"},
                   {"label": "Mnoungou", "value": "C20721"},
                   {"label": "Mohoro", "value": "C20722"},
                   {"label": "Moroni", "value": "C20723"},
                   {"label": "Mtsamdou", "value": "C20724"},
                   {"label": "Mvouni", "value": "C20725"},
                   {"label": "Nioumamilima", "value": "C20726"},
                   {"label": "Ntsaoueni", "value": "C20727"},
                   {"label": "Ntsoudjini", "value": "C20728"},
                   {"label": "Ouellah", "value": "C20729"},
                   {"label": "Ouhozi", "value": "C20730"},
                   {"label": "Ouroveni", "value": "C20731"},
                   {"label": "Oussivo", "value": "C20732"},
                   {"label": "Salimani", "value": "C20733"},
                   {"label": "Selea", "value": "C20734"},
                   {"label": "Singani", "value": "C20735"},
                   {"label": "Tsidje", "value": "C20736"},
                   {"label": "Vanadjou", "value": "C20737"},
                   {"label": "Vanambouani", "value": "C20738"}],
 "Granma Province": [{"label": "Bartolome Maso", "value": "C21721"},
                     {"label": "Bayamo", "value": "C21722"},
                     {"label": "Campechuela", "value": "C21723"},
                     {"label": "Cauto Cristo", "value": "C21724"},
                     {"label": "Guisa", "value": "C21725"},
                     {"label": "Jiguani", "value": "C21726"},
                     {"label": "Manzanillo", "value": "C21727"},
                     {"label": "Media Luna", "value": "C21728"},
                     {"label": "Municipio de Bayamo", "value": "C21729"},
                     {"label": "Municipio de Manzanillo", "value": "C21730"},
                     {"label": "Municipio de Niquero", "value": "C21731"},
                     {"label": "Niquero", "value": "C21732"},
                     {"label": "Rio Cauto", "value": "C21733"},
                     {"label": "Yara", "value": "C21734"}],
 "Graubunden": [{"label": "Arosa", "value": "C116753"},
                {"label": "Arvigo", "value": "C116754"},
                {"label": "Bonaduz", "value": "C116755"},
                {"label": "Breil", "value": "C116756"},
                {"label": "Brusio", "value": "C116757"},
                {"label": "Buseno", "value": "C116758"},
                {"label": "Cauco", "value": "C116759"},
                {"label": "Cazis", "value": "C116760"},
                {"label": "Celerina", "value": "C116761"},
                {"label": "Chur", "value": "C116762"},
                {"label": "Churwalden", "value": "C116763"},
                {"label": "Davos", "value": "C116764"},
                {"label": "Disentis", "value": "C116765"},
                {"label": "Domat", "value": "C116766"},
                {"label": "Felsberg", "value": "C116767"},
                {"label": "Flims", "value": "C116768"},
                {"label": "Grusch", "value": "C116769"},
                {"label": "Hinterrhein", "value": "C116770"},
                {"label": "Igis", "value": "C116771"},
                {"label": "Ilanz", "value": "C116772"},
                {"label": "Jenaz", "value": "C116773"},
                {"label": "Klosters Serneus", "value": "C116774"},
                {"label": "La Punt Chamues-ch", "value": "C116775"},
                {"label": "Laax", "value": "C116776"},
                {"label": "Landquart", "value": "C116777"},
                {"label": "Lenzerheide", "value": "C116778"},
                {"label": "Luzein", "value": "C116779"},
                {"label": "Maienfeld", "value": "C116780"},
                {"label": "Malans", "value": "C116781"},
                {"label": "Mesocco", "value": "C116782"},
                {"label": "Pontresina", "value": "C116783"},
                {"label": "Poschiavo", "value": "C116784"},
                {"label": "Region Albula", "value": "C116785"},
                {"label": "Region Bernina", "value": "C116786"},
                {"label": "Region Engiadina Bassa  Val Mustair",
                 "value": "C116787"},
                {"label": "Region Imboden", "value": "C116788"},
                {"label": "Region Landquart", "value": "C116789"},
                {"label": "Region Maloja", "value": "C116790"},
                {"label": "Region Moesa", "value": "C116791"},
                {"label": "Region Plessur", "value": "C116792"},
                {"label": "Region Prattigau  Davos", "value": "C116793"},
                {"label": "Region Surselva", "value": "C116794"},
                {"label": "Region Viamala", "value": "C116795"},
                {"label": "Rhazuns", "value": "C116796"},
                {"label": "Samedan", "value": "C116797"},
                {"label": "Scharans", "value": "C116798"},
                {"label": "Schiers", "value": "C116799"},
                {"label": "Scuol", "value": "C116800"},
                {"label": "Silvaplana", "value": "C116801"},
                {"label": "St. Moritz", "value": "C116802"},
                {"label": "Tamins", "value": "C116803"},
                {"label": "Thusis", "value": "C116804"},
                {"label": "Tiefencastel", "value": "C116805"},
                {"label": "Trimmis", "value": "C116806"},
                {"label": "Trun", "value": "C116807"},
                {"label": "Untervaz", "value": "C116808"},
                {"label": "Vals Platz", "value": "C116809"},
                {"label": "Zernez", "value": "C116810"},
                {"label": "Zizers", "value": "C116811"},
                {"label": "Zuoz", "value": "C116812"}],
 "Greater Accra": [{"label": "Accra", "value": "C41521"},
                   {"label": "Atsiaman", "value": "C41522"},
                   {"label": "Dome", "value": "C41523"},
                   {"label": "Gbawe", "value": "C41524"},
                   {"label": "Medina Estates", "value": "C41525"},
                   {"label": "Nungua", "value": "C41526"},
                   {"label": "Tema", "value": "C41527"},
                   {"label": "Teshi Old Town", "value": "C41528"}],
 "Greater Poland Voivodeship": [{"label": "Babiak", "value": "C87630"},
                                {"label": "Baranow", "value": "C87631"},
                                {"label": "Baranowo", "value": "C87632"},
                                {"label": "Bialosliwie", "value": "C87633"},
                                {"label": "Blizanow", "value": "C87634"},
                                {"label": "Bojanowo", "value": "C87635"},
                                {"label": "Bojanowo Stare", "value": "C87636"},
                                {"label": "Borek Wielkopolski",
                                 "value": "C87637"},
                                {"label": "Bralin", "value": "C87638"},
                                {"label": "Brdow", "value": "C87639"},
                                {"label": "Brenno", "value": "C87640"},
                                {"label": "Brodnica", "value": "C87641"},
                                {"label": "Broniszewice", "value": "C87642"},
                                {"label": "Brudzew", "value": "C87643"},
                                {"label": "Budzyn", "value": "C87644"},
                                {"label": "Buk", "value": "C87645"},
                                {"label": "Chocz", "value": "C87646"},
                                {"label": "Chodow", "value": "C87647"},
                                {"label": "Chodziez", "value": "C87648"},
                                {"label": "Chrzypsko Wielkie",
                                 "value": "C87649"},
                                {"label": "Czajkow", "value": "C87650"},
                                {"label": "Czapury", "value": "C87651"},
                                {"label": "Czarnkow", "value": "C87652"},
                                {"label": "Czempin", "value": "C87653"},
                                {"label": "Czermin", "value": "C87654"},
                                {"label": "Czerniejewo", "value": "C87655"},
                                {"label": "Czerwonak", "value": "C87656"},
                                {"label": "Dabie", "value": "C87657"},
                                {"label": "Damaslawek", "value": "C87658"},
                                {"label": "Daszewice", "value": "C87659"},
                                {"label": "Dobra", "value": "C87660"},
                                {"label": "Dobrzyca", "value": "C87661"},
                                {"label": "Dolsk", "value": "C87662"},
                                {"label": "Dominowo", "value": "C87663"},
                                {"label": "Dopiewo", "value": "C87664"},
                                {"label": "Doruchow", "value": "C87665"},
                                {"label": "Drawsko", "value": "C87666"},
                                {"label": "Duszniki", "value": "C87667"},
                                {"label": "Gizalki", "value": "C87668"},
                                {"label": "Gniezno", "value": "C87669"},
                                {"label": "Golina", "value": "C87670"},
                                {"label": "Golancz", "value": "C87671"},
                                {"label": "Goluchow", "value": "C87672"},
                                {"label": "Gorzyce Wielkie", "value": "C87673"},
                                {"label": "Gostyn", "value": "C87674"},
                                {"label": "Grabow nad Prosna",
                                 "value": "C87675"},
                                {"label": "Granowo", "value": "C87676"},
                                {"label": "Grodziec", "value": "C87677"},
                                {"label": "Grodzisk Wielkopolski",
                                 "value": "C87678"},
                                {"label": "Grzegorzew", "value": "C87679"},
                                {"label": "Jablonna", "value": "C87680"},
                                {"label": "Jankow Przygodzki",
                                 "value": "C87681"},
                                {"label": "Jaraczewo", "value": "C87682"},
                                {"label": "Jarocin", "value": "C87683"},
                                {"label": "Jastrowie", "value": "C87684"},
                                {"label": "Jutrosin", "value": "C87685"},
                                {"label": "Kaczory", "value": "C87686"},
                                {"label": "Kalisz", "value": "C87687"},
                                {"label": "Kamieniec", "value": "C87688"},
                                {"label": "Kaweczyn", "value": "C87689"},
                                {"label": "Kazimierz Biskupi",
                                 "value": "C87690"},
                                {"label": "Kazmierz", "value": "C87691"},
                                {"label": "Kepno", "value": "C87692"},
                                {"label": "Kiszkowo", "value": "C87693"},
                                {"label": "Kleczew", "value": "C87694"},
                                {"label": "Kleszczewo", "value": "C87695"},
                                {"label": "Klecko", "value": "C87696"},
                                {"label": "Klodawa", "value": "C87697"},
                                {"label": "Kobyla Gora", "value": "C87698"},
                                {"label": "Kobylin", "value": "C87699"},
                                {"label": "Kobylnica", "value": "C87700"},
                                {"label": "Kolaczkowo", "value": "C87701"},
                                {"label": "Kolo", "value": "C87702"},
                                {"label": "Komorniki", "value": "C87703"},
                                {"label": "Konary", "value": "C87704"},
                                {"label": "Konin", "value": "C87705"},
                                {"label": "Kornik", "value": "C87706"},
                                {"label": "Koscian", "value": "C87707"},
                                {"label": "Koscielec", "value": "C87708"},
                                {"label": "Kostrzyn", "value": "C87709"},
                                {"label": "Kotlin", "value": "C87710"},
                                {"label": "Kozmin Wielkopolski",
                                 "value": "C87711"},
                                {"label": "Kozminek", "value": "C87712"},
                                {"label": "Krajenka", "value": "C87713"},
                                {"label": "Kramsk", "value": "C87714"},
                                {"label": "Kraszewice", "value": "C87715"},
                                {"label": "Krobia", "value": "C87716"},
                                {"label": "Krosno", "value": "C87717"},
                                {"label": "Krotoszyn", "value": "C87718"},
                                {"label": "Krzemieniewo", "value": "C87719"},
                                {"label": "Krzykosy", "value": "C87720"},
                                {"label": "Krzywin", "value": "C87721"},
                                {"label": "Krzyz Wielkopolski",
                                 "value": "C87722"},
                                {"label": "Ksiaz Wielkopolski",
                                 "value": "C87723"},
                                {"label": "Kuslin", "value": "C87724"},
                                {"label": "Kwilcz", "value": "C87725"},
                                {"label": "Ladek", "value": "C87726"},
                                {"label": "Leszno", "value": "C87727"},
                                {"label": "Lichen Stary", "value": "C87728"},
                                {"label": "Lipka", "value": "C87729"},
                                {"label": "Lipno", "value": "C87730"},
                                {"label": "Liskow", "value": "C87731"},
                                {"label": "Lubasz", "value": "C87732"},
                                {"label": "Lubon", "value": "C87733"},
                                {"label": "Lwowek", "value": "C87734"},
                                {"label": "Leka Opatowska", "value": "C87735"},
                                {"label": "Lobzenica", "value": "C87736"},
                                {"label": "Lubowo", "value": "C87737"},
                                {"label": "Malanow", "value": "C87738"},
                                {"label": "Margonin", "value": "C87739"},
                                {"label": "Miasteczko Krajenskie",
                                 "value": "C87740"},
                                {"label": "Miedzichowo", "value": "C87741"},
                                {"label": "Miedzychod", "value": "C87742"},
                                {"label": "Miejska Gorka", "value": "C87743"},
                                {"label": "Mieleszyn", "value": "C87744"},
                                {"label": "Miescisko", "value": "C87745"},
                                {"label": "Mikstat", "value": "C87746"},
                                {"label": "Miloslaw", "value": "C87747"},
                                {"label": "Mosina", "value": "C87748"},
                                {"label": "Murowana Goslina",
                                 "value": "C87749"},
                                {"label": "Nekla", "value": "C87750"},
                                {"label": "Niechanowo", "value": "C87751"},
                                {"label": "Nowe Miasto nad Warta",
                                 "value": "C87752"},
                                {"label": "Nowe Skalmierzyce",
                                 "value": "C87753"},
                                {"label": "Nowy Tomysl", "value": "C87754"},
                                {"label": "Oborniki", "value": "C87755"},
                                {"label": "Obrzycko", "value": "C87756"},
                                {"label": "Odolanow", "value": "C87757"},
                                {"label": "Okonek", "value": "C87758"},
                                {"label": "Olszowka", "value": "C87759"},
                                {"label": "Opalenica", "value": "C87760"},
                                {"label": "Opatow", "value": "C87761"},
                                {"label": "Opatowek", "value": "C87762"},
                                {"label": "Orchowo", "value": "C87763"},
                                {"label": "Osieczna", "value": "C87764"},
                                {"label": "Osiek Maly", "value": "C87765"},
                                {"label": "Osiek nad Notecia",
                                 "value": "C87766"},
                                {"label": "Ostrorog", "value": "C87767"},
                                {"label": "Ostrow Wielkopolski",
                                 "value": "C87768"},
                                {"label": "Ostrowite", "value": "C87769"},
                                {"label": "Ostrzeszow", "value": "C87770"},
                                {"label": "Pakoslaw", "value": "C87771"},
                                {"label": "Pamiatkowo", "value": "C87772"},
                                {"label": "Pecna", "value": "C87773"},
                                {"label": "Pepowo", "value": "C87774"},
                                {"label": "Perzow", "value": "C87775"},
                                {"label": "Piaski", "value": "C87776"},
                                {"label": "Pila", "value": "C87777"},
                                {"label": "Pleszew", "value": "C87778"},
                                {"label": "Plewiska", "value": "C87779"},
                                {"label": "Pniewy", "value": "C87780"},
                                {"label": "Pobiedziska", "value": "C87781"},
                                {"label": "Pogorzela", "value": "C87782"},
                                {"label": "Polajewo", "value": "C87783"},
                                {"label": "Poniec", "value": "C87784"},
                                {"label": "Powiat chodzieski",
                                 "value": "C87785"},
                                {"label": "Powiat czarnkowsko-trzcianecki",
                                 "value": "C87786"},
                                {"label": "Powiat gnieznienski",
                                 "value": "C87787"},
                                {"label": "Powiat gostynski",
                                 "value": "C87788"},
                                {"label": "Powiat grodziski",
                                 "value": "C87789"},
                                {"label": "Powiat jarocinski",
                                 "value": "C87790"},
                                {"label": "Powiat kaliski", "value": "C87791"},
                                {"label": "Powiat kepinski", "value": "C87792"},
                                {"label": "Powiat kolski", "value": "C87793"},
                                {"label": "Powiat koninski", "value": "C87794"},
                                {"label": "Powiat koscianski",
                                 "value": "C87795"},
                                {"label": "Powiat krotoszynski",
                                 "value": "C87796"},
                                {"label": "Powiat leszczynski",
                                 "value": "C87797"},
                                {"label": "Powiat miedzychodzki",
                                 "value": "C87798"},
                                {"label": "Powiat nowotomyski",
                                 "value": "C87799"},
                                {"label": "Powiat obornicki",
                                 "value": "C87800"},
                                {"label": "Powiat ostrowski",
                                 "value": "C87801"},
                                {"label": "Powiat ostrzeszowski",
                                 "value": "C87802"},
                                {"label": "Powiat pilski", "value": "C87803"},
                                {"label": "Powiat pleszewski",
                                 "value": "C87804"},
                                {"label": "Powiat poznanski",
                                 "value": "C87805"},
                                {"label": "Powiat rawicki", "value": "C87806"},
                                {"label": "Powiat slupecki", "value": "C87807"},
                                {"label": "Powiat sredzki", "value": "C87808"},
                                {"label": "Powiat sremski", "value": "C87809"},
                                {"label": "Powiat szamotulski",
                                 "value": "C87810"},
                                {"label": "Powiat turecki", "value": "C87811"},
                                {"label": "Powiat wagrowiecki",
                                 "value": "C87812"},
                                {"label": "Powiat wolsztynski",
                                 "value": "C87813"},
                                {"label": "Powiat wrzesinski",
                                 "value": "C87814"},
                                {"label": "Powiat zlotowski",
                                 "value": "C87815"},
                                {"label": "Powidz", "value": "C87816"},
                                {"label": "Poznan", "value": "C87817"},
                                {"label": "Przedecz", "value": "C87818"},
                                {"label": "Przemet", "value": "C87819"},
                                {"label": "Przygodzice", "value": "C87820"},
                                {"label": "Przykona", "value": "C87821"},
                                {"label": "Puszczykowo", "value": "C87822"},
                                {"label": "Pyzdry", "value": "C87823"},
                                {"label": "Rakoniewice", "value": "C87824"},
                                {"label": "Raszkow", "value": "C87825"},
                                {"label": "Rawicz", "value": "C87826"},
                                {"label": "Rogalinek", "value": "C87827"},
                                {"label": "Rogozno", "value": "C87828"},
                                {"label": "Rokietnica", "value": "C87829"},
                                {"label": "Rozdrazew", "value": "C87830"},
                                {"label": "Rychtal", "value": "C87831"},
                                {"label": "Rychwal", "value": "C87832"},
                                {"label": "Ryczywol", "value": "C87833"},
                                {"label": "Rydzyna", "value": "C87834"},
                                {"label": "Rzgow Pierwszy", "value": "C87835"},
                                {"label": "Siedlec", "value": "C87836"},
                                {"label": "Sierakow", "value": "C87837"},
                                {"label": "Sieroszewice", "value": "C87838"},
                                {"label": "Skalmierzyce", "value": "C87839"},
                                {"label": "Skoki", "value": "C87840"},
                                {"label": "Skulsk", "value": "C87841"},
                                {"label": "Slesin", "value": "C87842"},
                                {"label": "Slupca", "value": "C87843"},
                                {"label": "Slupia pod Kepnem",
                                 "value": "C87844"},
                                {"label": "Smigiel", "value": "C87845"},
                                {"label": "Smilowo", "value": "C87846"},
                                {"label": "Sompolno", "value": "C87847"},
                                {"label": "Sosnie", "value": "C87848"},
                                {"label": "Srem", "value": "C87849"},
                                {"label": "Sroda Wielkopolska",
                                 "value": "C87850"},
                                {"label": "Stare Miasto", "value": "C87851"},
                                {"label": "Stawiszyn", "value": "C87852"},
                                {"label": "Steszew", "value": "C87853"},
                                {"label": "Strzalkowo", "value": "C87854"},
                                {"label": "Suchy Las", "value": "C87855"},
                                {"label": "Sulmierzyce", "value": "C87856"},
                                {"label": "Swarzedz", "value": "C87857"},
                                {"label": "Swieciechowa", "value": "C87858"},
                                {"label": "Sypniewo", "value": "C87859"},
                                {"label": "Szamocin", "value": "C87860"},
                                {"label": "Szamotuly", "value": "C87861"},
                                {"label": "Szydlowo", "value": "C87862"},
                                {"label": "Tarnowka", "value": "C87863"},
                                {"label": "Tarnowo Podgorne",
                                 "value": "C87864"},
                                {"label": "Trzcianka", "value": "C87865"},
                                {"label": "Trzcinica", "value": "C87866"},
                                {"label": "Trzemeszno", "value": "C87867"},
                                {"label": "Tuliszkow", "value": "C87868"},
                                {"label": "Turek", "value": "C87869"},
                                {"label": "Ujscie", "value": "C87870"},
                                {"label": "Wagrowiec", "value": "C87871"},
                                {"label": "Wapno", "value": "C87872"},
                                {"label": "Wielen", "value": "C87873"},
                                {"label": "Wielichowo", "value": "C87874"},
                                {"label": "Wierzbinek", "value": "C87875"},
                                {"label": "Wijewo", "value": "C87876"},
                                {"label": "Wilczogora", "value": "C87877"},
                                {"label": "Wilczyn", "value": "C87878"},
                                {"label": "Wilkowice", "value": "C87879"},
                                {"label": "Witaszyce", "value": "C87880"},
                                {"label": "Witkowo", "value": "C87881"},
                                {"label": "Wladyslawow", "value": "C87882"},
                                {"label": "Wloszakowice", "value": "C87883"},
                                {"label": "Wolsztyn", "value": "C87884"},
                                {"label": "Wronki", "value": "C87885"},
                                {"label": "Wrzesnia", "value": "C87886"},
                                {"label": "Wyrzysk", "value": "C87887"},
                                {"label": "Wysoka", "value": "C87888"},
                                {"label": "Zagorow", "value": "C87889"},
                                {"label": "Zakrzewo", "value": "C87890"},
                                {"label": "Zaniemysl", "value": "C87891"},
                                {"label": "Zbaszyn", "value": "C87892"},
                                {"label": "Zduny", "value": "C87893"},
                                {"label": "Zelazkow", "value": "C87894"},
                                {"label": "Zerkow", "value": "C87895"},
                                {"label": "Zlotniki", "value": "C87896"},
                                {"label": "Zlotow", "value": "C87897"}],
 "Greater Skopje": [{"label": "Bojane", "value": "C65412"},
                    {"label": "Dracevo", "value": "C65413"},
                    {"label": "Ljubin", "value": "C65414"},
                    {"label": "Saraj", "value": "C65415"},
                    {"label": "Usje", "value": "C65416"}],
 "Grenadines Parish": [{"label": "Port Elizabeth", "value": "C106219"}],
 "Grobina Municipality": [{"label": "Grobina", "value": "C64868"}],
 "Grodno Region": [{"label": "Ashmyanski Rayon", "value": "C8786"},
                   {"label": "Ashmyany", "value": "C8787"},
                   {"label": "Astravyets", "value": "C8788"},
                   {"label": "Astravyetski Rayon", "value": "C8789"},
                   {"label": "Baruny", "value": "C8790"},
                   {"label": "Byarozawka", "value": "C8791"},
                   {"label": "Dyatlovo", "value": "C8792"},
                   {"label": "Grodnenskiy Rayon", "value": "C8793"},
                   {"label": "Halshany", "value": "C8794"},
                   {"label": "Horad Hrodna", "value": "C8795"},
                   {"label": "Hrodna", "value": "C8796"},
                   {"label": "Hyeranyony", "value": "C8797"},
                   {"label": "Indura", "value": "C8798"},
                   {"label": "Iwye", "value": "C8799"},
                   {"label": "Karelichy", "value": "C8800"},
                   {"label": "Karelitski Rayon", "value": "C8801"},
                   {"label": "Krasnoselskiy", "value": "C8802"},
                   {"label": "Kreva", "value": "C8803"},
                   {"label": "Lida", "value": "C8804"},
                   {"label": "Lidski Rayon", "value": "C8805"},
                   {"label": "Lyubcha", "value": "C8806"},
                   {"label": "Mir", "value": "C8807"},
                   {"label": "Mosty", "value": "C8808"},
                   {"label": "Novogrudok", "value": "C8809"},
                   {"label": "Ross", "value": "C8810"},
                   {"label": "Sapotskin", "value": "C8811"},
                   {"label": "Shchuchyn", "value": "C8812"},
                   {"label": "Shchuchynski Rayon", "value": "C8813"},
                   {"label": "Skidel", "value": "C8814"},
                   {"label": "Slonim", "value": "C8815"},
                   {"label": "Smarhon", "value": "C8816"},
                   {"label": "Soly", "value": "C8817"},
                   {"label": "Svislach", "value": "C8818"},
                   {"label": "Vishnyeva", "value": "C8819"},
                   {"label": "Volkovysk", "value": "C8820"},
                   {"label": "Voranava", "value": "C8821"},
                   {"label": "Vyalikaya Byerastavitsa", "value": "C8822"},
                   {"label": "Zelva", "value": "C8823"},
                   {"label": "Zhaludok", "value": "C8824"},
                   {"label": "Zhirovichi", "value": "C8825"}],
 "Groningen": [{"label": "Aduard", "value": "C76496"},
               {"label": "Appingedam", "value": "C76497"},
               {"label": "Baflo", "value": "C76498"},
               {"label": "Bedum", "value": "C76499"},
               {"label": "Bellingwolde", "value": "C76500"},
               {"label": "Blijham", "value": "C76501"},
               {"label": "Coendersborg", "value": "C76502"},
               {"label": "De Wijert", "value": "C76503"},
               {"label": "Delfzijl", "value": "C76504"},
               {"label": "Eenrum", "value": "C76505"},
               {"label": "Eexta", "value": "C76506"},
               {"label": "Farmsum", "value": "C76507"},
               {"label": "Gemeente Appingedam", "value": "C76508"},
               {"label": "Gemeente Delfzijl", "value": "C76509"},
               {"label": "Gemeente Groningen", "value": "C76510"},
               {"label": "Gemeente Loppersum", "value": "C76511"},
               {"label": "Gemeente Oldambt", "value": "C76512"},
               {"label": "Gemeente Pekela", "value": "C76513"},
               {"label": "Gemeente Stadskanaal", "value": "C76514"},
               {"label": "Gemeente Veendam", "value": "C76515"},
               {"label": "Glimmen", "value": "C76516"},
               {"label": "Grijpskerk", "value": "C76517"},
               {"label": "Groningen", "value": "C76518"},
               {"label": "Grootegast", "value": "C76519"},
               {"label": "Haren", "value": "C76520"},
               {"label": "Harkstede", "value": "C76521"},
               {"label": "Heiligerlee", "value": "C76522"},
               {"label": "Helpman", "value": "C76523"},
               {"label": "Hoogezand", "value": "C76524"},
               {"label": "Korrewegwijk", "value": "C76525"},
               {"label": "Leek", "value": "C76526"},
               {"label": "Leens", "value": "C76527"},
               {"label": "Loppersum", "value": "C76528"},
               {"label": "Marum", "value": "C76529"},
               {"label": "Meeden", "value": "C76530"},
               {"label": "Middelstum", "value": "C76531"},
               {"label": "Midden-Groningen", "value": "C76532"},
               {"label": "Midwolda", "value": "C76533"},
               {"label": "Niekerk", "value": "C76534"},
               {"label": "Nieuwe Pekela", "value": "C76535"},
               {"label": "Nieuwolda", "value": "C76536"},
               {"label": "Noordbroek", "value": "C76537"},
               {"label": "Noordhorn", "value": "C76538"},
               {"label": "Obergum", "value": "C76539"},
               {"label": "Oldehove", "value": "C76540"},
               {"label": "Ommelanderwijk", "value": "C76541"},
               {"label": "Onstwedde", "value": "C76542"},
               {"label": "Oosterhoogebrug", "value": "C76543"},
               {"label": "Oosterpark", "value": "C76544"},
               {"label": "Oude Pekela", "value": "C76545"},
               {"label": "Reitdiep", "value": "C76546"},
               {"label": "Sappemeer", "value": "C76547"},
               {"label": "Scheemda", "value": "C76548"},
               {"label": "Schildwolde", "value": "C76549"},
               {"label": "Sellingen", "value": "C76550"},
               {"label": "Selwerd", "value": "C76551"},
               {"label": "Siddeburen", "value": "C76552"},
               {"label": "Slochteren", "value": "C76553"},
               {"label": "Spijk", "value": "C76554"},
               {"label": "Stadskanaal", "value": "C76555"},
               {"label": "Ten Boer", "value": "C76556"},
               {"label": "Ter Apel", "value": "C76557"},
               {"label": "Tuikwerd", "value": "C76558"},
               {"label": "Uithuizen", "value": "C76559"},
               {"label": "Uithuizermeeden", "value": "C76560"},
               {"label": "Ulrum", "value": "C76561"},
               {"label": "Usquert", "value": "C76562"},
               {"label": "Veendam", "value": "C76563"},
               {"label": "Vlagtwedde", "value": "C76564"},
               {"label": "Wagenborgen", "value": "C76565"},
               {"label": "Warffum", "value": "C76566"},
               {"label": "Westerlee", "value": "C76567"},
               {"label": "Westerwolde", "value": "C76568"},
               {"label": "Wildervank", "value": "C76569"},
               {"label": "Winschoten", "value": "C76570"},
               {"label": "Winsum", "value": "C76571"},
               {"label": "Zoutkamp", "value": "C76572"},
               {"label": "Zuidbroek", "value": "C76573"},
               {"label": "Zuidhorn", "value": "C76574"}],
 "Gros Islet Quarter": [{"label": "Beausejour", "value": "C105963"},
                        {"label": "BeausejourFostinS Development",
                         "value": "C105964"},
                        {"label": "BeausejourNdc", "value": "C105965"},
                        {"label": "Bella Rosa", "value": "C105966"},
                        {"label": "Belle Vue", "value": "C105967"},
                        {"label": "Belle Vue Estate", "value": "C105968"},
                        {"label": "Boguis", "value": "C105969"},
                        {"label": "BoguisDesa Blond", "value": "C105970"},
                        {"label": "Bois DOrange", "value": "C105971"},
                        {"label": "Bois DOrnangeTrouya", "value": "C105972"},
                        {"label": "Bonneterre", "value": "C105973"},
                        {"label": "Bonneterre Gardens", "value": "C105974"},
                        {"label": "Cap Estate", "value": "C105975"},
                        {"label": "Cap EstateBecune Point", "value": "C105976"},
                        {"label": "Cap EstateCaribbean Park",
                         "value": "C105977"},
                        {"label": "Cap EstateGolf Park", "value": "C105978"},
                        {"label": "Cap EstateLower Saline Point",
                         "value": "C105979"},
                        {"label": "Cap EstateMon Du Cap", "value": "C105980"},
                        {"label": "Cap EstateRanch Site", "value": "C105981"},
                        {"label": "Cap EstateSaddlec Back", "value": "C105982"},
                        {"label": "Cap EstateUpper Saline Point",
                         "value": "C105983"},
                        {"label": "Cas En Bas", "value": "C105984"},
                        {"label": "Caye Manje", "value": "C105985"},
                        {"label": "Corinth", "value": "C105986"},
                        {"label": "Corinth Estate", "value": "C105987"},
                        {"label": "CorinthLa Bel Lair", "value": "C105988"},
                        {"label": "Dauphin", "value": "C105989"},
                        {"label": "Des Barras", "value": "C105990"},
                        {"label": "Des BarrasCacolie", "value": "C105991"},
                        {"label": "Desrameaux", "value": "C105992"},
                        {"label": "Garrand", "value": "C105993"},
                        {"label": "Grand Riviere", "value": "C105994"},
                        {"label": "Grande RiviereAssou Canal",
                         "value": "C105995"},
                        {"label": "Grande RiviereIngle Woods",
                         "value": "C105996"},
                        {"label": "Grande RiviereMorne Serpent",
                         "value": "C105997"},
                        {"label": "Grande RiviereNorbert", "value": "C105998"},
                        {"label": "Grande RivierePiat", "value": "C105999"},
                        {"label": "Grande RiviereWhite Rock",
                         "value": "C106000"},
                        {"label": "Gros Islet", "value": "C106001"},
                        {"label": "Gros Islet Town", "value": "C106002"},
                        {"label": "Gros IsletEdge Water", "value": "C106003"},
                        {"label": "LHermitage", "value": "C106004"},
                        {"label": "La Croix Chabourgh", "value": "C106005"},
                        {"label": "La Guerre", "value": "C106006"},
                        {"label": "La GuerreChicken Back Street",
                         "value": "C106007"},
                        {"label": "Marisule", "value": "C106008"},
                        {"label": "MarisuleBon Air", "value": "C106009"},
                        {"label": "MarisuleEast Winds", "value": "C106010"},
                        {"label": "MarisuleLa Brellotte", "value": "C106011"},
                        {"label": "MarisuleTop Of The World",
                         "value": "C106012"},
                        {"label": "Marquis Estate", "value": "C106013"},
                        {"label": "Massade", "value": "C106014"},
                        {"label": "Monchy", "value": "C106015"},
                        {"label": "MonchyCareffe", "value": "C106016"},
                        {"label": "MonchyCletus Village", "value": "C106017"},
                        {"label": "MonchyLa Borne", "value": "C106018"},
                        {"label": "MonchyLa BorneSans Souci",
                         "value": "C106019"},
                        {"label": "MonchyLa Retraite", "value": "C106020"},
                        {"label": "MonchyLafeuillee", "value": "C106021"},
                        {"label": "MonchyLawi Fwen", "value": "C106022"},
                        {"label": "MonchyMalgretoute", "value": "C106023"},
                        {"label": "MonchyMoulin A Vent", "value": "C106024"},
                        {"label": "MonchyRavine Macock", "value": "C106025"},
                        {"label": "MonchyRiviere Mitan", "value": "C106026"},
                        {"label": "MonchyTi Dauphin", "value": "C106027"},
                        {"label": "MonchyVieux Sucreic", "value": "C106028"},
                        {"label": "MonchyVieux SucreicBois DInde",
                         "value": "C106029"},
                        {"label": "MonchyVieux SucreicCareffe",
                         "value": "C106030"},
                        {"label": "Mongiraud", "value": "C106031"},
                        {"label": "Monier", "value": "C106032"},
                        {"label": "Morne Citon", "value": "C106033"},
                        {"label": "Paix Bouche", "value": "C106034"},
                        {"label": "Pigeon Island", "value": "C106035"},
                        {"label": "Plateau", "value": "C106036"},
                        {"label": "Reduit", "value": "C106037"},
                        {"label": "Reduit Orchard", "value": "C106038"},
                        {"label": "Reduit Park", "value": "C106039"},
                        {"label": "Rodney Bay", "value": "C106040"},
                        {"label": "Rodney Heights", "value": "C106041"},
                        {"label": "Union", "value": "C106042"},
                        {"label": "Union Terrace", "value": "C106043"},
                        {"label": "UnionTi Morne", "value": "C106044"}],
 "Grosuplje Municipality": [{"label": "Grosuplje", "value": "C107629"},
                            {"label": "Smarje-Sap", "value": "C107630"}],
 "Guadalcanal Province": [{"label": "Honiara", "value": "C107874"}],
 "Guainia": [{"label": "Barranco Minas", "value": "C20179"},
             {"label": "Cacahual", "value": "C20180"},
             {"label": "Inirida", "value": "C20181"},
             {"label": "La Guadalupe", "value": "C20182"},
             {"label": "Mapiripana", "value": "C20183"},
             {"label": "Morichal", "value": "C20184"},
             {"label": "Pana Pana", "value": "C20185"},
             {"label": "Puerto Colombia", "value": "C20186"},
             {"label": "San Felipe", "value": "C20187"}],
 "Guaira Department": [{"label": "Colonia Mauricio Jose Troche",
                        "value": "C80561"},
                       {"label": "Coronel Martinez", "value": "C80562"},
                       {"label": "Independencia", "value": "C80563"},
                       {"label": "Itape", "value": "C80564"},
                       {"label": "Iturbe", "value": "C80565"},
                       {"label": "Mbocayaty", "value": "C80566"},
                       {"label": "Natalicio Talavera", "value": "C80567"},
                       {"label": "Villarrica", "value": "C80568"}],
 "Guanacaste Province": [{"label": "Abangares", "value": "C20792"},
                         {"label": "Bagaces", "value": "C20793"},
                         {"label": "Belen", "value": "C20794"},
                         {"label": "Canas", "value": "C20795"},
                         {"label": "Carrillo", "value": "C20796"},
                         {"label": "Fortuna", "value": "C20797"},
                         {"label": "Hojancha", "value": "C20798"},
                         {"label": "Juntas", "value": "C20799"},
                         {"label": "La Cruz", "value": "C20800"},
                         {"label": "Liberia", "value": "C20801"},
                         {"label": "Nandayure", "value": "C20802"},
                         {"label": "Nicoya", "value": "C20803"},
                         {"label": "Samara", "value": "C20804"},
                         {"label": "Santa Cruz", "value": "C20805"},
                         {"label": "Sardinal", "value": "C20806"},
                         {"label": "Tilaran", "value": "C20807"}],
 "Guanajuato": [{"label": "Abasolo", "value": "C68299"},
                {"label": "Acambaro", "value": "C68300"},
                {"label": "Adjuntas del Rio", "value": "C68301"},
                {"label": "Aguas Buenas", "value": "C68302"},
                {"label": "Alcocer", "value": "C68303"},
                {"label": "Aldama", "value": "C68304"},
                {"label": "Alfaro", "value": "C68305"},
                {"label": "Ameche", "value": "C68306"},
                {"label": "Apaseo el Alto", "value": "C68307"},
                {"label": "Apaseo el Grande", "value": "C68308"},
                {"label": "Arandas", "value": "C68309"},
                {"label": "Atarjea", "value": "C68310"},
                {"label": "Bajio de Bonillas", "value": "C68311"},
                {"label": "Barretos", "value": "C68312"},
                {"label": "Barrio de Guadalupe del Mezquitillo",
                 "value": "C68313"},
                {"label": "Barron", "value": "C68314"},
                {"label": "Boquillas", "value": "C68315"},
                {"label": "Buenavista de Cortes", "value": "C68316"},
                {"label": "Caleras de Ameche", "value": "C68317"},
                {"label": "Calzada del Tepozan", "value": "C68318"},
                {"label": "Canada de Bustos", "value": "C68319"},
                {"label": "Canada de Caracheo", "value": "C68320"},
                {"label": "Canada de Negros", "value": "C68321"},
                {"label": "Capulin de Bustos", "value": "C68322"},
                {"label": "Cardenas", "value": "C68323"},
                {"label": "Carrizal Grande", "value": "C68324"},
                {"label": "Celaya", "value": "C68325"},
                {"label": "Centro Familiar la Soledad", "value": "C68326"},
                {"label": "Cerano", "value": "C68327"},
                {"label": "Cerrito de Gasca", "value": "C68328"},
                {"label": "Cerritos", "value": "C68329"},
                {"label": "Cerro Colorado", "value": "C68330"},
                {"label": "Cerro Gordo", "value": "C68331"},
                {"label": "Chamacuaro", "value": "C68332"},
                {"label": "Charco de Pantoja", "value": "C68333"},
                {"label": "Chichimequillas", "value": "C68334"},
                {"label": "Chirimoya (Estacion Chirimoya)", "value": "C68335"},
                {"label": "Churipitzeo", "value": "C68336"},
                {"label": "Cieneguilla", "value": "C68337"},
                {"label": "Ciudad Manuel Doblado", "value": "C68338"},
                {"label": "Coachiti", "value": "C68339"},
                {"label": "Colonia 18 de Marzo", "value": "C68340"},
                {"label": "Colonia Fraccionamiento el Puente",
                 "value": "C68341"},
                {"label": "Colonia Francisco Javier Mina", "value": "C68342"},
                {"label": "Colonia la Calzada (La Olla)", "value": "C68343"},
                {"label": "Colonia Latinoamericana", "value": "C68344"},
                {"label": "Colonia Morelos", "value": "C68345"},
                {"label": "Colonia Morelos de Guadalupe de Rivera",
                 "value": "C68346"},
                {"label": "Colonia Nuevo Mexico", "value": "C68347"},
                {"label": "Colonia Padre Hidalgo", "value": "C68348"},
                {"label": "Colonia Patria Nueva", "value": "C68349"},
                {"label": "Colonia Pedro Maria Anaya", "value": "C68350"},
                {"label": "Colonia Rafael Corrales Ayala", "value": "C68351"},
                {"label": "Colonia San Luis Rey", "value": "C68352"},
                {"label": "Comanjilla", "value": "C68353"},
                {"label": "Comonfort", "value": "C68354"},
                {"label": "Coroneo", "value": "C68355"},
                {"label": "Corral de Piedras de Arriba", "value": "C68356"},
                {"label": "Corralejo de Arriba", "value": "C68357"},
                {"label": "Cortazar", "value": "C68358"},
                {"label": "Cuarta Brigada", "value": "C68359"},
                {"label": "Cuchicuato", "value": "C68360"},
                {"label": "Cueramaro", "value": "C68361"},
                {"label": "Cuesta de Penones", "value": "C68362"},
                {"label": "Cupareo", "value": "C68363"},
                {"label": "De Parral", "value": "C68364"},
                {"label": "Delgado de Abajo", "value": "C68365"},
                {"label": "Delgado de Arriba", "value": "C68366"},
                {"label": "Derramadero Segundo (Infiernillo)",
                 "value": "C68367"},
                {"label": "Doctor Mora", "value": "C68368"},
                {"label": "Dolores Hidalgo Cuna de la Independencia Nacional",
                 "value": "C68369"},
                {"label": "Don Diego", "value": "C68370"},
                {"label": "Don Francisco", "value": "C68371"},
                {"label": "Duarte", "value": "C68372"},
                {"label": "Ejido Jesus Maria", "value": "C68373"},
                {"label": "Ejido la Joya", "value": "C68374"},
                {"label": "El Acebuche", "value": "C68375"},
                {"label": "El Canario", "value": "C68376"},
                {"label": "El Capulin", "value": "C68377"},
                {"label": "El Caracol", "value": "C68378"},
                {"label": "El Carmen", "value": "C68379"},
                {"label": "El Carreton", "value": "C68380"},
                {"label": "El Carrizalito", "value": "C68381"},
                {"label": "El Castillo", "value": "C68382"},
                {"label": "El CERESO", "value": "C68383"},
                {"label": "El Chinaco (El Pujido)", "value": "C68384"},
                {"label": "El Coecillo", "value": "C68385"},
                {"label": "El Copalillo", "value": "C68386"},
                {"label": "El Divisador", "value": "C68387"},
                {"label": "El Escoplo", "value": "C68388"},
                {"label": "El Espejo", "value": "C68389"},
                {"label": "El Fresno", "value": "C68390"},
                {"label": "El Gallinero", "value": "C68391"},
                {"label": "El Huizache", "value": "C68392"},
                {"label": "El Jaguey", "value": "C68393"},
                {"label": "El Llanito", "value": "C68394"},
                {"label": "El Maguey", "value": "C68395"},
                {"label": "El Mezquitillo", "value": "C68396"},
                {"label": "El Moral", "value": "C68397"},
                {"label": "El Nacimiento", "value": "C68398"},
                {"label": "El Naranjillo", "value": "C68399"},
                {"label": "El Picacho", "value": "C68400"},
                {"label": "El Puesto", "value": "C68401"},
                {"label": "El Recuerdo de Ancon (Xoconoxtle de Arriba)",
                 "value": "C68402"},
                {"label": "El Refugio de los Sauces", "value": "C68403"},
                {"label": "El Sabino", "value": "C68404"},
                {"label": "El Salto de Espejo", "value": "C68405"},
                {"label": "El Salvador (Ranchito San Jose del Carmen)",
                 "value": "C68406"},
                {"label": "El Sauz (El Sauz de Villasenor)", "value": "C68407"},
                {"label": "El Tecolote", "value": "C68408"},
                {"label": "El Tejocote (El Domingo)", "value": "C68409"},
                {"label": "El Tunal", "value": "C68410"},
                {"label": "El Varal", "value": "C68411"},
                {"label": "El Vicarlo", "value": "C68412"},
                {"label": "El Zapote", "value": "C68413"},
                {"label": "Empalme Escobedo", "value": "C68414"},
                {"label": "Estacion Corralejo", "value": "C68415"},
                {"label": "Estacion de San Francisco", "value": "C68416"},
                {"label": "Estacion Joaquin", "value": "C68417"},
                {"label": "Estacion la Piedad", "value": "C68418"},
                {"label": "Estacion Penjamo", "value": "C68419"},
                {"label": "Estancia del Llano", "value": "C68420"},
                {"label": "Estanzuela de Romero", "value": "C68421"},
                {"label": "Estrada", "value": "C68422"},
                {"label": "Ex-Hacienda del Copal", "value": "C68423"},
                {"label": "Fabrica de Melchor", "value": "C68424"},
                {"label": "Fraccionamiento la Mezquitera", "value": "C68425"},
                {"label": "Fraccionamiento las Liebres", "value": "C68426"},
                {"label": "Fraccionamiento Paraiso Real", "value": "C68427"},
                {"label": "Fraccionamiento Paseo de las Torres",
                 "value": "C68428"},
                {"label": "Fraccionamiento Praderas de la Venta",
                 "value": "C68429"},
                {"label": "Fraccionamiento Villa Jardin", "value": "C68430"},
                {"label": "Fraccionamiento Villas de Guanajuato",
                 "value": "C68431"},
                {"label": "Franco", "value": "C68432"},
                {"label": "Franco Tavera", "value": "C68433"},
                {"label": "Frias", "value": "C68434"},
                {"label": "Gachupines", "value": "C68435"},
                {"label": "Galerade Panales", "value": "C68436"},
                {"label": "Gasca", "value": "C68437"},
                {"label": "Gavia de Rionda", "value": "C68438"},
                {"label": "Guadalupe", "value": "C68439"},
                {"label": "Guadalupe de Paso Blanco", "value": "C68440"},
                {"label": "Guadalupe de Rivera", "value": "C68441"},
                {"label": "Guadalupe de Tambula", "value": "C68442"},
                {"label": "Guadalupe del Monte", "value": "C68443"},
                {"label": "Guanajuato", "value": "C68444"},
                {"label": "Hacienda Arriba", "value": "C68445"},
                {"label": "Hacienda de la Bolsa", "value": "C68446"},
                {"label": "Hacienda de Marquez", "value": "C68447"},
                {"label": "Hoya de Cintora (La Hoya de Arriba)",
                 "value": "C68448"},
                {"label": "Huanimaro", "value": "C68449"},
                {"label": "Huitzatarito", "value": "C68450"},
                {"label": "Ibarrilla", "value": "C68451"},
                {"label": "Iramuco", "value": "C68452"},
                {"label": "Irapuato", "value": "C68453"},
                {"label": "Jalpilla", "value": "C68454"},
                {"label": "Jamaica", "value": "C68455"},
                {"label": "Jaral del Progreso", "value": "C68456"},
                {"label": "Jauregui", "value": "C68457"},
                {"label": "Jerecuaro", "value": "C68458"},
                {"label": "Jesus del Monte", "value": "C68459"},
                {"label": "Jicamas", "value": "C68460"},
                {"label": "Jocoqui", "value": "C68461"},
                {"label": "Juan Martin", "value": "C68462"},
                {"label": "Juventino Rosas", "value": "C68463"},
                {"label": "La Aldea", "value": "C68464"},
                {"label": "La Angostura", "value": "C68465"},
                {"label": "La Aurora", "value": "C68466"},
                {"label": "La Caja", "value": "C68467"},
                {"label": "La Calera", "value": "C68468"},
                {"label": "La Capilla", "value": "C68469"},
                {"label": "La Cienega", "value": "C68470"},
                {"label": "La Cieneguita", "value": "C68471"},
                {"label": "La Compania", "value": "C68472"},
                {"label": "La Cruz", "value": "C68473"},
                {"label": "La Cruz del Palmar", "value": "C68474"},
                {"label": "La Cuevita", "value": "C68475"},
                {"label": "La Ermita", "value": "C68476"},
                {"label": "La Escondida", "value": "C68477"},
                {"label": "La Estancia de San Jose del Carmen",
                 "value": "C68478"},
                {"label": "La Estrella", "value": "C68479"},
                {"label": "La Gavia", "value": "C68480"},
                {"label": "La Joya de Calvillo", "value": "C68481"},
                {"label": "La Labor", "value": "C68482"},
                {"label": "La Laborcita", "value": "C68483"},
                {"label": "La Laja", "value": "C68484"},
                {"label": "La Lobera", "value": "C68485"},
                {"label": "La Luz", "value": "C68486"},
                {"label": "La Moncada", "value": "C68487"},
                {"label": "La Norita", "value": "C68488"},
                {"label": "La Ordena", "value": "C68489"},
                {"label": "La Ortiga", "value": "C68490"},
                {"label": "La Palma", "value": "C68491"},
                {"label": "La Palmita (La Palmita de San Gabriel)",
                 "value": "C68492"},
                {"label": "La Purisima", "value": "C68493"},
                {"label": "La Sabana", "value": "C68494"},
                {"label": "La Sardina", "value": "C68495"},
                {"label": "La Sauceda", "value": "C68496"},
                {"label": "La Soledad", "value": "C68497"},
                {"label": "La Tinaja", "value": "C68498"},
                {"label": "La Trinidad", "value": "C68499"},
                {"label": "Labor de Peralta", "value": "C68500"},
                {"label": "Ladrilleras del Refugio", "value": "C68501"},
                {"label": "Laguna de Guadalupe", "value": "C68502"},
                {"label": "Laguna Larga", "value": "C68503"},
                {"label": "Laguna Larga de Cortes", "value": "C68504"},
                {"label": "Landin", "value": "C68505"},
                {"label": "Las animas", "value": "C68506"},
                {"label": "Las Canas", "value": "C68507"},
                {"label": "Las Huertas Tercera Seccion", "value": "C68508"},
                {"label": "Las Liebres", "value": "C68509"},
                {"label": "Las Masas (La Luz Masas)", "value": "C68510"},
                {"label": "Las Trojas", "value": "C68511"},
                {"label": "Leon", "value": "C68512"},
                {"label": "Leon de los Aldama", "value": "C68513"},
                {"label": "Lo de Juarez", "value": "C68514"},
                {"label": "Loma de la Esperanza", "value": "C68515"},
                {"label": "Loma de Yerbabuena", "value": "C68516"},
                {"label": "Loma de Zempoala", "value": "C68517"},
                {"label": "Loma Pelada", "value": "C68518"},
                {"label": "Loma Tendida", "value": "C68519"},
                {"label": "Los angeles", "value": "C68520"},
                {"label": "Los Arcos", "value": "C68521"},
                {"label": "Los Desmontes", "value": "C68522"},
                {"label": "Los Dolores (Las Quince Letras)", "value": "C68523"},
                {"label": "Los Dulces Nombres", "value": "C68524"},
                {"label": "Los Fierros", "value": "C68525"},
                {"label": "Los Galvan", "value": "C68526"},
                {"label": "Los Lorenzos", "value": "C68527"},
                {"label": "Los Mancera", "value": "C68528"},
                {"label": "Los Medranos", "value": "C68529"},
                {"label": "Los Nicolases", "value": "C68530"},
                {"label": "Los Ocotes", "value": "C68531"},
                {"label": "Los Prietos", "value": "C68532"},
                {"label": "Los Ramirez", "value": "C68533"},
                {"label": "Los Remedios", "value": "C68534"},
                {"label": "Los Rodriguez", "value": "C68535"},
                {"label": "Loza de Barrera", "value": "C68536"},
                {"label": "Loza de los Padres", "value": "C68537"},
                {"label": "Lucio Blanco (Los Gavilanes)", "value": "C68538"},
                {"label": "Magdalena de Araceo", "value": "C68539"},
                {"label": "Malagana (San Antonio del Monte)",
                 "value": "C68540"},
                {"label": "Manuel Doblado", "value": "C68541"},
                {"label": "Maravatio del Encinal", "value": "C68542"},
                {"label": "Marfil", "value": "C68543"},
                {"label": "Marroquin", "value": "C68544"},
                {"label": "Medina", "value": "C68545"},
                {"label": "Medio Sitio", "value": "C68546"},
                {"label": "Menores", "value": "C68547"},
                {"label": "Merino", "value": "C68548"},
                {"label": "Mexicanos", "value": "C68549"},
                {"label": "Mezquite de Luna", "value": "C68550"},
                {"label": "Mezquite de Sotelo", "value": "C68551"},
                {"label": "Mezquite Gordo", "value": "C68552"},
                {"label": "Mineral de la Luz", "value": "C68553"},
                {"label": "Mision de Chichimecas", "value": "C68554"},
                {"label": "Molino de San Jose", "value": "C68555"},
                {"label": "Molino de Santa Ana", "value": "C68556"},
                {"label": "Monte Blanco", "value": "C68557"},
                {"label": "Monte del Coecillo", "value": "C68558"},
                {"label": "Monte Grande", "value": "C68559"},
                {"label": "Morales", "value": "C68560"},
                {"label": "Moroleon", "value": "C68561"},
                {"label": "Neutla", "value": "C68562"},
                {"label": "Nigromante", "value": "C68563"},
                {"label": "Obrajuelo", "value": "C68564"},
                {"label": "Ocampo", "value": "C68565"},
                {"label": "Ojo de Agua de Ballesteros", "value": "C68566"},
                {"label": "Ojo de Agua de la Trinidad", "value": "C68567"},
                {"label": "Ojo de Agua de Mendoza", "value": "C68568"},
                {"label": "Ojo de Agua del Refugio", "value": "C68569"},
                {"label": "Ojo Seco", "value": "C68570"},
                {"label": "Orduna de Abajo", "value": "C68571"},
                {"label": "Orduna de Arriba", "value": "C68572"},
                {"label": "Otates", "value": "C68573"},
                {"label": "Palmillas de San Juan", "value": "C68574"},
                {"label": "Palo Colorado", "value": "C68575"},
                {"label": "Panales Jamaica (Canones)", "value": "C68576"},
                {"label": "Paracuaro", "value": "C68577"},
                {"label": "Parangarico", "value": "C68578"},
                {"label": "Paso de Pirules", "value": "C68579"},
                {"label": "Paxtle", "value": "C68580"},
                {"label": "Penjamo", "value": "C68581"},
                {"label": "Penuelas", "value": "C68582"},
                {"label": "Peralta", "value": "C68583"},
                {"label": "Piedras de Lumbre", "value": "C68584"},
                {"label": "Piedras Negras", "value": "C68585"},
                {"label": "Pinicuaro", "value": "C68586"},
                {"label": "Plan de Ayala", "value": "C68587"},
                {"label": "Plan Guanajuato (La Sandia)", "value": "C68588"},
                {"label": "Plancarte", "value": "C68589"},
                {"label": "Potrerillos (Guanajal)", "value": "C68590"},
                {"label": "Pozos", "value": "C68591"},
                {"label": "Prados del Rosario", "value": "C68592"},
                {"label": "Presa Blanca", "value": "C68593"},
                {"label": "Primera Fraccion de Crespo", "value": "C68594"},
                {"label": "Providencia", "value": "C68595"},
                {"label": "Providencia de Napoles", "value": "C68596"},
                {"label": "Pueblo Nuevo", "value": "C68597"},
                {"label": "Puentecillas", "value": "C68598"},
                {"label": "Puerta del Monte", "value": "C68599"},
                {"label": "Purisima de Bustos", "value": "C68600"},
                {"label": "Purisima de Covarrubias", "value": "C68601"},
                {"label": "Purisima del Progreso", "value": "C68602"},
                {"label": "Purisima del Rincon", "value": "C68603"},
                {"label": "Puroagua", "value": "C68604"},
                {"label": "Rancho de Guadalupe", "value": "C68605"},
                {"label": "Rancho Nuevo de la Cruz", "value": "C68606"},
                {"label": "Rancho Nuevo de la Luz", "value": "C68607"},
                {"label": "Rancho Nuevo del Llanito", "value": "C68608"},
                {"label": "Rancho Viejo", "value": "C68609"},
                {"label": "Rincon de Cano", "value": "C68610"},
                {"label": "Rincon de Parangueo", "value": "C68611"},
                {"label": "Rincon de Tamayo", "value": "C68612"},
                {"label": "Rincon del Centeno", "value": "C68613"},
                {"label": "Rinconadas del Bosque", "value": "C68614"},
                {"label": "Rio Laja", "value": "C68615"},
                {"label": "Rizos de la Joya (Rizos del Saucillo)",
                 "value": "C68616"},
                {"label": "Romita", "value": "C68617"},
                {"label": "Roque", "value": "C68618"},
                {"label": "Salamanca", "value": "C68619"},
                {"label": "Salitrillo", "value": "C68620"},
                {"label": "Salvatierra", "value": "C68621"},
                {"label": "San Agustin de las Flores", "value": "C68622"},
                {"label": "San Andres Enguaro", "value": "C68623"},
                {"label": "San angel", "value": "C68624"},
                {"label": "San Antonio Calichar", "value": "C68625"},
                {"label": "San Antonio de Corrales", "value": "C68626"},
                {"label": "San Antonio de los Morales", "value": "C68627"},
                {"label": "San Antonio de los Tepetates", "value": "C68628"},
                {"label": "San Antonio de Romerillo", "value": "C68629"},
                {"label": "San Antonio el Chico", "value": "C68630"},
                {"label": "San Antonio el Rico", "value": "C68631"},
                {"label": "San Antonio Emenguaro", "value": "C68632"},
                {"label": "San Antonio Gallardo", "value": "C68633"},
                {"label": "San Antonio Primero", "value": "C68634"},
                {"label": "San Antonio Texas", "value": "C68635"},
                {"label": "San Bartolo de Berrios", "value": "C68636"},
                {"label": "San Bartolome Aguas Calientes", "value": "C68637"},
                {"label": "San Bernardo", "value": "C68638"},
                {"label": "San Bernardo Pena Blanca", "value": "C68639"},
                {"label": "San Cayetano", "value": "C68640"},
                {"label": "San Cristobal", "value": "C68641"},
                {"label": "San Diego", "value": "C68642"},
                {"label": "San Diego de Alcala", "value": "C68643"},
                {"label": "San Diego de la Union", "value": "C68644"},
                {"label": "San Elias", "value": "C68645"},
                {"label": "San Felipe", "value": "C68646"},
                {"label": "San Felipe Quiriceo", "value": "C68647"},
                {"label": "San Francisco", "value": "C68648"},
                {"label": "San Francisco (Banos de Agua Caliente)",
                 "value": "C68649"},
                {"label": "San Francisco de Duran", "value": "C68650"},
                {"label": "San Francisco del Rincon", "value": "C68651"},
                {"label": "San Gabriel", "value": "C68652"},
                {"label": "San Gabriel y San Ignacio", "value": "C68653"},
                {"label": "San Gregorio", "value": "C68654"},
                {"label": "San Ignacio", "value": "C68655"},
                {"label": "San Ignacio de Hidalgo", "value": "C68656"},
                {"label": "San Ignacio de Rivera (Ojo de Agua)",
                 "value": "C68657"},
                {"label": "San Ignacio de San Jose Parangueo",
                 "value": "C68658"},
                {"label": "San Isidro de Gamboa", "value": "C68659"},
                {"label": "San Isidro de la Concepcion", "value": "C68660"},
                {"label": "San Isidro de la Estacada", "value": "C68661"},
                {"label": "San Isidro de los Sauces", "value": "C68662"},
                {"label": "San Javier", "value": "C68663"},
                {"label": "San Jeronimo de Araceo", "value": "C68664"},
                {"label": "San Jose de Agua Azul", "value": "C68665"},
                {"label": "San Jose de Ayala", "value": "C68666"},
                {"label": "San Jose de Bernalejo (El Guayabo)",
                 "value": "C68667"},
                {"label": "San Jose de Cervera", "value": "C68668"},
                {"label": "San Jose de Duran (Los Troncoso)",
                 "value": "C68669"},
                {"label": "San Jose de Guanajuato", "value": "C68670"},
                {"label": "San Jose de la Montana", "value": "C68671"},
                {"label": "San Jose de las Pilas", "value": "C68672"},
                {"label": "San Jose de Llanos", "value": "C68673"},
                {"label": "San Jose de Mendoza", "value": "C68674"},
                {"label": "San Jose de Rancho Nuevo (Los Arrieros)",
                 "value": "C68675"},
                {"label": "San Jose del Cerrito de Camargo", "value": "C68676"},
                {"label": "San Jose del Potrero", "value": "C68677"},
                {"label": "San Jose del Rodeo", "value": "C68678"},
                {"label": "San Jose del Torreon", "value": "C68679"},
                {"label": "San Jose el Nuevo", "value": "C68680"},
                {"label": "San Jose Iturbide", "value": "C68681"},
                {"label": "San Jose Temascatio", "value": "C68682"},
                {"label": "San Jose Viborillas", "value": "C68683"},
                {"label": "San Juan Bautista Cacalote", "value": "C68684"},
                {"label": "San Juan de Abajo", "value": "C68685"},
                {"label": "San Juan de la Vega", "value": "C68686"},
                {"label": "San Juan de Otates", "value": "C68687"},
                {"label": "San Juan del Llanito", "value": "C68688"},
                {"label": "San Juan Grande", "value": "C68689"},
                {"label": "San Juan Jaripeo", "value": "C68690"},
                {"label": "San Juan Pan de Arriba", "value": "C68691"},
                {"label": "San Lorenzo", "value": "C68692"},
                {"label": "San Lucas", "value": "C68693"},
                {"label": "San Luis de la Paz", "value": "C68694"},
                {"label": "San Miguel de Allende", "value": "C68695"},
                {"label": "San Miguel del Arenal", "value": "C68696"},
                {"label": "San Miguel Emenguaro", "value": "C68697"},
                {"label": "San Miguel Octopan", "value": "C68698"},
                {"label": "San Nicolas de la Condesa", "value": "C68699"},
                {"label": "San Nicolas de los Agustinos", "value": "C68700"},
                {"label": "San Nicolas del Carmen", "value": "C68701"},
                {"label": "San Nicolas Parangueo", "value": "C68702"},
                {"label": "San Nicolas Temascatio", "value": "C68703"},
                {"label": "San Pablo Pejo", "value": "C68704"},
                {"label": "San Pedro de Ibarra", "value": "C68705"},
                {"label": "San Pedro de los Naranjos", "value": "C68706"},
                {"label": "San Pedro Tenango", "value": "C68707"},
                {"label": "San Roque", "value": "C68708"},
                {"label": "San Roque de Montes", "value": "C68709"},
                {"label": "San Roque de Torres", "value": "C68710"},
                {"label": "San Salvador Torrecillas", "value": "C68711"},
                {"label": "San Sebastian de Salitre", "value": "C68712"},
                {"label": "San Vicente", "value": "C68713"},
                {"label": "San Vicente de Flores", "value": "C68714"},
                {"label": "Santa Ana", "value": "C68715"},
                {"label": "Santa Ana Pacueco", "value": "C68716"},
                {"label": "Santa Anita", "value": "C68717"},
                {"label": "Santa Barbara", "value": "C68718"},
                {"label": "Santa Catarina", "value": "C68719"},
                {"label": "Santa Clara", "value": "C68720"},
                {"label": "Santa Cruz de Juventino Rosas", "value": "C68721"},
                {"label": "Santa Fe de la Purisima", "value": "C68722"},
                {"label": "Santa Maria del Refugio", "value": "C68723"},
                {"label": "Santa Rosa", "value": "C68724"},
                {"label": "Santa Rosa de Lima", "value": "C68725"},
                {"label": "Santa Rosa de Rivas", "value": "C68726"},
                {"label": "Santa Rosa los Angeles", "value": "C68727"},
                {"label": "Santa Teresa", "value": "C68728"},
                {"label": "Santa Teresita de Don Diego", "value": "C68729"},
                {"label": "Santiago Capitiro", "value": "C68730"},
                {"label": "Santiago de Cuenda", "value": "C68731"},
                {"label": "Santiago Maravatio", "value": "C68732"},
                {"label": "Santo Tomas Huatzindeo", "value": "C68733"},
                {"label": "Sarabia", "value": "C68734"},
                {"label": "Serrano", "value": "C68735"},
                {"label": "Silao", "value": "C68736"},
                {"label": "Silao de la Victoria", "value": "C68737"},
                {"label": "Silva", "value": "C68738"},
                {"label": "Soledad Nueva", "value": "C68739"},
                {"label": "Sotelo", "value": "C68740"},
                {"label": "Suchitlan", "value": "C68741"},
                {"label": "Tamazula", "value": "C68742"},
                {"label": "Tarandacuao", "value": "C68743"},
                {"label": "Taretan", "value": "C68744"},
                {"label": "Tarimoro", "value": "C68745"},
                {"label": "Tejocote de Calera", "value": "C68746"},
                {"label": "Tenango el Nuevo", "value": "C68747"},
                {"label": "Teneria del Santuario", "value": "C68748"},
                {"label": "Tequisquiapan", "value": "C68749"},
                {"label": "Tierra Fria", "value": "C68750"},
                {"label": "Tomelopitos", "value": "C68751"},
                {"label": "Tulillos de Abajo", "value": "C68752"},
                {"label": "Tupataro", "value": "C68753"},
                {"label": "Uriangato", "value": "C68754"},
                {"label": "Urireo", "value": "C68755"},
                {"label": "Valencia de Cerro Gordo", "value": "C68756"},
                {"label": "Valencianita", "value": "C68757"},
                {"label": "Valle de Santiago", "value": "C68758"},
                {"label": "Valtierrilla", "value": "C68759"},
                {"label": "Victoria", "value": "C68760"},
                {"label": "Victoria de Cortazar", "value": "C68761"},
                {"label": "Villagran", "value": "C68762"},
                {"label": "Villas de Irapuato", "value": "C68763"},
                {"label": "Xichu", "value": "C68764"},
                {"label": "Xoconoxtle el Grande", "value": "C68765"},
                {"label": "Yerbabuena", "value": "C68766"},
                {"label": "Yuriria", "value": "C68767"},
                {"label": "Yustis", "value": "C68768"},
                {"label": "Zangarro (Zangarro Nuevo)", "value": "C68769"},
                {"label": "Zapote de Barajas", "value": "C68770"},
                {"label": "Zapote de Cestao", "value": "C68771"},
                {"label": "Zapote de Palomas", "value": "C68772"},
                {"label": "Zapote de Peralta", "value": "C68773"},
                {"label": "Zapotillo de Mogotes", "value": "C68774"}],
 "Guangdong": [{"label": "Anbu", "value": "C18432"},
               {"label": "Chaozhou", "value": "C18433"},
               {"label": "Chenghua", "value": "C18434"},
               {"label": "Daliang", "value": "C18435"},
               {"label": "Danshui", "value": "C18436"},
               {"label": "Dasha", "value": "C18437"},
               {"label": "Dongguan", "value": "C18438"},
               {"label": "Donghai", "value": "C18439"},
               {"label": "Ducheng", "value": "C18440"},
               {"label": "Encheng", "value": "C18441"},
               {"label": "Foshan", "value": "C18442"},
               {"label": "Foshan Shi", "value": "C18443"},
               {"label": "Gaoyao", "value": "C18444"},
               {"label": "Gaozhou", "value": "C18445"},
               {"label": "Guangzhou", "value": "C18446"},
               {"label": "Guangzhou Shi", "value": "C18447"},
               {"label": "Haikuotiankong", "value": "C18448"},
               {"label": "Haimen", "value": "C18449"},
               {"label": "Hepo", "value": "C18450"},
               {"label": "Heyuan", "value": "C18451"},
               {"label": "Huaicheng", "value": "C18452"},
               {"label": "Huanggang", "value": "C18453"},
               {"label": "Huazhou", "value": "C18454"},
               {"label": "Huicheng", "value": "C18455"},
               {"label": "Huizhou", "value": "C18456"},
               {"label": "Humen", "value": "C18457"},
               {"label": "Jiangmen", "value": "C18458"},
               {"label": "Jiazi", "value": "C18459"},
               {"label": "Jieshi", "value": "C18460"},
               {"label": "Jieyang", "value": "C18461"},
               {"label": "Lecheng", "value": "C18462"},
               {"label": "Lianjiang", "value": "C18463"},
               {"label": "Lianzhou", "value": "C18464"},
               {"label": "Licheng", "value": "C18465"},
               {"label": "Lubu", "value": "C18466"},
               {"label": "Luocheng", "value": "C18467"},
               {"label": "Luoyang", "value": "C18468"},
               {"label": "Maba", "value": "C18469"},
               {"label": "Maoming", "value": "C18470"},
               {"label": "Meizhou", "value": "C18471"},
               {"label": "Nanfeng", "value": "C18472"},
               {"label": "Pingshan", "value": "C18473"},
               {"label": "Puning", "value": "C18474"},
               {"label": "Qingyuan", "value": "C18475"},
               {"label": "Sanshui", "value": "C18476"},
               {"label": "Shantou", "value": "C18477"},
               {"label": "Shanwei", "value": "C18478"},
               {"label": "Shaoguan", "value": "C18479"},
               {"label": "Shaping", "value": "C18480"},
               {"label": "Shenzhen", "value": "C18481"},
               {"label": "Shilong", "value": "C18482"},
               {"label": "Shiqi", "value": "C18483"},
               {"label": "Shiqiao", "value": "C18484"},
               {"label": "Shiwan", "value": "C18485"},
               {"label": "Shixing", "value": "C18486"},
               {"label": "Taishan", "value": "C18487"},
               {"label": "Tangping", "value": "C18488"},
               {"label": "Wuchuan", "value": "C18489"},
               {"label": "Xingning", "value": "C18490"},
               {"label": "Xinhui", "value": "C18491"},
               {"label": "Xinyi", "value": "C18492"},
               {"label": "Xiongzhou", "value": "C18493"},
               {"label": "Xucheng", "value": "C18494"},
               {"label": "Yangchun", "value": "C18495"},
               {"label": "Yangjiang", "value": "C18496"},
               {"label": "Yingcheng", "value": "C18497"},
               {"label": "Yunfu", "value": "C18498"},
               {"label": "Zhanjiang", "value": "C18499"},
               {"label": "Zhaoqing", "value": "C18500"},
               {"label": "Zhongshan", "value": "C18501"},
               {"label": "Zhongshan Prefecture", "value": "C18502"},
               {"label": "Zhuhai", "value": "C18503"}],
 "Guangxi Zhuang": [{"label": "Babu", "value": "C18504"},
                    {"label": "Baihe", "value": "C18505"},
                    {"label": "Baise City", "value": "C18506"},
                    {"label": "Baise Shi", "value": "C18507"},
                    {"label": "Beihai", "value": "C18508"},
                    {"label": "Chongzuo Shi", "value": "C18509"},
                    {"label": "Dazhai", "value": "C18510"},
                    {"label": "Fangchenggang Shi", "value": "C18511"},
                    {"label": "Guigang", "value": "C18512"},
                    {"label": "Guilin", "value": "C18513"},
                    {"label": "Guilin Shi", "value": "C18514"},
                    {"label": "Guiping", "value": "C18515"},
                    {"label": "Hechi Shi", "value": "C18516"},
                    {"label": "Jinji", "value": "C18517"},
                    {"label": "Laibin", "value": "C18518"},
                    {"label": "Lianzhou", "value": "C18519"},
                    {"label": "Lingcheng", "value": "C18520"},
                    {"label": "Liuzhou Shi", "value": "C18521"},
                    {"label": "Luorong", "value": "C18522"},
                    {"label": "Nandu", "value": "C18523"},
                    {"label": "Nanning", "value": "C18524"},
                    {"label": "Pingnan", "value": "C18525"},
                    {"label": "Pumiao", "value": "C18526"},
                    {"label": "Qinzhou", "value": "C18527"},
                    {"label": "Wuzhou", "value": "C18528"},
                    {"label": "Yangshuo", "value": "C18529"},
                    {"label": "Yashan", "value": "C18530"},
                    {"label": "Yulin", "value": "C18531"}],
 "Guantanamo Province": [{"label": "Baracoa", "value": "C21735"},
                         {"label": "Guantanamo", "value": "C21736"},
                         {"label": "Maisi", "value": "C21737"},
                         {"label": "Municipio de Guantanamo",
                          "value": "C21738"},
                         {"label": "Rio Guayabal de Yateras",
                          "value": "C21739"},
                         {"label": "San Antonio del Sur", "value": "C21740"}],
 "Guarda": [{"label": "Abrigada", "value": "C91099"},
            {"label": "Acores", "value": "C91100"},
            {"label": "Adao", "value": "C91101"},
            {"label": "Agualva", "value": "C91102"},
            {"label": "aguas Belas", "value": "C91103"},
            {"label": "Aguiar da Beira", "value": "C91104"},
            {"label": "Ajuda", "value": "C91105"},
            {"label": "Albardo", "value": "C91106"},
            {"label": "Alcabideche", "value": "C91107"},
            {"label": "Alcains", "value": "C91108"},
            {"label": "Alcantara", "value": "C91109"},
            {"label": "Alcoentre", "value": "C91110"},
            {"label": "Aldeia da Ponte", "value": "C91111"},
            {"label": "Aldeia da Ribeira", "value": "C91112"},
            {"label": "Aldeia de Santo Antonio", "value": "C91113"},
            {"label": "Aldeia do Bispo", "value": "C91114"},
            {"label": "Aldeia Galega da Merceana", "value": "C91115"},
            {"label": "Aldeia Gavinha", "value": "C91116"},
            {"label": "Aldeia Nova, Miranda do Douro", "value": "C91117"},
            {"label": "Aldeia Velha", "value": "C91118"},
            {"label": "Aldeia Vicosa", "value": "C91119"},
            {"label": "Aldeias", "value": "C91120"},
            {"label": "Alenquer", "value": "C91121"},
            {"label": "Alfaiates", "value": "C91122"},
            {"label": "Alfornelos", "value": "C91123"},
            {"label": "Alfragide", "value": "C91124"},
            {"label": "Alges", "value": "C91125"},
            {"label": "Algodres", "value": "C91126"},
            {"label": "Alguber", "value": "C91127"},
            {"label": "Algueirao", "value": "C91128"},
            {"label": "Algueirao-Mem Martins", "value": "C91129"},
            {"label": "Alhandra", "value": "C91130"},
            {"label": "Almargem", "value": "C91131"},
            {"label": "Almargem do Bispo", "value": "C91132"},
            {"label": "Almeida", "value": "C91133"},
            {"label": "Almendra", "value": "C91134"},
            {"label": "Almofala", "value": "C91135"},
            {"label": "Alto do Pina", "value": "C91136"},
            {"label": "Alvalade", "value": "C91137"},
            {"label": "Alvendre", "value": "C91138"},
            {"label": "Alverca da Beira", "value": "C91139"},
            {"label": "Alverca do Ribatejo", "value": "C91140"},
            {"label": "Alvoco da Serra", "value": "C91141"},
            {"label": "Amadora", "value": "C91142"},
            {"label": "Ameixoeira", "value": "C91143"},
            {"label": "Amoreira", "value": "C91144"},
            {"label": "Anjos", "value": "C91145"},
            {"label": "Apelacao", "value": "C91146"},
            {"label": "Arcozelo", "value": "C91147"},
            {"label": "Arranho", "value": "C91148"},
            {"label": "Arrifana", "value": "C91149"},
            {"label": "Arruda Dos Vinhos", "value": "C91150"},
            {"label": "Atalaia", "value": "C91151"},
            {"label": "Aveiras de Baixo", "value": "C91152"},
            {"label": "Aveiras de Cima", "value": "C91153"},
            {"label": "Avelas da Ribeira", "value": "C91154"},
            {"label": "Aveloso", "value": "C91155"},
            {"label": "Azambuja", "value": "C91156"},
            {"label": "Azambuja (town)", "value": "C91157"},
            {"label": "Azenhas do Mar", "value": "C91158"},
            {"label": "Azevo", "value": "C91159"},
            {"label": "Azueira", "value": "C91160"},
            {"label": "Baracal", "value": "C91161"},
            {"label": "Barcarena", "value": "C91162"},
            {"label": "Barreira", "value": "C91163"},
            {"label": "Beato", "value": "C91164"},
            {"label": "Belas", "value": "C91165"},
            {"label": "Bendada", "value": "C91166"},
            {"label": "Benespera", "value": "C91167"},
            {"label": "Benfica", "value": "C91168"},
            {"label": "Bismula", "value": "C91169"},
            {"label": "Bobadela", "value": "C91170"},
            {"label": "Bouca Cova", "value": "C91171"},
            {"label": "Brandoa", "value": "C91172"},
            {"label": "Bucelas", "value": "C91173"},
            {"label": "Buraca", "value": "C91174"},
            {"label": "Cabanas de Torres", "value": "C91175"},
            {"label": "Cabeca", "value": "C91176"},
            {"label": "Cacem", "value": "C91177"},
            {"label": "Cachoeiras", "value": "C91178"},
            {"label": "Cadafais", "value": "C91179"},
            {"label": "Cadafaz", "value": "C91180"},
            {"label": "Cadaval", "value": "C91181"},
            {"label": "Calhandriz", "value": "C91182"},
            {"label": "Camarate", "value": "C91183"},
            {"label": "Campelos", "value": "C91184"},
            {"label": "Campo Grande", "value": "C91185"},
            {"label": "Campolide", "value": "C91186"},
            {"label": "Canecas", "value": "C91187"},
            {"label": "Carapito", "value": "C91188"},
            {"label": "Carcavelos", "value": "C91189"},
            {"label": "Cardosas", "value": "C91190"},
            {"label": "Carmoes", "value": "C91191"},
            {"label": "Carnaxide", "value": "C91192"},
            {"label": "Carnicaes", "value": "C91193"},
            {"label": "Carnide", "value": "C91194"},
            {"label": "Carnota", "value": "C91195"},
            {"label": "Carragozela", "value": "C91196"},
            {"label": "Carrapichana", "value": "C91197"},
            {"label": "Carregado", "value": "C91198"},
            {"label": "Carvalhal", "value": "C91199"},
            {"label": "Carvoeira", "value": "C91200"},
            {"label": "Casal de Cambra", "value": "C91201"},
            {"label": "Casal de Cinza", "value": "C91202"},
            {"label": "Casal Vasco", "value": "C91203"},
            {"label": "Casas do Soeiro", "value": "C91204"},
            {"label": "Cascais", "value": "C91205"},
            {"label": "Castanheira", "value": "C91206"},
            {"label": "Castanheira do Ribatejo", "value": "C91207"},
            {"label": "Casteicao", "value": "C91208"},
            {"label": "Casteleiro", "value": "C91209"},
            {"label": "Castelo (Lisbon)", "value": "C91210"},
            {"label": "Castelo Bom", "value": "C91211"},
            {"label": "Castelo Melhor", "value": "C91212"},
            {"label": "Castelo Rodrigo", "value": "C91213"},
            {"label": "Cativelos", "value": "C91214"},
            {"label": "Cavadoude", "value": "C91215"},
            {"label": "Caxias", "value": "C91216"},
            {"label": "Cedovim", "value": "C91217"},
            {"label": "Celorico da Beira", "value": "C91218"},
            {"label": "Cercal", "value": "C91219"},
            {"label": "Cerdeira", "value": "C91220"},
            {"label": "Cerejo", "value": "C91221"},
            {"label": "Charneca", "value": "C91222"},
            {"label": "Chas", "value": "C91223"},
            {"label": "Cheleiros", "value": "C91224"},
            {"label": "Codesseiro", "value": "C91225"},
            {"label": "Cogula", "value": "C91226"},
            {"label": "Colares", "value": "C91227"},
            {"label": "Coracao de Jesus", "value": "C91228"},
            {"label": "Coriscada", "value": "C91229"},
            {"label": "Corticada", "value": "C91230"},
            {"label": "Cortico", "value": "C91231"},
            {"label": "Cortico da Serra", "value": "C91232"},
            {"label": "Coruche", "value": "C91233"},
            {"label": "Corujeira", "value": "C91234"},
            {"label": "Cotimos", "value": "C91235"},
            {"label": "Cruz Quebrada - Dafundo", "value": "C91236"},
            {"label": "Custoias", "value": "C91237"},
            {"label": "Damaia", "value": "C91238"},
            {"label": "Dois Portos", "value": "C91239"},
            {"label": "Dornelas", "value": "C91240"},
            {"label": "Eirado", "value": "C91241"},
            {"label": "Encarnacao", "value": "C91242"},
            {"label": "Enxara do Bispo", "value": "C91243"},
            {"label": "Ericeira", "value": "C91244"},
            {"label": "Ervas Tenras", "value": "C91245"},
            {"label": "Ervedosa", "value": "C91246"},
            {"label": "Escalhao", "value": "C91247"},
            {"label": "Estoril", "value": "C91248"},
            {"label": "Faia", "value": "C91249"},
            {"label": "Falagueira", "value": "C91250"},
            {"label": "Famalicao", "value": "C91251"},
            {"label": "Famoes", "value": "C91252"},
            {"label": "Fanhoes", "value": "C91253"},
            {"label": "Fernao Joanes", "value": "C91254"},
            {"label": "Fiaes", "value": "C91255"},
            {"label": "Figueira de Castelo Rodrigo", "value": "C91256"},
            {"label": "Figueira do Guincho", "value": "C91257"},
            {"label": "Figueiro da Granja", "value": "C91258"},
            {"label": "Figueiro da Serra", "value": "C91259"},
            {"label": "Figueiros", "value": "C91260"},
            {"label": "Foios", "value": "C91261"},
            {"label": "Folgosinho", "value": "C91262"},
            {"label": "Folhadosa", "value": "C91263"},
            {"label": "Fontanelas", "value": "C91264"},
            {"label": "Fonte Longa", "value": "C91265"},
            {"label": "Forninhos", "value": "C91266"},
            {"label": "Forno Telheiro", "value": "C91267"},
            {"label": "Fornos de Algodres", "value": "C91268"},
            {"label": "Forte da Casa", "value": "C91269"},
            {"label": "Freches", "value": "C91270"},
            {"label": "Freineda", "value": "C91271"},
            {"label": "Freiria", "value": "C91272"},
            {"label": "Freixeda do Torrao", "value": "C91273"},
            {"label": "Freixedas", "value": "C91274"},
            {"label": "Freixo", "value": "C91275"},
            {"label": "Freixo de Numao", "value": "C91276"},
            {"label": "Frielas", "value": "C91277"},
            {"label": "Gagos", "value": "C91278"},
            {"label": "Girabolhos", "value": "C91279"},
            {"label": "Goncalo", "value": "C91280"},
            {"label": "Goncalo Bocas", "value": "C91281"},
            {"label": "Gouveia", "value": "C91282"},
            {"label": "Graca", "value": "C91283"},
            {"label": "Gradil", "value": "C91284"},
            {"label": "Gradiz", "value": "C91285"},
            {"label": "Granja", "value": "C91286"},
            {"label": "Guarda", "value": "C91287"},
            {"label": "Guilheiro", "value": "C91288"},
            {"label": "Horta", "value": "C91289"},
            {"label": "Igreja Nova", "value": "C91290"},
            {"label": "Infias", "value": "C91291"},
            {"label": "Jarmelo Sao Miguel", "value": "C91292"},
            {"label": "Jarmelo Sao Pedro", "value": "C91293"},
            {"label": "Joao Antao", "value": "C91294"},
            {"label": "Junca", "value": "C91295"},
            {"label": "Juncais", "value": "C91296"},
            {"label": "Lagarinhos", "value": "C91297"},
            {"label": "Lajeosa", "value": "C91298"},
            {"label": "Lajeosa do Mondego", "value": "C91299"},
            {"label": "Lajes", "value": "C91300"},
            {"label": "Lamas", "value": "C91301"},
            {"label": "Lamegal", "value": "C91302"},
            {"label": "Lameiras", "value": "C91303"},
            {"label": "Lapa", "value": "C91304"},
            {"label": "Lapa Dos Dinheiros", "value": "C91305"},
            {"label": "Leomil", "value": "C91306"},
            {"label": "Linda-a-Velha", "value": "C91307"},
            {"label": "Linhares (Celorico da Beira)", "value": "C91308"},
            {"label": "Lisbon", "value": "C91309"},
            {"label": "Longroiva", "value": "C91310"},
            {"label": "Loriga", "value": "C91311"},
            {"label": "Loures", "value": "C91312"},
            {"label": "Lourinha", "value": "C91313"},
            {"label": "Lousa", "value": "C91314"},
            {"label": "Lumiar", "value": "C91315"},
            {"label": "Macainhas", "value": "C91316"},
            {"label": "Macal do Chao", "value": "C91317"},
            {"label": "Maceira", "value": "C91318"},
            {"label": "Macussa", "value": "C91319"},
            {"label": "Madalena", "value": "C91320"},
            {"label": "Mafra", "value": "C91321"},
            {"label": "Malcata", "value": "C91322"},
            {"label": "Malhada Sorda", "value": "C91323"},
            {"label": "Malpartida", "value": "C91324"},
            {"label": "Malveira", "value": "C91325"},
            {"label": "Mangualde da Serra", "value": "C91326"},
            {"label": "Manigoto", "value": "C91327"},
            {"label": "Manique do Intendente", "value": "C91328"},
            {"label": "Manteigas", "value": "C91329"},
            {"label": "Manteigas (Sao Pedro)", "value": "C91330"},
            {"label": "Marialva", "value": "C91331"},
            {"label": "Marmeleiro", "value": "C91332"},
            {"label": "Marteleira", "value": "C91333"},
            {"label": "Martires", "value": "C91334"},
            {"label": "Marvila", "value": "C91335"},
            {"label": "Massama", "value": "C91336"},
            {"label": "Mata de Lobos", "value": "C91337"},
            {"label": "Matacaes", "value": "C91338"},
            {"label": "Matanca", "value": "C91339"},
            {"label": "Maxial", "value": "C91340"},
            {"label": "Meca", "value": "C91341"},
            {"label": "Meda", "value": "C91342"},
            {"label": "Meios", "value": "C91343"},
            {"label": "Melo", "value": "C91344"},
            {"label": "Mem Martins", "value": "C91345"},
            {"label": "Merces", "value": "C91346"},
            {"label": "Mesquitela", "value": "C91347"},
            {"label": "Milharado", "value": "C91348"},
            {"label": "Mina", "value": "C91349"},
            {"label": "Minhocal", "value": "C91350"},
            {"label": "Mira-Sintra", "value": "C91351"},
            {"label": "Miragaia", "value": "C91352"},
            {"label": "Miuzela", "value": "C91353"},
            {"label": "Mizarela", "value": "C91354"},
            {"label": "Moimenta da Serra", "value": "C91355"},
            {"label": "Moimentinha", "value": "C91356"},
            {"label": "Moita", "value": "C91357"},
            {"label": "Moita dos Ferreiros", "value": "C91358"},
            {"label": "Moledo", "value": "C91359"},
            {"label": "Monte Abraao", "value": "C91360"},
            {"label": "Monte Estoril", "value": "C91361"},
            {"label": "Monte Redondo", "value": "C91362"},
            {"label": "Montelavar", "value": "C91363"},
            {"label": "Moreira de Rei", "value": "C91364"},
            {"label": "Mos", "value": "C91365"},
            {"label": "Moscavide", "value": "C91366"},
            {"label": "Murca", "value": "C91367"},
            {"label": "Muxagata", "value": "C91368"},
            {"label": "Nabais", "value": "C91369"},
            {"label": "Nave", "value": "C91370"},
            {"label": "Nave de Haver", "value": "C91371"},
            {"label": "Nespereira", "value": "C91372"},
            {"label": "Nossa Senhora de Fatima", "value": "C91373"},
            {"label": "Numao", "value": "C91374"},
            {"label": "Odivelas", "value": "C91375"},
            {"label": "Odivelas Municipality", "value": "C91376"},
            {"label": "Oeiras", "value": "C91377"},
            {"label": "Oeiras e Sao Juliao da Barra", "value": "C91378"},
            {"label": "Olhalvo", "value": "C91379"},
            {"label": "Olival de Basto", "value": "C91380"},
            {"label": "Olival do Basto", "value": "C91381"},
            {"label": "Ota", "value": "C91382"},
            {"label": "Outeiro da Cabeca", "value": "C91383"},
            {"label": "Outeiro de Gatos", "value": "C91384"},
            {"label": "Paco de Arcos", "value": "C91385"},
            {"label": "Pacos da Serra", "value": "C91386"},
            {"label": "Painho", "value": "C91387"},
            {"label": "Pala", "value": "C91388"},
            {"label": "Palhais", "value": "C91389"},
            {"label": "Panoias de Cima", "value": "C91390"},
            {"label": "Parada", "value": "C91391"},
            {"label": "Paranhos", "value": "C91392"},
            {"label": "Parede", "value": "C91393"},
            {"label": "Pega", "value": "C91394"},
            {"label": "Pena", "value": "C91395"},
            {"label": "Pena Lobo", "value": "C91396"},
            {"label": "Pena Verde", "value": "C91397"},
            {"label": "Penha de aguia", "value": "C91398"},
            {"label": "Penha de Franca", "value": "C91399"},
            {"label": "Pera do Moco", "value": "C91400"},
            {"label": "Peral", "value": "C91401"},
            {"label": "Pereiro", "value": "C91402"},
            {"label": "Pereiro de Palhacana", "value": "C91403"},
            {"label": "Pero Moniz", "value": "C91404"},
            {"label": "Pero Pinheiro", "value": "C91405"},
            {"label": "Peva", "value": "C91406"},
            {"label": "Pinhancos", "value": "C91407"},
            {"label": "Pinheiro", "value": "C91408"},
            {"label": "Pinhel", "value": "C91409"},
            {"label": "Pinzio", "value": "C91410"},
            {"label": "Poco do Canto", "value": "C91411"},
            {"label": "Pomares", "value": "C91412"},
            {"label": "Ponte do Rol", "value": "C91413"},
            {"label": "Pontinha", "value": "C91414"},
            {"label": "Portela", "value": "C91415"},
            {"label": "Porto da Carne", "value": "C91416"},
            {"label": "Porto Salvo", "value": "C91417"},
            {"label": "Pousada", "value": "C91418"},
            {"label": "Pousafoles do Bispo", "value": "C91419"},
            {"label": "Povoa de Santa Iria", "value": "C91420"},
            {"label": "Povoa de Santo Adriao", "value": "C91421"},
            {"label": "Povoa do Concelho", "value": "C91422"},
            {"label": "Prados", "value": "C91423"},
            {"label": "Prazeres", "value": "C91424"},
            {"label": "Prior Velho", "value": "C91425"},
            {"label": "Prova", "value": "C91426"},
            {"label": "Quadrazais", "value": "C91427"},
            {"label": "Queijas", "value": "C91428"},
            {"label": "Queiriz", "value": "C91429"},
            {"label": "Queluz", "value": "C91430"},
            {"label": "Quinta de Pero Martins", "value": "C91431"},
            {"label": "Quintas de Sao Bartolomeu", "value": "C91432"},
            {"label": "Rabacal", "value": "C91433"},
            {"label": "Ramada", "value": "C91434"},
            {"label": "Ramalhal", "value": "C91435"},
            {"label": "Ramela", "value": "C91436"},
            {"label": "Ranhados", "value": "C91437"},
            {"label": "Rapa", "value": "C91438"},
            {"label": "Rapoula do Coa", "value": "C91439"},
            {"label": "Ratoeira", "value": "C91440"},
            {"label": "Reboleira", "value": "C91441"},
            {"label": "Reboleiro", "value": "C91442"},
            {"label": "Rebolosa", "value": "C91443"},
            {"label": "Reguengo Grande", "value": "C91444"},
            {"label": "Reigada", "value": "C91445"},
            {"label": "Rendo", "value": "C91446"},
            {"label": "Ribafria", "value": "C91447"},
            {"label": "Ribamar", "value": "C91448"},
            {"label": "Ribamondego", "value": "C91449"},
            {"label": "Ribeira Dos Carinhos", "value": "C91450"},
            {"label": "Rio de Mel", "value": "C91451"},
            {"label": "Rio de Mouro", "value": "C91452"},
            {"label": "Rio Torto", "value": "C91453"},
            {"label": "Rochoso", "value": "C91454"},
            {"label": "Runa", "value": "C91455"},
            {"label": "Ruvina", "value": "C91456"},
            {"label": "Sabugal", "value": "C91457"},
            {"label": "Sabugueiro", "value": "C91458"},
            {"label": "Sacavem", "value": "C91459"},
            {"label": "Sacramento", "value": "C91460"},
            {"label": "Safurdao", "value": "C91461"},
            {"label": "Salgueirais", "value": "C91462"},
            {"label": "Sameice", "value": "C91463"},
            {"label": "Sameiro", "value": "C91464"},
            {"label": "Sandomil", "value": "C91465"},
            {"label": "Santa Barbara", "value": "C91466"},
            {"label": "Santa Catarina", "value": "C91467"},
            {"label": "Santa Comba", "value": "C91468"},
            {"label": "Santa Engracia", "value": "C91469"},
            {"label": "Santa Eufemia", "value": "C91470"},
            {"label": "Santa Eulalia", "value": "C91471"},
            {"label": "Santa Iria da Azoia", "value": "C91472"},
            {"label": "Santa Iria de Azoia", "value": "C91473"},
            {"label": "Santa Isabel", "value": "C91474"},
            {"label": "Santa Justa", "value": "C91475"},
            {"label": "Santa Maria", "value": "C91476"},
            {"label": "Santa Maria de Belem", "value": "C91477"},
            {"label": "Santa Maria do Castelo e Sao Miguel", "value": "C91478"},
            {"label": "Santa Maria dos Olivais", "value": "C91479"},
            {"label": "Santa Maria e Sao Miguel", "value": "C91480"},
            {"label": "Santa Marinha", "value": "C91481"},
            {"label": "Santana da Azinha", "value": "C91482"},
            {"label": "Santiago", "value": "C91483"},
            {"label": "Santiago dos Velhos", "value": "C91484"},
            {"label": "Santo Antao do Tojal", "value": "C91485"},
            {"label": "Santo Antonio dos Cavaleiros", "value": "C91486"},
            {"label": "Santo Condestavel", "value": "C91487"},
            {"label": "Santo Estevao", "value": "C91488"},
            {"label": "Santo Estevao das Gales", "value": "C91489"},
            {"label": "Santo Isidoro", "value": "C91490"},
            {"label": "Santo Quintino", "value": "C91491"},
            {"label": "Santos-o-Velho", "value": "C91492"},
            {"label": "Sao Bartolomeu", "value": "C91493"},
            {"label": "Sao Bartolomeu dos Galegos", "value": "C91494"},
            {"label": "Sao Bras", "value": "C91495"},
            {"label": "Sao Cristovao e Sao Lourenco", "value": "C91496"},
            {"label": "Sao Domingos de Benfica", "value": "C91497"},
            {"label": "Sao Domingos de Rana", "value": "C91498"},
            {"label": "Sao Francisco Xavier", "value": "C91499"},
            {"label": "Sao Joao", "value": "C91500"},
            {"label": "Sao Joao da Talha", "value": "C91501"},
            {"label": "Sao Joao das Lampas", "value": "C91502"},
            {"label": "Sao Joao de Brito", "value": "C91503"},
            {"label": "Sao Joao de Deus", "value": "C91504"},
            {"label": "Sao Joao Dos Montes", "value": "C91505"},
            {"label": "Sao Jorge de Arroios", "value": "C91506"},
            {"label": "Sao Jose", "value": "C91507"},
            {"label": "Sao Juliao", "value": "C91508"},
            {"label": "Sao Juliao do Tojal", "value": "C91509"},
            {"label": "Sao Mamede", "value": "C91510"},
            {"label": "Sao Marcos", "value": "C91511"},
            {"label": "Sao Martinho", "value": "C91512"},
            {"label": "Sao Miguel", "value": "C91513"},
            {"label": "Sao Miguel da Guarda", "value": "C91514"},
            {"label": "Sao Miguel de Alcainca", "value": "C91515"},
            {"label": "Sao Nicolau", "value": "C91516"},
            {"label": "Sao Paio", "value": "C91517"},
            {"label": "Sao Paulo", "value": "C91518"},
            {"label": "Sao Pedro", "value": "C91519"},
            {"label": "Sao Pedro da Cadeira", "value": "C91520"},
            {"label": "Sao Pedro de Penaferrim", "value": "C91521"},
            {"label": "Sao Pedro de Rio Seco", "value": "C91522"},
            {"label": "Sao Romao", "value": "C91523"},
            {"label": "Sao Sebastiao da Pedreira", "value": "C91524"},
            {"label": "Sao Vicente", "value": "C91525"},
            {"label": "Sao Vicente de Fora", "value": "C91526"},
            {"label": "Sapataria", "value": "C91527"},
            {"label": "Sazes da Beira", "value": "C91528"},
            {"label": "Se", "value": "C91529"},
            {"label": "Sebadelhe", "value": "C91530"},
            {"label": "Sebadelhe da Serra", "value": "C91531"},
            {"label": "Seia", "value": "C91532"},
            {"label": "Seixas", "value": "C91533"},
            {"label": "Seixo do Coa", "value": "C91534"},
            {"label": "Sequeira", "value": "C91535"},
            {"label": "Sequeiros", "value": "C91536"},
            {"label": "Silveira", "value": "C91537"},
            {"label": "Sintra", "value": "C91538"},
            {"label": "Sintra (town)", "value": "C91539"},
            {"label": "Sobral da Abelheira", "value": "C91540"},
            {"label": "Sobral da Serra", "value": "C91541"},
            {"label": "Sobral de Monte Agraco", "value": "C91542"},
            {"label": "Sobral Pichorro", "value": "C91543"},
            {"label": "Sobralinho", "value": "C91544"},
            {"label": "Socorro", "value": "C91545"},
            {"label": "Sortelha", "value": "C91546"},
            {"label": "Souro Pires", "value": "C91547"},
            {"label": "Souto", "value": "C91548"},
            {"label": "Souto de Aguiar da Beira", "value": "C91549"},
            {"label": "Souto Maior", "value": "C91550"},
            {"label": "Tamanhos", "value": "C91551"},
            {"label": "Teixeira", "value": "C91552"},
            {"label": "Terrenho", "value": "C91553"},
            {"label": "Terrugem", "value": "C91554"},
            {"label": "Torre do Terrenho", "value": "C91555"},
            {"label": "Torres", "value": "C91556"},
            {"label": "Torres Vedras", "value": "C91557"},
            {"label": "Torrozelo", "value": "C91558"},
            {"label": "Touca", "value": "C91559"},
            {"label": "Tourais", "value": "C91560"},
            {"label": "Trancoso", "value": "C91561"},
            {"label": "Travancinha", "value": "C91562"},
            {"label": "Triana", "value": "C91563"},
            {"label": "Trinta", "value": "C91564"},
            {"label": "Turcifal", "value": "C91565"},
            {"label": "Unhos", "value": "C91566"},
            {"label": "Valbom", "value": "C91567"},
            {"label": "Valdujo", "value": "C91568"},
            {"label": "Vale da Mula", "value": "C91569"},
            {"label": "Vale de Amoreira", "value": "C91570"},
            {"label": "Vale de Azares", "value": "C91571"},
            {"label": "Vale de Espinho", "value": "C91572"},
            {"label": "Vale de Estrela", "value": "C91573"},
            {"label": "Vale do Paraiso", "value": "C91574"},
            {"label": "Vale do Seixo", "value": "C91575"},
            {"label": "Vale Flor", "value": "C91576"},
            {"label": "Valezim", "value": "C91577"},
            {"label": "Valhelhas", "value": "C91578"},
            {"label": "Valverde", "value": "C91579"},
            {"label": "Varzea de Meruge", "value": "C91580"},
            {"label": "Vascoveiro", "value": "C91581"},
            {"label": "Vela", "value": "C91582"},
            {"label": "Velosa", "value": "C91583"},
            {"label": "Venda do Pinheiro", "value": "C91584"},
            {"label": "Venda Nova", "value": "C91585"},
            {"label": "Venteira", "value": "C91586"},
            {"label": "Ventosa", "value": "C91587"},
            {"label": "Vermelha", "value": "C91588"},
            {"label": "Vermiosa", "value": "C91589"},
            {"label": "Vialonga", "value": "C91590"},
            {"label": "Vide", "value": "C91591"},
            {"label": "Vide Entre Vinhas", "value": "C91592"},
            {"label": "Videmonte", "value": "C91593"},
            {"label": "Vila Boa", "value": "C91594"},
            {"label": "Vila Boa do Mondego", "value": "C91595"},
            {"label": "Vila Cortes da Serra", "value": "C91596"},
            {"label": "Vila Cortes do Mondego", "value": "C91597"},
            {"label": "Vila Cova a Coelheira", "value": "C91598"},
            {"label": "Vila do Touro", "value": "C91599"},
            {"label": "Vila Fernando", "value": "C91600"},
            {"label": "Vila Franca da Serra", "value": "C91601"},
            {"label": "Vila Franca das Naves", "value": "C91602"},
            {"label": "Vila Franca de Xira", "value": "C91603"},
            {"label": "Vila Franca do Deao", "value": "C91604"},
            {"label": "Vila Franca do Rosario", "value": "C91605"},
            {"label": "Vila Garcia", "value": "C91606"},
            {"label": "Vila Nova da Rainha", "value": "C91607"},
            {"label": "Vila Nova de Foz Coa", "value": "C91608"},
            {"label": "Vila Nova de Sao Pedro", "value": "C91609"},
            {"label": "Vila Nova de Tazem", "value": "C91610"},
            {"label": "Vila Ruiva", "value": "C91611"},
            {"label": "Vila Soeiro do Chao", "value": "C91612"},
            {"label": "Vila Verde", "value": "C91613"},
            {"label": "Vila Verde dos Francos", "value": "C91614"},
            {"label": "Vilar", "value": "C91615"},
            {"label": "Vilar de Amargo", "value": "C91616"},
            {"label": "Vilar Formoso", "value": "C91617"},
            {"label": "Vilar Maior", "value": "C91618"},
            {"label": "Vilar Torpim", "value": "C91619"},
            {"label": "Vilares", "value": "C91620"},
            {"label": "Vimeiro", "value": "C91621"},
            {"label": "Vinho", "value": "C91622"}],
 "Guarico": [{"label": "Altagracia de Orituco", "value": "C147179"},
             {"label": "Calabozo", "value": "C147180"},
             {"label": "San Juan de los Morros", "value": "C147181"},
             {"label": "Valle de La Pascua", "value": "C147182"},
             {"label": "Zaraza", "value": "C147183"}],
 "Guatemala Department": [{"label": "Amatitlan", "value": "C42686"},
                          {"label": "Chinautla", "value": "C42687"},
                          {"label": "Chuarrancho", "value": "C42688"},
                          {"label": "Fraijanes", "value": "C42689"},
                          {"label": "Guatemala City", "value": "C42690"},
                          {"label": "Mixco", "value": "C42691"},
                          {"label": "Palencia", "value": "C42692"},
                          {"label": "Petapa", "value": "C42693"},
                          {"label": "San Jose del Golfo", "value": "C42694"},
                          {"label": "San Jose Pinula", "value": "C42695"},
                          {"label": "San Juan Sacatepequez", "value": "C42696"},
                          {"label": "San Pedro Ayampuc", "value": "C42697"},
                          {"label": "San Pedro Sacatepequez",
                           "value": "C42698"},
                          {"label": "San Raimundo", "value": "C42699"},
                          {"label": "Santa Catarina Pinula", "value": "C42700"},
                          {"label": "Villa Canales", "value": "C42701"},
                          {"label": "Villa Nueva", "value": "C42702"}],
 "Guaviare": [{"label": "Calamar", "value": "C20188"},
              {"label": "El Retorno", "value": "C20189"},
              {"label": "Miraflores", "value": "C20190"},
              {"label": "San Jose del Guaviare", "value": "C20191"}],
 "Guayas": [{"label": "Alfredo Baquerizo Moreno", "value": "C24132"},
            {"label": "Balao", "value": "C24133"},
            {"label": "Balzar", "value": "C24134"},
            {"label": "Colimes", "value": "C24135"},
            {"label": "Coronel Marcelino Mariduena", "value": "C24136"},
            {"label": "El Triunfo", "value": "C24137"},
            {"label": "Eloy Alfaro", "value": "C24138"},
            {"label": "Guayaquil", "value": "C24139"},
            {"label": "Lomas de Sargentillo", "value": "C24140"},
            {"label": "Milagro", "value": "C24141"},
            {"label": "Naranjal", "value": "C24142"},
            {"label": "Naranjito", "value": "C24143"},
            {"label": "Palestina", "value": "C24144"},
            {"label": "Pedro Carbo", "value": "C24145"},
            {"label": "Playas", "value": "C24146"},
            {"label": "Samborondon", "value": "C24147"},
            {"label": "Santa Lucia", "value": "C24148"},
            {"label": "Velasco Ibarra", "value": "C24149"},
            {"label": "Yaguachi Nuevo", "value": "C24150"}],
 "Guelma": [{"label": "Boumahra Ahmed", "value": "C345"},
            {"label": "Guelma", "value": "C346"},
            {"label": "Heliopolis", "value": "C347"}],
 "Guelmim-Oued Noun (EH-partial)": [{"label": "Assa-Zag", "value": "C75722"},
                                    {"label": "Guelmim", "value": "C75723"},
                                    {"label": "Sidi Ifni", "value": "C75724"},
                                    {"label": "Tan-Tan", "value": "C75725"}],
 "Guera Region": [{"label": "Bitkine", "value": "C17856"},
                  {"label": "Melfi", "value": "C17857"},
                  {"label": "Mongo", "value": "C17858"}],
 "Guerrero": [{"label": "10 de Abril", "value": "C68775"},
              {"label": "Acahuizotla", "value": "C68776"},
              {"label": "Acalco", "value": "C68777"},
              {"label": "Acamixtla", "value": "C68778"},
              {"label": "Acapetlahuaya", "value": "C68779"},
              {"label": "Acapulco de Juarez", "value": "C68780"},
              {"label": "Acatempa", "value": "C68781"},
              {"label": "Acatempan", "value": "C68782"},
              {"label": "Acatepec", "value": "C68783"},
              {"label": "Acatlan", "value": "C68784"},
              {"label": "Acuitlapan", "value": "C68785"},
              {"label": "Agua Zarca", "value": "C68786"},
              {"label": "Aguas Blancas", "value": "C68787"},
              {"label": "Aguas Calientes", "value": "C68788"},
              {"label": "Ahuacachahue (Ndogyo Itun Tichi)", "value": "C68789"},
              {"label": "Ahuacuotzingo", "value": "C68790"},
              {"label": "Ahuajutla", "value": "C68791"},
              {"label": "Ahuatepec Pueblo", "value": "C68792"},
              {"label": "Ahuehuepan", "value": "C68793"},
              {"label": "Ahuexotitlan", "value": "C68794"},
              {"label": "Ahuihuiyuco", "value": "C68795"},
              {"label": "Ajuchitlan del Progreso", "value": "C68796"},
              {"label": "Alcholoa", "value": "C68797"},
              {"label": "Alcozacan", "value": "C68798"},
              {"label": "Alcozauca de Guerrero", "value": "C68799"},
              {"label": "Almolonga", "value": "C68800"},
              {"label": "Alpoyeca", "value": "C68801"},
              {"label": "Alpuyecancingo de las Montanas", "value": "C68802"},
              {"label": "Amatillo", "value": "C68803"},
              {"label": "Amuco de la Reforma", "value": "C68804"},
              {"label": "Apalani", "value": "C68805"},
              {"label": "Apango", "value": "C68806"},
              {"label": "Apantla", "value": "C68807"},
              {"label": "Apaxtla", "value": "C68808"},
              {"label": "Apaxtla de Castrejon", "value": "C68809"},
              {"label": "Apipilulco", "value": "C68810"},
              {"label": "Aratichanguio", "value": "C68811"},
              {"label": "Arcelia", "value": "C68812"},
              {"label": "Arcelia de Rodriguez", "value": "C68813"},
              {"label": "Arenal de alvarez (Arenal de Paco)",
               "value": "C68814"},
              {"label": "Atenango del Rio", "value": "C68815"},
              {"label": "Atenxoxola", "value": "C68816"},
              {"label": "Atlamajac", "value": "C68817"},
              {"label": "Atlamajalcingo del Rio", "value": "C68818"},
              {"label": "Atliaca", "value": "C68819"},
              {"label": "Atlixtac", "value": "C68820"},
              {"label": "Atoyac de alvarez", "value": "C68821"},
              {"label": "Atzacoaloya", "value": "C68822"},
              {"label": "Axaxacualco", "value": "C68823"},
              {"label": "Axixintla", "value": "C68824"},
              {"label": "Ayahualulco", "value": "C68825"},
              {"label": "Ayotzinapa", "value": "C68826"},
              {"label": "Ayutla de los Libres", "value": "C68827"},
              {"label": "Azoyu", "value": "C68828"},
              {"label": "Bajos del Ejido", "value": "C68829"},
              {"label": "Barra de Tecoanapa", "value": "C68830"},
              {"label": "Barrio Nuevo", "value": "C68831"},
              {"label": "Barrio Nuevo de los Muertos", "value": "C68832"},
              {"label": "Buena Vista", "value": "C68833"},
              {"label": "Buena Vista de la Salud", "value": "C68834"},
              {"label": "Buenavista de Allende", "value": "C68835"},
              {"label": "Buenavista de Cuellar", "value": "C68836"},
              {"label": "Cacalotenango", "value": "C68837"},
              {"label": "Cacalutla", "value": "C68838"},
              {"label": "Cahuatache", "value": "C68839"},
              {"label": "Cajelitos", "value": "C68840"},
              {"label": "Campanario", "value": "C68841"},
              {"label": "Carrizalillo", "value": "C68842"},
              {"label": "Caxitepec", "value": "C68843"},
              {"label": "Cayaco", "value": "C68844"},
              {"label": "Cerro de Piedra", "value": "C68845"},
              {"label": "Chacalapa", "value": "C68846"},
              {"label": "Changata", "value": "C68847"},
              {"label": "Chaucingo", "value": "C68848"},
              {"label": "Chiaucingo", "value": "C68849"},
              {"label": "Chichihualco", "value": "C68850"},
              {"label": "Chiepetepec", "value": "C68851"},
              {"label": "Chilacachapa", "value": "C68852"},
              {"label": "Chilapa de alvarez", "value": "C68853"},
              {"label": "Chilpancingo", "value": "C68854"},
              {"label": "Chontalcoatlan", "value": "C68855"},
              {"label": "Ciudad Altamirano", "value": "C68856"},
              {"label": "Ciudad de Huitzuco", "value": "C68857"},
              {"label": "Coachimalco", "value": "C68858"},
              {"label": "Coacoyula de alvarez", "value": "C68859"},
              {"label": "Coacoyulichan", "value": "C68860"},
              {"label": "Coacoyulillo", "value": "C68861"},
              {"label": "Coahuayutla de Guerrero", "value": "C68862"},
              {"label": "Coahuayutla de Jose Maria Izazaga", "value": "C68863"},
              {"label": "Coatepec Costales", "value": "C68864"},
              {"label": "Cochoapa", "value": "C68865"},
              {"label": "Cochoapa el Grande", "value": "C68866"},
              {"label": "Cocula", "value": "C68867"},
              {"label": "Colombia de Guadalupe", "value": "C68868"},
              {"label": "Colonia Aeropuerto", "value": "C68869"},
              {"label": "Colonia Alborada", "value": "C68870"},
              {"label": "Colonia Constitucion", "value": "C68871"},
              {"label": "Colonia el Pedregal", "value": "C68872"},
              {"label": "Colonia Luces en el Mar", "value": "C68873"},
              {"label": "Colonia Nueva Revolucion", "value": "C68874"},
              {"label": "Colonia Renacimiento", "value": "C68875"},
              {"label": "Colotepec", "value": "C68876"},
              {"label": "Colotlipa", "value": "C68877"},
              {"label": "Copala", "value": "C68878"},
              {"label": "Copalillo", "value": "C68879"},
              {"label": "Copanatoyac", "value": "C68880"},
              {"label": "Corral Falso", "value": "C68881"},
              {"label": "Coyahualco", "value": "C68882"},
              {"label": "Coyuca de Benitez", "value": "C68883"},
              {"label": "Coyuca de Catalan", "value": "C68884"},
              {"label": "Coyuquilla Norte", "value": "C68885"},
              {"label": "Cruz Grande", "value": "C68886"},
              {"label": "Cruz Quemada", "value": "C68887"},
              {"label": "Cuajinicuilapa", "value": "C68888"},
              {"label": "Cualac", "value": "C68889"},
              {"label": "Cuanacaxtitlan", "value": "C68890"},
              {"label": "Cuatzoquitengo", "value": "C68891"},
              {"label": "Cuauhtenango", "value": "C68892"},
              {"label": "Cuautepec", "value": "C68893"},
              {"label": "Cuetzala del Progreso", "value": "C68894"},
              {"label": "Cumbre de Barranca Honda", "value": "C68895"},
              {"label": "Cuonetzingo", "value": "C68896"},
              {"label": "Cutzamala de Pinzon", "value": "C68897"},
              {"label": "Cuyuxtlahuaca", "value": "C68898"},
              {"label": "Dos Arroyos", "value": "C68899"},
              {"label": "Ejido Nuevo", "value": "C68900"},
              {"label": "Ejido Viejo", "value": "C68901"},
              {"label": "El Bejuco", "value": "C68902"},
              {"label": "El Carmen", "value": "C68903"},
              {"label": "El Coacoyul", "value": "C68904"},
              {"label": "El Conchero", "value": "C68905"},
              {"label": "El Cortes", "value": "C68906"},
              {"label": "El Cortijo", "value": "C68907"},
              {"label": "El Durazno", "value": "C68908"},
              {"label": "El Embarcadero", "value": "C68909"},
              {"label": "El Escondido", "value": "C68910"},
              {"label": "El Espinalillo", "value": "C68911"},
              {"label": "El Fresno", "value": "C68912"},
              {"label": "El Jicaral", "value": "C68913"},
              {"label": "El Limon", "value": "C68914"},
              {"label": "El Meson", "value": "C68915"},
              {"label": "El Metlapil", "value": "C68916"},
              {"label": "El Naranjito", "value": "C68917"},
              {"label": "El Ocotito", "value": "C68918"},
              {"label": "El Papayo", "value": "C68919"},
              {"label": "El Paraiso", "value": "C68920"},
              {"label": "El Pericon", "value": "C68921"},
              {"label": "El Pitahayo", "value": "C68922"},
              {"label": "El Polvorin", "value": "C68923"},
              {"label": "El Potrerillo (Potrerillo del Rincon)",
               "value": "C68924"},
              {"label": "El Refugio", "value": "C68925"},
              {"label": "El Rincon (Santa Cruz del Rincon)", "value": "C68926"},
              {"label": "El Salto", "value": "C68927"},
              {"label": "El Suchil", "value": "C68928"},
              {"label": "El Tejocote", "value": "C68929"},
              {"label": "El Terrero", "value": "C68930"},
              {"label": "El Ticui", "value": "C68931"},
              {"label": "Escalerilla Lagunas", "value": "C68932"},
              {"label": "Fermin Rabadan Cervantes", "value": "C68933"},
              {"label": "Florencio Villarreal", "value": "C68934"},
              {"label": "General Canuto A. Neri", "value": "C68935"},
              {"label": "General Heliodoro Castillo", "value": "C68936"},
              {"label": "Guadalupe Victoria", "value": "C68937"},
              {"label": "Guayameo", "value": "C68938"},
              {"label": "Hacienda de Cabanas", "value": "C68939"},
              {"label": "Horcasitas", "value": "C68940"},
              {"label": "Huamuchapa", "value": "C68941"},
              {"label": "Huamuxtitlan", "value": "C68942"},
              {"label": "Huehuetan", "value": "C68943"},
              {"label": "Hueycantenango", "value": "C68944"},
              {"label": "Hueyitlalpan", "value": "C68945"},
              {"label": "Huitziltepec", "value": "C68946"},
              {"label": "Huitzuco de los Figueroa", "value": "C68947"},
              {"label": "Huixtepec", "value": "C68948"},
              {"label": "Iguala de la Independencia", "value": "C68949"},
              {"label": "Igualapa", "value": "C68950"},
              {"label": "Iliatenco", "value": "C68951"},
              {"label": "Ixcamilpa", "value": "C68952"},
              {"label": "Ixcapuzalco", "value": "C68953"},
              {"label": "Ixcateopan", "value": "C68954"},
              {"label": "Ixcateopan de Cuauhtemoc", "value": "C68955"},
              {"label": "Ixcatla", "value": "C68956"},
              {"label": "Ixtapa", "value": "C68957"},
              {"label": "Ixtapa-Zihuatanejo", "value": "C68958"},
              {"label": "Izotepec", "value": "C68959"},
              {"label": "Jalapa", "value": "C68960"},
              {"label": "Jaleaca de Catalan", "value": "C68961"},
              {"label": "Jicayan de Tovar", "value": "C68962"},
              {"label": "Jolotichan", "value": "C68963"},
              {"label": "Julian Blanco (Dos Caminos)", "value": "C68964"},
              {"label": "Kilometro 30", "value": "C68965"},
              {"label": "Kilometro 40", "value": "C68966"},
              {"label": "La Azozuca", "value": "C68967"},
              {"label": "La Concepcion", "value": "C68968"},
              {"label": "La Concepcion (La Concha)", "value": "C68969"},
              {"label": "La Concordia", "value": "C68970"},
              {"label": "La Dicha", "value": "C68971"},
              {"label": "La Esperanza", "value": "C68972"},
              {"label": "La Estacion", "value": "C68973"},
              {"label": "La Guadalupe", "value": "C68974"},
              {"label": "La Luz de Juarez", "value": "C68975"},
              {"label": "La Mohonera", "value": "C68976"},
              {"label": "La Palma", "value": "C68977"},
              {"label": "La Soledad", "value": "C68978"},
              {"label": "La Union", "value": "C68979"},
              {"label": "Lagunillas", "value": "C68980"},
              {"label": "Las animas", "value": "C68981"},
              {"label": "Las Compuertas", "value": "C68982"},
              {"label": "Las Cruces", "value": "C68983"},
              {"label": "Las Lomas", "value": "C68984"},
              {"label": "Las Mesas", "value": "C68985"},
              {"label": "Las Plazuelas", "value": "C68986"},
              {"label": "Las Tunas", "value": "C68987"},
              {"label": "Las Vigas", "value": "C68988"},
              {"label": "Llano de la Puerta", "value": "C68989"},
              {"label": "Llano Grande", "value": "C68990"},
              {"label": "Lodo Grande", "value": "C68991"},
              {"label": "Lomas de Chapultepec", "value": "C68992"},
              {"label": "Lomas de San Juan", "value": "C68993"},
              {"label": "Los Achotes", "value": "C68994"},
              {"label": "Los Almendros", "value": "C68995"},
              {"label": "Los Liros", "value": "C68996"},
              {"label": "Los Mogotes", "value": "C68997"},
              {"label": "Los organos de San Agustin", "value": "C68998"},
              {"label": "Los Sauces", "value": "C68999"},
              {"label": "Los Saucitos", "value": "C69000"},
              {"label": "Los Tepetates", "value": "C69001"},
              {"label": "Malinaltepec", "value": "C69002"},
              {"label": "Marquelia", "value": "C69003"},
              {"label": "Maxela", "value": "C69004"},
              {"label": "Mayanalan", "value": "C69005"},
              {"label": "Mazatlan", "value": "C69006"},
              {"label": "Melchor Ocampo", "value": "C69007"},
              {"label": "Metlapa", "value": "C69008"},
              {"label": "Metlatonoc", "value": "C69009"},
              {"label": "Mezcala", "value": "C69010"},
              {"label": "Mochitlan", "value": "C69011"},
              {"label": "Mohoneras", "value": "C69012"},
              {"label": "Montecillos", "value": "C69013"},
              {"label": "Morelita", "value": "C69014"},
              {"label": "Moyotepec (Moyotepec de Juarez)", "value": "C69015"},
              {"label": "Nejapa", "value": "C69016"},
              {"label": "Nueva Frontera", "value": "C69017"},
              {"label": "Nuevo Balsas", "value": "C69018"},
              {"label": "Nuevo Guerrero", "value": "C69019"},
              {"label": "Nuxco", "value": "C69020"},
              {"label": "Ocotequila", "value": "C69021"},
              {"label": "Ocotillo", "value": "C69022"},
              {"label": "Ocotlan", "value": "C69023"},
              {"label": "Olinala", "value": "C69024"},
              {"label": "Ometepec", "value": "C69025"},
              {"label": "Oxtotitlan", "value": "C69026"},
              {"label": "Oztotitlan", "value": "C69027"},
              {"label": "Paintla", "value": "C69028"},
              {"label": "Palmillas", "value": "C69029"},
              {"label": "Palo Blanco", "value": "C69030"},
              {"label": "Palos Blancos", "value": "C69031"},
              {"label": "Pantitlan", "value": "C69032"},
              {"label": "Pantla", "value": "C69033"},
              {"label": "Papanoa", "value": "C69034"},
              {"label": "Paraje Montero (Paraje Montero de Zaragoza)",
               "value": "C69035"},
              {"label": "Pascala del Oro", "value": "C69036"},
              {"label": "Paso de Arena", "value": "C69037"},
              {"label": "Paso Morelos", "value": "C69038"},
              {"label": "Patambo", "value": "C69039"},
              {"label": "Patlicha", "value": "C69040"},
              {"label": "Petacalco", "value": "C69041"},
              {"label": "Petaquillas", "value": "C69042"},
              {"label": "Petatlan", "value": "C69043"},
              {"label": "Pilcaya", "value": "C69044"},
              {"label": "Placeres del Oro", "value": "C69045"},
              {"label": "Platanillo", "value": "C69046"},
              {"label": "Pochahuizco", "value": "C69047"},
              {"label": "Pochotillo", "value": "C69048"},
              {"label": "Pochutla", "value": "C69049"},
              {"label": "Pololcingo", "value": "C69050"},
              {"label": "Pueblo Hidalgo", "value": "C69051"},
              {"label": "Pueblo Madero (El Playon)", "value": "C69052"},
              {"label": "Pueblo Viejo", "value": "C69053"},
              {"label": "Pungarabato", "value": "C69054"},
              {"label": "Quechultenango", "value": "C69055"},
              {"label": "Querendas", "value": "C69056"},
              {"label": "Quetzalapa", "value": "C69057"},
              {"label": "Rancho de las Lomas", "value": "C69058"},
              {"label": "Rancho Viejo", "value": "C69059"},
              {"label": "Rancho Viejo (Rancho Nuevo de la Democracia)",
               "value": "C69060"},
              {"label": "Sabanillas", "value": "C69061"},
              {"label": "San Agustin Oapan", "value": "C69062"},
              {"label": "San Andres Playa Encantada (El Podrido)",
               "value": "C69063"},
              {"label": "San angel", "value": "C69064"},
              {"label": "San Antonio Coyahuacan", "value": "C69065"},
              {"label": "San Antonio de las Huertas", "value": "C69066"},
              {"label": "San Cristobal", "value": "C69067"},
              {"label": "San Francisco", "value": "C69068"},
              {"label": "San Francisco Oxtutla", "value": "C69069"},
              {"label": "San Francisco Ozomatlan", "value": "C69070"},
              {"label": "San Gregorio", "value": "C69071"},
              {"label": "San Isidro Gallinero (El Gallinero)",
               "value": "C69072"},
              {"label": "San Jeronimito", "value": "C69073"},
              {"label": "San Jeronimo de Juarez", "value": "C69074"},
              {"label": "San Jeronimo el Grande", "value": "C69075"},
              {"label": "San Jose Ixtapa (Barrio Viejo)", "value": "C69076"},
              {"label": "San Jose la Hacienda", "value": "C69077"},
              {"label": "San Jose Poliutla", "value": "C69078"},
              {"label": "San Juan de Dios (Naranjas de Dios)",
               "value": "C69079"},
              {"label": "San Juan Totolcintla", "value": "C69080"},
              {"label": "San Lorenzo", "value": "C69081"},
              {"label": "San Luis Acatlan", "value": "C69082"},
              {"label": "San Luis de La Loma", "value": "C69083"},
              {"label": "San Luis San Pedro", "value": "C69084"},
              {"label": "San Marcos", "value": "C69085"},
              {"label": "San Martin", "value": "C69086"},
              {"label": "San Martin Pachivia (Pachivia)", "value": "C69087"},
              {"label": "San Martin Peras", "value": "C69088"},
              {"label": "San Miguel Axoxuca", "value": "C69089"},
              {"label": "San Miguel de las Palmas", "value": "C69090"},
              {"label": "San Miguel el Grande", "value": "C69091"},
              {"label": "San Miguel Tecuiciapan", "value": "C69092"},
              {"label": "San Miguel Totolapan", "value": "C69093"},
              {"label": "San Miguelito", "value": "C69094"},
              {"label": "San Nicolas", "value": "C69095"},
              {"label": "San Pablo Atzompa", "value": "C69096"},
              {"label": "San Pedro Cacahuatepec", "value": "C69097"},
              {"label": "San Pedro Cuitlapan", "value": "C69098"},
              {"label": "San Pedro Huitzapula Norte (Huitzapula Norte)",
               "value": "C69099"},
              {"label": "San Pedro las Playas", "value": "C69100"},
              {"label": "San Rafael", "value": "C69101"},
              {"label": "San Vicente", "value": "C69102"},
              {"label": "San Vicente Palapa", "value": "C69103"},
              {"label": "San Vicente Zoyatlan", "value": "C69104"},
              {"label": "Santa Anita", "value": "C69105"},
              {"label": "Santa Barbara", "value": "C69106"},
              {"label": "Santa Catarina", "value": "C69107"},
              {"label": "Santa Cruz", "value": "C69108"},
              {"label": "Santa Cruz Yucucani", "value": "C69109"},
              {"label": "Santa Fe Tepetlapa", "value": "C69110"},
              {"label": "Santa Maria", "value": "C69111"},
              {"label": "Santa Teresa", "value": "C69112"},
              {"label": "Santiago Tilapa", "value": "C69113"},
              {"label": "Sinahua", "value": "C69114"},
              {"label": "Tanganhuato", "value": "C69115"},
              {"label": "Taxco el Viejo", "value": "C69116"},
              {"label": "Tecalpulco", "value": "C69117"},
              {"label": "Tecolcuautla", "value": "C69118"},
              {"label": "Tecpan de Galeana", "value": "C69119"},
              {"label": "Tecuexcontitlan (Tecoescontitlan)", "value": "C69120"},
              {"label": "Tehuilotepec", "value": "C69121"},
              {"label": "Tehuixtla", "value": "C69122"},
              {"label": "Teloloapan", "value": "C69123"},
              {"label": "Tenexpa", "value": "C69124"},
              {"label": "Tepechicotlan", "value": "C69125"},
              {"label": "Tepecoacuilco de Trujano", "value": "C69126"},
              {"label": "Tepetixtla", "value": "C69127"},
              {"label": "Tepozcuautla", "value": "C69128"},
              {"label": "Tequicuilco", "value": "C69129"},
              {"label": "Tetipac", "value": "C69130"},
              {"label": "Tetitlan", "value": "C69131"},
              {"label": "Texca", "value": "C69132"},
              {"label": "Tierra Colorada", "value": "C69133"},
              {"label": "Tixtla de Guerrero", "value": "C69134"},
              {"label": "Tixtlancingo", "value": "C69135"},
              {"label": "Tlacoachistlahuaca", "value": "C69136"},
              {"label": "Tlacoapa", "value": "C69137"},
              {"label": "Tlacoaxtla", "value": "C69138"},
              {"label": "Tlacotepec", "value": "C69139"},
              {"label": "Tlahuapa", "value": "C69140"},
              {"label": "Tlalchapa", "value": "C69141"},
              {"label": "Tlalcozotitlan", "value": "C69142"},
              {"label": "Tlalixtaquilla", "value": "C69143"},
              {"label": "Tlaltempanapa", "value": "C69144"},
              {"label": "Tlamacazapa", "value": "C69145"},
              {"label": "Tlamixtlahuacan", "value": "C69146"},
              {"label": "Tlanipatla", "value": "C69147"},
              {"label": "Tlapa de Comonfort", "value": "C69148"},
              {"label": "Tlapehuala", "value": "C69149"},
              {"label": "Tlaquiltepec", "value": "C69150"},
              {"label": "Tlatlauquitepec", "value": "C69151"},
              {"label": "Tlatzala", "value": "C69152"},
              {"label": "Tlaxcalixtlahuaca", "value": "C69153"},
              {"label": "Tlaxinga (Clatzinga)", "value": "C69154"},
              {"label": "Tlaxmalac", "value": "C69155"},
              {"label": "Tonala", "value": "C69156"},
              {"label": "Tonalapa", "value": "C69157"},
              {"label": "Tonalapa del Sur", "value": "C69158"},
              {"label": "Topiltepec", "value": "C69159"},
              {"label": "Totomixtlahuaca", "value": "C69160"},
              {"label": "Tototepec", "value": "C69161"},
              {"label": "Tres Palos", "value": "C69162"},
              {"label": "Tuliman", "value": "C69163"},
              {"label": "Tuncingo", "value": "C69164"},
              {"label": "Tutepec", "value": "C69165"},
              {"label": "Tuxpan", "value": "C69166"},
              {"label": "Valle del Rio", "value": "C69167"},
              {"label": "Valle Luz", "value": "C69168"},
              {"label": "Vallecitos de Zaragoza", "value": "C69169"},
              {"label": "Villa Hermosa", "value": "C69170"},
              {"label": "Villa Hermosa (Las Pozas)", "value": "C69171"},
              {"label": "Villa Hidalgo (El Cubo)", "value": "C69172"},
              {"label": "Villa Madero", "value": "C69173"},
              {"label": "Villa Nicolas Bravo", "value": "C69174"},
              {"label": "Villa Rotaria", "value": "C69175"},
              {"label": "Xalatzala", "value": "C69176"},
              {"label": "Xalitla", "value": "C69177"},
              {"label": "Xalpatlahuac", "value": "C69178"},
              {"label": "Xaltianguis", "value": "C69179"},
              {"label": "Xochapa", "value": "C69180"},
              {"label": "Xochihuehuetlan", "value": "C69181"},
              {"label": "Xochipala", "value": "C69182"},
              {"label": "Xochistlahuaca", "value": "C69183"},
              {"label": "Xochitepec", "value": "C69184"},
              {"label": "Xocoyolzintla", "value": "C69185"},
              {"label": "Yetla", "value": "C69186"},
              {"label": "Yetlancingo", "value": "C69187"},
              {"label": "Yextla", "value": "C69188"},
              {"label": "Yoloxochitl", "value": "C69189"},
              {"label": "Zacacoyuca", "value": "C69190"},
              {"label": "Zacapuato", "value": "C69191"},
              {"label": "Zacatula", "value": "C69192"},
              {"label": "Zacoalpan", "value": "C69193"},
              {"label": "Zacualpan", "value": "C69194"},
              {"label": "Zapotitlan Tablas", "value": "C69195"},
              {"label": "Zelocotitlan", "value": "C69196"},
              {"label": "Zilacayotitlan", "value": "C69197"},
              {"label": "Zirandaro", "value": "C69198"},
              {"label": "Zirandaro de los Chavez", "value": "C69199"},
              {"label": "Zitlala", "value": "C69200"},
              {"label": "Zizicazapa", "value": "C69201"},
              {"label": "Zoquiapa", "value": "C69202"},
              {"label": "Zoquitlan", "value": "C69203"},
              {"label": "Zotoltitlan", "value": "C69204"},
              {"label": "Zoyatlan", "value": "C69205"},
              {"label": "Zumpango", "value": "C69206"},
              {"label": "Zumpango del Rio", "value": "C69207"}],
 "Guidimaka": [{"label": "Selibaby", "value": "C65951"}],
 "Guizhou": [{"label": "Anshun", "value": "C18532"},
             {"label": "Aoshi", "value": "C18533"},
             {"label": "Bahuang", "value": "C18534"},
             {"label": "Baishi", "value": "C18535"},
             {"label": "Bangdong", "value": "C18536"},
             {"label": "Benchu", "value": "C18537"},
             {"label": "Bijie", "value": "C18538"},
             {"label": "Chadian", "value": "C18539"},
             {"label": "Changsha", "value": "C18540"},
             {"label": "Chumi", "value": "C18541"},
             {"label": "Dabachang", "value": "C18542"},
             {"label": "Darong", "value": "C18543"},
             {"label": "Dundong", "value": "C18544"},
             {"label": "Duyun", "value": "C18545"},
             {"label": "Gaoniang", "value": "C18546"},
             {"label": "Gaowu", "value": "C18547"},
             {"label": "Gaozeng", "value": "C18548"},
             {"label": "Guandu", "value": "C18549"},
             {"label": "Guiyang", "value": "C18550"},
             {"label": "Hongzhou", "value": "C18551"},
             {"label": "Huaqiu", "value": "C18552"},
             {"label": "Lantian", "value": "C18553"},
             {"label": "Liangcunchang", "value": "C18554"},
             {"label": "Liupanshui", "value": "C18555"},
             {"label": "Longlisuo", "value": "C18556"},
             {"label": "Loushanguan", "value": "C18557"},
             {"label": "Maoping", "value": "C18558"},
             {"label": "Ouyang", "value": "C18559"},
             {"label": "Pingjiang", "value": "C18560"},
             {"label": "Qiandongnan Miao and Dong Autonomous Prefecture",
              "value": "C18561"},
             {"label": "Qianxinan Bouyeizu Miaozu Zizhizhou",
              "value": "C18562"},
             {"label": "Qimeng", "value": "C18563"},
             {"label": "Qinglang", "value": "C18564"},
             {"label": "Runsong", "value": "C18565"},
             {"label": "Sanchahe", "value": "C18566"},
             {"label": "Sangmu", "value": "C18567"},
             {"label": "Shiqian", "value": "C18568"},
             {"label": "Songkan", "value": "C18569"},
             {"label": "Tingdong", "value": "C18570"},
             {"label": "Tonggu", "value": "C18571"},
             {"label": "Tongren", "value": "C18572"},
             {"label": "Tongren Diqu", "value": "C18573"},
             {"label": "Weining", "value": "C18574"},
             {"label": "Wenshui", "value": "C18575"},
             {"label": "Xiajiang", "value": "C18576"},
             {"label": "Xiaoweizhai", "value": "C18577"},
             {"label": "Xinzhan", "value": "C18578"},
             {"label": "Xishan", "value": "C18579"},
             {"label": "Xujiaba", "value": "C18580"},
             {"label": "Yangtou", "value": "C18581"},
             {"label": "Youyupu", "value": "C18582"},
             {"label": "Zhongchao", "value": "C18583"},
             {"label": "Zhujiachang", "value": "C18584"},
             {"label": "Zunyi", "value": "C18585"}],
 "Gujarat": [{"label": "Abrama", "value": "C45468"},
             {"label": "Adalaj", "value": "C45469"},
             {"label": "Agol", "value": "C45470"},
             {"label": "Ahmedabad", "value": "C45471"},
             {"label": "Ahwa", "value": "C45472"},
             {"label": "Akrund", "value": "C45473"},
             {"label": "Amod", "value": "C45474"},
             {"label": "Amod,", "value": "C45475"},
             {"label": "Amreli", "value": "C45476"},
             {"label": "Amroli", "value": "C45477"},
             {"label": "Anand", "value": "C45478"},
             {"label": "Anjar", "value": "C45479"},
             {"label": "Ankleshwar", "value": "C45480"},
             {"label": "Babra", "value": "C45481"},
             {"label": "Bagasara", "value": "C45482"},
             {"label": "Bagasra", "value": "C45483"},
             {"label": "Bakharla", "value": "C45484"},
             {"label": "Balagam", "value": "C45485"},
             {"label": "Balasinor", "value": "C45486"},
             {"label": "Balisana", "value": "C45487"},
             {"label": "Bamanbore", "value": "C45488"},
             {"label": "Banas Kantha", "value": "C45489"},
             {"label": "Bandia", "value": "C45490"},
             {"label": "Bantva", "value": "C45491"},
             {"label": "Bardoli", "value": "C45492"},
             {"label": "Bedi", "value": "C45493"},
             {"label": "Bhachau", "value": "C45494"},
             {"label": "Bhadran", "value": "C45495"},
             {"label": "Bhandu", "value": "C45496"},
             {"label": "Bhanvad", "value": "C45497"},
             {"label": "Bharuch", "value": "C45498"},
             {"label": "Bhatha", "value": "C45499"},
             {"label": "Bhavnagar", "value": "C45500"},
             {"label": "Bhayavadar", "value": "C45501"},
             {"label": "Bhildi", "value": "C45502"},
             {"label": "Bhojpur Dharampur", "value": "C45503"},
             {"label": "Bhuj", "value": "C45504"},
             {"label": "Bilimora", "value": "C45505"},
             {"label": "Bilkha", "value": "C45506"},
             {"label": "Borsad", "value": "C45507"},
             {"label": "Botad", "value": "C45508"},
             {"label": "Chaklasi", "value": "C45509"},
             {"label": "Chalala", "value": "C45510"},
             {"label": "Chaloda", "value": "C45511"},
             {"label": "Champaner", "value": "C45512"},
             {"label": "Chanasma", "value": "C45513"},
             {"label": "Chhala", "value": "C45514"},
             {"label": "Chhota Udepur", "value": "C45515"},
             {"label": "Chikhli", "value": "C45516"},
             {"label": "Chotila", "value": "C45517"},
             {"label": "Chuda", "value": "C45518"},
             {"label": "Dabhoda", "value": "C45519"},
             {"label": "Dabhoi", "value": "C45520"},
             {"label": "Dahegam", "value": "C45521"},
             {"label": "Dahod", "value": "C45522"},
             {"label": "Dakor", "value": "C45523"},
             {"label": "Damnagar", "value": "C45524"},
             {"label": "Dandi", "value": "C45525"},
             {"label": "Dangs (India)", "value": "C45526"},
             {"label": "Danta", "value": "C45527"},
             {"label": "Dayapar", "value": "C45528"},
             {"label": "Delvada", "value": "C45529"},
             {"label": "Delwada", "value": "C45530"},
             {"label": "Detroj", "value": "C45531"},
             {"label": "Devbhumi Dwarka", "value": "C45532"},
             {"label": "Devgadh Bariya", "value": "C45533"},
             {"label": "Dhandhuka", "value": "C45534"},
             {"label": "Dhanera", "value": "C45535"},
             {"label": "Dhansura", "value": "C45536"},
             {"label": "Dharampur", "value": "C45537"},
             {"label": "Dharasana", "value": "C45538"},
             {"label": "Dhari", "value": "C45539"},
             {"label": "Dhasa", "value": "C45540"},
             {"label": "Dhola", "value": "C45541"},
             {"label": "Dholera", "value": "C45542"},
             {"label": "Dholka", "value": "C45543"},
             {"label": "Dhoraji", "value": "C45544"},
             {"label": "Dhrangadhra", "value": "C45545"},
             {"label": "Dhrol", "value": "C45546"},
             {"label": "Dhuwaran", "value": "C45547"},
             {"label": "Disa", "value": "C45548"},
             {"label": "Dohad", "value": "C45549"},
             {"label": "Dumkhal", "value": "C45550"},
             {"label": "Dungarpur", "value": "C45551"},
             {"label": "Dwarka", "value": "C45552"},
             {"label": "Gadhada", "value": "C45553"},
             {"label": "Gandevi", "value": "C45554"},
             {"label": "Gandhidham", "value": "C45555"},
             {"label": "Gandhinagar", "value": "C45556"},
             {"label": "Gariadhar", "value": "C45557"},
             {"label": "Ghodasar", "value": "C45558"},
             {"label": "Ghogha", "value": "C45559"},
             {"label": "Gir Somnath", "value": "C45560"},
             {"label": "Godhra", "value": "C45561"},
             {"label": "Gondal", "value": "C45562"},
             {"label": "Gorwa", "value": "C45563"},
             {"label": "Halenda", "value": "C45564"},
             {"label": "Halol", "value": "C45565"},
             {"label": "Halvad", "value": "C45566"},
             {"label": "Hansot", "value": "C45567"},
             {"label": "Harij", "value": "C45568"},
             {"label": "Harsol", "value": "C45569"},
             {"label": "Hathuran", "value": "C45570"},
             {"label": "Himatnagar", "value": "C45571"},
             {"label": "Idar", "value": "C45572"},
             {"label": "Jakhau", "value": "C45573"},
             {"label": "Jalalpore", "value": "C45574"},
             {"label": "Jalalpur", "value": "C45575"},
             {"label": "Jalia", "value": "C45576"},
             {"label": "Jambuda", "value": "C45577"},
             {"label": "Jambusar", "value": "C45578"},
             {"label": "Jamnagar", "value": "C45579"},
             {"label": "Jarod,", "value": "C45580"},
             {"label": "Jasdan", "value": "C45581"},
             {"label": "Jetalpur", "value": "C45582"},
             {"label": "Jetalsar", "value": "C45583"},
             {"label": "Jetpur", "value": "C45584"},
             {"label": "Jetpur (Navagadh)", "value": "C45585"},
             {"label": "Jhalod", "value": "C45586"},
             {"label": "Jhulasan", "value": "C45587"},
             {"label": "Jodhpur", "value": "C45588"},
             {"label": "Jodhpur (Ahmedabad)", "value": "C45589"},
             {"label": "Jodia", "value": "C45590"},
             {"label": "Jodiya Bandar", "value": "C45591"},
             {"label": "Junagadh", "value": "C45592"},
             {"label": "Kachchh", "value": "C45593"},
             {"label": "Kachholi", "value": "C45594"},
             {"label": "Kadi", "value": "C45595"},
             {"label": "Kadod", "value": "C45596"},
             {"label": "Kalavad", "value": "C45597"},
             {"label": "Kalol", "value": "C45598"},
             {"label": "Kandla", "value": "C45599"},
             {"label": "Kandla port", "value": "C45600"},
             {"label": "Kanodar", "value": "C45601"},
             {"label": "Kapadvanj", "value": "C45602"},
             {"label": "Karamsad", "value": "C45603"},
             {"label": "Kariana", "value": "C45604"},
             {"label": "Karjan", "value": "C45605"},
             {"label": "Kathor", "value": "C45606"},
             {"label": "Katpur", "value": "C45607"},
             {"label": "Kavant", "value": "C45608"},
             {"label": "Kawant", "value": "C45609"},
             {"label": "Kayavarohan", "value": "C45610"},
             {"label": "Kerwada", "value": "C45611"},
             {"label": "Keshod", "value": "C45612"},
             {"label": "Khambhalia", "value": "C45613"},
             {"label": "Khambhat", "value": "C45614"},
             {"label": "Khavda", "value": "C45615"},
             {"label": "Kheda", "value": "C45616"},
             {"label": "Khedbrahma", "value": "C45617"},
             {"label": "Khedoi", "value": "C45618"},
             {"label": "Kherali", "value": "C45619"},
             {"label": "Kheralu", "value": "C45620"},
             {"label": "Kodinar", "value": "C45621"},
             {"label": "Kosamba", "value": "C45622"},
             {"label": "Kothara", "value": "C45623"},
             {"label": "Kotharia", "value": "C45624"},
             {"label": "Kukarmunda", "value": "C45625"},
             {"label": "Kukma", "value": "C45626"},
             {"label": "Kundla", "value": "C45627"},
             {"label": "Kutch district", "value": "C45628"},
             {"label": "Kutiyana", "value": "C45629"},
             {"label": "Ladol", "value": "C45630"},
             {"label": "Lakhpat", "value": "C45631"},
             {"label": "Lakhtar", "value": "C45632"},
             {"label": "Lalpur", "value": "C45633"},
             {"label": "Langhnaj", "value": "C45634"},
             {"label": "Lathi", "value": "C45635"},
             {"label": "Limbdi", "value": "C45636"},
             {"label": "Limkheda", "value": "C45637"},
             {"label": "Lunavada", "value": "C45638"},
             {"label": "Madhavpur Ghed", "value": "C45639"},
             {"label": "Madhi", "value": "C45640"},
             {"label": "Mahemdavad", "value": "C45641"},
             {"label": "Mahesana", "value": "C45642"},
             {"label": "Mahisa", "value": "C45643"},
             {"label": "Mahudha", "value": "C45644"},
             {"label": "Mahuva", "value": "C45645"},
             {"label": "Mahuva (Surat)", "value": "C45646"},
             {"label": "Malpur", "value": "C45647"},
             {"label": "Manavadar", "value": "C45648"},
             {"label": "Mandal", "value": "C45649"},
             {"label": "Mandvi", "value": "C45650"},
             {"label": "Mandvi (Surat)", "value": "C45651"},
             {"label": "Mangrol", "value": "C45652"},
             {"label": "Mangrol (Junagadh)", "value": "C45653"},
             {"label": "Mansa", "value": "C45654"},
             {"label": "Meghraj", "value": "C45655"},
             {"label": "Mehsana", "value": "C45656"},
             {"label": "Mendarda", "value": "C45657"},
             {"label": "Mithapur", "value": "C45658"},
             {"label": "Modasa", "value": "C45659"},
             {"label": "Morbi", "value": "C45660"},
             {"label": "Morva (Hadaf)", "value": "C45661"},
             {"label": "Morwa", "value": "C45662"},
             {"label": "Mundra", "value": "C45663"},
             {"label": "Nadiad", "value": "C45664"},
             {"label": "Nadiad,", "value": "C45665"},
             {"label": "Nagwa", "value": "C45666"},
             {"label": "Naldhara", "value": "C45667"},
             {"label": "Naliya", "value": "C45668"},
             {"label": "Nargol", "value": "C45669"},
             {"label": "Narmada", "value": "C45670"},
             {"label": "Naroda", "value": "C45671"},
             {"label": "Navsari", "value": "C45672"},
             {"label": "Nikora", "value": "C45673"},
             {"label": "Nizar", "value": "C45674"},
             {"label": "Odadar", "value": "C45675"},
             {"label": "Okha", "value": "C45676"},
             {"label": "Olpad", "value": "C45677"},
             {"label": "Paddhari", "value": "C45678"},
             {"label": "Padra", "value": "C45679"},
             {"label": "Palanpur", "value": "C45680"},
             {"label": "Palanswa", "value": "C45681"},
             {"label": "Palitana", "value": "C45682"},
             {"label": "Paliyad", "value": "C45683"},
             {"label": "Paliyad (Bhavnagar)", "value": "C45684"},
             {"label": "Palsana", "value": "C45685"},
             {"label": "Panch Mahals", "value": "C45686"},
             {"label": "Panchmahal district", "value": "C45687"},
             {"label": "Pardi", "value": "C45688"},
             {"label": "Parnera", "value": "C45689"},
             {"label": "Patan", "value": "C45690"},
             {"label": "Pavi Jetpur", "value": "C45691"},
             {"label": "Petlad", "value": "C45692"},
             {"label": "Pipavav", "value": "C45693"},
             {"label": "Piplod", "value": "C45694"},
             {"label": "Porbandar", "value": "C45695"},
             {"label": "Prabhas Patan", "value": "C45696"},
             {"label": "Prantij", "value": "C45697"},
             {"label": "Radhanpur", "value": "C45698"},
             {"label": "Rajkot", "value": "C45699"},
             {"label": "Rajpipla", "value": "C45700"},
             {"label": "Rajula", "value": "C45701"},
             {"label": "Ranavav", "value": "C45702"},
             {"label": "Ranpur", "value": "C45703"},
             {"label": "Rapar", "value": "C45704"},
             {"label": "Reha", "value": "C45705"},
             {"label": "Roha", "value": "C45706"},
             {"label": "Sabar Kantha", "value": "C45707"},
             {"label": "Sachin", "value": "C45708"},
             {"label": "Salaya", "value": "C45709"},
             {"label": "Samakhiali", "value": "C45710"},
             {"label": "Sanand", "value": "C45711"},
             {"label": "Sankheda", "value": "C45712"},
             {"label": "Sarbhon", "value": "C45713"},
             {"label": "Sardoi", "value": "C45714"},
             {"label": "Sarkhej", "value": "C45715"},
             {"label": "Sathamba", "value": "C45716"},
             {"label": "Savarkundla", "value": "C45717"},
             {"label": "Savli", "value": "C45718"},
             {"label": "Sayla", "value": "C45719"},
             {"label": "Shahpur", "value": "C45720"},
             {"label": "Shivrajpur", "value": "C45721"},
             {"label": "Siddhpur", "value": "C45722"},
             {"label": "Sihor", "value": "C45723"},
             {"label": "Sikka", "value": "C45724"},
             {"label": "Sinor", "value": "C45725"},
             {"label": "Sojitra", "value": "C45726"},
             {"label": "Songadh", "value": "C45727"},
             {"label": "Supedi", "value": "C45728"},
             {"label": "Surat", "value": "C45729"},
             {"label": "Surendranagar", "value": "C45730"},
             {"label": "Sutrapada", "value": "C45731"},
             {"label": "Talaja", "value": "C45732"},
             {"label": "Tankara", "value": "C45733"},
             {"label": "Tapi", "value": "C45734"},
             {"label": "Than", "value": "C45735"},
             {"label": "Thangadh", "value": "C45736"},
             {"label": "Tharad", "value": "C45737"},
             {"label": "Thasra", "value": "C45738"},
             {"label": "The Dangs", "value": "C45739"},
             {"label": "Umarpada", "value": "C45740"},
             {"label": "Umrala", "value": "C45741"},
             {"label": "Umreth", "value": "C45742"},
             {"label": "Un", "value": "C45743"},
             {"label": "Una", "value": "C45744"},
             {"label": "Unjha", "value": "C45745"},
             {"label": "Upleta", "value": "C45746"},
             {"label": "Utran", "value": "C45747"},
             {"label": "Vadgam", "value": "C45748"},
             {"label": "Vadnagar", "value": "C45749"},
             {"label": "Vadodara", "value": "C45750"},
             {"label": "Vaghodia", "value": "C45751"},
             {"label": "Vaghodia INA", "value": "C45752"},
             {"label": "Vallabh Vidyanagar", "value": "C45753"},
             {"label": "Vallabhipur", "value": "C45754"},
             {"label": "Valsad", "value": "C45755"},
             {"label": "Vanala", "value": "C45756"},
             {"label": "Vansda", "value": "C45757"},
             {"label": "Vanthli", "value": "C45758"},
             {"label": "Vapi", "value": "C45759"},
             {"label": "Vartej", "value": "C45760"},
             {"label": "Vasa", "value": "C45761"},
             {"label": "Vasavad", "value": "C45762"},
             {"label": "Vaso", "value": "C45763"},
             {"label": "Vataman", "value": "C45764"},
             {"label": "Vejalpur", "value": "C45765"},
             {"label": "Veraval", "value": "C45766"},
             {"label": "Vijapur", "value": "C45767"},
             {"label": "Vinchhiya", "value": "C45768"},
             {"label": "Vinchia", "value": "C45769"},
             {"label": "Viramgam", "value": "C45770"},
             {"label": "Virpur", "value": "C45771"},
             {"label": "Visavadar", "value": "C45772"},
             {"label": "Visnagar", "value": "C45773"},
             {"label": "Vyara", "value": "C45774"},
             {"label": "Wadhai", "value": "C45775"},
             {"label": "Wadhwan", "value": "C45776"},
             {"label": "Waghai", "value": "C45777"},
             {"label": "Wankaner", "value": "C45778"}],
 "Gulbene Municipality": [{"label": "Gulbene", "value": "C64869"}],
 "Gulf": [{"label": "Kerema", "value": "C80420"},
          {"label": "Kikori", "value": "C80421"}],
 "Gumushane": [{"label": "Evren", "value": "C120349"},
               {"label": "Gumushkhane", "value": "C120350"},
               {"label": "Kelkit", "value": "C120351"},
               {"label": "Kose", "value": "C120352"},
               {"label": "Kurtun", "value": "C120353"},
               {"label": "Merkez", "value": "C120354"},
               {"label": "Siran", "value": "C120355"},
               {"label": "Torul", "value": "C120356"},
               {"label": "Yaglidere", "value": "C120357"}],
 "Guna Yala": [{"label": "Achutupo", "value": "C80029"},
               {"label": "Ailigandi", "value": "C80030"},
               {"label": "Carti Sugdup", "value": "C80031"},
               {"label": "El Porvenir", "value": "C80032"},
               {"label": "Mulatupo", "value": "C80033"},
               {"label": "Nargana", "value": "C80034"},
               {"label": "Playon Chico", "value": "C80035"},
               {"label": "Puerto Obaldia", "value": "C80036"},
               {"label": "San Ignacio de Tupile", "value": "C80037"},
               {"label": "Tubuala", "value": "C80038"},
               {"label": "Ustupo", "value": "C80039"}],
 "Gunma Prefecture": [{"label": "Agatsuma-gun", "value": "C62867"},
                      {"label": "Annaka", "value": "C62868"},
                      {"label": "Annaka Shi", "value": "C62869"},
                      {"label": "Fujioka", "value": "C62870"},
                      {"label": "Fujioka Shi", "value": "C62871"},
                      {"label": "Isesaki", "value": "C62872"},
                      {"label": "Isesaki Shi", "value": "C62873"},
                      {"label": "Kanekomachi", "value": "C62874"},
                      {"label": "Kiryu", "value": "C62875"},
                      {"label": "Kiryu Shi", "value": "C62876"},
                      {"label": "Maebashi", "value": "C62877"},
                      {"label": "Maebashi Shi", "value": "C62878"},
                      {"label": "Midori", "value": "C62879"},
                      {"label": "Midori Shi", "value": "C62880"},
                      {"label": "Nakanojomachi", "value": "C62881"},
                      {"label": "Numata", "value": "C62882"},
                      {"label": "Numata Shi", "value": "C62883"},
                      {"label": "Omamacho-omama", "value": "C62884"},
                      {"label": "Ota", "value": "C62885"},
                      {"label": "Ota-shi", "value": "C62886"},
                      {"label": "Sakai-nakajima", "value": "C62887"},
                      {"label": "Shibukawa", "value": "C62888"},
                      {"label": "Shibukawa-shi", "value": "C62889"},
                      {"label": "Takasaki", "value": "C62890"},
                      {"label": "Takasaki Shi", "value": "C62891"},
                      {"label": "Tamamura", "value": "C62892"},
                      {"label": "Tatebayashi", "value": "C62893"},
                      {"label": "Tatebayashi-shi", "value": "C62894"},
                      {"label": "Tomioka", "value": "C62895"},
                      {"label": "Tomioka-shi", "value": "C62896"},
                      {"label": "Yoshii", "value": "C62897"}],
 "Guria": [{"label": "Lanchkhuti", "value": "C34276"},
           {"label": "Naruja", "value": "C34277"},
           {"label": "Ozurgeti", "value": "C34278"},
           {"label": "Ureki", "value": "C34279"}],
 "Gusinje Municipality": [{"label": "Gusinje", "value": "C75605"}],
 "Gwangju": [{"label": "Gwangju", "value": "C108298"},
             {"label": "Gwangsan-gu", "value": "C108299"},
             {"label": "Masan", "value": "C108300"}],
 "Gyeonggi Province": [{"label": "Ansan-si", "value": "C108301"},
                       {"label": "Anseong", "value": "C108302"},
                       {"label": "Anyang-si", "value": "C108303"},
                       {"label": "Bucheon-si", "value": "C108304"},
                       {"label": "Dongducheon", "value": "C108305"},
                       {"label": "Gapyeong", "value": "C108306"},
                       {"label": "Gapyeong-gun", "value": "C108307"},
                       {"label": "Gimpo-si", "value": "C108308"},
                       {"label": "Goyang-si", "value": "C108309"},
                       {"label": "Gunpo-si", "value": "C108310"},
                       {"label": "Guri-si", "value": "C108311"},
                       {"label": "Gwacheon-si", "value": "C108312"},
                       {"label": "Gwangju", "value": "C108313"},
                       {"label": "Gwangju-si", "value": "C108314"},
                       {"label": "Gwangmyeong-si", "value": "C108315"},
                       {"label": "Hanam", "value": "C108316"},
                       {"label": "Hanam-si", "value": "C108317"},
                       {"label": "Hwado", "value": "C108318"},
                       {"label": "Hwaseong-si", "value": "C108319"},
                       {"label": "Icheon-si", "value": "C108320"},
                       {"label": "Kwangmyong", "value": "C108321"},
                       {"label": "Munsan", "value": "C108322"},
                       {"label": "Namyangju", "value": "C108323"},
                       {"label": "Namyangju-si", "value": "C108324"},
                       {"label": "Osan", "value": "C108325"},
                       {"label": "Osan-si", "value": "C108326"},
                       {"label": "Paju-si", "value": "C108327"},
                       {"label": "Pocheon-si", "value": "C108328"},
                       {"label": "Pubal", "value": "C108329"},
                       {"label": "Pyeongtaek", "value": "C108330"},
                       {"label": "Seongnam-si", "value": "C108331"},
                       {"label": "Siheung", "value": "C108332"},
                       {"label": "Suwon", "value": "C108333"},
                       {"label": "Uijeongbu-si", "value": "C108334"},
                       {"label": "Uiwang-si", "value": "C108335"},
                       {"label": "Wabu", "value": "C108336"},
                       {"label": "Yangju", "value": "C108337"},
                       {"label": "Yangju-si", "value": "C108338"},
                       {"label": "Yangpyong", "value": "C108339"},
                       {"label": "Yangpyeong-gun", "value": "C108340"},
                       {"label": "Yeoju-si", "value": "C108341"},
                       {"label": "Yeoncheon-gun", "value": "C108342"},
                       {"label": "Yongin-si", "value": "C108343"}],
 "Gyor-Moson-Sopron County": [{"label": "Abda", "value": "C44118"},
                              {"label": "agfalva", "value": "C44119"},
                              {"label": "asvanyraro", "value": "C44120"},
                              {"label": "Bakonyszentlaszlo", "value": "C44121"},
                              {"label": "Beled", "value": "C44122"},
                              {"label": "Bony", "value": "C44123"},
                              {"label": "Bosarkany", "value": "C44124"},
                              {"label": "Csorna", "value": "C44125"},
                              {"label": "Csornai Jaras", "value": "C44126"},
                              {"label": "Farad", "value": "C44127"},
                              {"label": "Fertod", "value": "C44128"},
                              {"label": "Fertorakos", "value": "C44129"},
                              {"label": "Fertoszentmiklos", "value": "C44130"},
                              {"label": "Gyor", "value": "C44131"},
                              {"label": "Gyori Jaras", "value": "C44132"},
                              {"label": "Gyorszemere", "value": "C44133"},
                              {"label": "Gyorujbarat", "value": "C44134"},
                              {"label": "Halaszi", "value": "C44135"},
                              {"label": "Janossomorja", "value": "C44136"},
                              {"label": "Kapuvar", "value": "C44137"},
                              {"label": "Kapuvari Jaras", "value": "C44138"},
                              {"label": "Kimle", "value": "C44139"},
                              {"label": "Kony", "value": "C44140"},
                              {"label": "Lebeny", "value": "C44141"},
                              {"label": "Mihalyi", "value": "C44142"},
                              {"label": "Mosonmagyarovar", "value": "C44143"},
                              {"label": "Mosonmagyarovari Jaras",
                               "value": "C44144"},
                              {"label": "Mosonszentmiklos", "value": "C44145"},
                              {"label": "Nagycenk", "value": "C44146"},
                              {"label": "Nyul", "value": "C44147"},
                              {"label": "Otteveny", "value": "C44148"},
                              {"label": "Pannonhalma", "value": "C44149"},
                              {"label": "Pannonhalmi Jaras", "value": "C44150"},
                              {"label": "Per", "value": "C44151"},
                              {"label": "Rabapatona", "value": "C44152"},
                              {"label": "Rajka", "value": "C44153"},
                              {"label": "Sopron", "value": "C44154"},
                              {"label": "Soproni Jaras", "value": "C44155"},
                              {"label": "Szany", "value": "C44156"},
                              {"label": "Tet", "value": "C44157"},
                              {"label": "Teti Jaras", "value": "C44158"},
                              {"label": "Toltestava", "value": "C44159"}],
 "Gzira": [{"label": "Gharb", "value": "C65884"}],
 "Ha Giang": [{"label": "Ha Giang", "value": "C147389"},
              {"label": "Huyen Bac Me", "value": "C147390"},
              {"label": "Huyen Bac Quang", "value": "C147391"},
              {"label": "Huyen Dong Van", "value": "C147392"},
              {"label": "Huyen Quang Binh", "value": "C147393"},
              {"label": "Huyen Vi Xuyen", "value": "C147394"},
              {"label": "Huyen Xin Man", "value": "C147395"},
              {"label": "Huyen Yen Minh", "value": "C147396"}],
 "Ha Nam": [{"label": "Huyen Binh Luc", "value": "C147397"},
            {"label": "Huyen Duy Tien", "value": "C147398"},
            {"label": "Huyen Kim Bang", "value": "C147399"},
            {"label": "Huyen Ly Nhan", "value": "C147400"},
            {"label": "Huyen Thanh Liem", "value": "C147401"},
            {"label": "Phu Ly", "value": "C147402"}],
 "Ha Noi": [{"label": "Ha Dong", "value": "C147403"},
            {"label": "Hanoi", "value": "C147404"},
            {"label": "Huyen Quoc Oai", "value": "C147405"},
            {"label": "Quan Ba Dinh", "value": "C147406"},
            {"label": "Quan Ha Dong", "value": "C147407"},
            {"label": "Son Tay", "value": "C147408"}],
 "Ha Tinh": [{"label": "Ha Tinh", "value": "C147409"},
             {"label": "Huyen Cam Xuyen", "value": "C147410"},
             {"label": "Huyen Can Loc", "value": "C147411"},
             {"label": "Huyen Duc Tho", "value": "C147412"},
             {"label": "Huyen Ky Anh", "value": "C147413"},
             {"label": "Huyen Nghi Xuan", "value": "C147414"},
             {"label": "Huyen Thach Ha", "value": "C147415"}],
 "Ha`apai": [{"label": "Pangai", "value": "C119647"}],
 "Haa Alif Atoll": [{"label": "Dhidhdhoo", "value": "C65807"}],
 "Haa Dhaalu Atoll": [{"label": "Kulhudhuffushi", "value": "C65808"}],
 "Haa District": [{"label": "Ha", "value": "C9637"}],
 "Hadhramaut Governorate": [{"label": "Ad Dis", "value": "C147878"},
                            {"label": "Ad Dis ash Sharqiyah",
                             "value": "C147879"},
                            {"label": "Adh Dhliaah", "value": "C147880"},
                            {"label": "Al Abr", "value": "C147881"},
                            {"label": "Al Hamdi", "value": "C147882"},
                            {"label": "Al Mukalla", "value": "C147883"},
                            {"label": "Al Mukalla City", "value": "C147884"},
                            {"label": "Al Qaf", "value": "C147885"},
                            {"label": "Al Qatn", "value": "C147886"},
                            {"label": "Amd", "value": "C147887"},
                            {"label": "Ar Raydah Wa Qusayar",
                             "value": "C147888"},
                            {"label": "As Sawm", "value": "C147889"},
                            {"label": "Ash Shihr", "value": "C147890"},
                            {"label": "At Tahaluf", "value": "C147891"},
                            {"label": "Brom Mayfa", "value": "C147892"},
                            {"label": "Dawan", "value": "C147893"},
                            {"label": "Ghayl Ba Wazir", "value": "C147894"},
                            {"label": "Ghayl Bin Yamin", "value": "C147895"},
                            {"label": "Hagr As Saiar", "value": "C147896"},
                            {"label": "Hajr", "value": "C147897"},
                            {"label": "Huraidhah", "value": "C147898"},
                            {"label": "Kilmia", "value": "C147899"},
                            {"label": "Mukalla", "value": "C147900"},
                            {"label": "Rakhyah", "value": "C147901"},
                            {"label": "Rumah", "value": "C147902"},
                            {"label": "Sah", "value": "C147903"},
                            {"label": "Sayun", "value": "C147904"},
                            {"label": "Shibam", "value": "C147905"},
                            {"label": "Suhayl Shibam", "value": "C147906"},
                            {"label": "Tarim", "value": "C147907"},
                            {"label": "Thamud", "value": "C147908"},
                            {"label": "Wadi Al Ayn", "value": "C147909"},
                            {"label": "Yabuth", "value": "C147910"},
                            {"label": "Zamakh wa Manwakh", "value": "C147911"}],
 "Hadjer-Lamis": [{"label": "Bokoro", "value": "C17859"},
                  {"label": "Massaguet", "value": "C17860"},
                  {"label": "Massakory", "value": "C17861"}],
 "Hai Duong": [{"label": "Hai Duong", "value": "C147416"},
               {"label": "Huyen Binh Giang", "value": "C147417"},
               {"label": "Huyen Cam Giang", "value": "C147418"},
               {"label": "Huyen Gia Loc", "value": "C147419"},
               {"label": "Huyen Kinh Mon", "value": "C147420"},
               {"label": "Huyen Nam Sach", "value": "C147421"},
               {"label": "Huyen Thanh Mien", "value": "C147422"},
               {"label": "Thi Xa Chi Linh", "value": "C147423"}],
 "Hai Phong": [{"label": "Cat Ba", "value": "C147424"},
               {"label": "Haiphong", "value": "C147425"}],
 "Haifa District": [{"label": "Atlit", "value": "C51626"},
                    {"label": "Caesarea", "value": "C51627"},
                    {"label": "Daliyat al Karmel", "value": "C51628"},
                    {"label": "El Fureidis", "value": "C51629"},
                    {"label": "Hadera", "value": "C51630"},
                    {"label": "Haifa", "value": "C51631"},
                    {"label": "Ibtin", "value": "C51632"},
                    {"label": "Nesher", "value": "C51633"},
                    {"label": "Qiryat Ata", "value": "C51634"},
                    {"label": "Qiryat Bialik", "value": "C51635"},
                    {"label": "Qiryat Mozqin", "value": "C51636"},
                    {"label": "Qiryat Yam", "value": "C51637"},
                    {"label": "Rekhasim", "value": "C51638"},
                    {"label": "Tirat Karmel", "value": "C51639"},
                    {"label": "Umm el Fahm", "value": "C51640"}],
 "Hail": [{"label": "Hail", "value": "C106559"},
          {"label": "Jubbah", "value": "C106560"},
          {"label": "Mawqaq", "value": "C106561"},
          {"label": "Qufar", "value": "C106562"},
          {"label": "Simira", "value": "C106563"}],
 "Hainan": [{"label": "Basuo", "value": "C18586"},
            {"label": "Chongshan", "value": "C18587"},
            {"label": "Dadonghai", "value": "C18588"},
            {"label": "Haikou", "value": "C18589"},
            {"label": "Jinjiang", "value": "C18590"},
            {"label": "Lincheng", "value": "C18591"},
            {"label": "Nada", "value": "C18592"},
            {"label": "Qionghai", "value": "C18593"},
            {"label": "Qiongshan", "value": "C18594"},
            {"label": "Sansha", "value": "C18595"},
            {"label": "Sanya", "value": "C18596"},
            {"label": "Wanning", "value": "C18597"},
            {"label": "Wenchang", "value": "C18598"},
            {"label": "Xiuying", "value": "C18599"}],
 "Hajdina Municipality": [{"label": "Spodnja Hajdina", "value": "C107631"}],
 "Hajdu-Bihar County": [{"label": "Bagamer", "value": "C44160"},
                        {"label": "Balmazujvaros", "value": "C44161"},
                        {"label": "Balmazujvarosi Jaras", "value": "C44162"},
                        {"label": "Barand", "value": "C44163"},
                        {"label": "Berettyoujfalu", "value": "C44164"},
                        {"label": "Berettyoujfalui Jaras", "value": "C44165"},
                        {"label": "Biharkeresztes", "value": "C44166"},
                        {"label": "Biharnagybajom", "value": "C44167"},
                        {"label": "Csokmo", "value": "C44168"},
                        {"label": "Debrecen", "value": "C44169"},
                        {"label": "Debreceni Jaras", "value": "C44170"},
                        {"label": "Derecske", "value": "C44171"},
                        {"label": "Derecskei Jaras", "value": "C44172"},
                        {"label": "Ebes", "value": "C44173"},
                        {"label": "Egyek", "value": "C44174"},
                        {"label": "Foldes", "value": "C44175"},
                        {"label": "Gorbehaza", "value": "C44176"},
                        {"label": "Hadjuszoboszloi Jaras", "value": "C44177"},
                        {"label": "Hajdubagos", "value": "C44178"},
                        {"label": "Hajduboszormeny", "value": "C44179"},
                        {"label": "Hajduboszormenyi Jaras", "value": "C44180"},
                        {"label": "Hajdudorog", "value": "C44181"},
                        {"label": "Hajduhadhaz", "value": "C44182"},
                        {"label": "Hajduhadhazi Jaras", "value": "C44183"},
                        {"label": "Hajdunanas", "value": "C44184"},
                        {"label": "Hajdunanasi Jaras", "value": "C44185"},
                        {"label": "Hajdusamson", "value": "C44186"},
                        {"label": "Hajduszoboszlo", "value": "C44187"},
                        {"label": "Hajduszovat", "value": "C44188"},
                        {"label": "Hortobagy", "value": "C44189"},
                        {"label": "Hosszupalyi", "value": "C44190"},
                        {"label": "Kaba", "value": "C44191"},
                        {"label": "Komadi", "value": "C44192"},
                        {"label": "Konyar", "value": "C44193"},
                        {"label": "Letavertes", "value": "C44194"},
                        {"label": "Mikepercs", "value": "C44195"},
                        {"label": "Monostorpalyi", "value": "C44196"},
                        {"label": "Nadudvar", "value": "C44197"},
                        {"label": "Nagyrabe", "value": "C44198"},
                        {"label": "Nyirabrany", "value": "C44199"},
                        {"label": "Nyiracsad", "value": "C44200"},
                        {"label": "Nyiradony", "value": "C44201"},
                        {"label": "Nyiradonyi Jaras", "value": "C44202"},
                        {"label": "Nyirmartonfalva", "value": "C44203"},
                        {"label": "Pocsaj", "value": "C44204"},
                        {"label": "Polgar", "value": "C44205"},
                        {"label": "Puspokladany", "value": "C44206"},
                        {"label": "Puspokladanyi Jaras", "value": "C44207"},
                        {"label": "Sarand", "value": "C44208"},
                        {"label": "Sarretudvari", "value": "C44209"},
                        {"label": "Teglas", "value": "C44210"},
                        {"label": "Tiszacsege", "value": "C44211"},
                        {"label": "Vamospercs", "value": "C44212"}],
 "Hajigabul District": [{"label": "Haciqabul", "value": "C8438"},
                        {"label": "Mughan", "value": "C8439"}],
 "Hajjah Governorate": [{"label": "Abs", "value": "C147912"},
                        {"label": "Aflah Al Yaman", "value": "C147913"},
                        {"label": "Aflah Ash Shawm", "value": "C147914"},
                        {"label": "Al Jamimah", "value": "C147915"},
                        {"label": "Al Maghrabah", "value": "C147916"},
                        {"label": "Al Mahabishah", "value": "C147917"},
                        {"label": "Al Miftah", "value": "C147918"},
                        {"label": "Ash Shaghadirah", "value": "C147919"},
                        {"label": "Ash Shahil", "value": "C147920"},
                        {"label": "Aslem", "value": "C147921"},
                        {"label": "Bakil Al Mir", "value": "C147922"},
                        {"label": "Bani al Awwam", "value": "C147923"},
                        {"label": "Bani Al Awam", "value": "C147924"},
                        {"label": "Bani Qais", "value": "C147925"},
                        {"label": "Hajjah", "value": "C147926"},
                        {"label": "Harad District", "value": "C147927"},
                        {"label": "Hayran", "value": "C147928"},
                        {"label": "Khayran Al Muharraq", "value": "C147929"},
                        {"label": "Kuaydinah", "value": "C147930"},
                        {"label": "Kuhlan Affar", "value": "C147931"},
                        {"label": "Kuhlan Ash Sharaf", "value": "C147932"},
                        {"label": "Kushar", "value": "C147933"},
                        {"label": "Mabyan", "value": "C147934"},
                        {"label": "Midi", "value": "C147935"},
                        {"label": "Mustaba", "value": "C147936"},
                        {"label": "Najrah", "value": "C147937"},
                        {"label": "Qafl Shamer", "value": "C147938"},
                        {"label": "Qarah", "value": "C147939"},
                        {"label": "Sharas", "value": "C147940"},
                        {"label": "Wadhrah", "value": "C147941"},
                        {"label": "Washhah", "value": "C147942"}],
 "Hakkari": [{"label": "Cukurca", "value": "C120358"},
             {"label": "Hakkari", "value": "C120359"},
             {"label": "Karsani", "value": "C120360"},
             {"label": "Semdinli", "value": "C120361"},
             {"label": "Semdinni Ilcesi", "value": "C120362"},
             {"label": "Yuksekova", "value": "C120363"}],
 "Halland County": [{"label": "Aled", "value": "C115592"},
                    {"label": "Asa", "value": "C115593"},
                    {"label": "Bua", "value": "C115594"},
                    {"label": "Falkenberg", "value": "C115595"},
                    {"label": "Fjaras kyrkby", "value": "C115596"},
                    {"label": "Frillesas", "value": "C115597"},
                    {"label": "Frosakull", "value": "C115598"},
                    {"label": "Fyllinge", "value": "C115599"},
                    {"label": "Getinge", "value": "C115600"},
                    {"label": "Glommen", "value": "C115601"},
                    {"label": "Gullbrandstorp", "value": "C115602"},
                    {"label": "Halmstad", "value": "C115603"},
                    {"label": "Harplinge", "value": "C115604"},
                    {"label": "Haverdal", "value": "C115605"},
                    {"label": "Hyltebruk", "value": "C115606"},
                    {"label": "Knared", "value": "C115607"},
                    {"label": "Kungsbacka", "value": "C115608"},
                    {"label": "Laholm", "value": "C115609"},
                    {"label": "Mellbystrand", "value": "C115610"},
                    {"label": "Mjallby", "value": "C115611"},
                    {"label": "Onsala", "value": "C115612"},
                    {"label": "Oskarstrom", "value": "C115613"},
                    {"label": "Saro", "value": "C115614"},
                    {"label": "Skrea", "value": "C115615"},
                    {"label": "Steninge", "value": "C115616"},
                    {"label": "Torup", "value": "C115617"},
                    {"label": "Traslovslage", "value": "C115618"},
                    {"label": "Tronninge", "value": "C115619"},
                    {"label": "Tvaaker", "value": "C115620"},
                    {"label": "Vallda", "value": "C115621"},
                    {"label": "Varberg", "value": "C115622"},
                    {"label": "Vastra Hagen", "value": "C115623"},
                    {"label": "Veddige", "value": "C115624"},
                    {"label": "Veinge", "value": "C115625"}],
 "Hama Governorate": [{"label": "Al-Salamiyah District", "value": "C117874"},
                      {"label": "As Salamiyah", "value": "C117875"},
                      {"label": "As Suqaylibiyah", "value": "C117876"},
                      {"label": "Halfaya", "value": "C117877"},
                      {"label": "Hama District", "value": "C117878"},
                      {"label": "Hamah", "value": "C117879"},
                      {"label": "Kafr Zayta", "value": "C117880"},
                      {"label": "Masyaf", "value": "C117881"},
                      {"label": "Masyaf District", "value": "C117882"},
                      {"label": "Murak", "value": "C117883"},
                      {"label": "Souran", "value": "C117884"},
                      {"label": "Tall Salhab", "value": "C117885"},
                      {"label": "Tayyibat al Imam", "value": "C117886"},
                      {"label": "Tremseh", "value": "C117887"}],
 "Hamadan": [{"label": "Ab Meshkin", "value": "C50196"},
             {"label": "Abarlaq-e Sofla", "value": "C50197"},
             {"label": "Abdalan", "value": "C50198"},
             {"label": "Abdol Rahim", "value": "C50199"},
             {"label": "Abrumand", "value": "C50200"},
             {"label": "Abshineh", "value": "C50201"},
             {"label": "Abu ol Fathabad", "value": "C50202"},
             {"label": "Aghcheh Qayah", "value": "C50203"},
             {"label": "Ahmadabad-e Tappeh", "value": "C50204"},
             {"label": "Ahu Tappeh", "value": "C50205"},
             {"label": "Ajin", "value": "C50206"},
             {"label": "Ajorlu", "value": "C50207"},
             {"label": "Akanlu", "value": "C50208"},
             {"label": "Akleh", "value": "C50209"},
             {"label": "Alan-e Sofla", "value": "C50210"},
             {"label": "Alanjeh", "value": "C50211"},
             {"label": "Alfavut", "value": "C50212"},
             {"label": "Aliabad-e Posht Shahr", "value": "C50213"},
             {"label": "Amirabad-e Kord", "value": "C50214"},
             {"label": "Amzajerd", "value": "C50215"},
             {"label": "Angoshteh", "value": "C50216"},
             {"label": "Anjir Baghi", "value": "C50217"},
             {"label": "Ansar ol Emam", "value": "C50218"},
             {"label": "Anuch", "value": "C50219"},
             {"label": "Aq Bolagh-e Aqdaq", "value": "C50220"},
             {"label": "Aq Bolagh-e Latgah", "value": "C50221"},
             {"label": "Aq Bolagh-e Morshed", "value": "C50222"},
             {"label": "Aq Chay", "value": "C50223"},
             {"label": "Aqa Bolaghi", "value": "C50224"},
             {"label": "Aqa Jan Bolaghi", "value": "C50225"},
             {"label": "Aqaj", "value": "C50226"},
             {"label": "Aqcheh Kharabeh", "value": "C50227"},
             {"label": "Aqchelu", "value": "C50228"},
             {"label": "Arges-e Sofla", "value": "C50229"},
             {"label": "Arikan", "value": "C50230"},
             {"label": "Arpa Darreh", "value": "C50231"},
             {"label": "Arteh Bolagh", "value": "C50232"},
             {"label": "Artiman", "value": "C50233"},
             {"label": "Arzan Fud", "value": "C50234"},
             {"label": "Arzuvaj", "value": "C50235"},
             {"label": "Asadabad", "value": "C50236"},
             {"label": "Ashmizan", "value": "C50237"},
             {"label": "Avarzaman", "value": "C50238"},
             {"label": "Azandarian", "value": "C50239"},
             {"label": "Aznahri", "value": "C50240"},
             {"label": "Baba Khanjar", "value": "C50241"},
             {"label": "Baba Pir", "value": "C50242"},
             {"label": "Baba Qasem", "value": "C50243"},
             {"label": "Babolqani", "value": "C50244"},
             {"label": "Bad Khvoreh", "value": "C50245"},
             {"label": "Bahar", "value": "C50246"},
             {"label": "Band Aman", "value": "C50247"},
             {"label": "Banesareh", "value": "C50248"},
             {"label": "Baraband", "value": "C50249"},
             {"label": "Bargecheh", "value": "C50250"},
             {"label": "Barreh Farakh", "value": "C50251"},
             {"label": "Barzul", "value": "C50252"},
             {"label": "Bashqurtaran", "value": "C50253"},
             {"label": "Bazeran", "value": "C50254"},
             {"label": "Behkandan", "value": "C50255"},
             {"label": "Belartu", "value": "C50256"},
             {"label": "Beshik Tappeh", "value": "C50257"},
             {"label": "Biaj", "value": "C50258"},
             {"label": "Biatan-e Sofla", "value": "C50259"},
             {"label": "Bid Korpeh-ye Sofla", "value": "C50260"},
             {"label": "Bid Korpeh-ye Vosta", "value": "C50261"},
             {"label": "Bifanaj", "value": "C50262"},
             {"label": "Bighash", "value": "C50263"},
             {"label": "Bish Aghaj", "value": "C50264"},
             {"label": "Bitervan", "value": "C50265"},
             {"label": "Borjaki", "value": "C50266"},
             {"label": "Boyukabad", "value": "C50267"},
             {"label": "Bozchalu", "value": "C50268"},
             {"label": "Bozineh Jerd", "value": "C50269"},
             {"label": "Bujin", "value": "C50270"},
             {"label": "Bustandar", "value": "C50271"},
             {"label": "Buyaqchi", "value": "C50272"},
             {"label": "Chahar Bolagh", "value": "C50273"},
             {"label": "Chal Boqa", "value": "C50274"},
             {"label": "Chaleh Kand", "value": "C50275"},
             {"label": "Charoq", "value": "C50276"},
             {"label": "Chasht Khvoreh", "value": "C50277"},
             {"label": "Chenari", "value": "C50278"},
             {"label": "Cheshmeh Malek", "value": "C50279"},
             {"label": "Cheshmeh Pahn-e Nanaj", "value": "C50280"},
             {"label": "Cheshmeh Qandab", "value": "C50281"},
             {"label": "Cheshmeh Qareh", "value": "C50282"},
             {"label": "Cheshmeh Qassaban", "value": "C50283"},
             {"label": "Cheshmeh Valad", "value": "C50284"},
             {"label": "Cheshmeh Zowraq", "value": "C50285"},
             {"label": "Choqa Sarahi", "value": "C50286"},
             {"label": "Chowtash", "value": "C50287"},
             {"label": "Chulak Qapanuri", "value": "C50288"},
             {"label": "Dahnejerd", "value": "C50289"},
             {"label": "Dali Chu", "value": "C50290"},
             {"label": "Damaq", "value": "C50291"},
             {"label": "Daqdaqabad", "value": "C50292"},
             {"label": "Dar Qeshlaq", "value": "C50293"},
             {"label": "Darani-ye Sofla", "value": "C50294"},
             {"label": "Darcham", "value": "C50295"},
             {"label": "Darreh Chenar", "value": "C50296"},
             {"label": "Darreh Mianeh-ye Sofla", "value": "C50297"},
             {"label": "Darreh Mirza", "value": "C50298"},
             {"label": "Darreh-ye Ebrahim", "value": "C50299"},
             {"label": "Darsibeh", "value": "C50300"},
             {"label": "Davijan", "value": "C50301"},
             {"label": "Deh Chaneh", "value": "C50302"},
             {"label": "Deh Dalian", "value": "C50303"},
             {"label": "Deh Ful", "value": "C50304"},
             {"label": "Deh Mianeh", "value": "C50305"},
             {"label": "Deh Nush", "value": "C50306"},
             {"label": "Deh Piaz", "value": "C50307"},
             {"label": "Deh-e Shaker", "value": "C50308"},
             {"label": "Dehnow-e Avarzaman", "value": "C50309"},
             {"label": "Dingeleh Kahriz", "value": "C50310"},
             {"label": "Do Rudan", "value": "C50311"},
             {"label": "Doraneh", "value": "C50312"},
             {"label": "Dorudgaran", "value": "C50313"},
             {"label": "Dow Baraleh", "value": "C50314"},
             {"label": "Dowlatabad-e Hajjilu", "value": "C50315"},
             {"label": "Dowlujerdin-e Bala", "value": "C50316"},
             {"label": "Durijan", "value": "C50317"},
             {"label": "Durnian", "value": "C50318"},
             {"label": "Emamzadeh Khatun", "value": "C50319"},
             {"label": "Emamzadeh Pir Nahan", "value": "C50320"},
             {"label": "Eskanan", "value": "C50321"},
             {"label": "Fahrumand", "value": "C50322"},
             {"label": "Famast", "value": "C50323"},
             {"label": "Famenin", "value": "C50324"},
             {"label": "Farasfaj", "value": "C50325"},
             {"label": "Farsban", "value": "C50326"},
             {"label": "Farvaz", "value": "C50327"},
             {"label": "Faryazan", "value": "C50328"},
             {"label": "Fasijan", "value": "C50329"},
             {"label": "Fiazaman", "value": "C50330"},
             {"label": "Firuzabad-e Sofla", "value": "C50331"},
             {"label": "Firuzan", "value": "C50332"},
             {"label": "Gamasa", "value": "C50333"},
             {"label": "Gamasb", "value": "C50334"},
             {"label": "Ganj Tappeh", "value": "C50335"},
             {"label": "Ganjab", "value": "C50336"},
             {"label": "Gashani", "value": "C50337"},
             {"label": "Gav Zaban", "value": "C50338"},
             {"label": "Gavkaran", "value": "C50339"},
             {"label": "Ghazyatan", "value": "C50340"},
             {"label": "Gholam Veys", "value": "C50341"},
             {"label": "Givaki", "value": "C50342"},
             {"label": "Giyan", "value": "C50343"},
             {"label": "Gol Darreh-ye Anuch", "value": "C50344"},
             {"label": "Gol Heydar", "value": "C50345"},
             {"label": "Golparabad", "value": "C50346"},
             {"label": "Golshir", "value": "C50347"},
             {"label": "Golushjerd", "value": "C50348"},
             {"label": "Gomush Bolagh", "value": "C50349"},
             {"label": "Gonbad Chay", "value": "C50350"},
             {"label": "Gonbad-e Kabud", "value": "C50351"},
             {"label": "Gostar", "value": "C50352"},
             {"label": "Gozal Abdal", "value": "C50353"},
             {"label": "Gug Qash", "value": "C50354"},
             {"label": "Gujak", "value": "C50355"},
             {"label": "Gulvand", "value": "C50356"},
             {"label": "Gunestan", "value": "C50357"},
             {"label": "Gur Gaz", "value": "C50358"},
             {"label": "Gurjiu", "value": "C50359"},
             {"label": "Gushalan", "value": "C50360"},
             {"label": "Gusheh-ye Kasavand", "value": "C50361"},
             {"label": "Hajji Kheder", "value": "C50362"},
             {"label": "Hajji Maqsud", "value": "C50363"},
             {"label": "Hajji Tu", "value": "C50364"},
             {"label": "Hamadan", "value": "C50365"},
             {"label": "Hamilabad", "value": "C50366"},
             {"label": "Hamzehlu-ye Sofla", "value": "C50367"},
             {"label": "Haramabad", "value": "C50368"},
             {"label": "Hasan Kusej", "value": "C50369"},
             {"label": "Hasan Teymur", "value": "C50370"},
             {"label": "Hasanabad-e Emam", "value": "C50371"},
             {"label": "Hasanabad-e Qush Bolagh", "value": "C50372"},
             {"label": "Hasanabad-e Sheverin", "value": "C50373"},
             {"label": "Heriraz", "value": "C50374"},
             {"label": "Hesar-e Qarah Baghi", "value": "C50375"},
             {"label": "Hesar-e Qujeh Baghi", "value": "C50376"},
             {"label": "Heydareh-ye Dar-e Emam", "value": "C50377"},
             {"label": "Heydareh-ye Posht-e Shahr", "value": "C50378"},
             {"label": "Hizaj", "value": "C50379"},
             {"label": "Holvar-e Sofla", "value": "C50380"},
             {"label": "Hom-e Khosrow", "value": "C50381"},
             {"label": "Horhoreh", "value": "C50382"},
             {"label": "Hoseynabad-e Bahar", "value": "C50383"},
             {"label": "Hoseynabad-e Nazem", "value": "C50384"},
             {"label": "Hoseynabad-e Shamlu", "value": "C50385"},
             {"label": "Hudaraj", "value": "C50386"},
             {"label": "Iraneh", "value": "C50387"},
             {"label": "Isti Bolagh", "value": "C50388"},
             {"label": "Jamishlu", "value": "C50389"},
             {"label": "Jaria", "value": "C50390"},
             {"label": "Javar Sajin", "value": "C50391"},
             {"label": "Jerbanlu", "value": "C50392"},
             {"label": "Jighi", "value": "C50393"},
             {"label": "Jijan Kuh", "value": "C50394"},
             {"label": "Jijian Rud", "value": "C50395"},
             {"label": "Jin Taraqayah", "value": "C50396"},
             {"label": "Juraqan", "value": "C50397"},
             {"label": "Kaboodrahang", "value": "C50398"},
             {"label": "Kahard", "value": "C50399"},
             {"label": "Kahkadan", "value": "C50400"},
             {"label": "Kahriz-e Baba Hoseyn", "value": "C50401"},
             {"label": "Kahriz-e Boqazi", "value": "C50402"},
             {"label": "Kahriz-e Hoseynabad-e Nazem", "value": "C50403"},
             {"label": "Kahriz-e Jamal", "value": "C50404"},
             {"label": "Kahriz-e Salim", "value": "C50405"},
             {"label": "Kal Kabud", "value": "C50406"},
             {"label": "Kalb Hesari", "value": "C50407"},
             {"label": "Kalilabad", "value": "C50408"},
             {"label": "Kamak-e Sofla", "value": "C50409"},
             {"label": "Kamar Boneh", "value": "C50410"},
             {"label": "Kamazan", "value": "C50411"},
             {"label": "Kand Halan", "value": "C50412"},
             {"label": "Kand Tappeh", "value": "C50413"},
             {"label": "Kand-e Bolaghi", "value": "C50414"},
             {"label": "Kangavar-e Kohneh", "value": "C50415"},
             {"label": "Karafs", "value": "C50416"},
             {"label": "Kark-e Sofla", "value": "C50417"},
             {"label": "Kartilabad", "value": "C50418"},
             {"label": "Kasavand", "value": "C50419"},
             {"label": "Kaslan Qayah", "value": "C50420"},
             {"label": "Kavanaj", "value": "C50421"},
             {"label": "Kesb", "value": "C50422"},
             {"label": "Keykaleh", "value": "C50423"},
             {"label": "Keyni Sayeh", "value": "C50424"},
             {"label": "Khabar Arkhi", "value": "C50425"},
             {"label": "Khaku", "value": "C50426"},
             {"label": "Khaleq Verdi", "value": "C50427"},
             {"label": "Khalil Kord", "value": "C50428"},
             {"label": "Kheradmand", "value": "C50429"},
             {"label": "Kheyr Qoli", "value": "C50430"},
             {"label": "Khomeygan", "value": "C50431"},
             {"label": "Khvajeh Hesar", "value": "C50432"},
             {"label": "Khvajeh Hoseyni", "value": "C50433"},
             {"label": "Khvajeh Kandi", "value": "C50434"},
             {"label": "Khvoshab-e Sofla", "value": "C50435"},
             {"label": "Kitu", "value": "C50436"},
             {"label": "Kohnush", "value": "C50437"},
             {"label": "Kolanjan", "value": "C50438"},
             {"label": "Konjineh", "value": "C50439"},
             {"label": "Kord Bolagh", "value": "C50440"},
             {"label": "Korzan", "value": "C50441"},
             {"label": "Kowzareh", "value": "C50442"},
             {"label": "Kuhani", "value": "C50443"},
             {"label": "Kur Kahriz", "value": "C50444"},
             {"label": "Kusaj Khalil", "value": "C50445"},
             {"label": "Kutah Darreh", "value": "C50446"},
             {"label": "Laleh Dan", "value": "C50447"},
             {"label": "Lalejin", "value": "C50448"},
             {"label": "Lamiyan", "value": "C50449"},
             {"label": "Latgah", "value": "C50450"},
             {"label": "Lavashan", "value": "C50451"},
             {"label": "Leyli Yadegar", "value": "C50452"},
             {"label": "Luluhar", "value": "C50453"},
             {"label": "Mahanabad", "value": "C50454"},
             {"label": "Mahbar", "value": "C50455"},
             {"label": "Mahdaviyeh", "value": "C50456"},
             {"label": "Mahnian", "value": "C50457"},
             {"label": "Malayer", "value": "C50458"},
             {"label": "Malek-e Ashtar", "value": "C50459"},
             {"label": "Malusan", "value": "C50460"},
             {"label": "Mangavi", "value": "C50461"},
             {"label": "Marvil", "value": "C50462"},
             {"label": "Maryanaj", "value": "C50463"},
             {"label": "Masjedin", "value": "C50464"},
             {"label": "Menjan", "value": "C50465"},
             {"label": "Mesinak", "value": "C50466"},
             {"label": "Meyvaleh", "value": "C50467"},
             {"label": "Mianzulan", "value": "C50468"},
             {"label": "Mihamleh-ye Sofla", "value": "C50469"},
             {"label": "Milab", "value": "C50470"},
             {"label": "Mirza Hesari", "value": "C50471"},
             {"label": "Mishen", "value": "C50472"},
             {"label": "Mobarakin", "value": "C50473"},
             {"label": "Mohajeran", "value": "C50474"},
             {"label": "Mohammadabad-e Chulak", "value": "C50475"},
             {"label": "Mohammadiyeh-e Sofla", "value": "C50476"},
             {"label": "Mohara", "value": "C50477"},
             {"label": "Mokarrabi", "value": "C50478"},
             {"label": "Molham Darreh", "value": "C50479"},
             {"label": "Molla Bodagh", "value": "C50480"},
             {"label": "Monavvar Tappeh", "value": "C50481"},
             {"label": "Morghabad", "value": "C50482"},
             {"label": "Mowdaran", "value": "C50483"},
             {"label": "Musa Bolaghi", "value": "C50484"},
             {"label": "Nahandar", "value": "C50485"},
             {"label": "Nahavand", "value": "C50486"},
             {"label": "Najafiyeh", "value": "C50487"},
             {"label": "Nakilabad", "value": "C50488"},
             {"label": "Nakin", "value": "C50489"},
             {"label": "Namileh", "value": "C50490"},
             {"label": "Nanaj", "value": "C50491"},
             {"label": "Nazul", "value": "C50492"},
             {"label": "Negar Khatun", "value": "C50493"},
             {"label": "Negarabad", "value": "C50494"},
             {"label": "Nehenjeh", "value": "C50495"},
             {"label": "Nesar", "value": "C50496"},
             {"label": "Neshar", "value": "C50497"},
             {"label": "Niyanj", "value": "C50498"},
             {"label": "Nosratabad-e Behraz", "value": "C50499"},
             {"label": "Nosratabad-e Laklak", "value": "C50500"},
             {"label": "Nurabad-e Simineh", "value": "C50501"},
             {"label": "Ojaq", "value": "C50502"},
             {"label": "Ommatlar", "value": "C50503"},
             {"label": "Oshtoran", "value": "C50504"},
             {"label": "Oshtorjin", "value": "C50505"},
             {"label": "Ovraqin", "value": "C50506"},
             {"label": "Owrteh Qamish", "value": "C50507"},
             {"label": "Owryad", "value": "C50508"},
             {"label": "Pahnabad", "value": "C50509"},
             {"label": "Panbeh Dar", "value": "C50510"},
             {"label": "Parluk", "value": "C50511"},
             {"label": "Parvaraq", "value": "C50512"},
             {"label": "Payandeh", "value": "C50513"},
             {"label": "Peyhan", "value": "C50514"},
             {"label": "Pileh Jin", "value": "C50515"},
             {"label": "Pir Anbar", "value": "C50516"},
             {"label": "Pir Badam", "value": "C50517"},
             {"label": "Pir Khodaverdi", "value": "C50518"},
             {"label": "Pir Malu", "value": "C50519"},
             {"label": "Pir Mishan", "value": "C50520"},
             {"label": "Pir Savaran", "value": "C50521"},
             {"label": "Pir Yusof", "value": "C50522"},
             {"label": "Piruz", "value": "C50523"},
             {"label": "Poshtjin", "value": "C50524"},
             {"label": "Qabanqoli", "value": "C50525"},
             {"label": "Qader Khalaj", "value": "C50526"},
             {"label": "Qadimi", "value": "C50527"},
             {"label": "Qahavand", "value": "C50528"},
             {"label": "Qajar Ab-e Sofla", "value": "C50529"},
             {"label": "Qarah Day", "value": "C50530"},
             {"label": "Qarah Tegini", "value": "C50531"},
             {"label": "Qaranqu Darreh", "value": "C50532"},
             {"label": "Qasabestan", "value": "C50533"},
             {"label": "Qasemabad-e Laklak", "value": "C50534"},
             {"label": "Qater Owlan", "value": "C50535"},
             {"label": "Qayesh", "value": "C50536"},
             {"label": "Qazan Qarah", "value": "C50537"},
             {"label": "Qazi Mardan", "value": "C50538"},
             {"label": "Qeshlaq Baba Rostam", "value": "C50539"},
             {"label": "Qeshlaq-e Anuch", "value": "C50540"},
             {"label": "Qeshlaq-e Deh Ful", "value": "C50541"},
             {"label": "Qeshlaq-e Dehnow", "value": "C50542"},
             {"label": "Qeshlaq-e Mohammadi", "value": "C50543"},
             {"label": "Qeshlaq-e Najaf", "value": "C50544"},
             {"label": "Qeshlaq-e Pust Shuran", "value": "C50545"},
             {"label": "Qeshlaq-e Qobad", "value": "C50546"},
             {"label": "Qeshlaq-e Shirazi", "value": "C50547"},
             {"label": "Qeshlaq-e Sofla", "value": "C50548"},
             {"label": "Qeshlaq-e Valiollah", "value": "C50549"},
             {"label": "Qeydli Bolagh", "value": "C50550"},
             {"label": "Qilab", "value": "C50551"},
             {"label": "Qohurd-e Sofla", "value": "C50552"},
             {"label": "Qoli Laleh-ye Sofla", "value": "C50553"},
             {"label": "Qolqolabad", "value": "C50554"},
             {"label": "Quch Tappeh", "value": "C50555"},
             {"label": "Qushjeh", "value": "C50556"},
             {"label": "Rahmanabad, Malayer", "value": "C50557"},
             {"label": "Ramishan", "value": "C50558"},
             {"label": "Rastguyan", "value": "C50559"},
             {"label": "Rasulabad-e Sofla", "value": "C50560"},
             {"label": "Ravand-e Vosta", "value": "C50561"},
             {"label": "Rayegan-e Sofla", "value": "C50562"},
             {"label": "Razaj", "value": "C50563"},
             {"label": "Razan", "value": "C50564"},
             {"label": "Razini", "value": "C50565"},
             {"label": "Reza Baghi", "value": "C50566"},
             {"label": "Rezvankadeh", "value": "C50567"},
             {"label": "Rivasijan", "value": "C50568"},
             {"label": "Robat-e Sheverin", "value": "C50569"},
             {"label": "Rud Avar", "value": "C50570"},
             {"label": "Rud-e Hasan-e Sofla", "value": "C50571"},
             {"label": "Safa Riz", "value": "C50572"},
             {"label": "Sahamabad", "value": "C50573"},
             {"label": "Salehabad", "value": "C50574"},
             {"label": "Salilak", "value": "C50575"},
             {"label": "Saluk", "value": "C50576"},
             {"label": "Samen", "value": "C50577"},
             {"label": "Sanaj", "value": "C50578"},
             {"label": "Sanjuzan", "value": "C50579"},
             {"label": "Sar Duran", "value": "C50580"},
             {"label": "Sarab-e Tajar", "value": "C50581"},
             {"label": "Saravak", "value": "C50582"},
             {"label": "Sarayjuq", "value": "C50583"},
             {"label": "Sarijlu", "value": "C50584"},
             {"label": "Sarkan", "value": "C50585"},
             {"label": "Saziyan", "value": "C50586"},
             {"label": "Sefid Khaneh", "value": "C50587"},
             {"label": "Segavi", "value": "C50588"},
             {"label": "Setaq", "value": "C50589"},
             {"label": "Shademaneh", "value": "C50590"},
             {"label": "Shanabad", "value": "C50591"},
             {"label": "Shanjur", "value": "C50592"},
             {"label": "Sharifabad-e Quzan", "value": "C50593"},
             {"label": "Sharifabad-e Tajar", "value": "C50594"},
             {"label": "Shatzal", "value": "C50595"},
             {"label": "Shavand", "value": "C50596"},
             {"label": "Sheverin", "value": "C50597"},
             {"label": "Sheykh Jarrah", "value": "C50598"},
             {"label": "Shilandar-e Sofla", "value": "C50599"},
             {"label": "Shir Barat", "value": "C50600"},
             {"label": "Shirin Su", "value": "C50601"},
             {"label": "Shush Ab", "value": "C50602"},
             {"label": "Siah Goleh", "value": "C50603"},
             {"label": "Sib Dar", "value": "C50604"},
             {"label": "Silvar", "value": "C50605"},
             {"label": "Simin-e Abaru", "value": "C50606"},
             {"label": "Simin-e Zagheh", "value": "C50607"},
             {"label": "Siravand", "value": "C50608"},
             {"label": "Sistaneh", "value": "C50609"},
             {"label": "Solulan", "value": "C50610"},
             {"label": "Sorkh Kand", "value": "C50611"},
             {"label": "Sulijeh", "value": "C50612"},
             {"label": "Surtajin", "value": "C50613"},
             {"label": "Sutlaq", "value": "C50614"},
             {"label": "Tafrijan", "value": "C50615"},
             {"label": "Tahunabad", "value": "C50616"},
             {"label": "Tajar-e Samen", "value": "C50617"},
             {"label": "Takhtiabad", "value": "C50618"},
             {"label": "Taleh Jerd-e Sofla", "value": "C50619"},
             {"label": "Tamuzan", "value": "C50620"},
             {"label": "Tappeh Yazdan", "value": "C50621"},
             {"label": "Tappeh-ye Dibi", "value": "C50622"},
             {"label": "Taraqayah", "value": "C50623"},
             {"label": "Tarik Darreh-ye Bala", "value": "C50624"},
             {"label": "Tarkhinabad", "value": "C50625"},
             {"label": "Tas Tappeh", "value": "C50626"},
             {"label": "Tasbandi", "value": "C50627"},
             {"label": "Tasran", "value": "C50628"},
             {"label": "Tavaleh", "value": "C50629"},
             {"label": "Tavaneh", "value": "C50630"},
             {"label": "Taveh", "value": "C50631"},
             {"label": "Tavilan-e Sofla", "value": "C50632"},
             {"label": "Tazehnab-e Sofla", "value": "C50633"},
             {"label": "Termianak", "value": "C50634"},
             {"label": "Timi Jan", "value": "C50635"},
             {"label": "Tuchaqaz", "value": "C50636"},
             {"label": "Tulki Tappeh", "value": "C50637"},
             {"label": "Tusk-e Sofla", "value": "C50638"},
             {"label": "Tutal", "value": "C50639"},
             {"label": "Tuyserkan", "value": "C50640"},
             {"label": "Vafr-e Jin", "value": "C50641"},
             {"label": "Vahandeh", "value": "C50642"},
             {"label": "Vahman", "value": "C50643"},
             {"label": "Vahnan", "value": "C50644"},
             {"label": "Vali Mohammad", "value": "C50645"},
             {"label": "Vali Sir Ab", "value": "C50646"},
             {"label": "Vandarabad", "value": "C50647"},
             {"label": "Varayeneh", "value": "C50648"},
             {"label": "Varazaneh", "value": "C50649"},
             {"label": "Varchaq", "value": "C50650"},
             {"label": "Vardavard-e Sofla", "value": "C50651"},
             {"label": "Vardavard-e Vosta", "value": "C50652"},
             {"label": "Varkaneh", "value": "C50653"},
             {"label": "Varqestan", "value": "C50654"},
             {"label": "Varvazin", "value": "C50655"},
             {"label": "Vasaj", "value": "C50656"},
             {"label": "Vashur", "value": "C50657"},
             {"label": "Vasleh", "value": "C50658"},
             {"label": "Vesht", "value": "C50659"},
             {"label": "Vezendan", "value": "C50660"},
             {"label": "Yalfan", "value": "C50661"},
             {"label": "Yarom Qayah", "value": "C50662"},
             {"label": "Yaromjeh Bagh", "value": "C50663"},
             {"label": "Yeganeh", "value": "C50664"},
             {"label": "Yekanabad", "value": "C50665"},
             {"label": "Yekleh", "value": "C50666"},
             {"label": "Yeserlu", "value": "C50667"},
             {"label": "Yunes", "value": "C50668"},
             {"label": "Yunji", "value": "C50669"},
             {"label": "Zagheh-ye Anuch", "value": "C50670"},
             {"label": "Zagheh-ye Tasbandi", "value": "C50671"},
             {"label": "Zaghlijeh", "value": "C50672"},
             {"label": "Zamanabad-e Mohammadabad", "value": "C50673"},
             {"label": "Zangeneh-ye Sofla", "value": "C50674"},
             {"label": "Zapon", "value": "C50675"},
             {"label": "Zaramin-e Sofla", "value": "C50676"},
             {"label": "Zarrin Bagh", "value": "C50677"},
             {"label": "Zeraq", "value": "C50678"},
             {"label": "Zirabiyeh", "value": "C50679"}],
 "Hamburg": [{"label": "Alsterdorf", "value": "C37383"},
             {"label": "Altona", "value": "C37384"},
             {"label": "Barmbek-Nord", "value": "C37385"},
             {"label": "Bergedorf", "value": "C37386"},
             {"label": "Bergstedt", "value": "C37387"},
             {"label": "Borgfelde", "value": "C37388"},
             {"label": "Duvenstedt", "value": "C37389"},
             {"label": "Eidelstedt", "value": "C37390"},
             {"label": "Eimsbuttel", "value": "C37391"},
             {"label": "Farmsen-Berne", "value": "C37392"},
             {"label": "Fuhlsbuttel", "value": "C37393"},
             {"label": "Hamburg", "value": "C37394"},
             {"label": "Hamburg-Altstadt", "value": "C37395"},
             {"label": "Hamburg-Mitte", "value": "C37396"},
             {"label": "Hamburg-Nord", "value": "C37397"},
             {"label": "Hammerbrook", "value": "C37398"},
             {"label": "Harburg", "value": "C37399"},
             {"label": "Hummelsbuttel", "value": "C37400"},
             {"label": "Kleiner Grasbrook", "value": "C37401"},
             {"label": "Langenhorn", "value": "C37402"},
             {"label": "Lemsahl-Mellingstedt", "value": "C37403"},
             {"label": "Lurup", "value": "C37404"},
             {"label": "Marienthal", "value": "C37405"},
             {"label": "Neustadt", "value": "C37406"},
             {"label": "Ohlsdorf", "value": "C37407"},
             {"label": "Ottensen", "value": "C37408"},
             {"label": "Poppenbuttel", "value": "C37409"},
             {"label": "Rothenburgsort", "value": "C37410"},
             {"label": "Sasel", "value": "C37411"},
             {"label": "St. Georg", "value": "C37412"},
             {"label": "St. Pauli", "value": "C37413"},
             {"label": "Steilshoop", "value": "C37414"},
             {"label": "Stellingen", "value": "C37415"},
             {"label": "Wandsbek", "value": "C37416"},
             {"label": "Wellingsbuttel", "value": "C37417"},
             {"label": "Winterhude", "value": "C37418"},
             {"label": "Wohldorf-Ohlstedt", "value": "C37419"}],
 "Hamrun": [{"label": "Hamrun", "value": "C65885"}],
 "Hanover Parish": [{"label": "Askenish", "value": "C61770"},
                    {"label": "Cacoon", "value": "C61771"},
                    {"label": "Cacoon Castle", "value": "C61772"},
                    {"label": "Cascade", "value": "C61773"},
                    {"label": "Cash Hill", "value": "C61774"},
                    {"label": "Cauldwell", "value": "C61775"},
                    {"label": "Chambers Pen", "value": "C61776"},
                    {"label": "Chester Castle", "value": "C61777"},
                    {"label": "Copse", "value": "C61778"},
                    {"label": "Cousins Cove", "value": "C61779"},
                    {"label": "Dias", "value": "C61780"},
                    {"label": "Great Valley", "value": "C61781"},
                    {"label": "Green Island", "value": "C61782"},
                    {"label": "Haddington", "value": "C61783"},
                    {"label": "Hopewell", "value": "C61784"},
                    {"label": "Jericho", "value": "C61785"},
                    {"label": "Kendal", "value": "C61786"},
                    {"label": "Kingsvale", "value": "C61787"},
                    {"label": "Lances Bay", "value": "C61788"},
                    {"label": "Logwood", "value": "C61789"},
                    {"label": "Lucea", "value": "C61790"},
                    {"label": "Lucea East", "value": "C61791"},
                    {"label": "Lucea West", "value": "C61792"},
                    {"label": "March Town", "value": "C61793"},
                    {"label": "Maryland", "value": "C61794"},
                    {"label": "Mount Peto", "value": "C61795"},
                    {"label": "Orange Bay", "value": "C61796"},
                    {"label": "Pell River", "value": "C61797"},
                    {"label": "Pondside", "value": "C61798"},
                    {"label": "Ramble", "value": "C61799"},
                    {"label": "Rock Spring", "value": "C61800"},
                    {"label": "Sandy Bay", "value": "C61801"},
                    {"label": "Santoy", "value": "C61802"},
                    {"label": "Success", "value": "C61803"},
                    {"label": "Woodlands", "value": "C61804"},
                    {"label": "Woodsville", "value": "C61805"}],
 "Harare Province": [{"label": "Chitungwiza", "value": "C148159"},
                     {"label": "Epworth", "value": "C148160"},
                     {"label": "Harare", "value": "C148161"}],
 "Harari Region": [{"label": "Harar", "value": "C24703"}],
 "Harbour Island": [{"label": "Dunmore Town", "value": "C119612"}],
 "Hardap Region": [{"label": "Aranos", "value": "C75973"},
                   {"label": "Hoachanas", "value": "C75974"},
                   {"label": "Maltahohe", "value": "C75975"},
                   {"label": "Mariental", "value": "C75976"},
                   {"label": "Rehoboth", "value": "C75977"}],
 "Harghita County": [{"label": "Atid", "value": "C95741"},
                     {"label": "Avramesti", "value": "C95742"},
                     {"label": "Baile Tusnad", "value": "C95743"},
                     {"label": "Balan", "value": "C95744"},
                     {"label": "Bancu", "value": "C95745"},
                     {"label": "Bilbor", "value": "C95746"},
                     {"label": "Borsec", "value": "C95747"},
                     {"label": "Bradesti", "value": "C95748"},
                     {"label": "Capalnita", "value": "C95749"},
                     {"label": "Carta", "value": "C95750"},
                     {"label": "Ciceu", "value": "C95751"},
                     {"label": "Ciucani", "value": "C95752"},
                     {"label": "Ciucsangeorgiu", "value": "C95753"},
                     {"label": "Ciumani", "value": "C95754"},
                     {"label": "Comuna Atid", "value": "C95755"},
                     {"label": "Comuna Avramesti", "value": "C95756"},
                     {"label": "Comuna Bilbor", "value": "C95757"},
                     {"label": "Comuna Bradesti", "value": "C95758"},
                     {"label": "Comuna Capalnita", "value": "C95759"},
                     {"label": "Comuna Carta", "value": "C95760"},
                     {"label": "Comuna Ciceu", "value": "C95761"},
                     {"label": "Comuna Ciucsangeorgiu", "value": "C95762"},
                     {"label": "Comuna Ciumani", "value": "C95763"},
                     {"label": "Comuna Corbu", "value": "C95764"},
                     {"label": "Comuna Corund", "value": "C95765"},
                     {"label": "Comuna Cozmeni", "value": "C95766"},
                     {"label": "Comuna Danesti", "value": "C95767"},
                     {"label": "Comuna Darjiu", "value": "C95768"},
                     {"label": "Comuna Dealu", "value": "C95769"},
                     {"label": "Comuna Ditrau", "value": "C95770"},
                     {"label": "Comuna Feliceni", "value": "C95771"},
                     {"label": "Comuna Frumoasa", "value": "C95772"},
                     {"label": "Comuna Galautas", "value": "C95773"},
                     {"label": "Comuna Joseni", "value": "C95774"},
                     {"label": "Comuna Lazarea", "value": "C95775"},
                     {"label": "Comuna Leliceni", "value": "C95776"},
                     {"label": "Comuna Lueta", "value": "C95777"},
                     {"label": "Comuna Lunca de Jos", "value": "C95778"},
                     {"label": "Comuna Lunca de Sus", "value": "C95779"},
                     {"label": "Comuna Lupeni", "value": "C95780"},
                     {"label": "Comuna Madaras", "value": "C95781"},
                     {"label": "Comuna Martinis", "value": "C95782"},
                     {"label": "Comuna Meresti", "value": "C95783"},
                     {"label": "Comuna Mihaileni", "value": "C95784"},
                     {"label": "Comuna Mugeni", "value": "C95785"},
                     {"label": "Comuna Ocland", "value": "C95786"},
                     {"label": "Comuna Pauleni-Ciuc", "value": "C95787"},
                     {"label": "Comuna Plaiesii De Jos", "value": "C95788"},
                     {"label": "Comuna Porumbeni", "value": "C95789"},
                     {"label": "Comuna Praid", "value": "C95790"},
                     {"label": "Comuna Racu", "value": "C95791"},
                     {"label": "Comuna Remetea", "value": "C95792"},
                     {"label": "Comuna Sacel", "value": "C95793"},
                     {"label": "Comuna Sancraieni", "value": "C95794"},
                     {"label": "Comuna Sandominic", "value": "C95795"},
                     {"label": "Comuna Sanmartin", "value": "C95796"},
                     {"label": "Comuna Sansimion", "value": "C95797"},
                     {"label": "Comuna Santimbru", "value": "C95798"},
                     {"label": "Comuna Sarmas", "value": "C95799"},
                     {"label": "Comuna Satu Mare", "value": "C95800"},
                     {"label": "Comuna Secuieni", "value": "C95801"},
                     {"label": "Comuna Siculeni", "value": "C95802"},
                     {"label": "Comuna Simonesti", "value": "C95803"},
                     {"label": "Comuna Subcetate", "value": "C95804"},
                     {"label": "Comuna Suseni", "value": "C95805"},
                     {"label": "Comuna Tomesti", "value": "C95806"},
                     {"label": "Comuna Tulghes", "value": "C95807"},
                     {"label": "Comuna Tusnad", "value": "C95808"},
                     {"label": "Comuna Ulies", "value": "C95809"},
                     {"label": "Comuna Varsag", "value": "C95810"},
                     {"label": "Comuna Voslabeni", "value": "C95811"},
                     {"label": "Comuna Zetea", "value": "C95812"},
                     {"label": "Corbu", "value": "C95813"},
                     {"label": "Corund", "value": "C95814"},
                     {"label": "Cozmeni", "value": "C95815"},
                     {"label": "Cristuru Secuiesc", "value": "C95816"},
                     {"label": "Danesti", "value": "C95817"},
                     {"label": "Darjiu", "value": "C95818"},
                     {"label": "Dealu", "value": "C95819"},
                     {"label": "Ditrau", "value": "C95820"},
                     {"label": "Eliseni", "value": "C95821"},
                     {"label": "Feliceni", "value": "C95822"},
                     {"label": "Filias", "value": "C95823"},
                     {"label": "Frumoasa", "value": "C95824"},
                     {"label": "Galautas", "value": "C95825"},
                     {"label": "Gheorgheni", "value": "C95826"},
                     {"label": "Hodosa", "value": "C95827"},
                     {"label": "Imper", "value": "C95828"},
                     {"label": "Ineu", "value": "C95829"},
                     {"label": "Joseni", "value": "C95830"},
                     {"label": "Lazarea", "value": "C95831"},
                     {"label": "Leliceni", "value": "C95832"},
                     {"label": "Lueta", "value": "C95833"},
                     {"label": "Lunca de Jos", "value": "C95834"},
                     {"label": "Lunca de Sus", "value": "C95835"},
                     {"label": "Lupeni", "value": "C95836"},
                     {"label": "Madaras", "value": "C95837"},
                     {"label": "Martinis", "value": "C95838"},
                     {"label": "Meresti", "value": "C95839"},
                     {"label": "Miercurea-Ciuc", "value": "C95840"},
                     {"label": "Mihaileni", "value": "C95841"},
                     {"label": "Misentea", "value": "C95842"},
                     {"label": "Mugeni", "value": "C95843"},
                     {"label": "Municipiul  Toplita", "value": "C95844"},
                     {"label": "Municipiul Gheorgheni", "value": "C95845"},
                     {"label": "Municipiul Miercurea Ciuc", "value": "C95846"},
                     {"label": "Municipiul Odorheiu Secuiesc",
                      "value": "C95847"},
                     {"label": "Nicolesti", "value": "C95848"},
                     {"label": "Ocland", "value": "C95849"},
                     {"label": "Ocna de Jos", "value": "C95850"},
                     {"label": "Ocna de Sus", "value": "C95851"},
                     {"label": "Odorheiu Secuiesc", "value": "C95852"},
                     {"label": "Oras Baile Tusnad", "value": "C95853"},
                     {"label": "Oras Balan", "value": "C95854"},
                     {"label": "Oras Borsec", "value": "C95855"},
                     {"label": "Oras Cristuru Secuiesc", "value": "C95856"},
                     {"label": "Oras Vlahita", "value": "C95857"},
                     {"label": "Pauleni-Ciuc", "value": "C95858"},
                     {"label": "Porumbenii Mari", "value": "C95859"},
                     {"label": "Praid", "value": "C95860"},
                     {"label": "Racu", "value": "C95861"},
                     {"label": "Remetea", "value": "C95862"},
                     {"label": "Sacel", "value": "C95863"},
                     {"label": "Sancrai", "value": "C95864"},
                     {"label": "Sancraieni", "value": "C95865"},
                     {"label": "Sandominic", "value": "C95866"},
                     {"label": "Sanmartin", "value": "C95867"},
                     {"label": "Sansimion", "value": "C95868"},
                     {"label": "Santimbru", "value": "C95869"},
                     {"label": "Sarmas", "value": "C95870"},
                     {"label": "Satu Mare", "value": "C95871"},
                     {"label": "Siculeni", "value": "C95872"},
                     {"label": "Simonesti", "value": "C95873"},
                     {"label": "Subcetate", "value": "C95874"},
                     {"label": "Suseni", "value": "C95875"},
                     {"label": "Taureni", "value": "C95876"},
                     {"label": "Tomesti", "value": "C95877"},
                     {"label": "Tulghes", "value": "C95878"},
                     {"label": "Tusnad", "value": "C95879"},
                     {"label": "Tusnadu Nou", "value": "C95880"},
                     {"label": "Vale", "value": "C95881"},
                     {"label": "Valea Rece", "value": "C95882"},
                     {"label": "Valea Stramba", "value": "C95883"},
                     {"label": "Varsag", "value": "C95884"},
                     {"label": "Vlahita", "value": "C95885"},
                     {"label": "Voslabeni", "value": "C95886"},
                     {"label": "Zencani", "value": "C95887"},
                     {"label": "Zetea", "value": "C95888"}],
 "Harju County": [{"label": "Anija vald", "value": "C24499"},
                  {"label": "Arukula", "value": "C24500"},
                  {"label": "Haabneeme", "value": "C24501"},
                  {"label": "Harku", "value": "C24502"},
                  {"label": "Harku vald", "value": "C24503"},
                  {"label": "Joelahtme vald", "value": "C24504"},
                  {"label": "Juri", "value": "C24505"},
                  {"label": "Kehra", "value": "C24506"},
                  {"label": "Keila", "value": "C24507"},
                  {"label": "Kiili", "value": "C24508"},
                  {"label": "Kiili vald", "value": "C24509"},
                  {"label": "Kose", "value": "C24510"},
                  {"label": "Kuusalu", "value": "C24511"},
                  {"label": "Laagri", "value": "C24512"},
                  {"label": "Loksa", "value": "C24513"},
                  {"label": "Loksa linn", "value": "C24514"},
                  {"label": "Loo", "value": "C24515"},
                  {"label": "Maardu", "value": "C24516"},
                  {"label": "Maardu linn", "value": "C24517"},
                  {"label": "Paldiski", "value": "C24518"},
                  {"label": "Pringi", "value": "C24519"},
                  {"label": "Raasiku", "value": "C24520"},
                  {"label": "Rae vald", "value": "C24521"},
                  {"label": "Riisipere", "value": "C24522"},
                  {"label": "Rummu", "value": "C24523"},
                  {"label": "Saku", "value": "C24524"},
                  {"label": "Saku vald", "value": "C24525"},
                  {"label": "Saue", "value": "C24526"},
                  {"label": "Saue vald", "value": "C24527"},
                  {"label": "Tabasalu", "value": "C24528"},
                  {"label": "Tallinn", "value": "C24529"},
                  {"label": "Turba", "value": "C24530"},
                  {"label": "Vaida", "value": "C24531"},
                  {"label": "Viimsi", "value": "C24532"},
                  {"label": "Viimsi vald", "value": "C24533"}],
 "Haryana": [{"label": "Ambala", "value": "C45779"},
             {"label": "Asandh", "value": "C45780"},
             {"label": "Ateli Mandi", "value": "C45781"},
             {"label": "Bahadurgarh", "value": "C45782"},
             {"label": "Bara Uchana", "value": "C45783"},
             {"label": "Barwala", "value": "C45784"},
             {"label": "Bawal", "value": "C45785"},
             {"label": "Beri Khas", "value": "C45786"},
             {"label": "Bhiwani", "value": "C45787"},
             {"label": "Bilaspur", "value": "C45788"},
             {"label": "Buriya", "value": "C45789"},
             {"label": "Charkhi Dadri", "value": "C45790"},
             {"label": "Chhachhrauli", "value": "C45791"},
             {"label": "Dabwali", "value": "C45792"},
             {"label": "Dharuhera", "value": "C45793"},
             {"label": "Ellenabad", "value": "C45794"},
             {"label": "Faridabad", "value": "C45795"},
             {"label": "Faridabad District", "value": "C45796"},
             {"label": "Farrukhnagar", "value": "C45797"},
             {"label": "Fatehabad", "value": "C45798"},
             {"label": "Fatehabad District", "value": "C45799"},
             {"label": "Firozpur Jhirka", "value": "C45800"},
             {"label": "Gharaunda", "value": "C45801"},
             {"label": "Gohana", "value": "C45802"},
             {"label": "Gorakhpur", "value": "C45803"},
             {"label": "Gurgaon", "value": "C45804"},
             {"label": "Hansi", "value": "C45805"},
             {"label": "Hasanpur", "value": "C45806"},
             {"label": "Hisar", "value": "C45807"},
             {"label": "Hodal", "value": "C45808"},
             {"label": "Inda Chhoi", "value": "C45809"},
             {"label": "Indri", "value": "C45810"},
             {"label": "Jagadhri", "value": "C45811"},
             {"label": "Jakhal", "value": "C45812"},
             {"label": "Jhajjar", "value": "C45813"},
             {"label": "Jind", "value": "C45814"},
             {"label": "Kaithal", "value": "C45815"},
             {"label": "Kalanaur", "value": "C45816"},
             {"label": "Kalanwali", "value": "C45817"},
             {"label": "Kanina Khas", "value": "C45818"},
             {"label": "Karnal", "value": "C45819"},
             {"label": "Kharkhauda", "value": "C45820"},
             {"label": "Kheri Sampla", "value": "C45821"},
             {"label": "Kurukshetra", "value": "C45822"},
             {"label": "Ladwa", "value": "C45823"},
             {"label": "Loharu", "value": "C45824"},
             {"label": "Maham", "value": "C45825"},
             {"label": "Mahendragarh", "value": "C45826"},
             {"label": "Mandholi Kalan", "value": "C45827"},
             {"label": "Mustafabad", "value": "C45828"},
             {"label": "Narayangarh", "value": "C45829"},
             {"label": "Narnaul", "value": "C45830"},
             {"label": "Narnaund", "value": "C45831"},
             {"label": "Narwana", "value": "C45832"},
             {"label": "Nilokheri", "value": "C45833"},
             {"label": "Nuh", "value": "C45834"},
             {"label": "Palwal", "value": "C45835"},
             {"label": "Panchkula", "value": "C45836"},
             {"label": "Panipat", "value": "C45837"},
             {"label": "Pataudi", "value": "C45838"},
             {"label": "Pehowa", "value": "C45839"},
             {"label": "Pinjaur", "value": "C45840"},
             {"label": "Punahana", "value": "C45841"},
             {"label": "Pundri", "value": "C45842"},
             {"label": "Radaur", "value": "C45843"},
             {"label": "Rania", "value": "C45844"},
             {"label": "Ratia", "value": "C45845"},
             {"label": "Rewari", "value": "C45846"},
             {"label": "Rewari District", "value": "C45847"},
             {"label": "Rohtak", "value": "C45848"},
             {"label": "Safidon", "value": "C45849"},
             {"label": "Samalkha", "value": "C45850"},
             {"label": "Shadipur Julana", "value": "C45851"},
             {"label": "Shahabad", "value": "C45852"},
             {"label": "Sirsa", "value": "C45853"},
             {"label": "Sohna", "value": "C45854"},
             {"label": "Sonipat", "value": "C45855"},
             {"label": "Taoru", "value": "C45856"},
             {"label": "Thanesar", "value": "C45857"},
             {"label": "Tohana", "value": "C45858"},
             {"label": "Tosham", "value": "C45859"},
             {"label": "Uklana", "value": "C45860"},
             {"label": "Yamunanagar", "value": "C45861"}],
 "Haskovo Province": [{"label": "Dimitrovgrad", "value": "C15930"},
                      {"label": "Harmanli", "value": "C15931"},
                      {"label": "Haskovo", "value": "C15932"},
                      {"label": "Ivaylovgrad", "value": "C15933"},
                      {"label": "Lyubimets", "value": "C15934"},
                      {"label": "Madzharovo", "value": "C15935"},
                      {"label": "Mineralni Bani", "value": "C15936"},
                      {"label": "Obshtina Dimitrovgrad", "value": "C15937"},
                      {"label": "Obshtina Harmanli", "value": "C15938"},
                      {"label": "Obshtina Haskovo", "value": "C15939"},
                      {"label": "Obshtina Ivaylovgrad", "value": "C15940"},
                      {"label": "Obshtina Madzharovo", "value": "C15941"},
                      {"label": "Obshtina Mineralni Bani", "value": "C15942"},
                      {"label": "Obshtina Stambolovo", "value": "C15943"},
                      {"label": "Obshtina Svilengrad", "value": "C15944"},
                      {"label": "Obshtina Topolovgrad", "value": "C15945"},
                      {"label": "Simeonovgrad", "value": "C15946"},
                      {"label": "Svilengrad", "value": "C15947"},
                      {"label": "Topolovgrad", "value": "C15948"}],
 "Hatay": [{"label": "Acikdere", "value": "C120364"},
           {"label": "Altinozu", "value": "C120365"},
           {"label": "Anayazi", "value": "C120366"},
           {"label": "Antakya", "value": "C120367"},
           {"label": "Arsuz", "value": "C120368"},
           {"label": "Asagi Karafakili", "value": "C120369"},
           {"label": "Asagiokcular", "value": "C120370"},
           {"label": "Baliklidere", "value": "C120371"},
           {"label": "Belen", "value": "C120372"},
           {"label": "Boynuyogun", "value": "C120373"},
           {"label": "Buyuk Dalyan", "value": "C120374"},
           {"label": "Buyukcat", "value": "C120375"},
           {"label": "Cirtiman", "value": "C120376"},
           {"label": "Defne", "value": "C120377"},
           {"label": "Denizciler", "value": "C120378"},
           {"label": "Dortyol", "value": "C120379"},
           {"label": "Dursunlu", "value": "C120380"},
           {"label": "Erzin", "value": "C120381"},
           {"label": "Gumusgoze", "value": "C120382"},
           {"label": "Gunyazi", "value": "C120383"},
           {"label": "Hacipasa", "value": "C120384"},
           {"label": "Hassa", "value": "C120385"},
           {"label": "Iskenderun", "value": "C120386"},
           {"label": "Karasuleymanli", "value": "C120387"},
           {"label": "Kastal", "value": "C120388"},
           {"label": "Kirikhan", "value": "C120389"},
           {"label": "Kislak", "value": "C120390"},
           {"label": "Kizkalesi", "value": "C120391"},
           {"label": "Kumlu Ilcesi", "value": "C120392"},
           {"label": "Kuzeytepe", "value": "C120393"},
           {"label": "Mahmutlar", "value": "C120394"},
           {"label": "Payas", "value": "C120395"},
           {"label": "Reyhanli", "value": "C120396"},
           {"label": "Samankaya", "value": "C120397"},
           {"label": "Serinyol", "value": "C120398"},
           {"label": "Tepehan", "value": "C120399"},
           {"label": "Toygarli", "value": "C120400"},
           {"label": "Uzunbag", "value": "C120401"},
           {"label": "Uzunkavak", "value": "C120402"},
           {"label": "Yayladagi", "value": "C120403"}],
 "Hato Mayor Province": [{"label": "El Valle", "value": "C23902"},
                         {"label": "Guayabo Dulce", "value": "C23903"},
                         {"label": "Hato Mayor del Rey", "value": "C23904"},
                         {"label": "Sabana de la Mar", "value": "C23905"}],
 "Hatohobei": [{"label": "Tobi Village", "value": "C79773"}],
 "Hau Giang": [{"label": "Huyen Chau Thanh A", "value": "C147426"},
               {"label": "Vi Thanh", "value": "C147427"}],
 "Haut-Katanga": [{"label": "Haut Katanga", "value": "C23315"},
                  {"label": "Kambove", "value": "C23316"},
                  {"label": "Kipushi", "value": "C23317"},
                  {"label": "Likasi", "value": "C23318"},
                  {"label": "Lubumbashi", "value": "C23319"}],
 "Haut-Lomami": [{"label": "Bukama", "value": "C23320"},
                 {"label": "Kamina", "value": "C23321"}],
 "Haut-Mbomou Prefecture": [{"label": "Obo", "value": "C17814"},
                            {"label": "Zemio", "value": "C17815"}],
 "Haut-Ogooue Province": [{"label": "Franceville", "value": "C34124"},
                          {"label": "Lekoni", "value": "C34125"},
                          {"label": "Moanda", "value": "C34126"},
                          {"label": "Mounana", "value": "C34127"},
                          {"label": "Okondja", "value": "C34128"}],
 "Haut-Uele": [{"label": "Isiro", "value": "C23322"},
               {"label": "Wamba", "value": "C23323"},
               {"label": "Watsa", "value": "C23324"}],
 "Haute-Kotto Prefecture": [{"label": "Bria", "value": "C17816"},
                            {"label": "Ouadda", "value": "C17817"}],
 "Hauts-Bassins Region": [{"label": "Bobo-Dioulasso", "value": "C16409"},
                          {"label": "Hounde", "value": "C16410"},
                          {"label": "Province du Houet", "value": "C16411"},
                          {"label": "Province du Kenedougou",
                           "value": "C16412"},
                          {"label": "Province du Tuy", "value": "C16413"}],
 "Hauts-de-France": [{"label": "Abbeville", "value": "C28989"},
                     {"label": "Ablain-Saint-Nazaire", "value": "C28990"},
                     {"label": "Abscon", "value": "C28991"},
                     {"label": "Achicourt", "value": "C28992"},
                     {"label": "Achiet-le-Grand", "value": "C28993"},
                     {"label": "Agnetz", "value": "C28994"},
                     {"label": "Agny", "value": "C28995"},
                     {"label": "Ailly-sur-Noye", "value": "C28996"},
                     {"label": "Ailly-sur-Somme", "value": "C28997"},
                     {"label": "Airaines", "value": "C28998"},
                     {"label": "Aire-sur-la-Lys", "value": "C28999"},
                     {"label": "Aix-Noulette", "value": "C29000"},
                     {"label": "Albert", "value": "C29001"},
                     {"label": "Allennes-les-Marais", "value": "C29002"},
                     {"label": "Allonne", "value": "C29003"},
                     {"label": "Allouagne", "value": "C29004"},
                     {"label": "Amblainville", "value": "C29005"},
                     {"label": "Ambleny", "value": "C29006"},
                     {"label": "Ambleteuse", "value": "C29007"},
                     {"label": "Amiens", "value": "C29008"},
                     {"label": "Andeville", "value": "C29009"},
                     {"label": "Andres", "value": "C29010"},
                     {"label": "Angicourt", "value": "C29011"},
                     {"label": "Angres", "value": "C29012"},
                     {"label": "Angy", "value": "C29013"},
                     {"label": "Anhiers", "value": "C29014"},
                     {"label": "Aniche", "value": "C29015"},
                     {"label": "Anizy-le-Chateau", "value": "C29016"},
                     {"label": "Annay", "value": "C29017"},
                     {"label": "Annequin", "value": "C29018"},
                     {"label": "Annezin", "value": "C29019"},
                     {"label": "Annoeullin", "value": "C29020"},
                     {"label": "Anor", "value": "C29021"},
                     {"label": "Ansauvillers", "value": "C29022"},
                     {"label": "Anstaing", "value": "C29023"},
                     {"label": "Anzin", "value": "C29024"},
                     {"label": "Anzin-Saint-Aubin", "value": "C29025"},
                     {"label": "Ardres", "value": "C29026"},
                     {"label": "Arleux", "value": "C29027"},
                     {"label": "Armbouts-Cappel", "value": "C29028"},
                     {"label": "Armentieres", "value": "C29029"},
                     {"label": "Arneke", "value": "C29030"},
                     {"label": "Arques", "value": "C29031"},
                     {"label": "Arras", "value": "C29032"},
                     {"label": "Artres", "value": "C29033"},
                     {"label": "Athies-sous-Laon", "value": "C29034"},
                     {"label": "Attiches", "value": "C29035"},
                     {"label": "Attichy", "value": "C29036"},
                     {"label": "Auberchicourt", "value": "C29037"},
                     {"label": "Aubers", "value": "C29038"},
                     {"label": "Aubigny-au-Bac", "value": "C29039"},
                     {"label": "Aubigny-en-Artois", "value": "C29040"},
                     {"label": "Aubry-du-Hainaut", "value": "C29041"},
                     {"label": "Auby", "value": "C29042"},
                     {"label": "Auchel", "value": "C29043"},
                     {"label": "Auchy-les-Hesdin", "value": "C29044"},
                     {"label": "Auchy-les-Mines", "value": "C29045"},
                     {"label": "Audruicq", "value": "C29046"},
                     {"label": "Aulnois-sous-Laon", "value": "C29047"},
                     {"label": "Aulnoy-lez-Valenciennes", "value": "C29048"},
                     {"label": "Aulnoye-Aymeries", "value": "C29049"},
                     {"label": "Ault", "value": "C29050"},
                     {"label": "Auneuil", "value": "C29051"},
                     {"label": "Auxi-le-Chateau", "value": "C29052"},
                     {"label": "Avelin", "value": "C29053"},
                     {"label": "Avesnelles", "value": "C29054"},
                     {"label": "Avesnes-le-Comte", "value": "C29055"},
                     {"label": "Avesnes-le-Sec", "value": "C29056"},
                     {"label": "Avesnes-les-Aubert", "value": "C29057"},
                     {"label": "Avilly-Saint-Leonard", "value": "C29058"},
                     {"label": "Avion", "value": "C29059"},
                     {"label": "Avrechy", "value": "C29060"},
                     {"label": "Bachant", "value": "C29061"},
                     {"label": "Bachy", "value": "C29062"},
                     {"label": "Bailleul", "value": "C29063"},
                     {"label": "Bailleul-Sir-Berthoult", "value": "C29064"},
                     {"label": "Bailleul-sur-Therain", "value": "C29065"},
                     {"label": "Bailleval", "value": "C29066"},
                     {"label": "Baincthun", "value": "C29067"},
                     {"label": "Baisieux", "value": "C29068"},
                     {"label": "Balagny-sur-Therain", "value": "C29069"},
                     {"label": "Bapaume", "value": "C29070"},
                     {"label": "Barlin", "value": "C29071"},
                     {"label": "Bauvin", "value": "C29072"},
                     {"label": "Bavay", "value": "C29073"},
                     {"label": "Beaucamps-le-Vieux", "value": "C29074"},
                     {"label": "Beauchamps", "value": "C29075"},
                     {"label": "Beaumetz-les-Loges", "value": "C29076"},
                     {"label": "Beauquesne", "value": "C29077"},
                     {"label": "Beaurains", "value": "C29078"},
                     {"label": "Beaurainville", "value": "C29079"},
                     {"label": "Beaurevoir", "value": "C29080"},
                     {"label": "Beautor", "value": "C29081"},
                     {"label": "Beauvais", "value": "C29082"},
                     {"label": "Beauval", "value": "C29083"},
                     {"label": "Beauvois-en-Cambresis", "value": "C29084"},
                     {"label": "Bellaing", "value": "C29085"},
                     {"label": "Belleu", "value": "C29086"},
                     {"label": "Berck", "value": "C29087"},
                     {"label": "Berck-Plage", "value": "C29088"},
                     {"label": "Bergues", "value": "C29089"},
                     {"label": "Berlaimont", "value": "C29090"},
                     {"label": "Bernaville", "value": "C29091"},
                     {"label": "Bersee", "value": "C29092"},
                     {"label": "Berteaucourt-les-Dames", "value": "C29093"},
                     {"label": "Berthecourt", "value": "C29094"},
                     {"label": "Bertry", "value": "C29095"},
                     {"label": "Bethencourt-sur-Mer", "value": "C29096"},
                     {"label": "Bethisy-Saint-Martin", "value": "C29097"},
                     {"label": "Bethisy-Saint-Pierre", "value": "C29098"},
                     {"label": "Bethune", "value": "C29099"},
                     {"label": "Betz", "value": "C29100"},
                     {"label": "Beuvrages", "value": "C29101"},
                     {"label": "Beuvry", "value": "C29102"},
                     {"label": "Biache-Saint-Vaast", "value": "C29103"},
                     {"label": "Bierne", "value": "C29104"},
                     {"label": "Billy-Berclau", "value": "C29105"},
                     {"label": "Billy-Montigny", "value": "C29106"},
                     {"label": "Billy-sur-Aisne", "value": "C29107"},
                     {"label": "Blaincourt-les-Precy", "value": "C29108"},
                     {"label": "Blaringhem", "value": "C29109"},
                     {"label": "Blendecques", "value": "C29110"},
                     {"label": "Blerancourt", "value": "C29111"},
                     {"label": "Boeschepe", "value": "C29112"},
                     {"label": "Bohain-en-Vermandois", "value": "C29113"},
                     {"label": "Bois-Grenier", "value": "C29114"},
                     {"label": "Bollezeele", "value": "C29115"},
                     {"label": "Bondues", "value": "C29116"},
                     {"label": "Boran-sur-Oise", "value": "C29117"},
                     {"label": "Bornel", "value": "C29118"},
                     {"label": "Bouchain", "value": "C29119"},
                     {"label": "Boue", "value": "C29120"},
                     {"label": "Boulogne-sur-Mer", "value": "C29121"},
                     {"label": "Bourbourg", "value": "C29122"},
                     {"label": "Bourghelles", "value": "C29123"},
                     {"label": "Bourlon", "value": "C29124"},
                     {"label": "Bousbecque", "value": "C29125"},
                     {"label": "Bousies", "value": "C29126"},
                     {"label": "Boussois", "value": "C29127"},
                     {"label": "Bouttencourt", "value": "C29128"},
                     {"label": "Bouvignies", "value": "C29129"},
                     {"label": "Bouvigny-Boyeffles", "value": "C29130"},
                     {"label": "Boves", "value": "C29131"},
                     {"label": "Braine", "value": "C29132"},
                     {"label": "Brasles", "value": "C29133"},
                     {"label": "Bray-Dunes", "value": "C29134"},
                     {"label": "Bray-sur-Somme", "value": "C29135"},
                     {"label": "Brebieres", "value": "C29136"},
                     {"label": "Bremes", "value": "C29137"},
                     {"label": "Brenouille", "value": "C29138"},
                     {"label": "Bresles", "value": "C29139"},
                     {"label": "Breteuil", "value": "C29140"},
                     {"label": "Breuil-le-Sec", "value": "C29141"},
                     {"label": "Breuil-le-Vert", "value": "C29142"},
                     {"label": "Brouckerque", "value": "C29143"},
                     {"label": "Bruay-la-Buissiere", "value": "C29144"},
                     {"label": "Bruay-sur-lEscaut", "value": "C29145"},
                     {"label": "Bruille-lez-Marchiennes", "value": "C29146"},
                     {"label": "Bruille-Saint-Amand", "value": "C29147"},
                     {"label": "Bruyeres-et-Montberault", "value": "C29148"},
                     {"label": "Bucquoy", "value": "C29149"},
                     {"label": "Bucy-le-Long", "value": "C29150"},
                     {"label": "Buironfosse", "value": "C29151"},
                     {"label": "Bully-les-Mines", "value": "C29152"},
                     {"label": "Burbure", "value": "C29153"},
                     {"label": "Bury", "value": "C29154"},
                     {"label": "Busigny", "value": "C29155"},
                     {"label": "Busnes", "value": "C29156"},
                     {"label": "Caestre", "value": "C29157"},
                     {"label": "Cagny", "value": "C29158"},
                     {"label": "Calais", "value": "C29159"},
                     {"label": "Calonne-Ricouart", "value": "C29160"},
                     {"label": "Calonne-sur-la-Lys", "value": "C29161"},
                     {"label": "Camblain-Chatelain", "value": "C29162"},
                     {"label": "Cambrai", "value": "C29163"},
                     {"label": "Cambronne-les-Clermont", "value": "C29164"},
                     {"label": "Cambronne-les-Ribecourt", "value": "C29165"},
                     {"label": "Camiers", "value": "C29166"},
                     {"label": "Camon", "value": "C29167"},
                     {"label": "Campagne-les-Hesdin", "value": "C29168"},
                     {"label": "Camphin-en-Carembault", "value": "C29169"},
                     {"label": "Camphin-en-Pevele", "value": "C29170"},
                     {"label": "Cantin", "value": "C29171"},
                     {"label": "Capinghem", "value": "C29172"},
                     {"label": "Cappelle-en-Pevele", "value": "C29173"},
                     {"label": "Cappelle-la-Grande", "value": "C29174"},
                     {"label": "Carlepont", "value": "C29175"},
                     {"label": "Cartignies", "value": "C29176"},
                     {"label": "Carvin", "value": "C29177"},
                     {"label": "Cassel", "value": "C29178"},
                     {"label": "Catenoy", "value": "C29179"},
                     {"label": "Cauchy-a-la-Tour", "value": "C29180"},
                     {"label": "Caudry", "value": "C29181"},
                     {"label": "Cauffry", "value": "C29182"},
                     {"label": "Cauvigny", "value": "C29183"},
                     {"label": "Cayeux-sur-Mer", "value": "C29184"},
                     {"label": "Chamant", "value": "C29185"},
                     {"label": "Chambly", "value": "C29186"},
                     {"label": "Chantilly", "value": "C29187"},
                     {"label": "Charly-sur-Marne", "value": "C29188"},
                     {"label": "Charmes", "value": "C29189"},
                     {"label": "Chateau-Thierry", "value": "C29190"},
                     {"label": "Chaulnes", "value": "C29191"},
                     {"label": "Chaumont-en-Vexin", "value": "C29192"},
                     {"label": "Chauny", "value": "C29193"},
                     {"label": "Chepy", "value": "C29194"},
                     {"label": "Chereng", "value": "C29195"},
                     {"label": "Chevrieres", "value": "C29196"},
                     {"label": "Chezy-sur-Marne", "value": "C29197"},
                     {"label": "Chierry", "value": "C29198"},
                     {"label": "Chiry-Ourscamp", "value": "C29199"},
                     {"label": "Chocques", "value": "C29200"},
                     {"label": "Choisy-au-Bac", "value": "C29201"},
                     {"label": "Cinqueux", "value": "C29202"},
                     {"label": "Cires-les-Mello", "value": "C29203"},
                     {"label": "Clairoix", "value": "C29204"},
                     {"label": "Clary", "value": "C29205"},
                     {"label": "Clermont", "value": "C29206"},
                     {"label": "Coincy", "value": "C29207"},
                     {"label": "Colleret", "value": "C29208"},
                     {"label": "Comines", "value": "C29209"},
                     {"label": "Compiegne", "value": "C29210"},
                     {"label": "Conde-sur-lEscaut", "value": "C29211"},
                     {"label": "Condette", "value": "C29212"},
                     {"label": "Conty", "value": "C29213"},
                     {"label": "Coquelles", "value": "C29214"},
                     {"label": "Corbehem", "value": "C29215"},
                     {"label": "Corbie", "value": "C29216"},
                     {"label": "Coucy-le-Chateau-Auffrique", "value": "C29217"},
                     {"label": "Coudekerque-Branche", "value": "C29218"},
                     {"label": "Coudekerque-Village", "value": "C29219"},
                     {"label": "Coudun", "value": "C29220"},
                     {"label": "Coulogne", "value": "C29221"},
                     {"label": "Courcelles-les-Lens", "value": "C29222"},
                     {"label": "Courchelettes", "value": "C29223"},
                     {"label": "Courmelles", "value": "C29224"},
                     {"label": "Courrieres", "value": "C29225"},
                     {"label": "Cousolre", "value": "C29226"},
                     {"label": "Coutiches", "value": "C29227"},
                     {"label": "Couvron-et-Aumencourt", "value": "C29228"},
                     {"label": "Coye-la-Foret", "value": "C29229"},
                     {"label": "Crecy-en-Ponthieu", "value": "C29230"},
                     {"label": "Crecy-sur-Serre", "value": "C29231"},
                     {"label": "Creil", "value": "C29232"},
                     {"label": "Crepy", "value": "C29233"},
                     {"label": "Crepy-en-Valois", "value": "C29234"},
                     {"label": "Crespin", "value": "C29235"},
                     {"label": "Crevecoeur-le-Grand", "value": "C29236"},
                     {"label": "Crezancy", "value": "C29237"},
                     {"label": "Crisolles", "value": "C29238"},
                     {"label": "Croisilles", "value": "C29239"},
                     {"label": "Croix", "value": "C29240"},
                     {"label": "Crouy", "value": "C29241"},
                     {"label": "Crouy-en-Thelle", "value": "C29242"},
                     {"label": "Cucq", "value": "C29243"},
                     {"label": "Cuffies", "value": "C29244"},
                     {"label": "Cuinchy", "value": "C29245"},
                     {"label": "Cuincy", "value": "C29246"},
                     {"label": "Cuise-la-Motte", "value": "C29247"},
                     {"label": "Curgies", "value": "C29248"},
                     {"label": "Cysoing", "value": "C29249"},
                     {"label": "Dainville", "value": "C29250"},
                     {"label": "Dannes", "value": "C29251"},
                     {"label": "Dargnies", "value": "C29252"},
                     {"label": "Dechy", "value": "C29253"},
                     {"label": "Denain", "value": "C29254"},
                     {"label": "Departement de lAisne", "value": "C29255"},
                     {"label": "Departement de lOise", "value": "C29256"},
                     {"label": "Desvres", "value": "C29257"},
                     {"label": "Deulemont", "value": "C29258"},
                     {"label": "Divion", "value": "C29259"},
                     {"label": "Doingt", "value": "C29260"},
                     {"label": "Domart-en-Ponthieu", "value": "C29261"},
                     {"label": "Don", "value": "C29262"},
                     {"label": "Douai", "value": "C29263"},
                     {"label": "Douchy-les-Mines", "value": "C29264"},
                     {"label": "Doullens", "value": "C29265"},
                     {"label": "Dourges", "value": "C29266"},
                     {"label": "Douvrin", "value": "C29267"},
                     {"label": "Dreslincourt", "value": "C29268"},
                     {"label": "Dreuil-les-Amiens", "value": "C29269"},
                     {"label": "Drocourt", "value": "C29270"},
                     {"label": "Duisans", "value": "C29271"},
                     {"label": "Dunkerque", "value": "C29272"},
                     {"label": "Dury", "value": "C29273"},
                     {"label": "ecaillon", "value": "C29274"},
                     {"label": "ecourt-Saint-Quentin", "value": "C29275"},
                     {"label": "Ecques", "value": "C29276"},
                     {"label": "eleu-dit-Leauwette", "value": "C29277"},
                     {"label": "emerchicourt", "value": "C29278"},
                     {"label": "Emmerin", "value": "C29279"},
                     {"label": "Englefontaine", "value": "C29280"},
                     {"label": "Ennetieres-en-Weppes", "value": "C29281"},
                     {"label": "Ennevelin", "value": "C29282"},
                     {"label": "epehy", "value": "C29283"},
                     {"label": "eperlecques", "value": "C29284"},
                     {"label": "Eppeville", "value": "C29285"},
                     {"label": "equihen-Plage", "value": "C29286"},
                     {"label": "Ercuis", "value": "C29287"},
                     {"label": "Erquinghem-Lys", "value": "C29288"},
                     {"label": "Erre", "value": "C29289"},
                     {"label": "Escaudain", "value": "C29290"},
                     {"label": "Escaudoeuvres", "value": "C29291"},
                     {"label": "Escautpont", "value": "C29292"},
                     {"label": "Esches", "value": "C29293"},
                     {"label": "Esqueheries", "value": "C29294"},
                     {"label": "Esquelbecq", "value": "C29295"},
                     {"label": "Esquerdes", "value": "C29296"},
                     {"label": "Essars", "value": "C29297"},
                     {"label": "Essigny-le-Grand", "value": "C29298"},
                     {"label": "Essomes-sur-Marne", "value": "C29299"},
                     {"label": "Estaires", "value": "C29300"},
                     {"label": "Estevelles", "value": "C29301"},
                     {"label": "Estrees", "value": "C29302"},
                     {"label": "Estrees-Saint-Denis", "value": "C29303"},
                     {"label": "etampes-sur-Marne", "value": "C29304"},
                     {"label": "etaples", "value": "C29305"},
                     {"label": "etreillers", "value": "C29306"},
                     {"label": "etreux", "value": "C29307"},
                     {"label": "evin-Malmaison", "value": "C29308"},
                     {"label": "Faches-Thumesnil", "value": "C29309"},
                     {"label": "Famars", "value": "C29310"},
                     {"label": "Fampoux", "value": "C29311"},
                     {"label": "Faumont", "value": "C29312"},
                     {"label": "Fechain", "value": "C29313"},
                     {"label": "Feignies", "value": "C29314"},
                     {"label": "Fenain", "value": "C29315"},
                     {"label": "Fere-en-Tardenois", "value": "C29316"},
                     {"label": "Ferin", "value": "C29317"},
                     {"label": "Ferques", "value": "C29318"},
                     {"label": "Ferriere-la-Grande", "value": "C29319"},
                     {"label": "Festubert", "value": "C29320"},
                     {"label": "Feuchy", "value": "C29321"},
                     {"label": "Feuquieres", "value": "C29322"},
                     {"label": "Feuquieres-en-Vimeu", "value": "C29323"},
                     {"label": "Fitz-James", "value": "C29324"},
                     {"label": "Flavy-le-Martel", "value": "C29325"},
                     {"label": "Flers-en-Escrebieux", "value": "C29326"},
                     {"label": "Flesselles", "value": "C29327"},
                     {"label": "Fleurbaix", "value": "C29328"},
                     {"label": "Fleurines", "value": "C29329"},
                     {"label": "Flines-les-Mortagne", "value": "C29330"},
                     {"label": "Flines-lez-Raches", "value": "C29331"},
                     {"label": "Flixecourt", "value": "C29332"},
                     {"label": "Folembray", "value": "C29333"},
                     {"label": "Fontaine-au-Pire", "value": "C29334"},
                     {"label": "Fontaine-Notre-Dame", "value": "C29335"},
                     {"label": "Forest-sur-Marque", "value": "C29336"},
                     {"label": "Formerie", "value": "C29337"},
                     {"label": "Fort-Mahon-Plage", "value": "C29338"},
                     {"label": "Fort-Mardyck", "value": "C29339"},
                     {"label": "Fouquereuil", "value": "C29340"},
                     {"label": "Fouquieres-les-Bethune", "value": "C29341"},
                     {"label": "Fouquieres-les-Lens", "value": "C29342"},
                     {"label": "Fourmies", "value": "C29343"},
                     {"label": "Fournes-en-Weppes", "value": "C29344"},
                     {"label": "Frelinghien", "value": "C29345"},
                     {"label": "Fresnes-sur-Escaut", "value": "C29346"},
                     {"label": "Fresnoy-le-Grand", "value": "C29347"},
                     {"label": "Fressenneville", "value": "C29348"},
                     {"label": "Frethun", "value": "C29349"},
                     {"label": "Fretin", "value": "C29350"},
                     {"label": "Frevent", "value": "C29351"},
                     {"label": "Friville-Escarbotin", "value": "C29352"},
                     {"label": "Fruges", "value": "C29353"},
                     {"label": "Gamaches", "value": "C29354"},
                     {"label": "Gauchy", "value": "C29355"},
                     {"label": "Genech", "value": "C29356"},
                     {"label": "Ghyvelde", "value": "C29357"},
                     {"label": "Givenchy-en-Gohelle", "value": "C29358"},
                     {"label": "Godewaersvelde", "value": "C29359"},
                     {"label": "Goeulzin", "value": "C29360"},
                     {"label": "Goincourt", "value": "C29361"},
                     {"label": "Gommegnies", "value": "C29362"},
                     {"label": "Gondecourt", "value": "C29363"},
                     {"label": "Gonnehem", "value": "C29364"},
                     {"label": "Gosnay", "value": "C29365"},
                     {"label": "Gouvieux", "value": "C29366"},
                     {"label": "Gouy-sous-Bellonne", "value": "C29367"},
                     {"label": "Gouzeaucourt", "value": "C29368"},
                     {"label": "Grand-Fort-Philippe", "value": "C29369"},
                     {"label": "Grande-Synthe", "value": "C29370"},
                     {"label": "Grandfresnoy", "value": "C29371"},
                     {"label": "Grandvilliers", "value": "C29372"},
                     {"label": "Gravelines", "value": "C29373"},
                     {"label": "Grenay", "value": "C29374"},
                     {"label": "Groffliers", "value": "C29375"},
                     {"label": "Gruson", "value": "C29376"},
                     {"label": "Guarbecque", "value": "C29377"},
                     {"label": "Guesnain", "value": "C29378"},
                     {"label": "Guignicourt", "value": "C29379"},
                     {"label": "Guines", "value": "C29380"},
                     {"label": "Guiscard", "value": "C29381"},
                     {"label": "Guise", "value": "C29382"},
                     {"label": "Haillicourt", "value": "C29383"},
                     {"label": "Haisnes", "value": "C29384"},
                     {"label": "Hallencourt", "value": "C29385"},
                     {"label": "Hallennes-lez-Haubourdin", "value": "C29386"},
                     {"label": "Hallines", "value": "C29387"},
                     {"label": "Halluin", "value": "C29388"},
                     {"label": "Ham", "value": "C29389"},
                     {"label": "Hames-Boucres", "value": "C29390"},
                     {"label": "Harbonnieres", "value": "C29391"},
                     {"label": "Hardinghen", "value": "C29392"},
                     {"label": "Harly", "value": "C29393"},
                     {"label": "Harnes", "value": "C29394"},
                     {"label": "Hasnon", "value": "C29395"},
                     {"label": "Haspres", "value": "C29396"},
                     {"label": "Haubourdin", "value": "C29397"},
                     {"label": "Haulchin", "value": "C29398"},
                     {"label": "Haussy", "value": "C29399"},
                     {"label": "Hautmont", "value": "C29400"},
                     {"label": "Haveluy", "value": "C29401"},
                     {"label": "Haverskerque", "value": "C29402"},
                     {"label": "Hazebrouck", "value": "C29403"},
                     {"label": "Helesmes", "value": "C29404"},
                     {"label": "Helfaut", "value": "C29405"},
                     {"label": "Hem", "value": "C29406"},
                     {"label": "Henin-Beaumont", "value": "C29407"},
                     {"label": "Hergnies", "value": "C29408"},
                     {"label": "Herin", "value": "C29409"},
                     {"label": "Herlies", "value": "C29410"},
                     {"label": "Hermes", "value": "C29411"},
                     {"label": "Hermies", "value": "C29412"},
                     {"label": "Hersin-Coupigny", "value": "C29413"},
                     {"label": "Herzeele", "value": "C29414"},
                     {"label": "Hesdin", "value": "C29415"},
                     {"label": "Heuringhem", "value": "C29416"},
                     {"label": "Hinges", "value": "C29417"},
                     {"label": "Hirson", "value": "C29418"},
                     {"label": "Holnon", "value": "C29419"},
                     {"label": "Hombleux", "value": "C29420"},
                     {"label": "Homblieres", "value": "C29421"},
                     {"label": "Hondschoote", "value": "C29422"},
                     {"label": "Hordain", "value": "C29423"},
                     {"label": "Hornaing", "value": "C29424"},
                     {"label": "Hornoy-le-Bourg", "value": "C29425"},
                     {"label": "Houdain", "value": "C29426"},
                     {"label": "Houplin-Ancoisne", "value": "C29427"},
                     {"label": "Houplines", "value": "C29428"},
                     {"label": "Hoymille", "value": "C29429"},
                     {"label": "Hulluch", "value": "C29430"},
                     {"label": "Illies", "value": "C29431"},
                     {"label": "Isbergues", "value": "C29432"},
                     {"label": "Isques", "value": "C29433"},
                     {"label": "Itancourt", "value": "C29434"},
                     {"label": "Iwuy", "value": "C29435"},
                     {"label": "Jaux", "value": "C29436"},
                     {"label": "Jenlain", "value": "C29437"},
                     {"label": "Jeumont", "value": "C29438"},
                     {"label": "Jussy", "value": "C29439"},
                     {"label": "Killem", "value": "C29440"},
                     {"label": "La Bassee", "value": "C29441"},
                     {"label": "La Capelle", "value": "C29442"},
                     {"label": "La Capelle-les-Boulogne", "value": "C29443"},
                     {"label": "La Chapelle-en-Serval", "value": "C29444"},
                     {"label": "La Couture", "value": "C29445"},
                     {"label": "La Fere", "value": "C29446"},
                     {"label": "La Ferte-Milon", "value": "C29447"},
                     {"label": "La Flamengrie", "value": "C29448"},
                     {"label": "La Gorgue", "value": "C29449"},
                     {"label": "La Longueville", "value": "C29450"},
                     {"label": "La Madeleine", "value": "C29451"},
                     {"label": "La Sentinelle", "value": "C29452"},
                     {"label": "Labeuvriere", "value": "C29453"},
                     {"label": "Laboissiere-en-Thelle", "value": "C29454"},
                     {"label": "Labourse", "value": "C29455"},
                     {"label": "Lachapelle-aux-Pots", "value": "C29456"},
                     {"label": "Lacroix-Saint-Ouen", "value": "C29457"},
                     {"label": "Lagny-le-Sec", "value": "C29458"},
                     {"label": "Laigneville", "value": "C29459"},
                     {"label": "Lallaing", "value": "C29460"},
                     {"label": "Lambersart", "value": "C29461"},
                     {"label": "Lambres-lez-Douai", "value": "C29462"},
                     {"label": "Lamorlaye", "value": "C29463"},
                     {"label": "Landas", "value": "C29464"},
                     {"label": "Landrecies", "value": "C29465"},
                     {"label": "Lannoy", "value": "C29466"},
                     {"label": "Laon", "value": "C29467"},
                     {"label": "Lapugnoy", "value": "C29468"},
                     {"label": "Lassigny", "value": "C29469"},
                     {"label": "Lauwin-Planque", "value": "C29470"},
                     {"label": "Laventie", "value": "C29471"},
                     {"label": "Le Crotoy", "value": "C29472"},
                     {"label": "Le Doulieu", "value": "C29473"},
                     {"label": "Le Mesnil-en-Thelle", "value": "C29474"},
                     {"label": "Le Meux", "value": "C29475"},
                     {"label": "Le Nouvion-en-Thierache", "value": "C29476"},
                     {"label": "Le Plessis-Belleville", "value": "C29477"},
                     {"label": "Le Plessis-Brion", "value": "C29478"},
                     {"label": "Le Portel", "value": "C29479"},
                     {"label": "Le Quesnoy", "value": "C29480"},
                     {"label": "Le Touquet-Paris-Plage", "value": "C29481"},
                     {"label": "Lecelles", "value": "C29482"},
                     {"label": "Lecluse", "value": "C29483"},
                     {"label": "Leers", "value": "C29484"},
                     {"label": "Leffrinckoucke", "value": "C29485"},
                     {"label": "Leforest", "value": "C29486"},
                     {"label": "Lens", "value": "C29487"},
                     {"label": "Les Ageux", "value": "C29488"},
                     {"label": "Les Attaques", "value": "C29489"},
                     {"label": "Lesquin", "value": "C29490"},
                     {"label": "Lestrem", "value": "C29491"},
                     {"label": "Leval", "value": "C29492"},
                     {"label": "Lewarde", "value": "C29493"},
                     {"label": "Lezennes", "value": "C29494"},
                     {"label": "Liancourt", "value": "C29495"},
                     {"label": "Libercourt", "value": "C29496"},
                     {"label": "Licques", "value": "C29497"},
                     {"label": "Liesse-Notre-Dame", "value": "C29498"},
                     {"label": "Lieu-Saint-Amand", "value": "C29499"},
                     {"label": "Lievin", "value": "C29500"},
                     {"label": "Ligny-en-Cambresis", "value": "C29501"},
                     {"label": "Lille", "value": "C29502"},
                     {"label": "Lillers", "value": "C29503"},
                     {"label": "Linselles", "value": "C29504"},
                     {"label": "Locon", "value": "C29505"},
                     {"label": "Loison-sous-Lens", "value": "C29506"},
                     {"label": "Lomme", "value": "C29507"},
                     {"label": "Lompret", "value": "C29508"},
                     {"label": "Longfosse", "value": "C29509"},
                     {"label": "Longpre-les-Corps-Saints", "value": "C29510"},
                     {"label": "Longueau", "value": "C29511"},
                     {"label": "Longueil-Annel", "value": "C29512"},
                     {"label": "Longueil-Sainte-Marie", "value": "C29513"},
                     {"label": "Longuenesse", "value": "C29514"},
                     {"label": "Looberghe", "value": "C29515"},
                     {"label": "Loon-Plage", "value": "C29516"},
                     {"label": "Loos", "value": "C29517"},
                     {"label": "Loos-en-Gohelle", "value": "C29518"},
                     {"label": "Lorgies", "value": "C29519"},
                     {"label": "Lormaison", "value": "C29520"},
                     {"label": "Lourches", "value": "C29521"},
                     {"label": "Louvroil", "value": "C29522"},
                     {"label": "Lozinghem", "value": "C29523"},
                     {"label": "Lumbres", "value": "C29524"},
                     {"label": "Lys-lez-Lannoy", "value": "C29525"},
                     {"label": "Maignelay-Montigny", "value": "C29526"},
                     {"label": "Maing", "value": "C29527"},
                     {"label": "Maisnil-les-Ruitz", "value": "C29528"},
                     {"label": "Mametz", "value": "C29529"},
                     {"label": "Marchiennes", "value": "C29530"},
                     {"label": "Marck", "value": "C29531"},
                     {"label": "Marcoing", "value": "C29532"},
                     {"label": "Marconne", "value": "C29533"},
                     {"label": "Marconnelle", "value": "C29534"},
                     {"label": "Marcq-en-Baroeul", "value": "C29535"},
                     {"label": "Maretz", "value": "C29536"},
                     {"label": "Mareuil-sur-Ourcq", "value": "C29537"},
                     {"label": "Margny-les-Compiegne", "value": "C29538"},
                     {"label": "Marle", "value": "C29539"},
                     {"label": "Marles-les-Mines", "value": "C29540"},
                     {"label": "Marly", "value": "C29541"},
                     {"label": "Maroeuil", "value": "C29542"},
                     {"label": "Maroilles", "value": "C29543"},
                     {"label": "Marpent", "value": "C29544"},
                     {"label": "Marquette-en-Ostrevant", "value": "C29545"},
                     {"label": "Marquette-lez-Lille", "value": "C29546"},
                     {"label": "Marquillies", "value": "C29547"},
                     {"label": "Marquion", "value": "C29548"},
                     {"label": "Marquise", "value": "C29549"},
                     {"label": "Masnieres", "value": "C29550"},
                     {"label": "Masny", "value": "C29551"},
                     {"label": "Maubeuge", "value": "C29552"},
                     {"label": "Mazingarbe", "value": "C29553"},
                     {"label": "Meaulte", "value": "C29554"},
                     {"label": "Mericourt", "value": "C29555"},
                     {"label": "Merignies", "value": "C29556"},
                     {"label": "Merlimont", "value": "C29557"},
                     {"label": "Mers-les-Bains", "value": "C29558"},
                     {"label": "Meru", "value": "C29559"},
                     {"label": "Merville", "value": "C29560"},
                     {"label": "Meteren", "value": "C29561"},
                     {"label": "Meurchin", "value": "C29562"},
                     {"label": "Milly-sur-Therain", "value": "C29563"},
                     {"label": "Mogneville", "value": "C29564"},
                     {"label": "Moislains", "value": "C29565"},
                     {"label": "Monchecourt", "value": "C29566"},
                     {"label": "Monchy-Saint-eloi", "value": "C29567"},
                     {"label": "Mons-en-Baroeul", "value": "C29568"},
                     {"label": "Mons-en-Pevele", "value": "C29569"},
                     {"label": "Mont-Bernanchon", "value": "C29570"},
                     {"label": "Mont-Saint-eloi", "value": "C29571"},
                     {"label": "Montataire", "value": "C29572"},
                     {"label": "Montcornet", "value": "C29573"},
                     {"label": "Montdidier", "value": "C29574"},
                     {"label": "Montescourt-Lizerolles", "value": "C29575"},
                     {"label": "Montigny-en-Gohelle", "value": "C29576"},
                     {"label": "Montigny-en-Ostrevent", "value": "C29577"},
                     {"label": "Montmacq", "value": "C29578"},
                     {"label": "Montreuil", "value": "C29579"},
                     {"label": "Montreuil-aux-Lions", "value": "C29580"},
                     {"label": "Morbecque", "value": "C29581"},
                     {"label": "Moreuil", "value": "C29582"},
                     {"label": "Morienval", "value": "C29583"},
                     {"label": "Mortagne-du-Nord", "value": "C29584"},
                     {"label": "Mouchin", "value": "C29585"},
                     {"label": "Mouvaux", "value": "C29586"},
                     {"label": "Mouy", "value": "C29587"},
                     {"label": "Nanteuil-le-Haudouin", "value": "C29588"},
                     {"label": "Naours", "value": "C29589"},
                     {"label": "Nesle", "value": "C29590"},
                     {"label": "Nesles", "value": "C29591"},
                     {"label": "Nesles-la-Montagne", "value": "C29592"},
                     {"label": "Neuf-Berquin", "value": "C29593"},
                     {"label": "Neufchatel-Hardelot", "value": "C29594"},
                     {"label": "Neuilly-en-Thelle", "value": "C29595"},
                     {"label": "Neuilly-Saint-Front", "value": "C29596"},
                     {"label": "Neuilly-sous-Clermont", "value": "C29597"},
                     {"label": "Neuville-en-Ferrain", "value": "C29598"},
                     {"label": "Neuville-Saint-Remy", "value": "C29599"},
                     {"label": "Neuville-Saint-Vaast", "value": "C29600"},
                     {"label": "Neuville-sur-Escaut", "value": "C29601"},
                     {"label": "Nieppe", "value": "C29602"},
                     {"label": "Noailles", "value": "C29603"},
                     {"label": "Noeux-les-Mines", "value": "C29604"},
                     {"label": "Nogent-lArtaud", "value": "C29605"},
                     {"label": "Nogent-sur-Oise", "value": "C29606"},
                     {"label": "Nogentel", "value": "C29607"},
                     {"label": "Nomain", "value": "C29608"},
                     {"label": "Nord", "value": "C29609"},
                     {"label": "Norrent-Fontes", "value": "C29610"},
                     {"label": "Nortkerque", "value": "C29611"},
                     {"label": "Nouvion", "value": "C29612"},
                     {"label": "Noyelles-Godault", "value": "C29613"},
                     {"label": "Noyelles-les-Vermelles", "value": "C29614"},
                     {"label": "Noyelles-sous-Lens", "value": "C29615"},
                     {"label": "Noyon", "value": "C29616"},
                     {"label": "Ognes", "value": "C29617"},
                     {"label": "Oignies", "value": "C29618"},
                     {"label": "Oisemont", "value": "C29619"},
                     {"label": "Oisy-le-Verger", "value": "C29620"},
                     {"label": "Omissy", "value": "C29621"},
                     {"label": "Onnaing", "value": "C29622"},
                     {"label": "Ons-en-Bray", "value": "C29623"},
                     {"label": "Orchies", "value": "C29624"},
                     {"label": "Origny-en-Thierache", "value": "C29625"},
                     {"label": "Origny-Sainte-Benoite", "value": "C29626"},
                     {"label": "Orry-la-Ville", "value": "C29627"},
                     {"label": "Ostricourt", "value": "C29628"},
                     {"label": "Outreau", "value": "C29629"},
                     {"label": "Oye-Plage", "value": "C29630"},
                     {"label": "Pas-de-Calais", "value": "C29631"},
                     {"label": "Pasly", "value": "C29632"},
                     {"label": "Pecquencourt", "value": "C29633"},
                     {"label": "Pende", "value": "C29634"},
                     {"label": "Perenchies", "value": "C29635"},
                     {"label": "Pernes", "value": "C29636"},
                     {"label": "Peronne", "value": "C29637"},
                     {"label": "Peroy-les-Gombries", "value": "C29638"},
                     {"label": "Petite-Foret", "value": "C29639"},
                     {"label": "Phalempin", "value": "C29640"},
                     {"label": "Picquigny", "value": "C29641"},
                     {"label": "Pierrefonds", "value": "C29642"},
                     {"label": "Pinon", "value": "C29643"},
                     {"label": "Plailly", "value": "C29644"},
                     {"label": "Poix-de-Picardie", "value": "C29645"},
                     {"label": "Poix-du-Nord", "value": "C29646"},
                     {"label": "Ponchon", "value": "C29647"},
                     {"label": "Pont-a-Marcq", "value": "C29648"},
                     {"label": "Pont-a-Vendin", "value": "C29649"},
                     {"label": "Pont-de-Metz", "value": "C29650"},
                     {"label": "Pont-Remy", "value": "C29651"},
                     {"label": "Pont-Sainte-Maxence", "value": "C29652"},
                     {"label": "Pont-sur-Sambre", "value": "C29653"},
                     {"label": "Pontpoint", "value": "C29654"},
                     {"label": "Poulainville", "value": "C29655"},
                     {"label": "Precy-sur-Oise", "value": "C29656"},
                     {"label": "Premesques", "value": "C29657"},
                     {"label": "Preseau", "value": "C29658"},
                     {"label": "Prouvy", "value": "C29659"},
                     {"label": "Proville", "value": "C29660"},
                     {"label": "Provin", "value": "C29661"},
                     {"label": "Quaedypre", "value": "C29662"},
                     {"label": "Quarouble", "value": "C29663"},
                     {"label": "Quend", "value": "C29664"},
                     {"label": "Quesnoy-sur-Deule", "value": "C29665"},
                     {"label": "Quevauvillers", "value": "C29666"},
                     {"label": "Quievrechain", "value": "C29667"},
                     {"label": "Quievy", "value": "C29668"},
                     {"label": "Raches", "value": "C29669"},
                     {"label": "Racquinghem", "value": "C29670"},
                     {"label": "Radinghem-en-Weppes", "value": "C29671"},
                     {"label": "Raillencourt-Sainte-Olle", "value": "C29672"},
                     {"label": "Raimbeaucourt", "value": "C29673"},
                     {"label": "Raismes", "value": "C29674"},
                     {"label": "Ranchicourt", "value": "C29675"},
                     {"label": "Rang-du-Fliers", "value": "C29676"},
                     {"label": "Rantigny", "value": "C29677"},
                     {"label": "Ravenel", "value": "C29678"},
                     {"label": "Recquignies", "value": "C29679"},
                     {"label": "Remy", "value": "C29680"},
                     {"label": "Renescure", "value": "C29681"},
                     {"label": "Ressons-sur-Matz", "value": "C29682"},
                     {"label": "Rety", "value": "C29683"},
                     {"label": "Rexpoede", "value": "C29684"},
                     {"label": "Ribemont", "value": "C29685"},
                     {"label": "Richebourg", "value": "C29686"},
                     {"label": "Richebourg-lAvoue", "value": "C29687"},
                     {"label": "Rieulay", "value": "C29688"},
                     {"label": "Rieux", "value": "C29689"},
                     {"label": "Rieux-en-Cambresis", "value": "C29690"},
                     {"label": "Rinxent", "value": "C29691"},
                     {"label": "Rivery", "value": "C29692"},
                     {"label": "Riviere", "value": "C29693"},
                     {"label": "Robecq", "value": "C29694"},
                     {"label": "Roeulx", "value": "C29695"},
                     {"label": "Roeux", "value": "C29696"},
                     {"label": "Roisel", "value": "C29697"},
                     {"label": "Ronchin", "value": "C29698"},
                     {"label": "Roncq", "value": "C29699"},
                     {"label": "Roost-Warendin", "value": "C29700"},
                     {"label": "Roquetoire", "value": "C29701"},
                     {"label": "Rosieres-en-Santerre", "value": "C29702"},
                     {"label": "Rosult", "value": "C29703"},
                     {"label": "Roubaix", "value": "C29704"},
                     {"label": "Rousies", "value": "C29705"},
                     {"label": "Rouvroy", "value": "C29706"},
                     {"label": "Roye", "value": "C29707"},
                     {"label": "Rozoy-sur-Serre", "value": "C29708"},
                     {"label": "Rue", "value": "C29709"},
                     {"label": "Ruitz", "value": "C29710"},
                     {"label": "Rumegies", "value": "C29711"},
                     {"label": "Rumilly-en-Cambresis", "value": "C29712"},
                     {"label": "Ruminghem", "value": "C29713"},
                     {"label": "Sacy-le-Grand", "value": "C29714"},
                     {"label": "Sailly-Flibeaucourt", "value": "C29715"},
                     {"label": "Sailly-Labourse", "value": "C29716"},
                     {"label": "Sailly-lez-Lannoy", "value": "C29717"},
                     {"label": "Sailly-sur-la-Lys", "value": "C29718"},
                     {"label": "Sainghin-en-Melantois", "value": "C29719"},
                     {"label": "Sainghin-en-Weppes", "value": "C29720"},
                     {"label": "Sains-du-Nord", "value": "C29721"},
                     {"label": "Sains-en-Amienois", "value": "C29722"},
                     {"label": "Sains-en-Gohelle", "value": "C29723"},
                     {"label": "Saint-Amand-les-Eaux", "value": "C29724"},
                     {"label": "Saint-Andre-lez-Lille", "value": "C29725"},
                     {"label": "Saint-Aubert", "value": "C29726"},
                     {"label": "Saint-Aubin-en-Bray", "value": "C29727"},
                     {"label": "Saint-Crepin-Ibouvillers", "value": "C29728"},
                     {"label": "Saint-Erme-Outre-et-Ramecourt",
                      "value": "C29729"},
                     {"label": "Saint-etienne-au-Mont", "value": "C29730"},
                     {"label": "Saint-Folquin", "value": "C29731"},
                     {"label": "Saint-Germer-de-Fly", "value": "C29732"},
                     {"label": "Saint-Gobain", "value": "C29733"},
                     {"label": "Saint-Hilaire-lez-Cambrai", "value": "C29734"},
                     {"label": "Saint-Jans-Cappel", "value": "C29735"},
                     {"label": "Saint-Josse", "value": "C29736"},
                     {"label": "Saint-Just-en-Chaussee", "value": "C29737"},
                     {"label": "Saint-Laurent-Blangy", "value": "C29738"},
                     {"label": "Saint-Leger-les-Domart", "value": "C29739"},
                     {"label": "Saint-Leonard", "value": "C29740"},
                     {"label": "Saint-Martin-au-Laert", "value": "C29741"},
                     {"label": "Saint-Martin-Boulogne", "value": "C29742"},
                     {"label": "Saint-Martin-Longueau", "value": "C29743"},
                     {"label": "Saint-Maximin", "value": "C29744"},
                     {"label": "Saint-Michel", "value": "C29745"},
                     {"label": "Saint-Nicolas", "value": "C29746"},
                     {"label": "Saint-Omer", "value": "C29747"},
                     {"label": "Saint-Omer-en-Chaussee", "value": "C29748"},
                     {"label": "Saint-Ouen", "value": "C29749"},
                     {"label": "Saint-Paul", "value": "C29750"},
                     {"label": "Saint-Pol-sur-Mer", "value": "C29751"},
                     {"label": "Saint-Pol-sur-Ternoise", "value": "C29752"},
                     {"label": "Saint-Python", "value": "C29753"},
                     {"label": "Saint-Quentin", "value": "C29754"},
                     {"label": "Saint-Riquier", "value": "C29755"},
                     {"label": "Saint-Saulve", "value": "C29756"},
                     {"label": "Saint-Sauveur", "value": "C29757"},
                     {"label": "Saint-Souplet", "value": "C29758"},
                     {"label": "Saint-Sulpice", "value": "C29759"},
                     {"label": "Saint-Sylvestre-Cappel", "value": "C29760"},
                     {"label": "Saint-Valery-sur-Somme", "value": "C29761"},
                     {"label": "Saint-Venant", "value": "C29762"},
                     {"label": "Sainte-Catherine", "value": "C29763"},
                     {"label": "Sainte-Genevieve", "value": "C29764"},
                     {"label": "Sainte-Marie-Kerque", "value": "C29765"},
                     {"label": "Saleux", "value": "C29766"},
                     {"label": "Sallaumines", "value": "C29767"},
                     {"label": "Salome", "value": "C29768"},
                     {"label": "Salouel", "value": "C29769"},
                     {"label": "Sameon", "value": "C29770"},
                     {"label": "Samer", "value": "C29771"},
                     {"label": "Sangatte", "value": "C29772"},
                     {"label": "Santes", "value": "C29773"},
                     {"label": "Sars-Poteries", "value": "C29774"},
                     {"label": "Saultain", "value": "C29775"},
                     {"label": "Saulzoir", "value": "C29776"},
                     {"label": "Savy-Berlette", "value": "C29777"},
                     {"label": "Seboncourt", "value": "C29778"},
                     {"label": "Sebourg", "value": "C29779"},
                     {"label": "Seclin", "value": "C29780"},
                     {"label": "Selvigny", "value": "C29781"},
                     {"label": "Senlis", "value": "C29782"},
                     {"label": "Sequedin", "value": "C29783"},
                     {"label": "Serifontaine", "value": "C29784"},
                     {"label": "Serques", "value": "C29785"},
                     {"label": "Silly-le-Long", "value": "C29786"},
                     {"label": "Sin-le-Noble", "value": "C29787"},
                     {"label": "Sinceny", "value": "C29788"},
                     {"label": "Sissonne", "value": "C29789"},
                     {"label": "Socx", "value": "C29790"},
                     {"label": "Soissons", "value": "C29791"},
                     {"label": "Solesmes", "value": "C29792"},
                     {"label": "Solre-le-Chateau", "value": "C29793"},
                     {"label": "Somain", "value": "C29794"},
                     {"label": "Somme", "value": "C29795"},
                     {"label": "Songeons", "value": "C29796"},
                     {"label": "Souchez", "value": "C29797"},
                     {"label": "Spycker", "value": "C29798"},
                     {"label": "Steenbecque", "value": "C29799"},
                     {"label": "Steene", "value": "C29800"},
                     {"label": "Steenvoorde", "value": "C29801"},
                     {"label": "Steenwerck", "value": "C29802"},
                     {"label": "Talmas", "value": "C29803"},
                     {"label": "Tatinghem", "value": "C29804"},
                     {"label": "Templemars", "value": "C29805"},
                     {"label": "Templeuve-en-Pevele", "value": "C29806"},
                     {"label": "Tergnier", "value": "C29807"},
                     {"label": "Teteghem", "value": "C29808"},
                     {"label": "Thelus", "value": "C29809"},
                     {"label": "Therouanne", "value": "C29810"},
                     {"label": "Thiant", "value": "C29811"},
                     {"label": "Thiers-sur-Theve", "value": "C29812"},
                     {"label": "Thiverny", "value": "C29813"},
                     {"label": "Thourotte", "value": "C29814"},
                     {"label": "Thumeries", "value": "C29815"},
                     {"label": "Thun-Saint-Amand", "value": "C29816"},
                     {"label": "Tille", "value": "C29817"},
                     {"label": "Tilloy-les-Mofflaines", "value": "C29818"},
                     {"label": "Tilques", "value": "C29819"},
                     {"label": "Toufflers", "value": "C29820"},
                     {"label": "Tourcoing", "value": "C29821"},
                     {"label": "Tournehem-sur-la-Hem", "value": "C29822"},
                     {"label": "Tracy-le-Mont", "value": "C29823"},
                     {"label": "Trelon", "value": "C29824"},
                     {"label": "Tressin", "value": "C29825"},
                     {"label": "Tricot", "value": "C29826"},
                     {"label": "Trie-Chateau", "value": "C29827"},
                     {"label": "Trith-Saint-Leger", "value": "C29828"},
                     {"label": "Troissereux", "value": "C29829"},
                     {"label": "Trosly-Breuil", "value": "C29830"},
                     {"label": "Ully-Saint-Georges", "value": "C29831"},
                     {"label": "Uxem", "value": "C29832"},
                     {"label": "Vailly-sur-Aisne", "value": "C29833"},
                     {"label": "Valenciennes", "value": "C29834"},
                     {"label": "Vaulx-Vraucourt", "value": "C29835"},
                     {"label": "Vaumoise", "value": "C29836"},
                     {"label": "Vendegies-sur-ecaillon", "value": "C29837"},
                     {"label": "Vendeville", "value": "C29838"},
                     {"label": "Vendin-le-Vieil", "value": "C29839"},
                     {"label": "Vendin-les-Bethune", "value": "C29840"},
                     {"label": "Venette", "value": "C29841"},
                     {"label": "Venizel", "value": "C29842"},
                     {"label": "Ver-sur-Launette", "value": "C29843"},
                     {"label": "Verberie", "value": "C29844"},
                     {"label": "Verlinghem", "value": "C29845"},
                     {"label": "Vermand", "value": "C29846"},
                     {"label": "Vermelles", "value": "C29847"},
                     {"label": "Verneuil-en-Halatte", "value": "C29848"},
                     {"label": "Verquin", "value": "C29849"},
                     {"label": "Verton", "value": "C29850"},
                     {"label": "Vervins", "value": "C29851"},
                     {"label": "Vic-sur-Aisne", "value": "C29852"},
                     {"label": "Vicq", "value": "C29853"},
                     {"label": "Vieille-eglise", "value": "C29854"},
                     {"label": "Viesly", "value": "C29855"},
                     {"label": "Vieux-Berquin", "value": "C29856"},
                     {"label": "Vieux-Conde", "value": "C29857"},
                     {"label": "Vignacourt", "value": "C29858"},
                     {"label": "Villeneuve-dAscq", "value": "C29859"},
                     {"label": "Villeneuve-les-Sablons", "value": "C29860"},
                     {"label": "Villeneuve-Saint-Germain", "value": "C29861"},
                     {"label": "Villers-Bocage", "value": "C29862"},
                     {"label": "Villers-Bretonneux", "value": "C29863"},
                     {"label": "Villers-Cotterets", "value": "C29864"},
                     {"label": "Villers-en-Cauchies", "value": "C29865"},
                     {"label": "Villers-Outreaux", "value": "C29866"},
                     {"label": "Villers-Pol", "value": "C29867"},
                     {"label": "Villers-Saint-Paul", "value": "C29868"},
                     {"label": "Villers-sous-Saint-Leu", "value": "C29869"},
                     {"label": "Villers-sur-Coudun", "value": "C29870"},
                     {"label": "Vimy", "value": "C29871"},
                     {"label": "Vineuil-Saint-Firmin", "value": "C29872"},
                     {"label": "Violaines", "value": "C29873"},
                     {"label": "Viry-Noureuil", "value": "C29874"},
                     {"label": "Vitry-en-Artois", "value": "C29875"},
                     {"label": "Vred", "value": "C29876"},
                     {"label": "Wahagnies", "value": "C29877"},
                     {"label": "Wailly", "value": "C29878"},
                     {"label": "Walincourt-Selvigny", "value": "C29879"},
                     {"label": "Wallers", "value": "C29880"},
                     {"label": "Wambrechies", "value": "C29881"},
                     {"label": "Wandignies-Hamage", "value": "C29882"},
                     {"label": "Wardrecques", "value": "C29883"},
                     {"label": "Wargnies-le-Grand", "value": "C29884"},
                     {"label": "Warhem", "value": "C29885"},
                     {"label": "Warluis", "value": "C29886"},
                     {"label": "Wasquehal", "value": "C29887"},
                     {"label": "Wassigny", "value": "C29888"},
                     {"label": "Watten", "value": "C29889"},
                     {"label": "Wattignies", "value": "C29890"},
                     {"label": "Wattrelos", "value": "C29891"},
                     {"label": "Wavignies", "value": "C29892"},
                     {"label": "Wavrechain-sous-Denain", "value": "C29893"},
                     {"label": "Wavrin", "value": "C29894"},
                     {"label": "Waziers", "value": "C29895"},
                     {"label": "Wervicq-Sud", "value": "C29896"},
                     {"label": "Wignehies", "value": "C29897"},
                     {"label": "Willems", "value": "C29898"},
                     {"label": "Wimereux", "value": "C29899"},
                     {"label": "Wimille", "value": "C29900"},
                     {"label": "Wingles", "value": "C29901"},
                     {"label": "Winnezeele", "value": "C29902"},
                     {"label": "Wissant", "value": "C29903"},
                     {"label": "Wizernes", "value": "C29904"},
                     {"label": "Woincourt", "value": "C29905"},
                     {"label": "Wormhout", "value": "C29906"},
                     {"label": "Zegerscappel", "value": "C29907"},
                     {"label": "Zutkerque", "value": "C29908"},
                     {"label": "Zuydcoote", "value": "C29909"}],
 "Havana Province": [{"label": "Alamar", "value": "C21741"},
                     {"label": "Arroyo Naranjo", "value": "C21742"},
                     {"label": "Boyeros", "value": "C21743"},
                     {"label": "Centro Habana", "value": "C21744"},
                     {"label": "Cerro", "value": "C21745"},
                     {"label": "Diez de Octubre", "value": "C21746"},
                     {"label": "Guanabacoa", "value": "C21747"},
                     {"label": "Habana del Este", "value": "C21748"},
                     {"label": "Havana", "value": "C21749"},
                     {"label": "La Habana Vieja", "value": "C21750"},
                     {"label": "Regla", "value": "C21751"},
                     {"label": "San Miguel del Padron", "value": "C21752"},
                     {"label": "Santiago de las Vegas", "value": "C21753"}],
 "Hawaii": [{"label": "ahuimanu", "value": "C131033"},
            {"label": "Aiea", "value": "C131034"},
            {"label": "Eleele", "value": "C131035"},
            {"label": "Ewa Beach", "value": "C131036"},
            {"label": "Ewa Gentry", "value": "C131037"},
            {"label": "Ewa Villages", "value": "C131038"},
            {"label": "Omao", "value": "C131039"},
            {"label": "Ainaloa", "value": "C131040"},
            {"label": "Anahola", "value": "C131041"},
            {"label": "Captain Cook", "value": "C131042"},
            {"label": "East Honolulu", "value": "C131043"},
            {"label": "Fern Acres", "value": "C131044"},
            {"label": "Haiku-Pauwela", "value": "C131045"},
            {"label": "Halawa", "value": "C131046"},
            {"label": "Halawa Heights", "value": "C131047"},
            {"label": "Haleiwa", "value": "C131048"},
            {"label": "Hana", "value": "C131049"},
            {"label": "Hanamaulu", "value": "C131050"},
            {"label": "Hanapepe", "value": "C131051"},
            {"label": "Hanapepe Heights", "value": "C131052"},
            {"label": "Hauula", "value": "C131053"},
            {"label": "Hawaii County", "value": "C131054"},
            {"label": "Hawaiian Acres", "value": "C131055"},
            {"label": "Hawaiian Beaches", "value": "C131056"},
            {"label": "Hawaiian Ocean View", "value": "C131057"},
            {"label": "Hawaiian Paradise Park", "value": "C131058"},
            {"label": "Hawi", "value": "C131059"},
            {"label": "Heeia", "value": "C131060"},
            {"label": "Hickam Field", "value": "C131061"},
            {"label": "Hilo", "value": "C131062"},
            {"label": "Holualoa", "value": "C131063"},
            {"label": "Honalo", "value": "C131064"},
            {"label": "Honaunau-Napoopoo", "value": "C131065"},
            {"label": "Honokaa", "value": "C131066"},
            {"label": "Honolulu", "value": "C131067"},
            {"label": "Honolulu County", "value": "C131068"},
            {"label": "Iroquois Point", "value": "C131069"},
            {"label": "Kaaawa", "value": "C131070"},
            {"label": "Kaanapali", "value": "C131071"},
            {"label": "Kaanapali Landing", "value": "C131072"},
            {"label": "Kahaluu", "value": "C131073"},
            {"label": "Kahaluu-Keauhou", "value": "C131074"},
            {"label": "Kahuku", "value": "C131075"},
            {"label": "Kahului", "value": "C131076"},
            {"label": "Kailua", "value": "C131077"},
            {"label": "Kailua-Kona", "value": "C131078"},
            {"label": "Kalaheo", "value": "C131079"},
            {"label": "Kalaoa", "value": "C131080"},
            {"label": "Kalawao County", "value": "C131081"},
            {"label": "Kaneohe", "value": "C131082"},
            {"label": "Kapaa", "value": "C131083"},
            {"label": "Kapaau", "value": "C131084"},
            {"label": "Kapolei", "value": "C131085"},
            {"label": "Kauai County", "value": "C131086"},
            {"label": "Kaunakakai", "value": "C131087"},
            {"label": "Keaau", "value": "C131088"},
            {"label": "Kealakekua", "value": "C131089"},
            {"label": "Kekaha", "value": "C131090"},
            {"label": "Keokea", "value": "C131091"},
            {"label": "Kihei", "value": "C131092"},
            {"label": "Kilauea", "value": "C131093"},
            {"label": "Ko Olina", "value": "C131094"},
            {"label": "Koloa", "value": "C131095"},
            {"label": "Kualapuu", "value": "C131096"},
            {"label": "Kula", "value": "C131097"},
            {"label": "Kurtistown", "value": "C131098"},
            {"label": "Laie", "value": "C131099"},
            {"label": "Lahaina", "value": "C131100"},
            {"label": "Lanai City", "value": "C131101"},
            {"label": "Lawai", "value": "C131102"},
            {"label": "Leilani Estates", "value": "C131103"},
            {"label": "Lihue", "value": "C131104"},
            {"label": "Maili", "value": "C131105"},
            {"label": "Makaha", "value": "C131106"},
            {"label": "Makaha Valley", "value": "C131107"},
            {"label": "Makakilo City", "value": "C131108"},
            {"label": "Makawao", "value": "C131109"},
            {"label": "Marine Corps Base Hawaii - MCBH", "value": "C131110"},
            {"label": "Maui County", "value": "C131111"},
            {"label": "Maunawili", "value": "C131112"},
            {"label": "Mililani Town", "value": "C131113"},
            {"label": "Mokuleia", "value": "C131114"},
            {"label": "Mountain View", "value": "C131115"},
            {"label": "Nanakuli", "value": "C131116"},
            {"label": "Nanawale Estates", "value": "C131117"},
            {"label": "Napili-Honokowai", "value": "C131118"},
            {"label": "Ocean Pointe", "value": "C131119"},
            {"label": "Orchidlands Estates", "value": "C131120"},
            {"label": "Pahala", "value": "C131121"},
            {"label": "Paia", "value": "C131122"},
            {"label": "Papaikou", "value": "C131123"},
            {"label": "Pearl City", "value": "C131124"},
            {"label": "Pepeekeo", "value": "C131125"},
            {"label": "Princeville", "value": "C131126"},
            {"label": "Puhi", "value": "C131127"},
            {"label": "Pukalani", "value": "C131128"},
            {"label": "Punaluu", "value": "C131129"},
            {"label": "Pupukea", "value": "C131130"},
            {"label": "Royal Kunia", "value": "C131131"},
            {"label": "Schofield Barracks", "value": "C131132"},
            {"label": "Village Park", "value": "C131133"},
            {"label": "Volcano", "value": "C131134"},
            {"label": "Wahiawa", "value": "C131135"},
            {"label": "Waialua", "value": "C131136"},
            {"label": "Waianae", "value": "C131137"},
            {"label": "Waihee-Waiehu", "value": "C131138"},
            {"label": "Waikapu", "value": "C131139"},
            {"label": "Waikoloa", "value": "C131140"},
            {"label": "Wailea", "value": "C131141"},
            {"label": "Wailua", "value": "C131142"},
            {"label": "Wailua Homesteads", "value": "C131143"},
            {"label": "Wailuku", "value": "C131144"},
            {"label": "Waimalu", "value": "C131145"},
            {"label": "Waimanalo", "value": "C131146"},
            {"label": "Waimanalo Beach", "value": "C131147"},
            {"label": "Waimea", "value": "C131148"},
            {"label": "Wainaku", "value": "C131149"},
            {"label": "Waipahu", "value": "C131150"},
            {"label": "Waipio Acres", "value": "C131151"},
            {"label": "Waipio", "value": "C131152"},
            {"label": "Whitmore Village", "value": "C131153"}],
 "Hawalli Governorate": [{"label": "Ar Rumaythiyah", "value": "C64700"},
                         {"label": "As Salimiyah", "value": "C64701"},
                         {"label": "Bayan", "value": "C64702"},
                         {"label": "Hawalli", "value": "C64703"},
                         {"label": "Salwa", "value": "C64704"}],
 "Hawkes Bay Region": [{"label": "Hastings", "value": "C77786"},
                       {"label": "Napier", "value": "C77787"},
                       {"label": "Taradale", "value": "C77788"},
                       {"label": "Wairoa", "value": "C77789"}],
 "Hebei": [{"label": "Baoding", "value": "C18600"},
           {"label": "Beidaihehaibin", "value": "C18601"},
           {"label": "Botou", "value": "C18602"},
           {"label": "Cangzhou", "value": "C18603"},
           {"label": "Cangzhou Shi", "value": "C18604"},
           {"label": "Changli", "value": "C18605"},
           {"label": "Chengde", "value": "C18606"},
           {"label": "Chengde Prefecture", "value": "C18607"},
           {"label": "Dingzhou", "value": "C18608"},
           {"label": "Fengrun", "value": "C18609"},
           {"label": "Guye", "value": "C18610"},
           {"label": "Handan", "value": "C18611"},
           {"label": "Hecun", "value": "C18612"},
           {"label": "Hengshui", "value": "C18613"},
           {"label": "Langfang", "value": "C18614"},
           {"label": "Langfang Shi", "value": "C18615"},
           {"label": "Linshui", "value": "C18616"},
           {"label": "Linxi", "value": "C18617"},
           {"label": "Luancheng", "value": "C18618"},
           {"label": "Nangong", "value": "C18619"},
           {"label": "Pengcheng", "value": "C18620"},
           {"label": "Qinhuangdao", "value": "C18621"},
           {"label": "Renqiu", "value": "C18622"},
           {"label": "Shahecheng", "value": "C18623"},
           {"label": "Shanhaiguan", "value": "C18624"},
           {"label": "Shijiazhuang", "value": "C18625"},
           {"label": "Shijiazhuang Shi", "value": "C18626"},
           {"label": "Songling", "value": "C18627"},
           {"label": "Tangjiazhuang", "value": "C18628"},
           {"label": "Tangshan", "value": "C18629"},
           {"label": "Tangshan Shi", "value": "C18630"},
           {"label": "Tianchang", "value": "C18631"},
           {"label": "Xingtai", "value": "C18632"},
           {"label": "Xinji", "value": "C18633"},
           {"label": "Zhangjiakou", "value": "C18634"},
           {"label": "Zhangjiakou Shi", "value": "C18635"},
           {"label": "Zhangjiakou Shi Xuanhua Qu", "value": "C18636"},
           {"label": "Zhaogezhuang", "value": "C18637"},
           {"label": "Zunhua", "value": "C18638"}],
 "Hedmark": [{"label": "Alvdal", "value": "C78726"},
             {"label": "Amot", "value": "C78727"},
             {"label": "Asnes", "value": "C78728"},
             {"label": "Brumunddal", "value": "C78729"},
             {"label": "Eidskog", "value": "C78730"},
             {"label": "Elverum", "value": "C78731"},
             {"label": "Engerdal", "value": "C78732"},
             {"label": "Folldal", "value": "C78733"},
             {"label": "Grue", "value": "C78734"},
             {"label": "Hamar", "value": "C78735"},
             {"label": "Innbygda", "value": "C78736"},
             {"label": "Kirkenaer", "value": "C78737"},
             {"label": "Kongsvinger", "value": "C78738"},
             {"label": "Koppang", "value": "C78739"},
             {"label": "Loten", "value": "C78740"},
             {"label": "Moelv", "value": "C78741"},
             {"label": "Nord-Odal", "value": "C78742"},
             {"label": "Os", "value": "C78743"},
             {"label": "Rena", "value": "C78744"},
             {"label": "Rendalen", "value": "C78745"},
             {"label": "Ringsaker", "value": "C78746"},
             {"label": "Sand", "value": "C78747"},
             {"label": "Skarnes", "value": "C78748"},
             {"label": "Skotterud", "value": "C78749"},
             {"label": "Sor-Odal", "value": "C78750"},
             {"label": "Spetalen", "value": "C78751"},
             {"label": "Stange", "value": "C78752"},
             {"label": "Stor-Elvdal", "value": "C78753"},
             {"label": "Tolga", "value": "C78754"},
             {"label": "Trysil", "value": "C78755"},
             {"label": "Tynset", "value": "C78756"},
             {"label": "Valer", "value": "C78757"}],
 "Heilongjiang": [{"label": "Acheng", "value": "C18639"},
                  {"label": "Anda", "value": "C18640"},
                  {"label": "Baiquan", "value": "C18641"},
                  {"label": "Bamiantong", "value": "C18642"},
                  {"label": "Baoqing", "value": "C18643"},
                  {"label": "Baoshan", "value": "C18644"},
                  {"label": "Bayan", "value": "C18645"},
                  {"label": "Beian", "value": "C18646"},
                  {"label": "Binzhou", "value": "C18647"},
                  {"label": "Boli", "value": "C18648"},
                  {"label": "Chaihe", "value": "C18649"},
                  {"label": "Chengzihe", "value": "C18650"},
                  {"label": "Daqing", "value": "C18651"},
                  {"label": "Dongning", "value": "C18652"},
                  {"label": "Dongxing", "value": "C18653"},
                  {"label": "Fendou", "value": "C18654"},
                  {"label": "Fengxiang", "value": "C18655"},
                  {"label": "Fujin", "value": "C18656"},
                  {"label": "Fuli", "value": "C18657"},
                  {"label": "Fuyu", "value": "C18658"},
                  {"label": "Fuyuan", "value": "C18659"},
                  {"label": "Gannan", "value": "C18660"},
                  {"label": "Hailin", "value": "C18661"},
                  {"label": "Hailun", "value": "C18662"},
                  {"label": "Harbin", "value": "C18663"},
                  {"label": "Hegang", "value": "C18664"},
                  {"label": "Heihe", "value": "C18665"},
                  {"label": "Honggang", "value": "C18666"},
                  {"label": "Huanan", "value": "C18667"},
                  {"label": "Hulan", "value": "C18668"},
                  {"label": "Hulan Ergi", "value": "C18669"},
                  {"label": "Jiamusi", "value": "C18670"},
                  {"label": "Jidong", "value": "C18671"},
                  {"label": "Jixi", "value": "C18672"},
                  {"label": "Langxiang", "value": "C18673"},
                  {"label": "Lanxi", "value": "C18674"},
                  {"label": "Lianhe", "value": "C18675"},
                  {"label": "Lingdong", "value": "C18676"},
                  {"label": "Linkou", "value": "C18677"},
                  {"label": "Longfeng", "value": "C18678"},
                  {"label": "Longjiang", "value": "C18679"},
                  {"label": "Mingshui", "value": "C18680"},
                  {"label": "Mishan", "value": "C18681"},
                  {"label": "Mudanjiang", "value": "C18682"},
                  {"label": "Nehe", "value": "C18683"},
                  {"label": "Nenjiang", "value": "C18684"},
                  {"label": "Nianzishan", "value": "C18685"},
                  {"label": "Ningan", "value": "C18686"},
                  {"label": "Qinggang", "value": "C18687"},
                  {"label": "Qiqihar", "value": "C18688"},
                  {"label": "Shangzhi", "value": "C18689"},
                  {"label": "Shanhecun", "value": "C18690"},
                  {"label": "Shuangcheng", "value": "C18691"},
                  {"label": "Shuangyashan", "value": "C18692"},
                  {"label": "Suifenhe", "value": "C18693"},
                  {"label": "Suihua", "value": "C18694"},
                  {"label": "Suileng", "value": "C18695"},
                  {"label": "Tahe", "value": "C18696"},
                  {"label": "Taihecun", "value": "C18697"},
                  {"label": "Taikang", "value": "C18698"},
                  {"label": "Tailai", "value": "C18699"},
                  {"label": "Tieli", "value": "C18700"},
                  {"label": "Wangkui", "value": "C18701"},
                  {"label": "Wuchang", "value": "C18702"},
                  {"label": "Xinqing", "value": "C18703"},
                  {"label": "Yichun", "value": "C18704"},
                  {"label": "Yilan", "value": "C18705"},
                  {"label": "Youhao", "value": "C18706"},
                  {"label": "Zhaodong", "value": "C18707"},
                  {"label": "Zhaoyuan", "value": "C18708"},
                  {"label": "Zhaozhou", "value": "C18709"}],
 "Hela": [{"label": "Komo Margarima", "value": "C80422"},
          {"label": "Koroba-Lake Kopiago", "value": "C80423"},
          {"label": "Tari", "value": "C80424"},
          {"label": "Tari Pori", "value": "C80425"}],
 "Helmand": [{"label": "Alaqahdari Dishu", "value": "C28"},
             {"label": "Gereshk", "value": "C29"},
             {"label": "Lashkar Gah", "value": "C30"},
             {"label": "Markaz-e Hukumat-e Darweshan", "value": "C31"},
             {"label": "Sangin", "value": "C32"}],
 "Henan": [{"label": "Anyang", "value": "C18710"},
           {"label": "Anyang Shi", "value": "C18711"},
           {"label": "Binhe", "value": "C18712"},
           {"label": "Chengguan", "value": "C18713"},
           {"label": "Chengjiao", "value": "C18714"},
           {"label": "Daokou", "value": "C18715"},
           {"label": "Dingcheng", "value": "C18716"},
           {"label": "Hancheng", "value": "C18717"},
           {"label": "Hebi", "value": "C18718"},
           {"label": "Huaidian", "value": "C18719"},
           {"label": "Huazhou", "value": "C18720"},
           {"label": "Huichang", "value": "C18721"},
           {"label": "Jianshe", "value": "C18722"},
           {"label": "Jiaozuo", "value": "C18723"},
           {"label": "Jishui", "value": "C18724"},
           {"label": "Jiyuan", "value": "C18725"},
           {"label": "Kaifeng", "value": "C18726"},
           {"label": "Kaiyuan", "value": "C18727"},
           {"label": "Lingbao Chengguanzhen", "value": "C18728"},
           {"label": "Luohe", "value": "C18729"},
           {"label": "Luohe Shi", "value": "C18730"},
           {"label": "Luoyang", "value": "C18731"},
           {"label": "Minggang", "value": "C18732"},
           {"label": "Nanyang", "value": "C18733"},
           {"label": "Pingdingshan", "value": "C18734"},
           {"label": "Puyang Chengguanzhen", "value": "C18735"},
           {"label": "Puyang Shi", "value": "C18736"},
           {"label": "Qingping", "value": "C18737"},
           {"label": "Runing", "value": "C18738"},
           {"label": "Ruzhou", "value": "C18739"},
           {"label": "Shangqiu", "value": "C18740"},
           {"label": "Songyang", "value": "C18741"},
           {"label": "Suohe", "value": "C18742"},
           {"label": "Tanbei", "value": "C18743"},
           {"label": "Wacheng", "value": "C18744"},
           {"label": "Xiangcheng Chengguanzhen", "value": "C18745"},
           {"label": "Xincheng", "value": "C18746"},
           {"label": "Xinhualu", "value": "C18747"},
           {"label": "Xinxiang", "value": "C18748"},
           {"label": "Xinxiang Shi", "value": "C18749"},
           {"label": "Xinyang", "value": "C18750"},
           {"label": "Xixiang", "value": "C18751"},
           {"label": "Xuchang", "value": "C18752"},
           {"label": "Xuchang Shi", "value": "C18753"},
           {"label": "Yakou", "value": "C18754"},
           {"label": "Yanshi Chengguanzhen", "value": "C18755"},
           {"label": "Yigou", "value": "C18756"},
           {"label": "Yima", "value": "C18757"},
           {"label": "Yingchuan", "value": "C18758"},
           {"label": "Yunyang", "value": "C18759"},
           {"label": "Zhengzhou", "value": "C18760"},
           {"label": "Zhoukou", "value": "C18761"},
           {"label": "Zhumadian", "value": "C18762"},
           {"label": "Zhumadian Shi", "value": "C18763"},
           {"label": "Zijinglu", "value": "C18764"}],
 "Herat": [{"label": "Chahar Burj", "value": "C33"},
           {"label": "Ghoriyan", "value": "C34"},
           {"label": "Herat", "value": "C35"},
           {"label": "Kafir Qala", "value": "C36"},
           {"label": "Karukh", "value": "C37"},
           {"label": "Kuhsan", "value": "C38"},
           {"label": "Kushk", "value": "C39"},
           {"label": "Qarah Bagh", "value": "C40"},
           {"label": "Shindand", "value": "C41"},
           {"label": "Tir Pul", "value": "C42"},
           {"label": "Zindah Jan", "value": "C43"}],
 "Heredia Province": [{"label": "angeles", "value": "C20808"},
                      {"label": "Barva", "value": "C20809"},
                      {"label": "Belen", "value": "C20810"},
                      {"label": "Flores", "value": "C20811"},
                      {"label": "Heredia", "value": "C20812"},
                      {"label": "La Asuncion", "value": "C20813"},
                      {"label": "Llorente", "value": "C20814"},
                      {"label": "Mercedes", "value": "C20815"},
                      {"label": "San Antonio", "value": "C20816"},
                      {"label": "San Francisco", "value": "C20817"},
                      {"label": "San Isidro", "value": "C20818"},
                      {"label": "San Josecito", "value": "C20819"},
                      {"label": "San Pablo", "value": "C20820"},
                      {"label": "San Rafael", "value": "C20821"},
                      {"label": "Santa Barbara", "value": "C20822"},
                      {"label": "Santo Domingo", "value": "C20823"},
                      {"label": "Sarapiqui", "value": "C20824"}],
 "Hermanas Mirabal Province": [{"label": "Salcedo", "value": "C23906"},
                               {"label": "Salsipuedes", "value": "C23907"},
                               {"label": "Tenares", "value": "C23908"},
                               {"label": "Villa Tapia", "value": "C23909"}],
 "Herrera Province": [{"label": "Boca de Parita", "value": "C80040"},
                      {"label": "Cabuya", "value": "C80041"},
                      {"label": "Cerro Largo", "value": "C80042"},
                      {"label": "Chitre", "value": "C80043"},
                      {"label": "Chumical", "value": "C80044"},
                      {"label": "Chupampa", "value": "C80045"},
                      {"label": "Distrito de Chitre", "value": "C80046"},
                      {"label": "Distrito de Las Minas", "value": "C80047"},
                      {"label": "Distrito de Los Pozos", "value": "C80048"},
                      {"label": "Distrito de Ocu", "value": "C80049"},
                      {"label": "Distrito de Parita", "value": "C80050"},
                      {"label": "Distrito de Pese", "value": "C80051"},
                      {"label": "Distrito de Santa Maria", "value": "C80052"},
                      {"label": "El Calabacito", "value": "C80053"},
                      {"label": "El Capuri", "value": "C80054"},
                      {"label": "El Cedro", "value": "C80055"},
                      {"label": "El Pedregoso", "value": "C80056"},
                      {"label": "El Rincon", "value": "C80057"},
                      {"label": "El Toro", "value": "C80058"},
                      {"label": "La Arena", "value": "C80059"},
                      {"label": "La Trinidad", "value": "C80060"},
                      {"label": "Las Guabas", "value": "C80061"},
                      {"label": "Las Minas", "value": "C80062"},
                      {"label": "Leones Arriba", "value": "C80063"},
                      {"label": "Limon de Tijeras", "value": "C80064"},
                      {"label": "Llano Bonito", "value": "C80065"},
                      {"label": "Llano de La Cruz", "value": "C80066"},
                      {"label": "Llano Grande", "value": "C80067"},
                      {"label": "Los Canelos", "value": "C80068"},
                      {"label": "Los Castillos", "value": "C80069"},
                      {"label": "Los Cerritos", "value": "C80070"},
                      {"label": "Los Cerros de Paja", "value": "C80071"},
                      {"label": "Los Pozos", "value": "C80072"},
                      {"label": "Monagrillo", "value": "C80073"},
                      {"label": "Ocu", "value": "C80074"},
                      {"label": "Paris", "value": "C80075"},
                      {"label": "Parita", "value": "C80076"},
                      {"label": "Penas Chatas", "value": "C80077"},
                      {"label": "Pese", "value": "C80078"},
                      {"label": "Pitaloza Arriba", "value": "C80079"},
                      {"label": "Portobelillo", "value": "C80080"},
                      {"label": "Potuga", "value": "C80081"},
                      {"label": "Quebrada del Rosario", "value": "C80082"},
                      {"label": "Rincon Hondo", "value": "C80083"},
                      {"label": "Sabana Grande", "value": "C80084"},
                      {"label": "San Juan Bautista", "value": "C80085"},
                      {"label": "Santa Maria", "value": "C80086"}],
 "Hesse": [{"label": "Albshausen", "value": "C37420"},
           {"label": "Alheim", "value": "C37421"},
           {"label": "Allendorf", "value": "C37422"},
           {"label": "Allendorf an der Lahn", "value": "C37423"},
           {"label": "Alsbach-Hahnlein", "value": "C37424"},
           {"label": "Alsfeld", "value": "C37425"},
           {"label": "Alten Buseck", "value": "C37426"},
           {"label": "Altenstadt", "value": "C37427"},
           {"label": "Amoneburg", "value": "C37428"},
           {"label": "Asslar", "value": "C37429"},
           {"label": "Babenhausen", "value": "C37430"},
           {"label": "Bad Arolsen", "value": "C37431"},
           {"label": "Bad Camberg", "value": "C37432"},
           {"label": "Bad Endbach", "value": "C37433"},
           {"label": "Bad Hersfeld", "value": "C37434"},
           {"label": "Bad Homburg vor der Hohe", "value": "C37435"},
           {"label": "Bad Karlshafen", "value": "C37436"},
           {"label": "Bad Konig", "value": "C37437"},
           {"label": "Bad Nauheim", "value": "C37438"},
           {"label": "Bad Orb", "value": "C37439"},
           {"label": "Bad Salzschlirf", "value": "C37440"},
           {"label": "Bad Schwalbach", "value": "C37441"},
           {"label": "Bad Soden am Taunus", "value": "C37442"},
           {"label": "Bad Soden-Salmunster", "value": "C37443"},
           {"label": "Bad Sooden-Allendorf", "value": "C37444"},
           {"label": "Bad Vilbel", "value": "C37445"},
           {"label": "Bad Wildungen", "value": "C37446"},
           {"label": "Battenberg", "value": "C37447"},
           {"label": "Baunatal", "value": "C37448"},
           {"label": "Bebra", "value": "C37449"},
           {"label": "Beerfelden", "value": "C37450"},
           {"label": "Bensheim", "value": "C37451"},
           {"label": "Berstadt", "value": "C37452"},
           {"label": "Beuern", "value": "C37453"},
           {"label": "Biblis", "value": "C37454"},
           {"label": "Bickenbach", "value": "C37455"},
           {"label": "Biebesheim", "value": "C37456"},
           {"label": "Biedenkopf", "value": "C37457"},
           {"label": "Birkenau", "value": "C37458"},
           {"label": "Birstein", "value": "C37459"},
           {"label": "Bischoffen", "value": "C37460"},
           {"label": "Bischofsheim", "value": "C37461"},
           {"label": "Borken", "value": "C37462"},
           {"label": "Braunfels", "value": "C37463"},
           {"label": "Breidenbach", "value": "C37464"},
           {"label": "Breitenbach", "value": "C37465"},
           {"label": "Breitscheid", "value": "C37466"},
           {"label": "Brensbach", "value": "C37467"},
           {"label": "Breuna", "value": "C37468"},
           {"label": "Bromskirchen", "value": "C37469"},
           {"label": "Bruchkobel", "value": "C37470"},
           {"label": "Budingen", "value": "C37471"},
           {"label": "Burghaun", "value": "C37472"},
           {"label": "Burgsolms", "value": "C37473"},
           {"label": "Burstadt", "value": "C37474"},
           {"label": "Buttelborn", "value": "C37475"},
           {"label": "Butzbach", "value": "C37476"},
           {"label": "Calden", "value": "C37477"},
           {"label": "Colbe", "value": "C37478"},
           {"label": "Cornberg", "value": "C37479"},
           {"label": "Darmstadt", "value": "C37480"},
           {"label": "Dehrn", "value": "C37481"},
           {"label": "Dieburg", "value": "C37482"},
           {"label": "Dietzenbach", "value": "C37483"},
           {"label": "Dillenburg", "value": "C37484"},
           {"label": "Dipperz", "value": "C37485"},
           {"label": "Dreieich", "value": "C37486"},
           {"label": "Driedorf", "value": "C37487"},
           {"label": "Echzell", "value": "C37488"},
           {"label": "Egelsbach", "value": "C37489"},
           {"label": "Ehringshausen", "value": "C37490"},
           {"label": "Eichenzell", "value": "C37491"},
           {"label": "Einhausen", "value": "C37492"},
           {"label": "Eiterfeld", "value": "C37493"},
           {"label": "Eltville", "value": "C37494"},
           {"label": "Elz", "value": "C37495"},
           {"label": "Eppertshausen", "value": "C37496"},
           {"label": "Eppstein", "value": "C37497"},
           {"label": "Erbach", "value": "C37498"},
           {"label": "Erlensee", "value": "C37499"},
           {"label": "Erzhausen", "value": "C37500"},
           {"label": "Eschborn", "value": "C37501"},
           {"label": "Eschwege", "value": "C37502"},
           {"label": "Espenau", "value": "C37503"},
           {"label": "Felsberg", "value": "C37504"},
           {"label": "Flieden", "value": "C37505"},
           {"label": "Florsheim", "value": "C37506"},
           {"label": "Florstadt", "value": "C37507"},
           {"label": "Frankenau", "value": "C37508"},
           {"label": "Frankenberg", "value": "C37509"},
           {"label": "Frankfurt am Main", "value": "C37510"},
           {"label": "Frankisch-Crumbach", "value": "C37511"},
           {"label": "Freiensteinau", "value": "C37512"},
           {"label": "Friedberg", "value": "C37513"},
           {"label": "Friedewald", "value": "C37514"},
           {"label": "Friedrichsdorf", "value": "C37515"},
           {"label": "Frielendorf", "value": "C37516"},
           {"label": "Fritzlar", "value": "C37517"},
           {"label": "Fronhausen", "value": "C37518"},
           {"label": "Fulda", "value": "C37519"},
           {"label": "Fuldatal", "value": "C37520"},
           {"label": "Furth", "value": "C37521"},
           {"label": "Gallus", "value": "C37522"},
           {"label": "Gedern", "value": "C37523"},
           {"label": "Geisenheim", "value": "C37524"},
           {"label": "Gelnhausen", "value": "C37525"},
           {"label": "Gemunden an der Wohra", "value": "C37526"},
           {"label": "Gernsheim", "value": "C37527"},
           {"label": "Gersfeld", "value": "C37528"},
           {"label": "Giessen", "value": "C37529"},
           {"label": "Gilserberg", "value": "C37530"},
           {"label": "Ginsheim-Gustavsburg", "value": "C37531"},
           {"label": "Gladenbach", "value": "C37532"},
           {"label": "Glashutten", "value": "C37533"},
           {"label": "Glauburg", "value": "C37534"},
           {"label": "Gravenwiesbach", "value": "C37535"},
           {"label": "Grebenau", "value": "C37536"},
           {"label": "Grebenhain", "value": "C37537"},
           {"label": "Grebenstein", "value": "C37538"},
           {"label": "Greifenstein", "value": "C37539"},
           {"label": "Griesheim", "value": "C37540"},
           {"label": "Gross-Bieberau", "value": "C37541"},
           {"label": "Gross-Gerau", "value": "C37542"},
           {"label": "Gross-Rohrheim", "value": "C37543"},
           {"label": "Gross-Umstadt", "value": "C37544"},
           {"label": "Gross-Zimmern", "value": "C37545"},
           {"label": "Grossalmerode", "value": "C37546"},
           {"label": "Grossenluder", "value": "C37547"},
           {"label": "Grosskrotzenburg", "value": "C37548"},
           {"label": "Grunberg", "value": "C37549"},
           {"label": "Gudensberg", "value": "C37550"},
           {"label": "Guxhagen", "value": "C37551"},
           {"label": "Hadamar", "value": "C37552"},
           {"label": "Haiger", "value": "C37553"},
           {"label": "Hain-Grundau", "value": "C37554"},
           {"label": "Haina", "value": "C37555"},
           {"label": "Hammersbach", "value": "C37556"},
           {"label": "Hanau am Main", "value": "C37557"},
           {"label": "Hattersheim", "value": "C37558"},
           {"label": "Hatzfeld", "value": "C37559"},
           {"label": "Helsa", "value": "C37560"},
           {"label": "Heppenheim an der Bergstrasse", "value": "C37561"},
           {"label": "Herborn", "value": "C37562"},
           {"label": "Herbstein", "value": "C37563"},
           {"label": "Heringen", "value": "C37564"},
           {"label": "Herleshausen", "value": "C37565"},
           {"label": "Hessisch Lichtenau", "value": "C37566"},
           {"label": "Heuchelheim", "value": "C37567"},
           {"label": "Heusenstamm", "value": "C37568"},
           {"label": "Hilders", "value": "C37569"},
           {"label": "Hirschhorn", "value": "C37570"},
           {"label": "Hirzenhain", "value": "C37571"},
           {"label": "Hochheim am Main", "value": "C37572"},
           {"label": "Hochst im Odenwald", "value": "C37573"},
           {"label": "Hofbieber", "value": "C37574"},
           {"label": "Hofgeismar", "value": "C37575"},
           {"label": "Hofheim am Taunus", "value": "C37576"},
           {"label": "Hoingen", "value": "C37577"},
           {"label": "Homberg", "value": "C37578"},
           {"label": "Hornsheim", "value": "C37579"},
           {"label": "Hosenfeld", "value": "C37580"},
           {"label": "Hunfeld", "value": "C37581"},
           {"label": "Hungen", "value": "C37582"},
           {"label": "Idstein", "value": "C37583"},
           {"label": "Immenhausen", "value": "C37584"},
           {"label": "Jesberg", "value": "C37585"},
           {"label": "Karben", "value": "C37586"},
           {"label": "Kassel", "value": "C37587"},
           {"label": "Kaufungen", "value": "C37588"},
           {"label": "Kefenrod", "value": "C37589"},
           {"label": "Kelkheim (Taunus)", "value": "C37590"},
           {"label": "Kelsterbach", "value": "C37591"},
           {"label": "Kiedrich", "value": "C37592"},
           {"label": "Kirchhain", "value": "C37593"},
           {"label": "Kirchheim", "value": "C37594"},
           {"label": "Kirtorf", "value": "C37595"},
           {"label": "Konigstein im Taunus", "value": "C37596"},
           {"label": "Korbach", "value": "C37597"},
           {"label": "Korle", "value": "C37598"},
           {"label": "Kriftel", "value": "C37599"},
           {"label": "Kronberg", "value": "C37600"},
           {"label": "Kronberg Tal", "value": "C37601"},
           {"label": "Kunzell", "value": "C37602"},
           {"label": "Lampertheim", "value": "C37603"},
           {"label": "Langen", "value": "C37604"},
           {"label": "Langenselbold", "value": "C37605"},
           {"label": "Langgons", "value": "C37606"},
           {"label": "Laubach", "value": "C37607"},
           {"label": "Laufdorf", "value": "C37608"},
           {"label": "Lauterbach", "value": "C37609"},
           {"label": "Lautertal", "value": "C37610"},
           {"label": "Leun", "value": "C37611"},
           {"label": "Lich", "value": "C37612"},
           {"label": "Liebenau", "value": "C37613"},
           {"label": "Liederbach", "value": "C37614"},
           {"label": "Limburg an der Lahn", "value": "C37615"},
           {"label": "Lindenfels", "value": "C37616"},
           {"label": "Lohfelden", "value": "C37617"},
           {"label": "Lohnberg", "value": "C37618"},
           {"label": "Lohra", "value": "C37619"},
           {"label": "Lollar", "value": "C37620"},
           {"label": "Lorsch", "value": "C37621"},
           {"label": "Lutzelbach", "value": "C37622"},
           {"label": "Maintal", "value": "C37623"},
           {"label": "Malsfeld", "value": "C37624"},
           {"label": "Marburg an der Lahn", "value": "C37625"},
           {"label": "Martinhagen", "value": "C37626"},
           {"label": "Melbach", "value": "C37627"},
           {"label": "Melsungen", "value": "C37628"},
           {"label": "Mengerskirchen", "value": "C37629"},
           {"label": "Merenberg", "value": "C37630"},
           {"label": "Merkenbach", "value": "C37631"},
           {"label": "Messel", "value": "C37632"},
           {"label": "Michelstadt", "value": "C37633"},
           {"label": "Mittenaar", "value": "C37634"},
           {"label": "Morfelden-Walldorf", "value": "C37635"},
           {"label": "Morlenbach", "value": "C37636"},
           {"label": "Muhlheim am Main", "value": "C37637"},
           {"label": "Munchhausen", "value": "C37638"},
           {"label": "Munster", "value": "C37639"},
           {"label": "Munzenberg", "value": "C37640"},
           {"label": "Naumburg", "value": "C37641"},
           {"label": "Neckarsteinach", "value": "C37642"},
           {"label": "Nentershausen", "value": "C37643"},
           {"label": "Neu Isenburg", "value": "C37644"},
           {"label": "Neu-Anspach", "value": "C37645"},
           {"label": "Neuental", "value": "C37646"},
           {"label": "Neuhof", "value": "C37647"},
           {"label": "Neukirchen", "value": "C37648"},
           {"label": "Neustadt (Hessen)", "value": "C37649"},
           {"label": "Nidda", "value": "C37650"},
           {"label": "Nidderau", "value": "C37651"},
           {"label": "Niedenstein", "value": "C37652"},
           {"label": "Nieder-Grundau", "value": "C37653"},
           {"label": "Niederaula", "value": "C37654"},
           {"label": "Niederbiel", "value": "C37655"},
           {"label": "Niederdorfelden", "value": "C37656"},
           {"label": "Niederklein", "value": "C37657"},
           {"label": "Niedernhausen", "value": "C37658"},
           {"label": "Niederrad", "value": "C37659"},
           {"label": "Ober-Morlen", "value": "C37660"},
           {"label": "Ober-Ramstadt", "value": "C37661"},
           {"label": "Oberaula", "value": "C37662"},
           {"label": "Oberbiel", "value": "C37663"},
           {"label": "Obertshausen", "value": "C37664"},
           {"label": "Oberursel", "value": "C37665"},
           {"label": "Offenbach", "value": "C37666"},
           {"label": "Ortenberg", "value": "C37667"},
           {"label": "Ottrau", "value": "C37668"},
           {"label": "Pfungstadt", "value": "C37669"},
           {"label": "Philippsthal", "value": "C37670"},
           {"label": "Poppenhausen", "value": "C37671"},
           {"label": "Rabenau", "value": "C37672"},
           {"label": "Ranstadt", "value": "C37673"},
           {"label": "Rasdorf", "value": "C37674"},
           {"label": "Raunheim", "value": "C37675"},
           {"label": "Rauschenberg", "value": "C37676"},
           {"label": "Regierungsbezirk Darmstadt", "value": "C37677"},
           {"label": "Regierungsbezirk Giessen", "value": "C37678"},
           {"label": "Regierungsbezirk Kassel", "value": "C37679"},
           {"label": "Reichelsheim", "value": "C37680"},
           {"label": "Reinhardshausen", "value": "C37681"},
           {"label": "Reinheim", "value": "C37682"},
           {"label": "Reiskirchen", "value": "C37683"},
           {"label": "Riedstadt", "value": "C37684"},
           {"label": "Rimbach", "value": "C37685"},
           {"label": "Rockenberg", "value": "C37686"},
           {"label": "Rodenbach", "value": "C37687"},
           {"label": "Rodgau", "value": "C37688"},
           {"label": "Romrod", "value": "C37689"},
           {"label": "Ronshausen", "value": "C37690"},
           {"label": "Rosbach vor der Hohe", "value": "C37691"},
           {"label": "Rosenthal", "value": "C37692"},
           {"label": "Rossdorf", "value": "C37693"},
           {"label": "Rotenburg an der Fulda", "value": "C37694"},
           {"label": "Rothenberg", "value": "C37695"},
           {"label": "Rudesheim am Rhein", "value": "C37696"},
           {"label": "Runkel", "value": "C37697"},
           {"label": "Russelsheim", "value": "C37698"},
           {"label": "Sachsenhausen", "value": "C37699"},
           {"label": "Schaafheim", "value": "C37700"},
           {"label": "Schenklengsfeld", "value": "C37701"},
           {"label": "Schlangenbad", "value": "C37702"},
           {"label": "Schlitz", "value": "C37703"},
           {"label": "Schluchtern", "value": "C37704"},
           {"label": "Schmitten", "value": "C37705"},
           {"label": "Schoffengrund", "value": "C37706"},
           {"label": "Schotten", "value": "C37707"},
           {"label": "Schrecksbach", "value": "C37708"},
           {"label": "Schwalbach", "value": "C37709"},
           {"label": "Schwalbach am Taunus", "value": "C37710"},
           {"label": "Schwalmstadt", "value": "C37711"},
           {"label": "Schwalmtal", "value": "C37712"},
           {"label": "Schwarzenborn", "value": "C37713"},
           {"label": "Seeheim-Jugenheim", "value": "C37714"},
           {"label": "Seligenstadt", "value": "C37715"},
           {"label": "Selters", "value": "C37716"},
           {"label": "Sinn", "value": "C37717"},
           {"label": "Sodel", "value": "C37718"},
           {"label": "Solms", "value": "C37719"},
           {"label": "Sontra", "value": "C37720"},
           {"label": "Spangenberg", "value": "C37721"},
           {"label": "Stadtallendorf", "value": "C37722"},
           {"label": "Staufenberg", "value": "C37723"},
           {"label": "Steeden", "value": "C37724"},
           {"label": "Steinau an der Strasse", "value": "C37725"},
           {"label": "Steinbach am Taunus", "value": "C37726"},
           {"label": "Stockstadt am Rhein", "value": "C37727"},
           {"label": "Sulzbach", "value": "C37728"},
           {"label": "Tann", "value": "C37729"},
           {"label": "Taunusstein", "value": "C37730"},
           {"label": "Trebur", "value": "C37731"},
           {"label": "Trendelburg", "value": "C37732"},
           {"label": "Udenhausen", "value": "C37733"},
           {"label": "Ulrichstein", "value": "C37734"},
           {"label": "Unter-Abtsteinach", "value": "C37735"},
           {"label": "Usingen", "value": "C37736"},
           {"label": "Vellmar", "value": "C37737"},
           {"label": "Viernheim", "value": "C37738"},
           {"label": "Villmar", "value": "C37739"},
           {"label": "Vohl", "value": "C37740"},
           {"label": "Volkmarsen", "value": "C37741"},
           {"label": "Wabern", "value": "C37742"},
           {"label": "Wachtersbach", "value": "C37743"},
           {"label": "Wald-Michelbach", "value": "C37744"},
           {"label": "Waldbrunn", "value": "C37745"},
           {"label": "Waldeck", "value": "C37746"},
           {"label": "Waldems", "value": "C37747"},
           {"label": "Waldkappel", "value": "C37748"},
           {"label": "Walluf", "value": "C37749"},
           {"label": "Wanfried", "value": "C37750"},
           {"label": "Wehrheim", "value": "C37751"},
           {"label": "Weilburg", "value": "C37752"},
           {"label": "Weilmunster", "value": "C37753"},
           {"label": "Weinbach", "value": "C37754"},
           {"label": "Weiterstadt", "value": "C37755"},
           {"label": "Wetter", "value": "C37756"},
           {"label": "Wetzlar", "value": "C37757"},
           {"label": "Wiesbaden", "value": "C37758"},
           {"label": "Willingen", "value": "C37759"},
           {"label": "Willingshausen", "value": "C37760"},
           {"label": "Witzenhausen", "value": "C37761"},
           {"label": "Wohnbach", "value": "C37762"},
           {"label": "Wolfersheim", "value": "C37763"},
           {"label": "Wolfhagen", "value": "C37764"},
           {"label": "Zierenberg", "value": "C37765"},
           {"label": "Zwingenberg", "value": "C37766"}],
 "Heves County": [{"label": "Abasar", "value": "C44213"},
                  {"label": "Adacs", "value": "C44214"},
                  {"label": "Andornaktalya", "value": "C44215"},
                  {"label": "Apc", "value": "C44216"},
                  {"label": "Belapatfalva", "value": "C44217"},
                  {"label": "Belapatfalvai Jaras", "value": "C44218"},
                  {"label": "Besenyotelek", "value": "C44219"},
                  {"label": "Boldog", "value": "C44220"},
                  {"label": "Csany", "value": "C44221"},
                  {"label": "Domoszlo", "value": "C44222"},
                  {"label": "Ecsed", "value": "C44223"},
                  {"label": "Eger", "value": "C44224"},
                  {"label": "Egerszalok", "value": "C44225"},
                  {"label": "Egri Jaras", "value": "C44226"},
                  {"label": "Erdotelek", "value": "C44227"},
                  {"label": "Felsotarkany", "value": "C44228"},
                  {"label": "Fuzesabony", "value": "C44229"},
                  {"label": "Fuzesabonyi Jaras", "value": "C44230"},
                  {"label": "Gyongyos", "value": "C44231"},
                  {"label": "Gyongyoshalasz", "value": "C44232"},
                  {"label": "Gyongyosi Jaras", "value": "C44233"},
                  {"label": "Gyongyospata", "value": "C44234"},
                  {"label": "Gyongyossolymos", "value": "C44235"},
                  {"label": "Gyongyostarjan", "value": "C44236"},
                  {"label": "Hatvan", "value": "C44237"},
                  {"label": "Hatvani Jaras", "value": "C44238"},
                  {"label": "Hered", "value": "C44239"},
                  {"label": "Heves", "value": "C44240"},
                  {"label": "Hevesi Jaras", "value": "C44241"},
                  {"label": "Hort", "value": "C44242"},
                  {"label": "Kal", "value": "C44243"},
                  {"label": "Karacsond", "value": "C44244"},
                  {"label": "Kerecsend", "value": "C44245"},
                  {"label": "Kiskore", "value": "C44246"},
                  {"label": "Kompolt", "value": "C44247"},
                  {"label": "Lorinci", "value": "C44248"},
                  {"label": "Maklar", "value": "C44249"},
                  {"label": "Matraderecske", "value": "C44250"},
                  {"label": "Nagyrede", "value": "C44251"},
                  {"label": "Ostoros", "value": "C44252"},
                  {"label": "Parad", "value": "C44253"},
                  {"label": "Paradsasvar", "value": "C44254"},
                  {"label": "Petervasara", "value": "C44255"},
                  {"label": "Petervasarai Jaras", "value": "C44256"},
                  {"label": "Petofibanya", "value": "C44257"},
                  {"label": "Poroszlo", "value": "C44258"},
                  {"label": "Recsk", "value": "C44259"},
                  {"label": "Rozsaszentmarton", "value": "C44260"},
                  {"label": "Sirok", "value": "C44261"},
                  {"label": "Szihalom", "value": "C44262"},
                  {"label": "Szilvasvarad", "value": "C44263"},
                  {"label": "Tarnalelesz", "value": "C44264"},
                  {"label": "Tarnaors", "value": "C44265"},
                  {"label": "Tiszanana", "value": "C44266"},
                  {"label": "Vamosgyork", "value": "C44267"},
                  {"label": "Verpelet", "value": "C44268"},
                  {"label": "Zagyvaszanto", "value": "C44269"}],
 "Hhohho District": [{"label": "Bulembu", "value": "C115473"},
                     {"label": "Hhukwini", "value": "C115474"},
                     {"label": "Lobamba", "value": "C115475"},
                     {"label": "Mbabane", "value": "C115476"},
                     {"label": "Nkhaba", "value": "C115477"},
                     {"label": "Piggs Peak", "value": "C115478"}],
 "Hidalgo": [{"label": "Acahuasco", "value": "C69208"},
             {"label": "Acatepec", "value": "C69209"},
             {"label": "Acatlan", "value": "C69210"},
             {"label": "Acaxochitlan", "value": "C69211"},
             {"label": "Acayuca", "value": "C69212"},
             {"label": "Acoapa", "value": "C69213"},
             {"label": "Acoxcatlan", "value": "C69214"},
             {"label": "Acoyotla", "value": "C69215"},
             {"label": "Actopan", "value": "C69216"},
             {"label": "Ahuatitla", "value": "C69217"},
             {"label": "Ahuehuetitla", "value": "C69218"},
             {"label": "Ajacuba", "value": "C69219"},
             {"label": "Alfajayucan", "value": "C69220"},
             {"label": "Almoloya", "value": "C69221"},
             {"label": "alvaro Obregon", "value": "C69222"},
             {"label": "Apan", "value": "C69223"},
             {"label": "Atengo", "value": "C69224"},
             {"label": "Atitalaquia", "value": "C69225"},
             {"label": "Atlapexco", "value": "C69226"},
             {"label": "Atotonilco de Tula", "value": "C69227"},
             {"label": "Atotonilco el Grande", "value": "C69228"},
             {"label": "Bangandho", "value": "C69229"},
             {"label": "Barrio Aztlan", "value": "C69230"},
             {"label": "Benito Juarez", "value": "C69231"},
             {"label": "Bomanxotha", "value": "C69232"},
             {"label": "Bomintzha", "value": "C69233"},
             {"label": "Bondojito", "value": "C69234"},
             {"label": "Calnali", "value": "C69235"},
             {"label": "Caltimacan", "value": "C69236"},
             {"label": "Camelia (Barrio la Camelia)", "value": "C69237"},
             {"label": "Campestre Villas del alamo", "value": "C69238"},
             {"label": "Canada", "value": "C69239"},
             {"label": "Canada de Madero", "value": "C69240"},
             {"label": "Cantera de Villagran", "value": "C69241"},
             {"label": "Cantinela", "value": "C69242"},
             {"label": "Carboneras", "value": "C69243"},
             {"label": "Cardonal", "value": "C69244"},
             {"label": "Carpinteros", "value": "C69245"},
             {"label": "Caxuxi", "value": "C69246"},
             {"label": "Cerritos", "value": "C69247"},
             {"label": "Chachahuantla", "value": "C69248"},
             {"label": "Chalahuiyapa", "value": "C69249"},
             {"label": "Chapantongo", "value": "C69250"},
             {"label": "Chapulhuacan", "value": "C69251"},
             {"label": "Chapulhuacanito", "value": "C69252"},
             {"label": "Chatipan", "value": "C69253"},
             {"label": "Chicavasco", "value": "C69254"},
             {"label": "Chichatla", "value": "C69255"},
             {"label": "Chilcuautla", "value": "C69256"},
             {"label": "Chililico", "value": "C69257"},
             {"label": "Chimalapa", "value": "C69258"},
             {"label": "Chimalpa y Tlalayote", "value": "C69259"},
             {"label": "Cipreses", "value": "C69260"},
             {"label": "Ciudad Sahagun", "value": "C69261"},
             {"label": "Coacuilco", "value": "C69262"},
             {"label": "Colinas de Plata", "value": "C69263"},
             {"label": "Colonia 28 de Mayo (Santa Rosa)", "value": "C69264"},
             {"label": "Colonia Benito Juarez", "value": "C69265"},
             {"label": "Colonia Felipe angeles", "value": "C69266"},
             {"label": "Colonia Guadalupe", "value": "C69267"},
             {"label": "Colonia Militar", "value": "C69268"},
             {"label": "Colonia Morelos", "value": "C69269"},
             {"label": "Colonia Morelos (El Nueve)", "value": "C69270"},
             {"label": "Colonia San Juan", "value": "C69271"},
             {"label": "Colonia Sesenta y Dos", "value": "C69272"},
             {"label": "Colonia Tenhe", "value": "C69273"},
             {"label": "Conejos", "value": "C69274"},
             {"label": "Coyotillos", "value": "C69275"},
             {"label": "Cuapaxtitla", "value": "C69276"},
             {"label": "Cuatolol", "value": "C69277"},
             {"label": "Cuautepec de Hinojosa", "value": "C69278"},
             {"label": "Cuazahuatl", "value": "C69279"},
             {"label": "Dajiedi", "value": "C69280"},
             {"label": "Dantzibojay", "value": "C69281"},
             {"label": "Daxtha", "value": "C69282"},
             {"label": "Dengantzha", "value": "C69283"},
             {"label": "Dextho de Victoria", "value": "C69284"},
             {"label": "Dios Padre", "value": "C69285"},
             {"label": "Don Antonio", "value": "C69286"},
             {"label": "Doxey", "value": "C69287"},
             {"label": "El Arenal", "value": "C69288"},
             {"label": "El Barrido", "value": "C69289"},
             {"label": "El Bingu", "value": "C69290"},
             {"label": "El Boxtha", "value": "C69291"},
             {"label": "El Capulin", "value": "C69292"},
             {"label": "El Cid", "value": "C69293"},
             {"label": "El Colorado", "value": "C69294"},
             {"label": "El Huaxtho", "value": "C69295"},
             {"label": "El Huixmi", "value": "C69296"},
             {"label": "El Jaguey", "value": "C69297"},
             {"label": "El Jiadi", "value": "C69298"},
             {"label": "El Llano", "value": "C69299"},
             {"label": "El Mirador", "value": "C69300"},
             {"label": "El Moreno (San Miguel Moreno)", "value": "C69301"},
             {"label": "El Nith", "value": "C69302"},
             {"label": "El Olvera", "value": "C69303"},
             {"label": "El Paredon", "value": "C69304"},
             {"label": "El Pintor", "value": "C69305"},
             {"label": "El Refugio", "value": "C69306"},
             {"label": "El Rincon", "value": "C69307"},
             {"label": "El Roble", "value": "C69308"},
             {"label": "El Rosario", "value": "C69309"},
             {"label": "El Saucillo", "value": "C69310"},
             {"label": "El Saucillo (Fraccionamiento)", "value": "C69311"},
             {"label": "El Susto", "value": "C69312"},
             {"label": "El Tepeyac", "value": "C69313"},
             {"label": "El Tephe", "value": "C69314"},
             {"label": "El Venado", "value": "C69315"},
             {"label": "Eloxochitlan", "value": "C69316"},
             {"label": "Emiliano Zapata", "value": "C69317"},
             {"label": "Epazoyucan", "value": "C69318"},
             {"label": "Estacion de Apulco", "value": "C69319"},
             {"label": "Fontezuelas", "value": "C69320"},
             {"label": "Forjadores de Pachuca", "value": "C69321"},
             {"label": "Fraccionamiento Carlos Salinas de Gortari",
              "value": "C69322"},
             {"label": "Fraccionamiento del Magisterio Tulancinguense",
              "value": "C69323"},
             {"label": "Gandho", "value": "C69324"},
             {"label": "General Felipe angeles (Los angeles)",
              "value": "C69325"},
             {"label": "General Pedro Maria Anaya", "value": "C69326"},
             {"label": "Guadalupe Minerva", "value": "C69327"},
             {"label": "Guadalupe Victoria", "value": "C69328"},
             {"label": "Hualula", "value": "C69329"},
             {"label": "Huasca de Ocampo", "value": "C69330"},
             {"label": "Huautla", "value": "C69331"},
             {"label": "Huehuetla", "value": "C69332"},
             {"label": "Huejutla de Reyes", "value": "C69333"},
             {"label": "Hueyapa", "value": "C69334"},
             {"label": "Huichapan", "value": "C69335"},
             {"label": "Huitel", "value": "C69336"},
             {"label": "Huitepec", "value": "C69337"},
             {"label": "Huitzila", "value": "C69338"},
             {"label": "Huitzitzilingo", "value": "C69339"},
             {"label": "Ignacio Zaragoza", "value": "C69340"},
             {"label": "Irolo", "value": "C69341"},
             {"label": "Ixcatlan", "value": "C69342"},
             {"label": "Ixcuinquitlapilco", "value": "C69343"},
             {"label": "Ixmiquilpan", "value": "C69344"},
             {"label": "Ixtlahuaca", "value": "C69345"},
             {"label": "Jacala", "value": "C69346"},
             {"label": "Jaguey Blanco", "value": "C69347"},
             {"label": "Jaguey de Tellez (Estacion Tellez)", "value": "C69348"},
             {"label": "Jalpa", "value": "C69349"},
             {"label": "Jaltepec", "value": "C69350"},
             {"label": "Jaltocan", "value": "C69351"},
             {"label": "Javier Rojo Gomez", "value": "C69352"},
             {"label": "Jonacapa", "value": "C69353"},
             {"label": "Jose Maria Morelos (San Jose)", "value": "C69354"},
             {"label": "Jose Maria Pino Suarez", "value": "C69355"},
             {"label": "Julian Villagran", "value": "C69356"},
             {"label": "La Amistad", "value": "C69357"},
             {"label": "La Boveda", "value": "C69358"},
             {"label": "La Colonia", "value": "C69359"},
             {"label": "La Cruz", "value": "C69360"},
             {"label": "La Esquina", "value": "C69361"},
             {"label": "La Estancia", "value": "C69362"},
             {"label": "La Estanzuela", "value": "C69363"},
             {"label": "La Laguna", "value": "C69364"},
             {"label": "La Lagunilla", "value": "C69365"},
             {"label": "La Loma", "value": "C69366"},
             {"label": "La Mesilla", "value": "C69367"},
             {"label": "La Providencia Siglo XXI", "value": "C69368"},
             {"label": "La Reforma", "value": "C69369"},
             {"label": "La Sabinita", "value": "C69370"},
             {"label": "La Sala (La Caliente)", "value": "C69371"},
             {"label": "La Trinidad", "value": "C69372"},
             {"label": "Lagunilla", "value": "C69373"},
             {"label": "Lazaro Cardenas", "value": "C69374"},
             {"label": "Lindavista", "value": "C69375"},
             {"label": "Llano Largo", "value": "C69376"},
             {"label": "Lolotla", "value": "C69377"},
             {"label": "Los Cides", "value": "C69378"},
             {"label": "Los Reyes", "value": "C69379"},
             {"label": "Los Romeros", "value": "C69380"},
             {"label": "Los Sabinos", "value": "C69381"},
             {"label": "Los Tuzos", "value": "C69382"},
             {"label": "Machetla", "value": "C69383"},
             {"label": "Magisterio Digno", "value": "C69384"},
             {"label": "Maguey Blanco", "value": "C69385"},
             {"label": "Mamithi", "value": "C69386"},
             {"label": "Mangas", "value": "C69387"},
             {"label": "Manuel avila Camacho", "value": "C69388"},
             {"label": "Mecatlan", "value": "C69389"},
             {"label": "Melchor Ocampo", "value": "C69390"},
             {"label": "Metepec", "value": "C69391"},
             {"label": "Metepec Primero", "value": "C69392"},
             {"label": "Metztitlan", "value": "C69393"},
             {"label": "Mezquititlan", "value": "C69394"},
             {"label": "Michimaloya", "value": "C69395"},
             {"label": "Mineral del Monte", "value": "C69396"},
             {"label": "Mixquiahuala de Juarez", "value": "C69397"},
             {"label": "Molango", "value": "C69398"},
             {"label": "Monte Alegre", "value": "C69399"},
             {"label": "Motovatha", "value": "C69400"},
             {"label": "Munitepec de Madero", "value": "C69401"},
             {"label": "Nantzha", "value": "C69402"},
             {"label": "Nopala de Villagran", "value": "C69403"},
             {"label": "Nuevo Centro de Poblacion Agricola el Chacon",
              "value": "C69404"},
             {"label": "Ocampo", "value": "C69405"},
             {"label": "Ohuatipa", "value": "C69406"},
             {"label": "Ojo de Agua", "value": "C69407"},
             {"label": "Omitlan de Juarez", "value": "C69408"},
             {"label": "Oriental de Zapata", "value": "C69409"},
             {"label": "Orizabita", "value": "C69410"},
             {"label": "Oxeloco", "value": "C69411"},
             {"label": "Pachiquita", "value": "C69412"},
             {"label": "Pachuca de Soto", "value": "C69413"},
             {"label": "Pachuquilla", "value": "C69414"},
             {"label": "Pahactla", "value": "C69415"},
             {"label": "Pahuatlan", "value": "C69416"},
             {"label": "Palmillas", "value": "C69417"},
             {"label": "Panales", "value": "C69418"},
             {"label": "Panhe", "value": "C69419"},
             {"label": "Panuaya", "value": "C69420"},
             {"label": "Papatlatla", "value": "C69421"},
             {"label": "Parque de Poblamiento Solidaridad", "value": "C69422"},
             {"label": "Parque Urbano Napateco", "value": "C69423"},
             {"label": "Paseos de la Pradera", "value": "C69424"},
             {"label": "Patria Nueva", "value": "C69425"},
             {"label": "Paxtepec", "value": "C69426"},
             {"label": "Pedregal de San Jose", "value": "C69427"},
             {"label": "Pino Suarez", "value": "C69428"},
             {"label": "Pisaflores", "value": "C69429"},
             {"label": "Portezuelo", "value": "C69430"},
             {"label": "Pozuelos", "value": "C69431"},
             {"label": "Praderas del Potrero", "value": "C69432"},
             {"label": "Presas", "value": "C69433"},
             {"label": "PRI Chacon", "value": "C69434"},
             {"label": "Privada del alamo", "value": "C69435"},
             {"label": "Progreso", "value": "C69436"},
             {"label": "Progreso de Alvaro Obregon", "value": "C69437"},
             {"label": "Pueblo Hidalgo", "value": "C69438"},
             {"label": "Pueblo Nuevo", "value": "C69439"},
             {"label": "Residencial Arboledas", "value": "C69440"},
             {"label": "Rinconada de los angeles", "value": "C69441"},
             {"label": "Rinconadas de San Francisco", "value": "C69442"},
             {"label": "Rinconadas del Venado I", "value": "C69443"},
             {"label": "Rincones de la Hacienda", "value": "C69444"},
             {"label": "Rio de la Soledad", "value": "C69445"},
             {"label": "Rio Seco Puente de Doria", "value": "C69446"},
             {"label": "Salitrillo", "value": "C69447"},
             {"label": "San Agustin Tlaxiaca", "value": "C69448"},
             {"label": "San Agustin Zapotlan", "value": "C69449"},
             {"label": "San Andres", "value": "C69450"},
             {"label": "San Andres (San Andres Chichayotla)",
              "value": "C69451"},
             {"label": "San Antonio", "value": "C69452"},
             {"label": "San Antonio el Grande", "value": "C69453"},
             {"label": "San Antonio el Paso", "value": "C69454"},
             {"label": "San Antonio Zaragoza", "value": "C69455"},
             {"label": "San Bartolo Ozocalpan", "value": "C69456"},
             {"label": "San Bartolo Tutotepec", "value": "C69457"},
             {"label": "San Buenaventura", "value": "C69458"},
             {"label": "San Cristobal Chacon", "value": "C69459"},
             {"label": "San Esteban", "value": "C69460"},
             {"label": "San Felipe Orizatlan", "value": "C69461"},
             {"label": "San Francisco", "value": "C69462"},
             {"label": "San Francisco Atotonilco", "value": "C69463"},
             {"label": "San Gabriel", "value": "C69464"},
             {"label": "San Gabriel Azteca", "value": "C69465"},
             {"label": "San Gregorio", "value": "C69466"},
             {"label": "San Ignacio Nopala", "value": "C69467"},
             {"label": "San Ildefonso", "value": "C69468"},
             {"label": "San Isidro", "value": "C69469"},
             {"label": "San Joaquin", "value": "C69470"},
             {"label": "San Jose", "value": "C69471"},
             {"label": "San Jose Atlan", "value": "C69472"},
             {"label": "San Jose Boxay", "value": "C69473"},
             {"label": "San Jose Corral Blanco", "value": "C69474"},
             {"label": "San Jose Ocotillos", "value": "C69475"},
             {"label": "San Juan Achichilco", "value": "C69476"},
             {"label": "San Juan Ahuehueco", "value": "C69477"},
             {"label": "San Juan Hueyapan", "value": "C69478"},
             {"label": "San Juan Solis", "value": "C69479"},
             {"label": "San Juan Tepa", "value": "C69480"},
             {"label": "San Juan Tilcuautla", "value": "C69481"},
             {"label": "San Juan Tizahuapan", "value": "C69482"},
             {"label": "San Lorenzo Achiotepec", "value": "C69483"},
             {"label": "San Lorenzo Sayula", "value": "C69484"},
             {"label": "San Luis Tecuhautitlan", "value": "C69485"},
             {"label": "San Marcos", "value": "C69486"},
             {"label": "San Marcos Guaquilpan", "value": "C69487"},
             {"label": "San Mateo", "value": "C69488"},
             {"label": "San Miguel Cerezo (El Cerezo)", "value": "C69489"},
             {"label": "San Miguel Vindho", "value": "C69490"},
             {"label": "San Nicolas", "value": "C69491"},
             {"label": "San Nicolas el Chico", "value": "C69492"},
             {"label": "San Nicolas Tecomatlan", "value": "C69493"},
             {"label": "San Pablo el Grande", "value": "C69494"},
             {"label": "San Pedro Tlachichilco", "value": "C69495"},
             {"label": "San Pedro Tlaquilpan", "value": "C69496"},
             {"label": "San Salvador", "value": "C69497"},
             {"label": "San Sebastian Tenochtitlan", "value": "C69498"},
             {"label": "Santa Ana Ahuehuepan", "value": "C69499"},
             {"label": "Santa Ana Azcapotzaltongo", "value": "C69500"},
             {"label": "Santa Ana Batha", "value": "C69501"},
             {"label": "Santa Ana de Allende", "value": "C69502"},
             {"label": "Santa Ana Hueytlalpan", "value": "C69503"},
             {"label": "Santa Ana Tlachiahualpa", "value": "C69504"},
             {"label": "Santa Ana Tzacuala", "value": "C69505"},
             {"label": "Santa Barbara", "value": "C69506"},
             {"label": "Santa Catarina", "value": "C69507"},
             {"label": "Santa Clara", "value": "C69508"},
             {"label": "Santa Cruz", "value": "C69509"},
             {"label": "Santa Elena Paliseca", "value": "C69510"},
             {"label": "Santa Maria Actipac", "value": "C69511"},
             {"label": "Santa Maria Amajac", "value": "C69512"},
             {"label": "Santa Maria Amealco", "value": "C69513"},
             {"label": "Santa Maria Apaxco", "value": "C69514"},
             {"label": "Santa Maria Asuncion", "value": "C69515"},
             {"label": "Santa Maria Batha", "value": "C69516"},
             {"label": "Santa Maria Ilucan", "value": "C69517"},
             {"label": "Santa Maria la Calera", "value": "C69518"},
             {"label": "Santa Maria Macua", "value": "C69519"},
             {"label": "Santa Maria Magdalena", "value": "C69520"},
             {"label": "Santa Maria Nativitas", "value": "C69521"},
             {"label": "Santa Maria Quelites", "value": "C69522"},
             {"label": "Santa Maria Xigui", "value": "C69523"},
             {"label": "Santa Monica", "value": "C69524"},
             {"label": "Santa Teresa", "value": "C69525"},
             {"label": "Santa Ursula", "value": "C69526"},
             {"label": "Santiago Acayutlan", "value": "C69527"},
             {"label": "Santiago Cuaula", "value": "C69528"},
             {"label": "Santiago de Anaya", "value": "C69529"},
             {"label": "Santiago Jaltepec", "value": "C69530"},
             {"label": "Santiago Tepeyahualco", "value": "C69531"},
             {"label": "Santiago Tezontlale", "value": "C69532"},
             {"label": "Santiago Tlaltepoxco", "value": "C69533"},
             {"label": "Santiago Tlapacoya", "value": "C69534"},
             {"label": "Santiago Tlapanaloya", "value": "C69535"},
             {"label": "Santiago Tlautla", "value": "C69536"},
             {"label": "Santiago Tulantepec", "value": "C69537"},
             {"label": "Santo Tomas", "value": "C69538"},
             {"label": "Singuilucan", "value": "C69539"},
             {"label": "Talol", "value": "C69540"},
             {"label": "Tancazahuela", "value": "C69541"},
             {"label": "Tasquillo", "value": "C69542"},
             {"label": "Taxadho", "value": "C69543"},
             {"label": "Teacalco", "value": "C69544"},
             {"label": "Tecacahuaco", "value": "C69545"},
             {"label": "Tecozautla", "value": "C69546"},
             {"label": "Tehuetlan", "value": "C69547"},
             {"label": "Teltipan de Juarez", "value": "C69548"},
             {"label": "Temango", "value": "C69549"},
             {"label": "Tenango", "value": "C69550"},
             {"label": "Tenango de Doria", "value": "C69551"},
             {"label": "Teocalco", "value": "C69552"},
             {"label": "Teofani", "value": "C69553"},
             {"label": "Tepatepec", "value": "C69554"},
             {"label": "Tepeapulco", "value": "C69555"},
             {"label": "Tepehuacan de Guerrero", "value": "C69556"},
             {"label": "Tepeitic", "value": "C69557"},
             {"label": "Tepeji del Rio de Ocampo", "value": "C69558"},
             {"label": "Tepepa", "value": "C69559"},
             {"label": "Tepetitla", "value": "C69560"},
             {"label": "Tepetitlan", "value": "C69561"},
             {"label": "Tepexititla", "value": "C69562"},
             {"label": "Tepojaco", "value": "C69563"},
             {"label": "Tetepango", "value": "C69564"},
             {"label": "Texcaco", "value": "C69565"},
             {"label": "Texcaltepec", "value": "C69566"},
             {"label": "Texcapa", "value": "C69567"},
             {"label": "Texcatepec", "value": "C69568"},
             {"label": "Tezapotla", "value": "C69569"},
             {"label": "Tezontepec", "value": "C69570"},
             {"label": "Tezontepec de Aldama", "value": "C69571"},
             {"label": "Tezoquipa", "value": "C69572"},
             {"label": "Tianguistengo", "value": "C69573"},
             {"label": "Tianguistengo (La Romera)", "value": "C69574"},
             {"label": "Tiltepec", "value": "C69575"},
             {"label": "Tinajas", "value": "C69576"},
             {"label": "Tizayuca", "value": "C69577"},
             {"label": "Tlacomulco", "value": "C69578"},
             {"label": "Tlacpac", "value": "C69579"},
             {"label": "Tlahuelilpan", "value": "C69580"},
             {"label": "Tlahuelompa (San Francisco Tlahuelompa)",
              "value": "C69581"},
             {"label": "Tlahuiltepa", "value": "C69582"},
             {"label": "Tlalchiyahualica", "value": "C69583"},
             {"label": "Tlalminulpa", "value": "C69584"},
             {"label": "Tlamaco (San Geronimo Tlamaco)", "value": "C69585"},
             {"label": "Tlanalapa", "value": "C69586"},
             {"label": "Tlanchinol", "value": "C69587"},
             {"label": "Tlaxcalilla", "value": "C69588"},
             {"label": "Tlaxcoapan", "value": "C69589"},
             {"label": "Tlaxocoyucan", "value": "C69590"},
             {"label": "Tolcayuca", "value": "C69591"},
             {"label": "Tula de Allende", "value": "C69592"},
             {"label": "Tulancingo", "value": "C69593"},
             {"label": "Tulancingo de Bravo", "value": "C69594"},
             {"label": "Tulipanes", "value": "C69595"},
             {"label": "Tunititlan", "value": "C69596"},
             {"label": "Unidad Habitacional Antonio Osorio de Leon (Bojay)",
              "value": "C69597"},
             {"label": "Unidad Minera 11 de Julio", "value": "C69598"},
             {"label": "Unidades Habitacionales", "value": "C69599"},
             {"label": "Ventoquipa", "value": "C69600"},
             {"label": "Vicente Guerrero", "value": "C69601"},
             {"label": "Vicente Guerrero (San Javier)", "value": "C69602"},
             {"label": "Vito", "value": "C69603"},
             {"label": "Xiquila", "value": "C69604"},
             {"label": "Xiteje de Zapata", "value": "C69605"},
             {"label": "Xochiatipan", "value": "C69606"},
             {"label": "Xochiatipan de Castillo", "value": "C69607"},
             {"label": "Xochicoatlan", "value": "C69608"},
             {"label": "Xochitlan", "value": "C69609"},
             {"label": "Xolostitla de Morelos (Xolostitla)", "value": "C69610"},
             {"label": "Xoxolpa", "value": "C69611"},
             {"label": "Yahualica", "value": "C69612"},
             {"label": "Yolotepec", "value": "C69613"},
             {"label": "Zacacuautla", "value": "C69614"},
             {"label": "Zacamulpa", "value": "C69615"},
             {"label": "Zacualtipan", "value": "C69616"},
             {"label": "Zacualtipanito", "value": "C69617"},
             {"label": "Zapotlan de Juarez", "value": "C69618"},
             {"label": "Zempoala", "value": "C69619"},
             {"label": "Zimapan", "value": "C69620"},
             {"label": "Zocea", "value": "C69621"},
             {"label": "Zoquitipan", "value": "C69622"},
             {"label": "Zothe", "value": "C69623"},
             {"label": "Zotoluca", "value": "C69624"}],
 "Hiiu County": [{"label": "Kardla", "value": "C24534"}],
 "Himachal Pradesh": [{"label": "Arki", "value": "C45862"},
                      {"label": "Baddi", "value": "C45863"},
                      {"label": "Banjar", "value": "C45864"},
                      {"label": "Bilaspur", "value": "C45865"},
                      {"label": "Chamba", "value": "C45866"},
                      {"label": "Chaupal", "value": "C45867"},
                      {"label": "Chowari", "value": "C45868"},
                      {"label": "Chuari Khas", "value": "C45869"},
                      {"label": "Dagshai", "value": "C45870"},
                      {"label": "Dalhousie", "value": "C45871"},
                      {"label": "Daulatpur", "value": "C45872"},
                      {"label": "Dera Gopipur", "value": "C45873"},
                      {"label": "Dharamsala", "value": "C45874"},
                      {"label": "Gagret", "value": "C45875"},
                      {"label": "Ghumarwin", "value": "C45876"},
                      {"label": "Hamirpur", "value": "C45877"},
                      {"label": "Jawala Mukhi", "value": "C45878"},
                      {"label": "Jogindarnagar", "value": "C45879"},
                      {"label": "Jubbal", "value": "C45880"},
                      {"label": "Jutogh", "value": "C45881"},
                      {"label": "Kalka", "value": "C45882"},
                      {"label": "Kangar", "value": "C45883"},
                      {"label": "Kangra", "value": "C45884"},
                      {"label": "Kasauli", "value": "C45885"},
                      {"label": "Kinnaur", "value": "C45886"},
                      {"label": "Kotkhai", "value": "C45887"},
                      {"label": "Kotla", "value": "C45888"},
                      {"label": "Kulu", "value": "C45889"},
                      {"label": "Kyelang", "value": "C45890"},
                      {"label": "Lahul and Spiti", "value": "C45891"},
                      {"label": "Manali", "value": "C45892"},
                      {"label": "Mandi", "value": "C45893"},
                      {"label": "Nadaun", "value": "C45894"},
                      {"label": "Nagar", "value": "C45895"},
                      {"label": "Nagrota", "value": "C45896"},
                      {"label": "Nahan", "value": "C45897"},
                      {"label": "Nalagarh", "value": "C45898"},
                      {"label": "Palampur", "value": "C45899"},
                      {"label": "Pandoh", "value": "C45900"},
                      {"label": "Paonta Sahib", "value": "C45901"},
                      {"label": "Parwanoo", "value": "C45902"},
                      {"label": "Rajgarh", "value": "C45903"},
                      {"label": "Rampur", "value": "C45904"},
                      {"label": "Rohru", "value": "C45905"},
                      {"label": "Sabathu", "value": "C45906"},
                      {"label": "Santokhgarh", "value": "C45907"},
                      {"label": "Sarahan", "value": "C45908"},
                      {"label": "Sarka Ghat", "value": "C45909"},
                      {"label": "Seoni", "value": "C45910"},
                      {"label": "Shimla", "value": "C45911"},
                      {"label": "Sirmaur", "value": "C45912"},
                      {"label": "Solan", "value": "C45913"},
                      {"label": "Sundarnagar", "value": "C45914"},
                      {"label": "Theog", "value": "C45915"},
                      {"label": "Tira Sujanpur", "value": "C45916"},
                      {"label": "Una", "value": "C45917"},
                      {"label": "Yol", "value": "C45918"}],
 "Hincesti District": [{"label": "Dancu", "value": "C75522"},
                       {"label": "Hincesti", "value": "C75523"}],
 "Hiran": [{"label": "Beledweyne", "value": "C107900"},
           {"label": "Buulobarde", "value": "C107901"},
           {"label": "Jalalaqsi", "value": "C107902"}],
 "Hiroshima Prefecture": [{"label": "Aki-takata Shi", "value": "C62898"},
                          {"label": "Etajima-shi", "value": "C62899"},
                          {"label": "Fuchu-shi", "value": "C62900"},
                          {"label": "Fuchucho", "value": "C62901"},
                          {"label": "Fukuyama", "value": "C62902"},
                          {"label": "Fukuyama Shi", "value": "C62903"},
                          {"label": "Hatsukaichi", "value": "C62904"},
                          {"label": "Hatsukaichi-shi", "value": "C62905"},
                          {"label": "Higashihiroshima Shi", "value": "C62906"},
                          {"label": "Hiroshima", "value": "C62907"},
                          {"label": "Hiroshima-shi", "value": "C62908"},
                          {"label": "Innoshima", "value": "C62909"},
                          {"label": "Kannabecho-yahiro", "value": "C62910"},
                          {"label": "Kure", "value": "C62911"},
                          {"label": "Kure-shi", "value": "C62912"},
                          {"label": "Mihara", "value": "C62913"},
                          {"label": "Mihara Shi", "value": "C62914"},
                          {"label": "Miyajima", "value": "C62915"},
                          {"label": "Miyoshi", "value": "C62916"},
                          {"label": "Miyoshi Shi", "value": "C62917"},
                          {"label": "Ono-hara", "value": "C62918"},
                          {"label": "Onomichi", "value": "C62919"},
                          {"label": "Onomichi-shi", "value": "C62920"},
                          {"label": "Otake", "value": "C62921"},
                          {"label": "Otake-shi", "value": "C62922"},
                          {"label": "Shinichi", "value": "C62923"},
                          {"label": "Shobara", "value": "C62924"},
                          {"label": "Shobara-shi", "value": "C62925"},
                          {"label": "Takehara", "value": "C62926"},
                          {"label": "Takehara-shi", "value": "C62927"}],
 "Ho Chi Minh": [{"label": "Can Gio", "value": "C147428"},
                 {"label": "Cu Chi", "value": "C147429"},
                 {"label": "Ho Chi Minh City", "value": "C147430"}],
 "Hoa Binh": [{"label": "Hoa Binh", "value": "C147431"},
              {"label": "Huyen Cao Phong", "value": "C147432"},
              {"label": "Huyen Ky Son", "value": "C147433"},
              {"label": "Huyen Lac Thuy", "value": "C147434"},
              {"label": "Huyen Luong Son", "value": "C147435"},
              {"label": "Huyen Tan Lac", "value": "C147436"}],
 "Hoce-Slivnica Municipality": [{"label": "Hotinja Vas", "value": "C107632"},
                                {"label": "Radizel", "value": "C107633"},
                                {"label": "Rogoza", "value": "C107634"},
                                {"label": "Spodnje Hoce", "value": "C107635"}],
 "Hodh Ech Chargui": [{"label": "Diade", "value": "C65952"},
                      {"label": "Kataouane", "value": "C65953"},
                      {"label": "Nema", "value": "C65954"},
                      {"label": "Timbedra", "value": "C65955"}],
 "Hodh El Gharbi": [{"label": "Aioun", "value": "C65956"},
                    {"label": "Ayoun el Atrous", "value": "C65957"},
                    {"label": "Togba", "value": "C65958"}],
 "Hodos Municipality": [{"label": "Hodos", "value": "C107636"}],
 "Hokkaido Prefecture": [{"label": "Abashiri", "value": "C62928"},
                         {"label": "Abashiri Shi", "value": "C62929"},
                         {"label": "Akabira", "value": "C62930"},
                         {"label": "Akabira-shi", "value": "C62931"},
                         {"label": "Asahikawa", "value": "C62932"},
                         {"label": "Ashibetsu", "value": "C62933"},
                         {"label": "Ashibetsu-shi", "value": "C62934"},
                         {"label": "Bibai", "value": "C62935"},
                         {"label": "Chitose", "value": "C62936"},
                         {"label": "Chitose Shi", "value": "C62937"},
                         {"label": "Date", "value": "C62938"},
                         {"label": "Date-shi", "value": "C62939"},
                         {"label": "Ebetsu", "value": "C62940"},
                         {"label": "Eniwa-shi", "value": "C62941"},
                         {"label": "Fukagawa", "value": "C62942"},
                         {"label": "Fukagawa-shi", "value": "C62943"},
                         {"label": "Furano-shi", "value": "C62944"},
                         {"label": "Hakodate", "value": "C62945"},
                         {"label": "Hakodate Shi", "value": "C62946"},
                         {"label": "Hokuto", "value": "C62947"},
                         {"label": "Hokuto-shi", "value": "C62948"},
                         {"label": "Honcho", "value": "C62949"},
                         {"label": "Ishikari", "value": "C62950"},
                         {"label": "Ishikari-shi", "value": "C62951"},
                         {"label": "Iwamizawa", "value": "C62952"},
                         {"label": "Iwamizawa-shi", "value": "C62953"},
                         {"label": "Iwanai", "value": "C62954"},
                         {"label": "Kamiiso", "value": "C62955"},
                         {"label": "Kamikawa", "value": "C62956"},
                         {"label": "Kitahiroshima", "value": "C62957"},
                         {"label": "Kitahiroshima-shi", "value": "C62958"},
                         {"label": "Kitami", "value": "C62959"},
                         {"label": "Ktiami Shi", "value": "C62960"},
                         {"label": "Kushiro", "value": "C62961"},
                         {"label": "Kushiro Shi", "value": "C62962"},
                         {"label": "Makubetsu", "value": "C62963"},
                         {"label": "Mikasa", "value": "C62964"},
                         {"label": "Mikasa-shi", "value": "C62965"},
                         {"label": "Mombetsu", "value": "C62966"},
                         {"label": "Monbetsu Shi", "value": "C62967"},
                         {"label": "Motomachi", "value": "C62968"},
                         {"label": "Muroran", "value": "C62969"},
                         {"label": "Muroran-shi", "value": "C62970"},
                         {"label": "Nayoro", "value": "C62971"},
                         {"label": "Nayoro Shi", "value": "C62972"},
                         {"label": "Nemuro", "value": "C62973"},
                         {"label": "Nemuro-shi", "value": "C62974"},
                         {"label": "Niseko Town", "value": "C62975"},
                         {"label": "Noboribetsu", "value": "C62976"},
                         {"label": "Noboribetsu-shi", "value": "C62977"},
                         {"label": "Obihiro", "value": "C62978"},
                         {"label": "Obihiro Shi", "value": "C62979"},
                         {"label": "Otaru", "value": "C62980"},
                         {"label": "Otaru-shi", "value": "C62981"},
                         {"label": "Otofuke", "value": "C62982"},
                         {"label": "Rebun Gun", "value": "C62983"},
                         {"label": "Rishiri Gun", "value": "C62984"},
                         {"label": "Rishiri Town", "value": "C62985"},
                         {"label": "Rumoi", "value": "C62986"},
                         {"label": "Rumoi-shi", "value": "C62987"},
                         {"label": "Sapporo", "value": "C62988"},
                         {"label": "Sapporo-shi", "value": "C62989"},
                         {"label": "Shibetsu", "value": "C62990"},
                         {"label": "Shibetsu Shi", "value": "C62991"},
                         {"label": "Shimo-furano", "value": "C62992"},
                         {"label": "Shiraoi", "value": "C62993"},
                         {"label": "Shizunai-furukawacho", "value": "C62994"},
                         {"label": "Sunagawa", "value": "C62995"},
                         {"label": "Sunagawa-shi", "value": "C62996"},
                         {"label": "Takikawa", "value": "C62997"},
                         {"label": "Takikawa-shi", "value": "C62998"},
                         {"label": "Tobetsu", "value": "C62999"},
                         {"label": "Tomakomai", "value": "C63000"},
                         {"label": "Tomakomai Shi", "value": "C63001"},
                         {"label": "Utashinai", "value": "C63002"},
                         {"label": "Utashinai-shi", "value": "C63003"},
                         {"label": "Wakkanai", "value": "C63004"},
                         {"label": "Wakkanai Shi", "value": "C63005"},
                         {"label": "Yoichi", "value": "C63006"},
                         {"label": "Yubari", "value": "C63007"},
                         {"label": "Yubari-shi", "value": "C63008"}],
 "Holguin Province": [{"label": "Banes", "value": "C21754"},
                      {"label": "Cacocum", "value": "C21755"},
                      {"label": "Cueto", "value": "C21756"},
                      {"label": "Gibara", "value": "C21757"},
                      {"label": "Holguin", "value": "C21758"},
                      {"label": "Jobabo", "value": "C21759"},
                      {"label": "Moa", "value": "C21760"},
                      {"label": "Municipio de Banes", "value": "C21761"},
                      {"label": "Municipio de Holguin", "value": "C21762"},
                      {"label": "Sagua de Tanamo", "value": "C21763"},
                      {"label": "San German", "value": "C21764"}],
 "Homa Bay": [{"label": "Homa Bay", "value": "C64553"},
              {"label": "Oyugis", "value": "C64554"},
              {"label": "Rachuonyo District", "value": "C64555"}],
 "Homs Governorate": [{"label": "Al Ghantu", "value": "C117888"},
                      {"label": "Al Qaryatayn", "value": "C117889"},
                      {"label": "Al Qusayr", "value": "C117890"},
                      {"label": "Al-Rastan District", "value": "C117891"},
                      {"label": "Ar Rastan", "value": "C117892"},
                      {"label": "Hisya", "value": "C117893"},
                      {"label": "Homs", "value": "C117894"},
                      {"label": "Kafr Laha", "value": "C117895"},
                      {"label": "Mukharram al Fawqani", "value": "C117896"},
                      {"label": "Sadad", "value": "C117897"},
                      {"label": "Tadmur", "value": "C117898"},
                      {"label": "Tadmur District", "value": "C117899"},
                      {"label": "Tallbisah", "value": "C117900"},
                      {"label": "Tallkalakh", "value": "C117901"}],
 "Hordaland": [{"label": "Agotnes", "value": "C78758"},
               {"label": "Askoy", "value": "C78759"},
               {"label": "Austevoll", "value": "C78760"},
               {"label": "Austrheim", "value": "C78761"},
               {"label": "Bergen", "value": "C78762"},
               {"label": "Bomlo", "value": "C78763"},
               {"label": "Dale", "value": "C78764"},
               {"label": "Eidfjord", "value": "C78765"},
               {"label": "Eikelandsosen", "value": "C78766"},
               {"label": "Espeland", "value": "C78767"},
               {"label": "Etne", "value": "C78768"},
               {"label": "Fedje", "value": "C78769"},
               {"label": "Fitjar", "value": "C78770"},
               {"label": "Fjell", "value": "C78771"},
               {"label": "Frekhaug", "value": "C78772"},
               {"label": "Fusa", "value": "C78773"},
               {"label": "Granvin", "value": "C78774"},
               {"label": "Hagavik", "value": "C78775"},
               {"label": "Hylkje", "value": "C78776"},
               {"label": "Indre Arna", "value": "C78777"},
               {"label": "Jondal", "value": "C78778"},
               {"label": "Kinsarvik", "value": "C78779"},
               {"label": "Knappskog", "value": "C78780"},
               {"label": "Knarvik", "value": "C78781"},
               {"label": "Kvam", "value": "C78782"},
               {"label": "Kvinnherad", "value": "C78783"},
               {"label": "Leirvik", "value": "C78784"},
               {"label": "Lindas", "value": "C78785"},
               {"label": "Lonevag", "value": "C78786"},
               {"label": "Manger", "value": "C78787"},
               {"label": "Masfjorden", "value": "C78788"},
               {"label": "Meland", "value": "C78789"},
               {"label": "Modalen", "value": "C78790"},
               {"label": "Mosterhamn", "value": "C78791"},
               {"label": "Norheimsund", "value": "C78792"},
               {"label": "Odda", "value": "C78793"},
               {"label": "Os", "value": "C78794"},
               {"label": "Osteroy", "value": "C78795"},
               {"label": "Oygarden", "value": "C78796"},
               {"label": "Oystese", "value": "C78797"},
               {"label": "Radoy", "value": "C78798"},
               {"label": "Rosendal", "value": "C78799"},
               {"label": "Rubbestadneset", "value": "C78800"},
               {"label": "Sagvag", "value": "C78801"},
               {"label": "Samnanger", "value": "C78802"},
               {"label": "Sandsli", "value": "C78803"},
               {"label": "Skogsvagen", "value": "C78804"},
               {"label": "Stord", "value": "C78805"},
               {"label": "Storebo", "value": "C78806"},
               {"label": "Straume", "value": "C78807"},
               {"label": "Sund", "value": "C78808"},
               {"label": "Sveio", "value": "C78809"},
               {"label": "Syfteland", "value": "C78810"},
               {"label": "Tysnes", "value": "C78811"},
               {"label": "Ullensvang", "value": "C78812"},
               {"label": "Ulvik", "value": "C78813"},
               {"label": "Vaksdal", "value": "C78814"},
               {"label": "Voss", "value": "C78815"},
               {"label": "Ytre Arna", "value": "C78816"},
               {"label": "Ytrebygda", "value": "C78817"}],
 "Horjul Municipality": [{"label": "Horjul", "value": "C107637"}],
 "Hormozgan": [{"label": "Bandar Abbas", "value": "C50680"},
               {"label": "Bandar Lengeh", "value": "C50681"},
               {"label": "Bandar-e Lengeh", "value": "C50682"},
               {"label": "Bastak", "value": "C50683"},
               {"label": "Kish", "value": "C50684"},
               {"label": "Minab", "value": "C50685"},
               {"label": "Qeshm", "value": "C50686"},
               {"label": "Shahrestan-e Abu Musa", "value": "C50687"},
               {"label": "Shahrestan-e Bandar Abbas", "value": "C50688"},
               {"label": "Shahrestan-e Bashagard", "value": "C50689"},
               {"label": "Shahrestan-e Hajjiabad", "value": "C50690"},
               {"label": "Shahrestan-e Jask", "value": "C50691"},
               {"label": "Shahrestan-e Khamir", "value": "C50692"},
               {"label": "Shahrestan-e Minab", "value": "C50693"},
               {"label": "Shahrestan-e Parsian", "value": "C50694"},
               {"label": "Shahrestan-e Rudan", "value": "C50695"},
               {"label": "Shahrestan-e Sirik", "value": "C50696"}],
 "Houaphanh Province": [{"label": "Xam Neua", "value": "C64791"},
                        {"label": "Xam Nua", "value": "C64792"}],
 "Hrastnik Municipality": [{"label": "Dol pri Hrastniku", "value": "C107638"},
                           {"label": "Hrastnik", "value": "C107639"}],
 "Hrpelje-Kozina Municipality": [{"label": "Kozina", "value": "C107640"}],
 "Hsinchu": [{"label": "Hsinchu", "value": "C117960"},
             {"label": "Hsinchu County", "value": "C117961"}],
 "Hualien": [{"label": "Hualien", "value": "C117962"},
             {"label": "Hualien City", "value": "C117963"}],
 "Huambo Province": [{"label": "Caala", "value": "C542"},
                     {"label": "Chela", "value": "C543"},
                     {"label": "Huambo", "value": "C544"},
                     {"label": "Longonjo", "value": "C545"}],
 "Huancavelica": [{"label": "Huancavelica", "value": "C80795"},
                  {"label": "Huaytara", "value": "C80796"},
                  {"label": "Pampas", "value": "C80797"},
                  {"label": "Provincia de Acobamba", "value": "C80798"},
                  {"label": "Provincia de Angaraes", "value": "C80799"},
                  {"label": "Provincia de Castrovirreyna", "value": "C80800"},
                  {"label": "Provincia de Churcampa", "value": "C80801"},
                  {"label": "Provincia de Huancavelica", "value": "C80802"},
                  {"label": "Provincia de Tayacaja", "value": "C80803"}],
 "Huanuco": [{"label": "Ambo", "value": "C80804"},
             {"label": "Huacaybamba", "value": "C80805"},
             {"label": "Huanuco", "value": "C80806"},
             {"label": "La Union", "value": "C80807"},
             {"label": "Lauricocha", "value": "C80808"},
             {"label": "Llata", "value": "C80809"},
             {"label": "Provincia de Ambo", "value": "C80810"},
             {"label": "Provincia de Dos de Mayo", "value": "C80811"},
             {"label": "Provincia de Huamalies", "value": "C80812"},
             {"label": "Provincia de Huanuco", "value": "C80813"},
             {"label": "Provincia de Leoncio Prado", "value": "C80814"},
             {"label": "Provincia de Maranon", "value": "C80815"},
             {"label": "Provincia de Pachitea", "value": "C80816"},
             {"label": "Puerto Inca", "value": "C80817"},
             {"label": "San Miguel de Cauri", "value": "C80818"},
             {"label": "Tingo Maria", "value": "C80819"},
             {"label": "Yanacancha", "value": "C80820"},
             {"label": "Yarowilca", "value": "C80821"}],
 "Hubei": [{"label": "Anlu", "value": "C18765"},
           {"label": "Buhe", "value": "C18766"},
           {"label": "Caidian", "value": "C18767"},
           {"label": "Caohe", "value": "C18768"},
           {"label": "Chengzhong", "value": "C18769"},
           {"label": "Danjiangkou", "value": "C18770"},
           {"label": "Daye", "value": "C18771"},
           {"label": "Duobao", "value": "C18772"},
           {"label": "Enshi", "value": "C18773"},
           {"label": "Enshi Tujiazu Miaozu Zizhizhou", "value": "C18774"},
           {"label": "Ezhou", "value": "C18775"},
           {"label": "Ezhou Shi", "value": "C18776"},
           {"label": "Fengkou", "value": "C18777"},
           {"label": "Guangshui", "value": "C18778"},
           {"label": "Gucheng Chengguanzhen", "value": "C18779"},
           {"label": "Hanchuan", "value": "C18780"},
           {"label": "Huanggang", "value": "C18781"},
           {"label": "Huangmei", "value": "C18782"},
           {"label": "Huangpi", "value": "C18783"},
           {"label": "Huangshi", "value": "C18784"},
           {"label": "Huangzhou", "value": "C18785"},
           {"label": "Jingling", "value": "C18786"},
           {"label": "Jingmen", "value": "C18787"},
           {"label": "Jingmen Shi", "value": "C18788"},
           {"label": "Jingzhou", "value": "C18789"},
           {"label": "Laohekou", "value": "C18790"},
           {"label": "Lichuan", "value": "C18791"},
           {"label": "Macheng", "value": "C18792"},
           {"label": "Nanzhang Chengguanzhen", "value": "C18793"},
           {"label": "Puqi", "value": "C18794"},
           {"label": "Qianjiang", "value": "C18795"},
           {"label": "Qingquan", "value": "C18796"},
           {"label": "Shashi", "value": "C18797"},
           {"label": "Shennongjia", "value": "C18798"},
           {"label": "Shiyan", "value": "C18799"},
           {"label": "Suizhou", "value": "C18800"},
           {"label": "Wuhan", "value": "C18801"},
           {"label": "Wuxue", "value": "C18802"},
           {"label": "Xiangyang", "value": "C18803"},
           {"label": "Xianning", "value": "C18804"},
           {"label": "Xianning Prefecture", "value": "C18805"},
           {"label": "Xiantao", "value": "C18806"},
           {"label": "Xiaogan", "value": "C18807"},
           {"label": "Xihe", "value": "C18808"},
           {"label": "Xindi", "value": "C18809"},
           {"label": "Xinshi", "value": "C18810"},
           {"label": "Xinzhou", "value": "C18811"},
           {"label": "Xiulin", "value": "C18812"},
           {"label": "Yichang", "value": "C18813"},
           {"label": "Yicheng", "value": "C18814"},
           {"label": "Yunmeng Chengguanzhen", "value": "C18815"},
           {"label": "Zaoyang", "value": "C18816"},
           {"label": "Zhicheng", "value": "C18817"},
           {"label": "Zhijiang", "value": "C18818"},
           {"label": "Zhongxiang", "value": "C18819"}],
 "Huehuetenango Department": [{"label": "Aguacatan", "value": "C42703"},
                              {"label": "Barillas", "value": "C42704"},
                              {"label": "Chiantla", "value": "C42705"},
                              {"label": "Colotenango", "value": "C42706"},
                              {"label": "Concepcion Huista", "value": "C42707"},
                              {"label": "Cuilco", "value": "C42708"},
                              {"label": "Huehuetenango", "value": "C42709"},
                              {"label": "Ixtahuacan", "value": "C42710"},
                              {"label": "Jacaltenango", "value": "C42711"},
                              {"label": "La Libertad", "value": "C42712"},
                              {"label": "Malacatancito", "value": "C42713"},
                              {"label": "Nenton", "value": "C42714"},
                              {"label": "San Antonio Huista",
                               "value": "C42715"},
                              {"label": "San Gaspar Ixchil", "value": "C42716"},
                              {"label": "San Juan Atitan", "value": "C42717"},
                              {"label": "San Juan Ixcoy", "value": "C42718"},
                              {"label": "San Mateo Ixtatan", "value": "C42719"},
                              {"label": "San Miguel Acatan", "value": "C42720"},
                              {"label": "San Pedro Necta", "value": "C42721"},
                              {"label": "San Rafael La Independencia",
                               "value": "C42722"},
                              {"label": "San Rafael Petzal", "value": "C42723"},
                              {"label": "San Sebastian Coatan",
                               "value": "C42724"},
                              {"label": "San Sebastian Huehuetenango",
                               "value": "C42725"},
                              {"label": "Santa Ana Huista", "value": "C42726"},
                              {"label": "Santa Barbara", "value": "C42727"},
                              {"label": "Santa Eulalia", "value": "C42728"},
                              {"label": "Santiago Chimaltenango",
                               "value": "C42729"},
                              {"label": "Soloma", "value": "C42730"},
                              {"label": "Tectitan", "value": "C42731"},
                              {"label": "Todos Santos Cuchumatan",
                               "value": "C42732"}],
 "Huila": [{"label": "Acevedo", "value": "C20192"},
           {"label": "Aipe", "value": "C20193"},
           {"label": "Algeciras", "value": "C20194"},
           {"label": "Altamira", "value": "C20195"},
           {"label": "Baraya", "value": "C20196"},
           {"label": "Campoalegre", "value": "C20197"},
           {"label": "Colombia", "value": "C20198"},
           {"label": "El Agrado", "value": "C20199"},
           {"label": "Elias", "value": "C20200"},
           {"label": "Garzon", "value": "C20201"},
           {"label": "Gigante", "value": "C20202"},
           {"label": "Guadalupe", "value": "C20203"},
           {"label": "Hobo", "value": "C20204"},
           {"label": "Iquira", "value": "C20205"},
           {"label": "Isnos", "value": "C20206"},
           {"label": "La Argentina", "value": "C20207"},
           {"label": "La Plata", "value": "C20208"},
           {"label": "Nataga", "value": "C20209"},
           {"label": "Neiva", "value": "C20210"},
           {"label": "Oporapa", "value": "C20211"},
           {"label": "Paicol", "value": "C20212"},
           {"label": "Palermo", "value": "C20213"},
           {"label": "Palestina", "value": "C20214"},
           {"label": "Pital", "value": "C20215"},
           {"label": "Pitalito", "value": "C20216"},
           {"label": "Rivera", "value": "C20217"},
           {"label": "Saladoblanco", "value": "C20218"},
           {"label": "San Agustin", "value": "C20219"},
           {"label": "Santa Maria", "value": "C20220"},
           {"label": "Suaza", "value": "C20221"},
           {"label": "Tarqui", "value": "C20222"},
           {"label": "Tello", "value": "C20223"},
           {"label": "Teruel", "value": "C20224"},
           {"label": "Tesalia", "value": "C20225"},
           {"label": "Timana", "value": "C20226"},
           {"label": "Villavieja", "value": "C20227"},
           {"label": "Yaguara", "value": "C20228"}],
 "Huila Province": [{"label": "Caconda", "value": "C546"},
                    {"label": "Caluquembe", "value": "C547"},
                    {"label": "Chibia", "value": "C548"},
                    {"label": "Chicomba", "value": "C549"},
                    {"label": "Chipindo", "value": "C550"},
                    {"label": "Cuvango", "value": "C551"},
                    {"label": "Gambos", "value": "C552"},
                    {"label": "Humpata", "value": "C553"},
                    {"label": "Jamba", "value": "C554"},
                    {"label": "Lubango", "value": "C555"},
                    {"label": "Matala", "value": "C556"},
                    {"label": "Quilengues", "value": "C557"},
                    {"label": "Quipungo", "value": "C558"}],
 "Hunan": [{"label": "Anjiang", "value": "C18820"},
           {"label": "Anping", "value": "C18821"},
           {"label": "Anxiang", "value": "C18822"},
           {"label": "Baisha", "value": "C18823"},
           {"label": "Biyong", "value": "C18824"},
           {"label": "Bojia", "value": "C18825"},
           {"label": "Boyang", "value": "C18826"},
           {"label": "Bozhou", "value": "C18827"},
           {"label": "Changde", "value": "C18828"},
           {"label": "Changsha", "value": "C18829"},
           {"label": "Changsha Shi", "value": "C18830"},
           {"label": "Chatian", "value": "C18831"},
           {"label": "Chenzhou", "value": "C18832"},
           {"label": "Dabaozi", "value": "C18833"},
           {"label": "Dehang", "value": "C18834"},
           {"label": "Dengjiapu", "value": "C18835"},
           {"label": "Dengyuantai", "value": "C18836"},
           {"label": "Dongshan Dongzuxiang", "value": "C18837"},
           {"label": "Fenghuang", "value": "C18838"},
           {"label": "Gangdong", "value": "C18839"},
           {"label": "Gaoqiao", "value": "C18840"},
           {"label": "Gaoyi", "value": "C18841"},
           {"label": "Guankou", "value": "C18842"},
           {"label": "Hengbanqiao", "value": "C18843"},
           {"label": "Hengyang", "value": "C18844"},
           {"label": "Hexiangqiao", "value": "C18845"},
           {"label": "Hongjiang", "value": "C18846"},
           {"label": "Hongqiao", "value": "C18847"},
           {"label": "Huaihua", "value": "C18848"},
           {"label": "Huangjinjing", "value": "C18849"},
           {"label": "Huanglong", "value": "C18850"},
           {"label": "Huangmaoyuan", "value": "C18851"},
           {"label": "Huangqiao", "value": "C18852"},
           {"label": "Huangtukuang", "value": "C18853"},
           {"label": "Huangxikou", "value": "C18854"},
           {"label": "Huaqiao", "value": "C18855"},
           {"label": "Huayuan", "value": "C18856"},
           {"label": "Huomachong", "value": "C18857"},
           {"label": "Jiangfang", "value": "C18858"},
           {"label": "Jiangkouxu", "value": "C18859"},
           {"label": "Jiangshi", "value": "C18860"},
           {"label": "Jinhe", "value": "C18861"},
           {"label": "Jinshi", "value": "C18862"},
           {"label": "Jinshiqiao", "value": "C18863"},
           {"label": "Lanli", "value": "C18864"},
           {"label": "Leiyang", "value": "C18865"},
           {"label": "Lengshuijiang", "value": "C18866"},
           {"label": "Lengshuitan", "value": "C18867"},
           {"label": "Liangyaping", "value": "C18868"},
           {"label": "Lianyuan", "value": "C18869"},
           {"label": "Linkou", "value": "C18870"},
           {"label": "Liuduzhai", "value": "C18871"},
           {"label": "Lixiqiao", "value": "C18872"},
           {"label": "Longtan", "value": "C18873"},
           {"label": "Longtouan", "value": "C18874"},
           {"label": "Loudi", "value": "C18875"},
           {"label": "Luojiu", "value": "C18876"},
           {"label": "Luyang", "value": "C18877"},
           {"label": "Maan", "value": "C18878"},
           {"label": "Malin", "value": "C18879"},
           {"label": "Maoping", "value": "C18880"},
           {"label": "Nanmuping", "value": "C18881"},
           {"label": "Nanzhou", "value": "C18882"},
           {"label": "Prefecture of Chenzhou", "value": "C18883"},
           {"label": "Pukou", "value": "C18884"},
           {"label": "Puzi", "value": "C18885"},
           {"label": "Qiancheng", "value": "C18886"},
           {"label": "Qianzhou", "value": "C18887"},
           {"label": "Qiaojiang", "value": "C18888"},
           {"label": "Qingjiangqiao", "value": "C18889"},
           {"label": "Qingxi", "value": "C18890"},
           {"label": "Qionghu", "value": "C18891"},
           {"label": "Ruoshui", "value": "C18892"},
           {"label": "Shangmei", "value": "C18893"},
           {"label": "Shanmen", "value": "C18894"},
           {"label": "Shijiang", "value": "C18895"},
           {"label": "Shuangjiang", "value": "C18896"},
           {"label": "Shuangxi", "value": "C18897"},
           {"label": "Shuiche", "value": "C18898"},
           {"label": "Shuidatian", "value": "C18899"},
           {"label": "Simenqian", "value": "C18900"},
           {"label": "Tangjiafang", "value": "C18901"},
           {"label": "Tanwan", "value": "C18902"},
           {"label": "Tongwan", "value": "C18903"},
           {"label": "Tuokou", "value": "C18904"},
           {"label": "Wantouqiao", "value": "C18905"},
           {"label": "Wenxing", "value": "C18906"},
           {"label": "Wulingyuan", "value": "C18907"},
           {"label": "Wuxi", "value": "C18908"},
           {"label": "Wuyang", "value": "C18909"},
           {"label": "Xiangtan", "value": "C18910"},
           {"label": "Xiangxi Tujiazu Miaozu Zizhizhou", "value": "C18911"},
           {"label": "Xiangxiang", "value": "C18912"},
           {"label": "Xianrenwan", "value": "C18913"},
           {"label": "Xianxi", "value": "C18914"},
           {"label": "Xiaohenglong", "value": "C18915"},
           {"label": "Xiaolongmen", "value": "C18916"},
           {"label": "Xiaoshajiang", "value": "C18917"},
           {"label": "Xishan", "value": "C18918"},
           {"label": "Xixi", "value": "C18919"},
           {"label": "Xiyan", "value": "C18920"},
           {"label": "Yanmen", "value": "C18921"},
           {"label": "Yaoshi", "value": "C18922"},
           {"label": "Yatunpu", "value": "C18923"},
           {"label": "Yiyang", "value": "C18924"},
           {"label": "Yongfeng", "value": "C18925"},
           {"label": "Yongzhou", "value": "C18926"},
           {"label": "Yueyang", "value": "C18927"},
           {"label": "Yueyang Shi", "value": "C18928"},
           {"label": "Yutan", "value": "C18929"},
           {"label": "Zhaishi Miaozu Dongzuxiang", "value": "C18930"},
           {"label": "Zhangjiajie", "value": "C18931"},
           {"label": "Zhongfang", "value": "C18932"},
           {"label": "Zhongzhai", "value": "C18933"},
           {"label": "Zhushi", "value": "C18934"},
           {"label": "Zhuzhou", "value": "C18935"},
           {"label": "Zhuzhou Shi", "value": "C18936"},
           {"label": "Zhuzhoujiang Miaozuxiang", "value": "C18937"}],
 "Hunedoara County": [{"label": "Aninoasa", "value": "C95889"},
                      {"label": "Bacia", "value": "C95890"},
                      {"label": "Baia de Cris", "value": "C95891"},
                      {"label": "Baita", "value": "C95892"},
                      {"label": "Balsa", "value": "C95893"},
                      {"label": "Banita", "value": "C95894"},
                      {"label": "Baru", "value": "C95895"},
                      {"label": "Baru Mic", "value": "C95896"},
                      {"label": "Barza", "value": "C95897"},
                      {"label": "Batrana", "value": "C95898"},
                      {"label": "Beriu", "value": "C95899"},
                      {"label": "Blajeni", "value": "C95900"},
                      {"label": "Bosorod", "value": "C95901"},
                      {"label": "Brad", "value": "C95902"},
                      {"label": "Branisca", "value": "C95903"},
                      {"label": "Brazi", "value": "C95904"},
                      {"label": "Bretea Romana", "value": "C95905"},
                      {"label": "Buces", "value": "C95906"},
                      {"label": "Bucium-Orlea", "value": "C95907"},
                      {"label": "Bucuresci", "value": "C95908"},
                      {"label": "Bulzestii de Sus", "value": "C95909"},
                      {"label": "Bunila", "value": "C95910"},
                      {"label": "Burjuc", "value": "C95911"},
                      {"label": "Calan", "value": "C95912"},
                      {"label": "Carjiti", "value": "C95913"},
                      {"label": "Castau", "value": "C95914"},
                      {"label": "Cerbal", "value": "C95915"},
                      {"label": "Certeju de Sus", "value": "C95916"},
                      {"label": "Comuna Bacia", "value": "C95917"},
                      {"label": "Comuna Baia de Cris", "value": "C95918"},
                      {"label": "Comuna Baita", "value": "C95919"},
                      {"label": "Comuna Balsa", "value": "C95920"},
                      {"label": "Comuna Banita", "value": "C95921"},
                      {"label": "Comuna Baru", "value": "C95922"},
                      {"label": "Comuna Batrana", "value": "C95923"},
                      {"label": "Comuna Beriu", "value": "C95924"},
                      {"label": "Comuna Blajeni", "value": "C95925"},
                      {"label": "Comuna Bosorod", "value": "C95926"},
                      {"label": "Comuna Branisca", "value": "C95927"},
                      {"label": "Comuna Bretea Romana", "value": "C95928"},
                      {"label": "Comuna Buces", "value": "C95929"},
                      {"label": "Comuna Bucuresci", "value": "C95930"},
                      {"label": "Comuna Bulzestii De Sus", "value": "C95931"},
                      {"label": "Comuna Bunila", "value": "C95932"},
                      {"label": "Comuna Burjuc", "value": "C95933"},
                      {"label": "Comuna Carjiti", "value": "C95934"},
                      {"label": "Comuna Cerbal", "value": "C95935"},
                      {"label": "Comuna Certeju de Sus", "value": "C95936"},
                      {"label": "Comuna Criscior", "value": "C95937"},
                      {"label": "Comuna Densus", "value": "C95938"},
                      {"label": "Comuna Dobra", "value": "C95939"},
                      {"label": "Comuna General Berthelot", "value": "C95940"},
                      {"label": "Comuna Ghelari", "value": "C95941"},
                      {"label": "Comuna Gurasada", "value": "C95942"},
                      {"label": "Comuna Harau", "value": "C95943"},
                      {"label": "Comuna Ilia", "value": "C95944"},
                      {"label": "Comuna Lapugiu De Jos", "value": "C95945"},
                      {"label": "Comuna Lelese", "value": "C95946"},
                      {"label": "Comuna Lunca Cernii De Jos",
                       "value": "C95947"},
                      {"label": "Comuna Luncoiu De Jos", "value": "C95948"},
                      {"label": "Comuna Martinesti", "value": "C95949"},
                      {"label": "Comuna Orastioara de Sus", "value": "C95950"},
                      {"label": "Comuna Pestisu Mic", "value": "C95951"},
                      {"label": "Comuna Pui", "value": "C95952"},
                      {"label": "Comuna Rachitova", "value": "C95953"},
                      {"label": "Comuna Rapoltu Mare", "value": "C95954"},
                      {"label": "Comuna Rau de Mori", "value": "C95955"},
                      {"label": "Comuna Ribita", "value": "C95956"},
                      {"label": "Comuna Romos", "value": "C95957"},
                      {"label": "Comuna Salasu De Sus", "value": "C95958"},
                      {"label": "Comuna Santamaria-Orlea", "value": "C95959"},
                      {"label": "Comuna Sarmizegetusa", "value": "C95960"},
                      {"label": "Comuna Soimus", "value": "C95961"},
                      {"label": "Comuna Teliucu Inferior", "value": "C95962"},
                      {"label": "Comuna Tomesti", "value": "C95963"},
                      {"label": "Comuna Toplita", "value": "C95964"},
                      {"label": "Comuna Totesti", "value": "C95965"},
                      {"label": "Comuna Turdas", "value": "C95966"},
                      {"label": "Comuna Valisoara", "value": "C95967"},
                      {"label": "Comuna Vata De Jos", "value": "C95968"},
                      {"label": "Comuna Vetel", "value": "C95969"},
                      {"label": "Comuna Vorta", "value": "C95970"},
                      {"label": "Comuna Zam", "value": "C95971"},
                      {"label": "Criscior", "value": "C95972"},
                      {"label": "Cristur", "value": "C95973"},
                      {"label": "Densus", "value": "C95974"},
                      {"label": "Deva", "value": "C95975"},
                      {"label": "Dobra", "value": "C95976"},
                      {"label": "General Berthelot", "value": "C95977"},
                      {"label": "Geoagiu", "value": "C95978"},
                      {"label": "Ghelari", "value": "C95979"},
                      {"label": "Gurasada", "value": "C95980"},
                      {"label": "Harau", "value": "C95981"},
                      {"label": "Hartagani", "value": "C95982"},
                      {"label": "Hateg", "value": "C95983"},
                      {"label": "Hunedoara", "value": "C95984"},
                      {"label": "Ilia", "value": "C95985"},
                      {"label": "Iscroni", "value": "C95986"},
                      {"label": "Jiu-Paroseni", "value": "C95987"},
                      {"label": "Lapugiu de Jos", "value": "C95988"},
                      {"label": "Lelese", "value": "C95989"},
                      {"label": "Lunca Cernii de Jos", "value": "C95990"},
                      {"label": "Luncoiu de Jos", "value": "C95991"},
                      {"label": "Lupeni", "value": "C95992"},
                      {"label": "Martinesti", "value": "C95993"},
                      {"label": "Municipiul  Lupeni", "value": "C95994"},
                      {"label": "Municipiul  Vulcan", "value": "C95995"},
                      {"label": "Municipiul Brad", "value": "C95996"},
                      {"label": "Municipiul Deva", "value": "C95997"},
                      {"label": "Municipiul Hunedoara", "value": "C95998"},
                      {"label": "Municipiul Orastie", "value": "C95999"},
                      {"label": "Municipiul Petrosani", "value": "C96000"},
                      {"label": "Oras Aninoasa", "value": "C96001"},
                      {"label": "Oras Calan", "value": "C96002"},
                      {"label": "Oras Geoagiu", "value": "C96003"},
                      {"label": "Oras Hateg", "value": "C96004"},
                      {"label": "Oras Petrila", "value": "C96005"},
                      {"label": "Oras Simeria", "value": "C96006"},
                      {"label": "Oras Uricani", "value": "C96007"},
                      {"label": "Orastie", "value": "C96008"},
                      {"label": "Orastioara de Sus", "value": "C96009"},
                      {"label": "Pestisu Mare", "value": "C96010"},
                      {"label": "Pestisu Mic", "value": "C96011"},
                      {"label": "Petrila", "value": "C96012"},
                      {"label": "Petrosani", "value": "C96013"},
                      {"label": "Pricaz", "value": "C96014"},
                      {"label": "Pui", "value": "C96015"},
                      {"label": "Rachitova", "value": "C96016"},
                      {"label": "Rapoltu Mare", "value": "C96017"},
                      {"label": "Rau de Mori", "value": "C96018"},
                      {"label": "Ribita", "value": "C96019"},
                      {"label": "Romos", "value": "C96020"},
                      {"label": "Salasu de Sus", "value": "C96021"},
                      {"label": "Santamaria-Orlea", "value": "C96022"},
                      {"label": "Sarmizegetusa", "value": "C96023"},
                      {"label": "Simeria", "value": "C96024"},
                      {"label": "Soimus", "value": "C96025"},
                      {"label": "Teliucu Inferior", "value": "C96026"},
                      {"label": "Tomesti", "value": "C96027"},
                      {"label": "Toplita", "value": "C96028"},
                      {"label": "Totesti", "value": "C96029"},
                      {"label": "Turdas", "value": "C96030"},
                      {"label": "Uricani", "value": "C96031"},
                      {"label": "Valisoara", "value": "C96032"},
                      {"label": "Vata de Jos", "value": "C96033"},
                      {"label": "Vetel", "value": "C96034"},
                      {"label": "Vorta", "value": "C96035"},
                      {"label": "Vulcan", "value": "C96036"},
                      {"label": "Zam", "value": "C96037"},
                      {"label": "Zdrapti", "value": "C96038"}],
 "Hung Yen": [{"label": "Hung Yen", "value": "C147437"},
              {"label": "Huyen An Thi", "value": "C147438"},
              {"label": "Huyen Khoai Chau", "value": "C147439"},
              {"label": "Huyen Kim Dong", "value": "C147440"},
              {"label": "Huyen My Hao", "value": "C147441"},
              {"label": "Huyen Phu Cu", "value": "C147442"},
              {"label": "Huyen Tien Lu", "value": "C147443"}],
 "Hyogo Prefecture": [{"label": "Aioi", "value": "C63009"},
                      {"label": "Aioi Shi", "value": "C63010"},
                      {"label": "Akashi", "value": "C63011"},
                      {"label": "Akashi Shi", "value": "C63012"},
                      {"label": "Amagasaki Shi", "value": "C63013"},
                      {"label": "Asago-shi", "value": "C63014"},
                      {"label": "Ashiya", "value": "C63015"},
                      {"label": "Ashiya Shi", "value": "C63016"},
                      {"label": "Awaji Shi", "value": "C63017"},
                      {"label": "Fukura", "value": "C63018"},
                      {"label": "Himeji", "value": "C63019"},
                      {"label": "Himeji Shi", "value": "C63020"},
                      {"label": "Itami", "value": "C63021"},
                      {"label": "Itami Shi", "value": "C63022"},
                      {"label": "Kakogawa Shi", "value": "C63023"},
                      {"label": "Kakogawacho-honmachi", "value": "C63024"},
                      {"label": "Kariya", "value": "C63025"},
                      {"label": "Kasai Shi", "value": "C63026"},
                      {"label": "Kato Shi", "value": "C63027"},
                      {"label": "Kawanishi", "value": "C63028"},
                      {"label": "Kawanishi Shi", "value": "C63029"},
                      {"label": "Kobe", "value": "C63030"},
                      {"label": "Miki", "value": "C63031"},
                      {"label": "Miki Shi", "value": "C63032"},
                      {"label": "Minamiawaji Shi", "value": "C63033"},
                      {"label": "Nishinomiya Shi", "value": "C63034"},
                      {"label": "Nishinomiya-hama", "value": "C63035"},
                      {"label": "Nishiwaki", "value": "C63036"},
                      {"label": "Nishiwaki-shi", "value": "C63037"},
                      {"label": "Ono", "value": "C63038"},
                      {"label": "Ono Shi", "value": "C63039"},
                      {"label": "Sanda Shi", "value": "C63040"},
                      {"label": "Sandacho", "value": "C63041"},
                      {"label": "Sasayama", "value": "C63042"},
                      {"label": "Sasayama-shi", "value": "C63043"},
                      {"label": "Shirahamacho-usazakiminami",
                       "value": "C63044"},
                      {"label": "Shiso-shi", "value": "C63045"},
                      {"label": "Sumoto", "value": "C63046"},
                      {"label": "Sumoto Shi", "value": "C63047"},
                      {"label": "Taka-gun", "value": "C63048"},
                      {"label": "Takarazuka", "value": "C63049"},
                      {"label": "Takarazuka Shi", "value": "C63050"},
                      {"label": "Takasago Shi", "value": "C63051"},
                      {"label": "Tanba-shi", "value": "C63052"},
                      {"label": "Tatsuno-shi", "value": "C63053"},
                      {"label": "Tatsunocho-tominaga", "value": "C63054"},
                      {"label": "Toyooka", "value": "C63055"},
                      {"label": "Toyooka-shi", "value": "C63056"},
                      {"label": "Yabu", "value": "C63057"},
                      {"label": "Yabu-shi", "value": "C63058"},
                      {"label": "Yamazakicho-nakabirose", "value": "C63059"},
                      {"label": "Yashiro", "value": "C63060"},
                      {"label": "Yonedacho Sendo", "value": "C63061"}],
 "Ialomita County": [{"label": "Adancata", "value": "C96039"},
                     {"label": "Albesti", "value": "C96040"},
                     {"label": "Alexeni", "value": "C96041"},
                     {"label": "Amara", "value": "C96042"},
                     {"label": "Andrasesti", "value": "C96043"},
                     {"label": "Armasesti", "value": "C96044"},
                     {"label": "Axintele", "value": "C96045"},
                     {"label": "Balaciu", "value": "C96046"},
                     {"label": "Barbulesti", "value": "C96047"},
                     {"label": "Barcanesti", "value": "C96048"},
                     {"label": "Boranesti", "value": "C96049"},
                     {"label": "Bordusani", "value": "C96050"},
                     {"label": "Brosteni", "value": "C96051"},
                     {"label": "Bucu", "value": "C96052"},
                     {"label": "Buesti", "value": "C96053"},
                     {"label": "Buliga", "value": "C96054"},
                     {"label": "Cazanesti", "value": "C96055"},
                     {"label": "Cegani", "value": "C96056"},
                     {"label": "Ciocarlia", "value": "C96057"},
                     {"label": "Ciochina", "value": "C96058"},
                     {"label": "Ciulnita", "value": "C96059"},
                     {"label": "Cocora", "value": "C96060"},
                     {"label": "Colelia", "value": "C96061"},
                     {"label": "Comuna Adancata", "value": "C96062"},
                     {"label": "Comuna Albesti", "value": "C96063"},
                     {"label": "Comuna Alexeni", "value": "C96064"},
                     {"label": "Comuna Andrasesti", "value": "C96065"},
                     {"label": "Comuna Armasesti", "value": "C96066"},
                     {"label": "Comuna Axintele", "value": "C96067"},
                     {"label": "Comuna Balaciu", "value": "C96068"},
                     {"label": "Comuna Barbulesti", "value": "C96069"},
                     {"label": "Comuna Barcanesti", "value": "C96070"},
                     {"label": "Comuna Boranesti", "value": "C96071"},
                     {"label": "Comuna Bordusani", "value": "C96072"},
                     {"label": "Comuna Bucu", "value": "C96073"},
                     {"label": "Comuna Buesti", "value": "C96074"},
                     {"label": "Comuna Ciocarlia", "value": "C96075"},
                     {"label": "Comuna Ciochina", "value": "C96076"},
                     {"label": "Comuna Ciulnita", "value": "C96077"},
                     {"label": "Comuna Cocora", "value": "C96078"},
                     {"label": "Comuna Colelia", "value": "C96079"},
                     {"label": "Comuna Cosambesti", "value": "C96080"},
                     {"label": "Comuna Cosereni", "value": "C96081"},
                     {"label": "Comuna Dragoesti", "value": "C96082"},
                     {"label": "Comuna Dridu", "value": "C96083"},
                     {"label": "Comuna Facaeni", "value": "C96084"},
                     {"label": "Comuna Garbovi", "value": "C96085"},
                     {"label": "Comuna Gheorghe Doja", "value": "C96086"},
                     {"label": "Comuna Gheorghe Lazar", "value": "C96087"},
                     {"label": "Comuna Giurgeni", "value": "C96088"},
                     {"label": "Comuna Grindu", "value": "C96089"},
                     {"label": "Comuna Grivita", "value": "C96090"},
                     {"label": "Comuna Gura Ialomitei", "value": "C96091"},
                     {"label": "Comuna Ion Roata", "value": "C96092"},
                     {"label": "Comuna Jilavele", "value": "C96093"},
                     {"label": "Comuna Maia", "value": "C96094"},
                     {"label": "Comuna Manasia", "value": "C96095"},
                     {"label": "Comuna Marculesti", "value": "C96096"},
                     {"label": "Comuna Mihail Kogalniceanu", "value": "C96097"},
                     {"label": "Comuna Milosesti", "value": "C96098"},
                     {"label": "Comuna Moldoveni", "value": "C96099"},
                     {"label": "Comuna Movila", "value": "C96100"},
                     {"label": "Comuna Movilita", "value": "C96101"},
                     {"label": "Comuna Munteni Buzau", "value": "C96102"},
                     {"label": "Comuna Ograda", "value": "C96103"},
                     {"label": "Comuna Perieti", "value": "C96104"},
                     {"label": "Comuna Platonesti", "value": "C96105"},
                     {"label": "Comuna Radulesti", "value": "C96106"},
                     {"label": "Comuna Reviga", "value": "C96107"},
                     {"label": "Comuna Rosiori", "value": "C96108"},
                     {"label": "Comuna Salcioara", "value": "C96109"},
                     {"label": "Comuna Sarateni", "value": "C96110"},
                     {"label": "Comuna Saveni", "value": "C96111"},
                     {"label": "Comuna Scanteia", "value": "C96112"},
                     {"label": "Comuna Sfantu Gheorghe", "value": "C96113"},
                     {"label": "Comuna Sintesti", "value": "C96114"},
                     {"label": "Comuna Slobozia", "value": "C96115"},
                     {"label": "Comuna Stelnica", "value": "C96116"},
                     {"label": "Comuna Suditi", "value": "C96117"},
                     {"label": "Comuna Traian", "value": "C96118"},
                     {"label": "Comuna Valea Ciorii", "value": "C96119"},
                     {"label": "Comuna Valea Macrisului", "value": "C96120"},
                     {"label": "Comuna Vladeni", "value": "C96121"},
                     {"label": "Condeesti", "value": "C96122"},
                     {"label": "Cosambesti", "value": "C96123"},
                     {"label": "Cosereni", "value": "C96124"},
                     {"label": "Dragoesti", "value": "C96125"},
                     {"label": "Dragoesti-Snagov", "value": "C96126"},
                     {"label": "Dridu", "value": "C96127"},
                     {"label": "Facaeni", "value": "C96128"},
                     {"label": "Fetesti", "value": "C96129"},
                     {"label": "Fetesti-Gara", "value": "C96130"},
                     {"label": "Fierbinti-Targ", "value": "C96131"},
                     {"label": "Fierbintii de Jos", "value": "C96132"},
                     {"label": "Fierbintii de Sus", "value": "C96133"},
                     {"label": "Garbovi", "value": "C96134"},
                     {"label": "Gheorghe Doja", "value": "C96135"},
                     {"label": "Gheorghe Lazar", "value": "C96136"},
                     {"label": "Giurgeni", "value": "C96137"},
                     {"label": "Grindu", "value": "C96138"},
                     {"label": "Grivita", "value": "C96139"},
                     {"label": "Gura Ialomitei", "value": "C96140"},
                     {"label": "Iazu", "value": "C96141"},
                     {"label": "Ion Roata", "value": "C96142"},
                     {"label": "Jilavele", "value": "C96143"},
                     {"label": "Lacusteni", "value": "C96144"},
                     {"label": "Luciu", "value": "C96145"},
                     {"label": "Maia", "value": "C96146"},
                     {"label": "Malu Rosu", "value": "C96147"},
                     {"label": "Manasia", "value": "C96148"},
                     {"label": "Marculesti", "value": "C96149"},
                     {"label": "Mihail Kogalniceanu", "value": "C96150"},
                     {"label": "Milosesti", "value": "C96151"},
                     {"label": "Moldoveni", "value": "C96152"},
                     {"label": "Movila", "value": "C96153"},
                     {"label": "Movilita", "value": "C96154"},
                     {"label": "Municipiul Fetesti", "value": "C96155"},
                     {"label": "Municipiul Urziceni", "value": "C96156"},
                     {"label": "Munteni Buzau", "value": "C96157"},
                     {"label": "Ograda", "value": "C96158"},
                     {"label": "Oras Amara", "value": "C96159"},
                     {"label": "Oras Cazanesti", "value": "C96160"},
                     {"label": "Oras Fierbinti-Targ", "value": "C96161"},
                     {"label": "Oras Tandarei", "value": "C96162"},
                     {"label": "Patru Frati", "value": "C96163"},
                     {"label": "Perieti", "value": "C96164"},
                     {"label": "Platonesti", "value": "C96165"},
                     {"label": "Radulesti", "value": "C96166"},
                     {"label": "Rasi", "value": "C96167"},
                     {"label": "Reviga", "value": "C96168"},
                     {"label": "Rosiori", "value": "C96169"},
                     {"label": "Rovine", "value": "C96170"},
                     {"label": "Salcioara", "value": "C96171"},
                     {"label": "Sarateni", "value": "C96172"},
                     {"label": "Saveni", "value": "C96173"},
                     {"label": "Scanteia", "value": "C96174"},
                     {"label": "Sfantu Gheorghe", "value": "C96175"},
                     {"label": "Sinesti", "value": "C96176"},
                     {"label": "Slobozia", "value": "C96177"},
                     {"label": "Smirna", "value": "C96178"},
                     {"label": "Stelnica", "value": "C96179"},
                     {"label": "Suditi", "value": "C96180"},
                     {"label": "Tandarei", "value": "C96181"},
                     {"label": "Traian", "value": "C96182"},
                     {"label": "Urziceni", "value": "C96183"},
                     {"label": "Valea Ciorii", "value": "C96184"},
                     {"label": "Valea Macrisului", "value": "C96185"},
                     {"label": "Vladeni", "value": "C96186"},
                     {"label": "Vlasca", "value": "C96187"}],
 "Ialoveni District": [{"label": "Ialoveni", "value": "C75524"}],
 "Iasi County": [{"label": "Alexandru I. Cuza", "value": "C96188"},
                 {"label": "Andrieseni", "value": "C96189"},
                 {"label": "Aroneanu", "value": "C96190"},
                 {"label": "Badeni", "value": "C96191"},
                 {"label": "Bals", "value": "C96192"},
                 {"label": "Baltati", "value": "C96193"},
                 {"label": "Barnova", "value": "C96194"},
                 {"label": "Belcesti", "value": "C96195"},
                 {"label": "Bivolari", "value": "C96196"},
                 {"label": "Bohotin", "value": "C96197"},
                 {"label": "Borosoaia", "value": "C96198"},
                 {"label": "Bosia", "value": "C96199"},
                 {"label": "Boureni", "value": "C96200"},
                 {"label": "Bradicesti", "value": "C96201"},
                 {"label": "Braesti", "value": "C96202"},
                 {"label": "Bratesti", "value": "C96203"},
                 {"label": "Breazu", "value": "C96204"},
                 {"label": "Buda", "value": "C96205"},
                 {"label": "Budai", "value": "C96206"},
                 {"label": "Buhalnita", "value": "C96207"},
                 {"label": "Butea", "value": "C96208"},
                 {"label": "Buznea", "value": "C96209"},
                 {"label": "Carjoaia", "value": "C96210"},
                 {"label": "Carniceni", "value": "C96211"},
                 {"label": "Ceplenita", "value": "C96212"},
                 {"label": "Chiscareni", "value": "C96213"},
                 {"label": "Ciohorani", "value": "C96214"},
                 {"label": "Ciortesti", "value": "C96215"},
                 {"label": "Ciurea", "value": "C96216"},
                 {"label": "Coarnele Caprei", "value": "C96217"},
                 {"label": "Cogeasca", "value": "C96218"},
                 {"label": "Comarna", "value": "C96219"},
                 {"label": "Comuna Alexandru I. Cuza", "value": "C96220"},
                 {"label": "Comuna Andrieseni", "value": "C96221"},
                 {"label": "Comuna Aroneanu", "value": "C96222"},
                 {"label": "Comuna Bals", "value": "C96223"},
                 {"label": "Comuna Baltati", "value": "C96224"},
                 {"label": "Comuna Barnova", "value": "C96225"},
                 {"label": "Comuna Belcesti", "value": "C96226"},
                 {"label": "Comuna Bivolari", "value": "C96227"},
                 {"label": "Comuna Braesti", "value": "C96228"},
                 {"label": "Comuna Butea", "value": "C96229"},
                 {"label": "Comuna Ceplenita", "value": "C96230"},
                 {"label": "Comuna Ciohorani", "value": "C96231"},
                 {"label": "Comuna Ciortesti", "value": "C96232"},
                 {"label": "Comuna Ciurea", "value": "C96233"},
                 {"label": "Comuna Coarnele Caprei", "value": "C96234"},
                 {"label": "Comuna Comarna", "value": "C96235"},
                 {"label": "Comuna Costesti", "value": "C96236"},
                 {"label": "Comuna Costuleni", "value": "C96237"},
                 {"label": "Comuna Cotnari", "value": "C96238"},
                 {"label": "Comuna Cozmesti", "value": "C96239"},
                 {"label": "Comuna Cristesti", "value": "C96240"},
                 {"label": "Comuna Cucuteni", "value": "C96241"},
                 {"label": "Comuna Dagata", "value": "C96242"},
                 {"label": "Comuna Deleni", "value": "C96243"},
                 {"label": "Comuna Dobrovat", "value": "C96244"},
                 {"label": "Comuna Dolhesti", "value": "C96245"},
                 {"label": "Comuna Draguseni", "value": "C96246"},
                 {"label": "Comuna Dumesti", "value": "C96247"},
                 {"label": "Comuna Erbiceni", "value": "C96248"},
                 {"label": "Comuna Fantanele", "value": "C96249"},
                 {"label": "Comuna Focuri", "value": "C96250"},
                 {"label": "Comuna Golaiesti", "value": "C96251"},
                 {"label": "Comuna Gorban", "value": "C96252"},
                 {"label": "Comuna Grajduri", "value": "C96253"},
                 {"label": "Comuna Gropnita", "value": "C96254"},
                 {"label": "Comuna Grozesti", "value": "C96255"},
                 {"label": "Comuna Halaucesti", "value": "C96256"},
                 {"label": "Comuna Harmanesti", "value": "C96257"},
                 {"label": "Comuna Helesteni", "value": "C96258"},
                 {"label": "Comuna Holboca", "value": "C96259"},
                 {"label": "Comuna Horlesti", "value": "C96260"},
                 {"label": "Comuna Ion Neculce", "value": "C96261"},
                 {"label": "Comuna Ipatele", "value": "C96262"},
                 {"label": "Comuna Lespezi", "value": "C96263"},
                 {"label": "Comuna Letcani", "value": "C96264"},
                 {"label": "Comuna Lungani", "value": "C96265"},
                 {"label": "Comuna Madarjac", "value": "C96266"},
                 {"label": "Comuna Mircesti", "value": "C96267"},
                 {"label": "Comuna Mironeasa", "value": "C96268"},
                 {"label": "Comuna Miroslava", "value": "C96269"},
                 {"label": "Comuna Miroslovesti", "value": "C96270"},
                 {"label": "Comuna Mogosesti", "value": "C96271"},
                 {"label": "Comuna Mogosesti-Siret", "value": "C96272"},
                 {"label": "Comuna Mosna", "value": "C96273"},
                 {"label": "Comuna Motca", "value": "C96274"},
                 {"label": "Comuna Movileni", "value": "C96275"},
                 {"label": "Comuna Oteleni", "value": "C96276"},
                 {"label": "Comuna Plugari", "value": "C96277"},
                 {"label": "Comuna Popesti", "value": "C96278"},
                 {"label": "Comuna Popricani", "value": "C96279"},
                 {"label": "Comuna Prisacani", "value": "C96280"},
                 {"label": "Comuna Probota", "value": "C96281"},
                 {"label": "Comuna Rachiteni", "value": "C96282"},
                 {"label": "Comuna Raducaneni", "value": "C96283"},
                 {"label": "Comuna Rediu-Tatar", "value": "C96284"},
                 {"label": "Comuna Romanesti", "value": "C96285"},
                 {"label": "Comuna Roscani", "value": "C96286"},
                 {"label": "Comuna Ruginoasa", "value": "C96287"},
                 {"label": "Comuna Scanteia", "value": "C96288"},
                 {"label": "Comuna Scheia", "value": "C96289"},
                 {"label": "Comuna Schitu-Duca", "value": "C96290"},
                 {"label": "Comuna Scobinti", "value": "C96291"},
                 {"label": "Comuna Sinesti", "value": "C96292"},
                 {"label": "Comuna Sipote", "value": "C96293"},
                 {"label": "Comuna Siretel", "value": "C96294"},
                 {"label": "Comuna Stolniceni-Prajescu", "value": "C96295"},
                 {"label": "Comuna Strunga", "value": "C96296"},
                 {"label": "Comuna Tansa", "value": "C96297"},
                 {"label": "Comuna Tatarusi", "value": "C96298"},
                 {"label": "Comuna Tibana", "value": "C96299"},
                 {"label": "Comuna Tibanesti", "value": "C96300"},
                 {"label": "Comuna Tiganasi", "value": "C96301"},
                 {"label": "Comuna Todiresti", "value": "C96302"},
                 {"label": "Comuna Tomesti", "value": "C96303"},
                 {"label": "Comuna Trifesti", "value": "C96304"},
                 {"label": "Comuna Tutora", "value": "C96305"},
                 {"label": "Comuna Ungheni", "value": "C96306"},
                 {"label": "Comuna Valea Lupului", "value": "C96307"},
                 {"label": "Comuna Valea Seaca", "value": "C96308"},
                 {"label": "Comuna Vanatori", "value": "C96309"},
                 {"label": "Comuna Victoria", "value": "C96310"},
                 {"label": "Comuna Vladeni", "value": "C96311"},
                 {"label": "Comuna Voinesti", "value": "C96312"},
                 {"label": "Contesti", "value": "C96313"},
                 {"label": "Coropceni", "value": "C96314"},
                 {"label": "Costesti", "value": "C96315"},
                 {"label": "Costuleni", "value": "C96316"},
                 {"label": "Cotnari", "value": "C96317"},
                 {"label": "Covasna", "value": "C96318"},
                 {"label": "Cozia", "value": "C96319"},
                 {"label": "Cozmesti", "value": "C96320"},
                 {"label": "Cristesti", "value": "C96321"},
                 {"label": "Crivesti", "value": "C96322"},
                 {"label": "Crucea", "value": "C96323"},
                 {"label": "Cucuteni", "value": "C96324"},
                 {"label": "Dagata", "value": "C96325"},
                 {"label": "Dancu", "value": "C96326"},
                 {"label": "Deleni", "value": "C96327"},
                 {"label": "Dobrovat", "value": "C96328"},
                 {"label": "Dolhesti", "value": "C96329"},
                 {"label": "Domnita", "value": "C96330"},
                 {"label": "Draguseni", "value": "C96331"},
                 {"label": "Dumbrava", "value": "C96332"},
                 {"label": "Dumbravita", "value": "C96333"},
                 {"label": "Dumesti", "value": "C96334"},
                 {"label": "Erbiceni", "value": "C96335"},
                 {"label": "Fantanele", "value": "C96336"},
                 {"label": "Farcaseni", "value": "C96337"},
                 {"label": "Fetesti", "value": "C96338"},
                 {"label": "Focuri", "value": "C96339"},
                 {"label": "Garbesti", "value": "C96340"},
                 {"label": "Glodenii Gandului", "value": "C96341"},
                 {"label": "Goesti", "value": "C96342"},
                 {"label": "Golaiesti", "value": "C96343"},
                 {"label": "Gorban", "value": "C96344"},
                 {"label": "Goruni", "value": "C96345"},
                 {"label": "Grajduri", "value": "C96346"},
                 {"label": "Gropnita", "value": "C96347"},
                 {"label": "Grozesti", "value": "C96348"},
                 {"label": "Halaucesti", "value": "C96349"},
                 {"label": "Harlau", "value": "C96350"},
                 {"label": "Harmaneasa", "value": "C96351"},
                 {"label": "Harmanestii Vechi", "value": "C96352"},
                 {"label": "Hartoape", "value": "C96353"},
                 {"label": "Heci", "value": "C96354"},
                 {"label": "Helesteni", "value": "C96355"},
                 {"label": "Hodora", "value": "C96356"},
                 {"label": "Holboca", "value": "C96357"},
                 {"label": "Horlesti", "value": "C96358"},
                 {"label": "Iasi", "value": "C96359"},
                 {"label": "Iazu Nou", "value": "C96360"},
                 {"label": "Ion Neculce", "value": "C96361"},
                 {"label": "Ipatele", "value": "C96362"},
                 {"label": "Iugani", "value": "C96363"},
                 {"label": "Izvoarele", "value": "C96364"},
                 {"label": "Lespezi", "value": "C96365"},
                 {"label": "Letcani", "value": "C96366"},
                 {"label": "Liteni", "value": "C96367"},
                 {"label": "Lunca", "value": "C96368"},
                 {"label": "Lunca Cetatuii", "value": "C96369"},
                 {"label": "Lungani", "value": "C96370"},
                 {"label": "Madarjac", "value": "C96371"},
                 {"label": "Manastirea", "value": "C96372"},
                 {"label": "Maxut", "value": "C96373"},
                 {"label": "Mircesti", "value": "C96374"},
                 {"label": "Mironeasa", "value": "C96375"},
                 {"label": "Miroslava", "value": "C96376"},
                 {"label": "Miroslovesti", "value": "C96377"},
                 {"label": "Mogosesti", "value": "C96378"},
                 {"label": "Mogosesti-Siret", "value": "C96379"},
                 {"label": "Mosna", "value": "C96380"},
                 {"label": "Motca", "value": "C96381"},
                 {"label": "Movileni", "value": "C96382"},
                 {"label": "Muncelu de Sus", "value": "C96383"},
                 {"label": "Municipiul Iasi", "value": "C96384"},
                 {"label": "Municipiul Pascani", "value": "C96385"},
                 {"label": "Munteni", "value": "C96386"},
                 {"label": "Oras Harlau", "value": "C96387"},
                 {"label": "Oras Podu Iloaiei", "value": "C96388"},
                 {"label": "Oras Targu Frumos", "value": "C96389"},
                 {"label": "Osoi", "value": "C96390"},
                 {"label": "Oteleni", "value": "C96391"},
                 {"label": "Parcovaci", "value": "C96392"},
                 {"label": "Pascani", "value": "C96393"},
                 {"label": "Pausesti", "value": "C96394"},
                 {"label": "Perieni", "value": "C96395"},
                 {"label": "Pietrosu", "value": "C96396"},
                 {"label": "Plugari", "value": "C96397"},
                 {"label": "Podolenii de Sus", "value": "C96398"},
                 {"label": "Podu Iloaiei", "value": "C96399"},
                 {"label": "Poiana", "value": "C96400"},
                 {"label": "Poiana Marului", "value": "C96401"},
                 {"label": "Poienile", "value": "C96402"},
                 {"label": "Popesti", "value": "C96403"},
                 {"label": "Popricani", "value": "C96404"},
                 {"label": "Prisacani", "value": "C96405"},
                 {"label": "Probota", "value": "C96406"},
                 {"label": "Rachiteni", "value": "C96407"},
                 {"label": "Raducaneni", "value": "C96408"},
                 {"label": "Razboieni", "value": "C96409"},
                 {"label": "Rediu", "value": "C96410"},
                 {"label": "Romanesti", "value": "C96411"},
                 {"label": "Roscani", "value": "C96412"},
                 {"label": "Ruginoasa", "value": "C96413"},
                 {"label": "Satu Nou", "value": "C96414"},
                 {"label": "Scanteia", "value": "C96415"},
                 {"label": "Scheia", "value": "C96416"},
                 {"label": "Schitu-Duca", "value": "C96417"},
                 {"label": "Scobinti", "value": "C96418"},
                 {"label": "Sinesti", "value": "C96419"},
                 {"label": "Sipote", "value": "C96420"},
                 {"label": "Siretel", "value": "C96421"},
                 {"label": "Slobozia", "value": "C96422"},
                 {"label": "Soci", "value": "C96423"},
                 {"label": "Sodomeni", "value": "C96424"},
                 {"label": "Sticlaria", "value": "C96425"},
                 {"label": "Stolniceni-Prajescu", "value": "C96426"},
                 {"label": "Stornesti", "value": "C96427"},
                 {"label": "Strunga", "value": "C96428"},
                 {"label": "Suhulet", "value": "C96429"},
                 {"label": "Tabara", "value": "C96430"},
                 {"label": "Tansa", "value": "C96431"},
                 {"label": "Targu Frumos", "value": "C96432"},
                 {"label": "Tatarusi", "value": "C96433"},
                 {"label": "Tibana", "value": "C96434"},
                 {"label": "Tibanesti", "value": "C96435"},
                 {"label": "Tiganasi", "value": "C96436"},
                 {"label": "Todiresti", "value": "C96437"},
                 {"label": "Tomesti", "value": "C96438"},
                 {"label": "Topile", "value": "C96439"},
                 {"label": "Totoesti", "value": "C96440"},
                 {"label": "Trifesti", "value": "C96441"},
                 {"label": "Tutora", "value": "C96442"},
                 {"label": "Uda", "value": "C96443"},
                 {"label": "Valea Lupului", "value": "C96444"},
                 {"label": "Valea Seaca", "value": "C96445"},
                 {"label": "Vanatori", "value": "C96446"},
                 {"label": "Verseni", "value": "C96447"},
                 {"label": "Victoria", "value": "C96448"},
                 {"label": "Vladeni", "value": "C96449"},
                 {"label": "Voinesti", "value": "C96450"},
                 {"label": "Zagavia", "value": "C96451"},
                 {"label": "Zmeu", "value": "C96452"}],
 "Ibaraki Prefecture": [{"label": "Ami", "value": "C63062"},
                        {"label": "Bando", "value": "C63063"},
                        {"label": "Bando-shi", "value": "C63064"},
                        {"label": "Chikusei", "value": "C63065"},
                        {"label": "Chikusei-shi", "value": "C63066"},
                        {"label": "Daigo", "value": "C63067"},
                        {"label": "Edosaki", "value": "C63068"},
                        {"label": "Fujishiro", "value": "C63069"},
                        {"label": "Funaishikawa", "value": "C63070"},
                        {"label": "Hitachi", "value": "C63071"},
                        {"label": "Hitachi-Naka", "value": "C63072"},
                        {"label": "Hitachi-ota", "value": "C63073"},
                        {"label": "Hitachi-shi", "value": "C63074"},
                        {"label": "Hitachinaka-shi", "value": "C63075"},
                        {"label": "Hitachiomiya-shi", "value": "C63076"},
                        {"label": "Hitachiota-shi", "value": "C63077"},
                        {"label": "Hokota-shi", "value": "C63078"},
                        {"label": "Inashiki", "value": "C63079"},
                        {"label": "Inashiki-shi", "value": "C63080"},
                        {"label": "Ishige", "value": "C63081"},
                        {"label": "Ishioka", "value": "C63082"},
                        {"label": "Ishioka-shi", "value": "C63083"},
                        {"label": "Itako", "value": "C63084"},
                        {"label": "Itako-shi", "value": "C63085"},
                        {"label": "Iwai", "value": "C63086"},
                        {"label": "Iwase", "value": "C63087"},
                        {"label": "Joso-shi", "value": "C63088"},
                        {"label": "Kamisu-shi", "value": "C63089"},
                        {"label": "Kasama", "value": "C63090"},
                        {"label": "Kasama-shi", "value": "C63091"},
                        {"label": "Kashima-shi", "value": "C63092"},
                        {"label": "Kasumigaura", "value": "C63093"},
                        {"label": "Kasumigaura-shi", "value": "C63094"},
                        {"label": "Katsuta", "value": "C63095"},
                        {"label": "Kitaibaraki", "value": "C63096"},
                        {"label": "Kitaibaraki-shi", "value": "C63097"},
                        {"label": "Koga", "value": "C63098"},
                        {"label": "Koga-shi", "value": "C63099"},
                        {"label": "Makabe", "value": "C63100"},
                        {"label": "Mito", "value": "C63101"},
                        {"label": "Mito-shi", "value": "C63102"},
                        {"label": "Mitsukaido", "value": "C63103"},
                        {"label": "Moriya", "value": "C63104"},
                        {"label": "Moriya-shi", "value": "C63105"},
                        {"label": "Naka", "value": "C63106"},
                        {"label": "Naka-gun", "value": "C63107"},
                        {"label": "Namegata", "value": "C63108"},
                        {"label": "Namegata-shi", "value": "C63109"},
                        {"label": "Oarai", "value": "C63110"},
                        {"label": "Okunoya", "value": "C63111"},
                        {"label": "Omitama-shi", "value": "C63112"},
                        {"label": "Omiya", "value": "C63113"},
                        {"label": "Ryugasaki", "value": "C63114"},
                        {"label": "Ryugasaki-shi", "value": "C63115"},
                        {"label": "Sakai", "value": "C63116"},
                        {"label": "Sakuragawa", "value": "C63117"},
                        {"label": "Sakuragawa-shi", "value": "C63118"},
                        {"label": "Shimodate", "value": "C63119"},
                        {"label": "Shimotsuma-shi", "value": "C63120"},
                        {"label": "Takahagi", "value": "C63121"},
                        {"label": "Tomobe", "value": "C63122"},
                        {"label": "Toride", "value": "C63123"},
                        {"label": "Toride-shi", "value": "C63124"},
                        {"label": "Tsuchiura-shi", "value": "C63125"},
                        {"label": "Tsukuba", "value": "C63126"},
                        {"label": "Tsukuba-shi", "value": "C63127"},
                        {"label": "Tsukubamirai", "value": "C63128"},
                        {"label": "Tsukubamirai-shi", "value": "C63129"},
                        {"label": "Ushiku", "value": "C63130"},
                        {"label": "Ushiku-shi", "value": "C63131"},
                        {"label": "Yuki", "value": "C63132"},
                        {"label": "Yuki-shi", "value": "C63133"}],
 "Ibb Governorate": [{"label": "Al Udayn", "value": "C147943"},
                     {"label": "Al Dhihar", "value": "C147944"},
                     {"label": "Al Makhadir", "value": "C147945"},
                     {"label": "Al Mashannah", "value": "C147946"},
                     {"label": "Al Qafr", "value": "C147947"},
                     {"label": "An Nadirah", "value": "C147948"},
                     {"label": "Ar Radmah", "value": "C147949"},
                     {"label": "As Sabrah", "value": "C147950"},
                     {"label": "As Saddah", "value": "C147951"},
                     {"label": "As Sayyani", "value": "C147952"},
                     {"label": "Ash Shair", "value": "C147953"},
                     {"label": "Badan", "value": "C147954"},
                     {"label": "Dhi as Sufal", "value": "C147955"},
                     {"label": "Far Al Udayn", "value": "C147956"},
                     {"label": "Hazm Al Udayn", "value": "C147957"},
                     {"label": "Hubaysh", "value": "C147958"},
                     {"label": "Ibb", "value": "C147959"},
                     {"label": "Jiblah", "value": "C147960"},
                     {"label": "Mudhaykhirah", "value": "C147961"},
                     {"label": "Yarim", "value": "C147962"}],
 "Ica": [{"label": "Chincha Alta", "value": "C80822"},
         {"label": "Ica", "value": "C80823"},
         {"label": "Los Aquijes", "value": "C80824"},
         {"label": "Minas de Marcona", "value": "C80825"},
         {"label": "Nazca", "value": "C80826"},
         {"label": "Palpa", "value": "C80827"},
         {"label": "Paracas", "value": "C80828"},
         {"label": "Pisco", "value": "C80829"},
         {"label": "Provincia de Chincha", "value": "C80830"},
         {"label": "Provincia de Ica", "value": "C80831"},
         {"label": "Provincia de Nazca", "value": "C80832"},
         {"label": "Provincia de Palpa", "value": "C80833"},
         {"label": "Provincia de Pisco", "value": "C80834"},
         {"label": "Rio Grande", "value": "C80835"},
         {"label": "San Clemente", "value": "C80836"},
         {"label": "San Juan Bautista", "value": "C80837"},
         {"label": "Santiago", "value": "C80838"},
         {"label": "Subtanjalla", "value": "C80839"},
         {"label": "Villa Tupac Amaru", "value": "C80840"}],
 "Ida-Viru County": [{"label": "Iisaku", "value": "C24535"},
                     {"label": "Johvi", "value": "C24536"},
                     {"label": "Johvi vald", "value": "C24537"},
                     {"label": "Kivioli", "value": "C24538"},
                     {"label": "Kohtla-Jarve", "value": "C24539"},
                     {"label": "Kohtla-Nomme", "value": "C24540"},
                     {"label": "Luganuse vald", "value": "C24541"},
                     {"label": "Narva", "value": "C24542"},
                     {"label": "Narva-Joesuu", "value": "C24543"},
                     {"label": "Narva-Joesuu linn", "value": "C24544"},
                     {"label": "Pussi", "value": "C24545"},
                     {"label": "Sillamae", "value": "C24546"},
                     {"label": "Toila", "value": "C24547"},
                     {"label": "Voka", "value": "C24548"}],
 "Idaho": [{"label": "Aberdeen", "value": "C131154"},
           {"label": "Ada County", "value": "C131155"},
           {"label": "Adams County", "value": "C131156"},
           {"label": "American Falls", "value": "C131157"},
           {"label": "Ammon", "value": "C131158"},
           {"label": "Arco", "value": "C131159"},
           {"label": "Ashton", "value": "C131160"},
           {"label": "Bannock County", "value": "C131161"},
           {"label": "Bear Lake County", "value": "C131162"},
           {"label": "Bellevue", "value": "C131163"},
           {"label": "Benewah County", "value": "C131164"},
           {"label": "Bingham County", "value": "C131165"},
           {"label": "Blackfoot", "value": "C131166"},
           {"label": "Blaine County", "value": "C131167"},
           {"label": "Boise", "value": "C131168"},
           {"label": "Boise County", "value": "C131169"},
           {"label": "Bonner County", "value": "C131170"},
           {"label": "Bonners Ferry", "value": "C131171"},
           {"label": "Bonneville County", "value": "C131172"},
           {"label": "Boundary County", "value": "C131173"},
           {"label": "Buhl", "value": "C131174"},
           {"label": "Burley", "value": "C131175"},
           {"label": "Butte County", "value": "C131176"},
           {"label": "Caldwell", "value": "C131177"},
           {"label": "Camas County", "value": "C131178"},
           {"label": "Canyon County", "value": "C131179"},
           {"label": "Caribou County", "value": "C131180"},
           {"label": "Cascade", "value": "C131181"},
           {"label": "Cassia County", "value": "C131182"},
           {"label": "Challis", "value": "C131183"},
           {"label": "Chubbuck", "value": "C131184"},
           {"label": "Clark County", "value": "C131185"},
           {"label": "Clearwater County", "value": "C131186"},
           {"label": "Coeur dAlene", "value": "C131187"},
           {"label": "Council", "value": "C131188"},
           {"label": "Custer County", "value": "C131189"},
           {"label": "Dalton Gardens", "value": "C131190"},
           {"label": "Driggs", "value": "C131191"},
           {"label": "Dubois", "value": "C131192"},
           {"label": "Eagle", "value": "C131193"},
           {"label": "Elmore County", "value": "C131194"},
           {"label": "Emmett", "value": "C131195"},
           {"label": "Fairfield", "value": "C131196"},
           {"label": "Filer", "value": "C131197"},
           {"label": "Fort Hall", "value": "C131198"},
           {"label": "Franklin County", "value": "C131199"},
           {"label": "Fremont County", "value": "C131200"},
           {"label": "Fruitland", "value": "C131201"},
           {"label": "Garden City", "value": "C131202"},
           {"label": "Gem County", "value": "C131203"},
           {"label": "Glenns Ferry", "value": "C131204"},
           {"label": "Gooding", "value": "C131205"},
           {"label": "Gooding County", "value": "C131206"},
           {"label": "Grangeville", "value": "C131207"},
           {"label": "Hailey", "value": "C131208"},
           {"label": "Hansen", "value": "C131209"},
           {"label": "Hayden", "value": "C131210"},
           {"label": "Heyburn", "value": "C131211"},
           {"label": "Hidden Spring", "value": "C131212"},
           {"label": "Homedale", "value": "C131213"},
           {"label": "Idaho City", "value": "C131214"},
           {"label": "Idaho County", "value": "C131215"},
           {"label": "Idaho Falls", "value": "C131216"},
           {"label": "Iona", "value": "C131217"},
           {"label": "Jefferson County", "value": "C131218"},
           {"label": "Jerome", "value": "C131219"},
           {"label": "Jerome County", "value": "C131220"},
           {"label": "Kamiah", "value": "C131221"},
           {"label": "Kellogg", "value": "C131222"},
           {"label": "Ketchum", "value": "C131223"},
           {"label": "Kimberly", "value": "C131224"},
           {"label": "Kootenai County", "value": "C131225"},
           {"label": "Kuna", "value": "C131226"},
           {"label": "Lapwai", "value": "C131227"},
           {"label": "Latah County", "value": "C131228"},
           {"label": "Lemhi County", "value": "C131229"},
           {"label": "Lewis County", "value": "C131230"},
           {"label": "Lewiston", "value": "C131231"},
           {"label": "Lewiston Orchards", "value": "C131232"},
           {"label": "Lincoln", "value": "C131233"},
           {"label": "Lincoln County", "value": "C131234"},
           {"label": "Madison County", "value": "C131235"},
           {"label": "Malad City", "value": "C131236"},
           {"label": "Marsing", "value": "C131237"},
           {"label": "McCall", "value": "C131238"},
           {"label": "Meridian", "value": "C131239"},
           {"label": "Middleton", "value": "C131240"},
           {"label": "Minidoka County", "value": "C131241"},
           {"label": "Montpelier", "value": "C131242"},
           {"label": "Moreland", "value": "C131243"},
           {"label": "Moscow", "value": "C131244"},
           {"label": "Mountain Home", "value": "C131245"},
           {"label": "Murphy", "value": "C131246"},
           {"label": "Nampa", "value": "C131247"},
           {"label": "New Plymouth", "value": "C131248"},
           {"label": "Nez Perce County", "value": "C131249"},
           {"label": "Nezperce", "value": "C131250"},
           {"label": "Oneida County", "value": "C131251"},
           {"label": "Orofino", "value": "C131252"},
           {"label": "Osburn", "value": "C131253"},
           {"label": "Owyhee County", "value": "C131254"},
           {"label": "Paris", "value": "C131255"},
           {"label": "Parma", "value": "C131256"},
           {"label": "Paul", "value": "C131257"},
           {"label": "Payette", "value": "C131258"},
           {"label": "Payette County", "value": "C131259"},
           {"label": "Pinehurst", "value": "C131260"},
           {"label": "Plummer", "value": "C131261"},
           {"label": "Pocatello", "value": "C131262"},
           {"label": "Ponderay", "value": "C131263"},
           {"label": "Post Falls", "value": "C131264"},
           {"label": "Power County", "value": "C131265"},
           {"label": "Preston", "value": "C131266"},
           {"label": "Priest River", "value": "C131267"},
           {"label": "Rathdrum", "value": "C131268"},
           {"label": "Rexburg", "value": "C131269"},
           {"label": "Rigby", "value": "C131270"},
           {"label": "Rupert", "value": "C131271"},
           {"label": "Saint Anthony", "value": "C131272"},
           {"label": "Saint Maries", "value": "C131273"},
           {"label": "Salmon", "value": "C131274"},
           {"label": "Sandpoint", "value": "C131275"},
           {"label": "Shelley", "value": "C131276"},
           {"label": "Shoshone", "value": "C131277"},
           {"label": "Shoshone County", "value": "C131278"},
           {"label": "Soda Springs", "value": "C131279"},
           {"label": "Spirit Lake", "value": "C131280"},
           {"label": "Star", "value": "C131281"},
           {"label": "Sugar City", "value": "C131282"},
           {"label": "Sun Valley", "value": "C131283"},
           {"label": "Teton County", "value": "C131284"},
           {"label": "Twin Falls", "value": "C131285"},
           {"label": "Twin Falls County", "value": "C131286"},
           {"label": "Tyhee", "value": "C131287"},
           {"label": "Ucon", "value": "C131288"},
           {"label": "Valley County", "value": "C131289"},
           {"label": "Victor", "value": "C131290"},
           {"label": "Wallace", "value": "C131291"},
           {"label": "Washington County", "value": "C131292"},
           {"label": "Weiser", "value": "C131293"},
           {"label": "Wendell", "value": "C131294"},
           {"label": "Wilder", "value": "C131295"}],
 "Idlib Governorate": [{"label": "Ad Dana", "value": "C117902"},
                       {"label": "Ariha", "value": "C117903"},
                       {"label": "Armanaz", "value": "C117904"},
                       {"label": "Binnish", "value": "C117905"},
                       {"label": "Darkush", "value": "C117906"},
                       {"label": "Harem District", "value": "C117907"},
                       {"label": "Harim", "value": "C117908"},
                       {"label": "Idlib", "value": "C117909"},
                       {"label": "Jisr al-Shughur District",
                        "value": "C117910"},
                       {"label": "Jisr ash Shughur", "value": "C117911"},
                       {"label": "Kafr Takharim", "value": "C117912"},
                       {"label": "Kafranbel", "value": "C117913"},
                       {"label": "Khan Shaykhun", "value": "C117914"},
                       {"label": "Maarratmisrin", "value": "C117915"},
                       {"label": "Maarrat al-Numan District",
                        "value": "C117916"},
                       {"label": "Salqin", "value": "C117917"},
                       {"label": "Saraqib", "value": "C117918"},
                       {"label": "Sarmin", "value": "C117919"},
                       {"label": "Taftanaz", "value": "C117920"}],
 "Idrija Municipality": [{"label": "Idrija", "value": "C107641"},
                         {"label": "Spodnja Idrija", "value": "C107642"}],
 "Iecava Municipality": [{"label": "Iecava", "value": "C64870"}],
 "Ig Municipality": [{"label": "Ig", "value": "C107643"}],
 "Igdir": [{"label": "Igdir", "value": "C120404"},
           {"label": "Karakoyunlu", "value": "C120405"},
           {"label": "Tuzluca Ilcesi", "value": "C120406"}],
 "Ijuw District": [{"label": "Ijuw", "value": "C76015"}],
 "Iklin": [{"label": "L-Iklin", "value": "C65886"}],
 "Ikskile Municipality": [{"label": "Ikskile", "value": "C64871"}],
 "Ilam": [{"label": "abdanan", "value": "C50697"},
          {"label": "Badreh", "value": "C50698"},
          {"label": "Chardavol County", "value": "C50699"},
          {"label": "Darreh Shahr", "value": "C50700"},
          {"label": "Darrehshahr", "value": "C50701"},
          {"label": "Dehloran", "value": "C50702"},
          {"label": "ilam", "value": "C50703"},
          {"label": "Mehran", "value": "C50704"},
          {"label": "Shahrestan-e abdanan", "value": "C50705"},
          {"label": "Shahrestan-e Dehloran", "value": "C50706"},
          {"label": "Shahrestan-e Eyvan", "value": "C50707"},
          {"label": "Shahrestan-e ilam", "value": "C50708"},
          {"label": "Shahrestan-e Malekshahi", "value": "C50709"},
          {"label": "Shahrestan-e Mehran", "value": "C50710"},
          {"label": "Sirvan", "value": "C50711"}],
 "Ile-de-France": [{"label": "Ableiges", "value": "C29910"},
                   {"label": "Ablis", "value": "C29911"},
                   {"label": "Ablon-sur-Seine", "value": "C29912"},
                   {"label": "Acheres", "value": "C29913"},
                   {"label": "Acheres-la-Foret", "value": "C29914"},
                   {"label": "Alfortville", "value": "C29915"},
                   {"label": "Andilly", "value": "C29916"},
                   {"label": "Andresy", "value": "C29917"},
                   {"label": "Angerville", "value": "C29918"},
                   {"label": "Angervilliers", "value": "C29919"},
                   {"label": "Annet-sur-Marne", "value": "C29920"},
                   {"label": "Antony", "value": "C29921"},
                   {"label": "Arbonne-la-Foret", "value": "C29922"},
                   {"label": "Arcueil", "value": "C29923"},
                   {"label": "Argenteuil", "value": "C29924"},
                   {"label": "Armentieres-en-Brie", "value": "C29925"},
                   {"label": "Arnouville", "value": "C29926"},
                   {"label": "Arpajon", "value": "C29927"},
                   {"label": "Asnieres-sur-Oise", "value": "C29928"},
                   {"label": "Asnieres-sur-Seine", "value": "C29929"},
                   {"label": "Athis-Mons", "value": "C29930"},
                   {"label": "Attainville", "value": "C29931"},
                   {"label": "Aubergenville", "value": "C29932"},
                   {"label": "Aubervilliers", "value": "C29933"},
                   {"label": "Auffargis", "value": "C29934"},
                   {"label": "Aulnay-sous-Bois", "value": "C29935"},
                   {"label": "Aulnay-sur-Mauldre", "value": "C29936"},
                   {"label": "Auvers-Saint-Georges", "value": "C29937"},
                   {"label": "Auvers-sur-Oise", "value": "C29938"},
                   {"label": "Avon", "value": "C29939"},
                   {"label": "Bagneaux-sur-Loing", "value": "C29940"},
                   {"label": "Bagneux", "value": "C29941"},
                   {"label": "Bagnolet", "value": "C29942"},
                   {"label": "Baillet-en-France", "value": "C29943"},
                   {"label": "Bailly", "value": "C29944"},
                   {"label": "Bailly-Carrois", "value": "C29945"},
                   {"label": "Bailly-Romainvilliers", "value": "C29946"},
                   {"label": "Ballainvilliers", "value": "C29947"},
                   {"label": "Ballancourt-sur-Essonne", "value": "C29948"},
                   {"label": "Barbizon", "value": "C29949"},
                   {"label": "Baulne", "value": "C29950"},
                   {"label": "Bazainville", "value": "C29951"},
                   {"label": "Bazemont", "value": "C29952"},
                   {"label": "Beauchamp", "value": "C29953"},
                   {"label": "Beaumont-du-Gatinais", "value": "C29954"},
                   {"label": "Beaumont-sur-Oise", "value": "C29955"},
                   {"label": "Belloy-en-France", "value": "C29956"},
                   {"label": "Bennecourt", "value": "C29957"},
                   {"label": "Bernes-sur-Oise", "value": "C29958"},
                   {"label": "Bessancourt", "value": "C29959"},
                   {"label": "Beynes", "value": "C29960"},
                   {"label": "Bezons", "value": "C29961"},
                   {"label": "Bievres", "value": "C29962"},
                   {"label": "Bobigny", "value": "C29963"},
                   {"label": "Bois-Colombes", "value": "C29964"},
                   {"label": "Bois-dArcy", "value": "C29965"},
                   {"label": "Bois-le-Roi", "value": "C29966"},
                   {"label": "Boissise-le-Roi", "value": "C29967"},
                   {"label": "Boissy-le-Chatel", "value": "C29968"},
                   {"label": "Boissy-le-Cutte", "value": "C29969"},
                   {"label": "Boissy-Saint-Leger", "value": "C29970"},
                   {"label": "Boissy-sous-Saint-Yon", "value": "C29971"},
                   {"label": "Bondoufle", "value": "C29972"},
                   {"label": "Bondy", "value": "C29973"},
                   {"label": "Bonnelles", "value": "C29974"},
                   {"label": "Bonneuil-sur-Marne", "value": "C29975"},
                   {"label": "Bonnieres-sur-Seine", "value": "C29976"},
                   {"label": "Bouafle", "value": "C29977"},
                   {"label": "Bouffemont", "value": "C29978"},
                   {"label": "Bougival", "value": "C29979"},
                   {"label": "Bouleurs", "value": "C29980"},
                   {"label": "Boulogne-Billancourt", "value": "C29981"},
                   {"label": "Bouray-sur-Juine", "value": "C29982"},
                   {"label": "Bourg-la-Reine", "value": "C29983"},
                   {"label": "Bourron-Marlotte", "value": "C29984"},
                   {"label": "Boussy-Saint-Antoine", "value": "C29985"},
                   {"label": "Boutigny-sur-Essonne", "value": "C29986"},
                   {"label": "Bray-sur-Seine", "value": "C29987"},
                   {"label": "Bretigny-sur-Orge", "value": "C29988"},
                   {"label": "Breuillet", "value": "C29989"},
                   {"label": "Breval", "value": "C29990"},
                   {"label": "Brie-Comte-Robert", "value": "C29991"},
                   {"label": "Briis-sous-Forges", "value": "C29992"},
                   {"label": "Brou-sur-Chantereine", "value": "C29993"},
                   {"label": "Brunoy", "value": "C29994"},
                   {"label": "Bruyeres-le-Chatel", "value": "C29995"},
                   {"label": "Bruyeres-sur-Oise", "value": "C29996"},
                   {"label": "Bry-sur-Marne", "value": "C29997"},
                   {"label": "Buc", "value": "C29998"},
                   {"label": "Buchelay", "value": "C29999"},
                   {"label": "Bullion", "value": "C30000"},
                   {"label": "Bures-sur-Yvette", "value": "C30001"},
                   {"label": "Bussy-Saint-Georges", "value": "C30002"},
                   {"label": "Butry-sur-Oise", "value": "C30003"},
                   {"label": "Cachan", "value": "C30004"},
                   {"label": "Cannes-ecluse", "value": "C30005"},
                   {"label": "Carrieres-sous-Poissy", "value": "C30006"},
                   {"label": "Carrieres-sur-Seine", "value": "C30007"},
                   {"label": "Cely", "value": "C30008"},
                   {"label": "Cergy", "value": "C30009"},
                   {"label": "Cergy-Pontoise", "value": "C30010"},
                   {"label": "Cernay-la-Ville", "value": "C30011"},
                   {"label": "Cerny", "value": "C30012"},
                   {"label": "Cesson", "value": "C30013"},
                   {"label": "Chailly-en-Biere", "value": "C30014"},
                   {"label": "Chailly-en-Brie", "value": "C30015"},
                   {"label": "Chalifert", "value": "C30016"},
                   {"label": "Chalo-Saint-Mars", "value": "C30017"},
                   {"label": "Chamarande", "value": "C30018"},
                   {"label": "Chambourcy", "value": "C30019"},
                   {"label": "Chamigny", "value": "C30020"},
                   {"label": "Champagne-sur-Oise", "value": "C30021"},
                   {"label": "Champagne-sur-Seine", "value": "C30022"},
                   {"label": "Champcueil", "value": "C30023"},
                   {"label": "Champigny-sur-Marne", "value": "C30024"},
                   {"label": "Champlan", "value": "C30025"},
                   {"label": "Champs-sur-Marne", "value": "C30026"},
                   {"label": "Changis-sur-Marne", "value": "C30027"},
                   {"label": "Chanteloup-en-Brie", "value": "C30028"},
                   {"label": "Chanteloup-les-Vignes", "value": "C30029"},
                   {"label": "Chapet", "value": "C30030"},
                   {"label": "Charenton-le-Pont", "value": "C30031"},
                   {"label": "Charny", "value": "C30032"},
                   {"label": "Chars", "value": "C30033"},
                   {"label": "Chartrettes", "value": "C30034"},
                   {"label": "Chateau-Landon", "value": "C30035"},
                   {"label": "Chateaufort", "value": "C30036"},
                   {"label": "Chatenay-Malabry", "value": "C30037"},
                   {"label": "Chatenay-sur-Seine", "value": "C30038"},
                   {"label": "Chatillon", "value": "C30039"},
                   {"label": "Chatou", "value": "C30040"},
                   {"label": "Chauconin-Neufmontiers", "value": "C30041"},
                   {"label": "Chaumes-en-Brie", "value": "C30042"},
                   {"label": "Chaumontel", "value": "C30043"},
                   {"label": "Chavenay", "value": "C30044"},
                   {"label": "Chaville", "value": "C30045"},
                   {"label": "Chelles", "value": "C30046"},
                   {"label": "Chennevieres-sur-Marne", "value": "C30047"},
                   {"label": "Chenoise", "value": "C30048"},
                   {"label": "Cheptainville", "value": "C30049"},
                   {"label": "Chessy", "value": "C30050"},
                   {"label": "Chevannes", "value": "C30051"},
                   {"label": "Chevilly-Larue", "value": "C30052"},
                   {"label": "Chevreuse", "value": "C30053"},
                   {"label": "Chevry-Cossigny", "value": "C30054"},
                   {"label": "Chilly-Mazarin", "value": "C30055"},
                   {"label": "Choisy-en-Brie", "value": "C30056"},
                   {"label": "Choisy-le-Roi", "value": "C30057"},
                   {"label": "Clamart", "value": "C30058"},
                   {"label": "Claye-Souilly", "value": "C30059"},
                   {"label": "Clichy", "value": "C30060"},
                   {"label": "Clichy-sous-Bois", "value": "C30061"},
                   {"label": "Coignieres", "value": "C30062"},
                   {"label": "Collegien", "value": "C30063"},
                   {"label": "Colombes", "value": "C30064"},
                   {"label": "Combs-la-Ville", "value": "C30065"},
                   {"label": "Conches-sur-Gondoire", "value": "C30066"},
                   {"label": "Conde-Sainte-Libiaire", "value": "C30067"},
                   {"label": "Conde-sur-Vesgre", "value": "C30068"},
                   {"label": "Conflans-Sainte-Honorine", "value": "C30069"},
                   {"label": "Congis-sur-Therouanne", "value": "C30070"},
                   {"label": "Corbeil-Essonnes", "value": "C30071"},
                   {"label": "Corbreuse", "value": "C30072"},
                   {"label": "Cormeilles-en-Parisis", "value": "C30073"},
                   {"label": "Coubert", "value": "C30074"},
                   {"label": "Coubron", "value": "C30075"},
                   {"label": "Couilly-Pont-aux-Dames", "value": "C30076"},
                   {"label": "Coulommiers", "value": "C30077"},
                   {"label": "Coupvray", "value": "C30078"},
                   {"label": "Courbevoie", "value": "C30079"},
                   {"label": "Courcouronnes", "value": "C30080"},
                   {"label": "Courdimanche", "value": "C30081"},
                   {"label": "Courpalay", "value": "C30082"},
                   {"label": "Courtry", "value": "C30083"},
                   {"label": "Cregy-les-Meaux", "value": "C30084"},
                   {"label": "Crespieres", "value": "C30085"},
                   {"label": "Creteil", "value": "C30086"},
                   {"label": "Croissy-Beaubourg", "value": "C30087"},
                   {"label": "Croissy-sur-Seine", "value": "C30088"},
                   {"label": "Crosne", "value": "C30089"},
                   {"label": "Crouy-sur-Ourcq", "value": "C30090"},
                   {"label": "Dammarie-les-Lys", "value": "C30091"},
                   {"label": "Dammartin-en-Goele", "value": "C30092"},
                   {"label": "Dampierre-en-Yvelines", "value": "C30093"},
                   {"label": "Dampmart", "value": "C30094"},
                   {"label": "Departement de lEssonne", "value": "C30095"},
                   {"label": "Departement du Val-dOise", "value": "C30096"},
                   {"label": "Deuil-la-Barre", "value": "C30097"},
                   {"label": "Domont", "value": "C30098"},
                   {"label": "Donnemarie-Dontilly", "value": "C30099"},
                   {"label": "Doue", "value": "C30100"},
                   {"label": "Dourdan", "value": "C30101"},
                   {"label": "Drancy", "value": "C30102"},
                   {"label": "Draveil", "value": "C30103"},
                   {"label": "Dugny", "value": "C30104"},
                   {"label": "Eaubonne", "value": "C30105"},
                   {"label": "ecouen", "value": "C30106"},
                   {"label": "Ecquevilly", "value": "C30107"},
                   {"label": "ecuelles", "value": "C30108"},
                   {"label": "egly", "value": "C30109"},
                   {"label": "egreville", "value": "C30110"},
                   {"label": "elancourt", "value": "C30111"},
                   {"label": "emerainville", "value": "C30112"},
                   {"label": "Enghien-les-Bains", "value": "C30113"},
                   {"label": "Ennery", "value": "C30114"},
                   {"label": "epinay-sous-Senart", "value": "C30115"},
                   {"label": "epinay-sur-Orge", "value": "C30116"},
                   {"label": "epinay-sur-Seine", "value": "C30117"},
                   {"label": "epone", "value": "C30118"},
                   {"label": "eragny", "value": "C30119"},
                   {"label": "Ermont", "value": "C30120"},
                   {"label": "Esbly", "value": "C30121"},
                   {"label": "etampes", "value": "C30122"},
                   {"label": "etiolles", "value": "C30123"},
                   {"label": "etrechy", "value": "C30124"},
                   {"label": "evry", "value": "C30125"},
                   {"label": "ezanville", "value": "C30126"},
                   {"label": "Faremoutiers", "value": "C30127"},
                   {"label": "Favieres", "value": "C30128"},
                   {"label": "Ferolles-Attilly", "value": "C30129"},
                   {"label": "Ferrieres-en-Brie", "value": "C30130"},
                   {"label": "Feucherolles", "value": "C30131"},
                   {"label": "Fleury-Merogis", "value": "C30132"},
                   {"label": "Flins-sur-Seine", "value": "C30133"},
                   {"label": "Follainville-Dennemont", "value": "C30134"},
                   {"label": "Fontainebleau", "value": "C30135"},
                   {"label": "Fontenay-aux-Roses", "value": "C30136"},
                   {"label": "Fontenay-en-Parisis", "value": "C30137"},
                   {"label": "Fontenay-le-Fleury", "value": "C30138"},
                   {"label": "Fontenay-les-Briis", "value": "C30139"},
                   {"label": "Fontenay-sous-Bois", "value": "C30140"},
                   {"label": "Fontenay-Tresigny", "value": "C30141"},
                   {"label": "Forges-les-Bains", "value": "C30142"},
                   {"label": "Fosses", "value": "C30143"},
                   {"label": "Fourqueux", "value": "C30144"},
                   {"label": "Franconville", "value": "C30145"},
                   {"label": "Freneuse", "value": "C30146"},
                   {"label": "Frepillon", "value": "C30147"},
                   {"label": "Fresnes", "value": "C30148"},
                   {"label": "Fublaines", "value": "C30149"},
                   {"label": "Gagny", "value": "C30150"},
                   {"label": "Galluis", "value": "C30151"},
                   {"label": "Gambais", "value": "C30152"},
                   {"label": "Garancieres", "value": "C30153"},
                   {"label": "Garches", "value": "C30154"},
                   {"label": "Gargenville", "value": "C30155"},
                   {"label": "Garges-les-Gonesse", "value": "C30156"},
                   {"label": "Gazeran", "value": "C30157"},
                   {"label": "Gennevilliers", "value": "C30158"},
                   {"label": "Gentilly", "value": "C30159"},
                   {"label": "Gif-sur-Yvette", "value": "C30160"},
                   {"label": "Gometz-la-Ville", "value": "C30161"},
                   {"label": "Gometz-le-Chatel", "value": "C30162"},
                   {"label": "Gonesse", "value": "C30163"},
                   {"label": "Gouaix", "value": "C30164"},
                   {"label": "Gournay-sur-Marne", "value": "C30165"},
                   {"label": "Goussainville", "value": "C30166"},
                   {"label": "Gouvernes", "value": "C30167"},
                   {"label": "Gretz-Armainvilliers", "value": "C30168"},
                   {"label": "Grez-sur-Loing", "value": "C30169"},
                   {"label": "Grigny", "value": "C30170"},
                   {"label": "Grisy-Suisnes", "value": "C30171"},
                   {"label": "Groslay", "value": "C30172"},
                   {"label": "Guerard", "value": "C30173"},
                   {"label": "Guermantes", "value": "C30174"},
                   {"label": "Guerville", "value": "C30175"},
                   {"label": "Guignes", "value": "C30176"},
                   {"label": "Guyancourt", "value": "C30177"},
                   {"label": "Hardricourt", "value": "C30178"},
                   {"label": "Hauts-de-Seine", "value": "C30179"},
                   {"label": "Herblay", "value": "C30180"},
                   {"label": "Hericy", "value": "C30181"},
                   {"label": "Houdan", "value": "C30182"},
                   {"label": "Houilles", "value": "C30183"},
                   {"label": "Igny", "value": "C30184"},
                   {"label": "Issou", "value": "C30185"},
                   {"label": "Issy-les-Moulineaux", "value": "C30186"},
                   {"label": "Itteville", "value": "C30187"},
                   {"label": "Ivry-sur-Seine", "value": "C30188"},
                   {"label": "Janville-sur-Juine", "value": "C30189"},
                   {"label": "Joinville-le-Pont", "value": "C30190"},
                   {"label": "Jouarre", "value": "C30191"},
                   {"label": "Jouars-Pontchartrain", "value": "C30192"},
                   {"label": "Jouy-en-Josas", "value": "C30193"},
                   {"label": "Jouy-le-Chatel", "value": "C30194"},
                   {"label": "Jouy-le-Moutier", "value": "C30195"},
                   {"label": "Jouy-sur-Morin", "value": "C30196"},
                   {"label": "Juilly", "value": "C30197"},
                   {"label": "Juvisy-sur-Orge", "value": "C30198"},
                   {"label": "Juziers", "value": "C30199"},
                   {"label": "LHay-les-Roses", "value": "C30200"},
                   {"label": "Letang-la-Ville", "value": "C30201"},
                   {"label": "LIsle-Adam", "value": "C30202"},
                   {"label": "La Celle-Saint-Cloud", "value": "C30203"},
                   {"label": "La Celle-sur-Morin", "value": "C30204"},
                   {"label": "La Chapelle-Gauthier", "value": "C30205"},
                   {"label": "La Chapelle-la-Reine", "value": "C30206"},
                   {"label": "La Chapelle-sur-Crecy", "value": "C30207"},
                   {"label": "La Courneuve", "value": "C30208"},
                   {"label": "La Defense", "value": "C30209"},
                   {"label": "La Ferte-Alais", "value": "C30210"},
                   {"label": "La Ferte-Gaucher", "value": "C30211"},
                   {"label": "La Ferte-sous-Jouarre", "value": "C30212"},
                   {"label": "La Frette-sur-Seine", "value": "C30213"},
                   {"label": "La Garenne-Colombes", "value": "C30214"},
                   {"label": "La Grande-Paroisse", "value": "C30215"},
                   {"label": "La Houssaye-en-Brie", "value": "C30216"},
                   {"label": "La Norville", "value": "C30217"},
                   {"label": "La Queue-en-Brie", "value": "C30218"},
                   {"label": "La Queue-les-Yvelines", "value": "C30219"},
                   {"label": "La Rochette", "value": "C30220"},
                   {"label": "La Verriere", "value": "C30221"},
                   {"label": "La Ville-du-Bois", "value": "C30222"},
                   {"label": "Lagny-sur-Marne", "value": "C30223"},
                   {"label": "Lardy", "value": "C30224"},
                   {"label": "Le Blanc-Mesnil", "value": "C30225"},
                   {"label": "Le Bourget", "value": "C30226"},
                   {"label": "Le Chatelet-en-Brie", "value": "C30227"},
                   {"label": "Le Chesnay", "value": "C30228"},
                   {"label": "Le Coudray-Montceaux", "value": "C30229"},
                   {"label": "Le Kremlin-Bicetre", "value": "C30230"},
                   {"label": "Le Mee-sur-Seine", "value": "C30231"},
                   {"label": "Le Mesnil-le-Roi", "value": "C30232"},
                   {"label": "Le Mesnil-Saint-Denis", "value": "C30233"},
                   {"label": "Le Pecq", "value": "C30234"},
                   {"label": "Le Perray-en-Yvelines", "value": "C30235"},
                   {"label": "Le Perreux-sur-Marne", "value": "C30236"},
                   {"label": "Le Pin", "value": "C30237"},
                   {"label": "Le Plessis-Bouchard", "value": "C30238"},
                   {"label": "Le Plessis-Pate", "value": "C30239"},
                   {"label": "Le Plessis-Robinson", "value": "C30240"},
                   {"label": "Le Plessis-Trevise", "value": "C30241"},
                   {"label": "Le Port-Marly", "value": "C30242"},
                   {"label": "Le Pre-Saint-Gervais", "value": "C30243"},
                   {"label": "Le Raincy", "value": "C30244"},
                   {"label": "Le Thillay", "value": "C30245"},
                   {"label": "Le Val-Saint-Germain", "value": "C30246"},
                   {"label": "Le Vesinet", "value": "C30247"},
                   {"label": "Les Alluets-le-Roi", "value": "C30248"},
                   {"label": "Les Clayes-sous-Bois", "value": "C30249"},
                   {"label": "Les Essarts-le-Roi", "value": "C30250"},
                   {"label": "Les Lilas", "value": "C30251"},
                   {"label": "Les Loges-en-Josas", "value": "C30252"},
                   {"label": "Les Molieres", "value": "C30253"},
                   {"label": "Les Mureaux", "value": "C30254"},
                   {"label": "Les Pavillons-sous-Bois", "value": "C30255"},
                   {"label": "Les Ulis", "value": "C30256"},
                   {"label": "Lesigny", "value": "C30257"},
                   {"label": "Leudeville", "value": "C30258"},
                   {"label": "Leuville-sur-Orge", "value": "C30259"},
                   {"label": "Levallois-Perret", "value": "C30260"},
                   {"label": "Levis-Saint-Nom", "value": "C30261"},
                   {"label": "Lieusaint", "value": "C30262"},
                   {"label": "Limay", "value": "C30263"},
                   {"label": "Limeil-Brevannes", "value": "C30264"},
                   {"label": "Limetz-Villez", "value": "C30265"},
                   {"label": "Limours", "value": "C30266"},
                   {"label": "Linas", "value": "C30267"},
                   {"label": "Lisses", "value": "C30268"},
                   {"label": "Liverdy-en-Brie", "value": "C30269"},
                   {"label": "Livry-Gargan", "value": "C30270"},
                   {"label": "Livry-sur-Seine", "value": "C30271"},
                   {"label": "Lizy-sur-Ourcq", "value": "C30272"},
                   {"label": "Lognes", "value": "C30273"},
                   {"label": "Longjumeau", "value": "C30274"},
                   {"label": "Longnes", "value": "C30275"},
                   {"label": "Longperrier", "value": "C30276"},
                   {"label": "Longpont-sur-Orge", "value": "C30277"},
                   {"label": "Longueville", "value": "C30278"},
                   {"label": "Lorrez-le-Bocage-Preaux", "value": "C30279"},
                   {"label": "Louveciennes", "value": "C30280"},
                   {"label": "Louvres", "value": "C30281"},
                   {"label": "Lumigny-Nesles-Ormeaux", "value": "C30282"},
                   {"label": "Luzarches", "value": "C30283"},
                   {"label": "Maffliers", "value": "C30284"},
                   {"label": "Magnanville", "value": "C30285"},
                   {"label": "Magny-en-Vexin", "value": "C30286"},
                   {"label": "Magny-le-Hongre", "value": "C30287"},
                   {"label": "Magny-les-Hameaux", "value": "C30288"},
                   {"label": "Maincy", "value": "C30289"},
                   {"label": "Maisons-Alfort", "value": "C30290"},
                   {"label": "Maisons-Laffitte", "value": "C30291"},
                   {"label": "Maisse", "value": "C30292"},
                   {"label": "Malakoff", "value": "C30293"},
                   {"label": "Mandres-les-Roses", "value": "C30294"},
                   {"label": "Mantes-la-Jolie", "value": "C30295"},
                   {"label": "Mantes-la-Ville", "value": "C30296"},
                   {"label": "Marcoussis", "value": "C30297"},
                   {"label": "Mareil-Marly", "value": "C30298"},
                   {"label": "Mareil-sur-Mauldre", "value": "C30299"},
                   {"label": "Mareuil-les-Meaux", "value": "C30300"},
                   {"label": "Margency", "value": "C30301"},
                   {"label": "Marines", "value": "C30302"},
                   {"label": "Marles-en-Brie", "value": "C30303"},
                   {"label": "Marly-la-Ville", "value": "C30304"},
                   {"label": "Marly-le-Roi", "value": "C30305"},
                   {"label": "Marnes-la-Coquette", "value": "C30306"},
                   {"label": "Marolles-en-Hurepoix", "value": "C30307"},
                   {"label": "Marolles-sur-Seine", "value": "C30308"},
                   {"label": "Mary-sur-Marne", "value": "C30309"},
                   {"label": "Massy", "value": "C30310"},
                   {"label": "Maule", "value": "C30311"},
                   {"label": "Maurecourt", "value": "C30312"},
                   {"label": "Maurepas", "value": "C30313"},
                   {"label": "Meaux", "value": "C30314"},
                   {"label": "Medan", "value": "C30315"},
                   {"label": "Melun", "value": "C30316"},
                   {"label": "Mennecy", "value": "C30317"},
                   {"label": "Menucourt", "value": "C30318"},
                   {"label": "Mere", "value": "C30319"},
                   {"label": "Mereville", "value": "C30320"},
                   {"label": "Meriel", "value": "C30321"},
                   {"label": "Mery-sur-Oise", "value": "C30322"},
                   {"label": "Meudon", "value": "C30323"},
                   {"label": "Meulan-en-Yvelines", "value": "C30324"},
                   {"label": "Mezieres-sur-Seine", "value": "C30325"},
                   {"label": "Mezy-sur-Seine", "value": "C30326"},
                   {"label": "Milly-la-Foret", "value": "C30327"},
                   {"label": "Mitry-Mory", "value": "C30328"},
                   {"label": "Moigny-sur-ecole", "value": "C30329"},
                   {"label": "Moisenay", "value": "C30330"},
                   {"label": "Moisselles", "value": "C30331"},
                   {"label": "Moissy-Cramayel", "value": "C30332"},
                   {"label": "Montcourt-Fromonville", "value": "C30333"},
                   {"label": "Montereau-Fault-Yonne", "value": "C30334"},
                   {"label": "Montesson", "value": "C30335"},
                   {"label": "Montevrain", "value": "C30336"},
                   {"label": "Montfermeil", "value": "C30337"},
                   {"label": "Montgeron", "value": "C30338"},
                   {"label": "Monthyon", "value": "C30339"},
                   {"label": "Montigny-le-Bretonneux", "value": "C30340"},
                   {"label": "Montigny-Lencoup", "value": "C30341"},
                   {"label": "Montigny-les-Cormeilles", "value": "C30342"},
                   {"label": "Montigny-sur-Loing", "value": "C30343"},
                   {"label": "Montlhery", "value": "C30344"},
                   {"label": "Montlignon", "value": "C30345"},
                   {"label": "Montmagny", "value": "C30346"},
                   {"label": "Montmorency", "value": "C30347"},
                   {"label": "Montreuil", "value": "C30348"},
                   {"label": "Montrouge", "value": "C30349"},
                   {"label": "Montry", "value": "C30350"},
                   {"label": "Montsoult", "value": "C30351"},
                   {"label": "Morainvilliers", "value": "C30352"},
                   {"label": "Morangis", "value": "C30353"},
                   {"label": "Moret-sur-Loing", "value": "C30354"},
                   {"label": "Morigny-Champigny", "value": "C30355"},
                   {"label": "Mormant", "value": "C30356"},
                   {"label": "Morsang-sur-Orge", "value": "C30357"},
                   {"label": "Mortcerf", "value": "C30358"},
                   {"label": "Mouroux", "value": "C30359"},
                   {"label": "Mours", "value": "C30360"},
                   {"label": "Moussy-le-Neuf", "value": "C30361"},
                   {"label": "Moussy-le-Vieux", "value": "C30362"},
                   {"label": "Nandy", "value": "C30363"},
                   {"label": "Nangis", "value": "C30364"},
                   {"label": "Nanterre", "value": "C30365"},
                   {"label": "Nanteuil-les-Meaux", "value": "C30366"},
                   {"label": "Neauphle-le-Chateau", "value": "C30367"},
                   {"label": "Nemours", "value": "C30368"},
                   {"label": "Nesles-la-Vallee", "value": "C30369"},
                   {"label": "Neufmoutiers-en-Brie", "value": "C30370"},
                   {"label": "Neuilly-Plaisance", "value": "C30371"},
                   {"label": "Neuilly-sur-Marne", "value": "C30372"},
                   {"label": "Neuilly-sur-Seine", "value": "C30373"},
                   {"label": "Neuville-sur-Oise", "value": "C30374"},
                   {"label": "Nogent-sur-Marne", "value": "C30375"},
                   {"label": "Noiseau", "value": "C30376"},
                   {"label": "Noisiel", "value": "C30377"},
                   {"label": "Noisy-le-Grand", "value": "C30378"},
                   {"label": "Noisy-le-Roi", "value": "C30379"},
                   {"label": "Noisy-le-Sec", "value": "C30380"},
                   {"label": "Noisy-sur-ecole", "value": "C30381"},
                   {"label": "Nozay", "value": "C30382"},
                   {"label": "Oinville-sur-Montcient", "value": "C30383"},
                   {"label": "Oissery", "value": "C30384"},
                   {"label": "Ollainville", "value": "C30385"},
                   {"label": "Orgerus", "value": "C30386"},
                   {"label": "Orgeval", "value": "C30387"},
                   {"label": "Orly", "value": "C30388"},
                   {"label": "Ormesson-sur-Marne", "value": "C30389"},
                   {"label": "Ormoy", "value": "C30390"},
                   {"label": "Orsay", "value": "C30391"},
                   {"label": "Osny", "value": "C30392"},
                   {"label": "Othis", "value": "C30393"},
                   {"label": "Ozoir-la-Ferriere", "value": "C30394"},
                   {"label": "Ozouer-le-Voulgis", "value": "C30395"},
                   {"label": "Palaiseau", "value": "C30396"},
                   {"label": "Pantin", "value": "C30397"},
                   {"label": "Paray-Vieille-Poste", "value": "C30398"},
                   {"label": "Paris", "value": "C30399"},
                   {"label": "Parmain", "value": "C30400"},
                   {"label": "Perigny", "value": "C30401"},
                   {"label": "Persan", "value": "C30402"},
                   {"label": "Perthes", "value": "C30403"},
                   {"label": "Pierrefitte-sur-Seine", "value": "C30404"},
                   {"label": "Pierrelaye", "value": "C30405"},
                   {"label": "Plaisir", "value": "C30406"},
                   {"label": "Poissy", "value": "C30407"},
                   {"label": "Pommeuse", "value": "C30408"},
                   {"label": "Pomponne", "value": "C30409"},
                   {"label": "Pontault-Combault", "value": "C30410"},
                   {"label": "Pontcarre", "value": "C30411"},
                   {"label": "Ponthierry", "value": "C30412"},
                   {"label": "Pontoise", "value": "C30413"},
                   {"label": "Porcheville", "value": "C30414"},
                   {"label": "Presles-en-Brie", "value": "C30415"},
                   {"label": "Pringy", "value": "C30416"},
                   {"label": "Provins", "value": "C30417"},
                   {"label": "Puiseux-en-France", "value": "C30418"},
                   {"label": "Pussay", "value": "C30419"},
                   {"label": "Puteaux", "value": "C30420"},
                   {"label": "Quincy-sous-Senart", "value": "C30421"},
                   {"label": "Quincy-Voisins", "value": "C30422"},
                   {"label": "Rambouillet", "value": "C30423"},
                   {"label": "Rebais", "value": "C30424"},
                   {"label": "Ris-Orangis", "value": "C30425"},
                   {"label": "Rocquencourt", "value": "C30426"},
                   {"label": "Roissy-en-Brie", "value": "C30427"},
                   {"label": "Roissy-en-France", "value": "C30428"},
                   {"label": "Romainville", "value": "C30429"},
                   {"label": "Rosny-sous-Bois", "value": "C30430"},
                   {"label": "Rosny-sur-Seine", "value": "C30431"},
                   {"label": "Rozay-en-Brie", "value": "C30432"},
                   {"label": "Rubelles", "value": "C30433"},
                   {"label": "Rueil-Malmaison", "value": "C30434"},
                   {"label": "Rungis", "value": "C30435"},
                   {"label": "Saacy-sur-Marne", "value": "C30436"},
                   {"label": "Saclas", "value": "C30437"},
                   {"label": "Saclay", "value": "C30438"},
                   {"label": "Sagy", "value": "C30439"},
                   {"label": "Saint-Arnoult-en-Yvelines", "value": "C30440"},
                   {"label": "Saint-Augustin", "value": "C30441"},
                   {"label": "Saint-Brice-sous-Foret", "value": "C30442"},
                   {"label": "Saint-Cheron", "value": "C30443"},
                   {"label": "Saint-Cloud", "value": "C30444"},
                   {"label": "Saint-Cyr-lecole", "value": "C30445"},
                   {"label": "Saint-Cyr-sous-Dourdan", "value": "C30446"},
                   {"label": "Saint-Cyr-sur-Morin", "value": "C30447"},
                   {"label": "Saint-Denis", "value": "C30448"},
                   {"label": "Saint-Fargeau-Ponthierry", "value": "C30449"},
                   {"label": "Saint-Germain-de-la-Grange", "value": "C30450"},
                   {"label": "Saint-Germain-en-Laye", "value": "C30451"},
                   {"label": "Saint-Germain-Laval", "value": "C30452"},
                   {"label": "Saint-Germain-les-Arpajon", "value": "C30453"},
                   {"label": "Saint-Germain-les-Corbeil", "value": "C30454"},
                   {"label": "Saint-Germain-sur-Morin", "value": "C30455"},
                   {"label": "Saint-Gratien", "value": "C30456"},
                   {"label": "Saint-Jean-les-Deux-Jumeaux", "value": "C30457"},
                   {"label": "Saint-Leger-en-Yvelines", "value": "C30458"},
                   {"label": "Saint-Leu-la-Foret", "value": "C30459"},
                   {"label": "Saint-Mammes", "value": "C30460"},
                   {"label": "Saint-Mande", "value": "C30461"},
                   {"label": "Saint-Mard", "value": "C30462"},
                   {"label": "Saint-Martin-du-Tertre", "value": "C30463"},
                   {"label": "Saint-Maur-des-Fosses", "value": "C30464"},
                   {"label": "Saint-Maurice", "value": "C30465"},
                   {"label": "Saint-Maurice-Montcouronne", "value": "C30466"},
                   {"label": "Saint-Michel-sur-Orge", "value": "C30467"},
                   {"label": "Saint-Nom-la-Breteche", "value": "C30468"},
                   {"label": "Saint-Ouen", "value": "C30469"},
                   {"label": "Saint-Ouen-lAumone", "value": "C30470"},
                   {"label": "Saint-Pathus", "value": "C30471"},
                   {"label": "Saint-Pierre-du-Perray", "value": "C30472"},
                   {"label": "Saint-Pierre-les-Nemours", "value": "C30473"},
                   {"label": "Saint-Prix", "value": "C30474"},
                   {"label": "Saint-Quentin-en-Yvelines", "value": "C30475"},
                   {"label": "Saint-Remy-les-Chevreuse", "value": "C30476"},
                   {"label": "Saint-Sauveur-sur-ecole", "value": "C30477"},
                   {"label": "Saint-Soupplets", "value": "C30478"},
                   {"label": "Saint-Thibault-des-Vignes", "value": "C30479"},
                   {"label": "Saint-Vrain", "value": "C30480"},
                   {"label": "Saint-Witz", "value": "C30481"},
                   {"label": "Sainte-Colombe", "value": "C30482"},
                   {"label": "Sainte-Genevieve-des-Bois", "value": "C30483"},
                   {"label": "Saintry-sur-Seine", "value": "C30484"},
                   {"label": "Saints", "value": "C30485"},
                   {"label": "Salins", "value": "C30486"},
                   {"label": "Sammeron", "value": "C30487"},
                   {"label": "Samois-sur-Seine", "value": "C30488"},
                   {"label": "Samoreau", "value": "C30489"},
                   {"label": "Sannois", "value": "C30490"},
                   {"label": "Santeny", "value": "C30491"},
                   {"label": "Sarcelles", "value": "C30492"},
                   {"label": "Sartrouville", "value": "C30493"},
                   {"label": "Saulx-les-Chartreux", "value": "C30494"},
                   {"label": "Savigny-le-Temple", "value": "C30495"},
                   {"label": "Savigny-sur-Orge", "value": "C30496"},
                   {"label": "Sceaux", "value": "C30497"},
                   {"label": "Seine-et-Marne", "value": "C30498"},
                   {"label": "Seine-Port", "value": "C30499"},
                   {"label": "Seine-Saint-Denis", "value": "C30500"},
                   {"label": "Septeuil", "value": "C30501"},
                   {"label": "Seraincourt", "value": "C30502"},
                   {"label": "Serris", "value": "C30503"},
                   {"label": "Servon", "value": "C30504"},
                   {"label": "Seugy", "value": "C30505"},
                   {"label": "Sevran", "value": "C30506"},
                   {"label": "Sevres", "value": "C30507"},
                   {"label": "Soignolles-en-Brie", "value": "C30508"},
                   {"label": "Soisy-sous-Montmorency", "value": "C30509"},
                   {"label": "Soisy-sur-ecole", "value": "C30510"},
                   {"label": "Soisy-sur-Seine", "value": "C30511"},
                   {"label": "Solers", "value": "C30512"},
                   {"label": "Sonchamp", "value": "C30513"},
                   {"label": "Souppes-sur-Loing", "value": "C30514"},
                   {"label": "Sourdun", "value": "C30515"},
                   {"label": "Stains", "value": "C30516"},
                   {"label": "Sucy-en-Brie", "value": "C30517"},
                   {"label": "Suresnes", "value": "C30518"},
                   {"label": "Survilliers", "value": "C30519"},
                   {"label": "Tacoignieres", "value": "C30520"},
                   {"label": "Taverny", "value": "C30521"},
                   {"label": "Thiais", "value": "C30522"},
                   {"label": "Thiverval-Grignon", "value": "C30523"},
                   {"label": "Thoiry", "value": "C30524"},
                   {"label": "Thomery", "value": "C30525"},
                   {"label": "Thorigny-sur-Marne", "value": "C30526"},
                   {"label": "Tigery", "value": "C30527"},
                   {"label": "Torcy", "value": "C30528"},
                   {"label": "Touquin", "value": "C30529"},
                   {"label": "Tournan-en-Brie", "value": "C30530"},
                   {"label": "Trappes", "value": "C30531"},
                   {"label": "Tremblay-en-France", "value": "C30532"},
                   {"label": "Triel-sur-Seine", "value": "C30533"},
                   {"label": "Trilport", "value": "C30534"},
                   {"label": "Us", "value": "C30535"},
                   {"label": "Vaires-sur-Marne", "value": "C30536"},
                   {"label": "Val-de-Marne", "value": "C30537"},
                   {"label": "Valenton", "value": "C30538"},
                   {"label": "Valmondois", "value": "C30539"},
                   {"label": "Vanves", "value": "C30540"},
                   {"label": "Varennes-Jarcy", "value": "C30541"},
                   {"label": "Varennes-sur-Seine", "value": "C30542"},
                   {"label": "Varreddes", "value": "C30543"},
                   {"label": "Vaucresson", "value": "C30544"},
                   {"label": "Vaugrigneuse", "value": "C30545"},
                   {"label": "Vauhallan", "value": "C30546"},
                   {"label": "Vaujours", "value": "C30547"},
                   {"label": "Vaureal", "value": "C30548"},
                   {"label": "Vaux-le-Penil", "value": "C30549"},
                   {"label": "Vaux-sur-Seine", "value": "C30550"},
                   {"label": "Velizy-Villacoublay", "value": "C30551"},
                   {"label": "Vemars", "value": "C30552"},
                   {"label": "Veneux-les-Sablons", "value": "C30553"},
                   {"label": "Verneuil-sur-Seine", "value": "C30554"},
                   {"label": "Vernou-la-Celle-sur-Seine", "value": "C30555"},
                   {"label": "Vernouillet", "value": "C30556"},
                   {"label": "Verrieres-le-Buisson", "value": "C30557"},
                   {"label": "Versailles", "value": "C30558"},
                   {"label": "Vert-le-Grand", "value": "C30559"},
                   {"label": "Vert-le-Petit", "value": "C30560"},
                   {"label": "Vert-Saint-Denis", "value": "C30561"},
                   {"label": "Viarmes", "value": "C30562"},
                   {"label": "Vigneux-sur-Seine", "value": "C30563"},
                   {"label": "Vigny", "value": "C30564"},
                   {"label": "Villabe", "value": "C30565"},
                   {"label": "Ville-dAvray", "value": "C30566"},
                   {"label": "Villebon-sur-Yvette", "value": "C30567"},
                   {"label": "Villecresnes", "value": "C30568"},
                   {"label": "Villejuif", "value": "C30569"},
                   {"label": "Villejust", "value": "C30570"},
                   {"label": "Villemoisson-sur-Orge", "value": "C30571"},
                   {"label": "Villemomble", "value": "C30572"},
                   {"label": "Villeneuve-la-Garenne", "value": "C30573"},
                   {"label": "Villeneuve-le-Comte", "value": "C30574"},
                   {"label": "Villeneuve-le-Roi", "value": "C30575"},
                   {"label": "Villeneuve-Saint-Georges", "value": "C30576"},
                   {"label": "Villeneuve-sur-Bellot", "value": "C30577"},
                   {"label": "Villennes-sur-Seine", "value": "C30578"},
                   {"label": "Villenoy", "value": "C30579"},
                   {"label": "Villeparisis", "value": "C30580"},
                   {"label": "Villepinte", "value": "C30581"},
                   {"label": "Villepreux", "value": "C30582"},
                   {"label": "Villetaneuse", "value": "C30583"},
                   {"label": "Villevaude", "value": "C30584"},
                   {"label": "Villiers-le-Bacle", "value": "C30585"},
                   {"label": "Villiers-le-Bel", "value": "C30586"},
                   {"label": "Villiers-Saint-Frederic", "value": "C30587"},
                   {"label": "Villiers-Saint-Georges", "value": "C30588"},
                   {"label": "Villiers-sur-Marne", "value": "C30589"},
                   {"label": "Villiers-sur-Morin", "value": "C30590"},
                   {"label": "Villiers-sur-Orge", "value": "C30591"},
                   {"label": "Vincennes", "value": "C30592"},
                   {"label": "Viroflay", "value": "C30593"},
                   {"label": "Viry-Chatillon", "value": "C30594"},
                   {"label": "Vitry-sur-Seine", "value": "C30595"},
                   {"label": "Voisenon", "value": "C30596"},
                   {"label": "Voisins-le-Bretonneux", "value": "C30597"},
                   {"label": "Voulangis", "value": "C30598"},
                   {"label": "Voulx", "value": "C30599"},
                   {"label": "Vulaines-sur-Seine", "value": "C30600"},
                   {"label": "Wissous", "value": "C30601"},
                   {"label": "Yerres", "value": "C30602"},
                   {"label": "Yvelines", "value": "C30603"}],
 "Ilfov County": [{"label": "1 Decembrie", "value": "C96453"},
                  {"label": "Afumati", "value": "C96454"},
                  {"label": "Alunisu", "value": "C96455"},
                  {"label": "Balaceanca", "value": "C96456"},
                  {"label": "Balotesti", "value": "C96457"},
                  {"label": "Berceni", "value": "C96458"},
                  {"label": "Bragadiru", "value": "C96459"},
                  {"label": "Branesti", "value": "C96460"},
                  {"label": "Buciumeni", "value": "C96461"},
                  {"label": "Buftea", "value": "C96462"},
                  {"label": "Burias", "value": "C96463"},
                  {"label": "Caciulati", "value": "C96464"},
                  {"label": "Caldararu", "value": "C96465"},
                  {"label": "Catelu", "value": "C96466"},
                  {"label": "Cernica", "value": "C96467"},
                  {"label": "Chiajna", "value": "C96468"},
                  {"label": "Chitila", "value": "C96469"},
                  {"label": "Ciofliceni", "value": "C96470"},
                  {"label": "Ciolpani", "value": "C96471"},
                  {"label": "Ciorogarla", "value": "C96472"},
                  {"label": "Clinceni", "value": "C96473"},
                  {"label": "Comuna 1 Decembrie", "value": "C96474"},
                  {"label": "Comuna Afumati", "value": "C96475"},
                  {"label": "Comuna Balotesti", "value": "C96476"},
                  {"label": "Comuna Berceni", "value": "C96477"},
                  {"label": "Comuna Branesti", "value": "C96478"},
                  {"label": "Comuna Cernica", "value": "C96479"},
                  {"label": "Comuna Chiajna", "value": "C96480"},
                  {"label": "Comuna Ciolpani", "value": "C96481"},
                  {"label": "Comuna Ciorogarla", "value": "C96482"},
                  {"label": "Comuna Clinceni", "value": "C96483"},
                  {"label": "Comuna Copaceni", "value": "C96484"},
                  {"label": "Comuna Corbeanca", "value": "C96485"},
                  {"label": "Comuna Cornetu", "value": "C96486"},
                  {"label": "Comuna Darasti-Ilfov", "value": "C96487"},
                  {"label": "Comuna Dascalu Creata", "value": "C96488"},
                  {"label": "Comuna Domnesti", "value": "C96489"},
                  {"label": "Comuna Dragomiresti-Vale", "value": "C96490"},
                  {"label": "Comuna Fundeni-Dobroesti", "value": "C96491"},
                  {"label": "Comuna Ganeasa", "value": "C96492"},
                  {"label": "Comuna Glina", "value": "C96493"},
                  {"label": "Comuna Gradistea", "value": "C96494"},
                  {"label": "Comuna Gruiu", "value": "C96495"},
                  {"label": "Comuna Jilava", "value": "C96496"},
                  {"label": "Comuna Moara Vlasiei", "value": "C96497"},
                  {"label": "Comuna Mogosoaia", "value": "C96498"},
                  {"label": "Comuna Nuci", "value": "C96499"},
                  {"label": "Comuna Peris", "value": "C96500"},
                  {"label": "Comuna Petrachioaia", "value": "C96501"},
                  {"label": "Comuna Snagov", "value": "C96502"},
                  {"label": "Comuna Stefanestii De Jos", "value": "C96503"},
                  {"label": "Comuna Tunari", "value": "C96504"},
                  {"label": "Comuna Vidra", "value": "C96505"},
                  {"label": "Copaceni", "value": "C96506"},
                  {"label": "Corbeanca", "value": "C96507"},
                  {"label": "Cornetu", "value": "C96508"},
                  {"label": "Cretesti", "value": "C96509"},
                  {"label": "Darasti-Ilfov", "value": "C96510"},
                  {"label": "Darvari", "value": "C96511"},
                  {"label": "Dascalu", "value": "C96512"},
                  {"label": "Dobroesti", "value": "C96513"},
                  {"label": "Domnesti", "value": "C96514"},
                  {"label": "Domnesti-Sarbi", "value": "C96515"},
                  {"label": "Dragomiresti-Deal", "value": "C96516"},
                  {"label": "Dragomiresti-Vale", "value": "C96517"},
                  {"label": "Fundeni", "value": "C96518"},
                  {"label": "Ganeasa", "value": "C96519"},
                  {"label": "Ghermanesti", "value": "C96520"},
                  {"label": "Glina", "value": "C96521"},
                  {"label": "Gradistea", "value": "C96522"},
                  {"label": "Gruiu", "value": "C96523"},
                  {"label": "Islaz", "value": "C96524"},
                  {"label": "Jilava", "value": "C96525"},
                  {"label": "Lipia", "value": "C96526"},
                  {"label": "Magurele", "value": "C96527"},
                  {"label": "Merii Petchii", "value": "C96528"},
                  {"label": "Moara Vlasiei", "value": "C96529"},
                  {"label": "Mogosoaia", "value": "C96530"},
                  {"label": "Nuci", "value": "C96531"},
                  {"label": "Olteni", "value": "C96532"},
                  {"label": "Oras Bragadiru", "value": "C96533"},
                  {"label": "Oras Buftea", "value": "C96534"},
                  {"label": "Oras Chitila", "value": "C96535"},
                  {"label": "Oras Magurele", "value": "C96536"},
                  {"label": "Oras Otopeni", "value": "C96537"},
                  {"label": "Oras Pantelimon", "value": "C96538"},
                  {"label": "Oras Popesti Leordeni", "value": "C96539"},
                  {"label": "Otopeni", "value": "C96540"},
                  {"label": "Pantelimon", "value": "C96541"},
                  {"label": "Peris", "value": "C96542"},
                  {"label": "Petrachioaia", "value": "C96543"},
                  {"label": "Piteasca", "value": "C96544"},
                  {"label": "Popesti-Leordeni", "value": "C96545"},
                  {"label": "Rosu", "value": "C96546"},
                  {"label": "Rudeni", "value": "C96547"},
                  {"label": "Silistea Snagovului", "value": "C96548"},
                  {"label": "Sintesti", "value": "C96549"},
                  {"label": "Snagov", "value": "C96550"},
                  {"label": "Stefanestii de Jos", "value": "C96551"},
                  {"label": "Tamasi", "value": "C96552"},
                  {"label": "Tanganu", "value": "C96553"},
                  {"label": "Tunari", "value": "C96554"},
                  {"label": "Varteju", "value": "C96555"},
                  {"label": "Vidra", "value": "C96556"},
                  {"label": "Voluntari", "value": "C96557"},
                  {"label": "Voluntari City", "value": "C96558"}],
 "Ilinden Municipality": [{"label": "Ilinden", "value": "C65417"},
                          {"label": "Kadino", "value": "C65418"},
                          {"label": "Marino", "value": "C65419"},
                          {"label": "Miladinovci", "value": "C65420"},
                          {"label": "Idrizovo", "value": "C65421"},
                          {"label": "Jurumleri", "value": "C65422"}],
 "Illinois": [{"label": "Abingdon", "value": "C131296"},
              {"label": "Adams County", "value": "C131297"},
              {"label": "Addison", "value": "C131298"},
              {"label": "Albany Park", "value": "C131299"},
              {"label": "Albers", "value": "C131300"},
              {"label": "Albion", "value": "C131301"},
              {"label": "Aledo", "value": "C131302"},
              {"label": "Alexander County", "value": "C131303"},
              {"label": "Algonquin", "value": "C131304"},
              {"label": "Alorton", "value": "C131305"},
              {"label": "Alsip", "value": "C131306"},
              {"label": "Altamont", "value": "C131307"},
              {"label": "Alton", "value": "C131308"},
              {"label": "Amboy", "value": "C131309"},
              {"label": "Andalusia", "value": "C131310"},
              {"label": "Anna", "value": "C131311"},
              {"label": "Antioch", "value": "C131312"},
              {"label": "Arcola", "value": "C131313"},
              {"label": "Arlington Heights", "value": "C131314"},
              {"label": "Arthur", "value": "C131315"},
              {"label": "Ashburn", "value": "C131316"},
              {"label": "Ashland", "value": "C131317"},
              {"label": "Assumption", "value": "C131318"},
              {"label": "Astoria", "value": "C131319"},
              {"label": "Athens", "value": "C131320"},
              {"label": "Atlanta", "value": "C131321"},
              {"label": "Atwood", "value": "C131322"},
              {"label": "Auburn", "value": "C131323"},
              {"label": "Auburn Gresham", "value": "C131324"},
              {"label": "Aurora", "value": "C131325"},
              {"label": "Aviston", "value": "C131326"},
              {"label": "Avondale", "value": "C131327"},
              {"label": "Bannockburn", "value": "C131328"},
              {"label": "Barrington", "value": "C131329"},
              {"label": "Barrington Hills", "value": "C131330"},
              {"label": "Barry", "value": "C131331"},
              {"label": "Bartlett", "value": "C131332"},
              {"label": "Bartonville", "value": "C131333"},
              {"label": "Batavia", "value": "C131334"},
              {"label": "Beach Park", "value": "C131335"},
              {"label": "Beardstown", "value": "C131336"},
              {"label": "Beckemeyer", "value": "C131337"},
              {"label": "Beecher", "value": "C131338"},
              {"label": "Belleville", "value": "C131339"},
              {"label": "Bellevue", "value": "C131340"},
              {"label": "Bellwood", "value": "C131341"},
              {"label": "Belmont Cragin", "value": "C131342"},
              {"label": "Belvidere", "value": "C131343"},
              {"label": "Bement", "value": "C131344"},
              {"label": "Benld", "value": "C131345"},
              {"label": "Bensenville", "value": "C131346"},
              {"label": "Benton", "value": "C131347"},
              {"label": "Berkeley", "value": "C131348"},
              {"label": "Berwyn", "value": "C131349"},
              {"label": "Bethalto", "value": "C131350"},
              {"label": "Bethany", "value": "C131351"},
              {"label": "Big Rock", "value": "C131352"},
              {"label": "Bloomingdale", "value": "C131353"},
              {"label": "Bloomington", "value": "C131354"},
              {"label": "Blue Island", "value": "C131355"},
              {"label": "Blue Mound", "value": "C131356"},
              {"label": "Bolingbrook", "value": "C131357"},
              {"label": "Bond County", "value": "C131358"},
              {"label": "Boone County", "value": "C131359"},
              {"label": "Boulder Hill", "value": "C131360"},
              {"label": "Bourbonnais", "value": "C131361"},
              {"label": "Bradley", "value": "C131362"},
              {"label": "Braidwood", "value": "C131363"},
              {"label": "Breese", "value": "C131364"},
              {"label": "Bridgeport", "value": "C131365"},
              {"label": "Bridgeview", "value": "C131366"},
              {"label": "Brighton", "value": "C131367"},
              {"label": "Brighton Park", "value": "C131368"},
              {"label": "Broadview", "value": "C131369"},
              {"label": "Brookfield", "value": "C131370"},
              {"label": "Brown County", "value": "C131371"},
              {"label": "Buffalo Grove", "value": "C131372"},
              {"label": "Bull Valley", "value": "C131373"},
              {"label": "Bunker Hill", "value": "C131374"},
              {"label": "Burbank", "value": "C131375"},
              {"label": "Bureau County", "value": "C131376"},
              {"label": "Burnham", "value": "C131377"},
              {"label": "Burr Ridge", "value": "C131378"},
              {"label": "Bushnell", "value": "C131379"},
              {"label": "Byron", "value": "C131380"},
              {"label": "Cahokia", "value": "C131381"},
              {"label": "Cairo", "value": "C131382"},
              {"label": "Calhoun County", "value": "C131383"},
              {"label": "Calumet City", "value": "C131384"},
              {"label": "Calumet Park", "value": "C131385"},
              {"label": "Cambria", "value": "C131386"},
              {"label": "Cambridge", "value": "C131387"},
              {"label": "Camp Point", "value": "C131388"},
              {"label": "Canton", "value": "C131389"},
              {"label": "Capron", "value": "C131390"},
              {"label": "Carbon Cliff", "value": "C131391"},
              {"label": "Carbondale", "value": "C131392"},
              {"label": "Carlinville", "value": "C131393"},
              {"label": "Carlyle", "value": "C131394"},
              {"label": "Carmi", "value": "C131395"},
              {"label": "Carol Stream", "value": "C131396"},
              {"label": "Carpentersville", "value": "C131397"},
              {"label": "Carrier Mills", "value": "C131398"},
              {"label": "Carroll County", "value": "C131399"},
              {"label": "Carrollton", "value": "C131400"},
              {"label": "Carterville", "value": "C131401"},
              {"label": "Carthage", "value": "C131402"},
              {"label": "Cary", "value": "C131403"},
              {"label": "Casey", "value": "C131404"},
              {"label": "Caseyville", "value": "C131405"},
              {"label": "Cass County", "value": "C131406"},
              {"label": "Catlin", "value": "C131407"},
              {"label": "Central City", "value": "C131408"},
              {"label": "Centralia", "value": "C131409"},
              {"label": "Centreville", "value": "C131410"},
              {"label": "Cerro Gordo", "value": "C131411"},
              {"label": "Champaign", "value": "C131412"},
              {"label": "Champaign County", "value": "C131413"},
              {"label": "Channahon", "value": "C131414"},
              {"label": "Channel Lake", "value": "C131415"},
              {"label": "Charleston", "value": "C131416"},
              {"label": "Chatham", "value": "C131417"},
              {"label": "Chatsworth", "value": "C131418"},
              {"label": "Chebanse", "value": "C131419"},
              {"label": "Chenoa", "value": "C131420"},
              {"label": "Cherry Valley", "value": "C131421"},
              {"label": "Chester", "value": "C131422"},
              {"label": "Chicago", "value": "C131423"},
              {"label": "Chicago Heights", "value": "C131424"},
              {"label": "Chicago Lawn", "value": "C131425"},
              {"label": "Chicago Loop", "value": "C131426"},
              {"label": "Chicago Ridge", "value": "C131427"},
              {"label": "Chillicothe", "value": "C131428"},
              {"label": "Chrisman", "value": "C131429"},
              {"label": "Christian County", "value": "C131430"},
              {"label": "Christopher", "value": "C131431"},
              {"label": "Cicero", "value": "C131432"},
              {"label": "Clarendon Hills", "value": "C131433"},
              {"label": "Clark County", "value": "C131434"},
              {"label": "Clay County", "value": "C131435"},
              {"label": "Clifton", "value": "C131436"},
              {"label": "Clinton", "value": "C131437"},
              {"label": "Clinton County", "value": "C131438"},
              {"label": "Coal City", "value": "C131439"},
              {"label": "Coal Valley", "value": "C131440"},
              {"label": "Cobden", "value": "C131441"},
              {"label": "Colchester", "value": "C131442"},
              {"label": "Coles County", "value": "C131443"},
              {"label": "Colfax", "value": "C131444"},
              {"label": "Collinsville", "value": "C131445"},
              {"label": "Colona", "value": "C131446"},
              {"label": "Columbia", "value": "C131447"},
              {"label": "Cook County", "value": "C131448"},
              {"label": "Cortland", "value": "C131449"},
              {"label": "Country Club Hills", "value": "C131450"},
              {"label": "Countryside", "value": "C131451"},
              {"label": "Crainville", "value": "C131452"},
              {"label": "Crawford County", "value": "C131453"},
              {"label": "Crest Hill", "value": "C131454"},
              {"label": "Crestwood", "value": "C131455"},
              {"label": "Crete", "value": "C131456"},
              {"label": "Creve Coeur", "value": "C131457"},
              {"label": "Crystal Lake", "value": "C131458"},
              {"label": "Crystal Lawns", "value": "C131459"},
              {"label": "Cuba", "value": "C131460"},
              {"label": "Cumberland County", "value": "C131461"},
              {"label": "Danvers", "value": "C131462"},
              {"label": "Danville", "value": "C131463"},
              {"label": "Darien", "value": "C131464"},
              {"label": "Davis Junction", "value": "C131465"},
              {"label": "De Soto", "value": "C131466"},
              {"label": "De Witt County", "value": "C131467"},
              {"label": "Decatur", "value": "C131468"},
              {"label": "Deer Park", "value": "C131469"},
              {"label": "Deerfield", "value": "C131470"},
              {"label": "DeKalb", "value": "C131471"},
              {"label": "DeKalb County", "value": "C131472"},
              {"label": "Delavan", "value": "C131473"},
              {"label": "Depue", "value": "C131474"},
              {"label": "Des Plaines", "value": "C131475"},
              {"label": "Diamond", "value": "C131476"},
              {"label": "Divernon", "value": "C131477"},
              {"label": "Dixmoor", "value": "C131478"},
              {"label": "Dixon", "value": "C131479"},
              {"label": "Dolton", "value": "C131480"},
              {"label": "Douglas", "value": "C131481"},
              {"label": "Douglas County", "value": "C131482"},
              {"label": "Downers Grove", "value": "C131483"},
              {"label": "Du Quoin", "value": "C131484"},
              {"label": "Dunlap", "value": "C131485"},
              {"label": "DuPage County", "value": "C131486"},
              {"label": "Dupo", "value": "C131487"},
              {"label": "Durand", "value": "C131488"},
              {"label": "Dwight", "value": "C131489"},
              {"label": "Earlville", "value": "C131490"},
              {"label": "East Alton", "value": "C131491"},
              {"label": "East Dubuque", "value": "C131492"},
              {"label": "East Dundee", "value": "C131493"},
              {"label": "East Garfield Park", "value": "C131494"},
              {"label": "East Hazel Crest", "value": "C131495"},
              {"label": "East Moline", "value": "C131496"},
              {"label": "East Peoria", "value": "C131497"},
              {"label": "East Saint Louis", "value": "C131498"},
              {"label": "Edgar County", "value": "C131499"},
              {"label": "Edgewater", "value": "C131500"},
              {"label": "Edinburg", "value": "C131501"},
              {"label": "Edwards County", "value": "C131502"},
              {"label": "Edwardsville", "value": "C131503"},
              {"label": "Effingham", "value": "C131504"},
              {"label": "Effingham County", "value": "C131505"},
              {"label": "El Paso", "value": "C131506"},
              {"label": "Elburn", "value": "C131507"},
              {"label": "Eldorado", "value": "C131508"},
              {"label": "Elgin", "value": "C131509"},
              {"label": "Elizabethtown", "value": "C131510"},
              {"label": "Elk Grove Village", "value": "C131511"},
              {"label": "Elmhurst", "value": "C131512"},
              {"label": "Elmwood", "value": "C131513"},
              {"label": "Elmwood Park", "value": "C131514"},
              {"label": "Elwood", "value": "C131515"},
              {"label": "Energy", "value": "C131516"},
              {"label": "Englewood", "value": "C131517"},
              {"label": "Erie", "value": "C131518"},
              {"label": "Eureka", "value": "C131519"},
              {"label": "Evanston", "value": "C131520"},
              {"label": "Evergreen Park", "value": "C131521"},
              {"label": "Fairbury", "value": "C131522"},
              {"label": "Fairfield", "value": "C131523"},
              {"label": "Fairmont", "value": "C131524"},
              {"label": "Fairmont City", "value": "C131525"},
              {"label": "Fairview Heights", "value": "C131526"},
              {"label": "Farmer City", "value": "C131527"},
              {"label": "Farmington", "value": "C131528"},
              {"label": "Fayette County", "value": "C131529"},
              {"label": "Fisher", "value": "C131530"},
              {"label": "Flanagan", "value": "C131531"},
              {"label": "Flora", "value": "C131532"},
              {"label": "Flossmoor", "value": "C131533"},
              {"label": "Ford County", "value": "C131534"},
              {"label": "Ford Heights", "value": "C131535"},
              {"label": "Forest Lake", "value": "C131536"},
              {"label": "Forest Park", "value": "C131537"},
              {"label": "Forrest", "value": "C131538"},
              {"label": "Forreston", "value": "C131539"},
              {"label": "Forsyth", "value": "C131540"},
              {"label": "Fox Lake", "value": "C131541"},
              {"label": "Fox Lake Hills", "value": "C131542"},
              {"label": "Fox River Grove", "value": "C131543"},
              {"label": "Frankfort", "value": "C131544"},
              {"label": "Frankfort Square", "value": "C131545"},
              {"label": "Franklin County", "value": "C131546"},
              {"label": "Franklin Park", "value": "C131547"},
              {"label": "Freeburg", "value": "C131548"},
              {"label": "Freeport", "value": "C131549"},
              {"label": "Fulton", "value": "C131550"},
              {"label": "Fulton County", "value": "C131551"},
              {"label": "Gage Park", "value": "C131552"},
              {"label": "Gages Lake", "value": "C131553"},
              {"label": "Galena", "value": "C131554"},
              {"label": "Galesburg", "value": "C131555"},
              {"label": "Gallatin County", "value": "C131556"},
              {"label": "Galva", "value": "C131557"},
              {"label": "Gardner", "value": "C131558"},
              {"label": "Geneseo", "value": "C131559"},
              {"label": "Geneva", "value": "C131560"},
              {"label": "Genoa", "value": "C131561"},
              {"label": "Georgetown", "value": "C131562"},
              {"label": "Germantown", "value": "C131563"},
              {"label": "Germantown Hills", "value": "C131564"},
              {"label": "Gibson City", "value": "C131565"},
              {"label": "Gifford", "value": "C131566"},
              {"label": "Gilberts", "value": "C131567"},
              {"label": "Gillespie", "value": "C131568"},
              {"label": "Gilman", "value": "C131569"},
              {"label": "Girard", "value": "C131570"},
              {"label": "Glasford", "value": "C131571"},
              {"label": "Glen Carbon", "value": "C131572"},
              {"label": "Glen Ellyn", "value": "C131573"},
              {"label": "Glencoe", "value": "C131574"},
              {"label": "Glendale Heights", "value": "C131575"},
              {"label": "Glenview", "value": "C131576"},
              {"label": "Glenwood", "value": "C131577"},
              {"label": "Godfrey", "value": "C131578"},
              {"label": "Golconda", "value": "C131579"},
              {"label": "Goodings Grove", "value": "C131580"},
              {"label": "Goreville", "value": "C131581"},
              {"label": "Grand Boulevard", "value": "C131582"},
              {"label": "Grandview", "value": "C131583"},
              {"label": "Grandwood Park", "value": "C131584"},
              {"label": "Granite City", "value": "C131585"},
              {"label": "Grant Park", "value": "C131586"},
              {"label": "Granville", "value": "C131587"},
              {"label": "Grayslake", "value": "C131588"},
              {"label": "Grayville", "value": "C131589"},
              {"label": "Greater Grand Crossing", "value": "C131590"},
              {"label": "Green Oaks", "value": "C131591"},
              {"label": "Green Rock", "value": "C131592"},
              {"label": "Greene County", "value": "C131593"},
              {"label": "Greenfield", "value": "C131594"},
              {"label": "Greenup", "value": "C131595"},
              {"label": "Greenville", "value": "C131596"},
              {"label": "Gridley", "value": "C131597"},
              {"label": "Griggsville", "value": "C131598"},
              {"label": "Grundy County", "value": "C131599"},
              {"label": "Gurnee", "value": "C131600"},
              {"label": "Hainesville", "value": "C131601"},
              {"label": "Hamilton", "value": "C131602"},
              {"label": "Hamilton County", "value": "C131603"},
              {"label": "Hampshire", "value": "C131604"},
              {"label": "Hampton", "value": "C131605"},
              {"label": "Hancock County", "value": "C131606"},
              {"label": "Hanna City", "value": "C131607"},
              {"label": "Hanover Park", "value": "C131608"},
              {"label": "Hardin", "value": "C131609"},
              {"label": "Hardin County", "value": "C131610"},
              {"label": "Harrisburg", "value": "C131611"},
              {"label": "Harristown", "value": "C131612"},
              {"label": "Hartford", "value": "C131613"},
              {"label": "Harvard", "value": "C131614"},
              {"label": "Harvey", "value": "C131615"},
              {"label": "Harwood Heights", "value": "C131616"},
              {"label": "Havana", "value": "C131617"},
              {"label": "Hawthorn Woods", "value": "C131618"},
              {"label": "Hazel Crest", "value": "C131619"},
              {"label": "Hebron", "value": "C131620"},
              {"label": "Henderson County", "value": "C131621"},
              {"label": "Hennepin", "value": "C131622"},
              {"label": "Henry", "value": "C131623"},
              {"label": "Henry County", "value": "C131624"},
              {"label": "Heritage Lake", "value": "C131625"},
              {"label": "Herrin", "value": "C131626"},
              {"label": "Herscher", "value": "C131627"},
              {"label": "Heyworth", "value": "C131628"},
              {"label": "Hickory Hills", "value": "C131629"},
              {"label": "Highland", "value": "C131630"},
              {"label": "Highland Park", "value": "C131631"},
              {"label": "Highwood", "value": "C131632"},
              {"label": "Hillcrest", "value": "C131633"},
              {"label": "Hillsboro", "value": "C131634"},
              {"label": "Hillside", "value": "C131635"},
              {"label": "Hinckley", "value": "C131636"},
              {"label": "Hinsdale", "value": "C131637"},
              {"label": "Hodgkins", "value": "C131638"},
              {"label": "Hoffman Estates", "value": "C131639"},
              {"label": "Holiday Shores", "value": "C131640"},
              {"label": "Homer", "value": "C131641"},
              {"label": "Homer Glen", "value": "C131642"},
              {"label": "Hometown", "value": "C131643"},
              {"label": "Homewood", "value": "C131644"},
              {"label": "Hoopeston", "value": "C131645"},
              {"label": "Hudson", "value": "C131646"},
              {"label": "Huntley", "value": "C131647"},
              {"label": "Hyde Park", "value": "C131648"},
              {"label": "Ina", "value": "C131649"},
              {"label": "Indian Head Park", "value": "C131650"},
              {"label": "Ingalls Park", "value": "C131651"},
              {"label": "Inverness", "value": "C131652"},
              {"label": "Iroquois County", "value": "C131653"},
              {"label": "Irving Park", "value": "C131654"},
              {"label": "Island Lake", "value": "C131655"},
              {"label": "Itasca", "value": "C131656"},
              {"label": "Jackson County", "value": "C131657"},
              {"label": "Jacksonville", "value": "C131658"},
              {"label": "Jasper County", "value": "C131659"},
              {"label": "Jefferson County", "value": "C131660"},
              {"label": "Jerome", "value": "C131661"},
              {"label": "Jersey County", "value": "C131662"},
              {"label": "Jerseyville", "value": "C131663"},
              {"label": "Jo Daviess County", "value": "C131664"},
              {"label": "Johnsburg", "value": "C131665"},
              {"label": "Johnson County", "value": "C131666"},
              {"label": "Johnston City", "value": "C131667"},
              {"label": "Joliet", "value": "C131668"},
              {"label": "Jonesboro", "value": "C131669"},
              {"label": "Justice", "value": "C131670"},
              {"label": "Kane County", "value": "C131671"},
              {"label": "Kankakee", "value": "C131672"},
              {"label": "Kankakee County", "value": "C131673"},
              {"label": "Kendall County", "value": "C131674"},
              {"label": "Kenilworth", "value": "C131675"},
              {"label": "Kenwood", "value": "C131676"},
              {"label": "Kewanee", "value": "C131677"},
              {"label": "Kildeer", "value": "C131678"},
              {"label": "Kincaid", "value": "C131679"},
              {"label": "Kingston", "value": "C131680"},
              {"label": "Kirkland", "value": "C131681"},
              {"label": "Knollwood", "value": "C131682"},
              {"label": "Knox County", "value": "C131683"},
              {"label": "Knoxville", "value": "C131684"},
              {"label": "La Grange", "value": "C131685"},
              {"label": "La Grange Park", "value": "C131686"},
              {"label": "La Harpe", "value": "C131687"},
              {"label": "La Salle", "value": "C131688"},
              {"label": "Lacon", "value": "C131689"},
              {"label": "Ladd", "value": "C131690"},
              {"label": "Lake Barrington", "value": "C131691"},
              {"label": "Lake Bluff", "value": "C131692"},
              {"label": "Lake Camelot", "value": "C131693"},
              {"label": "Lake Catherine", "value": "C131694"},
              {"label": "Lake County", "value": "C131695"},
              {"label": "Lake Forest", "value": "C131696"},
              {"label": "Lake Holiday", "value": "C131697"},
              {"label": "Lake in the Hills", "value": "C131698"},
              {"label": "Lake of the Woods", "value": "C131699"},
              {"label": "Lake Summerset", "value": "C131700"},
              {"label": "Lake Villa", "value": "C131701"},
              {"label": "Lake Zurich", "value": "C131702"},
              {"label": "Lakemoor", "value": "C131703"},
              {"label": "Lakewood", "value": "C131704"},
              {"label": "Lakewood Shores", "value": "C131705"},
              {"label": "Lanark", "value": "C131706"},
              {"label": "Lansing", "value": "C131707"},
              {"label": "LaSalle County", "value": "C131708"},
              {"label": "Lawrence County", "value": "C131709"},
              {"label": "Lawrenceville", "value": "C131710"},
              {"label": "Le Roy", "value": "C131711"},
              {"label": "Lebanon", "value": "C131712"},
              {"label": "Lee County", "value": "C131713"},
              {"label": "Leland Grove", "value": "C131714"},
              {"label": "Lemont", "value": "C131715"},
              {"label": "Lena", "value": "C131716"},
              {"label": "Lewistown", "value": "C131717"},
              {"label": "Lexington", "value": "C131718"},
              {"label": "Libertyville", "value": "C131719"},
              {"label": "Lily Lake", "value": "C131720"},
              {"label": "Limestone", "value": "C131721"},
              {"label": "Lincoln", "value": "C131722"},
              {"label": "Lincoln Park", "value": "C131723"},
              {"label": "Lincoln Square", "value": "C131724"},
              {"label": "Lincolnshire", "value": "C131725"},
              {"label": "Lincolnwood", "value": "C131726"},
              {"label": "Lindenhurst", "value": "C131727"},
              {"label": "Lisle", "value": "C131728"},
              {"label": "Litchfield", "value": "C131729"},
              {"label": "Livingston County", "value": "C131730"},
              {"label": "Lockport", "value": "C131731"},
              {"label": "Logan County", "value": "C131732"},
              {"label": "Logan Square", "value": "C131733"},
              {"label": "Lombard", "value": "C131734"},
              {"label": "Long Creek", "value": "C131735"},
              {"label": "Long Grove", "value": "C131736"},
              {"label": "Long Lake", "value": "C131737"},
              {"label": "Louisville", "value": "C131738"},
              {"label": "Loves Park", "value": "C131739"},
              {"label": "Lovington", "value": "C131740"},
              {"label": "Lower West Side", "value": "C131741"},
              {"label": "Lynwood", "value": "C131742"},
              {"label": "Lyons", "value": "C131743"},
              {"label": "Machesney Park", "value": "C131744"},
              {"label": "Mackinaw", "value": "C131745"},
              {"label": "Macomb", "value": "C131746"},
              {"label": "Macon", "value": "C131747"},
              {"label": "Macon County", "value": "C131748"},
              {"label": "Macoupin County", "value": "C131749"},
              {"label": "Madison", "value": "C131750"},
              {"label": "Madison County", "value": "C131751"},
              {"label": "Mahomet", "value": "C131752"},
              {"label": "Malta", "value": "C131753"},
              {"label": "Manhattan", "value": "C131754"},
              {"label": "Manito", "value": "C131755"},
              {"label": "Manteno", "value": "C131756"},
              {"label": "Maple Park", "value": "C131757"},
              {"label": "Marengo", "value": "C131758"},
              {"label": "Marion", "value": "C131759"},
              {"label": "Marion County", "value": "C131760"},
              {"label": "Marissa", "value": "C131761"},
              {"label": "Markham", "value": "C131762"},
              {"label": "Maroa", "value": "C131763"},
              {"label": "Marquette Heights", "value": "C131764"},
              {"label": "Marseilles", "value": "C131765"},
              {"label": "Marshall", "value": "C131766"},
              {"label": "Marshall County", "value": "C131767"},
              {"label": "Martinsville", "value": "C131768"},
              {"label": "Maryville", "value": "C131769"},
              {"label": "Mascoutah", "value": "C131770"},
              {"label": "Mason City", "value": "C131771"},
              {"label": "Mason County", "value": "C131772"},
              {"label": "Massac County", "value": "C131773"},
              {"label": "Matteson", "value": "C131774"},
              {"label": "Mattoon", "value": "C131775"},
              {"label": "Maywood", "value": "C131776"},
              {"label": "McCullom Lake", "value": "C131777"},
              {"label": "McDonough County", "value": "C131778"},
              {"label": "McHenry", "value": "C131779"},
              {"label": "McHenry County", "value": "C131780"},
              {"label": "McKinley Park", "value": "C131781"},
              {"label": "McLean County", "value": "C131782"},
              {"label": "McLeansboro", "value": "C131783"},
              {"label": "Melrose Park", "value": "C131784"},
              {"label": "Menard County", "value": "C131785"},
              {"label": "Mendota", "value": "C131786"},
              {"label": "Mercer County", "value": "C131787"},
              {"label": "Meredosia", "value": "C131788"},
              {"label": "Merrionette Park", "value": "C131789"},
              {"label": "Metamora", "value": "C131790"},
              {"label": "Metropolis", "value": "C131791"},
              {"label": "Midlothian", "value": "C131792"},
              {"label": "Milan", "value": "C131793"},
              {"label": "Milford", "value": "C131794"},
              {"label": "Millstadt", "value": "C131795"},
              {"label": "Minier", "value": "C131796"},
              {"label": "Minonk", "value": "C131797"},
              {"label": "Minooka", "value": "C131798"},
              {"label": "Mitchell", "value": "C131799"},
              {"label": "Mokena", "value": "C131800"},
              {"label": "Moline", "value": "C131801"},
              {"label": "Momence", "value": "C131802"},
              {"label": "Monee", "value": "C131803"},
              {"label": "Monmouth", "value": "C131804"},
              {"label": "Monroe County", "value": "C131805"},
              {"label": "Montgomery", "value": "C131806"},
              {"label": "Montgomery County", "value": "C131807"},
              {"label": "Monticello", "value": "C131808"},
              {"label": "Morgan County", "value": "C131809"},
              {"label": "Morgan Park", "value": "C131810"},
              {"label": "Morris", "value": "C131811"},
              {"label": "Morrison", "value": "C131812"},
              {"label": "Morrisonville", "value": "C131813"},
              {"label": "Morton", "value": "C131814"},
              {"label": "Morton Grove", "value": "C131815"},
              {"label": "Moultrie County", "value": "C131816"},
              {"label": "Mound City", "value": "C131817"},
              {"label": "Mount Carmel", "value": "C131818"},
              {"label": "Mount Carroll", "value": "C131819"},
              {"label": "Mount Greenwood", "value": "C131820"},
              {"label": "Mount Morris", "value": "C131821"},
              {"label": "Mount Olive", "value": "C131822"},
              {"label": "Mount Prospect", "value": "C131823"},
              {"label": "Mount Pulaski", "value": "C131824"},
              {"label": "Mount Sterling", "value": "C131825"},
              {"label": "Mount Vernon", "value": "C131826"},
              {"label": "Mount Zion", "value": "C131827"},
              {"label": "Moweaqua", "value": "C131828"},
              {"label": "Mundelein", "value": "C131829"},
              {"label": "Murphysboro", "value": "C131830"},
              {"label": "Naperville", "value": "C131831"},
              {"label": "Nashville", "value": "C131832"},
              {"label": "Nauvoo", "value": "C131833"},
              {"label": "Near North Side", "value": "C131834"},
              {"label": "Near South Side", "value": "C131835"},
              {"label": "Neoga", "value": "C131836"},
              {"label": "New Athens", "value": "C131837"},
              {"label": "New Baden", "value": "C131838"},
              {"label": "New Berlin", "value": "C131839"},
              {"label": "New City", "value": "C131840"},
              {"label": "New Lenox", "value": "C131841"},
              {"label": "Newark", "value": "C131842"},
              {"label": "Newton", "value": "C131843"},
              {"label": "Niles", "value": "C131844"},
              {"label": "Nokomis", "value": "C131845"},
              {"label": "Normal", "value": "C131846"},
              {"label": "Norridge", "value": "C131847"},
              {"label": "Norris City", "value": "C131848"},
              {"label": "North Aurora", "value": "C131849"},
              {"label": "North Barrington", "value": "C131850"},
              {"label": "North Center", "value": "C131851"},
              {"label": "North Chicago", "value": "C131852"},
              {"label": "North Lawndale", "value": "C131853"},
              {"label": "North Pekin", "value": "C131854"},
              {"label": "North Peoria", "value": "C131855"},
              {"label": "North Riverside", "value": "C131856"},
              {"label": "Northbrook", "value": "C131857"},
              {"label": "Northfield", "value": "C131858"},
              {"label": "Northlake", "value": "C131859"},
              {"label": "OFallon", "value": "C131860"},
              {"label": "Oak Brook", "value": "C131861"},
              {"label": "Oak Forest", "value": "C131862"},
              {"label": "Oak Lawn", "value": "C131863"},
              {"label": "Oak Park", "value": "C131864"},
              {"label": "Oakbrook Terrace", "value": "C131865"},
              {"label": "Oakwood", "value": "C131866"},
              {"label": "Oakwood Hills", "value": "C131867"},
              {"label": "Oblong", "value": "C131868"},
              {"label": "Odin", "value": "C131869"},
              {"label": "Ogle County", "value": "C131870"},
              {"label": "Oglesby", "value": "C131871"},
              {"label": "Okawville", "value": "C131872"},
              {"label": "Olney", "value": "C131873"},
              {"label": "Olympia Fields", "value": "C131874"},
              {"label": "Onarga", "value": "C131875"},
              {"label": "Oquawka", "value": "C131876"},
              {"label": "Oregon", "value": "C131877"},
              {"label": "Orion", "value": "C131878"},
              {"label": "Orland Hills", "value": "C131879"},
              {"label": "Orland Park", "value": "C131880"},
              {"label": "Oswego", "value": "C131881"},
              {"label": "Ottawa", "value": "C131882"},
              {"label": "Palatine", "value": "C131883"},
              {"label": "Palestine", "value": "C131884"},
              {"label": "Palos Heights", "value": "C131885"},
              {"label": "Palos Hills", "value": "C131886"},
              {"label": "Palos Park", "value": "C131887"},
              {"label": "Pana", "value": "C131888"},
              {"label": "Paris", "value": "C131889"},
              {"label": "Park City", "value": "C131890"},
              {"label": "Park Forest", "value": "C131891"},
              {"label": "Park Ridge", "value": "C131892"},
              {"label": "Pawnee", "value": "C131893"},
              {"label": "Paxton", "value": "C131894"},
              {"label": "Payson", "value": "C131895"},
              {"label": "Pecatonica", "value": "C131896"},
              {"label": "Pekin", "value": "C131897"},
              {"label": "Peoria", "value": "C131898"},
              {"label": "Peoria County", "value": "C131899"},
              {"label": "Peoria Heights", "value": "C131900"},
              {"label": "Peotone", "value": "C131901"},
              {"label": "Perry County", "value": "C131902"},
              {"label": "Peru", "value": "C131903"},
              {"label": "Petersburg", "value": "C131904"},
              {"label": "Philo", "value": "C131905"},
              {"label": "Phoenix", "value": "C131906"},
              {"label": "Piatt County", "value": "C131907"},
              {"label": "Pike County", "value": "C131908"},
              {"label": "Pinckneyville", "value": "C131909"},
              {"label": "Pingree Grove", "value": "C131910"},
              {"label": "Pistakee Highlands", "value": "C131911"},
              {"label": "Pittsfield", "value": "C131912"},
              {"label": "Plainfield", "value": "C131913"},
              {"label": "Plano", "value": "C131914"},
              {"label": "Polo", "value": "C131915"},
              {"label": "Pontiac", "value": "C131916"},
              {"label": "Pontoon Beach", "value": "C131917"},
              {"label": "Pope County", "value": "C131918"},
              {"label": "Poplar Grove", "value": "C131919"},
              {"label": "Port Barrington", "value": "C131920"},
              {"label": "Port Byron", "value": "C131921"},
              {"label": "Portage Park", "value": "C131922"},
              {"label": "Posen", "value": "C131923"},
              {"label": "Prairie Grove", "value": "C131924"},
              {"label": "Prestbury", "value": "C131925"},
              {"label": "Preston Heights", "value": "C131926"},
              {"label": "Princeton", "value": "C131927"},
              {"label": "Princeville", "value": "C131928"},
              {"label": "Prophetstown", "value": "C131929"},
              {"label": "Prospect Heights", "value": "C131930"},
              {"label": "Pulaski County", "value": "C131931"},
              {"label": "Putnam County", "value": "C131932"},
              {"label": "Quincy", "value": "C131933"},
              {"label": "Ramsey", "value": "C131934"},
              {"label": "Randolph County", "value": "C131935"},
              {"label": "Rantoul", "value": "C131936"},
              {"label": "Red Bud", "value": "C131937"},
              {"label": "Richland County", "value": "C131938"},
              {"label": "Richmond", "value": "C131939"},
              {"label": "Richton Park", "value": "C131940"},
              {"label": "River Forest", "value": "C131941"},
              {"label": "River Grove", "value": "C131942"},
              {"label": "Riverdale", "value": "C131943"},
              {"label": "Riverside", "value": "C131944"},
              {"label": "Riverton", "value": "C131945"},
              {"label": "Riverwoods", "value": "C131946"},
              {"label": "Roanoke", "value": "C131947"},
              {"label": "Robbins", "value": "C131948"},
              {"label": "Robinson", "value": "C131949"},
              {"label": "Rochelle", "value": "C131950"},
              {"label": "Rochester", "value": "C131951"},
              {"label": "Rock Falls", "value": "C131952"},
              {"label": "Rock Island", "value": "C131953"},
              {"label": "Rock Island County", "value": "C131954"},
              {"label": "Rockdale", "value": "C131955"},
              {"label": "Rockford", "value": "C131956"},
              {"label": "Rockton", "value": "C131957"},
              {"label": "Rogers Park", "value": "C131958"},
              {"label": "Rolling Meadows", "value": "C131959"},
              {"label": "Rome", "value": "C131960"},
              {"label": "Romeoville", "value": "C131961"},
              {"label": "Roodhouse", "value": "C131962"},
              {"label": "Roscoe", "value": "C131963"},
              {"label": "Roselle", "value": "C131964"},
              {"label": "Rosemont", "value": "C131965"},
              {"label": "Rosewood Heights", "value": "C131966"},
              {"label": "Rosiclare", "value": "C131967"},
              {"label": "Rossville", "value": "C131968"},
              {"label": "Round Lake", "value": "C131969"},
              {"label": "Round Lake Beach", "value": "C131970"},
              {"label": "Round Lake Heights", "value": "C131971"},
              {"label": "Round Lake Park", "value": "C131972"},
              {"label": "Roxana", "value": "C131973"},
              {"label": "Royalton", "value": "C131974"},
              {"label": "Rushville", "value": "C131975"},
              {"label": "Saint Anne", "value": "C131976"},
              {"label": "Saint Charles", "value": "C131977"},
              {"label": "Saint Clair County", "value": "C131978"},
              {"label": "Saint Elmo", "value": "C131979"},
              {"label": "Saint Jacob", "value": "C131980"},
              {"label": "Saint Joseph", "value": "C131981"},
              {"label": "Salem", "value": "C131982"},
              {"label": "Saline County", "value": "C131983"},
              {"label": "Sandoval", "value": "C131984"},
              {"label": "Sandwich", "value": "C131985"},
              {"label": "Sangamon County", "value": "C131986"},
              {"label": "Sauk Village", "value": "C131987"},
              {"label": "Savanna", "value": "C131988"},
              {"label": "Savoy", "value": "C131989"},
              {"label": "Schaumburg", "value": "C131990"},
              {"label": "Schiller Park", "value": "C131991"},
              {"label": "Schuyler County", "value": "C131992"},
              {"label": "Scott Air Force Base", "value": "C131993"},
              {"label": "Scott County", "value": "C131994"},
              {"label": "Seneca", "value": "C131995"},
              {"label": "Sesser", "value": "C131996"},
              {"label": "Shawneetown", "value": "C131997"},
              {"label": "Shelby County", "value": "C131998"},
              {"label": "Shelbyville", "value": "C131999"},
              {"label": "Sheldon", "value": "C132000"},
              {"label": "Sheridan", "value": "C132001"},
              {"label": "Sherman", "value": "C132002"},
              {"label": "Shiloh", "value": "C132003"},
              {"label": "Shorewood", "value": "C132004"},
              {"label": "Sidney", "value": "C132005"},
              {"label": "Silvis", "value": "C132006"},
              {"label": "Skokie", "value": "C132007"},
              {"label": "Sleepy Hollow", "value": "C132008"},
              {"label": "Smithton", "value": "C132009"},
              {"label": "Somonauk", "value": "C132010"},
              {"label": "South Barrington", "value": "C132011"},
              {"label": "South Beloit", "value": "C132012"},
              {"label": "South Chicago", "value": "C132013"},
              {"label": "South Chicago Heights", "value": "C132014"},
              {"label": "South Elgin", "value": "C132015"},
              {"label": "South Holland", "value": "C132016"},
              {"label": "South Jacksonville", "value": "C132017"},
              {"label": "South Lawndale", "value": "C132018"},
              {"label": "South Pekin", "value": "C132019"},
              {"label": "South Roxana", "value": "C132020"},
              {"label": "South Shore", "value": "C132021"},
              {"label": "Southern View", "value": "C132022"},
              {"label": "Sparta", "value": "C132023"},
              {"label": "Spring Grove", "value": "C132024"},
              {"label": "Spring Valley", "value": "C132025"},
              {"label": "Springfield", "value": "C132026"},
              {"label": "Stark County", "value": "C132027"},
              {"label": "Staunton", "value": "C132028"},
              {"label": "Steeleville", "value": "C132029"},
              {"label": "Steger", "value": "C132030"},
              {"label": "Stephenson County", "value": "C132031"},
              {"label": "Sterling", "value": "C132032"},
              {"label": "Stickney", "value": "C132033"},
              {"label": "Stillman Valley", "value": "C132034"},
              {"label": "Stockton", "value": "C132035"},
              {"label": "Stone Park", "value": "C132036"},
              {"label": "Streamwood", "value": "C132037"},
              {"label": "Streator", "value": "C132038"},
              {"label": "Sugar Grove", "value": "C132039"},
              {"label": "Sullivan", "value": "C132040"},
              {"label": "Summit", "value": "C132041"},
              {"label": "Sumner", "value": "C132042"},
              {"label": "Swansea", "value": "C132043"},
              {"label": "Sycamore", "value": "C132044"},
              {"label": "Taylorville", "value": "C132045"},
              {"label": "Tazewell County", "value": "C132046"},
              {"label": "Teutopolis", "value": "C132047"},
              {"label": "The Galena Territory", "value": "C132048"},
              {"label": "Third Lake", "value": "C132049"},
              {"label": "Thomasboro", "value": "C132050"},
              {"label": "Thornton", "value": "C132051"},
              {"label": "Tilton", "value": "C132052"},
              {"label": "Tinley Park", "value": "C132053"},
              {"label": "Toledo", "value": "C132054"},
              {"label": "Tolono", "value": "C132055"},
              {"label": "Toluca", "value": "C132056"},
              {"label": "Toulon", "value": "C132057"},
              {"label": "Tower Lake", "value": "C132058"},
              {"label": "Tremont", "value": "C132059"},
              {"label": "Trenton", "value": "C132060"},
              {"label": "Troy", "value": "C132061"},
              {"label": "Tuscola", "value": "C132062"},
              {"label": "Twin Grove", "value": "C132063"},
              {"label": "Union County", "value": "C132064"},
              {"label": "University Park", "value": "C132065"},
              {"label": "Upper Alton", "value": "C132066"},
              {"label": "Uptown", "value": "C132067"},
              {"label": "Urbana", "value": "C132068"},
              {"label": "Valmeyer", "value": "C132069"},
              {"label": "Vandalia", "value": "C132070"},
              {"label": "Venetian Village", "value": "C132071"},
              {"label": "Venice", "value": "C132072"},
              {"label": "Vermilion County", "value": "C132073"},
              {"label": "Vernon Hills", "value": "C132074"},
              {"label": "Vienna", "value": "C132075"},
              {"label": "Villa Grove", "value": "C132076"},
              {"label": "Villa Park", "value": "C132077"},
              {"label": "Village of Campton Hills", "value": "C132078"},
              {"label": "Virden", "value": "C132079"},
              {"label": "Virginia", "value": "C132080"},
              {"label": "Volo", "value": "C132081"},
              {"label": "Wabash County", "value": "C132082"},
              {"label": "Wadsworth", "value": "C132083"},
              {"label": "Walnut", "value": "C132084"},
              {"label": "Wamac", "value": "C132085"},
              {"label": "Warren", "value": "C132086"},
              {"label": "Warren County", "value": "C132087"},
              {"label": "Warrensburg", "value": "C132088"},
              {"label": "Warrenville", "value": "C132089"},
              {"label": "Warsaw", "value": "C132090"},
              {"label": "Wasco", "value": "C132091"},
              {"label": "Washburn", "value": "C132092"},
              {"label": "Washington", "value": "C132093"},
              {"label": "Washington County", "value": "C132094"},
              {"label": "Washington Park", "value": "C132095"},
              {"label": "Waterloo", "value": "C132096"},
              {"label": "Waterman", "value": "C132097"},
              {"label": "Watseka", "value": "C132098"},
              {"label": "Wauconda", "value": "C132099"},
              {"label": "Waukegan", "value": "C132100"},
              {"label": "Waverly", "value": "C132101"},
              {"label": "Wayne", "value": "C132102"},
              {"label": "Wayne City", "value": "C132103"},
              {"label": "Wayne County", "value": "C132104"},
              {"label": "West Chicago", "value": "C132105"},
              {"label": "West Dundee", "value": "C132106"},
              {"label": "West Elsdon", "value": "C132107"},
              {"label": "West Englewood", "value": "C132108"},
              {"label": "West Frankfort", "value": "C132109"},
              {"label": "West Garfield Park", "value": "C132110"},
              {"label": "West Lawn", "value": "C132111"},
              {"label": "West Peoria", "value": "C132112"},
              {"label": "West Ridge", "value": "C132113"},
              {"label": "West Town", "value": "C132114"},
              {"label": "Westchester", "value": "C132115"},
              {"label": "Western Springs", "value": "C132116"},
              {"label": "Westmont", "value": "C132117"},
              {"label": "Westville", "value": "C132118"},
              {"label": "Wheaton", "value": "C132119"},
              {"label": "Wheeling", "value": "C132120"},
              {"label": "White County", "value": "C132121"},
              {"label": "White Hall", "value": "C132122"},
              {"label": "Whiteside County", "value": "C132123"},
              {"label": "Will County", "value": "C132124"},
              {"label": "Williamson County", "value": "C132125"},
              {"label": "Williamsville", "value": "C132126"},
              {"label": "Willow Springs", "value": "C132127"},
              {"label": "Willowbrook", "value": "C132128"},
              {"label": "Wilmette", "value": "C132129"},
              {"label": "Wilmington", "value": "C132130"},
              {"label": "Winchester", "value": "C132131"},
              {"label": "Windsor", "value": "C132132"},
              {"label": "Winfield", "value": "C132133"},
              {"label": "Winnebago", "value": "C132134"},
              {"label": "Winnebago County", "value": "C132135"},
              {"label": "Winnetka", "value": "C132136"},
              {"label": "Winthrop Harbor", "value": "C132137"},
              {"label": "Wonder Lake", "value": "C132138"},
              {"label": "Wood Dale", "value": "C132139"},
              {"label": "Wood River", "value": "C132140"},
              {"label": "Woodford County", "value": "C132141"},
              {"label": "Woodlawn", "value": "C132142"},
              {"label": "Woodridge", "value": "C132143"},
              {"label": "Woodstock", "value": "C132144"},
              {"label": "Worden", "value": "C132145"},
              {"label": "Worth", "value": "C132146"},
              {"label": "Wyoming", "value": "C132147"},
              {"label": "Yorkville", "value": "C132148"},
              {"label": "Zeigler", "value": "C132149"},
              {"label": "Zion", "value": "C132150"}],
 "Illizi": [{"label": "Illizi", "value": "C348"}],
 "Ilocos Region": [{"label": "Acao", "value": "C86320"},
                   {"label": "Agno", "value": "C86321"},
                   {"label": "Agoo", "value": "C86322"},
                   {"label": "Aguilar", "value": "C86323"},
                   {"label": "Alac", "value": "C86324"},
                   {"label": "Alaminos", "value": "C86325"},
                   {"label": "Alcala", "value": "C86326"},
                   {"label": "Alilem", "value": "C86327"},
                   {"label": "Allangigan Primero", "value": "C86328"},
                   {"label": "Aloleng", "value": "C86329"},
                   {"label": "Amagbagan", "value": "C86330"},
                   {"label": "Anambongan", "value": "C86331"},
                   {"label": "Anda", "value": "C86332"},
                   {"label": "Angatel", "value": "C86333"},
                   {"label": "Anulid", "value": "C86334"},
                   {"label": "Aringay", "value": "C86335"},
                   {"label": "Asingan", "value": "C86336"},
                   {"label": "Baay", "value": "C86337"},
                   {"label": "Bacag", "value": "C86338"},
                   {"label": "Bacarra", "value": "C86339"},
                   {"label": "Bacnar", "value": "C86340"},
                   {"label": "Bacnotan", "value": "C86341"},
                   {"label": "Bactad Proper", "value": "C86342"},
                   {"label": "Bacundao Weste", "value": "C86343"},
                   {"label": "Badoc", "value": "C86344"},
                   {"label": "Bagulin", "value": "C86345"},
                   {"label": "Bail", "value": "C86346"},
                   {"label": "Balaoan", "value": "C86347"},
                   {"label": "Balingasay", "value": "C86348"},
                   {"label": "Balingueo", "value": "C86349"},
                   {"label": "Balogo", "value": "C86350"},
                   {"label": "Balungao", "value": "C86351"},
                   {"label": "Baluyot", "value": "C86352"},
                   {"label": "Banayoyo", "value": "C86353"},
                   {"label": "Bangan-Oda", "value": "C86354"},
                   {"label": "Bangar", "value": "C86355"},
                   {"label": "Bangui", "value": "C86356"},
                   {"label": "Bani", "value": "C86357"},
                   {"label": "Banog Sur", "value": "C86358"},
                   {"label": "Bantay", "value": "C86359"},
                   {"label": "Bantog", "value": "C86360"},
                   {"label": "Barangobong", "value": "C86361"},
                   {"label": "Baro", "value": "C86362"},
                   {"label": "Barong", "value": "C86363"},
                   {"label": "Basing", "value": "C86364"},
                   {"label": "Basista", "value": "C86365"},
                   {"label": "Batac City", "value": "C86366"},
                   {"label": "Bataquil", "value": "C86367"},
                   {"label": "Bauang", "value": "C86368"},
                   {"label": "Bautista", "value": "C86369"},
                   {"label": "Bayambang", "value": "C86370"},
                   {"label": "Bayaoas", "value": "C86371"},
                   {"label": "Bical Norte", "value": "C86372"},
                   {"label": "Bil-Loca", "value": "C86373"},
                   {"label": "Binabalian", "value": "C86374"},
                   {"label": "Binalonan", "value": "C86375"},
                   {"label": "Binday", "value": "C86376"},
                   {"label": "Binmaley", "value": "C86377"},
                   {"label": "Bobonan", "value": "C86378"},
                   {"label": "Bogtong", "value": "C86379"},
                   {"label": "Bolaoit", "value": "C86380"},
                   {"label": "Bolingit", "value": "C86381"},
                   {"label": "Bolo", "value": "C86382"},
                   {"label": "Bongalon", "value": "C86383"},
                   {"label": "Botao", "value": "C86384"},
                   {"label": "Bued", "value": "C86385"},
                   {"label": "Buenlag", "value": "C86386"},
                   {"label": "Bugallon", "value": "C86387"},
                   {"label": "Bulog", "value": "C86388"},
                   {"label": "Burgos", "value": "C86389"},
                   {"label": "Butubut Norte", "value": "C86390"},
                   {"label": "Caabiangan", "value": "C86391"},
                   {"label": "Caba", "value": "C86392"},
                   {"label": "Cabalaoangan", "value": "C86393"},
                   {"label": "Cabalitian", "value": "C86394"},
                   {"label": "Cabittaogan", "value": "C86395"},
                   {"label": "Cabugao", "value": "C86396"},
                   {"label": "Cabungan", "value": "C86397"},
                   {"label": "Calasiao", "value": "C86398"},
                   {"label": "Calepaan", "value": "C86399"},
                   {"label": "Callaguip", "value": "C86400"},
                   {"label": "Calomboyan", "value": "C86401"},
                   {"label": "Calongbuyan", "value": "C86402"},
                   {"label": "Calsib", "value": "C86403"},
                   {"label": "Camaley", "value": "C86404"},
                   {"label": "Canan Norte", "value": "C86405"},
                   {"label": "Canaoalan", "value": "C86406"},
                   {"label": "Candon", "value": "C86407"},
                   {"label": "Cantoria", "value": "C86408"},
                   {"label": "Caoayan", "value": "C86409"},
                   {"label": "Capandanan", "value": "C86410"},
                   {"label": "Capulaan", "value": "C86411"},
                   {"label": "Caramutan", "value": "C86412"},
                   {"label": "Carasi", "value": "C86413"},
                   {"label": "Carmen", "value": "C86414"},
                   {"label": "Caronoan West", "value": "C86415"},
                   {"label": "Carot", "value": "C86416"},
                   {"label": "Carriedo", "value": "C86417"},
                   {"label": "Carusucan", "value": "C86418"},
                   {"label": "Catablan", "value": "C86419"},
                   {"label": "Caterman", "value": "C86420"},
                   {"label": "Cato", "value": "C86421"},
                   {"label": "Catuday", "value": "C86422"},
                   {"label": "Cayanga", "value": "C86423"},
                   {"label": "Cayungnan", "value": "C86424"},
                   {"label": "Cervantes", "value": "C86425"},
                   {"label": "Comillas Norte", "value": "C86426"},
                   {"label": "Corrooy", "value": "C86427"},
                   {"label": "Currimao", "value": "C86428"},
                   {"label": "Dagup", "value": "C86429"},
                   {"label": "Dagupan", "value": "C86430"},
                   {"label": "Damortis", "value": "C86431"},
                   {"label": "Darapidap", "value": "C86432"},
                   {"label": "Dasol", "value": "C86433"},
                   {"label": "Davila", "value": "C86434"},
                   {"label": "Diaz", "value": "C86435"},
                   {"label": "Dilan", "value": "C86436"},
                   {"label": "Dingras", "value": "C86437"},
                   {"label": "Domalanoan", "value": "C86438"},
                   {"label": "Domampot", "value": "C86439"},
                   {"label": "Don Pedro", "value": "C86440"},
                   {"label": "Dorongan Punta", "value": "C86441"},
                   {"label": "Doyong", "value": "C86442"},
                   {"label": "Dulig", "value": "C86443"},
                   {"label": "Dumalneg", "value": "C86444"},
                   {"label": "Dumpay", "value": "C86445"},
                   {"label": "Eguia", "value": "C86446"},
                   {"label": "Esmeralda", "value": "C86447"},
                   {"label": "Espiritu", "value": "C86448"},
                   {"label": "Fuerte", "value": "C86449"},
                   {"label": "Galimuyod", "value": "C86450"},
                   {"label": "Gayaman", "value": "C86451"},
                   {"label": "Gregorio del Pilar", "value": "C86452"},
                   {"label": "Guiling", "value": "C86453"},
                   {"label": "Guiset East", "value": "C86454"},
                   {"label": "Hacienda", "value": "C86455"},
                   {"label": "Halog West", "value": "C86456"},
                   {"label": "Ilioilio", "value": "C86457"},
                   {"label": "Inabaan Sur", "value": "C86458"},
                   {"label": "Infanta", "value": "C86459"},
                   {"label": "Isla", "value": "C86460"},
                   {"label": "Labayug", "value": "C86461"},
                   {"label": "Labney", "value": "C86462"},
                   {"label": "Labrador", "value": "C86463"},
                   {"label": "Lagasit", "value": "C86464"},
                   {"label": "Laguit Centro", "value": "C86465"},
                   {"label": "Laoag", "value": "C86466"},
                   {"label": "Leones East", "value": "C86467"},
                   {"label": "Lepa", "value": "C86468"},
                   {"label": "Libas", "value": "C86469"},
                   {"label": "Lidlidda", "value": "C86470"},
                   {"label": "Lingayen", "value": "C86471"},
                   {"label": "Linmansangan", "value": "C86472"},
                   {"label": "Lloren", "value": "C86473"},
                   {"label": "Lobong", "value": "C86474"},
                   {"label": "Longos", "value": "C86475"},
                   {"label": "Loqueb Este", "value": "C86476"},
                   {"label": "Lucap", "value": "C86477"},
                   {"label": "Lucero", "value": "C86478"},
                   {"label": "Luna", "value": "C86479"},
                   {"label": "Lunec", "value": "C86480"},
                   {"label": "Lungog", "value": "C86481"},
                   {"label": "Lusong", "value": "C86482"},
                   {"label": "Mabilao", "value": "C86483"},
                   {"label": "Mabilbila Sur", "value": "C86484"},
                   {"label": "Mabini", "value": "C86485"},
                   {"label": "Mabusag", "value": "C86486"},
                   {"label": "Macabuboni", "value": "C86487"},
                   {"label": "Macalong", "value": "C86488"},
                   {"label": "Macalva Norte", "value": "C86489"},
                   {"label": "Macayug", "value": "C86490"},
                   {"label": "Magallanes", "value": "C86491"},
                   {"label": "Magsingal", "value": "C86492"},
                   {"label": "Magtaking", "value": "C86493"},
                   {"label": "Malabago", "value": "C86494"},
                   {"label": "Malanay", "value": "C86495"},
                   {"label": "Malasiqui", "value": "C86496"},
                   {"label": "Malawa", "value": "C86497"},
                   {"label": "Malibong East", "value": "C86498"},
                   {"label": "Manaoag", "value": "C86499"},
                   {"label": "Mangaldan", "value": "C86500"},
                   {"label": "Mangatarem", "value": "C86501"},
                   {"label": "Mapandan", "value": "C86502"},
                   {"label": "Mapolopolo", "value": "C86503"},
                   {"label": "Marcos", "value": "C86504"},
                   {"label": "Maticmatic", "value": "C86505"},
                   {"label": "Minien East", "value": "C86506"},
                   {"label": "Nagbacalan", "value": "C86507"},
                   {"label": "Nagsaing", "value": "C86508"},
                   {"label": "Naguelguel", "value": "C86509"},
                   {"label": "Naguilayan", "value": "C86510"},
                   {"label": "Naguilian", "value": "C86511"},
                   {"label": "Nalsian Norte", "value": "C86512"},
                   {"label": "Nama", "value": "C86513"},
                   {"label": "Namboongan", "value": "C86514"},
                   {"label": "Nancalobasaan", "value": "C86515"},
                   {"label": "Narvacan", "value": "C86516"},
                   {"label": "Natividad", "value": "C86517"},
                   {"label": "Navatat", "value": "C86518"},
                   {"label": "Nibaliw Central", "value": "C86519"},
                   {"label": "Nilombot", "value": "C86520"},
                   {"label": "Ninoy", "value": "C86521"},
                   {"label": "Nueva Era", "value": "C86522"},
                   {"label": "Oaqui", "value": "C86523"},
                   {"label": "Olea", "value": "C86524"},
                   {"label": "Padong", "value": "C86525"},
                   {"label": "Pagsanahan Norte", "value": "C86526"},
                   {"label": "Pagudpud", "value": "C86527"},
                   {"label": "Paitan Este", "value": "C86528"},
                   {"label": "Palacpalac", "value": "C86529"},
                   {"label": "Paldit", "value": "C86530"},
                   {"label": "Palguyod", "value": "C86531"},
                   {"label": "Panganiban", "value": "C86532"},
                   {"label": "Pangapisan", "value": "C86533"},
                   {"label": "Pangascasan", "value": "C86534"},
                   {"label": "Pangpang", "value": "C86535"},
                   {"label": "Paoay", "value": "C86536"},
                   {"label": "Paringao", "value": "C86537"},
                   {"label": "Parioc Segundo", "value": "C86538"},
                   {"label": "Pasibi West", "value": "C86539"},
                   {"label": "Pasuquin", "value": "C86540"},
                   {"label": "Patayac", "value": "C86541"},
                   {"label": "Patpata Segundo", "value": "C86542"},
                   {"label": "Payocpoc Sur", "value": "C86543"},
                   {"label": "Piddig", "value": "C86544"},
                   {"label": "Pindangan Centro", "value": "C86545"},
                   {"label": "Pinili", "value": "C86546"},
                   {"label": "Pogonsili", "value": "C86547"},
                   {"label": "Polo", "value": "C86548"},
                   {"label": "Polong", "value": "C86549"},
                   {"label": "Polong Norte", "value": "C86550"},
                   {"label": "Pozorrubio", "value": "C86551"},
                   {"label": "Province of Ilocos Norte", "value": "C86552"},
                   {"label": "Province of Ilocos Sur", "value": "C86553"},
                   {"label": "Province of La Union", "value": "C86554"},
                   {"label": "Province of Pangasinan", "value": "C86555"},
                   {"label": "Pudoc", "value": "C86556"},
                   {"label": "Pudoc North", "value": "C86557"},
                   {"label": "Puelay", "value": "C86558"},
                   {"label": "Pugo", "value": "C86559"},
                   {"label": "Puro Pinget", "value": "C86560"},
                   {"label": "Quiling", "value": "C86561"},
                   {"label": "Quinarayan", "value": "C86562"},
                   {"label": "Quintong", "value": "C86563"},
                   {"label": "Quirino", "value": "C86564"},
                   {"label": "Ranao", "value": "C86565"},
                   {"label": "Real", "value": "C86566"},
                   {"label": "Rimus", "value": "C86567"},
                   {"label": "Rissing", "value": "C86568"},
                   {"label": "Rosales", "value": "C86569"},
                   {"label": "Rosario", "value": "C86570"},
                   {"label": "Sablig", "value": "C86571"},
                   {"label": "Sagud-Bahley", "value": "C86572"},
                   {"label": "Sagunto", "value": "C86573"},
                   {"label": "Salcedo", "value": "C86574"},
                   {"label": "Samon", "value": "C86575"},
                   {"label": "San Emilio", "value": "C86576"},
                   {"label": "San Esteban", "value": "C86577"},
                   {"label": "San Eugenio", "value": "C86578"},
                   {"label": "San Fabian", "value": "C86579"},
                   {"label": "San Fernando", "value": "C86580"},
                   {"label": "San Gabriel", "value": "C86581"},
                   {"label": "San Gabriel First", "value": "C86582"},
                   {"label": "San Ildefonso", "value": "C86583"},
                   {"label": "San Jacinto", "value": "C86584"},
                   {"label": "San Juan", "value": "C86585"},
                   {"label": "San Lorenzo", "value": "C86586"},
                   {"label": "San Manuel", "value": "C86587"},
                   {"label": "San Nicolas", "value": "C86588"},
                   {"label": "San Pedro Apartado", "value": "C86589"},
                   {"label": "San Quintin", "value": "C86590"},
                   {"label": "San Sebastian", "value": "C86591"},
                   {"label": "San Vicente", "value": "C86592"},
                   {"label": "Sanlibo", "value": "C86593"},
                   {"label": "Santa", "value": "C86594"},
                   {"label": "Santa Barbara", "value": "C86595"},
                   {"label": "Santa Catalina", "value": "C86596"},
                   {"label": "Santa Cruz", "value": "C86597"},
                   {"label": "Santa Lucia", "value": "C86598"},
                   {"label": "Santa Maria", "value": "C86599"},
                   {"label": "Santiago", "value": "C86600"},
                   {"label": "Santo Domingo", "value": "C86601"},
                   {"label": "Santo Tomas", "value": "C86602"},
                   {"label": "Santol", "value": "C86603"},
                   {"label": "Sarrat", "value": "C86604"},
                   {"label": "Sigay", "value": "C86605"},
                   {"label": "Sinait", "value": "C86606"},
                   {"label": "Sison", "value": "C86607"},
                   {"label": "Solsona", "value": "C86608"},
                   {"label": "Sonquil", "value": "C86609"},
                   {"label": "Sual", "value": "C86610"},
                   {"label": "Subusub", "value": "C86611"},
                   {"label": "Sudipen", "value": "C86612"},
                   {"label": "Sugpon", "value": "C86613"},
                   {"label": "Sumabnit", "value": "C86614"},
                   {"label": "Suso", "value": "C86615"},
                   {"label": "Suyo", "value": "C86616"},
                   {"label": "Tablac", "value": "C86617"},
                   {"label": "Tabug", "value": "C86618"},
                   {"label": "Tagudin", "value": "C86619"},
                   {"label": "Talospatang", "value": "C86620"},
                   {"label": "Taloy", "value": "C86621"},
                   {"label": "Tamayo", "value": "C86622"},
                   {"label": "Tamorong", "value": "C86623"},
                   {"label": "Tandoc", "value": "C86624"},
                   {"label": "Tanolong", "value": "C86625"},
                   {"label": "Tayug", "value": "C86626"},
                   {"label": "Tebag East", "value": "C86627"},
                   {"label": "Telbang", "value": "C86628"},
                   {"label": "Tiep", "value": "C86629"},
                   {"label": "Toboy", "value": "C86630"},
                   {"label": "Tobuan", "value": "C86631"},
                   {"label": "Tococ East", "value": "C86632"},
                   {"label": "Tocok", "value": "C86633"},
                   {"label": "Tombod", "value": "C86634"},
                   {"label": "Tondol", "value": "C86635"},
                   {"label": "Toritori", "value": "C86636"},
                   {"label": "Tubao", "value": "C86637"},
                   {"label": "Umanday Centro", "value": "C86638"},
                   {"label": "Umingan", "value": "C86639"},
                   {"label": "Unzad", "value": "C86640"},
                   {"label": "Urbiztondo", "value": "C86641"},
                   {"label": "Urdaneta", "value": "C86642"},
                   {"label": "Uyong", "value": "C86643"},
                   {"label": "Vigan", "value": "C86644"},
                   {"label": "Villanueva", "value": "C86645"},
                   {"label": "Villasis", "value": "C86646"},
                   {"label": "Vintar", "value": "C86647"},
                   {"label": "Zaragoza", "value": "C86648"}],
 "Ilukste Municipality": [{"label": "Ilukste", "value": "C64872"}],
 "Imbabura": [{"label": "Atuntaqui", "value": "C24151"},
              {"label": "Cotacachi", "value": "C24152"},
              {"label": "Ibarra", "value": "C24153"},
              {"label": "Otavalo", "value": "C24154"},
              {"label": "Pimampiro", "value": "C24155"}],
 "Imereti": [{"label": "Baghdatis Munitsipaliteti", "value": "C34280"},
             {"label": "Chiatura", "value": "C34281"},
             {"label": "Kalaki Chiatura", "value": "C34282"},
             {"label": "Kulashi", "value": "C34283"},
             {"label": "Kharagauli", "value": "C34284"},
             {"label": "Khoni", "value": "C34285"},
             {"label": "Kutaisi", "value": "C34286"},
             {"label": "Sachkhere", "value": "C34287"},
             {"label": "Samtredia", "value": "C34288"},
             {"label": "Shorapani", "value": "C34289"},
             {"label": "Tqibuli", "value": "C34290"},
             {"label": "Tsqaltubo", "value": "C34291"},
             {"label": "Vani", "value": "C34292"},
             {"label": "Zestaponi", "value": "C34293"}],
 "Imishli District": [{"label": "Imishli", "value": "C8440"}],
 "Imo": [{"label": "Iho", "value": "C78282"},
         {"label": "Oguta", "value": "C78283"},
         {"label": "Okigwe", "value": "C78284"},
         {"label": "Orlu", "value": "C78285"},
         {"label": "Orodo", "value": "C78286"},
         {"label": "Owerri", "value": "C78287"}],
 "Inagua": [{"label": "Matthew Town", "value": "C119613"}],
 "Incheon": [{"label": "Bupyeong-gu", "value": "C108344"},
             {"label": "Incheon", "value": "C108345"},
             {"label": "Michuhol", "value": "C108346"},
             {"label": "Yeonsu-gu", "value": "C108347"}],
 "Inchiri": [{"label": "Akjoujt", "value": "C65959"}],
 "Incukalns Municipality": [{"label": "Incukalns", "value": "C64873"},
                            {"label": "Vangazi", "value": "C64874"}],
 "Independencia": [{"label": "Cristobal", "value": "C23910"},
                   {"label": "Duverge", "value": "C23911"},
                   {"label": "Guayabal", "value": "C23912"},
                   {"label": "Jimani", "value": "C23913"},
                   {"label": "La Descubierta", "value": "C23914"},
                   {"label": "Mella", "value": "C23915"},
                   {"label": "Postrer Rio", "value": "C23916"}],
 "Indiana": [{"label": "Aberdeen", "value": "C132151"},
             {"label": "Adams County", "value": "C132152"},
             {"label": "Akron", "value": "C132153"},
             {"label": "Albany", "value": "C132154"},
             {"label": "Albion", "value": "C132155"},
             {"label": "Alexandria", "value": "C132156"},
             {"label": "Allen County", "value": "C132157"},
             {"label": "Anderson", "value": "C132158"},
             {"label": "Andrews", "value": "C132159"},
             {"label": "Angola", "value": "C132160"},
             {"label": "Arcadia", "value": "C132161"},
             {"label": "Argos", "value": "C132162"},
             {"label": "Attica", "value": "C132163"},
             {"label": "Auburn", "value": "C132164"},
             {"label": "Aurora", "value": "C132165"},
             {"label": "Austin", "value": "C132166"},
             {"label": "Avilla", "value": "C132167"},
             {"label": "Avon", "value": "C132168"},
             {"label": "Bargersville", "value": "C132169"},
             {"label": "Bartholomew County", "value": "C132170"},
             {"label": "Bass Lake", "value": "C132171"},
             {"label": "Batesville", "value": "C132172"},
             {"label": "Battle Ground", "value": "C132173"},
             {"label": "Bedford", "value": "C132174"},
             {"label": "Beech Grove", "value": "C132175"},
             {"label": "Benton County", "value": "C132176"},
             {"label": "Berne", "value": "C132177"},
             {"label": "Bicknell", "value": "C132178"},
             {"label": "Blackford County", "value": "C132179"},
             {"label": "Bloomfield", "value": "C132180"},
             {"label": "Bloomington", "value": "C132181"},
             {"label": "Bluffton", "value": "C132182"},
             {"label": "Boone County", "value": "C132183"},
             {"label": "Boonville", "value": "C132184"},
             {"label": "Bourbon", "value": "C132185"},
             {"label": "Brazil", "value": "C132186"},
             {"label": "Bremen", "value": "C132187"},
             {"label": "Bright", "value": "C132188"},
             {"label": "Bristol", "value": "C132189"},
             {"label": "Broad Ripple", "value": "C132190"},
             {"label": "Brooklyn", "value": "C132191"},
             {"label": "Brookston", "value": "C132192"},
             {"label": "Brookville", "value": "C132193"},
             {"label": "Brown County", "value": "C132194"},
             {"label": "Brownsburg", "value": "C132195"},
             {"label": "Brownstown", "value": "C132196"},
             {"label": "Burns Harbor", "value": "C132197"},
             {"label": "Butler", "value": "C132198"},
             {"label": "Cambridge City", "value": "C132199"},
             {"label": "Cannelton", "value": "C132200"},
             {"label": "Carmel", "value": "C132201"},
             {"label": "Carroll County", "value": "C132202"},
             {"label": "Cass County", "value": "C132203"},
             {"label": "Cayuga", "value": "C132204"},
             {"label": "Cedar Lake", "value": "C132205"},
             {"label": "Centerville", "value": "C132206"},
             {"label": "Chandler", "value": "C132207"},
             {"label": "Charlestown", "value": "C132208"},
             {"label": "Chesterfield", "value": "C132209"},
             {"label": "Chesterton", "value": "C132210"},
             {"label": "Churubusco", "value": "C132211"},
             {"label": "Cicero", "value": "C132212"},
             {"label": "Clark County", "value": "C132213"},
             {"label": "Clarksville", "value": "C132214"},
             {"label": "Clay County", "value": "C132215"},
             {"label": "Clermont", "value": "C132216"},
             {"label": "Clinton", "value": "C132217"},
             {"label": "Clinton County", "value": "C132218"},
             {"label": "Cloverdale", "value": "C132219"},
             {"label": "Columbia City", "value": "C132220"},
             {"label": "Columbus", "value": "C132221"},
             {"label": "Connersville", "value": "C132222"},
             {"label": "Converse", "value": "C132223"},
             {"label": "Cordry Sweetwater Lakes", "value": "C132224"},
             {"label": "Corydon", "value": "C132225"},
             {"label": "Country Squire Lakes", "value": "C132226"},
             {"label": "Covington", "value": "C132227"},
             {"label": "Crawford County", "value": "C132228"},
             {"label": "Crawfordsville", "value": "C132229"},
             {"label": "Crothersville", "value": "C132230"},
             {"label": "Crown Point", "value": "C132231"},
             {"label": "Culver", "value": "C132232"},
             {"label": "Cumberland", "value": "C132233"},
             {"label": "Dale", "value": "C132234"},
             {"label": "Daleville", "value": "C132235"},
             {"label": "Danville", "value": "C132236"},
             {"label": "Darmstadt", "value": "C132237"},
             {"label": "Daviess County", "value": "C132238"},
             {"label": "Dayton", "value": "C132239"},
             {"label": "Dearborn County", "value": "C132240"},
             {"label": "Decatur", "value": "C132241"},
             {"label": "Decatur County", "value": "C132242"},
             {"label": "DeKalb County", "value": "C132243"},
             {"label": "Delaware County", "value": "C132244"},
             {"label": "Delphi", "value": "C132245"},
             {"label": "DeMotte", "value": "C132246"},
             {"label": "Dillsboro", "value": "C132247"},
             {"label": "Dubois County", "value": "C132248"},
             {"label": "Dunkirk", "value": "C132249"},
             {"label": "Dunlap", "value": "C132250"},
             {"label": "Dyer", "value": "C132251"},
             {"label": "East Chicago", "value": "C132252"},
             {"label": "Eaton", "value": "C132253"},
             {"label": "Edgewood", "value": "C132254"},
             {"label": "Edinburgh", "value": "C132255"},
             {"label": "Elkhart", "value": "C132256"},
             {"label": "Elkhart County", "value": "C132257"},
             {"label": "Ellettsville", "value": "C132258"},
             {"label": "Elwood", "value": "C132259"},
             {"label": "English", "value": "C132260"},
             {"label": "Evansville", "value": "C132261"},
             {"label": "Fairfield Heights", "value": "C132262"},
             {"label": "Fairmount", "value": "C132263"},
             {"label": "Fairview Park", "value": "C132264"},
             {"label": "Farmersburg", "value": "C132265"},
             {"label": "Farmland", "value": "C132266"},
             {"label": "Fayette County", "value": "C132267"},
             {"label": "Ferdinand", "value": "C132268"},
             {"label": "Fish Lake", "value": "C132269"},
             {"label": "Fishers", "value": "C132270"},
             {"label": "Flora", "value": "C132271"},
             {"label": "Floyd County", "value": "C132272"},
             {"label": "Fort Branch", "value": "C132273"},
             {"label": "Fort Wayne", "value": "C132274"},
             {"label": "Fortville", "value": "C132275"},
             {"label": "Fountain County", "value": "C132276"},
             {"label": "Fowler", "value": "C132277"},
             {"label": "Frankfort", "value": "C132278"},
             {"label": "Franklin", "value": "C132279"},
             {"label": "Franklin County", "value": "C132280"},
             {"label": "Frankton", "value": "C132281"},
             {"label": "Fremont", "value": "C132282"},
             {"label": "French Lick", "value": "C132283"},
             {"label": "Fulton County", "value": "C132284"},
             {"label": "Galena", "value": "C132285"},
             {"label": "Galveston", "value": "C132286"},
             {"label": "Garrett", "value": "C132287"},
             {"label": "Gary", "value": "C132288"},
             {"label": "Gas City", "value": "C132289"},
             {"label": "Geneva", "value": "C132290"},
             {"label": "Georgetown", "value": "C132291"},
             {"label": "Gibson County", "value": "C132292"},
             {"label": "Goodland", "value": "C132293"},
             {"label": "Goshen", "value": "C132294"},
             {"label": "Grabill", "value": "C132295"},
             {"label": "Granger", "value": "C132296"},
             {"label": "Grant County", "value": "C132297"},
             {"label": "Greencastle", "value": "C132298"},
             {"label": "Greendale", "value": "C132299"},
             {"label": "Greene County", "value": "C132300"},
             {"label": "Greenfield", "value": "C132301"},
             {"label": "Greensburg", "value": "C132302"},
             {"label": "Greentown", "value": "C132303"},
             {"label": "Greenwood", "value": "C132304"},
             {"label": "Griffith", "value": "C132305"},
             {"label": "Grissom Air Force Base", "value": "C132306"},
             {"label": "Gulivoire Park", "value": "C132307"},
             {"label": "Hagerstown", "value": "C132308"},
             {"label": "Hamilton", "value": "C132309"},
             {"label": "Hamilton County", "value": "C132310"},
             {"label": "Hammond", "value": "C132311"},
             {"label": "Hancock County", "value": "C132312"},
             {"label": "Hanover", "value": "C132313"},
             {"label": "Harlan", "value": "C132314"},
             {"label": "Harrison County", "value": "C132315"},
             {"label": "Hartford City", "value": "C132316"},
             {"label": "Haubstadt", "value": "C132317"},
             {"label": "Hebron", "value": "C132318"},
             {"label": "Hendricks County", "value": "C132319"},
             {"label": "Henry County", "value": "C132320"},
             {"label": "Henryville", "value": "C132321"},
             {"label": "Heritage Lake", "value": "C132322"},
             {"label": "Hidden Valley", "value": "C132323"},
             {"label": "Highland", "value": "C132324"},
             {"label": "Hobart", "value": "C132325"},
             {"label": "Hope", "value": "C132326"},
             {"label": "Howard County", "value": "C132327"},
             {"label": "Hudson Lake", "value": "C132328"},
             {"label": "Huntertown", "value": "C132329"},
             {"label": "Huntingburg", "value": "C132330"},
             {"label": "Huntington", "value": "C132331"},
             {"label": "Huntington County", "value": "C132332"},
             {"label": "Indian Heights", "value": "C132333"},
             {"label": "Indianapolis", "value": "C132334"},
             {"label": "Ingalls", "value": "C132335"},
             {"label": "Jackson County", "value": "C132336"},
             {"label": "Jasonville", "value": "C132337"},
             {"label": "Jasper", "value": "C132338"},
             {"label": "Jasper County", "value": "C132339"},
             {"label": "Jay County", "value": "C132340"},
             {"label": "Jefferson County", "value": "C132341"},
             {"label": "Jeffersonville", "value": "C132342"},
             {"label": "Jennings County", "value": "C132343"},
             {"label": "Johnson County", "value": "C132344"},
             {"label": "Jonesboro", "value": "C132345"},
             {"label": "Kendallville", "value": "C132346"},
             {"label": "Kentland", "value": "C132347"},
             {"label": "Kingsford Heights", "value": "C132348"},
             {"label": "Knightstown", "value": "C132349"},
             {"label": "Knox", "value": "C132350"},
             {"label": "Knox County", "value": "C132351"},
             {"label": "Kokomo", "value": "C132352"},
             {"label": "Koontz Lake", "value": "C132353"},
             {"label": "Kosciusko County", "value": "C132354"},
             {"label": "Kouts", "value": "C132355"},
             {"label": "La Porte", "value": "C132356"},
             {"label": "Lafayette", "value": "C132357"},
             {"label": "Lagrange", "value": "C132358"},
             {"label": "LaGrange County", "value": "C132359"},
             {"label": "Lake County", "value": "C132360"},
             {"label": "Lake Dalecarlia", "value": "C132361"},
             {"label": "Lake Station", "value": "C132362"},
             {"label": "Lakes of the Four Seasons", "value": "C132363"},
             {"label": "Lapel", "value": "C132364"},
             {"label": "LaPorte", "value": "C132365"},
             {"label": "LaPorte County", "value": "C132366"},
             {"label": "Lawrence", "value": "C132367"},
             {"label": "Lawrence County", "value": "C132368"},
             {"label": "Lawrenceburg", "value": "C132369"},
             {"label": "Lebanon", "value": "C132370"},
             {"label": "Leo-Cedarville", "value": "C132371"},
             {"label": "Liberty", "value": "C132372"},
             {"label": "Ligonier", "value": "C132373"},
             {"label": "Linton", "value": "C132374"},
             {"label": "Logansport", "value": "C132375"},
             {"label": "Long Beach", "value": "C132376"},
             {"label": "Loogootee", "value": "C132377"},
             {"label": "Lowell", "value": "C132378"},
             {"label": "Lynn", "value": "C132379"},
             {"label": "Madison", "value": "C132380"},
             {"label": "Madison County", "value": "C132381"},
             {"label": "Marion", "value": "C132382"},
             {"label": "Marion County", "value": "C132383"},
             {"label": "Markle", "value": "C132384"},
             {"label": "Marshall County", "value": "C132385"},
             {"label": "Martin County", "value": "C132386"},
             {"label": "Martinsville", "value": "C132387"},
             {"label": "McCordsville", "value": "C132388"},
             {"label": "Melody Hill", "value": "C132389"},
             {"label": "Meridian Hills", "value": "C132390"},
             {"label": "Merrillville", "value": "C132391"},
             {"label": "Miami County", "value": "C132392"},
             {"label": "Michigan City", "value": "C132393"},
             {"label": "Middlebury", "value": "C132394"},
             {"label": "Middletown", "value": "C132395"},
             {"label": "Milan", "value": "C132396"},
             {"label": "Milford", "value": "C132397"},
             {"label": "Mishawaka", "value": "C132398"},
             {"label": "Mitchell", "value": "C132399"},
             {"label": "Monon", "value": "C132400"},
             {"label": "Monroe County", "value": "C132401"},
             {"label": "Monroeville", "value": "C132402"},
             {"label": "Monrovia", "value": "C132403"},
             {"label": "Montgomery County", "value": "C132404"},
             {"label": "Monticello", "value": "C132405"},
             {"label": "Montpelier", "value": "C132406"},
             {"label": "Mooresville", "value": "C132407"},
             {"label": "Morgan County", "value": "C132408"},
             {"label": "Morocco", "value": "C132409"},
             {"label": "Morristown", "value": "C132410"},
             {"label": "Mount Vernon", "value": "C132411"},
             {"label": "Mulberry", "value": "C132412"},
             {"label": "Muncie", "value": "C132413"},
             {"label": "Munster", "value": "C132414"},
             {"label": "Nappanee", "value": "C132415"},
             {"label": "Nashville", "value": "C132416"},
             {"label": "New Albany", "value": "C132417"},
             {"label": "New Carlisle", "value": "C132418"},
             {"label": "New Castle", "value": "C132419"},
             {"label": "New Chicago", "value": "C132420"},
             {"label": "New Haven", "value": "C132421"},
             {"label": "New Palestine", "value": "C132422"},
             {"label": "New Paris", "value": "C132423"},
             {"label": "New Pekin", "value": "C132424"},
             {"label": "New Whiteland", "value": "C132425"},
             {"label": "Newburgh", "value": "C132426"},
             {"label": "Newport", "value": "C132427"},
             {"label": "Newton County", "value": "C132428"},
             {"label": "Noble County", "value": "C132429"},
             {"label": "Noblesville", "value": "C132430"},
             {"label": "North Judson", "value": "C132431"},
             {"label": "North Liberty", "value": "C132432"},
             {"label": "North Madison", "value": "C132433"},
             {"label": "North Manchester", "value": "C132434"},
             {"label": "North Terre Haute", "value": "C132435"},
             {"label": "North Vernon", "value": "C132436"},
             {"label": "North Webster", "value": "C132437"},
             {"label": "Notre Dame", "value": "C132438"},
             {"label": "Oak Park", "value": "C132439"},
             {"label": "Oakland City", "value": "C132440"},
             {"label": "Odon", "value": "C132441"},
             {"label": "Ogden Dunes", "value": "C132442"},
             {"label": "Ohio County", "value": "C132443"},
             {"label": "Oolitic", "value": "C132444"},
             {"label": "Orange County", "value": "C132445"},
             {"label": "Orleans", "value": "C132446"},
             {"label": "Osceola", "value": "C132447"},
             {"label": "Osgood", "value": "C132448"},
             {"label": "Ossian", "value": "C132449"},
             {"label": "Otterbein", "value": "C132450"},
             {"label": "Owen County", "value": "C132451"},
             {"label": "Owensville", "value": "C132452"},
             {"label": "Oxford", "value": "C132453"},
             {"label": "Paoli", "value": "C132454"},
             {"label": "Parke County", "value": "C132455"},
             {"label": "Parker City", "value": "C132456"},
             {"label": "Pendleton", "value": "C132457"},
             {"label": "Perry County", "value": "C132458"},
             {"label": "Peru", "value": "C132459"},
             {"label": "Petersburg", "value": "C132460"},
             {"label": "Pierceton", "value": "C132461"},
             {"label": "Pike County", "value": "C132462"},
             {"label": "Pittsboro", "value": "C132463"},
             {"label": "Plainfield", "value": "C132464"},
             {"label": "Plymouth", "value": "C132465"},
             {"label": "Portage", "value": "C132466"},
             {"label": "Porter", "value": "C132467"},
             {"label": "Porter County", "value": "C132468"},
             {"label": "Portland", "value": "C132469"},
             {"label": "Posey County", "value": "C132470"},
             {"label": "Poseyville", "value": "C132471"},
             {"label": "Princes Lakes", "value": "C132472"},
             {"label": "Princeton", "value": "C132473"},
             {"label": "Pulaski County", "value": "C132474"},
             {"label": "Putnam County", "value": "C132475"},
             {"label": "Randolph County", "value": "C132476"},
             {"label": "Redkey", "value": "C132477"},
             {"label": "Remington", "value": "C132478"},
             {"label": "Rensselaer", "value": "C132479"},
             {"label": "Richmond", "value": "C132480"},
             {"label": "Ripley County", "value": "C132481"},
             {"label": "Rising Sun", "value": "C132482"},
             {"label": "Roanoke", "value": "C132483"},
             {"label": "Rochester", "value": "C132484"},
             {"label": "Rockport", "value": "C132485"},
             {"label": "Rockville", "value": "C132486"},
             {"label": "Rome City", "value": "C132487"},
             {"label": "Roselawn", "value": "C132488"},
             {"label": "Rossville", "value": "C132489"},
             {"label": "Rush County", "value": "C132490"},
             {"label": "Rushville", "value": "C132491"},
             {"label": "Russiaville", "value": "C132492"},
             {"label": "Saint John", "value": "C132493"},
             {"label": "Saint Joseph County", "value": "C132494"},
             {"label": "Saint Paul", "value": "C132495"},
             {"label": "Salem", "value": "C132496"},
             {"label": "Santa Claus", "value": "C132497"},
             {"label": "Schererville", "value": "C132498"},
             {"label": "Scott County", "value": "C132499"},
             {"label": "Scottsburg", "value": "C132500"},
             {"label": "Seelyville", "value": "C132501"},
             {"label": "Sellersburg", "value": "C132502"},
             {"label": "Seymour", "value": "C132503"},
             {"label": "Shadeland", "value": "C132504"},
             {"label": "Shelburn", "value": "C132505"},
             {"label": "Shelby County", "value": "C132506"},
             {"label": "Shelbyville", "value": "C132507"},
             {"label": "Sheridan", "value": "C132508"},
             {"label": "Shoals", "value": "C132509"},
             {"label": "Shorewood Forest", "value": "C132510"},
             {"label": "Simonton Lake", "value": "C132511"},
             {"label": "Smithville-Sanders", "value": "C132512"},
             {"label": "South Bend", "value": "C132513"},
             {"label": "South Haven", "value": "C132514"},
             {"label": "South Whitley", "value": "C132515"},
             {"label": "Southport", "value": "C132516"},
             {"label": "Speedway", "value": "C132517"},
             {"label": "Spencer", "value": "C132518"},
             {"label": "Spencer County", "value": "C132519"},
             {"label": "Starke County", "value": "C132520"},
             {"label": "Steuben County", "value": "C132521"},
             {"label": "Sullivan", "value": "C132522"},
             {"label": "Sullivan County", "value": "C132523"},
             {"label": "Sunman", "value": "C132524"},
             {"label": "Sweetser", "value": "C132525"},
             {"label": "Switzerland County", "value": "C132526"},
             {"label": "Syracuse", "value": "C132527"},
             {"label": "Tell City", "value": "C132528"},
             {"label": "Terre Haute", "value": "C132529"},
             {"label": "Thorntown", "value": "C132530"},
             {"label": "Tippecanoe County", "value": "C132531"},
             {"label": "Tipton", "value": "C132532"},
             {"label": "Tipton County", "value": "C132533"},
             {"label": "Topeka", "value": "C132534"},
             {"label": "Trafalgar", "value": "C132535"},
             {"label": "Trail Creek", "value": "C132536"},
             {"label": "Tri-Lakes", "value": "C132537"},
             {"label": "Union City", "value": "C132538"},
             {"label": "Union County", "value": "C132539"},
             {"label": "Upland", "value": "C132540"},
             {"label": "Valparaiso", "value": "C132541"},
             {"label": "Vanderburgh County", "value": "C132542"},
             {"label": "Veedersburg", "value": "C132543"},
             {"label": "Vermillion County", "value": "C132544"},
             {"label": "Versailles", "value": "C132545"},
             {"label": "Vevay", "value": "C132546"},
             {"label": "Vigo County", "value": "C132547"},
             {"label": "Vincennes", "value": "C132548"},
             {"label": "Wabash", "value": "C132549"},
             {"label": "Wabash County", "value": "C132550"},
             {"label": "Wakarusa", "value": "C132551"},
             {"label": "Walkerton", "value": "C132552"},
             {"label": "Walton", "value": "C132553"},
             {"label": "Wanatah", "value": "C132554"},
             {"label": "Warren", "value": "C132555"},
             {"label": "Warren County", "value": "C132556"},
             {"label": "Warren Park", "value": "C132557"},
             {"label": "Warrick County", "value": "C132558"},
             {"label": "Warsaw", "value": "C132559"},
             {"label": "Washington", "value": "C132560"},
             {"label": "Washington County", "value": "C132561"},
             {"label": "Waterloo", "value": "C132562"},
             {"label": "Wayne County", "value": "C132563"},
             {"label": "Wells County", "value": "C132564"},
             {"label": "West Lafayette", "value": "C132565"},
             {"label": "West Terre Haute", "value": "C132566"},
             {"label": "Westfield", "value": "C132567"},
             {"label": "Westport", "value": "C132568"},
             {"label": "Westville", "value": "C132569"},
             {"label": "White County", "value": "C132570"},
             {"label": "Whiteland", "value": "C132571"},
             {"label": "Whitestown", "value": "C132572"},
             {"label": "Whiting", "value": "C132573"},
             {"label": "Whitley County", "value": "C132574"},
             {"label": "Williamsport", "value": "C132575"},
             {"label": "Winamac", "value": "C132576"},
             {"label": "Winchester", "value": "C132577"},
             {"label": "Winfield", "value": "C132578"},
             {"label": "Winona Lake", "value": "C132579"},
             {"label": "Wolcottville", "value": "C132580"},
             {"label": "Woodburn", "value": "C132581"},
             {"label": "Worthington", "value": "C132582"},
             {"label": "Yorktown", "value": "C132583"},
             {"label": "Zionsville", "value": "C132584"}],
 "Inhambane Province": [{"label": "Inhambane", "value": "C75860"},
                        {"label": "Maxixe", "value": "C75861"}],
 "Inner Mongolia": [{"label": "Baotou", "value": "C18938"},
                    {"label": "Bayan Nur", "value": "C18939"},
                    {"label": "Bayannur Shi", "value": "C18940"},
                    {"label": "Beichengqu", "value": "C18941"},
                    {"label": "Chifeng", "value": "C18942"},
                    {"label": "Dongsheng", "value": "C18943"},
                    {"label": "Eerguna", "value": "C18944"},
                    {"label": "Erenhot", "value": "C18945"},
                    {"label": "Genhe", "value": "C18946"},
                    {"label": "Hailar", "value": "C18947"},
                    {"label": "Hohhot", "value": "C18948"},
                    {"label": "Hulunbuir Region", "value": "C18949"},
                    {"label": "Jalai Nur", "value": "C18950"},
                    {"label": "Jiagedaqi", "value": "C18951"},
                    {"label": "Jining", "value": "C18952"},
                    {"label": "Manzhouli", "value": "C18953"},
                    {"label": "Mositai", "value": "C18954"},
                    {"label": "Mujiayingzi", "value": "C18955"},
                    {"label": "Ordos", "value": "C18956"},
                    {"label": "Ordos Shi", "value": "C18957"},
                    {"label": "Oroqen Zizhiqi", "value": "C18958"},
                    {"label": "Pingzhuang", "value": "C18959"},
                    {"label": "Salaqi", "value": "C18960"},
                    {"label": "Shiguai", "value": "C18961"},
                    {"label": "Tongliao", "value": "C18962"},
                    {"label": "Ulanhot", "value": "C18963"},
                    {"label": "Wenquan", "value": "C18964"},
                    {"label": "Wuda", "value": "C18965"},
                    {"label": "Wuhai", "value": "C18966"},
                    {"label": "Xilin Gol Meng", "value": "C18967"},
                    {"label": "Xilin Hot", "value": "C18968"},
                    {"label": "Yakeshi", "value": "C18969"},
                    {"label": "Zhalantun", "value": "C18970"}],
 "Intibuca Department": [{"label": "Camasca", "value": "C43511"},
                         {"label": "Colomoncagua", "value": "C43512"},
                         {"label": "Concepcion", "value": "C43513"},
                         {"label": "Dolores", "value": "C43514"},
                         {"label": "Intibuca", "value": "C43515"},
                         {"label": "Jesus de Otoro", "value": "C43516"},
                         {"label": "Jiquinlaca", "value": "C43517"},
                         {"label": "La Esperanza", "value": "C43518"},
                         {"label": "Magdalena", "value": "C43519"},
                         {"label": "Masaguara", "value": "C43520"},
                         {"label": "San Antonio", "value": "C43521"},
                         {"label": "San Francisco de Opalaca",
                          "value": "C43522"},
                         {"label": "San Isidro", "value": "C43523"},
                         {"label": "San Juan", "value": "C43524"},
                         {"label": "San Marcos de la Sierra",
                          "value": "C43525"},
                         {"label": "San Miguelito", "value": "C43526"},
                         {"label": "Santa Lucia", "value": "C43527"},
                         {"label": "Yamaranguila", "value": "C43528"}],
 "Ionian Islands Region": [{"label": "Acharavi", "value": "C42265"},
                           {"label": "Agios Georgis", "value": "C42266"},
                           {"label": "agios Matthaios", "value": "C42267"},
                           {"label": "Alepou", "value": "C42268"},
                           {"label": "Ambelokipoi", "value": "C42269"},
                           {"label": "Argostolion", "value": "C42270"},
                           {"label": "Corfu", "value": "C42271"},
                           {"label": "Gaios", "value": "C42272"},
                           {"label": "Gaitanion", "value": "C42273"},
                           {"label": "Ithaki", "value": "C42274"},
                           {"label": "Kanali", "value": "C42275"},
                           {"label": "Katastarion", "value": "C42276"},
                           {"label": "Kontokali", "value": "C42277"},
                           {"label": "Kynopiastes", "value": "C42278"},
                           {"label": "Lefkada", "value": "C42279"},
                           {"label": "Lefkimmi", "value": "C42280"},
                           {"label": "Lithakia", "value": "C42281"},
                           {"label": "Lixouri", "value": "C42282"},
                           {"label": "Mouzaki", "value": "C42283"},
                           {"label": "Nomos Kerkyras", "value": "C42284"},
                           {"label": "Nomos Zakynthou", "value": "C42285"},
                           {"label": "Perama", "value": "C42286"},
                           {"label": "Perivoli", "value": "C42287"},
                           {"label": "Poros", "value": "C42288"},
                           {"label": "Potamos", "value": "C42289"},
                           {"label": "Sami", "value": "C42290"},
                           {"label": "Valsamata", "value": "C42291"},
                           {"label": "Vanaton", "value": "C42292"},
                           {"label": "Viros", "value": "C42293"},
                           {"label": "Zakynthos", "value": "C42294"}],
 "Iowa": [{"label": "Ackley", "value": "C132585"},
          {"label": "Adair County", "value": "C132586"},
          {"label": "Adams County", "value": "C132587"},
          {"label": "Adel", "value": "C132588"},
          {"label": "Akron", "value": "C132589"},
          {"label": "Albia", "value": "C132590"},
          {"label": "Algona", "value": "C132591"},
          {"label": "Allamakee County", "value": "C132592"},
          {"label": "Allison", "value": "C132593"},
          {"label": "Alta", "value": "C132594"},
          {"label": "Alton", "value": "C132595"},
          {"label": "Altoona", "value": "C132596"},
          {"label": "Ames", "value": "C132597"},
          {"label": "Anamosa", "value": "C132598"},
          {"label": "Ankeny", "value": "C132599"},
          {"label": "Aplington", "value": "C132600"},
          {"label": "Appanoose County", "value": "C132601"},
          {"label": "Arnolds Park", "value": "C132602"},
          {"label": "Asbury", "value": "C132603"},
          {"label": "Atkins", "value": "C132604"},
          {"label": "Atlantic", "value": "C132605"},
          {"label": "Audubon", "value": "C132606"},
          {"label": "Audubon County", "value": "C132607"},
          {"label": "Avoca", "value": "C132608"},
          {"label": "Baxter", "value": "C132609"},
          {"label": "Bedford", "value": "C132610"},
          {"label": "Belle Plaine", "value": "C132611"},
          {"label": "Bellevue", "value": "C132612"},
          {"label": "Belmond", "value": "C132613"},
          {"label": "Benton County", "value": "C132614"},
          {"label": "Bettendorf", "value": "C132615"},
          {"label": "Black Hawk County", "value": "C132616"},
          {"label": "Bloomfield", "value": "C132617"},
          {"label": "Blue Grass", "value": "C132618"},
          {"label": "Bondurant", "value": "C132619"},
          {"label": "Boone", "value": "C132620"},
          {"label": "Boone County", "value": "C132621"},
          {"label": "Bremer County", "value": "C132622"},
          {"label": "Britt", "value": "C132623"},
          {"label": "Brooklyn", "value": "C132624"},
          {"label": "Buchanan County", "value": "C132625"},
          {"label": "Buena Vista County", "value": "C132626"},
          {"label": "Buffalo", "value": "C132627"},
          {"label": "Buffalo (historical)", "value": "C132628"},
          {"label": "Burlington", "value": "C132629"},
          {"label": "Butler County", "value": "C132630"},
          {"label": "Calhoun County", "value": "C132631"},
          {"label": "Camanche", "value": "C132632"},
          {"label": "Carlisle", "value": "C132633"},
          {"label": "Carroll", "value": "C132634"},
          {"label": "Carroll County", "value": "C132635"},
          {"label": "Carter Lake", "value": "C132636"},
          {"label": "Cascade", "value": "C132637"},
          {"label": "Cass County", "value": "C132638"},
          {"label": "Cedar County", "value": "C132639"},
          {"label": "Cedar Falls", "value": "C132640"},
          {"label": "Cedar Rapids", "value": "C132641"},
          {"label": "Center Point", "value": "C132642"},
          {"label": "Centerville", "value": "C132643"},
          {"label": "Central City", "value": "C132644"},
          {"label": "Cerro Gordo County", "value": "C132645"},
          {"label": "Chariton", "value": "C132646"},
          {"label": "Charles City", "value": "C132647"},
          {"label": "Cherokee", "value": "C132648"},
          {"label": "Cherokee County", "value": "C132649"},
          {"label": "Chickasaw County", "value": "C132650"},
          {"label": "Clarinda", "value": "C132651"},
          {"label": "Clarion", "value": "C132652"},
          {"label": "Clarke County", "value": "C132653"},
          {"label": "Clarksville", "value": "C132654"},
          {"label": "Clay County", "value": "C132655"},
          {"label": "Clayton County", "value": "C132656"},
          {"label": "Clear Lake", "value": "C132657"},
          {"label": "Clinton", "value": "C132658"},
          {"label": "Clinton County", "value": "C132659"},
          {"label": "Clive", "value": "C132660"},
          {"label": "Colfax", "value": "C132661"},
          {"label": "Columbus Junction", "value": "C132662"},
          {"label": "Conrad", "value": "C132663"},
          {"label": "Coon Rapids", "value": "C132664"},
          {"label": "Coralville", "value": "C132665"},
          {"label": "Corning", "value": "C132666"},
          {"label": "Corydon", "value": "C132667"},
          {"label": "Council Bluffs", "value": "C132668"},
          {"label": "Crawford County", "value": "C132669"},
          {"label": "Cresco", "value": "C132670"},
          {"label": "Creston", "value": "C132671"},
          {"label": "Dakota City", "value": "C132672"},
          {"label": "Dallas Center", "value": "C132673"},
          {"label": "Dallas County", "value": "C132674"},
          {"label": "Davenport", "value": "C132675"},
          {"label": "Davis County", "value": "C132676"},
          {"label": "De Soto", "value": "C132677"},
          {"label": "De Witt", "value": "C132678"},
          {"label": "Decatur County", "value": "C132679"},
          {"label": "Decorah", "value": "C132680"},
          {"label": "Delaware County", "value": "C132681"},
          {"label": "Denison", "value": "C132682"},
          {"label": "Denver", "value": "C132683"},
          {"label": "Des Moines", "value": "C132684"},
          {"label": "Des Moines County", "value": "C132685"},
          {"label": "Dickinson County", "value": "C132686"},
          {"label": "Dike", "value": "C132687"},
          {"label": "Dubuque", "value": "C132688"},
          {"label": "Dubuque County", "value": "C132689"},
          {"label": "Durant", "value": "C132690"},
          {"label": "Dyersville", "value": "C132691"},
          {"label": "Dysart", "value": "C132692"},
          {"label": "Eagle Grove", "value": "C132693"},
          {"label": "Earlham", "value": "C132694"},
          {"label": "Eddyville", "value": "C132695"},
          {"label": "Eldora", "value": "C132696"},
          {"label": "Eldridge", "value": "C132697"},
          {"label": "Elk Run Heights", "value": "C132698"},
          {"label": "Elkader", "value": "C132699"},
          {"label": "Ely", "value": "C132700"},
          {"label": "Emmet County", "value": "C132701"},
          {"label": "Emmetsburg", "value": "C132702"},
          {"label": "Epworth", "value": "C132703"},
          {"label": "Estherville", "value": "C132704"},
          {"label": "Evansdale", "value": "C132705"},
          {"label": "Fairbank", "value": "C132706"},
          {"label": "Fairfax", "value": "C132707"},
          {"label": "Fairfield", "value": "C132708"},
          {"label": "Farley", "value": "C132709"},
          {"label": "Fayette", "value": "C132710"},
          {"label": "Fayette County", "value": "C132711"},
          {"label": "Floyd County", "value": "C132712"},
          {"label": "Forest City", "value": "C132713"},
          {"label": "Fort Dodge", "value": "C132714"},
          {"label": "Fort Madison", "value": "C132715"},
          {"label": "Franklin County", "value": "C132716"},
          {"label": "Fremont County", "value": "C132717"},
          {"label": "Garner", "value": "C132718"},
          {"label": "George", "value": "C132719"},
          {"label": "Gilbert", "value": "C132720"},
          {"label": "Glenwood", "value": "C132721"},
          {"label": "Glidden", "value": "C132722"},
          {"label": "Granger", "value": "C132723"},
          {"label": "Greene", "value": "C132724"},
          {"label": "Greene County", "value": "C132725"},
          {"label": "Greenfield", "value": "C132726"},
          {"label": "Grimes", "value": "C132727"},
          {"label": "Grinnell", "value": "C132728"},
          {"label": "Grundy Center", "value": "C132729"},
          {"label": "Grundy County", "value": "C132730"},
          {"label": "Guthrie Center", "value": "C132731"},
          {"label": "Guthrie County", "value": "C132732"},
          {"label": "Guttenberg", "value": "C132733"},
          {"label": "Hamburg", "value": "C132734"},
          {"label": "Hamilton County", "value": "C132735"},
          {"label": "Hampton", "value": "C132736"},
          {"label": "Hancock County", "value": "C132737"},
          {"label": "Hardin County", "value": "C132738"},
          {"label": "Harlan", "value": "C132739"},
          {"label": "Harrison County", "value": "C132740"},
          {"label": "Hartley", "value": "C132741"},
          {"label": "Hawarden", "value": "C132742"},
          {"label": "Henry County", "value": "C132743"},
          {"label": "Hiawatha", "value": "C132744"},
          {"label": "Holstein", "value": "C132745"},
          {"label": "Howard County", "value": "C132746"},
          {"label": "Hudson", "value": "C132747"},
          {"label": "Hull", "value": "C132748"},
          {"label": "Humboldt", "value": "C132749"},
          {"label": "Humboldt County", "value": "C132750"},
          {"label": "Huxley", "value": "C132751"},
          {"label": "Ida County", "value": "C132752"},
          {"label": "Ida Grove", "value": "C132753"},
          {"label": "Independence", "value": "C132754"},
          {"label": "Indianola", "value": "C132755"},
          {"label": "Iowa City", "value": "C132756"},
          {"label": "Iowa County", "value": "C132757"},
          {"label": "Iowa Falls", "value": "C132758"},
          {"label": "Jackson County", "value": "C132759"},
          {"label": "Jasper County", "value": "C132760"},
          {"label": "Jefferson", "value": "C132761"},
          {"label": "Jefferson County", "value": "C132762"},
          {"label": "Jesup", "value": "C132763"},
          {"label": "Jewell", "value": "C132764"},
          {"label": "Johnson County", "value": "C132765"},
          {"label": "Johnston", "value": "C132766"},
          {"label": "Jones County", "value": "C132767"},
          {"label": "Kalona", "value": "C132768"},
          {"label": "Keokuk", "value": "C132769"},
          {"label": "Keokuk County", "value": "C132770"},
          {"label": "Keosauqua", "value": "C132771"},
          {"label": "Kingsley", "value": "C132772"},
          {"label": "Knoxville", "value": "C132773"},
          {"label": "Kossuth County", "value": "C132774"},
          {"label": "La Porte City", "value": "C132775"},
          {"label": "Lake City", "value": "C132776"},
          {"label": "Lake Mills", "value": "C132777"},
          {"label": "Lake Panorama", "value": "C132778"},
          {"label": "Lake Park", "value": "C132779"},
          {"label": "Lake View", "value": "C132780"},
          {"label": "Lamoni", "value": "C132781"},
          {"label": "Laurens", "value": "C132782"},
          {"label": "Le Claire", "value": "C132783"},
          {"label": "Le Mars", "value": "C132784"},
          {"label": "Lee County", "value": "C132785"},
          {"label": "Lenox", "value": "C132786"},
          {"label": "Leon", "value": "C132787"},
          {"label": "Linn County", "value": "C132788"},
          {"label": "Lisbon", "value": "C132789"},
          {"label": "Logan", "value": "C132790"},
          {"label": "Lone Tree", "value": "C132791"},
          {"label": "Louisa County", "value": "C132792"},
          {"label": "Lucas County", "value": "C132793"},
          {"label": "Lyon County", "value": "C132794"},
          {"label": "Madison County", "value": "C132795"},
          {"label": "Madrid", "value": "C132796"},
          {"label": "Maharishi Vedic City", "value": "C132797"},
          {"label": "Mahaska County", "value": "C132798"},
          {"label": "Malvern", "value": "C132799"},
          {"label": "Manchester", "value": "C132800"},
          {"label": "Manly", "value": "C132801"},
          {"label": "Manning", "value": "C132802"},
          {"label": "Manson", "value": "C132803"},
          {"label": "Mapleton", "value": "C132804"},
          {"label": "Maquoketa", "value": "C132805"},
          {"label": "Marcus", "value": "C132806"},
          {"label": "Marengo", "value": "C132807"},
          {"label": "Marion", "value": "C132808"},
          {"label": "Marion County", "value": "C132809"},
          {"label": "Marshall County", "value": "C132810"},
          {"label": "Marshalltown", "value": "C132811"},
          {"label": "Mason City", "value": "C132812"},
          {"label": "Mechanicsville", "value": "C132813"},
          {"label": "Mediapolis", "value": "C132814"},
          {"label": "Melcher-Dallas", "value": "C132815"},
          {"label": "Milford", "value": "C132816"},
          {"label": "Mills County", "value": "C132817"},
          {"label": "Missouri Valley", "value": "C132818"},
          {"label": "Mitchell County", "value": "C132819"},
          {"label": "Mitchellville", "value": "C132820"},
          {"label": "Monona", "value": "C132821"},
          {"label": "Monona County", "value": "C132822"},
          {"label": "Monroe", "value": "C132823"},
          {"label": "Monroe County", "value": "C132824"},
          {"label": "Montezuma", "value": "C132825"},
          {"label": "Montgomery County", "value": "C132826"},
          {"label": "Monticello", "value": "C132827"},
          {"label": "Mount Ayr", "value": "C132828"},
          {"label": "Mount Pleasant", "value": "C132829"},
          {"label": "Mount Vernon", "value": "C132830"},
          {"label": "Moville", "value": "C132831"},
          {"label": "Muscatine", "value": "C132832"},
          {"label": "Muscatine County", "value": "C132833"},
          {"label": "Nashua", "value": "C132834"},
          {"label": "Nevada", "value": "C132835"},
          {"label": "New Hampton", "value": "C132836"},
          {"label": "New London", "value": "C132837"},
          {"label": "New Sharon", "value": "C132838"},
          {"label": "Newton", "value": "C132839"},
          {"label": "Nora Springs", "value": "C132840"},
          {"label": "North English", "value": "C132841"},
          {"label": "North Liberty", "value": "C132842"},
          {"label": "Northwood", "value": "C132843"},
          {"label": "Norwalk", "value": "C132844"},
          {"label": "OBrien County", "value": "C132845"},
          {"label": "Oakland", "value": "C132846"},
          {"label": "Oelwein", "value": "C132847"},
          {"label": "Ogden", "value": "C132848"},
          {"label": "Onawa", "value": "C132849"},
          {"label": "Orange City", "value": "C132850"},
          {"label": "Osage", "value": "C132851"},
          {"label": "Osceola", "value": "C132852"},
          {"label": "Osceola County", "value": "C132853"},
          {"label": "Oskaloosa", "value": "C132854"},
          {"label": "Ottumwa", "value": "C132855"},
          {"label": "Page County", "value": "C132856"},
          {"label": "Palo", "value": "C132857"},
          {"label": "Palo Alto County", "value": "C132858"},
          {"label": "Panora", "value": "C132859"},
          {"label": "Park View", "value": "C132860"},
          {"label": "Parkersburg", "value": "C132861"},
          {"label": "Paullina", "value": "C132862"},
          {"label": "Pella", "value": "C132863"},
          {"label": "Peosta", "value": "C132864"},
          {"label": "Perry", "value": "C132865"},
          {"label": "Pleasant Hill", "value": "C132866"},
          {"label": "Pleasantville", "value": "C132867"},
          {"label": "Plymouth County", "value": "C132868"},
          {"label": "Pocahontas", "value": "C132869"},
          {"label": "Pocahontas County", "value": "C132870"},
          {"label": "Polk City", "value": "C132871"},
          {"label": "Polk County", "value": "C132872"},
          {"label": "Postville", "value": "C132873"},
          {"label": "Pottawattamie County", "value": "C132874"},
          {"label": "Poweshiek County", "value": "C132875"},
          {"label": "Prairie City", "value": "C132876"},
          {"label": "Preston", "value": "C132877"},
          {"label": "Primghar", "value": "C132878"},
          {"label": "Red Oak", "value": "C132879"},
          {"label": "Reinbeck", "value": "C132880"},
          {"label": "Remsen", "value": "C132881"},
          {"label": "Ringgold County", "value": "C132882"},
          {"label": "Riverside", "value": "C132883"},
          {"label": "Robins", "value": "C132884"},
          {"label": "Rock Rapids", "value": "C132885"},
          {"label": "Rock Valley", "value": "C132886"},
          {"label": "Rockwell", "value": "C132887"},
          {"label": "Rockwell City", "value": "C132888"},
          {"label": "Roland", "value": "C132889"},
          {"label": "Sac City", "value": "C132890"},
          {"label": "Sac County", "value": "C132891"},
          {"label": "Saint Ansgar", "value": "C132892"},
          {"label": "Sanborn", "value": "C132893"},
          {"label": "Saylorville", "value": "C132894"},
          {"label": "Scott County", "value": "C132895"},
          {"label": "Sergeant Bluff", "value": "C132896"},
          {"label": "Sheffield", "value": "C132897"},
          {"label": "Shelby County", "value": "C132898"},
          {"label": "Sheldon", "value": "C132899"},
          {"label": "Shell Rock", "value": "C132900"},
          {"label": "Shenandoah", "value": "C132901"},
          {"label": "Sibley", "value": "C132902"},
          {"label": "Sidney", "value": "C132903"},
          {"label": "Sigourney", "value": "C132904"},
          {"label": "Sioux Center", "value": "C132905"},
          {"label": "Sioux City", "value": "C132906"},
          {"label": "Sioux County", "value": "C132907"},
          {"label": "Slater", "value": "C132908"},
          {"label": "Solon", "value": "C132909"},
          {"label": "Spencer", "value": "C132910"},
          {"label": "Spirit Lake", "value": "C132911"},
          {"label": "Springville", "value": "C132912"},
          {"label": "State Center", "value": "C132913"},
          {"label": "Storm Lake", "value": "C132914"},
          {"label": "Story City", "value": "C132915"},
          {"label": "Story County", "value": "C132916"},
          {"label": "Strawberry Point", "value": "C132917"},
          {"label": "Stuart", "value": "C132918"},
          {"label": "Sumner", "value": "C132919"},
          {"label": "Tama", "value": "C132920"},
          {"label": "Tama County", "value": "C132921"},
          {"label": "Taylor County", "value": "C132922"},
          {"label": "Tiffin", "value": "C132923"},
          {"label": "Tipton", "value": "C132924"},
          {"label": "Toledo", "value": "C132925"},
          {"label": "Traer", "value": "C132926"},
          {"label": "Tripoli", "value": "C132927"},
          {"label": "Union County", "value": "C132928"},
          {"label": "University Heights", "value": "C132929"},
          {"label": "Urbana", "value": "C132930"},
          {"label": "Urbandale", "value": "C132931"},
          {"label": "Van Buren County", "value": "C132932"},
          {"label": "Van Meter", "value": "C132933"},
          {"label": "Villisca", "value": "C132934"},
          {"label": "Vinton", "value": "C132935"},
          {"label": "Walcott", "value": "C132936"},
          {"label": "Walford", "value": "C132937"},
          {"label": "Wapello", "value": "C132938"},
          {"label": "Wapello County", "value": "C132939"},
          {"label": "Warren County", "value": "C132940"},
          {"label": "Washington", "value": "C132941"},
          {"label": "Washington County", "value": "C132942"},
          {"label": "Waterloo", "value": "C132943"},
          {"label": "Waukee", "value": "C132944"},
          {"label": "Waukon", "value": "C132945"},
          {"label": "Waverly", "value": "C132946"},
          {"label": "Wayne County", "value": "C132947"},
          {"label": "Webster City", "value": "C132948"},
          {"label": "Webster County", "value": "C132949"},
          {"label": "Wellman", "value": "C132950"},
          {"label": "West Branch", "value": "C132951"},
          {"label": "West Burlington", "value": "C132952"},
          {"label": "West Des Moines", "value": "C132953"},
          {"label": "West Liberty", "value": "C132954"},
          {"label": "West Union", "value": "C132955"},
          {"label": "Williamsburg", "value": "C132956"},
          {"label": "Wilton", "value": "C132957"},
          {"label": "Windsor Heights", "value": "C132958"},
          {"label": "Winfield", "value": "C132959"},
          {"label": "Winnebago County", "value": "C132960"},
          {"label": "Winneshiek County", "value": "C132961"},
          {"label": "Winterset", "value": "C132962"},
          {"label": "Woodbine", "value": "C132963"},
          {"label": "Woodbury County", "value": "C132964"},
          {"label": "Woodward", "value": "C132965"},
          {"label": "Worth County", "value": "C132966"},
          {"label": "Wright County", "value": "C132967"}],
 "Irbid Governorate": [{"label": "Ar Ramtha", "value": "C64214"},
                       {"label": "Ash Shajarah", "value": "C64215"},
                       {"label": "At Tayyibah", "value": "C64216"},
                       {"label": "At Turrah", "value": "C64217"},
                       {"label": "Aydun", "value": "C64218"},
                       {"label": "Bayt idis", "value": "C64219"},
                       {"label": "Bayt Yafa", "value": "C64220"},
                       {"label": "Dayr Yusuf", "value": "C64221"},
                       {"label": "Hakama", "value": "C64222"},
                       {"label": "Hatim", "value": "C64223"},
                       {"label": "Irbid", "value": "C64224"},
                       {"label": "Judita", "value": "C64225"},
                       {"label": "Kafr Abil", "value": "C64226"},
                       {"label": "Kafr Asad", "value": "C64227"},
                       {"label": "Kafr Sawm", "value": "C64228"},
                       {"label": "Kharja", "value": "C64229"},
                       {"label": "Kitim", "value": "C64230"},
                       {"label": "Kurayyimah", "value": "C64231"},
                       {"label": "Malka", "value": "C64232"},
                       {"label": "Qumaym", "value": "C64233"},
                       {"label": "Saham al Kaffarat", "value": "C64234"},
                       {"label": "Sal", "value": "C64235"},
                       {"label": "Samma", "value": "C64236"},
                       {"label": "Tibnah", "value": "C64237"},
                       {"label": "Umm Qays", "value": "C64238"},
                       {"label": "Waqqas", "value": "C64239"},
                       {"label": "Zahar", "value": "C64240"}],
 "Iringa": [{"label": "Ilula", "value": "C118096"},
            {"label": "Iringa", "value": "C118097"},
            {"label": "Izazi", "value": "C118098"},
            {"label": "Mafinga", "value": "C118099"},
            {"label": "Makungu", "value": "C118100"},
            {"label": "Malangali", "value": "C118101"}],
 "Isabel Province": [{"label": "Buala", "value": "C107875"}],
 "Isfahan": [{"label": "Abrisham", "value": "C50712"},
             {"label": "Ardestan", "value": "C50713"},
             {"label": "Buin va Miandasht", "value": "C50714"},
             {"label": "Chadegan", "value": "C50715"},
             {"label": "Daran", "value": "C50716"},
             {"label": "Dehaqan", "value": "C50717"},
             {"label": "Dorcheh Piaz", "value": "C50718"},
             {"label": "Dowlatabad", "value": "C50719"},
             {"label": "Falavarjan", "value": "C50720"},
             {"label": "Fareydunshahr", "value": "C50721"},
             {"label": "Fereydan", "value": "C50722"},
             {"label": "Golpayegan", "value": "C50723"},
             {"label": "Isfahan", "value": "C50724"},
             {"label": "Kelishad va Sudarjan", "value": "C50725"},
             {"label": "Khomeyni Shahr", "value": "C50726"},
             {"label": "Khur", "value": "C50727"},
             {"label": "Khvansar", "value": "C50728"},
             {"label": "Mobarakeh", "value": "C50729"},
             {"label": "Nain", "value": "C50730"},
             {"label": "Najafabad", "value": "C50731"},
             {"label": "Natanz", "value": "C50732"},
             {"label": "Qahderijan", "value": "C50733"},
             {"label": "Rehnan", "value": "C50734"},
             {"label": "Semirom", "value": "C50735"},
             {"label": "Shahin Shahr", "value": "C50736"},
             {"label": "Shahrestan-e aran va Bidgol", "value": "C50737"},
             {"label": "Shahrestan-e Ardestan", "value": "C50738"},
             {"label": "Shahrestan-e Borkhvar", "value": "C50739"},
             {"label": "Shahrestan-e Chadegan", "value": "C50740"},
             {"label": "Shahrestan-e Dehaqan", "value": "C50741"},
             {"label": "Shahrestan-e Esfahan", "value": "C50742"},
             {"label": "Shahrestan-e Falavarjan", "value": "C50743"},
             {"label": "Shahrestan-e Fareydunshahr", "value": "C50744"},
             {"label": "Shahrestan-e Golpayegan", "value": "C50745"},
             {"label": "Shahrestan-e Kashan", "value": "C50746"},
             {"label": "Shahrestan-e Khomeyni Shahr", "value": "C50747"},
             {"label": "Shahrestan-e Khur va Biabanak", "value": "C50748"},
             {"label": "Shahrestan-e Khvansar", "value": "C50749"},
             {"label": "Shahrestan-e Lenjan", "value": "C50750"},
             {"label": "Shahrestan-e Mobarakeh", "value": "C50751"},
             {"label": "Shahrestan-e Nain", "value": "C50752"},
             {"label": "Shahrestan-e Najafabad", "value": "C50753"},
             {"label": "Shahrestan-e Natanz", "value": "C50754"},
             {"label": "Shahrestan-e Semirom", "value": "C50755"},
             {"label": "Shahrestan-e Shahin Shahr va Meymeh",
              "value": "C50756"},
             {"label": "Shahrestan-e Shahreza", "value": "C50757"},
             {"label": "Shahrestan-e Tiran va Karvan", "value": "C50758"},
             {"label": "Shahreza", "value": "C50759"},
             {"label": "Tiran", "value": "C50760"},
             {"label": "Zarrin Shahr", "value": "C50761"}],
 "Ishikawa Prefecture": [{"label": "Hakui", "value": "C63134"},
                         {"label": "Hakui Shi", "value": "C63135"},
                         {"label": "Hakusan Shi", "value": "C63136"},
                         {"label": "Kaga Shi", "value": "C63137"},
                         {"label": "Kahoku Shi", "value": "C63138"},
                         {"label": "Kanazawa", "value": "C63139"},
                         {"label": "Kanazawa-shi", "value": "C63140"},
                         {"label": "Komatsu", "value": "C63141"},
                         {"label": "Matsuto", "value": "C63142"},
                         {"label": "Nanao Shi", "value": "C63143"},
                         {"label": "Nomi Shi", "value": "C63144"},
                         {"label": "Nonoichi", "value": "C63145"},
                         {"label": "Nonoichi-shi", "value": "C63146"},
                         {"label": "Suzu", "value": "C63147"},
                         {"label": "Suzu Shi", "value": "C63148"},
                         {"label": "Tsubata", "value": "C63149"},
                         {"label": "Tsurugi-asahimachi", "value": "C63150"},
                         {"label": "Wajima Shi", "value": "C63151"}],
 "Isiolo": [{"label": "Isiolo", "value": "C64556"}],
 "Isla de la Juventud": [{"label": "Nueva Gerona", "value": "C21765"}],
 "Islamabad Capital Territory": [{"label": "Islamabad", "value": "C79387"}],
 "Ismailia": [{"label": "Ismailia", "value": "C24284"}],
 "Ismailli District": [{"label": "Basqal", "value": "C8441"},
                       {"label": "Ismayilli", "value": "C8442"}],
 "Isparta": [{"label": "Aksu", "value": "C120407"},
             {"label": "Anamas", "value": "C120408"},
             {"label": "Atabey", "value": "C120409"},
             {"label": "Egirdir", "value": "C120410"},
             {"label": "Gelendost", "value": "C120411"},
             {"label": "Gonen", "value": "C120412"},
             {"label": "Isparta", "value": "C120413"},
             {"label": "Keciborlu", "value": "C120414"},
             {"label": "Pavlu Cebel", "value": "C120415"},
             {"label": "Sarkikaraagac", "value": "C120416"},
             {"label": "Senirkent", "value": "C120417"},
             {"label": "Sutculer Ilcesi", "value": "C120418"},
             {"label": "Uluborlu", "value": "C120419"},
             {"label": "Yalvac", "value": "C120420"},
             {"label": "Yenisarbademli", "value": "C120421"}],
 "Issyk-Kul Region": [{"label": "Ak-Suu", "value": "C64731"},
                      {"label": "Balykchy", "value": "C64732"},
                      {"label": "Cholpon-Ata", "value": "C64733"},
                      {"label": "Kadzhi-Say", "value": "C64734"},
                      {"label": "Karakol", "value": "C64735"},
                      {"label": "Kyzyl-Suu", "value": "C64736"},
                      {"label": "Tyup", "value": "C64737"}],
 "Istanbul": [{"label": "Adalar", "value": "C120422"},
              {"label": "Arnavutkoy", "value": "C120423"},
              {"label": "Atasehir", "value": "C120424"},
              {"label": "Avcilar", "value": "C120425"},
              {"label": "Bagcilar", "value": "C120426"},
              {"label": "Bahcelievler", "value": "C120427"},
              {"label": "Bakirkoy", "value": "C120428"},
              {"label": "Basaksehir", "value": "C120429"},
              {"label": "Bayrampasa", "value": "C120430"},
              {"label": "Besiktas", "value": "C120431"},
              {"label": "Beykoz", "value": "C120432"},
              {"label": "Beylikduzu", "value": "C120433"},
              {"label": "Beyoglu", "value": "C120434"},
              {"label": "Buyukcekmece", "value": "C120435"},
              {"label": "Catalca", "value": "C120436"},
              {"label": "Cekmekoy", "value": "C120437"},
              {"label": "Eminonu", "value": "C120438"},
              {"label": "Esenler", "value": "C120439"},
              {"label": "Esenyurt", "value": "C120440"},
              {"label": "Eyupsultan", "value": "C120441"},
              {"label": "Fatih", "value": "C120442"},
              {"label": "Gaziosmanpasa", "value": "C120443"},
              {"label": "Gungoren", "value": "C120444"},
              {"label": "Kadikoy", "value": "C120445"},
              {"label": "Kagithane", "value": "C120446"},
              {"label": "Kartal", "value": "C120447"},
              {"label": "Kucukcekmece", "value": "C120448"},
              {"label": "Maltepe", "value": "C120449"},
              {"label": "Pendik", "value": "C120450"},
              {"label": "Sancaktepe", "value": "C120451"},
              {"label": "Sariyer", "value": "C120452"},
              {"label": "Sile", "value": "C120453"},
              {"label": "Silivri", "value": "C120454"},
              {"label": "Sisli", "value": "C120455"},
              {"label": "Sultanbeyli", "value": "C120456"},
              {"label": "Sultangazi", "value": "C120457"},
              {"label": "Tuzla", "value": "C120458"},
              {"label": "Umraniye", "value": "C120459"},
              {"label": "uskudar", "value": "C120460"},
              {"label": "Zeytinburnu", "value": "C120461"}],
 "Istria": [{"label": "Bale", "value": "C21088"},
            {"label": "Bale-Valle", "value": "C21089"},
            {"label": "Brtonigla", "value": "C21090"},
            {"label": "Brtonigla-Verteneglio", "value": "C21091"},
            {"label": "Buje", "value": "C21092"},
            {"label": "Buje-Buie", "value": "C21093"},
            {"label": "Buzet", "value": "C21094"},
            {"label": "Fazana", "value": "C21095"},
            {"label": "Fazana-Fasana", "value": "C21096"},
            {"label": "Funtana", "value": "C21097"},
            {"label": "Funtana-Fontane", "value": "C21098"},
            {"label": "Galizana", "value": "C21099"},
            {"label": "Grad Buzet", "value": "C21100"},
            {"label": "Grad Labin", "value": "C21101"},
            {"label": "Grad Pazin", "value": "C21102"},
            {"label": "Groznjan", "value": "C21103"},
            {"label": "Groznjan-Grisignana", "value": "C21104"},
            {"label": "Kanfanar", "value": "C21105"},
            {"label": "Karojba", "value": "C21106"},
            {"label": "Kastelir-Labinci", "value": "C21107"},
            {"label": "Labin", "value": "C21108"},
            {"label": "Liznjan", "value": "C21109"},
            {"label": "Liznjan-Lisignano", "value": "C21110"},
            {"label": "Lupoglav", "value": "C21111"},
            {"label": "Marcana", "value": "C21112"},
            {"label": "Medulin", "value": "C21113"},
            {"label": "Motovun", "value": "C21114"},
            {"label": "Motovun-Montona", "value": "C21115"},
            {"label": "Novigrad", "value": "C21116"},
            {"label": "Novigrad-Cittanova", "value": "C21117"},
            {"label": "Opcina Lanisce", "value": "C21118"},
            {"label": "Oprtalj-Portole", "value": "C21119"},
            {"label": "Pazin", "value": "C21120"},
            {"label": "Porec", "value": "C21121"},
            {"label": "Porec-Parenzo", "value": "C21122"},
            {"label": "Pula", "value": "C21123"},
            {"label": "Pula-Pola", "value": "C21124"},
            {"label": "Rabac", "value": "C21125"},
            {"label": "Rasa", "value": "C21126"},
            {"label": "Rovinj", "value": "C21127"},
            {"label": "Rovinj-Rovigno", "value": "C21128"},
            {"label": "Sveta Nedelja", "value": "C21129"},
            {"label": "Sveti Lovrec", "value": "C21130"},
            {"label": "Tar", "value": "C21131"},
            {"label": "Tar-Vabriga-Torre Abrega", "value": "C21132"},
            {"label": "Umag", "value": "C21133"},
            {"label": "Umag-Umago", "value": "C21134"},
            {"label": "Valbandon", "value": "C21135"},
            {"label": "Vinez", "value": "C21136"},
            {"label": "Visnjan-Visignano", "value": "C21137"},
            {"label": "Vizinada-Visinada", "value": "C21138"},
            {"label": "Vodnjan", "value": "C21139"},
            {"label": "Vodnjan-Dignano", "value": "C21140"},
            {"label": "Vrsar", "value": "C21141"},
            {"label": "Vrsar-Orsera", "value": "C21142"},
            {"label": "Zminj", "value": "C21143"}],
 "Itapua": [{"label": "Arquitecto Tomas Romero Pereira", "value": "C80569"},
            {"label": "Bella Vista", "value": "C80570"},
            {"label": "Capitan Miranda", "value": "C80571"},
            {"label": "Carmen del Parana", "value": "C80572"},
            {"label": "Colonia Carlos Antonio Lopez", "value": "C80573"},
            {"label": "Colonia San Lorenzo", "value": "C80574"},
            {"label": "Edelira", "value": "C80575"},
            {"label": "Encarnacion", "value": "C80576"},
            {"label": "Fram", "value": "C80577"},
            {"label": "General Artigas", "value": "C80578"},
            {"label": "General Delgado", "value": "C80579"},
            {"label": "Hohenau", "value": "C80580"},
            {"label": "Jesus", "value": "C80581"},
            {"label": "Natalio", "value": "C80582"},
            {"label": "Obligado", "value": "C80583"},
            {"label": "Pirapo", "value": "C80584"},
            {"label": "Puerto Mayor Otano", "value": "C80585"},
            {"label": "San Cosme y Damian", "value": "C80586"},
            {"label": "San Juan del Parana", "value": "C80587"},
            {"label": "San Pedro del Parana", "value": "C80588"},
            {"label": "Trinidad", "value": "C80589"}],
 "Ituri": [{"label": "Bunia", "value": "C23325"}],
 "Ivancna Gorica Municipality": [{"label": "Ivancna Gorica",
                                  "value": "C107644"},
                                 {"label": "Sentvid pri Sticni",
                                  "value": "C107645"}],
 "Ivano-Frankivska oblast": [{"label": "Bili Oslavy", "value": "C121878"},
                             {"label": "Bilshivtsi", "value": "C121879"},
                             {"label": "Bohorodchanskyy Rayon",
                              "value": "C121880"},
                             {"label": "Bohorodchany", "value": "C121881"},
                             {"label": "Bolekhiv", "value": "C121882"},
                             {"label": "Bolekhivska Miskrada",
                              "value": "C121883"},
                             {"label": "Borshchevskiy Rayon",
                              "value": "C121884"},
                             {"label": "Broshniv-Osada", "value": "C121885"},
                             {"label": "Burshtyn", "value": "C121886"},
                             {"label": "Burshtynska Miskrada",
                              "value": "C121887"},
                             {"label": "Bystrytsya", "value": "C121888"},
                             {"label": "Bytkiv", "value": "C121889"},
                             {"label": "Chernelytsya", "value": "C121890"},
                             {"label": "Cherniyiv", "value": "C121891"},
                             {"label": "Chernyatyn", "value": "C121892"},
                             {"label": "Chornoliztsi", "value": "C121893"},
                             {"label": "Delyatyn", "value": "C121894"},
                             {"label": "Dolyna", "value": "C121895"},
                             {"label": "Dolynskyy Rayon", "value": "C121896"},
                             {"label": "Halych", "value": "C121897"},
                             {"label": "Halytskyy Rayon", "value": "C121898"},
                             {"label": "Horodenka", "value": "C121899"},
                             {"label": "Horodenkivskyy Rayon",
                              "value": "C121900"},
                             {"label": "Hvizd", "value": "C121901"},
                             {"label": "Ivano-Frankivska Miskrada",
                              "value": "C121902"},
                             {"label": "Ivano-Frankivsk", "value": "C121903"},
                             {"label": "Kaluskyy Rayon", "value": "C121904"},
                             {"label": "Kalush", "value": "C121905"},
                             {"label": "Khymchyn", "value": "C121906"},
                             {"label": "Kolomyia", "value": "C121907"},
                             {"label": "Kolomyyskyy Rayon", "value": "C121908"},
                             {"label": "Kosiv", "value": "C121909"},
                             {"label": "Kosivskyy Rayon", "value": "C121910"},
                             {"label": "Kosmach", "value": "C121911"},
                             {"label": "Krasna", "value": "C121912"},
                             {"label": "Kuty", "value": "C121913"},
                             {"label": "Lanchyn", "value": "C121914"},
                             {"label": "Manyava", "value": "C121915"},
                             {"label": "Markova", "value": "C121916"},
                             {"label": "Nadvirna", "value": "C121917"},
                             {"label": "Nadvirnyanskyy Rayon",
                              "value": "C121918"},
                             {"label": "Nebyliv", "value": "C121919"},
                             {"label": "Novytsya", "value": "C121920"},
                             {"label": "Obertyn", "value": "C121921"},
                             {"label": "Otyniya", "value": "C121922"},
                             {"label": "Pasichna", "value": "C121923"},
                             {"label": "Pechenizhyn", "value": "C121924"},
                             {"label": "Petranka", "value": "C121925"},
                             {"label": "Pniv", "value": "C121926"},
                             {"label": "Porohy", "value": "C121927"},
                             {"label": "Radcha", "value": "C121928"},
                             {"label": "Rechka", "value": "C121929"},
                             {"label": "Rohatyn", "value": "C121930"},
                             {"label": "Rohatynskyy Rayon", "value": "C121931"},
                             {"label": "Rozhniativ", "value": "C121932"},
                             {"label": "Rozhniv", "value": "C121933"},
                             {"label": "Rozhnyativskyy Rayon",
                              "value": "C121934"},
                             {"label": "Sadzhavka", "value": "C121935"},
                             {"label": "Sheshory", "value": "C121936"},
                             {"label": "Sniatyn", "value": "C121937"},
                             {"label": "Snyatynskyy Rayon", "value": "C121938"},
                             {"label": "Solotvyn", "value": "C121939"},
                             {"label": "Spas", "value": "C121940"},
                             {"label": "Stari Bohorodchany",
                              "value": "C121941"},
                             {"label": "Stari Kuty", "value": "C121942"},
                             {"label": "Stetseva", "value": "C121943"},
                             {"label": "Svarychiv", "value": "C121944"},
                             {"label": "Tatariv", "value": "C121945"},
                             {"label": "Tlumach", "value": "C121946"},
                             {"label": "Tlumatskyy Rayon", "value": "C121947"},
                             {"label": "Tysmenychany", "value": "C121948"},
                             {"label": "Tysmenytskyy Rayon",
                              "value": "C121949"},
                             {"label": "Tysmenytsya", "value": "C121950"},
                             {"label": "Uhryniv", "value": "C121951"},
                             {"label": "Velykyy Klyuchiv", "value": "C121952"},
                             {"label": "Verkhniy Yasenov", "value": "C121953"},
                             {"label": "Verkhovyna", "value": "C121954"},
                             {"label": "Vorokhta", "value": "C121955"},
                             {"label": "Yabluniv", "value": "C121956"},
                             {"label": "Yablunytsya", "value": "C121957"},
                             {"label": "Yamnytsya", "value": "C121958"},
                             {"label": "Yaremchansks Miskrada",
                              "value": "C121959"},
                             {"label": "Yaremche", "value": "C121960"},
                             {"label": "Zabolotiv", "value": "C121961"},
                             {"label": "Zahvizdya", "value": "C121962"},
                             {"label": "Zarichchya", "value": "C121963"}],
 "Ivanovo Oblast": [{"label": "Arkhipovka", "value": "C100889"},
                    {"label": "Bogorodskoye", "value": "C100890"},
                    {"label": "Dulyapino", "value": "C100891"},
                    {"label": "Furmanov", "value": "C100892"},
                    {"label": "Gavrilov Posad", "value": "C100893"},
                    {"label": "Ilinskoye-Khovanskoye", "value": "C100894"},
                    {"label": "Ivanovo", "value": "C100895"},
                    {"label": "Ivanovskiy Rayon", "value": "C100896"},
                    {"label": "Kamenka", "value": "C100897"},
                    {"label": "Kaminskiy", "value": "C100898"},
                    {"label": "Kineshemskiy Rayon", "value": "C100899"},
                    {"label": "Kineshma", "value": "C100900"},
                    {"label": "Kitovo", "value": "C100901"},
                    {"label": "Kokhma", "value": "C100902"},
                    {"label": "Kolobovo", "value": "C100903"},
                    {"label": "Komsomolsk", "value": "C100904"},
                    {"label": "Komsomolskiy Rayon", "value": "C100905"},
                    {"label": "Kuznechikha", "value": "C100906"},
                    {"label": "Lezhnevo", "value": "C100907"},
                    {"label": "Lezhnevskiy Rayon", "value": "C100908"},
                    {"label": "Lukh", "value": "C100909"},
                    {"label": "Lukhskiy Rayon", "value": "C100910"},
                    {"label": "Markovo", "value": "C100911"},
                    {"label": "Navoloki", "value": "C100912"},
                    {"label": "Nerl", "value": "C100913"},
                    {"label": "Novo-Talitsy", "value": "C100914"},
                    {"label": "Novopistsovo", "value": "C100915"},
                    {"label": "Novoye Leushino", "value": "C100916"},
                    {"label": "Novyye Gorki", "value": "C100917"},
                    {"label": "Palekh", "value": "C100918"},
                    {"label": "Palekhskiy Rayon", "value": "C100919"},
                    {"label": "Pestyaki", "value": "C100920"},
                    {"label": "Pestyakovskiy Rayon", "value": "C100921"},
                    {"label": "Petrovskiy", "value": "C100922"},
                    {"label": "Pistsovo", "value": "C100923"},
                    {"label": "Ples", "value": "C100924"},
                    {"label": "Privolzhsk", "value": "C100925"},
                    {"label": "Privolzhskiy Rayon", "value": "C100926"},
                    {"label": "Puchezh", "value": "C100927"},
                    {"label": "Puchezhskiy Rayon", "value": "C100928"},
                    {"label": "Rodniki", "value": "C100929"},
                    {"label": "Rodnikovskiy Rayon", "value": "C100930"},
                    {"label": "Savino", "value": "C100931"},
                    {"label": "Savinskiy Rayon", "value": "C100932"},
                    {"label": "Shuya", "value": "C100933"},
                    {"label": "Shuyskiy Rayon", "value": "C100934"},
                    {"label": "Staraya Vichuga", "value": "C100935"},
                    {"label": "Talitsy", "value": "C100936"},
                    {"label": "Teykovo", "value": "C100937"},
                    {"label": "Teykovskiy Rayon", "value": "C100938"},
                    {"label": "Verkhnelandekhovskiy Rayon", "value": "C100939"},
                    {"label": "Verkhniy Landekh", "value": "C100940"},
                    {"label": "Vichuga", "value": "C100941"},
                    {"label": "Vichugskiy Rayon", "value": "C100942"},
                    {"label": "Yuryevets", "value": "C100943"},
                    {"label": "Yuryevetskiy Rayon", "value": "C100944"},
                    {"label": "Yuzha", "value": "C100945"},
                    {"label": "Yuzhskiy Rayon", "value": "C100946"},
                    {"label": "Zarechnyy", "value": "C100947"},
                    {"label": "Zavolzhsk", "value": "C100948"},
                    {"label": "Zavolzhskiy Rayon", "value": "C100949"}],
 "Iwate Prefecture": [{"label": "Hachimantai", "value": "C63152"},
                      {"label": "Hachimantai Shi", "value": "C63153"},
                      {"label": "Hanamaki", "value": "C63154"},
                      {"label": "Hanamaki Shi", "value": "C63155"},
                      {"label": "Ichinohe", "value": "C63156"},
                      {"label": "Ichinoseki", "value": "C63157"},
                      {"label": "Ichinoseki-shi", "value": "C63158"},
                      {"label": "Iwate-gun", "value": "C63159"},
                      {"label": "Kamaishi", "value": "C63160"},
                      {"label": "Kamaishi-shi", "value": "C63161"},
                      {"label": "Kitakami", "value": "C63162"},
                      {"label": "Kitakami-shi", "value": "C63163"},
                      {"label": "Kuji", "value": "C63164"},
                      {"label": "Kuji-shi", "value": "C63165"},
                      {"label": "Miyako", "value": "C63166"},
                      {"label": "Miyako-shi", "value": "C63167"},
                      {"label": "Mizusawa", "value": "C63168"},
                      {"label": "Morioka", "value": "C63169"},
                      {"label": "Morioka-shi", "value": "C63170"},
                      {"label": "Ninohe", "value": "C63171"},
                      {"label": "Ninohe Shi", "value": "C63172"},
                      {"label": "Ofunato", "value": "C63173"},
                      {"label": "Ofunato-shi", "value": "C63174"},
                      {"label": "Oshu", "value": "C63175"},
                      {"label": "Oshu-shi", "value": "C63176"},
                      {"label": "Otsuchi", "value": "C63177"},
                      {"label": "Rikuzentakata-shi", "value": "C63178"},
                      {"label": "Shizukuishi", "value": "C63179"},
                      {"label": "Takizawa-shi", "value": "C63180"},
                      {"label": "Tono", "value": "C63181"},
                      {"label": "Tono-shi", "value": "C63182"},
                      {"label": "Yamada", "value": "C63183"}],
 "Izabal Department": [{"label": "El Estor", "value": "C42733"},
                       {"label": "Livingston", "value": "C42734"},
                       {"label": "Los Amates", "value": "C42735"},
                       {"label": "Morales", "value": "C42736"},
                       {"label": "Municipio de Morales", "value": "C42737"},
                       {"label": "Municipio de Puerto Barrios",
                        "value": "C42738"},
                       {"label": "Puerto Barrios", "value": "C42739"}],
 "Izmir": [{"label": "Alacati", "value": "C120462"},
           {"label": "Aliaga", "value": "C120463"},
           {"label": "Bademli", "value": "C120464"},
           {"label": "Balcova", "value": "C120465"},
           {"label": "Bayindir", "value": "C120466"},
           {"label": "Bayrakli", "value": "C120467"},
           {"label": "Belevi", "value": "C120468"},
           {"label": "Bergama", "value": "C120469"},
           {"label": "Beydag", "value": "C120470"},
           {"label": "Bornova", "value": "C120471"},
           {"label": "Buca", "value": "C120472"},
           {"label": "Candarli", "value": "C120473"},
           {"label": "Cayli", "value": "C120474"},
           {"label": "Cesme", "value": "C120475"},
           {"label": "Cigli", "value": "C120476"},
           {"label": "Dikili", "value": "C120477"},
           {"label": "Foca", "value": "C120478"},
           {"label": "Gaziemir", "value": "C120479"},
           {"label": "Gokcen", "value": "C120480"},
           {"label": "Guzelbahce", "value": "C120481"},
           {"label": "Izmir", "value": "C120482"},
           {"label": "Karabaglar", "value": "C120483"},
           {"label": "Karaburun", "value": "C120484"},
           {"label": "Karsiyaka Ilcesi", "value": "C120485"},
           {"label": "Kaymakci", "value": "C120486"},
           {"label": "Kemalpasa", "value": "C120487"},
           {"label": "Kiraz", "value": "C120488"},
           {"label": "Kinik", "value": "C120489"},
           {"label": "Konak", "value": "C120490"},
           {"label": "Konakli", "value": "C120491"},
           {"label": "Menderes", "value": "C120492"},
           {"label": "Menemen", "value": "C120493"},
           {"label": "Narlidere", "value": "C120494"},
           {"label": "Odemis", "value": "C120495"},
           {"label": "Ozdere", "value": "C120496"},
           {"label": "Seferihisar", "value": "C120497"},
           {"label": "Selcuk", "value": "C120498"},
           {"label": "Tire", "value": "C120499"},
           {"label": "Torbali", "value": "C120500"},
           {"label": "Urla", "value": "C120501"},
           {"label": "Yenifoca", "value": "C120502"},
           {"label": "Yenisakran", "value": "C120503"},
           {"label": "Zeytindag", "value": "C120504"}],
 "Izola Municipality": [{"label": "Izola", "value": "C107646"},
                        {"label": "Jagodje", "value": "C107647"}],
 "Jabal al Akhdar": [{"label": "Al Bayda", "value": "C65031"}],
 "Jabal al Gharbi District": [{"label": "Gharyan", "value": "C65032"},
                              {"label": "Giado", "value": "C65033"},
                              {"label": "Mizdah", "value": "C65034"},
                              {"label": "Yafran", "value": "C65035"},
                              {"label": "Zintan", "value": "C65036"}],
 "Jabrayil District": [{"label": "Jebrail", "value": "C8443"}],
 "Jafara": [{"label": "Al Aziziyah", "value": "C65037"}],
 "Jalal-Abad Region": [{"label": "Ala-Buka", "value": "C64738"},
                       {"label": "Bazar-Korgon", "value": "C64739"},
                       {"label": "Jalal-Abad", "value": "C64740"},
                       {"label": "Kazarman", "value": "C64741"},
                       {"label": "Kerben", "value": "C64742"},
                       {"label": "Kochkor-Ata", "value": "C64743"},
                       {"label": "Suzak", "value": "C64744"},
                       {"label": "Tash-Kumyr", "value": "C64745"},
                       {"label": "Toktogul", "value": "C64746"},
                       {"label": "Toktogul District", "value": "C64747"}],
 "Jalapa Department": [{"label": "Jalapa", "value": "C42740"},
                       {"label": "Mataquescuintla", "value": "C42741"},
                       {"label": "Monjas", "value": "C42742"},
                       {"label": "Municipio de Jalapa", "value": "C42743"},
                       {"label": "Municipio de Mataquescuintla",
                        "value": "C42744"},
                       {"label": "San Luis Jilotepeque", "value": "C42745"},
                       {"label": "San Manuel Chaparron", "value": "C42746"},
                       {"label": "San Pedro Pinula", "value": "C42747"}],
 "Jalilabad District": [{"label": "Jalilabad", "value": "C8444"},
                        {"label": "Prishibinskoye", "value": "C8445"}],
 "Jalisco": [{"label": "Acatlan de Juarez Municipality", "value": "C69625"},
             {"label": "Acueducto Fraccionamiento", "value": "C69626"},
             {"label": "Agua Bermeja", "value": "C69627"},
             {"label": "Agua Caliente", "value": "C69628"},
             {"label": "Agua Colorada", "value": "C69629"},
             {"label": "Agua Delgada", "value": "C69630"},
             {"label": "Agua Escondida", "value": "C69631"},
             {"label": "Agua Fria", "value": "C69632"},
             {"label": "Agua Nueva", "value": "C69633"},
             {"label": "Agua Paloma", "value": "C69634"},
             {"label": "Agua Prieta", "value": "C69635"},
             {"label": "Agua Rica", "value": "C69636"},
             {"label": "Agua Salada", "value": "C69637"},
             {"label": "Agua Zarca", "value": "C69638"},
             {"label": "Ahualulco de Mercado Municipality", "value": "C69639"},
             {"label": "Ahuatlan", "value": "C69640"},
             {"label": "Ahuetita de Abajo", "value": "C69641"},
             {"label": "Ahuisculco", "value": "C69642"},
             {"label": "Ajijic", "value": "C69643"},
             {"label": "Alfredo V. Bonfil", "value": "C69644"},
             {"label": "Alista", "value": "C69645"},
             {"label": "Allende", "value": "C69646"},
             {"label": "Altavista de Ramos", "value": "C69647"},
             {"label": "Altus Bosques", "value": "C69648"},
             {"label": "Amacuautitlan", "value": "C69649"},
             {"label": "Amacueca Municipality", "value": "C69650"},
             {"label": "Amajaquillo", "value": "C69651"},
             {"label": "Amatanejo", "value": "C69652"},
             {"label": "Amatitan", "value": "C69653"},
             {"label": "Ambrosio", "value": "C69654"},
             {"label": "Ameca", "value": "C69655"},
             {"label": "Ameca Municipality", "value": "C69656"},
             {"label": "Analco", "value": "C69657"},
             {"label": "Anoca", "value": "C69658"},
             {"label": "Antonio Escobedo", "value": "C69659"},
             {"label": "Apazulco", "value": "C69660"},
             {"label": "Apozol de Gutierrez", "value": "C69661"},
             {"label": "Aquiles Serdan", "value": "C69662"},
             {"label": "Arandas", "value": "C69663"},
             {"label": "Arroyo Hondo", "value": "C69664"},
             {"label": "Atacco", "value": "C69665"},
             {"label": "Atarjeas de Covarrubias", "value": "C69666"},
             {"label": "Atemanica", "value": "C69667"},
             {"label": "Atengo", "value": "C69668"},
             {"label": "Atenguillo", "value": "C69669"},
             {"label": "Atequiza", "value": "C69670"},
             {"label": "Atotonilco el Alto Municipality", "value": "C69671"},
             {"label": "Atotonilco el Bajo", "value": "C69672"},
             {"label": "Atotonilquillo", "value": "C69673"},
             {"label": "Atoyac", "value": "C69674"},
             {"label": "Atzcatlan", "value": "C69675"},
             {"label": "Autlan de Navarro", "value": "C69676"},
             {"label": "Ayotitlan", "value": "C69677"},
             {"label": "Ayotlan Municipality", "value": "C69678"},
             {"label": "Ayuquila", "value": "C69679"},
             {"label": "Ayutla, Jalisco", "value": "C69680"},
             {"label": "Bajio de Ratones", "value": "C69681"},
             {"label": "Bajio de San Jose", "value": "C69682"},
             {"label": "Bajio Seco", "value": "C69683"},
             {"label": "Balcones de la Calera", "value": "C69684"},
             {"label": "Banus Vallarta", "value": "C69685"},
             {"label": "Barra de Navidad", "value": "C69686"},
             {"label": "Barranca de los Laureles", "value": "C69687"},
             {"label": "Barranca de Otates", "value": "C69688"},
             {"label": "Barranca de Santa Clara", "value": "C69689"},
             {"label": "Barranca del Tule", "value": "C69690"},
             {"label": "Barrio de Reyes", "value": "C69691"},
             {"label": "Barrio de Tapias", "value": "C69692"},
             {"label": "Belem del Refugio", "value": "C69693"},
             {"label": "Bellavista", "value": "C69694"},
             {"label": "Benito Juarez", "value": "C69695"},
             {"label": "Betania", "value": "C69696"},
             {"label": "Betulia", "value": "C69697"},
             {"label": "Bicercio", "value": "C69698"},
             {"label": "Boca de Tomatlan", "value": "C69699"},
             {"label": "Bolanos Municipality", "value": "C69700"},
             {"label": "Bosque Real Fraccionamiento", "value": "C69701"},
             {"label": "Brisas de la Navidad", "value": "C69702"},
             {"label": "Brizuela", "value": "C69703"},
             {"label": "Buena Vista", "value": "C69704"},
             {"label": "Buenavista", "value": "C69705"},
             {"label": "Buenos Aires", "value": "C69706"},
             {"label": "Cabo Corrientes", "value": "C69707"},
             {"label": "Cajititlan", "value": "C69708"},
             {"label": "Calerita", "value": "C69709"},
             {"label": "Callejones", "value": "C69710"},
             {"label": "Camajapita", "value": "C69711"},
             {"label": "Campestre las Palomas Fraccionamiento",
              "value": "C69712"},
             {"label": "Campo Acosta", "value": "C69713"},
             {"label": "Campo Real", "value": "C69714"},
             {"label": "Canada de Islas", "value": "C69715"},
             {"label": "Canada de Ricos", "value": "C69716"},
             {"label": "Canada de San Ignacio", "value": "C69717"},
             {"label": "Canada Grande", "value": "C69718"},
             {"label": "Canadas de Obregon", "value": "C69719"},
             {"label": "Canchol", "value": "C69720"},
             {"label": "Canoas", "value": "C69721"},
             {"label": "Canoas de Arriba", "value": "C69722"},
             {"label": "Capilla de Guadalupe", "value": "C69723"},
             {"label": "Capilla de Milpillas", "value": "C69724"},
             {"label": "Capulin", "value": "C69725"},
             {"label": "Caquixtle de Arriba", "value": "C69726"},
             {"label": "Carrasqueno", "value": "C69727"},
             {"label": "Carretas", "value": "C69728"},
             {"label": "Carrizal", "value": "C69729"},
             {"label": "Casa de Teja", "value": "C69730"},
             {"label": "Casas Viejas", "value": "C69731"},
             {"label": "Casimiro Castillo", "value": "C69732"},
             {"label": "Castro Urdiales", "value": "C69733"},
             {"label": "Ceja de Bolitas", "value": "C69734"},
             {"label": "Centro de Readaptacion Social", "value": "C69735"},
             {"label": "Cerrito de Buenos Aires", "value": "C69736"},
             {"label": "Cerrito de Tierra", "value": "C69737"},
             {"label": "Cerro Santa Fe", "value": "C69738"},
             {"label": "Chacala", "value": "C69739"},
             {"label": "Chachahuatlan", "value": "C69740"},
             {"label": "Chalpicote", "value": "C69741"},
             {"label": "Chamacuero", "value": "C69742"},
             {"label": "Chamela, Jalisco", "value": "C69743"},
             {"label": "Chancol", "value": "C69744"},
             {"label": "Chancuellar", "value": "C69745"},
             {"label": "Chapala", "value": "C69746"},
             {"label": "Chayotillo", "value": "C69747"},
             {"label": "Chilacayote", "value": "C69748"},
             {"label": "Chimaltitan", "value": "C69749"},
             {"label": "Chinampas", "value": "C69750"},
             {"label": "Chipinque de Arriba", "value": "C69751"},
             {"label": "Chiquihuitillo", "value": "C69752"},
             {"label": "Chiquilistlan", "value": "C69753"},
             {"label": "Chiquilistlan, Jalisco", "value": "C69754"},
             {"label": "Chome", "value": "C69755"},
             {"label": "Churintzio", "value": "C69756"},
             {"label": "Cienega de Galvanes", "value": "C69757"},
             {"label": "Cienega del Pastor", "value": "C69758"},
             {"label": "Cihuatlan, Jalisco", "value": "C69759"},
             {"label": "Cima del Sol", "value": "C69760"},
             {"label": "Citala", "value": "C69761"},
             {"label": "Ciudad Guzman", "value": "C69762"},
             {"label": "Ciudad Guzman CERESO", "value": "C69763"},
             {"label": "Coamostita", "value": "C69764"},
             {"label": "Coatlancillo", "value": "C69765"},
             {"label": "Cocuasco", "value": "C69766"},
             {"label": "Cocula, Jalisco", "value": "C69767"},
             {"label": "Codorniz", "value": "C69768"},
             {"label": "Cofradia", "value": "C69769"},
             {"label": "Cofradia Santa Cruz de Camotlan", "value": "C69770"},
             {"label": "Colinas del Roble", "value": "C69771"},
             {"label": "Colinas del Valle", "value": "C69772"},
             {"label": "Colonia Agua Caliente", "value": "C69773"},
             {"label": "Colonia Bosques del Sol", "value": "C69774"},
             {"label": "Colonia Capilla de Rayo", "value": "C69775"},
             {"label": "Colonia Cuisillos", "value": "C69776"},
             {"label": "Colonia Ganadera", "value": "C69777"},
             {"label": "Colonia Guadalupana", "value": "C69778"},
             {"label": "Colonia Hatmasie", "value": "C69779"},
             {"label": "Colonia la Granjena", "value": "C69780"},
             {"label": "Colonia las Granjas", "value": "C69781"},
             {"label": "Colonia las Palmas Campamento SAGAR",
              "value": "C69782"},
             {"label": "Colonia Lazaro Cardenas", "value": "C69783"},
             {"label": "Colonia Lazaro Cardenas del Rio", "value": "C69784"},
             {"label": "Colonia Loma Alta", "value": "C69785"},
             {"label": "Colonia los Robles", "value": "C69786"},
             {"label": "Colonia los Sauces", "value": "C69787"},
             {"label": "Colonia Madero", "value": "C69788"},
             {"label": "Colonia Ninos Heroes", "value": "C69789"},
             {"label": "Colonia Paso del Molino", "value": "C69790"},
             {"label": "Colonia Pinal Villa", "value": "C69791"},
             {"label": "Colonia Santa Cecilia", "value": "C69792"},
             {"label": "Colonia Trigomil", "value": "C69793"},
             {"label": "Colonia Veintitres de Mayo", "value": "C69794"},
             {"label": "Colonia Veintiuno de Marzo", "value": "C69795"},
             {"label": "Colotlan", "value": "C69796"},
             {"label": "Colotlan Municipality", "value": "C69797"},
             {"label": "Comanja de Corona", "value": "C69798"},
             {"label": "Concepcion de Buenos Aires", "value": "C69799"},
             {"label": "Concepcion del Bramador", "value": "C69800"},
             {"label": "Copala", "value": "C69801"},
             {"label": "Copales", "value": "C69802"},
             {"label": "Corral Blanco", "value": "C69803"},
             {"label": "Corral de Piedra", "value": "C69804"},
             {"label": "Corralillos", "value": "C69805"},
             {"label": "Coyamel", "value": "C69806"},
             {"label": "Coyula", "value": "C69807"},
             {"label": "Cristeros Fraccionamiento", "value": "C69808"},
             {"label": "Cuauhtemoc", "value": "C69809"},
             {"label": "Cuautitlan", "value": "C69810"},
             {"label": "Cuautitlan de Garcia Barragan", "value": "C69811"},
             {"label": "Cuautla", "value": "C69812"},
             {"label": "Cuesta Chica", "value": "C69813"},
             {"label": "Cuesta de Ovejas", "value": "C69814"},
             {"label": "Cuexcomatitlan", "value": "C69815"},
             {"label": "Cuisillos", "value": "C69816"},
             {"label": "Cuitzeo", "value": "C69817"},
             {"label": "Cuquio", "value": "C69818"},
             {"label": "Custique", "value": "C69819"},
             {"label": "Cuxpala", "value": "C69820"},
             {"label": "Degollado", "value": "C69821"},
             {"label": "Dieciocho de Marzo", "value": "C69822"},
             {"label": "Ejido Agua Zarca", "value": "C69823"},
             {"label": "Ejido Cienega de Mora", "value": "C69824"},
             {"label": "Ejido Modelo", "value": "C69825"},
             {"label": "Ejido Poblado Atenquique", "value": "C69826"},
             {"label": "Ejido Uno de Febrero", "value": "C69827"},
             {"label": "Ejutla", "value": "C69828"},
             {"label": "El Agostadero", "value": "C69829"},
             {"label": "El Aguacate", "value": "C69830"},
             {"label": "El Alto", "value": "C69831"},
             {"label": "El Amarillo", "value": "C69832"},
             {"label": "El Amarradero", "value": "C69833"},
             {"label": "El Arca", "value": "C69834"},
             {"label": "El Arco, Ameca", "value": "C69835"},
             {"label": "El Arenal", "value": "C69836"},
             {"label": "El Asoleadero", "value": "C69837"},
             {"label": "El Bajio", "value": "C69838"},
             {"label": "El Bajio de Plascencia", "value": "C69839"},
             {"label": "El Bajio Lomas del Club", "value": "C69840"},
             {"label": "El Banco del Venado", "value": "C69841"},
             {"label": "El Barrio", "value": "C69842"},
             {"label": "El Brillante Fraccionamiento", "value": "C69843"},
             {"label": "El Briseno", "value": "C69844"},
             {"label": "El Cabezon", "value": "C69845"},
             {"label": "El Cacalote", "value": "C69846"},
             {"label": "El Camichin", "value": "C69847"},
             {"label": "El Canton", "value": "C69848"},
             {"label": "El Capulin de Chavez", "value": "C69849"},
             {"label": "El Carmen", "value": "C69850"},
             {"label": "El Carricillo", "value": "C69851"},
             {"label": "El Carrizal", "value": "C69852"},
             {"label": "El Cedazo", "value": "C69853"},
             {"label": "El Cerrito", "value": "C69854"},
             {"label": "El Cerro, Jalisco", "value": "C69855"},
             {"label": "El Chalate", "value": "C69856"},
             {"label": "El Chante", "value": "C69857"},
             {"label": "El Chaveno", "value": "C69858"},
             {"label": "El Chilar", "value": "C69859"},
             {"label": "El Chispeadero", "value": "C69860"},
             {"label": "El Chivatillo", "value": "C69861"},
             {"label": "El Coahuayote", "value": "C69862"},
             {"label": "El Colomo", "value": "C69863"},
             {"label": "El Colorado", "value": "C69864"},
             {"label": "El Columpio", "value": "C69865"},
             {"label": "El Conde", "value": "C69866"},
             {"label": "El Conejo", "value": "C69867"},
             {"label": "El Corcovado", "value": "C69868"},
             {"label": "El Cortijo", "value": "C69869"},
             {"label": "El Coyotillo", "value": "C69870"},
             {"label": "El Crucero", "value": "C69871"},
             {"label": "El Crucero de Arandas", "value": "C69872"},
             {"label": "El Crucero de Santa Maria", "value": "C69873"},
             {"label": "El Cuatro", "value": "C69874"},
             {"label": "El Derramadero", "value": "C69875"},
             {"label": "El Dique", "value": "C69876"},
             {"label": "El Fraile", "value": "C69877"},
             {"label": "El Gato", "value": "C69878"},
             {"label": "El Grullo Municipality", "value": "C69879"},
             {"label": "El Hollejo", "value": "C69880"},
             {"label": "El Huiroche", "value": "C69881"},
             {"label": "El Jabali", "value": "C69882"},
             {"label": "El Jaguey", "value": "C69883"},
             {"label": "El Jaral", "value": "C69884"},
             {"label": "El Jaralillo", "value": "C69885"},
             {"label": "El Jaralito", "value": "C69886"},
             {"label": "El Jardin", "value": "C69887"},
             {"label": "El Jazmin", "value": "C69888"},
             {"label": "El Jomate", "value": "C69889"},
             {"label": "El Laurel", "value": "C69890"},
             {"label": "El Lazo", "value": "C69891"},
             {"label": "El Limon", "value": "C69892"},
             {"label": "El Llano de Barajas", "value": "C69893"},
             {"label": "El Llano de los Toros", "value": "C69894"},
             {"label": "El Llano de Plascencia", "value": "C69895"},
             {"label": "El Lobo", "value": "C69896"},
             {"label": "El Lopeno", "value": "C69897"},
             {"label": "El Loreto Occidental", "value": "C69898"},
             {"label": "El Macuchi", "value": "C69899"},
             {"label": "El Maestranzo", "value": "C69900"},
             {"label": "El Manantial", "value": "C69901"},
             {"label": "El Mastranzo", "value": "C69902"},
             {"label": "El Mayoral de Abajo", "value": "C69903"},
             {"label": "El Medineno", "value": "C69904"},
             {"label": "El Mentidero", "value": "C69905"},
             {"label": "El Mesoncito", "value": "C69906"},
             {"label": "El Mezquite Grande", "value": "C69907"},
             {"label": "El Mirador", "value": "C69908"},
             {"label": "El Molino", "value": "C69909"},
             {"label": "El Moral", "value": "C69910"},
             {"label": "El Muey", "value": "C69911"},
             {"label": "El Nacimiento", "value": "C69912"},
             {"label": "El Nacimiento de Abajo", "value": "C69913"},
             {"label": "El Naranjo", "value": "C69914"},
             {"label": "El Nuevo Poblado", "value": "C69915"},
             {"label": "El Ocote", "value": "C69916"},
             {"label": "El Ocotillo", "value": "C69917"},
             {"label": "El Ojo de Agua", "value": "C69918"},
             {"label": "El Ojuelo", "value": "C69919"},
             {"label": "El Olvido", "value": "C69920"},
             {"label": "El Padre Juan", "value": "C69921"},
             {"label": "El Palmar de San Antonio", "value": "C69922"},
             {"label": "El Pantano", "value": "C69923"},
             {"label": "El Paso Real", "value": "C69924"},
             {"label": "El Pino", "value": "C69925"},
             {"label": "El Plan", "value": "C69926"},
             {"label": "El Platanar", "value": "C69927"},
             {"label": "El Pochote", "value": "C69928"},
             {"label": "El Popote", "value": "C69929"},
             {"label": "El Portezuelo", "value": "C69930"},
             {"label": "El Porvenir", "value": "C69931"},
             {"label": "El Pozo Santo", "value": "C69932"},
             {"label": "El Pueblito", "value": "C69933"},
             {"label": "El Puente", "value": "C69934"},
             {"label": "El Puerto", "value": "C69935"},
             {"label": "El Puerto de Amolero", "value": "C69936"},
             {"label": "El Puesto", "value": "C69937"},
             {"label": "El Quemado", "value": "C69938"},
             {"label": "El Quince", "value": "C69939"},
             {"label": "El Ranchito", "value": "C69940"},
             {"label": "El Rayo", "value": "C69941"},
             {"label": "El Rebalse", "value": "C69942"},
             {"label": "El Rebalsito de Apazulco", "value": "C69943"},
             {"label": "El Refugio", "value": "C69944"},
             {"label": "El Refugio de los Altos", "value": "C69945"},
             {"label": "El Refugio de Vazquez", "value": "C69946"},
             {"label": "El Refugio Suchitlan", "value": "C69947"},
             {"label": "El Refugio, Jalisco", "value": "C69948"},
             {"label": "El Reparo", "value": "C69949"},
             {"label": "El Rincon", "value": "C69950"},
             {"label": "El Rodeo", "value": "C69951"},
             {"label": "El Rodeo Viejo", "value": "C69952"},
             {"label": "El Romereno", "value": "C69953"},
             {"label": "El Rosario", "value": "C69954"},
             {"label": "El Sabino", "value": "C69955"},
             {"label": "El Sacrificio", "value": "C69956"},
             {"label": "El Salero", "value": "C69957"},
             {"label": "El Salitre", "value": "C69958"},
             {"label": "El Salto", "value": "C69959"},
             {"label": "El Salto de las Penas", "value": "C69960"},
             {"label": "El Salto de San Antonio", "value": "C69961"},
             {"label": "El Salto Municipality", "value": "C69962"},
             {"label": "El Salvador", "value": "C69963"},
             {"label": "El Salvial", "value": "C69964"},
             {"label": "El Saucillo", "value": "C69965"},
             {"label": "El Saucillo de los Perez", "value": "C69966"},
             {"label": "El Saucito", "value": "C69967"},
             {"label": "El Sauz de Ibarra", "value": "C69968"},
             {"label": "El Seminario", "value": "C69969"},
             {"label": "El Sitio", "value": "C69970"},
             {"label": "El Sopial", "value": "C69971"},
             {"label": "El Soyate", "value": "C69972"},
             {"label": "El Tablero", "value": "C69973"},
             {"label": "El Taray", "value": "C69974"},
             {"label": "El Tarengo Nuevo", "value": "C69975"},
             {"label": "El Tecolote", "value": "C69976"},
             {"label": "El Tecuan", "value": "C69977"},
             {"label": "El Tempisque", "value": "C69978"},
             {"label": "El Tepame", "value": "C69979"},
             {"label": "El Tepec", "value": "C69980"},
             {"label": "El Tepetate", "value": "C69981"},
             {"label": "El Tepozan", "value": "C69982"},
             {"label": "El Tequesquite", "value": "C69983"},
             {"label": "El Terrerito de Sevilla", "value": "C69984"},
             {"label": "El Terrero", "value": "C69985"},
             {"label": "El Torque", "value": "C69986"},
             {"label": "El Torreon", "value": "C69987"},
             {"label": "El Tuito", "value": "C69988"},
             {"label": "El Tule", "value": "C69989"},
             {"label": "El Tulillo", "value": "C69990"},
             {"label": "El Uvalano", "value": "C69991"},
             {"label": "El Verde", "value": "C69992"},
             {"label": "El Vergel", "value": "C69993"},
             {"label": "El Vigia", "value": "C69994"},
             {"label": "El Zancudo", "value": "C69995"},
             {"label": "El Zapatero", "value": "C69996"},
             {"label": "El Zapote", "value": "C69997"},
             {"label": "El Zapote de Chavez", "value": "C69998"},
             {"label": "El Zapotillo", "value": "C69999"},
             {"label": "Emiliano Zapata", "value": "C70000"},
             {"label": "Emiliano Zapata, Jalisco", "value": "C70001"},
             {"label": "Encarnacion de Diaz", "value": "C70002"},
             {"label": "Epeche Chico", "value": "C70003"},
             {"label": "Epeche Grande", "value": "C70004"},
             {"label": "Estancia de San Nicolas", "value": "C70005"},
             {"label": "Estancia Vieja de Arriba", "value": "C70006"},
             {"label": "Estanzuela", "value": "C70007"},
             {"label": "Estipa", "value": "C70008"},
             {"label": "Etzatlan Municipality", "value": "C70009"},
             {"label": "Ferreria de Tula", "value": "C70010"},
             {"label": "Francisco I. Madero", "value": "C70011"},
             {"label": "Francisco Primo de Verdad", "value": "C70012"},
             {"label": "Galaxia Bonito Jalisco", "value": "C70013"},
             {"label": "Galaxia la Noria", "value": "C70014"},
             {"label": "Gargantillo", "value": "C70015"},
             {"label": "General Andres Figueroa", "value": "C70016"},
             {"label": "General Gabriel Leyva", "value": "C70017"},
             {"label": "Geovillas Laureles del Campanario", "value": "C70018"},
             {"label": "Getsemani", "value": "C70019"},
             {"label": "Gomez Farias Municipality", "value": "C70020"},
             {"label": "Granadillas", "value": "C70021"},
             {"label": "Guachinango", "value": "C70022"},
             {"label": "Guadalajara", "value": "C70023"},
             {"label": "Guadalupe de Lerma", "value": "C70024"},
             {"label": "Guadalupe Victoria", "value": "C70025"},
             {"label": "Guamuchilillo", "value": "C70026"},
             {"label": "Guayabitos", "value": "C70027"},
             {"label": "Hacienda de la Labor", "value": "C70028"},
             {"label": "Hacienda los Fresnos", "value": "C70029"},
             {"label": "Hacienda Santa Fe", "value": "C70030"},
             {"label": "Hacienda Vieja", "value": "C70031"},
             {"label": "Hacienda Vieja del Castillo", "value": "C70032"},
             {"label": "Haciendas la Herradura", "value": "C70033"},
             {"label": "Hierba de Matancillas", "value": "C70034"},
             {"label": "Higueras San Miguel", "value": "C70035"},
             {"label": "Higuerillas", "value": "C70036"},
             {"label": "Hostotipaquillo", "value": "C70037"},
             {"label": "Huajacatlan", "value": "C70038"},
             {"label": "Huascato", "value": "C70039"},
             {"label": "Huaxtla", "value": "C70040"},
             {"label": "Huejotitan", "value": "C70041"},
             {"label": "Huejotitlan", "value": "C70042"},
             {"label": "Huejucar", "value": "C70043"},
             {"label": "Huejuquilla El Alto Municipality", "value": "C70044"},
             {"label": "Huertas Familiares San Pedro", "value": "C70045"},
             {"label": "Huisichi", "value": "C70046"},
             {"label": "Huitzometl", "value": "C70047"},
             {"label": "Huizaches", "value": "C70048"},
             {"label": "Ipazoltic", "value": "C70049"},
             {"label": "IPROVIPE I", "value": "C70050"},
             {"label": "Ixtapa", "value": "C70051"},
             {"label": "Ixtlahuacan de los Membrillos", "value": "C70052"},
             {"label": "Ixtlahuacan del Rio", "value": "C70053"},
             {"label": "Izolta", "value": "C70054"},
             {"label": "J. Isabel Flores", "value": "C70055"},
             {"label": "Jalostotitlan", "value": "C70056"},
             {"label": "Jaluco", "value": "C70057"},
             {"label": "Jamay", "value": "C70058"},
             {"label": "Jardines de San Sebastian", "value": "C70059"},
             {"label": "Jardines del Eden", "value": "C70060"},
             {"label": "Jaritas", "value": "C70061"},
             {"label": "Jesus Maria", "value": "C70062"},
             {"label": "Jesus Maria, Nayarit", "value": "C70063"},
             {"label": "Jilotlan de los Dolores", "value": "C70064"},
             {"label": "Jiquilpan", "value": "C70065"},
             {"label": "Joconoxtle", "value": "C70066"},
             {"label": "Jocotepec", "value": "C70067"},
             {"label": "Jose de Jesus Aguirre, Teocaltiche", "value": "C70068"},
             {"label": "Jose Maria Pino Suarez", "value": "C70069"},
             {"label": "Josefa Ortiz de Dominguez", "value": "C70070"},
             {"label": "Juan Gil Preciado", "value": "C70071"},
             {"label": "Juanacatlan", "value": "C70072"},
             {"label": "La Alameda", "value": "C70073"},
             {"label": "La Aldaba", "value": "C70074"},
             {"label": "La Angostura de los Altos", "value": "C70075"},
             {"label": "La Arbolada Plus", "value": "C70076"},
             {"label": "La Barca", "value": "C70077"},
             {"label": "La Barranca", "value": "C70078"},
             {"label": "La Calera", "value": "C70079"},
             {"label": "La Calera Fraccionamiento", "value": "C70080"},
             {"label": "La Canada", "value": "C70081"},
             {"label": "La Canada Tezcaltilti", "value": "C70082"},
             {"label": "La Cantera", "value": "C70083"},
             {"label": "La Cebadilla", "value": "C70084"},
             {"label": "La Ceja de la Capilla", "value": "C70085"},
             {"label": "La Cerca Blanca", "value": "C70086"},
             {"label": "La Cienega", "value": "C70087"},
             {"label": "La Cienega de Huaistita", "value": "C70088"},
             {"label": "La Cieneguita", "value": "C70089"},
             {"label": "La Cofradia", "value": "C70090"},
             {"label": "La Cofradia del Rosario", "value": "C70091"},
             {"label": "La Colonia San Juanito", "value": "C70092"},
             {"label": "La Concepcion", "value": "C70093"},
             {"label": "La Conchilla", "value": "C70094"},
             {"label": "La Conchita", "value": "C70095"},
             {"label": "La Constancia", "value": "C70096"},
             {"label": "La Cruz", "value": "C70097"},
             {"label": "La Cruz de Loreto", "value": "C70098"},
             {"label": "La Cuchilla", "value": "C70099"},
             {"label": "La Cuesta de Medina", "value": "C70100"},
             {"label": "La Cueva", "value": "C70101"},
             {"label": "La Estacada", "value": "C70102"},
             {"label": "La Estancia", "value": "C70103"},
             {"label": "La Estancia de Arriba", "value": "C70104"},
             {"label": "La Estancia de Ayones", "value": "C70105"},
             {"label": "La Estancia de Navarro", "value": "C70106"},
             {"label": "La Estancita", "value": "C70107"},
             {"label": "La Estanzuela", "value": "C70108"},
             {"label": "La Florena", "value": "C70109"},
             {"label": "La Fortuna Fraccionamiento", "value": "C70110"},
             {"label": "La Frontera", "value": "C70111"},
             {"label": "La Garita, Jalisco", "value": "C70112"},
             {"label": "La Garruna", "value": "C70113"},
             {"label": "La Gila", "value": "C70114"},
             {"label": "La Gloria", "value": "C70115"},
             {"label": "La Granjena", "value": "C70116"},
             {"label": "La Guadalupe", "value": "C70117"},
             {"label": "La Guasima", "value": "C70118"},
             {"label": "La Hermosura", "value": "C70119"},
             {"label": "La Higuera", "value": "C70120"},
             {"label": "La Huerta", "value": "C70121"},
             {"label": "La Huevera", "value": "C70122"},
             {"label": "La Isla", "value": "C70123"},
             {"label": "La Jarrilla", "value": "C70124"},
             {"label": "La Joya", "value": "C70125"},
             {"label": "La Joya Chica", "value": "C70126"},
             {"label": "La Joya Grande", "value": "C70127"},
             {"label": "La Labor de la Concepcion", "value": "C70128"},
             {"label": "La Labor de los Alba", "value": "C70129"},
             {"label": "La Ladera", "value": "C70130"},
             {"label": "La Ladera Grande", "value": "C70131"},
             {"label": "La Laguna", "value": "C70132"},
             {"label": "La Laguna Grande", "value": "C70133"},
             {"label": "La Laja", "value": "C70134"},
             {"label": "La Lobera", "value": "C70135"},
             {"label": "La Loma", "value": "C70136"},
             {"label": "La Loma de los Rizo", "value": "C70137"},
             {"label": "La Loma del Nopal", "value": "C70138"},
             {"label": "La Lomita", "value": "C70139"},
             {"label": "La Luz", "value": "C70140"},
             {"label": "La Manzanilla", "value": "C70141"},
             {"label": "La Manzanilla de La Paz", "value": "C70142"},
             {"label": "La Mata", "value": "C70143"},
             {"label": "La Merced", "value": "C70144"},
             {"label": "La Mesa", "value": "C70145"},
             {"label": "La Mesa del Guitarrero", "value": "C70146"},
             {"label": "La Mesita", "value": "C70147"},
             {"label": "La Mezcalilla", "value": "C70148"},
             {"label": "La Mezquitera", "value": "C70149"},
             {"label": "La Mial", "value": "C70150"},
             {"label": "La Mina", "value": "C70151"},
             {"label": "La Mojarra", "value": "C70152"},
             {"label": "La Mora", "value": "C70153"},
             {"label": "La Mota", "value": "C70154"},
             {"label": "La Nance", "value": "C70155"},
             {"label": "La Nopalera", "value": "C70156"},
             {"label": "La Noria", "value": "C70157"},
             {"label": "La Orilla de la Cerca", "value": "C70158"},
             {"label": "La Orilla del Agua", "value": "C70159"},
             {"label": "La Palma", "value": "C70160"},
             {"label": "La Palmita", "value": "C70161"},
             {"label": "La Parota", "value": "C70162"},
             {"label": "La Parrita", "value": "C70163"},
             {"label": "La Paz", "value": "C70164"},
             {"label": "La Paz de Ordaz", "value": "C70165"},
             {"label": "La Pena, Jalisco", "value": "C70166"},
             {"label": "La Playa", "value": "C70167"},
             {"label": "La Presa", "value": "C70168"},
             {"label": "La Primavera", "value": "C70169"},
             {"label": "La Providencia", "value": "C70170"},
             {"label": "La Puerta de San Pedro", "value": "C70171"},
             {"label": "La Puerta del Coche", "value": "C70172"},
             {"label": "La Punta", "value": "C70173"},
             {"label": "La Purisima", "value": "C70174"},
             {"label": "La Quemada", "value": "C70175"},
             {"label": "La Rana", "value": "C70176"},
             {"label": "La Raya", "value": "C70177"},
             {"label": "La Red", "value": "C70178"},
             {"label": "La Resolana", "value": "C70179"},
             {"label": "La Ribera", "value": "C70180"},
             {"label": "La Roca", "value": "C70181"},
             {"label": "La Rosa", "value": "C70182"},
             {"label": "La Rosa Amarilla", "value": "C70183"},
             {"label": "La Rosa de Castilla", "value": "C70184"},
             {"label": "La Rueda", "value": "C70185"},
             {"label": "La Sanguijuela", "value": "C70186"},
             {"label": "La Sauceda", "value": "C70187"},
             {"label": "La Soledad", "value": "C70188"},
             {"label": "La Teja", "value": "C70189"},
             {"label": "La Tijera", "value": "C70190"},
             {"label": "La Tinajera", "value": "C70191"},
             {"label": "La Tiricia", "value": "C70192"},
             {"label": "La Tortuga", "value": "C70193"},
             {"label": "La Trinidad", "value": "C70194"},
             {"label": "La Union del Cuatro", "value": "C70195"},
             {"label": "La Vaquera", "value": "C70196"},
             {"label": "La Vega", "value": "C70197"},
             {"label": "La Venta del Astillero", "value": "C70198"},
             {"label": "La Verdolaga", "value": "C70199"},
             {"label": "La Villa", "value": "C70200"},
             {"label": "La Virgencita", "value": "C70201"},
             {"label": "La Yerbabuena", "value": "C70202"},
             {"label": "La Zapotera", "value": "C70203"},
             {"label": "Labor de Medina", "value": "C70204"},
             {"label": "Labor de Montoya", "value": "C70205"},
             {"label": "Ladera Chica", "value": "C70206"},
             {"label": "Lagos de Moreno", "value": "C70207"},
             {"label": "Laguna Colorada", "value": "C70208"},
             {"label": "Laguna de Piedra", "value": "C70209"},
             {"label": "Lagunillas, Jalisco", "value": "C70210"},
             {"label": "Las Alfalfas", "value": "C70211"},
             {"label": "Las Amarillas", "value": "C70212"},
             {"label": "Las Asturias Fraccionamiento", "value": "C70213"},
             {"label": "Las Azules", "value": "C70214"},
             {"label": "Las Calaveras", "value": "C70215"},
             {"label": "Las Canadas", "value": "C70216"},
             {"label": "Las Canoas", "value": "C70217"},
             {"label": "Las Carretas", "value": "C70218"},
             {"label": "Las Golondrinas [Fraccionamiento]", "value": "C70219"},
             {"label": "Las Juntas", "value": "C70220"},
             {"label": "Las Pintas de Arriba", "value": "C70221"},
             {"label": "Las Pintitas", "value": "C70222"},
             {"label": "Las Primaveras [Invernadero]", "value": "C70223"},
             {"label": "Lazaro Cardenas, Jalisco", "value": "C70224"},
             {"label": "Lo Arado", "value": "C70225"},
             {"label": "Lomas de San Agustin", "value": "C70226"},
             {"label": "Lomas de Tejeda", "value": "C70227"},
             {"label": "Lomas del Sur", "value": "C70228"},
             {"label": "Los Azulitos", "value": "C70229"},
             {"label": "Los Cedros", "value": "C70230"},
             {"label": "Los Gavilanes", "value": "C70231"},
             {"label": "Los Guerrero", "value": "C70232"},
             {"label": "Los Mazos", "value": "C70233"},
             {"label": "Los Olivos", "value": "C70234"},
             {"label": "Los Pocitos", "value": "C70235"},
             {"label": "Los Ruisenores", "value": "C70236"},
             {"label": "Los Sauces", "value": "C70237"},
             {"label": "Los Vergara", "value": "C70238"},
             {"label": "Magdalena", "value": "C70239"},
             {"label": "Manalisco", "value": "C70240"},
             {"label": "Margaritas", "value": "C70241"},
             {"label": "Mascota Municipality", "value": "C70242"},
             {"label": "Matancillas (San Isidro Matancillas)",
              "value": "C70243"},
             {"label": "Matanzas", "value": "C70244"},
             {"label": "Matatlan", "value": "C70245"},
             {"label": "Mazamitlongo", "value": "C70246"},
             {"label": "Mechoacanejo", "value": "C70247"},
             {"label": "Mexticacan", "value": "C70248"},
             {"label": "Mezcala", "value": "C70249"},
             {"label": "Mezquitic de la Magdalena", "value": "C70250"},
             {"label": "Mezquitic Municipality", "value": "C70251"},
             {"label": "Milpillas", "value": "C70252"},
             {"label": "Mision San Luis Gonzaga Chiriyaqui", "value": "C70253"},
             {"label": "Mismaloya", "value": "C70254"},
             {"label": "Mismaloya (Fraccionamiento Pedregal de Santa Martha)",
              "value": "C70255"},
             {"label": "Mixtlan", "value": "C70256"},
             {"label": "Moras", "value": "C70257"},
             {"label": "Navajas", "value": "C70258"},
             {"label": "Nextipac", "value": "C70259"},
             {"label": "Nicolas R Casillas", "value": "C70260"},
             {"label": "Nuevo Mexico, Jalisco", "value": "C70261"},
             {"label": "Nuevo Refugio de Afuera (Nuevo Refugio)",
              "value": "C70262"},
             {"label": "Oconahua, Jalisco", "value": "C70263"},
             {"label": "Ocotlan, Jalisco", "value": "C70264"},
             {"label": "Ojo de Agua de Moran, Jalisco", "value": "C70265"},
             {"label": "Ojuelos de Jalisco", "value": "C70266"},
             {"label": "Otatlan", "value": "C70267"},
             {"label": "Palomar", "value": "C70268"},
             {"label": "Palos Altos", "value": "C70269"},
             {"label": "Paseo de las Canadas", "value": "C70270"},
             {"label": "Paseo del Prado", "value": "C70271"},
             {"label": "Paseo Puente Viejo", "value": "C70272"},
             {"label": "Paseos del Valle [Fraccionamiento]", "value": "C70273"},
             {"label": "Paso de Cuarenta", "value": "C70274"},
             {"label": "Pegueros", "value": "C70275"},
             {"label": "Piedra Pesada", "value": "C70276"},
             {"label": "Pihuamo", "value": "C70277"},
             {"label": "Pochotita", "value": "C70278"},
             {"label": "Poncitlan Municipality", "value": "C70279"},
             {"label": "Portes Gil", "value": "C70280"},
             {"label": "Potrerillos", "value": "C70281"},
             {"label": "Primero de Mayo (Chupaderos)", "value": "C70282"},
             {"label": "Puente de Camotlan", "value": "C70283"},
             {"label": "Puente Grande", "value": "C70284"},
             {"label": "Puerta del Llano", "value": "C70285"},
             {"label": "Puerto Vallarta", "value": "C70286"},
             {"label": "Punta de la Vega", "value": "C70287"},
             {"label": "Purificacion", "value": "C70288"},
             {"label": "Quitupan", "value": "C70289"},
             {"label": "Rancho Alegre [Fraccionamiento]", "value": "C70290"},
             {"label": "Real del Valle (El Paraiso) [Fraccionamiento]",
              "value": "C70291"},
             {"label": "Rinconada la Loma [Fraccionamiento]",
              "value": "C70292"},
             {"label": "San Agustin", "value": "C70293"},
             {"label": "San Andres Cohamiata", "value": "C70294"},
             {"label": "San Andres Ixtlan", "value": "C70295"},
             {"label": "San Antonio", "value": "C70296"},
             {"label": "San Antonio de los Barrera", "value": "C70297"},
             {"label": "San Antonio de los Vazquez", "value": "C70298"},
             {"label": "San Antonio de Rivas", "value": "C70299"},
             {"label": "San Clemente", "value": "C70300"},
             {"label": "San Cristobal", "value": "C70301"},
             {"label": "San Cristobal de la Barranca Municipality",
              "value": "C70302"},
             {"label": "San Cristobal Zapotitlan", "value": "C70303"},
             {"label": "San Diego de Alejandria", "value": "C70304"},
             {"label": "San Esteban (San Miguel Tateposco)", "value": "C70305"},
             {"label": "San Felipe de Hijar", "value": "C70306"},
             {"label": "San Francisco de Asis", "value": "C70307"},
             {"label": "San Francisco de Rivas", "value": "C70308"},
             {"label": "San Gabriel,  Jalisco", "value": "C70309"},
             {"label": "San Gaspar", "value": "C70310"},
             {"label": "San Gaspar de los Reyes", "value": "C70311"},
             {"label": "San Ignacio Municipality, Jalisco", "value": "C70312"},
             {"label": "San Isidro", "value": "C70313"},
             {"label": "San Isidro Mazatepec", "value": "C70314"},
             {"label": "San Jacintito", "value": "C70315"},
             {"label": "San Jacinto, Jalisco", "value": "C70316"},
             {"label": "San Joaquin Zorrillos", "value": "C70317"},
             {"label": "San Jorge", "value": "C70318"},
             {"label": "San Jose Casas Caidas", "value": "C70319"},
             {"label": "San Jose de Gracia, Jalisco", "value": "C70320"},
             {"label": "San Jose de la Paz", "value": "C70321"},
             {"label": "San Jose de las Flores", "value": "C70322"},
             {"label": "San Jose de los Reynoso", "value": "C70323"},
             {"label": "San Jose del Caliche (El Caliche)", "value": "C70324"},
             {"label": "San Jose del Castillo", "value": "C70325"},
             {"label": "San Jose del Valle", "value": "C70326"},
             {"label": "San Juan Cosala", "value": "C70327"},
             {"label": "San Juan de los Arcos", "value": "C70328"},
             {"label": "San Juan de los Lagos", "value": "C70329"},
             {"label": "San Juan Tecomatlan", "value": "C70330"},
             {"label": "San Juanito de Escobedo", "value": "C70331"},
             {"label": "San Julian", "value": "C70332"},
             {"label": "San Lucas Evangelista", "value": "C70333"},
             {"label": "San Luciano", "value": "C70334"},
             {"label": "San Luis de Agua Caliente", "value": "C70335"},
             {"label": "San Luis Soyatlan", "value": "C70336"},
             {"label": "San Marcos", "value": "C70337"},
             {"label": "San Martin de Bolanos", "value": "C70338"},
             {"label": "San Martin de Hidalgo", "value": "C70339"},
             {"label": "San Miguel Cuyutlan", "value": "C70340"},
             {"label": "San Miguel de la Paz", "value": "C70341"},
             {"label": "San Miguel de la Sierra, Ayutla", "value": "C70342"},
             {"label": "San Miguel el Alto", "value": "C70343"},
             {"label": "San Miguel Zapotitlan", "value": "C70344"},
             {"label": "San Nicolas", "value": "C70345"},
             {"label": "San Nicolas de las Flores", "value": "C70346"},
             {"label": "San Patricio, Jalisco", "value": "C70347"},
             {"label": "San Pedro", "value": "C70348"},
             {"label": "San Pedro Itzican", "value": "C70349"},
             {"label": "San Sebastian", "value": "C70350"},
             {"label": "San Sebastian del Oeste", "value": "C70351"},
             {"label": "San Vicente", "value": "C70352"},
             {"label": "Santa Anita", "value": "C70353"},
             {"label": "Santa Cruz de Barcenas", "value": "C70354"},
             {"label": "Santa Cruz de la Loma (Tepetates)", "value": "C70355"},
             {"label": "Santa Cruz de la Soledad", "value": "C70356"},
             {"label": "Santa Cruz de las Flores, Tlajomulco de Zuniga",
              "value": "C70357"},
             {"label": "Santa Cruz del Astillero", "value": "C70358"},
             {"label": "Santa Cruz del Valle", "value": "C70359"},
             {"label": "Santa Cruz el Grande", "value": "C70360"},
             {"label": "Santa Maria de Enmedio", "value": "C70361"},
             {"label": "Santa Maria de los angeles", "value": "C70362"},
             {"label": "Santa Maria del Valle", "value": "C70363"},
             {"label": "Santa Maria Transpontina", "value": "C70364"},
             {"label": "Santa Rita", "value": "C70365"},
             {"label": "Santa Rosa", "value": "C70366"},
             {"label": "Santa Rosalia, Jalisco", "value": "C70367"},
             {"label": "Santa Teresa", "value": "C70368"},
             {"label": "Santiaguito", "value": "C70369"},
             {"label": "Santiaguito (Santiaguito de Velazquez)",
              "value": "C70370"},
             {"label": "Sayula", "value": "C70371"},
             {"label": "Soyatlan de Afuera", "value": "C70372"},
             {"label": "Soyatlan del Oro", "value": "C70373"},
             {"label": "Talpa de Allende", "value": "C70374"},
             {"label": "Tamazula de Gordiano", "value": "C70375"},
             {"label": "Tamazulita, Jalisco", "value": "C70376"},
             {"label": "Tapalpa", "value": "C70377"},
             {"label": "Tazumbo", "value": "C70378"},
             {"label": "Tecalitlan Municipality", "value": "C70379"},
             {"label": "Techaluta de Montenegro", "value": "C70380"},
             {"label": "Tecolotlan", "value": "C70381"},
             {"label": "Tecomates", "value": "C70382"},
             {"label": "Tecuattitan", "value": "C70383"},
             {"label": "Telcruz", "value": "C70384"},
             {"label": "Temastian (La Cantera)", "value": "C70385"},
             {"label": "Tenamaxtlan", "value": "C70386"},
             {"label": "Tenayuca", "value": "C70387"},
             {"label": "Teocaltiche", "value": "C70388"},
             {"label": "Teocuitatlan de Corona", "value": "C70389"},
             {"label": "Tepatitlan de Morelos", "value": "C70390"},
             {"label": "Tepec", "value": "C70391"},
             {"label": "Tepusco", "value": "C70392"},
             {"label": "Tequesquitlan", "value": "C70393"},
             {"label": "Tequila", "value": "C70394"},
             {"label": "Tesistan", "value": "C70395"},
             {"label": "Teuchitlan, Jalisco", "value": "C70396"},
             {"label": "Tierras Coloradas", "value": "C70397"},
             {"label": "Tizapan el Alto", "value": "C70398"},
             {"label": "Tlacuitapan", "value": "C70399"},
             {"label": "Tlajomulco de Zuniga", "value": "C70400"},
             {"label": "Tlaquepaque", "value": "C70401"},
             {"label": "Toliman Municipality", "value": "C70402"},
             {"label": "Tomatlan", "value": "C70403"},
             {"label": "Tonala", "value": "C70404"},
             {"label": "Tonaya", "value": "C70405"},
             {"label": "Tonila", "value": "C70406"},
             {"label": "Totatiche Municipality", "value": "C70407"},
             {"label": "Tototlan", "value": "C70408"},
             {"label": "Trapiche de Abra", "value": "C70409"},
             {"label": "Trejos", "value": "C70410"},
             {"label": "Tultitlan", "value": "C70411"},
             {"label": "Tuxcacuesco", "value": "C70412"},
             {"label": "Tuxcueca", "value": "C70413"},
             {"label": "Tuxpan", "value": "C70414"},
             {"label": "Tuxpan de Bolanos", "value": "C70415"},
             {"label": "Union de San Antonio", "value": "C70416"},
             {"label": "Union de Tula", "value": "C70417"},
             {"label": "Usmajac", "value": "C70418"},
             {"label": "Valle de Guadalupe Municipality", "value": "C70419"},
             {"label": "Valle de Juarez", "value": "C70420"},
             {"label": "Valle de los Girasoles [Fraccionamiento]",
              "value": "C70421"},
             {"label": "Valle Dorado Inn", "value": "C70422"},
             {"label": "Vaquerias", "value": "C70423"},
             {"label": "Villa Corona", "value": "C70424"},
             {"label": "Villa de los Ninos", "value": "C70425"},
             {"label": "Villa Emiliano Zapata", "value": "C70426"},
             {"label": "Villa Guerrero Municipality", "value": "C70427"},
             {"label": "Villa Hidalgo", "value": "C70428"},
             {"label": "Villa las Flores", "value": "C70429"},
             {"label": "Villa Purificacion", "value": "C70430"},
             {"label": "Villas de la Hacienda [Fraccionamiento]",
              "value": "C70431"},
             {"label": "Vista Hermosa", "value": "C70432"},
             {"label": "Vistas del Maguey [Fraccionamiento]",
              "value": "C70433"},
             {"label": "Xochitlan", "value": "C70434"},
             {"label": "Yahualica de Gonzalez Gallo", "value": "C70435"},
             {"label": "Zacoalco de Torres", "value": "C70436"},
             {"label": "Zalamea", "value": "C70437"},
             {"label": "Zapopan", "value": "C70438"},
             {"label": "Zapotiltic", "value": "C70439"},
             {"label": "Zapotitan de Hidalgo", "value": "C70440"},
             {"label": "Zapotitlan de Vadillo", "value": "C70441"},
             {"label": "Zapotlan del Rey", "value": "C70442"},
             {"label": "Zapotlan El Grande Municipality", "value": "C70443"},
             {"label": "Zapotlanejo", "value": "C70444"},
             {"label": "Zula", "value": "C70445"}],
 "Jambi": [{"label": "Bejubang Dua", "value": "C49255"},
           {"label": "Jambi City", "value": "C49256"},
           {"label": "Kabupaten Batang Hari", "value": "C49257"},
           {"label": "Kabupaten Bungo", "value": "C49258"},
           {"label": "Kabupaten Kerinci", "value": "C49259"},
           {"label": "Kabupaten Merangin", "value": "C49260"},
           {"label": "Kabupaten Muaro Jambi", "value": "C49261"},
           {"label": "Kabupaten Sarolangun", "value": "C49262"},
           {"label": "Kabupaten Tanjung Jabung Barat", "value": "C49263"},
           {"label": "Kabupaten Tanjung Jabung Timur", "value": "C49264"},
           {"label": "Kabupaten Tebo", "value": "C49265"},
           {"label": "Kota Jambi", "value": "C49266"},
           {"label": "Kota Sungai Penuh", "value": "C49267"},
           {"label": "Kuala Tungkal", "value": "C49268"},
           {"label": "Mendaha", "value": "C49269"},
           {"label": "Simpang", "value": "C49270"},
           {"label": "Sungai Penuh", "value": "C49271"}],
 "Jambyl Region": [{"label": "Aqbaqay", "value": "C64377"},
                   {"label": "Chu", "value": "C64378"},
                   {"label": "Georgiyevka", "value": "C64379"},
                   {"label": "Granitogorsk", "value": "C64380"},
                   {"label": "Karatau", "value": "C64381"},
                   {"label": "Khantau", "value": "C64382"},
                   {"label": "Lugovoy", "value": "C64383"},
                   {"label": "Lugovoye", "value": "C64384"},
                   {"label": "Merke", "value": "C64385"},
                   {"label": "Moyynkum", "value": "C64386"},
                   {"label": "Mynaral", "value": "C64387"},
                   {"label": "Oytal", "value": "C64388"},
                   {"label": "Sarykemer", "value": "C64389"},
                   {"label": "Shyghanaq", "value": "C64390"},
                   {"label": "Taraz", "value": "C64391"},
                   {"label": "Zhangatas", "value": "C64392"}],
 "Jammu and Kashmir": [{"label": "Akhnur", "value": "C45919"},
                       {"label": "Anantnag", "value": "C45920"},
                       {"label": "Awantipur", "value": "C45921"},
                       {"label": "Badgam", "value": "C45922"},
                       {"label": "Bandipore", "value": "C45923"},
                       {"label": "Bandipura", "value": "C45924"},
                       {"label": "Banihal", "value": "C45925"},
                       {"label": "Baramula", "value": "C45926"},
                       {"label": "Batoti", "value": "C45927"},
                       {"label": "Bhadarwah", "value": "C45928"},
                       {"label": "Bijbehara", "value": "C45929"},
                       {"label": "Bishnah", "value": "C45930"},
                       {"label": "Doda", "value": "C45931"},
                       {"label": "Gandarbal", "value": "C45932"},
                       {"label": "Ganderbal", "value": "C45933"},
                       {"label": "Gho Brahmanan de", "value": "C45934"},
                       {"label": "Hajan", "value": "C45935"},
                       {"label": "Hiranagar", "value": "C45936"},
                       {"label": "Jammu", "value": "C45937"},
                       {"label": "Jaurian", "value": "C45938"},
                       {"label": "Kathua", "value": "C45939"},
                       {"label": "Katra", "value": "C45940"},
                       {"label": "Khaur", "value": "C45941"},
                       {"label": "Kishtwar", "value": "C45942"},
                       {"label": "Kud", "value": "C45943"},
                       {"label": "Kulgam", "value": "C45944"},
                       {"label": "Kupwara", "value": "C45945"},
                       {"label": "Ladakh", "value": "C45946"},
                       {"label": "Magam", "value": "C45947"},
                       {"label": "Nawanshahr", "value": "C45948"},
                       {"label": "Noria", "value": "C45949"},
                       {"label": "Padam", "value": "C45950"},
                       {"label": "Pahlgam", "value": "C45951"},
                       {"label": "Parol", "value": "C45952"},
                       {"label": "Pattan", "value": "C45953"},
                       {"label": "Pulwama", "value": "C45954"},
                       {"label": "Punch", "value": "C45955"},
                       {"label": "Qazigund", "value": "C45956"},
                       {"label": "Rajaori", "value": "C45957"},
                       {"label": "Rajauri", "value": "C45958"},
                       {"label": "Ramban", "value": "C45959"},
                       {"label": "Ramgarh", "value": "C45960"},
                       {"label": "Ramnagar", "value": "C45961"},
                       {"label": "Riasi", "value": "C45962"},
                       {"label": "Samba", "value": "C45963"},
                       {"label": "Shupiyan", "value": "C45964"},
                       {"label": "Sopur", "value": "C45965"},
                       {"label": "Soyibug", "value": "C45966"},
                       {"label": "Srinagar", "value": "C45967"},
                       {"label": "Sumbal", "value": "C45968"},
                       {"label": "Thang", "value": "C45969"},
                       {"label": "Thanna Mandi", "value": "C45970"},
                       {"label": "Tral", "value": "C45971"},
                       {"label": "Tsrar Sharif", "value": "C45972"},
                       {"label": "Udhampur", "value": "C45973"},
                       {"label": "Uri", "value": "C45974"}],
 "Jarva County": [{"label": "Jarva-Jaani", "value": "C24549"},
                  {"label": "Koeru", "value": "C24550"},
                  {"label": "Paide", "value": "C24551"},
                  {"label": "Paide linn", "value": "C24552"},
                  {"label": "Sarevere", "value": "C24553"},
                  {"label": "Turi", "value": "C24554"},
                  {"label": "Turi vald", "value": "C24555"}],
 "Jasz-Nagykun-Szolnok County": [{"label": "Abadszalok", "value": "C44270"},
                                 {"label": "Alattyan", "value": "C44271"},
                                 {"label": "Besenyszog", "value": "C44272"},
                                 {"label": "Cibakhaza", "value": "C44273"},
                                 {"label": "Cserkeszolo", "value": "C44274"},
                                 {"label": "Fegyvernek", "value": "C44275"},
                                 {"label": "Janoshida", "value": "C44276"},
                                 {"label": "Jaszalsoszentgyorgy",
                                  "value": "C44277"},
                                 {"label": "Jaszapati", "value": "C44278"},
                                 {"label": "Jaszapati Jaras",
                                  "value": "C44279"},
                                 {"label": "Jaszarokszallas",
                                  "value": "C44280"},
                                 {"label": "Jaszbereny", "value": "C44281"},
                                 {"label": "Jaszberenyi Jaras",
                                  "value": "C44282"},
                                 {"label": "Jaszdozsa", "value": "C44283"},
                                 {"label": "Jaszjakohalma", "value": "C44284"},
                                 {"label": "Jaszkiser", "value": "C44285"},
                                 {"label": "Jaszladany", "value": "C44286"},
                                 {"label": "Jaszszentandras",
                                  "value": "C44287"},
                                 {"label": "Karcag", "value": "C44288"},
                                 {"label": "Karcagi Jaras", "value": "C44289"},
                                 {"label": "Kenderes", "value": "C44290"},
                                 {"label": "Kengyel", "value": "C44291"},
                                 {"label": "Kisujszallas", "value": "C44292"},
                                 {"label": "Kunhegyes", "value": "C44293"},
                                 {"label": "Kunhegyesi Jaras",
                                  "value": "C44294"},
                                 {"label": "Kunmadaras", "value": "C44295"},
                                 {"label": "Kunszentmarton", "value": "C44296"},
                                 {"label": "Kunszentmartoni Jaras",
                                  "value": "C44297"},
                                 {"label": "Mezotur", "value": "C44298"},
                                 {"label": "Mezoturi Jaras", "value": "C44299"},
                                 {"label": "Ocsod", "value": "C44300"},
                                 {"label": "Rakoczifalva", "value": "C44301"},
                                 {"label": "Rakocziujfalu", "value": "C44302"},
                                 {"label": "Szajol", "value": "C44303"},
                                 {"label": "Szeleveny", "value": "C44304"},
                                 {"label": "Szolnok", "value": "C44305"},
                                 {"label": "Szolnoki Jaras", "value": "C44306"},
                                 {"label": "Tiszabo", "value": "C44307"},
                                 {"label": "Tiszabura", "value": "C44308"},
                                 {"label": "Tiszafoldvar", "value": "C44309"},
                                 {"label": "Tiszafured", "value": "C44310"},
                                 {"label": "Tiszafuredi Jaras",
                                  "value": "C44311"},
                                 {"label": "Tiszapuspoki", "value": "C44312"},
                                 {"label": "Tiszaroff", "value": "C44313"},
                                 {"label": "Tiszasuly", "value": "C44314"},
                                 {"label": "Tiszaszentimre", "value": "C44315"},
                                 {"label": "Tiszaszolos", "value": "C44316"},
                                 {"label": "Torokszentmiklos",
                                  "value": "C44317"},
                                 {"label": "Torokszentmiklosi Jaras",
                                  "value": "C44318"},
                                 {"label": "Toszeg", "value": "C44319"},
                                 {"label": "Turkeve", "value": "C44320"},
                                 {"label": "Ujszasz", "value": "C44321"},
                                 {"label": "Zagyvarekas", "value": "C44322"}],
 "Jaunjelgava Municipality": [{"label": "Jaunjelgava", "value": "C64875"}],
 "Jaunpils Municipality": [{"label": "Jaunpils", "value": "C64876"}],
 "Jawa Barat": [{"label": "Arjawinangun", "value": "C49272"},
                {"label": "Astanajapura", "value": "C49273"},
                {"label": "Bandung", "value": "C49274"},
                {"label": "Banjar", "value": "C49275"},
                {"label": "Banjaran", "value": "C49276"},
                {"label": "Bekasi", "value": "C49277"},
                {"label": "Bogor", "value": "C49278"},
                {"label": "Caringin", "value": "C49279"},
                {"label": "Ciamis", "value": "C49280"},
                {"label": "Ciampea", "value": "C49281"},
                {"label": "Cibinong", "value": "C49282"},
                {"label": "Cicurug", "value": "C49283"},
                {"label": "Cikampek", "value": "C49284"},
                {"label": "Cikarang", "value": "C49285"},
                {"label": "Cikupa", "value": "C49286"},
                {"label": "Cileungsir", "value": "C49287"},
                {"label": "Cileunyi", "value": "C49288"},
                {"label": "Cimahi", "value": "C49289"},
                {"label": "Ciputat", "value": "C49290"},
                {"label": "Ciranjang-hilir", "value": "C49291"},
                {"label": "Cirebon", "value": "C49292"},
                {"label": "Citeureup", "value": "C49293"},
                {"label": "Depok", "value": "C49294"},
                {"label": "Indramayu", "value": "C49295"},
                {"label": "Jatibarang", "value": "C49296"},
                {"label": "Jatiwangi", "value": "C49297"},
                {"label": "Kabupaten Bandung", "value": "C49298"},
                {"label": "Kabupaten Bandung Barat", "value": "C49299"},
                {"label": "Kabupaten Bekasi", "value": "C49300"},
                {"label": "Kabupaten Bogor", "value": "C49301"},
                {"label": "Kabupaten Ciamis", "value": "C49302"},
                {"label": "Kabupaten Cianjur", "value": "C49303"},
                {"label": "Kabupaten Cirebon", "value": "C49304"},
                {"label": "Kabupaten Garut", "value": "C49305"},
                {"label": "Kabupaten Indramayu", "value": "C49306"},
                {"label": "Kabupaten Karawang", "value": "C49307"},
                {"label": "Kabupaten Kuningan", "value": "C49308"},
                {"label": "Kabupaten Majalengka", "value": "C49309"},
                {"label": "Kabupaten Pangandaran", "value": "C49310"},
                {"label": "Kabupaten Purwakarta", "value": "C49311"},
                {"label": "Kabupaten Subang", "value": "C49312"},
                {"label": "Kabupaten Sukabumi", "value": "C49313"},
                {"label": "Kabupaten Sumedang", "value": "C49314"},
                {"label": "Kabupaten Tasikmalaya", "value": "C49315"},
                {"label": "Karangampel", "value": "C49316"},
                {"label": "Karangsembung", "value": "C49317"},
                {"label": "Kawalu", "value": "C49318"},
                {"label": "Klangenan", "value": "C49319"},
                {"label": "Kota Bandung", "value": "C49320"},
                {"label": "Kota Banjar", "value": "C49321"},
                {"label": "Kota Bekasi", "value": "C49322"},
                {"label": "Kota Bogor", "value": "C49323"},
                {"label": "Kota Cimahi", "value": "C49324"},
                {"label": "Kota Cirebon", "value": "C49325"},
                {"label": "Kota Depok", "value": "C49326"},
                {"label": "Kota Sukabumi", "value": "C49327"},
                {"label": "Kota Tasikmalaya", "value": "C49328"},
                {"label": "Kresek", "value": "C49329"},
                {"label": "Kuningan", "value": "C49330"},
                {"label": "Lembang", "value": "C49331"},
                {"label": "Majalengka", "value": "C49332"},
                {"label": "Margahayukencana", "value": "C49333"},
                {"label": "Ngawi", "value": "C49334"},
                {"label": "Padalarang", "value": "C49335"},
                {"label": "Palimanan", "value": "C49336"},
                {"label": "Pamanukan", "value": "C49337"},
                {"label": "Pameungpeuk", "value": "C49338"},
                {"label": "Pamulang", "value": "C49339"},
                {"label": "Parung", "value": "C49340"},
                {"label": "Pasarkemis", "value": "C49341"},
                {"label": "Paseh", "value": "C49342"},
                {"label": "Pelabuhanratu", "value": "C49343"},
                {"label": "Plumbon", "value": "C49344"},
                {"label": "Purwakarta", "value": "C49345"},
                {"label": "Rajapolah", "value": "C49346"},
                {"label": "Rengasdengklok", "value": "C49347"},
                {"label": "Sawangan", "value": "C49348"},
                {"label": "Sepatan", "value": "C49349"},
                {"label": "Serpong", "value": "C49350"},
                {"label": "Singaparna", "value": "C49351"},
                {"label": "Soreang", "value": "C49352"},
                {"label": "Sukabumi", "value": "C49353"},
                {"label": "Sumber", "value": "C49354"},
                {"label": "Sumedang", "value": "C49355"},
                {"label": "Sumedang Utara", "value": "C49356"},
                {"label": "Tasikmalaya", "value": "C49357"},
                {"label": "Teluknaga", "value": "C49358"},
                {"label": "Wanaraja", "value": "C49359"},
                {"label": "Weru", "value": "C49360"}],
 "Jawa Tengah": [{"label": "Adiwerna", "value": "C49361"},
                 {"label": "Ambarawa", "value": "C49362"},
                 {"label": "Baekrajan", "value": "C49363"},
                 {"label": "Baki", "value": "C49364"},
                 {"label": "Balapulang", "value": "C49365"},
                 {"label": "Banyumas", "value": "C49366"},
                 {"label": "Batang", "value": "C49367"},
                 {"label": "Baturaden", "value": "C49368"},
                 {"label": "Blora", "value": "C49369"},
                 {"label": "Boyolali", "value": "C49370"},
                 {"label": "Buaran", "value": "C49371"},
                 {"label": "Bulakamba", "value": "C49372"},
                 {"label": "Candi Prambanan", "value": "C49373"},
                 {"label": "Ceper", "value": "C49374"},
                 {"label": "Cepu", "value": "C49375"},
                 {"label": "Colomadu", "value": "C49376"},
                 {"label": "Comal", "value": "C49377"},
                 {"label": "Delanggu", "value": "C49378"},
                 {"label": "Demak", "value": "C49379"},
                 {"label": "Dukuhturi", "value": "C49380"},
                 {"label": "Gatak", "value": "C49381"},
                 {"label": "Gebog", "value": "C49382"},
                 {"label": "Gombong", "value": "C49383"},
                 {"label": "Grogol", "value": "C49384"},
                 {"label": "Gunung Kendil", "value": "C49385"},
                 {"label": "Jaten", "value": "C49386"},
                 {"label": "Jatiroto", "value": "C49387"},
                 {"label": "Jekulo", "value": "C49388"},
                 {"label": "Jogonalan", "value": "C49389"},
                 {"label": "Juwana", "value": "C49390"},
                 {"label": "Kabupaten Banjarnegara", "value": "C49391"},
                 {"label": "Kabupaten Banyumas", "value": "C49392"},
                 {"label": "Kabupaten Batang", "value": "C49393"},
                 {"label": "Kabupaten Blora", "value": "C49394"},
                 {"label": "Kabupaten Boyolali", "value": "C49395"},
                 {"label": "Kabupaten Brebes", "value": "C49396"},
                 {"label": "Kabupaten Cilacap", "value": "C49397"},
                 {"label": "Kabupaten Demak", "value": "C49398"},
                 {"label": "Kabupaten Grobogan", "value": "C49399"},
                 {"label": "Kabupaten Jepara", "value": "C49400"},
                 {"label": "Kabupaten Karanganyar", "value": "C49401"},
                 {"label": "Kabupaten Kebumen", "value": "C49402"},
                 {"label": "Kabupaten Kendal", "value": "C49403"},
                 {"label": "Kabupaten Klaten", "value": "C49404"},
                 {"label": "Kabupaten Kudus", "value": "C49405"},
                 {"label": "Kabupaten Magelang", "value": "C49406"},
                 {"label": "Kabupaten Pati", "value": "C49407"},
                 {"label": "Kabupaten Pekalongan", "value": "C49408"},
                 {"label": "Kabupaten Pemalang", "value": "C49409"},
                 {"label": "Kabupaten Purbalingga", "value": "C49410"},
                 {"label": "Kabupaten Purworejo", "value": "C49411"},
                 {"label": "Kabupaten Rembang", "value": "C49412"},
                 {"label": "Kabupaten Semarang", "value": "C49413"},
                 {"label": "Kabupaten Sragen", "value": "C49414"},
                 {"label": "Kabupaten Sukoharjo", "value": "C49415"},
                 {"label": "Kabupaten Tegal", "value": "C49416"},
                 {"label": "Kabupaten Temanggung", "value": "C49417"},
                 {"label": "Kabupaten Wonogiri", "value": "C49418"},
                 {"label": "Kabupaten Wonosobo", "value": "C49419"},
                 {"label": "Karanganom", "value": "C49420"},
                 {"label": "Kartasura", "value": "C49421"},
                 {"label": "Kebonarun", "value": "C49422"},
                 {"label": "Kedungwuni", "value": "C49423"},
                 {"label": "Ketanggungan", "value": "C49424"},
                 {"label": "Klaten", "value": "C49425"},
                 {"label": "Kota Magelang", "value": "C49426"},
                 {"label": "Kota Pekalongan", "value": "C49427"},
                 {"label": "Kota Salatiga", "value": "C49428"},
                 {"label": "Kota Semarang", "value": "C49429"},
                 {"label": "Kota Surakarta", "value": "C49430"},
                 {"label": "Kota Tegal", "value": "C49431"},
                 {"label": "Kroya", "value": "C49432"},
                 {"label": "Kudus", "value": "C49433"},
                 {"label": "Kutoarjo", "value": "C49434"},
                 {"label": "Lasem", "value": "C49435"},
                 {"label": "Lebaksiu", "value": "C49436"},
                 {"label": "Magelang", "value": "C49437"},
                 {"label": "Majenang", "value": "C49438"},
                 {"label": "Margasari", "value": "C49439"},
                 {"label": "Mertoyudan", "value": "C49440"},
                 {"label": "Mlonggo", "value": "C49441"},
                 {"label": "Mranggen", "value": "C49442"},
                 {"label": "Muntilan", "value": "C49443"},
                 {"label": "Ngemplak", "value": "C49444"},
                 {"label": "Pati", "value": "C49445"},
                 {"label": "Pecangaan", "value": "C49446"},
                 {"label": "Pekalongan", "value": "C49447"},
                 {"label": "Pemalang", "value": "C49448"},
                 {"label": "Purbalingga", "value": "C49449"},
                 {"label": "Purwodadi", "value": "C49450"},
                 {"label": "Purwokerto", "value": "C49451"},
                 {"label": "Randudongkal", "value": "C49452"},
                 {"label": "Rembangan", "value": "C49453"},
                 {"label": "Salatiga", "value": "C49454"},
                 {"label": "Selogiri", "value": "C49455"},
                 {"label": "Semarang", "value": "C49456"},
                 {"label": "Sidareja", "value": "C49457"},
                 {"label": "Slawi", "value": "C49458"},
                 {"label": "Sokaraja", "value": "C49459"},
                 {"label": "Sragen", "value": "C49460"},
                 {"label": "Surakarta", "value": "C49461"},
                 {"label": "Tarub", "value": "C49462"},
                 {"label": "Tayu", "value": "C49463"},
                 {"label": "Tegal", "value": "C49464"},
                 {"label": "Trucuk", "value": "C49465"},
                 {"label": "Ungaran", "value": "C49466"},
                 {"label": "Wangon", "value": "C49467"},
                 {"label": "Wedi", "value": "C49468"},
                 {"label": "Welahan", "value": "C49469"},
                 {"label": "Weleri", "value": "C49470"},
                 {"label": "Wiradesa", "value": "C49471"},
                 {"label": "Wonopringgo", "value": "C49472"},
                 {"label": "Wonosobo", "value": "C49473"}],
 "Jawa Timur": [{"label": "Babat", "value": "C49474"},
                {"label": "Balung", "value": "C49475"},
                {"label": "Bangil", "value": "C49476"},
                {"label": "Bangkalan", "value": "C49477"},
                {"label": "Banyuwangi", "value": "C49478"},
                {"label": "Batu", "value": "C49479"},
                {"label": "Besuki", "value": "C49480"},
                {"label": "Blitar", "value": "C49481"},
                {"label": "Bojonegoro", "value": "C49482"},
                {"label": "Bondowoso", "value": "C49483"},
                {"label": "Boyolangu", "value": "C49484"},
                {"label": "Buduran", "value": "C49485"},
                {"label": "Dampit", "value": "C49486"},
                {"label": "Diwek", "value": "C49487"},
                {"label": "Driyorejo", "value": "C49488"},
                {"label": "Gambiran Satu", "value": "C49489"},
                {"label": "Gampengrejo", "value": "C49490"},
                {"label": "Gedangan", "value": "C49491"},
                {"label": "Genteng", "value": "C49492"},
                {"label": "Gongdanglegi Kulon", "value": "C49493"},
                {"label": "Gresik", "value": "C49494"},
                {"label": "Gresik Regency", "value": "C49495"},
                {"label": "Jember", "value": "C49496"},
                {"label": "Jombang", "value": "C49497"},
                {"label": "Kabupaten Bangkalan", "value": "C49498"},
                {"label": "Kabupaten Banyuwangi", "value": "C49499"},
                {"label": "Kabupaten Blitar", "value": "C49500"},
                {"label": "Kabupaten Bojonegoro", "value": "C49501"},
                {"label": "Kabupaten Bondowoso", "value": "C49502"},
                {"label": "Kabupaten Jember", "value": "C49503"},
                {"label": "Kabupaten Jombang", "value": "C49504"},
                {"label": "Kabupaten Kediri", "value": "C49505"},
                {"label": "Kabupaten Lamongan", "value": "C49506"},
                {"label": "Kabupaten Lumajang", "value": "C49507"},
                {"label": "Kabupaten Madiun", "value": "C49508"},
                {"label": "Kabupaten Magetan", "value": "C49509"},
                {"label": "Kabupaten Malang", "value": "C49510"},
                {"label": "Kabupaten Mojokerto", "value": "C49511"},
                {"label": "Kabupaten Nganjuk", "value": "C49512"},
                {"label": "Kabupaten Ngawi", "value": "C49513"},
                {"label": "Kabupaten Pacitan", "value": "C49514"},
                {"label": "Kabupaten Pamekasan", "value": "C49515"},
                {"label": "Kabupaten Pasuruan", "value": "C49516"},
                {"label": "Kabupaten Ponorogo", "value": "C49517"},
                {"label": "Kabupaten Probolinggo", "value": "C49518"},
                {"label": "Kabupaten Sampang", "value": "C49519"},
                {"label": "Kabupaten Sidoarjo", "value": "C49520"},
                {"label": "Kabupaten Situbondo", "value": "C49521"},
                {"label": "Kabupaten Sumenep", "value": "C49522"},
                {"label": "Kabupaten Trenggalek", "value": "C49523"},
                {"label": "Kabupaten Tuban", "value": "C49524"},
                {"label": "Kabupaten Tulungagung", "value": "C49525"},
                {"label": "Kalianget", "value": "C49526"},
                {"label": "Kamal", "value": "C49527"},
                {"label": "Kebomas", "value": "C49528"},
                {"label": "Kediri", "value": "C49529"},
                {"label": "Kedungwaru", "value": "C49530"},
                {"label": "Kencong", "value": "C49531"},
                {"label": "Kepanjen", "value": "C49532"},
                {"label": "Kertosono", "value": "C49533"},
                {"label": "Kota Batu", "value": "C49534"},
                {"label": "Kota Blitar", "value": "C49535"},
                {"label": "Kota Kediri", "value": "C49536"},
                {"label": "Kota Madiun", "value": "C49537"},
                {"label": "Kota Malang", "value": "C49538"},
                {"label": "Kota Mojokerto", "value": "C49539"},
                {"label": "Kota Pasuruan", "value": "C49540"},
                {"label": "Kota Probolinggo", "value": "C49541"},
                {"label": "Kota Surabaya", "value": "C49542"},
                {"label": "Kraksaan", "value": "C49543"},
                {"label": "Krian", "value": "C49544"},
                {"label": "Lamongan", "value": "C49545"},
                {"label": "Lawang", "value": "C49546"},
                {"label": "Lumajang", "value": "C49547"},
                {"label": "Madiun", "value": "C49548"},
                {"label": "Malang", "value": "C49549"},
                {"label": "Mojoagung", "value": "C49550"},
                {"label": "Mojokerto", "value": "C49551"},
                {"label": "Muncar", "value": "C49552"},
                {"label": "Nganjuk", "value": "C49553"},
                {"label": "Ngoro", "value": "C49554"},
                {"label": "Ngunut", "value": "C49555"},
                {"label": "Paciran", "value": "C49556"},
                {"label": "Pakisaji", "value": "C49557"},
                {"label": "Pamekasan", "value": "C49558"},
                {"label": "Panarukan", "value": "C49559"},
                {"label": "Pandaan", "value": "C49560"},
                {"label": "Panji", "value": "C49561"},
                {"label": "Pare", "value": "C49562"},
                {"label": "Pasuruan", "value": "C49563"},
                {"label": "Ponorogo", "value": "C49564"},
                {"label": "Prigen", "value": "C49565"},
                {"label": "Probolinggo", "value": "C49566"},
                {"label": "Sampang", "value": "C49567"},
                {"label": "Sidoarjo", "value": "C49568"},
                {"label": "Singojuruh", "value": "C49569"},
                {"label": "Singosari", "value": "C49570"},
                {"label": "Situbondo", "value": "C49571"},
                {"label": "Soko", "value": "C49572"},
                {"label": "Srono", "value": "C49573"},
                {"label": "Sumberpucung", "value": "C49574"},
                {"label": "Sumenep", "value": "C49575"},
                {"label": "Surabaya", "value": "C49576"},
                {"label": "Tanggul", "value": "C49577"},
                {"label": "Tanggulangin", "value": "C49578"},
                {"label": "Trenggalek", "value": "C49579"},
                {"label": "Tuban", "value": "C49580"},
                {"label": "Tulangan Utara", "value": "C49581"},
                {"label": "Tulungagung", "value": "C49582"},
                {"label": "Wongsorejo", "value": "C49583"}],
 "Jegunovce Municipality": [{"label": "Jegunovce", "value": "C65423"},
                            {"label": "Vratnica", "value": "C65424"}],
 "Jeju": [{"label": "Gaigeturi", "value": "C108348"},
          {"label": "Jeju City", "value": "C108349"},
          {"label": "Jeju-si", "value": "C108350"},
          {"label": "Seogwipo", "value": "C108351"}],
 "Jekabpils Municipality": [{"label": "Jekabpils", "value": "C64877"},
                            {"label": "Krustpils", "value": "C64878"}],
 "Jelgava": [{"label": "Jelgava", "value": "C64879"}],
 "Jelgava Municipality": [{"label": "Tireli", "value": "C64880"}],
 "Jendouba Governorate": [{"label": "Fernana", "value": "C119709"},
                          {"label": "Jendouba", "value": "C119710"},
                          {"label": "Oued Meliz", "value": "C119711"},
                          {"label": "Tabarka", "value": "C119712"}],
 "Jerash Governorate": [{"label": "Al Kittah", "value": "C64241"},
                        {"label": "Balila", "value": "C64242"},
                        {"label": "Burma", "value": "C64243"},
                        {"label": "Jarash", "value": "C64244"},
                        {"label": "Qafqafa", "value": "C64245"},
                        {"label": "Raymun", "value": "C64246"},
                        {"label": "Sakib", "value": "C64247"},
                        {"label": "Suf", "value": "C64248"}],
 "Jerusalem District": [{"label": "Abu Ghaush", "value": "C51641"},
                        {"label": "Bet Shemesh", "value": "C51642"},
                        {"label": "Har Adar", "value": "C51643"},
                        {"label": "Jerusalem", "value": "C51644"},
                        {"label": "Mevasseret Ziyyon", "value": "C51645"},
                        {"label": "Modiin Ilit", "value": "C51646"},
                        {"label": "West Jerusalem", "value": "C51647"},
                        {"label": "Zur Hadassa", "value": "C51648"}],
 "Jesenice Municipality": [{"label": "Hrusica", "value": "C107648"},
                           {"label": "Jesenice", "value": "C107649"},
                           {"label": "Koroska Bela", "value": "C107650"},
                           {"label": "Slovenski Javornik", "value": "C107651"}],
 "Jewish Autonomous Oblast": [{"label": "Amurzet", "value": "C100950"},
                              {"label": "Babstovo", "value": "C100951"},
                              {"label": "Bira", "value": "C100952"},
                              {"label": "Birakan", "value": "C100953"},
                              {"label": "Birobidzhan", "value": "C100954"},
                              {"label": "Birobidzhanskiy Rayon",
                               "value": "C100955"},
                              {"label": "Izvestkovyy", "value": "C100956"},
                              {"label": "Khingansk", "value": "C100957"},
                              {"label": "Kuldur", "value": "C100958"},
                              {"label": "Leninskiy Rayon", "value": "C100959"},
                              {"label": "Leninskoye", "value": "C100960"},
                              {"label": "Londoko", "value": "C100961"},
                              {"label": "Obluchenskiy Rayon",
                               "value": "C100962"},
                              {"label": "Oktyabrskiy Rayon",
                               "value": "C100963"},
                              {"label": "Priamurskiy", "value": "C100964"},
                              {"label": "Smidovich", "value": "C100965"},
                              {"label": "Smidovichskiy Rayon",
                               "value": "C100966"},
                              {"label": "Volochayevka Vtoraya",
                               "value": "C100967"}],
 "Jezersko Municipality": [{"label": "Zgornje Jezersko", "value": "C107652"}],
 "Jharkhand": [{"label": "Bagra", "value": "C45975"},
               {"label": "Barka Kana", "value": "C45976"},
               {"label": "Barki Saria", "value": "C45977"},
               {"label": "Barwadih", "value": "C45978"},
               {"label": "Bhojudih", "value": "C45979"},
               {"label": "Bokaro", "value": "C45980"},
               {"label": "Bundu", "value": "C45981"},
               {"label": "Chaibasa", "value": "C45982"},
               {"label": "Chakradharpur", "value": "C45983"},
               {"label": "Chakulia", "value": "C45984"},
               {"label": "Chandil", "value": "C45985"},
               {"label": "Chas", "value": "C45986"},
               {"label": "Chatra", "value": "C45987"},
               {"label": "Chiria", "value": "C45988"},
               {"label": "Daltonganj", "value": "C45989"},
               {"label": "Deogarh", "value": "C45990"},
               {"label": "Dhanbad", "value": "C45991"},
               {"label": "Dhanwar", "value": "C45992"},
               {"label": "Dugda", "value": "C45993"},
               {"label": "Dumka", "value": "C45994"},
               {"label": "Garhwa", "value": "C45995"},
               {"label": "Ghatsila", "value": "C45996"},
               {"label": "Giridih", "value": "C45997"},
               {"label": "Gobindpur", "value": "C45998"},
               {"label": "Godda", "value": "C45999"},
               {"label": "Gomoh", "value": "C46000"},
               {"label": "Gopinathpur", "value": "C46001"},
               {"label": "Gua", "value": "C46002"},
               {"label": "Gumia", "value": "C46003"},
               {"label": "Gumla", "value": "C46004"},
               {"label": "Hazaribag", "value": "C46005"},
               {"label": "Hazaribagh", "value": "C46006"},
               {"label": "Hesla", "value": "C46007"},
               {"label": "Husainabad", "value": "C46008"},
               {"label": "Jagannathpur", "value": "C46009"},
               {"label": "Jamadoba", "value": "C46010"},
               {"label": "Jamshedpur", "value": "C46011"},
               {"label": "Jamtara", "value": "C46012"},
               {"label": "Jasidih", "value": "C46013"},
               {"label": "Jharia", "value": "C46014"},
               {"label": "Jugsalai", "value": "C46015"},
               {"label": "Jumri Tilaiya", "value": "C46016"},
               {"label": "Kalikapur", "value": "C46017"},
               {"label": "Kandra", "value": "C46018"},
               {"label": "Kanke", "value": "C46019"},
               {"label": "Katras", "value": "C46020"},
               {"label": "Kenduadih", "value": "C46021"},
               {"label": "Kharsawan", "value": "C46022"},
               {"label": "Khunti", "value": "C46023"},
               {"label": "Kodarma", "value": "C46024"},
               {"label": "Kuju", "value": "C46025"},
               {"label": "Latehar", "value": "C46026"},
               {"label": "Lohardaga", "value": "C46027"},
               {"label": "Madhupur", "value": "C46028"},
               {"label": "Malkera", "value": "C46029"},
               {"label": "Manoharpur", "value": "C46030"},
               {"label": "Mugma", "value": "C46031"},
               {"label": "Mushabani", "value": "C46032"},
               {"label": "Neturhat", "value": "C46033"},
               {"label": "Nirsa", "value": "C46034"},
               {"label": "Noamundi", "value": "C46035"},
               {"label": "Pakur", "value": "C46036"},
               {"label": "Palamu", "value": "C46037"},
               {"label": "Pashchim Singhbhum", "value": "C46038"},
               {"label": "patamda", "value": "C46039"},
               {"label": "Pathardih", "value": "C46040"},
               {"label": "Purba Singhbhum", "value": "C46041"},
               {"label": "Ramgarh", "value": "C46042"},
               {"label": "Ranchi", "value": "C46043"},
               {"label": "Ray", "value": "C46044"},
               {"label": "Sahibganj", "value": "C46045"},
               {"label": "Saraikela", "value": "C46046"},
               {"label": "Sarubera", "value": "C46047"},
               {"label": "Sijua", "value": "C46048"},
               {"label": "Simdega", "value": "C46049"},
               {"label": "Sini", "value": "C46050"},
               {"label": "Topchanchi", "value": "C46051"}],
 "Jiangsu": [{"label": "ChangZhou", "value": "C18971"},
             {"label": "HuaiAn", "value": "C18972"},
             {"label": "LianYunGang", "value": "C18973"},
             {"label": "NanJing", "value": "C18974"},
             {"label": "NanTong", "value": "C18975"},
             {"label": "SuQian", "value": "C18976"},
             {"label": "SuZhou", "value": "C18977"},
             {"label": "TaiZhou", "value": "C18978"},
             {"label": "WuXi", "value": "C18979"},
             {"label": "XuZhou", "value": "C18980"},
             {"label": "YanCheng", "value": "C18981"},
             {"label": "YangZhou", "value": "C18982"},
             {"label": "ZhenJiang", "value": "C18983"}],
 "Jiangxi": [{"label": "Changleng", "value": "C18984"},
             {"label": "Fenyi", "value": "C18985"},
             {"label": "Ganzhou", "value": "C18986"},
             {"label": "Ganzhou Shi", "value": "C18987"},
             {"label": "Guixi", "value": "C18988"},
             {"label": "Jian", "value": "C18989"},
             {"label": "Jianguang", "value": "C18990"},
             {"label": "Jingdezhen", "value": "C18991"},
             {"label": "Jingdezhen Shi", "value": "C18992"},
             {"label": "Jiujiang", "value": "C18993"},
             {"label": "Nanchang", "value": "C18994"},
             {"label": "Pingxiang", "value": "C18995"},
             {"label": "Poyang", "value": "C18996"},
             {"label": "Shangrao", "value": "C18997"},
             {"label": "Xinyu", "value": "C18998"},
             {"label": "Yichun", "value": "C18999"}],
 "Jigawa": [{"label": "Babura", "value": "C78288"},
            {"label": "Birnin Kudu", "value": "C78289"},
            {"label": "Birniwa", "value": "C78290"},
            {"label": "Dutse", "value": "C78291"},
            {"label": "Gagarawa", "value": "C78292"},
            {"label": "Gumel", "value": "C78293"},
            {"label": "Gwaram", "value": "C78294"},
            {"label": "Hadejia", "value": "C78295"},
            {"label": "Kafin Hausa", "value": "C78296"},
            {"label": "Kazaure", "value": "C78297"},
            {"label": "Kiyawa", "value": "C78298"},
            {"label": "Mallammaduri", "value": "C78299"},
            {"label": "Ringim", "value": "C78300"},
            {"label": "Samamiya", "value": "C78301"}],
 "Jihocesky kraj": [{"label": "Bavorov", "value": "C21953"},
                    {"label": "Bechyne", "value": "C21954"},
                    {"label": "Benesov nad Cernou", "value": "C21955"},
                    {"label": "Bernartice", "value": "C21956"},
                    {"label": "Blatna", "value": "C21957"},
                    {"label": "Borek", "value": "C21958"},
                    {"label": "Borova Lada", "value": "C21959"},
                    {"label": "Borovany", "value": "C21960"},
                    {"label": "Brloh", "value": "C21961"},
                    {"label": "Ceske Budejovice", "value": "C21962"},
                    {"label": "Ceske Velenice", "value": "C21963"},
                    {"label": "Cesky Krumlov", "value": "C21964"},
                    {"label": "Cesky Rudolec", "value": "C21965"},
                    {"label": "Chlum u Trebone", "value": "C21966"},
                    {"label": "Chotoviny", "value": "C21967"},
                    {"label": "Chvalsiny", "value": "C21968"},
                    {"label": "Chynov", "value": "C21969"},
                    {"label": "Chysky", "value": "C21970"},
                    {"label": "Cimelice", "value": "C21971"},
                    {"label": "Ckyne", "value": "C21972"},
                    {"label": "Dacice", "value": "C21973"},
                    {"label": "Dolni Bukovsko", "value": "C21974"},
                    {"label": "Dolni Dvoriste", "value": "C21975"},
                    {"label": "Driten", "value": "C21976"},
                    {"label": "Dubne", "value": "C21977"},
                    {"label": "Frymburk", "value": "C21978"},
                    {"label": "Hluboka nad Vltavou", "value": "C21979"},
                    {"label": "Horni Plana", "value": "C21980"},
                    {"label": "Horni Stropnice", "value": "C21981"},
                    {"label": "Hrdejovice", "value": "C21982"},
                    {"label": "Husinec", "value": "C21983"},
                    {"label": "Jarosov nad Nezarkou", "value": "C21984"},
                    {"label": "Jindrichuv Hradec", "value": "C21985"},
                    {"label": "Jistebnice", "value": "C21986"},
                    {"label": "Kajov", "value": "C21987"},
                    {"label": "Kamenny Ujezd", "value": "C21988"},
                    {"label": "Kaplice", "value": "C21989"},
                    {"label": "Kardasova Recice", "value": "C21990"},
                    {"label": "Katovice", "value": "C21991"},
                    {"label": "Kovarov", "value": "C21992"},
                    {"label": "Kremze", "value": "C21993"},
                    {"label": "Kunzak", "value": "C21994"},
                    {"label": "Ledenice", "value": "C21995"},
                    {"label": "Lhenice", "value": "C21996"},
                    {"label": "Lisov", "value": "C21997"},
                    {"label": "Litvinovice", "value": "C21998"},
                    {"label": "Lomnice nad Luznici", "value": "C21999"},
                    {"label": "Loucovice", "value": "C22000"},
                    {"label": "Malonty", "value": "C22001"},
                    {"label": "Malsice", "value": "C22002"},
                    {"label": "Milevsko", "value": "C22003"},
                    {"label": "Mirotice", "value": "C22004"},
                    {"label": "Mirovice", "value": "C22005"},
                    {"label": "Mlada Vozice", "value": "C22006"},
                    {"label": "Netolice", "value": "C22007"},
                    {"label": "Nova Bystrice", "value": "C22008"},
                    {"label": "Nova Vcelnice", "value": "C22009"},
                    {"label": "Nove Hrady", "value": "C22010"},
                    {"label": "Okres Ceske Budejovice", "value": "C22011"},
                    {"label": "Okres Cesky Krumlov", "value": "C22012"},
                    {"label": "Okres Jindrichuv Hradec", "value": "C22013"},
                    {"label": "Okres Pisek", "value": "C22014"},
                    {"label": "Okres Prachatice", "value": "C22015"},
                    {"label": "Okres Strakonice", "value": "C22016"},
                    {"label": "Okres Tabor", "value": "C22017"},
                    {"label": "Oparany", "value": "C22018"},
                    {"label": "Pisek", "value": "C22019"},
                    {"label": "Plana nad Luznici", "value": "C22020"},
                    {"label": "Prachatice", "value": "C22021"},
                    {"label": "Protivin", "value": "C22022"},
                    {"label": "Radomysl", "value": "C22023"},
                    {"label": "Rudolfov", "value": "C22024"},
                    {"label": "Sedlice", "value": "C22025"},
                    {"label": "Sepekov", "value": "C22026"},
                    {"label": "Sevetin", "value": "C22027"},
                    {"label": "Sezimovo Usti", "value": "C22028"},
                    {"label": "Slavonice", "value": "C22029"},
                    {"label": "Sobeslav", "value": "C22030"},
                    {"label": "Srubec", "value": "C22031"},
                    {"label": "Stachy", "value": "C22032"},
                    {"label": "Strakonice", "value": "C22033"},
                    {"label": "Strmilov", "value": "C22034"},
                    {"label": "Strunkovice nad Blanici", "value": "C22035"},
                    {"label": "Studena", "value": "C22036"},
                    {"label": "Suchdol nad Luznici", "value": "C22037"},
                    {"label": "Tabor", "value": "C22038"},
                    {"label": "Trebon", "value": "C22039"},
                    {"label": "Trhove Sviny", "value": "C22040"},
                    {"label": "Tyn nad Vltavou", "value": "C22041"},
                    {"label": "Vacov", "value": "C22042"},
                    {"label": "Vcelna", "value": "C22043"},
                    {"label": "Velesin", "value": "C22044"},
                    {"label": "Veseli nad Luznici", "value": "C22045"},
                    {"label": "Vetrni", "value": "C22046"},
                    {"label": "Vimperk", "value": "C22047"},
                    {"label": "Vlachovo Brezi", "value": "C22048"},
                    {"label": "Vodnany", "value": "C22049"},
                    {"label": "Volary", "value": "C22050"},
                    {"label": "Volyne", "value": "C22051"},
                    {"label": "Vyssi Brod", "value": "C22052"},
                    {"label": "Zdikov", "value": "C22053"},
                    {"label": "Zliv", "value": "C22054"}],
 "Jihomoravsky kraj": [{"label": "Adamov", "value": "C22055"},
                       {"label": "Bilovice nad Svitavou", "value": "C22056"},
                       {"label": "Blansko", "value": "C22057"},
                       {"label": "Blazovice", "value": "C22058"},
                       {"label": "Blizkovice", "value": "C22059"},
                       {"label": "Blucina", "value": "C22060"},
                       {"label": "Boretice", "value": "C22061"},
                       {"label": "Boritov", "value": "C22062"},
                       {"label": "Boskovice", "value": "C22063"},
                       {"label": "Bosovice", "value": "C22064"},
                       {"label": "Bozice", "value": "C22065"},
                       {"label": "Breclav", "value": "C22066"},
                       {"label": "Brezi", "value": "C22067"},
                       {"label": "Brno", "value": "C22068"},
                       {"label": "Bucovice", "value": "C22069"},
                       {"label": "Bzenec", "value": "C22070"},
                       {"label": "Cebin", "value": "C22071"},
                       {"label": "Cejc", "value": "C22072"},
                       {"label": "Cejkovice", "value": "C22073"},
                       {"label": "Cerna Hora", "value": "C22074"},
                       {"label": "Damborice", "value": "C22075"},
                       {"label": "Dobsice", "value": "C22076"},
                       {"label": "Dolni Bojanovice", "value": "C22077"},
                       {"label": "Dolni Dunajovice", "value": "C22078"},
                       {"label": "Dolni Kounice", "value": "C22079"},
                       {"label": "Dolni Loucky", "value": "C22080"},
                       {"label": "Domanin", "value": "C22081"},
                       {"label": "Doubravice nad Svitavou", "value": "C22082"},
                       {"label": "Drasov", "value": "C22083"},
                       {"label": "Drnholec", "value": "C22084"},
                       {"label": "Drnovice", "value": "C22085"},
                       {"label": "Dubnany", "value": "C22086"},
                       {"label": "Hevlin", "value": "C22087"},
                       {"label": "Hlohovec", "value": "C22088"},
                       {"label": "Hodonice", "value": "C22089"},
                       {"label": "Hodonin", "value": "C22090"},
                       {"label": "Hosteradice", "value": "C22091"},
                       {"label": "Hovorany", "value": "C22092"},
                       {"label": "Hroznova Lhota", "value": "C22093"},
                       {"label": "Hrusky", "value": "C22094"},
                       {"label": "Hrusovany nad Jevisovkou", "value": "C22095"},
                       {"label": "Hrusovany u Brna", "value": "C22096"},
                       {"label": "Hustopece", "value": "C22097"},
                       {"label": "Ivancice", "value": "C22098"},
                       {"label": "Ivanovice na Hane", "value": "C22099"},
                       {"label": "Jaroslavice", "value": "C22100"},
                       {"label": "Jedovnice", "value": "C22101"},
                       {"label": "Jevisovice", "value": "C22102"},
                       {"label": "Klobouky", "value": "C22103"},
                       {"label": "Knezdub", "value": "C22104"},
                       {"label": "Kobyli", "value": "C22105"},
                       {"label": "Kostice", "value": "C22106"},
                       {"label": "Krenovice", "value": "C22107"},
                       {"label": "Krepice", "value": "C22108"},
                       {"label": "Krumvir", "value": "C22109"},
                       {"label": "Kunstat", "value": "C22110"},
                       {"label": "Kurim", "value": "C22111"},
                       {"label": "Kyjov", "value": "C22112"},
                       {"label": "Lanzhot", "value": "C22113"},
                       {"label": "Lednice", "value": "C22114"},
                       {"label": "Lelekovice", "value": "C22115"},
                       {"label": "Letonice", "value": "C22116"},
                       {"label": "Letovice", "value": "C22117"},
                       {"label": "Lipov", "value": "C22118"},
                       {"label": "Lipovec", "value": "C22119"},
                       {"label": "Lipuvka", "value": "C22120"},
                       {"label": "Lomnice", "value": "C22121"},
                       {"label": "Louka", "value": "C22122"},
                       {"label": "Luzice", "value": "C22123"},
                       {"label": "Lysice", "value": "C22124"},
                       {"label": "Menin", "value": "C22125"},
                       {"label": "Mesto Brno", "value": "C22126"},
                       {"label": "Mikulcice", "value": "C22127"},
                       {"label": "Mikulov", "value": "C22128"},
                       {"label": "Milotice", "value": "C22129"},
                       {"label": "Miroslav", "value": "C22130"},
                       {"label": "Modrice", "value": "C22131"},
                       {"label": "Mokra Hora", "value": "C22132"},
                       {"label": "Moravany", "value": "C22133"},
                       {"label": "Moravska Nova Ves", "value": "C22134"},
                       {"label": "Moravsky Krumlov", "value": "C22135"},
                       {"label": "Moravsky Pisek", "value": "C22136"},
                       {"label": "Moravsky Zizkov", "value": "C22137"},
                       {"label": "Moutnice", "value": "C22138"},
                       {"label": "Mutenice", "value": "C22139"},
                       {"label": "Nedvedice", "value": "C22140"},
                       {"label": "Nesovice", "value": "C22141"},
                       {"label": "Nosislav", "value": "C22142"},
                       {"label": "Novosedly", "value": "C22143"},
                       {"label": "Okres Blansko", "value": "C22144"},
                       {"label": "Okres Breclav", "value": "C22145"},
                       {"label": "Okres Brno-venkov", "value": "C22146"},
                       {"label": "Okres Hodonin", "value": "C22147"},
                       {"label": "Okres Vyskov", "value": "C22148"},
                       {"label": "Okres Znojmo", "value": "C22149"},
                       {"label": "Olbramovice", "value": "C22150"},
                       {"label": "Olesnice", "value": "C22151"},
                       {"label": "Orechov", "value": "C22152"},
                       {"label": "Oslavany", "value": "C22153"},
                       {"label": "Ostopovice", "value": "C22154"},
                       {"label": "Ostrov u Macochy", "value": "C22155"},
                       {"label": "Otnice", "value": "C22156"},
                       {"label": "Petrov", "value": "C22157"},
                       {"label": "Podivin", "value": "C22158"},
                       {"label": "Podoli", "value": "C22159"},
                       {"label": "Pohorelice", "value": "C22160"},
                       {"label": "Pozorice", "value": "C22161"},
                       {"label": "Predklasteri", "value": "C22162"},
                       {"label": "Prusanky", "value": "C22163"},
                       {"label": "Pustimer", "value": "C22164"},
                       {"label": "Rajec-Jestrebi", "value": "C22165"},
                       {"label": "Rajecko", "value": "C22166"},
                       {"label": "Rajhrad", "value": "C22167"},
                       {"label": "Rajhradice", "value": "C22168"},
                       {"label": "Rakvice", "value": "C22169"},
                       {"label": "Ratiskovice", "value": "C22170"},
                       {"label": "Ricany", "value": "C22171"},
                       {"label": "Rohatec", "value": "C22172"},
                       {"label": "Rosice", "value": "C22173"},
                       {"label": "Rousinov", "value": "C22174"},
                       {"label": "Sakvice", "value": "C22175"},
                       {"label": "Sanov", "value": "C22176"},
                       {"label": "Sardice", "value": "C22177"},
                       {"label": "Satov", "value": "C22178"},
                       {"label": "Sitborice", "value": "C22179"},
                       {"label": "Slapanice", "value": "C22180"},
                       {"label": "Slavkov u Brna", "value": "C22181"},
                       {"label": "Sokolnice", "value": "C22182"},
                       {"label": "Straznice", "value": "C22183"},
                       {"label": "Strelice", "value": "C22184"},
                       {"label": "Suchohrdly", "value": "C22185"},
                       {"label": "Sudomerice", "value": "C22186"},
                       {"label": "Svitavka", "value": "C22187"},
                       {"label": "Tasovice", "value": "C22188"},
                       {"label": "Telnice", "value": "C22189"},
                       {"label": "Tesany", "value": "C22190"},
                       {"label": "Tisnov", "value": "C22191"},
                       {"label": "Troubsko", "value": "C22192"},
                       {"label": "Tvarozna", "value": "C22193"},
                       {"label": "Tvrdonice", "value": "C22194"},
                       {"label": "Tynec", "value": "C22195"},
                       {"label": "Unanov", "value": "C22196"},
                       {"label": "Vacenovice", "value": "C22197"},
                       {"label": "Valtice", "value": "C22198"},
                       {"label": "Velka nad Velickou", "value": "C22199"},
                       {"label": "Velke Bilovice", "value": "C22200"},
                       {"label": "Velke Nemcice", "value": "C22201"},
                       {"label": "Velke Opatovice", "value": "C22202"},
                       {"label": "Velke Pavlovice", "value": "C22203"},
                       {"label": "Veseli nad Moravou", "value": "C22204"},
                       {"label": "Veverska Bityska", "value": "C22205"},
                       {"label": "Vinicne Sumice", "value": "C22206"},
                       {"label": "Visnove", "value": "C22207"},
                       {"label": "Vlkos", "value": "C22208"},
                       {"label": "Vnorovy", "value": "C22209"},
                       {"label": "Vojkovice", "value": "C22210"},
                       {"label": "Vracov", "value": "C22211"},
                       {"label": "Vranovice", "value": "C22212"},
                       {"label": "Vrbice", "value": "C22213"},
                       {"label": "Vrbovec", "value": "C22214"},
                       {"label": "Vyskov", "value": "C22215"},
                       {"label": "Zabcice", "value": "C22216"},
                       {"label": "Zajeci", "value": "C22217"},
                       {"label": "Zastavka", "value": "C22218"},
                       {"label": "Zbraslav", "value": "C22219"},
                       {"label": "Zbysov", "value": "C22220"},
                       {"label": "Zdanice", "value": "C22221"},
                       {"label": "Zelesice", "value": "C22222"},
                       {"label": "Zeravice", "value": "C22223"},
                       {"label": "Zidlochovice", "value": "C22224"},
                       {"label": "Znojmo", "value": "C22225"}],
 "Jijel": [{"label": "Jijel", "value": "C349"}],
 "Jilin": [{"label": "Baicheng", "value": "C19000"},
           {"label": "Baishan", "value": "C19001"},
           {"label": "Baishishan", "value": "C19002"},
           {"label": "Changchun", "value": "C19003"},
           {"label": "Changling", "value": "C19004"},
           {"label": "Chaoyang", "value": "C19005"},
           {"label": "Dalai", "value": "C19006"},
           {"label": "Dashitou", "value": "C19007"},
           {"label": "Dehui", "value": "C19008"},
           {"label": "Dongfeng", "value": "C19009"},
           {"label": "Dunhua", "value": "C19010"},
           {"label": "Erdaojiang", "value": "C19011"},
           {"label": "Fuyu", "value": "C19012"},
           {"label": "Gongzhuling", "value": "C19013"},
           {"label": "Guangming", "value": "C19014"},
           {"label": "Helong", "value": "C19015"},
           {"label": "Hepingjie", "value": "C19016"},
           {"label": "Huadian", "value": "C19017"},
           {"label": "Huangnihe", "value": "C19018"},
           {"label": "Huinan", "value": "C19019"},
           {"label": "Hunchun", "value": "C19020"},
           {"label": "Jian", "value": "C19021"},
           {"label": "Jilin", "value": "C19022"},
           {"label": "Jishu", "value": "C19023"},
           {"label": "Jiutai", "value": "C19024"},
           {"label": "Kaitong", "value": "C19025"},
           {"label": "Liaoyuan", "value": "C19026"},
           {"label": "Linjiang", "value": "C19027"},
           {"label": "Lishu", "value": "C19028"},
           {"label": "Liuhe", "value": "C19029"},
           {"label": "Longjing", "value": "C19030"},
           {"label": "Meihekou", "value": "C19031"},
           {"label": "Mingyue", "value": "C19032"},
           {"label": "Minzhu", "value": "C19033"},
           {"label": "Panshi", "value": "C19034"},
           {"label": "Sanchazi", "value": "C19035"},
           {"label": "Shuangyang", "value": "C19036"},
           {"label": "Shulan", "value": "C19037"},
           {"label": "Siping", "value": "C19038"},
           {"label": "Songjianghe", "value": "C19039"},
           {"label": "Songyuan", "value": "C19040"},
           {"label": "Tonghua", "value": "C19041"},
           {"label": "Tonghua Shi", "value": "C19042"},
           {"label": "Tumen", "value": "C19043"},
           {"label": "Wangqing", "value": "C19044"},
           {"label": "Xinglongshan", "value": "C19045"},
           {"label": "Yanbian Chaoxianzu Zizhizhou", "value": "C19046"},
           {"label": "Yanji", "value": "C19047"},
           {"label": "Yantongshan", "value": "C19048"},
           {"label": "Yushu", "value": "C19049"},
           {"label": "Zhengjiatun", "value": "C19050"},
           {"label": "Zhenlai", "value": "C19051"}],
 "Jinotega": [{"label": "El Cua", "value": "C77932"},
              {"label": "Jinotega", "value": "C77933"},
              {"label": "La Concordia", "value": "C77934"},
              {"label": "Las Praderas", "value": "C77935"},
              {"label": "LLano de La Cruz", "value": "C77936"},
              {"label": "San Jose de Bocay", "value": "C77937"},
              {"label": "San Rafael del Norte", "value": "C77938"},
              {"label": "San Sebastian de Yali", "value": "C77939"}],
 "Jiwaka Province": [{"label": "Angalimp South Wahgi", "value": "C80426"},
                     {"label": "Jimi", "value": "C80427"},
                     {"label": "North Wahgi", "value": "C80428"}],
 "Jizan": [{"label": "Abu Arish", "value": "C106564"},
           {"label": "Abu Radif", "value": "C106565"},
           {"label": "Ad Darb", "value": "C106566"},
           {"label": "Ad Dur`iyah", "value": "C106567"},
           {"label": "Adh Dhagharir", "value": "C106568"},
           {"label": "Al `Ulayin", "value": "C106569"},
           {"label": "Al `Usaylah", "value": "C106570"},
           {"label": "Al Badawi", "value": "C106571"},
           {"label": "Al Hadrur", "value": "C106572"},
           {"label": "Al Hanashah", "value": "C106573"},
           {"label": "Al Harani", "value": "C106574"},
           {"label": "Al Hasamah", "value": "C106575"},
           {"label": "Al Hijfar", "value": "C106576"},
           {"label": "Al Jadi", "value": "C106577"},
           {"label": "Al Jaradiyah", "value": "C106578"},
           {"label": "Al Jawah", "value": "C106579"},
           {"label": "Al Jirbah", "value": "C106580"},
           {"label": "Al Karbus", "value": "C106581"},
           {"label": "Al Kawahilah", "value": "C106582"},
           {"label": "Al Khadra, Jizan", "value": "C106583"},
           {"label": "Al Kharabah, Jizan", "value": "C106584"},
           {"label": "Al Kharadilah", "value": "C106585"},
           {"label": "Al Khashabiyah", "value": "C106586"},
           {"label": "Al Khubah", "value": "C106587"},
           {"label": "Al Kirs", "value": "C106588"},
           {"label": "Al Luqiyah", "value": "C106589"},
           {"label": "Al Ma`ayin", "value": "C106590"},
           {"label": "Al Madaya", "value": "C106591"},
           {"label": "Al Mali", "value": "C106592"},
           {"label": "Al Mayasam", "value": "C106593"},
           {"label": "Al Qaim", "value": "C106594"},
           {"label": "Al Quful", "value": "C106595"},
           {"label": "Al Qurayb", "value": "C106596"},
           {"label": "Al Quwah", "value": "C106597"},
           {"label": "Al Wasili", "value": "C106598"},
           {"label": "An Najamiyah", "value": "C106599"},
           {"label": "Ar Rukubah", "value": "C106600"},
           {"label": "Ash Shuqayq", "value": "C106601"},
           {"label": "Bakhshat Yamani", "value": "C106602"},
           {"label": "Farasan", "value": "C106603"},
           {"label": "Ghawiyah", "value": "C106604"},
           {"label": "Hamayyah", "value": "C106605"},
           {"label": "Hamdah", "value": "C106606"},
           {"label": "Jizan", "value": "C106607"},
           {"label": "Juha, Saudi Arabia", "value": "C106608"},
           {"label": "Ka`lul", "value": "C106609"},
           {"label": "Khabath Sa`id", "value": "C106610"},
           {"label": "Khalfah", "value": "C106611"},
           {"label": "Khatib, Saudi Arabia", "value": "C106612"},
           {"label": "Khumsiyah", "value": "C106613"},
           {"label": "Khushaym", "value": "C106614"},
           {"label": "Mahatah", "value": "C106615"},
           {"label": "Malgocta", "value": "C106616"},
           {"label": "Misliyah", "value": "C106617"},
           {"label": "Mizhirah", "value": "C106618"},
           {"label": "Mukambal", "value": "C106619"},
           {"label": "Mundaraq", "value": "C106620"},
           {"label": "Muwassam", "value": "C106621"},
           {"label": "Qitabir", "value": "C106622"},
           {"label": "Quwayda", "value": "C106623"},
           {"label": "Rahwan", "value": "C106624"},
           {"label": "Rawkhah", "value": "C106625"},
           {"label": "Sabya", "value": "C106626"},
           {"label": "Sadiliyah", "value": "C106627"},
           {"label": "Salamah", "value": "C106628"},
           {"label": "Samitah", "value": "C106629"}],
 "Jizzakh Region": [{"label": "Dashtobod", "value": "C147031"},
                    {"label": "Dustlik", "value": "C147032"},
                    {"label": "Gagarin", "value": "C147033"},
                    {"label": "Jizzax", "value": "C147034"},
                    {"label": "Paxtakor", "value": "C147035"},
                    {"label": "Zomin", "value": "C147036"}],
 "Jogeva County": [{"label": "Jogeva", "value": "C24556"},
                   {"label": "Jogeva vald", "value": "C24557"},
                   {"label": "Mustvee", "value": "C24558"},
                   {"label": "Poltsamaa", "value": "C24559"},
                   {"label": "Poltsamaa vald", "value": "C24560"}],
 "Johor": [{"label": "Bakri", "value": "C65622"},
           {"label": "Batu Pahat", "value": "C65623"},
           {"label": "Buloh Kasap", "value": "C65624"},
           {"label": "Chaah", "value": "C65625"},
           {"label": "Daerah Batu Pahat", "value": "C65626"},
           {"label": "Daerah Johor Baharu", "value": "C65627"},
           {"label": "Daerah Keluang", "value": "C65628"},
           {"label": "Daerah Kota Tinggi", "value": "C65629"},
           {"label": "Daerah Mersing", "value": "C65630"},
           {"label": "Daerah Muar", "value": "C65631"},
           {"label": "Daerah Pontian", "value": "C65632"},
           {"label": "Daerah Segamat", "value": "C65633"},
           {"label": "Johor Bahru", "value": "C65634"},
           {"label": "Kampung Pasir Gudang Baru", "value": "C65635"},
           {"label": "Kampung Simpang Renggam", "value": "C65636"},
           {"label": "Kelapa Sawit", "value": "C65637"},
           {"label": "Kluang", "value": "C65638"},
           {"label": "Kota Tinggi", "value": "C65639"},
           {"label": "Kulai", "value": "C65640"},
           {"label": "Labis", "value": "C65641"},
           {"label": "Mersing", "value": "C65642"},
           {"label": "Muar", "value": "C65643"},
           {"label": "Parit Raja", "value": "C65644"},
           {"label": "Pekan Nenas", "value": "C65645"},
           {"label": "Pontian Kechil", "value": "C65646"},
           {"label": "Segamat", "value": "C65647"},
           {"label": "Skudai", "value": "C65648"},
           {"label": "Taman Senai", "value": "C65649"},
           {"label": "Tangkak", "value": "C65650"},
           {"label": "Ulu Tiram", "value": "C65651"},
           {"label": "Yong Peng", "value": "C65652"}],
 "Jonkoping County": [{"label": "Anderstorp", "value": "C115626"},
                      {"label": "Aneby", "value": "C115627"},
                      {"label": "Bankeryd", "value": "C115628"},
                      {"label": "Bodafors", "value": "C115629"},
                      {"label": "Bor", "value": "C115630"},
                      {"label": "Bredaryd", "value": "C115631"},
                      {"label": "Ekenassjon", "value": "C115632"},
                      {"label": "Eksjo", "value": "C115633"},
                      {"label": "Forserum", "value": "C115634"},
                      {"label": "Forsheda", "value": "C115635"},
                      {"label": "Gislaved", "value": "C115636"},
                      {"label": "Gnosjo", "value": "C115637"},
                      {"label": "Granna", "value": "C115638"},
                      {"label": "Habo", "value": "C115639"},
                      {"label": "Hestra", "value": "C115640"},
                      {"label": "Hillerstorp", "value": "C115641"},
                      {"label": "Huskvarna", "value": "C115642"},
                      {"label": "Jonkoping", "value": "C115643"},
                      {"label": "Kaxholmen", "value": "C115644"},
                      {"label": "Landsbro", "value": "C115645"},
                      {"label": "Malmback", "value": "C115646"},
                      {"label": "Mariannelund", "value": "C115647"},
                      {"label": "Marieholm", "value": "C115648"},
                      {"label": "Mullsjo", "value": "C115649"},
                      {"label": "Nassjo", "value": "C115650"},
                      {"label": "Odensjo", "value": "C115651"},
                      {"label": "Reftele", "value": "C115652"},
                      {"label": "Rydaholm", "value": "C115653"},
                      {"label": "Savsjo", "value": "C115654"},
                      {"label": "Skillingaryd", "value": "C115655"},
                      {"label": "Smalandsstenar", "value": "C115656"},
                      {"label": "Stockaryd", "value": "C115657"},
                      {"label": "Taberg", "value": "C115658"},
                      {"label": "Tenhult", "value": "C115659"},
                      {"label": "Tranas", "value": "C115660"},
                      {"label": "Vaggeryd", "value": "C115661"},
                      {"label": "Varnamo", "value": "C115662"},
                      {"label": "Vetlanda", "value": "C115663"},
                      {"label": "Vrigstad", "value": "C115664"}],
 "Jowzjan": [{"label": "aqchah", "value": "C44"},
             {"label": "Darzab", "value": "C45"},
             {"label": "Qarqin", "value": "C46"},
             {"label": "Shibirghan", "value": "C47"}],
 "Jufra": [{"label": "Hun", "value": "C65038"},
           {"label": "Waddan", "value": "C65039"}],
 "Jujuy": [{"label": "Abra Pampa", "value": "C1085"},
           {"label": "Caimancito", "value": "C1086"},
           {"label": "Calilegua", "value": "C1087"},
           {"label": "Departamento de Cochinoca", "value": "C1088"},
           {"label": "Departamento de Rinconada", "value": "C1089"},
           {"label": "Departamento de Tumbaya", "value": "C1090"},
           {"label": "El Aguilar", "value": "C1091"},
           {"label": "Fraile Pintado", "value": "C1092"},
           {"label": "Humahuaca", "value": "C1093"},
           {"label": "Ingenio La Esperanza", "value": "C1094"},
           {"label": "La Mendieta", "value": "C1095"},
           {"label": "La Quiaca", "value": "C1096"},
           {"label": "Libertador General San Martin", "value": "C1097"},
           {"label": "Maimara", "value": "C1098"},
           {"label": "Palma Sola", "value": "C1099"},
           {"label": "Palpala", "value": "C1100"},
           {"label": "San Pedro de Jujuy", "value": "C1101"},
           {"label": "San Salvador de Jujuy", "value": "C1102"},
           {"label": "Santa Clara", "value": "C1103"},
           {"label": "Tilcara", "value": "C1104"},
           {"label": "Yuto", "value": "C1105"}],
 "Junin": [{"label": "Acolla", "value": "C80841"},
           {"label": "Carhuamayo", "value": "C80842"},
           {"label": "Chanchamayo", "value": "C80843"},
           {"label": "Chupaca", "value": "C80844"},
           {"label": "Concepcion", "value": "C80845"},
           {"label": "Huancayo", "value": "C80846"},
           {"label": "Huasahuasi", "value": "C80847"},
           {"label": "Huayucachi", "value": "C80848"},
           {"label": "Jauja", "value": "C80849"},
           {"label": "Junin", "value": "C80850"},
           {"label": "La Oroya", "value": "C80851"},
           {"label": "Mazamari", "value": "C80852"},
           {"label": "Morococha", "value": "C80853"},
           {"label": "Orcotuna", "value": "C80854"},
           {"label": "Perene", "value": "C80855"},
           {"label": "Pilcomay", "value": "C80856"},
           {"label": "Provincia de Chupaca", "value": "C80857"},
           {"label": "Provincia de Concepcion", "value": "C80858"},
           {"label": "Provincia de Huancayo", "value": "C80859"},
           {"label": "Provincia de Jauja", "value": "C80860"},
           {"label": "Provincia de Junin", "value": "C80861"},
           {"label": "Provincia de Satipo", "value": "C80862"},
           {"label": "Provincia de Tarma", "value": "C80863"},
           {"label": "Provincia de Yauli", "value": "C80864"},
           {"label": "San Agustin", "value": "C80865"},
           {"label": "San Jeronimo", "value": "C80866"},
           {"label": "San Pedro de Cajas", "value": "C80867"},
           {"label": "San Ramon", "value": "C80868"},
           {"label": "Satipo", "value": "C80869"},
           {"label": "Sicaya", "value": "C80870"},
           {"label": "Tarma", "value": "C80871"}],
 "Jura": [{"label": "Alle", "value": "C116813"},
          {"label": "Bassecourt", "value": "C116814"},
          {"label": "Boncourt", "value": "C116815"},
          {"label": "Courfaivre", "value": "C116816"},
          {"label": "Courgenay", "value": "C116817"},
          {"label": "Courrendlin", "value": "C116818"},
          {"label": "Courroux", "value": "C116819"},
          {"label": "Courtetelle", "value": "C116820"},
          {"label": "Delemont", "value": "C116821"},
          {"label": "Delemont District", "value": "C116822"},
          {"label": "Fontenais", "value": "C116823"},
          {"label": "Franches-Montagnes District", "value": "C116824"},
          {"label": "Glovelier", "value": "C116825"},
          {"label": "Le Noirmont", "value": "C116826"},
          {"label": "Les Bois", "value": "C116827"},
          {"label": "Les Breuleux", "value": "C116828"},
          {"label": "Porrentruy", "value": "C116829"},
          {"label": "Porrentruy District", "value": "C116830"},
          {"label": "Saignelegier", "value": "C116831"},
          {"label": "Vicques", "value": "C116832"}],
 "Jurmala": [{"label": "Jurmala", "value": "C64881"}],
 "Jursinci Municipality": [{"label": "Jursinci", "value": "C107653"}],
 "Jutiapa Department": [{"label": "Agua Blanca", "value": "C42748"},
                        {"label": "Asuncion Mita", "value": "C42749"},
                        {"label": "Atescatempa", "value": "C42750"},
                        {"label": "Comapa", "value": "C42751"},
                        {"label": "Conguaco", "value": "C42752"},
                        {"label": "El Adelanto", "value": "C42753"},
                        {"label": "El Progreso", "value": "C42754"},
                        {"label": "Jalpatagua", "value": "C42755"},
                        {"label": "Jerez", "value": "C42756"},
                        {"label": "Jutiapa", "value": "C42757"},
                        {"label": "Moyuta", "value": "C42758"},
                        {"label": "Municipio de Asuncion Mita",
                         "value": "C42759"},
                        {"label": "Pasaco", "value": "C42760"},
                        {"label": "Quesada", "value": "C42761"},
                        {"label": "San Jose Acatempa", "value": "C42762"},
                        {"label": "Santa Catarina Mita", "value": "C42763"},
                        {"label": "Yupiltepeque", "value": "C42764"},
                        {"label": "Zapotitlan", "value": "C42765"}],
 "Kaafu Atoll": [{"label": "Guraidhoo", "value": "C65809"},
                 {"label": "Hulhumale", "value": "C65810"},
                 {"label": "Maafushi", "value": "C65811"},
                 {"label": "Male", "value": "C65812"}],
 "Kabardino-Balkar Republic": [{"label": "Aleksandrovskaya",
                                "value": "C100968"},
                               {"label": "Altud", "value": "C100969"},
                               {"label": "Argudan", "value": "C100970"},
                               {"label": "Aushiger", "value": "C100971"},
                               {"label": "Babugent", "value": "C100972"},
                               {"label": "Baksan", "value": "C100973"},
                               {"label": "Baksanenok", "value": "C100974"},
                               {"label": "Baksanskiy Rayon",
                                "value": "C100975"},
                               {"label": "Belaya Rechka", "value": "C100976"},
                               {"label": "Bylym", "value": "C100977"},
                               {"label": "Chegem", "value": "C100978"},
                               {"label": "Chegem Vtoroy", "value": "C100979"},
                               {"label": "Cherekskiy Rayon",
                                "value": "C100980"},
                               {"label": "Deyskoye", "value": "C100981"},
                               {"label": "Dugulubgey", "value": "C100982"},
                               {"label": "Elbrus", "value": "C100983"},
                               {"label": "Elbrusskiy Rayon",
                                "value": "C100984"},
                               {"label": "Germenchik", "value": "C100985"},
                               {"label": "Gundelen", "value": "C100986"},
                               {"label": "Islamey", "value": "C100987"},
                               {"label": "Kakhun", "value": "C100988"},
                               {"label": "Kamenka", "value": "C100989"},
                               {"label": "Kamennomostskoye",
                                "value": "C100990"},
                               {"label": "Karagach", "value": "C100991"},
                               {"label": "Kashkhatau", "value": "C100992"},
                               {"label": "Kenzhe", "value": "C100993"},
                               {"label": "Kishpek", "value": "C100994"},
                               {"label": "Kotlyarevskaya", "value": "C100995"},
                               {"label": "Kuba-Taba", "value": "C100996"},
                               {"label": "Kyzburun Pervyy", "value": "C100997"},
                               {"label": "Lechinkay", "value": "C100998"},
                               {"label": "Leskenskiy rayon",
                                "value": "C100999"},
                               {"label": "Malka", "value": "C101000"},
                               {"label": "Mayskiy Rayon", "value": "C101001"},
                               {"label": "Nalchik", "value": "C101002"},
                               {"label": "Nartan", "value": "C101003"},
                               {"label": "Nartkala", "value": "C101004"},
                               {"label": "Nizhniy Cherek", "value": "C101005"},
                               {"label": "Nizhniy Kurkuzhin",
                                "value": "C101006"},
                               {"label": "Planovskoye", "value": "C101007"},
                               {"label": "Primalkinskoye", "value": "C101008"},
                               {"label": "Prokhladnenskiy Rayon",
                                "value": "C101009"},
                               {"label": "Prokhladnyy", "value": "C101010"},
                               {"label": "Psygansu", "value": "C101011"},
                               {"label": "Sarmakovo", "value": "C101012"},
                               {"label": "Shalushka", "value": "C101013"},
                               {"label": "Soldatskaya", "value": "C101014"},
                               {"label": "Staryy Cherek", "value": "C101015"},
                               {"label": "Staryy Urukh", "value": "C101016"},
                               {"label": "Terek", "value": "C101017"},
                               {"label": "Terskiy Rayon", "value": "C101018"},
                               {"label": "Terskol", "value": "C101019"},
                               {"label": "Tyrnyauz", "value": "C101020"},
                               {"label": "Urukh", "value": "C101021"},
                               {"label": "Urvan", "value": "C101022"},
                               {"label": "Urvanskiy Rayon", "value": "C101023"},
                               {"label": "Verkhniy Kurkuzhin",
                                "value": "C101024"},
                               {"label": "Verkhnyaya Balkariya",
                                "value": "C101025"},
                               {"label": "Volnyy Aul", "value": "C101026"},
                               {"label": "Zalukokoazhe", "value": "C101027"},
                               {"label": "Zayukovo", "value": "C101028"},
                               {"label": "Zhankhoteko", "value": "C101029"},
                               {"label": "Zhemtala", "value": "C101030"},
                               {"label": "Zolskiy Rayon", "value": "C101031"}],
 "Kabul": [{"label": "Kabul", "value": "C48"},
           {"label": "Mir Bachah Kot", "value": "C49"},
           {"label": "Paghman", "value": "C50"}],
 "Kachin State": [{"label": "Bhamo", "value": "C75911"},
                  {"label": "Myitkyina", "value": "C75912"}],
 "Kaduna": [{"label": "Anchau", "value": "C78302"},
            {"label": "Burumburum", "value": "C78303"},
            {"label": "Dutsen Wai", "value": "C78304"},
            {"label": "Hunkuyi", "value": "C78305"},
            {"label": "Kachia", "value": "C78306"},
            {"label": "Kaduna", "value": "C78307"},
            {"label": "Kafanchan", "value": "C78308"},
            {"label": "Kagoro", "value": "C78309"},
            {"label": "Kajuru", "value": "C78310"},
            {"label": "Kujama", "value": "C78311"},
            {"label": "Lere", "value": "C78312"},
            {"label": "Mando", "value": "C78313"},
            {"label": "Saminaka", "value": "C78314"},
            {"label": "Soba", "value": "C78315"},
            {"label": "Sofo-Birnin-Gwari", "value": "C78316"},
            {"label": "Zaria", "value": "C78317"}],
 "Kaffrine": [{"label": "Kaffrine", "value": "C106841"},
              {"label": "Koungheul", "value": "C106842"}],
 "Kafr el-Sheikh": [{"label": "Al Hamul", "value": "C24285"},
                    {"label": "Disuq", "value": "C24286"},
                    {"label": "Fuwwah", "value": "C24287"},
                    {"label": "Kafr ash Shaykh", "value": "C24288"},
                    {"label": "Markaz Disuq", "value": "C24289"},
                    {"label": "Munshat Ali agha", "value": "C24290"},
                    {"label": "Sidi Salim", "value": "C24291"}],
 "Kagawa Prefecture": [{"label": "Higashikagawa Shi", "value": "C63184"},
                       {"label": "Kanonji Shi", "value": "C63185"},
                       {"label": "Kanonjicho", "value": "C63186"},
                       {"label": "Marugame", "value": "C63187"},
                       {"label": "Marugame Shi", "value": "C63188"},
                       {"label": "Mitoyo Shi", "value": "C63189"},
                       {"label": "Sakaide Shi", "value": "C63190"},
                       {"label": "Sakaidecho", "value": "C63191"},
                       {"label": "Sanuki-shi", "value": "C63192"},
                       {"label": "Shido", "value": "C63193"},
                       {"label": "Tadotsu", "value": "C63194"},
                       {"label": "Takamatsu", "value": "C63195"},
                       {"label": "Takamatsu Shi", "value": "C63196"},
                       {"label": "Tonosho", "value": "C63197"},
                       {"label": "Zentsuji Shi", "value": "C63198"}],
 "Kagera": [{"label": "Biharamulo", "value": "C118102"},
            {"label": "Bugarama", "value": "C118103"},
            {"label": "Bugene", "value": "C118104"},
            {"label": "Bukoba", "value": "C118105"},
            {"label": "Kabanga", "value": "C118106"},
            {"label": "Kamachumu", "value": "C118107"},
            {"label": "Katerero", "value": "C118108"},
            {"label": "Katoro", "value": "C118109"},
            {"label": "Kyaka", "value": "C118110"},
            {"label": "Ngara", "value": "C118111"},
            {"label": "Nshamba", "value": "C118112"},
            {"label": "Nsunga", "value": "C118113"},
            {"label": "Nyakahanga", "value": "C118114"},
            {"label": "Rulenge", "value": "C118115"}],
 "Kagoshima Prefecture": [{"label": "Aira Shi", "value": "C63199"},
                          {"label": "Akune", "value": "C63200"},
                          {"label": "Akune Shi", "value": "C63201"},
                          {"label": "Amami", "value": "C63202"},
                          {"label": "Amami Shi", "value": "C63203"},
                          {"label": "Hamanoichi", "value": "C63204"},
                          {"label": "Hioki", "value": "C63205"},
                          {"label": "Hioki Shi", "value": "C63206"},
                          {"label": "Ibusuki", "value": "C63207"},
                          {"label": "Ibusuki Shi", "value": "C63208"},
                          {"label": "Ichikikushikino Shi", "value": "C63209"},
                          {"label": "Ijuin", "value": "C63210"},
                          {"label": "Isa Shi", "value": "C63211"},
                          {"label": "Izumi", "value": "C63212"},
                          {"label": "Izumi Shi", "value": "C63213"},
                          {"label": "Kagoshima", "value": "C63214"},
                          {"label": "Kagoshima Shi", "value": "C63215"},
                          {"label": "Kajiki", "value": "C63216"},
                          {"label": "Kanoya", "value": "C63217"},
                          {"label": "Kanoya Shi", "value": "C63218"},
                          {"label": "Kaseda-shirakame", "value": "C63219"},
                          {"label": "Kirishima Shi", "value": "C63220"},
                          {"label": "Kokubu-matsuki", "value": "C63221"},
                          {"label": "Koseda", "value": "C63222"},
                          {"label": "Koshima", "value": "C63223"},
                          {"label": "Kurio", "value": "C63224"},
                          {"label": "Kushikino", "value": "C63225"},
                          {"label": "Makurazaki", "value": "C63226"},
                          {"label": "Makurazaki Shi", "value": "C63227"},
                          {"label": "Minamikyushu Shi", "value": "C63228"},
                          {"label": "Minamisatsuma Shi", "value": "C63229"},
                          {"label": "Miyanoura", "value": "C63230"},
                          {"label": "Nagata", "value": "C63231"},
                          {"label": "Naze", "value": "C63232"},
                          {"label": "Nishinoomote", "value": "C63233"},
                          {"label": "Nishinoomote Shi", "value": "C63234"},
                          {"label": "Okuchi-shinohara", "value": "C63235"},
                          {"label": "Satsumasendai", "value": "C63236"},
                          {"label": "Satsumasendai Shi", "value": "C63237"},
                          {"label": "Shibushi", "value": "C63238"},
                          {"label": "Shibushi-shi", "value": "C63239"},
                          {"label": "Soo Shi", "value": "C63240"},
                          {"label": "Sueyoshicho-ninokata", "value": "C63241"},
                          {"label": "Tarumizu", "value": "C63242"},
                          {"label": "Tarumizu Shi", "value": "C63243"},
                          {"label": "Yudomari", "value": "C63244"}],
 "Kahramanmaras": [{"label": "Afsin", "value": "C120505"},
                   {"label": "Andirin", "value": "C120506"},
                   {"label": "Caglayancerit", "value": "C120507"},
                   {"label": "Celeyke", "value": "C120508"},
                   {"label": "Dulkadiroglu", "value": "C120509"},
                   {"label": "Ekinozu Ilcesi", "value": "C120510"},
                   {"label": "Elbistan", "value": "C120511"},
                   {"label": "Goksun", "value": "C120512"},
                   {"label": "Kahramanmaras", "value": "C120513"},
                   {"label": "Nurhak", "value": "C120514"},
                   {"label": "Onikisubat", "value": "C120515"},
                   {"label": "Pazarcik", "value": "C120516"},
                   {"label": "Suleymanli", "value": "C120517"},
                   {"label": "Turkoglu Ilcesi", "value": "C120518"}],
 "Kainuu": [{"label": "Hyrynsalmi", "value": "C24919"},
            {"label": "Kajaani", "value": "C24920"},
            {"label": "Kuhmo", "value": "C24921"},
            {"label": "Paltamo", "value": "C24922"},
            {"label": "Puolanka", "value": "C24923"},
            {"label": "Ristijarvi", "value": "C24924"},
            {"label": "Sotkamo", "value": "C24925"},
            {"label": "Suomussalmi", "value": "C24926"},
            {"label": "Vaala", "value": "C24927"},
            {"label": "Vuokatti", "value": "C24928"},
            {"label": "Vuolijoki", "value": "C24929"}],
 "Kairouan Governorate": [{"label": "Haffouz", "value": "C119713"},
                          {"label": "Kairouan", "value": "C119714"},
                          {"label": "Sbikha", "value": "C119715"}],
 "Kajiado": [{"label": "Kajiado", "value": "C64557"},
             {"label": "Magadi", "value": "C64558"},
             {"label": "Ngong", "value": "C64559"}],
 "Kakamega": [{"label": "Butere", "value": "C64560"},
              {"label": "Kakamega", "value": "C64561"},
              {"label": "Mumias", "value": "C64562"}],
 "Kakheti": [{"label": "Akhmeta", "value": "C34294"},
             {"label": "Akhmetis Munitsipaliteti", "value": "C34295"},
             {"label": "Gurjaani", "value": "C34296"},
             {"label": "Lagodekhi", "value": "C34297"},
             {"label": "Qvareli", "value": "C34298"},
             {"label": "Sagarejo", "value": "C34299"},
             {"label": "Sighnaghi", "value": "C34300"},
             {"label": "Sighnaghis Munitsipaliteti", "value": "C34301"},
             {"label": "Telavi", "value": "C34302"},
             {"label": "Tsinandali", "value": "C34303"},
             {"label": "Tsnori", "value": "C34304"}],
 "Kalasin": [{"label": "Amphoe Don Chan", "value": "C118609"},
             {"label": "Amphoe Huai Mek", "value": "C118610"},
             {"label": "Amphoe Huai Phueng", "value": "C118611"},
             {"label": "Amphoe Kamalasai", "value": "C118612"},
             {"label": "Amphoe Kham Muang", "value": "C118613"},
             {"label": "Amphoe Khao Wong", "value": "C118614"},
             {"label": "Amphoe Khong Chai", "value": "C118615"},
             {"label": "Amphoe Kuchinarai", "value": "C118616"},
             {"label": "Amphoe Mueang Kalasin", "value": "C118617"},
             {"label": "Amphoe Na Khu", "value": "C118618"},
             {"label": "Amphoe Na Mon", "value": "C118619"},
             {"label": "Amphoe Nong Kung Si", "value": "C118620"},
             {"label": "Amphoe Rong Kham", "value": "C118621"},
             {"label": "Amphoe Sahatsakhan", "value": "C118622"},
             {"label": "Amphoe Sam Chai", "value": "C118623"},
             {"label": "Amphoe Somdet", "value": "C118624"},
             {"label": "Amphoe Tha Khantho", "value": "C118625"},
             {"label": "Amphoe Yang Talat", "value": "C118626"},
             {"label": "Huai Mek", "value": "C118627"},
             {"label": "Kalasin", "value": "C118628"},
             {"label": "Kamalasai", "value": "C118629"},
             {"label": "Khao Wong", "value": "C118630"},
             {"label": "Kuchinarai", "value": "C118631"},
             {"label": "Nong Kung Si", "value": "C118632"},
             {"label": "Yang Talat", "value": "C118633"}],
 "Kalbajar District": [{"label": "Kerbakhiar", "value": "C8446"},
                       {"label": "Vank", "value": "C8447"}],
 "Kalimantan Barat": [{"label": "Bengkayang", "value": "C49584"},
                      {"label": "Kapuas Hulu", "value": "C49585"},
                      {"label": "Kayong Utara", "value": "C49586"},
                      {"label": "Ketapang", "value": "C49587"},
                      {"label": "Kubu Raya", "value": "C49588"},
                      {"label": "Landak", "value": "C49589"},
                      {"label": "Manismata", "value": "C49590"},
                      {"label": "Melawi", "value": "C49591"},
                      {"label": "Mempawah", "value": "C49592"},
                      {"label": "Pemangkat", "value": "C49593"},
                      {"label": "Pontianak", "value": "C49594"},
                      {"label": "Sambas", "value": "C49595"},
                      {"label": "Sanggau", "value": "C49596"},
                      {"label": "Sekadau", "value": "C49597"},
                      {"label": "Singkawang", "value": "C49598"},
                      {"label": "Sintang", "value": "C49599"}],
 "Kalimantan Selatan": [{"label": "Amuntai", "value": "C49600"},
                        {"label": "Banjarmasin", "value": "C49601"},
                        {"label": "Barabai", "value": "C49602"},
                        {"label": "Kabupaten Balangan", "value": "C49603"},
                        {"label": "Kabupaten Banjar", "value": "C49604"},
                        {"label": "Kabupaten Barito Kuala", "value": "C49605"},
                        {"label": "Kabupaten Hulu Sungai Selatan",
                         "value": "C49606"},
                        {"label": "Kabupaten Hulu Sungai Tengah",
                         "value": "C49607"},
                        {"label": "Kabupaten Hulu Sungai Utara",
                         "value": "C49608"},
                        {"label": "Kabupaten Kota Baru", "value": "C49609"},
                        {"label": "Kabupaten Tabalong", "value": "C49610"},
                        {"label": "Kabupaten Tanah Bumbu", "value": "C49611"},
                        {"label": "Kabupaten Tanah Laut", "value": "C49612"},
                        {"label": "Kabupaten Tapin", "value": "C49613"},
                        {"label": "Kota Banjar Baru", "value": "C49614"},
                        {"label": "Kota Banjarmasin", "value": "C49615"},
                        {"label": "Martapura", "value": "C49616"}],
 "Kalimantan Tengah": [{"label": "Kabupaten Barito Selatan", "value": "C49617"},
                       {"label": "Kabupaten Barito Timur", "value": "C49618"},
                       {"label": "Kabupaten Barito Utara", "value": "C49619"},
                       {"label": "Kabupaten Gunung Mas", "value": "C49620"},
                       {"label": "Kabupaten Kapuas", "value": "C49621"},
                       {"label": "Kabupaten Katingan", "value": "C49622"},
                       {"label": "Kabupaten Kotawaringin Barat",
                        "value": "C49623"},
                       {"label": "Kabupaten Kotawaringin Timur",
                        "value": "C49624"},
                       {"label": "Kabupaten Lamandau", "value": "C49625"},
                       {"label": "Kabupaten Murung Raya", "value": "C49626"},
                       {"label": "Kabupaten Pulang Pisau", "value": "C49627"},
                       {"label": "Kabupaten Seruyan", "value": "C49628"},
                       {"label": "Kabupaten Sukamara", "value": "C49629"},
                       {"label": "Kota Palangka Raya", "value": "C49630"},
                       {"label": "Kualakapuas", "value": "C49631"},
                       {"label": "Palangkaraya", "value": "C49632"},
                       {"label": "Pangkalanbuun", "value": "C49633"},
                       {"label": "Sampit", "value": "C49634"}],
 "Kalimantan Timur": [{"label": "Balikpapan", "value": "C49635"},
                      {"label": "Bontang", "value": "C49636"},
                      {"label": "City of Balikpapan", "value": "C49637"},
                      {"label": "Kabupaten Berau", "value": "C49638"},
                      {"label": "Kabupaten Kutai Barat", "value": "C49639"},
                      {"label": "Kabupaten Kutai Kartanegara",
                       "value": "C49640"},
                      {"label": "Kabupaten Kutai Timur", "value": "C49641"},
                      {"label": "Kabupaten Mahakam Hulu", "value": "C49642"},
                      {"label": "Kabupaten Paser", "value": "C49643"},
                      {"label": "Kabupaten Penajam Paser Utara",
                       "value": "C49644"},
                      {"label": "Kota Balikpapan", "value": "C49645"},
                      {"label": "Kota Bontang", "value": "C49646"},
                      {"label": "Kota Samarinda", "value": "C49647"},
                      {"label": "Loa Janan", "value": "C49648"},
                      {"label": "Samarinda", "value": "C49649"}],
 "Kalimantan Utara": [{"label": "Kabupaten Bulungan", "value": "C49650"},
                      {"label": "Kabupaten Malinau", "value": "C49651"},
                      {"label": "Kabupaten Nunukan", "value": "C49652"},
                      {"label": "Kabupaten Tana Tidung", "value": "C49653"},
                      {"label": "Tanjung Selor", "value": "C49654"},
                      {"label": "Tarakan", "value": "C49655"}],
 "Kaliningrad": [{"label": "Bagrationovsk", "value": "C101032"},
                 {"label": "Baltiysk", "value": "C101033"},
                 {"label": "Bolshoe Isakovo", "value": "C101034"},
                 {"label": "Chernyakhovsk", "value": "C101035"},
                 {"label": "Donskoye", "value": "C101036"},
                 {"label": "Guryevsk", "value": "C101037"},
                 {"label": "Gusev", "value": "C101038"},
                 {"label": "Gvardeysk", "value": "C101039"},
                 {"label": "Kaliningrad", "value": "C101040"},
                 {"label": "Khrabrovo", "value": "C101041"},
                 {"label": "Krasnoznamensk", "value": "C101042"},
                 {"label": "Ladushkin", "value": "C101043"},
                 {"label": "Maloye Isakovo", "value": "C101044"},
                 {"label": "Mamonovo", "value": "C101045"},
                 {"label": "Neman", "value": "C101046"},
                 {"label": "Nemanskiy Rayon", "value": "C101047"},
                 {"label": "Nesterov", "value": "C101048"},
                 {"label": "Nesterovskiy Rayon", "value": "C101049"},
                 {"label": "Otradnoye", "value": "C101050"},
                 {"label": "Ozersk", "value": "C101051"},
                 {"label": "Ozerskiy Rayon", "value": "C101052"},
                 {"label": "Pionerskiy", "value": "C101053"},
                 {"label": "Polessk", "value": "C101054"},
                 {"label": "Pravdinsk", "value": "C101055"},
                 {"label": "Primorsk", "value": "C101056"},
                 {"label": "Slavsk", "value": "C101057"},
                 {"label": "Slavskiy Rayon", "value": "C101058"},
                 {"label": "Sovetsk", "value": "C101059"},
                 {"label": "Svetlogorsk", "value": "C101060"},
                 {"label": "Svetlyy", "value": "C101061"},
                 {"label": "Volochayevskoye", "value": "C101062"},
                 {"label": "Vzmorye", "value": "C101063"},
                 {"label": "Yantarnyy", "value": "C101064"},
                 {"label": "Yuzhnyy", "value": "C101065"},
                 {"label": "Zelenogradsk", "value": "C101066"},
                 {"label": "Zheleznodorozhnyy", "value": "C101067"},
                 {"label": "Znamensk", "value": "C101068"}],
 "Kalkara": [{"label": "Imgarr", "value": "C65887"},
             {"label": "Mgarr", "value": "C65888"}],
 "Kalmar County": [{"label": "Ankarsrum", "value": "C115665"},
                   {"label": "Bergkvara", "value": "C115666"},
                   {"label": "Blomstermala", "value": "C115667"},
                   {"label": "Borgholm", "value": "C115668"},
                   {"label": "Emmaboda", "value": "C115669"},
                   {"label": "Farjestaden", "value": "C115670"},
                   {"label": "Gamleby", "value": "C115671"},
                   {"label": "Gunnebo", "value": "C115672"},
                   {"label": "Hogsby", "value": "C115673"},
                   {"label": "Hultsfred", "value": "C115674"},
                   {"label": "Kalmar", "value": "C115675"},
                   {"label": "Kristdala", "value": "C115676"},
                   {"label": "Lindsdal", "value": "C115677"},
                   {"label": "Ljungbyholm", "value": "C115678"},
                   {"label": "Malilla", "value": "C115679"},
                   {"label": "Monsteras", "value": "C115680"},
                   {"label": "Morbylanga", "value": "C115681"},
                   {"label": "Nybro", "value": "C115682"},
                   {"label": "Oskarshamn", "value": "C115683"},
                   {"label": "Overum", "value": "C115684"},
                   {"label": "Paskallavik", "value": "C115685"},
                   {"label": "Rinkabyholm", "value": "C115686"},
                   {"label": "Smedby", "value": "C115687"},
                   {"label": "Sodra Sandby", "value": "C115688"},
                   {"label": "Sodra Vi", "value": "C115689"},
                   {"label": "Storebro", "value": "C115690"},
                   {"label": "Timmernabben", "value": "C115691"},
                   {"label": "Torsas", "value": "C115692"},
                   {"label": "Trekanten", "value": "C115693"},
                   {"label": "Vastervik", "value": "C115694"},
                   {"label": "Vimmerby", "value": "C115695"},
                   {"label": "Virserum", "value": "C115696"}],
 "Kaluga Oblast": [{"label": "Babynino", "value": "C101069"},
                   {"label": "Babyninskiy Rayon", "value": "C101070"},
                   {"label": "Balabanovo", "value": "C101071"},
                   {"label": "Baryatino", "value": "C101072"},
                   {"label": "Baryatinskiy Rayon", "value": "C101073"},
                   {"label": "Belousovo", "value": "C101074"},
                   {"label": "Betlitsa", "value": "C101075"},
                   {"label": "Borovsk", "value": "C101076"},
                   {"label": "Borovskiy Rayon", "value": "C101077"},
                   {"label": "Deshovki", "value": "C101078"},
                   {"label": "Detchino", "value": "C101079"},
                   {"label": "Duminichi", "value": "C101080"},
                   {"label": "Duminichskiy Rayon", "value": "C101081"},
                   {"label": "Dvortsy", "value": "C101082"},
                   {"label": "Dzerzhinskiy Rayon", "value": "C101083"},
                   {"label": "Ferzikovo", "value": "C101084"},
                   {"label": "Ferzikovskiy Rayon", "value": "C101085"},
                   {"label": "Iznoski", "value": "C101086"},
                   {"label": "Iznoskovskiy rayon", "value": "C101087"},
                   {"label": "Kaluga", "value": "C101088"},
                   {"label": "Khvastovichi", "value": "C101089"},
                   {"label": "Khvastovichskiy Rayon", "value": "C101090"},
                   {"label": "Kirov", "value": "C101091"},
                   {"label": "Kirovskiy Rayon", "value": "C101092"},
                   {"label": "Kondrovo", "value": "C101093"},
                   {"label": "Kozelsk", "value": "C101094"},
                   {"label": "Kozelskiy Rayon", "value": "C101095"},
                   {"label": "Kremenki", "value": "C101096"},
                   {"label": "Kudinovo", "value": "C101097"},
                   {"label": "Kurovskoye", "value": "C101098"},
                   {"label": "Kuybyshevskiy Rayon", "value": "C101099"},
                   {"label": "Lyudinovo", "value": "C101100"},
                   {"label": "Lyudinovskiy Rayon", "value": "C101101"},
                   {"label": "Maloyaroslavets", "value": "C101102"},
                   {"label": "Maloyaroslavetskiy Rayon", "value": "C101103"},
                   {"label": "Medyn", "value": "C101104"},
                   {"label": "Medynskiy Rayon", "value": "C101105"},
                   {"label": "Meshchovsk", "value": "C101106"},
                   {"label": "Meshchovskiy Rayon", "value": "C101107"},
                   {"label": "Mosalsk", "value": "C101108"},
                   {"label": "Mosalskiy Rayon", "value": "C101109"},
                   {"label": "Mstikhino", "value": "C101110"},
                   {"label": "Myatlevo", "value": "C101111"},
                   {"label": "Nikola-Lenivets", "value": "C101112"},
                   {"label": "Obninsk", "value": "C101113"},
                   {"label": "Peremyshl", "value": "C101114"},
                   {"label": "Peremyshlskiy Rayon", "value": "C101115"},
                   {"label": "Polotnyanyy Zavod", "value": "C101116"},
                   {"label": "Pyatovskiy", "value": "C101117"},
                   {"label": "Seredeyskiy", "value": "C101118"},
                   {"label": "Sosenskiy", "value": "C101119"},
                   {"label": "Spas-Demensk", "value": "C101120"},
                   {"label": "Spas-Demenskiy Rayon", "value": "C101121"},
                   {"label": "Sukhinichi", "value": "C101122"},
                   {"label": "Sukhinichskiy Rayon", "value": "C101123"},
                   {"label": "Tarusa", "value": "C101124"},
                   {"label": "Tarusskiy Rayon", "value": "C101125"},
                   {"label": "Tovarkovo", "value": "C101126"},
                   {"label": "Ulyanovskiy Rayon", "value": "C101127"},
                   {"label": "Vorotynsk", "value": "C101128"},
                   {"label": "Vysokinichi", "value": "C101129"},
                   {"label": "Yermolino", "value": "C101130"},
                   {"label": "Yukhnov", "value": "C101131"},
                   {"label": "Yukhnovskiy Rayon", "value": "C101132"},
                   {"label": "Zarechye", "value": "C101133"},
                   {"label": "Zhiletovo", "value": "C101134"},
                   {"label": "Zhizdra", "value": "C101135"},
                   {"label": "Zhizdrinskiy Rayon", "value": "C101136"},
                   {"label": "Zhukovo", "value": "C101137"},
                   {"label": "Zhukovskiy Rayon", "value": "C101138"}],
 "Kamchatka Krai": [{"label": "Aleutskiy Rayon", "value": "C101139"},
                    {"label": "Atlasovo", "value": "C101140"},
                    {"label": "Bystrinskiy Rayon", "value": "C101141"},
                    {"label": "Esso", "value": "C101142"},
                    {"label": "Karaginsky District", "value": "C101143"},
                    {"label": "Klyuchi", "value": "C101144"},
                    {"label": "Kozyrevsk", "value": "C101145"},
                    {"label": "Milkovo", "value": "C101146"},
                    {"label": "Milkovskiy Rayon", "value": "C101147"},
                    {"label": "Olyutorskiy Rayon", "value": "C101148"},
                    {"label": "Ossora", "value": "C101149"},
                    {"label": "Ozernovskiy", "value": "C101150"},
                    {"label": "Palana", "value": "C101151"},
                    {"label": "Paratunka", "value": "C101152"},
                    {"label": "Penzhinskiy Rayon", "value": "C101153"},
                    {"label": "Petropavlovsk-Kamchatsky", "value": "C101154"},
                    {"label": "Sobolevskiy Rayon", "value": "C101155"},
                    {"label": "Tigil", "value": "C101156"},
                    {"label": "Tigilskiy Rayon", "value": "C101157"},
                    {"label": "Tilichiki", "value": "C101158"},
                    {"label": "Ust-Bolsheretskiy Rayon", "value": "C101159"},
                    {"label": "Ust-Kamchatsk Staryy", "value": "C101160"},
                    {"label": "Ust-Kamchatskiy Rayon", "value": "C101161"},
                    {"label": "Vilyuchinsk", "value": "C101162"},
                    {"label": "Yelizovo", "value": "C101163"},
                    {"label": "Yelizovskiy Rayon", "value": "C101164"}],
 "Kamnik Municipality": [{"label": "Kamnik", "value": "C107654"},
                         {"label": "Mekinje", "value": "C107655"},
                         {"label": "Smarca", "value": "C107656"}],
 "Kamphaeng Phet": [{"label": "Amphoe Bueng Samakkhi", "value": "C118634"},
                    {"label": "Amphoe Khanu Woralaksaburi", "value": "C118635"},
                    {"label": "Amphoe Khlong Khlung", "value": "C118636"},
                    {"label": "Amphoe Khlong Lan", "value": "C118637"},
                    {"label": "Amphoe Kosamphi Nakhon", "value": "C118638"},
                    {"label": "Amphoe Lan Krabue", "value": "C118639"},
                    {"label": "Amphoe Mueang Kamphaeng Phet",
                     "value": "C118640"},
                    {"label": "Amphoe Pang Sila Thong", "value": "C118641"},
                    {"label": "Amphoe Phran Kratai", "value": "C118642"},
                    {"label": "Amphoe Sai Ngam", "value": "C118643"},
                    {"label": "Amphoe Sai Thong Watthana", "value": "C118644"},
                    {"label": "Kamphaeng Phet", "value": "C118645"},
                    {"label": "Khanu Woralaksaburi", "value": "C118646"},
                    {"label": "Lan Krabue", "value": "C118647"}],
 "Kampong Cham": [{"label": "Cheung Prey", "value": "C16469"},
                  {"label": "Kampong Cham", "value": "C16470"},
                  {"label": "Srok Batheay", "value": "C16471"}],
 "Kampong Chhnang": [{"label": "Baribour", "value": "C16472"},
                     {"label": "Kampong Chhnang", "value": "C16473"},
                     {"label": "Rolea Bier", "value": "C16474"},
                     {"label": "Srok Chol Kiri", "value": "C16475"},
                     {"label": "Srok Sameakki Mean Chey", "value": "C16476"}],
 "Kampong Speu": [{"label": "Kampong Speu", "value": "C16477"},
                  {"label": "Krong Chbar Mon", "value": "C16478"},
                  {"label": "Srok Basedth", "value": "C16479"},
                  {"label": "Srok Kong Pisei", "value": "C16480"},
                  {"label": "Srok Odongk", "value": "C16481"}],
 "Kampot": [{"label": "Angkor Chey", "value": "C16482"},
            {"label": "Banteay Meas", "value": "C16483"},
            {"label": "Chhouk District", "value": "C16484"},
            {"label": "Kampong Bay", "value": "C16485"},
            {"label": "Kampong Tranch", "value": "C16486"},
            {"label": "Kampot", "value": "C16487"},
            {"label": "Srok Chum Kiri", "value": "C16488"},
            {"label": "Srok Dangtong", "value": "C16489"},
            {"label": "Srok Tuek Chhou", "value": "C16490"}],
 "Kanagawa Prefecture": [{"label": "Atsugi", "value": "C63245"},
                         {"label": "Atsugi Shi", "value": "C63246"},
                         {"label": "Ayase Shi", "value": "C63247"},
                         {"label": "Chigasaki", "value": "C63248"},
                         {"label": "Chigasaki Shi", "value": "C63249"},
                         {"label": "Ebina Shi", "value": "C63250"},
                         {"label": "Fujisawa", "value": "C63251"},
                         {"label": "Fujisawa Shi", "value": "C63252"},
                         {"label": "Hadano", "value": "C63253"},
                         {"label": "Hadano-shi", "value": "C63254"},
                         {"label": "Hakone", "value": "C63255"},
                         {"label": "Hayama", "value": "C63256"},
                         {"label": "Hiratsuka", "value": "C63257"},
                         {"label": "Hiratsuka Shi", "value": "C63258"},
                         {"label": "Isehara", "value": "C63259"},
                         {"label": "Isehara Shi", "value": "C63260"},
                         {"label": "Kamakura", "value": "C63261"},
                         {"label": "Kamakura Shi", "value": "C63262"},
                         {"label": "Kawasaki", "value": "C63263"},
                         {"label": "Kawasaki-shi", "value": "C63264"},
                         {"label": "Minamiashigara", "value": "C63265"},
                         {"label": "Minamiashigara Shi", "value": "C63266"},
                         {"label": "Minamirinkan", "value": "C63267"},
                         {"label": "Miura", "value": "C63268"},
                         {"label": "Miura Shi", "value": "C63269"},
                         {"label": "Ninomiya", "value": "C63270"},
                         {"label": "Odawara", "value": "C63271"},
                         {"label": "Odawara-shi", "value": "C63272"},
                         {"label": "Oiso", "value": "C63273"},
                         {"label": "Sagamihara-shi", "value": "C63274"},
                         {"label": "Yamakita", "value": "C63275"},
                         {"label": "Yamato-shi", "value": "C63276"},
                         {"label": "Yokohama", "value": "C63277"},
                         {"label": "Yokohama Shi", "value": "C63278"},
                         {"label": "Yokosuka", "value": "C63279"},
                         {"label": "Yokosuka Shi", "value": "C63280"},
                         {"label": "Yugawara", "value": "C63281"},
                         {"label": "Zama", "value": "C63282"},
                         {"label": "Zama Shi", "value": "C63283"},
                         {"label": "Zushi Shi", "value": "C63284"}],
 "Kanal ob Soci Municipality": [{"label": "Deskle", "value": "C107657"},
                                {"label": "Kanal", "value": "C107658"}],
 "Kanchanaburi": [{"label": "Amphoe Bo Phloi", "value": "C118648"},
                  {"label": "Amphoe Dan Makham Tia", "value": "C118649"},
                  {"label": "Amphoe Huai Krachao", "value": "C118650"},
                  {"label": "Amphoe Lao Khwan", "value": "C118651"},
                  {"label": "Amphoe Mueang Kanchanaburi", "value": "C118652"},
                  {"label": "Amphoe Nong Prue", "value": "C118653"},
                  {"label": "Amphoe Phanom Thuan", "value": "C118654"},
                  {"label": "Amphoe Sai Yok", "value": "C118655"},
                  {"label": "Amphoe Sangkhla Buri", "value": "C118656"},
                  {"label": "Amphoe Si Sawat", "value": "C118657"},
                  {"label": "Amphoe Tha Maka", "value": "C118658"},
                  {"label": "Amphoe Tha Muang", "value": "C118659"},
                  {"label": "Amphoe Thong Pha Phum", "value": "C118660"},
                  {"label": "Bo Phloi", "value": "C118661"},
                  {"label": "Kanchanaburi", "value": "C118662"},
                  {"label": "Phanom Thuan", "value": "C118663"},
                  {"label": "Sangkhla Buri", "value": "C118664"},
                  {"label": "Tha Maka", "value": "C118665"},
                  {"label": "Tha Muang", "value": "C118666"}],
 "Kandahar": [{"label": "Kandahar", "value": "C51"}],
 "Kandal": [{"label": "Krong Ta Khmau", "value": "C16491"},
            {"label": "Srok Khsach Kandal", "value": "C16492"},
            {"label": "Ta Khmau", "value": "C16493"}],
 "Kandava Municipality": [{"label": "Kandava", "value": "C64882"}],
 "Kandy District": [{"label": "Akurana", "value": "C115273"},
                    {"label": "Alawatugoda", "value": "C115274"},
                    {"label": "Ambatenna", "value": "C115275"},
                    {"label": "Daskara", "value": "C115276"},
                    {"label": "Daulagala", "value": "C115277"},
                    {"label": "Delthota", "value": "C115278"},
                    {"label": "Doluwa", "value": "C115279"},
                    {"label": "Galagedara", "value": "C115280"},
                    {"label": "Galhinna", "value": "C115281"},
                    {"label": "Gampola", "value": "C115282"},
                    {"label": "Ganga Ihala Korale", "value": "C115283"},
                    {"label": "Hanguranketa", "value": "C115284"},
                    {"label": "Hapugastalawa", "value": "C115285"},
                    {"label": "Harispattuwa", "value": "C115286"},
                    {"label": "Hatharaliyadda", "value": "C115287"},
                    {"label": "Kadugannawa", "value": "C115288"},
                    {"label": "Kandy Four Gravets", "value": "C115289"},
                    {"label": "Katugastota", "value": "C115290"},
                    {"label": "Kundasale", "value": "C115291"},
                    {"label": "Madawala", "value": "C115292"},
                    {"label": "Medadumbara", "value": "C115293"},
                    {"label": "Menikdiwela", "value": "C115294"},
                    {"label": "Minipe", "value": "C115295"},
                    {"label": "Nawalapitiya", "value": "C115296"},
                    {"label": "Pallekele", "value": "C115297"},
                    {"label": "Panvila", "value": "C115298"},
                    {"label": "Pasbage Korale", "value": "C115299"},
                    {"label": "Pathadumbara", "value": "C115300"},
                    {"label": "Pathahewaheta", "value": "C115301"},
                    {"label": "Peradeniya", "value": "C115302"},
                    {"label": "Pilimathalawa", "value": "C115303"},
                    {"label": "Poojapitiya", "value": "C115304"},
                    {"label": "Pussellawa", "value": "C115305"},
                    {"label": "Talatuoya", "value": "C115306"},
                    {"label": "Teldeniya", "value": "C115307"},
                    {"label": "Thumpane", "value": "C115308"},
                    {"label": "Udadumbara", "value": "C115309"},
                    {"label": "Udapalatha", "value": "C115310"},
                    {"label": "Ududumbara", "value": "C115311"},
                    {"label": "Udunuwara", "value": "C115312"},
                    {"label": "Ulapane", "value": "C115313"},
                    {"label": "Watadeniya", "value": "C115314"},
                    {"label": "Wattegama", "value": "C115315"},
                    {"label": "Welamboda", "value": "C115316"},
                    {"label": "Yatinuwara", "value": "C115317"}],
 "Kanem Region": [{"label": "Mao", "value": "C17862"}],
 "Kangwon Province": [{"label": "Anbyon-up", "value": "C78540"},
                      {"label": "Hoeyang", "value": "C78541"},
                      {"label": "Kosan", "value": "C78542"},
                      {"label": "Tongchon-up", "value": "C78543"},
                      {"label": "Wonsan", "value": "C78544"}],
 "Kankan Region": [{"label": "Kankan", "value": "C43010"},
                   {"label": "Kankan Prefecture", "value": "C43011"},
                   {"label": "Kerouane", "value": "C43012"},
                   {"label": "Kerouane Prefecture", "value": "C43013"},
                   {"label": "Kouroussa", "value": "C43014"},
                   {"label": "Mandiana", "value": "C43015"},
                   {"label": "Mandiana Prefecture", "value": "C43016"},
                   {"label": "Siguiri", "value": "C43017"},
                   {"label": "Siguiri Prefecture", "value": "C43018"},
                   {"label": "Tokonou", "value": "C43019"}],
 "Kano": [{"label": "Dan Gora", "value": "C78318"},
          {"label": "Gaya", "value": "C78319"},
          {"label": "Kano", "value": "C78320"}],
 "Kansas": [{"label": "Abilene", "value": "C132968"},
            {"label": "Allen County", "value": "C132969"},
            {"label": "Alma", "value": "C132970"},
            {"label": "Altamont", "value": "C132971"},
            {"label": "Anderson County", "value": "C132972"},
            {"label": "Andover", "value": "C132973"},
            {"label": "Anthony", "value": "C132974"},
            {"label": "Arkansas City", "value": "C132975"},
            {"label": "Arma", "value": "C132976"},
            {"label": "Ashland", "value": "C132977"},
            {"label": "Atchison", "value": "C132978"},
            {"label": "Atchison County", "value": "C132979"},
            {"label": "Atwood", "value": "C132980"},
            {"label": "Auburn", "value": "C132981"},
            {"label": "Augusta", "value": "C132982"},
            {"label": "Baldwin City", "value": "C132983"},
            {"label": "Barber County", "value": "C132984"},
            {"label": "Barton County", "value": "C132985"},
            {"label": "Basehor", "value": "C132986"},
            {"label": "Baxter Springs", "value": "C132987"},
            {"label": "Bellaire", "value": "C132988"},
            {"label": "Belle Plaine", "value": "C132989"},
            {"label": "Belleville", "value": "C132990"},
            {"label": "Beloit", "value": "C132991"},
            {"label": "Bonner Springs", "value": "C132992"},
            {"label": "Bourbon County", "value": "C132993"},
            {"label": "Brown County", "value": "C132994"},
            {"label": "Buhler", "value": "C132995"},
            {"label": "Burlington", "value": "C132996"},
            {"label": "Butler County", "value": "C132997"},
            {"label": "Caldwell", "value": "C132998"},
            {"label": "Caney", "value": "C132999"},
            {"label": "Carbondale", "value": "C133000"},
            {"label": "Chanute", "value": "C133001"},
            {"label": "Chapman", "value": "C133002"},
            {"label": "Chase County", "value": "C133003"},
            {"label": "Chautauqua County", "value": "C133004"},
            {"label": "Cheney", "value": "C133005"},
            {"label": "Cherokee County", "value": "C133006"},
            {"label": "Cherryvale", "value": "C133007"},
            {"label": "Chetopa", "value": "C133008"},
            {"label": "Cheyenne County", "value": "C133009"},
            {"label": "Cimarron", "value": "C133010"},
            {"label": "Clark County", "value": "C133011"},
            {"label": "Clay Center", "value": "C133012"},
            {"label": "Clay County", "value": "C133013"},
            {"label": "Clearwater", "value": "C133014"},
            {"label": "Cloud County", "value": "C133015"},
            {"label": "Coffey County", "value": "C133016"},
            {"label": "Coffeyville", "value": "C133017"},
            {"label": "Colby", "value": "C133018"},
            {"label": "Coldwater", "value": "C133019"},
            {"label": "Columbus", "value": "C133020"},
            {"label": "Colwich", "value": "C133021"},
            {"label": "Comanche County", "value": "C133022"},
            {"label": "Concordia", "value": "C133023"},
            {"label": "Conway Springs", "value": "C133024"},
            {"label": "Cottonwood Falls", "value": "C133025"},
            {"label": "Council Grove", "value": "C133026"},
            {"label": "Cowley County", "value": "C133027"},
            {"label": "Crawford County", "value": "C133028"},
            {"label": "De Soto", "value": "C133029"},
            {"label": "Decatur County", "value": "C133030"},
            {"label": "Derby", "value": "C133031"},
            {"label": "Dickinson County", "value": "C133032"},
            {"label": "Dighton", "value": "C133033"},
            {"label": "Dodge City", "value": "C133034"},
            {"label": "Doniphan County", "value": "C133035"},
            {"label": "Douglas County", "value": "C133036"},
            {"label": "Douglass", "value": "C133037"},
            {"label": "Edgerton", "value": "C133038"},
            {"label": "Edwards County", "value": "C133039"},
            {"label": "Edwardsville", "value": "C133040"},
            {"label": "El Dorado", "value": "C133041"},
            {"label": "Elk County", "value": "C133042"},
            {"label": "Elkhart", "value": "C133043"},
            {"label": "Ellinwood", "value": "C133044"},
            {"label": "Ellis", "value": "C133045"},
            {"label": "Ellis County", "value": "C133046"},
            {"label": "Ellsworth", "value": "C133047"},
            {"label": "Ellsworth County", "value": "C133048"},
            {"label": "Elwood", "value": "C133049"},
            {"label": "Emporia", "value": "C133050"},
            {"label": "Erie", "value": "C133051"},
            {"label": "Eudora", "value": "C133052"},
            {"label": "Eureka", "value": "C133053"},
            {"label": "Fairway", "value": "C133054"},
            {"label": "Finney County", "value": "C133055"},
            {"label": "Ford County", "value": "C133056"},
            {"label": "Fort Riley North", "value": "C133057"},
            {"label": "Fort Scott", "value": "C133058"},
            {"label": "Franklin County", "value": "C133059"},
            {"label": "Fredonia", "value": "C133060"},
            {"label": "Frontenac", "value": "C133061"},
            {"label": "Galena", "value": "C133062"},
            {"label": "Garden City", "value": "C133063"},
            {"label": "Gardner", "value": "C133064"},
            {"label": "Garnett", "value": "C133065"},
            {"label": "Geary County", "value": "C133066"},
            {"label": "Girard", "value": "C133067"},
            {"label": "Goddard", "value": "C133068"},
            {"label": "Goodland", "value": "C133069"},
            {"label": "Gove", "value": "C133070"},
            {"label": "Gove County", "value": "C133071"},
            {"label": "Graham County", "value": "C133072"},
            {"label": "Grandview Plaza", "value": "C133073"},
            {"label": "Grant County", "value": "C133074"},
            {"label": "Gray County", "value": "C133075"},
            {"label": "Great Bend", "value": "C133076"},
            {"label": "Greeley County", "value": "C133077"},
            {"label": "Greensburg", "value": "C133078"},
            {"label": "Greenwood County", "value": "C133079"},
            {"label": "Halstead", "value": "C133080"},
            {"label": "Hamilton County", "value": "C133081"},
            {"label": "Harper", "value": "C133082"},
            {"label": "Harper County", "value": "C133083"},
            {"label": "Harvey County", "value": "C133084"},
            {"label": "Haskell County", "value": "C133085"},
            {"label": "Haven", "value": "C133086"},
            {"label": "Hays", "value": "C133087"},
            {"label": "Haysville", "value": "C133088"},
            {"label": "Herington", "value": "C133089"},
            {"label": "Hesston", "value": "C133090"},
            {"label": "Hiawatha", "value": "C133091"},
            {"label": "Highland", "value": "C133092"},
            {"label": "Hill City", "value": "C133093"},
            {"label": "Hillsboro", "value": "C133094"},
            {"label": "Hodgeman County", "value": "C133095"},
            {"label": "Hoisington", "value": "C133096"},
            {"label": "Holcomb", "value": "C133097"},
            {"label": "Holton", "value": "C133098"},
            {"label": "Horton", "value": "C133099"},
            {"label": "Howard", "value": "C133100"},
            {"label": "Hoxie", "value": "C133101"},
            {"label": "Hugoton", "value": "C133102"},
            {"label": "Humboldt", "value": "C133103"},
            {"label": "Hutchinson", "value": "C133104"},
            {"label": "Independence", "value": "C133105"},
            {"label": "Inman", "value": "C133106"},
            {"label": "Iola", "value": "C133107"},
            {"label": "Jackson County", "value": "C133108"},
            {"label": "Jefferson County", "value": "C133109"},
            {"label": "Jetmore", "value": "C133110"},
            {"label": "Jewell County", "value": "C133111"},
            {"label": "Johnson", "value": "C133112"},
            {"label": "Johnson County", "value": "C133113"},
            {"label": "Junction City", "value": "C133114"},
            {"label": "Kansas City", "value": "C133115"},
            {"label": "Kearny County", "value": "C133116"},
            {"label": "Kechi", "value": "C133117"},
            {"label": "Kingman", "value": "C133118"},
            {"label": "Kingman County", "value": "C133119"},
            {"label": "Kinsley", "value": "C133120"},
            {"label": "Kiowa", "value": "C133121"},
            {"label": "Kiowa County", "value": "C133122"},
            {"label": "La Crosse", "value": "C133123"},
            {"label": "La Cygne", "value": "C133124"},
            {"label": "Labette County", "value": "C133125"},
            {"label": "Lakin", "value": "C133126"},
            {"label": "Lane County", "value": "C133127"},
            {"label": "Lansing", "value": "C133128"},
            {"label": "Larned", "value": "C133129"},
            {"label": "Lawrence", "value": "C133130"},
            {"label": "Leavenworth", "value": "C133131"},
            {"label": "Leavenworth County", "value": "C133132"},
            {"label": "Leawood", "value": "C133133"},
            {"label": "Lenexa", "value": "C133134"},
            {"label": "Leoti", "value": "C133135"},
            {"label": "Liberal", "value": "C133136"},
            {"label": "Lincoln", "value": "C133137"},
            {"label": "Lincoln County", "value": "C133138"},
            {"label": "Lindsborg", "value": "C133139"},
            {"label": "Linn County", "value": "C133140"},
            {"label": "Logan County", "value": "C133141"},
            {"label": "Louisburg", "value": "C133142"},
            {"label": "Lyndon", "value": "C133143"},
            {"label": "Lyon County", "value": "C133144"},
            {"label": "Lyons", "value": "C133145"},
            {"label": "Maize", "value": "C133146"},
            {"label": "Manhattan", "value": "C133147"},
            {"label": "Mankato", "value": "C133148"},
            {"label": "Marion", "value": "C133149"},
            {"label": "Marion County", "value": "C133150"},
            {"label": "Marshall County", "value": "C133151"},
            {"label": "Marysville", "value": "C133152"},
            {"label": "McConnell AFB", "value": "C133153"},
            {"label": "McPherson", "value": "C133154"},
            {"label": "McPherson County", "value": "C133155"},
            {"label": "Meade", "value": "C133156"},
            {"label": "Meade County", "value": "C133157"},
            {"label": "Medicine Lodge", "value": "C133158"},
            {"label": "Merriam", "value": "C133159"},
            {"label": "Miami County", "value": "C133160"},
            {"label": "Minneapolis", "value": "C133161"},
            {"label": "Mission", "value": "C133162"},
            {"label": "Mission Hills", "value": "C133163"},
            {"label": "Mitchell County", "value": "C133164"},
            {"label": "Montgomery County", "value": "C133165"},
            {"label": "Morris County", "value": "C133166"},
            {"label": "Morton County", "value": "C133167"},
            {"label": "Mound City", "value": "C133168"},
            {"label": "Moundridge", "value": "C133169"},
            {"label": "Mulvane", "value": "C133170"},
            {"label": "Nemaha County", "value": "C133171"},
            {"label": "Neodesha", "value": "C133172"},
            {"label": "Neosho County", "value": "C133173"},
            {"label": "Ness City", "value": "C133174"},
            {"label": "Ness County", "value": "C133175"},
            {"label": "New Century, KS", "value": "C133176"},
            {"label": "Newton", "value": "C133177"},
            {"label": "Nickerson", "value": "C133178"},
            {"label": "North Newton", "value": "C133179"},
            {"label": "Norton", "value": "C133180"},
            {"label": "Norton County", "value": "C133181"},
            {"label": "Oakley", "value": "C133182"},
            {"label": "Oberlin", "value": "C133183"},
            {"label": "Ogden", "value": "C133184"},
            {"label": "Olathe", "value": "C133185"},
            {"label": "Osage City", "value": "C133186"},
            {"label": "Osage County", "value": "C133187"},
            {"label": "Osawatomie", "value": "C133188"},
            {"label": "Osborne", "value": "C133189"},
            {"label": "Osborne County", "value": "C133190"},
            {"label": "Oskaloosa", "value": "C133191"},
            {"label": "Oswego", "value": "C133192"},
            {"label": "Ottawa", "value": "C133193"},
            {"label": "Ottawa County", "value": "C133194"},
            {"label": "Overbrook", "value": "C133195"},
            {"label": "Overland Park", "value": "C133196"},
            {"label": "Oxford", "value": "C133197"},
            {"label": "Paola", "value": "C133198"},
            {"label": "Park City", "value": "C133199"},
            {"label": "Parsons", "value": "C133200"},
            {"label": "Pawnee County", "value": "C133201"},
            {"label": "Peabody", "value": "C133202"},
            {"label": "Phillips County", "value": "C133203"},
            {"label": "Phillipsburg", "value": "C133204"},
            {"label": "Pittsburg", "value": "C133205"},
            {"label": "Plains", "value": "C133206"},
            {"label": "Plainville", "value": "C133207"},
            {"label": "Pleasanton", "value": "C133208"},
            {"label": "Pottawatomie County", "value": "C133209"},
            {"label": "Prairie Village", "value": "C133210"},
            {"label": "Pratt", "value": "C133211"},
            {"label": "Pratt County", "value": "C133212"},
            {"label": "Rawlins County", "value": "C133213"},
            {"label": "Reno County", "value": "C133214"},
            {"label": "Republic County", "value": "C133215"},
            {"label": "Rice County", "value": "C133216"},
            {"label": "Riley County", "value": "C133217"},
            {"label": "Roeland Park", "value": "C133218"},
            {"label": "Rooks County", "value": "C133219"},
            {"label": "Rose Hill", "value": "C133220"},
            {"label": "Rossville", "value": "C133221"},
            {"label": "Rush County", "value": "C133222"},
            {"label": "Russell", "value": "C133223"},
            {"label": "Russell County", "value": "C133224"},
            {"label": "Sabetha", "value": "C133225"},
            {"label": "Saint Francis", "value": "C133226"},
            {"label": "Saint John", "value": "C133227"},
            {"label": "Saint Marys", "value": "C133228"},
            {"label": "Salina", "value": "C133229"},
            {"label": "Saline County", "value": "C133230"},
            {"label": "Satanta", "value": "C133231"},
            {"label": "Scott City", "value": "C133232"},
            {"label": "Scott County", "value": "C133233"},
            {"label": "Sedan", "value": "C133234"},
            {"label": "Sedgwick", "value": "C133235"},
            {"label": "Sedgwick County", "value": "C133236"},
            {"label": "Seneca", "value": "C133237"},
            {"label": "Seward County", "value": "C133238"},
            {"label": "Sharon Springs", "value": "C133239"},
            {"label": "Shawnee", "value": "C133240"},
            {"label": "Shawnee County", "value": "C133241"},
            {"label": "Sheridan County", "value": "C133242"},
            {"label": "Sherman County", "value": "C133243"},
            {"label": "Silver Lake", "value": "C133244"},
            {"label": "Smith Center", "value": "C133245"},
            {"label": "Smith County", "value": "C133246"},
            {"label": "Solomon", "value": "C133247"},
            {"label": "South Hutchinson", "value": "C133248"},
            {"label": "Spring Hill", "value": "C133249"},
            {"label": "Stafford County", "value": "C133250"},
            {"label": "Stanton County", "value": "C133251"},
            {"label": "Sterling", "value": "C133252"},
            {"label": "Stevens County", "value": "C133253"},
            {"label": "Stockton", "value": "C133254"},
            {"label": "Sublette", "value": "C133255"},
            {"label": "Sumner County", "value": "C133256"},
            {"label": "Syracuse", "value": "C133257"},
            {"label": "Thomas County", "value": "C133258"},
            {"label": "Tonganoxie", "value": "C133259"},
            {"label": "Topeka", "value": "C133260"},
            {"label": "Towanda", "value": "C133261"},
            {"label": "Trego County", "value": "C133262"},
            {"label": "Tribune", "value": "C133263"},
            {"label": "Troy", "value": "C133264"},
            {"label": "Ulysses", "value": "C133265"},
            {"label": "Valley Center", "value": "C133266"},
            {"label": "Valley Falls", "value": "C133267"},
            {"label": "Victoria", "value": "C133268"},
            {"label": "Wabaunsee County", "value": "C133269"},
            {"label": "WaKeeney", "value": "C133270"},
            {"label": "Wallace County", "value": "C133271"},
            {"label": "Wamego", "value": "C133272"},
            {"label": "Washington", "value": "C133273"},
            {"label": "Washington County", "value": "C133274"},
            {"label": "Wathena", "value": "C133275"},
            {"label": "Wellington", "value": "C133276"},
            {"label": "Wellsville", "value": "C133277"},
            {"label": "Westmoreland", "value": "C133278"},
            {"label": "Westwood", "value": "C133279"},
            {"label": "Wichita", "value": "C133280"},
            {"label": "Wichita County", "value": "C133281"},
            {"label": "Wilson County", "value": "C133282"},
            {"label": "Winfield", "value": "C133283"},
            {"label": "Woodson County", "value": "C133284"},
            {"label": "Wyandotte County", "value": "C133285"},
            {"label": "Yates Center", "value": "C133286"}],
 "Kaohsiung": [{"label": "Kaohsiung", "value": "C117964"}],
 "Kaolack": [{"label": "Gandiaye", "value": "C106843"},
             {"label": "Kaolack", "value": "C106844"},
             {"label": "Ndofane", "value": "C106845"},
             {"label": "Nioro du Rip", "value": "C106846"}],
 "Kapisa": [{"label": "Sidqabad", "value": "C52"}],
 "Kara Region": [{"label": "Bafilo", "value": "C119628"},
                 {"label": "Bassar", "value": "C119629"},
                 {"label": "Kande", "value": "C119630"},
                 {"label": "Kara", "value": "C119631"},
                 {"label": "Niamtougou", "value": "C119632"},
                 {"label": "Pagouda", "value": "C119633"},
                 {"label": "Prefecture de Bassar", "value": "C119634"}],
 "Karabuk": [{"label": "Eflani", "value": "C120519"},
             {"label": "Eskipazar Ilcesi", "value": "C120520"},
             {"label": "Gozyeri", "value": "C120521"},
             {"label": "Karabuk", "value": "C120522"},
             {"label": "Ovacik Ilcesi", "value": "C120523"},
             {"label": "Safranbolu", "value": "C120524"},
             {"label": "Yenice", "value": "C120525"}],
 "Karachay-Cherkess Republic": [{"label": "Adyge-Khabl", "value": "C101165"},
                                {"label": "Ali-Berdukovskiy",
                                 "value": "C101166"},
                                {"label": "Besleney", "value": "C101167"},
                                {"label": "Cherkessk", "value": "C101168"},
                                {"label": "Druzhba", "value": "C101169"},
                                {"label": "Ikon-Khalk", "value": "C101170"},
                                {"label": "Ispravnaya", "value": "C101171"},
                                {"label": "Karachayevsk", "value": "C101172"},
                                {"label": "Karachayevskiy Rayon",
                                 "value": "C101173"},
                                {"label": "Kardonikskaya", "value": "C101174"},
                                {"label": "Kavkazskiy", "value": "C101175"},
                                {"label": "Khabez", "value": "C101176"},
                                {"label": "Krasnyy Kurgan", "value": "C101177"},
                                {"label": "Kumysh", "value": "C101178"},
                                {"label": "Kurdzhinovo", "value": "C101179"},
                                {"label": "Kyzyl-Oktyabrskiy",
                                 "value": "C101180"},
                                {"label": "Malokarachayevskiy Rayon",
                                 "value": "C101181"},
                                {"label": "Mednogorskiy", "value": "C101182"},
                                {"label": "Nizhniy Arkhyz", "value": "C101183"},
                                {"label": "Novyy Karachay", "value": "C101184"},
                                {"label": "Ordzhonikidzevskiy",
                                 "value": "C101185"},
                                {"label": "Pervomayskoye", "value": "C101186"},
                                {"label": "Pravokubanskiy", "value": "C101187"},
                                {"label": "Pregradnaya", "value": "C101188"},
                                {"label": "Prikubanskiy Rayon",
                                 "value": "C101189"},
                                {"label": "Psyzh", "value": "C101190"},
                                {"label": "Sary-Tyuz", "value": "C101191"},
                                {"label": "Storozhevaya", "value": "C101192"},
                                {"label": "Tereze", "value": "C101193"},
                                {"label": "Uchkeken", "value": "C101194"},
                                {"label": "Uchkulan", "value": "C101195"},
                                {"label": "Udarnyy", "value": "C101196"},
                                {"label": "Ust-Dzheguta", "value": "C101197"},
                                {"label": "Zelenchukskaya",
                                 "value": "C101198"}],
 "Karaganda Region": [{"label": "Abay", "value": "C64393"},
                      {"label": "Abay Qalasy", "value": "C64394"},
                      {"label": "Aksu-Ayuly", "value": "C64395"},
                      {"label": "Aktas", "value": "C64396"},
                      {"label": "Aktau", "value": "C64397"},
                      {"label": "Aktogay", "value": "C64398"},
                      {"label": "Aqadyr", "value": "C64399"},
                      {"label": "Aqshatau", "value": "C64400"},
                      {"label": "Aqtoghay Audany", "value": "C64401"},
                      {"label": "Atasu", "value": "C64402"},
                      {"label": "Balqash", "value": "C64403"},
                      {"label": "Bukhar-Zhyrau", "value": "C64404"},
                      {"label": "Dolinka", "value": "C64405"},
                      {"label": "Karagandy", "value": "C64406"},
                      {"label": "Kievka", "value": "C64407"},
                      {"label": "Koktal", "value": "C64408"},
                      {"label": "Kushoky", "value": "C64409"},
                      {"label": "Kyzylzhar", "value": "C64410"},
                      {"label": "Moyynty", "value": "C64411"},
                      {"label": "Novodolinskiy", "value": "C64412"},
                      {"label": "Osakarovka", "value": "C64413"},
                      {"label": "Prigorodnoye", "value": "C64414"},
                      {"label": "Priozersk", "value": "C64415"},
                      {"label": "Qarazhal", "value": "C64416"},
                      {"label": "Qarqaraly", "value": "C64417"},
                      {"label": "Saryshaghan", "value": "C64418"},
                      {"label": "Sayaq", "value": "C64419"},
                      {"label": "Shakhan", "value": "C64420"},
                      {"label": "Shakhtinsk", "value": "C64421"},
                      {"label": "Shashubay", "value": "C64422"},
                      {"label": "Shubarkol", "value": "C64423"},
                      {"label": "Soran", "value": "C64424"},
                      {"label": "Temirtau", "value": "C64425"},
                      {"label": "Tokarevka", "value": "C64426"},
                      {"label": "Verkhniye Kayrakty", "value": "C64427"},
                      {"label": "Zhambyl", "value": "C64428"},
                      {"label": "Zharyk", "value": "C64429"},
                      {"label": "Zhezqazghan", "value": "C64430"}],
 "Karak Governorate": [{"label": "Ayy", "value": "C64249"},
                       {"label": "Izra", "value": "C64250"},
                       {"label": "Adir", "value": "C64251"},
                       {"label": "Al Khinzirah", "value": "C64252"},
                       {"label": "Al Mazar al Janubi", "value": "C64253"},
                       {"label": "Al Qasr", "value": "C64254"},
                       {"label": "Ar Rabbah", "value": "C64255"},
                       {"label": "Karak City", "value": "C64256"},
                       {"label": "Safi", "value": "C64257"}],
 "Karakalpakstan": [{"label": "Beruniy", "value": "C147037"},
                    {"label": "Kegeyli Shahar", "value": "C147038"},
                    {"label": "Khujayli", "value": "C147039"},
                    {"label": "Manghit", "value": "C147040"},
                    {"label": "Muynoq", "value": "C147041"},
                    {"label": "Novyy Turtkul", "value": "C147042"},
                    {"label": "Nukus", "value": "C147043"},
                    {"label": "Oltinkol", "value": "C147044"}],
 "Karaman": [{"label": "Ayranci Ilcesi", "value": "C120526"},
             {"label": "Basyayla", "value": "C120527"},
             {"label": "Ermenek", "value": "C120528"},
             {"label": "Inonu", "value": "C120529"},
             {"label": "Karaman", "value": "C120530"},
             {"label": "Kazimkarabekir", "value": "C120531"},
             {"label": "Sariveliler", "value": "C120532"}],
 "Karas Region": [{"label": "Bethanie", "value": "C75978"},
                  {"label": "Karasburg", "value": "C75979"},
                  {"label": "Keetmanshoop", "value": "C75980"},
                  {"label": "Luderitz", "value": "C75981"},
                  {"label": "Oranjemund", "value": "C75982"},
                  {"label": "Tses", "value": "C75983"},
                  {"label": "Warmbad", "value": "C75984"}],
 "Karbala Governorate": [{"label": "Al Hindiyah", "value": "C51183"},
                         {"label": "Karbala", "value": "C51184"}],
 "Karbinci": [{"label": "Karbinci", "value": "C65425"}],
 "Kardzhali Province": [{"label": "Ardino", "value": "C15949"},
                        {"label": "Dzhebel", "value": "C15950"},
                        {"label": "Kardzhali", "value": "C15951"},
                        {"label": "Kirkovo", "value": "C15952"},
                        {"label": "Krumovgrad", "value": "C15953"},
                        {"label": "Obshtina Ardino", "value": "C15954"},
                        {"label": "Obshtina Chernoochene", "value": "C15955"},
                        {"label": "Obshtina Dzhebel", "value": "C15956"},
                        {"label": "Obshtina Kardzhali", "value": "C15957"},
                        {"label": "Obshtina Kirkovo", "value": "C15958"},
                        {"label": "Obshtina Momchilgrad", "value": "C15959"}],
 "Karlovarsky kraj": [{"label": "Abertamy", "value": "C22226"},
                      {"label": "As", "value": "C22227"},
                      {"label": "Bochov", "value": "C22228"},
                      {"label": "Brezova", "value": "C22229"},
                      {"label": "Bukovany", "value": "C22230"},
                      {"label": "Cheb", "value": "C22231"},
                      {"label": "Chodov", "value": "C22232"},
                      {"label": "Dalovice", "value": "C22233"},
                      {"label": "Dolni Rychnov", "value": "C22234"},
                      {"label": "Dolni Zandov", "value": "C22235"},
                      {"label": "Frantiskovy Lazne", "value": "C22236"},
                      {"label": "Habartov", "value": "C22237"},
                      {"label": "Hazlov", "value": "C22238"},
                      {"label": "Horni Slavkov", "value": "C22239"},
                      {"label": "Hranice", "value": "C22240"},
                      {"label": "Hroznetin", "value": "C22241"},
                      {"label": "Jachymov", "value": "C22242"},
                      {"label": "Karlovy Vary", "value": "C22243"},
                      {"label": "Klaster", "value": "C22244"},
                      {"label": "Kraslice", "value": "C22245"},
                      {"label": "Kynsperk nad Ohri", "value": "C22246"},
                      {"label": "Lazne Kynzvart", "value": "C22247"},
                      {"label": "Loket", "value": "C22248"},
                      {"label": "Lomnice", "value": "C22249"},
                      {"label": "Luby", "value": "C22250"},
                      {"label": "Marianske Lazne", "value": "C22251"},
                      {"label": "Merklin", "value": "C22252"},
                      {"label": "Mesto", "value": "C22253"},
                      {"label": "Nejdek", "value": "C22254"},
                      {"label": "Nova Role", "value": "C22255"},
                      {"label": "Nove Sedlo", "value": "C22256"},
                      {"label": "Okres Cheb", "value": "C22257"},
                      {"label": "Okres Karlovy Vary", "value": "C22258"},
                      {"label": "Okres Sokolov", "value": "C22259"},
                      {"label": "Olovi", "value": "C22260"},
                      {"label": "Ostrov", "value": "C22261"},
                      {"label": "Plesna", "value": "C22262"},
                      {"label": "Rotava", "value": "C22263"},
                      {"label": "Sadov", "value": "C22264"},
                      {"label": "Skalna", "value": "C22265"},
                      {"label": "Sokolov", "value": "C22266"},
                      {"label": "Svatava", "value": "C22267"},
                      {"label": "Touzim", "value": "C22268"},
                      {"label": "Velka Hledsebe", "value": "C22269"},
                      {"label": "Vintirov", "value": "C22270"},
                      {"label": "Zlutice", "value": "C22271"}],
 "Karnataka": [{"label": "Afzalpur", "value": "C46052"},
               {"label": "Ajjampur", "value": "C46053"},
               {"label": "Aland", "value": "C46054"},
               {"label": "Alnavar", "value": "C46055"},
               {"label": "Alur", "value": "C46056"},
               {"label": "Anekal", "value": "C46057"},
               {"label": "Ankola", "value": "C46058"},
               {"label": "Annigeri", "value": "C46059"},
               {"label": "Arkalgud", "value": "C46060"},
               {"label": "Arsikere", "value": "C46061"},
               {"label": "Athni", "value": "C46062"},
               {"label": "Aurad", "value": "C46063"},
               {"label": "Badami", "value": "C46064"},
               {"label": "Bagalkot", "value": "C46065"},
               {"label": "Bagepalli", "value": "C46066"},
               {"label": "Bail-Hongal", "value": "C46067"},
               {"label": "Ballari", "value": "C46068"},
               {"label": "Ballary", "value": "C46069"},
               {"label": "Banavar", "value": "C46070"},
               {"label": "Bangalore Rural", "value": "C46071"},
               {"label": "Bangalore Urban", "value": "C46072"},
               {"label": "Bangarapet", "value": "C46073"},
               {"label": "Bannur", "value": "C46074"},
               {"label": "Bantval", "value": "C46075"},
               {"label": "Basavakalyan", "value": "C46076"},
               {"label": "Basavana Bagevadi", "value": "C46077"},
               {"label": "Belagavi", "value": "C46078"},
               {"label": "Belluru", "value": "C46079"},
               {"label": "Beltangadi", "value": "C46080"},
               {"label": "Belur", "value": "C46081"},
               {"label": "Bengaluru", "value": "C46082"},
               {"label": "Bhadravati", "value": "C46083"},
               {"label": "Bhalki", "value": "C46084"},
               {"label": "Bhatkal", "value": "C46085"},
               {"label": "Bidar", "value": "C46086"},
               {"label": "Bilgi", "value": "C46087"},
               {"label": "Birur", "value": "C46088"},
               {"label": "Byadgi", "value": "C46089"},
               {"label": "Byndoor", "value": "C46090"},
               {"label": "Canacona", "value": "C46091"},
               {"label": "Challakere", "value": "C46092"},
               {"label": "Chamrajnagar", "value": "C46093"},
               {"label": "Channagiri", "value": "C46094"},
               {"label": "Channapatna", "value": "C46095"},
               {"label": "Channarayapatna", "value": "C46096"},
               {"label": "Chik Ballapur", "value": "C46097"},
               {"label": "Chikkaballapur", "value": "C46098"},
               {"label": "Chikkamagaluru", "value": "C46099"},
               {"label": "Chiknayakanhalli", "value": "C46100"},
               {"label": "Chikodi", "value": "C46101"},
               {"label": "Chincholi", "value": "C46102"},
               {"label": "Chintamani", "value": "C46103"},
               {"label": "Chitapur", "value": "C46104"},
               {"label": "Chitradurga", "value": "C46105"},
               {"label": "Closepet", "value": "C46106"},
               {"label": "Coondapoor", "value": "C46107"},
               {"label": "Dakshina Kannada", "value": "C46108"},
               {"label": "Dandeli", "value": "C46109"},
               {"label": "Davanagere", "value": "C46110"},
               {"label": "Devanhalli", "value": "C46111"},
               {"label": "Dharwad", "value": "C46112"},
               {"label": "Dod Ballapur", "value": "C46113"},
               {"label": "French Rocks", "value": "C46114"},
               {"label": "Gadag", "value": "C46115"},
               {"label": "Gadag-Betageri", "value": "C46116"},
               {"label": "Gajendragarh", "value": "C46117"},
               {"label": "Gangawati", "value": "C46118"},
               {"label": "Gangolli", "value": "C46119"},
               {"label": "Gokak", "value": "C46120"},
               {"label": "Gokarna", "value": "C46121"},
               {"label": "Goribidnur", "value": "C46122"},
               {"label": "Gorur", "value": "C46123"},
               {"label": "Gubbi", "value": "C46124"},
               {"label": "Gudibanda", "value": "C46125"},
               {"label": "Guledagudda", "value": "C46126"},
               {"label": "Gundlupet", "value": "C46127"},
               {"label": "Gurmatkal", "value": "C46128"},
               {"label": "Hadagalli", "value": "C46129"},
               {"label": "Haliyal", "value": "C46130"},
               {"label": "Hampi", "value": "C46131"},
               {"label": "Hangal", "value": "C46132"},
               {"label": "Harihar", "value": "C46133"},
               {"label": "Harpanahalli", "value": "C46134"},
               {"label": "Hassan", "value": "C46135"},
               {"label": "Haveri", "value": "C46136"},
               {"label": "Heggadadevankote", "value": "C46137"},
               {"label": "Hirekerur", "value": "C46138"},
               {"label": "Hiriyur", "value": "C46139"},
               {"label": "Holalkere", "value": "C46140"},
               {"label": "Hole Narsipur", "value": "C46141"},
               {"label": "Homnabad", "value": "C46142"},
               {"label": "Honavar", "value": "C46143"},
               {"label": "Honnali", "value": "C46144"},
               {"label": "Hosanagara", "value": "C46145"},
               {"label": "Hosangadi", "value": "C46146"},
               {"label": "Hosdurga", "value": "C46147"},
               {"label": "Hoskote", "value": "C46148"},
               {"label": "Hospet", "value": "C46149"},
               {"label": "Hubballi", "value": "C46150"},
               {"label": "Hukeri", "value": "C46151"},
               {"label": "Hungund", "value": "C46152"},
               {"label": "Hunsur", "value": "C46153"},
               {"label": "Ilkal", "value": "C46154"},
               {"label": "Indi", "value": "C46155"},
               {"label": "Jagalur", "value": "C46156"},
               {"label": "Jamkhandi", "value": "C46157"},
               {"label": "Jevargi", "value": "C46158"},
               {"label": "Kadur", "value": "C46159"},
               {"label": "Kalaburgi", "value": "C46160"},
               {"label": "Kalghatgi", "value": "C46161"},
               {"label": "Kampli", "value": "C46162"},
               {"label": "Kankanhalli", "value": "C46163"},
               {"label": "Karkala", "value": "C46164"},
               {"label": "Karwar", "value": "C46165"},
               {"label": "Kavalur", "value": "C46166"},
               {"label": "Kerur", "value": "C46167"},
               {"label": "Khanapur", "value": "C46168"},
               {"label": "Kodagu", "value": "C46169"},
               {"label": "Kodigenahalli", "value": "C46170"},
               {"label": "Kodlipet", "value": "C46171"},
               {"label": "Kolar", "value": "C46172"},
               {"label": "Kollegal", "value": "C46173"},
               {"label": "Konanur", "value": "C46174"},
               {"label": "Konnur", "value": "C46175"},
               {"label": "Koppa", "value": "C46176"},
               {"label": "Koppal", "value": "C46177"},
               {"label": "Koratagere", "value": "C46178"},
               {"label": "Kotturu", "value": "C46179"},
               {"label": "Krishnarajpet", "value": "C46180"},
               {"label": "Kudachi", "value": "C46181"},
               {"label": "Kudligi", "value": "C46182"},
               {"label": "Kumsi", "value": "C46183"},
               {"label": "Kumta", "value": "C46184"},
               {"label": "Kundgol", "value": "C46185"},
               {"label": "Kunigal", "value": "C46186"},
               {"label": "Kurgunta", "value": "C46187"},
               {"label": "Kushalnagar", "value": "C46188"},
               {"label": "Kushtagi", "value": "C46189"},
               {"label": "Lakshmeshwar", "value": "C46190"},
               {"label": "Lingsugur", "value": "C46191"},
               {"label": "Londa", "value": "C46192"},
               {"label": "Maddagiri", "value": "C46193"},
               {"label": "Maddur", "value": "C46194"},
               {"label": "Madikeri", "value": "C46195"},
               {"label": "Magadi", "value": "C46196"},
               {"label": "Mahalingpur", "value": "C46197"},
               {"label": "Malavalli", "value": "C46198"},
               {"label": "Malpe", "value": "C46199"},
               {"label": "Malur", "value": "C46200"},
               {"label": "Mandya", "value": "C46201"},
               {"label": "Mangaluru", "value": "C46202"},
               {"label": "Manipal", "value": "C46203"},
               {"label": "Manvi", "value": "C46204"},
               {"label": "Mayakonda", "value": "C46205"},
               {"label": "Melukote", "value": "C46206"},
               {"label": "Mudbidri", "value": "C46207"},
               {"label": "Muddebihal", "value": "C46208"},
               {"label": "Mudgal", "value": "C46209"},
               {"label": "Mudgere", "value": "C46210"},
               {"label": "Mudhol", "value": "C46211"},
               {"label": "Mulbagal", "value": "C46212"},
               {"label": "Mulgund", "value": "C46213"},
               {"label": "Mulki", "value": "C46214"},
               {"label": "Mundargi", "value": "C46215"},
               {"label": "Mundgod", "value": "C46216"},
               {"label": "Munirabad", "value": "C46217"},
               {"label": "Murudeshwara", "value": "C46218"},
               {"label": "Mysuru", "value": "C46219"},
               {"label": "Nagamangala", "value": "C46220"},
               {"label": "Nanjangud", "value": "C46221"},
               {"label": "Narasimharajapura", "value": "C46222"},
               {"label": "Naregal", "value": "C46223"},
               {"label": "Nargund", "value": "C46224"},
               {"label": "Navalgund", "value": "C46225"},
               {"label": "Nelamangala", "value": "C46226"},
               {"label": "Nyamti", "value": "C46227"},
               {"label": "Pangala", "value": "C46228"},
               {"label": "Pavugada", "value": "C46229"},
               {"label": "Piriyapatna", "value": "C46230"},
               {"label": "Ponnampet", "value": "C46231"},
               {"label": "Puttur", "value": "C46232"},
               {"label": "Rabkavi", "value": "C46233"},
               {"label": "Raichur", "value": "C46234"},
               {"label": "Ramanagara", "value": "C46235"},
               {"label": "Ranibennur", "value": "C46236"},
               {"label": "Raybag", "value": "C46237"},
               {"label": "Robertsonpet", "value": "C46238"},
               {"label": "Ron", "value": "C46239"},
               {"label": "Sadalgi", "value": "C46240"},
               {"label": "Sagar", "value": "C46241"},
               {"label": "Sakleshpur", "value": "C46242"},
               {"label": "Sandur", "value": "C46243"},
               {"label": "Sanivarsante", "value": "C46244"},
               {"label": "Sankeshwar", "value": "C46245"},
               {"label": "Sargur", "value": "C46246"},
               {"label": "Saundatti", "value": "C46247"},
               {"label": "Savanur", "value": "C46248"},
               {"label": "Seram", "value": "C46249"},
               {"label": "Shahabad", "value": "C46250"},
               {"label": "Shahpur", "value": "C46251"},
               {"label": "Shiggaon", "value": "C46252"},
               {"label": "Shikarpur", "value": "C46253"},
               {"label": "Shimoga", "value": "C46254"},
               {"label": "Shirhatti", "value": "C46255"},
               {"label": "Shorapur", "value": "C46256"},
               {"label": "Shrirangapattana", "value": "C46257"},
               {"label": "Siddapur", "value": "C46258"},
               {"label": "Sidlaghatta", "value": "C46259"},
               {"label": "Sindgi", "value": "C46260"},
               {"label": "Sindhnur", "value": "C46261"},
               {"label": "Sira", "value": "C46262"},
               {"label": "Sirsi", "value": "C46263"},
               {"label": "Siruguppa", "value": "C46264"},
               {"label": "Someshwar", "value": "C46265"},
               {"label": "Somvarpet", "value": "C46266"},
               {"label": "Sorab", "value": "C46267"},
               {"label": "Sravana Belgola", "value": "C46268"},
               {"label": "Sringeri", "value": "C46269"},
               {"label": "Srinivaspur", "value": "C46270"},
               {"label": "Sulya", "value": "C46271"},
               {"label": "Suntikoppa", "value": "C46272"},
               {"label": "Talikota", "value": "C46273"},
               {"label": "Tarikere", "value": "C46274"},
               {"label": "Tekkalakote", "value": "C46275"},
               {"label": "Terdal", "value": "C46276"},
               {"label": "Tiptur", "value": "C46277"},
               {"label": "Tirthahalli", "value": "C46278"},
               {"label": "Tirumakudal Narsipur", "value": "C46279"},
               {"label": "Tumakuru", "value": "C46280"},
               {"label": "Turuvekere", "value": "C46281"},
               {"label": "Udupi", "value": "C46282"},
               {"label": "Ullal", "value": "C46283"},
               {"label": "Uttar Kannada", "value": "C46284"},
               {"label": "Vadigenhalli", "value": "C46285"},
               {"label": "Vijayapura", "value": "C46286"},
               {"label": "Virarajendrapet", "value": "C46287"},
               {"label": "Wadi", "value": "C46288"},
               {"label": "Yadgir", "value": "C46289"},
               {"label": "Yelahanka", "value": "C46290"},
               {"label": "Yelandur", "value": "C46291"},
               {"label": "Yelbarga", "value": "C46292"},
               {"label": "Yellapur", "value": "C46293"}],
 "Karpos Municipality": [{"label": "Skopje", "value": "C65426"}],
 "Kars": [{"label": "Akyaka", "value": "C120533"},
          {"label": "Arpacay", "value": "C120534"},
          {"label": "Digor Ilcesi", "value": "C120535"},
          {"label": "Kagizman", "value": "C120536"},
          {"label": "Kars", "value": "C120537"},
          {"label": "Sarikamis", "value": "C120538"},
          {"label": "Selim", "value": "C120539"},
          {"label": "Susuz", "value": "C120540"}],
 "Karsava Municipality": [{"label": "Karsava", "value": "C64883"}],
 "Karuzi Province": [{"label": "Karuzi", "value": "C16448"}],
 "Kasai": [{"label": "Ilebo", "value": "C23326"},
           {"label": "Luebo", "value": "C23327"},
           {"label": "Mweka", "value": "C23328"},
           {"label": "Tshikapa", "value": "C23329"}],
 "Kasai Oriental": [{"label": "Gandajika", "value": "C23330"},
                    {"label": "Kabinda", "value": "C23331"},
                    {"label": "Mbuji-Mayi", "value": "C23332"}],
 "Kassala": [{"label": "Aroma", "value": "C115404"},
             {"label": "Kassala", "value": "C115405"},
             {"label": "Wagar", "value": "C115406"}],
 "Kasserine Governorate": [{"label": "Kasserine", "value": "C119716"},
                           {"label": "Rohia", "value": "C119717"},
                           {"label": "Sbiba", "value": "C119718"},
                           {"label": "Thala", "value": "C119719"}],
 "Kassrine": [{"label": "Beja", "value": "C119720"},
              {"label": "Delegation de Beja Nord", "value": "C119721"},
              {"label": "Goubellat", "value": "C119722"},
              {"label": "Medjez el Bab", "value": "C119723"},
              {"label": "Tabursuq", "value": "C119724"},
              {"label": "Testour", "value": "C119725"}],
 "Kastamonu": [{"label": "Abana", "value": "C120541"},
               {"label": "Agli Ilcesi", "value": "C120542"},
               {"label": "Arac", "value": "C120543"},
               {"label": "Azdavay", "value": "C120544"},
               {"label": "Bozkurt", "value": "C120545"},
               {"label": "Catalzeytin Ilcesi", "value": "C120546"},
               {"label": "Cide", "value": "C120547"},
               {"label": "Daday", "value": "C120548"},
               {"label": "Devrekani", "value": "C120549"},
               {"label": "Doganyurt", "value": "C120550"},
               {"label": "Hanonu", "value": "C120551"},
               {"label": "Ihsangazi", "value": "C120552"},
               {"label": "Inebolu", "value": "C120553"},
               {"label": "Kastamonu", "value": "C120554"},
               {"label": "Kure", "value": "C120555"},
               {"label": "Pinarbasi", "value": "C120556"},
               {"label": "Senpazar Ilcesi", "value": "C120557"},
               {"label": "Seydiler", "value": "C120558"},
               {"label": "Taskopru", "value": "C120559"},
               {"label": "Tosya", "value": "C120560"}],
 "Katavi": [{"label": "Inyonga", "value": "C118116"},
            {"label": "Karema", "value": "C118117"},
            {"label": "Mpanda", "value": "C118118"},
            {"label": "Usevia", "value": "C118119"}],
 "Katsina": [{"label": "Danja", "value": "C78321"},
             {"label": "Dankama", "value": "C78322"},
             {"label": "Daura", "value": "C78323"},
             {"label": "Dutsin-Ma", "value": "C78324"},
             {"label": "Funtua", "value": "C78325"},
             {"label": "Gora", "value": "C78326"},
             {"label": "Jibia", "value": "C78327"},
             {"label": "Jikamshi", "value": "C78328"},
             {"label": "Kankara", "value": "C78329"},
             {"label": "Katsina", "value": "C78330"},
             {"label": "Mashi", "value": "C78331"},
             {"label": "Ruma", "value": "C78332"},
             {"label": "Runka", "value": "C78333"},
             {"label": "Wagini", "value": "C78334"}],
 "Kaunas City Municipality": [{"label": "Akademija (Kaunas)",
                               "value": "C65089"},
                              {"label": "Aleksotas", "value": "C65090"},
                              {"label": "Ariogala", "value": "C65091"},
                              {"label": "Birstonas", "value": "C65092"},
                              {"label": "Dainava (Kaunas)", "value": "C65093"},
                              {"label": "Eiguliai", "value": "C65094"},
                              {"label": "Ezerelis", "value": "C65095"},
                              {"label": "Garliava", "value": "C65096"},
                              {"label": "Jieznas", "value": "C65097"},
                              {"label": "Jonava", "value": "C65098"},
                              {"label": "Kaisiadorys", "value": "C65099"},
                              {"label": "Karmelava", "value": "C65100"},
                              {"label": "Kaunas", "value": "C65101"},
                              {"label": "Kedainiai", "value": "C65102"},
                              {"label": "Kulautuva", "value": "C65103"},
                              {"label": "Mastaiciai", "value": "C65104"},
                              {"label": "Prienai", "value": "C65105"},
                              {"label": "Ramuciai", "value": "C65106"},
                              {"label": "Raseiniai", "value": "C65107"},
                              {"label": "Sargenai", "value": "C65108"},
                              {"label": "Silainiai", "value": "C65109"},
                              {"label": "Vilkija", "value": "C65110"},
                              {"label": "Ziezmariai", "value": "C65111"}],
 "Kavadarci Municipality": [{"label": "Kavadarci", "value": "C65427"},
                            {"label": "Vatasa", "value": "C65428"}],
 "Kavango East Region": [{"label": "Rundu", "value": "C75985"}],
 "Kayah State": [{"label": "Loikaw", "value": "C75913"}],
 "Kayangel": [{"label": "Kayangel", "value": "C79774"}],
 "Kayanza Province": [{"label": "Kayanza", "value": "C16449"}],
 "Kayes Region": [{"label": "Bafoulabe", "value": "C65828"},
                  {"label": "Kayes", "value": "C65829"},
                  {"label": "Kita", "value": "C65830"},
                  {"label": "Kokofata", "value": "C65831"},
                  {"label": "Sagalo", "value": "C65832"}],
 "Kayin State": [{"label": "Dellok", "value": "C75914"},
                 {"label": "Hpa-An", "value": "C75915"},
                 {"label": "Klonhtoug", "value": "C75916"},
                 {"label": "Kyain Seikgyi Township", "value": "C75917"},
                 {"label": "Mikenaungea", "value": "C75918"},
                 {"label": "Myawadi", "value": "C75919"},
                 {"label": "Pulei", "value": "C75920"},
                 {"label": "Tagondaing", "value": "C75921"},
                 {"label": "Tamoowoug", "value": "C75922"}],
 "Kayseri": [{"label": "Akkisla", "value": "C120561"},
             {"label": "Bunyan", "value": "C120562"},
             {"label": "Develi", "value": "C120563"},
             {"label": "Felahiye", "value": "C120564"},
             {"label": "Hacilar", "value": "C120565"},
             {"label": "Incesu", "value": "C120566"},
             {"label": "Kayseri", "value": "C120567"},
             {"label": "Kocasinan", "value": "C120568"},
             {"label": "Melikgazi", "value": "C120569"},
             {"label": "Ozvatan Ilcesi", "value": "C120570"},
             {"label": "Pinarbasi", "value": "C120571"},
             {"label": "Sarioglan", "value": "C120572"},
             {"label": "Sariz", "value": "C120573"},
             {"label": "Talas", "value": "C120574"},
             {"label": "Tomarza", "value": "C120575"},
             {"label": "Yahyali", "value": "C120576"},
             {"label": "Yahyali", "value": "C120577"},
             {"label": "Yesilhisar", "value": "C120578"}],
 "Kebbi": [{"label": "Argungu", "value": "C78335"},
           {"label": "Bagudo", "value": "C78336"},
           {"label": "Bena", "value": "C78337"},
           {"label": "Bin Yauri", "value": "C78338"},
           {"label": "Birnin Kebbi", "value": "C78339"},
           {"label": "Dabai", "value": "C78340"},
           {"label": "Dakingari", "value": "C78341"},
           {"label": "Gulma", "value": "C78342"},
           {"label": "Gwandu", "value": "C78343"},
           {"label": "Jega", "value": "C78344"},
           {"label": "Kamba", "value": "C78345"},
           {"label": "Kangiwa", "value": "C78346"},
           {"label": "Kende", "value": "C78347"},
           {"label": "Mahuta", "value": "C78348"},
           {"label": "Maiyama", "value": "C78349"},
           {"label": "Shanga", "value": "C78350"},
           {"label": "Wasagu", "value": "C78351"},
           {"label": "Zuru", "value": "C78352"}],
 "Kebili Governorate": [{"label": "Douz", "value": "C119726"},
                        {"label": "El Golaa", "value": "C119727"},
                        {"label": "Jemna", "value": "C119728"},
                        {"label": "Kebili", "value": "C119729"}],
 "Kedah": [{"label": "Alor Setar", "value": "C65653"},
           {"label": "Ayer Hangat", "value": "C65654"},
           {"label": "Bedong", "value": "C65655"},
           {"label": "Gurun", "value": "C65656"},
           {"label": "Jitra", "value": "C65657"},
           {"label": "Kampung Kilim", "value": "C65658"},
           {"label": "Kampung Kok", "value": "C65659"},
           {"label": "Kuah", "value": "C65660"},
           {"label": "Kuala Kedah", "value": "C65661"},
           {"label": "Kuala Teriang", "value": "C65662"},
           {"label": "Kulim", "value": "C65663"},
           {"label": "Langkawi", "value": "C65664"},
           {"label": "Padang Mat Sirat", "value": "C65665"},
           {"label": "Sungai Petani", "value": "C65666"}],
 "Kedougou": [{"label": "Departement de Salemata", "value": "C106847"},
              {"label": "Kedougou", "value": "C106848"},
              {"label": "Kedougou Department", "value": "C106849"},
              {"label": "Saraya", "value": "C106850"}],
 "Kef Governorate": [{"label": "As Sars", "value": "C119730"},
                     {"label": "El Kef", "value": "C119731"},
                     {"label": "El Ksour", "value": "C119732"},
                     {"label": "Menzel Salem", "value": "C119733"},
                     {"label": "Nibbar", "value": "C119734"},
                     {"label": "Sakiet Sidi Youssef", "value": "C119735"},
                     {"label": "Tajerouine", "value": "C119736"}],
 "Kefalonia Prefecture": [{"label": "Agia Triada", "value": "C42295"},
                          {"label": "Agnantero", "value": "C42296"},
                          {"label": "Anavra", "value": "C42297"},
                          {"label": "Anthiro", "value": "C42298"},
                          {"label": "Artesiano", "value": "C42299"},
                          {"label": "Itea", "value": "C42300"},
                          {"label": "Kallifoni", "value": "C42301"},
                          {"label": "Kallithiro", "value": "C42302"},
                          {"label": "Karditsa", "value": "C42303"},
                          {"label": "Karditsomagoula", "value": "C42304"},
                          {"label": "Karpochori", "value": "C42305"},
                          {"label": "Magoula", "value": "C42306"},
                          {"label": "Makrychori", "value": "C42307"},
                          {"label": "Mavrommati", "value": "C42308"},
                          {"label": "Mitropoli", "value": "C42309"},
                          {"label": "Morfovouni", "value": "C42310"},
                          {"label": "Mouzaki", "value": "C42311"},
                          {"label": "Palamas", "value": "C42312"},
                          {"label": "Proastio", "value": "C42313"},
                          {"label": "Sofades", "value": "C42314"}],
 "Kegums Municipality": [{"label": "Kegums", "value": "C64884"}],
 "Kekava Municipality": [{"label": "Balozi", "value": "C64885"},
                         {"label": "Kekava", "value": "C64886"}],
 "Kelantan": [{"label": "Gua Musang", "value": "C65667"},
              {"label": "Kampong Kadok", "value": "C65668"},
              {"label": "Kampong Pangkal Kalong", "value": "C65669"},
              {"label": "Kota Bharu", "value": "C65670"},
              {"label": "Pasir Mas", "value": "C65671"},
              {"label": "Peringat", "value": "C65672"},
              {"label": "Tumpat", "value": "C65673"}],
 "Kemerovo Oblast": [{"label": "Abagur", "value": "C101199"},
                     {"label": "Anzhero-Sudzhensk", "value": "C101200"},
                     {"label": "Artyshta", "value": "C101201"},
                     {"label": "Bachatskiy", "value": "C101202"},
                     {"label": "Barzas", "value": "C101203"},
                     {"label": "Belogorsk", "value": "C101204"},
                     {"label": "Belovo", "value": "C101205"},
                     {"label": "Berezovskiy", "value": "C101206"},
                     {"label": "Borovoy", "value": "C101207"},
                     {"label": "Chistogorskiy", "value": "C101208"},
                     {"label": "Gramoteino", "value": "C101209"},
                     {"label": "Guryevsk", "value": "C101210"},
                     {"label": "Inskoy", "value": "C101211"},
                     {"label": "Itatskiy", "value": "C101212"},
                     {"label": "Izhmorskiy", "value": "C101213"},
                     {"label": "Kaltan", "value": "C101214"},
                     {"label": "Karagayla", "value": "C101215"},
                     {"label": "Kaz", "value": "C101216"},
                     {"label": "Kedrovka", "value": "C101217"},
                     {"label": "Kemerovo", "value": "C101218"},
                     {"label": "Kiselevsk", "value": "C101219"},
                     {"label": "Krapivinskiy", "value": "C101220"},
                     {"label": "Krasnobrodskiy", "value": "C101221"},
                     {"label": "Krasnogorskiy", "value": "C101222"},
                     {"label": "Krasnoye", "value": "C101223"},
                     {"label": "Kuzedeyevo", "value": "C101224"},
                     {"label": "Leninsk-Kuznetskiy Rayon", "value": "C101225"},
                     {"label": "Leninsk-Kuznetsky", "value": "C101226"},
                     {"label": "Listvyagi", "value": "C101227"},
                     {"label": "Mariinsk", "value": "C101228"},
                     {"label": "Mezhdurechensk", "value": "C101229"},
                     {"label": "Mundybash", "value": "C101230"},
                     {"label": "Myski", "value": "C101231"},
                     {"label": "Nikitinskiy", "value": "C101232"},
                     {"label": "Novokuznetsk", "value": "C101233"},
                     {"label": "Novokuznetskiy Rayon", "value": "C101234"},
                     {"label": "Novopesterevo", "value": "C101235"},
                     {"label": "Osinniki", "value": "C101236"},
                     {"label": "Pioner", "value": "C101237"},
                     {"label": "Plotnikovo", "value": "C101238"},
                     {"label": "Polysayevo", "value": "C101239"},
                     {"label": "Pritomskiy", "value": "C101240"},
                     {"label": "Prokopyevsk", "value": "C101241"},
                     {"label": "Prokopyevskiy Rayon", "value": "C101242"},
                     {"label": "Promyshlennaya", "value": "C101243"},
                     {"label": "Promyshlennovskiy", "value": "C101244"},
                     {"label": "Rudnichnyy", "value": "C101245"},
                     {"label": "Salair", "value": "C101246"},
                     {"label": "Sheregesh", "value": "C101247"},
                     {"label": "Sosnovka", "value": "C101248"},
                     {"label": "Spassk", "value": "C101249"},
                     {"label": "Starobachaty", "value": "C101250"},
                     {"label": "Staropesterevo", "value": "C101251"},
                     {"label": "Suslovo", "value": "C101252"},
                     {"label": "Tashtagol", "value": "C101253"},
                     {"label": "Tashtagolskiy Rayon", "value": "C101254"},
                     {"label": "Tayga", "value": "C101255"},
                     {"label": "Tayzhina", "value": "C101256"},
                     {"label": "Temirtau", "value": "C101257"},
                     {"label": "Tisul", "value": "C101258"},
                     {"label": "Topki", "value": "C101259"},
                     {"label": "Trudarmeyskiy", "value": "C101260"},
                     {"label": "Tyazhinskiy", "value": "C101261"},
                     {"label": "Ursk", "value": "C101262"},
                     {"label": "Verkh-Chebula", "value": "C101263"},
                     {"label": "Yagunovo", "value": "C101264"},
                     {"label": "Yagunovskiy", "value": "C101265"},
                     {"label": "Yashkino", "value": "C101266"},
                     {"label": "Yaya", "value": "C101267"},
                     {"label": "Yurga", "value": "C101268"},
                     {"label": "Yurginskiy Rayon", "value": "C101269"},
                     {"label": "Zelenogorskiy", "value": "C101270"}],
 "Kemo Prefecture": [{"label": "Sibut", "value": "C17818"}],
 "Kenitra": [{"label": "Arbaoua", "value": "C75726"},
             {"label": "Had Kourt", "value": "C75727"},
             {"label": "Kenitra", "value": "C75728"},
             {"label": "Kenitra Province", "value": "C75729"},
             {"label": "Khemisset", "value": "C75730"},
             {"label": "Mechraa Bel Ksiri", "value": "C75731"},
             {"label": "Oulmes", "value": "C75732"},
             {"label": "Rabat", "value": "C75733"},
             {"label": "Sale", "value": "C75734"},
             {"label": "Sidi Bousber", "value": "C75735"},
             {"label": "Sidi Qacem", "value": "C75736"},
             {"label": "Sidi Redouane", "value": "C75737"},
             {"label": "Sidi Slimane", "value": "C75738"},
             {"label": "Sidi Yahia El Gharb", "value": "C75739"},
             {"label": "Sidi-Kacem", "value": "C75740"},
             {"label": "Skhirate", "value": "C75741"},
             {"label": "Skhirate-Temara", "value": "C75742"},
             {"label": "Souq Larba al Gharb", "value": "C75743"},
             {"label": "Temara", "value": "C75744"},
             {"label": "Teroual", "value": "C75745"},
             {"label": "Tiflet", "value": "C75746"}],
 "Kentucky": [{"label": "Adair County", "value": "C133287"},
              {"label": "Albany", "value": "C133288"},
              {"label": "Alexandria", "value": "C133289"},
              {"label": "Allen County", "value": "C133290"},
              {"label": "Anchorage", "value": "C133291"},
              {"label": "Anderson County", "value": "C133292"},
              {"label": "Annville", "value": "C133293"},
              {"label": "Ashland", "value": "C133294"},
              {"label": "Auburn", "value": "C133295"},
              {"label": "Audubon Park", "value": "C133296"},
              {"label": "Augusta", "value": "C133297"},
              {"label": "Ballard County", "value": "C133298"},
              {"label": "Barbourmeade", "value": "C133299"},
              {"label": "Barbourville", "value": "C133300"},
              {"label": "Bardstown", "value": "C133301"},
              {"label": "Bardwell", "value": "C133302"},
              {"label": "Barren County", "value": "C133303"},
              {"label": "Bath County", "value": "C133304"},
              {"label": "Beattyville", "value": "C133305"},
              {"label": "Beaver Dam", "value": "C133306"},
              {"label": "Bedford", "value": "C133307"},
              {"label": "Beechwood Village", "value": "C133308"},
              {"label": "Bell County", "value": "C133309"},
              {"label": "Bellevue", "value": "C133310"},
              {"label": "Benton", "value": "C133311"},
              {"label": "Berea", "value": "C133312"},
              {"label": "Bloomfield", "value": "C133313"},
              {"label": "Boone County", "value": "C133314"},
              {"label": "Booneville", "value": "C133315"},
              {"label": "Bourbon County", "value": "C133316"},
              {"label": "Bowling Green", "value": "C133317"},
              {"label": "Boyd County", "value": "C133318"},
              {"label": "Boyle County", "value": "C133319"},
              {"label": "Bracken County", "value": "C133320"},
              {"label": "Brandenburg", "value": "C133321"},
              {"label": "Breathitt County", "value": "C133322"},
              {"label": "Breckinridge Center", "value": "C133323"},
              {"label": "Breckinridge County", "value": "C133324"},
              {"label": "Brodhead", "value": "C133325"},
              {"label": "Brooks", "value": "C133326"},
              {"label": "Brooksville", "value": "C133327"},
              {"label": "Brownsville", "value": "C133328"},
              {"label": "Buckner", "value": "C133329"},
              {"label": "Buechel", "value": "C133330"},
              {"label": "Bullitt County", "value": "C133331"},
              {"label": "Burkesville", "value": "C133332"},
              {"label": "Burlington", "value": "C133333"},
              {"label": "Butler County", "value": "C133334"},
              {"label": "Cadiz", "value": "C133335"},
              {"label": "Caldwell County", "value": "C133336"},
              {"label": "Calhoun", "value": "C133337"},
              {"label": "Calloway County", "value": "C133338"},
              {"label": "Calvert City", "value": "C133339"},
              {"label": "Camargo", "value": "C133340"},
              {"label": "Campbell County", "value": "C133341"},
              {"label": "Campbellsville", "value": "C133342"},
              {"label": "Campton", "value": "C133343"},
              {"label": "Carlisle", "value": "C133344"},
              {"label": "Carlisle County", "value": "C133345"},
              {"label": "Carroll County", "value": "C133346"},
              {"label": "Carrollton", "value": "C133347"},
              {"label": "Carter County", "value": "C133348"},
              {"label": "Casey County", "value": "C133349"},
              {"label": "Catlettsburg", "value": "C133350"},
              {"label": "Cave City", "value": "C133351"},
              {"label": "Central City", "value": "C133352"},
              {"label": "Christian County", "value": "C133353"},
              {"label": "Clark County", "value": "C133354"},
              {"label": "Claryville", "value": "C133355"},
              {"label": "Clay", "value": "C133356"},
              {"label": "Clay City", "value": "C133357"},
              {"label": "Clay County", "value": "C133358"},
              {"label": "Clinton", "value": "C133359"},
              {"label": "Clinton County", "value": "C133360"},
              {"label": "Cloverport", "value": "C133361"},
              {"label": "Coal Run Village", "value": "C133362"},
              {"label": "Cold Spring", "value": "C133363"},
              {"label": "Coldstream", "value": "C133364"},
              {"label": "Columbia", "value": "C133365"},
              {"label": "Corbin", "value": "C133366"},
              {"label": "Covington", "value": "C133367"},
              {"label": "Crescent Springs", "value": "C133368"},
              {"label": "Crestview Hills", "value": "C133369"},
              {"label": "Crestwood", "value": "C133370"},
              {"label": "Crittenden", "value": "C133371"},
              {"label": "Crittenden County", "value": "C133372"},
              {"label": "Cumberland", "value": "C133373"},
              {"label": "Cumberland County", "value": "C133374"},
              {"label": "Cynthiana", "value": "C133375"},
              {"label": "Danville", "value": "C133376"},
              {"label": "Daviess County", "value": "C133377"},
              {"label": "Dawson Springs", "value": "C133378"},
              {"label": "Dayton", "value": "C133379"},
              {"label": "Dixon", "value": "C133380"},
              {"label": "Doe Valley", "value": "C133381"},
              {"label": "Douglass Hills", "value": "C133382"},
              {"label": "Dry Ridge", "value": "C133383"},
              {"label": "Earlington", "value": "C133384"},
              {"label": "Eddyville", "value": "C133385"},
              {"label": "Edgewood", "value": "C133386"},
              {"label": "Edmonson County", "value": "C133387"},
              {"label": "Edmonton", "value": "C133388"},
              {"label": "Elizabethtown", "value": "C133389"},
              {"label": "Elk Creek", "value": "C133390"},
              {"label": "Elkfork", "value": "C133391"},
              {"label": "Elkton", "value": "C133392"},
              {"label": "Elliott County", "value": "C133393"},
              {"label": "Elsmere", "value": "C133394"},
              {"label": "Eminence", "value": "C133395"},
              {"label": "Erlanger", "value": "C133396"},
              {"label": "Estill County", "value": "C133397"},
              {"label": "Fairdale", "value": "C133398"},
              {"label": "Falmouth", "value": "C133399"},
              {"label": "Farley", "value": "C133400"},
              {"label": "Fayette County", "value": "C133401"},
              {"label": "Fern Creek", "value": "C133402"},
              {"label": "Flatwoods", "value": "C133403"},
              {"label": "Fleming County", "value": "C133404"},
              {"label": "Flemingsburg", "value": "C133405"},
              {"label": "Florence", "value": "C133406"},
              {"label": "Floyd County", "value": "C133407"},
              {"label": "Fort Campbell North", "value": "C133408"},
              {"label": "Fort Knox", "value": "C133409"},
              {"label": "Fort Mitchell", "value": "C133410"},
              {"label": "Fort Thomas", "value": "C133411"},
              {"label": "Fort Wright", "value": "C133412"},
              {"label": "Francisville", "value": "C133413"},
              {"label": "Frankfort", "value": "C133414"},
              {"label": "Franklin", "value": "C133415"},
              {"label": "Franklin County", "value": "C133416"},
              {"label": "Frenchburg", "value": "C133417"},
              {"label": "Fulton", "value": "C133418"},
              {"label": "Fulton County", "value": "C133419"},
              {"label": "Gallatin County", "value": "C133420"},
              {"label": "Garrard County", "value": "C133421"},
              {"label": "Georgetown", "value": "C133422"},
              {"label": "Glasgow", "value": "C133423"},
              {"label": "Grant County", "value": "C133424"},
              {"label": "Graves County", "value": "C133425"},
              {"label": "Graymoor-Devondale", "value": "C133426"},
              {"label": "Grayson", "value": "C133427"},
              {"label": "Grayson County", "value": "C133428"},
              {"label": "Green County", "value": "C133429"},
              {"label": "Greensburg", "value": "C133430"},
              {"label": "Greenup", "value": "C133431"},
              {"label": "Greenup County", "value": "C133432"},
              {"label": "Greenville", "value": "C133433"},
              {"label": "Guthrie", "value": "C133434"},
              {"label": "Hancock County", "value": "C133435"},
              {"label": "Hardin County", "value": "C133436"},
              {"label": "Hardinsburg", "value": "C133437"},
              {"label": "Harlan", "value": "C133438"},
              {"label": "Harlan County", "value": "C133439"},
              {"label": "Harrison County", "value": "C133440"},
              {"label": "Harrodsburg", "value": "C133441"},
              {"label": "Hart County", "value": "C133442"},
              {"label": "Hartford", "value": "C133443"},
              {"label": "Hawesville", "value": "C133444"},
              {"label": "Hazard", "value": "C133445"},
              {"label": "Hebron", "value": "C133446"},
              {"label": "Hebron Estates", "value": "C133447"},
              {"label": "Henderson", "value": "C133448"},
              {"label": "Henderson County", "value": "C133449"},
              {"label": "Hendron", "value": "C133450"},
              {"label": "Henry County", "value": "C133451"},
              {"label": "Heritage Creek", "value": "C133452"},
              {"label": "Hickman", "value": "C133453"},
              {"label": "Hickman County", "value": "C133454"},
              {"label": "Highland Heights", "value": "C133455"},
              {"label": "Highview", "value": "C133456"},
              {"label": "Hillview", "value": "C133457"},
              {"label": "Hindman", "value": "C133458"},
              {"label": "Hodgenville", "value": "C133459"},
              {"label": "Hopkins County", "value": "C133460"},
              {"label": "Hopkinsville", "value": "C133461"},
              {"label": "Horse Cave", "value": "C133462"},
              {"label": "Hurstbourne", "value": "C133463"},
              {"label": "Hurstbourne Acres", "value": "C133464"},
              {"label": "Hyden", "value": "C133465"},
              {"label": "Independence", "value": "C133466"},
              {"label": "Indian Hills", "value": "C133467"},
              {"label": "Indian Hills Cherokee Section", "value": "C133468"},
              {"label": "Inez", "value": "C133469"},
              {"label": "Ironville", "value": "C133470"},
              {"label": "Irvine", "value": "C133471"},
              {"label": "Irvington", "value": "C133472"},
              {"label": "Jackson", "value": "C133473"},
              {"label": "Jackson County", "value": "C133474"},
              {"label": "Jamestown", "value": "C133475"},
              {"label": "Jefferson County", "value": "C133476"},
              {"label": "Jeffersontown", "value": "C133477"},
              {"label": "Jeffersonville", "value": "C133478"},
              {"label": "Jenkins", "value": "C133479"},
              {"label": "Jessamine County", "value": "C133480"},
              {"label": "Johnson County", "value": "C133481"},
              {"label": "Junction City", "value": "C133482"},
              {"label": "Kenton County", "value": "C133483"},
              {"label": "Knott County", "value": "C133484"},
              {"label": "Knottsville", "value": "C133485"},
              {"label": "Knox County", "value": "C133486"},
              {"label": "La Center", "value": "C133487"},
              {"label": "La Grange", "value": "C133488"},
              {"label": "Lakeside Park", "value": "C133489"},
              {"label": "Lancaster", "value": "C133490"},
              {"label": "Larue County", "value": "C133491"},
              {"label": "Laurel County", "value": "C133492"},
              {"label": "Lawrence County", "value": "C133493"},
              {"label": "Lawrenceburg", "value": "C133494"},
              {"label": "Lebanon", "value": "C133495"},
              {"label": "Lebanon Junction", "value": "C133496"},
              {"label": "Ledbetter", "value": "C133497"},
              {"label": "Lee County", "value": "C133498"},
              {"label": "Leitchfield", "value": "C133499"},
              {"label": "Leslie County", "value": "C133500"},
              {"label": "Letcher County", "value": "C133501"},
              {"label": "Lewis County", "value": "C133502"},
              {"label": "Lewisport", "value": "C133503"},
              {"label": "Lexington", "value": "C133504"},
              {"label": "Lexington-Fayette", "value": "C133505"},
              {"label": "Liberty", "value": "C133506"},
              {"label": "Lincoln County", "value": "C133507"},
              {"label": "Livermore", "value": "C133508"},
              {"label": "Livingston County", "value": "C133509"},
              {"label": "Logan County", "value": "C133510"},
              {"label": "London", "value": "C133511"},
              {"label": "Louisa", "value": "C133512"},
              {"label": "Louisville", "value": "C133513"},
              {"label": "Ludlow", "value": "C133514"},
              {"label": "Lyndon", "value": "C133515"},
              {"label": "Lyon County", "value": "C133516"},
              {"label": "Madison County", "value": "C133517"},
              {"label": "Madisonville", "value": "C133518"},
              {"label": "Magoffin County", "value": "C133519"},
              {"label": "Manchester", "value": "C133520"},
              {"label": "Marion", "value": "C133521"},
              {"label": "Marion County", "value": "C133522"},
              {"label": "Marshall County", "value": "C133523"},
              {"label": "Martin County", "value": "C133524"},
              {"label": "Mason County", "value": "C133525"},
              {"label": "Masonville", "value": "C133526"},
              {"label": "Massac", "value": "C133527"},
              {"label": "Mayfield", "value": "C133528"},
              {"label": "Maysville", "value": "C133529"},
              {"label": "McCracken County", "value": "C133530"},
              {"label": "McCreary County", "value": "C133531"},
              {"label": "McKee", "value": "C133532"},
              {"label": "McLean County", "value": "C133533"},
              {"label": "Meade County", "value": "C133534"},
              {"label": "Meads", "value": "C133535"},
              {"label": "Menifee County", "value": "C133536"},
              {"label": "Mercer County", "value": "C133537"},
              {"label": "Metcalfe County", "value": "C133538"},
              {"label": "Middlesboro", "value": "C133539"},
              {"label": "Middletown", "value": "C133540"},
              {"label": "Midway", "value": "C133541"},
              {"label": "Monroe County", "value": "C133542"},
              {"label": "Montgomery County", "value": "C133543"},
              {"label": "Monticello", "value": "C133544"},
              {"label": "Morehead", "value": "C133545"},
              {"label": "Morgan County", "value": "C133546"},
              {"label": "Morganfield", "value": "C133547"},
              {"label": "Morgantown", "value": "C133548"},
              {"label": "Mount Olivet", "value": "C133549"},
              {"label": "Mount Sterling", "value": "C133550"},
              {"label": "Mount Vernon", "value": "C133551"},
              {"label": "Mount Washington", "value": "C133552"},
              {"label": "Muhlenberg County", "value": "C133553"},
              {"label": "Munfordville", "value": "C133554"},
              {"label": "Murray", "value": "C133555"},
              {"label": "Nelson County", "value": "C133556"},
              {"label": "New Castle", "value": "C133557"},
              {"label": "Newburg", "value": "C133558"},
              {"label": "Newport", "value": "C133559"},
              {"label": "Nicholas County", "value": "C133560"},
              {"label": "Nicholasville", "value": "C133561"},
              {"label": "North Corbin", "value": "C133562"},
              {"label": "Northfield", "value": "C133563"},
              {"label": "Nortonville", "value": "C133564"},
              {"label": "Oak Grove", "value": "C133565"},
              {"label": "Oakbrook", "value": "C133566"},
              {"label": "Ohio County", "value": "C133567"},
              {"label": "Okolona", "value": "C133568"},
              {"label": "Oldham County", "value": "C133569"},
              {"label": "Olive Hill", "value": "C133570"},
              {"label": "Orchard Grass Hills", "value": "C133571"},
              {"label": "Owen County", "value": "C133572"},
              {"label": "Owensboro", "value": "C133573"},
              {"label": "Owenton", "value": "C133574"},
              {"label": "Owingsville", "value": "C133575"},
              {"label": "Owsley County", "value": "C133576"},
              {"label": "Paducah", "value": "C133577"},
              {"label": "Paintsville", "value": "C133578"},
              {"label": "Paris", "value": "C133579"},
              {"label": "Park Hills", "value": "C133580"},
              {"label": "Pendleton County", "value": "C133581"},
              {"label": "Perry County", "value": "C133582"},
              {"label": "Pewee Valley", "value": "C133583"},
              {"label": "Pike County", "value": "C133584"},
              {"label": "Pikeville", "value": "C133585"},
              {"label": "Pine Knot", "value": "C133586"},
              {"label": "Pineville", "value": "C133587"},
              {"label": "Pioneer Village", "value": "C133588"},
              {"label": "Plano", "value": "C133589"},
              {"label": "Pleasure Ridge Park", "value": "C133590"},
              {"label": "Powell County", "value": "C133591"},
              {"label": "Prestonsburg", "value": "C133592"},
              {"label": "Princeton", "value": "C133593"},
              {"label": "Prospect", "value": "C133594"},
              {"label": "Providence", "value": "C133595"},
              {"label": "Pulaski County", "value": "C133596"},
              {"label": "Raceland", "value": "C133597"},
              {"label": "Radcliff", "value": "C133598"},
              {"label": "Reidland", "value": "C133599"},
              {"label": "Richmond", "value": "C133600"},
              {"label": "Robertson County", "value": "C133601"},
              {"label": "Rockcastle County", "value": "C133602"},
              {"label": "Rowan County", "value": "C133603"},
              {"label": "Russell", "value": "C133604"},
              {"label": "Russell County", "value": "C133605"},
              {"label": "Russell Springs", "value": "C133606"},
              {"label": "Russellville", "value": "C133607"},
              {"label": "Ryland Heights", "value": "C133608"},
              {"label": "Saint Dennis", "value": "C133609"},
              {"label": "Saint Matthews", "value": "C133610"},
              {"label": "Saint Regis Park", "value": "C133611"},
              {"label": "Salyersville", "value": "C133612"},
              {"label": "Sandy Hook", "value": "C133613"},
              {"label": "Scott County", "value": "C133614"},
              {"label": "Scottsville", "value": "C133615"},
              {"label": "Sebree", "value": "C133616"},
              {"label": "Shelby County", "value": "C133617"},
              {"label": "Shelbyville", "value": "C133618"},
              {"label": "Shepherdsville", "value": "C133619"},
              {"label": "Shively", "value": "C133620"},
              {"label": "Silver Grove", "value": "C133621"},
              {"label": "Simpson County", "value": "C133622"},
              {"label": "Simpsonville", "value": "C133623"},
              {"label": "Smithland", "value": "C133624"},
              {"label": "Somerset", "value": "C133625"},
              {"label": "South Shore", "value": "C133626"},
              {"label": "Southgate", "value": "C133627"},
              {"label": "Spencer County", "value": "C133628"},
              {"label": "Springfield", "value": "C133629"},
              {"label": "Stanford", "value": "C133630"},
              {"label": "Stanton", "value": "C133631"},
              {"label": "Stearns", "value": "C133632"},
              {"label": "Sturgis", "value": "C133633"},
              {"label": "Taylor County", "value": "C133634"},
              {"label": "Taylor Mill", "value": "C133635"},
              {"label": "Taylorsville", "value": "C133636"},
              {"label": "Todd County", "value": "C133637"},
              {"label": "Tompkinsville", "value": "C133638"},
              {"label": "Trigg County", "value": "C133639"},
              {"label": "Trimble County", "value": "C133640"},
              {"label": "Union", "value": "C133641"},
              {"label": "Union County", "value": "C133642"},
              {"label": "Valley Station", "value": "C133643"},
              {"label": "Van Lear", "value": "C133644"},
              {"label": "Vanceburg", "value": "C133645"},
              {"label": "Verona", "value": "C133646"},
              {"label": "Versailles", "value": "C133647"},
              {"label": "Villa Hills", "value": "C133648"},
              {"label": "Vine Grove", "value": "C133649"},
              {"label": "Walton", "value": "C133650"},
              {"label": "Warren County", "value": "C133651"},
              {"label": "Warsaw", "value": "C133652"},
              {"label": "Washington County", "value": "C133653"},
              {"label": "Watterson Park", "value": "C133654"},
              {"label": "Wayne County", "value": "C133655"},
              {"label": "Webster County", "value": "C133656"},
              {"label": "West Buechel", "value": "C133657"},
              {"label": "West Liberty", "value": "C133658"},
              {"label": "Westwood", "value": "C133659"},
              {"label": "Whitesburg", "value": "C133660"},
              {"label": "Whitley City", "value": "C133661"},
              {"label": "Whitley County", "value": "C133662"},
              {"label": "Wickliffe", "value": "C133663"},
              {"label": "Wilder", "value": "C133664"},
              {"label": "Williamsburg", "value": "C133665"},
              {"label": "Williamstown", "value": "C133666"},
              {"label": "Wilmore", "value": "C133667"},
              {"label": "Winchester", "value": "C133668"},
              {"label": "Windy Hills", "value": "C133669"},
              {"label": "Wolfe County", "value": "C133670"},
              {"label": "Woodford County", "value": "C133671"},
              {"label": "Worthington", "value": "C133672"},
              {"label": "Worthington Hills", "value": "C133673"},
              {"label": "Wurtland", "value": "C133674"}],
 "Kep": [{"label": "Krong Kep", "value": "C16494"},
         {"label": "Srok Damnak Changaeur", "value": "C16495"}],
 "Kepulauan Bangka Belitung": [{"label": "Kabupaten Bangka", "value": "C49656"},
                               {"label": "Kabupaten Bangka Barat",
                                "value": "C49657"},
                               {"label": "Kabupaten Bangka Selatan",
                                "value": "C49658"},
                               {"label": "Kabupaten Bangka Tengah",
                                "value": "C49659"},
                               {"label": "Kabupaten Belitung",
                                "value": "C49660"},
                               {"label": "Kabupaten Belitung Timur",
                                "value": "C49661"},
                               {"label": "Kota Pangkal Pinang",
                                "value": "C49662"},
                               {"label": "Manggar", "value": "C49663"},
                               {"label": "Muntok", "value": "C49664"},
                               {"label": "Pangkalpinang", "value": "C49665"},
                               {"label": "Sungailiat", "value": "C49666"},
                               {"label": "Tanjung Pandan", "value": "C49667"}],
 "Kepulauan Riau": [{"label": "Kabupaten Bintan", "value": "C49668"},
                    {"label": "Kabupaten Karimun", "value": "C49669"},
                    {"label": "Kabupaten Kepulauan Anambas", "value": "C49670"},
                    {"label": "Kabupaten Lingga", "value": "C49671"},
                    {"label": "Kabupaten Natuna", "value": "C49672"},
                    {"label": "Kijang", "value": "C49673"},
                    {"label": "Kota Batam", "value": "C49674"},
                    {"label": "Kota Tanjung Pinang", "value": "C49675"},
                    {"label": "Tanjung Pinang", "value": "C49676"}],
 "Kerala": [{"label": "Adur", "value": "C46294"},
            {"label": "Alappuzha", "value": "C46295"},
            {"label": "Aluva", "value": "C46296"},
            {"label": "Alwaye", "value": "C46297"},
            {"label": "Angamali", "value": "C46298"},
            {"label": "Aroor", "value": "C46299"},
            {"label": "Arukutti", "value": "C46300"},
            {"label": "Attingal", "value": "C46301"},
            {"label": "Avanoor", "value": "C46302"},
            {"label": "Azhikkal", "value": "C46303"},
            {"label": "Badagara", "value": "C46304"},
            {"label": "Beypore", "value": "C46305"},
            {"label": "Changanacheri", "value": "C46306"},
            {"label": "Chelakara", "value": "C46307"},
            {"label": "Chengannur", "value": "C46308"},
            {"label": "Cherpulassery", "value": "C46309"},
            {"label": "Cherthala", "value": "C46310"},
            {"label": "Chetwayi", "value": "C46311"},
            {"label": "Chittur", "value": "C46312"},
            {"label": "Cochin", "value": "C46313"},
            {"label": "Dharmadam", "value": "C46314"},
            {"label": "Edakkulam", "value": "C46315"},
            {"label": "Elur", "value": "C46316"},
            {"label": "Erattupetta", "value": "C46317"},
            {"label": "Ernakulam", "value": "C46318"},
            {"label": "Ferokh", "value": "C46319"},
            {"label": "Guruvayur", "value": "C46320"},
            {"label": "Idukki", "value": "C46321"},
            {"label": "Iringal", "value": "C46322"},
            {"label": "Irinjalakuda", "value": "C46323"},
            {"label": "Kadakkavoor", "value": "C46324"},
            {"label": "Kalamassery", "value": "C46325"},
            {"label": "Kalavoor", "value": "C46326"},
            {"label": "Kalpatta", "value": "C46327"},
            {"label": "Kannangad", "value": "C46328"},
            {"label": "Kannavam", "value": "C46329"},
            {"label": "Kannur", "value": "C46330"},
            {"label": "Kasaragod", "value": "C46331"},
            {"label": "Kasaragod District", "value": "C46332"},
            {"label": "Kattanam", "value": "C46333"},
            {"label": "Kayankulam", "value": "C46334"},
            {"label": "Kizhake Chalakudi", "value": "C46335"},
            {"label": "Kodungallur", "value": "C46336"},
            {"label": "Kollam", "value": "C46337"},
            {"label": "Kotamangalam", "value": "C46338"},
            {"label": "Kottayam", "value": "C46339"},
            {"label": "Kovalam", "value": "C46340"},
            {"label": "Kozhikode", "value": "C46341"},
            {"label": "Kumbalam", "value": "C46342"},
            {"label": "Kunnamangalam", "value": "C46343"},
            {"label": "Kunnamkulam", "value": "C46344"},
            {"label": "Kunnumma", "value": "C46345"},
            {"label": "Kutiatodu", "value": "C46346"},
            {"label": "Kuttampuzha", "value": "C46347"},
            {"label": "Lalam", "value": "C46348"},
            {"label": "Mahe", "value": "C46349"},
            {"label": "Malappuram", "value": "C46350"},
            {"label": "Manjeri", "value": "C46351"},
            {"label": "Manjeshvar", "value": "C46352"},
            {"label": "Mannarakkat", "value": "C46353"},
            {"label": "Marayur", "value": "C46354"},
            {"label": "Mattanur", "value": "C46355"},
            {"label": "Mavelikara", "value": "C46356"},
            {"label": "Mavoor", "value": "C46357"},
            {"label": "Muluppilagadu", "value": "C46358"},
            {"label": "Munnar", "value": "C46359"},
            {"label": "Muvattupula", "value": "C46360"},
            {"label": "Muvattupuzha", "value": "C46361"},
            {"label": "Nadapuram", "value": "C46362"},
            {"label": "Naduvannur", "value": "C46363"},
            {"label": "Nedumangad", "value": "C46364"},
            {"label": "Neyyattinkara", "value": "C46365"},
            {"label": "Nileshwar", "value": "C46366"},
            {"label": "Ottappalam", "value": "C46367"},
            {"label": "Palackattumala", "value": "C46368"},
            {"label": "Palakkad district", "value": "C46369"},
            {"label": "Palghat", "value": "C46370"},
            {"label": "Panamaram", "value": "C46371"},
            {"label": "Pappinissheri", "value": "C46372"},
            {"label": "Paravur Tekkumbhagam", "value": "C46373"},
            {"label": "Pariyapuram", "value": "C46374"},
            {"label": "Pathanamthitta", "value": "C46375"},
            {"label": "Pattanamtitta", "value": "C46376"},
            {"label": "Payyannur", "value": "C46377"},
            {"label": "Perumbavoor", "value": "C46378"},
            {"label": "Perumpavur", "value": "C46379"},
            {"label": "Perya", "value": "C46380"},
            {"label": "Piravam", "value": "C46381"},
            {"label": "Ponmana", "value": "C46382"},
            {"label": "Ponnani", "value": "C46383"},
            {"label": "Punalur", "value": "C46384"},
            {"label": "Ramamangalam", "value": "C46385"},
            {"label": "Shertallai", "value": "C46386"},
            {"label": "Shoranur", "value": "C46387"},
            {"label": "Talipparamba", "value": "C46388"},
            {"label": "Tellicherry", "value": "C46389"},
            {"label": "Thanniyam", "value": "C46390"},
            {"label": "Thiruvananthapuram", "value": "C46391"},
            {"label": "Thrissur", "value": "C46392"},
            {"label": "Thrissur District", "value": "C46393"},
            {"label": "Tirur", "value": "C46394"},
            {"label": "Tiruvalla", "value": "C46395"},
            {"label": "Vaikam", "value": "C46396"},
            {"label": "Varkala", "value": "C46397"},
            {"label": "Vayalar", "value": "C46398"},
            {"label": "Vettur", "value": "C46399"},
            {"label": "Wayanad", "value": "C46400"}],
 "Kercem": [{"label": "Mqabba", "value": "C65889"}],
 "Kericho": [{"label": "Kericho", "value": "C64563"},
             {"label": "Kipkelion", "value": "C64564"},
             {"label": "Litein", "value": "C64565"},
             {"label": "Londiani", "value": "C64566"}],
 "Kerman": [{"label": "Baft", "value": "C50762"},
            {"label": "Bam", "value": "C50763"},
            {"label": "Bardsir", "value": "C50764"},
            {"label": "Kerman", "value": "C50765"},
            {"label": "Kuh Sefid", "value": "C50766"},
            {"label": "Rafsanjan", "value": "C50767"},
            {"label": "Ravar", "value": "C50768"},
            {"label": "Rigan", "value": "C50769"},
            {"label": "Shahr-e Babak", "value": "C50770"},
            {"label": "Shahrak-e Pabedana", "value": "C50771"},
            {"label": "Shahrestan-e Anbarabad", "value": "C50772"},
            {"label": "Shahrestan-e Anar", "value": "C50773"},
            {"label": "Shahrestan-e Baft", "value": "C50774"},
            {"label": "Shahrestan-e Bardsir", "value": "C50775"},
            {"label": "Shahrestan-e Faryab", "value": "C50776"},
            {"label": "Shahrestan-e Jiroft", "value": "C50777"},
            {"label": "Shahrestan-e Kahnuj", "value": "C50778"},
            {"label": "Shahrestan-e Kerman", "value": "C50779"},
            {"label": "Shahrestan-e Kuhbanan", "value": "C50780"},
            {"label": "Shahrestan-e Manujan", "value": "C50781"},
            {"label": "Shahrestan-e Narmashir", "value": "C50782"},
            {"label": "Shahrestan-e Orzuiyeh", "value": "C50783"},
            {"label": "Shahrestan-e Qaleh Ganj", "value": "C50784"},
            {"label": "Shahrestan-e Rabor", "value": "C50785"},
            {"label": "Shahrestan-e Rafsanjan", "value": "C50786"},
            {"label": "Shahrestan-e Rudbar-e Jonubi", "value": "C50787"},
            {"label": "Shahrestan-e Shahr-e Babak", "value": "C50788"},
            {"label": "Shahrestan-e Sirjan", "value": "C50789"},
            {"label": "Sirjan", "value": "C50790"},
            {"label": "Zarand", "value": "C50791"}],
 "Kermanshah": [{"label": "Harsin", "value": "C50792"},
                {"label": "Javanrud", "value": "C50793"},
                {"label": "Kahriz", "value": "C50794"},
                {"label": "Kangavar", "value": "C50795"},
                {"label": "Kermanshah", "value": "C50796"},
                {"label": "Paveh", "value": "C50797"},
                {"label": "Sarpol-e Zahab", "value": "C50798"},
                {"label": "Shahrestan-e Dalahu", "value": "C50799"},
                {"label": "Shahrestan-e Eslamabad-e Gharb", "value": "C50800"},
                {"label": "Shahrestan-e Gilan-e Gharb", "value": "C50801"},
                {"label": "Shahrestan-e Harsin", "value": "C50802"},
                {"label": "Shahrestan-e Javanrud", "value": "C50803"},
                {"label": "Shahrestan-e Kangavar", "value": "C50804"},
                {"label": "Shahrestan-e Kermanshah", "value": "C50805"},
                {"label": "Shahrestan-e Paveh", "value": "C50806"},
                {"label": "Shahrestan-e Qasr-e Shirin", "value": "C50807"},
                {"label": "Shahrestan-e Ravansar", "value": "C50808"},
                {"label": "Shahrestan-e Sahneh", "value": "C50809"},
                {"label": "Shahrestan-e Salas-e Baba Jani", "value": "C50810"},
                {"label": "Shahrestan-e Sarpol-e Zahab", "value": "C50811"},
                {"label": "Sonqor", "value": "C50812"}],
 "Kgalagadi District": [{"label": "Hukuntsi", "value": "C10156"},
                        {"label": "Kang", "value": "C10157"},
                        {"label": "Lehututu", "value": "C10158"},
                        {"label": "Manyana", "value": "C10159"},
                        {"label": "Tshabong", "value": "C10160"},
                        {"label": "Werda", "value": "C10161"}],
 "Kgatleng District": [{"label": "Bokaa", "value": "C10162"},
                       {"label": "Mmathubudukwane", "value": "C10163"},
                       {"label": "Mochudi", "value": "C10164"},
                       {"label": "Pilane", "value": "C10165"}],
 "Khabarovsk Krai": [{"label": "Amursk", "value": "C101271"},
                     {"label": "Ayan", "value": "C101272"},
                     {"label": "Berezovyy", "value": "C101273"},
                     {"label": "Bikin", "value": "C101274"},
                     {"label": "Bikinskiy Rayon", "value": "C101275"},
                     {"label": "Bogorodskoye", "value": "C101276"},
                     {"label": "Chegdomyn", "value": "C101277"},
                     {"label": "Chumikan", "value": "C101278"},
                     {"label": "De-Kastri", "value": "C101279"},
                     {"label": "Gorin", "value": "C101280"},
                     {"label": "Gornyy", "value": "C101281"},
                     {"label": "Imeni Poliny Osipenko", "value": "C101282"},
                     {"label": "Khabarovsk", "value": "C101283"},
                     {"label": "Khabarovsk Vtoroy", "value": "C101284"},
                     {"label": "Khor", "value": "C101285"},
                     {"label": "Khurba", "value": "C101286"},
                     {"label": "Knyaze-Volkonskoye", "value": "C101287"},
                     {"label": "Komsomolsk-on-Amur", "value": "C101288"},
                     {"label": "Korfovskiy", "value": "C101289"},
                     {"label": "Lazarev", "value": "C101290"},
                     {"label": "Lermontovka", "value": "C101291"},
                     {"label": "Litovko", "value": "C101292"},
                     {"label": "Lososina", "value": "C101293"},
                     {"label": "Mago", "value": "C101294"},
                     {"label": "Mayskiy", "value": "C101295"},
                     {"label": "Mnogovershinnyy", "value": "C101296"},
                     {"label": "Mukhen", "value": "C101297"},
                     {"label": "Nekrasovka", "value": "C101298"},
                     {"label": "Nikolayevsk-on-Amure", "value": "C101299"},
                     {"label": "Novyy Urgal", "value": "C101300"},
                     {"label": "Okhotsk", "value": "C101301"},
                     {"label": "Selikhino", "value": "C101302"},
                     {"label": "Solnechnyy", "value": "C101303"},
                     {"label": "Sovetskaya Gavan", "value": "C101304"},
                     {"label": "Topolevo", "value": "C101305"},
                     {"label": "Tyrma", "value": "C101306"},
                     {"label": "Vanino", "value": "C101307"},
                     {"label": "Vyazemskiy", "value": "C101308"},
                     {"label": "Vysokogornyy", "value": "C101309"},
                     {"label": "Zavety Ilicha", "value": "C101310"}],
 "Khachmaz District": [{"label": "Xacmaz", "value": "C8448"},
                       {"label": "Xudat", "value": "C8449"}],
 "Khammouane Province": [{"label": "Muang Thakhek", "value": "C64793"},
                         {"label": "Thakhek", "value": "C64794"}],
 "Khanh Hoa": [{"label": "Cam Ranh", "value": "C147444"},
               {"label": "Huyen Dien Khanh", "value": "C147445"},
               {"label": "Huyen Khanh Son", "value": "C147446"},
               {"label": "Huyen Khanh Vinh", "value": "C147447"},
               {"label": "Huyen Van Ninh", "value": "C147448"},
               {"label": "Nha Trang", "value": "C147449"},
               {"label": "Thanh Pho Cam Ranh", "value": "C147450"},
               {"label": "Thanh Pho Nha Trang", "value": "C147451"},
               {"label": "Thi Xa Ninh Hoa", "value": "C147452"}],
 "Khanty-Mansi Autonomous Okrug": [{"label": "Agirish", "value": "C101311"},
                                   {"label": "Andra", "value": "C101312"},
                                   {"label": "Barsovo", "value": "C101313"},
                                   {"label": "Beloyarskiy", "value": "C101314"},
                                   {"label": "Berezovo", "value": "C101315"},
                                   {"label": "Fedorovskiy", "value": "C101316"},
                                   {"label": "Gornopravdinsk",
                                    "value": "C101317"},
                                   {"label": "Igrim", "value": "C101318"},
                                   {"label": "Izluchinsk", "value": "C101319"},
                                   {"label": "Khanty-Mansiysk",
                                    "value": "C101320"},
                                   {"label": "Khulimsunt", "value": "C101321"},
                                   {"label": "Kogalym", "value": "C101322"},
                                   {"label": "Kommunisticheskiy",
                                    "value": "C101323"},
                                   {"label": "Kondinskoye", "value": "C101324"},
                                   {"label": "Kuminskiy", "value": "C101325"},
                                   {"label": "Langepas", "value": "C101326"},
                                   {"label": "Lokosovo", "value": "C101327"},
                                   {"label": "Lyantor", "value": "C101328"},
                                   {"label": "Malinovskiy", "value": "C101329"},
                                   {"label": "Megion", "value": "C101330"},
                                   {"label": "Mezhdurechenskiy",
                                    "value": "C101331"},
                                   {"label": "Mortka", "value": "C101332"},
                                   {"label": "Nefteyugansk",
                                    "value": "C101333"},
                                   {"label": "Nizhnesortymskiy",
                                    "value": "C101334"},
                                   {"label": "Nizhnevartovsk",
                                    "value": "C101335"},
                                   {"label": "Novoagansk", "value": "C101336"},
                                   {"label": "Nyagan", "value": "C101337"},
                                   {"label": "Pionerskiy", "value": "C101338"},
                                   {"label": "Pokachi", "value": "C101339"},
                                   {"label": "Poykovskiy", "value": "C101340"},
                                   {"label": "Priobye", "value": "C101341"},
                                   {"label": "Pyt-Yakh", "value": "C101342"},
                                   {"label": "Raduzhny", "value": "C101343"},
                                   {"label": "Russkinskiye",
                                    "value": "C101344"},
                                   {"label": "Salym", "value": "C101345"},
                                   {"label": "Saranpaul", "value": "C101346"},
                                   {"label": "Sherkaly", "value": "C101347"},
                                   {"label": "Singapay", "value": "C101348"},
                                   {"label": "Solnechniy", "value": "C101349"},
                                   {"label": "Sovetskiy", "value": "C101350"},
                                   {"label": "Surgut", "value": "C101351"},
                                   {"label": "Uray", "value": "C101352"},
                                   {"label": "Yugorsk", "value": "C101353"},
                                   {"label": "Zelenoborsk",
                                    "value": "C101354"}],
 "Kharkivska oblast": [{"label": "Balakliya", "value": "C121964"},
                       {"label": "Barvinkove", "value": "C121965"},
                       {"label": "Berezivka", "value": "C121966"},
                       {"label": "Bezlyudivka", "value": "C121967"},
                       {"label": "Blyznyuky", "value": "C121968"},
                       {"label": "Bohodukhiv", "value": "C121969"},
                       {"label": "Borova", "value": "C121970"},
                       {"label": "Budy", "value": "C121971"},
                       {"label": "Chapayeve", "value": "C121972"},
                       {"label": "Chervonyy Oskil", "value": "C121973"},
                       {"label": "Chuhuyiv", "value": "C121974"},
                       {"label": "Derhachi", "value": "C121975"},
                       {"label": "Dvorichna", "value": "C121976"},
                       {"label": "Eskhar", "value": "C121977"},
                       {"label": "Huty", "value": "C121978"},
                       {"label": "Izyum", "value": "C121979"},
                       {"label": "Kehychivka", "value": "C121980"},
                       {"label": "Kharkiv", "value": "C121981"},
                       {"label": "Khorosheve", "value": "C121982"},
                       {"label": "Kivsharivka", "value": "C121983"},
                       {"label": "Kochetok", "value": "C121984"},
                       {"label": "Kolomak", "value": "C121985"},
                       {"label": "Korotych", "value": "C121986"},
                       {"label": "Krasnohrad", "value": "C121987"},
                       {"label": "Krasnokutsk", "value": "C121988"},
                       {"label": "Krasnopavlivka", "value": "C121989"},
                       {"label": "Kulynychi", "value": "C121990"},
                       {"label": "Kupjansk", "value": "C121991"},
                       {"label": "Lozova", "value": "C121992"},
                       {"label": "Lyman", "value": "C121993"},
                       {"label": "Lyubotyn", "value": "C121994"},
                       {"label": "Mala Danylivka", "value": "C121995"},
                       {"label": "Malynivka", "value": "C121996"},
                       {"label": "Manchenky", "value": "C121997"},
                       {"label": "Merefa", "value": "C121998"},
                       {"label": "Nova Vodolaha", "value": "C121999"},
                       {"label": "Novopokrovka", "value": "C122000"},
                       {"label": "Panyutyne", "value": "C122001"},
                       {"label": "Peresichna", "value": "C122002"},
                       {"label": "Pervomaiskyi Raion", "value": "C122003"},
                       {"label": "Pervomayskyy", "value": "C122004"},
                       {"label": "Petrivske", "value": "C122005"},
                       {"label": "Pivdenne", "value": "C122006"},
                       {"label": "Pokotylivka", "value": "C122007"},
                       {"label": "Prudyanka", "value": "C122008"},
                       {"label": "Prykolotne", "value": "C122009"},
                       {"label": "Sakhnovshchyna", "value": "C122010"},
                       {"label": "Savyntsi", "value": "C122011"},
                       {"label": "Shevchenkove", "value": "C122012"},
                       {"label": "Slatyne", "value": "C122013"},
                       {"label": "Slobozhanske", "value": "C122014"},
                       {"label": "Solonytsivka", "value": "C122015"},
                       {"label": "Staryy Merchyk", "value": "C122016"},
                       {"label": "Staryy Saltiv", "value": "C122017"},
                       {"label": "Studenok", "value": "C122018"},
                       {"label": "Utkivka", "value": "C122019"},
                       {"label": "Valky", "value": "C122020"},
                       {"label": "Vasyshcheve", "value": "C122021"},
                       {"label": "Velykyy Burluk", "value": "C122022"},
                       {"label": "Verbivka", "value": "C122023"},
                       {"label": "Vovchansk", "value": "C122024"},
                       {"label": "Vvedenka", "value": "C122025"},
                       {"label": "Vysokyy", "value": "C122026"},
                       {"label": "Zachepylivka", "value": "C122027"},
                       {"label": "Zmiyiv", "value": "C122028"},
                       {"label": "Zolochiv", "value": "C122029"}],
 "Khartoum": [{"label": "Khartoum", "value": "C115407"},
              {"label": "Omdurman", "value": "C115408"}],
 "Khatlon Province": [{"label": "Abdurahmoni Jomi", "value": "C118014"},
                      {"label": "Boshchorbogh", "value": "C118015"},
                      {"label": "Bustonqala", "value": "C118016"},
                      {"label": "Chubek", "value": "C118017"},
                      {"label": "Danghara", "value": "C118018"},
                      {"label": "Dusti", "value": "C118019"},
                      {"label": "Farkhor", "value": "C118020"},
                      {"label": "Gharavuti", "value": "C118021"},
                      {"label": "Jilikul", "value": "C118022"},
                      {"label": "Kirov", "value": "C118023"},
                      {"label": "Kolkhozobod", "value": "C118024"},
                      {"label": "Kulob", "value": "C118025"},
                      {"label": "Moskovskiy", "value": "C118026"},
                      {"label": "Muminobod", "value": "C118027"},
                      {"label": "Nohiyai Kolkhozobod", "value": "C118028"},
                      {"label": "Nohiyai Panj", "value": "C118029"},
                      {"label": "Nohiyai Vakhsh", "value": "C118030"},
                      {"label": "Norak", "value": "C118031"},
                      {"label": "Orzu", "value": "C118032"},
                      {"label": "Panj", "value": "C118033"},
                      {"label": "Qurghonteppa", "value": "C118034"},
                      {"label": "Shahritus", "value": "C118035"},
                      {"label": "Sovet", "value": "C118036"},
                      {"label": "Tartiki", "value": "C118037"},
                      {"label": "Vakhsh", "value": "C118038"},
                      {"label": "Vose", "value": "C118039"},
                      {"label": "Yovon", "value": "C118040"}],
 "Khenchela": [{"label": "Khenchela", "value": "C350"}],
 "Khenifra": [{"label": "Aguelmous", "value": "C75747"},
              {"label": "Al Fqih Ben Calah", "value": "C75748"},
              {"label": "Azilal", "value": "C75749"},
              {"label": "Azilal Province", "value": "C75750"},
              {"label": "Beni Mellal", "value": "C75751"},
              {"label": "Beni-Mellal", "value": "C75752"},
              {"label": "Boujniba", "value": "C75753"},
              {"label": "Bzou", "value": "C75754"},
              {"label": "Dar Ould Zidouh", "value": "C75755"},
              {"label": "Demnate", "value": "C75756"},
              {"label": "El Ksiba", "value": "C75757"},
              {"label": "Fquih Ben Salah Province", "value": "C75758"},
              {"label": "Ifrane", "value": "C75759"},
              {"label": "Isseksi", "value": "C75760"},
              {"label": "Itzer", "value": "C75761"},
              {"label": "Kasba Tadla", "value": "C75762"},
              {"label": "Kerrouchen", "value": "C75763"},
              {"label": "Khenifra", "value": "C75764"},
              {"label": "Khouribga", "value": "C75765"},
              {"label": "Khouribga Province", "value": "C75766"},
              {"label": "Midelt", "value": "C75767"},
              {"label": "Ouaoula", "value": "C75768"},
              {"label": "Oued Zem", "value": "C75769"},
              {"label": "Sidi Jaber", "value": "C75770"},
              {"label": "Timoulilt", "value": "C75771"},
              {"label": "Zawyat ech Cheikh", "value": "C75772"}],
 "Khentii Province": [{"label": "Undurkhaan", "value": "C75568"}],
 "Khersonska oblast": [{"label": "Askaniya-Nova", "value": "C122030"},
                       {"label": "Bekhtery", "value": "C122031"},
                       {"label": "Beryslav", "value": "C122032"},
                       {"label": "Bilozerka", "value": "C122033"},
                       {"label": "Brylivka", "value": "C122034"},
                       {"label": "Chaplynka", "value": "C122035"},
                       {"label": "Chornobayivka", "value": "C122036"},
                       {"label": "Chornyanka", "value": "C122037"},
                       {"label": "Chulakivka", "value": "C122038"},
                       {"label": "Dnipryany", "value": "C122039"},
                       {"label": "Dudchany", "value": "C122040"},
                       {"label": "Henichesk", "value": "C122041"},
                       {"label": "Henicheskyy Rayon", "value": "C122042"},
                       {"label": "Hola Prystan", "value": "C122043"},
                       {"label": "Hornostayivka", "value": "C122044"},
                       {"label": "Ivanivka", "value": "C122045"},
                       {"label": "Kakhovka", "value": "C122046"},
                       {"label": "Kalanchak", "value": "C122047"},
                       {"label": "Kayiry", "value": "C122048"},
                       {"label": "Kherson", "value": "C122049"},
                       {"label": "Komyshany", "value": "C122050"},
                       {"label": "Kozachi Laheri", "value": "C122051"},
                       {"label": "Krasne", "value": "C122052"},
                       {"label": "Lazurne", "value": "C122053"},
                       {"label": "Lyubymivka", "value": "C122054"},
                       {"label": "Malokakhovka", "value": "C122055"},
                       {"label": "Nova Kakhovka", "value": "C122056"},
                       {"label": "Nova Mayachka", "value": "C122057"},
                       {"label": "Novofedorivka", "value": "C122058"},
                       {"label": "Novooleksiyivka", "value": "C122059"},
                       {"label": "Novovorontsovka", "value": "C122060"},
                       {"label": "Nyzhni Sirohozy", "value": "C122061"},
                       {"label": "Partyzany", "value": "C122062"},
                       {"label": "Radensk", "value": "C122063"},
                       {"label": "Skadovsk", "value": "C122064"},
                       {"label": "Stanislav", "value": "C122065"},
                       {"label": "Tsyurupynsk", "value": "C122066"},
                       {"label": "Velyka Lepetykha", "value": "C122067"},
                       {"label": "Velyka Oleksandrivka", "value": "C122068"},
                       {"label": "Velyki Kopani", "value": "C122069"},
                       {"label": "Verkhniy Rohachyk", "value": "C122070"},
                       {"label": "Vynohradove", "value": "C122071"},
                       {"label": "Vysokopillya", "value": "C122072"},
                       {"label": "Zaliznyy Port", "value": "C122073"}],
 "Khizi District": [{"label": "Altiagac", "value": "C8450"},
                    {"label": "Khyzy", "value": "C8451"},
                    {"label": "Kilyazi", "value": "C8452"},
                    {"label": "Suraabad", "value": "C8453"}],
 "Khmelnytska oblast": [{"label": "Antoniny", "value": "C122074"},
                        {"label": "Bazaliya", "value": "C122075"},
                        {"label": "Derazhnya", "value": "C122076"},
                        {"label": "Derazhnyanskyy Rayon", "value": "C122077"},
                        {"label": "Dunaivtsi", "value": "C122078"},
                        {"label": "Horodok", "value": "C122079"},
                        {"label": "Izyaslav", "value": "C122080"},
                        {"label": "Kamianets-Podilskyi", "value": "C122081"},
                        {"label": "Khmelnytskyy Rayon", "value": "C122082"},
                        {"label": "Khmelnytskyi", "value": "C122083"},
                        {"label": "Krasyliv", "value": "C122084"},
                        {"label": "Letychiv", "value": "C122085"},
                        {"label": "Makiv", "value": "C122086"},
                        {"label": "Medzhybizh", "value": "C122087"},
                        {"label": "Narkevychi", "value": "C122088"},
                        {"label": "Netishyn", "value": "C122089"},
                        {"label": "Nova Ushytsya", "value": "C122090"},
                        {"label": "Novoselytsya", "value": "C122091"},
                        {"label": "Polonne", "value": "C122092"},
                        {"label": "Poninka", "value": "C122093"},
                        {"label": "Shepetivka", "value": "C122094"},
                        {"label": "Slavuta", "value": "C122095"},
                        {"label": "Smotrych", "value": "C122096"},
                        {"label": "Smyga", "value": "C122097"},
                        {"label": "Stara Syniava", "value": "C122098"},
                        {"label": "Starokostiantyniv", "value": "C122099"},
                        {"label": "Yarmolyntsi", "value": "C122100"},
                        {"label": "Zakupne", "value": "C122101"},
                        {"label": "Zhvanets", "value": "C122102"}],
 "Khojali District": [{"label": "Askyaran", "value": "C8454"},
                      {"label": "Xocali", "value": "C8455"}],
 "Khomas Region": [{"label": "Katutura", "value": "C75986"},
                   {"label": "Windhoek", "value": "C75987"}],
 "Khon Kaen": [{"label": "Amphoe Ban Fang", "value": "C118667"},
               {"label": "Amphoe Ban Haet", "value": "C118668"},
               {"label": "Amphoe Ban Phai", "value": "C118669"},
               {"label": "Amphoe Chonnabot", "value": "C118670"},
               {"label": "Amphoe Chum Phae", "value": "C118671"},
               {"label": "Amphoe Khao Suan Kwang", "value": "C118672"},
               {"label": "Amphoe Khok Pho Chai", "value": "C118673"},
               {"label": "Amphoe Kranuan", "value": "C118674"},
               {"label": "Amphoe Mancha Khiri", "value": "C118675"},
               {"label": "Amphoe Mueang Khon Kaen", "value": "C118676"},
               {"label": "Amphoe Nam Phong", "value": "C118677"},
               {"label": "Amphoe Non Sila", "value": "C118678"},
               {"label": "Amphoe Nong Na Kham", "value": "C118679"},
               {"label": "Amphoe Nong Ruea", "value": "C118680"},
               {"label": "Amphoe Nong Song Hong", "value": "C118681"},
               {"label": "Amphoe Phon", "value": "C118682"},
               {"label": "Amphoe Phra Yuen", "value": "C118683"},
               {"label": "Amphoe Phu Pha Man", "value": "C118684"},
               {"label": "Amphoe Phu Wiang", "value": "C118685"},
               {"label": "Amphoe Pueai Noi", "value": "C118686"},
               {"label": "Amphoe Sam Sung", "value": "C118687"},
               {"label": "Amphoe Si Chom Phu", "value": "C118688"},
               {"label": "Amphoe Ubolratana", "value": "C118689"},
               {"label": "Amphoe Waeng Noi", "value": "C118690"},
               {"label": "Amphoe Waeng Yai", "value": "C118691"},
               {"label": "Amphoe Wiang Kao", "value": "C118692"},
               {"label": "Ban Phai", "value": "C118693"},
               {"label": "Chum Phae", "value": "C118694"},
               {"label": "Khon Kaen", "value": "C118695"},
               {"label": "Kranuan", "value": "C118696"},
               {"label": "Phon", "value": "C118697"},
               {"label": "Pueai Noi", "value": "C118698"}],
 "Khost": [{"label": "Khost", "value": "C53"}],
 "Khovd Province": [{"label": "Khovd", "value": "C75569"},
                    {"label": "Most", "value": "C75570"},
                    {"label": "uyonch", "value": "C75571"}],
 "Khovsgol Province": [{"label": "Hanh", "value": "C75572"},
                       {"label": "Murun-kuren", "value": "C75573"},
                       {"label": "Tsengel", "value": "C75574"},
                       {"label": "Turt", "value": "C75575"}],
 "Khulna District": [{"label": "Bagerhat", "value": "C8626"},
                     {"label": "Bhatpara Abhaynagar", "value": "C8627"},
                     {"label": "Bheramara", "value": "C8628"},
                     {"label": "Chuadanga", "value": "C8629"},
                     {"label": "Jessore", "value": "C8630"},
                     {"label": "Jhenaidah", "value": "C8631"},
                     {"label": "Jhingergacha", "value": "C8632"},
                     {"label": "Kalia", "value": "C8633"},
                     {"label": "Kaliganj", "value": "C8634"},
                     {"label": "Kesabpur", "value": "C8635"},
                     {"label": "Khulna", "value": "C8636"},
                     {"label": "Kushtia", "value": "C8637"},
                     {"label": "Magura", "value": "C8638"},
                     {"label": "Meherpur", "value": "C8639"},
                     {"label": "Morrelgonj", "value": "C8640"},
                     {"label": "Narail", "value": "C8641"},
                     {"label": "Nowlamary", "value": "C8642"},
                     {"label": "Phultala", "value": "C8643"},
                     {"label": "Sarankhola", "value": "C8644"},
                     {"label": "Satkhira", "value": "C8645"},
                     {"label": "Ujalpur", "value": "C8646"},
                     {"label": "Uttar Char Fasson", "value": "C8647"}],
 "Khuzestan": [{"label": "Abadan", "value": "C50813"},
               {"label": "Aghajari", "value": "C50814"},
               {"label": "Ahvaz", "value": "C50815"},
               {"label": "Bagh Shahrestan-e Malek", "value": "C50816"},
               {"label": "Behbahan", "value": "C50817"},
               {"label": "Gotvand", "value": "C50818"},
               {"label": "Hamidiyeh", "value": "C50819"},
               {"label": "Jongiyeh", "value": "C50820"},
               {"label": "Karun", "value": "C50821"},
               {"label": "Khorramshahr", "value": "C50822"},
               {"label": "Masjed Soleyman", "value": "C50823"},
               {"label": "Omidiyeh", "value": "C50824"},
               {"label": "Ramhormoz", "value": "C50825"},
               {"label": "Ramshir", "value": "C50826"},
               {"label": "sedeyen-e Yek", "value": "C50827"},
               {"label": "Shadegan", "value": "C50828"},
               {"label": "Shahrak-e Kuluri", "value": "C50829"},
               {"label": "Shahrestan-e abadan", "value": "C50830"},
               {"label": "Shahrestan-e Ahvaz", "value": "C50831"},
               {"label": "Shahrestan-e Andika", "value": "C50832"},
               {"label": "Shahrestan-e Andimeshk", "value": "C50833"},
               {"label": "Shahrestan-e Bandar-e Mahshahr", "value": "C50834"},
               {"label": "Shahrestan-e Bavi", "value": "C50835"},
               {"label": "Shahrestan-e Behbahan", "value": "C50836"},
               {"label": "Shahrestan-e Dasht-e azadegan", "value": "C50837"},
               {"label": "Shahrestan-e Dezful", "value": "C50838"},
               {"label": "Shahrestan-e Hendijan", "value": "C50839"},
               {"label": "Shahrestan-e izeh", "value": "C50840"},
               {"label": "Shahrestan-e Lali", "value": "C50841"},
               {"label": "Shahrestan-e Masjed Soleyman", "value": "C50842"},
               {"label": "Shahrestan-e Omidiyeh", "value": "C50843"},
               {"label": "Shahrestan-e Ramhormoz", "value": "C50844"},
               {"label": "Shahrestan-e Ramshir", "value": "C50845"},
               {"label": "Shahrestan-e Shadegan", "value": "C50846"},
               {"label": "Shahrestan-e Shush", "value": "C50847"},
               {"label": "Shahrestan-e Shushtar", "value": "C50848"},
               {"label": "Shush", "value": "C50849"},
               {"label": "Shushtar", "value": "C50850"},
               {"label": "Susangerd", "value": "C50851"}],
 "Khyber Pakhtunkhwa": [{"label": "Abbottabad", "value": "C79388"},
                        {"label": "Akora", "value": "C79389"},
                        {"label": "Aman Garh", "value": "C79390"},
                        {"label": "Amirabad", "value": "C79391"},
                        {"label": "Ashanagro Koto", "value": "C79392"},
                        {"label": "Baffa", "value": "C79393"},
                        {"label": "Bannu", "value": "C79394"},
                        {"label": "Bat Khela", "value": "C79395"},
                        {"label": "Battagram", "value": "C79396"},
                        {"label": "Battagram District", "value": "C79397"},
                        {"label": "Buner District", "value": "C79398"},
                        {"label": "Charsadda", "value": "C79399"},
                        {"label": "Cherat Cantonement", "value": "C79400"},
                        {"label": "Chitral", "value": "C79401"},
                        {"label": "Dera Ismail Khan", "value": "C79402"},
                        {"label": "Dera Ismail Khan District",
                         "value": "C79403"},
                        {"label": "Doaba", "value": "C79404"},
                        {"label": "Hangu", "value": "C79405"},
                        {"label": "Haripur", "value": "C79406"},
                        {"label": "Havelian", "value": "C79407"},
                        {"label": "Kakad Wari Dir Upper", "value": "C79408"},
                        {"label": "Karak", "value": "C79409"},
                        {"label": "Khalabat", "value": "C79410"},
                        {"label": "Kohat", "value": "C79411"},
                        {"label": "Kulachi", "value": "C79412"},
                        {"label": "Lachi", "value": "C79413"},
                        {"label": "Lakki", "value": "C79414"},
                        {"label": "Mansehra", "value": "C79415"},
                        {"label": "Mardan", "value": "C79416"},
                        {"label": "Mingora", "value": "C79417"},
                        {"label": "Noorabad", "value": "C79418"},
                        {"label": "Nowshera", "value": "C79419"},
                        {"label": "Nowshera Cantonment", "value": "C79420"},
                        {"label": "Pabbi", "value": "C79421"},
                        {"label": "Paharpur", "value": "C79422"},
                        {"label": "Peshawar", "value": "C79423"},
                        {"label": "Risalpur Cantonment", "value": "C79424"},
                        {"label": "Sarai Naurang", "value": "C79425"},
                        {"label": "Shabqadar", "value": "C79426"},
                        {"label": "Shingli Bala", "value": "C79427"},
                        {"label": "Shorkot", "value": "C79428"},
                        {"label": "Swabi", "value": "C79429"},
                        {"label": "Tangi", "value": "C79430"},
                        {"label": "Tank", "value": "C79431"},
                        {"label": "Thal", "value": "C79432"},
                        {"label": "Topi", "value": "C79433"},
                        {"label": "Upper Dir", "value": "C79434"},
                        {"label": "Utmanzai", "value": "C79435"},
                        {"label": "Zaida", "value": "C79436"}],
 "Kiambu": [{"label": "Kiambu", "value": "C64567"},
            {"label": "Kikuyu", "value": "C64568"},
            {"label": "Limuru", "value": "C64569"},
            {"label": "Thika", "value": "C64570"}],
 "Kicevo Municipality": [{"label": "Drugovo", "value": "C65429"},
                         {"label": "Kicevo", "value": "C65430"},
                         {"label": "Srbica", "value": "C65431"},
                         {"label": "Strelci", "value": "C65432"},
                         {"label": "Tuin", "value": "C65433"},
                         {"label": "Vranestica", "value": "C65434"},
                         {"label": "Zajas", "value": "C65435"}],
 "Kidal Region": [{"label": "Abeibara", "value": "C65833"},
                  {"label": "Cercle dAbeibara", "value": "C65834"},
                  {"label": "Kidal", "value": "C65835"}],
 "Kidricevo Municipality": [{"label": "Kidricevo", "value": "C107659"}],
 "Kie-Ntem Province": [{"label": "Ebebiyin", "value": "C24472"},
                       {"label": "Mikomeseng", "value": "C24473"},
                       {"label": "Ncue", "value": "C24474"},
                       {"label": "Nsang", "value": "C24475"}],
 "Kien Giang": [{"label": "Duong Dong", "value": "C147453"},
                {"label": "Ha Tien", "value": "C147454"},
                {"label": "Huyen An Bien", "value": "C147455"},
                {"label": "Huyen An Minh", "value": "C147456"},
                {"label": "Huyen Chau Thanh", "value": "C147457"},
                {"label": "Huyen Giong Rieng", "value": "C147458"},
                {"label": "Huyen Go Quao", "value": "C147459"},
                {"label": "Huyen Hon Dat", "value": "C147460"},
                {"label": "Huyen Kien Hai", "value": "C147461"},
                {"label": "Huyen Phu Quoc", "value": "C147462"},
                {"label": "Huyen Tan Hiep", "value": "C147463"},
                {"label": "Huyen Vinh Thuan", "value": "C147464"},
                {"label": "Kien Luong Town", "value": "C147465"},
                {"label": "Rach Gia", "value": "C147466"}],
 "Kigali district": [{"label": "Kigali", "value": "C105712"}],
 "Kigoma": [{"label": "Kakonko", "value": "C118120"},
            {"label": "Kasulu", "value": "C118121"},
            {"label": "Kibondo", "value": "C118122"},
            {"label": "Kigoma", "value": "C118123"},
            {"label": "Mabamba", "value": "C118124"},
            {"label": "Mwandiga", "value": "C118125"},
            {"label": "Nguruka", "value": "C118126"},
            {"label": "Uvinza", "value": "C118127"}],
 "Kilifi": [{"label": "Iten", "value": "C64571"},
            {"label": "Kapsowar", "value": "C64572"},
            {"label": "Kilifi", "value": "C64573"},
            {"label": "Malindi", "value": "C64574"},
            {"label": "Mariakani", "value": "C64575"},
            {"label": "Takaungu", "value": "C64576"}],
 "Kilimanjaro": [{"label": "Hedaru", "value": "C118128"},
                 {"label": "Kihurio", "value": "C118129"},
                 {"label": "Kisiwani", "value": "C118130"},
                 {"label": "Kwakoa", "value": "C118131"},
                 {"label": "Lembeni", "value": "C118132"},
                 {"label": "Makanya", "value": "C118133"},
                 {"label": "Moshi", "value": "C118134"},
                 {"label": "Mwembe", "value": "C118135"},
                 {"label": "Ndungu", "value": "C118136"},
                 {"label": "Same", "value": "C118137"}],
 "Kilis": [{"label": "Elbeyli", "value": "C120579"},
           {"label": "Kilis", "value": "C120580"},
           {"label": "Musabeyli", "value": "C120581"},
           {"label": "Polateli Ilcesi", "value": "C120582"}],
 "Kindia Region": [{"label": "Coyah", "value": "C43020"},
                   {"label": "Dubreka", "value": "C43021"},
                   {"label": "Forecariah", "value": "C43022"},
                   {"label": "Kindia", "value": "C43023"},
                   {"label": "Prefecture de Dubreka", "value": "C43024"},
                   {"label": "Prefecture de Forecariah", "value": "C43025"},
                   {"label": "Telimele", "value": "C43026"},
                   {"label": "Telimele Prefecture", "value": "C43027"},
                   {"label": "Tondon", "value": "C43028"}],
 "Kingston Parish": [{"label": "Allman Town", "value": "C61806"},
                     {"label": "Bournemouth Gardens", "value": "C61807"},
                     {"label": "Campbell Town", "value": "C61808"},
                     {"label": "Central Down Town", "value": "C61809"},
                     {"label": "DAguilar Town Rennock Lodge",
                      "value": "C61810"},
                     {"label": "Denham Town", "value": "C61811"},
                     {"label": "East Down Town", "value": "C61812"},
                     {"label": "Fletchers Land", "value": "C61813"},
                     {"label": "Franklyn Town", "value": "C61814"},
                     {"label": "Hannah Town Craig Town", "value": "C61815"},
                     {"label": "Johnson Town", "value": "C61816"},
                     {"label": "Kingston", "value": "C61817"},
                     {"label": "Kingston Gardens", "value": "C61818"},
                     {"label": "Manley Meadows", "value": "C61819"},
                     {"label": "Newport East", "value": "C61820"},
                     {"label": "Newton Square", "value": "C61821"},
                     {"label": "Norman Gardens", "value": "C61822"},
                     {"label": "Passmore Town", "value": "C61823"},
                     {"label": "Port Royal", "value": "C61824"},
                     {"label": "Rae Town", "value": "C61825"},
                     {"label": "Rollington Town", "value": "C61826"},
                     {"label": "Southside", "value": "C61827"},
                     {"label": "Springfield", "value": "C61828"},
                     {"label": "Tivoli Gardens", "value": "C61829"},
                     {"label": "West Down Town", "value": "C61830"}],
 "Kinmen": [{"label": "Jincheng", "value": "C117965"},
            {"label": "Kinmen County", "value": "C117966"}],
 "Kinshasa": [{"label": "Kinshasa", "value": "C23333"},
              {"label": "Masina", "value": "C23334"}],
 "Kirikkale": [{"label": "Bahsili Ilcesi", "value": "C120583"},
               {"label": "Baliseyh", "value": "C120584"},
               {"label": "Celebi Ilcesi", "value": "C120585"},
               {"label": "Delice", "value": "C120586"},
               {"label": "Karakecili", "value": "C120587"},
               {"label": "Keskin", "value": "C120588"},
               {"label": "Kirikkale", "value": "C120589"},
               {"label": "Sulakyurt", "value": "C120590"},
               {"label": "Yahsihan Ilcesi", "value": "C120591"}],
 "Kirinyaga": [{"label": "Kerugoya", "value": "C64577"},
               {"label": "Sagana", "value": "C64578"}],
 "Kirklareli": [{"label": "Babaeski", "value": "C120592"},
                {"label": "Demirkoy Ilcesi", "value": "C120593"},
                {"label": "Kirklareli", "value": "C120594"},
                {"label": "Kofcaz", "value": "C120595"},
                {"label": "Luleburgaz Ilcesi", "value": "C120596"},
                {"label": "Pehlivankoy", "value": "C120597"},
                {"label": "Pinarhisar", "value": "C120598"},
                {"label": "uskup", "value": "C120599"},
                {"label": "Vize", "value": "C120600"}],
 "Kirkop": [{"label": "Imsida", "value": "C65890"},
            {"label": "Kirkop", "value": "C65891"}],
 "Kirkuk Governorate": [{"label": "Kirkuk", "value": "C51185"}],
 "Kirov Oblast": [{"label": "Afanasyevskiy Rayon", "value": "C101355"},
                  {"label": "Arbazh", "value": "C101356"},
                  {"label": "Arkul", "value": "C101357"},
                  {"label": "Belaya Kholunitsa", "value": "C101358"},
                  {"label": "Belorechensk", "value": "C101359"},
                  {"label": "Bogorodskoye", "value": "C101360"},
                  {"label": "Chernaya Kholunitsa", "value": "C101361"},
                  {"label": "Darovskoy", "value": "C101362"},
                  {"label": "Demyanovo", "value": "C101363"},
                  {"label": "Dubrovka", "value": "C101364"},
                  {"label": "Falenki", "value": "C101365"},
                  {"label": "Falenskiy Rayon", "value": "C101366"},
                  {"label": "Kiknur", "value": "C101367"},
                  {"label": "Kiknurskiy Rayon", "value": "C101368"},
                  {"label": "Kilmez", "value": "C101369"},
                  {"label": "Kirov", "value": "C101370"},
                  {"label": "Kirovo-Chepetsk", "value": "C101371"},
                  {"label": "Kirovo-Chepetskiy Rayon", "value": "C101372"},
                  {"label": "Kirs", "value": "C101373"},
                  {"label": "Kosino", "value": "C101374"},
                  {"label": "Kostino", "value": "C101375"},
                  {"label": "Kotelnich", "value": "C101376"},
                  {"label": "Krasnaya Polyana", "value": "C101377"},
                  {"label": "Kumeny", "value": "C101378"},
                  {"label": "Lalsk", "value": "C101379"},
                  {"label": "Leninskoye", "value": "C101380"},
                  {"label": "Lesnoy", "value": "C101381"},
                  {"label": "Lesnyye Polyany", "value": "C101382"},
                  {"label": "Luza", "value": "C101383"},
                  {"label": "Luzskiy Rayon", "value": "C101384"},
                  {"label": "Lyangasovo", "value": "C101385"},
                  {"label": "Malmyzh", "value": "C101386"},
                  {"label": "Malmyzhskiy Rayon", "value": "C101387"},
                  {"label": "Maromitsa", "value": "C101388"},
                  {"label": "Medvedok", "value": "C101389"},
                  {"label": "Mirnyy", "value": "C101390"},
                  {"label": "Murashi", "value": "C101391"},
                  {"label": "Murashinskiy Rayon", "value": "C101392"},
                  {"label": "Murygino", "value": "C101393"},
                  {"label": "Nagorsk", "value": "C101394"},
                  {"label": "Nagorskiy Rayon", "value": "C101395"},
                  {"label": "Nema", "value": "C101396"},
                  {"label": "Nemskiy Rayon", "value": "C101397"},
                  {"label": "Nizhneivkino", "value": "C101398"},
                  {"label": "Nolinsk", "value": "C101399"},
                  {"label": "Nolinskiy Rayon", "value": "C101400"},
                  {"label": "Omutninsk", "value": "C101401"},
                  {"label": "Oparino", "value": "C101402"},
                  {"label": "Orichevskiy Rayon", "value": "C101403"},
                  {"label": "Orichi", "value": "C101404"},
                  {"label": "Orlov", "value": "C101405"},
                  {"label": "Orlovskiy Rayon", "value": "C101406"},
                  {"label": "Pasegovo", "value": "C101407"},
                  {"label": "Peskovka", "value": "C101408"},
                  {"label": "Pinyug", "value": "C101409"},
                  {"label": "Pizhanka", "value": "C101410"},
                  {"label": "Pizhanskiy Rayon", "value": "C101411"},
                  {"label": "Podosinovets", "value": "C101412"},
                  {"label": "Podosinovskiy Rayon", "value": "C101413"},
                  {"label": "Raduzhnyy", "value": "C101414"},
                  {"label": "Rudnichnyy", "value": "C101415"},
                  {"label": "Sanchursk", "value": "C101416"},
                  {"label": "Sanchurskiy Rayon", "value": "C101417"},
                  {"label": "Shabalinskiy Rayon", "value": "C101418"},
                  {"label": "Slobodskoy", "value": "C101419"},
                  {"label": "Slobodskoy Rayon", "value": "C101420"},
                  {"label": "Sosnovka", "value": "C101421"},
                  {"label": "Sovetsk", "value": "C101422"},
                  {"label": "Sovetskiy Rayon", "value": "C101423"},
                  {"label": "Sozimskiy", "value": "C101424"},
                  {"label": "Strizhi", "value": "C101425"},
                  {"label": "Stulovo", "value": "C101426"},
                  {"label": "Suna", "value": "C101427"},
                  {"label": "Sunskiy Rayon", "value": "C101428"},
                  {"label": "Svecha", "value": "C101429"},
                  {"label": "Svetlopolyansk", "value": "C101430"},
                  {"label": "Torfyanoy", "value": "C101431"},
                  {"label": "Tuzha", "value": "C101432"},
                  {"label": "Uni", "value": "C101433"},
                  {"label": "Uninskiy Rayon", "value": "C101434"},
                  {"label": "Urzhum", "value": "C101435"},
                  {"label": "Vakhrushi", "value": "C101436"},
                  {"label": "Verkhoshizhemye", "value": "C101437"},
                  {"label": "Vostochnyy", "value": "C101438"},
                  {"label": "Vyatskiye Polyany", "value": "C101439"},
                  {"label": "Yaransk", "value": "C101440"},
                  {"label": "Yaranskiy Rayon", "value": "C101441"},
                  {"label": "Yuryanskiy Rayon", "value": "C101442"},
                  {"label": "Zuyevka", "value": "C101443"}],
 "Kirovohradska oblast": [{"label": "Adzhamka", "value": "C122103"},
                          {"label": "Blahovishchenske Raion",
                           "value": "C122104"},
                          {"label": "Bobrynets", "value": "C122105"},
                          {"label": "Dmytrivka", "value": "C122106"},
                          {"label": "Dobrovelychkivka", "value": "C122107"},
                          {"label": "Dobrovelychkivskyy Rayon",
                           "value": "C122108"},
                          {"label": "Dolynska", "value": "C122109"},
                          {"label": "Dolynskyy Rayon", "value": "C122110"},
                          {"label": "Hayvoron", "value": "C122111"},
                          {"label": "Kapitanivka", "value": "C122112"},
                          {"label": "Kirovohradska Miskrada",
                           "value": "C122113"},
                          {"label": "Kompaniyivka", "value": "C122114"},
                          {"label": "Kropyvnytskyi", "value": "C122115"},
                          {"label": "Lozuvatka", "value": "C122116"},
                          {"label": "Lypnyazhka", "value": "C122117"},
                          {"label": "Mala Vyska", "value": "C122118"},
                          {"label": "Malovyskivskyy Rayon", "value": "C122119"},
                          {"label": "Molodizhne", "value": "C122120"},
                          {"label": "Nova Praha", "value": "C122121"},
                          {"label": "Novhorodka", "value": "C122122"},
                          {"label": "Novoarkhanhelsk", "value": "C122123"},
                          {"label": "Novomyrhorod", "value": "C122124"},
                          {"label": "Novoukrayinka", "value": "C122125"},
                          {"label": "Novyy Starodub", "value": "C122126"},
                          {"label": "Oleksandrivka", "value": "C122127"},
                          {"label": "Oleksandriya", "value": "C122128"},
                          {"label": "Oleksandriyske", "value": "C122129"},
                          {"label": "Onufriyivka", "value": "C122130"},
                          {"label": "Pantayivka", "value": "C122131"},
                          {"label": "Pavlysh", "value": "C122132"},
                          {"label": "Perehonivka", "value": "C122133"},
                          {"label": "Petrove", "value": "C122134"},
                          {"label": "Pobugskoye", "value": "C122135"},
                          {"label": "Pomichna", "value": "C122136"},
                          {"label": "Pryyutivka", "value": "C122137"},
                          {"label": "Rivne", "value": "C122138"},
                          {"label": "Subottsi", "value": "C122139"},
                          {"label": "Svitlovodsk", "value": "C122140"},
                          {"label": "Tyshkivka", "value": "C122141"},
                          {"label": "Ustynivka", "value": "C122142"},
                          {"label": "Yelyzavethradka", "value": "C122143"},
                          {"label": "Zavallya", "value": "C122144"},
                          {"label": "Zlynka", "value": "C122145"},
                          {"label": "Znomenka", "value": "C122146"}],
 "Kirsehir": [{"label": "Akcakent Ilcesi", "value": "C120601"},
              {"label": "Akpinar", "value": "C120602"},
              {"label": "Boztepe Ilcesi", "value": "C120603"},
              {"label": "Cicekdagi", "value": "C120604"},
              {"label": "Kaman", "value": "C120605"},
              {"label": "Kirsehir", "value": "C120606"},
              {"label": "Mucur", "value": "C120607"}],
 "Kirundo Province": [{"label": "Kirundo", "value": "C16450"}],
 "Kisela Voda Municipality": [{"label": "Kisela Voda", "value": "C65436"}],
 "Kisii": [{"label": "Kisii", "value": "C64579"},
           {"label": "Ogembo", "value": "C64580"}],
 "Kisumu": [{"label": "Ahero", "value": "C64581"},
            {"label": "Kisumu", "value": "C64582"},
            {"label": "Muhoroni", "value": "C64583"}],
 "Kitui": [{"label": "Kitui", "value": "C64584"},
           {"label": "Mwingi", "value": "C64585"}],
 "Klaipeda District Municipality": [{"label": "Gargzdai", "value": "C65112"},
                                    {"label": "Klaipeda", "value": "C65113"},
                                    {"label": "Kretinga", "value": "C65114"},
                                    {"label": "Neringa", "value": "C65115"},
                                    {"label": "Nida", "value": "C65116"},
                                    {"label": "Palanga", "value": "C65117"},
                                    {"label": "Priekule", "value": "C65118"},
                                    {"label": "Rusne", "value": "C65119"},
                                    {"label": "Salantai", "value": "C65120"},
                                    {"label": "Silute", "value": "C65121"},
                                    {"label": "Skuodas", "value": "C65122"},
                                    {"label": "Sventoji", "value": "C65123"},
                                    {"label": "Zemaiciu Naumiestis",
                                     "value": "C65124"}],
 "Kobarid Municipality": [{"label": "Kobarid", "value": "C107660"}],
 "Kobilje Municipality": [{"label": "Kobilje", "value": "C107661"}],
 "Kocaeli": [{"label": "Bahcecik", "value": "C120608"},
             {"label": "Balcik", "value": "C120609"},
             {"label": "Basiskele", "value": "C120610"},
             {"label": "Cayirova", "value": "C120611"},
             {"label": "Darica", "value": "C120612"},
             {"label": "Derince", "value": "C120613"},
             {"label": "Dilovasi", "value": "C120614"},
             {"label": "Gebze", "value": "C120615"},
             {"label": "Golcuk", "value": "C120616"},
             {"label": "Halidere", "value": "C120617"},
             {"label": "Ihsaniye", "value": "C120618"},
             {"label": "Izmit", "value": "C120619"},
             {"label": "Kandira", "value": "C120620"},
             {"label": "Karamursel", "value": "C120621"},
             {"label": "Karsiyaka", "value": "C120622"},
             {"label": "Kartepe", "value": "C120623"},
             {"label": "Kefken", "value": "C120624"},
             {"label": "Korfez", "value": "C120625"},
             {"label": "Kosekoy", "value": "C120626"},
             {"label": "Kullar", "value": "C120627"},
             {"label": "Tavsancil", "value": "C120628"},
             {"label": "Tavsanli", "value": "C120629"},
             {"label": "Ulasli", "value": "C120630"},
             {"label": "Yalakdere", "value": "C120631"},
             {"label": "Yuvacik", "value": "C120632"}],
 "Kocani Municipality": [{"label": "Kochani", "value": "C65437"},
                         {"label": "Orizari", "value": "C65438"}],
 "Koceni Municipality": [{"label": "Koceni", "value": "C64887"}],
 "Kocevje Municipality": [{"label": "Kocevje", "value": "C107662"}],
 "Kochi Prefecture": [{"label": "Achi", "value": "C63285"},
                      {"label": "Agematsu", "value": "C63286"},
                      {"label": "Aki", "value": "C63287"},
                      {"label": "Anan", "value": "C63288"},
                      {"label": "Aoki", "value": "C63289"},
                      {"label": "Asahi", "value": "C63290"},
                      {"label": "Azumino", "value": "C63291"},
                      {"label": "Chikuhoku", "value": "C63292"},
                      {"label": "Chikuma", "value": "C63293"},
                      {"label": "Chino", "value": "C63294"},
                      {"label": "Fujimi", "value": "C63295"},
                      {"label": "Geisei", "value": "C63296"},
                      {"label": "Hakuba", "value": "C63297"},
                      {"label": "Hara", "value": "C63298"},
                      {"label": "Hidaka", "value": "C63299"},
                      {"label": "Hiraya", "value": "C63300"},
                      {"label": "Iida", "value": "C63301"},
                      {"label": "Iijima", "value": "C63302"},
                      {"label": "Iiyama", "value": "C63303"},
                      {"label": "Iizuna", "value": "C63304"},
                      {"label": "Ikeda", "value": "C63305"},
                      {"label": "Ikusaka", "value": "C63306"},
                      {"label": "Ina", "value": "C63307"},
                      {"label": "Ino", "value": "C63308"},
                      {"label": "Karuizawa", "value": "C63309"},
                      {"label": "Kawakami", "value": "C63310"},
                      {"label": "Kijimadaira", "value": "C63311"},
                      {"label": "Kiso", "value": "C63312"},
                      {"label": "Kitaaiki", "value": "C63313"},
                      {"label": "Kitagawa", "value": "C63314"},
                      {"label": "Kochi", "value": "C63315"},
                      {"label": "Komoro", "value": "C63316"},
                      {"label": "Konan", "value": "C63317"},
                      {"label": "Kuroshio", "value": "C63318"},
                      {"label": "Matsukawa", "value": "C63319"},
                      {"label": "Matsumoto", "value": "C63320"},
                      {"label": "Mihara", "value": "C63321"},
                      {"label": "Minamiaiki", "value": "C63322"},
                      {"label": "Minamimaki", "value": "C63323"},
                      {"label": "Minamiminowa", "value": "C63324"},
                      {"label": "Minowa", "value": "C63325"},
                      {"label": "Mitsushima", "value": "C63326"},
                      {"label": "Miyada", "value": "C63327"},
                      {"label": "Miyota", "value": "C63328"},
                      {"label": "Motoyama", "value": "C63329"},
                      {"label": "Muroto", "value": "C63330"},
                      {"label": "Nagano", "value": "C63331"},
                      {"label": "Nagawa", "value": "C63332"},
                      {"label": "Nagiso", "value": "C63333"},
                      {"label": "Nahari", "value": "C63334"},
                      {"label": "Nakagawa", "value": "C63335"},
                      {"label": "Nakajo", "value": "C63336"},
                      {"label": "Nakamura", "value": "C63337"},
                      {"label": "Nakano", "value": "C63338"},
                      {"label": "Nakatosa", "value": "C63339"},
                      {"label": "Nankoku", "value": "C63340"},
                      {"label": "Neba", "value": "C63341"},
                      {"label": "Niyodogawa", "value": "C63342"},
                      {"label": "Nozawaonsen", "value": "C63343"},
                      {"label": "Obuse", "value": "C63344"},
                      {"label": "Ochi", "value": "C63345"},
                      {"label": "Ogawa", "value": "C63346"},
                      {"label": "Okawa", "value": "C63347"},
                      {"label": "Okaya", "value": "C63348"},
                      {"label": "Okuwa", "value": "C63349"},
                      {"label": "Omachi", "value": "C63350"},
                      {"label": "Omi", "value": "C63351"},
                      {"label": "Oshika", "value": "C63352"},
                      {"label": "Otaki", "value": "C63353"},
                      {"label": "Otari", "value": "C63354"},
                      {"label": "Otoyo", "value": "C63355"},
                      {"label": "Otsuki", "value": "C63356"},
                      {"label": "Sakae", "value": "C63357"},
                      {"label": "Sakaki", "value": "C63358"},
                      {"label": "Sakawa", "value": "C63359"},
                      {"label": "Saku", "value": "C63360"},
                      {"label": "Sakuho", "value": "C63361"},
                      {"label": "Sanada", "value": "C63362"},
                      {"label": "Shimanto", "value": "C63363"},
                      {"label": "Shimojo", "value": "C63364"},
                      {"label": "Shimosuwa", "value": "C63365"},
                      {"label": "Shinano", "value": "C63366"},
                      {"label": "Shiojiri", "value": "C63367"},
                      {"label": "Sukumo", "value": "C63368"},
                      {"label": "Susaki", "value": "C63369"},
                      {"label": "Suwa", "value": "C63370"},
                      {"label": "Suzaka", "value": "C63371"},
                      {"label": "Takagi", "value": "C63372"},
                      {"label": "Takamori", "value": "C63373"},
                      {"label": "Takayama", "value": "C63374"},
                      {"label": "Tano", "value": "C63375"},
                      {"label": "Tateshina", "value": "C63376"},
                      {"label": "Tatsuno", "value": "C63377"},
                      {"label": "Togari", "value": "C63378"},
                      {"label": "Tomi", "value": "C63379"},
                      {"label": "Tosa", "value": "C63380"},
                      {"label": "Tosashimizu", "value": "C63381"},
                      {"label": "Toyo", "value": "C63382"},
                      {"label": "Toyooka", "value": "C63383"},
                      {"label": "Tsuno", "value": "C63384"},
                      {"label": "Ueda", "value": "C63385"},
                      {"label": "Umaji", "value": "C63386"},
                      {"label": "Urugi", "value": "C63387"},
                      {"label": "Usa", "value": "C63388"},
                      {"label": "Yamagata", "value": "C63389"},
                      {"label": "Yamanouchi", "value": "C63390"},
                      {"label": "Yasuda", "value": "C63391"},
                      {"label": "Yasuoka", "value": "C63392"},
                      {"label": "Yawata", "value": "C63393"},
                      {"label": "Yusuhara", "value": "C63394"}],
 "Kogi": [{"label": "Abocho", "value": "C78353"},
          {"label": "Adoru", "value": "C78354"},
          {"label": "Ankpa", "value": "C78355"},
          {"label": "Bugana", "value": "C78356"},
          {"label": "Dekina", "value": "C78357"},
          {"label": "Egbe", "value": "C78358"},
          {"label": "Icheu", "value": "C78359"},
          {"label": "Idah", "value": "C78360"},
          {"label": "Isanlu-Itedoijowa", "value": "C78361"},
          {"label": "Kabba", "value": "C78362"},
          {"label": "Koton-Karfe", "value": "C78363"},
          {"label": "Lokoja", "value": "C78364"},
          {"label": "Ogaminana", "value": "C78365"},
          {"label": "Ogurugu", "value": "C78366"},
          {"label": "Okene", "value": "C78367"}],
 "Koh Kong": [{"label": "Koh Kong", "value": "C16496"},
              {"label": "Smach Mean Chey", "value": "C16497"},
              {"label": "Srae Ambel", "value": "C16498"},
              {"label": "Srok Batum Sakor", "value": "C16499"},
              {"label": "Srok Mondol Seima", "value": "C16500"}],
 "Kohgiluyeh and Boyer-Ahmad": [{"label": "Dehdasht", "value": "C50852"},
                                {"label": "Dogonbadan", "value": "C50853"},
                                {"label": "Landeh", "value": "C50854"},
                                {"label": "Shahrestan-e Bahmai",
                                 "value": "C50855"},
                                {"label": "Shahrestan-e Basht",
                                 "value": "C50856"},
                                {"label": "Shahrestan-e Bowyer Ahmad",
                                 "value": "C50857"},
                                {"label": "Shahrestan-e Charam",
                                 "value": "C50858"},
                                {"label": "Shahrestan-e Dana",
                                 "value": "C50859"},
                                {"label": "Shahrestan-e Gachsaran",
                                 "value": "C50860"},
                                {"label": "Shahrestan-e Kohgiluyeh",
                                 "value": "C50861"},
                                {"label": "Yasuj", "value": "C50862"}],
 "Koknese Municipality": [{"label": "Koknese", "value": "C64888"}],
 "Kolasin Municipality": [{"label": "Kolasin", "value": "C75606"}],
 "Kolda": [{"label": "Kolda", "value": "C106851"},
           {"label": "Kolda Department", "value": "C106852"},
           {"label": "Marsassoum", "value": "C106853"},
           {"label": "Velingara", "value": "C106854"}],
 "Komarom-Esztergom": [{"label": "acs", "value": "C44323"},
                       {"label": "Aka", "value": "C44324"},
                       {"label": "Almasfuzito", "value": "C44325"},
                       {"label": "aszar", "value": "C44326"},
                       {"label": "Babolna", "value": "C44327"},
                       {"label": "Bajot", "value": "C44328"},
                       {"label": "Bakonybank", "value": "C44329"},
                       {"label": "Bakonysarkany", "value": "C44330"},
                       {"label": "Bakonyszombathely", "value": "C44331"},
                       {"label": "Bana", "value": "C44332"},
                       {"label": "Banhida", "value": "C44333"},
                       {"label": "Barsonyos", "value": "C44334"},
                       {"label": "Csaszar", "value": "C44335"},
                       {"label": "Csatka", "value": "C44336"},
                       {"label": "Csem", "value": "C44337"},
                       {"label": "Csep", "value": "C44338"},
                       {"label": "Csolnok", "value": "C44339"},
                       {"label": "Dad", "value": "C44340"},
                       {"label": "Dag", "value": "C44341"},
                       {"label": "Domos", "value": "C44342"},
                       {"label": "Dorog", "value": "C44343"},
                       {"label": "Dunaalmas", "value": "C44344"},
                       {"label": "Dunaszentmiklos", "value": "C44345"},
                       {"label": "Epol", "value": "C44346"},
                       {"label": "Esztergom", "value": "C44347"},
                       {"label": "Ete", "value": "C44348"},
                       {"label": "Gyermely", "value": "C44349"},
                       {"label": "Hereg", "value": "C44350"},
                       {"label": "Kecsked", "value": "C44351"},
                       {"label": "Kerekteleki", "value": "C44352"},
                       {"label": "Kesztolc", "value": "C44353"},
                       {"label": "Kisber", "value": "C44354"},
                       {"label": "Kisigmand", "value": "C44355"},
                       {"label": "Kocs", "value": "C44356"},
                       {"label": "Komarom", "value": "C44357"},
                       {"label": "Komlod", "value": "C44358"},
                       {"label": "Kornye", "value": "C44359"},
                       {"label": "Labatlan", "value": "C44360"},
                       {"label": "Leanyvar", "value": "C44361"},
                       {"label": "Mariahalom", "value": "C44362"},
                       {"label": "Mocsa", "value": "C44363"},
                       {"label": "Mogyorosbanya", "value": "C44364"},
                       {"label": "Nagyigmand", "value": "C44365"},
                       {"label": "Nagysap", "value": "C44366"},
                       {"label": "Naszaly", "value": "C44367"},
                       {"label": "Neszmely", "value": "C44368"},
                       {"label": "Nyergesujfalu", "value": "C44369"},
                       {"label": "Oroszlany", "value": "C44370"},
                       {"label": "Piliscsev", "value": "C44371"},
                       {"label": "Pilismarot", "value": "C44372"},
                       {"label": "Rede", "value": "C44373"},
                       {"label": "Sarisap", "value": "C44374"},
                       {"label": "Sur", "value": "C44375"},
                       {"label": "Sutto", "value": "C44376"},
                       {"label": "Szakszend", "value": "C44377"},
                       {"label": "Szomod", "value": "C44378"},
                       {"label": "Szomor", "value": "C44379"},
                       {"label": "Szony", "value": "C44380"},
                       {"label": "Tardos", "value": "C44381"},
                       {"label": "Tarjan", "value": "C44382"},
                       {"label": "Tarkany", "value": "C44383"},
                       {"label": "Tatabanya", "value": "C44384"},
                       {"label": "Tokodaltaro", "value": "C44385"},
                       {"label": "Uny", "value": "C44386"},
                       {"label": "Vargesztes", "value": "C44387"},
                       {"label": "Verteskethely", "value": "C44388"},
                       {"label": "Vertessomlo", "value": "C44389"},
                       {"label": "Vertesszolos", "value": "C44390"},
                       {"label": "Vertestolna", "value": "C44391"}],
 "Komen Municipality": [{"label": "Komen", "value": "C107663"}],
 "Komenda Municipality": [{"label": "Komenda", "value": "C107664"},
                          {"label": "Moste", "value": "C107665"}],
 "Komi Republic": [{"label": "Aykino", "value": "C101444"},
                   {"label": "Blagoyevo", "value": "C101445"},
                   {"label": "Borovoy", "value": "C101446"},
                   {"label": "Ezhva", "value": "C101447"},
                   {"label": "Inta", "value": "C101448"},
                   {"label": "Izhma", "value": "C101449"},
                   {"label": "Knyazhpogostskiy Rayon", "value": "C101450"},
                   {"label": "Kortkeros", "value": "C101451"},
                   {"label": "Koslan", "value": "C101452"},
                   {"label": "Koygorodok", "value": "C101453"},
                   {"label": "Koygorodskiy Rayon", "value": "C101454"},
                   {"label": "Kozhva", "value": "C101455"},
                   {"label": "Letka", "value": "C101456"},
                   {"label": "Mezhdurechensk", "value": "C101457"},
                   {"label": "Mikun", "value": "C101458"},
                   {"label": "Mordino", "value": "C101459"},
                   {"label": "Nizhniy Odes", "value": "C101460"},
                   {"label": "Parma", "value": "C101461"},
                   {"label": "Pechora", "value": "C101462"},
                   {"label": "Priluzskiy Rayon", "value": "C101463"},
                   {"label": "Promyshlennyy", "value": "C101464"},
                   {"label": "Puteyets", "value": "C101465"},
                   {"label": "Sedkyrkeshch", "value": "C101466"},
                   {"label": "Shudayag", "value": "C101467"},
                   {"label": "Sindor", "value": "C101468"},
                   {"label": "Sosnogorsk", "value": "C101469"},
                   {"label": "Sovetskiy", "value": "C101470"},
                   {"label": "Syktyvkar", "value": "C101471"},
                   {"label": "Synya", "value": "C101472"},
                   {"label": "Troitsko-Pechorsk", "value": "C101473"},
                   {"label": "Troitsko-Pechorskiy Rayon", "value": "C101474"},
                   {"label": "Tsementnozavodskiy", "value": "C101475"},
                   {"label": "Ukhta", "value": "C101476"},
                   {"label": "Usinsk", "value": "C101477"},
                   {"label": "Usogorsk", "value": "C101478"},
                   {"label": "Ust-Tsilma", "value": "C101479"},
                   {"label": "Ust-Kulom", "value": "C101480"},
                   {"label": "Ust-Tsilemskiy Rayon", "value": "C101481"},
                   {"label": "Verkhnyaya Inta", "value": "C101482"},
                   {"label": "Verkhnyaya Maksakovka", "value": "C101483"},
                   {"label": "Vizinga", "value": "C101484"},
                   {"label": "Vodnyy", "value": "C101485"},
                   {"label": "Vorgashor", "value": "C101486"},
                   {"label": "Vorkuta", "value": "C101487"},
                   {"label": "Voyvozh", "value": "C101488"},
                   {"label": "Vuktyl", "value": "C101489"},
                   {"label": "Yarega", "value": "C101490"},
                   {"label": "Yemva", "value": "C101491"},
                   {"label": "Zapolyarnyy", "value": "C101492"},
                   {"label": "Zelenets", "value": "C101493"},
                   {"label": "Zheshart", "value": "C101494"}],
 "Kon Tum": [{"label": "Huyen Dak Glei", "value": "C147467"},
             {"label": "Huyen Dak Ha", "value": "C147468"},
             {"label": "Huyen Dak To", "value": "C147469"},
             {"label": "Huyen Ia HDrai", "value": "C147470"},
             {"label": "Huyen Kon Plong", "value": "C147471"},
             {"label": "Huyen Ngoc Hoi", "value": "C147472"},
             {"label": "Huyen Sa Thay", "value": "C147473"},
             {"label": "Kon Tum", "value": "C147474"}],
 "Konce Municipality": [{"label": "Konce", "value": "C65439"}],
 "Kongo Central": [{"label": "Boma", "value": "C23335"},
                   {"label": "Kasangulu", "value": "C23336"},
                   {"label": "Matadi", "value": "C23337"},
                   {"label": "Mbanza-Ngungu", "value": "C23338"},
                   {"label": "Moanda", "value": "C23339"},
                   {"label": "Tshela", "value": "C23340"}],
 "Konya": [{"label": "Ahirli Ilcesi", "value": "C120633"},
           {"label": "Akoren Ilcesi", "value": "C120634"},
           {"label": "Aksehir", "value": "C120635"},
           {"label": "Altinekin", "value": "C120636"},
           {"label": "Beysehir", "value": "C120637"},
           {"label": "Bozkir", "value": "C120638"},
           {"label": "Catalhoyuk", "value": "C120639"},
           {"label": "Celtik Ilcesi", "value": "C120640"},
           {"label": "Cihanbeyli", "value": "C120641"},
           {"label": "Cihanbeyli District", "value": "C120642"},
           {"label": "Cumra", "value": "C120643"},
           {"label": "Derbent", "value": "C120644"},
           {"label": "Derebucak", "value": "C120645"},
           {"label": "Doganhisar", "value": "C120646"},
           {"label": "Emirgazi", "value": "C120647"},
           {"label": "Eregli", "value": "C120648"},
           {"label": "Guneysinir Ilcesi", "value": "C120649"},
           {"label": "Hadim", "value": "C120650"},
           {"label": "Halkapinar", "value": "C120651"},
           {"label": "Huyuk", "value": "C120652"},
           {"label": "Ilgin", "value": "C120653"},
           {"label": "Kadinhani", "value": "C120654"},
           {"label": "Karapinar", "value": "C120655"},
           {"label": "Karatay", "value": "C120656"},
           {"label": "Konya", "value": "C120657"},
           {"label": "Kulu", "value": "C120658"},
           {"label": "Kuyulusebil", "value": "C120659"},
           {"label": "Meram Ilcesi", "value": "C120660"},
           {"label": "Ovakavagi", "value": "C120661"},
           {"label": "Sarayonu", "value": "C120662"},
           {"label": "Selcuklu", "value": "C120663"},
           {"label": "Seydisehir", "value": "C120664"},
           {"label": "Taskent", "value": "C120665"},
           {"label": "Tuzlukcu", "value": "C120666"},
           {"label": "Yalihuyuk", "value": "C120667"},
           {"label": "Yarma", "value": "C120668"},
           {"label": "Yeniceoba", "value": "C120669"},
           {"label": "Yunak", "value": "C120670"}],
 "Koper City Municipality": [{"label": "Dekani", "value": "C107666"},
                             {"label": "Hrvatini", "value": "C107667"},
                             {"label": "Koper", "value": "C107668"},
                             {"label": "Pobegi", "value": "C107669"},
                             {"label": "Prade", "value": "C107670"},
                             {"label": "Spodnje Skofije", "value": "C107671"},
                             {"label": "Sv. Anton", "value": "C107672"}],
 "Koprivnica-Krizevci": [{"label": "Drnje", "value": "C21144"},
                         {"label": "Delekovec", "value": "C21145"},
                         {"label": "Durdevac", "value": "C21146"},
                         {"label": "Ferdinandovac", "value": "C21147"},
                         {"label": "Gola", "value": "C21148"},
                         {"label": "Gornja Rijeka", "value": "C21149"},
                         {"label": "Grad Koprivnica", "value": "C21150"},
                         {"label": "Grad Krizevci", "value": "C21151"},
                         {"label": "Hlebine", "value": "C21152"},
                         {"label": "Kalinovac", "value": "C21153"},
                         {"label": "Koprivnica", "value": "C21154"},
                         {"label": "Koprivnicki Ivanec", "value": "C21155"},
                         {"label": "Krizevci", "value": "C21156"},
                         {"label": "Legrad", "value": "C21157"},
                         {"label": "Molve", "value": "C21158"},
                         {"label": "Novo Virje", "value": "C21159"},
                         {"label": "Peteranec", "value": "C21160"},
                         {"label": "Rasinja", "value": "C21161"},
                         {"label": "Reka", "value": "C21162"},
                         {"label": "Sigetec", "value": "C21163"},
                         {"label": "Virje", "value": "C21164"}],
 "Korce County": [{"label": "Bashkia Devoll", "value": "C154"},
                  {"label": "Bashkia Kolonje", "value": "C155"},
                  {"label": "Bashkia Maliq", "value": "C156"},
                  {"label": "Bashkia Pustec", "value": "C157"},
                  {"label": "Bilisht", "value": "C158"},
                  {"label": "Erseke", "value": "C159"},
                  {"label": "Korce", "value": "C160"},
                  {"label": "Leskovik", "value": "C161"},
                  {"label": "Libonik", "value": "C162"},
                  {"label": "Maliq", "value": "C163"},
                  {"label": "Mborje", "value": "C164"},
                  {"label": "Pogradec", "value": "C165"},
                  {"label": "Rrethi i Devollit", "value": "C166"},
                  {"label": "Rrethi i Kolonjes", "value": "C167"},
                  {"label": "Velcan", "value": "C168"},
                  {"label": "Voskopoje", "value": "C169"}],
 "Koror": [{"label": "Koror", "value": "C79775"},
           {"label": "Koror Town", "value": "C79776"}],
 "Kosice Region": [{"label": "Cierna nad Tisou", "value": "C107384"},
                   {"label": "Dobsina", "value": "C107385"},
                   {"label": "Gelnica", "value": "C107386"},
                   {"label": "Kavecany", "value": "C107387"},
                   {"label": "Kosice", "value": "C107388"},
                   {"label": "Kosice I", "value": "C107389"},
                   {"label": "Kosice II", "value": "C107390"},
                   {"label": "Kosice III", "value": "C107391"},
                   {"label": "Kosice IV", "value": "C107392"},
                   {"label": "Krompachy", "value": "C107393"},
                   {"label": "Medzev", "value": "C107394"},
                   {"label": "Michalovce", "value": "C107395"},
                   {"label": "Moldava nad Bodvou", "value": "C107396"},
                   {"label": "Okres Gelnica", "value": "C107397"},
                   {"label": "Okres Kosice-okolie", "value": "C107398"},
                   {"label": "Okres Michalovce", "value": "C107399"},
                   {"label": "Okres Roznava", "value": "C107400"},
                   {"label": "Okres Sobrance", "value": "C107401"},
                   {"label": "Okres Spisska Nova Ves", "value": "C107402"},
                   {"label": "Okres Trebisov", "value": "C107403"},
                   {"label": "Roznava", "value": "C107404"},
                   {"label": "Secovce", "value": "C107405"},
                   {"label": "Sobrance", "value": "C107406"},
                   {"label": "Spisska Nova Ves", "value": "C107407"},
                   {"label": "Strazske", "value": "C107408"},
                   {"label": "Trebisov", "value": "C107409"},
                   {"label": "Vinne", "value": "C107410"},
                   {"label": "Zehra", "value": "C107411"}],
 "Kosrae State": [{"label": "Lelu Municipality", "value": "C75446"},
                  {"label": "Malem Municipality", "value": "C75447"},
                  {"label": "Tafunsak Municipality", "value": "C75448"},
                  {"label": "Tofol", "value": "C75449"},
                  {"label": "Utwe Municipality", "value": "C75450"}],
 "Kostanay Region": [{"label": "Arkalyk", "value": "C64431"},
                     {"label": "Ayat", "value": "C64432"},
                     {"label": "Borovskoy", "value": "C64433"},
                     {"label": "Dzhetygara", "value": "C64434"},
                     {"label": "Fyodorovka", "value": "C64435"},
                     {"label": "Karasu", "value": "C64436"},
                     {"label": "Komsomolets", "value": "C64437"},
                     {"label": "Kostanay", "value": "C64438"},
                     {"label": "Lisakovsk", "value": "C64439"},
                     {"label": "Ordzhonikidze", "value": "C64440"},
                     {"label": "Qashar", "value": "C64441"},
                     {"label": "Qusmuryn", "value": "C64442"},
                     {"label": "Rudnyy", "value": "C64443"},
                     {"label": "Tobol", "value": "C64444"},
                     {"label": "Torghay", "value": "C64445"},
                     {"label": "Troyebratskiy", "value": "C64446"}],
 "Kostel Municipality": [{"label": "Kostel", "value": "C107673"}],
 "Kostroma Oblast": [{"label": "Antropovo", "value": "C101495"},
                     {"label": "Antropovskiy Rayon", "value": "C101496"},
                     {"label": "Bogovarovo", "value": "C101497"},
                     {"label": "Buy", "value": "C101498"},
                     {"label": "Buyskiy Rayon", "value": "C101499"},
                     {"label": "Chistyye Bory", "value": "C101500"},
                     {"label": "Chukhloma", "value": "C101501"},
                     {"label": "Galich", "value": "C101502"},
                     {"label": "Galichskiy Rayon", "value": "C101503"},
                     {"label": "Georgiyevskoye", "value": "C101504"},
                     {"label": "Kadyy", "value": "C101505"},
                     {"label": "Kadyyskiy Rayon", "value": "C101506"},
                     {"label": "Kologriv", "value": "C101507"},
                     {"label": "Kosmynino", "value": "C101508"},
                     {"label": "Kostroma", "value": "C101509"},
                     {"label": "Kostromskoy Rayon", "value": "C101510"},
                     {"label": "Krasnoselskiy Rayon", "value": "C101511"},
                     {"label": "Krasnoye-na-Volge", "value": "C101512"},
                     {"label": "Makaryev", "value": "C101513"},
                     {"label": "Manturovo", "value": "C101514"},
                     {"label": "Manturovskiy Rayon", "value": "C101515"},
                     {"label": "Nerekhta", "value": "C101516"},
                     {"label": "Nerekhtskiy Rayon", "value": "C101517"},
                     {"label": "Neya", "value": "C101518"},
                     {"label": "Neyskiy Rayon", "value": "C101519"},
                     {"label": "Ostrovskiy Rayon", "value": "C101520"},
                     {"label": "Ostrovskoye", "value": "C101521"},
                     {"label": "Parfenyevo", "value": "C101522"},
                     {"label": "Parfenyevskiy Rayon", "value": "C101523"},
                     {"label": "Pavino", "value": "C101524"},
                     {"label": "Ponazyrevo", "value": "C101525"},
                     {"label": "Ponazyrevskiy Rayon", "value": "C101526"},
                     {"label": "Pyshchug", "value": "C101527"},
                     {"label": "Sharinskiy Rayon", "value": "C101528"},
                     {"label": "Sharya", "value": "C101529"},
                     {"label": "Soligalich", "value": "C101530"},
                     {"label": "Soligalichskiy Rayon", "value": "C101531"},
                     {"label": "Sudislavl", "value": "C101532"},
                     {"label": "Sudislavskiy Rayon", "value": "C101533"},
                     {"label": "Susanino", "value": "C101534"},
                     {"label": "Vetluzhskiy", "value": "C101535"},
                     {"label": "Vokhma", "value": "C101536"},
                     {"label": "Vokhomskiy Rayon", "value": "C101537"},
                     {"label": "Volgorechensk", "value": "C101538"}],
 "Kotayk Region": [{"label": "Abovyan", "value": "C1709"},
                   {"label": "Aghavnadzor", "value": "C1710"},
                   {"label": "Akunk", "value": "C1711"},
                   {"label": "Aramus", "value": "C1712"},
                   {"label": "Argel", "value": "C1713"},
                   {"label": "Arzakan", "value": "C1714"},
                   {"label": "Arzni", "value": "C1715"},
                   {"label": "Balahovit", "value": "C1716"},
                   {"label": "Bjni", "value": "C1717"},
                   {"label": "Buzhakan", "value": "C1718"},
                   {"label": "Byureghavan", "value": "C1719"},
                   {"label": "Dzoraghbyur", "value": "C1720"},
                   {"label": "Fantan", "value": "C1721"},
                   {"label": "Garrni", "value": "C1722"},
                   {"label": "Goght", "value": "C1723"},
                   {"label": "Hrazdan", "value": "C1724"},
                   {"label": "Kaputan", "value": "C1725"},
                   {"label": "Kotayk", "value": "C1726"},
                   {"label": "Lerrnanist", "value": "C1727"},
                   {"label": "Mayakovski", "value": "C1728"},
                   {"label": "Meghradzor", "value": "C1729"},
                   {"label": "Mrgashen", "value": "C1730"},
                   {"label": "Nor Geghi", "value": "C1731"},
                   {"label": "Nor Gyugh", "value": "C1732"},
                   {"label": "Prroshyan", "value": "C1733"},
                   {"label": "Ptghni", "value": "C1734"},
                   {"label": "Solak", "value": "C1735"},
                   {"label": "Tsaghkadzor", "value": "C1736"},
                   {"label": "Yeghvard", "value": "C1737"},
                   {"label": "Zarr", "value": "C1738"},
                   {"label": "Zoravan", "value": "C1739"},
                   {"label": "Zovaber", "value": "C1740"}],
 "Kotor Municipality": [{"label": "Dobrota", "value": "C75607"},
                        {"label": "Kotor", "value": "C75608"},
                        {"label": "Prcanj", "value": "C75609"},
                        {"label": "Risan", "value": "C75610"}],
 "Kouffo Department": [{"label": "Djakotomey", "value": "C9617"},
                       {"label": "Dogbo", "value": "C9618"}],
 "Koulikoro Region": [{"label": "Banamba", "value": "C65836"},
                      {"label": "Kangaba", "value": "C65837"},
                      {"label": "Kati", "value": "C65838"},
                      {"label": "Kolokani", "value": "C65839"},
                      {"label": "Koulikoro", "value": "C65840"}],
 "Kozje Municipality": [{"label": "Kozje", "value": "C107674"}],
 "Krabi": [{"label": "Amphoe Ao Luek", "value": "C118699"},
           {"label": "Amphoe Khao Phanom", "value": "C118700"},
           {"label": "Amphoe Khlong Thom", "value": "C118701"},
           {"label": "Amphoe Ko Lanta", "value": "C118702"},
           {"label": "Amphoe Lam Thap", "value": "C118703"},
           {"label": "Amphoe Mueang Krabi", "value": "C118704"},
           {"label": "Amphoe Nuea Khlong", "value": "C118705"},
           {"label": "Amphoe Plai Phraya", "value": "C118706"},
           {"label": "Ao Luek", "value": "C118707"},
           {"label": "Ko Lanta", "value": "C118708"},
           {"label": "Krabi", "value": "C118709"},
           {"label": "Nuea Khlong", "value": "C118710"},
           {"label": "Saladan", "value": "C118711"}],
 "Kraj Vysocina": [{"label": "Batelov", "value": "C22272"},
                   {"label": "Bohdalov", "value": "C22273"},
                   {"label": "Brtnice", "value": "C22274"},
                   {"label": "Budisov", "value": "C22275"},
                   {"label": "Bystrice nad Pernstejnem", "value": "C22276"},
                   {"label": "Cernovice", "value": "C22277"},
                   {"label": "Chotebor", "value": "C22278"},
                   {"label": "Dobronin", "value": "C22279"},
                   {"label": "Dolni Cerekev", "value": "C22280"},
                   {"label": "Golcuv Jenikov", "value": "C22281"},
                   {"label": "Habry", "value": "C22282"},
                   {"label": "Havlickuv Brod", "value": "C22283"},
                   {"label": "Heralec", "value": "C22284"},
                   {"label": "Horni Cerekev", "value": "C22285"},
                   {"label": "Hrotovice", "value": "C22286"},
                   {"label": "Humpolec", "value": "C22287"},
                   {"label": "Jaromerice nad Rokytnou", "value": "C22288"},
                   {"label": "Jemnice", "value": "C22289"},
                   {"label": "Jihlava", "value": "C22290"},
                   {"label": "Jimramov", "value": "C22291"},
                   {"label": "Kamenice", "value": "C22292"},
                   {"label": "Kamenice nad Lipou", "value": "C22293"},
                   {"label": "Knezice", "value": "C22294"},
                   {"label": "Krizanov", "value": "C22295"},
                   {"label": "Krizova", "value": "C22296"},
                   {"label": "Ledec nad Sazavou", "value": "C22297"},
                   {"label": "Lipa", "value": "C22298"},
                   {"label": "Luka nad Jihlavou", "value": "C22299"},
                   {"label": "Lukavec", "value": "C22300"},
                   {"label": "Merin", "value": "C22301"},
                   {"label": "Mohelno", "value": "C22302"},
                   {"label": "Moravske Budejovice", "value": "C22303"},
                   {"label": "Namest nad Oslavou", "value": "C22304"},
                   {"label": "Nova Cerekev", "value": "C22305"},
                   {"label": "Nove Mesto na Morave", "value": "C22306"},
                   {"label": "Nove Syrovice", "value": "C22307"},
                   {"label": "Nove Veseli", "value": "C22308"},
                   {"label": "Okres Havlickuv Brod", "value": "C22309"},
                   {"label": "Okres Jihlava", "value": "C22310"},
                   {"label": "Okres Pelhrimov", "value": "C22311"},
                   {"label": "Okres Trebic", "value": "C22312"},
                   {"label": "Okres Zdar nad Sazavou", "value": "C22313"},
                   {"label": "Okrisky", "value": "C22314"},
                   {"label": "Okrouhlice", "value": "C22315"},
                   {"label": "Pacov", "value": "C22316"},
                   {"label": "Pelhrimov", "value": "C22317"},
                   {"label": "Pocatky", "value": "C22318"},
                   {"label": "Polna", "value": "C22319"},
                   {"label": "Pribyslav", "value": "C22320"},
                   {"label": "Rouchovany", "value": "C22321"},
                   {"label": "Starec", "value": "C22322"},
                   {"label": "Stoky", "value": "C22323"},
                   {"label": "Svetla nad Sazavou", "value": "C22324"},
                   {"label": "Svratka", "value": "C22325"},
                   {"label": "Telc", "value": "C22326"},
                   {"label": "Trebic", "value": "C22327"},
                   {"label": "Trest", "value": "C22328"},
                   {"label": "Velka Bites", "value": "C22329"},
                   {"label": "Velke Mezirici", "value": "C22330"},
                   {"label": "Velky Beranov", "value": "C22331"},
                   {"label": "Vilemov", "value": "C22332"},
                   {"label": "Vladislav", "value": "C22333"},
                   {"label": "Zdar nad Sazavou", "value": "C22334"},
                   {"label": "Zdar nad Sazavou Druhy", "value": "C22335"},
                   {"label": "Zeletava", "value": "C22336"},
                   {"label": "Zeliv", "value": "C22337"},
                   {"label": "Zirovnice", "value": "C22338"}],
 "Kralovehradecky kraj": [{"label": "Albrechtice nad Orlici",
                           "value": "C22339"},
                          {"label": "Bila Tremesna", "value": "C22340"},
                          {"label": "Borohradek", "value": "C22341"},
                          {"label": "Broumov", "value": "C22342"},
                          {"label": "Castolovice", "value": "C22343"},
                          {"label": "Cernilov", "value": "C22344"},
                          {"label": "Cernozice", "value": "C22345"},
                          {"label": "Cerveny Kostelec", "value": "C22346"},
                          {"label": "Ceska Skalice", "value": "C22347"},
                          {"label": "Ceske Mezirici", "value": "C22348"},
                          {"label": "Chlumec nad Cidlinou", "value": "C22349"},
                          {"label": "Dobruska", "value": "C22350"},
                          {"label": "Dolni Cernilov", "value": "C22351"},
                          {"label": "Doudleby nad Orlici", "value": "C22352"},
                          {"label": "Dvur Kralove nad Labem",
                           "value": "C22353"},
                          {"label": "Horice", "value": "C22354"},
                          {"label": "Hostinne", "value": "C22355"},
                          {"label": "Hradec Kralove", "value": "C22356"},
                          {"label": "Hronov", "value": "C22357"},
                          {"label": "Jaromer", "value": "C22358"},
                          {"label": "Jicin", "value": "C22359"},
                          {"label": "Kopidlno", "value": "C22360"},
                          {"label": "Kostelec nad Orlici", "value": "C22361"},
                          {"label": "Kvasiny", "value": "C22362"},
                          {"label": "Lazne Belohrad", "value": "C22363"},
                          {"label": "Liban", "value": "C22364"},
                          {"label": "Machov", "value": "C22365"},
                          {"label": "Male Svatonovice", "value": "C22366"},
                          {"label": "Mezimesti", "value": "C22367"},
                          {"label": "Mlade Buky", "value": "C22368"},
                          {"label": "Mostek", "value": "C22369"},
                          {"label": "Nachod", "value": "C22370"},
                          {"label": "Nechanice", "value": "C22371"},
                          {"label": "Nova Paka", "value": "C22372"},
                          {"label": "Nove Mesto nad Metuji", "value": "C22373"},
                          {"label": "Novy Bydzov", "value": "C22374"},
                          {"label": "Okres Hradec Kralove", "value": "C22375"},
                          {"label": "Okres Jicin", "value": "C22376"},
                          {"label": "Okres Nachod", "value": "C22377"},
                          {"label": "Okres Rychnov nad Kneznou",
                           "value": "C22378"},
                          {"label": "Okres Trutnov", "value": "C22379"},
                          {"label": "Opocno", "value": "C22380"},
                          {"label": "Ostromer", "value": "C22381"},
                          {"label": "Pecka", "value": "C22382"},
                          {"label": "Pilnikov", "value": "C22383"},
                          {"label": "Police nad Metuji", "value": "C22384"},
                          {"label": "Predmerice nad Labem", "value": "C22385"},
                          {"label": "Provodov-Sonov", "value": "C22386"},
                          {"label": "Radvanice", "value": "C22387"},
                          {"label": "Rokytnice v Orlickych Horach",
                           "value": "C22388"},
                          {"label": "Rtyne v Podkrkonosi", "value": "C22389"},
                          {"label": "Rudnik", "value": "C22390"},
                          {"label": "Rychnov nad Kneznou", "value": "C22391"},
                          {"label": "Skuhrov nad Belou", "value": "C22392"},
                          {"label": "Smidary", "value": "C22393"},
                          {"label": "Smirice", "value": "C22394"},
                          {"label": "Sobotka", "value": "C22395"},
                          {"label": "Solnice", "value": "C22396"},
                          {"label": "Spindleruv Mlyn", "value": "C22397"},
                          {"label": "Stara Paka", "value": "C22398"},
                          {"label": "Stare Nechanice", "value": "C22399"},
                          {"label": "Stezery", "value": "C22400"},
                          {"label": "Svoboda nad Upou", "value": "C22401"},
                          {"label": "Teplice nad Metuji", "value": "C22402"},
                          {"label": "Trebechovice pod Orebem",
                           "value": "C22403"},
                          {"label": "Trutnov", "value": "C22404"},
                          {"label": "Tyniste nad Orlici", "value": "C22405"},
                          {"label": "Upice", "value": "C22406"},
                          {"label": "Valdice", "value": "C22407"},
                          {"label": "Vamberk", "value": "C22408"},
                          {"label": "Velke Porici", "value": "C22409"},
                          {"label": "Velke Svatonovice", "value": "C22410"},
                          {"label": "Vrchlabi", "value": "C22411"},
                          {"label": "Vsestary", "value": "C22412"},
                          {"label": "Zacler", "value": "C22413"},
                          {"label": "Zadni Mostek", "value": "C22414"},
                          {"label": "Zeleznice", "value": "C22415"}],
 "Kranj City Municipality": [{"label": "Britof", "value": "C107675"},
                             {"label": "Golnik", "value": "C107676"},
                             {"label": "Kokrica", "value": "C107677"},
                             {"label": "Kranj", "value": "C107678"},
                             {"label": "Mlaka pri Kranju", "value": "C107679"},
                             {"label": "Zgornje Bitnje", "value": "C107680"}],
 "Kranjska Gora Municipality": [{"label": "Kranjska Gora", "value": "C107681"},
                                {"label": "Mojstrana", "value": "C107682"}],
 "Krapina-Zagorje": [{"label": "Bedekovcina", "value": "C21165"},
                     {"label": "Budinscina", "value": "C21166"},
                     {"label": "Durmanec", "value": "C21167"},
                     {"label": "Grad Donja Stubica", "value": "C21168"},
                     {"label": "Grad Klanjec", "value": "C21169"},
                     {"label": "Grad Krapina", "value": "C21170"},
                     {"label": "Grad Zabok", "value": "C21171"},
                     {"label": "Grad Zlatar", "value": "C21172"},
                     {"label": "Jesenje", "value": "C21173"},
                     {"label": "Klanjec", "value": "C21174"},
                     {"label": "Konjscina", "value": "C21175"},
                     {"label": "Krapina", "value": "C21176"},
                     {"label": "Kumrovec", "value": "C21177"},
                     {"label": "Mace", "value": "C21178"},
                     {"label": "Marija Bistrica", "value": "C21179"},
                     {"label": "Mihovljan", "value": "C21180"},
                     {"label": "Oroslavje", "value": "C21181"},
                     {"label": "Pregrada", "value": "C21182"},
                     {"label": "Radoboj", "value": "C21183"},
                     {"label": "Stubicke Toplice", "value": "C21184"},
                     {"label": "Sveti Kriz Zacretje", "value": "C21185"},
                     {"label": "Zabok", "value": "C21186"},
                     {"label": "Zlatar", "value": "C21187"},
                     {"label": "Zlatar Bistrica", "value": "C21188"}],
 "Kraslava Municipality": [{"label": "Kraslava", "value": "C64889"}],
 "Krasnodar Krai": [{"label": "Abinsk", "value": "C101539"},
                    {"label": "Abinskiy Rayon", "value": "C101540"},
                    {"label": "Abrau-Dyurso", "value": "C101541"},
                    {"label": "Adler", "value": "C101542"},
                    {"label": "Afipskiy", "value": "C101543"},
                    {"label": "Agoy", "value": "C101544"},
                    {"label": "Agronom", "value": "C101545"},
                    {"label": "Akhtanizovskaya", "value": "C101546"},
                    {"label": "Akhtyrskiy", "value": "C101547"},
                    {"label": "Alekseyevskaya", "value": "C101548"},
                    {"label": "Anapa", "value": "C101549"},
                    {"label": "Anapskaya", "value": "C101550"},
                    {"label": "Anastasiyevskaya", "value": "C101551"},
                    {"label": "Apsheronsk", "value": "C101552"},
                    {"label": "Arkhipo-Osipovka", "value": "C101553"},
                    {"label": "Armavir", "value": "C101554"},
                    {"label": "Atamanskaya", "value": "C101555"},
                    {"label": "Azovskaya", "value": "C101556"},
                    {"label": "Baturinskaya", "value": "C101557"},
                    {"label": "Belaya Glina", "value": "C101558"},
                    {"label": "Belorechensk", "value": "C101559"},
                    {"label": "Belorechenskiy Rayon", "value": "C101560"},
                    {"label": "Belozernyy", "value": "C101561"},
                    {"label": "Berezanskaya", "value": "C101562"},
                    {"label": "Besleneyevskaya", "value": "C101563"},
                    {"label": "Besskorbnaya", "value": "C101564"},
                    {"label": "Blagovetschenskaya", "value": "C101565"},
                    {"label": "Bryukhovetskaya", "value": "C101566"},
                    {"label": "Chamlykskaya", "value": "C101567"},
                    {"label": "Chelbasskaya", "value": "C101568"},
                    {"label": "Chernomorskiy", "value": "C101569"},
                    {"label": "Chernoyerkovskaya", "value": "C101570"},
                    {"label": "Dagomys", "value": "C101571"},
                    {"label": "Dinskaya", "value": "C101572"},
                    {"label": "Divnomorskoye", "value": "C101573"},
                    {"label": "Dmitriyevskaya", "value": "C101574"},
                    {"label": "Dneprovskaya", "value": "C101575"},
                    {"label": "Dolzhanskaya", "value": "C101576"},
                    {"label": "Dvubratskiy", "value": "C101577"},
                    {"label": "Dzhiginka", "value": "C101578"},
                    {"label": "Dzhubga", "value": "C101579"},
                    {"label": "Fastovetskaya", "value": "C101580"},
                    {"label": "Gayduk", "value": "C101581"},
                    {"label": "Gelendzhik", "value": "C101582"},
                    {"label": "Girey", "value": "C101583"},
                    {"label": "Glafirovka", "value": "C101584"},
                    {"label": "Glubokiy", "value": "C101585"},
                    {"label": "Golubitskaya", "value": "C101586"},
                    {"label": "Gornoye Loo", "value": "C101587"},
                    {"label": "Goryachiy Klyuch", "value": "C101588"},
                    {"label": "Gostagayevskaya", "value": "C101589"},
                    {"label": "Grivenskaya", "value": "C101590"},
                    {"label": "Gubskaya", "value": "C101591"},
                    {"label": "Gulkevichi", "value": "C101592"},
                    {"label": "Ilich", "value": "C101593"},
                    {"label": "Ilskiy", "value": "C101594"},
                    {"label": "Irkliyevskaya", "value": "C101595"},
                    {"label": "Kabardinka", "value": "C101596"},
                    {"label": "Kalininskaya", "value": "C101597"},
                    {"label": "Kalnibolotskaya", "value": "C101598"},
                    {"label": "Kamyshevatskaya", "value": "C101599"},
                    {"label": "Kanelovskaya", "value": "C101600"},
                    {"label": "Kanevskaya", "value": "C101601"},
                    {"label": "Kavkazskaya", "value": "C101602"},
                    {"label": "Kazanskaya", "value": "C101603"},
                    {"label": "Khadyzhensk", "value": "C101604"},
                    {"label": "Kholmskiy", "value": "C101605"},
                    {"label": "Khosta", "value": "C101606"},
                    {"label": "Kislyakovskaya", "value": "C101607"},
                    {"label": "Kiyevskoye", "value": "C101608"},
                    {"label": "Konokovo", "value": "C101609"},
                    {"label": "Konstantinovskaya", "value": "C101610"},
                    {"label": "Kontenko", "value": "C101611"},
                    {"label": "Kopanskaya", "value": "C101612"},
                    {"label": "Korenovsk", "value": "C101613"},
                    {"label": "Korzhevskiy", "value": "C101614"},
                    {"label": "Kovalevskoye", "value": "C101615"},
                    {"label": "Krasnaya Polyana", "value": "C101616"},
                    {"label": "Krasnoarmeyskaya", "value": "C101617"},
                    {"label": "Krasnodar", "value": "C101618"},
                    {"label": "Krasnoye", "value": "C101619"},
                    {"label": "Kropotkin", "value": "C101620"},
                    {"label": "Krylovskaya", "value": "C101621"},
                    {"label": "Krylovskiy Rayon", "value": "C101622"},
                    {"label": "Krymsk", "value": "C101623"},
                    {"label": "Krymskiy Rayon", "value": "C101624"},
                    {"label": "Kubanskiy", "value": "C101625"},
                    {"label": "Kuchugury", "value": "C101626"},
                    {"label": "Kudepsta", "value": "C101627"},
                    {"label": "Kurganinsk", "value": "C101628"},
                    {"label": "Kushchevskaya", "value": "C101629"},
                    {"label": "Labinsk", "value": "C101630"},
                    {"label": "Ladozhskaya", "value": "C101631"},
                    {"label": "Lazarevskoye", "value": "C101632"},
                    {"label": "Lenina", "value": "C101633"},
                    {"label": "Leningradskaya", "value": "C101634"},
                    {"label": "Lermontovo", "value": "C101635"},
                    {"label": "Lovlinskaya", "value": "C101636"},
                    {"label": "Maykopskoye", "value": "C101637"},
                    {"label": "Medvedovskaya", "value": "C101638"},
                    {"label": "Mikhaylovskaya", "value": "C101639"},
                    {"label": "Mirskoy", "value": "C101640"},
                    {"label": "Mostovskiy Rayon", "value": "C101641"},
                    {"label": "Mostovskoy", "value": "C101642"},
                    {"label": "Myskhako", "value": "C101643"},
                    {"label": "Nebug", "value": "C101644"},
                    {"label": "Neftegorsk", "value": "C101645"},
                    {"label": "Nekrasovskaya", "value": "C101646"},
                    {"label": "Novoalekseyevskaya", "value": "C101647"},
                    {"label": "Novobeysugskaya", "value": "C101648"},
                    {"label": "Novoderevyankovskaya", "value": "C101649"},
                    {"label": "Novodmitriyevskaya", "value": "C101650"},
                    {"label": "Novodzhereliyevskaya", "value": "C101651"},
                    {"label": "Novokorsunskaya", "value": "C101652"},
                    {"label": "Novokubansk", "value": "C101653"},
                    {"label": "Novokubanskiy Rayon", "value": "C101654"},
                    {"label": "Novolabinskaya", "value": "C101655"},
                    {"label": "Novoleushkovskaya", "value": "C101656"},
                    {"label": "Novomalorossiyskaya", "value": "C101657"},
                    {"label": "Novomikhaylovskiy", "value": "C101658"},
                    {"label": "Novominskaya", "value": "C101659"},
                    {"label": "Novomyshastovskaya", "value": "C101660"},
                    {"label": "Novoplatnirovskaya", "value": "C101661"},
                    {"label": "Novopokrovskaya", "value": "C101662"},
                    {"label": "Novorossiysk", "value": "C101663"},
                    {"label": "Novorozhdestvenskaya", "value": "C101664"},
                    {"label": "Novoshcherbinovskaya", "value": "C101665"},
                    {"label": "Novotitarovskaya", "value": "C101666"},
                    {"label": "Novoukrainskiy", "value": "C101667"},
                    {"label": "Novoukrainskoye", "value": "C101668"},
                    {"label": "Novovelichkovskaya", "value": "C101669"},
                    {"label": "Olginka", "value": "C101670"},
                    {"label": "Olginskaya", "value": "C101671"},
                    {"label": "Orel-Izumrud", "value": "C101672"},
                    {"label": "Otradnaya", "value": "C101673"},
                    {"label": "Otrado-Kubanskoye", "value": "C101674"},
                    {"label": "Parkovyy", "value": "C101675"},
                    {"label": "Pashkovskiy", "value": "C101676"},
                    {"label": "Pavlovskaya", "value": "C101677"},
                    {"label": "Pavlovskiy Rayon", "value": "C101678"},
                    {"label": "Peredovaya", "value": "C101679"},
                    {"label": "Perepravnaya", "value": "C101680"},
                    {"label": "Pereyaslovskaya", "value": "C101681"},
                    {"label": "Petropavlovskaya", "value": "C101682"},
                    {"label": "Petrovskaya", "value": "C101683"},
                    {"label": "Plastunovskaya", "value": "C101684"},
                    {"label": "Platnirovskaya", "value": "C101685"},
                    {"label": "Poputnaya", "value": "C101686"},
                    {"label": "Prikubanskiy", "value": "C101687"},
                    {"label": "Primorsko-Akhtarsk", "value": "C101688"},
                    {"label": "Prochnookopskaya", "value": "C101689"},
                    {"label": "Psebay", "value": "C101690"},
                    {"label": "Pshada", "value": "C101691"},
                    {"label": "Pshekhskaya", "value": "C101692"},
                    {"label": "Rayevskaya", "value": "C101693"},
                    {"label": "Rodnikovskaya", "value": "C101694"},
                    {"label": "Rogovskaya", "value": "C101695"},
                    {"label": "Ryazanskaya", "value": "C101696"},
                    {"label": "Saratovskaya", "value": "C101697"},
                    {"label": "Severskaya", "value": "C101698"},
                    {"label": "Shabelskoye", "value": "C101699"},
                    {"label": "Shedok", "value": "C101700"},
                    {"label": "Shepsi", "value": "C101701"},
                    {"label": "Shirochanka", "value": "C101702"},
                    {"label": "Shkurinskaya", "value": "C101703"},
                    {"label": "Slavyansk-na-Kubani", "value": "C101704"},
                    {"label": "Slavyansky rayon", "value": "C101705"},
                    {"label": "Smolenskaya", "value": "C101706"},
                    {"label": "Sochi", "value": "C101707"},
                    {"label": "Sokolovskoye", "value": "C101708"},
                    {"label": "Sovetskaya", "value": "C101709"},
                    {"label": "Sovkhoznyy", "value": "C101710"},
                    {"label": "Spokoynaya", "value": "C101711"},
                    {"label": "Staraya Stanitsa", "value": "C101712"},
                    {"label": "Staroderevyankovskaya", "value": "C101713"},
                    {"label": "Starodzhereliyevskaya", "value": "C101714"},
                    {"label": "Starokorsunskaya", "value": "C101715"},
                    {"label": "Staroleushkovskaya", "value": "C101716"},
                    {"label": "Starominskaya", "value": "C101717"},
                    {"label": "Staromyshastovskaya", "value": "C101718"},
                    {"label": "Staronizhestebliyevskaya", "value": "C101719"},
                    {"label": "Staroshcherbinovskaya", "value": "C101720"},
                    {"label": "Starotitarovskaya", "value": "C101721"},
                    {"label": "Starovelichkovskaya", "value": "C101722"},
                    {"label": "Strelka", "value": "C101723"},
                    {"label": "Sukko", "value": "C101724"},
                    {"label": "Taman", "value": "C101725"},
                    {"label": "Tbilisskaya", "value": "C101726"},
                    {"label": "Temirgoyevskaya", "value": "C101727"},
                    {"label": "Temizhbekskaya", "value": "C101728"},
                    {"label": "Temruksky rayon", "value": "C101729"},
                    {"label": "Temryuk", "value": "C101730"},
                    {"label": "Ternevskaya", "value": "C101731"},
                    {"label": "Tikhoretsk", "value": "C101732"},
                    {"label": "Tikhoretskiy Rayon", "value": "C101733"},
                    {"label": "Timashevsk", "value": "C101734"},
                    {"label": "Troitskaya", "value": "C101735"},
                    {"label": "Trudobelikovskiy", "value": "C101736"},
                    {"label": "Tsibanobalka", "value": "C101737"},
                    {"label": "Tuapse", "value": "C101738"},
                    {"label": "Tuapsinskiy Rayon", "value": "C101739"},
                    {"label": "Udobnaya", "value": "C101740"},
                    {"label": "Upornaya", "value": "C101741"},
                    {"label": "Uspenskaya", "value": "C101742"},
                    {"label": "Uspenskoye", "value": "C101743"},
                    {"label": "Ust-Labinsk", "value": "C101744"},
                    {"label": "Vardane", "value": "C101745"},
                    {"label": "Varenikovskaya", "value": "C101746"},
                    {"label": "Vasyurinskaya", "value": "C101747"},
                    {"label": "Velikovechnoye", "value": "C101748"},
                    {"label": "Ventsy", "value": "C101749"},
                    {"label": "Verkhnebakanskiy", "value": "C101750"},
                    {"label": "Vinogradnyy", "value": "C101751"},
                    {"label": "Vityazevo", "value": "C101752"},
                    {"label": "Vladimirskaya", "value": "C101753"},
                    {"label": "Vorontsovka", "value": "C101754"},
                    {"label": "Voznesenskaya", "value": "C101755"},
                    {"label": "Vyselki", "value": "C101756"},
                    {"label": "Vyselkovskiy Rayon", "value": "C101757"},
                    {"label": "Vyshestebliyevskaya", "value": "C101758"},
                    {"label": "Vysokoye", "value": "C101759"},
                    {"label": "Yaroslavskaya", "value": "C101760"},
                    {"label": "Yasenskaya", "value": "C101761"},
                    {"label": "Yelizavetinskaya", "value": "C101762"},
                    {"label": "Yeysk", "value": "C101763"},
                    {"label": "Yeyskoye Ukrepleniye", "value": "C101764"},
                    {"label": "Yurovka", "value": "C101765"},
                    {"label": "Yuzhnyy", "value": "C101766"},
                    {"label": "Zavetnyy", "value": "C101767"}],
 "Krasnoyarsk Krai": [{"label": "Aban", "value": "C101768"},
                      {"label": "Abanskiy Rayon", "value": "C101769"},
                      {"label": "Achinsk", "value": "C101770"},
                      {"label": "Achinskiy Rayon", "value": "C101771"},
                      {"label": "Aginskoye", "value": "C101772"},
                      {"label": "Balakhta", "value": "C101773"},
                      {"label": "Balakhtinskiy Rayon", "value": "C101774"},
                      {"label": "Baykit", "value": "C101775"},
                      {"label": "Berezovka", "value": "C101776"},
                      {"label": "Berezovskiy rayon", "value": "C101777"},
                      {"label": "Birilyusskiy Rayon", "value": "C101778"},
                      {"label": "Bogotol", "value": "C101779"},
                      {"label": "Bogotolskiy Rayon", "value": "C101780"},
                      {"label": "Boguchanskiy Rayon", "value": "C101781"},
                      {"label": "Boguchany", "value": "C101782"},
                      {"label": "Bolshemurtinskiy Rayon", "value": "C101783"},
                      {"label": "Bolsheuluyskiy Rayon", "value": "C101784"},
                      {"label": "Bor", "value": "C101785"},
                      {"label": "Borodino", "value": "C101786"},
                      {"label": "Chunoyar", "value": "C101787"},
                      {"label": "Dikson", "value": "C101788"},
                      {"label": "Divnogorsk", "value": "C101789"},
                      {"label": "Dubinino", "value": "C101790"},
                      {"label": "Dudinka", "value": "C101791"},
                      {"label": "Dzerzhinskoye", "value": "C101792"},
                      {"label": "Evenkiyskiy District", "value": "C101793"},
                      {"label": "Filimonovo", "value": "C101794"},
                      {"label": "Idrinskiy Rayon", "value": "C101795"},
                      {"label": "Idrinskoye", "value": "C101796"},
                      {"label": "Igarka", "value": "C101797"},
                      {"label": "Ilichevo", "value": "C101798"},
                      {"label": "Ilanskiy", "value": "C101799"},
                      {"label": "Ilanskiy Rayon", "value": "C101800"},
                      {"label": "Irbeyskiy Rayon", "value": "C101801"},
                      {"label": "Irbeyskoye", "value": "C101802"},
                      {"label": "Irsha", "value": "C101803"},
                      {"label": "Kansk", "value": "C101804"},
                      {"label": "Kanskiy Rayon", "value": "C101805"},
                      {"label": "Karatuzskoye", "value": "C101806"},
                      {"label": "Kayyerkan", "value": "C101807"},
                      {"label": "Kazachinskiy Rayon", "value": "C101808"},
                      {"label": "Kazachinskoye", "value": "C101809"},
                      {"label": "Kedrovyy", "value": "C101810"},
                      {"label": "Khatanga", "value": "C101811"},
                      {"label": "Kodinsk", "value": "C101812"},
                      {"label": "Koshurnikovo", "value": "C101813"},
                      {"label": "Krasnokamensk", "value": "C101814"},
                      {"label": "Krasnoturansk", "value": "C101815"},
                      {"label": "Krasnoyarsk", "value": "C101816"},
                      {"label": "Kuragino", "value": "C101817"},
                      {"label": "Lesosibirsk", "value": "C101818"},
                      {"label": "Manskiy Rayon", "value": "C101819"},
                      {"label": "Manzya", "value": "C101820"},
                      {"label": "Minusinsk", "value": "C101821"},
                      {"label": "Minusinskiy Rayon", "value": "C101822"},
                      {"label": "Motygino", "value": "C101823"},
                      {"label": "Nazarovo", "value": "C101824"},
                      {"label": "Nazarovskiy Rayon", "value": "C101825"},
                      {"label": "Nizhneingashskiy Rayon", "value": "C101826"},
                      {"label": "Nizhniy Ingash", "value": "C101827"},
                      {"label": "Nizhnyaya Poyma", "value": "C101828"},
                      {"label": "Norilsk", "value": "C101829"},
                      {"label": "Novobirilyussy", "value": "C101830"},
                      {"label": "Novochernorechenskiy", "value": "C101831"},
                      {"label": "Ovsyanka", "value": "C101832"},
                      {"label": "Partizanskiy Rayon", "value": "C101833"},
                      {"label": "Partizanskoye", "value": "C101834"},
                      {"label": "Pirovskoye", "value": "C101835"},
                      {"label": "Predivinsk", "value": "C101836"},
                      {"label": "Rassvet", "value": "C101837"},
                      {"label": "Razdolinsk", "value": "C101838"},
                      {"label": "Sayansk", "value": "C101839"},
                      {"label": "Sayanskiy Rayon", "value": "C101840"},
                      {"label": "Severo-Yeniseyskiy", "value": "C101841"},
                      {"label": "Severo-Yeniseyskiy Rayon", "value": "C101842"},
                      {"label": "Shalinskoye", "value": "C101843"},
                      {"label": "Sharypovo", "value": "C101844"},
                      {"label": "Sharypovskiy Rayon", "value": "C101845"},
                      {"label": "Shushenskiy Rayon", "value": "C101846"},
                      {"label": "Shushenskoye", "value": "C101847"},
                      {"label": "Snezhnogorsk", "value": "C101848"},
                      {"label": "Solnechnyy", "value": "C101849"},
                      {"label": "Sosnovoborsk", "value": "C101850"},
                      {"label": "Strelka", "value": "C101851"},
                      {"label": "Sukhobuzimskoye", "value": "C101852"},
                      {"label": "Svetlogorsk", "value": "C101853"},
                      {"label": "Talnakh", "value": "C101854"},
                      {"label": "Tanzybey", "value": "C101855"},
                      {"label": "Taseyevo", "value": "C101856"},
                      {"label": "Taymyrsky Dolgano-Nenetsky District",
                       "value": "C101857"},
                      {"label": "Teya", "value": "C101858"},
                      {"label": "Tinskoy", "value": "C101859"},
                      {"label": "Tura", "value": "C101860"},
                      {"label": "Turukhansk", "value": "C101861"},
                      {"label": "Tyukhtet", "value": "C101862"},
                      {"label": "Ural", "value": "C101863"},
                      {"label": "Uyar", "value": "C101864"},
                      {"label": "Uyarskiy Rayon", "value": "C101865"},
                      {"label": "Uzhur", "value": "C101866"},
                      {"label": "Vanavara", "value": "C101867"},
                      {"label": "Yemelyanovo", "value": "C101868"},
                      {"label": "Yemelyanovskiy Rayon", "value": "C101869"},
                      {"label": "Yeniseysk", "value": "C101870"},
                      {"label": "Yeniseyskiy Rayon", "value": "C101871"},
                      {"label": "Yermakovskoye", "value": "C101872"},
                      {"label": "Zelenogorsk", "value": "C101873"},
                      {"label": "Zelenyy Bor", "value": "C101874"},
                      {"label": "Zheleznogorsk", "value": "C101875"},
                      {"label": "Zykovo", "value": "C101876"}],
 "Kratie": [{"label": "Kracheh", "value": "C16501"},
            {"label": "Kratie", "value": "C16502"},
            {"label": "Snuol", "value": "C16503"}],
 "Kratovo Municipality": [{"label": "Kratovo", "value": "C65440"}],
 "Kriva Palanka Municipality": [{"label": "Kriva Palanka", "value": "C65441"}],
 "Krivogastani Municipality": [{"label": "Krivogashtani", "value": "C65442"},
                               {"label": "Obrsani", "value": "C65443"},
                               {"label": "Klechovtse", "value": "C65444"}],
 "Krizevci Municipality": [{"label": "Krizevci pri Ljutomeru",
                            "value": "C107683"}],
 "Kronoberg County": [{"label": "Almhult", "value": "C115697"},
                      {"label": "Alvesta", "value": "C115698"},
                      {"label": "Aseda", "value": "C115699"},
                      {"label": "Braas", "value": "C115700"},
                      {"label": "Gemla", "value": "C115701"},
                      {"label": "Hovmantorp", "value": "C115702"},
                      {"label": "Ingelstad", "value": "C115703"},
                      {"label": "Lagan", "value": "C115704"},
                      {"label": "Lammhult", "value": "C115705"},
                      {"label": "Lenhovda", "value": "C115706"},
                      {"label": "Lessebo", "value": "C115707"},
                      {"label": "Ljungby", "value": "C115708"},
                      {"label": "Markaryd", "value": "C115709"},
                      {"label": "Moheda", "value": "C115710"},
                      {"label": "Rottne", "value": "C115711"},
                      {"label": "Ryd", "value": "C115712"},
                      {"label": "Stromsnasbruk", "value": "C115713"},
                      {"label": "Tingsryd", "value": "C115714"},
                      {"label": "Vaxjo", "value": "C115715"},
                      {"label": "Vislanda", "value": "C115716"}],
 "Krusevo Municipality": [{"label": "Bucin", "value": "C65445"},
                          {"label": "Krusevo", "value": "C65446"}],
 "Kuala Lumpur": [{"label": "Kuala Lumpur", "value": "C65674"}],
 "Kufra District": [{"label": "Al Jawf", "value": "C65040"},
                    {"label": "At Taj", "value": "C65041"}],
 "Kukes District": [{"label": "Bajram Curri", "value": "C170"},
                    {"label": "Krume", "value": "C171"},
                    {"label": "Kukes", "value": "C172"},
                    {"label": "Rrethi i Hasit", "value": "C173"},
                    {"label": "Rrethi i Kukesit", "value": "C174"}],
 "Kuldiga Municipality": [{"label": "Kuldiga", "value": "C64890"}],
 "Kumamoto Prefecture": [{"label": "Amakusa Gun", "value": "C63395"},
                         {"label": "Amakusa Shi", "value": "C63396"},
                         {"label": "Arao Shi", "value": "C63397"},
                         {"label": "Aso-shi", "value": "C63398"},
                         {"label": "Hitoyoshi", "value": "C63399"},
                         {"label": "Hitoyoshi Shi", "value": "C63400"},
                         {"label": "Hondomachi-hondo", "value": "C63401"},
                         {"label": "Honmachi", "value": "C63402"},
                         {"label": "Kamiamakusa Shi", "value": "C63403"},
                         {"label": "Kikuchi", "value": "C63404"},
                         {"label": "Kikuchi Shi", "value": "C63405"},
                         {"label": "Koshi Shi", "value": "C63406"},
                         {"label": "Kumamoto", "value": "C63407"},
                         {"label": "Kumamoto Shi", "value": "C63408"},
                         {"label": "Matsubase", "value": "C63409"},
                         {"label": "Minamata", "value": "C63410"},
                         {"label": "Minamata Shi", "value": "C63411"},
                         {"label": "Ozu", "value": "C63412"},
                         {"label": "Tamana", "value": "C63413"},
                         {"label": "Tamana Shi", "value": "C63414"},
                         {"label": "Uekimachi-mono", "value": "C63415"},
                         {"label": "Uki Shi", "value": "C63416"},
                         {"label": "Ushibukamachi", "value": "C63417"},
                         {"label": "Uto", "value": "C63418"},
                         {"label": "Uto Shi", "value": "C63419"},
                         {"label": "Yamaga Shi", "value": "C63420"},
                         {"label": "Yatsushiro Shi", "value": "C63421"}],
 "Kumanovo Municipality": [{"label": "Bedinje", "value": "C65447"},
                           {"label": "Kumanovo", "value": "C65448"},
                           {"label": "Romanovci", "value": "C65449"}],
 "Kunar": [{"label": "Asadabad", "value": "C54"},
           {"label": "asmar", "value": "C55"}],
 "Kunduz Province": [{"label": "Dasht-e Archi", "value": "C56"},
                     {"label": "Imam Sahib", "value": "C57"},
                     {"label": "Khanabad", "value": "C58"},
                     {"label": "Kunduz", "value": "C59"},
                     {"label": "Qarawul", "value": "C60"}],
 "Kunene Region": [{"label": "Epupa Constituency", "value": "C75988"},
                   {"label": "Khorixas", "value": "C75989"},
                   {"label": "Khorixas Constituency", "value": "C75990"},
                   {"label": "Opuwo", "value": "C75991"},
                   {"label": "Opuwo Constituency", "value": "C75992"},
                   {"label": "Outjo", "value": "C75993"},
                   {"label": "Sesfontein Constituency", "value": "C75994"}],
 "Kungota": [{"label": "Zgornja Kungota", "value": "C107684"}],
 "Kurdamir District": [{"label": "Kyurdarmir", "value": "C8456"}],
 "Kurdistan": [{"label": "Baneh", "value": "C50863"},
               {"label": "Bijar", "value": "C50864"},
               {"label": "Kamyaran", "value": "C50865"},
               {"label": "Marivan", "value": "C50866"},
               {"label": "Qorveh", "value": "C50867"},
               {"label": "Sanandaj", "value": "C50868"},
               {"label": "Saqqez", "value": "C50869"},
               {"label": "Shahrestan-e Baneh", "value": "C50870"},
               {"label": "Shahrestan-e Bijar", "value": "C50871"},
               {"label": "Shahrestan-e Dehgolan", "value": "C50872"},
               {"label": "Shahrestan-e Divandarreh", "value": "C50873"},
               {"label": "Shahrestan-e Kamyaran", "value": "C50874"},
               {"label": "Shahrestan-e Marivan", "value": "C50875"},
               {"label": "Shahrestan-e Sarvabad", "value": "C50876"}],
 "Kurgan Oblast": [{"label": "Belozerskoye", "value": "C101877"},
                   {"label": "Dalmatovo", "value": "C101878"},
                   {"label": "Glyadyanskoye", "value": "C101879"},
                   {"label": "Ikovka", "value": "C101880"},
                   {"label": "Kataysk", "value": "C101881"},
                   {"label": "Ketovo", "value": "C101882"},
                   {"label": "Krasnyy Oktyabr", "value": "C101883"},
                   {"label": "Kurgan", "value": "C101884"},
                   {"label": "Kurtamysh", "value": "C101885"},
                   {"label": "Lebyazhye", "value": "C101886"},
                   {"label": "Lebyazhyevskiy Rayon", "value": "C101887"},
                   {"label": "Lesnikovo", "value": "C101888"},
                   {"label": "Makushino", "value": "C101889"},
                   {"label": "Mishkino", "value": "C101890"},
                   {"label": "Mokrousovo", "value": "C101891"},
                   {"label": "Mokrousovskiy Rayon", "value": "C101892"},
                   {"label": "Petukhovo", "value": "C101893"},
                   {"label": "Polovinnoye", "value": "C101894"},
                   {"label": "Prosvet", "value": "C101895"},
                   {"label": "Safakulevo", "value": "C101896"},
                   {"label": "Safakulevskiy Rayon", "value": "C101897"},
                   {"label": "Shadrinsk", "value": "C101898"},
                   {"label": "Shatrovo", "value": "C101899"},
                   {"label": "Shumikha", "value": "C101900"},
                   {"label": "Shumikhinskiy Rayon", "value": "C101901"},
                   {"label": "Tselinnoye", "value": "C101902"},
                   {"label": "Vargashi", "value": "C101903"},
                   {"label": "Vargashinskiy Rayon", "value": "C101904"},
                   {"label": "Vvedenskoye", "value": "C101905"},
                   {"label": "Yurgamysh", "value": "C101906"},
                   {"label": "Zverinogolovskoye", "value": "C101907"}],
 "Kursk Oblast": [{"label": "Belaya", "value": "C101908"},
                  {"label": "Cheremisinovo", "value": "C101909"},
                  {"label": "Cheremisinovskiy Rayon", "value": "C101910"},
                  {"label": "Chernitsyno", "value": "C101911"},
                  {"label": "Fatezh", "value": "C101912"},
                  {"label": "Fatezhskiy Rayon", "value": "C101913"},
                  {"label": "Glushkovo", "value": "C101914"},
                  {"label": "Gorshechenskiy Rayon", "value": "C101915"},
                  {"label": "Gorshechnoye", "value": "C101916"},
                  {"label": "Ivanino", "value": "C101917"},
                  {"label": "Kastorenskiy Rayon", "value": "C101918"},
                  {"label": "Kastornoye", "value": "C101919"},
                  {"label": "Khomutovka", "value": "C101920"},
                  {"label": "Kirovskiy", "value": "C101921"},
                  {"label": "Konyshevka", "value": "C101922"},
                  {"label": "Korenevo", "value": "C101923"},
                  {"label": "Kshenskiy", "value": "C101924"},
                  {"label": "Kurchatov", "value": "C101925"},
                  {"label": "Kursk", "value": "C101926"},
                  {"label": "Kurskiy Rayon", "value": "C101927"},
                  {"label": "Lgovskiy", "value": "C101928"},
                  {"label": "Lgovskiy Rayon", "value": "C101929"},
                  {"label": "Manturovo", "value": "C101930"},
                  {"label": "Medvenskiy Rayon", "value": "C101931"},
                  {"label": "Mikhaylovka", "value": "C101932"},
                  {"label": "Oboyan", "value": "C101933"},
                  {"label": "Oboyanskiy Rayon", "value": "C101934"},
                  {"label": "Olym", "value": "C101935"},
                  {"label": "Pervoavgustovskiy", "value": "C101936"},
                  {"label": "Ponyri Vtoryye", "value": "C101937"},
                  {"label": "Pristen", "value": "C101938"},
                  {"label": "Pristenskiy Rayon", "value": "C101939"},
                  {"label": "Pryamitsyno", "value": "C101940"},
                  {"label": "Rylsk", "value": "C101941"},
                  {"label": "Shchigry", "value": "C101942"},
                  {"label": "Solntsevo", "value": "C101943"},
                  {"label": "Solntsevskiy Rayon", "value": "C101944"},
                  {"label": "Sovetskiy Rayon", "value": "C101945"},
                  {"label": "Sudzha", "value": "C101946"},
                  {"label": "Tim", "value": "C101947"},
                  {"label": "Voroshnevo", "value": "C101948"},
                  {"label": "Zheleznogorsk", "value": "C101949"},
                  {"label": "Zolotukhino", "value": "C101950"},
                  {"label": "Zolotukhinskiy Rayon", "value": "C101951"}],
 "Kutahya": [{"label": "Altintas Ilcesi", "value": "C120671"},
             {"label": "Aslanapa", "value": "C120672"},
             {"label": "Cavdarhisar Ilcesi", "value": "C120673"},
             {"label": "Domanic", "value": "C120674"},
             {"label": "Dumlupinar", "value": "C120675"},
             {"label": "Emet", "value": "C120676"},
             {"label": "Gediz", "value": "C120677"},
             {"label": "Hisarcik Ilcesi", "value": "C120678"},
             {"label": "Kutahya", "value": "C120679"},
             {"label": "Pazarlar", "value": "C120680"},
             {"label": "Saphane", "value": "C120681"},
             {"label": "Simav", "value": "C120682"},
             {"label": "Tavsanli", "value": "C120683"}],
 "Kuyavian-Pomeranian Voivodeship": [{"label": "Aleksandrow Kujawski",
                                      "value": "C87898"},
                                     {"label": "Barcin", "value": "C87899"},
                                     {"label": "Bartniczka", "value": "C87900"},
                                     {"label": "Baruchowo", "value": "C87901"},
                                     {"label": "Biale Blota",
                                      "value": "C87902"},
                                     {"label": "Bobrowniki", "value": "C87903"},
                                     {"label": "Bobrowo", "value": "C87904"},
                                     {"label": "Boniewo", "value": "C87905"},
                                     {"label": "Brodnica", "value": "C87906"},
                                     {"label": "Brzesc Kujawski",
                                      "value": "C87907"},
                                     {"label": "Brzozie", "value": "C87908"},
                                     {"label": "Brzuze", "value": "C87909"},
                                     {"label": "Bukowiec", "value": "C87910"},
                                     {"label": "Bydgoszcz", "value": "C87911"},
                                     {"label": "Byton", "value": "C87912"},
                                     {"label": "Cekcyn", "value": "C87913"},
                                     {"label": "Chelmno", "value": "C87914"},
                                     {"label": "Chelmza", "value": "C87915"},
                                     {"label": "Chocen", "value": "C87916"},
                                     {"label": "Chodecz", "value": "C87917"},
                                     {"label": "Chrostkowo", "value": "C87918"},
                                     {"label": "Ciechocin", "value": "C87919"},
                                     {"label": "Ciechocinek",
                                      "value": "C87920"},
                                     {"label": "Czerniewice",
                                      "value": "C87921"},
                                     {"label": "Czernikowo", "value": "C87922"},
                                     {"label": "Dabrowa", "value": "C87923"},
                                     {"label": "Dabrowa Chelminska",
                                      "value": "C87924"},
                                     {"label": "Debowa Laka",
                                      "value": "C87925"},
                                     {"label": "Dobre", "value": "C87926"},
                                     {"label": "Dobrzyn nad Wisla",
                                      "value": "C87927"},
                                     {"label": "Drzycim", "value": "C87928"},
                                     {"label": "Fabianki", "value": "C87929"},
                                     {"label": "Fordon", "value": "C87930"},
                                     {"label": "Gasawa", "value": "C87931"},
                                     {"label": "Gniewkowo", "value": "C87932"},
                                     {"label": "Golub-Dobrzyn",
                                      "value": "C87933"},
                                     {"label": "Gorzno", "value": "C87934"},
                                     {"label": "Gostycyn", "value": "C87935"},
                                     {"label": "Grudziadz", "value": "C87936"},
                                     {"label": "Gruta", "value": "C87937"},
                                     {"label": "Inowroclaw", "value": "C87938"},
                                     {"label": "Izbica Kujawska",
                                      "value": "C87939"},
                                     {"label": "Jablonowo Pomorskie",
                                      "value": "C87940"},
                                     {"label": "Janikowo", "value": "C87941"},
                                     {"label": "Janowiec Wielkopolski",
                                      "value": "C87942"},
                                     {"label": "Jezewo", "value": "C87943"},
                                     {"label": "Jeziora Wielkie",
                                      "value": "C87944"},
                                     {"label": "Kamien Krajenski",
                                      "value": "C87945"},
                                     {"label": "Kcynia", "value": "C87946"},
                                     {"label": "Kesowo", "value": "C87947"},
                                     {"label": "Kikol", "value": "C87948"},
                                     {"label": "Koronowo", "value": "C87949"},
                                     {"label": "Kowal", "value": "C87950"},
                                     {"label": "Kowalewo Pomorskie",
                                      "value": "C87951"},
                                     {"label": "Kruszwica", "value": "C87952"},
                                     {"label": "Lipno", "value": "C87953"},
                                     {"label": "Lisewo", "value": "C87954"},
                                     {"label": "Lniano", "value": "C87955"},
                                     {"label": "Lubanie", "value": "C87956"},
                                     {"label": "Lubicz Dolny",
                                      "value": "C87957"},
                                     {"label": "Lubicz Gorny",
                                      "value": "C87958"},
                                     {"label": "Lubien Kujawski",
                                      "value": "C87959"},
                                     {"label": "Lubiewo", "value": "C87960"},
                                     {"label": "Lubraniec", "value": "C87961"},
                                     {"label": "Labiszyn", "value": "C87962"},
                                     {"label": "Lasin", "value": "C87963"},
                                     {"label": "Lubianka", "value": "C87964"},
                                     {"label": "Lysomice", "value": "C87965"},
                                     {"label": "Mogilno", "value": "C87966"},
                                     {"label": "Mrocza", "value": "C87967"},
                                     {"label": "Naklo nad Notecia",
                                      "value": "C87968"},
                                     {"label": "Nieszawa", "value": "C87969"},
                                     {"label": "Nowa Wies Wielka",
                                      "value": "C87970"},
                                     {"label": "Nowe", "value": "C87971"},
                                     {"label": "Obrowo", "value": "C87972"},
                                     {"label": "Osie", "value": "C87973"},
                                     {"label": "Osieciny", "value": "C87974"},
                                     {"label": "Osiek", "value": "C87975"},
                                     {"label": "Osielsko", "value": "C87976"},
                                     {"label": "Ostrowite", "value": "C87977"},
                                     {"label": "Pakosc", "value": "C87978"},
                                     {"label": "Piotrkow Kujawski",
                                      "value": "C87979"},
                                     {"label": "Pluznica", "value": "C87980"},
                                     {"label": "Powiat aleksandrowski",
                                      "value": "C87981"},
                                     {"label": "Powiat brodnicki",
                                      "value": "C87982"},
                                     {"label": "Powiat bydgoski",
                                      "value": "C87983"},
                                     {"label": "Powiat chelminski",
                                      "value": "C87984"},
                                     {"label": "Powiat golubsko-dobrzynski",
                                      "value": "C87985"},
                                     {"label": "Powiat grudziadzki",
                                      "value": "C87986"},
                                     {"label": "Powiat inowroclawski",
                                      "value": "C87987"},
                                     {"label": "Powiat lipnowski",
                                      "value": "C87988"},
                                     {"label": "Powiat mogilenski",
                                      "value": "C87989"},
                                     {"label": "Powiat nakielski",
                                      "value": "C87990"},
                                     {"label": "Powiat radziejowski",
                                      "value": "C87991"},
                                     {"label": "Powiat rypinski",
                                      "value": "C87992"},
                                     {"label": "Powiat sepolenski",
                                      "value": "C87993"},
                                     {"label": "Powiat swiecki",
                                      "value": "C87994"},
                                     {"label": "Powiat torunski",
                                      "value": "C87995"},
                                     {"label": "Powiat tucholski",
                                      "value": "C87996"},
                                     {"label": "Powiat wabrzeski",
                                      "value": "C87997"},
                                     {"label": "Powiat wloclawski",
                                      "value": "C87998"},
                                     {"label": "Powiat zninski",
                                      "value": "C87999"},
                                     {"label": "Pruszcz", "value": "C88000"},
                                     {"label": "Raciazek", "value": "C88001"},
                                     {"label": "Radomin", "value": "C88002"},
                                     {"label": "Radziejow", "value": "C88003"},
                                     {"label": "Radzyn Chelminski",
                                      "value": "C88004"},
                                     {"label": "Rogowo", "value": "C88005"},
                                     {"label": "Rogozno", "value": "C88006"},
                                     {"label": "Rypin", "value": "C88007"},
                                     {"label": "Sadki", "value": "C88008"},
                                     {"label": "Sepolno Krajenskie",
                                      "value": "C88009"},
                                     {"label": "Sicienko", "value": "C88010"},
                                     {"label": "Skepe", "value": "C88011"},
                                     {"label": "Skrwilno", "value": "C88012"},
                                     {"label": "Slesin", "value": "C88013"},
                                     {"label": "Sliwice", "value": "C88014"},
                                     {"label": "Solec Kujawski",
                                      "value": "C88015"},
                                     {"label": "Sosno", "value": "C88016"},
                                     {"label": "Strzelno", "value": "C88017"},
                                     {"label": "Swiecie", "value": "C88018"},
                                     {"label": "Swiecie nad Osa",
                                      "value": "C88019"},
                                     {"label": "Swiedziebnia",
                                      "value": "C88020"},
                                     {"label": "Swiekatowo", "value": "C88021"},
                                     {"label": "Sypniewo", "value": "C88022"},
                                     {"label": "Szubin", "value": "C88023"},
                                     {"label": "Tluchowo", "value": "C88024"},
                                     {"label": "Topolka", "value": "C88025"},
                                     {"label": "Torun", "value": "C88026"},
                                     {"label": "Tuchola", "value": "C88027"},
                                     {"label": "Unislaw", "value": "C88028"},
                                     {"label": "Wabrzezno", "value": "C88029"},
                                     {"label": "Waganiec", "value": "C88030"},
                                     {"label": "Wapielsk", "value": "C88031"},
                                     {"label": "Warlubie", "value": "C88032"},
                                     {"label": "Wiecbork", "value": "C88033"},
                                     {"label": "Wielgie", "value": "C88034"},
                                     {"label": "Wielka Nieszawka",
                                      "value": "C88035"},
                                     {"label": "Wierzchoslawice",
                                      "value": "C88036"},
                                     {"label": "Wloclawek", "value": "C88037"},
                                     {"label": "Zbojno", "value": "C88038"},
                                     {"label": "Zlawies Wielka",
                                      "value": "C88039"},
                                     {"label": "Zlotniki Kujawskie",
                                      "value": "C88040"},
                                     {"label": "Znin", "value": "C88041"}],
 "Kuzma Municipality": [{"label": "Kuzma", "value": "C107685"}],
 "Kvemo Kartli": [{"label": "Bolnisi", "value": "C34305"},
                  {"label": "Bolnisis Munitsipaliteti", "value": "C34306"},
                  {"label": "Didi Lilo", "value": "C34307"},
                  {"label": "Dmanisis Munitsipaliteti", "value": "C34308"},
                  {"label": "Gardabani", "value": "C34309"},
                  {"label": "Gardabnis Munitsipaliteti", "value": "C34310"},
                  {"label": "Manglisi", "value": "C34311"},
                  {"label": "Marneuli", "value": "C34312"},
                  {"label": "Marneulis Munitsipaliteti", "value": "C34313"},
                  {"label": "Naghvarevi", "value": "C34314"},
                  {"label": "Rustavi", "value": "C34315"},
                  {"label": "Tetri Tsqaro", "value": "C34316"},
                  {"label": "Tetritsqalos Munitsipaliteti", "value": "C34317"},
                  {"label": "Tsalkis Munitsipaliteti", "value": "C34318"},
                  {"label": "Tsalka", "value": "C34319"}],
 "KwaZulu-Natal": [{"label": "Amajuba District Municipality",
                    "value": "C108044"},
                   {"label": "Ballito", "value": "C108045"},
                   {"label": "Berea", "value": "C108046"},
                   {"label": "Dundee", "value": "C108047"},
                   {"label": "Durban", "value": "C108048"},
                   {"label": "Ekuvukeni", "value": "C108049"},
                   {"label": "eMkhomazi", "value": "C108050"},
                   {"label": "Empangeni", "value": "C108051"},
                   {"label": "Eshowe", "value": "C108052"},
                   {"label": "eSikhaleni", "value": "C108053"},
                   {"label": "eThekwini Metropolitan Municipality",
                    "value": "C108054"},
                   {"label": "Glencoe", "value": "C108055"},
                   {"label": "Greytown", "value": "C108056"},
                   {"label": "Hluhluwe", "value": "C108057"},
                   {"label": "Howick", "value": "C108058"},
                   {"label": "iLembe District Municipality",
                    "value": "C108059"},
                   {"label": "Kokstad", "value": "C108060"},
                   {"label": "KwaDukuza", "value": "C108061"},
                   {"label": "Margate", "value": "C108062"},
                   {"label": "Mondlo", "value": "C108063"},
                   {"label": "Mooirivier", "value": "C108064"},
                   {"label": "Mpophomeni", "value": "C108065"},
                   {"label": "Mpumalanga", "value": "C108066"},
                   {"label": "Mtubatuba", "value": "C108067"},
                   {"label": "Ndwedwe", "value": "C108068"},
                   {"label": "Newcastle", "value": "C108069"},
                   {"label": "Pietermaritzburg", "value": "C108070"},
                   {"label": "Port Shepstone", "value": "C108071"},
                   {"label": "Richards Bay", "value": "C108072"},
                   {"label": "Richmond", "value": "C108073"},
                   {"label": "Scottburgh", "value": "C108074"},
                   {"label": "Sisonke District Municipality",
                    "value": "C108075"},
                   {"label": "Sundumbili", "value": "C108076"},
                   {"label": "Ugu District Municipality", "value": "C108077"},
                   {"label": "Ulundi", "value": "C108078"},
                   {"label": "uMgungundlovu District Municipality",
                    "value": "C108079"},
                   {"label": "uMkhanyakude District Municipality",
                    "value": "C108080"},
                   {"label": "uMzinyathi District Municipality",
                    "value": "C108081"},
                   {"label": "uThukela District Municipality",
                    "value": "C108082"},
                   {"label": "uThungulu District Municipality",
                    "value": "C108083"},
                   {"label": "Utrecht", "value": "C108084"},
                   {"label": "Vryheid", "value": "C108085"},
                   {"label": "Zululand District Municipality",
                    "value": "C108086"}],
 "Kwale": [{"label": "Gazi", "value": "C64586"},
           {"label": "Kinango", "value": "C64587"},
           {"label": "Kwale", "value": "C64588"},
           {"label": "Sawa Sawa", "value": "C64589"},
           {"label": "Shimoni", "value": "C64590"}],
 "Kwango": [{"label": "Kasongo-Lunda", "value": "C23341"}],
 "Kwara": [{"label": "Ajasse Ipo", "value": "C78368"},
           {"label": "Bode Saadu", "value": "C78369"},
           {"label": "Gwasero", "value": "C78370"},
           {"label": "Ilorin", "value": "C78371"},
           {"label": "Jebba", "value": "C78372"},
           {"label": "Kaiama", "value": "C78373"},
           {"label": "Lafiagi", "value": "C78374"},
           {"label": "Offa", "value": "C78375"},
           {"label": "Okuta", "value": "C78376"},
           {"label": "Omu-Aran", "value": "C78377"},
           {"label": "Patigi", "value": "C78378"},
           {"label": "Suya", "value": "C78379"},
           {"label": "Yashikera", "value": "C78380"}],
 "Kweneng District": [{"label": "Botlhapatlou", "value": "C10166"},
                      {"label": "Dutlwe", "value": "C10167"},
                      {"label": "Gabane", "value": "C10168"},
                      {"label": "Gaphatshwe", "value": "C10169"},
                      {"label": "Khudumelapye", "value": "C10170"},
                      {"label": "Lenchwe Le Tau", "value": "C10171"},
                      {"label": "Letlhakeng", "value": "C10172"},
                      {"label": "Metsemotlhaba", "value": "C10173"},
                      {"label": "Mmopone", "value": "C10174"},
                      {"label": "Mogoditshane", "value": "C10175"},
                      {"label": "Molepolole", "value": "C10176"},
                      {"label": "Nkoyaphiri", "value": "C10177"},
                      {"label": "Thamaga", "value": "C10178"}],
 "Kwilu": [{"label": "Bandundu", "value": "C23342"},
           {"label": "Bulungu", "value": "C23343"},
           {"label": "Kikwit", "value": "C23344"},
           {"label": "Mangai", "value": "C23345"}],
 "Kyiv": [{"label": "Darnytsia Raion", "value": "C122147"},
          {"label": "Desnyanskyy Rayon", "value": "C122148"},
          {"label": "Dnipro Raion", "value": "C122149"},
          {"label": "Holosiiv Raion", "value": "C122150"},
          {"label": "Kotsyubynske", "value": "C122151"},
          {"label": "Kyiv", "value": "C122152"},
          {"label": "Pechersk Raion", "value": "C122153"},
          {"label": "Podilskyy Rayon", "value": "C122154"},
          {"label": "Prolisky", "value": "C122155"},
          {"label": "Shevchenkivskyy Rayon", "value": "C122156"},
          {"label": "Solomianka Rayon", "value": "C122157"},
          {"label": "Svyatoshynskyy Rayon", "value": "C122158"}],
 "Kyivska oblast": [{"label": "Baryshivka", "value": "C122159"},
                    {"label": "Baryshivskyy Rayon", "value": "C122160"},
                    {"label": "Bila Tserkva", "value": "C122161"},
                    {"label": "Bila Tserkva Raion", "value": "C122162"},
                    {"label": "Bohuslav", "value": "C122163"},
                    {"label": "Borispolskiy Rayon", "value": "C122164"},
                    {"label": "Borodianka", "value": "C122165"},
                    {"label": "Borodyanskyy Rayon", "value": "C122166"},
                    {"label": "Borova", "value": "C122167"},
                    {"label": "Boryspil", "value": "C122168"},
                    {"label": "Boyarka", "value": "C122169"},
                    {"label": "Brovary", "value": "C122170"},
                    {"label": "Brovary Raion", "value": "C122171"},
                    {"label": "Bucha", "value": "C122172"},
                    {"label": "Chabany", "value": "C122173"},
                    {"label": "Chernyakhivka", "value": "C122174"},
                    {"label": "Dymer", "value": "C122175"},
                    {"label": "Fastiv", "value": "C122176"},
                    {"label": "Fastiv Raion", "value": "C122177"},
                    {"label": "Hlevakha", "value": "C122178"},
                    {"label": "Hnidyn", "value": "C122179"},
                    {"label": "Horenka", "value": "C122180"},
                    {"label": "Hostomel", "value": "C122181"},
                    {"label": "Irpin", "value": "C122182"},
                    {"label": "Irpinska Miskrada", "value": "C122183"},
                    {"label": "Ivankiv", "value": "C122184"},
                    {"label": "Ivankiv Raion", "value": "C122185"},
                    {"label": "Kaharlyk", "value": "C122186"},
                    {"label": "Kaharlyk Raion", "value": "C122187"},
                    {"label": "Kalynove", "value": "C122188"},
                    {"label": "Karapyshi", "value": "C122189"},
                    {"label": "Katyuzhanka", "value": "C122190"},
                    {"label": "Khotiv", "value": "C122191"},
                    {"label": "Kirove", "value": "C122192"},
                    {"label": "Klavdiyevo-Tarasove", "value": "C122193"},
                    {"label": "Knyazhichi", "value": "C122194"},
                    {"label": "Kodra", "value": "C122195"},
                    {"label": "Korniyivka", "value": "C122196"},
                    {"label": "Kozhanka", "value": "C122197"},
                    {"label": "Krasyatychi", "value": "C122198"},
                    {"label": "Kriukivschina", "value": "C122199"},
                    {"label": "Kryva Hora", "value": "C122200"},
                    {"label": "Kyyevo-Svyatoshynskyy Rayon",
                     "value": "C122201"},
                    {"label": "Liutizh", "value": "C122202"},
                    {"label": "Makariv", "value": "C122203"},
                    {"label": "Makariv Raion", "value": "C122204"},
                    {"label": "Mirovka", "value": "C122205"},
                    {"label": "Motovylivka", "value": "C122206"},
                    {"label": "Myronivka", "value": "C122207"},
                    {"label": "Novi Petrivtsi", "value": "C122208"},
                    {"label": "Obukhiv", "value": "C122209"},
                    {"label": "Obukhiv Raion", "value": "C122210"},
                    {"label": "Obukhivska Miskrada", "value": "C122211"},
                    {"label": "Pereiaslav-Khmelnytskyi", "value": "C122212"},
                    {"label": "Pereiaslav-Khmelnytskyi Raion",
                     "value": "C122213"},
                    {"label": "Piskivka", "value": "C122214"},
                    {"label": "Pohreby", "value": "C122215"},
                    {"label": "Pukhivka", "value": "C122216"},
                    {"label": "Rokytne", "value": "C122217"},
                    {"label": "Rokytne Raion", "value": "C122218"},
                    {"label": "Roskoshnyy", "value": "C122219"},
                    {"label": "Rzhyshchiv", "value": "C122220"},
                    {"label": "Semypolky", "value": "C122221"},
                    {"label": "Skvyra", "value": "C122222"},
                    {"label": "Skvyrskyy Rayon", "value": "C122223"},
                    {"label": "Slavutych", "value": "C122224"},
                    {"label": "Sofiyivska Borschagivka", "value": "C122225"},
                    {"label": "Stavyshche", "value": "C122226"},
                    {"label": "Stayki", "value": "C122227"},
                    {"label": "Tarashcha", "value": "C122228"},
                    {"label": "Tetiiv", "value": "C122229"},
                    {"label": "Trebukhiv", "value": "C122230"},
                    {"label": "Trypillia", "value": "C122231"},
                    {"label": "Tsybli", "value": "C122232"},
                    {"label": "Ukrainka", "value": "C122233"},
                    {"label": "Uzyn", "value": "C122234"},
                    {"label": "Vasylkiv", "value": "C122235"},
                    {"label": "Vasylkiv Raion", "value": "C122236"},
                    {"label": "Velyka Dymerka", "value": "C122237"},
                    {"label": "Volodarka", "value": "C122238"},
                    {"label": "Voronkov", "value": "C122239"},
                    {"label": "Vorzel", "value": "C122240"},
                    {"label": "Vyshgorodskiy Rayon", "value": "C122241"},
                    {"label": "Vyshhorod", "value": "C122242"},
                    {"label": "Vyshneve", "value": "C122243"},
                    {"label": "Yahotyn", "value": "C122244"},
                    {"label": "Yahotynskyy Rayon", "value": "C122245"},
                    {"label": "Z*hurivskyy Rayon", "value": "C122246"},
                    {"label": "Zgurovka", "value": "C122247"}],
 "Kymenlaakso": [{"label": "Anjala", "value": "C24930"},
                 {"label": "Elimaki", "value": "C24931"},
                 {"label": "Hamina", "value": "C24932"},
                 {"label": "Iitti", "value": "C24933"},
                 {"label": "Jaala", "value": "C24934"},
                 {"label": "Karhula", "value": "C24935"},
                 {"label": "Kotka", "value": "C24936"},
                 {"label": "Kouvola", "value": "C24937"},
                 {"label": "Miehikkala", "value": "C24938"},
                 {"label": "Pyhtaa", "value": "C24939"},
                 {"label": "Virojoki", "value": "C24940"}],
 "Kyoto Prefecture": [{"label": "Arashiyama", "value": "C63422"},
                      {"label": "Ayabe", "value": "C63423"},
                      {"label": "Ayabe-shi", "value": "C63424"},
                      {"label": "Fukuchiyama-shi", "value": "C63425"},
                      {"label": "Joyo Shi", "value": "C63426"},
                      {"label": "Kameoka", "value": "C63427"},
                      {"label": "Kameoka-shi", "value": "C63428"},
                      {"label": "Kamigyo-ku", "value": "C63429"},
                      {"label": "Kizugawa-shi", "value": "C63430"},
                      {"label": "Kyotanabe Shi", "value": "C63431"},
                      {"label": "Kyotango-shi", "value": "C63432"},
                      {"label": "Kyoto", "value": "C63433"},
                      {"label": "Maizuru", "value": "C63434"},
                      {"label": "Maizuru-shi", "value": "C63435"},
                      {"label": "Miyazu", "value": "C63436"},
                      {"label": "Miyazu-shi", "value": "C63437"},
                      {"label": "Muko Shi", "value": "C63438"},
                      {"label": "Nagaokakyo Shi", "value": "C63439"},
                      {"label": "Nantan-shi", "value": "C63440"},
                      {"label": "Tanabe", "value": "C63441"},
                      {"label": "Uji", "value": "C63442"},
                      {"label": "Uji Shi", "value": "C63443"},
                      {"label": "Yawata", "value": "C63444"},
                      {"label": "Yawata Shi", "value": "C63445"}],
 "Kyrenia District (Keryneia)": [{"label": "Kyrenia", "value": "C21873"},
                                 {"label": "Kyrenia Municipality",
                                  "value": "C21874"},
                                 {"label": "Lapithos", "value": "C21875"}],
 "Kyustendil Province": [{"label": "Boboshevo", "value": "C15960"},
                         {"label": "Bobov Dol", "value": "C15961"},
                         {"label": "Dupnitsa", "value": "C15962"},
                         {"label": "Kocherinovo", "value": "C15963"},
                         {"label": "Kyustendil", "value": "C15964"},
                         {"label": "Nevestino", "value": "C15965"},
                         {"label": "Obshtina Boboshevo", "value": "C15966"},
                         {"label": "Obshtina Bobov Dol", "value": "C15967"},
                         {"label": "Obshtina Dupnitsa", "value": "C15968"},
                         {"label": "Obshtina Kocherinovo", "value": "C15969"},
                         {"label": "Obshtina Kyustendil", "value": "C15970"},
                         {"label": "Obshtina Nevestino", "value": "C15971"},
                         {"label": "Obshtina Rila", "value": "C15972"},
                         {"label": "Obshtina Sapareva Banya",
                          "value": "C15973"},
                         {"label": "Obshtina Treklyano", "value": "C15974"},
                         {"label": "Rila", "value": "C15975"},
                         {"label": "Sapareva Banya", "value": "C15976"}],
 "Kyzylorda Region": [{"label": "Aral", "value": "C64447"},
                      {"label": "Ayteke Bi", "value": "C64448"},
                      {"label": "Belkol", "value": "C64449"},
                      {"label": "Dzhalagash", "value": "C64450"},
                      {"label": "Kyzylorda", "value": "C64451"},
                      {"label": "Qazaly", "value": "C64452"},
                      {"label": "Sekseuil", "value": "C64453"},
                      {"label": "Shalqiya", "value": "C64454"},
                      {"label": "Shieli", "value": "C64455"},
                      {"label": "Tasboget", "value": "C64456"},
                      {"label": "Terenozek", "value": "C64457"},
                      {"label": "Yanykurgan", "value": "C64458"},
                      {"label": "Zhosaly", "value": "C64459"}],
 "LOriental": [{"label": "Ahfir", "value": "C75773"},
               {"label": "Ain Beni Mathar", "value": "C75774"},
               {"label": "Al Aaroui", "value": "C75775"},
               {"label": "Berkane", "value": "C75776"},
               {"label": "Bouarfa", "value": "C75777"},
               {"label": "Debdou", "value": "C75778"},
               {"label": "Driouch Province", "value": "C75779"},
               {"label": "El Aioun", "value": "C75780"},
               {"label": "Figuig", "value": "C75781"},
               {"label": "Guercif Province", "value": "C75782"},
               {"label": "Jerada", "value": "C75783"},
               {"label": "Madagh", "value": "C75784"},
               {"label": "Midar", "value": "C75785"},
               {"label": "Nador", "value": "C75786"},
               {"label": "Oujda-Angad", "value": "C75787"},
               {"label": "Saidia", "value": "C75788"},
               {"label": "Selouane", "value": "C75789"},
               {"label": "Taourirt", "value": "C75790"},
               {"label": "Tiztoutine", "value": "C75791"},
               {"label": "Zaio", "value": "C75792"}],
 "La Altagracia Province": [{"label": "Boca de Yuma", "value": "C23917"},
                            {"label": "Higuey", "value": "C23918"},
                            {"label": "Otra Banda", "value": "C23919"},
                            {"label": "Punta Cana", "value": "C23920"},
                            {"label": "Salvaleon de Higuey", "value": "C23921"},
                            {"label": "San Rafael del Yuma",
                             "value": "C23922"}],
 "La Araucania": [{"label": "Angol", "value": "C17972"},
                  {"label": "Carahue", "value": "C17973"},
                  {"label": "Cholchol", "value": "C17974"},
                  {"label": "Collipulli", "value": "C17975"},
                  {"label": "Cunco", "value": "C17976"},
                  {"label": "Curacautin", "value": "C17977"},
                  {"label": "Curarrehue", "value": "C17978"},
                  {"label": "Ercilla", "value": "C17979"},
                  {"label": "Freire", "value": "C17980"},
                  {"label": "Galvarino", "value": "C17981"},
                  {"label": "Gorbea", "value": "C17982"},
                  {"label": "Lautaro", "value": "C17983"},
                  {"label": "Loncoche", "value": "C17984"},
                  {"label": "Lonquimay", "value": "C17985"},
                  {"label": "Los Sauces", "value": "C17986"},
                  {"label": "Lumaco", "value": "C17987"},
                  {"label": "Melipeuco", "value": "C17988"},
                  {"label": "Nueva Imperial", "value": "C17989"},
                  {"label": "Padre Las Casas", "value": "C17990"},
                  {"label": "Perquenco", "value": "C17991"},
                  {"label": "Pitrufquen", "value": "C17992"},
                  {"label": "Pucon", "value": "C17993"},
                  {"label": "Puren", "value": "C17994"},
                  {"label": "Renaico", "value": "C17995"},
                  {"label": "Saavedra", "value": "C17996"},
                  {"label": "Temuco", "value": "C17997"},
                  {"label": "Teodoro Schmidt", "value": "C17998"},
                  {"label": "Tolten", "value": "C17999"},
                  {"label": "Traiguen", "value": "C18000"},
                  {"label": "Victoria", "value": "C18001"},
                  {"label": "Vilcun", "value": "C18002"},
                  {"label": "Villarrica", "value": "C18003"}],
 "La Guaira": [{"label": "Caraballeda", "value": "C147184"},
               {"label": "Catia La Mar", "value": "C147185"},
               {"label": "La Guaira", "value": "C147186"},
               {"label": "Maiquetia", "value": "C147187"}],
 "La Guajira": [{"label": "Albania", "value": "C20229"},
                {"label": "Barrancas", "value": "C20230"},
                {"label": "Dibulla", "value": "C20231"},
                {"label": "Distraccion", "value": "C20232"},
                {"label": "El Molino", "value": "C20233"},
                {"label": "Fonseca", "value": "C20234"},
                {"label": "Hatonuevo", "value": "C20235"},
                {"label": "La Jagua del Pilar", "value": "C20236"},
                {"label": "Maicao", "value": "C20237"},
                {"label": "Manaure", "value": "C20238"},
                {"label": "Riohacha", "value": "C20239"},
                {"label": "San Juan del Cesar", "value": "C20240"},
                {"label": "Uribia", "value": "C20241"},
                {"label": "Urumita", "value": "C20242"},
                {"label": "Villanueva", "value": "C20243"}],
 "La Libertad": [{"label": "Ascope", "value": "C80872"},
                 {"label": "Cascas", "value": "C80873"},
                 {"label": "Chepen", "value": "C80874"},
                 {"label": "Chicama", "value": "C80875"},
                 {"label": "Chocope", "value": "C80876"},
                 {"label": "Gran Chimu", "value": "C80877"},
                 {"label": "Guadalupe", "value": "C80878"},
                 {"label": "Huamachuco", "value": "C80879"},
                 {"label": "Julcan", "value": "C80880"},
                 {"label": "Laredo", "value": "C80881"},
                 {"label": "Moche", "value": "C80882"},
                 {"label": "Otuzco", "value": "C80883"},
                 {"label": "Pacanga", "value": "C80884"},
                 {"label": "Pacasmayo", "value": "C80885"},
                 {"label": "Paijan", "value": "C80886"},
                 {"label": "Provincia de Bolivar", "value": "C80887"},
                 {"label": "Provincia de Otuzco", "value": "C80888"},
                 {"label": "Provincia de Pacasmayo", "value": "C80889"},
                 {"label": "Provincia de Pataz", "value": "C80890"},
                 {"label": "Provincia de Santiago de Chuco", "value": "C80891"},
                 {"label": "Provincia de Trujillo", "value": "C80892"},
                 {"label": "Pueblo Nuevo", "value": "C80893"},
                 {"label": "Quiruvilca", "value": "C80894"},
                 {"label": "Salaverry", "value": "C80895"},
                 {"label": "San Pedro de Lloc", "value": "C80896"},
                 {"label": "Sanchez Carrion", "value": "C80897"},
                 {"label": "Santiago de Cao", "value": "C80898"},
                 {"label": "Santiago de Chuco", "value": "C80899"},
                 {"label": "Trujillo", "value": "C80900"},
                 {"label": "Viru", "value": "C80901"}],
 "La Libertad Department": [{"label": "Antiguo Cuscatlan", "value": "C24380"},
                            {"label": "Ciudad Arce", "value": "C24381"},
                            {"label": "La Libertad", "value": "C24382"},
                            {"label": "Nuevo Cuscatlan", "value": "C24383"},
                            {"label": "Quezaltepeque", "value": "C24384"},
                            {"label": "San Juan Opico", "value": "C24385"},
                            {"label": "San Pablo Tacachico", "value": "C24386"},
                            {"label": "Santa Tecla", "value": "C24387"},
                            {"label": "Zaragoza", "value": "C24388"}],
 "La Massana": [{"label": "Arinsal", "value": "C522"},
                {"label": "la Massana", "value": "C523"}],
 "La Pampa": [{"label": "Alpachiri", "value": "C1106"},
              {"label": "Alta Italia", "value": "C1107"},
              {"label": "Anguil", "value": "C1108"},
              {"label": "Arata", "value": "C1109"},
              {"label": "Bernardo Larroude", "value": "C1110"},
              {"label": "Bernasconi", "value": "C1111"},
              {"label": "Caleufu", "value": "C1112"},
              {"label": "Catrilo", "value": "C1113"},
              {"label": "Colonia Baron", "value": "C1114"},
              {"label": "Departamento de Caleu-Caleu", "value": "C1115"},
              {"label": "Departamento de Toay", "value": "C1116"},
              {"label": "Doblas", "value": "C1117"},
              {"label": "Eduardo Castex", "value": "C1118"},
              {"label": "Embajador Martini", "value": "C1119"},
              {"label": "General Acha", "value": "C1120"},
              {"label": "General Manuel J. Campos", "value": "C1121"},
              {"label": "General Pico", "value": "C1122"},
              {"label": "General San Martin", "value": "C1123"},
              {"label": "Guatrache", "value": "C1124"},
              {"label": "Ingeniero Luiggi", "value": "C1125"},
              {"label": "Intendente Alvear", "value": "C1126"},
              {"label": "Jacinto Arauz", "value": "C1127"},
              {"label": "La Adela", "value": "C1128"},
              {"label": "La Maruja", "value": "C1129"},
              {"label": "Lonquimay", "value": "C1130"},
              {"label": "Macachin", "value": "C1131"},
              {"label": "Miguel Riglos", "value": "C1132"},
              {"label": "Parera", "value": "C1133"},
              {"label": "Quemu Quemu", "value": "C1134"},
              {"label": "Rancul", "value": "C1135"},
              {"label": "Realico", "value": "C1136"},
              {"label": "Santa Isabel", "value": "C1137"},
              {"label": "Santa Rosa", "value": "C1138"},
              {"label": "Telen", "value": "C1139"},
              {"label": "Trenel", "value": "C1140"},
              {"label": "Uriburu", "value": "C1141"},
              {"label": "Veinticinco de Mayo", "value": "C1142"},
              {"label": "Victorica", "value": "C1143"},
              {"label": "Winifreda", "value": "C1144"}],
 "La Paz Department": [{"label": "Achacachi", "value": "C9720"},
                       {"label": "Amarete", "value": "C9721"},
                       {"label": "Batallas", "value": "C9722"},
                       {"label": "Caranavi", "value": "C9723"},
                       {"label": "Chulumani", "value": "C9724"},
                       {"label": "Colquiri", "value": "C9725"},
                       {"label": "Coripata", "value": "C9726"},
                       {"label": "Coroico", "value": "C9727"},
                       {"label": "Curahuara de Carangas", "value": "C9728"},
                       {"label": "Eucaliptus", "value": "C9729"},
                       {"label": "Guanay", "value": "C9730"},
                       {"label": "Huarina", "value": "C9731"},
                       {"label": "Huatajata", "value": "C9732"},
                       {"label": "Jose Manuel Pando", "value": "C9733"},
                       {"label": "La Paz", "value": "C9734"},
                       {"label": "Lahuachaca", "value": "C9735"},
                       {"label": "Mapiri", "value": "C9736"},
                       {"label": "Patacamaya", "value": "C9737"},
                       {"label": "Provincia Aroma", "value": "C9738"},
                       {"label": "Provincia Bautista Saavedra",
                        "value": "C9739"},
                       {"label": "Provincia Camacho", "value": "C9740"},
                       {"label": "Provincia Franz Tamayo", "value": "C9741"},
                       {"label": "Provincia Gualberto Villarroel",
                        "value": "C9742"},
                       {"label": "Provincia Ingavi", "value": "C9743"},
                       {"label": "Provincia Inquisivi", "value": "C9744"},
                       {"label": "Provincia Iturralde", "value": "C9745"},
                       {"label": "Provincia Larecaja", "value": "C9746"},
                       {"label": "Provincia Loayza", "value": "C9747"},
                       {"label": "Provincia Los Andes", "value": "C9748"},
                       {"label": "Provincia Manco Kapac", "value": "C9749"},
                       {"label": "Provincia Munecas", "value": "C9750"},
                       {"label": "Provincia Murillo", "value": "C9751"},
                       {"label": "Provincia Nor Yungas", "value": "C9752"},
                       {"label": "Provincia Omasuyos", "value": "C9753"},
                       {"label": "Provincia Pacajes", "value": "C9754"},
                       {"label": "Provincia Sud Yungas", "value": "C9755"},
                       {"label": "Quime", "value": "C9756"},
                       {"label": "San Pablo", "value": "C9757"},
                       {"label": "San Pedro", "value": "C9758"},
                       {"label": "Sorata", "value": "C9759"},
                       {"label": "Tiahuanaco", "value": "C9760"},
                       {"label": "Viloco", "value": "C9761"},
                       {"label": "Yumani", "value": "C9762"},
                       {"label": "El Rosario", "value": "C24389"},
                       {"label": "Olocuilta", "value": "C24390"},
                       {"label": "San Pedro Masahuat", "value": "C24391"},
                       {"label": "Santiago Nonualco", "value": "C24392"},
                       {"label": "Zacatecoluca", "value": "C24393"},
                       {"label": "Aguanqueterique", "value": "C43529"},
                       {"label": "Cabanas", "value": "C43530"},
                       {"label": "Cane", "value": "C43531"},
                       {"label": "Chinacla", "value": "C43532"},
                       {"label": "Guajiquiro", "value": "C43533"},
                       {"label": "La Paz", "value": "C43534"},
                       {"label": "Lauterique", "value": "C43535"},
                       {"label": "Los Planes", "value": "C43536"},
                       {"label": "Marcala", "value": "C43537"},
                       {"label": "Mercedes de Oriente", "value": "C43538"},
                       {"label": "Opatoro", "value": "C43539"},
                       {"label": "San Antonio del Norte", "value": "C43540"},
                       {"label": "San Jose", "value": "C43541"},
                       {"label": "San Juan", "value": "C43542"},
                       {"label": "San Pedro de Tutule", "value": "C43543"},
                       {"label": "Santa Ana", "value": "C43544"},
                       {"label": "Santa Elena", "value": "C43545"},
                       {"label": "Santa Maria", "value": "C43546"},
                       {"label": "Santiago Puringla", "value": "C43547"},
                       {"label": "Tepanguare", "value": "C43548"},
                       {"label": "Yarula", "value": "C43549"},
                       {"label": "Yarumela", "value": "C43550"}],
 "La Rioja": [{"label": "Arauco", "value": "C1145"},
              {"label": "Castro Barros", "value": "C1146"},
              {"label": "Chamical", "value": "C1147"},
              {"label": "Chilecito", "value": "C1148"},
              {"label": "Departamento de Arauco", "value": "C1149"},
              {"label": "Departamento de General Lamadrid", "value": "C1150"},
              {"label": "Departamento de Independencia", "value": "C1151"},
              {"label": "La Rioja", "value": "C1152"},
              {"label": "Villa Bustos", "value": "C1153"},
              {"label": "Vinchina", "value": "C1154"},
              {"label": "abalos", "value": "C112236"},
              {"label": "Agoncillo", "value": "C112237"},
              {"label": "Aguilar del Rio Alhama", "value": "C112238"},
              {"label": "Ajamil", "value": "C112239"},
              {"label": "Albelda de Iregua", "value": "C112240"},
              {"label": "Alberite", "value": "C112241"},
              {"label": "Alcanadre", "value": "C112242"},
              {"label": "Aldeanueva de Ebro", "value": "C112243"},
              {"label": "Alesanco", "value": "C112244"},
              {"label": "Aleson", "value": "C112245"},
              {"label": "Alfaro", "value": "C112246"},
              {"label": "Almarza de Cameros", "value": "C112247"},
              {"label": "Anguciana", "value": "C112248"},
              {"label": "Anguiano", "value": "C112249"},
              {"label": "Arenzana de Abajo", "value": "C112250"},
              {"label": "Arenzana de Arriba", "value": "C112251"},
              {"label": "Arnedillo", "value": "C112252"},
              {"label": "Arnedo", "value": "C112253"},
              {"label": "Arrubal", "value": "C112254"},
              {"label": "Ausejo", "value": "C112255"},
              {"label": "Autol", "value": "C112256"},
              {"label": "Azofra", "value": "C112257"},
              {"label": "Badaran", "value": "C112258"},
              {"label": "Banares", "value": "C112259"},
              {"label": "Banos de Rio Tobia", "value": "C112260"},
              {"label": "Banos de Rioja", "value": "C112261"},
              {"label": "Berceo", "value": "C112262"},
              {"label": "Bergasa", "value": "C112263"},
              {"label": "Bergasillas Bajera", "value": "C112264"},
              {"label": "Bobadilla", "value": "C112265"},
              {"label": "Brieva de Cameros", "value": "C112266"},
              {"label": "Brinas", "value": "C112267"},
              {"label": "Briones", "value": "C112268"},
              {"label": "Cabezon de Cameros", "value": "C112269"},
              {"label": "Calahorra", "value": "C112270"},
              {"label": "Canales de la Sierra", "value": "C112271"},
              {"label": "Canas", "value": "C112272"},
              {"label": "Canillas de Rio Tuerto", "value": "C112273"},
              {"label": "Cardenas", "value": "C112274"},
              {"label": "Casalarreina", "value": "C112275"},
              {"label": "Castanares de Rioja", "value": "C112276"},
              {"label": "Cellorigo", "value": "C112277"},
              {"label": "Cenicero", "value": "C112278"},
              {"label": "Cervera del Rio Alhama", "value": "C112279"},
              {"label": "Cidamon", "value": "C112280"},
              {"label": "Cihuri", "value": "C112281"},
              {"label": "Ciruena", "value": "C112282"},
              {"label": "Clavijo", "value": "C112283"},
              {"label": "Cordovin", "value": "C112284"},
              {"label": "Corera", "value": "C112285"},
              {"label": "Cornago", "value": "C112286"},
              {"label": "Corporales", "value": "C112287"},
              {"label": "Enciso", "value": "C112288"},
              {"label": "Entrena", "value": "C112289"},
              {"label": "Estollo", "value": "C112290"},
              {"label": "Ezcaray", "value": "C112291"},
              {"label": "Foncea", "value": "C112292"},
              {"label": "Fonzaleche", "value": "C112293"},
              {"label": "Fuenmayor", "value": "C112294"},
              {"label": "Galbarruli", "value": "C112295"},
              {"label": "Galilea", "value": "C112296"},
              {"label": "Gallinero de Cameros", "value": "C112297"},
              {"label": "Gimileo", "value": "C112298"},
              {"label": "Granon", "value": "C112299"},
              {"label": "Gravalos", "value": "C112300"},
              {"label": "Haro", "value": "C112301"},
              {"label": "Herce", "value": "C112302"},
              {"label": "Herramelluri", "value": "C112303"},
              {"label": "Hervias", "value": "C112304"},
              {"label": "Hormilla", "value": "C112305"},
              {"label": "Hormilleja", "value": "C112306"},
              {"label": "Hornillos de Cameros", "value": "C112307"},
              {"label": "Hornos de Moncalvillo", "value": "C112308"},
              {"label": "Huercanos", "value": "C112309"},
              {"label": "Igea", "value": "C112310"},
              {"label": "Jalon de Cameros", "value": "C112311"},
              {"label": "Laguna de Cameros", "value": "C112312"},
              {"label": "Lagunilla del Jubera", "value": "C112313"},
              {"label": "Lardero", "value": "C112314"},
              {"label": "Ledesma de la Cogolla", "value": "C112315"},
              {"label": "Leiva", "value": "C112316"},
              {"label": "Leza de Rio Leza", "value": "C112317"},
              {"label": "Logrono", "value": "C112318"},
              {"label": "Lumbreras", "value": "C112319"},
              {"label": "Manjarres", "value": "C112320"},
              {"label": "Manzanares de Rioja", "value": "C112321"},
              {"label": "Matute", "value": "C112322"},
              {"label": "Medrano", "value": "C112323"},
              {"label": "Munilla", "value": "C112324"},
              {"label": "Murillo de Rio Leza", "value": "C112325"},
              {"label": "Muro de Aguas", "value": "C112326"},
              {"label": "Muro en Cameros", "value": "C112327"},
              {"label": "Najera", "value": "C112328"},
              {"label": "Nalda", "value": "C112329"},
              {"label": "Navajun", "value": "C112330"},
              {"label": "Nieva de Cameros", "value": "C112331"},
              {"label": "Ochanduri", "value": "C112332"},
              {"label": "Ocon", "value": "C112333"},
              {"label": "Ojacastro", "value": "C112334"},
              {"label": "Ollauri", "value": "C112335"},
              {"label": "Pazuengos", "value": "C112336"},
              {"label": "Pedroso", "value": "C112337"},
              {"label": "Pinillos", "value": "C112338"},
              {"label": "Pradejon", "value": "C112339"},
              {"label": "Pradillo", "value": "C112340"},
              {"label": "Prejano", "value": "C112341"},
              {"label": "Provincia de La Rioja", "value": "C112342"},
              {"label": "Quel", "value": "C112343"},
              {"label": "Rabanera", "value": "C112344"},
              {"label": "Rincon de Soto", "value": "C112345"},
              {"label": "Robres del Castillo", "value": "C112346"},
              {"label": "Rodezno", "value": "C112347"},
              {"label": "Sajazarra", "value": "C112348"},
              {"label": "San Asensio", "value": "C112349"},
              {"label": "San Millan de la Cogolla", "value": "C112350"},
              {"label": "San Millan de Yecora", "value": "C112351"},
              {"label": "San Roman de Cameros", "value": "C112352"},
              {"label": "San Torcuato", "value": "C112353"},
              {"label": "San Vicente de la Sonsierra", "value": "C112354"},
              {"label": "Santa Coloma", "value": "C112355"},
              {"label": "Santa Eulalia Bajera", "value": "C112356"},
              {"label": "Santo Domingo de la Calzada", "value": "C112357"},
              {"label": "Santurdejo", "value": "C112358"},
              {"label": "Sojuela", "value": "C112359"},
              {"label": "Sorzano", "value": "C112360"},
              {"label": "Soto en Cameros", "value": "C112361"},
              {"label": "Terroba", "value": "C112362"},
              {"label": "Tirgo", "value": "C112363"},
              {"label": "Tobia", "value": "C112364"},
              {"label": "Tormantos", "value": "C112365"},
              {"label": "Torre en Cameros", "value": "C112366"},
              {"label": "Torrecilla en Cameros", "value": "C112367"},
              {"label": "Torrecilla sobre Alesanco", "value": "C112368"},
              {"label": "Torremontalbo", "value": "C112369"},
              {"label": "Treviana", "value": "C112370"},
              {"label": "Tricio", "value": "C112371"},
              {"label": "Tudelilla", "value": "C112372"},
              {"label": "Urunuela", "value": "C112373"},
              {"label": "Valdemadera", "value": "C112374"},
              {"label": "Valganon", "value": "C112375"},
              {"label": "Ventosa", "value": "C112376"},
              {"label": "Ventrosa", "value": "C112377"},
              {"label": "Viguera", "value": "C112378"},
              {"label": "Villalba de Rioja", "value": "C112379"},
              {"label": "Villalobar de Rioja", "value": "C112380"},
              {"label": "Villamediana de Iregua", "value": "C112381"},
              {"label": "Villanueva de Cameros", "value": "C112382"},
              {"label": "Villar de Torre", "value": "C112383"},
              {"label": "Villarejo", "value": "C112384"},
              {"label": "Villarroya", "value": "C112385"},
              {"label": "Villarta-Quintana", "value": "C112386"},
              {"label": "Villavelayo", "value": "C112387"},
              {"label": "Villaverde de Rioja", "value": "C112388"},
              {"label": "Villoslada de Cameros", "value": "C112389"},
              {"label": "Viniegra de Abajo", "value": "C112390"},
              {"label": "Viniegra de Arriba", "value": "C112391"},
              {"label": "Zarraton", "value": "C112392"},
              {"label": "Zarzosa", "value": "C112393"},
              {"label": "Zorraquin", "value": "C112394"}],
 "La Riviere Anglaise": [{"label": "Victoria", "value": "C107234"}],
 "La Romana Province": [{"label": "Guaymate", "value": "C23923"},
                        {"label": "La Romana", "value": "C23924"}],
 "La Union Department": [{"label": "Anamoros", "value": "C24394"},
                         {"label": "Conchagua", "value": "C24395"},
                         {"label": "Intipuca", "value": "C24396"},
                         {"label": "La Union", "value": "C24397"},
                         {"label": "Nueva Esparta", "value": "C24398"},
                         {"label": "Pasaquina", "value": "C24399"},
                         {"label": "San Alejo", "value": "C24400"},
                         {"label": "Santa Rosa de Lima", "value": "C24401"}],
 "La Vega Province": [{"label": "Concepcion de La Vega", "value": "C23925"},
                      {"label": "Constanza", "value": "C23926"},
                      {"label": "Jarabacoa", "value": "C23927"},
                      {"label": "Jima Abajo", "value": "C23928"},
                      {"label": "Rincon", "value": "C23929"},
                      {"label": "Rio Verde Arriba", "value": "C23930"},
                      {"label": "Tireo Arriba", "value": "C23931"}],
 "Laamu Atoll": [{"label": "Fonadhoo", "value": "C65813"}],
 "Laane County": [{"label": "Haapsalu", "value": "C24561"},
                  {"label": "Haapsalu linn", "value": "C24562"},
                  {"label": "Hullo", "value": "C24563"},
                  {"label": "Laane-Nigula vald", "value": "C24564"},
                  {"label": "Taebla", "value": "C24565"},
                  {"label": "Uuemoisa", "value": "C24566"},
                  {"label": "Vormsi vald", "value": "C24567"}],
 "Laane-Viru County": [{"label": "Aseri", "value": "C24568"},
                       {"label": "Haljala", "value": "C24569"},
                       {"label": "Haljala vald", "value": "C24570"},
                       {"label": "Kadrina", "value": "C24571"},
                       {"label": "Kadrina vald", "value": "C24572"},
                       {"label": "Kunda", "value": "C24573"},
                       {"label": "Pajusti", "value": "C24574"},
                       {"label": "Rakke", "value": "C24575"},
                       {"label": "Rakvere", "value": "C24576"},
                       {"label": "Rakvere vald", "value": "C24577"},
                       {"label": "Someru", "value": "C24578"},
                       {"label": "Tamsalu", "value": "C24579"},
                       {"label": "Tapa", "value": "C24580"},
                       {"label": "Tapa vald", "value": "C24581"},
                       {"label": "Vaiatu", "value": "C24582"},
                       {"label": "Vaike-Maarja", "value": "C24583"},
                       {"label": "Vaike-Maarja vald", "value": "C24584"},
                       {"label": "Vinni", "value": "C24585"},
                       {"label": "Vinni vald", "value": "C24586"},
                       {"label": "Viru-Nigula vald", "value": "C24587"}],
 "Laayoune-Sakia El Hamra (EH-partial)": [{"label": "Akhfennir",
                                           "value": "C75793"},
                                          {"label": "Boujdour",
                                           "value": "C75794"},
                                          {"label": "Es-Semara",
                                           "value": "C75795"},
                                          {"label": "Gueltat Zemmour",
                                           "value": "C75796"},
                                          {"label": "Laayoune",
                                           "value": "C75797"},
                                          {"label": "Smara", "value": "C75798"},
                                          {"label": "Tarfaya",
                                           "value": "C75799"}],
 "Labe Region": [{"label": "Koubia", "value": "C43029"},
                 {"label": "Labe", "value": "C43030"},
                 {"label": "Labe Prefecture", "value": "C43031"},
                 {"label": "Lelouma", "value": "C43032"},
                 {"label": "Lelouma Prefecture", "value": "C43033"},
                 {"label": "Mali", "value": "C43034"},
                 {"label": "Mali Prefecture", "value": "C43035"},
                 {"label": "Tougue", "value": "C43036"},
                 {"label": "Tougue Prefecture", "value": "C43037"}],
 "Laborie Quarter": [{"label": "Annus", "value": "C106045"},
                     {"label": "Balca", "value": "C106046"},
                     {"label": "BalcaEn Leur Ba", "value": "C106047"},
                     {"label": "Balembouche", "value": "C106048"},
                     {"label": "Banse", "value": "C106049"},
                     {"label": "Banse La Grace", "value": "C106050"},
                     {"label": "Bongalo", "value": "C106051"},
                     {"label": "Daban", "value": "C106052"},
                     {"label": "Fond Berange", "value": "C106053"},
                     {"label": "Gayabois", "value": "C106054"},
                     {"label": "Gentil", "value": "C106055"},
                     {"label": "Getrine", "value": "C106056"},
                     {"label": "Giraud", "value": "C106057"},
                     {"label": "HErelle", "value": "C106058"},
                     {"label": "Kennedy HighwayLaborie Bypass",
                      "value": "C106059"},
                     {"label": "La Haut", "value": "C106060"},
                     {"label": "La Perle", "value": "C106061"},
                     {"label": "Laborie", "value": "C106062"},
                     {"label": "Londonderry", "value": "C106063"},
                     {"label": "Macdomel", "value": "C106064"},
                     {"label": "Morne Gomier", "value": "C106065"},
                     {"label": "Morne Le Blanc", "value": "C106066"},
                     {"label": "Olibo", "value": "C106067"},
                     {"label": "Parc Estate", "value": "C106068"},
                     {"label": "Piaye", "value": "C106069"},
                     {"label": "Saltibus", "value": "C106070"},
                     {"label": "Saphire", "value": "C106071"},
                     {"label": "Tete Morne", "value": "C106072"},
                     {"label": "Village", "value": "C106073"},
                     {"label": "WarwickDaban", "value": "C106074"}],
 "Labuan": [{"label": "Labuan", "value": "C65675"}],
 "Lac Region": [{"label": "Bol", "value": "C17863"}],
 "Lachin District": [{"label": "Lacin", "value": "C8457"}],
 "Lacs Region": [{"label": "Arrah", "value": "C20960"},
                 {"label": "Belier", "value": "C20961"},
                 {"label": "Bocanda", "value": "C20962"},
                 {"label": "Bongouanou", "value": "C20963"},
                 {"label": "Daoukro", "value": "C20964"},
                 {"label": "Dimbokro", "value": "C20965"},
                 {"label": "Iffou", "value": "C20966"},
                 {"label": "Moronou", "value": "C20967"},
                 {"label": "NZi", "value": "C20968"},
                 {"label": "Toumodi", "value": "C20969"},
                 {"label": "Yamoussoukro", "value": "C20970"}],
 "Ladakh": [{"label": "Kargil", "value": "C46401"},
            {"label": "Leh", "value": "C46402"}],
 "Laghman": [{"label": "Mehtar Lam", "value": "C61"}],
 "Laghouat": [{"label": "Aflou", "value": "C351"},
              {"label": "Laghouat", "value": "C352"}],
 "Lagos": [{"label": "Apapa", "value": "C78381"},
           {"label": "Badagry", "value": "C78382"},
           {"label": "Ebute Ikorodu", "value": "C78383"},
           {"label": "Ejirin", "value": "C78384"},
           {"label": "Epe", "value": "C78385"},
           {"label": "Ikeja", "value": "C78386"},
           {"label": "Lagos", "value": "C78387"},
           {"label": "Makoko", "value": "C78388"}],
 "Lagunes region": [{"label": "Adzope", "value": "C20971"},
                    {"label": "Affery", "value": "C20972"},
                    {"label": "Agboville", "value": "C20973"},
                    {"label": "Agneby-Tiassa", "value": "C20974"},
                    {"label": "Akoupe", "value": "C20975"},
                    {"label": "Dabou", "value": "C20976"},
                    {"label": "Grand-Lahou", "value": "C20977"},
                    {"label": "Grands-Ponts", "value": "C20978"},
                    {"label": "Tiassale", "value": "C20979"}],
 "Lahij Governorate": [{"label": "Al  Hawtah", "value": "C147963"},
                       {"label": "Al Habilayn", "value": "C147964"},
                       {"label": "Al Had", "value": "C147965"},
                       {"label": "Al Madaribah Wa Al Arah", "value": "C147966"},
                       {"label": "Al Maflahy", "value": "C147967"},
                       {"label": "Al Maqatirah", "value": "C147968"},
                       {"label": "Al Milah", "value": "C147969"},
                       {"label": "Al Musaymir", "value": "C147970"},
                       {"label": "Al Qabbaytah", "value": "C147971"},
                       {"label": "Habil Jabr", "value": "C147972"},
                       {"label": "Halimayn", "value": "C147973"},
                       {"label": "Lahij", "value": "C147974"},
                       {"label": "Radfan", "value": "C147975"},
                       {"label": "Tuban", "value": "C147976"},
                       {"label": "Tur Al Bahah", "value": "C147977"},
                       {"label": "Yafaa", "value": "C147978"},
                       {"label": "Yahr", "value": "C147979"}],
 "Lai Chau": [{"label": "Huyen Muong Te", "value": "C147475"},
              {"label": "Huyen Tam Duong", "value": "C147476"},
              {"label": "Huyen Than Uyen", "value": "C147477"}],
 "Laikipia": [{"label": "Nanyuki", "value": "C64591"},
              {"label": "Nyahururu", "value": "C64592"},
              {"label": "Rumuruti", "value": "C64593"}],
 "Lakes": [{"label": "Yirol", "value": "C108549"}],
 "Lakshadweep": [{"label": "Kavaratti", "value": "C46403"},
                 {"label": "Lakshadweep", "value": "C46404"}],
 "Lam Dong": [{"label": "Bao Loc", "value": "C147478"},
              {"label": "Dam Rong", "value": "C147479"},
              {"label": "Dinh Van", "value": "C147480"},
              {"label": "Duc Trong", "value": "C147481"},
              {"label": "Da Lat", "value": "C147482"},
              {"label": "Huyen Bao Lam", "value": "C147483"},
              {"label": "Huyen Da Huoai", "value": "C147484"},
              {"label": "Huyen Da Teh", "value": "C147485"},
              {"label": "Huyen Don Duong", "value": "C147486"},
              {"label": "Huyen Duc Trong", "value": "C147487"},
              {"label": "Huyen Lam Ha", "value": "C147488"}],
 "Lambayeque": [{"label": "Chiclayo", "value": "C80902"},
                {"label": "Chongoyape", "value": "C80903"},
                {"label": "Eten", "value": "C80904"},
                {"label": "Ferrenafe", "value": "C80905"},
                {"label": "Jayanca", "value": "C80906"},
                {"label": "Lambayeque", "value": "C80907"},
                {"label": "Mochumi", "value": "C80908"},
                {"label": "Monsefu", "value": "C80909"},
                {"label": "Morrope", "value": "C80910"},
                {"label": "Motupe", "value": "C80911"},
                {"label": "Olmos", "value": "C80912"},
                {"label": "Oyotun", "value": "C80913"},
                {"label": "Picsi", "value": "C80914"},
                {"label": "Pimentel", "value": "C80915"},
                {"label": "Provincia de Chiclayo", "value": "C80916"},
                {"label": "Provincia de Ferrenafe", "value": "C80917"},
                {"label": "Provincia de Lambayeque", "value": "C80918"},
                {"label": "Pueblo Nuevo", "value": "C80919"},
                {"label": "Reque", "value": "C80920"},
                {"label": "San Jose", "value": "C80921"},
                {"label": "Sana", "value": "C80922"},
                {"label": "Santa Rosa", "value": "C80923"},
                {"label": "Tucume", "value": "C80924"}],
 "Lampang": [{"label": "Amphoe Chae Hom", "value": "C118712"},
             {"label": "Amphoe Hang Chat", "value": "C118713"},
             {"label": "Amphoe Ko Kha", "value": "C118714"},
             {"label": "Amphoe Mae Mo", "value": "C118715"},
             {"label": "Amphoe Mae Phrik", "value": "C118716"},
             {"label": "Amphoe Mae Tha", "value": "C118717"},
             {"label": "Amphoe Mueang Lampang", "value": "C118718"},
             {"label": "Amphoe Mueang Pan", "value": "C118719"},
             {"label": "Amphoe Ngao", "value": "C118720"},
             {"label": "Amphoe Soem Ngam", "value": "C118721"},
             {"label": "Amphoe Sop Prap", "value": "C118722"},
             {"label": "Amphoe Thoen", "value": "C118723"},
             {"label": "Amphoe Wang Nuea", "value": "C118724"},
             {"label": "Lampang", "value": "C118725"},
             {"label": "Thoen", "value": "C118726"},
             {"label": "Wang Nuea", "value": "C118727"}],
 "Lamphun": [{"label": "Amphoe Ban Hong", "value": "C118728"},
             {"label": "Amphoe Ban Thi", "value": "C118729"},
             {"label": "Amphoe Li", "value": "C118730"},
             {"label": "Amphoe Mae Tha", "value": "C118731"},
             {"label": "Amphoe Mueang Lamphun", "value": "C118732"},
             {"label": "Amphoe Pa Sang", "value": "C118733"},
             {"label": "Amphoe Thung Hua Chang", "value": "C118734"},
             {"label": "Amphoe Wiang Nong Long", "value": "C118735"},
             {"label": "Lamphun", "value": "C118736"},
             {"label": "Mae Tha", "value": "C118737"},
             {"label": "Pa Sang", "value": "C118738"}],
 "Lampung": [{"label": "Bandar Lampung", "value": "C49677"},
             {"label": "Kabupaten Lampung Barat", "value": "C49678"},
             {"label": "Kabupaten Lampung Selatan", "value": "C49679"},
             {"label": "Kabupaten Lampung Tengah", "value": "C49680"},
             {"label": "Kabupaten Lampung Timur", "value": "C49681"},
             {"label": "Kabupaten Lampung Utara", "value": "C49682"},
             {"label": "Kabupaten Mesuji", "value": "C49683"},
             {"label": "Kabupaten Pesawaran", "value": "C49684"},
             {"label": "Kabupaten Pesisir Barat", "value": "C49685"},
             {"label": "Kabupaten Pringsewu", "value": "C49686"},
             {"label": "Kabupaten Tanggamus", "value": "C49687"},
             {"label": "Kabupaten Tulangbawang", "value": "C49688"},
             {"label": "Kabupaten Way Kanan", "value": "C49689"},
             {"label": "Kota Bandar Lampung", "value": "C49690"},
             {"label": "Kota Metro", "value": "C49691"},
             {"label": "Kotabumi", "value": "C49692"},
             {"label": "Metro", "value": "C49693"},
             {"label": "Terbanggi Besar", "value": "C49694"}],
 "Lamu": [{"label": "Lamu", "value": "C64594"},
          {"label": "Witu", "value": "C64595"}],
 "Lang Son": [{"label": "Huyen Bac Son", "value": "C147489"},
              {"label": "Huyen Binh Gia", "value": "C147490"},
              {"label": "Huyen Cao Loc", "value": "C147491"},
              {"label": "Huyen Chi Lang", "value": "C147492"},
              {"label": "Huyen Dinh Lap", "value": "C147493"},
              {"label": "Huyen Huu Lung", "value": "C147494"},
              {"label": "Huyen Van Quan", "value": "C147495"},
              {"label": "Lang Son", "value": "C147496"}],
 "Lankaran District": [{"label": "Haftoni", "value": "C8458"},
                       {"label": "Lankaran", "value": "C8459"}],
 "Lao Cai": [{"label": "Huyen Bac Ha", "value": "C147497"},
             {"label": "Huyen Bao Yen", "value": "C147498"},
             {"label": "Huyen Bat Xat", "value": "C147499"},
             {"label": "Huyen Muong Khuong", "value": "C147500"},
             {"label": "Huyen Sa Pa", "value": "C147501"},
             {"label": "Huyen Si Ma Cai", "value": "C147502"},
             {"label": "Huyen Van Ban", "value": "C147503"},
             {"label": "Lao Cai", "value": "C147504"},
             {"label": "Lao Chai", "value": "C147505"},
             {"label": "Sa Pa", "value": "C147506"}],
 "Lapland": [{"label": "Enontekio", "value": "C24941"},
             {"label": "Inari", "value": "C24942"},
             {"label": "Ivalo", "value": "C24943"},
             {"label": "Kemi", "value": "C24944"},
             {"label": "Kemijarvi", "value": "C24945"},
             {"label": "Keminmaa", "value": "C24946"},
             {"label": "Kittila", "value": "C24947"},
             {"label": "Kolari", "value": "C24948"},
             {"label": "Muonio", "value": "C24949"},
             {"label": "Pelkosenniemi", "value": "C24950"},
             {"label": "Pello", "value": "C24951"},
             {"label": "Posio", "value": "C24952"},
             {"label": "Pyhajarvi", "value": "C24953"},
             {"label": "Ranua", "value": "C24954"},
             {"label": "Rovaniemi", "value": "C24955"},
             {"label": "Salla", "value": "C24956"},
             {"label": "Savukoski", "value": "C24957"},
             {"label": "Simo", "value": "C24958"},
             {"label": "Sodankyla", "value": "C24959"},
             {"label": "Tervola", "value": "C24960"},
             {"label": "Tornio", "value": "C24961"},
             {"label": "Utsjoki", "value": "C24962"},
             {"label": "Ylitornio", "value": "C24963"}],
 "Lara": [{"label": "Barquisimeto", "value": "C147188"},
          {"label": "Cabudare", "value": "C147189"},
          {"label": "Carora", "value": "C147190"},
          {"label": "El Tocuyo", "value": "C147191"},
          {"label": "Los Rastrojos", "value": "C147192"},
          {"label": "Quibor", "value": "C147193"}],
 "Larnaca District (Larnaka)": [{"label": "Aradippou", "value": "C21876"},
                                {"label": "Athienou", "value": "C21877"},
                                {"label": "Dhromolaxia", "value": "C21878"},
                                {"label": "Kiti", "value": "C21879"},
                                {"label": "Kofinou", "value": "C21880"},
                                {"label": "Kolossi", "value": "C21881"},
                                {"label": "Kornos", "value": "C21882"},
                                {"label": "Larnaca", "value": "C21883"},
                                {"label": "Livadia", "value": "C21884"},
                                {"label": "Meneou", "value": "C21885"},
                                {"label": "Mosfiloti", "value": "C21886"},
                                {"label": "Pergamos", "value": "C21887"},
                                {"label": "Perivolia", "value": "C21888"},
                                {"label": "Psevdas", "value": "C21889"},
                                {"label": "Pyla", "value": "C21890"},
                                {"label": "Tersefanou", "value": "C21891"},
                                {"label": "Troulloi", "value": "C21892"},
                                {"label": "Voroklini", "value": "C21893"},
                                {"label": "Xylofagou", "value": "C21894"},
                                {"label": "Xylotymbou", "value": "C21895"}],
 "Las Tunas Province": [{"label": "Amancio", "value": "C21766"},
                        {"label": "Colombia", "value": "C21767"},
                        {"label": "Jesus Menendez", "value": "C21768"},
                        {"label": "Jobabo", "value": "C21769"},
                        {"label": "Las Tunas", "value": "C21770"},
                        {"label": "Manati", "value": "C21771"},
                        {"label": "Puerto Padre", "value": "C21772"}],
 "Lasko Municipality": [{"label": "Lasko", "value": "C107686"}],
 "Latakia Governorate": [{"label": "Al-Haffah District", "value": "C117921"},
                         {"label": "Jablah", "value": "C117922"},
                         {"label": "Jableh District", "value": "C117923"},
                         {"label": "Kassab", "value": "C117924"},
                         {"label": "Latakia", "value": "C117925"},
                         {"label": "Latakia District", "value": "C117926"},
                         {"label": "Qardaha District", "value": "C117927"},
                         {"label": "Slinfah", "value": "C117928"}],
 "Lautem Municipality": [{"label": "Iliomar", "value": "C24069"},
                         {"label": "Lautem", "value": "C24070"},
                         {"label": "Lospalos", "value": "C24071"},
                         {"label": "Luro", "value": "C24072"},
                         {"label": "Tutuala", "value": "C24073"}],
 "Lavalleja Department": [{"label": "Jose Batlle y Ordonez",
                           "value": "C146889"},
                          {"label": "Jose Pedro Varela", "value": "C146890"},
                          {"label": "Mariscala", "value": "C146891"},
                          {"label": "Minas", "value": "C146892"},
                          {"label": "Solis de Mataojo", "value": "C146893"}],
 "Lazio": [{"label": "Accumoli", "value": "C54635"},
           {"label": "Acilia-Castel Fusano-Ostia Antica", "value": "C54636"},
           {"label": "Acquafondata", "value": "C54637"},
           {"label": "Acquapendente", "value": "C54638"},
           {"label": "Acuto", "value": "C54639"},
           {"label": "Affile", "value": "C54640"},
           {"label": "Agosta", "value": "C54641"},
           {"label": "Alatri", "value": "C54642"},
           {"label": "Albano Laziale", "value": "C54643"},
           {"label": "Albuccione", "value": "C54644"},
           {"label": "Allumiere", "value": "C54645"},
           {"label": "Alvito", "value": "C54646"},
           {"label": "Amaseno", "value": "C54647"},
           {"label": "Amatrice", "value": "C54648"},
           {"label": "Anagni", "value": "C54649"},
           {"label": "Anguillara Sabazia", "value": "C54650"},
           {"label": "Anitrella-Chiaiamari", "value": "C54651"},
           {"label": "Anticoli Corrado", "value": "C54652"},
           {"label": "Antrodoco", "value": "C54653"},
           {"label": "Anzio", "value": "C54654"},
           {"label": "Aprilia", "value": "C54655"},
           {"label": "Aquino", "value": "C54656"},
           {"label": "Ara Nova", "value": "C54657"},
           {"label": "Arce", "value": "C54658"},
           {"label": "Arci-Empolitana", "value": "C54659"},
           {"label": "Arcinazzo Romano", "value": "C54660"},
           {"label": "Ardea", "value": "C54661"},
           {"label": "Area Produttiva", "value": "C54662"},
           {"label": "Ariccia", "value": "C54663"},
           {"label": "Arlena di Castro", "value": "C54664"},
           {"label": "Armetta", "value": "C54665"},
           {"label": "Arnara", "value": "C54666"},
           {"label": "Arpino", "value": "C54667"},
           {"label": "Arsoli", "value": "C54668"},
           {"label": "Artena", "value": "C54669"},
           {"label": "Ascrea", "value": "C54670"},
           {"label": "Atina", "value": "C54671"},
           {"label": "Atina Inferiore", "value": "C54672"},
           {"label": "Aurelia", "value": "C54673"},
           {"label": "Ausonia", "value": "C54674"},
           {"label": "Bagni di Tivoli", "value": "C54675"},
           {"label": "Bagnoregio", "value": "C54676"},
           {"label": "Barbarano Romano", "value": "C54677"},
           {"label": "Bassano in Teverina", "value": "C54678"},
           {"label": "Bassano Romano", "value": "C54679"},
           {"label": "Bassiano", "value": "C54680"},
           {"label": "Bella Farnia", "value": "C54681"},
           {"label": "Bellegra", "value": "C54682"},
           {"label": "Belmonte Castello", "value": "C54683"},
           {"label": "Belmonte in Sabina", "value": "C54684"},
           {"label": "Bivio di Capanelle", "value": "C54685"},
           {"label": "Bivio San Polo", "value": "C54686"},
           {"label": "Blera", "value": "C54687"},
           {"label": "Bolsena", "value": "C54688"},
           {"label": "Bomarzo", "value": "C54689"},
           {"label": "Borbona", "value": "C54690"},
           {"label": "Borgo Grappa", "value": "C54691"},
           {"label": "Borgo Hermada", "value": "C54692"},
           {"label": "Borgo Lotti", "value": "C54693"},
           {"label": "Borgo Podgora", "value": "C54694"},
           {"label": "Borgo Sabotino-Foce Verde", "value": "C54695"},
           {"label": "Borgo San Michele", "value": "C54696"},
           {"label": "Borgo Velino", "value": "C54697"},
           {"label": "Borgorose", "value": "C54698"},
           {"label": "Boville Ernica", "value": "C54699"},
           {"label": "Bracciano", "value": "C54700"},
           {"label": "Broccostella", "value": "C54701"},
           {"label": "Caira", "value": "C54702"},
           {"label": "Calcata Nuova", "value": "C54703"},
           {"label": "Camerata Nuova", "value": "C54704"},
           {"label": "Camilleri-Vallelata", "value": "C54705"},
           {"label": "Campagnano di Roma", "value": "C54706"},
           {"label": "Campo di Carne", "value": "C54707"},
           {"label": "Campo Jemini", "value": "C54708"},
           {"label": "Campo Limpido-Favale", "value": "C54709"},
           {"label": "Campodimele", "value": "C54710"},
           {"label": "Campoleone", "value": "C54711"},
           {"label": "Campoli Appennino", "value": "C54712"},
           {"label": "Canale Monterano", "value": "C54713"},
           {"label": "Canepina", "value": "C54714"},
           {"label": "Canino", "value": "C54715"},
           {"label": "Cantalice", "value": "C54716"},
           {"label": "Cantalupo in Sabina", "value": "C54717"},
           {"label": "Canterano", "value": "C54718"},
           {"label": "Capena", "value": "C54719"},
           {"label": "Capodimonte", "value": "C54720"},
           {"label": "Capranica", "value": "C54721"},
           {"label": "Capranica Prenestina", "value": "C54722"},
           {"label": "Caprarola", "value": "C54723"},
           {"label": "Carbognano", "value": "C54724"},
           {"label": "Carchitti", "value": "C54725"},
           {"label": "Carpineto Romano", "value": "C54726"},
           {"label": "Carrara-Pontenuovo", "value": "C54727"},
           {"label": "Cartiera-Stazione", "value": "C54728"},
           {"label": "Casal Palocco", "value": "C54729"},
           {"label": "Casalattico", "value": "C54730"},
           {"label": "Casalazzara", "value": "C54731"},
           {"label": "Casali", "value": "C54732"},
           {"label": "Casalvieri", "value": "C54733"},
           {"label": "Casape", "value": "C54734"},
           {"label": "Casaprota", "value": "C54735"},
           {"label": "Case Campoli-Panetta", "value": "C54736"},
           {"label": "Casperia", "value": "C54737"},
           {"label": "Cassino", "value": "C54738"},
           {"label": "Castel Chiodato", "value": "C54739"},
           {"label": "Castel di Tora", "value": "C54740"},
           {"label": "Castel Fusano", "value": "C54741"},
           {"label": "Castel Gandolfo", "value": "C54742"},
           {"label": "Castel Madama", "value": "C54743"},
           {"label": "Castel San Pietro Romano", "value": "C54744"},
           {"label": "Castel SantAngelo", "value": "C54745"},
           {"label": "Castel SantElia", "value": "C54746"},
           {"label": "Castelforte", "value": "C54747"},
           {"label": "Castelliri", "value": "C54748"},
           {"label": "Castelmassimo", "value": "C54749"},
           {"label": "Castelnuovo di Farfa", "value": "C54750"},
           {"label": "Castelnuovo di Porto", "value": "C54751"},
           {"label": "Castelnuovo Parano", "value": "C54752"},
           {"label": "Castiglione in Teverina", "value": "C54753"},
           {"label": "Castro dei Volsci", "value": "C54754"},
           {"label": "Castrocielo", "value": "C54755"},
           {"label": "Cave", "value": "C54756"},
           {"label": "Ceccano", "value": "C54757"},
           {"label": "Cecchina", "value": "C54758"},
           {"label": "Celleno", "value": "C54759"},
           {"label": "Cellere", "value": "C54760"},
           {"label": "Ceprano", "value": "C54761"},
           {"label": "Cerquotti-Madonna del Piano", "value": "C54762"},
           {"label": "Cerreto Laziale", "value": "C54763"},
           {"label": "Cervara di Roma", "value": "C54764"},
           {"label": "Cervaro", "value": "C54765"},
           {"label": "Cerveteri", "value": "C54766"},
           {"label": "Cesano", "value": "C54767"},
           {"label": "Ciampino", "value": "C54768"},
           {"label": "Ciciliano", "value": "C54769"},
           {"label": "Cineto Romano", "value": "C54770"},
           {"label": "Cisterna di Latina", "value": "C54771"},
           {"label": "Citta metropolitana di Roma Capitale", "value": "C54772"},
           {"label": "Cittaducale", "value": "C54773"},
           {"label": "Cittareale", "value": "C54774"},
           {"label": "Civita Castellana", "value": "C54775"},
           {"label": "Civitavecchia", "value": "C54776"},
           {"label": "Civitella dAgliano", "value": "C54777"},
           {"label": "Civitella San Paolo", "value": "C54778"},
           {"label": "Coldragone", "value": "C54779"},
           {"label": "Collalto Sabino", "value": "C54780"},
           {"label": "Colle Campano-Scrima", "value": "C54781"},
           {"label": "Colle del Pino", "value": "C54782"},
           {"label": "Colle di Fuori", "value": "C54783"},
           {"label": "Colle di Tora", "value": "C54784"},
           {"label": "Colle Mainello", "value": "C54785"},
           {"label": "Colle San Magno", "value": "C54786"},
           {"label": "Colle Spina", "value": "C54787"},
           {"label": "Colle Verde", "value": "C54788"},
           {"label": "Colleferro", "value": "C54789"},
           {"label": "Collefontana-Fontana Liri Inferiore", "value": "C54790"},
           {"label": "Collegiove", "value": "C54791"},
           {"label": "Collepardo", "value": "C54792"},
           {"label": "Collevecchio", "value": "C54793"},
           {"label": "Colleverde II", "value": "C54794"},
           {"label": "Colli di Enea", "value": "C54795"},
           {"label": "Colli sul Velino", "value": "C54796"},
           {"label": "Colonna", "value": "C54797"},
           {"label": "Colubro", "value": "C54798"},
           {"label": "Concerviano", "value": "C54799"},
           {"label": "Configni", "value": "C54800"},
           {"label": "Contigliano", "value": "C54801"},
           {"label": "Corchiano", "value": "C54802"},
           {"label": "Corcolle", "value": "C54803"},
           {"label": "Coreno Ausonio", "value": "C54804"},
           {"label": "Cori", "value": "C54805"},
           {"label": "Corvaro", "value": "C54806"},
           {"label": "Costaroni", "value": "C54807"},
           {"label": "Cottanello", "value": "C54808"},
           {"label": "Cretone", "value": "C54809"},
           {"label": "Esperia", "value": "C54810"},
           {"label": "Fabrica di Roma", "value": "C54811"},
           {"label": "Faleria", "value": "C54812"},
           {"label": "Falvaterra", "value": "C54813"},
           {"label": "Fara in Sabina", "value": "C54814"},
           {"label": "Farnese", "value": "C54815"},
           {"label": "Ferentino", "value": "C54816"},
           {"label": "Fiamignano", "value": "C54817"},
           {"label": "Fiano Romano", "value": "C54818"},
           {"label": "Filacciano", "value": "C54819"},
           {"label": "Filettino", "value": "C54820"},
           {"label": "Fiuggi", "value": "C54821"},
           {"label": "Fiumicino-Isola Sacra", "value": "C54822"},
           {"label": "Focene", "value": "C54823"},
           {"label": "Fondi", "value": "C54824"},
           {"label": "Fontechiari", "value": "C54825"},
           {"label": "Forano", "value": "C54826"},
           {"label": "Formello", "value": "C54827"},
           {"label": "Formia", "value": "C54828"},
           {"label": "Fossignano", "value": "C54829"},
           {"label": "Frascati", "value": "C54830"},
           {"label": "Frasso Sabino", "value": "C54831"},
           {"label": "Fregene", "value": "C54832"},
           {"label": "Frosinone", "value": "C54833"},
           {"label": "Fumone", "value": "C54834"},
           {"label": "Gaeta", "value": "C54835"},
           {"label": "Gallese", "value": "C54836"},
           {"label": "Gallicano nel Lazio", "value": "C54837"},
           {"label": "Gallinaro", "value": "C54838"},
           {"label": "Gavignano", "value": "C54839"},
           {"label": "Genazzano", "value": "C54840"},
           {"label": "Genio Civile", "value": "C54841"},
           {"label": "Genzano di Roma", "value": "C54842"},
           {"label": "Gerano", "value": "C54843"},
           {"label": "Girardi-Bellavista-Terrazze", "value": "C54844"},
           {"label": "Giulianello", "value": "C54845"},
           {"label": "Giuliano di Roma", "value": "C54846"},
           {"label": "Gorga", "value": "C54847"},
           {"label": "Gradoli", "value": "C54848"},
           {"label": "Graffignano", "value": "C54849"},
           {"label": "Grottaferrata", "value": "C54850"},
           {"label": "Grotte di Castro", "value": "C54851"},
           {"label": "Grotte Santo Stefano", "value": "C54852"},
           {"label": "Grunuovo-Campomaggiore San Luca", "value": "C54853"},
           {"label": "Guarcino", "value": "C54854"},
           {"label": "Guidonia", "value": "C54855"},
           {"label": "Guidonia Montecelio", "value": "C54856"},
           {"label": "Ischia di Castro", "value": "C54857"},
           {"label": "Isola del Liri", "value": "C54858"},
           {"label": "Itri", "value": "C54859"},
           {"label": "Jenne", "value": "C54860"},
           {"label": "La Botte", "value": "C54861"},
           {"label": "La Forma", "value": "C54862"},
           {"label": "La Massimina-Casal Lumbroso", "value": "C54863"},
           {"label": "Labico", "value": "C54864"},
           {"label": "Labro", "value": "C54865"},
           {"label": "Ladispoli", "value": "C54866"},
           {"label": "Laghetto", "value": "C54867"},
           {"label": "Lanuvio", "value": "C54868"},
           {"label": "Lariano", "value": "C54869"},
           {"label": "Latera", "value": "C54870"},
           {"label": "Latina", "value": "C54871"},
           {"label": "Latina Scalo", "value": "C54872"},
           {"label": "Lavinio", "value": "C54873"},
           {"label": "Le Forna", "value": "C54874"},
           {"label": "Le Rughe", "value": "C54875"},
           {"label": "Lenola", "value": "C54876"},
           {"label": "Leonessa", "value": "C54877"},
           {"label": "Licenza", "value": "C54878"},
           {"label": "Lido dei Pini", "value": "C54879"},
           {"label": "Lido di Ostia", "value": "C54880"},
           {"label": "Limiti di Greccio", "value": "C54881"},
           {"label": "Longone Sabino", "value": "C54882"},
           {"label": "Lubriano", "value": "C54883"},
           {"label": "Maccarese", "value": "C54884"},
           {"label": "Macere", "value": "C54885"},
           {"label": "Maenza", "value": "C54886"},
           {"label": "Magliano Romano", "value": "C54887"},
           {"label": "Magliano Sabina", "value": "C54888"},
           {"label": "Mandela", "value": "C54889"},
           {"label": "Mantiglia di Ardea", "value": "C54890"},
           {"label": "Manziana", "value": "C54891"},
           {"label": "Marano Equo", "value": "C54892"},
           {"label": "Maranola-Trivio", "value": "C54893"},
           {"label": "Marcellina", "value": "C54894"},
           {"label": "Marcetelli", "value": "C54895"},
           {"label": "Marco Simone", "value": "C54896"},
           {"label": "Marina di Ardea-Tor San Lorenzo", "value": "C54897"},
           {"label": "Marina di Cerveteri", "value": "C54898"},
           {"label": "Marina San Nicola", "value": "C54899"},
           {"label": "Marino", "value": "C54900"},
           {"label": "Marta", "value": "C54901"},
           {"label": "Mazzano Romano", "value": "C54902"},
           {"label": "Mentana", "value": "C54903"},
           {"label": "Micigliano", "value": "C54904"},
           {"label": "Minturno", "value": "C54905"},
           {"label": "Molella", "value": "C54906"},
           {"label": "Mompeo", "value": "C54907"},
           {"label": "Montalto di Castro", "value": "C54908"},
           {"label": "Montasola", "value": "C54909"},
           {"label": "Monte Caminetto", "value": "C54910"},
           {"label": "Monte Migliore La Selvotta", "value": "C54911"},
           {"label": "Monte Porzio Catone", "value": "C54912"},
           {"label": "Monte Romano", "value": "C54913"},
           {"label": "Monte San Biagio", "value": "C54914"},
           {"label": "Monte San Giovanni Campano", "value": "C54915"},
           {"label": "Monte San Giovanni in Sabina", "value": "C54916"},
           {"label": "Monte San Marino", "value": "C54917"},
           {"label": "Montebuono", "value": "C54918"},
           {"label": "Montecelio", "value": "C54919"},
           {"label": "Montecompatri", "value": "C54920"},
           {"label": "Montefiascone", "value": "C54921"},
           {"label": "Monteflavio", "value": "C54922"},
           {"label": "Montelanico", "value": "C54923"},
           {"label": "Montelarco", "value": "C54924"},
           {"label": "Monteleone Sabino", "value": "C54925"},
           {"label": "Montelibretti", "value": "C54926"},
           {"label": "Montenero Sabino", "value": "C54927"},
           {"label": "Monterosi", "value": "C54928"},
           {"label": "Monterotondo", "value": "C54929"},
           {"label": "Montopoli in Sabina", "value": "C54930"},
           {"label": "Montorio Romano", "value": "C54931"},
           {"label": "Moricone", "value": "C54932"},
           {"label": "Morlupo", "value": "C54933"},
           {"label": "Morolo", "value": "C54934"},
           {"label": "Morro Reatino", "value": "C54935"},
           {"label": "Nazzano", "value": "C54936"},
           {"label": "Nemi", "value": "C54937"},
           {"label": "Nepi", "value": "C54938"},
           {"label": "Nerola", "value": "C54939"},
           {"label": "Nespolo", "value": "C54940"},
           {"label": "Nettuno", "value": "C54941"},
           {"label": "Norma", "value": "C54942"},
           {"label": "Olevano Romano", "value": "C54943"},
           {"label": "Onano", "value": "C54944"},
           {"label": "Oriolo Romano", "value": "C54945"},
           {"label": "Orte", "value": "C54946"},
           {"label": "Orte Scalo", "value": "C54947"},
           {"label": "Orvinio", "value": "C54948"},
           {"label": "Osteria Nuova", "value": "C54949"},
           {"label": "Paganico Sabino", "value": "C54950"},
           {"label": "Palestrina", "value": "C54951"},
           {"label": "Paliano", "value": "C54952"},
           {"label": "Palombara Sabina", "value": "C54953"},
           {"label": "Parco Leonardo", "value": "C54954"},
           {"label": "Passoscuro", "value": "C54955"},
           {"label": "Pastena", "value": "C54956"},
           {"label": "Patrica", "value": "C54957"},
           {"label": "Pavona", "value": "C54958"},
           {"label": "Penitro", "value": "C54959"},
           {"label": "Percile", "value": "C54960"},
           {"label": "Pescia Romana", "value": "C54961"},
           {"label": "Pescorocchiano", "value": "C54962"},
           {"label": "Pescosolido", "value": "C54963"},
           {"label": "Petrella Salto", "value": "C54964"},
           {"label": "Piansano", "value": "C54965"},
           {"label": "Picinisco", "value": "C54966"},
           {"label": "Pico", "value": "C54967"},
           {"label": "Piedimonte San Germano", "value": "C54968"},
           {"label": "Piedimonte San Germano Alta", "value": "C54969"},
           {"label": "Piglio", "value": "C54970"},
           {"label": "Pignataro Interamna", "value": "C54971"},
           {"label": "Pisoniano", "value": "C54972"},
           {"label": "Pofi", "value": "C54973"},
           {"label": "Poggio Bustone", "value": "C54974"},
           {"label": "Poggio Catino", "value": "C54975"},
           {"label": "Poggio Ellera", "value": "C54976"},
           {"label": "Poggio Mirteto", "value": "C54977"},
           {"label": "Poggio Moiano", "value": "C54978"},
           {"label": "Poggio Nativo", "value": "C54979"},
           {"label": "Poggio San Lorenzo", "value": "C54980"},
           {"label": "Poli", "value": "C54981"},
           {"label": "Pomezia", "value": "C54982"},
           {"label": "Ponte Galeria-La Pisana", "value": "C54983"},
           {"label": "Pontecorvo", "value": "C54984"},
           {"label": "Pontinia", "value": "C54985"},
           {"label": "Ponton dellElce", "value": "C54986"},
           {"label": "Ponza", "value": "C54987"},
           {"label": "Ponzano Romano", "value": "C54988"},
           {"label": "Posta", "value": "C54989"},
           {"label": "Posta Fibreno", "value": "C54990"},
           {"label": "Pozzaglia Sabino", "value": "C54991"},
           {"label": "Prato di Coppola", "value": "C54992"},
           {"label": "Priverno", "value": "C54993"},
           {"label": "Proceno", "value": "C54994"},
           {"label": "Prossedi", "value": "C54995"},
           {"label": "Provincia di Frosinone", "value": "C54996"},
           {"label": "Provincia di Latina", "value": "C54997"},
           {"label": "Provincia di Rieti", "value": "C54998"},
           {"label": "Provincia di Viterbo", "value": "C54999"},
           {"label": "Regolelli", "value": "C55000"},
           {"label": "Riano", "value": "C55001"},
           {"label": "Rieti", "value": "C55002"},
           {"label": "Rignano Flaminio", "value": "C55003"},
           {"label": "Riofreddo", "value": "C55004"},
           {"label": "Ripi", "value": "C55005"},
           {"label": "Rivodutri", "value": "C55006"},
           {"label": "Rocca Canterano", "value": "C55007"},
           {"label": "Rocca dArce", "value": "C55008"},
           {"label": "Rocca di Cave", "value": "C55009"},
           {"label": "Rocca di Papa", "value": "C55010"},
           {"label": "Rocca Massima", "value": "C55011"},
           {"label": "Rocca Priora", "value": "C55012"},
           {"label": "Rocca Santo Stefano", "value": "C55013"},
           {"label": "Rocca Sinibalda", "value": "C55014"},
           {"label": "Roccagiovine", "value": "C55015"},
           {"label": "Roccagorga", "value": "C55016"},
           {"label": "Roccantica", "value": "C55017"},
           {"label": "Roccasecca", "value": "C55018"},
           {"label": "Roccasecca dei Volsci", "value": "C55019"},
           {"label": "Roccasecca Stazione", "value": "C55020"},
           {"label": "Roiate", "value": "C55021"},
           {"label": "Rome", "value": "C55022"},
           {"label": "Ronciglione", "value": "C55023"},
           {"label": "Roviano", "value": "C55024"},
           {"label": "Sabaudia", "value": "C55025"},
           {"label": "Sacrofano", "value": "C55026"},
           {"label": "Salisano", "value": "C55027"},
           {"label": "Sambuci", "value": "C55028"},
           {"label": "San Bartolomeo", "value": "C55029"},
           {"label": "San Biagio Saracinisco", "value": "C55030"},
           {"label": "San Cesareo", "value": "C55031"},
           {"label": "San Donato Val di Comino", "value": "C55032"},
           {"label": "San Felice Circeo", "value": "C55033"},
           {"label": "San Giorgio a Liri", "value": "C55034"},
           {"label": "San Giovanni Incarico", "value": "C55035"},
           {"label": "San Giovanni-Patoni", "value": "C55036"},
           {"label": "San Giuseppe le Prata-Cotropagno", "value": "C55037"},
           {"label": "San Gregorio da Sassola", "value": "C55038"},
           {"label": "San Lorenzo Nuovo", "value": "C55039"},
           {"label": "San Martino al Cimino", "value": "C55040"},
           {"label": "San Polo dei Cavalieri", "value": "C55041"},
           {"label": "San Vito Romano", "value": "C55042"},
           {"label": "San Vittore del Lazio", "value": "C55043"},
           {"label": "SantAmbrogio sul Garigliano", "value": "C55044"},
           {"label": "SantAndrea del Garigliano", "value": "C55045"},
           {"label": "SantAngelo in Villa-Giglio", "value": "C55046"},
           {"label": "SantAngelo Romano", "value": "C55047"},
           {"label": "SantApollinare", "value": "C55048"},
           {"label": "SantElia Fiumerapido", "value": "C55049"},
           {"label": "SantOreste", "value": "C55050"},
           {"label": "Santa Lucia", "value": "C55051"},
           {"label": "Santa Marinella", "value": "C55052"},
           {"label": "Santa Rufina", "value": "C55053"},
           {"label": "Santi Cosma e Damiano", "value": "C55054"},
           {"label": "Santopadre", "value": "C55055"},
           {"label": "Saracinesco", "value": "C55056"},
           {"label": "Sassacci", "value": "C55057"},
           {"label": "Scandriglia", "value": "C55058"},
           {"label": "Segni", "value": "C55059"},
           {"label": "Selcetta", "value": "C55060"},
           {"label": "Selci", "value": "C55061"},
           {"label": "Sermoneta", "value": "C55062"},
           {"label": "Serrone", "value": "C55063"},
           {"label": "Settefrati", "value": "C55064"},
           {"label": "Setteville", "value": "C55065"},
           {"label": "Sezze", "value": "C55066"},
           {"label": "Sezze Scalo", "value": "C55067"},
           {"label": "Sgurgola", "value": "C55068"},
           {"label": "Sonnino", "value": "C55069"},
           {"label": "Sora", "value": "C55070"},
           {"label": "Soriano nel Cimino", "value": "C55071"},
           {"label": "Sperlonga", "value": "C55072"},
           {"label": "Spigno Saturnia Inferiore", "value": "C55073"},
           {"label": "Spigno Saturnia Superiore", "value": "C55074"},
           {"label": "Stimigliano", "value": "C55075"},
           {"label": "Strangolagalli", "value": "C55076"},
           {"label": "Subiaco", "value": "C55077"},
           {"label": "Supino", "value": "C55078"},
           {"label": "Sutri", "value": "C55079"},
           {"label": "Tarano", "value": "C55080"},
           {"label": "Tarquinia", "value": "C55081"},
           {"label": "Tecchiena", "value": "C55082"},
           {"label": "Terelle", "value": "C55083"},
           {"label": "Terracina", "value": "C55084"},
           {"label": "Tessennano", "value": "C55085"},
           {"label": "Tivoli", "value": "C55086"},
           {"label": "Toffia", "value": "C55087"},
           {"label": "Tolfa", "value": "C55088"},
           {"label": "Tor Lupara", "value": "C55089"},
           {"label": "Torre Caietani", "value": "C55090"},
           {"label": "Torri in Sabina", "value": "C55091"},
           {"label": "Torrice", "value": "C55092"},
           {"label": "Torricella in Sabina", "value": "C55093"},
           {"label": "Torrita Tiberina", "value": "C55094"},
           {"label": "Torvaianica", "value": "C55095"},
           {"label": "Torvaianica Alta", "value": "C55096"},
           {"label": "Tragliatella Campitello", "value": "C55097"},
           {"label": "Trevi nel Lazio", "value": "C55098"},
           {"label": "Trevignano Romano", "value": "C55099"},
           {"label": "Trivigliano", "value": "C55100"},
           {"label": "Turania", "value": "C55101"},
           {"label": "Tuscania", "value": "C55102"},
           {"label": "Vacone", "value": "C55103"},
           {"label": "Valcanneto", "value": "C55104"},
           {"label": "Valentano", "value": "C55105"},
           {"label": "Valle Martella", "value": "C55106"},
           {"label": "Valle Santa", "value": "C55107"},
           {"label": "Vallecorsa", "value": "C55108"},
           {"label": "Vallemaio", "value": "C55109"},
           {"label": "Vallepietra", "value": "C55110"},
           {"label": "Vallerano", "value": "C55111"},
           {"label": "Vallerotonda", "value": "C55112"},
           {"label": "Vallinfreda", "value": "C55113"},
           {"label": "Valmontone", "value": "C55114"},
           {"label": "Varco Sabino", "value": "C55115"},
           {"label": "Vasanello", "value": "C55116"},
           {"label": "Vejano", "value": "C55117"},
           {"label": "Velletri", "value": "C55118"},
           {"label": "Ventotene", "value": "C55119"},
           {"label": "Veroli", "value": "C55120"},
           {"label": "Vetralla", "value": "C55121"},
           {"label": "Vicalvi", "value": "C55122"},
           {"label": "Vico nel Lazio", "value": "C55123"},
           {"label": "Vicovaro", "value": "C55124"},
           {"label": "Vignanello", "value": "C55125"},
           {"label": "Villa Adriana", "value": "C55126"},
           {"label": "Villa Latina", "value": "C55127"},
           {"label": "Villa San Giovanni in Tuscia", "value": "C55128"},
           {"label": "Villa Santa Lucia", "value": "C55129"},
           {"label": "Villa Santo Stefano", "value": "C55130"},
           {"label": "Villalba", "value": "C55131"},
           {"label": "Villanova", "value": "C55132"},
           {"label": "Viterbo", "value": "C55133"},
           {"label": "Viticuso", "value": "C55134"},
           {"label": "Vitinia", "value": "C55135"},
           {"label": "Vitorchiano", "value": "C55136"},
           {"label": "Vivaro Romano", "value": "C55137"},
           {"label": "Zagarolo", "value": "C55138"},
           {"label": "Zepponami", "value": "C55139"}],
 "Lebap Region": [{"label": "Atamyrat", "value": "C121180"},
                  {"label": "Farap", "value": "C121181"},
                  {"label": "Gazojak", "value": "C121182"},
                  {"label": "Gowurdak", "value": "C121183"},
                  {"label": "Sayat", "value": "C121184"},
                  {"label": "Turkmenabat", "value": "C121185"}],
 "Lefkada Regional Unit": [{"label": "Agria", "value": "C42315"},
                           {"label": "alli Meria", "value": "C42316"},
                           {"label": "Almyros", "value": "C42317"},
                           {"label": "Anakasia", "value": "C42318"},
                           {"label": "ano Lekhonia", "value": "C42319"},
                           {"label": "Argalasti", "value": "C42320"},
                           {"label": "Evxinoupolis", "value": "C42321"},
                           {"label": "Kanalia", "value": "C42322"},
                           {"label": "Kato Lekhonia", "value": "C42323"},
                           {"label": "Nea Anchialos", "value": "C42324"},
                           {"label": "Nea Ionia", "value": "C42325"},
                           {"label": "Patitirion", "value": "C42326"},
                           {"label": "Portaria", "value": "C42327"},
                           {"label": "Pteleos", "value": "C42328"},
                           {"label": "Rizomylos", "value": "C42329"},
                           {"label": "Skiathos", "value": "C42330"},
                           {"label": "Skopelos", "value": "C42331"},
                           {"label": "Sourpi", "value": "C42332"},
                           {"label": "Stefanovikeio", "value": "C42333"},
                           {"label": "Trikeri", "value": "C42334"},
                           {"label": "Velestino", "value": "C42335"},
                           {"label": "Volos", "value": "C42336"},
                           {"label": "Zagora", "value": "C42337"}],
 "Leinster": [{"label": "Abbeyleix", "value": "C51256"},
              {"label": "An Iarmhi", "value": "C51257"},
              {"label": "An Longfort", "value": "C51258"},
              {"label": "An Mhi", "value": "C51259"},
              {"label": "An Muileann gCearr", "value": "C51260"},
              {"label": "An Ros", "value": "C51261"},
              {"label": "Ardee", "value": "C51262"},
              {"label": "Arklow", "value": "C51263"},
              {"label": "Artane", "value": "C51264"},
              {"label": "Ashbourne", "value": "C51265"},
              {"label": "Ashford", "value": "C51266"},
              {"label": "Athboy", "value": "C51267"},
              {"label": "Athgarvan", "value": "C51268"},
              {"label": "Athlone", "value": "C51269"},
              {"label": "Athy", "value": "C51270"},
              {"label": "Aughrim", "value": "C51271"},
              {"label": "Bagenalstown", "value": "C51272"},
              {"label": "Balally", "value": "C51273"},
              {"label": "Balbriggan", "value": "C51274"},
              {"label": "Baldoyle", "value": "C51275"},
              {"label": "Ballinroad", "value": "C51276"},
              {"label": "Ballinteer", "value": "C51277"},
              {"label": "Ballivor", "value": "C51278"},
              {"label": "Ballyboden", "value": "C51279"},
              {"label": "Ballyfermot", "value": "C51280"},
              {"label": "Ballygerry", "value": "C51281"},
              {"label": "Ballylinan", "value": "C51282"},
              {"label": "Ballymahon", "value": "C51283"},
              {"label": "Ballymun", "value": "C51284"},
              {"label": "Ballyragget", "value": "C51285"},
              {"label": "Balrothery", "value": "C51286"},
              {"label": "Baltinglass", "value": "C51287"},
              {"label": "Banagher", "value": "C51288"},
              {"label": "Bayside", "value": "C51289"},
              {"label": "Beaumont", "value": "C51290"},
              {"label": "Birr", "value": "C51291"},
              {"label": "Blackrock", "value": "C51292"},
              {"label": "Blanchardstown", "value": "C51293"},
              {"label": "Blessington", "value": "C51294"},
              {"label": "Bonnybrook", "value": "C51295"},
              {"label": "Booterstown", "value": "C51296"},
              {"label": "Bray", "value": "C51297"},
              {"label": "Bunclody", "value": "C51298"},
              {"label": "Cabinteely", "value": "C51299"},
              {"label": "Cabra", "value": "C51300"},
              {"label": "Callan", "value": "C51301"},
              {"label": "Carlingford", "value": "C51302"},
              {"label": "Carlow", "value": "C51303"},
              {"label": "Carnew", "value": "C51304"},
              {"label": "Castlebellingham", "value": "C51305"},
              {"label": "Castlebridge", "value": "C51306"},
              {"label": "Castlecomer", "value": "C51307"},
              {"label": "Castledermot", "value": "C51308"},
              {"label": "Castleknock", "value": "C51309"},
              {"label": "Castlepollard", "value": "C51310"},
              {"label": "Castletown", "value": "C51311"},
              {"label": "Celbridge", "value": "C51312"},
              {"label": "Chapelizod", "value": "C51313"},
              {"label": "Charlesland", "value": "C51314"},
              {"label": "Cherry Orchard", "value": "C51315"},
              {"label": "Cherryville", "value": "C51316"},
              {"label": "Clane", "value": "C51317"},
              {"label": "Clara", "value": "C51318"},
              {"label": "Clogherhead", "value": "C51319"},
              {"label": "Clondalkin", "value": "C51320"},
              {"label": "Clonskeagh", "value": "C51321"},
              {"label": "Confey", "value": "C51322"},
              {"label": "Coolock", "value": "C51323"},
              {"label": "County Carlow", "value": "C51324"},
              {"label": "Courtown", "value": "C51325"},
              {"label": "Crumlin", "value": "C51326"},
              {"label": "Daingean", "value": "C51327"},
              {"label": "Dalkey", "value": "C51328"},
              {"label": "Darndale", "value": "C51329"},
              {"label": "Derrinturn", "value": "C51330"},
              {"label": "Dollymount", "value": "C51331"},
              {"label": "Donabate", "value": "C51332"},
              {"label": "Donaghmede", "value": "C51333"},
              {"label": "Donnybrook", "value": "C51334"},
              {"label": "Donnycarney", "value": "C51335"},
              {"label": "Drogheda", "value": "C51336"},
              {"label": "Droichead Nua", "value": "C51337"},
              {"label": "Dromiskin", "value": "C51338"},
              {"label": "Drumcondra", "value": "C51339"},
              {"label": "Dublin", "value": "C51340"},
              {"label": "Dublin City", "value": "C51341"},
              {"label": "Duleek", "value": "C51342"},
              {"label": "Dun Laoghaire", "value": "C51343"},
              {"label": "Dun Laoghaire-Rathdown", "value": "C51344"},
              {"label": "Dunboyne", "value": "C51345"},
              {"label": "Dundalk", "value": "C51346"},
              {"label": "Dundrum", "value": "C51347"},
              {"label": "Dunleer", "value": "C51348"},
              {"label": "Dunshaughlin", "value": "C51349"},
              {"label": "Eadestown", "value": "C51350"},
              {"label": "Edenderry", "value": "C51351"},
              {"label": "Edgeworthstown", "value": "C51352"},
              {"label": "Enfield", "value": "C51353"},
              {"label": "Enniscorthy", "value": "C51354"},
              {"label": "Enniskerry", "value": "C51355"},
              {"label": "Fairview", "value": "C51356"},
              {"label": "Ferbane", "value": "C51357"},
              {"label": "Ferns", "value": "C51358"},
              {"label": "Fingal County", "value": "C51359"},
              {"label": "Finglas", "value": "C51360"},
              {"label": "Firhouse", "value": "C51361"},
              {"label": "Foxrock", "value": "C51362"},
              {"label": "Glasnevin", "value": "C51363"},
              {"label": "Gorey", "value": "C51364"},
              {"label": "Graiguenamanagh", "value": "C51365"},
              {"label": "Granard", "value": "C51366"},
              {"label": "Greenhills", "value": "C51367"},
              {"label": "Greystones", "value": "C51368"},
              {"label": "Hartstown", "value": "C51369"},
              {"label": "Howth", "value": "C51370"},
              {"label": "Jobstown", "value": "C51371"},
              {"label": "Johnstown", "value": "C51372"},
              {"label": "Kells", "value": "C51373"},
              {"label": "Kentstown", "value": "C51374"},
              {"label": "Kilbeggan", "value": "C51375"},
              {"label": "Kilcock", "value": "C51376"},
              {"label": "Kilcoole", "value": "C51377"},
              {"label": "Kilcullen", "value": "C51378"},
              {"label": "Kildare", "value": "C51379"},
              {"label": "Kilkenny", "value": "C51380"},
              {"label": "Kill", "value": "C51381"},
              {"label": "Killester", "value": "C51382"},
              {"label": "Kilmacanoge", "value": "C51383"},
              {"label": "Kilpedder", "value": "C51384"},
              {"label": "Kilquade", "value": "C51385"},
              {"label": "Kinnegad", "value": "C51386"},
              {"label": "Kinsealy-Drinan", "value": "C51387"},
              {"label": "Knocklyon", "value": "C51388"},
              {"label": "Lanesborough", "value": "C51389"},
              {"label": "Laois", "value": "C51390"},
              {"label": "Laytown", "value": "C51391"},
              {"label": "Leixlip", "value": "C51392"},
              {"label": "Little Bray", "value": "C51393"},
              {"label": "Loch Garman", "value": "C51394"},
              {"label": "Longford", "value": "C51395"},
              {"label": "Longwood", "value": "C51396"},
              {"label": "Loughlinstown", "value": "C51397"},
              {"label": "Lu", "value": "C51398"},
              {"label": "Lucan", "value": "C51399"},
              {"label": "Lusk", "value": "C51400"},
              {"label": "Malahide", "value": "C51401"},
              {"label": "Marino", "value": "C51402"},
              {"label": "Maynooth", "value": "C51403"},
              {"label": "Milltown", "value": "C51404"},
              {"label": "Moate", "value": "C51405"},
              {"label": "Monasterevin", "value": "C51406"},
              {"label": "Monkstown", "value": "C51407"},
              {"label": "Mooncoin", "value": "C51408"},
              {"label": "Moone", "value": "C51409"},
              {"label": "Mount Merrion", "value": "C51410"},
              {"label": "Mountmellick", "value": "C51411"},
              {"label": "Mountrath", "value": "C51412"},
              {"label": "Naas", "value": "C51413"},
              {"label": "Navan", "value": "C51414"},
              {"label": "New Ross", "value": "C51415"},
              {"label": "Newcastle", "value": "C51416"},
              {"label": "Newtown Trim", "value": "C51417"},
              {"label": "Newtownmountkennedy", "value": "C51418"},
              {"label": "Old Kilcullen", "value": "C51419"},
              {"label": "Oldbawn", "value": "C51420"},
              {"label": "Oldcastle", "value": "C51421"},
              {"label": "Palmerstown", "value": "C51422"},
              {"label": "Piltown", "value": "C51423"},
              {"label": "Portarlington", "value": "C51424"},
              {"label": "Portlaoise", "value": "C51425"},
              {"label": "Portmarnock", "value": "C51426"},
              {"label": "Portraine", "value": "C51427"},
              {"label": "Prosperous", "value": "C51428"},
              {"label": "Raheny", "value": "C51429"},
              {"label": "Rathangan", "value": "C51430"},
              {"label": "Rathcoole", "value": "C51431"},
              {"label": "Rathdowney", "value": "C51432"},
              {"label": "Rathdrum", "value": "C51433"},
              {"label": "Rathgar", "value": "C51434"},
              {"label": "Rathmines", "value": "C51435"},
              {"label": "Rathnew", "value": "C51436"},
              {"label": "Rathwire", "value": "C51437"},
              {"label": "Ratoath", "value": "C51438"},
              {"label": "Rialto", "value": "C51439"},
              {"label": "Ringsend", "value": "C51440"},
              {"label": "Rochfortbridge", "value": "C51441"},
              {"label": "Rosslare", "value": "C51442"},
              {"label": "Saggart", "value": "C51443"},
              {"label": "Sallins", "value": "C51444"},
              {"label": "Sallynoggin", "value": "C51445"},
              {"label": "Sandyford", "value": "C51446"},
              {"label": "Sandymount", "value": "C51447"},
              {"label": "Shankill", "value": "C51448"},
              {"label": "Skerries", "value": "C51449"},
              {"label": "Slane", "value": "C51450"},
              {"label": "South Dublin", "value": "C51451"},
              {"label": "Stamullin", "value": "C51452"},
              {"label": "Stradbally", "value": "C51453"},
              {"label": "Sutton", "value": "C51454"},
              {"label": "Swords", "value": "C51455"},
              {"label": "Tallaght", "value": "C51456"},
              {"label": "Templeogue", "value": "C51457"},
              {"label": "Terenure", "value": "C51458"},
              {"label": "Termonfeckin", "value": "C51459"},
              {"label": "Thomastown", "value": "C51460"},
              {"label": "Trim", "value": "C51461"},
              {"label": "Tullamore", "value": "C51462"},
              {"label": "Tullow", "value": "C51463"},
              {"label": "Tullyallen", "value": "C51464"},
              {"label": "Uibh Fhaili", "value": "C51465"},
              {"label": "Valleymount", "value": "C51466"},
              {"label": "Wicklow", "value": "C51467"}],
 "Leiria": [{"label": "A dos Francos", "value": "C91623"},
            {"label": "Alcobaca", "value": "C91624"},
            {"label": "Alfeizerao", "value": "C91625"},
            {"label": "Aljubarrota", "value": "C91626"},
            {"label": "Alvaiazere", "value": "C91627"},
            {"label": "Alvorninha", "value": "C91628"},
            {"label": "Amor", "value": "C91629"},
            {"label": "Ansiao", "value": "C91630"},
            {"label": "Atouguia da Baleia", "value": "C91631"},
            {"label": "Batalha", "value": "C91632"},
            {"label": "Benedita", "value": "C91633"},
            {"label": "Bombarral", "value": "C91634"},
            {"label": "Caldas da Rainha", "value": "C91635"},
            {"label": "Caranguejeira", "value": "C91636"},
            {"label": "Castanheira de Pera", "value": "C91637"},
            {"label": "Cela", "value": "C91638"},
            {"label": "Famalicao", "value": "C91639"},
            {"label": "Ferrel", "value": "C91640"},
            {"label": "Figueiro Dos Vinhos", "value": "C91641"},
            {"label": "Foz do Arelho", "value": "C91642"},
            {"label": "Leiria", "value": "C91643"},
            {"label": "Lourical", "value": "C91644"},
            {"label": "Maceira", "value": "C91645"},
            {"label": "Marinha Grande", "value": "C91646"},
            {"label": "Mira", "value": "C91647"},
            {"label": "Monte Real", "value": "C91648"},
            {"label": "Monte Redondo", "value": "C91649"},
            {"label": "Nadadouro", "value": "C91650"},
            {"label": "Nazare", "value": "C91651"},
            {"label": "obidos", "value": "C91652"},
            {"label": "Pataias", "value": "C91653"},
            {"label": "Pedrogao Grande", "value": "C91654"},
            {"label": "Peniche", "value": "C91655"},
            {"label": "Pombal", "value": "C91656"},
            {"label": "Porto de Mos", "value": "C91657"},
            {"label": "Salir de Matos", "value": "C91658"},
            {"label": "Santa Catarina da Serra", "value": "C91659"},
            {"label": "Sao Martinho do Porto", "value": "C91660"},
            {"label": "Serra de El-Rei", "value": "C91661"},
            {"label": "Souto da Carpalhosa", "value": "C91662"},
            {"label": "Turquel", "value": "C91663"},
            {"label": "Valado de Frades", "value": "C91664"},
            {"label": "Vestiaria", "value": "C91665"},
            {"label": "Vidais", "value": "C91666"},
            {"label": "Vieira de Leiria", "value": "C91667"}],
 "Lekoumou Department": [{"label": "Sibiti", "value": "C20752"}],
 "Lempira Department": [{"label": "Belen", "value": "C43551"},
                        {"label": "Candelaria", "value": "C43552"},
                        {"label": "Cololaca", "value": "C43553"},
                        {"label": "El Achiotal", "value": "C43554"},
                        {"label": "Erandique", "value": "C43555"},
                        {"label": "Gracias", "value": "C43556"},
                        {"label": "Gualcince", "value": "C43557"},
                        {"label": "Guarita", "value": "C43558"},
                        {"label": "La Campa", "value": "C43559"},
                        {"label": "La Iguala", "value": "C43560"},
                        {"label": "La Libertad", "value": "C43561"},
                        {"label": "La Union", "value": "C43562"},
                        {"label": "La Virtud", "value": "C43563"},
                        {"label": "Las Flores", "value": "C43564"},
                        {"label": "Las Tejeras", "value": "C43565"},
                        {"label": "Lepaera", "value": "C43566"},
                        {"label": "Mapulaca", "value": "C43567"},
                        {"label": "Piraera", "value": "C43568"},
                        {"label": "San Andres", "value": "C43569"},
                        {"label": "San Francisco", "value": "C43570"},
                        {"label": "San Juan Guarita", "value": "C43571"},
                        {"label": "San Manuel Colohete", "value": "C43572"},
                        {"label": "San Marcos de Caiquin", "value": "C43573"},
                        {"label": "San Rafael", "value": "C43574"},
                        {"label": "San Sebastian", "value": "C43575"},
                        {"label": "Santa Cruz", "value": "C43576"},
                        {"label": "Talgua", "value": "C43577"},
                        {"label": "Tambla", "value": "C43578"},
                        {"label": "Taragual", "value": "C43579"},
                        {"label": "Tomala", "value": "C43580"},
                        {"label": "Valladolid", "value": "C43581"},
                        {"label": "Virginia", "value": "C43582"}],
 "Lenart Municipality": [{"label": "Lenart v Slov. Goricah",
                          "value": "C107687"}],
 "Lendava Municipality": [{"label": "Lendava", "value": "C107688"}],
 "Leningrad Oblast": [{"label": "Agalatovo", "value": "C101952"},
                      {"label": "Akademicheskoe", "value": "C101953"},
                      {"label": "Annino", "value": "C101954"},
                      {"label": "Begunitsy", "value": "C101955"},
                      {"label": "Boksitogorsk", "value": "C101956"},
                      {"label": "Boksitogorskiy Rayon", "value": "C101957"},
                      {"label": "Bolshaya Izhora", "value": "C101958"},
                      {"label": "Borisova Griva", "value": "C101959"},
                      {"label": "Budogoshch", "value": "C101960"},
                      {"label": "Bugry", "value": "C101961"},
                      {"label": "Chernaya Rechka", "value": "C101962"},
                      {"label": "Druzhnaya Gorka", "value": "C101963"},
                      {"label": "Fedorovskoye", "value": "C101964"},
                      {"label": "Finlyandskiy", "value": "C101965"},
                      {"label": "Fornosovo", "value": "C101966"},
                      {"label": "Garbolovo", "value": "C101967"},
                      {"label": "Gatchina", "value": "C101968"},
                      {"label": "Glebychevo", "value": "C101969"},
                      {"label": "Gorbunki", "value": "C101970"},
                      {"label": "Gorskaya", "value": "C101971"},
                      {"label": "Gostilitsy", "value": "C101972"},
                      {"label": "Ilichevo", "value": "C101973"},
                      {"label": "Imeni Morozova", "value": "C101974"},
                      {"label": "Imeni Sverdlova", "value": "C101975"},
                      {"label": "Issad", "value": "C101976"},
                      {"label": "Ivangorod", "value": "C101977"},
                      {"label": "Kalininskiy", "value": "C101978"},
                      {"label": "Kamenka", "value": "C101979"},
                      {"label": "Kamennogorsk", "value": "C101980"},
                      {"label": "Khvalovo", "value": "C101981"},
                      {"label": "Kikerino", "value": "C101982"},
                      {"label": "Kingisepp", "value": "C101983"},
                      {"label": "Kingiseppskiy Rayon", "value": "C101984"},
                      {"label": "Kipen", "value": "C101985"},
                      {"label": "Kirishi", "value": "C101986"},
                      {"label": "Kirovsk", "value": "C101987"},
                      {"label": "Kirovskiy Rayon", "value": "C101988"},
                      {"label": "Kobrinskoye", "value": "C101989"},
                      {"label": "Kolchanovo", "value": "C101990"},
                      {"label": "Koltushi", "value": "C101991"},
                      {"label": "Kommunar", "value": "C101992"},
                      {"label": "Koporye", "value": "C101993"},
                      {"label": "Korobitsyno", "value": "C101994"},
                      {"label": "Krasnyy Bor", "value": "C101995"},
                      {"label": "Krestovskiy ostrov", "value": "C101996"},
                      {"label": "Kurortnyy", "value": "C101997"},
                      {"label": "Kuyvozi", "value": "C101998"},
                      {"label": "Kuznechnoye", "value": "C101999"},
                      {"label": "Lebyazhye", "value": "C102000"},
                      {"label": "Leninskoye", "value": "C102001"},
                      {"label": "Leskolovo", "value": "C102002"},
                      {"label": "Lesogorskiy", "value": "C102003"},
                      {"label": "Lodeynopolskiy Rayon", "value": "C102004"},
                      {"label": "Lodeynoye Pole", "value": "C102005"},
                      {"label": "Lomonosovskiy Rayon", "value": "C102006"},
                      {"label": "Luga", "value": "C102007"},
                      {"label": "Luppolovo", "value": "C102008"},
                      {"label": "Luzhskiy Rayon", "value": "C102009"},
                      {"label": "Lyuban", "value": "C102010"},
                      {"label": "Maloye Verevo", "value": "C102011"},
                      {"label": "Mariyenburg", "value": "C102012"},
                      {"label": "Mga", "value": "C102013"},
                      {"label": "Michurinskoye", "value": "C102014"},
                      {"label": "Mshinskaya", "value": "C102015"},
                      {"label": "Murino", "value": "C102016"},
                      {"label": "Nikolskoye", "value": "C102017"},
                      {"label": "Novaya Ladoga", "value": "C102018"},
                      {"label": "Novoye Devyatkino", "value": "C102019"},
                      {"label": "Nurma", "value": "C102020"},
                      {"label": "Olgino", "value": "C102021"},
                      {"label": "Oselki", "value": "C102022"},
                      {"label": "Otradnoye", "value": "C102023"},
                      {"label": "Parnas", "value": "C102024"},
                      {"label": "Pasha", "value": "C102025"},
                      {"label": "Pavlovo", "value": "C102026"},
                      {"label": "Pervomayskoye", "value": "C102027"},
                      {"label": "Pikalevo", "value": "C102028"},
                      {"label": "Podporozhye", "value": "C102029"},
                      {"label": "Podporozhskiy Rayon", "value": "C102030"},
                      {"label": "Priladozhskiy", "value": "C102031"},
                      {"label": "Primorsk", "value": "C102032"},
                      {"label": "Priozersk", "value": "C102033"},
                      {"label": "Priozersky", "value": "C102034"},
                      {"label": "Romanovka", "value": "C102035"},
                      {"label": "Roshchino", "value": "C102036"},
                      {"label": "Rozhdestveno", "value": "C102037"},
                      {"label": "Russko-Vysotskoye", "value": "C102038"},
                      {"label": "Ryabovo", "value": "C102039"},
                      {"label": "Rybatskoye", "value": "C102040"},
                      {"label": "Sampsonievskiy", "value": "C102041"},
                      {"label": "Sapernoye", "value": "C102042"},
                      {"label": "Semiozerje", "value": "C102043"},
                      {"label": "Sertolovo", "value": "C102044"},
                      {"label": "Shcheglovo", "value": "C102045"},
                      {"label": "Shlisselburg", "value": "C102046"},
                      {"label": "Sinyavino", "value": "C102047"},
                      {"label": "Sista-Palkino", "value": "C102048"},
                      {"label": "Siverskiy", "value": "C102049"},
                      {"label": "Slantsevskiy Rayon", "value": "C102050"},
                      {"label": "Slantsy", "value": "C102051"},
                      {"label": "Sosnovo", "value": "C102052"},
                      {"label": "Sosnovyy Bor", "value": "C102053"},
                      {"label": "Sovetskiy", "value": "C102054"},
                      {"label": "Staraya", "value": "C102055"},
                      {"label": "Staraya Ladoga", "value": "C102056"},
                      {"label": "Svetlanovskiy", "value": "C102057"},
                      {"label": "Svetogorsk", "value": "C102058"},
                      {"label": "Syasstroy", "value": "C102059"},
                      {"label": "Taytsy", "value": "C102060"},
                      {"label": "Tikhvin", "value": "C102061"},
                      {"label": "Tikhvinskiy Rayon", "value": "C102062"},
                      {"label": "Toksovo", "value": "C102063"},
                      {"label": "Tolmachevo", "value": "C102064"},
                      {"label": "Tosnenskiy Rayon", "value": "C102065"},
                      {"label": "Tosno", "value": "C102066"},
                      {"label": "Udelnaya", "value": "C102067"},
                      {"label": "Ulyanovka", "value": "C102068"},
                      {"label": "Untolovo", "value": "C102069"},
                      {"label": "Ust-Luga", "value": "C102070"},
                      {"label": "Vaskelovo", "value": "C102071"},
                      {"label": "Vazhiny", "value": "C102072"},
                      {"label": "Verkhniye Oselki", "value": "C102073"},
                      {"label": "Veshchevo", "value": "C102074"},
                      {"label": "Vinnitsy", "value": "C102075"},
                      {"label": "Vistino", "value": "C102076"},
                      {"label": "Volkhov", "value": "C102077"},
                      {"label": "Volkhovskiy rayon", "value": "C102078"},
                      {"label": "Volosovo", "value": "C102079"},
                      {"label": "Volosovskiy Rayon", "value": "C102080"},
                      {"label": "Voyskovitsy", "value": "C102081"},
                      {"label": "Voznesenye", "value": "C102082"},
                      {"label": "Vsevolozhsk", "value": "C102083"},
                      {"label": "Vsevolozhskiy Rayon", "value": "C102084"},
                      {"label": "Vyborg", "value": "C102085"},
                      {"label": "Vyritsa", "value": "C102086"},
                      {"label": "Vysotsk", "value": "C102087"},
                      {"label": "Yakovlevo", "value": "C102088"},
                      {"label": "Yanino-1", "value": "C102089"},
                      {"label": "Yaroslavichi", "value": "C102090"},
                      {"label": "Yefimovskiy", "value": "C102091"},
                      {"label": "Yelizavetino", "value": "C102092"}],
 "Leon": [{"label": "Achuapa", "value": "C77940"},
          {"label": "El Jicaral", "value": "C77941"},
          {"label": "El Sauce", "value": "C77942"},
          {"label": "La Paz Centro", "value": "C77943"},
          {"label": "Larreynaga", "value": "C77944"},
          {"label": "Leon", "value": "C77945"},
          {"label": "Nagarote", "value": "C77946"},
          {"label": "Quezalguaque", "value": "C77947"},
          {"label": "Santa Rosa del Penon", "value": "C77948"},
          {"label": "Telica", "value": "C77949"},
          {"label": "Abades", "value": "C112395"},
          {"label": "Abajas", "value": "C112396"},
          {"label": "Abejar", "value": "C112397"},
          {"label": "Abezames", "value": "C112398"},
          {"label": "Abia de las Torres", "value": "C112399"},
          {"label": "Abusejo", "value": "C112400"},
          {"label": "Acebedo", "value": "C112401"},
          {"label": "Adalia", "value": "C112402"},
          {"label": "Adanero", "value": "C112403"},
          {"label": "Adrada de Haza", "value": "C112404"},
          {"label": "Adrada de Piron", "value": "C112405"},
          {"label": "Adradas", "value": "C112406"},
          {"label": "Adrados", "value": "C112407"},
          {"label": "Agallas", "value": "C112408"},
          {"label": "agreda", "value": "C112409"},
          {"label": "Aguasal", "value": "C112410"},
          {"label": "Aguilafuente", "value": "C112411"},
          {"label": "Aguilar de Bureba", "value": "C112412"},
          {"label": "Aguilar de Campoo", "value": "C112413"},
          {"label": "Aguilar de Campos", "value": "C112414"},
          {"label": "Ahigal de los Aceiteros", "value": "C112415"},
          {"label": "Ahigal de Villarino", "value": "C112416"},
          {"label": "Alaejos", "value": "C112417"},
          {"label": "Alar del Rey", "value": "C112418"},
          {"label": "Alaraz", "value": "C112419"},
          {"label": "Alba de Cerrato", "value": "C112420"},
          {"label": "Alba de Tormes", "value": "C112421"},
          {"label": "Alba de Yeltes", "value": "C112422"},
          {"label": "Albillos", "value": "C112423"},
          {"label": "Albornos", "value": "C112424"},
          {"label": "Alcanices", "value": "C112425"},
          {"label": "Alcazaren", "value": "C112426"},
          {"label": "Alcocero de Mola", "value": "C112427"},
          {"label": "Alconaba", "value": "C112428"},
          {"label": "Alconada", "value": "C112429"},
          {"label": "Alconada de Maderuelo", "value": "C112430"},
          {"label": "Alcubilla de Avellaneda", "value": "C112431"},
          {"label": "Alcubilla de las Penas", "value": "C112432"},
          {"label": "Alcubilla de Nogales", "value": "C112433"},
          {"label": "Aldea de San Miguel", "value": "C112434"},
          {"label": "Aldea del Obispo", "value": "C112435"},
          {"label": "Aldea Real", "value": "C112436"},
          {"label": "Aldeacipreste", "value": "C112437"},
          {"label": "Aldeadavila de la Ribera", "value": "C112438"},
          {"label": "Aldealafuente", "value": "C112439"},
          {"label": "Aldealcorvo", "value": "C112440"},
          {"label": "Aldealengua", "value": "C112441"},
          {"label": "Aldealengua de Santa Maria", "value": "C112442"},
          {"label": "Aldealpozo", "value": "C112443"},
          {"label": "Aldealsenor", "value": "C112444"},
          {"label": "Aldeamayor de San Martin", "value": "C112445"},
          {"label": "Aldeanueva de Figueroa", "value": "C112446"},
          {"label": "Aldeanueva de la Sierra", "value": "C112447"},
          {"label": "Aldeanueva de Santa Cruz", "value": "C112448"},
          {"label": "Aldeanueva del Codonal", "value": "C112449"},
          {"label": "Aldearrodrigo", "value": "C112450"},
          {"label": "Aldearrubia", "value": "C112451"},
          {"label": "Aldeaseca", "value": "C112452"},
          {"label": "Aldeaseca de Alba", "value": "C112453"},
          {"label": "Aldeaseca de la Frontera", "value": "C112454"},
          {"label": "Aldeasona", "value": "C112455"},
          {"label": "Aldeatejada", "value": "C112456"},
          {"label": "Aldeavieja de Tormes", "value": "C112457"},
          {"label": "Aldehuela de la Boveda", "value": "C112458"},
          {"label": "Aldehuela de Yeltes", "value": "C112459"},
          {"label": "Aldehuela del Codonal", "value": "C112460"},
          {"label": "Aldeonte", "value": "C112461"},
          {"label": "Alentisque", "value": "C112462"},
          {"label": "Algadefe", "value": "C112463"},
          {"label": "Algodre", "value": "C112464"},
          {"label": "Aliud", "value": "C112465"},
          {"label": "Almajano", "value": "C112466"},
          {"label": "Almaluez", "value": "C112467"},
          {"label": "Almanza", "value": "C112468"},
          {"label": "Almaraz de Duero", "value": "C112469"},
          {"label": "Almarza", "value": "C112470"},
          {"label": "Almazan", "value": "C112471"},
          {"label": "Almazul", "value": "C112472"},
          {"label": "Almenar de Soria", "value": "C112473"},
          {"label": "Almenara de Adaja", "value": "C112474"},
          {"label": "Almenara de Tormes", "value": "C112475"},
          {"label": "Almendra", "value": "C112476"},
          {"label": "Alpanseque", "value": "C112477"},
          {"label": "Altable", "value": "C112478"},
          {"label": "Amavida", "value": "C112479"},
          {"label": "Amayuelas de Arriba", "value": "C112480"},
          {"label": "Ameyugo", "value": "C112481"},
          {"label": "Ampudia", "value": "C112482"},
          {"label": "Amusco", "value": "C112483"},
          {"label": "Amusquillo", "value": "C112484"},
          {"label": "Anaya", "value": "C112485"},
          {"label": "Anaya de Alba", "value": "C112486"},
          {"label": "Ane", "value": "C112487"},
          {"label": "Anguix", "value": "C112488"},
          {"label": "Anover de Tormes", "value": "C112489"},
          {"label": "Antiguedad", "value": "C112490"},
          {"label": "Arahuetes", "value": "C112491"},
          {"label": "Arancon", "value": "C112492"},
          {"label": "Aranda de Duero", "value": "C112493"},
          {"label": "Arandilla", "value": "C112494"},
          {"label": "Arapiles", "value": "C112495"},
          {"label": "Arauzo de Miel", "value": "C112496"},
          {"label": "Arauzo de Salce", "value": "C112497"},
          {"label": "Arauzo de Torre", "value": "C112498"},
          {"label": "Arcediano", "value": "C112499"},
          {"label": "Arcenillas", "value": "C112500"},
          {"label": "Arconada", "value": "C112501"},
          {"label": "Arcones", "value": "C112502"},
          {"label": "Arcos", "value": "C112503"},
          {"label": "Arcos de Jalon", "value": "C112504"},
          {"label": "Arcos de la Polvorosa", "value": "C112505"},
          {"label": "Ardon", "value": "C112506"},
          {"label": "Arenas de San Pedro", "value": "C112507"},
          {"label": "Arenillas", "value": "C112508"},
          {"label": "Arevalillo de Cega", "value": "C112509"},
          {"label": "Arevalo", "value": "C112510"},
          {"label": "Arevalo de la Sierra", "value": "C112511"},
          {"label": "Arganin", "value": "C112512"},
          {"label": "Arganza", "value": "C112513"},
          {"label": "Argujillo", "value": "C112514"},
          {"label": "Arija", "value": "C112515"},
          {"label": "Arlanzon", "value": "C112516"},
          {"label": "Armenteros", "value": "C112517"},
          {"label": "Armuna", "value": "C112518"},
          {"label": "Arquillinos", "value": "C112519"},
          {"label": "Arrabalde", "value": "C112520"},
          {"label": "Arraya de Oca", "value": "C112521"},
          {"label": "Arroyo de la Encomienda", "value": "C112522"},
          {"label": "Aspariegos", "value": "C112523"},
          {"label": "Astorga", "value": "C112524"},
          {"label": "Astudillo", "value": "C112525"},
          {"label": "Asturianos", "value": "C112526"},
          {"label": "Atapuerca", "value": "C112527"},
          {"label": "Ataquines", "value": "C112528"},
          {"label": "Ausejo de la Sierra", "value": "C112529"},
          {"label": "Autilla del Pino", "value": "C112530"},
          {"label": "Autillo de Campos", "value": "C112531"},
          {"label": "Aveinte", "value": "C112532"},
          {"label": "Avellaneda", "value": "C112533"},
          {"label": "Avellanosa de Muno", "value": "C112534"},
          {"label": "avila", "value": "C112535"},
          {"label": "Ayllon", "value": "C112536"},
          {"label": "Ayoo de Vidriales", "value": "C112537"},
          {"label": "Ayuela", "value": "C112538"},
          {"label": "Babilafuente", "value": "C112539"},
          {"label": "Bahabon", "value": "C112540"},
          {"label": "Bahabon de Esgueva", "value": "C112541"},
          {"label": "Balboa", "value": "C112542"},
          {"label": "Baltanas", "value": "C112543"},
          {"label": "Banobarez", "value": "C112544"},
          {"label": "Banos de Valdearados", "value": "C112545"},
          {"label": "Banuelos de Bureba", "value": "C112546"},
          {"label": "Baquerin de Campos", "value": "C112547"},
          {"label": "Barbadillo de Herreros", "value": "C112548"},
          {"label": "Barbadillo del Mercado", "value": "C112549"},
          {"label": "Barbadillo del Pez", "value": "C112550"},
          {"label": "Barbalos", "value": "C112551"},
          {"label": "Barbolla", "value": "C112552"},
          {"label": "Barca", "value": "C112553"},
          {"label": "Barcena de Campos", "value": "C112554"},
          {"label": "Barceo", "value": "C112555"},
          {"label": "Barcial de la Loma", "value": "C112556"},
          {"label": "Barcial del Barco", "value": "C112557"},
          {"label": "Barcones", "value": "C112558"},
          {"label": "Barjas", "value": "C112559"},
          {"label": "Barraco", "value": "C112560"},
          {"label": "Barrio de Muno", "value": "C112561"},
          {"label": "Barrios de Colina", "value": "C112562"},
          {"label": "Barroman", "value": "C112563"},
          {"label": "Barruecopardo", "value": "C112564"},
          {"label": "Barruelo de Santullan", "value": "C112565"},
          {"label": "Basardilla", "value": "C112566"},
          {"label": "Basconcillos del Tozo", "value": "C112567"},
          {"label": "Bascones de Ojeda", "value": "C112568"},
          {"label": "Bascunana", "value": "C112569"},
          {"label": "Bayubas de Abajo", "value": "C112570"},
          {"label": "Bayubas de Arriba", "value": "C112571"},
          {"label": "Becedas", "value": "C112572"},
          {"label": "Becedillas", "value": "C112573"},
          {"label": "Becerril de Campos", "value": "C112574"},
          {"label": "Becilla de Valderaduey", "value": "C112575"},
          {"label": "Bejar", "value": "C112576"},
          {"label": "Belbimbre", "value": "C112577"},
          {"label": "Belena", "value": "C112578"},
          {"label": "Belmonte de Campos", "value": "C112579"},
          {"label": "Belorado", "value": "C112580"},
          {"label": "Belver de los Montes", "value": "C112581"},
          {"label": "Bembibre", "value": "C112582"},
          {"label": "Benafarces", "value": "C112583"},
          {"label": "Benavente", "value": "C112584"},
          {"label": "Benavides", "value": "C112585"},
          {"label": "Benegiles", "value": "C112586"},
          {"label": "Benuza", "value": "C112587"},
          {"label": "Beraton", "value": "C112588"},
          {"label": "Berberana", "value": "C112589"},
          {"label": "Bercero", "value": "C112590"},
          {"label": "Berceruelo", "value": "C112591"},
          {"label": "Bercial", "value": "C112592"},
          {"label": "Bercial de Zapardiel", "value": "C112593"},
          {"label": "Bercianos del Paramo", "value": "C112594"},
          {"label": "Bercianos del Real Camino", "value": "C112595"},
          {"label": "Bercimuel", "value": "C112596"},
          {"label": "Berlanga de Duero", "value": "C112597"},
          {"label": "Berlanga del Bierzo", "value": "C112598"},
          {"label": "Berlangas de Roa", "value": "C112599"},
          {"label": "Bermellar", "value": "C112600"},
          {"label": "Bermillo de Sayago", "value": "C112601"},
          {"label": "Bernardos", "value": "C112602"},
          {"label": "Berrocal de Huebra", "value": "C112603"},
          {"label": "Berrocal de Salvatierra", "value": "C112604"},
          {"label": "Berrocalejo de Aragona", "value": "C112605"},
          {"label": "Berrueces", "value": "C112606"},
          {"label": "Berzosa de Bureba", "value": "C112607"},
          {"label": "Berzosilla", "value": "C112608"},
          {"label": "Blacos", "value": "C112609"},
          {"label": "Blascomillan", "value": "C112610"},
          {"label": "Blascosancho", "value": "C112611"},
          {"label": "Bliecos", "value": "C112612"},
          {"label": "Boada", "value": "C112613"},
          {"label": "Boada de Campos", "value": "C112614"},
          {"label": "Boadilla del Camino", "value": "C112615"},
          {"label": "Bobadilla del Campo", "value": "C112616"},
          {"label": "Boca de Huergano", "value": "C112617"},
          {"label": "Boceguillas", "value": "C112618"},
          {"label": "Bocigas", "value": "C112619"},
          {"label": "Bocos de Duero", "value": "C112620"},
          {"label": "Boecillo", "value": "C112621"},
          {"label": "Bogajo", "value": "C112622"},
          {"label": "Bohoyo", "value": "C112623"},
          {"label": "Bolanos de Campos", "value": "C112624"},
          {"label": "Bonar", "value": "C112625"},
          {"label": "Bonilla de la Sierra", "value": "C112626"},
          {"label": "Borjabad", "value": "C112627"},
          {"label": "Borobia", "value": "C112628"},
          {"label": "Borrenes", "value": "C112629"},
          {"label": "Boveda del Rio Almar", "value": "C112630"},
          {"label": "Brabos", "value": "C112631"},
          {"label": "Branosera", "value": "C112632"},
          {"label": "Brazacorta", "value": "C112633"},
          {"label": "Brazuelo", "value": "C112634"},
          {"label": "Breto", "value": "C112635"},
          {"label": "Bretocino", "value": "C112636"},
          {"label": "Brieva", "value": "C112637"},
          {"label": "Brime de Sog", "value": "C112638"},
          {"label": "Brime de Urz", "value": "C112639"},
          {"label": "Brincones", "value": "C112640"},
          {"label": "Briviesca", "value": "C112641"},
          {"label": "Buberos", "value": "C112642"},
          {"label": "Buenamadre", "value": "C112643"},
          {"label": "Buenavista", "value": "C112644"},
          {"label": "Buenavista de Valdavia", "value": "C112645"},
          {"label": "Bugedo", "value": "C112646"},
          {"label": "Buitrago", "value": "C112647"},
          {"label": "Buniel", "value": "C112648"},
          {"label": "Burganes de Valverde", "value": "C112649"},
          {"label": "Burgohondo", "value": "C112650"},
          {"label": "Burgos", "value": "C112651"},
          {"label": "Buron", "value": "C112652"},
          {"label": "Bustillo de Chaves", "value": "C112653"},
          {"label": "Bustillo de la Vega", "value": "C112654"},
          {"label": "Bustillo del Oro", "value": "C112655"},
          {"label": "Bustillo del Paramo", "value": "C112656"},
          {"label": "Bustillo del Paramo de Carrion", "value": "C112657"},
          {"label": "Busto de Bureba", "value": "C112658"},
          {"label": "Caballar", "value": "C112659"},
          {"label": "Cabanas de Polendos", "value": "C112660"},
          {"label": "Cabanas de Sayago", "value": "C112661"},
          {"label": "Cabanes de Esgueva", "value": "C112662"},
          {"label": "Cabeza del Caballo", "value": "C112663"},
          {"label": "Cabezabellosa de la Calzada", "value": "C112664"},
          {"label": "Cabezas de Alambre", "value": "C112665"},
          {"label": "Cabezas del Pozo", "value": "C112666"},
          {"label": "Cabezas del Villar", "value": "C112667"},
          {"label": "Cabezon", "value": "C112668"},
          {"label": "Cabezon de la Sierra", "value": "C112669"},
          {"label": "Cabezon de Valderaduey", "value": "C112670"},
          {"label": "Cabezuela", "value": "C112671"},
          {"label": "Cabizuela", "value": "C112672"},
          {"label": "Cabrejas del Campo", "value": "C112673"},
          {"label": "Cabrejas del Pinar", "value": "C112674"},
          {"label": "Cabrerizos", "value": "C112675"},
          {"label": "Cabreros del Monte", "value": "C112676"},
          {"label": "Cabreros del Rio", "value": "C112677"},
          {"label": "Cabrillanes", "value": "C112678"},
          {"label": "Cabrillas", "value": "C112679"},
          {"label": "Cacabelos", "value": "C112680"},
          {"label": "Calahorra de Boedo", "value": "C112681"},
          {"label": "Calatanazor", "value": "C112682"},
          {"label": "Caleruega", "value": "C112683"},
          {"label": "Caltojar", "value": "C112684"},
          {"label": "Calvarrasa de Abajo", "value": "C112685"},
          {"label": "Calvarrasa de Arriba", "value": "C112686"},
          {"label": "Calzada de Don Diego", "value": "C112687"},
          {"label": "Calzada de los Molinos", "value": "C112688"},
          {"label": "Calzada de Valdunciel", "value": "C112689"},
          {"label": "Calzada del Coto", "value": "C112690"},
          {"label": "Calzadilla de Tera", "value": "C112691"},
          {"label": "Camarzana de Tera", "value": "C112692"},
          {"label": "Campaspero", "value": "C112693"},
          {"label": "Campazas", "value": "C112694"},
          {"label": "Campillo de Aranda", "value": "C112695"},
          {"label": "Campillo de Azaba", "value": "C112696"},
          {"label": "Campo de San Pedro", "value": "C112697"},
          {"label": "Campo de Villavidel", "value": "C112698"},
          {"label": "Campolara", "value": "C112699"},
          {"label": "Camponaraya", "value": "C112700"},
          {"label": "Camporredondo", "value": "C112701"},
          {"label": "Canalejas de Penafiel", "value": "C112702"},
          {"label": "Canales", "value": "C112703"},
          {"label": "Canamaque", "value": "C112704"},
          {"label": "Candelario", "value": "C112705"},
          {"label": "Candeleda", "value": "C112706"},
          {"label": "Candilichera", "value": "C112707"},
          {"label": "Candin", "value": "C112708"},
          {"label": "Canicosa de la Sierra", "value": "C112709"},
          {"label": "Canillas de Abajo", "value": "C112710"},
          {"label": "Canillas de Esgueva", "value": "C112711"},
          {"label": "Canizal", "value": "C112712"},
          {"label": "Canizo", "value": "C112713"},
          {"label": "Cantabrana", "value": "C112714"},
          {"label": "Cantagallo", "value": "C112715"},
          {"label": "Cantalapiedra", "value": "C112716"},
          {"label": "Cantalejo", "value": "C112717"},
          {"label": "Cantalpino", "value": "C112718"},
          {"label": "Cantaracillo", "value": "C112719"},
          {"label": "Cantimpalos", "value": "C112720"},
          {"label": "Cantiveros", "value": "C112721"},
          {"label": "Capillas", "value": "C112722"},
          {"label": "Carabantes", "value": "C112723"},
          {"label": "Caracena", "value": "C112724"},
          {"label": "Carazo", "value": "C112725"},
          {"label": "Carbajales de Alba", "value": "C112726"},
          {"label": "Carbajosa de la Sagrada", "value": "C112727"},
          {"label": "Carbellino", "value": "C112728"},
          {"label": "Carbonero el Mayor", "value": "C112729"},
          {"label": "Carcedo de Bureba", "value": "C112730"},
          {"label": "Carcedo de Burgos", "value": "C112731"},
          {"label": "Cardenadijo", "value": "C112732"},
          {"label": "Cardenajimeno", "value": "C112733"},
          {"label": "Cardenosa", "value": "C112734"},
          {"label": "Cardenosa de Volpejera", "value": "C112735"},
          {"label": "Carmenes", "value": "C112736"},
          {"label": "Carpio", "value": "C112737"},
          {"label": "Carpio de Azaba", "value": "C112738"},
          {"label": "Carracedelo", "value": "C112739"},
          {"label": "Carrascal de Barregas", "value": "C112740"},
          {"label": "Carrascal del Obispo", "value": "C112741"},
          {"label": "Carrascosa de Abajo", "value": "C112742"},
          {"label": "Carrascosa de la Sierra", "value": "C112743"},
          {"label": "Carrias", "value": "C112744"},
          {"label": "Carrion de los Condes", "value": "C112745"},
          {"label": "Carrizo de la Ribera", "value": "C112746"},
          {"label": "Carrocera", "value": "C112747"},
          {"label": "Carucedo", "value": "C112748"},
          {"label": "Casafranca", "value": "C112749"},
          {"label": "Casarejos", "value": "C112750"},
          {"label": "Casaseca de Campean", "value": "C112751"},
          {"label": "Casaseca de las Chanas", "value": "C112752"},
          {"label": "Casasola de Arion", "value": "C112753"},
          {"label": "Casavieja", "value": "C112754"},
          {"label": "Cascajares de Bureba", "value": "C112755"},
          {"label": "Cascajares de la Sierra", "value": "C112756"},
          {"label": "Casillas", "value": "C112757"},
          {"label": "Casillas de Flores", "value": "C112758"},
          {"label": "Casla", "value": "C112759"},
          {"label": "Castellanos de Castro", "value": "C112760"},
          {"label": "Castellanos de Moriscos", "value": "C112761"},
          {"label": "Castellanos de Villiquera", "value": "C112762"},
          {"label": "Castellanos de Zapardiel", "value": "C112763"},
          {"label": "Castil de Peones", "value": "C112764"},
          {"label": "Castil de Vela", "value": "C112765"},
          {"label": "Castildelgado", "value": "C112766"},
          {"label": "Castilfale", "value": "C112767"},
          {"label": "Castilfrio de la Sierra", "value": "C112768"},
          {"label": "Castillejo de Martin Viejo", "value": "C112769"},
          {"label": "Castillejo de Mesleon", "value": "C112770"},
          {"label": "Castillejo de Robledo", "value": "C112771"},
          {"label": "Castilruiz", "value": "C112772"},
          {"label": "Castraz", "value": "C112773"},
          {"label": "Castrejon de la Pena", "value": "C112774"},
          {"label": "Castrillo de Cabrera", "value": "C112775"},
          {"label": "Castrillo de Don Juan", "value": "C112776"},
          {"label": "Castrillo de Duero", "value": "C112777"},
          {"label": "Castrillo de la Guarena", "value": "C112778"},
          {"label": "Castrillo de la Reina", "value": "C112779"},
          {"label": "Castrillo de la Valduerna", "value": "C112780"},
          {"label": "Castrillo de la Vega", "value": "C112781"},
          {"label": "Castrillo de Onielo", "value": "C112782"},
          {"label": "Castrillo de Villavega", "value": "C112783"},
          {"label": "Castrillo del Val", "value": "C112784"},
          {"label": "Castrillo-Tejeriego", "value": "C112785"},
          {"label": "Castro de Fuentiduena", "value": "C112786"},
          {"label": "Castrobol", "value": "C112787"},
          {"label": "Castrocalbon", "value": "C112788"},
          {"label": "Castrocontrigo", "value": "C112789"},
          {"label": "Castrodeza", "value": "C112790"},
          {"label": "Castrogonzalo", "value": "C112791"},
          {"label": "Castrojimeno", "value": "C112792"},
          {"label": "Castromembibre", "value": "C112793"},
          {"label": "Castromocho", "value": "C112794"},
          {"label": "Castromonte", "value": "C112795"},
          {"label": "Castronuevo", "value": "C112796"},
          {"label": "Castronuevo de Esgueva", "value": "C112797"},
          {"label": "Castronuno", "value": "C112798"},
          {"label": "Castropodame", "value": "C112799"},
          {"label": "Castroponce", "value": "C112800"},
          {"label": "Castroserna de Abajo", "value": "C112801"},
          {"label": "Castroserracin", "value": "C112802"},
          {"label": "Castroverde de Campos", "value": "C112803"},
          {"label": "Castroverde de Cerrato", "value": "C112804"},
          {"label": "Cayuela", "value": "C112805"},
          {"label": "Cazurra", "value": "C112806"},
          {"label": "Cebanico", "value": "C112807"},
          {"label": "Cebrecos", "value": "C112808"},
          {"label": "Cebreros", "value": "C112809"},
          {"label": "Cebrones del Rio", "value": "C112810"},
          {"label": "Cedillo de la Torre", "value": "C112811"},
          {"label": "Ceinos de Campos", "value": "C112812"},
          {"label": "Celada del Camino", "value": "C112813"},
          {"label": "Centenera de Andaluz", "value": "C112814"},
          {"label": "Cepeda", "value": "C112815"},
          {"label": "Cepeda la Mora", "value": "C112816"},
          {"label": "Cerbon", "value": "C112817"},
          {"label": "Cereceda de la Sierra", "value": "C112818"},
          {"label": "Cerecinos de Campos", "value": "C112819"},
          {"label": "Cerecinos del Carrizal", "value": "C112820"},
          {"label": "Cerezal de Penahorcada", "value": "C112821"},
          {"label": "Cerezo de Abajo", "value": "C112822"},
          {"label": "Cerezo de Arriba", "value": "C112823"},
          {"label": "Cernadilla", "value": "C112824"},
          {"label": "Cerralbo", "value": "C112825"},
          {"label": "Cerraton de Juarros", "value": "C112826"},
          {"label": "Cervatos de la Cueza", "value": "C112827"},
          {"label": "Cervera de Pisuerga", "value": "C112828"},
          {"label": "Cervillego de la Cruz", "value": "C112829"},
          {"label": "Cevico de la Torre", "value": "C112830"},
          {"label": "Cevico Navero", "value": "C112831"},
          {"label": "Chamartin", "value": "C112832"},
          {"label": "Chane", "value": "C112833"},
          {"label": "Chozas de Abajo", "value": "C112834"},
          {"label": "Ciadoncha", "value": "C112835"},
          {"label": "Cidones", "value": "C112836"},
          {"label": "Cigales", "value": "C112837"},
          {"label": "Cigudosa", "value": "C112838"},
          {"label": "Cigunuela", "value": "C112839"},
          {"label": "Cihuela", "value": "C112840"},
          {"label": "Cillan", "value": "C112841"},
          {"label": "Cilleros de la Bastida", "value": "C112842"},
          {"label": "Cilleruelo de Abajo", "value": "C112843"},
          {"label": "Cilleruelo de Arriba", "value": "C112844"},
          {"label": "Cilleruelo de San Mames", "value": "C112845"},
          {"label": "Cimanes de la Vega", "value": "C112846"},
          {"label": "Cimanes del Tejar", "value": "C112847"},
          {"label": "Ciperez", "value": "C112848"},
          {"label": "Ciria", "value": "C112849"},
          {"label": "Ciruelos de Cervera", "value": "C112850"},
          {"label": "Cirujales del Rio", "value": "C112851"},
          {"label": "Cisla", "value": "C112852"},
          {"label": "Cisneros", "value": "C112853"},
          {"label": "Cisterniga", "value": "C112854"},
          {"label": "Cistierna", "value": "C112855"},
          {"label": "Ciudad Rodrigo", "value": "C112856"},
          {"label": "Cobos de Cerrato", "value": "C112857"},
          {"label": "Cobos de Fuentiduena", "value": "C112858"},
          {"label": "Cobreros", "value": "C112859"},
          {"label": "Coca", "value": "C112860"},
          {"label": "Coca de Alba", "value": "C112861"},
          {"label": "Codorniz", "value": "C112862"},
          {"label": "Cogeces del Monte", "value": "C112863"},
          {"label": "Cogollos", "value": "C112864"},
          {"label": "Collado de Contreras", "value": "C112865"},
          {"label": "Collado del Miron", "value": "C112866"},
          {"label": "Collado Hermoso", "value": "C112867"},
          {"label": "Collazos de Boedo", "value": "C112868"},
          {"label": "Colmenar de Montemayor", "value": "C112869"},
          {"label": "Congosto", "value": "C112870"},
          {"label": "Congosto de Valdavia", "value": "C112871"},
          {"label": "Constanzana", "value": "C112872"},
          {"label": "Contreras", "value": "C112873"},
          {"label": "Coomonte", "value": "C112874"},
          {"label": "Corbillos de los Oteros", "value": "C112875"},
          {"label": "Corcos", "value": "C112876"},
          {"label": "Cordovilla", "value": "C112877"},
          {"label": "Cordovilla la Real", "value": "C112878"},
          {"label": "Coreses", "value": "C112879"},
          {"label": "Corral de Ayllon", "value": "C112880"},
          {"label": "Corrales", "value": "C112881"},
          {"label": "Corrales de Duero", "value": "C112882"},
          {"label": "Corullon", "value": "C112883"},
          {"label": "Coruna del Conde", "value": "C112884"},
          {"label": "Coscurita", "value": "C112885"},
          {"label": "Covaleda", "value": "C112886"},
          {"label": "Covarrubias", "value": "C112887"},
          {"label": "Cozuelos de Fuentiduena", "value": "C112888"},
          {"label": "Cremenes", "value": "C112889"},
          {"label": "Crespos", "value": "C112890"},
          {"label": "Cristobal", "value": "C112891"},
          {"label": "Cuadros", "value": "C112892"},
          {"label": "Cubilla", "value": "C112893"},
          {"label": "Cubillas de Cerrato", "value": "C112894"},
          {"label": "Cubillas de los Oteros", "value": "C112895"},
          {"label": "Cubillas de Rueda", "value": "C112896"},
          {"label": "Cubillas de Santa Marta", "value": "C112897"},
          {"label": "Cubillo", "value": "C112898"},
          {"label": "Cubillo del Campo", "value": "C112899"},
          {"label": "Cubillos", "value": "C112900"},
          {"label": "Cubillos del Sil", "value": "C112901"},
          {"label": "Cubo de Benavente", "value": "C112902"},
          {"label": "Cubo de Bureba", "value": "C112903"},
          {"label": "Cubo de la Solana", "value": "C112904"},
          {"label": "Cuelgamures", "value": "C112905"},
          {"label": "Cuellar", "value": "C112906"},
          {"label": "Cuenca de Campos", "value": "C112907"},
          {"label": "Cueva de agreda", "value": "C112908"},
          {"label": "Cuevas de Provanco", "value": "C112909"},
          {"label": "Cuevas de San Clemente", "value": "C112910"},
          {"label": "Cuevas del Valle", "value": "C112911"},
          {"label": "Curiel de Duero", "value": "C112912"},
          {"label": "Dehesa de Montejo", "value": "C112913"},
          {"label": "Dehesa de Romanos", "value": "C112914"},
          {"label": "Destriana", "value": "C112915"},
          {"label": "Deza", "value": "C112916"},
          {"label": "Dios le Guarde", "value": "C112917"},
          {"label": "Domingo Garcia", "value": "C112918"},
          {"label": "Donhierro", "value": "C112919"},
          {"label": "Doninos de Ledesma", "value": "C112920"},
          {"label": "Doninos de Salamanca", "value": "C112921"},
          {"label": "Donjimeno", "value": "C112922"},
          {"label": "Donvidas", "value": "C112923"},
          {"label": "Duenas", "value": "C112924"},
          {"label": "Duruelo", "value": "C112925"},
          {"label": "Duruelo de la Sierra", "value": "C112926"},
          {"label": "Ejeme", "value": "C112927"},
          {"label": "El Barco de avila", "value": "C112928"},
          {"label": "El Burgo de Osma", "value": "C112929"},
          {"label": "El Hoyo de Pinares", "value": "C112930"},
          {"label": "El Tiemblo", "value": "C112931"},
          {"label": "Encina de San Silvestre", "value": "C112932"},
          {"label": "Encinas", "value": "C112933"},
          {"label": "Encinas de Abajo", "value": "C112934"},
          {"label": "Encinas de Arriba", "value": "C112935"},
          {"label": "Encinas de Esgueva", "value": "C112936"},
          {"label": "Encinasola de los Comendadores", "value": "C112937"},
          {"label": "Encinedo", "value": "C112938"},
          {"label": "Encinillas", "value": "C112939"},
          {"label": "Encio", "value": "C112940"},
          {"label": "Endrinal", "value": "C112941"},
          {"label": "Entrala", "value": "C112942"},
          {"label": "Escalona del Prado", "value": "C112943"},
          {"label": "Escarabajosa de Cabezas", "value": "C112944"},
          {"label": "Escobar de Campos", "value": "C112945"},
          {"label": "Escobar de Polendos", "value": "C112946"},
          {"label": "Escobosa de Almazan", "value": "C112947"},
          {"label": "Escurial de la Sierra", "value": "C112948"},
          {"label": "Esguevillas de Esgueva", "value": "C112949"},
          {"label": "Espadana", "value": "C112950"},
          {"label": "Espadanedo", "value": "C112951"},
          {"label": "Espeja", "value": "C112952"},
          {"label": "Espeja de San Marcelino", "value": "C112953"},
          {"label": "Espejon", "value": "C112954"},
          {"label": "Espino de la Orbada", "value": "C112955"},
          {"label": "Espinosa de Cerrato", "value": "C112956"},
          {"label": "Espinosa de Cervera", "value": "C112957"},
          {"label": "Espinosa de los Monteros", "value": "C112958"},
          {"label": "Espinosa de Villagonzalo", "value": "C112959"},
          {"label": "Espinosa del Camino", "value": "C112960"},
          {"label": "Espirdo", "value": "C112961"},
          {"label": "Estepa de San Juan", "value": "C112962"},
          {"label": "Estepar", "value": "C112963"},
          {"label": "Fabero", "value": "C112964"},
          {"label": "Faramontanos de Tabara", "value": "C112965"},
          {"label": "Fariza", "value": "C112966"},
          {"label": "Fermoselle", "value": "C112967"},
          {"label": "Ferreras de Abajo", "value": "C112968"},
          {"label": "Ferreras de Arriba", "value": "C112969"},
          {"label": "Ferreruela", "value": "C112970"},
          {"label": "Figueruela de Arriba", "value": "C112971"},
          {"label": "Flores de avila", "value": "C112972"},
          {"label": "Florida de Liebana", "value": "C112973"},
          {"label": "Folgoso de la Ribera", "value": "C112974"},
          {"label": "Fombellida", "value": "C112975"},
          {"label": "Fompedraza", "value": "C112976"},
          {"label": "Fonfria", "value": "C112977"},
          {"label": "Fontihoyuelo", "value": "C112978"},
          {"label": "Fontioso", "value": "C112979"},
          {"label": "Fontiveros", "value": "C112980"},
          {"label": "Forfoleda", "value": "C112981"},
          {"label": "Frades de la Sierra", "value": "C112982"},
          {"label": "Frandovinez", "value": "C112983"},
          {"label": "Frechilla", "value": "C112984"},
          {"label": "Frechilla de Almazan", "value": "C112985"},
          {"label": "Fresneda de Cuellar", "value": "C112986"},
          {"label": "Fresneda de la Sierra Tiron", "value": "C112987"},
          {"label": "Fresnedilla", "value": "C112988"},
          {"label": "Fresnedoso", "value": "C112989"},
          {"label": "Fresnena", "value": "C112990"},
          {"label": "Fresnillo de las Duenas", "value": "C112991"},
          {"label": "Fresno de Cantespino", "value": "C112992"},
          {"label": "Fresno de Caracena", "value": "C112993"},
          {"label": "Fresno de la Fuente", "value": "C112994"},
          {"label": "Fresno de la Polvorosa", "value": "C112995"},
          {"label": "Fresno de la Ribera", "value": "C112996"},
          {"label": "Fresno de la Vega", "value": "C112997"},
          {"label": "Fresno de Rodilla", "value": "C112998"},
          {"label": "Fresno de Sayago", "value": "C112999"},
          {"label": "Fresno del Rio", "value": "C113000"},
          {"label": "Fresno El Viejo", "value": "C113001"},
          {"label": "Frias", "value": "C113002"},
          {"label": "Friera de Valverde", "value": "C113003"},
          {"label": "Fromista", "value": "C113004"},
          {"label": "Frumales", "value": "C113005"},
          {"label": "Fuensaldana", "value": "C113006"},
          {"label": "Fuente de Santa Cruz", "value": "C113007"},
          {"label": "Fuente el Olmo de Fuentiduena", "value": "C113008"},
          {"label": "Fuente el Sol", "value": "C113009"},
          {"label": "Fuente Encalada", "value": "C113010"},
          {"label": "Fuentearmegil", "value": "C113011"},
          {"label": "Fuentebureba", "value": "C113012"},
          {"label": "Fuentecambron", "value": "C113013"},
          {"label": "Fuentecantos", "value": "C113014"},
          {"label": "Fuentecen", "value": "C113015"},
          {"label": "Fuenteguinaldo", "value": "C113016"},
          {"label": "Fuentelapena", "value": "C113017"},
          {"label": "Fuentelcesped", "value": "C113018"},
          {"label": "Fuentelisendo", "value": "C113019"},
          {"label": "Fuentelsaz de Soria", "value": "C113020"},
          {"label": "Fuentemolinos", "value": "C113021"},
          {"label": "Fuentenebro", "value": "C113022"},
          {"label": "Fuentepelayo", "value": "C113023"},
          {"label": "Fuentepinel", "value": "C113024"},
          {"label": "Fuentepinilla", "value": "C113025"},
          {"label": "Fuenterroble de Salvatierra", "value": "C113026"},
          {"label": "Fuentes de Ano", "value": "C113027"},
          {"label": "Fuentes de Bejar", "value": "C113028"},
          {"label": "Fuentes de Carbajal", "value": "C113029"},
          {"label": "Fuentes de Magana", "value": "C113030"},
          {"label": "Fuentes de Nava", "value": "C113031"},
          {"label": "Fuentes de Onoro", "value": "C113032"},
          {"label": "Fuentes de Ropel", "value": "C113033"},
          {"label": "Fuentes de Valdepero", "value": "C113034"},
          {"label": "Fuentesauco", "value": "C113035"},
          {"label": "Fuentesauco de Fuentiduena", "value": "C113036"},
          {"label": "Fuentesecas", "value": "C113037"},
          {"label": "Fuentesoto", "value": "C113038"},
          {"label": "Fuentespina", "value": "C113039"},
          {"label": "Fuentespreadas", "value": "C113040"},
          {"label": "Fuentestrun", "value": "C113041"},
          {"label": "Fuentiduena", "value": "C113042"},
          {"label": "Gajates", "value": "C113043"},
          {"label": "Galbarros", "value": "C113044"},
          {"label": "Galende", "value": "C113045"},
          {"label": "Galindo y Perahuy", "value": "C113046"},
          {"label": "Galinduste", "value": "C113047"},
          {"label": "Galisancho", "value": "C113048"},
          {"label": "Gallegos", "value": "C113049"},
          {"label": "Gallegos de Arganan", "value": "C113050"},
          {"label": "Gallegos de Hornija", "value": "C113051"},
          {"label": "Gallegos de Sobrinos", "value": "C113052"},
          {"label": "Gallegos del Pan", "value": "C113053"},
          {"label": "Gallegos del Rio", "value": "C113054"},
          {"label": "Gamonal", "value": "C113055"},
          {"label": "Gamones", "value": "C113056"},
          {"label": "Garcibuey", "value": "C113057"},
          {"label": "Garcihernandez", "value": "C113058"},
          {"label": "Garcillan", "value": "C113059"},
          {"label": "Garcirrey", "value": "C113060"},
          {"label": "Garganta del Villar", "value": "C113061"},
          {"label": "Garrafe de Torio", "value": "C113062"},
          {"label": "Garray", "value": "C113063"},
          {"label": "Gaton de Campos", "value": "C113064"},
          {"label": "Gavilanes", "value": "C113065"},
          {"label": "Gejuelo del Barro", "value": "C113066"},
          {"label": "Gema", "value": "C113067"},
          {"label": "Gemuno", "value": "C113068"},
          {"label": "Geria", "value": "C113069"},
          {"label": "Gimialcon", "value": "C113070"},
          {"label": "Golmayo", "value": "C113071"},
          {"label": "Golpejas", "value": "C113072"},
          {"label": "Gomara", "value": "C113073"},
          {"label": "Gomecello", "value": "C113074"},
          {"label": "Gordaliza del Pino", "value": "C113075"},
          {"label": "Gordoncillo", "value": "C113076"},
          {"label": "Gormaz", "value": "C113077"},
          {"label": "Gotarrendura", "value": "C113078"},
          {"label": "Gradefes", "value": "C113079"},
          {"label": "Grajal de Campos", "value": "C113080"},
          {"label": "Grajera", "value": "C113081"},
          {"label": "Granja de Moreruela", "value": "C113082"},
          {"label": "Granucillo", "value": "C113083"},
          {"label": "Grijalba", "value": "C113084"},
          {"label": "Grijota", "value": "C113085"},
          {"label": "Grisalena", "value": "C113086"},
          {"label": "Guadramiro", "value": "C113087"},
          {"label": "Guardo", "value": "C113088"},
          {"label": "Guaza de Campos", "value": "C113089"},
          {"label": "Guijo de avila", "value": "C113090"},
          {"label": "Guijuelo", "value": "C113091"},
          {"label": "Guisando", "value": "C113092"},
          {"label": "Gusendos de los Oteros", "value": "C113093"},
          {"label": "Gutierre-Munoz", "value": "C113094"},
          {"label": "Hacinas", "value": "C113095"},
          {"label": "Haza", "value": "C113096"},
          {"label": "Herguijuela de Ciudad Rodrigo", "value": "C113097"},
          {"label": "Herguijuela de la Sierra", "value": "C113098"},
          {"label": "Herguijuela del Campo", "value": "C113099"},
          {"label": "Hermedes de Cerrato", "value": "C113100"},
          {"label": "Hermisende", "value": "C113101"},
          {"label": "Hernansancho", "value": "C113102"},
          {"label": "Herrera de Pisuerga", "value": "C113103"},
          {"label": "Herrera de Soria", "value": "C113104"},
          {"label": "Herrera de Valdecanas", "value": "C113105"},
          {"label": "Herreros de Suso", "value": "C113106"},
          {"label": "Herrin de Campos", "value": "C113107"},
          {"label": "Higuera de las Duenas", "value": "C113108"},
          {"label": "Hinojosa de Duero", "value": "C113109"},
          {"label": "Hinojosa del Campo", "value": "C113110"},
          {"label": "Honrubia de la Cuesta", "value": "C113111"},
          {"label": "Hontalbilla", "value": "C113112"},
          {"label": "Hontanares de Eresma", "value": "C113113"},
          {"label": "Hontanas", "value": "C113114"},
          {"label": "Hontangas", "value": "C113115"},
          {"label": "Hontoria de Cerrato", "value": "C113116"},
          {"label": "Hontoria de la Cantera", "value": "C113117"},
          {"label": "Hontoria de Valdearados", "value": "C113118"},
          {"label": "Hontoria del Pinar", "value": "C113119"},
          {"label": "Horcajo de las Torres", "value": "C113120"},
          {"label": "Horcajo de Montemayor", "value": "C113121"},
          {"label": "Hornillos de Cerrato", "value": "C113122"},
          {"label": "Hornillos del Camino", "value": "C113123"},
          {"label": "Hortiguela", "value": "C113124"},
          {"label": "Hospital de orbigo", "value": "C113125"},
          {"label": "Hoyales de Roa", "value": "C113126"},
          {"label": "Hoyocasero", "value": "C113127"},
          {"label": "Hoyorredondo", "value": "C113128"},
          {"label": "Hoyos de Miguel Munoz", "value": "C113129"},
          {"label": "Hoyos del Collado", "value": "C113130"},
          {"label": "Hoyos del Espino", "value": "C113131"},
          {"label": "Huermeces", "value": "C113132"},
          {"label": "Huerta de Arriba", "value": "C113133"},
          {"label": "Huerta del Rey", "value": "C113134"},
          {"label": "Humada", "value": "C113135"},
          {"label": "Hurones", "value": "C113136"},
          {"label": "Hurtumpascual", "value": "C113137"},
          {"label": "Husillos", "value": "C113138"},
          {"label": "Ibeas de Juarros", "value": "C113139"},
          {"label": "Ibrillos", "value": "C113140"},
          {"label": "Iglesiarrubia", "value": "C113141"},
          {"label": "Iglesias", "value": "C113142"},
          {"label": "Iguena", "value": "C113143"},
          {"label": "Iruelos", "value": "C113144"},
          {"label": "Isar", "value": "C113145"},
          {"label": "Iscar", "value": "C113146"},
          {"label": "Itero de la Vega", "value": "C113147"},
          {"label": "Itero del Castillo", "value": "C113148"},
          {"label": "Ituero de Azaba", "value": "C113149"},
          {"label": "Ituero y Lama", "value": "C113150"},
          {"label": "Izagre", "value": "C113151"},
          {"label": "Jambrina", "value": "C113152"},
          {"label": "Jaramillo de la Fuente", "value": "C113153"},
          {"label": "Jaramillo Quemado", "value": "C113154"},
          {"label": "Joarilla de las Matas", "value": "C113155"},
          {"label": "Juarros de Voltoya", "value": "C113156"},
          {"label": "Junciana", "value": "C113157"},
          {"label": "Justel", "value": "C113158"},
          {"label": "Juzbado", "value": "C113159"},
          {"label": "La Adrada", "value": "C113160"},
          {"label": "La Alberca", "value": "C113161"},
          {"label": "La Baneza", "value": "C113162"},
          {"label": "La Bouza", "value": "C113163"},
          {"label": "La Carrera", "value": "C113164"},
          {"label": "La Cuesta", "value": "C113165"},
          {"label": "La Fuente de San Esteban", "value": "C113166"},
          {"label": "La Lastrilla", "value": "C113167"},
          {"label": "La Pedraja de Portillo", "value": "C113168"},
          {"label": "La Pola de Gordon", "value": "C113169"},
          {"label": "La Robla", "value": "C113170"},
          {"label": "La Seca", "value": "C113171"},
          {"label": "Labajos", "value": "C113172"},
          {"label": "Lagartos", "value": "C113173"},
          {"label": "Laguna Dalga", "value": "C113174"},
          {"label": "Laguna de Contreras", "value": "C113175"},
          {"label": "Laguna de Duero", "value": "C113176"},
          {"label": "Laguna de Negrillos", "value": "C113177"},
          {"label": "Lagunilla", "value": "C113178"},
          {"label": "Langa", "value": "C113179"},
          {"label": "Langa de Duero", "value": "C113180"},
          {"label": "Langayo", "value": "C113181"},
          {"label": "Languilla", "value": "C113182"},
          {"label": "Lantadilla", "value": "C113183"},
          {"label": "Larrodrigo", "value": "C113184"},
          {"label": "Las Navas del Marques", "value": "C113185"},
          {"label": "Lastras de Cuellar", "value": "C113186"},
          {"label": "Lastras del Pozo", "value": "C113187"},
          {"label": "Ledesma", "value": "C113188"},
          {"label": "Ledigos", "value": "C113189"},
          {"label": "Ledrada", "value": "C113190"},
          {"label": "Leon", "value": "C113191"},
          {"label": "Lerma", "value": "C113192"},
          {"label": "Liceras", "value": "C113193"},
          {"label": "Linares de Riofrio", "value": "C113194"},
          {"label": "Llamas de la Ribera", "value": "C113195"},
          {"label": "Llano de Bureba", "value": "C113196"},
          {"label": "Llano de Olmedo", "value": "C113197"},
          {"label": "Lomas", "value": "C113198"},
          {"label": "Losacino", "value": "C113199"},
          {"label": "Losacio", "value": "C113200"},
          {"label": "Lubian", "value": "C113201"},
          {"label": "Luelmo", "value": "C113202"},
          {"label": "Lumbrales", "value": "C113203"},
          {"label": "Luyego", "value": "C113204"},
          {"label": "Machacon", "value": "C113205"},
          {"label": "Macotera", "value": "C113206"},
          {"label": "Maderuelo", "value": "C113207"},
          {"label": "Madridanos", "value": "C113208"},
          {"label": "Madrigal de las Altas Torres", "value": "C113209"},
          {"label": "Madrigal del Monte", "value": "C113210"},
          {"label": "Madrigalejo del Monte", "value": "C113211"},
          {"label": "Madronal", "value": "C113212"},
          {"label": "Maello", "value": "C113213"},
          {"label": "Magana", "value": "C113214"},
          {"label": "Magaz de Cepeda", "value": "C113215"},
          {"label": "Mahamud", "value": "C113216"},
          {"label": "Mahide", "value": "C113217"},
          {"label": "Maire de Castroponce", "value": "C113218"},
          {"label": "Majan", "value": "C113219"},
          {"label": "Malpartida", "value": "C113220"},
          {"label": "Malpartida de Corneja", "value": "C113221"},
          {"label": "Malva", "value": "C113222"},
          {"label": "Mamblas", "value": "C113223"},
          {"label": "Mambrilla de Castrejon", "value": "C113224"},
          {"label": "Mambrillas de Lara", "value": "C113225"},
          {"label": "Mamolar", "value": "C113226"},
          {"label": "Mancera de Abajo", "value": "C113227"},
          {"label": "Mancera de Arriba", "value": "C113228"},
          {"label": "Manciles", "value": "C113229"},
          {"label": "Manganeses de la Lampreana", "value": "C113230"},
          {"label": "Manganeses de la Polvorosa", "value": "C113231"},
          {"label": "Manjabalago", "value": "C113232"},
          {"label": "Manquillos", "value": "C113233"},
          {"label": "Mansilla de las Mulas", "value": "C113234"},
          {"label": "Mansilla Mayor", "value": "C113235"},
          {"label": "Mantinos", "value": "C113236"},
          {"label": "Manzanal de Arriba", "value": "C113237"},
          {"label": "Manzanal de los Infantes", "value": "C113238"},
          {"label": "Manzanal del Barco", "value": "C113239"},
          {"label": "Manzanillo", "value": "C113240"},
          {"label": "Marana", "value": "C113241"},
          {"label": "Marazoleja", "value": "C113242"},
          {"label": "Marazuela", "value": "C113243"},
          {"label": "Marcilla de Campos", "value": "C113244"},
          {"label": "Martiago", "value": "C113245"},
          {"label": "Martiherrero", "value": "C113246"},
          {"label": "Martin de Yeltes", "value": "C113247"},
          {"label": "Martin Miguel", "value": "C113248"},
          {"label": "Martin Munoz de la Dehesa", "value": "C113249"},
          {"label": "Martin Munoz de las Posadas", "value": "C113250"},
          {"label": "Martinamor", "value": "C113251"},
          {"label": "Martinez", "value": "C113252"},
          {"label": "Marugan", "value": "C113253"},
          {"label": "Marzales", "value": "C113254"},
          {"label": "Masueco", "value": "C113255"},
          {"label": "Mata de Cuellar", "value": "C113256"},
          {"label": "Matabuena", "value": "C113257"},
          {"label": "Matadeon de los Oteros", "value": "C113258"},
          {"label": "Matalebreras", "value": "C113259"},
          {"label": "Matallana de Torio", "value": "C113260"},
          {"label": "Matamala de Almazan", "value": "C113261"},
          {"label": "Matapozuelos", "value": "C113262"},
          {"label": "Matilla de Arzon", "value": "C113263"},
          {"label": "Matilla de los Canos", "value": "C113264"},
          {"label": "Matilla de los Canos del Rio", "value": "C113265"},
          {"label": "Matilla la Seca", "value": "C113266"},
          {"label": "Mayalde", "value": "C113267"},
          {"label": "Mayorga", "value": "C113268"},
          {"label": "Mazariegos", "value": "C113269"},
          {"label": "Mazuecos de Valdeginate", "value": "C113270"},
          {"label": "Mazuela", "value": "C113271"},
          {"label": "Mecerreyes", "value": "C113272"},
          {"label": "Mediana de Voltoya", "value": "C113273"},
          {"label": "Medina de Pomar", "value": "C113274"},
          {"label": "Medina de Rioseco", "value": "C113275"},
          {"label": "Medina del Campo", "value": "C113276"},
          {"label": "Medinaceli", "value": "C113277"},
          {"label": "Medinilla", "value": "C113278"},
          {"label": "Megeces", "value": "C113279"},
          {"label": "Melgar de Abajo", "value": "C113280"},
          {"label": "Melgar de Arriba", "value": "C113281"},
          {"label": "Melgar de Fernamental", "value": "C113282"},
          {"label": "Melgar de Tera", "value": "C113283"},
          {"label": "Melgar de Yuso", "value": "C113284"},
          {"label": "Membibre de la Hoz", "value": "C113285"},
          {"label": "Membribe de la Sierra", "value": "C113286"},
          {"label": "Meneses de Campos", "value": "C113287"},
          {"label": "Mengamunoz", "value": "C113288"},
          {"label": "Mesegar de Corneja", "value": "C113289"},
          {"label": "Micereces de Tera", "value": "C113290"},
          {"label": "Micieces de Ojeda", "value": "C113291"},
          {"label": "Mieza", "value": "C113292"},
          {"label": "Miguelanez", "value": "C113293"},
          {"label": "Mijares", "value": "C113294"},
          {"label": "Milagros", "value": "C113295"},
          {"label": "Milles de la Polvorosa", "value": "C113296"},
          {"label": "Mingorria", "value": "C113297"},
          {"label": "Mino de San Esteban", "value": "C113298"},
          {"label": "Miranda de Azan", "value": "C113299"},
          {"label": "Miranda de Ebro", "value": "C113300"},
          {"label": "Miranda del Castanar", "value": "C113301"},
          {"label": "Miraveche", "value": "C113302"},
          {"label": "Mironcillo", "value": "C113303"},
          {"label": "Modubar de la Emparedada", "value": "C113304"},
          {"label": "Mogarraz", "value": "C113305"},
          {"label": "Mojados", "value": "C113306"},
          {"label": "Molacillos", "value": "C113307"},
          {"label": "Molezuelas de la Carballeda", "value": "C113308"},
          {"label": "Molinaseca", "value": "C113309"},
          {"label": "Molinillo", "value": "C113310"},
          {"label": "Molinos de Duero", "value": "C113311"},
          {"label": "Mombeltran", "value": "C113312"},
          {"label": "Momblona", "value": "C113313"},
          {"label": "Mombuey", "value": "C113314"},
          {"label": "Monasterio de la Sierra", "value": "C113315"},
          {"label": "Monasterio de Rodilla", "value": "C113316"},
          {"label": "Monasterio de Vega", "value": "C113317"},
          {"label": "Moncalvillo", "value": "C113318"},
          {"label": "Monfarracinos", "value": "C113319"},
          {"label": "Monforte de la Sierra", "value": "C113320"},
          {"label": "Monleon", "value": "C113321"},
          {"label": "Monleras", "value": "C113322"},
          {"label": "Monsagro", "value": "C113323"},
          {"label": "Monsalupe", "value": "C113324"},
          {"label": "Montamarta", "value": "C113325"},
          {"label": "Monteagudo de las Vicarias", "value": "C113326"},
          {"label": "Montejo", "value": "C113327"},
          {"label": "Montejo de Arevalo", "value": "C113328"},
          {"label": "Montejo de la Vega de la Serrezuela", "value": "C113329"},
          {"label": "Montejo de Tiermes", "value": "C113330"},
          {"label": "Montemayor de Pililla", "value": "C113331"},
          {"label": "Montemayor del Rio", "value": "C113332"},
          {"label": "Montenegro de Cameros", "value": "C113333"},
          {"label": "Monterrubio", "value": "C113334"},
          {"label": "Monterrubio de Armuna", "value": "C113335"},
          {"label": "Monterrubio de la Sierra", "value": "C113336"},
          {"label": "Montorio", "value": "C113337"},
          {"label": "Monzon de Campos", "value": "C113338"},
          {"label": "Moradillo de Roa", "value": "C113339"},
          {"label": "Moral de la Reina", "value": "C113340"},
          {"label": "Moral de Sayago", "value": "C113341"},
          {"label": "Moraleja de las Panaderas", "value": "C113342"},
          {"label": "Moraleja de Matacabras", "value": "C113343"},
          {"label": "Moraleja de Sayago", "value": "C113344"},
          {"label": "Moraleja del Vino", "value": "C113345"},
          {"label": "Morales de Campos", "value": "C113346"},
          {"label": "Morales de Toro", "value": "C113347"},
          {"label": "Morales de Valverde", "value": "C113348"},
          {"label": "Morales del Vino", "value": "C113349"},
          {"label": "Moralina", "value": "C113350"},
          {"label": "Morasverdes", "value": "C113351"},
          {"label": "Moratinos", "value": "C113352"},
          {"label": "Moreruela de los Infanzones", "value": "C113353"},
          {"label": "Moreruela de Tabara", "value": "C113354"},
          {"label": "Morille", "value": "C113355"},
          {"label": "Moriscos", "value": "C113356"},
          {"label": "Moron de Almazan", "value": "C113357"},
          {"label": "Moronta", "value": "C113358"},
          {"label": "Mota del Marques", "value": "C113359"},
          {"label": "Mozarbez", "value": "C113360"},
          {"label": "Mozoncillo", "value": "C113361"},
          {"label": "Mucientes", "value": "C113362"},
          {"label": "Muda", "value": "C113363"},
          {"label": "Muelas de los Caballeros", "value": "C113364"},
          {"label": "Muga de Sayago", "value": "C113365"},
          {"label": "Munana", "value": "C113366"},
          {"label": "Munico", "value": "C113367"},
          {"label": "Munogalindo", "value": "C113368"},
          {"label": "Munogrande", "value": "C113369"},
          {"label": "Munomer del Peco", "value": "C113370"},
          {"label": "Munopedro", "value": "C113371"},
          {"label": "Munopepe", "value": "C113372"},
          {"label": "Munosancho", "value": "C113373"},
          {"label": "Munotello", "value": "C113374"},
          {"label": "Munoveros", "value": "C113375"},
          {"label": "Murias de Paredes", "value": "C113376"},
          {"label": "Muriel de la Fuente", "value": "C113377"},
          {"label": "Muriel Viejo", "value": "C113378"},
          {"label": "Nafria de Ucero", "value": "C113379"},
          {"label": "Narrillos del alamo", "value": "C113380"},
          {"label": "Narrillos del Rebollar", "value": "C113381"},
          {"label": "Narros", "value": "C113382"},
          {"label": "Narros de Matalayegua", "value": "C113383"},
          {"label": "Narros de Salduena", "value": "C113384"},
          {"label": "Narros del Castillo", "value": "C113385"},
          {"label": "Narros del Puerto", "value": "C113386"},
          {"label": "Nava de Arevalo", "value": "C113387"},
          {"label": "Nava de Bejar", "value": "C113388"},
          {"label": "Nava de Francia", "value": "C113389"},
          {"label": "Nava de la Asuncion", "value": "C113390"},
          {"label": "Nava de Roa", "value": "C113391"},
          {"label": "Nava de Sotrobal", "value": "C113392"},
          {"label": "Nava del Barco", "value": "C113393"},
          {"label": "Nava del Rey", "value": "C113394"},
          {"label": "Navacarros", "value": "C113395"},
          {"label": "Navacepedilla de Corneja", "value": "C113396"},
          {"label": "Navadijos", "value": "C113397"},
          {"label": "Navaescurial", "value": "C113398"},
          {"label": "Navafria", "value": "C113399"},
          {"label": "Navahondilla", "value": "C113400"},
          {"label": "Navalacruz", "value": "C113401"},
          {"label": "Navaleno", "value": "C113402"},
          {"label": "Navales", "value": "C113403"},
          {"label": "Navalilla", "value": "C113404"},
          {"label": "Navalmanzano", "value": "C113405"},
          {"label": "Navalmoral", "value": "C113406"},
          {"label": "Navalmoral de Bejar", "value": "C113407"},
          {"label": "Navalosa", "value": "C113408"},
          {"label": "Navalperal de Pinares", "value": "C113409"},
          {"label": "Navalperal de Tormes", "value": "C113410"},
          {"label": "Navaluenga", "value": "C113411"},
          {"label": "Navamorales", "value": "C113412"},
          {"label": "Navaquesera", "value": "C113413"},
          {"label": "Navares de Ayuso", "value": "C113414"},
          {"label": "Navares de Enmedio", "value": "C113415"},
          {"label": "Navares de las Cuevas", "value": "C113416"},
          {"label": "Navarredonda de Gredos", "value": "C113417"},
          {"label": "Navarredonda de la Rinconada", "value": "C113418"},
          {"label": "Navarredondilla", "value": "C113419"},
          {"label": "Navarrevisca", "value": "C113420"},
          {"label": "Navas de Bureba", "value": "C113421"},
          {"label": "Navas de Oro", "value": "C113422"},
          {"label": "Navasfrias", "value": "C113423"},
          {"label": "Navatalgordo", "value": "C113424"},
          {"label": "Navatejares", "value": "C113425"},
          {"label": "Navianos de Valverde", "value": "C113426"},
          {"label": "Nebreda", "value": "C113427"},
          {"label": "Negrilla de Palencia", "value": "C113428"},
          {"label": "Neila", "value": "C113429"},
          {"label": "Neila de San Miguel", "value": "C113430"},
          {"label": "Nepas", "value": "C113431"},
          {"label": "Nieva", "value": "C113432"},
          {"label": "Niharra", "value": "C113433"},
          {"label": "Nogal de las Huertas", "value": "C113434"},
          {"label": "Nolay", "value": "C113435"},
          {"label": "Noviercas", "value": "C113436"},
          {"label": "Nueva Villa de las Torres", "value": "C113437"},
          {"label": "Oencia", "value": "C113438"},
          {"label": "Ojos-Albos", "value": "C113439"},
          {"label": "Olea de Boedo", "value": "C113440"},
          {"label": "Olivares de Duero", "value": "C113441"},
          {"label": "Olmedillo de Roa", "value": "C113442"},
          {"label": "Olmedo", "value": "C113443"},
          {"label": "Olmedo de Camaces", "value": "C113444"},
          {"label": "Olmillos de Castro", "value": "C113445"},
          {"label": "Olmillos de Muno", "value": "C113446"},
          {"label": "Olmos de Esgueva", "value": "C113447"},
          {"label": "Olmos de Ojeda", "value": "C113448"},
          {"label": "Olmos de Penafiel", "value": "C113449"},
          {"label": "Olombrada", "value": "C113450"},
          {"label": "Olvega", "value": "C113451"},
          {"label": "Ona", "value": "C113452"},
          {"label": "Oncala", "value": "C113453"},
          {"label": "Onzonilla", "value": "C113454"},
          {"label": "Oquillas", "value": "C113455"},
          {"label": "Orbita", "value": "C113456"},
          {"label": "Ortigosa de Pestano", "value": "C113457"},
          {"label": "Ortigosa del Monte", "value": "C113458"},
          {"label": "Oseja de Sajambre", "value": "C113459"},
          {"label": "Osornillo", "value": "C113460"},
          {"label": "Otero de Bodas", "value": "C113461"},
          {"label": "Otero de Herreros", "value": "C113462"},
          {"label": "Padiernos", "value": "C113463"},
          {"label": "Padilla de Abajo", "value": "C113464"},
          {"label": "Padilla de Arriba", "value": "C113465"},
          {"label": "Padrones de Bureba", "value": "C113466"},
          {"label": "Pajarejos", "value": "C113467"},
          {"label": "Pajares de Adaja", "value": "C113468"},
          {"label": "Pajares de la Laguna", "value": "C113469"},
          {"label": "Pajares de la Lampreana", "value": "C113470"},
          {"label": "Pajares de los Oteros", "value": "C113471"},
          {"label": "Palacios de Goda", "value": "C113472"},
          {"label": "Palacios de la Sierra", "value": "C113473"},
          {"label": "Palacios de la Valduerna", "value": "C113474"},
          {"label": "Palacios de Sanabria", "value": "C113475"},
          {"label": "Palacios del Arzobispo", "value": "C113476"},
          {"label": "Palacios del Pan", "value": "C113477"},
          {"label": "Palacios del Sil", "value": "C113478"},
          {"label": "Palaciosrubios", "value": "C113479"},
          {"label": "Palazuelo de Vedija", "value": "C113480"},
          {"label": "Palazuelos de Eresma", "value": "C113481"},
          {"label": "Palazuelos de la Sierra", "value": "C113482"},
          {"label": "Palazuelos de Muno", "value": "C113483"},
          {"label": "Palencia", "value": "C113484"},
          {"label": "Palencia de Negrilla", "value": "C113485"},
          {"label": "Palenzuela", "value": "C113486"},
          {"label": "Pampliega", "value": "C113487"},
          {"label": "Pancorbo", "value": "C113488"},
          {"label": "Papatrigo", "value": "C113489"},
          {"label": "Parada de Arriba", "value": "C113490"},
          {"label": "Parada de Rubiales", "value": "C113491"},
          {"label": "Paradinas de San Juan", "value": "C113492"},
          {"label": "Paramo de Boedo", "value": "C113493"},
          {"label": "Paramo del Sil", "value": "C113494"},
          {"label": "Pardilla", "value": "C113495"},
          {"label": "Paredes de Nava", "value": "C113496"},
          {"label": "Pastores", "value": "C113497"},
          {"label": "Payo de Ojeda", "value": "C113498"},
          {"label": "Pedrajas de San Esteban", "value": "C113499"},
          {"label": "Pedraza", "value": "C113500"},
          {"label": "Pedraza de Alba", "value": "C113501"},
          {"label": "Pedraza de Campos", "value": "C113502"},
          {"label": "Pedro Bernardo", "value": "C113503"},
          {"label": "Pedrosa de Duero", "value": "C113504"},
          {"label": "Pedrosa de la Vega", "value": "C113505"},
          {"label": "Pedrosa del Paramo", "value": "C113506"},
          {"label": "Pedrosa del Principe", "value": "C113507"},
          {"label": "Pedrosa del Rey", "value": "C113508"},
          {"label": "Pedrosillo de Alba", "value": "C113509"},
          {"label": "Pedrosillo de los Aires", "value": "C113510"},
          {"label": "Pedrosillo el Ralo", "value": "C113511"},
          {"label": "Peguerinos", "value": "C113512"},
          {"label": "Pelabravo", "value": "C113513"},
          {"label": "Pelarrodriguez", "value": "C113514"},
          {"label": "Pelayos", "value": "C113515"},
          {"label": "Pelayos del Arroyo", "value": "C113516"},
          {"label": "Peleagonzalo", "value": "C113517"},
          {"label": "Peleas de Abajo", "value": "C113518"},
          {"label": "Penacaballera", "value": "C113519"},
          {"label": "Penafiel", "value": "C113520"},
          {"label": "Penaflor de Hornija", "value": "C113521"},
          {"label": "Penalba de avila", "value": "C113522"},
          {"label": "Penaparda", "value": "C113523"},
          {"label": "Penaranda de Bracamonte", "value": "C113524"},
          {"label": "Penaranda de Duero", "value": "C113525"},
          {"label": "Penarandilla", "value": "C113526"},
          {"label": "Penausende", "value": "C113527"},
          {"label": "Peque", "value": "C113528"},
          {"label": "Peral de Arlanza", "value": "C113529"},
          {"label": "Peralejos de Abajo", "value": "C113530"},
          {"label": "Peralejos de Arriba", "value": "C113531"},
          {"label": "Perales", "value": "C113532"},
          {"label": "Peranzanes", "value": "C113533"},
          {"label": "Pereruela", "value": "C113534"},
          {"label": "Perilla de Castro", "value": "C113535"},
          {"label": "Peromingo", "value": "C113536"},
          {"label": "Perosillo", "value": "C113537"},
          {"label": "Pias", "value": "C113538"},
          {"label": "Piedrahita de Castro", "value": "C113539"},
          {"label": "Piedralaves", "value": "C113540"},
          {"label": "Piernigas", "value": "C113541"},
          {"label": "Pina de Campos", "value": "C113542"},
          {"label": "Pina de Esgueva", "value": "C113543"},
          {"label": "Pinarejos", "value": "C113544"},
          {"label": "Pinarnegrillo", "value": "C113545"},
          {"label": "Pineda de la Sierra", "value": "C113546"},
          {"label": "Pinedas", "value": "C113547"},
          {"label": "Pinel de Abajo", "value": "C113548"},
          {"label": "Pinel de Arriba", "value": "C113549"},
          {"label": "Pinilla de los Barruecos", "value": "C113550"},
          {"label": "Pinilla de los Moros", "value": "C113551"},
          {"label": "Pinilla de Toro", "value": "C113552"},
          {"label": "Pinilla del Campo", "value": "C113553"},
          {"label": "Pino del Rio", "value": "C113554"},
          {"label": "Pitiegua", "value": "C113555"},
          {"label": "Pizarral", "value": "C113556"},
          {"label": "Poblacion de Arroyo", "value": "C113557"},
          {"label": "Poblacion de Campos", "value": "C113558"},
          {"label": "Poblacion de Cerrato", "value": "C113559"},
          {"label": "Pobladura de Pelayo Garcia", "value": "C113560"},
          {"label": "Pobladura de Valderaduey", "value": "C113561"},
          {"label": "Pobladura del Valle", "value": "C113562"},
          {"label": "Polentinos", "value": "C113563"},
          {"label": "Pollos", "value": "C113564"},
          {"label": "Pomar de Valdivia", "value": "C113565"},
          {"label": "Ponferrada", "value": "C113566"},
          {"label": "Portillo", "value": "C113567"},
          {"label": "Portillo de Soria", "value": "C113568"},
          {"label": "Porto", "value": "C113569"},
          {"label": "Posada de Valdeon", "value": "C113570"},
          {"label": "Poveda", "value": "C113571"},
          {"label": "Poveda de las Cintas", "value": "C113572"},
          {"label": "Poyales del Hoyo", "value": "C113573"},
          {"label": "Poza de la Sal", "value": "C113574"},
          {"label": "Poza de la Vega", "value": "C113575"},
          {"label": "Pozal de Gallinas", "value": "C113576"},
          {"label": "Pozalmuro", "value": "C113577"},
          {"label": "Pozanco", "value": "C113578"},
          {"label": "Pozo de Urama", "value": "C113579"},
          {"label": "Pozoantiguo", "value": "C113580"},
          {"label": "Pozos de Hinojo", "value": "C113581"},
          {"label": "Pozuelo de la Orden", "value": "C113582"},
          {"label": "Pozuelo de Tabara", "value": "C113583"},
          {"label": "Pozuelo del Paramo", "value": "C113584"},
          {"label": "Pradales", "value": "C113585"},
          {"label": "Pradanos de Bureba", "value": "C113586"},
          {"label": "Pradanos de Ojeda", "value": "C113587"},
          {"label": "Pradena", "value": "C113588"},
          {"label": "Prado", "value": "C113589"},
          {"label": "Prado de la Guzpena", "value": "C113590"},
          {"label": "Pradoluengo", "value": "C113591"},
          {"label": "Pradosegar", "value": "C113592"},
          {"label": "Presencio", "value": "C113593"},
          {"label": "Priaranza del Bierzo", "value": "C113594"},
          {"label": "Prioro", "value": "C113595"},
          {"label": "Provincia de avila", "value": "C113596"},
          {"label": "Provincia de Burgos", "value": "C113597"},
          {"label": "Provincia de Leon", "value": "C113598"},
          {"label": "Provincia de Palencia", "value": "C113599"},
          {"label": "Provincia de Salamanca", "value": "C113600"},
          {"label": "Provincia de Segovia", "value": "C113601"},
          {"label": "Provincia de Soria", "value": "C113602"},
          {"label": "Provincia de Valladolid", "value": "C113603"},
          {"label": "Provincia de Zamora", "value": "C113604"},
          {"label": "Puebla de Azaba", "value": "C113605"},
          {"label": "Puebla de Lillo", "value": "C113606"},
          {"label": "Puebla de Pedraza", "value": "C113607"},
          {"label": "Puebla de San Medel", "value": "C113608"},
          {"label": "Puebla de Sanabria", "value": "C113609"},
          {"label": "Puebla de Yeltes", "value": "C113610"},
          {"label": "Pueblica de Valverde", "value": "C113611"},
          {"label": "Puente de Domingo Florez", "value": "C113612"},
          {"label": "Puente del Congosto", "value": "C113613"},
          {"label": "Puentedura", "value": "C113614"},
          {"label": "Puertas", "value": "C113615"},
          {"label": "Puerto Castilla", "value": "C113616"},
          {"label": "Puerto de Bejar", "value": "C113617"},
          {"label": "Puerto Seguro", "value": "C113618"},
          {"label": "Puras", "value": "C113619"},
          {"label": "Quemada", "value": "C113620"},
          {"label": "Quintana del Castillo", "value": "C113621"},
          {"label": "Quintana del Marco", "value": "C113622"},
          {"label": "Quintana del Pidio", "value": "C113623"},
          {"label": "Quintana del Puente", "value": "C113624"},
          {"label": "Quintana Redonda", "value": "C113625"},
          {"label": "Quintana y Congosto", "value": "C113626"},
          {"label": "Quintanabureba", "value": "C113627"},
          {"label": "Quintanaelez", "value": "C113628"},
          {"label": "Quintanaortuno", "value": "C113629"},
          {"label": "Quintanapalla", "value": "C113630"},
          {"label": "Quintanar de la Sierra", "value": "C113631"},
          {"label": "Quintanas de Gormaz", "value": "C113632"},
          {"label": "Quintanavides", "value": "C113633"},
          {"label": "Quintanilla de Arriba", "value": "C113634"},
          {"label": "Quintanilla de la Mata", "value": "C113635"},
          {"label": "Quintanilla de Onesimo", "value": "C113636"},
          {"label": "Quintanilla de Onsona", "value": "C113637"},
          {"label": "Quintanilla de Trigueros", "value": "C113638"},
          {"label": "Quintanilla de Urz", "value": "C113639"},
          {"label": "Quintanilla del Coco", "value": "C113640"},
          {"label": "Quintanilla del Molar", "value": "C113641"},
          {"label": "Quintanilla del Monte", "value": "C113642"},
          {"label": "Quintanilla del Olmo", "value": "C113643"},
          {"label": "Quiruelas de Vidriales", "value": "C113644"},
          {"label": "Rabanales", "value": "C113645"},
          {"label": "Rabanera del Pinar", "value": "C113646"},
          {"label": "Rabano", "value": "C113647"},
          {"label": "Rabano de Aliste", "value": "C113648"},
          {"label": "Rabanos", "value": "C113649"},
          {"label": "Rabe de las Calzadas", "value": "C113650"},
          {"label": "Ragama", "value": "C113651"},
          {"label": "Ramiro", "value": "C113652"},
          {"label": "Rapariegos", "value": "C113653"},
          {"label": "Rasueros", "value": "C113654"},
          {"label": "Rebolledo de la Torre", "value": "C113655"},
          {"label": "Rebollo", "value": "C113656"},
          {"label": "Recuerda", "value": "C113657"},
          {"label": "Redecilla del Camino", "value": "C113658"},
          {"label": "Redecilla del Campo", "value": "C113659"},
          {"label": "Regueras de Arriba", "value": "C113660"},
          {"label": "Regumiel de la Sierra", "value": "C113661"},
          {"label": "Reinoso", "value": "C113662"},
          {"label": "Reinoso de Cerrato", "value": "C113663"},
          {"label": "Rello", "value": "C113664"},
          {"label": "Remondo", "value": "C113665"},
          {"label": "Renedo de la Vega", "value": "C113666"},
          {"label": "Renieblas", "value": "C113667"},
          {"label": "Requena de Campos", "value": "C113668"},
          {"label": "Respenda de la Pena", "value": "C113669"},
          {"label": "Retortillo", "value": "C113670"},
          {"label": "Retortillo de Soria", "value": "C113671"},
          {"label": "Retuerta", "value": "C113672"},
          {"label": "Revellinos", "value": "C113673"},
          {"label": "Revenga de Campos", "value": "C113674"},
          {"label": "Revilla de Collazos", "value": "C113675"},
          {"label": "Revilla del Campo", "value": "C113676"},
          {"label": "Revillarruz", "value": "C113677"},
          {"label": "Reyero", "value": "C113678"},
          {"label": "Rezmondo", "value": "C113679"},
          {"label": "Reznos", "value": "C113680"},
          {"label": "Riaguas de San Bartolome", "value": "C113681"},
          {"label": "Riano", "value": "C113682"},
          {"label": "Riaza", "value": "C113683"},
          {"label": "Ribas de Campos", "value": "C113684"},
          {"label": "Riberos de la Cueza", "value": "C113685"},
          {"label": "Ribota", "value": "C113686"},
          {"label": "Riego de la Vega", "value": "C113687"},
          {"label": "Riocavado de la Sierra", "value": "C113688"},
          {"label": "Riofrio de Aliste", "value": "C113689"},
          {"label": "Rivilla de Barajas", "value": "C113690"},
          {"label": "Roa", "value": "C113691"},
          {"label": "Roales", "value": "C113692"},
          {"label": "Robladillo", "value": "C113693"},
          {"label": "Robleda", "value": "C113694"},
          {"label": "Robleda-Cervantes", "value": "C113695"},
          {"label": "Robliza de Cojos", "value": "C113696"},
          {"label": "Roda de Eresma", "value": "C113697"},
          {"label": "Rojas", "value": "C113698"},
          {"label": "Rollamienta", "value": "C113699"},
          {"label": "Rollan", "value": "C113700"},
          {"label": "Roperuelos del Paramo", "value": "C113701"},
          {"label": "Roturas", "value": "C113702"},
          {"label": "Royuela de Rio Franco", "value": "C113703"},
          {"label": "Rubena", "value": "C113704"},
          {"label": "Rubi de Bracamonte", "value": "C113705"},
          {"label": "Rublacedo de Abajo", "value": "C113706"},
          {"label": "Rucandio", "value": "C113707"},
          {"label": "Rueda", "value": "C113708"},
          {"label": "Ruesga", "value": "C113709"},
          {"label": "Sabero", "value": "C113710"},
          {"label": "Sacramenia", "value": "C113711"},
          {"label": "Saelices de Mayorga", "value": "C113712"},
          {"label": "Saelices el Chico", "value": "C113713"},
          {"label": "Sahagun", "value": "C113714"},
          {"label": "Salamanca", "value": "C113715"},
          {"label": "Salas de Bureba", "value": "C113716"},
          {"label": "Salas de los Infantes", "value": "C113717"},
          {"label": "Salce", "value": "C113718"},
          {"label": "Saldana", "value": "C113719"},
          {"label": "Saldana de Burgos", "value": "C113720"},
          {"label": "Saldeana", "value": "C113721"},
          {"label": "Salduero", "value": "C113722"},
          {"label": "Salinas de Pisuerga", "value": "C113723"},
          {"label": "Salinillas de Bureba", "value": "C113724"},
          {"label": "Salmoral", "value": "C113725"},
          {"label": "Salobral", "value": "C113726"},
          {"label": "Salvadios", "value": "C113727"},
          {"label": "Salvador de Zapardiel", "value": "C113728"},
          {"label": "Salvatierra de Tormes", "value": "C113729"},
          {"label": "Samboal", "value": "C113730"},
          {"label": "Samir de los Canos", "value": "C113731"},
          {"label": "San Adrian de Juarros", "value": "C113732"},
          {"label": "San Adrian del Valle", "value": "C113733"},
          {"label": "San Agustin del Pozo", "value": "C113734"},
          {"label": "San Andres del Rabanedo", "value": "C113735"},
          {"label": "San Bartolome de Bejar", "value": "C113736"},
          {"label": "San Bartolome de Corneja", "value": "C113737"},
          {"label": "San Cebrian de Campos", "value": "C113738"},
          {"label": "San Cebrian de Castro", "value": "C113739"},
          {"label": "San Cebrian de Mazote", "value": "C113740"},
          {"label": "San Cebrian de Muda", "value": "C113741"},
          {"label": "San Cristobal de Boedo", "value": "C113742"},
          {"label": "San Cristobal de Cuellar", "value": "C113743"},
          {"label": "San Cristobal de Entrevinas", "value": "C113744"},
          {"label": "San Cristobal de la Cuesta", "value": "C113745"},
          {"label": "San Cristobal de la Polantera", "value": "C113746"},
          {"label": "San Cristobal de la Vega", "value": "C113747"},
          {"label": "San Cristobal de Segovia", "value": "C113748"},
          {"label": "San Emiliano", "value": "C113749"},
          {"label": "San Esteban de Gormaz", "value": "C113750"},
          {"label": "San Esteban de la Sierra", "value": "C113751"},
          {"label": "San Esteban de los Patos", "value": "C113752"},
          {"label": "San Esteban de Nogales", "value": "C113753"},
          {"label": "San Esteban del Molar", "value": "C113754"},
          {"label": "San Esteban del Valle", "value": "C113755"},
          {"label": "San Felices de los Gallegos", "value": "C113756"},
          {"label": "San Garcia de Ingelmos", "value": "C113757"},
          {"label": "San Ildefonso", "value": "C113758"},
          {"label": "San Juan de la Encinilla", "value": "C113759"},
          {"label": "San Juan de la Nava", "value": "C113760"},
          {"label": "San Juan del Molinillo", "value": "C113761"},
          {"label": "San Juan del Monte", "value": "C113762"},
          {"label": "San Justo", "value": "C113763"},
          {"label": "San Justo de la Vega", "value": "C113764"},
          {"label": "San Leonardo de Yague", "value": "C113765"},
          {"label": "San Llorente", "value": "C113766"},
          {"label": "San Lorenzo de Tormes", "value": "C113767"},
          {"label": "San Mames de Burgos", "value": "C113768"},
          {"label": "San Mames de Campos", "value": "C113769"},
          {"label": "San Martin de la Vega del Alberche", "value": "C113770"},
          {"label": "San Martin de Rubiales", "value": "C113771"},
          {"label": "San Martin de Valderaduey", "value": "C113772"},
          {"label": "San Martin de Valveni", "value": "C113773"},
          {"label": "San Martin del Castanar", "value": "C113774"},
          {"label": "San Martin del Pimpollar", "value": "C113775"},
          {"label": "San Miguel de Corneja", "value": "C113776"},
          {"label": "San Miguel de la Ribera", "value": "C113777"},
          {"label": "San Miguel de Serrezuela", "value": "C113778"},
          {"label": "San Miguel de Valero", "value": "C113779"},
          {"label": "San Miguel del Arroyo", "value": "C113780"},
          {"label": "San Miguel del Pino", "value": "C113781"},
          {"label": "San Millan de Lara", "value": "C113782"},
          {"label": "San Millan de los Caballeros", "value": "C113783"},
          {"label": "San Morales", "value": "C113784"},
          {"label": "San Munoz", "value": "C113785"},
          {"label": "San Pablo de la Moraleja", "value": "C113786"},
          {"label": "San Pascual", "value": "C113787"},
          {"label": "San Pedro Bercianos", "value": "C113788"},
          {"label": "San Pedro de Ceque", "value": "C113789"},
          {"label": "San Pedro de Gaillos", "value": "C113790"},
          {"label": "San Pedro de Latarce", "value": "C113791"},
          {"label": "San Pedro de Rozados", "value": "C113792"},
          {"label": "San Pedro del Arroyo", "value": "C113793"},
          {"label": "San Pedro del Valle", "value": "C113794"},
          {"label": "San Pedro Manrique", "value": "C113795"},
          {"label": "San Pelayo", "value": "C113796"},
          {"label": "San Pelayo de Guarena", "value": "C113797"},
          {"label": "San Roman de Hornija", "value": "C113798"},
          {"label": "San Roman de la Cuba", "value": "C113799"},
          {"label": "San Vicente de Arevalo", "value": "C113800"},
          {"label": "San Vicente de la Cabeza", "value": "C113801"},
          {"label": "San Vicente del Palacio", "value": "C113802"},
          {"label": "San Vicente del Valle", "value": "C113803"},
          {"label": "San Vitero", "value": "C113804"},
          {"label": "Sancedo", "value": "C113805"},
          {"label": "Sanchidrian", "value": "C113806"},
          {"label": "Sanchon de la Ribera", "value": "C113807"},
          {"label": "Sanchon de la Sagrada", "value": "C113808"},
          {"label": "Sanchonuno", "value": "C113809"},
          {"label": "Sanchorreja", "value": "C113810"},
          {"label": "Sanchotello", "value": "C113811"},
          {"label": "Sando", "value": "C113812"},
          {"label": "Sangarcia", "value": "C113813"},
          {"label": "Santa Cecilia", "value": "C113814"},
          {"label": "Santa Cecilia del Alcor", "value": "C113815"},
          {"label": "Santa Clara de Avedillo", "value": "C113816"},
          {"label": "Santa Colomba de Curueno", "value": "C113817"},
          {"label": "Santa Colomba de las Monjas", "value": "C113818"},
          {"label": "Santa Colomba de Somoza", "value": "C113819"},
          {"label": "Santa Cristina de la Polvorosa", "value": "C113820"},
          {"label": "Santa Cristina de Valmadrigal", "value": "C113821"},
          {"label": "Santa Croya de Tera", "value": "C113822"},
          {"label": "Santa Cruz de Boedo", "value": "C113823"},
          {"label": "Santa Cruz de la Salceda", "value": "C113824"},
          {"label": "Santa Cruz de Pinares", "value": "C113825"},
          {"label": "Santa Cruz de Yanguas", "value": "C113826"},
          {"label": "Santa Cruz del Valle", "value": "C113827"},
          {"label": "Santa Cruz del Valle Urbion", "value": "C113828"},
          {"label": "Santa Elena de Jamuz", "value": "C113829"},
          {"label": "Santa Eufemia del Arroyo", "value": "C113830"},
          {"label": "Santa Eufemia del Barco", "value": "C113831"},
          {"label": "Santa Gadea del Cid", "value": "C113832"},
          {"label": "Santa Ines", "value": "C113833"},
          {"label": "Santa Maria de Huerta", "value": "C113834"},
          {"label": "Santa Maria de la Isla", "value": "C113835"},
          {"label": "Santa Maria de la Vega", "value": "C113836"},
          {"label": "Santa Maria de las Hoyas", "value": "C113837"},
          {"label": "Santa Maria de los Caballeros", "value": "C113838"},
          {"label": "Santa Maria de Ordas", "value": "C113839"},
          {"label": "Santa Maria de Sando", "value": "C113840"},
          {"label": "Santa Maria de Valverde", "value": "C113841"},
          {"label": "Santa Maria del Berrocal", "value": "C113842"},
          {"label": "Santa Maria del Campo", "value": "C113843"},
          {"label": "Santa Maria del Invierno", "value": "C113844"},
          {"label": "Santa Maria del Monte de Cea", "value": "C113845"},
          {"label": "Santa Maria del Paramo", "value": "C113846"},
          {"label": "Santa Maria la Real de Nieva", "value": "C113847"},
          {"label": "Santa Marina del Rey", "value": "C113848"},
          {"label": "Santa Marta de Tormes", "value": "C113849"},
          {"label": "Santa Marta del Cerro", "value": "C113850"},
          {"label": "Santa Olalla de Bureba", "value": "C113851"},
          {"label": "Santas Martas", "value": "C113852"},
          {"label": "Santervas de Campos", "value": "C113853"},
          {"label": "Santervas de la Vega", "value": "C113854"},
          {"label": "Santiago de la Puebla", "value": "C113855"},
          {"label": "Santiago del Collado", "value": "C113856"},
          {"label": "Santiago Millas", "value": "C113857"},
          {"label": "Santibanez de Bejar", "value": "C113858"},
          {"label": "Santibanez de Ecla", "value": "C113859"},
          {"label": "Santibanez de la Pena", "value": "C113860"},
          {"label": "Santibanez de la Sierra", "value": "C113861"},
          {"label": "Santibanez de Tera", "value": "C113862"},
          {"label": "Santibanez de Valcorba", "value": "C113863"},
          {"label": "Santibanez de Vidriales", "value": "C113864"},
          {"label": "Santibanez del Val", "value": "C113865"},
          {"label": "Santiuste de San Juan Bautista", "value": "C113866"},
          {"label": "Santiz", "value": "C113867"},
          {"label": "Santo Domingo de las Posadas", "value": "C113868"},
          {"label": "Santo Domingo de Piron", "value": "C113869"},
          {"label": "Santo Domingo de Silos", "value": "C113870"},
          {"label": "Santo Tome de Zabarcos", "value": "C113871"},
          {"label": "Santovenia", "value": "C113872"},
          {"label": "Santovenia de Pisuerga", "value": "C113873"},
          {"label": "Santoyo", "value": "C113874"},
          {"label": "Sanzoles", "value": "C113875"},
          {"label": "Sardon de Duero", "value": "C113876"},
          {"label": "Sardon de los Frailes", "value": "C113877"},
          {"label": "Sargentes de la Lora", "value": "C113878"},
          {"label": "Sariegos", "value": "C113879"},
          {"label": "Sarracin", "value": "C113880"},
          {"label": "Sasamon", "value": "C113881"},
          {"label": "Saucelle", "value": "C113882"},
          {"label": "Sauquillo de Cabezas", "value": "C113883"},
          {"label": "Sebulcor", "value": "C113884"},
          {"label": "Segovia", "value": "C113885"},
          {"label": "Sena de Luna", "value": "C113886"},
          {"label": "Sepulveda", "value": "C113887"},
          {"label": "Sequera de Fresno", "value": "C113888"},
          {"label": "Sequeros", "value": "C113889"},
          {"label": "Serrada", "value": "C113890"},
          {"label": "Serradilla del Arroyo", "value": "C113891"},
          {"label": "Serradilla del Llano", "value": "C113892"},
          {"label": "Serranillos", "value": "C113893"},
          {"label": "Siete Iglesias de Trabancos", "value": "C113894"},
          {"label": "Sigeres", "value": "C113895"},
          {"label": "Simancas", "value": "C113896"},
          {"label": "Sinlabajos", "value": "C113897"},
          {"label": "Sobradillo", "value": "C113898"},
          {"label": "Sobrado", "value": "C113899"},
          {"label": "Solarana", "value": "C113900"},
          {"label": "Soliedra", "value": "C113901"},
          {"label": "Solosancho", "value": "C113902"},
          {"label": "Sordillos", "value": "C113903"},
          {"label": "Soria", "value": "C113904"},
          {"label": "Sorihuela", "value": "C113905"},
          {"label": "Sotalbo", "value": "C113906"},
          {"label": "Sotillo", "value": "C113907"},
          {"label": "Sotillo de la Adrada", "value": "C113908"},
          {"label": "Sotillo de la Ribera", "value": "C113909"},
          {"label": "Sotillo del Rincon", "value": "C113910"},
          {"label": "Soto de Cerrato", "value": "C113911"},
          {"label": "Soto de la Vega", "value": "C113912"},
          {"label": "Soto y Amio", "value": "C113913"},
          {"label": "Sotobanado y Priorato", "value": "C113914"},
          {"label": "Sotosalbos", "value": "C113915"},
          {"label": "Sotoserrano", "value": "C113916"},
          {"label": "Sotragero", "value": "C113917"},
          {"label": "Sotresgudo", "value": "C113918"},
          {"label": "Suellacabras", "value": "C113919"},
          {"label": "Susinos del Paramo", "value": "C113920"},
          {"label": "Tabanera de Cerrato", "value": "C113921"},
          {"label": "Tabanera de Valdavia", "value": "C113922"},
          {"label": "Tabanera la Luenga", "value": "C113923"},
          {"label": "Tabara", "value": "C113924"},
          {"label": "Tabera de Abajo", "value": "C113925"},
          {"label": "Tajahuerce", "value": "C113926"},
          {"label": "Tajueco", "value": "C113927"},
          {"label": "Tamames", "value": "C113928"},
          {"label": "Tamariz de Campos", "value": "C113929"},
          {"label": "Tamaron", "value": "C113930"},
          {"label": "Tapioles", "value": "C113931"},
          {"label": "Tarazona de Guarena", "value": "C113932"},
          {"label": "Tardaguila", "value": "C113933"},
          {"label": "Tardajos", "value": "C113934"},
          {"label": "Tardelcuende", "value": "C113935"},
          {"label": "Taroda", "value": "C113936"},
          {"label": "Tejada", "value": "C113937"},
          {"label": "Tejado", "value": "C113938"},
          {"label": "Tejeda y Segoyuela", "value": "C113939"},
          {"label": "Tenebron", "value": "C113940"},
          {"label": "Terradillos", "value": "C113941"},
          {"label": "Terradillos de Esgueva", "value": "C113942"},
          {"label": "Tiedra", "value": "C113943"},
          {"label": "Tinosillos", "value": "C113944"},
          {"label": "Tobar", "value": "C113945"},
          {"label": "Tolbanos", "value": "C113946"},
          {"label": "Tolocirio", "value": "C113947"},
          {"label": "Topas", "value": "C113948"},
          {"label": "Toral de los Guzmanes", "value": "C113949"},
          {"label": "Tordehumos", "value": "C113950"},
          {"label": "Tordesillas", "value": "C113951"},
          {"label": "Tordillos", "value": "C113952"},
          {"label": "Tordomar", "value": "C113953"},
          {"label": "Toreno", "value": "C113954"},
          {"label": "Torlengua", "value": "C113955"},
          {"label": "Tormellas", "value": "C113956"},
          {"label": "Tornadizos de avila", "value": "C113957"},
          {"label": "Toro", "value": "C113958"},
          {"label": "Torquemada", "value": "C113959"},
          {"label": "Torre de Esgueva", "value": "C113960"},
          {"label": "Torre de Penafiel", "value": "C113961"},
          {"label": "Torre del Bierzo", "value": "C113962"},
          {"label": "Torre Val de San Pedro", "value": "C113963"},
          {"label": "Torreadrada", "value": "C113964"},
          {"label": "Torreblacos", "value": "C113965"},
          {"label": "Torrecaballeros", "value": "C113966"},
          {"label": "Torrecilla de la Abadesa", "value": "C113967"},
          {"label": "Torrecilla de la Orden", "value": "C113968"},
          {"label": "Torrecilla de la Torre", "value": "C113969"},
          {"label": "Torrecilla del Monte", "value": "C113970"},
          {"label": "Torrecilla del Pinar", "value": "C113971"},
          {"label": "Torregalindo", "value": "C113972"},
          {"label": "Torregamones", "value": "C113973"},
          {"label": "Torreiglesias", "value": "C113974"},
          {"label": "Torrelara", "value": "C113975"},
          {"label": "Torrelobaton", "value": "C113976"},
          {"label": "Torremormojon", "value": "C113977"},
          {"label": "Torres del Carrizal", "value": "C113978"},
          {"label": "Torresandino", "value": "C113979"},
          {"label": "Torrescarcela", "value": "C113980"},
          {"label": "Torresmenudas", "value": "C113981"},
          {"label": "Torrubia de Soria", "value": "C113982"},
          {"label": "Tortoles", "value": "C113983"},
          {"label": "Tosantos", "value": "C113984"},
          {"label": "Trabadelo", "value": "C113985"},
          {"label": "Trabanca", "value": "C113986"},
          {"label": "Trabazos", "value": "C113987"},
          {"label": "Traspinedo", "value": "C113988"},
          {"label": "Trefacio", "value": "C113989"},
          {"label": "Tremedal de Tormes", "value": "C113990"},
          {"label": "Trescasas", "value": "C113991"},
          {"label": "Trespaderne", "value": "C113992"},
          {"label": "Trigueros del Valle", "value": "C113993"},
          {"label": "Triollo", "value": "C113994"},
          {"label": "Truchas", "value": "C113995"},
          {"label": "Tubilla del Agua", "value": "C113996"},
          {"label": "Tubilla del Lago", "value": "C113997"},
          {"label": "Tudela de Duero", "value": "C113998"},
          {"label": "Turcia", "value": "C113999"},
          {"label": "Turegano", "value": "C114000"},
          {"label": "Ucero", "value": "C114001"},
          {"label": "Umbrias", "value": "C114002"},
          {"label": "Una de Quintana", "value": "C114003"},
          {"label": "Urdiales del Paramo", "value": "C114004"},
          {"label": "Urones de Castroponce", "value": "C114005"},
          {"label": "Uruena", "value": "C114006"},
          {"label": "Uruenas", "value": "C114007"},
          {"label": "Vadillo", "value": "C114008"},
          {"label": "Vadillo de la Guarena", "value": "C114009"},
          {"label": "Vadillo de la Sierra", "value": "C114010"},
          {"label": "Vadocondes", "value": "C114011"},
          {"label": "Val de San Lorenzo", "value": "C114012"},
          {"label": "Valbuena de Duero", "value": "C114013"},
          {"label": "Valbuena de Pisuerga", "value": "C114014"},
          {"label": "Valcabado", "value": "C114015"},
          {"label": "Valdeande", "value": "C114016"},
          {"label": "Valdearcos de la Vega", "value": "C114017"},
          {"label": "Valdeavellano de Tera", "value": "C114018"},
          {"label": "Valdecarros", "value": "C114019"},
          {"label": "Valdecasa", "value": "C114020"},
          {"label": "Valdefinjas", "value": "C114021"},
          {"label": "Valdefresno", "value": "C114022"},
          {"label": "Valdefuentes de Sangusin", "value": "C114023"},
          {"label": "Valdefuentes del Paramo", "value": "C114024"},
          {"label": "Valdehijaderos", "value": "C114025"},
          {"label": "Valdelacasa", "value": "C114026"},
          {"label": "Valdelageve", "value": "C114027"},
          {"label": "Valdelagua del Cerro", "value": "C114028"},
          {"label": "Valdelosa", "value": "C114029"},
          {"label": "Valdemaluque", "value": "C114030"},
          {"label": "Valdemierque", "value": "C114031"},
          {"label": "Valdemora", "value": "C114032"},
          {"label": "Valdenebro", "value": "C114033"},
          {"label": "Valdenebro de los Valles", "value": "C114034"},
          {"label": "Valdeolmillos", "value": "C114035"},
          {"label": "Valdepielago", "value": "C114036"},
          {"label": "Valdepolo", "value": "C114037"},
          {"label": "Valdeprado", "value": "C114038"},
          {"label": "Valdeprados", "value": "C114039"},
          {"label": "Valderas", "value": "C114040"},
          {"label": "Valderrabano", "value": "C114041"},
          {"label": "Valderrey", "value": "C114042"},
          {"label": "Valderrodilla", "value": "C114043"},
          {"label": "Valderrodrigo", "value": "C114044"},
          {"label": "Valderrueda", "value": "C114045"},
          {"label": "Valdesamario", "value": "C114046"},
          {"label": "Valdescorriel", "value": "C114047"},
          {"label": "Valdestillas", "value": "C114048"},
          {"label": "Valdevacas de Montejo", "value": "C114049"},
          {"label": "Valdevimbre", "value": "C114050"},
          {"label": "Valdezate", "value": "C114051"},
          {"label": "Valdorros", "value": "C114052"},
          {"label": "Valdunciel", "value": "C114053"},
          {"label": "Valdunquillo", "value": "C114054"},
          {"label": "Valencia de Don Juan", "value": "C114055"},
          {"label": "Valero", "value": "C114056"},
          {"label": "Valladolid", "value": "C114057"},
          {"label": "Vallarta de Bureba", "value": "C114058"},
          {"label": "Valle de Cerrato", "value": "C114059"},
          {"label": "Valle de Tabladillo", "value": "C114060"},
          {"label": "Vallecillo", "value": "C114061"},
          {"label": "Vallejera de Riofrio", "value": "C114062"},
          {"label": "Vallelado", "value": "C114063"},
          {"label": "Valleruela de Pedraza", "value": "C114064"},
          {"label": "Valleruela de Sepulveda", "value": "C114065"},
          {"label": "Valles de Palenzuela", "value": "C114066"},
          {"label": "Vallesa de la Guarena", "value": "C114067"},
          {"label": "Valluercanes", "value": "C114068"},
          {"label": "Valmala", "value": "C114069"},
          {"label": "Valoria la Buena", "value": "C114070"},
          {"label": "Valsalabroso", "value": "C114071"},
          {"label": "Valseca", "value": "C114072"},
          {"label": "Valtajeros", "value": "C114073"},
          {"label": "Valtiendas", "value": "C114074"},
          {"label": "Valverde de Campos", "value": "C114075"},
          {"label": "Valverde de la Virgen", "value": "C114076"},
          {"label": "Valverde de Valdelacasa", "value": "C114077"},
          {"label": "Valverde del Majano", "value": "C114078"},
          {"label": "Valverde-Enrique", "value": "C114079"},
          {"label": "Valverdon", "value": "C114080"},
          {"label": "Vecinos", "value": "C114081"},
          {"label": "Vega de Espinareda", "value": "C114082"},
          {"label": "Vega de Infanzones", "value": "C114083"},
          {"label": "Vega de Ruiponce", "value": "C114084"},
          {"label": "Vega de Santa Maria", "value": "C114085"},
          {"label": "Vega de Tera", "value": "C114086"},
          {"label": "Vega de Tirados", "value": "C114087"},
          {"label": "Vega de Valcarce", "value": "C114088"},
          {"label": "Vega de Valdetronco", "value": "C114089"},
          {"label": "Vega de Villalobos", "value": "C114090"},
          {"label": "Vegacervera", "value": "C114091"},
          {"label": "Vegalatrave", "value": "C114092"},
          {"label": "Veganzones", "value": "C114093"},
          {"label": "Vegaquemada", "value": "C114094"},
          {"label": "Vegas de Matute", "value": "C114095"},
          {"label": "Velamazan", "value": "C114096"},
          {"label": "Velascalvaro", "value": "C114097"},
          {"label": "Velayos", "value": "C114098"},
          {"label": "Velilla", "value": "C114099"},
          {"label": "Velilla de los Ajos", "value": "C114100"},
          {"label": "Velilla del Rio Carrion", "value": "C114101"},
          {"label": "Velliza", "value": "C114102"},
          {"label": "Venialbo", "value": "C114103"},
          {"label": "Venta de Banos", "value": "C114104"},
          {"label": "Ventosa de la Cuesta", "value": "C114105"},
          {"label": "Ventosa del Rio Almar", "value": "C114106"},
          {"label": "Vertavillo", "value": "C114107"},
          {"label": "Vezdemarban", "value": "C114108"},
          {"label": "Viana de Cega", "value": "C114109"},
          {"label": "Viana de Duero", "value": "C114110"},
          {"label": "Vidayanes", "value": "C114111"},
          {"label": "Videmala", "value": "C114112"},
          {"label": "Vilena", "value": "C114113"},
          {"label": "Villabanez", "value": "C114114"},
          {"label": "Villabaruz de Campos", "value": "C114115"},
          {"label": "Villablino", "value": "C114116"},
          {"label": "Villabragima", "value": "C114117"},
          {"label": "Villabraz", "value": "C114118"},
          {"label": "Villabrazaro", "value": "C114119"},
          {"label": "Villabuena del Puente", "value": "C114120"},
          {"label": "Villacarralon", "value": "C114121"},
          {"label": "Villacastin", "value": "C114122"},
          {"label": "Villacid de Campos", "value": "C114123"},
          {"label": "Villacidaler", "value": "C114124"},
          {"label": "Villaciervos", "value": "C114125"},
          {"label": "Villaco", "value": "C114126"},
          {"label": "Villaconancio", "value": "C114127"},
          {"label": "Villada", "value": "C114128"},
          {"label": "Villadangos del Paramo", "value": "C114129"},
          {"label": "Villadecanes", "value": "C114130"},
          {"label": "Villademor de la Vega", "value": "C114131"},
          {"label": "Villadepera", "value": "C114132"},
          {"label": "Villadiego", "value": "C114133"},
          {"label": "Villaeles de Valdavia", "value": "C114134"},
          {"label": "Villaescusa", "value": "C114135"},
          {"label": "Villaescusa de Roa", "value": "C114136"},
          {"label": "Villaescusa la Sombria", "value": "C114137"},
          {"label": "Villaespasa", "value": "C114138"},
          {"label": "Villafafila", "value": "C114139"},
          {"label": "Villaferruena", "value": "C114140"},
          {"label": "Villaflor", "value": "C114141"},
          {"label": "Villaflores", "value": "C114142"},
          {"label": "Villafrades de Campos", "value": "C114143"},
          {"label": "Villafranca de Duero", "value": "C114144"},
          {"label": "Villafranca de la Sierra", "value": "C114145"},
          {"label": "Villafranca del Bierzo", "value": "C114146"},
          {"label": "Villafrechos", "value": "C114147"},
          {"label": "Villafruela", "value": "C114148"},
          {"label": "Villafuerte", "value": "C114149"},
          {"label": "Villagalijo", "value": "C114150"},
          {"label": "Villagarcia de Campos", "value": "C114151"},
          {"label": "Villagaton", "value": "C114152"},
          {"label": "Villageriz", "value": "C114153"},
          {"label": "Villagomez la Nueva", "value": "C114154"},
          {"label": "Villagonzalo de Tormes", "value": "C114155"},
          {"label": "Villagonzalo-Pedernales", "value": "C114156"},
          {"label": "Villahan", "value": "C114157"},
          {"label": "Villaherreros", "value": "C114158"},
          {"label": "Villahoz", "value": "C114159"},
          {"label": "Villalaco", "value": "C114160"},
          {"label": "Villalan de Campos", "value": "C114161"},
          {"label": "Villalar de los Comuneros", "value": "C114162"},
          {"label": "Villalazan", "value": "C114163"},
          {"label": "Villalba de Duero", "value": "C114164"},
          {"label": "Villalba de Guardo", "value": "C114165"},
          {"label": "Villalba de la Lampreana", "value": "C114166"},
          {"label": "Villalba de la Loma", "value": "C114167"},
          {"label": "Villalba de los Alcores", "value": "C114168"},
          {"label": "Villalba de los Llanos", "value": "C114169"},
          {"label": "Villalbarba", "value": "C114170"},
          {"label": "Villalbilla de Burgos", "value": "C114171"},
          {"label": "Villalbilla de Gumiel", "value": "C114172"},
          {"label": "Villalcampo", "value": "C114173"},
          {"label": "Villalcazar de Sirga", "value": "C114174"},
          {"label": "Villalcon", "value": "C114175"},
          {"label": "Villaldemiro", "value": "C114176"},
          {"label": "Villalmanzo", "value": "C114177"},
          {"label": "Villalobon", "value": "C114178"},
          {"label": "Villalobos", "value": "C114179"},
          {"label": "Villalon de Campos", "value": "C114180"},
          {"label": "Villalonso", "value": "C114181"},
          {"label": "Villalpando", "value": "C114182"},
          {"label": "Villalube", "value": "C114183"},
          {"label": "Villaluenga de la Vega", "value": "C114184"},
          {"label": "Villamanan", "value": "C114185"},
          {"label": "Villamandos", "value": "C114186"},
          {"label": "Villamanin", "value": "C114187"},
          {"label": "Villamartin de Campos", "value": "C114188"},
          {"label": "Villamartin de Don Sancho", "value": "C114189"},
          {"label": "Villamayor", "value": "C114190"},
          {"label": "Villamayor de Campos", "value": "C114191"},
          {"label": "Villamayor de los Montes", "value": "C114192"},
          {"label": "Villamayor de Trevino", "value": "C114193"},
          {"label": "Villamediana", "value": "C114194"},
          {"label": "Villamedianilla", "value": "C114195"},
          {"label": "Villamejil", "value": "C114196"},
          {"label": "Villameriel", "value": "C114197"},
          {"label": "Villamiel de la Sierra", "value": "C114198"},
          {"label": "Villamol", "value": "C114199"},
          {"label": "Villamontan de la Valduerna", "value": "C114200"},
          {"label": "Villamor de los Escuderos", "value": "C114201"},
          {"label": "Villamoratiel de las Matas", "value": "C114202"},
          {"label": "Villamoronta", "value": "C114203"},
          {"label": "Villamuera de la Cueza", "value": "C114204"},
          {"label": "Villamuriel de Campos", "value": "C114205"},
          {"label": "Villamuriel de Cerrato", "value": "C114206"},
          {"label": "Villan de Tordesillas", "value": "C114207"},
          {"label": "Villanazar", "value": "C114208"},
          {"label": "Villangomez", "value": "C114209"},
          {"label": "Villanubla", "value": "C114210"},
          {"label": "Villanueva de Argano", "value": "C114211"},
          {"label": "Villanueva de Azoague", "value": "C114212"},
          {"label": "Villanueva de Campean", "value": "C114213"},
          {"label": "Villanueva de Carazo", "value": "C114214"},
          {"label": "Villanueva de Duero", "value": "C114215"},
          {"label": "Villanueva de Gomez", "value": "C114216"},
          {"label": "Villanueva de Gormaz", "value": "C114217"},
          {"label": "Villanueva de Gumiel", "value": "C114218"},
          {"label": "Villanueva de la Condesa", "value": "C114219"},
          {"label": "Villanueva de las Manzanas", "value": "C114220"},
          {"label": "Villanueva de las Peras", "value": "C114221"},
          {"label": "Villanueva de los Caballeros", "value": "C114222"},
          {"label": "Villanueva de San Mancio", "value": "C114223"},
          {"label": "Villanueva de Teba", "value": "C114224"},
          {"label": "Villanueva del Aceral", "value": "C114225"},
          {"label": "Villanueva del Campillo", "value": "C114226"},
          {"label": "Villanueva del Campo", "value": "C114227"},
          {"label": "Villanueva del Conde", "value": "C114228"},
          {"label": "Villanueva del Rebollar", "value": "C114229"},
          {"label": "Villanuno de Valdavia", "value": "C114230"},
          {"label": "Villaobispo de Otero", "value": "C114231"},
          {"label": "Villaprovedo", "value": "C114232"},
          {"label": "Villaquejida", "value": "C114233"},
          {"label": "Villaquilambre", "value": "C114234"},
          {"label": "Villaquiran de la Puebla", "value": "C114235"},
          {"label": "Villaquiran de los Infantes", "value": "C114236"},
          {"label": "Villar de Ciervo", "value": "C114237"},
          {"label": "Villar de Corneja", "value": "C114238"},
          {"label": "Villar de Fallaves", "value": "C114239"},
          {"label": "Villar de Gallimazo", "value": "C114240"},
          {"label": "Villar de la Yegua", "value": "C114241"},
          {"label": "Villar de Peralonso", "value": "C114242"},
          {"label": "Villar de Samaniego", "value": "C114243"},
          {"label": "Villar del Ala", "value": "C114244"},
          {"label": "Villar del Buey", "value": "C114245"},
          {"label": "Villar del Campo", "value": "C114246"},
          {"label": "Villar del Rio", "value": "C114247"},
          {"label": "Villaralbo", "value": "C114248"},
          {"label": "Villardeciervos", "value": "C114249"},
          {"label": "Villardefrades", "value": "C114250"},
          {"label": "Villardiegua de la Ribera", "value": "C114251"},
          {"label": "Villardiga", "value": "C114252"},
          {"label": "Villardondiego", "value": "C114253"},
          {"label": "Villarejo de orbigo", "value": "C114254"},
          {"label": "Villarejo del Valle", "value": "C114255"},
          {"label": "Villares de la Reina", "value": "C114256"},
          {"label": "Villares de orbigo", "value": "C114257"},
          {"label": "Villares de Yeltes", "value": "C114258"},
          {"label": "Villariezo", "value": "C114259"},
          {"label": "Villarino de los Aires", "value": "C114260"},
          {"label": "Villarmayor", "value": "C114261"},
          {"label": "Villarmentero de Campos", "value": "C114262"},
          {"label": "Villarmentero de Esgueva", "value": "C114263"},
          {"label": "Villarmuerto", "value": "C114264"},
          {"label": "Villarrabe", "value": "C114265"},
          {"label": "Villarramiel", "value": "C114266"},
          {"label": "Villarrin de Campos", "value": "C114267"},
          {"label": "Villasabariego", "value": "C114268"},
          {"label": "Villasana de Mena", "value": "C114269"},
          {"label": "Villasandino", "value": "C114270"},
          {"label": "Villasarracino", "value": "C114271"},
          {"label": "Villasayas", "value": "C114272"},
          {"label": "Villasbuenas", "value": "C114273"},
          {"label": "Villasdardo", "value": "C114274"},
          {"label": "Villaseca de Arciel", "value": "C114275"},
          {"label": "Villaseco de los Gamitos", "value": "C114276"},
          {"label": "Villaseco de los Reyes", "value": "C114277"},
          {"label": "Villaselan", "value": "C114278"},
          {"label": "Villasexmir", "value": "C114279"},
          {"label": "Villasila de Valdavia", "value": "C114280"},
          {"label": "Villasrubias", "value": "C114281"},
          {"label": "Villatoro", "value": "C114282"},
          {"label": "Villatuelda", "value": "C114283"},
          {"label": "Villaturde", "value": "C114284"},
          {"label": "Villaturiel", "value": "C114285"},
          {"label": "Villaumbrales", "value": "C114286"},
          {"label": "Villavaquerin", "value": "C114287"},
          {"label": "Villavellid", "value": "C114288"},
          {"label": "Villavendimio", "value": "C114289"},
          {"label": "Villaverde de Guarena", "value": "C114290"},
          {"label": "Villaverde de Medina", "value": "C114291"},
          {"label": "Villaverde de Montejo", "value": "C114292"},
          {"label": "Villaverde del Monte", "value": "C114293"},
          {"label": "Villaverde-Mogina", "value": "C114294"},
          {"label": "Villaveza de Valverde", "value": "C114295"},
          {"label": "Villaveza del Agua", "value": "C114296"},
          {"label": "Villavicencio de los Caballeros", "value": "C114297"},
          {"label": "Villavieja de Yeltes", "value": "C114298"},
          {"label": "Villaviudas", "value": "C114299"},
          {"label": "Villazala", "value": "C114300"},
          {"label": "Villazanzo de Valderaduey", "value": "C114301"},
          {"label": "Villazopeque", "value": "C114302"},
          {"label": "Villegas", "value": "C114303"},
          {"label": "Villeguillo", "value": "C114304"},
          {"label": "Villodre", "value": "C114305"},
          {"label": "Villodrigo", "value": "C114306"},
          {"label": "Villoldo", "value": "C114307"},
          {"label": "Villoria", "value": "C114308"},
          {"label": "Villoruebo", "value": "C114309"},
          {"label": "Villoruela", "value": "C114310"},
          {"label": "Villota del Paramo", "value": "C114311"},
          {"label": "Villovieco", "value": "C114312"},
          {"label": "Viloria", "value": "C114313"},
          {"label": "Viloria de Rioja", "value": "C114314"},
          {"label": "Vilvestre", "value": "C114315"},
          {"label": "Vilviestre del Pinar", "value": "C114316"},
          {"label": "Vinegra de Morana", "value": "C114317"},
          {"label": "Vinuesa", "value": "C114318"},
          {"label": "Vita", "value": "C114319"},
          {"label": "Vitigudino", "value": "C114320"},
          {"label": "Vizcainos", "value": "C114321"},
          {"label": "Vizmanos", "value": "C114322"},
          {"label": "Vozmediano", "value": "C114323"},
          {"label": "Wamba", "value": "C114324"},
          {"label": "Yanguas", "value": "C114325"},
          {"label": "Yanguas de Eresma", "value": "C114326"},
          {"label": "Yecla de Yeltes", "value": "C114327"},
          {"label": "Yelo", "value": "C114328"},
          {"label": "Zael", "value": "C114329"},
          {"label": "Zamarra", "value": "C114330"},
          {"label": "Zamayon", "value": "C114331"},
          {"label": "Zamora", "value": "C114332"},
          {"label": "Zapardiel de la Canada", "value": "C114333"},
          {"label": "Zapardiel de la Ribera", "value": "C114334"},
          {"label": "Zarapicos", "value": "C114335"},
          {"label": "Zaratan", "value": "C114336"},
          {"label": "Zarzuela del Monte", "value": "C114337"},
          {"label": "Zarzuela del Pinar", "value": "C114338"},
          {"label": "Zazuar", "value": "C114339"},
          {"label": "Zorita de la Frontera", "value": "C114340"},
          {"label": "Zotes del Paramo", "value": "C114341"},
          {"label": "Zuneda", "value": "C114342"}],
 "Leribe District": [{"label": "Leribe", "value": "C64992"},
                     {"label": "Maputsoe", "value": "C64993"}],
 "Lerik District": [{"label": "Lerik", "value": "C8460"}],
 "Lesser Poland Voivodeship": [{"label": "Alwernia", "value": "C88042"},
                               {"label": "Andrychow", "value": "C88043"},
                               {"label": "Babice", "value": "C88044"},
                               {"label": "Bachowice", "value": "C88045"},
                               {"label": "Balice", "value": "C88046"},
                               {"label": "Balin", "value": "C88047"},
                               {"label": "Barwald Gorny", "value": "C88048"},
                               {"label": "Barwald Sredni", "value": "C88049"},
                               {"label": "Beblo", "value": "C88050"},
                               {"label": "Beczarka", "value": "C88051"},
                               {"label": "Bialka", "value": "C88052"},
                               {"label": "Bialka Tatrzanska",
                                "value": "C88053"},
                               {"label": "Bialy Dunajec", "value": "C88054"},
                               {"label": "Biecz", "value": "C88055"},
                               {"label": "Bienkowka", "value": "C88056"},
                               {"label": "Binarowa", "value": "C88057"},
                               {"label": "Biskupice Radlowskie",
                                "value": "C88058"},
                               {"label": "Bobowa", "value": "C88059"},
                               {"label": "Bochnia", "value": "C88060"},
                               {"label": "Bolechowice", "value": "C88061"},
                               {"label": "Bolecin", "value": "C88062"},
                               {"label": "Boleslaw", "value": "C88063"},
                               {"label": "Borek", "value": "C88064"},
                               {"label": "Borzecin", "value": "C88065"},
                               {"label": "Borzeta", "value": "C88066"},
                               {"label": "Brodla", "value": "C88067"},
                               {"label": "Brody", "value": "C88068"},
                               {"label": "Brzaczowice", "value": "C88069"},
                               {"label": "Brzesko", "value": "C88070"},
                               {"label": "Brzeszcze", "value": "C88071"},
                               {"label": "Brzezinka", "value": "C88072"},
                               {"label": "Brzeznica", "value": "C88073"},
                               {"label": "Budzow", "value": "C88074"},
                               {"label": "Bukowina Tatrzanska",
                                "value": "C88075"},
                               {"label": "Bukowno", "value": "C88076"},
                               {"label": "Bulowice", "value": "C88077"},
                               {"label": "Bystra", "value": "C88078"},
                               {"label": "Chelmek", "value": "C88079"},
                               {"label": "Chelmiec", "value": "C88080"},
                               {"label": "Chocznia", "value": "C88081"},
                               {"label": "Chrzanow", "value": "C88082"},
                               {"label": "Chyzne", "value": "C88083"},
                               {"label": "Ciezkowice", "value": "C88084"},
                               {"label": "Czarna Gora", "value": "C88085"},
                               {"label": "Czarnochowice", "value": "C88086"},
                               {"label": "Czarny Dunajec", "value": "C88087"},
                               {"label": "Czchow", "value": "C88088"},
                               {"label": "Czernichow", "value": "C88089"},
                               {"label": "Czulow", "value": "C88090"},
                               {"label": "Dabrowa Tarnowska",
                                "value": "C88091"},
                               {"label": "Debno", "value": "C88092"},
                               {"label": "Dobczyce", "value": "C88093"},
                               {"label": "Dobra", "value": "C88094"},
                               {"label": "Dziewin", "value": "C88095"},
                               {"label": "Filipowice", "value": "C88096"},
                               {"label": "Frydman", "value": "C88097"},
                               {"label": "Frydrychowice", "value": "C88098"},
                               {"label": "Gdow", "value": "C88099"},
                               {"label": "Giebultow", "value": "C88100"},
                               {"label": "Gieraltowice", "value": "C88101"},
                               {"label": "Glogoczow", "value": "C88102"},
                               {"label": "Gnojnik", "value": "C88103"},
                               {"label": "Gorenice", "value": "C88104"},
                               {"label": "Gorlice", "value": "C88105"},
                               {"label": "Greboszow", "value": "C88106"},
                               {"label": "Grojec", "value": "C88107"},
                               {"label": "Gromnik", "value": "C88108"},
                               {"label": "Gruszow Wielki", "value": "C88109"},
                               {"label": "Grybow", "value": "C88110"},
                               {"label": "Grzechynia", "value": "C88111"},
                               {"label": "Harbutowice", "value": "C88112"},
                               {"label": "Inwald", "value": "C88113"},
                               {"label": "Iwkowa", "value": "C88114"},
                               {"label": "Izdebnik", "value": "C88115"},
                               {"label": "Jablonka", "value": "C88116"},
                               {"label": "Jadowniki", "value": "C88117"},
                               {"label": "Jadowniki Mokre", "value": "C88118"},
                               {"label": "Janowice", "value": "C88119"},
                               {"label": "Jaroszowice", "value": "C88120"},
                               {"label": "Jasien", "value": "C88121"},
                               {"label": "Jasienica", "value": "C88122"},
                               {"label": "Jastrzebia", "value": "C88123"},
                               {"label": "Jawornik", "value": "C88124"},
                               {"label": "Jerzmanowice", "value": "C88125"},
                               {"label": "Jodlowka", "value": "C88126"},
                               {"label": "Jodlowka-Walki", "value": "C88127"},
                               {"label": "Jordanow", "value": "C88128"},
                               {"label": "Juszczyn", "value": "C88129"},
                               {"label": "Kalwaria Zebrzydowska",
                                "value": "C88130"},
                               {"label": "Kamien", "value": "C88131"},
                               {"label": "Kamienica", "value": "C88132"},
                               {"label": "Kamionka Wielka", "value": "C88133"},
                               {"label": "Kasina Wielka", "value": "C88134"},
                               {"label": "Kaszow", "value": "C88135"},
                               {"label": "Kety", "value": "C88136"},
                               {"label": "Klecza Dolna", "value": "C88137"},
                               {"label": "Klikuszowa", "value": "C88138"},
                               {"label": "Klimontow", "value": "C88139"},
                               {"label": "Klucze", "value": "C88140"},
                               {"label": "Kluszkowce", "value": "C88141"},
                               {"label": "Klaj", "value": "C88142"},
                               {"label": "Kobylanka", "value": "C88143"},
                               {"label": "Kokotow", "value": "C88144"},
                               {"label": "Korzenna", "value": "C88145"},
                               {"label": "Koscielisko", "value": "C88146"},
                               {"label": "Koszyce", "value": "C88147"},
                               {"label": "Koszyce Wielkie", "value": "C88148"},
                               {"label": "Kozlow", "value": "C88149"},
                               {"label": "Krakow", "value": "C88150"},
                               {"label": "Kroscienko nad Dunajcem",
                                "value": "C88151"},
                               {"label": "Krosnica", "value": "C88152"},
                               {"label": "Krynica-Zdroj", "value": "C88153"},
                               {"label": "Kryspinow", "value": "C88154"},
                               {"label": "Krzczonow", "value": "C88155"},
                               {"label": "Krzecin", "value": "C88156"},
                               {"label": "Krzeczow", "value": "C88157"},
                               {"label": "Krzeszow", "value": "C88158"},
                               {"label": "Krzeszowice", "value": "C88159"},
                               {"label": "Krzyszkowice", "value": "C88160"},
                               {"label": "Krzywaczka", "value": "C88161"},
                               {"label": "Kukow", "value": "C88162"},
                               {"label": "Kwaczala", "value": "C88163"},
                               {"label": "Lachowice", "value": "C88164"},
                               {"label": "Lanckorona", "value": "C88165"},
                               {"label": "Lasek", "value": "C88166"},
                               {"label": "Laskowa", "value": "C88167"},
                               {"label": "Lencze", "value": "C88168"},
                               {"label": "Lesnica", "value": "C88169"},
                               {"label": "Libertow", "value": "C88170"},
                               {"label": "Libiaz", "value": "C88171"},
                               {"label": "Limanowa", "value": "C88172"},
                               {"label": "Lipinki", "value": "C88173"},
                               {"label": "Lipnica Mala", "value": "C88174"},
                               {"label": "Lipnica Wielka", "value": "C88175"},
                               {"label": "Lipnik", "value": "C88176"},
                               {"label": "Lisia Gora", "value": "C88177"},
                               {"label": "Liszki", "value": "C88178"},
                               {"label": "Lubien", "value": "C88179"},
                               {"label": "Lubomierz", "value": "C88180"},
                               {"label": "Ludzmierz", "value": "C88181"},
                               {"label": "Luszowice", "value": "C88182"},
                               {"label": "Labowa", "value": "C88183"},
                               {"label": "Lacko", "value": "C88184"},
                               {"label": "Laczany", "value": "C88185"},
                               {"label": "Lapanow", "value": "C88186"},
                               {"label": "Lapczyca", "value": "C88187"},
                               {"label": "Lapsze Nizne", "value": "C88188"},
                               {"label": "Letownia", "value": "C88189"},
                               {"label": "Lopuszna", "value": "C88190"},
                               {"label": "Lososina Dolna", "value": "C88191"},
                               {"label": "Lukowa", "value": "C88192"},
                               {"label": "Lukowica", "value": "C88193"},
                               {"label": "Luzna", "value": "C88194"},
                               {"label": "Makow Podhalanski",
                                "value": "C88195"},
                               {"label": "Malec", "value": "C88196"},
                               {"label": "Maniowy", "value": "C88197"},
                               {"label": "Maszkienice", "value": "C88198"},
                               {"label": "Medrzechow", "value": "C88199"},
                               {"label": "Metkow", "value": "C88200"},
                               {"label": "Michalowice", "value": "C88201"},
                               {"label": "Miechow", "value": "C88202"},
                               {"label": "Miechow Charsznica",
                                "value": "C88203"},
                               {"label": "Miekinia", "value": "C88204"},
                               {"label": "Mnikow", "value": "C88205"},
                               {"label": "Modlnica", "value": "C88206"},
                               {"label": "Modlniczka", "value": "C88207"},
                               {"label": "Mogilany", "value": "C88208"},
                               {"label": "Moszczenica", "value": "C88209"},
                               {"label": "Mszana Dolna", "value": "C88210"},
                               {"label": "Mszana Gorna", "value": "C88211"},
                               {"label": "Muszyna", "value": "C88212"},
                               {"label": "Myslachowice", "value": "C88213"},
                               {"label": "Myslenice", "value": "C88214"},
                               {"label": "Naprawa", "value": "C88215"},
                               {"label": "Nawojowa", "value": "C88216"},
                               {"label": "Nidek", "value": "C88217"},
                               {"label": "Niedomice", "value": "C88218"},
                               {"label": "Niedzica", "value": "C88219"},
                               {"label": "Niedzwiedz", "value": "C88220"},
                               {"label": "Niepolomice", "value": "C88221"},
                               {"label": "Nowa Gora", "value": "C88222"},
                               {"label": "Nowa Wies", "value": "C88223"},
                               {"label": "Nowe Brzesko", "value": "C88224"},
                               {"label": "Nowy Sacz", "value": "C88225"},
                               {"label": "Nowy Targ", "value": "C88226"},
                               {"label": "Nowy Wisnicz", "value": "C88227"},
                               {"label": "Ochojno", "value": "C88228"},
                               {"label": "Ochotnica Dolna", "value": "C88229"},
                               {"label": "Okocim", "value": "C88230"},
                               {"label": "Olesno", "value": "C88231"},
                               {"label": "Olkusz", "value": "C88232"},
                               {"label": "Olszana", "value": "C88233"},
                               {"label": "Olszowka", "value": "C88234"},
                               {"label": "Olpiny", "value": "C88235"},
                               {"label": "Osieczany", "value": "C88236"},
                               {"label": "Osiek", "value": "C88237"},
                               {"label": "Osielec", "value": "C88238"},
                               {"label": "Ostreznica", "value": "C88239"},
                               {"label": "Ostrowsko", "value": "C88240"},
                               {"label": "Oswiecim", "value": "C88241"},
                               {"label": "Palcza", "value": "C88242"},
                               {"label": "Pawlikowice", "value": "C88243"},
                               {"label": "Pcim", "value": "C88244"},
                               {"label": "Piekary", "value": "C88245"},
                               {"label": "Piekielnik", "value": "C88246"},
                               {"label": "Piwniczna-Zdroj", "value": "C88247"},
                               {"label": "Plesna", "value": "C88248"},
                               {"label": "Plaza", "value": "C88249"},
                               {"label": "Podegrodzie", "value": "C88250"},
                               {"label": "Podleze", "value": "C88251"},
                               {"label": "Podwilk", "value": "C88252"},
                               {"label": "Pogorska Wola", "value": "C88253"},
                               {"label": "Pogorzyce", "value": "C88254"},
                               {"label": "Polanka Wielka", "value": "C88255"},
                               {"label": "Ponikiew", "value": "C88256"},
                               {"label": "Porabka Uszewska", "value": "C88257"},
                               {"label": "Poreba", "value": "C88258"},
                               {"label": "Poreba Spytkowska",
                                "value": "C88259"},
                               {"label": "Poreba Wielka", "value": "C88260"},
                               {"label": "Poronin", "value": "C88261"},
                               {"label": "Powiat bochenski", "value": "C88262"},
                               {"label": "Powiat brzeski", "value": "C88263"},
                               {"label": "Powiat chrzanowski",
                                "value": "C88264"},
                               {"label": "Powiat dabrowski", "value": "C88265"},
                               {"label": "Powiat gorlicki", "value": "C88266"},
                               {"label": "Powiat krakowski", "value": "C88267"},
                               {"label": "Powiat limanowski",
                                "value": "C88268"},
                               {"label": "Powiat miechowski",
                                "value": "C88269"},
                               {"label": "Powiat myslenicki",
                                "value": "C88270"},
                               {"label": "Powiat nowosadecki",
                                "value": "C88271"},
                               {"label": "Powiat nowotarski",
                                "value": "C88272"},
                               {"label": "Powiat olkuski", "value": "C88273"},
                               {"label": "Powiat oswiecimski",
                                "value": "C88274"},
                               {"label": "Powiat proszowicki",
                                "value": "C88275"},
                               {"label": "Powiat suski", "value": "C88276"},
                               {"label": "Powiat tarnowski", "value": "C88277"},
                               {"label": "Powiat tatrzanski",
                                "value": "C88278"},
                               {"label": "Powiat wadowicki", "value": "C88279"},
                               {"label": "Powiat wielicki", "value": "C88280"},
                               {"label": "Proszowice", "value": "C88281"},
                               {"label": "Przecieszyn", "value": "C88282"},
                               {"label": "Przeciszow", "value": "C88283"},
                               {"label": "Przeginia", "value": "C88284"},
                               {"label": "Przyborow", "value": "C88285"},
                               {"label": "Przytkowice", "value": "C88286"},
                               {"label": "Psary", "value": "C88287"},
                               {"label": "Raba Wyzna", "value": "C88288"},
                               {"label": "Rabka-Zdroj", "value": "C88289"},
                               {"label": "Raclawice", "value": "C88290"},
                               {"label": "Raczna", "value": "C88291"},
                               {"label": "Radgoszcz", "value": "C88292"},
                               {"label": "Radlow", "value": "C88293"},
                               {"label": "Radocza", "value": "C88294"},
                               {"label": "Radziszow", "value": "C88295"},
                               {"label": "Rajsko", "value": "C88296"},
                               {"label": "Regulice", "value": "C88297"},
                               {"label": "Roczyny", "value": "C88298"},
                               {"label": "Rokiciny", "value": "C88299"},
                               {"label": "Ropa", "value": "C88300"},
                               {"label": "Rudawa", "value": "C88301"},
                               {"label": "Rudnik", "value": "C88302"},
                               {"label": "Rusocice", "value": "C88303"},
                               {"label": "Ryczow", "value": "C88304"},
                               {"label": "Ryglice", "value": "C88305"},
                               {"label": "Rytro", "value": "C88306"},
                               {"label": "Rzaska", "value": "C88307"},
                               {"label": "Rzepiennik Strzyzewski",
                                "value": "C88308"},
                               {"label": "Rzeszotary", "value": "C88309"},
                               {"label": "Rzezawa", "value": "C88310"},
                               {"label": "Rzozow", "value": "C88311"},
                               {"label": "Rzyki", "value": "C88312"},
                               {"label": "Sanka", "value": "C88313"},
                               {"label": "Saspow", "value": "C88314"},
                               {"label": "Sekowa", "value": "C88315"},
                               {"label": "Sidzina", "value": "C88316"},
                               {"label": "Siedliska", "value": "C88317"},
                               {"label": "Siemiechow", "value": "C88318"},
                               {"label": "Sieniawa", "value": "C88319"},
                               {"label": "Siepraw", "value": "C88320"},
                               {"label": "Sieradza", "value": "C88321"},
                               {"label": "Skala", "value": "C88322"},
                               {"label": "Skawica", "value": "C88323"},
                               {"label": "Skawina", "value": "C88324"},
                               {"label": "Skawinki", "value": "C88325"},
                               {"label": "Skomielna Czarna", "value": "C88326"},
                               {"label": "Skrzydlna", "value": "C88327"},
                               {"label": "Skrzyszow", "value": "C88328"},
                               {"label": "Slomniki", "value": "C88329"},
                               {"label": "Slopnice", "value": "C88330"},
                               {"label": "Slupiec", "value": "C88331"},
                               {"label": "Smegorzow", "value": "C88332"},
                               {"label": "Sosnowice", "value": "C88333"},
                               {"label": "Spytkowice", "value": "C88334"},
                               {"label": "Stanislaw Dolny", "value": "C88335"},
                               {"label": "Stanislaw Gorny", "value": "C88336"},
                               {"label": "Stanislawice", "value": "C88337"},
                               {"label": "Stary Sacz", "value": "C88338"},
                               {"label": "Stary Wisnicz", "value": "C88339"},
                               {"label": "Stronie", "value": "C88340"},
                               {"label": "Stroza", "value": "C88341"},
                               {"label": "Stryszawa", "value": "C88342"},
                               {"label": "Stryszow", "value": "C88343"},
                               {"label": "Sucha Beskidzka", "value": "C88344"},
                               {"label": "Sulecin", "value": "C88345"},
                               {"label": "Sulkowice", "value": "C88346"},
                               {"label": "Suloszowa", "value": "C88347"},
                               {"label": "Swiatniki Gorne", "value": "C88348"},
                               {"label": "Szaflary", "value": "C88349"},
                               {"label": "Szarow", "value": "C88350"},
                               {"label": "Szczawnica", "value": "C88351"},
                               {"label": "Szczucin", "value": "C88352"},
                               {"label": "Szczurowa", "value": "C88353"},
                               {"label": "Szerzyny", "value": "C88354"},
                               {"label": "Szynwald", "value": "C88355"},
                               {"label": "Targanice", "value": "C88356"},
                               {"label": "Targowisko", "value": "C88357"},
                               {"label": "Tarnow", "value": "C88358"},
                               {"label": "Tarnowiec", "value": "C88359"},
                               {"label": "Tenczynek", "value": "C88360"},
                               {"label": "Tokarnia", "value": "C88361"},
                               {"label": "Tomaszkowice", "value": "C88362"},
                               {"label": "Tomice", "value": "C88363"},
                               {"label": "Toporzysko", "value": "C88364"},
                               {"label": "Trabki", "value": "C88365"},
                               {"label": "Trzciana", "value": "C88366"},
                               {"label": "Trzebinia", "value": "C88367"},
                               {"label": "Trzebunia", "value": "C88368"},
                               {"label": "Trzemesnia", "value": "C88369"},
                               {"label": "Tuchow", "value": "C88370"},
                               {"label": "Tylicz", "value": "C88371"},
                               {"label": "Tymbark", "value": "C88372"},
                               {"label": "Uscie Gorlickie", "value": "C88373"},
                               {"label": "Wadowice", "value": "C88374"},
                               {"label": "Waksmund", "value": "C88375"},
                               {"label": "Wawrzenczyce", "value": "C88376"},
                               {"label": "Weglowka", "value": "C88377"},
                               {"label": "Wegrzce Wielkie", "value": "C88378"},
                               {"label": "Wieliczka", "value": "C88379"},
                               {"label": "Wielka Wies", "value": "C88380"},
                               {"label": "Wieprz", "value": "C88381"},
                               {"label": "Wierzchoslawice", "value": "C88382"},
                               {"label": "Wietrzychowice", "value": "C88383"},
                               {"label": "Wisniowa", "value": "C88384"},
                               {"label": "Witanowice", "value": "C88385"},
                               {"label": "Witkowice", "value": "C88386"},
                               {"label": "Wlosienica", "value": "C88387"},
                               {"label": "Wojnicz", "value": "C88388"},
                               {"label": "Wola Batorska", "value": "C88389"},
                               {"label": "Wola Debinska", "value": "C88390"},
                               {"label": "Wola Filipowska", "value": "C88391"},
                               {"label": "Wola Radziszowska",
                                "value": "C88392"},
                               {"label": "Wola Zabierzowska",
                                "value": "C88393"},
                               {"label": "Wolbrom", "value": "C88394"},
                               {"label": "Wolowice", "value": "C88395"},
                               {"label": "Wozniki", "value": "C88396"},
                               {"label": "Wrzasowice", "value": "C88397"},
                               {"label": "Wysoka", "value": "C88398"},
                               {"label": "Zabierzow", "value": "C88399"},
                               {"label": "Zabierzow Bochenski",
                                "value": "C88400"},
                               {"label": "Zabno", "value": "C88401"},
                               {"label": "Zaborze", "value": "C88402"},
                               {"label": "Zagornik", "value": "C88403"},
                               {"label": "Zagorze", "value": "C88404"},
                               {"label": "Zakliczyn", "value": "C88405"},
                               {"label": "Zakopane", "value": "C88406"},
                               {"label": "Zakrzow", "value": "C88407"},
                               {"label": "Zalas", "value": "C88408"},
                               {"label": "Zarki", "value": "C88409"},
                               {"label": "Zarzecze", "value": "C88410"},
                               {"label": "Zator", "value": "C88411"},
                               {"label": "Zawoja", "value": "C88412"},
                               {"label": "Zebrzydowice", "value": "C88413"},
                               {"label": "Zegocina", "value": "C88414"},
                               {"label": "Zembrzyce", "value": "C88415"},
                               {"label": "Zielonki", "value": "C88416"},
                               {"label": "Zlota", "value": "C88417"},
                               {"label": "Zubrzyca Dolna", "value": "C88418"},
                               {"label": "Zubrzyca Gorna", "value": "C88419"},
                               {"label": "Zurowa", "value": "C88420"}],
 "Lezhe County": [{"label": "Bashkia Kurbin", "value": "C175"},
                  {"label": "Bashkia Lezhe", "value": "C176"},
                  {"label": "Bashkia Mirdite", "value": "C177"},
                  {"label": "Kurbnesh", "value": "C178"},
                  {"label": "Lac", "value": "C179"},
                  {"label": "Lezhe", "value": "C180"},
                  {"label": "Mamurras", "value": "C181"},
                  {"label": "Milot", "value": "C182"},
                  {"label": "Rreshen", "value": "C183"},
                  {"label": "Rrethi i Kurbinit", "value": "C184"},
                  {"label": "Rubik", "value": "C185"},
                  {"label": "Shengjin", "value": "C186"}],
 "Lhaviyani Atoll": [{"label": "Naifaru", "value": "C65814"}],
 "Lhuntse District": [{"label": "Lhuentse", "value": "C9638"}],
 "Liaoning": [{"label": "Anshan", "value": "C19052"},
              {"label": "Beipiao", "value": "C19053"},
              {"label": "Benxi", "value": "C19054"},
              {"label": "Changtu", "value": "C19055"},
              {"label": "Chaoyang", "value": "C19056"},
              {"label": "Dalian", "value": "C19057"},
              {"label": "Dalianwan", "value": "C19058"},
              {"label": "Dandong", "value": "C19059"},
              {"label": "Dashiqiao", "value": "C19060"},
              {"label": "Dongling", "value": "C19061"},
              {"label": "Fengcheng", "value": "C19062"},
              {"label": "Fushun", "value": "C19063"},
              {"label": "Fuxin", "value": "C19064"},
              {"label": "Gaizhou", "value": "C19065"},
              {"label": "Gongchangling", "value": "C19066"},
              {"label": "Haicheng", "value": "C19067"},
              {"label": "Heishan", "value": "C19068"},
              {"label": "Huanren", "value": "C19069"},
              {"label": "Huludao", "value": "C19070"},
              {"label": "Huludao Shi", "value": "C19071"},
              {"label": "Hushitai", "value": "C19072"},
              {"label": "Jinzhou", "value": "C19073"},
              {"label": "Jiupu", "value": "C19074"},
              {"label": "Kaiyuan", "value": "C19075"},
              {"label": "Kuandian", "value": "C19076"},
              {"label": "Langtoucun", "value": "C19077"},
              {"label": "Lianshan", "value": "C19078"},
              {"label": "Liaoyang", "value": "C19079"},
              {"label": "Liaozhong", "value": "C19080"},
              {"label": "Linghai", "value": "C19081"},
              {"label": "Lingyuan", "value": "C19082"},
              {"label": "Lushun", "value": "C19083"},
              {"label": "Nanpiao", "value": "C19084"},
              {"label": "Nantai", "value": "C19085"},
              {"label": "Panjin Shi", "value": "C19086"},
              {"label": "Panshan", "value": "C19087"},
              {"label": "Pulandian", "value": "C19088"},
              {"label": "Shenyang", "value": "C19089"},
              {"label": "Sujiatun", "value": "C19090"},
              {"label": "Tieling", "value": "C19091"},
              {"label": "Tieling Shi", "value": "C19092"},
              {"label": "Wafangdian", "value": "C19093"},
              {"label": "Xiaoshi", "value": "C19094"},
              {"label": "Xifeng", "value": "C19095"},
              {"label": "Xingcheng", "value": "C19096"},
              {"label": "Xinmin", "value": "C19097"},
              {"label": "Xinxing", "value": "C19098"},
              {"label": "Xiuyan", "value": "C19099"},
              {"label": "Yebaishou", "value": "C19100"},
              {"label": "Yingkou", "value": "C19101"},
              {"label": "Zhuanghe", "value": "C19102"}],
 "Liberecky kraj": [{"label": "Benecko", "value": "C22416"},
                    {"label": "Brniste", "value": "C22417"},
                    {"label": "Ceska Lipa", "value": "C22418"},
                    {"label": "Cesky Dub", "value": "C22419"},
                    {"label": "Chrastava", "value": "C22420"},
                    {"label": "Cvikov", "value": "C22421"},
                    {"label": "Desna", "value": "C22422"},
                    {"label": "Doksy", "value": "C22423"},
                    {"label": "Duba", "value": "C22424"},
                    {"label": "Frydlant", "value": "C22425"},
                    {"label": "Harrachov", "value": "C22426"},
                    {"label": "Hejnice", "value": "C22427"},
                    {"label": "Hodkovice nad Mohelkou", "value": "C22428"},
                    {"label": "Horni Branna", "value": "C22429"},
                    {"label": "Hradek nad Nisou", "value": "C22430"},
                    {"label": "Jablonec nad Jizerou", "value": "C22431"},
                    {"label": "Jablonec nad Nisou", "value": "C22432"},
                    {"label": "Jablonne v Podjestedi", "value": "C22433"},
                    {"label": "Janov nad Nisou", "value": "C22434"},
                    {"label": "Jilemnice", "value": "C22435"},
                    {"label": "Josefuv Dul", "value": "C22436"},
                    {"label": "Kamenicky Senov", "value": "C22437"},
                    {"label": "Korenov", "value": "C22438"},
                    {"label": "Kostalov", "value": "C22439"},
                    {"label": "Liberec", "value": "C22440"},
                    {"label": "Lomnice nad Popelkou", "value": "C22441"},
                    {"label": "Lucany nad Nisou", "value": "C22442"},
                    {"label": "Mala Skala", "value": "C22443"},
                    {"label": "Mimon", "value": "C22444"},
                    {"label": "Mnisek", "value": "C22445"},
                    {"label": "Nove Mesto pod Smrkem", "value": "C22446"},
                    {"label": "Novy Bor", "value": "C22447"},
                    {"label": "Ohrazenice", "value": "C22448"},
                    {"label": "Okres Ceska Lipa", "value": "C22449"},
                    {"label": "Okres Jablonec nad Nisou", "value": "C22450"},
                    {"label": "Okres Liberec", "value": "C22451"},
                    {"label": "Okres Semily", "value": "C22452"},
                    {"label": "Osecna", "value": "C22453"},
                    {"label": "Pencin", "value": "C22454"},
                    {"label": "Plavy", "value": "C22455"},
                    {"label": "Ponikla", "value": "C22456"},
                    {"label": "Prisovice", "value": "C22457"},
                    {"label": "Raspenava", "value": "C22458"},
                    {"label": "Rokytnice nad Jizerou", "value": "C22459"},
                    {"label": "Rovensko pod Troskami", "value": "C22460"},
                    {"label": "Semily", "value": "C22461"},
                    {"label": "Smrzovka", "value": "C22462"},
                    {"label": "Straz nad Nisou", "value": "C22463"},
                    {"label": "Straz pod Ralskem", "value": "C22464"},
                    {"label": "Studenec", "value": "C22465"},
                    {"label": "Tanvald", "value": "C22466"},
                    {"label": "Turnov", "value": "C22467"},
                    {"label": "Valdice", "value": "C22468"},
                    {"label": "Velke Hamry", "value": "C22469"},
                    {"label": "Visnova", "value": "C22470"},
                    {"label": "Vysoke nad Jizerou", "value": "C22471"},
                    {"label": "Zakupy", "value": "C22472"},
                    {"label": "Zandov", "value": "C22473"},
                    {"label": "Zelezny Brod", "value": "C22474"}],
 "Libertador General Bernardo OHiggins": [{"label": "Chepica",
                                           "value": "C18004"},
                                          {"label": "Chimbarongo",
                                           "value": "C18005"},
                                          {"label": "Codegua",
                                           "value": "C18006"},
                                          {"label": "Coinco",
                                           "value": "C18007"},
                                          {"label": "Coltauco",
                                           "value": "C18008"},
                                          {"label": "Donihue",
                                           "value": "C18009"},
                                          {"label": "Graneros",
                                           "value": "C18010"},
                                          {"label": "La Estrella",
                                           "value": "C18011"},
                                          {"label": "Las Cabras",
                                           "value": "C18012"},
                                          {"label": "Litueche",
                                           "value": "C18013"},
                                          {"label": "Lolol", "value": "C18014"},
                                          {"label": "Machali",
                                           "value": "C18015"},
                                          {"label": "Malloa",
                                           "value": "C18016"},
                                          {"label": "Marchigue",
                                           "value": "C18017"},
                                          {"label": "Mostazal",
                                           "value": "C18018"},
                                          {"label": "Nancagua",
                                           "value": "C18019"},
                                          {"label": "Navidad",
                                           "value": "C18020"},
                                          {"label": "Olivar",
                                           "value": "C18021"},
                                          {"label": "Palmilla",
                                           "value": "C18022"},
                                          {"label": "Paredones",
                                           "value": "C18023"},
                                          {"label": "Peralillo",
                                           "value": "C18024"},
                                          {"label": "Peumo", "value": "C18025"},
                                          {"label": "Pichidegua",
                                           "value": "C18026"},
                                          {"label": "Pichilemu",
                                           "value": "C18027"},
                                          {"label": "Placilla",
                                           "value": "C18028"},
                                          {"label": "Pumanque",
                                           "value": "C18029"},
                                          {"label": "Quinta de Tilcoco",
                                           "value": "C18030"},
                                          {"label": "Rancagua",
                                           "value": "C18031"},
                                          {"label": "Rengo", "value": "C18032"},
                                          {"label": "Requinoa",
                                           "value": "C18033"},
                                          {"label": "San Fernando",
                                           "value": "C18034"},
                                          {"label": "San Vicente",
                                           "value": "C18035"},
                                          {"label": "Santa Cruz",
                                           "value": "C18036"}],
 "Lielvarde Municipality": [{"label": "Lielvarde", "value": "C64891"}],
 "Lienchiang": [{"label": "Lienchiang", "value": "C117967"},
                {"label": "Nangan", "value": "C117968"}],
 "Liepaja": [{"label": "Karosta", "value": "C64892"},
             {"label": "Liepaja", "value": "C64893"}],
 "Ligatne Municipality": [{"label": "Ligatne", "value": "C64894"}],
 "Liguria": [{"label": "Airole", "value": "C55140"},
             {"label": "Alassio", "value": "C55141"},
             {"label": "Albenga", "value": "C55142"},
             {"label": "Albisola Marina", "value": "C55143"},
             {"label": "Albisola Superiore", "value": "C55144"},
             {"label": "Altare", "value": "C55145"},
             {"label": "Ameglia", "value": "C55146"},
             {"label": "Andora", "value": "C55147"},
             {"label": "Apricale", "value": "C55148"},
             {"label": "Aquila di Arroscia", "value": "C55149"},
             {"label": "Arcola", "value": "C55150"},
             {"label": "Arenzano", "value": "C55151"},
             {"label": "Armo", "value": "C55152"},
             {"label": "Arnasco", "value": "C55153"},
             {"label": "Aurigo", "value": "C55154"},
             {"label": "Avegno", "value": "C55155"},
             {"label": "Badalucco", "value": "C55156"},
             {"label": "Bajardo", "value": "C55157"},
             {"label": "Balestrino", "value": "C55158"},
             {"label": "Bardineto", "value": "C55159"},
             {"label": "Bargagli", "value": "C55160"},
             {"label": "Bergeggi", "value": "C55161"},
             {"label": "Beverino", "value": "C55162"},
             {"label": "Bocco", "value": "C55163"},
             {"label": "Bogliasco", "value": "C55164"},
             {"label": "Boissano", "value": "C55165"},
             {"label": "Bolano", "value": "C55166"},
             {"label": "Bonassola", "value": "C55167"},
             {"label": "Bordighera", "value": "C55168"},
             {"label": "Borghetto dArroscia", "value": "C55169"},
             {"label": "Borghetto di Vara", "value": "C55170"},
             {"label": "Borghetto Santo Spirito", "value": "C55171"},
             {"label": "Borgio", "value": "C55172"},
             {"label": "Borgo di Ranzo", "value": "C55173"},
             {"label": "Borgo Fornari-Pieve", "value": "C55174"},
             {"label": "Borgomaro", "value": "C55175"},
             {"label": "Bormida", "value": "C55176"},
             {"label": "Bormida-Genepro", "value": "C55177"},
             {"label": "Borzonasca", "value": "C55178"},
             {"label": "Brugnato", "value": "C55179"},
             {"label": "Busalla", "value": "C55180"},
             {"label": "Cairo Montenotte", "value": "C55181"},
             {"label": "Calice al Cornoviglio", "value": "C55182"},
             {"label": "Calice Ligure", "value": "C55183"},
             {"label": "Calizzano", "value": "C55184"},
             {"label": "Camogli", "value": "C55185"},
             {"label": "Campo Ligure", "value": "C55186"},
             {"label": "Campochiesa", "value": "C55187"},
             {"label": "Campomorone", "value": "C55188"},
             {"label": "Camporosso", "value": "C55189"},
             {"label": "Carasco", "value": "C55190"},
             {"label": "Caravonica", "value": "C55191"},
             {"label": "Carcare", "value": "C55192"},
             {"label": "Carpasio", "value": "C55193"},
             {"label": "Carro", "value": "C55194"},
             {"label": "Carrodano", "value": "C55195"},
             {"label": "Casano-Dogana-Isola", "value": "C55196"},
             {"label": "Casanova Lerrone", "value": "C55197"},
             {"label": "Casarza Ligure", "value": "C55198"},
             {"label": "Casavecchia", "value": "C55199"},
             {"label": "Casella", "value": "C55200"},
             {"label": "Castel Vittorio", "value": "C55201"},
             {"label": "Castelbianco", "value": "C55202"},
             {"label": "Castellaro", "value": "C55203"},
             {"label": "Castelnuovo Magra", "value": "C55204"},
             {"label": "Castelvecchio di Rocca Barbena", "value": "C55205"},
             {"label": "Castiglione", "value": "C55206"},
             {"label": "Celle Ligure", "value": "C55207"},
             {"label": "Cengio Alto", "value": "C55208"},
             {"label": "Ceparana-Carpena", "value": "C55209"},
             {"label": "Ceranesi", "value": "C55210"},
             {"label": "Ceriale", "value": "C55211"},
             {"label": "Ceriana", "value": "C55212"},
             {"label": "Cervo", "value": "C55213"},
             {"label": "Cesio", "value": "C55214"},
             {"label": "Chiavari", "value": "C55215"},
             {"label": "Chiesa", "value": "C55216"},
             {"label": "Chiesa Nuova", "value": "C55217"},
             {"label": "Chiusanico", "value": "C55218"},
             {"label": "Chiusavecchia", "value": "C55219"},
             {"label": "Cicagna", "value": "C55220"},
             {"label": "Cipressa", "value": "C55221"},
             {"label": "Cisano", "value": "C55222"},
             {"label": "Civezza", "value": "C55223"},
             {"label": "Cogoleto", "value": "C55224"},
             {"label": "Cogorno", "value": "C55225"},
             {"label": "Colombiera-Molicciara", "value": "C55226"},
             {"label": "Conscenti", "value": "C55227"},
             {"label": "Coreglia Ligure", "value": "C55228"},
             {"label": "Cosio di Arroscia", "value": "C55229"},
             {"label": "Cosseria", "value": "C55230"},
             {"label": "Costarainera", "value": "C55231"},
             {"label": "Crocefieschi", "value": "C55232"},
             {"label": "Davagna", "value": "C55233"},
             {"label": "Dego", "value": "C55234"},
             {"label": "Deiva Marina", "value": "C55235"},
             {"label": "Diano Arentino", "value": "C55236"},
             {"label": "Diano Castello", "value": "C55237"},
             {"label": "Diano Marina", "value": "C55238"},
             {"label": "Diano San Pietro", "value": "C55239"},
             {"label": "Dolceacqua", "value": "C55240"},
             {"label": "Dolcedo", "value": "C55241"},
             {"label": "Erli", "value": "C55242"},
             {"label": "Fascia", "value": "C55243"},
             {"label": "Favale di Malvaro", "value": "C55244"},
             {"label": "Feglino", "value": "C55245"},
             {"label": "Ferrada", "value": "C55246"},
             {"label": "Ferriere", "value": "C55247"},
             {"label": "Finale Ligure", "value": "C55248"},
             {"label": "Fontanigorda", "value": "C55249"},
             {"label": "Framura", "value": "C55250"},
             {"label": "Garlenda", "value": "C55251"},
             {"label": "Genoa", "value": "C55252"},
             {"label": "Giustenice", "value": "C55253"},
             {"label": "Giusvalla", "value": "C55254"},
             {"label": "Gorreto", "value": "C55255"},
             {"label": "Imperia", "value": "C55256"},
             {"label": "Isola del Cantone", "value": "C55257"},
             {"label": "Isolabona", "value": "C55258"},
             {"label": "Isoverde", "value": "C55259"},
             {"label": "La Spezia", "value": "C55260"},
             {"label": "Laigueglia", "value": "C55261"},
             {"label": "Lavagna", "value": "C55262"},
             {"label": "Le Grazie", "value": "C55263"},
             {"label": "Leivi", "value": "C55264"},
             {"label": "Lerici", "value": "C55265"},
             {"label": "Levanto", "value": "C55266"},
             {"label": "Loano", "value": "C55267"},
             {"label": "Lorsica", "value": "C55268"},
             {"label": "Lucinasco", "value": "C55269"},
             {"label": "Magliolo", "value": "C55270"},
             {"label": "Maissana", "value": "C55271"},
             {"label": "Mallare", "value": "C55272"},
             {"label": "Manesseno", "value": "C55273"},
             {"label": "Marina di Andora", "value": "C55274"},
             {"label": "Masone", "value": "C55275"},
             {"label": "Massimino", "value": "C55276"},
             {"label": "Mele", "value": "C55277"},
             {"label": "Mendatica", "value": "C55278"},
             {"label": "Mezzanego", "value": "C55279"},
             {"label": "Mignanego", "value": "C55280"},
             {"label": "Millesimo", "value": "C55281"},
             {"label": "Mioglia", "value": "C55282"},
             {"label": "Moconesi", "value": "C55283"},
             {"label": "Molini", "value": "C55284"},
             {"label": "Molini di Triora", "value": "C55285"},
             {"label": "Molino Vecchio-Scapitola-Baio", "value": "C55286"},
             {"label": "Molino-Pera", "value": "C55287"},
             {"label": "Moneglia", "value": "C55288"},
             {"label": "Montalto Ligure", "value": "C55289"},
             {"label": "Montebruno", "value": "C55290"},
             {"label": "Montegrosso Pian Latte", "value": "C55291"},
             {"label": "Monterosso al Mare", "value": "C55292"},
             {"label": "Montoggio", "value": "C55293"},
             {"label": "Murialdo", "value": "C55294"},
             {"label": "Nasino-Borgo", "value": "C55295"},
             {"label": "Neirone", "value": "C55296"},
             {"label": "Noli", "value": "C55297"},
             {"label": "Olivetta San Michele", "value": "C55298"},
             {"label": "Onzo", "value": "C55299"},
             {"label": "Orco Feglino", "value": "C55300"},
             {"label": "Orero", "value": "C55301"},
             {"label": "Ortovero", "value": "C55302"},
             {"label": "Osiglia", "value": "C55303"},
             {"label": "Ospedaletti", "value": "C55304"},
             {"label": "Osteria dei Cacciatori-Stella", "value": "C55305"},
             {"label": "Padivarma", "value": "C55306"},
             {"label": "Pallare", "value": "C55307"},
             {"label": "Pedemonte", "value": "C55308"},
             {"label": "Perinaldo", "value": "C55309"},
             {"label": "Piana Battolla", "value": "C55310"},
             {"label": "Piana Crixia", "value": "C55311"},
             {"label": "Piano", "value": "C55312"},
             {"label": "Piano di Follo", "value": "C55313"},
             {"label": "Piccarello", "value": "C55314"},
             {"label": "Pietra Ligure", "value": "C55315"},
             {"label": "Pietrabruna", "value": "C55316"},
             {"label": "Pieve di Teco", "value": "C55317"},
             {"label": "Pieve di Zignago", "value": "C55318"},
             {"label": "Pieve Ligure", "value": "C55319"},
             {"label": "Pigna", "value": "C55320"},
             {"label": "Pignone", "value": "C55321"},
             {"label": "Pitelli", "value": "C55322"},
             {"label": "Plodio", "value": "C55323"},
             {"label": "Pompeiana", "value": "C55324"},
             {"label": "Pontedassio", "value": "C55325"},
             {"label": "Pontinvrea", "value": "C55326"},
             {"label": "Pornassio", "value": "C55327"},
             {"label": "Portofino", "value": "C55328"},
             {"label": "Portovenere", "value": "C55329"},
             {"label": "Prati", "value": "C55330"},
             {"label": "Prela Castello", "value": "C55331"},
             {"label": "Propata", "value": "C55332"},
             {"label": "Provincia di Genova", "value": "C55333"},
             {"label": "Provincia di Imperia", "value": "C55334"},
             {"label": "Provincia di La Spezia", "value": "C55335"},
             {"label": "Provincia di Savona", "value": "C55336"},
             {"label": "Quiliano", "value": "C55337"},
             {"label": "Rapallo", "value": "C55338"},
             {"label": "Recco", "value": "C55339"},
             {"label": "Rezzo", "value": "C55340"},
             {"label": "Rezzoaglio", "value": "C55341"},
             {"label": "Rialto", "value": "C55342"},
             {"label": "Ricco del Golfo", "value": "C55343"},
             {"label": "Riomaggiore", "value": "C55344"},
             {"label": "Riva Ligure", "value": "C55345"},
             {"label": "Roccavignale", "value": "C55346"},
             {"label": "Rocchetta di Vara", "value": "C55347"},
             {"label": "Rocchetta Nervina", "value": "C55348"},
             {"label": "Romito Magra", "value": "C55349"},
             {"label": "Ronco Scrivia", "value": "C55350"},
             {"label": "Rondanina", "value": "C55351"},
             {"label": "Rossi", "value": "C55352"},
             {"label": "Rossiglione", "value": "C55353"},
             {"label": "Rovegno", "value": "C55354"},
             {"label": "San Bartolomeo al Mare", "value": "C55355"},
             {"label": "San Biagio della Cima", "value": "C55356"},
             {"label": "San Fedele-Lusignano", "value": "C55357"},
             {"label": "San Giovanni", "value": "C55358"},
             {"label": "San Lorenzo", "value": "C55359"},
             {"label": "San Lorenzo al Mare", "value": "C55360"},
             {"label": "San Pietro dOlba", "value": "C55361"},
             {"label": "San Remo", "value": "C55362"},
             {"label": "San Salvatore", "value": "C55363"},
             {"label": "San Terenzo", "value": "C55364"},
             {"label": "Santa Margherita Ligure", "value": "C55365"},
             {"label": "Santa Marta", "value": "C55366"},
             {"label": "Santo Stefano al Mare", "value": "C55367"},
             {"label": "Santo Stefano dAveto", "value": "C55368"},
             {"label": "Santo Stefano di Magra", "value": "C55369"},
             {"label": "Sarzana", "value": "C55370"},
             {"label": "Sassello", "value": "C55371"},
             {"label": "Savignone", "value": "C55372"},
             {"label": "Savona", "value": "C55373"},
             {"label": "Sciarborasca", "value": "C55374"},
             {"label": "Seborga", "value": "C55375"},
             {"label": "Serra Ricco", "value": "C55376"},
             {"label": "Sesta Godano", "value": "C55377"},
             {"label": "Sestri Levante", "value": "C55378"},
             {"label": "Soldano", "value": "C55379"},
             {"label": "Sori", "value": "C55380"},
             {"label": "Spotorno", "value": "C55381"},
             {"label": "Stazione-Fornola", "value": "C55382"},
             {"label": "Stellanello", "value": "C55383"},
             {"label": "Taggia", "value": "C55384"},
             {"label": "Terzorio", "value": "C55385"},
             {"label": "Testico", "value": "C55386"},
             {"label": "Tiglieto", "value": "C55387"},
             {"label": "Toirano", "value": "C55388"},
             {"label": "Torriglia", "value": "C55389"},
             {"label": "Tovo San Giacomo", "value": "C55390"},
             {"label": "Tribogna", "value": "C55391"},
             {"label": "Triora", "value": "C55392"},
             {"label": "Urbe", "value": "C55393"},
             {"label": "Uscio", "value": "C55394"},
             {"label": "Vado Centro", "value": "C55395"},
             {"label": "Vado Ligure", "value": "C55396"},
             {"label": "Valle di Vado", "value": "C55397"},
             {"label": "Vallebona", "value": "C55398"},
             {"label": "Vallecrosia", "value": "C55399"},
             {"label": "Valleggia", "value": "C55400"},
             {"label": "Valzemola", "value": "C55401"},
             {"label": "Varazze", "value": "C55402"},
             {"label": "Varese Ligure", "value": "C55403"},
             {"label": "Vasia", "value": "C55404"},
             {"label": "Vendone", "value": "C55405"},
             {"label": "Ventimiglia", "value": "C55406"},
             {"label": "Vernazza", "value": "C55407"},
             {"label": "Vessalico", "value": "C55408"},
             {"label": "Vezzano Ligure", "value": "C55409"},
             {"label": "Vezzi Portio", "value": "C55410"},
             {"label": "Villa Faraldi", "value": "C55411"},
             {"label": "Villafranca", "value": "C55412"},
             {"label": "Villanova dAlbenga", "value": "C55413"},
             {"label": "Vobbia", "value": "C55414"},
             {"label": "Zoagli", "value": "C55415"},
             {"label": "Zuccarello", "value": "C55416"}],
 "Lija": [{"label": "Imtarfa", "value": "C65892"},
          {"label": "Lija", "value": "C65893"}],
 "Lika-Senj": [{"label": "Brinje", "value": "C21189"},
               {"label": "Gospic", "value": "C21190"},
               {"label": "Karlobag", "value": "C21191"},
               {"label": "Licki Osik", "value": "C21192"},
               {"label": "Novalja", "value": "C21193"},
               {"label": "Otocac", "value": "C21194"},
               {"label": "Perusic", "value": "C21195"},
               {"label": "Plitvicka Jezera", "value": "C21196"},
               {"label": "Popovaca", "value": "C21197"},
               {"label": "Senj", "value": "C21198"}],
 "Likouala Department": [{"label": "Impfondo", "value": "C20753"}],
 "Lima": [{"label": "Asentamiento Humano Nicolas de Pierola",
           "value": "C80925"},
          {"label": "Barranca", "value": "C80926"},
          {"label": "Caleta de Carquin", "value": "C80927"},
          {"label": "Chancay", "value": "C80928"},
          {"label": "Chilca", "value": "C80929"},
          {"label": "Chosica", "value": "C80930"},
          {"label": "Huacho", "value": "C80931"},
          {"label": "Hualmay", "value": "C80932"},
          {"label": "Huaral", "value": "C80933"},
          {"label": "Huaura", "value": "C80934"},
          {"label": "Imperial", "value": "C80935"},
          {"label": "Independencia", "value": "C80936"},
          {"label": "Lima", "value": "C80937"},
          {"label": "Mala", "value": "C80938"},
          {"label": "Matucana", "value": "C80939"},
          {"label": "Nuevo Imperial", "value": "C80940"},
          {"label": "Oyon", "value": "C80941"},
          {"label": "Paramonga", "value": "C80942"},
          {"label": "Pativilca", "value": "C80943"},
          {"label": "Provincia de Cajatambo", "value": "C80944"},
          {"label": "Provincia de Canete", "value": "C80945"},
          {"label": "Provincia de Canta", "value": "C80946"},
          {"label": "Provincia de Huaral", "value": "C80947"},
          {"label": "Provincia de Huarochiri", "value": "C80948"},
          {"label": "Provincia de Yauyos", "value": "C80949"},
          {"label": "Puerto Supe", "value": "C80950"},
          {"label": "Quilmana", "value": "C80951"},
          {"label": "Ricardo Palma", "value": "C80952"},
          {"label": "San Bartolo", "value": "C80953"},
          {"label": "San Isidro", "value": "C80954"},
          {"label": "San Luis", "value": "C80955"},
          {"label": "San Vicente de Canete", "value": "C80956"},
          {"label": "Santa Maria", "value": "C80957"},
          {"label": "Santiago de Surco", "value": "C80958"},
          {"label": "Sayan", "value": "C80959"},
          {"label": "Supe", "value": "C80960"},
          {"label": "Urb. Santo Domingo", "value": "C80961"},
          {"label": "Vegueta", "value": "C80962"}],
 "Limassol District (Leymasun)": [{"label": "agios Tomas", "value": "C21896"},
                                  {"label": "agios Tychon", "value": "C21897"},
                                  {"label": "Erimi", "value": "C21898"},
                                  {"label": "Germasogeia", "value": "C21899"},
                                  {"label": "Kyperounta", "value": "C21900"},
                                  {"label": "Lemesos", "value": "C21901"},
                                  {"label": "Limassol", "value": "C21902"},
                                  {"label": "Mouttagiaka", "value": "C21903"},
                                  {"label": "Pachna", "value": "C21904"},
                                  {"label": "Pano Polemidia",
                                   "value": "C21905"},
                                  {"label": "Parekklisha", "value": "C21906"},
                                  {"label": "Pelendri", "value": "C21907"},
                                  {"label": "Pissouri", "value": "C21908"},
                                  {"label": "Pyrgos", "value": "C21909"},
                                  {"label": "Sotira", "value": "C21910"},
                                  {"label": "Souni-Zanakia", "value": "C21911"},
                                  {"label": "Ypsonas", "value": "C21912"}],
 "Limbazi Municipality": [{"label": "Limbazi", "value": "C64895"}],
 "Limburg": [{"label": "Abdissenbosch", "value": "C76575"},
             {"label": "Amby", "value": "C76576"},
             {"label": "America", "value": "C76577"},
             {"label": "Amstenrade", "value": "C76578"},
             {"label": "Arcen", "value": "C76579"},
             {"label": "Baarlo", "value": "C76580"},
             {"label": "Banholt", "value": "C76581"},
             {"label": "Beegden", "value": "C76582"},
             {"label": "Beek", "value": "C76583"},
             {"label": "Beersdal", "value": "C76584"},
             {"label": "Beesel", "value": "C76585"},
             {"label": "Belfort", "value": "C76586"},
             {"label": "Berg", "value": "C76587"},
             {"label": "Beringe", "value": "C76588"},
             {"label": "Biesland", "value": "C76589"},
             {"label": "Bleijerheide", "value": "C76590"},
             {"label": "Blitterswijck", "value": "C76591"},
             {"label": "Bocholtz", "value": "C76592"},
             {"label": "Borgharen", "value": "C76593"},
             {"label": "Born", "value": "C76594"},
             {"label": "Brabander", "value": "C76595"},
             {"label": "Brachterbeek", "value": "C76596"},
             {"label": "Broekhem", "value": "C76597"},
             {"label": "Broeksittard", "value": "C76598"},
             {"label": "Brukske", "value": "C76599"},
             {"label": "Brunssum", "value": "C76600"},
             {"label": "Buchten", "value": "C76601"},
             {"label": "Budschop", "value": "C76602"},
             {"label": "Bunde", "value": "C76603"},
             {"label": "Chevremont", "value": "C76604"},
             {"label": "De Heeg", "value": "C76605"},
             {"label": "Doenrade", "value": "C76606"},
             {"label": "Egchel", "value": "C76607"},
             {"label": "Eijsden", "value": "C76608"},
             {"label": "Eijsden-Margraten", "value": "C76609"},
             {"label": "Einighausen", "value": "C76610"},
             {"label": "Elsloo", "value": "C76611"},
             {"label": "Geleen", "value": "C76612"},
             {"label": "Gennep", "value": "C76613"},
             {"label": "Gracht", "value": "C76614"},
             {"label": "Grashoek", "value": "C76615"},
             {"label": "Grevenbicht", "value": "C76616"},
             {"label": "Gronsveld", "value": "C76617"},
             {"label": "Gulpen", "value": "C76618"},
             {"label": "Gulpen-Wittem", "value": "C76619"},
             {"label": "Guttecoven", "value": "C76620"},
             {"label": "Haanrade", "value": "C76621"},
             {"label": "Haelen", "value": "C76622"},
             {"label": "Heel", "value": "C76623"},
             {"label": "Heer", "value": "C76624"},
             {"label": "Heerlen", "value": "C76625"},
             {"label": "Hegelsom", "value": "C76626"},
             {"label": "Heide", "value": "C76627"},
             {"label": "Heijen", "value": "C76628"},
             {"label": "Heksenberg", "value": "C76629"},
             {"label": "Helden", "value": "C76630"},
             {"label": "Herkenbosch", "value": "C76631"},
             {"label": "Herten", "value": "C76632"},
             {"label": "Heugem", "value": "C76633"},
             {"label": "Heythuysen", "value": "C76634"},
             {"label": "Hoensbroek", "value": "C76635"},
             {"label": "Holtum", "value": "C76636"},
             {"label": "Holz", "value": "C76637"},
             {"label": "Hopel", "value": "C76638"},
             {"label": "Houthem", "value": "C76639"},
             {"label": "Hulsberg", "value": "C76640"},
             {"label": "Husken", "value": "C76641"},
             {"label": "Kaalheide", "value": "C76642"},
             {"label": "Kakert", "value": "C76643"},
             {"label": "Keent", "value": "C76644"},
             {"label": "Kelpen-Oler", "value": "C76645"},
             {"label": "Kerensheide", "value": "C76646"},
             {"label": "Kerkrade", "value": "C76647"},
             {"label": "Kessel", "value": "C76648"},
             {"label": "Klimmen", "value": "C76649"},
             {"label": "Koningsbosch", "value": "C76650"},
             {"label": "Koningslust", "value": "C76651"},
             {"label": "Kruisberg", "value": "C76652"},
             {"label": "Kunrade", "value": "C76653"},
             {"label": "Landgraaf", "value": "C76654"},
             {"label": "Lauradorp", "value": "C76655"},
             {"label": "Leeuwen", "value": "C76656"},
             {"label": "Leuken", "value": "C76657"},
             {"label": "Leunen", "value": "C76658"},
             {"label": "Lichtenberg", "value": "C76659"},
             {"label": "Limbricht", "value": "C76660"},
             {"label": "Limmel", "value": "C76661"},
             {"label": "Lindenheuvel", "value": "C76662"},
             {"label": "Linne", "value": "C76663"},
             {"label": "Lottum", "value": "C76664"},
             {"label": "Maasbracht", "value": "C76665"},
             {"label": "Maasbree", "value": "C76666"},
             {"label": "Maastricht", "value": "C76667"},
             {"label": "Malberg", "value": "C76668"},
             {"label": "Margraten", "value": "C76669"},
             {"label": "Mariarade", "value": "C76670"},
             {"label": "Mechelen", "value": "C76671"},
             {"label": "Meerlo", "value": "C76672"},
             {"label": "Meerssen", "value": "C76673"},
             {"label": "Meezenbroek", "value": "C76674"},
             {"label": "Meijel", "value": "C76675"},
             {"label": "Melderslo", "value": "C76676"},
             {"label": "Melick", "value": "C76677"},
             {"label": "Merkelbeek", "value": "C76678"},
             {"label": "Merselo", "value": "C76679"},
             {"label": "Merum", "value": "C76680"},
             {"label": "Milsbeek", "value": "C76681"},
             {"label": "Moesel", "value": "C76682"},
             {"label": "Montfort", "value": "C76683"},
             {"label": "Mook", "value": "C76684"},
             {"label": "Munstergeleen", "value": "C76685"},
             {"label": "Nederweert", "value": "C76686"},
             {"label": "Neerbeek", "value": "C76687"},
             {"label": "Nieuw-Lotbroek", "value": "C76688"},
             {"label": "Nieuwdorp", "value": "C76689"},
             {"label": "Nuth", "value": "C76690"},
             {"label": "Obbicht", "value": "C76691"},
             {"label": "Offenbeek", "value": "C76692"},
             {"label": "Oirlo", "value": "C76693"},
             {"label": "Oirsbeek", "value": "C76694"},
             {"label": "Oostrum", "value": "C76695"},
             {"label": "Ophoven", "value": "C76696"},
             {"label": "Ospel", "value": "C76697"},
             {"label": "Ottersum", "value": "C76698"},
             {"label": "Oud-Caberg", "value": "C76699"},
             {"label": "Overhoven", "value": "C76700"},
             {"label": "Panningen", "value": "C76701"},
             {"label": "Passart", "value": "C76702"},
             {"label": "Peij", "value": "C76703"},
             {"label": "Posterholt", "value": "C76704"},
             {"label": "Pottenberg", "value": "C76705"},
             {"label": "Puth", "value": "C76706"},
             {"label": "Reuver", "value": "C76707"},
             {"label": "Roermond", "value": "C76708"},
             {"label": "Roosteren", "value": "C76709"},
             {"label": "Rothem", "value": "C76710"},
             {"label": "Scharn", "value": "C76711"},
             {"label": "Schilberg", "value": "C76712"},
             {"label": "Schimmert", "value": "C76713"},
             {"label": "Schinnen", "value": "C76714"},
             {"label": "Schinveld", "value": "C76715"},
             {"label": "Sevenum", "value": "C76716"},
             {"label": "Sibbe", "value": "C76717"},
             {"label": "Simpelveld", "value": "C76718"},
             {"label": "Sint Joost", "value": "C76719"},
             {"label": "Sint Odilienberg", "value": "C76720"},
             {"label": "Sittard", "value": "C76721"},
             {"label": "Spaubeek", "value": "C76722"},
             {"label": "Spekholzerheide", "value": "C76723"},
             {"label": "Stadbroek", "value": "C76724"},
             {"label": "Stein", "value": "C76725"},
             {"label": "Stramproy", "value": "C76726"},
             {"label": "Tegelen", "value": "C76727"},
             {"label": "Terwinselen", "value": "C76728"},
             {"label": "Thorn", "value": "C76729"},
             {"label": "Tienray", "value": "C76730"},
             {"label": "Ubachsberg", "value": "C76731"},
             {"label": "Ulestraten", "value": "C76732"},
             {"label": "Urmond", "value": "C76733"},
             {"label": "Vaals", "value": "C76734"},
             {"label": "Valkenburg", "value": "C76735"},
             {"label": "Valkenburg aan de Geul", "value": "C76736"},
             {"label": "Veltum", "value": "C76737"},
             {"label": "Venlo", "value": "C76738"},
             {"label": "Venray", "value": "C76739"},
             {"label": "Vijlen", "value": "C76740"},
             {"label": "Vlodrop", "value": "C76741"},
             {"label": "Voerendaal", "value": "C76742"},
             {"label": "Vrieheide", "value": "C76743"},
             {"label": "Wanssum", "value": "C76744"},
             {"label": "Waubach", "value": "C76745"},
             {"label": "Weert", "value": "C76746"},
             {"label": "Well", "value": "C76747"},
             {"label": "Wellerlooi", "value": "C76748"},
             {"label": "Wessem", "value": "C76749"},
             {"label": "Wijlre", "value": "C76750"},
             {"label": "Wijnandsrade", "value": "C76751"},
             {"label": "Wolder", "value": "C76752"},
             {"label": "Ysselsteyn", "value": "C76753"}],
 "Limon Province": [{"label": "Batan", "value": "C20825"},
                    {"label": "Guacimo", "value": "C20826"},
                    {"label": "Guapiles", "value": "C20827"},
                    {"label": "Limon", "value": "C20828"},
                    {"label": "Matina", "value": "C20829"},
                    {"label": "Pococi", "value": "C20830"},
                    {"label": "Pocora", "value": "C20831"},
                    {"label": "Roxana", "value": "C20832"},
                    {"label": "Siquirres", "value": "C20833"},
                    {"label": "Sixaola", "value": "C20834"},
                    {"label": "Talamanca", "value": "C20835"}],
 "Limpopo": [{"label": "Bochum", "value": "C108087"},
             {"label": "Capricorn District Municipality", "value": "C108088"},
             {"label": "Duiwelskloof", "value": "C108089"},
             {"label": "Ga-Kgapane", "value": "C108090"},
             {"label": "Giyani", "value": "C108091"},
             {"label": "Lebowakgomo", "value": "C108092"},
             {"label": "Louis Trichardt", "value": "C108093"},
             {"label": "Mankoeng", "value": "C108094"},
             {"label": "Modimolle", "value": "C108095"},
             {"label": "Mokopane", "value": "C108096"},
             {"label": "Mopani District Municipality", "value": "C108097"},
             {"label": "Musina", "value": "C108098"},
             {"label": "Nkowakowa", "value": "C108099"},
             {"label": "Phalaborwa", "value": "C108100"},
             {"label": "Polokwane", "value": "C108101"},
             {"label": "Sekhukhune District Municipality", "value": "C108102"},
             {"label": "Thabazimbi", "value": "C108103"},
             {"label": "Thohoyandou", "value": "C108104"},
             {"label": "Thulamahashi", "value": "C108105"},
             {"label": "Tzaneen", "value": "C108106"},
             {"label": "Vhembe District Municipality", "value": "C108107"},
             {"label": "Warmbaths", "value": "C108108"},
             {"label": "Waterberg District Municipality", "value": "C108109"}],
 "Lindi": [{"label": "Lindi", "value": "C118138"},
           {"label": "Mbekenyera", "value": "C118139"},
           {"label": "Mingoyo", "value": "C118140"},
           {"label": "Mtama", "value": "C118141"},
           {"label": "Nachingwea", "value": "C118142"},
           {"label": "Nyangao", "value": "C118143"},
           {"label": "Ruangwa", "value": "C118144"}],
 "Line Islands": [{"label": "Banana Village", "value": "C64677"},
                  {"label": "Kiritimati", "value": "C64678"},
                  {"label": "London Village", "value": "C64679"},
                  {"label": "Napari Village", "value": "C64680"},
                  {"label": "Tabuaeran", "value": "C64681"},
                  {"label": "Tabwakea Village", "value": "C64682"},
                  {"label": "Teraina", "value": "C64683"}],
 "Lipetsk Oblast": [{"label": "Bolshoy Khomutets", "value": "C102093"},
                    {"label": "Borinskoye", "value": "C102094"},
                    {"label": "Chaplygin", "value": "C102095"},
                    {"label": "Chaplyginskiy Rayon", "value": "C102096"},
                    {"label": "Dankov", "value": "C102097"},
                    {"label": "Dankovskiy Rayon", "value": "C102098"},
                    {"label": "Dobrinka", "value": "C102099"},
                    {"label": "Dobrinskiy Rayon", "value": "C102100"},
                    {"label": "Dobrovskiy Rayon", "value": "C102101"},
                    {"label": "Dobroye", "value": "C102102"},
                    {"label": "Dolgorukovo", "value": "C102103"},
                    {"label": "Dolgorukovskiy Rayon", "value": "C102104"},
                    {"label": "Donskoye", "value": "C102105"},
                    {"label": "Gryazi", "value": "C102106"},
                    {"label": "Gryazinskiy Rayon", "value": "C102107"},
                    {"label": "Izmalkovo", "value": "C102108"},
                    {"label": "Izmalkovskiy Rayon", "value": "C102109"},
                    {"label": "Kalikino", "value": "C102110"},
                    {"label": "Kazaki", "value": "C102111"},
                    {"label": "Kazinka", "value": "C102112"},
                    {"label": "Khlevenskiy Rayon", "value": "C102113"},
                    {"label": "Khlevnoye", "value": "C102114"},
                    {"label": "Krasninskiy Rayon", "value": "C102115"},
                    {"label": "Krasnoye", "value": "C102116"},
                    {"label": "Kuzminskiye Otverzhki", "value": "C102117"},
                    {"label": "Lebedyan", "value": "C102118"},
                    {"label": "Lebedyanskiy Rayon", "value": "C102119"},
                    {"label": "Lev Tolstoy", "value": "C102120"},
                    {"label": "Lev-Tolstovskiy Rayon", "value": "C102121"},
                    {"label": "Lipetsk", "value": "C102122"},
                    {"label": "Lipetskiy Rayon", "value": "C102123"},
                    {"label": "Petrovskiy", "value": "C102124"},
                    {"label": "Plekhanovo", "value": "C102125"},
                    {"label": "Prigorodka", "value": "C102126"},
                    {"label": "Stanovlyanskiy Rayon", "value": "C102127"},
                    {"label": "Stanovoye", "value": "C102128"},
                    {"label": "Syrskoye", "value": "C102129"},
                    {"label": "Terbunskiy Rayon", "value": "C102130"},
                    {"label": "Terbuny", "value": "C102131"},
                    {"label": "Usman", "value": "C102132"},
                    {"label": "Usmanskiy Rayon", "value": "C102133"},
                    {"label": "Volovo", "value": "C102134"},
                    {"label": "Volovskiy Rayon", "value": "C102135"},
                    {"label": "Yelets", "value": "C102136"},
                    {"label": "Yeletskiy Rayon", "value": "C102137"},
                    {"label": "Zadonsk", "value": "C102138"},
                    {"label": "Zadonskiy Rayon", "value": "C102139"}],
 "Lipkovo Municipality": [{"label": "Izvor", "value": "C65450"},
                          {"label": "Lipkovo", "value": "C65451"},
                          {"label": "Lojane", "value": "C65452"},
                          {"label": "Matejce", "value": "C65453"},
                          {"label": "Otlja", "value": "C65454"},
                          {"label": "Vaksince", "value": "C65455"},
                          {"label": "Slupchane", "value": "C65456"}],
 "Liquica Municipality": [{"label": "Bazartete", "value": "C24074"},
                          {"label": "Likisa", "value": "C24075"},
                          {"label": "Maubara", "value": "C24076"}],
 "Lisbon": [{"label": "A dos Cunhados", "value": "C91668"},
            {"label": "Abrigada", "value": "C91669"},
            {"label": "Alcabideche", "value": "C91670"},
            {"label": "Alcoentre", "value": "C91671"},
            {"label": "Alenquer", "value": "C91672"},
            {"label": "Alfragide", "value": "C91673"},
            {"label": "Alges", "value": "C91674"},
            {"label": "Algueirao", "value": "C91675"},
            {"label": "Alhandra", "value": "C91676"},
            {"label": "Almargem", "value": "C91677"},
            {"label": "Alvalade", "value": "C91678"},
            {"label": "Amadora", "value": "C91679"},
            {"label": "Apelacao", "value": "C91680"},
            {"label": "Arranho", "value": "C91681"},
            {"label": "Arruda dos Vinhos", "value": "C91682"},
            {"label": "Aveiras de Cima", "value": "C91683"},
            {"label": "Azambuja", "value": "C91684"},
            {"label": "Barcarena", "value": "C91685"},
            {"label": "Beato Antonio", "value": "C91686"},
            {"label": "Belas", "value": "C91687"},
            {"label": "Benfica", "value": "C91688"},
            {"label": "Bobadela", "value": "C91689"},
            {"label": "Bucelas", "value": "C91690"},
            {"label": "Cabanas de Torres", "value": "C91691"},
            {"label": "Cacem", "value": "C91692"},
            {"label": "Cadafais", "value": "C91693"},
            {"label": "Cadaval", "value": "C91694"},
            {"label": "Camarate", "value": "C91695"},
            {"label": "Campelos", "value": "C91696"},
            {"label": "Canecas", "value": "C91697"},
            {"label": "Carcavelos", "value": "C91698"},
            {"label": "Carnaxide", "value": "C91699"},
            {"label": "Carregado", "value": "C91700"},
            {"label": "Cascais", "value": "C91701"},
            {"label": "Castanheira do Ribatejo", "value": "C91702"},
            {"label": "Caxias", "value": "C91703"},
            {"label": "Charneca", "value": "C91704"},
            {"label": "Colares", "value": "C91705"},
            {"label": "Ericeira", "value": "C91706"},
            {"label": "Estoril", "value": "C91707"},
            {"label": "Famoes", "value": "C91708"},
            {"label": "Fontanelas", "value": "C91709"},
            {"label": "Linda-a-Velha", "value": "C91710"},
            {"label": "Lisbon", "value": "C91711"},
            {"label": "Loures", "value": "C91712"},
            {"label": "Lourinha", "value": "C91713"},
            {"label": "Mafra", "value": "C91714"},
            {"label": "Malveira", "value": "C91715"},
            {"label": "Massama", "value": "C91716"},
            {"label": "Meca", "value": "C91717"},
            {"label": "Mem Martins", "value": "C91718"},
            {"label": "Milharado", "value": "C91719"},
            {"label": "Moita dos Ferreiros", "value": "C91720"},
            {"label": "Monte Estoril", "value": "C91721"},
            {"label": "Moscavide e Portela", "value": "C91722"},
            {"label": "Odivelas", "value": "C91723"},
            {"label": "Oeiras", "value": "C91724"},
            {"label": "Olivais", "value": "C91725"},
            {"label": "Olival do Basto", "value": "C91726"},
            {"label": "Ota", "value": "C91727"},
            {"label": "Paco de Arcos", "value": "C91728"},
            {"label": "Parede", "value": "C91729"},
            {"label": "Pero Pinheiro", "value": "C91730"},
            {"label": "Pontinha", "value": "C91731"},
            {"label": "Porto Salvo", "value": "C91732"},
            {"label": "Povoa de Santa Iria", "value": "C91733"},
            {"label": "Povoa de Santo Adriao", "value": "C91734"},
            {"label": "Prior Velho", "value": "C91735"},
            {"label": "Queijas", "value": "C91736"},
            {"label": "Queluz", "value": "C91737"},
            {"label": "Ramada", "value": "C91738"},
            {"label": "Rio de Mouro", "value": "C91739"},
            {"label": "Sacavem", "value": "C91740"},
            {"label": "Santa Iria da Azoia", "value": "C91741"},
            {"label": "Santo Antao do Tojal", "value": "C91742"},
            {"label": "Santo Isidoro", "value": "C91743"},
            {"label": "Santos-o-Velho", "value": "C91744"},
            {"label": "Sao Bartolomeu", "value": "C91745"},
            {"label": "Sao Domingos de Rana", "value": "C91746"},
            {"label": "Sao Joao da Talha", "value": "C91747"},
            {"label": "Sao Joao das Lampas", "value": "C91748"},
            {"label": "Sao Joao dos Montes", "value": "C91749"},
            {"label": "Sao Pedro da Cadeira", "value": "C91750"},
            {"label": "Silveira", "value": "C91751"},
            {"label": "Sintra", "value": "C91752"},
            {"label": "Sobral de Monte Agraco", "value": "C91753"},
            {"label": "Sobralinho", "value": "C91754"},
            {"label": "Terrugem", "value": "C91755"},
            {"label": "Torres Vedras", "value": "C91756"},
            {"label": "Unhos", "value": "C91757"},
            {"label": "Venda do Pinheiro", "value": "C91758"},
            {"label": "Ventosa", "value": "C91759"},
            {"label": "Vialonga", "value": "C91760"},
            {"label": "Vila Franca de Xira", "value": "C91761"},
            {"label": "Vila Verde", "value": "C91762"},
            {"label": "Vimeiro", "value": "C91763"}],
 "Litija Municipality": [{"label": "Litija", "value": "C107689"}],
 "Litoral Province": [{"label": "Bata", "value": "C24476"},
                      {"label": "Bitica", "value": "C24477"},
                      {"label": "Cogo", "value": "C24478"},
                      {"label": "Machinda", "value": "C24479"},
                      {"label": "Mbini", "value": "C24480"},
                      {"label": "Rio Campo", "value": "C24481"}],
 "Littoral": [{"label": "Bonaberi", "value": "C16626"},
              {"label": "Diang", "value": "C16627"},
              {"label": "Dibombari", "value": "C16628"},
              {"label": "Dizangue", "value": "C16629"},
              {"label": "Douala", "value": "C16630"},
              {"label": "Edea", "value": "C16631"},
              {"label": "Loum", "value": "C16632"},
              {"label": "Manjo", "value": "C16633"},
              {"label": "Mbanga", "value": "C16634"},
              {"label": "Melong", "value": "C16635"},
              {"label": "Mouanko", "value": "C16636"},
              {"label": "Ndom", "value": "C16637"},
              {"label": "Ngambe", "value": "C16638"},
              {"label": "Nkongsamba", "value": "C16639"},
              {"label": "Penja", "value": "C16640"},
              {"label": "Yabassi", "value": "C16641"}],
 "Littoral Department": [{"label": "Cotonou", "value": "C9619"}],
 "Livani Municipality": [{"label": "Livani", "value": "C64896"}],
 "Ljubljana City Municipality": [{"label": "Dravlje District",
                                  "value": "C107690"},
                                 {"label": "Jarse District",
                                  "value": "C107691"},
                                 {"label": "Ljubljana", "value": "C107692"},
                                 {"label": "Opcina Ljubljana-Bezigrad",
                                  "value": "C107693"},
                                 {"label": "Opstina Ljubljana-Center",
                                  "value": "C107694"},
                                 {"label": "Opstina Ljubljana-Moste-Polje",
                                  "value": "C107695"},
                                 {"label": "Roznik District",
                                  "value": "C107696"},
                                 {"label": "Sentvid District",
                                  "value": "C107697"},
                                 {"label": "Sostro District",
                                  "value": "C107698"},
                                 {"label": "Trnovo District",
                                  "value": "C107699"},
                                 {"label": "Vic District", "value": "C107700"}],
 "Ljubno Municipality": [{"label": "Ljubno ob Savinji", "value": "C107701"}],
 "Ljutomer Municipality": [{"label": "Ljutomer", "value": "C107702"}],
 "Lobaye Prefecture": [{"label": "Boda", "value": "C17819"},
                       {"label": "Boganangone", "value": "C17820"},
                       {"label": "Mbaiki", "value": "C17821"},
                       {"label": "Mongoumba", "value": "C17822"}],
 "Lodz Voivodeship": [{"label": "Aleksandrow", "value": "C88962"},
                      {"label": "Aleksandrow Lodzki", "value": "C88963"},
                      {"label": "Andrespol", "value": "C88964"},
                      {"label": "Bedkow", "value": "C88965"},
                      {"label": "Bedlno", "value": "C88966"},
                      {"label": "Belchatow", "value": "C88967"},
                      {"label": "Biala Rawska", "value": "C88968"},
                      {"label": "Bialaczow", "value": "C88969"},
                      {"label": "Bielawy", "value": "C88970"},
                      {"label": "Blaszki", "value": "C88971"},
                      {"label": "Bobrowniki", "value": "C88972"},
                      {"label": "Boleslawiec", "value": "C88973"},
                      {"label": "Bolimow", "value": "C88974"},
                      {"label": "Braszewice", "value": "C88975"},
                      {"label": "Brojce", "value": "C88976"},
                      {"label": "Brzeziny", "value": "C88977"},
                      {"label": "Brzeznio", "value": "C88978"},
                      {"label": "Buczek", "value": "C88979"},
                      {"label": "Budziszewice", "value": "C88980"},
                      {"label": "Burzenin", "value": "C88981"},
                      {"label": "Chasno", "value": "C88982"},
                      {"label": "Cieladz", "value": "C88983"},
                      {"label": "Czarnocin", "value": "C88984"},
                      {"label": "Czarnozyly", "value": "C88985"},
                      {"label": "Czastary", "value": "C88986"},
                      {"label": "Czerniewice", "value": "C88987"},
                      {"label": "Dabrowice", "value": "C88988"},
                      {"label": "Dalikow", "value": "C88989"},
                      {"label": "Daszyna", "value": "C88990"},
                      {"label": "Dlutow", "value": "C88991"},
                      {"label": "Dmosin", "value": "C88992"},
                      {"label": "Dobron", "value": "C88993"},
                      {"label": "Domaniewice", "value": "C88994"},
                      {"label": "Druzbice", "value": "C88995"},
                      {"label": "Drzewica", "value": "C88996"},
                      {"label": "Dzialoszyn", "value": "C88997"},
                      {"label": "Galewice", "value": "C88998"},
                      {"label": "Galkow Maly", "value": "C88999"},
                      {"label": "Gidle", "value": "C89000"},
                      {"label": "Glowno", "value": "C89001"},
                      {"label": "Gluchow", "value": "C89002"},
                      {"label": "Godzianow", "value": "C89003"},
                      {"label": "Gomunice", "value": "C89004"},
                      {"label": "Gora Swietej Malgorzaty", "value": "C89005"},
                      {"label": "Gorzkowice", "value": "C89006"},
                      {"label": "Goszczanow", "value": "C89007"},
                      {"label": "Grabica", "value": "C89008"},
                      {"label": "Grabow", "value": "C89009"},
                      {"label": "Inowlodz", "value": "C89010"},
                      {"label": "Jezow", "value": "C89011"},
                      {"label": "Kamiensk", "value": "C89012"},
                      {"label": "Kaweczyn Nowy", "value": "C89013"},
                      {"label": "Kiernozia", "value": "C89014"},
                      {"label": "Kleszczow", "value": "C89015"},
                      {"label": "Klonowa", "value": "C89016"},
                      {"label": "Kluki", "value": "C89017"},
                      {"label": "Kocierzew Poludniowy", "value": "C89018"},
                      {"label": "Koluszki", "value": "C89019"},
                      {"label": "Konstantynow Lodzki", "value": "C89020"},
                      {"label": "Kowiesy", "value": "C89021"},
                      {"label": "Krosniewice", "value": "C89022"},
                      {"label": "Krzyzanow", "value": "C89023"},
                      {"label": "Ksawerow", "value": "C89024"},
                      {"label": "Kutno", "value": "C89025"},
                      {"label": "Lgota Wielka", "value": "C89026"},
                      {"label": "Lipce Reymontowskie", "value": "C89027"},
                      {"label": "Lubochnia", "value": "C89028"},
                      {"label": "Lutomiersk", "value": "C89029"},
                      {"label": "Lututow", "value": "C89030"},
                      {"label": "Lanieta", "value": "C89031"},
                      {"label": "Lask", "value": "C89032"},
                      {"label": "Leczyca", "value": "C89033"},
                      {"label": "Leki Szlacheckie", "value": "C89034"},
                      {"label": "Lodz", "value": "C89035"},
                      {"label": "Lowicz", "value": "C89036"},
                      {"label": "Lubnice", "value": "C89037"},
                      {"label": "Lyszkowice", "value": "C89038"},
                      {"label": "Makow", "value": "C89039"},
                      {"label": "Mniszkow", "value": "C89040"},
                      {"label": "Mokrsko", "value": "C89041"},
                      {"label": "Moszczenica", "value": "C89042"},
                      {"label": "Nieborow", "value": "C89043"},
                      {"label": "Nowe Ostrowy", "value": "C89044"},
                      {"label": "Opoczno", "value": "C89045"},
                      {"label": "Oporow", "value": "C89046"},
                      {"label": "Osjakow", "value": "C89047"},
                      {"label": "Ostrowy", "value": "C89048"},
                      {"label": "Ozarow", "value": "C89049"},
                      {"label": "Ozorkow", "value": "C89050"},
                      {"label": "Pabianice", "value": "C89051"},
                      {"label": "Pajeczno", "value": "C89052"},
                      {"label": "Paradyz", "value": "C89053"},
                      {"label": "Parzeczew", "value": "C89054"},
                      {"label": "Patnow", "value": "C89055"},
                      {"label": "Peczniew", "value": "C89056"},
                      {"label": "Piatek", "value": "C89057"},
                      {"label": "Piotrkow Trybunalski", "value": "C89058"},
                      {"label": "Plawno", "value": "C89059"},
                      {"label": "Poddebice", "value": "C89060"},
                      {"label": "Poswietne", "value": "C89061"},
                      {"label": "Powiat belchatowski", "value": "C89062"},
                      {"label": "Powiat brzezinski", "value": "C89063"},
                      {"label": "Powiat kutnowski", "value": "C89064"},
                      {"label": "Powiat laski", "value": "C89065"},
                      {"label": "Powiat leczycki", "value": "C89066"},
                      {"label": "Powiat lodzki wschodni", "value": "C89067"},
                      {"label": "powiat Lowicki", "value": "C89068"},
                      {"label": "Powiat opoczynski", "value": "C89069"},
                      {"label": "Powiat pabianicki", "value": "C89070"},
                      {"label": "Powiat pajeczanski", "value": "C89071"},
                      {"label": "Powiat piotrkowski", "value": "C89072"},
                      {"label": "Powiat poddebicki", "value": "C89073"},
                      {"label": "Powiat radomszczanski", "value": "C89074"},
                      {"label": "Powiat rawski", "value": "C89075"},
                      {"label": "Powiat sieradzki", "value": "C89076"},
                      {"label": "Powiat skierniewicki", "value": "C89077"},
                      {"label": "Powiat tomaszowski", "value": "C89078"},
                      {"label": "Powiat wielunski", "value": "C89079"},
                      {"label": "Powiat wieruszowski", "value": "C89080"},
                      {"label": "Powiat zdunskowolski", "value": "C89081"},
                      {"label": "Powiat zgierski", "value": "C89082"},
                      {"label": "Przedborz", "value": "C89083"},
                      {"label": "Radomsko", "value": "C89084"},
                      {"label": "Rawa Mazowiecka", "value": "C89085"},
                      {"label": "Reczno", "value": "C89086"},
                      {"label": "Regnow", "value": "C89087"},
                      {"label": "Rogow", "value": "C89088"},
                      {"label": "Rokiciny", "value": "C89089"},
                      {"label": "Rokiciny-Kolonia", "value": "C89090"},
                      {"label": "Rozprza", "value": "C89091"},
                      {"label": "Rusiec", "value": "C89092"},
                      {"label": "Rzeczyca", "value": "C89093"},
                      {"label": "Rzgow", "value": "C89094"},
                      {"label": "Sadkowice", "value": "C89095"},
                      {"label": "Sedziejowice", "value": "C89096"},
                      {"label": "Siemkowice", "value": "C89097"},
                      {"label": "Sieradz", "value": "C89098"},
                      {"label": "Skierniewice", "value": "C89099"},
                      {"label": "Skomlin", "value": "C89100"},
                      {"label": "Slawno", "value": "C89101"},
                      {"label": "Slupia", "value": "C89102"},
                      {"label": "Sokolniki", "value": "C89103"},
                      {"label": "Starowa Gora", "value": "C89104"},
                      {"label": "Strykow", "value": "C89105"},
                      {"label": "Strzelce", "value": "C89106"},
                      {"label": "Strzelce Wielkie", "value": "C89107"},
                      {"label": "Sulejow", "value": "C89108"},
                      {"label": "Sulmierzyce", "value": "C89109"},
                      {"label": "Swinice Warckie", "value": "C89110"},
                      {"label": "Szadek", "value": "C89111"},
                      {"label": "Szczercow", "value": "C89112"},
                      {"label": "Tomaszow Mazowiecki", "value": "C89113"},
                      {"label": "Tuszyn", "value": "C89114"},
                      {"label": "Ujazd", "value": "C89115"},
                      {"label": "Uniejow", "value": "C89116"},
                      {"label": "Walichnowy", "value": "C89117"},
                      {"label": "Warta", "value": "C89118"},
                      {"label": "Wartkowice", "value": "C89119"},
                      {"label": "Widawa", "value": "C89120"},
                      {"label": "Wielun", "value": "C89121"},
                      {"label": "Wieruszow", "value": "C89122"},
                      {"label": "Wierzchlas", "value": "C89123"},
                      {"label": "Witonia", "value": "C89124"},
                      {"label": "Wodzierady", "value": "C89125"},
                      {"label": "Wola Krzysztoporska", "value": "C89126"},
                      {"label": "Wolborz", "value": "C89127"},
                      {"label": "Wroblew", "value": "C89128"},
                      {"label": "Zadzim", "value": "C89129"},
                      {"label": "Zapolice", "value": "C89130"},
                      {"label": "Zarnow", "value": "C89131"},
                      {"label": "Zdunska Wola", "value": "C89132"},
                      {"label": "Zelechlinek", "value": "C89133"},
                      {"label": "Zelow", "value": "C89134"},
                      {"label": "Zgierz", "value": "C89135"},
                      {"label": "Zloczew", "value": "C89136"},
                      {"label": "Zychlin", "value": "C89137"}],
 "Loei": [{"label": "Amphoe Chiang Khan", "value": "C118739"},
          {"label": "Amphoe Dan Sai", "value": "C118740"},
          {"label": "Amphoe Erawan", "value": "C118741"},
          {"label": "Amphoe Mueang Loei", "value": "C118742"},
          {"label": "Amphoe Na Duang", "value": "C118743"},
          {"label": "Amphoe Na Haeo", "value": "C118744"},
          {"label": "Amphoe Nong Hin", "value": "C118745"},
          {"label": "Amphoe Pak Chom", "value": "C118746"},
          {"label": "Amphoe Pha Khao", "value": "C118747"},
          {"label": "Amphoe Phu Kradueng", "value": "C118748"},
          {"label": "Amphoe Phu Luang", "value": "C118749"},
          {"label": "Amphoe Phu Ruea", "value": "C118750"},
          {"label": "Amphoe Tha Li", "value": "C118751"},
          {"label": "Amphoe Wang Saphung", "value": "C118752"},
          {"label": "Loei", "value": "C118753"},
          {"label": "Phu Kradueng", "value": "C118754"},
          {"label": "Wang Saphung", "value": "C118755"}],
 "Lofa County": [{"label": "Voinjama", "value": "C65009"}],
 "Logar": [{"label": "Baraki Barak", "value": "C62"},
           {"label": "Hukumati Azrah", "value": "C63"},
           {"label": "Pul-e Alam", "value": "C64"}],
 "Logatec Municipality": [{"label": "Logatec", "value": "C107703"}],
 "Logone Occidental Region": [{"label": "Beinamar", "value": "C17864"},
                              {"label": "Benoy", "value": "C17865"},
                              {"label": "Lac Wey", "value": "C17866"},
                              {"label": "Moundou", "value": "C17867"}],
 "Logone Oriental Region": [{"label": "Bebedja", "value": "C17868"},
                            {"label": "Beboto", "value": "C17869"},
                            {"label": "Doba", "value": "C17870"}],
 "Loja": [{"label": "Loja", "value": "C24156"}],
 "Lomami": [{"label": "Lubao", "value": "C23346"},
            {"label": "Mwene-Ditu", "value": "C23347"}],
 "Lombardy": [{"label": "Abbadia Cerreto", "value": "C55417"},
              {"label": "Abbadia Lariana", "value": "C55418"},
              {"label": "Abbazia", "value": "C55419"},
              {"label": "Abbiategrasso", "value": "C55420"},
              {"label": "Acquafredda", "value": "C55421"},
              {"label": "Acquanegra Cremonese", "value": "C55422"},
              {"label": "Acquanegra sul Chiese", "value": "C55423"},
              {"label": "Adrara San Martino", "value": "C55424"},
              {"label": "Adrara San Rocco", "value": "C55425"},
              {"label": "Adro", "value": "C55426"},
              {"label": "Agnadello", "value": "C55427"},
              {"label": "Agnosine", "value": "C55428"},
              {"label": "Agra", "value": "C55429"},
              {"label": "Agrate Brianza", "value": "C55430"},
              {"label": "Aicurzio", "value": "C55431"},
              {"label": "Airuno", "value": "C55432"},
              {"label": "Alagna", "value": "C55433"},
              {"label": "Albairate", "value": "C55434"},
              {"label": "Albano SantAlessandro", "value": "C55435"},
              {"label": "Albaredo", "value": "C55436"},
              {"label": "Albaredo Arnaboldi", "value": "C55437"},
              {"label": "Albavilla", "value": "C55438"},
              {"label": "Albese Con Cassano", "value": "C55439"},
              {"label": "Albiate", "value": "C55440"},
              {"label": "Albignano", "value": "C55441"},
              {"label": "Albino", "value": "C55442"},
              {"label": "Albiolo", "value": "C55443"},
              {"label": "Albizzate", "value": "C55444"},
              {"label": "Albonese", "value": "C55445"},
              {"label": "Albosaggia", "value": "C55446"},
              {"label": "Albuzzano", "value": "C55447"},
              {"label": "Alfianello", "value": "C55448"},
              {"label": "Algua", "value": "C55449"},
              {"label": "Alme", "value": "C55450"},
              {"label": "Almenno San Bartolomeo", "value": "C55451"},
              {"label": "Almenno San Salvatore", "value": "C55452"},
              {"label": "Alserio", "value": "C55453"},
              {"label": "Alzano Lombardo", "value": "C55454"},
              {"label": "Alzate Brianza", "value": "C55455"},
              {"label": "Ambivere", "value": "C55456"},
              {"label": "Andalo Valtellino", "value": "C55457"},
              {"label": "Anfo", "value": "C55458"},
              {"label": "Angera", "value": "C55459"},
              {"label": "Angolo Terme", "value": "C55460"},
              {"label": "Annicco", "value": "C55461"},
              {"label": "Annone di Brianza", "value": "C55462"},
              {"label": "Antegnate", "value": "C55463"},
              {"label": "Anzano del Parco", "value": "C55464"},
              {"label": "Appiano Gentile", "value": "C55465"},
              {"label": "Aprica", "value": "C55466"},
              {"label": "Arcene", "value": "C55467"},
              {"label": "Arcisate", "value": "C55468"},
              {"label": "Arconate", "value": "C55469"},
              {"label": "Arcore", "value": "C55470"},
              {"label": "Ardenno", "value": "C55471"},
              {"label": "Ardesio", "value": "C55472"},
              {"label": "Ardole San Marino", "value": "C55473"},
              {"label": "Arena Po", "value": "C55474"},
              {"label": "Arese", "value": "C55475"},
              {"label": "Argegno", "value": "C55476"},
              {"label": "Arlate", "value": "C55477"},
              {"label": "Arluno", "value": "C55478"},
              {"label": "Arosio", "value": "C55479"},
              {"label": "Arsago Seprio", "value": "C55480"},
              {"label": "Artogne", "value": "C55481"},
              {"label": "Arzago dAdda", "value": "C55482"},
              {"label": "Asola", "value": "C55483"},
              {"label": "Assago", "value": "C55484"},
              {"label": "Asso", "value": "C55485"},
              {"label": "Aurogna", "value": "C55486"},
              {"label": "Averara", "value": "C55487"},
              {"label": "Aviatico", "value": "C55488"},
              {"label": "Azzanello", "value": "C55489"},
              {"label": "Azzano", "value": "C55490"},
              {"label": "Azzano Mella", "value": "C55491"},
              {"label": "Azzano San Paolo", "value": "C55492"},
              {"label": "Azzate", "value": "C55493"},
              {"label": "Azzio", "value": "C55494"},
              {"label": "Azzone", "value": "C55495"},
              {"label": "Badalasco", "value": "C55496"},
              {"label": "Badia Pavese", "value": "C55497"},
              {"label": "Badile", "value": "C55498"},
              {"label": "Bagnaria", "value": "C55499"},
              {"label": "Bagnatica", "value": "C55500"},
              {"label": "Bagnolo Cremasco", "value": "C55501"},
              {"label": "Bagnolo Mella", "value": "C55502"},
              {"label": "Bagnolo San Vito", "value": "C55503"},
              {"label": "Bagolino", "value": "C55504"},
              {"label": "Ballabio", "value": "C55505"},
              {"label": "Baraggia", "value": "C55506"},
              {"label": "Baranzate", "value": "C55507"},
              {"label": "Barasso", "value": "C55508"},
              {"label": "Barbariga", "value": "C55509"},
              {"label": "Barbata", "value": "C55510"},
              {"label": "Barbianello", "value": "C55511"},
              {"label": "Bardello", "value": "C55512"},
              {"label": "Bareggio", "value": "C55513"},
              {"label": "Bargano", "value": "C55514"},
              {"label": "Barghe", "value": "C55515"},
              {"label": "Bariano", "value": "C55516"},
              {"label": "Barlassina", "value": "C55517"},
              {"label": "Barni", "value": "C55518"},
              {"label": "Barzago", "value": "C55519"},
              {"label": "Barzana", "value": "C55520"},
              {"label": "Barzano", "value": "C55521"},
              {"label": "Barzio", "value": "C55522"},
              {"label": "Bascape", "value": "C55523"},
              {"label": "Basiano", "value": "C55524"},
              {"label": "Basiglio", "value": "C55525"},
              {"label": "Bassano Bresciano", "value": "C55526"},
              {"label": "Bastida de Dossi", "value": "C55527"},
              {"label": "Bastida Pancarana", "value": "C55528"},
              {"label": "Battaglione-Bagnara", "value": "C55529"},
              {"label": "Battuda", "value": "C55530"},
              {"label": "Bedero", "value": "C55531"},
              {"label": "Bedero Valcuvia", "value": "C55532"},
              {"label": "Bedizzole", "value": "C55533"},
              {"label": "Bedulita", "value": "C55534"},
              {"label": "Belforte", "value": "C55535"},
              {"label": "Belgioioso", "value": "C55536"},
              {"label": "Bellagio", "value": "C55537"},
              {"label": "Bellano", "value": "C55538"},
              {"label": "Bellinzago Lombardo", "value": "C55539"},
              {"label": "Bellusco", "value": "C55540"},
              {"label": "Bema", "value": "C55541"},
              {"label": "Bene Lario", "value": "C55542"},
              {"label": "Berbenno", "value": "C55543"},
              {"label": "Berbenno di Valtellina", "value": "C55544"},
              {"label": "Beregazzo", "value": "C55545"},
              {"label": "Beregazzo con Figliaro", "value": "C55546"},
              {"label": "Bereguardo", "value": "C55547"},
              {"label": "Bergamo", "value": "C55548"},
              {"label": "Berlingo", "value": "C55549"},
              {"label": "Bernareggio", "value": "C55550"},
              {"label": "Bernate Ticino", "value": "C55551"},
              {"label": "Bertonico", "value": "C55552"},
              {"label": "Berzo", "value": "C55553"},
              {"label": "Berzo Inferiore", "value": "C55554"},
              {"label": "Berzo San Fermo", "value": "C55555"},
              {"label": "Besana in Brianza", "value": "C55556"},
              {"label": "Besano", "value": "C55557"},
              {"label": "Besate", "value": "C55558"},
              {"label": "Besnate", "value": "C55559"},
              {"label": "Besozzo", "value": "C55560"},
              {"label": "Bettola", "value": "C55561"},
              {"label": "Bettola-Zeloforomagno", "value": "C55562"},
              {"label": "Biandronno", "value": "C55563"},
              {"label": "Bianzano", "value": "C55564"},
              {"label": "Bianzone", "value": "C55565"},
              {"label": "Biassono", "value": "C55566"},
              {"label": "Bienno", "value": "C55567"},
              {"label": "Binago", "value": "C55568"},
              {"label": "Binasco", "value": "C55569"},
              {"label": "Bione", "value": "C55570"},
              {"label": "Bisuschio", "value": "C55571"},
              {"label": "Bizzarone", "value": "C55572"},
              {"label": "Blello", "value": "C55573"},
              {"label": "Blessagno", "value": "C55574"},
              {"label": "Blevio", "value": "C55575"},
              {"label": "Bodio", "value": "C55576"},
              {"label": "Bodio Lomnago", "value": "C55577"},
              {"label": "Boffalora dAdda", "value": "C55578"},
              {"label": "Boffalora Sopra Ticino", "value": "C55579"},
              {"label": "Bolgare", "value": "C55580"},
              {"label": "Bolladello-Peveranza", "value": "C55581"},
              {"label": "Bollate", "value": "C55582"},
              {"label": "Boltiere", "value": "C55583"},
              {"label": "Bonate Sopra", "value": "C55584"},
              {"label": "Bonate Sotto", "value": "C55585"},
              {"label": "Bondeno", "value": "C55586"},
              {"label": "Bondione", "value": "C55587"},
              {"label": "Bonemerse", "value": "C55588"},
              {"label": "Bordolano", "value": "C55589"},
              {"label": "Borgarello", "value": "C55590"},
              {"label": "Borghetto Lodigiano", "value": "C55591"},
              {"label": "Borgo di Terzo", "value": "C55592"},
              {"label": "Borgo Priolo", "value": "C55593"},
              {"label": "Borgo San Giacomo", "value": "C55594"},
              {"label": "Borgo San Giovanni", "value": "C55595"},
              {"label": "Borgo San Siro", "value": "C55596"},
              {"label": "Borgoforte", "value": "C55597"},
              {"label": "Borgofranco sul Po", "value": "C55598"},
              {"label": "Borgoratto Mormorolo", "value": "C55599"},
              {"label": "Borgosatollo", "value": "C55600"},
              {"label": "Bormio", "value": "C55601"},
              {"label": "Bornasco", "value": "C55602"},
              {"label": "Bornato", "value": "C55603"},
              {"label": "Borno", "value": "C55604"},
              {"label": "Boschetto", "value": "C55605"},
              {"label": "Bosco Ex Parmigiano", "value": "C55606"},
              {"label": "Bosisio Parini", "value": "C55607"},
              {"label": "Bosnasco", "value": "C55608"},
              {"label": "Bossico", "value": "C55609"},
              {"label": "Botta", "value": "C55610"},
              {"label": "Bottanuco", "value": "C55611"},
              {"label": "Botticino", "value": "C55612"},
              {"label": "Bovegno", "value": "C55613"},
              {"label": "Bovezzo", "value": "C55614"},
              {"label": "Bovisio-Masciago", "value": "C55615"},
              {"label": "Bozzolo", "value": "C55616"},
              {"label": "Bracca", "value": "C55617"},
              {"label": "Brandico", "value": "C55618"},
              {"label": "Branzi", "value": "C55619"},
              {"label": "Braone", "value": "C55620"},
              {"label": "Brebbia", "value": "C55621"},
              {"label": "Bregano", "value": "C55622"},
              {"label": "Bregnano", "value": "C55623"},
              {"label": "Brembate", "value": "C55624"},
              {"label": "Brembate di Sopra", "value": "C55625"},
              {"label": "Brembilla", "value": "C55626"},
              {"label": "Brembio", "value": "C55627"},
              {"label": "Breme", "value": "C55628"},
              {"label": "Brenna", "value": "C55629"},
              {"label": "Brenno Useria", "value": "C55630"},
              {"label": "Breno", "value": "C55631"},
              {"label": "Brenta", "value": "C55632"},
              {"label": "Brescia", "value": "C55633"},
              {"label": "Bressana", "value": "C55634"},
              {"label": "Bresso", "value": "C55635"},
              {"label": "Brezzo di Bedero", "value": "C55636"},
              {"label": "Brienno", "value": "C55637"},
              {"label": "Brignano Gera dAdda", "value": "C55638"},
              {"label": "Brinzio", "value": "C55639"},
              {"label": "Brione", "value": "C55640"},
              {"label": "Briosco", "value": "C55641"},
              {"label": "Brissago-Valtravaglia", "value": "C55642"},
              {"label": "Brivio", "value": "C55643"},
              {"label": "Brongio", "value": "C55644"},
              {"label": "Broni", "value": "C55645"},
              {"label": "Brozzo", "value": "C55646"},
              {"label": "Brugherio", "value": "C55647"},
              {"label": "Brumano", "value": "C55648"},
              {"label": "Brunate", "value": "C55649"},
              {"label": "Brunello", "value": "C55650"},
              {"label": "Brusaporto", "value": "C55651"},
              {"label": "Brusimpiano", "value": "C55652"},
              {"label": "Bubbiano", "value": "C55653"},
              {"label": "Buccinasco", "value": "C55654"},
              {"label": "Buffalora-Bettole", "value": "C55655"},
              {"label": "Buglio in Monte", "value": "C55656"},
              {"label": "Buguggiate", "value": "C55657"},
              {"label": "Bulciago", "value": "C55658"},
              {"label": "Bulgarograsso", "value": "C55659"},
              {"label": "Bulgorello", "value": "C55660"},
              {"label": "Burago di Molgora", "value": "C55661"},
              {"label": "Buscate", "value": "C55662"},
              {"label": "Buscoldo", "value": "C55663"},
              {"label": "Busnago", "value": "C55664"},
              {"label": "Bussero", "value": "C55665"},
              {"label": "Busto Arsizio", "value": "C55666"},
              {"label": "Busto Garolfo", "value": "C55667"},
              {"label": "Ca dAndrea", "value": "C55668"},
              {"label": "Cabiate", "value": "C55669"},
              {"label": "Cadegliano-Viconago", "value": "C55670"},
              {"label": "Cadenabbia", "value": "C55671"},
              {"label": "Cadorago-Caslino al Piano", "value": "C55672"},
              {"label": "Cadrezzate", "value": "C55673"},
              {"label": "Caglio", "value": "C55674"},
              {"label": "Cagno", "value": "C55675"},
              {"label": "Caino", "value": "C55676"},
              {"label": "Caiolo", "value": "C55677"},
              {"label": "Cairate", "value": "C55678"},
              {"label": "Calchera-Frontale", "value": "C55679"},
              {"label": "Calcinate", "value": "C55680"},
              {"label": "Calcinato", "value": "C55681"},
              {"label": "Calcio", "value": "C55682"},
              {"label": "Calco", "value": "C55683"},
              {"label": "Caleppio", "value": "C55684"},
              {"label": "Calo", "value": "C55685"},
              {"label": "Calolziocorte", "value": "C55686"},
              {"label": "Calozzo", "value": "C55687"},
              {"label": "Calusco dAdda", "value": "C55688"},
              {"label": "Calvagese della Riviera", "value": "C55689"},
              {"label": "Calvatone", "value": "C55690"},
              {"label": "Calvenzano", "value": "C55691"},
              {"label": "Calvignano", "value": "C55692"},
              {"label": "Calvignasco", "value": "C55693"},
              {"label": "Calvisano", "value": "C55694"},
              {"label": "Camairago", "value": "C55695"},
              {"label": "Cambiago", "value": "C55696"},
              {"label": "Camerata Cornello", "value": "C55697"},
              {"label": "Camisano", "value": "C55698"},
              {"label": "Camnago-Boscone", "value": "C55699"},
              {"label": "Camoneone", "value": "C55700"},
              {"label": "Campagnola Cremasca", "value": "C55701"},
              {"label": "Camparada", "value": "C55702"},
              {"label": "Campione", "value": "C55703"},
              {"label": "Campitello", "value": "C55704"},
              {"label": "Campodolcino", "value": "C55705"},
              {"label": "Campofiorenzo-California", "value": "C55706"},
              {"label": "Campospinoso", "value": "C55707"},
              {"label": "Candia Lomellina", "value": "C55708"},
              {"label": "Canegrate", "value": "C55709"},
              {"label": "Canneto", "value": "C55710"},
              {"label": "Canneto Pavese", "value": "C55711"},
              {"label": "Canneto sullOglio", "value": "C55712"},
              {"label": "Canonica", "value": "C55713"},
              {"label": "Canonica dAdda", "value": "C55714"},
              {"label": "Canova-San Zeno", "value": "C55715"},
              {"label": "Cantalupo", "value": "C55716"},
              {"label": "Cantello", "value": "C55717"},
              {"label": "Cantu", "value": "C55718"},
              {"label": "Canzo", "value": "C55719"},
              {"label": "Capergnanica", "value": "C55720"},
              {"label": "Capiago-Intimiano-Olmeda", "value": "C55721"},
              {"label": "Capo di Ponte", "value": "C55722"},
              {"label": "Caponago", "value": "C55723"},
              {"label": "Capovalle", "value": "C55724"},
              {"label": "Cappella Cantone", "value": "C55725"},
              {"label": "Cappella de Picenardi", "value": "C55726"},
              {"label": "Cappelletta", "value": "C55727"},
              {"label": "Capralba", "value": "C55728"},
              {"label": "Capriano", "value": "C55729"},
              {"label": "Capriano del Colle", "value": "C55730"},
              {"label": "Capriate San Gervasio", "value": "C55731"},
              {"label": "Caprino", "value": "C55732"},
              {"label": "Capriolo", "value": "C55733"},
              {"label": "Carate Brianza", "value": "C55734"},
              {"label": "Carate Urio", "value": "C55735"},
              {"label": "Caravaggio", "value": "C55736"},
              {"label": "Caravate", "value": "C55737"},
              {"label": "Carbonara al Ticino", "value": "C55738"},
              {"label": "Carbonara di Po", "value": "C55739"},
              {"label": "Carbonate", "value": "C55740"},
              {"label": "Cardano al Campo", "value": "C55741"},
              {"label": "Carenno", "value": "C55742"},
              {"label": "Carimate", "value": "C55743"},
              {"label": "Carlazzo", "value": "C55744"},
              {"label": "Carnago", "value": "C55745"},
              {"label": "Carnate", "value": "C55746"},
              {"label": "Carobbio", "value": "C55747"},
              {"label": "Carobbio degli Angeli", "value": "C55748"},
              {"label": "Carona", "value": "C55749"},
              {"label": "Caronno Pertusella", "value": "C55750"},
              {"label": "Caronno Varesino", "value": "C55751"},
              {"label": "Carpenedolo", "value": "C55752"},
              {"label": "Carpiano", "value": "C55753"},
              {"label": "Carugate", "value": "C55754"},
              {"label": "Carugo", "value": "C55755"},
              {"label": "Carvico", "value": "C55756"},
              {"label": "Carzago Riviera", "value": "C55757"},
              {"label": "Casa Ponte", "value": "C55758"},
              {"label": "Casalbuttano", "value": "C55759"},
              {"label": "Casale Cremasco", "value": "C55760"},
              {"label": "Casale Litta", "value": "C55761"},
              {"label": "Casaletto Ceredano", "value": "C55762"},
              {"label": "Casaletto di Sopra", "value": "C55763"},
              {"label": "Casaletto Lodigiano", "value": "C55764"},
              {"label": "Casaletto Vaprio", "value": "C55765"},
              {"label": "Casalmaggiore", "value": "C55766"},
              {"label": "Casalmaiocco", "value": "C55767"},
              {"label": "Casalmorano", "value": "C55768"},
              {"label": "Casalmoro", "value": "C55769"},
              {"label": "Casaloldo", "value": "C55770"},
              {"label": "Casalpusterlengo", "value": "C55771"},
              {"label": "Casalromano", "value": "C55772"},
              {"label": "Casalzuigno", "value": "C55773"},
              {"label": "Casanova Lonati", "value": "C55774"},
              {"label": "Casargo", "value": "C55775"},
              {"label": "Casarile", "value": "C55776"},
              {"label": "Casasco Intelvi", "value": "C55777"},
              {"label": "Casate", "value": "C55778"},
              {"label": "Casatenovo", "value": "C55779"},
              {"label": "Casatisma", "value": "C55780"},
              {"label": "Casazza", "value": "C55781"},
              {"label": "Casciago", "value": "C55782"},
              {"label": "Cascina Elisa", "value": "C55783"},
              {"label": "Casco", "value": "C55784"},
              {"label": "Case Nuove", "value": "C55785"},
              {"label": "Casei", "value": "C55786"},
              {"label": "Caselle Landi", "value": "C55787"},
              {"label": "Caselle Lurani", "value": "C55788"},
              {"label": "Caseo", "value": "C55789"},
              {"label": "Casirate dAdda", "value": "C55790"},
              {"label": "Caslino dErba", "value": "C55791"},
              {"label": "Casnate Con Bernate", "value": "C55792"},
              {"label": "Casnigo", "value": "C55793"},
              {"label": "Casone", "value": "C55794"},
              {"label": "Casorate Primo", "value": "C55795"},
              {"label": "Casorate Sempione", "value": "C55796"},
              {"label": "Casorezzo", "value": "C55797"},
              {"label": "Caspoggio", "value": "C55798"},
              {"label": "Cassago Brianza", "value": "C55799"},
              {"label": "Cassano dAdda", "value": "C55800"},
              {"label": "Cassano Magnago", "value": "C55801"},
              {"label": "Cassano Valcuvia", "value": "C55802"},
              {"label": "Cassiglio", "value": "C55803"},
              {"label": "Cassina de Pecchi", "value": "C55804"},
              {"label": "Cassina Rizzardi", "value": "C55805"},
              {"label": "Cassina Valsassina", "value": "C55806"},
              {"label": "Cassinetta di Lugagnano", "value": "C55807"},
              {"label": "Cassino dAlberi", "value": "C55808"},
              {"label": "Cassolnovo", "value": "C55809"},
              {"label": "Castana", "value": "C55810"},
              {"label": "Castano Primo", "value": "C55811"},
              {"label": "Casteggio", "value": "C55812"},
              {"label": "Castegnato", "value": "C55813"},
              {"label": "Castel dArio", "value": "C55814"},
              {"label": "Castel Gabbiano", "value": "C55815"},
              {"label": "Castel Goffredo", "value": "C55816"},
              {"label": "Castel Mella", "value": "C55817"},
              {"label": "Castel Rozzone", "value": "C55818"},
              {"label": "Castelbelforte", "value": "C55819"},
              {"label": "Castelcovati", "value": "C55820"},
              {"label": "Casteldidone", "value": "C55821"},
              {"label": "Castellanza", "value": "C55822"},
              {"label": "Castelleone", "value": "C55823"},
              {"label": "Castelletto", "value": "C55824"},
              {"label": "Castelletto di Branduzzo", "value": "C55825"},
              {"label": "Castelletto Po", "value": "C55826"},
              {"label": "Castelli Calepio", "value": "C55827"},
              {"label": "Castello", "value": "C55828"},
              {"label": "Castello Cabiaglio", "value": "C55829"},
              {"label": "Castello dAgogna", "value": "C55830"},
              {"label": "Castello dellAcqua", "value": "C55831"},
              {"label": "Castello di Brianza", "value": "C55832"},
              {"label": "Castellucchio", "value": "C55833"},
              {"label": "Castelmarte", "value": "C55834"},
              {"label": "Castelnovetto", "value": "C55835"},
              {"label": "Castelnuovo Bocca dAdda", "value": "C55836"},
              {"label": "Castelnuovo Bozzente", "value": "C55837"},
              {"label": "Castelseprio", "value": "C55838"},
              {"label": "Castelveccana", "value": "C55839"},
              {"label": "Castelverde", "value": "C55840"},
              {"label": "Castelvisconti", "value": "C55841"},
              {"label": "Castenedolo", "value": "C55842"},
              {"label": "Castiglione dAdda", "value": "C55843"},
              {"label": "Castiglione dIntelvi", "value": "C55844"},
              {"label": "Castiglione delle Stiviere", "value": "C55845"},
              {"label": "Castiglione Olona", "value": "C55846"},
              {"label": "Castione", "value": "C55847"},
              {"label": "Castione Andevenno", "value": "C55848"},
              {"label": "Castione della Presolana", "value": "C55849"},
              {"label": "Castiraga Vidardo", "value": "C55850"},
              {"label": "Casto", "value": "C55851"},
              {"label": "Castrezzato", "value": "C55852"},
              {"label": "Castro", "value": "C55853"},
              {"label": "Castronno", "value": "C55854"},
              {"label": "Cataeggio", "value": "C55855"},
              {"label": "Cava Manara", "value": "C55856"},
              {"label": "Cavacurta", "value": "C55857"},
              {"label": "Cavallasca", "value": "C55858"},
              {"label": "Cavargna", "value": "C55859"},
              {"label": "Cavaria Con Premezzo", "value": "C55860"},
              {"label": "Cavenago dAdda", "value": "C55861"},
              {"label": "Cavenago di Brianza", "value": "C55862"},
              {"label": "Cavernago", "value": "C55863"},
              {"label": "Cavriana", "value": "C55864"},
              {"label": "Cazzago Brabbia", "value": "C55865"},
              {"label": "Cazzago San Martino-Calino", "value": "C55866"},
              {"label": "Cazzano SantAndrea", "value": "C55867"},
              {"label": "Cecima", "value": "C55868"},
              {"label": "Cedegolo", "value": "C55869"},
              {"label": "Cedessano", "value": "C55870"},
              {"label": "Cedrasco", "value": "C55871"},
              {"label": "Cella Dati", "value": "C55872"},
              {"label": "Cellatica", "value": "C55873"},
              {"label": "Cenate di Sotto", "value": "C55874"},
              {"label": "Cenate Sopra", "value": "C55875"},
              {"label": "Cene", "value": "C55876"},
              {"label": "Cepina", "value": "C55877"},
              {"label": "Cerano dIntelvi", "value": "C55878"},
              {"label": "Ceranova", "value": "C55879"},
              {"label": "Cercino", "value": "C55880"},
              {"label": "Cerello-Battuello", "value": "C55881"},
              {"label": "Ceresara", "value": "C55882"},
              {"label": "Cerese", "value": "C55883"},
              {"label": "Cerete Alto", "value": "C55884"},
              {"label": "Ceretto Lomellina", "value": "C55885"},
              {"label": "Cergnago", "value": "C55886"},
              {"label": "Ceriano Laghetto", "value": "C55887"},
              {"label": "Cerlongo", "value": "C55888"},
              {"label": "Cermenate", "value": "C55889"},
              {"label": "Cernobbio", "value": "C55890"},
              {"label": "Cernusco Lombardone", "value": "C55891"},
              {"label": "Cernusco sul Naviglio", "value": "C55892"},
              {"label": "Cerro al Lambro", "value": "C55893"},
              {"label": "Cerro Maggiore", "value": "C55894"},
              {"label": "Certosa di Pavia", "value": "C55895"},
              {"label": "Cerveno", "value": "C55896"},
              {"label": "Cervesina", "value": "C55897"},
              {"label": "Cervignano dAdda", "value": "C55898"},
              {"label": "Cesana Brianza", "value": "C55899"},
              {"label": "Cesano Boscone", "value": "C55900"},
              {"label": "Cesano Maderno", "value": "C55901"},
              {"label": "Cesate", "value": "C55902"},
              {"label": "Ceto", "value": "C55903"},
              {"label": "Cevo", "value": "C55904"},
              {"label": "Chiaravalle", "value": "C55905"},
              {"label": "Chiari", "value": "C55906"},
              {"label": "Chiavenna", "value": "C55907"},
              {"label": "Chiesa", "value": "C55908"},
              {"label": "Chiesa in Valmalenco", "value": "C55909"},
              {"label": "Chieve", "value": "C55910"},
              {"label": "Chignolo dIsola", "value": "C55911"},
              {"label": "Chignolo Po", "value": "C55912"},
              {"label": "Chiuduno", "value": "C55913"},
              {"label": "Chiuro", "value": "C55914"},
              {"label": "Cicognolo", "value": "C55915"},
              {"label": "Cigognola", "value": "C55916"},
              {"label": "Cigole", "value": "C55917"},
              {"label": "Cilavegna", "value": "C55918"},
              {"label": "Cimbergo", "value": "C55919"},
              {"label": "Cimbro", "value": "C55920"},
              {"label": "Cingia de Botti", "value": "C55921"},
              {"label": "Cinisello Balsamo", "value": "C55922"},
              {"label": "Cino", "value": "C55923"},
              {"label": "Cirimido", "value": "C55924"},
              {"label": "Cisano Bergamasco", "value": "C55925"},
              {"label": "Ciserano", "value": "C55926"},
              {"label": "Cislago", "value": "C55927"},
              {"label": "Cisliano", "value": "C55928"},
              {"label": "Citta metropolitana di Milano", "value": "C55929"},
              {"label": "Cittiglio", "value": "C55930"},
              {"label": "Civate", "value": "C55931"},
              {"label": "Civesio", "value": "C55932"},
              {"label": "Cividate al Piano", "value": "C55933"},
              {"label": "Cividate Camuno", "value": "C55934"},
              {"label": "Cizzago-Comezzano", "value": "C55935"},
              {"label": "Clivio", "value": "C55936"},
              {"label": "Clusane", "value": "C55937"},
              {"label": "Clusone", "value": "C55938"},
              {"label": "Coccaglio", "value": "C55939"},
              {"label": "Cocquio", "value": "C55940"},
              {"label": "Codevilla", "value": "C55941"},
              {"label": "Codogna-Cardano", "value": "C55942"},
              {"label": "Codogno", "value": "C55943"},
              {"label": "Cogliate", "value": "C55944"},
              {"label": "Colere", "value": "C55945"},
              {"label": "Colico Piano", "value": "C55946"},
              {"label": "Colla-Muggiasca", "value": "C55947"},
              {"label": "Colle Brianza", "value": "C55948"},
              {"label": "Collebeato", "value": "C55949"},
              {"label": "Collio", "value": "C55950"},
              {"label": "Cologna-Caraverio", "value": "C55951"},
              {"label": "Cologne", "value": "C55952"},
              {"label": "Cologno al Serio", "value": "C55953"},
              {"label": "Cologno Monzese", "value": "C55954"},
              {"label": "Colombaro-Timoline", "value": "C55955"},
              {"label": "Colonno", "value": "C55956"},
              {"label": "Colorina", "value": "C55957"},
              {"label": "Colturano", "value": "C55958"},
              {"label": "Colzate", "value": "C55959"},
              {"label": "Comabbio", "value": "C55960"},
              {"label": "Comazzo", "value": "C55961"},
              {"label": "Comerio", "value": "C55962"},
              {"label": "Commessaggio", "value": "C55963"},
              {"label": "Como", "value": "C55964"},
              {"label": "Comun Nuovo", "value": "C55965"},
              {"label": "Concesio", "value": "C55966"},
              {"label": "Concorezzo", "value": "C55967"},
              {"label": "Confienza", "value": "C55968"},
              {"label": "Consiglio di Rumo", "value": "C55969"},
              {"label": "Copiano", "value": "C55970"},
              {"label": "Corana", "value": "C55971"},
              {"label": "Corbetta", "value": "C55972"},
              {"label": "Corgeno", "value": "C55973"},
              {"label": "Cormano", "value": "C55974"},
              {"label": "Corna Imagna", "value": "C55975"},
              {"label": "Cornalba", "value": "C55976"},
              {"label": "Cornale", "value": "C55977"},
              {"label": "Cornaredo", "value": "C55978"},
              {"label": "Cornate dAdda", "value": "C55979"},
              {"label": "Corneno-Galliano-Carella Mariaga", "value": "C55980"},
              {"label": "Corno Giovine", "value": "C55981"},
              {"label": "Cornovecchio", "value": "C55982"},
              {"label": "Correzzana", "value": "C55983"},
              {"label": "Corrido", "value": "C55984"},
              {"label": "Corsico", "value": "C55985"},
              {"label": "Corte de Cortesi", "value": "C55986"},
              {"label": "Corte de Cortesi con Cignone", "value": "C55987"},
              {"label": "Corte de Frati", "value": "C55988"},
              {"label": "Corte Franca", "value": "C55989"},
              {"label": "Corteno Golgi", "value": "C55990"},
              {"label": "Cortenova", "value": "C55991"},
              {"label": "Cortenuova", "value": "C55992"},
              {"label": "Corteolona", "value": "C55993"},
              {"label": "Corvino San Quirico", "value": "C55994"},
              {"label": "Corzano", "value": "C55995"},
              {"label": "Cosio Valtellino", "value": "C55996"},
              {"label": "Costa de Nobili", "value": "C55997"},
              {"label": "Costa di Mezzate", "value": "C55998"},
              {"label": "Costa di Serina", "value": "C55999"},
              {"label": "Costa Lambro", "value": "C56000"},
              {"label": "Costa Masnaga", "value": "C56001"},
              {"label": "Costa SantAbramo", "value": "C56002"},
              {"label": "Costa Valle Imagna", "value": "C56003"},
              {"label": "Costa Volpino", "value": "C56004"},
              {"label": "Costa-Barco", "value": "C56005"},
              {"label": "Covo", "value": "C56006"},
              {"label": "Cozzo", "value": "C56007"},
              {"label": "Crandola Valsassina", "value": "C56008"},
              {"label": "Credaro", "value": "C56009"},
              {"label": "Credera", "value": "C56010"},
              {"label": "Crema", "value": "C56011"},
              {"label": "Cremella", "value": "C56012"},
              {"label": "Cremenaga", "value": "C56013"},
              {"label": "Cremeno", "value": "C56014"},
              {"label": "Cremia", "value": "C56015"},
              {"label": "Cremona", "value": "C56016"},
              {"label": "Cremosano", "value": "C56017"},
              {"label": "Crespiatica", "value": "C56018"},
              {"label": "Crone", "value": "C56019"},
              {"label": "Crosio della Valle", "value": "C56020"},
              {"label": "Crotta dAdda", "value": "C56021"},
              {"label": "Crugnola", "value": "C56022"},
              {"label": "Cuasso al Monte", "value": "C56023"},
              {"label": "Cuasso al Piano", "value": "C56024"},
              {"label": "Cucciago", "value": "C56025"},
              {"label": "Cuggiono", "value": "C56026"},
              {"label": "Cugliate-Fabiasco", "value": "C56027"},
              {"label": "Cumignano sul Naviglio", "value": "C56028"},
              {"label": "Cunardo", "value": "C56029"},
              {"label": "Cunettone-Villa", "value": "C56030"},
              {"label": "Cura Carpignano", "value": "C56031"},
              {"label": "Curiglia", "value": "C56032"},
              {"label": "Curno", "value": "C56033"},
              {"label": "Cusago", "value": "C56034"},
              {"label": "Cusano", "value": "C56035"},
              {"label": "Cusino", "value": "C56036"},
              {"label": "Cusio", "value": "C56037"},
              {"label": "Cuveglio", "value": "C56038"},
              {"label": "Cuvio", "value": "C56039"},
              {"label": "Dairago", "value": "C56040"},
              {"label": "Dalmine", "value": "C56041"},
              {"label": "Darfo Boario Terme", "value": "C56042"},
              {"label": "Daverio", "value": "C56043"},
              {"label": "Dazio", "value": "C56044"},
              {"label": "Delebio", "value": "C56045"},
              {"label": "Dello", "value": "C56046"},
              {"label": "Derovere", "value": "C56047"},
              {"label": "Dervio", "value": "C56048"},
              {"label": "Desenzano del Garda", "value": "C56049"},
              {"label": "Desio", "value": "C56050"},
              {"label": "Dizzasco-Biazzeno", "value": "C56051"},
              {"label": "Dolzago", "value": "C56052"},
              {"label": "Domaso", "value": "C56053"},
              {"label": "Dongo", "value": "C56054"},
              {"label": "Dorio", "value": "C56055"},
              {"label": "Dorno", "value": "C56056"},
              {"label": "Dosimo", "value": "C56057"},
              {"label": "Dosolo", "value": "C56058"},
              {"label": "Dossena", "value": "C56059"},
              {"label": "Dosso del Liro", "value": "C56060"},
              {"label": "Dosso-Ville", "value": "C56061"},
              {"label": "Dovera", "value": "C56062"},
              {"label": "Dresano", "value": "C56063"},
              {"label": "Drizzona", "value": "C56064"},
              {"label": "Dubino", "value": "C56065"},
              {"label": "Dumenza", "value": "C56066"},
              {"label": "Duno", "value": "C56067"},
              {"label": "Duomo", "value": "C56068"},
              {"label": "Edolo", "value": "C56069"},
              {"label": "Ello", "value": "C56070"},
              {"label": "Endine", "value": "C56071"},
              {"label": "Entratico", "value": "C56072"},
              {"label": "Era", "value": "C56073"},
              {"label": "Erba", "value": "C56074"},
              {"label": "Erbusco", "value": "C56075"},
              {"label": "Eremo", "value": "C56076"},
              {"label": "Erve", "value": "C56077"},
              {"label": "Esine", "value": "C56078"},
              {"label": "Esino Lario", "value": "C56079"},
              {"label": "Faedo Valtellino", "value": "C56080"},
              {"label": "Faggeto Lario", "value": "C56081"},
              {"label": "Fagnano Olona-Bergoro", "value": "C56082"},
              {"label": "Faloppio", "value": "C56083"},
              {"label": "Fantasina", "value": "C56084"},
              {"label": "Fara Gera dAdda", "value": "C56085"},
              {"label": "Fara Olivana", "value": "C56086"},
              {"label": "Felonica", "value": "C56087"},
              {"label": "Fenegro", "value": "C56088"},
              {"label": "Ferno", "value": "C56089"},
              {"label": "Ferrera di Varese", "value": "C56090"},
              {"label": "Ferrera Erbognone", "value": "C56091"},
              {"label": "Fiesco", "value": "C56092"},
              {"label": "Fiesse", "value": "C56093"},
              {"label": "Figino", "value": "C56094"},
              {"label": "Figino Serenza", "value": "C56095"},
              {"label": "Figliaro", "value": "C56096"},
              {"label": "Filago", "value": "C56097"},
              {"label": "Filighera", "value": "C56098"},
              {"label": "Fino del Monte", "value": "C56099"},
              {"label": "Fino Mornasco", "value": "C56100"},
              {"label": "Fiorano al Serio", "value": "C56101"},
              {"label": "Fizzonasco", "value": "C56102"},
              {"label": "Flero", "value": "C56103"},
              {"label": "Folzano", "value": "C56104"},
              {"label": "Fombio", "value": "C56105"},
              {"label": "Fontane-Zurane-Gresine", "value": "C56106"},
              {"label": "Fontanella", "value": "C56107"},
              {"label": "Fonteno", "value": "C56108"},
              {"label": "Foppolo", "value": "C56109"},
              {"label": "Forcola", "value": "C56110"},
              {"label": "Foresto Sparso", "value": "C56111"},
              {"label": "Formigara", "value": "C56112"},
              {"label": "Fornaci", "value": "C56113"},
              {"label": "Fornovo San Giovanni", "value": "C56114"},
              {"label": "Fortunago", "value": "C56115"},
              {"label": "Frascarolo", "value": "C56116"},
              {"label": "Fuipiano Valle Imagna", "value": "C56117"},
              {"label": "Furato", "value": "C56118"},
              {"label": "Fusine", "value": "C56119"},
              {"label": "Gabbioneta", "value": "C56120"},
              {"label": "Gadesco-Pieve Delmona", "value": "C56121"},
              {"label": "Gaggiano", "value": "C56122"},
              {"label": "Gaggino", "value": "C56123"},
              {"label": "Galbiate", "value": "C56124"},
              {"label": "Galgagnano", "value": "C56125"},
              {"label": "Gallarate", "value": "C56126"},
              {"label": "Galliate Lombardo", "value": "C56127"},
              {"label": "Galliavola", "value": "C56128"},
              {"label": "Gallo-Tre Re-Mezzana Corti", "value": "C56129"},
              {"label": "Gambara", "value": "C56130"},
              {"label": "Gambarana", "value": "C56131"},
              {"label": "Gambolo", "value": "C56132"},
              {"label": "Gandellino", "value": "C56133"},
              {"label": "Gandino", "value": "C56134"},
              {"label": "Gandosso", "value": "C56135"},
              {"label": "Ganna", "value": "C56136"},
              {"label": "Garbagnate", "value": "C56137"},
              {"label": "Garbagnate Milanese", "value": "C56138"},
              {"label": "Garbagnate Monastero", "value": "C56139"},
              {"label": "Gardola", "value": "C56140"},
              {"label": "Gardone Riviera", "value": "C56141"},
              {"label": "Gardone Val Trompia", "value": "C56142"},
              {"label": "Gargnano", "value": "C56143"},
              {"label": "Garlasco", "value": "C56144"},
              {"label": "Garlate", "value": "C56145"},
              {"label": "Garzeno", "value": "C56146"},
              {"label": "Gavardo-Sopraponte", "value": "C56147"},
              {"label": "Gavarno Rinnovata", "value": "C56148"},
              {"label": "Gavarno-Tribulina", "value": "C56149"},
              {"label": "Gaverina Terme", "value": "C56150"},
              {"label": "Gavirate", "value": "C56151"},
              {"label": "Gazoldo degli Ippoliti", "value": "C56152"},
              {"label": "Gazzada Schianno", "value": "C56153"},
              {"label": "Gazzaniga", "value": "C56154"},
              {"label": "Gazzo", "value": "C56155"},
              {"label": "Gazzuolo", "value": "C56156"},
              {"label": "Gemonio", "value": "C56157"},
              {"label": "Genivolta", "value": "C56158"},
              {"label": "Genzone", "value": "C56159"},
              {"label": "Gera Lario", "value": "C56160"},
              {"label": "Gerenzago", "value": "C56161"},
              {"label": "Gerenzano", "value": "C56162"},
              {"label": "Germignaga", "value": "C56163"},
              {"label": "Gerola Alta", "value": "C56164"},
              {"label": "Geromina", "value": "C56165"},
              {"label": "Gerosa", "value": "C56166"},
              {"label": "Gerre de Caprioli", "value": "C56167"},
              {"label": "Gessate", "value": "C56168"},
              {"label": "Ghedi", "value": "C56169"},
              {"label": "Ghiaie", "value": "C56170"},
              {"label": "Ghisalba", "value": "C56171"},
              {"label": "Gianico", "value": "C56172"},
              {"label": "Giovenzano", "value": "C56173"},
              {"label": "Gironico al Piano", "value": "C56174"},
              {"label": "Giussago", "value": "C56175"},
              {"label": "Giussano", "value": "C56176"},
              {"label": "Godiasco", "value": "C56177"},
              {"label": "Goito", "value": "C56178"},
              {"label": "Golasecca", "value": "C56179"},
              {"label": "Golferenzo", "value": "C56180"},
              {"label": "Gombito", "value": "C56181"},
              {"label": "Gonzaga", "value": "C56182"},
              {"label": "Gordona", "value": "C56183"},
              {"label": "Gorgonzola", "value": "C56184"},
              {"label": "Gorla Maggiore", "value": "C56185"},
              {"label": "Gorla Minore", "value": "C56186"},
              {"label": "Gorlago", "value": "C56187"},
              {"label": "Gorle", "value": "C56188"},
              {"label": "Gornate Olona", "value": "C56189"},
              {"label": "Gorno", "value": "C56190"},
              {"label": "Gottolengo", "value": "C56191"},
              {"label": "Graffignana", "value": "C56192"},
              {"label": "Grandate", "value": "C56193"},
              {"label": "Grandola ed Uniti", "value": "C56194"},
              {"label": "Grantola", "value": "C56195"},
              {"label": "Grassobbio", "value": "C56196"},
              {"label": "Gratacasolo", "value": "C56197"},
              {"label": "Gravedona-San Gregorio", "value": "C56198"},
              {"label": "Gravellona", "value": "C56199"},
              {"label": "Grezzago", "value": "C56200"},
              {"label": "Griante", "value": "C56201"},
              {"label": "Grignano", "value": "C56202"},
              {"label": "Gromlongo", "value": "C56203"},
              {"label": "Gromo", "value": "C56204"},
              {"label": "Grone", "value": "C56205"},
              {"label": "Grontardo", "value": "C56206"},
              {"label": "Gropello Cairoli", "value": "C56207"},
              {"label": "Grosio", "value": "C56208"},
              {"label": "Grosotto", "value": "C56209"},
              {"label": "Grumello Cremonese", "value": "C56210"},
              {"label": "Grumello del Monte", "value": "C56211"},
              {"label": "Guanzate", "value": "C56212"},
              {"label": "Guardamiglio", "value": "C56213"},
              {"label": "Gudo Visconti", "value": "C56214"},
              {"label": "Guidizzolo", "value": "C56215"},
              {"label": "Guinzano", "value": "C56216"},
              {"label": "Gussago", "value": "C56217"},
              {"label": "Gussola", "value": "C56218"},
              {"label": "Idro", "value": "C56219"},
              {"label": "Imbersago", "value": "C56220"},
              {"label": "Inarzo", "value": "C56221"},
              {"label": "Incudine", "value": "C56222"},
              {"label": "Induno Olona", "value": "C56223"},
              {"label": "Introbio", "value": "C56224"},
              {"label": "Introzzo", "value": "C56225"},
              {"label": "Inverigo", "value": "C56226"},
              {"label": "Inverno", "value": "C56227"},
              {"label": "Inverno e Monteleone", "value": "C56228"},
              {"label": "Inveruno", "value": "C56229"},
              {"label": "Inzago", "value": "C56230"},
              {"label": "Irma", "value": "C56231"},
              {"label": "Iseo", "value": "C56232"},
              {"label": "Isola di Fondra", "value": "C56233"},
              {"label": "Isola Dovarese", "value": "C56234"},
              {"label": "Isolaccia", "value": "C56235"},
              {"label": "Isorella", "value": "C56236"},
              {"label": "Ispra", "value": "C56237"},
              {"label": "Isso", "value": "C56238"},
              {"label": "Izano", "value": "C56239"},
              {"label": "Jerago Con Orago", "value": "C56240"},
              {"label": "Lacchiarella", "value": "C56241"},
              {"label": "Laglio", "value": "C56242"},
              {"label": "Lainate", "value": "C56243"},
              {"label": "Laino", "value": "C56244"},
              {"label": "Lallio", "value": "C56245"},
              {"label": "Lambrinia", "value": "C56246"},
              {"label": "Lambrugo", "value": "C56247"},
              {"label": "Landriano", "value": "C56248"},
              {"label": "Langosco", "value": "C56249"},
              {"label": "Lanzada", "value": "C56250"},
              {"label": "Lanzo dIntelvi", "value": "C56251"},
              {"label": "Lardirago", "value": "C56252"},
              {"label": "Lasnigo", "value": "C56253"},
              {"label": "Lavena Ponte Tresa", "value": "C56254"},
              {"label": "Laveno", "value": "C56255"},
              {"label": "Lavenone", "value": "C56256"},
              {"label": "Laxolo", "value": "C56257"},
              {"label": "Lazzate", "value": "C56258"},
              {"label": "Lecco", "value": "C56259"},
              {"label": "Leffe", "value": "C56260"},
              {"label": "Leggiuno", "value": "C56261"},
              {"label": "Legnano", "value": "C56262"},
              {"label": "Lemna", "value": "C56263"},
              {"label": "Lenna", "value": "C56264"},
              {"label": "Lenno", "value": "C56265"},
              {"label": "Leno", "value": "C56266"},
              {"label": "Lentate sul Seveso", "value": "C56267"},
              {"label": "Lesmo", "value": "C56268"},
              {"label": "Levata", "value": "C56269"},
              {"label": "Levate", "value": "C56270"},
              {"label": "Lezzeno", "value": "C56271"},
              {"label": "Lierna", "value": "C56272"},
              {"label": "Limbiate", "value": "C56273"},
              {"label": "Limido Comasco", "value": "C56274"},
              {"label": "Limone sul Garda", "value": "C56275"},
              {"label": "Linarolo", "value": "C56276"},
              {"label": "Linate", "value": "C56277"},
              {"label": "Lipomo", "value": "C56278"},
              {"label": "Lirio", "value": "C56279"},
              {"label": "Lisanza", "value": "C56280"},
              {"label": "Liscate", "value": "C56281"},
              {"label": "Lissone", "value": "C56282"},
              {"label": "Livigno", "value": "C56283"},
              {"label": "Livo", "value": "C56284"},
              {"label": "Livraga", "value": "C56285"},
              {"label": "Locate di Triulzi", "value": "C56286"},
              {"label": "Locate Varesino", "value": "C56287"},
              {"label": "Locatello", "value": "C56288"},
              {"label": "Lodetto", "value": "C56289"},
              {"label": "Lodi", "value": "C56290"},
              {"label": "Lodi Vecchio", "value": "C56291"},
              {"label": "Lodrino", "value": "C56292"},
              {"label": "Lograto", "value": "C56293"},
              {"label": "Lomagna", "value": "C56294"},
              {"label": "Lomazzo", "value": "C56295"},
              {"label": "Lomello", "value": "C56296"},
              {"label": "Lonate Ceppino", "value": "C56297"},
              {"label": "Lonate Pozzolo", "value": "C56298"},
              {"label": "Lonato", "value": "C56299"},
              {"label": "Longhena", "value": "C56300"},
              {"label": "Longone al Segrino", "value": "C56301"},
              {"label": "Losine", "value": "C56302"},
              {"label": "Lovere", "value": "C56303"},
              {"label": "Lovero", "value": "C56304"},
              {"label": "Lozio", "value": "C56305"},
              {"label": "Lozza", "value": "C56306"},
              {"label": "Lucino", "value": "C56307"},
              {"label": "Lucino-Rodano", "value": "C56308"},
              {"label": "Ludriano", "value": "C56309"},
              {"label": "Luino", "value": "C56310"},
              {"label": "Luisago", "value": "C56311"},
              {"label": "Lumezzane", "value": "C56312"},
              {"label": "Lunetta-Frassino", "value": "C56313"},
              {"label": "Lungavilla", "value": "C56314"},
              {"label": "Lurago dErba", "value": "C56315"},
              {"label": "Lurago Marinone", "value": "C56316"},
              {"label": "Lurano", "value": "C56317"},
              {"label": "Lurate Caccivio", "value": "C56318"},
              {"label": "Luvinate", "value": "C56319"},
              {"label": "Luzzana", "value": "C56320"},
              {"label": "Maccagno", "value": "C56321"},
              {"label": "Maccastorna", "value": "C56322"},
              {"label": "Macherio", "value": "C56323"},
              {"label": "Maclodio", "value": "C56324"},
              {"label": "Madignano", "value": "C56325"},
              {"label": "Madone", "value": "C56326"},
              {"label": "Magasa", "value": "C56327"},
              {"label": "Magenta", "value": "C56328"},
              {"label": "Magherno", "value": "C56329"},
              {"label": "Magnacavallo", "value": "C56330"},
              {"label": "Magnago", "value": "C56331"},
              {"label": "Magno", "value": "C56332"},
              {"label": "Magreglio", "value": "C56333"},
              {"label": "Mairago", "value": "C56334"},
              {"label": "Mairano", "value": "C56335"},
              {"label": "Malagnino", "value": "C56336"},
              {"label": "Malavicina", "value": "C56337"},
              {"label": "Malegno", "value": "C56338"},
              {"label": "Maleo", "value": "C56339"},
              {"label": "Malgesso", "value": "C56340"},
              {"label": "Malgrate", "value": "C56341"},
              {"label": "Malnate", "value": "C56342"},
              {"label": "Malonno", "value": "C56343"},
              {"label": "Mandello del Lario", "value": "C56344"},
              {"label": "Manera", "value": "C56345"},
              {"label": "Manerba del Garda", "value": "C56346"},
              {"label": "Manerbio", "value": "C56347"},
              {"label": "Mantegazza", "value": "C56348"},
              {"label": "Mantello", "value": "C56349"},
              {"label": "Mantova", "value": "C56350"},
              {"label": "Mapello", "value": "C56351"},
              {"label": "Marcallo", "value": "C56352"},
              {"label": "Marcaria", "value": "C56353"},
              {"label": "Marcheno", "value": "C56354"},
              {"label": "Marchirolo", "value": "C56355"},
              {"label": "Marcignago", "value": "C56356"},
              {"label": "Maresso", "value": "C56357"},
              {"label": "Margno", "value": "C56358"},
              {"label": "Mariana Mantovana", "value": "C56359"},
              {"label": "Mariano Comense", "value": "C56360"},
              {"label": "Marmentino", "value": "C56361"},
              {"label": "Marmirolo", "value": "C56362"},
              {"label": "Marnate", "value": "C56363"},
              {"label": "Marone", "value": "C56364"},
              {"label": "Martignana di Po", "value": "C56365"},
              {"label": "Martinengo", "value": "C56366"},
              {"label": "Marudo", "value": "C56367"},
              {"label": "Marzano", "value": "C56368"},
              {"label": "Marzio", "value": "C56369"},
              {"label": "Masate", "value": "C56370"},
              {"label": "Masciago Primo", "value": "C56371"},
              {"label": "Maslianico", "value": "C56372"},
              {"label": "Massalengo-Motta Vigana", "value": "C56373"},
              {"label": "Mazzano", "value": "C56374"},
              {"label": "Mazzo di Valtellina", "value": "C56375"},
              {"label": "Meda", "value": "C56376"},
              {"label": "Mede", "value": "C56377"},
              {"label": "Mediglia", "value": "C56378"},
              {"label": "Medolago", "value": "C56379"},
              {"label": "Medole", "value": "C56380"},
              {"label": "Melegnano", "value": "C56381"},
              {"label": "Meleti", "value": "C56382"},
              {"label": "Mello", "value": "C56383"},
              {"label": "Melzo", "value": "C56384"},
              {"label": "Menaggio", "value": "C56385"},
              {"label": "Menarola", "value": "C56386"},
              {"label": "Menconico", "value": "C56387"},
              {"label": "Merate", "value": "C56388"},
              {"label": "Mercallo", "value": "C56389"},
              {"label": "Merlino", "value": "C56390"},
              {"label": "Merone", "value": "C56391"},
              {"label": "Mese", "value": "C56392"},
              {"label": "Mesenzana", "value": "C56393"},
              {"label": "Mesero", "value": "C56394"},
              {"label": "Mezzago", "value": "C56395"},
              {"label": "Mezzana Bigli", "value": "C56396"},
              {"label": "Mezzana Rabattone", "value": "C56397"},
              {"label": "Mezzanino", "value": "C56398"},
              {"label": "Mezzate", "value": "C56399"},
              {"label": "Mezzegra", "value": "C56400"},
              {"label": "Mezzoldo", "value": "C56401"},
              {"label": "Milan", "value": "C56402"},
              {"label": "Millepini", "value": "C56403"},
              {"label": "Milzano", "value": "C56404"},
              {"label": "Miradolo Terme", "value": "C56405"},
              {"label": "Misano di Gera dAdda", "value": "C56406"},
              {"label": "Misinto", "value": "C56407"},
              {"label": "Missaglia", "value": "C56408"},
              {"label": "Moggio", "value": "C56409"},
              {"label": "Moglia", "value": "C56410"},
              {"label": "Moio de Calvi", "value": "C56411"},
              {"label": "Molinetto", "value": "C56412"},
              {"label": "Molteno", "value": "C56413"},
              {"label": "Moltrasio", "value": "C56414"},
              {"label": "Mombelli", "value": "C56415"},
              {"label": "Mombretto", "value": "C56416"},
              {"label": "Monasterolo", "value": "C56417"},
              {"label": "Monasterolo del Castello", "value": "C56418"},
              {"label": "Moncucco", "value": "C56419"},
              {"label": "Moniga del Garda", "value": "C56420"},
              {"label": "Monno", "value": "C56421"},
              {"label": "Montagna in Valtellina", "value": "C56422"},
              {"label": "Montalto Pavese", "value": "C56423"},
              {"label": "Montanara", "value": "C56424"},
              {"label": "Montanaso Lombardo", "value": "C56425"},
              {"label": "Montano", "value": "C56426"},
              {"label": "Montano Lucino", "value": "C56427"},
              {"label": "Monte", "value": "C56428"},
              {"label": "Monte Cremasco", "value": "C56429"},
              {"label": "Monte Marenzo", "value": "C56430"},
              {"label": "Montebello", "value": "C56431"},
              {"label": "Montecalvo Versiggia", "value": "C56432"},
              {"label": "Montegrino Valtravaglia", "value": "C56433"},
              {"label": "Montello", "value": "C56434"},
              {"label": "Montemezzo", "value": "C56435"},
              {"label": "Montescano", "value": "C56436"},
              {"label": "Montesegale", "value": "C56437"},
              {"label": "Montesolaro", "value": "C56438"},
              {"label": "Montevecchia", "value": "C56439"},
              {"label": "Monticelli Brusati", "value": "C56440"},
              {"label": "Monticelli Pavese", "value": "C56441"},
              {"label": "Monticello", "value": "C56442"},
              {"label": "Monticello Brianza", "value": "C56443"},
              {"label": "Montichiari", "value": "C56444"},
              {"label": "Montirone", "value": "C56445"},
              {"label": "Montodine", "value": "C56446"},
              {"label": "Montorfano", "value": "C56447"},
              {"label": "Montu Beccaria", "value": "C56448"},
              {"label": "Monvalle", "value": "C56449"},
              {"label": "Monza", "value": "C56450"},
              {"label": "Monzambano", "value": "C56451"},
              {"label": "Morazzone", "value": "C56452"},
              {"label": "Morbegno", "value": "C56453"},
              {"label": "Morengo", "value": "C56454"},
              {"label": "Morimondo", "value": "C56455"},
              {"label": "Mornago", "value": "C56456"},
              {"label": "Mornico", "value": "C56457"},
              {"label": "Mornico al Serio", "value": "C56458"},
              {"label": "Mornico Losana", "value": "C56459"},
              {"label": "Mortara", "value": "C56460"},
              {"label": "Morterone", "value": "C56461"},
              {"label": "Moscazzano", "value": "C56462"},
              {"label": "Motta Baluffi", "value": "C56463"},
              {"label": "Motta Visconti", "value": "C56464"},
              {"label": "Motteggiana", "value": "C56465"},
              {"label": "Mottella", "value": "C56466"},
              {"label": "Mozzanica", "value": "C56467"},
              {"label": "Mozzate", "value": "C56468"},
              {"label": "Mozzo", "value": "C56469"},
              {"label": "Muggio", "value": "C56470"},
              {"label": "Mulazzano", "value": "C56471"},
              {"label": "Mura", "value": "C56472"},
              {"label": "Muscoline", "value": "C56473"},
              {"label": "Musso", "value": "C56474"},
              {"label": "Muzza di Cornegliano Laudense", "value": "C56475"},
              {"label": "Nava", "value": "C56476"},
              {"label": "Nave", "value": "C56477"},
              {"label": "Navedano", "value": "C56478"},
              {"label": "Negrone", "value": "C56479"},
              {"label": "Nembro", "value": "C56480"},
              {"label": "Nerviano", "value": "C56481"},
              {"label": "Nesso", "value": "C56482"},
              {"label": "Niardo", "value": "C56483"},
              {"label": "Nibionno", "value": "C56484"},
              {"label": "Nicorvo", "value": "C56485"},
              {"label": "Nigoline-Bonomelli", "value": "C56486"},
              {"label": "Nobile-Monguzzo", "value": "C56487"},
              {"label": "Nosadello", "value": "C56488"},
              {"label": "Nosate", "value": "C56489"},
              {"label": "Nova Milanese", "value": "C56490"},
              {"label": "Novagli", "value": "C56491"},
              {"label": "Novate Mezzola", "value": "C56492"},
              {"label": "Novate Milanese", "value": "C56493"},
              {"label": "Novedrate", "value": "C56494"},
              {"label": "Novegro-Tregarezzo-San Felice", "value": "C56495"},
              {"label": "Noverasco-Sporting Mirasole", "value": "C56496"},
              {"label": "Noviglio", "value": "C56497"},
              {"label": "Nuvolento", "value": "C56498"},
              {"label": "Nuvolera", "value": "C56499"},
              {"label": "Occagno", "value": "C56500"},
              {"label": "Odolo", "value": "C56501"},
              {"label": "Offanengo", "value": "C56502"},
              {"label": "Offlaga", "value": "C56503"},
              {"label": "Oggiona-Santo Stefano", "value": "C56504"},
              {"label": "Oggiono", "value": "C56505"},
              {"label": "Olevano di Lomellina", "value": "C56506"},
              {"label": "Olgiate Comasco", "value": "C56507"},
              {"label": "Olgiate Molgora", "value": "C56508"},
              {"label": "Olgiate Olona", "value": "C56509"},
              {"label": "Olginate", "value": "C56510"},
              {"label": "Oliva Gessi", "value": "C56511"},
              {"label": "Olmeneta", "value": "C56512"},
              {"label": "Olmo al Brembo", "value": "C56513"},
              {"label": "Oltre Il Colle", "value": "C56514"},
              {"label": "Oltressenda Alta", "value": "C56515"},
              {"label": "Oltrona di San Mamette", "value": "C56516"},
              {"label": "Ome", "value": "C56517"},
              {"label": "Oneta", "value": "C56518"},
              {"label": "Ono San Pietro", "value": "C56519"},
              {"label": "Onore", "value": "C56520"},
              {"label": "Opera", "value": "C56521"},
              {"label": "Origgio", "value": "C56522"},
              {"label": "Orino", "value": "C56523"},
              {"label": "Orio al Serio", "value": "C56524"},
              {"label": "Orio Litta", "value": "C56525"},
              {"label": "Ornago", "value": "C56526"},
              {"label": "Ornica", "value": "C56527"},
              {"label": "Orsenigo", "value": "C56528"},
              {"label": "Orzinuovi", "value": "C56529"},
              {"label": "Orzivecchi", "value": "C56530"},
              {"label": "Osigo", "value": "C56531"},
              {"label": "Osio Sopra", "value": "C56532"},
              {"label": "Osio Sotto", "value": "C56533"},
              {"label": "Osmate", "value": "C56534"},
              {"label": "Osnago", "value": "C56535"},
              {"label": "Ospedaletto Lodigiano", "value": "C56536"},
              {"label": "Ospitaletto", "value": "C56537"},
              {"label": "Ossago Lodigiano", "value": "C56538"},
              {"label": "Ossimo Superiore", "value": "C56539"},
              {"label": "Ossona", "value": "C56540"},
              {"label": "Ossuccio", "value": "C56541"},
              {"label": "Osteno", "value": "C56542"},
              {"label": "Osteno-Claino", "value": "C56543"},
              {"label": "Ostiano", "value": "C56544"},
              {"label": "Ostiglia", "value": "C56545"},
              {"label": "Ottobiano", "value": "C56546"},
              {"label": "Ozzero", "value": "C56547"},
              {"label": "Padenghe sul Garda", "value": "C56548"},
              {"label": "Paderno dAdda", "value": "C56549"},
              {"label": "Paderno Dugnano", "value": "C56550"},
              {"label": "Paderno Franciacorta", "value": "C56551"},
              {"label": "Paderno Ponchielli", "value": "C56552"},
              {"label": "Pagazzano", "value": "C56553"},
              {"label": "Pagnona", "value": "C56554"},
              {"label": "Paisco Loveno", "value": "C56555"},
              {"label": "Paitone", "value": "C56556"},
              {"label": "Paladina", "value": "C56557"},
              {"label": "Palazzago", "value": "C56558"},
              {"label": "Palazzo Pignano", "value": "C56559"},
              {"label": "Palazzolo sullOglio", "value": "C56560"},
              {"label": "Palestro", "value": "C56561"},
              {"label": "Palidano", "value": "C56562"},
              {"label": "Palosco", "value": "C56563"},
              {"label": "Pancarana", "value": "C56564"},
              {"label": "Pandino", "value": "C56565"},
              {"label": "Pantigliate", "value": "C56566"},
              {"label": "Parabiago", "value": "C56567"},
              {"label": "Paratico", "value": "C56568"},
              {"label": "Pare", "value": "C56569"},
              {"label": "Parlasco", "value": "C56570"},
              {"label": "Parmezzana Calzana", "value": "C56571"},
              {"label": "Parona", "value": "C56572"},
              {"label": "Parre", "value": "C56573"},
              {"label": "Parzanica", "value": "C56574"},
              {"label": "Paspardo", "value": "C56575"},
              {"label": "Passirana", "value": "C56576"},
              {"label": "Passirano", "value": "C56577"},
              {"label": "Pasturo", "value": "C56578"},
              {"label": "Paullo", "value": "C56579"},
              {"label": "Pavia", "value": "C56580"},
              {"label": "Pavone del Mella", "value": "C56581"},
              {"label": "Pedesina", "value": "C56582"},
              {"label": "Pedrengo", "value": "C56583"},
              {"label": "Peglio", "value": "C56584"},
              {"label": "Pegognaga", "value": "C56585"},
              {"label": "Peia", "value": "C56586"},
              {"label": "Pellio Intelvi", "value": "C56587"},
              {"label": "Perego", "value": "C56588"},
              {"label": "Perledo", "value": "C56589"},
              {"label": "Pero", "value": "C56590"},
              {"label": "Persico Dosimo", "value": "C56591"},
              {"label": "Pertica Alta", "value": "C56592"},
              {"label": "Pertica Bassa", "value": "C56593"},
              {"label": "Pescarolo", "value": "C56594"},
              {"label": "Pescate", "value": "C56595"},
              {"label": "Pessano Con Bornago", "value": "C56596"},
              {"label": "Pessina Cremonese", "value": "C56597"},
              {"label": "Pezzaze", "value": "C56598"},
              {"label": "Piadena", "value": "C56599"},
              {"label": "Piamborno", "value": "C56600"},
              {"label": "Pian Camuno", "value": "C56601"},
              {"label": "Pianazzo", "value": "C56602"},
              {"label": "Pianello del Lario", "value": "C56603"},
              {"label": "Pianengo", "value": "C56604"},
              {"label": "Piangaiano", "value": "C56605"},
              {"label": "Pianico", "value": "C56606"},
              {"label": "Piantedo", "value": "C56607"},
              {"label": "Piario", "value": "C56608"},
              {"label": "Piateda Centro", "value": "C56609"},
              {"label": "Piazza", "value": "C56610"},
              {"label": "Piazza Brembana", "value": "C56611"},
              {"label": "Piazza Caduti", "value": "C56612"},
              {"label": "Piazzatorre", "value": "C56613"},
              {"label": "Piazzolo", "value": "C56614"},
              {"label": "Pieranica", "value": "C56615"},
              {"label": "Pietra de Giorgi", "value": "C56616"},
              {"label": "Pieve", "value": "C56617"},
              {"label": "Pieve Albignola", "value": "C56618"},
              {"label": "Pieve dOlmi", "value": "C56619"},
              {"label": "Pieve del Cairo", "value": "C56620"},
              {"label": "Pieve di Coriano", "value": "C56621"},
              {"label": "Pieve Emanuele", "value": "C56622"},
              {"label": "Pieve Fissiraga", "value": "C56623"},
              {"label": "Pieve Porto Morone", "value": "C56624"},
              {"label": "Pieve San Giacomo", "value": "C56625"},
              {"label": "Pievedizio", "value": "C56626"},
              {"label": "Pigra", "value": "C56627"},
              {"label": "Pinarolo Po", "value": "C56628"},
              {"label": "Pino sulla Sponda del Lago Maggiore",
               "value": "C56629"},
              {"label": "Pioltello", "value": "C56630"},
              {"label": "Pisogne", "value": "C56631"},
              {"label": "Piubega", "value": "C56632"},
              {"label": "Piuro", "value": "C56633"},
              {"label": "Pizzale", "value": "C56634"},
              {"label": "Pizzighettone", "value": "C56635"},
              {"label": "Plesio", "value": "C56636"},
              {"label": "Poasco-Sorigherio", "value": "C56637"},
              {"label": "Poggio Rusco", "value": "C56638"},
              {"label": "Poggiridenti Alto", "value": "C56639"},
              {"label": "Poggiridenti Piano", "value": "C56640"},
              {"label": "Pogliano Milanese", "value": "C56641"},
              {"label": "Pognana Lario", "value": "C56642"},
              {"label": "Pognano", "value": "C56643"},
              {"label": "Polaveno", "value": "C56644"},
              {"label": "Polpenazze del Garda", "value": "C56645"},
              {"label": "Pometo", "value": "C56646"},
              {"label": "Pompiano", "value": "C56647"},
              {"label": "Pomponesco", "value": "C56648"},
              {"label": "Poncarale", "value": "C56649"},
              {"label": "Ponna Superiore", "value": "C56650"},
              {"label": "Ponte Caffaro", "value": "C56651"},
              {"label": "Ponte Cingoli", "value": "C56652"},
              {"label": "Ponte di Legno", "value": "C56653"},
              {"label": "Ponte in Valtellina", "value": "C56654"},
              {"label": "Ponte Lambro", "value": "C56655"},
              {"label": "Ponte Nizza", "value": "C56656"},
              {"label": "Ponte Nossa", "value": "C56657"},
              {"label": "Ponte San Marco", "value": "C56658"},
              {"label": "Ponte San Pietro", "value": "C56659"},
              {"label": "Ponteranica", "value": "C56660"},
              {"label": "Pontevico", "value": "C56661"},
              {"label": "Ponti sul Mincio", "value": "C56662"},
              {"label": "Pontida", "value": "C56663"},
              {"label": "Pontirolo Nuovo", "value": "C56664"},
              {"label": "Pontoglio", "value": "C56665"},
              {"label": "Porlezza", "value": "C56666"},
              {"label": "Portalbera", "value": "C56667"},
              {"label": "Porto Ceresio", "value": "C56668"},
              {"label": "Porto dAdda", "value": "C56669"},
              {"label": "Porto Valtravaglia", "value": "C56670"},
              {"label": "Postalesio", "value": "C56671"},
              {"label": "Pozzaglio", "value": "C56672"},
              {"label": "Pozzaglio ed Uniti", "value": "C56673"},
              {"label": "Pozzo dAdda", "value": "C56674"},
              {"label": "Pozzolengo", "value": "C56675"},
              {"label": "Pozzuolo Martesana", "value": "C56676"},
              {"label": "Pradalunga", "value": "C56677"},
              {"label": "Pralboino", "value": "C56678"},
              {"label": "Prata Camportaccio", "value": "C56679"},
              {"label": "Prata Centro", "value": "C56680"},
              {"label": "Predore", "value": "C56681"},
              {"label": "Pregnana Milanese", "value": "C56682"},
              {"label": "Pregola", "value": "C56683"},
              {"label": "Premana", "value": "C56684"},
              {"label": "Premenugo", "value": "C56685"},
              {"label": "Premolo", "value": "C56686"},
              {"label": "Preseglie", "value": "C56687"},
              {"label": "Presezzo", "value": "C56688"},
              {"label": "Prevalle", "value": "C56689"},
              {"label": "Primaluna", "value": "C56690"},
              {"label": "Proserpio", "value": "C56691"},
              {"label": "Prosto", "value": "C56692"},
              {"label": "Provaglio dIseo", "value": "C56693"},
              {"label": "Provaglio Val Sabbia", "value": "C56694"},
              {"label": "Provezze", "value": "C56695"},
              {"label": "Provincia di Bergamo", "value": "C56696"},
              {"label": "Provincia di Brescia", "value": "C56697"},
              {"label": "Provincia di Como", "value": "C56698"},
              {"label": "Provincia di Cremona", "value": "C56699"},
              {"label": "Provincia di Lecco", "value": "C56700"},
              {"label": "Provincia di Lodi", "value": "C56701"},
              {"label": "Provincia di Mantova", "value": "C56702"},
              {"label": "Provincia di Monza e della Brianza",
               "value": "C56703"},
              {"label": "Provincia di Pavia", "value": "C56704"},
              {"label": "Provincia di Sondrio", "value": "C56705"},
              {"label": "Provincia di Varese", "value": "C56706"},
              {"label": "Puegnago sul Garda", "value": "C56707"},
              {"label": "Puginate", "value": "C56708"},
              {"label": "Pumenengo", "value": "C56709"},
              {"label": "Pusiano", "value": "C56710"},
              {"label": "Quartiano", "value": "C56711"},
              {"label": "Quattro Strade", "value": "C56712"},
              {"label": "Quingentole", "value": "C56713"},
              {"label": "Quintano", "value": "C56714"},
              {"label": "Quinzano dOglio", "value": "C56715"},
              {"label": "Quistello", "value": "C56716"},
              {"label": "Raffa", "value": "C56717"},
              {"label": "Ramponio Verna", "value": "C56718"},
              {"label": "Rancio Valcuvia", "value": "C56719"},
              {"label": "Ranco", "value": "C56720"},
              {"label": "Ranica", "value": "C56721"},
              {"label": "Ranzanico", "value": "C56722"},
              {"label": "Rasura", "value": "C56723"},
              {"label": "Rea", "value": "C56724"},
              {"label": "Redavalle", "value": "C56725"},
              {"label": "Redondesco", "value": "C56726"},
              {"label": "Regoledo", "value": "C56727"},
              {"label": "Remedello", "value": "C56728"},
              {"label": "Remedello di Sopra", "value": "C56729"},
              {"label": "Renate", "value": "C56730"},
              {"label": "Rescaldina", "value": "C56731"},
              {"label": "Retorbido", "value": "C56732"},
              {"label": "Revere", "value": "C56733"},
              {"label": "Rezzago", "value": "C56734"},
              {"label": "Rezzato", "value": "C56735"},
              {"label": "Rho", "value": "C56736"},
              {"label": "Ricengo", "value": "C56737"},
              {"label": "Riozzo", "value": "C56738"},
              {"label": "Ripalta Arpina", "value": "C56739"},
              {"label": "Ripalta Guerina", "value": "C56740"},
              {"label": "Ripalta Nuova", "value": "C56741"},
              {"label": "Riva di Solto", "value": "C56742"},
              {"label": "Rivanazzano", "value": "C56743"},
              {"label": "Rivarolo del Re", "value": "C56744"},
              {"label": "Rivarolo Mantovano", "value": "C56745"},
              {"label": "Rivolta dAdda", "value": "C56746"},
              {"label": "Robbiate", "value": "C56747"},
              {"label": "Robbio", "value": "C56748"},
              {"label": "Robecchetto Con Induno", "value": "C56749"},
              {"label": "Robecco dOglio", "value": "C56750"},
              {"label": "Robecco Pavese", "value": "C56751"},
              {"label": "Robecco sul Naviglio", "value": "C56752"},
              {"label": "Roccafranca", "value": "C56753"},
              {"label": "Rodengo-Saiano", "value": "C56754"},
              {"label": "Rodero", "value": "C56755"},
              {"label": "Rodigo", "value": "C56756"},
              {"label": "Roe", "value": "C56757"},
              {"label": "Roe Volciano", "value": "C56758"},
              {"label": "Rogeno", "value": "C56759"},
              {"label": "Roggione", "value": "C56760"},
              {"label": "Rognano", "value": "C56761"},
              {"label": "Rogno", "value": "C56762"},
              {"label": "Rogolo", "value": "C56763"},
              {"label": "Rogoredo-Valaperta-Rimoldo", "value": "C56764"},
              {"label": "Romagnese", "value": "C56765"},
              {"label": "Romanengo", "value": "C56766"},
              {"label": "Romano Banco", "value": "C56767"},
              {"label": "Romano di Lombardia", "value": "C56768"},
              {"label": "Ronago", "value": "C56769"},
              {"label": "Roncadelle", "value": "C56770"},
              {"label": "Roncaro", "value": "C56771"},
              {"label": "Roncello", "value": "C56772"},
              {"label": "Ronco", "value": "C56773"},
              {"label": "Ronco Briantino", "value": "C56774"},
              {"label": "Roncobello", "value": "C56775"},
              {"label": "Roncoferraro", "value": "C56776"},
              {"label": "Roncola", "value": "C56777"},
              {"label": "Rosasco", "value": "C56778"},
              {"label": "Rosate", "value": "C56779"},
              {"label": "Rota dImagna", "value": "C56780"},
              {"label": "Rotta", "value": "C56781"},
              {"label": "Rovagnate", "value": "C56782"},
              {"label": "Rovato", "value": "C56783"},
              {"label": "Rovellasca", "value": "C56784"},
              {"label": "Rovello Porro", "value": "C56785"},
              {"label": "Roverbella", "value": "C56786"},
              {"label": "Rovescala", "value": "C56787"},
              {"label": "Rovetta", "value": "C56788"},
              {"label": "Rozzano", "value": "C56789"},
              {"label": "Rudiano", "value": "C56790"},
              {"label": "Ruino", "value": "C56791"},
              {"label": "Sabbio Chiese", "value": "C56792"},
              {"label": "Sabbioneta", "value": "C56793"},
              {"label": "Sacca", "value": "C56794"},
              {"label": "Sairano", "value": "C56795"},
              {"label": "Sala al Barro", "value": "C56796"},
              {"label": "Sala Comacina", "value": "C56797"},
              {"label": "Sale Marasino", "value": "C56798"},
              {"label": "Salerano sul Lambro", "value": "C56799"},
              {"label": "Salice Terme", "value": "C56800"},
              {"label": "Salo", "value": "C56801"},
              {"label": "Saltrio", "value": "C56802"},
              {"label": "Salvirola", "value": "C56803"},
              {"label": "Samarate", "value": "C56804"},
              {"label": "Samolaco", "value": "C56805"},
              {"label": "San Bartolomeo Val Cavargna", "value": "C56806"},
              {"label": "San Bassano", "value": "C56807"},
              {"label": "San Benedetto Po", "value": "C56808"},
              {"label": "San Biagio", "value": "C56809"},
              {"label": "San Bovio-San Felice", "value": "C56810"},
              {"label": "San Cassiano", "value": "C56811"},
              {"label": "San Cipriano Po", "value": "C56812"},
              {"label": "San Colombano al Lambro", "value": "C56813"},
              {"label": "San Damiano al Colle", "value": "C56814"},
              {"label": "San Daniele Po", "value": "C56815"},
              {"label": "San Donato Milanese", "value": "C56816"},
              {"label": "San Fedele Intelvi", "value": "C56817"},
              {"label": "San Fedele Superiore", "value": "C56818"},
              {"label": "San Felice", "value": "C56819"},
              {"label": "San Felice del Benaco", "value": "C56820"},
              {"label": "San Fermo della Battaglia", "value": "C56821"},
              {"label": "San Fiorano", "value": "C56822"},
              {"label": "San Genesio ed Uniti", "value": "C56823"},
              {"label": "San Gervasio Bresciano", "value": "C56824"},
              {"label": "San Giacomo", "value": "C56825"},
              {"label": "San Giacomo delle Segnate", "value": "C56826"},
              {"label": "San Giacomo Filippo", "value": "C56827"},
              {"label": "San Giorgio di Lomellina", "value": "C56828"},
              {"label": "San Giorgio Su Legnano", "value": "C56829"},
              {"label": "San Giovanni Bianco", "value": "C56830"},
              {"label": "San Giovanni del Dosso", "value": "C56831"},
              {"label": "San Giovanni in Croce", "value": "C56832"},
              {"label": "San Giuliano Milanese", "value": "C56833"},
              {"label": "San Lorenzo di Rovetta", "value": "C56834"},
              {"label": "San Mamete", "value": "C56835"},
              {"label": "San Martino DallArgine", "value": "C56836"},
              {"label": "San Martino del Lago", "value": "C56837"},
              {"label": "San Martino della Battaglia", "value": "C56838"},
              {"label": "San Martino in Strada", "value": "C56839"},
              {"label": "San Martino Siccomario", "value": "C56840"},
              {"label": "San Michele-San Giorgio", "value": "C56841"},
              {"label": "San Nazzaro Val Cavargna", "value": "C56842"},
              {"label": "San Nicolo", "value": "C56843"},
              {"label": "San Paolo", "value": "C56844"},
              {"label": "San Paolo dArgon", "value": "C56845"},
              {"label": "San Pellegrino Terme", "value": "C56846"},
              {"label": "San Rocco", "value": "C56847"},
              {"label": "San Rocco al Porto", "value": "C56848"},
              {"label": "San Sebastiano", "value": "C56849"},
              {"label": "San Siro", "value": "C56850"},
              {"label": "San Vigilio", "value": "C56851"},
              {"label": "San Vittore", "value": "C56852"},
              {"label": "San Vittore Olona", "value": "C56853"},
              {"label": "San Zeno Naviglio", "value": "C56854"},
              {"label": "San Zenone", "value": "C56855"},
              {"label": "San Zenone al Lambro", "value": "C56856"},
              {"label": "San Zenone al Po", "value": "C56857"},
              {"label": "Sangiano", "value": "C56858"},
              {"label": "Sannazzaro de Burgondi", "value": "C56859"},
              {"label": "SantAgata Martesana", "value": "C56860"},
              {"label": "SantAlessio Con Vialone", "value": "C56861"},
              {"label": "SantAngelo Lodigiano", "value": "C56862"},
              {"label": "SantAngelo Lomellina", "value": "C56863"},
              {"label": "SantAntonio", "value": "C56864"},
              {"label": "SantOmobono Terme", "value": "C56865"},
              {"label": "Santa Brigida", "value": "C56866"},
              {"label": "Santa Corinna", "value": "C56867"},
              {"label": "Santa Cristina", "value": "C56868"},
              {"label": "Santa Giuletta", "value": "C56869"},
              {"label": "Santa Margherita", "value": "C56870"},
              {"label": "Santa Margherita di Staffora", "value": "C56871"},
              {"label": "Santa Maria dei Sabbioni", "value": "C56872"},
              {"label": "Santa Maria della Versa", "value": "C56873"},
              {"label": "Santa Maria Hoe", "value": "C56874"},
              {"label": "Santo Stefano Lodigiano", "value": "C56875"},
              {"label": "Santo Stefano Ticino", "value": "C56876"},
              {"label": "Sarezzo", "value": "C56877"},
              {"label": "Sarnico", "value": "C56878"},
              {"label": "Saronno", "value": "C56879"},
              {"label": "Sartirana Lomellina", "value": "C56880"},
              {"label": "Saviore", "value": "C56881"},
              {"label": "Scaldasole", "value": "C56882"},
              {"label": "Scandolara Ravara", "value": "C56883"},
              {"label": "Scandolara Ripa dOglio", "value": "C56884"},
              {"label": "Scannabue-Cascine Capri", "value": "C56885"},
              {"label": "Scano al Brembo", "value": "C56886"},
              {"label": "Scanzorosciate", "value": "C56887"},
              {"label": "Schignano", "value": "C56888"},
              {"label": "Schilpario", "value": "C56889"},
              {"label": "Schivenoglia", "value": "C56890"},
              {"label": "Secugnago", "value": "C56891"},
              {"label": "Sedriano", "value": "C56892"},
              {"label": "Sedrina", "value": "C56893"},
              {"label": "Segrate", "value": "C56894"},
              {"label": "Selino Basso", "value": "C56895"},
              {"label": "Sellero", "value": "C56896"},
              {"label": "Selvino", "value": "C56897"},
              {"label": "Semiana", "value": "C56898"},
              {"label": "Senago", "value": "C56899"},
              {"label": "Seniga", "value": "C56900"},
              {"label": "Senna Comasco", "value": "C56901"},
              {"label": "Senna Lodigiana", "value": "C56902"},
              {"label": "Seregno", "value": "C56903"},
              {"label": "Sergnano", "value": "C56904"},
              {"label": "Seriate", "value": "C56905"},
              {"label": "Serina", "value": "C56906"},
              {"label": "Serle", "value": "C56907"},
              {"label": "Sermide", "value": "C56908"},
              {"label": "Sernio", "value": "C56909"},
              {"label": "Serone", "value": "C56910"},
              {"label": "Serravalle a Po", "value": "C56911"},
              {"label": "Sesto Calende", "value": "C56912"},
              {"label": "Sesto ed Uniti", "value": "C56913"},
              {"label": "Sesto San Giovanni", "value": "C56914"},
              {"label": "Settala", "value": "C56915"},
              {"label": "Settimo Milanese", "value": "C56916"},
              {"label": "Seveso", "value": "C56917"},
              {"label": "Silvano Pietra", "value": "C56918"},
              {"label": "Sirmione", "value": "C56919"},
              {"label": "Sirone", "value": "C56920"},
              {"label": "Sirta", "value": "C56921"},
              {"label": "Sirtori", "value": "C56922"},
              {"label": "Siviano", "value": "C56923"},
              {"label": "Siziano", "value": "C56924"},
              {"label": "Soave", "value": "C56925"},
              {"label": "Soiano", "value": "C56926"},
              {"label": "Solaro", "value": "C56927"},
              {"label": "Solarolo", "value": "C56928"},
              {"label": "Solarolo Rainerio", "value": "C56929"},
              {"label": "Solbiate", "value": "C56930"},
              {"label": "Solbiate Arno", "value": "C56931"},
              {"label": "Solbiate Olona", "value": "C56932"},
              {"label": "Solferino", "value": "C56933"},
              {"label": "Solto Collina", "value": "C56934"},
              {"label": "Solza", "value": "C56935"},
              {"label": "Somaglia", "value": "C56936"},
              {"label": "Somma Lombardo", "value": "C56937"},
              {"label": "Sommo", "value": "C56938"},
              {"label": "Soncino", "value": "C56939"},
              {"label": "Sondalo", "value": "C56940"},
              {"label": "Sondrio", "value": "C56941"},
              {"label": "Songavazzo", "value": "C56942"},
              {"label": "Sonico", "value": "C56943"},
              {"label": "Sordio", "value": "C56944"},
              {"label": "Soresina", "value": "C56945"},
              {"label": "Sorico", "value": "C56946"},
              {"label": "Sorisole", "value": "C56947"},
              {"label": "Sormano", "value": "C56948"},
              {"label": "Sospiro", "value": "C56949"},
              {"label": "Sotto il Monte Giovanni XXIII", "value": "C56950"},
              {"label": "Sottocastello", "value": "C56951"},
              {"label": "Sovere", "value": "C56952"},
              {"label": "Sovico", "value": "C56953"},
              {"label": "Spessa", "value": "C56954"},
              {"label": "Spinadesco", "value": "C56955"},
              {"label": "Spineda", "value": "C56956"},
              {"label": "Spino dAdda", "value": "C56957"},
              {"label": "Spinone al Lago", "value": "C56958"},
              {"label": "Spirano", "value": "C56959"},
              {"label": "Spriana", "value": "C56960"},
              {"label": "Stagno Lombardo", "value": "C56961"},
              {"label": "Stazzona", "value": "C56962"},
              {"label": "Stezzano", "value": "C56963"},
              {"label": "Stradella", "value": "C56964"},
              {"label": "Stravignino", "value": "C56965"},
              {"label": "Strozza", "value": "C56966"},
              {"label": "Suardi", "value": "C56967"},
              {"label": "Sueglio", "value": "C56968"},
              {"label": "Suello", "value": "C56969"},
              {"label": "Suisio", "value": "C56970"},
              {"label": "Sulbiate", "value": "C56971"},
              {"label": "Sulzano", "value": "C56972"},
              {"label": "Sumirago", "value": "C56973"},
              {"label": "Susella", "value": "C56974"},
              {"label": "Sustinente", "value": "C56975"},
              {"label": "Suzzara", "value": "C56976"},
              {"label": "Tabiago-Cibrone", "value": "C56977"},
              {"label": "Taceno", "value": "C56978"},
              {"label": "Taino", "value": "C56979"},
              {"label": "Talamona", "value": "C56980"},
              {"label": "Taleggio", "value": "C56981"},
              {"label": "Tartano", "value": "C56982"},
              {"label": "Tavazzano", "value": "C56983"},
              {"label": "Tavernerio", "value": "C56984"},
              {"label": "Tavernola Bergamasca", "value": "C56985"},
              {"label": "Tavernole", "value": "C56986"},
              {"label": "Teglio", "value": "C56987"},
              {"label": "Telgate", "value": "C56988"},
              {"label": "Temu", "value": "C56989"},
              {"label": "Ternate", "value": "C56990"},
              {"label": "Terno dIsola", "value": "C56991"},
              {"label": "Terranova dei Passerini", "value": "C56992"},
              {"label": "Terraverde-Corte Palasio", "value": "C56993"},
              {"label": "Ticengo", "value": "C56994"},
              {"label": "Tirano", "value": "C56995"},
              {"label": "Torbole Casaglia", "value": "C56996"},
              {"label": "Torchione-Moia", "value": "C56997"},
              {"label": "Torlino Vimercati", "value": "C56998"},
              {"label": "Tornata", "value": "C56999"},
              {"label": "Torno", "value": "C57000"},
              {"label": "Torrazza Coste", "value": "C57001"},
              {"label": "Torrazza dei Mandelli", "value": "C57002"},
              {"label": "Torre Beretti", "value": "C57003"},
              {"label": "Torre Boldone", "value": "C57004"},
              {"label": "Torre dArese", "value": "C57005"},
              {"label": "Torre dIsola", "value": "C57006"},
              {"label": "Torre de Busi", "value": "C57007"},
              {"label": "Torre de Negri", "value": "C57008"},
              {"label": "Torre de Picenardi", "value": "C57009"},
              {"label": "Torre de Roveri", "value": "C57010"},
              {"label": "Torre del Mangano", "value": "C57011"},
              {"label": "Torre di Santa Maria", "value": "C57012"},
              {"label": "Torre Pallavicina", "value": "C57013"},
              {"label": "Torrevecchia Pia", "value": "C57014"},
              {"label": "Torricella del Pizzo", "value": "C57015"},
              {"label": "Torricella Verzate", "value": "C57016"},
              {"label": "Toscolano Maderno", "value": "C57017"},
              {"label": "Tovo di SantAgata", "value": "C57018"},
              {"label": "Tradate", "value": "C57019"},
              {"label": "Traona", "value": "C57020"},
              {"label": "Travaco Siccomario", "value": "C57021"},
              {"label": "Travagliato", "value": "C57022"},
              {"label": "Travedona Monate", "value": "C57023"},
              {"label": "Trebbio", "value": "C57024"},
              {"label": "Trecella", "value": "C57025"},
              {"label": "Tregasio", "value": "C57026"},
              {"label": "Tremenico", "value": "C57027"},
              {"label": "Tremezzo", "value": "C57028"},
              {"label": "Trenzano", "value": "C57029"},
              {"label": "Trescore Balneario", "value": "C57030"},
              {"label": "Trescore Cremasco", "value": "C57031"},
              {"label": "Tresivio", "value": "C57032"},
              {"label": "Treviglio", "value": "C57033"},
              {"label": "Treviolo", "value": "C57034"},
              {"label": "Trezzano Rosa", "value": "C57035"},
              {"label": "Trezzano sul Naviglio", "value": "C57036"},
              {"label": "Trezzo sullAdda", "value": "C57037"},
              {"label": "Trezzone", "value": "C57038"},
              {"label": "Tribiano", "value": "C57039"},
              {"label": "Triginto", "value": "C57040"},
              {"label": "Trigolo", "value": "C57041"},
              {"label": "Triuggio", "value": "C57042"},
              {"label": "Trivolzio", "value": "C57043"},
              {"label": "Tromello", "value": "C57044"},
              {"label": "Tronzano Lago Maggiore", "value": "C57045"},
              {"label": "Trovo", "value": "C57046"},
              {"label": "Truccazzano", "value": "C57047"},
              {"label": "Turano Lodigiano", "value": "C57048"},
              {"label": "Turate", "value": "C57049"},
              {"label": "Turbigo", "value": "C57050"},
              {"label": "Ubiale", "value": "C57051"},
              {"label": "Ubiale Clanezzo", "value": "C57052"},
              {"label": "Uboldo", "value": "C57053"},
              {"label": "Uggiate Trevano", "value": "C57054"},
              {"label": "Urago dOglio", "value": "C57055"},
              {"label": "Urgnano", "value": "C57056"},
              {"label": "Usmate-Velate", "value": "C57057"},
              {"label": "Vaiano Cremasco", "value": "C57058"},
              {"label": "Vailate", "value": "C57059"},
              {"label": "Val Masino", "value": "C57060"},
              {"label": "Val Rezzo", "value": "C57061"},
              {"label": "Valbrona", "value": "C57062"},
              {"label": "Valdisotto", "value": "C57063"},
              {"label": "Valeggio", "value": "C57064"},
              {"label": "Valera Fratta", "value": "C57065"},
              {"label": "Valganna", "value": "C57066"},
              {"label": "Valgoglio", "value": "C57067"},
              {"label": "Valgreghentino", "value": "C57068"},
              {"label": "VallAlta", "value": "C57069"},
              {"label": "Valle Lomellina", "value": "C57070"},
              {"label": "Valle Salimbene", "value": "C57071"},
              {"label": "Valleve", "value": "C57072"},
              {"label": "Vallio Terme", "value": "C57073"},
              {"label": "Valmadrera-Caserta", "value": "C57074"},
              {"label": "Valmorea", "value": "C57075"},
              {"label": "Valnegra", "value": "C57076"},
              {"label": "Valtorta", "value": "C57077"},
              {"label": "Valvestino", "value": "C57078"},
              {"label": "Vanzaghello", "value": "C57079"},
              {"label": "Vanzago", "value": "C57080"},
              {"label": "Vaprio dAdda", "value": "C57081"},
              {"label": "Varano Borghi", "value": "C57082"},
              {"label": "Varedo", "value": "C57083"},
              {"label": "Varenna", "value": "C57084"},
              {"label": "Varese", "value": "C57085"},
              {"label": "Varzi", "value": "C57086"},
              {"label": "Vassena", "value": "C57087"},
              {"label": "Vedano al Lambro", "value": "C57088"},
              {"label": "Vedano Olona", "value": "C57089"},
              {"label": "Veddasca", "value": "C57090"},
              {"label": "Vedeseta", "value": "C57091"},
              {"label": "Veduggio Con Colzano", "value": "C57092"},
              {"label": "Velasca", "value": "C57093"},
              {"label": "Veleso", "value": "C57094"},
              {"label": "Velezzo Lomellina", "value": "C57095"},
              {"label": "Vellezzo Bellini", "value": "C57096"},
              {"label": "Vendrogno", "value": "C57097"},
              {"label": "Venegono Inferiore", "value": "C57098"},
              {"label": "Venegono Superiore", "value": "C57099"},
              {"label": "Veniano", "value": "C57100"},
              {"label": "Verano Brianza", "value": "C57101"},
              {"label": "Vercana", "value": "C57102"},
              {"label": "Verceia", "value": "C57103"},
              {"label": "Vercurago", "value": "C57104"},
              {"label": "Verdellino", "value": "C57105"},
              {"label": "Verdello", "value": "C57106"},
              {"label": "Verderio Inferiore", "value": "C57107"},
              {"label": "Verderio Superiore", "value": "C57108"},
              {"label": "Vergano-Villa", "value": "C57109"},
              {"label": "Vergiate", "value": "C57110"},
              {"label": "Vermezzo", "value": "C57111"},
              {"label": "Vernate", "value": "C57112"},
              {"label": "Verolanuova", "value": "C57113"},
              {"label": "Verolavecchia", "value": "C57114"},
              {"label": "Verretto", "value": "C57115"},
              {"label": "Verrua Po", "value": "C57116"},
              {"label": "Vertemate Con Minoprio", "value": "C57117"},
              {"label": "Vertova", "value": "C57118"},
              {"label": "Vervio", "value": "C57119"},
              {"label": "Vescovato", "value": "C57120"},
              {"label": "Vestone", "value": "C57121"},
              {"label": "Vestreno", "value": "C57122"},
              {"label": "Vezza dOglio", "value": "C57123"},
              {"label": "Viadana", "value": "C57124"},
              {"label": "Viadanica", "value": "C57125"},
              {"label": "Vicomoscano", "value": "C57126"},
              {"label": "Vidalengo", "value": "C57127"},
              {"label": "Vidardo", "value": "C57128"},
              {"label": "Vidigulfo", "value": "C57129"},
              {"label": "Vigano", "value": "C57130"},
              {"label": "Vigano San Martino", "value": "C57131"},
              {"label": "Vigevano", "value": "C57132"},
              {"label": "Viggiu", "value": "C57133"},
              {"label": "Vighignolo", "value": "C57134"},
              {"label": "Vighizzolo", "value": "C57135"},
              {"label": "Vigliano-Bettolino", "value": "C57136"},
              {"label": "Vignate", "value": "C57137"},
              {"label": "Vignola", "value": "C57138"},
              {"label": "Vigolo", "value": "C57139"},
              {"label": "Vigonzone", "value": "C57140"},
              {"label": "VillAlbese", "value": "C57141"},
              {"label": "Villa", "value": "C57142"},
              {"label": "Villa Biscossi", "value": "C57143"},
              {"label": "Villa Carcina", "value": "C57144"},
              {"label": "Villa Cortese", "value": "C57145"},
              {"label": "Villa dAdda", "value": "C57146"},
              {"label": "Villa dAlme", "value": "C57147"},
              {"label": "Villa dOgna", "value": "C57148"},
              {"label": "Villa dOneta", "value": "C57149"},
              {"label": "Villa di Chiavenna", "value": "C57150"},
              {"label": "Villa di Serio", "value": "C57151"},
              {"label": "Villa Fornace", "value": "C57152"},
              {"label": "Villa Guardia", "value": "C57153"},
              {"label": "Villa Pedergnano", "value": "C57154"},
              {"label": "Villa Poma", "value": "C57155"},
              {"label": "Villa Raverio", "value": "C57156"},
              {"label": "Villachiara", "value": "C57157"},
              {"label": "Villaggio del Sole", "value": "C57158"},
              {"label": "Villaggio Residenziale", "value": "C57159"},
              {"label": "Villanova", "value": "C57160"},
              {"label": "Villanova dArdenghi", "value": "C57161"},
              {"label": "Villanova del Sillaro", "value": "C57162"},
              {"label": "Villanterio", "value": "C57163"},
              {"label": "Villanuova", "value": "C57164"},
              {"label": "Villanuova sul Clisi", "value": "C57165"},
              {"label": "Villapinta", "value": "C57166"},
              {"label": "Villasanta", "value": "C57167"},
              {"label": "Villassio", "value": "C57168"},
              {"label": "Villimpenta", "value": "C57169"},
              {"label": "Villongo", "value": "C57170"},
              {"label": "Vilminore", "value": "C57171"},
              {"label": "Vilminore di Scalve", "value": "C57172"},
              {"label": "Vimercate", "value": "C57173"},
              {"label": "Vimodrone", "value": "C57174"},
              {"label": "Vione", "value": "C57175"},
              {"label": "Visano", "value": "C57176"},
              {"label": "Vistarino", "value": "C57177"},
              {"label": "Vittuone", "value": "C57178"},
              {"label": "Vizzola Ticino", "value": "C57179"},
              {"label": "Vizzolo Predabissi", "value": "C57180"},
              {"label": "Vobarno", "value": "C57181"},
              {"label": "Voghera", "value": "C57182"},
              {"label": "Volongo", "value": "C57183"},
              {"label": "Volpara", "value": "C57184"},
              {"label": "Volta Mantovana", "value": "C57185"},
              {"label": "Voltido", "value": "C57186"},
              {"label": "Zandobbio", "value": "C57187"},
              {"label": "Zanica", "value": "C57188"},
              {"label": "Zavattarello", "value": "C57189"},
              {"label": "Zeccone", "value": "C57190"},
              {"label": "Zelbio", "value": "C57191"},
              {"label": "Zelo Buon Persico", "value": "C57192"},
              {"label": "Zelo Surrigone", "value": "C57193"},
              {"label": "Zeme", "value": "C57194"},
              {"label": "Zenevredo", "value": "C57195"},
              {"label": "Zerbo", "value": "C57196"},
              {"label": "Zerbolo", "value": "C57197"},
              {"label": "Zibido San Giacomo", "value": "C57198"},
              {"label": "Zinasco Vecchio", "value": "C57199"},
              {"label": "Zoccorino-Vergo", "value": "C57200"},
              {"label": "Zogno", "value": "C57201"},
              {"label": "Zone", "value": "C57202"},
              {"label": "Zorlesco", "value": "C57203"}],
 "Long An": [{"label": "Can Giuoc", "value": "C147507"},
             {"label": "Huyen Ben Luc", "value": "C147508"},
             {"label": "Huyen Can Duoc", "value": "C147509"},
             {"label": "Huyen Can Giuoc", "value": "C147510"},
             {"label": "Huyen Chau Thanh", "value": "C147511"},
             {"label": "Huyen Duc Hoa", "value": "C147512"},
             {"label": "Huyen Duc Hue", "value": "C147513"},
             {"label": "Huyen Moc Hoa", "value": "C147514"},
             {"label": "Huyen Tan Hung", "value": "C147515"},
             {"label": "Huyen Tan Thanh", "value": "C147516"},
             {"label": "Huyen Tan Tru", "value": "C147517"},
             {"label": "Huyen Thanh Hoa", "value": "C147518"},
             {"label": "Huyen Thu Thua", "value": "C147519"},
             {"label": "Huyen Vinh Hung", "value": "C147520"},
             {"label": "Tan An", "value": "C147521"}],
 "Long Island": [{"label": "Clarence Town", "value": "C119614"}],
 "Lop Buri": [{"label": "Amphoe Ban Mi", "value": "C118756"},
              {"label": "Amphoe Chai Badan", "value": "C118757"},
              {"label": "Amphoe Khok Charoen", "value": "C118758"},
              {"label": "Amphoe Khok Samrong", "value": "C118759"},
              {"label": "Amphoe Lam Sonthi", "value": "C118760"},
              {"label": "Amphoe Nong Muang", "value": "C118761"},
              {"label": "Amphoe Phatthana Nikhom", "value": "C118762"},
              {"label": "Amphoe Sa Bot", "value": "C118763"},
              {"label": "Amphoe Tha Luang", "value": "C118764"},
              {"label": "Amphoe Tha Wung", "value": "C118765"},
              {"label": "Chai Badan", "value": "C118766"},
              {"label": "Lop Buri", "value": "C118767"},
              {"label": "Muang Lop Buri District", "value": "C118768"}],
 "Lorestan": [{"label": "Aleshtar", "value": "C50877"},
              {"label": "Aligudarz", "value": "C50878"},
              {"label": "Azna", "value": "C50879"},
              {"label": "Borujerd", "value": "C50880"},
              {"label": "Delfan", "value": "C50881"},
              {"label": "Khorramabad", "value": "C50882"},
              {"label": "Kuhdasht", "value": "C50883"},
              {"label": "Nurabad", "value": "C50884"},
              {"label": "Pol-e Dokhtar", "value": "C50885"},
              {"label": "Rumeshkhan County", "value": "C50886"},
              {"label": "Selseleh", "value": "C50887"},
              {"label": "Shahrestan-e Aligudarz", "value": "C50888"},
              {"label": "Shahrestan-e Azna", "value": "C50889"},
              {"label": "Shahrestan-e Borujerd", "value": "C50890"},
              {"label": "Shahrestan-e Dureh", "value": "C50891"},
              {"label": "Shahrestan-e Khorramabad", "value": "C50892"},
              {"label": "Shahrestan-e Kuhdasht", "value": "C50893"},
              {"label": "Vasian", "value": "C50894"}],
 "Loreto": [{"label": "Andoas", "value": "C80963"},
            {"label": "Borja", "value": "C80964"},
            {"label": "Caballococha", "value": "C80965"},
            {"label": "Cape Pantoja", "value": "C80966"},
            {"label": "Indiana", "value": "C80967"},
            {"label": "Iquitos", "value": "C80968"},
            {"label": "Jeberos", "value": "C80969"},
            {"label": "Jenaro Herrera", "value": "C80970"},
            {"label": "Lagunas", "value": "C80971"},
            {"label": "Nauta", "value": "C80972"},
            {"label": "Punchana", "value": "C80973"},
            {"label": "Requena", "value": "C80974"},
            {"label": "San Antonio del Estrecho", "value": "C80975"},
            {"label": "San Lorenzo", "value": "C80976"},
            {"label": "San Pablo", "value": "C80977"},
            {"label": "Tamshiyacu", "value": "C80978"},
            {"label": "Yurimaguas", "value": "C80979"}],
 "Lori Region": [{"label": "Agarak", "value": "C1741"},
                 {"label": "Akhtala", "value": "C1742"},
                 {"label": "Alaverdi", "value": "C1743"},
                 {"label": "Arevashogh", "value": "C1744"},
                 {"label": "Bazum", "value": "C1745"},
                 {"label": "Chochkan", "value": "C1746"},
                 {"label": "Darpas", "value": "C1747"},
                 {"label": "Dsegh", "value": "C1748"},
                 {"label": "Fioletovo", "value": "C1749"},
                 {"label": "Gogaran", "value": "C1750"},
                 {"label": "Gugark", "value": "C1751"},
                 {"label": "Gyulagarak", "value": "C1752"},
                 {"label": "Jrashen", "value": "C1753"},
                 {"label": "Lerrnantsk", "value": "C1754"},
                 {"label": "Lerrnapat", "value": "C1755"},
                 {"label": "Lerrnavan", "value": "C1756"},
                 {"label": "Lorut", "value": "C1757"},
                 {"label": "Margahovit", "value": "C1758"},
                 {"label": "Mets Parni", "value": "C1759"},
                 {"label": "Metsavan", "value": "C1760"},
                 {"label": "Norashen", "value": "C1761"},
                 {"label": "Odzun", "value": "C1762"},
                 {"label": "Sarahart", "value": "C1763"},
                 {"label": "Saramej", "value": "C1764"},
                 {"label": "Shahumyan", "value": "C1765"},
                 {"label": "Shirakamut", "value": "C1766"},
                 {"label": "Shnogh", "value": "C1767"},
                 {"label": "Spitak", "value": "C1768"},
                 {"label": "Stepanavan", "value": "C1769"},
                 {"label": "Tashir", "value": "C1770"},
                 {"label": "Tsaghkaber", "value": "C1771"},
                 {"label": "Urrut", "value": "C1772"},
                 {"label": "Vahagni", "value": "C1773"},
                 {"label": "Vanadzor", "value": "C1774"},
                 {"label": "Vardablur", "value": "C1775"},
                 {"label": "Yeghegnut", "value": "C1776"}],
 "Los Lagos": [{"label": "Ancud", "value": "C18037"},
               {"label": "Calbuco", "value": "C18038"},
               {"label": "Castro", "value": "C18039"},
               {"label": "Chaiten", "value": "C18040"},
               {"label": "Chonchi", "value": "C18041"},
               {"label": "Cochamo", "value": "C18042"},
               {"label": "Curaco de Velez", "value": "C18043"},
               {"label": "Dalcahue", "value": "C18044"},
               {"label": "Fresia", "value": "C18045"},
               {"label": "Frutillar", "value": "C18046"},
               {"label": "Futaleufu", "value": "C18047"},
               {"label": "Hualaihue", "value": "C18048"},
               {"label": "Llanquihue", "value": "C18049"},
               {"label": "Los Muermos", "value": "C18050"},
               {"label": "Maullin", "value": "C18051"},
               {"label": "Osorno", "value": "C18052"},
               {"label": "Palena", "value": "C18053"},
               {"label": "Puerto Montt", "value": "C18054"},
               {"label": "Puerto Octay", "value": "C18055"},
               {"label": "Puerto Varas", "value": "C18056"},
               {"label": "Puqueldon", "value": "C18057"},
               {"label": "Purranque", "value": "C18058"},
               {"label": "Puyehue", "value": "C18059"},
               {"label": "Queilen", "value": "C18060"},
               {"label": "Quellon", "value": "C18061"},
               {"label": "Quemchi", "value": "C18062"},
               {"label": "Quinchao", "value": "C18063"},
               {"label": "Rio Negro", "value": "C18064"},
               {"label": "San Juan de la Costa", "value": "C18065"},
               {"label": "San Pablo", "value": "C18066"}],
 "Los Rios": [{"label": "Corral", "value": "C18067"},
              {"label": "Futrono", "value": "C18068"},
              {"label": "La Union", "value": "C18069"},
              {"label": "Lago Ranco", "value": "C18070"},
              {"label": "Lanco", "value": "C18071"},
              {"label": "Los Lagos", "value": "C18072"},
              {"label": "Mafil", "value": "C18073"},
              {"label": "Mariquina", "value": "C18074"},
              {"label": "Paillaco", "value": "C18075"},
              {"label": "Panguipulli", "value": "C18076"},
              {"label": "Rio Bueno", "value": "C18077"},
              {"label": "Valdivia", "value": "C18078"},
              {"label": "Babahoyo", "value": "C24157"},
              {"label": "Catarama", "value": "C24158"},
              {"label": "Montalvo", "value": "C24159"},
              {"label": "Palenque", "value": "C24160"},
              {"label": "Quevedo", "value": "C24161"},
              {"label": "Ventanas", "value": "C24162"},
              {"label": "Vinces", "value": "C24163"}],
 "Los Santos Province": [{"label": "Agua Buena", "value": "C80087"},
                         {"label": "Ave Maria", "value": "C80088"},
                         {"label": "Bahia Honda", "value": "C80089"},
                         {"label": "Bajo Corral", "value": "C80090"},
                         {"label": "Bayano", "value": "C80091"},
                         {"label": "Bella Vista", "value": "C80092"},
                         {"label": "Cambutal", "value": "C80093"},
                         {"label": "Canas", "value": "C80094"},
                         {"label": "Cedro Arriba", "value": "C80095"},
                         {"label": "Distrito de Guarare", "value": "C80096"},
                         {"label": "Distrito de Las Tablas", "value": "C80097"},
                         {"label": "Distrito de Los Santos", "value": "C80098"},
                         {"label": "Distrito de Macaracas", "value": "C80099"},
                         {"label": "Distrito de Pedasi", "value": "C80100"},
                         {"label": "Distrito de Pocri", "value": "C80101"},
                         {"label": "Distrito de Tonosi", "value": "C80102"},
                         {"label": "El Cacao", "value": "C80103"},
                         {"label": "El Canafistulo", "value": "C80104"},
                         {"label": "El Carate", "value": "C80105"},
                         {"label": "El Cocal", "value": "C80106"},
                         {"label": "El Cortezo", "value": "C80107"},
                         {"label": "El Ejido", "value": "C80108"},
                         {"label": "El Guasimo", "value": "C80109"},
                         {"label": "El Hato", "value": "C80110"},
                         {"label": "El Macano", "value": "C80111"},
                         {"label": "El Manantial", "value": "C80112"},
                         {"label": "El Munoz", "value": "C80113"},
                         {"label": "El Pedregoso", "value": "C80114"},
                         {"label": "Flores", "value": "C80115"},
                         {"label": "Guarare", "value": "C80116"},
                         {"label": "Guarare Arriba", "value": "C80117"},
                         {"label": "La Colorada", "value": "C80118"},
                         {"label": "La Enea", "value": "C80119"},
                         {"label": "La Espigadilla", "value": "C80120"},
                         {"label": "La Laja", "value": "C80121"},
                         {"label": "La Mesa", "value": "C80122"},
                         {"label": "La Miel", "value": "C80123"},
                         {"label": "La Palma", "value": "C80124"},
                         {"label": "La Pasera", "value": "C80125"},
                         {"label": "La Tiza", "value": "C80126"},
                         {"label": "La Tronosa", "value": "C80127"},
                         {"label": "Lajamina", "value": "C80128"},
                         {"label": "Las Cruces", "value": "C80129"},
                         {"label": "Las Guabas", "value": "C80130"},
                         {"label": "Las Palmas", "value": "C80131"},
                         {"label": "Las Palmitas", "value": "C80132"},
                         {"label": "Las Tablas", "value": "C80133"},
                         {"label": "Las Trancas", "value": "C80134"},
                         {"label": "Llano Abajo", "value": "C80135"},
                         {"label": "Llano de Piedra", "value": "C80136"},
                         {"label": "Llano Largo", "value": "C80137"},
                         {"label": "Los angeles", "value": "C80138"},
                         {"label": "Los Asientos", "value": "C80139"},
                         {"label": "Los Olivos", "value": "C80140"},
                         {"label": "Los Santos", "value": "C80141"},
                         {"label": "Macaracas", "value": "C80142"},
                         {"label": "Mogollon", "value": "C80143"},
                         {"label": "Nuario", "value": "C80144"},
                         {"label": "Oria Arriba", "value": "C80145"},
                         {"label": "Palmira", "value": "C80146"},
                         {"label": "Paraiso", "value": "C80147"},
                         {"label": "Paritilla", "value": "C80148"},
                         {"label": "Pedasi", "value": "C80149"},
                         {"label": "Pena Blanca", "value": "C80150"},
                         {"label": "Perales", "value": "C80151"},
                         {"label": "Pocri", "value": "C80152"},
                         {"label": "Rio Hondo", "value": "C80153"},
                         {"label": "Sabana Grande", "value": "C80154"},
                         {"label": "San Jose", "value": "C80155"},
                         {"label": "Santa Ana Arriba", "value": "C80156"},
                         {"label": "Santo Domingo", "value": "C80157"},
                         {"label": "Sesteadero", "value": "C80158"},
                         {"label": "Tonosi", "value": "C80159"},
                         {"label": "Tres Quebradas", "value": "C80160"},
                         {"label": "Valle Rico", "value": "C80161"},
                         {"label": "Vallerriquito", "value": "C80162"},
                         {"label": "Villa Lourdes", "value": "C80163"}],
 "Loska Dolina Municipality": [{"label": "Leskova Dolina", "value": "C107704"}],
 "Loski Potok Municipality": [{"label": "Hrib-Loski Potok",
                               "value": "C107705"}],
 "Louga": [{"label": "Dara", "value": "C106855"},
           {"label": "Gueoul", "value": "C106856"},
           {"label": "Linguere Department", "value": "C106857"},
           {"label": "Louga", "value": "C106858"},
           {"label": "Ndibene Dahra", "value": "C106859"}],
 "Louisiana": [{"label": "Abbeville", "value": "C133675"},
               {"label": "Abita Springs", "value": "C133676"},
               {"label": "Acadia Parish", "value": "C133677"},
               {"label": "Addis", "value": "C133678"},
               {"label": "Albany", "value": "C133679"},
               {"label": "Alexandria", "value": "C133680"},
               {"label": "Allen Parish", "value": "C133681"},
               {"label": "Ama", "value": "C133682"},
               {"label": "Amelia", "value": "C133683"},
               {"label": "Amite", "value": "C133684"},
               {"label": "Arabi", "value": "C133685"},
               {"label": "Arcadia", "value": "C133686"},
               {"label": "Arnaudville", "value": "C133687"},
               {"label": "Ascension Parish", "value": "C133688"},
               {"label": "Assumption Parish", "value": "C133689"},
               {"label": "Avondale", "value": "C133690"},
               {"label": "Avoyelles Parish", "value": "C133691"},
               {"label": "Baker", "value": "C133692"},
               {"label": "Baldwin", "value": "C133693"},
               {"label": "Ball", "value": "C133694"},
               {"label": "Banks Springs", "value": "C133695"},
               {"label": "Barataria", "value": "C133696"},
               {"label": "Basile", "value": "C133697"},
               {"label": "Bastrop", "value": "C133698"},
               {"label": "Baton Rouge", "value": "C133699"},
               {"label": "Bawcomville", "value": "C133700"},
               {"label": "Bayou Cane", "value": "C133701"},
               {"label": "Bayou Gauche", "value": "C133702"},
               {"label": "Bayou Vista", "value": "C133703"},
               {"label": "Beauregard Parish", "value": "C133704"},
               {"label": "Belle Chasse", "value": "C133705"},
               {"label": "Belle Rose", "value": "C133706"},
               {"label": "Benton", "value": "C133707"},
               {"label": "Bernice", "value": "C133708"},
               {"label": "Berwick", "value": "C133709"},
               {"label": "Bienville Parish", "value": "C133710"},
               {"label": "Blanchard", "value": "C133711"},
               {"label": "Bogalusa", "value": "C133712"},
               {"label": "Bossier City", "value": "C133713"},
               {"label": "Bossier Parish", "value": "C133714"},
               {"label": "Bourg", "value": "C133715"},
               {"label": "Boutte", "value": "C133716"},
               {"label": "Breaux Bridge", "value": "C133717"},
               {"label": "Bridge City", "value": "C133718"},
               {"label": "Broussard", "value": "C133719"},
               {"label": "Brownsfield", "value": "C133720"},
               {"label": "Brownsville", "value": "C133721"},
               {"label": "Brusly", "value": "C133722"},
               {"label": "Bunkie", "value": "C133723"},
               {"label": "Caddo Parish", "value": "C133724"},
               {"label": "Cade", "value": "C133725"},
               {"label": "Calcasieu Parish", "value": "C133726"},
               {"label": "Caldwell Parish", "value": "C133727"},
               {"label": "Cameron", "value": "C133728"},
               {"label": "Cameron Parish", "value": "C133729"},
               {"label": "Campti", "value": "C133730"},
               {"label": "Carencro", "value": "C133731"},
               {"label": "Carlyss", "value": "C133732"},
               {"label": "Carville", "value": "C133733"},
               {"label": "Catahoula", "value": "C133734"},
               {"label": "Catahoula Parish", "value": "C133735"},
               {"label": "Cecilia", "value": "C133736"},
               {"label": "Central", "value": "C133737"},
               {"label": "Chackbay", "value": "C133738"},
               {"label": "Chalmette", "value": "C133739"},
               {"label": "Charenton", "value": "C133740"},
               {"label": "Chauvin", "value": "C133741"},
               {"label": "Church Point", "value": "C133742"},
               {"label": "Claiborne", "value": "C133743"},
               {"label": "Claiborne Parish", "value": "C133744"},
               {"label": "Clarks", "value": "C133745"},
               {"label": "Clinton", "value": "C133746"},
               {"label": "Colfax", "value": "C133747"},
               {"label": "Columbia", "value": "C133748"},
               {"label": "Concordia Parish", "value": "C133749"},
               {"label": "Convent", "value": "C133750"},
               {"label": "Cottonport", "value": "C133751"},
               {"label": "Coushatta", "value": "C133752"},
               {"label": "Covington", "value": "C133753"},
               {"label": "Crowley", "value": "C133754"},
               {"label": "Cullen", "value": "C133755"},
               {"label": "Cut Off", "value": "C133756"},
               {"label": "De Soto Parish", "value": "C133757"},
               {"label": "Delcambre", "value": "C133758"},
               {"label": "Delhi", "value": "C133759"},
               {"label": "Denham Springs", "value": "C133760"},
               {"label": "DeQuincy", "value": "C133761"},
               {"label": "DeRidder", "value": "C133762"},
               {"label": "Des Allemands", "value": "C133763"},
               {"label": "Destrehan", "value": "C133764"},
               {"label": "Deville", "value": "C133765"},
               {"label": "Donaldsonville", "value": "C133766"},
               {"label": "Dulac", "value": "C133767"},
               {"label": "Duson", "value": "C133768"},
               {"label": "East Baton Rouge Parish", "value": "C133769"},
               {"label": "East Carroll Parish", "value": "C133770"},
               {"label": "East Feliciana Parish", "value": "C133771"},
               {"label": "Eastwood", "value": "C133772"},
               {"label": "Eden Isle", "value": "C133773"},
               {"label": "Edgard", "value": "C133774"},
               {"label": "Elmwood", "value": "C133775"},
               {"label": "Elton", "value": "C133776"},
               {"label": "Erath", "value": "C133777"},
               {"label": "Erwinville", "value": "C133778"},
               {"label": "Estelle", "value": "C133779"},
               {"label": "Eunice", "value": "C133780"},
               {"label": "Evangeline Parish", "value": "C133781"},
               {"label": "Farmerville", "value": "C133782"},
               {"label": "Ferriday", "value": "C133783"},
               {"label": "Fort Polk North", "value": "C133784"},
               {"label": "Fort Polk South", "value": "C133785"},
               {"label": "Franklin", "value": "C133786"},
               {"label": "Franklin Parish", "value": "C133787"},
               {"label": "Franklinton", "value": "C133788"},
               {"label": "French Settlement", "value": "C133789"},
               {"label": "Galliano", "value": "C133790"},
               {"label": "Gardere", "value": "C133791"},
               {"label": "Garyville", "value": "C133792"},
               {"label": "Glenmora", "value": "C133793"},
               {"label": "Golden Meadow", "value": "C133794"},
               {"label": "Gonzales", "value": "C133795"},
               {"label": "Grambling", "value": "C133796"},
               {"label": "Gramercy", "value": "C133797"},
               {"label": "Grand Isle", "value": "C133798"},
               {"label": "Grand Point", "value": "C133799"},
               {"label": "Grant Parish", "value": "C133800"},
               {"label": "Gray", "value": "C133801"},
               {"label": "Greensburg", "value": "C133802"},
               {"label": "Greenwood", "value": "C133803"},
               {"label": "Gretna", "value": "C133804"},
               {"label": "Gueydan", "value": "C133805"},
               {"label": "Hackberry", "value": "C133806"},
               {"label": "Hahnville", "value": "C133807"},
               {"label": "Hammond", "value": "C133808"},
               {"label": "Harahan", "value": "C133809"},
               {"label": "Harrisonburg", "value": "C133810"},
               {"label": "Harvey", "value": "C133811"},
               {"label": "Haughton", "value": "C133812"},
               {"label": "Haynesville", "value": "C133813"},
               {"label": "Henderson", "value": "C133814"},
               {"label": "Homer", "value": "C133815"},
               {"label": "Houma", "value": "C133816"},
               {"label": "Iberia Parish", "value": "C133817"},
               {"label": "Iberville Parish", "value": "C133818"},
               {"label": "Independence", "value": "C133819"},
               {"label": "Inniswold", "value": "C133820"},
               {"label": "Iota", "value": "C133821"},
               {"label": "Iowa", "value": "C133822"},
               {"label": "Jackson", "value": "C133823"},
               {"label": "Jackson Parish", "value": "C133824"},
               {"label": "Jean Lafitte", "value": "C133825"},
               {"label": "Jeanerette", "value": "C133826"},
               {"label": "Jefferson", "value": "C133827"},
               {"label": "Jefferson Davis Parish", "value": "C133828"},
               {"label": "Jefferson Parish", "value": "C133829"},
               {"label": "Jena", "value": "C133830"},
               {"label": "Jennings", "value": "C133831"},
               {"label": "Jonesboro", "value": "C133832"},
               {"label": "Jonesville", "value": "C133833"},
               {"label": "Kaplan", "value": "C133834"},
               {"label": "Kenner", "value": "C133835"},
               {"label": "Kentwood", "value": "C133836"},
               {"label": "Killian", "value": "C133837"},
               {"label": "Kinder", "value": "C133838"},
               {"label": "Krotz Springs", "value": "C133839"},
               {"label": "La Salle Parish", "value": "C133840"},
               {"label": "Labadieville", "value": "C133841"},
               {"label": "Lacombe", "value": "C133842"},
               {"label": "Lafayette", "value": "C133843"},
               {"label": "Lafayette Parish", "value": "C133844"},
               {"label": "Lafourche Parish", "value": "C133845"},
               {"label": "Lake Arthur", "value": "C133846"},
               {"label": "Lake Charles", "value": "C133847"},
               {"label": "Lake Providence", "value": "C133848"},
               {"label": "Lakeshore", "value": "C133849"},
               {"label": "Laplace", "value": "C133850"},
               {"label": "Larose", "value": "C133851"},
               {"label": "Lawtell", "value": "C133852"},
               {"label": "Lecompte", "value": "C133853"},
               {"label": "Leesville", "value": "C133854"},
               {"label": "Leonville", "value": "C133855"},
               {"label": "Lincoln Parish", "value": "C133856"},
               {"label": "Livingston", "value": "C133857"},
               {"label": "Livingston Parish", "value": "C133858"},
               {"label": "Livonia", "value": "C133859"},
               {"label": "Lockport", "value": "C133860"},
               {"label": "Lockport Heights", "value": "C133861"},
               {"label": "Logansport", "value": "C133862"},
               {"label": "Luling", "value": "C133863"},
               {"label": "Lutcher", "value": "C133864"},
               {"label": "Madison Parish", "value": "C133865"},
               {"label": "Mamou", "value": "C133866"},
               {"label": "Mandeville", "value": "C133867"},
               {"label": "Mansfield", "value": "C133868"},
               {"label": "Mansura", "value": "C133869"},
               {"label": "Many", "value": "C133870"},
               {"label": "Maringouin", "value": "C133871"},
               {"label": "Marksville", "value": "C133872"},
               {"label": "Marrero", "value": "C133873"},
               {"label": "Mathews", "value": "C133874"},
               {"label": "Maurice", "value": "C133875"},
               {"label": "Melville", "value": "C133876"},
               {"label": "Meraux", "value": "C133877"},
               {"label": "Merrydale", "value": "C133878"},
               {"label": "Merryville", "value": "C133879"},
               {"label": "Metairie", "value": "C133880"},
               {"label": "Metairie Terrace", "value": "C133881"},
               {"label": "Midway", "value": "C133882"},
               {"label": "Milton", "value": "C133883"},
               {"label": "Minden", "value": "C133884"},
               {"label": "Minorca", "value": "C133885"},
               {"label": "Monroe", "value": "C133886"},
               {"label": "Montegut", "value": "C133887"},
               {"label": "Monticello", "value": "C133888"},
               {"label": "Montz", "value": "C133889"},
               {"label": "Morehouse Parish", "value": "C133890"},
               {"label": "Morgan City", "value": "C133891"},
               {"label": "Moss Bluff", "value": "C133892"},
               {"label": "Napoleonville", "value": "C133893"},
               {"label": "Natalbany", "value": "C133894"},
               {"label": "Natchitoches", "value": "C133895"},
               {"label": "Natchitoches Parish", "value": "C133896"},
               {"label": "New Iberia", "value": "C133897"},
               {"label": "New Llano", "value": "C133898"},
               {"label": "New Orleans", "value": "C133899"},
               {"label": "New Roads", "value": "C133900"},
               {"label": "New Sarpy", "value": "C133901"},
               {"label": "Newellton", "value": "C133902"},
               {"label": "Norco", "value": "C133903"},
               {"label": "North Vacherie", "value": "C133904"},
               {"label": "Oak Grove", "value": "C133905"},
               {"label": "Oak Hills Place", "value": "C133906"},
               {"label": "Oakdale", "value": "C133907"},
               {"label": "Oberlin", "value": "C133908"},
               {"label": "Old Jefferson", "value": "C133909"},
               {"label": "Olla", "value": "C133910"},
               {"label": "Opelousas", "value": "C133911"},
               {"label": "Orleans Parish", "value": "C133912"},
               {"label": "Ossun", "value": "C133913"},
               {"label": "Ouachita Parish", "value": "C133914"},
               {"label": "Paradis", "value": "C133915"},
               {"label": "Patterson", "value": "C133916"},
               {"label": "Paulina", "value": "C133917"},
               {"label": "Pearl River", "value": "C133918"},
               {"label": "Pierre Part", "value": "C133919"},
               {"label": "Pine Prairie", "value": "C133920"},
               {"label": "Pineville", "value": "C133921"},
               {"label": "Plaquemine", "value": "C133922"},
               {"label": "Plaquemines Parish", "value": "C133923"},
               {"label": "Pointe Coupee Parish", "value": "C133924"},
               {"label": "Ponchatoula", "value": "C133925"},
               {"label": "Port Allen", "value": "C133926"},
               {"label": "Port Barre", "value": "C133927"},
               {"label": "Port Sulphur", "value": "C133928"},
               {"label": "Poydras", "value": "C133929"},
               {"label": "Prairieville", "value": "C133930"},
               {"label": "Presquille", "value": "C133931"},
               {"label": "Prien", "value": "C133932"},
               {"label": "Raceland", "value": "C133933"},
               {"label": "Rapides Parish", "value": "C133934"},
               {"label": "Rayne", "value": "C133935"},
               {"label": "Rayville", "value": "C133936"},
               {"label": "Red Chute", "value": "C133937"},
               {"label": "Red River Parish", "value": "C133938"},
               {"label": "Reserve", "value": "C133939"},
               {"label": "Richland Parish", "value": "C133940"},
               {"label": "Richwood", "value": "C133941"},
               {"label": "Ringgold", "value": "C133942"},
               {"label": "River Ridge", "value": "C133943"},
               {"label": "Roseland", "value": "C133944"},
               {"label": "Rosepine", "value": "C133945"},
               {"label": "Ruston", "value": "C133946"},
               {"label": "Sabine Parish", "value": "C133947"},
               {"label": "Saint Bernard Parish", "value": "C133948"},
               {"label": "Saint Charles Parish", "value": "C133949"},
               {"label": "Saint Francisville", "value": "C133950"},
               {"label": "Saint Gabriel", "value": "C133951"},
               {"label": "Saint Helena Parish", "value": "C133952"},
               {"label": "Saint James Parish", "value": "C133953"},
               {"label": "Saint John the Baptist Parish", "value": "C133954"},
               {"label": "Saint Joseph", "value": "C133955"},
               {"label": "Saint Landry Parish", "value": "C133956"},
               {"label": "Saint Martin Parish", "value": "C133957"},
               {"label": "Saint Martinville", "value": "C133958"},
               {"label": "Saint Mary Parish", "value": "C133959"},
               {"label": "Saint Rose", "value": "C133960"},
               {"label": "Saint Tammany Parish", "value": "C133961"},
               {"label": "Schriever", "value": "C133962"},
               {"label": "Scott", "value": "C133963"},
               {"label": "Shenandoah", "value": "C133964"},
               {"label": "Shreveport", "value": "C133965"},
               {"label": "Sibley", "value": "C133966"},
               {"label": "Simmesport", "value": "C133967"},
               {"label": "Slidell", "value": "C133968"},
               {"label": "Sorrento", "value": "C133969"},
               {"label": "South Vacherie", "value": "C133970"},
               {"label": "Springhill", "value": "C133971"},
               {"label": "Sterlington", "value": "C133972"},
               {"label": "Stonewall", "value": "C133973"},
               {"label": "Sulphur", "value": "C133974"},
               {"label": "Sunset", "value": "C133975"},
               {"label": "Supreme", "value": "C133976"},
               {"label": "Swartz", "value": "C133977"},
               {"label": "Tallulah", "value": "C133978"},
               {"label": "Tangipahoa Parish", "value": "C133979"},
               {"label": "Tensas Parish", "value": "C133980"},
               {"label": "Terrebonne Parish", "value": "C133981"},
               {"label": "Terrytown", "value": "C133982"},
               {"label": "Thibodaux", "value": "C133983"},
               {"label": "Timberlane", "value": "C133984"},
               {"label": "Union Parish", "value": "C133985"},
               {"label": "Urania", "value": "C133986"},
               {"label": "Vermilion Parish", "value": "C133987"},
               {"label": "Vernon Parish", "value": "C133988"},
               {"label": "Vidalia", "value": "C133989"},
               {"label": "Vienna Bend", "value": "C133990"},
               {"label": "Village Saint George", "value": "C133991"},
               {"label": "Ville Platte", "value": "C133992"},
               {"label": "Vinton", "value": "C133993"},
               {"label": "Violet", "value": "C133994"},
               {"label": "Vivian", "value": "C133995"},
               {"label": "Waggaman", "value": "C133996"},
               {"label": "Walker", "value": "C133997"},
               {"label": "Washington Parish", "value": "C133998"},
               {"label": "Watson", "value": "C133999"},
               {"label": "Webster Parish", "value": "C134000"},
               {"label": "Welsh", "value": "C134001"},
               {"label": "West Baton Rouge Parish", "value": "C134002"},
               {"label": "West Carroll Parish", "value": "C134003"},
               {"label": "West Feliciana Parish", "value": "C134004"},
               {"label": "West Ferriday", "value": "C134005"},
               {"label": "West Monroe", "value": "C134006"},
               {"label": "Westlake", "value": "C134007"},
               {"label": "Westminster", "value": "C134008"},
               {"label": "Westwego", "value": "C134009"},
               {"label": "White Castle", "value": "C134010"},
               {"label": "Winn Parish", "value": "C134011"},
               {"label": "Winnfield", "value": "C134012"},
               {"label": "Winnsboro", "value": "C134013"},
               {"label": "Woodmere", "value": "C134014"},
               {"label": "Woodworth", "value": "C134015"},
               {"label": "Youngsville", "value": "C134016"},
               {"label": "Zachary", "value": "C134017"},
               {"label": "Zwolle", "value": "C134018"}],
 "Lovech Province": [{"label": "Apriltsi", "value": "C15977"},
                     {"label": "Letnitsa", "value": "C15978"},
                     {"label": "Lovech", "value": "C15979"},
                     {"label": "Lukovit", "value": "C15980"},
                     {"label": "Obshtina Lovech", "value": "C15981"},
                     {"label": "Obshtina Teteven", "value": "C15982"},
                     {"label": "Obshtina Ugarchin", "value": "C15983"},
                     {"label": "Teteven", "value": "C15984"},
                     {"label": "Troyan", "value": "C15985"},
                     {"label": "Ugarchin", "value": "C15986"},
                     {"label": "Yablanitsa", "value": "C15987"}],
 "Lovrenc na Pohorju Municipality": [{"label": "Lovrenc na Pohorju",
                                      "value": "C107706"}],
 "Lower Austria": [{"label": "Absdorf", "value": "C6398"},
                   {"label": "Achau", "value": "C6399"},
                   {"label": "Aderklaa", "value": "C6400"},
                   {"label": "Aggsbach", "value": "C6401"},
                   {"label": "Alland", "value": "C6402"},
                   {"label": "Allentsteig", "value": "C6403"},
                   {"label": "Allhartsberg", "value": "C6404"},
                   {"label": "Altenburg", "value": "C6405"},
                   {"label": "Altendorf", "value": "C6406"},
                   {"label": "Altenmarkt an der Triesting", "value": "C6407"},
                   {"label": "Altlengbach", "value": "C6408"},
                   {"label": "Altlichtenwarth", "value": "C6409"},
                   {"label": "Altmelon", "value": "C6410"},
                   {"label": "Amstetten", "value": "C6411"},
                   {"label": "Andlersdorf", "value": "C6412"},
                   {"label": "Angern an der March", "value": "C6413"},
                   {"label": "Arbesbach", "value": "C6414"},
                   {"label": "Ardagger Stift", "value": "C6415"},
                   {"label": "Aschbach Markt", "value": "C6416"},
                   {"label": "Aspang Markt", "value": "C6417"},
                   {"label": "Asparn an der Zaya", "value": "C6418"},
                   {"label": "Asperhofen", "value": "C6419"},
                   {"label": "Atzenbrugg", "value": "C6420"},
                   {"label": "Au am Leithaberge", "value": "C6421"},
                   {"label": "Auersthal", "value": "C6422"},
                   {"label": "Bad Deutsch-Altenburg", "value": "C6423"},
                   {"label": "Bad Erlach", "value": "C6424"},
                   {"label": "Bad Fischau", "value": "C6425"},
                   {"label": "Bad Pirawarth", "value": "C6426"},
                   {"label": "Bad Schonau", "value": "C6427"},
                   {"label": "Bad Traunstein", "value": "C6428"},
                   {"label": "Bad Voslau", "value": "C6429"},
                   {"label": "Baden", "value": "C6430"},
                   {"label": "Barnkopf", "value": "C6431"},
                   {"label": "Behamberg", "value": "C6432"},
                   {"label": "Berg", "value": "C6433"},
                   {"label": "Bergland", "value": "C6434"},
                   {"label": "Berndorf", "value": "C6435"},
                   {"label": "Bernhardsthal", "value": "C6436"},
                   {"label": "Biberbach", "value": "C6437"},
                   {"label": "Biedermannsdorf", "value": "C6438"},
                   {"label": "Bisamberg", "value": "C6439"},
                   {"label": "Bischofstetten", "value": "C6440"},
                   {"label": "Blindenmarkt", "value": "C6441"},
                   {"label": "Bockfliess", "value": "C6442"},
                   {"label": "Boheimkirchen", "value": "C6443"},
                   {"label": "Breitenau", "value": "C6444"},
                   {"label": "Breitenfurt bei Wien", "value": "C6445"},
                   {"label": "Bromberg", "value": "C6446"},
                   {"label": "Bruck an der Leitha", "value": "C6447"},
                   {"label": "Brunn am Gebirge", "value": "C6448"},
                   {"label": "Brunn an der Schneebergbahn", "value": "C6449"},
                   {"label": "Brunn an der Wild", "value": "C6450"},
                   {"label": "Buchbach", "value": "C6451"},
                   {"label": "Deutsch-Wagram", "value": "C6452"},
                   {"label": "Dobersberg", "value": "C6453"},
                   {"label": "Dorfstetten", "value": "C6454"},
                   {"label": "Drasenhofen", "value": "C6455"},
                   {"label": "Drosing", "value": "C6456"},
                   {"label": "Dross", "value": "C6457"},
                   {"label": "Durnkrut", "value": "C6458"},
                   {"label": "Durnstein", "value": "C6459"},
                   {"label": "Ebenfurth", "value": "C6460"},
                   {"label": "Ebenthal", "value": "C6461"},
                   {"label": "Ebergassing", "value": "C6462"},
                   {"label": "Ebreichsdorf", "value": "C6463"},
                   {"label": "Echsenbach", "value": "C6464"},
                   {"label": "Eckartsau", "value": "C6465"},
                   {"label": "Edlitz", "value": "C6466"},
                   {"label": "Eggenburg", "value": "C6467"},
                   {"label": "Eggendorf", "value": "C6468"},
                   {"label": "Eggern", "value": "C6469"},
                   {"label": "Eichgraben", "value": "C6470"},
                   {"label": "Eisgarn", "value": "C6471"},
                   {"label": "Emmersdorf an der Donau", "value": "C6472"},
                   {"label": "Engelhartstetten", "value": "C6473"},
                   {"label": "Ennsdorf", "value": "C6474"},
                   {"label": "Enzenreith", "value": "C6475"},
                   {"label": "Enzersdorf an der Fischa", "value": "C6476"},
                   {"label": "Enzersfeld", "value": "C6477"},
                   {"label": "Erlauf", "value": "C6478"},
                   {"label": "Ernstbrunn", "value": "C6479"},
                   {"label": "Ernsthofen", "value": "C6480"},
                   {"label": "Erpersdorf", "value": "C6481"},
                   {"label": "Ertl", "value": "C6482"},
                   {"label": "Eschenau", "value": "C6483"},
                   {"label": "Euratsfeld", "value": "C6484"},
                   {"label": "Falkenstein", "value": "C6485"},
                   {"label": "Fallbach", "value": "C6486"},
                   {"label": "Feistritz am Wechsel", "value": "C6487"},
                   {"label": "Felixdorf", "value": "C6488"},
                   {"label": "Fels am Wagram", "value": "C6489"},
                   {"label": "Ferschnitz", "value": "C6490"},
                   {"label": "Fischamend-Markt", "value": "C6491"},
                   {"label": "Frankenfels", "value": "C6492"},
                   {"label": "Frohsdorf", "value": "C6493"},
                   {"label": "Furth an der Triesting", "value": "C6494"},
                   {"label": "Furth bei Gottweig", "value": "C6495"},
                   {"label": "Gaaden", "value": "C6496"},
                   {"label": "Gablitz", "value": "C6497"},
                   {"label": "Gainfarn", "value": "C6498"},
                   {"label": "Gaming", "value": "C6499"},
                   {"label": "Ganserndorf", "value": "C6500"},
                   {"label": "Gars am Kamp", "value": "C6501"},
                   {"label": "Gastern", "value": "C6502"},
                   {"label": "Gaubitsch", "value": "C6503"},
                   {"label": "Gaweinstal", "value": "C6504"},
                   {"label": "Gedersdorf", "value": "C6505"},
                   {"label": "Geras", "value": "C6506"},
                   {"label": "Gerasdorf bei Wien", "value": "C6507"},
                   {"label": "Gerersdorf", "value": "C6508"},
                   {"label": "Gerolding", "value": "C6509"},
                   {"label": "Gfohl", "value": "C6510"},
                   {"label": "Giesshubl", "value": "C6511"},
                   {"label": "Glinzendorf", "value": "C6512"},
                   {"label": "Gloggnitz", "value": "C6513"},
                   {"label": "Gmund", "value": "C6514"},
                   {"label": "Gnadendorf", "value": "C6515"},
                   {"label": "Gneixendorf", "value": "C6516"},
                   {"label": "Gollersdorf", "value": "C6517"},
                   {"label": "Gopfritz an der Wild", "value": "C6518"},
                   {"label": "Gostling an der Ybbs", "value": "C6519"},
                   {"label": "Gotzendorf an der Leitha", "value": "C6520"},
                   {"label": "Grafenegg", "value": "C6521"},
                   {"label": "Grafenschlag", "value": "C6522"},
                   {"label": "Grafenworth", "value": "C6523"},
                   {"label": "Gramatneusiedl", "value": "C6524"},
                   {"label": "Greinsfurth", "value": "C6525"},
                   {"label": "Gresten", "value": "C6526"},
                   {"label": "Grimmenstein", "value": "C6527"},
                   {"label": "Gross-Engersdorf", "value": "C6528"},
                   {"label": "Gross-Enzersdorf", "value": "C6529"},
                   {"label": "Gross-Gerungs", "value": "C6530"},
                   {"label": "Gross-Schweinbarth", "value": "C6531"},
                   {"label": "Gross-Siegharts", "value": "C6532"},
                   {"label": "Grossebersdorf", "value": "C6533"},
                   {"label": "Grossharras", "value": "C6534"},
                   {"label": "Grosshofen", "value": "C6535"},
                   {"label": "Grosskrut", "value": "C6536"},
                   {"label": "Grossmugl", "value": "C6537"},
                   {"label": "Grossriedenthal", "value": "C6538"},
                   {"label": "Grossrussbach", "value": "C6539"},
                   {"label": "Grossweikersdorf", "value": "C6540"},
                   {"label": "Grunbach am Schneeberg", "value": "C6541"},
                   {"label": "Gugging", "value": "C6542"},
                   {"label": "Gumpoldskirchen", "value": "C6543"},
                   {"label": "Gunselsdorf", "value": "C6544"},
                   {"label": "Guntersdorf", "value": "C6545"},
                   {"label": "Guntramsdorf", "value": "C6546"},
                   {"label": "Gutenbrunn", "value": "C6547"},
                   {"label": "Gutenstein", "value": "C6548"},
                   {"label": "Haag", "value": "C6549"},
                   {"label": "Hadersdorf am Kamp", "value": "C6550"},
                   {"label": "Hadres", "value": "C6551"},
                   {"label": "Hafnerbach", "value": "C6552"},
                   {"label": "Hagenbrunn", "value": "C6553"},
                   {"label": "Haidershofen", "value": "C6554"},
                   {"label": "Hainburg an der Donau", "value": "C6555"},
                   {"label": "Hainfeld", "value": "C6556"},
                   {"label": "Hardegg", "value": "C6557"},
                   {"label": "Haringsee", "value": "C6558"},
                   {"label": "Harland", "value": "C6559"},
                   {"label": "Harmannsdorf", "value": "C6560"},
                   {"label": "Hart", "value": "C6561"},
                   {"label": "Haugschlag", "value": "C6562"},
                   {"label": "Haugsdorf", "value": "C6563"},
                   {"label": "Haunoldstein", "value": "C6564"},
                   {"label": "Hausbrunn", "value": "C6565"},
                   {"label": "Hauskirchen", "value": "C6566"},
                   {"label": "Hausleiten", "value": "C6567"},
                   {"label": "Hausmening", "value": "C6568"},
                   {"label": "Heidenreichstein", "value": "C6569"},
                   {"label": "Heiligeneich", "value": "C6570"},
                   {"label": "Heiligenkreuz", "value": "C6571"},
                   {"label": "Hennersdorf", "value": "C6572"},
                   {"label": "Hernstein", "value": "C6573"},
                   {"label": "Herrnbaumgarten", "value": "C6574"},
                   {"label": "Herzogenburg", "value": "C6575"},
                   {"label": "Himberg", "value": "C6576"},
                   {"label": "Hinterbruhl", "value": "C6577"},
                   {"label": "Hinterleiten", "value": "C6578"},
                   {"label": "Hirschbach", "value": "C6579"},
                   {"label": "Hirtenberg", "value": "C6580"},
                   {"label": "Hochleithen", "value": "C6581"},
                   {"label": "Hochwolkersdorf", "value": "C6582"},
                   {"label": "Hof am Leithaberge", "value": "C6583"},
                   {"label": "Hofamt Priel", "value": "C6584"},
                   {"label": "Hoflein", "value": "C6585"},
                   {"label": "Hofling", "value": "C6586"},
                   {"label": "Hofstetten", "value": "C6587"},
                   {"label": "Hohenau", "value": "C6588"},
                   {"label": "Hohenberg", "value": "C6589"},
                   {"label": "Hoheneich", "value": "C6590"},
                   {"label": "Hohenruppersdorf", "value": "C6591"},
                   {"label": "Hollabrunn", "value": "C6592"},
                   {"label": "Hollenstein an der Ybbs", "value": "C6593"},
                   {"label": "Hollenthon", "value": "C6594"},
                   {"label": "Horn", "value": "C6595"},
                   {"label": "Hundsheim", "value": "C6596"},
                   {"label": "Hurm", "value": "C6597"},
                   {"label": "Hutten", "value": "C6598"},
                   {"label": "Jaidhof", "value": "C6599"},
                   {"label": "Japons", "value": "C6600"},
                   {"label": "Jedenspeigen", "value": "C6601"},
                   {"label": "Kaltenleutgeben", "value": "C6602"},
                   {"label": "Kapelln", "value": "C6603"},
                   {"label": "Karlstetten", "value": "C6604"},
                   {"label": "Kasten bei Boheimkirchen", "value": "C6605"},
                   {"label": "Katzelsdorf", "value": "C6606"},
                   {"label": "Kaumberg", "value": "C6607"},
                   {"label": "Kautzen", "value": "C6608"},
                   {"label": "Kematen an der Ybbs", "value": "C6609"},
                   {"label": "Kierling", "value": "C6610"},
                   {"label": "Kilb", "value": "C6611"},
                   {"label": "Kirchberg am Wagram", "value": "C6612"},
                   {"label": "Kirchberg am Walde", "value": "C6613"},
                   {"label": "Kirchberg am Wechsel", "value": "C6614"},
                   {"label": "Kirchberg an der Pielach", "value": "C6615"},
                   {"label": "Kirchschlag", "value": "C6616"},
                   {"label": "Kirchschlag in der Buckligen Welt",
                    "value": "C6617"},
                   {"label": "Kirchstetten", "value": "C6618"},
                   {"label": "Kirnberg an der Mank", "value": "C6619"},
                   {"label": "Klausen-Leopoldsdorf", "value": "C6620"},
                   {"label": "Klein-Neusiedl", "value": "C6621"},
                   {"label": "Klein-Pochlarn", "value": "C6622"},
                   {"label": "Kleinzell", "value": "C6623"},
                   {"label": "Klosterneuburg", "value": "C6624"},
                   {"label": "Konigstetten", "value": "C6625"},
                   {"label": "Korneuburg", "value": "C6626"},
                   {"label": "Kottingbrunn", "value": "C6627"},
                   {"label": "Krems an der Donau", "value": "C6628"},
                   {"label": "Krems an der Donau Stadt", "value": "C6629"},
                   {"label": "Kritzendorf", "value": "C6630"},
                   {"label": "Krumau am Kamp", "value": "C6631"},
                   {"label": "Krumbach Markt", "value": "C6632"},
                   {"label": "Krumnussbaum", "value": "C6633"},
                   {"label": "Laa an der Thaya", "value": "C6634"},
                   {"label": "Laab im Walde", "value": "C6635"},
                   {"label": "Ladendorf", "value": "C6636"},
                   {"label": "Landegg", "value": "C6637"},
                   {"label": "Langau", "value": "C6638"},
                   {"label": "Langenlebarn-Oberaigen", "value": "C6639"},
                   {"label": "Langenlois", "value": "C6640"},
                   {"label": "Langenrohr", "value": "C6641"},
                   {"label": "Langenzersdorf", "value": "C6642"},
                   {"label": "Langschlag", "value": "C6643"},
                   {"label": "Lanzendorf", "value": "C6644"},
                   {"label": "Lanzenkirchen", "value": "C6645"},
                   {"label": "Lassee", "value": "C6646"},
                   {"label": "Laxenburg", "value": "C6647"},
                   {"label": "Leiben", "value": "C6648"},
                   {"label": "Leitzersdorf", "value": "C6649"},
                   {"label": "Lengenfeld", "value": "C6650"},
                   {"label": "Leobendorf", "value": "C6651"},
                   {"label": "Leobersdorf", "value": "C6652"},
                   {"label": "Leopoldsdorf", "value": "C6653"},
                   {"label": "Leopoldsdorf im Marchfelde", "value": "C6654"},
                   {"label": "Lerchenfeld", "value": "C6655"},
                   {"label": "Lichtenegg", "value": "C6656"},
                   {"label": "Lichtenworth", "value": "C6657"},
                   {"label": "Lilienfeld", "value": "C6658"},
                   {"label": "Litschau", "value": "C6659"},
                   {"label": "Loich", "value": "C6660"},
                   {"label": "Loosdorf", "value": "C6661"},
                   {"label": "Lunz am See", "value": "C6662"},
                   {"label": "Mailberg", "value": "C6663"},
                   {"label": "Maissau", "value": "C6664"},
                   {"label": "Mank", "value": "C6665"},
                   {"label": "Mannersdorf am Leithagebirge", "value": "C6666"},
                   {"label": "Mannsdorf an der Donau", "value": "C6667"},
                   {"label": "Mannsworth", "value": "C6668"},
                   {"label": "Marbach an der Donau", "value": "C6669"},
                   {"label": "Marchegg", "value": "C6670"},
                   {"label": "Margarethen am Moos", "value": "C6671"},
                   {"label": "Maria Ellend", "value": "C6672"},
                   {"label": "Maria Enzersdorf", "value": "C6673"},
                   {"label": "Maria Laach am Jauerling", "value": "C6674"},
                   {"label": "Maria Lanzendorf", "value": "C6675"},
                   {"label": "Maria Taferl", "value": "C6676"},
                   {"label": "Maria-Anzbach", "value": "C6677"},
                   {"label": "Markersdorf an der Pielach", "value": "C6678"},
                   {"label": "Markgrafneusiedl", "value": "C6679"},
                   {"label": "Markt Piesting", "value": "C6680"},
                   {"label": "Martinsberg", "value": "C6681"},
                   {"label": "Matzen", "value": "C6682"},
                   {"label": "Matzendorf", "value": "C6683"},
                   {"label": "Mauer bei Amstetten", "value": "C6684"},
                   {"label": "Mauerbach", "value": "C6685"},
                   {"label": "Mautern", "value": "C6686"},
                   {"label": "Melk", "value": "C6687"},
                   {"label": "Michelhausen", "value": "C6688"},
                   {"label": "Miesenbach", "value": "C6689"},
                   {"label": "Mistelbach", "value": "C6690"},
                   {"label": "Mitterbach am Erlaufsee", "value": "C6691"},
                   {"label": "Mitterndorf an der Fischa", "value": "C6692"},
                   {"label": "Modling", "value": "C6693"},
                   {"label": "Mollersdorf", "value": "C6694"},
                   {"label": "Monichkirchen", "value": "C6695"},
                   {"label": "Moosbrunn", "value": "C6696"},
                   {"label": "Muckendorf an der Donau", "value": "C6697"},
                   {"label": "Muggendorf", "value": "C6698"},
                   {"label": "Muhldorf", "value": "C6699"},
                   {"label": "Munchendorf", "value": "C6700"},
                   {"label": "Neidling", "value": "C6701"},
                   {"label": "Neu-Guntramsdorf", "value": "C6702"},
                   {"label": "Neudorf bei Staatz", "value": "C6703"},
                   {"label": "Neufurth", "value": "C6704"},
                   {"label": "Neuhofen an der Ybbs", "value": "C6705"},
                   {"label": "Neulengbach", "value": "C6706"},
                   {"label": "Neumarkt an der Ybbs", "value": "C6707"},
                   {"label": "Neunkirchen", "value": "C6708"},
                   {"label": "Neusiedl", "value": "C6709"},
                   {"label": "Neusiedl an der Zaya", "value": "C6710"},
                   {"label": "Niederhollabrunn", "value": "C6711"},
                   {"label": "Niederleis", "value": "C6712"},
                   {"label": "Nochling", "value": "C6713"},
                   {"label": "Ober-Grafendorf", "value": "C6714"},
                   {"label": "Oberhausen", "value": "C6715"},
                   {"label": "Oberndorf an der Melk", "value": "C6716"},
                   {"label": "Oberndorf in der Ebene", "value": "C6717"},
                   {"label": "Obersdorf", "value": "C6718"},
                   {"label": "Obersiebenbrunn", "value": "C6719"},
                   {"label": "Oberwagram", "value": "C6720"},
                   {"label": "Oberwaltersdorf", "value": "C6721"},
                   {"label": "Oberwolbling", "value": "C6722"},
                   {"label": "Oehling", "value": "C6723"},
                   {"label": "Oeynhausen", "value": "C6724"},
                   {"label": "Opponitz", "value": "C6725"},
                   {"label": "Orth an der Donau", "value": "C6726"},
                   {"label": "Ottenschlag", "value": "C6727"},
                   {"label": "Ottenthal", "value": "C6728"},
                   {"label": "Otterthal", "value": "C6729"},
                   {"label": "Parbasdorf", "value": "C6730"},
                   {"label": "Paudorf", "value": "C6731"},
                   {"label": "Payerbach", "value": "C6732"},
                   {"label": "Penz", "value": "C6733"},
                   {"label": "Perchtoldsdorf", "value": "C6734"},
                   {"label": "Pernegg", "value": "C6735"},
                   {"label": "Pernersdorf", "value": "C6736"},
                   {"label": "Pernitz", "value": "C6737"},
                   {"label": "Persenbeug", "value": "C6738"},
                   {"label": "Petronell-Carnuntum", "value": "C6739"},
                   {"label": "Petzenkirchen", "value": "C6740"},
                   {"label": "Pfaffstatten", "value": "C6741"},
                   {"label": "Pillichsdorf", "value": "C6742"},
                   {"label": "Pitten", "value": "C6743"},
                   {"label": "Pochlarn", "value": "C6744"},
                   {"label": "Poggstall", "value": "C6745"},
                   {"label": "Politischer Bezirk Amstetten", "value": "C6746"},
                   {"label": "Politischer Bezirk Baden", "value": "C6747"},
                   {"label": "Politischer Bezirk Bruck an der Leitha",
                    "value": "C6748"},
                   {"label": "Politischer Bezirk Ganserndorf",
                    "value": "C6749"},
                   {"label": "Politischer Bezirk Gmund", "value": "C6750"},
                   {"label": "Politischer Bezirk Hollabrunn", "value": "C6751"},
                   {"label": "Politischer Bezirk Horn", "value": "C6752"},
                   {"label": "Politischer Bezirk Korneuburg", "value": "C6753"},
                   {"label": "Politischer Bezirk Krems", "value": "C6754"},
                   {"label": "Politischer Bezirk Lilienfeld", "value": "C6755"},
                   {"label": "Politischer Bezirk Melk", "value": "C6756"},
                   {"label": "Politischer Bezirk Mistelbach", "value": "C6757"},
                   {"label": "Politischer Bezirk Modling", "value": "C6758"},
                   {"label": "Politischer Bezirk Neunkirchen",
                    "value": "C6759"},
                   {"label": "Politischer Bezirk Sankt Polten",
                    "value": "C6760"},
                   {"label": "Politischer Bezirk Scheibbs", "value": "C6761"},
                   {"label": "Politischer Bezirk Tulln", "value": "C6762"},
                   {"label": "Politischer Bezirk Waidhofen an der Thaya",
                    "value": "C6763"},
                   {"label": "Politischer Bezirk Wiener Neustadt",
                    "value": "C6764"},
                   {"label": "Politischer Bezirk Zwettl", "value": "C6765"},
                   {"label": "Pottenbrunn", "value": "C6766"},
                   {"label": "Pottendorf", "value": "C6767"},
                   {"label": "Pottenstein", "value": "C6768"},
                   {"label": "Pottschach", "value": "C6769"},
                   {"label": "Poysdorf", "value": "C6770"},
                   {"label": "Prellenkirchen", "value": "C6771"},
                   {"label": "Pressbaum", "value": "C6772"},
                   {"label": "Prigglitz", "value": "C6773"},
                   {"label": "Prinzersdorf", "value": "C6774"},
                   {"label": "Prottes", "value": "C6775"},
                   {"label": "Puchberg am Schneeberg", "value": "C6776"},
                   {"label": "Puchenstuben", "value": "C6777"},
                   {"label": "Pulkau", "value": "C6778"},
                   {"label": "Purgstall", "value": "C6779"},
                   {"label": "Purkersdorf", "value": "C6780"},
                   {"label": "Pyhra", "value": "C6781"},
                   {"label": "Raabs an der Thaya", "value": "C6782"},
                   {"label": "Raach am Hochgebirge", "value": "C6783"},
                   {"label": "Raasdorf", "value": "C6784"},
                   {"label": "Rabensburg", "value": "C6785"},
                   {"label": "Rabenstein an der Pielach", "value": "C6786"},
                   {"label": "Ramsau", "value": "C6787"},
                   {"label": "Randegg", "value": "C6788"},
                   {"label": "Rannersdorf", "value": "C6789"},
                   {"label": "Rappottenstein", "value": "C6790"},
                   {"label": "Rastenfeld", "value": "C6791"},
                   {"label": "Ratzersdorf an der Traisen", "value": "C6792"},
                   {"label": "Rauchenwarth", "value": "C6793"},
                   {"label": "Ravelsbach", "value": "C6794"},
                   {"label": "Raxendorf", "value": "C6795"},
                   {"label": "Rehberg", "value": "C6796"},
                   {"label": "Reichenau an der Rax", "value": "C6797"},
                   {"label": "Reingers", "value": "C6798"},
                   {"label": "Reinsberg", "value": "C6799"},
                   {"label": "Reisenberg", "value": "C6800"},
                   {"label": "Retz", "value": "C6801"},
                   {"label": "Rohr im Gebirge", "value": "C6802"},
                   {"label": "Rohrau", "value": "C6803"},
                   {"label": "Rohrbach an der Golsen", "value": "C6804"},
                   {"label": "Rohrenbach", "value": "C6805"},
                   {"label": "Rohrendorf bei Krems", "value": "C6806"},
                   {"label": "Roschitz", "value": "C6807"},
                   {"label": "Rosenau am Sonntagberg", "value": "C6808"},
                   {"label": "Ruprechtshofen", "value": "C6809"},
                   {"label": "Sallingberg", "value": "C6810"},
                   {"label": "Sankt Aegyd am Neuwalde", "value": "C6811"},
                   {"label": "Sankt Andra vor dem Hagenthale",
                    "value": "C6812"},
                   {"label": "Sankt Corona am Wechsel", "value": "C6813"},
                   {"label": "Sankt Georgen am Ybbsfelde", "value": "C6814"},
                   {"label": "Sankt Georgen an der Leys", "value": "C6815"},
                   {"label": "Sankt Leonhard am Forst", "value": "C6816"},
                   {"label": "Sankt Leonhard am Hornerwald", "value": "C6817"},
                   {"label": "Sankt Margarethen an der Sierning",
                    "value": "C6818"},
                   {"label": "Sankt Martin am Ybbsfelde", "value": "C6819"},
                   {"label": "Sankt Pantaleon", "value": "C6820"},
                   {"label": "Sankt Peter in der Au Markt", "value": "C6821"},
                   {"label": "Sankt Polten", "value": "C6822"},
                   {"label": "Sankt Polten Stadt", "value": "C6823"},
                   {"label": "Sankt Valentin", "value": "C6824"},
                   {"label": "Sankt Veit an der Golsen", "value": "C6825"},
                   {"label": "Scharndorf", "value": "C6826"},
                   {"label": "Scheibbs", "value": "C6827"},
                   {"label": "Schleinbach", "value": "C6828"},
                   {"label": "Schonau an der Triesting", "value": "C6829"},
                   {"label": "Schonbach", "value": "C6830"},
                   {"label": "Schonkirchen-Reyersdorf", "value": "C6831"},
                   {"label": "Schottwien", "value": "C6832"},
                   {"label": "Schrattenbach", "value": "C6833"},
                   {"label": "Schrattenberg", "value": "C6834"},
                   {"label": "Schrattenthal", "value": "C6835"},
                   {"label": "Schrems", "value": "C6836"},
                   {"label": "Schwadorf", "value": "C6837"},
                   {"label": "Schwarzau am Steinfeld", "value": "C6838"},
                   {"label": "Schwarzau im Gebirge", "value": "C6839"},
                   {"label": "Schwarzenau", "value": "C6840"},
                   {"label": "Schwarzenbach", "value": "C6841"},
                   {"label": "Schwarzenbach an der Pielach", "value": "C6842"},
                   {"label": "Schwechat", "value": "C6843"},
                   {"label": "Schweiggers", "value": "C6844"},
                   {"label": "Seebenstein", "value": "C6845"},
                   {"label": "Seibersdorf", "value": "C6846"},
                   {"label": "Seitenstetten Markt", "value": "C6847"},
                   {"label": "Semmering", "value": "C6848"},
                   {"label": "Senftenberg", "value": "C6849"},
                   {"label": "Seyring", "value": "C6850"},
                   {"label": "Sieghartskirchen", "value": "C6851"},
                   {"label": "Sierndorf", "value": "C6852"},
                   {"label": "Sigmundsherberg", "value": "C6853"},
                   {"label": "Sitzendorf an der Schmida", "value": "C6854"},
                   {"label": "Sollenau", "value": "C6855"},
                   {"label": "Sommerein", "value": "C6856"},
                   {"label": "Sonntagberg", "value": "C6857"},
                   {"label": "Sooss", "value": "C6858"},
                   {"label": "Spannberg", "value": "C6859"},
                   {"label": "Spillern", "value": "C6860"},
                   {"label": "Spitz", "value": "C6861"},
                   {"label": "Spratzern", "value": "C6862"},
                   {"label": "Staatz", "value": "C6863"},
                   {"label": "Stattersdorf", "value": "C6864"},
                   {"label": "Statzendorf", "value": "C6865"},
                   {"label": "Stein an der Donau", "value": "C6866"},
                   {"label": "Steinabruckl", "value": "C6867"},
                   {"label": "Steinakirchen am Forst", "value": "C6868"},
                   {"label": "Stephanshart", "value": "C6869"},
                   {"label": "Stetteldorf am Wagram", "value": "C6870"},
                   {"label": "Stetten", "value": "C6871"},
                   {"label": "Stockerau", "value": "C6872"},
                   {"label": "Stollhofen", "value": "C6873"},
                   {"label": "Stossing", "value": "C6874"},
                   {"label": "Strass", "value": "C6875"},
                   {"label": "Strasshof an der Nordbahn", "value": "C6876"},
                   {"label": "Stratzing", "value": "C6877"},
                   {"label": "Strengberg", "value": "C6878"},
                   {"label": "Stronsdorf", "value": "C6879"},
                   {"label": "Tattendorf", "value": "C6880"},
                   {"label": "Teesdorf", "value": "C6881"},
                   {"label": "Ternitz", "value": "C6882"},
                   {"label": "Thaya", "value": "C6883"},
                   {"label": "Theresienfeld", "value": "C6884"},
                   {"label": "Thomasberg", "value": "C6885"},
                   {"label": "Tiefenbach", "value": "C6886"},
                   {"label": "Traisen", "value": "C6887"},
                   {"label": "Traiskirchen", "value": "C6888"},
                   {"label": "Traismauer", "value": "C6889"},
                   {"label": "Trattenbach", "value": "C6890"},
                   {"label": "Trautmannsdorf an der Leitha", "value": "C6891"},
                   {"label": "Tribuswinkel", "value": "C6892"},
                   {"label": "Trumau", "value": "C6893"},
                   {"label": "Tulbing", "value": "C6894"},
                   {"label": "Tulln", "value": "C6895"},
                   {"label": "Tullnerbach-Lawies", "value": "C6896"},
                   {"label": "Turnitz", "value": "C6897"},
                   {"label": "Ulrichskirchen", "value": "C6898"},
                   {"label": "Untersiebenbrunn", "value": "C6899"},
                   {"label": "Unterstinkenbrunn", "value": "C6900"},
                   {"label": "Unterwagram", "value": "C6901"},
                   {"label": "Unterwaltersdorf", "value": "C6902"},
                   {"label": "Velm", "value": "C6903"},
                   {"label": "Viehofen", "value": "C6904"},
                   {"label": "Vitis", "value": "C6905"},
                   {"label": "Vosendorf", "value": "C6906"},
                   {"label": "Waidhofen an der Thaya", "value": "C6907"},
                   {"label": "Waidhofen an der Ybbs", "value": "C6908"},
                   {"label": "Waidhofen an der Ybbs Stadt", "value": "C6909"},
                   {"label": "Waidmannsfeld", "value": "C6910"},
                   {"label": "Waldegg", "value": "C6911"},
                   {"label": "Waldenstein", "value": "C6912"},
                   {"label": "Waldhausen", "value": "C6913"},
                   {"label": "Waldkirchen an der Thaya", "value": "C6914"},
                   {"label": "Wallsee", "value": "C6915"},
                   {"label": "Walpersbach", "value": "C6916"},
                   {"label": "Wang", "value": "C6917"},
                   {"label": "Warth", "value": "C6918"},
                   {"label": "Wartmannstetten", "value": "C6919"},
                   {"label": "Weidling", "value": "C6920"},
                   {"label": "Weigelsdorf", "value": "C6921"},
                   {"label": "Weikendorf", "value": "C6922"},
                   {"label": "Weikersdorf am Steinfelde", "value": "C6923"},
                   {"label": "Weinburg", "value": "C6924"},
                   {"label": "Weinzierl am Walde", "value": "C6925"},
                   {"label": "Weinzierl bei Krems", "value": "C6926"},
                   {"label": "Weissenbach an der Triesting", "value": "C6927"},
                   {"label": "Weissenkirchen in der Wachau", "value": "C6928"},
                   {"label": "Weistrach", "value": "C6929"},
                   {"label": "Weiten", "value": "C6930"},
                   {"label": "Weitersfeld", "value": "C6931"},
                   {"label": "Weitra", "value": "C6932"},
                   {"label": "Wiener Neudorf", "value": "C6933"},
                   {"label": "Wiener Neustadt", "value": "C6934"},
                   {"label": "Wiener Neustadt Stadt", "value": "C6935"},
                   {"label": "Wienersdorf", "value": "C6936"},
                   {"label": "Wieselburg", "value": "C6937"},
                   {"label": "Wiesmath", "value": "C6938"},
                   {"label": "Wilfersdorf", "value": "C6939"},
                   {"label": "Wilfleinsdorf", "value": "C6940"},
                   {"label": "Wilhelmsburg", "value": "C6941"},
                   {"label": "Willendorf am Steinfelde", "value": "C6942"},
                   {"label": "Wimpassing im Schwarzatale", "value": "C6943"},
                   {"label": "Windhag", "value": "C6944"},
                   {"label": "Windigsteig", "value": "C6945"},
                   {"label": "Winklarn", "value": "C6946"},
                   {"label": "Winzendorf", "value": "C6947"},
                   {"label": "Wolfpassing", "value": "C6948"},
                   {"label": "Wolfsbach", "value": "C6949"},
                   {"label": "Wolfsgraben", "value": "C6950"},
                   {"label": "Wolfsthal", "value": "C6951"},
                   {"label": "Wolkersdorf im Weinviertel", "value": "C6952"},
                   {"label": "Wollersdorf", "value": "C6953"},
                   {"label": "Wordern", "value": "C6954"},
                   {"label": "Wullersdorf", "value": "C6955"},
                   {"label": "Wurflach", "value": "C6956"},
                   {"label": "Wurmla", "value": "C6957"},
                   {"label": "Ybbs an der Donau", "value": "C6958"},
                   {"label": "Ybbsitz", "value": "C6959"},
                   {"label": "Ysper", "value": "C6960"},
                   {"label": "Zeillern", "value": "C6961"},
                   {"label": "Zeiselmauer", "value": "C6962"},
                   {"label": "Zell-Arzberg", "value": "C6963"},
                   {"label": "Zell-Markt", "value": "C6964"},
                   {"label": "Zellerndorf", "value": "C6965"},
                   {"label": "Ziersdorf", "value": "C6966"},
                   {"label": "Zillingdorf", "value": "C6967"},
                   {"label": "Zistersdorf", "value": "C6968"},
                   {"label": "Zobern", "value": "C6969"},
                   {"label": "Zwentendorf", "value": "C6970"},
                   {"label": "Zwettl Stadt", "value": "C6971"},
                   {"label": "Zwolfaxing", "value": "C6972"}],
 "Lower Juba": [{"label": "Buur Gaabo", "value": "C107903"},
                {"label": "Jamaame", "value": "C107904"},
                {"label": "Kismayo", "value": "C107905"}],
 "Lower River Division": [{"label": "Baro Kunda", "value": "C34179"},
                          {"label": "Bureng", "value": "C34180"},
                          {"label": "Jali", "value": "C34181"},
                          {"label": "Jarra Central", "value": "C34182"},
                          {"label": "Jarra East", "value": "C34183"},
                          {"label": "Jarra West", "value": "C34184"},
                          {"label": "Jenoi", "value": "C34185"},
                          {"label": "Jifarong", "value": "C34186"},
                          {"label": "Kaiaf", "value": "C34187"},
                          {"label": "Karantaba", "value": "C34188"},
                          {"label": "Keneba", "value": "C34189"},
                          {"label": "Kiang Central", "value": "C34190"},
                          {"label": "Kiang East", "value": "C34191"},
                          {"label": "Kiang West District", "value": "C34192"},
                          {"label": "Mansa Konko", "value": "C34193"},
                          {"label": "Nioro", "value": "C34194"},
                          {"label": "Sankwia", "value": "C34195"},
                          {"label": "Si Kunda", "value": "C34196"},
                          {"label": "Soma", "value": "C34197"},
                          {"label": "Sutukung", "value": "C34198"},
                          {"label": "Toniataba", "value": "C34199"},
                          {"label": "Wellingara Ba", "value": "C34200"}],
 "Lower Saxony": [{"label": "Abbesbuttel", "value": "C37767"},
                  {"label": "Achim", "value": "C37768"},
                  {"label": "Adelebsen", "value": "C37769"},
                  {"label": "Adelheidsdorf", "value": "C37770"},
                  {"label": "Adenbuttel", "value": "C37771"},
                  {"label": "Adendorf", "value": "C37772"},
                  {"label": "Adenstedt", "value": "C37773"},
                  {"label": "Aerzen", "value": "C37774"},
                  {"label": "Agathenburg", "value": "C37775"},
                  {"label": "Ahausen", "value": "C37776"},
                  {"label": "Ahlden", "value": "C37777"},
                  {"label": "Ahlerstedt", "value": "C37778"},
                  {"label": "Ahnsbeck", "value": "C37779"},
                  {"label": "Ahnsen", "value": "C37780"},
                  {"label": "Ahsen-Oetzen", "value": "C37781"},
                  {"label": "Alfeld", "value": "C37782"},
                  {"label": "Alfhausen", "value": "C37783"},
                  {"label": "Algermissen", "value": "C37784"},
                  {"label": "Alt Wallmoden", "value": "C37785"},
                  {"label": "Altenau", "value": "C37786"},
                  {"label": "Altenmedingen", "value": "C37787"},
                  {"label": "Amelinghausen", "value": "C37788"},
                  {"label": "Ankum", "value": "C37789"},
                  {"label": "Apelern", "value": "C37790"},
                  {"label": "Apen", "value": "C37791"},
                  {"label": "Apensen", "value": "C37792"},
                  {"label": "Appel", "value": "C37793"},
                  {"label": "Arpke", "value": "C37794"},
                  {"label": "Artlenburg", "value": "C37795"},
                  {"label": "Asendorf", "value": "C37796"},
                  {"label": "Auf der Horst", "value": "C37797"},
                  {"label": "Auhagen", "value": "C37798"},
                  {"label": "Aurich", "value": "C37799"},
                  {"label": "Axstedt", "value": "C37800"},
                  {"label": "Bad Bentheim", "value": "C37801"},
                  {"label": "Bad Bevensen", "value": "C37802"},
                  {"label": "Bad Eilsen", "value": "C37803"},
                  {"label": "Bad Essen", "value": "C37804"},
                  {"label": "Bad Fallingbostel", "value": "C37805"},
                  {"label": "Bad Gandersheim", "value": "C37806"},
                  {"label": "Bad Grund", "value": "C37807"},
                  {"label": "Bad Harzburg", "value": "C37808"},
                  {"label": "Bad Iburg", "value": "C37809"},
                  {"label": "Bad Laer", "value": "C37810"},
                  {"label": "Bad Lauterberg im Harz", "value": "C37811"},
                  {"label": "Bad Munder am Deister", "value": "C37812"},
                  {"label": "Bad Nenndorf", "value": "C37813"},
                  {"label": "Bad Pyrmont", "value": "C37814"},
                  {"label": "Bad Rothenfelde", "value": "C37815"},
                  {"label": "Bad Sachsa", "value": "C37816"},
                  {"label": "Bad Salzdetfurth", "value": "C37817"},
                  {"label": "Bad Zwischenahn", "value": "C37818"},
                  {"label": "Badbergen", "value": "C37819"},
                  {"label": "Baddeckenstedt", "value": "C37820"},
                  {"label": "Badenhausen", "value": "C37821"},
                  {"label": "Bahrdorf", "value": "C37822"},
                  {"label": "Bahrenborstel", "value": "C37823"},
                  {"label": "Bakum", "value": "C37824"},
                  {"label": "Balge", "value": "C37825"},
                  {"label": "Balje", "value": "C37826"},
                  {"label": "Banteln", "value": "C37827"},
                  {"label": "Bardowick", "value": "C37828"},
                  {"label": "Barenburg", "value": "C37829"},
                  {"label": "Barendorf", "value": "C37830"},
                  {"label": "Bargstedt", "value": "C37831"},
                  {"label": "Barnstorf", "value": "C37832"},
                  {"label": "Barsinghausen", "value": "C37833"},
                  {"label": "Barssel", "value": "C37834"},
                  {"label": "Barum", "value": "C37835"},
                  {"label": "Barver", "value": "C37836"},
                  {"label": "Barwedel", "value": "C37837"},
                  {"label": "Basdahl", "value": "C37838"},
                  {"label": "Bassum", "value": "C37839"},
                  {"label": "Bawinkel", "value": "C37840"},
                  {"label": "Beckdorf", "value": "C37841"},
                  {"label": "Beckedorf", "value": "C37842"},
                  {"label": "Beedenbostel", "value": "C37843"},
                  {"label": "Beesten", "value": "C37844"},
                  {"label": "Bei der Hohne", "value": "C37845"},
                  {"label": "Belm", "value": "C37846"},
                  {"label": "Bendestorf", "value": "C37847"},
                  {"label": "Berge", "value": "C37848"},
                  {"label": "Bergen", "value": "C37849"},
                  {"label": "Bergen an der Dumme", "value": "C37850"},
                  {"label": "Bersenbruck", "value": "C37851"},
                  {"label": "Berumbur", "value": "C37852"},
                  {"label": "Betheln", "value": "C37853"},
                  {"label": "Betzendorf", "value": "C37854"},
                  {"label": "Bevenrode", "value": "C37855"},
                  {"label": "Bevern", "value": "C37856"},
                  {"label": "Beverstedt", "value": "C37857"},
                  {"label": "Bienenbuttel", "value": "C37858"},
                  {"label": "Bilshausen", "value": "C37859"},
                  {"label": "Binnen", "value": "C37860"},
                  {"label": "Bippen", "value": "C37861"},
                  {"label": "Bispingen", "value": "C37862"},
                  {"label": "Bissendorf", "value": "C37863"},
                  {"label": "Bleckede", "value": "C37864"},
                  {"label": "Blender", "value": "C37865"},
                  {"label": "Bliedersdorf", "value": "C37866"},
                  {"label": "Blomberg", "value": "C37867"},
                  {"label": "Bockenem", "value": "C37868"},
                  {"label": "Bockhorn", "value": "C37869"},
                  {"label": "Bockhorst", "value": "C37870"},
                  {"label": "Bodenfelde", "value": "C37871"},
                  {"label": "Bodenwerder", "value": "C37872"},
                  {"label": "Boffzen", "value": "C37873"},
                  {"label": "Bohmte", "value": "C37874"},
                  {"label": "Bokel", "value": "C37875"},
                  {"label": "Bomlitz", "value": "C37876"},
                  {"label": "Borger", "value": "C37877"},
                  {"label": "Borkum", "value": "C37878"},
                  {"label": "Borssum", "value": "C37879"},
                  {"label": "Borstel", "value": "C37880"},
                  {"label": "Bosel", "value": "C37881"},
                  {"label": "Botersen", "value": "C37882"},
                  {"label": "Bothel", "value": "C37883"},
                  {"label": "Bovenden", "value": "C37884"},
                  {"label": "Brackel", "value": "C37885"},
                  {"label": "Brake (Unterweser)", "value": "C37886"},
                  {"label": "Bramsche", "value": "C37887"},
                  {"label": "Bramstedt", "value": "C37888"},
                  {"label": "Braunlage", "value": "C37889"},
                  {"label": "Braunschweig", "value": "C37890"},
                  {"label": "Breddorf", "value": "C37891"},
                  {"label": "Bremervorde", "value": "C37892"},
                  {"label": "Brietlingen", "value": "C37893"},
                  {"label": "Brockel", "value": "C37894"},
                  {"label": "Brockum", "value": "C37895"},
                  {"label": "Brome", "value": "C37896"},
                  {"label": "Bruchhausen-Vilsen", "value": "C37897"},
                  {"label": "Bruggen", "value": "C37898"},
                  {"label": "Buchholz", "value": "C37899"},
                  {"label": "Buchholz in der Nordheide", "value": "C37900"},
                  {"label": "Buckeburg", "value": "C37901"},
                  {"label": "Bucken", "value": "C37902"},
                  {"label": "Buddenstedt", "value": "C37903"},
                  {"label": "Buhren", "value": "C37904"},
                  {"label": "Bunde", "value": "C37905"},
                  {"label": "Burgdorf", "value": "C37906"},
                  {"label": "Butjadingen", "value": "C37907"},
                  {"label": "Buxtehude", "value": "C37908"},
                  {"label": "Cadenberge", "value": "C37909"},
                  {"label": "Calberlah", "value": "C37910"},
                  {"label": "Cappeln", "value": "C37911"},
                  {"label": "Celle", "value": "C37912"},
                  {"label": "Clausthal-Zellerfeld", "value": "C37913"},
                  {"label": "Clenze", "value": "C37914"},
                  {"label": "Cloppenburg", "value": "C37915"},
                  {"label": "Coppenbrugge", "value": "C37916"},
                  {"label": "Cremlingen", "value": "C37917"},
                  {"label": "Cuxhaven", "value": "C37918"},
                  {"label": "Dahlenburg", "value": "C37919"},
                  {"label": "Damme", "value": "C37920"},
                  {"label": "Danndorf", "value": "C37921"},
                  {"label": "Dannenberg", "value": "C37922"},
                  {"label": "Dassel", "value": "C37923"},
                  {"label": "Dedelstorf", "value": "C37924"},
                  {"label": "Deensen", "value": "C37925"},
                  {"label": "Deinste", "value": "C37926"},
                  {"label": "Delligsen", "value": "C37927"},
                  {"label": "Delmenhorst", "value": "C37928"},
                  {"label": "Dersum", "value": "C37929"},
                  {"label": "Destedt", "value": "C37930"},
                  {"label": "Detern", "value": "C37931"},
                  {"label": "Detmerode", "value": "C37932"},
                  {"label": "Dettum", "value": "C37933"},
                  {"label": "Deutsch Evern", "value": "C37934"},
                  {"label": "Didderse", "value": "C37935"},
                  {"label": "Diekholzen", "value": "C37936"},
                  {"label": "Diepholz", "value": "C37937"},
                  {"label": "Dinklage", "value": "C37938"},
                  {"label": "Dissen", "value": "C37939"},
                  {"label": "Dohren", "value": "C37940"},
                  {"label": "Dollbergen", "value": "C37941"},
                  {"label": "Dollern", "value": "C37942"},
                  {"label": "Dornum", "value": "C37943"},
                  {"label": "Dorpen", "value": "C37944"},
                  {"label": "Dorum", "value": "C37945"},
                  {"label": "Dorverden", "value": "C37946"},
                  {"label": "Dotlingen", "value": "C37947"},
                  {"label": "Drage", "value": "C37948"},
                  {"label": "Drakenburg", "value": "C37949"},
                  {"label": "Drangstedt", "value": "C37950"},
                  {"label": "Dransfeld", "value": "C37951"},
                  {"label": "Drentwede", "value": "C37952"},
                  {"label": "Drochtersen", "value": "C37953"},
                  {"label": "Duderstadt", "value": "C37954"},
                  {"label": "Duingen", "value": "C37955"},
                  {"label": "Dunsen", "value": "C37956"},
                  {"label": "Dunum", "value": "C37957"},
                  {"label": "Ebergotzen", "value": "C37958"},
                  {"label": "Ebersdorf", "value": "C37959"},
                  {"label": "Ebstorf", "value": "C37960"},
                  {"label": "Echem", "value": "C37961"},
                  {"label": "Edemissen", "value": "C37962"},
                  {"label": "Edewecht", "value": "C37963"},
                  {"label": "Egestorf", "value": "C37964"},
                  {"label": "Eggermuhlen", "value": "C37965"},
                  {"label": "Ehrenburg", "value": "C37966"},
                  {"label": "Eicklingen", "value": "C37967"},
                  {"label": "Eime", "value": "C37968"},
                  {"label": "Eimen", "value": "C37969"},
                  {"label": "Eimke", "value": "C37970"},
                  {"label": "Einbeck", "value": "C37971"},
                  {"label": "Eisdorf am Harz", "value": "C37972"},
                  {"label": "Elbe", "value": "C37973"},
                  {"label": "Eldingen", "value": "C37974"},
                  {"label": "Elsdorf", "value": "C37975"},
                  {"label": "Elsfleth", "value": "C37976"},
                  {"label": "Elze", "value": "C37977"},
                  {"label": "Embsen", "value": "C37978"},
                  {"label": "Emden", "value": "C37979"},
                  {"label": "Emlichheim", "value": "C37980"},
                  {"label": "Emsburen", "value": "C37981"},
                  {"label": "Emstek", "value": "C37982"},
                  {"label": "Emtinghausen", "value": "C37983"},
                  {"label": "Engeln", "value": "C37984"},
                  {"label": "Erkerode", "value": "C37985"},
                  {"label": "Eschede", "value": "C37986"},
                  {"label": "Eschershausen", "value": "C37987"},
                  {"label": "Esens", "value": "C37988"},
                  {"label": "Essel", "value": "C37989"},
                  {"label": "Essen", "value": "C37990"},
                  {"label": "Esterwegen", "value": "C37991"},
                  {"label": "Estorf", "value": "C37992"},
                  {"label": "Evessen", "value": "C37993"},
                  {"label": "Eydelstedt", "value": "C37994"},
                  {"label": "Eyendorf", "value": "C37995"},
                  {"label": "Eystrup", "value": "C37996"},
                  {"label": "Fassberg", "value": "C37997"},
                  {"label": "Fedderwarden", "value": "C37998"},
                  {"label": "Filsum", "value": "C37999"},
                  {"label": "Fintel", "value": "C38000"},
                  {"label": "Flothe", "value": "C38001"},
                  {"label": "Freden", "value": "C38002"},
                  {"label": "Freiburg", "value": "C38003"},
                  {"label": "Freren", "value": "C38004"},
                  {"label": "Friedeburg", "value": "C38005"},
                  {"label": "Friedland", "value": "C38006"},
                  {"label": "Friesoythe", "value": "C38007"},
                  {"label": "Furstenau", "value": "C38008"},
                  {"label": "Furstenberg", "value": "C38009"},
                  {"label": "Ganderkesee", "value": "C38010"},
                  {"label": "Garbsen", "value": "C38011"},
                  {"label": "Garbsen-Mitte", "value": "C38012"},
                  {"label": "Garlstorf", "value": "C38013"},
                  {"label": "Garrel", "value": "C38014"},
                  {"label": "Garstedt", "value": "C38015"},
                  {"label": "Gartow", "value": "C38016"},
                  {"label": "Geeste", "value": "C38017"},
                  {"label": "Gehrde", "value": "C38018"},
                  {"label": "Gehrden", "value": "C38019"},
                  {"label": "Gemeinde Friedland", "value": "C38020"},
                  {"label": "Georgsdorf", "value": "C38021"},
                  {"label": "Georgsmarienhutte", "value": "C38022"},
                  {"label": "Gerdau", "value": "C38023"},
                  {"label": "Gersten", "value": "C38024"},
                  {"label": "Gieboldehausen", "value": "C38025"},
                  {"label": "Giesen", "value": "C38026"},
                  {"label": "Gifhorn", "value": "C38027"},
                  {"label": "Gilten", "value": "C38028"},
                  {"label": "Gittelde", "value": "C38029"},
                  {"label": "Glandorf", "value": "C38030"},
                  {"label": "Gnarrenburg", "value": "C38031"},
                  {"label": "Goldenstedt", "value": "C38032"},
                  {"label": "Golmbach", "value": "C38033"},
                  {"label": "Goslar", "value": "C38034"},
                  {"label": "Gottingen", "value": "C38035"},
                  {"label": "Grafhorst", "value": "C38036"},
                  {"label": "Grasberg", "value": "C38037"},
                  {"label": "Grasleben", "value": "C38038"},
                  {"label": "Gronau", "value": "C38039"},
                  {"label": "Gross Ippener", "value": "C38040"},
                  {"label": "Gross Munzel", "value": "C38041"},
                  {"label": "Gross Oesingen", "value": "C38042"},
                  {"label": "Gross Twulpstedt", "value": "C38043"},
                  {"label": "Grossenkneten", "value": "C38044"},
                  {"label": "Grossgoltern", "value": "C38045"},
                  {"label": "Grunendeich", "value": "C38046"},
                  {"label": "Guderhandviertel", "value": "C38047"},
                  {"label": "Gyhum", "value": "C38048"},
                  {"label": "Hage", "value": "C38049"},
                  {"label": "Hagen", "value": "C38050"},
                  {"label": "Hagen im Bremischen", "value": "C38051"},
                  {"label": "Hagenburg", "value": "C38052"},
                  {"label": "Halbemond", "value": "C38053"},
                  {"label": "Halle", "value": "C38054"},
                  {"label": "Hambergen", "value": "C38055"},
                  {"label": "Hambuhren", "value": "C38056"},
                  {"label": "Hameln", "value": "C38057"},
                  {"label": "Hammah", "value": "C38058"},
                  {"label": "Handeloh", "value": "C38059"},
                  {"label": "Handorf", "value": "C38060"},
                  {"label": "Hanigsen", "value": "C38061"},
                  {"label": "Hankensbuttel", "value": "C38062"},
                  {"label": "Hannover", "value": "C38063"},
                  {"label": "Hannoversch Munden", "value": "C38064"},
                  {"label": "Hanstedt", "value": "C38065"},
                  {"label": "Hanstedt Eins", "value": "C38066"},
                  {"label": "Hardegsen", "value": "C38067"},
                  {"label": "Haren", "value": "C38068"},
                  {"label": "Harpstedt", "value": "C38069"},
                  {"label": "Harsefeld", "value": "C38070"},
                  {"label": "Harsum", "value": "C38071"},
                  {"label": "Hasbergen", "value": "C38072"},
                  {"label": "Haselunne", "value": "C38073"},
                  {"label": "Hassbergen", "value": "C38074"},
                  {"label": "Hassel", "value": "C38075"},
                  {"label": "Hassendorf", "value": "C38076"},
                  {"label": "Haste", "value": "C38077"},
                  {"label": "Hatten", "value": "C38078"},
                  {"label": "Hattorf", "value": "C38079"},
                  {"label": "Haverlah", "value": "C38080"},
                  {"label": "Hechthausen", "value": "C38081"},
                  {"label": "Heede", "value": "C38082"},
                  {"label": "Heemsen", "value": "C38083"},
                  {"label": "Heere", "value": "C38084"},
                  {"label": "Heeslingen", "value": "C38085"},
                  {"label": "Heessen", "value": "C38086"},
                  {"label": "Hehlen", "value": "C38087"},
                  {"label": "Heidenau", "value": "C38088"},
                  {"label": "Heinade", "value": "C38089"},
                  {"label": "Heinbockel", "value": "C38090"},
                  {"label": "Heinsen", "value": "C38091"},
                  {"label": "Hellwege", "value": "C38092"},
                  {"label": "Helmstedt", "value": "C38093"},
                  {"label": "Helpsen", "value": "C38094"},
                  {"label": "Hemmingen", "value": "C38095"},
                  {"label": "Hemmoor", "value": "C38096"},
                  {"label": "Hemsbunde", "value": "C38097"},
                  {"label": "Hemslingen", "value": "C38098"},
                  {"label": "Hepstedt", "value": "C38099"},
                  {"label": "Hermannsburg", "value": "C38100"},
                  {"label": "Herzberg am Harz", "value": "C38101"},
                  {"label": "Herzlake", "value": "C38102"},
                  {"label": "Hesel", "value": "C38103"},
                  {"label": "Hespe", "value": "C38104"},
                  {"label": "Hessisch Oldendorf", "value": "C38105"},
                  {"label": "Heuerssen", "value": "C38106"},
                  {"label": "Heyersum", "value": "C38107"},
                  {"label": "Hildesheim", "value": "C38108"},
                  {"label": "Hilgermissen", "value": "C38109"},
                  {"label": "Hillerse", "value": "C38110"},
                  {"label": "Hilter", "value": "C38111"},
                  {"label": "Himbergen", "value": "C38112"},
                  {"label": "Himmelpforten", "value": "C38113"},
                  {"label": "Hinte", "value": "C38114"},
                  {"label": "Hipstedt", "value": "C38115"},
                  {"label": "Hitzacker", "value": "C38116"},
                  {"label": "Hodenhagen", "value": "C38117"},
                  {"label": "Hohenhameln", "value": "C38118"},
                  {"label": "Hohnhorst", "value": "C38119"},
                  {"label": "Holdorf", "value": "C38120"},
                  {"label": "Holle", "value": "C38121"},
                  {"label": "Hollenstedt", "value": "C38122"},
                  {"label": "Holtland", "value": "C38123"},
                  {"label": "Holzminden", "value": "C38124"},
                  {"label": "Hoogstede", "value": "C38125"},
                  {"label": "Horden", "value": "C38126"},
                  {"label": "Hornburg", "value": "C38127"},
                  {"label": "Horneburg", "value": "C38128"},
                  {"label": "Horstedt", "value": "C38129"},
                  {"label": "Hoya", "value": "C38130"},
                  {"label": "Hude", "value": "C38131"},
                  {"label": "Huede", "value": "C38132"},
                  {"label": "Hulsede", "value": "C38133"},
                  {"label": "Husum", "value": "C38134"},
                  {"label": "Ihlienworth", "value": "C38135"},
                  {"label": "Ilsede", "value": "C38136"},
                  {"label": "Isenbuttel", "value": "C38137"},
                  {"label": "Isernhagen Farster Bauerschaft",
                   "value": "C38138"},
                  {"label": "Itterbeck", "value": "C38139"},
                  {"label": "Jameln", "value": "C38140"},
                  {"label": "Jembke", "value": "C38141"},
                  {"label": "Jemgum", "value": "C38142"},
                  {"label": "Jerxheim", "value": "C38143"},
                  {"label": "Jesteburg", "value": "C38144"},
                  {"label": "Jever", "value": "C38145"},
                  {"label": "Jork", "value": "C38146"},
                  {"label": "Juhnde", "value": "C38147"},
                  {"label": "Juist", "value": "C38148"},
                  {"label": "Kakenstorf", "value": "C38149"},
                  {"label": "Kalefeld", "value": "C38150"},
                  {"label": "Katlenburg-Lindau", "value": "C38151"},
                  {"label": "Kettenkamp", "value": "C38152"},
                  {"label": "Kirchbrak", "value": "C38153"},
                  {"label": "Kirchdorf", "value": "C38154"},
                  {"label": "Kirchgellersen", "value": "C38155"},
                  {"label": "Kirchlinteln", "value": "C38156"},
                  {"label": "Kirchseelte", "value": "C38157"},
                  {"label": "Kirchtimke", "value": "C38158"},
                  {"label": "Kirchwalsede", "value": "C38159"},
                  {"label": "Kissenbruck", "value": "C38160"},
                  {"label": "Klein Berssen", "value": "C38161"},
                  {"label": "Klein Gusborn", "value": "C38162"},
                  {"label": "Klein Schwulper", "value": "C38163"},
                  {"label": "Kluse", "value": "C38164"},
                  {"label": "Konigslutter am Elm", "value": "C38165"},
                  {"label": "Krebeck", "value": "C38166"},
                  {"label": "Kreiensen", "value": "C38167"},
                  {"label": "Kuhrstedt", "value": "C38168"},
                  {"label": "Kusten", "value": "C38169"},
                  {"label": "Kutenholz", "value": "C38170"},
                  {"label": "Laar", "value": "C38171"},
                  {"label": "Laatzen", "value": "C38172"},
                  {"label": "Lachendorf", "value": "C38173"},
                  {"label": "Lahden", "value": "C38174"},
                  {"label": "Lamspringe", "value": "C38175"},
                  {"label": "Lamstedt", "value": "C38176"},
                  {"label": "Landesbergen", "value": "C38177"},
                  {"label": "Landolfshausen", "value": "C38178"},
                  {"label": "Langelsheim", "value": "C38179"},
                  {"label": "Langen", "value": "C38180"},
                  {"label": "Langenhagen", "value": "C38181"},
                  {"label": "Langeoog", "value": "C38182"},
                  {"label": "Langlingen", "value": "C38183"},
                  {"label": "Langwedel", "value": "C38184"},
                  {"label": "Lastrup", "value": "C38185"},
                  {"label": "Lathen", "value": "C38186"},
                  {"label": "Lauenau", "value": "C38187"},
                  {"label": "Lauenbruck", "value": "C38188"},
                  {"label": "Lauenhagen", "value": "C38189"},
                  {"label": "Leer", "value": "C38190"},
                  {"label": "Leese", "value": "C38191"},
                  {"label": "Leezdorf", "value": "C38192"},
                  {"label": "Lehre", "value": "C38193"},
                  {"label": "Lehrte", "value": "C38194"},
                  {"label": "Leiferde", "value": "C38195"},
                  {"label": "Lemforde", "value": "C38196"},
                  {"label": "Lemwerder", "value": "C38197"},
                  {"label": "Lengede", "value": "C38198"},
                  {"label": "Lengerich", "value": "C38199"},
                  {"label": "Liebenau", "value": "C38200"},
                  {"label": "Liebenburg", "value": "C38201"},
                  {"label": "Lilienthal", "value": "C38202"},
                  {"label": "Lindhorst", "value": "C38203"},
                  {"label": "Lindwedel", "value": "C38204"},
                  {"label": "Lingen", "value": "C38205"},
                  {"label": "Lintig", "value": "C38206"},
                  {"label": "Lohne", "value": "C38207"},
                  {"label": "Loningen", "value": "C38208"},
                  {"label": "Lorup", "value": "C38209"},
                  {"label": "Loxstedt", "value": "C38210"},
                  {"label": "Luchow", "value": "C38211"},
                  {"label": "Luder", "value": "C38212"},
                  {"label": "Ludersfeld", "value": "C38213"},
                  {"label": "Luhden", "value": "C38214"},
                  {"label": "Luneburg", "value": "C38215"},
                  {"label": "Lunestedt", "value": "C38216"},
                  {"label": "Lunne", "value": "C38217"},
                  {"label": "Lutter am Barenberge", "value": "C38218"},
                  {"label": "Marienhafe", "value": "C38219"},
                  {"label": "Mariental", "value": "C38220"},
                  {"label": "Marklohe", "value": "C38221"},
                  {"label": "Marschacht", "value": "C38222"},
                  {"label": "Martfeld", "value": "C38223"},
                  {"label": "Marxen", "value": "C38224"},
                  {"label": "Meerbeck", "value": "C38225"},
                  {"label": "Meerdorf", "value": "C38226"},
                  {"label": "Meinersen", "value": "C38227"},
                  {"label": "Melbeck", "value": "C38228"},
                  {"label": "Melle", "value": "C38229"},
                  {"label": "Mellinghausen", "value": "C38230"},
                  {"label": "Menslage", "value": "C38231"},
                  {"label": "Meppen", "value": "C38232"},
                  {"label": "Merzen", "value": "C38233"},
                  {"label": "Messingen", "value": "C38234"},
                  {"label": "Midlum", "value": "C38235"},
                  {"label": "Mittegrossefehn", "value": "C38236"},
                  {"label": "Moisburg", "value": "C38237"},
                  {"label": "Molbergen", "value": "C38238"},
                  {"label": "Moringen", "value": "C38239"},
                  {"label": "Morsum", "value": "C38240"},
                  {"label": "Muden", "value": "C38241"},
                  {"label": "Munster", "value": "C38242"},
                  {"label": "Nahrendorf", "value": "C38243"},
                  {"label": "Neu Darchau", "value": "C38244"},
                  {"label": "Neu Wulmstorf", "value": "C38245"},
                  {"label": "Neuborger", "value": "C38246"},
                  {"label": "Neubruck", "value": "C38247"},
                  {"label": "Neuenkirchen", "value": "C38248"},
                  {"label": "Neuharlingersiel", "value": "C38249"},
                  {"label": "Neuhaus an der Oste", "value": "C38250"},
                  {"label": "Neukamperfehn", "value": "C38251"},
                  {"label": "Neustadt am Rubenberge", "value": "C38252"},
                  {"label": "Niederlangen", "value": "C38253"},
                  {"label": "Niedernwohren", "value": "C38254"},
                  {"label": "Nienburg", "value": "C38255"},
                  {"label": "Nienstadt", "value": "C38256"},
                  {"label": "Norddeich", "value": "C38257"},
                  {"label": "Norden", "value": "C38258"},
                  {"label": "Nordenham", "value": "C38259"},
                  {"label": "Norderney", "value": "C38260"},
                  {"label": "Nordholz", "value": "C38261"},
                  {"label": "Nordhorn", "value": "C38262"},
                  {"label": "Nordleda", "value": "C38263"},
                  {"label": "Nordstemmen", "value": "C38264"},
                  {"label": "Norten-Hardenberg", "value": "C38265"},
                  {"label": "Northeim", "value": "C38266"},
                  {"label": "Nortmoor", "value": "C38267"},
                  {"label": "Nortrup", "value": "C38268"},
                  {"label": "Nottensdorf", "value": "C38269"},
                  {"label": "Oberndorf", "value": "C38270"},
                  {"label": "Obernfeld", "value": "C38271"},
                  {"label": "Obernkirchen", "value": "C38272"},
                  {"label": "Oederquart", "value": "C38273"},
                  {"label": "Oerel", "value": "C38274"},
                  {"label": "Oldenburg", "value": "C38275"},
                  {"label": "Oldendorf", "value": "C38276"},
                  {"label": "Osloss", "value": "C38277"},
                  {"label": "Osnabruck", "value": "C38278"},
                  {"label": "Osteel", "value": "C38279"},
                  {"label": "Osten", "value": "C38280"},
                  {"label": "Ostercappeln", "value": "C38281"},
                  {"label": "Osterholz-Scharmbeck", "value": "C38282"},
                  {"label": "Osterode am Harz", "value": "C38283"},
                  {"label": "Ostrhauderfehn", "value": "C38284"},
                  {"label": "Ottenstein", "value": "C38285"},
                  {"label": "Otter", "value": "C38286"},
                  {"label": "Otterndorf", "value": "C38287"},
                  {"label": "Ottersberg", "value": "C38288"},
                  {"label": "Ovelgonne", "value": "C38289"},
                  {"label": "Oyten", "value": "C38290"},
                  {"label": "Papenburg", "value": "C38291"},
                  {"label": "Parsau", "value": "C38292"},
                  {"label": "Pattensen", "value": "C38293"},
                  {"label": "Peine", "value": "C38294"},
                  {"label": "Pennigsehl", "value": "C38295"},
                  {"label": "Polle", "value": "C38296"},
                  {"label": "Pollhagen", "value": "C38297"},
                  {"label": "Quakenbruck", "value": "C38298"},
                  {"label": "Radbruch", "value": "C38299"},
                  {"label": "Raddestorf", "value": "C38300"},
                  {"label": "Rastede", "value": "C38301"},
                  {"label": "Rechtsupweg", "value": "C38302"},
                  {"label": "Reessum", "value": "C38303"},
                  {"label": "Regesbostel", "value": "C38304"},
                  {"label": "Rehburg-Loccum", "value": "C38305"},
                  {"label": "Rehden", "value": "C38306"},
                  {"label": "Reinstorf", "value": "C38307"},
                  {"label": "Remlingen", "value": "C38308"},
                  {"label": "Reppenstedt", "value": "C38309"},
                  {"label": "Rethem", "value": "C38310"},
                  {"label": "Rethen", "value": "C38311"},
                  {"label": "Rhade", "value": "C38312"},
                  {"label": "Rhede", "value": "C38313"},
                  {"label": "Rheden", "value": "C38314"},
                  {"label": "Rhumspringe", "value": "C38315"},
                  {"label": "Ribbesbuttel", "value": "C38316"},
                  {"label": "Riede", "value": "C38317"},
                  {"label": "Rieste", "value": "C38318"},
                  {"label": "Rinteln", "value": "C38319"},
                  {"label": "Ritterhude", "value": "C38320"},
                  {"label": "Rodenberg", "value": "C38321"},
                  {"label": "Rohrsen", "value": "C38322"},
                  {"label": "Ronnenberg", "value": "C38323"},
                  {"label": "Rosche", "value": "C38324"},
                  {"label": "Rosdorf", "value": "C38325"},
                  {"label": "Rotenburg", "value": "C38326"},
                  {"label": "Rotgesbuttel", "value": "C38327"},
                  {"label": "Ruhen", "value": "C38328"},
                  {"label": "Rullstorf", "value": "C38329"},
                  {"label": "Sachsenhagen", "value": "C38330"},
                  {"label": "Salzbergen", "value": "C38331"},
                  {"label": "Salzgitter", "value": "C38332"},
                  {"label": "Salzhausen", "value": "C38333"},
                  {"label": "Salzhemmendorf", "value": "C38334"},
                  {"label": "Sande", "value": "C38335"},
                  {"label": "Sandstedt", "value": "C38336"},
                  {"label": "Sankt Andreasberg", "value": "C38337"},
                  {"label": "Sarstedt", "value": "C38338"},
                  {"label": "Sassenburg", "value": "C38339"},
                  {"label": "Sauensiek", "value": "C38340"},
                  {"label": "Schapen", "value": "C38341"},
                  {"label": "Scharnebeck", "value": "C38342"},
                  {"label": "Scheden", "value": "C38343"},
                  {"label": "Scheessel", "value": "C38344"},
                  {"label": "Schellerten", "value": "C38345"},
                  {"label": "Schiffdorf", "value": "C38346"},
                  {"label": "Schillig", "value": "C38347"},
                  {"label": "Schladen", "value": "C38348"},
                  {"label": "Schnega", "value": "C38349"},
                  {"label": "Schneverdingen", "value": "C38350"},
                  {"label": "Schoningen", "value": "C38351"},
                  {"label": "Schoppenstedt", "value": "C38352"},
                  {"label": "Schortens", "value": "C38353"},
                  {"label": "Schuttorf", "value": "C38354"},
                  {"label": "Schwaforden", "value": "C38355"},
                  {"label": "Schwanewede", "value": "C38356"},
                  {"label": "Schwarme", "value": "C38357"},
                  {"label": "Schwarmstedt", "value": "C38358"},
                  {"label": "Seeburg", "value": "C38359"},
                  {"label": "Seelze", "value": "C38360"},
                  {"label": "Seesen", "value": "C38361"},
                  {"label": "Seevetal", "value": "C38362"},
                  {"label": "Seggebruch", "value": "C38363"},
                  {"label": "Sehlde", "value": "C38364"},
                  {"label": "Sehlem", "value": "C38365"},
                  {"label": "Sehnde", "value": "C38366"},
                  {"label": "Selsingen", "value": "C38367"},
                  {"label": "Seulingen", "value": "C38368"},
                  {"label": "Sibbesse", "value": "C38369"},
                  {"label": "Sickte", "value": "C38370"},
                  {"label": "Siedenburg", "value": "C38371"},
                  {"label": "Sillenstede", "value": "C38372"},
                  {"label": "Sittensen", "value": "C38373"},
                  {"label": "Soderstorf", "value": "C38374"},
                  {"label": "Sogel", "value": "C38375"},
                  {"label": "Sohlde", "value": "C38376"},
                  {"label": "Soltau", "value": "C38377"},
                  {"label": "Soltendieck", "value": "C38378"},
                  {"label": "Sottrum", "value": "C38379"},
                  {"label": "Spelle", "value": "C38380"},
                  {"label": "Sprakensehl", "value": "C38381"},
                  {"label": "Springe", "value": "C38382"},
                  {"label": "Stade", "value": "C38383"},
                  {"label": "Stadensen", "value": "C38384"},
                  {"label": "Stadthagen", "value": "C38385"},
                  {"label": "Stadtoldendorf", "value": "C38386"},
                  {"label": "Stedesdorf", "value": "C38387"},
                  {"label": "Steimbke", "value": "C38388"},
                  {"label": "Steinfeld", "value": "C38389"},
                  {"label": "Steinhorst", "value": "C38390"},
                  {"label": "Steinkirchen", "value": "C38391"},
                  {"label": "Stelle", "value": "C38392"},
                  {"label": "Steyerberg", "value": "C38393"},
                  {"label": "Stockse", "value": "C38394"},
                  {"label": "Stolzenau", "value": "C38395"},
                  {"label": "Stuhr", "value": "C38396"},
                  {"label": "Suddendorf", "value": "C38397"},
                  {"label": "Suderburg", "value": "C38398"},
                  {"label": "Sudergellersen", "value": "C38399"},
                  {"label": "Sudwalde", "value": "C38400"},
                  {"label": "Suhlendorf", "value": "C38401"},
                  {"label": "Sulingen", "value": "C38402"},
                  {"label": "Supplingen", "value": "C38403"},
                  {"label": "Surwold", "value": "C38404"},
                  {"label": "Sustedt", "value": "C38405"},
                  {"label": "Sustrum", "value": "C38406"},
                  {"label": "Syke", "value": "C38407"},
                  {"label": "Tappenbeck", "value": "C38408"},
                  {"label": "Tarmstedt", "value": "C38409"},
                  {"label": "Tespe", "value": "C38410"},
                  {"label": "Thedinghausen", "value": "C38411"},
                  {"label": "Thomasburg", "value": "C38412"},
                  {"label": "Thuine", "value": "C38413"},
                  {"label": "Tiddische", "value": "C38414"},
                  {"label": "Toppenstedt", "value": "C38415"},
                  {"label": "Tostedt", "value": "C38416"},
                  {"label": "Tulau", "value": "C38417"},
                  {"label": "Twistringen", "value": "C38418"},
                  {"label": "Uchte", "value": "C38419"},
                  {"label": "Uehrde", "value": "C38420"},
                  {"label": "Uelsen", "value": "C38421"},
                  {"label": "Uelzen", "value": "C38422"},
                  {"label": "Uetze", "value": "C38423"},
                  {"label": "Ummern", "value": "C38424"},
                  {"label": "Unterluss", "value": "C38425"},
                  {"label": "Upgant-Schott", "value": "C38426"},
                  {"label": "Uslar", "value": "C38427"},
                  {"label": "Uthlede", "value": "C38428"},
                  {"label": "Varel", "value": "C38429"},
                  {"label": "Varrel", "value": "C38430"},
                  {"label": "Vechelde", "value": "C38431"},
                  {"label": "Vechta", "value": "C38432"},
                  {"label": "Velpke", "value": "C38433"},
                  {"label": "Veltheim", "value": "C38434"},
                  {"label": "Verden", "value": "C38435"},
                  {"label": "Vienenburg", "value": "C38436"},
                  {"label": "Visbek", "value": "C38437"},
                  {"label": "Visselhovede", "value": "C38438"},
                  {"label": "Vogelsen", "value": "C38439"},
                  {"label": "Vollersode", "value": "C38440"},
                  {"label": "Voltlage", "value": "C38441"},
                  {"label": "Vordorf", "value": "C38442"},
                  {"label": "Vorwerk", "value": "C38443"},
                  {"label": "Vrees", "value": "C38444"},
                  {"label": "Waake", "value": "C38445"},
                  {"label": "Wagenfeld", "value": "C38446"},
                  {"label": "Wagenhoff", "value": "C38447"},
                  {"label": "Wahrenholz", "value": "C38448"},
                  {"label": "Walchum", "value": "C38449"},
                  {"label": "Walkenried", "value": "C38450"},
                  {"label": "Wallenhorst", "value": "C38451"},
                  {"label": "Walsrode", "value": "C38452"},
                  {"label": "Wangerooge", "value": "C38453"},
                  {"label": "Wanna", "value": "C38454"},
                  {"label": "Wardenburg", "value": "C38455"},
                  {"label": "Warmsen", "value": "C38456"},
                  {"label": "Wasbuttel", "value": "C38457"},
                  {"label": "Wathlingen", "value": "C38458"},
                  {"label": "Weener", "value": "C38459"},
                  {"label": "Welle", "value": "C38460"},
                  {"label": "Wendeburg", "value": "C38461"},
                  {"label": "Wendisch Evern", "value": "C38462"},
                  {"label": "Wennigsen", "value": "C38463"},
                  {"label": "Wenzendorf", "value": "C38464"},
                  {"label": "Werlte", "value": "C38465"},
                  {"label": "Werpeloh", "value": "C38466"},
                  {"label": "Wesendorf", "value": "C38467"},
                  {"label": "Weste", "value": "C38468"},
                  {"label": "Westergellersen", "value": "C38469"},
                  {"label": "Westerholt", "value": "C38470"},
                  {"label": "Westerstede", "value": "C38471"},
                  {"label": "Westhagen", "value": "C38472"},
                  {"label": "Wetschen", "value": "C38473"},
                  {"label": "Weyhausen", "value": "C38474"},
                  {"label": "Wieda", "value": "C38475"},
                  {"label": "Wiedensahl", "value": "C38476"},
                  {"label": "Wiefelstede", "value": "C38477"},
                  {"label": "Wienhausen", "value": "C38478"},
                  {"label": "Wieren", "value": "C38479"},
                  {"label": "Wiesmoor", "value": "C38480"},
                  {"label": "Wietmarschen", "value": "C38481"},
                  {"label": "Wietze", "value": "C38482"},
                  {"label": "Wietzen", "value": "C38483"},
                  {"label": "Wietzendorf", "value": "C38484"},
                  {"label": "Wildemann", "value": "C38485"},
                  {"label": "Wildeshausen", "value": "C38486"},
                  {"label": "Wilhelmshaven", "value": "C38487"},
                  {"label": "Wilstedt", "value": "C38488"},
                  {"label": "Wilsum", "value": "C38489"},
                  {"label": "Windhausen", "value": "C38490"},
                  {"label": "Wingst", "value": "C38491"},
                  {"label": "Winsen", "value": "C38492"},
                  {"label": "Wirdum", "value": "C38493"},
                  {"label": "Wischhafen", "value": "C38494"},
                  {"label": "Wistedt", "value": "C38495"},
                  {"label": "Wittingen", "value": "C38496"},
                  {"label": "Wittmar", "value": "C38497"},
                  {"label": "Wittmund", "value": "C38498"},
                  {"label": "Wittorf", "value": "C38499"},
                  {"label": "Wolfenbuttel", "value": "C38500"},
                  {"label": "Wolfsburg", "value": "C38501"},
                  {"label": "Wolpinghausen", "value": "C38502"},
                  {"label": "Wolsdorf", "value": "C38503"},
                  {"label": "Woltersdorf", "value": "C38504"},
                  {"label": "Worpswede", "value": "C38505"},
                  {"label": "Wremen", "value": "C38506"},
                  {"label": "Wrestedt", "value": "C38507"},
                  {"label": "Wriedel", "value": "C38508"},
                  {"label": "Wulfsen", "value": "C38509"},
                  {"label": "Wulften", "value": "C38510"},
                  {"label": "Wulsbuttel", "value": "C38511"},
                  {"label": "Wunstorf", "value": "C38512"},
                  {"label": "Wustrow", "value": "C38513"},
                  {"label": "Zernien", "value": "C38514"},
                  {"label": "Zetel", "value": "C38515"},
                  {"label": "Zeven", "value": "C38516"},
                  {"label": "Zorge", "value": "C38517"}],
 "Lower Shebelle": [{"label": "Afgooye", "value": "C107906"},
                    {"label": "Marka", "value": "C107907"},
                    {"label": "Qoryooley", "value": "C107908"},
                    {"label": "Wanlaweyn", "value": "C107909"}],
 "Lower Silesian Voivodeship": [{"label": "Bardo", "value": "C88421"},
                                {"label": "Bielany Wroclawskie",
                                 "value": "C88422"},
                                {"label": "Bielawa", "value": "C88423"},
                                {"label": "Bierutow", "value": "C88424"},
                                {"label": "Biskupin", "value": "C88425"},
                                {"label": "Bogatynia", "value": "C88426"},
                                {"label": "Boguszow-Gorce", "value": "C88427"},
                                {"label": "Boleslawiec", "value": "C88428"},
                                {"label": "Bolkow", "value": "C88429"},
                                {"label": "Bozkow", "value": "C88430"},
                                {"label": "Brzeg Dolny", "value": "C88431"},
                                {"label": "Budzow", "value": "C88432"},
                                {"label": "Bystrzyca", "value": "C88433"},
                                {"label": "Bystrzyca Klodzka",
                                 "value": "C88434"},
                                {"label": "Chocianow", "value": "C88435"},
                                {"label": "Chojnow", "value": "C88436"},
                                {"label": "Ciechow", "value": "C88437"},
                                {"label": "Cieplice Slaskie Zdroj",
                                 "value": "C88438"},
                                {"label": "Cieplowody", "value": "C88439"},
                                {"label": "Cieszkow", "value": "C88440"},
                                {"label": "Czarny Bor", "value": "C88441"},
                                {"label": "Czernica", "value": "C88442"},
                                {"label": "Dabie", "value": "C88443"},
                                {"label": "Dlugoleka", "value": "C88444"},
                                {"label": "Dobroszyce", "value": "C88445"},
                                {"label": "Duszniki-Zdroj", "value": "C88446"},
                                {"label": "Dziadowa Kloda", "value": "C88447"},
                                {"label": "Dziecmorowice", "value": "C88448"},
                                {"label": "Dzierzoniow", "value": "C88449"},
                                {"label": "Gadow Maly", "value": "C88450"},
                                {"label": "Gaworzyce", "value": "C88451"},
                                {"label": "Glogow", "value": "C88452"},
                                {"label": "Gluszyca", "value": "C88453"},
                                {"label": "Gluszyca Gorna", "value": "C88454"},
                                {"label": "Gniechowice", "value": "C88455"},
                                {"label": "Gora", "value": "C88456"},
                                {"label": "Grabiszyn", "value": "C88457"},
                                {"label": "Grabiszynek", "value": "C88458"},
                                {"label": "Grebocice", "value": "C88459"},
                                {"label": "Gromadka", "value": "C88460"},
                                {"label": "Gryfow Slaski", "value": "C88461"},
                                {"label": "Henrykow", "value": "C88462"},
                                {"label": "Janowice Wielkie",
                                 "value": "C88463"},
                                {"label": "Jarnoltow-Jerzmanowo",
                                 "value": "C88464"},
                                {"label": "Jawor", "value": "C88465"},
                                {"label": "Jaworzyna Slaska",
                                 "value": "C88466"},
                                {"label": "Jedlina-Zdroj", "value": "C88467"},
                                {"label": "Jelcz", "value": "C88468"},
                                {"label": "Jelcz Laskowice", "value": "C88469"},
                                {"label": "Jelenia Gora", "value": "C88470"},
                                {"label": "Jezow Sudecki", "value": "C88471"},
                                {"label": "Jordanow Slaski", "value": "C88472"},
                                {"label": "Jugow", "value": "C88473"},
                                {"label": "Kamieniec Wroclawski",
                                 "value": "C88474"},
                                {"label": "Kamieniec Zabkowicki",
                                 "value": "C88475"},
                                {"label": "Kamienna Gora", "value": "C88476"},
                                {"label": "Karlowice", "value": "C88477"},
                                {"label": "Karpacz", "value": "C88478"},
                                {"label": "Katy Wroclawskie",
                                 "value": "C88479"},
                                {"label": "Kielczow", "value": "C88480"},
                                {"label": "Klodzko", "value": "C88481"},
                                {"label": "Kobierzyce", "value": "C88482"},
                                {"label": "Kotla", "value": "C88483"},
                                {"label": "Kowale-Popiele", "value": "C88484"},
                                {"label": "Kowary", "value": "C88485"},
                                {"label": "Kozanow", "value": "C88486"},
                                {"label": "Krosnice", "value": "C88487"},
                                {"label": "Krzeszow", "value": "C88488"},
                                {"label": "Krzyki", "value": "C88489"},
                                {"label": "Ksieze Male-Ksieze Wielkie",
                                 "value": "C88490"},
                                {"label": "Kudowa-Zdroj", "value": "C88491"},
                                {"label": "Kunice", "value": "C88492"},
                                {"label": "Ladek-Zdroj", "value": "C88493"},
                                {"label": "Legnica", "value": "C88494"},
                                {"label": "Legnickie Pole", "value": "C88495"},
                                {"label": "Lesna", "value": "C88496"},
                                {"label": "Lesnica-Ratyn-Pustki",
                                 "value": "C88497"},
                                {"label": "Lewin Klodzki", "value": "C88498"},
                                {"label": "Luban", "value": "C88499"},
                                {"label": "Lubawka", "value": "C88500"},
                                {"label": "Lubin", "value": "C88501"},
                                {"label": "Lubomierz", "value": "C88502"},
                                {"label": "Ludwikowice Klodzkie",
                                 "value": "C88503"},
                                {"label": "Lwowek Slaski", "value": "C88504"},
                                {"label": "Lagiewniki", "value": "C88505"},
                                {"label": "Lagow", "value": "C88506"},
                                {"label": "Malczyce", "value": "C88507"},
                                {"label": "Marciszow", "value": "C88508"},
                                {"label": "Marszowice", "value": "C88509"},
                                {"label": "Maslice Male", "value": "C88510"},
                                {"label": "Maslice Wielkie", "value": "C88511"},
                                {"label": "Miedzyborz", "value": "C88512"},
                                {"label": "Miedzylesie", "value": "C88513"},
                                {"label": "Miekinia", "value": "C88514"},
                                {"label": "Mieroszow", "value": "C88515"},
                                {"label": "Milicz", "value": "C88516"},
                                {"label": "Milkowice", "value": "C88517"},
                                {"label": "Mirkow", "value": "C88518"},
                                {"label": "Mirsk", "value": "C88519"},
                                {"label": "Mrozow", "value": "C88520"},
                                {"label": "Myslakowice", "value": "C88521"},
                                {"label": "Niemcza", "value": "C88522"},
                                {"label": "Nowa Ruda", "value": "C88523"},
                                {"label": "Nowogrodziec", "value": "C88524"},
                                {"label": "Oborniki Slaskie",
                                 "value": "C88525"},
                                {"label": "Olesnica", "value": "C88526"},
                                {"label": "Olszanica", "value": "C88527"},
                                {"label": "Olszyna", "value": "C88528"},
                                {"label": "Olawa", "value": "C88529"},
                                {"label": "Osiedle Henrykowskie",
                                 "value": "C88530"},
                                {"label": "Osiedle Kosmonautow",
                                 "value": "C88531"},
                                {"label": "Osiek", "value": "C88532"},
                                {"label": "Ostroszowice", "value": "C88533"},
                                {"label": "Partynice", "value": "C88534"},
                                {"label": "Paszowice", "value": "C88535"},
                                {"label": "Pawlowice-Klokoczyce",
                                 "value": "C88536"},
                                {"label": "Piechowice", "value": "C88537"},
                                {"label": "Piensk", "value": "C88538"},
                                {"label": "Pieszyce", "value": "C88539"},
                                {"label": "Pilczyce", "value": "C88540"},
                                {"label": "Pilawa Gorna", "value": "C88541"},
                                {"label": "Pisarzowice", "value": "C88542"},
                                {"label": "Podgorzyn", "value": "C88543"},
                                {"label": "Polanica-Zdroj", "value": "C88544"},
                                {"label": "Polkowice", "value": "C88545"},
                                {"label": "Poswietne", "value": "C88546"},
                                {"label": "Powiat boleslawiecki",
                                 "value": "C88547"},
                                {"label": "Powiat dzierzoniowski",
                                 "value": "C88548"},
                                {"label": "Powiat glogowski",
                                 "value": "C88549"},
                                {"label": "Powiat gorowski", "value": "C88550"},
                                {"label": "Powiat jaworski", "value": "C88551"},
                                {"label": "Powiat jeleniogorski",
                                 "value": "C88552"},
                                {"label": "Powiat kamiennogorski",
                                 "value": "C88553"},
                                {"label": "Powiat klodzki", "value": "C88554"},
                                {"label": "Powiat legnicki", "value": "C88555"},
                                {"label": "Powiat lubanski", "value": "C88556"},
                                {"label": "Powiat lubinski", "value": "C88557"},
                                {"label": "Powiat lwowecki", "value": "C88558"},
                                {"label": "Powiat milicki", "value": "C88559"},
                                {"label": "Powiat olesnicki",
                                 "value": "C88560"},
                                {"label": "Powiat olawski", "value": "C88561"},
                                {"label": "Powiat polkowicki",
                                 "value": "C88562"},
                                {"label": "Powiat sredzki", "value": "C88563"},
                                {"label": "Powiat strzelinski",
                                 "value": "C88564"},
                                {"label": "Powiat swidnicki",
                                 "value": "C88565"},
                                {"label": "Powiat trzebnicki",
                                 "value": "C88566"},
                                {"label": "Powiat walbrzyski",
                                 "value": "C88567"},
                                {"label": "Powiat wolowski", "value": "C88568"},
                                {"label": "Powiat wroclawski",
                                 "value": "C88569"},
                                {"label": "Powiat zabkowicki",
                                 "value": "C88570"},
                                {"label": "Powiat zgorzelecki",
                                 "value": "C88571"},
                                {"label": "Powiat zlotoryjski",
                                 "value": "C88572"},
                                {"label": "Powstancow Slaskich Wschod",
                                 "value": "C88573"},
                                {"label": "Powstancow Slaskich Zachod-Centrum"
                                          ,
                                 "value": "C88574"},
                                {"label": "Pracze Odrzanskie-Janowek",
                                 "value": "C88575"},
                                {"label": "Prochowice", "value": "C88576"},
                                {"label": "Prusice", "value": "C88577"},
                                {"label": "Przemkow", "value": "C88578"},
                                {"label": "Przeworno", "value": "C88579"},
                                {"label": "Psary", "value": "C88580"},
                                {"label": "Psie Pole Poludnie-Kielczow",
                                 "value": "C88581"},
                                {"label": "Radkow", "value": "C88582"},
                                {"label": "Radwanice", "value": "C88583"},
                                {"label": "Ratowice", "value": "C88584"},
                                {"label": "Rejon alei Kromera",
                                 "value": "C88585"},
                                {"label": "Rejon placu Grunwaldzkiego",
                                 "value": "C88586"},
                                {"label": "Rejon placu Swietego Macieja",
                                 "value": "C88587"},
                                {"label": "Rejon ulicy Borowskiej-Poludnie",
                                 "value": "C88588"},
                                {"label": "Rejon ulicy Kleczkowskiej",
                                 "value": "C88589"},
                                {"label": "Rejon ulicy Mieleckiej",
                                 "value": "C88590"},
                                {"label": "Rejon ulicy Saperow",
                                 "value": "C88591"},
                                {"label": "Rejon ulicy Traugutta",
                                 "value": "C88592"},
                                {"label": "Rozanka-Polanka", "value": "C88593"},
                                {"label": "Rudna", "value": "C88594"},
                                {"label": "Scinawa", "value": "C88595"},
                                {"label": "Sepolno", "value": "C88596"},
                                {"label": "Siechnice", "value": "C88597"},
                                {"label": "Siekierczyn", "value": "C88598"},
                                {"label": "Smolec", "value": "C88599"},
                                {"label": "Sobotka", "value": "C88600"},
                                {"label": "Soltysowice", "value": "C88601"},
                                {"label": "Sosnowka", "value": "C88602"},
                                {"label": "Sroda Slaska", "value": "C88603"},
                                {"label": "Stablowice", "value": "C88604"},
                                {"label": "Stanowice", "value": "C88605"},
                                {"label": "Stara Kamienica", "value": "C88606"},
                                {"label": "Stare Bogaczowice",
                                 "value": "C88607"},
                                {"label": "Stoszowice", "value": "C88608"},
                                {"label": "Strachocin-Wojnow",
                                 "value": "C88609"},
                                {"label": "Stronie Slaskie", "value": "C88610"},
                                {"label": "Strzegom", "value": "C88611"},
                                {"label": "Strzelin", "value": "C88612"},
                                {"label": "Sulikow", "value": "C88613"},
                                {"label": "Sulow", "value": "C88614"},
                                {"label": "Swidnica", "value": "C88615"},
                                {"label": "Swiebodzice", "value": "C88616"},
                                {"label": "Swieradow-Zdroj", "value": "C88617"},
                                {"label": "Swierzawa", "value": "C88618"},
                                {"label": "Swieta Katarzyna",
                                 "value": "C88619"},
                                {"label": "Swojczyce", "value": "C88620"},
                                {"label": "Sycow", "value": "C88621"},
                                {"label": "Szczawno-Zdroj", "value": "C88622"},
                                {"label": "Szczepanow", "value": "C88623"},
                                {"label": "Szczytna", "value": "C88624"},
                                {"label": "Szklarska Poreba",
                                 "value": "C88625"},
                                {"label": "Trzebnica", "value": "C88626"},
                                {"label": "Twardogora", "value": "C88627"},
                                {"label": "Tyniec Maly", "value": "C88628"},
                                {"label": "Uciechow", "value": "C88629"},
                                {"label": "Udanin", "value": "C88630"},
                                {"label": "Walim", "value": "C88631"},
                                {"label": "Walbrzych", "value": "C88632"},
                                {"label": "Wasosz", "value": "C88633"},
                                {"label": "Wegliniec", "value": "C88634"},
                                {"label": "Wiazow", "value": "C88635"},
                                {"label": "Widawa-Lipa Piotrowska-Polanowice",
                                 "value": "C88636"},
                                {"label": "Wierzbno", "value": "C88637"},
                                {"label": "Wilczyce", "value": "C88638"},
                                {"label": "Wilkow", "value": "C88639"},
                                {"label": "Winsko", "value": "C88640"},
                                {"label": "Wlen", "value": "C88641"},
                                {"label": "Wojcieszow", "value": "C88642"},
                                {"label": "Wojszyce", "value": "C88643"},
                                {"label": "Wolow", "value": "C88644"},
                                {"label": "Wroclaw", "value": "C88645"},
                                {"label": "Zabkowice Slaskie",
                                 "value": "C88646"},
                                {"label": "Zacisze", "value": "C88647"},
                                {"label": "Zagrodno", "value": "C88648"},
                                {"label": "Zakrzow", "value": "C88649"},
                                {"label": "Zalesie i Stadion",
                                 "value": "C88650"},
                                {"label": "Zarow", "value": "C88651"},
                                {"label": "Zerniki Wroclawskie",
                                 "value": "C88652"},
                                {"label": "Zgorzelec", "value": "C88653"},
                                {"label": "Zgorzelisko", "value": "C88654"},
                                {"label": "Ziebice", "value": "C88655"},
                                {"label": "Zlotniki", "value": "C88656"},
                                {"label": "Zlotoryja", "value": "C88657"},
                                {"label": "Zloty Stok", "value": "C88658"},
                                {"label": "Zmigrod", "value": "C88659"},
                                {"label": "Zorawina", "value": "C88660"}],
 "Lozovo Municipality": [{"label": "Lozovo", "value": "C65457"}],
 "Luanda Province": [{"label": "Belas", "value": "C559"},
                     {"label": "Icolo e Bengo", "value": "C560"},
                     {"label": "Luanda", "value": "C561"}],
 "Luang Namtha Province": [{"label": "Luang Namtha", "value": "C64795"},
                           {"label": "Muang Louang Namtha", "value": "C64796"}],
 "Luang Prabang Province": [{"label": "Luang Prabang", "value": "C64797"}],
 "Luapula Province": [{"label": "Kawambwa", "value": "C148110"},
                      {"label": "Mansa", "value": "C148111"},
                      {"label": "Mwense", "value": "C148112"},
                      {"label": "Nchelenge", "value": "C148113"},
                      {"label": "Samfya", "value": "C148114"}],
 "Lubana Municipality": [{"label": "Lubana", "value": "C64897"}],
 "Lublin Voivodeship": [{"label": "Abramow", "value": "C88661"},
                        {"label": "Adamow", "value": "C88662"},
                        {"label": "Aleksandrow", "value": "C88663"},
                        {"label": "Annopol", "value": "C88664"},
                        {"label": "Baranow", "value": "C88665"},
                        {"label": "Batorz", "value": "C88666"},
                        {"label": "Belzec", "value": "C88667"},
                        {"label": "Belzyce", "value": "C88668"},
                        {"label": "Biala Podlaska", "value": "C88669"},
                        {"label": "Bilgoraj", "value": "C88670"},
                        {"label": "Biszcza", "value": "C88671"},
                        {"label": "Borki", "value": "C88672"},
                        {"label": "Borzechow", "value": "C88673"},
                        {"label": "Bychawa", "value": "C88674"},
                        {"label": "Chelm", "value": "C88675"},
                        {"label": "Chodel", "value": "C88676"},
                        {"label": "Chrzanow", "value": "C88677"},
                        {"label": "Cycow", "value": "C88678"},
                        {"label": "Czemierniki", "value": "C88679"},
                        {"label": "Deblin", "value": "C88680"},
                        {"label": "Debowa Kloda", "value": "C88681"},
                        {"label": "Dolhobyczow", "value": "C88682"},
                        {"label": "Dorohusk", "value": "C88683"},
                        {"label": "Drelow", "value": "C88684"},
                        {"label": "Dubienka", "value": "C88685"},
                        {"label": "Dzwola", "value": "C88686"},
                        {"label": "Fajslawice", "value": "C88687"},
                        {"label": "Firlej", "value": "C88688"},
                        {"label": "Frampol", "value": "C88689"},
                        {"label": "Garbow", "value": "C88690"},
                        {"label": "Godziszow", "value": "C88691"},
                        {"label": "Godziszow Pierwszy", "value": "C88692"},
                        {"label": "Goraj", "value": "C88693"},
                        {"label": "Gorzkow", "value": "C88694"},
                        {"label": "Goscieradow", "value": "C88695"},
                        {"label": "Grabowiec", "value": "C88696"},
                        {"label": "Hansk", "value": "C88697"},
                        {"label": "Horodlo", "value": "C88698"},
                        {"label": "Hrubieszow", "value": "C88699"},
                        {"label": "Izbica", "value": "C88700"},
                        {"label": "Jablon", "value": "C88701"},
                        {"label": "Jablonna", "value": "C88702"},
                        {"label": "Jakubowice Murowane", "value": "C88703"},
                        {"label": "Janow Lubelski", "value": "C88704"},
                        {"label": "Janow Podlaski", "value": "C88705"},
                        {"label": "Janowiec", "value": "C88706"},
                        {"label": "Jarczow", "value": "C88707"},
                        {"label": "Jastkow", "value": "C88708"},
                        {"label": "Jeziorzany", "value": "C88709"},
                        {"label": "Jozefow", "value": "C88710"},
                        {"label": "Jozefow nad Wisla", "value": "C88711"},
                        {"label": "Kamionka", "value": "C88712"},
                        {"label": "Karczmiska", "value": "C88713"},
                        {"label": "Kazimierz Dolny", "value": "C88714"},
                        {"label": "Kloczew", "value": "C88715"},
                        {"label": "Kock", "value": "C88716"},
                        {"label": "Koden", "value": "C88717"},
                        {"label": "Komarow-Osada", "value": "C88718"},
                        {"label": "Komarowka Podlaska", "value": "C88719"},
                        {"label": "Konskowola", "value": "C88720"},
                        {"label": "Konstantynow", "value": "C88721"},
                        {"label": "Kozubszczyzna", "value": "C88722"},
                        {"label": "Krasniczyn", "value": "C88723"},
                        {"label": "Krasnik", "value": "C88724"},
                        {"label": "Krasnobrod", "value": "C88725"},
                        {"label": "Krasnystaw", "value": "C88726"},
                        {"label": "Krynice", "value": "C88727"},
                        {"label": "Krzczonow", "value": "C88728"},
                        {"label": "Krzywda", "value": "C88729"},
                        {"label": "Ksiezomierz", "value": "C88730"},
                        {"label": "Ksiezpol", "value": "C88731"},
                        {"label": "Kurow", "value": "C88732"},
                        {"label": "Lesna Podlaska", "value": "C88733"},
                        {"label": "Lubartow", "value": "C88734"},
                        {"label": "Lublin", "value": "C88735"},
                        {"label": "Lubycza Krolewska", "value": "C88736"},
                        {"label": "Ludwin", "value": "C88737"},
                        {"label": "Labunie", "value": "C88738"},
                        {"label": "Laszczow", "value": "C88739"},
                        {"label": "Laziska", "value": "C88740"},
                        {"label": "Leczna", "value": "C88741"},
                        {"label": "Lomazy", "value": "C88742"},
                        {"label": "Lopiennik Gorny", "value": "C88743"},
                        {"label": "Lukow", "value": "C88744"},
                        {"label": "Lukowa", "value": "C88745"},
                        {"label": "Markuszow", "value": "C88746"},
                        {"label": "Melgiew", "value": "C88747"},
                        {"label": "Miaczyn", "value": "C88748"},
                        {"label": "Michalow", "value": "C88749"},
                        {"label": "Michow", "value": "C88750"},
                        {"label": "Miedzyrzec Podlaski", "value": "C88751"},
                        {"label": "Milanow", "value": "C88752"},
                        {"label": "Milejow", "value": "C88753"},
                        {"label": "Mircze", "value": "C88754"},
                        {"label": "Modliborzyce", "value": "C88755"},
                        {"label": "Naleczow", "value": "C88756"},
                        {"label": "Niedrzwica Duza", "value": "C88757"},
                        {"label": "Niedzwiada", "value": "C88758"},
                        {"label": "Nielisz", "value": "C88759"},
                        {"label": "Niemce", "value": "C88760"},
                        {"label": "Nowodwor", "value": "C88761"},
                        {"label": "Obsza", "value": "C88762"},
                        {"label": "Opole Lubelskie", "value": "C88763"},
                        {"label": "Ostrow Lubelski", "value": "C88764"},
                        {"label": "Ostrowek", "value": "C88765"},
                        {"label": "Parczew", "value": "C88766"},
                        {"label": "Piaski", "value": "C88767"},
                        {"label": "Piszczac", "value": "C88768"},
                        {"label": "Podedworze", "value": "C88769"},
                        {"label": "Pokrowka", "value": "C88770"},
                        {"label": "Poniatowa", "value": "C88771"},
                        {"label": "Potok Gorny", "value": "C88772"},
                        {"label": "Potok Wielki", "value": "C88773"},
                        {"label": "Powiat bialski", "value": "C88774"},
                        {"label": "Powiat bilgorajski", "value": "C88775"},
                        {"label": "Powiat chelmski", "value": "C88776"},
                        {"label": "Powiat hrubieszowski", "value": "C88777"},
                        {"label": "Powiat janowski", "value": "C88778"},
                        {"label": "Powiat krasnicki", "value": "C88779"},
                        {"label": "Powiat krasnostawski", "value": "C88780"},
                        {"label": "Powiat lubartowski", "value": "C88781"},
                        {"label": "Powiat lubelski", "value": "C88782"},
                        {"label": "Powiat leczynski", "value": "C88783"},
                        {"label": "Powiat lukowski", "value": "C88784"},
                        {"label": "Powiat opolski", "value": "C88785"},
                        {"label": "Powiat parczewski", "value": "C88786"},
                        {"label": "Powiat pulawski", "value": "C88787"},
                        {"label": "Powiat radzynski", "value": "C88788"},
                        {"label": "Powiat rycki", "value": "C88789"},
                        {"label": "Powiat swidnicki", "value": "C88790"},
                        {"label": "Powiat tomaszowski", "value": "C88791"},
                        {"label": "Powiat wlodawski", "value": "C88792"},
                        {"label": "Powiat zamojski", "value": "C88793"},
                        {"label": "Przytoczno", "value": "C88794"},
                        {"label": "Puchaczow", "value": "C88795"},
                        {"label": "Pulawy", "value": "C88796"},
                        {"label": "Rachanie", "value": "C88797"},
                        {"label": "Radecznica", "value": "C88798"},
                        {"label": "Radzyn Podlaski", "value": "C88799"},
                        {"label": "Rejowiec", "value": "C88800"},
                        {"label": "Rejowiec Fabryczny", "value": "C88801"},
                        {"label": "Rogozno", "value": "C88802"},
                        {"label": "Rokitno", "value": "C88803"},
                        {"label": "Ruda-Huta", "value": "C88804"},
                        {"label": "Rudnik", "value": "C88805"},
                        {"label": "Rybczewice", "value": "C88806"},
                        {"label": "Ryki", "value": "C88807"},
                        {"label": "Rzeczyca", "value": "C88808"},
                        {"label": "Sawin", "value": "C88809"},
                        {"label": "Serniki", "value": "C88810"},
                        {"label": "Serokomla", "value": "C88811"},
                        {"label": "Siemien", "value": "C88812"},
                        {"label": "Siennica Rozana", "value": "C88813"},
                        {"label": "Sitno", "value": "C88814"},
                        {"label": "Skierbieszow", "value": "C88815"},
                        {"label": "Slawatycze", "value": "C88816"},
                        {"label": "Sosnowica", "value": "C88817"},
                        {"label": "Sosnowka", "value": "C88818"},
                        {"label": "Spiczyn", "value": "C88819"},
                        {"label": "Stary Zamosc", "value": "C88820"},
                        {"label": "Stezyca", "value": "C88821"},
                        {"label": "Stoczek Lukowski", "value": "C88822"},
                        {"label": "Strzyzowice", "value": "C88823"},
                        {"label": "Sulow", "value": "C88824"},
                        {"label": "Susiec", "value": "C88825"},
                        {"label": "Swidnik", "value": "C88826"},
                        {"label": "Szastarka", "value": "C88827"},
                        {"label": "Szczebrzeszyn", "value": "C88828"},
                        {"label": "Tarnawatka", "value": "C88829"},
                        {"label": "Tarnogrod", "value": "C88830"},
                        {"label": "Telatyn", "value": "C88831"},
                        {"label": "Terespol", "value": "C88832"},
                        {"label": "Tereszpol", "value": "C88833"},
                        {"label": "Tomaszow Lubelski", "value": "C88834"},
                        {"label": "Trawniki", "value": "C88835"},
                        {"label": "Trzebieszow", "value": "C88836"},
                        {"label": "Trzydnik Duzy", "value": "C88837"},
                        {"label": "Turobin", "value": "C88838"},
                        {"label": "Tyszowce", "value": "C88839"},
                        {"label": "Ulhowek", "value": "C88840"},
                        {"label": "Ulez", "value": "C88841"},
                        {"label": "Urszulin", "value": "C88842"},
                        {"label": "Urzedow", "value": "C88843"},
                        {"label": "Uscimow Stary", "value": "C88844"},
                        {"label": "Wawolnica", "value": "C88845"},
                        {"label": "Werbkowice", "value": "C88846"},
                        {"label": "Wilkolaz", "value": "C88847"},
                        {"label": "Wilkow", "value": "C88848"},
                        {"label": "Wisznice", "value": "C88849"},
                        {"label": "Wlodawa", "value": "C88850"},
                        {"label": "Wohyn", "value": "C88851"},
                        {"label": "Wojciechow", "value": "C88852"},
                        {"label": "Wojcieszkow", "value": "C88853"},
                        {"label": "Wojslawice", "value": "C88854"},
                        {"label": "Wola Sernicka", "value": "C88855"},
                        {"label": "Wola Uhruska", "value": "C88856"},
                        {"label": "Wysokie", "value": "C88857"},
                        {"label": "Zakrzew", "value": "C88858"},
                        {"label": "Zakrzowek", "value": "C88859"},
                        {"label": "Zamch", "value": "C88860"},
                        {"label": "Zamosc", "value": "C88861"},
                        {"label": "Zolkiewka", "value": "C88862"},
                        {"label": "Zwierzyniec", "value": "C88863"},
                        {"label": "Zyrzyn", "value": "C88864"}],
 "Lubombo District": [{"label": "Big Bend", "value": "C115479"},
                      {"label": "Dvokodvweni Inkhundla", "value": "C115480"},
                      {"label": "Lomashasha", "value": "C115481"},
                      {"label": "Mhlume", "value": "C115482"},
                      {"label": "Nsoko", "value": "C115483"},
                      {"label": "Siteki", "value": "C115484"},
                      {"label": "Tshaneni", "value": "C115485"},
                      {"label": "Vuvulane", "value": "C115486"}],
 "Lubusz Voivodeship": [{"label": "Babimost", "value": "C88865"},
                        {"label": "Bledzew", "value": "C88866"},
                        {"label": "Bobrowice", "value": "C88867"},
                        {"label": "Bogdaniec", "value": "C88868"},
                        {"label": "Bojadla", "value": "C88869"},
                        {"label": "Brody", "value": "C88870"},
                        {"label": "Brojce", "value": "C88871"},
                        {"label": "Bytnica", "value": "C88872"},
                        {"label": "Bytom Odrzanski", "value": "C88873"},
                        {"label": "Cybinka", "value": "C88874"},
                        {"label": "Czerwiensk", "value": "C88875"},
                        {"label": "Dabie", "value": "C88876"},
                        {"label": "Deszczno", "value": "C88877"},
                        {"label": "Dobiegniew", "value": "C88878"},
                        {"label": "Drezdenko", "value": "C88879"},
                        {"label": "Gorzow Wielkopolski", "value": "C88880"},
                        {"label": "Gorzyca", "value": "C88881"},
                        {"label": "Gozdnica", "value": "C88882"},
                        {"label": "Gubin", "value": "C88883"},
                        {"label": "Ilowa", "value": "C88884"},
                        {"label": "Jasien", "value": "C88885"},
                        {"label": "Jenin", "value": "C88886"},
                        {"label": "Kargowa", "value": "C88887"},
                        {"label": "Klenica", "value": "C88888"},
                        {"label": "Klodawa", "value": "C88889"},
                        {"label": "Kolsko", "value": "C88890"},
                        {"label": "Konotop", "value": "C88891"},
                        {"label": "Kostrzyn nad Odra", "value": "C88892"},
                        {"label": "Kozuchow", "value": "C88893"},
                        {"label": "Krosno Odrzanskie", "value": "C88894"},
                        {"label": "Krzeszyce", "value": "C88895"},
                        {"label": "Kunice Zarskie", "value": "C88896"},
                        {"label": "Lipinki Luzyckie", "value": "C88897"},
                        {"label": "Lubiszyn", "value": "C88898"},
                        {"label": "Lubniewice", "value": "C88899"},
                        {"label": "Lubrza", "value": "C88900"},
                        {"label": "Lubsko", "value": "C88901"},
                        {"label": "Lagow", "value": "C88902"},
                        {"label": "Leknica", "value": "C88903"},
                        {"label": "Malomice", "value": "C88904"},
                        {"label": "Maszewo", "value": "C88905"},
                        {"label": "Miedzylesie", "value": "C88906"},
                        {"label": "Miedzyrzecz", "value": "C88907"},
                        {"label": "Nowa Sol", "value": "C88908"},
                        {"label": "Nowe Miasteczko", "value": "C88909"},
                        {"label": "Nowogrod Bobrzanski", "value": "C88910"},
                        {"label": "Ochla", "value": "C88911"},
                        {"label": "Osno Lubuskie", "value": "C88912"},
                        {"label": "Otyn", "value": "C88913"},
                        {"label": "Powiat gorzowski", "value": "C88914"},
                        {"label": "Powiat krosnienski", "value": "C88915"},
                        {"label": "Powiat miedzyrzecki", "value": "C88916"},
                        {"label": "Powiat nowosolski", "value": "C88917"},
                        {"label": "Powiat slubicki", "value": "C88918"},
                        {"label": "Powiat strzelecko-drezdenecki",
                         "value": "C88919"},
                        {"label": "Powiat sulecinski", "value": "C88920"},
                        {"label": "Powiat swiebodzinski", "value": "C88921"},
                        {"label": "Powiat wschowski", "value": "C88922"},
                        {"label": "Powiat zaganski", "value": "C88923"},
                        {"label": "Powiat zarski", "value": "C88924"},
                        {"label": "Powiat zielonogorski", "value": "C88925"},
                        {"label": "Przewoz", "value": "C88926"},
                        {"label": "Przyborow", "value": "C88927"},
                        {"label": "Przytoczna", "value": "C88928"},
                        {"label": "Pszczew", "value": "C88929"},
                        {"label": "Rzepin", "value": "C88930"},
                        {"label": "Santok", "value": "C88931"},
                        {"label": "Siedlisko", "value": "C88932"},
                        {"label": "Sieniawa", "value": "C88933"},
                        {"label": "Sieniawa Zarska", "value": "C88934"},
                        {"label": "Skape", "value": "C88935"},
                        {"label": "Skwierzyna", "value": "C88936"},
                        {"label": "Slawa", "value": "C88937"},
                        {"label": "Slonsk", "value": "C88938"},
                        {"label": "Slubice", "value": "C88939"},
                        {"label": "Stare Kurowo", "value": "C88940"},
                        {"label": "Strzelce Krajenskie", "value": "C88941"},
                        {"label": "Sulechow", "value": "C88942"},
                        {"label": "Swidnica", "value": "C88943"},
                        {"label": "Swiebodzin", "value": "C88944"},
                        {"label": "Szczaniec", "value": "C88945"},
                        {"label": "Szlichtyngowa", "value": "C88946"},
                        {"label": "Szprotawa", "value": "C88947"},
                        {"label": "Torzym", "value": "C88948"},
                        {"label": "Trzciel", "value": "C88949"},
                        {"label": "Trzebiechow", "value": "C88950"},
                        {"label": "Trzebiel", "value": "C88951"},
                        {"label": "Tuplice", "value": "C88952"},
                        {"label": "Witnica", "value": "C88953"},
                        {"label": "Wschowa", "value": "C88954"},
                        {"label": "Wymiarki", "value": "C88955"},
                        {"label": "Zabor", "value": "C88956"},
                        {"label": "Zagan", "value": "C88957"},
                        {"label": "Zary", "value": "C88958"},
                        {"label": "Zbaszynek", "value": "C88959"},
                        {"label": "Zielona Gora", "value": "C88960"},
                        {"label": "Zwierzyn", "value": "C88961"}],
 "Luce Municipality": [{"label": "Luce", "value": "C107707"}],
 "Lucerne": [{"label": "Adligenswil", "value": "C116833"},
             {"label": "Altishofen", "value": "C116834"},
             {"label": "Ballwil", "value": "C116835"},
             {"label": "Beromunster", "value": "C116836"},
             {"label": "Buchrain", "value": "C116837"},
             {"label": "Buron", "value": "C116838"},
             {"label": "Buttisholz", "value": "C116839"},
             {"label": "Dagmersellen", "value": "C116840"},
             {"label": "Ebikon", "value": "C116841"},
             {"label": "Eich", "value": "C116842"},
             {"label": "Emmen", "value": "C116843"},
             {"label": "Entlebuch", "value": "C116844"},
             {"label": "Entlebuch District", "value": "C116845"},
             {"label": "Escholzmatt", "value": "C116846"},
             {"label": "Ettiswil", "value": "C116847"},
             {"label": "Fluhli", "value": "C116848"},
             {"label": "Geuensee", "value": "C116849"},
             {"label": "Grosswangen", "value": "C116850"},
             {"label": "Gunzwil", "value": "C116851"},
             {"label": "Hasle", "value": "C116852"},
             {"label": "Hildisrieden", "value": "C116853"},
             {"label": "Hitzkirch", "value": "C116854"},
             {"label": "Hochdorf", "value": "C116855"},
             {"label": "Hochdorf District", "value": "C116856"},
             {"label": "Hohenrain", "value": "C116857"},
             {"label": "Horw", "value": "C116858"},
             {"label": "Inwil", "value": "C116859"},
             {"label": "Knutwil", "value": "C116860"},
             {"label": "Kriens", "value": "C116861"},
             {"label": "Littau", "value": "C116862"},
             {"label": "Lucerne-Land District", "value": "C116863"},
             {"label": "Lucerne-Stadt District", "value": "C116864"},
             {"label": "Luthern", "value": "C116865"},
             {"label": "Luzern", "value": "C116866"},
             {"label": "Malters", "value": "C116867"},
             {"label": "Mauensee", "value": "C116868"},
             {"label": "Meggen", "value": "C116869"},
             {"label": "Meierskappel", "value": "C116870"},
             {"label": "Menznau", "value": "C116871"},
             {"label": "Nebikon", "value": "C116872"},
             {"label": "Neudorf", "value": "C116873"},
             {"label": "Neuenkirch", "value": "C116874"},
             {"label": "Nottwil", "value": "C116875"},
             {"label": "Oberkirch", "value": "C116876"},
             {"label": "Pfaffnau", "value": "C116877"},
             {"label": "Reiden", "value": "C116878"},
             {"label": "Romerswil", "value": "C116879"},
             {"label": "Root", "value": "C116880"},
             {"label": "Ruswil", "value": "C116881"},
             {"label": "Schenkon", "value": "C116882"},
             {"label": "Schotz", "value": "C116883"},
             {"label": "Schupfheim", "value": "C116884"},
             {"label": "Schwarzenberg", "value": "C116885"},
             {"label": "Sempach", "value": "C116886"},
             {"label": "Sursee", "value": "C116887"},
             {"label": "Sursee District", "value": "C116888"},
             {"label": "Triengen", "value": "C116889"},
             {"label": "Udligenswil", "value": "C116890"},
             {"label": "Vitznau", "value": "C116891"},
             {"label": "Wauwil", "value": "C116892"},
             {"label": "Weggis", "value": "C116893"},
             {"label": "Werthenstein", "value": "C116894"},
             {"label": "Wikon", "value": "C116895"},
             {"label": "Willisau", "value": "C116896"},
             {"label": "Willisau District", "value": "C116897"},
             {"label": "Wolhusen", "value": "C116898"},
             {"label": "Zell", "value": "C116899"}],
 "Ludza Municipality": [{"label": "Ludza", "value": "C64898"}],
 "Luhanska oblast": [{"label": "Alchevsk", "value": "C122248"},
                     {"label": "Alchevska Miskrada", "value": "C122249"},
                     {"label": "Antratsyt", "value": "C122250"},
                     {"label": "Antratsytivskyy Rayon", "value": "C122251"},
                     {"label": "Artemivsk", "value": "C122252"},
                     {"label": "Bayrachky", "value": "C122253"},
                     {"label": "Bile", "value": "C122254"},
                     {"label": "Bilohorivka", "value": "C122255"},
                     {"label": "Bilokurakyne", "value": "C122256"},
                     {"label": "Bilovodsk", "value": "C122257"},
                     {"label": "Biryukove", "value": "C122258"},
                     {"label": "Bryanka", "value": "C122259"},
                     {"label": "Buran", "value": "C122260"},
                     {"label": "Chervonopartyzansk", "value": "C122261"},
                     {"label": "Chornukhyne", "value": "C122262"},
                     {"label": "Dovzhanskyy Rayon", "value": "C122263"},
                     {"label": "Hirske", "value": "C122264"},
                     {"label": "Kadiyivka", "value": "C122265"},
                     {"label": "Kirovsk", "value": "C122266"},
                     {"label": "Kirovska Miskrada", "value": "C122267"},
                     {"label": "Klenovyy", "value": "C122268"},
                     {"label": "Krasnyy Kut", "value": "C122269"},
                     {"label": "Krasnyy Luch", "value": "C122270"},
                     {"label": "Kreminna", "value": "C122271"},
                     {"label": "Kripenskyy", "value": "C122272"},
                     {"label": "Lenina", "value": "C122273"},
                     {"label": "Lozno-Oleksandrivka", "value": "C122274"},
                     {"label": "Luhanska Miskrada", "value": "C122275"},
                     {"label": "Luhansk", "value": "C122276"},
                     {"label": "Lutuhyne", "value": "C122277"},
                     {"label": "Lutuhynskyy Rayon", "value": "C122278"},
                     {"label": "Lysychansk", "value": "C122279"},
                     {"label": "Makariv Yar", "value": "C122280"},
                     {"label": "Markivka", "value": "C122281"},
                     {"label": "Millerovo", "value": "C122282"},
                     {"label": "Milove", "value": "C122283"},
                     {"label": "Miusynsk", "value": "C122284"},
                     {"label": "Molodohvardiysk", "value": "C122285"},
                     {"label": "Novopskov", "value": "C122286"},
                     {"label": "Nyzhnya Duvanka", "value": "C122287"},
                     {"label": "Pavlivka", "value": "C122288"},
                     {"label": "Perevalsk", "value": "C122289"},
                     {"label": "Pervomaysk", "value": "C122290"},
                     {"label": "Popasna", "value": "C122291"},
                     {"label": "Pryvillya", "value": "C122292"},
                     {"label": "Rovenky", "value": "C122293"},
                     {"label": "Rozkishne", "value": "C122294"},
                     {"label": "Rubizhanska Miskrada", "value": "C122295"},
                     {"label": "Rubizhne", "value": "C122296"},
                     {"label": "Shchastya", "value": "C122297"},
                     {"label": "Simeykyne", "value": "C122298"},
                     {"label": "Slov`yanoserbsk", "value": "C122299"},
                     {"label": "Sorokyne", "value": "C122300"},
                     {"label": "Sorokynskyi Rayon", "value": "C122301"},
                     {"label": "Stanytsya Luhanska", "value": "C122302"},
                     {"label": "Starobilsk", "value": "C122303"},
                     {"label": "Svatove", "value": "C122304"},
                     {"label": "Sverdlovsk", "value": "C122305"},
                     {"label": "Sverdlovska Miskrada", "value": "C122306"},
                     {"label": "Syevyerodonetsk", "value": "C122307"},
                     {"label": "Teple", "value": "C122308"},
                     {"label": "Toshkivka", "value": "C122309"},
                     {"label": "Trokhizbenka", "value": "C122310"},
                     {"label": "Uralo-Kavkaz", "value": "C122311"},
                     {"label": "Uspenka", "value": "C122312"},
                     {"label": "Zalesnoye", "value": "C122313"},
                     {"label": "Zorynsk", "value": "C122314"},
                     {"label": "Zymohiria", "value": "C122315"}],
 "Lukovica Municipality": [{"label": "Lukovica pri Domzalah",
                            "value": "C107708"}],
 "Lunda Norte Province": [{"label": "Lucapa", "value": "C562"}],
 "Lunda Sul Province": [{"label": "Cazaji", "value": "C563"},
                        {"label": "Saurimo", "value": "C564"}],
 "Luqa": [{"label": "Luqa", "value": "C65894"},
          {"label": "Senglea", "value": "C65895"}],
 "Lusaka Province": [{"label": "Chongwe", "value": "C148115"},
                     {"label": "Kafue", "value": "C148116"},
                     {"label": "Luangwa", "value": "C148117"},
                     {"label": "Lusaka", "value": "C148118"}],
 "Luxor": [{"label": "Luxor", "value": "C24292"},
           {"label": "Markaz al Uqsur", "value": "C24293"}],
 "Lvivska oblast": [{"label": "Belz", "value": "C122316"},
                    {"label": "Bibrka", "value": "C122317"},
                    {"label": "Boryslav", "value": "C122318"},
                    {"label": "Brody", "value": "C122319"},
                    {"label": "Bryukhovychi", "value": "C122320"},
                    {"label": "Chervonohrad", "value": "C122321"},
                    {"label": "Demnya", "value": "C122322"},
                    {"label": "Dobromyl", "value": "C122323"},
                    {"label": "Drohobych", "value": "C122324"},
                    {"label": "Dublyany", "value": "C122325"},
                    {"label": "Duliby", "value": "C122326"},
                    {"label": "Dzvinogrud", "value": "C122327"},
                    {"label": "Hlynsk", "value": "C122328"},
                    {"label": "Horodok", "value": "C122329"},
                    {"label": "Ivano-Frankove", "value": "C122330"},
                    {"label": "Khodoriv", "value": "C122331"},
                    {"label": "Khyriv", "value": "C122332"},
                    {"label": "Komarno", "value": "C122333"},
                    {"label": "Krasne", "value": "C122334"},
                    {"label": "Kulykiv", "value": "C122335"},
                    {"label": "Lopatyn", "value": "C122336"},
                    {"label": "Lviv", "value": "C122337"},
                    {"label": "Medenychi", "value": "C122338"},
                    {"label": "Morshyn", "value": "C122339"},
                    {"label": "Mostyska", "value": "C122340"},
                    {"label": "Murovane", "value": "C122341"},
                    {"label": "Mykolaiv", "value": "C122342"},
                    {"label": "Navaria", "value": "C122343"},
                    {"label": "Novoyavorivsk", "value": "C122344"},
                    {"label": "Novyi Yarychiv", "value": "C122345"},
                    {"label": "Obroshyne", "value": "C122346"},
                    {"label": "Olesko", "value": "C122347"},
                    {"label": "Peremyshlyany", "value": "C122348"},
                    {"label": "Pidbuzh", "value": "C122349"},
                    {"label": "Pidhirtsi", "value": "C122350"},
                    {"label": "Pidkamin", "value": "C122351"},
                    {"label": "Pnikut", "value": "C122352"},
                    {"label": "Pomoriany", "value": "C122353"},
                    {"label": "Potelych", "value": "C122354"},
                    {"label": "Pustomyty", "value": "C122355"},
                    {"label": "Ralivka", "value": "C122356"},
                    {"label": "Rava-Ruska", "value": "C122357"},
                    {"label": "Richky", "value": "C122358"},
                    {"label": "Rodatychi", "value": "C122359"},
                    {"label": "Rozdil", "value": "C122360"},
                    {"label": "Rozvadiv", "value": "C122361"},
                    {"label": "Rudky", "value": "C122362"},
                    {"label": "Rudne", "value": "C122363"},
                    {"label": "Rykhtychi", "value": "C122364"},
                    {"label": "Sambir", "value": "C122365"},
                    {"label": "Shchyrets", "value": "C122366"},
                    {"label": "Shklo", "value": "C122367"},
                    {"label": "Silets", "value": "C122368"},
                    {"label": "Skelivka", "value": "C122369"},
                    {"label": "Skhidnytsya", "value": "C122370"},
                    {"label": "Skole", "value": "C122371"},
                    {"label": "Slavske", "value": "C122372"},
                    {"label": "Sokal", "value": "C122373"},
                    {"label": "Solonka", "value": "C122374"},
                    {"label": "Staroye Selo", "value": "C122375"},
                    {"label": "Starychi", "value": "C122376"},
                    {"label": "Staryy Dobrotvir", "value": "C122377"},
                    {"label": "Staryy Sambir", "value": "C122378"},
                    {"label": "Stebnyk", "value": "C122379"},
                    {"label": "Stryi", "value": "C122380"},
                    {"label": "Sudova Vyshnya", "value": "C122381"},
                    {"label": "Sukhovolya", "value": "C122382"},
                    {"label": "Truskavets", "value": "C122383"},
                    {"label": "Tukhlia", "value": "C122384"},
                    {"label": "Turka", "value": "C122385"},
                    {"label": "Uhniv", "value": "C122386"},
                    {"label": "Ulychne", "value": "C122387"},
                    {"label": "Verblyany", "value": "C122388"},
                    {"label": "Verchnia Rozhanka", "value": "C122389"},
                    {"label": "Verkhnie Synevydne", "value": "C122390"},
                    {"label": "Verkhnyaya Belka", "value": "C122391"},
                    {"label": "Voyutychi", "value": "C122392"},
                    {"label": "Yavoriv", "value": "C122393"},
                    {"label": "Zhovkva", "value": "C122394"},
                    {"label": "Zhydachiv", "value": "C122395"},
                    {"label": "Zolochiv", "value": "C122396"}],
 "MSila": [{"label": "Ain el Hadjel", "value": "C353"},
           {"label": "Ain el Melh", "value": "C354"},
           {"label": "MSila", "value": "C355"},
           {"label": "Sidi Aissa", "value": "C356"}],
 "Maan Governorate": [{"label": "Al Jafr", "value": "C64258"},
                      {"label": "Al Quwayrah", "value": "C64259"},
                      {"label": "Ash Shawbak", "value": "C64260"},
                      {"label": "At Tayyibah", "value": "C64261"},
                      {"label": "Maan", "value": "C64262"},
                      {"label": "Petra", "value": "C64263"},
                      {"label": "Qir Moav", "value": "C64264"}],
 "Machakos": [{"label": "Athi River", "value": "C64596"},
              {"label": "Kangundo", "value": "C64597"},
              {"label": "Konza", "value": "C64598"},
              {"label": "Machakos", "value": "C64599"}],
 "Madaba Governorate": [{"label": "Madaba", "value": "C64265"}],
 "Madang Province": [{"label": "Bogia", "value": "C80429"},
                     {"label": "Madang", "value": "C80430"},
                     {"label": "Middle Ramu", "value": "C80431"},
                     {"label": "Rai Coast", "value": "C80432"},
                     {"label": "Sumkar", "value": "C80433"},
                     {"label": "Usino Bundi", "value": "C80434"}],
 "Madeira": [{"label": "agua de Pena", "value": "C91764"},
             {"label": "Arco da Calheta", "value": "C91765"},
             {"label": "Boaventura", "value": "C91766"},
             {"label": "Calheta", "value": "C91767"},
             {"label": "Camacha", "value": "C91768"},
             {"label": "Camara de Lobos", "value": "C91769"},
             {"label": "Campanario", "value": "C91770"},
             {"label": "Canhas", "value": "C91771"},
             {"label": "Canical", "value": "C91772"},
             {"label": "Canico", "value": "C91773"},
             {"label": "Curral das Freiras", "value": "C91774"},
             {"label": "Estreito da Calheta", "value": "C91775"},
             {"label": "Faial", "value": "C91776"},
             {"label": "Faja da Ovelha", "value": "C91777"},
             {"label": "Funchal", "value": "C91778"},
             {"label": "Machico", "value": "C91779"},
             {"label": "Nossa Senhora do Monte", "value": "C91780"},
             {"label": "Ponta do Sol", "value": "C91781"},
             {"label": "Porto da Cruz", "value": "C91782"},
             {"label": "Porto Moniz", "value": "C91783"},
             {"label": "Porto Santo", "value": "C91784"},
             {"label": "Ribeira Brava", "value": "C91785"},
             {"label": "Santa Cruz", "value": "C91786"},
             {"label": "Santana", "value": "C91787"},
             {"label": "Sao Jorge", "value": "C91788"},
             {"label": "Sao Martinho", "value": "C91789"},
             {"label": "Sao Roque", "value": "C91790"},
             {"label": "Sao Vicente", "value": "C91791"}],
 "Madhya Pradesh": [{"label": "Agar", "value": "C46405"},
                    {"label": "Ajaigarh", "value": "C46406"},
                    {"label": "Akodia", "value": "C46407"},
                    {"label": "Alampur", "value": "C46408"},
                    {"label": "Alirajpur", "value": "C46409"},
                    {"label": "Alot", "value": "C46410"},
                    {"label": "Amanganj", "value": "C46411"},
                    {"label": "Amarkantak", "value": "C46412"},
                    {"label": "Amarpatan", "value": "C46413"},
                    {"label": "Amarwara", "value": "C46414"},
                    {"label": "Ambah", "value": "C46415"},
                    {"label": "Amla", "value": "C46416"},
                    {"label": "Anjad", "value": "C46417"},
                    {"label": "Antri", "value": "C46418"},
                    {"label": "Anuppur", "value": "C46419"},
                    {"label": "Aron", "value": "C46420"},
                    {"label": "Ashoknagar", "value": "C46421"},
                    {"label": "Ashta", "value": "C46422"},
                    {"label": "Babai", "value": "C46423"},
                    {"label": "Badarwas", "value": "C46424"},
                    {"label": "Badnawar", "value": "C46425"},
                    {"label": "Bag", "value": "C46426"},
                    {"label": "Bagli", "value": "C46427"},
                    {"label": "Baihar", "value": "C46428"},
                    {"label": "Baikunthpur", "value": "C46429"},
                    {"label": "Bakshwaho", "value": "C46430"},
                    {"label": "Balaghat", "value": "C46431"},
                    {"label": "Baldeogarh", "value": "C46432"},
                    {"label": "Bamna", "value": "C46433"},
                    {"label": "Bamor Kalan", "value": "C46434"},
                    {"label": "Bamora", "value": "C46435"},
                    {"label": "Banda", "value": "C46436"},
                    {"label": "Barela", "value": "C46437"},
                    {"label": "Barghat", "value": "C46438"},
                    {"label": "Bargi", "value": "C46439"},
                    {"label": "Barhi", "value": "C46440"},
                    {"label": "Barwani", "value": "C46441"},
                    {"label": "Basoda", "value": "C46442"},
                    {"label": "Begamganj", "value": "C46443"},
                    {"label": "Beohari", "value": "C46444"},
                    {"label": "Berasia", "value": "C46445"},
                    {"label": "Betma", "value": "C46446"},
                    {"label": "Betul", "value": "C46447"},
                    {"label": "Betul Bazar", "value": "C46448"},
                    {"label": "Bhabhra", "value": "C46449"},
                    {"label": "Bhainsdehi", "value": "C46450"},
                    {"label": "Bhander", "value": "C46451"},
                    {"label": "Bhanpura", "value": "C46452"},
                    {"label": "Bhawaniganj", "value": "C46453"},
                    {"label": "Bhikangaon", "value": "C46454"},
                    {"label": "Bhind", "value": "C46455"},
                    {"label": "Bhitarwar", "value": "C46456"},
                    {"label": "Bhopal", "value": "C46457"},
                    {"label": "Biaora", "value": "C46458"},
                    {"label": "Bijawar", "value": "C46459"},
                    {"label": "Bijrauni", "value": "C46460"},
                    {"label": "Bodri", "value": "C46461"},
                    {"label": "Burhanpur", "value": "C46462"},
                    {"label": "Burhar", "value": "C46463"},
                    {"label": "Chanderi", "value": "C46464"},
                    {"label": "Chandia", "value": "C46465"},
                    {"label": "Chandla", "value": "C46466"},
                    {"label": "Chhatarpur", "value": "C46467"},
                    {"label": "Chhindwara", "value": "C46468"},
                    {"label": "Chichli", "value": "C46469"},
                    {"label": "Chorhat", "value": "C46470"},
                    {"label": "Daboh", "value": "C46471"},
                    {"label": "Dabra", "value": "C46472"},
                    {"label": "Damoh", "value": "C46473"},
                    {"label": "Datia", "value": "C46474"},
                    {"label": "Deori Khas", "value": "C46475"},
                    {"label": "Depalpur", "value": "C46476"},
                    {"label": "Dewas", "value": "C46477"},
                    {"label": "Dhamnod", "value": "C46478"},
                    {"label": "Dhana", "value": "C46479"},
                    {"label": "Dhar", "value": "C46480"},
                    {"label": "Dharampuri", "value": "C46481"},
                    {"label": "Dindori", "value": "C46482"},
                    {"label": "Etawa", "value": "C46483"},
                    {"label": "Gadarwara", "value": "C46484"},
                    {"label": "Garha Brahman", "value": "C46485"},
                    {"label": "Garhakota", "value": "C46486"},
                    {"label": "Gautampura", "value": "C46487"},
                    {"label": "Ghansor", "value": "C46488"},
                    {"label": "Gogapur", "value": "C46489"},
                    {"label": "Gohadi", "value": "C46490"},
                    {"label": "Govindgarh", "value": "C46491"},
                    {"label": "Guna", "value": "C46492"},
                    {"label": "Gurh", "value": "C46493"},
                    {"label": "Gwalior", "value": "C46494"},
                    {"label": "Harda", "value": "C46495"},
                    {"label": "Harda Khas", "value": "C46496"},
                    {"label": "Harpalpur", "value": "C46497"},
                    {"label": "Harrai", "value": "C46498"},
                    {"label": "Harsud", "value": "C46499"},
                    {"label": "Hatod", "value": "C46500"},
                    {"label": "Hatta", "value": "C46501"},
                    {"label": "Hindoria", "value": "C46502"},
                    {"label": "Hoshangabad", "value": "C46503"},
                    {"label": "Iawar", "value": "C46504"},
                    {"label": "Ichhawar", "value": "C46505"},
                    {"label": "Iklehra", "value": "C46506"},
                    {"label": "Indore", "value": "C46507"},
                    {"label": "Isagarh", "value": "C46508"},
                    {"label": "Itarsi", "value": "C46509"},
                    {"label": "Jabalpur", "value": "C46510"},
                    {"label": "Jaisinghnagar", "value": "C46511"},
                    {"label": "Jaithari", "value": "C46512"},
                    {"label": "Jamai", "value": "C46513"},
                    {"label": "Jaora", "value": "C46514"},
                    {"label": "Jatara", "value": "C46515"},
                    {"label": "Jawad", "value": "C46516"},
                    {"label": "Jhabua", "value": "C46517"},
                    {"label": "Jiran", "value": "C46518"},
                    {"label": "Jobat", "value": "C46519"},
                    {"label": "Kailaras", "value": "C46520"},
                    {"label": "Kaimori", "value": "C46521"},
                    {"label": "Kannod", "value": "C46522"},
                    {"label": "Kareli", "value": "C46523"},
                    {"label": "Karera", "value": "C46524"},
                    {"label": "Karrapur", "value": "C46525"},
                    {"label": "Kasrawad", "value": "C46526"},
                    {"label": "Katangi", "value": "C46527"},
                    {"label": "Katni", "value": "C46528"},
                    {"label": "Khachrod", "value": "C46529"},
                    {"label": "Khailar", "value": "C46530"},
                    {"label": "Khajuraho Group of Monuments",
                     "value": "C46531"},
                    {"label": "Khamaria", "value": "C46532"},
                    {"label": "Khandwa", "value": "C46533"},
                    {"label": "Khandwa district", "value": "C46534"},
                    {"label": "Khargapur", "value": "C46535"},
                    {"label": "Khargone", "value": "C46536"},
                    {"label": "Khategaon", "value": "C46537"},
                    {"label": "Khilchipur", "value": "C46538"},
                    {"label": "Khirkiyan", "value": "C46539"},
                    {"label": "Khujner", "value": "C46540"},
                    {"label": "Khurai", "value": "C46541"},
                    {"label": "Kolaras", "value": "C46542"},
                    {"label": "Korwai", "value": "C46543"},
                    {"label": "Kotar", "value": "C46544"},
                    {"label": "Kothi", "value": "C46545"},
                    {"label": "Kotma", "value": "C46546"},
                    {"label": "Kotwa", "value": "C46547"},
                    {"label": "Kukshi", "value": "C46548"},
                    {"label": "Kumbhraj", "value": "C46549"},
                    {"label": "Lahar", "value": "C46550"},
                    {"label": "Lakhnadon", "value": "C46551"},
                    {"label": "Leteri", "value": "C46552"},
                    {"label": "Lodhikheda", "value": "C46553"},
                    {"label": "Machalpur", "value": "C46554"},
                    {"label": "Madhogarh", "value": "C46555"},
                    {"label": "Maheshwar", "value": "C46556"},
                    {"label": "Mahgawan", "value": "C46557"},
                    {"label": "Maihar", "value": "C46558"},
                    {"label": "Majholi", "value": "C46559"},
                    {"label": "Maksi", "value": "C46560"},
                    {"label": "Malhargarh", "value": "C46561"},
                    {"label": "Manasa", "value": "C46562"},
                    {"label": "Manawar", "value": "C46563"},
                    {"label": "Mandideep", "value": "C46564"},
                    {"label": "Mandla", "value": "C46565"},
                    {"label": "Mandleshwar", "value": "C46566"},
                    {"label": "Mandsaur", "value": "C46567"},
                    {"label": "Mangawan", "value": "C46568"},
                    {"label": "Manpur", "value": "C46569"},
                    {"label": "Mau", "value": "C46570"},
                    {"label": "Mauganj", "value": "C46571"},
                    {"label": "Mihona", "value": "C46572"},
                    {"label": "Mohgaon", "value": "C46573"},
                    {"label": "Morar", "value": "C46574"},
                    {"label": "Morena", "value": "C46575"},
                    {"label": "Multai", "value": "C46576"},
                    {"label": "Mundi", "value": "C46577"},
                    {"label": "Mungaoli", "value": "C46578"},
                    {"label": "Murwara", "value": "C46579"},
                    {"label": "Nagda", "value": "C46580"},
                    {"label": "Nagod", "value": "C46581"},
                    {"label": "Naigarhi", "value": "C46582"},
                    {"label": "Nainpur", "value": "C46583"},
                    {"label": "Namli", "value": "C46584"},
                    {"label": "Naraini", "value": "C46585"},
                    {"label": "Narayangarh", "value": "C46586"},
                    {"label": "Narsimhapur", "value": "C46587"},
                    {"label": "Narsinghgarh", "value": "C46588"},
                    {"label": "Narwar", "value": "C46589"},
                    {"label": "Nasrullahganj", "value": "C46590"},
                    {"label": "Neemuch", "value": "C46591"},
                    {"label": "Nepanagar", "value": "C46592"},
                    {"label": "Orchha", "value": "C46593"},
                    {"label": "Pachmarhi", "value": "C46594"},
                    {"label": "Palera", "value": "C46595"},
                    {"label": "Pali", "value": "C46596"},
                    {"label": "Panagar", "value": "C46597"},
                    {"label": "Panara", "value": "C46598"},
                    {"label": "Pandhana", "value": "C46599"},
                    {"label": "Pandhurna", "value": "C46600"},
                    {"label": "Panna", "value": "C46601"},
                    {"label": "Pansemal", "value": "C46602"},
                    {"label": "Parasia", "value": "C46603"},
                    {"label": "Patan", "value": "C46604"},
                    {"label": "Patharia", "value": "C46605"},
                    {"label": "Pawai", "value": "C46606"},
                    {"label": "Petlawad", "value": "C46607"},
                    {"label": "Piploda", "value": "C46608"},
                    {"label": "Pithampur", "value": "C46609"},
                    {"label": "Porsa", "value": "C46610"},
                    {"label": "Punasa", "value": "C46611"},
                    {"label": "Raghogarh", "value": "C46612"},
                    {"label": "Rahatgarh", "value": "C46613"},
                    {"label": "Raisen", "value": "C46614"},
                    {"label": "Rajgarh", "value": "C46615"},
                    {"label": "Rajnagar", "value": "C46616"},
                    {"label": "Rajpur", "value": "C46617"},
                    {"label": "Rampura", "value": "C46618"},
                    {"label": "Ranapur", "value": "C46619"},
                    {"label": "Ratangarh", "value": "C46620"},
                    {"label": "Ratlam", "value": "C46621"},
                    {"label": "Rehli", "value": "C46622"},
                    {"label": "Rehti", "value": "C46623"},
                    {"label": "Rewa", "value": "C46624"},
                    {"label": "Sabalgarh", "value": "C46625"},
                    {"label": "Sagar", "value": "C46626"},
                    {"label": "Sailana", "value": "C46627"},
                    {"label": "Sanawad", "value": "C46628"},
                    {"label": "Sanchi", "value": "C46629"},
                    {"label": "Sanwer", "value": "C46630"},
                    {"label": "Sarangpur", "value": "C46631"},
                    {"label": "Satna", "value": "C46632"},
                    {"label": "Satwas", "value": "C46633"},
                    {"label": "Saugor", "value": "C46634"},
                    {"label": "Sausar", "value": "C46635"},
                    {"label": "Sehore", "value": "C46636"},
                    {"label": "Sendhwa", "value": "C46637"},
                    {"label": "Seondha", "value": "C46638"},
                    {"label": "Seoni", "value": "C46639"},
                    {"label": "Seoni Malwa", "value": "C46640"},
                    {"label": "Shahdol", "value": "C46641"},
                    {"label": "Shahgarh", "value": "C46642"},
                    {"label": "Shahpur", "value": "C46643"},
                    {"label": "Shahpura", "value": "C46644"},
                    {"label": "Shajapur", "value": "C46645"},
                    {"label": "Shamgarh", "value": "C46646"},
                    {"label": "Sheopur", "value": "C46647"},
                    {"label": "Shivpuri", "value": "C46648"},
                    {"label": "Shujalpur", "value": "C46649"},
                    {"label": "Sidhi", "value": "C46650"},
                    {"label": "Sihora", "value": "C46651"},
                    {"label": "Simaria", "value": "C46652"},
                    {"label": "Singoli", "value": "C46653"},
                    {"label": "Singrauli", "value": "C46654"},
                    {"label": "Sirmaur", "value": "C46655"},
                    {"label": "Sironj", "value": "C46656"},
                    {"label": "Sitamau", "value": "C46657"},
                    {"label": "Sohagi", "value": "C46658"},
                    {"label": "Sohagpur", "value": "C46659"},
                    {"label": "Sultanpur", "value": "C46660"},
                    {"label": "Susner", "value": "C46661"},
                    {"label": "Tal", "value": "C46662"},
                    {"label": "Talen", "value": "C46663"},
                    {"label": "Tarana", "value": "C46664"},
                    {"label": "Tekanpur", "value": "C46665"},
                    {"label": "Tendukheda", "value": "C46666"},
                    {"label": "Teonthar", "value": "C46667"},
                    {"label": "Thandla", "value": "C46668"},
                    {"label": "Tikamgarh", "value": "C46669"},
                    {"label": "Tirodi", "value": "C46670"},
                    {"label": "Udaipura", "value": "C46671"},
                    {"label": "Ujjain", "value": "C46672"},
                    {"label": "Ukwa", "value": "C46673"},
                    {"label": "Umaria", "value": "C46674"},
                    {"label": "Umaria District", "value": "C46675"},
                    {"label": "Umri", "value": "C46676"},
                    {"label": "Unhel", "value": "C46677"},
                    {"label": "Vidisha", "value": "C46678"},
                    {"label": "Waraseoni", "value": "C46679"}],
 "Madinat ash Shamal": [{"label": "Ar Ruways", "value": "C92080"},
                        {"label": "Fuwayrit", "value": "C92081"},
                        {"label": "Madinat ash Shamal", "value": "C92082"}],
 "Madona Municipality": [{"label": "Madona", "value": "C64899"}],
 "Madre de Dios": [{"label": "Iberia", "value": "C80980"},
                   {"label": "Provincia de Manu", "value": "C80981"},
                   {"label": "Provincia de Tahuamanu", "value": "C80982"},
                   {"label": "Provincia de Tambopata", "value": "C80983"},
                   {"label": "Puerto Maldonado", "value": "C80984"},
                   {"label": "Salvacion", "value": "C80985"},
                   {"label": "Tambopata", "value": "C80986"}],
 "Madrid": [{"label": "Ajalvir", "value": "C114343"},
            {"label": "Alameda del Valle", "value": "C114344"},
            {"label": "Alcala de Henares", "value": "C114345"},
            {"label": "Alcobendas", "value": "C114346"},
            {"label": "Alcorcon", "value": "C114347"},
            {"label": "Aldea del Fresno", "value": "C114348"},
            {"label": "Algete", "value": "C114349"},
            {"label": "Alpedrete", "value": "C114350"},
            {"label": "Ambite", "value": "C114351"},
            {"label": "Anchuelo", "value": "C114352"},
            {"label": "Aranjuez", "value": "C114353"},
            {"label": "Arganda", "value": "C114354"},
            {"label": "Arganzuela", "value": "C114355"},
            {"label": "Arroyomolinos", "value": "C114356"},
            {"label": "Barajas de Madrid", "value": "C114357"},
            {"label": "Batres", "value": "C114358"},
            {"label": "Becerril de la Sierra", "value": "C114359"},
            {"label": "Belmonte de Tajo", "value": "C114360"},
            {"label": "Berzosa del Lozoya", "value": "C114361"},
            {"label": "Boadilla del Monte", "value": "C114362"},
            {"label": "Boalo", "value": "C114363"},
            {"label": "Braojos", "value": "C114364"},
            {"label": "Brea de Tajo", "value": "C114365"},
            {"label": "Brunete", "value": "C114366"},
            {"label": "Buitrago del Lozoya", "value": "C114367"},
            {"label": "Bustarviejo", "value": "C114368"},
            {"label": "Cabanillas de la Sierra", "value": "C114369"},
            {"label": "Cadalso de los Vidrios", "value": "C114370"},
            {"label": "Camarma de Esteruelas", "value": "C114371"},
            {"label": "Campo Real", "value": "C114372"},
            {"label": "Canencia", "value": "C114373"},
            {"label": "Carabana", "value": "C114374"},
            {"label": "Carabanchel", "value": "C114375"},
            {"label": "Casarrubuelos", "value": "C114376"},
            {"label": "Cenicientos", "value": "C114377"},
            {"label": "Cercedilla", "value": "C114378"},
            {"label": "Cervera de Buitrago", "value": "C114379"},
            {"label": "Chamartin", "value": "C114380"},
            {"label": "Chamberi", "value": "C114381"},
            {"label": "Chapineria", "value": "C114382"},
            {"label": "Chinchon", "value": "C114383"},
            {"label": "Ciempozuelos", "value": "C114384"},
            {"label": "City Center", "value": "C114385"},
            {"label": "Ciudad Lineal", "value": "C114386"},
            {"label": "Cobena", "value": "C114387"},
            {"label": "Collado Mediano", "value": "C114388"},
            {"label": "Collado-Villalba", "value": "C114389"},
            {"label": "Colmenar de Oreja", "value": "C114390"},
            {"label": "Colmenar del Arroyo", "value": "C114391"},
            {"label": "Colmenar Viejo", "value": "C114392"},
            {"label": "Colmenarejo", "value": "C114393"},
            {"label": "Corpa", "value": "C114394"},
            {"label": "Coslada", "value": "C114395"},
            {"label": "Cubas", "value": "C114396"},
            {"label": "Daganzo de Arriba", "value": "C114397"},
            {"label": "El alamo", "value": "C114398"},
            {"label": "El Escorial", "value": "C114399"},
            {"label": "El Molar", "value": "C114400"},
            {"label": "El Pardo", "value": "C114401"},
            {"label": "El Vellon", "value": "C114402"},
            {"label": "Estremera", "value": "C114403"},
            {"label": "Fresnedillas", "value": "C114404"},
            {"label": "Fresno de Torote", "value": "C114405"},
            {"label": "Fuencarral-El Pardo", "value": "C114406"},
            {"label": "Fuenlabrada", "value": "C114407"},
            {"label": "Fuente el Saz", "value": "C114408"},
            {"label": "Fuentiduena de Tajo", "value": "C114409"},
            {"label": "Galapagar", "value": "C114410"},
            {"label": "Garganta de los Montes", "value": "C114411"},
            {"label": "Gascones", "value": "C114412"},
            {"label": "Getafe", "value": "C114413"},
            {"label": "Grinon", "value": "C114414"},
            {"label": "Guadalix de la Sierra", "value": "C114415"},
            {"label": "Guadarrama", "value": "C114416"},
            {"label": "Horcajo de la Sierra", "value": "C114417"},
            {"label": "Horcajuelo de la Sierra", "value": "C114418"},
            {"label": "Hortaleza", "value": "C114419"},
            {"label": "Hoyo de Manzanares", "value": "C114420"},
            {"label": "Humanes de Madrid", "value": "C114421"},
            {"label": "La Cabrera", "value": "C114422"},
            {"label": "Las Matas", "value": "C114423"},
            {"label": "Las Rozas de Madrid", "value": "C114424"},
            {"label": "Las Tablas", "value": "C114425"},
            {"label": "Latina", "value": "C114426"},
            {"label": "Leganes", "value": "C114427"},
            {"label": "Loeches", "value": "C114428"},
            {"label": "Los Molinos", "value": "C114429"},
            {"label": "Los Santos de la Humosa", "value": "C114430"},
            {"label": "Lozoya", "value": "C114431"},
            {"label": "Madrid", "value": "C114432"},
            {"label": "Majadahonda", "value": "C114433"},
            {"label": "Manzanares el Real", "value": "C114434"},
            {"label": "Meco", "value": "C114435"},
            {"label": "Mejorada del Campo", "value": "C114436"},
            {"label": "Miraflores de la Sierra", "value": "C114437"},
            {"label": "Moncloa-Aravaca", "value": "C114438"},
            {"label": "Montejo de la Sierra", "value": "C114439"},
            {"label": "Moraleja de Enmedio", "value": "C114440"},
            {"label": "Moralzarzal", "value": "C114441"},
            {"label": "Morata de Tajuna", "value": "C114442"},
            {"label": "Moratalaz", "value": "C114443"},
            {"label": "Mostoles", "value": "C114444"},
            {"label": "Navacerrada", "value": "C114445"},
            {"label": "Navalafuente", "value": "C114446"},
            {"label": "Navalagamella", "value": "C114447"},
            {"label": "Navalcarnero", "value": "C114448"},
            {"label": "Navalquejigo", "value": "C114449"},
            {"label": "Navas del Rey", "value": "C114450"},
            {"label": "Nuevo Baztan", "value": "C114451"},
            {"label": "Paracuellos de Jarama", "value": "C114452"},
            {"label": "Parla", "value": "C114453"},
            {"label": "Patones", "value": "C114454"},
            {"label": "Pedrezuela", "value": "C114455"},
            {"label": "Pelayos de la Presa", "value": "C114456"},
            {"label": "Perales de Tajuna", "value": "C114457"},
            {"label": "Pezuela de las Torres", "value": "C114458"},
            {"label": "Pinar de Chamartin", "value": "C114459"},
            {"label": "Pinilla del Valle", "value": "C114460"},
            {"label": "Pinto", "value": "C114461"},
            {"label": "Pozuelo de Alarcon", "value": "C114462"},
            {"label": "Pozuelo del Rey", "value": "C114463"},
            {"label": "Pradena del Rincon", "value": "C114464"},
            {"label": "Provincia de Madrid", "value": "C114465"},
            {"label": "Puente de Vallecas", "value": "C114466"},
            {"label": "Quijorna", "value": "C114467"},
            {"label": "Rascafria", "value": "C114468"},
            {"label": "Reduena", "value": "C114469"},
            {"label": "Retiro", "value": "C114470"},
            {"label": "Ribatejada", "value": "C114471"},
            {"label": "Rivas-Vaciamadrid", "value": "C114472"},
            {"label": "Robledillo de la Jara", "value": "C114473"},
            {"label": "Robledo de Chavela", "value": "C114474"},
            {"label": "Rozas de Puerto Real", "value": "C114475"},
            {"label": "Salamanca", "value": "C114476"},
            {"label": "San Agustin del Guadalix", "value": "C114477"},
            {"label": "San Blas-Canillejas", "value": "C114478"},
            {"label": "San Fernando de Henares", "value": "C114479"},
            {"label": "San Lorenzo de El Escorial", "value": "C114480"},
            {"label": "San Martin de la Vega", "value": "C114481"},
            {"label": "San Martin de Valdeiglesias", "value": "C114482"},
            {"label": "San Sebastian de los Reyes", "value": "C114483"},
            {"label": "Santa Maria de la Alameda", "value": "C114484"},
            {"label": "Santorcaz", "value": "C114485"},
            {"label": "Serranillos del Valle", "value": "C114486"},
            {"label": "Sevilla La Nueva", "value": "C114487"},
            {"label": "Talamanca de Jarama", "value": "C114488"},
            {"label": "Tetuan de las Victorias", "value": "C114489"},
            {"label": "Tielmes", "value": "C114490"},
            {"label": "Titulcia", "value": "C114491"},
            {"label": "Torrejon de Ardoz", "value": "C114492"},
            {"label": "Torrejon de la Calzada", "value": "C114493"},
            {"label": "Torrejon de Velasco", "value": "C114494"},
            {"label": "Torrelaguna", "value": "C114495"},
            {"label": "Torrelodones", "value": "C114496"},
            {"label": "Torremocha de Jarama", "value": "C114497"},
            {"label": "Torres de la Alameda", "value": "C114498"},
            {"label": "Tres Cantos", "value": "C114499"},
            {"label": "Usera", "value": "C114500"},
            {"label": "Valdaracete", "value": "C114501"},
            {"label": "Valdeavero", "value": "C114502"},
            {"label": "Valdemanco", "value": "C114503"},
            {"label": "Valdemaqueda", "value": "C114504"},
            {"label": "Valdemorillo", "value": "C114505"},
            {"label": "Valdemoro", "value": "C114506"},
            {"label": "Valdeolmos", "value": "C114507"},
            {"label": "Valdepielagos", "value": "C114508"},
            {"label": "Valdetorres de Jarama", "value": "C114509"},
            {"label": "Valdilecha", "value": "C114510"},
            {"label": "Valverde de Alcala", "value": "C114511"},
            {"label": "Velilla de San Antonio", "value": "C114512"},
            {"label": "Venturada", "value": "C114513"},
            {"label": "Vicalvaro", "value": "C114514"},
            {"label": "Villa de Vallecas", "value": "C114515"},
            {"label": "Villa del Prado", "value": "C114516"},
            {"label": "Villaconejos", "value": "C114517"},
            {"label": "Villalbilla", "value": "C114518"},
            {"label": "Villamanrique de Tajo", "value": "C114519"},
            {"label": "Villamanta", "value": "C114520"},
            {"label": "Villamantilla", "value": "C114521"},
            {"label": "Villanueva de la Canada", "value": "C114522"},
            {"label": "Villanueva de Perales", "value": "C114523"},
            {"label": "Villanueva del Pardillo", "value": "C114524"},
            {"label": "Villar del Olmo", "value": "C114525"},
            {"label": "Villarejo de Salvanes", "value": "C114526"},
            {"label": "Villaverde", "value": "C114527"},
            {"label": "Villaviciosa de Odon", "value": "C114528"},
            {"label": "Villavieja del Lozoya", "value": "C114529"},
            {"label": "Zarzalejo", "value": "C114530"}],
 "Madriz": [{"label": "Las Sabanas", "value": "C77950"},
            {"label": "Palacaguina", "value": "C77951"},
            {"label": "San Jose de Cusmapa", "value": "C77952"},
            {"label": "San Juan de Rio Coco", "value": "C77953"},
            {"label": "San Lucas", "value": "C77954"},
            {"label": "Somoto", "value": "C77955"},
            {"label": "Telpaneca", "value": "C77956"},
            {"label": "Totogalpa", "value": "C77957"},
            {"label": "Yalaguina", "value": "C77958"}],
 "Mae Hong Son": [{"label": "Amphoe Khun Yuam", "value": "C118769"},
                  {"label": "Amphoe Mae La Noi", "value": "C118770"},
                  {"label": "Amphoe Mae Sariang", "value": "C118771"},
                  {"label": "Amphoe Mueang Mae Hong Son", "value": "C118772"},
                  {"label": "Amphoe Pai", "value": "C118773"},
                  {"label": "Amphoe Pang Mapha", "value": "C118774"},
                  {"label": "Amphoe Sop Moei", "value": "C118775"},
                  {"label": "Mae Hong Son", "value": "C118776"}],
 "Maekel Region": [{"label": "Asmara", "value": "C24495"}],
 "Mafeteng District": [{"label": "Mafeteng", "value": "C64994"}],
 "Mafraq Governorate": [{"label": "Al Hamra", "value": "C64266"},
                        {"label": "Mafraq", "value": "C64267"},
                        {"label": "Rehab", "value": "C64268"},
                        {"label": "Rukban", "value": "C64269"},
                        {"label": "Sabha", "value": "C64270"},
                        {"label": "Umm al Qittayn", "value": "C64271"}],
 "Magadan Oblast": [{"label": "Arman", "value": "C102140"},
                    {"label": "Dukat", "value": "C102141"},
                    {"label": "Evensk", "value": "C102142"},
                    {"label": "Gorod Magadan", "value": "C102143"},
                    {"label": "Kholodnyy", "value": "C102144"},
                    {"label": "Magadan", "value": "C102145"},
                    {"label": "Myaundzha", "value": "C102146"},
                    {"label": "Olskiy Rayon", "value": "C102147"},
                    {"label": "Ola", "value": "C102148"},
                    {"label": "Omsukchan", "value": "C102149"},
                    {"label": "Omsukchanskiy Rayon", "value": "C102150"},
                    {"label": "Orotukan", "value": "C102151"},
                    {"label": "Palatka", "value": "C102152"},
                    {"label": "Seymchan", "value": "C102153"},
                    {"label": "Sinegorye", "value": "C102154"},
                    {"label": "Sokol", "value": "C102155"},
                    {"label": "Srednekanskiy Rayon", "value": "C102156"},
                    {"label": "Susuman", "value": "C102157"},
                    {"label": "Talaya", "value": "C102158"},
                    {"label": "Uptar", "value": "C102159"},
                    {"label": "Ust-Omchug", "value": "C102160"},
                    {"label": "Yagodnoye", "value": "C102161"}],
 "Magallanes y de la Antartica Chilena": [{"label": "Antartica",
                                           "value": "C18079"},
                                          {"label": "Cabo de Hornos",
                                           "value": "C18080"},
                                          {"label": "Laguna Blanca",
                                           "value": "C18081"},
                                          {"label": "Natales",
                                           "value": "C18082"},
                                          {"label": "Porvenir",
                                           "value": "C18083"},
                                          {"label": "Primavera",
                                           "value": "C18084"},
                                          {"label": "Punta Arenas",
                                           "value": "C18085"},
                                          {"label": "Rio Verde",
                                           "value": "C18086"},
                                          {"label": "San Gregorio",
                                           "value": "C18087"},
                                          {"label": "Timaukel",
                                           "value": "C18088"},
                                          {"label": "Torres del Paine",
                                           "value": "C18089"}],
 "Magdalena": [{"label": "Algarrobo", "value": "C20244"},
               {"label": "Aracataca", "value": "C20245"},
               {"label": "Ariguani", "value": "C20246"},
               {"label": "Cerro de San Antonio", "value": "C20247"},
               {"label": "Chivolo", "value": "C20248"},
               {"label": "Cienaga", "value": "C20249"},
               {"label": "Concordia", "value": "C20250"},
               {"label": "El Banco", "value": "C20251"},
               {"label": "El Pinon", "value": "C20252"},
               {"label": "El Reten", "value": "C20253"},
               {"label": "Fundacion", "value": "C20254"},
               {"label": "Guamal", "value": "C20255"},
               {"label": "Nueva Granada", "value": "C20256"},
               {"label": "Pedraza", "value": "C20257"},
               {"label": "Pijino del Carmen", "value": "C20258"},
               {"label": "Pivijay", "value": "C20259"},
               {"label": "Plato", "value": "C20260"},
               {"label": "Puebloviejo", "value": "C20261"},
               {"label": "Remolino", "value": "C20262"},
               {"label": "Sabanas de San Angel", "value": "C20263"},
               {"label": "Salamina", "value": "C20264"},
               {"label": "San Sebastian de Buenavista", "value": "C20265"},
               {"label": "San Zenon", "value": "C20266"},
               {"label": "Santa Ana", "value": "C20267"},
               {"label": "Santa Barbara de Pinto", "value": "C20268"},
               {"label": "Santa Marta", "value": "C20269"},
               {"label": "Sitionuevo", "value": "C20270"},
               {"label": "Tenerife", "value": "C20271"},
               {"label": "Zapayan", "value": "C20272"},
               {"label": "Zona Bananera", "value": "C20273"}],
 "Magway Region": [{"label": "Chauk", "value": "C75923"},
                   {"label": "Magway", "value": "C75924"},
                   {"label": "Minbu", "value": "C75925"},
                   {"label": "Myaydo", "value": "C75926"},
                   {"label": "Pakokku", "value": "C75927"},
                   {"label": "Taungdwingyi", "value": "C75928"},
                   {"label": "Thayetmyo", "value": "C75929"},
                   {"label": "Yenangyaung", "value": "C75930"}],
 "Maha Sarakham": [{"label": "Amphoe Borabue", "value": "C118777"},
                   {"label": "Amphoe Chiang Yuen", "value": "C118778"},
                   {"label": "Amphoe Chuen Chom", "value": "C118779"},
                   {"label": "Amphoe Kae Dam", "value": "C118780"},
                   {"label": "Amphoe Kantharawichai", "value": "C118781"},
                   {"label": "Amphoe Kosum Phisai", "value": "C118782"},
                   {"label": "Amphoe Kut Rang", "value": "C118783"},
                   {"label": "Amphoe Mueang Maha Sarakham", "value": "C118784"},
                   {"label": "Amphoe Na Chueak", "value": "C118785"},
                   {"label": "Amphoe Na Dun", "value": "C118786"},
                   {"label": "Amphoe Phayakkhaphum Phisai", "value": "C118787"},
                   {"label": "Amphoe Wapi Pathum", "value": "C118788"},
                   {"label": "Amphoe Yang Si Surat", "value": "C118789"},
                   {"label": "Kosum Phisai", "value": "C118790"},
                   {"label": "Maha Sarakham", "value": "C118791"},
                   {"label": "Phayakkhaphum Phisai", "value": "C118792"},
                   {"label": "Wapi Pathum", "value": "C118793"}],
 "Mahaica-Berbice": [{"label": "Mahaicony Village", "value": "C43073"},
                     {"label": "Rosignol", "value": "C43074"}],
 "Maharashtra": [{"label": "Achalpur", "value": "C46680"},
                 {"label": "Adawad", "value": "C46681"},
                 {"label": "Agar Panchaitan", "value": "C46682"},
                 {"label": "Aheri", "value": "C46683"},
                 {"label": "Ahiri", "value": "C46684"},
                 {"label": "Ahmadnagar", "value": "C46685"},
                 {"label": "Ahmadpur", "value": "C46686"},
                 {"label": "Ahmednagar", "value": "C46687"},
                 {"label": "Airoli", "value": "C46688"},
                 {"label": "Ajra", "value": "C46689"},
                 {"label": "Akalkot", "value": "C46690"},
                 {"label": "Akkalkot", "value": "C46691"},
                 {"label": "Akluj", "value": "C46692"},
                 {"label": "Akola", "value": "C46693"},
                 {"label": "Akolner", "value": "C46694"},
                 {"label": "Akot", "value": "C46695"},
                 {"label": "Akrani", "value": "C46696"},
                 {"label": "Alandi", "value": "C46697"},
                 {"label": "Ale", "value": "C46698"},
                 {"label": "Alibag", "value": "C46699"},
                 {"label": "Alkuti", "value": "C46700"},
                 {"label": "Allapalli", "value": "C46701"},
                 {"label": "Amalner", "value": "C46702"},
                 {"label": "Amarnath", "value": "C46703"},
                 {"label": "Ambad", "value": "C46704"},
                 {"label": "Ambajogai", "value": "C46705"},
                 {"label": "Ambarnath", "value": "C46706"},
                 {"label": "Ambegaon", "value": "C46707"},
                 {"label": "Amgaon", "value": "C46708"},
                 {"label": "Amravati", "value": "C46709"},
                 {"label": "Amravati Division", "value": "C46710"},
                 {"label": "Andheri", "value": "C46711"},
                 {"label": "Andura", "value": "C46712"},
                 {"label": "Anjangaon", "value": "C46713"},
                 {"label": "Anjarle", "value": "C46714"},
                 {"label": "Anshing", "value": "C46715"},
                 {"label": "Arag", "value": "C46716"},
                 {"label": "Arangaon", "value": "C46717"},
                 {"label": "Ardhapur", "value": "C46718"},
                 {"label": "Argaon", "value": "C46719"},
                 {"label": "Artist Village", "value": "C46720"},
                 {"label": "Arvi", "value": "C46721"},
                 {"label": "Ashta", "value": "C46722"},
                 {"label": "Ashti", "value": "C46723"},
                 {"label": "Asoda", "value": "C46724"},
                 {"label": "Assaye", "value": "C46725"},
                 {"label": "Astagaon", "value": "C46726"},
                 {"label": "Aundh, Satara", "value": "C46727"},
                 {"label": "Aurangabad", "value": "C46728"},
                 {"label": "Ausa", "value": "C46729"},
                 {"label": "Badlapur", "value": "C46730"},
                 {"label": "Badnapur", "value": "C46731"},
                 {"label": "Badnera", "value": "C46732"},
                 {"label": "Bagewadi", "value": "C46733"},
                 {"label": "Balapur", "value": "C46734"},
                 {"label": "Balapur, Akola district", "value": "C46735"},
                 {"label": "Ballalpur", "value": "C46736"},
                 {"label": "Ballard Estate", "value": "C46737"},
                 {"label": "Ballarpur", "value": "C46738"},
                 {"label": "Banda, Maharashtra", "value": "C46739"},
                 {"label": "Bandra", "value": "C46740"},
                 {"label": "Baner", "value": "C46741"},
                 {"label": "Bankot", "value": "C46742"},
                 {"label": "Baramati", "value": "C46743"},
                 {"label": "Barsi", "value": "C46744"},
                 {"label": "Basmat", "value": "C46745"},
                 {"label": "Basmath", "value": "C46746"},
                 {"label": "Bavdhan", "value": "C46747"},
                 {"label": "Bawanbir", "value": "C46748"},
                 {"label": "Beed", "value": "C46749"},
                 {"label": "Bhadgaon, Maharashtra", "value": "C46750"},
                 {"label": "Bhandara", "value": "C46751"},
                 {"label": "Bhandardara", "value": "C46752"},
                 {"label": "Bhandup", "value": "C46753"},
                 {"label": "Bhayandar", "value": "C46754"},
                 {"label": "Bhigvan", "value": "C46755"},
                 {"label": "Bhiwandi", "value": "C46756"},
                 {"label": "Bhiwapur", "value": "C46757"},
                 {"label": "Bhokar", "value": "C46758"},
                 {"label": "Bhokardan", "value": "C46759"},
                 {"label": "Bhoom", "value": "C46760"},
                 {"label": "Bhor", "value": "C46761"},
                 {"label": "Bhudgaon", "value": "C46762"},
                 {"label": "Bhugaon", "value": "C46763"},
                 {"label": "Bhum", "value": "C46764"},
                 {"label": "Bhusaval", "value": "C46765"},
                 {"label": "Bhusawal", "value": "C46766"},
                 {"label": "Bid", "value": "C46767"},
                 {"label": "Bijur", "value": "C46768"},
                 {"label": "Bilashi", "value": "C46769"},
                 {"label": "Biloli", "value": "C46770"},
                 {"label": "Boisar", "value": "C46771"},
                 {"label": "Borgaon Manju", "value": "C46772"},
                 {"label": "Borivali", "value": "C46773"},
                 {"label": "Borivli", "value": "C46774"},
                 {"label": "Brahmapuri", "value": "C46775"},
                 {"label": "Breach Candy", "value": "C46776"},
                 {"label": "Buldana", "value": "C46777"},
                 {"label": "Buldhana", "value": "C46778"},
                 {"label": "Byculla", "value": "C46779"},
                 {"label": "Chakan", "value": "C46780"},
                 {"label": "Chakur", "value": "C46781"},
                 {"label": "Chalisgaon", "value": "C46782"},
                 {"label": "Chanda", "value": "C46783"},
                 {"label": "Chandgad", "value": "C46784"},
                 {"label": "Chandor", "value": "C46785"},
                 {"label": "Chandrapur", "value": "C46786"},
                 {"label": "Chandur", "value": "C46787"},
                 {"label": "Chandur Bazar", "value": "C46788"},
                 {"label": "Chausala", "value": "C46789"},
                 {"label": "Chembur", "value": "C46790"},
                 {"label": "Chicholi", "value": "C46791"},
                 {"label": "Chichondi Patil", "value": "C46792"},
                 {"label": "Chikhli (Buldhana)", "value": "C46793"},
                 {"label": "Chikhli (Jalna)", "value": "C46794"},
                 {"label": "Chimur", "value": "C46795"},
                 {"label": "Chinchani", "value": "C46796"},
                 {"label": "Chinchpokli", "value": "C46797"},
                 {"label": "Chiplun", "value": "C46798"},
                 {"label": "Chopda", "value": "C46799"},
                 {"label": "Colaba", "value": "C46800"},
                 {"label": "Dabhol", "value": "C46801"},
                 {"label": "Daddi", "value": "C46802"},
                 {"label": "Dahanu", "value": "C46803"},
                 {"label": "Dahivel", "value": "C46804"},
                 {"label": "Dapoli", "value": "C46805"},
                 {"label": "Darwha", "value": "C46806"},
                 {"label": "Daryapur", "value": "C46807"},
                 {"label": "Dattapur", "value": "C46808"},
                 {"label": "Daulatabad", "value": "C46809"},
                 {"label": "Daund", "value": "C46810"},
                 {"label": "Deccan Gymkhana", "value": "C46811"},
                 {"label": "Deglur", "value": "C46812"},
                 {"label": "Dehu", "value": "C46813"},
                 {"label": "Deolali", "value": "C46814"},
                 {"label": "Deolapar", "value": "C46815"},
                 {"label": "Deoli", "value": "C46816"},
                 {"label": "Deoni", "value": "C46817"},
                 {"label": "Deulgaon Raja", "value": "C46818"},
                 {"label": "Devrukh", "value": "C46819"},
                 {"label": "Dharangaon", "value": "C46820"},
                 {"label": "Dharavi", "value": "C46821"},
                 {"label": "Dharmabad", "value": "C46822"},
                 {"label": "Dharur", "value": "C46823"},
                 {"label": "Dhawalpuri", "value": "C46824"},
                 {"label": "Dhule", "value": "C46825"},
                 {"label": "Dhulia", "value": "C46826"},
                 {"label": "Dighori", "value": "C46827"},
                 {"label": "Diglur", "value": "C46828"},
                 {"label": "Digras", "value": "C46829"},
                 {"label": "Dindori, Maharashtra", "value": "C46830"},
                 {"label": "Diveagar", "value": "C46831"},
                 {"label": "Dombivli", "value": "C46832"},
                 {"label": "Dondaicha", "value": "C46833"},
                 {"label": "Dongri", "value": "C46834"},
                 {"label": "Dudhani", "value": "C46835"},
                 {"label": "Durgapur", "value": "C46836"},
                 {"label": "Durgapur, Chandrapur", "value": "C46837"},
                 {"label": "Erandol", "value": "C46838"},
                 {"label": "Faizpur", "value": "C46839"},
                 {"label": "Fort", "value": "C46840"},
                 {"label": "Gadchiroli", "value": "C46841"},
                 {"label": "Gadhinglaj", "value": "C46842"},
                 {"label": "Gangakher", "value": "C46843"},
                 {"label": "Gangapur", "value": "C46844"},
                 {"label": "Ganpatipule", "value": "C46845"},
                 {"label": "Gevrai", "value": "C46846"},
                 {"label": "Ghargaon", "value": "C46847"},
                 {"label": "Ghatanji", "value": "C46848"},
                 {"label": "Ghatkopar", "value": "C46849"},
                 {"label": "Ghoti Budrukh", "value": "C46850"},
                 {"label": "Ghugus", "value": "C46851"},
                 {"label": "Girgaon", "value": "C46852"},
                 {"label": "Gondia", "value": "C46853"},
                 {"label": "Gondiya", "value": "C46854"},
                 {"label": "Gorai", "value": "C46855"},
                 {"label": "Goregaon", "value": "C46856"},
                 {"label": "Guhagar", "value": "C46857"},
                 {"label": "Hadapsar, Pune", "value": "C46858"},
                 {"label": "Hadgaon", "value": "C46859"},
                 {"label": "Halkarni", "value": "C46860"},
                 {"label": "Harangul", "value": "C46861"},
                 {"label": "Harnai", "value": "C46862"},
                 {"label": "Helwak", "value": "C46863"},
                 {"label": "Hinganghat", "value": "C46864"},
                 {"label": "Hingoli", "value": "C46865"},
                 {"label": "Hirapur", "value": "C46866"},
                 {"label": "Hirapur Hamesha", "value": "C46867"},
                 {"label": "Hotgi", "value": "C46868"},
                 {"label": "Ichalkaranji", "value": "C46869"},
                 {"label": "Igatpuri", "value": "C46870"},
                 {"label": "Indapur", "value": "C46871"},
                 {"label": "Jaisingpur", "value": "C46872"},
                 {"label": "Jaitapur", "value": "C46873"},
                 {"label": "Jakhangaon", "value": "C46874"},
                 {"label": "Jalgaon", "value": "C46875"},
                 {"label": "Jalgaon Jamod", "value": "C46876"},
                 {"label": "Jalkot", "value": "C46877"},
                 {"label": "Jalna", "value": "C46878"},
                 {"label": "Jamkhed", "value": "C46879"},
                 {"label": "Jamod", "value": "C46880"},
                 {"label": "Janephal", "value": "C46881"},
                 {"label": "Jaoli", "value": "C46882"},
                 {"label": "Jat, Sangli", "value": "C46883"},
                 {"label": "Jategaon", "value": "C46884"},
                 {"label": "Jawhar", "value": "C46885"},
                 {"label": "Jaysingpur", "value": "C46886"},
                 {"label": "Jejuri", "value": "C46887"},
                 {"label": "Jintur", "value": "C46888"},
                 {"label": "Jogeshwari", "value": "C46889"},
                 {"label": "Juhu", "value": "C46890"},
                 {"label": "Junnar", "value": "C46891"},
                 {"label": "Kachurwahi", "value": "C46892"},
                 {"label": "Kadegaon", "value": "C46893"},
                 {"label": "Kadus", "value": "C46894"},
                 {"label": "Kagal", "value": "C46895"},
                 {"label": "Kaij", "value": "C46896"},
                 {"label": "Kalamb", "value": "C46897"},
                 {"label": "Kalamb, Osmanabad", "value": "C46898"},
                 {"label": "Kalamboli", "value": "C46899"},
                 {"label": "Kalamnuri", "value": "C46900"},
                 {"label": "Kalas", "value": "C46901"},
                 {"label": "Kali(DK)", "value": "C46902"},
                 {"label": "Kalmeshwar", "value": "C46903"},
                 {"label": "Kalundri", "value": "C46904"},
                 {"label": "Kalyan", "value": "C46905"},
                 {"label": "Kalyani Nagar", "value": "C46906"},
                 {"label": "Kamargaon", "value": "C46907"},
                 {"label": "Kamatgi", "value": "C46908"},
                 {"label": "Kamptee", "value": "C46909"},
                 {"label": "Kamthi", "value": "C46910"},
                 {"label": "Kandri", "value": "C46911"},
                 {"label": "Kankauli", "value": "C46912"},
                 {"label": "Kankavli", "value": "C46913"},
                 {"label": "Kannad", "value": "C46914"},
                 {"label": "Karad", "value": "C46915"},
                 {"label": "Karajagi", "value": "C46916"},
                 {"label": "Karanja", "value": "C46917"},
                 {"label": "Karanja Lad", "value": "C46918"},
                 {"label": "Karjat", "value": "C46919"},
                 {"label": "Karkamb", "value": "C46920"},
                 {"label": "Karmala", "value": "C46921"},
                 {"label": "Kasara", "value": "C46922"},
                 {"label": "Kasoda", "value": "C46923"},
                 {"label": "Kati", "value": "C46924"},
                 {"label": "Katol", "value": "C46925"},
                 {"label": "Katral", "value": "C46926"},
                 {"label": "Khadki", "value": "C46927"},
                 {"label": "Khalapur", "value": "C46928"},
                 {"label": "Khallar", "value": "C46929"},
                 {"label": "Khamgaon", "value": "C46930"},
                 {"label": "Khanapur", "value": "C46931"},
                 {"label": "Khandala", "value": "C46932"},
                 {"label": "Khangaon", "value": "C46933"},
                 {"label": "Khapa", "value": "C46934"},
                 {"label": "Kharakvasla", "value": "C46935"},
                 {"label": "Kharda", "value": "C46936"},
                 {"label": "Kharghar", "value": "C46937"},
                 {"label": "Kharsundi", "value": "C46938"},
                 {"label": "Khed", "value": "C46939"},
                 {"label": "Khede", "value": "C46940"},
                 {"label": "Khetia", "value": "C46941"},
                 {"label": "Khoni", "value": "C46942"},
                 {"label": "Khopoli", "value": "C46943"},
                 {"label": "Khuldabad", "value": "C46944"},
                 {"label": "Kinwat", "value": "C46945"},
                 {"label": "Kodoli", "value": "C46946"},
                 {"label": "Kolhapur", "value": "C46947"},
                 {"label": "Kondalwadi", "value": "C46948"},
                 {"label": "Kondhali", "value": "C46949"},
                 {"label": "Kopar Khairane", "value": "C46950"},
                 {"label": "Kopargaon", "value": "C46951"},
                 {"label": "Kopela", "value": "C46952"},
                 {"label": "Koradi", "value": "C46953"},
                 {"label": "Koregaon", "value": "C46954"},
                 {"label": "Koynanagar", "value": "C46955"},
                 {"label": "Kudal", "value": "C46956"},
                 {"label": "Kuhi", "value": "C46957"},
                 {"label": "Kurandvad", "value": "C46958"},
                 {"label": "Kurankhed", "value": "C46959"},
                 {"label": "Kurduvadi", "value": "C46960"},
                 {"label": "Kusumba", "value": "C46961"},
                 {"label": "Lakhandur", "value": "C46962"},
                 {"label": "Lanja", "value": "C46963"},
                 {"label": "Lasalgaon", "value": "C46964"},
                 {"label": "Latur", "value": "C46965"},
                 {"label": "Lavasa", "value": "C46966"},
                 {"label": "Lohogaon", "value": "C46967"},
                 {"label": "Lonar", "value": "C46968"},
                 {"label": "Lonavla", "value": "C46969"},
                 {"label": "Mahabaleshwar", "value": "C46970"},
                 {"label": "Mahad", "value": "C46971"},
                 {"label": "Mahape", "value": "C46972"},
                 {"label": "Mahim", "value": "C46973"},
                 {"label": "Maindargi", "value": "C46974"},
                 {"label": "Majalgaon", "value": "C46975"},
                 {"label": "Makhjan", "value": "C46976"},
                 {"label": "Malabar Hill", "value": "C46977"},
                 {"label": "Malad", "value": "C46978"},
                 {"label": "Malegaon", "value": "C46979"},
                 {"label": "Malkapur", "value": "C46980"},
                 {"label": "Malvan", "value": "C46981"},
                 {"label": "Manchar", "value": "C46982"},
                 {"label": "Mandangad", "value": "C46983"},
                 {"label": "Mandhal", "value": "C46984"},
                 {"label": "Mandwa", "value": "C46985"},
                 {"label": "Mangaon", "value": "C46986"},
                 {"label": "Mangrul Pir", "value": "C46987"},
                 {"label": "Manjlegaon", "value": "C46988"},
                 {"label": "Mankeshwar", "value": "C46989"},
                 {"label": "Mankhurd", "value": "C46990"},
                 {"label": "Manmad", "value": "C46991"},
                 {"label": "Manor", "value": "C46992"},
                 {"label": "Mansar", "value": "C46993"},
                 {"label": "Manwat", "value": "C46994"},
                 {"label": "Manwath", "value": "C46995"},
                 {"label": "Maregaon", "value": "C46996"},
                 {"label": "Mastiholi", "value": "C46997"},
                 {"label": "Masur, India", "value": "C46998"},
                 {"label": "Matheran", "value": "C46999"},
                 {"label": "Matunga", "value": "C47000"},
                 {"label": "Mazagaon", "value": "C47001"},
                 {"label": "Mehekar", "value": "C47002"},
                 {"label": "Mehergaon", "value": "C47003"},
                 {"label": "Mehkar", "value": "C47004"},
                 {"label": "Mhasla", "value": "C47005"},
                 {"label": "Mhasvad", "value": "C47006"},
                 {"label": "Miraj", "value": "C47007"},
                 {"label": "Mohadi", "value": "C47008"},
                 {"label": "Mohol", "value": "C47009"},
                 {"label": "Mohpa", "value": "C47010"},
                 {"label": "Mokhada taluka", "value": "C47011"},
                 {"label": "Mora, Maharashtra", "value": "C47012"},
                 {"label": "Moram", "value": "C47013"},
                 {"label": "Morsi", "value": "C47014"},
                 {"label": "Mowad", "value": "C47015"},
                 {"label": "Mudkhed", "value": "C47016"},
                 {"label": "Mukher", "value": "C47017"},
                 {"label": "Mul", "value": "C47018"},
                 {"label": "Mulher", "value": "C47019"},
                 {"label": "Mulund", "value": "C47020"},
                 {"label": "Mumbai", "value": "C47021"},
                 {"label": "Mumbai Suburban", "value": "C47022"},
                 {"label": "Murbad", "value": "C47023"},
                 {"label": "Murgud", "value": "C47024"},
                 {"label": "Murtajapur", "value": "C47025"},
                 {"label": "Murud (Raigad)", "value": "C47026"},
                 {"label": "Murud (Ratnagiri)", "value": "C47027"},
                 {"label": "Murum", "value": "C47028"},
                 {"label": "Nadgaon", "value": "C47029"},
                 {"label": "Nagapur", "value": "C47030"},
                 {"label": "Nagothana", "value": "C47031"},
                 {"label": "Nagpur", "value": "C47032"},
                 {"label": "Nagpur Division", "value": "C47033"},
                 {"label": "Nala Sopara", "value": "C47034"},
                 {"label": "Naldurg", "value": "C47035"},
                 {"label": "Nalegaon", "value": "C47036"},
                 {"label": "Nampur", "value": "C47037"},
                 {"label": "Nanded", "value": "C47038"},
                 {"label": "Nandgaon", "value": "C47039"},
                 {"label": "Nandnee", "value": "C47040"},
                 {"label": "Nandura", "value": "C47041"},
                 {"label": "Nandura Buzurg", "value": "C47042"},
                 {"label": "Nandurbar", "value": "C47043"},
                 {"label": "Narayangaon", "value": "C47044"},
                 {"label": "Nardana", "value": "C47045"},
                 {"label": "Nariman Point", "value": "C47046"},
                 {"label": "Narkhed", "value": "C47047"},
                 {"label": "Nashik", "value": "C47048"},
                 {"label": "Nashik Division", "value": "C47049"},
                 {"label": "Navapur", "value": "C47050"},
                 {"label": "Navi Mumbai", "value": "C47051"},
                 {"label": "Neral", "value": "C47052"},
                 {"label": "Nerur", "value": "C47053"},
                 {"label": "Nevasa", "value": "C47054"},
                 {"label": "Nighoj", "value": "C47055"},
                 {"label": "Nilanga", "value": "C47056"},
                 {"label": "Nipani", "value": "C47057"},
                 {"label": "Niphad", "value": "C47058"},
                 {"label": "Nira Narsingpur", "value": "C47059"},
                 {"label": "Osmanabad", "value": "C47060"},
                 {"label": "Ozar", "value": "C47061"},
                 {"label": "Pabal", "value": "C47062"},
                 {"label": "Pachora", "value": "C47063"},
                 {"label": "Pahur, Maharashtra", "value": "C47064"},
                 {"label": "Paithan", "value": "C47065"},
                 {"label": "Palghar", "value": "C47066"},
                 {"label": "Pali, Raigad", "value": "C47067"},
                 {"label": "Palso", "value": "C47068"},
                 {"label": "Panchgani", "value": "C47069"},
                 {"label": "Pandharpur", "value": "C47070"},
                 {"label": "Pandhurli", "value": "C47071"},
                 {"label": "Panhala", "value": "C47072"},
                 {"label": "Panvel", "value": "C47073"},
                 {"label": "Parbhani", "value": "C47074"},
                 {"label": "Parel", "value": "C47075"},
                 {"label": "Parli Vaijnath", "value": "C47076"},
                 {"label": "Parner", "value": "C47077"},
                 {"label": "Parola", "value": "C47078"},
                 {"label": "Parseoni", "value": "C47079"},
                 {"label": "Partur", "value": "C47080"},
                 {"label": "Patan", "value": "C47081"},
                 {"label": "Pathardi", "value": "C47082"},
                 {"label": "Pathri", "value": "C47083"},
                 {"label": "Patur", "value": "C47084"},
                 {"label": "Paturda", "value": "C47085"},
                 {"label": "Paud", "value": "C47086"},
                 {"label": "Pauni", "value": "C47087"},
                 {"label": "Pawni", "value": "C47088"},
                 {"label": "Pedgaon", "value": "C47089"},
                 {"label": "Peint", "value": "C47090"},
                 {"label": "Pen", "value": "C47091"},
                 {"label": "Phaltan", "value": "C47092"},
                 {"label": "Phulambri", "value": "C47093"},
                 {"label": "Piliv", "value": "C47094"},
                 {"label": "Pimpalgaon Baswant", "value": "C47095"},
                 {"label": "Pimpalgaon Raja", "value": "C47096"},
                 {"label": "Pimpri", "value": "C47097"},
                 {"label": "Pimpri-Chinchwad", "value": "C47098"},
                 {"label": "Pipri", "value": "C47099"},
                 {"label": "Powai", "value": "C47100"},
                 {"label": "Prabhadevi", "value": "C47101"},
                 {"label": "Prakasha", "value": "C47102"},
                 {"label": "Pulgaon", "value": "C47103"},
                 {"label": "Pune", "value": "C47104"},
                 {"label": "Pune Division", "value": "C47105"},
                 {"label": "Puntamba", "value": "C47106"},
                 {"label": "Pural", "value": "C47107"},
                 {"label": "Purna", "value": "C47108"},
                 {"label": "Pusad", "value": "C47109"},
                 {"label": "Radhanagari", "value": "C47110"},
                 {"label": "Rahata", "value": "C47111"},
                 {"label": "Rahimatpur", "value": "C47112"},
                 {"label": "Rahuri", "value": "C47113"},
                 {"label": "Raigarh", "value": "C47114"},
                 {"label": "Raireshwar", "value": "C47115"},
                 {"label": "Rajapur", "value": "C47116"},
                 {"label": "Rajgurunagar", "value": "C47117"},
                 {"label": "Rajur", "value": "C47118"},
                 {"label": "Rajura", "value": "C47119"},
                 {"label": "Ralegaon", "value": "C47120"},
                 {"label": "Ramewadi", "value": "C47121"},
                 {"label": "Ramtek", "value": "C47122"},
                 {"label": "Ratnagiri", "value": "C47123"},
                 {"label": "Raver", "value": "C47124"},
                 {"label": "Renapur", "value": "C47125"},
                 {"label": "Renavi", "value": "C47126"},
                 {"label": "Revadanda", "value": "C47127"},
                 {"label": "Revdanda", "value": "C47128"},
                 {"label": "Risod", "value": "C47129"},
                 {"label": "Roha", "value": "C47130"},
                 {"label": "Sailu", "value": "C47131"},
                 {"label": "Sakol", "value": "C47132"},
                 {"label": "Sakoli", "value": "C47133"},
                 {"label": "Sakri", "value": "C47134"},
                 {"label": "Samudrapur", "value": "C47135"},
                 {"label": "Sangameshwar", "value": "C47136"},
                 {"label": "Sangamner", "value": "C47137"},
                 {"label": "Sangli", "value": "C47138"},
                 {"label": "Sangola", "value": "C47139"},
                 {"label": "Sangole", "value": "C47140"},
                 {"label": "Sangrampur, Maharashtra", "value": "C47141"},
                 {"label": "Saoli", "value": "C47142"},
                 {"label": "Saoner", "value": "C47143"},
                 {"label": "Sarangkheda", "value": "C47144"},
                 {"label": "Sasvad", "value": "C47145"},
                 {"label": "Saswad", "value": "C47146"},
                 {"label": "Satana", "value": "C47147"},
                 {"label": "Satara", "value": "C47148"},
                 {"label": "Satara Division", "value": "C47149"},
                 {"label": "Satpati", "value": "C47150"},
                 {"label": "Savantvadi", "value": "C47151"},
                 {"label": "Savda", "value": "C47152"},
                 {"label": "Savlaj", "value": "C47153"},
                 {"label": "Sawantvadi", "value": "C47154"},
                 {"label": "Selu", "value": "C47155"},
                 {"label": "Sevagram", "value": "C47156"},
                 {"label": "Sewri", "value": "C47157"},
                 {"label": "Shahada", "value": "C47158"},
                 {"label": "Shahapur", "value": "C47159"},
                 {"label": "Shedbal", "value": "C47160"},
                 {"label": "Shegaon", "value": "C47161"},
                 {"label": "Shevgaon", "value": "C47162"},
                 {"label": "Shikrapur", "value": "C47163"},
                 {"label": "Shiraguppi", "value": "C47164"},
                 {"label": "Shirala", "value": "C47165"},
                 {"label": "Shirdi", "value": "C47166"},
                 {"label": "Shirgaon", "value": "C47167"},
                 {"label": "Shirol", "value": "C47168"},
                 {"label": "Shirpur", "value": "C47169"},
                 {"label": "Shirud", "value": "C47170"},
                 {"label": "Shirwal", "value": "C47171"},
                 {"label": "Shivaji Nagar", "value": "C47172"},
                 {"label": "Shrigonda", "value": "C47173"},
                 {"label": "Sillod", "value": "C47174"},
                 {"label": "Sindewahi", "value": "C47175"},
                 {"label": "Sindhudurg", "value": "C47176"},
                 {"label": "Sindi", "value": "C47177"},
                 {"label": "Sindkheda", "value": "C47178"},
                 {"label": "Sinnar", "value": "C47179"},
                 {"label": "Sion, Mumbai", "value": "C47180"},
                 {"label": "Sironcha", "value": "C47181"},
                 {"label": "Sirur", "value": "C47182"},
                 {"label": "Sivala, East Godavari district", "value": "C47183"},
                 {"label": "Solapur", "value": "C47184"},
                 {"label": "Sonala", "value": "C47185"},
                 {"label": "Sonegaon", "value": "C47186"},
                 {"label": "Songir", "value": "C47187"},
                 {"label": "Sonvad", "value": "C47188"},
                 {"label": "Soygaon", "value": "C47189"},
                 {"label": "Srivardhan", "value": "C47190"},
                 {"label": "Surgana", "value": "C47191"},
                 {"label": "Taklibhan", "value": "C47192"},
                 {"label": "Talbid", "value": "C47193"},
                 {"label": "Talegaon Dabhade", "value": "C47194"},
                 {"label": "Talegaon Dhamdhere", "value": "C47195"},
                 {"label": "Taloda", "value": "C47196"},
                 {"label": "Talode", "value": "C47197"},
                 {"label": "Tarapur", "value": "C47198"},
                 {"label": "Tardeo", "value": "C47199"},
                 {"label": "Tasgaon", "value": "C47200"},
                 {"label": "Telhara", "value": "C47201"},
                 {"label": "Thalner", "value": "C47202"},
                 {"label": "Thane", "value": "C47203"},
                 {"label": "Trimbak", "value": "C47204"},
                 {"label": "Trombay", "value": "C47205"},
                 {"label": "Tuljapur", "value": "C47206"},
                 {"label": "Tumsar", "value": "C47207"},
                 {"label": "Udgir", "value": "C47208"},
                 {"label": "Ulhasnagar", "value": "C47209"},
                 {"label": "Umarga", "value": "C47210"},
                 {"label": "Umarkhed", "value": "C47211"},
                 {"label": "Umred", "value": "C47212"},
                 {"label": "Uran", "value": "C47213"},
                 {"label": "Uruli Kanchan", "value": "C47214"},
                 {"label": "Vada", "value": "C47215"},
                 {"label": "Vadgaon", "value": "C47216"},
                 {"label": "Vadner", "value": "C47217"},
                 {"label": "Vaijapur", "value": "C47218"},
                 {"label": "Vairag", "value": "C47219"},
                 {"label": "Valsang", "value": "C47220"},
                 {"label": "Vangaon", "value": "C47221"},
                 {"label": "Varangaon", "value": "C47222"},
                 {"label": "Vashi", "value": "C47223"},
                 {"label": "Vasind", "value": "C47224"},
                 {"label": "Vatul", "value": "C47225"},
                 {"label": "Velas, Maharashtra", "value": "C47226"},
                 {"label": "Velneshwar", "value": "C47227"},
                 {"label": "Vengurla", "value": "C47228"},
                 {"label": "Vijaydurg", "value": "C47229"},
                 {"label": "Vikhroli", "value": "C47230"},
                 {"label": "Vile Parle", "value": "C47231"},
                 {"label": "Vinchur", "value": "C47232"},
                 {"label": "Virar", "value": "C47233"},
                 {"label": "Vita, Maharashtra", "value": "C47234"},
                 {"label": "Vite", "value": "C47235"},
                 {"label": "Wadala", "value": "C47236"},
                 {"label": "Wadgaon", "value": "C47237"},
                 {"label": "Wadner", "value": "C47238"},
                 {"label": "Wadwani", "value": "C47239"},
                 {"label": "Wagholi", "value": "C47240"},
                 {"label": "Wai", "value": "C47241"},
                 {"label": "Wakad", "value": "C47242"},
                 {"label": "Walgaon", "value": "C47243"},
                 {"label": "Walki", "value": "C47244"},
                 {"label": "Wani", "value": "C47245"},
                 {"label": "Wardha", "value": "C47246"},
                 {"label": "Warora", "value": "C47247"},
                 {"label": "Warud", "value": "C47248"},
                 {"label": "Washim", "value": "C47249"},
                 {"label": "Worli", "value": "C47250"},
                 {"label": "Yaval", "value": "C47251"},
                 {"label": "Yavatmal", "value": "C47252"},
                 {"label": "Yeola", "value": "C47253"}],
 "Mahdia Governorate": [{"label": "Chebba", "value": "C119737"},
                        {"label": "Chorbane", "value": "C119738"},
                        {"label": "El Jem", "value": "C119739"},
                        {"label": "Ksour Essaf", "value": "C119740"},
                        {"label": "Mahdia", "value": "C119741"},
                        {"label": "Mellouleche", "value": "C119742"},
                        {"label": "Salakta", "value": "C119743"},
                        {"label": "Sidi Alouane", "value": "C119744"},
                        {"label": "Zouila", "value": "C119745"}],
 "Mai-Ndombe": [{"label": "Bolobo", "value": "C23348"},
                {"label": "Inongo", "value": "C23349"},
                {"label": "Mushie", "value": "C23350"},
                {"label": "Nioki", "value": "C23351"}],
 "Maine": [{"label": "Acton", "value": "C134019"},
           {"label": "Addison", "value": "C134020"},
           {"label": "Albion", "value": "C134021"},
           {"label": "Alfred", "value": "C134022"},
           {"label": "Androscoggin County", "value": "C134023"},
           {"label": "Appleton", "value": "C134024"},
           {"label": "Aroostook County", "value": "C134025"},
           {"label": "Arundel", "value": "C134026"},
           {"label": "Auburn", "value": "C134027"},
           {"label": "Augusta", "value": "C134028"},
           {"label": "Bangor", "value": "C134029"},
           {"label": "Bar Harbor", "value": "C134030"},
           {"label": "Bath", "value": "C134031"},
           {"label": "Belfast", "value": "C134032"},
           {"label": "Belgrade", "value": "C134033"},
           {"label": "Benton", "value": "C134034"},
           {"label": "Berwick", "value": "C134035"},
           {"label": "Bethel", "value": "C134036"},
           {"label": "Biddeford", "value": "C134037"},
           {"label": "Boothbay", "value": "C134038"},
           {"label": "Boothbay Harbor", "value": "C134039"},
           {"label": "Bradford", "value": "C134040"},
           {"label": "Bradley", "value": "C134041"},
           {"label": "Brewer", "value": "C134042"},
           {"label": "Bridgton", "value": "C134043"},
           {"label": "Bristol", "value": "C134044"},
           {"label": "Brooks", "value": "C134045"},
           {"label": "Brownfield", "value": "C134046"},
           {"label": "Brownville", "value": "C134047"},
           {"label": "Brunswick", "value": "C134048"},
           {"label": "Buckfield", "value": "C134049"},
           {"label": "Bucksport", "value": "C134050"},
           {"label": "Burnham", "value": "C134051"},
           {"label": "Buxton", "value": "C134052"},
           {"label": "Calais", "value": "C134053"},
           {"label": "Camden", "value": "C134054"},
           {"label": "Canaan", "value": "C134055"},
           {"label": "Canton", "value": "C134056"},
           {"label": "Cape Neddick", "value": "C134057"},
           {"label": "Caribou", "value": "C134058"},
           {"label": "Carmel", "value": "C134059"},
           {"label": "Castine", "value": "C134060"},
           {"label": "Charleston", "value": "C134061"},
           {"label": "Chelsea", "value": "C134062"},
           {"label": "Cherryfield", "value": "C134063"},
           {"label": "Chesterville", "value": "C134064"},
           {"label": "China", "value": "C134065"},
           {"label": "Chisholm", "value": "C134066"},
           {"label": "Clinton", "value": "C134067"},
           {"label": "Corinna", "value": "C134068"},
           {"label": "Cornish", "value": "C134069"},
           {"label": "Cornville", "value": "C134070"},
           {"label": "Cumberland Center", "value": "C134071"},
           {"label": "Cumberland County", "value": "C134072"},
           {"label": "Cushing", "value": "C134073"},
           {"label": "Damariscotta", "value": "C134074"},
           {"label": "Dayton", "value": "C134075"},
           {"label": "Dedham", "value": "C134076"},
           {"label": "Deer Isle", "value": "C134077"},
           {"label": "Denmark", "value": "C134078"},
           {"label": "Dexter", "value": "C134079"},
           {"label": "Dixfield", "value": "C134080"},
           {"label": "Dixmont", "value": "C134081"},
           {"label": "Dover-Foxcroft", "value": "C134082"},
           {"label": "East Machias", "value": "C134083"},
           {"label": "East Millinocket", "value": "C134084"},
           {"label": "Easton", "value": "C134085"},
           {"label": "Eastport", "value": "C134086"},
           {"label": "Eddington", "value": "C134087"},
           {"label": "Edgecomb", "value": "C134088"},
           {"label": "Eliot", "value": "C134089"},
           {"label": "Ellsworth", "value": "C134090"},
           {"label": "Enfield", "value": "C134091"},
           {"label": "Etna", "value": "C134092"},
           {"label": "Fairfield", "value": "C134093"},
           {"label": "Falmouth", "value": "C134094"},
           {"label": "Falmouth Foreside", "value": "C134095"},
           {"label": "Farmingdale", "value": "C134096"},
           {"label": "Farmington", "value": "C134097"},
           {"label": "Fayette", "value": "C134098"},
           {"label": "Fort Fairfield", "value": "C134099"},
           {"label": "Fort Kent", "value": "C134100"},
           {"label": "Frankfort", "value": "C134101"},
           {"label": "Franklin", "value": "C134102"},
           {"label": "Franklin County", "value": "C134103"},
           {"label": "Freeport", "value": "C134104"},
           {"label": "Frenchville", "value": "C134105"},
           {"label": "Friendship", "value": "C134106"},
           {"label": "Fryeburg", "value": "C134107"},
           {"label": "Gardiner", "value": "C134108"},
           {"label": "Garland", "value": "C134109"},
           {"label": "Gorham", "value": "C134110"},
           {"label": "Gouldsboro", "value": "C134111"},
           {"label": "Greenbush", "value": "C134112"},
           {"label": "Greene", "value": "C134113"},
           {"label": "Greenville", "value": "C134114"},
           {"label": "Hallowell", "value": "C134115"},
           {"label": "Hampden", "value": "C134116"},
           {"label": "Hancock", "value": "C134117"},
           {"label": "Hancock County", "value": "C134118"},
           {"label": "Harpswell Center", "value": "C134119"},
           {"label": "Harrison", "value": "C134120"},
           {"label": "Hartford", "value": "C134121"},
           {"label": "Hebron", "value": "C134122"},
           {"label": "Hermon", "value": "C134123"},
           {"label": "Hiram", "value": "C134124"},
           {"label": "Hodgdon", "value": "C134125"},
           {"label": "Holden", "value": "C134126"},
           {"label": "Hollis Center", "value": "C134127"},
           {"label": "Hope", "value": "C134128"},
           {"label": "Houlton", "value": "C134129"},
           {"label": "Howland", "value": "C134130"},
           {"label": "Hudson", "value": "C134131"},
           {"label": "Jay", "value": "C134132"},
           {"label": "Jefferson", "value": "C134133"},
           {"label": "Jonesport", "value": "C134134"},
           {"label": "Kenduskeag", "value": "C134135"},
           {"label": "Kennebec County", "value": "C134136"},
           {"label": "Kennebunk", "value": "C134137"},
           {"label": "Kennebunkport", "value": "C134138"},
           {"label": "Kingfield", "value": "C134139"},
           {"label": "Kittery", "value": "C134140"},
           {"label": "Kittery Point", "value": "C134141"},
           {"label": "Knox County", "value": "C134142"},
           {"label": "Lake Arrowhead", "value": "C134143"},
           {"label": "Lebanon", "value": "C134144"},
           {"label": "Leeds", "value": "C134145"},
           {"label": "Levant", "value": "C134146"},
           {"label": "Lewiston", "value": "C134147"},
           {"label": "Limerick", "value": "C134148"},
           {"label": "Limestone", "value": "C134149"},
           {"label": "Limington", "value": "C134150"},
           {"label": "Lincoln", "value": "C134151"},
           {"label": "Lincoln County", "value": "C134152"},
           {"label": "Lincolnville", "value": "C134153"},
           {"label": "Lisbon", "value": "C134154"},
           {"label": "Lisbon Falls", "value": "C134155"},
           {"label": "Livermore", "value": "C134156"},
           {"label": "Livermore Falls", "value": "C134157"},
           {"label": "Lovell", "value": "C134158"},
           {"label": "Machias", "value": "C134159"},
           {"label": "Machiasport", "value": "C134160"},
           {"label": "Madawaska", "value": "C134161"},
           {"label": "Madison", "value": "C134162"},
           {"label": "Manchester", "value": "C134163"},
           {"label": "Mechanic Falls", "value": "C134164"},
           {"label": "Medway", "value": "C134165"},
           {"label": "Mexico", "value": "C134166"},
           {"label": "Milbridge", "value": "C134167"},
           {"label": "Milford", "value": "C134168"},
           {"label": "Millinocket", "value": "C134169"},
           {"label": "Milo", "value": "C134170"},
           {"label": "Minot", "value": "C134171"},
           {"label": "Monmouth", "value": "C134172"},
           {"label": "Mount Vernon", "value": "C134173"},
           {"label": "New Gloucester", "value": "C134174"},
           {"label": "New Sharon", "value": "C134175"},
           {"label": "Newfield", "value": "C134176"},
           {"label": "Newport", "value": "C134177"},
           {"label": "Nobleboro", "value": "C134178"},
           {"label": "Norridgewock", "value": "C134179"},
           {"label": "North Bath", "value": "C134180"},
           {"label": "North Berwick", "value": "C134181"},
           {"label": "North Windham", "value": "C134182"},
           {"label": "Northport", "value": "C134183"},
           {"label": "Norway", "value": "C134184"},
           {"label": "Oakland", "value": "C134185"},
           {"label": "Ogunquit", "value": "C134186"},
           {"label": "Old Orchard Beach", "value": "C134187"},
           {"label": "Old Town", "value": "C134188"},
           {"label": "Orland", "value": "C134189"},
           {"label": "Orono", "value": "C134190"},
           {"label": "Orrington", "value": "C134191"},
           {"label": "Owls Head", "value": "C134192"},
           {"label": "Oxford", "value": "C134193"},
           {"label": "Oxford County", "value": "C134194"},
           {"label": "Palermo", "value": "C134195"},
           {"label": "Palmyra", "value": "C134196"},
           {"label": "Paris", "value": "C134197"},
           {"label": "Parsonsfield", "value": "C134198"},
           {"label": "Patten", "value": "C134199"},
           {"label": "Penobscot", "value": "C134200"},
           {"label": "Penobscot County", "value": "C134201"},
           {"label": "Peru", "value": "C134202"},
           {"label": "Phillips", "value": "C134203"},
           {"label": "Phippsburg", "value": "C134204"},
           {"label": "Piscataquis County", "value": "C134205"},
           {"label": "Pittsfield", "value": "C134206"},
           {"label": "Pittston", "value": "C134207"},
           {"label": "Plymouth", "value": "C134208"},
           {"label": "Poland", "value": "C134209"},
           {"label": "Porter", "value": "C134210"},
           {"label": "Portland", "value": "C134211"},
           {"label": "Presque Isle", "value": "C134212"},
           {"label": "Randolph", "value": "C134213"},
           {"label": "Raymond", "value": "C134214"},
           {"label": "Readfield", "value": "C134215"},
           {"label": "Richmond", "value": "C134216"},
           {"label": "Rockland", "value": "C134217"},
           {"label": "Rockport", "value": "C134218"},
           {"label": "Rome", "value": "C134219"},
           {"label": "Rumford", "value": "C134220"},
           {"label": "Sabattus", "value": "C134221"},
           {"label": "Saco", "value": "C134222"},
           {"label": "Sagadahoc County", "value": "C134223"},
           {"label": "Saint Albans", "value": "C134224"},
           {"label": "Saint George", "value": "C134225"},
           {"label": "Sanford", "value": "C134226"},
           {"label": "Sangerville", "value": "C134227"},
           {"label": "Scarborough", "value": "C134228"},
           {"label": "Searsmont", "value": "C134229"},
           {"label": "Sedgwick", "value": "C134230"},
           {"label": "Shapleigh", "value": "C134231"},
           {"label": "Sidney", "value": "C134232"},
           {"label": "Skowhegan", "value": "C134233"},
           {"label": "Somerset County", "value": "C134234"},
           {"label": "South Berwick", "value": "C134235"},
           {"label": "South Eliot", "value": "C134236"},
           {"label": "South Paris", "value": "C134237"},
           {"label": "South Portland", "value": "C134238"},
           {"label": "South Portland Gardens", "value": "C134239"},
           {"label": "South Sanford", "value": "C134240"},
           {"label": "South Thomaston", "value": "C134241"},
           {"label": "South Windham", "value": "C134242"},
           {"label": "Springvale", "value": "C134243"},
           {"label": "Steep Falls", "value": "C134244"},
           {"label": "Stetson", "value": "C134245"},
           {"label": "Steuben", "value": "C134246"},
           {"label": "Stockton Springs", "value": "C134247"},
           {"label": "Stonington", "value": "C134248"},
           {"label": "Strong", "value": "C134249"},
           {"label": "Sullivan", "value": "C134250"},
           {"label": "Surry", "value": "C134251"},
           {"label": "Swanville", "value": "C134252"},
           {"label": "Thomaston", "value": "C134253"},
           {"label": "Topsham", "value": "C134254"},
           {"label": "Tremont", "value": "C134255"},
           {"label": "Trenton", "value": "C134256"},
           {"label": "Troy", "value": "C134257"},
           {"label": "Turner", "value": "C134258"},
           {"label": "Union", "value": "C134259"},
           {"label": "Van Buren", "value": "C134260"},
           {"label": "Vassalboro", "value": "C134261"},
           {"label": "Veazie", "value": "C134262"},
           {"label": "Vinalhaven", "value": "C134263"},
           {"label": "Waldo County", "value": "C134264"},
           {"label": "Waldoboro", "value": "C134265"},
           {"label": "Warren", "value": "C134266"},
           {"label": "Washington", "value": "C134267"},
           {"label": "Washington County", "value": "C134268"},
           {"label": "Waterboro", "value": "C134269"},
           {"label": "Waterville", "value": "C134270"},
           {"label": "Wayne", "value": "C134271"},
           {"label": "Wells Beach Station", "value": "C134272"},
           {"label": "West Kennebunk", "value": "C134273"},
           {"label": "West Paris", "value": "C134274"},
           {"label": "West Scarborough", "value": "C134275"},
           {"label": "Westbrook", "value": "C134276"},
           {"label": "Whitefield", "value": "C134277"},
           {"label": "Wilton", "value": "C134278"},
           {"label": "Windsor", "value": "C134279"},
           {"label": "Winslow", "value": "C134280"},
           {"label": "Winterport", "value": "C134281"},
           {"label": "Winthrop", "value": "C134282"},
           {"label": "Wiscasset", "value": "C134283"},
           {"label": "Woodstock", "value": "C134284"},
           {"label": "Woolwich", "value": "C134285"},
           {"label": "Yarmouth", "value": "C134286"},
           {"label": "York Beach", "value": "C134287"},
           {"label": "York County", "value": "C134288"},
           {"label": "York Harbor", "value": "C134289"}],
 "Maio Municipality": [{"label": "Vila do Maio", "value": "C17786"}],
 "Majsperk Municipality": [{"label": "Majsperk", "value": "C107709"}],
 "Makamba Province": [{"label": "Makamba", "value": "C16451"}],
 "Makedonska Kamenica Municipality": [{"label": "Makedonska Kamenica",
                                       "value": "C65458"}],
 "Makedonski Brod Municipality": [{"label": "Makedonski Brod",
                                   "value": "C65459"},
                                  {"label": "Samokov", "value": "C65460"}],
 "Makira-Ulawa Province": [{"label": "Kirakira", "value": "C107876"}],
 "Makkah": [{"label": "Abha", "value": "C106630"},
            {"label": "Abu `Urwah", "value": "C106631"},
            {"label": "Abu Hisani", "value": "C106632"},
            {"label": "Abu Qirfah", "value": "C106633"},
            {"label": "Abu Shu`ayb", "value": "C106634"},
            {"label": "Ad Dabbah", "value": "C106635"},
            {"label": "Ad Dawh", "value": "C106636"},
            {"label": "Ad Dur", "value": "C106637"},
            {"label": "Al abar", "value": "C106638"},
            {"label": "Al Adl", "value": "C106639"},
            {"label": "Al Ashraf", "value": "C106640"},
            {"label": "Al Balad", "value": "C106641"},
            {"label": "Al Barabir", "value": "C106642"},
            {"label": "Al Biar", "value": "C106643"},
            {"label": "Al Birk", "value": "C106644"},
            {"label": "Al Buraykah", "value": "C106645"},
            {"label": "Al Fawwarah", "value": "C106646"},
            {"label": "Al Faydah", "value": "C106647"},
            {"label": "Al Fazz", "value": "C106648"},
            {"label": "Al Gharith", "value": "C106649"},
            {"label": "Al Ghassalah", "value": "C106650"},
            {"label": "Al Ghulah", "value": "C106651"},
            {"label": "Al Hada", "value": "C106652"},
            {"label": "Al Halaqah", "value": "C106653"},
            {"label": "Al Hamimah", "value": "C106654"},
            {"label": "Al Harra, Makkah", "value": "C106655"},
            {"label": "Al Hawiyah", "value": "C106656"},
            {"label": "Al Iskan", "value": "C106657"},
            {"label": "Al Jadidah", "value": "C106658"},
            {"label": "Al Jami`ah", "value": "C106659"},
            {"label": "Al Jid`", "value": "C106660"},
            {"label": "Al Ju`ranah", "value": "C106661"},
            {"label": "Al Jumum", "value": "C106662"},
            {"label": "Al Khadra, Makkah", "value": "C106663"},
            {"label": "Al Khalas", "value": "C106664"},
            {"label": "Al Khamrah", "value": "C106665"},
            {"label": "Al Khaydar", "value": "C106666"},
            {"label": "Al Khayf", "value": "C106667"},
            {"label": "Al Khulasah", "value": "C106668"},
            {"label": "Al Kidwah", "value": "C106669"},
            {"label": "Al Kura`", "value": "C106670"},
            {"label": "Al Ma`rash", "value": "C106671"},
            {"label": "Al Madiq, Makkah", "value": "C106672"},
            {"label": "Al Maghal", "value": "C106673"},
            {"label": "Al Mahjar", "value": "C106674"},
            {"label": "Al Majaridah", "value": "C106675"},
            {"label": "Al Maqrah", "value": "C106676"},
            {"label": "Al Masarrah", "value": "C106677"},
            {"label": "Al Masfalah", "value": "C106678"},
            {"label": "Al Mashayikh", "value": "C106679"},
            {"label": "Al Mathnah", "value": "C106680"},
            {"label": "Al Mubarak", "value": "C106681"},
            {"label": "Al Mudawwarah", "value": "C106682"},
            {"label": "Al Mulayha", "value": "C106683"},
            {"label": "Al Mundassah", "value": "C106684"},
            {"label": "Al Muqayti`", "value": "C106685"},
            {"label": "Al Muqr", "value": "C106686"},
            {"label": "Al Muwayh", "value": "C106687"},
            {"label": "Al Qadimah", "value": "C106688"},
            {"label": "Al Qahab", "value": "C106689"},
            {"label": "Al Qararah", "value": "C106690"},
            {"label": "Al Qaryat", "value": "C106691"},
            {"label": "Al Qawba`iyah", "value": "C106692"},
            {"label": "Al Qirshan", "value": "C106693"},
            {"label": "Al Qu`tubah", "value": "C106694"},
            {"label": "Al Qufayf", "value": "C106695"},
            {"label": "Al Qushashiyah", "value": "C106696"},
            {"label": "Al Ukhaydir", "value": "C106697"},
            {"label": "Al Waht", "value": "C106698"},
            {"label": "Ar Rabwah as Sufla", "value": "C106699"},
            {"label": "Ar Rafah", "value": "C106700"},
            {"label": "Ar Rawdah ash Shamaliyah", "value": "C106701"},
            {"label": "Ar Rudaymah", "value": "C106702"},
            {"label": "Arya`", "value": "C106703"},
            {"label": "As Sadr", "value": "C106704"},
            {"label": "As Samd ash Shamali", "value": "C106705"},
            {"label": "As Sayl al Kabir", "value": "C106706"},
            {"label": "As Sayl as Saghir", "value": "C106707"},
            {"label": "As Sifyani", "value": "C106708"},
            {"label": "As Sudayrah, Makkah", "value": "C106709"},
            {"label": "As Suwadah", "value": "C106710"},
            {"label": "Ash Shafa", "value": "C106711"},
            {"label": "Ash Shajwah", "value": "C106712"},
            {"label": "Ash Shamiyah", "value": "C106713"},
            {"label": "Ash Sharai`", "value": "C106714"},
            {"label": "Ash Shaybi", "value": "C106715"},
            {"label": "Ash Shi`b", "value": "C106716"},
            {"label": "Ash Shishah", "value": "C106717"},
            {"label": "Ash Shumaysi", "value": "C106718"},
            {"label": "Ash Shuwaybit", "value": "C106719"},
            {"label": "At Tan`im", "value": "C106720"},
            {"label": "At Tarfa", "value": "C106721"},
            {"label": "At Turqi", "value": "C106722"},
            {"label": "Az Zaymah", "value": "C106723"},
            {"label": "Az Zilal", "value": "C106724"},
            {"label": "Az Zughbah", "value": "C106725"},
            {"label": "Az Zurra`", "value": "C106726"},
            {"label": "Az Zuwayb", "value": "C106727"},
            {"label": "Bahrat al Qadimah", "value": "C106728"},
            {"label": "Bahwil", "value": "C106729"},
            {"label": "Baranah", "value": "C106730"},
            {"label": "Barzah", "value": "C106731"},
            {"label": "Bashm", "value": "C106732"},
            {"label": "Buraykah", "value": "C106733"},
            {"label": "Burayman", "value": "C106734"},
            {"label": "CITY GHRAN", "value": "C106735"},
            {"label": "Dabyah", "value": "C106736"},
            {"label": "Dahaban", "value": "C106737"},
            {"label": "Dughaybjah", "value": "C106738"},
            {"label": "Fayd", "value": "C106739"},
            {"label": "Ghran", "value": "C106740"},
            {"label": "Hadda", "value": "C106741"},
            {"label": "Haddat ash Sham", "value": "C106742"},
            {"label": "Hadhah", "value": "C106743"},
            {"label": "Hajur", "value": "C106744"},
            {"label": "Halamah", "value": "C106745"},
            {"label": "Husnah", "value": "C106746"},
            {"label": "Jarwal", "value": "C106747"},
            {"label": "Jeddah", "value": "C106748"},
            {"label": "Julayyil", "value": "C106749"},
            {"label": "Khamis Mushait", "value": "C106750"},
            {"label": "Khumrah", "value": "C106751"},
            {"label": "Kulakh", "value": "C106752"},
            {"label": "Ma`riyah", "value": "C106753"},
            {"label": "Madrakah", "value": "C106754"},
            {"label": "Mafruq", "value": "C106755"},
            {"label": "Malakan", "value": "C106756"},
            {"label": "Mashajji", "value": "C106757"},
            {"label": "Masihat Mahd al Hayl", "value": "C106758"},
            {"label": "Maskar", "value": "C106759"},
            {"label": "Matiyah", "value": "C106760"},
            {"label": "Mecca", "value": "C106761"},
            {"label": "Mifa", "value": "C106762"},
            {"label": "Mina", "value": "C106763"},
            {"label": "Munayzir", "value": "C106764"},
            {"label": "Murshidiyah", "value": "C106765"},
            {"label": "Mushrif", "value": "C106766"},
            {"label": "Nughayshiyah", "value": "C106767"},
            {"label": "Nuzlat al Faqin", "value": "C106768"},
            {"label": "Qiya", "value": "C106769"},
            {"label": "Quwayzah", "value": "C106770"},
            {"label": "Rabigh", "value": "C106771"},
            {"label": "Rabwah Ghran", "value": "C106772"},
            {"label": "Raqiyah", "value": "C106773"},
            {"label": "Sabuhah", "value": "C106774"},
            {"label": "Shi`b `amir", "value": "C106775"},
            {"label": "Shira`ayn", "value": "C106776"},
            {"label": "Sulaym", "value": "C106777"},
            {"label": "Sumaymah", "value": "C106778"},
            {"label": "Suways", "value": "C106779"},
            {"label": "Taif", "value": "C106780"},
            {"label": "Tabalah", "value": "C106781"},
            {"label": "Tharwah", "value": "C106782"},
            {"label": "Thuwal", "value": "C106783"},
            {"label": "Turabah", "value": "C106784"},
            {"label": "Usfan", "value": "C106785"},
            {"label": "Wadi al Jalil", "value": "C106786"}],
 "Makueni": [{"label": "Makueni Boma", "value": "C64600"},
             {"label": "Mtito Andei", "value": "C64601"},
             {"label": "Wote", "value": "C64602"}],
 "Malacca": [{"label": "Alor Gajah", "value": "C65676"},
             {"label": "Batu Berendam", "value": "C65677"},
             {"label": "Bemban", "value": "C65678"},
             {"label": "Bukit Rambai", "value": "C65679"},
             {"label": "Kampong Masjid Tanah", "value": "C65680"},
             {"label": "Kampung Ayer Keroh", "value": "C65681"},
             {"label": "Kampung Ayer Molek", "value": "C65682"},
             {"label": "Kampung Bukit Baharu", "value": "C65683"},
             {"label": "Klebang Besar", "value": "C65684"},
             {"label": "Kuala Sungai Baru", "value": "C65685"},
             {"label": "Malacca", "value": "C65686"},
             {"label": "Pulau Sebang", "value": "C65687"},
             {"label": "Sungai Udang", "value": "C65688"}],
 "Malaita Province": [{"label": "Auki", "value": "C107877"}],
 "Malampa": [{"label": "Lakatoro", "value": "C147116"},
             {"label": "Norsup", "value": "C147117"}],
 "Malanje Province": [{"label": "Malanje", "value": "C565"}],
 "Malatya": [{"label": "Akcadag", "value": "C120684"},
             {"label": "Arapgir", "value": "C120685"},
             {"label": "Arguvan", "value": "C120686"},
             {"label": "Battalgazi", "value": "C120687"},
             {"label": "Darende", "value": "C120688"},
             {"label": "Dogansehir", "value": "C120689"},
             {"label": "Doganyol", "value": "C120690"},
             {"label": "Hekimhan", "value": "C120691"},
             {"label": "Kale", "value": "C120692"},
             {"label": "Kuluncak", "value": "C120693"},
             {"label": "Malatya", "value": "C120694"},
             {"label": "Puturge", "value": "C120695"},
             {"label": "Yazihan", "value": "C120696"},
             {"label": "Yesilyurt", "value": "C120697"}],
 "Maldonado Department": [{"label": "Aigua", "value": "C146894"},
                          {"label": "Maldonado", "value": "C146895"},
                          {"label": "Pan de Azucar", "value": "C146896"},
                          {"label": "Piriapolis", "value": "C146897"},
                          {"label": "Punta del Este", "value": "C146898"},
                          {"label": "San Carlos", "value": "C146899"}],
 "Malpils Municipality": [{"label": "Malpils", "value": "C64900"}],
 "Maluku": [{"label": "Amahai", "value": "C49695"},
            {"label": "Ambon", "value": "C49696"},
            {"label": "Kabupaten Buru", "value": "C49697"},
            {"label": "Kabupaten Buru Selatan", "value": "C49698"},
            {"label": "Kabupaten Kepulauan Aru", "value": "C49699"},
            {"label": "Kabupaten Maluku Barat Daya", "value": "C49700"},
            {"label": "Kabupaten Maluku Tengah", "value": "C49701"},
            {"label": "Kabupaten Maluku Tenggara", "value": "C49702"},
            {"label": "Kabupaten Maluku Tenggara Barat", "value": "C49703"},
            {"label": "Kabupaten Seram Bagian Barat", "value": "C49704"},
            {"label": "Kabupaten Seram Bagian Timur", "value": "C49705"},
            {"label": "Kota Ambon", "value": "C49706"},
            {"label": "Kota Tual", "value": "C49707"},
            {"label": "Tual", "value": "C49708"}],
 "Maluku Utara": [{"label": "East Halmahera Regency", "value": "C49709"},
                  {"label": "Kabupaten Halmahera Barat", "value": "C49710"},
                  {"label": "Kabupaten Halmahera Selatan", "value": "C49711"},
                  {"label": "Kabupaten Halmahera Tengah", "value": "C49712"},
                  {"label": "Kabupaten Halmahera Utara", "value": "C49713"},
                  {"label": "Kabupaten Kepulauan Sula", "value": "C49714"},
                  {"label": "Kabupaten Pulau Morotai", "value": "C49715"},
                  {"label": "Kabupaten Pulau Taliabu", "value": "C49716"},
                  {"label": "Kota Ternate", "value": "C49717"},
                  {"label": "Kota Tidore Kepulauan", "value": "C49718"},
                  {"label": "Sofifi", "value": "C49719"},
                  {"label": "Ternate", "value": "C49720"},
                  {"label": "Tobelo", "value": "C49721"}],
 "Mambere-Kadei": [{"label": "Berberati", "value": "C17823"},
                   {"label": "Carnot", "value": "C17824"},
                   {"label": "Gamboula", "value": "C17825"}],
 "Mamou Region": [{"label": "Dalaba", "value": "C43038"},
                  {"label": "Mamou", "value": "C43039"},
                  {"label": "Mamou Prefecture", "value": "C43040"},
                  {"label": "Pita", "value": "C43041"}],
 "Manabi": [{"label": "Bahia de Caraquez", "value": "C24164"},
            {"label": "Calceta", "value": "C24165"},
            {"label": "Canton Portoviejo", "value": "C24166"},
            {"label": "Chone", "value": "C24167"},
            {"label": "Jipijapa", "value": "C24168"},
            {"label": "Junin", "value": "C24169"},
            {"label": "Manta", "value": "C24170"},
            {"label": "Montecristi", "value": "C24171"},
            {"label": "Pajan", "value": "C24172"},
            {"label": "Pedernales", "value": "C24173"},
            {"label": "Portoviejo", "value": "C24174"},
            {"label": "Rocafuerte", "value": "C24175"},
            {"label": "San Vicente", "value": "C24176"},
            {"label": "Santa Ana", "value": "C24177"},
            {"label": "Sucre", "value": "C24178"},
            {"label": "Tosagua", "value": "C24179"}],
 "Managua": [{"label": "Ciudad Sandino", "value": "C77959"},
             {"label": "El Crucero", "value": "C77960"},
             {"label": "Managua", "value": "C77961"},
             {"label": "Masachapa", "value": "C77962"},
             {"label": "San Rafael del Sur", "value": "C77963"},
             {"label": "Ticuantepe", "value": "C77964"},
             {"label": "Tipitapa", "value": "C77965"},
             {"label": "Valle San Francisco", "value": "C77966"},
             {"label": "Villa El Carmen", "value": "C77967"}],
 "Manatuto District": [{"label": "Barique", "value": "C24077"},
                       {"label": "Laclo", "value": "C24078"},
                       {"label": "Laclubar", "value": "C24079"},
                       {"label": "Manatuto", "value": "C24080"},
                       {"label": "Manatutu", "value": "C24081"},
                       {"label": "Soibada", "value": "C24082"}],
 "Manawatu-Wanganui Region": [{"label": "Bulls", "value": "C77790"},
                              {"label": "Foxton", "value": "C77791"},
                              {"label": "Horowhenua District",
                               "value": "C77792"},
                              {"label": "Levin", "value": "C77793"},
                              {"label": "Manawatu District", "value": "C77794"},
                              {"label": "Palmerston North", "value": "C77795"},
                              {"label": "Waiouru", "value": "C77796"},
                              {"label": "Wanganui", "value": "C77797"}],
 "Manchester Parish": [{"label": "Alligator Pond", "value": "C61831"},
                       {"label": "AsiaPratville", "value": "C61832"},
                       {"label": "Auchtembeddie", "value": "C61833"},
                       {"label": "Bellefield", "value": "C61834"},
                       {"label": "Bethany", "value": "C61835"},
                       {"label": "Blue Mountain", "value": "C61836"},
                       {"label": "Bombay", "value": "C61837"},
                       {"label": "Butt-Up", "value": "C61838"},
                       {"label": "Cedar Grove", "value": "C61839"},
                       {"label": "Chantilly", "value": "C61840"},
                       {"label": "Christiana", "value": "C61841"},
                       {"label": "Chudleigh", "value": "C61842"},
                       {"label": "Cobbla", "value": "C61843"},
                       {"label": "Coleyville", "value": "C61844"},
                       {"label": "Comfort", "value": "C61845"},
                       {"label": "Comfort Hall", "value": "C61846"},
                       {"label": "Content", "value": "C61847"},
                       {"label": "Craig Head", "value": "C61848"},
                       {"label": "Cross Keys", "value": "C61849"},
                       {"label": "Devon", "value": "C61850"},
                       {"label": "Downs", "value": "C61851"},
                       {"label": "Ellen Street", "value": "C61852"},
                       {"label": "Evergreen", "value": "C61853"},
                       {"label": "Fairfield", "value": "C61854"},
                       {"label": "French Park", "value": "C61855"},
                       {"label": "Georges Valley", "value": "C61856"},
                       {"label": "Good Intent", "value": "C61857"},
                       {"label": "Greenvale", "value": "C61858"},
                       {"label": "Grove Place", "value": "C61859"},
                       {"label": "Grove Town", "value": "C61860"},
                       {"label": "Harmons", "value": "C61861"},
                       {"label": "Harry Watch", "value": "C61862"},
                       {"label": "Hatfield", "value": "C61863"},
                       {"label": "Heartease", "value": "C61864"},
                       {"label": "Hibernia", "value": "C61865"},
                       {"label": "Huntley", "value": "C61866"},
                       {"label": "Kendal", "value": "C61867"},
                       {"label": "Knockpatrick", "value": "C61868"},
                       {"label": "Lancaster", "value": "C61869"},
                       {"label": "Litchfield", "value": "C61870"},
                       {"label": "Maidstone", "value": "C61871"},
                       {"label": "Mandeville", "value": "C61872"},
                       {"label": "Mandeville Proper", "value": "C61873"},
                       {"label": "Marlie Hill", "value": "C61874"},
                       {"label": "May Day", "value": "C61875"},
                       {"label": "Medina", "value": "C61876"},
                       {"label": "Mike Town", "value": "C61877"},
                       {"label": "Mile Gully", "value": "C61878"},
                       {"label": "New Forest", "value": "C61879"},
                       {"label": "New Green", "value": "C61880"},
                       {"label": "Newport", "value": "C61881"},
                       {"label": "Old England", "value": "C61882"},
                       {"label": "Part Of Banana Ground", "value": "C61883"},
                       {"label": "Part Of Gutters", "value": "C61884"},
                       {"label": "Part of Spaldings", "value": "C61885"},
                       {"label": "Pike", "value": "C61886"},
                       {"label": "Plowden", "value": "C61887"},
                       {"label": "Porus", "value": "C61888"},
                       {"label": "Prospect", "value": "C61889"},
                       {"label": "Richmond", "value": "C61890"},
                       {"label": "Robins Hall", "value": "C61891"},
                       {"label": "Rose Hill", "value": "C61892"},
                       {"label": "Royal Flat", "value": "C61893"},
                       {"label": "Scotts Pass", "value": "C61894"},
                       {"label": "Snowdon", "value": "C61895"},
                       {"label": "Somerset", "value": "C61896"},
                       {"label": "Spring Ground", "value": "C61897"},
                       {"label": "Spur Tree", "value": "C61898"},
                       {"label": "St. Pauls", "value": "C61899"},
                       {"label": "Swabys Hope", "value": "C61900"},
                       {"label": "Top Hill", "value": "C61901"},
                       {"label": "Victoria Town", "value": "C61902"},
                       {"label": "Walderston", "value": "C61903"},
                       {"label": "Watham", "value": "C61904"},
                       {"label": "Williamsfield", "value": "C61905"}],
 "Mandalay Region": [{"label": "Kyaukse", "value": "C75931"},
                     {"label": "Mandalay", "value": "C75932"},
                     {"label": "Meiktila", "value": "C75933"},
                     {"label": "Mogok", "value": "C75934"},
                     {"label": "Myingyan", "value": "C75935"},
                     {"label": "Nyaungshwe", "value": "C75936"},
                     {"label": "Pyin Oo Lwin", "value": "C75937"},
                     {"label": "Yamethin", "value": "C75938"}],
 "Mandera": [{"label": "Mandera", "value": "C64603"}],
 "Mandoul Region": [{"label": "Goundi", "value": "C17871"},
                    {"label": "Koumra", "value": "C17872"},
                    {"label": "Moissala", "value": "C17873"}],
 "Mangystau Region": [{"label": "Aktau", "value": "C64460"},
                      {"label": "Bautino", "value": "C64461"},
                      {"label": "Beyneu", "value": "C64462"},
                      {"label": "Fort-Shevchenko", "value": "C64463"},
                      {"label": "Munayshy", "value": "C64464"},
                      {"label": "Omirzaq", "value": "C64465"},
                      {"label": "Sayotesh", "value": "C64466"},
                      {"label": "Shetpe", "value": "C64467"},
                      {"label": "Taushyq", "value": "C64468"},
                      {"label": "Yeraliyev", "value": "C64469"},
                      {"label": "Zhanaozen", "value": "C64470"},
                      {"label": "Zhetibay", "value": "C64471"}],
 "Manica Province": [{"label": "Chimoio", "value": "C75862"}],
 "Manicaland": [{"label": "Buhera District", "value": "C148162"},
                {"label": "Chimanimani", "value": "C148163"},
                {"label": "Chimanimani District", "value": "C148164"},
                {"label": "Chipinge", "value": "C148165"},
                {"label": "Chipinge District", "value": "C148166"},
                {"label": "Dorowa Mining Lease", "value": "C148167"},
                {"label": "Headlands", "value": "C148168"},
                {"label": "Makoni District", "value": "C148169"},
                {"label": "Mutare", "value": "C148170"},
                {"label": "Mutare District", "value": "C148171"},
                {"label": "Mutasa District", "value": "C148172"},
                {"label": "Nyanga", "value": "C148173"},
                {"label": "Nyanga District", "value": "C148174"},
                {"label": "Nyazura", "value": "C148175"},
                {"label": "Odzi", "value": "C148176"},
                {"label": "Penhalonga", "value": "C148177"},
                {"label": "Rusape", "value": "C148178"}],
 "Maniema": [{"label": "Kampene", "value": "C23352"},
             {"label": "Kasongo", "value": "C23353"},
             {"label": "Kindu", "value": "C23354"}],
 "Manipur": [{"label": "Bishnupur", "value": "C47254"},
             {"label": "Churachandpur", "value": "C47255"},
             {"label": "Imphal", "value": "C47256"},
             {"label": "Kakching", "value": "C47257"},
             {"label": "Mayang Imphal", "value": "C47258"},
             {"label": "Moirang", "value": "C47259"},
             {"label": "Phek", "value": "C47260"},
             {"label": "Senapati", "value": "C47261"},
             {"label": "Tamenglong", "value": "C47262"},
             {"label": "Thoubal", "value": "C47263"},
             {"label": "Ukhrul", "value": "C47264"},
             {"label": "Wangjing", "value": "C47265"},
             {"label": "Yairipok", "value": "C47266"}],
 "Manisa": [{"label": "Ahmetli", "value": "C120698"},
            {"label": "Akhisar", "value": "C120699"},
            {"label": "Alasehir", "value": "C120700"},
            {"label": "Demirci", "value": "C120701"},
            {"label": "Golmarmara", "value": "C120702"},
            {"label": "Gordes", "value": "C120703"},
            {"label": "Kirkagac", "value": "C120704"},
            {"label": "Koprubasi", "value": "C120705"},
            {"label": "Kula", "value": "C120706"},
            {"label": "Manisa", "value": "C120707"},
            {"label": "Salihli Ilcesi", "value": "C120708"},
            {"label": "Sarigol", "value": "C120709"},
            {"label": "Saruhanli", "value": "C120710"},
            {"label": "Sehzadeler", "value": "C120711"},
            {"label": "Selendi", "value": "C120712"},
            {"label": "Soma", "value": "C120713"},
            {"label": "Turgutlu Ilcesi", "value": "C120714"},
            {"label": "Yunusemre", "value": "C120715"},
            {"label": "Zeytinliova", "value": "C120716"}],
 "Manitoba": [{"label": "Altona", "value": "C16958"},
              {"label": "Beausejour", "value": "C16959"},
              {"label": "Boissevain", "value": "C16960"},
              {"label": "Brandon", "value": "C16961"},
              {"label": "Carberry", "value": "C16962"},
              {"label": "Carman", "value": "C16963"},
              {"label": "Cross Lake 19A", "value": "C16964"},
              {"label": "Dauphin", "value": "C16965"},
              {"label": "De Salaberry", "value": "C16966"},
              {"label": "Deloraine", "value": "C16967"},
              {"label": "Flin Flon", "value": "C16968"},
              {"label": "Gimli", "value": "C16969"},
              {"label": "Grunthal", "value": "C16970"},
              {"label": "Headingley", "value": "C16971"},
              {"label": "Ile des Chenes", "value": "C16972"},
              {"label": "Killarney", "value": "C16973"},
              {"label": "La Broquerie", "value": "C16974"},
              {"label": "Lac du Bonnet", "value": "C16975"},
              {"label": "Landmark", "value": "C16976"},
              {"label": "Lorette", "value": "C16977"},
              {"label": "Melita", "value": "C16978"},
              {"label": "Minnedosa", "value": "C16979"},
              {"label": "Moose Lake", "value": "C16980"},
              {"label": "Morden", "value": "C16981"},
              {"label": "Morris", "value": "C16982"},
              {"label": "Neepawa", "value": "C16983"},
              {"label": "Niverville", "value": "C16984"},
              {"label": "Portage la Prairie", "value": "C16985"},
              {"label": "Rivers", "value": "C16986"},
              {"label": "Roblin", "value": "C16987"},
              {"label": "Selkirk", "value": "C16988"},
              {"label": "Shilo", "value": "C16989"},
              {"label": "Souris", "value": "C16990"},
              {"label": "St. Adolphe", "value": "C16991"},
              {"label": "Steinbach", "value": "C16992"},
              {"label": "Stonewall", "value": "C16993"},
              {"label": "Swan River", "value": "C16994"},
              {"label": "The Pas", "value": "C16995"},
              {"label": "Thompson", "value": "C16996"},
              {"label": "Virden", "value": "C16997"},
              {"label": "West St. Paul", "value": "C16998"},
              {"label": "Winkler", "value": "C16999"},
              {"label": "Winnipeg", "value": "C17000"}],
 "Manouba Governorate": [{"label": "El Battan", "value": "C119746"},
                         {"label": "Manouba", "value": "C119747"},
                         {"label": "Mutamadiyat Manubah", "value": "C119748"},
                         {"label": "Oued Lill", "value": "C119749"}],
 "Manufahi Municipality": [{"label": "Alas", "value": "C24083"},
                           {"label": "Fatuberliu", "value": "C24084"},
                           {"label": "Same", "value": "C24085"},
                           {"label": "Turiscai", "value": "C24086"}],
 "Manus Province": [{"label": "Lorengau", "value": "C80435"},
                    {"label": "Manus", "value": "C80436"}],
 "Manyara": [{"label": "Babati", "value": "C118145"},
             {"label": "Bashanet", "value": "C118146"},
             {"label": "Basotu", "value": "C118147"},
             {"label": "Dareda", "value": "C118148"},
             {"label": "Dongobesh", "value": "C118149"},
             {"label": "Endasak", "value": "C118150"},
             {"label": "Galappo", "value": "C118151"},
             {"label": "Katesh", "value": "C118152"},
             {"label": "Kibaya", "value": "C118153"},
             {"label": "Kirya", "value": "C118154"},
             {"label": "Magugu", "value": "C118155"},
             {"label": "Mbulu", "value": "C118156"},
             {"label": "Naberera", "value": "C118157"},
             {"label": "Nangwa", "value": "C118158"}],
 "Manzini District": [{"label": "Bhunya", "value": "C115487"},
                      {"label": "Ekukhanyeni", "value": "C115488"},
                      {"label": "Kwaluseni", "value": "C115489"},
                      {"label": "Malkerns", "value": "C115490"},
                      {"label": "Manzini", "value": "C115491"},
                      {"label": "Manzini South", "value": "C115492"},
                      {"label": "Mhlambanyatsi", "value": "C115493"},
                      {"label": "Ngwempisi", "value": "C115494"},
                      {"label": "Ntondozi", "value": "C115495"},
                      {"label": "Sidvokodvo", "value": "C115496"}],
 "Maputo": [{"label": "KaTembe", "value": "C75863"},
            {"label": "Maputo", "value": "C75864"}],
 "Maputo Province": [{"label": "Boane District", "value": "C75865"},
                     {"label": "Concelho de Matola", "value": "C75866"},
                     {"label": "Magude District", "value": "C75867"},
                     {"label": "Manhica", "value": "C75868"},
                     {"label": "Marracuene District", "value": "C75869"},
                     {"label": "Matola", "value": "C75870"},
                     {"label": "Matutiune District", "value": "C75871"},
                     {"label": "Moamba District", "value": "C75872"},
                     {"label": "Namaacha District", "value": "C75873"},
                     {"label": "Ressano Garcia", "value": "C75874"}],
 "Mara": [{"label": "Bukonyo", "value": "C118159"},
          {"label": "Butiama", "value": "C118160"},
          {"label": "Issenye", "value": "C118161"},
          {"label": "Kibara", "value": "C118162"},
          {"label": "Mugango", "value": "C118163"},
          {"label": "Mugumu", "value": "C118164"},
          {"label": "Muriti", "value": "C118165"},
          {"label": "Musoma", "value": "C118166"},
          {"label": "Nakatunguru", "value": "C118167"},
          {"label": "Nansio", "value": "C118168"},
          {"label": "Nyamuswa", "value": "C118169"},
          {"label": "Sirari", "value": "C118170"}],
 "Maradi Region": [{"label": "Aguie", "value": "C78062"},
                   {"label": "Dakoro", "value": "C78063"},
                   {"label": "Departement dAguie", "value": "C78064"},
                   {"label": "Departement de Dakoro", "value": "C78065"},
                   {"label": "Departement de Mayahi", "value": "C78066"},
                   {"label": "Departement de Tessaoua", "value": "C78067"},
                   {"label": "Guidan Roumdji Department", "value": "C78068"},
                   {"label": "Madarounfa", "value": "C78069"},
                   {"label": "Maradi", "value": "C78070"},
                   {"label": "Mayahi", "value": "C78071"},
                   {"label": "Tessaoua", "value": "C78072"},
                   {"label": "Tibiri", "value": "C78073"}],
 "Maramures County": [{"label": "Ardusat", "value": "C96559"},
                      {"label": "Arduzel", "value": "C96560"},
                      {"label": "Ariesu de Camp", "value": "C96561"},
                      {"label": "Ariesu de Padure", "value": "C96562"},
                      {"label": "Arinis", "value": "C96563"},
                      {"label": "Aspra", "value": "C96564"},
                      {"label": "Asuaju de Jos", "value": "C96565"},
                      {"label": "Asuaju de Sus", "value": "C96566"},
                      {"label": "Baba", "value": "C96567"},
                      {"label": "Baia Mare", "value": "C96568"},
                      {"label": "Baia Sprie", "value": "C96569"},
                      {"label": "Baile Borsa", "value": "C96570"},
                      {"label": "Baita", "value": "C96571"},
                      {"label": "Baita de sub Codru", "value": "C96572"},
                      {"label": "Baiut", "value": "C96573"},
                      {"label": "Bargau", "value": "C96574"},
                      {"label": "Barsana", "value": "C96575"},
                      {"label": "Basesti", "value": "C96576"},
                      {"label": "Berbesti", "value": "C96577"},
                      {"label": "Berchez", "value": "C96578"},
                      {"label": "Berinta", "value": "C96579"},
                      {"label": "Bicaz", "value": "C96580"},
                      {"label": "Bistra", "value": "C96581"},
                      {"label": "Blidari", "value": "C96582"},
                      {"label": "Bocicoel", "value": "C96583"},
                      {"label": "Bocicoiu Mare", "value": "C96584"},
                      {"label": "Bogdan Voda", "value": "C96585"},
                      {"label": "Boiereni", "value": "C96586"},
                      {"label": "Boiu Mare", "value": "C96587"},
                      {"label": "Bontaieni", "value": "C96588"},
                      {"label": "Borcut", "value": "C96589"},
                      {"label": "Borsa", "value": "C96590"},
                      {"label": "Botiza", "value": "C96591"},
                      {"label": "Bozanta Mare", "value": "C96592"},
                      {"label": "Bozanta Mica", "value": "C96593"},
                      {"label": "Breb", "value": "C96594"},
                      {"label": "Brebeni", "value": "C96595"},
                      {"label": "Buciumi", "value": "C96596"},
                      {"label": "Budesti", "value": "C96597"},
                      {"label": "Busag", "value": "C96598"},
                      {"label": "Buteasa", "value": "C96599"},
                      {"label": "Buzesti", "value": "C96600"},
                      {"label": "Calinesti", "value": "C96601"},
                      {"label": "Campulung la Tisa", "value": "C96602"},
                      {"label": "Carbunari", "value": "C96603"},
                      {"label": "Carpinis", "value": "C96604"},
                      {"label": "Catalina", "value": "C96605"},
                      {"label": "Cavnic", "value": "C96606"},
                      {"label": "Cernesti", "value": "C96607"},
                      {"label": "Cetatele", "value": "C96608"},
                      {"label": "Chechis", "value": "C96609"},
                      {"label": "Chelinta", "value": "C96610"},
                      {"label": "Chiuzbaia", "value": "C96611"},
                      {"label": "Cicarlau", "value": "C96612"},
                      {"label": "Ciocotis", "value": "C96613"},
                      {"label": "Ciolt", "value": "C96614"},
                      {"label": "Ciuta", "value": "C96615"},
                      {"label": "Coas", "value": "C96616"},
                      {"label": "Codru Butesii", "value": "C96617"},
                      {"label": "Coltau", "value": "C96618"},
                      {"label": "Coltirea", "value": "C96619"},
                      {"label": "Comuna Ardusat", "value": "C96620"},
                      {"label": "Comuna Arinis", "value": "C96621"},
                      {"label": "Comuna Asuaju de Sus", "value": "C96622"},
                      {"label": "Comuna Baita de sub Codru", "value": "C96623"},
                      {"label": "Comuna Baiut", "value": "C96624"},
                      {"label": "Comuna Barsana", "value": "C96625"},
                      {"label": "Comuna Basesti", "value": "C96626"},
                      {"label": "Comuna Bicaz", "value": "C96627"},
                      {"label": "Comuna Bistra", "value": "C96628"},
                      {"label": "Comuna Bocicoiu Mare", "value": "C96629"},
                      {"label": "Comuna Bogdan Voda", "value": "C96630"},
                      {"label": "Comuna Boiu Mare", "value": "C96631"},
                      {"label": "Comuna Botiza", "value": "C96632"},
                      {"label": "Comuna Budesti", "value": "C96633"},
                      {"label": "Comuna Calinesti", "value": "C96634"},
                      {"label": "Comuna Campulung la Tisa", "value": "C96635"},
                      {"label": "Comuna Cernesti", "value": "C96636"},
                      {"label": "Comuna Cicarlau", "value": "C96637"},
                      {"label": "Comuna Coas", "value": "C96638"},
                      {"label": "Comuna Coltau", "value": "C96639"},
                      {"label": "Comuna Copalnic Manastur", "value": "C96640"},
                      {"label": "Comuna Coroieni", "value": "C96641"},
                      {"label": "Comuna Cupseni", "value": "C96642"},
                      {"label": "Comuna Desesti", "value": "C96643"},
                      {"label": "Comuna Dumbravita", "value": "C96644"},
                      {"label": "Comuna Farcasa", "value": "C96645"},
                      {"label": "Comuna Gardani", "value": "C96646"},
                      {"label": "Comuna Giulesti", "value": "C96647"},
                      {"label": "Comuna Grosi", "value": "C96648"},
                      {"label": "Comuna Grosii Tiblesului", "value": "C96649"},
                      {"label": "Comuna Ieud", "value": "C96650"},
                      {"label": "Comuna Lapus", "value": "C96651"},
                      {"label": "Comuna Leordina", "value": "C96652"},
                      {"label": "Comuna Miresu Mare", "value": "C96653"},
                      {"label": "Comuna Moisei", "value": "C96654"},
                      {"label": "Comuna Oarta De Jos", "value": "C96655"},
                      {"label": "Comuna Ocna Sugatag", "value": "C96656"},
                      {"label": "Comuna Oncesti", "value": "C96657"},
                      {"label": "Comuna Petrova", "value": "C96658"},
                      {"label": "Comuna Poienile de sub Munte",
                       "value": "C96659"},
                      {"label": "Comuna Poienile Izei", "value": "C96660"},
                      {"label": "Comuna Recea", "value": "C96661"},
                      {"label": "Comuna Remetea Chioarului", "value": "C96662"},
                      {"label": "Comuna Remeti", "value": "C96663"},
                      {"label": "Comuna Repedea", "value": "C96664"},
                      {"label": "Comuna Rona de Jos", "value": "C96665"},
                      {"label": "Comuna Rona de Sus", "value": "C96666"},
                      {"label": "Comuna Rozavlea", "value": "C96667"},
                      {"label": "Comuna Ruscova", "value": "C96668"},
                      {"label": "Comuna Sacalaseni", "value": "C96669"},
                      {"label": "Comuna Sacel", "value": "C96670"},
                      {"label": "Comuna Salsig", "value": "C96671"},
                      {"label": "Comuna Sapanta", "value": "C96672"},
                      {"label": "Comuna Sarasau", "value": "C96673"},
                      {"label": "Comuna Satulung", "value": "C96674"},
                      {"label": "Comuna Sieu", "value": "C96675"},
                      {"label": "Comuna Sisesti", "value": "C96676"},
                      {"label": "Comuna Stramtura", "value": "C96677"},
                      {"label": "Comuna Suciu de Sus", "value": "C96678"},
                      {"label": "Comuna Vadu Izei", "value": "C96679"},
                      {"label": "Comuna Valea Chioarului", "value": "C96680"},
                      {"label": "Comuna Vima Mica", "value": "C96681"},
                      {"label": "Comuna Viseu de Jos", "value": "C96682"},
                      {"label": "Copalnic", "value": "C96683"},
                      {"label": "Copalnic Manastur", "value": "C96684"},
                      {"label": "Cornesti", "value": "C96685"},
                      {"label": "Corni", "value": "C96686"},
                      {"label": "Coroieni", "value": "C96687"},
                      {"label": "Coruia", "value": "C96688"},
                      {"label": "Costeni", "value": "C96689"},
                      {"label": "Costiui", "value": "C96690"},
                      {"label": "Craciunesti", "value": "C96691"},
                      {"label": "Crasna Viseului", "value": "C96692"},
                      {"label": "Cufoaia", "value": "C96693"},
                      {"label": "Culcea", "value": "C96694"},
                      {"label": "Cupseni", "value": "C96695"},
                      {"label": "Curtuiusu Mare", "value": "C96696"},
                      {"label": "Curtuiusu Mic", "value": "C96697"},
                      {"label": "Damacuseni", "value": "C96698"},
                      {"label": "Danesti", "value": "C96699"},
                      {"label": "Danestii Chioarului", "value": "C96700"},
                      {"label": "Dealu Corbului", "value": "C96701"},
                      {"label": "Dealu Mare", "value": "C96702"},
                      {"label": "Desesti", "value": "C96703"},
                      {"label": "Dobricu Lapusului", "value": "C96704"},
                      {"label": "Draghia", "value": "C96705"},
                      {"label": "Dragomiresti", "value": "C96706"},
                      {"label": "Dumbrava", "value": "C96707"},
                      {"label": "Dumbravita", "value": "C96708"},
                      {"label": "Durusa", "value": "C96709"},
                      {"label": "Fanate", "value": "C96710"},
                      {"label": "Fantanele", "value": "C96711"},
                      {"label": "Farcasa", "value": "C96712"},
                      {"label": "Fauresti", "value": "C96713"},
                      {"label": "Feresti", "value": "C96714"},
                      {"label": "Fericea", "value": "C96715"},
                      {"label": "Fersig", "value": "C96716"},
                      {"label": "Finteusu Mare", "value": "C96717"},
                      {"label": "Finteusu Mic", "value": "C96718"},
                      {"label": "Firiza", "value": "C96719"},
                      {"label": "Francenii Boiului", "value": "C96720"},
                      {"label": "Gardani", "value": "C96721"},
                      {"label": "Giulesti", "value": "C96722"},
                      {"label": "Glod", "value": "C96723"},
                      {"label": "Groape", "value": "C96724"},
                      {"label": "Grosi", "value": "C96725"},
                      {"label": "Grosii Tiblesului", "value": "C96726"},
                      {"label": "Handalu Ilbei", "value": "C96727"},
                      {"label": "Harnicesti", "value": "C96728"},
                      {"label": "Hideaga", "value": "C96729"},
                      {"label": "Hoteni", "value": "C96730"},
                      {"label": "Hovrila", "value": "C96731"},
                      {"label": "Iadara", "value": "C96732"},
                      {"label": "Iapa", "value": "C96733"},
                      {"label": "Ieud", "value": "C96734"},
                      {"label": "Ilba", "value": "C96735"},
                      {"label": "Inau", "value": "C96736"},
                      {"label": "Intrerauri", "value": "C96737"},
                      {"label": "Izvoarele", "value": "C96738"},
                      {"label": "Jugastreni", "value": "C96739"},
                      {"label": "Lapus", "value": "C96740"},
                      {"label": "Lapusel", "value": "C96741"},
                      {"label": "Larga", "value": "C96742"},
                      {"label": "Laschia", "value": "C96743"},
                      {"label": "Lazu Baciului", "value": "C96744"},
                      {"label": "Leordina", "value": "C96745"},
                      {"label": "Libotin", "value": "C96746"},
                      {"label": "Lucacesti", "value": "C96747"},
                      {"label": "Lunca la Tisa", "value": "C96748"},
                      {"label": "Magureni", "value": "C96749"},
                      {"label": "Manastirea", "value": "C96750"},
                      {"label": "Manau", "value": "C96751"},
                      {"label": "Mara", "value": "C96752"},
                      {"label": "Merisor", "value": "C96753"},
                      {"label": "Mesteacan", "value": "C96754"},
                      {"label": "Miresu Mare", "value": "C96755"},
                      {"label": "Mocira", "value": "C96756"},
                      {"label": "Mogosesti", "value": "C96757"},
                      {"label": "Moisei", "value": "C96758"},
                      {"label": "Nanesti", "value": "C96759"},
                      {"label": "Negreia", "value": "C96760"},
                      {"label": "Nistru", "value": "C96761"},
                      {"label": "Oarta de Jos", "value": "C96762"},
                      {"label": "Oarta de Sus", "value": "C96763"},
                      {"label": "Ocna Sugatag", "value": "C96764"},
                      {"label": "Ocolis", "value": "C96765"},
                      {"label": "Odesti", "value": "C96766"},
                      {"label": "Oncesti", "value": "C96767"},
                      {"label": "Ortita", "value": "C96768"},
                      {"label": "Peteritea", "value": "C96769"},
                      {"label": "Petrova", "value": "C96770"},
                      {"label": "Piatra", "value": "C96771"},
                      {"label": "Plopis", "value": "C96772"},
                      {"label": "Poiana Botizii", "value": "C96773"},
                      {"label": "Poienile de sub Munte", "value": "C96774"},
                      {"label": "Poienile Izei", "value": "C96775"},
                      {"label": "Posta", "value": "C96776"},
                      {"label": "Preluca Noua", "value": "C96777"},
                      {"label": "Preluca Veche", "value": "C96778"},
                      {"label": "Pribilesti", "value": "C96779"},
                      {"label": "Prislop", "value": "C96780"},
                      {"label": "Razoare", "value": "C96781"},
                      {"label": "Recea", "value": "C96782"},
                      {"label": "Remecioara", "value": "C96783"},
                      {"label": "Remetea Chioarului", "value": "C96784"},
                      {"label": "Remeti", "value": "C96785"},
                      {"label": "Remeti pe Somes", "value": "C96786"},
                      {"label": "Repedea", "value": "C96787"},
                      {"label": "Rodina", "value": "C96788"},
                      {"label": "Rogoz", "value": "C96789"},
                      {"label": "Rohia", "value": "C96790"},
                      {"label": "Romanesti", "value": "C96791"},
                      {"label": "Rona de Jos", "value": "C96792"},
                      {"label": "Rona de Sus", "value": "C96793"},
                      {"label": "Rozavlea", "value": "C96794"},
                      {"label": "Rus", "value": "C96795"},
                      {"label": "Ruscova", "value": "C96796"},
                      {"label": "Rusor", "value": "C96797"},
                      {"label": "Sabisa", "value": "C96798"},
                      {"label": "Sacalaseni", "value": "C96799"},
                      {"label": "Sacel", "value": "C96800"},
                      {"label": "Saliste", "value": "C96801"},
                      {"label": "Salistea de Sus", "value": "C96802"},
                      {"label": "Salnita", "value": "C96803"},
                      {"label": "Salsig", "value": "C96804"},
                      {"label": "Salta", "value": "C96805"},
                      {"label": "Sapanta", "value": "C96806"},
                      {"label": "Sarasau", "value": "C96807"},
                      {"label": "Sarbi", "value": "C96808"},
                      {"label": "Sasar", "value": "C96809"},
                      {"label": "Sat-Sugatag", "value": "C96810"},
                      {"label": "Satu Nou de Jos", "value": "C96811"},
                      {"label": "Satu Nou de Sus", "value": "C96812"},
                      {"label": "Satulung", "value": "C96813"},
                      {"label": "Seini", "value": "C96814"},
                      {"label": "Sieu", "value": "C96815"},
                      {"label": "Sighetu Marmatiei", "value": "C96816"},
                      {"label": "Sindresti", "value": "C96817"},
                      {"label": "Sisesti", "value": "C96818"},
                      {"label": "Slatioara", "value": "C96819"},
                      {"label": "Somcuta Mare", "value": "C96820"},
                      {"label": "Somes-Uileac", "value": "C96821"},
                      {"label": "Stejera", "value": "C96822"},
                      {"label": "Stoiceni", "value": "C96823"},
                      {"label": "Strambu-Baiut", "value": "C96824"},
                      {"label": "Stramtura", "value": "C96825"},
                      {"label": "Stremt", "value": "C96826"},
                      {"label": "Suciu de Jos", "value": "C96827"},
                      {"label": "Suciu de Sus", "value": "C96828"},
                      {"label": "Sugau", "value": "C96829"},
                      {"label": "Surdesti", "value": "C96830"},
                      {"label": "Tamaia", "value": "C96831"},
                      {"label": "Tamasesti", "value": "C96832"},
                      {"label": "Targu Lapus", "value": "C96833"},
                      {"label": "Tautii de Sus", "value": "C96834"},
                      {"label": "Tautii-Magheraus", "value": "C96835"},
                      {"label": "Teceu Mic", "value": "C96836"},
                      {"label": "Ticau", "value": "C96837"},
                      {"label": "Tisa", "value": "C96838"},
                      {"label": "tohat", "value": "C96839"},
                      {"label": "Trestia", "value": "C96840"},
                      {"label": "Tulghies", "value": "C96841"},
                      {"label": "Ulmeni", "value": "C96842"},
                      {"label": "Ulmoasa", "value": "C96843"},
                      {"label": "Unguras", "value": "C96844"},
                      {"label": "Ungureni", "value": "C96845"},
                      {"label": "Urmenis", "value": "C96846"},
                      {"label": "Vad", "value": "C96847"},
                      {"label": "Vadu Izei", "value": "C96848"},
                      {"label": "Valea Chioarului", "value": "C96849"},
                      {"label": "Valea Cufundoasa", "value": "C96850"},
                      {"label": "Valea Hotarului", "value": "C96851"},
                      {"label": "Valea Neagra", "value": "C96852"},
                      {"label": "Valea Stejarului", "value": "C96853"},
                      {"label": "Valea Viseului", "value": "C96854"},
                      {"label": "Valeni", "value": "C96855"},
                      {"label": "Valenii Lapusului", "value": "C96856"},
                      {"label": "Valenii Somcutei", "value": "C96857"},
                      {"label": "Varai", "value": "C96858"},
                      {"label": "Vicea", "value": "C96859"},
                      {"label": "Viile Apei", "value": "C96860"},
                      {"label": "Vima Mare", "value": "C96861"},
                      {"label": "Vima Mica", "value": "C96862"},
                      {"label": "Viseu de Jos", "value": "C96863"},
                      {"label": "Viseu de Mijloc", "value": "C96864"},
                      {"label": "Viseu De Sus", "value": "C96865"}],
 "Maranhao": [{"label": "Acailandia", "value": "C11334"},
              {"label": "Afonso Cunha", "value": "C11335"},
              {"label": "agua Doce do Maranhao", "value": "C11336"},
              {"label": "Alcantara", "value": "C11337"},
              {"label": "Aldeias Altas", "value": "C11338"},
              {"label": "Altamira do Maranhao", "value": "C11339"},
              {"label": "Alto Alegre do Maranhao", "value": "C11340"},
              {"label": "Alto Alegre do Pindare", "value": "C11341"},
              {"label": "Alto Parnaiba", "value": "C11342"},
              {"label": "Amapa do Maranhao", "value": "C11343"},
              {"label": "Amarante do Maranhao", "value": "C11344"},
              {"label": "Anajatuba", "value": "C11345"},
              {"label": "Anapurus", "value": "C11346"},
              {"label": "Apicum-Acu", "value": "C11347"},
              {"label": "Araguana", "value": "C11348"},
              {"label": "Araioses", "value": "C11349"},
              {"label": "Arame", "value": "C11350"},
              {"label": "Arari", "value": "C11351"},
              {"label": "Atins", "value": "C11352"},
              {"label": "Axixa", "value": "C11353"},
              {"label": "Bacabal", "value": "C11354"},
              {"label": "Bacabeira", "value": "C11355"},
              {"label": "Bacuri", "value": "C11356"},
              {"label": "Bacurituba", "value": "C11357"},
              {"label": "Balsas", "value": "C11358"},
              {"label": "Barao de Grajau", "value": "C11359"},
              {"label": "Barra do Corda", "value": "C11360"},
              {"label": "Barreirinhas", "value": "C11361"},
              {"label": "Bela Vista do Maranhao", "value": "C11362"},
              {"label": "Belagua", "value": "C11363"},
              {"label": "Benedito Leite", "value": "C11364"},
              {"label": "Bequimao", "value": "C11365"},
              {"label": "Bernardo do Mearim", "value": "C11366"},
              {"label": "Boa Vista do Gurupi", "value": "C11367"},
              {"label": "Bom Jardim", "value": "C11368"},
              {"label": "Bom Jesus das Selvas", "value": "C11369"},
              {"label": "Bom Lugar", "value": "C11370"},
              {"label": "Brejo", "value": "C11371"},
              {"label": "Brejo de Areia", "value": "C11372"},
              {"label": "Buriti", "value": "C11373"},
              {"label": "Buriti Bravo", "value": "C11374"},
              {"label": "Buriticupu", "value": "C11375"},
              {"label": "Buritirana", "value": "C11376"},
              {"label": "Cachoeira Grande", "value": "C11377"},
              {"label": "Cajapio", "value": "C11378"},
              {"label": "Cajari", "value": "C11379"},
              {"label": "Campestre do Maranhao", "value": "C11380"},
              {"label": "Candido Mendes", "value": "C11381"},
              {"label": "Cantanhede", "value": "C11382"},
              {"label": "Capinzal do Norte", "value": "C11383"},
              {"label": "Carolina", "value": "C11384"},
              {"label": "Carutapera", "value": "C11385"},
              {"label": "Caxias", "value": "C11386"},
              {"label": "Cedral", "value": "C11387"},
              {"label": "Central do Maranhao", "value": "C11388"},
              {"label": "Centro do Guilherme", "value": "C11389"},
              {"label": "Centro Novo do Maranhao", "value": "C11390"},
              {"label": "Chapadinha", "value": "C11391"},
              {"label": "Cidelandia", "value": "C11392"},
              {"label": "Codo", "value": "C11393"},
              {"label": "Coelho Neto", "value": "C11394"},
              {"label": "Colinas", "value": "C11395"},
              {"label": "Conceicao do Lago-Acu", "value": "C11396"},
              {"label": "Coroata", "value": "C11397"},
              {"label": "Cururupu", "value": "C11398"},
              {"label": "Davinopolis", "value": "C11399"},
              {"label": "Dom Pedro", "value": "C11400"},
              {"label": "Duque Bacelar", "value": "C11401"},
              {"label": "Esperantinopolis", "value": "C11402"},
              {"label": "Estreito", "value": "C11403"},
              {"label": "Feira Nova do Maranhao", "value": "C11404"},
              {"label": "Fernando Falcao", "value": "C11405"},
              {"label": "Formosa da Serra Negra", "value": "C11406"},
              {"label": "Fortaleza dos Nogueiras", "value": "C11407"},
              {"label": "Fortuna", "value": "C11408"},
              {"label": "Godofredo Viana", "value": "C11409"},
              {"label": "Goncalves Dias", "value": "C11410"},
              {"label": "Governador Archer", "value": "C11411"},
              {"label": "Governador Edison Lobao", "value": "C11412"},
              {"label": "Governador Eugenio Barros", "value": "C11413"},
              {"label": "Governador Luiz Rocha", "value": "C11414"},
              {"label": "Governador Newton Bello", "value": "C11415"},
              {"label": "Governador Nunes Freire", "value": "C11416"},
              {"label": "Graca Aranha", "value": "C11417"},
              {"label": "Grajau", "value": "C11418"},
              {"label": "Guimaraes", "value": "C11419"},
              {"label": "Humberto de Campos", "value": "C11420"},
              {"label": "Icatu", "value": "C11421"},
              {"label": "Igarape do Meio", "value": "C11422"},
              {"label": "Igarape Grande", "value": "C11423"},
              {"label": "Imperatriz", "value": "C11424"},
              {"label": "Itaipava do Grajau", "value": "C11425"},
              {"label": "Itapecuru Mirim", "value": "C11426"},
              {"label": "Itinga do Maranhao", "value": "C11427"},
              {"label": "Jatoba", "value": "C11428"},
              {"label": "Jenipapo dos Vieiras", "value": "C11429"},
              {"label": "Joao Lisboa", "value": "C11430"},
              {"label": "Joselandia", "value": "C11431"},
              {"label": "Junco do Maranhao", "value": "C11432"},
              {"label": "Lago da Pedra", "value": "C11433"},
              {"label": "Lago do Junco", "value": "C11434"},
              {"label": "Lago dos Rodrigues", "value": "C11435"},
              {"label": "Lago Verde", "value": "C11436"},
              {"label": "Lagoa do Mato", "value": "C11437"},
              {"label": "Lagoa Grande do Maranhao", "value": "C11438"},
              {"label": "Lajeado Novo", "value": "C11439"},
              {"label": "Lima Campos", "value": "C11440"},
              {"label": "Loreto", "value": "C11441"},
              {"label": "Luis Domingues", "value": "C11442"},
              {"label": "Magalhaes de Almeida", "value": "C11443"},
              {"label": "Maracacume", "value": "C11444"},
              {"label": "Maraja do Sena", "value": "C11445"},
              {"label": "Maranhaozinho", "value": "C11446"},
              {"label": "Mata Roma", "value": "C11447"},
              {"label": "Matinha", "value": "C11448"},
              {"label": "Matoes", "value": "C11449"},
              {"label": "Matoes do Norte", "value": "C11450"},
              {"label": "Milagres do Maranhao", "value": "C11451"},
              {"label": "Mirador", "value": "C11452"},
              {"label": "Miranda do Norte", "value": "C11453"},
              {"label": "Mirinzal", "value": "C11454"},
              {"label": "Moncao", "value": "C11455"},
              {"label": "Montes Altos", "value": "C11456"},
              {"label": "Morros", "value": "C11457"},
              {"label": "Nina Rodrigues", "value": "C11458"},
              {"label": "Nova Colinas", "value": "C11459"},
              {"label": "Nova Iorque", "value": "C11460"},
              {"label": "Nova Olinda do Maranhao", "value": "C11461"},
              {"label": "Olho dagua das Cunhas", "value": "C11462"},
              {"label": "Olinda Nova do Maranhao", "value": "C11463"},
              {"label": "Paco do Lumiar", "value": "C11464"},
              {"label": "Palmeirandia", "value": "C11465"},
              {"label": "Paraibano", "value": "C11466"},
              {"label": "Parnarama", "value": "C11467"},
              {"label": "Passagem Franca", "value": "C11468"},
              {"label": "Pastos Bons", "value": "C11469"},
              {"label": "Paulino Neves", "value": "C11470"},
              {"label": "Paulo Ramos", "value": "C11471"},
              {"label": "Pedreiras", "value": "C11472"},
              {"label": "Pedro do Rosario", "value": "C11473"},
              {"label": "Penalva", "value": "C11474"},
              {"label": "Peri Mirim", "value": "C11475"},
              {"label": "Peritoro", "value": "C11476"},
              {"label": "Pindare Mirim", "value": "C11477"},
              {"label": "Pindare-Mirim", "value": "C11478"},
              {"label": "Pinheiro", "value": "C11479"},
              {"label": "Pio XII", "value": "C11480"},
              {"label": "Pirapemas", "value": "C11481"},
              {"label": "Pocao de Pedras", "value": "C11482"},
              {"label": "Porto Franco", "value": "C11483"},
              {"label": "Porto Rico do Maranhao", "value": "C11484"},
              {"label": "Presidente Dutra", "value": "C11485"},
              {"label": "Presidente Juscelino", "value": "C11486"},
              {"label": "Presidente Medici", "value": "C11487"},
              {"label": "Presidente Sarney", "value": "C11488"},
              {"label": "Presidente Vargas", "value": "C11489"},
              {"label": "Primeira Cruz", "value": "C11490"},
              {"label": "Raposa", "value": "C11491"},
              {"label": "Riachao", "value": "C11492"},
              {"label": "Ribamar Fiquene", "value": "C11493"},
              {"label": "Rosario", "value": "C11494"},
              {"label": "Sambaiba", "value": "C11495"},
              {"label": "Santa Filomena do Maranhao", "value": "C11496"},
              {"label": "Santa Helena", "value": "C11497"},
              {"label": "Santa Ines", "value": "C11498"},
              {"label": "Santa Luzia", "value": "C11499"},
              {"label": "Santa Luzia do Parua", "value": "C11500"},
              {"label": "Santa Quiteria do Maranhao", "value": "C11501"},
              {"label": "Santa Rita", "value": "C11502"},
              {"label": "Santana do Maranhao", "value": "C11503"},
              {"label": "Santo Amaro do Maranhao", "value": "C11504"},
              {"label": "Santo Antonio dos Lopes", "value": "C11505"},
              {"label": "Sao Benedito do Rio Preto", "value": "C11506"},
              {"label": "Sao Bento", "value": "C11507"},
              {"label": "Sao Bernardo", "value": "C11508"},
              {"label": "Sao Domingos do Azeitao", "value": "C11509"},
              {"label": "Sao Domingos do Maranhao", "value": "C11510"},
              {"label": "Sao Felix de Balsas", "value": "C11511"},
              {"label": "Sao Francisco do Brejao", "value": "C11512"},
              {"label": "Sao Francisco do Maranhao", "value": "C11513"},
              {"label": "Sao Joao Batista", "value": "C11514"},
              {"label": "Sao Joao do Caru", "value": "C11515"},
              {"label": "Sao Joao do Paraiso", "value": "C11516"},
              {"label": "Sao Joao do Soter", "value": "C11517"},
              {"label": "Sao Joao dos Patos", "value": "C11518"},
              {"label": "Sao Jose de Ribamar", "value": "C11519"},
              {"label": "Sao Jose dos Basilios", "value": "C11520"},
              {"label": "Sao Luis", "value": "C11521"},
              {"label": "Sao Luis Gonzaga do Maranhao", "value": "C11522"},
              {"label": "Sao Mateus do Maranhao", "value": "C11523"},
              {"label": "Sao Pedro da agua Branca", "value": "C11524"},
              {"label": "Sao Pedro dos Crentes", "value": "C11525"},
              {"label": "Sao Raimundo das Mangabeiras", "value": "C11526"},
              {"label": "Sao Raimundo do Doca Bezerra", "value": "C11527"},
              {"label": "Sao Roberto", "value": "C11528"},
              {"label": "Sao Vicente Ferrer", "value": "C11529"},
              {"label": "Satubinha", "value": "C11530"},
              {"label": "Senador Alexandre Costa", "value": "C11531"},
              {"label": "Senador La Rocque", "value": "C11532"},
              {"label": "Serrano do Maranhao", "value": "C11533"},
              {"label": "Sitio Novo", "value": "C11534"},
              {"label": "Sucupira do Norte", "value": "C11535"},
              {"label": "Sucupira do Riachao", "value": "C11536"},
              {"label": "Tasso Fragoso", "value": "C11537"},
              {"label": "Timbiras", "value": "C11538"},
              {"label": "Timon", "value": "C11539"},
              {"label": "Trizidela do Vale", "value": "C11540"},
              {"label": "Tufilandia", "value": "C11541"},
              {"label": "Tuntum", "value": "C11542"},
              {"label": "Turiacu", "value": "C11543"},
              {"label": "Turilandia", "value": "C11544"},
              {"label": "Tutoia", "value": "C11545"},
              {"label": "Urbano Santos", "value": "C11546"},
              {"label": "Vargem Grande", "value": "C11547"},
              {"label": "Viana", "value": "C11548"},
              {"label": "Vila Nova dos Martirios", "value": "C11549"},
              {"label": "Vitoria do Mearim", "value": "C11550"},
              {"label": "Vitorino Freire", "value": "C11551"},
              {"label": "Ze Doca", "value": "C11552"}],
 "Marche": [{"label": "Acqualagna", "value": "C57204"},
            {"label": "Acquasanta Terme", "value": "C57205"},
            {"label": "Acquaviva Picena", "value": "C57206"},
            {"label": "Agugliano", "value": "C57207"},
            {"label": "Altidona", "value": "C57208"},
            {"label": "Amandola", "value": "C57209"},
            {"label": "Ancona", "value": "C57210"},
            {"label": "Apecchio", "value": "C57211"},
            {"label": "Apiro", "value": "C57212"},
            {"label": "Appignano", "value": "C57213"},
            {"label": "Appignano del Tronto", "value": "C57214"},
            {"label": "Arcevia", "value": "C57215"},
            {"label": "Arquata del Tronto", "value": "C57216"},
            {"label": "Ascoli Piceno", "value": "C57217"},
            {"label": "Auditore", "value": "C57218"},
            {"label": "Balzo", "value": "C57219"},
            {"label": "Barbara", "value": "C57220"},
            {"label": "Barchi", "value": "C57221"},
            {"label": "Belforte", "value": "C57222"},
            {"label": "Belforte del Chienti", "value": "C57223"},
            {"label": "Bellocchi", "value": "C57224"},
            {"label": "Belmonte Piceno", "value": "C57225"},
            {"label": "Belvedere Ostrense", "value": "C57226"},
            {"label": "Bolognola", "value": "C57227"},
            {"label": "Borghetto", "value": "C57228"},
            {"label": "Borgo Massano", "value": "C57229"},
            {"label": "Borgo Pace", "value": "C57230"},
            {"label": "Borgo Santa Maria", "value": "C57231"},
            {"label": "Borgo Stazione", "value": "C57232"},
            {"label": "Bottega", "value": "C57233"},
            {"label": "Cagli", "value": "C57234"},
            {"label": "Calcinelli", "value": "C57235"},
            {"label": "Caldarola", "value": "C57236"},
            {"label": "Camerano", "value": "C57237"},
            {"label": "Camerata Picena", "value": "C57238"},
            {"label": "Camerino", "value": "C57239"},
            {"label": "Campiglione", "value": "C57240"},
            {"label": "Campocavallo", "value": "C57241"},
            {"label": "Campofilone", "value": "C57242"},
            {"label": "Camporotondo di Fiastrone", "value": "C57243"},
            {"label": "Canavaccio", "value": "C57244"},
            {"label": "Cantiano", "value": "C57245"},
            {"label": "Capodarco", "value": "C57246"},
            {"label": "Cappone", "value": "C57247"},
            {"label": "Carassai", "value": "C57248"},
            {"label": "Carpegna", "value": "C57249"},
            {"label": "Cartoceto", "value": "C57250"},
            {"label": "Cascinare", "value": "C57251"},
            {"label": "Casette dEte", "value": "C57252"},
            {"label": "Casette Verdini", "value": "C57253"},
            {"label": "Casine", "value": "C57254"},
            {"label": "Casinina", "value": "C57255"},
            {"label": "Castel di Lama", "value": "C57256"},
            {"label": "Castelbellino", "value": "C57257"},
            {"label": "Castelfidardo", "value": "C57258"},
            {"label": "Castelleone di Suasa", "value": "C57259"},
            {"label": "Castelplanio", "value": "C57260"},
            {"label": "Castelraimondo", "value": "C57261"},
            {"label": "Castelsantangelo sul Nera", "value": "C57262"},
            {"label": "Castignano", "value": "C57263"},
            {"label": "Castorano", "value": "C57264"},
            {"label": "Centinarola", "value": "C57265"},
            {"label": "Centobuchi", "value": "C57266"},
            {"label": "Cerreto dEsi", "value": "C57267"},
            {"label": "Cessapalombo", "value": "C57268"},
            {"label": "Chiaravalle", "value": "C57269"},
            {"label": "Cingoli", "value": "C57270"},
            {"label": "Civitanova Alta", "value": "C57271"},
            {"label": "Civitanova Marche", "value": "C57272"},
            {"label": "Colbordolo", "value": "C57273"},
            {"label": "Colli del Tronto", "value": "C57274"},
            {"label": "Colmurano", "value": "C57275"},
            {"label": "Comunanza", "value": "C57276"},
            {"label": "Corinaldo", "value": "C57277"},
            {"label": "Corridonia", "value": "C57278"},
            {"label": "Cossignano", "value": "C57279"},
            {"label": "Cuccurano", "value": "C57280"},
            {"label": "Cupra Marittima", "value": "C57281"},
            {"label": "Cupramontana", "value": "C57282"},
            {"label": "Esanatoglia", "value": "C57283"},
            {"label": "Fabriano", "value": "C57284"},
            {"label": "Falconara Marittima", "value": "C57285"},
            {"label": "Falerone", "value": "C57286"},
            {"label": "Fanano", "value": "C57287"},
            {"label": "Fano", "value": "C57288"},
            {"label": "Fenile", "value": "C57289"},
            {"label": "Fermignano", "value": "C57290"},
            {"label": "Fermo", "value": "C57291"},
            {"label": "Filottrano", "value": "C57292"},
            {"label": "Folignano", "value": "C57293"},
            {"label": "Force", "value": "C57294"},
            {"label": "Fossombrone", "value": "C57295"},
            {"label": "Francavilla dEte", "value": "C57296"},
            {"label": "Fratte Rosa", "value": "C57297"},
            {"label": "Frontino", "value": "C57298"},
            {"label": "Frontone", "value": "C57299"},
            {"label": "Gabicce Mare", "value": "C57300"},
            {"label": "Gagliole", "value": "C57301"},
            {"label": "Gallo", "value": "C57302"},
            {"label": "Genga", "value": "C57303"},
            {"label": "Gradara", "value": "C57304"},
            {"label": "Grottammare", "value": "C57305"},
            {"label": "Grottazzolina", "value": "C57306"},
            {"label": "Gualdo", "value": "C57307"},
            {"label": "Isola del Piano", "value": "C57308"},
            {"label": "Jesi", "value": "C57309"},
            {"label": "Lapedona", "value": "C57310"},
            {"label": "Le Grazie di Ancona", "value": "C57311"},
            {"label": "Lido di Fermo", "value": "C57312"},
            {"label": "Loreto", "value": "C57313"},
            {"label": "Loreto Stazione", "value": "C57314"},
            {"label": "Loro Piceno", "value": "C57315"},
            {"label": "Lucrezia", "value": "C57316"},
            {"label": "Lunano", "value": "C57317"},
            {"label": "Macerata", "value": "C57318"},
            {"label": "Macerata Feltria", "value": "C57319"},
            {"label": "Macine-Borgo Loreto", "value": "C57320"},
            {"label": "Magliano di Tenna", "value": "C57321"},
            {"label": "Maiolati Spontini", "value": "C57322"},
            {"label": "Maltignano", "value": "C57323"},
            {"label": "Marcelli", "value": "C57324"},
            {"label": "Marina di Altidona", "value": "C57325"},
            {"label": "Marina di Montemarciano", "value": "C57326"},
            {"label": "Marotta", "value": "C57327"},
            {"label": "Marsia", "value": "C57328"},
            {"label": "Massa", "value": "C57329"},
            {"label": "Massa Fermana", "value": "C57330"},
            {"label": "Massignano", "value": "C57331"},
            {"label": "Matelica", "value": "C57332"},
            {"label": "Mercatale", "value": "C57333"},
            {"label": "Mercatello sul Metauro", "value": "C57334"},
            {"label": "Mercatino Conca", "value": "C57335"},
            {"label": "Mercato Vecchio", "value": "C57336"},
            {"label": "Mergo", "value": "C57337"},
            {"label": "Mogliano", "value": "C57338"},
            {"label": "Moie", "value": "C57339"},
            {"label": "Mombaroccio", "value": "C57340"},
            {"label": "Mondavio", "value": "C57341"},
            {"label": "Mondolfo", "value": "C57342"},
            {"label": "Monsampietro Morico", "value": "C57343"},
            {"label": "Monsampolo del Tronto", "value": "C57344"},
            {"label": "Monsano", "value": "C57345"},
            {"label": "Montalto delle Marche", "value": "C57346"},
            {"label": "Montappone", "value": "C57347"},
            {"label": "Monte Cerignone", "value": "C57348"},
            {"label": "Monte Giberto", "value": "C57349"},
            {"label": "Monte Grimano Terme", "value": "C57350"},
            {"label": "Monte Porzio", "value": "C57351"},
            {"label": "Monte Rinaldo", "value": "C57352"},
            {"label": "Monte Roberto", "value": "C57353"},
            {"label": "Monte San Giusto", "value": "C57354"},
            {"label": "Monte San Martino", "value": "C57355"},
            {"label": "Monte San Pietrangeli", "value": "C57356"},
            {"label": "Monte San Vito", "value": "C57357"},
            {"label": "Monte Urano", "value": "C57358"},
            {"label": "Monte Vidon Combatte", "value": "C57359"},
            {"label": "Monte Vidon Corrado", "value": "C57360"},
            {"label": "Montecalvo in Foglia", "value": "C57361"},
            {"label": "Montecarotto", "value": "C57362"},
            {"label": "Montecassiano", "value": "C57363"},
            {"label": "Monteciccardo", "value": "C57364"},
            {"label": "Montecosaro", "value": "C57365"},
            {"label": "Montedinove", "value": "C57366"},
            {"label": "Montefalcone Appennino", "value": "C57367"},
            {"label": "Montefano", "value": "C57368"},
            {"label": "Montefelcino", "value": "C57369"},
            {"label": "Montefiore dellAso", "value": "C57370"},
            {"label": "Montefortino", "value": "C57371"},
            {"label": "Montegiorgio", "value": "C57372"},
            {"label": "Montegranaro", "value": "C57373"},
            {"label": "Montelabbate", "value": "C57374"},
            {"label": "Monteleone di Fermo", "value": "C57375"},
            {"label": "Montelparo", "value": "C57376"},
            {"label": "Montelupone", "value": "C57377"},
            {"label": "Montemaggiore al Metauro", "value": "C57378"},
            {"label": "Montemarciano", "value": "C57379"},
            {"label": "Montemonaco", "value": "C57380"},
            {"label": "Monteprandone", "value": "C57381"},
            {"label": "Monterado", "value": "C57382"},
            {"label": "Monterubbiano", "value": "C57383"},
            {"label": "Montignano-Marzocca", "value": "C57384"},
            {"label": "Montottone", "value": "C57385"},
            {"label": "Moresco", "value": "C57386"},
            {"label": "Morro dAlba", "value": "C57387"},
            {"label": "Morrovalle", "value": "C57388"},
            {"label": "Muccia", "value": "C57389"},
            {"label": "Numana", "value": "C57390"},
            {"label": "Offagna", "value": "C57391"},
            {"label": "Offida", "value": "C57392"},
            {"label": "Orciano di Pesaro", "value": "C57393"},
            {"label": "Ortezzano", "value": "C57394"},
            {"label": "Osimo", "value": "C57395"},
            {"label": "Osteria", "value": "C57396"},
            {"label": "Ostra", "value": "C57397"},
            {"label": "Ostra Vetere", "value": "C57398"},
            {"label": "Padiglione", "value": "C57399"},
            {"label": "Pagliare", "value": "C57400"},
            {"label": "Palmiano", "value": "C57401"},
            {"label": "Passo di Treia", "value": "C57402"},
            {"label": "Passo Ripe", "value": "C57403"},
            {"label": "Pedaso", "value": "C57404"},
            {"label": "Peglio", "value": "C57405"},
            {"label": "Penna San Giovanni", "value": "C57406"},
            {"label": "Pergola", "value": "C57407"},
            {"label": "Pesaro", "value": "C57408"},
            {"label": "Petriano", "value": "C57409"},
            {"label": "Petriolo", "value": "C57410"},
            {"label": "Petritoli", "value": "C57411"},
            {"label": "Piagge", "value": "C57412"},
            {"label": "Piandimeleto", "value": "C57413"},
            {"label": "Piane", "value": "C57414"},
            {"label": "Piane di Montegiorgio", "value": "C57415"},
            {"label": "Piane di Morro", "value": "C57416"},
            {"label": "Pianello", "value": "C57417"},
            {"label": "Pianello Vallesina", "value": "C57418"},
            {"label": "Piattoni-Villa SantAntonio", "value": "C57419"},
            {"label": "Pie del Colle", "value": "C57420"},
            {"label": "Pie del Sasso", "value": "C57421"},
            {"label": "Piediripa", "value": "C57422"},
            {"label": "Pietra la Croce", "value": "C57423"},
            {"label": "Pieve Torina", "value": "C57424"},
            {"label": "Pievebovigliana", "value": "C57425"},
            {"label": "Pinocchio di Ancona", "value": "C57426"},
            {"label": "Piobbico", "value": "C57427"},
            {"label": "Pioraco", "value": "C57428"},
            {"label": "Poggio San Marcello", "value": "C57429"},
            {"label": "Poggio San Vicino", "value": "C57430"},
            {"label": "Pollenza", "value": "C57431"},
            {"label": "Polverigi", "value": "C57432"},
            {"label": "Ponzano di Fermo", "value": "C57433"},
            {"label": "Porto Potenza Picena", "value": "C57434"},
            {"label": "Porto Recanati", "value": "C57435"},
            {"label": "Porto San Giorgio", "value": "C57436"},
            {"label": "Porto SantElpidio", "value": "C57437"},
            {"label": "Posatora", "value": "C57438"},
            {"label": "Potenza Picena", "value": "C57439"},
            {"label": "Province of Fermo", "value": "C57440"},
            {"label": "Provincia di Ancona", "value": "C57441"},
            {"label": "Provincia di Ascoli Piceno", "value": "C57442"},
            {"label": "Provincia di Macerata", "value": "C57443"},
            {"label": "Provincia di Pesaro e Urbino", "value": "C57444"},
            {"label": "Rapagnano", "value": "C57445"},
            {"label": "Recanati", "value": "C57446"},
            {"label": "Rio Salso-Case Bernardi", "value": "C57447"},
            {"label": "Ripatransone", "value": "C57448"},
            {"label": "Ripe", "value": "C57449"},
            {"label": "Ripe San Ginesio", "value": "C57450"},
            {"label": "Rosciano", "value": "C57451"},
            {"label": "Rosora", "value": "C57452"},
            {"label": "Rotella", "value": "C57453"},
            {"label": "Saltara", "value": "C57454"},
            {"label": "San Benedetto del Tronto", "value": "C57455"},
            {"label": "San Biagio", "value": "C57456"},
            {"label": "San Costanzo", "value": "C57457"},
            {"label": "San Ginesio", "value": "C57458"},
            {"label": "San Giorgio di Pesaro", "value": "C57459"},
            {"label": "San Lorenzo in Campo", "value": "C57460"},
            {"label": "San Marcello", "value": "C57461"},
            {"label": "San Michele", "value": "C57462"},
            {"label": "San Paolo di Jesi", "value": "C57463"},
            {"label": "San Severino Marche", "value": "C57464"},
            {"label": "San Tommaso Tre Archi", "value": "C57465"},
            {"label": "San Vittoria in Matenano", "value": "C57466"},
            {"label": "SantAngelo in Lizzola", "value": "C57467"},
            {"label": "SantAngelo in Pontano", "value": "C57468"},
            {"label": "SantAngelo in Vado", "value": "C57469"},
            {"label": "SantElpidio a Mare", "value": "C57470"},
            {"label": "SantIppolito", "value": "C57471"},
            {"label": "Santa Maria Apparente", "value": "C57472"},
            {"label": "Santa Maria Nuova", "value": "C57473"},
            {"label": "Sarnano", "value": "C57474"},
            {"label": "Sassocorvaro", "value": "C57475"},
            {"label": "Sassofeltrio", "value": "C57476"},
            {"label": "Sassoferrato", "value": "C57477"},
            {"label": "Sefro", "value": "C57478"},
            {"label": "Senigallia", "value": "C57479"},
            {"label": "Serra de Conti", "value": "C57480"},
            {"label": "Serra San Quirico", "value": "C57481"},
            {"label": "Serra SantAbbondio", "value": "C57482"},
            {"label": "Serrapetrona", "value": "C57483"},
            {"label": "Serravalle di Chienti", "value": "C57484"},
            {"label": "Serrungarina", "value": "C57485"},
            {"label": "Servigliano", "value": "C57486"},
            {"label": "Sforzacosta", "value": "C57487"},
            {"label": "Sirolo", "value": "C57488"},
            {"label": "Smerillo", "value": "C57489"},
            {"label": "Spinetoli", "value": "C57490"},
            {"label": "Staffolo", "value": "C57491"},
            {"label": "Stazione", "value": "C57492"},
            {"label": "Stella", "value": "C57493"},
            {"label": "Strada", "value": "C57494"},
            {"label": "Tavernelle", "value": "C57495"},
            {"label": "Tavoleto", "value": "C57496"},
            {"label": "Tavullia", "value": "C57497"},
            {"label": "Tolentino", "value": "C57498"},
            {"label": "Torre San Patrizio", "value": "C57499"},
            {"label": "Trebbio", "value": "C57500"},
            {"label": "Treia", "value": "C57501"},
            {"label": "Trodica", "value": "C57502"},
            {"label": "Troviggiano", "value": "C57503"},
            {"label": "Urbania", "value": "C57504"},
            {"label": "Urbino", "value": "C57505"},
            {"label": "Urbisaglia", "value": "C57506"},
            {"label": "Valle e Castello", "value": "C57507"},
            {"label": "Venarotta", "value": "C57508"},
            {"label": "Villa Ceccolini", "value": "C57509"},
            {"label": "Villa Musone", "value": "C57510"},
            {"label": "Villa Pigna", "value": "C57511"},
            {"label": "Villa Potenza", "value": "C57512"},
            {"label": "Villa San Filippo", "value": "C57513"},
            {"label": "Villa San Giuseppe", "value": "C57514"},
            {"label": "Villagrande", "value": "C57515"},
            {"label": "Villanova", "value": "C57516"},
            {"label": "Visso", "value": "C57517"},
            {"label": "Zona Industriale", "value": "C57518"}],
 "Mardin": [{"label": "Acirli", "value": "C120717"},
            {"label": "Akarsu", "value": "C120718"},
            {"label": "Alakamis", "value": "C120719"},
            {"label": "Aran", "value": "C120720"},
            {"label": "Artuklu", "value": "C120721"},
            {"label": "Avine", "value": "C120722"},
            {"label": "Baglica", "value": "C120723"},
            {"label": "Baristepe", "value": "C120724"},
            {"label": "Baskavak", "value": "C120725"},
            {"label": "Calpinar", "value": "C120726"},
            {"label": "Cavuslu", "value": "C120727"},
            {"label": "Cinatamiho", "value": "C120728"},
            {"label": "Cinaralti", "value": "C120729"},
            {"label": "Ciplak", "value": "C120730"},
            {"label": "Dara", "value": "C120731"},
            {"label": "Dargecit", "value": "C120732"},
            {"label": "Dereyani", "value": "C120733"},
            {"label": "Derik Ilcesi", "value": "C120734"},
            {"label": "Duruca", "value": "C120735"},
            {"label": "Ebish", "value": "C120736"},
            {"label": "Esme", "value": "C120737"},
            {"label": "Eymirli", "value": "C120738"},
            {"label": "Gelinkaya", "value": "C120739"},
            {"label": "Gulveren", "value": "C120740"},
            {"label": "Gyundyukoru", "value": "C120741"},
            {"label": "Haberli", "value": "C120742"},
            {"label": "Kabala", "value": "C120743"},
            {"label": "Karalar", "value": "C120744"},
            {"label": "Kavsan", "value": "C120745"},
            {"label": "Kayalipinar", "value": "C120746"},
            {"label": "Kaynakkaya", "value": "C120747"},
            {"label": "Kindirip", "value": "C120748"},
            {"label": "Kilavuz", "value": "C120749"},
            {"label": "Kiziltepe", "value": "C120750"},
            {"label": "Kumlu", "value": "C120751"},
            {"label": "Kutlubey", "value": "C120752"},
            {"label": "Mardin", "value": "C120753"},
            {"label": "Mardin Merkez", "value": "C120754"},
            {"label": "Mazidagi", "value": "C120755"},
            {"label": "Midyat", "value": "C120756"},
            {"label": "Nusaybin", "value": "C120757"},
            {"label": "Omerli", "value": "C120758"},
            {"label": "Ortaca", "value": "C120759"},
            {"label": "Ortakoy", "value": "C120760"},
            {"label": "Oyali", "value": "C120761"},
            {"label": "Ozbek", "value": "C120762"},
            {"label": "Pinardere", "value": "C120763"},
            {"label": "Reshidi", "value": "C120764"},
            {"label": "Savur", "value": "C120765"},
            {"label": "Selah", "value": "C120766"},
            {"label": "Senocak", "value": "C120767"},
            {"label": "Senyurt", "value": "C120768"},
            {"label": "Seri", "value": "C120769"},
            {"label": "Serkan", "value": "C120770"},
            {"label": "Sivrice", "value": "C120771"},
            {"label": "Teffi", "value": "C120772"},
            {"label": "Telminar", "value": "C120773"},
            {"label": "Tepealti", "value": "C120774"},
            {"label": "Toptepe", "value": "C120775"},
            {"label": "Yayli", "value": "C120776"},
            {"label": "Yayvantepe", "value": "C120777"},
            {"label": "Yesilalan", "value": "C120778"},
            {"label": "Yesilli", "value": "C120779"},
            {"label": "Yolbasi", "value": "C120780"}],
 "Margibi County": [{"label": "Kakata", "value": "C65010"}],
 "Mari El Republic": [{"label": "Gornomariyskiy Rayon", "value": "C102162"},
                      {"label": "Kilemarskiy Rayon", "value": "C102163"},
                      {"label": "Kilemary", "value": "C102164"},
                      {"label": "Kozmodemyansk", "value": "C102165"},
                      {"label": "Krasnogorskiy", "value": "C102166"},
                      {"label": "Kuzhener", "value": "C102167"},
                      {"label": "Kuzhenerskiy Rayon", "value": "C102168"},
                      {"label": "Mari-Turek", "value": "C102169"},
                      {"label": "Mari-Turekskiy Rayon", "value": "C102170"},
                      {"label": "Medvedevo", "value": "C102171"},
                      {"label": "Medvedevskiy Rayon", "value": "C102172"},
                      {"label": "Mochalishche", "value": "C102173"},
                      {"label": "Morki", "value": "C102174"},
                      {"label": "Morkinskiy Rayon", "value": "C102175"},
                      {"label": "Novotoryalskiy Rayon", "value": "C102176"},
                      {"label": "Orshanka", "value": "C102177"},
                      {"label": "Orshanskiy Rayon", "value": "C102178"},
                      {"label": "Paranga", "value": "C102179"},
                      {"label": "Paranginskiy Rayon", "value": "C102180"},
                      {"label": "Pomary", "value": "C102181"},
                      {"label": "Privolzhskiy", "value": "C102182"},
                      {"label": "Sernur", "value": "C102183"},
                      {"label": "Sernurskiy Rayon", "value": "C102184"},
                      {"label": "Sovetskiy", "value": "C102185"},
                      {"label": "Sovetskiy Rayon", "value": "C102186"},
                      {"label": "Surok", "value": "C102187"},
                      {"label": "Suslonger", "value": "C102188"},
                      {"label": "Vilovatovo", "value": "C102189"},
                      {"label": "Volzhsk", "value": "C102190"},
                      {"label": "Volzhskiy Rayon", "value": "C102191"},
                      {"label": "Yoshkar-Ola", "value": "C102192"},
                      {"label": "Yurino", "value": "C102193"},
                      {"label": "Yurinskiy Rayon", "value": "C102194"},
                      {"label": "Zvenigovo", "value": "C102195"},
                      {"label": "Zvenigovskiy Rayon", "value": "C102196"}],
 "Maria Trinidad Sanchez Province": [{"label": "Arroyo Salado",
                                      "value": "C23932"},
                                     {"label": "Cabrera", "value": "C23933"},
                                     {"label": "El Factor", "value": "C23934"},
                                     {"label": "La Entrada", "value": "C23935"},
                                     {"label": "Nagua", "value": "C23936"},
                                     {"label": "Rio San Juan",
                                      "value": "C23937"}],
 "Marib Governorate": [{"label": "Al Abdiyah", "value": "C147980"},
                       {"label": "Al Jubah", "value": "C147981"},
                       {"label": "Bidbadah", "value": "C147982"},
                       {"label": "Harib", "value": "C147983"},
                       {"label": "Harib Al Qaramish", "value": "C147984"},
                       {"label": "Jabal Murad", "value": "C147985"},
                       {"label": "Marib", "value": "C147986"},
                       {"label": "Mahliyah", "value": "C147987"},
                       {"label": "Majzar", "value": "C147988"},
                       {"label": "Marib", "value": "C147989"},
                       {"label": "Marib City", "value": "C147990"},
                       {"label": "Medghal", "value": "C147991"},
                       {"label": "Raghwan", "value": "C147992"},
                       {"label": "Rahabah", "value": "C147993"},
                       {"label": "Sirwah", "value": "C147994"}],
 "Maribor City Municipality": [{"label": "Bresternica", "value": "C107710"},
                               {"label": "Kamnica", "value": "C107711"},
                               {"label": "Limbus", "value": "C107712"},
                               {"label": "Maribor", "value": "C107713"},
                               {"label": "Pekre", "value": "C107714"},
                               {"label": "Razvanje", "value": "C107715"}],
 "Marijampole Municipality": [{"label": "Gelgaudiskis", "value": "C65125"},
                              {"label": "Kalvarija", "value": "C65126"},
                              {"label": "Kalvarija Municipality",
                               "value": "C65127"},
                              {"label": "Kazlu Ruda", "value": "C65128"},
                              {"label": "Kazlu Ruda Municipality",
                               "value": "C65129"},
                              {"label": "Kudirkos Naumiestis",
                               "value": "C65130"},
                              {"label": "Kybartai", "value": "C65131"},
                              {"label": "Marijampole", "value": "C65132"},
                              {"label": "Marijampole Municipality",
                               "value": "C65133"},
                              {"label": "Sakiai", "value": "C65134"},
                              {"label": "Vilkaviskis", "value": "C65135"},
                              {"label": "Vilkaviskis District Municipality",
                               "value": "C65136"},
                              {"label": "Virbalis", "value": "C65137"}],
 "Maritime": [{"label": "Aneho", "value": "C119635"},
              {"label": "Lome", "value": "C119636"},
              {"label": "Tabligbo", "value": "C119637"},
              {"label": "Tsevie", "value": "C119638"},
              {"label": "Vogan", "value": "C119639"}],
 "Marj District": [{"label": "Al Abyar", "value": "C65042"},
                   {"label": "Al Marj", "value": "C65043"},
                   {"label": "Tukrah", "value": "C65044"}],
 "Markazi": [{"label": "abyek", "value": "C50895"},
             {"label": "Arak", "value": "C50896"},
             {"label": "Delijan", "value": "C50897"},
             {"label": "Khomeyn", "value": "C50898"},
             {"label": "Komijan", "value": "C50899"},
             {"label": "Saveh", "value": "C50900"},
             {"label": "Shahrestan-e Arak", "value": "C50901"},
             {"label": "Shahrestan-e ashtian", "value": "C50902"},
             {"label": "Shahrestan-e Delijan", "value": "C50903"},
             {"label": "Shahrestan-e Farahan", "value": "C50904"},
             {"label": "Shahrestan-e Khondab", "value": "C50905"},
             {"label": "Shahrestan-e Komijan", "value": "C50906"},
             {"label": "Shahrestan-e Mahallat", "value": "C50907"},
             {"label": "Shahrestan-e Saveh", "value": "C50908"},
             {"label": "Shahrestan-e Shazand", "value": "C50909"},
             {"label": "Shahrestan-e Zarandiyeh", "value": "C50910"},
             {"label": "Tafresh", "value": "C50911"}],
 "Markovci Municipality": [{"label": "Markovci", "value": "C107716"}],
 "Marlborough Region": [{"label": "Blenheim", "value": "C77798"},
                        {"label": "Picton", "value": "C77799"}],
 "Marowijne District": [{"label": "Albina", "value": "C115465"},
                        {"label": "Moengo", "value": "C115466"}],
 "Marsa": [{"label": "Kalkara", "value": "C65896"}],
 "Marsabit": [{"label": "Marsabit", "value": "C64604"},
              {"label": "Moyale", "value": "C64605"}],
 "Marsaskala": [{"label": "Kercem", "value": "C65897"},
                {"label": "Marsaskala", "value": "C65898"}],
 "Marsaxlokk": [{"label": "Marsaxlokk", "value": "C65899"}],
 "Martuni": [{"label": "Hadrut", "value": "C8461"},
             {"label": "Novyy Karanlug", "value": "C8462"},
             {"label": "Qirmizi Bazar", "value": "C8463"}],
 "Marupe Municipality": [{"label": "Marupe", "value": "C64901"}],
 "Mary Region": [{"label": "Bayramaly", "value": "C121186"},
                 {"label": "Mary", "value": "C121187"},
                 {"label": "Serhetabat", "value": "C121188"},
                 {"label": "Seydi", "value": "C121189"},
                 {"label": "Yoloten", "value": "C121190"}],
 "Maryland": [{"label": "Aberdeen", "value": "C134290"},
              {"label": "Aberdeen Proving Ground", "value": "C134291"},
              {"label": "Accokeek", "value": "C134292"},
              {"label": "Adamstown", "value": "C134293"},
              {"label": "Adelphi", "value": "C134294"},
              {"label": "Algonquin", "value": "C134295"},
              {"label": "Allegany County", "value": "C134296"},
              {"label": "Andrews AFB", "value": "C134297"},
              {"label": "Annapolis", "value": "C134298"},
              {"label": "Anne Arundel County", "value": "C134299"},
              {"label": "Arbutus", "value": "C134300"},
              {"label": "Arden on the Severn", "value": "C134301"},
              {"label": "Arlington", "value": "C134302"},
              {"label": "Arnold", "value": "C134303"},
              {"label": "Ashton-Sandy Spring", "value": "C134304"},
              {"label": "Aspen Hill", "value": "C134305"},
              {"label": "Baden", "value": "C134306"},
              {"label": "Ballenger Creek", "value": "C134307"},
              {"label": "Baltimore", "value": "C134308"},
              {"label": "Baltimore County", "value": "C134309"},
              {"label": "Baltimore Highlands", "value": "C134310"},
              {"label": "Bartonsville", "value": "C134311"},
              {"label": "Bel Air", "value": "C134312"},
              {"label": "Bel Air North", "value": "C134313"},
              {"label": "Bel Air South", "value": "C134314"},
              {"label": "Beltsville", "value": "C134315"},
              {"label": "Bennsville", "value": "C134316"},
              {"label": "Berlin", "value": "C134317"},
              {"label": "Berwyn Heights", "value": "C134318"},
              {"label": "Bethesda", "value": "C134319"},
              {"label": "Bladensburg", "value": "C134320"},
              {"label": "Boonsboro", "value": "C134321"},
              {"label": "Bowie", "value": "C134322"},
              {"label": "Bowleys Quarters", "value": "C134323"},
              {"label": "Bowling Green", "value": "C134324"},
              {"label": "Braddock Heights", "value": "C134325"},
              {"label": "Brandywine", "value": "C134326"},
              {"label": "Brentwood", "value": "C134327"},
              {"label": "Brock Hall", "value": "C134328"},
              {"label": "Brooklyn Park", "value": "C134329"},
              {"label": "Brookmont", "value": "C134330"},
              {"label": "Brunswick", "value": "C134331"},
              {"label": "Bryans Road", "value": "C134332"},
              {"label": "Buckeystown", "value": "C134333"},
              {"label": "Burtonsville", "value": "C134334"},
              {"label": "Butchers Hill", "value": "C134335"},
              {"label": "Cabin John", "value": "C134336"},
              {"label": "California", "value": "C134337"},
              {"label": "Calvert County", "value": "C134338"},
              {"label": "Calverton", "value": "C134339"},
              {"label": "Cambridge", "value": "C134340"},
              {"label": "Camp Springs", "value": "C134341"},
              {"label": "Cape Saint Claire", "value": "C134342"},
              {"label": "Capitol Heights", "value": "C134343"},
              {"label": "Carney", "value": "C134344"},
              {"label": "Caroline County", "value": "C134345"},
              {"label": "Carroll County", "value": "C134346"},
              {"label": "Catonsville", "value": "C134347"},
              {"label": "Cavetown", "value": "C134348"},
              {"label": "Cecil County", "value": "C134349"},
              {"label": "Centreville", "value": "C134350"},
              {"label": "Charles County", "value": "C134351"},
              {"label": "Charles Village", "value": "C134352"},
              {"label": "Charlestown", "value": "C134353"},
              {"label": "Charlotte Hall", "value": "C134354"},
              {"label": "Chesapeake Beach", "value": "C134355"},
              {"label": "Chesapeake Ranch Estates", "value": "C134356"},
              {"label": "Chester", "value": "C134357"},
              {"label": "Chestertown", "value": "C134358"},
              {"label": "Cheverly", "value": "C134359"},
              {"label": "Chevy Chase", "value": "C134360"},
              {"label": "Chevy Chase Village", "value": "C134361"},
              {"label": "Chillum", "value": "C134362"},
              {"label": "City of Baltimore", "value": "C134363"},
              {"label": "Clarksburg", "value": "C134364"},
              {"label": "Clinton", "value": "C134365"},
              {"label": "Clover Hill", "value": "C134366"},
              {"label": "Cloverly", "value": "C134367"},
              {"label": "Cobb Island", "value": "C134368"},
              {"label": "Cockeysville", "value": "C134369"},
              {"label": "Colesville", "value": "C134370"},
              {"label": "College Park", "value": "C134371"},
              {"label": "Colmar Manor", "value": "C134372"},
              {"label": "Columbia", "value": "C134373"},
              {"label": "Coral Hills", "value": "C134374"},
              {"label": "Cottage City", "value": "C134375"},
              {"label": "Cresaptown", "value": "C134376"},
              {"label": "Crisfield", "value": "C134377"},
              {"label": "Crofton", "value": "C134378"},
              {"label": "Croom", "value": "C134379"},
              {"label": "Crownsville", "value": "C134380"},
              {"label": "Cumberland", "value": "C134381"},
              {"label": "Damascus", "value": "C134382"},
              {"label": "Darnestown", "value": "C134383"},
              {"label": "Davidsonville", "value": "C134384"},
              {"label": "Deale", "value": "C134385"},
              {"label": "Denton", "value": "C134386"},
              {"label": "Derwood", "value": "C134387"},
              {"label": "District Heights", "value": "C134388"},
              {"label": "Dorchester County", "value": "C134389"},
              {"label": "Drum Point", "value": "C134390"},
              {"label": "Dundalk", "value": "C134391"},
              {"label": "Dunkirk", "value": "C134392"},
              {"label": "Dunkirk Town Center", "value": "C134393"},
              {"label": "East Riverdale", "value": "C134394"},
              {"label": "Easton", "value": "C134395"},
              {"label": "Edgemere", "value": "C134396"},
              {"label": "Edgewater", "value": "C134397"},
              {"label": "Edgewood", "value": "C134398"},
              {"label": "Edmonston", "value": "C134399"},
              {"label": "Eldersburg", "value": "C134400"},
              {"label": "Elkridge", "value": "C134401"},
              {"label": "Elkton", "value": "C134402"},
              {"label": "Ellicott City", "value": "C134403"},
              {"label": "Emmitsburg", "value": "C134404"},
              {"label": "Essex", "value": "C134405"},
              {"label": "Fairland", "value": "C134406"},
              {"label": "Fairmount Heights", "value": "C134407"},
              {"label": "Fairwood", "value": "C134408"},
              {"label": "Fallston", "value": "C134409"},
              {"label": "Federalsburg", "value": "C134410"},
              {"label": "Ferndale", "value": "C134411"},
              {"label": "Forest Glen", "value": "C134412"},
              {"label": "Forest Heights", "value": "C134413"},
              {"label": "Forestville", "value": "C134414"},
              {"label": "Fort George G Mead Junction", "value": "C134415"},
              {"label": "Fort Meade", "value": "C134416"},
              {"label": "Fort Washington", "value": "C134417"},
              {"label": "Fountainhead-Orchard Hills", "value": "C134418"},
              {"label": "Four Corners", "value": "C134419"},
              {"label": "Frederick", "value": "C134420"},
              {"label": "Frederick County", "value": "C134421"},
              {"label": "Friendly", "value": "C134422"},
              {"label": "Friendship Village", "value": "C134423"},
              {"label": "Frostburg", "value": "C134424"},
              {"label": "Fruitland", "value": "C134425"},
              {"label": "Fulton", "value": "C134426"},
              {"label": "Gaithersburg", "value": "C134427"},
              {"label": "Gambrills", "value": "C134428"},
              {"label": "Garrett County", "value": "C134429"},
              {"label": "Garrett Park", "value": "C134430"},
              {"label": "Garrison", "value": "C134431"},
              {"label": "Germantown", "value": "C134432"},
              {"label": "Glassmanor", "value": "C134433"},
              {"label": "Glen Burnie", "value": "C134434"},
              {"label": "Glenarden", "value": "C134435"},
              {"label": "Glenmont", "value": "C134436"},
              {"label": "Glenn Dale", "value": "C134437"},
              {"label": "Goddard", "value": "C134438"},
              {"label": "Golden Beach", "value": "C134439"},
              {"label": "Grasonville", "value": "C134440"},
              {"label": "Greater Upper Marlboro", "value": "C134441"},
              {"label": "Green Haven", "value": "C134442"},
              {"label": "Green Valley", "value": "C134443"},
              {"label": "Greenbelt", "value": "C134444"},
              {"label": "Greensboro", "value": "C134445"},
              {"label": "Hagerstown", "value": "C134446"},
              {"label": "Halfway", "value": "C134447"},
              {"label": "Hampstead", "value": "C134448"},
              {"label": "Hampton", "value": "C134449"},
              {"label": "Hancock", "value": "C134450"},
              {"label": "Hanover", "value": "C134451"},
              {"label": "Harford County", "value": "C134452"},
              {"label": "Havre de Grace", "value": "C134453"},
              {"label": "Hebron", "value": "C134454"},
              {"label": "Herald Harbor", "value": "C134455"},
              {"label": "Highfield-Cascade", "value": "C134456"},
              {"label": "Highland", "value": "C134457"},
              {"label": "Hillandale", "value": "C134458"},
              {"label": "Hillcrest Heights", "value": "C134459"},
              {"label": "Hillsmere Shores", "value": "C134460"},
              {"label": "Howard County", "value": "C134461"},
              {"label": "Hughesville", "value": "C134462"},
              {"label": "Hunt Valley", "value": "C134463"},
              {"label": "Huntingtown", "value": "C134464"},
              {"label": "Huntingtown Town Center", "value": "C134465"},
              {"label": "Hurlock", "value": "C134466"},
              {"label": "Hyattsville", "value": "C134467"},
              {"label": "Ilchester", "value": "C134468"},
              {"label": "Indian Head", "value": "C134469"},
              {"label": "Irvington", "value": "C134470"},
              {"label": "Jarrettsville", "value": "C134471"},
              {"label": "Jefferson", "value": "C134472"},
              {"label": "Jessup", "value": "C134473"},
              {"label": "Joppatowne", "value": "C134474"},
              {"label": "Keedysville", "value": "C134475"},
              {"label": "Kemp Mill", "value": "C134476"},
              {"label": "Kensington", "value": "C134477"},
              {"label": "Kent County", "value": "C134478"},
              {"label": "Kettering", "value": "C134479"},
              {"label": "Kingstown", "value": "C134480"},
              {"label": "Kingsville", "value": "C134481"},
              {"label": "La Plata", "value": "C134482"},
              {"label": "La Vale", "value": "C134483"},
              {"label": "Lake Arbor", "value": "C134484"},
              {"label": "Lake Shore", "value": "C134485"},
              {"label": "Landover", "value": "C134486"},
              {"label": "Landover Hills", "value": "C134487"},
              {"label": "Langley Park", "value": "C134488"},
              {"label": "Lanham", "value": "C134489"},
              {"label": "Lanham-Seabrook", "value": "C134490"},
              {"label": "Lansdowne", "value": "C134491"},
              {"label": "Largo", "value": "C134492"},
              {"label": "Laurel", "value": "C134493"},
              {"label": "Layhill", "value": "C134494"},
              {"label": "Leisure World", "value": "C134495"},
              {"label": "Leonardtown", "value": "C134496"},
              {"label": "Lexington Park", "value": "C134497"},
              {"label": "Linganore", "value": "C134498"},
              {"label": "Linthicum", "value": "C134499"},
              {"label": "Lochearn", "value": "C134500"},
              {"label": "Lonaconing", "value": "C134501"},
              {"label": "Londontowne", "value": "C134502"},
              {"label": "Long Beach", "value": "C134503"},
              {"label": "Lusby", "value": "C134504"},
              {"label": "Lutherville", "value": "C134505"},
              {"label": "Lutherville-Timonium", "value": "C134506"},
              {"label": "Manchester", "value": "C134507"},
              {"label": "Marlboro Meadows", "value": "C134508"},
              {"label": "Marlboro Village", "value": "C134509"},
              {"label": "Marlow Heights", "value": "C134510"},
              {"label": "Marlton", "value": "C134511"},
              {"label": "Maryland City", "value": "C134512"},
              {"label": "Maugansville", "value": "C134513"},
              {"label": "Mayo", "value": "C134514"},
              {"label": "Mays Chapel", "value": "C134515"},
              {"label": "Mechanicsville", "value": "C134516"},
              {"label": "Mellwood", "value": "C134517"},
              {"label": "Middle River", "value": "C134518"},
              {"label": "Middletown", "value": "C134519"},
              {"label": "Milford Mill", "value": "C134520"},
              {"label": "Mitchellville", "value": "C134521"},
              {"label": "Montgomery County", "value": "C134522"},
              {"label": "Montgomery Village", "value": "C134523"},
              {"label": "Morningside", "value": "C134524"},
              {"label": "Mount Airy", "value": "C134525"},
              {"label": "Mount Rainier", "value": "C134526"},
              {"label": "Mountain Lake Park", "value": "C134527"},
              {"label": "Myersville", "value": "C134528"},
              {"label": "National Harbor", "value": "C134529"},
              {"label": "Naval Academy", "value": "C134530"},
              {"label": "New Carrollton", "value": "C134531"},
              {"label": "New Windsor", "value": "C134532"},
              {"label": "North Beach", "value": "C134533"},
              {"label": "North Bel Air", "value": "C134534"},
              {"label": "North Bethesda", "value": "C134535"},
              {"label": "North East", "value": "C134536"},
              {"label": "North Kensington", "value": "C134537"},
              {"label": "North Laurel", "value": "C134538"},
              {"label": "North Potomac", "value": "C134539"},
              {"label": "Oakland", "value": "C134540"},
              {"label": "Ocean City", "value": "C134541"},
              {"label": "Ocean Pines", "value": "C134542"},
              {"label": "Odenton", "value": "C134543"},
              {"label": "Olney", "value": "C134544"},
              {"label": "Overlea", "value": "C134545"},
              {"label": "Owings", "value": "C134546"},
              {"label": "Owings Mills", "value": "C134547"},
              {"label": "Oxon Hill", "value": "C134548"},
              {"label": "Oxon Hill-Glassmanor", "value": "C134549"},
              {"label": "Paramount-Long Meadow", "value": "C134550"},
              {"label": "Parkville", "value": "C134551"},
              {"label": "Parole", "value": "C134552"},
              {"label": "Pasadena", "value": "C134553"},
              {"label": "Peppermill Village", "value": "C134554"},
              {"label": "Perry Hall", "value": "C134555"},
              {"label": "Perryman", "value": "C134556"},
              {"label": "Perryville", "value": "C134557"},
              {"label": "Pikesville", "value": "C134558"},
              {"label": "Pittsville", "value": "C134559"},
              {"label": "Pleasant Hills", "value": "C134560"},
              {"label": "Pocomoke City", "value": "C134561"},
              {"label": "Point of Rocks", "value": "C134562"},
              {"label": "Poolesville", "value": "C134563"},
              {"label": "Potomac", "value": "C134564"},
              {"label": "Potomac Heights", "value": "C134565"},
              {"label": "Potomac Park", "value": "C134566"},
              {"label": "Prince Frederick", "value": "C134567"},
              {"label": "Prince Georges County", "value": "C134568"},
              {"label": "Princess Anne", "value": "C134569"},
              {"label": "Pumphrey", "value": "C134570"},
              {"label": "Queen Anne", "value": "C134571"},
              {"label": "Queen Annes County", "value": "C134572"},
              {"label": "Queenland", "value": "C134573"},
              {"label": "Randallstown", "value": "C134574"},
              {"label": "Redland", "value": "C134575"},
              {"label": "Reisterstown", "value": "C134576"},
              {"label": "Ridgely", "value": "C134577"},
              {"label": "Rising Sun", "value": "C134578"},
              {"label": "Riva", "value": "C134579"},
              {"label": "Riverdale Park", "value": "C134580"},
              {"label": "Riverside", "value": "C134581"},
              {"label": "Riviera Beach", "value": "C134582"},
              {"label": "Robinwood", "value": "C134583"},
              {"label": "Rock Hall", "value": "C134584"},
              {"label": "Rockville", "value": "C134585"},
              {"label": "Rosaryville", "value": "C134586"},
              {"label": "Rosedale", "value": "C134587"},
              {"label": "Rossmoor", "value": "C134588"},
              {"label": "Rossville", "value": "C134589"},
              {"label": "Saint Charles", "value": "C134590"},
              {"label": "Saint James", "value": "C134591"},
              {"label": "Saint Marys County", "value": "C134592"},
              {"label": "Saint Michaels", "value": "C134593"},
              {"label": "Salisbury", "value": "C134594"},
              {"label": "Savage", "value": "C134595"},
              {"label": "Scaggsville", "value": "C134596"},
              {"label": "Seabrook", "value": "C134597"},
              {"label": "Seat Pleasant", "value": "C134598"},
              {"label": "Selby-on-the-Bay", "value": "C134599"},
              {"label": "Severn", "value": "C134600"},
              {"label": "Severna Park", "value": "C134601"},
              {"label": "Shady Side", "value": "C134602"},
              {"label": "Silver Hill", "value": "C134603"},
              {"label": "Silver Spring", "value": "C134604"},
              {"label": "Smithsburg", "value": "C134605"},
              {"label": "Snow Hill", "value": "C134606"},
              {"label": "Solomons", "value": "C134607"},
              {"label": "Somerset", "value": "C134608"},
              {"label": "Somerset County", "value": "C134609"},
              {"label": "South Bel Air", "value": "C134610"},
              {"label": "South Gate", "value": "C134611"},
              {"label": "South Kensington", "value": "C134612"},
              {"label": "South Laurel", "value": "C134613"},
              {"label": "Spencerville", "value": "C134614"},
              {"label": "Spring Ridge", "value": "C134615"},
              {"label": "Springdale", "value": "C134616"},
              {"label": "Stevensville", "value": "C134617"},
              {"label": "Suitland", "value": "C134618"},
              {"label": "Suitland-Silver Hill", "value": "C134619"},
              {"label": "Summerfield", "value": "C134620"},
              {"label": "Sykesville", "value": "C134621"},
              {"label": "Takoma Park", "value": "C134622"},
              {"label": "Talbot County", "value": "C134623"},
              {"label": "Taneytown", "value": "C134624"},
              {"label": "Temple Hills", "value": "C134625"},
              {"label": "Thurmont", "value": "C134626"},
              {"label": "Timonium", "value": "C134627"},
              {"label": "Towson", "value": "C134628"},
              {"label": "Trappe", "value": "C134629"},
              {"label": "Travilah", "value": "C134630"},
              {"label": "University Park", "value": "C134631"},
              {"label": "Upper Marlboro", "value": "C134632"},
              {"label": "Urbana", "value": "C134633"},
              {"label": "Waldorf", "value": "C134634"},
              {"label": "Walker Mill", "value": "C134635"},
              {"label": "Walkersville", "value": "C134636"},
              {"label": "Washington County", "value": "C134637"},
              {"label": "West Elkridge", "value": "C134638"},
              {"label": "West Laurel", "value": "C134639"},
              {"label": "West Ocean City", "value": "C134640"},
              {"label": "Westernport", "value": "C134641"},
              {"label": "Westminster", "value": "C134642"},
              {"label": "Westphalia", "value": "C134643"},
              {"label": "Wheaton", "value": "C134644"},
              {"label": "White Marsh", "value": "C134645"},
              {"label": "White Oak", "value": "C134646"},
              {"label": "Wicomico County", "value": "C134647"},
              {"label": "Williamsport", "value": "C134648"},
              {"label": "Wilson-Conococheague", "value": "C134649"},
              {"label": "Woodlawn", "value": "C134650"},
              {"label": "Woodmore", "value": "C134651"},
              {"label": "Woodsboro", "value": "C134652"},
              {"label": "Worcester County", "value": "C134653"}],
 "Maryland County": [{"label": "Harper", "value": "C65011"}],
 "Masally District": [{"label": "Boradigah", "value": "C8464"},
                      {"label": "Masally", "value": "C8465"}],
 "Masaya": [{"label": "Catarina", "value": "C77968"},
            {"label": "La Concepcion", "value": "C77969"},
            {"label": "Masatepe", "value": "C77970"},
            {"label": "Masaya", "value": "C77971"},
            {"label": "Municipio de Masatepe", "value": "C77972"},
            {"label": "Municipio de Nandasmo", "value": "C77973"},
            {"label": "Municipio de Niquinohomo", "value": "C77974"},
            {"label": "Municipio de San Juan de Oriente", "value": "C77975"},
            {"label": "Nandasmo", "value": "C77976"},
            {"label": "Nindiri", "value": "C77977"},
            {"label": "Niquinohomo", "value": "C77978"},
            {"label": "San Juan de Oriente", "value": "C77979"},
            {"label": "Tisma", "value": "C77980"}],
 "Mascara": [{"label": "Bou Hanifia el Hamamat", "value": "C357"},
             {"label": "Mascara", "value": "C358"},
             {"label": "Oued el Abtal", "value": "C359"},
             {"label": "Sig", "value": "C360"}],
 "Maseru District": [{"label": "Maseru", "value": "C64995"},
                     {"label": "Nako", "value": "C64996"}],
 "Mashonaland Central Province": [{"label": "Bindura", "value": "C148179"},
                                  {"label": "Bindura District",
                                   "value": "C148180"},
                                  {"label": "Centenary", "value": "C148181"},
                                  {"label": "Centenary District",
                                   "value": "C148182"},
                                  {"label": "Concession", "value": "C148183"},
                                  {"label": "Glendale", "value": "C148184"},
                                  {"label": "Guruve District",
                                   "value": "C148185"},
                                  {"label": "Mazowe", "value": "C148186"},
                                  {"label": "Mazowe District",
                                   "value": "C148187"},
                                  {"label": "Mount Darwin", "value": "C148188"},
                                  {"label": "Mvurwi", "value": "C148189"},
                                  {"label": "Rushinga District",
                                   "value": "C148190"},
                                  {"label": "Shamva", "value": "C148191"},
                                  {"label": "Shamva District",
                                   "value": "C148192"}],
 "Mashonaland East Province": [{"label": "Beatrice", "value": "C148193"},
                               {"label": "Chivhu", "value": "C148194"},
                               {"label": "Goromonzi District",
                                "value": "C148195"},
                               {"label": "Macheke", "value": "C148196"},
                               {"label": "Marondera", "value": "C148197"},
                               {"label": "Marondera District",
                                "value": "C148198"},
                               {"label": "Mudzi District", "value": "C148199"},
                               {"label": "Murehwa", "value": "C148200"},
                               {"label": "Murehwa District",
                                "value": "C148201"},
                               {"label": "Mutoko", "value": "C148202"},
                               {"label": "Ruwa", "value": "C148203"}],
 "Mashonaland West Province": [{"label": "Banket", "value": "C148204"},
                               {"label": "Chakari", "value": "C148205"},
                               {"label": "Chegutu", "value": "C148206"},
                               {"label": "Chegutu District",
                                "value": "C148207"},
                               {"label": "Chinhoyi", "value": "C148208"},
                               {"label": "Chirundu", "value": "C148209"},
                               {"label": "Hurungwe District",
                                "value": "C148210"},
                               {"label": "Kadoma", "value": "C148211"},
                               {"label": "Kadoma District", "value": "C148212"},
                               {"label": "Kariba", "value": "C148213"},
                               {"label": "Kariba District", "value": "C148214"},
                               {"label": "Karoi", "value": "C148215"},
                               {"label": "Makonde District",
                                "value": "C148216"},
                               {"label": "Mhangura", "value": "C148217"},
                               {"label": "Norton", "value": "C148218"},
                               {"label": "Raffingora", "value": "C148219"}],
 "Masovian Voivodeship": [{"label": "Baboszewo", "value": "C89138"},
                          {"label": "Baniocha", "value": "C89139"},
                          {"label": "Baranowo", "value": "C89140"},
                          {"label": "Bemowo", "value": "C89141"},
                          {"label": "Biala", "value": "C89142"},
                          {"label": "Bialobrzegi", "value": "C89143"},
                          {"label": "Bialoleka", "value": "C89144"},
                          {"label": "Bielany", "value": "C89145"},
                          {"label": "Bielsk", "value": "C89146"},
                          {"label": "Bieniewice", "value": "C89147"},
                          {"label": "Biezun", "value": "C89148"},
                          {"label": "Bledow", "value": "C89149"},
                          {"label": "Blonie", "value": "C89150"},
                          {"label": "Bodzanow", "value": "C89151"},
                          {"label": "Boguty-Pianki", "value": "C89152"},
                          {"label": "Borkowice", "value": "C89153"},
                          {"label": "Borowie", "value": "C89154"},
                          {"label": "Branszczyk", "value": "C89155"},
                          {"label": "Brochow", "value": "C89156"},
                          {"label": "Brody-Parcele", "value": "C89157"},
                          {"label": "Brok", "value": "C89158"},
                          {"label": "Brudzen Duzy", "value": "C89159"},
                          {"label": "Brwinow", "value": "C89160"},
                          {"label": "Bulkowo", "value": "C89161"},
                          {"label": "Ceglow", "value": "C89162"},
                          {"label": "Celestynow", "value": "C89163"},
                          {"label": "Ceranow", "value": "C89164"},
                          {"label": "Chlewiska", "value": "C89165"},
                          {"label": "Chorzele", "value": "C89166"},
                          {"label": "Chotcza", "value": "C89167"},
                          {"label": "Chynow", "value": "C89168"},
                          {"label": "Ciechanow", "value": "C89169"},
                          {"label": "Ciepielow", "value": "C89170"},
                          {"label": "Czarnia", "value": "C89171"},
                          {"label": "Czernice Borowe", "value": "C89172"},
                          {"label": "Czerwin", "value": "C89173"},
                          {"label": "Czerwinsk Nad Wisla", "value": "C89174"},
                          {"label": "Czerwonka", "value": "C89175"},
                          {"label": "Dabrowka", "value": "C89176"},
                          {"label": "Debe Wielkie", "value": "C89177"},
                          {"label": "Dlugosiodlo", "value": "C89178"},
                          {"label": "Dobre", "value": "C89179"},
                          {"label": "Domanice", "value": "C89180"},
                          {"label": "Drobin", "value": "C89181"},
                          {"label": "Duczki", "value": "C89182"},
                          {"label": "Dziekanow Lesny", "value": "C89183"},
                          {"label": "Dzierzaznia", "value": "C89184"},
                          {"label": "Dzierzgowo", "value": "C89185"},
                          {"label": "Gabin", "value": "C89186"},
                          {"label": "Garbatka-Letnisko", "value": "C89187"},
                          {"label": "Garwolin", "value": "C89188"},
                          {"label": "Gasocin", "value": "C89189"},
                          {"label": "Gielniow", "value": "C89190"},
                          {"label": "Glinojeck", "value": "C89191"},
                          {"label": "Golymin-Osrodek", "value": "C89192"},
                          {"label": "Gora Kalwaria", "value": "C89193"},
                          {"label": "Gorzno", "value": "C89194"},
                          {"label": "Gostynin", "value": "C89195"},
                          {"label": "Goszczyn", "value": "C89196"},
                          {"label": "Goworowo", "value": "C89197"},
                          {"label": "Gozd", "value": "C89198"},
                          {"label": "Gozdowo", "value": "C89199"},
                          {"label": "Granica", "value": "C89200"},
                          {"label": "Grebkow", "value": "C89201"},
                          {"label": "Grodzisk Mazowiecki", "value": "C89202"},
                          {"label": "Grojec", "value": "C89203"},
                          {"label": "Grudusk", "value": "C89204"},
                          {"label": "Guzow", "value": "C89205"},
                          {"label": "Gzy", "value": "C89206"},
                          {"label": "Halinow", "value": "C89207"},
                          {"label": "Hornowek", "value": "C89208"},
                          {"label": "Ilow", "value": "C89209"},
                          {"label": "Ilza", "value": "C89210"},
                          {"label": "Izabelin", "value": "C89211"},
                          {"label": "Jablonna", "value": "C89212"},
                          {"label": "Jablonna Lacka", "value": "C89213"},
                          {"label": "Jadow", "value": "C89214"},
                          {"label": "Jakubow", "value": "C89215"},
                          {"label": "Jasienica", "value": "C89216"},
                          {"label": "Jasieniec", "value": "C89217"},
                          {"label": "Jastrzab", "value": "C89218"},
                          {"label": "Jastrzebia", "value": "C89219"},
                          {"label": "Jedlinsk", "value": "C89220"},
                          {"label": "Jedlnia-Letnisko", "value": "C89221"},
                          {"label": "Jednorozec", "value": "C89222"},
                          {"label": "Joniec", "value": "C89223"},
                          {"label": "Jozefoslaw", "value": "C89224"},
                          {"label": "Jozefow", "value": "C89225"},
                          {"label": "Kabaty", "value": "C89226"},
                          {"label": "Kadzidlo", "value": "C89227"},
                          {"label": "Kaluszyn", "value": "C89228"},
                          {"label": "Kampinos", "value": "C89229"},
                          {"label": "Karczew", "value": "C89230"},
                          {"label": "Karniewo", "value": "C89231"},
                          {"label": "Kazanow", "value": "C89232"},
                          {"label": "Kielpin", "value": "C89233"},
                          {"label": "Klembow", "value": "C89234"},
                          {"label": "Klwow", "value": "C89235"},
                          {"label": "Kobylka", "value": "C89236"},
                          {"label": "Kolbiel", "value": "C89237"},
                          {"label": "Komorow", "value": "C89238"},
                          {"label": "Konstancin-Jeziorna", "value": "C89239"},
                          {"label": "Korczew", "value": "C89240"},
                          {"label": "Korytnica", "value": "C89241"},
                          {"label": "Kosow Lacki", "value": "C89242"},
                          {"label": "Kotun", "value": "C89243"},
                          {"label": "Kowala", "value": "C89244"},
                          {"label": "Kozienice", "value": "C89245"},
                          {"label": "Krasne", "value": "C89246"},
                          {"label": "Krasnosielc", "value": "C89247"},
                          {"label": "Krzynowloga Mala", "value": "C89248"},
                          {"label": "Kuczbork-Osada", "value": "C89249"},
                          {"label": "Latowicz", "value": "C89250"},
                          {"label": "Legionowo", "value": "C89251"},
                          {"label": "Leszno", "value": "C89252"},
                          {"label": "Lesznowola", "value": "C89253"},
                          {"label": "Lipowiec Koscielny", "value": "C89254"},
                          {"label": "Lipsko", "value": "C89255"},
                          {"label": "Lubowidz", "value": "C89256"},
                          {"label": "Lutocin", "value": "C89257"},
                          {"label": "Lack", "value": "C89258"},
                          {"label": "Lajski", "value": "C89259"},
                          {"label": "Laskarzew", "value": "C89260"},
                          {"label": "Lochow", "value": "C89261"},
                          {"label": "Lomianki", "value": "C89262"},
                          {"label": "Losice", "value": "C89263"},
                          {"label": "Lyse", "value": "C89264"},
                          {"label": "Maciejowice", "value": "C89265"},
                          {"label": "Makow Mazowiecki", "value": "C89266"},
                          {"label": "Mala Wies", "value": "C89267"},
                          {"label": "Malkinia Gorna", "value": "C89268"},
                          {"label": "Marki", "value": "C89269"},
                          {"label": "Maszewo Duze", "value": "C89270"},
                          {"label": "Miastkow Koscielny", "value": "C89271"},
                          {"label": "Michalow-Reginow", "value": "C89272"},
                          {"label": "Michalowice", "value": "C89273"},
                          {"label": "Miedzna", "value": "C89274"},
                          {"label": "Mietne", "value": "C89275"},
                          {"label": "Milanowek", "value": "C89276"},
                          {"label": "Minsk Mazowiecki", "value": "C89277"},
                          {"label": "Mlawa", "value": "C89278"},
                          {"label": "Mlodzieszyn", "value": "C89279"},
                          {"label": "Mlynarze", "value": "C89280"},
                          {"label": "Mochowo", "value": "C89281"},
                          {"label": "Mogielnica", "value": "C89282"},
                          {"label": "Mokobody", "value": "C89283"},
                          {"label": "Mokotow", "value": "C89284"},
                          {"label": "Mordy", "value": "C89285"},
                          {"label": "Mrozy", "value": "C89286"},
                          {"label": "Mszczonow", "value": "C89287"},
                          {"label": "Mysiadlo", "value": "C89288"},
                          {"label": "Myszyniec", "value": "C89289"},
                          {"label": "Nadarzyn", "value": "C89290"},
                          {"label": "Naruszewo", "value": "C89291"},
                          {"label": "Nasielsk", "value": "C89292"},
                          {"label": "Nieporet", "value": "C89293"},
                          {"label": "Nowe Grocholice", "value": "C89294"},
                          {"label": "Nowe Lipiny", "value": "C89295"},
                          {"label": "Nowe Miasto", "value": "C89296"},
                          {"label": "Nowe Miasto nad Pilica",
                           "value": "C89297"},
                          {"label": "Nowy Duninow", "value": "C89298"},
                          {"label": "Nowy Dwor Mazowiecki", "value": "C89299"},
                          {"label": "Nur", "value": "C89300"},
                          {"label": "Obryte", "value": "C89301"},
                          {"label": "Ochota", "value": "C89302"},
                          {"label": "Odrzywol", "value": "C89303"},
                          {"label": "Ojrzen", "value": "C89304"},
                          {"label": "Opinogora Gorna", "value": "C89305"},
                          {"label": "Oronsko", "value": "C89306"},
                          {"label": "Osieck", "value": "C89307"},
                          {"label": "Ostroleka", "value": "C89308"},
                          {"label": "Ostrow Mazowiecka", "value": "C89309"},
                          {"label": "Ostrowek", "value": "C89310"},
                          {"label": "Otrebusy", "value": "C89311"},
                          {"label": "Otwock", "value": "C89312"},
                          {"label": "Owczarnia", "value": "C89313"},
                          {"label": "Ozarow Mazowiecki", "value": "C89314"},
                          {"label": "Pacyna", "value": "C89315"},
                          {"label": "Paprotnia", "value": "C89316"},
                          {"label": "Parysow", "value": "C89317"},
                          {"label": "Piaseczno", "value": "C89318"},
                          {"label": "Piastow", "value": "C89319"},
                          {"label": "Pilawa", "value": "C89320"},
                          {"label": "Pionki", "value": "C89321"},
                          {"label": "Plock", "value": "C89322"},
                          {"label": "Ploniawy-Bramura", "value": "C89323"},
                          {"label": "Plonsk", "value": "C89324"},
                          {"label": "Podeblocie", "value": "C89325"},
                          {"label": "Podkowa Lesna", "value": "C89326"},
                          {"label": "Pokrzywnica", "value": "C89327"},
                          {"label": "Policzna", "value": "C89328"},
                          {"label": "Pomiechowek", "value": "C89329"},
                          {"label": "Poswietne", "value": "C89330"},
                          {"label": "Potworow", "value": "C89331"},
                          {"label": "Powiat bialobrzeski", "value": "C89332"},
                          {"label": "Powiat ciechanowski", "value": "C89333"},
                          {"label": "Powiat garwolinski", "value": "C89334"},
                          {"label": "Powiat gostyninski", "value": "C89335"},
                          {"label": "Powiat grodziski", "value": "C89336"},
                          {"label": "Powiat grojecki", "value": "C89337"},
                          {"label": "Powiat kozienicki", "value": "C89338"},
                          {"label": "Powiat legionowski", "value": "C89339"},
                          {"label": "Powiat lipski", "value": "C89340"},
                          {"label": "Powiat losicki", "value": "C89341"},
                          {"label": "Powiat makowski", "value": "C89342"},
                          {"label": "Powiat minski", "value": "C89343"},
                          {"label": "Powiat mlawski", "value": "C89344"},
                          {"label": "Powiat nowodworski", "value": "C89345"},
                          {"label": "Powiat ostrolecki", "value": "C89346"},
                          {"label": "Powiat ostrowski", "value": "C89347"},
                          {"label": "Powiat otwocki", "value": "C89348"},
                          {"label": "Powiat piaseczynski", "value": "C89349"},
                          {"label": "Powiat plocki", "value": "C89350"},
                          {"label": "Powiat plonski", "value": "C89351"},
                          {"label": "Powiat pruszkowski", "value": "C89352"},
                          {"label": "Powiat przasnyski", "value": "C89353"},
                          {"label": "Powiat przysuski", "value": "C89354"},
                          {"label": "Powiat pultuski", "value": "C89355"},
                          {"label": "Powiat radomski", "value": "C89356"},
                          {"label": "Powiat siedlecki", "value": "C89357"},
                          {"label": "Powiat sierpecki", "value": "C89358"},
                          {"label": "Powiat sochaczewski", "value": "C89359"},
                          {"label": "Powiat sokolowski", "value": "C89360"},
                          {"label": "Powiat szydlowiecki", "value": "C89361"},
                          {"label": "Powiat warszawski zachodni",
                           "value": "C89362"},
                          {"label": "Powiat wegrowski", "value": "C89363"},
                          {"label": "Powiat wolominski", "value": "C89364"},
                          {"label": "Powiat wyszkowski", "value": "C89365"},
                          {"label": "Powiat zurominski", "value": "C89366"},
                          {"label": "Powiat zwolenski", "value": "C89367"},
                          {"label": "Powiat zyrardowski", "value": "C89368"},
                          {"label": "Praga Polnoc", "value": "C89369"},
                          {"label": "Praga Poludnie", "value": "C89370"},
                          {"label": "Prazmow", "value": "C89371"},
                          {"label": "Promna", "value": "C89372"},
                          {"label": "Pruszkow", "value": "C89373"},
                          {"label": "Przasnysz", "value": "C89374"},
                          {"label": "Przesmyki", "value": "C89375"},
                          {"label": "Przylek", "value": "C89376"},
                          {"label": "Przysucha", "value": "C89377"},
                          {"label": "Przytyk", "value": "C89378"},
                          {"label": "Pultusk", "value": "C89379"},
                          {"label": "Puszcza Marianska", "value": "C89380"},
                          {"label": "Raciaz", "value": "C89381"},
                          {"label": "Radom", "value": "C89382"},
                          {"label": "Radzanow", "value": "C89383"},
                          {"label": "Radzanowo", "value": "C89384"},
                          {"label": "Radziejowice", "value": "C89385"},
                          {"label": "Radzymin", "value": "C89386"},
                          {"label": "Raszyn", "value": "C89387"},
                          {"label": "Regimin", "value": "C89388"},
                          {"label": "Rembertow", "value": "C89389"},
                          {"label": "Repki", "value": "C89390"},
                          {"label": "Rosciszewo", "value": "C89391"},
                          {"label": "Rozan", "value": "C89392"},
                          {"label": "Rusinow", "value": "C89393"},
                          {"label": "Rybie", "value": "C89394"},
                          {"label": "Rybno", "value": "C89395"},
                          {"label": "Ryczywol", "value": "C89396"},
                          {"label": "Rzasnik", "value": "C89397"},
                          {"label": "Rzeczniow", "value": "C89398"},
                          {"label": "Rzekun", "value": "C89399"},
                          {"label": "Rzewnie", "value": "C89400"},
                          {"label": "Sabnie", "value": "C89401"},
                          {"label": "Sadowne", "value": "C89402"},
                          {"label": "Sanniki", "value": "C89403"},
                          {"label": "Sarnaki", "value": "C89404"},
                          {"label": "Serock", "value": "C89405"},
                          {"label": "Siedlce", "value": "C89406"},
                          {"label": "Siemiatkowo", "value": "C89407"},
                          {"label": "Siennica", "value": "C89408"},
                          {"label": "Sienno", "value": "C89409"},
                          {"label": "Sierpc", "value": "C89410"},
                          {"label": "Skaryszew", "value": "C89411"},
                          {"label": "Skorzec", "value": "C89412"},
                          {"label": "Slubice", "value": "C89413"},
                          {"label": "Slupno", "value": "C89414"},
                          {"label": "Sobienie Jeziory", "value": "C89415"},
                          {"label": "Sobolew", "value": "C89416"},
                          {"label": "Sochaczew", "value": "C89417"},
                          {"label": "Sochocin", "value": "C89418"},
                          {"label": "Sokolow Podlaski", "value": "C89419"},
                          {"label": "Solec Nad Wisla", "value": "C89420"},
                          {"label": "Sonsk", "value": "C89421"},
                          {"label": "Srodmiescie", "value": "C89422"},
                          {"label": "Stanislawow", "value": "C89423"},
                          {"label": "Stanislawow Pierwszy", "value": "C89424"},
                          {"label": "Stara Blotnica", "value": "C89425"},
                          {"label": "Stara Kornica", "value": "C89426"},
                          {"label": "Stare Babice", "value": "C89427"},
                          {"label": "Starozreby", "value": "C89428"},
                          {"label": "Sterdyn", "value": "C89429"},
                          {"label": "Stoczek", "value": "C89430"},
                          {"label": "Strachowka", "value": "C89431"},
                          {"label": "Stromiec", "value": "C89432"},
                          {"label": "Strzegowo", "value": "C89433"},
                          {"label": "Stupsk", "value": "C89434"},
                          {"label": "Sucha", "value": "C89435"},
                          {"label": "Suchozebry", "value": "C89436"},
                          {"label": "Sulbiny Gorne", "value": "C89437"},
                          {"label": "Sulejowek", "value": "C89438"},
                          {"label": "Sulkowice", "value": "C89439"},
                          {"label": "Swiercze", "value": "C89440"},
                          {"label": "Sypniewo", "value": "C89441"},
                          {"label": "Szczutowo", "value": "C89442"},
                          {"label": "Szelkow", "value": "C89443"},
                          {"label": "Szrensk", "value": "C89444"},
                          {"label": "Szydlowiec", "value": "C89445"},
                          {"label": "Szydlowo", "value": "C89446"},
                          {"label": "Tarczyn", "value": "C89447"},
                          {"label": "Targowek", "value": "C89448"},
                          {"label": "Tczow", "value": "C89449"},
                          {"label": "Teresin", "value": "C89450"},
                          {"label": "Tluszcz", "value": "C89451"},
                          {"label": "Trabki", "value": "C89452"},
                          {"label": "Trablice", "value": "C89453"},
                          {"label": "Trojanow", "value": "C89454"},
                          {"label": "Troszyn", "value": "C89455"},
                          {"label": "Truskaw", "value": "C89456"},
                          {"label": "Ursus", "value": "C89457"},
                          {"label": "Ursynow", "value": "C89458"},
                          {"label": "Warka", "value": "C89459"},
                          {"label": "Warsaw", "value": "C89460"},
                          {"label": "Warszawa", "value": "C89461"},
                          {"label": "Wawer", "value": "C89462"},
                          {"label": "Wegrow", "value": "C89463"},
                          {"label": "Wesola", "value": "C89464"},
                          {"label": "Wieczfnia Koscielna", "value": "C89465"},
                          {"label": "Wieliszew", "value": "C89466"},
                          {"label": "Wieniawa", "value": "C89467"},
                          {"label": "Wierzbica", "value": "C89468"},
                          {"label": "Wierzbno", "value": "C89469"},
                          {"label": "Wilanow", "value": "C89470"},
                          {"label": "Wilga", "value": "C89471"},
                          {"label": "Winnica", "value": "C89472"},
                          {"label": "Wiskitki", "value": "C89473"},
                          {"label": "Wisniew", "value": "C89474"},
                          {"label": "Wisniewo", "value": "C89475"},
                          {"label": "Wlochy", "value": "C89476"},
                          {"label": "Wodynie", "value": "C89477"},
                          {"label": "Wola", "value": "C89478"},
                          {"label": "Wola Rebkowska", "value": "C89479"},
                          {"label": "Wolanow", "value": "C89480"},
                          {"label": "Wolomin", "value": "C89481"},
                          {"label": "Wysmierzyce", "value": "C89482"},
                          {"label": "Wyszkow", "value": "C89483"},
                          {"label": "Wyszogrod", "value": "C89484"},
                          {"label": "Zabia Wola", "value": "C89485"},
                          {"label": "Zabieniec", "value": "C89486"},
                          {"label": "Zabki", "value": "C89487"},
                          {"label": "Zakroczym", "value": "C89488"},
                          {"label": "Zakrzew", "value": "C89489"},
                          {"label": "Zalesie Gorne", "value": "C89490"},
                          {"label": "Zaluski", "value": "C89491"},
                          {"label": "Zatory", "value": "C89492"},
                          {"label": "Zawidz", "value": "C89493"},
                          {"label": "Zbuczyn", "value": "C89494"},
                          {"label": "Zelechow", "value": "C89495"},
                          {"label": "Zielonka", "value": "C89496"},
                          {"label": "Zoliborz", "value": "C89497"},
                          {"label": "Zuromin", "value": "C89498"},
                          {"label": "Zwolen", "value": "C89499"},
                          {"label": "Zyrardow", "value": "C89500"}],
 "Massachusetts": [{"label": "Abington", "value": "C134654"},
                   {"label": "Acton", "value": "C134655"},
                   {"label": "Acushnet", "value": "C134656"},
                   {"label": "Acushnet Center", "value": "C134657"},
                   {"label": "Adams", "value": "C134658"},
                   {"label": "Agawam", "value": "C134659"},
                   {"label": "Amesbury", "value": "C134660"},
                   {"label": "Amherst", "value": "C134661"},
                   {"label": "Amherst Center", "value": "C134662"},
                   {"label": "Andover", "value": "C134663"},
                   {"label": "Arlington", "value": "C134664"},
                   {"label": "Ashburnham", "value": "C134665"},
                   {"label": "Ashby", "value": "C134666"},
                   {"label": "Ashfield", "value": "C134667"},
                   {"label": "Ashland", "value": "C134668"},
                   {"label": "Assonet", "value": "C134669"},
                   {"label": "Athol", "value": "C134670"},
                   {"label": "Attleboro", "value": "C134671"},
                   {"label": "Auburn", "value": "C134672"},
                   {"label": "Avon", "value": "C134673"},
                   {"label": "Ayer", "value": "C134674"},
                   {"label": "Baldwinville", "value": "C134675"},
                   {"label": "Barnstable", "value": "C134676"},
                   {"label": "Barnstable County", "value": "C134677"},
                   {"label": "Barre", "value": "C134678"},
                   {"label": "Becket", "value": "C134679"},
                   {"label": "Bedford", "value": "C134680"},
                   {"label": "Belchertown", "value": "C134681"},
                   {"label": "Bellingham", "value": "C134682"},
                   {"label": "Belmont", "value": "C134683"},
                   {"label": "Berkley", "value": "C134684"},
                   {"label": "Berkshire County", "value": "C134685"},
                   {"label": "Berlin", "value": "C134686"},
                   {"label": "Bernardston", "value": "C134687"},
                   {"label": "Beverly", "value": "C134688"},
                   {"label": "Beverly Cove", "value": "C134689"},
                   {"label": "Billerica", "value": "C134690"},
                   {"label": "Blackstone", "value": "C134691"},
                   {"label": "Bliss Corner", "value": "C134692"},
                   {"label": "Bolton", "value": "C134693"},
                   {"label": "Bondsville", "value": "C134694"},
                   {"label": "Boston", "value": "C134695"},
                   {"label": "Bourne", "value": "C134696"},
                   {"label": "Boxborough", "value": "C134697"},
                   {"label": "Boxford", "value": "C134698"},
                   {"label": "Boylston", "value": "C134699"},
                   {"label": "Braintree", "value": "C134700"},
                   {"label": "Brewster", "value": "C134701"},
                   {"label": "Bridgewater", "value": "C134702"},
                   {"label": "Brimfield", "value": "C134703"},
                   {"label": "Bristol County", "value": "C134704"},
                   {"label": "Brockton", "value": "C134705"},
                   {"label": "Brookline", "value": "C134706"},
                   {"label": "Buckland", "value": "C134707"},
                   {"label": "Burlington", "value": "C134708"},
                   {"label": "Buzzards Bay", "value": "C134709"},
                   {"label": "Cambridge", "value": "C134710"},
                   {"label": "Canton", "value": "C134711"},
                   {"label": "Carlisle", "value": "C134712"},
                   {"label": "Carver", "value": "C134713"},
                   {"label": "Centerville", "value": "C134714"},
                   {"label": "Charlemont", "value": "C134715"},
                   {"label": "Charlton", "value": "C134716"},
                   {"label": "Chatham", "value": "C134717"},
                   {"label": "Chelmsford", "value": "C134718"},
                   {"label": "Chelsea", "value": "C134719"},
                   {"label": "Chesterfield", "value": "C134720"},
                   {"label": "Chicopee", "value": "C134721"},
                   {"label": "Clinton", "value": "C134722"},
                   {"label": "Cochituate", "value": "C134723"},
                   {"label": "Cohasset", "value": "C134724"},
                   {"label": "Colrain", "value": "C134725"},
                   {"label": "Concord", "value": "C134726"},
                   {"label": "Conway", "value": "C134727"},
                   {"label": "Cordaville", "value": "C134728"},
                   {"label": "Cotuit", "value": "C134729"},
                   {"label": "Dalton", "value": "C134730"},
                   {"label": "Danvers", "value": "C134731"},
                   {"label": "Dartmouth", "value": "C134732"},
                   {"label": "Dedham", "value": "C134733"},
                   {"label": "Dennis", "value": "C134734"},
                   {"label": "Dennis Port", "value": "C134735"},
                   {"label": "Devens", "value": "C134736"},
                   {"label": "Dighton", "value": "C134737"},
                   {"label": "Douglas", "value": "C134738"},
                   {"label": "Dover", "value": "C134739"},
                   {"label": "Dracut", "value": "C134740"},
                   {"label": "Dudley", "value": "C134741"},
                   {"label": "Dukes County", "value": "C134742"},
                   {"label": "Dunstable", "value": "C134743"},
                   {"label": "Duxbury", "value": "C134744"},
                   {"label": "East Bridgewater", "value": "C134745"},
                   {"label": "East Brookfield", "value": "C134746"},
                   {"label": "East Dennis", "value": "C134747"},
                   {"label": "East Douglas", "value": "C134748"},
                   {"label": "East Falmouth", "value": "C134749"},
                   {"label": "East Harwich", "value": "C134750"},
                   {"label": "East Longmeadow", "value": "C134751"},
                   {"label": "East Pepperell", "value": "C134752"},
                   {"label": "East Sandwich", "value": "C134753"},
                   {"label": "Eastham", "value": "C134754"},
                   {"label": "Easthampton", "value": "C134755"},
                   {"label": "Easton", "value": "C134756"},
                   {"label": "Edgartown", "value": "C134757"},
                   {"label": "Erving", "value": "C134758"},
                   {"label": "Essex", "value": "C134759"},
                   {"label": "Essex County", "value": "C134760"},
                   {"label": "Everett", "value": "C134761"},
                   {"label": "Fairhaven", "value": "C134762"},
                   {"label": "Fall River", "value": "C134763"},
                   {"label": "Falmouth", "value": "C134764"},
                   {"label": "Fiskdale", "value": "C134765"},
                   {"label": "Fitchburg", "value": "C134766"},
                   {"label": "Forestdale", "value": "C134767"},
                   {"label": "Foxborough", "value": "C134768"},
                   {"label": "Framingham", "value": "C134769"},
                   {"label": "Framingham Center", "value": "C134770"},
                   {"label": "Franklin", "value": "C134771"},
                   {"label": "Franklin County", "value": "C134772"},
                   {"label": "Freetown", "value": "C134773"},
                   {"label": "Gardner", "value": "C134774"},
                   {"label": "Gill", "value": "C134775"},
                   {"label": "Gloucester", "value": "C134776"},
                   {"label": "Grafton", "value": "C134777"},
                   {"label": "Granby", "value": "C134778"},
                   {"label": "Granville", "value": "C134779"},
                   {"label": "Great Barrington", "value": "C134780"},
                   {"label": "Green Harbor-Cedar Crest", "value": "C134781"},
                   {"label": "Greenfield", "value": "C134782"},
                   {"label": "Groton", "value": "C134783"},
                   {"label": "Groveland", "value": "C134784"},
                   {"label": "Hadley", "value": "C134785"},
                   {"label": "Halifax", "value": "C134786"},
                   {"label": "Hamilton Worcester", "value": "C134787"},
                   {"label": "Hampden", "value": "C134788"},
                   {"label": "Hampden County", "value": "C134789"},
                   {"label": "Hampshire County", "value": "C134790"},
                   {"label": "Hanover", "value": "C134791"},
                   {"label": "Hanson", "value": "C134792"},
                   {"label": "Hardwick", "value": "C134793"},
                   {"label": "Harvard", "value": "C134794"},
                   {"label": "Harwich", "value": "C134795"},
                   {"label": "Harwich Center", "value": "C134796"},
                   {"label": "Harwich Port", "value": "C134797"},
                   {"label": "Hatfield", "value": "C134798"},
                   {"label": "Haverhill", "value": "C134799"},
                   {"label": "Head of Westport", "value": "C134800"},
                   {"label": "Hingham", "value": "C134801"},
                   {"label": "Hinsdale", "value": "C134802"},
                   {"label": "Holbrook", "value": "C134803"},
                   {"label": "Holden", "value": "C134804"},
                   {"label": "Holland", "value": "C134805"},
                   {"label": "Holliston", "value": "C134806"},
                   {"label": "Holyoke", "value": "C134807"},
                   {"label": "Hopedale", "value": "C134808"},
                   {"label": "Hopkinton", "value": "C134809"},
                   {"label": "Housatonic", "value": "C134810"},
                   {"label": "Hubbardston", "value": "C134811"},
                   {"label": "Hudson", "value": "C134812"},
                   {"label": "Hull", "value": "C134813"},
                   {"label": "Hyannis", "value": "C134814"},
                   {"label": "Ipswich", "value": "C134815"},
                   {"label": "Jamaica Plain", "value": "C134816"},
                   {"label": "Kingston", "value": "C134817"},
                   {"label": "Lancaster", "value": "C134818"},
                   {"label": "Lanesborough", "value": "C134819"},
                   {"label": "Lawrence", "value": "C134820"},
                   {"label": "Lee", "value": "C134821"},
                   {"label": "Leicester", "value": "C134822"},
                   {"label": "Lenox", "value": "C134823"},
                   {"label": "Leominster", "value": "C134824"},
                   {"label": "Leverett", "value": "C134825"},
                   {"label": "Lexington", "value": "C134826"},
                   {"label": "Lincoln", "value": "C134827"},
                   {"label": "Littleton Common", "value": "C134828"},
                   {"label": "Longmeadow", "value": "C134829"},
                   {"label": "Lowell", "value": "C134830"},
                   {"label": "Ludlow", "value": "C134831"},
                   {"label": "Lunenburg", "value": "C134832"},
                   {"label": "Lynn", "value": "C134833"},
                   {"label": "Lynnfield", "value": "C134834"},
                   {"label": "Malden", "value": "C134835"},
                   {"label": "Manchester-by-the-Sea", "value": "C134836"},
                   {"label": "Mansfield", "value": "C134837"},
                   {"label": "Mansfield Center", "value": "C134838"},
                   {"label": "Marblehead", "value": "C134839"},
                   {"label": "Marion", "value": "C134840"},
                   {"label": "Marion Center", "value": "C134841"},
                   {"label": "Marlborough", "value": "C134842"},
                   {"label": "Marshfield", "value": "C134843"},
                   {"label": "Marshfield Hills", "value": "C134844"},
                   {"label": "Marstons Mills", "value": "C134845"},
                   {"label": "Mashpee", "value": "C134846"},
                   {"label": "Mattapoisett", "value": "C134847"},
                   {"label": "Mattapoisett Center", "value": "C134848"},
                   {"label": "Maynard", "value": "C134849"},
                   {"label": "Medfield", "value": "C134850"},
                   {"label": "Medford", "value": "C134851"},
                   {"label": "Medway", "value": "C134852"},
                   {"label": "Melrose", "value": "C134853"},
                   {"label": "Mendon", "value": "C134854"},
                   {"label": "Merrimac", "value": "C134855"},
                   {"label": "Methuen", "value": "C134856"},
                   {"label": "Middleborough", "value": "C134857"},
                   {"label": "Middleborough Center", "value": "C134858"},
                   {"label": "Middlesex County", "value": "C134859"},
                   {"label": "Middleton", "value": "C134860"},
                   {"label": "Milford", "value": "C134861"},
                   {"label": "Millbury", "value": "C134862"},
                   {"label": "Millers Falls", "value": "C134863"},
                   {"label": "Millis", "value": "C134864"},
                   {"label": "Millis-Clicquot", "value": "C134865"},
                   {"label": "Millville", "value": "C134866"},
                   {"label": "Milton", "value": "C134867"},
                   {"label": "Monson", "value": "C134868"},
                   {"label": "Monson Center", "value": "C134869"},
                   {"label": "Montague", "value": "C134870"},
                   {"label": "Monument Beach", "value": "C134871"},
                   {"label": "Nahant", "value": "C134872"},
                   {"label": "Nantucket", "value": "C134873"},
                   {"label": "Nantucket County", "value": "C134874"},
                   {"label": "Natick", "value": "C134875"},
                   {"label": "Needham", "value": "C134876"},
                   {"label": "New Bedford", "value": "C134877"},
                   {"label": "New Marlborough", "value": "C134878"},
                   {"label": "Newburyport", "value": "C134879"},
                   {"label": "Newton", "value": "C134880"},
                   {"label": "Norfolk", "value": "C134881"},
                   {"label": "Norfolk County", "value": "C134882"},
                   {"label": "North Adams", "value": "C134883"},
                   {"label": "North Amherst", "value": "C134884"},
                   {"label": "North Andover", "value": "C134885"},
                   {"label": "North Attleborough Center", "value": "C134886"},
                   {"label": "North Brookfield", "value": "C134887"},
                   {"label": "North Chicopee", "value": "C134888"},
                   {"label": "North Eastham", "value": "C134889"},
                   {"label": "North Falmouth", "value": "C134890"},
                   {"label": "North Lakeville", "value": "C134891"},
                   {"label": "North Pembroke", "value": "C134892"},
                   {"label": "North Plymouth", "value": "C134893"},
                   {"label": "North Reading", "value": "C134894"},
                   {"label": "North Scituate", "value": "C134895"},
                   {"label": "North Seekonk", "value": "C134896"},
                   {"label": "North Westport", "value": "C134897"},
                   {"label": "Northampton", "value": "C134898"},
                   {"label": "Northborough", "value": "C134899"},
                   {"label": "Northbridge", "value": "C134900"},
                   {"label": "Northfield", "value": "C134901"},
                   {"label": "Northwest Harwich", "value": "C134902"},
                   {"label": "Norton", "value": "C134903"},
                   {"label": "Norton Center", "value": "C134904"},
                   {"label": "Norwell", "value": "C134905"},
                   {"label": "Norwood", "value": "C134906"},
                   {"label": "Oak Bluffs", "value": "C134907"},
                   {"label": "Oakham", "value": "C134908"},
                   {"label": "Ocean Bluff-Brant Rock", "value": "C134909"},
                   {"label": "Ocean Grove", "value": "C134910"},
                   {"label": "Onset", "value": "C134911"},
                   {"label": "Orange", "value": "C134912"},
                   {"label": "Orleans", "value": "C134913"},
                   {"label": "Osterville", "value": "C134914"},
                   {"label": "Otis", "value": "C134915"},
                   {"label": "Oxford", "value": "C134916"},
                   {"label": "Palmer", "value": "C134917"},
                   {"label": "Paxton", "value": "C134918"},
                   {"label": "Peabody", "value": "C134919"},
                   {"label": "Pelham", "value": "C134920"},
                   {"label": "Pepperell", "value": "C134921"},
                   {"label": "Phillipston", "value": "C134922"},
                   {"label": "Pinehurst", "value": "C134923"},
                   {"label": "Pittsfield", "value": "C134924"},
                   {"label": "Plainville", "value": "C134925"},
                   {"label": "Plymouth", "value": "C134926"},
                   {"label": "Plymouth County", "value": "C134927"},
                   {"label": "Plympton", "value": "C134928"},
                   {"label": "Pocasset", "value": "C134929"},
                   {"label": "Princeton", "value": "C134930"},
                   {"label": "Provincetown", "value": "C134931"},
                   {"label": "Quincy", "value": "C134932"},
                   {"label": "Randolph", "value": "C134933"},
                   {"label": "Raynham", "value": "C134934"},
                   {"label": "Raynham Center", "value": "C134935"},
                   {"label": "Reading", "value": "C134936"},
                   {"label": "Rehoboth", "value": "C134937"},
                   {"label": "Revere", "value": "C134938"},
                   {"label": "Richmond", "value": "C134939"},
                   {"label": "Rochester", "value": "C134940"},
                   {"label": "Rockland", "value": "C134941"},
                   {"label": "Rockport", "value": "C134942"},
                   {"label": "Rowley", "value": "C134943"},
                   {"label": "Royalston", "value": "C134944"},
                   {"label": "Rutland", "value": "C134945"},
                   {"label": "Sagamore", "value": "C134946"},
                   {"label": "Salem", "value": "C134947"},
                   {"label": "Salisbury", "value": "C134948"},
                   {"label": "Sandwich", "value": "C134949"},
                   {"label": "Saugus", "value": "C134950"},
                   {"label": "Scituate", "value": "C134951"},
                   {"label": "Seekonk", "value": "C134952"},
                   {"label": "Sharon", "value": "C134953"},
                   {"label": "Sheffield", "value": "C134954"},
                   {"label": "Shelburne", "value": "C134955"},
                   {"label": "Shelburne Falls", "value": "C134956"},
                   {"label": "Sherborn", "value": "C134957"},
                   {"label": "Shirley", "value": "C134958"},
                   {"label": "Shrewsbury", "value": "C134959"},
                   {"label": "Shutesbury", "value": "C134960"},
                   {"label": "Smith Mills", "value": "C134961"},
                   {"label": "Somerset", "value": "C134962"},
                   {"label": "Somerville", "value": "C134963"},
                   {"label": "South Amherst", "value": "C134964"},
                   {"label": "South Ashburnham", "value": "C134965"},
                   {"label": "South Boston", "value": "C134966"},
                   {"label": "South Deerfield", "value": "C134967"},
                   {"label": "South Dennis", "value": "C134968"},
                   {"label": "South Duxbury", "value": "C134969"},
                   {"label": "South Hadley", "value": "C134970"},
                   {"label": "South Lancaster", "value": "C134971"},
                   {"label": "South Peabody", "value": "C134972"},
                   {"label": "South Yarmouth", "value": "C134973"},
                   {"label": "Southampton", "value": "C134974"},
                   {"label": "Southborough", "value": "C134975"},
                   {"label": "Southbridge", "value": "C134976"},
                   {"label": "Southwick", "value": "C134977"},
                   {"label": "Spencer", "value": "C134978"},
                   {"label": "Springfield", "value": "C134979"},
                   {"label": "Sterling", "value": "C134980"},
                   {"label": "Stockbridge", "value": "C134981"},
                   {"label": "Stoneham", "value": "C134982"},
                   {"label": "Stoughton", "value": "C134983"},
                   {"label": "Stow", "value": "C134984"},
                   {"label": "Sturbridge", "value": "C134985"},
                   {"label": "Sudbury", "value": "C134986"},
                   {"label": "Suffolk County", "value": "C134987"},
                   {"label": "Sunderland", "value": "C134988"},
                   {"label": "Sutton", "value": "C134989"},
                   {"label": "Swampscott", "value": "C134990"},
                   {"label": "Swansea", "value": "C134991"},
                   {"label": "Taunton", "value": "C134992"},
                   {"label": "Teaticket", "value": "C134993"},
                   {"label": "Templeton", "value": "C134994"},
                   {"label": "Tewksbury", "value": "C134995"},
                   {"label": "Three Rivers", "value": "C134996"},
                   {"label": "Topsfield", "value": "C134997"},
                   {"label": "Townsend", "value": "C134998"},
                   {"label": "Truro", "value": "C134999"},
                   {"label": "Turners Falls", "value": "C135000"},
                   {"label": "Tyngsboro", "value": "C135001"},
                   {"label": "Upton", "value": "C135002"},
                   {"label": "Uxbridge", "value": "C135003"},
                   {"label": "Vineyard Haven", "value": "C135004"},
                   {"label": "Wakefield", "value": "C135005"},
                   {"label": "Wales", "value": "C135006"},
                   {"label": "Walpole", "value": "C135007"},
                   {"label": "Waltham", "value": "C135008"},
                   {"label": "Ware", "value": "C135009"},
                   {"label": "Wareham Center", "value": "C135010"},
                   {"label": "Warren", "value": "C135011"},
                   {"label": "Watertown", "value": "C135012"},
                   {"label": "Wayland", "value": "C135013"},
                   {"label": "Webster", "value": "C135014"},
                   {"label": "Wellesley", "value": "C135015"},
                   {"label": "Wellfleet", "value": "C135016"},
                   {"label": "Wendell", "value": "C135017"},
                   {"label": "Wenham", "value": "C135018"},
                   {"label": "West Barnstable", "value": "C135019"},
                   {"label": "West Boylston", "value": "C135020"},
                   {"label": "West Bridgewater", "value": "C135021"},
                   {"label": "West Brookfield", "value": "C135022"},
                   {"label": "West Chatham", "value": "C135023"},
                   {"label": "West Concord", "value": "C135024"},
                   {"label": "West Dennis", "value": "C135025"},
                   {"label": "West Falmouth", "value": "C135026"},
                   {"label": "West Newbury", "value": "C135027"},
                   {"label": "West Springfield", "value": "C135028"},
                   {"label": "West Stockbridge", "value": "C135029"},
                   {"label": "West Tisbury", "value": "C135030"},
                   {"label": "West Wareham", "value": "C135031"},
                   {"label": "West Yarmouth", "value": "C135032"},
                   {"label": "Westborough", "value": "C135033"},
                   {"label": "Westfield", "value": "C135034"},
                   {"label": "Westford", "value": "C135035"},
                   {"label": "Westhampton", "value": "C135036"},
                   {"label": "Westminster", "value": "C135037"},
                   {"label": "Weston", "value": "C135038"},
                   {"label": "Westwood", "value": "C135039"},
                   {"label": "Weweantic", "value": "C135040"},
                   {"label": "Weymouth", "value": "C135041"},
                   {"label": "Whately", "value": "C135042"},
                   {"label": "White Island Shores", "value": "C135043"},
                   {"label": "Whitinsville", "value": "C135044"},
                   {"label": "Whitman", "value": "C135045"},
                   {"label": "Wilbraham", "value": "C135046"},
                   {"label": "Williamsburg", "value": "C135047"},
                   {"label": "Williamstown", "value": "C135048"},
                   {"label": "Wilmington", "value": "C135049"},
                   {"label": "Winchendon", "value": "C135050"},
                   {"label": "Winchester", "value": "C135051"},
                   {"label": "Winthrop", "value": "C135052"},
                   {"label": "Woburn", "value": "C135053"},
                   {"label": "Worcester", "value": "C135054"},
                   {"label": "Worcester County", "value": "C135055"},
                   {"label": "Wrentham", "value": "C135056"},
                   {"label": "Yarmouth", "value": "C135057"},
                   {"label": "Yarmouth Port", "value": "C135058"}],
 "Masvingo Province": [{"label": "Bikita District", "value": "C148220"},
                       {"label": "Chiredzi", "value": "C148221"},
                       {"label": "Chiredzi District", "value": "C148222"},
                       {"label": "Chivi District", "value": "C148223"},
                       {"label": "Gutu District", "value": "C148224"},
                       {"label": "Mashava", "value": "C148225"},
                       {"label": "Masvingo", "value": "C148226"},
                       {"label": "Masvingo District", "value": "C148227"},
                       {"label": "Mwenezi District", "value": "C148228"},
                       {"label": "Zvishavane", "value": "C148229"}],
 "Matabeleland North Province": [{"label": "Binga", "value": "C148230"},
                                 {"label": "Binga District",
                                  "value": "C148231"},
                                 {"label": "Bubi District", "value": "C148232"},
                                 {"label": "Dete", "value": "C148233"},
                                 {"label": "Hwange", "value": "C148234"},
                                 {"label": "Hwange District",
                                  "value": "C148235"},
                                 {"label": "Inyati", "value": "C148236"},
                                 {"label": "Kamativi Mine", "value": "C148237"},
                                 {"label": "Lupane", "value": "C148238"},
                                 {"label": "Lupane District",
                                  "value": "C148239"},
                                 {"label": "Nkayi District",
                                  "value": "C148240"},
                                 {"label": "Victoria Falls",
                                  "value": "C148241"}],
 "Matabeleland South Province": [{"label": "Beitbridge", "value": "C148242"},
                                 {"label": "Beitbridge District",
                                  "value": "C148243"},
                                 {"label": "Esigodini", "value": "C148244"},
                                 {"label": "Filabusi", "value": "C148245"},
                                 {"label": "Gwanda", "value": "C148246"},
                                 {"label": "Gwanda District",
                                  "value": "C148247"},
                                 {"label": "Insiza", "value": "C148248"},
                                 {"label": "Insiza District",
                                  "value": "C148249"},
                                 {"label": "Mangwe District",
                                  "value": "C148250"},
                                 {"label": "Matobo", "value": "C148251"},
                                 {"label": "Plumtree", "value": "C148252"},
                                 {"label": "Umzingwane District",
                                  "value": "C148253"}],
 "Matagalpa": [{"label": "Ciudad Dario", "value": "C77981"},
               {"label": "Matagalpa", "value": "C77982"},
               {"label": "Matiguas", "value": "C77983"},
               {"label": "Muy Muy", "value": "C77984"},
               {"label": "Rio Blanco", "value": "C77985"},
               {"label": "San Dionisio", "value": "C77986"},
               {"label": "San Ramon", "value": "C77987"},
               {"label": "Terrabona", "value": "C77988"}],
 "Matam": [{"label": "Diawara", "value": "C106860"},
           {"label": "Kanel", "value": "C106861"},
           {"label": "Matam", "value": "C106862"},
           {"label": "Matam Department", "value": "C106863"},
           {"label": "Ouro Sogui", "value": "C106864"},
           {"label": "Ranerou", "value": "C106865"},
           {"label": "Seme", "value": "C106866"},
           {"label": "Waounde", "value": "C106867"}],
 "Matanzas Province": [{"label": "Alacranes", "value": "C21773"},
                       {"label": "Bolondron", "value": "C21774"},
                       {"label": "Calimete", "value": "C21775"},
                       {"label": "Cardenas", "value": "C21776"},
                       {"label": "Colon", "value": "C21777"},
                       {"label": "Jaguey Grande", "value": "C21778"},
                       {"label": "Jovellanos", "value": "C21779"},
                       {"label": "Limonar", "value": "C21780"},
                       {"label": "Los Arabos", "value": "C21781"},
                       {"label": "Manguito", "value": "C21782"},
                       {"label": "Marti", "value": "C21783"},
                       {"label": "Matanzas", "value": "C21784"},
                       {"label": "Municipio de Cardenas", "value": "C21785"},
                       {"label": "Municipio de Matanzas", "value": "C21786"},
                       {"label": "Pedro Betancourt", "value": "C21787"},
                       {"label": "Perico", "value": "C21788"},
                       {"label": "Union de Reyes", "value": "C21789"},
                       {"label": "Varadero", "value": "C21790"}],
 "Mato Grosso": [{"label": "Acorizal", "value": "C11553"},
                 {"label": "agua Boa", "value": "C11554"},
                 {"label": "Alta Floresta", "value": "C11555"},
                 {"label": "Alto Araguaia", "value": "C11556"},
                 {"label": "Alto Boa Vista", "value": "C11557"},
                 {"label": "Alto Garcas", "value": "C11558"},
                 {"label": "Alto Paraguai", "value": "C11559"},
                 {"label": "Alto Taquari", "value": "C11560"},
                 {"label": "Apiacas", "value": "C11561"},
                 {"label": "Araguaiana", "value": "C11562"},
                 {"label": "Araguainha", "value": "C11563"},
                 {"label": "Araputanga", "value": "C11564"},
                 {"label": "Arenapolis", "value": "C11565"},
                 {"label": "Aripuana", "value": "C11566"},
                 {"label": "Barao de Melgaco", "value": "C11567"},
                 {"label": "Barra do Bugres", "value": "C11568"},
                 {"label": "Barra do Garcas", "value": "C11569"},
                 {"label": "Bom Jesus do Araguaia", "value": "C11570"},
                 {"label": "Brasnorte", "value": "C11571"},
                 {"label": "Caceres", "value": "C11572"},
                 {"label": "Campinapolis", "value": "C11573"},
                 {"label": "Campo Novo do Parecis", "value": "C11574"},
                 {"label": "Campo Verde", "value": "C11575"},
                 {"label": "Campos de Julio", "value": "C11576"},
                 {"label": "CanaBrava do Norte", "value": "C11577"},
                 {"label": "Canarana", "value": "C11578"},
                 {"label": "Carlinda", "value": "C11579"},
                 {"label": "Castanheira", "value": "C11580"},
                 {"label": "Chapada dos Guimaraes", "value": "C11581"},
                 {"label": "Claudia", "value": "C11582"},
                 {"label": "Cocalinho", "value": "C11583"},
                 {"label": "Colider", "value": "C11584"},
                 {"label": "Colniza", "value": "C11585"},
                 {"label": "Comodoro", "value": "C11586"},
                 {"label": "Confresa", "value": "C11587"},
                 {"label": "Conquista Doeste", "value": "C11588"},
                 {"label": "Cotriguacu", "value": "C11589"},
                 {"label": "Cuiaba", "value": "C11590"},
                 {"label": "Curvelandia", "value": "C11591"},
                 {"label": "Denise", "value": "C11592"},
                 {"label": "Diamantino", "value": "C11593"},
                 {"label": "Dom Aquino", "value": "C11594"},
                 {"label": "Feliz Natal", "value": "C11595"},
                 {"label": "Figueiropolis dOeste", "value": "C11596"},
                 {"label": "Gaucha do Norte", "value": "C11597"},
                 {"label": "General Carneiro", "value": "C11598"},
                 {"label": "Gloria dOeste", "value": "C11599"},
                 {"label": "Guaranta do Norte", "value": "C11600"},
                 {"label": "Guiratinga", "value": "C11601"},
                 {"label": "Indiavai", "value": "C11602"},
                 {"label": "Ipiranga do Norte", "value": "C11603"},
                 {"label": "Itanhanga", "value": "C11604"},
                 {"label": "Itauba", "value": "C11605"},
                 {"label": "Itiquira", "value": "C11606"},
                 {"label": "Jaciara", "value": "C11607"},
                 {"label": "Jangada", "value": "C11608"},
                 {"label": "Jauru", "value": "C11609"},
                 {"label": "Juara", "value": "C11610"},
                 {"label": "Juina", "value": "C11611"},
                 {"label": "Juruena", "value": "C11612"},
                 {"label": "Juscimeira", "value": "C11613"},
                 {"label": "Lambari dOeste", "value": "C11614"},
                 {"label": "Lucas", "value": "C11615"},
                 {"label": "Lucas do Rio Verde", "value": "C11616"},
                 {"label": "Luciara", "value": "C11617"},
                 {"label": "Marcelandia", "value": "C11618"},
                 {"label": "Matupa", "value": "C11619"},
                 {"label": "Mirassol dOeste", "value": "C11620"},
                 {"label": "Nobres", "value": "C11621"},
                 {"label": "Nortelandia", "value": "C11622"},
                 {"label": "Nossa Senhora do Livramento", "value": "C11623"},
                 {"label": "Nova Bandeirantes", "value": "C11624"},
                 {"label": "Nova Brasilandia", "value": "C11625"},
                 {"label": "Nova Canaa do Norte", "value": "C11626"},
                 {"label": "Nova Guarita", "value": "C11627"},
                 {"label": "Nova Lacerda", "value": "C11628"},
                 {"label": "Nova Marilandia", "value": "C11629"},
                 {"label": "Nova Maringa", "value": "C11630"},
                 {"label": "Nova Monte Verde", "value": "C11631"},
                 {"label": "Nova Mutum", "value": "C11632"},
                 {"label": "Nova Nazare", "value": "C11633"},
                 {"label": "Nova Olimpia", "value": "C11634"},
                 {"label": "Nova Santa Helena", "value": "C11635"},
                 {"label": "Nova Ubirata", "value": "C11636"},
                 {"label": "Nova Xavantina", "value": "C11637"},
                 {"label": "Novo Horizonte do Norte", "value": "C11638"},
                 {"label": "Novo Mundo", "value": "C11639"},
                 {"label": "Novo Santo Antonio", "value": "C11640"},
                 {"label": "Novo Sao Joaquim", "value": "C11641"},
                 {"label": "Paranaita", "value": "C11642"},
                 {"label": "Paranatinga", "value": "C11643"},
                 {"label": "Pedra Preta", "value": "C11644"},
                 {"label": "Peixoto de Azevedo", "value": "C11645"},
                 {"label": "Planalto da Serra", "value": "C11646"},
                 {"label": "Pocone", "value": "C11647"},
                 {"label": "Pontal do Araguaia", "value": "C11648"},
                 {"label": "Ponte Branca", "value": "C11649"},
                 {"label": "Pontes e Lacerda", "value": "C11650"},
                 {"label": "Porto Alegre do Norte", "value": "C11651"},
                 {"label": "Porto dos Gauchos", "value": "C11652"},
                 {"label": "Porto Esperidiao", "value": "C11653"},
                 {"label": "Porto Estrela", "value": "C11654"},
                 {"label": "Poxoreo", "value": "C11655"},
                 {"label": "Poxoreu", "value": "C11656"},
                 {"label": "Primavera do Leste", "value": "C11657"},
                 {"label": "Querencia", "value": "C11658"},
                 {"label": "Reserva do Cabacal", "value": "C11659"},
                 {"label": "Ribeirao Cascalheira", "value": "C11660"},
                 {"label": "Ribeiraozinho", "value": "C11661"},
                 {"label": "Rio Branco", "value": "C11662"},
                 {"label": "Rondolandia", "value": "C11663"},
                 {"label": "Rondonopolis", "value": "C11664"},
                 {"label": "Rosario Oeste", "value": "C11665"},
                 {"label": "Salto do Ceu", "value": "C11666"},
                 {"label": "Santa Carmem", "value": "C11667"},
                 {"label": "Santa Cruz do Xingu", "value": "C11668"},
                 {"label": "Santa Rita do Trivelato", "value": "C11669"},
                 {"label": "Santa Terezinha", "value": "C11670"},
                 {"label": "Santo Afonso", "value": "C11671"},
                 {"label": "Santo Antonio do Leste", "value": "C11672"},
                 {"label": "Santo Antonio do Leverger", "value": "C11673"},
                 {"label": "Sao Felix do Araguaia", "value": "C11674"},
                 {"label": "Sao Jose do Povo", "value": "C11675"},
                 {"label": "Sao Jose do Rio Claro", "value": "C11676"},
                 {"label": "Sao Jose do Xingu", "value": "C11677"},
                 {"label": "Sao Jose dos Quatro Marcos", "value": "C11678"},
                 {"label": "Sao Pedro da Cipa", "value": "C11679"},
                 {"label": "Sapezal", "value": "C11680"},
                 {"label": "Serra Nova Dourada", "value": "C11681"},
                 {"label": "Sinop", "value": "C11682"},
                 {"label": "Sorriso", "value": "C11683"},
                 {"label": "Tabapora", "value": "C11684"},
                 {"label": "Tangara da Serra", "value": "C11685"},
                 {"label": "Tapurah", "value": "C11686"},
                 {"label": "Terra Nova do Norte", "value": "C11687"},
                 {"label": "Tesouro", "value": "C11688"},
                 {"label": "Torixoreu", "value": "C11689"},
                 {"label": "Uniao do Sul", "value": "C11690"},
                 {"label": "Vale de Sao Domingos", "value": "C11691"},
                 {"label": "Varzea Grande", "value": "C11692"},
                 {"label": "Vera", "value": "C11693"},
                 {"label": "Vila Bela da Santissima Trindade",
                  "value": "C11694"},
                 {"label": "Vila Rica", "value": "C11695"}],
 "Mato Grosso do Sul": [{"label": "agua Clara", "value": "C11696"},
                        {"label": "Alcinopolis", "value": "C11697"},
                        {"label": "Amambai", "value": "C11698"},
                        {"label": "Anastacio", "value": "C11699"},
                        {"label": "Anaurilandia", "value": "C11700"},
                        {"label": "Angelica", "value": "C11701"},
                        {"label": "Antonio Joao", "value": "C11702"},
                        {"label": "Aparecida do Taboado", "value": "C11703"},
                        {"label": "Aquidauana", "value": "C11704"},
                        {"label": "Aral Moreira", "value": "C11705"},
                        {"label": "Bandeirantes", "value": "C11706"},
                        {"label": "Bataguassu", "value": "C11707"},
                        {"label": "Bataipora", "value": "C11708"},
                        {"label": "Bataypora", "value": "C11709"},
                        {"label": "Bela Vista", "value": "C11710"},
                        {"label": "Bodoquena", "value": "C11711"},
                        {"label": "Bonito", "value": "C11712"},
                        {"label": "Brasilandia", "value": "C11713"},
                        {"label": "Caarapo", "value": "C11714"},
                        {"label": "Camapua", "value": "C11715"},
                        {"label": "Campo Grande", "value": "C11716"},
                        {"label": "Campo Verde", "value": "C11717"},
                        {"label": "Caracol", "value": "C11718"},
                        {"label": "Cassilandia", "value": "C11719"},
                        {"label": "Chapadao do Sul", "value": "C11720"},
                        {"label": "Corguinho", "value": "C11721"},
                        {"label": "Coronel Sapucaia", "value": "C11722"},
                        {"label": "Corumba", "value": "C11723"},
                        {"label": "Costa Rica", "value": "C11724"},
                        {"label": "Coxim", "value": "C11725"},
                        {"label": "Deodapolis", "value": "C11726"},
                        {"label": "Dois Irmaos do Buriti", "value": "C11727"},
                        {"label": "Douradina", "value": "C11728"},
                        {"label": "Dourados", "value": "C11729"},
                        {"label": "Eldorado", "value": "C11730"},
                        {"label": "Fatima do Sul", "value": "C11731"},
                        {"label": "Figueirao", "value": "C11732"},
                        {"label": "Gloria de Dourados", "value": "C11733"},
                        {"label": "Guia Lopes da Laguna", "value": "C11734"},
                        {"label": "Iguatemi", "value": "C11735"},
                        {"label": "Inocencia", "value": "C11736"},
                        {"label": "Itapora", "value": "C11737"},
                        {"label": "Itaquirai", "value": "C11738"},
                        {"label": "Ivinhema", "value": "C11739"},
                        {"label": "Japora", "value": "C11740"},
                        {"label": "Jaraguari", "value": "C11741"},
                        {"label": "Jardim", "value": "C11742"},
                        {"label": "Jatei", "value": "C11743"},
                        {"label": "Juti", "value": "C11744"},
                        {"label": "Ladario", "value": "C11745"},
                        {"label": "Laguna Carapa", "value": "C11746"},
                        {"label": "Maracaju", "value": "C11747"},
                        {"label": "Miranda", "value": "C11748"},
                        {"label": "Mundo Novo", "value": "C11749"},
                        {"label": "Navirai", "value": "C11750"},
                        {"label": "Nioaque", "value": "C11751"},
                        {"label": "Nova Alvorada do Sul", "value": "C11752"},
                        {"label": "Nova Andradina", "value": "C11753"},
                        {"label": "Novo Horizonte do Sul", "value": "C11754"},
                        {"label": "Paraiso das aguas", "value": "C11755"},
                        {"label": "Paranaiba", "value": "C11756"},
                        {"label": "Paranhos", "value": "C11757"},
                        {"label": "Pedro Gomes", "value": "C11758"},
                        {"label": "Ponta Pora", "value": "C11759"},
                        {"label": "Porto Barra do Ivinheima",
                         "value": "C11760"},
                        {"label": "Porto Murtinho", "value": "C11761"},
                        {"label": "Ribas do Rio Pardo", "value": "C11762"},
                        {"label": "Rio Brilhante", "value": "C11763"},
                        {"label": "Rio Negro", "value": "C11764"},
                        {"label": "Rio Verde de Mato Grosso",
                         "value": "C11765"},
                        {"label": "Rochedo", "value": "C11766"},
                        {"label": "Santa Rita do Pardo", "value": "C11767"},
                        {"label": "Sao Gabriel do Oeste", "value": "C11768"},
                        {"label": "Selviria", "value": "C11769"},
                        {"label": "Sete Quedas", "value": "C11770"},
                        {"label": "Sidrolandia", "value": "C11771"},
                        {"label": "Sonora", "value": "C11772"},
                        {"label": "Tacuru", "value": "C11773"},
                        {"label": "Taquarussu", "value": "C11774"},
                        {"label": "Terenos", "value": "C11775"},
                        {"label": "Tres Lagoas", "value": "C11776"},
                        {"label": "Vicentina", "value": "C11777"}],
 "Matrouh": [{"label": "Al Alamayn", "value": "C24294"},
             {"label": "Mersa Matruh", "value": "C24295"},
             {"label": "Siwa Oasis", "value": "C24296"}],
 "Maule": [{"label": "Cauquenes", "value": "C18090"},
           {"label": "Chanco", "value": "C18091"},
           {"label": "Colbun", "value": "C18092"},
           {"label": "Constitucion", "value": "C18093"},
           {"label": "Curepto", "value": "C18094"},
           {"label": "Curico", "value": "C18095"},
           {"label": "Empedrado", "value": "C18096"},
           {"label": "Hualane", "value": "C18097"},
           {"label": "Licanten", "value": "C18098"},
           {"label": "Linares", "value": "C18099"},
           {"label": "Longavi", "value": "C18100"},
           {"label": "Maule", "value": "C18101"},
           {"label": "Molina", "value": "C18102"},
           {"label": "Parral", "value": "C18103"},
           {"label": "Pelarco", "value": "C18104"},
           {"label": "Pelluhue", "value": "C18105"},
           {"label": "Pencahue", "value": "C18106"},
           {"label": "Rauco", "value": "C18107"},
           {"label": "Retiro", "value": "C18108"},
           {"label": "Rio Claro", "value": "C18109"},
           {"label": "Romeral", "value": "C18110"},
           {"label": "Sagrada Familia", "value": "C18111"},
           {"label": "San Clemente", "value": "C18112"},
           {"label": "San Javier", "value": "C18113"},
           {"label": "San Rafael", "value": "C18114"},
           {"label": "Talca", "value": "C18115"},
           {"label": "Teno", "value": "C18116"},
           {"label": "Vichuquen", "value": "C18117"},
           {"label": "Villa Alegre", "value": "C18118"},
           {"label": "Yerbas Buenas", "value": "C18119"}],
 "Mauren": [{"label": "Mauren", "value": "C65074"}],
 "Mavrovo and Rostusa Municipality": [{"label": "Rostusa", "value": "C65461"}],
 "Mayabeque Province": [{"label": "Batabano", "value": "C21791"},
                        {"label": "Bejucal", "value": "C21792"},
                        {"label": "Guines", "value": "C21793"},
                        {"label": "Jamaica", "value": "C21794"},
                        {"label": "Jaruco", "value": "C21795"},
                        {"label": "La Salud", "value": "C21796"},
                        {"label": "Madruga", "value": "C21797"},
                        {"label": "Municipio de Guines", "value": "C21798"},
                        {"label": "Municipio de Melena del Sur",
                         "value": "C21799"},
                        {"label": "Quivican", "value": "C21800"},
                        {"label": "San Jose de las Lajas", "value": "C21801"},
                        {"label": "San Nicolas de Bari", "value": "C21802"},
                        {"label": "Santa Cruz del Norte", "value": "C21803"}],
 "Mayaguana District": [{"label": "Abrahams Bay", "value": "C119615"}],
 "Mayo-Kebbi Est Region": [{"label": "Bongor", "value": "C17874"},
                           {"label": "Gounou Gaya", "value": "C17875"},
                           {"label": "Guelendeng", "value": "C17876"}],
 "Mayo-Kebbi Ouest Region": [{"label": "Mboursou Lere", "value": "C17877"},
                             {"label": "Pala", "value": "C17878"}],
 "Maysan Governorate": [{"label": "Ali al Gharbi", "value": "C51186"},
                        {"label": "Al Amarah", "value": "C51187"},
                        {"label": "Al-Mejar Al-Kabi District",
                         "value": "C51188"}],
 "Mazandaran": [{"label": "amol", "value": "C50912"},
                {"label": "Babol", "value": "C50913"},
                {"label": "Babolsar", "value": "C50914"},
                {"label": "Behshahr", "value": "C50915"},
                {"label": "Chalus", "value": "C50916"},
                {"label": "Fereydunkenar", "value": "C50917"},
                {"label": "Juybar", "value": "C50918"},
                {"label": "Nashtarud", "value": "C50919"},
                {"label": "Neka", "value": "C50920"},
                {"label": "Nowshahr", "value": "C50921"},
                {"label": "Sari", "value": "C50922"},
                {"label": "Savadkuh-e Shomali", "value": "C50923"},
                {"label": "Shahrestan-e Abbasabad", "value": "C50924"},
                {"label": "Shahrestan-e amol", "value": "C50925"},
                {"label": "Shahrestan-e Babol", "value": "C50926"},
                {"label": "Shahrestan-e Babolsar", "value": "C50927"},
                {"label": "Shahrestan-e Chalus", "value": "C50928"},
                {"label": "Shahrestan-e Fareydunkenar", "value": "C50929"},
                {"label": "Shahrestan-e Galugah", "value": "C50930"},
                {"label": "Shahrestan-e Juybar", "value": "C50931"},
                {"label": "Shahrestan-e Kalar Dasht", "value": "C50932"},
                {"label": "Shahrestan-e Mahmudabad", "value": "C50933"},
                {"label": "Shahrestan-e Miandorud", "value": "C50934"},
                {"label": "Shahrestan-e Neka", "value": "C50935"},
                {"label": "Shahrestan-e Nur", "value": "C50936"},
                {"label": "Shahrestan-e Qaem Shahr", "value": "C50937"},
                {"label": "Shahrestan-e Ramsar", "value": "C50938"},
                {"label": "Shahrestan-e Sari", "value": "C50939"},
                {"label": "Shahrestan-e Savadkuh", "value": "C50940"},
                {"label": "Shahrestan-e Tonekabon", "value": "C50941"},
                {"label": "Simorgh County", "value": "C50942"},
                {"label": "Tonekabon", "value": "C50943"}],
 "Mazsalaca Municipality": [{"label": "Mazsalaca", "value": "C64902"}],
 "Mbeya": [{"label": "Chimala", "value": "C118171"},
           {"label": "Hedaru", "value": "C118172"},
           {"label": "Ibaba", "value": "C118173"},
           {"label": "Ikama", "value": "C118174"},
           {"label": "Ikinga", "value": "C118175"},
           {"label": "Ikolo", "value": "C118176"},
           {"label": "Ikuti", "value": "C118177"},
           {"label": "Ilembo", "value": "C118178"},
           {"label": "Ipinda", "value": "C118179"},
           {"label": "Kabula", "value": "C118180"},
           {"label": "Kahe, Tanzania", "value": "C118181"},
           {"label": "Kandete", "value": "C118182"},
           {"label": "Katumba", "value": "C118183"},
           {"label": "Kihurio", "value": "C118184"},
           {"label": "Kisiwani", "value": "C118185"},
           {"label": "Kiwira", "value": "C118186"},
           {"label": "Kwakoa", "value": "C118187"},
           {"label": "Lembeni", "value": "C118188"},
           {"label": "Lupata", "value": "C118189"},
           {"label": "Lusungo", "value": "C118190"},
           {"label": "Machame", "value": "C118191"},
           {"label": "Makanya", "value": "C118192"},
           {"label": "Marangu", "value": "C118193"},
           {"label": "Masukulu", "value": "C118194"},
           {"label": "Mbeya", "value": "C118195"},
           {"label": "Mlowo", "value": "C118196"},
           {"label": "Moshi", "value": "C118197"},
           {"label": "Mpuguso", "value": "C118198"},
           {"label": "Mwansanga", "value": "C118199"},
           {"label": "Mwaya", "value": "C118200"},
           {"label": "Mwembe", "value": "C118201"},
           {"label": "Same", "value": "C118202"},
           {"label": "Tukuyu", "value": "C118203"},
           {"label": "Tunduma", "value": "C118204"},
           {"label": "Ugweno", "value": "C118205"},
           {"label": "Ulanga", "value": "C118206"}],
 "Mbomou Prefecture": [{"label": "Bangassou", "value": "C17826"},
                       {"label": "Gambo", "value": "C17827"},
                       {"label": "Ouango", "value": "C17828"},
                       {"label": "Rafai", "value": "C17829"}],
 "Mecklenburg-Vorpommern": [{"label": "Admannshagen-Bargeshagen",
                             "value": "C38518"},
                            {"label": "Ahlbeck", "value": "C38519"},
                            {"label": "Alt Meteln", "value": "C38520"},
                            {"label": "Alt-Sanitz", "value": "C38521"},
                            {"label": "Altefahr", "value": "C38522"},
                            {"label": "Altenkirchen", "value": "C38523"},
                            {"label": "Altenpleen", "value": "C38524"},
                            {"label": "Altentreptow", "value": "C38525"},
                            {"label": "Altstadt", "value": "C38526"},
                            {"label": "Anklam", "value": "C38527"},
                            {"label": "Bad Doberan", "value": "C38528"},
                            {"label": "Bad Kleinen", "value": "C38529"},
                            {"label": "Bad Sulze", "value": "C38530"},
                            {"label": "Banzkow", "value": "C38531"},
                            {"label": "Bartenshagen-Parkentin",
                             "value": "C38532"},
                            {"label": "Barth", "value": "C38533"},
                            {"label": "Bastorf", "value": "C38534"},
                            {"label": "Bentwisch", "value": "C38535"},
                            {"label": "Bentzin", "value": "C38536"},
                            {"label": "Bergen auf Rugen", "value": "C38537"},
                            {"label": "Bernitt", "value": "C38538"},
                            {"label": "Biendorf", "value": "C38539"},
                            {"label": "Blankensee", "value": "C38540"},
                            {"label": "Blowatz", "value": "C38541"},
                            {"label": "Bobitz", "value": "C38542"},
                            {"label": "Boizenburg", "value": "C38543"},
                            {"label": "Borgerende-Rethwisch",
                             "value": "C38544"},
                            {"label": "Born", "value": "C38545"},
                            {"label": "Borrentin", "value": "C38546"},
                            {"label": "Brandshagen", "value": "C38547"},
                            {"label": "Broderstorf", "value": "C38548"},
                            {"label": "Bruel", "value": "C38549"},
                            {"label": "Brunn", "value": "C38550"},
                            {"label": "Brusewitz", "value": "C38551"},
                            {"label": "Burg Stargard", "value": "C38552"},
                            {"label": "Burow", "value": "C38553"},
                            {"label": "Butzow", "value": "C38554"},
                            {"label": "Carlow", "value": "C38555"},
                            {"label": "Carpin", "value": "C38556"},
                            {"label": "Crivitz", "value": "C38557"},
                            {"label": "Dabel", "value": "C38558"},
                            {"label": "Dargun", "value": "C38559"},
                            {"label": "Dassow", "value": "C38560"},
                            {"label": "Demen", "value": "C38561"},
                            {"label": "Demmin", "value": "C38562"},
                            {"label": "Dersekow", "value": "C38563"},
                            {"label": "Dierkow-Neu", "value": "C38564"},
                            {"label": "Dierkow-West", "value": "C38565"},
                            {"label": "Dobbertin", "value": "C38566"},
                            {"label": "Domitz", "value": "C38567"},
                            {"label": "Domsuhl", "value": "C38568"},
                            {"label": "Dranske", "value": "C38569"},
                            {"label": "Ducherow", "value": "C38570"},
                            {"label": "Dummer", "value": "C38571"},
                            {"label": "Dummerstorf", "value": "C38572"},
                            {"label": "Eggesin", "value": "C38573"},
                            {"label": "Eldena", "value": "C38574"},
                            {"label": "Elmenhorst", "value": "C38575"},
                            {"label": "Feldstadt", "value": "C38576"},
                            {"label": "Ferdinandshof", "value": "C38577"},
                            {"label": "Franzburg", "value": "C38578"},
                            {"label": "Friedland", "value": "C38579"},
                            {"label": "Gadebusch", "value": "C38580"},
                            {"label": "Gagelow", "value": "C38581"},
                            {"label": "Garz", "value": "C38582"},
                            {"label": "Gelbensande", "value": "C38583"},
                            {"label": "Gielow", "value": "C38584"},
                            {"label": "Gingst", "value": "C38585"},
                            {"label": "Glowe", "value": "C38586"},
                            {"label": "Gnoien", "value": "C38587"},
                            {"label": "Goldberg", "value": "C38588"},
                            {"label": "Gormin", "value": "C38589"},
                            {"label": "Grabow", "value": "C38590"},
                            {"label": "Grabowhofe", "value": "C38591"},
                            {"label": "Gramkow", "value": "C38592"},
                            {"label": "Greifswald", "value": "C38593"},
                            {"label": "Grevesmuhlen", "value": "C38594"},
                            {"label": "Grimmen", "value": "C38595"},
                            {"label": "Gross Kiesow", "value": "C38596"},
                            {"label": "Gross Laasch", "value": "C38597"},
                            {"label": "Gross Miltzow", "value": "C38598"},
                            {"label": "Gross Nemerow", "value": "C38599"},
                            {"label": "Gross Wokern", "value": "C38600"},
                            {"label": "Gustrow", "value": "C38601"},
                            {"label": "Gutzkow", "value": "C38602"},
                            {"label": "Hagenow", "value": "C38603"},
                            {"label": "Hiddensee", "value": "C38604"},
                            {"label": "Hornstorf", "value": "C38605"},
                            {"label": "Jarmen", "value": "C38606"},
                            {"label": "Jatznick", "value": "C38607"},
                            {"label": "Jordenstorf", "value": "C38608"},
                            {"label": "Jurgenshagen", "value": "C38609"},
                            {"label": "Kalkhorst", "value": "C38610"},
                            {"label": "Karlshagen", "value": "C38611"},
                            {"label": "Kavelstorf", "value": "C38612"},
                            {"label": "Kemnitz", "value": "C38613"},
                            {"label": "Kessin", "value": "C38614"},
                            {"label": "Klein Rogahn", "value": "C38615"},
                            {"label": "Klink", "value": "C38616"},
                            {"label": "Klutz", "value": "C38617"},
                            {"label": "Koserow", "value": "C38618"},
                            {"label": "Krakow am See", "value": "C38619"},
                            {"label": "Kramerhof", "value": "C38620"},
                            {"label": "Kritzmow", "value": "C38621"},
                            {"label": "Kropelin", "value": "C38622"},
                            {"label": "Kroslin", "value": "C38623"},
                            {"label": "Laage", "value": "C38624"},
                            {"label": "Lalendorf", "value": "C38625"},
                            {"label": "Lambrechtshagen", "value": "C38626"},
                            {"label": "Lankow", "value": "C38627"},
                            {"label": "Lassan", "value": "C38628"},
                            {"label": "Leezen", "value": "C38629"},
                            {"label": "Lewenberg", "value": "C38630"},
                            {"label": "Locknitz", "value": "C38631"},
                            {"label": "Loddin", "value": "C38632"},
                            {"label": "Loitz", "value": "C38633"},
                            {"label": "Lubmin", "value": "C38634"},
                            {"label": "Lubow", "value": "C38635"},
                            {"label": "Lubstorf", "value": "C38636"},
                            {"label": "Lubtheen", "value": "C38637"},
                            {"label": "Lubz", "value": "C38638"},
                            {"label": "Ludersdorf", "value": "C38639"},
                            {"label": "Ludwigslust", "value": "C38640"},
                            {"label": "Lutzow", "value": "C38641"},
                            {"label": "Malchin", "value": "C38642"},
                            {"label": "Malchow", "value": "C38643"},
                            {"label": "Malliss", "value": "C38644"},
                            {"label": "Marlow", "value": "C38645"},
                            {"label": "Mecklenburg", "value": "C38646"},
                            {"label": "Mesekenhagen", "value": "C38647"},
                            {"label": "Mirow", "value": "C38648"},
                            {"label": "Mollenhagen", "value": "C38649"},
                            {"label": "Monchhagen", "value": "C38650"},
                            {"label": "Muhl Rosin", "value": "C38651"},
                            {"label": "Muhlen Eichsen", "value": "C38652"},
                            {"label": "Neu Kaliss", "value": "C38653"},
                            {"label": "Neubrandenburg", "value": "C38654"},
                            {"label": "Neubukow", "value": "C38655"},
                            {"label": "Neuburg", "value": "C38656"},
                            {"label": "Neuenkirchen", "value": "C38657"},
                            {"label": "Neukalen", "value": "C38658"},
                            {"label": "Neukloster", "value": "C38659"},
                            {"label": "Neumuhle", "value": "C38660"},
                            {"label": "Neustadt-Glewe", "value": "C38661"},
                            {"label": "Neustrelitz", "value": "C38662"},
                            {"label": "Neverin", "value": "C38663"},
                            {"label": "Nienhagen", "value": "C38664"},
                            {"label": "Niepars", "value": "C38665"},
                            {"label": "Nostorf", "value": "C38666"},
                            {"label": "Ostseebad Binz", "value": "C38667"},
                            {"label": "Ostseebad Boltenhagen",
                             "value": "C38668"},
                            {"label": "Ostseebad Dierhagen", "value": "C38669"},
                            {"label": "Ostseebad Gohren", "value": "C38670"},
                            {"label": "Ostseebad Kuhlungsborn",
                             "value": "C38671"},
                            {"label": "Ostseebad Prerow", "value": "C38672"},
                            {"label": "Ostseebad Sellin", "value": "C38673"},
                            {"label": "Ostseebad Zinnowitz", "value": "C38674"},
                            {"label": "Pampow", "value": "C38675"},
                            {"label": "Papendorf", "value": "C38676"},
                            {"label": "Parchim", "value": "C38677"},
                            {"label": "Pasewalk", "value": "C38678"},
                            {"label": "Paulsstadt", "value": "C38679"},
                            {"label": "Penkun", "value": "C38680"},
                            {"label": "Penzlin", "value": "C38681"},
                            {"label": "Pinnow", "value": "C38682"},
                            {"label": "Plate", "value": "C38683"},
                            {"label": "Plau am See", "value": "C38684"},
                            {"label": "Poseritz", "value": "C38685"},
                            {"label": "Preetz", "value": "C38686"},
                            {"label": "Prohn", "value": "C38687"},
                            {"label": "Putbus", "value": "C38688"},
                            {"label": "Raben Steinfeld", "value": "C38689"},
                            {"label": "Rambin", "value": "C38690"},
                            {"label": "Rastow", "value": "C38691"},
                            {"label": "Rechlin", "value": "C38692"},
                            {"label": "Rehna", "value": "C38693"},
                            {"label": "Reinberg", "value": "C38694"},
                            {"label": "Retgendorf", "value": "C38695"},
                            {"label": "Retschow", "value": "C38696"},
                            {"label": "Ribnitz-Damgarten", "value": "C38697"},
                            {"label": "Richtenberg", "value": "C38698"},
                            {"label": "Robel", "value": "C38699"},
                            {"label": "Roggendorf", "value": "C38700"},
                            {"label": "Roggentin", "value": "C38701"},
                            {"label": "Rosenow", "value": "C38702"},
                            {"label": "Rostock", "value": "C38703"},
                            {"label": "Rovershagen", "value": "C38704"},
                            {"label": "Saal", "value": "C38705"},
                            {"label": "Sagard", "value": "C38706"},
                            {"label": "Samtens", "value": "C38707"},
                            {"label": "Sassnitz", "value": "C38708"},
                            {"label": "Satow-Oberhagen", "value": "C38709"},
                            {"label": "Schelfstadt", "value": "C38710"},
                            {"label": "Schlagsdorf", "value": "C38711"},
                            {"label": "Schwaan", "value": "C38712"},
                            {"label": "Schwerin", "value": "C38713"},
                            {"label": "Seebad Bansin", "value": "C38714"},
                            {"label": "Seebad Heringsdorf", "value": "C38715"},
                            {"label": "Seeheilbad Graal-Muritz",
                             "value": "C38716"},
                            {"label": "Seehof", "value": "C38717"},
                            {"label": "Sehlen", "value": "C38718"},
                            {"label": "Sellin", "value": "C38719"},
                            {"label": "Selmsdorf", "value": "C38720"},
                            {"label": "Siggelkow", "value": "C38721"},
                            {"label": "Spornitz", "value": "C38722"},
                            {"label": "Stabelow", "value": "C38723"},
                            {"label": "Steinhagen", "value": "C38724"},
                            {"label": "Sternberg", "value": "C38725"},
                            {"label": "Stralendorf", "value": "C38726"},
                            {"label": "Stralsund", "value": "C38727"},
                            {"label": "Strasburg", "value": "C38728"},
                            {"label": "Sukow", "value": "C38729"},
                            {"label": "Sulstorf", "value": "C38730"},
                            {"label": "Tarnow", "value": "C38731"},
                            {"label": "Tessin", "value": "C38732"},
                            {"label": "Teterow", "value": "C38733"},
                            {"label": "Torgelow", "value": "C38734"},
                            {"label": "Tribsees", "value": "C38735"},
                            {"label": "Trinwillershagen", "value": "C38736"},
                            {"label": "Trollenhagen", "value": "C38737"},
                            {"label": "Tutow", "value": "C38738"},
                            {"label": "Ueckermunde", "value": "C38739"},
                            {"label": "Usedom", "value": "C38740"},
                            {"label": "Velgast", "value": "C38741"},
                            {"label": "Viereck", "value": "C38742"},
                            {"label": "Wackerow", "value": "C38743"},
                            {"label": "Wardow", "value": "C38744"},
                            {"label": "Waren", "value": "C38745"},
                            {"label": "Warin", "value": "C38746"},
                            {"label": "Warnemunde", "value": "C38747"},
                            {"label": "Warnow", "value": "C38748"},
                            {"label": "Wattmannshagen", "value": "C38749"},
                            {"label": "Weitenhagen", "value": "C38750"},
                            {"label": "Wendorf", "value": "C38751"},
                            {"label": "Werdervorstadt", "value": "C38752"},
                            {"label": "Wesenberg", "value": "C38753"},
                            {"label": "Weststadt", "value": "C38754"},
                            {"label": "Wiek", "value": "C38755"},
                            {"label": "Wismar", "value": "C38756"},
                            {"label": "Wittenburg", "value": "C38757"},
                            {"label": "Wittenforden", "value": "C38758"},
                            {"label": "Wittenhagen", "value": "C38759"},
                            {"label": "Woldegk", "value": "C38760"},
                            {"label": "Wolgast", "value": "C38761"},
                            {"label": "Wulkenzin", "value": "C38762"},
                            {"label": "Wusterhusen", "value": "C38763"},
                            {"label": "Wustrow", "value": "C38764"},
                            {"label": "Zarrendorf", "value": "C38765"},
                            {"label": "Zarrentin", "value": "C38766"},
                            {"label": "Ziesendorf", "value": "C38767"},
                            {"label": "Zingst", "value": "C38768"},
                            {"label": "Zurow", "value": "C38769"},
                            {"label": "Zussow", "value": "C38770"}],
 "Medea": [{"label": "Ain Boucif", "value": "C361"},
           {"label": "Berrouaghia", "value": "C362"},
           {"label": "Ksar el Boukhari", "value": "C363"},
           {"label": "Medea", "value": "C364"}],
 "Medenine Governorate": [{"label": "Ben Gardane", "value": "C119750"},
                          {"label": "Beni Kheddache", "value": "C119751"},
                          {"label": "Erriadh", "value": "C119752"},
                          {"label": "Houmt El Souk", "value": "C119753"},
                          {"label": "Jerba Midoun", "value": "C119754"},
                          {"label": "Medenine", "value": "C119755"},
                          {"label": "Midoun", "value": "C119756"},
                          {"label": "Zarzis", "value": "C119757"}],
 "Medimurje": [{"label": "Belica", "value": "C21199"},
               {"label": "Cakovec", "value": "C21200"},
               {"label": "Dekanovec", "value": "C21201"},
               {"label": "Domasinec", "value": "C21202"},
               {"label": "Gorican", "value": "C21203"},
               {"label": "Grad Cakovec", "value": "C21204"},
               {"label": "Hodosan", "value": "C21205"},
               {"label": "Ivanovec", "value": "C21206"},
               {"label": "Kotoriba", "value": "C21207"},
               {"label": "Kursanec", "value": "C21208"},
               {"label": "Lopatinec", "value": "C21209"},
               {"label": "Mackovec", "value": "C21210"},
               {"label": "Mala Subotica", "value": "C21211"},
               {"label": "Mihovljan", "value": "C21212"},
               {"label": "Mursko Sredisce", "value": "C21213"},
               {"label": "Nedelisce", "value": "C21214"},
               {"label": "Novo Selo Rok", "value": "C21215"},
               {"label": "Orehovica", "value": "C21216"},
               {"label": "Peklenica", "value": "C21217"},
               {"label": "Podturen", "value": "C21218"},
               {"label": "Prelog", "value": "C21219"},
               {"label": "Pribislavec", "value": "C21220"},
               {"label": "Senkovec", "value": "C21221"},
               {"label": "Strahoninec", "value": "C21222"},
               {"label": "Sveti Juraj na Bregu", "value": "C21223"},
               {"label": "Vratisinec", "value": "C21224"}],
 "Medvode Municipality": [{"label": "Medvode", "value": "C107717"},
                          {"label": "Opstina [historical] Ljubljana-Siska",
                           "value": "C107718"},
                          {"label": "Zgornje Pirnice", "value": "C107719"}],
 "Meemu Atoll": [{"label": "Muli", "value": "C65815"}],
 "Meghalaya": [{"label": "Cherrapunji", "value": "C47267"},
               {"label": "East Garo Hills", "value": "C47268"},
               {"label": "East Jaintia Hills", "value": "C47269"},
               {"label": "East Khasi Hills", "value": "C47270"},
               {"label": "Mairang", "value": "C47271"},
               {"label": "Mankachar", "value": "C47272"},
               {"label": "Nongpoh", "value": "C47273"},
               {"label": "Nongstoin", "value": "C47274"},
               {"label": "North Garo Hills", "value": "C47275"},
               {"label": "Ri-Bhoi", "value": "C47276"},
               {"label": "Shillong", "value": "C47277"},
               {"label": "South Garo Hills", "value": "C47278"},
               {"label": "South West Garo Hills", "value": "C47279"},
               {"label": "South West Khasi Hills", "value": "C47280"},
               {"label": "Tura", "value": "C47281"},
               {"label": "West Garo Hills", "value": "C47282"},
               {"label": "West Jaintia Hills", "value": "C47283"},
               {"label": "West Khasi Hills", "value": "C47284"}],
 "Mehedinti County": [{"label": "Bacles", "value": "C96866"},
                      {"label": "Baia de Arama", "value": "C96867"},
                      {"label": "Bala", "value": "C96868"},
                      {"label": "Balacita", "value": "C96869"},
                      {"label": "Balta", "value": "C96870"},
                      {"label": "Balta Verde", "value": "C96871"},
                      {"label": "Balvanesti", "value": "C96872"},
                      {"label": "Bistrita", "value": "C96873"},
                      {"label": "Branistea", "value": "C96874"},
                      {"label": "Breznita Ocol", "value": "C96875"},
                      {"label": "Breznita-Motru", "value": "C96876"},
                      {"label": "Brosteni", "value": "C96877"},
                      {"label": "Burila Mare", "value": "C96878"},
                      {"label": "Butoiesti", "value": "C96879"},
                      {"label": "Cazanesti", "value": "C96880"},
                      {"label": "Cerneti", "value": "C96881"},
                      {"label": "Ciochiuta", "value": "C96882"},
                      {"label": "Cioroboreni", "value": "C96883"},
                      {"label": "Ciresu", "value": "C96884"},
                      {"label": "Comanda", "value": "C96885"},
                      {"label": "Comuna Bacles", "value": "C96886"},
                      {"label": "Comuna Bala", "value": "C96887"},
                      {"label": "Comuna Balacita", "value": "C96888"},
                      {"label": "Comuna Balta", "value": "C96889"},
                      {"label": "Comuna Balvanesti", "value": "C96890"},
                      {"label": "Comuna Branistea", "value": "C96891"},
                      {"label": "Comuna Breznita Ocol", "value": "C96892"},
                      {"label": "Comuna Breznita-Motru", "value": "C96893"},
                      {"label": "Comuna Brosteni", "value": "C96894"},
                      {"label": "Comuna Burila Mare", "value": "C96895"},
                      {"label": "Comuna Butoiesti", "value": "C96896"},
                      {"label": "Comuna Cazanesti", "value": "C96897"},
                      {"label": "Comuna Ciresu", "value": "C96898"},
                      {"label": "Comuna Corcova", "value": "C96899"},
                      {"label": "Comuna Corlatel", "value": "C96900"},
                      {"label": "Comuna Cujmir", "value": "C96901"},
                      {"label": "Comuna Darvari", "value": "C96902"},
                      {"label": "Comuna Devesel", "value": "C96903"},
                      {"label": "Comuna Dubova", "value": "C96904"},
                      {"label": "Comuna Dumbrava", "value": "C96905"},
                      {"label": "Comuna Eselnita", "value": "C96906"},
                      {"label": "Comuna Floresti", "value": "C96907"},
                      {"label": "Comuna Garla Mare", "value": "C96908"},
                      {"label": "Comuna Godeanu", "value": "C96909"},
                      {"label": "Comuna Gogosu", "value": "C96910"},
                      {"label": "Comuna Greci", "value": "C96911"},
                      {"label": "Comuna Grozesti", "value": "C96912"},
                      {"label": "Comuna Gruia", "value": "C96913"},
                      {"label": "Comuna Hinova", "value": "C96914"},
                      {"label": "Comuna Husnicioara", "value": "C96915"},
                      {"label": "Comuna Ilovat", "value": "C96916"},
                      {"label": "Comuna Ilovita", "value": "C96917"},
                      {"label": "Comuna Isverna", "value": "C96918"},
                      {"label": "Comuna Izvoru Barzii", "value": "C96919"},
                      {"label": "Comuna Jiana", "value": "C96920"},
                      {"label": "Comuna Livezile", "value": "C96921"},
                      {"label": "Comuna Malovat", "value": "C96922"},
                      {"label": "Comuna Obarsia De Camp", "value": "C96923"},
                      {"label": "Comuna Obarsia-Closani", "value": "C96924"},
                      {"label": "Comuna Oprisor", "value": "C96925"},
                      {"label": "Comuna Padina", "value": "C96926"},
                      {"label": "Comuna Patulele", "value": "C96927"},
                      {"label": "Comuna Podeni", "value": "C96928"},
                      {"label": "Comuna Ponoarele", "value": "C96929"},
                      {"label": "Comuna Poroina Mare", "value": "C96930"},
                      {"label": "Comuna Pristol", "value": "C96931"},
                      {"label": "Comuna Prunisor", "value": "C96932"},
                      {"label": "Comuna Punghina", "value": "C96933"},
                      {"label": "Comuna Rogova", "value": "C96934"},
                      {"label": "Comuna Salcia", "value": "C96935"},
                      {"label": "Comuna Simian", "value": "C96936"},
                      {"label": "Comuna Sisesti", "value": "C96937"},
                      {"label": "Comuna Sovarna", "value": "C96938"},
                      {"label": "Comuna Stangaceaua", "value": "C96939"},
                      {"label": "Comuna Svinita", "value": "C96940"},
                      {"label": "Comuna Tamna", "value": "C96941"},
                      {"label": "Comuna Vanatori", "value": "C96942"},
                      {"label": "Comuna Vanjulet", "value": "C96943"},
                      {"label": "Comuna Vladaia", "value": "C96944"},
                      {"label": "Comuna Voloiac", "value": "C96945"},
                      {"label": "Comuna Vrata", "value": "C96946"},
                      {"label": "Corcova", "value": "C96947"},
                      {"label": "Corlatel", "value": "C96948"},
                      {"label": "Cujmir", "value": "C96949"},
                      {"label": "Danceu", "value": "C96950"},
                      {"label": "Darvari", "value": "C96951"},
                      {"label": "Devesel", "value": "C96952"},
                      {"label": "Drobeta-Turnu Severin", "value": "C96953"},
                      {"label": "Dubova", "value": "C96954"},
                      {"label": "Dudasu", "value": "C96955"},
                      {"label": "Dumbrava de Sus", "value": "C96956"},
                      {"label": "Eselnita", "value": "C96957"},
                      {"label": "Floresti", "value": "C96958"},
                      {"label": "Garla-Mare", "value": "C96959"},
                      {"label": "Gemeni", "value": "C96960"},
                      {"label": "Godeanu", "value": "C96961"},
                      {"label": "Gogosu", "value": "C96962"},
                      {"label": "Greci", "value": "C96963"},
                      {"label": "Grozesti", "value": "C96964"},
                      {"label": "Gruia", "value": "C96965"},
                      {"label": "Gura Vaii", "value": "C96966"},
                      {"label": "Gvardinita", "value": "C96967"},
                      {"label": "Halanga", "value": "C96968"},
                      {"label": "Hinova", "value": "C96969"},
                      {"label": "Husnicioara", "value": "C96970"},
                      {"label": "Ilovat", "value": "C96971"},
                      {"label": "Ilovita", "value": "C96972"},
                      {"label": "Isverna", "value": "C96973"},
                      {"label": "Izimsa", "value": "C96974"},
                      {"label": "Izvoarele", "value": "C96975"},
                      {"label": "Izvoru Barzii", "value": "C96976"},
                      {"label": "Jiana", "value": "C96977"},
                      {"label": "Jiana Veche", "value": "C96978"},
                      {"label": "Jidostita", "value": "C96979"},
                      {"label": "Jirov", "value": "C96980"},
                      {"label": "Livezile", "value": "C96981"},
                      {"label": "Malovat", "value": "C96982"},
                      {"label": "Municipiul Drobeta-Turnu Severin",
                       "value": "C96983"},
                      {"label": "Municipiul Orsova", "value": "C96984"},
                      {"label": "Nicolae Balcescu", "value": "C96985"},
                      {"label": "Obarsia de Camp", "value": "C96986"},
                      {"label": "Obarsia-Closani", "value": "C96987"},
                      {"label": "Oprisor", "value": "C96988"},
                      {"label": "Oras Baia De Arama", "value": "C96989"},
                      {"label": "Oras Strehaia", "value": "C96990"},
                      {"label": "Oras Vanju Mare", "value": "C96991"},
                      {"label": "Orevita Mare", "value": "C96992"},
                      {"label": "Orsova", "value": "C96993"},
                      {"label": "Ostrovu Mare", "value": "C96994"},
                      {"label": "Padina Mica", "value": "C96995"},
                      {"label": "Patulele", "value": "C96996"},
                      {"label": "Podeni", "value": "C96997"},
                      {"label": "Ponoarele", "value": "C96998"},
                      {"label": "Poroina Mare", "value": "C96999"},
                      {"label": "Pristol", "value": "C97000"},
                      {"label": "Prunisor", "value": "C97001"},
                      {"label": "Punghina", "value": "C97002"},
                      {"label": "Recea", "value": "C97003"},
                      {"label": "Rogova", "value": "C97004"},
                      {"label": "Rosiori", "value": "C97005"},
                      {"label": "Salcia", "value": "C97006"},
                      {"label": "Simian", "value": "C97007"},
                      {"label": "Sisesti", "value": "C97008"},
                      {"label": "Sovarna", "value": "C97009"},
                      {"label": "Stangaceaua", "value": "C97010"},
                      {"label": "Strehaia", "value": "C97011"},
                      {"label": "Svinita", "value": "C97012"},
                      {"label": "Tamna", "value": "C97013"},
                      {"label": "Vanatori", "value": "C97014"},
                      {"label": "Vanju-Mare", "value": "C97015"},
                      {"label": "Vanjulet", "value": "C97016"},
                      {"label": "Vladaia", "value": "C97017"},
                      {"label": "Voloiac", "value": "C97018"},
                      {"label": "Vrata", "value": "C97019"}],
 "Melekeok": [{"label": "Melekeok Village", "value": "C79777"}],
 "Melilla": [{"label": "Melilla", "value": "C114531"}],
 "Mendoza": [{"label": "Departamento de Capital", "value": "C1155"},
             {"label": "Departamento de General Alvear", "value": "C1156"},
             {"label": "Departamento de Godoy Cruz", "value": "C1157"},
             {"label": "Departamento de Guaymallen", "value": "C1158"},
             {"label": "Departamento de La Paz", "value": "C1159"},
             {"label": "Departamento de Las Heras", "value": "C1160"},
             {"label": "Departamento de Lavalle", "value": "C1161"},
             {"label": "Departamento de Lujan", "value": "C1162"},
             {"label": "Departamento de Maipu", "value": "C1163"},
             {"label": "Departamento de Malargue", "value": "C1164"},
             {"label": "Departamento de Rivadavia", "value": "C1165"},
             {"label": "Departamento de San Carlos", "value": "C1166"},
             {"label": "Departamento de San Martin", "value": "C1167"},
             {"label": "Departamento de San Rafael", "value": "C1168"},
             {"label": "Departamento de Santa Rosa", "value": "C1169"},
             {"label": "Departamento de Tunuyan", "value": "C1170"},
             {"label": "Departamento de Tupungato", "value": "C1171"},
             {"label": "Godoy Cruz", "value": "C1172"},
             {"label": "Las Heras", "value": "C1173"},
             {"label": "Mendoza", "value": "C1174"},
             {"label": "San Martin", "value": "C1175"},
             {"label": "San Rafael", "value": "C1176"}],
 "Meneng District": [{"label": "Menen", "value": "C76016"}],
 "Menges Municipality": [{"label": "Menges", "value": "C107720"},
                         {"label": "Preserje pri Radomljah",
                          "value": "C107721"}],
 "Merida": [{"label": "Ejido", "value": "C147194"},
            {"label": "El Vigia", "value": "C147195"},
            {"label": "Merida", "value": "C147196"},
            {"label": "Mucumpiz", "value": "C147197"},
            {"label": "Municipio Libertador", "value": "C147198"}],
 "Mersin": [{"label": "Akdeniz", "value": "C120781"},
            {"label": "Akdere", "value": "C120782"},
            {"label": "Anamur", "value": "C120783"},
            {"label": "Aydincik", "value": "C120784"},
            {"label": "Bozyazi", "value": "C120785"},
            {"label": "Camliyayla Ilcesi", "value": "C120786"},
            {"label": "Elvanli", "value": "C120787"},
            {"label": "Erdemli", "value": "C120788"},
            {"label": "Gulnar", "value": "C120789"},
            {"label": "Kocahasanli", "value": "C120790"},
            {"label": "Mersin", "value": "C120791"},
            {"label": "Mezitli Ilcesi", "value": "C120792"},
            {"label": "Mut", "value": "C120793"},
            {"label": "Pinarbasi", "value": "C120794"},
            {"label": "Silifke", "value": "C120795"},
            {"label": "Tarsus", "value": "C120796"},
            {"label": "Toroslar", "value": "C120797"},
            {"label": "Yenisehir", "value": "C120798"}],
 "Meru": [{"label": "Maua", "value": "C64606"},
          {"label": "Meru", "value": "C64607"}],
 "Meta": [{"label": "Acacias", "value": "C20274"},
          {"label": "Barranca de Upia", "value": "C20275"},
          {"label": "Cabuyaro", "value": "C20276"},
          {"label": "Castilla la Nueva", "value": "C20277"},
          {"label": "Cubarral", "value": "C20278"},
          {"label": "Cumaral", "value": "C20279"},
          {"label": "El Calvario", "value": "C20280"},
          {"label": "El Castillo", "value": "C20281"},
          {"label": "El Dorado", "value": "C20282"},
          {"label": "Fuente de Oro", "value": "C20283"},
          {"label": "Granada", "value": "C20284"},
          {"label": "Guamal", "value": "C20285"},
          {"label": "La Macarena", "value": "C20286"},
          {"label": "Lejanias", "value": "C20287"},
          {"label": "Mapiripan", "value": "C20288"},
          {"label": "Mesetas", "value": "C20289"},
          {"label": "Puerto Concordia", "value": "C20290"},
          {"label": "Puerto Gaitan", "value": "C20291"},
          {"label": "Puerto Lleras", "value": "C20292"},
          {"label": "Puerto Lopez", "value": "C20293"},
          {"label": "Puerto Rico", "value": "C20294"},
          {"label": "Restrepo", "value": "C20295"},
          {"label": "San Carlos de Guaroa", "value": "C20296"},
          {"label": "San Juan de Arama", "value": "C20297"},
          {"label": "San Juanito", "value": "C20298"},
          {"label": "San Martin", "value": "C20299"},
          {"label": "Uribe", "value": "C20300"},
          {"label": "Villavicencio", "value": "C20301"},
          {"label": "Vistahermosa", "value": "C20302"}],
 "Metlika Municipality": [{"label": "Metlika", "value": "C107722"}],
 "Metro Manila": [{"label": "Caloocan", "value": "C86649"},
                  {"label": "Calumpang", "value": "C86650"},
                  {"label": "Dasmarinas Village", "value": "C86651"},
                  {"label": "Ermita", "value": "C86652"},
                  {"label": "Intramuros", "value": "C86653"},
                  {"label": "Las Pinas", "value": "C86654"},
                  {"label": "Makati", "value": "C86655"},
                  {"label": "Malabon", "value": "C86656"},
                  {"label": "Malate", "value": "C86657"},
                  {"label": "Mandaluyong", "value": "C86658"},
                  {"label": "Manila", "value": "C86659"},
                  {"label": "Marikina", "value": "C86660"},
                  {"label": "Muntinlupa", "value": "C86661"},
                  {"label": "National Capital Region", "value": "C86662"},
                  {"label": "Navotas", "value": "C86663"},
                  {"label": "Niugan", "value": "C86664"},
                  {"label": "Paco", "value": "C86665"},
                  {"label": "Pandacan", "value": "C86666"},
                  {"label": "Paranaque", "value": "C86667"},
                  {"label": "Pasay", "value": "C86668"},
                  {"label": "Pasig", "value": "C86669"},
                  {"label": "Pateros", "value": "C86670"},
                  {"label": "Port Area", "value": "C86671"},
                  {"label": "Quezon City", "value": "C86672"},
                  {"label": "Quiapo", "value": "C86673"},
                  {"label": "Sambayanihan Peoples Village", "value": "C86674"},
                  {"label": "San Juan", "value": "C86675"},
                  {"label": "San Miguel", "value": "C86676"},
                  {"label": "Santa Ana", "value": "C86677"},
                  {"label": "Santa Cruz", "value": "C86678"},
                  {"label": "Singkamas", "value": "C86679"},
                  {"label": "Taguig", "value": "C86680"},
                  {"label": "Tanza", "value": "C86681"},
                  {"label": "Tondo", "value": "C86682"},
                  {"label": "Valenzuela", "value": "C86683"}],
 "Mezica Municipality": [{"label": "Mezica", "value": "C107723"}],
 "Mgarr": [{"label": "Marsa", "value": "C65900"}],
 "Miaoli": [{"label": "Miaoli", "value": "C117969"}],
 "Michigan": [{"label": "Adrian", "value": "C135059"},
              {"label": "Albion", "value": "C135060"},
              {"label": "Alcona County", "value": "C135061"},
              {"label": "Alger County", "value": "C135062"},
              {"label": "Algonac", "value": "C135063"},
              {"label": "Allegan", "value": "C135064"},
              {"label": "Allegan County", "value": "C135065"},
              {"label": "Allen Park", "value": "C135066"},
              {"label": "Allendale", "value": "C135067"},
              {"label": "Alma", "value": "C135068"},
              {"label": "Almont", "value": "C135069"},
              {"label": "Alpena", "value": "C135070"},
              {"label": "Alpena County", "value": "C135071"},
              {"label": "Ann Arbor", "value": "C135072"},
              {"label": "Antrim County", "value": "C135073"},
              {"label": "Arenac County", "value": "C135074"},
              {"label": "Argentine", "value": "C135075"},
              {"label": "Armada", "value": "C135076"},
              {"label": "Athens", "value": "C135077"},
              {"label": "Atlanta", "value": "C135078"},
              {"label": "Au Sable", "value": "C135079"},
              {"label": "Auburn", "value": "C135080"},
              {"label": "Auburn Hills", "value": "C135081"},
              {"label": "Bad Axe", "value": "C135082"},
              {"label": "Baldwin", "value": "C135083"},
              {"label": "Bangor", "value": "C135084"},
              {"label": "Baraga", "value": "C135085"},
              {"label": "Baraga County", "value": "C135086"},
              {"label": "Barnes Lake-Millers Lake", "value": "C135087"},
              {"label": "Barry County", "value": "C135088"},
              {"label": "Bath", "value": "C135089"},
              {"label": "Battle Creek", "value": "C135090"},
              {"label": "Bay City", "value": "C135091"},
              {"label": "Bay County", "value": "C135092"},
              {"label": "Bay Harbor", "value": "C135093"},
              {"label": "Beaverton", "value": "C135094"},
              {"label": "Beecher", "value": "C135095"},
              {"label": "Beechwood", "value": "C135096"},
              {"label": "Belding", "value": "C135097"},
              {"label": "Bellaire", "value": "C135098"},
              {"label": "Belleville", "value": "C135099"},
              {"label": "Bellevue", "value": "C135100"},
              {"label": "Benton Harbor", "value": "C135101"},
              {"label": "Benton Heights", "value": "C135102"},
              {"label": "Benzie County", "value": "C135103"},
              {"label": "Berkley", "value": "C135104"},
              {"label": "Berrien County", "value": "C135105"},
              {"label": "Berrien Springs", "value": "C135106"},
              {"label": "Bessemer", "value": "C135107"},
              {"label": "Beulah", "value": "C135108"},
              {"label": "Beverly Hills", "value": "C135109"},
              {"label": "Big Rapids", "value": "C135110"},
              {"label": "Bingham Farms", "value": "C135111"},
              {"label": "Birch Run", "value": "C135112"},
              {"label": "Birmingham", "value": "C135113"},
              {"label": "Blissfield", "value": "C135114"},
              {"label": "Bloomfield Hills", "value": "C135115"},
              {"label": "Boyne City", "value": "C135116"},
              {"label": "Branch County", "value": "C135117"},
              {"label": "Breckenridge", "value": "C135118"},
              {"label": "Bridgeport", "value": "C135119"},
              {"label": "Bridgman", "value": "C135120"},
              {"label": "Brighton", "value": "C135121"},
              {"label": "Bronson", "value": "C135122"},
              {"label": "Brooklyn", "value": "C135123"},
              {"label": "Brown City", "value": "C135124"},
              {"label": "Brownlee Park", "value": "C135125"},
              {"label": "Buchanan", "value": "C135126"},
              {"label": "Buena Vista", "value": "C135127"},
              {"label": "Burt", "value": "C135128"},
              {"label": "Burton", "value": "C135129"},
              {"label": "Byron Center", "value": "C135130"},
              {"label": "Cadillac", "value": "C135131"},
              {"label": "Caledonia", "value": "C135132"},
              {"label": "Calhoun County", "value": "C135133"},
              {"label": "Canadian Lakes", "value": "C135134"},
              {"label": "Canton", "value": "C135135"},
              {"label": "Capac", "value": "C135136"},
              {"label": "Carleton", "value": "C135137"},
              {"label": "Caro", "value": "C135138"},
              {"label": "Carrollton", "value": "C135139"},
              {"label": "Carson City", "value": "C135140"},
              {"label": "Cass City", "value": "C135141"},
              {"label": "Cass County", "value": "C135142"},
              {"label": "Cassopolis", "value": "C135143"},
              {"label": "Cedar Springs", "value": "C135144"},
              {"label": "Center Line", "value": "C135145"},
              {"label": "Centreville", "value": "C135146"},
              {"label": "Charlevoix", "value": "C135147"},
              {"label": "Charlevoix County", "value": "C135148"},
              {"label": "Charlotte", "value": "C135149"},
              {"label": "Cheboygan", "value": "C135150"},
              {"label": "Cheboygan County", "value": "C135151"},
              {"label": "Chelsea", "value": "C135152"},
              {"label": "Chesaning", "value": "C135153"},
              {"label": "Chippewa County", "value": "C135154"},
              {"label": "Clare", "value": "C135155"},
              {"label": "Clare County", "value": "C135156"},
              {"label": "Clarkston", "value": "C135157"},
              {"label": "Clawson", "value": "C135158"},
              {"label": "Clinton", "value": "C135159"},
              {"label": "Clinton County", "value": "C135160"},
              {"label": "Clinton Township", "value": "C135161"},
              {"label": "Clio", "value": "C135162"},
              {"label": "Coldwater", "value": "C135163"},
              {"label": "Coleman", "value": "C135164"},
              {"label": "Coloma", "value": "C135165"},
              {"label": "Colon", "value": "C135166"},
              {"label": "Comstock Northwest", "value": "C135167"},
              {"label": "Comstock Park", "value": "C135168"},
              {"label": "Concord", "value": "C135169"},
              {"label": "Constantine", "value": "C135170"},
              {"label": "Coopersville", "value": "C135171"},
              {"label": "Corunna", "value": "C135172"},
              {"label": "Crawford County", "value": "C135173"},
              {"label": "Croswell", "value": "C135174"},
              {"label": "Crystal Falls", "value": "C135175"},
              {"label": "Cutlerville", "value": "C135176"},
              {"label": "Davison", "value": "C135177"},
              {"label": "Dearborn", "value": "C135178"},
              {"label": "Dearborn Heights", "value": "C135179"},
              {"label": "Decatur", "value": "C135180"},
              {"label": "Delta County", "value": "C135181"},
              {"label": "Detroit", "value": "C135182"},
              {"label": "Detroit Beach", "value": "C135183"},
              {"label": "DeWitt", "value": "C135184"},
              {"label": "Dexter", "value": "C135185"},
              {"label": "Dickinson County", "value": "C135186"},
              {"label": "Dimondale", "value": "C135187"},
              {"label": "Dollar Bay", "value": "C135188"},
              {"label": "Douglas", "value": "C135189"},
              {"label": "Dowagiac", "value": "C135190"},
              {"label": "Dundee", "value": "C135191"},
              {"label": "Durand", "value": "C135192"},
              {"label": "Eagle River", "value": "C135193"},
              {"label": "East Grand Rapids", "value": "C135194"},
              {"label": "East Jordan", "value": "C135195"},
              {"label": "East Lansing", "value": "C135196"},
              {"label": "East Tawas", "value": "C135197"},
              {"label": "Eastpointe", "value": "C135198"},
              {"label": "Eastwood", "value": "C135199"},
              {"label": "Eaton County", "value": "C135200"},
              {"label": "Eaton Rapids", "value": "C135201"},
              {"label": "Ecorse", "value": "C135202"},
              {"label": "Edgemont Park", "value": "C135203"},
              {"label": "Edmore", "value": "C135204"},
              {"label": "Edwardsburg", "value": "C135205"},
              {"label": "Elk Rapids", "value": "C135206"},
              {"label": "Emmet County", "value": "C135207"},
              {"label": "Escanaba", "value": "C135208"},
              {"label": "Essexville", "value": "C135209"},
              {"label": "Evart", "value": "C135210"},
              {"label": "Fair Plain", "value": "C135211"},
              {"label": "Farmington", "value": "C135212"},
              {"label": "Farmington Hills", "value": "C135213"},
              {"label": "Fennville", "value": "C135214"},
              {"label": "Fenton", "value": "C135215"},
              {"label": "Ferndale", "value": "C135216"},
              {"label": "Ferrysburg", "value": "C135217"},
              {"label": "Flat Rock", "value": "C135218"},
              {"label": "Flint", "value": "C135219"},
              {"label": "Flushing", "value": "C135220"},
              {"label": "Forest Hills", "value": "C135221"},
              {"label": "Fowler", "value": "C135222"},
              {"label": "Fowlerville", "value": "C135223"},
              {"label": "Frankenmuth", "value": "C135224"},
              {"label": "Frankfort", "value": "C135225"},
              {"label": "Franklin", "value": "C135226"},
              {"label": "Fraser", "value": "C135227"},
              {"label": "Freeland", "value": "C135228"},
              {"label": "Fremont", "value": "C135229"},
              {"label": "Fruitport", "value": "C135230"},
              {"label": "Galesburg", "value": "C135231"},
              {"label": "Garden City", "value": "C135232"},
              {"label": "Gaylord", "value": "C135233"},
              {"label": "Genesee County", "value": "C135234"},
              {"label": "Gibraltar", "value": "C135235"},
              {"label": "Gladstone", "value": "C135236"},
              {"label": "Gladwin", "value": "C135237"},
              {"label": "Gladwin County", "value": "C135238"},
              {"label": "Gogebic County", "value": "C135239"},
              {"label": "Goodrich", "value": "C135240"},
              {"label": "Grand Blanc", "value": "C135241"},
              {"label": "Grand Haven", "value": "C135242"},
              {"label": "Grand Ledge", "value": "C135243"},
              {"label": "Grand Rapids", "value": "C135244"},
              {"label": "Grand Traverse County", "value": "C135245"},
              {"label": "Grandville", "value": "C135246"},
              {"label": "Grass Lake", "value": "C135247"},
              {"label": "Gratiot County", "value": "C135248"},
              {"label": "Grayling", "value": "C135249"},
              {"label": "Greenville", "value": "C135250"},
              {"label": "Greilickville", "value": "C135251"},
              {"label": "Grosse Ile", "value": "C135252"},
              {"label": "Grosse Pointe", "value": "C135253"},
              {"label": "Grosse Pointe Farms", "value": "C135254"},
              {"label": "Grosse Pointe Park", "value": "C135255"},
              {"label": "Grosse Pointe Shores", "value": "C135256"},
              {"label": "Grosse Pointe Woods", "value": "C135257"},
              {"label": "Gwinn", "value": "C135258"},
              {"label": "Hamtramck", "value": "C135259"},
              {"label": "Hancock", "value": "C135260"},
              {"label": "Harbor Beach", "value": "C135261"},
              {"label": "Harbor Springs", "value": "C135262"},
              {"label": "Harper Woods", "value": "C135263"},
              {"label": "Harrison", "value": "C135264"},
              {"label": "Harrisville", "value": "C135265"},
              {"label": "Hart", "value": "C135266"},
              {"label": "Hartford", "value": "C135267"},
              {"label": "Harvey", "value": "C135268"},
              {"label": "Haslett", "value": "C135269"},
              {"label": "Hastings", "value": "C135270"},
              {"label": "Hazel Park", "value": "C135271"},
              {"label": "Hemlock", "value": "C135272"},
              {"label": "Highland Park", "value": "C135273"},
              {"label": "Hillsdale", "value": "C135274"},
              {"label": "Hillsdale County", "value": "C135275"},
              {"label": "Holland", "value": "C135276"},
              {"label": "Holly", "value": "C135277"},
              {"label": "Holt", "value": "C135278"},
              {"label": "Homer", "value": "C135279"},
              {"label": "Houghton", "value": "C135280"},
              {"label": "Houghton County", "value": "C135281"},
              {"label": "Houghton Lake", "value": "C135282"},
              {"label": "Howard City", "value": "C135283"},
              {"label": "Howell", "value": "C135284"},
              {"label": "Hubbard Lake", "value": "C135285"},
              {"label": "Hudson", "value": "C135286"},
              {"label": "Hudsonville", "value": "C135287"},
              {"label": "Huntington Woods", "value": "C135288"},
              {"label": "Huron County", "value": "C135289"},
              {"label": "Imlay City", "value": "C135290"},
              {"label": "Indian River", "value": "C135291"},
              {"label": "Ingham County", "value": "C135292"},
              {"label": "Inkster", "value": "C135293"},
              {"label": "Ionia", "value": "C135294"},
              {"label": "Ionia County", "value": "C135295"},
              {"label": "Iosco County", "value": "C135296"},
              {"label": "Iron County", "value": "C135297"},
              {"label": "Iron Mountain", "value": "C135298"},
              {"label": "Iron River", "value": "C135299"},
              {"label": "Ironwood", "value": "C135300"},
              {"label": "Isabella County", "value": "C135301"},
              {"label": "Ishpeming", "value": "C135302"},
              {"label": "Ithaca", "value": "C135303"},
              {"label": "Jackson", "value": "C135304"},
              {"label": "Jackson County", "value": "C135305"},
              {"label": "Jenison", "value": "C135306"},
              {"label": "Jonesville", "value": "C135307"},
              {"label": "K. I. Sawyer Air Force Base", "value": "C135308"},
              {"label": "Kalamazoo", "value": "C135309"},
              {"label": "Kalamazoo County", "value": "C135310"},
              {"label": "Kalkaska", "value": "C135311"},
              {"label": "Kalkaska County", "value": "C135312"},
              {"label": "Keego Harbor", "value": "C135313"},
              {"label": "Kent City", "value": "C135314"},
              {"label": "Kent County", "value": "C135315"},
              {"label": "Kentwood", "value": "C135316"},
              {"label": "Keweenaw County", "value": "C135317"},
              {"label": "Kilmanagh", "value": "C135318"},
              {"label": "Kingsford", "value": "C135319"},
              {"label": "Kingsley", "value": "C135320"},
              {"label": "LAnse", "value": "C135321"},
              {"label": "Laingsburg", "value": "C135322"},
              {"label": "Lake City", "value": "C135323"},
              {"label": "Lake County", "value": "C135324"},
              {"label": "Lake Fenton", "value": "C135325"},
              {"label": "Lake Isabella", "value": "C135326"},
              {"label": "Lake Michigan Beach", "value": "C135327"},
              {"label": "Lake Odessa", "value": "C135328"},
              {"label": "Lake Orion", "value": "C135329"},
              {"label": "Lakeview", "value": "C135330"},
              {"label": "Lakewood Club", "value": "C135331"},
              {"label": "Lambertville", "value": "C135332"},
              {"label": "Lansing", "value": "C135333"},
              {"label": "Lapeer", "value": "C135334"},
              {"label": "Lapeer County", "value": "C135335"},
              {"label": "Lathrup Village", "value": "C135336"},
              {"label": "Laurium", "value": "C135337"},
              {"label": "Lawton", "value": "C135338"},
              {"label": "Leelanau County", "value": "C135339"},
              {"label": "Leland", "value": "C135340"},
              {"label": "Lenawee County", "value": "C135341"},
              {"label": "Leslie", "value": "C135342"},
              {"label": "Level Park-Oak Park", "value": "C135343"},
              {"label": "Lewiston", "value": "C135344"},
              {"label": "Lexington", "value": "C135345"},
              {"label": "Lincoln Park", "value": "C135346"},
              {"label": "Linden", "value": "C135347"},
              {"label": "Litchfield", "value": "C135348"},
              {"label": "Livingston County", "value": "C135349"},
              {"label": "Livonia", "value": "C135350"},
              {"label": "Lowell", "value": "C135351"},
              {"label": "Luce County", "value": "C135352"},
              {"label": "Ludington", "value": "C135353"},
              {"label": "Luna Pier", "value": "C135354"},
              {"label": "Mackinac County", "value": "C135355"},
              {"label": "Macomb County", "value": "C135356"},
              {"label": "Madison Heights", "value": "C135357"},
              {"label": "Mancelona", "value": "C135358"},
              {"label": "Manchester", "value": "C135359"},
              {"label": "Manistee", "value": "C135360"},
              {"label": "Manistee County", "value": "C135361"},
              {"label": "Manistique", "value": "C135362"},
              {"label": "Manitou Beach-Devils Lake", "value": "C135363"},
              {"label": "Manton", "value": "C135364"},
              {"label": "Marcellus", "value": "C135365"},
              {"label": "Marine City", "value": "C135366"},
              {"label": "Marlette", "value": "C135367"},
              {"label": "Marquette", "value": "C135368"},
              {"label": "Marquette County", "value": "C135369"},
              {"label": "Marshall", "value": "C135370"},
              {"label": "Marysville", "value": "C135371"},
              {"label": "Mason", "value": "C135372"},
              {"label": "Mason County", "value": "C135373"},
              {"label": "Mattawan", "value": "C135374"},
              {"label": "Mecosta County", "value": "C135375"},
              {"label": "Melvindale", "value": "C135376"},
              {"label": "Memphis", "value": "C135377"},
              {"label": "Menominee", "value": "C135378"},
              {"label": "Menominee County", "value": "C135379"},
              {"label": "Michigan Center", "value": "C135380"},
              {"label": "Middleville", "value": "C135381"},
              {"label": "Midland", "value": "C135382"},
              {"label": "Midland County", "value": "C135383"},
              {"label": "Milan", "value": "C135384"},
              {"label": "Milford", "value": "C135385"},
              {"label": "Millington", "value": "C135386"},
              {"label": "Mio", "value": "C135387"},
              {"label": "Missaukee County", "value": "C135388"},
              {"label": "Monroe", "value": "C135389"},
              {"label": "Monroe County", "value": "C135390"},
              {"label": "Montague", "value": "C135391"},
              {"label": "Montcalm County", "value": "C135392"},
              {"label": "Montmorency County", "value": "C135393"},
              {"label": "Montrose", "value": "C135394"},
              {"label": "Morenci", "value": "C135395"},
              {"label": "Mount Clemens", "value": "C135396"},
              {"label": "Mount Morris", "value": "C135397"},
              {"label": "Mount Pleasant", "value": "C135398"},
              {"label": "Munising", "value": "C135399"},
              {"label": "Muskegon", "value": "C135400"},
              {"label": "Muskegon County", "value": "C135401"},
              {"label": "Muskegon Heights", "value": "C135402"},
              {"label": "Napoleon", "value": "C135403"},
              {"label": "Nashville", "value": "C135404"},
              {"label": "Negaunee", "value": "C135405"},
              {"label": "New Baltimore", "value": "C135406"},
              {"label": "New Buffalo", "value": "C135407"},
              {"label": "New Haven", "value": "C135408"},
              {"label": "Newaygo", "value": "C135409"},
              {"label": "Newaygo County", "value": "C135410"},
              {"label": "Newberry", "value": "C135411"},
              {"label": "Niles", "value": "C135412"},
              {"label": "North Branch", "value": "C135413"},
              {"label": "North Muskegon", "value": "C135414"},
              {"label": "Northview", "value": "C135415"},
              {"label": "Northville", "value": "C135416"},
              {"label": "Norton Shores", "value": "C135417"},
              {"label": "Norway", "value": "C135418"},
              {"label": "Novi", "value": "C135419"},
              {"label": "Oak Park", "value": "C135420"},
              {"label": "Oakland County", "value": "C135421"},
              {"label": "Oceana County", "value": "C135422"},
              {"label": "Ogemaw County", "value": "C135423"},
              {"label": "Okemos", "value": "C135424"},
              {"label": "Olivet", "value": "C135425"},
              {"label": "Ontonagon", "value": "C135426"},
              {"label": "Ontonagon County", "value": "C135427"},
              {"label": "Orchard Lake", "value": "C135428"},
              {"label": "Ortonville", "value": "C135429"},
              {"label": "Osceola County", "value": "C135430"},
              {"label": "Oscoda County", "value": "C135431"},
              {"label": "Otsego", "value": "C135432"},
              {"label": "Otsego County", "value": "C135433"},
              {"label": "Ottawa County", "value": "C135434"},
              {"label": "Ovid", "value": "C135435"},
              {"label": "Owosso", "value": "C135436"},
              {"label": "Oxford", "value": "C135437"},
              {"label": "Parchment", "value": "C135438"},
              {"label": "Paw Paw", "value": "C135439"},
              {"label": "Paw Paw Lake", "value": "C135440"},
              {"label": "Pearl Beach", "value": "C135441"},
              {"label": "Perry", "value": "C135442"},
              {"label": "Petersburg", "value": "C135443"},
              {"label": "Petoskey", "value": "C135444"},
              {"label": "Pigeon", "value": "C135445"},
              {"label": "Pinckney", "value": "C135446"},
              {"label": "Pinconning", "value": "C135447"},
              {"label": "Plainwell", "value": "C135448"},
              {"label": "Pleasant Ridge", "value": "C135449"},
              {"label": "Plymouth", "value": "C135450"},
              {"label": "Pontiac", "value": "C135451"},
              {"label": "Port Huron", "value": "C135452"},
              {"label": "Portage", "value": "C135453"},
              {"label": "Portland", "value": "C135454"},
              {"label": "Potterville", "value": "C135455"},
              {"label": "Presque Isle County", "value": "C135456"},
              {"label": "Prudenville", "value": "C135457"},
              {"label": "Quincy", "value": "C135458"},
              {"label": "Quinnesec", "value": "C135459"},
              {"label": "Rapid City", "value": "C135460"},
              {"label": "Ravenna", "value": "C135461"},
              {"label": "Reading", "value": "C135462"},
              {"label": "Redford", "value": "C135463"},
              {"label": "Reed City", "value": "C135464"},
              {"label": "Reese", "value": "C135465"},
              {"label": "Richmond", "value": "C135466"},
              {"label": "River Rouge", "value": "C135467"},
              {"label": "Riverview", "value": "C135468"},
              {"label": "Rochester", "value": "C135469"},
              {"label": "Rochester Hills", "value": "C135470"},
              {"label": "Rockford", "value": "C135471"},
              {"label": "Rockwood", "value": "C135472"},
              {"label": "Rogers City", "value": "C135473"},
              {"label": "Romeo", "value": "C135474"},
              {"label": "Romulus", "value": "C135475"},
              {"label": "Roosevelt Park", "value": "C135476"},
              {"label": "Roscommon", "value": "C135477"},
              {"label": "Roscommon County", "value": "C135478"},
              {"label": "Roseville", "value": "C135479"},
              {"label": "Royal Oak", "value": "C135480"},
              {"label": "Saginaw", "value": "C135481"},
              {"label": "Saginaw County", "value": "C135482"},
              {"label": "Saginaw Township North", "value": "C135483"},
              {"label": "Saint Charles", "value": "C135484"},
              {"label": "Saint Clair", "value": "C135485"},
              {"label": "Saint Clair County", "value": "C135486"},
              {"label": "Saint Clair Shores", "value": "C135487"},
              {"label": "Saint Helen", "value": "C135488"},
              {"label": "Saint Ignace", "value": "C135489"},
              {"label": "Saint Johns", "value": "C135490"},
              {"label": "Saint Joseph", "value": "C135491"},
              {"label": "Saint Joseph County", "value": "C135492"},
              {"label": "Saint Louis", "value": "C135493"},
              {"label": "Saline", "value": "C135494"},
              {"label": "Sand Lake", "value": "C135495"},
              {"label": "Sandusky", "value": "C135496"},
              {"label": "Sanilac County", "value": "C135497"},
              {"label": "Saranac", "value": "C135498"},
              {"label": "Sault Ste. Marie", "value": "C135499"},
              {"label": "Schoolcraft", "value": "C135500"},
              {"label": "Schoolcraft County", "value": "C135501"},
              {"label": "Scottville", "value": "C135502"},
              {"label": "Sebewaing", "value": "C135503"},
              {"label": "Shelby", "value": "C135504"},
              {"label": "Shepherd", "value": "C135505"},
              {"label": "Shiawassee County", "value": "C135506"},
              {"label": "Shields", "value": "C135507"},
              {"label": "Shorewood-Tower Hills-Harbert", "value": "C135508"},
              {"label": "Skidway Lake", "value": "C135509"},
              {"label": "South Gull Lake", "value": "C135510"},
              {"label": "South Haven", "value": "C135511"},
              {"label": "South Lyon", "value": "C135512"},
              {"label": "South Monroe", "value": "C135513"},
              {"label": "South Rockwood", "value": "C135514"},
              {"label": "Southfield", "value": "C135515"},
              {"label": "Southgate", "value": "C135516"},
              {"label": "Sparta", "value": "C135517"},
              {"label": "Spring Arbor", "value": "C135518"},
              {"label": "Spring Lake", "value": "C135519"},
              {"label": "Springfield", "value": "C135520"},
              {"label": "Stambaugh, Iron River", "value": "C135521"},
              {"label": "Standish", "value": "C135522"},
              {"label": "Stanton", "value": "C135523"},
              {"label": "Sterling Heights", "value": "C135524"},
              {"label": "Stevensville", "value": "C135525"},
              {"label": "Stockbridge", "value": "C135526"},
              {"label": "Stony Point", "value": "C135527"},
              {"label": "Sturgis", "value": "C135528"},
              {"label": "Swartz Creek", "value": "C135529"},
              {"label": "Sylvan Lake", "value": "C135530"},
              {"label": "Tawas City", "value": "C135531"},
              {"label": "Taylor", "value": "C135532"},
              {"label": "Tecumseh", "value": "C135533"},
              {"label": "Temperance", "value": "C135534"},
              {"label": "Three Oaks", "value": "C135535"},
              {"label": "Three Rivers", "value": "C135536"},
              {"label": "Traverse City", "value": "C135537"},
              {"label": "Trenton", "value": "C135538"},
              {"label": "Trowbridge Park", "value": "C135539"},
              {"label": "Troy", "value": "C135540"},
              {"label": "Tuscola County", "value": "C135541"},
              {"label": "Twin Lake", "value": "C135542"},
              {"label": "Union City", "value": "C135543"},
              {"label": "Utica", "value": "C135544"},
              {"label": "Van Buren County", "value": "C135545"},
              {"label": "Vandercook Lake", "value": "C135546"},
              {"label": "Vassar", "value": "C135547"},
              {"label": "Vicksburg", "value": "C135548"},
              {"label": "Wacousta", "value": "C135549"},
              {"label": "Wakefield", "value": "C135550"},
              {"label": "Walker", "value": "C135551"},
              {"label": "Walled Lake", "value": "C135552"},
              {"label": "Warren", "value": "C135553"},
              {"label": "Washtenaw County", "value": "C135554"},
              {"label": "Waterford", "value": "C135555"},
              {"label": "Watervliet", "value": "C135556"},
              {"label": "Waverly", "value": "C135557"},
              {"label": "Wayland", "value": "C135558"},
              {"label": "Wayne", "value": "C135559"},
              {"label": "Wayne County", "value": "C135560"},
              {"label": "Webberville", "value": "C135561"},
              {"label": "West Bloomfield Township", "value": "C135562"},
              {"label": "West Branch", "value": "C135563"},
              {"label": "West Ishpeming", "value": "C135564"},
              {"label": "West Monroe", "value": "C135565"},
              {"label": "Westland", "value": "C135566"},
              {"label": "Westwood", "value": "C135567"},
              {"label": "Wexford County", "value": "C135568"},
              {"label": "White Cloud", "value": "C135569"},
              {"label": "White Pigeon", "value": "C135570"},
              {"label": "Whitehall", "value": "C135571"},
              {"label": "Whitmore Lake", "value": "C135572"},
              {"label": "Williamston", "value": "C135573"},
              {"label": "Wixom", "value": "C135574"},
              {"label": "Wolf Lake", "value": "C135575"},
              {"label": "Wolverine Lake", "value": "C135576"},
              {"label": "Woodhaven", "value": "C135577"},
              {"label": "Woodland Beach", "value": "C135578"},
              {"label": "Wyandotte", "value": "C135579"},
              {"label": "Wyoming", "value": "C135580"},
              {"label": "Yale", "value": "C135581"},
              {"label": "Ypsilanti", "value": "C135582"},
              {"label": "Zeeland", "value": "C135583"},
              {"label": "Zilwaukee", "value": "C135584"}],
 "Michoacan de Ocampo": [{"label": "Acuitzio", "value": "C70446"},
                         {"label": "Acuitzio del Canje", "value": "C70447"},
                         {"label": "Agostitlan", "value": "C70448"},
                         {"label": "Agua Caliente", "value": "C70449"},
                         {"label": "Aguililla", "value": "C70450"},
                         {"label": "Ahuiran", "value": "C70451"},
                         {"label": "Ajuno", "value": "C70452"},
                         {"label": "alvaro Obregon", "value": "C70453"},
                         {"label": "Angahuan", "value": "C70454"},
                         {"label": "Angamacutiro de la Union",
                          "value": "C70455"},
                         {"label": "Ansihuacuaro", "value": "C70456"},
                         {"label": "Antunez", "value": "C70457"},
                         {"label": "Antunez (Morelos)", "value": "C70458"},
                         {"label": "Apatzingan", "value": "C70459"},
                         {"label": "Apeo", "value": "C70460"},
                         {"label": "Apo", "value": "C70461"},
                         {"label": "Aporo", "value": "C70462"},
                         {"label": "Aputzio de Juarez", "value": "C70463"},
                         {"label": "Aquila", "value": "C70464"},
                         {"label": "Aquiles Cordova Moran", "value": "C70465"},
                         {"label": "Aquiles Serdan", "value": "C70466"},
                         {"label": "Arantepacua", "value": "C70467"},
                         {"label": "Aranza", "value": "C70468"},
                         {"label": "Araro", "value": "C70469"},
                         {"label": "Ario de Rayon", "value": "C70470"},
                         {"label": "Ario de Rosales", "value": "C70471"},
                         {"label": "Arteaga", "value": "C70472"},
                         {"label": "Atacheo de Regalado", "value": "C70473"},
                         {"label": "Atapan", "value": "C70474"},
                         {"label": "Atapaneo", "value": "C70475"},
                         {"label": "Atecucario de la Constitucion (Atecuario)",
                          "value": "C70476"},
                         {"label": "Atotonilco", "value": "C70477"},
                         {"label": "Belisario Dominguez", "value": "C70478"},
                         {"label": "Bellas Fuentes", "value": "C70479"},
                         {"label": "Benito Juarez", "value": "C70480"},
                         {"label": "Bocaneo (San Pedro)", "value": "C70481"},
                         {"label": "Bonifacio Moreno (El Aguaje)",
                          "value": "C70482"},
                         {"label": "Brisenas", "value": "C70483"},
                         {"label": "Brisenas de Matamoros", "value": "C70484"},
                         {"label": "Buenavista", "value": "C70485"},
                         {"label": "Buenavista de Benito Juarez",
                          "value": "C70486"},
                         {"label": "Buenavista Tomatlan", "value": "C70487"},
                         {"label": "Buenos Aires", "value": "C70488"},
                         {"label": "Caltzontzin", "value": "C70489"},
                         {"label": "Camembaro", "value": "C70490"},
                         {"label": "Campestre Tarimbaro", "value": "C70491"},
                         {"label": "Canada de Ramirez", "value": "C70492"},
                         {"label": "Cantabria", "value": "C70493"},
                         {"label": "Capacho", "value": "C70494"},
                         {"label": "Capacuaro", "value": "C70495"},
                         {"label": "Capula", "value": "C70496"},
                         {"label": "Caracuaro", "value": "C70497"},
                         {"label": "Carona", "value": "C70498"},
                         {"label": "Caurio de Guadalupe", "value": "C70499"},
                         {"label": "Cenobio Moreno", "value": "C70500"},
                         {"label": "Cerro Colorado de Ocampo",
                          "value": "C70501"},
                         {"label": "Chapa", "value": "C70502"},
                         {"label": "Charapan", "value": "C70503"},
                         {"label": "Charapendo", "value": "C70504"},
                         {"label": "Charo", "value": "C70505"},
                         {"label": "Chavinda", "value": "C70506"},
                         {"label": "Cheran", "value": "C70507"},
                         {"label": "Cheran Atzicuirin (Cheranastico)",
                          "value": "C70508"},
                         {"label": "Chilchota", "value": "C70509"},
                         {"label": "Chiquimitio", "value": "C70510"},
                         {"label": "Chiteje de Garabato", "value": "C70511"},
                         {"label": "Chucandiro", "value": "C70512"},
                         {"label": "Chupio", "value": "C70513"},
                         {"label": "Churintzio", "value": "C70514"},
                         {"label": "Churumuco de Morelos", "value": "C70515"},
                         {"label": "Ciudad Hidalgo", "value": "C70516"},
                         {"label": "Ciudad Lazaro Cardenas", "value": "C70517"},
                         {"label": "Coahuayana de Hidalgo", "value": "C70518"},
                         {"label": "Coahuayana Viejo", "value": "C70519"},
                         {"label": "Coajomulco", "value": "C70520"},
                         {"label": "Coalcoman de Vazquez Pallares",
                          "value": "C70521"},
                         {"label": "Cocucho", "value": "C70522"},
                         {"label": "Coeneo de la Libertad", "value": "C70523"},
                         {"label": "Cojumatlan de Regules", "value": "C70524"},
                         {"label": "Colonia Antorcha Campesina (Santa Rosa)",
                          "value": "C70525"},
                         {"label": "Colonia Aquiles Cordoba Moran",
                          "value": "C70526"},
                         {"label": "Colonia de Guadalupe", "value": "C70527"},
                         {"label": "Colonia Ecologica Asociacion de Lucha Social (Lucha Social)",
                          "value": "C70528"},
                         {"label": "Colonia el Mirador (Llano del Ejido)",
                          "value": "C70529"},
                         {"label": "Colonia Emiliano Zapata (San Juan Zitacuaro)",
                          "value": "C70530"},
                         {"label": "Colonia Independencia", "value": "C70531"},
                         {"label": "Colonia las Malvinas (Colonia Antorcha)",
                          "value": "C70532"},
                         {"label": "Colonia Miguel Hidalgo", "value": "C70533"},
                         {"label": "Colonia Vista Bella (Lomas del Peaje)",
                          "value": "C70534"},
                         {"label": "Comachuen", "value": "C70535"},
                         {"label": "Comanja", "value": "C70536"},
                         {"label": "Condembaro", "value": "C70537"},
                         {"label": "Conjunto Habitacional el Trebol",
                          "value": "C70538"},
                         {"label": "Conjunto Habitacional Villas del Pedregal",
                          "value": "C70539"},
                         {"label": "Copandaro (Copandaro del Cuatro)",
                          "value": "C70540"},
                         {"label": "Copandaro de Galeana", "value": "C70541"},
                         {"label": "Corupo", "value": "C70542"},
                         {"label": "Cotija de la Paz", "value": "C70543"},
                         {"label": "Crescencio Morales (San Mateo)",
                          "value": "C70544"},
                         {"label": "Cuamio", "value": "C70545"},
                         {"label": "Cuanajo", "value": "C70546"},
                         {"label": "Cuaracurio", "value": "C70547"},
                         {"label": "Cuartel la Mesa (El Asoleadero)",
                          "value": "C70548"},
                         {"label": "Cuatro Caminos", "value": "C70549"},
                         {"label": "Cucuchucho", "value": "C70550"},
                         {"label": "Cuitzeo del Porvenir", "value": "C70551"},
                         {"label": "Cumuato", "value": "C70552"},
                         {"label": "Cuparataro", "value": "C70553"},
                         {"label": "Cupuan del Rio", "value": "C70554"},
                         {"label": "Curimeo", "value": "C70555"},
                         {"label": "Curungueo", "value": "C70556"},
                         {"label": "Cuto de la Esperanza", "value": "C70557"},
                         {"label": "Cuto del Porvenir", "value": "C70558"},
                         {"label": "Dieciocho de Marzo", "value": "C70559"},
                         {"label": "Division del Norte", "value": "C70560"},
                         {"label": "Doctor Miguel Silva (San Guillermo)",
                          "value": "C70561"},
                         {"label": "Donaciano Ojeda", "value": "C70562"},
                         {"label": "Ecuandureo", "value": "C70563"},
                         {"label": "Ejido el Rosario", "value": "C70564"},
                         {"label": "El Alvareno", "value": "C70565"},
                         {"label": "El Cahulote de Santa Ana",
                          "value": "C70566"},
                         {"label": "El Capulin", "value": "C70567"},
                         {"label": "El Cenidor", "value": "C70568"},
                         {"label": "El Cesped", "value": "C70569"},
                         {"label": "El Chauz", "value": "C70570"},
                         {"label": "El Colegio", "value": "C70571"},
                         {"label": "El Cuenqueno", "value": "C70572"},
                         {"label": "El Cuitzillo Grande", "value": "C70573"},
                         {"label": "El Durazno", "value": "C70574"},
                         {"label": "El Fortin", "value": "C70575"},
                         {"label": "El Fuerte", "value": "C70576"},
                         {"label": "El Guaco", "value": "C70577"},
                         {"label": "El Letrero", "value": "C70578"},
                         {"label": "El Maluco", "value": "C70579"},
                         {"label": "El Paracho", "value": "C70580"},
                         {"label": "El Pedregal", "value": "C70581"},
                         {"label": "El Platanal", "value": "C70582"},
                         {"label": "El Rincon de San Felipe (Tercera Manzana San Felipe)",
                          "value": "C70583"},
                         {"label": "El Sauz de Abajo", "value": "C70584"},
                         {"label": "El Tequesquite", "value": "C70585"},
                         {"label": "El Tigre (Segunda Manzana de Crescencio Morales)",
                          "value": "C70586"},
                         {"label": "Emiliano Zapata", "value": "C70587"},
                         {"label": "Erongaricuaro", "value": "C70588"},
                         {"label": "Estacion Querendaro", "value": "C70589"},
                         {"label": "Etucuaro", "value": "C70590"},
                         {"label": "Ex-Hacienda de Guadalupe",
                          "value": "C70591"},
                         {"label": "Felipe Carrillo Puerto", "value": "C70592"},
                         {"label": "Fraccionamiento Colinas Universidad",
                          "value": "C70593"},
                         {"label": "Fraccionamiento Ex-Hacienda el Refugio",
                          "value": "C70594"},
                         {"label": "Fraccionamiento Galaxia Tarimbaro",
                          "value": "C70595"},
                         {"label": "Fraccionamiento Laureles Erendira",
                          "value": "C70596"},
                         {"label": "Fraccionamiento Metropolis II",
                          "value": "C70597"},
                         {"label": "Fraccionamiento Mision del Valle",
                          "value": "C70598"},
                         {"label": "Fraccionamiento Monte Olivo",
                          "value": "C70599"},
                         {"label": "Fraccionamiento Privadas del Sol",
                          "value": "C70600"},
                         {"label": "Fraccionamiento San Miguel",
                          "value": "C70601"},
                         {"label": "Francisco Sarabia (Cerrito Pelon)",
                          "value": "C70602"},
                         {"label": "Francisco Serrato (San Bartolo)",
                          "value": "C70603"},
                         {"label": "Francisco Villa", "value": "C70604"},
                         {"label": "Gambara", "value": "C70605"},
                         {"label": "General Francisco Villa",
                          "value": "C70606"},
                         {"label": "Gildardo Magana (Los angeles)",
                          "value": "C70607"},
                         {"label": "Guacamayas", "value": "C70608"},
                         {"label": "Guandaro", "value": "C70609"},
                         {"label": "Heroes de Chapultepec (Rodeo de San Antonio)"
                                   ,
                          "value": "C70610"},
                         {"label": "Heroica Zitacuaro", "value": "C70611"},
                         {"label": "Huajumbaro", "value": "C70612"},
                         {"label": "Huandacareo", "value": "C70613"},
                         {"label": "Huanguitio", "value": "C70614"},
                         {"label": "Huaniqueo de Morales", "value": "C70615"},
                         {"label": "Huetamo de Nunez", "value": "C70616"},
                         {"label": "Huiramba", "value": "C70617"},
                         {"label": "Ibarra", "value": "C70618"},
                         {"label": "Ichan", "value": "C70619"},
                         {"label": "Ihuatzio", "value": "C70620"},
                         {"label": "Indaparapeo", "value": "C70621"},
                         {"label": "Infiernillo (Morelos de Infiernillo)",
                          "value": "C70622"},
                         {"label": "Irapeo", "value": "C70623"},
                         {"label": "Iratzio", "value": "C70624"},
                         {"label": "Irimbo", "value": "C70625"},
                         {"label": "Isaac Arriaga (Santa Ana Mancera)",
                          "value": "C70626"},
                         {"label": "Istaro", "value": "C70627"},
                         {"label": "Ixtlan de los Hervores", "value": "C70628"},
                         {"label": "J. Jesus Diaz Tzirio", "value": "C70629"},
                         {"label": "Jacona", "value": "C70630"},
                         {"label": "Jamaica", "value": "C70631"},
                         {"label": "Janitzio", "value": "C70632"},
                         {"label": "Jaracuaro", "value": "C70633"},
                         {"label": "Jaripeo", "value": "C70634"},
                         {"label": "Jesus del Monte", "value": "C70635"},
                         {"label": "Jiquilpan de Juarez", "value": "C70636"},
                         {"label": "Jose Maria Morelos (La Yegueria)",
                          "value": "C70637"},
                         {"label": "Jucutacato", "value": "C70638"},
                         {"label": "Jungapeo de Juarez", "value": "C70639"},
                         {"label": "La Aldea", "value": "C70640"},
                         {"label": "La Angostura", "value": "C70641"},
                         {"label": "La Calera (Nacimientos)",
                          "value": "C70642"},
                         {"label": "La Encarnacion", "value": "C70643"},
                         {"label": "La Ermita (Nueva Jerusalen)",
                          "value": "C70644"},
                         {"label": "La Esperanza", "value": "C70645"},
                         {"label": "La Estancia de Amezcua", "value": "C70646"},
                         {"label": "La Fundicion (Quinta Manzana)",
                          "value": "C70647"},
                         {"label": "La Goleta", "value": "C70648"},
                         {"label": "La Higuerilla (Los Lirios)",
                          "value": "C70649"},
                         {"label": "La Higuerita (Colonia San Rafael)",
                          "value": "C70650"},
                         {"label": "La Huacana", "value": "C70651"},
                         {"label": "La Iberica (La Gotera)", "value": "C70652"},
                         {"label": "La Luz", "value": "C70653"},
                         {"label": "La Mesa (La Mesa de Cedano)",
                          "value": "C70654"},
                         {"label": "La Mintzita (Piedra Dura)",
                          "value": "C70655"},
                         {"label": "La Mira", "value": "C70656"},
                         {"label": "La Mira Tumbiscatio", "value": "C70657"},
                         {"label": "La Mojonera", "value": "C70658"},
                         {"label": "La Orilla", "value": "C70659"},
                         {"label": "La Palma", "value": "C70660"},
                         {"label": "La Palma (Las Palmas)", "value": "C70661"},
                         {"label": "La Piedad", "value": "C70662"},
                         {"label": "La Placita de Morelos", "value": "C70663"},
                         {"label": "La Plaza del Limon", "value": "C70664"},
                         {"label": "La Rinconoda", "value": "C70665"},
                         {"label": "La Sauceda", "value": "C70666"},
                         {"label": "La Soledad", "value": "C70667"},
                         {"label": "Lagunillas", "value": "C70668"},
                         {"label": "Las Canadas", "value": "C70669"},
                         {"label": "Las Canas", "value": "C70670"},
                         {"label": "Las Cieneguitas", "value": "C70671"},
                         {"label": "Las Letras", "value": "C70672"},
                         {"label": "Lazaro Cardenas (La Purisima)",
                          "value": "C70673"},
                         {"label": "Loma de los Hoyos", "value": "C70674"},
                         {"label": "Lomas de la Maestranza", "value": "C70675"},
                         {"label": "Lombardia", "value": "C70676"},
                         {"label": "Los Cajones", "value": "C70677"},
                         {"label": "Los Charcos", "value": "C70678"},
                         {"label": "Los Guajes", "value": "C70679"},
                         {"label": "Los Limones", "value": "C70680"},
                         {"label": "Los Pilares", "value": "C70681"},
                         {"label": "Los Reyes de Salgado", "value": "C70682"},
                         {"label": "Macho de Agua", "value": "C70683"},
                         {"label": "Macutzio", "value": "C70684"},
                         {"label": "Manuel Villalongin", "value": "C70685"},
                         {"label": "Manzana de San Luis", "value": "C70686"},
                         {"label": "Manzana la Cofradia", "value": "C70687"},
                         {"label": "Maravatio de Ocampo", "value": "C70688"},
                         {"label": "Mariano Escobedo", "value": "C70689"},
                         {"label": "Meson Nuevo (Canada de la Magdalena)",
                          "value": "C70690"},
                         {"label": "Mineral de Angangueo", "value": "C70691"},
                         {"label": "Montana Monarca (Punta Altozano)",
                          "value": "C70692"},
                         {"label": "Monteleon", "value": "C70693"},
                         {"label": "Morelia", "value": "C70694"},
                         {"label": "Morelos", "value": "C70695"},
                         {"label": "Nahuatzen", "value": "C70696"},
                         {"label": "Naranja de Tapia", "value": "C70697"},
                         {"label": "Nocupetaro", "value": "C70698"},
                         {"label": "Nueva Italia de Ruiz", "value": "C70699"},
                         {"label": "Nuevo San Juan Parangaricutiro",
                          "value": "C70700"},
                         {"label": "Nuevo Urecho", "value": "C70701"},
                         {"label": "Nuevo Zirosto", "value": "C70702"},
                         {"label": "Numaran", "value": "C70703"},
                         {"label": "Nurio", "value": "C70704"},
                         {"label": "Ocampo", "value": "C70705"},
                         {"label": "Ocurio", "value": "C70706"},
                         {"label": "Ojo de Agua de Bucio", "value": "C70707"},
                         {"label": "Ojo de Rana", "value": "C70708"},
                         {"label": "Opopeo", "value": "C70709"},
                         {"label": "Pajacuaran", "value": "C70710"},
                         {"label": "Pamatacuaro", "value": "C70711"},
                         {"label": "Panindicuaro", "value": "C70712"},
                         {"label": "Paracho de Verduzco", "value": "C70713"},
                         {"label": "Paracuaro", "value": "C70714"},
                         {"label": "Parahuen", "value": "C70715"},
                         {"label": "Pareo", "value": "C70716"},
                         {"label": "Paricuaro", "value": "C70717"},
                         {"label": "Paso de Hidalgo (Paso de alamos)",
                          "value": "C70718"},
                         {"label": "Paso de Nunez (Buenavista)",
                          "value": "C70719"},
                         {"label": "Pastor Ortiz", "value": "C70720"},
                         {"label": "Patuan", "value": "C70721"},
                         {"label": "Patzcuaro", "value": "C70722"},
                         {"label": "Pedernales", "value": "C70723"},
                         {"label": "Pena del Panal", "value": "C70724"},
                         {"label": "Penjamillo de Degollado",
                          "value": "C70725"},
                         {"label": "Periban de Ramos", "value": "C70726"},
                         {"label": "Pichataro", "value": "C70727"},
                         {"label": "Pinzandaro", "value": "C70728"},
                         {"label": "Pomacuaran", "value": "C70729"},
                         {"label": "Poturo", "value": "C70730"},
                         {"label": "Presa del Rosario", "value": "C70731"},
                         {"label": "Puacuaro", "value": "C70732"},
                         {"label": "Pueblo Viejo", "value": "C70733"},
                         {"label": "Puentecillas (Tercera Manzana de Zirahuato)",
                          "value": "C70734"},
                         {"label": "Puerto de Buenavista (Lazaro Cardenas)",
                          "value": "C70735"},
                         {"label": "Purechucho", "value": "C70736"},
                         {"label": "Purepero de Echaiz", "value": "C70737"},
                         {"label": "Puruandiro", "value": "C70738"},
                         {"label": "Puruaran", "value": "C70739"},
                         {"label": "Querendaro", "value": "C70740"},
                         {"label": "Quinceo", "value": "C70741"},
                         {"label": "Quiroga", "value": "C70742"},
                         {"label": "Rancho Viejo", "value": "C70743"},
                         {"label": "Real Hacienda (Metropolis)",
                          "value": "C70744"},
                         {"label": "Rincon de Cedenos (Rincon de Dolores)",
                          "value": "C70745"},
                         {"label": "Rincon de Curungueo", "value": "C70746"},
                         {"label": "Rincon de Nicolas Romero (Cedros Tercera"
                                   ,
                          "value": "C70747"},
                         {"label": "Rio Grande", "value": "C70748"},
                         {"label": "Romero de Guzman", "value": "C70749"},
                         {"label": "Romero de Torres", "value": "C70750"},
                         {"label": "Sahuayo", "value": "C70751"},
                         {"label": "Sahuayo de Morelos", "value": "C70752"},
                         {"label": "Salguero", "value": "C70753"},
                         {"label": "San Agustin del Maiz", "value": "C70754"},
                         {"label": "San Agustin del Pulque", "value": "C70755"},
                         {"label": "San Andres Coru", "value": "C70756"},
                         {"label": "San Andres Zirondaro", "value": "C70757"},
                         {"label": "San Antonio", "value": "C70758"},
                         {"label": "San Antonio la Labor", "value": "C70759"},
                         {"label": "San Antonio Molinos", "value": "C70760"},
                         {"label": "San Antonio Tariacuri", "value": "C70761"},
                         {"label": "San Antonio Villalongin",
                          "value": "C70762"},
                         {"label": "San Bartolo Cuitareo", "value": "C70763"},
                         {"label": "San Benito", "value": "C70764"},
                         {"label": "San Bernabe de las Canteras",
                          "value": "C70765"},
                         {"label": "San Felipe de los Herreros",
                          "value": "C70766"},
                         {"label": "San Felipe los Alzati (Colonia Nueva)",
                          "value": "C70767"},
                         {"label": "San Francisco Periban", "value": "C70768"},
                         {"label": "San Gregorio", "value": "C70769"},
                         {"label": "San Isidro", "value": "C70770"},
                         {"label": "San Isidro Alta Huerta", "value": "C70771"},
                         {"label": "San Jeronimo Purenchecuaro",
                          "value": "C70772"},
                         {"label": "San Jose Cuaro", "value": "C70773"},
                         {"label": "San Jose de Gracia", "value": "C70774"},
                         {"label": "San Jose Huipana", "value": "C70775"},
                         {"label": "San Juan Benito Juarez (San Juan Tararameo)",
                          "value": "C70776"},
                         {"label": "San Juan De Los Platanos",
                          "value": "C70777"},
                         {"label": "San Juan de Vina", "value": "C70778"},
                         {"label": "San Juanito Itzicuaro", "value": "C70779"},
                         {"label": "San Lorenzo", "value": "C70780"},
                         {"label": "San Lorenzo Itzicuaro", "value": "C70781"},
                         {"label": "San Lorenzo Querendaro", "value": "C70782"},
                         {"label": "San Lucas", "value": "C70783"},
                         {"label": "San Lucas Huarirapeo (La Mesa de San Lucas)",
                          "value": "C70784"},
                         {"label": "San Lucas Pio", "value": "C70785"},
                         {"label": "San Martin Totolan", "value": "C70786"},
                         {"label": "San Miguel Epejan", "value": "C70787"},
                         {"label": "San Nicolas Obispo", "value": "C70788"},
                         {"label": "San Pedro de los Sauces",
                          "value": "C70789"},
                         {"label": "San Pedro Jacuaro", "value": "C70790"},
                         {"label": "San Rafael Tecario", "value": "C70791"},
                         {"label": "San Sebastian", "value": "C70792"},
                         {"label": "Santa Ana", "value": "C70793"},
                         {"label": "Santa Ana Amatlan", "value": "C70794"},
                         {"label": "Santa Ana Chapitiro", "value": "C70795"},
                         {"label": "Santa Ana de Guerrero (El Cascabel)",
                          "value": "C70796"},
                         {"label": "Santa Ana Maya", "value": "C70797"},
                         {"label": "Santa Ana Zirosto", "value": "C70798"},
                         {"label": "Santa Casilda", "value": "C70799"},
                         {"label": "Santa Catarina", "value": "C70800"},
                         {"label": "Santa Clara de Valladares",
                          "value": "C70801"},
                         {"label": "Santa Clara del Cobre", "value": "C70802"},
                         {"label": "Santa Clara del Tule", "value": "C70803"},
                         {"label": "Santa Elena", "value": "C70804"},
                         {"label": "Santa Fe de la Laguna", "value": "C70805"},
                         {"label": "Santa Fe del Rio", "value": "C70806"},
                         {"label": "Santa Juana", "value": "C70807"},
                         {"label": "Santa Maria de los angeles",
                          "value": "C70808"},
                         {"label": "Santa Maria Huiramangaro (San Juan Tumbio)",
                          "value": "C70809"},
                         {"label": "Santa Maria Urapicho", "value": "C70810"},
                         {"label": "Santa Rosa (Santa Barbara)",
                          "value": "C70811"},
                         {"label": "Santiago Azajo", "value": "C70812"},
                         {"label": "Santiago Puriatzicuaro", "value": "C70813"},
                         {"label": "Santiago Tangamandapio", "value": "C70814"},
                         {"label": "Santiago Undameo", "value": "C70815"},
                         {"label": "Senguio", "value": "C70816"},
                         {"label": "Sevina", "value": "C70817"},
                         {"label": "Susupuato de Guerrero", "value": "C70818"},
                         {"label": "Tacambaro", "value": "C70819"},
                         {"label": "Tacambaro de Codallos", "value": "C70820"},
                         {"label": "Tacatzcuaro", "value": "C70821"},
                         {"label": "Tacicuaro", "value": "C70822"},
                         {"label": "Tacuro (Santa Maria Tacuro)",
                          "value": "C70823"},
                         {"label": "Tafetan", "value": "C70824"},
                         {"label": "Tanaco", "value": "C70825"},
                         {"label": "Tancitaro", "value": "C70826"},
                         {"label": "Tangancicuaro", "value": "C70827"},
                         {"label": "Tanhuato de Guerrero", "value": "C70828"},
                         {"label": "Tarejero", "value": "C70829"},
                         {"label": "Taretan", "value": "C70830"},
                         {"label": "Tarimbaro", "value": "C70831"},
                         {"label": "Tavera", "value": "C70832"},
                         {"label": "Tecario", "value": "C70833"},
                         {"label": "Tecomatan", "value": "C70834"},
                         {"label": "Tepalcatepec", "value": "C70835"},
                         {"label": "Tepuxtepec", "value": "C70836"},
                         {"label": "Tierras Coloradas (San Pedro)",
                          "value": "C70837"},
                         {"label": "Tingambato", "value": "C70838"},
                         {"label": "Tinguindin", "value": "C70839"},
                         {"label": "Tiquicheo", "value": "C70840"},
                         {"label": "Tiquicheo de Nicolas Romero",
                          "value": "C70841"},
                         {"label": "Tirindaro", "value": "C70842"},
                         {"label": "Tiripetio", "value": "C70843"},
                         {"label": "Tlalpujahua de Rayon", "value": "C70844"},
                         {"label": "Tocumbo", "value": "C70845"},
                         {"label": "Tomendan", "value": "C70846"},
                         {"label": "Toreo Bajo (El Toreo Bajo)",
                          "value": "C70847"},
                         {"label": "Tumbiscatio de Ruiz", "value": "C70848"},
                         {"label": "Tungareo", "value": "C70849"},
                         {"label": "Turicato", "value": "C70850"},
                         {"label": "Turicuaro", "value": "C70851"},
                         {"label": "Tuxpan", "value": "C70852"},
                         {"label": "Tuzantla", "value": "C70853"},
                         {"label": "Tzintzimeo", "value": "C70854"},
                         {"label": "Tzintzingareo", "value": "C70855"},
                         {"label": "Tzintzuntzan", "value": "C70856"},
                         {"label": "Tziritzicuaro", "value": "C70857"},
                         {"label": "Tzitzio", "value": "C70858"},
                         {"label": "Ucareo", "value": "C70859"},
                         {"label": "Uricho", "value": "C70860"},
                         {"label": "Uripitio", "value": "C70861"},
                         {"label": "Uruapan", "value": "C70862"},
                         {"label": "Uruetaro", "value": "C70863"},
                         {"label": "Uspero", "value": "C70864"},
                         {"label": "Valle Verde", "value": "C70865"},
                         {"label": "Venustiano Carranza", "value": "C70866"},
                         {"label": "Villa Jimenez", "value": "C70867"},
                         {"label": "Villa Madero", "value": "C70868"},
                         {"label": "Villa Magna", "value": "C70869"},
                         {"label": "Villa Victoria", "value": "C70870"},
                         {"label": "Villamar", "value": "C70871"},
                         {"label": "Villas de la Loma", "value": "C70872"},
                         {"label": "Vista Hermosa de Negrete",
                          "value": "C70873"},
                         {"label": "Yurecuaro", "value": "C70874"},
                         {"label": "Zacapu", "value": "C70875"},
                         {"label": "Zamora", "value": "C70876"},
                         {"label": "Zaragoza (Puerto de Medina)",
                          "value": "C70877"},
                         {"label": "Zicuicho", "value": "C70878"},
                         {"label": "Zicuiran", "value": "C70879"},
                         {"label": "Zinaparo", "value": "C70880"},
                         {"label": "Zinapecuaro", "value": "C70881"},
                         {"label": "Ziquitaro", "value": "C70882"},
                         {"label": "Ziracuaretiro", "value": "C70883"},
                         {"label": "Zirahuato de los Bernal",
                          "value": "C70884"},
                         {"label": "Zirahuen", "value": "C70885"},
                         {"label": "Zirimicuaro", "value": "C70886"},
                         {"label": "Zurumbeneo", "value": "C70887"}],
 "Micoud Quarter": [{"label": "Anse Ger", "value": "C106075"},
                    {"label": "Beauchamp", "value": "C106076"},
                    {"label": "Blanchard", "value": "C106077"},
                    {"label": "Canelles", "value": "C106078"},
                    {"label": "Cazuca", "value": "C106079"},
                    {"label": "ChiqueBlanchard", "value": "C106080"},
                    {"label": "Coolie Town", "value": "C106081"},
                    {"label": "Delomel", "value": "C106082"},
                    {"label": "Des Blanchard", "value": "C106083"},
                    {"label": "Desruisseaux", "value": "C106084"},
                    {"label": "Dugard", "value": "C106085"},
                    {"label": "Escap", "value": "C106086"},
                    {"label": "Fond DLorDugard", "value": "C106087"},
                    {"label": "Fond Estate", "value": "C106088"},
                    {"label": "FondDesruisseaux", "value": "C106089"},
                    {"label": "Gomier", "value": "C106090"},
                    {"label": "Gouette", "value": "C106091"},
                    {"label": "Honeymoon Beach", "value": "C106092"},
                    {"label": "LEau Mineau", "value": "C106093"},
                    {"label": "La Courville", "value": "C106094"},
                    {"label": "La Pointe", "value": "C106095"},
                    {"label": "La Tille", "value": "C106096"},
                    {"label": "Lezy", "value": "C106097"},
                    {"label": "Lombard", "value": "C106098"},
                    {"label": "Mahaut", "value": "C106099"},
                    {"label": "Malgretoute", "value": "C106100"},
                    {"label": "Mamiku", "value": "C106101"},
                    {"label": "Micoud", "value": "C106102"},
                    {"label": "Mon Repos", "value": "C106103"},
                    {"label": "Moreau", "value": "C106104"},
                    {"label": "Morne Vient", "value": "C106105"},
                    {"label": "Myette Gardens", "value": "C106106"},
                    {"label": "New Village", "value": "C106107"},
                    {"label": "Paix Bouche", "value": "C106108"},
                    {"label": "Palmiste Estate", "value": "C106109"},
                    {"label": "Patience", "value": "C106110"},
                    {"label": "Planard", "value": "C106111"},
                    {"label": "Praslin", "value": "C106112"},
                    {"label": "Rameau", "value": "C106113"},
                    {"label": "Saint Marie Road", "value": "C106114"},
                    {"label": "Saut", "value": "C106115"},
                    {"label": "Savannes", "value": "C106116"},
                    {"label": "St Helen Estate", "value": "C106117"},
                    {"label": "Ti Riviere", "value": "C106118"},
                    {"label": "Ti Rocher", "value": "C106119"},
                    {"label": "Troumassee", "value": "C106120"},
                    {"label": "Village", "value": "C106121"},
                    {"label": "Volet", "value": "C106122"}],
 "Mid-Western Region": [{"label": "Bhojpur", "value": "C76057"},
                        {"label": "Birendranagar", "value": "C76058"},
                        {"label": "Dailekh", "value": "C76059"},
                        {"label": "Gulariya", "value": "C76060"},
                        {"label": "Jumla", "value": "C76061"},
                        {"label": "Nepalgunj", "value": "C76062"},
                        {"label": "Tulsipur", "value": "C76063"}],
 "Middle Juba": [{"label": "Dujuuma", "value": "C107910"},
                 {"label": "Jilib", "value": "C107911"},
                 {"label": "Saacow", "value": "C107912"}],
 "Middle Shebelle": [{"label": "Cadale", "value": "C107913"},
                     {"label": "Jawhar", "value": "C107914"},
                     {"label": "Mahaddayweyne", "value": "C107915"}],
 "Midlands Province": [{"label": "Gokwe", "value": "C148254"},
                       {"label": "Gweru", "value": "C148255"},
                       {"label": "Gweru District", "value": "C148256"},
                       {"label": "Kwekwe", "value": "C148257"},
                       {"label": "Kwekwe District", "value": "C148258"},
                       {"label": "Lalapanzi", "value": "C148259"},
                       {"label": "Mberengwa District", "value": "C148260"},
                       {"label": "Mvuma", "value": "C148261"},
                       {"label": "Redcliff", "value": "C148262"},
                       {"label": "Shangani", "value": "C148263"},
                       {"label": "Shurugwi", "value": "C148264"},
                       {"label": "Shurugwi District", "value": "C148265"},
                       {"label": "Zvishavane District", "value": "C148266"}],
 "Mie Prefecture": [{"label": "Hisai-motomachi", "value": "C63446"},
                    {"label": "Iga-shi", "value": "C63447"},
                    {"label": "Inabe", "value": "C63448"},
                    {"label": "Inabe-shi", "value": "C63449"},
                    {"label": "Ise", "value": "C63450"},
                    {"label": "Ise-shi", "value": "C63451"},
                    {"label": "Kameyama", "value": "C63452"},
                    {"label": "Kameyama Shi", "value": "C63453"},
                    {"label": "Kawage", "value": "C63454"},
                    {"label": "Komono", "value": "C63455"},
                    {"label": "Kumano", "value": "C63456"},
                    {"label": "Kumano-shi", "value": "C63457"},
                    {"label": "Kuwana", "value": "C63458"},
                    {"label": "Kuwana-shi", "value": "C63459"},
                    {"label": "Matsuzaka-shi", "value": "C63460"},
                    {"label": "Nabari", "value": "C63461"},
                    {"label": "Nabari-shi", "value": "C63462"},
                    {"label": "Owase", "value": "C63463"},
                    {"label": "Shima", "value": "C63464"},
                    {"label": "Shima-shi", "value": "C63465"},
                    {"label": "Suzuka", "value": "C63466"},
                    {"label": "Suzuka-shi", "value": "C63467"},
                    {"label": "Toba", "value": "C63468"},
                    {"label": "Toba-shi", "value": "C63469"},
                    {"label": "Tsu", "value": "C63470"},
                    {"label": "Tsu-shi", "value": "C63471"},
                    {"label": "Ueno-ebisumachi", "value": "C63472"},
                    {"label": "Yokkaichi-shi", "value": "C63473"}],
 "Migori": [{"label": "Kihancha", "value": "C64608"},
            {"label": "Migori", "value": "C64609"}],
 "Miklavz na Dravskem Polju Municipality": [{"label": "Miklavz na Dravskem Polju",
                                             "value": "C107724"}],
 "Mila": [{"label": "Chelghoum el Aid", "value": "C365"},
          {"label": "Mila", "value": "C366"},
          {"label": "Rouached", "value": "C367"},
          {"label": "Sidi Merouane", "value": "C368"},
          {"label": "Telerghma", "value": "C369"}],
 "Milne Bay Province": [{"label": "Alotau", "value": "C80437"},
                        {"label": "Esaala", "value": "C80438"},
                        {"label": "Kiriwina Goodenough", "value": "C80439"},
                        {"label": "Samarai", "value": "C80440"},
                        {"label": "Samarai Murua", "value": "C80441"}],
 "Minas Gerais": [{"label": "Abadia dos Dourados", "value": "C11778"},
                  {"label": "Abaete", "value": "C11779"},
                  {"label": "Abre Campo", "value": "C11780"},
                  {"label": "Acaiaca", "value": "C11781"},
                  {"label": "Acucena", "value": "C11782"},
                  {"label": "agua Boa", "value": "C11783"},
                  {"label": "agua Comprida", "value": "C11784"},
                  {"label": "Aguanil", "value": "C11785"},
                  {"label": "aguas Formosas", "value": "C11786"},
                  {"label": "aguas Vermelhas", "value": "C11787"},
                  {"label": "Aimores", "value": "C11788"},
                  {"label": "Aiuruoca", "value": "C11789"},
                  {"label": "Alagoa", "value": "C11790"},
                  {"label": "Albertina", "value": "C11791"},
                  {"label": "Alem Paraiba", "value": "C11792"},
                  {"label": "Alfenas", "value": "C11793"},
                  {"label": "Alfredo Vasconcelos", "value": "C11794"},
                  {"label": "Almenara", "value": "C11795"},
                  {"label": "Alpercata", "value": "C11796"},
                  {"label": "Alpinopolis", "value": "C11797"},
                  {"label": "Alterosa", "value": "C11798"},
                  {"label": "Alto Caparao", "value": "C11799"},
                  {"label": "Alto Jequitiba", "value": "C11800"},
                  {"label": "Alto Rio Doce", "value": "C11801"},
                  {"label": "Alvarenga", "value": "C11802"},
                  {"label": "Alvinopolis", "value": "C11803"},
                  {"label": "Alvorada de Minas", "value": "C11804"},
                  {"label": "Amparo da Serra", "value": "C11805"},
                  {"label": "Andradas", "value": "C11806"},
                  {"label": "Andrelandia", "value": "C11807"},
                  {"label": "Angelandia", "value": "C11808"},
                  {"label": "Antonio Carlos", "value": "C11809"},
                  {"label": "Antonio Dias", "value": "C11810"},
                  {"label": "Antonio Prado de Minas", "value": "C11811"},
                  {"label": "Aracai", "value": "C11812"},
                  {"label": "Aracitaba", "value": "C11813"},
                  {"label": "Aracuai", "value": "C11814"},
                  {"label": "Araguari", "value": "C11815"},
                  {"label": "Arantina", "value": "C11816"},
                  {"label": "Araponga", "value": "C11817"},
                  {"label": "Arapora", "value": "C11818"},
                  {"label": "Arapua", "value": "C11819"},
                  {"label": "Araujos", "value": "C11820"},
                  {"label": "Araxa", "value": "C11821"},
                  {"label": "Arceburgo", "value": "C11822"},
                  {"label": "Arcos", "value": "C11823"},
                  {"label": "Areado", "value": "C11824"},
                  {"label": "Argirita", "value": "C11825"},
                  {"label": "Aricanduva", "value": "C11826"},
                  {"label": "Arinos", "value": "C11827"},
                  {"label": "Astolfo Dutra", "value": "C11828"},
                  {"label": "Ataleia", "value": "C11829"},
                  {"label": "Augusto de Lima", "value": "C11830"},
                  {"label": "Baependi", "value": "C11831"},
                  {"label": "Baldim", "value": "C11832"},
                  {"label": "Bambui", "value": "C11833"},
                  {"label": "Bandeira", "value": "C11834"},
                  {"label": "Bandeira do Sul", "value": "C11835"},
                  {"label": "Barao de Cocais", "value": "C11836"},
                  {"label": "Barao de Monte Alto", "value": "C11837"},
                  {"label": "Barbacena", "value": "C11838"},
                  {"label": "Barra Longa", "value": "C11839"},
                  {"label": "Barreiro do Jaiba", "value": "C11840"},
                  {"label": "Barroso", "value": "C11841"},
                  {"label": "Bela Vista de Minas", "value": "C11842"},
                  {"label": "Belmiro Braga", "value": "C11843"},
                  {"label": "Belo Horizonte", "value": "C11844"},
                  {"label": "Belo Oriente", "value": "C11845"},
                  {"label": "Belo Vale", "value": "C11846"},
                  {"label": "Berilo", "value": "C11847"},
                  {"label": "Berizal", "value": "C11848"},
                  {"label": "Bertopolis", "value": "C11849"},
                  {"label": "Betim", "value": "C11850"},
                  {"label": "Bias Fortes", "value": "C11851"},
                  {"label": "Bicas", "value": "C11852"},
                  {"label": "Biquinhas", "value": "C11853"},
                  {"label": "Boa Esperanca", "value": "C11854"},
                  {"label": "Bocaina de Minas", "value": "C11855"},
                  {"label": "Bocaiuva", "value": "C11856"},
                  {"label": "Bom Despacho", "value": "C11857"},
                  {"label": "Bom Jardim de Minas", "value": "C11858"},
                  {"label": "Bom Jesus da Penha", "value": "C11859"},
                  {"label": "Bom Jesus do Amparo", "value": "C11860"},
                  {"label": "Bom Jesus do Galho", "value": "C11861"},
                  {"label": "Bom Repouso", "value": "C11862"},
                  {"label": "Bom Sucesso", "value": "C11863"},
                  {"label": "Bonfim", "value": "C11864"},
                  {"label": "Bonfinopolis de Minas", "value": "C11865"},
                  {"label": "Bonito de Minas", "value": "C11866"},
                  {"label": "Borda da Mata", "value": "C11867"},
                  {"label": "Botelhos", "value": "C11868"},
                  {"label": "Botumirim", "value": "C11869"},
                  {"label": "Bras Pires", "value": "C11870"},
                  {"label": "Brasilandia de Minas", "value": "C11871"},
                  {"label": "Brasilia de Minas", "value": "C11872"},
                  {"label": "Braunas", "value": "C11873"},
                  {"label": "Brazopolis", "value": "C11874"},
                  {"label": "Brumadinho", "value": "C11875"},
                  {"label": "Bueno Brandao", "value": "C11876"},
                  {"label": "Buenopolis", "value": "C11877"},
                  {"label": "Bugre", "value": "C11878"},
                  {"label": "Buritis", "value": "C11879"},
                  {"label": "Buritizeiro", "value": "C11880"},
                  {"label": "Cabeceira Grande", "value": "C11881"},
                  {"label": "Cabo Verde", "value": "C11882"},
                  {"label": "Cachoeira da Prata", "value": "C11883"},
                  {"label": "Cachoeira de Minas", "value": "C11884"},
                  {"label": "Cachoeira de Pajeu", "value": "C11885"},
                  {"label": "Cachoeira Dourada", "value": "C11886"},
                  {"label": "Caetanopolis", "value": "C11887"},
                  {"label": "Caete", "value": "C11888"},
                  {"label": "Caiana", "value": "C11889"},
                  {"label": "Cajuri", "value": "C11890"},
                  {"label": "Caldas", "value": "C11891"},
                  {"label": "Camacho", "value": "C11892"},
                  {"label": "Camanducaia", "value": "C11893"},
                  {"label": "Cambui", "value": "C11894"},
                  {"label": "Cambuquira", "value": "C11895"},
                  {"label": "Campanario", "value": "C11896"},
                  {"label": "Campanha", "value": "C11897"},
                  {"label": "Campestre", "value": "C11898"},
                  {"label": "Campina Verde", "value": "C11899"},
                  {"label": "Campo Azul", "value": "C11900"},
                  {"label": "Campo Belo", "value": "C11901"},
                  {"label": "Campo do Meio", "value": "C11902"},
                  {"label": "Campo Florido", "value": "C11903"},
                  {"label": "Campos Altos", "value": "C11904"},
                  {"label": "Campos Gerais", "value": "C11905"},
                  {"label": "Cana Verde", "value": "C11906"},
                  {"label": "Canaa", "value": "C11907"},
                  {"label": "Canapolis", "value": "C11908"},
                  {"label": "Candeias", "value": "C11909"},
                  {"label": "Cantagalo", "value": "C11910"},
                  {"label": "Caparao", "value": "C11911"},
                  {"label": "Capela Nova", "value": "C11912"},
                  {"label": "Capelinha", "value": "C11913"},
                  {"label": "Capetinga", "value": "C11914"},
                  {"label": "Capim Branco", "value": "C11915"},
                  {"label": "Capinopolis", "value": "C11916"},
                  {"label": "Capitao Andrade", "value": "C11917"},
                  {"label": "Capitao Eneas", "value": "C11918"},
                  {"label": "Capitolio", "value": "C11919"},
                  {"label": "Caputira", "value": "C11920"},
                  {"label": "Carai", "value": "C11921"},
                  {"label": "Caranaiba", "value": "C11922"},
                  {"label": "Carandai", "value": "C11923"},
                  {"label": "Carangola", "value": "C11924"},
                  {"label": "Caratinga", "value": "C11925"},
                  {"label": "Carbonita", "value": "C11926"},
                  {"label": "Careacu", "value": "C11927"},
                  {"label": "Carlos Chagas", "value": "C11928"},
                  {"label": "Carmesia", "value": "C11929"},
                  {"label": "Carmo da Cachoeira", "value": "C11930"},
                  {"label": "Carmo da Mata", "value": "C11931"},
                  {"label": "Carmo de Minas", "value": "C11932"},
                  {"label": "Carmo do Cajuru", "value": "C11933"},
                  {"label": "Carmo do Paranaiba", "value": "C11934"},
                  {"label": "Carmo do Rio Claro", "value": "C11935"},
                  {"label": "Carmopolis de Minas", "value": "C11936"},
                  {"label": "Carneirinho", "value": "C11937"},
                  {"label": "Carrancas", "value": "C11938"},
                  {"label": "Carvalhopolis", "value": "C11939"},
                  {"label": "Carvalhos", "value": "C11940"},
                  {"label": "Casa Grande", "value": "C11941"},
                  {"label": "Cascalho Rico", "value": "C11942"},
                  {"label": "Cassia", "value": "C11943"},
                  {"label": "Cataguases", "value": "C11944"},
                  {"label": "Catas Altas", "value": "C11945"},
                  {"label": "Catas Altas da Noruega", "value": "C11946"},
                  {"label": "Catuji", "value": "C11947"},
                  {"label": "Catuti", "value": "C11948"},
                  {"label": "Caxambu", "value": "C11949"},
                  {"label": "Cedro do Abaete", "value": "C11950"},
                  {"label": "Central de Minas", "value": "C11951"},
                  {"label": "Centralina", "value": "C11952"},
                  {"label": "Chacara", "value": "C11953"},
                  {"label": "Chale", "value": "C11954"},
                  {"label": "Chapada do Norte", "value": "C11955"},
                  {"label": "Chapada Gaucha", "value": "C11956"},
                  {"label": "Chiador", "value": "C11957"},
                  {"label": "Cipotanea", "value": "C11958"},
                  {"label": "Claraval", "value": "C11959"},
                  {"label": "Claro dos Pocoes", "value": "C11960"},
                  {"label": "Claudio", "value": "C11961"},
                  {"label": "Coimbra", "value": "C11962"},
                  {"label": "Coluna", "value": "C11963"},
                  {"label": "Comendador Gomes", "value": "C11964"},
                  {"label": "Comercinho", "value": "C11965"},
                  {"label": "Conceicao da Aparecida", "value": "C11966"},
                  {"label": "Conceicao da Barra de Minas", "value": "C11967"},
                  {"label": "Conceicao das Alagoas", "value": "C11968"},
                  {"label": "Conceicao das Pedras", "value": "C11969"},
                  {"label": "Conceicao de Ipanema", "value": "C11970"},
                  {"label": "Conceicao do Mato Dentro", "value": "C11971"},
                  {"label": "Conceicao do Para", "value": "C11972"},
                  {"label": "Conceicao do Rio Verde", "value": "C11973"},
                  {"label": "Conceicao dos Ouros", "value": "C11974"},
                  {"label": "Conego Marinho", "value": "C11975"},
                  {"label": "Confins", "value": "C11976"},
                  {"label": "Congonhal", "value": "C11977"},
                  {"label": "Congonhas", "value": "C11978"},
                  {"label": "Congonhas do Norte", "value": "C11979"},
                  {"label": "Conquista", "value": "C11980"},
                  {"label": "Conselheiro Lafaiete", "value": "C11981"},
                  {"label": "Conselheiro Pena", "value": "C11982"},
                  {"label": "Consolacao", "value": "C11983"},
                  {"label": "Contagem", "value": "C11984"},
                  {"label": "Coqueiral", "value": "C11985"},
                  {"label": "Coracao de Jesus", "value": "C11986"},
                  {"label": "Cordisburgo", "value": "C11987"},
                  {"label": "Cordislandia", "value": "C11988"},
                  {"label": "Corinto", "value": "C11989"},
                  {"label": "Coroaci", "value": "C11990"},
                  {"label": "Coromandel", "value": "C11991"},
                  {"label": "Coronel Fabriciano", "value": "C11992"},
                  {"label": "Coronel Murta", "value": "C11993"},
                  {"label": "Coronel Pacheco", "value": "C11994"},
                  {"label": "Coronel Xavier Chaves", "value": "C11995"},
                  {"label": "Corrego Danta", "value": "C11996"},
                  {"label": "Corrego do Bom Jesus", "value": "C11997"},
                  {"label": "Corrego Fundo", "value": "C11998"},
                  {"label": "Corrego Novo", "value": "C11999"},
                  {"label": "Couto de Magalhaes de Minas", "value": "C12000"},
                  {"label": "Crisolita", "value": "C12001"},
                  {"label": "Cristais", "value": "C12002"},
                  {"label": "Cristalia", "value": "C12003"},
                  {"label": "Cristiano Otoni", "value": "C12004"},
                  {"label": "Cristina", "value": "C12005"},
                  {"label": "Crucilandia", "value": "C12006"},
                  {"label": "Cruzeiro da Fortaleza", "value": "C12007"},
                  {"label": "Cruzilia", "value": "C12008"},
                  {"label": "Cuparaque", "value": "C12009"},
                  {"label": "Curral de Dentro", "value": "C12010"},
                  {"label": "Curvelo", "value": "C12011"},
                  {"label": "Datas", "value": "C12012"},
                  {"label": "Delfim Moreira", "value": "C12013"},
                  {"label": "Delfinopolis", "value": "C12014"},
                  {"label": "Delta", "value": "C12015"},
                  {"label": "Descoberto", "value": "C12016"},
                  {"label": "Desterro de Entre Rios", "value": "C12017"},
                  {"label": "Desterro do Melo", "value": "C12018"},
                  {"label": "Diamantina", "value": "C12019"},
                  {"label": "Diogo de Vasconcelos", "value": "C12020"},
                  {"label": "Dionisio", "value": "C12021"},
                  {"label": "Divinesia", "value": "C12022"},
                  {"label": "Divino", "value": "C12023"},
                  {"label": "Divino das Laranjeiras", "value": "C12024"},
                  {"label": "Divinolandia de Minas", "value": "C12025"},
                  {"label": "Divinopolis", "value": "C12026"},
                  {"label": "Divisa Alegre", "value": "C12027"},
                  {"label": "Divisa Nova", "value": "C12028"},
                  {"label": "Divisopolis", "value": "C12029"},
                  {"label": "Dom Bosco", "value": "C12030"},
                  {"label": "Dom Cavati", "value": "C12031"},
                  {"label": "Dom Joaquim", "value": "C12032"},
                  {"label": "Dom Silverio", "value": "C12033"},
                  {"label": "Dom Vicoso", "value": "C12034"},
                  {"label": "Dona Eusebia", "value": "C12035"},
                  {"label": "Dores de Campos", "value": "C12036"},
                  {"label": "Dores de Guanhaes", "value": "C12037"},
                  {"label": "Dores do Indaia", "value": "C12038"},
                  {"label": "Dores do Turvo", "value": "C12039"},
                  {"label": "Doresopolis", "value": "C12040"},
                  {"label": "Douradoquara", "value": "C12041"},
                  {"label": "Durande", "value": "C12042"},
                  {"label": "Eloi Mendes", "value": "C12043"},
                  {"label": "Engenheiro Caldas", "value": "C12044"},
                  {"label": "Engenheiro Navarro", "value": "C12045"},
                  {"label": "Entre Folhas", "value": "C12046"},
                  {"label": "Entre Rios de Minas", "value": "C12047"},
                  {"label": "Ervalia", "value": "C12048"},
                  {"label": "Esmeraldas", "value": "C12049"},
                  {"label": "Espera Feliz", "value": "C12050"},
                  {"label": "Espinosa", "value": "C12051"},
                  {"label": "Espirito Santo do Dourado", "value": "C12052"},
                  {"label": "Estiva", "value": "C12053"},
                  {"label": "Estrela Dalva", "value": "C12054"},
                  {"label": "Estrela do Indaia", "value": "C12055"},
                  {"label": "Estrela do Sul", "value": "C12056"},
                  {"label": "Eugenopolis", "value": "C12057"},
                  {"label": "Ewbank da Camara", "value": "C12058"},
                  {"label": "Extrema", "value": "C12059"},
                  {"label": "Fama", "value": "C12060"},
                  {"label": "Faria Lemos", "value": "C12061"},
                  {"label": "Felicio dos Santos", "value": "C12062"},
                  {"label": "Felisburgo", "value": "C12063"},
                  {"label": "Felixlandia", "value": "C12064"},
                  {"label": "Fernandes Tourinho", "value": "C12065"},
                  {"label": "Ferros", "value": "C12066"},
                  {"label": "Fervedouro", "value": "C12067"},
                  {"label": "Florestal", "value": "C12068"},
                  {"label": "Formiga", "value": "C12069"},
                  {"label": "Formoso", "value": "C12070"},
                  {"label": "Fortaleza de Minas", "value": "C12071"},
                  {"label": "Fortuna de Minas", "value": "C12072"},
                  {"label": "Francisco Badaro", "value": "C12073"},
                  {"label": "Francisco Dumont", "value": "C12074"},
                  {"label": "Francisco Sa", "value": "C12075"},
                  {"label": "Franciscopolis", "value": "C12076"},
                  {"label": "Frei Gaspar", "value": "C12077"},
                  {"label": "Frei Inocencio", "value": "C12078"},
                  {"label": "Frei Lagonegro", "value": "C12079"},
                  {"label": "Fronteira", "value": "C12080"},
                  {"label": "Fronteira dos Vales", "value": "C12081"},
                  {"label": "Fruta de Leite", "value": "C12082"},
                  {"label": "Frutal", "value": "C12083"},
                  {"label": "Funilandia", "value": "C12084"},
                  {"label": "Galileia", "value": "C12085"},
                  {"label": "Gameleiras", "value": "C12086"},
                  {"label": "Glaucilandia", "value": "C12087"},
                  {"label": "Goiabeira", "value": "C12088"},
                  {"label": "Goiana", "value": "C12089"},
                  {"label": "Goncalves", "value": "C12090"},
                  {"label": "Gonzaga", "value": "C12091"},
                  {"label": "Gouveia", "value": "C12092"},
                  {"label": "Governador Valadares", "value": "C12093"},
                  {"label": "Grao Mogol", "value": "C12094"},
                  {"label": "Grupiara", "value": "C12095"},
                  {"label": "Guanhaes", "value": "C12096"},
                  {"label": "Guape", "value": "C12097"},
                  {"label": "Guaraciaba", "value": "C12098"},
                  {"label": "Guaraciama", "value": "C12099"},
                  {"label": "Guaranesia", "value": "C12100"},
                  {"label": "Guarani", "value": "C12101"},
                  {"label": "Guarara", "value": "C12102"},
                  {"label": "Guarda-Mor", "value": "C12103"},
                  {"label": "Guaxupe", "value": "C12104"},
                  {"label": "Guidoval", "value": "C12105"},
                  {"label": "Guimarania", "value": "C12106"},
                  {"label": "Guiricema", "value": "C12107"},
                  {"label": "Gurinhata", "value": "C12108"},
                  {"label": "Heliodora", "value": "C12109"},
                  {"label": "Iapu", "value": "C12110"},
                  {"label": "Ibertioga", "value": "C12111"},
                  {"label": "Ibia", "value": "C12112"},
                  {"label": "Ibiai", "value": "C12113"},
                  {"label": "Ibiracatu", "value": "C12114"},
                  {"label": "Ibiraci", "value": "C12115"},
                  {"label": "Ibirite", "value": "C12116"},
                  {"label": "Ibitiura de Minas", "value": "C12117"},
                  {"label": "Ibituruna", "value": "C12118"},
                  {"label": "Icarai de Minas", "value": "C12119"},
                  {"label": "Igarape", "value": "C12120"},
                  {"label": "Igaratinga", "value": "C12121"},
                  {"label": "Iguatama", "value": "C12122"},
                  {"label": "Ijaci", "value": "C12123"},
                  {"label": "Ilicinea", "value": "C12124"},
                  {"label": "Imbe de Minas", "value": "C12125"},
                  {"label": "Inconfidentes", "value": "C12126"},
                  {"label": "Indaiabira", "value": "C12127"},
                  {"label": "Indianopolis", "value": "C12128"},
                  {"label": "Ingai", "value": "C12129"},
                  {"label": "Inhapim", "value": "C12130"},
                  {"label": "Inhauma", "value": "C12131"},
                  {"label": "Inimutaba", "value": "C12132"},
                  {"label": "Ipaba", "value": "C12133"},
                  {"label": "Ipanema", "value": "C12134"},
                  {"label": "Ipatinga", "value": "C12135"},
                  {"label": "Ipiacu", "value": "C12136"},
                  {"label": "Ipuiuna", "value": "C12137"},
                  {"label": "Irai de Minas", "value": "C12138"},
                  {"label": "Itabira", "value": "C12139"},
                  {"label": "Itabirinha", "value": "C12140"},
                  {"label": "Itabirito", "value": "C12141"},
                  {"label": "Itacambira", "value": "C12142"},
                  {"label": "Itacarambi", "value": "C12143"},
                  {"label": "Itaguara", "value": "C12144"},
                  {"label": "Itaipe", "value": "C12145"},
                  {"label": "Itajuba", "value": "C12146"},
                  {"label": "Itamarandiba", "value": "C12147"},
                  {"label": "Itamarati de Minas", "value": "C12148"},
                  {"label": "Itambacuri", "value": "C12149"},
                  {"label": "Itambe do Mato Dentro", "value": "C12150"},
                  {"label": "Itamogi", "value": "C12151"},
                  {"label": "Itamonte", "value": "C12152"},
                  {"label": "Itanhandu", "value": "C12153"},
                  {"label": "Itanhomi", "value": "C12154"},
                  {"label": "Itaobim", "value": "C12155"},
                  {"label": "Itapagipe", "value": "C12156"},
                  {"label": "Itapecerica", "value": "C12157"},
                  {"label": "Itapeva", "value": "C12158"},
                  {"label": "Itatiaiucu", "value": "C12159"},
                  {"label": "Itau de Minas", "value": "C12160"},
                  {"label": "Itauna", "value": "C12161"},
                  {"label": "Itaverava", "value": "C12162"},
                  {"label": "Itinga", "value": "C12163"},
                  {"label": "Itueta", "value": "C12164"},
                  {"label": "Ituiutaba", "value": "C12165"},
                  {"label": "Itumirim", "value": "C12166"},
                  {"label": "Iturama", "value": "C12167"},
                  {"label": "Itutinga", "value": "C12168"},
                  {"label": "Jaboticatubas", "value": "C12169"},
                  {"label": "Jacinto", "value": "C12170"},
                  {"label": "Jacui", "value": "C12171"},
                  {"label": "Jacutinga", "value": "C12172"},
                  {"label": "Jaguaracu", "value": "C12173"},
                  {"label": "Jaiba", "value": "C12174"},
                  {"label": "Jampruca", "value": "C12175"},
                  {"label": "Janauba", "value": "C12176"},
                  {"label": "Januaria", "value": "C12177"},
                  {"label": "Japaraiba", "value": "C12178"},
                  {"label": "Japonvar", "value": "C12179"},
                  {"label": "Jeceaba", "value": "C12180"},
                  {"label": "Jenipapo de Minas", "value": "C12181"},
                  {"label": "Jequeri", "value": "C12182"},
                  {"label": "Jequitai", "value": "C12183"},
                  {"label": "Jequitiba", "value": "C12184"},
                  {"label": "Jequitinhonha", "value": "C12185"},
                  {"label": "Jesuania", "value": "C12186"},
                  {"label": "Joaima", "value": "C12187"},
                  {"label": "Joanesia", "value": "C12188"},
                  {"label": "Joao Monlevade", "value": "C12189"},
                  {"label": "Joao Pinheiro", "value": "C12190"},
                  {"label": "Joaquim Felicio", "value": "C12191"},
                  {"label": "Jordania", "value": "C12192"},
                  {"label": "Jose Goncalves de Minas", "value": "C12193"},
                  {"label": "Jose Raydan", "value": "C12194"},
                  {"label": "Josenopolis", "value": "C12195"},
                  {"label": "Juatuba", "value": "C12196"},
                  {"label": "Juiz de Fora", "value": "C12197"},
                  {"label": "Juramento", "value": "C12198"},
                  {"label": "Juruaia", "value": "C12199"},
                  {"label": "Juvenilia", "value": "C12200"},
                  {"label": "Ladainha", "value": "C12201"},
                  {"label": "Lagamar", "value": "C12202"},
                  {"label": "Lagoa da Prata", "value": "C12203"},
                  {"label": "Lagoa dos Patos", "value": "C12204"},
                  {"label": "Lagoa Dourada", "value": "C12205"},
                  {"label": "Lagoa Formosa", "value": "C12206"},
                  {"label": "Lagoa Grande", "value": "C12207"},
                  {"label": "Lagoa Santa", "value": "C12208"},
                  {"label": "Lajinha", "value": "C12209"},
                  {"label": "Lambari", "value": "C12210"},
                  {"label": "Lamim", "value": "C12211"},
                  {"label": "Laranjal", "value": "C12212"},
                  {"label": "Lassance", "value": "C12213"},
                  {"label": "Lavras", "value": "C12214"},
                  {"label": "Leandro Ferreira", "value": "C12215"},
                  {"label": "Leme do Prado", "value": "C12216"},
                  {"label": "Leopoldina", "value": "C12217"},
                  {"label": "Liberdade", "value": "C12218"},
                  {"label": "Lima Duarte", "value": "C12219"},
                  {"label": "Limeira do Oeste", "value": "C12220"},
                  {"label": "Lontra", "value": "C12221"},
                  {"label": "Luisburgo", "value": "C12222"},
                  {"label": "Luislandia", "value": "C12223"},
                  {"label": "Luminarias", "value": "C12224"},
                  {"label": "Luz", "value": "C12225"},
                  {"label": "Machacalis", "value": "C12226"},
                  {"label": "Machado", "value": "C12227"},
                  {"label": "Madre de Deus de Minas", "value": "C12228"},
                  {"label": "Malacacheta", "value": "C12229"},
                  {"label": "Mamonas", "value": "C12230"},
                  {"label": "Manga", "value": "C12231"},
                  {"label": "Manhuacu", "value": "C12232"},
                  {"label": "Manhumirim", "value": "C12233"},
                  {"label": "Mantena", "value": "C12234"},
                  {"label": "Mar de Espanha", "value": "C12235"},
                  {"label": "Maravilhas", "value": "C12236"},
                  {"label": "Maria da Fe", "value": "C12237"},
                  {"label": "Mariana", "value": "C12238"},
                  {"label": "Marilac", "value": "C12239"},
                  {"label": "Mario Campos", "value": "C12240"},
                  {"label": "Maripa de Minas", "value": "C12241"},
                  {"label": "Marlieria", "value": "C12242"},
                  {"label": "Marmelopolis", "value": "C12243"},
                  {"label": "Martinho Campos", "value": "C12244"},
                  {"label": "Martins Soares", "value": "C12245"},
                  {"label": "Mata Verde", "value": "C12246"},
                  {"label": "Materlandia", "value": "C12247"},
                  {"label": "Mateus Leme", "value": "C12248"},
                  {"label": "Mathias Lobato", "value": "C12249"},
                  {"label": "Matias Barbosa", "value": "C12250"},
                  {"label": "Matias Cardoso", "value": "C12251"},
                  {"label": "Matipo", "value": "C12252"},
                  {"label": "Mato Verde", "value": "C12253"},
                  {"label": "Matozinhos", "value": "C12254"},
                  {"label": "Matutina", "value": "C12255"},
                  {"label": "Medeiros", "value": "C12256"},
                  {"label": "Medina", "value": "C12257"},
                  {"label": "Mendes Pimentel", "value": "C12258"},
                  {"label": "Merces", "value": "C12259"},
                  {"label": "Mesquita", "value": "C12260"},
                  {"label": "Minas Novas", "value": "C12261"},
                  {"label": "Minduri", "value": "C12262"},
                  {"label": "Mirabela", "value": "C12263"},
                  {"label": "Miradouro", "value": "C12264"},
                  {"label": "Mirai", "value": "C12265"},
                  {"label": "Miravania", "value": "C12266"},
                  {"label": "Moeda", "value": "C12267"},
                  {"label": "Moema", "value": "C12268"},
                  {"label": "Monjolos", "value": "C12269"},
                  {"label": "Monsenhor Paulo", "value": "C12270"},
                  {"label": "Montalvania", "value": "C12271"},
                  {"label": "Monte Alegre de Minas", "value": "C12272"},
                  {"label": "Monte Azul", "value": "C12273"},
                  {"label": "Monte Belo", "value": "C12274"},
                  {"label": "Monte Carmelo", "value": "C12275"},
                  {"label": "Monte Formoso", "value": "C12276"},
                  {"label": "Monte Santo de Minas", "value": "C12277"},
                  {"label": "Monte Siao", "value": "C12278"},
                  {"label": "Monte Verde", "value": "C12279"},
                  {"label": "Montes Claros", "value": "C12280"},
                  {"label": "Montezuma", "value": "C12281"},
                  {"label": "Morada Nova de Minas", "value": "C12282"},
                  {"label": "Morro da Garca", "value": "C12283"},
                  {"label": "Morro do Pilar", "value": "C12284"},
                  {"label": "Munhoz", "value": "C12285"},
                  {"label": "Muriae", "value": "C12286"},
                  {"label": "Mutum", "value": "C12287"},
                  {"label": "Muzambinho", "value": "C12288"},
                  {"label": "Nacip Raydan", "value": "C12289"},
                  {"label": "Nanuque", "value": "C12290"},
                  {"label": "Naque", "value": "C12291"},
                  {"label": "Natalandia", "value": "C12292"},
                  {"label": "Natercia", "value": "C12293"},
                  {"label": "Nazareno", "value": "C12294"},
                  {"label": "Nepomuceno", "value": "C12295"},
                  {"label": "Ninheira", "value": "C12296"},
                  {"label": "Nova Belem", "value": "C12297"},
                  {"label": "Nova Era", "value": "C12298"},
                  {"label": "Nova Lima", "value": "C12299"},
                  {"label": "Nova Modica", "value": "C12300"},
                  {"label": "Nova Ponte", "value": "C12301"},
                  {"label": "Nova Porteirinha", "value": "C12302"},
                  {"label": "Nova Resende", "value": "C12303"},
                  {"label": "Nova Serrana", "value": "C12304"},
                  {"label": "Nova Uniao", "value": "C12305"},
                  {"label": "Novo Cruzeiro", "value": "C12306"},
                  {"label": "Novo Oriente de Minas", "value": "C12307"},
                  {"label": "Novorizonte", "value": "C12308"},
                  {"label": "Olaria", "value": "C12309"},
                  {"label": "Olhos-dagua", "value": "C12310"},
                  {"label": "Olimpio Noronha", "value": "C12311"},
                  {"label": "Oliveira", "value": "C12312"},
                  {"label": "Oliveira Fortes", "value": "C12313"},
                  {"label": "Onca de Pitangui", "value": "C12314"},
                  {"label": "Oratorios", "value": "C12315"},
                  {"label": "Orizania", "value": "C12316"},
                  {"label": "Ouro Branco", "value": "C12317"},
                  {"label": "Ouro Fino", "value": "C12318"},
                  {"label": "Ouro Preto", "value": "C12319"},
                  {"label": "Ouro Verde de Minas", "value": "C12320"},
                  {"label": "Padre Carvalho", "value": "C12321"},
                  {"label": "Padre Paraiso", "value": "C12322"},
                  {"label": "Pai Pedro", "value": "C12323"},
                  {"label": "Paineiras", "value": "C12324"},
                  {"label": "Pains", "value": "C12325"},
                  {"label": "Paiva", "value": "C12326"},
                  {"label": "Palma", "value": "C12327"},
                  {"label": "Palmopolis", "value": "C12328"},
                  {"label": "Papagaios", "value": "C12329"},
                  {"label": "Para de Minas", "value": "C12330"},
                  {"label": "Paracatu", "value": "C12331"},
                  {"label": "Paraguacu", "value": "C12332"},
                  {"label": "Paraisopolis", "value": "C12333"},
                  {"label": "Paraopeba", "value": "C12334"},
                  {"label": "Passa Quatro", "value": "C12335"},
                  {"label": "Passa Tempo", "value": "C12336"},
                  {"label": "Passa-Vinte", "value": "C12337"},
                  {"label": "Passabem", "value": "C12338"},
                  {"label": "Passos", "value": "C12339"},
                  {"label": "Patis", "value": "C12340"},
                  {"label": "Patos de Minas", "value": "C12341"},
                  {"label": "Patrocinio", "value": "C12342"},
                  {"label": "Patrocinio do Muriae", "value": "C12343"},
                  {"label": "Paula Candido", "value": "C12344"},
                  {"label": "Paulistas", "value": "C12345"},
                  {"label": "Pavao", "value": "C12346"},
                  {"label": "Pecanha", "value": "C12347"},
                  {"label": "Pedra Azul", "value": "C12348"},
                  {"label": "Pedra Bonita", "value": "C12349"},
                  {"label": "Pedra do Anta", "value": "C12350"},
                  {"label": "Pedra do Indaia", "value": "C12351"},
                  {"label": "Pedra Dourada", "value": "C12352"},
                  {"label": "Pedralva", "value": "C12353"},
                  {"label": "Pedras de Maria da Cruz", "value": "C12354"},
                  {"label": "Pedrinopolis", "value": "C12355"},
                  {"label": "Pedro Leopoldo", "value": "C12356"},
                  {"label": "Pedro Teixeira", "value": "C12357"},
                  {"label": "Pequeri", "value": "C12358"},
                  {"label": "Pequi", "value": "C12359"},
                  {"label": "Perdigao", "value": "C12360"},
                  {"label": "Perdizes", "value": "C12361"},
                  {"label": "Perdoes", "value": "C12362"},
                  {"label": "Periquito", "value": "C12363"},
                  {"label": "Pescador", "value": "C12364"},
                  {"label": "Piau", "value": "C12365"},
                  {"label": "Piedade de Caratinga", "value": "C12366"},
                  {"label": "Piedade de Ponte Nova", "value": "C12367"},
                  {"label": "Piedade do Rio Grande", "value": "C12368"},
                  {"label": "Piedade dos Gerais", "value": "C12369"},
                  {"label": "Pimenta", "value": "C12370"},
                  {"label": "Pingo-dagua", "value": "C12371"},
                  {"label": "Pintopolis", "value": "C12372"},
                  {"label": "Piracema", "value": "C12373"},
                  {"label": "Pirajuba", "value": "C12374"},
                  {"label": "Piranga", "value": "C12375"},
                  {"label": "Pirangucu", "value": "C12376"},
                  {"label": "Piranguinho", "value": "C12377"},
                  {"label": "Pirapetinga", "value": "C12378"},
                  {"label": "Pirapora", "value": "C12379"},
                  {"label": "Pirauba", "value": "C12380"},
                  {"label": "Pitangui", "value": "C12381"},
                  {"label": "Piui", "value": "C12382"},
                  {"label": "Piumhi", "value": "C12383"},
                  {"label": "Planura", "value": "C12384"},
                  {"label": "Poco Fundo", "value": "C12385"},
                  {"label": "Pocos de Caldas", "value": "C12386"},
                  {"label": "Pocrane", "value": "C12387"},
                  {"label": "Pompeu", "value": "C12388"},
                  {"label": "Ponte Nova", "value": "C12389"},
                  {"label": "Ponto Chique", "value": "C12390"},
                  {"label": "Ponto dos Volantes", "value": "C12391"},
                  {"label": "Porteirinha", "value": "C12392"},
                  {"label": "Porto Firme", "value": "C12393"},
                  {"label": "Pote", "value": "C12394"},
                  {"label": "Pouso Alegre", "value": "C12395"},
                  {"label": "Pouso Alto", "value": "C12396"},
                  {"label": "Prados", "value": "C12397"},
                  {"label": "Prata", "value": "C12398"},
                  {"label": "Pratapolis", "value": "C12399"},
                  {"label": "Pratinha", "value": "C12400"},
                  {"label": "Presidente Bernardes", "value": "C12401"},
                  {"label": "Presidente Juscelino", "value": "C12402"},
                  {"label": "Presidente Kubitschek", "value": "C12403"},
                  {"label": "Presidente Olegario", "value": "C12404"},
                  {"label": "Prudente de Morais", "value": "C12405"},
                  {"label": "Quartel Geral", "value": "C12406"},
                  {"label": "Queluzito", "value": "C12407"},
                  {"label": "Raposos", "value": "C12408"},
                  {"label": "Raul Soares", "value": "C12409"},
                  {"label": "Recreio", "value": "C12410"},
                  {"label": "Reduto", "value": "C12411"},
                  {"label": "Resende Costa", "value": "C12412"},
                  {"label": "Resplendor", "value": "C12413"},
                  {"label": "Ressaquinha", "value": "C12414"},
                  {"label": "Riachinho", "value": "C12415"},
                  {"label": "Riacho dos Machados", "value": "C12416"},
                  {"label": "Ribeirao das Neves", "value": "C12417"},
                  {"label": "Ribeirao Vermelho", "value": "C12418"},
                  {"label": "Rio Acima", "value": "C12419"},
                  {"label": "Rio Casca", "value": "C12420"},
                  {"label": "Rio do Prado", "value": "C12421"},
                  {"label": "Rio Doce", "value": "C12422"},
                  {"label": "Rio Espera", "value": "C12423"},
                  {"label": "Rio Manso", "value": "C12424"},
                  {"label": "Rio Novo", "value": "C12425"},
                  {"label": "Rio Paranaiba", "value": "C12426"},
                  {"label": "Rio Pardo de Minas", "value": "C12427"},
                  {"label": "Rio Piracicaba", "value": "C12428"},
                  {"label": "Rio Pomba", "value": "C12429"},
                  {"label": "Rio Preto", "value": "C12430"},
                  {"label": "Rio Vermelho", "value": "C12431"},
                  {"label": "Ritapolis", "value": "C12432"},
                  {"label": "Rochedo de Minas", "value": "C12433"},
                  {"label": "Rodeiro", "value": "C12434"},
                  {"label": "Romaria", "value": "C12435"},
                  {"label": "Rosario da Limeira", "value": "C12436"},
                  {"label": "Rubelita", "value": "C12437"},
                  {"label": "Rubim", "value": "C12438"},
                  {"label": "Sabara", "value": "C12439"},
                  {"label": "Sabinopolis", "value": "C12440"},
                  {"label": "Sacramento", "value": "C12441"},
                  {"label": "Salinas", "value": "C12442"},
                  {"label": "Salto da Divisa", "value": "C12443"},
                  {"label": "Santa Barbara", "value": "C12444"},
                  {"label": "Santa Barbara do Leste", "value": "C12445"},
                  {"label": "Santa Barbara do Monte Verde", "value": "C12446"},
                  {"label": "Santa Barbara do Tugurio", "value": "C12447"},
                  {"label": "Santa Cruz de Minas", "value": "C12448"},
                  {"label": "Santa Cruz de Salinas", "value": "C12449"},
                  {"label": "Santa Cruz do Escalvado", "value": "C12450"},
                  {"label": "Santa Efigenia de Minas", "value": "C12451"},
                  {"label": "Santa Fe de Minas", "value": "C12452"},
                  {"label": "Santa Helena de Minas", "value": "C12453"},
                  {"label": "Santa Juliana", "value": "C12454"},
                  {"label": "Santa Luzia", "value": "C12455"},
                  {"label": "Santa Margarida", "value": "C12456"},
                  {"label": "Santa Maria de Itabira", "value": "C12457"},
                  {"label": "Santa Maria do Salto", "value": "C12458"},
                  {"label": "Santa Maria do Suacui", "value": "C12459"},
                  {"label": "Santa Rita de Caldas", "value": "C12460"},
                  {"label": "Santa Rita de Ibitipoca", "value": "C12461"},
                  {"label": "Santa Rita de Jacutinga", "value": "C12462"},
                  {"label": "Santa Rita de Minas", "value": "C12463"},
                  {"label": "Santa Rita do Itueto", "value": "C12464"},
                  {"label": "Santa Rita do Sapucai", "value": "C12465"},
                  {"label": "Santa Rosa da Serra", "value": "C12466"},
                  {"label": "Santa Vitoria", "value": "C12467"},
                  {"label": "Santana da Vargem", "value": "C12468"},
                  {"label": "Santana de Cataguases", "value": "C12469"},
                  {"label": "Santana de Pirapama", "value": "C12470"},
                  {"label": "Santana do Deserto", "value": "C12471"},
                  {"label": "Santana do Garambeu", "value": "C12472"},
                  {"label": "Santana do Jacare", "value": "C12473"},
                  {"label": "Santana do Manhuacu", "value": "C12474"},
                  {"label": "Santana do Paraiso", "value": "C12475"},
                  {"label": "Santana do Riacho", "value": "C12476"},
                  {"label": "Santana dos Montes", "value": "C12477"},
                  {"label": "Santo Antonio do Amparo", "value": "C12478"},
                  {"label": "Santo Antonio do Aventureiro", "value": "C12479"},
                  {"label": "Santo Antonio do Grama", "value": "C12480"},
                  {"label": "Santo Antonio do Itambe", "value": "C12481"},
                  {"label": "Santo Antonio do Jacinto", "value": "C12482"},
                  {"label": "Santo Antonio do Monte", "value": "C12483"},
                  {"label": "Santo Antonio do Retiro", "value": "C12484"},
                  {"label": "Santo Antonio do Rio Abaixo", "value": "C12485"},
                  {"label": "Santo Hipolito", "value": "C12486"},
                  {"label": "Santos Dumont", "value": "C12487"},
                  {"label": "Sao Bento Abade", "value": "C12488"},
                  {"label": "Sao Bras do Suacui", "value": "C12489"},
                  {"label": "Sao Domingos das Dores", "value": "C12490"},
                  {"label": "Sao Domingos do Prata", "value": "C12491"},
                  {"label": "Sao Felix de Minas", "value": "C12492"},
                  {"label": "Sao Francisco", "value": "C12493"},
                  {"label": "Sao Francisco de Paula", "value": "C12494"},
                  {"label": "Sao Francisco de Sales", "value": "C12495"},
                  {"label": "Sao Francisco do Gloria", "value": "C12496"},
                  {"label": "Sao Geraldo", "value": "C12497"},
                  {"label": "Sao Geraldo da Piedade", "value": "C12498"},
                  {"label": "Sao Geraldo do Baixio", "value": "C12499"},
                  {"label": "Sao Goncalo do Abaete", "value": "C12500"},
                  {"label": "Sao Goncalo do Para", "value": "C12501"},
                  {"label": "Sao Goncalo do Rio Abaixo", "value": "C12502"},
                  {"label": "Sao Goncalo do Rio Preto", "value": "C12503"},
                  {"label": "Sao Goncalo do Sapucai", "value": "C12504"},
                  {"label": "Sao Gotardo", "value": "C12505"},
                  {"label": "Sao Joao Batista do Gloria", "value": "C12506"},
                  {"label": "Sao Joao da Lagoa", "value": "C12507"},
                  {"label": "Sao Joao da Mata", "value": "C12508"},
                  {"label": "Sao Joao da Ponte", "value": "C12509"},
                  {"label": "Sao Joao das Missoes", "value": "C12510"},
                  {"label": "Sao Joao del Rei", "value": "C12511"},
                  {"label": "Sao Joao do Manhuacu", "value": "C12512"},
                  {"label": "Sao Joao do Manteninha", "value": "C12513"},
                  {"label": "Sao Joao do Oriente", "value": "C12514"},
                  {"label": "Sao Joao do Pacui", "value": "C12515"},
                  {"label": "Sao Joao do Paraiso", "value": "C12516"},
                  {"label": "Sao Joao Evangelista", "value": "C12517"},
                  {"label": "Sao Joao Nepomuceno", "value": "C12518"},
                  {"label": "Sao Joaquim de Bicas", "value": "C12519"},
                  {"label": "Sao Jose da Barra", "value": "C12520"},
                  {"label": "Sao Jose da Lapa", "value": "C12521"},
                  {"label": "Sao Jose da Safira", "value": "C12522"},
                  {"label": "Sao Jose da Varginha", "value": "C12523"},
                  {"label": "Sao Jose do Alegre", "value": "C12524"},
                  {"label": "Sao Jose do Divino", "value": "C12525"},
                  {"label": "Sao Jose do Goiabal", "value": "C12526"},
                  {"label": "Sao Jose do Jacuri", "value": "C12527"},
                  {"label": "Sao Jose do Mantimento", "value": "C12528"},
                  {"label": "Sao Lourenco", "value": "C12529"},
                  {"label": "Sao Miguel do Anta", "value": "C12530"},
                  {"label": "Sao Pedro da Uniao", "value": "C12531"},
                  {"label": "Sao Pedro do Suacui", "value": "C12532"},
                  {"label": "Sao Pedro dos Ferros", "value": "C12533"},
                  {"label": "Sao Romao", "value": "C12534"},
                  {"label": "Sao Roque de Minas", "value": "C12535"},
                  {"label": "Sao Sebastiao da Bela Vista", "value": "C12536"},
                  {"label": "Sao Sebastiao da Vargem Alegre",
                   "value": "C12537"},
                  {"label": "Sao Sebastiao do Anta", "value": "C12538"},
                  {"label": "Sao Sebastiao do Maranhao", "value": "C12539"},
                  {"label": "Sao Sebastiao do Oeste", "value": "C12540"},
                  {"label": "Sao Sebastiao do Paraiso", "value": "C12541"},
                  {"label": "Sao Sebastiao do Rio Preto", "value": "C12542"},
                  {"label": "Sao Sebastiao do Rio Verde", "value": "C12543"},
                  {"label": "Sao Thome das Letras", "value": "C12544"},
                  {"label": "Sao Tiago", "value": "C12545"},
                  {"label": "Sao Tomas de Aquino", "value": "C12546"},
                  {"label": "Sao Vicente de Minas", "value": "C12547"},
                  {"label": "Sapucai-Mirim", "value": "C12548"},
                  {"label": "Sardoa", "value": "C12549"},
                  {"label": "Sarzedo", "value": "C12550"},
                  {"label": "Sem-Peixe", "value": "C12551"},
                  {"label": "Senador Amaral", "value": "C12552"},
                  {"label": "Senador Cortes", "value": "C12553"},
                  {"label": "Senador Firmino", "value": "C12554"},
                  {"label": "Senador Jose Bento", "value": "C12555"},
                  {"label": "Senador Modestino Goncalves", "value": "C12556"},
                  {"label": "Senhora de Oliveira", "value": "C12557"},
                  {"label": "Senhora do Porto", "value": "C12558"},
                  {"label": "Senhora dos Remedios", "value": "C12559"},
                  {"label": "Sericita", "value": "C12560"},
                  {"label": "Seritinga", "value": "C12561"},
                  {"label": "Serra Azul de Minas", "value": "C12562"},
                  {"label": "Serra da Saudade", "value": "C12563"},
                  {"label": "Serra do Salitre", "value": "C12564"},
                  {"label": "Serra dos Aimores", "value": "C12565"},
                  {"label": "Serrania", "value": "C12566"},
                  {"label": "Serranopolis de Minas", "value": "C12567"},
                  {"label": "Serranos", "value": "C12568"},
                  {"label": "Serro", "value": "C12569"},
                  {"label": "Sete Lagoas", "value": "C12570"},
                  {"label": "Setubinha", "value": "C12571"},
                  {"label": "Silveirania", "value": "C12572"},
                  {"label": "Silvianopolis", "value": "C12573"},
                  {"label": "Simao Pereira", "value": "C12574"},
                  {"label": "Simonesia", "value": "C12575"},
                  {"label": "Sobralia", "value": "C12576"},
                  {"label": "Soledade de Minas", "value": "C12577"},
                  {"label": "Tabuleiro", "value": "C12578"},
                  {"label": "Taiobeiras", "value": "C12579"},
                  {"label": "Taparuba", "value": "C12580"},
                  {"label": "Tapira", "value": "C12581"},
                  {"label": "Tapirai", "value": "C12582"},
                  {"label": "Taquaracu de Minas", "value": "C12583"},
                  {"label": "Tarumirim", "value": "C12584"},
                  {"label": "Teixeiras", "value": "C12585"},
                  {"label": "Teofilo Otoni", "value": "C12586"},
                  {"label": "Timoteo", "value": "C12587"},
                  {"label": "Tiradentes", "value": "C12588"},
                  {"label": "Tiros", "value": "C12589"},
                  {"label": "Tocantins", "value": "C12590"},
                  {"label": "Tocos do Moji", "value": "C12591"},
                  {"label": "Toledo", "value": "C12592"},
                  {"label": "Tombos", "value": "C12593"},
                  {"label": "Tres Coracoes", "value": "C12594"},
                  {"label": "Tres Marias", "value": "C12595"},
                  {"label": "Tres Pontas", "value": "C12596"},
                  {"label": "Tumiritinga", "value": "C12597"},
                  {"label": "Tupaciguara", "value": "C12598"},
                  {"label": "Turmalina", "value": "C12599"},
                  {"label": "Turvolandia", "value": "C12600"},
                  {"label": "Uba", "value": "C12601"},
                  {"label": "Ubai", "value": "C12602"},
                  {"label": "Ubaporanga", "value": "C12603"},
                  {"label": "Uberaba", "value": "C12604"},
                  {"label": "Uberlandia", "value": "C12605"},
                  {"label": "Umburatiba", "value": "C12606"},
                  {"label": "Unai", "value": "C12607"},
                  {"label": "Uniao de Minas", "value": "C12608"},
                  {"label": "Uruana de Minas", "value": "C12609"},
                  {"label": "Urucania", "value": "C12610"},
                  {"label": "Urucuia", "value": "C12611"},
                  {"label": "Vargem Alegre", "value": "C12612"},
                  {"label": "Vargem Bonita", "value": "C12613"},
                  {"label": "Vargem Grande do Rio Pardo", "value": "C12614"},
                  {"label": "Varginha", "value": "C12615"},
                  {"label": "Varjao de MInas", "value": "C12616"},
                  {"label": "Varzea da Palma", "value": "C12617"},
                  {"label": "Varzelandia", "value": "C12618"},
                  {"label": "Vazante", "value": "C12619"},
                  {"label": "Verdelandia", "value": "C12620"},
                  {"label": "Veredinha", "value": "C12621"},
                  {"label": "Verissimo", "value": "C12622"},
                  {"label": "Vermelho Novo", "value": "C12623"},
                  {"label": "Vespasiano", "value": "C12624"},
                  {"label": "Vicosa", "value": "C12625"},
                  {"label": "Vieiras", "value": "C12626"},
                  {"label": "Virgem da Lapa", "value": "C12627"},
                  {"label": "Virginia", "value": "C12628"},
                  {"label": "Virginopolis", "value": "C12629"},
                  {"label": "Virgolandia", "value": "C12630"},
                  {"label": "Visconde do Rio Branco", "value": "C12631"},
                  {"label": "Volta Grande", "value": "C12632"},
                  {"label": "Wenceslau Braz", "value": "C12633"}],
 "Mingachevir": [{"label": "Mingelchaur", "value": "C8466"}],
 "Minnesota": [{"label": "Ada", "value": "C135585"},
               {"label": "Adrian", "value": "C135586"},
               {"label": "Afton", "value": "C135587"},
               {"label": "Aitkin", "value": "C135588"},
               {"label": "Aitkin County", "value": "C135589"},
               {"label": "Albany", "value": "C135590"},
               {"label": "Albert Lea", "value": "C135591"},
               {"label": "Albertville", "value": "C135592"},
               {"label": "Alexandria", "value": "C135593"},
               {"label": "Andover", "value": "C135594"},
               {"label": "Annandale", "value": "C135595"},
               {"label": "Anoka", "value": "C135596"},
               {"label": "Anoka County", "value": "C135597"},
               {"label": "Apple Valley", "value": "C135598"},
               {"label": "Appleton", "value": "C135599"},
               {"label": "Arden Hills", "value": "C135600"},
               {"label": "Arlington", "value": "C135601"},
               {"label": "Arnold", "value": "C135602"},
               {"label": "Atwater", "value": "C135603"},
               {"label": "Aurora", "value": "C135604"},
               {"label": "Austin", "value": "C135605"},
               {"label": "Avon", "value": "C135606"},
               {"label": "Babbitt", "value": "C135607"},
               {"label": "Bagley", "value": "C135608"},
               {"label": "Barnesville", "value": "C135609"},
               {"label": "Baudette", "value": "C135610"},
               {"label": "Baxter", "value": "C135611"},
               {"label": "Bayport", "value": "C135612"},
               {"label": "Becker", "value": "C135613"},
               {"label": "Becker County", "value": "C135614"},
               {"label": "Belle Plaine", "value": "C135615"},
               {"label": "Beltrami County", "value": "C135616"},
               {"label": "Bemidji", "value": "C135617"},
               {"label": "Benson", "value": "C135618"},
               {"label": "Benton County", "value": "C135619"},
               {"label": "Big Lake", "value": "C135620"},
               {"label": "Big Stone County", "value": "C135621"},
               {"label": "Birchwood", "value": "C135622"},
               {"label": "Blaine", "value": "C135623"},
               {"label": "Blooming Prairie", "value": "C135624"},
               {"label": "Bloomington", "value": "C135625"},
               {"label": "Blue Earth", "value": "C135626"},
               {"label": "Blue Earth County", "value": "C135627"},
               {"label": "Braham", "value": "C135628"},
               {"label": "Brainerd", "value": "C135629"},
               {"label": "Branch", "value": "C135630"},
               {"label": "Breckenridge", "value": "C135631"},
               {"label": "Breezy Point", "value": "C135632"},
               {"label": "Brooklyn Center", "value": "C135633"},
               {"label": "Brooklyn Park", "value": "C135634"},
               {"label": "Brown County", "value": "C135635"},
               {"label": "Buffalo", "value": "C135636"},
               {"label": "Burnsville", "value": "C135637"},
               {"label": "Byron", "value": "C135638"},
               {"label": "Caledonia", "value": "C135639"},
               {"label": "Cambridge", "value": "C135640"},
               {"label": "Canby", "value": "C135641"},
               {"label": "Cannon Falls", "value": "C135642"},
               {"label": "Carlton", "value": "C135643"},
               {"label": "Carlton County", "value": "C135644"},
               {"label": "Carver", "value": "C135645"},
               {"label": "Carver County", "value": "C135646"},
               {"label": "Cass County", "value": "C135647"},
               {"label": "Center City", "value": "C135648"},
               {"label": "Centerville", "value": "C135649"},
               {"label": "Champlin", "value": "C135650"},
               {"label": "Chanhassen", "value": "C135651"},
               {"label": "Chaska", "value": "C135652"},
               {"label": "Chatfield", "value": "C135653"},
               {"label": "Chippewa County", "value": "C135654"},
               {"label": "Chisago City", "value": "C135655"},
               {"label": "Chisago County", "value": "C135656"},
               {"label": "Chisholm", "value": "C135657"},
               {"label": "Circle Pines", "value": "C135658"},
               {"label": "Clara City", "value": "C135659"},
               {"label": "Clay County", "value": "C135660"},
               {"label": "Clearwater", "value": "C135661"},
               {"label": "Clearwater County", "value": "C135662"},
               {"label": "Cloquet", "value": "C135663"},
               {"label": "Cohasset", "value": "C135664"},
               {"label": "Cokato", "value": "C135665"},
               {"label": "Cold Spring", "value": "C135666"},
               {"label": "Coleraine", "value": "C135667"},
               {"label": "Collegeville", "value": "C135668"},
               {"label": "Cologne", "value": "C135669"},
               {"label": "Columbia Heights", "value": "C135670"},
               {"label": "Columbus", "value": "C135671"},
               {"label": "Cook County", "value": "C135672"},
               {"label": "Coon Rapids", "value": "C135673"},
               {"label": "Corcoran", "value": "C135674"},
               {"label": "Cottage Grove", "value": "C135675"},
               {"label": "Cottonwood", "value": "C135676"},
               {"label": "Cottonwood County", "value": "C135677"},
               {"label": "Crookston", "value": "C135678"},
               {"label": "Crosby", "value": "C135679"},
               {"label": "Cross Lake", "value": "C135680"},
               {"label": "Crow Wing County", "value": "C135681"},
               {"label": "Crystal", "value": "C135682"},
               {"label": "Dakota County", "value": "C135683"},
               {"label": "Dassel", "value": "C135684"},
               {"label": "Dawson", "value": "C135685"},
               {"label": "Dayton", "value": "C135686"},
               {"label": "Deephaven", "value": "C135687"},
               {"label": "Delano", "value": "C135688"},
               {"label": "Dellwood", "value": "C135689"},
               {"label": "Detroit Lakes", "value": "C135690"},
               {"label": "Dilworth", "value": "C135691"},
               {"label": "Dodge Center", "value": "C135692"},
               {"label": "Dodge County", "value": "C135693"},
               {"label": "Douglas County", "value": "C135694"},
               {"label": "Duluth", "value": "C135695"},
               {"label": "Dundas", "value": "C135696"},
               {"label": "Eagan", "value": "C135697"},
               {"label": "Eagle Lake", "value": "C135698"},
               {"label": "East Bethel", "value": "C135699"},
               {"label": "East Grand Forks", "value": "C135700"},
               {"label": "East Gull Lake", "value": "C135701"},
               {"label": "Eden Prairie", "value": "C135702"},
               {"label": "Eden Valley", "value": "C135703"},
               {"label": "Edgerton", "value": "C135704"},
               {"label": "Edina", "value": "C135705"},
               {"label": "Elbow Lake", "value": "C135706"},
               {"label": "Elgin", "value": "C135707"},
               {"label": "Elk River", "value": "C135708"},
               {"label": "Elko New Market", "value": "C135709"},
               {"label": "Ely", "value": "C135710"},
               {"label": "Esko", "value": "C135711"},
               {"label": "Eveleth", "value": "C135712"},
               {"label": "Excelsior", "value": "C135713"},
               {"label": "Eyota", "value": "C135714"},
               {"label": "Fairfax", "value": "C135715"},
               {"label": "Fairmont", "value": "C135716"},
               {"label": "Falcon Heights", "value": "C135717"},
               {"label": "Faribault", "value": "C135718"},
               {"label": "Faribault County", "value": "C135719"},
               {"label": "Farmington", "value": "C135720"},
               {"label": "Fergus Falls", "value": "C135721"},
               {"label": "Fillmore County", "value": "C135722"},
               {"label": "Foley", "value": "C135723"},
               {"label": "Forest Lake", "value": "C135724"},
               {"label": "Fosston", "value": "C135725"},
               {"label": "Frazee", "value": "C135726"},
               {"label": "Freeborn County", "value": "C135727"},
               {"label": "Fridley", "value": "C135728"},
               {"label": "Fulda", "value": "C135729"},
               {"label": "Gaylord", "value": "C135730"},
               {"label": "Gilbert", "value": "C135731"},
               {"label": "Glencoe", "value": "C135732"},
               {"label": "Glenwood", "value": "C135733"},
               {"label": "Glyndon", "value": "C135734"},
               {"label": "Golden Valley", "value": "C135735"},
               {"label": "Goodhue", "value": "C135736"},
               {"label": "Goodhue County", "value": "C135737"},
               {"label": "Goodview", "value": "C135738"},
               {"label": "Grand Marais", "value": "C135739"},
               {"label": "Grand Meadow", "value": "C135740"},
               {"label": "Grand Rapids", "value": "C135741"},
               {"label": "Granite Falls", "value": "C135742"},
               {"label": "Grant", "value": "C135743"},
               {"label": "Grant County", "value": "C135744"},
               {"label": "Greenfield", "value": "C135745"},
               {"label": "Hallock", "value": "C135746"},
               {"label": "Ham Lake", "value": "C135747"},
               {"label": "Hanover", "value": "C135748"},
               {"label": "Harris", "value": "C135749"},
               {"label": "Hastings", "value": "C135750"},
               {"label": "Hawley", "value": "C135751"},
               {"label": "Hayfield", "value": "C135752"},
               {"label": "Hector", "value": "C135753"},
               {"label": "Hennepin County", "value": "C135754"},
               {"label": "Hermantown", "value": "C135755"},
               {"label": "Hibbing", "value": "C135756"},
               {"label": "Hinckley", "value": "C135757"},
               {"label": "Hopkins", "value": "C135758"},
               {"label": "Houston County", "value": "C135759"},
               {"label": "Howard Lake", "value": "C135760"},
               {"label": "Hoyt Lakes", "value": "C135761"},
               {"label": "Hubbard County", "value": "C135762"},
               {"label": "Hugo", "value": "C135763"},
               {"label": "Hutchinson", "value": "C135764"},
               {"label": "Independence", "value": "C135765"},
               {"label": "International Falls", "value": "C135766"},
               {"label": "Inver Grove Heights", "value": "C135767"},
               {"label": "Isanti", "value": "C135768"},
               {"label": "Isanti County", "value": "C135769"},
               {"label": "Itasca County", "value": "C135770"},
               {"label": "Ivanhoe", "value": "C135771"},
               {"label": "Jackson", "value": "C135772"},
               {"label": "Jackson County", "value": "C135773"},
               {"label": "Janesville", "value": "C135774"},
               {"label": "Jordan", "value": "C135775"},
               {"label": "Kanabec County", "value": "C135776"},
               {"label": "Kandiyohi County", "value": "C135777"},
               {"label": "Kasson", "value": "C135778"},
               {"label": "Keewatin", "value": "C135779"},
               {"label": "Kenyon", "value": "C135780"},
               {"label": "Kittson County", "value": "C135781"},
               {"label": "Koochiching County", "value": "C135782"},
               {"label": "La Crescent", "value": "C135783"},
               {"label": "Lac qui Parle County", "value": "C135784"},
               {"label": "Lake City", "value": "C135785"},
               {"label": "Lake County", "value": "C135786"},
               {"label": "Lake Crystal", "value": "C135787"},
               {"label": "Lake Elmo", "value": "C135788"},
               {"label": "Lake of the Woods County", "value": "C135789"},
               {"label": "Lake Saint Croix Beach", "value": "C135790"},
               {"label": "Lake Shore", "value": "C135791"},
               {"label": "Lakefield", "value": "C135792"},
               {"label": "Lakeland", "value": "C135793"},
               {"label": "Lakeville", "value": "C135794"},
               {"label": "Lauderdale", "value": "C135795"},
               {"label": "Le Center", "value": "C135796"},
               {"label": "Le Sueur", "value": "C135797"},
               {"label": "Le Sueur County", "value": "C135798"},
               {"label": "Lester Prairie", "value": "C135799"},
               {"label": "Lewiston", "value": "C135800"},
               {"label": "Lexington", "value": "C135801"},
               {"label": "Lincoln County", "value": "C135802"},
               {"label": "Lindstrom", "value": "C135803"},
               {"label": "Lino Lakes", "value": "C135804"},
               {"label": "Litchfield", "value": "C135805"},
               {"label": "Little Canada", "value": "C135806"},
               {"label": "Little Falls", "value": "C135807"},
               {"label": "Little Rock", "value": "C135808"},
               {"label": "Long Lake", "value": "C135809"},
               {"label": "Long Prairie", "value": "C135810"},
               {"label": "Lonsdale", "value": "C135811"},
               {"label": "Luverne", "value": "C135812"},
               {"label": "Lyon County", "value": "C135813"},
               {"label": "Madelia", "value": "C135814"},
               {"label": "Madison", "value": "C135815"},
               {"label": "Madison Lake", "value": "C135816"},
               {"label": "Mahnomen", "value": "C135817"},
               {"label": "Mahnomen County", "value": "C135818"},
               {"label": "Mahtomedi", "value": "C135819"},
               {"label": "Mankato", "value": "C135820"},
               {"label": "Mantorville", "value": "C135821"},
               {"label": "Maple Grove", "value": "C135822"},
               {"label": "Maple Lake", "value": "C135823"},
               {"label": "Maple Plain", "value": "C135824"},
               {"label": "Mapleton", "value": "C135825"},
               {"label": "Maplewood", "value": "C135826"},
               {"label": "Marshall", "value": "C135827"},
               {"label": "Marshall County", "value": "C135828"},
               {"label": "Martin County", "value": "C135829"},
               {"label": "Mayer", "value": "C135830"},
               {"label": "McLeod County", "value": "C135831"},
               {"label": "Medford", "value": "C135832"},
               {"label": "Medina", "value": "C135833"},
               {"label": "Meeker County", "value": "C135834"},
               {"label": "Melrose", "value": "C135835"},
               {"label": "Menahga", "value": "C135836"},
               {"label": "Mendota Heights", "value": "C135837"},
               {"label": "Milaca", "value": "C135838"},
               {"label": "Mille Lacs County", "value": "C135839"},
               {"label": "Minneapolis", "value": "C135840"},
               {"label": "Minneota", "value": "C135841"},
               {"label": "Minnetonka", "value": "C135842"},
               {"label": "Minnetonka Mills", "value": "C135843"},
               {"label": "Minnetrista", "value": "C135844"},
               {"label": "Montevideo", "value": "C135845"},
               {"label": "Montgomery", "value": "C135846"},
               {"label": "Monticello", "value": "C135847"},
               {"label": "Montrose", "value": "C135848"},
               {"label": "Moorhead", "value": "C135849"},
               {"label": "Moose Lake", "value": "C135850"},
               {"label": "Mora", "value": "C135851"},
               {"label": "Morris", "value": "C135852"},
               {"label": "Morrison County", "value": "C135853"},
               {"label": "Mound", "value": "C135854"},
               {"label": "Mounds View", "value": "C135855"},
               {"label": "Mountain Iron", "value": "C135856"},
               {"label": "Mountain Lake", "value": "C135857"},
               {"label": "Mower County", "value": "C135858"},
               {"label": "Murray County", "value": "C135859"},
               {"label": "New Brighton", "value": "C135860"},
               {"label": "New Hope", "value": "C135861"},
               {"label": "New London", "value": "C135862"},
               {"label": "New Prague", "value": "C135863"},
               {"label": "New Richland", "value": "C135864"},
               {"label": "New Ulm", "value": "C135865"},
               {"label": "New York Mills", "value": "C135866"},
               {"label": "Newport", "value": "C135867"},
               {"label": "Nicollet", "value": "C135868"},
               {"label": "Nicollet County", "value": "C135869"},
               {"label": "Nisswa", "value": "C135870"},
               {"label": "Nobles County", "value": "C135871"},
               {"label": "Norman County", "value": "C135872"},
               {"label": "North Branch", "value": "C135873"},
               {"label": "North Mankato", "value": "C135874"},
               {"label": "North Oaks", "value": "C135875"},
               {"label": "North Saint Paul", "value": "C135876"},
               {"label": "Northfield", "value": "C135877"},
               {"label": "Norwood (historical)", "value": "C135878"},
               {"label": "Norwood Young America", "value": "C135879"},
               {"label": "Nowthen", "value": "C135880"},
               {"label": "Oak Grove", "value": "C135881"},
               {"label": "Oak Park Heights", "value": "C135882"},
               {"label": "Oakdale", "value": "C135883"},
               {"label": "Oakport", "value": "C135884"},
               {"label": "Olivia", "value": "C135885"},
               {"label": "Olmsted County", "value": "C135886"},
               {"label": "Orono", "value": "C135887"},
               {"label": "Oronoco", "value": "C135888"},
               {"label": "Ortonville", "value": "C135889"},
               {"label": "Osakis", "value": "C135890"},
               {"label": "Osseo", "value": "C135891"},
               {"label": "Otsego", "value": "C135892"},
               {"label": "Otter Tail County", "value": "C135893"},
               {"label": "Owatonna", "value": "C135894"},
               {"label": "Park Rapids", "value": "C135895"},
               {"label": "Parkers Prairie", "value": "C135896"},
               {"label": "Parkville", "value": "C135897"},
               {"label": "Paynesville", "value": "C135898"},
               {"label": "Pelican Rapids", "value": "C135899"},
               {"label": "Pennington County", "value": "C135900"},
               {"label": "Pequot Lakes", "value": "C135901"},
               {"label": "Perham", "value": "C135902"},
               {"label": "Pierz", "value": "C135903"},
               {"label": "Pine City", "value": "C135904"},
               {"label": "Pine County", "value": "C135905"},
               {"label": "Pine Island", "value": "C135906"},
               {"label": "Pipestone", "value": "C135907"},
               {"label": "Pipestone County", "value": "C135908"},
               {"label": "Plainview", "value": "C135909"},
               {"label": "Plymouth", "value": "C135910"},
               {"label": "Polk County", "value": "C135911"},
               {"label": "Pope County", "value": "C135912"},
               {"label": "Preston", "value": "C135913"},
               {"label": "Princeton", "value": "C135914"},
               {"label": "Prior Lake", "value": "C135915"},
               {"label": "Proctor", "value": "C135916"},
               {"label": "Ramsey", "value": "C135917"},
               {"label": "Ramsey County", "value": "C135918"},
               {"label": "Red Lake", "value": "C135919"},
               {"label": "Red Lake County", "value": "C135920"},
               {"label": "Red Lake Falls", "value": "C135921"},
               {"label": "Red Wing", "value": "C135922"},
               {"label": "Redby", "value": "C135923"},
               {"label": "Redwood County", "value": "C135924"},
               {"label": "Redwood Falls", "value": "C135925"},
               {"label": "Renville", "value": "C135926"},
               {"label": "Renville County", "value": "C135927"},
               {"label": "Rice", "value": "C135928"},
               {"label": "Rice County", "value": "C135929"},
               {"label": "Richfield", "value": "C135930"},
               {"label": "Richmond", "value": "C135931"},
               {"label": "Robbinsdale", "value": "C135932"},
               {"label": "Rochester", "value": "C135933"},
               {"label": "Rock County", "value": "C135934"},
               {"label": "Rock Creek", "value": "C135935"},
               {"label": "Rockford", "value": "C135936"},
               {"label": "Rockville", "value": "C135937"},
               {"label": "Rogers", "value": "C135938"},
               {"label": "Roseau", "value": "C135939"},
               {"label": "Roseau County", "value": "C135940"},
               {"label": "Rosemount", "value": "C135941"},
               {"label": "Roseville", "value": "C135942"},
               {"label": "Royalton", "value": "C135943"},
               {"label": "Rush City", "value": "C135944"},
               {"label": "Rushford", "value": "C135945"},
               {"label": "Saint Anthony", "value": "C135946"},
               {"label": "Saint Augusta", "value": "C135947"},
               {"label": "Saint Bonifacius", "value": "C135948"},
               {"label": "Saint Charles", "value": "C135949"},
               {"label": "Saint Cloud", "value": "C135950"},
               {"label": "Saint Francis", "value": "C135951"},
               {"label": "Saint James", "value": "C135952"},
               {"label": "Saint Joseph", "value": "C135953"},
               {"label": "Saint Louis County", "value": "C135954"},
               {"label": "Saint Louis Park", "value": "C135955"},
               {"label": "Saint Michael", "value": "C135956"},
               {"label": "Saint Paul", "value": "C135957"},
               {"label": "Saint Paul Park", "value": "C135958"},
               {"label": "Saint Peter", "value": "C135959"},
               {"label": "Sandstone", "value": "C135960"},
               {"label": "Sartell", "value": "C135961"},
               {"label": "Sauk Centre", "value": "C135962"},
               {"label": "Sauk Rapids", "value": "C135963"},
               {"label": "Savage", "value": "C135964"},
               {"label": "Scandia", "value": "C135965"},
               {"label": "Scott County", "value": "C135966"},
               {"label": "Shafer", "value": "C135967"},
               {"label": "Shakopee", "value": "C135968"},
               {"label": "Sherburn", "value": "C135969"},
               {"label": "Sherburne County", "value": "C135970"},
               {"label": "Shoreview", "value": "C135971"},
               {"label": "Shorewood", "value": "C135972"},
               {"label": "Sibley County", "value": "C135973"},
               {"label": "Silver Bay", "value": "C135974"},
               {"label": "Slayton", "value": "C135975"},
               {"label": "Sleepy Eye", "value": "C135976"},
               {"label": "South Saint Paul", "value": "C135977"},
               {"label": "Spicer", "value": "C135978"},
               {"label": "Spring Grove", "value": "C135979"},
               {"label": "Spring Lake Park", "value": "C135980"},
               {"label": "Spring Park", "value": "C135981"},
               {"label": "Spring Valley", "value": "C135982"},
               {"label": "Springfield", "value": "C135983"},
               {"label": "Stacy", "value": "C135984"},
               {"label": "Staples", "value": "C135985"},
               {"label": "Starbuck", "value": "C135986"},
               {"label": "Stearns County", "value": "C135987"},
               {"label": "Steele County", "value": "C135988"},
               {"label": "Stevens County", "value": "C135989"},
               {"label": "Stewartville", "value": "C135990"},
               {"label": "Stillwater", "value": "C135991"},
               {"label": "Swift County", "value": "C135992"},
               {"label": "Taylors Falls", "value": "C135993"},
               {"label": "Thief River Falls", "value": "C135994"},
               {"label": "Todd County", "value": "C135995"},
               {"label": "Tonka Bay", "value": "C135996"},
               {"label": "Tracy", "value": "C135997"},
               {"label": "Traverse County", "value": "C135998"},
               {"label": "Truman", "value": "C135999"},
               {"label": "Two Harbors", "value": "C136000"},
               {"label": "Tyler", "value": "C136001"},
               {"label": "Vadnais Heights", "value": "C136002"},
               {"label": "Victoria", "value": "C136003"},
               {"label": "Vineland", "value": "C136004"},
               {"label": "Virginia", "value": "C136005"},
               {"label": "Wabasha", "value": "C136006"},
               {"label": "Wabasha County", "value": "C136007"},
               {"label": "Waconia", "value": "C136008"},
               {"label": "Wadena", "value": "C136009"},
               {"label": "Wadena County", "value": "C136010"},
               {"label": "Waite Park", "value": "C136011"},
               {"label": "Walker", "value": "C136012"},
               {"label": "Wanamingo", "value": "C136013"},
               {"label": "Warren", "value": "C136014"},
               {"label": "Warroad", "value": "C136015"},
               {"label": "Waseca", "value": "C136016"},
               {"label": "Waseca County", "value": "C136017"},
               {"label": "Washington County", "value": "C136018"},
               {"label": "Watertown", "value": "C136019"},
               {"label": "Waterville", "value": "C136020"},
               {"label": "Watonwan County", "value": "C136021"},
               {"label": "Waverly", "value": "C136022"},
               {"label": "Wayzata", "value": "C136023"},
               {"label": "Wells", "value": "C136024"},
               {"label": "West Coon Rapids", "value": "C136025"},
               {"label": "West Saint Paul", "value": "C136026"},
               {"label": "Wheaton", "value": "C136027"},
               {"label": "White Bear Lake", "value": "C136028"},
               {"label": "Wilkin County", "value": "C136029"},
               {"label": "Willmar", "value": "C136030"},
               {"label": "Windom", "value": "C136031"},
               {"label": "Winnebago", "value": "C136032"},
               {"label": "Winona", "value": "C136033"},
               {"label": "Winona County", "value": "C136034"},
               {"label": "Winsted", "value": "C136035"},
               {"label": "Winthrop", "value": "C136036"},
               {"label": "Woodbury", "value": "C136037"},
               {"label": "Worthington", "value": "C136038"},
               {"label": "Wright County", "value": "C136039"},
               {"label": "Wyoming", "value": "C136040"},
               {"label": "Yellow Medicine County", "value": "C136041"},
               {"label": "Young America (historical)", "value": "C136042"},
               {"label": "Zimmerman", "value": "C136043"},
               {"label": "Zumbrota", "value": "C136044"}],
 "Minsk": [{"label": "Frunzyenski Rayon", "value": "C8826"},
           {"label": "Kastrychnitski Rayon", "value": "C8827"},
           {"label": "Lyeninski Rayon", "value": "C8828"},
           {"label": "Maskowski Rayon", "value": "C8829"},
           {"label": "Minsk", "value": "C8830"},
           {"label": "Partyzanski Rayon", "value": "C8831"},
           {"label": "Savyetski Rayon", "value": "C8832"},
           {"label": "Tsentralny Rayon", "value": "C8833"},
           {"label": "Zavodski Rayon", "value": "C8834"}],
 "Minsk Region": [{"label": "Astrashytski Haradok", "value": "C8835"},
                  {"label": "Atolina", "value": "C8836"},
                  {"label": "Azyartso", "value": "C8837"},
                  {"label": "Balshavik", "value": "C8838"},
                  {"label": "Barysaw", "value": "C8839"},
                  {"label": "Barysawski Rayon", "value": "C8840"},
                  {"label": "Blon", "value": "C8841"},
                  {"label": "Bobr", "value": "C8842"},
                  {"label": "Borovlyany", "value": "C8843"},
                  {"label": "Byarezinski Rayon", "value": "C8844"},
                  {"label": "Byerazino", "value": "C8845"},
                  {"label": "Chervyen", "value": "C8846"},
                  {"label": "Chervyenski Rayon", "value": "C8847"},
                  {"label": "Chyrvonaya Slabada", "value": "C8848"},
                  {"label": "Chyst", "value": "C8849"},
                  {"label": "Druzhny", "value": "C8850"},
                  {"label": "Dukora", "value": "C8851"},
                  {"label": "Dzyarzhynsk", "value": "C8852"},
                  {"label": "Enyerhyetykaw", "value": "C8853"},
                  {"label": "Fanipol", "value": "C8854"},
                  {"label": "Haradzyeya", "value": "C8855"},
                  {"label": "Hatava", "value": "C8856"},
                  {"label": "Horad Smalyavichy", "value": "C8857"},
                  {"label": "Horad Zhodzina", "value": "C8858"},
                  {"label": "Hotsk", "value": "C8859"},
                  {"label": "Ilya", "value": "C8860"},
                  {"label": "Ivyanyets", "value": "C8861"},
                  {"label": "Kalodzishchy", "value": "C8862"},
                  {"label": "Kapyl", "value": "C8863"},
                  {"label": "Kapylski Rayon", "value": "C8864"},
                  {"label": "Khatsyezhyna", "value": "C8865"},
                  {"label": "Kholopenichi", "value": "C8866"},
                  {"label": "Klyetsk", "value": "C8867"},
                  {"label": "Klyetski Rayon", "value": "C8868"},
                  {"label": "Korolev Stan", "value": "C8869"},
                  {"label": "Krasnaye", "value": "C8870"},
                  {"label": "Krupki", "value": "C8871"},
                  {"label": "Kryvichy", "value": "C8872"},
                  {"label": "Lahoysk", "value": "C8873"},
                  {"label": "Loshnitsa", "value": "C8874"},
                  {"label": "Luhavaya Slabada", "value": "C8875"},
                  {"label": "Lyasny", "value": "C8876"},
                  {"label": "Lyeskawka", "value": "C8877"},
                  {"label": "Lyuban", "value": "C8878"},
                  {"label": "Machulishchy", "value": "C8879"},
                  {"label": "Maladzyechna", "value": "C8880"},
                  {"label": "Marina Horka", "value": "C8881"},
                  {"label": "Michanovichi", "value": "C8882"},
                  {"label": "Myadzyel", "value": "C8883"},
                  {"label": "Myadzyelski Rayon", "value": "C8884"},
                  {"label": "Narach", "value": "C8885"},
                  {"label": "Nasilava", "value": "C8886"},
                  {"label": "Novoselye", "value": "C8887"},
                  {"label": "Novy Svyerzhan", "value": "C8888"},
                  {"label": "Nyasvizh", "value": "C8889"},
                  {"label": "Nyasvizhski Rayon", "value": "C8890"},
                  {"label": "Plyeshchanitsy", "value": "C8891"},
                  {"label": "Prawdzinski", "value": "C8892"},
                  {"label": "Pryvolny", "value": "C8893"},
                  {"label": "Pukhavichy", "value": "C8894"},
                  {"label": "Pukhavichy Raion", "value": "C8895"},
                  {"label": "Pyatryshki", "value": "C8896"},
                  {"label": "Radashkovichy", "value": "C8897"},
                  {"label": "Rakaw", "value": "C8898"},
                  {"label": "Rudzyensk", "value": "C8899"},
                  {"label": "Salihorsk", "value": "C8900"},
                  {"label": "Samakhvalavichy", "value": "C8901"},
                  {"label": "Sarachy", "value": "C8902"},
                  {"label": "Schomyslitsa", "value": "C8903"},
                  {"label": "Slabada", "value": "C8904"},
                  {"label": "Slutsk", "value": "C8905"},
                  {"label": "Slutski Rayon", "value": "C8906"},
                  {"label": "Smalyavitski Rayon", "value": "C8907"},
                  {"label": "Smilavichy", "value": "C8908"},
                  {"label": "Snow", "value": "C8909"},
                  {"label": "Stankava", "value": "C8910"},
                  {"label": "Staradarozhski Rayon", "value": "C8911"},
                  {"label": "Starobin", "value": "C8912"},
                  {"label": "Staryya Darohi", "value": "C8913"},
                  {"label": "Stawbtsowski Rayon", "value": "C8914"},
                  {"label": "Stowbtsy", "value": "C8915"},
                  {"label": "Svir", "value": "C8916"},
                  {"label": "Svislach", "value": "C8917"},
                  {"label": "Syenitsa", "value": "C8918"},
                  {"label": "Syomkava", "value": "C8919"},
                  {"label": "Tsimkavichy", "value": "C8920"},
                  {"label": "Turets-Bayary", "value": "C8921"},
                  {"label": "Urechcha", "value": "C8922"},
                  {"label": "Usiazh", "value": "C8923"},
                  {"label": "Uzda", "value": "C8924"},
                  {"label": "Uzdzyenski Rayon", "value": "C8925"},
                  {"label": "Valozhyn", "value": "C8926"},
                  {"label": "Vilyeyka", "value": "C8927"},
                  {"label": "Vilyeyski Rayon", "value": "C8928"},
                  {"label": "Vyaliki Trastsyanets", "value": "C8929"},
                  {"label": "Yubilyeyny", "value": "C8930"},
                  {"label": "Zamostochye", "value": "C8931"},
                  {"label": "Zaslawye", "value": "C8932"},
                  {"label": "Zhdanovichy", "value": "C8933"},
                  {"label": "Zyembin", "value": "C8934"},
                  {"label": "Oktiabrskii", "value": "C8935"}],
 "Minya": [{"label": "Abu Qurqas", "value": "C24297"},
           {"label": "Al Minya", "value": "C24298"},
           {"label": "Bani Mazar", "value": "C24299"},
           {"label": "Dayr Mawas", "value": "C24300"},
           {"label": "Mallawi", "value": "C24301"},
           {"label": "Matay", "value": "C24302"},
           {"label": "Samalut", "value": "C24303"}],
 "Miranda": [{"label": "Baruta", "value": "C147199"},
             {"label": "Carrizal", "value": "C147200"},
             {"label": "Caucaguita", "value": "C147201"},
             {"label": "Caucaguito", "value": "C147202"},
             {"label": "Chacao", "value": "C147203"},
             {"label": "Charallave", "value": "C147204"},
             {"label": "Cua", "value": "C147205"},
             {"label": "El Cafetal", "value": "C147206"},
             {"label": "El Hatillo", "value": "C147207"},
             {"label": "Guarenas", "value": "C147208"},
             {"label": "Guatire", "value": "C147209"},
             {"label": "La Dolorita", "value": "C147210"},
             {"label": "Los Dos Caminos", "value": "C147211"},
             {"label": "Los Teques", "value": "C147212"},
             {"label": "Ocumare del Tuy", "value": "C147213"},
             {"label": "Petare", "value": "C147214"},
             {"label": "San Antonio de Los Altos", "value": "C147215"},
             {"label": "Santa Teresa del Tuy", "value": "C147216"}],
 "Miren-Kostanjevica Municipality": [{"label": "Bilje", "value": "C107725"},
                                     {"label": "Miren", "value": "C107726"}],
 "Mirna Pec Municipality": [{"label": "Mirna Pec", "value": "C107727"}],
 "Misiones": [{"label": "Alba Posse", "value": "C1177"},
              {"label": "Almafuerte", "value": "C1178"},
              {"label": "Aristobulo del Valle", "value": "C1179"},
              {"label": "Arroyo del Medio", "value": "C1180"},
              {"label": "Azara", "value": "C1181"},
              {"label": "Bernardo de Irigoyen", "value": "C1182"},
              {"label": "Bonpland", "value": "C1183"},
              {"label": "Campo Grande", "value": "C1184"},
              {"label": "Campo Ramon", "value": "C1185"},
              {"label": "Campo Viera", "value": "C1186"},
              {"label": "Candelaria", "value": "C1187"},
              {"label": "Capiovi", "value": "C1188"},
              {"label": "Caraguatay", "value": "C1189"},
              {"label": "Cerro Azul", "value": "C1190"},
              {"label": "Cerro Cora", "value": "C1191"},
              {"label": "Colonia Aurora", "value": "C1192"},
              {"label": "Concepcion de la Sierra", "value": "C1193"},
              {"label": "Departamento de Apostoles", "value": "C1194"},
              {"label": "Departamento de Cainguas", "value": "C1195"},
              {"label": "Departamento de Candelaria", "value": "C1196"},
              {"label": "Departamento de Capital", "value": "C1197"},
              {"label": "Departamento de Concepcion de la Sierra",
               "value": "C1198"},
              {"label": "Departamento de Eldorado", "value": "C1199"},
              {"label": "Departamento de General Manuel Belgrano",
               "value": "C1200"},
              {"label": "Departamento de Guarani", "value": "C1201"},
              {"label": "Departamento de Iguazu", "value": "C1202"},
              {"label": "Departamento de Leandro N. Alem", "value": "C1203"},
              {"label": "Departamento de Libertador General San Martin",
               "value": "C1204"},
              {"label": "Departamento de Montecarlo", "value": "C1205"},
              {"label": "Departamento de Obera", "value": "C1206"},
              {"label": "Departamento de San Ignacio", "value": "C1207"},
              {"label": "Departamento de San Javier", "value": "C1208"},
              {"label": "Departamento de San Pedro", "value": "C1209"},
              {"label": "Departamento de Veinticinco de Mayo",
               "value": "C1210"},
              {"label": "Dos Arroyos", "value": "C1211"},
              {"label": "Dos de Mayo", "value": "C1212"},
              {"label": "El Alcazar", "value": "C1213"},
              {"label": "El Soberbio", "value": "C1214"},
              {"label": "Florentino Ameghino", "value": "C1215"},
              {"label": "Garuhape", "value": "C1216"},
              {"label": "Garupa", "value": "C1217"},
              {"label": "General Alvear", "value": "C1218"},
              {"label": "Gobernador Roca", "value": "C1219"},
              {"label": "Guarani", "value": "C1220"},
              {"label": "Jardin America", "value": "C1221"},
              {"label": "Loreto", "value": "C1222"},
              {"label": "Los Helechos", "value": "C1223"},
              {"label": "Martires", "value": "C1224"},
              {"label": "Mojon Grande", "value": "C1225"},
              {"label": "Montecarlo", "value": "C1226"},
              {"label": "Obera", "value": "C1227"},
              {"label": "Panambi", "value": "C1228"},
              {"label": "Picada Gobernador Lopez", "value": "C1229"},
              {"label": "Posadas", "value": "C1230"},
              {"label": "Puerto Eldorado", "value": "C1231"},
              {"label": "Puerto Esperanza", "value": "C1232"},
              {"label": "Puerto Iguazu", "value": "C1233"},
              {"label": "Puerto Leoni", "value": "C1234"},
              {"label": "Puerto Libertad", "value": "C1235"},
              {"label": "Puerto Piray", "value": "C1236"},
              {"label": "Puerto Rico", "value": "C1237"},
              {"label": "Ruiz de Montoya", "value": "C1238"},
              {"label": "San Jose", "value": "C1239"},
              {"label": "San Pedro", "value": "C1240"},
              {"label": "San Vicente", "value": "C1241"},
              {"label": "Santa Maria", "value": "C1242"},
              {"label": "Santo Pipo", "value": "C1243"},
              {"label": "Tres Capones", "value": "C1244"},
              {"label": "Veinticinco de Mayo", "value": "C1245"},
              {"label": "Wanda", "value": "C1246"}],
 "Misiones Department": [{"label": "Juan de Ayolas", "value": "C80590"},
                         {"label": "San Juan Bautista", "value": "C80591"},
                         {"label": "San Miguel", "value": "C80592"},
                         {"label": "San Patricio", "value": "C80593"},
                         {"label": "Santa Maria", "value": "C80594"},
                         {"label": "Santa Rosa Misiones", "value": "C80595"},
                         {"label": "Santiago", "value": "C80596"},
                         {"label": "Villa Florida", "value": "C80597"}],
 "Mislinja Municipality": [{"label": "Mislinja", "value": "C107728"}],
 "Misrata District": [{"label": "Bani Walid", "value": "C65045"},
                      {"label": "Misratah", "value": "C65046"},
                      {"label": "Zliten", "value": "C65047"}],
 "Mississippi": [{"label": "Aberdeen", "value": "C136045"},
                 {"label": "Ackerman", "value": "C136046"},
                 {"label": "Adams County", "value": "C136047"},
                 {"label": "Alcorn County", "value": "C136048"},
                 {"label": "Amite County", "value": "C136049"},
                 {"label": "Amory", "value": "C136050"},
                 {"label": "Arnold Line", "value": "C136051"},
                 {"label": "Ashland", "value": "C136052"},
                 {"label": "Attala County", "value": "C136053"},
                 {"label": "Baldwyn", "value": "C136054"},
                 {"label": "Batesville", "value": "C136055"},
                 {"label": "Bay Saint Louis", "value": "C136056"},
                 {"label": "Bay Springs", "value": "C136057"},
                 {"label": "Beechwood", "value": "C136058"},
                 {"label": "Belmont", "value": "C136059"},
                 {"label": "Belzoni", "value": "C136060"},
                 {"label": "Benton County", "value": "C136061"},
                 {"label": "Biloxi", "value": "C136062"},
                 {"label": "Bolivar County", "value": "C136063"},
                 {"label": "Booneville", "value": "C136064"},
                 {"label": "Brandon", "value": "C136065"},
                 {"label": "Brookhaven", "value": "C136066"},
                 {"label": "Brooksville", "value": "C136067"},
                 {"label": "Bruce", "value": "C136068"},
                 {"label": "Bude", "value": "C136069"},
                 {"label": "Byhalia", "value": "C136070"},
                 {"label": "Byram", "value": "C136071"},
                 {"label": "Caledonia", "value": "C136072"},
                 {"label": "Calhoun City", "value": "C136073"},
                 {"label": "Calhoun County", "value": "C136074"},
                 {"label": "Canton", "value": "C136075"},
                 {"label": "Carriere", "value": "C136076"},
                 {"label": "Carroll County", "value": "C136077"},
                 {"label": "Carrollton", "value": "C136078"},
                 {"label": "Carthage", "value": "C136079"},
                 {"label": "Centreville", "value": "C136080"},
                 {"label": "Charleston", "value": "C136081"},
                 {"label": "Chickasaw County", "value": "C136082"},
                 {"label": "Choctaw County", "value": "C136083"},
                 {"label": "Claiborne County", "value": "C136084"},
                 {"label": "Clarke County", "value": "C136085"},
                 {"label": "Clarksdale", "value": "C136086"},
                 {"label": "Clay County", "value": "C136087"},
                 {"label": "Cleary", "value": "C136088"},
                 {"label": "Cleveland", "value": "C136089"},
                 {"label": "Clinton", "value": "C136090"},
                 {"label": "Coahoma County", "value": "C136091"},
                 {"label": "Coldwater", "value": "C136092"},
                 {"label": "Collins", "value": "C136093"},
                 {"label": "Collinsville", "value": "C136094"},
                 {"label": "Columbia", "value": "C136095"},
                 {"label": "Columbus", "value": "C136096"},
                 {"label": "Columbus Air Force Base", "value": "C136097"},
                 {"label": "Como", "value": "C136098"},
                 {"label": "Conehatta", "value": "C136099"},
                 {"label": "Copiah County", "value": "C136100"},
                 {"label": "Corinth", "value": "C136101"},
                 {"label": "Covington County", "value": "C136102"},
                 {"label": "Crystal Springs", "value": "C136103"},
                 {"label": "DIberville", "value": "C136104"},
                 {"label": "De Kalb", "value": "C136105"},
                 {"label": "De Lisle", "value": "C136106"},
                 {"label": "De Soto County", "value": "C136107"},
                 {"label": "Decatur", "value": "C136108"},
                 {"label": "Derma", "value": "C136109"},
                 {"label": "Diamondhead", "value": "C136110"},
                 {"label": "Drew", "value": "C136111"},
                 {"label": "Duck Hill", "value": "C136112"},
                 {"label": "Durant", "value": "C136113"},
                 {"label": "Edwards", "value": "C136114"},
                 {"label": "Ellisville", "value": "C136115"},
                 {"label": "Escatawpa", "value": "C136116"},
                 {"label": "Eupora", "value": "C136117"},
                 {"label": "Farmington", "value": "C136118"},
                 {"label": "Fayette", "value": "C136119"},
                 {"label": "Flora", "value": "C136120"},
                 {"label": "Florence", "value": "C136121"},
                 {"label": "Flowood", "value": "C136122"},
                 {"label": "Forest", "value": "C136123"},
                 {"label": "Forrest County", "value": "C136124"},
                 {"label": "Franklin County", "value": "C136125"},
                 {"label": "Friars Point", "value": "C136126"},
                 {"label": "Fulton", "value": "C136127"},
                 {"label": "Gautier", "value": "C136128"},
                 {"label": "George County", "value": "C136129"},
                 {"label": "Glendale", "value": "C136130"},
                 {"label": "Goodman", "value": "C136131"},
                 {"label": "Greene County", "value": "C136132"},
                 {"label": "Greenville", "value": "C136133"},
                 {"label": "Greenwood", "value": "C136134"},
                 {"label": "Grenada", "value": "C136135"},
                 {"label": "Grenada County", "value": "C136136"},
                 {"label": "Gulf Hills", "value": "C136137"},
                 {"label": "Gulf Park Estates", "value": "C136138"},
                 {"label": "Gulfport", "value": "C136139"},
                 {"label": "Guntown", "value": "C136140"},
                 {"label": "Hancock County", "value": "C136141"},
                 {"label": "Harrison County", "value": "C136142"},
                 {"label": "Hattiesburg", "value": "C136143"},
                 {"label": "Hazlehurst", "value": "C136144"},
                 {"label": "Helena", "value": "C136145"},
                 {"label": "Hernando", "value": "C136146"},
                 {"label": "Hickory Hills", "value": "C136147"},
                 {"label": "Hide-A-Way Lake", "value": "C136148"},
                 {"label": "Hillsboro", "value": "C136149"},
                 {"label": "Hinds County", "value": "C136150"},
                 {"label": "Hollandale", "value": "C136151"},
                 {"label": "Holly Springs", "value": "C136152"},
                 {"label": "Holmes County", "value": "C136153"},
                 {"label": "Horn Lake", "value": "C136154"},
                 {"label": "Houston", "value": "C136155"},
                 {"label": "Humphreys County", "value": "C136156"},
                 {"label": "Hurley", "value": "C136157"},
                 {"label": "Indianola", "value": "C136158"},
                 {"label": "Issaquena County", "value": "C136159"},
                 {"label": "Itawamba County", "value": "C136160"},
                 {"label": "Itta Bena", "value": "C136161"},
                 {"label": "Iuka", "value": "C136162"},
                 {"label": "Jackson", "value": "C136163"},
                 {"label": "Jackson County", "value": "C136164"},
                 {"label": "Jasper County", "value": "C136165"},
                 {"label": "Jefferson County", "value": "C136166"},
                 {"label": "Jefferson Davis County", "value": "C136167"},
                 {"label": "Jones County", "value": "C136168"},
                 {"label": "Jonestown", "value": "C136169"},
                 {"label": "Kearney Park", "value": "C136170"},
                 {"label": "Kemper County", "value": "C136171"},
                 {"label": "Kiln", "value": "C136172"},
                 {"label": "Kosciusko", "value": "C136173"},
                 {"label": "Lafayette County", "value": "C136174"},
                 {"label": "Lamar County", "value": "C136175"},
                 {"label": "Lambert", "value": "C136176"},
                 {"label": "Latimer", "value": "C136177"},
                 {"label": "Lauderdale County", "value": "C136178"},
                 {"label": "Laurel", "value": "C136179"},
                 {"label": "Lawrence County", "value": "C136180"},
                 {"label": "Leake County", "value": "C136181"},
                 {"label": "Leakesville", "value": "C136182"},
                 {"label": "Lee County", "value": "C136183"},
                 {"label": "Leflore County", "value": "C136184"},
                 {"label": "Leland", "value": "C136185"},
                 {"label": "Lexington", "value": "C136186"},
                 {"label": "Liberty", "value": "C136187"},
                 {"label": "Lincoln County", "value": "C136188"},
                 {"label": "Long Beach", "value": "C136189"},
                 {"label": "Louisville", "value": "C136190"},
                 {"label": "Lowndes County", "value": "C136191"},
                 {"label": "Lucedale", "value": "C136192"},
                 {"label": "Lumberton", "value": "C136193"},
                 {"label": "Lyman", "value": "C136194"},
                 {"label": "Lynchburg", "value": "C136195"},
                 {"label": "Macon", "value": "C136196"},
                 {"label": "Madison", "value": "C136197"},
                 {"label": "Madison County", "value": "C136198"},
                 {"label": "Magee", "value": "C136199"},
                 {"label": "Magnolia", "value": "C136200"},
                 {"label": "Mantachie", "value": "C136201"},
                 {"label": "Marion", "value": "C136202"},
                 {"label": "Marion County", "value": "C136203"},
                 {"label": "Marks", "value": "C136204"},
                 {"label": "Marshall County", "value": "C136205"},
                 {"label": "Mayersville", "value": "C136206"},
                 {"label": "McComb", "value": "C136207"},
                 {"label": "Meadville", "value": "C136208"},
                 {"label": "Mendenhall", "value": "C136209"},
                 {"label": "Meridian", "value": "C136210"},
                 {"label": "Meridian Station", "value": "C136211"},
                 {"label": "Metcalfe", "value": "C136212"},
                 {"label": "Mikoma", "value": "C136213"},
                 {"label": "Monroe County", "value": "C136214"},
                 {"label": "Montgomery County", "value": "C136215"},
                 {"label": "Monticello", "value": "C136216"},
                 {"label": "Moorhead", "value": "C136217"},
                 {"label": "Morgantown", "value": "C136218"},
                 {"label": "Morton", "value": "C136219"},
                 {"label": "Moss Point", "value": "C136220"},
                 {"label": "Mound Bayou", "value": "C136221"},
                 {"label": "Natchez", "value": "C136222"},
                 {"label": "Nellieburg", "value": "C136223"},
                 {"label": "Neshoba County", "value": "C136224"},
                 {"label": "Nettleton", "value": "C136225"},
                 {"label": "New Albany", "value": "C136226"},
                 {"label": "New Augusta", "value": "C136227"},
                 {"label": "New Hope", "value": "C136228"},
                 {"label": "Newton", "value": "C136229"},
                 {"label": "Newton County", "value": "C136230"},
                 {"label": "Nicholson", "value": "C136231"},
                 {"label": "North Tunica", "value": "C136232"},
                 {"label": "Noxubee County", "value": "C136233"},
                 {"label": "Ocean Springs", "value": "C136234"},
                 {"label": "Okolona", "value": "C136235"},
                 {"label": "Oktibbeha County", "value": "C136236"},
                 {"label": "Olive Branch", "value": "C136237"},
                 {"label": "Oxford", "value": "C136238"},
                 {"label": "Panola County", "value": "C136239"},
                 {"label": "Pascagoula", "value": "C136240"},
                 {"label": "Pass Christian", "value": "C136241"},
                 {"label": "Pearl", "value": "C136242"},
                 {"label": "Pearl River", "value": "C136243"},
                 {"label": "Pearl River County", "value": "C136244"},
                 {"label": "Pearlington", "value": "C136245"},
                 {"label": "Pelahatchie", "value": "C136246"},
                 {"label": "Perry County", "value": "C136247"},
                 {"label": "Petal", "value": "C136248"},
                 {"label": "Philadelphia", "value": "C136249"},
                 {"label": "Picayune", "value": "C136250"},
                 {"label": "Pickens", "value": "C136251"},
                 {"label": "Pike County", "value": "C136252"},
                 {"label": "Pittsboro", "value": "C136253"},
                 {"label": "Plantersville", "value": "C136254"},
                 {"label": "Pontotoc", "value": "C136255"},
                 {"label": "Pontotoc County", "value": "C136256"},
                 {"label": "Poplarville", "value": "C136257"},
                 {"label": "Port Gibson", "value": "C136258"},
                 {"label": "Prentiss", "value": "C136259"},
                 {"label": "Prentiss County", "value": "C136260"},
                 {"label": "Purvis", "value": "C136261"},
                 {"label": "Quitman", "value": "C136262"},
                 {"label": "Quitman County", "value": "C136263"},
                 {"label": "Raleigh", "value": "C136264"},
                 {"label": "Rankin County", "value": "C136265"},
                 {"label": "Rawls Springs", "value": "C136266"},
                 {"label": "Raymond", "value": "C136267"},
                 {"label": "Richland", "value": "C136268"},
                 {"label": "Richton", "value": "C136269"},
                 {"label": "Ridgeland", "value": "C136270"},
                 {"label": "Ripley", "value": "C136271"},
                 {"label": "Rolling Fork", "value": "C136272"},
                 {"label": "Rosedale", "value": "C136273"},
                 {"label": "Ruleville", "value": "C136274"},
                 {"label": "Saint Martin", "value": "C136275"},
                 {"label": "Saltillo", "value": "C136276"},
                 {"label": "Sardis", "value": "C136277"},
                 {"label": "Saucier", "value": "C136278"},
                 {"label": "Scott County", "value": "C136279"},
                 {"label": "Senatobia", "value": "C136280"},
                 {"label": "Shannon", "value": "C136281"},
                 {"label": "Sharkey County", "value": "C136282"},
                 {"label": "Sharon", "value": "C136283"},
                 {"label": "Shaw", "value": "C136284"},
                 {"label": "Shelby", "value": "C136285"},
                 {"label": "Simpson County", "value": "C136286"},
                 {"label": "Smith County", "value": "C136287"},
                 {"label": "Southaven", "value": "C136288"},
                 {"label": "Starkville", "value": "C136289"},
                 {"label": "Stone County", "value": "C136290"},
                 {"label": "Stonewall", "value": "C136291"},
                 {"label": "Summit", "value": "C136292"},
                 {"label": "Sumrall", "value": "C136293"},
                 {"label": "Sunflower", "value": "C136294"},
                 {"label": "Sunflower County", "value": "C136295"},
                 {"label": "Tallahatchie County", "value": "C136296"},
                 {"label": "Tate County", "value": "C136297"},
                 {"label": "Taylorsville", "value": "C136298"},
                 {"label": "Tchula", "value": "C136299"},
                 {"label": "Terry", "value": "C136300"},
                 {"label": "Tippah County", "value": "C136301"},
                 {"label": "Tishomingo County", "value": "C136302"},
                 {"label": "Tunica", "value": "C136303"},
                 {"label": "Tunica County", "value": "C136304"},
                 {"label": "Tunica Resorts", "value": "C136305"},
                 {"label": "Tupelo", "value": "C136306"},
                 {"label": "Tutwiler", "value": "C136307"},
                 {"label": "Tylertown", "value": "C136308"},
                 {"label": "Union", "value": "C136309"},
                 {"label": "Union County", "value": "C136310"},
                 {"label": "University", "value": "C136311"},
                 {"label": "Vancleave", "value": "C136312"},
                 {"label": "Vardaman", "value": "C136313"},
                 {"label": "Verona", "value": "C136314"},
                 {"label": "Vicksburg", "value": "C136315"},
                 {"label": "Wade", "value": "C136316"},
                 {"label": "Walls", "value": "C136317"},
                 {"label": "Walnut Grove", "value": "C136318"},
                 {"label": "Walthall", "value": "C136319"},
                 {"label": "Walthall County", "value": "C136320"},
                 {"label": "Warren County", "value": "C136321"},
                 {"label": "Washington County", "value": "C136322"},
                 {"label": "Water Valley", "value": "C136323"},
                 {"label": "Waveland", "value": "C136324"},
                 {"label": "Wayne County", "value": "C136325"},
                 {"label": "Waynesboro", "value": "C136326"},
                 {"label": "Webster County", "value": "C136327"},
                 {"label": "Wesson", "value": "C136328"},
                 {"label": "West Gulfport", "value": "C136329"},
                 {"label": "West Hattiesburg", "value": "C136330"},
                 {"label": "West Point", "value": "C136331"},
                 {"label": "Wiggins", "value": "C136332"},
                 {"label": "Wilkinson County", "value": "C136333"},
                 {"label": "Winona", "value": "C136334"},
                 {"label": "Winston County", "value": "C136335"},
                 {"label": "Woodville", "value": "C136336"},
                 {"label": "Yalobusha County", "value": "C136337"},
                 {"label": "Yazoo City", "value": "C136338"},
                 {"label": "Yazoo County", "value": "C136339"}],
 "Missouri": [{"label": "Adair County", "value": "C136340"},
              {"label": "Adrian", "value": "C136341"},
              {"label": "Advance", "value": "C136342"},
              {"label": "Affton", "value": "C136343"},
              {"label": "Albany", "value": "C136344"},
              {"label": "Alton", "value": "C136345"},
              {"label": "Anderson", "value": "C136346"},
              {"label": "Andrew County", "value": "C136347"},
              {"label": "Appleton City", "value": "C136348"},
              {"label": "Archie", "value": "C136349"},
              {"label": "Arnold", "value": "C136350"},
              {"label": "Ash Grove", "value": "C136351"},
              {"label": "Ashland", "value": "C136352"},
              {"label": "Atchison County", "value": "C136353"},
              {"label": "Audrain County", "value": "C136354"},
              {"label": "Aurora", "value": "C136355"},
              {"label": "Ava", "value": "C136356"},
              {"label": "Ballwin", "value": "C136357"},
              {"label": "Barnhart", "value": "C136358"},
              {"label": "Barry County", "value": "C136359"},
              {"label": "Barton County", "value": "C136360"},
              {"label": "Bates County", "value": "C136361"},
              {"label": "Battlefield", "value": "C136362"},
              {"label": "Bel-Nor", "value": "C136363"},
              {"label": "Bel-Ridge", "value": "C136364"},
              {"label": "Belle", "value": "C136365"},
              {"label": "Bellefontaine Neighbors", "value": "C136366"},
              {"label": "Belton", "value": "C136367"},
              {"label": "Benton", "value": "C136368"},
              {"label": "Benton County", "value": "C136369"},
              {"label": "Berkeley", "value": "C136370"},
              {"label": "Bernie", "value": "C136371"},
              {"label": "Bethany", "value": "C136372"},
              {"label": "Billings", "value": "C136373"},
              {"label": "Bismarck", "value": "C136374"},
              {"label": "Black Jack", "value": "C136375"},
              {"label": "Bloomfield", "value": "C136376"},
              {"label": "Blue Springs", "value": "C136377"},
              {"label": "Bolivar", "value": "C136378"},
              {"label": "Bollinger County", "value": "C136379"},
              {"label": "Bonne Terre", "value": "C136380"},
              {"label": "Boone County", "value": "C136381"},
              {"label": "Boonville", "value": "C136382"},
              {"label": "Bourbon", "value": "C136383"},
              {"label": "Bowling Green", "value": "C136384"},
              {"label": "Branson", "value": "C136385"},
              {"label": "Breckenridge Hills", "value": "C136386"},
              {"label": "Brentwood", "value": "C136387"},
              {"label": "Bridgeton", "value": "C136388"},
              {"label": "Brookfield", "value": "C136389"},
              {"label": "Buchanan County", "value": "C136390"},
              {"label": "Buckner", "value": "C136391"},
              {"label": "Buffalo", "value": "C136392"},
              {"label": "Butler", "value": "C136393"},
              {"label": "Butler County", "value": "C136394"},
              {"label": "Byrnes Mill", "value": "C136395"},
              {"label": "Cabool", "value": "C136396"},
              {"label": "Caldwell County", "value": "C136397"},
              {"label": "California", "value": "C136398"},
              {"label": "Callaway County", "value": "C136399"},
              {"label": "Calverton Park", "value": "C136400"},
              {"label": "Camden County", "value": "C136401"},
              {"label": "Camdenton", "value": "C136402"},
              {"label": "Cameron", "value": "C136403"},
              {"label": "Campbell", "value": "C136404"},
              {"label": "Canton", "value": "C136405"},
              {"label": "Cape Girardeau", "value": "C136406"},
              {"label": "Cape Girardeau County", "value": "C136407"},
              {"label": "Carl Junction", "value": "C136408"},
              {"label": "Carroll County", "value": "C136409"},
              {"label": "Carrollton", "value": "C136410"},
              {"label": "Carter County", "value": "C136411"},
              {"label": "Carterville", "value": "C136412"},
              {"label": "Carthage", "value": "C136413"},
              {"label": "Caruthersville", "value": "C136414"},
              {"label": "Cass County", "value": "C136415"},
              {"label": "Cassville", "value": "C136416"},
              {"label": "Castle Point", "value": "C136417"},
              {"label": "Cedar County", "value": "C136418"},
              {"label": "Cedar Hill", "value": "C136419"},
              {"label": "Centerville", "value": "C136420"},
              {"label": "Centralia", "value": "C136421"},
              {"label": "Chaffee", "value": "C136422"},
              {"label": "Chariton County", "value": "C136423"},
              {"label": "Charlack", "value": "C136424"},
              {"label": "Charleston", "value": "C136425"},
              {"label": "Chesterfield", "value": "C136426"},
              {"label": "Chillicothe", "value": "C136427"},
              {"label": "Christian County", "value": "C136428"},
              {"label": "City of Saint Louis", "value": "C136429"},
              {"label": "Clark County", "value": "C136430"},
              {"label": "Clarkson Valley", "value": "C136431"},
              {"label": "Clarkton", "value": "C136432"},
              {"label": "Clay County", "value": "C136433"},
              {"label": "Claycomo", "value": "C136434"},
              {"label": "Clayton", "value": "C136435"},
              {"label": "Clever", "value": "C136436"},
              {"label": "Clinton", "value": "C136437"},
              {"label": "Clinton County", "value": "C136438"},
              {"label": "Cole Camp", "value": "C136439"},
              {"label": "Cole County", "value": "C136440"},
              {"label": "Columbia", "value": "C136441"},
              {"label": "Concord", "value": "C136442"},
              {"label": "Concordia", "value": "C136443"},
              {"label": "Cool Valley", "value": "C136444"},
              {"label": "Cooper County", "value": "C136445"},
              {"label": "Cottleville", "value": "C136446"},
              {"label": "Country Club Hills", "value": "C136447"},
              {"label": "Country Club Village", "value": "C136448"},
              {"label": "Crane", "value": "C136449"},
              {"label": "Crawford County", "value": "C136450"},
              {"label": "Crestwood", "value": "C136451"},
              {"label": "Creve Coeur", "value": "C136452"},
              {"label": "Crocker", "value": "C136453"},
              {"label": "Crystal City", "value": "C136454"},
              {"label": "Cuba", "value": "C136455"},
              {"label": "Dade County", "value": "C136456"},
              {"label": "Dallas County", "value": "C136457"},
              {"label": "Dardenne Prairie", "value": "C136458"},
              {"label": "Daviess County", "value": "C136459"},
              {"label": "De Soto", "value": "C136460"},
              {"label": "DeKalb County", "value": "C136461"},
              {"label": "Dellwood", "value": "C136462"},
              {"label": "Dent County", "value": "C136463"},
              {"label": "Des Peres", "value": "C136464"},
              {"label": "Desloge", "value": "C136465"},
              {"label": "Dexter", "value": "C136466"},
              {"label": "Dixon", "value": "C136467"},
              {"label": "Doniphan", "value": "C136468"},
              {"label": "Douglas County", "value": "C136469"},
              {"label": "Duenweg", "value": "C136470"},
              {"label": "Dunklin County", "value": "C136471"},
              {"label": "Duquesne", "value": "C136472"},
              {"label": "East Independence", "value": "C136473"},
              {"label": "East Prairie", "value": "C136474"},
              {"label": "Edina", "value": "C136475"},
              {"label": "El Dorado Springs", "value": "C136476"},
              {"label": "Eldon", "value": "C136477"},
              {"label": "Ellisville", "value": "C136478"},
              {"label": "Elsberry", "value": "C136479"},
              {"label": "Elvins", "value": "C136480"},
              {"label": "Eminence", "value": "C136481"},
              {"label": "Esther", "value": "C136482"},
              {"label": "Eureka", "value": "C136483"},
              {"label": "Excelsior Springs", "value": "C136484"},
              {"label": "Fair Grove", "value": "C136485"},
              {"label": "Farmington", "value": "C136486"},
              {"label": "Fayette", "value": "C136487"},
              {"label": "Fenton", "value": "C136488"},
              {"label": "Ferguson", "value": "C136489"},
              {"label": "Festus", "value": "C136490"},
              {"label": "Flat River", "value": "C136491"},
              {"label": "Florissant", "value": "C136492"},
              {"label": "Forsyth", "value": "C136493"},
              {"label": "Fort Leonard Wood", "value": "C136494"},
              {"label": "Four Seasons", "value": "C136495"},
              {"label": "Franklin County", "value": "C136496"},
              {"label": "Fredericktown", "value": "C136497"},
              {"label": "Frontenac", "value": "C136498"},
              {"label": "Fulton", "value": "C136499"},
              {"label": "Gainesville", "value": "C136500"},
              {"label": "Galena", "value": "C136501"},
              {"label": "Gallatin", "value": "C136502"},
              {"label": "Garden City", "value": "C136503"},
              {"label": "Gasconade County", "value": "C136504"},
              {"label": "Gentry County", "value": "C136505"},
              {"label": "Gerald", "value": "C136506"},
              {"label": "Gideon", "value": "C136507"},
              {"label": "Gladstone", "value": "C136508"},
              {"label": "Glasgow", "value": "C136509"},
              {"label": "Glasgow Village", "value": "C136510"},
              {"label": "Glendale", "value": "C136511"},
              {"label": "Goodman", "value": "C136512"},
              {"label": "Gower", "value": "C136513"},
              {"label": "Grain Valley", "value": "C136514"},
              {"label": "Granby", "value": "C136515"},
              {"label": "Grandview", "value": "C136516"},
              {"label": "Grant City", "value": "C136517"},
              {"label": "Gray Summit", "value": "C136518"},
              {"label": "Green Park", "value": "C136519"},
              {"label": "Greene County", "value": "C136520"},
              {"label": "Greenfield", "value": "C136521"},
              {"label": "Greenville", "value": "C136522"},
              {"label": "Greenwood", "value": "C136523"},
              {"label": "Grundy County", "value": "C136524"},
              {"label": "Hallsville", "value": "C136525"},
              {"label": "Hamilton", "value": "C136526"},
              {"label": "Hanley Hills", "value": "C136527"},
              {"label": "Hannibal", "value": "C136528"},
              {"label": "Harrison County", "value": "C136529"},
              {"label": "Harrisonville", "value": "C136530"},
              {"label": "Hartville", "value": "C136531"},
              {"label": "Hayti", "value": "C136532"},
              {"label": "Hazelwood", "value": "C136533"},
              {"label": "Henry County", "value": "C136534"},
              {"label": "Herculaneum", "value": "C136535"},
              {"label": "Hermann", "value": "C136536"},
              {"label": "Hermitage", "value": "C136537"},
              {"label": "Hickory County", "value": "C136538"},
              {"label": "Higginsville", "value": "C136539"},
              {"label": "High Ridge", "value": "C136540"},
              {"label": "Hillsboro", "value": "C136541"},
              {"label": "Hillsdale", "value": "C136542"},
              {"label": "Holden", "value": "C136543"},
              {"label": "Hollister", "value": "C136544"},
              {"label": "Holt County", "value": "C136545"},
              {"label": "Holts Summit", "value": "C136546"},
              {"label": "Houston", "value": "C136547"},
              {"label": "Howard County", "value": "C136548"},
              {"label": "Howell County", "value": "C136549"},
              {"label": "Humansville", "value": "C136550"},
              {"label": "Huntsville", "value": "C136551"},
              {"label": "Imperial", "value": "C136552"},
              {"label": "Independence", "value": "C136553"},
              {"label": "Iron County", "value": "C136554"},
              {"label": "Ironton", "value": "C136555"},
              {"label": "Jackson", "value": "C136556"},
              {"label": "Jackson County", "value": "C136557"},
              {"label": "Jasper County", "value": "C136558"},
              {"label": "Jefferson City", "value": "C136559"},
              {"label": "Jefferson County", "value": "C136560"},
              {"label": "Jennings", "value": "C136561"},
              {"label": "Johnson County", "value": "C136562"},
              {"label": "Joplin", "value": "C136563"},
              {"label": "Kahoka", "value": "C136564"},
              {"label": "Kansas City", "value": "C136565"},
              {"label": "Kearney", "value": "C136566"},
              {"label": "Kennett", "value": "C136567"},
              {"label": "Keytesville", "value": "C136568"},
              {"label": "Kimberling City", "value": "C136569"},
              {"label": "King City", "value": "C136570"},
              {"label": "Kingston", "value": "C136571"},
              {"label": "Kirksville", "value": "C136572"},
              {"label": "Kirkwood", "value": "C136573"},
              {"label": "Kissee Mills", "value": "C136574"},
              {"label": "Knob Noster", "value": "C136575"},
              {"label": "Knox County", "value": "C136576"},
              {"label": "La Monte", "value": "C136577"},
              {"label": "La Plata", "value": "C136578"},
              {"label": "LaBarque Creek", "value": "C136579"},
              {"label": "Laclede County", "value": "C136580"},
              {"label": "Ladue", "value": "C136581"},
              {"label": "Lafayette County", "value": "C136582"},
              {"label": "Lake Lotawana", "value": "C136583"},
              {"label": "Lake Ozark", "value": "C136584"},
              {"label": "Lake Saint Louis", "value": "C136585"},
              {"label": "Lake Winnebago", "value": "C136586"},
              {"label": "Lakeshire", "value": "C136587"},
              {"label": "Lamar", "value": "C136588"},
              {"label": "Lancaster", "value": "C136589"},
              {"label": "Lathrop", "value": "C136590"},
              {"label": "Lawrence County", "value": "C136591"},
              {"label": "Lawson", "value": "C136592"},
              {"label": "Leadwood", "value": "C136593"},
              {"label": "Lebanon", "value": "C136594"},
              {"label": "Lees Summit", "value": "C136595"},
              {"label": "Lemay", "value": "C136596"},
              {"label": "Lewis County", "value": "C136597"},
              {"label": "Lexington", "value": "C136598"},
              {"label": "Liberty", "value": "C136599"},
              {"label": "Licking", "value": "C136600"},
              {"label": "Lilbourn", "value": "C136601"},
              {"label": "Lincoln", "value": "C136602"},
              {"label": "Lincoln County", "value": "C136603"},
              {"label": "Linn", "value": "C136604"},
              {"label": "Linn County", "value": "C136605"},
              {"label": "Linneus", "value": "C136606"},
              {"label": "Livingston County", "value": "C136607"},
              {"label": "Lone Jack", "value": "C136608"},
              {"label": "Louisiana", "value": "C136609"},
              {"label": "Macon", "value": "C136610"},
              {"label": "Macon County", "value": "C136611"},
              {"label": "Madison County", "value": "C136612"},
              {"label": "Malden", "value": "C136613"},
              {"label": "Manchester", "value": "C136614"},
              {"label": "Mansfield", "value": "C136615"},
              {"label": "Maplewood", "value": "C136616"},
              {"label": "Marble Hill", "value": "C136617"},
              {"label": "Marceline", "value": "C136618"},
              {"label": "Maries County", "value": "C136619"},
              {"label": "Marion County", "value": "C136620"},
              {"label": "Marionville", "value": "C136621"},
              {"label": "Marlborough", "value": "C136622"},
              {"label": "Marshall", "value": "C136623"},
              {"label": "Marshfield", "value": "C136624"},
              {"label": "Marthasville", "value": "C136625"},
              {"label": "Maryland Heights", "value": "C136626"},
              {"label": "Maryville", "value": "C136627"},
              {"label": "Maysville", "value": "C136628"},
              {"label": "McDonald County", "value": "C136629"},
              {"label": "Mehlville", "value": "C136630"},
              {"label": "Memphis", "value": "C136631"},
              {"label": "Mercer County", "value": "C136632"},
              {"label": "Merriam Woods", "value": "C136633"},
              {"label": "Mexico", "value": "C136634"},
              {"label": "Milan", "value": "C136635"},
              {"label": "Miller County", "value": "C136636"},
              {"label": "Mississippi County", "value": "C136637"},
              {"label": "Moberly", "value": "C136638"},
              {"label": "Moline Acres", "value": "C136639"},
              {"label": "Monett", "value": "C136640"},
              {"label": "Moniteau County", "value": "C136641"},
              {"label": "Monroe City", "value": "C136642"},
              {"label": "Monroe County", "value": "C136643"},
              {"label": "Montgomery City", "value": "C136644"},
              {"label": "Montgomery County", "value": "C136645"},
              {"label": "Monticello", "value": "C136646"},
              {"label": "Morgan County", "value": "C136647"},
              {"label": "Moscow Mills", "value": "C136648"},
              {"label": "Mound City", "value": "C136649"},
              {"label": "Mount Vernon", "value": "C136650"},
              {"label": "Mountain Grove", "value": "C136651"},
              {"label": "Mountain View", "value": "C136652"},
              {"label": "Murphy", "value": "C136653"},
              {"label": "Neosho", "value": "C136654"},
              {"label": "Nevada", "value": "C136655"},
              {"label": "New Franklin", "value": "C136656"},
              {"label": "New Haven", "value": "C136657"},
              {"label": "New London", "value": "C136658"},
              {"label": "New Madrid", "value": "C136659"},
              {"label": "New Madrid County", "value": "C136660"},
              {"label": "Newton County", "value": "C136661"},
              {"label": "Nixa", "value": "C136662"},
              {"label": "Nodaway County", "value": "C136663"},
              {"label": "Noel", "value": "C136664"},
              {"label": "Normandy", "value": "C136665"},
              {"label": "North Kansas City", "value": "C136666"},
              {"label": "Northwoods", "value": "C136667"},
              {"label": "OFallon", "value": "C136668"},
              {"label": "Oak Grove", "value": "C136669"},
              {"label": "Oakland", "value": "C136670"},
              {"label": "Oakville", "value": "C136671"},
              {"label": "Odessa", "value": "C136672"},
              {"label": "Old Jamestown", "value": "C136673"},
              {"label": "Olivette", "value": "C136674"},
              {"label": "Oran", "value": "C136675"},
              {"label": "Oregon", "value": "C136676"},
              {"label": "Oregon County", "value": "C136677"},
              {"label": "Oronogo", "value": "C136678"},
              {"label": "Osage Beach", "value": "C136679"},
              {"label": "Osage County", "value": "C136680"},
              {"label": "Osceola", "value": "C136681"},
              {"label": "Overland", "value": "C136682"},
              {"label": "Owensville", "value": "C136683"},
              {"label": "Ozark", "value": "C136684"},
              {"label": "Ozark County", "value": "C136685"},
              {"label": "Pacific", "value": "C136686"},
              {"label": "Pagedale", "value": "C136687"},
              {"label": "Palmyra", "value": "C136688"},
              {"label": "Paris", "value": "C136689"},
              {"label": "Park Hills", "value": "C136690"},
              {"label": "Parkville", "value": "C136691"},
              {"label": "Peculiar", "value": "C136692"},
              {"label": "Pemiscot County", "value": "C136693"},
              {"label": "Perry County", "value": "C136694"},
              {"label": "Perryville", "value": "C136695"},
              {"label": "Pettis County", "value": "C136696"},
              {"label": "Pevely", "value": "C136697"},
              {"label": "Phelps County", "value": "C136698"},
              {"label": "Piedmont", "value": "C136699"},
              {"label": "Pierce City", "value": "C136700"},
              {"label": "Pike County", "value": "C136701"},
              {"label": "Pine Lawn", "value": "C136702"},
              {"label": "Pineville", "value": "C136703"},
              {"label": "Platte City", "value": "C136704"},
              {"label": "Platte County", "value": "C136705"},
              {"label": "Plattsburg", "value": "C136706"},
              {"label": "Pleasant Hill", "value": "C136707"},
              {"label": "Pleasant Valley", "value": "C136708"},
              {"label": "Polk County", "value": "C136709"},
              {"label": "Poplar Bluff", "value": "C136710"},
              {"label": "Portageville", "value": "C136711"},
              {"label": "Potosi", "value": "C136712"},
              {"label": "Princeton", "value": "C136713"},
              {"label": "Pulaski County", "value": "C136714"},
              {"label": "Purdy", "value": "C136715"},
              {"label": "Putnam County", "value": "C136716"},
              {"label": "Ralls County", "value": "C136717"},
              {"label": "Randolph County", "value": "C136718"},
              {"label": "Ray County", "value": "C136719"},
              {"label": "Raymore", "value": "C136720"},
              {"label": "Raytown", "value": "C136721"},
              {"label": "Republic", "value": "C136722"},
              {"label": "Reynolds County", "value": "C136723"},
              {"label": "Rich Hill", "value": "C136724"},
              {"label": "Richland", "value": "C136725"},
              {"label": "Richmond", "value": "C136726"},
              {"label": "Richmond Heights", "value": "C136727"},
              {"label": "Ripley County", "value": "C136728"},
              {"label": "Riverside", "value": "C136729"},
              {"label": "Riverview", "value": "C136730"},
              {"label": "Rock Hill", "value": "C136731"},
              {"label": "Rock Port", "value": "C136732"},
              {"label": "Rogersville", "value": "C136733"},
              {"label": "Rolla", "value": "C136734"},
              {"label": "Saint Ann", "value": "C136735"},
              {"label": "Saint Charles", "value": "C136736"},
              {"label": "Saint Charles County", "value": "C136737"},
              {"label": "Saint Clair", "value": "C136738"},
              {"label": "Saint Clair County", "value": "C136739"},
              {"label": "Saint Francois County", "value": "C136740"},
              {"label": "Saint George", "value": "C136741"},
              {"label": "Saint James", "value": "C136742"},
              {"label": "Saint John", "value": "C136743"},
              {"label": "Saint Johns", "value": "C136744"},
              {"label": "Saint Joseph", "value": "C136745"},
              {"label": "Saint Louis County", "value": "C136746"},
              {"label": "Saint Martins", "value": "C136747"},
              {"label": "Saint Paul", "value": "C136748"},
              {"label": "Saint Peters", "value": "C136749"},
              {"label": "Saint Robert", "value": "C136750"},
              {"label": "Sainte Genevieve", "value": "C136751"},
              {"label": "Sainte Genevieve County", "value": "C136752"},
              {"label": "Salem", "value": "C136753"},
              {"label": "Saline County", "value": "C136754"},
              {"label": "Salisbury", "value": "C136755"},
              {"label": "Sappington", "value": "C136756"},
              {"label": "Sarcoxie", "value": "C136757"},
              {"label": "Savannah", "value": "C136758"},
              {"label": "Schuyler County", "value": "C136759"},
              {"label": "Scotland County", "value": "C136760"},
              {"label": "Scott City", "value": "C136761"},
              {"label": "Scott County", "value": "C136762"},
              {"label": "Sedalia", "value": "C136763"},
              {"label": "Senath", "value": "C136764"},
              {"label": "Seneca", "value": "C136765"},
              {"label": "Seymour", "value": "C136766"},
              {"label": "Shannon County", "value": "C136767"},
              {"label": "Shelbina", "value": "C136768"},
              {"label": "Shelby County", "value": "C136769"},
              {"label": "Shelbyville", "value": "C136770"},
              {"label": "Shell Knob", "value": "C136771"},
              {"label": "Shrewsbury", "value": "C136772"},
              {"label": "Sikeston", "value": "C136773"},
              {"label": "Slater", "value": "C136774"},
              {"label": "Smithville", "value": "C136775"},
              {"label": "Spanish Lake", "value": "C136776"},
              {"label": "Sparta", "value": "C136777"},
              {"label": "Springfield", "value": "C136778"},
              {"label": "St. Louis", "value": "C136779"},
              {"label": "Stanberry", "value": "C136780"},
              {"label": "Steele", "value": "C136781"},
              {"label": "Steelville", "value": "C136782"},
              {"label": "Stockton", "value": "C136783"},
              {"label": "Stoddard County", "value": "C136784"},
              {"label": "Stone County", "value": "C136785"},
              {"label": "Stover", "value": "C136786"},
              {"label": "Strafford", "value": "C136787"},
              {"label": "Sugar Creek", "value": "C136788"},
              {"label": "Sullivan", "value": "C136789"},
              {"label": "Sullivan County", "value": "C136790"},
              {"label": "Sunset Hills", "value": "C136791"},
              {"label": "Sweet Springs", "value": "C136792"},
              {"label": "Taney County", "value": "C136793"},
              {"label": "Taos", "value": "C136794"},
              {"label": "Tarkio", "value": "C136795"},
              {"label": "Terre du Lac", "value": "C136796"},
              {"label": "Terre Haute", "value": "C136797"},
              {"label": "Texas County", "value": "C136798"},
              {"label": "Thayer", "value": "C136799"},
              {"label": "Tipton", "value": "C136800"},
              {"label": "Town and Country", "value": "C136801"},
              {"label": "Trenton", "value": "C136802"},
              {"label": "Troy", "value": "C136803"},
              {"label": "Tuscumbia", "value": "C136804"},
              {"label": "Union", "value": "C136805"},
              {"label": "Unionville", "value": "C136806"},
              {"label": "University City", "value": "C136807"},
              {"label": "Valley Park", "value": "C136808"},
              {"label": "Van Buren", "value": "C136809"},
              {"label": "Vandalia", "value": "C136810"},
              {"label": "Velda Village", "value": "C136811"},
              {"label": "Velda Village Hills", "value": "C136812"},
              {"label": "Vernon County", "value": "C136813"},
              {"label": "Versailles", "value": "C136814"},
              {"label": "Vienna", "value": "C136815"},
              {"label": "Villa Ridge", "value": "C136816"},
              {"label": "Vinita Park", "value": "C136817"},
              {"label": "Wardsville", "value": "C136818"},
              {"label": "Warren County", "value": "C136819"},
              {"label": "Warrensburg", "value": "C136820"},
              {"label": "Warrenton", "value": "C136821"},
              {"label": "Warsaw", "value": "C136822"},
              {"label": "Warson Woods", "value": "C136823"},
              {"label": "Washington", "value": "C136824"},
              {"label": "Washington County", "value": "C136825"},
              {"label": "Wayne County", "value": "C136826"},
              {"label": "Waynesville", "value": "C136827"},
              {"label": "Weatherby Lake", "value": "C136828"},
              {"label": "Webb City", "value": "C136829"},
              {"label": "Webster County", "value": "C136830"},
              {"label": "Webster Groves", "value": "C136831"},
              {"label": "Weldon Spring", "value": "C136832"},
              {"label": "Wellston", "value": "C136833"},
              {"label": "Wellsville", "value": "C136834"},
              {"label": "Wentzville", "value": "C136835"},
              {"label": "West Plains", "value": "C136836"},
              {"label": "Weston", "value": "C136837"},
              {"label": "Whiteman Air Force Base", "value": "C136838"},
              {"label": "Wildwood", "value": "C136839"},
              {"label": "Willard", "value": "C136840"},
              {"label": "Willow Springs", "value": "C136841"},
              {"label": "Winchester", "value": "C136842"},
              {"label": "Windsor", "value": "C136843"},
              {"label": "Winfield", "value": "C136844"},
              {"label": "Winona", "value": "C136845"},
              {"label": "Woodson Terrace", "value": "C136846"},
              {"label": "Worth County", "value": "C136847"},
              {"label": "Wright City", "value": "C136848"},
              {"label": "Wright County", "value": "C136849"}],
 "Miyagi Prefecture": [{"label": "Furukawa", "value": "C63474"},
                       {"label": "Higashimatshushima Shi", "value": "C63475"},
                       {"label": "Higashimatsushima", "value": "C63476"},
                       {"label": "Ishinomaki", "value": "C63477"},
                       {"label": "Ishinomaki Shi", "value": "C63478"},
                       {"label": "Iwanuma", "value": "C63479"},
                       {"label": "Iwanuma-shi", "value": "C63480"},
                       {"label": "Kakuda", "value": "C63481"},
                       {"label": "Kakuda Shi", "value": "C63482"},
                       {"label": "Kesennuma", "value": "C63483"},
                       {"label": "Kesennuma Shi", "value": "C63484"},
                       {"label": "Kogota", "value": "C63485"},
                       {"label": "Kurihara", "value": "C63486"},
                       {"label": "Kurihara Shi", "value": "C63487"},
                       {"label": "Marumori", "value": "C63488"},
                       {"label": "Matsushima", "value": "C63489"},
                       {"label": "Natori Shi", "value": "C63490"},
                       {"label": "Okawara", "value": "C63491"},
                       {"label": "Onagawa Cho", "value": "C63492"},
                       {"label": "Osaki", "value": "C63493"},
                       {"label": "Osaki Shi", "value": "C63494"},
                       {"label": "Rifu", "value": "C63495"},
                       {"label": "Sendai", "value": "C63496"},
                       {"label": "Shiogama", "value": "C63497"},
                       {"label": "Shiroishi", "value": "C63498"},
                       {"label": "Shiroishi Shi", "value": "C63499"},
                       {"label": "Tagajo Shi", "value": "C63500"},
                       {"label": "Tome Shi", "value": "C63501"},
                       {"label": "Tomiya", "value": "C63502"},
                       {"label": "Wakuya", "value": "C63503"},
                       {"label": "Watari", "value": "C63504"},
                       {"label": "Watari-gun", "value": "C63505"},
                       {"label": "Yamoto", "value": "C63506"}],
 "Miyazaki Prefecture": [{"label": "Ebino-shi", "value": "C63507"},
                         {"label": "Hyuga-shi", "value": "C63508"},
                         {"label": "Kobayashi", "value": "C63509"},
                         {"label": "Kobayashi Shi", "value": "C63510"},
                         {"label": "Kushima", "value": "C63511"},
                         {"label": "Kushima Shi", "value": "C63512"},
                         {"label": "Miyakonojo", "value": "C63513"},
                         {"label": "Miyakonojo Shi", "value": "C63514"},
                         {"label": "Miyazaki", "value": "C63515"},
                         {"label": "Miyazaki-shi", "value": "C63516"},
                         {"label": "Nichinan", "value": "C63517"},
                         {"label": "Nichinan Shi", "value": "C63518"},
                         {"label": "Nobeoka", "value": "C63519"},
                         {"label": "Nobeoka-shi", "value": "C63520"},
                         {"label": "Saito-shi", "value": "C63521"},
                         {"label": "Takanabe", "value": "C63522"},
                         {"label": "Tsuma", "value": "C63523"}],
 "Mizoram": [{"label": "Aizawl", "value": "C47285"},
             {"label": "Champhai", "value": "C47286"},
             {"label": "Darlawn", "value": "C47287"},
             {"label": "Khawhai", "value": "C47288"},
             {"label": "Kolasib", "value": "C47289"},
             {"label": "Kolasib district", "value": "C47290"},
             {"label": "Lawngtlai", "value": "C47291"},
             {"label": "Lunglei", "value": "C47292"},
             {"label": "Mamit", "value": "C47293"},
             {"label": "North Vanlaiphai", "value": "C47294"},
             {"label": "Saiha", "value": "C47295"},
             {"label": "Sairang", "value": "C47296"},
             {"label": "Saitlaw", "value": "C47297"},
             {"label": "Serchhip", "value": "C47298"},
             {"label": "Thenzawl", "value": "C47299"}],
 "Mogila Municipality": [{"label": "Beranci", "value": "C65462"},
                         {"label": "Dobrusevo", "value": "C65463"},
                         {"label": "Dolno Srpci", "value": "C65464"},
                         {"label": "Mogila", "value": "C65465"}],
 "Mogilev Region": [{"label": "Asipovichy", "value": "C8936"},
                    {"label": "Asipovitski Rayon", "value": "C8937"},
                    {"label": "Babruysk", "value": "C8938"},
                    {"label": "Babruyski Rayon", "value": "C8939"},
                    {"label": "Buynichy", "value": "C8940"},
                    {"label": "Byalynichy", "value": "C8941"},
                    {"label": "Byalynitski Rayon", "value": "C8942"},
                    {"label": "Bykhaw", "value": "C8943"},
                    {"label": "Chavuski Rayon", "value": "C8944"},
                    {"label": "Chavusy", "value": "C8945"},
                    {"label": "Cherykaw", "value": "C8946"},
                    {"label": "Cherykawski Rayon", "value": "C8947"},
                    {"label": "Dashkawka", "value": "C8948"},
                    {"label": "Drybin", "value": "C8949"},
                    {"label": "Drybinski Rayon", "value": "C8950"},
                    {"label": "Hlusha", "value": "C8951"},
                    {"label": "Hlusk", "value": "C8952"},
                    {"label": "Horatski Rayon", "value": "C8953"},
                    {"label": "Horki", "value": "C8954"},
                    {"label": "Kadino", "value": "C8955"},
                    {"label": "Kamyennyya Lavy", "value": "C8956"},
                    {"label": "Kastsyukovichy", "value": "C8957"},
                    {"label": "Khodasy", "value": "C8958"},
                    {"label": "Khotsimsk", "value": "C8959"},
                    {"label": "Khotsimski Rayon", "value": "C8960"},
                    {"label": "Kirawsk", "value": "C8961"},
                    {"label": "Klichaw", "value": "C8962"},
                    {"label": "Klimavichy", "value": "C8963"},
                    {"label": "Krasnapolski Rayon", "value": "C8964"},
                    {"label": "Krasnapollye", "value": "C8965"},
                    {"label": "Krasnyy Bereg", "value": "C8966"},
                    {"label": "Kruhlaye", "value": "C8967"},
                    {"label": "Krychaw", "value": "C8968"},
                    {"label": "Mahilyow", "value": "C8969"},
                    {"label": "Mahilyowski Rayon", "value": "C8970"},
                    {"label": "Mstsislaw", "value": "C8971"},
                    {"label": "Myazhysyatki", "value": "C8972"},
                    {"label": "Myshkavichy", "value": "C8973"},
                    {"label": "Palykavichy Pyershyya", "value": "C8974"},
                    {"label": "Poselok Voskhod", "value": "C8975"},
                    {"label": "Ramanavichy", "value": "C8976"},
                    {"label": "Shklow", "value": "C8977"},
                    {"label": "Shklowski Rayon", "value": "C8978"},
                    {"label": "Slawharad", "value": "C8979"},
                    {"label": "Veyno", "value": "C8980"},
                    {"label": "Vishow", "value": "C8981"},
                    {"label": "Yalizava", "value": "C8982"}],
 "Mohales Hoek District": [{"label": "Mohales Hoek", "value": "C64997"}],
 "Moheli": [{"label": "Djoyezi", "value": "C20739"},
            {"label": "Fomboni", "value": "C20740"},
            {"label": "Hoani", "value": "C20741"},
            {"label": "Mtakoudja", "value": "C20742"},
            {"label": "Nioumachoua", "value": "C20743"},
            {"label": "Ouanani", "value": "C20744"},
            {"label": "Ziroudani", "value": "C20745"}],
 "Mojkovac Municipality": [{"label": "Mojkovac", "value": "C75611"}],
 "Moka District": [{"label": "Camp Thorel", "value": "C66008"},
                   {"label": "Dagotiere", "value": "C66009"},
                   {"label": "Dubreuil", "value": "C66010"},
                   {"label": "Melrose", "value": "C66011"},
                   {"label": "Moka", "value": "C66012"},
                   {"label": "Pailles", "value": "C66013"},
                   {"label": "Providence", "value": "C66014"},
                   {"label": "Quartier Militaire", "value": "C66015"},
                   {"label": "Saint Pierre", "value": "C66016"},
                   {"label": "Verdun", "value": "C66017"}],
 "Mokhotlong District": [{"label": "Mokhotlong", "value": "C64998"}],
 "Molise": [{"label": "Acquaviva Collecroce", "value": "C57519"},
            {"label": "Acquaviva dIsernia", "value": "C57520"},
            {"label": "Agnone", "value": "C57521"},
            {"label": "Bagnoli del Trigno", "value": "C57522"},
            {"label": "Baranello", "value": "C57523"},
            {"label": "Belmonte del Sannio", "value": "C57524"},
            {"label": "Bojano", "value": "C57525"},
            {"label": "Bonefro", "value": "C57526"},
            {"label": "Busso", "value": "C57527"},
            {"label": "Campobasso", "value": "C57528"},
            {"label": "Campochiaro", "value": "C57529"},
            {"label": "Campodipietra", "value": "C57530"},
            {"label": "Campolieto", "value": "C57531"},
            {"label": "Campomarino", "value": "C57532"},
            {"label": "Cantalupo nel Sannio", "value": "C57533"},
            {"label": "Capracotta", "value": "C57534"},
            {"label": "Carovilli", "value": "C57535"},
            {"label": "Carpinone", "value": "C57536"},
            {"label": "Casacalenda", "value": "C57537"},
            {"label": "Casalciprano", "value": "C57538"},
            {"label": "Castel del Giudice", "value": "C57539"},
            {"label": "Castel San Vincenzo", "value": "C57540"},
            {"label": "Castelbottaccio", "value": "C57541"},
            {"label": "Castellino del Biferno", "value": "C57542"},
            {"label": "Castelmauro", "value": "C57543"},
            {"label": "Castelpetroso", "value": "C57544"},
            {"label": "Castelpizzuto", "value": "C57545"},
            {"label": "Castelverrino", "value": "C57546"},
            {"label": "Castropignano", "value": "C57547"},
            {"label": "Cercemaggiore", "value": "C57548"},
            {"label": "Cercepiccola", "value": "C57549"},
            {"label": "Cerro al Volturno", "value": "C57550"},
            {"label": "Chiauci", "value": "C57551"},
            {"label": "Civitacampomarano", "value": "C57552"},
            {"label": "Civitanova del Sannio", "value": "C57553"},
            {"label": "Colle dAnchise", "value": "C57554"},
            {"label": "Colletorto", "value": "C57555"},
            {"label": "Colli a Volturno", "value": "C57556"},
            {"label": "Conca Casale", "value": "C57557"},
            {"label": "Duronia", "value": "C57558"},
            {"label": "Ferrazzano", "value": "C57559"},
            {"label": "Filignano", "value": "C57560"},
            {"label": "Forli del Sannio", "value": "C57561"},
            {"label": "Fornelli", "value": "C57562"},
            {"label": "Fossalto", "value": "C57563"},
            {"label": "Frosolone", "value": "C57564"},
            {"label": "Gambatesa", "value": "C57565"},
            {"label": "Gildone", "value": "C57566"},
            {"label": "Guardialfiera", "value": "C57567"},
            {"label": "Guardiaregia", "value": "C57568"},
            {"label": "Guglionesi", "value": "C57569"},
            {"label": "Isernia", "value": "C57570"},
            {"label": "Jelsi", "value": "C57571"},
            {"label": "Larino", "value": "C57572"},
            {"label": "Limosano", "value": "C57573"},
            {"label": "Longano", "value": "C57574"},
            {"label": "Lucito", "value": "C57575"},
            {"label": "Lupara", "value": "C57576"},
            {"label": "Macchia dIsernia", "value": "C57577"},
            {"label": "Macchia Valfortore", "value": "C57578"},
            {"label": "Macchiagodena", "value": "C57579"},
            {"label": "Mafalda", "value": "C57580"},
            {"label": "Matrice", "value": "C57581"},
            {"label": "Mimosa-Poggio Verde-Nuova Comunita", "value": "C57582"},
            {"label": "Mirabello Sannitico", "value": "C57583"},
            {"label": "Miranda", "value": "C57584"},
            {"label": "Molise", "value": "C57585"},
            {"label": "Monacilioni", "value": "C57586"},
            {"label": "Montagano", "value": "C57587"},
            {"label": "Montaquila", "value": "C57588"},
            {"label": "Montecilfone", "value": "C57589"},
            {"label": "Montefalcone nel Sannio", "value": "C57590"},
            {"label": "Montelongo", "value": "C57591"},
            {"label": "Montemitro", "value": "C57592"},
            {"label": "Montenero di Bisaccia", "value": "C57593"},
            {"label": "Montenero Val Cocchiara", "value": "C57594"},
            {"label": "Monteroduni", "value": "C57595"},
            {"label": "Montorio Nei Frentani", "value": "C57596"},
            {"label": "Morrone del Sannio", "value": "C57597"},
            {"label": "Oratino", "value": "C57598"},
            {"label": "Palata", "value": "C57599"},
            {"label": "Pesche", "value": "C57600"},
            {"label": "Pescolanciano", "value": "C57601"},
            {"label": "Pescopennataro", "value": "C57602"},
            {"label": "Petacciato", "value": "C57603"},
            {"label": "Petrella Tifernina", "value": "C57604"},
            {"label": "Pettoranello del Molise", "value": "C57605"},
            {"label": "Pietrabbondante", "value": "C57606"},
            {"label": "Pietracatella", "value": "C57607"},
            {"label": "Pietracupa", "value": "C57608"},
            {"label": "Pizzone", "value": "C57609"},
            {"label": "Poggio Sannita", "value": "C57610"},
            {"label": "Portocannone", "value": "C57611"},
            {"label": "Pozzilli", "value": "C57612"},
            {"label": "Provincia di Campobasso", "value": "C57613"},
            {"label": "Provincia di Isernia", "value": "C57614"},
            {"label": "Provvidenti", "value": "C57615"},
            {"label": "Riccia", "value": "C57616"},
            {"label": "Rionero Sannitico", "value": "C57617"},
            {"label": "Ripabottoni", "value": "C57618"},
            {"label": "Ripalimosani", "value": "C57619"},
            {"label": "Roccamandolfi", "value": "C57620"},
            {"label": "Roccasicura", "value": "C57621"},
            {"label": "Roccavivara", "value": "C57622"},
            {"label": "Rocchetta a Volturno", "value": "C57623"},
            {"label": "Rocchetta Nuova", "value": "C57624"},
            {"label": "Rotello", "value": "C57625"},
            {"label": "Salcito", "value": "C57626"},
            {"label": "San Biase", "value": "C57627"},
            {"label": "San Felice del Molise", "value": "C57628"},
            {"label": "San Giacomo degli Schiavoni", "value": "C57629"},
            {"label": "San Giovanni in Galdo", "value": "C57630"},
            {"label": "San Giuliano del Sannio", "value": "C57631"},
            {"label": "San Giuliano di Puglia", "value": "C57632"},
            {"label": "San Martino in Pensilis", "value": "C57633"},
            {"label": "San Massimo", "value": "C57634"},
            {"label": "San Pietro Avellana", "value": "C57635"},
            {"label": "San Polomatese", "value": "C57636"},
            {"label": "SantAgapito", "value": "C57637"},
            {"label": "SantAngelo del Pesco", "value": "C57638"},
            {"label": "SantAngelo Limosano", "value": "C57639"},
            {"label": "SantElena Sannita", "value": "C57640"},
            {"label": "SantElia a Pianisi", "value": "C57641"},
            {"label": "Santa Croce di Magliano", "value": "C57642"},
            {"label": "Santa Maria del Molise", "value": "C57643"},
            {"label": "Scapoli", "value": "C57644"},
            {"label": "Sepino", "value": "C57645"},
            {"label": "Sessano del Molise", "value": "C57646"},
            {"label": "Sesto Campano", "value": "C57647"},
            {"label": "Spinete", "value": "C57648"},
            {"label": "Tavenna", "value": "C57649"},
            {"label": "Taverna Ravindola", "value": "C57650"},
            {"label": "Termoli", "value": "C57651"},
            {"label": "Torella del Sannio", "value": "C57652"},
            {"label": "Toro", "value": "C57653"},
            {"label": "Trivento", "value": "C57654"},
            {"label": "Tufara", "value": "C57655"},
            {"label": "Ururi", "value": "C57656"},
            {"label": "Vastogirardi", "value": "C57657"},
            {"label": "Venafro", "value": "C57658"},
            {"label": "Vinchiaturo", "value": "C57659"}],
 "Mombasa": [{"label": "Mombasa", "value": "C64610"}],
 "Mon State": [{"label": "Kyaikkami", "value": "C75939"},
               {"label": "Kyaikto", "value": "C75940"},
               {"label": "Martaban", "value": "C75941"},
               {"label": "Mawlamyine", "value": "C75942"},
               {"label": "Mudon", "value": "C75943"},
               {"label": "Thaton", "value": "C75944"}],
 "Monagas": [{"label": "Caripito", "value": "C147217"},
             {"label": "Maturin", "value": "C147218"},
             {"label": "Municipio Maturin", "value": "C147219"},
             {"label": "Municipio Uracoa", "value": "C147220"}],
 "Monastir Governorate": [{"label": "Banbalah", "value": "C119758"},
                          {"label": "Bekalta", "value": "C119759"},
                          {"label": "Beni Hassane", "value": "C119760"},
                          {"label": "Djemmal", "value": "C119761"},
                          {"label": "Ksar Hellal", "value": "C119762"},
                          {"label": "Ksibet el Mediouni", "value": "C119763"},
                          {"label": "Lemta", "value": "C119764"},
                          {"label": "Menzel Kamel", "value": "C119765"},
                          {"label": "Mesdour", "value": "C119766"},
                          {"label": "Monastir", "value": "C119767"},
                          {"label": "Ouardenine", "value": "C119768"},
                          {"label": "Sahline", "value": "C119769"},
                          {"label": "Seiada", "value": "C119770"},
                          {"label": "Sidi Ben Nour", "value": "C119771"},
                          {"label": "Skanes", "value": "C119772"},
                          {"label": "Touza", "value": "C119773"}],
 "Mondulkiri": [{"label": "Krong Senmonourom", "value": "C16504"},
                {"label": "Sen Monorom", "value": "C16505"},
                {"label": "Srok Kaev Seima", "value": "C16506"},
                {"label": "Srok Pech Chreada", "value": "C16507"}],
 "Mongala": [{"label": "Bumba", "value": "C23355"}],
 "Mongar District": [{"label": "Mongar", "value": "C9639"}],
 "Mono Department": [{"label": "Commune of Athieme", "value": "C9620"},
                     {"label": "Lokossa", "value": "C9621"}],
 "Monsenor Nouel Province": [{"label": "Bonao", "value": "C23938"},
                             {"label": "Juan Adrian", "value": "C23939"},
                             {"label": "Maimon", "value": "C23940"},
                             {"label": "Piedra Blanca", "value": "C23941"},
                             {"label": "Sabana del Puerto", "value": "C23942"}],
 "Montana": [{"label": "Absarokee", "value": "C136850"},
             {"label": "Anaconda", "value": "C136851"},
             {"label": "Baker", "value": "C136852"},
             {"label": "Beaverhead County", "value": "C136853"},
             {"label": "Belgrade", "value": "C136854"},
             {"label": "Big Horn County", "value": "C136855"},
             {"label": "Big Sky", "value": "C136856"},
             {"label": "Big Timber", "value": "C136857"},
             {"label": "Bigfork", "value": "C136858"},
             {"label": "Billings", "value": "C136859"},
             {"label": "Blaine County", "value": "C136860"},
             {"label": "Bonner-West Riverside", "value": "C136861"},
             {"label": "Boulder", "value": "C136862"},
             {"label": "Bozeman", "value": "C136863"},
             {"label": "Broadus", "value": "C136864"},
             {"label": "Broadwater County", "value": "C136865"},
             {"label": "Browning", "value": "C136866"},
             {"label": "Butte", "value": "C136867"},
             {"label": "Butte-Silver Bow (Balance)", "value": "C136868"},
             {"label": "Carbon County", "value": "C136869"},
             {"label": "Carter County", "value": "C136870"},
             {"label": "Cascade County", "value": "C136871"},
             {"label": "Chester", "value": "C136872"},
             {"label": "Chinook", "value": "C136873"},
             {"label": "Choteau", "value": "C136874"},
             {"label": "Chouteau County", "value": "C136875"},
             {"label": "Circle", "value": "C136876"},
             {"label": "Clancy", "value": "C136877"},
             {"label": "Clinton", "value": "C136878"},
             {"label": "Colstrip", "value": "C136879"},
             {"label": "Columbia Falls", "value": "C136880"},
             {"label": "Columbus", "value": "C136881"},
             {"label": "Conrad", "value": "C136882"},
             {"label": "Crow Agency", "value": "C136883"},
             {"label": "Custer County", "value": "C136884"},
             {"label": "Cut Bank", "value": "C136885"},
             {"label": "Daniels County", "value": "C136886"},
             {"label": "Dawson County", "value": "C136887"},
             {"label": "Deer Lodge", "value": "C136888"},
             {"label": "Deer Lodge County", "value": "C136889"},
             {"label": "Dillon", "value": "C136890"},
             {"label": "East Helena", "value": "C136891"},
             {"label": "East Missoula", "value": "C136892"},
             {"label": "Ekalaka", "value": "C136893"},
             {"label": "Eureka", "value": "C136894"},
             {"label": "Evergreen", "value": "C136895"},
             {"label": "Fallon County", "value": "C136896"},
             {"label": "Fergus County", "value": "C136897"},
             {"label": "Flathead County", "value": "C136898"},
             {"label": "Forsyth", "value": "C136899"},
             {"label": "Fort Belknap Agency", "value": "C136900"},
             {"label": "Fort Benton", "value": "C136901"},
             {"label": "Four Corners", "value": "C136902"},
             {"label": "Frenchtown", "value": "C136903"},
             {"label": "Gallatin County", "value": "C136904"},
             {"label": "Garfield County", "value": "C136905"},
             {"label": "Glacier County", "value": "C136906"},
             {"label": "Glasgow", "value": "C136907"},
             {"label": "Glendive", "value": "C136908"},
             {"label": "Golden Valley County", "value": "C136909"},
             {"label": "Granite County", "value": "C136910"},
             {"label": "Great Falls", "value": "C136911"},
             {"label": "Hamilton", "value": "C136912"},
             {"label": "Hardin", "value": "C136913"},
             {"label": "Harlowton", "value": "C136914"},
             {"label": "Havre", "value": "C136915"},
             {"label": "Helena", "value": "C136916"},
             {"label": "Helena Valley Northeast", "value": "C136917"},
             {"label": "Helena Valley Northwest", "value": "C136918"},
             {"label": "Helena Valley Southeast", "value": "C136919"},
             {"label": "Helena Valley West Central", "value": "C136920"},
             {"label": "Helena West Side", "value": "C136921"},
             {"label": "Hill County", "value": "C136922"},
             {"label": "Hysham", "value": "C136923"},
             {"label": "Jefferson County", "value": "C136924"},
             {"label": "Jordan", "value": "C136925"},
             {"label": "Judith Basin County", "value": "C136926"},
             {"label": "Kalispell", "value": "C136927"},
             {"label": "Lake County", "value": "C136928"},
             {"label": "Lakeside", "value": "C136929"},
             {"label": "Lame Deer", "value": "C136930"},
             {"label": "Laurel", "value": "C136931"},
             {"label": "Lewis and Clark County", "value": "C136932"},
             {"label": "Lewistown", "value": "C136933"},
             {"label": "Libby", "value": "C136934"},
             {"label": "Liberty County", "value": "C136935"},
             {"label": "Lincoln", "value": "C136936"},
             {"label": "Lincoln County", "value": "C136937"},
             {"label": "Livingston", "value": "C136938"},
             {"label": "Lockwood", "value": "C136939"},
             {"label": "Lolo", "value": "C136940"},
             {"label": "Madison County", "value": "C136941"},
             {"label": "Malmstrom Air Force Base", "value": "C136942"},
             {"label": "Malta", "value": "C136943"},
             {"label": "Manhattan", "value": "C136944"},
             {"label": "McCone County", "value": "C136945"},
             {"label": "Meagher County", "value": "C136946"},
             {"label": "Miles City", "value": "C136947"},
             {"label": "Mineral County", "value": "C136948"},
             {"label": "Missoula", "value": "C136949"},
             {"label": "Missoula County", "value": "C136950"},
             {"label": "Montana City", "value": "C136951"},
             {"label": "Musselshell County", "value": "C136952"},
             {"label": "North Browning", "value": "C136953"},
             {"label": "Orchard Homes", "value": "C136954"},
             {"label": "Pablo", "value": "C136955"},
             {"label": "Park County", "value": "C136956"},
             {"label": "Petroleum County", "value": "C136957"},
             {"label": "Philipsburg", "value": "C136958"},
             {"label": "Phillips County", "value": "C136959"},
             {"label": "Plains", "value": "C136960"},
             {"label": "Plentywood", "value": "C136961"},
             {"label": "Polson", "value": "C136962"},
             {"label": "Pondera County", "value": "C136963"},
             {"label": "Powder River County", "value": "C136964"},
             {"label": "Powell County", "value": "C136965"},
             {"label": "Prairie County", "value": "C136966"},
             {"label": "Ravalli County", "value": "C136967"},
             {"label": "Red Lodge", "value": "C136968"},
             {"label": "Richland County", "value": "C136969"},
             {"label": "Ronan", "value": "C136970"},
             {"label": "Roosevelt County", "value": "C136971"},
             {"label": "Rosebud County", "value": "C136972"},
             {"label": "Roundup", "value": "C136973"},
             {"label": "Ryegate", "value": "C136974"},
             {"label": "Sanders County", "value": "C136975"},
             {"label": "Scobey", "value": "C136976"},
             {"label": "Seeley Lake", "value": "C136977"},
             {"label": "Shelby", "value": "C136978"},
             {"label": "Sheridan County", "value": "C136979"},
             {"label": "Sidney", "value": "C136980"},
             {"label": "Silver Bow County", "value": "C136981"},
             {"label": "Somers", "value": "C136982"},
             {"label": "South Browning", "value": "C136983"},
             {"label": "Stanford", "value": "C136984"},
             {"label": "Stevensville", "value": "C136985"},
             {"label": "Stillwater County", "value": "C136986"},
             {"label": "Sun Prairie", "value": "C136987"},
             {"label": "Superior", "value": "C136988"},
             {"label": "Sweet Grass County", "value": "C136989"},
             {"label": "Terry", "value": "C136990"},
             {"label": "Teton County", "value": "C136991"},
             {"label": "Thompson Falls", "value": "C136992"},
             {"label": "Three Forks", "value": "C136993"},
             {"label": "Toole County", "value": "C136994"},
             {"label": "Townsend", "value": "C136995"},
             {"label": "Treasure County", "value": "C136996"},
             {"label": "Valley County", "value": "C136997"},
             {"label": "Virginia City", "value": "C136998"},
             {"label": "Warm Springs", "value": "C136999"},
             {"label": "West Glendive", "value": "C137000"},
             {"label": "West Yellowstone", "value": "C137001"},
             {"label": "Wheatland County", "value": "C137002"},
             {"label": "White Sulphur Springs", "value": "C137003"},
             {"label": "Whitefish", "value": "C137004"},
             {"label": "Whitehall", "value": "C137005"},
             {"label": "Wibaux", "value": "C137006"},
             {"label": "Wibaux County", "value": "C137007"},
             {"label": "Winnett", "value": "C137008"},
             {"label": "Wolf Point", "value": "C137009"},
             {"label": "Yellowstone County", "value": "C137010"}],
 "Montana Province": [{"label": "Berkovitsa", "value": "C15988"},
                      {"label": "Boychinovtsi", "value": "C15989"},
                      {"label": "Brusartsi", "value": "C15990"},
                      {"label": "Chiprovtsi", "value": "C15991"},
                      {"label": "Lom", "value": "C15992"},
                      {"label": "Medkovets", "value": "C15993"},
                      {"label": "Montana", "value": "C15994"},
                      {"label": "Obshtina Boychinovtsi", "value": "C15995"},
                      {"label": "Obshtina Chiprovtsi", "value": "C15996"},
                      {"label": "Obshtina Georgi Damyanovo", "value": "C15997"},
                      {"label": "Obshtina Lom", "value": "C15998"},
                      {"label": "Obshtina Montana", "value": "C15999"},
                      {"label": "Obshtina Valchedram", "value": "C16000"},
                      {"label": "Obshtina Varshets", "value": "C16001"},
                      {"label": "Obshtina Yakimovo", "value": "C16002"},
                      {"label": "Valchedram", "value": "C16003"},
                      {"label": "Varshets", "value": "C16004"},
                      {"label": "Yakimovo", "value": "C16005"}],
 "Monte Cristi Province": [{"label": "Cana Chapeton", "value": "C23943"},
                           {"label": "Castanuelas", "value": "C23944"},
                           {"label": "Guayubin", "value": "C23945"},
                           {"label": "Hatillo Palma", "value": "C23946"},
                           {"label": "Las Matas de Santa Cruz",
                            "value": "C23947"},
                           {"label": "Monte Cristi", "value": "C23948"},
                           {"label": "Pepillo Salcedo", "value": "C23949"},
                           {"label": "San Fernando de Monte Cristi",
                            "value": "C23950"},
                           {"label": "Villa Elisa", "value": "C23951"},
                           {"label": "Villa Vasquez", "value": "C23952"}],
 "Monte Plata Province": [{"label": "Bayaguana", "value": "C23953"},
                          {"label": "Don Juan", "value": "C23954"},
                          {"label": "Esperalvillo", "value": "C23955"},
                          {"label": "Gonzalo", "value": "C23956"},
                          {"label": "Los Botados", "value": "C23957"},
                          {"label": "Majagual", "value": "C23958"},
                          {"label": "Monte Plata", "value": "C23959"},
                          {"label": "Sabana Grande de Boya", "value": "C23960"},
                          {"label": "Yamasa", "value": "C23961"}],
 "Montegiardino": [{"label": "Monte Giardino", "value": "C106251"}],
 "Montevideo Department": [{"label": "Bella Vista", "value": "C146900"},
                           {"label": "Belvedere", "value": "C146901"},
                           {"label": "Buceo", "value": "C146902"},
                           {"label": "Capurro (Montevideo)",
                            "value": "C146903"},
                           {"label": "Carrasco", "value": "C146904"},
                           {"label": "Casabo", "value": "C146905"},
                           {"label": "Centro", "value": "C146906"},
                           {"label": "Cerrito, Montevideo", "value": "C146907"},
                           {"label": "Colon Centro y Noroeste",
                            "value": "C146908"},
                           {"label": "Colon Sudeste", "value": "C146909"},
                           {"label": "Conciliacion", "value": "C146910"},
                           {"label": "Cordon", "value": "C146911"},
                           {"label": "Flor de Maronas", "value": "C146912"},
                           {"label": "Ituzaingo", "value": "C146913"},
                           {"label": "La Union", "value": "C146914"},
                           {"label": "Malvin", "value": "C146915"},
                           {"label": "Manga", "value": "C146916"},
                           {"label": "Maronas", "value": "C146917"},
                           {"label": "Montevideo", "value": "C146918"},
                           {"label": "Nuevo Paris", "value": "C146919"},
                           {"label": "Pajas Blancas", "value": "C146920"},
                           {"label": "Paso del Molino", "value": "C146921"},
                           {"label": "Penarol", "value": "C146922"},
                           {"label": "Piedras Blancas", "value": "C146923"},
                           {"label": "Pocitos", "value": "C146924"},
                           {"label": "Punta Carretas", "value": "C146925"},
                           {"label": "Punta Gorda", "value": "C146926"},
                           {"label": "Reducto", "value": "C146927"},
                           {"label": "Santiago Vazquez", "value": "C146928"},
                           {"label": "Sayage", "value": "C146929"},
                           {"label": "Tres Cruces", "value": "C146930"},
                           {"label": "Villa del Cerro", "value": "C146931"},
                           {"label": "Villa Espanola", "value": "C146932"},
                           {"label": "Villa Garcia", "value": "C146933"},
                           {"label": "Villa Munoz", "value": "C146934"}],
 "Montserrado County": [{"label": "Bensonville", "value": "C65012"},
                        {"label": "Monrovia", "value": "C65013"}],
 "Monufia": [{"label": "Al Bajur", "value": "C24304"},
             {"label": "Ash Shuhada", "value": "C24305"},
             {"label": "Ashmun", "value": "C24306"},
             {"label": "Munuf", "value": "C24307"},
             {"label": "Quwaysina", "value": "C24308"},
             {"label": "Shibin al Kawm", "value": "C24309"},
             {"label": "Tala", "value": "C24310"}],
 "Mopti Region": [{"label": "Bandiagara", "value": "C65841"},
                  {"label": "Djenne", "value": "C65842"},
                  {"label": "Douentza", "value": "C65843"},
                  {"label": "Douentza Cercle", "value": "C65844"},
                  {"label": "Mopti", "value": "C65845"},
                  {"label": "Tenenkou", "value": "C65846"}],
 "Moquegua": [{"label": "Ilo", "value": "C80987"},
              {"label": "Moquegua", "value": "C80988"},
              {"label": "Pacocha", "value": "C80989"},
              {"label": "Provincia de General Sanchez Cerro",
               "value": "C80990"},
              {"label": "Provincia de Ilo", "value": "C80991"},
              {"label": "Provincia de Mariscal Nieto", "value": "C80992"},
              {"label": "Torata", "value": "C80993"}],
 "Moravce Municipality": [{"label": "Moravce", "value": "C107729"}],
 "Moravske Toplice Municipality": [{"label": "Moravske Toplice",
                                    "value": "C107730"}],
 "Moravskoslezsky kraj": [{"label": "Albrechtice", "value": "C22475"},
                          {"label": "Bartosovice", "value": "C22476"},
                          {"label": "Baska", "value": "C22477"},
                          {"label": "Bilovec", "value": "C22478"},
                          {"label": "Bohumin", "value": "C22479"},
                          {"label": "Bohuslavice", "value": "C22480"},
                          {"label": "Bolatice", "value": "C22481"},
                          {"label": "Brantice", "value": "C22482"},
                          {"label": "Brezova", "value": "C22483"},
                          {"label": "Bridlicna", "value": "C22484"},
                          {"label": "Brumovice", "value": "C22485"},
                          {"label": "Bruntal", "value": "C22486"},
                          {"label": "Brusperk", "value": "C22487"},
                          {"label": "Budisov nad Budisovkou",
                           "value": "C22488"},
                          {"label": "Bukovec", "value": "C22489"},
                          {"label": "Bystrice", "value": "C22490"},
                          {"label": "Celadna", "value": "C22491"},
                          {"label": "Cesky Tesin", "value": "C22492"},
                          {"label": "Chlebicov", "value": "C22493"},
                          {"label": "Chotebuz", "value": "C22494"},
                          {"label": "Chuchelna", "value": "C22495"},
                          {"label": "Darkovice", "value": "C22496"},
                          {"label": "Detmarovice", "value": "C22497"},
                          {"label": "Dobra", "value": "C22498"},
                          {"label": "Dobratice", "value": "C22499"},
                          {"label": "Dolni Benesov", "value": "C22500"},
                          {"label": "Dolni Lhota", "value": "C22501"},
                          {"label": "Dolni Lutyne", "value": "C22502"},
                          {"label": "Dolni Zivotice", "value": "C22503"},
                          {"label": "Doubrava", "value": "C22504"},
                          {"label": "Dvorce", "value": "C22505"},
                          {"label": "Frenstat pod Radhostem",
                           "value": "C22506"},
                          {"label": "Frycovice", "value": "C22507"},
                          {"label": "Frydek-Mistek", "value": "C22508"},
                          {"label": "Frydlant nad Ostravici",
                           "value": "C22509"},
                          {"label": "Fulnek", "value": "C22510"},
                          {"label": "Haj ve Slezsku", "value": "C22511"},
                          {"label": "Hat", "value": "C22512"},
                          {"label": "Havirov", "value": "C22513"},
                          {"label": "Hlucin", "value": "C22514"},
                          {"label": "Hnevosice", "value": "C22515"},
                          {"label": "Hnojnik", "value": "C22516"},
                          {"label": "Hodslavice", "value": "C22517"},
                          {"label": "Holasovice", "value": "C22518"},
                          {"label": "Horni Benesov", "value": "C22519"},
                          {"label": "Horni Bludovice", "value": "C22520"},
                          {"label": "Horni Mesto", "value": "C22521"},
                          {"label": "Horni Sucha", "value": "C22522"},
                          {"label": "Horni Terlicko", "value": "C22523"},
                          {"label": "Hrabyne", "value": "C22524"},
                          {"label": "Hradec nad Moravici", "value": "C22525"},
                          {"label": "Hradek", "value": "C22526"},
                          {"label": "Hukvaldy", "value": "C22527"},
                          {"label": "Jablunkov", "value": "C22528"},
                          {"label": "Jakartovice", "value": "C22529"},
                          {"label": "Janovice", "value": "C22530"},
                          {"label": "Jesenik nad Odrou", "value": "C22531"},
                          {"label": "Jindrichov", "value": "C22532"},
                          {"label": "Jistebnik", "value": "C22533"},
                          {"label": "Karlovice", "value": "C22534"},
                          {"label": "Karvina", "value": "C22535"},
                          {"label": "Klimkovice", "value": "C22536"},
                          {"label": "Koberice", "value": "C22537"},
                          {"label": "Komorni Lhotka", "value": "C22538"},
                          {"label": "Koprivnice", "value": "C22539"},
                          {"label": "Kozlovice", "value": "C22540"},
                          {"label": "Kozmice", "value": "C22541"},
                          {"label": "Kravare", "value": "C22542"},
                          {"label": "Krmelin", "value": "C22543"},
                          {"label": "Krnov", "value": "C22544"},
                          {"label": "Kuncice pod Ondrejnikem",
                           "value": "C22545"},
                          {"label": "Kunin", "value": "C22546"},
                          {"label": "Lichnov", "value": "C22547"},
                          {"label": "Lichnov (o. Novy Jicin)",
                           "value": "C22548"},
                          {"label": "Lucina", "value": "C22549"},
                          {"label": "Ludgerovice", "value": "C22550"},
                          {"label": "Markvartovice", "value": "C22551"},
                          {"label": "Mesto Albrechtice", "value": "C22552"},
                          {"label": "Metylovice", "value": "C22553"},
                          {"label": "Milikov", "value": "C22554"},
                          {"label": "Mokre Lazce", "value": "C22555"},
                          {"label": "Moravka", "value": "C22556"},
                          {"label": "Morkov", "value": "C22557"},
                          {"label": "Mosty u Jablunkova", "value": "C22558"},
                          {"label": "Navsi u Jablunkova", "value": "C22559"},
                          {"label": "Novy Jicin", "value": "C22560"},
                          {"label": "Nydek", "value": "C22561"},
                          {"label": "Odry", "value": "C22562"},
                          {"label": "Okres Bruntal", "value": "C22563"},
                          {"label": "Okres Frydek-Mistek", "value": "C22564"},
                          {"label": "Okres Karvina", "value": "C22565"},
                          {"label": "Okres Novy Jicin", "value": "C22566"},
                          {"label": "Okres Opava", "value": "C22567"},
                          {"label": "Okres Ostrava-mesto", "value": "C22568"},
                          {"label": "Oldrisov", "value": "C22569"},
                          {"label": "Opava", "value": "C22570"},
                          {"label": "Orlova", "value": "C22571"},
                          {"label": "Osoblaha", "value": "C22572"},
                          {"label": "Ostrava", "value": "C22573"},
                          {"label": "Ostravice", "value": "C22574"},
                          {"label": "Otice", "value": "C22575"},
                          {"label": "Palkovice", "value": "C22576"},
                          {"label": "Paskov", "value": "C22577"},
                          {"label": "Petrovice u Karvine", "value": "C22578"},
                          {"label": "Petrvald", "value": "C22579"},
                          {"label": "Pisek", "value": "C22580"},
                          {"label": "Pist", "value": "C22581"},
                          {"label": "Prazmo", "value": "C22582"},
                          {"label": "Pribor", "value": "C22583"},
                          {"label": "Pusta Polom", "value": "C22584"},
                          {"label": "Raskovice", "value": "C22585"},
                          {"label": "Repiste", "value": "C22586"},
                          {"label": "Ropice", "value": "C22587"},
                          {"label": "Rybi", "value": "C22588"},
                          {"label": "Rychvald", "value": "C22589"},
                          {"label": "Rymarov", "value": "C22590"},
                          {"label": "Sedliste", "value": "C22591"},
                          {"label": "Sedlnice", "value": "C22592"},
                          {"label": "Senov", "value": "C22593"},
                          {"label": "Silherovice", "value": "C22594"},
                          {"label": "Slavkov", "value": "C22595"},
                          {"label": "Stara Ves nad Ondrejnici",
                           "value": "C22596"},
                          {"label": "Stare Mesto", "value": "C22597"},
                          {"label": "Staric", "value": "C22598"},
                          {"label": "Stary Bohumin", "value": "C22599"},
                          {"label": "Stary Jicin", "value": "C22600"},
                          {"label": "Steborice", "value": "C22601"},
                          {"label": "Stepankovice", "value": "C22602"},
                          {"label": "Stitina", "value": "C22603"},
                          {"label": "Stonava", "value": "C22604"},
                          {"label": "Stramberk", "value": "C22605"},
                          {"label": "Studenka", "value": "C22606"},
                          {"label": "Sviadnov", "value": "C22607"},
                          {"label": "Ticha", "value": "C22608"},
                          {"label": "Trinec", "value": "C22609"},
                          {"label": "Trojanovice", "value": "C22610"},
                          {"label": "Vaclavovice", "value": "C22611"},
                          {"label": "Velka Polom", "value": "C22612"},
                          {"label": "Velke Heraltice", "value": "C22613"},
                          {"label": "Velke Hostice", "value": "C22614"},
                          {"label": "Vendryne", "value": "C22615"},
                          {"label": "Verovice", "value": "C22616"},
                          {"label": "Vitkov", "value": "C22617"},
                          {"label": "Vlcnov", "value": "C22618"},
                          {"label": "Vratimov", "value": "C22619"},
                          {"label": "Vrbice", "value": "C22620"},
                          {"label": "Vrbno pod Pradedem", "value": "C22621"},
                          {"label": "Vresina", "value": "C22622"},
                          {"label": "Zator", "value": "C22623"}],
 "Morazan Department": [{"label": "Cacaopera", "value": "C24402"},
                        {"label": "Corinto", "value": "C24403"},
                        {"label": "Guatajiagua", "value": "C24404"},
                        {"label": "Jocoro", "value": "C24405"},
                        {"label": "San Francisco", "value": "C24406"},
                        {"label": "Sociedad", "value": "C24407"}],
 "More og Romsdal": [{"label": "Alesund", "value": "C78818"},
                     {"label": "Andalsnes", "value": "C78819"},
                     {"label": "Aukra", "value": "C78820"},
                     {"label": "Aure", "value": "C78821"},
                     {"label": "Averoy", "value": "C78822"},
                     {"label": "Batnfjordsora", "value": "C78823"},
                     {"label": "Brattvag", "value": "C78824"},
                     {"label": "Eide", "value": "C78825"},
                     {"label": "Eidsvag", "value": "C78826"},
                     {"label": "Elnesvagen", "value": "C78827"},
                     {"label": "Fraena", "value": "C78828"},
                     {"label": "Giske", "value": "C78829"},
                     {"label": "Gjemnes", "value": "C78830"},
                     {"label": "Halsa", "value": "C78831"},
                     {"label": "Haram", "value": "C78832"},
                     {"label": "Hareid", "value": "C78833"},
                     {"label": "Heroy", "value": "C78834"},
                     {"label": "Hjelset", "value": "C78835"},
                     {"label": "Hopen", "value": "C78836"},
                     {"label": "Kristiansund", "value": "C78837"},
                     {"label": "Larsnes", "value": "C78838"},
                     {"label": "Midsund", "value": "C78839"},
                     {"label": "Molde", "value": "C78840"},
                     {"label": "Nesset", "value": "C78841"},
                     {"label": "Norddal", "value": "C78842"},
                     {"label": "Nordstranda", "value": "C78843"},
                     {"label": "Orskog", "value": "C78844"},
                     {"label": "Orsta", "value": "C78845"},
                     {"label": "Rauma", "value": "C78846"},
                     {"label": "Rensvik", "value": "C78847"},
                     {"label": "Sande", "value": "C78848"},
                     {"label": "Sandoy", "value": "C78849"},
                     {"label": "Sjoholt", "value": "C78850"},
                     {"label": "Skodje", "value": "C78851"},
                     {"label": "Smola", "value": "C78852"},
                     {"label": "Steinshamn", "value": "C78853"},
                     {"label": "Stordal", "value": "C78854"},
                     {"label": "Stranda", "value": "C78855"},
                     {"label": "Sula", "value": "C78856"},
                     {"label": "Sunndal", "value": "C78857"},
                     {"label": "Sunndalsora", "value": "C78858"},
                     {"label": "Surnadal", "value": "C78859"},
                     {"label": "Sykkylven", "value": "C78860"},
                     {"label": "Tingvoll", "value": "C78861"},
                     {"label": "Tomra", "value": "C78862"},
                     {"label": "Ulstein", "value": "C78863"},
                     {"label": "Ulsteinvik", "value": "C78864"},
                     {"label": "Ulsteinvik weather pws station",
                      "value": "C78865"},
                     {"label": "Vanylven", "value": "C78866"},
                     {"label": "Vatne", "value": "C78867"},
                     {"label": "Vestnes", "value": "C78868"},
                     {"label": "Volda", "value": "C78869"}],
 "Morelos": [{"label": "Abelardo L. Rodriguez", "value": "C70888"},
             {"label": "Acamilpa", "value": "C70889"},
             {"label": "Achichipico", "value": "C70890"},
             {"label": "Adolfo Lopez Mateos", "value": "C70891"},
             {"label": "Ahuehuetzingo", "value": "C70892"},
             {"label": "Alfredo V. Bonfil (Chacampalco)", "value": "C70893"},
             {"label": "Alpuyeca", "value": "C70894"},
             {"label": "Amacuitlapilco", "value": "C70895"},
             {"label": "Amacuzac", "value": "C70896"},
             {"label": "Amatlan de Quetzalcoatl", "value": "C70897"},
             {"label": "Amayuca", "value": "C70898"},
             {"label": "Amilcingo", "value": "C70899"},
             {"label": "Anenecuilco", "value": "C70900"},
             {"label": "Arroyo Vista Hermosa", "value": "C70901"},
             {"label": "Atlacahualoya", "value": "C70902"},
             {"label": "Atlacholoaya", "value": "C70903"},
             {"label": "Atlatlahucan", "value": "C70904"},
             {"label": "Atotonilco", "value": "C70905"},
             {"label": "Axochiapan", "value": "C70906"},
             {"label": "Ayala", "value": "C70907"},
             {"label": "Bonifacio Garcia", "value": "C70908"},
             {"label": "Buenavista", "value": "C70909"},
             {"label": "Calera Chica", "value": "C70910"},
             {"label": "Calle Real", "value": "C70911"},
             {"label": "Casahuatlan", "value": "C70912"},
             {"label": "Cerritos de Cardenas", "value": "C70913"},
             {"label": "Chalcatzingo", "value": "C70914"},
             {"label": "Chavarria", "value": "C70915"},
             {"label": "Chiconcuac", "value": "C70916"},
             {"label": "Chinameca", "value": "C70917"},
             {"label": "Ciudad Ayala", "value": "C70918"},
             {"label": "Cliserio Alanis (San Gaspar)", "value": "C70919"},
             {"label": "Coahuixtla", "value": "C70920"},
             {"label": "Coatetelco", "value": "C70921"},
             {"label": "Coatlan del Rio", "value": "C70922"},
             {"label": "Cocoyoc", "value": "C70923"},
             {"label": "Cocoyotla", "value": "C70924"},
             {"label": "Colonia angel Bocanegra (Adolfo Lopez Mateos)",
              "value": "C70925"},
             {"label": "Colonia el Florido", "value": "C70926"},
             {"label": "Colonia las Arboledas", "value": "C70927"},
             {"label": "Colonia los Cerritos", "value": "C70928"},
             {"label": "Colonia Obrera", "value": "C70929"},
             {"label": "Colonia Palo Prieto (Chipitongo)", "value": "C70930"},
             {"label": "Colonia San Francisco", "value": "C70931"},
             {"label": "Constancio Farfan (La Pascuala)", "value": "C70932"},
             {"label": "Cuauchichinola", "value": "C70933"},
             {"label": "Cuauhtempan (San Andres Cuauhtempan)",
              "value": "C70934"},
             {"label": "Cuautla", "value": "C70935"},
             {"label": "Cuentepec", "value": "C70936"},
             {"label": "Cuernavaca", "value": "C70937"},
             {"label": "Dos Rios", "value": "C70938"},
             {"label": "El Carmen", "value": "C70939"},
             {"label": "El Estudiante", "value": "C70940"},
             {"label": "El Mirador", "value": "C70941"},
             {"label": "El Rodeo", "value": "C70942"},
             {"label": "El Salitre", "value": "C70943"},
             {"label": "Emiliano Zapata", "value": "C70944"},
             {"label": "Emiliano Zapata (Casahuates)", "value": "C70945"},
             {"label": "Enthavi", "value": "C70946"},
             {"label": "Ex-Hacienda el Hospital", "value": "C70947"},
             {"label": "Felipe Neri (Cuatepec)", "value": "C70948"},
             {"label": "Fraccionamiento Lomas de Ahuatlan", "value": "C70949"},
             {"label": "Fraccionamiento Universo", "value": "C70950"},
             {"label": "Galeana", "value": "C70951"},
             {"label": "Higueron", "value": "C70952"},
             {"label": "Huajintlan", "value": "C70953"},
             {"label": "Huatecalco", "value": "C70954"},
             {"label": "Huazulco", "value": "C70955"},
             {"label": "Huecahuasco", "value": "C70956"},
             {"label": "Huepalcalco (San Miguel)", "value": "C70957"},
             {"label": "Hueyapan", "value": "C70958"},
             {"label": "Huitchila", "value": "C70959"},
             {"label": "Huitzilac", "value": "C70960"},
             {"label": "Huitzililla", "value": "C70961"},
             {"label": "Ignacio Bastida (Santa Catarina Tlayca)",
              "value": "C70962"},
             {"label": "Independencia", "value": "C70963"},
             {"label": "Itzamatitlan", "value": "C70964"},
             {"label": "Ixtlilco el Chico", "value": "C70965"},
             {"label": "Jaltepec", "value": "C70966"},
             {"label": "Jantetelco", "value": "C70967"},
             {"label": "Jicarero", "value": "C70968"},
             {"label": "Jiutepec", "value": "C70969"},
             {"label": "Jojutla", "value": "C70970"},
             {"label": "Jonacatepec", "value": "C70971"},
             {"label": "Juan Morales", "value": "C70972"},
             {"label": "Jumiltepec", "value": "C70973"},
             {"label": "La Huanica", "value": "C70974"},
             {"label": "La Joya", "value": "C70975"},
             {"label": "La Unidad Huitzizilapan", "value": "C70976"},
             {"label": "Lazaro Cardenas (El Empalme)", "value": "C70977"},
             {"label": "Loma Bonita", "value": "C70978"},
             {"label": "Lomas de Ahuatepec", "value": "C70979"},
             {"label": "Los Arcos", "value": "C70980"},
             {"label": "Marcelino Rodriguez (San Ignacio)", "value": "C70981"},
             {"label": "Mazatepec", "value": "C70982"},
             {"label": "Metepec", "value": "C70983"},
             {"label": "Miacatlan", "value": "C70984"},
             {"label": "Michapa", "value": "C70985"},
             {"label": "Miguel Bocanegra", "value": "C70986"},
             {"label": "Mixtlalcingo", "value": "C70987"},
             {"label": "Moyotepec", "value": "C70988"},
             {"label": "Narciso Mendoza", "value": "C70989"},
             {"label": "Nepopualco", "value": "C70990"},
             {"label": "Ninguno [Centro de Readaptacion Social de Atlacholoaya]",
              "value": "C70991"},
             {"label": "Oacalco", "value": "C70992"},
             {"label": "Oaxtepec", "value": "C70993"},
             {"label": "Ocoxaltepec", "value": "C70994"},
             {"label": "Ocuituco", "value": "C70995"},
             {"label": "Olintepec", "value": "C70996"},
             {"label": "Pedro Amaro", "value": "C70997"},
             {"label": "Pena Flores (Palo Verde)", "value": "C70998"},
             {"label": "Perez de Galeana", "value": "C70999"},
             {"label": "Progreso", "value": "C71000"},
             {"label": "Pueblo Nuevo", "value": "C71001"},
             {"label": "Pueblo Nuevo de Morelos", "value": "C71002"},
             {"label": "Puente de Ixtla", "value": "C71003"},
             {"label": "Puxtla", "value": "C71004"},
             {"label": "Quebrantadero", "value": "C71005"},
             {"label": "Reyes Acozac", "value": "C71006"},
             {"label": "Salazar", "value": "C71007"},
             {"label": "San Andres Cuexcontitlan", "value": "C71008"},
             {"label": "San Andres de la Cal", "value": "C71009"},
             {"label": "San Andres Ocotlan", "value": "C71010"},
             {"label": "San Antonio Chiverias", "value": "C71011"},
             {"label": "San Antonio del Puente", "value": "C71012"},
             {"label": "San Bartolome Tlaltelulco", "value": "C71013"},
             {"label": "San Diego", "value": "C71014"},
             {"label": "San Diego Alcala", "value": "C71015"},
             {"label": "San Diego Linares", "value": "C71016"},
             {"label": "San Felipe Teotitlan", "value": "C71017"},
             {"label": "San Francisco Chimalpa", "value": "C71018"},
             {"label": "San Francisco Tetetla", "value": "C71019"},
             {"label": "San Francisco Zacacalco", "value": "C71020"},
             {"label": "San Gabriel las Palmas", "value": "C71021"},
             {"label": "San Gaspar Tlahuelilpan", "value": "C71022"},
             {"label": "San Jeronimo Acazulco", "value": "C71023"},
             {"label": "San Jeronimo Chicahualco", "value": "C71024"},
             {"label": "San Jose", "value": "C71025"},
             {"label": "San Jose Buenavista el Grande", "value": "C71026"},
             {"label": "San Jose Comalco", "value": "C71027"},
             {"label": "San Jose el Llanito", "value": "C71028"},
             {"label": "San Jose Solis", "value": "C71029"},
             {"label": "San Jose Vista Hermosa", "value": "C71030"},
             {"label": "San Juan Ahuehueyo", "value": "C71031"},
             {"label": "San Juan la Isla", "value": "C71032"},
             {"label": "San Juan Pueblo Nuevo", "value": "C71033"},
             {"label": "San Juan Texcalpan", "value": "C71034"},
             {"label": "San Juan Tilapa", "value": "C71035"},
             {"label": "San Juan Xochiaca", "value": "C71036"},
             {"label": "San Juan Yautepec", "value": "C71037"},
             {"label": "San Juan Zitlaltepec", "value": "C71038"},
             {"label": "San Lorenzo Cuauhtenco", "value": "C71039"},
             {"label": "San Lorenzo Nenamicoyan", "value": "C71040"},
             {"label": "San Lorenzo Oyamel", "value": "C71041"},
             {"label": "San Luis Ayucan", "value": "C71042"},
             {"label": "San Marcos de la Cruz", "value": "C71043"},
             {"label": "San Mateo Atarasquillo", "value": "C71044"},
             {"label": "San Mateo Otzacatipan", "value": "C71045"},
             {"label": "San Miguel", "value": "C71046"},
             {"label": "San Miguel Almaya", "value": "C71047"},
             {"label": "San Miguel Ameyalco", "value": "C71048"},
             {"label": "San Miguel Atepoxco", "value": "C71049"},
             {"label": "San Miguel Balderas", "value": "C71050"},
             {"label": "San Miguel de La Victoria", "value": "C71051"},
             {"label": "San Miguel Totoltepec", "value": "C71052"},
             {"label": "San Nicolas Coatepec", "value": "C71053"},
             {"label": "San Nicolas Peralta", "value": "C71054"},
             {"label": "San Nicolas Solis", "value": "C71055"},
             {"label": "San Nicolas Tlazala", "value": "C71056"},
             {"label": "San Pablo Autopan", "value": "C71057"},
             {"label": "San Pedro Apatlaco", "value": "C71058"},
             {"label": "San Pedro Atlapulco", "value": "C71059"},
             {"label": "San Pedro Chochula", "value": "C71060"},
             {"label": "San Pedro Huaquilpan", "value": "C71061"},
             {"label": "San Pedro Techuchulco", "value": "C71062"},
             {"label": "San Pedro Tlaltizapan", "value": "C71063"},
             {"label": "San Pedro Totoltepec", "value": "C71064"},
             {"label": "San Pedro Tultepec", "value": "C71065"},
             {"label": "San Pedro Xalpa", "value": "C71066"},
             {"label": "San Pedro Zictepec", "value": "C71067"},
             {"label": "San Rafael Zaragoza", "value": "C71068"},
             {"label": "San Sebastian", "value": "C71069"},
             {"label": "San Vicente de Juarez", "value": "C71070"},
             {"label": "Santa Ana Jilotzingo", "value": "C71071"},
             {"label": "Santa Catarina", "value": "C71072"},
             {"label": "Santa Cruz Ayotuxco", "value": "C71073"},
             {"label": "Santa Cruz Pueblo Nuevo", "value": "C71074"},
             {"label": "Santa Maria Ajoloapan", "value": "C71075"},
             {"label": "Santa Maria Atarasquillo", "value": "C71076"},
             {"label": "Santa Maria Magdalena Ocotitlan", "value": "C71077"},
             {"label": "Santa Martha", "value": "C71078"},
             {"label": "Santa Rosa Treinta", "value": "C71079"},
             {"label": "Santiago Analco", "value": "C71080"},
             {"label": "Santiago Oxthoc", "value": "C71081"},
             {"label": "Santiago Tepatlaxco", "value": "C71082"},
             {"label": "Santiago Tilapa", "value": "C71083"},
             {"label": "Santo Domingo Aztacameca", "value": "C71084"},
             {"label": "Santo Domingo Ocotitlan", "value": "C71085"},
             {"label": "Tecajec", "value": "C71086"},
             {"label": "Tehuixtla", "value": "C71087"},
             {"label": "Telixtac", "value": "C71088"},
             {"label": "Temimilcingo", "value": "C71089"},
             {"label": "Temixco", "value": "C71090"},
             {"label": "Temoac", "value": "C71091"},
             {"label": "Tenango", "value": "C71092"},
             {"label": "Tenextepango", "value": "C71093"},
             {"label": "Tepalcingo", "value": "C71094"},
             {"label": "Tepetzingo", "value": "C71095"},
             {"label": "Tepoztlan", "value": "C71096"},
             {"label": "Tequesquitengo", "value": "C71097"},
             {"label": "Tetecala", "value": "C71098"},
             {"label": "Tetecalita", "value": "C71099"},
             {"label": "Tetecolala", "value": "C71100"},
             {"label": "Tetela del Volcan", "value": "C71101"},
             {"label": "Tetelilla", "value": "C71102"},
             {"label": "Texcala", "value": "C71103"},
             {"label": "Tezoyuca", "value": "C71104"},
             {"label": "Tianguistongo", "value": "C71105"},
             {"label": "Ticuman", "value": "C71106"},
             {"label": "Tierra Larga (Campo Nuevo)", "value": "C71107"},
             {"label": "Tilzapotla", "value": "C71108"},
             {"label": "Tlachaloya", "value": "C71109"},
             {"label": "Tlacotepec", "value": "C71110"},
             {"label": "Tlacuitlapa", "value": "C71111"},
             {"label": "Tlalnepantla", "value": "C71112"},
             {"label": "Tlaltenanguito", "value": "C71113"},
             {"label": "Tlaltetelco (San Miguel Tlaltetelco)",
              "value": "C71114"},
             {"label": "Tlaltizapan", "value": "C71115"},
             {"label": "Tlapanaloya", "value": "C71116"},
             {"label": "Tlaquiltenango", "value": "C71117"},
             {"label": "Tlatenchi", "value": "C71118"},
             {"label": "Tlayacapan", "value": "C71119"},
             {"label": "Totolapan", "value": "C71120"},
             {"label": "Tres de Mayo", "value": "C71121"},
             {"label": "Tres Marias", "value": "C71122"},
             {"label": "Unidad Habitacional 10 de Abril", "value": "C71123"},
             {"label": "Unidad Habitacional Jose Maria Morelos y Pavon",
              "value": "C71124"},
             {"label": "Unidad Habitacional Mariano Matamoros",
              "value": "C71125"},
             {"label": "Unidad Habitacional Rinconada Acolapa",
              "value": "C71126"},
             {"label": "Valle de Vazquez", "value": "C71127"},
             {"label": "Villa Santiago", "value": "C71128"},
             {"label": "Xalostoc", "value": "C71129"},
             {"label": "Xochicalco (Cirenio Longares)", "value": "C71130"},
             {"label": "Xochitepec", "value": "C71131"},
             {"label": "Xochitlan", "value": "C71132"},
             {"label": "Xoxocotla", "value": "C71133"},
             {"label": "Yautepec", "value": "C71134"},
             {"label": "Yecapixteca", "value": "C71135"},
             {"label": "Yecapixtla", "value": "C71136"},
             {"label": "Zacapalco", "value": "C71137"},
             {"label": "Zacatepec", "value": "C71138"},
             {"label": "Zacualpan de Amilpas", "value": "C71139"},
             {"label": "Zaragoza de Guadalupe", "value": "C71140"}],
 "Morobe Province": [{"label": "Bulolo", "value": "C80442"},
                     {"label": "Finschhafen", "value": "C80443"},
                     {"label": "Huon Gulf", "value": "C80444"},
                     {"label": "Kabwum", "value": "C80445"},
                     {"label": "Lae", "value": "C80446"},
                     {"label": "Markham", "value": "C80447"},
                     {"label": "Menyamya", "value": "C80448"},
                     {"label": "Nawae", "value": "C80449"},
                     {"label": "Tewai Siassi", "value": "C80450"},
                     {"label": "Wau", "value": "C80451"}],
 "Morogoro": [{"label": "Geiro", "value": "C118207"},
              {"label": "Ifakara", "value": "C118208"},
              {"label": "Kidatu", "value": "C118209"},
              {"label": "Kidodi", "value": "C118210"},
              {"label": "Kilosa", "value": "C118211"},
              {"label": "Kimamba", "value": "C118212"},
              {"label": "Kisanga", "value": "C118213"},
              {"label": "Lupiro", "value": "C118214"},
              {"label": "Magole", "value": "C118215"},
              {"label": "Mahenge", "value": "C118216"},
              {"label": "Malinyi", "value": "C118217"},
              {"label": "Mikumi", "value": "C118218"},
              {"label": "Mlimba", "value": "C118219"},
              {"label": "Morogoro", "value": "C118220"},
              {"label": "Msowero", "value": "C118221"},
              {"label": "Mtimbira", "value": "C118222"},
              {"label": "Mvomero District", "value": "C118223"},
              {"label": "Ngerengere", "value": "C118224"}],
 "Morona-Santiago": [{"label": "Gualaquiza", "value": "C24180"},
                     {"label": "Macas", "value": "C24181"},
                     {"label": "Palora", "value": "C24182"},
                     {"label": "Sucua", "value": "C24183"}],
 "Moscow": [{"label": "Altufyevskiy", "value": "C102197"},
            {"label": "Aminyevo", "value": "C102198"},
            {"label": "Annino", "value": "C102199"},
            {"label": "Babushkin", "value": "C102200"},
            {"label": "Bibirevo", "value": "C102201"},
            {"label": "Biryulevo", "value": "C102202"},
            {"label": "Bogorodskoye", "value": "C102203"},
            {"label": "Brateyevo", "value": "C102204"},
            {"label": "Cheremushki", "value": "C102205"},
            {"label": "Chertanovo Yuzhnoye", "value": "C102206"},
            {"label": "Davydkovo", "value": "C102207"},
            {"label": "Golyanovo", "value": "C102208"},
            {"label": "Golovinskiy", "value": "C102209"},
            {"label": "Ivanovskoye", "value": "C102210"},
            {"label": "Kapotnya", "value": "C102211"},
            {"label": "Kokoshkino", "value": "C102212"},
            {"label": "Kolomenskoye", "value": "C102213"},
            {"label": "Kotlovka", "value": "C102214"},
            {"label": "Kozeyevo", "value": "C102215"},
            {"label": "Krasnaya Pahra", "value": "C102216"},
            {"label": "Kuryanovo", "value": "C102217"},
            {"label": "Kuzminki", "value": "C102218"},
            {"label": "Lefortovo", "value": "C102219"},
            {"label": "Leonovo", "value": "C102220"},
            {"label": "Lianozovo", "value": "C102221"},
            {"label": "Likhobory", "value": "C102222"},
            {"label": "LMS", "value": "C102223"},
            {"label": "Luzhniki", "value": "C102224"},
            {"label": "Lyublino", "value": "C102225"},
            {"label": "Marino", "value": "C102226"},
            {"label": "Matveyevskoye", "value": "C102227"},
            {"label": "Metrogorodok", "value": "C102228"},
            {"label": "Mikhalkovo", "value": "C102229"},
            {"label": "Moscow", "value": "C102230"},
            {"label": "Moskovskiy", "value": "C102231"},
            {"label": "Nagornyy", "value": "C102232"},
            {"label": "Nekrasovka", "value": "C102233"},
            {"label": "Nikolskoye", "value": "C102234"},
            {"label": "Nikulino", "value": "C102235"},
            {"label": "Novo-Peredelkino", "value": "C102236"},
            {"label": "Novogireyevo", "value": "C102237"},
            {"label": "Novokhovrino", "value": "C102238"},
            {"label": "Novokuzminki", "value": "C102239"},
            {"label": "Novovladykino", "value": "C102240"},
            {"label": "Novyye Cheremushki", "value": "C102241"},
            {"label": "Novyye Kuzminki", "value": "C102242"},
            {"label": "Ochakovo-Matveyevskoye", "value": "C102243"},
            {"label": "Orekhovo-Borisovo", "value": "C102244"},
            {"label": "Orekhovo-Borisovo Severnoye", "value": "C102245"},
            {"label": "Ostankinskiy", "value": "C102246"},
            {"label": "Otradnoye", "value": "C102247"},
            {"label": "Pokrovskoye-Streshnevo", "value": "C102248"},
            {"label": "Presnenskiy", "value": "C102249"},
            {"label": "Ramenki", "value": "C102250"},
            {"label": "Rostokino", "value": "C102251"},
            {"label": "Rublevo", "value": "C102252"},
            {"label": "Ryazanskiy", "value": "C102253"},
            {"label": "Setun", "value": "C102254"},
            {"label": "Severnyy", "value": "C102255"},
            {"label": "Shcherbinka", "value": "C102256"},
            {"label": "Shchukino", "value": "C102257"},
            {"label": "Slobodka", "value": "C102258"},
            {"label": "Sokol", "value": "C102259"},
            {"label": "Sokolniki", "value": "C102260"},
            {"label": "Solntsevo", "value": "C102261"},
            {"label": "Strogino", "value": "C102262"},
            {"label": "Sviblovo", "value": "C102263"},
            {"label": "Taganskiy", "value": "C102264"},
            {"label": "Tekstilshchiki", "value": "C102265"},
            {"label": "Tolstopaltsevo", "value": "C102266"},
            {"label": "Troitsk", "value": "C102267"},
            {"label": "Troparevo", "value": "C102268"},
            {"label": "Tsaritsyno", "value": "C102269"},
            {"label": "Tsentralnyy Administrativnyy Okrug", "value": "C102270"},
            {"label": "Tyoply Stan", "value": "C102271"},
            {"label": "Vagonoremont", "value": "C102272"},
            {"label": "Vatutinki", "value": "C102273"},
            {"label": "Vatutino", "value": "C102274"},
            {"label": "Veshnyaki", "value": "C102275"},
            {"label": "Vnukovo", "value": "C102276"},
            {"label": "Vorobyovo", "value": "C102277"},
            {"label": "Vostochnoe Degunino", "value": "C102278"},
            {"label": "Vostochnyy", "value": "C102279"},
            {"label": "Vykhino-Zhulebino", "value": "C102280"},
            {"label": "Yaroslavskiy", "value": "C102281"},
            {"label": "Yasenevo", "value": "C102282"},
            {"label": "Zagorye", "value": "C102283"},
            {"label": "Zamoskvorechye", "value": "C102284"},
            {"label": "Zelenograd", "value": "C102285"},
            {"label": "Zhulebino", "value": "C102286"},
            {"label": "Zyablikovo", "value": "C102287"},
            {"label": "Zyuzino", "value": "C102288"}],
 "Moscow Oblast": [{"label": "Alabushevo", "value": "C102289"},
                   {"label": "Alekseyevka", "value": "C102290"},
                   {"label": "Andreyevka", "value": "C102291"},
                   {"label": "Andreyevskoye", "value": "C102292"},
                   {"label": "Annino", "value": "C102293"},
                   {"label": "Aprelevka", "value": "C102294"},
                   {"label": "Arkhangelskoye", "value": "C102295"},
                   {"label": "Ashitkovo", "value": "C102296"},
                   {"label": "Ashukino", "value": "C102297"},
                   {"label": "Ateptsevo", "value": "C102298"},
                   {"label": "Avsyunino", "value": "C102299"},
                   {"label": "Avtopoligon", "value": "C102300"},
                   {"label": "Bakhchivandzhi", "value": "C102301"},
                   {"label": "Baksheyevo", "value": "C102302"},
                   {"label": "Balashikha", "value": "C102303"},
                   {"label": "Balashikha Urban Okrug", "value": "C102304"},
                   {"label": "Barvikha", "value": "C102305"},
                   {"label": "Barybino", "value": "C102306"},
                   {"label": "Beloomut", "value": "C102307"},
                   {"label": "Beloozerskiy", "value": "C102308"},
                   {"label": "Belyye Stolby", "value": "C102309"},
                   {"label": "Biorki", "value": "C102310"},
                   {"label": "Biryulevo Zapadnoye", "value": "C102311"},
                   {"label": "Bogorodskoye", "value": "C102312"},
                   {"label": "Bolshiye Vyazemy", "value": "C102313"},
                   {"label": "Bolshaya Setun", "value": "C102314"},
                   {"label": "Bolshoye Gryzlovo", "value": "C102315"},
                   {"label": "Bolshevo", "value": "C102316"},
                   {"label": "Bratovshchina", "value": "C102317"},
                   {"label": "Bronnitsy", "value": "C102318"},
                   {"label": "Budenovetc", "value": "C102319"},
                   {"label": "Bykovo", "value": "C102320"},
                   {"label": "Chashnikovo", "value": "C102321"},
                   {"label": "Chekhov", "value": "C102322"},
                   {"label": "Cherkizovo", "value": "C102323"},
                   {"label": "Chernogolovka", "value": "C102324"},
                   {"label": "Cherusti", "value": "C102325"},
                   {"label": "Chkalovskiy", "value": "C102326"},
                   {"label": "Chornoye", "value": "C102327"},
                   {"label": "Chupryakovo", "value": "C102328"},
                   {"label": "Danki", "value": "C102329"},
                   {"label": "Davydovo", "value": "C102330"},
                   {"label": "Dedenevo", "value": "C102331"},
                   {"label": "Dedovsk", "value": "C102332"},
                   {"label": "Demikhovo", "value": "C102333"},
                   {"label": "Dmitrov", "value": "C102334"},
                   {"label": "Dmitrovskiy Pogost", "value": "C102335"},
                   {"label": "Dmitrovskiy Rayon", "value": "C102336"},
                   {"label": "Dolgoprudnyy", "value": "C102337"},
                   {"label": "Domodedovo", "value": "C102338"},
                   {"label": "Domodedovskiy Rayon", "value": "C102339"},
                   {"label": "Dorogomilovo", "value": "C102340"},
                   {"label": "Dorokhovo", "value": "C102341"},
                   {"label": "Drezna", "value": "C102342"},
                   {"label": "Druzhba", "value": "C102343"},
                   {"label": "Dubna", "value": "C102344"},
                   {"label": "Dubrovitsy", "value": "C102345"},
                   {"label": "Dzerzhinskiy", "value": "C102346"},
                   {"label": "Dzerzhinsky", "value": "C102347"},
                   {"label": "Elektrogorsk", "value": "C102348"},
                   {"label": "Elektrostal", "value": "C102349"},
                   {"label": "Elektrougli", "value": "C102350"},
                   {"label": "Fedurnovo", "value": "C102351"},
                   {"label": "Fili", "value": "C102352"},
                   {"label": "Filimonki", "value": "C102353"},
                   {"label": "Firsanovka", "value": "C102354"},
                   {"label": "Fosforitnyy", "value": "C102355"},
                   {"label": "Fryanovo", "value": "C102356"},
                   {"label": "Fryazevo", "value": "C102357"},
                   {"label": "Fryazino", "value": "C102358"},
                   {"label": "Golitsyno", "value": "C102359"},
                   {"label": "Gorki Vtoryye", "value": "C102360"},
                   {"label": "Gorki-Leninskiye", "value": "C102361"},
                   {"label": "Gorshkovo", "value": "C102362"},
                   {"label": "Grebnevo", "value": "C102363"},
                   {"label": "Gzhel", "value": "C102364"},
                   {"label": "Iksha", "value": "C102365"},
                   {"label": "Ilinskiy", "value": "C102366"},
                   {"label": "Ilinskiy Pogost", "value": "C102367"},
                   {"label": "Ilinskoye", "value": "C102368"},
                   {"label": "Imeni Tsyurupy", "value": "C102369"},
                   {"label": "Imeni Vorovskogo", "value": "C102370"},
                   {"label": "Istra", "value": "C102371"},
                   {"label": "Istrinskiy Rayon", "value": "C102372"},
                   {"label": "Ivanteyevka", "value": "C102373"},
                   {"label": "Izmaylovo", "value": "C102374"},
                   {"label": "Kabanovo", "value": "C102375"},
                   {"label": "Karinskoye", "value": "C102376"},
                   {"label": "Kashira", "value": "C102377"},
                   {"label": "Kastanayevo", "value": "C102378"},
                   {"label": "Kerva", "value": "C102379"},
                   {"label": "Khimki", "value": "C102380"},
                   {"label": "Khimki Urban Okrug", "value": "C102381"},
                   {"label": "Khorlovo", "value": "C102382"},
                   {"label": "Khoroshevo-Mnevniki", "value": "C102383"},
                   {"label": "Khotkovo", "value": "C102384"},
                   {"label": "Kievskiy", "value": "C102385"},
                   {"label": "Klimovsk", "value": "C102386"},
                   {"label": "Klin", "value": "C102387"},
                   {"label": "Klinskiy Rayon", "value": "C102388"},
                   {"label": "Klyazminskoe Reservoir", "value": "C102389"},
                   {"label": "Kolomenskiy Rayon", "value": "C102390"},
                   {"label": "Kolomna", "value": "C102391"},
                   {"label": "Kolyubakino", "value": "C102392"},
                   {"label": "Kommunarka", "value": "C102393"},
                   {"label": "Konobeyevo", "value": "C102394"},
                   {"label": "Konstantinovo", "value": "C102395"},
                   {"label": "Koptevo", "value": "C102396"},
                   {"label": "Korenevo", "value": "C102397"},
                   {"label": "Korolev", "value": "C102398"},
                   {"label": "Korolyov", "value": "C102399"},
                   {"label": "Kostino", "value": "C102400"},
                   {"label": "Kostrovo", "value": "C102401"},
                   {"label": "Kotelniki", "value": "C102402"},
                   {"label": "Kozhukhovo", "value": "C102403"},
                   {"label": "Kraskovo", "value": "C102404"},
                   {"label": "Krasnaya Poyma", "value": "C102405"},
                   {"label": "Krasnoarmeysk", "value": "C102406"},
                   {"label": "Krasnogorsk", "value": "C102407"},
                   {"label": "Krasnogorskiy Rayon", "value": "C102408"},
                   {"label": "Krasnozavodsk", "value": "C102409"},
                   {"label": "Krasnoznamensk", "value": "C102410"},
                   {"label": "Krasnyy Tkach", "value": "C102411"},
                   {"label": "Kratovo", "value": "C102412"},
                   {"label": "Krivtsovo", "value": "C102413"},
                   {"label": "Kubinka", "value": "C102414"},
                   {"label": "Kupavna", "value": "C102415"},
                   {"label": "Kurilovo", "value": "C102416"},
                   {"label": "Kurovskoye", "value": "C102417"},
                   {"label": "Kuskovo", "value": "C102418"},
                   {"label": "Lvovskiy", "value": "C102419"},
                   {"label": "Leninskiy Rayon", "value": "C102420"},
                   {"label": "Leninskiye Gory", "value": "C102421"},
                   {"label": "Lesnoy", "value": "C102422"},
                   {"label": "Lesnoy Gorodok", "value": "C102423"},
                   {"label": "Lesnyye Polyany", "value": "C102424"},
                   {"label": "Levoberezhnyy", "value": "C102425"},
                   {"label": "Likino-Dulevo", "value": "C102426"},
                   {"label": "Lobnya", "value": "C102427"},
                   {"label": "Lopatinskiy", "value": "C102428"},
                   {"label": "Losino-Petrovskiy", "value": "C102429"},
                   {"label": "Lotoshino", "value": "C102430"},
                   {"label": "Lotoshinskiy Rayon", "value": "C102431"},
                   {"label": "Lozhki", "value": "C102432"},
                   {"label": "Lugovaya", "value": "C102433"},
                   {"label": "Lukhovitsy", "value": "C102434"},
                   {"label": "Lytkarino", "value": "C102435"},
                   {"label": "Lyuberetskiy Rayon", "value": "C102436"},
                   {"label": "Lyubertsy", "value": "C102437"},
                   {"label": "Lyubuchany", "value": "C102438"},
                   {"label": "Malakhovka", "value": "C102439"},
                   {"label": "Malaya Dubna", "value": "C102440"},
                   {"label": "Malino", "value": "C102441"},
                   {"label": "Malyshevo", "value": "C102442"},
                   {"label": "Mamontovka", "value": "C102443"},
                   {"label": "Marfino", "value": "C102444"},
                   {"label": "Medvezhi Ozera", "value": "C102445"},
                   {"label": "Melikhovo", "value": "C102446"},
                   {"label": "Mendeleyevo", "value": "C102447"},
                   {"label": "Meshcherino", "value": "C102448"},
                   {"label": "Mikhnevo", "value": "C102449"},
                   {"label": "Mikulino", "value": "C102450"},
                   {"label": "Misheronskiy", "value": "C102451"},
                   {"label": "Molokovo", "value": "C102452"},
                   {"label": "Monino", "value": "C102453"},
                   {"label": "Mosrentgen", "value": "C102454"},
                   {"label": "Mozhaysk", "value": "C102455"},
                   {"label": "Mozhayskiy Rayon", "value": "C102456"},
                   {"label": "Muranovo", "value": "C102457"},
                   {"label": "Mytishchi", "value": "C102458"},
                   {"label": "Mytishchi Urban Okrug", "value": "C102459"},
                   {"label": "Nakhabino", "value": "C102460"},
                   {"label": "Naro-Fominsk", "value": "C102461"},
                   {"label": "Nazaryevo", "value": "C102462"},
                   {"label": "Nekrasovskiy", "value": "C102463"},
                   {"label": "Nemchinovka", "value": "C102464"},
                   {"label": "Nikolina Gora", "value": "C102465"},
                   {"label": "Noginsk", "value": "C102466"},
                   {"label": "Noginsk-9", "value": "C102467"},
                   {"label": "Noginskiy Rayon", "value": "C102468"},
                   {"label": "Novo-Nikolskoye", "value": "C102469"},
                   {"label": "Novoivanovskoye", "value": "C102470"},
                   {"label": "Novopetrovskoye", "value": "C102471"},
                   {"label": "Novopodrezkovo", "value": "C102472"},
                   {"label": "Novosinkovo", "value": "C102473"},
                   {"label": "Novoye", "value": "C102474"},
                   {"label": "Novyy Byt", "value": "C102475"},
                   {"label": "Nudol", "value": "C102476"},
                   {"label": "Obolensk", "value": "C102477"},
                   {"label": "Obukhovo", "value": "C102478"},
                   {"label": "Odintsovo", "value": "C102479"},
                   {"label": "Odintsovskiy Rayon", "value": "C102480"},
                   {"label": "Oktyabrskiy", "value": "C102481"},
                   {"label": "Olyavidovo", "value": "C102482"},
                   {"label": "Opalikha", "value": "C102483"},
                   {"label": "Orekhovo-Zuyevo", "value": "C102484"},
                   {"label": "Orekhovo-Zuyevskiy Rayon", "value": "C102485"},
                   {"label": "Orudyevo", "value": "C102486"},
                   {"label": "Ostashevo", "value": "C102487"},
                   {"label": "Ostrovtsy", "value": "C102488"},
                   {"label": "Ozery", "value": "C102489"},
                   {"label": "Ozherelye", "value": "C102490"},
                   {"label": "Ozyory Urban Okrug", "value": "C102491"},
                   {"label": "Pavlovskaya Sloboda", "value": "C102492"},
                   {"label": "Pavlovskiy Posad", "value": "C102493"},
                   {"label": "Pavshino", "value": "C102494"},
                   {"label": "Peresvet", "value": "C102495"},
                   {"label": "Pervomayskiy", "value": "C102496"},
                   {"label": "Peshki", "value": "C102497"},
                   {"label": "Peski", "value": "C102498"},
                   {"label": "Petrovo-Dalneye", "value": "C102499"},
                   {"label": "Petrovskaya", "value": "C102500"},
                   {"label": "Pirogovskiy", "value": "C102501"},
                   {"label": "Podolsk", "value": "C102502"},
                   {"label": "Podosinki", "value": "C102503"},
                   {"label": "Popovo", "value": "C102504"},
                   {"label": "Poselok Marino", "value": "C102505"},
                   {"label": "Povarovo", "value": "C102506"},
                   {"label": "Povedniki", "value": "C102507"},
                   {"label": "Pravdinskiy", "value": "C102508"},
                   {"label": "Proletarskiy", "value": "C102509"},
                   {"label": "Protvino", "value": "C102510"},
                   {"label": "Purshevo", "value": "C102511"},
                   {"label": "Pushchino", "value": "C102512"},
                   {"label": "Pushkino", "value": "C102513"},
                   {"label": "Pushkinskiy Rayon", "value": "C102514"},
                   {"label": "Radovitskiy", "value": "C102515"},
                   {"label": "Radumlya", "value": "C102516"},
                   {"label": "Raduzhnyy", "value": "C102517"},
                   {"label": "Rakhmanovo", "value": "C102518"},
                   {"label": "Ramenskiy Rayon", "value": "C102519"},
                   {"label": "Ramenskoye", "value": "C102520"},
                   {"label": "Razvilka", "value": "C102521"},
                   {"label": "Rechitsy", "value": "C102522"},
                   {"label": "Reshetnikovo", "value": "C102523"},
                   {"label": "Reutov", "value": "C102524"},
                   {"label": "Rodniki", "value": "C102525"},
                   {"label": "Rogachevo", "value": "C102526"},
                   {"label": "Roshal", "value": "C102527"},
                   {"label": "Rozhdestveno", "value": "C102528"},
                   {"label": "Rumyantsevo", "value": "C102529"},
                   {"label": "Ruza", "value": "C102530"},
                   {"label": "Ruzskiy Rayon", "value": "C102531"},
                   {"label": "Rybnoye", "value": "C102532"},
                   {"label": "Rzhavki", "value": "C102533"},
                   {"label": "Saltykovka", "value": "C102534"},
                   {"label": "Savinskaya", "value": "C102535"},
                   {"label": "Savvinskaya Sloboda", "value": "C102536"},
                   {"label": "Selyatino", "value": "C102537"},
                   {"label": "Semenovskoye", "value": "C102538"},
                   {"label": "Semkhoz", "value": "C102539"},
                   {"label": "Serebryano-Prudskiy Rayon", "value": "C102540"},
                   {"label": "Serebryanyye Prudy", "value": "C102541"},
                   {"label": "Sergiyev Posad", "value": "C102542"},
                   {"label": "Sergiyevo-Posadskiy Rayon", "value": "C102543"},
                   {"label": "Serpukhov", "value": "C102544"},
                   {"label": "Serpukhovskiy Rayon", "value": "C102545"},
                   {"label": "Severnyy", "value": "C102546"},
                   {"label": "Shaburnovo", "value": "C102547"},
                   {"label": "Shakhovskaya", "value": "C102548"},
                   {"label": "Shakhovskoy Rayon", "value": "C102549"},
                   {"label": "Shatura", "value": "C102550"},
                   {"label": "Shaturtorf", "value": "C102551"},
                   {"label": "Shchelkovo", "value": "C102552"},
                   {"label": "Shchelkovskiy Rayon", "value": "C102553"},
                   {"label": "Shemetovo", "value": "C102554"},
                   {"label": "Sheremetyevskiy", "value": "C102555"},
                   {"label": "Shevlyakovo", "value": "C102556"},
                   {"label": "Shishkin Les", "value": "C102557"},
                   {"label": "Skhodnya", "value": "C102558"},
                   {"label": "Skolkovo", "value": "C102559"},
                   {"label": "Skoropuskovskiy", "value": "C102560"},
                   {"label": "Snegiri", "value": "C102561"},
                   {"label": "Sofrino", "value": "C102562"},
                   {"label": "Solnechnogorsk", "value": "C102563"},
                   {"label": "Solnechnogorskiy Rayon", "value": "C102564"},
                   {"label": "Spas-Zaulok", "value": "C102565"},
                   {"label": "Staraya Kupavna", "value": "C102566"},
                   {"label": "Stolbovaya", "value": "C102567"},
                   {"label": "Stromyn", "value": "C102568"},
                   {"label": "Stupino", "value": "C102569"},
                   {"label": "Stupinskiy Rayon", "value": "C102570"},
                   {"label": "Svatkovo", "value": "C102571"},
                   {"label": "Sverdlovskiy", "value": "C102572"},
                   {"label": "Sychevo", "value": "C102573"},
                   {"label": "Taldom", "value": "C102574"},
                   {"label": "Taldomskiy Rayon", "value": "C102575"},
                   {"label": "Tarasovka", "value": "C102576"},
                   {"label": "Teryayevo", "value": "C102577"},
                   {"label": "Tomilino", "value": "C102578"},
                   {"label": "Troitskoye", "value": "C102579"},
                   {"label": "Tuchkovo", "value": "C102580"},
                   {"label": "Tugolesskiy Bor", "value": "C102581"},
                   {"label": "Udelnaya", "value": "C102582"},
                   {"label": "Uvarovka", "value": "C102583"},
                   {"label": "Uzunovo", "value": "C102584"},
                   {"label": "Verbilki", "value": "C102585"},
                   {"label": "Vereya", "value": "C102586"},
                   {"label": "Vidnoye", "value": "C102587"},
                   {"label": "Vinogradovo", "value": "C102588"},
                   {"label": "Vishnyakovskiye Dachi", "value": "C102589"},
                   {"label": "Vniissok", "value": "C102590"},
                   {"label": "Volodarskogo", "value": "C102591"},
                   {"label": "Volokolamsk", "value": "C102592"},
                   {"label": "Volokolamskiy Rayon", "value": "C102593"},
                   {"label": "Voskresensk", "value": "C102594"},
                   {"label": "Voskresenskiy Rayon", "value": "C102595"},
                   {"label": "Vostryakovo", "value": "C102596"},
                   {"label": "Vysokovsk", "value": "C102597"},
                   {"label": "Yakhroma", "value": "C102598"},
                   {"label": "Yakovlevskoye", "value": "C102599"},
                   {"label": "Yam", "value": "C102600"},
                   {"label": "Yamkino", "value": "C102601"},
                   {"label": "Yaropolets", "value": "C102602"},
                   {"label": "Yegoryevsk", "value": "C102603"},
                   {"label": "Yeldigino", "value": "C102604"},
                   {"label": "Yermolino", "value": "C102605"},
                   {"label": "Yershovo", "value": "C102606"},
                   {"label": "Yubileyny", "value": "C102607"},
                   {"label": "Zagoryanskiy", "value": "C102608"},
                   {"label": "Zaprudnya", "value": "C102609"},
                   {"label": "Zaraysk", "value": "C102610"},
                   {"label": "Zarechye", "value": "C102611"},
                   {"label": "Zarya", "value": "C102612"},
                   {"label": "Zelenogradskiy", "value": "C102613"},
                   {"label": "Zhavoronki", "value": "C102614"},
                   {"label": "Zheleznodorozhnyy", "value": "C102615"},
                   {"label": "Zhilevo", "value": "C102616"},
                   {"label": "Zhitnevo", "value": "C102617"},
                   {"label": "Zhukovka", "value": "C102618"},
                   {"label": "Zhukovskiy", "value": "C102619"},
                   {"label": "Zvenigorod", "value": "C102620"}],
 "Mostaganem": [{"label": "Mostaganem", "value": "C370"}],
 "Mosteiros": [{"label": "Igreja", "value": "C17787"}],
 "Mount Lebanon Governorate": [{"label": "Baabda", "value": "C64971"},
                               {"label": "Bhamdoun", "value": "C64972"},
                               {"label": "Bhamdoun el Mhatta",
                                "value": "C64973"},
                               {"label": "Caza de Baabda", "value": "C64974"},
                               {"label": "Jbail", "value": "C64975"},
                               {"label": "Jounieh", "value": "C64976"}],
 "Moxico Province": [{"label": "Leua", "value": "C566"},
                     {"label": "Luau", "value": "C567"},
                     {"label": "Luena", "value": "C568"},
                     {"label": "Lumeje", "value": "C569"}],
 "Moyen-Chari Region": [{"label": "Kyabe", "value": "C17879"},
                        {"label": "Sarh", "value": "C17880"}],
 "Moyen-Ogooue Province": [{"label": "Lambarene", "value": "C34129"},
                           {"label": "Ndjole", "value": "C34130"}],
 "Mozirje Municipality": [{"label": "Mozirje", "value": "C107731"}],
 "Mpumalanga": [{"label": "Balfour", "value": "C108110"},
                {"label": "Barberton", "value": "C108111"},
                {"label": "Belfast", "value": "C108112"},
                {"label": "Bethal", "value": "C108113"},
                {"label": "Breyten", "value": "C108114"},
                {"label": "Carolina", "value": "C108115"},
                {"label": "Delmas", "value": "C108116"},
                {"label": "Driefontein", "value": "C108117"},
                {"label": "Ehlanzeni District", "value": "C108118"},
                {"label": "eMbalenhle", "value": "C108119"},
                {"label": "Ermelo", "value": "C108120"},
                {"label": "Gert Sibande District Municipality",
                 "value": "C108121"},
                {"label": "Hendrina", "value": "C108122"},
                {"label": "Komatipoort", "value": "C108123"},
                {"label": "Kriel", "value": "C108124"},
                {"label": "Lydenburg", "value": "C108125"},
                {"label": "Middelburg", "value": "C108126"},
                {"label": "Nelspruit", "value": "C108127"},
                {"label": "Nkangala District Municipality", "value": "C108128"},
                {"label": "Piet Retief", "value": "C108129"},
                {"label": "Secunda", "value": "C108130"},
                {"label": "Siyabuswa", "value": "C108131"},
                {"label": "Standerton", "value": "C108132"},
                {"label": "Volksrust", "value": "C108133"},
                {"label": "White River", "value": "C108134"},
                {"label": "Witbank", "value": "C108135"}],
 "Msida": [{"label": "Mellieha", "value": "C65901"}],
 "Mtskheta-Mtianeti": [{"label": "Akhalgori", "value": "C34320"},
                       {"label": "Dzegvi", "value": "C34321"},
                       {"label": "Gudauri", "value": "C34322"},
                       {"label": "Java", "value": "C34323"},
                       {"label": "Mtskheta", "value": "C34324"},
                       {"label": "Pasanauri", "value": "C34325"},
                       {"label": "Stepantsminda", "value": "C34326"},
                       {"label": "Zhinvali", "value": "C34327"}],
 "Mtwara": [{"label": "Chiungutwa", "value": "C118225"},
            {"label": "Kitama", "value": "C118226"},
            {"label": "Kitangari", "value": "C118227"},
            {"label": "Luchingu", "value": "C118228"},
            {"label": "Lukuledi", "value": "C118229"},
            {"label": "Lulindi", "value": "C118230"},
            {"label": "Madimba", "value": "C118231"},
            {"label": "Mahuta", "value": "C118232"},
            {"label": "Masasi", "value": "C118233"},
            {"label": "Masuguru", "value": "C118234"},
            {"label": "Mtwara", "value": "C118235"},
            {"label": "Namalenga", "value": "C118236"},
            {"label": "Namikupa", "value": "C118237"},
            {"label": "Nanganga", "value": "C118238"},
            {"label": "Nangomba", "value": "C118239"},
            {"label": "Nanhyanga", "value": "C118240"},
            {"label": "Nanyamba", "value": "C118241"},
            {"label": "Newala Kisimani", "value": "C118242"},
            {"label": "Tandahimba", "value": "C118243"}],
 "Mubarak Al-Kabeer Governorate": [{"label": "Abu Al Hasaniya",
                                    "value": "C64705"},
                                   {"label": "Abu Fatira", "value": "C64706"},
                                   {"label": "Al Funaytis", "value": "C64707"},
                                   {"label": "Al-Masayel", "value": "C64708"},
                                   {"label": "Sabah as Salim",
                                    "value": "C64709"}],
 "Muchinga Province": [{"label": "Chama", "value": "C148119"},
                       {"label": "Chinsali", "value": "C148120"},
                       {"label": "Isoka", "value": "C148121"},
                       {"label": "Mpika", "value": "C148122"},
                       {"label": "Nakonde", "value": "C148123"}],
 "Mudug": [{"label": "Gaalkacyo", "value": "C107916"},
           {"label": "Hobyo", "value": "C107917"},
           {"label": "Xarardheere", "value": "C107918"}],
 "Mugla": [{"label": "Bayir", "value": "C120799"},
           {"label": "Bodrum", "value": "C120800"},
           {"label": "Bozarmut", "value": "C120801"},
           {"label": "Dalaman", "value": "C120802"},
           {"label": "Dalyan", "value": "C120803"},
           {"label": "Datca", "value": "C120804"},
           {"label": "Fethiye", "value": "C120805"},
           {"label": "Gocek", "value": "C120806"},
           {"label": "Guvercinlik", "value": "C120807"},
           {"label": "Kargi", "value": "C120808"},
           {"label": "Kavaklidere", "value": "C120809"},
           {"label": "Koycegiz Ilcesi", "value": "C120810"},
           {"label": "Marmaris", "value": "C120811"},
           {"label": "Mentes`e", "value": "C120812"},
           {"label": "Milas", "value": "C120813"},
           {"label": "Mugla", "value": "C120814"},
           {"label": "Oludeniz", "value": "C120815"},
           {"label": "Ortaca", "value": "C120816"},
           {"label": "Sarigerme", "value": "C120817"},
           {"label": "Seydikemer", "value": "C120818"},
           {"label": "Turgut", "value": "C120819"},
           {"label": "Turgutreis", "value": "C120820"},
           {"label": "Ula", "value": "C120821"},
           {"label": "Yalikavak", "value": "C120822"},
           {"label": "Yaniklar", "value": "C120823"},
           {"label": "Yatagan", "value": "C120824"}],
 "Muharraq Governorate": [{"label": "Al Hadd", "value": "C8549"},
                          {"label": "Al Muharraq", "value": "C8550"}],
 "Mukdahan": [{"label": "Amphoe Don Tan", "value": "C118794"},
              {"label": "Amphoe Dong Luang", "value": "C118795"},
              {"label": "Amphoe Khamcha-i", "value": "C118796"},
              {"label": "Amphoe Mueang Mukdahan", "value": "C118797"},
              {"label": "Amphoe Nikhom Kham Soi", "value": "C118798"},
              {"label": "Amphoe Nong Sung", "value": "C118799"},
              {"label": "Amphoe Wan Yai", "value": "C118800"},
              {"label": "Mukdahan", "value": "C118801"}],
 "Municipality of Apace": [{"label": "Apace", "value": "C107732"}],
 "Municipality of Ilirska Bistrica": [{"label": "Ilirska Bistrica",
                                       "value": "C107733"}],
 "Municipality of Krsko": [{"label": "Krsko", "value": "C107734"},
                           {"label": "Leskovec pri Krskem", "value": "C107735"},
                           {"label": "Senovo", "value": "C107736"}],
 "Municipality of Skofljica": [{"label": "Lavrica", "value": "C107737"},
                               {"label": "Skofljica", "value": "C107738"}],
 "Munster": [{"label": "Abbeyfeale", "value": "C51468"},
             {"label": "Adare", "value": "C51469"},
             {"label": "Aghada", "value": "C51470"},
             {"label": "An Clar", "value": "C51471"},
             {"label": "Annacotty", "value": "C51472"},
             {"label": "Ardnacrusha", "value": "C51473"},
             {"label": "Askeaton", "value": "C51474"},
             {"label": "Ballina", "value": "C51475"},
             {"label": "Ballybunnion", "value": "C51476"},
             {"label": "Bandon", "value": "C51477"},
             {"label": "Bantry", "value": "C51478"},
             {"label": "Blarney", "value": "C51479"},
             {"label": "Caherconlish", "value": "C51480"},
             {"label": "Cahersiveen", "value": "C51481"},
             {"label": "Cahir", "value": "C51482"},
             {"label": "Carrick-on-Suir", "value": "C51483"},
             {"label": "Carrigaline", "value": "C51484"},
             {"label": "Carrigtwohill", "value": "C51485"},
             {"label": "Cashel", "value": "C51486"},
             {"label": "Castleconnell", "value": "C51487"},
             {"label": "Castleisland", "value": "C51488"},
             {"label": "Castlemartyr", "value": "C51489"},
             {"label": "Ciarrai", "value": "C51490"},
             {"label": "Cill Airne", "value": "C51491"},
             {"label": "Clonakilty", "value": "C51492"},
             {"label": "Cloyne", "value": "C51493"},
             {"label": "Cluain Meala", "value": "C51494"},
             {"label": "Cobh", "value": "C51495"},
             {"label": "Cork", "value": "C51496"},
             {"label": "Cork City", "value": "C51497"},
             {"label": "County Cork", "value": "C51498"},
             {"label": "County Tipperary", "value": "C51499"},
             {"label": "Croom", "value": "C51500"},
             {"label": "Crosshaven", "value": "C51501"},
             {"label": "Derry", "value": "C51502"},
             {"label": "Dingle", "value": "C51503"},
             {"label": "Dungarvan", "value": "C51504"},
             {"label": "Dunmanway", "value": "C51505"},
             {"label": "Dunmore East", "value": "C51506"},
             {"label": "Ennis", "value": "C51507"},
             {"label": "Fermoy", "value": "C51508"},
             {"label": "Fethard", "value": "C51509"},
             {"label": "Kanturk", "value": "C51510"},
             {"label": "Kenmare", "value": "C51511"},
             {"label": "Killaloe", "value": "C51512"},
             {"label": "Killorglin", "value": "C51513"},
             {"label": "Killumney", "value": "C51514"},
             {"label": "Kilmallock", "value": "C51515"},
             {"label": "Kilrush", "value": "C51516"},
             {"label": "Kinsale", "value": "C51517"},
             {"label": "Listowel", "value": "C51518"},
             {"label": "Luimneach", "value": "C51519"},
             {"label": "Macroom", "value": "C51520"},
             {"label": "Mallow", "value": "C51521"},
             {"label": "Midleton", "value": "C51522"},
             {"label": "Millstreet", "value": "C51523"},
             {"label": "Mitchelstown", "value": "C51524"},
             {"label": "Moroe", "value": "C51525"},
             {"label": "Moyross", "value": "C51526"},
             {"label": "Nenagh", "value": "C51527"},
             {"label": "Nenagh Bridge", "value": "C51528"},
             {"label": "Newcastle West", "value": "C51529"},
             {"label": "Newmarket on Fergus", "value": "C51530"},
             {"label": "Newport", "value": "C51531"},
             {"label": "Passage West", "value": "C51532"},
             {"label": "Portlaw", "value": "C51533"},
             {"label": "Rath Luirc", "value": "C51534"},
             {"label": "Rathcormac", "value": "C51535"},
             {"label": "Rathkeale", "value": "C51536"},
             {"label": "Roscrea", "value": "C51537"},
             {"label": "Shannon", "value": "C51538"},
             {"label": "Sixmilebridge", "value": "C51539"},
             {"label": "Skibbereen", "value": "C51540"},
             {"label": "Templemore", "value": "C51541"},
             {"label": "Thurles", "value": "C51542"},
             {"label": "Tipperary", "value": "C51543"},
             {"label": "Tower", "value": "C51544"},
             {"label": "Tra Mhor", "value": "C51545"},
             {"label": "Tralee", "value": "C51546"},
             {"label": "Waterford", "value": "C51547"},
             {"label": "Watergrasshill", "value": "C51548"},
             {"label": "Whitegate", "value": "C51549"},
             {"label": "Youghal", "value": "C51550"}],
 "Munxar": [{"label": "Munxar", "value": "C65902"}],
 "Muramvya Province": [{"label": "Muramvya", "value": "C16452"}],
 "Muranga": [{"label": "Kangema", "value": "C64611"},
             {"label": "Karuri", "value": "C64612"},
             {"label": "Maragua", "value": "C64613"},
             {"label": "Muranga", "value": "C64614"}],
 "Murcia": [{"label": "Abanilla", "value": "C114532"},
            {"label": "Abaran", "value": "C114533"},
            {"label": "aguilas", "value": "C114534"},
            {"label": "Albudeite", "value": "C114535"},
            {"label": "Alcantarilla", "value": "C114536"},
            {"label": "Aledo", "value": "C114537"},
            {"label": "Alguazas", "value": "C114538"},
            {"label": "Alhama de Murcia", "value": "C114539"},
            {"label": "Archena", "value": "C114540"},
            {"label": "Atamaria", "value": "C114541"},
            {"label": "Barrio de la Concepcion", "value": "C114542"},
            {"label": "Beniel", "value": "C114543"},
            {"label": "Blanca", "value": "C114544"},
            {"label": "Bullas", "value": "C114545"},
            {"label": "Calasparra", "value": "C114546"},
            {"label": "Campos del Rio", "value": "C114547"},
            {"label": "Caravaca", "value": "C114548"},
            {"label": "Cartagena", "value": "C114549"},
            {"label": "Cehegin", "value": "C114550"},
            {"label": "Ceuti", "value": "C114551"},
            {"label": "Cieza", "value": "C114552"},
            {"label": "Cobatillas", "value": "C114553"},
            {"label": "El Plan", "value": "C114554"},
            {"label": "Esparragal", "value": "C114555"},
            {"label": "Fortuna", "value": "C114556"},
            {"label": "Fuente-alamo de Murcia", "value": "C114557"},
            {"label": "Jumilla", "value": "C114558"},
            {"label": "La Manga del Mar Menor", "value": "C114559"},
            {"label": "La Torrecilla", "value": "C114560"},
            {"label": "La Union", "value": "C114561"},
            {"label": "Las Torres de Cotillas", "value": "C114562"},
            {"label": "Librilla", "value": "C114563"},
            {"label": "Lo Pagan", "value": "C114564"},
            {"label": "Lorca", "value": "C114565"},
            {"label": "Lorqui", "value": "C114566"},
            {"label": "Los Alcazares", "value": "C114567"},
            {"label": "Los Gabatos", "value": "C114568"},
            {"label": "Los Martinez", "value": "C114569"},
            {"label": "Mazarron", "value": "C114570"},
            {"label": "Molina de Segura", "value": "C114571"},
            {"label": "Moratalla", "value": "C114572"},
            {"label": "Mula", "value": "C114573"},
            {"label": "Murcia", "value": "C114574"},
            {"label": "Ojos", "value": "C114575"},
            {"label": "Pliego", "value": "C114576"},
            {"label": "Puerto Lumbreras", "value": "C114577"},
            {"label": "Ricote", "value": "C114578"},
            {"label": "San Javier", "value": "C114579"},
            {"label": "San Pedro del Pinatar", "value": "C114580"},
            {"label": "Sangonera la Verde", "value": "C114581"},
            {"label": "Santa Cruz", "value": "C114582"},
            {"label": "Santiago de la Ribera", "value": "C114583"},
            {"label": "Santomera", "value": "C114584"},
            {"label": "Torre-Pacheco", "value": "C114585"},
            {"label": "Totana", "value": "C114586"},
            {"label": "Ulea", "value": "C114587"},
            {"label": "Yecla", "value": "C114588"}],
 "Mures County": [{"label": "Abus", "value": "C97020"},
                  {"label": "Acatari", "value": "C97021"},
                  {"label": "Adamus", "value": "C97022"},
                  {"label": "Adrian", "value": "C97023"},
                  {"label": "Adrianu Mare", "value": "C97024"},
                  {"label": "Adrianu Mic", "value": "C97025"},
                  {"label": "Agristeu", "value": "C97026"},
                  {"label": "Albesti", "value": "C97027"},
                  {"label": "Alunis", "value": "C97028"},
                  {"label": "Andreneasa", "value": "C97029"},
                  {"label": "Angofa", "value": "C97030"},
                  {"label": "Apalina", "value": "C97031"},
                  {"label": "Apold", "value": "C97032"},
                  {"label": "Archita", "value": "C97033"},
                  {"label": "Arsita", "value": "C97034"},
                  {"label": "Atintis", "value": "C97035"},
                  {"label": "Aurel Vlaicu", "value": "C97036"},
                  {"label": "Avramesti", "value": "C97037"},
                  {"label": "Bagaciu", "value": "C97038"},
                  {"label": "Bahnea", "value": "C97039"},
                  {"label": "Baita", "value": "C97040"},
                  {"label": "Bala", "value": "C97041"},
                  {"label": "Balauseri", "value": "C97042"},
                  {"label": "Balda", "value": "C97043"},
                  {"label": "Band", "value": "C97044"},
                  {"label": "Bara", "value": "C97045"},
                  {"label": "Barbosi", "value": "C97046"},
                  {"label": "Bardesti", "value": "C97047"},
                  {"label": "Barlibas", "value": "C97048"},
                  {"label": "Barlibasoaia", "value": "C97049"},
                  {"label": "Barza", "value": "C97050"},
                  {"label": "Batos", "value": "C97051"},
                  {"label": "Bedeni", "value": "C97052"},
                  {"label": "Beica de Jos", "value": "C97053"},
                  {"label": "Beica de Sus", "value": "C97054"},
                  {"label": "Bereni", "value": "C97055"},
                  {"label": "Berghia", "value": "C97056"},
                  {"label": "Bernadea", "value": "C97057"},
                  {"label": "Beu", "value": "C97058"},
                  {"label": "Bezid", "value": "C97059"},
                  {"label": "Bicasu", "value": "C97060"},
                  {"label": "Bichis", "value": "C97061"},
                  {"label": "Bistra Muresului", "value": "C97062"},
                  {"label": "Blidireasa", "value": "C97063"},
                  {"label": "Bobohalma", "value": "C97064"},
                  {"label": "Bogata", "value": "C97065"},
                  {"label": "Boiu", "value": "C97066"},
                  {"label": "Bolintineni", "value": "C97067"},
                  {"label": "Bologaia", "value": "C97068"},
                  {"label": "Bord", "value": "C97069"},
                  {"label": "Bordosiu", "value": "C97070"},
                  {"label": "Borzia", "value": "C97071"},
                  {"label": "Botei", "value": "C97072"},
                  {"label": "Botez", "value": "C97073"},
                  {"label": "Botorca", "value": "C97074"},
                  {"label": "Bozed", "value": "C97075"},
                  {"label": "Bozeni", "value": "C97076"},
                  {"label": "Bradetelu", "value": "C97077"},
                  {"label": "Brancovenesti", "value": "C97078"},
                  {"label": "Breaza", "value": "C97079"},
                  {"label": "Budiu Mic", "value": "C97080"},
                  {"label": "Bujor", "value": "C97081"},
                  {"label": "Bujor-Hodaie", "value": "C97082"},
                  {"label": "Caciulata", "value": "C97083"},
                  {"label": "Cacuciu", "value": "C97084"},
                  {"label": "Calimanesti", "value": "C97085"},
                  {"label": "Calugareni", "value": "C97086"},
                  {"label": "Caluseri", "value": "C97087"},
                  {"label": "Campenita", "value": "C97088"},
                  {"label": "Campu Cetatii", "value": "C97089"},
                  {"label": "Candu", "value": "C97090"},
                  {"label": "Capalna de Sus", "value": "C97091"},
                  {"label": "Capeti", "value": "C97092"},
                  {"label": "Capusu de Campie", "value": "C97093"},
                  {"label": "Casva", "value": "C97094"},
                  {"label": "Cecalaca", "value": "C97095"},
                  {"label": "Ceie", "value": "C97096"},
                  {"label": "Cerghid", "value": "C97097"},
                  {"label": "Cerghizel", "value": "C97098"},
                  {"label": "Ceuas", "value": "C97099"},
                  {"label": "Ceuasu de Campie", "value": "C97100"},
                  {"label": "Chendu", "value": "C97101"},
                  {"label": "Chetani", "value": "C97102"},
                  {"label": "Chibed", "value": "C97103"},
                  {"label": "Chiheru de Jos", "value": "C97104"},
                  {"label": "Chiheru de Sus", "value": "C97105"},
                  {"label": "Chinari", "value": "C97106"},
                  {"label": "Chincius", "value": "C97107"},
                  {"label": "Chirileu", "value": "C97108"},
                  {"label": "Chisalita", "value": "C97109"},
                  {"label": "Ciba", "value": "C97110"},
                  {"label": "Cibu", "value": "C97111"},
                  {"label": "Cinta", "value": "C97112"},
                  {"label": "Cioarga", "value": "C97113"},
                  {"label": "Ciobotani", "value": "C97114"},
                  {"label": "Cipaieni", "value": "C97115"},
                  {"label": "Cipau", "value": "C97116"},
                  {"label": "Ciretea", "value": "C97117"},
                  {"label": "Cirhagau", "value": "C97118"},
                  {"label": "Ciulea", "value": "C97119"},
                  {"label": "Ciurgau", "value": "C97120"},
                  {"label": "Cloasterf", "value": "C97121"},
                  {"label": "Coasta Grindului", "value": "C97122"},
                  {"label": "Coasta Mare", "value": "C97123"},
                  {"label": "Comori", "value": "C97124"},
                  {"label": "Comuna Coroisanmartin", "value": "C97125"},
                  {"label": "Comuna Ibanesti Padure", "value": "C97126"},
                  {"label": "Comuna Rusii Munti", "value": "C97127"},
                  {"label": "Corbesti", "value": "C97128"},
                  {"label": "Cordos", "value": "C97129"},
                  {"label": "Cornesti", "value": "C97130"},
                  {"label": "Coroi", "value": "C97131"},
                  {"label": "Corunca", "value": "C97132"},
                  {"label": "Cotorinau", "value": "C97133"},
                  {"label": "Cotus", "value": "C97134"},
                  {"label": "Cozma", "value": "C97135"},
                  {"label": "Craciunesti", "value": "C97136"},
                  {"label": "Craiesti", "value": "C97137"},
                  {"label": "Cris", "value": "C97138"},
                  {"label": "Cristesti", "value": "C97139"},
                  {"label": "Cucerdea", "value": "C97140"},
                  {"label": "Cuci", "value": "C97141"},
                  {"label": "Cuiesd", "value": "C97142"},
                  {"label": "Culpiu", "value": "C97143"},
                  {"label": "Cund", "value": "C97144"},
                  {"label": "Curete", "value": "C97145"},
                  {"label": "Curteni", "value": "C97146"},
                  {"label": "Custelnic", "value": "C97147"},
                  {"label": "Daia", "value": "C97148"},
                  {"label": "Dalu", "value": "C97149"},
                  {"label": "Dambau", "value": "C97150"},
                  {"label": "Dambu", "value": "C97151"},
                  {"label": "Damieni", "value": "C97152"},
                  {"label": "Danes", "value": "C97153"},
                  {"label": "Dataseni", "value": "C97154"},
                  {"label": "Deag", "value": "C97155"},
                  {"label": "Deaj", "value": "C97156"},
                  {"label": "Deda", "value": "C97157"},
                  {"label": "Dedrad", "value": "C97158"},
                  {"label": "Deleni", "value": "C97159"},
                  {"label": "Dileu Nou", "value": "C97160"},
                  {"label": "Dileu Vechi", "value": "C97161"},
                  {"label": "Dobra", "value": "C97162"},
                  {"label": "Draculea Bandului", "value": "C97163"},
                  {"label": "Drojdii", "value": "C97164"},
                  {"label": "Dubistea de Padure", "value": "C97165"},
                  {"label": "Dulcea", "value": "C97166"},
                  {"label": "Dumbrava", "value": "C97167"},
                  {"label": "Dumbravioara", "value": "C97168"},
                  {"label": "Dumitreni", "value": "C97169"},
                  {"label": "Dumitrestii", "value": "C97170"},
                  {"label": "Dupa Deal", "value": "C97171"},
                  {"label": "Ercea", "value": "C97172"},
                  {"label": "Eremieni", "value": "C97173"},
                  {"label": "Eremitu", "value": "C97174"},
                  {"label": "Ernei", "value": "C97175"},
                  {"label": "Fanate", "value": "C97176"},
                  {"label": "Fanatele Madarasului", "value": "C97177"},
                  {"label": "Fanatele Socolului", "value": "C97178"},
                  {"label": "Fantana Babii", "value": "C97179"},
                  {"label": "Fantanele", "value": "C97180"},
                  {"label": "Faragau", "value": "C97181"},
                  {"label": "Feleag", "value": "C97182"},
                  {"label": "Filea", "value": "C97183"},
                  {"label": "Filitelnic", "value": "C97184"},
                  {"label": "Filpisu Mare", "value": "C97185"},
                  {"label": "Filpisu Mic", "value": "C97186"},
                  {"label": "Fitcau", "value": "C97187"},
                  {"label": "Foi", "value": "C97188"},
                  {"label": "Frunzeni", "value": "C97189"},
                  {"label": "Fundatura", "value": "C97190"},
                  {"label": "Fundoaia", "value": "C97191"},
                  {"label": "Gaiesti", "value": "C97192"},
                  {"label": "Galaoaia", "value": "C97193"},
                  {"label": "Galateni", "value": "C97194"},
                  {"label": "Galesti", "value": "C97195"},
                  {"label": "Gambut", "value": "C97196"},
                  {"label": "Ganesti", "value": "C97197"},
                  {"label": "Gaura Sangerului", "value": "C97198"},
                  {"label": "Gheja", "value": "C97199"},
                  {"label": "Gheorghe Doja", "value": "C97200"},
                  {"label": "Ghidasteu", "value": "C97201"},
                  {"label": "Ghindari", "value": "C97202"},
                  {"label": "Ghinesti", "value": "C97203"},
                  {"label": "Giulus", "value": "C97204"},
                  {"label": "Giurgis", "value": "C97205"},
                  {"label": "Glajarie", "value": "C97206"},
                  {"label": "Glodeni", "value": "C97207"},
                  {"label": "Gogan", "value": "C97208"},
                  {"label": "Goreni", "value": "C97209"},
                  {"label": "Gornesti", "value": "C97210"},
                  {"label": "Gradini", "value": "C97211"},
                  {"label": "Grausorul", "value": "C97212"},
                  {"label": "Grebenisu de Campie", "value": "C97213"},
                  {"label": "Grindeni", "value": "C97214"},
                  {"label": "Groapa Radaii", "value": "C97215"},
                  {"label": "Gruisor", "value": "C97216"},
                  {"label": "Gurghiu", "value": "C97217"},
                  {"label": "Habic", "value": "C97218"},
                  {"label": "Hadareni", "value": "C97219"},
                  {"label": "Hagau", "value": "C97220"},
                  {"label": "Haranglab", "value": "C97221"},
                  {"label": "Hartau", "value": "C97222"},
                  {"label": "Herepea", "value": "C97223"},
                  {"label": "Herghelia", "value": "C97224"},
                  {"label": "Hetiur", "value": "C97225"},
                  {"label": "Hodac", "value": "C97226"},
                  {"label": "Hodaia", "value": "C97227"},
                  {"label": "Hodosa", "value": "C97228"},
                  {"label": "Iara de Mures", "value": "C97229"},
                  {"label": "Ibanesti-Padure", "value": "C97230"},
                  {"label": "Icland", "value": "C97231"},
                  {"label": "Iclandu Mare", "value": "C97232"},
                  {"label": "Iclanzel", "value": "C97233"},
                  {"label": "Ideciu de Jos", "value": "C97234"},
                  {"label": "Idicel-Padure", "value": "C97235"},
                  {"label": "Idiciu", "value": "C97236"},
                  {"label": "Idrifaia", "value": "C97237"},
                  {"label": "Iernut", "value": "C97238"},
                  {"label": "Iernuteni", "value": "C97239"},
                  {"label": "Ihod", "value": "C97240"},
                  {"label": "Ilieni", "value": "C97241"},
                  {"label": "Iliesi", "value": "C97242"},
                  {"label": "Ilioara", "value": "C97243"},
                  {"label": "Iod", "value": "C97244"},
                  {"label": "Isla", "value": "C97245"},
                  {"label": "Istan-Tau", "value": "C97246"},
                  {"label": "Istihaza", "value": "C97247"},
                  {"label": "Ivanesti", "value": "C97248"},
                  {"label": "Jabenita", "value": "C97249"},
                  {"label": "Jacodu", "value": "C97250"},
                  {"label": "Jacu", "value": "C97251"},
                  {"label": "Lapusna", "value": "C97252"},
                  {"label": "Larga", "value": "C97253"},
                  {"label": "Lascud", "value": "C97254"},
                  {"label": "Laslau Mare", "value": "C97255"},
                  {"label": "Laslau Mic", "value": "C97256"},
                  {"label": "Laureni", "value": "C97257"},
                  {"label": "Lechincioara", "value": "C97258"},
                  {"label": "Lechinta", "value": "C97259"},
                  {"label": "Lefaia", "value": "C97260"},
                  {"label": "Lenis", "value": "C97261"},
                  {"label": "Leordeni", "value": "C97262"},
                  {"label": "Leorinta", "value": "C97263"},
                  {"label": "Leorinta-Saulia", "value": "C97264"},
                  {"label": "Lepindea", "value": "C97265"},
                  {"label": "Lint", "value": "C97266"},
                  {"label": "Livezeni", "value": "C97267"},
                  {"label": "Logig", "value": "C97268"},
                  {"label": "Lotu", "value": "C97269"},
                  {"label": "Ludus", "value": "C97270"},
                  {"label": "Luieriu", "value": "C97271"},
                  {"label": "Lunca", "value": "C97272"},
                  {"label": "Lunca Bradului", "value": "C97273"},
                  {"label": "Lunca Muresului", "value": "C97274"},
                  {"label": "Macicasesti", "value": "C97275"},
                  {"label": "Madaras", "value": "C97276"},
                  {"label": "Madaraseni", "value": "C97277"},
                  {"label": "Magherani", "value": "C97278"},
                  {"label": "Magherus", "value": "C97279"},
                  {"label": "Maia", "value": "C97280"},
                  {"label": "Maiad", "value": "C97281"},
                  {"label": "Maioresti", "value": "C97282"},
                  {"label": "Malaesti", "value": "C97283"},
                  {"label": "Maldaoci", "value": "C97284"},
                  {"label": "Malea", "value": "C97285"},
                  {"label": "Marasesti", "value": "C97286"},
                  {"label": "Marculeni", "value": "C97287"},
                  {"label": "Matrici", "value": "C97288"},
                  {"label": "Merisor", "value": "C97289"},
                  {"label": "Merisoru", "value": "C97290"},
                  {"label": "Mestera", "value": "C97291"},
                  {"label": "Mica", "value": "C97292"},
                  {"label": "Miercurea Nirajului", "value": "C97293"},
                  {"label": "Mihai Viteazu", "value": "C97294"},
                  {"label": "Mihesu de Campie", "value": "C97295"},
                  {"label": "Milasel", "value": "C97296"},
                  {"label": "Mirigioaia", "value": "C97297"},
                  {"label": "Mitresti", "value": "C97298"},
                  {"label": "Moara de Jos", "value": "C97299"},
                  {"label": "Mogoaia", "value": "C97300"},
                  {"label": "Moisa", "value": "C97301"},
                  {"label": "Morareni", "value": "C97302"},
                  {"label": "Moresti", "value": "C97303"},
                  {"label": "Morut", "value": "C97304"},
                  {"label": "Mosuni", "value": "C97305"},
                  {"label": "Mura Mare", "value": "C97306"},
                  {"label": "Mura Mica", "value": "C97307"},
                  {"label": "Mureni", "value": "C97308"},
                  {"label": "Mureseni", "value": "C97309"},
                  {"label": "Murgesti", "value": "C97310"},
                  {"label": "Nadasa", "value": "C97311"},
                  {"label": "Nades", "value": "C97312"},
                  {"label": "Nandra", "value": "C97313"},
                  {"label": "Nazna", "value": "C97314"},
                  {"label": "Neagra", "value": "C97315"},
                  {"label": "Neaua", "value": "C97316"},
                  {"label": "Negrenii de Campie", "value": "C97317"},
                  {"label": "Nicolesti", "value": "C97318"},
                  {"label": "Nima Milaselului", "value": "C97319"},
                  {"label": "Nima Raciului", "value": "C97320"},
                  {"label": "Oarba de Mures", "value": "C97321"},
                  {"label": "Obarsie", "value": "C97322"},
                  {"label": "Odrihei", "value": "C97323"},
                  {"label": "Ogra", "value": "C97324"},
                  {"label": "Onuca", "value": "C97325"},
                  {"label": "Ormenis", "value": "C97326"},
                  {"label": "Oroiu", "value": "C97327"},
                  {"label": "Orosia", "value": "C97328"},
                  {"label": "Orsova", "value": "C97329"},
                  {"label": "Ozd", "value": "C97330"},
                  {"label": "Pacureni", "value": "C97331"},
                  {"label": "Padurea", "value": "C97332"},
                  {"label": "Padureni", "value": "C97333"},
                  {"label": "Paingeni", "value": "C97334"},
                  {"label": "Panet", "value": "C97335"},
                  {"label": "Papiu Ilarian", "value": "C97336"},
                  {"label": "Parau Crucii", "value": "C97337"},
                  {"label": "Parau Mare", "value": "C97338"},
                  {"label": "Pasareni", "value": "C97339"},
                  {"label": "Paucisoara", "value": "C97340"},
                  {"label": "Pauloaia", "value": "C97341"},
                  {"label": "Peris", "value": "C97342"},
                  {"label": "Petea", "value": "C97343"},
                  {"label": "Petelea", "value": "C97344"},
                  {"label": "Petrilaca", "value": "C97345"},
                  {"label": "Petrilaca de Mures", "value": "C97346"},
                  {"label": "Pietris", "value": "C97347"},
                  {"label": "Pipea", "value": "C97348"},
                  {"label": "Poarta", "value": "C97349"},
                  {"label": "Poduri", "value": "C97350"},
                  {"label": "Pogaceaua", "value": "C97351"},
                  {"label": "Poienita", "value": "C97352"},
                  {"label": "Porumbac", "value": "C97353"},
                  {"label": "Porumbeni", "value": "C97354"},
                  {"label": "Pripoare", "value": "C97355"},
                  {"label": "Pusta", "value": "C97356"},
                  {"label": "Racamet", "value": "C97357"},
                  {"label": "Raciu", "value": "C97358"},
                  {"label": "Ranta", "value": "C97359"},
                  {"label": "Rapa de Jos", "value": "C97360"},
                  {"label": "Rastolita", "value": "C97361"},
                  {"label": "Razoare", "value": "C97362"},
                  {"label": "Recea", "value": "C97363"},
                  {"label": "Reghin", "value": "C97364"},
                  {"label": "Remetea", "value": "C97365"},
                  {"label": "Rigmani", "value": "C97366"},
                  {"label": "Rora", "value": "C97367"},
                  {"label": "Rosiori", "value": "C97368"},
                  {"label": "Roteni", "value": "C97369"},
                  {"label": "Roua", "value": "C97370"},
                  {"label": "Rusii-Munti", "value": "C97371"},
                  {"label": "Sabed", "value": "C97372"},
                  {"label": "Sacadat", "value": "C97373"},
                  {"label": "Sacalu de Padure", "value": "C97374"},
                  {"label": "Sacareni", "value": "C97375"},
                  {"label": "Saes", "value": "C97376"},
                  {"label": "Salard", "value": "C97377"},
                  {"label": "Salasuri", "value": "C97378"},
                  {"label": "Salcud", "value": "C97379"},
                  {"label": "Sambrias", "value": "C97380"},
                  {"label": "Sancraiu de Mures", "value": "C97381"},
                  {"label": "Sandru", "value": "C97382"},
                  {"label": "Sangeorgiu de Campie", "value": "C97383"},
                  {"label": "Sangeorgiu de Mures", "value": "C97384"},
                  {"label": "Sangeorgiu de Padure", "value": "C97385"},
                  {"label": "Sanger", "value": "C97386"},
                  {"label": "Sangeru de Padure", "value": "C97387"},
                  {"label": "Saniacob", "value": "C97388"},
                  {"label": "Sanisor", "value": "C97389"},
                  {"label": "Sanmarghita", "value": "C97390"},
                  {"label": "Sanmartinu de Campie", "value": "C97391"},
                  {"label": "Sanmihai de Padure", "value": "C97392"},
                  {"label": "Sanpaul", "value": "C97393"},
                  {"label": "Sanpetru de Campie", "value": "C97394"},
                  {"label": "Sansimion", "value": "C97395"},
                  {"label": "Santana de Mures", "value": "C97396"},
                  {"label": "Santioana", "value": "C97397"},
                  {"label": "Santioana de Mures", "value": "C97398"},
                  {"label": "Santu", "value": "C97399"},
                  {"label": "Sanvasii", "value": "C97400"},
                  {"label": "Sapartoc", "value": "C97401"},
                  {"label": "Sarateni", "value": "C97402"},
                  {"label": "Sardu Nirajului", "value": "C97403"},
                  {"label": "Sarmasel", "value": "C97404"},
                  {"label": "Sarmasu", "value": "C97405"},
                  {"label": "Saschiz", "value": "C97406"},
                  {"label": "Satu Nou", "value": "C97407"},
                  {"label": "Saulia", "value": "C97408"},
                  {"label": "Saulita", "value": "C97409"},
                  {"label": "Sausa", "value": "C97410"},
                  {"label": "Scurta", "value": "C97411"},
                  {"label": "Sebes", "value": "C97412"},
                  {"label": "Seleus", "value": "C97413"},
                  {"label": "Senereus", "value": "C97414"},
                  {"label": "Serbeni", "value": "C97415"},
                  {"label": "Seuca", "value": "C97416"},
                  {"label": "Seulia de Mures", "value": "C97417"},
                  {"label": "Sfantu Gheorghe", "value": "C97418"},
                  {"label": "Sicele", "value": "C97419"},
                  {"label": "Sighisoara", "value": "C97420"},
                  {"label": "Silea Nirajului", "value": "C97421"},
                  {"label": "Sincai", "value": "C97422"},
                  {"label": "Sincai-Fanate", "value": "C97423"},
                  {"label": "Soard", "value": "C97424"},
                  {"label": "Socolu de Campie", "value": "C97425"},
                  {"label": "Soimus", "value": "C97426"},
                  {"label": "Solocma", "value": "C97427"},
                  {"label": "Solovastru", "value": "C97428"},
                  {"label": "Somostelnic", "value": "C97429"},
                  {"label": "Soromiclea", "value": "C97430"},
                  {"label": "Sovata", "value": "C97431"},
                  {"label": "Stanceni", "value": "C97432"},
                  {"label": "Stefanca", "value": "C97433"},
                  {"label": "Stefaneaca", "value": "C97434"},
                  {"label": "Stejarenii", "value": "C97435"},
                  {"label": "Stejeris", "value": "C97436"},
                  {"label": "Sub Padure", "value": "C97437"},
                  {"label": "Suplac", "value": "C97438"},
                  {"label": "Suseni", "value": "C97439"},
                  {"label": "Suveica", "value": "C97440"},
                  {"label": "Tablaseni", "value": "C97441"},
                  {"label": "Tampa", "value": "C97442"},
                  {"label": "Targu Mures", "value": "C97443"},
                  {"label": "Tarnaveni", "value": "C97444"},
                  {"label": "Tau", "value": "C97445"},
                  {"label": "Taureni", "value": "C97446"},
                  {"label": "Teleac", "value": "C97447"},
                  {"label": "Tigmandru", "value": "C97448"},
                  {"label": "Tiptelnic", "value": "C97449"},
                  {"label": "Tireu", "value": "C97450"},
                  {"label": "Tirimia", "value": "C97451"},
                  {"label": "Tirimioara", "value": "C97452"},
                  {"label": "Tisieu", "value": "C97453"},
                  {"label": "Titiana", "value": "C97454"},
                  {"label": "Toaca", "value": "C97455"},
                  {"label": "Tofalau", "value": "C97456"},
                  {"label": "Toldal", "value": "C97457"},
                  {"label": "Tonciu", "value": "C97458"},
                  {"label": "Topa", "value": "C97459"},
                  {"label": "Torba", "value": "C97460"},
                  {"label": "Trei Sate", "value": "C97461"},
                  {"label": "Troita", "value": "C97462"},
                  {"label": "Tusinu", "value": "C97463"},
                  {"label": "Uila", "value": "C97464"},
                  {"label": "Ulies", "value": "C97465"},
                  {"label": "Ungheni", "value": "C97466"},
                  {"label": "Uricea", "value": "C97467"},
                  {"label": "Urisiu de Jos", "value": "C97468"},
                  {"label": "Urisiu de Sus", "value": "C97469"},
                  {"label": "Ursoaia", "value": "C97470"},
                  {"label": "Vadas", "value": "C97471"},
                  {"label": "Vadu", "value": "C97472"},
                  {"label": "Vaidacuta", "value": "C97473"},
                  {"label": "Vaideiu", "value": "C97474"},
                  {"label": "Valea", "value": "C97475"},
                  {"label": "Valea Albestiului", "value": "C97476"},
                  {"label": "Valea Daii", "value": "C97477"},
                  {"label": "Valea Fratiei", "value": "C97478"},
                  {"label": "Valea Glodului", "value": "C97479"},
                  {"label": "Valea Iclandului", "value": "C97480"},
                  {"label": "Valea Izvoarelor", "value": "C97481"},
                  {"label": "Valea Larga", "value": "C97482"},
                  {"label": "Valea Mare", "value": "C97483"},
                  {"label": "Valea Padurii", "value": "C97484"},
                  {"label": "Valea Rece", "value": "C97485"},
                  {"label": "Valea Sanmartinului", "value": "C97486"},
                  {"label": "Valea Sanpetrului", "value": "C97487"},
                  {"label": "Valea Sapartocului", "value": "C97488"},
                  {"label": "Valea Sasului", "value": "C97489"},
                  {"label": "Valea Seaca", "value": "C97490"},
                  {"label": "Valea Surii", "value": "C97491"},
                  {"label": "Valea Uliesului", "value": "C97492"},
                  {"label": "Valea Ungurului", "value": "C97493"},
                  {"label": "Valea Uriesului", "value": "C97494"},
                  {"label": "Valeni", "value": "C97495"},
                  {"label": "Valenii", "value": "C97496"},
                  {"label": "Valenii de Mures", "value": "C97497"},
                  {"label": "Valisoara", "value": "C97498"},
                  {"label": "Valureni", "value": "C97499"},
                  {"label": "Vanatori", "value": "C97500"},
                  {"label": "Vargata", "value": "C97501"},
                  {"label": "Vatava", "value": "C97502"},
                  {"label": "Venchi", "value": "C97503"},
                  {"label": "Veta", "value": "C97504"},
                  {"label": "Vetca", "value": "C97505"},
                  {"label": "Vidrasau", "value": "C97506"},
                  {"label": "Viforoasa", "value": "C97507"},
                  {"label": "Viilor", "value": "C97508"},
                  {"label": "Viisoara", "value": "C97509"},
                  {"label": "Visinelu", "value": "C97510"},
                  {"label": "Voiniceni", "value": "C97511"},
                  {"label": "Voivodeni", "value": "C97512"},
                  {"label": "Vulcan", "value": "C97513"},
                  {"label": "Zagar", "value": "C97514"},
                  {"label": "Zapodea", "value": "C97515"},
                  {"label": "Zau de Campie", "value": "C97516"},
                  {"label": "Zimti", "value": "C97517"}],
 "Murmansk Oblast": [{"label": "Abram Mys", "value": "C102621"},
                     {"label": "Afrikanda", "value": "C102622"},
                     {"label": "Alakurtti", "value": "C102623"},
                     {"label": "Apatity", "value": "C102624"},
                     {"label": "Gadzhiyevo", "value": "C102625"},
                     {"label": "Kandalaksha", "value": "C102626"},
                     {"label": "Kandalakshskiy rayon", "value": "C102627"},
                     {"label": "Kirovsk", "value": "C102628"},
                     {"label": "Kolskiy Rayon", "value": "C102629"},
                     {"label": "Kola", "value": "C102630"},
                     {"label": "Kovdor", "value": "C102631"},
                     {"label": "Kovdorskiy Rayon", "value": "C102632"},
                     {"label": "Lovozero", "value": "C102633"},
                     {"label": "Lovozerskiy Rayon", "value": "C102634"},
                     {"label": "Molochnyy", "value": "C102635"},
                     {"label": "Monchegorsk", "value": "C102636"},
                     {"label": "Murmansk", "value": "C102637"},
                     {"label": "Murmashi", "value": "C102638"},
                     {"label": "Nikel", "value": "C102639"},
                     {"label": "Olenegorsk", "value": "C102640"},
                     {"label": "Ostrovnoy", "value": "C102641"},
                     {"label": "Pechenga", "value": "C102642"},
                     {"label": "Pechengskiy Rayon", "value": "C102643"},
                     {"label": "Polyarnyy", "value": "C102644"},
                     {"label": "Polyarnyye Zori", "value": "C102645"},
                     {"label": "Poyakonda", "value": "C102646"},
                     {"label": "Revda", "value": "C102647"},
                     {"label": "Roslyakovo", "value": "C102648"},
                     {"label": "Safonovo", "value": "C102649"},
                     {"label": "Severomorsk", "value": "C102650"},
                     {"label": "Severomorsk-3", "value": "C102651"},
                     {"label": "Shonguy", "value": "C102652"},
                     {"label": "Snezhnogorsk", "value": "C102653"},
                     {"label": "Teriberka", "value": "C102654"},
                     {"label": "Terskiy Rayon", "value": "C102655"},
                     {"label": "Umba", "value": "C102656"},
                     {"label": "Verkhnetulomskiy", "value": "C102657"},
                     {"label": "Vidyayevo", "value": "C102658"},
                     {"label": "Vysokiy", "value": "C102659"},
                     {"label": "Zaozersk", "value": "C102660"},
                     {"label": "Zapolyarnyy", "value": "C102661"},
                     {"label": "Zelenoborskiy", "value": "C102662"}],
 "Murqub": [{"label": "Al Khums", "value": "C65048"},
            {"label": "Masallatah", "value": "C65049"},
            {"label": "Tarhuna", "value": "C65050"}],
 "Murska Sobota City Municipality": [{"label": "Bakovci", "value": "C107739"},
                                     {"label": "Cernelavci",
                                      "value": "C107740"},
                                     {"label": "Krog", "value": "C107741"},
                                     {"label": "Murska Sobota",
                                      "value": "C107742"},
                                     {"label": "Rakican", "value": "C107743"}],
 "Murzuq District": [{"label": "Al Qatrun", "value": "C65051"},
                     {"label": "Murzuq", "value": "C65052"}],
 "Mus": [{"label": "Bulanik", "value": "C120825"},
         {"label": "Haskoy", "value": "C120826"},
         {"label": "Korkut", "value": "C120827"},
         {"label": "Malazgirt", "value": "C120828"},
         {"label": "Merkez", "value": "C120829"},
         {"label": "Mus", "value": "C120830"},
         {"label": "Varto", "value": "C120831"}],
 "Musandam Governorate": [{"label": "Dib Dibba", "value": "C79306"},
                          {"label": "Khasab", "value": "C79307"},
                          {"label": "Madha al Jadidah", "value": "C79308"}],
 "Muscat Governorate": [{"label": "Bawshar", "value": "C79309"},
                        {"label": "Muscat", "value": "C79310"},
                        {"label": "Seeb", "value": "C79311"}],
 "Muta Municipality": [{"label": "Muta", "value": "C107744"}],
 "Muyinga Province": [{"label": "Muyinga", "value": "C16453"}],
 "Mwanza": [{"label": "Ilemela District", "value": "C118244"},
            {"label": "Kihangara", "value": "C118245"},
            {"label": "Malya", "value": "C118246"},
            {"label": "Misasi", "value": "C118247"},
            {"label": "Misungwi", "value": "C118248"},
            {"label": "Mwanza", "value": "C118249"},
            {"label": "Ngudu", "value": "C118250"},
            {"label": "Nyanguge", "value": "C118251"},
            {"label": "Usagara", "value": "C118252"}],
 "Mwaro Province": [{"label": "Mwaro", "value": "C16454"}],
 "Mykolaivska oblast": [{"label": "Arbuzynka", "value": "C122397"},
                        {"label": "Bashtanka", "value": "C122398"},
                        {"label": "Berezanka", "value": "C122399"},
                        {"label": "Bereznehuvate", "value": "C122400"},
                        {"label": "Domanivka", "value": "C122401"},
                        {"label": "Kazanka", "value": "C122402"},
                        {"label": "Kobleve", "value": "C122403"},
                        {"label": "Kostyantynivka", "value": "C122404"},
                        {"label": "Kryve Ozero", "value": "C122405"},
                        {"label": "Lysa Hora", "value": "C122406"},
                        {"label": "Myhiya", "value": "C122407"},
                        {"label": "Mykolayiv", "value": "C122408"},
                        {"label": "Nova Odesa", "value": "C122409"},
                        {"label": "Novyy Buh", "value": "C122410"},
                        {"label": "Ochakiv", "value": "C122411"},
                        {"label": "Olshanskoye", "value": "C122412"},
                        {"label": "Oleksandrivka", "value": "C122413"},
                        {"label": "Parutyne", "value": "C122414"},
                        {"label": "Pervomaysk", "value": "C122415"},
                        {"label": "Pidhorodna", "value": "C122416"},
                        {"label": "Rybakivka", "value": "C122417"},
                        {"label": "Shevchenkove", "value": "C122418"},
                        {"label": "Snihurivka", "value": "C122419"},
                        {"label": "Veselynove", "value": "C122420"},
                        {"label": "Voznesensk", "value": "C122421"},
                        {"label": "Vradiyivka", "value": "C122422"},
                        {"label": "Yuzhnoukrainsk", "value": "C122423"}],
 "Mymensingh District": [{"label": "Gafargaon", "value": "C8648"},
                         {"label": "Jamalpur", "value": "C8649"},
                         {"label": "Muktagacha", "value": "C8650"},
                         {"label": "Mymensingh", "value": "C8651"},
                         {"label": "Netrakona", "value": "C8652"},
                         {"label": "Sarishabari", "value": "C8653"},
                         {"label": "Sherpur", "value": "C8654"}],
 "Naama": [{"label": "Ain Sefra", "value": "C371"},
           {"label": "Naama", "value": "C372"}],
 "Nabatieh Governorate": [{"label": "Ain Ebel", "value": "C64977"},
                          {"label": "Caza de Bent Jbail", "value": "C64978"},
                          {"label": "Caza de Nabatiye", "value": "C64979"},
                          {"label": "Habbouch", "value": "C64980"},
                          {"label": "Marjayoun", "value": "C64981"},
                          {"label": "Nabatiye et Tahta", "value": "C64982"}],
 "Nadur": [{"label": "Nadur", "value": "C65903"}],
 "Nagaland": [{"label": "Dimapur", "value": "C47300"},
              {"label": "Kohima", "value": "C47301"},
              {"label": "Mokokchung", "value": "C47302"},
              {"label": "Mon", "value": "C47303"},
              {"label": "Peren", "value": "C47304"},
              {"label": "Phek", "value": "C47305"},
              {"label": "Tuensang", "value": "C47306"},
              {"label": "Tuensang District", "value": "C47307"},
              {"label": "Wokha", "value": "C47308"},
              {"label": "Zunheboto", "value": "C47309"}],
 "Nagano Prefecture": [{"label": "Azumino-Shi", "value": "C63524"},
                       {"label": "Chikuma Shi", "value": "C63525"},
                       {"label": "Chino", "value": "C63526"},
                       {"label": "Chino Shi", "value": "C63527"},
                       {"label": "Hakuba", "value": "C63528"},
                       {"label": "Hotaka", "value": "C63529"},
                       {"label": "Iida", "value": "C63530"},
                       {"label": "Iida-shi", "value": "C63531"},
                       {"label": "Iiyama", "value": "C63532"},
                       {"label": "Iiyama Shi", "value": "C63533"},
                       {"label": "Ina", "value": "C63534"},
                       {"label": "Ina-shi", "value": "C63535"},
                       {"label": "Kamimaruko", "value": "C63536"},
                       {"label": "Komagane", "value": "C63537"},
                       {"label": "Komagane-shi", "value": "C63538"},
                       {"label": "Komoro", "value": "C63539"},
                       {"label": "Komoro Shi", "value": "C63540"},
                       {"label": "Matsumoto", "value": "C63541"},
                       {"label": "Matsumoto Shi", "value": "C63542"},
                       {"label": "Nagano", "value": "C63543"},
                       {"label": "Nagano Shi", "value": "C63544"},
                       {"label": "Nakano Shi", "value": "C63545"},
                       {"label": "Okaya", "value": "C63546"},
                       {"label": "Okaya Shi", "value": "C63547"},
                       {"label": "Omachi", "value": "C63548"},
                       {"label": "Omachi-shi", "value": "C63549"},
                       {"label": "Saku", "value": "C63550"},
                       {"label": "Saku Shi", "value": "C63551"},
                       {"label": "Shiojiri", "value": "C63552"},
                       {"label": "Shiojiri-shi", "value": "C63553"},
                       {"label": "Suwa", "value": "C63554"},
                       {"label": "Suwa Shi", "value": "C63555"},
                       {"label": "Suzaka", "value": "C63556"},
                       {"label": "Suzaka-shi", "value": "C63557"},
                       {"label": "Tatsuno", "value": "C63558"},
                       {"label": "Tomi", "value": "C63559"},
                       {"label": "Tomi Shi", "value": "C63560"},
                       {"label": "Toyoshina", "value": "C63561"},
                       {"label": "Ueda", "value": "C63562"},
                       {"label": "Ueda Shi", "value": "C63563"}],
 "Nagasaki Prefecture": [{"label": "Fukuecho", "value": "C63564"},
                         {"label": "Goto Shi", "value": "C63565"},
                         {"label": "Hirado", "value": "C63566"},
                         {"label": "Hirado Shi", "value": "C63567"},
                         {"label": "Iki Shi", "value": "C63568"},
                         {"label": "Isahaya", "value": "C63569"},
                         {"label": "Isahaya-shi", "value": "C63570"},
                         {"label": "Matsuura", "value": "C63571"},
                         {"label": "Matsuura Shi", "value": "C63572"},
                         {"label": "Minamishimabara-shi", "value": "C63573"},
                         {"label": "Nagasaki", "value": "C63574"},
                         {"label": "Nagasaki-shi", "value": "C63575"},
                         {"label": "Obita", "value": "C63576"},
                         {"label": "Omura", "value": "C63577"},
                         {"label": "Omura-shi", "value": "C63578"},
                         {"label": "Saikai-shi", "value": "C63579"},
                         {"label": "Sasebo Shi", "value": "C63580"},
                         {"label": "Shimabara", "value": "C63581"},
                         {"label": "Shimabara-shi", "value": "C63582"},
                         {"label": "Togitsu", "value": "C63583"},
                         {"label": "Tsushima Shi", "value": "C63584"},
                         {"label": "Unzen-shi", "value": "C63585"}],
 "Nairobi City": [{"label": "Nairobi", "value": "C64615"},
                  {"label": "Pumwani", "value": "C64616"}],
 "Najaf Governorate": [{"label": "Al Mishkhab", "value": "C51189"},
                       {"label": "Kufa", "value": "C51190"},
                       {"label": "Najaf", "value": "C51191"}],
 "Najran": [{"label": "Najran", "value": "C106787"}],
 "Nakhchivan Autonomous Republic": [{"label": "Cahri", "value": "C8467"},
                                    {"label": "Calxanqala", "value": "C8468"},
                                    {"label": "Culfa", "value": "C8469"},
                                    {"label": "Deste", "value": "C8470"},
                                    {"label": "Heydarabad", "value": "C8471"},
                                    {"label": "Julfa Rayon", "value": "C8472"},
                                    {"label": "Nakhchivan", "value": "C8473"},
                                    {"label": "Oglanqala", "value": "C8474"},
                                    {"label": "Ordubad", "value": "C8475"},
                                    {"label": "Ordubad Rayon",
                                     "value": "C8476"},
                                    {"label": "Qivraq", "value": "C8477"},
                                    {"label": "Sahbuz", "value": "C8478"},
                                    {"label": "Sedarak", "value": "C8479"},
                                    {"label": "Shahbuz Rayon",
                                     "value": "C8480"},
                                    {"label": "Sharur City", "value": "C8481"},
                                    {"label": "Sumbatan-diza",
                                     "value": "C8482"},
                                    {"label": "Tazakend", "value": "C8483"},
                                    {"label": "Yayci", "value": "C8484"}],
 "Nakhon Nayok": [{"label": "Amphoe Ban Na", "value": "C118802"},
                  {"label": "Amphoe Mueang Nakhon Nayok", "value": "C118803"},
                  {"label": "Amphoe Ongkharak", "value": "C118804"},
                  {"label": "Amphoe Pak Phli", "value": "C118805"},
                  {"label": "Nakhon Nayok", "value": "C118806"}],
 "Nakhon Pathom": [{"label": "Amphoe Bang Len", "value": "C118807"},
                   {"label": "Amphoe Don Tum", "value": "C118808"},
                   {"label": "Amphoe Mueang Nakhon Pathom", "value": "C118809"},
                   {"label": "Amphoe Nakhon Chai Si", "value": "C118810"},
                   {"label": "Amphoe Phutthamonthon", "value": "C118811"},
                   {"label": "Amphoe Sam Phran", "value": "C118812"},
                   {"label": "Bang Len", "value": "C118813"},
                   {"label": "Kamphaeng Saen District", "value": "C118814"},
                   {"label": "Nakhon Pathom", "value": "C118815"},
                   {"label": "Sam Phran", "value": "C118816"}],
 "Nakhon Phanom": [{"label": "Amphoe Ban Phaeng", "value": "C118817"},
                   {"label": "Amphoe Mueang Nakhon Phanom", "value": "C118818"},
                   {"label": "Amphoe Na Kae", "value": "C118819"},
                   {"label": "Amphoe Na Thom", "value": "C118820"},
                   {"label": "Amphoe Na Wa", "value": "C118821"},
                   {"label": "Amphoe Phon Sawan", "value": "C118822"},
                   {"label": "Amphoe Pla Pak", "value": "C118823"},
                   {"label": "Amphoe Renu Nakhon", "value": "C118824"},
                   {"label": "Amphoe Si Songkhram", "value": "C118825"},
                   {"label": "Amphoe Tha Uthen", "value": "C118826"},
                   {"label": "Amphoe That Phanom", "value": "C118827"},
                   {"label": "Amphoe Wangyang", "value": "C118828"},
                   {"label": "Na Wa", "value": "C118829"},
                   {"label": "Nakhon Phanom", "value": "C118830"},
                   {"label": "That Phanom", "value": "C118831"}],
 "Nakhon Ratchasima": [{"label": "Amphoe Ban Lueam", "value": "C118832"},
                       {"label": "Amphoe Bua Lai", "value": "C118833"},
                       {"label": "Amphoe Bua Yai", "value": "C118834"},
                       {"label": "Amphoe Chakkarat", "value": "C118835"},
                       {"label": "Amphoe Chaloem Phra Kiat",
                        "value": "C118836"},
                       {"label": "Amphoe Chok Chai", "value": "C118837"},
                       {"label": "Amphoe Chum Phuang", "value": "C118838"},
                       {"label": "Amphoe Dan Khun Thot", "value": "C118839"},
                       {"label": "Amphoe Huai Thalaeng", "value": "C118840"},
                       {"label": "Amphoe Kaeng Sanam Nang", "value": "C118841"},
                       {"label": "Amphoe Kham Sakae Saeng", "value": "C118842"},
                       {"label": "Amphoe Kham Thale So", "value": "C118843"},
                       {"label": "Amphoe Khon Buri", "value": "C118844"},
                       {"label": "Amphoe Khong", "value": "C118845"},
                       {"label": "Amphoe Lam Thamen Chai", "value": "C118846"},
                       {"label": "Amphoe Mueang Nakhon Ratchasima",
                        "value": "C118847"},
                       {"label": "Amphoe Mueang Yang", "value": "C118848"},
                       {"label": "Amphoe Non Daeng", "value": "C118849"},
                       {"label": "Amphoe Non Sung", "value": "C118850"},
                       {"label": "Amphoe Non Thai", "value": "C118851"},
                       {"label": "Amphoe Nong Bun Mak", "value": "C118852"},
                       {"label": "Amphoe Pak Chong", "value": "C118853"},
                       {"label": "Amphoe Pak Thong Chai", "value": "C118854"},
                       {"label": "Amphoe Phimai", "value": "C118855"},
                       {"label": "Amphoe Phra Thong Kham", "value": "C118856"},
                       {"label": "Amphoe Prathai", "value": "C118857"},
                       {"label": "Amphoe Sida", "value": "C118858"},
                       {"label": "Amphoe Sikhio", "value": "C118859"},
                       {"label": "Amphoe Sikhiu", "value": "C118860"},
                       {"label": "Amphoe Soeng Sang", "value": "C118861"},
                       {"label": "Amphoe Sung Noen", "value": "C118862"},
                       {"label": "Amphoe Thepharak", "value": "C118863"},
                       {"label": "Amphoe Wang Nam Khiao", "value": "C118864"},
                       {"label": "Ban Huai Thalaeng", "value": "C118865"},
                       {"label": "Bua Yai", "value": "C118866"},
                       {"label": "Chok Chai", "value": "C118867"},
                       {"label": "Dan Khun Thot", "value": "C118868"},
                       {"label": "Kham Sakae Saeng", "value": "C118869"},
                       {"label": "Khon Buri", "value": "C118870"},
                       {"label": "Nakhon Ratchasima", "value": "C118871"},
                       {"label": "Non Sung", "value": "C118872"},
                       {"label": "Non Thai", "value": "C118873"},
                       {"label": "Pak Chong", "value": "C118874"},
                       {"label": "Pak Thong Chai", "value": "C118875"},
                       {"label": "Phimai", "value": "C118876"},
                       {"label": "Soeng Sang", "value": "C118877"},
                       {"label": "Sung Noen", "value": "C118878"}],
 "Nakhon Sawan": [{"label": "Amphoe Banphot Phisai", "value": "C118879"},
                  {"label": "Amphoe Chum Ta Bong", "value": "C118880"},
                  {"label": "Amphoe Chumsaeng", "value": "C118881"},
                  {"label": "Amphoe Kao Liao", "value": "C118882"},
                  {"label": "Amphoe Krok Phra", "value": "C118883"},
                  {"label": "Amphoe Lat Yao", "value": "C118884"},
                  {"label": "Amphoe Mae Poen", "value": "C118885"},
                  {"label": "Amphoe Mae Wong", "value": "C118886"},
                  {"label": "Amphoe Mueang Nakhon Sawan", "value": "C118887"},
                  {"label": "Amphoe Nong Bua", "value": "C118888"},
                  {"label": "Amphoe Phai Sali", "value": "C118889"},
                  {"label": "Amphoe Phayuha Khiri", "value": "C118890"},
                  {"label": "Amphoe Tak Fa", "value": "C118891"},
                  {"label": "Amphoe Takhli", "value": "C118892"},
                  {"label": "Amphoe Tha Tako", "value": "C118893"},
                  {"label": "Kao Liao", "value": "C118894"},
                  {"label": "Lat Yao", "value": "C118895"},
                  {"label": "Nakhon Sawan", "value": "C118896"},
                  {"label": "Nong Bua", "value": "C118897"},
                  {"label": "Phai Sali", "value": "C118898"},
                  {"label": "Phayuha Khiri", "value": "C118899"},
                  {"label": "Takhli", "value": "C118900"}],
 "Nakhon Si Thammarat": [{"label": "Amphoe Bang Khan", "value": "C118901"},
                         {"label": "Amphoe Cha-uat", "value": "C118902"},
                         {"label": "Amphoe Chaloem Phra Kiat",
                          "value": "C118903"},
                         {"label": "Amphoe Chang Klang", "value": "C118904"},
                         {"label": "Amphoe Chawang", "value": "C118905"},
                         {"label": "Amphoe Chian Yai", "value": "C118906"},
                         {"label": "Amphoe Chulabhorn", "value": "C118907"},
                         {"label": "Amphoe Hua Sai", "value": "C118908"},
                         {"label": "Amphoe Khanom", "value": "C118909"},
                         {"label": "Amphoe Lan Saka", "value": "C118910"},
                         {"label": "Amphoe Mueang Nakhon Si Thammarat",
                          "value": "C118911"},
                         {"label": "Amphoe Na Bon", "value": "C118912"},
                         {"label": "Amphoe Nopphitam", "value": "C118913"},
                         {"label": "Amphoe Pak Phanang", "value": "C118914"},
                         {"label": "Amphoe Phipun", "value": "C118915"},
                         {"label": "Amphoe Phra Phrom", "value": "C118916"},
                         {"label": "Amphoe Phrom Khiri", "value": "C118917"},
                         {"label": "Amphoe Ron Phibun", "value": "C118918"},
                         {"label": "Amphoe Sichon", "value": "C118919"},
                         {"label": "Amphoe Tha Sala", "value": "C118920"},
                         {"label": "Amphoe Tham Phannara", "value": "C118921"},
                         {"label": "Amphoe Thung Song", "value": "C118922"},
                         {"label": "Amphoe Thung Yai", "value": "C118923"},
                         {"label": "Chawang", "value": "C118924"},
                         {"label": "Nakhon Si Thammarat", "value": "C118925"},
                         {"label": "Pak Phanang", "value": "C118926"},
                         {"label": "Ron Phibun", "value": "C118927"},
                         {"label": "Tham Phannara", "value": "C118928"},
                         {"label": "Thung Song", "value": "C118929"}],
 "Naklo Municipality": [{"label": "Naklo", "value": "C107745"}],
 "Nakuru": [{"label": "Kijabe", "value": "C64617"},
            {"label": "Molo", "value": "C64618"},
            {"label": "Naivasha", "value": "C64619"},
            {"label": "Nakuru", "value": "C64620"},
            {"label": "Rongai", "value": "C64621"}],
 "Nalut District": [{"label": "Ghadamis", "value": "C65053"},
                    {"label": "Nalut", "value": "C65054"}],
 "Nam Dinh": [{"label": "Huyen Giao Thuy", "value": "C147522"},
              {"label": "Huyen Hai Hau", "value": "C147523"},
              {"label": "Huyen My Loc", "value": "C147524"},
              {"label": "Huyen Nam Truc", "value": "C147525"},
              {"label": "Huyen Nghia Hung", "value": "C147526"},
              {"label": "Huyen Truc Ninh", "value": "C147527"},
              {"label": "Huyen Vu Ban", "value": "C147528"},
              {"label": "Huyen Y Yen", "value": "C147529"},
              {"label": "Nam Dinh", "value": "C147530"}],
 "Namangan Region": [{"label": "Chortoq", "value": "C147045"},
                     {"label": "Chust", "value": "C147046"},
                     {"label": "Haqqulobod", "value": "C147047"},
                     {"label": "Kosonsoy", "value": "C147048"},
                     {"label": "Namangan", "value": "C147049"},
                     {"label": "Pop", "value": "C147050"},
                     {"label": "Torqaorgon", "value": "C147051"},
                     {"label": "Toshbuloq", "value": "C147052"},
                     {"label": "Uchqurghon Shahri", "value": "C147053"},
                     {"label": "Uychi", "value": "C147054"},
                     {"label": "Yangiqorgon", "value": "C147055"}],
 "Nampula Province": [{"label": "Antonio Enes", "value": "C75875"},
                      {"label": "Ilha de Mocambique", "value": "C75876"},
                      {"label": "Mutuali", "value": "C75877"},
                      {"label": "Nacala", "value": "C75878"},
                      {"label": "Nampula", "value": "C75879"}],
 "Nan": [{"label": "Amphoe Ban Luang", "value": "C118930"},
         {"label": "Amphoe Bo Kluea", "value": "C118931"},
         {"label": "Amphoe Chaloem Phra Kiat", "value": "C118932"},
         {"label": "Amphoe Chiang Klang", "value": "C118933"},
         {"label": "Amphoe Mae Charim", "value": "C118934"},
         {"label": "Amphoe Mueang Nan", "value": "C118935"},
         {"label": "Amphoe Na Muen", "value": "C118936"},
         {"label": "Amphoe Na Noi", "value": "C118937"},
         {"label": "Amphoe Phu Phiang", "value": "C118938"},
         {"label": "Amphoe Pua", "value": "C118939"},
         {"label": "Amphoe Santi Suk", "value": "C118940"},
         {"label": "Amphoe Song Khwae", "value": "C118941"},
         {"label": "Amphoe Tha Wang Pha", "value": "C118942"},
         {"label": "Amphoe Thung Chang", "value": "C118943"},
         {"label": "Amphoe Wiang Sa", "value": "C118944"},
         {"label": "Chiang Klang", "value": "C118945"},
         {"label": "Nan", "value": "C118946"}],
 "Nana-Grebizi Economic Prefecture": [{"label": "Kaga Bandoro",
                                       "value": "C17830"},
                                      {"label": "Kaga-Bandoro",
                                       "value": "C17831"}],
 "Nana-Mambere Prefecture": [{"label": "Baoro", "value": "C17832"},
                             {"label": "Bouar", "value": "C17833"}],
 "Nandi": [{"label": "Kapsabet", "value": "C64622"},
           {"label": "Nandi Hills", "value": "C64623"}],
 "Nangarhar": [{"label": "Basawul", "value": "C65"},
               {"label": "Jalalabad", "value": "C66"},
               {"label": "Markaz-e Woluswali-ye achin", "value": "C67"}],
 "Nantou": [{"label": "Lugu", "value": "C117970"},
            {"label": "Nantou", "value": "C117971"},
            {"label": "Puli", "value": "C117972"},
            {"label": "Zhongxing New Village", "value": "C117973"}],
 "Nanumanga": [{"label": "Toga Village", "value": "C121194"}],
 "Napo": [{"label": "Archidona", "value": "C24184"},
          {"label": "Tena", "value": "C24185"}],
 "Nara Prefecture": [{"label": "Gojo", "value": "C63586"},
                     {"label": "Gojo-shi", "value": "C63587"},
                     {"label": "Gose", "value": "C63588"},
                     {"label": "Haibara-akanedai", "value": "C63589"},
                     {"label": "Horyuji", "value": "C63590"},
                     {"label": "Ikoma", "value": "C63591"},
                     {"label": "Ikoma-shi", "value": "C63592"},
                     {"label": "Kashiba-shi", "value": "C63593"},
                     {"label": "Kashihara-shi", "value": "C63594"},
                     {"label": "Katsuragi", "value": "C63595"},
                     {"label": "Katsuragi Shi", "value": "C63596"},
                     {"label": "Nara-shi", "value": "C63597"},
                     {"label": "Sakurai", "value": "C63598"},
                     {"label": "Sakurai-shi", "value": "C63599"},
                     {"label": "Tawaramoto", "value": "C63600"},
                     {"label": "Tenri", "value": "C63601"},
                     {"label": "Tenri-shi", "value": "C63602"},
                     {"label": "Uda Shi", "value": "C63603"},
                     {"label": "Yamatokoriyama-shi", "value": "C63604"},
                     {"label": "Yamatotakada-shi", "value": "C63605"},
                     {"label": "Yoshino-cho", "value": "C63606"}],
 "Narathiwat": [{"label": "Amphoe Ba Cho", "value": "C118947"},
                {"label": "Amphoe Chanae", "value": "C118948"},
                {"label": "Amphoe Cho-airong", "value": "C118949"},
                {"label": "Amphoe Mueang Narathiwat", "value": "C118950"},
                {"label": "Amphoe Ra-ngae", "value": "C118951"},
                {"label": "Amphoe Rueso", "value": "C118952"},
                {"label": "Amphoe Si Sakhon", "value": "C118953"},
                {"label": "Amphoe Su-ngai Kolok", "value": "C118954"},
                {"label": "Amphoe Su-ngai Padi", "value": "C118955"},
                {"label": "Amphoe Sukhirin", "value": "C118956"},
                {"label": "Amphoe Tak Bai", "value": "C118957"},
                {"label": "Amphoe Waeng", "value": "C118958"},
                {"label": "Amphoe Yi-ngo", "value": "C118959"},
                {"label": "Ban Su-ngai Pa Di", "value": "C118960"},
                {"label": "Narathiwat", "value": "C118961"},
                {"label": "Ra-ngae", "value": "C118962"},
                {"label": "Su-ngai Kolok", "value": "C118963"},
                {"label": "Tak Bai", "value": "C118964"}],
 "Narino": [{"label": "Aldana", "value": "C20303"},
            {"label": "Ancuya", "value": "C20304"},
            {"label": "Arboleda", "value": "C20305"},
            {"label": "Barbacoas", "value": "C20306"},
            {"label": "Belen", "value": "C20307"},
            {"label": "Buesaco", "value": "C20308"},
            {"label": "Chachagui", "value": "C20309"},
            {"label": "Colon", "value": "C20310"},
            {"label": "Consaca", "value": "C20311"},
            {"label": "Contadero", "value": "C20312"},
            {"label": "Cordoba", "value": "C20313"},
            {"label": "Cuaspud", "value": "C20314"},
            {"label": "Cumbal", "value": "C20315"},
            {"label": "Cumbitara", "value": "C20316"},
            {"label": "El Charco", "value": "C20317"},
            {"label": "El Penol", "value": "C20318"},
            {"label": "El Rosario", "value": "C20319"},
            {"label": "El Tablon de Gomez", "value": "C20320"},
            {"label": "El Tambo", "value": "C20321"},
            {"label": "Francisco Pizarro", "value": "C20322"},
            {"label": "Funes", "value": "C20323"},
            {"label": "Guachucal", "value": "C20324"},
            {"label": "Guaitarilla", "value": "C20325"},
            {"label": "Gualmatan", "value": "C20326"},
            {"label": "Iles", "value": "C20327"},
            {"label": "Imues", "value": "C20328"},
            {"label": "Ipiales", "value": "C20329"},
            {"label": "La Cruz", "value": "C20330"},
            {"label": "La Florida", "value": "C20331"},
            {"label": "La Llanada", "value": "C20332"},
            {"label": "La Tola", "value": "C20333"},
            {"label": "La Union", "value": "C20334"},
            {"label": "Leiva", "value": "C20335"},
            {"label": "Linares", "value": "C20336"},
            {"label": "Los Andes", "value": "C20337"},
            {"label": "Magui Payan", "value": "C20338"},
            {"label": "Mallama", "value": "C20339"},
            {"label": "Mosquera", "value": "C20340"},
            {"label": "Narino", "value": "C20341"},
            {"label": "Olaya Herrera", "value": "C20342"},
            {"label": "Ospina", "value": "C20343"},
            {"label": "Pasto", "value": "C20344"},
            {"label": "Policarpa", "value": "C20345"},
            {"label": "Potosi", "value": "C20346"},
            {"label": "Providencia", "value": "C20347"},
            {"label": "Puerres", "value": "C20348"},
            {"label": "Pupiales", "value": "C20349"},
            {"label": "Ricaurte", "value": "C20350"},
            {"label": "Roberto Payan", "value": "C20351"},
            {"label": "Samaniego", "value": "C20352"},
            {"label": "San Bernardo", "value": "C20353"},
            {"label": "San Jose de Alban", "value": "C20354"},
            {"label": "San Lorenzo", "value": "C20355"},
            {"label": "San Pablo", "value": "C20356"},
            {"label": "San Pedro de Cartago", "value": "C20357"},
            {"label": "Sandona", "value": "C20358"},
            {"label": "Santa Barbara", "value": "C20359"},
            {"label": "Santacruz", "value": "C20360"},
            {"label": "Sapuyes", "value": "C20361"},
            {"label": "Taminango", "value": "C20362"},
            {"label": "Tangua", "value": "C20363"},
            {"label": "Tumaco", "value": "C20364"},
            {"label": "Tuquerres", "value": "C20365"},
            {"label": "Yacuanquer", "value": "C20366"}],
 "Narok": [{"label": "Narok", "value": "C64624"}],
 "Naryn Region": [{"label": "At-Bashi", "value": "C64748"},
                  {"label": "Jumgal", "value": "C64749"},
                  {"label": "Naryn", "value": "C64750"}],
 "Nasarawa": [{"label": "Buga", "value": "C78389"},
              {"label": "Doma", "value": "C78390"},
              {"label": "Keffi", "value": "C78391"},
              {"label": "Lafia", "value": "C78392"},
              {"label": "Nasarawa", "value": "C78393"},
              {"label": "Wamba", "value": "C78394"}],
 "Naukseni Municipality": [{"label": "Naukseni", "value": "C64903"}],
 "Navarra": [{"label": "Abaigar", "value": "C114589"},
             {"label": "Adios", "value": "C114590"},
             {"label": "Aguilar de Codes", "value": "C114591"},
             {"label": "Allo", "value": "C114592"},
             {"label": "Altsasu", "value": "C114593"},
             {"label": "Ancin", "value": "C114594"},
             {"label": "Andosilla", "value": "C114595"},
             {"label": "Anorbe", "value": "C114596"},
             {"label": "Aoiz", "value": "C114597"},
             {"label": "Aranarache", "value": "C114598"},
             {"label": "Arano", "value": "C114599"},
             {"label": "Aras", "value": "C114600"},
             {"label": "Arbizu", "value": "C114601"},
             {"label": "Arellano", "value": "C114602"},
             {"label": "Areso", "value": "C114603"},
             {"label": "Arguedas", "value": "C114604"},
             {"label": "Aria", "value": "C114605"},
             {"label": "Armananzas", "value": "C114606"},
             {"label": "Arroniz", "value": "C114607"},
             {"label": "Arruazu", "value": "C114608"},
             {"label": "Artajona", "value": "C114609"},
             {"label": "Artazu", "value": "C114610"},
             {"label": "Atarrabia", "value": "C114611"},
             {"label": "Ayegui", "value": "C114612"},
             {"label": "Azagra", "value": "C114613"},
             {"label": "Azuelo", "value": "C114614"},
             {"label": "Bakaiku", "value": "C114615"},
             {"label": "Baranain", "value": "C114616"},
             {"label": "Barasoain", "value": "C114617"},
             {"label": "Bargota", "value": "C114618"},
             {"label": "Barillas", "value": "C114619"},
             {"label": "Beire", "value": "C114620"},
             {"label": "Belascoain", "value": "C114621"},
             {"label": "Bera", "value": "C114622"},
             {"label": "Berbinzana", "value": "C114623"},
             {"label": "Beriain", "value": "C114624"},
             {"label": "Berriobeiti", "value": "C114625"},
             {"label": "Berriozar", "value": "C114626"},
             {"label": "Betelu", "value": "C114627"},
             {"label": "Bunuel", "value": "C114628"},
             {"label": "Burlata", "value": "C114629"},
             {"label": "Cabredo", "value": "C114630"},
             {"label": "Cadreita", "value": "C114631"},
             {"label": "Caparroso", "value": "C114632"},
             {"label": "Carcar", "value": "C114633"},
             {"label": "Carcastillo", "value": "C114634"},
             {"label": "Cascante", "value": "C114635"},
             {"label": "Caseda", "value": "C114636"},
             {"label": "Castejon", "value": "C114637"},
             {"label": "Castillonuevo", "value": "C114638"},
             {"label": "Cintruenigo", "value": "C114639"},
             {"label": "Cirauqui", "value": "C114640"},
             {"label": "Ciriza", "value": "C114641"},
             {"label": "Cizur Mayor", "value": "C114642"},
             {"label": "Corella", "value": "C114643"},
             {"label": "Desojo", "value": "C114644"},
             {"label": "Dicastillo", "value": "C114645"},
             {"label": "Doneztebe", "value": "C114646"},
             {"label": "Echarri", "value": "C114647"},
             {"label": "Echarri-Aranaz", "value": "C114648"},
             {"label": "Elgorriaga", "value": "C114649"},
             {"label": "Elorz", "value": "C114650"},
             {"label": "Eneriz", "value": "C114651"},
             {"label": "Ermitagana", "value": "C114652"},
             {"label": "Eslava", "value": "C114653"},
             {"label": "Espronceda", "value": "C114654"},
             {"label": "Estella-Lizarra", "value": "C114655"},
             {"label": "Etayo", "value": "C114656"},
             {"label": "Eulate", "value": "C114657"},
             {"label": "Falces", "value": "C114658"},
             {"label": "Fitero", "value": "C114659"},
             {"label": "Fontellas", "value": "C114660"},
             {"label": "Funes", "value": "C114661"},
             {"label": "Fustinana", "value": "C114662"},
             {"label": "Galar", "value": "C114663"},
             {"label": "Gallipienzo", "value": "C114664"},
             {"label": "Garde", "value": "C114665"},
             {"label": "Garinoain", "value": "C114666"},
             {"label": "Garralda", "value": "C114667"},
             {"label": "Genevilla", "value": "C114668"},
             {"label": "Goizueta", "value": "C114669"},
             {"label": "Goni", "value": "C114670"},
             {"label": "Guirguillano", "value": "C114671"},
             {"label": "Huarte-Uharte", "value": "C114672"},
             {"label": "Igantzi", "value": "C114673"},
             {"label": "Iguzquiza", "value": "C114674"},
             {"label": "Iraneta", "value": "C114675"},
             {"label": "Ituren", "value": "C114676"},
             {"label": "Iturmendi", "value": "C114677"},
             {"label": "Iturrama", "value": "C114678"},
             {"label": "Javier", "value": "C114679"},
             {"label": "Lapoblacion", "value": "C114680"},
             {"label": "Larraga", "value": "C114681"},
             {"label": "Larraona", "value": "C114682"},
             {"label": "Lazagurria", "value": "C114683"},
             {"label": "Leache", "value": "C114684"},
             {"label": "Legarda", "value": "C114685"},
             {"label": "Legaria", "value": "C114686"},
             {"label": "Lerga", "value": "C114687"},
             {"label": "Lerin", "value": "C114688"},
             {"label": "Lezaun", "value": "C114689"},
             {"label": "Liedena", "value": "C114690"},
             {"label": "Lizoain", "value": "C114691"},
             {"label": "Lodosa", "value": "C114692"},
             {"label": "Los Arcos", "value": "C114693"},
             {"label": "Lumbier", "value": "C114694"},
             {"label": "Maneru", "value": "C114695"},
             {"label": "Maranon", "value": "C114696"},
             {"label": "Marcilla", "value": "C114697"},
             {"label": "Melida", "value": "C114698"},
             {"label": "Mendavia", "value": "C114699"},
             {"label": "Mendigorria", "value": "C114700"},
             {"label": "Metauten", "value": "C114701"},
             {"label": "Milagro", "value": "C114702"},
             {"label": "Mirafuentes", "value": "C114703"},
             {"label": "Miranda de Arga", "value": "C114704"},
             {"label": "Monreal", "value": "C114705"},
             {"label": "Morentin", "value": "C114706"},
             {"label": "Murchante", "value": "C114707"},
             {"label": "Murieta", "value": "C114708"},
             {"label": "Murillo el Fruto", "value": "C114709"},
             {"label": "Muruzabal", "value": "C114710"},
             {"label": "Navascues", "value": "C114711"},
             {"label": "Nazar", "value": "C114712"},
             {"label": "Obanos", "value": "C114713"},
             {"label": "Oco", "value": "C114714"},
             {"label": "Olazagutia", "value": "C114715"},
             {"label": "Olejua", "value": "C114716"},
             {"label": "Olite", "value": "C114717"},
             {"label": "Oloriz", "value": "C114718"},
             {"label": "Oltza", "value": "C114719"},
             {"label": "Orbara", "value": "C114720"},
             {"label": "Orisoain", "value": "C114721"},
             {"label": "Orkoien", "value": "C114722"},
             {"label": "Oronz", "value": "C114723"},
             {"label": "Oteiza", "value": "C114724"},
             {"label": "Pamplona", "value": "C114725"},
             {"label": "Peralta", "value": "C114726"},
             {"label": "Petilla de Aragon", "value": "C114727"},
             {"label": "Piedramillera", "value": "C114728"},
             {"label": "Pitillas", "value": "C114729"},
             {"label": "Primer Ensanche", "value": "C114730"},
             {"label": "Provincia de Navarra", "value": "C114731"},
             {"label": "Puente la Reina", "value": "C114732"},
             {"label": "Pueyo", "value": "C114733"},
             {"label": "Ribaforada", "value": "C114734"},
             {"label": "Saldias", "value": "C114735"},
             {"label": "Salinas de Oro", "value": "C114736"},
             {"label": "San Adrian", "value": "C114737"},
             {"label": "San Martin de Unx", "value": "C114738"},
             {"label": "SanguesaZangoza", "value": "C114739"},
             {"label": "Sansol", "value": "C114740"},
             {"label": "Santacara", "value": "C114741"},
             {"label": "Sarriguren", "value": "C114742"},
             {"label": "Sartaguda", "value": "C114743"},
             {"label": "Segundo Ensanche", "value": "C114744"},
             {"label": "Sesma", "value": "C114745"},
             {"label": "Sorlada", "value": "C114746"},
             {"label": "Tafalla", "value": "C114747"},
             {"label": "Tirapu", "value": "C114748"},
             {"label": "Torres del Rio", "value": "C114749"},
             {"label": "Tudela", "value": "C114750"},
             {"label": "Tulebras", "value": "C114751"},
             {"label": "Ujue", "value": "C114752"},
             {"label": "Unzue", "value": "C114753"},
             {"label": "Urzainqui", "value": "C114754"},
             {"label": "Uterga", "value": "C114755"},
             {"label": "Valtierra", "value": "C114756"},
             {"label": "Villafranca", "value": "C114757"},
             {"label": "Villamayor de Monjardin", "value": "C114758"},
             {"label": "Villatuerta", "value": "C114759"},
             {"label": "Yerri", "value": "C114760"},
             {"label": "Yesa", "value": "C114761"},
             {"label": "Zubieta", "value": "C114762"},
             {"label": "Zugarramurdi", "value": "C114763"},
             {"label": "Zuniga", "value": "C114764"}],
 "Navoiy Region": [{"label": "Navoiy", "value": "C147056"},
                   {"label": "Nurota", "value": "C147057"},
                   {"label": "Qiziltepa", "value": "C147058"},
                   {"label": "Yangirabot", "value": "C147059"}],
 "Naxxar": [{"label": "Naxxar", "value": "C65904"}],
 "Nayarit": [{"label": "Acaponeta", "value": "C71141"},
             {"label": "Ahuacatlan", "value": "C71142"},
             {"label": "Amapa", "value": "C71143"},
             {"label": "Amatlan de Canas", "value": "C71144"},
             {"label": "Aquiles Serdan", "value": "C71145"},
             {"label": "Aticama", "value": "C71146"},
             {"label": "Atonalisco", "value": "C71147"},
             {"label": "Autan", "value": "C71148"},
             {"label": "Bellavista", "value": "C71149"},
             {"label": "Bucerias", "value": "C71150"},
             {"label": "Camalotita", "value": "C71151"},
             {"label": "Camichin de Jauja", "value": "C71152"},
             {"label": "Canada del Tabaco", "value": "C71153"},
             {"label": "Chapalilla", "value": "C71154"},
             {"label": "Chilapa", "value": "C71155"},
             {"label": "Colonia Emiliano Zapata", "value": "C71156"},
             {"label": "Colonia Paraiso Escondido", "value": "C71157"},
             {"label": "Compostela", "value": "C71158"},
             {"label": "Corral del Risco (Punta de Mita)", "value": "C71159"},
             {"label": "Cruz de Huanacaxtle", "value": "C71160"},
             {"label": "Cuamiles", "value": "C71161"},
             {"label": "El Ahuacate", "value": "C71162"},
             {"label": "El Botadero", "value": "C71163"},
             {"label": "El Capomal", "value": "C71164"},
             {"label": "El Corte", "value": "C71165"},
             {"label": "El Jicote", "value": "C71166"},
             {"label": "El Llano", "value": "C71167"},
             {"label": "El Rosario", "value": "C71168"},
             {"label": "El Tamarindo", "value": "C71169"},
             {"label": "El Tizate", "value": "C71170"},
             {"label": "El Vado de San Pedro", "value": "C71171"},
             {"label": "El Venado", "value": "C71172"},
             {"label": "Emiliano Zapata", "value": "C71173"},
             {"label": "Felipe Carrillo Puerto", "value": "C71174"},
             {"label": "Francisco I. Madero", "value": "C71175"},
             {"label": "Guadalupe Victoria", "value": "C71176"},
             {"label": "Higuera Blanca", "value": "C71177"},
             {"label": "Huajicori", "value": "C71178"},
             {"label": "Huajimic", "value": "C71179"},
             {"label": "Ixtapan de la Concepcion", "value": "C71180"},
             {"label": "Ixtlan del Rio", "value": "C71181"},
             {"label": "Jalcocotan", "value": "C71182"},
             {"label": "Jesus Maria", "value": "C71183"},
             {"label": "Jomulco", "value": "C71184"},
             {"label": "Juan Escutia (Borbollon)", "value": "C71185"},
             {"label": "La Cantera", "value": "C71186"},
             {"label": "La Corregidora", "value": "C71187"},
             {"label": "La Fortuna", "value": "C71188"},
             {"label": "La Guasima", "value": "C71189"},
             {"label": "La Labor", "value": "C71190"},
             {"label": "La Libertad", "value": "C71191"},
             {"label": "La Palma", "value": "C71192"},
             {"label": "La Penita de Jaltomba", "value": "C71193"},
             {"label": "La Presa", "value": "C71194"},
             {"label": "La Yesca", "value": "C71195"},
             {"label": "Las Jarretaderas", "value": "C71196"},
             {"label": "Las Varas", "value": "C71197"},
             {"label": "Llano del Tigre", "value": "C71198"},
             {"label": "Lo de Lamedo", "value": "C71199"},
             {"label": "Lo de Marcos", "value": "C71200"},
             {"label": "Los Aguajes", "value": "C71201"},
             {"label": "Los Otates", "value": "C71202"},
             {"label": "Mecatan", "value": "C71203"},
             {"label": "Mesa del Nayar", "value": "C71204"},
             {"label": "Mexpan", "value": "C71205"},
             {"label": "Mezcales", "value": "C71206"},
             {"label": "Milpas Viejas", "value": "C71207"},
             {"label": "Mojarras", "value": "C71208"},
             {"label": "Monteon", "value": "C71209"},
             {"label": "Navarrete", "value": "C71210"},
             {"label": "Nuevo Vallarta", "value": "C71211"},
             {"label": "Palma Grande", "value": "C71212"},
             {"label": "Palmar de Cuautla", "value": "C71213"},
             {"label": "Pantanal", "value": "C71214"},
             {"label": "Pericos", "value": "C71215"},
             {"label": "Pozo de Ibarra", "value": "C71216"},
             {"label": "Puerta de Mangos", "value": "C71217"},
             {"label": "Puga", "value": "C71218"},
             {"label": "Quimichis", "value": "C71219"},
             {"label": "Rincon de Guayabitos", "value": "C71220"},
             {"label": "Rosa Blanca", "value": "C71221"},
             {"label": "Rosamorada", "value": "C71222"},
             {"label": "Ruiz", "value": "C71223"},
             {"label": "San Blas", "value": "C71224"},
             {"label": "San Cayetano", "value": "C71225"},
             {"label": "San Clemente de Lima", "value": "C71226"},
             {"label": "San Felipe Aztatan", "value": "C71227"},
             {"label": "San Francisco", "value": "C71228"},
             {"label": "San Jose de Gracia", "value": "C71229"},
             {"label": "San Juan Bautista", "value": "C71230"},
             {"label": "San Juan de Abajo", "value": "C71231"},
             {"label": "San Luis de Lozada", "value": "C71232"},
             {"label": "San Pedro Lagunillas", "value": "C71233"},
             {"label": "San Vicente", "value": "C71234"},
             {"label": "Santa Cruz", "value": "C71235"},
             {"label": "Santa Isabel", "value": "C71236"},
             {"label": "Santa Maria del Oro", "value": "C71237"},
             {"label": "Santa Teresa", "value": "C71238"},
             {"label": "Santiago de Pochotitan", "value": "C71239"},
             {"label": "Santiago Ixcuintla", "value": "C71240"},
             {"label": "Sauta", "value": "C71241"},
             {"label": "Sayulilla", "value": "C71242"},
             {"label": "Sayulita", "value": "C71243"},
             {"label": "Sentispac", "value": "C71244"},
             {"label": "Tecuala", "value": "C71245"},
             {"label": "Tepic", "value": "C71246"},
             {"label": "Tequepexpan", "value": "C71247"},
             {"label": "Testerazo", "value": "C71248"},
             {"label": "Tetitlan", "value": "C71249"},
             {"label": "Union de Corrientes", "value": "C71250"},
             {"label": "Uzeta", "value": "C71251"},
             {"label": "Valle de Banderas", "value": "C71252"},
             {"label": "Villa Hidalgo", "value": "C71253"},
             {"label": "Villa Juarez", "value": "C71254"},
             {"label": "Yago", "value": "C71255"},
             {"label": "Zacualpan", "value": "C71256"},
             {"label": "Zapotan", "value": "C71257"},
             {"label": "Zapotanito", "value": "C71258"}],
 "Naypyidaw Union Territory": [{"label": "Nay Pyi Taw", "value": "C75945"},
                               {"label": "Pyinmana", "value": "C75946"}],
 "Nazarje Municipality": [{"label": "Nazarje", "value": "C107746"}],
 "Neamt County": [{"label": "Adjudeni", "value": "C97518"},
                  {"label": "Agapia", "value": "C97519"},
                  {"label": "Bahna", "value": "C97520"},
                  {"label": "Baltatesti", "value": "C97521"},
                  {"label": "Balusesti", "value": "C97522"},
                  {"label": "Bara", "value": "C97523"},
                  {"label": "Barcanesti", "value": "C97524"},
                  {"label": "Bargauani", "value": "C97525"},
                  {"label": "Barticesti", "value": "C97526"},
                  {"label": "Bicaz", "value": "C97527"},
                  {"label": "Bicaz-Chei", "value": "C97528"},
                  {"label": "Bicazu Ardelean", "value": "C97529"},
                  {"label": "Bistrita", "value": "C97530"},
                  {"label": "Boboiesti", "value": "C97531"},
                  {"label": "Bodesti", "value": "C97532"},
                  {"label": "Bodestii de Jos", "value": "C97533"},
                  {"label": "Boghicea", "value": "C97534"},
                  {"label": "Borca", "value": "C97535"},
                  {"label": "Borlesti", "value": "C97536"},
                  {"label": "Botesti", "value": "C97537"},
                  {"label": "Bozieni", "value": "C97538"},
                  {"label": "Brusturi", "value": "C97539"},
                  {"label": "Budesti", "value": "C97540"},
                  {"label": "Buruienesti", "value": "C97541"},
                  {"label": "Candesti", "value": "C97542"},
                  {"label": "Ceahlau", "value": "C97543"},
                  {"label": "Chilii", "value": "C97544"},
                  {"label": "Chintinici", "value": "C97545"},
                  {"label": "Comuna Agapia", "value": "C97546"},
                  {"label": "Comuna Alexandru Cel Bun", "value": "C97547"},
                  {"label": "Comuna Bahna", "value": "C97548"},
                  {"label": "Comuna Baltatesti", "value": "C97549"},
                  {"label": "Comuna Bara", "value": "C97550"},
                  {"label": "Comuna Bargauani", "value": "C97551"},
                  {"label": "Comuna Bicaz Chei", "value": "C97552"},
                  {"label": "Comuna Bicazu Ardelean", "value": "C97553"},
                  {"label": "Comuna Bodesti-Precista", "value": "C97554"},
                  {"label": "Comuna Boghicea", "value": "C97555"},
                  {"label": "Comuna Borca", "value": "C97556"},
                  {"label": "Comuna Borlesti", "value": "C97557"},
                  {"label": "Comuna Botesti", "value": "C97558"},
                  {"label": "Comuna Bozieni", "value": "C97559"},
                  {"label": "Comuna Brusturi-Draganesti", "value": "C97560"},
                  {"label": "Comuna Candesti", "value": "C97561"},
                  {"label": "Comuna Ceahlau", "value": "C97562"},
                  {"label": "Comuna Cordun", "value": "C97563"},
                  {"label": "Comuna Costisa", "value": "C97564"},
                  {"label": "Comuna Cracaoani", "value": "C97565"},
                  {"label": "Comuna Damuc", "value": "C97566"},
                  {"label": "Comuna Dobreni", "value": "C97567"},
                  {"label": "Comuna Dochia", "value": "C97568"},
                  {"label": "Comuna Doljesti", "value": "C97569"},
                  {"label": "Comuna Draganesti", "value": "C97570"},
                  {"label": "Comuna Dragomiresti", "value": "C97571"},
                  {"label": "Comuna Dulcesti", "value": "C97572"},
                  {"label": "Comuna Dumbrava Rosie", "value": "C97573"},
                  {"label": "Comuna Farcasa", "value": "C97574"},
                  {"label": "Comuna Faurei", "value": "C97575"},
                  {"label": "Comuna Gadinti", "value": "C97576"},
                  {"label": "Comuna Garcina", "value": "C97577"},
                  {"label": "Comuna Gheraesti", "value": "C97578"},
                  {"label": "Comuna Ghindaoani", "value": "C97579"},
                  {"label": "Comuna Girov", "value": "C97580"},
                  {"label": "Comuna Grinties", "value": "C97581"},
                  {"label": "Comuna Grumazesti", "value": "C97582"},
                  {"label": "Comuna Hangu", "value": "C97583"},
                  {"label": "Comuna Horia", "value": "C97584"},
                  {"label": "Comuna Icusesti", "value": "C97585"},
                  {"label": "Comuna Ion Creanga", "value": "C97586"},
                  {"label": "Comuna Margineni", "value": "C97587"},
                  {"label": "Comuna Moldoveni", "value": "C97588"},
                  {"label": "Comuna Negresti", "value": "C97589"},
                  {"label": "Comuna Oniceni", "value": "C97590"},
                  {"label": "Comuna Pancesti", "value": "C97591"},
                  {"label": "Comuna Pangarati", "value": "C97592"},
                  {"label": "Comuna Pastraveni", "value": "C97593"},
                  {"label": "Comuna Petricani", "value": "C97594"},
                  {"label": "Comuna Piatra Soimului", "value": "C97595"},
                  {"label": "Comuna Pipirig", "value": "C97596"},
                  {"label": "Comuna Podoleni", "value": "C97597"},
                  {"label": "Comuna Poiana Teiului", "value": "C97598"},
                  {"label": "Comuna Poienari", "value": "C97599"},
                  {"label": "Comuna Raucesti", "value": "C97600"},
                  {"label": "Comuna Razboieni", "value": "C97601"},
                  {"label": "Comuna Rediu", "value": "C97602"},
                  {"label": "Comuna Romani", "value": "C97603"},
                  {"label": "Comuna Ruginoasa", "value": "C97604"},
                  {"label": "Comuna Sabaoani", "value": "C97605"},
                  {"label": "Comuna Sagna", "value": "C97606"},
                  {"label": "Comuna Savinesti", "value": "C97607"},
                  {"label": "Comuna Secuieni", "value": "C97608"},
                  {"label": "Comuna Stanita", "value": "C97609"},
                  {"label": "Comuna Stefan cel Mare", "value": "C97610"},
                  {"label": "Comuna Tamaseni", "value": "C97611"},
                  {"label": "Comuna Tarcau", "value": "C97612"},
                  {"label": "Comuna Tasca", "value": "C97613"},
                  {"label": "Comuna Tazlau", "value": "C97614"},
                  {"label": "Comuna Tibucani", "value": "C97615"},
                  {"label": "Comuna Timisesti", "value": "C97616"},
                  {"label": "Comuna Trifesti", "value": "C97617"},
                  {"label": "Comuna Tupilati", "value": "C97618"},
                  {"label": "Comuna Urecheni", "value": "C97619"},
                  {"label": "Comuna Valea Ursului", "value": "C97620"},
                  {"label": "Comuna Valeni", "value": "C97621"},
                  {"label": "Comuna Vanatori Neamt", "value": "C97622"},
                  {"label": "Comuna Zanesti", "value": "C97623"},
                  {"label": "Cordun", "value": "C97624"},
                  {"label": "Costisa", "value": "C97625"},
                  {"label": "Cotu Vames", "value": "C97626"},
                  {"label": "Cracaoani", "value": "C97627"},
                  {"label": "Cracaul Negru", "value": "C97628"},
                  {"label": "Cuejdiu", "value": "C97629"},
                  {"label": "Cut", "value": "C97630"},
                  {"label": "Damuc", "value": "C97631"},
                  {"label": "Dobreni", "value": "C97632"},
                  {"label": "Dochia", "value": "C97633"},
                  {"label": "Dodeni", "value": "C97634"},
                  {"label": "Dolhesti", "value": "C97635"},
                  {"label": "Doljesti", "value": "C97636"},
                  {"label": "Draganesti", "value": "C97637"},
                  {"label": "Dragomiresti", "value": "C97638"},
                  {"label": "Dulcesti", "value": "C97639"},
                  {"label": "Dumbrava", "value": "C97640"},
                  {"label": "Dumbrava Rosie", "value": "C97641"},
                  {"label": "Farcasa", "value": "C97642"},
                  {"label": "Faurei", "value": "C97643"},
                  {"label": "Filioara", "value": "C97644"},
                  {"label": "Gadinti", "value": "C97645"},
                  {"label": "Garcina", "value": "C97646"},
                  {"label": "Gheraesti", "value": "C97647"},
                  {"label": "Ghindaoani", "value": "C97648"},
                  {"label": "Girov", "value": "C97649"},
                  {"label": "Gosmani", "value": "C97650"},
                  {"label": "Grinties", "value": "C97651"},
                  {"label": "Grumazesti", "value": "C97652"},
                  {"label": "Hangu", "value": "C97653"},
                  {"label": "Hlapesti", "value": "C97654"},
                  {"label": "Hociungi", "value": "C97655"},
                  {"label": "Horia", "value": "C97656"},
                  {"label": "Humulesti", "value": "C97657"},
                  {"label": "Icusesti", "value": "C97658"},
                  {"label": "Ion Creanga", "value": "C97659"},
                  {"label": "Izvoare", "value": "C97660"},
                  {"label": "Luminis", "value": "C97661"},
                  {"label": "Lunca", "value": "C97662"},
                  {"label": "Margineni", "value": "C97663"},
                  {"label": "Mastacan", "value": "C97664"},
                  {"label": "Miron Costin", "value": "C97665"},
                  {"label": "Moldoveni", "value": "C97666"},
                  {"label": "Municipiul Piatra-Neamt", "value": "C97667"},
                  {"label": "Municipiul Roman", "value": "C97668"},
                  {"label": "Negresti", "value": "C97669"},
                  {"label": "Nemtisor", "value": "C97670"},
                  {"label": "Nisiporesti", "value": "C97671"},
                  {"label": "Oantu", "value": "C97672"},
                  {"label": "Oglinzi", "value": "C97673"},
                  {"label": "Oniceni", "value": "C97674"},
                  {"label": "Oras Bicaz", "value": "C97675"},
                  {"label": "Oras Roznov", "value": "C97676"},
                  {"label": "Oras Targu Neamt", "value": "C97677"},
                  {"label": "Pancesti", "value": "C97678"},
                  {"label": "Pangaracior", "value": "C97679"},
                  {"label": "Pangarati", "value": "C97680"},
                  {"label": "Pastraveni", "value": "C97681"},
                  {"label": "Petricani", "value": "C97682"},
                  {"label": "Piatra Neamt", "value": "C97683"},
                  {"label": "Piatra Soimului", "value": "C97684"},
                  {"label": "Pildesti", "value": "C97685"},
                  {"label": "Pipirig", "value": "C97686"},
                  {"label": "Podoleni", "value": "C97687"},
                  {"label": "Poiana Cracaoani", "value": "C97688"},
                  {"label": "Poiana Teiului", "value": "C97689"},
                  {"label": "Poienari", "value": "C97690"},
                  {"label": "Poieni", "value": "C97691"},
                  {"label": "Poloboc", "value": "C97692"},
                  {"label": "Radeni", "value": "C97693"},
                  {"label": "Raucesti", "value": "C97694"},
                  {"label": "Razboienii de Jos", "value": "C97695"},
                  {"label": "Rediu", "value": "C97696"},
                  {"label": "Roman", "value": "C97697"},
                  {"label": "Romani", "value": "C97698"},
                  {"label": "Rotunda", "value": "C97699"},
                  {"label": "Roznov", "value": "C97700"},
                  {"label": "Ruginoasa", "value": "C97701"},
                  {"label": "Ruseni", "value": "C97702"},
                  {"label": "Sabaoani", "value": "C97703"},
                  {"label": "Sabasa", "value": "C97704"},
                  {"label": "Sagna", "value": "C97705"},
                  {"label": "Savinesti", "value": "C97706"},
                  {"label": "Secuieni", "value": "C97707"},
                  {"label": "Silistea", "value": "C97708"},
                  {"label": "Slobozia", "value": "C97709"},
                  {"label": "Stanca", "value": "C97710"},
                  {"label": "Stanita", "value": "C97711"},
                  {"label": "Stefan cel Mare", "value": "C97712"},
                  {"label": "Stejaru", "value": "C97713"},
                  {"label": "Tamaseni", "value": "C97714"},
                  {"label": "Tarcau", "value": "C97715"},
                  {"label": "Targu Neamt", "value": "C97716"},
                  {"label": "Tasca", "value": "C97717"},
                  {"label": "Tazlau", "value": "C97718"},
                  {"label": "Telec", "value": "C97719"},
                  {"label": "Tibucani", "value": "C97720"},
                  {"label": "Timisesti", "value": "C97721"},
                  {"label": "Tolici", "value": "C97722"},
                  {"label": "Topolita", "value": "C97723"},
                  {"label": "Traian", "value": "C97724"},
                  {"label": "Trifesti", "value": "C97725"},
                  {"label": "Tupilati", "value": "C97726"},
                  {"label": "Urecheni", "value": "C97727"},
                  {"label": "Vadurele", "value": "C97728"},
                  {"label": "Valea Arini", "value": "C97729"},
                  {"label": "Valea Seaca", "value": "C97730"},
                  {"label": "Valea Ursului", "value": "C97731"},
                  {"label": "Valeni", "value": "C97732"},
                  {"label": "Vanatori-Neamt", "value": "C97733"},
                  {"label": "Viisoara", "value": "C97734"},
                  {"label": "Zanesti", "value": "C97735"}],
 "Nebraska": [{"label": "Adams County", "value": "C137011"},
              {"label": "Ainsworth", "value": "C137012"},
              {"label": "Albion", "value": "C137013"},
              {"label": "Alliance", "value": "C137014"},
              {"label": "Alma", "value": "C137015"},
              {"label": "Antelope County", "value": "C137016"},
              {"label": "Arapahoe", "value": "C137017"},
              {"label": "Arlington", "value": "C137018"},
              {"label": "Arthur", "value": "C137019"},
              {"label": "Arthur County", "value": "C137020"},
              {"label": "Ashland", "value": "C137021"},
              {"label": "Atkinson", "value": "C137022"},
              {"label": "Auburn", "value": "C137023"},
              {"label": "Aurora", "value": "C137024"},
              {"label": "Banner County", "value": "C137025"},
              {"label": "Bartlett", "value": "C137026"},
              {"label": "Bassett", "value": "C137027"},
              {"label": "Battle Creek", "value": "C137028"},
              {"label": "Bayard", "value": "C137029"},
              {"label": "Beatrice", "value": "C137030"},
              {"label": "Beaver City", "value": "C137031"},
              {"label": "Bellevue", "value": "C137032"},
              {"label": "Benkelman", "value": "C137033"},
              {"label": "Bennington", "value": "C137034"},
              {"label": "Blaine County", "value": "C137035"},
              {"label": "Blair", "value": "C137036"},
              {"label": "Boone County", "value": "C137037"},
              {"label": "Box Butte County", "value": "C137038"},
              {"label": "Boyd County", "value": "C137039"},
              {"label": "Brewster", "value": "C137040"},
              {"label": "Bridgeport", "value": "C137041"},
              {"label": "Broken Bow", "value": "C137042"},
              {"label": "Brown County", "value": "C137043"},
              {"label": "Buffalo County", "value": "C137044"},
              {"label": "Burt County", "value": "C137045"},
              {"label": "Burwell", "value": "C137046"},
              {"label": "Butler County", "value": "C137047"},
              {"label": "Butte", "value": "C137048"},
              {"label": "Cambridge", "value": "C137049"},
              {"label": "Cass County", "value": "C137050"},
              {"label": "Cedar County", "value": "C137051"},
              {"label": "Center", "value": "C137052"},
              {"label": "Central City", "value": "C137053"},
              {"label": "Chadron", "value": "C137054"},
              {"label": "Chalco", "value": "C137055"},
              {"label": "Chappell", "value": "C137056"},
              {"label": "Chase County", "value": "C137057"},
              {"label": "Cherry County", "value": "C137058"},
              {"label": "Cheyenne County", "value": "C137059"},
              {"label": "Clay Center", "value": "C137060"},
              {"label": "Clay County", "value": "C137061"},
              {"label": "Colfax County", "value": "C137062"},
              {"label": "Columbus", "value": "C137063"},
              {"label": "Cozad", "value": "C137064"},
              {"label": "Creighton", "value": "C137065"},
              {"label": "Crete", "value": "C137066"},
              {"label": "Cuming County", "value": "C137067"},
              {"label": "Custer County", "value": "C137068"},
              {"label": "Dakota City", "value": "C137069"},
              {"label": "Dakota County", "value": "C137070"},
              {"label": "David City", "value": "C137071"},
              {"label": "Dawes County", "value": "C137072"},
              {"label": "Dawson County", "value": "C137073"},
              {"label": "Deuel County", "value": "C137074"},
              {"label": "Dixon County", "value": "C137075"},
              {"label": "Dodge County", "value": "C137076"},
              {"label": "Douglas County", "value": "C137077"},
              {"label": "Dundy County", "value": "C137078"},
              {"label": "Eagle", "value": "C137079"},
              {"label": "Elkhorn", "value": "C137080"},
              {"label": "Elwood", "value": "C137081"},
              {"label": "Fairbury", "value": "C137082"},
              {"label": "Falls City", "value": "C137083"},
              {"label": "Fillmore County", "value": "C137084"},
              {"label": "Franklin", "value": "C137085"},
              {"label": "Franklin County", "value": "C137086"},
              {"label": "Fremont", "value": "C137087"},
              {"label": "Friend", "value": "C137088"},
              {"label": "Frontier County", "value": "C137089"},
              {"label": "Fullerton", "value": "C137090"},
              {"label": "Furnas County", "value": "C137091"},
              {"label": "Gage County", "value": "C137092"},
              {"label": "Garden County", "value": "C137093"},
              {"label": "Garfield County", "value": "C137094"},
              {"label": "Geneva", "value": "C137095"},
              {"label": "Gering", "value": "C137096"},
              {"label": "Gibbon", "value": "C137097"},
              {"label": "Gordon", "value": "C137098"},
              {"label": "Gosper County", "value": "C137099"},
              {"label": "Gothenburg", "value": "C137100"},
              {"label": "Grand Island", "value": "C137101"},
              {"label": "Grant", "value": "C137102"},
              {"label": "Grant County", "value": "C137103"},
              {"label": "Greeley", "value": "C137104"},
              {"label": "Greeley County", "value": "C137105"},
              {"label": "Gretna", "value": "C137106"},
              {"label": "Hall County", "value": "C137107"},
              {"label": "Hamilton County", "value": "C137108"},
              {"label": "Harlan County", "value": "C137109"},
              {"label": "Harrisburg", "value": "C137110"},
              {"label": "Harrison", "value": "C137111"},
              {"label": "Hartington", "value": "C137112"},
              {"label": "Hastings", "value": "C137113"},
              {"label": "Hayes Center", "value": "C137114"},
              {"label": "Hayes County", "value": "C137115"},
              {"label": "Hebron", "value": "C137116"},
              {"label": "Hickman", "value": "C137117"},
              {"label": "Hitchcock County", "value": "C137118"},
              {"label": "Holdrege", "value": "C137119"},
              {"label": "Holt County", "value": "C137120"},
              {"label": "Hooker County", "value": "C137121"},
              {"label": "Howard County", "value": "C137122"},
              {"label": "Hyannis", "value": "C137123"},
              {"label": "Imperial", "value": "C137124"},
              {"label": "Jefferson County", "value": "C137125"},
              {"label": "Johnson County", "value": "C137126"},
              {"label": "Kearney", "value": "C137127"},
              {"label": "Kearney County", "value": "C137128"},
              {"label": "Keith County", "value": "C137129"},
              {"label": "Keya Paha County", "value": "C137130"},
              {"label": "Kimball", "value": "C137131"},
              {"label": "Kimball County", "value": "C137132"},
              {"label": "Knox County", "value": "C137133"},
              {"label": "La Vista", "value": "C137134"},
              {"label": "Lancaster County", "value": "C137135"},
              {"label": "Lexington", "value": "C137136"},
              {"label": "Lincoln", "value": "C137137"},
              {"label": "Lincoln County", "value": "C137138"},
              {"label": "Logan County", "value": "C137139"},
              {"label": "Louisville", "value": "C137140"},
              {"label": "Loup City", "value": "C137141"},
              {"label": "Loup County", "value": "C137142"},
              {"label": "Macy", "value": "C137143"},
              {"label": "Madison", "value": "C137144"},
              {"label": "Madison County", "value": "C137145"},
              {"label": "McCook", "value": "C137146"},
              {"label": "McPherson County", "value": "C137147"},
              {"label": "Merrick County", "value": "C137148"},
              {"label": "Milford", "value": "C137149"},
              {"label": "Minden", "value": "C137150"},
              {"label": "Mitchell", "value": "C137151"},
              {"label": "Morrill County", "value": "C137152"},
              {"label": "Mullen", "value": "C137153"},
              {"label": "Nance County", "value": "C137154"},
              {"label": "Nebraska City", "value": "C137155"},
              {"label": "Neligh", "value": "C137156"},
              {"label": "Nelson", "value": "C137157"},
              {"label": "Nemaha County", "value": "C137158"},
              {"label": "Norfolk", "value": "C137159"},
              {"label": "North Bend", "value": "C137160"},
              {"label": "North Platte", "value": "C137161"},
              {"label": "Nuckolls County", "value": "C137162"},
              {"label": "ONeill", "value": "C137163"},
              {"label": "Oakland", "value": "C137164"},
              {"label": "Offutt Air Force Base", "value": "C137165"},
              {"label": "Ogallala", "value": "C137166"},
              {"label": "Omaha", "value": "C137167"},
              {"label": "Ord", "value": "C137168"},
              {"label": "Osceola", "value": "C137169"},
              {"label": "Oshkosh", "value": "C137170"},
              {"label": "Otoe County", "value": "C137171"},
              {"label": "Papillion", "value": "C137172"},
              {"label": "Pawnee City", "value": "C137173"},
              {"label": "Pawnee County", "value": "C137174"},
              {"label": "Pender", "value": "C137175"},
              {"label": "Perkins County", "value": "C137176"},
              {"label": "Phelps County", "value": "C137177"},
              {"label": "Pierce", "value": "C137178"},
              {"label": "Pierce County", "value": "C137179"},
              {"label": "Plainview", "value": "C137180"},
              {"label": "Platte County", "value": "C137181"},
              {"label": "Plattsmouth", "value": "C137182"},
              {"label": "Polk County", "value": "C137183"},
              {"label": "Ponca", "value": "C137184"},
              {"label": "Ralston", "value": "C137185"},
              {"label": "Ravenna", "value": "C137186"},
              {"label": "Red Cloud", "value": "C137187"},
              {"label": "Red Willow County", "value": "C137188"},
              {"label": "Richardson County", "value": "C137189"},
              {"label": "Rock County", "value": "C137190"},
              {"label": "Rushville", "value": "C137191"},
              {"label": "Saint Paul", "value": "C137192"},
              {"label": "Saline County", "value": "C137193"},
              {"label": "Sarpy County", "value": "C137194"},
              {"label": "Saunders County", "value": "C137195"},
              {"label": "Schuyler", "value": "C137196"},
              {"label": "Scotts Bluff County", "value": "C137197"},
              {"label": "Scottsbluff", "value": "C137198"},
              {"label": "Seward", "value": "C137199"},
              {"label": "Seward County", "value": "C137200"},
              {"label": "Shelton", "value": "C137201"},
              {"label": "Sheridan County", "value": "C137202"},
              {"label": "Sherman County", "value": "C137203"},
              {"label": "Sidney", "value": "C137204"},
              {"label": "Sioux County", "value": "C137205"},
              {"label": "South Sioux City", "value": "C137206"},
              {"label": "Springfield", "value": "C137207"},
              {"label": "Springview", "value": "C137208"},
              {"label": "Stanton", "value": "C137209"},
              {"label": "Stanton County", "value": "C137210"},
              {"label": "Stapleton", "value": "C137211"},
              {"label": "Stockville", "value": "C137212"},
              {"label": "Stromsburg", "value": "C137213"},
              {"label": "Superior", "value": "C137214"},
              {"label": "Sutherland", "value": "C137215"},
              {"label": "Sutton", "value": "C137216"},
              {"label": "Syracuse", "value": "C137217"},
              {"label": "Taylor", "value": "C137218"},
              {"label": "Tecumseh", "value": "C137219"},
              {"label": "Tekamah", "value": "C137220"},
              {"label": "Terrytown", "value": "C137221"},
              {"label": "Thayer County", "value": "C137222"},
              {"label": "Thedford", "value": "C137223"},
              {"label": "Thomas County", "value": "C137224"},
              {"label": "Thurston County", "value": "C137225"},
              {"label": "Trenton", "value": "C137226"},
              {"label": "Tryon", "value": "C137227"},
              {"label": "Valentine", "value": "C137228"},
              {"label": "Valley", "value": "C137229"},
              {"label": "Valley County", "value": "C137230"},
              {"label": "Wahoo", "value": "C137231"},
              {"label": "Wakefield", "value": "C137232"},
              {"label": "Washington County", "value": "C137233"},
              {"label": "Waterloo", "value": "C137234"},
              {"label": "Waverly", "value": "C137235"},
              {"label": "Wayne", "value": "C137236"},
              {"label": "Wayne County", "value": "C137237"},
              {"label": "Webster County", "value": "C137238"},
              {"label": "Weeping Water", "value": "C137239"},
              {"label": "West Point", "value": "C137240"},
              {"label": "Wheeler County", "value": "C137241"},
              {"label": "Wilber", "value": "C137242"},
              {"label": "Wisner", "value": "C137243"},
              {"label": "Wood River", "value": "C137244"},
              {"label": "Wymore", "value": "C137245"},
              {"label": "York", "value": "C137246"},
              {"label": "York County", "value": "C137247"},
              {"label": "Yutan", "value": "C137248"}],
 "Neftchala District": [{"label": "Neftcala", "value": "C8485"},
                        {"label": "Severo-Vostotchnyi Bank", "value": "C8486"},
                        {"label": "Sovetabad", "value": "C8487"},
                        {"label": "Xilli", "value": "C8488"}],
 "Negeri Sembilan": [{"label": "Bahau", "value": "C65689"},
                     {"label": "Kampung Baharu Nilai", "value": "C65690"},
                     {"label": "Kuala Pilah", "value": "C65691"},
                     {"label": "Port Dickson", "value": "C65692"},
                     {"label": "Seremban", "value": "C65693"},
                     {"label": "Tampin", "value": "C65694"}],
 "Negotino Municipality": [{"label": "Negotino", "value": "C65466"}],
 "Nelson Region": [{"label": "Nelson", "value": "C77800"}],
 "Nenets Autonomous Okrug": [{"label": "Iskateley", "value": "C102663"},
                             {"label": "Naryan-Mar", "value": "C102664"}],
 "Nereta Municipality": [{"label": "Nereta", "value": "C64904"}],
 "Neuchatel": [{"label": "Auvernier", "value": "C116900"},
               {"label": "Bevaix", "value": "C116901"},
               {"label": "Boudry", "value": "C116902"},
               {"label": "Boudry District", "value": "C116903"},
               {"label": "Cernier", "value": "C116904"},
               {"label": "Chezard-Saint-Martin", "value": "C116905"},
               {"label": "Cornaux", "value": "C116906"},
               {"label": "Cortaillod", "value": "C116907"},
               {"label": "Couvet", "value": "C116908"},
               {"label": "Dombresson", "value": "C116909"},
               {"label": "Fleurier", "value": "C116910"},
               {"label": "Fontainemelon", "value": "C116911"},
               {"label": "Gorgier", "value": "C116912"},
               {"label": "Grand-Savagnier", "value": "C116913"},
               {"label": "La Chaux-de-Fonds", "value": "C116914"},
               {"label": "La Chaux-de-Fonds District", "value": "C116915"},
               {"label": "Le Landeron", "value": "C116916"},
               {"label": "Le Locle", "value": "C116917"},
               {"label": "Le Locle District", "value": "C116918"},
               {"label": "Les Brenets", "value": "C116919"},
               {"label": "Les Geneveys-sur-Coffrane", "value": "C116920"},
               {"label": "Les Ponts-de-Martel", "value": "C116921"},
               {"label": "Marin-Epagnier", "value": "C116922"},
               {"label": "Neuchatel", "value": "C116923"},
               {"label": "Neuchatel District", "value": "C116924"},
               {"label": "Peseux", "value": "C116925"},
               {"label": "Saint-Aubin-Sauges", "value": "C116926"},
               {"label": "Saint-Blaise", "value": "C116927"},
               {"label": "Travers", "value": "C116928"},
               {"label": "Val-de-Ruz District", "value": "C116929"},
               {"label": "Val-de-Travers District", "value": "C116930"}],
 "Neuquen": [{"label": "Alumine", "value": "C1247"},
             {"label": "Andacollo", "value": "C1248"},
             {"label": "Anelo", "value": "C1249"},
             {"label": "Barrancas", "value": "C1250"},
             {"label": "Buta Ranquil", "value": "C1251"},
             {"label": "Centenario", "value": "C1252"},
             {"label": "Chos Malal", "value": "C1253"},
             {"label": "Cutral-Co", "value": "C1254"},
             {"label": "Departamento de Alumine", "value": "C1255"},
             {"label": "Departamento de Anelo", "value": "C1256"},
             {"label": "Departamento de Catan-Lil", "value": "C1257"},
             {"label": "Departamento de Chos-Malal", "value": "C1258"},
             {"label": "Departamento de Collon-Cura", "value": "C1259"},
             {"label": "Departamento de Confluencia", "value": "C1260"},
             {"label": "Departamento de Lacar", "value": "C1261"},
             {"label": "Departamento de Minas", "value": "C1262"},
             {"label": "Departamento de Zapala", "value": "C1263"},
             {"label": "El Huecu", "value": "C1264"},
             {"label": "Junin de los Andes", "value": "C1265"},
             {"label": "Las Coloradas", "value": "C1266"},
             {"label": "Las Lajas", "value": "C1267"},
             {"label": "Las Ovejas", "value": "C1268"},
             {"label": "Loncopue", "value": "C1269"},
             {"label": "Mariano Moreno", "value": "C1270"},
             {"label": "Neuquen", "value": "C1271"},
             {"label": "Picun Leufu", "value": "C1272"},
             {"label": "Piedra del aguila", "value": "C1273"},
             {"label": "Plaza Huincul", "value": "C1274"},
             {"label": "Plottier", "value": "C1275"},
             {"label": "San Martin de los Andes", "value": "C1276"},
             {"label": "Senillosa", "value": "C1277"},
             {"label": "Villa La Angostura", "value": "C1278"},
             {"label": "Vista Alegre", "value": "C1279"},
             {"label": "Zapala", "value": "C1280"}],
 "Nevada": [{"label": "Alamo", "value": "C137249"},
            {"label": "Battle Mountain", "value": "C137250"},
            {"label": "Beatty", "value": "C137251"},
            {"label": "Boulder City", "value": "C137252"},
            {"label": "Bunkerville", "value": "C137253"},
            {"label": "Caliente", "value": "C137254"},
            {"label": "Carlin", "value": "C137255"},
            {"label": "Carson City", "value": "C137256"},
            {"label": "Churchill County", "value": "C137257"},
            {"label": "Clark County", "value": "C137258"},
            {"label": "Cold Springs", "value": "C137259"},
            {"label": "Dayton", "value": "C137260"},
            {"label": "Douglas County", "value": "C137261"},
            {"label": "East Valley", "value": "C137262"},
            {"label": "Elko", "value": "C137263"},
            {"label": "Elko County", "value": "C137264"},
            {"label": "Ely", "value": "C137265"},
            {"label": "Enterprise", "value": "C137266"},
            {"label": "Esmeralda County", "value": "C137267"},
            {"label": "Eureka", "value": "C137268"},
            {"label": "Eureka County", "value": "C137269"},
            {"label": "Fallon", "value": "C137270"},
            {"label": "Fernley", "value": "C137271"},
            {"label": "Gardnerville", "value": "C137272"},
            {"label": "Gardnerville Ranchos", "value": "C137273"},
            {"label": "Golden Valley", "value": "C137274"},
            {"label": "Goldfield", "value": "C137275"},
            {"label": "Hawthorne", "value": "C137276"},
            {"label": "Henderson", "value": "C137277"},
            {"label": "Humboldt County", "value": "C137278"},
            {"label": "Incline Village", "value": "C137279"},
            {"label": "Indian Hills", "value": "C137280"},
            {"label": "Jackpot", "value": "C137281"},
            {"label": "Johnson Lane", "value": "C137282"},
            {"label": "Kingsbury", "value": "C137283"},
            {"label": "Lander County", "value": "C137284"},
            {"label": "Las Vegas", "value": "C137285"},
            {"label": "Laughlin", "value": "C137286"},
            {"label": "Lemmon Valley", "value": "C137287"},
            {"label": "Lincoln County", "value": "C137288"},
            {"label": "Lovelock", "value": "C137289"},
            {"label": "Lyon County", "value": "C137290"},
            {"label": "McGill", "value": "C137291"},
            {"label": "Mesquite", "value": "C137292"},
            {"label": "Minden", "value": "C137293"},
            {"label": "Mineral County", "value": "C137294"},
            {"label": "Moapa Town", "value": "C137295"},
            {"label": "Moapa Valley", "value": "C137296"},
            {"label": "Mogul", "value": "C137297"},
            {"label": "Nellis Air Force Base", "value": "C137298"},
            {"label": "North Las Vegas", "value": "C137299"},
            {"label": "Nye County", "value": "C137300"},
            {"label": "Pahrump", "value": "C137301"},
            {"label": "Paradise", "value": "C137302"},
            {"label": "Pershing County", "value": "C137303"},
            {"label": "Pioche", "value": "C137304"},
            {"label": "Reno", "value": "C137305"},
            {"label": "Sandy Valley", "value": "C137306"},
            {"label": "Silver Springs", "value": "C137307"},
            {"label": "Smith", "value": "C137308"},
            {"label": "Smith Valley", "value": "C137309"},
            {"label": "Spanish Springs", "value": "C137310"},
            {"label": "Sparks", "value": "C137311"},
            {"label": "Spring Creek", "value": "C137312"},
            {"label": "Spring Valley", "value": "C137313"},
            {"label": "Stagecoach", "value": "C137314"},
            {"label": "Storey County", "value": "C137315"},
            {"label": "Summerlin South", "value": "C137316"},
            {"label": "Sun Valley", "value": "C137317"},
            {"label": "Sunrise Manor", "value": "C137318"},
            {"label": "Tonopah", "value": "C137319"},
            {"label": "Topaz Ranch Estates", "value": "C137320"},
            {"label": "Verdi", "value": "C137321"},
            {"label": "Virginia City", "value": "C137322"},
            {"label": "Washoe County", "value": "C137323"},
            {"label": "Wells", "value": "C137324"},
            {"label": "West Wendover", "value": "C137325"},
            {"label": "White Pine County", "value": "C137326"},
            {"label": "Whitney", "value": "C137327"},
            {"label": "Winchester", "value": "C137328"},
            {"label": "Winnemucca", "value": "C137329"},
            {"label": "Yerington", "value": "C137330"}],
 "Nevsehir": [{"label": "Acigol", "value": "C120832"},
              {"label": "Avanos", "value": "C120833"},
              {"label": "Derinkuyu", "value": "C120834"},
              {"label": "Goreme", "value": "C120835"},
              {"label": "Gulsehir", "value": "C120836"},
              {"label": "Hacibektas", "value": "C120837"},
              {"label": "Kozakli Ilcesi", "value": "C120838"},
              {"label": "Merkez", "value": "C120839"},
              {"label": "Nevsehir", "value": "C120840"},
              {"label": "urgup", "value": "C120841"}],
 "New Brunswick": [{"label": "Baie Ste. Anne", "value": "C17001"},
                   {"label": "Bathurst", "value": "C17002"},
                   {"label": "Bouctouche", "value": "C17003"},
                   {"label": "Campbellton", "value": "C17004"},
                   {"label": "Dieppe", "value": "C17005"},
                   {"label": "Edmundston", "value": "C17006"},
                   {"label": "Florenceville-Bristol", "value": "C17007"},
                   {"label": "Fredericton", "value": "C17008"},
                   {"label": "Fundy Bay", "value": "C17009"},
                   {"label": "Grande-Digue", "value": "C17010"},
                   {"label": "Greater Lakeburn", "value": "C17011"},
                   {"label": "Hampton", "value": "C17012"},
                   {"label": "Harrison Brook", "value": "C17013"},
                   {"label": "Keswick Ridge", "value": "C17014"},
                   {"label": "Lincoln", "value": "C17015"},
                   {"label": "Lutes Mountain", "value": "C17016"},
                   {"label": "McEwen", "value": "C17017"},
                   {"label": "Miramichi", "value": "C17018"},
                   {"label": "Moncton", "value": "C17019"},
                   {"label": "Nackawic", "value": "C17020"},
                   {"label": "New Maryland", "value": "C17021"},
                   {"label": "Noonan", "value": "C17022"},
                   {"label": "Oromocto", "value": "C17023"},
                   {"label": "Richibucto", "value": "C17024"},
                   {"label": "Sackville", "value": "C17025"},
                   {"label": "Saint Andrews", "value": "C17026"},
                   {"label": "Saint John", "value": "C17027"},
                   {"label": "Saint-Antoine", "value": "C17028"},
                   {"label": "Saint-Leonard", "value": "C17029"},
                   {"label": "Salisbury", "value": "C17030"},
                   {"label": "Shediac", "value": "C17031"},
                   {"label": "Shediac Bridge-Shediac River", "value": "C17032"},
                   {"label": "Shippagan", "value": "C17033"},
                   {"label": "Starlight Village", "value": "C17034"},
                   {"label": "Sussex", "value": "C17035"},
                   {"label": "Tracadie-Sheila", "value": "C17036"},
                   {"label": "Wells", "value": "C17037"}],
 "New Hampshire": [{"label": "Alexandria", "value": "C137331"},
                   {"label": "Alstead", "value": "C137332"},
                   {"label": "Andover", "value": "C137333"},
                   {"label": "Antrim", "value": "C137334"},
                   {"label": "Ashland", "value": "C137335"},
                   {"label": "Atkinson", "value": "C137336"},
                   {"label": "Auburn", "value": "C137337"},
                   {"label": "Barnstead", "value": "C137338"},
                   {"label": "Barrington", "value": "C137339"},
                   {"label": "Bedford", "value": "C137340"},
                   {"label": "Belknap County", "value": "C137341"},
                   {"label": "Belmont", "value": "C137342"},
                   {"label": "Berlin", "value": "C137343"},
                   {"label": "Boscawen", "value": "C137344"},
                   {"label": "Bow Bog", "value": "C137345"},
                   {"label": "Brentwood", "value": "C137346"},
                   {"label": "Bridgewater", "value": "C137347"},
                   {"label": "Bristol", "value": "C137348"},
                   {"label": "Brookline", "value": "C137349"},
                   {"label": "Candia", "value": "C137350"},
                   {"label": "Canterbury", "value": "C137351"},
                   {"label": "Carroll County", "value": "C137352"},
                   {"label": "Center Harbor", "value": "C137353"},
                   {"label": "Charlestown", "value": "C137354"},
                   {"label": "Cheshire County", "value": "C137355"},
                   {"label": "Chester", "value": "C137356"},
                   {"label": "Chesterfield", "value": "C137357"},
                   {"label": "Chichester", "value": "C137358"},
                   {"label": "Claremont", "value": "C137359"},
                   {"label": "Colebrook", "value": "C137360"},
                   {"label": "Concord", "value": "C137361"},
                   {"label": "Contoocook", "value": "C137362"},
                   {"label": "Conway", "value": "C137363"},
                   {"label": "Coos County", "value": "C137364"},
                   {"label": "Danbury", "value": "C137365"},
                   {"label": "Danville", "value": "C137366"},
                   {"label": "Deerfield", "value": "C137367"},
                   {"label": "Deering", "value": "C137368"},
                   {"label": "Derry", "value": "C137369"},
                   {"label": "Derry Village", "value": "C137370"},
                   {"label": "Dover", "value": "C137371"},
                   {"label": "Dublin", "value": "C137372"},
                   {"label": "Durham", "value": "C137373"},
                   {"label": "East Concord", "value": "C137374"},
                   {"label": "East Kingston", "value": "C137375"},
                   {"label": "East Merrimack", "value": "C137376"},
                   {"label": "Effingham", "value": "C137377"},
                   {"label": "Enfield", "value": "C137378"},
                   {"label": "Epping", "value": "C137379"},
                   {"label": "Epsom", "value": "C137380"},
                   {"label": "Exeter", "value": "C137381"},
                   {"label": "Farmington", "value": "C137382"},
                   {"label": "Fitzwilliam", "value": "C137383"},
                   {"label": "Francestown", "value": "C137384"},
                   {"label": "Franklin", "value": "C137385"},
                   {"label": "Freedom", "value": "C137386"},
                   {"label": "Fremont", "value": "C137387"},
                   {"label": "Gilford", "value": "C137388"},
                   {"label": "Gilmanton", "value": "C137389"},
                   {"label": "Goffstown", "value": "C137390"},
                   {"label": "Gorham", "value": "C137391"},
                   {"label": "Grafton", "value": "C137392"},
                   {"label": "Grafton County", "value": "C137393"},
                   {"label": "Grantham", "value": "C137394"},
                   {"label": "Greenfield", "value": "C137395"},
                   {"label": "Greenland", "value": "C137396"},
                   {"label": "Greenville", "value": "C137397"},
                   {"label": "Groveton", "value": "C137398"},
                   {"label": "Hampstead", "value": "C137399"},
                   {"label": "Hampton", "value": "C137400"},
                   {"label": "Hampton Beach", "value": "C137401"},
                   {"label": "Hampton Falls", "value": "C137402"},
                   {"label": "Hanover", "value": "C137403"},
                   {"label": "Harrisville", "value": "C137404"},
                   {"label": "Haverhill", "value": "C137405"},
                   {"label": "Henniker", "value": "C137406"},
                   {"label": "Hill", "value": "C137407"},
                   {"label": "Hillsborough", "value": "C137408"},
                   {"label": "Hillsborough County", "value": "C137409"},
                   {"label": "Hinsdale", "value": "C137410"},
                   {"label": "Holderness", "value": "C137411"},
                   {"label": "Hollis", "value": "C137412"},
                   {"label": "Hooksett", "value": "C137413"},
                   {"label": "Hopkinton", "value": "C137414"},
                   {"label": "Hudson", "value": "C137415"},
                   {"label": "Jaffrey", "value": "C137416"},
                   {"label": "Jefferson", "value": "C137417"},
                   {"label": "Keene", "value": "C137418"},
                   {"label": "Kensington", "value": "C137419"},
                   {"label": "Kingston", "value": "C137420"},
                   {"label": "Laconia", "value": "C137421"},
                   {"label": "Lancaster", "value": "C137422"},
                   {"label": "Lebanon", "value": "C137423"},
                   {"label": "Lee", "value": "C137424"},
                   {"label": "Lempster", "value": "C137425"},
                   {"label": "Litchfield", "value": "C137426"},
                   {"label": "Littleton", "value": "C137427"},
                   {"label": "Londonderry", "value": "C137428"},
                   {"label": "Lyme", "value": "C137429"},
                   {"label": "Lyndeborough", "value": "C137430"},
                   {"label": "Madbury", "value": "C137431"},
                   {"label": "Madison", "value": "C137432"},
                   {"label": "Manchester", "value": "C137433"},
                   {"label": "Marlborough", "value": "C137434"},
                   {"label": "Mason", "value": "C137435"},
                   {"label": "Meredith", "value": "C137436"},
                   {"label": "Merrimack", "value": "C137437"},
                   {"label": "Merrimack County", "value": "C137438"},
                   {"label": "Milan", "value": "C137439"},
                   {"label": "Milford", "value": "C137440"},
                   {"label": "Mont Vernon", "value": "C137441"},
                   {"label": "Moultonborough", "value": "C137442"},
                   {"label": "Nashua", "value": "C137443"},
                   {"label": "New Boston", "value": "C137444"},
                   {"label": "New Castle", "value": "C137445"},
                   {"label": "New Durham", "value": "C137446"},
                   {"label": "New Ipswich", "value": "C137447"},
                   {"label": "New London", "value": "C137448"},
                   {"label": "Newbury", "value": "C137449"},
                   {"label": "Newmarket", "value": "C137450"},
                   {"label": "Newport", "value": "C137451"},
                   {"label": "Newton", "value": "C137452"},
                   {"label": "North Conway", "value": "C137453"},
                   {"label": "North Hampton", "value": "C137454"},
                   {"label": "Northfield", "value": "C137455"},
                   {"label": "Northumberland", "value": "C137456"},
                   {"label": "Northwood", "value": "C137457"},
                   {"label": "Nottingham", "value": "C137458"},
                   {"label": "Orford", "value": "C137459"},
                   {"label": "Ossipee", "value": "C137460"},
                   {"label": "Pelham", "value": "C137461"},
                   {"label": "Pembroke", "value": "C137462"},
                   {"label": "Peterborough", "value": "C137463"},
                   {"label": "Pinardville", "value": "C137464"},
                   {"label": "Pittsfield", "value": "C137465"},
                   {"label": "Plaistow", "value": "C137466"},
                   {"label": "Plymouth", "value": "C137467"},
                   {"label": "Portsmouth", "value": "C137468"},
                   {"label": "Raymond", "value": "C137469"},
                   {"label": "Richmond", "value": "C137470"},
                   {"label": "Rindge", "value": "C137471"},
                   {"label": "Rochester", "value": "C137472"},
                   {"label": "Rockingham County", "value": "C137473"},
                   {"label": "Rollinsford", "value": "C137474"},
                   {"label": "Rumney", "value": "C137475"},
                   {"label": "Rye", "value": "C137476"},
                   {"label": "Salem", "value": "C137477"},
                   {"label": "Salisbury", "value": "C137478"},
                   {"label": "Sanbornton", "value": "C137479"},
                   {"label": "Sanbornville", "value": "C137480"},
                   {"label": "Sandown", "value": "C137481"},
                   {"label": "Sandwich", "value": "C137482"},
                   {"label": "Seabrook", "value": "C137483"},
                   {"label": "Somersworth", "value": "C137484"},
                   {"label": "South Hooksett", "value": "C137485"},
                   {"label": "Springfield", "value": "C137486"},
                   {"label": "Strafford", "value": "C137487"},
                   {"label": "Strafford County", "value": "C137488"},
                   {"label": "Stratford", "value": "C137489"},
                   {"label": "Stratham Station", "value": "C137490"},
                   {"label": "Sullivan County", "value": "C137491"},
                   {"label": "Sunapee", "value": "C137492"},
                   {"label": "Suncook", "value": "C137493"},
                   {"label": "Sutton", "value": "C137494"},
                   {"label": "Swanzey", "value": "C137495"},
                   {"label": "Tamworth", "value": "C137496"},
                   {"label": "Temple", "value": "C137497"},
                   {"label": "Thornton", "value": "C137498"},
                   {"label": "Tilton", "value": "C137499"},
                   {"label": "Tilton-Northfield", "value": "C137500"},
                   {"label": "Troy", "value": "C137501"},
                   {"label": "Tuftonboro", "value": "C137502"},
                   {"label": "Unity", "value": "C137503"},
                   {"label": "Wakefield", "value": "C137504"},
                   {"label": "Weare", "value": "C137505"},
                   {"label": "Webster", "value": "C137506"},
                   {"label": "West Swanzey", "value": "C137507"},
                   {"label": "Westmoreland", "value": "C137508"},
                   {"label": "Whitefield", "value": "C137509"},
                   {"label": "Wilmot", "value": "C137510"},
                   {"label": "Wilton", "value": "C137511"},
                   {"label": "Winchester", "value": "C137512"},
                   {"label": "Windham", "value": "C137513"},
                   {"label": "Wolfeboro", "value": "C137514"},
                   {"label": "Woodstock", "value": "C137515"},
                   {"label": "Woodsville", "value": "C137516"}],
 "New Ireland Province": [{"label": "Kavieng", "value": "C80452"},
                          {"label": "Namatanai", "value": "C80453"}],
 "New Jersey": [{"label": "Absecon", "value": "C137517"},
                {"label": "Allendale", "value": "C137518"},
                {"label": "Allentown", "value": "C137519"},
                {"label": "Alloway", "value": "C137520"},
                {"label": "Alpha", "value": "C137521"},
                {"label": "Alpine", "value": "C137522"},
                {"label": "Annandale", "value": "C137523"},
                {"label": "Asbury Park", "value": "C137524"},
                {"label": "Ashland", "value": "C137525"},
                {"label": "Atco", "value": "C137526"},
                {"label": "Atlantic City", "value": "C137527"},
                {"label": "Atlantic County", "value": "C137528"},
                {"label": "Atlantic Highlands", "value": "C137529"},
                {"label": "Audubon", "value": "C137530"},
                {"label": "Audubon Park", "value": "C137531"},
                {"label": "Avalon", "value": "C137532"},
                {"label": "Avenel", "value": "C137533"},
                {"label": "Avon-by-the-Sea", "value": "C137534"},
                {"label": "Barnegat", "value": "C137535"},
                {"label": "Barrington", "value": "C137536"},
                {"label": "Bayonne", "value": "C137537"},
                {"label": "Bayville", "value": "C137538"},
                {"label": "Beach Haven", "value": "C137539"},
                {"label": "Beach Haven West", "value": "C137540"},
                {"label": "Beachwood", "value": "C137541"},
                {"label": "Beattystown", "value": "C137542"},
                {"label": "Beckett", "value": "C137543"},
                {"label": "Bedminster", "value": "C137544"},
                {"label": "Belford", "value": "C137545"},
                {"label": "Belleville", "value": "C137546"},
                {"label": "Bellmawr", "value": "C137547"},
                {"label": "Belmar", "value": "C137548"},
                {"label": "Belvidere", "value": "C137549"},
                {"label": "Bergen County", "value": "C137550"},
                {"label": "Bergenfield", "value": "C137551"},
                {"label": "Berkeley Heights", "value": "C137552"},
                {"label": "Berlin", "value": "C137553"},
                {"label": "Bernardsville", "value": "C137554"},
                {"label": "Beverly", "value": "C137555"},
                {"label": "Blackwood", "value": "C137556"},
                {"label": "Bloomfield", "value": "C137557"},
                {"label": "Bloomingdale", "value": "C137558"},
                {"label": "Bogota", "value": "C137559"},
                {"label": "Boonton", "value": "C137560"},
                {"label": "Bordentown", "value": "C137561"},
                {"label": "Bound Brook", "value": "C137562"},
                {"label": "Bradley Beach", "value": "C137563"},
                {"label": "Bradley Gardens", "value": "C137564"},
                {"label": "Brass Castle", "value": "C137565"},
                {"label": "Bridgeton", "value": "C137566"},
                {"label": "Bridgewater", "value": "C137567"},
                {"label": "Brielle", "value": "C137568"},
                {"label": "Brigantine", "value": "C137569"},
                {"label": "Brookdale", "value": "C137570"},
                {"label": "Brooklawn", "value": "C137571"},
                {"label": "Browns Mills", "value": "C137572"},
                {"label": "Brownville", "value": "C137573"},
                {"label": "Budd Lake", "value": "C137574"},
                {"label": "Buena", "value": "C137575"},
                {"label": "Burlington", "value": "C137576"},
                {"label": "Burlington County", "value": "C137577"},
                {"label": "Butler", "value": "C137578"},
                {"label": "Caldwell", "value": "C137579"},
                {"label": "Califon", "value": "C137580"},
                {"label": "Camden", "value": "C137581"},
                {"label": "Camden County", "value": "C137582"},
                {"label": "Cape May", "value": "C137583"},
                {"label": "Cape May County", "value": "C137584"},
                {"label": "Cape May Court House", "value": "C137585"},
                {"label": "Carlstadt", "value": "C137586"},
                {"label": "Carneys Point", "value": "C137587"},
                {"label": "Carteret", "value": "C137588"},
                {"label": "Cedar Glen Lakes", "value": "C137589"},
                {"label": "Cedar Glen West", "value": "C137590"},
                {"label": "Cedar Grove", "value": "C137591"},
                {"label": "Chatham", "value": "C137592"},
                {"label": "Cherry Hill", "value": "C137593"},
                {"label": "Cherry Hill Mall", "value": "C137594"},
                {"label": "Chesilhurst", "value": "C137595"},
                {"label": "Chester", "value": "C137596"},
                {"label": "Cinnaminson", "value": "C137597"},
                {"label": "Clark", "value": "C137598"},
                {"label": "Clayton", "value": "C137599"},
                {"label": "Clearbrook Park", "value": "C137600"},
                {"label": "Clementon", "value": "C137601"},
                {"label": "Cliffside Park", "value": "C137602"},
                {"label": "Cliffwood Beach", "value": "C137603"},
                {"label": "Clifton", "value": "C137604"},
                {"label": "Clinton", "value": "C137605"},
                {"label": "Closter", "value": "C137606"},
                {"label": "Collings Lakes", "value": "C137607"},
                {"label": "Collingswood", "value": "C137608"},
                {"label": "Colonia", "value": "C137609"},
                {"label": "Concordia", "value": "C137610"},
                {"label": "Country Lake Estates", "value": "C137611"},
                {"label": "Cranbury", "value": "C137612"},
                {"label": "Crandon Lakes", "value": "C137613"},
                {"label": "Cranford", "value": "C137614"},
                {"label": "Cresskill", "value": "C137615"},
                {"label": "Crestwood Village", "value": "C137616"},
                {"label": "Cumberland County", "value": "C137617"},
                {"label": "Dayton", "value": "C137618"},
                {"label": "Delanco", "value": "C137619"},
                {"label": "Demarest", "value": "C137620"},
                {"label": "Dover", "value": "C137621"},
                {"label": "Dover Beaches North", "value": "C137622"},
                {"label": "Dover Beaches South", "value": "C137623"},
                {"label": "Dumont", "value": "C137624"},
                {"label": "Dunellen", "value": "C137625"},
                {"label": "East Brunswick", "value": "C137626"},
                {"label": "East Franklin", "value": "C137627"},
                {"label": "East Freehold", "value": "C137628"},
                {"label": "East Hanover", "value": "C137629"},
                {"label": "East Newark", "value": "C137630"},
                {"label": "East Orange", "value": "C137631"},
                {"label": "East Rutherford", "value": "C137632"},
                {"label": "Eatontown", "value": "C137633"},
                {"label": "Echelon", "value": "C137634"},
                {"label": "Edgewater", "value": "C137635"},
                {"label": "Edgewater Park", "value": "C137636"},
                {"label": "Edison", "value": "C137637"},
                {"label": "Egg Harbor City", "value": "C137638"},
                {"label": "Elizabeth", "value": "C137639"},
                {"label": "Ellisburg", "value": "C137640"},
                {"label": "Elmer", "value": "C137641"},
                {"label": "Elmwood Park", "value": "C137642"},
                {"label": "Elwood", "value": "C137643"},
                {"label": "Emerson", "value": "C137644"},
                {"label": "Englewood", "value": "C137645"},
                {"label": "Englewood Cliffs", "value": "C137646"},
                {"label": "Englishtown", "value": "C137647"},
                {"label": "Erma", "value": "C137648"},
                {"label": "Essex County", "value": "C137649"},
                {"label": "Essex Fells", "value": "C137650"},
                {"label": "Estell Manor", "value": "C137651"},
                {"label": "Ewing", "value": "C137652"},
                {"label": "Fair Haven", "value": "C137653"},
                {"label": "Fair Lawn", "value": "C137654"},
                {"label": "Fairfield", "value": "C137655"},
                {"label": "Fairton", "value": "C137656"},
                {"label": "Fairview", "value": "C137657"},
                {"label": "Fanwood", "value": "C137658"},
                {"label": "Farmingdale", "value": "C137659"},
                {"label": "Finderne", "value": "C137660"},
                {"label": "Flemington", "value": "C137661"},
                {"label": "Florence", "value": "C137662"},
                {"label": "Florham Park", "value": "C137663"},
                {"label": "Folsom", "value": "C137664"},
                {"label": "Fords", "value": "C137665"},
                {"label": "Forked River", "value": "C137666"},
                {"label": "Fort Dix", "value": "C137667"},
                {"label": "Fort Lee", "value": "C137668"},
                {"label": "Franklin", "value": "C137669"},
                {"label": "Franklin Center", "value": "C137670"},
                {"label": "Franklin Lakes", "value": "C137671"},
                {"label": "Franklin Park", "value": "C137672"},
                {"label": "Freehold", "value": "C137673"},
                {"label": "Frenchtown", "value": "C137674"},
                {"label": "Garfield", "value": "C137675"},
                {"label": "Garwood", "value": "C137676"},
                {"label": "Gibbsboro", "value": "C137677"},
                {"label": "Gibbstown", "value": "C137678"},
                {"label": "Gladstone", "value": "C137679"},
                {"label": "Glassboro", "value": "C137680"},
                {"label": "Glen Gardner", "value": "C137681"},
                {"label": "Glen Ridge", "value": "C137682"},
                {"label": "Glen Rock", "value": "C137683"},
                {"label": "Glendora", "value": "C137684"},
                {"label": "Gloucester City", "value": "C137685"},
                {"label": "Gloucester County", "value": "C137686"},
                {"label": "Golden Triangle", "value": "C137687"},
                {"label": "Green Knoll", "value": "C137688"},
                {"label": "Greentree", "value": "C137689"},
                {"label": "Groveville", "value": "C137690"},
                {"label": "Guttenberg", "value": "C137691"},
                {"label": "Hackensack", "value": "C137692"},
                {"label": "Hackettstown", "value": "C137693"},
                {"label": "Haddon Heights", "value": "C137694"},
                {"label": "Haddonfield", "value": "C137695"},
                {"label": "Haledon", "value": "C137696"},
                {"label": "Hamburg", "value": "C137697"},
                {"label": "Hamilton Square", "value": "C137698"},
                {"label": "Hammonton", "value": "C137699"},
                {"label": "Hampton", "value": "C137700"},
                {"label": "Hanover", "value": "C137701"},
                {"label": "Hardwick", "value": "C137702"},
                {"label": "Harrington Park", "value": "C137703"},
                {"label": "Harrison", "value": "C137704"},
                {"label": "Hasbrouck Heights", "value": "C137705"},
                {"label": "Haworth", "value": "C137706"},
                {"label": "Hawthorne", "value": "C137707"},
                {"label": "Hazlet Township", "value": "C137708"},
                {"label": "Heathcote", "value": "C137709"},
                {"label": "Helmetta", "value": "C137710"},
                {"label": "High Bridge", "value": "C137711"},
                {"label": "Highland Lake", "value": "C137712"},
                {"label": "Highland Park", "value": "C137713"},
                {"label": "Highlands", "value": "C137714"},
                {"label": "Hightstown", "value": "C137715"},
                {"label": "Hillsdale", "value": "C137716"},
                {"label": "Hillside", "value": "C137717"},
                {"label": "Ho-Ho-Kus", "value": "C137718"},
                {"label": "Hoboken", "value": "C137719"},
                {"label": "Holiday City South", "value": "C137720"},
                {"label": "Holiday City-Berkeley", "value": "C137721"},
                {"label": "Holiday Heights", "value": "C137722"},
                {"label": "Hopatcong", "value": "C137723"},
                {"label": "Hopatcong Hills", "value": "C137724"},
                {"label": "Hopewell", "value": "C137725"},
                {"label": "Hudson County", "value": "C137726"},
                {"label": "Hunterdon County", "value": "C137727"},
                {"label": "Irvington", "value": "C137728"},
                {"label": "Iselin", "value": "C137729"},
                {"label": "Island Heights", "value": "C137730"},
                {"label": "Jackson", "value": "C137731"},
                {"label": "Jamesburg", "value": "C137732"},
                {"label": "Jersey City", "value": "C137733"},
                {"label": "Keansburg", "value": "C137734"},
                {"label": "Kearny", "value": "C137735"},
                {"label": "Kendall Park", "value": "C137736"},
                {"label": "Kenilworth", "value": "C137737"},
                {"label": "Kenvil", "value": "C137738"},
                {"label": "Keyport", "value": "C137739"},
                {"label": "Kingston", "value": "C137740"},
                {"label": "Kingston Estates", "value": "C137741"},
                {"label": "Kinnelon", "value": "C137742"},
                {"label": "Lake Como", "value": "C137743"},
                {"label": "Lake Mohawk", "value": "C137744"},
                {"label": "Lake Telemark", "value": "C137745"},
                {"label": "Lakehurst", "value": "C137746"},
                {"label": "Lakewood", "value": "C137747"},
                {"label": "Lambertville", "value": "C137748"},
                {"label": "Landing", "value": "C137749"},
                {"label": "Laurel Lake", "value": "C137750"},
                {"label": "Laurel Springs", "value": "C137751"},
                {"label": "Laurence Harbor", "value": "C137752"},
                {"label": "Lavallette", "value": "C137753"},
                {"label": "Lawnside", "value": "C137754"},
                {"label": "Lawrenceville", "value": "C137755"},
                {"label": "Lebanon", "value": "C137756"},
                {"label": "Leisure Knoll", "value": "C137757"},
                {"label": "Leisure Village", "value": "C137758"},
                {"label": "Leisure Village East", "value": "C137759"},
                {"label": "Leisure Village West-Pine Lake Park",
                 "value": "C137760"},
                {"label": "Leisuretowne", "value": "C137761"},
                {"label": "Leonardo", "value": "C137762"},
                {"label": "Leonia", "value": "C137763"},
                {"label": "Lincoln Park", "value": "C137764"},
                {"label": "Lincroft", "value": "C137765"},
                {"label": "Linden", "value": "C137766"},
                {"label": "Lindenwold", "value": "C137767"},
                {"label": "Linwood", "value": "C137768"},
                {"label": "Little Falls", "value": "C137769"},
                {"label": "Little Ferry", "value": "C137770"},
                {"label": "Little Silver", "value": "C137771"},
                {"label": "Livingston", "value": "C137772"},
                {"label": "Lodi", "value": "C137773"},
                {"label": "Long Branch", "value": "C137774"},
                {"label": "Long Valley", "value": "C137775"},
                {"label": "Lyndhurst", "value": "C137776"},
                {"label": "Madison", "value": "C137777"},
                {"label": "Madison Park", "value": "C137778"},
                {"label": "Magnolia", "value": "C137779"},
                {"label": "Mahwah", "value": "C137780"},
                {"label": "Manahawkin", "value": "C137781"},
                {"label": "Manasquan", "value": "C137782"},
                {"label": "Mantua Township", "value": "C137783"},
                {"label": "Manville", "value": "C137784"},
                {"label": "Maple Shade", "value": "C137785"},
                {"label": "Maplewood", "value": "C137786"},
                {"label": "Margate City", "value": "C137787"},
                {"label": "Marlboro", "value": "C137788"},
                {"label": "Marlton", "value": "C137789"},
                {"label": "Martinsville", "value": "C137790"},
                {"label": "Matawan", "value": "C137791"},
                {"label": "Mays Landing", "value": "C137792"},
                {"label": "Maywood", "value": "C137793"},
                {"label": "McGuire AFB", "value": "C137794"},
                {"label": "Medford Lakes", "value": "C137795"},
                {"label": "Mendham", "value": "C137796"},
                {"label": "Mercer County", "value": "C137797"},
                {"label": "Mercerville", "value": "C137798"},
                {"label": "Mercerville-Hamilton Square", "value": "C137799"},
                {"label": "Merchantville", "value": "C137800"},
                {"label": "Metuchen", "value": "C137801"},
                {"label": "Middlebush", "value": "C137802"},
                {"label": "Middlesex", "value": "C137803"},
                {"label": "Middlesex County", "value": "C137804"},
                {"label": "Midland Park", "value": "C137805"},
                {"label": "Milford", "value": "C137806"},
                {"label": "Milltown", "value": "C137807"},
                {"label": "Millville", "value": "C137808"},
                {"label": "Monmouth Beach", "value": "C137809"},
                {"label": "Monmouth County", "value": "C137810"},
                {"label": "Monmouth Junction", "value": "C137811"},
                {"label": "Montclair", "value": "C137812"},
                {"label": "Montvale", "value": "C137813"},
                {"label": "Moonachie", "value": "C137814"},
                {"label": "Moorestown-Lenola", "value": "C137815"},
                {"label": "Morganville", "value": "C137816"},
                {"label": "Morris County", "value": "C137817"},
                {"label": "Morris Plains", "value": "C137818"},
                {"label": "Morristown", "value": "C137819"},
                {"label": "Mount Arlington", "value": "C137820"},
                {"label": "Mount Ephraim", "value": "C137821"},
                {"label": "Mount Holly", "value": "C137822"},
                {"label": "Mount Laurel", "value": "C137823"},
                {"label": "Mountain Lakes", "value": "C137824"},
                {"label": "Mountainside", "value": "C137825"},
                {"label": "Mullica Hill", "value": "C137826"},
                {"label": "Mystic Island", "value": "C137827"},
                {"label": "National Park", "value": "C137828"},
                {"label": "Navesink", "value": "C137829"},
                {"label": "Neptune City", "value": "C137830"},
                {"label": "Netcong", "value": "C137831"},
                {"label": "New Brunswick", "value": "C137832"},
                {"label": "New Egypt", "value": "C137833"},
                {"label": "New Milford", "value": "C137834"},
                {"label": "New Providence", "value": "C137835"},
                {"label": "Newark", "value": "C137836"},
                {"label": "Newfield", "value": "C137837"},
                {"label": "Newton", "value": "C137838"},
                {"label": "North Arlington", "value": "C137839"},
                {"label": "North Beach Haven", "value": "C137840"},
                {"label": "North Bergen", "value": "C137841"},
                {"label": "North Caldwell", "value": "C137842"},
                {"label": "North Cape May", "value": "C137843"},
                {"label": "North Haledon", "value": "C137844"},
                {"label": "North Middletown", "value": "C137845"},
                {"label": "North Plainfield", "value": "C137846"},
                {"label": "North Wildwood", "value": "C137847"},
                {"label": "Northfield", "value": "C137848"},
                {"label": "Northvale", "value": "C137849"},
                {"label": "Norwood", "value": "C137850"},
                {"label": "Nutley", "value": "C137851"},
                {"label": "Oak Valley", "value": "C137852"},
                {"label": "Oakhurst", "value": "C137853"},
                {"label": "Oakland", "value": "C137854"},
                {"label": "Oaklyn", "value": "C137855"},
                {"label": "Ocean Acres", "value": "C137856"},
                {"label": "Ocean City", "value": "C137857"},
                {"label": "Ocean County", "value": "C137858"},
                {"label": "Ocean Gate", "value": "C137859"},
                {"label": "Ocean Grove", "value": "C137860"},
                {"label": "Oceanport", "value": "C137861"},
                {"label": "Ogdensburg", "value": "C137862"},
                {"label": "Old Bridge", "value": "C137863"},
                {"label": "Old Tappan", "value": "C137864"},
                {"label": "Olivet", "value": "C137865"},
                {"label": "Oradell", "value": "C137866"},
                {"label": "Orange", "value": "C137867"},
                {"label": "Oxford", "value": "C137868"},
                {"label": "Palisades Park", "value": "C137869"},
                {"label": "Palmyra", "value": "C137870"},
                {"label": "Paramus", "value": "C137871"},
                {"label": "Park Ridge", "value": "C137872"},
                {"label": "Parsippany", "value": "C137873"},
                {"label": "Passaic", "value": "C137874"},
                {"label": "Passaic County", "value": "C137875"},
                {"label": "Paterson", "value": "C137876"},
                {"label": "Paulsboro", "value": "C137877"},
                {"label": "Peapack", "value": "C137878"},
                {"label": "Pemberton", "value": "C137879"},
                {"label": "Pemberton Heights", "value": "C137880"},
                {"label": "Pennington", "value": "C137881"},
                {"label": "Penns Grove", "value": "C137882"},
                {"label": "Pennsauken", "value": "C137883"},
                {"label": "Pennsville", "value": "C137884"},
                {"label": "Perth Amboy", "value": "C137885"},
                {"label": "Phillipsburg", "value": "C137886"},
                {"label": "Pine Beach", "value": "C137887"},
                {"label": "Pine Hill", "value": "C137888"},
                {"label": "Pine Lake Park", "value": "C137889"},
                {"label": "Pine Ridge at Crestwood", "value": "C137890"},
                {"label": "Piscataway", "value": "C137891"},
                {"label": "Pitman", "value": "C137892"},
                {"label": "Plainfield", "value": "C137893"},
                {"label": "Plainsboro Center", "value": "C137894"},
                {"label": "Pleasantville", "value": "C137895"},
                {"label": "Point Pleasant", "value": "C137896"},
                {"label": "Point Pleasant Beach", "value": "C137897"},
                {"label": "Pomona", "value": "C137898"},
                {"label": "Pompton Lakes", "value": "C137899"},
                {"label": "Port Monmouth", "value": "C137900"},
                {"label": "Port Norris", "value": "C137901"},
                {"label": "Port Reading", "value": "C137902"},
                {"label": "Port Republic", "value": "C137903"},
                {"label": "Presidential Lakes Estates", "value": "C137904"},
                {"label": "Princeton", "value": "C137905"},
                {"label": "Princeton Junction", "value": "C137906"},
                {"label": "Princeton Meadows", "value": "C137907"},
                {"label": "Prospect Park", "value": "C137908"},
                {"label": "Rahway", "value": "C137909"},
                {"label": "Ramblewood", "value": "C137910"},
                {"label": "Ramsey", "value": "C137911"},
                {"label": "Ramtown", "value": "C137912"},
                {"label": "Randolph", "value": "C137913"},
                {"label": "Raritan", "value": "C137914"},
                {"label": "Red Bank", "value": "C137915"},
                {"label": "Richwood", "value": "C137916"},
                {"label": "Ridgefield", "value": "C137917"},
                {"label": "Ridgefield Park", "value": "C137918"},
                {"label": "Ridgewood", "value": "C137919"},
                {"label": "Ringwood", "value": "C137920"},
                {"label": "Rio Grande", "value": "C137921"},
                {"label": "River Edge", "value": "C137922"},
                {"label": "River Vale", "value": "C137923"},
                {"label": "Riverdale", "value": "C137924"},
                {"label": "Riverton", "value": "C137925"},
                {"label": "Robbinsville", "value": "C137926"},
                {"label": "Robertsville", "value": "C137927"},
                {"label": "Rochelle Park", "value": "C137928"},
                {"label": "Rockaway", "value": "C137929"},
                {"label": "Roebling", "value": "C137930"},
                {"label": "Roseland", "value": "C137931"},
                {"label": "Roselle", "value": "C137932"},
                {"label": "Roselle Park", "value": "C137933"},
                {"label": "Rosenhayn", "value": "C137934"},
                {"label": "Rossmoor", "value": "C137935"},
                {"label": "Rumson", "value": "C137936"},
                {"label": "Runnemede", "value": "C137937"},
                {"label": "Rutherford", "value": "C137938"},
                {"label": "Saddle Brook", "value": "C137939"},
                {"label": "Saddle River", "value": "C137940"},
                {"label": "Salem", "value": "C137941"},
                {"label": "Salem County", "value": "C137942"},
                {"label": "Sayreville", "value": "C137943"},
                {"label": "Sayreville Junction", "value": "C137944"},
                {"label": "Scotch Plains", "value": "C137945"},
                {"label": "Sea Bright", "value": "C137946"},
                {"label": "Sea Girt", "value": "C137947"},
                {"label": "Sea Isle City", "value": "C137948"},
                {"label": "Seabrook Farms", "value": "C137949"},
                {"label": "Seaside Heights", "value": "C137950"},
                {"label": "Seaside Park", "value": "C137951"},
                {"label": "Secaucus", "value": "C137952"},
                {"label": "Sewaren", "value": "C137953"},
                {"label": "Shark River Hills", "value": "C137954"},
                {"label": "Ship Bottom", "value": "C137955"},
                {"label": "Short Hills", "value": "C137956"},
                {"label": "Shrewsbury", "value": "C137957"},
                {"label": "Sicklerville", "value": "C137958"},
                {"label": "Silver Ridge", "value": "C137959"},
                {"label": "Singac", "value": "C137960"},
                {"label": "Sixmile Run", "value": "C137961"},
                {"label": "Smithville", "value": "C137962"},
                {"label": "Society Hill", "value": "C137963"},
                {"label": "Somerdale", "value": "C137964"},
                {"label": "Somers Point", "value": "C137965"},
                {"label": "Somerset", "value": "C137966"},
                {"label": "Somerset County", "value": "C137967"},
                {"label": "Somerville", "value": "C137968"},
                {"label": "South Amboy", "value": "C137969"},
                {"label": "South Belmar", "value": "C137970"},
                {"label": "South Bound Brook", "value": "C137971"},
                {"label": "South Old Bridge", "value": "C137972"},
                {"label": "South Orange", "value": "C137973"},
                {"label": "South Plainfield", "value": "C137974"},
                {"label": "South River", "value": "C137975"},
                {"label": "South Toms River", "value": "C137976"},
                {"label": "South Vineland", "value": "C137977"},
                {"label": "Sparta", "value": "C137978"},
                {"label": "Spotswood", "value": "C137979"},
                {"label": "Spring Lake", "value": "C137980"},
                {"label": "Spring Lake Heights", "value": "C137981"},
                {"label": "Springdale", "value": "C137982"},
                {"label": "Springfield", "value": "C137983"},
                {"label": "Stanhope", "value": "C137984"},
                {"label": "Stratford", "value": "C137985"},
                {"label": "Strathmore", "value": "C137986"},
                {"label": "Succasunna", "value": "C137987"},
                {"label": "Summit", "value": "C137988"},
                {"label": "Surf City", "value": "C137989"},
                {"label": "Sussex", "value": "C137990"},
                {"label": "Sussex County", "value": "C137991"},
                {"label": "Swedesboro", "value": "C137992"},
                {"label": "Teaneck", "value": "C137993"},
                {"label": "Ten Mile Run", "value": "C137994"},
                {"label": "Tenafly", "value": "C137995"},
                {"label": "Tinton Falls", "value": "C137996"},
                {"label": "Toms River", "value": "C137997"},
                {"label": "Totowa", "value": "C137998"},
                {"label": "Trenton", "value": "C137999"},
                {"label": "Tuckerton", "value": "C138000"},
                {"label": "Turnersville", "value": "C138001"},
                {"label": "Twin Rivers", "value": "C138002"},
                {"label": "Union", "value": "C138003"},
                {"label": "Union Beach", "value": "C138004"},
                {"label": "Union City", "value": "C138005"},
                {"label": "Union County", "value": "C138006"},
                {"label": "Upper Montclair", "value": "C138007"},
                {"label": "Upper Pohatcong", "value": "C138008"},
                {"label": "Upper Saddle River", "value": "C138009"},
                {"label": "Ventnor City", "value": "C138010"},
                {"label": "Vernon Center", "value": "C138011"},
                {"label": "Vernon Valley", "value": "C138012"},
                {"label": "Verona", "value": "C138013"},
                {"label": "Victory Gardens", "value": "C138014"},
                {"label": "Victory Lakes", "value": "C138015"},
                {"label": "Villas", "value": "C138016"},
                {"label": "Vincentown", "value": "C138017"},
                {"label": "Vineland", "value": "C138018"},
                {"label": "Vista Center", "value": "C138019"},
                {"label": "Voorhees", "value": "C138020"},
                {"label": "Waldwick", "value": "C138021"},
                {"label": "Wallington", "value": "C138022"},
                {"label": "Wanamassa", "value": "C138023"},
                {"label": "Wanaque", "value": "C138024"},
                {"label": "Waretown", "value": "C138025"},
                {"label": "Warren County", "value": "C138026"},
                {"label": "Warren Township", "value": "C138027"},
                {"label": "Washington", "value": "C138028"},
                {"label": "Watchung", "value": "C138029"},
                {"label": "Wayne", "value": "C138030"},
                {"label": "Weehawken", "value": "C138031"},
                {"label": "Wenonah", "value": "C138032"},
                {"label": "West Belmar", "value": "C138033"},
                {"label": "West Cape May", "value": "C138034"},
                {"label": "West Freehold", "value": "C138035"},
                {"label": "West Long Branch", "value": "C138036"},
                {"label": "West Milford", "value": "C138037"},
                {"label": "West New York", "value": "C138038"},
                {"label": "West Orange", "value": "C138039"},
                {"label": "Westfield", "value": "C138040"},
                {"label": "Weston", "value": "C138041"},
                {"label": "Westville", "value": "C138042"},
                {"label": "Westwood", "value": "C138043"},
                {"label": "Wharton", "value": "C138044"},
                {"label": "White Horse", "value": "C138045"},
                {"label": "White Meadow Lake", "value": "C138046"},
                {"label": "Whitehouse Station", "value": "C138047"},
                {"label": "Whitesboro", "value": "C138048"},
                {"label": "Whitesboro-Burleigh", "value": "C138049"},
                {"label": "Whittingham", "value": "C138050"},
                {"label": "Wildwood", "value": "C138051"},
                {"label": "Wildwood Crest", "value": "C138052"},
                {"label": "Williamstown", "value": "C138053"},
                {"label": "Willingboro", "value": "C138054"},
                {"label": "Winfield", "value": "C138055"},
                {"label": "Wood-Lynne", "value": "C138056"},
                {"label": "Wood-Ridge", "value": "C138057"},
                {"label": "Woodbine", "value": "C138058"},
                {"label": "Woodbridge", "value": "C138059"},
                {"label": "Woodbury", "value": "C138060"},
                {"label": "Woodbury Heights", "value": "C138061"},
                {"label": "Woodcliff Lake", "value": "C138062"},
                {"label": "Woodland Park", "value": "C138063"},
                {"label": "Woodstown", "value": "C138064"},
                {"label": "Wyckoff", "value": "C138065"},
                {"label": "Yardville", "value": "C138066"},
                {"label": "Yorketown", "value": "C138067"}],
 "New Mexico": [{"label": "Agua Fria", "value": "C138068"},
                {"label": "Alamo", "value": "C138069"},
                {"label": "Alamogordo", "value": "C138070"},
                {"label": "Albuquerque", "value": "C138071"},
                {"label": "Angel Fire", "value": "C138072"},
                {"label": "Anthony", "value": "C138073"},
                {"label": "Arenas Valley", "value": "C138074"},
                {"label": "Arroyo Seco", "value": "C138075"},
                {"label": "Artesia", "value": "C138076"},
                {"label": "Atoka", "value": "C138077"},
                {"label": "Aztec", "value": "C138078"},
                {"label": "Bayard", "value": "C138079"},
                {"label": "Belen", "value": "C138080"},
                {"label": "Berino", "value": "C138081"},
                {"label": "Bernalillo", "value": "C138082"},
                {"label": "Bernalillo County", "value": "C138083"},
                {"label": "Black Rock", "value": "C138084"},
                {"label": "Bloomfield", "value": "C138085"},
                {"label": "Boles Acres", "value": "C138086"},
                {"label": "Bosque Farms", "value": "C138087"},
                {"label": "Cannon Air Force Base", "value": "C138088"},
                {"label": "Capitan", "value": "C138089"},
                {"label": "Carlsbad", "value": "C138090"},
                {"label": "Carnuel", "value": "C138091"},
                {"label": "Carrizozo", "value": "C138092"},
                {"label": "Catron County", "value": "C138093"},
                {"label": "Chama", "value": "C138094"},
                {"label": "Chaparral", "value": "C138095"},
                {"label": "Chaves County", "value": "C138096"},
                {"label": "Chimayo", "value": "C138097"},
                {"label": "Church Rock", "value": "C138098"},
                {"label": "Cibola County", "value": "C138099"},
                {"label": "Clayton", "value": "C138100"},
                {"label": "Clovis", "value": "C138101"},
                {"label": "Colfax County", "value": "C138102"},
                {"label": "Columbus", "value": "C138103"},
                {"label": "Corrales", "value": "C138104"},
                {"label": "Crownpoint", "value": "C138105"},
                {"label": "Curry County", "value": "C138106"},
                {"label": "De Baca County", "value": "C138107"},
                {"label": "Deming", "value": "C138108"},
                {"label": "Dexter", "value": "C138109"},
                {"label": "Dona Ana", "value": "C138110"},
                {"label": "Dona Ana County", "value": "C138111"},
                {"label": "Dulce", "value": "C138112"},
                {"label": "Eddy County", "value": "C138113"},
                {"label": "Edgewood", "value": "C138114"},
                {"label": "El Cerro", "value": "C138115"},
                {"label": "El Cerro Mission", "value": "C138116"},
                {"label": "El Rancho", "value": "C138117"},
                {"label": "El Valle de Arroyo Seco", "value": "C138118"},
                {"label": "Eldorado at Santa Fe", "value": "C138119"},
                {"label": "Elephant Butte", "value": "C138120"},
                {"label": "Enchanted Hills", "value": "C138121"},
                {"label": "Espanola", "value": "C138122"},
                {"label": "Estancia", "value": "C138123"},
                {"label": "Eunice", "value": "C138124"},
                {"label": "Farmington", "value": "C138125"},
                {"label": "Flora Vista", "value": "C138126"},
                {"label": "Fort Sumner", "value": "C138127"},
                {"label": "Gallup", "value": "C138128"},
                {"label": "Grant County", "value": "C138129"},
                {"label": "Grants", "value": "C138130"},
                {"label": "Guadalupe County", "value": "C138131"},
                {"label": "Hagerman", "value": "C138132"},
                {"label": "Harding County", "value": "C138133"},
                {"label": "Hatch", "value": "C138134"},
                {"label": "Hidalgo County", "value": "C138135"},
                {"label": "Hobbs", "value": "C138136"},
                {"label": "Holloman Air Force Base", "value": "C138137"},
                {"label": "Hurley", "value": "C138138"},
                {"label": "Jal", "value": "C138139"},
                {"label": "Jarales", "value": "C138140"},
                {"label": "Jemez Pueblo", "value": "C138141"},
                {"label": "Keeler Farm", "value": "C138142"},
                {"label": "Kirtland", "value": "C138143"},
                {"label": "La Cienega", "value": "C138144"},
                {"label": "La Huerta", "value": "C138145"},
                {"label": "La Luz", "value": "C138146"},
                {"label": "La Mesilla", "value": "C138147"},
                {"label": "La Puebla", "value": "C138148"},
                {"label": "La Union", "value": "C138149"},
                {"label": "Laguna", "value": "C138150"},
                {"label": "Las Cruces", "value": "C138151"},
                {"label": "Las Maravillas", "value": "C138152"},
                {"label": "Las Vegas", "value": "C138153"},
                {"label": "Lea County", "value": "C138154"},
                {"label": "Lee Acres", "value": "C138155"},
                {"label": "Lincoln County", "value": "C138156"},
                {"label": "Lordsburg", "value": "C138157"},
                {"label": "Los Alamos", "value": "C138158"},
                {"label": "Los Alamos County", "value": "C138159"},
                {"label": "Los Chavez", "value": "C138160"},
                {"label": "Los Lunas", "value": "C138161"},
                {"label": "Los Ranchos de Albuquerque", "value": "C138162"},
                {"label": "Loving", "value": "C138163"},
                {"label": "Lovington", "value": "C138164"},
                {"label": "Luna County", "value": "C138165"},
                {"label": "McIntosh", "value": "C138166"},
                {"label": "McKinley County", "value": "C138167"},
                {"label": "Meadow Lake", "value": "C138168"},
                {"label": "Mescalero", "value": "C138169"},
                {"label": "Mesilla", "value": "C138170"},
                {"label": "Mesquite", "value": "C138171"},
                {"label": "Milan", "value": "C138172"},
                {"label": "Monterey Park", "value": "C138173"},
                {"label": "Mora", "value": "C138174"},
                {"label": "Mora County", "value": "C138175"},
                {"label": "Moriarty", "value": "C138176"},
                {"label": "Mosquero", "value": "C138177"},
                {"label": "Nambe", "value": "C138178"},
                {"label": "Navajo", "value": "C138179"},
                {"label": "North Valley", "value": "C138180"},
                {"label": "Ohkay Owingeh", "value": "C138181"},
                {"label": "Otero County", "value": "C138182"},
                {"label": "Paradise Hills", "value": "C138183"},
                {"label": "Pecos", "value": "C138184"},
                {"label": "Peralta", "value": "C138185"},
                {"label": "Placitas", "value": "C138186"},
                {"label": "Pojoaque", "value": "C138187"},
                {"label": "Ponderosa Pine", "value": "C138188"},
                {"label": "Portales", "value": "C138189"},
                {"label": "Quay County", "value": "C138190"},
                {"label": "Questa", "value": "C138191"},
                {"label": "Radium Springs", "value": "C138192"},
                {"label": "Ranchos de Taos", "value": "C138193"},
                {"label": "Raton", "value": "C138194"},
                {"label": "Reserve", "value": "C138195"},
                {"label": "Rio Arriba County", "value": "C138196"},
                {"label": "Rio Communities", "value": "C138197"},
                {"label": "Rio Rancho", "value": "C138198"},
                {"label": "Roosevelt County", "value": "C138199"},
                {"label": "Roswell", "value": "C138200"},
                {"label": "Ruidoso", "value": "C138201"},
                {"label": "Ruidoso Downs", "value": "C138202"},
                {"label": "San Felipe Pueblo", "value": "C138203"},
                {"label": "San Juan County", "value": "C138204"},
                {"label": "San Miguel", "value": "C138205"},
                {"label": "San Miguel County", "value": "C138206"},
                {"label": "San Ysidro", "value": "C138207"},
                {"label": "Sandia Heights", "value": "C138208"},
                {"label": "Sandia Knolls", "value": "C138209"},
                {"label": "Sandoval County", "value": "C138210"},
                {"label": "Santa Clara", "value": "C138211"},
                {"label": "Santa Clara Pueblo", "value": "C138212"},
                {"label": "Santa Fe", "value": "C138213"},
                {"label": "Santa Fe County", "value": "C138214"},
                {"label": "Santa Rosa", "value": "C138215"},
                {"label": "Santa Teresa", "value": "C138216"},
                {"label": "Santo Domingo Pueblo", "value": "C138217"},
                {"label": "Shiprock", "value": "C138218"},
                {"label": "Sierra County", "value": "C138219"},
                {"label": "Silver City", "value": "C138220"},
                {"label": "Skyline-Ganipa", "value": "C138221"},
                {"label": "Socorro", "value": "C138222"},
                {"label": "Socorro County", "value": "C138223"},
                {"label": "South Valley", "value": "C138224"},
                {"label": "Spencerville", "value": "C138225"},
                {"label": "Sunland Park", "value": "C138226"},
                {"label": "Taos", "value": "C138227"},
                {"label": "Taos County", "value": "C138228"},
                {"label": "Taos Pueblo", "value": "C138229"},
                {"label": "Texico", "value": "C138230"},
                {"label": "Thoreau", "value": "C138231"},
                {"label": "Tierra Amarilla", "value": "C138232"},
                {"label": "Tome", "value": "C138233"},
                {"label": "Torrance County", "value": "C138234"},
                {"label": "Truth or Consequences", "value": "C138235"},
                {"label": "Tucumcari", "value": "C138236"},
                {"label": "Tularosa", "value": "C138237"},
                {"label": "Twin Lakes", "value": "C138238"},
                {"label": "Union County", "value": "C138239"},
                {"label": "University Park", "value": "C138240"},
                {"label": "Upper Fruitland", "value": "C138241"},
                {"label": "Vado", "value": "C138242"},
                {"label": "Valencia", "value": "C138243"},
                {"label": "Valencia County", "value": "C138244"},
                {"label": "Waterflow", "value": "C138245"},
                {"label": "West Hammond", "value": "C138246"},
                {"label": "White Rock", "value": "C138247"},
                {"label": "White Sands", "value": "C138248"},
                {"label": "Zuni Pueblo", "value": "C138249"}],
 "New Providence": [{"label": "Nassau", "value": "C119616"}],
 "New South Wales": [{"label": "Abbotsbury", "value": "C1950"},
                     {"label": "Abbotsford", "value": "C1951"},
                     {"label": "Abercrombie", "value": "C1952"},
                     {"label": "Aberdare", "value": "C1953"},
                     {"label": "Aberdeen", "value": "C1954"},
                     {"label": "Aberglasslyn", "value": "C1955"},
                     {"label": "Abermain", "value": "C1956"},
                     {"label": "Acacia Gardens", "value": "C1957"},
                     {"label": "Adamstown", "value": "C1958"},
                     {"label": "Adamstown Heights", "value": "C1959"},
                     {"label": "Airds", "value": "C1960"},
                     {"label": "Albion Park", "value": "C1961"},
                     {"label": "Albion Park Rail", "value": "C1962"},
                     {"label": "Albury", "value": "C1963"},
                     {"label": "Albury Municipality", "value": "C1964"},
                     {"label": "Aldavilla", "value": "C1965"},
                     {"label": "Alexandria", "value": "C1966"},
                     {"label": "Alfords Point", "value": "C1967"},
                     {"label": "Allambie Heights", "value": "C1968"},
                     {"label": "Allawah", "value": "C1969"},
                     {"label": "Alstonville", "value": "C1970"},
                     {"label": "Ambarvale", "value": "C1971"},
                     {"label": "Anna Bay", "value": "C1972"},
                     {"label": "Annandale", "value": "C1973"},
                     {"label": "Annangrove", "value": "C1974"},
                     {"label": "Appin", "value": "C1975"},
                     {"label": "Arcadia", "value": "C1976"},
                     {"label": "Arcadia vale", "value": "C1977"},
                     {"label": "Argenton", "value": "C1978"},
                     {"label": "Armidale", "value": "C1979"},
                     {"label": "Arncliffe", "value": "C1980"},
                     {"label": "Artarmon", "value": "C1981"},
                     {"label": "Ashbury", "value": "C1982"},
                     {"label": "Ashcroft", "value": "C1983"},
                     {"label": "Ashfield", "value": "C1984"},
                     {"label": "Ashmont", "value": "C1985"},
                     {"label": "Ashtonfield", "value": "C1986"},
                     {"label": "Asquith", "value": "C1987"},
                     {"label": "Auburn", "value": "C1988"},
                     {"label": "Austinmer", "value": "C1989"},
                     {"label": "Austral", "value": "C1990"},
                     {"label": "Avoca Beach", "value": "C1991"},
                     {"label": "Avondale", "value": "C1992"},
                     {"label": "Balgowlah", "value": "C1993"},
                     {"label": "Balgowlah Heights", "value": "C1994"},
                     {"label": "Balgownie", "value": "C1995"},
                     {"label": "Ballina", "value": "C1996"},
                     {"label": "Balmain", "value": "C1997"},
                     {"label": "Balmain East", "value": "C1998"},
                     {"label": "Balranald", "value": "C1999"},
                     {"label": "Bangalow", "value": "C2000"},
                     {"label": "Bangor", "value": "C2001"},
                     {"label": "Banksia", "value": "C2002"},
                     {"label": "Banksmeadow", "value": "C2003"},
                     {"label": "Bankstown", "value": "C2004"},
                     {"label": "Banora Point", "value": "C2005"},
                     {"label": "Bar Beach", "value": "C2006"},
                     {"label": "Barden Ridge", "value": "C2007"},
                     {"label": "Bardia", "value": "C2008"},
                     {"label": "Bardwell Park", "value": "C2009"},
                     {"label": "Bardwell Valley", "value": "C2010"},
                     {"label": "Bargo", "value": "C2011"},
                     {"label": "Barham", "value": "C2012"},
                     {"label": "Barnsley", "value": "C2013"},
                     {"label": "Barooga", "value": "C2014"},
                     {"label": "Barraba", "value": "C2015"},
                     {"label": "Barrack Heights", "value": "C2016"},
                     {"label": "Basin View", "value": "C2017"},
                     {"label": "Bass Hill", "value": "C2018"},
                     {"label": "Bateau Bay", "value": "C2019"},
                     {"label": "Batehaven", "value": "C2020"},
                     {"label": "Batemans Bay", "value": "C2021"},
                     {"label": "Bathurst", "value": "C2022"},
                     {"label": "Bathurst city centre", "value": "C2023"},
                     {"label": "Bathurst Regional", "value": "C2024"},
                     {"label": "Batlow", "value": "C2025"},
                     {"label": "Baulkham Hills", "value": "C2026"},
                     {"label": "Bay View", "value": "C2027"},
                     {"label": "Beacon Hill", "value": "C2028"},
                     {"label": "Beaumont Hills", "value": "C2029"},
                     {"label": "Beecroft", "value": "C2030"},
                     {"label": "Bega", "value": "C2031"},
                     {"label": "Bega Valley", "value": "C2032"},
                     {"label": "Belfield", "value": "C2033"},
                     {"label": "Bella Vista", "value": "C2034"},
                     {"label": "Bellambi", "value": "C2035"},
                     {"label": "Bellbird", "value": "C2036"},
                     {"label": "Bellevue Hill", "value": "C2037"},
                     {"label": "Bellingen", "value": "C2038"},
                     {"label": "Belmont", "value": "C2039"},
                     {"label": "Belmont North", "value": "C2040"},
                     {"label": "Belmont South", "value": "C2041"},
                     {"label": "Belmore", "value": "C2042"},
                     {"label": "Belrose", "value": "C2043"},
                     {"label": "Bensville", "value": "C2044"},
                     {"label": "Berala", "value": "C2045"},
                     {"label": "Beresfield", "value": "C2046"},
                     {"label": "Berkeley", "value": "C2047"},
                     {"label": "Berkeley Vale", "value": "C2048"},
                     {"label": "Berkshire Park", "value": "C2049"},
                     {"label": "Bermagui", "value": "C2050"},
                     {"label": "Berowra", "value": "C2051"},
                     {"label": "Berowra Heights", "value": "C2052"},
                     {"label": "Berridale", "value": "C2053"},
                     {"label": "Berrigan", "value": "C2054"},
                     {"label": "Berry", "value": "C2055"},
                     {"label": "Beverley Park", "value": "C2056"},
                     {"label": "Beverly Hills", "value": "C2057"},
                     {"label": "Bexley", "value": "C2058"},
                     {"label": "Bexley North", "value": "C2059"},
                     {"label": "Bidwill", "value": "C2060"},
                     {"label": "Bilambil Heights", "value": "C2061"},
                     {"label": "Bilgola Plateau", "value": "C2062"},
                     {"label": "Bingara", "value": "C2063"},
                     {"label": "Birchgrove", "value": "C2064"},
                     {"label": "Birmingham Gardens", "value": "C2065"},
                     {"label": "Birrong", "value": "C2066"},
                     {"label": "Blackalls Park", "value": "C2067"},
                     {"label": "Blackbutt", "value": "C2068"},
                     {"label": "Blackett", "value": "C2069"},
                     {"label": "Blackheath", "value": "C2070"},
                     {"label": "Blacksmiths", "value": "C2071"},
                     {"label": "Blacktown", "value": "C2072"},
                     {"label": "Blackwall", "value": "C2073"},
                     {"label": "Blair Athol", "value": "C2074"},
                     {"label": "Blakehurst", "value": "C2075"},
                     {"label": "Bland", "value": "C2076"},
                     {"label": "Blaxland", "value": "C2077"},
                     {"label": "Blayney", "value": "C2078"},
                     {"label": "Bligh Park", "value": "C2079"},
                     {"label": "Blue Bay", "value": "C2080"},
                     {"label": "Blue Haven", "value": "C2081"},
                     {"label": "Blue Mountains Municipality", "value": "C2082"},
                     {"label": "Boambee", "value": "C2083"},
                     {"label": "Boambee East", "value": "C2084"},
                     {"label": "Bogan", "value": "C2085"},
                     {"label": "Bogangar", "value": "C2086"},
                     {"label": "Boggabri", "value": "C2087"},
                     {"label": "Bolton Point", "value": "C2088"},
                     {"label": "Bolwarra Heights", "value": "C2089"},
                     {"label": "Bomaderry", "value": "C2090"},
                     {"label": "Bombala", "value": "C2091"},
                     {"label": "Bondi", "value": "C2092"},
                     {"label": "Bondi Beach", "value": "C2093"},
                     {"label": "Bondi Junction", "value": "C2094"},
                     {"label": "Bonnells Bay", "value": "C2095"},
                     {"label": "Bonnet Bay", "value": "C2096"},
                     {"label": "Bonny Hills", "value": "C2097"},
                     {"label": "Bonnyrigg", "value": "C2098"},
                     {"label": "Bonnyrigg Heights", "value": "C2099"},
                     {"label": "Bonville", "value": "C2100"},
                     {"label": "Booker Bay", "value": "C2101"},
                     {"label": "Booragul", "value": "C2102"},
                     {"label": "Boorowa", "value": "C2103"},
                     {"label": "Bossley Park", "value": "C2104"},
                     {"label": "Botany", "value": "C2105"},
                     {"label": "Botany Bay", "value": "C2106"},
                     {"label": "Bourke", "value": "C2107"},
                     {"label": "Bourkelands", "value": "C2108"},
                     {"label": "Bow Bowing", "value": "C2109"},
                     {"label": "Bowen Mountain", "value": "C2110"},
                     {"label": "Bowenfels", "value": "C2111"},
                     {"label": "Bowral", "value": "C2112"},
                     {"label": "Bowraville", "value": "C2113"},
                     {"label": "Bradbury", "value": "C2114"},
                     {"label": "Braidwood", "value": "C2115"},
                     {"label": "Branxton", "value": "C2116"},
                     {"label": "Breakfast Point", "value": "C2117"},
                     {"label": "Brewarrina", "value": "C2118"},
                     {"label": "Brighton-Le-Sands", "value": "C2119"},
                     {"label": "Bringelly", "value": "C2120"},
                     {"label": "Broadmeadow", "value": "C2121"},
                     {"label": "Broken Hill", "value": "C2122"},
                     {"label": "Broken Hill Municipality", "value": "C2123"},
                     {"label": "Bronte", "value": "C2124"},
                     {"label": "Brookvale", "value": "C2125"},
                     {"label": "Broulee", "value": "C2126"},
                     {"label": "Brunswick Heads", "value": "C2127"},
                     {"label": "Budgewoi", "value": "C2128"},
                     {"label": "Buff Point", "value": "C2129"},
                     {"label": "Bulahdelah", "value": "C2130"},
                     {"label": "Bullaburra", "value": "C2131"},
                     {"label": "Bulli", "value": "C2132"},
                     {"label": "Bundanoon", "value": "C2133"},
                     {"label": "Bundeena", "value": "C2134"},
                     {"label": "Bungarribee", "value": "C2135"},
                     {"label": "Bungendore", "value": "C2136"},
                     {"label": "Buronga", "value": "C2137"},
                     {"label": "Burradoo", "value": "C2138"},
                     {"label": "Burraneer", "value": "C2139"},
                     {"label": "Burrill Lake", "value": "C2140"},
                     {"label": "Burwood", "value": "C2141"},
                     {"label": "Busby", "value": "C2142"},
                     {"label": "Buttaba", "value": "C2143"},
                     {"label": "Buxton", "value": "C2144"},
                     {"label": "Byron Bay", "value": "C2145"},
                     {"label": "Byron Shire", "value": "C2146"},
                     {"label": "Bywong", "value": "C2147"},
                     {"label": "Cabarita", "value": "C2148"},
                     {"label": "Cabonne", "value": "C2149"},
                     {"label": "Cabramatta", "value": "C2150"},
                     {"label": "Cabramatta West", "value": "C2151"},
                     {"label": "Caddens", "value": "C2152"},
                     {"label": "Calala", "value": "C2153"},
                     {"label": "Callaghan", "value": "C2154"},
                     {"label": "Callala Bay", "value": "C2155"},
                     {"label": "Cambewarra Village", "value": "C2156"},
                     {"label": "Cambridge Gardens", "value": "C2157"},
                     {"label": "Cambridge Park", "value": "C2158"},
                     {"label": "Camden", "value": "C2159"},
                     {"label": "Camden Haven", "value": "C2160"},
                     {"label": "Camden South", "value": "C2161"},
                     {"label": "Cameron Park", "value": "C2162"},
                     {"label": "Cammeray", "value": "C2163"},
                     {"label": "Campbelltown", "value": "C2164"},
                     {"label": "Campbelltown Municipality", "value": "C2165"},
                     {"label": "Camperdown", "value": "C2166"},
                     {"label": "Campsie", "value": "C2167"},
                     {"label": "Canada Bay", "value": "C2168"},
                     {"label": "Canley Heights", "value": "C2169"},
                     {"label": "Canley Vale", "value": "C2170"},
                     {"label": "Canowindra", "value": "C2171"},
                     {"label": "Canterbury", "value": "C2172"},
                     {"label": "Canton Beach", "value": "C2173"},
                     {"label": "Cardiff", "value": "C2174"},
                     {"label": "Cardiff Heights", "value": "C2175"},
                     {"label": "Cardiff South", "value": "C2176"},
                     {"label": "Caringbah", "value": "C2177"},
                     {"label": "Caringbah South", "value": "C2178"},
                     {"label": "Carlingford", "value": "C2179"},
                     {"label": "Carlton", "value": "C2180"},
                     {"label": "Carnes Hill", "value": "C2181"},
                     {"label": "Carramar", "value": "C2182"},
                     {"label": "Carrathool", "value": "C2183"},
                     {"label": "Carrington", "value": "C2184"},
                     {"label": "Carss Park", "value": "C2185"},
                     {"label": "Cartwright", "value": "C2186"},
                     {"label": "Carwoola", "value": "C2187"},
                     {"label": "Casino", "value": "C2188"},
                     {"label": "Castle Cove", "value": "C2189"},
                     {"label": "Castle Hill", "value": "C2190"},
                     {"label": "Castlecrag", "value": "C2191"},
                     {"label": "Castlereagh", "value": "C2192"},
                     {"label": "Casuarina", "value": "C2193"},
                     {"label": "Casula", "value": "C2194"},
                     {"label": "Catalina", "value": "C2195"},
                     {"label": "Catherine Field", "value": "C2196"},
                     {"label": "Caves Beach", "value": "C2197"},
                     {"label": "Cecil Hills", "value": "C2198"},
                     {"label": "Centennial Park", "value": "C2199"},
                     {"label": "Central Darling", "value": "C2200"},
                     {"label": "Cessnock", "value": "C2201"},
                     {"label": "Chain Valley Bay", "value": "C2202"},
                     {"label": "Charlestown", "value": "C2203"},
                     {"label": "Charmhaven", "value": "C2204"},
                     {"label": "Chatswood", "value": "C2205"},
                     {"label": "Chatswood West", "value": "C2206"},
                     {"label": "Cherrybrook", "value": "C2207"},
                     {"label": "Chester Hill", "value": "C2208"},
                     {"label": "Chifley", "value": "C2209"},
                     {"label": "Chinderah", "value": "C2210"},
                     {"label": "Chippendale", "value": "C2211"},
                     {"label": "Chipping Norton", "value": "C2212"},
                     {"label": "Chiswick", "value": "C2213"},
                     {"label": "Chittaway Bay", "value": "C2214"},
                     {"label": "City of Sydney", "value": "C2215"},
                     {"label": "Claremont Meadows", "value": "C2216"},
                     {"label": "Clarence Town", "value": "C2217"},
                     {"label": "Clarence Valley", "value": "C2218"},
                     {"label": "Claymore", "value": "C2219"},
                     {"label": "Clemton Park", "value": "C2220"},
                     {"label": "Clontarf", "value": "C2221"},
                     {"label": "Clovelly", "value": "C2222"},
                     {"label": "Coal Point", "value": "C2223"},
                     {"label": "Cobar", "value": "C2224"},
                     {"label": "Cobbitty", "value": "C2225"},
                     {"label": "Coffs Harbour", "value": "C2226"},
                     {"label": "Coleambally", "value": "C2227"},
                     {"label": "Colebee", "value": "C2228"},
                     {"label": "Coledale", "value": "C2229"},
                     {"label": "Collaroy", "value": "C2230"},
                     {"label": "Collaroy Plateau", "value": "C2231"},
                     {"label": "Colo Vale", "value": "C2232"},
                     {"label": "Colyton", "value": "C2233"},
                     {"label": "Como", "value": "C2234"},
                     {"label": "Concord", "value": "C2235"},
                     {"label": "Concord West", "value": "C2236"},
                     {"label": "Condell Park", "value": "C2237"},
                     {"label": "Condobolin", "value": "C2238"},
                     {"label": "Coniston", "value": "C2239"},
                     {"label": "Connells Point", "value": "C2240"},
                     {"label": "Constitution Hill", "value": "C2241"},
                     {"label": "Coogee", "value": "C2242"},
                     {"label": "Cooks Hill", "value": "C2243"},
                     {"label": "Coolah", "value": "C2244"},
                     {"label": "Coolamon", "value": "C2245"},
                     {"label": "Cooma", "value": "C2246"},
                     {"label": "Coonabarabran", "value": "C2247"},
                     {"label": "Coonamble", "value": "C2248"},
                     {"label": "Cooranbong", "value": "C2249"},
                     {"label": "Cootamundra", "value": "C2250"},
                     {"label": "Copacabana", "value": "C2251"},
                     {"label": "Coraki", "value": "C2252"},
                     {"label": "Cordeaux Heights", "value": "C2253"},
                     {"label": "Corindi Beach", "value": "C2254"},
                     {"label": "Corlette", "value": "C2255"},
                     {"label": "Corowa", "value": "C2256"},
                     {"label": "Corrimal", "value": "C2257"},
                     {"label": "Coutts Crossing", "value": "C2258"},
                     {"label": "Cowra", "value": "C2259"},
                     {"label": "Cranebrook", "value": "C2260"},
                     {"label": "Cremorne", "value": "C2261"},
                     {"label": "Cremorne Point", "value": "C2262"},
                     {"label": "Crescent Head", "value": "C2263"},
                     {"label": "Crestwood", "value": "C2264"},
                     {"label": "Cringila", "value": "C2265"},
                     {"label": "Cromer", "value": "C2266"},
                     {"label": "Cronulla", "value": "C2267"},
                     {"label": "Crookwell", "value": "C2268"},
                     {"label": "Crows Nest", "value": "C2269"},
                     {"label": "Croydon", "value": "C2270"},
                     {"label": "Culburra Beach", "value": "C2271"},
                     {"label": "Culcairn", "value": "C2272"},
                     {"label": "Cumbalum", "value": "C2273"},
                     {"label": "Cundletown", "value": "C2274"},
                     {"label": "Curl Curl", "value": "C2275"},
                     {"label": "Currans Hill", "value": "C2276"},
                     {"label": "Daceyville", "value": "C2277"},
                     {"label": "Dalmeny", "value": "C2278"},
                     {"label": "Dapto", "value": "C2279"},
                     {"label": "Darling Point", "value": "C2280"},
                     {"label": "Darlinghurst", "value": "C2281"},
                     {"label": "Darlington", "value": "C2282"},
                     {"label": "Darlington Point", "value": "C2283"},
                     {"label": "Davidson", "value": "C2284"},
                     {"label": "Davistown", "value": "C2285"},
                     {"label": "Dean Park", "value": "C2286"},
                     {"label": "Dee Why", "value": "C2287"},
                     {"label": "Denham Court", "value": "C2288"},
                     {"label": "Deniliquin", "value": "C2289"},
                     {"label": "Denistone", "value": "C2290"},
                     {"label": "Denistone East", "value": "C2291"},
                     {"label": "Denman", "value": "C2292"},
                     {"label": "Dharruk", "value": "C2293"},
                     {"label": "Dolls Point", "value": "C2294"},
                     {"label": "Doonside", "value": "C2295"},
                     {"label": "Dora Creek", "value": "C2296"},
                     {"label": "Dorrigo", "value": "C2297"},
                     {"label": "Double Bay", "value": "C2298"},
                     {"label": "Douglas Park", "value": "C2299"},
                     {"label": "Dover Heights", "value": "C2300"},
                     {"label": "Drummoyne", "value": "C2301"},
                     {"label": "Dubbo", "value": "C2302"},
                     {"label": "Dudley", "value": "C2303"},
                     {"label": "Dulwich Hill", "value": "C2304"},
                     {"label": "Dundas Valley", "value": "C2305"},
                     {"label": "Dunedoo", "value": "C2306"},
                     {"label": "Dungog", "value": "C2307"},
                     {"label": "Dural", "value": "C2308"},
                     {"label": "Eagle Vale", "value": "C2309"},
                     {"label": "Earlwood", "value": "C2310"},
                     {"label": "East Albury", "value": "C2311"},
                     {"label": "East Ballina", "value": "C2312"},
                     {"label": "East Branxton", "value": "C2313"},
                     {"label": "East Corrimal", "value": "C2314"},
                     {"label": "East Gosford", "value": "C2315"},
                     {"label": "East Hills", "value": "C2316"},
                     {"label": "East Jindabyne", "value": "C2317"},
                     {"label": "East Kempsey", "value": "C2318"},
                     {"label": "East Killara", "value": "C2319"},
                     {"label": "East Kurrajong", "value": "C2320"},
                     {"label": "East Lindfield", "value": "C2321"},
                     {"label": "East Lismore", "value": "C2322"},
                     {"label": "East Maitland", "value": "C2323"},
                     {"label": "East Ryde", "value": "C2324"},
                     {"label": "East Tamworth", "value": "C2325"},
                     {"label": "Eastlakes", "value": "C2326"},
                     {"label": "Eastwood", "value": "C2327"},
                     {"label": "Eden", "value": "C2328"},
                     {"label": "Edensor Park", "value": "C2329"},
                     {"label": "Edgecliff", "value": "C2330"},
                     {"label": "Edgeworth", "value": "C2331"},
                     {"label": "Edmondson Park", "value": "C2332"},
                     {"label": "Eglinton", "value": "C2333"},
                     {"label": "Elanora Heights", "value": "C2334"},
                     {"label": "Elderslie", "value": "C2335"},
                     {"label": "Eleebana", "value": "C2336"},
                     {"label": "Elermore Vale", "value": "C2337"},
                     {"label": "Elizabeth Bay", "value": "C2338"},
                     {"label": "Elizabeth Hills", "value": "C2339"},
                     {"label": "Ellalong", "value": "C2340"},
                     {"label": "Emerald Beach", "value": "C2341"},
                     {"label": "Emerton", "value": "C2342"},
                     {"label": "Empire Bay", "value": "C2343"},
                     {"label": "Emu Heights", "value": "C2344"},
                     {"label": "Emu Plains", "value": "C2345"},
                     {"label": "Engadine", "value": "C2346"},
                     {"label": "Enmore", "value": "C2347"},
                     {"label": "Epping", "value": "C2348"},
                     {"label": "Erina", "value": "C2349"},
                     {"label": "Ermington", "value": "C2350"},
                     {"label": "Erskine Park", "value": "C2351"},
                     {"label": "Erskineville", "value": "C2352"},
                     {"label": "Eschol Park", "value": "C2353"},
                     {"label": "Estella", "value": "C2354"},
                     {"label": "Ettalong", "value": "C2355"},
                     {"label": "Ettalong Beach", "value": "C2356"},
                     {"label": "Eulomogo", "value": "C2357"},
                     {"label": "Eurobodalla", "value": "C2358"},
                     {"label": "Evans Head", "value": "C2359"},
                     {"label": "Fairfield", "value": "C2360"},
                     {"label": "Fairfield East", "value": "C2361"},
                     {"label": "Fairfield Heights", "value": "C2362"},
                     {"label": "Fairfield West", "value": "C2363"},
                     {"label": "Fairlight", "value": "C2364"},
                     {"label": "Fairy Meadow", "value": "C2365"},
                     {"label": "Farmborough Heights", "value": "C2366"},
                     {"label": "Faulconbridge", "value": "C2367"},
                     {"label": "Fennell Bay", "value": "C2368"},
                     {"label": "Fern Bay", "value": "C2369"},
                     {"label": "Fern Hill", "value": "C2370"},
                     {"label": "Figtree", "value": "C2371"},
                     {"label": "Fingal Bay", "value": "C2372"},
                     {"label": "Finley", "value": "C2373"},
                     {"label": "Five Dock", "value": "C2374"},
                     {"label": "Fletcher", "value": "C2375"},
                     {"label": "Flinders", "value": "C2376"},
                     {"label": "Floraville", "value": "C2377"},
                     {"label": "Forbes", "value": "C2378"},
                     {"label": "Forest Hill", "value": "C2379"},
                     {"label": "Forest Lodge", "value": "C2380"},
                     {"label": "Forestville", "value": "C2381"},
                     {"label": "Forresters Beach", "value": "C2382"},
                     {"label": "Forster", "value": "C2383"},
                     {"label": "Frederickton", "value": "C2384"},
                     {"label": "Freemans Reach", "value": "C2385"},
                     {"label": "Frenchs Forest", "value": "C2386"},
                     {"label": "Freshwater", "value": "C2387"},
                     {"label": "Galston", "value": "C2388"},
                     {"label": "Garden Suburb", "value": "C2389"},
                     {"label": "Gateshead", "value": "C2390"},
                     {"label": "Georges Hall", "value": "C2391"},
                     {"label": "Georgetown", "value": "C2392"},
                     {"label": "Gerringong", "value": "C2393"},
                     {"label": "Gilgandra", "value": "C2394"},
                     {"label": "Gillieston Heights", "value": "C2395"},
                     {"label": "Girards Hill", "value": "C2396"},
                     {"label": "Girraween", "value": "C2397"},
                     {"label": "Gladesville", "value": "C2398"},
                     {"label": "Glebe", "value": "C2399"},
                     {"label": "Glen Alpine", "value": "C2400"},
                     {"label": "Glen Innes", "value": "C2401"},
                     {"label": "Glen Innes Severn", "value": "C2402"},
                     {"label": "Glenbrook", "value": "C2403"},
                     {"label": "Glendale", "value": "C2404"},
                     {"label": "Glendenning", "value": "C2405"},
                     {"label": "Glenfield", "value": "C2406"},
                     {"label": "Glenfield Park", "value": "C2407"},
                     {"label": "Glenhaven", "value": "C2408"},
                     {"label": "Glenmore Park", "value": "C2409"},
                     {"label": "Glenning Valley", "value": "C2410"},
                     {"label": "Glenorie", "value": "C2411"},
                     {"label": "Glenroy", "value": "C2412"},
                     {"label": "Glenwood", "value": "C2413"},
                     {"label": "Glossodia", "value": "C2414"},
                     {"label": "Gloucester", "value": "C2415"},
                     {"label": "Gol Gol", "value": "C2416"},
                     {"label": "Googong", "value": "C2417"},
                     {"label": "Goonellabah", "value": "C2418"},
                     {"label": "Gordon", "value": "C2419"},
                     {"label": "Gorokan", "value": "C2420"},
                     {"label": "Gosford", "value": "C2421"},
                     {"label": "Goulburn", "value": "C2422"},
                     {"label": "Goulburn Mulwaree", "value": "C2423"},
                     {"label": "Grafton", "value": "C2424"},
                     {"label": "Granville", "value": "C2425"},
                     {"label": "Grasmere", "value": "C2426"},
                     {"label": "Grays Point", "value": "C2427"},
                     {"label": "Greater Hume Shire", "value": "C2428"},
                     {"label": "Green Valley", "value": "C2429"},
                     {"label": "Greenacre", "value": "C2430"},
                     {"label": "Greenfield Park", "value": "C2431"},
                     {"label": "Greenwell Point", "value": "C2432"},
                     {"label": "Greenwich", "value": "C2433"},
                     {"label": "Gregory Hills", "value": "C2434"},
                     {"label": "Grenfell", "value": "C2435"},
                     {"label": "Greta", "value": "C2436"},
                     {"label": "Greystanes", "value": "C2437"},
                     {"label": "Griffith", "value": "C2438"},
                     {"label": "Grose Vale", "value": "C2439"},
                     {"label": "Guildford West", "value": "C2440"},
                     {"label": "Gulgong", "value": "C2441"},
                     {"label": "Gulmarrad", "value": "C2442"},
                     {"label": "Gundagai", "value": "C2443"},
                     {"label": "Gundaroo", "value": "C2444"},
                     {"label": "Gunnedah", "value": "C2445"},
                     {"label": "Guyra", "value": "C2446"},
                     {"label": "Gwandalan", "value": "C2447"},
                     {"label": "Gwydir", "value": "C2448"},
                     {"label": "Gwynneville", "value": "C2449"},
                     {"label": "Gymea", "value": "C2450"},
                     {"label": "Gymea Bay", "value": "C2451"},
                     {"label": "Haberfield", "value": "C2452"},
                     {"label": "Halekulani", "value": "C2453"},
                     {"label": "Hamilton", "value": "C2454"},
                     {"label": "Hamlyn Terrace", "value": "C2455"},
                     {"label": "Hammondville", "value": "C2456"},
                     {"label": "Hanwood", "value": "C2457"},
                     {"label": "Harrington", "value": "C2458"},
                     {"label": "Harrington Park", "value": "C2459"},
                     {"label": "Harris Park", "value": "C2460"},
                     {"label": "Hassall Grove", "value": "C2461"},
                     {"label": "Hawkesbury", "value": "C2462"},
                     {"label": "Hawks Nest", "value": "C2463"},
                     {"label": "Hay", "value": "C2464"},
                     {"label": "Haymarket", "value": "C2465"},
                     {"label": "Hazelbrook", "value": "C2466"},
                     {"label": "Heathcote", "value": "C2467"},
                     {"label": "Hebersham", "value": "C2468"},
                     {"label": "Heckenberg", "value": "C2469"},
                     {"label": "Heddon Greta", "value": "C2470"},
                     {"label": "Helensburgh", "value": "C2471"},
                     {"label": "Henty", "value": "C2472"},
                     {"label": "Hill Top", "value": "C2473"},
                     {"label": "Hillsdale", "value": "C2474"},
                     {"label": "Hillston", "value": "C2475"},
                     {"label": "Hillvue", "value": "C2476"},
                     {"label": "Hinchinbrook", "value": "C2477"},
                     {"label": "Hobartville", "value": "C2478"},
                     {"label": "Holbrook", "value": "C2479"},
                     {"label": "Holmesville", "value": "C2480"},
                     {"label": "Holroyd", "value": "C2481"},
                     {"label": "Holsworthy", "value": "C2482"},
                     {"label": "Homebush", "value": "C2483"},
                     {"label": "Homebush West", "value": "C2484"},
                     {"label": "Horningsea Park", "value": "C2485"},
                     {"label": "Hornsby", "value": "C2486"},
                     {"label": "Hornsby Heights", "value": "C2487"},
                     {"label": "Hornsby Shire", "value": "C2488"},
                     {"label": "Horsley", "value": "C2489"},
                     {"label": "Horsley Park", "value": "C2490"},
                     {"label": "Howlong", "value": "C2491"},
                     {"label": "Hoxton Park", "value": "C2492"},
                     {"label": "Hunters Hill", "value": "C2493"},
                     {"label": "Hunterview", "value": "C2494"},
                     {"label": "Huntingwood", "value": "C2495"},
                     {"label": "Hurlstone Park", "value": "C2496"},
                     {"label": "Hurstville", "value": "C2497"},
                     {"label": "Hurstville Grove", "value": "C2498"},
                     {"label": "Illawong", "value": "C2499"},
                     {"label": "Iluka", "value": "C2500"},
                     {"label": "Ingleburn", "value": "C2501"},
                     {"label": "Inverell", "value": "C2502"},
                     {"label": "Islington", "value": "C2503"},
                     {"label": "Jamberoo", "value": "C2504"},
                     {"label": "Jamisontown", "value": "C2505"},
                     {"label": "Jannali", "value": "C2506"},
                     {"label": "Jerilderie", "value": "C2507"},
                     {"label": "Jerrabomberra", "value": "C2508"},
                     {"label": "Jesmond", "value": "C2509"},
                     {"label": "Jewells", "value": "C2510"},
                     {"label": "Jilliby", "value": "C2511"},
                     {"label": "Jindabyne", "value": "C2512"},
                     {"label": "Jindera", "value": "C2513"},
                     {"label": "Jordan Springs", "value": "C2514"},
                     {"label": "Junction Hill", "value": "C2515"},
                     {"label": "Junee", "value": "C2516"},
                     {"label": "Kahibah", "value": "C2517"},
                     {"label": "Kanahooka", "value": "C2518"},
                     {"label": "Kandos", "value": "C2519"},
                     {"label": "Kanwal", "value": "C2520"},
                     {"label": "Kapooka", "value": "C2521"},
                     {"label": "Karabar", "value": "C2522"},
                     {"label": "Kareela", "value": "C2523"},
                     {"label": "Kariong", "value": "C2524"},
                     {"label": "Karuah", "value": "C2525"},
                     {"label": "Katoomba", "value": "C2526"},
                     {"label": "Kearns", "value": "C2527"},
                     {"label": "Keiraville", "value": "C2528"},
                     {"label": "Kellyville", "value": "C2529"},
                     {"label": "Kellyville Ridge", "value": "C2530"},
                     {"label": "Kelso", "value": "C2531"},
                     {"label": "Kemps Creek", "value": "C2532"},
                     {"label": "Kempsey", "value": "C2533"},
                     {"label": "Kendall", "value": "C2534"},
                     {"label": "Kensington", "value": "C2535"},
                     {"label": "Kenthurst", "value": "C2536"},
                     {"label": "Kew", "value": "C2537"},
                     {"label": "Kiama", "value": "C2538"},
                     {"label": "Kiama Downs", "value": "C2539"},
                     {"label": "Kilaben Bay", "value": "C2540"},
                     {"label": "Killara", "value": "C2541"},
                     {"label": "Killarney Heights", "value": "C2542"},
                     {"label": "Killarney Vale", "value": "C2543"},
                     {"label": "King Creek", "value": "C2544"},
                     {"label": "Kings Langley", "value": "C2545"},
                     {"label": "Kings Park", "value": "C2546"},
                     {"label": "Kingscliff", "value": "C2547"},
                     {"label": "Kingsford", "value": "C2548"},
                     {"label": "Kingsgrove", "value": "C2549"},
                     {"label": "Kingswood Park", "value": "C2550"},
                     {"label": "Kirrawee", "value": "C2551"},
                     {"label": "Kirribilli", "value": "C2552"},
                     {"label": "Kogarah", "value": "C2553"},
                     {"label": "Kogarah Bay", "value": "C2554"},
                     {"label": "Koonawarra", "value": "C2555"},
                     {"label": "Kooringal", "value": "C2556"},
                     {"label": "Kootingal", "value": "C2557"},
                     {"label": "Korora", "value": "C2558"},
                     {"label": "Kosciuszko National Park", "value": "C2559"},
                     {"label": "Kotara", "value": "C2560"},
                     {"label": "Kotara South", "value": "C2561"},
                     {"label": "Ku-ring-gai", "value": "C2562"},
                     {"label": "Kurnell", "value": "C2563"},
                     {"label": "Kurraba Point", "value": "C2564"},
                     {"label": "Kurrajong Heights", "value": "C2565"},
                     {"label": "Kurri Kurri", "value": "C2566"},
                     {"label": "Kyle Bay", "value": "C2567"},
                     {"label": "Kyogle", "value": "C2568"},
                     {"label": "Lachlan", "value": "C2569"},
                     {"label": "Lake Albert", "value": "C2570"},
                     {"label": "Lake Cargelligo", "value": "C2571"},
                     {"label": "Lake Cathie", "value": "C2572"},
                     {"label": "Lake Haven", "value": "C2573"},
                     {"label": "Lake Heights", "value": "C2574"},
                     {"label": "Lake Illawarra", "value": "C2575"},
                     {"label": "Lake Macquarie Shire", "value": "C2576"},
                     {"label": "Lake Munmorah", "value": "C2577"},
                     {"label": "Lakelands", "value": "C2578"},
                     {"label": "Lakemba", "value": "C2579"},
                     {"label": "Lakewood", "value": "C2580"},
                     {"label": "Lalor Park", "value": "C2581"},
                     {"label": "Lambton", "value": "C2582"},
                     {"label": "Lane Cove", "value": "C2583"},
                     {"label": "Lane Cove North", "value": "C2584"},
                     {"label": "Lane Cove West", "value": "C2585"},
                     {"label": "Lansvale", "value": "C2586"},
                     {"label": "Largs", "value": "C2587"},
                     {"label": "Lavington", "value": "C2588"},
                     {"label": "Lawrence", "value": "C2589"},
                     {"label": "Lawson", "value": "C2590"},
                     {"label": "Leeton", "value": "C2591"},
                     {"label": "Leichhardt", "value": "C2592"},
                     {"label": "Lemon Tree Passage", "value": "C2593"},
                     {"label": "Lennox Head", "value": "C2594"},
                     {"label": "Leonay", "value": "C2595"},
                     {"label": "Leppington", "value": "C2596"},
                     {"label": "Lethbridge Park", "value": "C2597"},
                     {"label": "Leumeah", "value": "C2598"},
                     {"label": "Leura", "value": "C2599"},
                     {"label": "Lewisham", "value": "C2600"},
                     {"label": "Liberty Grove", "value": "C2601"},
                     {"label": "Lidcombe", "value": "C2602"},
                     {"label": "Lightning Ridge", "value": "C2603"},
                     {"label": "Lilli Pilli", "value": "C2604"},
                     {"label": "Lilyfield", "value": "C2605"},
                     {"label": "Lindfield", "value": "C2606"},
                     {"label": "Lisarow", "value": "C2607"},
                     {"label": "Lismore", "value": "C2608"},
                     {"label": "Lismore Heights", "value": "C2609"},
                     {"label": "Lismore Municipality", "value": "C2610"},
                     {"label": "Lithgow", "value": "C2611"},
                     {"label": "Little Bay", "value": "C2612"},
                     {"label": "Liverpool", "value": "C2613"},
                     {"label": "Liverpool Plains", "value": "C2614"},
                     {"label": "Llanarth", "value": "C2615"},
                     {"label": "Llandilo", "value": "C2616"},
                     {"label": "Lockhart", "value": "C2617"},
                     {"label": "Loftus", "value": "C2618"},
                     {"label": "Londonderry", "value": "C2619"},
                     {"label": "Long Beach", "value": "C2620"},
                     {"label": "Long Jetty", "value": "C2621"},
                     {"label": "Longueville", "value": "C2622"},
                     {"label": "Lorn", "value": "C2623"},
                     {"label": "Luddenham", "value": "C2624"},
                     {"label": "Lugarno", "value": "C2625"},
                     {"label": "Lurnea", "value": "C2626"},
                     {"label": "Macksville", "value": "C2627"},
                     {"label": "Maclean", "value": "C2628"},
                     {"label": "Macmasters Beach", "value": "C2629"},
                     {"label": "Macquarie Fields", "value": "C2630"},
                     {"label": "Macquarie Hills", "value": "C2631"},
                     {"label": "Macquarie Links", "value": "C2632"},
                     {"label": "Macquarie Park", "value": "C2633"},
                     {"label": "Maitland", "value": "C2634"},
                     {"label": "Maitland city centre", "value": "C2635"},
                     {"label": "Maitland Municipality", "value": "C2636"},
                     {"label": "Malabar", "value": "C2637"},
                     {"label": "Malua Bay", "value": "C2638"},
                     {"label": "Mangerton", "value": "C2639"},
                     {"label": "Manilla", "value": "C2640"},
                     {"label": "Manly", "value": "C2641"},
                     {"label": "Manly Vale", "value": "C2642"},
                     {"label": "Mannering Park", "value": "C2643"},
                     {"label": "Maraylya", "value": "C2644"},
                     {"label": "Marayong", "value": "C2645"},
                     {"label": "Mardi", "value": "C2646"},
                     {"label": "Marks Point", "value": "C2647"},
                     {"label": "Maroubra", "value": "C2648"},
                     {"label": "Marrickville", "value": "C2649"},
                     {"label": "Marsfield", "value": "C2650"},
                     {"label": "Marulan", "value": "C2651"},
                     {"label": "Maryland", "value": "C2652"},
                     {"label": "Maryville", "value": "C2653"},
                     {"label": "Mascot", "value": "C2654"},
                     {"label": "Matraville", "value": "C2655"},
                     {"label": "Mayfield", "value": "C2656"},
                     {"label": "Mayfield East", "value": "C2657"},
                     {"label": "Mayfield West", "value": "C2658"},
                     {"label": "McGraths Hill", "value": "C2659"},
                     {"label": "McMahons Point", "value": "C2660"},
                     {"label": "Meadowbank", "value": "C2661"},
                     {"label": "Medowie", "value": "C2662"},
                     {"label": "Melrose Park", "value": "C2663"},
                     {"label": "Menai", "value": "C2664"},
                     {"label": "Menangle", "value": "C2665"},
                     {"label": "Merewether", "value": "C2666"},
                     {"label": "Merewether Heights", "value": "C2667"},
                     {"label": "Merimbula", "value": "C2668"},
                     {"label": "Merriwa", "value": "C2669"},
                     {"label": "Merrylands", "value": "C2670"},
                     {"label": "Merrylands West", "value": "C2671"},
                     {"label": "Metford", "value": "C2672"},
                     {"label": "Mid-Western Regional", "value": "C2673"},
                     {"label": "Middle Cove", "value": "C2674"},
                     {"label": "Middleton Grange", "value": "C2675"},
                     {"label": "Miller", "value": "C2676"},
                     {"label": "Millers Point", "value": "C2677"},
                     {"label": "Millthorpe", "value": "C2678"},
                     {"label": "Milperra", "value": "C2679"},
                     {"label": "Milsons Point", "value": "C2680"},
                     {"label": "Milton", "value": "C2681"},
                     {"label": "Minchinbury", "value": "C2682"},
                     {"label": "Minto", "value": "C2683"},
                     {"label": "Miranda", "value": "C2684"},
                     {"label": "Mitchell", "value": "C2685"},
                     {"label": "Mittagong", "value": "C2686"},
                     {"label": "Moama", "value": "C2687"},
                     {"label": "Mollymook", "value": "C2688"},
                     {"label": "Mollymook Beach", "value": "C2689"},
                     {"label": "Molong", "value": "C2690"},
                     {"label": "Mona Vale", "value": "C2691"},
                     {"label": "Monterey", "value": "C2692"},
                     {"label": "Moonbi", "value": "C2693"},
                     {"label": "Moonee Beach", "value": "C2694"},
                     {"label": "Moorebank", "value": "C2695"},
                     {"label": "Moree", "value": "C2696"},
                     {"label": "Moree Plains", "value": "C2697"},
                     {"label": "Morisset", "value": "C2698"},
                     {"label": "Morpeth", "value": "C2699"},
                     {"label": "Mortdale", "value": "C2700"},
                     {"label": "Mortlake", "value": "C2701"},
                     {"label": "Moruya", "value": "C2702"},
                     {"label": "Mosman", "value": "C2703"},
                     {"label": "Moss Vale", "value": "C2704"},
                     {"label": "Mount Annan", "value": "C2705"},
                     {"label": "Mount Austin", "value": "C2706"},
                     {"label": "Mount Colah", "value": "C2707"},
                     {"label": "Mount Druitt", "value": "C2708"},
                     {"label": "Mount Hutton", "value": "C2709"},
                     {"label": "Mount Keira", "value": "C2710"},
                     {"label": "Mount Kembla", "value": "C2711"},
                     {"label": "Mount Kuring-Gai", "value": "C2712"},
                     {"label": "Mount Lewis", "value": "C2713"},
                     {"label": "Mount Ousley", "value": "C2714"},
                     {"label": "Mount Pleasant", "value": "C2715"},
                     {"label": "Mount Pritchard", "value": "C2716"},
                     {"label": "Mount Riverview", "value": "C2717"},
                     {"label": "Mount Saint Thomas", "value": "C2718"},
                     {"label": "Mount Vernon", "value": "C2719"},
                     {"label": "Mount Warrigal", "value": "C2720"},
                     {"label": "Mudgee", "value": "C2721"},
                     {"label": "Mulgoa", "value": "C2722"},
                     {"label": "Mullumbimby", "value": "C2723"},
                     {"label": "Mulwala", "value": "C2724"},
                     {"label": "Murrumbateman", "value": "C2725"},
                     {"label": "Murrurundi", "value": "C2726"},
                     {"label": "Murwillumbah", "value": "C2727"},
                     {"label": "Muswellbrook", "value": "C2728"},
                     {"label": "Nabiac", "value": "C2729"},
                     {"label": "Nambucca", "value": "C2730"},
                     {"label": "Nambucca Heads", "value": "C2731"},
                     {"label": "Nambucca Shire", "value": "C2732"},
                     {"label": "Nana Glen", "value": "C2733"},
                     {"label": "Narara", "value": "C2734"},
                     {"label": "Narellan", "value": "C2735"},
                     {"label": "Narellan Vale", "value": "C2736"},
                     {"label": "Naremburn", "value": "C2737"},
                     {"label": "Narooma", "value": "C2738"},
                     {"label": "Narrabeen", "value": "C2739"},
                     {"label": "Narrabri", "value": "C2740"},
                     {"label": "Narrandera", "value": "C2741"},
                     {"label": "Narrawallee", "value": "C2742"},
                     {"label": "Narraweena", "value": "C2743"},
                     {"label": "Narromine", "value": "C2744"},
                     {"label": "Narwee", "value": "C2745"},
                     {"label": "Nelson Bay", "value": "C2746"},
                     {"label": "Neutral Bay", "value": "C2747"},
                     {"label": "New Lambton", "value": "C2748"},
                     {"label": "New Lambton Heights", "value": "C2749"},
                     {"label": "Newcastle", "value": "C2750"},
                     {"label": "Newcastle city centre", "value": "C2751"},
                     {"label": "Newcastle East", "value": "C2752"},
                     {"label": "Newington", "value": "C2753"},
                     {"label": "Newport", "value": "C2754"},
                     {"label": "Newtown", "value": "C2755"},
                     {"label": "Niagara Park", "value": "C2756"},
                     {"label": "Nimbin", "value": "C2757"},
                     {"label": "Noraville", "value": "C2758"},
                     {"label": "Normanhurst", "value": "C2759"},
                     {"label": "North Albury", "value": "C2760"},
                     {"label": "North Avoca", "value": "C2761"},
                     {"label": "North Balgowlah", "value": "C2762"},
                     {"label": "North Boambee Valley", "value": "C2763"},
                     {"label": "North Bondi", "value": "C2764"},
                     {"label": "North Curl Curl", "value": "C2765"},
                     {"label": "North Epping", "value": "C2766"},
                     {"label": "North Gosford", "value": "C2767"},
                     {"label": "North Haven", "value": "C2768"},
                     {"label": "North Lambton", "value": "C2769"},
                     {"label": "North Manly", "value": "C2770"},
                     {"label": "North Narrabeen", "value": "C2771"},
                     {"label": "North Nowra", "value": "C2772"},
                     {"label": "North Parramatta", "value": "C2773"},
                     {"label": "North Richmond", "value": "C2774"},
                     {"label": "North Rocks", "value": "C2775"},
                     {"label": "North Ryde", "value": "C2776"},
                     {"label": "North St Marys", "value": "C2777"},
                     {"label": "North Strathfield", "value": "C2778"},
                     {"label": "North Sydney", "value": "C2779"},
                     {"label": "North Tamworth", "value": "C2780"},
                     {"label": "North Turramurra", "value": "C2781"},
                     {"label": "North Wahroonga", "value": "C2782"},
                     {"label": "North Willoughby", "value": "C2783"},
                     {"label": "North Wollongong", "value": "C2784"},
                     {"label": "Northbridge", "value": "C2785"},
                     {"label": "Northmead", "value": "C2786"},
                     {"label": "Nowra", "value": "C2787"},
                     {"label": "Nowra Hill", "value": "C2788"},
                     {"label": "Nyngan", "value": "C2789"},
                     {"label": "Oak Flats", "value": "C2790"},
                     {"label": "Oakdale", "value": "C2791"},
                     {"label": "Oakhurst", "value": "C2792"},
                     {"label": "Oakville", "value": "C2793"},
                     {"label": "Oatlands", "value": "C2794"},
                     {"label": "Oatley", "value": "C2795"},
                     {"label": "Oberon", "value": "C2796"},
                     {"label": "Ocean Shores", "value": "C2797"},
                     {"label": "Old Bar", "value": "C2798"},
                     {"label": "Old Erowal Bay", "value": "C2799"},
                     {"label": "Old Guildford", "value": "C2800"},
                     {"label": "Old Toongabbie", "value": "C2801"},
                     {"label": "Oran Park", "value": "C2802"},
                     {"label": "Orange", "value": "C2803"},
                     {"label": "Orange Municipality", "value": "C2804"},
                     {"label": "Orangeville", "value": "C2805"},
                     {"label": "Orchard Hills", "value": "C2806"},
                     {"label": "Ourimbah", "value": "C2807"},
                     {"label": "Oxley Park", "value": "C2808"},
                     {"label": "Oxley Vale", "value": "C2809"},
                     {"label": "Oyster Bay", "value": "C2810"},
                     {"label": "Paddington", "value": "C2811"},
                     {"label": "Padstow", "value": "C2812"},
                     {"label": "Padstow Heights", "value": "C2813"},
                     {"label": "Pagewood", "value": "C2814"},
                     {"label": "Palm Beach", "value": "C2815"},
                     {"label": "Panania", "value": "C2816"},
                     {"label": "Parkes", "value": "C2817"},
                     {"label": "Parklea", "value": "C2818"},
                     {"label": "Parramatta", "value": "C2819"},
                     {"label": "Peak Hill", "value": "C2820"},
                     {"label": "Peakhurst", "value": "C2821"},
                     {"label": "Peakhurst Heights", "value": "C2822"},
                     {"label": "Pemulwuy", "value": "C2823"},
                     {"label": "Pendle Hill", "value": "C2824"},
                     {"label": "Pennant Hills", "value": "C2825"},
                     {"label": "Penrith", "value": "C2826"},
                     {"label": "Penrith Municipality", "value": "C2827"},
                     {"label": "Penshurst", "value": "C2828"},
                     {"label": "Petersham", "value": "C2829"},
                     {"label": "Phillip", "value": "C2830"},
                     {"label": "Picnic Point", "value": "C2831"},
                     {"label": "Picton", "value": "C2832"},
                     {"label": "Pitt Town", "value": "C2833"},
                     {"label": "Plumpton", "value": "C2834"},
                     {"label": "Point Clare", "value": "C2835"},
                     {"label": "Point Frederick", "value": "C2836"},
                     {"label": "Point Piper", "value": "C2837"},
                     {"label": "Pokolbin", "value": "C2838"},
                     {"label": "Port Hacking", "value": "C2839"},
                     {"label": "Port Kembla", "value": "C2840"},
                     {"label": "Port Macquarie", "value": "C2841"},
                     {"label": "Port Macquarie-Hastings", "value": "C2842"},
                     {"label": "Port Stephens Shire", "value": "C2843"},
                     {"label": "Portland", "value": "C2844"},
                     {"label": "Potts Point", "value": "C2845"},
                     {"label": "Pottsville", "value": "C2846"},
                     {"label": "Pottsville Beach", "value": "C2847"},
                     {"label": "Prairiewood", "value": "C2848"},
                     {"label": "Prestons", "value": "C2849"},
                     {"label": "Primbee", "value": "C2850"},
                     {"label": "Prospect", "value": "C2851"},
                     {"label": "Punchbowl", "value": "C2852"},
                     {"label": "Putney", "value": "C2853"},
                     {"label": "Pymble", "value": "C2854"},
                     {"label": "Pyrmont", "value": "C2855"},
                     {"label": "Quakers Hill", "value": "C2856"},
                     {"label": "Queanbeyan", "value": "C2857"},
                     {"label": "Queanbeyan East", "value": "C2858"},
                     {"label": "Queanbeyan West", "value": "C2859"},
                     {"label": "Queens Park", "value": "C2860"},
                     {"label": "Queenscliff", "value": "C2861"},
                     {"label": "Quirindi", "value": "C2862"},
                     {"label": "Raby", "value": "C2863"},
                     {"label": "Raglan", "value": "C2864"},
                     {"label": "Ramsgate", "value": "C2865"},
                     {"label": "Ramsgate Beach", "value": "C2866"},
                     {"label": "Randwick", "value": "C2867"},
                     {"label": "Rankin Park", "value": "C2868"},
                     {"label": "Rathmines", "value": "C2869"},
                     {"label": "Raworth", "value": "C2870"},
                     {"label": "Raymond Terrace", "value": "C2871"},
                     {"label": "Razorback", "value": "C2872"},
                     {"label": "Redfern", "value": "C2873"},
                     {"label": "Redhead", "value": "C2874"},
                     {"label": "Regents Park", "value": "C2875"},
                     {"label": "Revesby", "value": "C2876"},
                     {"label": "Revesby Heights", "value": "C2877"},
                     {"label": "Rhodes", "value": "C2878"},
                     {"label": "Richmond", "value": "C2879"},
                     {"label": "Richmond Valley", "value": "C2880"},
                     {"label": "Riverstone", "value": "C2881"},
                     {"label": "Riverview", "value": "C2882"},
                     {"label": "Riverwood", "value": "C2883"},
                     {"label": "Robertson", "value": "C2884"},
                     {"label": "Rockdale", "value": "C2885"},
                     {"label": "Rodd Point", "value": "C2886"},
                     {"label": "Rooty Hill", "value": "C2887"},
                     {"label": "Ropes Crossing", "value": "C2888"},
                     {"label": "Rose Bay", "value": "C2889"},
                     {"label": "Rosebery", "value": "C2890"},
                     {"label": "Rosehill", "value": "C2891"},
                     {"label": "Roselands", "value": "C2892"},
                     {"label": "Rosemeadow", "value": "C2893"},
                     {"label": "Roseville", "value": "C2894"},
                     {"label": "Roseville Chase", "value": "C2895"},
                     {"label": "Rouse Hill", "value": "C2896"},
                     {"label": "Rozelle", "value": "C2897"},
                     {"label": "Ruse", "value": "C2898"},
                     {"label": "Rushcutters Bay", "value": "C2899"},
                     {"label": "Russell Lea", "value": "C2900"},
                     {"label": "Russell Vale", "value": "C2901"},
                     {"label": "Rutherford", "value": "C2902"},
                     {"label": "Rydalmere", "value": "C2903"},
                     {"label": "Ryde", "value": "C2904"},
                     {"label": "Sadleir", "value": "C2905"},
                     {"label": "Saint Ives", "value": "C2906"},
                     {"label": "Saint Peters", "value": "C2907"},
                     {"label": "Salamander Bay", "value": "C2908"},
                     {"label": "Salt Ash", "value": "C2909"},
                     {"label": "San Remo", "value": "C2910"},
                     {"label": "Sanctuary Point", "value": "C2911"},
                     {"label": "Sandringham", "value": "C2912"},
                     {"label": "Sandy Beach", "value": "C2913"},
                     {"label": "Sans Souci", "value": "C2914"},
                     {"label": "Sapphire Beach", "value": "C2915"},
                     {"label": "Saratoga", "value": "C2916"},
                     {"label": "Sawtell", "value": "C2917"},
                     {"label": "Schofields", "value": "C2918"},
                     {"label": "Scone", "value": "C2919"},
                     {"label": "Seaforth", "value": "C2920"},
                     {"label": "Sefton", "value": "C2921"},
                     {"label": "Seven Hills", "value": "C2922"},
                     {"label": "Shalvey", "value": "C2923"},
                     {"label": "Shell Cove", "value": "C2924"},
                     {"label": "Shellharbour", "value": "C2925"},
                     {"label": "Shelly Beach", "value": "C2926"},
                     {"label": "Shoal Bay", "value": "C2927"},
                     {"label": "Shoalhaven Heads", "value": "C2928"},
                     {"label": "Shoalhaven Shire", "value": "C2929"},
                     {"label": "Shortland", "value": "C2930"},
                     {"label": "Silverdale", "value": "C2931"},
                     {"label": "Silverwater", "value": "C2932"},
                     {"label": "Singleton", "value": "C2933"},
                     {"label": "Singleton Heights", "value": "C2934"},
                     {"label": "Skennars Head", "value": "C2935"},
                     {"label": "Smithfield", "value": "C2936"},
                     {"label": "Smiths Lake", "value": "C2937"},
                     {"label": "Soldiers Point", "value": "C2938"},
                     {"label": "Somersby", "value": "C2939"},
                     {"label": "South Albury", "value": "C2940"},
                     {"label": "South Bathurst", "value": "C2941"},
                     {"label": "South Bowenfels", "value": "C2942"},
                     {"label": "South Coogee", "value": "C2943"},
                     {"label": "South Grafton", "value": "C2944"},
                     {"label": "South Granville", "value": "C2945"},
                     {"label": "South Hurstville", "value": "C2946"},
                     {"label": "South Kempsey", "value": "C2947"},
                     {"label": "South Lismore", "value": "C2948"},
                     {"label": "South Murwillumbah", "value": "C2949"},
                     {"label": "South Nowra", "value": "C2950"},
                     {"label": "South Penrith", "value": "C2951"},
                     {"label": "South Tamworth", "value": "C2952"},
                     {"label": "South Turramurra", "value": "C2953"},
                     {"label": "South Wentworthville", "value": "C2954"},
                     {"label": "South West Rocks", "value": "C2955"},
                     {"label": "South Windsor", "value": "C2956"},
                     {"label": "Speers Point", "value": "C2957"},
                     {"label": "Spring Farm", "value": "C2958"},
                     {"label": "Springdale Heights", "value": "C2959"},
                     {"label": "Springvale", "value": "C2960"},
                     {"label": "Springwood", "value": "C2961"},
                     {"label": "St Andrews", "value": "C2962"},
                     {"label": "St Clair", "value": "C2963"},
                     {"label": "St Helens Park", "value": "C2964"},
                     {"label": "St Huberts Island", "value": "C2965"},
                     {"label": "St Ives Chase", "value": "C2966"},
                     {"label": "St Johns Park", "value": "C2967"},
                     {"label": "St Leonards", "value": "C2968"},
                     {"label": "St. Georges Basin", "value": "C2969"},
                     {"label": "Stanhope Gardens", "value": "C2970"},
                     {"label": "Stanmore", "value": "C2971"},
                     {"label": "Stanwell Park", "value": "C2972"},
                     {"label": "Stockton", "value": "C2973"},
                     {"label": "Strathfield", "value": "C2974"},
                     {"label": "Strathfield South", "value": "C2975"},
                     {"label": "Suffolk Park", "value": "C2976"},
                     {"label": "Summer Hill", "value": "C2977"},
                     {"label": "Summerland Point", "value": "C2978"},
                     {"label": "Sunshine Bay", "value": "C2979"},
                     {"label": "Surfside", "value": "C2980"},
                     {"label": "Surry Hills", "value": "C2981"},
                     {"label": "Sussex Inlet", "value": "C2982"},
                     {"label": "Sutherland", "value": "C2983"},
                     {"label": "Sutherland Shire", "value": "C2984"},
                     {"label": "Sutton", "value": "C2985"},
                     {"label": "Swansea", "value": "C2986"},
                     {"label": "Sydenham", "value": "C2987"},
                     {"label": "Sydney", "value": "C2988"},
                     {"label": "Sydney Central Business District",
                      "value": "C2989"},
                     {"label": "Sydney Olympic Park", "value": "C2990"},
                     {"label": "Sylvania", "value": "C2991"},
                     {"label": "Sylvania Waters", "value": "C2992"},
                     {"label": "Table Top", "value": "C2993"},
                     {"label": "Tahmoor", "value": "C2994"},
                     {"label": "Tamarama", "value": "C2995"},
                     {"label": "Tamworth", "value": "C2996"},
                     {"label": "Tamworth Municipality", "value": "C2997"},
                     {"label": "Tanilba Bay", "value": "C2998"},
                     {"label": "Taree", "value": "C2999"},
                     {"label": "Taren Point", "value": "C3000"},
                     {"label": "Tarrawanna", "value": "C3001"},
                     {"label": "Tarro", "value": "C3002"},
                     {"label": "Tascott", "value": "C3003"},
                     {"label": "Tathra", "value": "C3004"},
                     {"label": "Tatton", "value": "C3005"},
                     {"label": "Tea Gardens", "value": "C3006"},
                     {"label": "Telarah", "value": "C3007"},
                     {"label": "Telopea", "value": "C3008"},
                     {"label": "Temora", "value": "C3009"},
                     {"label": "Temora Municipality", "value": "C3010"},
                     {"label": "Tempe", "value": "C3011"},
                     {"label": "Tenambit", "value": "C3012"},
                     {"label": "Tennyson Point", "value": "C3013"},
                     {"label": "Tenterfield", "value": "C3014"},
                     {"label": "Tenterfield Municipality", "value": "C3015"},
                     {"label": "Teralba", "value": "C3016"},
                     {"label": "Terranora", "value": "C3017"},
                     {"label": "Terrigal", "value": "C3018"},
                     {"label": "Terry Hills", "value": "C3019"},
                     {"label": "The Entrance", "value": "C3020"},
                     {"label": "The Entrance North", "value": "C3021"},
                     {"label": "The Hill", "value": "C3022"},
                     {"label": "The Hills Shire", "value": "C3023"},
                     {"label": "The Junction", "value": "C3024"},
                     {"label": "The Oaks", "value": "C3025"},
                     {"label": "The Ponds", "value": "C3026"},
                     {"label": "The Rock", "value": "C3027"},
                     {"label": "The Rocks", "value": "C3028"},
                     {"label": "Thirlmere", "value": "C3029"},
                     {"label": "Thirroul", "value": "C3030"},
                     {"label": "Thornleigh", "value": "C3031"},
                     {"label": "Thornton", "value": "C3032"},
                     {"label": "Thurgoona", "value": "C3033"},
                     {"label": "Tighes Hill", "value": "C3034"},
                     {"label": "Tingira Heights", "value": "C3035"},
                     {"label": "Tinonee", "value": "C3036"},
                     {"label": "Tocumwal", "value": "C3037"},
                     {"label": "Tolland", "value": "C3038"},
                     {"label": "Tomakin", "value": "C3039"},
                     {"label": "Tomerong", "value": "C3040"},
                     {"label": "Toongabbie", "value": "C3041"},
                     {"label": "Toormina", "value": "C3042"},
                     {"label": "Toronto", "value": "C3043"},
                     {"label": "Toukley", "value": "C3044"},
                     {"label": "Towradgi", "value": "C3045"},
                     {"label": "Trangie", "value": "C3046"},
                     {"label": "Tregear", "value": "C3047"},
                     {"label": "Tuggerawong", "value": "C3048"},
                     {"label": "Tumbarumba", "value": "C3049"},
                     {"label": "Tumbi Vmbi", "value": "C3050"},
                     {"label": "Tumut", "value": "C3051"},
                     {"label": "Tuncurry", "value": "C3052"},
                     {"label": "Tura Beach", "value": "C3053"},
                     {"label": "Tuross Head", "value": "C3054"},
                     {"label": "Turramurra", "value": "C3055"},
                     {"label": "Turrella", "value": "C3056"},
                     {"label": "Turvey Park", "value": "C3057"},
                     {"label": "Tweed", "value": "C3058"},
                     {"label": "Tweed Heads", "value": "C3059"},
                     {"label": "Tweed Heads South", "value": "C3060"},
                     {"label": "Tweed Heads West", "value": "C3061"},
                     {"label": "Ulladulla", "value": "C3062"},
                     {"label": "Ultimo", "value": "C3063"},
                     {"label": "Umina Beach", "value": "C3064"},
                     {"label": "Unanderra", "value": "C3065"},
                     {"label": "Upper Hunter Shire", "value": "C3066"},
                     {"label": "Upper Lachlan Shire", "value": "C3067"},
                     {"label": "Uralla", "value": "C3068"},
                     {"label": "Urunga", "value": "C3069"},
                     {"label": "Valentine", "value": "C3070"},
                     {"label": "Valla Beach", "value": "C3071"},
                     {"label": "Valley Heights", "value": "C3072"},
                     {"label": "Vaucluse", "value": "C3073"},
                     {"label": "Villawood", "value": "C3074"},
                     {"label": "Vincentia", "value": "C3075"},
                     {"label": "Vineyard", "value": "C3076"},
                     {"label": "Voyager Point", "value": "C3077"},
                     {"label": "Wadalba", "value": "C3078"},
                     {"label": "Wagga Wagga", "value": "C3079"},
                     {"label": "Wahroonga", "value": "C3080"},
                     {"label": "Waitara", "value": "C3081"},
                     {"label": "Wakeley", "value": "C3082"},
                     {"label": "Walcha", "value": "C3083"},
                     {"label": "Walgett", "value": "C3084"},
                     {"label": "Wallacia", "value": "C3085"},
                     {"label": "Wallalong", "value": "C3086"},
                     {"label": "Wallerawang", "value": "C3087"},
                     {"label": "Wallsend", "value": "C3088"},
                     {"label": "Wamberal", "value": "C3089"},
                     {"label": "Wamboin", "value": "C3090"},
                     {"label": "Wangi Wangi", "value": "C3091"},
                     {"label": "Warabrook", "value": "C3092"},
                     {"label": "Waratah", "value": "C3093"},
                     {"label": "Waratah West", "value": "C3094"},
                     {"label": "Wareemba", "value": "C3095"},
                     {"label": "Warialda", "value": "C3096"},
                     {"label": "Warilla", "value": "C3097"},
                     {"label": "Warners Bay", "value": "C3098"},
                     {"label": "Warragamba", "value": "C3099"},
                     {"label": "Warrawee", "value": "C3100"},
                     {"label": "Warrawong", "value": "C3101"},
                     {"label": "Warren", "value": "C3102"},
                     {"label": "Warren Shire", "value": "C3103"},
                     {"label": "Warriewood", "value": "C3104"},
                     {"label": "Warrimoo", "value": "C3105"},
                     {"label": "Warrumbungle Shire", "value": "C3106"},
                     {"label": "Warwick Farm", "value": "C3107"},
                     {"label": "Watanobbi", "value": "C3108"},
                     {"label": "Waterview Heights", "value": "C3109"},
                     {"label": "Wattle Grove", "value": "C3110"},
                     {"label": "Wattle Ponds", "value": "C3111"},
                     {"label": "Wauchope", "value": "C3112"},
                     {"label": "Waverley", "value": "C3113"},
                     {"label": "Waverton", "value": "C3114"},
                     {"label": "Weddin", "value": "C3115"},
                     {"label": "Wee Waa", "value": "C3116"},
                     {"label": "Wellington", "value": "C3117"},
                     {"label": "Wentworth", "value": "C3118"},
                     {"label": "Wentworth Falls", "value": "C3119"},
                     {"label": "Wentworth Point", "value": "C3120"},
                     {"label": "Wentworthville", "value": "C3121"},
                     {"label": "Werrington", "value": "C3122"},
                     {"label": "Werrington County", "value": "C3123"},
                     {"label": "Werrington Downs", "value": "C3124"},
                     {"label": "Werris Creek", "value": "C3125"},
                     {"label": "West Albury", "value": "C3126"},
                     {"label": "West Ballina", "value": "C3127"},
                     {"label": "West Bathurst", "value": "C3128"},
                     {"label": "West Gosford", "value": "C3129"},
                     {"label": "West Haven", "value": "C3130"},
                     {"label": "West Hoxton", "value": "C3131"},
                     {"label": "West Kempsey", "value": "C3132"},
                     {"label": "West Nowra", "value": "C3133"},
                     {"label": "West Pennant Hills", "value": "C3134"},
                     {"label": "West Pymble", "value": "C3135"},
                     {"label": "West Ryde", "value": "C3136"},
                     {"label": "West Tamworth", "value": "C3137"},
                     {"label": "West Wallsend", "value": "C3138"},
                     {"label": "West Wollongong", "value": "C3139"},
                     {"label": "West Wyalong", "value": "C3140"},
                     {"label": "Westdale", "value": "C3141"},
                     {"label": "Westleigh", "value": "C3142"},
                     {"label": "Westmead", "value": "C3143"},
                     {"label": "Wetherill Park", "value": "C3144"},
                     {"label": "Whalan", "value": "C3145"},
                     {"label": "Whale Beach", "value": "C3146"},
                     {"label": "Wheeler Heights", "value": "C3147"},
                     {"label": "Whitebridge", "value": "C3148"},
                     {"label": "Wickham", "value": "C3149"},
                     {"label": "Wilberforce", "value": "C3150"},
                     {"label": "Wiley Park", "value": "C3151"},
                     {"label": "Williamtown", "value": "C3152"},
                     {"label": "Willmot", "value": "C3153"},
                     {"label": "Willoughby", "value": "C3154"},
                     {"label": "Willoughby East", "value": "C3155"},
                     {"label": "Wilton", "value": "C3156"},
                     {"label": "Windale", "value": "C3157"},
                     {"label": "Windang", "value": "C3158"},
                     {"label": "Windradyne", "value": "C3159"},
                     {"label": "Windsor", "value": "C3160"},
                     {"label": "Windsor Downs", "value": "C3161"},
                     {"label": "Wingecarribee", "value": "C3162"},
                     {"label": "Wingham", "value": "C3163"},
                     {"label": "Winmalee", "value": "C3164"},
                     {"label": "Winston Hills", "value": "C3165"},
                     {"label": "Wolli Creek", "value": "C3166"},
                     {"label": "Wollondilly", "value": "C3167"},
                     {"label": "Wollongbar", "value": "C3168"},
                     {"label": "Wollongong", "value": "C3169"},
                     {"label": "Wollongong city centre", "value": "C3170"},
                     {"label": "Wollstonecraft", "value": "C3171"},
                     {"label": "Woodberry", "value": "C3172"},
                     {"label": "Woodbine", "value": "C3173"},
                     {"label": "Woodcroft", "value": "C3174"},
                     {"label": "Woodford", "value": "C3175"},
                     {"label": "Woodpark", "value": "C3176"},
                     {"label": "Woodrising", "value": "C3177"},
                     {"label": "Woolgoolga", "value": "C3178"},
                     {"label": "Woollahra", "value": "C3179"},
                     {"label": "Woolloomooloo", "value": "C3180"},
                     {"label": "Woolooware", "value": "C3181"},
                     {"label": "Woongarrah", "value": "C3182"},
                     {"label": "Woonona", "value": "C3183"},
                     {"label": "Woronora", "value": "C3184"},
                     {"label": "Woronora Heights", "value": "C3185"},
                     {"label": "Worrigee", "value": "C3186"},
                     {"label": "Woy Woy", "value": "C3187"},
                     {"label": "Wyee", "value": "C3188"},
                     {"label": "Wyee Point", "value": "C3189"},
                     {"label": "Wyoming", "value": "C3190"},
                     {"label": "Wyong", "value": "C3191"},
                     {"label": "Wyongah", "value": "C3192"},
                     {"label": "Yagoona", "value": "C3193"},
                     {"label": "Yallah", "value": "C3194"},
                     {"label": "Yamba", "value": "C3195"},
                     {"label": "Yarravel", "value": "C3196"},
                     {"label": "Yarrawarrah", "value": "C3197"},
                     {"label": "Yass", "value": "C3198"},
                     {"label": "Yass Valley", "value": "C3199"},
                     {"label": "Yenda", "value": "C3200"},
                     {"label": "Yennora", "value": "C3201"},
                     {"label": "Yerrinbool", "value": "C3202"},
                     {"label": "Yoogali", "value": "C3203"},
                     {"label": "Young", "value": "C3204"},
                     {"label": "Yowie Bay", "value": "C3205"},
                     {"label": "Zetland", "value": "C3206"}],
 "New Valley": [{"label": "Al Kharijah", "value": "C24311"},
                {"label": "Qasr al Farafirah", "value": "C24312"}],
 "New York": [{"label": "Adams", "value": "C138250"},
              {"label": "Adams Center", "value": "C138251"},
              {"label": "Addison", "value": "C138252"},
              {"label": "Airmont", "value": "C138253"},
              {"label": "Akron", "value": "C138254"},
              {"label": "Alabama", "value": "C138255"},
              {"label": "Albany", "value": "C138256"},
              {"label": "Albany County", "value": "C138257"},
              {"label": "Albertson", "value": "C138258"},
              {"label": "Albion", "value": "C138259"},
              {"label": "Alden", "value": "C138260"},
              {"label": "Alexandria Bay", "value": "C138261"},
              {"label": "Alfred", "value": "C138262"},
              {"label": "Allegany", "value": "C138263"},
              {"label": "Allegany County", "value": "C138264"},
              {"label": "Altamont", "value": "C138265"},
              {"label": "Amagansett", "value": "C138266"},
              {"label": "Amherst", "value": "C138267"},
              {"label": "Amityville", "value": "C138268"},
              {"label": "Amsterdam", "value": "C138269"},
              {"label": "Andover", "value": "C138270"},
              {"label": "Angola", "value": "C138271"},
              {"label": "Angola on the Lake", "value": "C138272"},
              {"label": "Apalachin", "value": "C138273"},
              {"label": "Aquebogue", "value": "C138274"},
              {"label": "Arcade", "value": "C138275"},
              {"label": "Ardsley", "value": "C138276"},
              {"label": "Arlington", "value": "C138277"},
              {"label": "Armonk", "value": "C138278"},
              {"label": "Arrochar", "value": "C138279"},
              {"label": "Arverne", "value": "C138280"},
              {"label": "Astoria", "value": "C138281"},
              {"label": "Athens", "value": "C138282"},
              {"label": "Atlantic Beach", "value": "C138283"},
              {"label": "Attica", "value": "C138284"},
              {"label": "Auburn", "value": "C138285"},
              {"label": "Augusta", "value": "C138286"},
              {"label": "Averill Park", "value": "C138287"},
              {"label": "Avon", "value": "C138288"},
              {"label": "Babylon", "value": "C138289"},
              {"label": "Bainbridge", "value": "C138290"},
              {"label": "Baiting Hollow", "value": "C138291"},
              {"label": "Baldwin", "value": "C138292"},
              {"label": "Baldwin Harbor", "value": "C138293"},
              {"label": "Baldwinsville", "value": "C138294"},
              {"label": "Ballston Spa", "value": "C138295"},
              {"label": "Balmville", "value": "C138296"},
              {"label": "Bardonia", "value": "C138297"},
              {"label": "Barnum Island", "value": "C138298"},
              {"label": "Batavia", "value": "C138299"},
              {"label": "Bath", "value": "C138300"},
              {"label": "Bath Beach", "value": "C138301"},
              {"label": "Baxter Estates", "value": "C138302"},
              {"label": "Bay Park", "value": "C138303"},
              {"label": "Bay Shore", "value": "C138304"},
              {"label": "Bay Wood", "value": "C138305"},
              {"label": "Baychester", "value": "C138306"},
              {"label": "Bayport", "value": "C138307"},
              {"label": "Bayside", "value": "C138308"},
              {"label": "Bayville", "value": "C138309"},
              {"label": "Beacon", "value": "C138310"},
              {"label": "Beaver Dam Lake", "value": "C138311"},
              {"label": "Beaverdam Lake-Salisbury Mills", "value": "C138312"},
              {"label": "Bedford", "value": "C138313"},
              {"label": "Bedford Hills", "value": "C138314"},
              {"label": "Bellaire", "value": "C138315"},
              {"label": "Belle Harbor", "value": "C138316"},
              {"label": "Bellerose", "value": "C138317"},
              {"label": "Bellerose Terrace", "value": "C138318"},
              {"label": "Bellmore", "value": "C138319"},
              {"label": "Bellport", "value": "C138320"},
              {"label": "Belmont", "value": "C138321"},
              {"label": "Bensonhurst", "value": "C138322"},
              {"label": "Bergen", "value": "C138323"},
              {"label": "Bergen Beach", "value": "C138324"},
              {"label": "Bethpage", "value": "C138325"},
              {"label": "Big Flats", "value": "C138326"},
              {"label": "Billington Heights", "value": "C138327"},
              {"label": "Binghamton", "value": "C138328"},
              {"label": "Black River", "value": "C138329"},
              {"label": "Blasdell", "value": "C138330"},
              {"label": "Blauvelt", "value": "C138331"},
              {"label": "Bloomfield", "value": "C138332"},
              {"label": "Blue Point", "value": "C138333"},
              {"label": "Bohemia", "value": "C138334"},
              {"label": "Bolivar", "value": "C138335"},
              {"label": "Boonville", "value": "C138336"},
              {"label": "Borough Park", "value": "C138337"},
              {"label": "Boston", "value": "C138338"},
              {"label": "Brentwood", "value": "C138339"},
              {"label": "Brewerton", "value": "C138340"},
              {"label": "Brewster", "value": "C138341"},
              {"label": "Brewster Hill", "value": "C138342"},
              {"label": "Briarcliff Manor", "value": "C138343"},
              {"label": "Briarwood", "value": "C138344"},
              {"label": "Bridgehampton", "value": "C138345"},
              {"label": "Bridgeport", "value": "C138346"},
              {"label": "Brighton", "value": "C138347"},
              {"label": "Brighton Beach", "value": "C138348"},
              {"label": "Brightwaters", "value": "C138349"},
              {"label": "Brinckerhoff", "value": "C138350"},
              {"label": "Broad Channel", "value": "C138351"},
              {"label": "Broadalbin", "value": "C138352"},
              {"label": "Brockport", "value": "C138353"},
              {"label": "Brocton", "value": "C138354"},
              {"label": "Bronx", "value": "C138355"},
              {"label": "Bronxville", "value": "C138356"},
              {"label": "Brookhaven", "value": "C138357"},
              {"label": "Brooklyn", "value": "C138358"},
              {"label": "Brooklyn Heights", "value": "C138359"},
              {"label": "Brookville", "value": "C138360"},
              {"label": "Broome County", "value": "C138361"},
              {"label": "Brownsville", "value": "C138362"},
              {"label": "Brownville", "value": "C138363"},
              {"label": "Buchanan", "value": "C138364"},
              {"label": "Buffalo", "value": "C138365"},
              {"label": "Bushwick", "value": "C138366"},
              {"label": "Cairo", "value": "C138367"},
              {"label": "Calcium", "value": "C138368"},
              {"label": "Caledonia", "value": "C138369"},
              {"label": "Calverton", "value": "C138370"},
              {"label": "Cambria Heights", "value": "C138371"},
              {"label": "Cambridge", "value": "C138372"},
              {"label": "Camden", "value": "C138373"},
              {"label": "Camillus", "value": "C138374"},
              {"label": "Canajoharie", "value": "C138375"},
              {"label": "Canandaigua", "value": "C138376"},
              {"label": "Canarsie", "value": "C138377"},
              {"label": "Canastota", "value": "C138378"},
              {"label": "Canisteo", "value": "C138379"},
              {"label": "Canton", "value": "C138380"},
              {"label": "Carle Place", "value": "C138381"},
              {"label": "Carmel", "value": "C138382"},
              {"label": "Carmel Hamlet", "value": "C138383"},
              {"label": "Carthage", "value": "C138384"},
              {"label": "Castleton-on-Hudson", "value": "C138385"},
              {"label": "Catskill", "value": "C138386"},
              {"label": "Cattaraugus County", "value": "C138387"},
              {"label": "Cayuga County", "value": "C138388"},
              {"label": "Cayuga Heights", "value": "C138389"},
              {"label": "Cazenovia", "value": "C138390"},
              {"label": "Cedarhurst", "value": "C138391"},
              {"label": "Celoron", "value": "C138392"},
              {"label": "Center Moriches", "value": "C138393"},
              {"label": "Centereach", "value": "C138394"},
              {"label": "Centerport", "value": "C138395"},
              {"label": "Central Islip", "value": "C138396"},
              {"label": "Central Square", "value": "C138397"},
              {"label": "Central Valley", "value": "C138398"},
              {"label": "Chadwicks", "value": "C138399"},
              {"label": "Champlain", "value": "C138400"},
              {"label": "Chappaqua", "value": "C138401"},
              {"label": "Charleston", "value": "C138402"},
              {"label": "Chatham", "value": "C138403"},
              {"label": "Chautauqua County", "value": "C138404"},
              {"label": "Cheektowaga", "value": "C138405"},
              {"label": "Chelsea", "value": "C138406"},
              {"label": "Chemung County", "value": "C138407"},
              {"label": "Chenango Bridge", "value": "C138408"},
              {"label": "Chenango County", "value": "C138409"},
              {"label": "Chester", "value": "C138410"},
              {"label": "Chestnut Ridge", "value": "C138411"},
              {"label": "Chittenango", "value": "C138412"},
              {"label": "Churchville", "value": "C138413"},
              {"label": "City Island", "value": "C138414"},
              {"label": "Clarence", "value": "C138415"},
              {"label": "Clarence Center", "value": "C138416"},
              {"label": "Clark Mills", "value": "C138417"},
              {"label": "Clarkson", "value": "C138418"},
              {"label": "Clayton", "value": "C138419"},
              {"label": "Clifton", "value": "C138420"},
              {"label": "Clifton Springs", "value": "C138421"},
              {"label": "Clinton", "value": "C138422"},
              {"label": "Clinton County", "value": "C138423"},
              {"label": "Clintondale", "value": "C138424"},
              {"label": "Clyde", "value": "C138425"},
              {"label": "Clymer", "value": "C138426"},
              {"label": "Cobleskill", "value": "C138427"},
              {"label": "Cohoes", "value": "C138428"},
              {"label": "Cold Spring", "value": "C138429"},
              {"label": "Cold Spring Harbor", "value": "C138430"},
              {"label": "College Point", "value": "C138431"},
              {"label": "Colonie", "value": "C138432"},
              {"label": "Columbia County", "value": "C138433"},
              {"label": "Commack", "value": "C138434"},
              {"label": "Concord", "value": "C138435"},
              {"label": "Coney Island", "value": "C138436"},
              {"label": "Congers", "value": "C138437"},
              {"label": "Constantia", "value": "C138438"},
              {"label": "Cooperstown", "value": "C138439"},
              {"label": "Copiague", "value": "C138440"},
              {"label": "Coram", "value": "C138441"},
              {"label": "Corinth", "value": "C138442"},
              {"label": "Corning", "value": "C138443"},
              {"label": "Cornwall", "value": "C138444"},
              {"label": "Corona", "value": "C138445"},
              {"label": "Cortland", "value": "C138446"},
              {"label": "Cortland County", "value": "C138447"},
              {"label": "Cortland West", "value": "C138448"},
              {"label": "Country Knolls", "value": "C138449"},
              {"label": "Coxsackie", "value": "C138450"},
              {"label": "Crompond", "value": "C138451"},
              {"label": "Croton-on-Hudson", "value": "C138452"},
              {"label": "Crown Heights", "value": "C138453"},
              {"label": "Crugers", "value": "C138454"},
              {"label": "Cuba", "value": "C138455"},
              {"label": "Cumberland Head", "value": "C138456"},
              {"label": "Cutchogue", "value": "C138457"},
              {"label": "Cypress Hills", "value": "C138458"},
              {"label": "Dannemora", "value": "C138459"},
              {"label": "Dansville", "value": "C138460"},
              {"label": "Deer Park", "value": "C138461"},
              {"label": "Delaware County", "value": "C138462"},
              {"label": "Delevan", "value": "C138463"},
              {"label": "Delhi", "value": "C138464"},
              {"label": "Delmar", "value": "C138465"},
              {"label": "Depew", "value": "C138466"},
              {"label": "Deposit", "value": "C138467"},
              {"label": "Dexter", "value": "C138468"},
              {"label": "Dix Hills", "value": "C138469"},
              {"label": "Dobbs Ferry", "value": "C138470"},
              {"label": "Dolgeville", "value": "C138471"},
              {"label": "Dongan Hills", "value": "C138472"},
              {"label": "Douglaston", "value": "C138473"},
              {"label": "Dover Plains", "value": "C138474"},
              {"label": "Dryden", "value": "C138475"},
              {"label": "Dundee", "value": "C138476"},
              {"label": "Dunkirk", "value": "C138477"},
              {"label": "Durham", "value": "C138478"},
              {"label": "Dutchess County", "value": "C138479"},
              {"label": "Dyker Heights", "value": "C138480"},
              {"label": "East Atlantic Beach", "value": "C138481"},
              {"label": "East Aurora", "value": "C138482"},
              {"label": "East Elmhurst", "value": "C138483"},
              {"label": "East Farmingdale", "value": "C138484"},
              {"label": "East Flatbush", "value": "C138485"},
              {"label": "East Garden City", "value": "C138486"},
              {"label": "East Glenville", "value": "C138487"},
              {"label": "East Greenbush", "value": "C138488"},
              {"label": "East Hampton", "value": "C138489"},
              {"label": "East Hampton North", "value": "C138490"},
              {"label": "East Harlem", "value": "C138491"},
              {"label": "East Hills", "value": "C138492"},
              {"label": "East Islip", "value": "C138493"},
              {"label": "East Ithaca", "value": "C138494"},
              {"label": "East Massapequa", "value": "C138495"},
              {"label": "East Meadow", "value": "C138496"},
              {"label": "East Moriches", "value": "C138497"},
              {"label": "East New York", "value": "C138498"},
              {"label": "East Northport", "value": "C138499"},
              {"label": "East Norwich", "value": "C138500"},
              {"label": "East Patchogue", "value": "C138501"},
              {"label": "East Quogue", "value": "C138502"},
              {"label": "East Rochester", "value": "C138503"},
              {"label": "East Rockaway", "value": "C138504"},
              {"label": "East Setauket", "value": "C138505"},
              {"label": "East Shoreham", "value": "C138506"},
              {"label": "East Syracuse", "value": "C138507"},
              {"label": "East Tremont", "value": "C138508"},
              {"label": "East Village", "value": "C138509"},
              {"label": "East Williston", "value": "C138510"},
              {"label": "Eastchester", "value": "C138511"},
              {"label": "Eastport", "value": "C138512"},
              {"label": "Eatons Neck", "value": "C138513"},
              {"label": "Eden", "value": "C138514"},
              {"label": "Edgemere", "value": "C138515"},
              {"label": "Edinburg", "value": "C138516"},
              {"label": "Eggertsville", "value": "C138517"},
              {"label": "Elbridge", "value": "C138518"},
              {"label": "Elizabethtown", "value": "C138519"},
              {"label": "Ellenville", "value": "C138520"},
              {"label": "Elma Center", "value": "C138521"},
              {"label": "Elmhurst", "value": "C138522"},
              {"label": "Elmira", "value": "C138523"},
              {"label": "Elmira Heights", "value": "C138524"},
              {"label": "Elmont", "value": "C138525"},
              {"label": "Elmsford", "value": "C138526"},
              {"label": "Eltingville", "value": "C138527"},
              {"label": "Elwood", "value": "C138528"},
              {"label": "Emerson Hill", "value": "C138529"},
              {"label": "Endicott", "value": "C138530"},
              {"label": "Endwell", "value": "C138531"},
              {"label": "Erie County", "value": "C138532"},
              {"label": "Essex County", "value": "C138533"},
              {"label": "Fairmount", "value": "C138534"},
              {"label": "Fairport", "value": "C138535"},
              {"label": "Fairview", "value": "C138536"},
              {"label": "Falconer", "value": "C138537"},
              {"label": "Fallsburg", "value": "C138538"},
              {"label": "Far Rockaway", "value": "C138539"},
              {"label": "Farmingdale", "value": "C138540"},
              {"label": "Farmingville", "value": "C138541"},
              {"label": "Fayetteville", "value": "C138542"},
              {"label": "Financial District", "value": "C138543"},
              {"label": "Firthcliffe", "value": "C138544"},
              {"label": "Fishkill", "value": "C138545"},
              {"label": "Flanders", "value": "C138546"},
              {"label": "Flatbush", "value": "C138547"},
              {"label": "Flatlands", "value": "C138548"},
              {"label": "Floral Park", "value": "C138549"},
              {"label": "Florida", "value": "C138550"},
              {"label": "Flower Hill", "value": "C138551"},
              {"label": "Fonda", "value": "C138552"},
              {"label": "Fordham", "value": "C138553"},
              {"label": "Forest Hills", "value": "C138554"},
              {"label": "Fort Covington Hamlet", "value": "C138555"},
              {"label": "Fort Drum", "value": "C138556"},
              {"label": "Fort Edward", "value": "C138557"},
              {"label": "Fort Hamilton", "value": "C138558"},
              {"label": "Fort Montgomery", "value": "C138559"},
              {"label": "Fort Plain", "value": "C138560"},
              {"label": "Fort Salonga", "value": "C138561"},
              {"label": "Fort Wadsworth", "value": "C138562"},
              {"label": "Frankfort", "value": "C138563"},
              {"label": "Franklin County", "value": "C138564"},
              {"label": "Franklin Square", "value": "C138565"},
              {"label": "Franklinville", "value": "C138566"},
              {"label": "Fredonia", "value": "C138567"},
              {"label": "Freeport", "value": "C138568"},
              {"label": "Fresh Meadows", "value": "C138569"},
              {"label": "Frewsburg", "value": "C138570"},
              {"label": "Friendship", "value": "C138571"},
              {"label": "Fulton", "value": "C138572"},
              {"label": "Fulton County", "value": "C138573"},
              {"label": "Galeville", "value": "C138574"},
              {"label": "Gang Mills", "value": "C138575"},
              {"label": "Garden City", "value": "C138576"},
              {"label": "Garden City Park", "value": "C138577"},
              {"label": "Garden City South", "value": "C138578"},
              {"label": "Gardnertown", "value": "C138579"},
              {"label": "Gasport", "value": "C138580"},
              {"label": "Gates-North Gates", "value": "C138581"},
              {"label": "Genesee County", "value": "C138582"},
              {"label": "Geneseo", "value": "C138583"},
              {"label": "Geneva", "value": "C138584"},
              {"label": "Glasco", "value": "C138585"},
              {"label": "Glen Cove", "value": "C138586"},
              {"label": "Glen Head", "value": "C138587"},
              {"label": "Glen Oaks", "value": "C138588"},
              {"label": "Glendale", "value": "C138589"},
              {"label": "Glens Falls", "value": "C138590"},
              {"label": "Glens Falls North", "value": "C138591"},
              {"label": "Glenwood Landing", "value": "C138592"},
              {"label": "Gloversville", "value": "C138593"},
              {"label": "Goldens Bridge", "value": "C138594"},
              {"label": "Gordon Heights", "value": "C138595"},
              {"label": "Goshen", "value": "C138596"},
              {"label": "Gouverneur", "value": "C138597"},
              {"label": "Gowanda", "value": "C138598"},
              {"label": "Gramercy Park", "value": "C138599"},
              {"label": "Grand Island", "value": "C138600"},
              {"label": "Grandyle Village", "value": "C138601"},
              {"label": "Graniteville", "value": "C138602"},
              {"label": "Grant City", "value": "C138603"},
              {"label": "Granville", "value": "C138604"},
              {"label": "Gravesend", "value": "C138605"},
              {"label": "Great Kills", "value": "C138606"},
              {"label": "Great Neck", "value": "C138607"},
              {"label": "Great Neck Estates", "value": "C138608"},
              {"label": "Great Neck Gardens", "value": "C138609"},
              {"label": "Great Neck Plaza", "value": "C138610"},
              {"label": "Great River", "value": "C138611"},
              {"label": "Greece", "value": "C138612"},
              {"label": "Green Island", "value": "C138613"},
              {"label": "Greenburgh", "value": "C138614"},
              {"label": "Greene", "value": "C138615"},
              {"label": "Greene County", "value": "C138616"},
              {"label": "Greenlawn", "value": "C138617"},
              {"label": "Greenpoint", "value": "C138618"},
              {"label": "Greenport", "value": "C138619"},
              {"label": "Greenport West", "value": "C138620"},
              {"label": "Greenvale", "value": "C138621"},
              {"label": "Greenville", "value": "C138622"},
              {"label": "Greenwich", "value": "C138623"},
              {"label": "Greenwood Lake", "value": "C138624"},
              {"label": "Groton", "value": "C138625"},
              {"label": "Grymes Hill", "value": "C138626"},
              {"label": "Hadley", "value": "C138627"},
              {"label": "Hagaman", "value": "C138628"},
              {"label": "Halesite", "value": "C138629"},
              {"label": "Hamburg", "value": "C138630"},
              {"label": "Hamilton", "value": "C138631"},
              {"label": "Hamilton County", "value": "C138632"},
              {"label": "Hamlin", "value": "C138633"},
              {"label": "Hampton Bays", "value": "C138634"},
              {"label": "Hampton Manor", "value": "C138635"},
              {"label": "Hannawa Falls", "value": "C138636"},
              {"label": "Harbor Isle", "value": "C138637"},
              {"label": "Harlem", "value": "C138638"},
              {"label": "Harriman", "value": "C138639"},
              {"label": "Harris Hill", "value": "C138640"},
              {"label": "Harrison", "value": "C138641"},
              {"label": "Hartford", "value": "C138642"},
              {"label": "Hartsdale", "value": "C138643"},
              {"label": "Hastings-on-Hudson", "value": "C138644"},
              {"label": "Hauppauge", "value": "C138645"},
              {"label": "Haverstraw", "value": "C138646"},
              {"label": "Haviland", "value": "C138647"},
              {"label": "Hawthorne", "value": "C138648"},
              {"label": "Head of the Harbor", "value": "C138649"},
              {"label": "Hells Kitchen", "value": "C138650"},
              {"label": "Hempstead", "value": "C138651"},
              {"label": "Heritage Hills", "value": "C138652"},
              {"label": "Herkimer", "value": "C138653"},
              {"label": "Herkimer County", "value": "C138654"},
              {"label": "Herricks", "value": "C138655"},
              {"label": "Hewlett", "value": "C138656"},
              {"label": "Hewlett Harbor", "value": "C138657"},
              {"label": "Hicksville", "value": "C138658"},
              {"label": "Highland", "value": "C138659"},
              {"label": "Highland Falls", "value": "C138660"},
              {"label": "Highland Mills", "value": "C138661"},
              {"label": "Hillcrest", "value": "C138662"},
              {"label": "Hillside", "value": "C138663"},
              {"label": "Hillside Lake", "value": "C138664"},
              {"label": "Hilton", "value": "C138665"},
              {"label": "Holbrook", "value": "C138666"},
              {"label": "Holcomb", "value": "C138667"},
              {"label": "Holland", "value": "C138668"},
              {"label": "Holley", "value": "C138669"},
              {"label": "Hollis", "value": "C138670"},
              {"label": "Holtsville", "value": "C138671"},
              {"label": "Homer", "value": "C138672"},
              {"label": "Honeoye Falls", "value": "C138673"},
              {"label": "Hoosick Falls", "value": "C138674"},
              {"label": "Hornell", "value": "C138675"},
              {"label": "Horseheads", "value": "C138676"},
              {"label": "Horseheads North", "value": "C138677"},
              {"label": "Houghton", "value": "C138678"},
              {"label": "Howard Beach", "value": "C138679"},
              {"label": "Hudson", "value": "C138680"},
              {"label": "Hudson Falls", "value": "C138681"},
              {"label": "Huguenot", "value": "C138682"},
              {"label": "Huntington", "value": "C138683"},
              {"label": "Huntington Bay", "value": "C138684"},
              {"label": "Huntington Station", "value": "C138685"},
              {"label": "Hunts Point", "value": "C138686"},
              {"label": "Hurley", "value": "C138687"},
              {"label": "Hyde Park", "value": "C138688"},
              {"label": "Ilion", "value": "C138689"},
              {"label": "Inwood", "value": "C138690"},
              {"label": "Irondequoit", "value": "C138691"},
              {"label": "Irvington", "value": "C138692"},
              {"label": "Island Park", "value": "C138693"},
              {"label": "Islandia", "value": "C138694"},
              {"label": "Islip", "value": "C138695"},
              {"label": "Islip Terrace", "value": "C138696"},
              {"label": "Ithaca", "value": "C138697"},
              {"label": "Jackson Heights", "value": "C138698"},
              {"label": "Jamaica", "value": "C138699"},
              {"label": "Jamesport", "value": "C138700"},
              {"label": "Jamestown", "value": "C138701"},
              {"label": "Jamestown West", "value": "C138702"},
              {"label": "Jefferson County", "value": "C138703"},
              {"label": "Jefferson Heights", "value": "C138704"},
              {"label": "Jefferson Valley-Yorktown", "value": "C138705"},
              {"label": "Jericho", "value": "C138706"},
              {"label": "Johnson City", "value": "C138707"},
              {"label": "Johnstown", "value": "C138708"},
              {"label": "Jordan", "value": "C138709"},
              {"label": "Kaser", "value": "C138710"},
              {"label": "Katonah", "value": "C138711"},
              {"label": "Keeseville", "value": "C138712"},
              {"label": "Kenmore", "value": "C138713"},
              {"label": "Kensington", "value": "C138714"},
              {"label": "Kerhonkson", "value": "C138715"},
              {"label": "Keuka Park", "value": "C138716"},
              {"label": "Kew Gardens", "value": "C138717"},
              {"label": "Kew Gardens Hills", "value": "C138718"},
              {"label": "Kiantone", "value": "C138719"},
              {"label": "Kinderhook", "value": "C138720"},
              {"label": "Kings Bridge", "value": "C138721"},
              {"label": "Kings County", "value": "C138722"},
              {"label": "Kings Park", "value": "C138723"},
              {"label": "Kings Point", "value": "C138724"},
              {"label": "Kingston", "value": "C138725"},
              {"label": "Kiryas Joel", "value": "C138726"},
              {"label": "Lackawanna", "value": "C138727"},
              {"label": "Lake Carmel", "value": "C138728"},
              {"label": "Lake Erie Beach", "value": "C138729"},
              {"label": "Lake Grove", "value": "C138730"},
              {"label": "Lake Katrine", "value": "C138731"},
              {"label": "Lake Luzerne", "value": "C138732"},
              {"label": "Lake Mohegan", "value": "C138733"},
              {"label": "Lake Placid", "value": "C138734"},
              {"label": "Lake Pleasant", "value": "C138735"},
              {"label": "Lake Ronkonkoma", "value": "C138736"},
              {"label": "Lake Success", "value": "C138737"},
              {"label": "Lakeland", "value": "C138738"},
              {"label": "Lakeview", "value": "C138739"},
              {"label": "Lakewood", "value": "C138740"},
              {"label": "Lancaster", "value": "C138741"},
              {"label": "Lansing", "value": "C138742"},
              {"label": "Larchmont", "value": "C138743"},
              {"label": "Latham", "value": "C138744"},
              {"label": "Lattingtown", "value": "C138745"},
              {"label": "Laurel", "value": "C138746"},
              {"label": "Laurel Hollow", "value": "C138747"},
              {"label": "Laurelton", "value": "C138748"},
              {"label": "Lawrence", "value": "C138749"},
              {"label": "Le Roy", "value": "C138750"},
              {"label": "Levittown", "value": "C138751"},
              {"label": "Lewis County", "value": "C138752"},
              {"label": "Lewiston", "value": "C138753"},
              {"label": "Liberty", "value": "C138754"},
              {"label": "Lido Beach", "value": "C138755"},
              {"label": "Lima", "value": "C138756"},
              {"label": "Lincoln Park", "value": "C138757"},
              {"label": "Lincolndale", "value": "C138758"},
              {"label": "Lindenhurst", "value": "C138759"},
              {"label": "Lindley", "value": "C138760"},
              {"label": "Little Falls", "value": "C138761"},
              {"label": "Little Neck", "value": "C138762"},
              {"label": "Little Valley", "value": "C138763"},
              {"label": "Liverpool", "value": "C138764"},
              {"label": "Livingston County", "value": "C138765"},
              {"label": "Livingston Manor", "value": "C138766"},
              {"label": "Livonia", "value": "C138767"},
              {"label": "Lloyd Harbor", "value": "C138768"},
              {"label": "Lockport", "value": "C138769"},
              {"label": "Locust Valley", "value": "C138770"},
              {"label": "Long Beach", "value": "C138771"},
              {"label": "Long Island City", "value": "C138772"},
              {"label": "Lorenz Park", "value": "C138773"},
              {"label": "Lowville", "value": "C138774"},
              {"label": "Lynbrook", "value": "C138775"},
              {"label": "Lyncourt", "value": "C138776"},
              {"label": "Lyons", "value": "C138777"},
              {"label": "Macedon", "value": "C138778"},
              {"label": "Madison County", "value": "C138779"},
              {"label": "Mahopac", "value": "C138780"},
              {"label": "Malone", "value": "C138781"},
              {"label": "Malverne", "value": "C138782"},
              {"label": "Mamaroneck", "value": "C138783"},
              {"label": "Manchester", "value": "C138784"},
              {"label": "Manhasset", "value": "C138785"},
              {"label": "Manhasset Hills", "value": "C138786"},
              {"label": "Manhattan", "value": "C138787"},
              {"label": "Manlius", "value": "C138788"},
              {"label": "Manorhaven", "value": "C138789"},
              {"label": "Manorville", "value": "C138790"},
              {"label": "Marbletown", "value": "C138791"},
              {"label": "Marcellus", "value": "C138792"},
              {"label": "Mariners Harbor", "value": "C138793"},
              {"label": "Marion", "value": "C138794"},
              {"label": "Marlboro", "value": "C138795"},
              {"label": "Maspeth", "value": "C138796"},
              {"label": "Massapequa", "value": "C138797"},
              {"label": "Massapequa Park", "value": "C138798"},
              {"label": "Massena", "value": "C138799"},
              {"label": "Mastic", "value": "C138800"},
              {"label": "Mastic Beach", "value": "C138801"},
              {"label": "Mattituck", "value": "C138802"},
              {"label": "Mattydale", "value": "C138803"},
              {"label": "Maybrook", "value": "C138804"},
              {"label": "Mayville", "value": "C138805"},
              {"label": "McGraw", "value": "C138806"},
              {"label": "McKownville", "value": "C138807"},
              {"label": "Mechanicstown", "value": "C138808"},
              {"label": "Mechanicville", "value": "C138809"},
              {"label": "Medford", "value": "C138810"},
              {"label": "Medina", "value": "C138811"},
              {"label": "Melrose", "value": "C138812"},
              {"label": "Melrose Park", "value": "C138813"},
              {"label": "Melville", "value": "C138814"},
              {"label": "Menands", "value": "C138815"},
              {"label": "Merrick", "value": "C138816"},
              {"label": "Merritt Park", "value": "C138817"},
              {"label": "Mexico", "value": "C138818"},
              {"label": "Middle Island", "value": "C138819"},
              {"label": "Middle Village", "value": "C138820"},
              {"label": "Middleburgh", "value": "C138821"},
              {"label": "Middleport", "value": "C138822"},
              {"label": "Middletown", "value": "C138823"},
              {"label": "Midland Beach", "value": "C138824"},
              {"label": "Mill Neck", "value": "C138825"},
              {"label": "Millbrook", "value": "C138826"},
              {"label": "Miller Place", "value": "C138827"},
              {"label": "Milton", "value": "C138828"},
              {"label": "Mineola", "value": "C138829"},
              {"label": "Minetto", "value": "C138830"},
              {"label": "Mineville", "value": "C138831"},
              {"label": "Minoa", "value": "C138832"},
              {"label": "Mohawk", "value": "C138833"},
              {"label": "Monroe", "value": "C138834"},
              {"label": "Monroe County", "value": "C138835"},
              {"label": "Monsey", "value": "C138836"},
              {"label": "Montauk", "value": "C138837"},
              {"label": "Montebello", "value": "C138838"},
              {"label": "Montgomery", "value": "C138839"},
              {"label": "Montgomery County", "value": "C138840"},
              {"label": "Monticello", "value": "C138841"},
              {"label": "Montour Falls", "value": "C138842"},
              {"label": "Montrose", "value": "C138843"},
              {"label": "Moravia", "value": "C138844"},
              {"label": "Moriches", "value": "C138845"},
              {"label": "Morningside Heights", "value": "C138846"},
              {"label": "Morris Heights", "value": "C138847"},
              {"label": "Morris Park", "value": "C138848"},
              {"label": "Morrisania", "value": "C138849"},
              {"label": "Morrisonville", "value": "C138850"},
              {"label": "Morrisville", "value": "C138851"},
              {"label": "Mott Haven", "value": "C138852"},
              {"label": "Mount Ivy", "value": "C138853"},
              {"label": "Mount Kisco", "value": "C138854"},
              {"label": "Mount Morris", "value": "C138855"},
              {"label": "Mount Sinai", "value": "C138856"},
              {"label": "Mount Vernon", "value": "C138857"},
              {"label": "Mountain Lodge Park", "value": "C138858"},
              {"label": "Munsey Park", "value": "C138859"},
              {"label": "Munsons Corners", "value": "C138860"},
              {"label": "Muttontown", "value": "C138861"},
              {"label": "Myers Corner", "value": "C138862"},
              {"label": "Nanuet", "value": "C138863"},
              {"label": "Napanoch", "value": "C138864"},
              {"label": "Naples", "value": "C138865"},
              {"label": "Nassau", "value": "C138866"},
              {"label": "Nassau County", "value": "C138867"},
              {"label": "Nedrow", "value": "C138868"},
              {"label": "Neponsit", "value": "C138869"},
              {"label": "Nesconset", "value": "C138870"},
              {"label": "New Brighton", "value": "C138871"},
              {"label": "New Cassel", "value": "C138872"},
              {"label": "New City", "value": "C138873"},
              {"label": "New Dorp", "value": "C138874"},
              {"label": "New Dorp Beach", "value": "C138875"},
              {"label": "New Hartford", "value": "C138876"},
              {"label": "New Hempstead", "value": "C138877"},
              {"label": "New Hyde Park", "value": "C138878"},
              {"label": "New Paltz", "value": "C138879"},
              {"label": "New Rochelle", "value": "C138880"},
              {"label": "New Springville", "value": "C138881"},
              {"label": "New Square", "value": "C138882"},
              {"label": "New Windsor", "value": "C138883"},
              {"label": "New York City", "value": "C138884"},
              {"label": "New York County", "value": "C138885"},
              {"label": "New York Mills", "value": "C138886"},
              {"label": "Newark", "value": "C138887"},
              {"label": "Newburgh", "value": "C138888"},
              {"label": "Newfane", "value": "C138889"},
              {"label": "Niagara County", "value": "C138890"},
              {"label": "Niagara Falls", "value": "C138891"},
              {"label": "Niskayuna", "value": "C138892"},
              {"label": "Nissequogue", "value": "C138893"},
              {"label": "Niverville", "value": "C138894"},
              {"label": "Norfolk", "value": "C138895"},
              {"label": "North Amityville", "value": "C138896"},
              {"label": "North Babylon", "value": "C138897"},
              {"label": "North Ballston Spa", "value": "C138898"},
              {"label": "North Bay Shore", "value": "C138899"},
              {"label": "North Bellmore", "value": "C138900"},
              {"label": "North Bellport", "value": "C138901"},
              {"label": "North Boston", "value": "C138902"},
              {"label": "North Castle", "value": "C138903"},
              {"label": "North Collins", "value": "C138904"},
              {"label": "North Elba", "value": "C138905"},
              {"label": "North Gates", "value": "C138906"},
              {"label": "North Great River", "value": "C138907"},
              {"label": "North Hills", "value": "C138908"},
              {"label": "North Lindenhurst", "value": "C138909"},
              {"label": "North Massapequa", "value": "C138910"},
              {"label": "North Merrick", "value": "C138911"},
              {"label": "North New Hyde Park", "value": "C138912"},
              {"label": "North Patchogue", "value": "C138913"},
              {"label": "North Sea", "value": "C138914"},
              {"label": "North Syracuse", "value": "C138915"},
              {"label": "North Tonawanda", "value": "C138916"},
              {"label": "North Valley Stream", "value": "C138917"},
              {"label": "North Wantagh", "value": "C138918"},
              {"label": "Northeast Ithaca", "value": "C138919"},
              {"label": "Northport", "value": "C138920"},
              {"label": "Northumberland", "value": "C138921"},
              {"label": "Northville", "value": "C138922"},
              {"label": "Northwest Harbor", "value": "C138923"},
              {"label": "Northwest Ithaca", "value": "C138924"},
              {"label": "Norwich", "value": "C138925"},
              {"label": "Norwood", "value": "C138926"},
              {"label": "Noyack", "value": "C138927"},
              {"label": "Nunda", "value": "C138928"},
              {"label": "Nyack", "value": "C138929"},
              {"label": "Oakdale", "value": "C138930"},
              {"label": "Oakfield", "value": "C138931"},
              {"label": "Oakwood", "value": "C138932"},
              {"label": "Oceanside", "value": "C138933"},
              {"label": "Ogdensburg", "value": "C138934"},
              {"label": "Olcott", "value": "C138935"},
              {"label": "Old Bethpage", "value": "C138936"},
              {"label": "Old Brookville", "value": "C138937"},
              {"label": "Old Westbury", "value": "C138938"},
              {"label": "Olean", "value": "C138939"},
              {"label": "Oneida", "value": "C138940"},
              {"label": "Oneida County", "value": "C138941"},
              {"label": "Oneonta", "value": "C138942"},
              {"label": "Onondaga County", "value": "C138943"},
              {"label": "Ontario", "value": "C138944"},
              {"label": "Ontario County", "value": "C138945"},
              {"label": "Orange County", "value": "C138946"},
              {"label": "Orange Lake", "value": "C138947"},
              {"label": "Orangeburg", "value": "C138948"},
              {"label": "Orchard Park", "value": "C138949"},
              {"label": "Oriskany", "value": "C138950"},
              {"label": "Orleans County", "value": "C138951"},
              {"label": "Ossining", "value": "C138952"},
              {"label": "Oswego", "value": "C138953"},
              {"label": "Oswego County", "value": "C138954"},
              {"label": "Otisville", "value": "C138955"},
              {"label": "Otsego County", "value": "C138956"},
              {"label": "Owego", "value": "C138957"},
              {"label": "Oxford", "value": "C138958"},
              {"label": "Oyster Bay", "value": "C138959"},
              {"label": "Oyster Bay Cove", "value": "C138960"},
              {"label": "Ozone Park", "value": "C138961"},
              {"label": "Painted Post", "value": "C138962"},
              {"label": "Palenville", "value": "C138963"},
              {"label": "Palmyra", "value": "C138964"},
              {"label": "Park Slope", "value": "C138965"},
              {"label": "Parkchester", "value": "C138966"},
              {"label": "Patchogue", "value": "C138967"},
              {"label": "Pawling", "value": "C138968"},
              {"label": "Peach Lake", "value": "C138969"},
              {"label": "Pearl River", "value": "C138970"},
              {"label": "Peekskill", "value": "C138971"},
              {"label": "Pelham", "value": "C138972"},
              {"label": "Pelham Manor", "value": "C138973"},
              {"label": "Penn Yan", "value": "C138974"},
              {"label": "Perry", "value": "C138975"},
              {"label": "Perth", "value": "C138976"},
              {"label": "Peru", "value": "C138977"},
              {"label": "Phelps", "value": "C138978"},
              {"label": "Philadelphia", "value": "C138979"},
              {"label": "Philmont", "value": "C138980"},
              {"label": "Phoenix", "value": "C138981"},
              {"label": "Piermont", "value": "C138982"},
              {"label": "Pine Bush", "value": "C138983"},
              {"label": "Pine Plains", "value": "C138984"},
              {"label": "Pittsford", "value": "C138985"},
              {"label": "Plainedge", "value": "C138986"},
              {"label": "Plainview", "value": "C138987"},
              {"label": "Plandome", "value": "C138988"},
              {"label": "Plandome Heights", "value": "C138989"},
              {"label": "Plattekill", "value": "C138990"},
              {"label": "Plattsburgh", "value": "C138991"},
              {"label": "Plattsburgh West", "value": "C138992"},
              {"label": "Pleasant Valley", "value": "C138993"},
              {"label": "Pleasantville", "value": "C138994"},
              {"label": "Poestenkill", "value": "C138995"},
              {"label": "Point Lookout", "value": "C138996"},
              {"label": "Pomona", "value": "C138997"},
              {"label": "Port Byron", "value": "C138998"},
              {"label": "Port Chester", "value": "C138999"},
              {"label": "Port Dickinson", "value": "C139000"},
              {"label": "Port Ewen", "value": "C139001"},
              {"label": "Port Henry", "value": "C139002"},
              {"label": "Port Jefferson", "value": "C139003"},
              {"label": "Port Jefferson Station", "value": "C139004"},
              {"label": "Port Jervis", "value": "C139005"},
              {"label": "Port Morris", "value": "C139006"},
              {"label": "Port Richmond", "value": "C139007"},
              {"label": "Port Washington", "value": "C139008"},
              {"label": "Port Washington North", "value": "C139009"},
              {"label": "Portland", "value": "C139010"},
              {"label": "Potsdam", "value": "C139011"},
              {"label": "Poughkeepsie", "value": "C139012"},
              {"label": "Pound Ridge", "value": "C139013"},
              {"label": "Pulaski", "value": "C139014"},
              {"label": "Purchase", "value": "C139015"},
              {"label": "Putnam County", "value": "C139016"},
              {"label": "Putnam Lake", "value": "C139017"},
              {"label": "Queens", "value": "C139018"},
              {"label": "Queens County", "value": "C139019"},
              {"label": "Queens Village", "value": "C139020"},
              {"label": "Queensbury", "value": "C139021"},
              {"label": "Randolph", "value": "C139022"},
              {"label": "Ransomville", "value": "C139023"},
              {"label": "Rapids", "value": "C139024"},
              {"label": "Ravena", "value": "C139025"},
              {"label": "Red Hook", "value": "C139026"},
              {"label": "Red Oaks Mill", "value": "C139027"},
              {"label": "Rego Park", "value": "C139028"},
              {"label": "Remsenburg-Speonk", "value": "C139029"},
              {"label": "Rensselaer", "value": "C139030"},
              {"label": "Rensselaer County", "value": "C139031"},
              {"label": "Rhinebeck", "value": "C139032"},
              {"label": "Richfield Springs", "value": "C139033"},
              {"label": "Richland", "value": "C139034"},
              {"label": "Richmond County", "value": "C139035"},
              {"label": "Richmond Hill", "value": "C139036"},
              {"label": "Ridge", "value": "C139037"},
              {"label": "Ridgewood", "value": "C139038"},
              {"label": "Riverdale", "value": "C139039"},
              {"label": "Riverhead", "value": "C139040"},
              {"label": "Riverside", "value": "C139041"},
              {"label": "Rochester", "value": "C139042"},
              {"label": "Rock Hill", "value": "C139043"},
              {"label": "Rockaway Point", "value": "C139044"},
              {"label": "Rockland County", "value": "C139045"},
              {"label": "Rockville Centre", "value": "C139046"},
              {"label": "Rocky Point", "value": "C139047"},
              {"label": "Roessleville", "value": "C139048"},
              {"label": "Rome", "value": "C139049"},
              {"label": "Ronkonkoma", "value": "C139050"},
              {"label": "Roosevelt", "value": "C139051"},
              {"label": "Rosebank", "value": "C139052"},
              {"label": "Rosedale", "value": "C139053"},
              {"label": "Rosendale Village", "value": "C139054"},
              {"label": "Roslyn", "value": "C139055"},
              {"label": "Roslyn Estates", "value": "C139056"},
              {"label": "Roslyn Harbor", "value": "C139057"},
              {"label": "Roslyn Heights", "value": "C139058"},
              {"label": "Rossville", "value": "C139059"},
              {"label": "Rotterdam", "value": "C139060"},
              {"label": "Rouses Point", "value": "C139061"},
              {"label": "Rye", "value": "C139062"},
              {"label": "Rye Brook", "value": "C139063"},
              {"label": "Sackets Harbor", "value": "C139064"},
              {"label": "Sag Harbor", "value": "C139065"},
              {"label": "Saint Bonaventure", "value": "C139066"},
              {"label": "Saint James", "value": "C139067"},
              {"label": "Saint Johnsville", "value": "C139068"},
              {"label": "Salamanca", "value": "C139069"},
              {"label": "Salisbury", "value": "C139070"},
              {"label": "Sanborn", "value": "C139071"},
              {"label": "Sands Point", "value": "C139072"},
              {"label": "Saranac Lake", "value": "C139073"},
              {"label": "Saratoga County", "value": "C139074"},
              {"label": "Saratoga Springs", "value": "C139075"},
              {"label": "Saugerties", "value": "C139076"},
              {"label": "Saugerties South", "value": "C139077"},
              {"label": "Sayville", "value": "C139078"},
              {"label": "Scarsdale", "value": "C139079"},
              {"label": "Schenectady", "value": "C139080"},
              {"label": "Schenectady County", "value": "C139081"},
              {"label": "Schoharie", "value": "C139082"},
              {"label": "Schoharie County", "value": "C139083"},
              {"label": "Schuyler County", "value": "C139084"},
              {"label": "Schuylerville", "value": "C139085"},
              {"label": "Scotchtown", "value": "C139086"},
              {"label": "Scotia", "value": "C139087"},
              {"label": "Scottsville", "value": "C139088"},
              {"label": "Sea Cliff", "value": "C139089"},
              {"label": "Seaford", "value": "C139090"},
              {"label": "Searingtown", "value": "C139091"},
              {"label": "Seaside", "value": "C139092"},
              {"label": "Selden", "value": "C139093"},
              {"label": "Seneca County", "value": "C139094"},
              {"label": "Seneca Falls", "value": "C139095"},
              {"label": "Seneca Knolls", "value": "C139096"},
              {"label": "Setauket-East Setauket", "value": "C139097"},
              {"label": "Sheepshead Bay", "value": "C139098"},
              {"label": "Shelter Island", "value": "C139099"},
              {"label": "Shelter Island Heights", "value": "C139100"},
              {"label": "Shenorock", "value": "C139101"},
              {"label": "Sherburne", "value": "C139102"},
              {"label": "Sherrill", "value": "C139103"},
              {"label": "Shinnecock Hills", "value": "C139104"},
              {"label": "Shirley", "value": "C139105"},
              {"label": "Shokan", "value": "C139106"},
              {"label": "Shortsville", "value": "C139107"},
              {"label": "Shrub Oak", "value": "C139108"},
              {"label": "Sidney", "value": "C139109"},
              {"label": "Silver Creek", "value": "C139110"},
              {"label": "Skaneateles", "value": "C139111"},
              {"label": "Sleepy Hollow", "value": "C139112"},
              {"label": "Sloan", "value": "C139113"},
              {"label": "Sloatsburg", "value": "C139114"},
              {"label": "Smithtown", "value": "C139115"},
              {"label": "Sodus", "value": "C139116"},
              {"label": "Solvay", "value": "C139117"},
              {"label": "Sound Beach", "value": "C139118"},
              {"label": "South Beach", "value": "C139119"},
              {"label": "South Blooming Grove", "value": "C139120"},
              {"label": "South Corning", "value": "C139121"},
              {"label": "South Fallsburg", "value": "C139122"},
              {"label": "South Farmingdale", "value": "C139123"},
              {"label": "South Floral Park", "value": "C139124"},
              {"label": "South Glens Falls", "value": "C139125"},
              {"label": "South Hempstead", "value": "C139126"},
              {"label": "South Hill", "value": "C139127"},
              {"label": "South Huntington", "value": "C139128"},
              {"label": "South Lockport", "value": "C139129"},
              {"label": "South Nyack", "value": "C139130"},
              {"label": "South Valley Stream", "value": "C139131"},
              {"label": "Southampton", "value": "C139132"},
              {"label": "Southold", "value": "C139133"},
              {"label": "Southport", "value": "C139134"},
              {"label": "Spackenkill", "value": "C139135"},
              {"label": "Sparkill", "value": "C139136"},
              {"label": "Spencerport", "value": "C139137"},
              {"label": "Spring Valley", "value": "C139138"},
              {"label": "Springfield", "value": "C139139"},
              {"label": "Springfield Gardens", "value": "C139140"},
              {"label": "Springs", "value": "C139141"},
              {"label": "Springville", "value": "C139142"},
              {"label": "Spuyten Duyvil", "value": "C139143"},
              {"label": "St. Lawrence County", "value": "C139144"},
              {"label": "Stamford", "value": "C139145"},
              {"label": "Stapleton", "value": "C139146"},
              {"label": "Staten Island", "value": "C139147"},
              {"label": "Steinway", "value": "C139148"},
              {"label": "Steuben County", "value": "C139149"},
              {"label": "Stewart Manor", "value": "C139150"},
              {"label": "Stillwater", "value": "C139151"},
              {"label": "Stone Ridge", "value": "C139152"},
              {"label": "Stony Brook", "value": "C139153"},
              {"label": "Stony Point", "value": "C139154"},
              {"label": "Stottville", "value": "C139155"},
              {"label": "Suffern", "value": "C139156"},
              {"label": "Suffolk County", "value": "C139157"},
              {"label": "Sullivan County", "value": "C139158"},
              {"label": "Sunnyside", "value": "C139159"},
              {"label": "Sunset Park", "value": "C139160"},
              {"label": "Sylvan Beach", "value": "C139161"},
              {"label": "Syosset", "value": "C139162"},
              {"label": "Syracuse", "value": "C139163"},
              {"label": "Tappan", "value": "C139164"},
              {"label": "Tarrytown", "value": "C139165"},
              {"label": "Terrace Heights", "value": "C139166"},
              {"label": "Terryville", "value": "C139167"},
              {"label": "The Bronx", "value": "C139168"},
              {"label": "Thiells", "value": "C139169"},
              {"label": "Thomaston", "value": "C139170"},
              {"label": "Thornwood", "value": "C139171"},
              {"label": "Throgs Neck", "value": "C139172"},
              {"label": "Ticonderoga", "value": "C139173"},
              {"label": "Tillson", "value": "C139174"},
              {"label": "Tioga County", "value": "C139175"},
              {"label": "Tivoli", "value": "C139176"},
              {"label": "Tompkins County", "value": "C139177"},
              {"label": "Tompkinsville", "value": "C139178"},
              {"label": "Tonawanda", "value": "C139179"},
              {"label": "Town Line", "value": "C139180"},
              {"label": "Tremont", "value": "C139181"},
              {"label": "Tribes Hill", "value": "C139182"},
              {"label": "Troy", "value": "C139183"},
              {"label": "Trumansburg", "value": "C139184"},
              {"label": "Tuckahoe", "value": "C139185"},
              {"label": "Tupper Lake", "value": "C139186"},
              {"label": "Ulster County", "value": "C139187"},
              {"label": "Unadilla", "value": "C139188"},
              {"label": "Union Springs", "value": "C139189"},
              {"label": "Uniondale", "value": "C139190"},
              {"label": "Unionport", "value": "C139191"},
              {"label": "University Gardens", "value": "C139192"},
              {"label": "University Heights", "value": "C139193"},
              {"label": "Upper Brookville", "value": "C139194"},
              {"label": "Upper Nyack", "value": "C139195"},
              {"label": "Utica", "value": "C139196"},
              {"label": "Vails Gate", "value": "C139197"},
              {"label": "Valatie", "value": "C139198"},
              {"label": "Valhalla", "value": "C139199"},
              {"label": "Valley Cottage", "value": "C139200"},
              {"label": "Valley Stream", "value": "C139201"},
              {"label": "Van Nest", "value": "C139202"},
              {"label": "Vernon", "value": "C139203"},
              {"label": "Verplanck", "value": "C139204"},
              {"label": "Victor", "value": "C139205"},
              {"label": "Village Green", "value": "C139206"},
              {"label": "Village of the Branch", "value": "C139207"},
              {"label": "Viola", "value": "C139208"},
              {"label": "Volney", "value": "C139209"},
              {"label": "Voorheesville", "value": "C139210"},
              {"label": "Wading River", "value": "C139211"},
              {"label": "Wakefield", "value": "C139212"},
              {"label": "Walden", "value": "C139213"},
              {"label": "Wallkill", "value": "C139214"},
              {"label": "Walton", "value": "C139215"},
              {"label": "Walton Park", "value": "C139216"},
              {"label": "Wampsville", "value": "C139217"},
              {"label": "Wanakah", "value": "C139218"},
              {"label": "Wantagh", "value": "C139219"},
              {"label": "Wappingers Falls", "value": "C139220"},
              {"label": "Warren County", "value": "C139221"},
              {"label": "Warrensburg", "value": "C139222"},
              {"label": "Warsaw", "value": "C139223"},
              {"label": "Warwick", "value": "C139224"},
              {"label": "Washington County", "value": "C139225"},
              {"label": "Washington Heights", "value": "C139226"},
              {"label": "Washington Mills", "value": "C139227"},
              {"label": "Washingtonville", "value": "C139228"},
              {"label": "Watchtower", "value": "C139229"},
              {"label": "Water Mill", "value": "C139230"},
              {"label": "Waterford", "value": "C139231"},
              {"label": "Waterloo", "value": "C139232"},
              {"label": "Watertown", "value": "C139233"},
              {"label": "Waterville", "value": "C139234"},
              {"label": "Watervliet", "value": "C139235"},
              {"label": "Watkins Glen", "value": "C139236"},
              {"label": "Waverly", "value": "C139237"},
              {"label": "Wawarsing", "value": "C139238"},
              {"label": "Wayland", "value": "C139239"},
              {"label": "Wayne County", "value": "C139240"},
              {"label": "Webster", "value": "C139241"},
              {"label": "Weedsport", "value": "C139242"},
              {"label": "Wellsville", "value": "C139243"},
              {"label": "Wesley Hills", "value": "C139244"},
              {"label": "West Albany", "value": "C139245"},
              {"label": "West Babylon", "value": "C139246"},
              {"label": "West Bay Shore", "value": "C139247"},
              {"label": "West Carthage", "value": "C139248"},
              {"label": "West Elmira", "value": "C139249"},
              {"label": "West End", "value": "C139250"},
              {"label": "West Glens Falls", "value": "C139251"},
              {"label": "West Haverstraw", "value": "C139252"},
              {"label": "West Hempstead", "value": "C139253"},
              {"label": "West Hills", "value": "C139254"},
              {"label": "West Hurley", "value": "C139255"},
              {"label": "West Islip", "value": "C139256"},
              {"label": "West Nyack", "value": "C139257"},
              {"label": "West Point", "value": "C139258"},
              {"label": "West Sand Lake", "value": "C139259"},
              {"label": "West Sayville", "value": "C139260"},
              {"label": "West Seneca", "value": "C139261"},
              {"label": "Westbury", "value": "C139262"},
              {"label": "Westchester County", "value": "C139263"},
              {"label": "Westerleigh", "value": "C139264"},
              {"label": "Westfield", "value": "C139265"},
              {"label": "Westhampton", "value": "C139266"},
              {"label": "Westhampton Beach", "value": "C139267"},
              {"label": "Westmere", "value": "C139268"},
              {"label": "Weston Mills", "value": "C139269"},
              {"label": "Westvale", "value": "C139270"},
              {"label": "Wheatley Heights", "value": "C139271"},
              {"label": "White Plains", "value": "C139272"},
              {"label": "Whitehall", "value": "C139273"},
              {"label": "Whitesboro", "value": "C139274"},
              {"label": "Whitestone", "value": "C139275"},
              {"label": "Williamsburg", "value": "C139276"},
              {"label": "Williamson", "value": "C139277"},
              {"label": "Williamsville", "value": "C139278"},
              {"label": "Williston Park", "value": "C139279"},
              {"label": "Willowbrook", "value": "C139280"},
              {"label": "Wilson", "value": "C139281"},
              {"label": "Wolcott", "value": "C139282"},
              {"label": "Woodbury", "value": "C139283"},
              {"label": "Woodhaven", "value": "C139284"},
              {"label": "Woodlawn", "value": "C139285"},
              {"label": "Woodmere", "value": "C139286"},
              {"label": "Woodrow", "value": "C139287"},
              {"label": "Woodside", "value": "C139288"},
              {"label": "Woodstock", "value": "C139289"},
              {"label": "Worcester", "value": "C139290"},
              {"label": "Wurtsboro", "value": "C139291"},
              {"label": "Wyandanch", "value": "C139292"},
              {"label": "Wykagyl", "value": "C139293"},
              {"label": "Wynantskill", "value": "C139294"},
              {"label": "Wyoming County", "value": "C139295"},
              {"label": "Yaphank", "value": "C139296"},
              {"label": "Yates County", "value": "C139297"},
              {"label": "Yonkers", "value": "C139298"},
              {"label": "Yorkshire", "value": "C139299"},
              {"label": "Yorktown Heights", "value": "C139300"},
              {"label": "Yorkville", "value": "C139301"},
              {"label": "Youngstown", "value": "C139302"},
              {"label": "Zena", "value": "C139303"}],
 "Newfoundland and Labrador": [{"label": "Bay Roberts", "value": "C17038"},
                               {"label": "Bay St. George South",
                                "value": "C17039"},
                               {"label": "Bonavista", "value": "C17040"},
                               {"label": "Botwood", "value": "C17041"},
                               {"label": "Burgeo", "value": "C17042"},
                               {"label": "Carbonear", "value": "C17043"},
                               {"label": "Catalina", "value": "C17044"},
                               {"label": "Channel-Port aux Basques",
                                "value": "C17045"},
                               {"label": "Clarenville-Shoal Harbour",
                                "value": "C17046"},
                               {"label": "Conception Bay South",
                                "value": "C17047"},
                               {"label": "Corner Brook", "value": "C17048"},
                               {"label": "Deer Lake", "value": "C17049"},
                               {"label": "Fogo Island", "value": "C17050"},
                               {"label": "Gambo", "value": "C17051"},
                               {"label": "Goulds", "value": "C17052"},
                               {"label": "Grand Bank", "value": "C17053"},
                               {"label": "Grand Falls-Windsor",
                                "value": "C17054"},
                               {"label": "Happy Valley-Goose Bay",
                                "value": "C17055"},
                               {"label": "Harbour Breton", "value": "C17056"},
                               {"label": "Labrador City", "value": "C17057"},
                               {"label": "Lewisporte", "value": "C17058"},
                               {"label": "Marystown", "value": "C17059"},
                               {"label": "Mount Pearl", "value": "C17060"},
                               {"label": "Pasadena", "value": "C17061"},
                               {"label": "Springdale", "value": "C17062"},
                               {"label": "St. Anthony", "value": "C17063"},
                               {"label": "St. Johns", "value": "C17064"},
                               {"label": "Stephenville", "value": "C17065"},
                               {"label": "Stephenville Crossing",
                                "value": "C17066"},
                               {"label": "Torbay", "value": "C17067"},
                               {"label": "Upper Island Cove",
                                "value": "C17068"},
                               {"label": "Wabana", "value": "C17069"}],
 "Ngaraard": [{"label": "Ulimang", "value": "C79778"}],
 "Ngarchelong": [{"label": "Mengellang", "value": "C79779"}],
 "Ngardmau": [{"label": "Ngardmau", "value": "C79780"}],
 "Ngchesar": [{"label": "Ngchesar Hamlet", "value": "C79781"}],
 "Ngeremlengui": [{"label": "Imeong Hamlet", "value": "C79782"}],
 "Nghe An": [{"label": "Huyen Anh Son", "value": "C147531"},
             {"label": "Huyen Con Cuong", "value": "C147532"},
             {"label": "Huyen Dien Chau", "value": "C147533"},
             {"label": "Huyen Do Luong", "value": "C147534"},
             {"label": "Huyen Hung Nguyen", "value": "C147535"},
             {"label": "Huyen Ky Son", "value": "C147536"},
             {"label": "Huyen Nam Dan", "value": "C147537"},
             {"label": "Huyen Nghi Loc", "value": "C147538"},
             {"label": "Huyen Nghia Dan", "value": "C147539"},
             {"label": "Huyen Que Phong", "value": "C147540"},
             {"label": "Huyen Quy Chau", "value": "C147541"},
             {"label": "Huyen Quy Hop", "value": "C147542"},
             {"label": "Huyen Quynh Luu", "value": "C147543"},
             {"label": "Huyen Thanh Chuong", "value": "C147544"},
             {"label": "Huyen Tuong Duong", "value": "C147545"},
             {"label": "Huyen Yen Thanh", "value": "C147546"},
             {"label": "Vinh", "value": "C147547"},
             {"label": "Yen Vinh", "value": "C147548"}],
 "Ngiwal": [{"label": "Ngerkeai", "value": "C79783"}],
 "Ngobe-Bugle Comarca": [{"label": "Bahia Azul", "value": "C80164"},
                         {"label": "Besiko", "value": "C80165"},
                         {"label": "Bisira", "value": "C80166"},
                         {"label": "Boca de Balsa", "value": "C80167"},
                         {"label": "Buenos Aires", "value": "C80168"},
                         {"label": "Camaron Arriba", "value": "C80169"},
                         {"label": "Cerro Banco", "value": "C80170"},
                         {"label": "Cerro Cana", "value": "C80171"},
                         {"label": "Cerro Plata", "value": "C80172"},
                         {"label": "Chichica", "value": "C80173"},
                         {"label": "Guayabito", "value": "C80174"},
                         {"label": "Hato Chami", "value": "C80175"},
                         {"label": "Hato Corotu", "value": "C80176"},
                         {"label": "Kankintu", "value": "C80177"},
                         {"label": "Kusapin", "value": "C80178"},
                         {"label": "Lajero Arriba", "value": "C80179"},
                         {"label": "Llano nopo", "value": "C80180"},
                         {"label": "Loma Yuca", "value": "C80181"},
                         {"label": "Mirono", "value": "C80182"},
                         {"label": "Muna", "value": "C80183"},
                         {"label": "Nole Duima", "value": "C80184"},
                         {"label": "Nurun", "value": "C80185"},
                         {"label": "Oma", "value": "C80186"},
                         {"label": "Paredon Arriba", "value": "C80187"},
                         {"label": "Plan de Chorcha", "value": "C80188"},
                         {"label": "Pueblo Nuevo", "value": "C80189"},
                         {"label": "Quebrada Canoa", "value": "C80190"},
                         {"label": "Quebrada Tula", "value": "C80191"},
                         {"label": "Sitio Prado", "value": "C80192"},
                         {"label": "Tobobe", "value": "C80193"}],
 "Ngounie Province": [{"label": "Fougamou", "value": "C34131"},
                      {"label": "Mbigou", "value": "C34132"},
                      {"label": "Mimongo", "value": "C34133"},
                      {"label": "Mouila", "value": "C34134"},
                      {"label": "Ndende", "value": "C34135"}],
 "Ngozi Province": [{"label": "Ngozi", "value": "C16455"}],
 "Niari Department": [{"label": "Dolisie", "value": "C20754"},
                      {"label": "Mossendjo", "value": "C20755"}],
 "Niassa Province": [{"label": "Cuamba", "value": "C75880"},
                     {"label": "Lichinga", "value": "C75881"},
                     {"label": "Mandimba", "value": "C75882"}],
 "Nica Municipality": [{"label": "Nica", "value": "C64905"}],
 "Nickerie District": [{"label": "Nieuw Nickerie", "value": "C115467"},
                       {"label": "Wageningen", "value": "C115468"}],
 "Nicosia District (Lefkosa)": [{"label": "Akaki", "value": "C21913"},
                                {"label": "Alampra", "value": "C21914"},
                                {"label": "Arediou", "value": "C21915"},
                                {"label": "Astromeritis", "value": "C21916"},
                                {"label": "Dali", "value": "C21917"},
                                {"label": "Ergates", "value": "C21918"},
                                {"label": "Geri", "value": "C21919"},
                                {"label": "Kakopetria", "value": "C21920"},
                                {"label": "Kato Deftera", "value": "C21921"},
                                {"label": "Kato Pyrgos", "value": "C21922"},
                                {"label": "Klirou", "value": "C21923"},
                                {"label": "Kokkinotrimithia",
                                 "value": "C21924"},
                                {"label": "Lefka", "value": "C21925"},
                                {"label": "Lympia", "value": "C21926"},
                                {"label": "Lythrodontas", "value": "C21927"},
                                {"label": "Mammari", "value": "C21928"},
                                {"label": "Meniko", "value": "C21929"},
                                {"label": "Morfou", "value": "C21930"},
                                {"label": "Nicosia", "value": "C21931"},
                                {"label": "Nicosia Municipality",
                                 "value": "C21932"},
                                {"label": "Pano Deftera", "value": "C21933"},
                                {"label": "Pera", "value": "C21934"},
                                {"label": "Peristerona", "value": "C21935"},
                                {"label": "Psimolofou", "value": "C21936"},
                                {"label": "Tseri", "value": "C21937"}],
 "Nidwalden": [{"label": "Beckenried", "value": "C116931"},
               {"label": "Buochs", "value": "C116932"},
               {"label": "Dallenwil", "value": "C116933"},
               {"label": "Emmetten", "value": "C116934"},
               {"label": "Ennetburgen", "value": "C116935"},
               {"label": "Hergiswil", "value": "C116936"},
               {"label": "Stans", "value": "C116937"},
               {"label": "Stansstad", "value": "C116938"},
               {"label": "Wolfenschiessen", "value": "C116939"}],
 "Nigde": [{"label": "Altunhisar", "value": "C120842"},
           {"label": "Bor", "value": "C120843"},
           {"label": "Camardi Ilcesi", "value": "C120844"},
           {"label": "Ciftlik", "value": "C120845"},
           {"label": "Nigde", "value": "C120846"},
           {"label": "Ulukisla", "value": "C120847"}],
 "Niger": [{"label": "Auna", "value": "C78395"},
           {"label": "Babana", "value": "C78396"},
           {"label": "Badeggi", "value": "C78397"},
           {"label": "Baro", "value": "C78398"},
           {"label": "Bokani", "value": "C78399"},
           {"label": "Duku", "value": "C78400"},
           {"label": "Ibeto", "value": "C78401"},
           {"label": "Konkwesso", "value": "C78402"},
           {"label": "Kontagora", "value": "C78403"},
           {"label": "Kusheriki", "value": "C78404"},
           {"label": "Kuta", "value": "C78405"},
           {"label": "Lapai", "value": "C78406"},
           {"label": "Minna", "value": "C78407"},
           {"label": "New Shagunnu", "value": "C78408"},
           {"label": "Suleja", "value": "C78409"},
           {"label": "Tegina", "value": "C78410"},
           {"label": "Ukata", "value": "C78411"},
           {"label": "Wawa", "value": "C78412"},
           {"label": "Zungeru", "value": "C78413"}],
 "Niigata Prefecture": [{"label": "Agano Shi", "value": "C63607"},
                        {"label": "Arai", "value": "C63608"},
                        {"label": "Gosen", "value": "C63609"},
                        {"label": "Gosen Shi", "value": "C63610"},
                        {"label": "Itoigawa", "value": "C63611"},
                        {"label": "Itoigawa Shi", "value": "C63612"},
                        {"label": "Joetsu", "value": "C63613"},
                        {"label": "Joetsu Shi", "value": "C63614"},
                        {"label": "Kameda-honcho", "value": "C63615"},
                        {"label": "Kamo", "value": "C63616"},
                        {"label": "Kamo Shi", "value": "C63617"},
                        {"label": "Kashiwazaki", "value": "C63618"},
                        {"label": "Kashiwazaki Shi", "value": "C63619"},
                        {"label": "Maki", "value": "C63620"},
                        {"label": "Minamiuonuma Shi", "value": "C63621"},
                        {"label": "Mitsuke", "value": "C63622"},
                        {"label": "Mitsuke Shi", "value": "C63623"},
                        {"label": "Muikamachi", "value": "C63624"},
                        {"label": "Murakami Shi", "value": "C63625"},
                        {"label": "Muramatsu", "value": "C63626"},
                        {"label": "Myoko-shi", "value": "C63627"},
                        {"label": "Nagaoka", "value": "C63628"},
                        {"label": "Nagaoka Shi", "value": "C63629"},
                        {"label": "Niigata", "value": "C63630"},
                        {"label": "Niigata Shi", "value": "C63631"},
                        {"label": "Niitsu-honcho", "value": "C63632"},
                        {"label": "Ojiya", "value": "C63633"},
                        {"label": "Ojiya-shi", "value": "C63634"},
                        {"label": "Ryotsu-minato", "value": "C63635"},
                        {"label": "Sado Shi", "value": "C63636"},
                        {"label": "Sanjo Shi", "value": "C63637"},
                        {"label": "Shibata", "value": "C63638"},
                        {"label": "Shibata Shi", "value": "C63639"},
                        {"label": "Shiozawa", "value": "C63640"},
                        {"label": "Shirone", "value": "C63641"},
                        {"label": "Suibara", "value": "C63642"},
                        {"label": "Tainai", "value": "C63643"},
                        {"label": "Tainai Shi", "value": "C63644"},
                        {"label": "Tochio-honcho", "value": "C63645"},
                        {"label": "Tokamachi", "value": "C63646"},
                        {"label": "Tokamachi-shi", "value": "C63647"},
                        {"label": "Tsubame", "value": "C63648"},
                        {"label": "Tsubame Shi", "value": "C63649"},
                        {"label": "Uonuma", "value": "C63650"},
                        {"label": "Uonuma Shi", "value": "C63651"},
                        {"label": "Yoshida-kasugacho", "value": "C63652"}],
 "Niksic Municipality": [{"label": "Niksic", "value": "C75612"}],
 "Nimba": [{"label": "Ganta", "value": "C65014"},
           {"label": "New Yekepa", "value": "C65015"},
           {"label": "Sanniquellie", "value": "C65016"}],
 "Nimruz": [{"label": "Khash", "value": "C68"},
            {"label": "Mirabad", "value": "C69"},
            {"label": "Rudbar", "value": "C70"},
            {"label": "Zaranj", "value": "C71"}],
 "Nineveh Governorate": [{"label": "Aqrah", "value": "C51192"},
                         {"label": "Al Mawsil al Jadidah", "value": "C51193"},
                         {"label": "Al-Hamdaniya", "value": "C51194"},
                         {"label": "Ash Shaykhan", "value": "C51195"},
                         {"label": "Mosul", "value": "C51196"},
                         {"label": "Sinjar", "value": "C51197"},
                         {"label": "Tall Afar", "value": "C51198"},
                         {"label": "Tallkayf", "value": "C51199"}],
 "Ningxia Huizu": [{"label": "Dawukou", "value": "C19103"},
                   {"label": "Dongta", "value": "C19104"},
                   {"label": "Shitanjing", "value": "C19105"},
                   {"label": "Shizuishan", "value": "C19106"},
                   {"label": "Wuzhong", "value": "C19107"},
                   {"label": "Yinchuan", "value": "C19108"},
                   {"label": "Zhongwei", "value": "C19109"}],
 "Ninh Binh": [{"label": "Huyen Hoa Lu", "value": "C147549"},
               {"label": "Huyen Kim Son", "value": "C147550"},
               {"label": "Huyen Nho Quan", "value": "C147551"},
               {"label": "Huyen Yen Khanh", "value": "C147552"},
               {"label": "Huyen Yen Mo", "value": "C147553"},
               {"label": "Ninh Binh", "value": "C147554"}],
 "Ninh Thuan": [{"label": "Phan Rang-Thap Cham", "value": "C147555"}],
 "Nippes": [{"label": "Ansavo", "value": "C43108"},
            {"label": "Baraderes", "value": "C43109"},
            {"label": "Miragoane", "value": "C43110"},
            {"label": "Petit Trou de Nippes", "value": "C43111"}],
 "Nisporeni District": [{"label": "Nisporeni", "value": "C75525"}],
 "Nitra Region": [{"label": "Hurbanovo", "value": "C107412"},
                  {"label": "Kolarovo", "value": "C107413"},
                  {"label": "Komarno", "value": "C107414"},
                  {"label": "Levice", "value": "C107415"},
                  {"label": "Nitra", "value": "C107416"},
                  {"label": "Nove Zamky", "value": "C107417"},
                  {"label": "Okres Komarno", "value": "C107418"},
                  {"label": "Okres Levice", "value": "C107419"},
                  {"label": "Okres Nitra", "value": "C107420"},
                  {"label": "Okres Nove Zamky", "value": "C107421"},
                  {"label": "Okres Sala", "value": "C107422"},
                  {"label": "Okres Topolcany", "value": "C107423"},
                  {"label": "Okres Zlate Moravce", "value": "C107424"},
                  {"label": "Sahy", "value": "C107425"},
                  {"label": "Sala", "value": "C107426"},
                  {"label": "Sturovo", "value": "C107427"},
                  {"label": "Surany", "value": "C107428"},
                  {"label": "Svodin", "value": "C107429"},
                  {"label": "Tlmace", "value": "C107430"},
                  {"label": "Topolcany", "value": "C107431"},
                  {"label": "Vrable", "value": "C107432"},
                  {"label": "Zeliezovce", "value": "C107433"},
                  {"label": "Zlate Moravce", "value": "C107434"}],
 "Niuas": [{"label": "Hihifo", "value": "C119649"}],
 "Niutao Island Council": [{"label": "Kulia Village", "value": "C121195"},
                           {"label": "Niulakita", "value": "C121196"}],
 "Nizhny Novgorod Oblast": [{"label": "Afonino", "value": "C102665"},
                            {"label": "Arya", "value": "C102666"},
                            {"label": "Ardatov", "value": "C102667"},
                            {"label": "Ardatovskiy Rayon", "value": "C102668"},
                            {"label": "Arzamas", "value": "C102669"},
                            {"label": "Arzamasskiy Rayon", "value": "C102670"},
                            {"label": "Babino", "value": "C102671"},
                            {"label": "Blizhne-Pesochnoye", "value": "C102672"},
                            {"label": "Bogorodsk", "value": "C102673"},
                            {"label": "Bogorodskiy Rayon", "value": "C102674"},
                            {"label": "Bolsheboldinskiy Rayon",
                             "value": "C102675"},
                            {"label": "Bolshoye Boldino", "value": "C102676"},
                            {"label": "Bolshoye Kozino", "value": "C102677"},
                            {"label": "Bolshoye Murashkino",
                             "value": "C102678"},
                            {"label": "Bor", "value": "C102679"},
                            {"label": "Bor Urban Okrug", "value": "C102680"},
                            {"label": "Burevestnik", "value": "C102681"},
                            {"label": "Buturlino", "value": "C102682"},
                            {"label": "Chernukha", "value": "C102683"},
                            {"label": "Chistoye", "value": "C102684"},
                            {"label": "Chkalovsk", "value": "C102685"},
                            {"label": "Dalnekonstantinovskiy Rayon",
                             "value": "C102686"},
                            {"label": "Dalneye Konstantinovo",
                             "value": "C102687"},
                            {"label": "Diveyevo", "value": "C102688"},
                            {"label": "Doschatoye", "value": "C102689"},
                            {"label": "Dzerzhinsk", "value": "C102690"},
                            {"label": "Frolishchi", "value": "C102691"},
                            {"label": "Gagino", "value": "C102692"},
                            {"label": "Gidrotorf", "value": "C102693"},
                            {"label": "Gorbatov", "value": "C102694"},
                            {"label": "Gorbatovka", "value": "C102695"},
                            {"label": "Gorodets", "value": "C102696"},
                            {"label": "Gorodetskiy Rayon", "value": "C102697"},
                            {"label": "Gremyachevo", "value": "C102698"},
                            {"label": "Ilyinogorsk", "value": "C102699"},
                            {"label": "Imeni M. I. Kalinina",
                             "value": "C102700"},
                            {"label": "Imeni Stepana Razina",
                             "value": "C102701"},
                            {"label": "Knyaginino", "value": "C102702"},
                            {"label": "Kovernino", "value": "C102703"},
                            {"label": "Krasnaya Gorka", "value": "C102704"},
                            {"label": "Krasnooktyabrskiy Rayon",
                             "value": "C102705"},
                            {"label": "Krasnyye Baki", "value": "C102706"},
                            {"label": "Kstovo", "value": "C102707"},
                            {"label": "Kulebaki", "value": "C102708"},
                            {"label": "Linda", "value": "C102709"},
                            {"label": "Lukino", "value": "C102710"},
                            {"label": "Lukoyanov", "value": "C102711"},
                            {"label": "Lukoyanovskiy Rayon",
                             "value": "C102712"},
                            {"label": "Lyskovo", "value": "C102713"},
                            {"label": "Mukhtolovo", "value": "C102714"},
                            {"label": "Mulino", "value": "C102715"},
                            {"label": "Navashino", "value": "C102716"},
                            {"label": "Neklyudovo", "value": "C102717"},
                            {"label": "Nizhniy Novgorod", "value": "C102718"},
                            {"label": "Novaya Balakhna", "value": "C102719"},
                            {"label": "Novosmolinskiy", "value": "C102720"},
                            {"label": "Pamyat Parizhskoy Kommuny",
                             "value": "C102721"},
                            {"label": "Pavlovo", "value": "C102722"},
                            {"label": "Pavlovskiy Rayon", "value": "C102723"},
                            {"label": "Perevoz", "value": "C102724"},
                            {"label": "Pervomaysk", "value": "C102725"},
                            {"label": "Pervomayskiy", "value": "C102726"},
                            {"label": "Pervoye Maya", "value": "C102727"},
                            {"label": "Pilna", "value": "C102728"},
                            {"label": "Pilninskiy Rayon", "value": "C102729"},
                            {"label": "Pizhma", "value": "C102730"},
                            {"label": "Pochinki", "value": "C102731"},
                            {"label": "Pochinkovskiy Rayon",
                             "value": "C102732"},
                            {"label": "Podnovye", "value": "C102733"},
                            {"label": "Prudy", "value": "C102734"},
                            {"label": "Purekh", "value": "C102735"},
                            {"label": "Pyra", "value": "C102736"},
                            {"label": "Rabotki", "value": "C102737"},
                            {"label": "Reshetikha", "value": "C102738"},
                            {"label": "Sarov", "value": "C102739"},
                            {"label": "Satis", "value": "C102740"},
                            {"label": "Savasleyka", "value": "C102741"},
                            {"label": "Sechenovo", "value": "C102742"},
                            {"label": "Sechenovskiy Rayon", "value": "C102743"},
                            {"label": "Semenov", "value": "C102744"},
                            {"label": "Sergach", "value": "C102745"},
                            {"label": "Sergachskiy Rayon", "value": "C102746"},
                            {"label": "Shakhunya", "value": "C102747"},
                            {"label": "Sharanga", "value": "C102748"},
                            {"label": "Sharangskiy Rayon", "value": "C102749"},
                            {"label": "Shatki", "value": "C102750"},
                            {"label": "Shimorskoye", "value": "C102751"},
                            {"label": "Sitniki", "value": "C102752"},
                            {"label": "Smolino", "value": "C102753"},
                            {"label": "Sokolskoye", "value": "C102754"},
                            {"label": "Sosnovskiy Rayon", "value": "C102755"},
                            {"label": "Sosnovskoye", "value": "C102756"},
                            {"label": "Spasskiy Rayon", "value": "C102757"},
                            {"label": "Spasskoye", "value": "C102758"},
                            {"label": "Sukhobezvodnoye", "value": "C102759"},
                            {"label": "Surovatikha", "value": "C102760"},
                            {"label": "Syava", "value": "C102761"},
                            {"label": "Taremskoye", "value": "C102762"},
                            {"label": "Tonkino", "value": "C102763"},
                            {"label": "Tonshayevo", "value": "C102764"},
                            {"label": "Tsentralnyy", "value": "C102765"},
                            {"label": "Tumbotino", "value": "C102766"},
                            {"label": "Urazovka", "value": "C102767"},
                            {"label": "Uren", "value": "C102768"},
                            {"label": "Vacha", "value": "C102769"},
                            {"label": "Vachskiy Rayon", "value": "C102770"},
                            {"label": "Vad", "value": "C102771"},
                            {"label": "Vakhtan", "value": "C102772"},
                            {"label": "Varnavino", "value": "C102773"},
                            {"label": "Varnavinskiy Rayon", "value": "C102774"},
                            {"label": "Vasilsursk", "value": "C102775"},
                            {"label": "Vetluga", "value": "C102776"},
                            {"label": "Vetluzhskiy", "value": "C102777"},
                            {"label": "Vetluzhskiy Rayon", "value": "C102778"},
                            {"label": "Vilya", "value": "C102779"},
                            {"label": "Vladimirskoye", "value": "C102780"},
                            {"label": "Volodarsk", "value": "C102781"},
                            {"label": "Vorotynets", "value": "C102782"},
                            {"label": "Vorsma", "value": "C102783"},
                            {"label": "Voskresenskiy Rayon",
                             "value": "C102784"},
                            {"label": "Voskresenskoye", "value": "C102785"},
                            {"label": "Voznesenskoye", "value": "C102786"},
                            {"label": "Vyksa", "value": "C102787"},
                            {"label": "Vyyezdnoye", "value": "C102788"},
                            {"label": "Yuganets", "value": "C102789"},
                            {"label": "Zavolzhye", "value": "C102790"},
                            {"label": "Zelyony Gorod", "value": "C102791"}],
 "Njombe": [{"label": "Ilembula", "value": "C118253"},
            {"label": "Makumbako", "value": "C118254"},
            {"label": "Manda", "value": "C118255"},
            {"label": "Matamba", "value": "C118256"},
            {"label": "Mlangali", "value": "C118257"},
            {"label": "Mtwango", "value": "C118258"},
            {"label": "Njombe", "value": "C118259"}],
 "Nograd County": [{"label": "Balassagyarmat", "value": "C44392"},
                   {"label": "Balassagyarmati Jaras", "value": "C44393"},
                   {"label": "Batonyterenye", "value": "C44394"},
                   {"label": "Batonyterenyei Jaras", "value": "C44395"},
                   {"label": "Bercel", "value": "C44396"},
                   {"label": "Bujak", "value": "C44397"},
                   {"label": "Diosjeno", "value": "C44398"},
                   {"label": "ersekvadkert", "value": "C44399"},
                   {"label": "Hehalom", "value": "C44400"},
                   {"label": "Jobbagyi", "value": "C44401"},
                   {"label": "Karancskeszi", "value": "C44402"},
                   {"label": "Karancslapujto", "value": "C44403"},
                   {"label": "Kazar", "value": "C44404"},
                   {"label": "Matranovak", "value": "C44405"},
                   {"label": "Matraterenye", "value": "C44406"},
                   {"label": "Matraverebely", "value": "C44407"},
                   {"label": "Nagyoroszi", "value": "C44408"},
                   {"label": "Palotas", "value": "C44409"},
                   {"label": "Paszto", "value": "C44410"},
                   {"label": "Pasztoi Jaras", "value": "C44411"},
                   {"label": "Retsag", "value": "C44412"},
                   {"label": "Retsagi Jaras", "value": "C44413"},
                   {"label": "Rimoc", "value": "C44414"},
                   {"label": "Romhany", "value": "C44415"},
                   {"label": "Salgotarjan", "value": "C44416"},
                   {"label": "Salgotarjani Jaras", "value": "C44417"},
                   {"label": "Somoskoujfalu", "value": "C44418"},
                   {"label": "Szecseny", "value": "C44419"},
                   {"label": "Szecsenyi Jaras", "value": "C44420"},
                   {"label": "Szurdokpuspoki", "value": "C44421"},
                   {"label": "Tar", "value": "C44422"}],
 "Nong Bua Lam Phu": [{"label": "Amphoe Mueang Nong Bua Lamphu",
                       "value": "C118965"},
                      {"label": "Amphoe Na Klang", "value": "C118966"},
                      {"label": "Amphoe Na Wang", "value": "C118967"},
                      {"label": "Amphoe Non Sang", "value": "C118968"},
                      {"label": "Amphoe Si Bun Rueang", "value": "C118969"},
                      {"label": "Amphoe Suwannakhuha", "value": "C118970"},
                      {"label": "Na Klang", "value": "C118971"},
                      {"label": "Non Sang", "value": "C118972"},
                      {"label": "Nong Bua Lamphu", "value": "C118973"},
                      {"label": "Si Bun Rueang", "value": "C118974"}],
 "Nong Khai": [{"label": "Amphoe Fao Rai", "value": "C118975"},
               {"label": "Amphoe Mueang Nong Khai", "value": "C118976"},
               {"label": "Amphoe Pho Tak", "value": "C118977"},
               {"label": "Amphoe Phonphisai", "value": "C118978"},
               {"label": "Amphoe Rattanawapi", "value": "C118979"},
               {"label": "Amphoe Sa Khrai", "value": "C118980"},
               {"label": "Amphoe Sangkhom", "value": "C118981"},
               {"label": "Amphoe Si Chiang Mai", "value": "C118982"},
               {"label": "Amphoe Tha Bo", "value": "C118983"},
               {"label": "Nong Khai", "value": "C118984"},
               {"label": "Phon Charoen", "value": "C118985"},
               {"label": "Sangkhom", "value": "C118986"},
               {"label": "Si Chiang Mai", "value": "C118987"},
               {"label": "Tha Bo", "value": "C118988"}],
 "Nonthaburi": [{"label": "Amphoe Bang Bua Thong", "value": "C118989"},
                {"label": "Amphoe Bang Kruai", "value": "C118990"},
                {"label": "Amphoe Bang Yai", "value": "C118991"},
                {"label": "Amphoe Mueang Nonthaburi", "value": "C118992"},
                {"label": "Amphoe Pak Kret", "value": "C118993"},
                {"label": "Amphoe Sai Noi", "value": "C118994"},
                {"label": "Bang Bua Thong", "value": "C118995"},
                {"label": "Bang Kruai", "value": "C118996"},
                {"label": "Bang Yai", "value": "C118997"},
                {"label": "Mueang Nonthaburi", "value": "C118998"},
                {"label": "Pak Kret", "value": "C118999"}],
 "Noonu Atoll": [{"label": "Manadhoo", "value": "C65816"}],
 "Nord": [{"label": "Acul du Nord", "value": "C43112"},
          {"label": "Arrondissement de la Grande Riviere du Nord",
           "value": "C43113"},
          {"label": "Arrondissement de Plaisance", "value": "C43114"},
          {"label": "Arrondissement du Borgne", "value": "C43115"},
          {"label": "Bahon", "value": "C43116"},
          {"label": "Borgne", "value": "C43117"},
          {"label": "Dondon", "value": "C43118"},
          {"label": "Grande Riviere du Nord", "value": "C43119"},
          {"label": "Lenbe", "value": "C43120"},
          {"label": "Limonade", "value": "C43121"},
          {"label": "Milot", "value": "C43122"},
          {"label": "Okap", "value": "C43123"},
          {"label": "Pignon", "value": "C43124"},
          {"label": "Pilate", "value": "C43125"},
          {"label": "Plaine du Nord", "value": "C43126"},
          {"label": "Plaisance", "value": "C43127"},
          {"label": "Port-Margot", "value": "C43128"},
          {"label": "Quartier Morin", "value": "C43129"},
          {"label": "Ranquitte", "value": "C43130"},
          {"label": "Saint-Raphael", "value": "C43131"}],
 "Nord Region, Burkina Faso": [{"label": "Gourcy", "value": "C16414"},
                               {"label": "Ouahigouya", "value": "C16415"},
                               {"label": "Province du Loroum",
                                "value": "C16416"},
                               {"label": "Province du Passore",
                                "value": "C16417"},
                               {"label": "Province du Yatenga",
                                "value": "C16418"},
                               {"label": "Province du Zondoma",
                                "value": "C16419"},
                               {"label": "Titao", "value": "C16420"},
                               {"label": "Yako", "value": "C16421"}],
 "Nord-Est": [{"label": "Arrondissement de Fort Liberte", "value": "C43132"},
              {"label": "Arrondissement du Trou du Nord", "value": "C43133"},
              {"label": "Caracol", "value": "C43134"},
              {"label": "Carice", "value": "C43135"},
              {"label": "Derac", "value": "C43136"},
              {"label": "Ferrier", "value": "C43137"},
              {"label": "Fort Liberte", "value": "C43138"},
              {"label": "Montorganize", "value": "C43139"},
              {"label": "Ouanaminthe", "value": "C43140"},
              {"label": "Perches", "value": "C43141"},
              {"label": "Phaeton", "value": "C43142"},
              {"label": "Trou du Nord", "value": "C43143"},
              {"label": "Wanament", "value": "C43144"}],
 "Nord-Kivu": [{"label": "Beni", "value": "C23356"},
               {"label": "Butembo", "value": "C23357"},
               {"label": "Goma", "value": "C23358"},
               {"label": "Sake", "value": "C23359"}],
 "Nord-Ouest": [{"label": "Arcahaie", "value": "C43145"},
                {"label": "Arrondissement de Port-de-Paix", "value": "C43146"},
                {"label": "Arrondissement de Saint-Louis du Nord",
                 "value": "C43147"},
                {"label": "Arrondissement du Mole Saint-Nicolas",
                 "value": "C43148"},
                {"label": "Baie de Henne", "value": "C43149"},
                {"label": "Bombardopolis", "value": "C43150"},
                {"label": "Fond Bassin Bleu", "value": "C43151"},
                {"label": "Jean-Rabel", "value": "C43152"},
                {"label": "Mole Saint-Nicolas", "value": "C43153"},
                {"label": "Petite Anse", "value": "C43154"},
                {"label": "Port-de-Paix", "value": "C43155"},
                {"label": "Saint-Louis du Nord", "value": "C43156"},
                {"label": "Ti Port-de-Paix", "value": "C43157"}],
 "Nord-Ubangi": [{"label": "Bosobolo", "value": "C23360"},
                 {"label": "Businga", "value": "C23361"},
                 {"label": "Gbadolite", "value": "C23362"}],
 "Nordland": [{"label": "Alstahaug", "value": "C78870"},
              {"label": "Andenes", "value": "C78871"},
              {"label": "Andoy", "value": "C78872"},
              {"label": "Ballangen", "value": "C78873"},
              {"label": "Beiarn", "value": "C78874"},
              {"label": "Bindal", "value": "C78875"},
              {"label": "Bjerkvik", "value": "C78876"},
              {"label": "Bodo", "value": "C78877"},
              {"label": "Bogen", "value": "C78878"},
              {"label": "Bo", "value": "C78879"},
              {"label": "Bronnoy", "value": "C78880"},
              {"label": "Bronnoysund", "value": "C78881"},
              {"label": "Donna", "value": "C78882"},
              {"label": "Evenes", "value": "C78883"},
              {"label": "Evjen", "value": "C78884"},
              {"label": "Fauske", "value": "C78885"},
              {"label": "Flakstad", "value": "C78886"},
              {"label": "Gildeskal", "value": "C78887"},
              {"label": "Gladstad", "value": "C78888"},
              {"label": "Glomfjord", "value": "C78889"},
              {"label": "Grane", "value": "C78890"},
              {"label": "Gravdal", "value": "C78891"},
              {"label": "Hadsel", "value": "C78892"},
              {"label": "Hamaroy", "value": "C78893"},
              {"label": "Hattfjelldal", "value": "C78894"},
              {"label": "Hauknes", "value": "C78895"},
              {"label": "Hemnes", "value": "C78896"},
              {"label": "Hemnesberget", "value": "C78897"},
              {"label": "Heroy", "value": "C78898"},
              {"label": "Inndyr", "value": "C78899"},
              {"label": "Kabelvag", "value": "C78900"},
              {"label": "Kjopsvik", "value": "C78901"},
              {"label": "Korgen", "value": "C78902"},
              {"label": "Leirfjord", "value": "C78903"},
              {"label": "Leknes", "value": "C78904"},
              {"label": "Leland", "value": "C78905"},
              {"label": "Loding", "value": "C78906"},
              {"label": "Lodingen", "value": "C78907"},
              {"label": "Lopsmarka", "value": "C78908"},
              {"label": "Luroy", "value": "C78909"},
              {"label": "Melbu", "value": "C78910"},
              {"label": "Meloy", "value": "C78911"},
              {"label": "Mo i Rana", "value": "C78912"},
              {"label": "Mosjoen", "value": "C78913"},
              {"label": "Moskenes", "value": "C78914"},
              {"label": "Myre", "value": "C78915"},
              {"label": "Narvik", "value": "C78916"},
              {"label": "Nesna", "value": "C78917"},
              {"label": "Oksnes", "value": "C78918"},
              {"label": "Ornes", "value": "C78919"},
              {"label": "Rana", "value": "C78920"},
              {"label": "Reine", "value": "C78921"},
              {"label": "Rognan", "value": "C78922"},
              {"label": "Rodoy", "value": "C78923"},
              {"label": "Rost", "value": "C78924"},
              {"label": "Saltdal", "value": "C78925"},
              {"label": "Sandnessjoen", "value": "C78926"},
              {"label": "Sortland", "value": "C78927"},
              {"label": "Somna", "value": "C78928"},
              {"label": "Sorfold", "value": "C78929"},
              {"label": "Sorland", "value": "C78930"},
              {"label": "Steigen", "value": "C78931"},
              {"label": "Stokmarknes", "value": "C78932"},
              {"label": "Straume", "value": "C78933"},
              {"label": "Straumen", "value": "C78934"},
              {"label": "Svolvaer", "value": "C78935"},
              {"label": "Terrak", "value": "C78936"},
              {"label": "Tjeldsund", "value": "C78937"},
              {"label": "Traena", "value": "C78938"},
              {"label": "Trofors", "value": "C78939"},
              {"label": "Tysfjord", "value": "C78940"},
              {"label": "Vagan", "value": "C78941"},
              {"label": "Vaeroy", "value": "C78942"},
              {"label": "Vefsn", "value": "C78943"},
              {"label": "Vega", "value": "C78944"},
              {"label": "Vestvagoy", "value": "C78945"},
              {"label": "Vevelstad", "value": "C78946"},
              {"label": "Vik", "value": "C78947"}],
 "Normandie": [{"label": "Ablon", "value": "C30604"},
               {"label": "Acquigny", "value": "C30605"},
               {"label": "Agneaux", "value": "C30606"},
               {"label": "Agon-Coutainville", "value": "C30607"},
               {"label": "Alencon", "value": "C30608"},
               {"label": "Alizay", "value": "C30609"},
               {"label": "Amfreville", "value": "C30610"},
               {"label": "Amfreville-la-Mi-Voie", "value": "C30611"},
               {"label": "Ande", "value": "C30612"},
               {"label": "Angerville-lOrcher", "value": "C30613"},
               {"label": "Argences", "value": "C30614"},
               {"label": "Argentan", "value": "C30615"},
               {"label": "Arnieres-sur-Iton", "value": "C30616"},
               {"label": "Arques-la-Bataille", "value": "C30617"},
               {"label": "Athis-de-lOrne", "value": "C30618"},
               {"label": "Aube-sur-Rile", "value": "C30619"},
               {"label": "Aubevoye", "value": "C30620"},
               {"label": "Auffay", "value": "C30621"},
               {"label": "Aumale", "value": "C30622"},
               {"label": "Aunay-sur-Odon", "value": "C30623"},
               {"label": "Authie", "value": "C30624"},
               {"label": "Auzebosc", "value": "C30625"},
               {"label": "Aviron", "value": "C30626"},
               {"label": "Avranches", "value": "C30627"},
               {"label": "Bacqueville-en-Caux", "value": "C30628"},
               {"label": "Bagnoles-de-lOrne", "value": "C30629"},
               {"label": "Barentin", "value": "C30630"},
               {"label": "Barenton", "value": "C30631"},
               {"label": "Barneville-Carteret", "value": "C30632"},
               {"label": "Barneville-Plage", "value": "C30633"},
               {"label": "Bavent", "value": "C30634"},
               {"label": "Bayeux", "value": "C30635"},
               {"label": "Beaumont-Hague", "value": "C30636"},
               {"label": "Beaumont-le-Roger", "value": "C30637"},
               {"label": "Belbeuf", "value": "C30638"},
               {"label": "Belleme", "value": "C30639"},
               {"label": "Bellengreville", "value": "C30640"},
               {"label": "Benouville", "value": "C30641"},
               {"label": "Bernay", "value": "C30642"},
               {"label": "Berneval-le-Grand", "value": "C30643"},
               {"label": "Bernieres-sur-Mer", "value": "C30644"},
               {"label": "Beuville", "value": "C30645"},
               {"label": "Beuvillers", "value": "C30646"},
               {"label": "Beuzeville", "value": "C30647"},
               {"label": "Beuzeville-la-Grenier", "value": "C30648"},
               {"label": "Bezu-Saint-eloi", "value": "C30649"},
               {"label": "Bieville-Beuville", "value": "C30650"},
               {"label": "Bihorel", "value": "C30651"},
               {"label": "Blainville-Crevon", "value": "C30652"},
               {"label": "Blainville-sur-Mer", "value": "C30653"},
               {"label": "Blainville-sur-Orne", "value": "C30654"},
               {"label": "Blangy-sur-Bresle", "value": "C30655"},
               {"label": "Blonville-sur-Mer", "value": "C30656"},
               {"label": "Bois-Guillaume", "value": "C30657"},
               {"label": "Bolbec", "value": "C30658"},
               {"label": "Boos", "value": "C30659"},
               {"label": "Bosc-le-Hard", "value": "C30660"},
               {"label": "Bourg-Achard", "value": "C30661"},
               {"label": "Bourgtheroulde-Infreville", "value": "C30662"},
               {"label": "Bourguebus", "value": "C30663"},
               {"label": "Bourth", "value": "C30664"},
               {"label": "Bouville", "value": "C30665"},
               {"label": "Breaute", "value": "C30666"},
               {"label": "Brecey", "value": "C30667"},
               {"label": "Brehal", "value": "C30668"},
               {"label": "Breteuil", "value": "C30669"},
               {"label": "Bretoncelles", "value": "C30670"},
               {"label": "Bretteville", "value": "C30671"},
               {"label": "Bretteville-du-Grand-Caux", "value": "C30672"},
               {"label": "Bretteville-lOrgueilleuse", "value": "C30673"},
               {"label": "Bretteville-sur-Laize", "value": "C30674"},
               {"label": "Bretteville-sur-Odon", "value": "C30675"},
               {"label": "Breuilpont", "value": "C30676"},
               {"label": "Bricquebec", "value": "C30677"},
               {"label": "Brionne", "value": "C30678"},
               {"label": "Briouze", "value": "C30679"},
               {"label": "Brix", "value": "C30680"},
               {"label": "Broglie", "value": "C30681"},
               {"label": "Buchy", "value": "C30682"},
               {"label": "Bueil", "value": "C30683"},
               {"label": "Bully", "value": "C30684"},
               {"label": "Cabourg", "value": "C30685"},
               {"label": "Caen", "value": "C30686"},
               {"label": "Cagny", "value": "C30687"},
               {"label": "Cahagnes", "value": "C30688"},
               {"label": "Cairon", "value": "C30689"},
               {"label": "Calvados", "value": "C30690"},
               {"label": "Cambes-en-Plaine", "value": "C30691"},
               {"label": "Cambremer", "value": "C30692"},
               {"label": "Canon", "value": "C30693"},
               {"label": "Canteleu", "value": "C30694"},
               {"label": "Cany-Barville", "value": "C30695"},
               {"label": "Carentan", "value": "C30696"},
               {"label": "Carpiquet", "value": "C30697"},
               {"label": "Caudebec-en-Caux", "value": "C30698"},
               {"label": "Caudebec-les-Elbeuf", "value": "C30699"},
               {"label": "Caumont", "value": "C30700"},
               {"label": "Caumont-levente", "value": "C30701"},
               {"label": "Cauville-sur-Mer", "value": "C30702"},
               {"label": "Ceauce", "value": "C30703"},
               {"label": "Cerences", "value": "C30704"},
               {"label": "Cerisy-la-Salle", "value": "C30705"},
               {"label": "Ceton", "value": "C30706"},
               {"label": "Champsecret", "value": "C30707"},
               {"label": "Chanu", "value": "C30708"},
               {"label": "Charleval", "value": "C30709"},
               {"label": "Cherbourg-Octeville", "value": "C30710"},
               {"label": "Cheux", "value": "C30711"},
               {"label": "Claville", "value": "C30712"},
               {"label": "Clecy", "value": "C30713"},
               {"label": "Cleon", "value": "C30714"},
               {"label": "Cleres", "value": "C30715"},
               {"label": "Clinchamps-sur-Orne", "value": "C30716"},
               {"label": "Colleville-Montgomery", "value": "C30717"},
               {"label": "Colombelles", "value": "C30718"},
               {"label": "Conches-en-Ouche", "value": "C30719"},
               {"label": "Conde-sur-Huisne", "value": "C30720"},
               {"label": "Conde-sur-Noireau", "value": "C30721"},
               {"label": "Conde-sur-Sarthe", "value": "C30722"},
               {"label": "Conde-sur-Vire", "value": "C30723"},
               {"label": "Cormeilles", "value": "C30724"},
               {"label": "Cormelles-le-Royal", "value": "C30725"},
               {"label": "Corneville-sur-Risle", "value": "C30726"},
               {"label": "Courcelles-sur-Seine", "value": "C30727"},
               {"label": "Courseulles-sur-Mer", "value": "C30728"},
               {"label": "Courteilles", "value": "C30729"},
               {"label": "Coutances", "value": "C30730"},
               {"label": "Couterne", "value": "C30731"},
               {"label": "Creances", "value": "C30732"},
               {"label": "Cresserons", "value": "C30733"},
               {"label": "Creully", "value": "C30734"},
               {"label": "Criel-sur-Mer", "value": "C30735"},
               {"label": "Criquebeuf-sur-Seine", "value": "C30736"},
               {"label": "Criquetot-lEsneval", "value": "C30737"},
               {"label": "Croth", "value": "C30738"},
               {"label": "Cuverville", "value": "C30739"},
               {"label": "Damigny", "value": "C30740"},
               {"label": "Damville", "value": "C30741"},
               {"label": "Darnetal", "value": "C30742"},
               {"label": "Deauville", "value": "C30743"},
               {"label": "Demouville", "value": "C30744"},
               {"label": "Departement de lEure", "value": "C30745"},
               {"label": "Departement de lOrne", "value": "C30746"},
               {"label": "Deville-les-Rouen", "value": "C30747"},
               {"label": "Dieppe", "value": "C30748"},
               {"label": "Digosville", "value": "C30749"},
               {"label": "Dives-sur-Mer", "value": "C30750"},
               {"label": "Domfront", "value": "C30751"},
               {"label": "Donville-les-Bains", "value": "C30752"},
               {"label": "Doudeville", "value": "C30753"},
               {"label": "Douvres-la-Delivrande", "value": "C30754"},
               {"label": "Dozule", "value": "C30755"},
               {"label": "Ducey", "value": "C30756"},
               {"label": "Duclair", "value": "C30757"},
               {"label": "ecouche", "value": "C30758"},
               {"label": "ecrainville", "value": "C30759"},
               {"label": "Elbeuf", "value": "C30760"},
               {"label": "Envermeu", "value": "C30761"},
               {"label": "epaignes", "value": "C30762"},
               {"label": "epouville", "value": "C30763"},
               {"label": "epron", "value": "C30764"},
               {"label": "equemauville", "value": "C30765"},
               {"label": "equeurdreville-Hainneville", "value": "C30766"},
               {"label": "Eslettes", "value": "C30767"},
               {"label": "etainhus", "value": "C30768"},
               {"label": "eterville", "value": "C30769"},
               {"label": "etrepagny", "value": "C30770"},
               {"label": "etretat", "value": "C30771"},
               {"label": "Eu", "value": "C30772"},
               {"label": "evrecy", "value": "C30773"},
               {"label": "evreux", "value": "C30774"},
               {"label": "ezy-sur-Eure", "value": "C30775"},
               {"label": "Falaise", "value": "C30776"},
               {"label": "Fauville-en-Caux", "value": "C30777"},
               {"label": "Fecamp", "value": "C30778"},
               {"label": "Fermanville", "value": "C30779"},
               {"label": "Flamanville", "value": "C30780"},
               {"label": "Flers", "value": "C30781"},
               {"label": "Fleury-sur-Andelle", "value": "C30782"},
               {"label": "Fleury-sur-Orne", "value": "C30783"},
               {"label": "Fontaine-etoupefour", "value": "C30784"},
               {"label": "Fontaine-la-Mallet", "value": "C30785"},
               {"label": "Fontaine-le-Bourg", "value": "C30786"},
               {"label": "Fontaine-le-Dun", "value": "C30787"},
               {"label": "Fontenay", "value": "C30788"},
               {"label": "Fontenay-le-Marmion", "value": "C30789"},
               {"label": "Forges-les-Eaux", "value": "C30790"},
               {"label": "Foucarmont", "value": "C30791"},
               {"label": "Francheville", "value": "C30792"},
               {"label": "Gace", "value": "C30793"},
               {"label": "Gaillefontaine", "value": "C30794"},
               {"label": "Gaillon", "value": "C30795"},
               {"label": "Gainneville", "value": "C30796"},
               {"label": "Garennes-sur-Eure", "value": "C30797"},
               {"label": "Gasny", "value": "C30798"},
               {"label": "Gavray", "value": "C30799"},
               {"label": "Giberville", "value": "C30800"},
               {"label": "Gisors", "value": "C30801"},
               {"label": "Goderville", "value": "C30802"},
               {"label": "Gonneville-la-Mallet", "value": "C30803"},
               {"label": "Gournay-en-Bray", "value": "C30804"},
               {"label": "Gouville-sur-Mer", "value": "C30805"},
               {"label": "Grainville-la-Teinturiere", "value": "C30806"},
               {"label": "Grainville-sur-Odon", "value": "C30807"},
               {"label": "Grand-Couronne", "value": "C30808"},
               {"label": "Grandcamp-Maisy", "value": "C30809"},
               {"label": "Granville", "value": "C30810"},
               {"label": "Gravigny", "value": "C30811"},
               {"label": "Gruchet-le-Valasse", "value": "C30812"},
               {"label": "Guichainville", "value": "C30813"},
               {"label": "Hambye", "value": "C30814"},
               {"label": "Harfleur", "value": "C30815"},
               {"label": "Hautot-sur-Mer", "value": "C30816"},
               {"label": "Hauville", "value": "C30817"},
               {"label": "Henouville", "value": "C30818"},
               {"label": "Hermanville-sur-Mer", "value": "C30819"},
               {"label": "Herouville-Saint-Clair", "value": "C30820"},
               {"label": "Herouvillette", "value": "C30821"},
               {"label": "Heudreville-sur-Eure", "value": "C30822"},
               {"label": "Honfleur", "value": "C30823"},
               {"label": "Houlbec-Cocherel", "value": "C30824"},
               {"label": "Houlgate", "value": "C30825"},
               {"label": "Houppeville", "value": "C30826"},
               {"label": "Ifs", "value": "C30827"},
               {"label": "Igoville", "value": "C30828"},
               {"label": "Incheville", "value": "C30829"},
               {"label": "Isigny-le-Buat", "value": "C30830"},
               {"label": "Isigny-sur-Mer", "value": "C30831"},
               {"label": "Isneauville", "value": "C30832"},
               {"label": "Ivry-la-Bataille", "value": "C30833"},
               {"label": "Jullouville", "value": "C30834"},
               {"label": "Jumieges", "value": "C30835"},
               {"label": "Juvigny-sous-Andaine", "value": "C30836"},
               {"label": "La Bonneville-sur-Iton", "value": "C30837"},
               {"label": "La Cerlangue", "value": "C30838"},
               {"label": "La Chapelle-Reanville", "value": "C30839"},
               {"label": "La Couture-Boussey", "value": "C30840"},
               {"label": "La Ferriere-aux-etangs", "value": "C30841"},
               {"label": "La Ferte-Mace", "value": "C30842"},
               {"label": "La Feuillie", "value": "C30843"},
               {"label": "La Glacerie", "value": "C30844"},
               {"label": "La Haye-du-Puits", "value": "C30845"},
               {"label": "La Haye-Malherbe", "value": "C30846"},
               {"label": "La Haye-Pesnel", "value": "C30847"},
               {"label": "La Lande-Patry", "value": "C30848"},
               {"label": "La Madeleine-de-Nonancourt", "value": "C30849"},
               {"label": "La Mailleraye-sur-Seine", "value": "C30850"},
               {"label": "La Meauffe", "value": "C30851"},
               {"label": "La Remuee", "value": "C30852"},
               {"label": "La Riviere-Saint-Sauveur", "value": "C30853"},
               {"label": "La Saussaye", "value": "C30854"},
               {"label": "La Selle-la-Forge", "value": "C30855"},
               {"label": "La Vaupaliere", "value": "C30856"},
               {"label": "La Vespiere", "value": "C30857"},
               {"label": "Langrune-sur-Mer", "value": "C30858"},
               {"label": "Lanquetot", "value": "C30859"},
               {"label": "Le Bosc-Roger-en-Roumois", "value": "C30860"},
               {"label": "Le Grand-Quevilly", "value": "C30861"},
               {"label": "Le Havre", "value": "C30862"},
               {"label": "Le Houlme", "value": "C30863"},
               {"label": "Le Manoir", "value": "C30864"},
               {"label": "Le Mesnil-Esnard", "value": "C30865"},
               {"label": "Le Molay-Littry", "value": "C30866"},
               {"label": "Le Neubourg", "value": "C30867"},
               {"label": "Le Petit-Quevilly", "value": "C30868"},
               {"label": "Le Teilleul", "value": "C30869"},
               {"label": "Le Theil-Bocage", "value": "C30870"},
               {"label": "Le Thuit-Signol", "value": "C30871"},
               {"label": "Le Trait", "value": "C30872"},
               {"label": "Le Treport", "value": "C30873"},
               {"label": "Le Val-Saint-Pere", "value": "C30874"},
               {"label": "Le Vaudreuil", "value": "C30875"},
               {"label": "Lery", "value": "C30876"},
               {"label": "Les Andelys", "value": "C30877"},
               {"label": "Les Authieux-sur-le-Port-Saint-Ouen",
                "value": "C30878"},
               {"label": "Les Grandes-Ventes", "value": "C30879"},
               {"label": "Les Loges", "value": "C30880"},
               {"label": "Les Loges-Marchis", "value": "C30881"},
               {"label": "Les Pieux", "value": "C30882"},
               {"label": "Lessay", "value": "C30883"},
               {"label": "Lieurey", "value": "C30884"},
               {"label": "Lillebonne", "value": "C30885"},
               {"label": "Limesy", "value": "C30886"},
               {"label": "Lion-sur-Mer", "value": "C30887"},
               {"label": "Lisieux", "value": "C30888"},
               {"label": "Livarot", "value": "C30889"},
               {"label": "Londinieres", "value": "C30890"},
               {"label": "Longny-au-Perche", "value": "C30891"},
               {"label": "Louviers", "value": "C30892"},
               {"label": "Louvigny", "value": "C30893"},
               {"label": "Luc-sur-Mer", "value": "C30894"},
               {"label": "Luneray", "value": "C30895"},
               {"label": "Magny-le-Desert", "value": "C30896"},
               {"label": "Malaunay", "value": "C30897"},
               {"label": "Manche", "value": "C30898"},
               {"label": "Maneglise", "value": "C30899"},
               {"label": "Manneville-sur-Risle", "value": "C30900"},
               {"label": "Marcey-les-Greves", "value": "C30901"},
               {"label": "Marcilly-sur-Eure", "value": "C30902"},
               {"label": "Marigny", "value": "C30903"},
               {"label": "Maromme", "value": "C30904"},
               {"label": "Martin-eglise", "value": "C30905"},
               {"label": "Martinvast", "value": "C30906"},
               {"label": "Mathieu", "value": "C30907"},
               {"label": "May-sur-Orne", "value": "C30908"},
               {"label": "Menilles", "value": "C30909"},
               {"label": "Menneval", "value": "C30910"},
               {"label": "Merville-Franceville-Plage", "value": "C30911"},
               {"label": "Mesnieres-en-Bray", "value": "C30912"},
               {"label": "Messei", "value": "C30913"},
               {"label": "Mondeville", "value": "C30914"},
               {"label": "Mont-Saint-Aignan", "value": "C30915"},
               {"label": "Montaure", "value": "C30916"},
               {"label": "Montebourg", "value": "C30917"},
               {"label": "Montigny", "value": "C30918"},
               {"label": "Montivilliers", "value": "C30919"},
               {"label": "Montmain", "value": "C30920"},
               {"label": "Montmartin-sur-Mer", "value": "C30921"},
               {"label": "Mortagne-au-Perche", "value": "C30922"},
               {"label": "Mortain", "value": "C30923"},
               {"label": "Mortree", "value": "C30924"},
               {"label": "Mouen", "value": "C30925"},
               {"label": "Moult", "value": "C30926"},
               {"label": "Moyaux", "value": "C30927"},
               {"label": "Nassandres", "value": "C30928"},
               {"label": "Neaufles-Saint-Martin", "value": "C30929"},
               {"label": "Neufchatel-en-Bray", "value": "C30930"},
               {"label": "Neville", "value": "C30931"},
               {"label": "Nointot", "value": "C30932"},
               {"label": "Nonancourt", "value": "C30933"},
               {"label": "Normanville", "value": "C30934"},
               {"label": "Notre-Dame-de-Bondeville", "value": "C30935"},
               {"label": "Notre-Dame-de-Gravenchon", "value": "C30936"},
               {"label": "Octeville", "value": "C30937"},
               {"label": "Octeville-sur-Mer", "value": "C30938"},
               {"label": "Offranville", "value": "C30939"},
               {"label": "Oissel", "value": "C30940"},
               {"label": "Orbec", "value": "C30941"},
               {"label": "Orival", "value": "C30942"},
               {"label": "Ouistreham", "value": "C30943"},
               {"label": "Ourville-en-Caux", "value": "C30944"},
               {"label": "Pacy-sur-Eure", "value": "C30945"},
               {"label": "Parigny", "value": "C30946"},
               {"label": "Pavilly", "value": "C30947"},
               {"label": "Percy", "value": "C30948"},
               {"label": "Periers", "value": "C30949"},
               {"label": "Perriers-sur-Andelle", "value": "C30950"},
               {"label": "Petit-Couronne", "value": "C30951"},
               {"label": "Petiville", "value": "C30952"},
               {"label": "Picauville", "value": "C30953"},
               {"label": "Pirou", "value": "C30954"},
               {"label": "Pissy-Poville", "value": "C30955"},
               {"label": "Pitres", "value": "C30956"},
               {"label": "Pont-Audemer", "value": "C30957"},
               {"label": "Pont-dOuilly", "value": "C30958"},
               {"label": "Pont-ecrepin", "value": "C30959"},
               {"label": "Pont-Hebert", "value": "C30960"},
               {"label": "Pont-leveque", "value": "C30961"},
               {"label": "Pont-Saint-Pierre", "value": "C30962"},
               {"label": "Pontorson", "value": "C30963"},
               {"label": "Port-en-Bessin-Huppain", "value": "C30964"},
               {"label": "Portbail", "value": "C30965"},
               {"label": "Poses", "value": "C30966"},
               {"label": "Potigny", "value": "C30967"},
               {"label": "Preaux", "value": "C30968"},
               {"label": "Putanges-Pont-ecrepin", "value": "C30969"},
               {"label": "Querqueville", "value": "C30970"},
               {"label": "Quettehou", "value": "C30971"},
               {"label": "Quettreville-sur-Sienne", "value": "C30972"},
               {"label": "Quillebeuf-sur-Seine", "value": "C30973"},
               {"label": "Quincampoix", "value": "C30974"},
               {"label": "Radon", "value": "C30975"},
               {"label": "Rai", "value": "C30976"},
               {"label": "Ranville", "value": "C30977"},
               {"label": "Remalard", "value": "C30978"},
               {"label": "Reville", "value": "C30979"},
               {"label": "Rogerville", "value": "C30980"},
               {"label": "Rolleville", "value": "C30981"},
               {"label": "Romagny", "value": "C30982"},
               {"label": "Romilly-sur-Andelle", "value": "C30983"},
               {"label": "Roncherolles-sur-le-Vivier", "value": "C30984"},
               {"label": "Rots", "value": "C30985"},
               {"label": "Rouen", "value": "C30986"},
               {"label": "Roumare", "value": "C30987"},
               {"label": "Routot", "value": "C30988"},
               {"label": "Rouxmesnil-Bouteilles", "value": "C30989"},
               {"label": "Rugles", "value": "C30990"},
               {"label": "Sahurs", "value": "C30991"},
               {"label": "Saint-Amand", "value": "C30992"},
               {"label": "Saint-Andre-sur-Orne", "value": "C30993"},
               {"label": "Saint-Arnoult", "value": "C30994"},
               {"label": "Saint-Aubin-les-Elbeuf", "value": "C30995"},
               {"label": "Saint-Aubin-Routot", "value": "C30996"},
               {"label": "Saint-Aubin-sur-Gaillon", "value": "C30997"},
               {"label": "Saint-Aubin-sur-Mer", "value": "C30998"},
               {"label": "Saint-Aubin-sur-Scie", "value": "C30999"},
               {"label": "Saint-Contest", "value": "C31000"},
               {"label": "Saint-Denis-sur-Sarthon", "value": "C31001"},
               {"label": "Saint-Desir", "value": "C31002"},
               {"label": "Saint-etienne-du-Rouvray", "value": "C31003"},
               {"label": "Saint-Eustache-la-Foret", "value": "C31004"},
               {"label": "Saint-Gatien-des-Bois", "value": "C31005"},
               {"label": "Saint-Georges-des-Groseillers", "value": "C31006"},
               {"label": "Saint-Germain-du-Corbeis", "value": "C31007"},
               {"label": "Saint-Germain-la-Blanche-Herbe", "value": "C31008"},
               {"label": "Saint-Germain-sur-Avre", "value": "C31009"},
               {"label": "Saint-Germain-Village", "value": "C31010"},
               {"label": "Saint-Hilaire-du-Harcouet", "value": "C31011"},
               {"label": "Saint-Hilaire-Petitville", "value": "C31012"},
               {"label": "Saint-Jacques-sur-Darnetal", "value": "C31013"},
               {"label": "Saint-James", "value": "C31014"},
               {"label": "Saint-Jean-des-Baisants", "value": "C31015"},
               {"label": "Saint-Jean-des-Champs", "value": "C31016"},
               {"label": "Saint-Jean-du-Cardonnay", "value": "C31017"},
               {"label": "Saint-Jouin-Bruneval", "value": "C31018"},
               {"label": "Saint-Just", "value": "C31019"},
               {"label": "Saint-Laurent-de-Brevedent", "value": "C31020"},
               {"label": "Saint-Leger-du-Bourg-Denis", "value": "C31021"},
               {"label": "Saint-Leonard", "value": "C31022"},
               {"label": "Saint-Lo", "value": "C31023"},
               {"label": "Saint-Manvieu", "value": "C31024"},
               {"label": "Saint-Manvieu-Norrey", "value": "C31025"},
               {"label": "Saint-Marcel", "value": "C31026"},
               {"label": "Saint-Martin-de-Boscherville", "value": "C31027"},
               {"label": "Saint-Martin-de-Fontenay", "value": "C31028"},
               {"label": "Saint-Martin-de-Landelles", "value": "C31029"},
               {"label": "Saint-Martin-des-Besaces", "value": "C31030"},
               {"label": "Saint-Martin-des-Champs", "value": "C31031"},
               {"label": "Saint-Martin-du-Manoir", "value": "C31032"},
               {"label": "Saint-Martin-du-Vivier", "value": "C31033"},
               {"label": "Saint-Martin-en-Campagne", "value": "C31034"},
               {"label": "Saint-Martin-Osmonville", "value": "C31035"},
               {"label": "Saint-Nicolas-dAliermont", "value": "C31036"},
               {"label": "Saint-Nicolas-de-la-Taille", "value": "C31037"},
               {"label": "Saint-Ouen-de-Thouberville", "value": "C31038"},
               {"label": "Saint-Ouen-du-Tilleul", "value": "C31039"},
               {"label": "Saint-Paer", "value": "C31040"},
               {"label": "Saint-Pair-sur-Mer", "value": "C31041"},
               {"label": "Saint-Pierre-de-Varengeville", "value": "C31042"},
               {"label": "Saint-Pierre-des-Fleurs", "value": "C31043"},
               {"label": "Saint-Pierre-du-Regard", "value": "C31044"},
               {"label": "Saint-Pierre-du-Vauvray", "value": "C31045"},
               {"label": "Saint-Pierre-eglise", "value": "C31046"},
               {"label": "Saint-Pierre-en-Val", "value": "C31047"},
               {"label": "Saint-Pierre-les-Elbeuf", "value": "C31048"},
               {"label": "Saint-Pierre-sur-Dives", "value": "C31049"},
               {"label": "Saint-Planchers", "value": "C31050"},
               {"label": "Saint-Quentin-sur-le-Homme", "value": "C31051"},
               {"label": "Saint-Remy", "value": "C31052"},
               {"label": "Saint-Romain-de-Colbosc", "value": "C31053"},
               {"label": "Saint-Saens", "value": "C31054"},
               {"label": "Saint-Sauveur-le-Vicomte", "value": "C31055"},
               {"label": "Saint-Sauveur-Lendelin", "value": "C31056"},
               {"label": "Saint-Sebastien-de-Morsent", "value": "C31057"},
               {"label": "Saint-Senier-sous-Avranches", "value": "C31058"},
               {"label": "Saint-Sever-Calvados", "value": "C31059"},
               {"label": "Saint-Sulpice-sur-Risle", "value": "C31060"},
               {"label": "Saint-Sylvain", "value": "C31061"},
               {"label": "Saint-Vaast-la-Hougue", "value": "C31062"},
               {"label": "Saint-Valery-en-Caux", "value": "C31063"},
               {"label": "Saint-Vigor-le-Grand", "value": "C31064"},
               {"label": "Saint-Wandrille-Rancon", "value": "C31065"},
               {"label": "Sainte-Adresse", "value": "C31066"},
               {"label": "Sainte-Gauburge-Sainte-Colombe", "value": "C31067"},
               {"label": "Sainte-Honorine-du-Fay", "value": "C31068"},
               {"label": "Sainte-Marguerite-sur-Duclair", "value": "C31069"},
               {"label": "Sainte-Marie-des-Champs", "value": "C31070"},
               {"label": "Sainte-Mere-eglise", "value": "C31071"},
               {"label": "Sannerville", "value": "C31072"},
               {"label": "Sartilly", "value": "C31073"},
               {"label": "Sees", "value": "C31074"},
               {"label": "Seine-Maritime", "value": "C31075"},
               {"label": "Serqueux", "value": "C31076"},
               {"label": "Serquigny", "value": "C31077"},
               {"label": "Soliers", "value": "C31078"},
               {"label": "Sottevast", "value": "C31079"},
               {"label": "Sotteville-les-Rouen", "value": "C31080"},
               {"label": "Sourdeval", "value": "C31081"},
               {"label": "Surtainville", "value": "C31082"},
               {"label": "Tancarville", "value": "C31083"},
               {"label": "Tesse-la-Madeleine", "value": "C31084"},
               {"label": "Tessy-sur-Vire", "value": "C31085"},
               {"label": "Thaon", "value": "C31086"},
               {"label": "Thiberville", "value": "C31087"},
               {"label": "Thury-Harcourt", "value": "C31088"},
               {"label": "Tillieres-sur-Avre", "value": "C31089"},
               {"label": "Tilly-sur-Seulles", "value": "C31090"},
               {"label": "Tinchebray", "value": "C31091"},
               {"label": "Tollevast", "value": "C31092"},
               {"label": "Torigni-sur-Vire", "value": "C31093"},
               {"label": "Totes", "value": "C31094"},
               {"label": "Touques", "value": "C31095"},
               {"label": "Tourlaville", "value": "C31096"},
               {"label": "Tourouvre", "value": "C31097"},
               {"label": "Tourville-la-Riviere", "value": "C31098"},
               {"label": "Tourville-sur-Arques", "value": "C31099"},
               {"label": "Tourville-sur-Odon", "value": "C31100"},
               {"label": "Toutainville", "value": "C31101"},
               {"label": "Troarn", "value": "C31102"},
               {"label": "Trouville-sur-Mer", "value": "C31103"},
               {"label": "Trun", "value": "C31104"},
               {"label": "Turretot", "value": "C31105"},
               {"label": "Urville-Nacqueville", "value": "C31106"},
               {"label": "Val-de-Reuil", "value": "C31107"},
               {"label": "Valframbert", "value": "C31108"},
               {"label": "Valliquerville", "value": "C31109"},
               {"label": "Valognes", "value": "C31110"},
               {"label": "Varengeville-sur-Mer", "value": "C31111"},
               {"label": "Vassy", "value": "C31112"},
               {"label": "Vaudry", "value": "C31113"},
               {"label": "Ver-sur-Mer", "value": "C31114"},
               {"label": "Verneuil-sur-Avre", "value": "C31115"},
               {"label": "Vernon", "value": "C31116"},
               {"label": "Verson", "value": "C31117"},
               {"label": "Villedieu-les-Poeles", "value": "C31118"},
               {"label": "Villers-Bocage", "value": "C31119"},
               {"label": "Villers-ecalles", "value": "C31120"},
               {"label": "Villers-sur-Mer", "value": "C31121"},
               {"label": "Vimoutiers", "value": "C31122"},
               {"label": "Vire", "value": "C31123"},
               {"label": "Yainville", "value": "C31124"},
               {"label": "Yebleron", "value": "C31125"},
               {"label": "Yerville", "value": "C31126"},
               {"label": "Ymare", "value": "C31127"},
               {"label": "Yport", "value": "C31128"},
               {"label": "Yvetot", "value": "C31129"}],
 "Norrbotten County": [{"label": "Alvsbyn", "value": "C115717"},
                       {"label": "Arjeplog", "value": "C115718"},
                       {"label": "Arvidsjaur", "value": "C115719"},
                       {"label": "Bergnaset", "value": "C115720"},
                       {"label": "Bergsviken", "value": "C115721"},
                       {"label": "Bjorkskatan", "value": "C115722"},
                       {"label": "Boden", "value": "C115723"},
                       {"label": "Gallivare", "value": "C115724"},
                       {"label": "Gammelstad", "value": "C115725"},
                       {"label": "Haparanda", "value": "C115726"},
                       {"label": "Hortlax", "value": "C115727"},
                       {"label": "Jokkmokk", "value": "C115728"},
                       {"label": "Kalix", "value": "C115729"},
                       {"label": "Kiruna", "value": "C115730"},
                       {"label": "Lulea", "value": "C115731"},
                       {"label": "Malmberget", "value": "C115732"},
                       {"label": "Marielund", "value": "C115733"},
                       {"label": "Norrfjarden", "value": "C115734"},
                       {"label": "Overkalix", "value": "C115735"},
                       {"label": "Overtornea", "value": "C115736"},
                       {"label": "Pajala", "value": "C115737"},
                       {"label": "Pitea", "value": "C115738"},
                       {"label": "Ranea", "value": "C115739"},
                       {"label": "Roknas", "value": "C115740"},
                       {"label": "Rolfs", "value": "C115741"},
                       {"label": "Rosvik", "value": "C115742"},
                       {"label": "Savast", "value": "C115743"},
                       {"label": "Sodra Sunderbyn", "value": "C115744"},
                       {"label": "Tore", "value": "C115745"}],
 "Norte de Santander": [{"label": "Abrego", "value": "C20367"},
                        {"label": "Arboledas", "value": "C20368"},
                        {"label": "Bochalema", "value": "C20369"},
                        {"label": "Bucarasica", "value": "C20370"},
                        {"label": "Cachira", "value": "C20371"},
                        {"label": "Cacota", "value": "C20372"},
                        {"label": "Chinacota", "value": "C20373"},
                        {"label": "Chitaga", "value": "C20374"},
                        {"label": "Convencion", "value": "C20375"},
                        {"label": "Cucuta", "value": "C20376"},
                        {"label": "Cucutilla", "value": "C20377"},
                        {"label": "Durania", "value": "C20378"},
                        {"label": "El Carmen", "value": "C20379"},
                        {"label": "El Tarra", "value": "C20380"},
                        {"label": "El Zulia", "value": "C20381"},
                        {"label": "Gramalote", "value": "C20382"},
                        {"label": "Hacari", "value": "C20383"},
                        {"label": "Herran", "value": "C20384"},
                        {"label": "La Esperanza", "value": "C20385"},
                        {"label": "La Playa", "value": "C20386"},
                        {"label": "Labateca", "value": "C20387"},
                        {"label": "Los Patios", "value": "C20388"},
                        {"label": "Lourdes", "value": "C20389"},
                        {"label": "Mutiscua", "value": "C20390"},
                        {"label": "Ocana", "value": "C20391"},
                        {"label": "Pamplona", "value": "C20392"},
                        {"label": "Pamplonita", "value": "C20393"},
                        {"label": "Puerto Santander", "value": "C20394"},
                        {"label": "Ragonvalia", "value": "C20395"},
                        {"label": "Salazar", "value": "C20396"},
                        {"label": "San Calixto", "value": "C20397"},
                        {"label": "San Cayetano", "value": "C20398"},
                        {"label": "Santiago", "value": "C20399"},
                        {"label": "Sardinata", "value": "C20400"},
                        {"label": "Silos", "value": "C20401"},
                        {"label": "Teorama", "value": "C20402"},
                        {"label": "Tibu", "value": "C20403"},
                        {"label": "Toledo", "value": "C20404"},
                        {"label": "Villa Caro", "value": "C20405"},
                        {"label": "Villa del Rosario", "value": "C20406"}],
 "North": [{"label": "Faro Department", "value": "C16642"},
           {"label": "Garoua", "value": "C16643"},
           {"label": "Guider", "value": "C16644"},
           {"label": "Lagdo", "value": "C16645"},
           {"label": "Mayo-Louti", "value": "C16646"},
           {"label": "Mayo-Rey", "value": "C16647"},
           {"label": "Pitoa", "value": "C16648"},
           {"label": "Poli", "value": "C16649"},
           {"label": "Rey Bouba", "value": "C16650"},
           {"label": "Tchollire", "value": "C16651"}],
 "North Abaco": [{"label": "Coopers Town", "value": "C119617"}],
 "North Andros": [{"label": "Andros Town", "value": "C119618"},
                  {"label": "San Andros", "value": "C119619"}],
 "North Bank Division": [{"label": "Bambali", "value": "C34201"},
                         {"label": "Barra", "value": "C34202"},
                         {"label": "Central Baddibu", "value": "C34203"},
                         {"label": "Chilla", "value": "C34204"},
                         {"label": "Daru Rilwan", "value": "C34205"},
                         {"label": "Essau", "value": "C34206"},
                         {"label": "Farafenni", "value": "C34207"},
                         {"label": "Gunjur", "value": "C34208"},
                         {"label": "Jokadu", "value": "C34209"},
                         {"label": "Katchang", "value": "C34210"},
                         {"label": "Kerewan", "value": "C34211"},
                         {"label": "Lamin", "value": "C34212"},
                         {"label": "Lower Baddibu District", "value": "C34213"},
                         {"label": "Lower Niumi District", "value": "C34214"},
                         {"label": "No Kunda", "value": "C34215"},
                         {"label": "Saba", "value": "C34216"},
                         {"label": "Sara Kunda", "value": "C34217"},
                         {"label": "Upper Baddibu", "value": "C34218"},
                         {"label": "Upper Niumi District", "value": "C34219"}],
 "North Brabant": [{"label": "s Gravenmoer", "value": "C76754"},
                   {"label": "s-Hertogenbosch", "value": "C76755"},
                   {"label": "t Hofke", "value": "C76756"},
                   {"label": "Aalburg", "value": "C76757"},
                   {"label": "Aalst", "value": "C76758"},
                   {"label": "Aarle-Rixtel", "value": "C76759"},
                   {"label": "Abcoven", "value": "C76760"},
                   {"label": "Akert", "value": "C76761"},
                   {"label": "Almkerk", "value": "C76762"},
                   {"label": "Alphen", "value": "C76763"},
                   {"label": "Andel", "value": "C76764"},
                   {"label": "Asten", "value": "C76765"},
                   {"label": "Baardwijk", "value": "C76766"},
                   {"label": "Baarle-Nassau", "value": "C76767"},
                   {"label": "Bakel", "value": "C76768"},
                   {"label": "Bavel", "value": "C76769"},
                   {"label": "Beek", "value": "C76770"},
                   {"label": "Beers", "value": "C76771"},
                   {"label": "Belcrum", "value": "C76772"},
                   {"label": "Bergeijk", "value": "C76773"},
                   {"label": "Bergen op Zoom", "value": "C76774"},
                   {"label": "Berghem", "value": "C76775"},
                   {"label": "Berlicum", "value": "C76776"},
                   {"label": "Besoijen", "value": "C76777"},
                   {"label": "Best", "value": "C76778"},
                   {"label": "Besterd", "value": "C76779"},
                   {"label": "Biesdonk", "value": "C76780"},
                   {"label": "Bitswijk", "value": "C76781"},
                   {"label": "Blaarthem", "value": "C76782"},
                   {"label": "Bladel", "value": "C76783"},
                   {"label": "Boekel", "value": "C76784"},
                   {"label": "Bosschenhoofd", "value": "C76785"},
                   {"label": "Boxmeer", "value": "C76786"},
                   {"label": "Boxtel", "value": "C76787"},
                   {"label": "Brand", "value": "C76788"},
                   {"label": "Breda", "value": "C76789"},
                   {"label": "Breugel", "value": "C76790"},
                   {"label": "Broekhoven", "value": "C76791"},
                   {"label": "Budel", "value": "C76792"},
                   {"label": "Budel-Dorplein", "value": "C76793"},
                   {"label": "Budel-Schoot", "value": "C76794"},
                   {"label": "Chaam", "value": "C76795"},
                   {"label": "Coevering", "value": "C76796"},
                   {"label": "Cranendonck", "value": "C76797"},
                   {"label": "Cuijk", "value": "C76798"},
                   {"label": "De Blaak", "value": "C76799"},
                   {"label": "De Doornakkers", "value": "C76800"},
                   {"label": "De Kruiskamp", "value": "C76801"},
                   {"label": "De Reit", "value": "C76802"},
                   {"label": "De Rompert", "value": "C76803"},
                   {"label": "Den Dungen", "value": "C76804"},
                   {"label": "Deuteren", "value": "C76805"},
                   {"label": "Diessen", "value": "C76806"},
                   {"label": "Dinteloord", "value": "C76807"},
                   {"label": "Dommelen", "value": "C76808"},
                   {"label": "Dongen", "value": "C76809"},
                   {"label": "Donk", "value": "C76810"},
                   {"label": "Doonheide", "value": "C76811"},
                   {"label": "Dorst", "value": "C76812"},
                   {"label": "Duizel", "value": "C76813"},
                   {"label": "Eckart", "value": "C76814"},
                   {"label": "Eerde", "value": "C76815"},
                   {"label": "Eerschot", "value": "C76816"},
                   {"label": "Eersel", "value": "C76817"},
                   {"label": "Eindhoven", "value": "C76818"},
                   {"label": "Ekenrooi", "value": "C76819"},
                   {"label": "Elshout", "value": "C76820"},
                   {"label": "Enschot", "value": "C76821"},
                   {"label": "Erp", "value": "C76822"},
                   {"label": "Esch", "value": "C76823"},
                   {"label": "Fijnaart", "value": "C76824"},
                   {"label": "Gageldonk", "value": "C76825"},
                   {"label": "Geenhoven", "value": "C76826"},
                   {"label": "Geertruidenberg", "value": "C76827"},
                   {"label": "Geffen", "value": "C76828"},
                   {"label": "Geldrop", "value": "C76829"},
                   {"label": "Gemert", "value": "C76830"},
                   {"label": "Gemonde", "value": "C76831"},
                   {"label": "Genderen", "value": "C76832"},
                   {"label": "Genoenhuis", "value": "C76833"},
                   {"label": "Gerwen", "value": "C76834"},
                   {"label": "Giessen", "value": "C76835"},
                   {"label": "Gilze", "value": "C76836"},
                   {"label": "Ginneken", "value": "C76837"},
                   {"label": "Goirle", "value": "C76838"},
                   {"label": "Grasrijk", "value": "C76839"},
                   {"label": "Grave", "value": "C76840"},
                   {"label": "Groenewoud", "value": "C76841"},
                   {"label": "Haaren", "value": "C76842"},
                   {"label": "Haarsteeg", "value": "C76843"},
                   {"label": "Halsteren", "value": "C76844"},
                   {"label": "Handel", "value": "C76845"},
                   {"label": "Hapert", "value": "C76846"},
                   {"label": "Haps", "value": "C76847"},
                   {"label": "Heerle", "value": "C76848"},
                   {"label": "Heesch", "value": "C76849"},
                   {"label": "Heesterakker", "value": "C76850"},
                   {"label": "Heeswijk-Dinther", "value": "C76851"},
                   {"label": "Heeze", "value": "C76852"},
                   {"label": "Helmond", "value": "C76853"},
                   {"label": "Helvoirt", "value": "C76854"},
                   {"label": "Herpen", "value": "C76855"},
                   {"label": "Heusden", "value": "C76856"},
                   {"label": "Heusdenhout", "value": "C76857"},
                   {"label": "Hilvarenbeek", "value": "C76858"},
                   {"label": "Hoeven", "value": "C76859"},
                   {"label": "Hoge Vucht", "value": "C76860"},
                   {"label": "Hooge Mierde", "value": "C76861"},
                   {"label": "Hooge Zwaluwe", "value": "C76862"},
                   {"label": "Hoogeloon", "value": "C76863"},
                   {"label": "Hoogerheide", "value": "C76864"},
                   {"label": "Huijbergen", "value": "C76865"},
                   {"label": "Jagershoef", "value": "C76866"},
                   {"label": "Kalsdonk", "value": "C76867"},
                   {"label": "Klein-Zundert", "value": "C76868"},
                   {"label": "Klundert", "value": "C76869"},
                   {"label": "Korvel", "value": "C76870"},
                   {"label": "Krooswijk", "value": "C76871"},
                   {"label": "Kruisland", "value": "C76872"},
                   {"label": "Lage Mierde", "value": "C76873"},
                   {"label": "Lakerlopen", "value": "C76874"},
                   {"label": "Langenboom", "value": "C76875"},
                   {"label": "Leende", "value": "C76876"},
                   {"label": "Leest", "value": "C76877"},
                   {"label": "Lepelstraat", "value": "C76878"},
                   {"label": "Liempde", "value": "C76879"},
                   {"label": "Lierop", "value": "C76880"},
                   {"label": "Lieshout", "value": "C76881"},
                   {"label": "Liessel", "value": "C76882"},
                   {"label": "Lievendaal", "value": "C76883"},
                   {"label": "Lith", "value": "C76884"},
                   {"label": "Loon op Zand", "value": "C76885"},
                   {"label": "Loosbroek", "value": "C76886"},
                   {"label": "Loven", "value": "C76887"},
                   {"label": "Luyksgestel", "value": "C76888"},
                   {"label": "Maarheeze", "value": "C76889"},
                   {"label": "Maaskantje", "value": "C76890"},
                   {"label": "Made", "value": "C76891"},
                   {"label": "Mariahout", "value": "C76892"},
                   {"label": "Meerhoven", "value": "C76893"},
                   {"label": "Meerveldhoven", "value": "C76894"},
                   {"label": "Megen", "value": "C76895"},
                   {"label": "Meierijstad", "value": "C76896"},
                   {"label": "Melle", "value": "C76897"},
                   {"label": "Middelbeers", "value": "C76898"},
                   {"label": "Middelrode", "value": "C76899"},
                   {"label": "Mierlo", "value": "C76900"},
                   {"label": "Milheeze", "value": "C76901"},
                   {"label": "Moerdijk", "value": "C76902"},
                   {"label": "Molenhoek", "value": "C76903"},
                   {"label": "Muschberg en Geestenberg", "value": "C76904"},
                   {"label": "Naastenbest", "value": "C76905"},
                   {"label": "Neerkant", "value": "C76906"},
                   {"label": "Nieuw-Vossemeer", "value": "C76907"},
                   {"label": "Nieuwkuijk", "value": "C76908"},
                   {"label": "Nijnsel", "value": "C76909"},
                   {"label": "Nispen", "value": "C76910"},
                   {"label": "Nistelrode", "value": "C76911"},
                   {"label": "Noordgeest", "value": "C76912"},
                   {"label": "Noordhoek", "value": "C76913"},
                   {"label": "Nuenen", "value": "C76914"},
                   {"label": "Odiliapeel", "value": "C76915"},
                   {"label": "Oerle", "value": "C76916"},
                   {"label": "Oirschot", "value": "C76917"},
                   {"label": "Oisterwijk", "value": "C76918"},
                   {"label": "Oosteind", "value": "C76919"},
                   {"label": "Oostelbeers", "value": "C76920"},
                   {"label": "Oosterhout", "value": "C76921"},
                   {"label": "Orthen", "value": "C76922"},
                   {"label": "Oss", "value": "C76923"},
                   {"label": "Ossendrecht", "value": "C76924"},
                   {"label": "Oud Gastel", "value": "C76925"},
                   {"label": "Overloon", "value": "C76926"},
                   {"label": "Princenhage", "value": "C76927"},
                   {"label": "Prinsenbeek", "value": "C76928"},
                   {"label": "Putte", "value": "C76929"},
                   {"label": "Quirijnstok", "value": "C76930"},
                   {"label": "Raam", "value": "C76931"},
                   {"label": "Raamsdonk", "value": "C76932"},
                   {"label": "Raamsdonksveer", "value": "C76933"},
                   {"label": "Ravenstein", "value": "C76934"},
                   {"label": "Reek", "value": "C76935"},
                   {"label": "Reusel", "value": "C76936"},
                   {"label": "Riethoven", "value": "C76937"},
                   {"label": "Rijen", "value": "C76938"},
                   {"label": "Rijsbergen", "value": "C76939"},
                   {"label": "Rijswijk", "value": "C76940"},
                   {"label": "Roosendaal", "value": "C76941"},
                   {"label": "Rucphen", "value": "C76942"},
                   {"label": "Salderes", "value": "C76943"},
                   {"label": "Schaijk", "value": "C76944"},
                   {"label": "Schijndel", "value": "C76945"},
                   {"label": "Schutsboom", "value": "C76946"},
                   {"label": "Sint Anthonis", "value": "C76947"},
                   {"label": "Sint Willebrord", "value": "C76948"},
                   {"label": "Sint-Michielsgestel", "value": "C76949"},
                   {"label": "Sint-Oedenrode", "value": "C76950"},
                   {"label": "Soerendonk", "value": "C76951"},
                   {"label": "Someren", "value": "C76952"},
                   {"label": "Someren-Eind", "value": "C76953"},
                   {"label": "Son", "value": "C76954"},
                   {"label": "Speelheide", "value": "C76955"},
                   {"label": "Sprundel", "value": "C76956"},
                   {"label": "Stampersgat", "value": "C76957"},
                   {"label": "Standdaarbuiten", "value": "C76958"},
                   {"label": "Steenbergen", "value": "C76959"},
                   {"label": "Steensel", "value": "C76960"},
                   {"label": "Stepekolk", "value": "C76961"},
                   {"label": "Terheijden", "value": "C76962"},
                   {"label": "Teteringen", "value": "C76963"},
                   {"label": "Theereheide", "value": "C76964"},
                   {"label": "Tilburg", "value": "C76965"},
                   {"label": "Tivoli", "value": "C76966"},
                   {"label": "Tongelre", "value": "C76967"},
                   {"label": "Uden", "value": "C76968"},
                   {"label": "Udenhout", "value": "C76969"},
                   {"label": "Ulvenhout", "value": "C76970"},
                   {"label": "Vaartbroek", "value": "C76971"},
                   {"label": "Valkenswaard", "value": "C76972"},
                   {"label": "Veen", "value": "C76973"},
                   {"label": "Veghel", "value": "C76974"},
                   {"label": "Veldhoven", "value": "C76975"},
                   {"label": "Ven", "value": "C76976"},
                   {"label": "Venhorst", "value": "C76977"},
                   {"label": "Villapark", "value": "C76978"},
                   {"label": "Vliedberg", "value": "C76979"},
                   {"label": "Vlierden", "value": "C76980"},
                   {"label": "Vlokhoven", "value": "C76981"},
                   {"label": "Voldijn", "value": "C76982"},
                   {"label": "Volkel", "value": "C76983"},
                   {"label": "Vorstenbosch", "value": "C76984"},
                   {"label": "Vught", "value": "C76985"},
                   {"label": "Waalre", "value": "C76986"},
                   {"label": "Waalwijk", "value": "C76987"},
                   {"label": "Wagenberg", "value": "C76988"},
                   {"label": "Wanroij", "value": "C76989"},
                   {"label": "Waspik", "value": "C76990"},
                   {"label": "Waterdonken", "value": "C76991"},
                   {"label": "Welberg", "value": "C76992"},
                   {"label": "Werkendam", "value": "C76993"},
                   {"label": "Wernhout", "value": "C76994"},
                   {"label": "Westerhoven", "value": "C76995"},
                   {"label": "Wijbosch", "value": "C76996"},
                   {"label": "Wilhelminadorp", "value": "C76997"},
                   {"label": "Wisselaar", "value": "C76998"},
                   {"label": "Woensdrecht", "value": "C76999"},
                   {"label": "Woenselse Heide", "value": "C77000"},
                   {"label": "Woudrichem", "value": "C77001"},
                   {"label": "Wouw", "value": "C77002"},
                   {"label": "Zandberg", "value": "C77003"},
                   {"label": "Zeeland", "value": "C77004"},
                   {"label": "Zeelst", "value": "C77005"},
                   {"label": "Zegge", "value": "C77006"},
                   {"label": "Zeilberg", "value": "C77007"},
                   {"label": "Zesgehuchten", "value": "C77008"},
                   {"label": "Zevenbergschen Hoek", "value": "C77009"},
                   {"label": "Zijtaart", "value": "C77010"},
                   {"label": "Zonderwijk", "value": "C77011"},
                   {"label": "Zundert", "value": "C77012"}],
 "North Caribbean Coast": [{"label": "Bonanza", "value": "C77989"},
                           {"label": "Prinzapolka", "value": "C77990"},
                           {"label": "Puerto Cabezas", "value": "C77991"},
                           {"label": "Siuna", "value": "C77992"},
                           {"label": "Waslala", "value": "C77993"},
                           {"label": "Waspan", "value": "C77994"}],
 "North Carolina": [{"label": "Aberdeen", "value": "C139304"},
                    {"label": "Advance", "value": "C139305"},
                    {"label": "Ahoskie", "value": "C139306"},
                    {"label": "Alamance County", "value": "C139307"},
                    {"label": "Albemarle", "value": "C139308"},
                    {"label": "Alexander County", "value": "C139309"},
                    {"label": "Alleghany County", "value": "C139310"},
                    {"label": "Andrews", "value": "C139311"},
                    {"label": "Angier", "value": "C139312"},
                    {"label": "Anson County", "value": "C139313"},
                    {"label": "Apex", "value": "C139314"},
                    {"label": "Archdale", "value": "C139315"},
                    {"label": "Archer Lodge", "value": "C139316"},
                    {"label": "Ashe County", "value": "C139317"},
                    {"label": "Asheboro", "value": "C139318"},
                    {"label": "Asheville", "value": "C139319"},
                    {"label": "Atlantic Beach", "value": "C139320"},
                    {"label": "Avery County", "value": "C139321"},
                    {"label": "Avery Creek", "value": "C139322"},
                    {"label": "Ayden", "value": "C139323"},
                    {"label": "Badin", "value": "C139324"},
                    {"label": "Bakersville", "value": "C139325"},
                    {"label": "Balfour", "value": "C139326"},
                    {"label": "Banner Elk", "value": "C139327"},
                    {"label": "Barker Heights", "value": "C139328"},
                    {"label": "Bayboro", "value": "C139329"},
                    {"label": "Bayshore", "value": "C139330"},
                    {"label": "Beaufort", "value": "C139331"},
                    {"label": "Beaufort County", "value": "C139332"},
                    {"label": "Belhaven", "value": "C139333"},
                    {"label": "Belmont", "value": "C139334"},
                    {"label": "Belville", "value": "C139335"},
                    {"label": "Benson", "value": "C139336"},
                    {"label": "Bent Creek", "value": "C139337"},
                    {"label": "Bermuda Run", "value": "C139338"},
                    {"label": "Bertie County", "value": "C139339"},
                    {"label": "Bessemer City", "value": "C139340"},
                    {"label": "Bethel", "value": "C139341"},
                    {"label": "Bethlehem", "value": "C139342"},
                    {"label": "Beulaville", "value": "C139343"},
                    {"label": "Biltmore Forest", "value": "C139344"},
                    {"label": "Biscoe", "value": "C139345"},
                    {"label": "Black Mountain", "value": "C139346"},
                    {"label": "Bladen County", "value": "C139347"},
                    {"label": "Bladenboro", "value": "C139348"},
                    {"label": "Blowing Rock", "value": "C139349"},
                    {"label": "Boiling Spring Lakes", "value": "C139350"},
                    {"label": "Boiling Springs", "value": "C139351"},
                    {"label": "Bolivia", "value": "C139352"},
                    {"label": "Boone", "value": "C139353"},
                    {"label": "Boonville", "value": "C139354"},
                    {"label": "Brevard", "value": "C139355"},
                    {"label": "Brices Creek", "value": "C139356"},
                    {"label": "Broad Creek", "value": "C139357"},
                    {"label": "Broadway", "value": "C139358"},
                    {"label": "Brogden", "value": "C139359"},
                    {"label": "Brunswick", "value": "C139360"},
                    {"label": "Brunswick County", "value": "C139361"},
                    {"label": "Bryson City", "value": "C139362"},
                    {"label": "Buies Creek", "value": "C139363"},
                    {"label": "Buncombe County", "value": "C139364"},
                    {"label": "Burgaw", "value": "C139365"},
                    {"label": "Burke County", "value": "C139366"},
                    {"label": "Burlington", "value": "C139367"},
                    {"label": "Burnsville", "value": "C139368"},
                    {"label": "Butner", "value": "C139369"},
                    {"label": "Buxton", "value": "C139370"},
                    {"label": "Cabarrus County", "value": "C139371"},
                    {"label": "Cajahs Mountain", "value": "C139372"},
                    {"label": "Calabash", "value": "C139373"},
                    {"label": "Caldwell County", "value": "C139374"},
                    {"label": "Camden", "value": "C139375"},
                    {"label": "Camden County", "value": "C139376"},
                    {"label": "Canton", "value": "C139377"},
                    {"label": "Cape Carteret", "value": "C139378"},
                    {"label": "Carolina Beach", "value": "C139379"},
                    {"label": "Carolina Shores", "value": "C139380"},
                    {"label": "Carrboro", "value": "C139381"},
                    {"label": "Carteret County", "value": "C139382"},
                    {"label": "Carthage", "value": "C139383"},
                    {"label": "Cary", "value": "C139384"},
                    {"label": "Castle Hayne", "value": "C139385"},
                    {"label": "Caswell County", "value": "C139386"},
                    {"label": "Catawba County", "value": "C139387"},
                    {"label": "Cedar Point", "value": "C139388"},
                    {"label": "Chadbourn", "value": "C139389"},
                    {"label": "Chapel Hill", "value": "C139390"},
                    {"label": "Charlotte", "value": "C139391"},
                    {"label": "Chatham County", "value": "C139392"},
                    {"label": "Cherokee", "value": "C139393"},
                    {"label": "Cherokee County", "value": "C139394"},
                    {"label": "Cherryville", "value": "C139395"},
                    {"label": "China Grove", "value": "C139396"},
                    {"label": "Chowan County", "value": "C139397"},
                    {"label": "Claremont", "value": "C139398"},
                    {"label": "Clay County", "value": "C139399"},
                    {"label": "Clayton", "value": "C139400"},
                    {"label": "Clemmons", "value": "C139401"},
                    {"label": "Cleveland County", "value": "C139402"},
                    {"label": "Clinton", "value": "C139403"},
                    {"label": "Clyde", "value": "C139404"},
                    {"label": "Coats", "value": "C139405"},
                    {"label": "Columbia", "value": "C139406"},
                    {"label": "Columbus", "value": "C139407"},
                    {"label": "Columbus County", "value": "C139408"},
                    {"label": "Concord", "value": "C139409"},
                    {"label": "Connelly Springs", "value": "C139410"},
                    {"label": "Conover", "value": "C139411"},
                    {"label": "Cordova", "value": "C139412"},
                    {"label": "Cornelius", "value": "C139413"},
                    {"label": "Cove Creek", "value": "C139414"},
                    {"label": "Cramerton", "value": "C139415"},
                    {"label": "Craven County", "value": "C139416"},
                    {"label": "Creedmoor", "value": "C139417"},
                    {"label": "Cricket", "value": "C139418"},
                    {"label": "Cullowhee", "value": "C139419"},
                    {"label": "Cumberland County", "value": "C139420"},
                    {"label": "Currituck", "value": "C139421"},
                    {"label": "Currituck County", "value": "C139422"},
                    {"label": "Dallas", "value": "C139423"},
                    {"label": "Dana", "value": "C139424"},
                    {"label": "Danbury", "value": "C139425"},
                    {"label": "Dare County", "value": "C139426"},
                    {"label": "Davidson", "value": "C139427"},
                    {"label": "Davidson County", "value": "C139428"},
                    {"label": "Davie County", "value": "C139429"},
                    {"label": "Denton", "value": "C139430"},
                    {"label": "Denver", "value": "C139431"},
                    {"label": "Dobson", "value": "C139432"},
                    {"label": "Drexel", "value": "C139433"},
                    {"label": "Dunn", "value": "C139434"},
                    {"label": "Duplin County", "value": "C139435"},
                    {"label": "Durham", "value": "C139436"},
                    {"label": "Durham County", "value": "C139437"},
                    {"label": "East Flat Rock", "value": "C139438"},
                    {"label": "East Rockingham", "value": "C139439"},
                    {"label": "East Spencer", "value": "C139440"},
                    {"label": "Eastover", "value": "C139441"},
                    {"label": "Eden", "value": "C139442"},
                    {"label": "Edenton", "value": "C139443"},
                    {"label": "Edgecombe County", "value": "C139444"},
                    {"label": "Edneyville", "value": "C139445"},
                    {"label": "Elizabeth City", "value": "C139446"},
                    {"label": "Elizabethtown", "value": "C139447"},
                    {"label": "Elkin", "value": "C139448"},
                    {"label": "Ellerbe", "value": "C139449"},
                    {"label": "Elm City", "value": "C139450"},
                    {"label": "Elon", "value": "C139451"},
                    {"label": "Elroy", "value": "C139452"},
                    {"label": "Emerald Isle", "value": "C139453"},
                    {"label": "Enfield", "value": "C139454"},
                    {"label": "Enochville", "value": "C139455"},
                    {"label": "Erwin", "value": "C139456"},
                    {"label": "Etowah", "value": "C139457"},
                    {"label": "Fairfield Harbour", "value": "C139458"},
                    {"label": "Fairmont", "value": "C139459"},
                    {"label": "Fairplains", "value": "C139460"},
                    {"label": "Fairview", "value": "C139461"},
                    {"label": "Farmville", "value": "C139462"},
                    {"label": "Fayetteville", "value": "C139463"},
                    {"label": "Fearrington Village", "value": "C139464"},
                    {"label": "Flat Rock", "value": "C139465"},
                    {"label": "Fletcher", "value": "C139466"},
                    {"label": "Forest City", "value": "C139467"},
                    {"label": "Forest Oaks", "value": "C139468"},
                    {"label": "Forsyth County", "value": "C139469"},
                    {"label": "Fort Bragg", "value": "C139470"},
                    {"label": "Foscoe", "value": "C139471"},
                    {"label": "Four Oaks", "value": "C139472"},
                    {"label": "Franklin", "value": "C139473"},
                    {"label": "Franklin County", "value": "C139474"},
                    {"label": "Franklinton", "value": "C139475"},
                    {"label": "Franklinville", "value": "C139476"},
                    {"label": "Fremont", "value": "C139477"},
                    {"label": "Fruitland", "value": "C139478"},
                    {"label": "Fuquay-Varina", "value": "C139479"},
                    {"label": "Gamewell", "value": "C139480"},
                    {"label": "Garner", "value": "C139481"},
                    {"label": "Gaston", "value": "C139482"},
                    {"label": "Gaston County", "value": "C139483"},
                    {"label": "Gastonia", "value": "C139484"},
                    {"label": "Gates County", "value": "C139485"},
                    {"label": "Gatesville", "value": "C139486"},
                    {"label": "Gibsonville", "value": "C139487"},
                    {"label": "Glen Alpine", "value": "C139488"},
                    {"label": "Glen Raven", "value": "C139489"},
                    {"label": "Goldsboro", "value": "C139490"},
                    {"label": "Gorman", "value": "C139491"},
                    {"label": "Graham", "value": "C139492"},
                    {"label": "Graham County", "value": "C139493"},
                    {"label": "Granite Falls", "value": "C139494"},
                    {"label": "Granite Quarry", "value": "C139495"},
                    {"label": "Granville County", "value": "C139496"},
                    {"label": "Green Level", "value": "C139497"},
                    {"label": "Greene County", "value": "C139498"},
                    {"label": "Greensboro", "value": "C139499"},
                    {"label": "Greenville", "value": "C139500"},
                    {"label": "Grifton", "value": "C139501"},
                    {"label": "Guilford County", "value": "C139502"},
                    {"label": "Half Moon", "value": "C139503"},
                    {"label": "Halifax", "value": "C139504"},
                    {"label": "Halifax County", "value": "C139505"},
                    {"label": "Hamlet", "value": "C139506"},
                    {"label": "Hampstead", "value": "C139507"},
                    {"label": "Harkers Island", "value": "C139508"},
                    {"label": "Harnett County", "value": "C139509"},
                    {"label": "Harrisburg", "value": "C139510"},
                    {"label": "Havelock", "value": "C139511"},
                    {"label": "Haw River", "value": "C139512"},
                    {"label": "Hayesville", "value": "C139513"},
                    {"label": "Hays", "value": "C139514"},
                    {"label": "Haywood County", "value": "C139515"},
                    {"label": "Hazelwood", "value": "C139516"},
                    {"label": "Hemby Bridge", "value": "C139517"},
                    {"label": "Henderson", "value": "C139518"},
                    {"label": "Henderson County", "value": "C139519"},
                    {"label": "Hendersonville", "value": "C139520"},
                    {"label": "Hertford", "value": "C139521"},
                    {"label": "Hertford County", "value": "C139522"},
                    {"label": "Hickory", "value": "C139523"},
                    {"label": "High Point", "value": "C139524"},
                    {"label": "Hildebran", "value": "C139525"},
                    {"label": "Hillsborough", "value": "C139526"},
                    {"label": "Hoke County", "value": "C139527"},
                    {"label": "Holly Ridge", "value": "C139528"},
                    {"label": "Holly Springs", "value": "C139529"},
                    {"label": "Hoopers Creek", "value": "C139530"},
                    {"label": "Hope Mills", "value": "C139531"},
                    {"label": "Horse Shoe", "value": "C139532"},
                    {"label": "Hudson", "value": "C139533"},
                    {"label": "Huntersville", "value": "C139534"},
                    {"label": "Hyde County", "value": "C139535"},
                    {"label": "Icard", "value": "C139536"},
                    {"label": "Indian Trail", "value": "C139537"},
                    {"label": "Iredell County", "value": "C139538"},
                    {"label": "Jackson", "value": "C139539"},
                    {"label": "Jackson County", "value": "C139540"},
                    {"label": "Jacksonville", "value": "C139541"},
                    {"label": "James City", "value": "C139542"},
                    {"label": "Jamestown", "value": "C139543"},
                    {"label": "Jefferson", "value": "C139544"},
                    {"label": "Johnston County", "value": "C139545"},
                    {"label": "Jones County", "value": "C139546"},
                    {"label": "Jonesville", "value": "C139547"},
                    {"label": "Kannapolis", "value": "C139548"},
                    {"label": "Kenansville", "value": "C139549"},
                    {"label": "Kenly", "value": "C139550"},
                    {"label": "Kernersville", "value": "C139551"},
                    {"label": "Kill Devil Hills", "value": "C139552"},
                    {"label": "King", "value": "C139553"},
                    {"label": "Kings Grant", "value": "C139554"},
                    {"label": "Kings Mountain", "value": "C139555"},
                    {"label": "Kinston", "value": "C139556"},
                    {"label": "Kitty Hawk", "value": "C139557"},
                    {"label": "Knightdale", "value": "C139558"},
                    {"label": "Kure Beach", "value": "C139559"},
                    {"label": "La Grange", "value": "C139560"},
                    {"label": "Lake Junaluska", "value": "C139561"},
                    {"label": "Lake Lure", "value": "C139562"},
                    {"label": "Lake Norman of Catawba", "value": "C139563"},
                    {"label": "Lake Park", "value": "C139564"},
                    {"label": "Lake Waccamaw", "value": "C139565"},
                    {"label": "Landis", "value": "C139566"},
                    {"label": "Laurel Hill", "value": "C139567"},
                    {"label": "Laurel Park", "value": "C139568"},
                    {"label": "Laurinburg", "value": "C139569"},
                    {"label": "Lee County", "value": "C139570"},
                    {"label": "Leland", "value": "C139571"},
                    {"label": "Lenoir", "value": "C139572"},
                    {"label": "Lenoir County", "value": "C139573"},
                    {"label": "Lewisville", "value": "C139574"},
                    {"label": "Lexington", "value": "C139575"},
                    {"label": "Liberty", "value": "C139576"},
                    {"label": "Lillington", "value": "C139577"},
                    {"label": "Lincoln County", "value": "C139578"},
                    {"label": "Lincolnton", "value": "C139579"},
                    {"label": "Locust", "value": "C139580"},
                    {"label": "Long Beach", "value": "C139581"},
                    {"label": "Longview", "value": "C139582"},
                    {"label": "Louisburg", "value": "C139583"},
                    {"label": "Lowell", "value": "C139584"},
                    {"label": "Lowesville", "value": "C139585"},
                    {"label": "Lucama", "value": "C139586"},
                    {"label": "Lumberton", "value": "C139587"},
                    {"label": "Macon County", "value": "C139588"},
                    {"label": "Madison", "value": "C139589"},
                    {"label": "Madison County", "value": "C139590"},
                    {"label": "Maggie Valley", "value": "C139591"},
                    {"label": "Maiden", "value": "C139592"},
                    {"label": "Manteo", "value": "C139593"},
                    {"label": "Mar-Mac", "value": "C139594"},
                    {"label": "Marion", "value": "C139595"},
                    {"label": "Mars Hill", "value": "C139596"},
                    {"label": "Marshall", "value": "C139597"},
                    {"label": "Marshville", "value": "C139598"},
                    {"label": "Martin County", "value": "C139599"},
                    {"label": "Marvin", "value": "C139600"},
                    {"label": "Masonboro", "value": "C139601"},
                    {"label": "Matthews", "value": "C139602"},
                    {"label": "Maury", "value": "C139603"},
                    {"label": "Maxton", "value": "C139604"},
                    {"label": "Mayodan", "value": "C139605"},
                    {"label": "Maysville", "value": "C139606"},
                    {"label": "McDowell County", "value": "C139607"},
                    {"label": "McLeansville", "value": "C139608"},
                    {"label": "Mebane", "value": "C139609"},
                    {"label": "Mecklenburg County", "value": "C139610"},
                    {"label": "Midland", "value": "C139611"},
                    {"label": "Midway", "value": "C139612"},
                    {"label": "Millers Creek", "value": "C139613"},
                    {"label": "Mills River", "value": "C139614"},
                    {"label": "Mineral Springs", "value": "C139615"},
                    {"label": "Mint Hill", "value": "C139616"},
                    {"label": "Mitchell County", "value": "C139617"},
                    {"label": "Mocksville", "value": "C139618"},
                    {"label": "Monroe", "value": "C139619"},
                    {"label": "Montgomery County", "value": "C139620"},
                    {"label": "Moore County", "value": "C139621"},
                    {"label": "Mooresville", "value": "C139622"},
                    {"label": "Moravian Falls", "value": "C139623"},
                    {"label": "Morehead City", "value": "C139624"},
                    {"label": "Morganton", "value": "C139625"},
                    {"label": "Morrisville", "value": "C139626"},
                    {"label": "Mount Airy", "value": "C139627"},
                    {"label": "Mount Gilead", "value": "C139628"},
                    {"label": "Mount Holly", "value": "C139629"},
                    {"label": "Mount Olive", "value": "C139630"},
                    {"label": "Mount Pleasant", "value": "C139631"},
                    {"label": "Mountain Home", "value": "C139632"},
                    {"label": "Mountain View", "value": "C139633"},
                    {"label": "Moyock", "value": "C139634"},
                    {"label": "Mulberry", "value": "C139635"},
                    {"label": "Murfreesboro", "value": "C139636"},
                    {"label": "Murphy", "value": "C139637"},
                    {"label": "Murraysville", "value": "C139638"},
                    {"label": "Myrtle Grove", "value": "C139639"},
                    {"label": "Nags Head", "value": "C139640"},
                    {"label": "Nash County", "value": "C139641"},
                    {"label": "Nashville", "value": "C139642"},
                    {"label": "Navassa", "value": "C139643"},
                    {"label": "Neuse Forest", "value": "C139644"},
                    {"label": "New Bern", "value": "C139645"},
                    {"label": "New Hanover County", "value": "C139646"},
                    {"label": "Newland", "value": "C139647"},
                    {"label": "Newport", "value": "C139648"},
                    {"label": "Newton", "value": "C139649"},
                    {"label": "Norlina", "value": "C139650"},
                    {"label": "North Wilkesboro", "value": "C139651"},
                    {"label": "Northampton County", "value": "C139652"},
                    {"label": "Northchase", "value": "C139653"},
                    {"label": "Northlakes", "value": "C139654"},
                    {"label": "Norwood", "value": "C139655"},
                    {"label": "Oak Island", "value": "C139656"},
                    {"label": "Oak Ridge", "value": "C139657"},
                    {"label": "Oakboro", "value": "C139658"},
                    {"label": "Ogden", "value": "C139659"},
                    {"label": "Onslow County", "value": "C139660"},
                    {"label": "Orange County", "value": "C139661"},
                    {"label": "Oxford", "value": "C139662"},
                    {"label": "Pamlico County", "value": "C139663"},
                    {"label": "Pasquotank County", "value": "C139664"},
                    {"label": "Pembroke", "value": "C139665"},
                    {"label": "Pender County", "value": "C139666"},
                    {"label": "Perquimans County", "value": "C139667"},
                    {"label": "Person County", "value": "C139668"},
                    {"label": "Pilot Mountain", "value": "C139669"},
                    {"label": "Pine Knoll Shores", "value": "C139670"},
                    {"label": "Pine Level", "value": "C139671"},
                    {"label": "Pinebluff", "value": "C139672"},
                    {"label": "Pinehurst", "value": "C139673"},
                    {"label": "Pinetops", "value": "C139674"},
                    {"label": "Pineville", "value": "C139675"},
                    {"label": "Piney Green", "value": "C139676"},
                    {"label": "Pitt County", "value": "C139677"},
                    {"label": "Pittsboro", "value": "C139678"},
                    {"label": "Plain View", "value": "C139679"},
                    {"label": "Pleasant Garden", "value": "C139680"},
                    {"label": "Pleasant Hill", "value": "C139681"},
                    {"label": "Plymouth", "value": "C139682"},
                    {"label": "Polk County", "value": "C139683"},
                    {"label": "Polkton", "value": "C139684"},
                    {"label": "Princeton", "value": "C139685"},
                    {"label": "Princeville", "value": "C139686"},
                    {"label": "Pumpkin Center", "value": "C139687"},
                    {"label": "Raeford", "value": "C139688"},
                    {"label": "Raleigh", "value": "C139689"},
                    {"label": "Ramseur", "value": "C139690"},
                    {"label": "Randleman", "value": "C139691"},
                    {"label": "Randolph County", "value": "C139692"},
                    {"label": "Ranlo", "value": "C139693"},
                    {"label": "Red Oak", "value": "C139694"},
                    {"label": "Red Springs", "value": "C139695"},
                    {"label": "Reidsville", "value": "C139696"},
                    {"label": "Rhodhiss", "value": "C139697"},
                    {"label": "Richlands", "value": "C139698"},
                    {"label": "Richmond County", "value": "C139699"},
                    {"label": "River Bend", "value": "C139700"},
                    {"label": "River Road", "value": "C139701"},
                    {"label": "Roanoke Rapids", "value": "C139702"},
                    {"label": "Robbins", "value": "C139703"},
                    {"label": "Robbinsville", "value": "C139704"},
                    {"label": "Robersonville", "value": "C139705"},
                    {"label": "Robeson County", "value": "C139706"},
                    {"label": "Rockfish", "value": "C139707"},
                    {"label": "Rockingham", "value": "C139708"},
                    {"label": "Rockingham County", "value": "C139709"},
                    {"label": "Rockwell", "value": "C139710"},
                    {"label": "Rocky Mount", "value": "C139711"},
                    {"label": "Rocky Point", "value": "C139712"},
                    {"label": "Rolesville", "value": "C139713"},
                    {"label": "Rose Hill", "value": "C139714"},
                    {"label": "Roseboro", "value": "C139715"},
                    {"label": "Rowan County", "value": "C139716"},
                    {"label": "Rowland", "value": "C139717"},
                    {"label": "Roxboro", "value": "C139718"},
                    {"label": "Royal Pines", "value": "C139719"},
                    {"label": "Rural Hall", "value": "C139720"},
                    {"label": "Rutherford College", "value": "C139721"},
                    {"label": "Rutherford County", "value": "C139722"},
                    {"label": "Rutherfordton", "value": "C139723"},
                    {"label": "Saint James", "value": "C139724"},
                    {"label": "Saint Pauls", "value": "C139725"},
                    {"label": "Saint Stephens", "value": "C139726"},
                    {"label": "Salem", "value": "C139727"},
                    {"label": "Salisbury", "value": "C139728"},
                    {"label": "Sampson County", "value": "C139729"},
                    {"label": "Sanford", "value": "C139730"},
                    {"label": "Sawmills", "value": "C139731"},
                    {"label": "Saxapahaw", "value": "C139732"},
                    {"label": "Scotland County", "value": "C139733"},
                    {"label": "Scotland Neck", "value": "C139734"},
                    {"label": "Scotts Mill", "value": "C139735"},
                    {"label": "Sea Breeze", "value": "C139736"},
                    {"label": "Seagate", "value": "C139737"},
                    {"label": "Selma", "value": "C139738"},
                    {"label": "Seven Lakes", "value": "C139739"},
                    {"label": "Shallotte", "value": "C139740"},
                    {"label": "Sharpsburg", "value": "C139741"},
                    {"label": "Shelby", "value": "C139742"},
                    {"label": "Sherrills Ford", "value": "C139743"},
                    {"label": "Siler City", "value": "C139744"},
                    {"label": "Silver Lake", "value": "C139745"},
                    {"label": "Skippers Corner", "value": "C139746"},
                    {"label": "Smithfield", "value": "C139747"},
                    {"label": "Sneads Ferry", "value": "C139748"},
                    {"label": "Snow Hill", "value": "C139749"},
                    {"label": "South Gastonia", "value": "C139750"},
                    {"label": "South Henderson", "value": "C139751"},
                    {"label": "South Rosemary", "value": "C139752"},
                    {"label": "Southern Pines", "value": "C139753"},
                    {"label": "Southern Shores", "value": "C139754"},
                    {"label": "Southmont", "value": "C139755"},
                    {"label": "Southport", "value": "C139756"},
                    {"label": "Sparta", "value": "C139757"},
                    {"label": "Spencer", "value": "C139758"},
                    {"label": "Spindale", "value": "C139759"},
                    {"label": "Spring Hope", "value": "C139760"},
                    {"label": "Spring Lake", "value": "C139761"},
                    {"label": "Spruce Pine", "value": "C139762"},
                    {"label": "Stallings", "value": "C139763"},
                    {"label": "Stanfield", "value": "C139764"},
                    {"label": "Stanley", "value": "C139765"},
                    {"label": "Stanly County", "value": "C139766"},
                    {"label": "Statesville", "value": "C139767"},
                    {"label": "Stedman", "value": "C139768"},
                    {"label": "Stokes County", "value": "C139769"},
                    {"label": "Stokesdale", "value": "C139770"},
                    {"label": "Stoneville", "value": "C139771"},
                    {"label": "Stony Point", "value": "C139772"},
                    {"label": "Summerfield", "value": "C139773"},
                    {"label": "Sunset Beach", "value": "C139774"},
                    {"label": "Surf City", "value": "C139775"},
                    {"label": "Surry County", "value": "C139776"},
                    {"label": "Swain County", "value": "C139777"},
                    {"label": "Swannanoa", "value": "C139778"},
                    {"label": "Swanquarter", "value": "C139779"},
                    {"label": "Swansboro", "value": "C139780"},
                    {"label": "Swepsonville", "value": "C139781"},
                    {"label": "Sylva", "value": "C139782"},
                    {"label": "Tabor City", "value": "C139783"},
                    {"label": "Tarboro", "value": "C139784"},
                    {"label": "Taylorsville", "value": "C139785"},
                    {"label": "Thomasville", "value": "C139786"},
                    {"label": "Thurmond", "value": "C139787"},
                    {"label": "Toast", "value": "C139788"},
                    {"label": "Tobaccoville", "value": "C139789"},
                    {"label": "Transylvania County", "value": "C139790"},
                    {"label": "Trent Woods", "value": "C139791"},
                    {"label": "Trenton", "value": "C139792"},
                    {"label": "Trinity", "value": "C139793"},
                    {"label": "Troutman", "value": "C139794"},
                    {"label": "Troy", "value": "C139795"},
                    {"label": "Tryon", "value": "C139796"},
                    {"label": "Tyro", "value": "C139797"},
                    {"label": "Tyrrell County", "value": "C139798"},
                    {"label": "Union County", "value": "C139799"},
                    {"label": "Unionville", "value": "C139800"},
                    {"label": "Valdese", "value": "C139801"},
                    {"label": "Valley Hill", "value": "C139802"},
                    {"label": "Vance County", "value": "C139803"},
                    {"label": "Vander", "value": "C139804"},
                    {"label": "Wadesboro", "value": "C139805"},
                    {"label": "Wake County", "value": "C139806"},
                    {"label": "Wake Forest", "value": "C139807"},
                    {"label": "Walkertown", "value": "C139808"},
                    {"label": "Wallace", "value": "C139809"},
                    {"label": "Wallburg", "value": "C139810"},
                    {"label": "Walnut Cove", "value": "C139811"},
                    {"label": "Wanchese", "value": "C139812"},
                    {"label": "Warren County", "value": "C139813"},
                    {"label": "Warrenton", "value": "C139814"},
                    {"label": "Warsaw", "value": "C139815"},
                    {"label": "Washington", "value": "C139816"},
                    {"label": "Washington County", "value": "C139817"},
                    {"label": "Watauga County", "value": "C139818"},
                    {"label": "Waxhaw", "value": "C139819"},
                    {"label": "Wayne County", "value": "C139820"},
                    {"label": "Waynesville", "value": "C139821"},
                    {"label": "Weaverville", "value": "C139822"},
                    {"label": "Weddington", "value": "C139823"},
                    {"label": "Welcome", "value": "C139824"},
                    {"label": "Weldon", "value": "C139825"},
                    {"label": "Wendell", "value": "C139826"},
                    {"label": "Wentworth", "value": "C139827"},
                    {"label": "Wesley Chapel", "value": "C139828"},
                    {"label": "West Canton", "value": "C139829"},
                    {"label": "West Jefferson", "value": "C139830"},
                    {"label": "West Marion", "value": "C139831"},
                    {"label": "West Raleigh", "value": "C139832"},
                    {"label": "Westport", "value": "C139833"},
                    {"label": "Whispering Pines", "value": "C139834"},
                    {"label": "White Plains", "value": "C139835"},
                    {"label": "Whiteville", "value": "C139836"},
                    {"label": "Wilkes County", "value": "C139837"},
                    {"label": "Wilkesboro", "value": "C139838"},
                    {"label": "Williamston", "value": "C139839"},
                    {"label": "Wilmington", "value": "C139840"},
                    {"label": "Wilson", "value": "C139841"},
                    {"label": "Wilson County", "value": "C139842"},
                    {"label": "Wilsons Mills", "value": "C139843"},
                    {"label": "Windsor", "value": "C139844"},
                    {"label": "Wingate", "value": "C139845"},
                    {"label": "Winston-Salem", "value": "C139846"},
                    {"label": "Winterville", "value": "C139847"},
                    {"label": "Winton", "value": "C139848"},
                    {"label": "Woodfin", "value": "C139849"},
                    {"label": "Wrightsboro", "value": "C139850"},
                    {"label": "Wrightsville Beach", "value": "C139851"},
                    {"label": "Yadkin County", "value": "C139852"},
                    {"label": "Yadkinville", "value": "C139853"},
                    {"label": "Yancey County", "value": "C139854"},
                    {"label": "Yanceyville", "value": "C139855"},
                    {"label": "Youngsville", "value": "C139856"},
                    {"label": "Zebulon", "value": "C139857"}],
 "North Central Province": [{"label": "Anuradhapura", "value": "C115318"},
                            {"label": "Anuradhapura District",
                             "value": "C115319"},
                            {"label": "Mihintale", "value": "C115320"},
                            {"label": "Polonnaruwa", "value": "C115321"},
                            {"label": "Polonnaruwa District",
                             "value": "C115322"}],
 "North Chungcheong Province": [{"label": "Boeun-gun", "value": "C108352"},
                                {"label": "Cheongju-si", "value": "C108353"},
                                {"label": "Chinchon", "value": "C108354"},
                                {"label": "Chungju-si", "value": "C108355"},
                                {"label": "Danyang-gun", "value": "C108356"},
                                {"label": "Eumseong-gun", "value": "C108357"},
                                {"label": "Goesan-gun", "value": "C108358"},
                                {"label": "Jecheon-si", "value": "C108359"},
                                {"label": "Jeungpyeong-gun",
                                 "value": "C108360"},
                                {"label": "Jincheon-gun", "value": "C108361"},
                                {"label": "Koesan", "value": "C108362"},
                                {"label": "Okcheon", "value": "C108363"},
                                {"label": "Okcheon-gun", "value": "C108364"},
                                {"label": "Yeongdong", "value": "C108365"},
                                {"label": "Yeongdong-gun", "value": "C108366"}],
 "North Dakota": [{"label": "Adams County", "value": "C139858"},
                  {"label": "Amidon", "value": "C139859"},
                  {"label": "Ashley", "value": "C139860"},
                  {"label": "Barnes County", "value": "C139861"},
                  {"label": "Beach", "value": "C139862"},
                  {"label": "Belcourt", "value": "C139863"},
                  {"label": "Belfield", "value": "C139864"},
                  {"label": "Benson County", "value": "C139865"},
                  {"label": "Beulah", "value": "C139866"},
                  {"label": "Billings County", "value": "C139867"},
                  {"label": "Bismarck", "value": "C139868"},
                  {"label": "Bottineau", "value": "C139869"},
                  {"label": "Bottineau County", "value": "C139870"},
                  {"label": "Bowbells", "value": "C139871"},
                  {"label": "Bowman", "value": "C139872"},
                  {"label": "Bowman County", "value": "C139873"},
                  {"label": "Burke County", "value": "C139874"},
                  {"label": "Burleigh County", "value": "C139875"},
                  {"label": "Burlington", "value": "C139876"},
                  {"label": "Cando", "value": "C139877"},
                  {"label": "Carrington", "value": "C139878"},
                  {"label": "Carson", "value": "C139879"},
                  {"label": "Cass County", "value": "C139880"},
                  {"label": "Casselton", "value": "C139881"},
                  {"label": "Cavalier", "value": "C139882"},
                  {"label": "Cavalier County", "value": "C139883"},
                  {"label": "Center", "value": "C139884"},
                  {"label": "Cooperstown", "value": "C139885"},
                  {"label": "Crosby", "value": "C139886"},
                  {"label": "Devils Lake", "value": "C139887"},
                  {"label": "Dickey County", "value": "C139888"},
                  {"label": "Dickinson", "value": "C139889"},
                  {"label": "Divide County", "value": "C139890"},
                  {"label": "Dunn County", "value": "C139891"},
                  {"label": "Eddy County", "value": "C139892"},
                  {"label": "Ellendale", "value": "C139893"},
                  {"label": "Emmons County", "value": "C139894"},
                  {"label": "Fargo", "value": "C139895"},
                  {"label": "Fessenden", "value": "C139896"},
                  {"label": "Finley", "value": "C139897"},
                  {"label": "Forman", "value": "C139898"},
                  {"label": "Fort Totten", "value": "C139899"},
                  {"label": "Fort Yates", "value": "C139900"},
                  {"label": "Foster County", "value": "C139901"},
                  {"label": "Garrison", "value": "C139902"},
                  {"label": "Golden Valley County", "value": "C139903"},
                  {"label": "Grafton", "value": "C139904"},
                  {"label": "Grand Forks", "value": "C139905"},
                  {"label": "Grand Forks Air Force Base", "value": "C139906"},
                  {"label": "Grand Forks County", "value": "C139907"},
                  {"label": "Grant County", "value": "C139908"},
                  {"label": "Griggs County", "value": "C139909"},
                  {"label": "Harvey", "value": "C139910"},
                  {"label": "Hazen", "value": "C139911"},
                  {"label": "Hettinger", "value": "C139912"},
                  {"label": "Hettinger County", "value": "C139913"},
                  {"label": "Hillsboro", "value": "C139914"},
                  {"label": "Horace", "value": "C139915"},
                  {"label": "Jamestown", "value": "C139916"},
                  {"label": "Kenmare", "value": "C139917"},
                  {"label": "Kidder County", "value": "C139918"},
                  {"label": "Killdeer", "value": "C139919"},
                  {"label": "Lakota", "value": "C139920"},
                  {"label": "LaMoure County", "value": "C139921"},
                  {"label": "Langdon", "value": "C139922"},
                  {"label": "Larimore", "value": "C139923"},
                  {"label": "Lincoln", "value": "C139924"},
                  {"label": "Linton", "value": "C139925"},
                  {"label": "Lisbon", "value": "C139926"},
                  {"label": "Logan County", "value": "C139927"},
                  {"label": "Mandan", "value": "C139928"},
                  {"label": "Manning", "value": "C139929"},
                  {"label": "Mayville", "value": "C139930"},
                  {"label": "McClusky", "value": "C139931"},
                  {"label": "McHenry County", "value": "C139932"},
                  {"label": "McIntosh County", "value": "C139933"},
                  {"label": "McKenzie County", "value": "C139934"},
                  {"label": "McLean County", "value": "C139935"},
                  {"label": "Medora", "value": "C139936"},
                  {"label": "Mercer County", "value": "C139937"},
                  {"label": "Minnewaukan", "value": "C139938"},
                  {"label": "Minot", "value": "C139939"},
                  {"label": "Minot Air Force Base", "value": "C139940"},
                  {"label": "Mohall", "value": "C139941"},
                  {"label": "Morton County", "value": "C139942"},
                  {"label": "Mott", "value": "C139943"},
                  {"label": "Mountrail County", "value": "C139944"},
                  {"label": "Napoleon", "value": "C139945"},
                  {"label": "Nelson County", "value": "C139946"},
                  {"label": "New Rockford", "value": "C139947"},
                  {"label": "New Town", "value": "C139948"},
                  {"label": "Oakes", "value": "C139949"},
                  {"label": "Oliver County", "value": "C139950"},
                  {"label": "Park River", "value": "C139951"},
                  {"label": "Parshall", "value": "C139952"},
                  {"label": "Pembina County", "value": "C139953"},
                  {"label": "Pierce County", "value": "C139954"},
                  {"label": "Ramsey County", "value": "C139955"},
                  {"label": "Ransom County", "value": "C139956"},
                  {"label": "Renville County", "value": "C139957"},
                  {"label": "Richland County", "value": "C139958"},
                  {"label": "Rolette County", "value": "C139959"},
                  {"label": "Rolla", "value": "C139960"},
                  {"label": "Rugby", "value": "C139961"},
                  {"label": "Sargent County", "value": "C139962"},
                  {"label": "Sheldon", "value": "C139963"},
                  {"label": "Shell Valley", "value": "C139964"},
                  {"label": "Sheridan County", "value": "C139965"},
                  {"label": "Sioux County", "value": "C139966"},
                  {"label": "Slope County", "value": "C139967"},
                  {"label": "Stanley", "value": "C139968"},
                  {"label": "Stanton", "value": "C139969"},
                  {"label": "Stark County", "value": "C139970"},
                  {"label": "Steele", "value": "C139971"},
                  {"label": "Steele County", "value": "C139972"},
                  {"label": "Stutsman County", "value": "C139973"},
                  {"label": "Surrey", "value": "C139974"},
                  {"label": "Thompson", "value": "C139975"},
                  {"label": "Tioga", "value": "C139976"},
                  {"label": "Towner", "value": "C139977"},
                  {"label": "Towner County", "value": "C139978"},
                  {"label": "Traill County", "value": "C139979"},
                  {"label": "Valley City", "value": "C139980"},
                  {"label": "Velva", "value": "C139981"},
                  {"label": "Wahpeton", "value": "C139982"},
                  {"label": "Walsh County", "value": "C139983"},
                  {"label": "Ward County", "value": "C139984"},
                  {"label": "Washburn", "value": "C139985"},
                  {"label": "Watford City", "value": "C139986"},
                  {"label": "Wells County", "value": "C139987"},
                  {"label": "West Fargo", "value": "C139988"},
                  {"label": "Williams County", "value": "C139989"},
                  {"label": "Williston", "value": "C139990"}],
 "North Darfur": [{"label": "El Fasher", "value": "C115409"},
                  {"label": "Kutum", "value": "C115410"},
                  {"label": "Umm Kaddadah", "value": "C115411"}],
 "North Denmark Region": [{"label": "Aalborg", "value": "C23558"},
                          {"label": "Aars", "value": "C23559"},
                          {"label": "Abybro", "value": "C23560"},
                          {"label": "Alborg Kommune", "value": "C23561"},
                          {"label": "Alestrup", "value": "C23562"},
                          {"label": "Arden", "value": "C23563"},
                          {"label": "Brovst", "value": "C23564"},
                          {"label": "Bronderslev", "value": "C23565"},
                          {"label": "Bronderslev Kommune", "value": "C23566"},
                          {"label": "Dronninglund", "value": "C23567"},
                          {"label": "Farso", "value": "C23568"},
                          {"label": "Fjerritslev", "value": "C23569"},
                          {"label": "Frederikshavn", "value": "C23570"},
                          {"label": "Frederikshavn Kommune", "value": "C23571"},
                          {"label": "Frejlev", "value": "C23572"},
                          {"label": "Gistrup", "value": "C23573"},
                          {"label": "Hadsund", "value": "C23574"},
                          {"label": "Hals", "value": "C23575"},
                          {"label": "Hanstholm", "value": "C23576"},
                          {"label": "Hirtshals", "value": "C23577"},
                          {"label": "Hjallerup", "value": "C23578"},
                          {"label": "Hjorring", "value": "C23579"},
                          {"label": "Hjorring Kommune", "value": "C23580"},
                          {"label": "Hobro", "value": "C23581"},
                          {"label": "Hurup", "value": "C23582"},
                          {"label": "Jammerbugt Kommune", "value": "C23583"},
                          {"label": "Kas", "value": "C23584"},
                          {"label": "Klarup", "value": "C23585"},
                          {"label": "Laeso Kommune", "value": "C23586"},
                          {"label": "Logstor", "value": "C23587"},
                          {"label": "Lokken", "value": "C23588"},
                          {"label": "Mariager", "value": "C23589"},
                          {"label": "Mariagerfjord Kommune", "value": "C23590"},
                          {"label": "Morso Kommune", "value": "C23591"},
                          {"label": "Nibe", "value": "C23592"},
                          {"label": "Norresundby", "value": "C23593"},
                          {"label": "Nykobing Mors", "value": "C23594"},
                          {"label": "Pandrup", "value": "C23595"},
                          {"label": "Rebild Kommune", "value": "C23596"},
                          {"label": "Saeby", "value": "C23597"},
                          {"label": "Sindal", "value": "C23598"},
                          {"label": "Skagen", "value": "C23599"},
                          {"label": "Skorping", "value": "C23600"},
                          {"label": "Storvorde", "value": "C23601"},
                          {"label": "Stovring", "value": "C23602"},
                          {"label": "Strandby", "value": "C23603"},
                          {"label": "Svenstrup", "value": "C23604"},
                          {"label": "Tars", "value": "C23605"},
                          {"label": "Thisted", "value": "C23606"},
                          {"label": "Thisted Kommune", "value": "C23607"},
                          {"label": "Vadum", "value": "C23608"},
                          {"label": "Vestbjerg", "value": "C23609"},
                          {"label": "Vester Hassing", "value": "C23610"},
                          {"label": "Vesthimmerland Kommune",
                           "value": "C23611"},
                          {"label": "Vodskov", "value": "C23612"},
                          {"label": "Vra", "value": "C23613"}],
 "North East": [{"label": "Bunkpurugu-Nyakpanduri", "value": "C41529"},
                {"label": "Chereponi", "value": "C41530"},
                {"label": "East Mamprusi", "value": "C41531"},
                {"label": "Mamprugu-Moagduri", "value": "C41532"},
                {"label": "West Mamprusi", "value": "C41533"},
                {"label": "Yunyoo-Nasuan", "value": "C41534"}],
 "North Governorate": [{"label": "Batroun", "value": "C64983"},
                       {"label": "Bcharre", "value": "C64984"},
                       {"label": "Tripoli", "value": "C64985"}],
 "North Gyeongsang Province": [{"label": "Andong", "value": "C108367"},
                               {"label": "Andong-si", "value": "C108368"},
                               {"label": "Bonghwa-gun", "value": "C108369"},
                               {"label": "Cheongdo-gun", "value": "C108370"},
                               {"label": "Cheongsong gun", "value": "C108371"},
                               {"label": "Cheongsong-gun", "value": "C108372"},
                               {"label": "Chilgok-gun", "value": "C108373"},
                               {"label": "Eisen", "value": "C108374"},
                               {"label": "Gimcheon", "value": "C108375"},
                               {"label": "Gimcheon-si", "value": "C108376"},
                               {"label": "Goryeong-gun", "value": "C108377"},
                               {"label": "Gumi-si", "value": "C108378"},
                               {"label": "Gunwi-gun", "value": "C108379"},
                               {"label": "Gyeongju", "value": "C108380"},
                               {"label": "Gyeongsan-si", "value": "C108381"},
                               {"label": "Hayang", "value": "C108382"},
                               {"label": "Heunghae", "value": "C108383"},
                               {"label": "Jenzan", "value": "C108384"},
                               {"label": "Kunwi", "value": "C108385"},
                               {"label": "Mungyeong", "value": "C108386"},
                               {"label": "Mungyeong-si", "value": "C108387"},
                               {"label": "Pohang", "value": "C108388"},
                               {"label": "Pohang-si", "value": "C108389"},
                               {"label": "Sangju", "value": "C108390"},
                               {"label": "Seongju-gun", "value": "C108391"},
                               {"label": "Uiseong-gun", "value": "C108392"},
                               {"label": "Uljin-gun", "value": "C108393"},
                               {"label": "Ulleung-gun", "value": "C108394"},
                               {"label": "Waegwan", "value": "C108395"},
                               {"label": "Yecheon-gun", "value": "C108396"},
                               {"label": "Yeongcheon-si", "value": "C108397"},
                               {"label": "Yeongdeok-gun", "value": "C108398"},
                               {"label": "Yeongju-si", "value": "C108399"},
                               {"label": "Yeongyang-gun", "value": "C108400"},
                               {"label": "Yeonil", "value": "C108401"}],
 "North Hamgyong Province": [{"label": "Aoji", "value": "C78545"},
                             {"label": "Chongjin", "value": "C78546"},
                             {"label": "Hau-ri", "value": "C78547"},
                             {"label": "Hoemul-li", "value": "C78548"},
                             {"label": "Hoeryong", "value": "C78549"},
                             {"label": "Kilju", "value": "C78550"},
                             {"label": "Kimchaek-si", "value": "C78551"},
                             {"label": "Komusan Il-tong", "value": "C78552"},
                             {"label": "Kyongsong", "value": "C78553"},
                             {"label": "Kyongwon", "value": "C78554"},
                             {"label": "Musan-up", "value": "C78555"},
                             {"label": "Namyang-dong", "value": "C78556"},
                             {"label": "Nanam", "value": "C78557"},
                             {"label": "Onsong", "value": "C78558"},
                             {"label": "Puryong", "value": "C78559"},
                             {"label": "Sungam-nodongjagu", "value": "C78560"}],
 "North Holland": [{"label": "s-Graveland", "value": "C77013"},
                   {"label": "t Horntje", "value": "C77014"},
                   {"label": "t Kabel", "value": "C77015"},
                   {"label": "t Nopeind", "value": "C77016"},
                   {"label": "t Veld", "value": "C77017"},
                   {"label": "t Zand", "value": "C77018"},
                   {"label": "Aagtdorp", "value": "C77019"},
                   {"label": "Aalsmeer", "value": "C77020"},
                   {"label": "Aalsmeerderbrug", "value": "C77021"},
                   {"label": "Aartswoud", "value": "C77022"},
                   {"label": "Abbekerk", "value": "C77023"},
                   {"label": "Abbenes", "value": "C77024"},
                   {"label": "Akersloot", "value": "C77025"},
                   {"label": "Alkmaar", "value": "C77026"},
                   {"label": "Amstelveen", "value": "C77027"},
                   {"label": "Amsterdam", "value": "C77028"},
                   {"label": "Amsterdam-Zuidoost", "value": "C77029"},
                   {"label": "Andijk", "value": "C77030"},
                   {"label": "Ankeveen", "value": "C77031"},
                   {"label": "Ankeveense Rade", "value": "C77032"},
                   {"label": "Assendelft", "value": "C77033"},
                   {"label": "Avenhorn", "value": "C77034"},
                   {"label": "Badhoevedorp", "value": "C77035"},
                   {"label": "Bakkum", "value": "C77036"},
                   {"label": "Bangert", "value": "C77037"},
                   {"label": "Bargen", "value": "C77038"},
                   {"label": "Barsingerhorn", "value": "C77039"},
                   {"label": "Beemster", "value": "C77040"},
                   {"label": "Beets", "value": "C77041"},
                   {"label": "Beinsdorp", "value": "C77042"},
                   {"label": "Belt", "value": "C77043"},
                   {"label": "Bennebroek", "value": "C77044"},
                   {"label": "Benningbroek", "value": "C77045"},
                   {"label": "Bentveld", "value": "C77046"},
                   {"label": "Bergen", "value": "C77047"},
                   {"label": "Berkhout", "value": "C77048"},
                   {"label": "Berkmeer", "value": "C77049"},
                   {"label": "Beverwijk", "value": "C77050"},
                   {"label": "Bijlmermeer", "value": "C77051"},
                   {"label": "Bijvanck", "value": "C77052"},
                   {"label": "Binnenstad", "value": "C77053"},
                   {"label": "Blaricum", "value": "C77054"},
                   {"label": "Blauwe Keet", "value": "C77055"},
                   {"label": "Bloemendaal", "value": "C77056"},
                   {"label": "Blokker", "value": "C77057"},
                   {"label": "Bobeldijk", "value": "C77058"},
                   {"label": "Boekel", "value": "C77059"},
                   {"label": "Boesingheliede", "value": "C77060"},
                   {"label": "Bos en Lommer", "value": "C77061"},
                   {"label": "Bovenkarspel", "value": "C77062"},
                   {"label": "Breezand", "value": "C77063"},
                   {"label": "Bregtdorp", "value": "C77064"},
                   {"label": "Breukeleveen", "value": "C77065"},
                   {"label": "Broek in Waterland", "value": "C77066"},
                   {"label": "Broek op Langedijk", "value": "C77067"},
                   {"label": "Buiksloot", "value": "C77068"},
                   {"label": "Buikslotermeer", "value": "C77069"},
                   {"label": "Buitenkaag", "value": "C77070"},
                   {"label": "Buitenveldert", "value": "C77071"},
                   {"label": "Burgerbrug", "value": "C77072"},
                   {"label": "Burgerveen", "value": "C77073"},
                   {"label": "Burgervlotbrug", "value": "C77074"},
                   {"label": "Bussum", "value": "C77075"},
                   {"label": "Butterhuizen", "value": "C77076"},
                   {"label": "Callantsoog", "value": "C77077"},
                   {"label": "Calslagen", "value": "C77078"},
                   {"label": "Camperduin", "value": "C77079"},
                   {"label": "Castricum", "value": "C77080"},
                   {"label": "Catrijp", "value": "C77081"},
                   {"label": "Commandeurs", "value": "C77082"},
                   {"label": "Cruquius", "value": "C77083"},
                   {"label": "De Aker", "value": "C77084"},
                   {"label": "De Cocksdorp", "value": "C77085"},
                   {"label": "De Glip", "value": "C77086"},
                   {"label": "De Goorn", "value": "C77087"},
                   {"label": "De Haukes", "value": "C77088"},
                   {"label": "De Hulk", "value": "C77089"},
                   {"label": "De Koog", "value": "C77090"},
                   {"label": "De Kooy", "value": "C77091"},
                   {"label": "De Kwakel", "value": "C77092"},
                   {"label": "De Maer", "value": "C77093"},
                   {"label": "De Noord", "value": "C77094"},
                   {"label": "De Pijp", "value": "C77095"},
                   {"label": "De Rijp", "value": "C77096"},
                   {"label": "De Stolpen", "value": "C77097"},
                   {"label": "De Waal", "value": "C77098"},
                   {"label": "De Wallen", "value": "C77099"},
                   {"label": "De Weere", "value": "C77100"},
                   {"label": "De Weere", "value": "C77101"},
                   {"label": "De Woude", "value": "C77102"},
                   {"label": "Den Burg", "value": "C77103"},
                   {"label": "Den Helder", "value": "C77104"},
                   {"label": "Den Hoorn", "value": "C77105"},
                   {"label": "Den Ilp", "value": "C77106"},
                   {"label": "Den Oever", "value": "C77107"},
                   {"label": "Diemen", "value": "C77108"},
                   {"label": "Dirkshorn", "value": "C77109"},
                   {"label": "Drechterland", "value": "C77110"},
                   {"label": "Driehuis", "value": "C77111"},
                   {"label": "Driehuizen", "value": "C77112"},
                   {"label": "Driehuizen", "value": "C77113"},
                   {"label": "Driemond", "value": "C77114"},
                   {"label": "Duivendrecht", "value": "C77115"},
                   {"label": "Durgerdam", "value": "C77116"},
                   {"label": "Edam", "value": "C77117"},
                   {"label": "Edam-Volendam", "value": "C77118"},
                   {"label": "Eenigenburg", "value": "C77119"},
                   {"label": "Egmond aan den Hoef", "value": "C77120"},
                   {"label": "Egmond aan Zee", "value": "C77121"},
                   {"label": "Egmond-Binnen", "value": "C77122"},
                   {"label": "Enkhuizen", "value": "C77123"},
                   {"label": "Etersheim", "value": "C77124"},
                   {"label": "Friese Buurt", "value": "C77125"},
                   {"label": "Geuzenveld", "value": "C77126"},
                   {"label": "Gooise Meren", "value": "C77127"},
                   {"label": "Gouwe", "value": "C77128"},
                   {"label": "Graft", "value": "C77129"},
                   {"label": "Grootebroek", "value": "C77130"},
                   {"label": "Grootschermer", "value": "C77131"},
                   {"label": "Grosthuizen", "value": "C77132"},
                   {"label": "Haarlem", "value": "C77133"},
                   {"label": "Haarlemmerliede", "value": "C77134"},
                   {"label": "Haarlemmermeer", "value": "C77135"},
                   {"label": "Hakkelaarsbrug", "value": "C77136"},
                   {"label": "Halfweg", "value": "C77137"},
                   {"label": "Harderwijk", "value": "C77138"},
                   {"label": "Harenkarspel", "value": "C77139"},
                   {"label": "Hargen", "value": "C77140"},
                   {"label": "Haringhuizen", "value": "C77141"},
                   {"label": "Hauwert", "value": "C77142"},
                   {"label": "Heemskerk", "value": "C77143"},
                   {"label": "Heemstede", "value": "C77144"},
                   {"label": "Heerhugowaard", "value": "C77145"},
                   {"label": "Heiloo", "value": "C77146"},
                   {"label": "Hem", "value": "C77147"},
                   {"label": "Hensbroek", "value": "C77148"},
                   {"label": "Hilversum", "value": "C77149"},
                   {"label": "Hilversumse Meent", "value": "C77150"},
                   {"label": "Hinderdam", "value": "C77151"},
                   {"label": "Hippolytushoef", "value": "C77152"},
                   {"label": "Hobrede", "value": "C77153"},
                   {"label": "Hofgeest", "value": "C77154"},
                   {"label": "Hollands Kroon", "value": "C77155"},
                   {"label": "Holysloot", "value": "C77156"},
                   {"label": "Hoofddorp", "value": "C77157"},
                   {"label": "Hoogkarspel", "value": "C77158"},
                   {"label": "Hoogwoud", "value": "C77159"},
                   {"label": "Hoorn", "value": "C77160"},
                   {"label": "Horstermeer", "value": "C77161"},
                   {"label": "Huigsloot", "value": "C77162"},
                   {"label": "Huisduinen", "value": "C77163"},
                   {"label": "Huizen", "value": "C77164"},
                   {"label": "IJburg", "value": "C77165"},
                   {"label": "IJmuiden", "value": "C77166"},
                   {"label": "Ilpendam", "value": "C77167"},
                   {"label": "Jisp", "value": "C77168"},
                   {"label": "Jordaan", "value": "C77169"},
                   {"label": "Julianadorp", "value": "C77170"},
                   {"label": "Kadoelen", "value": "C77171"},
                   {"label": "Kalverdijk", "value": "C77172"},
                   {"label": "Kapel", "value": "C77173"},
                   {"label": "Katwoude", "value": "C77174"},
                   {"label": "Kerkbuurt", "value": "C77175"},
                   {"label": "Kerkelanden", "value": "C77176"},
                   {"label": "Koedijk", "value": "C77177"},
                   {"label": "Koggenland", "value": "C77178"},
                   {"label": "Kolhorn", "value": "C77179"},
                   {"label": "Koog aan de Zaan", "value": "C77180"},
                   {"label": "Kortenhoef", "value": "C77181"},
                   {"label": "Krabbendam", "value": "C77182"},
                   {"label": "Kreileroord", "value": "C77183"},
                   {"label": "Krommenie", "value": "C77184"},
                   {"label": "Kudelstaart", "value": "C77185"},
                   {"label": "Kwadijk", "value": "C77186"},
                   {"label": "Lambertschaag", "value": "C77187"},
                   {"label": "Landsmeer", "value": "C77188"},
                   {"label": "Langedijk", "value": "C77189"},
                   {"label": "Langeheit", "value": "C77190"},
                   {"label": "Langereis", "value": "C77191"},
                   {"label": "Laren", "value": "C77192"},
                   {"label": "Leimuiderbrug", "value": "C77193"},
                   {"label": "Lijnden", "value": "C77194"},
                   {"label": "Limmen", "value": "C77195"},
                   {"label": "Lisserbroek", "value": "C77196"},
                   {"label": "Loosdrecht", "value": "C77197"},
                   {"label": "Lutjebroek", "value": "C77198"},
                   {"label": "Lutjewinkel", "value": "C77199"},
                   {"label": "Marken", "value": "C77200"},
                   {"label": "Markenbinnen", "value": "C77201"},
                   {"label": "Medemblik", "value": "C77202"},
                   {"label": "Middelie", "value": "C77203"},
                   {"label": "Middenbeemster", "value": "C77204"},
                   {"label": "Middenmeer", "value": "C77205"},
                   {"label": "Midwoud", "value": "C77206"},
                   {"label": "Moerbeek", "value": "C77207"},
                   {"label": "Monnickendam", "value": "C77208"},
                   {"label": "Muiden", "value": "C77209"},
                   {"label": "Muiderberg", "value": "C77210"},
                   {"label": "Muyeveld", "value": "C77211"},
                   {"label": "Naarden", "value": "C77212"},
                   {"label": "Neck", "value": "C77213"},
                   {"label": "Nederhorst den Berg", "value": "C77214"},
                   {"label": "Nes aan de Amstel", "value": "C77215"},
                   {"label": "Nibbixwoud", "value": "C77216"},
                   {"label": "Nieuw-Loosdrecht", "value": "C77217"},
                   {"label": "Nieuw-Vennep", "value": "C77218"},
                   {"label": "Nieuwe Meer", "value": "C77219"},
                   {"label": "Nieuwe Niedorp", "value": "C77220"},
                   {"label": "Nieuwebrug", "value": "C77221"},
                   {"label": "Nieuwendam", "value": "C77222"},
                   {"label": "Nieuwesluis", "value": "C77223"},
                   {"label": "Noord-Scharwoude", "value": "C77224"},
                   {"label": "Noordbeemster", "value": "C77225"},
                   {"label": "Noordburen", "value": "C77226"},
                   {"label": "Noorddijk", "value": "C77227"},
                   {"label": "Noordeinde", "value": "C77228"},
                   {"label": "Obdam", "value": "C77229"},
                   {"label": "Omval", "value": "C77230"},
                   {"label": "Onderdijk", "value": "C77231"},
                   {"label": "Oost-Graftdijk", "value": "C77232"},
                   {"label": "Oosteinde", "value": "C77233"},
                   {"label": "Oosterdijk", "value": "C77234"},
                   {"label": "Oosterend", "value": "C77235"},
                   {"label": "Oosterland", "value": "C77236"},
                   {"label": "Oosterleek", "value": "C77237"},
                   {"label": "Oosterzij", "value": "C77238"},
                   {"label": "Oosthuizen", "value": "C77239"},
                   {"label": "Oostknollendam", "value": "C77240"},
                   {"label": "Oostwoud", "value": "C77241"},
                   {"label": "Oostzaan", "value": "C77242"},
                   {"label": "Opmeer", "value": "C77243"},
                   {"label": "Opperdoes", "value": "C77244"},
                   {"label": "Osdorp", "value": "C77245"},
                   {"label": "Oterleek", "value": "C77246"},
                   {"label": "Oud-Loosdrecht", "value": "C77247"},
                   {"label": "Oude Meer", "value": "C77248"},
                   {"label": "Oude Niedorp", "value": "C77249"},
                   {"label": "Oudendijk", "value": "C77250"},
                   {"label": "Ouder-Amstel", "value": "C77251"},
                   {"label": "Ouderkerk aan de Amstel", "value": "C77252"},
                   {"label": "Oudeschild", "value": "C77253"},
                   {"label": "Oudesluis", "value": "C77254"},
                   {"label": "Oudkarspel", "value": "C77255"},
                   {"label": "Oudorp", "value": "C77256"},
                   {"label": "Overleek", "value": "C77257"},
                   {"label": "Overmeer", "value": "C77258"},
                   {"label": "Overveen", "value": "C77259"},
                   {"label": "Penningsveer", "value": "C77260"},
                   {"label": "Petten", "value": "C77261"},
                   {"label": "Purmerend", "value": "C77262"},
                   {"label": "Purmerland", "value": "C77263"},
                   {"label": "Ransdorp", "value": "C77264"},
                   {"label": "Rijsenhout", "value": "C77265"},
                   {"label": "Risdam", "value": "C77266"},
                   {"label": "Ruigoord", "value": "C77267"},
                   {"label": "Rustenburg", "value": "C77268"},
                   {"label": "Santpoort", "value": "C77269"},
                   {"label": "Schagen", "value": "C77270"},
                   {"label": "Schagerbrug", "value": "C77271"},
                   {"label": "Schalkwijk", "value": "C77272"},
                   {"label": "Schardam", "value": "C77273"},
                   {"label": "Scharwoude", "value": "C77274"},
                   {"label": "Schellingwoude", "value": "C77275"},
                   {"label": "Schellinkhout", "value": "C77276"},
                   {"label": "Schermerhorn", "value": "C77277"},
                   {"label": "Schiphol-Rijk", "value": "C77278"},
                   {"label": "Schoorl", "value": "C77279"},
                   {"label": "Schoorldam", "value": "C77280"},
                   {"label": "Sijbekarspel", "value": "C77281"},
                   {"label": "Sint Maartenszee", "value": "C77282"},
                   {"label": "Sint Pancras", "value": "C77283"},
                   {"label": "Slootdorp", "value": "C77284"},
                   {"label": "Sloten", "value": "C77285"},
                   {"label": "Sloterdijk", "value": "C77286"},
                   {"label": "Slotermeer", "value": "C77287"},
                   {"label": "Slotervaart (former borough)", "value": "C77288"},
                   {"label": "Spaarndam-Oost", "value": "C77289"},
                   {"label": "Spaarndam-West", "value": "C77290"},
                   {"label": "Spaarnwoude", "value": "C77291"},
                   {"label": "Spanbroek", "value": "C77292"},
                   {"label": "Spierdijk", "value": "C77293"},
                   {"label": "Spijkerboor", "value": "C77294"},
                   {"label": "Starnmeer", "value": "C77295"},
                   {"label": "Stede Broec", "value": "C77296"},
                   {"label": "Stierop", "value": "C77297"},
                   {"label": "Stompetoren", "value": "C77298"},
                   {"label": "Stroe", "value": "C77299"},
                   {"label": "Stroet", "value": "C77300"},
                   {"label": "Terdiek", "value": "C77301"},
                   {"label": "Texel", "value": "C77302"},
                   {"label": "Tuindorp Oostzaan", "value": "C77303"},
                   {"label": "Tuitjenhorn", "value": "C77304"},
                   {"label": "Twisk", "value": "C77305"},
                   {"label": "Uitdam", "value": "C77306"},
                   {"label": "Uitgeest", "value": "C77307"},
                   {"label": "Uithoorn", "value": "C77308"},
                   {"label": "Ursem", "value": "C77309"},
                   {"label": "Valkkoog", "value": "C77310"},
                   {"label": "Van Ewijcksluis", "value": "C77311"},
                   {"label": "Vatrop", "value": "C77312"},
                   {"label": "Veenhuizen", "value": "C77313"},
                   {"label": "Velsen", "value": "C77314"},
                   {"label": "Velsen-Zuid", "value": "C77315"},
                   {"label": "Velserbroek", "value": "C77316"},
                   {"label": "Venhuizen", "value": "C77317"},
                   {"label": "Vijfhuizen", "value": "C77318"},
                   {"label": "Vinkebrug", "value": "C77319"},
                   {"label": "Vogelenzang", "value": "C77320"},
                   {"label": "Volendam", "value": "C77321"},
                   {"label": "Waarland", "value": "C77322"},
                   {"label": "Wadway", "value": "C77323"},
                   {"label": "Warder", "value": "C77324"},
                   {"label": "Warmenhuizen", "value": "C77325"},
                   {"label": "Waterakkers", "value": "C77326"},
                   {"label": "Watergang", "value": "C77327"},
                   {"label": "Watergraafsmeer", "value": "C77328"},
                   {"label": "Wateringskant", "value": "C77329"},
                   {"label": "Waterland", "value": "C77330"},
                   {"label": "Waver", "value": "C77331"},
                   {"label": "Weesp", "value": "C77332"},
                   {"label": "Weidevenne", "value": "C77333"},
                   {"label": "Wervershoof", "value": "C77334"},
                   {"label": "West-Graftdijk", "value": "C77335"},
                   {"label": "West-Knollendam", "value": "C77336"},
                   {"label": "Westbeemster", "value": "C77337"},
                   {"label": "Westerland", "value": "C77338"},
                   {"label": "Westwoud", "value": "C77339"},
                   {"label": "Westzaan", "value": "C77340"},
                   {"label": "Weteringbrug", "value": "C77341"},
                   {"label": "Wieringerwaard", "value": "C77342"},
                   {"label": "Wieringerwerf", "value": "C77343"},
                   {"label": "Wijdemeren", "value": "C77344"},
                   {"label": "Wijdenes", "value": "C77345"},
                   {"label": "Wijdewormer", "value": "C77346"},
                   {"label": "Wijk aan Zee", "value": "C77347"},
                   {"label": "Wimmenum", "value": "C77348"},
                   {"label": "Winkel", "value": "C77349"},
                   {"label": "Wognum", "value": "C77350"},
                   {"label": "Wormerland", "value": "C77351"},
                   {"label": "Wormerveer", "value": "C77352"},
                   {"label": "Zaandam", "value": "C77353"},
                   {"label": "Zaandijk", "value": "C77354"},
                   {"label": "Zaanstad", "value": "C77355"},
                   {"label": "Zandvoort", "value": "C77356"},
                   {"label": "Zuid-Scharwoude", "value": "C77357"},
                   {"label": "Zuidermeer", "value": "C77358"},
                   {"label": "Zwaag", "value": "C77359"},
                   {"label": "Zwaagdijk-Oost", "value": "C77360"},
                   {"label": "Zwaagdijk-West", "value": "C77361"},
                   {"label": "Zwaanshoek", "value": "C77362"}],
 "North Hwanghae Province": [{"label": "Anak", "value": "C78561"},
                             {"label": "Hukkyo-ri", "value": "C78562"},
                             {"label": "Hwangju-up", "value": "C78563"},
                             {"label": "Koksan", "value": "C78564"},
                             {"label": "Sariwon", "value": "C78565"},
                             {"label": "Sinmak", "value": "C78566"},
                             {"label": "Songnim", "value": "C78567"}],
 "North Jeolla Province": [{"label": "Buan-gun", "value": "C108402"},
                           {"label": "Changsu", "value": "C108403"},
                           {"label": "Gimje-si", "value": "C108404"},
                           {"label": "Gochang-gun", "value": "C108405"},
                           {"label": "Gunsan", "value": "C108406"},
                           {"label": "Gunsan-si", "value": "C108407"},
                           {"label": "Iksan", "value": "C108408"},
                           {"label": "Iksan-si", "value": "C108409"},
                           {"label": "Imsil", "value": "C108410"},
                           {"label": "Imsil-gun", "value": "C108411"},
                           {"label": "Jangsu-gun", "value": "C108412"},
                           {"label": "Jeongeup", "value": "C108413"},
                           {"label": "Jeongeup-si", "value": "C108414"},
                           {"label": "Jeonju", "value": "C108415"},
                           {"label": "Jeonju-si", "value": "C108416"},
                           {"label": "Jinan-gun", "value": "C108417"},
                           {"label": "Kimje", "value": "C108418"},
                           {"label": "Kochang", "value": "C108419"},
                           {"label": "Muju-gun", "value": "C108420"},
                           {"label": "Namwon", "value": "C108421"},
                           {"label": "Nangen", "value": "C108422"},
                           {"label": "Puan", "value": "C108423"},
                           {"label": "Sunchang-gun", "value": "C108424"},
                           {"label": "Wanju", "value": "C108425"}],
 "North Karelia": [{"label": "Eno", "value": "C24964"},
                   {"label": "Ilomantsi", "value": "C24965"},
                   {"label": "Joensuu", "value": "C24966"},
                   {"label": "Juuka", "value": "C24967"},
                   {"label": "Kesalahti", "value": "C24968"},
                   {"label": "Kiihtelysvaara", "value": "C24969"},
                   {"label": "Kitee", "value": "C24970"},
                   {"label": "Kontiolahti", "value": "C24971"},
                   {"label": "Lieksa", "value": "C24972"},
                   {"label": "Liperi", "value": "C24973"},
                   {"label": "Nurmes", "value": "C24974"},
                   {"label": "Outokumpu", "value": "C24975"},
                   {"label": "Polvijarvi", "value": "C24976"},
                   {"label": "Pyhaselka", "value": "C24977"},
                   {"label": "Raakkyla", "value": "C24978"},
                   {"label": "Tohmajarvi", "value": "C24979"},
                   {"label": "Tuupovaara", "value": "C24980"},
                   {"label": "Valtimo", "value": "C24981"}],
 "North Kazakhstan Region": [{"label": "Birlestik", "value": "C64472"},
                             {"label": "Bishkul", "value": "C64473"},
                             {"label": "Bulayevo", "value": "C64474"},
                             {"label": "Burabay", "value": "C64475"},
                             {"label": "Kzyltu", "value": "C64476"},
                             {"label": "Novoishimskiy", "value": "C64477"},
                             {"label": "Petropavl", "value": "C64478"},
                             {"label": "Sergeyevka", "value": "C64479"},
                             {"label": "Smirnovo", "value": "C64480"},
                             {"label": "Taiynsha", "value": "C64481"},
                             {"label": "Talshik", "value": "C64482"},
                             {"label": "Timiryazevo", "value": "C64483"},
                             {"label": "Volodarskoye", "value": "C64484"},
                             {"label": "Yavlenka", "value": "C64485"}],
 "North Khorasan": [{"label": "Bojnurd", "value": "C50944"},
                    {"label": "Esfarayen", "value": "C50945"},
                    {"label": "Raz and Jargalan", "value": "C50946"},
                    {"label": "Shahrestan-e Bojnurd", "value": "C50947"},
                    {"label": "Shahrestan-e Esfarayen", "value": "C50948"},
                    {"label": "Shahrestan-e Faruj", "value": "C50949"},
                    {"label": "Shahrestan-e Jajarm", "value": "C50950"},
                    {"label": "Shahrestan-e Maneh va Samalqan",
                     "value": "C50951"},
                    {"label": "Shahrestan-e Shirvan", "value": "C50952"},
                    {"label": "Shirvan", "value": "C50953"}],
 "North Kordofan": [{"label": "Ar Rahad", "value": "C115412"},
                    {"label": "Barah", "value": "C115413"},
                    {"label": "El Obeid", "value": "C115414"},
                    {"label": "Umm Ruwaba", "value": "C115415"}],
 "North Pyongan Province": [{"label": "Chongju", "value": "C78568"},
                            {"label": "Chongju-gun", "value": "C78569"},
                            {"label": "Kujang-up", "value": "C78570"},
                            {"label": "Kusong", "value": "C78571"},
                            {"label": "Kwaksan", "value": "C78572"},
                            {"label": "Panghyon-dong", "value": "C78573"},
                            {"label": "Pukchil-lodongjagu", "value": "C78574"},
                            {"label": "Sakchu-up", "value": "C78575"},
                            {"label": "Sinuiju", "value": "C78576"},
                            {"label": "Uiju", "value": "C78577"},
                            {"label": "Yomju-up", "value": "C78578"},
                            {"label": "Yongbyon", "value": "C78579"}],
 "North Rhine-Westphalia": [{"label": "Aachen", "value": "C38771"},
                            {"label": "Ahaus", "value": "C38772"},
                            {"label": "Ahlen", "value": "C38773"},
                            {"label": "Aldenhoven", "value": "C38774"},
                            {"label": "Alfter", "value": "C38775"},
                            {"label": "Alpen", "value": "C38776"},
                            {"label": "Alsdorf", "value": "C38777"},
                            {"label": "Altena", "value": "C38778"},
                            {"label": "Altenbeken", "value": "C38779"},
                            {"label": "Altenberge", "value": "C38780"},
                            {"label": "Altenburen", "value": "C38781"},
                            {"label": "Altstadt Nord", "value": "C38782"},
                            {"label": "Altstadt Sud", "value": "C38783"},
                            {"label": "Anrochte", "value": "C38784"},
                            {"label": "Arnsberg", "value": "C38785"},
                            {"label": "Ascheberg", "value": "C38786"},
                            {"label": "Attendorn", "value": "C38787"},
                            {"label": "Augustdorf", "value": "C38788"},
                            {"label": "Bad Berleburg", "value": "C38789"},
                            {"label": "Bad Driburg", "value": "C38790"},
                            {"label": "Bad Fredeburg", "value": "C38791"},
                            {"label": "Bad Holzhausen", "value": "C38792"},
                            {"label": "Bad Honnef", "value": "C38793"},
                            {"label": "Bad Laasphe", "value": "C38794"},
                            {"label": "Bad Lippspringe", "value": "C38795"},
                            {"label": "Bad Meinberg", "value": "C38796"},
                            {"label": "Bad Munstereifel", "value": "C38797"},
                            {"label": "Bad Oeynhausen", "value": "C38798"},
                            {"label": "Bad Salzuflen", "value": "C38799"},
                            {"label": "Bad Sassendorf", "value": "C38800"},
                            {"label": "Baesweiler", "value": "C38801"},
                            {"label": "Balve", "value": "C38802"},
                            {"label": "Bamenohl", "value": "C38803"},
                            {"label": "Barntrup", "value": "C38804"},
                            {"label": "Bayenthal", "value": "C38805"},
                            {"label": "Beckum", "value": "C38806"},
                            {"label": "Bedburg", "value": "C38807"},
                            {"label": "Beelen", "value": "C38808"},
                            {"label": "Bergheim", "value": "C38809"},
                            {"label": "Bergisch Gladbach", "value": "C38810"},
                            {"label": "Bergkamen", "value": "C38811"},
                            {"label": "Bergneustadt", "value": "C38812"},
                            {"label": "Bestwig", "value": "C38813"},
                            {"label": "Beverungen", "value": "C38814"},
                            {"label": "Bielefeld", "value": "C38815"},
                            {"label": "Bilderstoeckchen", "value": "C38816"},
                            {"label": "Billerbeck", "value": "C38817"},
                            {"label": "Birgte", "value": "C38818"},
                            {"label": "Blankenheim", "value": "C38819"},
                            {"label": "Blomberg", "value": "C38820"},
                            {"label": "Bocholt", "value": "C38821"},
                            {"label": "Bochum", "value": "C38822"},
                            {"label": "Bochum-Hordel", "value": "C38823"},
                            {"label": "Bonen", "value": "C38824"},
                            {"label": "Bonn", "value": "C38825"},
                            {"label": "Borgentreich", "value": "C38826"},
                            {"label": "Borgholzhausen", "value": "C38827"},
                            {"label": "Borken", "value": "C38828"},
                            {"label": "Bornheim", "value": "C38829"},
                            {"label": "Bottrop", "value": "C38830"},
                            {"label": "Brakel", "value": "C38831"},
                            {"label": "Breckerfeld", "value": "C38832"},
                            {"label": "Brilon", "value": "C38833"},
                            {"label": "Bruggen", "value": "C38834"},
                            {"label": "Bruhl", "value": "C38835"},
                            {"label": "Buchforst", "value": "C38836"},
                            {"label": "Buchheim", "value": "C38837"},
                            {"label": "Bunde", "value": "C38838"},
                            {"label": "Burbach", "value": "C38839"},
                            {"label": "Buren", "value": "C38840"},
                            {"label": "Burscheid", "value": "C38841"},
                            {"label": "Castrop-Rauxel", "value": "C38842"},
                            {"label": "Coesfeld", "value": "C38843"},
                            {"label": "Dahlem", "value": "C38844"},
                            {"label": "Datteln", "value": "C38845"},
                            {"label": "Delbruck", "value": "C38846"},
                            {"label": "Detmold", "value": "C38847"},
                            {"label": "Deutz", "value": "C38848"},
                            {"label": "Dinslaken", "value": "C38849"},
                            {"label": "Dorentrup", "value": "C38850"},
                            {"label": "Dormagen", "value": "C38851"},
                            {"label": "Dorsten", "value": "C38852"},
                            {"label": "Dortmund", "value": "C38853"},
                            {"label": "Dreierwalde", "value": "C38854"},
                            {"label": "Drensteinfurt", "value": "C38855"},
                            {"label": "Drolshagen", "value": "C38856"},
                            {"label": "Duisburg", "value": "C38857"},
                            {"label": "Dulmen", "value": "C38858"},
                            {"label": "Duren", "value": "C38859"},
                            {"label": "Dusseldorf", "value": "C38860"},
                            {"label": "Dusseldorf District", "value": "C38861"},
                            {"label": "Dusseldorf-Pempelfort",
                             "value": "C38862"},
                            {"label": "Eil", "value": "C38863"},
                            {"label": "Eitorf", "value": "C38864"},
                            {"label": "Elsdorf", "value": "C38865"},
                            {"label": "Emmerich", "value": "C38866"},
                            {"label": "Emsdetten", "value": "C38867"},
                            {"label": "Engelskirchen", "value": "C38868"},
                            {"label": "Enger", "value": "C38869"},
                            {"label": "Ennepetal", "value": "C38870"},
                            {"label": "Ennigerloh", "value": "C38871"},
                            {"label": "Erftstadt", "value": "C38872"},
                            {"label": "Erkelenz", "value": "C38873"},
                            {"label": "Erkrath", "value": "C38874"},
                            {"label": "Erndtebruck", "value": "C38875"},
                            {"label": "Erwitte", "value": "C38876"},
                            {"label": "Eschweiler", "value": "C38877"},
                            {"label": "Eslohe", "value": "C38878"},
                            {"label": "Espelkamp", "value": "C38879"},
                            {"label": "Essen", "value": "C38880"},
                            {"label": "Euskirchen", "value": "C38881"},
                            {"label": "Everswinkel", "value": "C38882"},
                            {"label": "Finnentrop", "value": "C38883"},
                            {"label": "Frechen", "value": "C38884"},
                            {"label": "Freudenberg", "value": "C38885"},
                            {"label": "Frondenberg", "value": "C38886"},
                            {"label": "Gangelt", "value": "C38887"},
                            {"label": "Geilenkirchen", "value": "C38888"},
                            {"label": "Geldern", "value": "C38889"},
                            {"label": "Gelsenkirchen", "value": "C38890"},
                            {"label": "Gescher", "value": "C38891"},
                            {"label": "Geseke", "value": "C38892"},
                            {"label": "Gevelsberg", "value": "C38893"},
                            {"label": "Gladbeck", "value": "C38894"},
                            {"label": "Goch", "value": "C38895"},
                            {"label": "Grefrath", "value": "C38896"},
                            {"label": "Gremberghoven", "value": "C38897"},
                            {"label": "Greven", "value": "C38898"},
                            {"label": "Grevenbroich", "value": "C38899"},
                            {"label": "Gronau", "value": "C38900"},
                            {"label": "Gummersbach", "value": "C38901"},
                            {"label": "Gutersloh", "value": "C38902"},
                            {"label": "Haan", "value": "C38903"},
                            {"label": "Hagen", "value": "C38904"},
                            {"label": "Halle", "value": "C38905"},
                            {"label": "Hallenberg", "value": "C38906"},
                            {"label": "Haltern am See", "value": "C38907"},
                            {"label": "Halver", "value": "C38908"},
                            {"label": "Hamm", "value": "C38909"},
                            {"label": "Hamminkeln", "value": "C38910"},
                            {"label": "Harsewinkel", "value": "C38911"},
                            {"label": "Hattingen", "value": "C38912"},
                            {"label": "Havixbeck", "value": "C38913"},
                            {"label": "Heek", "value": "C38914"},
                            {"label": "Heiden", "value": "C38915"},
                            {"label": "Heiligenhaus", "value": "C38916"},
                            {"label": "Heimbach", "value": "C38917"},
                            {"label": "Heinsberg", "value": "C38918"},
                            {"label": "Hellenthal", "value": "C38919"},
                            {"label": "Hemer", "value": "C38920"},
                            {"label": "Hennef", "value": "C38921"},
                            {"label": "Herdecke", "value": "C38922"},
                            {"label": "Herford", "value": "C38923"},
                            {"label": "Herne", "value": "C38924"},
                            {"label": "Herscheid", "value": "C38925"},
                            {"label": "Herten", "value": "C38926"},
                            {"label": "Herzogenrath", "value": "C38927"},
                            {"label": "Hiddenhausen", "value": "C38928"},
                            {"label": "Hilchenbach", "value": "C38929"},
                            {"label": "Hilden", "value": "C38930"},
                            {"label": "Hille", "value": "C38931"},
                            {"label": "Hochfeld", "value": "C38932"},
                            {"label": "Hohenberg", "value": "C38933"},
                            {"label": "Holzwickede", "value": "C38934"},
                            {"label": "Hordel", "value": "C38935"},
                            {"label": "Horn", "value": "C38936"},
                            {"label": "Horstel", "value": "C38937"},
                            {"label": "Horstmar", "value": "C38938"},
                            {"label": "Hovelhof", "value": "C38939"},
                            {"label": "Hoxter", "value": "C38940"},
                            {"label": "Huckelhoven", "value": "C38941"},
                            {"label": "Huckeswagen", "value": "C38942"},
                            {"label": "Hullhorst", "value": "C38943"},
                            {"label": "Humboldtkolonie", "value": "C38944"},
                            {"label": "Hunxe", "value": "C38945"},
                            {"label": "Hurtgenwald", "value": "C38946"},
                            {"label": "Hurth", "value": "C38947"},
                            {"label": "Ibbenburen", "value": "C38948"},
                            {"label": "Inden", "value": "C38949"},
                            {"label": "Iserlohn", "value": "C38950"},
                            {"label": "Isselburg", "value": "C38951"},
                            {"label": "Issum", "value": "C38952"},
                            {"label": "Juchen", "value": "C38953"},
                            {"label": "Julich", "value": "C38954"},
                            {"label": "Kaarst", "value": "C38955"},
                            {"label": "Kalk", "value": "C38956"},
                            {"label": "Kalkar", "value": "C38957"},
                            {"label": "Kall", "value": "C38958"},
                            {"label": "Kamen", "value": "C38959"},
                            {"label": "Kamp-Lintfort", "value": "C38960"},
                            {"label": "Kempen", "value": "C38961"},
                            {"label": "Kerpen", "value": "C38962"},
                            {"label": "Kevelaer", "value": "C38963"},
                            {"label": "Kierspe", "value": "C38964"},
                            {"label": "Kirchhundem", "value": "C38965"},
                            {"label": "Kirchlengern", "value": "C38966"},
                            {"label": "Klein Reken", "value": "C38967"},
                            {"label": "Kleve", "value": "C38968"},
                            {"label": "Koln", "value": "C38969"},
                            {"label": "Konigswinter", "value": "C38970"},
                            {"label": "Korschenbroich", "value": "C38971"},
                            {"label": "Kranenburg", "value": "C38972"},
                            {"label": "Krefeld", "value": "C38973"},
                            {"label": "Kreuzau", "value": "C38974"},
                            {"label": "Kreuztal", "value": "C38975"},
                            {"label": "Kurten", "value": "C38976"},
                            {"label": "Ladbergen", "value": "C38977"},
                            {"label": "Laer", "value": "C38978"},
                            {"label": "Lage", "value": "C38979"},
                            {"label": "Langenberg", "value": "C38980"},
                            {"label": "Langenfeld", "value": "C38981"},
                            {"label": "Langerwehe", "value": "C38982"},
                            {"label": "Lanstrop", "value": "C38983"},
                            {"label": "Legden", "value": "C38984"},
                            {"label": "Leichlingen", "value": "C38985"},
                            {"label": "Lemgo", "value": "C38986"},
                            {"label": "Lengerich", "value": "C38987"},
                            {"label": "Lennestadt", "value": "C38988"},
                            {"label": "Leopoldshohe", "value": "C38989"},
                            {"label": "Leverkusen", "value": "C38990"},
                            {"label": "Lichtenau", "value": "C38991"},
                            {"label": "Lienen", "value": "C38992"},
                            {"label": "Lindlar", "value": "C38993"},
                            {"label": "Linnich", "value": "C38994"},
                            {"label": "Lippstadt", "value": "C38995"},
                            {"label": "Lohmar", "value": "C38996"},
                            {"label": "Lohne", "value": "C38997"},
                            {"label": "Lotte", "value": "C38998"},
                            {"label": "Lubbecke", "value": "C38999"},
                            {"label": "Ludenscheid", "value": "C39000"},
                            {"label": "Ludinghausen", "value": "C39001"},
                            {"label": "Lugde", "value": "C39002"},
                            {"label": "Lunen", "value": "C39003"},
                            {"label": "Marienheide", "value": "C39004"},
                            {"label": "Marl", "value": "C39005"},
                            {"label": "Marsberg", "value": "C39006"},
                            {"label": "Mechernich", "value": "C39007"},
                            {"label": "Meckenheim", "value": "C39008"},
                            {"label": "Medebach", "value": "C39009"},
                            {"label": "Meerbusch", "value": "C39010"},
                            {"label": "Mehrhoog", "value": "C39011"},
                            {"label": "Meiderich", "value": "C39012"},
                            {"label": "Meinerzhagen", "value": "C39013"},
                            {"label": "Menden", "value": "C39014"},
                            {"label": "Mengenich", "value": "C39015"},
                            {"label": "Meschede", "value": "C39016"},
                            {"label": "Metelen", "value": "C39017"},
                            {"label": "Mettingen", "value": "C39018"},
                            {"label": "Mettmann", "value": "C39019"},
                            {"label": "Minden", "value": "C39020"},
                            {"label": "Moers", "value": "C39021"},
                            {"label": "Mohnesee", "value": "C39022"},
                            {"label": "Monchengladbach", "value": "C39023"},
                            {"label": "Monheim am Rhein", "value": "C39024"},
                            {"label": "Monschau", "value": "C39025"},
                            {"label": "Morsbach", "value": "C39026"},
                            {"label": "Much", "value": "C39027"},
                            {"label": "Mulheim", "value": "C39028"},
                            {"label": "Munster", "value": "C39029"},
                            {"label": "Nachrodt-Wiblingwerde",
                             "value": "C39030"},
                            {"label": "Netphen", "value": "C39031"},
                            {"label": "Nettersheim", "value": "C39032"},
                            {"label": "Nettetal", "value": "C39033"},
                            {"label": "Neu-Pattern", "value": "C39034"},
                            {"label": "Neubruck", "value": "C39035"},
                            {"label": "Neuehrenfeld", "value": "C39036"},
                            {"label": "Neuenkirchen", "value": "C39037"},
                            {"label": "Neuenrade", "value": "C39038"},
                            {"label": "Neunkirchen", "value": "C39039"},
                            {"label": "Neuss", "value": "C39040"},
                            {"label": "NeustadtNord", "value": "C39041"},
                            {"label": "NeustadtSud", "value": "C39042"},
                            {"label": "Nideggen", "value": "C39043"},
                            {"label": "Niederkassel", "value": "C39044"},
                            {"label": "Niederkruchten", "value": "C39045"},
                            {"label": "Niedermerz", "value": "C39046"},
                            {"label": "Niederzier", "value": "C39047"},
                            {"label": "Nieheim", "value": "C39048"},
                            {"label": "Nippes", "value": "C39049"},
                            {"label": "Nordkirchen", "value": "C39050"},
                            {"label": "Nordwalde", "value": "C39051"},
                            {"label": "Norvenich", "value": "C39052"},
                            {"label": "Nottuln", "value": "C39053"},
                            {"label": "Numbrecht", "value": "C39054"},
                            {"label": "Oberhausen", "value": "C39055"},
                            {"label": "Obernbeck", "value": "C39056"},
                            {"label": "Ochtrup", "value": "C39057"},
                            {"label": "Odenthal", "value": "C39058"},
                            {"label": "Oelde", "value": "C39059"},
                            {"label": "Oer-Erkenschwick", "value": "C39060"},
                            {"label": "Oerlinghausen", "value": "C39061"},
                            {"label": "Olfen", "value": "C39062"},
                            {"label": "Olpe", "value": "C39063"},
                            {"label": "Olsberg", "value": "C39064"},
                            {"label": "Opladen", "value": "C39065"},
                            {"label": "Ossendorf", "value": "C39066"},
                            {"label": "Ostbevern", "value": "C39067"},
                            {"label": "Ostheim", "value": "C39068"},
                            {"label": "Overath", "value": "C39069"},
                            {"label": "Paderborn", "value": "C39070"},
                            {"label": "Petershagen", "value": "C39071"},
                            {"label": "Plettenberg", "value": "C39072"},
                            {"label": "Poll", "value": "C39073"},
                            {"label": "Porta Westfalica", "value": "C39074"},
                            {"label": "Porz am Rhein", "value": "C39075"},
                            {"label": "Preussisch Oldendorf",
                             "value": "C39076"},
                            {"label": "Pulheim", "value": "C39077"},
                            {"label": "Radevormwald", "value": "C39078"},
                            {"label": "Raesfeld", "value": "C39079"},
                            {"label": "Rahden", "value": "C39080"},
                            {"label": "Rath", "value": "C39081"},
                            {"label": "Ratingen", "value": "C39082"},
                            {"label": "Recke", "value": "C39083"},
                            {"label": "Recklinghausen", "value": "C39084"},
                            {"label": "Rees", "value": "C39085"},
                            {"label": "Regierungsbezirk Arnsberg",
                             "value": "C39086"},
                            {"label": "Regierungsbezirk Detmold",
                             "value": "C39087"},
                            {"label": "Regierungsbezirk Koln",
                             "value": "C39088"},
                            {"label": "Regierungsbezirk Munster",
                             "value": "C39089"},
                            {"label": "Remscheid", "value": "C39090"},
                            {"label": "Rheda-Wiedenbruck", "value": "C39091"},
                            {"label": "Rhede", "value": "C39092"},
                            {"label": "Rheinbach", "value": "C39093"},
                            {"label": "Rheinberg", "value": "C39094"},
                            {"label": "Rheine", "value": "C39095"},
                            {"label": "Rheurdt", "value": "C39096"},
                            {"label": "Riehl", "value": "C39097"},
                            {"label": "Rietberg", "value": "C39098"},
                            {"label": "Rodinghausen", "value": "C39099"},
                            {"label": "Roetgen", "value": "C39100"},
                            {"label": "Rommerskirchen", "value": "C39101"},
                            {"label": "Rosrath", "value": "C39102"},
                            {"label": "Ruppichteroth", "value": "C39103"},
                            {"label": "Ruthen", "value": "C39104"},
                            {"label": "Saerbeck", "value": "C39105"},
                            {"label": "Salzkotten", "value": "C39106"},
                            {"label": "Sankt Augustin", "value": "C39107"},
                            {"label": "Sassenberg", "value": "C39108"},
                            {"label": "Schalksmuhle", "value": "C39109"},
                            {"label": "Schermbeck", "value": "C39110"},
                            {"label": "Schieder-Schwalenberg",
                             "value": "C39111"},
                            {"label": "Schlangen", "value": "C39112"},
                            {"label": "Schleiden", "value": "C39113"},
                            {"label": "Schmallenberg", "value": "C39114"},
                            {"label": "Schoppingen", "value": "C39115"},
                            {"label": "Schwalmtal", "value": "C39116"},
                            {"label": "Schwelm", "value": "C39117"},
                            {"label": "Schwerte", "value": "C39118"},
                            {"label": "Selm", "value": "C39119"},
                            {"label": "Senden", "value": "C39120"},
                            {"label": "Sendenhorst", "value": "C39121"},
                            {"label": "Siegburg", "value": "C39122"},
                            {"label": "Siegen", "value": "C39123"},
                            {"label": "Siersdorf", "value": "C39124"},
                            {"label": "Simmerath", "value": "C39125"},
                            {"label": "Sinnersdorf", "value": "C39126"},
                            {"label": "Soest", "value": "C39127"},
                            {"label": "Solingen", "value": "C39128"},
                            {"label": "Sonsbeck", "value": "C39129"},
                            {"label": "Spenge", "value": "C39130"},
                            {"label": "Sprockhovel", "value": "C39131"},
                            {"label": "Stadtlohn", "value": "C39132"},
                            {"label": "Steinfurt", "value": "C39133"},
                            {"label": "Steinhagen", "value": "C39134"},
                            {"label": "Steinheim", "value": "C39135"},
                            {"label": "Stolberg", "value": "C39136"},
                            {"label": "Stossdorf", "value": "C39137"},
                            {"label": "Straelen", "value": "C39138"},
                            {"label": "Sudlohn", "value": "C39139"},
                            {"label": "Sundern", "value": "C39140"},
                            {"label": "Tecklenburg", "value": "C39141"},
                            {"label": "Telgte", "value": "C39142"},
                            {"label": "Titz", "value": "C39143"},
                            {"label": "Tonisvorst", "value": "C39144"},
                            {"label": "Troisdorf", "value": "C39145"},
                            {"label": "Turnich", "value": "C39146"},
                            {"label": "ubach-Palenberg", "value": "C39147"},
                            {"label": "Uedem", "value": "C39148"},
                            {"label": "Unna", "value": "C39149"},
                            {"label": "Velbert", "value": "C39150"},
                            {"label": "Velen", "value": "C39151"},
                            {"label": "Verl", "value": "C39152"},
                            {"label": "Versmold", "value": "C39153"},
                            {"label": "Vettweiss", "value": "C39154"},
                            {"label": "Viersen", "value": "C39155"},
                            {"label": "Vlotho", "value": "C39156"},
                            {"label": "Voerde", "value": "C39157"},
                            {"label": "Vreden", "value": "C39158"},
                            {"label": "Wachtberg", "value": "C39159"},
                            {"label": "Wachtendonk", "value": "C39160"},
                            {"label": "Wadersloh", "value": "C39161"},
                            {"label": "Wahn-Heide", "value": "C39162"},
                            {"label": "Waldbrol", "value": "C39163"},
                            {"label": "Waldfeucht", "value": "C39164"},
                            {"label": "Waltrop", "value": "C39165"},
                            {"label": "Warburg", "value": "C39166"},
                            {"label": "Warendorf", "value": "C39167"},
                            {"label": "Warstein", "value": "C39168"},
                            {"label": "Wassenberg", "value": "C39169"},
                            {"label": "Weeze", "value": "C39170"},
                            {"label": "Wegberg", "value": "C39171"},
                            {"label": "Weilerswist", "value": "C39172"},
                            {"label": "Welver", "value": "C39173"},
                            {"label": "Wenden", "value": "C39174"},
                            {"label": "Werdohl", "value": "C39175"},
                            {"label": "Werl", "value": "C39176"},
                            {"label": "Wermelskirchen", "value": "C39177"},
                            {"label": "Werne", "value": "C39178"},
                            {"label": "Werther", "value": "C39179"},
                            {"label": "Wesel", "value": "C39180"},
                            {"label": "Wesseling", "value": "C39181"},
                            {"label": "Westerkappeln", "value": "C39182"},
                            {"label": "Wetter (Ruhr)", "value": "C39183"},
                            {"label": "Wettringen", "value": "C39184"},
                            {"label": "Wickede", "value": "C39185"},
                            {"label": "Wiehl", "value": "C39186"},
                            {"label": "Willebadessen", "value": "C39187"},
                            {"label": "Willich", "value": "C39188"},
                            {"label": "Wilnsdorf", "value": "C39189"},
                            {"label": "Winterberg", "value": "C39190"},
                            {"label": "Wipperfurth", "value": "C39191"},
                            {"label": "Witten", "value": "C39192"},
                            {"label": "Wulfrath", "value": "C39193"},
                            {"label": "Wunnenberg", "value": "C39194"},
                            {"label": "Wuppertal", "value": "C39195"},
                            {"label": "Wurselen", "value": "C39196"},
                            {"label": "Xanten", "value": "C39197"},
                            {"label": "Zulpich", "value": "C39198"}],
 "North Sinai": [{"label": "Arish", "value": "C24313"}],
 "North West": [{"label": "Bloemhof", "value": "C108136"},
                {"label": "Bojanala Platinum District Municipality",
                 "value": "C108137"},
                {"label": "Brits", "value": "C108138"},
                {"label": "Christiana", "value": "C108139"},
                {"label": "Dr Kenneth Kaunda District Municipality",
                 "value": "C108140"},
                {"label": "Dr Ruth Segomotsi Mompati District Municipality",
                 "value": "C108141"},
                {"label": "Fochville", "value": "C108142"},
                {"label": "Ga-Rankuwa", "value": "C108143"},
                {"label": "Jan Kempdorp", "value": "C108144"},
                {"label": "Klerksdorp", "value": "C108145"},
                {"label": "Koster", "value": "C108146"},
                {"label": "Lichtenburg", "value": "C108147"},
                {"label": "Mahikeng", "value": "C108148"},
                {"label": "Maile", "value": "C108149"},
                {"label": "Mmabatho", "value": "C108150"},
                {"label": "Ngaka Modiri Molema District Municipality",
                 "value": "C108151"},
                {"label": "Orkney", "value": "C108152"},
                {"label": "Potchefstroom", "value": "C108153"},
                {"label": "Rustenburg", "value": "C108154"},
                {"label": "Schweizer-Reneke", "value": "C108155"},
                {"label": "Stilfontein", "value": "C108156"},
                {"label": "Vryburg", "value": "C108157"},
                {"label": "Wolmaransstad", "value": "C108158"},
                {"label": "Zeerust", "value": "C108159"}],
 "North West Community Development Council": [{"label": "Woodlands",
                                               "value": "C107328"}],
 "North Western Province": [{"label": "Chilaw", "value": "C115323"},
                            {"label": "Kuliyapitiya", "value": "C115324"},
                            {"label": "Kurunegala", "value": "C115325"},
                            {"label": "Kurunegala District",
                             "value": "C115326"},
                            {"label": "Puttalam", "value": "C115327"},
                            {"label": "Puttalam District", "value": "C115328"}],
 "North-East District": [{"label": "Dukwe", "value": "C10179"},
                         {"label": "Makaleng", "value": "C10180"},
                         {"label": "Masunga", "value": "C10181"},
                         {"label": "Sebina", "value": "C10182"}],
 "North-West District": [{"label": "Maun", "value": "C10183"},
                         {"label": "Nokaneng", "value": "C10184"},
                         {"label": "Pandamatenga", "value": "C10185"},
                         {"label": "Sehithwa", "value": "C10186"},
                         {"label": "Shakawe", "value": "C10187"}],
 "Northeastern Region": [{"label": "Akureyri", "value": "C44841"},
                         {"label": "Dalvik", "value": "C44842"},
                         {"label": "Dalvikurbyggd", "value": "C44843"},
                         {"label": "Eyjafjardarsveit", "value": "C44844"},
                         {"label": "Fjallabyggd", "value": "C44845"},
                         {"label": "Grytubakkahreppur", "value": "C44846"},
                         {"label": "Horgarsveit", "value": "C44847"},
                         {"label": "Husavik", "value": "C44848"},
                         {"label": "Langanesbyggd", "value": "C44849"},
                         {"label": "Laugar", "value": "C44850"},
                         {"label": "Siglufjordur", "value": "C44851"},
                         {"label": "Skutustadahreppur", "value": "C44852"},
                         {"label": "Svalbardsstrandarhreppur",
                          "value": "C44853"},
                         {"label": "Tjorneshreppur", "value": "C44854"},
                         {"label": "Thingeyjarsveit", "value": "C44855"}],
 "Northern": [{"label": "Kpandae", "value": "C41535"},
              {"label": "Salaga", "value": "C41536"},
              {"label": "Savelugu", "value": "C41537"},
              {"label": "Tamale", "value": "C41538"},
              {"label": "Yendi", "value": "C41539"},
              {"label": "Ad Dabbah", "value": "C115416"},
              {"label": "Argo", "value": "C115417"},
              {"label": "Dongola", "value": "C115418"},
              {"label": "Karmah an Nuzul", "value": "C115419"},
              {"label": "Kuraymah", "value": "C115420"},
              {"label": "Merowe", "value": "C115421"}],
 "Northern Borders": [{"label": "Arar", "value": "C106788"},
                      {"label": "Nisab", "value": "C106789"},
                      {"label": "Turaif", "value": "C106790"},
                      {"label": "Umm Radamah", "value": "C106791"}],
 "Northern Cape": [{"label": "Barkly West", "value": "C108160"},
                   {"label": "Brandvlei", "value": "C108161"},
                   {"label": "Calvinia", "value": "C108162"},
                   {"label": "Carnarvon", "value": "C108163"},
                   {"label": "Colesberg", "value": "C108164"},
                   {"label": "Danielskuil", "value": "C108165"},
                   {"label": "De Aar", "value": "C108166"},
                   {"label": "Frances Baard District Municipality",
                    "value": "C108167"},
                   {"label": "Fraserburg", "value": "C108168"},
                   {"label": "John Taolo Gaetsewe District Municipality",
                    "value": "C108169"},
                   {"label": "Kathu", "value": "C108170"},
                   {"label": "Kenhardt", "value": "C108171"},
                   {"label": "Kimberley", "value": "C108172"},
                   {"label": "Kuruman", "value": "C108173"},
                   {"label": "Namakwa District Municipality",
                    "value": "C108174"},
                   {"label": "Noupoort", "value": "C108175"},
                   {"label": "Orania", "value": "C108176"},
                   {"label": "Pampierstad", "value": "C108177"},
                   {"label": "Pixley ka Seme District Municipality",
                    "value": "C108178"},
                   {"label": "Pofadder", "value": "C108179"},
                   {"label": "Postmasburg", "value": "C108180"},
                   {"label": "Prieska", "value": "C108181"},
                   {"label": "Ritchie", "value": "C108182"},
                   {"label": "Siyanda District Municipality",
                    "value": "C108183"},
                   {"label": "Springbok", "value": "C108184"},
                   {"label": "Upington", "value": "C108185"},
                   {"label": "Van Wyksvlei", "value": "C108186"},
                   {"label": "Warrenton", "value": "C108187"}],
 "Northern District": [{"label": "Eilabun", "value": "C51649"},
                       {"label": "Uzeir", "value": "C51650"},
                       {"label": "Acre", "value": "C51651"},
                       {"label": "Afula", "value": "C51652"},
                       {"label": "Basmat Tabun", "value": "C51653"},
                       {"label": "Beit Jann", "value": "C51654"},
                       {"label": "Bet Shean", "value": "C51655"},
                       {"label": "Bir el Maksur", "value": "C51656"},
                       {"label": "Bueina", "value": "C51657"},
                       {"label": "Buqeia", "value": "C51658"},
                       {"label": "Dabburiya", "value": "C51659"},
                       {"label": "Deir Hanna", "value": "C51660"},
                       {"label": "El Mazraa", "value": "C51661"},
                       {"label": "Er Reina", "value": "C51662"},
                       {"label": "Esh Sheikh Dannun", "value": "C51663"},
                       {"label": "Hurfeish", "value": "C51664"},
                       {"label": "Iksal", "value": "C51665"},
                       {"label": "Jish", "value": "C51666"},
                       {"label": "Judeida Makr", "value": "C51667"},
                       {"label": "Kabul", "value": "C51668"},
                       {"label": "Kafr Kamma", "value": "C51669"},
                       {"label": "Kafr Kanna", "value": "C51670"},
                       {"label": "Kafr Manda", "value": "C51671"},
                       {"label": "Kafr Misr", "value": "C51672"},
                       {"label": "Karmiel", "value": "C51673"},
                       {"label": "Kaukab Abu el Hija", "value": "C51674"},
                       {"label": "Kefar Rosh HaNiqra", "value": "C51675"},
                       {"label": "Kefar Tavor", "value": "C51676"},
                       {"label": "Kefar Weradim", "value": "C51677"},
                       {"label": "Kfar Yasif", "value": "C51678"},
                       {"label": "maalot Tarshiha", "value": "C51679"},
                       {"label": "Maghar", "value": "C51680"},
                       {"label": "Metulla", "value": "C51681"},
                       {"label": "Miilya", "value": "C51682"},
                       {"label": "Migdal HaEmeq", "value": "C51683"},
                       {"label": "Nahariyya", "value": "C51684"},
                       {"label": "Nahf", "value": "C51685"},
                       {"label": "Nazareth", "value": "C51686"},
                       {"label": "Nefat Akko", "value": "C51687"},
                       {"label": "Nein", "value": "C51688"},
                       {"label": "Pasuta", "value": "C51689"},
                       {"label": "Qiryat Shemona", "value": "C51690"},
                       {"label": "Ramat Yishay", "value": "C51691"},
                       {"label": "Rosh Pinna", "value": "C51692"},
                       {"label": "Rumat Heib", "value": "C51693"},
                       {"label": "Safed", "value": "C51694"},
                       {"label": "Sajur", "value": "C51695"},
                       {"label": "Sakhnin", "value": "C51696"},
                       {"label": "Sallama", "value": "C51697"},
                       {"label": "Shelomi", "value": "C51698"},
                       {"label": "Shibli", "value": "C51699"},
                       {"label": "Sulam", "value": "C51700"},
                       {"label": "Tamra", "value": "C51701"},
                       {"label": "Tiberias", "value": "C51702"},
                       {"label": "Timrat", "value": "C51703"},
                       {"label": "Yavneel", "value": "C51704"}],
 "Northern Division": [{"label": "Bua Province", "value": "C24815"},
                       {"label": "Cakaudrove Province", "value": "C24816"},
                       {"label": "Labasa", "value": "C24817"},
                       {"label": "Macuata Province", "value": "C24818"}],
 "Northern Ireland": [{"label": "Ahoghill", "value": "C126053"},
                      {"label": "Annahilt", "value": "C126054"},
                      {"label": "Annalong", "value": "C126055"},
                      {"label": "Antrim", "value": "C126056"},
                      {"label": "Antrim and Newtownabbey", "value": "C126057"},
                      {"label": "Ardglass", "value": "C126058"},
                      {"label": "Ards and North Down", "value": "C126059"},
                      {"label": "Armagh", "value": "C126060"},
                      {"label": "Armagh City Banbridge and Craigavon",
                       "value": "C126061"},
                      {"label": "Ballinamallard", "value": "C126062"},
                      {"label": "Ballintoy Harbour", "value": "C126063"},
                      {"label": "Ballycastle", "value": "C126064"},
                      {"label": "Ballyclare", "value": "C126065"},
                      {"label": "Ballygowan", "value": "C126066"},
                      {"label": "Ballykelly", "value": "C126067"},
                      {"label": "Ballymena", "value": "C126068"},
                      {"label": "Ballymoney", "value": "C126069"},
                      {"label": "Ballynahinch", "value": "C126070"},
                      {"label": "Ballypatrick", "value": "C126071"},
                      {"label": "Ballywalter", "value": "C126072"},
                      {"label": "Banbridge", "value": "C126073"},
                      {"label": "Bangor", "value": "C126074"},
                      {"label": "Belfast", "value": "C126075"},
                      {"label": "Bellaghy", "value": "C126076"},
                      {"label": "Broughshane", "value": "C126077"},
                      {"label": "Bushmills", "value": "C126078"},
                      {"label": "Carnlough", "value": "C126079"},
                      {"label": "Carnmoney", "value": "C126080"},
                      {"label": "Carrickfergus", "value": "C126081"},
                      {"label": "Carryduff", "value": "C126082"},
                      {"label": "Castledawson", "value": "C126083"},
                      {"label": "Castlederg", "value": "C126084"},
                      {"label": "Castlereagh", "value": "C126085"},
                      {"label": "Castlerock", "value": "C126086"},
                      {"label": "Castlewellan", "value": "C126087"},
                      {"label": "Causeway Coast and Glens", "value": "C126088"},
                      {"label": "City of Belfast", "value": "C126089"},
                      {"label": "Coalisland", "value": "C126090"},
                      {"label": "Coleraine", "value": "C126091"},
                      {"label": "Comber", "value": "C126092"},
                      {"label": "Connor", "value": "C126093"},
                      {"label": "Cookstown", "value": "C126094"},
                      {"label": "Craigavon", "value": "C126095"},
                      {"label": "Crossgar", "value": "C126096"},
                      {"label": "Crossmaglen", "value": "C126097"},
                      {"label": "Crumlin", "value": "C126098"},
                      {"label": "Cullybackey", "value": "C126099"},
                      {"label": "Culmore", "value": "C126100"},
                      {"label": "Cushendall", "value": "C126101"},
                      {"label": "Derry", "value": "C126102"},
                      {"label": "Derry City and Strabane", "value": "C126103"},
                      {"label": "Doagh", "value": "C126104"},
                      {"label": "Donaghadee", "value": "C126105"},
                      {"label": "Downpatrick", "value": "C126106"},
                      {"label": "Draperstown", "value": "C126107"},
                      {"label": "Dromore", "value": "C126108"},
                      {"label": "Dundonald", "value": "C126109"},
                      {"label": "Dundrum", "value": "C126110"},
                      {"label": "Dungannon", "value": "C126111"},
                      {"label": "Dungiven", "value": "C126112"},
                      {"label": "Dunloy", "value": "C126113"},
                      {"label": "Eglinton", "value": "C126114"},
                      {"label": "Enniskillen", "value": "C126115"},
                      {"label": "Fermanagh and Omagh", "value": "C126116"},
                      {"label": "Fintona", "value": "C126117"},
                      {"label": "Fivemiletown", "value": "C126118"},
                      {"label": "Garvagh", "value": "C126119"},
                      {"label": "Gilford", "value": "C126120"},
                      {"label": "Glenariff", "value": "C126121"},
                      {"label": "Glenavy", "value": "C126122"},
                      {"label": "Greenisland", "value": "C126123"},
                      {"label": "Greyabbey", "value": "C126124"},
                      {"label": "Hillsborough", "value": "C126125"},
                      {"label": "Holywood", "value": "C126126"},
                      {"label": "Irvinestown", "value": "C126127"},
                      {"label": "Jordanstown", "value": "C126128"},
                      {"label": "Keady", "value": "C126129"},
                      {"label": "Kilkeel", "value": "C126130"},
                      {"label": "Killyleagh", "value": "C126131"},
                      {"label": "Kilrea", "value": "C126132"},
                      {"label": "Kircubbin", "value": "C126133"},
                      {"label": "Larne", "value": "C126134"},
                      {"label": "Limavady", "value": "C126135"},
                      {"label": "Lisburn", "value": "C126136"},
                      {"label": "Lisburn and Castlereagh", "value": "C126137"},
                      {"label": "Lisnaskea", "value": "C126138"},
                      {"label": "Londonderry County Borough",
                       "value": "C126139"},
                      {"label": "Maghera", "value": "C126140"},
                      {"label": "Magherafelt", "value": "C126141"},
                      {"label": "Magheralin", "value": "C126142"},
                      {"label": "Mid and East Antrim", "value": "C126143"},
                      {"label": "Mid Ulster", "value": "C126144"},
                      {"label": "Millisle", "value": "C126145"},
                      {"label": "Moira", "value": "C126146"},
                      {"label": "Moneymore", "value": "C126147"},
                      {"label": "Moy", "value": "C126148"},
                      {"label": "Newcastle", "value": "C126149"},
                      {"label": "Newry", "value": "C126150"},
                      {"label": "Newry Mourne and Down", "value": "C126151"},
                      {"label": "Newtownabbey", "value": "C126152"},
                      {"label": "Newtownards", "value": "C126153"},
                      {"label": "Newtownstewart", "value": "C126154"},
                      {"label": "Omagh", "value": "C126155"},
                      {"label": "Portadown", "value": "C126156"},
                      {"label": "Portaferry", "value": "C126157"},
                      {"label": "Portavogie", "value": "C126158"},
                      {"label": "Portglenone", "value": "C126159"},
                      {"label": "Portrush", "value": "C126160"},
                      {"label": "Portstewart", "value": "C126161"},
                      {"label": "Randalstown", "value": "C126162"},
                      {"label": "Rathfriland", "value": "C126163"},
                      {"label": "Rostrevor", "value": "C126164"},
                      {"label": "Saintfield", "value": "C126165"},
                      {"label": "Sion Mills", "value": "C126166"},
                      {"label": "Strabane", "value": "C126167"},
                      {"label": "Tandragee", "value": "C126168"},
                      {"label": "Templepatrick", "value": "C126169"},
                      {"label": "Waringstown", "value": "C126170"},
                      {"label": "Warrenpoint", "value": "C126171"},
                      {"label": "Whitehead", "value": "C126172"}],
 "Northern Ostrobothnia": [{"label": "Alavieska", "value": "C24982"},
                           {"label": "Haapajarvi", "value": "C24983"},
                           {"label": "Haapavesi", "value": "C24984"},
                           {"label": "Hailuoto", "value": "C24985"},
                           {"label": "Haukipudas", "value": "C24986"},
                           {"label": "Himanka", "value": "C24987"},
                           {"label": "Ii", "value": "C24988"},
                           {"label": "Kalajoki", "value": "C24989"},
                           {"label": "Karsamaki", "value": "C24990"},
                           {"label": "Kempele", "value": "C24991"},
                           {"label": "Kestila", "value": "C24992"},
                           {"label": "Kiiminki", "value": "C24993"},
                           {"label": "Kuivaniemi", "value": "C24994"},
                           {"label": "Kuusamo", "value": "C24995"},
                           {"label": "Liminka", "value": "C24996"},
                           {"label": "Lumijoki", "value": "C24997"},
                           {"label": "Merijarvi", "value": "C24998"},
                           {"label": "Muhos", "value": "C24999"},
                           {"label": "Nivala", "value": "C25000"},
                           {"label": "Oulainen", "value": "C25001"},
                           {"label": "Oulu", "value": "C25002"},
                           {"label": "Oulunsalo", "value": "C25003"},
                           {"label": "Piippola", "value": "C25004"},
                           {"label": "Pudasjarvi", "value": "C25005"},
                           {"label": "Pulkkila", "value": "C25006"},
                           {"label": "Pyhajarvi", "value": "C25007"},
                           {"label": "Pyhajoki", "value": "C25008"},
                           {"label": "Pyhanta", "value": "C25009"},
                           {"label": "Raahe", "value": "C25010"},
                           {"label": "Rantsila", "value": "C25011"},
                           {"label": "Reisjarvi", "value": "C25012"},
                           {"label": "Ruukki", "value": "C25013"},
                           {"label": "Sievi", "value": "C25014"},
                           {"label": "Siikajoki", "value": "C25015"},
                           {"label": "Taivalkoski", "value": "C25016"},
                           {"label": "Tyrnava", "value": "C25017"},
                           {"label": "Utajarvi", "value": "C25018"},
                           {"label": "Vihanti", "value": "C25019"},
                           {"label": "Yli-Ii", "value": "C25020"},
                           {"label": "Ylikiiminki", "value": "C25021"},
                           {"label": "Ylivieska", "value": "C25022"}],
 "Northern Province": [{"label": "Byumba", "value": "C105713"},
                       {"label": "Musanze", "value": "C105714"},
                       {"label": "Alikalia", "value": "C107267"},
                       {"label": "Bindi", "value": "C107268"},
                       {"label": "Binkolo", "value": "C107269"},
                       {"label": "Bombali District", "value": "C107270"},
                       {"label": "Bumbuna", "value": "C107271"},
                       {"label": "Gberia Fotombu", "value": "C107272"},
                       {"label": "Kabala", "value": "C107273"},
                       {"label": "Kamakwie", "value": "C107274"},
                       {"label": "Kambia", "value": "C107275"},
                       {"label": "Kassiri", "value": "C107276"},
                       {"label": "Koinadugu District", "value": "C107277"},
                       {"label": "Konakridee", "value": "C107278"},
                       {"label": "Kukuna", "value": "C107279"},
                       {"label": "Loma", "value": "C107280"},
                       {"label": "Lunsar", "value": "C107281"},
                       {"label": "Magburaka", "value": "C107282"},
                       {"label": "Makali", "value": "C107283"},
                       {"label": "Makeni", "value": "C107284"},
                       {"label": "Mambolo", "value": "C107285"},
                       {"label": "Mange", "value": "C107286"},
                       {"label": "Masaka", "value": "C107287"},
                       {"label": "Masingbi", "value": "C107288"},
                       {"label": "Masoyila", "value": "C107289"},
                       {"label": "Pepel", "value": "C107290"},
                       {"label": "Rokupr", "value": "C107291"},
                       {"label": "Sawkta", "value": "C107292"},
                       {"label": "Seidu", "value": "C107293"},
                       {"label": "Tintafor", "value": "C107294"},
                       {"label": "Tonkolili District", "value": "C107295"},
                       {"label": "Yonibana", "value": "C107296"},
                       {"label": "Jaffna", "value": "C115329"},
                       {"label": "Jaffna District", "value": "C115330"},
                       {"label": "Kilinochchi", "value": "C115331"},
                       {"label": "Kilinochchi District", "value": "C115332"},
                       {"label": "Point Pedro", "value": "C115333"},
                       {"label": "Valvedditturai", "value": "C115334"},
                       {"label": "Vavuniya", "value": "C115335"},
                       {"label": "Vavuniya District", "value": "C115336"},
                       {"label": "Kaputa", "value": "C148124"},
                       {"label": "Kasama", "value": "C148125"},
                       {"label": "Luwingu", "value": "C148126"},
                       {"label": "Mbala", "value": "C148127"},
                       {"label": "Mporokoso", "value": "C148128"},
                       {"label": "Mpulungu", "value": "C148129"},
                       {"label": "Mungwi", "value": "C148130"}],
 "Northern Red Sea Region": [{"label": "Massawa", "value": "C24496"}],
 "Northern Region": [{"label": "Chitipa", "value": "C65581"},
                     {"label": "Chitipa District", "value": "C65582"},
                     {"label": "Karonga", "value": "C65583"},
                     {"label": "Karonga District", "value": "C65584"},
                     {"label": "Likoma District", "value": "C65585"},
                     {"label": "Livingstonia", "value": "C65586"},
                     {"label": "Mzimba", "value": "C65587"},
                     {"label": "Mzimba District", "value": "C65588"},
                     {"label": "Mzuzu", "value": "C65589"},
                     {"label": "Nkhata Bay", "value": "C65590"},
                     {"label": "Nkhata Bay District", "value": "C65591"},
                     {"label": "Rumphi", "value": "C65592"},
                     {"label": "Rumphi District", "value": "C65593"},
                     {"label": "Adjumani", "value": "C121249"},
                     {"label": "Amudat", "value": "C121250"},
                     {"label": "Apac", "value": "C121251"},
                     {"label": "Arua", "value": "C121252"},
                     {"label": "Gulu", "value": "C121253"},
                     {"label": "Kitgum", "value": "C121254"},
                     {"label": "Kotido", "value": "C121255"},
                     {"label": "Lira", "value": "C121256"},
                     {"label": "Moroto", "value": "C121257"},
                     {"label": "Moyo", "value": "C121258"},
                     {"label": "Nebbi", "value": "C121259"},
                     {"label": "Otuke District", "value": "C121260"},
                     {"label": "Oyam District", "value": "C121261"},
                     {"label": "Pader", "value": "C121262"},
                     {"label": "Pader Palwo", "value": "C121263"},
                     {"label": "Paidha", "value": "C121264"},
                     {"label": "Yumbe", "value": "C121265"}],
 "Northern Territory": [{"label": "Alawa", "value": "C3207"},
                        {"label": "Alice Springs", "value": "C3208"},
                        {"label": "Alyangula", "value": "C3209"},
                        {"label": "Anula", "value": "C3210"},
                        {"label": "Araluen", "value": "C3211"},
                        {"label": "Bakewell", "value": "C3212"},
                        {"label": "Barkly", "value": "C3213"},
                        {"label": "Bellamack", "value": "C3214"},
                        {"label": "Belyuen", "value": "C3215"},
                        {"label": "Berrimah", "value": "C3216"},
                        {"label": "Braitling", "value": "C3217"},
                        {"label": "Brinkin", "value": "C3218"},
                        {"label": "Central Desert", "value": "C3219"},
                        {"label": "Coconut Grove", "value": "C3220"},
                        {"label": "Coomalie", "value": "C3221"},
                        {"label": "Cossack", "value": "C3222"},
                        {"label": "Darwin", "value": "C3223"},
                        {"label": "Desert Springs", "value": "C3224"},
                        {"label": "Driver", "value": "C3225"},
                        {"label": "Durack", "value": "C3226"},
                        {"label": "East Arnhem", "value": "C3227"},
                        {"label": "East Side", "value": "C3228"},
                        {"label": "Fannie Bay", "value": "C3229"},
                        {"label": "Farrar", "value": "C3230"},
                        {"label": "Galiwinku", "value": "C3231"},
                        {"label": "Gillen", "value": "C3232"},
                        {"label": "Girraween", "value": "C3233"},
                        {"label": "Gray", "value": "C3234"},
                        {"label": "Gunbalanya", "value": "C3235"},
                        {"label": "Gunn", "value": "C3236"},
                        {"label": "Herbert", "value": "C3237"},
                        {"label": "Holtze", "value": "C3238"},
                        {"label": "Howard Springs", "value": "C3239"},
                        {"label": "Humpty Doo", "value": "C3240"},
                        {"label": "Jabiru", "value": "C3241"},
                        {"label": "Jingili", "value": "C3242"},
                        {"label": "Johnston", "value": "C3243"},
                        {"label": "Karama", "value": "C3244"},
                        {"label": "Katherine", "value": "C3245"},
                        {"label": "Katherine East", "value": "C3246"},
                        {"label": "Katherine South", "value": "C3247"},
                        {"label": "Larapinta", "value": "C3248"},
                        {"label": "Larrakeyah", "value": "C3249"},
                        {"label": "Leanyer", "value": "C3250"},
                        {"label": "Litchfield", "value": "C3251"},
                        {"label": "Ludmilla", "value": "C3252"},
                        {"label": "Lyons", "value": "C3253"},
                        {"label": "MacDonnell", "value": "C3254"},
                        {"label": "Malak", "value": "C3255"},
                        {"label": "Maningrida", "value": "C3256"},
                        {"label": "Marrara", "value": "C3257"},
                        {"label": "Milingimbi", "value": "C3258"},
                        {"label": "Millner", "value": "C3259"},
                        {"label": "Moil", "value": "C3260"},
                        {"label": "Moulden", "value": "C3261"},
                        {"label": "Muirhead", "value": "C3262"},
                        {"label": "Nakara", "value": "C3263"},
                        {"label": "Ngukurr", "value": "C3264"},
                        {"label": "Nhulunbuy", "value": "C3265"},
                        {"label": "Nightcliff", "value": "C3266"},
                        {"label": "Palmerston", "value": "C3267"},
                        {"label": "Parap", "value": "C3268"},
                        {"label": "Rapid Creek", "value": "C3269"},
                        {"label": "Roper Gulf", "value": "C3270"},
                        {"label": "Rosebery", "value": "C3271"},
                        {"label": "Ross", "value": "C3272"},
                        {"label": "Sadadeen", "value": "C3273"},
                        {"label": "Stuart Park", "value": "C3274"},
                        {"label": "Tennant Creek", "value": "C3275"},
                        {"label": "The Gap", "value": "C3276"},
                        {"label": "Tiwi", "value": "C3277"},
                        {"label": "Tiwi Islands", "value": "C3278"},
                        {"label": "Victoria Daly", "value": "C3279"},
                        {"label": "Virginia", "value": "C3280"},
                        {"label": "Wadeye", "value": "C3281"},
                        {"label": "Wagait", "value": "C3282"},
                        {"label": "Wagaman", "value": "C3283"},
                        {"label": "Wanguri", "value": "C3284"},
                        {"label": "West Arnhem", "value": "C3285"},
                        {"label": "Woodroffe", "value": "C3286"},
                        {"label": "Wulagi", "value": "C3287"},
                        {"label": "Wurrumiyanga", "value": "C3288"},
                        {"label": "Yulara", "value": "C3289"},
                        {"label": "Zuccoli", "value": "C3290"}],
 "Northland Region": [{"label": "Ahipara", "value": "C77801"},
                      {"label": "Dargaville", "value": "C77802"},
                      {"label": "Far North District", "value": "C77803"},
                      {"label": "Kaipara District", "value": "C77804"},
                      {"label": "Kaitaia", "value": "C77805"},
                      {"label": "Kawakawa", "value": "C77806"},
                      {"label": "Kerikeri", "value": "C77807"},
                      {"label": "Maungatapere", "value": "C77808"},
                      {"label": "Moerewa", "value": "C77809"},
                      {"label": "Ngunguru", "value": "C77810"},
                      {"label": "Paihia", "value": "C77811"},
                      {"label": "Ruakaka", "value": "C77812"},
                      {"label": "Taipa", "value": "C77813"},
                      {"label": "Waimate North", "value": "C77814"},
                      {"label": "Whangarei", "value": "C77815"}],
 "Northwest": [{"label": "Babanki", "value": "C16652"},
               {"label": "Bali", "value": "C16653"},
               {"label": "Bamenda", "value": "C16654"},
               {"label": "Batibo", "value": "C16655"},
               {"label": "Belo", "value": "C16656"},
               {"label": "Boyo", "value": "C16657"},
               {"label": "Fundong", "value": "C16658"},
               {"label": "Jakiri", "value": "C16659"},
               {"label": "Kumbo", "value": "C16660"},
               {"label": "Mbengwi", "value": "C16661"},
               {"label": "Mme-Bafumen", "value": "C16662"},
               {"label": "Njinikom", "value": "C16663"},
               {"label": "Wum", "value": "C16664"}],
 "Northwest Territories": [{"label": "Behchoko", "value": "C17070"},
                           {"label": "Fort McPherson", "value": "C17071"},
                           {"label": "Fort Smith", "value": "C17072"},
                           {"label": "Hay River", "value": "C17073"},
                           {"label": "Inuvik", "value": "C17074"},
                           {"label": "Norman Wells", "value": "C17075"},
                           {"label": "Yellowknife", "value": "C17076"}],
 "Northwestern Province": [{"label": "Kabompo", "value": "C148131"},
                           {"label": "Kalengwa", "value": "C148132"},
                           {"label": "Kansanshi", "value": "C148133"},
                           {"label": "Kasempa", "value": "C148134"},
                           {"label": "Mufumbwe", "value": "C148135"},
                           {"label": "Mwinilunga", "value": "C148136"},
                           {"label": "Solwezi", "value": "C148137"},
                           {"label": "Zambezi", "value": "C148138"}],
 "Northwestern Region": [{"label": "Akrahreppur", "value": "C44856"},
                         {"label": "Hunathing Vestra", "value": "C44857"},
                         {"label": "Saudarkrokur", "value": "C44858"},
                         {"label": "Skagabyggd", "value": "C44859"},
                         {"label": "Sveitarfelagid Skagafjordur",
                          "value": "C44860"}],
 "Nouakchott-Nord": [{"label": "Dar-Naim", "value": "C65960"},
                     {"label": "Teyarett", "value": "C65961"},
                     {"label": "Toujouonine", "value": "C65962"}],
 "Nouakchott-Ouest": [{"label": "Ksar", "value": "C65963"},
                      {"label": "Sebkha", "value": "C65964"},
                      {"label": "Tevragh-Zeina", "value": "C65965"}],
 "Nouakchott-Sud": [{"label": "Arafat", "value": "C65966"},
                    {"label": "Riyad", "value": "C65967"}],
 "Nouvelle-Aquitaine": [{"label": "Abzac", "value": "C31130"},
                        {"label": "Agen", "value": "C31131"},
                        {"label": "Agonac", "value": "C31132"},
                        {"label": "Ahetze", "value": "C31133"},
                        {"label": "Ahun", "value": "C31134"},
                        {"label": "Aiffres", "value": "C31135"},
                        {"label": "Aigre", "value": "C31136"},
                        {"label": "Aiguillon", "value": "C31137"},
                        {"label": "Airvault", "value": "C31138"},
                        {"label": "Aixe-sur-Vienne", "value": "C31139"},
                        {"label": "Ajain", "value": "C31140"},
                        {"label": "Allassac", "value": "C31141"},
                        {"label": "Ambares-et-Lagrave", "value": "C31142"},
                        {"label": "Ambazac", "value": "C31143"},
                        {"label": "Ambes", "value": "C31144"},
                        {"label": "Amou", "value": "C31145"},
                        {"label": "Andernos-les-Bains", "value": "C31146"},
                        {"label": "Andilly", "value": "C31147"},
                        {"label": "Anglet", "value": "C31148"},
                        {"label": "Angouleme", "value": "C31149"},
                        {"label": "Angoulins", "value": "C31150"},
                        {"label": "Angresse", "value": "C31151"},
                        {"label": "Annesse-et-Beaulieu", "value": "C31152"},
                        {"label": "Antonne-et-Trigonant", "value": "C31153"},
                        {"label": "Antran", "value": "C31154"},
                        {"label": "Arbonne", "value": "C31155"},
                        {"label": "Arbus", "value": "C31156"},
                        {"label": "Arcachon", "value": "C31157"},
                        {"label": "Arcangues", "value": "C31158"},
                        {"label": "Archigny", "value": "C31159"},
                        {"label": "Ardin", "value": "C31160"},
                        {"label": "Ares", "value": "C31161"},
                        {"label": "Arette", "value": "C31162"},
                        {"label": "Argentat", "value": "C31163"},
                        {"label": "Argenton-les-Vallees", "value": "C31164"},
                        {"label": "Arnac-la-Poste", "value": "C31165"},
                        {"label": "Arnac-Pompadour", "value": "C31166"},
                        {"label": "Ars-en-Re", "value": "C31167"},
                        {"label": "Arsac", "value": "C31168"},
                        {"label": "Arthez-de-Bearn", "value": "C31169"},
                        {"label": "Artiguelouve", "value": "C31170"},
                        {"label": "Artigues-pres-Bordeaux", "value": "C31171"},
                        {"label": "Artix", "value": "C31172"},
                        {"label": "Arudy", "value": "C31173"},
                        {"label": "Arvert", "value": "C31174"},
                        {"label": "Arveyres", "value": "C31175"},
                        {"label": "Ascain", "value": "C31176"},
                        {"label": "Asnieres-sur-Nouere", "value": "C31177"},
                        {"label": "Assat", "value": "C31178"},
                        {"label": "Asson", "value": "C31179"},
                        {"label": "Astaffort", "value": "C31180"},
                        {"label": "Atur", "value": "C31181"},
                        {"label": "Aubie-et-Espessas", "value": "C31182"},
                        {"label": "Aubusson", "value": "C31183"},
                        {"label": "Audenge", "value": "C31184"},
                        {"label": "Aulnay", "value": "C31185"},
                        {"label": "Auzances", "value": "C31186"},
                        {"label": "Availles-Limouzine", "value": "C31187"},
                        {"label": "Avanton", "value": "C31188"},
                        {"label": "Avensan", "value": "C31189"},
                        {"label": "Ayguemorte-les-Graves", "value": "C31190"},
                        {"label": "Ayron", "value": "C31191"},
                        {"label": "Aytre", "value": "C31192"},
                        {"label": "Azay-le-Brule", "value": "C31193"},
                        {"label": "Baignes-Sainte-Radegonde",
                         "value": "C31194"},
                        {"label": "Balzac", "value": "C31195"},
                        {"label": "Barbaste", "value": "C31196"},
                        {"label": "Barbezieux-Saint-Hilaire",
                         "value": "C31197"},
                        {"label": "Bardos", "value": "C31198"},
                        {"label": "Barsac", "value": "C31199"},
                        {"label": "Bassens", "value": "C31200"},
                        {"label": "Bassillac", "value": "C31201"},
                        {"label": "Bassussarry", "value": "C31202"},
                        {"label": "Bayonne", "value": "C31203"},
                        {"label": "Bazas", "value": "C31204"},
                        {"label": "Beaulieu-sur-Dordogne", "value": "C31205"},
                        {"label": "Beaumont", "value": "C31206"},
                        {"label": "Beaumont-du-Perigord", "value": "C31207"},
                        {"label": "Beaupuy", "value": "C31208"},
                        {"label": "Beautiran", "value": "C31209"},
                        {"label": "Beauvoir-sur-Niort", "value": "C31210"},
                        {"label": "Begaar", "value": "C31211"},
                        {"label": "Begles", "value": "C31212"},
                        {"label": "Belin-Beliet", "value": "C31213"},
                        {"label": "Bellac", "value": "C31214"},
                        {"label": "Belves", "value": "C31215"},
                        {"label": "Benejacq", "value": "C31216"},
                        {"label": "Benesse-Maremne", "value": "C31217"},
                        {"label": "Benquet", "value": "C31218"},
                        {"label": "Bergerac", "value": "C31219"},
                        {"label": "Berson", "value": "C31220"},
                        {"label": "Beruges", "value": "C31221"},
                        {"label": "Bessines", "value": "C31222"},
                        {"label": "Bessines-sur-Gartempe", "value": "C31223"},
                        {"label": "Beychac-et-Caillau", "value": "C31224"},
                        {"label": "Beynat", "value": "C31225"},
                        {"label": "Biard", "value": "C31226"},
                        {"label": "Biarritz", "value": "C31227"},
                        {"label": "Bias", "value": "C31228"},
                        {"label": "Bidache", "value": "C31229"},
                        {"label": "Bidart", "value": "C31230"},
                        {"label": "Biganos", "value": "C31231"},
                        {"label": "Bignoux", "value": "C31232"},
                        {"label": "Billere", "value": "C31233"},
                        {"label": "Biscarrosse", "value": "C31234"},
                        {"label": "Bizanos", "value": "C31235"},
                        {"label": "Blanquefort", "value": "C31236"},
                        {"label": "Blaye", "value": "C31237"},
                        {"label": "Boe", "value": "C31238"},
                        {"label": "Boeil-Bezing", "value": "C31239"},
                        {"label": "Boisme", "value": "C31240"},
                        {"label": "Boisseuil", "value": "C31241"},
                        {"label": "Bon-Encontre", "value": "C31242"},
                        {"label": "Bonnac-la-Cote", "value": "C31243"},
                        {"label": "Bonnat", "value": "C31244"},
                        {"label": "Bonnes", "value": "C31245"},
                        {"label": "Bonneuil-Matours", "value": "C31246"},
                        {"label": "Bordeaux", "value": "C31247"},
                        {"label": "Bordes", "value": "C31248"},
                        {"label": "Bords", "value": "C31249"},
                        {"label": "Bort-les-Orgues", "value": "C31250"},
                        {"label": "Bosdarros", "value": "C31251"},
                        {"label": "Bosmie-lAiguille", "value": "C31252"},
                        {"label": "Boucau", "value": "C31253"},
                        {"label": "Bouille-Loretz", "value": "C31254"},
                        {"label": "Boulazac", "value": "C31255"},
                        {"label": "Bouliac", "value": "C31256"},
                        {"label": "Bourcefranc-le-Chapus", "value": "C31257"},
                        {"label": "Bourg", "value": "C31258"},
                        {"label": "Bourganeuf", "value": "C31259"},
                        {"label": "Bourgneuf", "value": "C31260"},
                        {"label": "Boussac", "value": "C31261"},
                        {"label": "Boutiers-Saint-Trojan", "value": "C31262"},
                        {"label": "Boyard-Ville", "value": "C31263"},
                        {"label": "Branne", "value": "C31264"},
                        {"label": "Brantome", "value": "C31265"},
                        {"label": "Braud-et-Saint-Louis", "value": "C31266"},
                        {"label": "Brax", "value": "C31267"},
                        {"label": "Bressuire", "value": "C31268"},
                        {"label": "Breuil-Magne", "value": "C31269"},
                        {"label": "Breuillet", "value": "C31270"},
                        {"label": "Brie", "value": "C31271"},
                        {"label": "Brigueuil", "value": "C31272"},
                        {"label": "Brioux-sur-Boutonne", "value": "C31273"},
                        {"label": "Briscous", "value": "C31274"},
                        {"label": "Brive-la-Gaillarde", "value": "C31275"},
                        {"label": "Bruges", "value": "C31276"},
                        {"label": "Bugeat", "value": "C31277"},
                        {"label": "Burie", "value": "C31278"},
                        {"label": "Buros", "value": "C31279"},
                        {"label": "Bussac-sur-Charente", "value": "C31280"},
                        {"label": "Bussiere-Dunoise", "value": "C31281"},
                        {"label": "Bussiere-Galant", "value": "C31282"},
                        {"label": "Bussiere-Poitevine", "value": "C31283"},
                        {"label": "Buxerolles", "value": "C31284"},
                        {"label": "Buzet-sur-Baise", "value": "C31285"},
                        {"label": "Cabanac-et-Villagrains", "value": "C31286"},
                        {"label": "Cabariot", "value": "C31287"},
                        {"label": "Cadaujac", "value": "C31288"},
                        {"label": "Cadillac", "value": "C31289"},
                        {"label": "Cambes", "value": "C31290"},
                        {"label": "Camblanes-et-Meynac", "value": "C31291"},
                        {"label": "Cambo-les-Bains", "value": "C31292"},
                        {"label": "Cancon", "value": "C31293"},
                        {"label": "Canejan", "value": "C31294"},
                        {"label": "Cantenac", "value": "C31295"},
                        {"label": "Capbreton", "value": "C31296"},
                        {"label": "Captieux", "value": "C31297"},
                        {"label": "Carbon-Blanc", "value": "C31298"},
                        {"label": "Carcans", "value": "C31299"},
                        {"label": "Carignan-de-Bordeaux", "value": "C31300"},
                        {"label": "Cars", "value": "C31301"},
                        {"label": "Carsac-Aillac", "value": "C31302"},
                        {"label": "Casseneuil", "value": "C31303"},
                        {"label": "Castelculier", "value": "C31304"},
                        {"label": "Casteljaloux", "value": "C31305"},
                        {"label": "Castelmoron-sur-Lot", "value": "C31306"},
                        {"label": "Castelnau-de-Medoc", "value": "C31307"},
                        {"label": "Castets", "value": "C31308"},
                        {"label": "Castets-en-Dorthe", "value": "C31309"},
                        {"label": "Castillon-la-Bataille", "value": "C31310"},
                        {"label": "Castillonnes", "value": "C31311"},
                        {"label": "Castres-Gironde", "value": "C31312"},
                        {"label": "Cavignac", "value": "C31313"},
                        {"label": "Cazeres-sur-lAdour", "value": "C31314"},
                        {"label": "Celle-Levescault", "value": "C31315"},
                        {"label": "Celles-sur-Belle", "value": "C31316"},
                        {"label": "Cenac", "value": "C31317"},
                        {"label": "Cenac-et-Saint-Julien", "value": "C31318"},
                        {"label": "Cenon", "value": "C31319"},
                        {"label": "Cenon-sur-Vienne", "value": "C31320"},
                        {"label": "Cercoux", "value": "C31321"},
                        {"label": "Cerizay", "value": "C31322"},
                        {"label": "Cerons", "value": "C31323"},
                        {"label": "Cestas", "value": "C31324"},
                        {"label": "Cezac", "value": "C31325"},
                        {"label": "Chabanais", "value": "C31326"},
                        {"label": "Chaillevette", "value": "C31327"},
                        {"label": "Chalais", "value": "C31328"},
                        {"label": "Chalus", "value": "C31329"},
                        {"label": "Chamberet", "value": "C31330"},
                        {"label": "Chambon-sur-Voueize", "value": "C31331"},
                        {"label": "Chamboulive", "value": "C31332"},
                        {"label": "Chameyrat", "value": "C31333"},
                        {"label": "Champagne-Mouton", "value": "C31334"},
                        {"label": "Champcevinel", "value": "C31335"},
                        {"label": "Champdeniers-Saint-Denis",
                         "value": "C31336"},
                        {"label": "Champniers", "value": "C31337"},
                        {"label": "Chancelade", "value": "C31338"},
                        {"label": "Chaniers", "value": "C31339"},
                        {"label": "Chaptelat", "value": "C31340"},
                        {"label": "Charente", "value": "C31341"},
                        {"label": "Charente-Maritime", "value": "C31342"},
                        {"label": "Charron", "value": "C31343"},
                        {"label": "Charroux", "value": "C31344"},
                        {"label": "Chasseneuil-du-Poitou", "value": "C31345"},
                        {"label": "Chasseneuil-sur-Bonnieure",
                         "value": "C31346"},
                        {"label": "Chassors", "value": "C31347"},
                        {"label": "Chateaubernard", "value": "C31348"},
                        {"label": "Chateauneuf-la-Foret", "value": "C31349"},
                        {"label": "Chateauneuf-sur-Charente",
                         "value": "C31350"},
                        {"label": "Chateauponsac", "value": "C31351"},
                        {"label": "Chatelaillon-Plage", "value": "C31352"},
                        {"label": "Chatellerault", "value": "C31353"},
                        {"label": "Chatillon-sur-Thouet", "value": "C31354"},
                        {"label": "Chaunay", "value": "C31355"},
                        {"label": "Chauray", "value": "C31356"},
                        {"label": "Chauvigny", "value": "C31357"},
                        {"label": "Chazelles", "value": "C31358"},
                        {"label": "Chef-Boutonne", "value": "C31359"},
                        {"label": "Cherac", "value": "C31360"},
                        {"label": "Cheraute", "value": "C31361"},
                        {"label": "Chermignac", "value": "C31362"},
                        {"label": "Cherves-Richemont", "value": "C31363"},
                        {"label": "Cherveux", "value": "C31364"},
                        {"label": "Chevanceaux", "value": "C31365"},
                        {"label": "Chiche", "value": "C31366"},
                        {"label": "Ciboure", "value": "C31367"},
                        {"label": "Cire-dAunis", "value": "C31368"},
                        {"label": "Cisse", "value": "C31369"},
                        {"label": "Civray", "value": "C31370"},
                        {"label": "Clairac", "value": "C31371"},
                        {"label": "Clerac", "value": "C31372"},
                        {"label": "Coarraze", "value": "C31373"},
                        {"label": "Cognac", "value": "C31374"},
                        {"label": "Cognac-la-Foret", "value": "C31375"},
                        {"label": "Colayrac-Saint-Cirq", "value": "C31376"},
                        {"label": "Colombiers", "value": "C31377"},
                        {"label": "Combrand", "value": "C31378"},
                        {"label": "Compreignac", "value": "C31379"},
                        {"label": "Condat-sur-Vienne", "value": "C31380"},
                        {"label": "Confolens", "value": "C31381"},
                        {"label": "Corme-Royal", "value": "C31382"},
                        {"label": "Cornil", "value": "C31383"},
                        {"label": "Correze", "value": "C31384"},
                        {"label": "Cosnac", "value": "C31385"},
                        {"label": "Couhe", "value": "C31386"},
                        {"label": "Coulombiers", "value": "C31387"},
                        {"label": "Coulon", "value": "C31388"},
                        {"label": "Coulonges-sur-lAutize", "value": "C31389"},
                        {"label": "Coulounieix-Chamiers", "value": "C31390"},
                        {"label": "Courcon", "value": "C31391"},
                        {"label": "Courlay", "value": "C31392"},
                        {"label": "Cours-de-Pile", "value": "C31393"},
                        {"label": "Coursac", "value": "C31394"},
                        {"label": "Coussac-Bonneval", "value": "C31395"},
                        {"label": "Coutras", "value": "C31396"},
                        {"label": "Couzeix", "value": "C31397"},
                        {"label": "Cozes", "value": "C31398"},
                        {"label": "Creon", "value": "C31399"},
                        {"label": "Creuse", "value": "C31400"},
                        {"label": "Creysse", "value": "C31401"},
                        {"label": "Cublac", "value": "C31402"},
                        {"label": "Cubzac-les-Ponts", "value": "C31403"},
                        {"label": "Cussac", "value": "C31404"},
                        {"label": "Cussac-Fort-Medoc", "value": "C31405"},
                        {"label": "Damazan", "value": "C31406"},
                        {"label": "Dange-Saint-Romain", "value": "C31407"},
                        {"label": "Dax", "value": "C31408"},
                        {"label": "Denguin", "value": "C31409"},
                        {"label": "Departement de la Correze",
                         "value": "C31410"},
                        {"label": "Departement des Deux-Sevres",
                         "value": "C31411"},
                        {"label": "Departement des Pyrenees-Atlantiques",
                         "value": "C31412"},
                        {"label": "Departement du Lot-et-Garonne",
                         "value": "C31413"},
                        {"label": "Dignac", "value": "C31414"},
                        {"label": "Dirac", "value": "C31415"},
                        {"label": "Dissay", "value": "C31416"},
                        {"label": "Dolus-dOleron", "value": "C31417"},
                        {"label": "Domme", "value": "C31418"},
                        {"label": "Dompierre-sur-Mer", "value": "C31419"},
                        {"label": "Donzenac", "value": "C31420"},
                        {"label": "Dordogne", "value": "C31421"},
                        {"label": "Dun-le-Palestel", "value": "C31422"},
                        {"label": "Duras", "value": "C31423"},
                        {"label": "echillais", "value": "C31424"},
                        {"label": "echire", "value": "C31425"},
                        {"label": "ecoyeux", "value": "C31426"},
                        {"label": "egletons", "value": "C31427"},
                        {"label": "Esnandes", "value": "C31428"},
                        {"label": "Espelette", "value": "C31429"},
                        {"label": "Estillac", "value": "C31430"},
                        {"label": "etagnac", "value": "C31431"},
                        {"label": "etaules", "value": "C31432"},
                        {"label": "etauliers", "value": "C31433"},
                        {"label": "evaux-les-Bains", "value": "C31434"},
                        {"label": "Excideuil", "value": "C31435"},
                        {"label": "Exideuil", "value": "C31436"},
                        {"label": "Exireuil", "value": "C31437"},
                        {"label": "Eymet", "value": "C31438"},
                        {"label": "Eymoutiers", "value": "C31439"},
                        {"label": "Eysines", "value": "C31440"},
                        {"label": "Eyvigues-et-Eybenes", "value": "C31441"},
                        {"label": "Fargues-Saint-Hilaire", "value": "C31442"},
                        {"label": "Felletin", "value": "C31443"},
                        {"label": "Feytiat", "value": "C31444"},
                        {"label": "Fleac", "value": "C31445"},
                        {"label": "Floirac", "value": "C31446"},
                        {"label": "Fontaine-le-Comte", "value": "C31447"},
                        {"label": "Fontcouverte", "value": "C31448"},
                        {"label": "Fors", "value": "C31449"},
                        {"label": "Foulayronnes", "value": "C31450"},
                        {"label": "Fouras", "value": "C31451"},
                        {"label": "Fourques-sur-Garonne", "value": "C31452"},
                        {"label": "Fronsac", "value": "C31453"},
                        {"label": "Frontenay-Rohan-Rohan", "value": "C31454"},
                        {"label": "Fumel", "value": "C31455"},
                        {"label": "Gabarret", "value": "C31456"},
                        {"label": "Gaillan-en-Medoc", "value": "C31457"},
                        {"label": "Galgon", "value": "C31458"},
                        {"label": "Gan", "value": "C31459"},
                        {"label": "Garat", "value": "C31460"},
                        {"label": "Gardonne", "value": "C31461"},
                        {"label": "Garlin", "value": "C31462"},
                        {"label": "Gauriaguet", "value": "C31463"},
                        {"label": "Gelos", "value": "C31464"},
                        {"label": "Gemozac", "value": "C31465"},
                        {"label": "Genissac", "value": "C31466"},
                        {"label": "Gensac-la-Pallue", "value": "C31467"},
                        {"label": "Ger", "value": "C31468"},
                        {"label": "Gironde", "value": "C31469"},
                        {"label": "Gironde-sur-Dropt", "value": "C31470"},
                        {"label": "Gond-Pontouvre", "value": "C31471"},
                        {"label": "Gontaud-de-Nogaret", "value": "C31472"},
                        {"label": "Gouzon", "value": "C31473"},
                        {"label": "Gradignan", "value": "C31474"},
                        {"label": "Grenade-sur-lAdour", "value": "C31475"},
                        {"label": "Grignols", "value": "C31476"},
                        {"label": "Gueret", "value": "C31477"},
                        {"label": "Guethary", "value": "C31478"},
                        {"label": "Guitres", "value": "C31479"},
                        {"label": "Gujan-Mestras", "value": "C31480"},
                        {"label": "Habas", "value": "C31481"},
                        {"label": "Hagetmau", "value": "C31482"},
                        {"label": "Hasparren", "value": "C31483"},
                        {"label": "Haute-Vienne", "value": "C31484"},
                        {"label": "Hautefort", "value": "C31485"},
                        {"label": "Hendaye", "value": "C31486"},
                        {"label": "Heugas", "value": "C31487"},
                        {"label": "Hiersac", "value": "C31488"},
                        {"label": "Hinx", "value": "C31489"},
                        {"label": "Hourtin", "value": "C31490"},
                        {"label": "Idron", "value": "C31491"},
                        {"label": "Igon", "value": "C31492"},
                        {"label": "Illats", "value": "C31493"},
                        {"label": "Isle", "value": "C31494"},
                        {"label": "Iteuil", "value": "C31495"},
                        {"label": "Itxassou", "value": "C31496"},
                        {"label": "Izon", "value": "C31497"},
                        {"label": "Jarnac", "value": "C31498"},
                        {"label": "Jaunay-Clan", "value": "C31499"},
                        {"label": "Jonzac", "value": "C31500"},
                        {"label": "Juillac", "value": "C31501"},
                        {"label": "Jumilhac-le-Grand", "value": "C31502"},
                        {"label": "Jurancon", "value": "C31503"},
                        {"label": "La Chapelle-Saint-Laurent",
                         "value": "C31504"},
                        {"label": "La Coquille", "value": "C31505"},
                        {"label": "La Couarde-sur-Mer", "value": "C31506"},
                        {"label": "La Couronne", "value": "C31507"},
                        {"label": "La Courtine", "value": "C31508"},
                        {"label": "La Creche", "value": "C31509"},
                        {"label": "La Flotte", "value": "C31510"},
                        {"label": "La Force", "value": "C31511"},
                        {"label": "La Foret-sur-Sevre", "value": "C31512"},
                        {"label": "La Jarne", "value": "C31513"},
                        {"label": "La Jarrie", "value": "C31514"},
                        {"label": "La Lande-de-Fronsac", "value": "C31515"},
                        {"label": "La Mothe-Saint-Heray", "value": "C31516"},
                        {"label": "La Peyratte", "value": "C31517"},
                        {"label": "La Reole", "value": "C31518"},
                        {"label": "La Roche-Chalais", "value": "C31519"},
                        {"label": "La Roche-Posay", "value": "C31520"},
                        {"label": "La Rochefoucauld", "value": "C31521"},
                        {"label": "La Rochelle", "value": "C31522"},
                        {"label": "La Sauve", "value": "C31523"},
                        {"label": "La Souterraine", "value": "C31524"},
                        {"label": "La Teste-de-Buch", "value": "C31525"},
                        {"label": "La Tremblade", "value": "C31526"},
                        {"label": "La Villedieu-du-Clain", "value": "C31527"},
                        {"label": "Labatut", "value": "C31528"},
                        {"label": "Labenne", "value": "C31529"},
                        {"label": "Labouheyre", "value": "C31530"},
                        {"label": "Lacanau", "value": "C31531"},
                        {"label": "Ladignac-le-Long", "value": "C31532"},
                        {"label": "Lagor", "value": "C31533"},
                        {"label": "Lagorce", "value": "C31534"},
                        {"label": "Lagord", "value": "C31535"},
                        {"label": "Laguenne", "value": "C31536"},
                        {"label": "Lahonce", "value": "C31537"},
                        {"label": "Lalinde", "value": "C31538"},
                        {"label": "Lamarque", "value": "C31539"},
                        {"label": "Lamonzie-Saint-Martin", "value": "C31540"},
                        {"label": "Lamothe-Montravel", "value": "C31541"},
                        {"label": "Landes", "value": "C31542"},
                        {"label": "Landiras", "value": "C31543"},
                        {"label": "Langoiran", "value": "C31544"},
                        {"label": "Langon", "value": "C31545"},
                        {"label": "Lanouaille", "value": "C31546"},
                        {"label": "Lanton", "value": "C31547"},
                        {"label": "Laplume", "value": "C31548"},
                        {"label": "Larche", "value": "C31549"},
                        {"label": "Laroque-Timbaut", "value": "C31550"},
                        {"label": "Larressore", "value": "C31551"},
                        {"label": "Laruns", "value": "C31552"},
                        {"label": "Laruscade", "value": "C31553"},
                        {"label": "Lasseube", "value": "C31554"},
                        {"label": "Lathus-Saint-Remy", "value": "C31555"},
                        {"label": "Latille", "value": "C31556"},
                        {"label": "Latresne", "value": "C31557"},
                        {"label": "Lavardac", "value": "C31558"},
                        {"label": "Lavoux", "value": "C31559"},
                        {"label": "Layrac", "value": "C31560"},
                        {"label": "Le Barp", "value": "C31561"},
                        {"label": "Le Bois-Plage-en-Re", "value": "C31562"},
                        {"label": "Le Bouscat", "value": "C31563"},
                        {"label": "Le Bugue", "value": "C31564"},
                        {"label": "Le Buisson-de-Cadouin", "value": "C31565"},
                        {"label": "Le Dorat", "value": "C31566"},
                        {"label": "Le Fleix", "value": "C31567"},
                        {"label": "Le Grand-Bourg", "value": "C31568"},
                        {"label": "Le Gua", "value": "C31569"},
                        {"label": "Le Haillan", "value": "C31570"},
                        {"label": "Le Lardin-Saint-Lazare", "value": "C31571"},
                        {"label": "Le Mas-dAgenais", "value": "C31572"},
                        {"label": "Le Palais-sur-Vienne", "value": "C31573"},
                        {"label": "Le Passage", "value": "C31574"},
                        {"label": "Le Pian-Medoc", "value": "C31575"},
                        {"label": "Le Pin", "value": "C31576"},
                        {"label": "Le Pizou", "value": "C31577"},
                        {"label": "Le Porge", "value": "C31578"},
                        {"label": "Le Taillan-Medoc", "value": "C31579"},
                        {"label": "Le Tallud", "value": "C31580"},
                        {"label": "Le Teich", "value": "C31581"},
                        {"label": "Le Thou", "value": "C31582"},
                        {"label": "Le Verdon-sur-Mer", "value": "C31583"},
                        {"label": "Le Vigen", "value": "C31584"},
                        {"label": "Ledeuix", "value": "C31585"},
                        {"label": "Lembras", "value": "C31586"},
                        {"label": "Lencloitre", "value": "C31587"},
                        {"label": "Leognan", "value": "C31588"},
                        {"label": "Leon", "value": "C31589"},
                        {"label": "Les Artigues-de-Lussac", "value": "C31590"},
                        {"label": "Les eglisottes-et-Chalaures",
                         "value": "C31591"},
                        {"label": "Les Gonds", "value": "C31592"},
                        {"label": "Les Mathes", "value": "C31593"},
                        {"label": "Les Ormes", "value": "C31594"},
                        {"label": "Les Peintures", "value": "C31595"},
                        {"label": "Lescar", "value": "C31596"},
                        {"label": "Lesparre-Medoc", "value": "C31597"},
                        {"label": "Lestelle-Betharram", "value": "C31598"},
                        {"label": "Lezay", "value": "C31599"},
                        {"label": "Libourne", "value": "C31600"},
                        {"label": "Liguge", "value": "C31601"},
                        {"label": "Limoges", "value": "C31602"},
                        {"label": "Linards", "value": "C31603"},
                        {"label": "Linars", "value": "C31604"},
                        {"label": "Linxe", "value": "C31605"},
                        {"label": "Listrac-Medoc", "value": "C31606"},
                        {"label": "Lit-et-Mixe", "value": "C31607"},
                        {"label": "Lons", "value": "C31608"},
                        {"label": "Lormont", "value": "C31609"},
                        {"label": "Loubert", "value": "C31610"},
                        {"label": "Loudun", "value": "C31611"},
                        {"label": "Loupiac", "value": "C31612"},
                        {"label": "Louvie-Juzon", "value": "C31613"},
                        {"label": "Louzy", "value": "C31614"},
                        {"label": "Lubersac", "value": "C31615"},
                        {"label": "Lucq-de-Bearn", "value": "C31616"},
                        {"label": "Ludon-Medoc", "value": "C31617"},
                        {"label": "Lugon-et-lIle-du-Carnay", "value": "C31618"},
                        {"label": "Lusignan", "value": "C31619"},
                        {"label": "Lussac", "value": "C31620"},
                        {"label": "Lussac-les-Chateaux", "value": "C31621"},
                        {"label": "Macau", "value": "C31622"},
                        {"label": "Magescq", "value": "C31623"},
                        {"label": "Magnac-Laval", "value": "C31624"},
                        {"label": "Magnac-sur-Touvre", "value": "C31625"},
                        {"label": "Magne", "value": "C31626"},
                        {"label": "Malemort-sur-Correze", "value": "C31627"},
                        {"label": "Mansac", "value": "C31628"},
                        {"label": "Mansle", "value": "C31629"},
                        {"label": "Marans", "value": "C31630"},
                        {"label": "Marcamps", "value": "C31631"},
                        {"label": "Marcheprime", "value": "C31632"},
                        {"label": "Marcillac", "value": "C31633"},
                        {"label": "Marennes", "value": "C31634"},
                        {"label": "Mareuil", "value": "C31635"},
                        {"label": "Margaux", "value": "C31636"},
                        {"label": "Marigny-Brizay", "value": "C31637"},
                        {"label": "Marmande", "value": "C31638"},
                        {"label": "Marsilly", "value": "C31639"},
                        {"label": "Martignas-sur-Jalle", "value": "C31640"},
                        {"label": "Martillac", "value": "C31641"},
                        {"label": "Matha", "value": "C31642"},
                        {"label": "Mauleon-Licharre", "value": "C31643"},
                        {"label": "Mauze-sur-le-Mignon", "value": "C31644"},
                        {"label": "Mauze-Thouarsais", "value": "C31645"},
                        {"label": "Mazeres-Lezons", "value": "C31646"},
                        {"label": "Medis", "value": "C31647"},
                        {"label": "Mees", "value": "C31648"},
                        {"label": "Meilhan", "value": "C31649"},
                        {"label": "Meilhan-sur-Garonne", "value": "C31650"},
                        {"label": "Melle", "value": "C31651"},
                        {"label": "Menesplet", "value": "C31652"},
                        {"label": "Mensignac", "value": "C31653"},
                        {"label": "Merignac", "value": "C31654"},
                        {"label": "Meschers-sur-Gironde", "value": "C31655"},
                        {"label": "Meursac", "value": "C31656"},
                        {"label": "Meymac", "value": "C31657"},
                        {"label": "Meyssac", "value": "C31658"},
                        {"label": "Mezin", "value": "C31659"},
                        {"label": "Mignaloux-Beauvoir", "value": "C31660"},
                        {"label": "Migne-Auxances", "value": "C31661"},
                        {"label": "Mimbaste", "value": "C31662"},
                        {"label": "Mimizan", "value": "C31663"},
                        {"label": "Mios", "value": "C31664"},
                        {"label": "Mirambeau", "value": "C31665"},
                        {"label": "Miramont-de-Guyenne", "value": "C31666"},
                        {"label": "Mirebeau", "value": "C31667"},
                        {"label": "Mirepeix", "value": "C31668"},
                        {"label": "Monbazillac", "value": "C31669"},
                        {"label": "Moncontour", "value": "C31670"},
                        {"label": "Moncoutant", "value": "C31671"},
                        {"label": "Monein", "value": "C31672"},
                        {"label": "Monflanquin", "value": "C31673"},
                        {"label": "Monsegur", "value": "C31674"},
                        {"label": "Monsempron-Libos", "value": "C31675"},
                        {"label": "Mont", "value": "C31676"},
                        {"label": "Mont-de-Marsan", "value": "C31677"},
                        {"label": "Montagne", "value": "C31678"},
                        {"label": "Montamise", "value": "C31679"},
                        {"label": "Montardon", "value": "C31680"},
                        {"label": "Montaut", "value": "C31681"},
                        {"label": "Montayral", "value": "C31682"},
                        {"label": "Montbron", "value": "C31683"},
                        {"label": "Montcaret", "value": "C31684"},
                        {"label": "Montendre", "value": "C31685"},
                        {"label": "Montfort-en-Chalosse", "value": "C31686"},
                        {"label": "Montguyon", "value": "C31687"},
                        {"label": "Montignac", "value": "C31688"},
                        {"label": "Montlieu-la-Garde", "value": "C31689"},
                        {"label": "Montmoreau-Saint-Cybard", "value": "C31690"},
                        {"label": "Montmorillon", "value": "C31691"},
                        {"label": "Montpon-Menesterol", "value": "C31692"},
                        {"label": "Montrem", "value": "C31693"},
                        {"label": "Montussan", "value": "C31694"},
                        {"label": "Morcenx", "value": "C31695"},
                        {"label": "Morlaas", "value": "C31696"},
                        {"label": "Mornac", "value": "C31697"},
                        {"label": "Mortagne-sur-Gironde", "value": "C31698"},
                        {"label": "Mougon", "value": "C31699"},
                        {"label": "Mouguerre", "value": "C31700"},
                        {"label": "Mouleydier", "value": "C31701"},
                        {"label": "Mouliets-et-Villemartin", "value": "C31702"},
                        {"label": "Moulis-en-Medoc", "value": "C31703"},
                        {"label": "Mourenx", "value": "C31704"},
                        {"label": "Mouthiers-sur-Boeme", "value": "C31705"},
                        {"label": "Mugron", "value": "C31706"},
                        {"label": "Muron", "value": "C31707"},
                        {"label": "Mussidan", "value": "C31708"},
                        {"label": "Naintre", "value": "C31709"},
                        {"label": "Nanteuil", "value": "C31710"},
                        {"label": "Nanteuil-en-Vallee", "value": "C31711"},
                        {"label": "Nantiat", "value": "C31712"},
                        {"label": "Narrosse", "value": "C31713"},
                        {"label": "Navailles-Angos", "value": "C31714"},
                        {"label": "Navarrenx", "value": "C31715"},
                        {"label": "Naves", "value": "C31716"},
                        {"label": "Nay", "value": "C31717"},
                        {"label": "Nerac", "value": "C31718"},
                        {"label": "Nercillac", "value": "C31719"},
                        {"label": "Nersac", "value": "C31720"},
                        {"label": "Neuvic", "value": "C31721"},
                        {"label": "Neuvic-Entier", "value": "C31722"},
                        {"label": "Neuville-de-Poitou", "value": "C31723"},
                        {"label": "Nexon", "value": "C31724"},
                        {"label": "Nieul", "value": "C31725"},
                        {"label": "Nieul-les-Saintes", "value": "C31726"},
                        {"label": "Nieul-sur-Mer", "value": "C31727"},
                        {"label": "Niort", "value": "C31728"},
                        {"label": "Noaillan", "value": "C31729"},
                        {"label": "Nontron", "value": "C31730"},
                        {"label": "Notre-Dame-de-Sanilhac", "value": "C31731"},
                        {"label": "Nouaille-Maupertuis", "value": "C31732"},
                        {"label": "Nousty", "value": "C31733"},
                        {"label": "Nueil-les-Aubiers", "value": "C31734"},
                        {"label": "Objat", "value": "C31735"},
                        {"label": "Oeyreluy", "value": "C31736"},
                        {"label": "Ogeu-les-Bains", "value": "C31737"},
                        {"label": "Oloron-Sainte-Marie", "value": "C31738"},
                        {"label": "Ondres", "value": "C31739"},
                        {"label": "Onesse-Laharie", "value": "C31740"},
                        {"label": "Oradour-sur-Glane", "value": "C31741"},
                        {"label": "Oradour-sur-Vayres", "value": "C31742"},
                        {"label": "Orthez", "value": "C31743"},
                        {"label": "Ousse", "value": "C31744"},
                        {"label": "Paillet", "value": "C31745"},
                        {"label": "Pamproux", "value": "C31746"},
                        {"label": "Panazol", "value": "C31747"},
                        {"label": "Parempuyre", "value": "C31748"},
                        {"label": "Parentis-en-Born", "value": "C31749"},
                        {"label": "Parthenay", "value": "C31750"},
                        {"label": "Pau", "value": "C31751"},
                        {"label": "Pauillac", "value": "C31752"},
                        {"label": "Payzac", "value": "C31753"},
                        {"label": "Pellegrue", "value": "C31754"},
                        {"label": "Perignac", "value": "C31755"},
                        {"label": "Perigny", "value": "C31756"},
                        {"label": "Perigueux", "value": "C31757"},
                        {"label": "Pessac", "value": "C31758"},
                        {"label": "Peujard", "value": "C31759"},
                        {"label": "Peyrat-de-Bellac", "value": "C31760"},
                        {"label": "Peyrat-le-Chateau", "value": "C31761"},
                        {"label": "Peyrehorade", "value": "C31762"},
                        {"label": "Peyrilhac", "value": "C31763"},
                        {"label": "Piegut-Pluviers", "value": "C31764"},
                        {"label": "Pierre-Buffiere", "value": "C31765"},
                        {"label": "Pissos", "value": "C31766"},
                        {"label": "Pleumartin", "value": "C31767"},
                        {"label": "Podensac", "value": "C31768"},
                        {"label": "Poey-de-Lescar", "value": "C31769"},
                        {"label": "Poitiers", "value": "C31770"},
                        {"label": "Pomarez", "value": "C31771"},
                        {"label": "Pompaire", "value": "C31772"},
                        {"label": "Pompignac", "value": "C31773"},
                        {"label": "Pons", "value": "C31774"},
                        {"label": "Pont-du-Casse", "value": "C31775"},
                        {"label": "Pont-lAbbe-dArnoult", "value": "C31776"},
                        {"label": "Pontacq", "value": "C31777"},
                        {"label": "Pontenx-les-Forges", "value": "C31778"},
                        {"label": "Pontonx-sur-lAdour", "value": "C31779"},
                        {"label": "Port-des-Barques", "value": "C31780"},
                        {"label": "Port-Sainte-Foy-et-Ponchapt",
                         "value": "C31781"},
                        {"label": "Port-Sainte-Marie", "value": "C31782"},
                        {"label": "Portets", "value": "C31783"},
                        {"label": "Pouillon", "value": "C31784"},
                        {"label": "Prahecq", "value": "C31785"},
                        {"label": "Prechac", "value": "C31786"},
                        {"label": "Preignac", "value": "C31787"},
                        {"label": "Prigonrieux", "value": "C31788"},
                        {"label": "Pugnac", "value": "C31789"},
                        {"label": "Puilboreau", "value": "C31790"},
                        {"label": "Pujols", "value": "C31791"},
                        {"label": "Puymoyen", "value": "C31792"},
                        {"label": "Puyoo", "value": "C31793"},
                        {"label": "Quinsac", "value": "C31794"},
                        {"label": "Rauzan", "value": "C31795"},
                        {"label": "Razac-sur-lIsle", "value": "C31796"},
                        {"label": "Razes", "value": "C31797"},
                        {"label": "Reignac", "value": "C31798"},
                        {"label": "Riberac", "value": "C31799"},
                        {"label": "Rilhac-Rancon", "value": "C31800"},
                        {"label": "Rion-des-Landes", "value": "C31801"},
                        {"label": "Rions", "value": "C31802"},
                        {"label": "Rivedoux-Plage", "value": "C31803"},
                        {"label": "Riviere-Saas-et-Gourby", "value": "C31804"},
                        {"label": "Rivieres", "value": "C31805"},
                        {"label": "Rochechouart", "value": "C31806"},
                        {"label": "Rochefort", "value": "C31807"},
                        {"label": "Roches-Premarie-Andille", "value": "C31808"},
                        {"label": "Roquefort", "value": "C31809"},
                        {"label": "Rouffignac-Saint-Cernin-de-Reilhac",
                         "value": "C31810"},
                        {"label": "Rouillac", "value": "C31811"},
                        {"label": "Rouille", "value": "C31812"},
                        {"label": "Roullet-Saint-Estephe", "value": "C31813"},
                        {"label": "Roumazieres-Loubert", "value": "C31814"},
                        {"label": "Royan", "value": "C31815"},
                        {"label": "Ruelle-sur-Touvre", "value": "C31816"},
                        {"label": "Ruffec", "value": "C31817"},
                        {"label": "Sablonceaux", "value": "C31818"},
                        {"label": "Sablons", "value": "C31819"},
                        {"label": "Sabres", "value": "C31820"},
                        {"label": "Sadirac", "value": "C31821"},
                        {"label": "Saint-Agnant", "value": "C31822"},
                        {"label": "Saint-Agnant-de-Versillat",
                         "value": "C31823"},
                        {"label": "Saint-Aigulin", "value": "C31824"},
                        {"label": "Saint-Amand-sur-Sevre", "value": "C31825"},
                        {"label": "Saint-Amant-de-Boixe", "value": "C31826"},
                        {"label": "Saint-Andre-de-Seignanx", "value": "C31827"},
                        {"label": "Saint-Antoine-de-Breuilh",
                         "value": "C31828"},
                        {"label": "Saint-Astier", "value": "C31829"},
                        {"label": "Saint-Aubin-de-Medoc", "value": "C31830"},
                        {"label": "Saint-Aubin-le-Cloud", "value": "C31831"},
                        {"label": "Saint-Aulaye", "value": "C31832"},
                        {"label": "Saint-Benoit", "value": "C31833"},
                        {"label": "Saint-Brice", "value": "C31834"},
                        {"label": "Saint-Brice-sur-Vienne", "value": "C31835"},
                        {"label": "Saint-Caprais-de-Bordeaux",
                         "value": "C31836"},
                        {"label": "Saint-Christoly-de-Blaye",
                         "value": "C31837"},
                        {"label": "Saint-Ciers-dAbzac", "value": "C31838"},
                        {"label": "Saint-Ciers-sur-Gironde", "value": "C31839"},
                        {"label": "Saint-Claud", "value": "C31840"},
                        {"label": "Saint-Clement", "value": "C31841"},
                        {"label": "Saint-Cyprien", "value": "C31842"},
                        {"label": "Saint-Denis-dOleron", "value": "C31843"},
                        {"label": "Saint-Denis-de-Pile", "value": "C31844"},
                        {"label": "Saint-emilion", "value": "C31845"},
                        {"label": "Saint-Estephe", "value": "C31846"},
                        {"label": "Saint-etienne-de-Baigorry",
                         "value": "C31847"},
                        {"label": "Saint-Gelais", "value": "C31848"},
                        {"label": "Saint-Gence", "value": "C31849"},
                        {"label": "Saint-Genis-de-Saintonge",
                         "value": "C31850"},
                        {"label": "Saint-Georges-de-Didonne",
                         "value": "C31851"},
                        {"label": "Saint-Georges-des-Coteaux",
                         "value": "C31852"},
                        {"label": "Saint-Georges-du-Bois", "value": "C31853"},
                        {"label": "Saint-Georges-les-Baillargeaux",
                         "value": "C31854"},
                        {"label": "Saint-Geours-de-Maremne", "value": "C31855"},
                        {"label": "Saint-Germain-de-Lusignan",
                         "value": "C31856"},
                        {"label": "Saint-Germain-de-Marencennes",
                         "value": "C31857"},
                        {"label": "Saint-Germain-du-Puch", "value": "C31858"},
                        {"label": "Saint-Germain-les-Belles",
                         "value": "C31859"},
                        {"label": "Saint-Gervais", "value": "C31860"},
                        {"label": "Saint-Gervais-les-Trois-Clochers",
                         "value": "C31861"},
                        {"label": "Saint-Hilaire", "value": "C31862"},
                        {"label": "Saint-Hilaire-de-Villefranche",
                         "value": "C31863"},
                        {"label": "Saint-Hilaire-la-Palud", "value": "C31864"},
                        {"label": "Saint-Hippolyte", "value": "C31865"},
                        {"label": "Saint-Jean-dIllac", "value": "C31866"},
                        {"label": "Saint-Jean-de-Liversay", "value": "C31867"},
                        {"label": "Saint-Jean-de-Luz", "value": "C31868"},
                        {"label": "Saint-Jean-de-Sauves", "value": "C31869"},
                        {"label": "Saint-Jean-de-Thouars", "value": "C31870"},
                        {"label": "Saint-Jean-Pied-de-Port", "value": "C31871"},
                        {"label": "Saint-Jouvent", "value": "C31872"},
                        {"label": "Saint-Julien-en-Born", "value": "C31873"},
                        {"label": "Saint-Junien", "value": "C31874"},
                        {"label": "Saint-Just-le-Martel", "value": "C31875"},
                        {"label": "Saint-Just-Luzac", "value": "C31876"},
                        {"label": "Saint-Laurent-de-la-Pree",
                         "value": "C31877"},
                        {"label": "Saint-Laurent-sur-Gorre", "value": "C31878"},
                        {"label": "Saint-Leger-de-Montbrun", "value": "C31879"},
                        {"label": "Saint-Leon-sur-lIsle", "value": "C31880"},
                        {"label": "Saint-Leonard-de-Noblat", "value": "C31881"},
                        {"label": "Saint-Loubes", "value": "C31882"},
                        {"label": "Saint-Louis-de-Montferrand",
                         "value": "C31883"},
                        {"label": "Saint-Macaire", "value": "C31884"},
                        {"label": "Saint-Maixant", "value": "C31885"},
                        {"label": "Saint-Mariens", "value": "C31886"},
                        {"label": "Saint-Martin-de-Hinx", "value": "C31887"},
                        {"label": "Saint-Martin-de-Re", "value": "C31888"},
                        {"label": "Saint-Martin-de-Seignanx",
                         "value": "C31889"},
                        {"label": "Saint-Martin-Lacaussade", "value": "C31890"},
                        {"label": "Saint-Mathieu", "value": "C31891"},
                        {"label": "Saint-Maurice-la-Clouere",
                         "value": "C31892"},
                        {"label": "Saint-Maurice-la-Souterraine",
                         "value": "C31893"},
                        {"label": "Saint-Maxire", "value": "C31894"},
                        {"label": "Saint-Medard-dEyrans", "value": "C31895"},
                        {"label": "Saint-Medard-de-Guizieres",
                         "value": "C31896"},
                        {"label": "Saint-Medard-de-Mussidan",
                         "value": "C31897"},
                        {"label": "Saint-Medard-en-Jalles", "value": "C31898"},
                        {"label": "Saint-Meme-les-Carrieres",
                         "value": "C31899"},
                        {"label": "Saint-Mexant", "value": "C31900"},
                        {"label": "Saint-Michel", "value": "C31901"},
                        {"label": "Saint-Morillon", "value": "C31902"},
                        {"label": "Saint-Palais", "value": "C31903"},
                        {"label": "Saint-Palais-sur-Mer", "value": "C31904"},
                        {"label": "Saint-Pantaleon-de-Larche",
                         "value": "C31905"},
                        {"label": "Saint-Pardoux", "value": "C31906"},
                        {"label": "Saint-Pardoux-Isaac", "value": "C31907"},
                        {"label": "Saint-Pardoux-la-Riviere",
                         "value": "C31908"},
                        {"label": "Saint-Paul", "value": "C31909"},
                        {"label": "Saint-Paul-les-Dax", "value": "C31910"},
                        {"label": "Saint-Pee-sur-Nivelle", "value": "C31911"},
                        {"label": "Saint-Perdon", "value": "C31912"},
                        {"label": "Saint-Pierre-dAurillac", "value": "C31913"},
                        {"label": "Saint-Pierre-dOleron", "value": "C31914"},
                        {"label": "Saint-Pierre-des-echaubrognes",
                         "value": "C31915"},
                        {"label": "Saint-Pierre-du-Mont", "value": "C31916"},
                        {"label": "Saint-Porchaire", "value": "C31917"},
                        {"label": "Saint-Priest-sous-Aixe", "value": "C31918"},
                        {"label": "Saint-Priest-Taurion", "value": "C31919"},
                        {"label": "Saint-Privat", "value": "C31920"},
                        {"label": "Saint-Projet-Saint-Constant",
                         "value": "C31921"},
                        {"label": "Saint-Quentin-de-Baron", "value": "C31922"},
                        {"label": "Saint-Rogatien", "value": "C31923"},
                        {"label": "Saint-Romain-de-Benet", "value": "C31924"},
                        {"label": "Saint-Saturnin", "value": "C31925"},
                        {"label": "Saint-Sauvant", "value": "C31926"},
                        {"label": "Saint-Sauveur", "value": "C31927"},
                        {"label": "Saint-Sauveur-dAunis", "value": "C31928"},
                        {"label": "Saint-Savin", "value": "C31929"},
                        {"label": "Saint-Savinien", "value": "C31930"},
                        {"label": "Saint-Selve", "value": "C31931"},
                        {"label": "Saint-Seurin-sur-lIsle", "value": "C31932"},
                        {"label": "Saint-Sever", "value": "C31933"},
                        {"label": "Saint-Sulpice-de-Cognac", "value": "C31934"},
                        {"label": "Saint-Sulpice-de-Faleyrens",
                         "value": "C31935"},
                        {"label": "Saint-Sulpice-de-Royan", "value": "C31936"},
                        {"label": "Saint-Sulpice-et-Cameyrac",
                         "value": "C31937"},
                        {"label": "Saint-Sulpice-le-Gueretois",
                         "value": "C31938"},
                        {"label": "Saint-Sulpice-les-Feuilles",
                         "value": "C31939"},
                        {"label": "Saint-Sylvestre-sur-Lot", "value": "C31940"},
                        {"label": "Saint-Symphorien", "value": "C31941"},
                        {"label": "Saint-Trojan-les-Bains", "value": "C31942"},
                        {"label": "Saint-Varent", "value": "C31943"},
                        {"label": "Saint-Vaury", "value": "C31944"},
                        {"label": "Saint-Viance", "value": "C31945"},
                        {"label": "Saint-Victurnien", "value": "C31946"},
                        {"label": "Saint-Vincent-de-Paul", "value": "C31947"},
                        {"label": "Saint-Vincent-de-Tyrosse",
                         "value": "C31948"},
                        {"label": "Saint-Vite", "value": "C31949"},
                        {"label": "Saint-Vivien-de-Medoc", "value": "C31950"},
                        {"label": "Saint-Xandre", "value": "C31951"},
                        {"label": "Saint-Yrieix-la-Perche", "value": "C31952"},
                        {"label": "Saint-Yrieix-sur-Charente",
                         "value": "C31953"},
                        {"label": "Saint-Yzan-de-Soudiac", "value": "C31954"},
                        {"label": "Sainte-Bazeille", "value": "C31955"},
                        {"label": "Sainte-Colombe-en-Bruilhois",
                         "value": "C31956"},
                        {"label": "Sainte-Eulalie", "value": "C31957"},
                        {"label": "Sainte-Fereole", "value": "C31958"},
                        {"label": "Sainte-Feyre", "value": "C31959"},
                        {"label": "Sainte-Fortunade", "value": "C31960"},
                        {"label": "Sainte-Foy-la-Grande", "value": "C31961"},
                        {"label": "Sainte-Helene", "value": "C31962"},
                        {"label": "Sainte-Livrade-sur-Lot", "value": "C31963"},
                        {"label": "Sainte-Marie-de-Gosse", "value": "C31964"},
                        {"label": "Sainte-Marie-de-Re", "value": "C31965"},
                        {"label": "Sainte-Radegonde", "value": "C31966"},
                        {"label": "Sainte-Soulle", "value": "C31967"},
                        {"label": "Sainte-Terre", "value": "C31968"},
                        {"label": "Sainte-Verge", "value": "C31969"},
                        {"label": "Saintes", "value": "C31970"},
                        {"label": "Saivres", "value": "C31971"},
                        {"label": "Salies-de-Bearn", "value": "C31972"},
                        {"label": "Salignac", "value": "C31973"},
                        {"label": "Salignac-Eyvigues", "value": "C31974"},
                        {"label": "Salleboeuf", "value": "C31975"},
                        {"label": "Salles", "value": "C31976"},
                        {"label": "Salles-sur-Mer", "value": "C31977"},
                        {"label": "Samadet", "value": "C31978"},
                        {"label": "Sanguinet", "value": "C31979"},
                        {"label": "Sarbazan", "value": "C31980"},
                        {"label": "Sare", "value": "C31981"},
                        {"label": "Sarlat-la-Caneda", "value": "C31982"},
                        {"label": "Saubion", "value": "C31983"},
                        {"label": "Saubrigues", "value": "C31984"},
                        {"label": "Saucats", "value": "C31985"},
                        {"label": "Saugnac-et-Cambran", "value": "C31986"},
                        {"label": "Saujon", "value": "C31987"},
                        {"label": "Saulge", "value": "C31988"},
                        {"label": "Sauvagnon", "value": "C31989"},
                        {"label": "Sauveterre-de-Bearn", "value": "C31990"},
                        {"label": "Sauveterre-de-Guyenne", "value": "C31991"},
                        {"label": "Sauviat-sur-Vige", "value": "C31992"},
                        {"label": "Sauze-Vaussais", "value": "C31993"},
                        {"label": "Savigne", "value": "C31994"},
                        {"label": "Savigny-Levescault", "value": "C31995"},
                        {"label": "Scorbe-Clairvaux", "value": "C31996"},
                        {"label": "Secondigny", "value": "C31997"},
                        {"label": "Segonzac", "value": "C31998"},
                        {"label": "Seignosse", "value": "C31999"},
                        {"label": "Seilhac", "value": "C32000"},
                        {"label": "Semussac", "value": "C32001"},
                        {"label": "Sereilhac", "value": "C32002"},
                        {"label": "Serres-Castet", "value": "C32003"},
                        {"label": "Sevres-Anxaumont", "value": "C32004"},
                        {"label": "Sireuil", "value": "C32005"},
                        {"label": "Smarves", "value": "C32006"},
                        {"label": "Solignac", "value": "C32007"},
                        {"label": "Soorts-Hossegor", "value": "C32008"},
                        {"label": "Sorges", "value": "C32009"},
                        {"label": "Soubise", "value": "C32010"},
                        {"label": "Soulac-sur-Mer", "value": "C32011"},
                        {"label": "Soumoulou", "value": "C32012"},
                        {"label": "Souprosse", "value": "C32013"},
                        {"label": "Souraide", "value": "C32014"},
                        {"label": "Sourzac", "value": "C32015"},
                        {"label": "Soussans", "value": "C32016"},
                        {"label": "Soustons", "value": "C32017"},
                        {"label": "Soyaux", "value": "C32018"},
                        {"label": "Surgeres", "value": "C32019"},
                        {"label": "Tabanac", "value": "C32020"},
                        {"label": "Talence", "value": "C32021"},
                        {"label": "Taponnat-Fleurignac", "value": "C32022"},
                        {"label": "Targon", "value": "C32023"},
                        {"label": "Tarnos", "value": "C32024"},
                        {"label": "Tartas", "value": "C32025"},
                        {"label": "Tauriac", "value": "C32026"},
                        {"label": "Terce", "value": "C32027"},
                        {"label": "Tercis-les-Bains", "value": "C32028"},
                        {"label": "Terrasson-Lavilledieu", "value": "C32029"},
                        {"label": "Thaire", "value": "C32030"},
                        {"label": "Thenac", "value": "C32031"},
                        {"label": "Thenezay", "value": "C32032"},
                        {"label": "Thenon", "value": "C32033"},
                        {"label": "Thiviers", "value": "C32034"},
                        {"label": "Thorigne", "value": "C32035"},
                        {"label": "Thouars", "value": "C32036"},
                        {"label": "Thure", "value": "C32037"},
                        {"label": "Tocane-Saint-Apre", "value": "C32038"},
                        {"label": "Tonnay-Boutonne", "value": "C32039"},
                        {"label": "Tonnay-Charente", "value": "C32040"},
                        {"label": "Tonneins", "value": "C32041"},
                        {"label": "Tosse", "value": "C32042"},
                        {"label": "Toulenne", "value": "C32043"},
                        {"label": "Touvre", "value": "C32044"},
                        {"label": "Treignac", "value": "C32045"},
                        {"label": "Trelissac", "value": "C32046"},
                        {"label": "Tresses", "value": "C32047"},
                        {"label": "Trizay", "value": "C32048"},
                        {"label": "Tulle", "value": "C32049"},
                        {"label": "Urcuit", "value": "C32050"},
                        {"label": "Urrugne", "value": "C32051"},
                        {"label": "Urt", "value": "C32052"},
                        {"label": "Ussac", "value": "C32053"},
                        {"label": "Ussel", "value": "C32054"},
                        {"label": "Usson-du-Poitou", "value": "C32055"},
                        {"label": "Ustaritz", "value": "C32056"},
                        {"label": "Uzein", "value": "C32057"},
                        {"label": "Uzerche", "value": "C32058"},
                        {"label": "Varetz", "value": "C32059"},
                        {"label": "Vars", "value": "C32060"},
                        {"label": "Vasles", "value": "C32061"},
                        {"label": "Vaux-sur-Mer", "value": "C32062"},
                        {"label": "Vayres", "value": "C32063"},
                        {"label": "Velines", "value": "C32064"},
                        {"label": "Vendays-Montalivet", "value": "C32065"},
                        {"label": "Vendeuvre-du-Poitou", "value": "C32066"},
                        {"label": "Vergt", "value": "C32067"},
                        {"label": "Verines", "value": "C32068"},
                        {"label": "Verneuil-sur-Vienne", "value": "C32069"},
                        {"label": "Vertheuil", "value": "C32070"},
                        {"label": "Veyrac", "value": "C32071"},
                        {"label": "Vianne", "value": "C32072"},
                        {"label": "Vicq-sur-Breuilh", "value": "C32073"},
                        {"label": "Vielle-Saint-Girons", "value": "C32074"},
                        {"label": "Viennay", "value": "C32075"},
                        {"label": "Vienne", "value": "C32076"},
                        {"label": "Vieux-Boucau-les-Bains", "value": "C32077"},
                        {"label": "Vigeois", "value": "C32078"},
                        {"label": "Villefagnan", "value": "C32079"},
                        {"label": "Villefranque", "value": "C32080"},
                        {"label": "Villegouge", "value": "C32081"},
                        {"label": "Villenave-dOrnon", "value": "C32082"},
                        {"label": "Villeneuve-de-Marsan", "value": "C32083"},
                        {"label": "Villeneuve-sur-Lot", "value": "C32084"},
                        {"label": "Villereal", "value": "C32085"},
                        {"label": "Villiers-en-Plaine", "value": "C32086"},
                        {"label": "Virazeil", "value": "C32087"},
                        {"label": "Vivonne", "value": "C32088"},
                        {"label": "Voeuil-et-Giget", "value": "C32089"},
                        {"label": "Vouille", "value": "C32090"},
                        {"label": "Vouneuil-sous-Biard", "value": "C32091"},
                        {"label": "Vouneuil-sur-Vienne", "value": "C32092"},
                        {"label": "Voutezac", "value": "C32093"},
                        {"label": "Ychoux", "value": "C32094"},
                        {"label": "Ygos-Saint-Saturnin", "value": "C32095"},
                        {"label": "Yves", "value": "C32096"},
                        {"label": "Yvrac", "value": "C32097"}],
 "Nova Gorica City Municipality": [{"label": "Kromberk", "value": "C107747"},
                                   {"label": "Nova Gorica", "value": "C107748"},
                                   {"label": "Prvacina", "value": "C107749"},
                                   {"label": "Sempas", "value": "C107750"},
                                   {"label": "Solkan", "value": "C107751"}],
 "Nova Scotia": [{"label": "Amherst", "value": "C17077"},
                 {"label": "Annapolis County", "value": "C17078"},
                 {"label": "Antigonish", "value": "C17079"},
                 {"label": "Berwick", "value": "C17080"},
                 {"label": "Bridgewater", "value": "C17081"},
                 {"label": "Cape Breton County", "value": "C17082"},
                 {"label": "Chester", "value": "C17083"},
                 {"label": "Colchester", "value": "C17084"},
                 {"label": "Cole Harbour", "value": "C17085"},
                 {"label": "Cow Bay", "value": "C17086"},
                 {"label": "Dartmouth", "value": "C17087"},
                 {"label": "Digby", "value": "C17088"},
                 {"label": "Digby County", "value": "C17089"},
                 {"label": "English Corner", "value": "C17090"},
                 {"label": "Eskasoni 3", "value": "C17091"},
                 {"label": "Fall River", "value": "C17092"},
                 {"label": "Glace Bay", "value": "C17093"},
                 {"label": "Greenwood", "value": "C17094"},
                 {"label": "Halifax", "value": "C17095"},
                 {"label": "Hantsport", "value": "C17096"},
                 {"label": "Hayes Subdivision", "value": "C17097"},
                 {"label": "Kentville", "value": "C17098"},
                 {"label": "Lake Echo", "value": "C17099"},
                 {"label": "Lantz", "value": "C17100"},
                 {"label": "Lower Sackville", "value": "C17101"},
                 {"label": "Lunenburg", "value": "C17102"},
                 {"label": "Middleton", "value": "C17103"},
                 {"label": "New Glasgow", "value": "C17104"},
                 {"label": "Oxford", "value": "C17105"},
                 {"label": "Parrsboro", "value": "C17106"},
                 {"label": "Pictou", "value": "C17107"},
                 {"label": "Pictou County", "value": "C17108"},
                 {"label": "Port Hawkesbury", "value": "C17109"},
                 {"label": "Port Williams", "value": "C17110"},
                 {"label": "Princeville", "value": "C17111"},
                 {"label": "Shelburne", "value": "C17112"},
                 {"label": "Springhill", "value": "C17113"},
                 {"label": "Sydney", "value": "C17114"},
                 {"label": "Sydney Mines", "value": "C17115"},
                 {"label": "Truro", "value": "C17116"},
                 {"label": "Windsor", "value": "C17117"},
                 {"label": "Wolfville", "value": "C17118"},
                 {"label": "Yarmouth", "value": "C17119"}],
 "Novaci Municipality": [{"label": "Novaci", "value": "C65467"}],
 "Novgorod Oblast": [{"label": "Batetskiy", "value": "C102792"},
                     {"label": "Batetskiy Rayon", "value": "C102793"},
                     {"label": "Borovichi", "value": "C102794"},
                     {"label": "Borovichskiy Rayon", "value": "C102795"},
                     {"label": "Chudovo", "value": "C102796"},
                     {"label": "Chudovskiy Rayon", "value": "C102797"},
                     {"label": "Demyansk", "value": "C102798"},
                     {"label": "Demyanskiy Rayon", "value": "C102799"},
                     {"label": "Kholm", "value": "C102800"},
                     {"label": "Kholmskiy Rayon", "value": "C102801"},
                     {"label": "Khvoynaya", "value": "C102802"},
                     {"label": "Khvoyninskiy Rayon", "value": "C102803"},
                     {"label": "Krasnofarfornyy", "value": "C102804"},
                     {"label": "Krechevitsy", "value": "C102805"},
                     {"label": "Krestetskiy Rayon", "value": "C102806"},
                     {"label": "Kresttsy", "value": "C102807"},
                     {"label": "Kulotino", "value": "C102808"},
                     {"label": "Lyubytino", "value": "C102809"},
                     {"label": "Lyubytinskiy Rayon", "value": "C102810"},
                     {"label": "Malaya Vishera", "value": "C102811"},
                     {"label": "Malovisherskiy Rayon", "value": "C102812"},
                     {"label": "Marevo", "value": "C102813"},
                     {"label": "Marevskiy Rayon", "value": "C102814"},
                     {"label": "Moshenskoy Rayon", "value": "C102815"},
                     {"label": "Moshenskoye", "value": "C102816"},
                     {"label": "Nebolchi", "value": "C102817"},
                     {"label": "Novgorodskiy Rayon", "value": "C102818"},
                     {"label": "Okulovka", "value": "C102819"},
                     {"label": "Okulovskiy Rayon", "value": "C102820"},
                     {"label": "Opechenskiy Posad", "value": "C102821"},
                     {"label": "Pankovka", "value": "C102822"},
                     {"label": "Parfino", "value": "C102823"},
                     {"label": "Parfinskiy Rayon", "value": "C102824"},
                     {"label": "Pestovo", "value": "C102825"},
                     {"label": "Pestovskiy Rayon", "value": "C102826"},
                     {"label": "Poddorye", "value": "C102827"},
                     {"label": "Poddorskiy Rayon", "value": "C102828"},
                     {"label": "Proletariy", "value": "C102829"},
                     {"label": "Shimsk", "value": "C102830"},
                     {"label": "Shimskiy Rayon", "value": "C102831"},
                     {"label": "Soltsy", "value": "C102832"},
                     {"label": "Soletskiy Rayon", "value": "C102833"},
                     {"label": "Staraya Russa", "value": "C102834"},
                     {"label": "Starorusskiy Rayon", "value": "C102835"},
                     {"label": "Uglovka", "value": "C102836"},
                     {"label": "Utorgosh", "value": "C102837"},
                     {"label": "Valday", "value": "C102838"},
                     {"label": "Valdayskiy Rayon", "value": "C102839"},
                     {"label": "Velikiy Novgorod", "value": "C102840"},
                     {"label": "Volkhovskiy", "value": "C102841"},
                     {"label": "Volot", "value": "C102842"},
                     {"label": "Volotovskiy Rayon", "value": "C102843"},
                     {"label": "Yedrovo", "value": "C102844"},
                     {"label": "Zarubino", "value": "C102845"}],
 "Novo Selo Municipality": [{"label": "Novo Selo", "value": "C65468"},
                            {"label": "Susica", "value": "C65469"}],
 "Novosibirsk": [{"label": "Akademgorodok", "value": "C102846"},
                 {"label": "Bagan", "value": "C102847"},
                 {"label": "Barabinsk", "value": "C102848"},
                 {"label": "Baryshevo", "value": "C102849"},
                 {"label": "Berdsk", "value": "C102850"},
                 {"label": "Bolotnoye", "value": "C102851"},
                 {"label": "Chany", "value": "C102852"},
                 {"label": "Cherepanovo", "value": "C102853"},
                 {"label": "Chulym", "value": "C102854"},
                 {"label": "Dorogino", "value": "C102855"},
                 {"label": "Dubrovino", "value": "C102856"},
                 {"label": "Gornyy", "value": "C102857"},
                 {"label": "Iskitim", "value": "C102858"},
                 {"label": "Iskitimskiy Rayon", "value": "C102859"},
                 {"label": "Karasuk", "value": "C102860"},
                 {"label": "Kargat", "value": "C102861"},
                 {"label": "Kochenevo", "value": "C102862"},
                 {"label": "Kochki", "value": "C102863"},
                 {"label": "Koltsovo", "value": "C102864"},
                 {"label": "Kolyvan", "value": "C102865"},
                 {"label": "Krasnoobsk", "value": "C102866"},
                 {"label": "Krasnozerskoye", "value": "C102867"},
                 {"label": "Krivodanovka", "value": "C102868"},
                 {"label": "Kudryashovskiy", "value": "C102869"},
                 {"label": "Kupino", "value": "C102870"},
                 {"label": "Kuybyshev", "value": "C102871"},
                 {"label": "Kyshtovka", "value": "C102872"},
                 {"label": "Linevo", "value": "C102873"},
                 {"label": "Listvyanskiy", "value": "C102874"},
                 {"label": "Maslyanino", "value": "C102875"},
                 {"label": "Maslyaninskiy Rayon", "value": "C102876"},
                 {"label": "Mochishche", "value": "C102877"},
                 {"label": "Moshkovo", "value": "C102878"},
                 {"label": "Moshkovskiy Rayon", "value": "C102879"},
                 {"label": "Novolugovoye", "value": "C102880"},
                 {"label": "Novosibirsk", "value": "C102881"},
                 {"label": "Novosibirskiy Rayon", "value": "C102882"},
                 {"label": "Ob", "value": "C102883"},
                 {"label": "Ordynskiy Rayon", "value": "C102884"},
                 {"label": "Ordynskoye", "value": "C102885"},
                 {"label": "Polovinnoye", "value": "C102886"},
                 {"label": "Prokudskoye", "value": "C102887"},
                 {"label": "Sadovyy", "value": "C102888"},
                 {"label": "Severnoye", "value": "C102889"},
                 {"label": "Severnyy Rayon", "value": "C102890"},
                 {"label": "Sokur", "value": "C102891"},
                 {"label": "Stantsionno-Oyashinskiy", "value": "C102892"},
                 {"label": "Suzun", "value": "C102893"},
                 {"label": "Tashara", "value": "C102894"},
                 {"label": "Tatarsk", "value": "C102895"},
                 {"label": "Tatarskiy Rayon", "value": "C102896"},
                 {"label": "Toguchin", "value": "C102897"},
                 {"label": "Tolmachevo", "value": "C102898"},
                 {"label": "Ubinskoye", "value": "C102899"},
                 {"label": "Ust-Tarka", "value": "C102900"},
                 {"label": "Vengerovo", "value": "C102901"},
                 {"label": "Verkh-Tula", "value": "C102902"},
                 {"label": "Yarkovo", "value": "C102903"},
                 {"label": "Zdvinsk", "value": "C102904"}],
 "Nueva Esparta": [{"label": "Juan Griego", "value": "C147221"},
                   {"label": "La Asuncion", "value": "C147222"},
                   {"label": "Porlamar", "value": "C147223"}],
 "Nueva Segovia": [{"label": "Ciudad Antigua", "value": "C77995"},
                   {"label": "Dipilto", "value": "C77996"},
                   {"label": "El Jicaro", "value": "C77997"},
                   {"label": "Jalapa", "value": "C77998"},
                   {"label": "Macuelizo", "value": "C77999"},
                   {"label": "Mozonte", "value": "C78000"},
                   {"label": "Murra", "value": "C78001"},
                   {"label": "Ocotal", "value": "C78002"},
                   {"label": "Quilali", "value": "C78003"},
                   {"label": "San Fernando", "value": "C78004"},
                   {"label": "Santa Maria", "value": "C78005"},
                   {"label": "Wiwili", "value": "C78006"}],
 "Nuevo Leon": [{"label": "Agualeguas", "value": "C71259"},
                {"label": "Agualeguas, Nuevo Leon", "value": "C71260"},
                {"label": "Alberto Villarreal", "value": "C71261"},
                {"label": "Aldama Estacion", "value": "C71262"},
                {"label": "Alfonso Reyes", "value": "C71263"},
                {"label": "Alianza Real", "value": "C71264"},
                {"label": "Allende", "value": "C71265"},
                {"label": "Altamira", "value": "C71266"},
                {"label": "Ampliacion de la Laguna", "value": "C71267"},
                {"label": "Anahuac", "value": "C71268"},
                {"label": "Apodaca", "value": "C71269"},
                {"label": "Aquiles Serdan", "value": "C71270"},
                {"label": "Aramberri", "value": "C71271"},
                {"label": "Armando Fernandez Garza", "value": "C71272"},
                {"label": "Artemio Trevino", "value": "C71273"},
                {"label": "Barrio Aldamas", "value": "C71274"},
                {"label": "Barrio de Jalisco", "value": "C71275"},
                {"label": "Bello Amanecer", "value": "C71276"},
                {"label": "Bosques de San Pedro", "value": "C71277"},
                {"label": "Buena Vista", "value": "C71278"},
                {"label": "Bustamante", "value": "C71279"},
                {"label": "Cadereyta", "value": "C71280"},
                {"label": "Cadereyta Jimenez", "value": "C71281"},
                {"label": "Caja Pinta", "value": "C71282"},
                {"label": "Campestre el Montesito", "value": "C71283"},
                {"label": "Canon de Vacas", "value": "C71284"},
                {"label": "Cantu", "value": "C71285"},
                {"label": "Carboneras", "value": "C71286"},
                {"label": "Carmen", "value": "C71287"},
                {"label": "Casa Rosa", "value": "C71288"},
                {"label": "Catarino Rodriguez", "value": "C71289"},
                {"label": "Centro Habitacional Burocratas de Guadalupe",
                 "value": "C71290"},
                {"label": "Cerralvo", "value": "C71291"},
                {"label": "Charco Redondo", "value": "C71292"},
                {"label": "China", "value": "C71293"},
                {"label": "Cienega de Flores", "value": "C71294"},
                {"label": "Cinco Senores", "value": "C71295"},
                {"label": "Ciudad Anahuac", "value": "C71296"},
                {"label": "Ciudad Apodaca", "value": "C71297"},
                {"label": "Ciudad Benito Juarez", "value": "C71298"},
                {"label": "Ciudad Cerralvo", "value": "C71299"},
                {"label": "Ciudad de Allende", "value": "C71300"},
                {"label": "Ciudad de Villaldama", "value": "C71301"},
                {"label": "Ciudad General Escobedo", "value": "C71302"},
                {"label": "Ciudad General Teran", "value": "C71303"},
                {"label": "Ciudad Sabinas Hidalgo", "value": "C71304"},
                {"label": "Ciudad Satelite del Norte", "value": "C71305"},
                {"label": "Colinas del Aeropuerto", "value": "C71306"},
                {"label": "Colinas del Rio, Juarez", "value": "C71307"},
                {"label": "Colombia", "value": "C71308"},
                {"label": "Colonia Independencia (Monterrey)",
                 "value": "C71309"},
                {"label": "Colonia la Mesa", "value": "C71310"},
                {"label": "Conferin Arizpe", "value": "C71311"},
                {"label": "Congregacion Calles", "value": "C71312"},
                {"label": "Constitucion", "value": "C71313"},
                {"label": "Dieciocho de Marzo", "value": "C71314"},
                {"label": "Dieciseis de Septiembre", "value": "C71315"},
                {"label": "Doce de Octubre", "value": "C71316"},
                {"label": "Doctor Arroyo", "value": "C71317"},
                {"label": "Doctor Coss", "value": "C71318"},
                {"label": "Doctor Coss, Nuevo Leon", "value": "C71319"},
                {"label": "Doctor Gonzalez", "value": "C71320"},
                {"label": "Dolores", "value": "C71321"},
                {"label": "El Bajio", "value": "C71322"},
                {"label": "El Barco", "value": "C71323"},
                {"label": "El Bosque", "value": "C71324"},
                {"label": "El Brasil", "value": "C71325"},
                {"label": "El Carmen de los Elizondo", "value": "C71326"},
                {"label": "El Carmen, Nuevo Leon", "value": "C71327"},
                {"label": "El Cedral", "value": "C71328"},
                {"label": "El Cerrito", "value": "C71329"},
                {"label": "El Chamizal", "value": "C71330"},
                {"label": "El Dieciseis", "value": "C71331"},
                {"label": "El Diez", "value": "C71332"},
                {"label": "El Fraile", "value": "C71333"},
                {"label": "El Leal", "value": "C71334"},
                {"label": "El Maguey", "value": "C71335"},
                {"label": "El Mosquito, Linares", "value": "C71336"},
                {"label": "El Nogalito", "value": "C71337"},
                {"label": "El Palmital", "value": "C71338"},
                {"label": "El Pastor", "value": "C71339"},
                {"label": "El Ranchito Numero Uno", "value": "C71340"},
                {"label": "El Realito", "value": "C71341"},
                {"label": "El Refugio", "value": "C71342"},
                {"label": "El Refugio y el Reparo", "value": "C71343"},
                {"label": "El Rucio", "value": "C71344"},
                {"label": "El Saladito", "value": "C71345"},
                {"label": "El Salitre", "value": "C71346"},
                {"label": "Emiliano Zapata", "value": "C71347"},
                {"label": "Entronque Laredo-Salinas Victoria",
                 "value": "C71348"},
                {"label": "Estados Unidos", "value": "C71349"},
                {"label": "Fraccionamiento Cosmopolis Octavo Sector",
                 "value": "C71350"},
                {"label": "Fraccionamiento del Valle", "value": "C71351"},
                {"label": "Fraccionamiento Mision de San Javier",
                 "value": "C71352"},
                {"label": "Fraccionamiento Real Palmas", "value": "C71353"},
                {"label": "Francisco I. Madero", "value": "C71354"},
                {"label": "Francisco Villa", "value": "C71355"},
                {"label": "Galeana", "value": "C71356"},
                {"label": "Garcia", "value": "C71357"},
                {"label": "Garza Garcia", "value": "C71358"},
                {"label": "Garza Gonzalez", "value": "C71359"},
                {"label": "General Bravo", "value": "C71360"},
                {"label": "General Escobedo", "value": "C71361"},
                {"label": "General Tapia", "value": "C71362"},
                {"label": "General Teran, Nuevo Leon", "value": "C71363"},
                {"label": "General Trevino", "value": "C71364"},
                {"label": "General Zuazua", "value": "C71365"},
                {"label": "Graciano Bortoni", "value": "C71366"},
                {"label": "Guadalupe", "value": "C71367"},
                {"label": "Hacienda de Enmedio", "value": "C71368"},
                {"label": "Hacienda San Juan", "value": "C71369"},
                {"label": "Hacienda San Pedro", "value": "C71370"},
                {"label": "Hector Caballero", "value": "C71371"},
                {"label": "Hidalgo", "value": "C71372"},
                {"label": "Higueras, Nuevo Leon", "value": "C71373"},
                {"label": "Hualahuises", "value": "C71374"},
                {"label": "Huertas Estacion", "value": "C71375"},
                {"label": "Ibarrilla", "value": "C71376"},
                {"label": "Ismael Flores", "value": "C71377"},
                {"label": "Iturbide", "value": "C71378"},
                {"label": "Iturbide, Nuevo Leon", "value": "C71379"},
                {"label": "Jardines de la Silla", "value": "C71380"},
                {"label": "Jardines de la Silla (Jardines)", "value": "C71381"},
                {"label": "Jauregui", "value": "C71382"},
                {"label": "Jesus Maria Aguirre, Galeana", "value": "C71383"},
                {"label": "Jesus Maria de Berrones", "value": "C71384"},
                {"label": "Jesus Maria del Terrero", "value": "C71385"},
                {"label": "Juan Salazar", "value": "C71386"},
                {"label": "Juarez, Nuevo Leon", "value": "C71387"},
                {"label": "Kilometro Dieciseis", "value": "C71388"},
                {"label": "La Aguita", "value": "C71389"},
                {"label": "La Aldea", "value": "C71390"},
                {"label": "La Arena", "value": "C71391"},
                {"label": "La Ascension", "value": "C71392"},
                {"label": "La Barreta", "value": "C71393"},
                {"label": "La Baticolla", "value": "C71394"},
                {"label": "La Boquilla", "value": "C71395"},
                {"label": "La Campana", "value": "C71396"},
                {"label": "La Candelaria", "value": "C71397"},
                {"label": "La Cardona", "value": "C71398"},
                {"label": "La Chiripa", "value": "C71399"},
                {"label": "La Chueca, Cadereyta Jimenez", "value": "C71400"},
                {"label": "La Colmena de Arriba", "value": "C71401"},
                {"label": "La Escuadra", "value": "C71402"},
                {"label": "La Esperanza", "value": "C71403"},
                {"label": "La Estrella", "value": "C71404"},
                {"label": "La Fama", "value": "C71405"},
                {"label": "La Florida", "value": "C71406"},
                {"label": "La Gloria", "value": "C71407"},
                {"label": "La Haciendita", "value": "C71408"},
                {"label": "La Joya de Alardin", "value": "C71409"},
                {"label": "La Lagunita", "value": "C71410"},
                {"label": "La Lobera de Portillo", "value": "C71411"},
                {"label": "La Maestranza", "value": "C71412"},
                {"label": "La Mesa de Berrones", "value": "C71413"},
                {"label": "La Palmita, Nuevo Leon", "value": "C71414"},
                {"label": "La Presita de Berlanga", "value": "C71415"},
                {"label": "La Providencia", "value": "C71416"},
                {"label": "La Purisima", "value": "C71417"},
                {"label": "La Reforma", "value": "C71418"},
                {"label": "La Soledad", "value": "C71419"},
                {"label": "La Tinaja", "value": "C71420"},
                {"label": "La Trinidad", "value": "C71421"},
                {"label": "La Union y el Cardonal", "value": "C71422"},
                {"label": "La Ventana", "value": "C71423"},
                {"label": "Ladrillera", "value": "C71424"},
                {"label": "Ladrillera (Entronque Pesqueria)",
                 "value": "C71425"},
                {"label": "Lampazos de Naranjo", "value": "C71426"},
                {"label": "Las Barretas", "value": "C71427"},
                {"label": "Las Brisas", "value": "C71428"},
                {"label": "Las Mesas de San Juan", "value": "C71429"},
                {"label": "Las Palomas", "value": "C71430"},
                {"label": "Las Torres", "value": "C71431"},
                {"label": "Las Torres de Guadalupe", "value": "C71432"},
                {"label": "Linares", "value": "C71433"},
                {"label": "Linares, Nuevo Leon", "value": "C71434"},
                {"label": "Loma la Paz", "value": "C71435"},
                {"label": "Lomas de San Francisco", "value": "C71436"},
                {"label": "Lomas de San Martin", "value": "C71437"},
                {"label": "Los alamos", "value": "C71438"},
                {"label": "Los Aldamas", "value": "C71439"},
                {"label": "Los angeles, Nuevo Leon", "value": "C71440"},
                {"label": "Los Dos Rios", "value": "C71441"},
                {"label": "Los Herreras", "value": "C71442"},
                {"label": "Los Parques", "value": "C71443"},
                {"label": "Los Pocitos", "value": "C71444"},
                {"label": "Los Ramones", "value": "C71445"},
                {"label": "Maria Aurora", "value": "C71446"},
                {"label": "Maria Auxiliadora", "value": "C71447"},
                {"label": "Marin", "value": "C71448"},
                {"label": "Mazatlan", "value": "C71449"},
                {"label": "Melchor Ocampo, Nuevo Leon", "value": "C71450"},
                {"label": "Mexiquito", "value": "C71451"},
                {"label": "Mier y Noriega", "value": "C71452"},
                {"label": "Mina", "value": "C71453"},
                {"label": "Mina, Nuevo Leon", "value": "C71454"},
                {"label": "Mision San Pablo", "value": "C71455"},
                {"label": "Mitras Poniente", "value": "C71456"},
                {"label": "Monclova Primer Sector", "value": "C71457"},
                {"label": "Monclova Segundo Sector", "value": "C71458"},
                {"label": "Monte Kristal", "value": "C71459"},
                {"label": "Montemorelos", "value": "C71460"},
                {"label": "Monterrey", "value": "C71461"},
                {"label": "Nanez Gonzalez", "value": "C71462"},
                {"label": "Paras", "value": "C71463"},
                {"label": "Paras, Nuevo Leon", "value": "C71464"},
                {"label": "Parque Industrial Ciudad Mitras", "value": "C71465"},
                {"label": "Paseo de San Javier", "value": "C71466"},
                {"label": "Pedro Ibarra Hernandez", "value": "C71467"},
                {"label": "Pesqueria", "value": "C71468"},
                {"label": "Portal de las Salinas", "value": "C71469"},
                {"label": "Praderas de San Francisco", "value": "C71470"},
                {"label": "Prados de Santa Rosa", "value": "C71471"},
                {"label": "Pueblo Nuevo", "value": "C71472"},
                {"label": "Puerto Genovevo, Santiago", "value": "C71473"},
                {"label": "Rayones", "value": "C71474"},
                {"label": "Real del Sol", "value": "C71475"},
                {"label": "Rio Verde", "value": "C71476"},
                {"label": "Ruben Carlos Gallegos Reyes, Villaldama",
                 "value": "C71477"},
                {"label": "Sabinas Hidalgo", "value": "C71478"},
                {"label": "Salinas Victoria", "value": "C71479"},
                {"label": "San Antonio", "value": "C71480"},
                {"label": "San Antonio de Alamitos", "value": "C71481"},
                {"label": "San Bernardo, Nuevo Leon", "value": "C71482"},
                {"label": "San Jose de Cuatro Caminos", "value": "C71483"},
                {"label": "San Jose de Medina", "value": "C71484"},
                {"label": "San Jose de Raices", "value": "C71485"},
                {"label": "San Jose, Linares", "value": "C71486"},
                {"label": "San Juan", "value": "C71487"},
                {"label": "San Mateo, Doctor Arroyo", "value": "C71488"},
                {"label": "San Nicolas de los Garza", "value": "C71489"},
                {"label": "San Pedro Garza Garcia", "value": "C71490"},
                {"label": "San Rafael", "value": "C71491"},
                {"label": "Santa Catarina", "value": "C71492"},
                {"label": "Santa Rosa", "value": "C71493"},
                {"label": "Santa Teresita", "value": "C71494"},
                {"label": "Santa Veronica", "value": "C71495"},
                {"label": "Santiago", "value": "C71496"},
                {"label": "Simeprodeso (Colectivo Nuevo)", "value": "C71497"},
                {"label": "Union Agropecuarios Lazaro Cardenas del Norte",
                 "value": "C71498"},
                {"label": "Valle Alto", "value": "C71499"},
                {"label": "Valle de Juarez", "value": "C71500"},
                {"label": "Valle de Lincoln", "value": "C71501"},
                {"label": "Valle de Vaquerias", "value": "C71502"},
                {"label": "Vallecillo", "value": "C71503"},
                {"label": "Villaldama", "value": "C71504"},
                {"label": "Villaldama, Nuevo Leon", "value": "C71505"},
                {"label": "Villas de Alcala", "value": "C71506"}],
 "Nugal": [{"label": "Eyl", "value": "C107919"},
           {"label": "Garoowe", "value": "C107920"}],
 "Nui": [{"label": "Tanrake Village", "value": "C121197"}],
 "Nukufetau": [{"label": "Savave Village", "value": "C121198"}],
 "Nunavut": [{"label": "Clyde River", "value": "C17120"},
             {"label": "Gjoa Haven", "value": "C17121"},
             {"label": "Iqaluit", "value": "C17122"},
             {"label": "Kugluktuk", "value": "C17123"},
             {"label": "Pangnirtung", "value": "C17124"},
             {"label": "Rankin Inlet", "value": "C17125"}],
 "Nuqat al Khams": [{"label": "Al Ajaylat", "value": "C65055"},
                    {"label": "Zaltan", "value": "C65056"},
                    {"label": "Zuwarah", "value": "C65057"}],
 "Nur-Sultan": [{"label": "Nur-Sultan", "value": "C64486"}],
 "Nuristan": [{"label": "Parun", "value": "C72"}],
 "Nusa Tenggara Barat": [{"label": "Bima", "value": "C49722"},
                         {"label": "Dompu", "value": "C49723"},
                         {"label": "Gili Air", "value": "C49724"},
                         {"label": "Kabupaten Bima", "value": "C49725"},
                         {"label": "Kabupaten Dompu", "value": "C49726"},
                         {"label": "Kabupaten Lombok Barat", "value": "C49727"},
                         {"label": "Kabupaten Lombok Tengah",
                          "value": "C49728"},
                         {"label": "Kabupaten Lombok Timur", "value": "C49729"},
                         {"label": "Kabupaten Lombok Utara", "value": "C49730"},
                         {"label": "Kabupaten Sumbawa", "value": "C49731"},
                         {"label": "Kabupaten Sumbawa Barat",
                          "value": "C49732"},
                         {"label": "Kota Bima", "value": "C49733"},
                         {"label": "Kota Mataram", "value": "C49734"},
                         {"label": "Labuan Lombok", "value": "C49735"},
                         {"label": "Lembar", "value": "C49736"},
                         {"label": "Mataram", "value": "C49737"},
                         {"label": "Pemenang", "value": "C49738"},
                         {"label": "Pototano", "value": "C49739"},
                         {"label": "Praya", "value": "C49740"},
                         {"label": "Senggigi", "value": "C49741"},
                         {"label": "Sumbawa Besar", "value": "C49742"}],
 "Nusa Tenggara Timur": [{"label": "Atambua", "value": "C49743"},
                         {"label": "Ende", "value": "C49744"},
                         {"label": "Kabupaten Alor", "value": "C49745"},
                         {"label": "Kabupaten Belu", "value": "C49746"},
                         {"label": "Kabupaten Ende", "value": "C49747"},
                         {"label": "Kabupaten Flores Timur", "value": "C49748"},
                         {"label": "Kabupaten Kupang", "value": "C49749"},
                         {"label": "Kabupaten Lembata", "value": "C49750"},
                         {"label": "Kabupaten Malaka", "value": "C49751"},
                         {"label": "Kabupaten Manggarai", "value": "C49752"},
                         {"label": "Kabupaten Manggarai Barat",
                          "value": "C49753"},
                         {"label": "Kabupaten Manggarai Timur",
                          "value": "C49754"},
                         {"label": "Kabupaten Nagekeo", "value": "C49755"},
                         {"label": "Kabupaten Ngada", "value": "C49756"},
                         {"label": "Kabupaten Rote Ndao", "value": "C49757"},
                         {"label": "Kabupaten Sabu Raijua", "value": "C49758"},
                         {"label": "Kabupaten Sikka", "value": "C49759"},
                         {"label": "Kabupaten Sumba Barat", "value": "C49760"},
                         {"label": "Kabupaten Sumba Barat Daya",
                          "value": "C49761"},
                         {"label": "Kabupaten Sumba Tengah", "value": "C49762"},
                         {"label": "Kabupaten Sumba Timur", "value": "C49763"},
                         {"label": "Kabupaten Timor Tengah Selatan",
                          "value": "C49764"},
                         {"label": "Kabupaten Timor Tengah Utara",
                          "value": "C49765"},
                         {"label": "Kefamenanu", "value": "C49766"},
                         {"label": "Komodo", "value": "C49767"},
                         {"label": "Kota Kupang", "value": "C49768"},
                         {"label": "Kupang", "value": "C49769"},
                         {"label": "Labuan Bajo", "value": "C49770"},
                         {"label": "Maumere", "value": "C49771"},
                         {"label": "Naisano Dua", "value": "C49772"},
                         {"label": "Ruteng", "value": "C49773"},
                         {"label": "Soe", "value": "C49774"},
                         {"label": "Waingapu", "value": "C49775"}],
 "Nyamira": [{"label": "Keroka", "value": "C64625"},
             {"label": "Nyamira", "value": "C64626"}],
 "Nyandarua": [{"label": "Ol Kalou", "value": "C64627"}],
 "Nyanga Province": [{"label": "Mayumba", "value": "C34136"},
                     {"label": "Tchibanga", "value": "C34137"}],
 "Nyeri": [{"label": "Naro Moru", "value": "C64628"},
           {"label": "Nyeri", "value": "C64629"},
           {"label": "Othaya", "value": "C64630"}],
 "Nzerekore Region": [{"label": "Beyla", "value": "C43042"},
                      {"label": "Beyla Prefecture", "value": "C43043"},
                      {"label": "Gueckedou", "value": "C43044"},
                      {"label": "Lola", "value": "C43045"},
                      {"label": "Macenta", "value": "C43046"},
                      {"label": "Nzerekore", "value": "C43047"},
                      {"label": "Nzerekore Prefecture", "value": "C43048"},
                      {"label": "Prefecture de Guekedou", "value": "C43049"},
                      {"label": "Yomou", "value": "C43050"}],
 "Oaxaca": [{"label": "Acatlan de Perez Figueroa", "value": "C71507"},
            {"label": "Agua del Espino", "value": "C71508"},
            {"label": "Ahuehuetitlan", "value": "C71509"},
            {"label": "Albarradas", "value": "C71510"},
            {"label": "Almolonga", "value": "C71511"},
            {"label": "alvaro Obregon", "value": "C71512"},
            {"label": "Animas Trujano", "value": "C71513"},
            {"label": "Apoala", "value": "C71514"},
            {"label": "Arroyo Chical (Nuevo Arroyo Chicali)",
             "value": "C71515"},
            {"label": "Arroyo Choapam", "value": "C71516"},
            {"label": "Arroyo de Banco", "value": "C71517"},
            {"label": "Arroyo de Enmedio", "value": "C71518"},
            {"label": "Arroyo Limon", "value": "C71519"},
            {"label": "Arroyo Zapotillo", "value": "C71520"},
            {"label": "Asuncion Cacalotepec", "value": "C71521"},
            {"label": "Asuncion Ixtaltepec", "value": "C71522"},
            {"label": "Asuncion Nochixtlan", "value": "C71523"},
            {"label": "Asuncion Ocotlan", "value": "C71524"},
            {"label": "Asuncion Tlacolulita", "value": "C71525"},
            {"label": "Ayoquezco de Aldama", "value": "C71526"},
            {"label": "Ayotzintepec", "value": "C71527"},
            {"label": "Ayutla", "value": "C71528"},
            {"label": "Bajos de Chila", "value": "C71529"},
            {"label": "Barrio San Diego", "value": "C71530"},
            {"label": "Benemerito Juarez", "value": "C71531"},
            {"label": "Benito Juarez", "value": "C71532"},
            {"label": "Benito Juarez II (San Martin)", "value": "C71533"},
            {"label": "Bethania", "value": "C71534"},
            {"label": "Brisas de Zicatela", "value": "C71535"},
            {"label": "Cajonos", "value": "C71536"},
            {"label": "Calihuala", "value": "C71537"},
            {"label": "Camelia Roja", "value": "C71538"},
            {"label": "Camotinchan", "value": "C71539"},
            {"label": "Candelaria Loxicha", "value": "C71540"},
            {"label": "Capulalpam de Mendez", "value": "C71541"},
            {"label": "Cerro Armadillo Grande", "value": "C71542"},
            {"label": "Cerro del Aire", "value": "C71543"},
            {"label": "Cerro Quemado", "value": "C71544"},
            {"label": "Chahuite", "value": "C71545"},
            {"label": "Chahuites", "value": "C71546"},
            {"label": "Chicahua", "value": "C71547"},
            {"label": "Chicapa de Castro", "value": "C71548"},
            {"label": "Cienega de Zimatlan", "value": "C71549"},
            {"label": "Cieneguilla", "value": "C71550"},
            {"label": "Ciudad de Huajuapan de Leon", "value": "C71551"},
            {"label": "Coatecas Altas", "value": "C71552"},
            {"label": "Coixtlahuaca", "value": "C71553"},
            {"label": "Collantes", "value": "C71554"},
            {"label": "Colonia Jordan", "value": "C71555"},
            {"label": "Colonia Juarez", "value": "C71556"},
            {"label": "Colonia la Central", "value": "C71557"},
            {"label": "Colonia Progreso", "value": "C71558"},
            {"label": "Colonia Rincon Viejo", "value": "C71559"},
            {"label": "Colonia San Luis", "value": "C71560"},
            {"label": "Concepcion Papalo", "value": "C71561"},
            {"label": "Constancia del Rosario", "value": "C71562"},
            {"label": "Constitucion Mexicana", "value": "C71563"},
            {"label": "Corral de Piedra", "value": "C71564"},
            {"label": "Corralero", "value": "C71565"},
            {"label": "Cosoltepec", "value": "C71566"},
            {"label": "Crucecita", "value": "C71567"},
            {"label": "Cuapinolito (Azulillo)", "value": "C71568"},
            {"label": "Cuauhtemoc", "value": "C71569"},
            {"label": "Cuicatlan", "value": "C71570"},
            {"label": "Cuilapan de Guerrero", "value": "C71571"},
            {"label": "Donaji", "value": "C71572"},
            {"label": "Ejutla de Crespo", "value": "C71573"},
            {"label": "El Arador", "value": "C71574"},
            {"label": "El Bajio", "value": "C71575"},
            {"label": "El Barrio de la Soledad", "value": "C71576"},
            {"label": "El Camalote", "value": "C71577"},
            {"label": "El Camaron", "value": "C71578"},
            {"label": "El Capulin (La Nueva Pochota)", "value": "C71579"},
            {"label": "El Carrizal", "value": "C71580"},
            {"label": "El Carrizo", "value": "C71581"},
            {"label": "El Chocolate", "value": "C71582"},
            {"label": "El Ciruelo", "value": "C71583"},
            {"label": "El Coyul", "value": "C71584"},
            {"label": "El Espinal", "value": "C71585"},
            {"label": "El Molino", "value": "C71586"},
            {"label": "El Morro", "value": "C71587"},
            {"label": "El Porvenir", "value": "C71588"},
            {"label": "El Rosario", "value": "C71589"},
            {"label": "Eloxochitlan de Flores Magon", "value": "C71590"},
            {"label": "Encinal Colorado", "value": "C71591"},
            {"label": "Estacion Mogone", "value": "C71592"},
            {"label": "Flor Batavia", "value": "C71593"},
            {"label": "Fraccionamiento Ciudad Yagul", "value": "C71594"},
            {"label": "Fraccionamiento Ex-Hacienda Catano", "value": "C71595"},
            {"label": "Fraccionamiento Real del Valle", "value": "C71596"},
            {"label": "Fraccionamiento Riberas de San Jeronimo",
             "value": "C71597"},
            {"label": "Fraccionamiento San Miguel", "value": "C71598"},
            {"label": "Francisco Ibarra Ramos", "value": "C71599"},
            {"label": "General Pascual Fentes", "value": "C71600"},
            {"label": "Guadalupe Etla", "value": "C71601"},
            {"label": "Guadalupe Hidalgo", "value": "C71602"},
            {"label": "Guadalupe Victoria", "value": "C71603"},
            {"label": "Guamuchil", "value": "C71604"},
            {"label": "Guixe", "value": "C71605"},
            {"label": "Hacienda Blanca", "value": "C71606"},
            {"label": "Heroica Ciudad de Ejutla de Crespo", "value": "C71607"},
            {"label": "Heroica Ciudad de Juchitan de Zaragoza",
             "value": "C71608"},
            {"label": "Heroica Ciudad de Tlaxiaco", "value": "C71609"},
            {"label": "Huajintepec", "value": "C71610"},
            {"label": "Huautepec", "value": "C71611"},
            {"label": "Huazantlan del Rio", "value": "C71612"},
            {"label": "Ignacio Zaragoza", "value": "C71613"},
            {"label": "Isla Soyaltepec", "value": "C71614"},
            {"label": "Ixpantepec Nieves", "value": "C71615"},
            {"label": "Ixtepec", "value": "C71616"},
            {"label": "Ixtlan de Juarez", "value": "C71617"},
            {"label": "Jalapa", "value": "C71618"},
            {"label": "Jaltepec de Candayoc", "value": "C71619"},
            {"label": "Jaltepetongo", "value": "C71620"},
            {"label": "Jamiltepec", "value": "C71621"},
            {"label": "Jose Maria Morelos", "value": "C71622"},
            {"label": "Juchitan de Zaragoza", "value": "C71623"},
            {"label": "La Barra de Colotepec", "value": "C71624"},
            {"label": "La Blanca", "value": "C71625"},
            {"label": "La Canada", "value": "C71626"},
            {"label": "La Candelaria", "value": "C71627"},
            {"label": "La Erradura", "value": "C71628"},
            {"label": "La Humedad", "value": "C71629"},
            {"label": "La Junta", "value": "C71630"},
            {"label": "La Luz", "value": "C71631"},
            {"label": "La Mina", "value": "C71632"},
            {"label": "La Noria", "value": "C71633"},
            {"label": "La Pe", "value": "C71634"},
            {"label": "La Reforma", "value": "C71635"},
            {"label": "La Tabaquera", "value": "C71636"},
            {"label": "La Venta", "value": "C71637"},
            {"label": "La Ventosa", "value": "C71638"},
            {"label": "Las Amilpas", "value": "C71639"},
            {"label": "Las Margaritas", "value": "C71640"},
            {"label": "Lazaro Cardenas", "value": "C71641"},
            {"label": "Llano de Agua", "value": "C71642"},
            {"label": "Llano Suchiapa", "value": "C71643"},
            {"label": "Loma Bonita", "value": "C71644"},
            {"label": "Loma Chapultepec", "value": "C71645"},
            {"label": "Los angeles", "value": "C71646"},
            {"label": "Los Naranjos Esquipulas", "value": "C71647"},
            {"label": "Macin Chico", "value": "C71648"},
            {"label": "Macuilxochitl de Artigas Carranza", "value": "C71649"},
            {"label": "Magdalena Apasco", "value": "C71650"},
            {"label": "Magdalena Jaltepec", "value": "C71651"},
            {"label": "Magdalena Mixtepec", "value": "C71652"},
            {"label": "Magdalena Ocotlan", "value": "C71653"},
            {"label": "Magdalena Teitipac", "value": "C71654"},
            {"label": "Magdalena Tequisistlan", "value": "C71655"},
            {"label": "Magdalena Tlacotepec", "value": "C71656"},
            {"label": "Magdalena Yodocono de Porfirio Diaz", "value": "C71657"},
            {"label": "Magdalena Zahuatlan", "value": "C71658"},
            {"label": "Mancuernas", "value": "C71659"},
            {"label": "Maria Lombardo de Caso", "value": "C71660"},
            {"label": "Mariscala de Juarez", "value": "C71661"},
            {"label": "Matias Romero", "value": "C71662"},
            {"label": "Mazatlan Villa de Flores", "value": "C71663"},
            {"label": "Mazin Grande", "value": "C71664"},
            {"label": "Merced del Potrero", "value": "C71665"},
            {"label": "Mesones Hidalgo", "value": "C71666"},
            {"label": "Miahuatlan de Porfirio Diaz", "value": "C71667"},
            {"label": "Mixistlan de la Reforma", "value": "C71668"},
            {"label": "Montenegro la Lana", "value": "C71669"},
            {"label": "Nanahuatipam", "value": "C71670"},
            {"label": "Nativitas", "value": "C71671"},
            {"label": "Nazareno Etla", "value": "C71672"},
            {"label": "Nejapa de Madero", "value": "C71673"},
            {"label": "Niltepec", "value": "C71674"},
            {"label": "Nuevo Ixcatlan", "value": "C71675"},
            {"label": "Nuevo Paso Nazareno (Chichicazapa)", "value": "C71676"},
            {"label": "Nuevo San Martin", "value": "C71677"},
            {"label": "Oaxaca", "value": "C71678"},
            {"label": "Oaxaca de Juarez", "value": "C71679"},
            {"label": "Ocotlan de Morelos", "value": "C71680"},
            {"label": "Palomares", "value": "C71681"},
            {"label": "Papaloapan", "value": "C71682"},
            {"label": "Paso Canoa", "value": "C71683"},
            {"label": "Paso Real de Sarabia", "value": "C71684"},
            {"label": "Pena Colorada", "value": "C71685"},
            {"label": "Piedra Blanca", "value": "C71686"},
            {"label": "Piedra de Amolar", "value": "C71687"},
            {"label": "Pinotepa de Don Luis", "value": "C71688"},
            {"label": "Pinotepa Nacional", "value": "C71689"},
            {"label": "Playa Chica", "value": "C71690"},
            {"label": "Pluma Hidalgo", "value": "C71691"},
            {"label": "Praxedis de Guerrero", "value": "C71692"},
            {"label": "Progreso", "value": "C71693"},
            {"label": "Pueblo Viejo", "value": "C71694"},
            {"label": "Puerto angel", "value": "C71695"},
            {"label": "Puerto Escondido", "value": "C71696"},
            {"label": "Putla Villa de Guerrero", "value": "C71697"},
            {"label": "Quelove", "value": "C71698"},
            {"label": "Quinicuena", "value": "C71699"},
            {"label": "Rancho Grande", "value": "C71700"},
            {"label": "Rancho Viejo", "value": "C71701"},
            {"label": "Reforma de Pineda", "value": "C71702"},
            {"label": "Reyes Etla", "value": "C71703"},
            {"label": "Reyes Mantecon", "value": "C71704"},
            {"label": "Rincon Moreno", "value": "C71705"},
            {"label": "Rio Chiquito", "value": "C71706"},
            {"label": "Rio Grande", "value": "C71707"},
            {"label": "Rio Pachine", "value": "C71708"},
            {"label": "Rojas de Cuauhtemoc", "value": "C71709"},
            {"label": "Salina Cruz", "value": "C71710"},
            {"label": "San Agustin Amatengo", "value": "C71711"},
            {"label": "San Agustin Atenango", "value": "C71712"},
            {"label": "San Agustin Chayuco", "value": "C71713"},
            {"label": "San Agustin de las Juntas", "value": "C71714"},
            {"label": "San Agustin Etla", "value": "C71715"},
            {"label": "San Agustin Loxicha", "value": "C71716"},
            {"label": "San Agustin Yatareni", "value": "C71717"},
            {"label": "San Andres Chicahuaxtla", "value": "C71718"},
            {"label": "San Andres Dinicuiti", "value": "C71719"},
            {"label": "San Andres Hidalgo", "value": "C71720"},
            {"label": "San Andres Huaxpaltepec", "value": "C71721"},
            {"label": "San Andres Huayapam", "value": "C71722"},
            {"label": "San Andres Ixtlahuaca", "value": "C71723"},
            {"label": "San Andres Paxtlan", "value": "C71724"},
            {"label": "San Andres Sinaxtla", "value": "C71725"},
            {"label": "San Andres Solaga", "value": "C71726"},
            {"label": "San Andres Teotilalpam", "value": "C71727"},
            {"label": "San Andres Yaa", "value": "C71728"},
            {"label": "San Andres Zabache", "value": "C71729"},
            {"label": "San Andres Zautla", "value": "C71730"},
            {"label": "San Antonino Castillo Velasco", "value": "C71731"},
            {"label": "San Antonino el Alto", "value": "C71732"},
            {"label": "San Antonino Monte Verde", "value": "C71733"},
            {"label": "San Antonio Arrazola", "value": "C71734"},
            {"label": "San Antonio de la Cal", "value": "C71735"},
            {"label": "San Antonio Huitepec", "value": "C71736"},
            {"label": "San Antonio las Palmas", "value": "C71737"},
            {"label": "San Antonio Ocotlan", "value": "C71738"},
            {"label": "San Antonio Tepetlapa", "value": "C71739"},
            {"label": "San Baltazar Chichicapam", "value": "C71740"},
            {"label": "San Baltazar Guelavila", "value": "C71741"},
            {"label": "San Baltazar Loxicha", "value": "C71742"},
            {"label": "San Baltazar Yatzachi el Bajo", "value": "C71743"},
            {"label": "San Bartolo", "value": "C71744"},
            {"label": "San Bartolo Coyotepec", "value": "C71745"},
            {"label": "San Bartolo Coyotespec", "value": "C71746"},
            {"label": "San Bartolo Yautepec", "value": "C71747"},
            {"label": "San Bartolome Ayautla", "value": "C71748"},
            {"label": "San Bartolome Loxicha", "value": "C71749"},
            {"label": "San Bartolome Quialana", "value": "C71750"},
            {"label": "San Benito Encinal", "value": "C71751"},
            {"label": "San Bernardo Mixtepec", "value": "C71752"},
            {"label": "San Blas Atempa", "value": "C71753"},
            {"label": "San Carlos Yautepec", "value": "C71754"},
            {"label": "San Cristobal Amatlan", "value": "C71755"},
            {"label": "San Cristobal Amoltepec", "value": "C71756"},
            {"label": "San Cristobal Honduras", "value": "C71757"},
            {"label": "San Cristobal Lachirioag", "value": "C71758"},
            {"label": "San Dionisio del Mar", "value": "C71759"},
            {"label": "San Dionisio Ocotepec", "value": "C71760"},
            {"label": "San Dionisio Ocotlan", "value": "C71761"},
            {"label": "San Esteban Atatlahuca", "value": "C71762"},
            {"label": "San Felipe Cihualtepec", "value": "C71763"},
            {"label": "San Felipe Jalapa de Diaz", "value": "C71764"},
            {"label": "San Felipe Tejalapam", "value": "C71765"},
            {"label": "San Felipe Tilpam", "value": "C71766"},
            {"label": "San Felipe Usila", "value": "C71767"},
            {"label": "San Francisco Cajonos", "value": "C71768"},
            {"label": "San Francisco Chindua", "value": "C71769"},
            {"label": "San Francisco Coatlan", "value": "C71770"},
            {"label": "San Francisco Cozoaltepec", "value": "C71771"},
            {"label": "San Francisco del Mar", "value": "C71772"},
            {"label": "San Francisco del Mar Viejo", "value": "C71773"},
            {"label": "San Francisco Huehuetlan", "value": "C71774"},
            {"label": "San Francisco Ixhuatan", "value": "C71775"},
            {"label": "San Francisco Jaltepetongo", "value": "C71776"},
            {"label": "San Francisco Javier", "value": "C71777"},
            {"label": "San Francisco Lachigolo", "value": "C71778"},
            {"label": "San Francisco Logueche", "value": "C71779"},
            {"label": "San Francisco Loxicha", "value": "C71780"},
            {"label": "San Francisco Nuxano", "value": "C71781"},
            {"label": "San Francisco Ozolotepec", "value": "C71782"},
            {"label": "San Francisco Sola", "value": "C71783"},
            {"label": "San Francisco Telixtlahuaca", "value": "C71784"},
            {"label": "San Francisco Tutla", "value": "C71785"},
            {"label": "San Gabriel Mixtepec", "value": "C71786"},
            {"label": "San Ildefonso Amatlan", "value": "C71787"},
            {"label": "San Ildefonso Villa Alta", "value": "C71788"},
            {"label": "San Isidro Apango", "value": "C71789"},
            {"label": "San Isidro Monjas", "value": "C71790"},
            {"label": "San Jacinto Amilpas", "value": "C71791"},
            {"label": "San Jeronimo Coatlan", "value": "C71792"},
            {"label": "San Jeronimo Silacayoapilla", "value": "C71793"},
            {"label": "San Jeronimo Sosola", "value": "C71794"},
            {"label": "San Jeronimo Taviche", "value": "C71795"},
            {"label": "San Jeronimo Tecoatl", "value": "C71796"},
            {"label": "San Jeronimo Tlacochahuaya", "value": "C71797"},
            {"label": "San Jeronimo Yahuiche", "value": "C71798"},
            {"label": "San Jorge Nuchita", "value": "C71799"},
            {"label": "San Jose Ayuquila", "value": "C71800"},
            {"label": "San Jose Chacalapa", "value": "C71801"},
            {"label": "San Jose Chiltepec", "value": "C71802"},
            {"label": "San Jose de las Flores", "value": "C71803"},
            {"label": "San Jose del Progreso", "value": "C71804"},
            {"label": "San Jose Estancia Grande", "value": "C71805"},
            {"label": "San Jose la Garzona", "value": "C71806"},
            {"label": "San Jose Lachiguiri", "value": "C71807"},
            {"label": "San Jose Piedras Negras", "value": "C71808"},
            {"label": "San Jose Rio Manzo", "value": "C71809"},
            {"label": "San Jose Tenango", "value": "C71810"},
            {"label": "San Juan Achiutla", "value": "C71811"},
            {"label": "San Juan Atepec", "value": "C71812"},
            {"label": "San Juan Bautista Jayacatlan", "value": "C71813"},
            {"label": "San Juan Bautista la Raya", "value": "C71814"},
            {"label": "San Juan Bautista Lo de Soto", "value": "C71815"},
            {"label": "San Juan Cabeza del Rio", "value": "C71816"},
            {"label": "San Juan Cacahuatepec", "value": "C71817"},
            {"label": "San Juan Chilateca", "value": "C71818"},
            {"label": "San Juan Coatzospam", "value": "C71819"},
            {"label": "San Juan Colorado", "value": "C71820"},
            {"label": "San Juan Cotzocon", "value": "C71821"},
            {"label": "San Juan de los Cues", "value": "C71822"},
            {"label": "San Juan del Estado", "value": "C71823"},
            {"label": "San Juan del Rio", "value": "C71824"},
            {"label": "San Juan Diuxi", "value": "C71825"},
            {"label": "San Juan Guelavia", "value": "C71826"},
            {"label": "San Juan Guichicovi", "value": "C71827"},
            {"label": "San Juan Ihualtepec", "value": "C71828"},
            {"label": "San Juan Jaltepec", "value": "C71829"},
            {"label": "San Juan Jicayan", "value": "C71830"},
            {"label": "San Juan Juquila", "value": "C71831"},
            {"label": "San Juan Juquila Vijanos", "value": "C71832"},
            {"label": "San Juan Lachao", "value": "C71833"},
            {"label": "San Juan Lachigalla", "value": "C71834"},
            {"label": "San Juan Lagunas", "value": "C71835"},
            {"label": "San Juan Mazatlan", "value": "C71836"},
            {"label": "San Juan Metaltepec", "value": "C71837"},
            {"label": "San Juan Mixtepec -Dto. 08 -", "value": "C71838"},
            {"label": "San Juan Mixtepec -Dto. 26 -", "value": "C71839"},
            {"label": "San Juan numi", "value": "C71840"},
            {"label": "San Juan Ozolotepec", "value": "C71841"},
            {"label": "San Juan Petlapa", "value": "C71842"},
            {"label": "San Juan Quiahue", "value": "C71843"},
            {"label": "San Juan Quiotepec", "value": "C71844"},
            {"label": "San Juan Sautla", "value": "C71845"},
            {"label": "San Juan Sayultepec", "value": "C71846"},
            {"label": "San Juan Tabaa", "value": "C71847"},
            {"label": "San Juan Teitipac", "value": "C71848"},
            {"label": "San Juan Teposcolula", "value": "C71849"},
            {"label": "San Juan Yaee", "value": "C71850"},
            {"label": "San Juan Yatzona", "value": "C71851"},
            {"label": "San Juan Zapotitlan", "value": "C71852"},
            {"label": "San Lorenzo", "value": "C71853"},
            {"label": "San Lorenzo Albarradas", "value": "C71854"},
            {"label": "San Lorenzo Cacaotepec", "value": "C71855"},
            {"label": "San Lorenzo Cuaunecuiltitla", "value": "C71856"},
            {"label": "San Lorenzo Victoria", "value": "C71857"},
            {"label": "San Lorenzo Vista Hermosa", "value": "C71858"},
            {"label": "San Lucas Camotlan", "value": "C71859"},
            {"label": "San Lucas Ojitlan", "value": "C71860"},
            {"label": "San Lucas Quiavini", "value": "C71861"},
            {"label": "San Lucas Zoquiapam", "value": "C71862"},
            {"label": "San Luis Amatlan", "value": "C71863"},
            {"label": "San Marcial Ozolotepec", "value": "C71864"},
            {"label": "San Marcos Arteaga", "value": "C71865"},
            {"label": "San Marcos Zacatepec", "value": "C71866"},
            {"label": "San Martin de los Canseco", "value": "C71867"},
            {"label": "San Martin Itunyoso", "value": "C71868"},
            {"label": "San Martin Lachila", "value": "C71869"},
            {"label": "San Martin Tilcajete", "value": "C71870"},
            {"label": "San Mateo Cajonos", "value": "C71871"},
            {"label": "San Mateo del Mar", "value": "C71872"},
            {"label": "San Mateo Etlatongo", "value": "C71873"},
            {"label": "San Mateo Nejapam", "value": "C71874"},
            {"label": "San Mateo Pinas", "value": "C71875"},
            {"label": "San Mateo Sindihui", "value": "C71876"},
            {"label": "San Mateo Yoloxochitlan", "value": "C71877"},
            {"label": "San Melchor Betaza", "value": "C71878"},
            {"label": "San Miguel Abejones", "value": "C71879"},
            {"label": "San Miguel Achiutla", "value": "C71880"},
            {"label": "San Miguel Aloapam", "value": "C71881"},
            {"label": "San Miguel Chimalapa", "value": "C71882"},
            {"label": "San Miguel Coatlan", "value": "C71883"},
            {"label": "San Miguel del Puerto", "value": "C71884"},
            {"label": "San Miguel del Valle", "value": "C71885"},
            {"label": "San Miguel Ejutla", "value": "C71886"},
            {"label": "San Miguel el Grande", "value": "C71887"},
            {"label": "San Miguel el Grande Villa Juarez", "value": "C71888"},
            {"label": "San Miguel Figueroa", "value": "C71889"},
            {"label": "San Miguel Mixtepec", "value": "C71890"},
            {"label": "San Miguel Monteverde", "value": "C71891"},
            {"label": "San Miguel Panixtlahuaca", "value": "C71892"},
            {"label": "San Miguel Peras", "value": "C71893"},
            {"label": "San Miguel Quetzaltepec", "value": "C71894"},
            {"label": "San Miguel Suchixtepec", "value": "C71895"},
            {"label": "San Miguel Tecomatlan", "value": "C71896"},
            {"label": "San Miguel Tenango", "value": "C71897"},
            {"label": "San Miguel Tetepelcingo", "value": "C71898"},
            {"label": "San Miguel Tilquiapam", "value": "C71899"},
            {"label": "San Miguel Tlacamama", "value": "C71900"},
            {"label": "San Miguel Tlacotepec", "value": "C71901"},
            {"label": "San Nicolas", "value": "C71902"},
            {"label": "San Nicolas Quialana", "value": "C71903"},
            {"label": "San Pablo Coatlan", "value": "C71904"},
            {"label": "San Pablo Cuatro Venados", "value": "C71905"},
            {"label": "San Pablo Etla", "value": "C71906"},
            {"label": "San Pablo Guila", "value": "C71907"},
            {"label": "San Pablo Huitzo", "value": "C71908"},
            {"label": "San Pablo Huixtepec", "value": "C71909"},
            {"label": "San Pablo Macuiltianguis", "value": "C71910"},
            {"label": "San Pablo Villa de Mitla", "value": "C71911"},
            {"label": "San Pedro Amuzgos", "value": "C71912"},
            {"label": "San Pedro Apostol", "value": "C71913"},
            {"label": "San Pedro Atoyac", "value": "C71914"},
            {"label": "San Pedro Comitancillo", "value": "C71915"},
            {"label": "San Pedro Coxcaltepec Cantaros", "value": "C71916"},
            {"label": "San Pedro el Alto", "value": "C71917"},
            {"label": "San Pedro Huamelula", "value": "C71918"},
            {"label": "San Pedro Huilotepec", "value": "C71919"},
            {"label": "San Pedro Ixcatlan", "value": "C71920"},
            {"label": "San Pedro Ixtlahuaca", "value": "C71921"},
            {"label": "San Pedro Jicayan", "value": "C71922"},
            {"label": "San Pedro Juchaltengo", "value": "C71923"},
            {"label": "San Pedro Martir", "value": "C71924"},
            {"label": "San Pedro Martir Quiechapa", "value": "C71925"},
            {"label": "San Pedro Martir Yucuxaco", "value": "C71926"},
            {"label": "San Pedro Mixtepec", "value": "C71927"},
            {"label": "San Pedro Ocopetatillo", "value": "C71928"},
            {"label": "San Pedro Ocotepec", "value": "C71929"},
            {"label": "San Pedro Ozumacin", "value": "C71930"},
            {"label": "San Pedro Pochutla", "value": "C71931"},
            {"label": "San Pedro Quiatoni", "value": "C71932"},
            {"label": "San Pedro Sochiapam", "value": "C71933"},
            {"label": "San Pedro Tapanatepec", "value": "C71934"},
            {"label": "San Pedro Taviche", "value": "C71935"},
            {"label": "San Pedro Teutila", "value": "C71936"},
            {"label": "San Pedro Tidaa", "value": "C71937"},
            {"label": "San Pedro Topiltepec", "value": "C71938"},
            {"label": "San Pedro Totolapam", "value": "C71939"},
            {"label": "San Pedro Tulixtlahuaca", "value": "C71940"},
            {"label": "San Pedro y San Pablo Teposcolula", "value": "C71941"},
            {"label": "San Pedro y San Pablo Tequistepec", "value": "C71942"},
            {"label": "San Pedro Yolox", "value": "C71943"},
            {"label": "San Raymundo Jalpam", "value": "C71944"},
            {"label": "San Roque", "value": "C71945"},
            {"label": "San Sebastian Abasolo", "value": "C71946"},
            {"label": "San Sebastian Coatlan", "value": "C71947"},
            {"label": "San Sebastian del Monte", "value": "C71948"},
            {"label": "San Sebastian Etla", "value": "C71949"},
            {"label": "San Sebastian Ixcapa", "value": "C71950"},
            {"label": "San Sebastian Nicananduta", "value": "C71951"},
            {"label": "San Sebastian Nopalera", "value": "C71952"},
            {"label": "San Sebastian Rio Hondo", "value": "C71953"},
            {"label": "San Sebastian Tecomaxtlahuaca", "value": "C71954"},
            {"label": "San Sebastian Teitipac", "value": "C71955"},
            {"label": "San Sebastian Tutla", "value": "C71956"},
            {"label": "San Simon Almolongas", "value": "C71957"},
            {"label": "San Vicente Coatlan", "value": "C71958"},
            {"label": "San Vicente Lachixio", "value": "C71959"},
            {"label": "San Vicente Nunu", "value": "C71960"},
            {"label": "San Vicente Pinas", "value": "C71961"},
            {"label": "Santa Ana", "value": "C71962"},
            {"label": "Santa Ana del Valle", "value": "C71963"},
            {"label": "Santa Ana Tlapacoyan", "value": "C71964"},
            {"label": "Santa Ana Zegache", "value": "C71965"},
            {"label": "Santa Catalina Quieri", "value": "C71966"},
            {"label": "Santa Catarina Cuixtla", "value": "C71967"},
            {"label": "Santa Catarina Juquila", "value": "C71968"},
            {"label": "Santa Catarina Loxicha", "value": "C71969"},
            {"label": "Santa Catarina Mechoacan", "value": "C71970"},
            {"label": "Santa Catarina Minas", "value": "C71971"},
            {"label": "Santa Catarina Quiane", "value": "C71972"},
            {"label": "Santa Catarina Roatina", "value": "C71973"},
            {"label": "Santa Catarina Tayata", "value": "C71974"},
            {"label": "Santa Cecilia Jalieza", "value": "C71975"},
            {"label": "Santa Cruz Acatepec", "value": "C71976"},
            {"label": "Santa Cruz Amilpas", "value": "C71977"},
            {"label": "Santa Cruz de Bravo", "value": "C71978"},
            {"label": "Santa Cruz de Juarez", "value": "C71979"},
            {"label": "Santa Cruz Lachixolana", "value": "C71980"},
            {"label": "Santa Cruz Mixtepec", "value": "C71981"},
            {"label": "Santa Cruz Nundaco", "value": "C71982"},
            {"label": "Santa Cruz Ozolotepec", "value": "C71983"},
            {"label": "Santa Cruz Papalutla", "value": "C71984"},
            {"label": "Santa Cruz Tacache de Mina", "value": "C71985"},
            {"label": "Santa Cruz Tayata", "value": "C71986"},
            {"label": "Santa Cruz Xitla", "value": "C71987"},
            {"label": "Santa Cruz Xoxocotlan", "value": "C71988"},
            {"label": "Santa Fe y la Mar", "value": "C71989"},
            {"label": "Santa Gertrudis", "value": "C71990"},
            {"label": "Santa Ines de Zaragoza", "value": "C71991"},
            {"label": "Santa Ines del Monte", "value": "C71992"},
            {"label": "Santa Ines Yatzeche", "value": "C71993"},
            {"label": "Santa Lucia", "value": "C71994"},
            {"label": "Santa Lucia del Camino", "value": "C71995"},
            {"label": "Santa Lucia Ocotlan", "value": "C71996"},
            {"label": "Santa Lucia Teotepec", "value": "C71997"},
            {"label": "Santa Magdalena Jicotlan", "value": "C71998"},
            {"label": "Santa Margarita Huitepec", "value": "C71999"},
            {"label": "Santa Maria Acatepec", "value": "C72000"},
            {"label": "Santa Maria Alotepec", "value": "C72001"},
            {"label": "Santa Maria Apazco", "value": "C72002"},
            {"label": "Santa Maria Atzompa", "value": "C72003"},
            {"label": "Santa Maria Camotlan", "value": "C72004"},
            {"label": "Santa Maria Chachoapam", "value": "C72005"},
            {"label": "Santa Maria Chico Ometepec", "value": "C72006"},
            {"label": "Santa Maria Chilapa de Diaz", "value": "C72007"},
            {"label": "Santa Maria Chimalapa", "value": "C72008"},
            {"label": "Santa Maria Colotepec", "value": "C72009"},
            {"label": "Santa Maria Cortijo", "value": "C72010"},
            {"label": "Santa Maria Coyotepec", "value": "C72011"},
            {"label": "Santa Maria del Rosario", "value": "C72012"},
            {"label": "Santa Maria del Tule", "value": "C72013"},
            {"label": "Santa Maria Ecatepec", "value": "C72014"},
            {"label": "Santa Maria Guelace", "value": "C72015"},
            {"label": "Santa Maria Guenagati", "value": "C72016"},
            {"label": "Santa Maria Huamelula", "value": "C72017"},
            {"label": "Santa Maria Huatulco", "value": "C72018"},
            {"label": "Santa Maria Huazolotitlan", "value": "C72019"},
            {"label": "Santa Maria Ipalapa", "value": "C72020"},
            {"label": "Santa Maria Jacatepec", "value": "C72021"},
            {"label": "Santa Maria Jalapa del Marques", "value": "C72022"},
            {"label": "Santa Maria Jicaltepec", "value": "C72023"},
            {"label": "Santa Maria la Asuncion", "value": "C72024"},
            {"label": "Santa Maria Lachixio", "value": "C72025"},
            {"label": "Santa Maria Mixtequilla", "value": "C72026"},
            {"label": "Santa Maria Nativitas", "value": "C72027"},
            {"label": "Santa Maria Nduayaco", "value": "C72028"},
            {"label": "Santa Maria Papalo", "value": "C72029"},
            {"label": "Santa Maria Petapa", "value": "C72030"},
            {"label": "Santa Maria Puxmetacan", "value": "C72031"},
            {"label": "Santa Maria Quiegolani", "value": "C72032"},
            {"label": "Santa Maria Sola", "value": "C72033"},
            {"label": "Santa Maria Temaxcalapa", "value": "C72034"},
            {"label": "Santa Maria Temaxcaltepec", "value": "C72035"},
            {"label": "Santa Maria Teopoxco", "value": "C72036"},
            {"label": "Santa Maria Tepantlali", "value": "C72037"},
            {"label": "Santa Maria Tiltepec", "value": "C72038"},
            {"label": "Santa Maria Tlalixtac", "value": "C72039"},
            {"label": "Santa Maria Tonameca", "value": "C72040"},
            {"label": "Santa Maria Velato", "value": "C72041"},
            {"label": "Santa Maria Xadani", "value": "C72042"},
            {"label": "Santa Maria Yavesia", "value": "C72043"},
            {"label": "Santa Maria Yolotepec", "value": "C72044"},
            {"label": "Santa Maria Yucuhiti", "value": "C72045"},
            {"label": "Santa Maria Yucunicoco", "value": "C72046"},
            {"label": "Santa Maria Zacatepec", "value": "C72047"},
            {"label": "Santa Maria Zaniza", "value": "C72048"},
            {"label": "Santa Maria Zapotitlan", "value": "C72049"},
            {"label": "Santa Maria Zoquitlan", "value": "C72050"},
            {"label": "Santa Martha Chichihualtepec", "value": "C72051"},
            {"label": "Santa Rosa", "value": "C72052"},
            {"label": "Santa Rosa Caxtlahuaca", "value": "C72053"},
            {"label": "Santa Rosa de Lima", "value": "C72054"},
            {"label": "Santiago Amoltepec", "value": "C72055"},
            {"label": "Santiago Apostol", "value": "C72056"},
            {"label": "Santiago Astata", "value": "C72057"},
            {"label": "Santiago Ayuquililla", "value": "C72058"},
            {"label": "Santiago Cacaloxtepec", "value": "C72059"},
            {"label": "Santiago Chazumba", "value": "C72060"},
            {"label": "Santiago Chilixtlahuaca", "value": "C72061"},
            {"label": "Santiago Choapam", "value": "C72062"},
            {"label": "Santiago Comaltepec", "value": "C72063"},
            {"label": "Santiago Cuixtla", "value": "C72064"},
            {"label": "Santiago del Rio", "value": "C72065"},
            {"label": "Santiago Etla", "value": "C72066"},
            {"label": "Santiago Huajolotitlan", "value": "C72067"},
            {"label": "Santiago Huauclilla", "value": "C72068"},
            {"label": "Santiago Huaxolotipac", "value": "C72069"},
            {"label": "Santiago Ihuitlan Plumas", "value": "C72070"},
            {"label": "Santiago Ixcuintepec", "value": "C72071"},
            {"label": "Santiago Ixtaltepec", "value": "C72072"},
            {"label": "Santiago Ixtayutla", "value": "C72073"},
            {"label": "Santiago Jicayan", "value": "C72074"},
            {"label": "Santiago Jocotepec", "value": "C72075"},
            {"label": "Santiago Juxtlahuaca", "value": "C72076"},
            {"label": "Santiago Lachiguiri", "value": "C72077"},
            {"label": "Santiago Lalopa", "value": "C72078"},
            {"label": "Santiago Laollaga", "value": "C72079"},
            {"label": "Santiago Laxopa", "value": "C72080"},
            {"label": "Santiago Llano Grande", "value": "C72081"},
            {"label": "Santiago Malacatepec", "value": "C72082"},
            {"label": "Santiago Matatlan", "value": "C72083"},
            {"label": "Santiago Miltepec", "value": "C72084"},
            {"label": "Santiago Nejapilla", "value": "C72085"},
            {"label": "Santiago Niltepec", "value": "C72086"},
            {"label": "Santiago Nundiche", "value": "C72087"},
            {"label": "Santiago Nuyoo", "value": "C72088"},
            {"label": "Santiago Quiavicuzas", "value": "C72089"},
            {"label": "Santiago Suchilquitongo", "value": "C72090"},
            {"label": "Santiago Tenango", "value": "C72091"},
            {"label": "Santiago Tepextla", "value": "C72092"},
            {"label": "Santiago Tetepec", "value": "C72093"},
            {"label": "Santiago Texcalcingo", "value": "C72094"},
            {"label": "Santiago Textitlan", "value": "C72095"},
            {"label": "Santiago Tilantongo", "value": "C72096"},
            {"label": "Santiago Tillo", "value": "C72097"},
            {"label": "Santiago Xanica", "value": "C72098"},
            {"label": "Santiago Yaitepec", "value": "C72099"},
            {"label": "Santiago Yogana", "value": "C72100"},
            {"label": "Santiago Yolomecatl", "value": "C72101"},
            {"label": "Santiago Yosondua", "value": "C72102"},
            {"label": "Santiago Zacatepec", "value": "C72103"},
            {"label": "Santiago Zoochila", "value": "C72104"},
            {"label": "Santo Domingo Albarradas", "value": "C72105"},
            {"label": "Santo Domingo Armenta", "value": "C72106"},
            {"label": "Santo Domingo Chihuitan", "value": "C72107"},
            {"label": "Santo Domingo de Morelos", "value": "C72108"},
            {"label": "Santo Domingo Jalieza", "value": "C72109"},
            {"label": "Santo Domingo Ozolotepec", "value": "C72110"},
            {"label": "Santo Domingo Petapa", "value": "C72111"},
            {"label": "Santo Domingo Roayaga", "value": "C72112"},
            {"label": "Santo Domingo Tehuantepec", "value": "C72113"},
            {"label": "Santo Domingo Tepuxtepec", "value": "C72114"},
            {"label": "Santo Domingo Tlatayapam", "value": "C72115"},
            {"label": "Santo Domingo Tomaltepec", "value": "C72116"},
            {"label": "Santo Domingo Tonala", "value": "C72117"},
            {"label": "Santo Domingo Yanhuitlan", "value": "C72118"},
            {"label": "Santo Domingo Yodohino", "value": "C72119"},
            {"label": "Santo Domingo Zanatepec", "value": "C72120"},
            {"label": "Santo Tomas", "value": "C72121"},
            {"label": "Santo Tomas Jalieza", "value": "C72122"},
            {"label": "Santo Tomas Mazaltepec", "value": "C72123"},
            {"label": "Santo Tomas Ocotepec", "value": "C72124"},
            {"label": "Santo Tomas Tamazulapam", "value": "C72125"},
            {"label": "Santos Reyes Nopala", "value": "C72126"},
            {"label": "Santos Reyes Papalo", "value": "C72127"},
            {"label": "Santos Reyes Tepejillo", "value": "C72128"},
            {"label": "Santos Reyes Yucuna", "value": "C72129"},
            {"label": "Sector H Tres", "value": "C72130"},
            {"label": "Silacayoapam", "value": "C72131"},
            {"label": "Sitio de Xitlapehua", "value": "C72132"},
            {"label": "Soledad Etla", "value": "C72133"},
            {"label": "Soledad Salinas", "value": "C72134"},
            {"label": "Tamazola", "value": "C72135"},
            {"label": "Tamazulapam del Espiritu Santo", "value": "C72136"},
            {"label": "Tamazulapam Villa del Progreso", "value": "C72137"},
            {"label": "Tanetze de Zaragoza", "value": "C72138"},
            {"label": "Taniche", "value": "C72139"},
            {"label": "Tapanala", "value": "C72140"},
            {"label": "Tatahuicapa", "value": "C72141"},
            {"label": "Tataltepec de Valdes", "value": "C72142"},
            {"label": "Tecomavaca", "value": "C72143"},
            {"label": "Temascal", "value": "C72144"},
            {"label": "Teococuilco de Marcos Perez", "value": "C72145"},
            {"label": "Teojomulco", "value": "C72146"},
            {"label": "Teotitlan", "value": "C72147"},
            {"label": "Teotitlan del Valle", "value": "C72148"},
            {"label": "Tepenixtlahuaca", "value": "C72149"},
            {"label": "Tepetlapa", "value": "C72150"},
            {"label": "Tetela", "value": "C72151"},
            {"label": "Texcatitlan", "value": "C72152"},
            {"label": "Texmelucan", "value": "C72153"},
            {"label": "Tezoatlan de Segura y Luna", "value": "C72154"},
            {"label": "Tlachichilco", "value": "C72155"},
            {"label": "Tlacoatzintepec", "value": "C72156"},
            {"label": "Tlacolula de Matamoros", "value": "C72157"},
            {"label": "Tlahuitoltepec", "value": "C72158"},
            {"label": "Tlalixtac de Cabrera", "value": "C72159"},
            {"label": "Totontepec Villa de Morelos", "value": "C72160"},
            {"label": "Toxpalan", "value": "C72161"},
            {"label": "Trinidad de Viguera", "value": "C72162"},
            {"label": "Trinidad Zaachila", "value": "C72163"},
            {"label": "Tuxtepec", "value": "C72164"},
            {"label": "Union Hidalgo", "value": "C72165"},
            {"label": "Valdeflores", "value": "C72166"},
            {"label": "Valerio Trujano", "value": "C72167"},
            {"label": "Valle Nacional", "value": "C72168"},
            {"label": "Vega del Sol", "value": "C72169"},
            {"label": "Vicente Camalote", "value": "C72170"},
            {"label": "Vicente Guerrero", "value": "C72171"},
            {"label": "Villa Chalcatongo de Hidalgo", "value": "C72172"},
            {"label": "Villa de Etla", "value": "C72173"},
            {"label": "Villa de Tamazulapam del Progreso", "value": "C72174"},
            {"label": "Villa de Tututepec de Melchor Ocampo",
             "value": "C72175"},
            {"label": "Villa de Zaachila", "value": "C72176"},
            {"label": "Villa Diaz Ordaz", "value": "C72177"},
            {"label": "Villa Hidalgo", "value": "C72178"},
            {"label": "Villa Sola de Vega", "value": "C72179"},
            {"label": "Villa Talea de Castro", "value": "C72180"},
            {"label": "Villa Tejupam de la Union", "value": "C72181"},
            {"label": "Vista Hermosa", "value": "C72182"},
            {"label": "Xaaga", "value": "C72183"},
            {"label": "Xagacia", "value": "C72184"},
            {"label": "Xochitonalco", "value": "C72185"},
            {"label": "Yaganiza", "value": "C72186"},
            {"label": "Yalina", "value": "C72187"},
            {"label": "Yatzachi", "value": "C72188"},
            {"label": "Yaxe", "value": "C72189"},
            {"label": "Yetla de Juarez", "value": "C72190"},
            {"label": "Yucuita", "value": "C72191"},
            {"label": "Yutanduchi de Guerrero", "value": "C72192"},
            {"label": "Zacatal", "value": "C72193"},
            {"label": "Zapotitlan Lagunas", "value": "C72194"},
            {"label": "Zapotitlan Palmas", "value": "C72195"},
            {"label": "Zaragoza", "value": "C72196"},
            {"label": "Zarzal", "value": "C72197"},
            {"label": "Zimatlan de alvarez", "value": "C72198"},
            {"label": "Zocoteaca de Leon", "value": "C72199"},
            {"label": "Zona Urbana Ejidal", "value": "C72200"}],
 "Obock Region": [{"label": "Alaili Dadda", "value": "C23814"},
                  {"label": "Obock", "value": "C23815"}],
 "Obwalden": [{"label": "Alpnach", "value": "C116940"},
              {"label": "Engelberg", "value": "C116941"},
              {"label": "Giswil", "value": "C116942"},
              {"label": "Kerns", "value": "C116943"},
              {"label": "Lungern", "value": "C116944"},
              {"label": "Sachseln", "value": "C116945"},
              {"label": "Sarnen", "value": "C116946"}],
 "Occidental Mindoro": [{"label": "Abuyon", "value": "C86684"},
                        {"label": "Aga", "value": "C86685"},
                        {"label": "Agdangan", "value": "C86686"},
                        {"label": "Agoncillo", "value": "C86687"},
                        {"label": "Alabat", "value": "C86688"},
                        {"label": "Alaminos", "value": "C86689"},
                        {"label": "Alfonso", "value": "C86690"},
                        {"label": "Aliang", "value": "C86691"},
                        {"label": "Alitagtag", "value": "C86692"},
                        {"label": "Alupay", "value": "C86693"},
                        {"label": "Amadeo", "value": "C86694"},
                        {"label": "Ambulong", "value": "C86695"},
                        {"label": "Angono", "value": "C86696"},
                        {"label": "Antipolo", "value": "C86697"},
                        {"label": "Anuling", "value": "C86698"},
                        {"label": "Aplaya", "value": "C86699"},
                        {"label": "Atimonan", "value": "C86700"},
                        {"label": "Aurora", "value": "C86701"},
                        {"label": "Aya", "value": "C86702"},
                        {"label": "Ayusan Uno", "value": "C86703"},
                        {"label": "Bacoor", "value": "C86704"},
                        {"label": "Bagalangit", "value": "C86705"},
                        {"label": "Bagombong", "value": "C86706"},
                        {"label": "Bagong Pagasa", "value": "C86707"},
                        {"label": "Bagupaye", "value": "C86708"},
                        {"label": "Balagtasin", "value": "C86709"},
                        {"label": "Balayan", "value": "C86710"},
                        {"label": "Balele", "value": "C86711"},
                        {"label": "Balete", "value": "C86712"},
                        {"label": "Balibago", "value": "C86713"},
                        {"label": "Balite Segundo", "value": "C86714"},
                        {"label": "Balitoc", "value": "C86715"},
                        {"label": "Banaba", "value": "C86716"},
                        {"label": "Banalo", "value": "C86717"},
                        {"label": "Banaybanay", "value": "C86718"},
                        {"label": "Banilad", "value": "C86719"},
                        {"label": "Bantilan", "value": "C86720"},
                        {"label": "Banugao", "value": "C86721"},
                        {"label": "Baras", "value": "C86722"},
                        {"label": "Batangas", "value": "C86723"},
                        {"label": "Batas", "value": "C86724"},
                        {"label": "Bauan", "value": "C86725"},
                        {"label": "Bautista", "value": "C86726"},
                        {"label": "Bay", "value": "C86727"},
                        {"label": "Baybayin", "value": "C86728"},
                        {"label": "Biga", "value": "C86729"},
                        {"label": "Bignay Uno", "value": "C86730"},
                        {"label": "Bilaran", "value": "C86731"},
                        {"label": "Bilog-Bilog", "value": "C86732"},
                        {"label": "Binahaan", "value": "C86733"},
                        {"label": "Binan", "value": "C86734"},
                        {"label": "Binangonan", "value": "C86735"},
                        {"label": "Binay", "value": "C86736"},
                        {"label": "Binubusan", "value": "C86737"},
                        {"label": "Binulasan", "value": "C86738"},
                        {"label": "Bitangan", "value": "C86739"},
                        {"label": "Bitin", "value": "C86740"},
                        {"label": "Bolboc", "value": "C86741"},
                        {"label": "Bolo", "value": "C86742"},
                        {"label": "Boot", "value": "C86743"},
                        {"label": "Bosdak", "value": "C86744"},
                        {"label": "Bugaan", "value": "C86745"},
                        {"label": "Bukal", "value": "C86746"},
                        {"label": "Bukal Sur", "value": "C86747"},
                        {"label": "Bulacnin", "value": "C86748"},
                        {"label": "Bulihan", "value": "C86749"},
                        {"label": "Bungahan", "value": "C86750"},
                        {"label": "Bungoy", "value": "C86751"},
                        {"label": "Burdeos", "value": "C86752"},
                        {"label": "Cabanbanan", "value": "C86753"},
                        {"label": "Cabatang", "value": "C86754"},
                        {"label": "Cabay", "value": "C86755"},
                        {"label": "Cabuyao", "value": "C86756"},
                        {"label": "Cagsiay", "value": "C86757"},
                        {"label": "Cainta", "value": "C86758"},
                        {"label": "Calaca", "value": "C86759"},
                        {"label": "Calamba", "value": "C86760"},
                        {"label": "Calantas", "value": "C86761"},
                        {"label": "Calatagan", "value": "C86762"},
                        {"label": "Calauag", "value": "C86763"},
                        {"label": "Calauan", "value": "C86764"},
                        {"label": "Calilayan", "value": "C86765"},
                        {"label": "Calubcub Dos", "value": "C86766"},
                        {"label": "Calumpang", "value": "C86767"},
                        {"label": "Cambuga", "value": "C86768"},
                        {"label": "Camohaguin", "value": "C86769"},
                        {"label": "Camp Flora", "value": "C86770"},
                        {"label": "Candelaria", "value": "C86771"},
                        {"label": "Capuluan", "value": "C86772"},
                        {"label": "Cardona", "value": "C86773"},
                        {"label": "Carmona", "value": "C86774"},
                        {"label": "Casay", "value": "C86775"},
                        {"label": "Castanas", "value": "C86776"},
                        {"label": "Casuguran", "value": "C86777"},
                        {"label": "Catanauan", "value": "C86778"},
                        {"label": "Cavinti", "value": "C86779"},
                        {"label": "Cavite City", "value": "C86780"},
                        {"label": "Cawayan", "value": "C86781"},
                        {"label": "Cigaras", "value": "C86782"},
                        {"label": "Concepcion Ibaba", "value": "C86783"},
                        {"label": "Cuenca", "value": "C86784"},
                        {"label": "Dagatan", "value": "C86785"},
                        {"label": "Daraitan", "value": "C86786"},
                        {"label": "Dasmarinas", "value": "C86787"},
                        {"label": "Dayap", "value": "C86788"},
                        {"label": "Dayapan", "value": "C86789"},
                        {"label": "Del Monte", "value": "C86790"},
                        {"label": "Dinahican", "value": "C86791"},
                        {"label": "Dolores", "value": "C86792"},
                        {"label": "Famy", "value": "C86793"},
                        {"label": "General Emilio Aguinaldo",
                         "value": "C86794"},
                        {"label": "General Luna", "value": "C86795"},
                        {"label": "General Nakar", "value": "C86796"},
                        {"label": "General Trias", "value": "C86797"},
                        {"label": "Guinayangan, Fourth District of Quezon",
                         "value": "C86798"},
                        {"label": "Guisguis", "value": "C86799"},
                        {"label": "Gulod", "value": "C86800"},
                        {"label": "Gumaca", "value": "C86801"},
                        {"label": "Gumian", "value": "C86802"},
                        {"label": "Guyam Malaki", "value": "C86803"},
                        {"label": "Halayhay", "value": "C86804"},
                        {"label": "Halayhayin", "value": "C86805"},
                        {"label": "Haligue", "value": "C86806"},
                        {"label": "Hondagua", "value": "C86807"},
                        {"label": "Hukay", "value": "C86808"},
                        {"label": "Ibaan", "value": "C86809"},
                        {"label": "Ibabang Tayuman", "value": "C86810"},
                        {"label": "Ilihan", "value": "C86811"},
                        {"label": "Imus", "value": "C86812"},
                        {"label": "Indang", "value": "C86813"},
                        {"label": "Infanta", "value": "C86814"},
                        {"label": "Inicbulan", "value": "C86815"},
                        {"label": "Isabang", "value": "C86816"},
                        {"label": "Jalajala", "value": "C86817"},
                        {"label": "Janagdong", "value": "C86818"},
                        {"label": "Janopol", "value": "C86819"},
                        {"label": "Javalera", "value": "C86820"},
                        {"label": "Kabulusan", "value": "C86821"},
                        {"label": "Kalayaan", "value": "C86822"},
                        {"label": "Kanluran", "value": "C86823"},
                        {"label": "Kapatalan", "value": "C86824"},
                        {"label": "Karligan", "value": "C86825"},
                        {"label": "Kawit", "value": "C86826"},
                        {"label": "Kaytitinga", "value": "C86827"},
                        {"label": "Kiloloran", "value": "C86828"},
                        {"label": "Kinalaglagan", "value": "C86829"},
                        {"label": "Kinatakutan", "value": "C86830"},
                        {"label": "Lacdayan", "value": "C86831"},
                        {"label": "Laiya", "value": "C86832"},
                        {"label": "Lalig", "value": "C86833"},
                        {"label": "Lapolapo", "value": "C86834"},
                        {"label": "Laurel", "value": "C86835"},
                        {"label": "Lemery", "value": "C86836"},
                        {"label": "Lian", "value": "C86837"},
                        {"label": "Libato", "value": "C86838"},
                        {"label": "Lilio", "value": "C86839"},
                        {"label": "Lipa City", "value": "C86840"},
                        {"label": "Lipahan", "value": "C86841"},
                        {"label": "Lobo", "value": "C86842"},
                        {"label": "Looc", "value": "C86843"},
                        {"label": "Lopez", "value": "C86844"},
                        {"label": "Los Banos", "value": "C86845"},
                        {"label": "Lucban", "value": "C86846"},
                        {"label": "Lucena", "value": "C86847"},
                        {"label": "Lucsuhin", "value": "C86848"},
                        {"label": "Luisiana", "value": "C86849"},
                        {"label": "Luksuhin", "value": "C86850"},
                        {"label": "Lumbang", "value": "C86851"},
                        {"label": "Lumbangan", "value": "C86852"},
                        {"label": "Lumil", "value": "C86853"},
                        {"label": "Luntal", "value": "C86854"},
                        {"label": "Lusacan", "value": "C86855"},
                        {"label": "Mabini", "value": "C86856"},
                        {"label": "Mabitac", "value": "C86857"},
                        {"label": "Mabunga", "value": "C86858"},
                        {"label": "Macalamcam A", "value": "C86859"},
                        {"label": "Macalelon", "value": "C86860"},
                        {"label": "Madulao", "value": "C86861"},
                        {"label": "Magallanes", "value": "C86862"},
                        {"label": "Magdalena", "value": "C86863"},
                        {"label": "Magsaysay", "value": "C86864"},
                        {"label": "Maguyam", "value": "C86865"},
                        {"label": "Mahabang Parang", "value": "C86866"},
                        {"label": "Mainit Norte", "value": "C86867"},
                        {"label": "Majayjay", "value": "C86868"},
                        {"label": "Malabag", "value": "C86869"},
                        {"label": "Malabanan", "value": "C86870"},
                        {"label": "Malabanban Norte", "value": "C86871"},
                        {"label": "Malainen Luma", "value": "C86872"},
                        {"label": "Malanday", "value": "C86873"},
                        {"label": "Malaruhatan", "value": "C86874"},
                        {"label": "Malaya", "value": "C86875"},
                        {"label": "Malicboy", "value": "C86876"},
                        {"label": "Malinao Ilaya", "value": "C86877"},
                        {"label": "Malvar", "value": "C86878"},
                        {"label": "Mamala", "value": "C86879"},
                        {"label": "Mamatid", "value": "C86880"},
                        {"label": "Mangas", "value": "C86881"},
                        {"label": "Mangero", "value": "C86882"},
                        {"label": "Manggahan", "value": "C86883"},
                        {"label": "Mapulo", "value": "C86884"},
                        {"label": "Mapulot", "value": "C86885"},
                        {"label": "Maragondon", "value": "C86886"},
                        {"label": "Marao", "value": "C86887"},
                        {"label": "Masalukot Uno", "value": "C86888"},
                        {"label": "Masapang", "value": "C86889"},
                        {"label": "Masaya", "value": "C86890"},
                        {"label": "Mataas Na Kahoy", "value": "C86891"},
                        {"label": "Matagbak", "value": "C86892"},
                        {"label": "Matala", "value": "C86893"},
                        {"label": "Mataywanac", "value": "C86894"},
                        {"label": "Matingain", "value": "C86895"},
                        {"label": "Mauban", "value": "C86896"},
                        {"label": "Maugat West", "value": "C86897"},
                        {"label": "Maulawin", "value": "C86898"},
                        {"label": "Mendez-Nunez", "value": "C86899"},
                        {"label": "Montecillo", "value": "C86900"},
                        {"label": "Morong", "value": "C86901"},
                        {"label": "Mozon", "value": "C86902"},
                        {"label": "Mulanay", "value": "C86903"},
                        {"label": "Mulauin", "value": "C86904"},
                        {"label": "Nagcarlan", "value": "C86905"},
                        {"label": "Naic", "value": "C86906"},
                        {"label": "Nasugbu", "value": "C86907"},
                        {"label": "Navotas", "value": "C86908"},
                        {"label": "Noveleta", "value": "C86909"},
                        {"label": "Paagahan", "value": "C86910"},
                        {"label": "Padre Burgos", "value": "C86911"},
                        {"label": "Padre Garcia", "value": "C86912"},
                        {"label": "Paete", "value": "C86913"},
                        {"label": "Pagbilao", "value": "C86914"},
                        {"label": "Pagsangahan", "value": "C86915"},
                        {"label": "Pagsanjan", "value": "C86916"},
                        {"label": "Paiisa", "value": "C86917"},
                        {"label": "Pakil", "value": "C86918"},
                        {"label": "Palahanan Uno", "value": "C86919"},
                        {"label": "Palangue", "value": "C86920"},
                        {"label": "Pangao", "value": "C86921"},
                        {"label": "Pangil", "value": "C86922"},
                        {"label": "Panikihan", "value": "C86923"},
                        {"label": "Pansol", "value": "C86924"},
                        {"label": "Pansoy", "value": "C86925"},
                        {"label": "Pantay Na Matanda", "value": "C86926"},
                        {"label": "Pantijan No 2", "value": "C86927"},
                        {"label": "Panukulan", "value": "C86928"},
                        {"label": "Paradahan", "value": "C86929"},
                        {"label": "Pasong Kawayan Primero", "value": "C86930"},
                        {"label": "Patabog", "value": "C86931"},
                        {"label": "Patnanungan", "value": "C86932"},
                        {"label": "Patuto", "value": "C86933"},
                        {"label": "Payapa", "value": "C86934"},
                        {"label": "Perez", "value": "C86935"},
                        {"label": "Pila", "value": "C86936"},
                        {"label": "Pililla", "value": "C86937"},
                        {"label": "Pinagsibaan", "value": "C86938"},
                        {"label": "Pinugay", "value": "C86939"},
                        {"label": "Pitogo", "value": "C86940"},
                        {"label": "Plaridel", "value": "C86941"},
                        {"label": "Poctol", "value": "C86942"},
                        {"label": "Polillo", "value": "C86943"},
                        {"label": "Prinza", "value": "C86944"},
                        {"label": "Progreso", "value": "C86945"},
                        {"label": "Province of Batangas", "value": "C86946"},
                        {"label": "Province of Cavite", "value": "C86947"},
                        {"label": "Province of Laguna", "value": "C86948"},
                        {"label": "Province of Quezon", "value": "C86949"},
                        {"label": "Province of Rizal", "value": "C86950"},
                        {"label": "Pulangbato", "value": "C86951"},
                        {"label": "Pulo", "value": "C86952"},
                        {"label": "Pulong Santa Cruz", "value": "C86953"},
                        {"label": "Punta", "value": "C86954"},
                        {"label": "Puting Kahoy", "value": "C86955"},
                        {"label": "Putingkahoy", "value": "C86956"},
                        {"label": "Putol", "value": "C86957"},
                        {"label": "Quezon", "value": "C86958"},
                        {"label": "Quilo-quilo", "value": "C86959"},
                        {"label": "Quipot", "value": "C86960"},
                        {"label": "Quisao", "value": "C86961"},
                        {"label": "Real", "value": "C86962"},
                        {"label": "Rizal", "value": "C86963"},
                        {"label": "Rodriguez", "value": "C86964"},
                        {"label": "Rosario", "value": "C86965"},
                        {"label": "Sabang", "value": "C86966"},
                        {"label": "Sampaloc", "value": "C86967"},
                        {"label": "Sampiro", "value": "C86968"},
                        {"label": "San Andres", "value": "C86969"},
                        {"label": "San Antonio", "value": "C86970"},
                        {"label": "San Carlos", "value": "C86971"},
                        {"label": "San Celestio", "value": "C86972"},
                        {"label": "San Diego", "value": "C86973"},
                        {"label": "San Francisco", "value": "C86974"},
                        {"label": "San Gregorio", "value": "C86975"},
                        {"label": "San Isidro", "value": "C86976"},
                        {"label": "San Joaquin", "value": "C86977"},
                        {"label": "San Jose", "value": "C86978"},
                        {"label": "San Juan", "value": "C86979"},
                        {"label": "San Luis", "value": "C86980"},
                        {"label": "San Mateo", "value": "C86981"},
                        {"label": "San Miguel", "value": "C86982"},
                        {"label": "San Narciso", "value": "C86983"},
                        {"label": "San Nicolas", "value": "C86984"},
                        {"label": "San Pablo", "value": "C86985"},
                        {"label": "San Pascual", "value": "C86986"},
                        {"label": "San Pedro", "value": "C86987"},
                        {"label": "San Pedro One", "value": "C86988"},
                        {"label": "San Rafael", "value": "C86989"},
                        {"label": "San Vicente", "value": "C86990"},
                        {"label": "Santa Catalina", "value": "C86991"},
                        {"label": "Santa Catalina Norte", "value": "C86992"},
                        {"label": "Santa Catalina Sur", "value": "C86993"},
                        {"label": "Santa Cecilia", "value": "C86994"},
                        {"label": "Santa Clara", "value": "C86995"},
                        {"label": "Santa Cruz", "value": "C86996"},
                        {"label": "Santa Lucia", "value": "C86997"},
                        {"label": "Santa Maria", "value": "C86998"},
                        {"label": "Santa Rita Aplaya", "value": "C86999"},
                        {"label": "Santa Rosa", "value": "C87000"},
                        {"label": "Santa Teresita", "value": "C87001"},
                        {"label": "Santiago", "value": "C87002"},
                        {"label": "Santisimo Rosario", "value": "C87003"},
                        {"label": "Santo Nino", "value": "C87004"},
                        {"label": "Santo Tomas", "value": "C87005"},
                        {"label": "Santor", "value": "C87006"},
                        {"label": "Sariaya", "value": "C87007"},
                        {"label": "Sibulan", "value": "C87008"},
                        {"label": "Sico Uno", "value": "C87009"},
                        {"label": "Silang", "value": "C87010"},
                        {"label": "Silongin", "value": "C87011"},
                        {"label": "Sinala", "value": "C87012"},
                        {"label": "Siniloan", "value": "C87013"},
                        {"label": "Sinisian", "value": "C87014"},
                        {"label": "Solo", "value": "C87015"},
                        {"label": "Subic", "value": "C87016"},
                        {"label": "Taal", "value": "C87017"},
                        {"label": "Tagaytay", "value": "C87018"},
                        {"label": "Tagbacan Ibaba", "value": "C87019"},
                        {"label": "Tagkawayan Sabang", "value": "C87020"},
                        {"label": "Tala", "value": "C87021"},
                        {"label": "Talaga", "value": "C87022"},
                        {"label": "Talahib Payap", "value": "C87023"},
                        {"label": "Talahiban I", "value": "C87024"},
                        {"label": "Talaibon", "value": "C87025"},
                        {"label": "Talipan", "value": "C87026"},
                        {"label": "Talisay", "value": "C87027"},
                        {"label": "Tanauan", "value": "C87028"},
                        {"label": "Tanay", "value": "C87029"},
                        {"label": "Tanza", "value": "C87030"},
                        {"label": "Tayabas", "value": "C87031"},
                        {"label": "Tayabas Ibaba", "value": "C87032"},
                        {"label": "Taysan", "value": "C87033"},
                        {"label": "Taytay", "value": "C87034"},
                        {"label": "Taywanak Ilaya", "value": "C87035"},
                        {"label": "Teresa", "value": "C87036"},
                        {"label": "Ternate", "value": "C87037"},
                        {"label": "Tiaong", "value": "C87038"},
                        {"label": "Tignoan", "value": "C87039"},
                        {"label": "Tingloy", "value": "C87040"},
                        {"label": "Tipaz", "value": "C87041"},
                        {"label": "Toong", "value": "C87042"},
                        {"label": "Tranca", "value": "C87043"},
                        {"label": "Tuhian", "value": "C87044"},
                        {"label": "Tulay", "value": "C87045"},
                        {"label": "Tumalim", "value": "C87046"},
                        {"label": "Tuy", "value": "C87047"},
                        {"label": "Unisan", "value": "C87048"},
                        {"label": "Victoria", "value": "C87049"},
                        {"label": "Wawa", "value": "C87050"},
                        {"label": "Yuni", "value": "C87051"}],
 "Occitanie": [{"label": "Abeilhan", "value": "C32098"},
               {"label": "Agde", "value": "C32099"},
               {"label": "Aiguefonde", "value": "C32100"},
               {"label": "Aigues-Mortes", "value": "C32101"},
               {"label": "Aigues-Vives", "value": "C32102"},
               {"label": "Aimargues", "value": "C32103"},
               {"label": "Albi", "value": "C32104"},
               {"label": "Albias", "value": "C32105"},
               {"label": "Alenya", "value": "C32106"},
               {"label": "Ales", "value": "C32107"},
               {"label": "Alignan-du-Vent", "value": "C32108"},
               {"label": "Alzonne", "value": "C32109"},
               {"label": "Andrest", "value": "C32110"},
               {"label": "Anduze", "value": "C32111"},
               {"label": "Aniane", "value": "C32112"},
               {"label": "Aramon", "value": "C32113"},
               {"label": "Argelers", "value": "C32114"},
               {"label": "Argeles-Gazost", "value": "C32115"},
               {"label": "Argeliers", "value": "C32116"},
               {"label": "Arles", "value": "C32117"},
               {"label": "Armissan", "value": "C32118"},
               {"label": "Arthes", "value": "C32119"},
               {"label": "Arzens", "value": "C32120"},
               {"label": "Aspet", "value": "C32121"},
               {"label": "Aspiran", "value": "C32122"},
               {"label": "Assas", "value": "C32123"},
               {"label": "Aubais", "value": "C32124"},
               {"label": "Aubiet", "value": "C32125"},
               {"label": "Aubin", "value": "C32126"},
               {"label": "Aubord", "value": "C32127"},
               {"label": "Aucamville", "value": "C32128"},
               {"label": "Auch", "value": "C32129"},
               {"label": "Aumont-Aubrac", "value": "C32130"},
               {"label": "Aureilhan", "value": "C32131"},
               {"label": "Auriac-sur-Vendinelle", "value": "C32132"},
               {"label": "Aurignac", "value": "C32133"},
               {"label": "Aussillon", "value": "C32134"},
               {"label": "Aussonne", "value": "C32135"},
               {"label": "Auterive", "value": "C32136"},
               {"label": "Auvillar", "value": "C32137"},
               {"label": "Auzeville-Tolosane", "value": "C32138"},
               {"label": "Auzielle", "value": "C32139"},
               {"label": "Aveze", "value": "C32140"},
               {"label": "Avignonet-Lauragais", "value": "C32141"},
               {"label": "Ax-les-Thermes", "value": "C32142"},
               {"label": "Ayguesvives", "value": "C32143"},
               {"label": "Azille", "value": "C32144"},
               {"label": "Bagard", "value": "C32145"},
               {"label": "Bages", "value": "C32146"},
               {"label": "Bagnac-sur-Cele", "value": "C32147"},
               {"label": "Bagneres-de-Bigorre", "value": "C32148"},
               {"label": "Bagneres-de-Luchon", "value": "C32149"},
               {"label": "Bagnols-sur-Ceze", "value": "C32150"},
               {"label": "Baho", "value": "C32151"},
               {"label": "Baillargues", "value": "C32152"},
               {"label": "Baixas", "value": "C32153"},
               {"label": "Balaruc-le-Vieux", "value": "C32154"},
               {"label": "Balaruc-les-Bains", "value": "C32155"},
               {"label": "Balma", "value": "C32156"},
               {"label": "Banyuls de la Marenda", "value": "C32157"},
               {"label": "Banyuls-dels-Aspres", "value": "C32158"},
               {"label": "Baraqueville", "value": "C32159"},
               {"label": "Barbazan-Debat", "value": "C32160"},
               {"label": "Barcelonne-du-Gers", "value": "C32161"},
               {"label": "Barjac", "value": "C32162"},
               {"label": "Bassan", "value": "C32163"},
               {"label": "Bazet", "value": "C32164"},
               {"label": "Baziege", "value": "C32165"},
               {"label": "Beaucaire", "value": "C32166"},
               {"label": "Beaulieu", "value": "C32167"},
               {"label": "Beaumont-de-Lomagne", "value": "C32168"},
               {"label": "Beaumont-sur-Leze", "value": "C32169"},
               {"label": "Beaupuy", "value": "C32170"},
               {"label": "Beauvoisin", "value": "C32171"},
               {"label": "Beauzelle", "value": "C32172"},
               {"label": "Bedarieux", "value": "C32173"},
               {"label": "Belberaud", "value": "C32174"},
               {"label": "Belesta", "value": "C32175"},
               {"label": "Bellegarde", "value": "C32176"},
               {"label": "Belmont-sur-Rance", "value": "C32177"},
               {"label": "Belpech", "value": "C32178"},
               {"label": "Berat", "value": "C32179"},
               {"label": "Bernis", "value": "C32180"},
               {"label": "Bessan", "value": "C32181"},
               {"label": "Besseges", "value": "C32182"},
               {"label": "Bessieres", "value": "C32183"},
               {"label": "Beziers", "value": "C32184"},
               {"label": "Bezouce", "value": "C32185"},
               {"label": "Biars-sur-Cere", "value": "C32186"},
               {"label": "Bizanet", "value": "C32187"},
               {"label": "Bize-Minervois", "value": "C32188"},
               {"label": "Blagnac", "value": "C32189"},
               {"label": "Blauzac", "value": "C32190"},
               {"label": "Blaye-les-Mines", "value": "C32191"},
               {"label": "Boisseron", "value": "C32192"},
               {"label": "Boisset-et-Gaujac", "value": "C32193"},
               {"label": "Bompas", "value": "C32194"},
               {"label": "Bouillargues", "value": "C32195"},
               {"label": "Boujan-sur-Libron", "value": "C32196"},
               {"label": "Bouloc", "value": "C32197"},
               {"label": "Boulogne-sur-Gesse", "value": "C32198"},
               {"label": "Bout-du-Pont-de-Larn", "value": "C32199"},
               {"label": "Bouzigues", "value": "C32200"},
               {"label": "Bozouls", "value": "C32201"},
               {"label": "Bram", "value": "C32202"},
               {"label": "Branoux-les-Taillades", "value": "C32203"},
               {"label": "Brassac", "value": "C32204"},
               {"label": "Brax", "value": "C32205"},
               {"label": "Brens", "value": "C32206"},
               {"label": "Bressols", "value": "C32207"},
               {"label": "Bretenoux", "value": "C32208"},
               {"label": "Briatexte", "value": "C32209"},
               {"label": "Bruguieres", "value": "C32210"},
               {"label": "Burlats", "value": "C32211"},
               {"label": "Buzet-sur-Tarn", "value": "C32212"},
               {"label": "Cabestany", "value": "C32213"},
               {"label": "Cabrieres", "value": "C32214"},
               {"label": "Cadalen", "value": "C32215"},
               {"label": "Cagnac-les-Mines", "value": "C32216"},
               {"label": "Cahors", "value": "C32217"},
               {"label": "Cahuzac-sur-Vere", "value": "C32218"},
               {"label": "Caissargues", "value": "C32219"},
               {"label": "Cajarc", "value": "C32220"},
               {"label": "Calmont", "value": "C32221"},
               {"label": "Calvisson", "value": "C32222"},
               {"label": "Camares", "value": "C32223"},
               {"label": "Campan", "value": "C32224"},
               {"label": "Campsas", "value": "C32225"},
               {"label": "Candillargues", "value": "C32226"},
               {"label": "Canet", "value": "C32227"},
               {"label": "Canet-en-Roussillon", "value": "C32228"},
               {"label": "Canohes", "value": "C32229"},
               {"label": "Capdenac-Gare", "value": "C32230"},
               {"label": "Capendu", "value": "C32231"},
               {"label": "Capestang", "value": "C32232"},
               {"label": "Capvern", "value": "C32233"},
               {"label": "Caraman", "value": "C32234"},
               {"label": "Carbonne", "value": "C32235"},
               {"label": "Carcassonne", "value": "C32236"},
               {"label": "Carmaux", "value": "C32237"},
               {"label": "Cassagnes-Begonhes", "value": "C32238"},
               {"label": "Castanet-Tolosan", "value": "C32239"},
               {"label": "Castelginest", "value": "C32240"},
               {"label": "Castelmaurou", "value": "C32241"},
               {"label": "Castelnau-dEstretefonds", "value": "C32242"},
               {"label": "Castelnau-de-Guers", "value": "C32243"},
               {"label": "Castelnau-de-Levis", "value": "C32244"},
               {"label": "Castelnau-le-Lez", "value": "C32245"},
               {"label": "Castelnau-Montratier", "value": "C32246"},
               {"label": "Castelnaudary", "value": "C32247"},
               {"label": "Castelsarrasin", "value": "C32248"},
               {"label": "Castillon-du-Gard", "value": "C32249"},
               {"label": "Castres", "value": "C32250"},
               {"label": "Castries", "value": "C32251"},
               {"label": "Caunes-Minervois", "value": "C32252"},
               {"label": "Caussade", "value": "C32253"},
               {"label": "Cauterets", "value": "C32254"},
               {"label": "Caux", "value": "C32255"},
               {"label": "Caveirac", "value": "C32256"},
               {"label": "Caylus", "value": "C32257"},
               {"label": "Cazaubon", "value": "C32258"},
               {"label": "Cazeres", "value": "C32259"},
               {"label": "Cazes-Mondenard", "value": "C32260"},
               {"label": "Cazilhac", "value": "C32261"},
               {"label": "Cazouls-les-Beziers", "value": "C32262"},
               {"label": "Cendras", "value": "C32263"},
               {"label": "Cepet", "value": "C32264"},
               {"label": "Ceret", "value": "C32265"},
               {"label": "Cers", "value": "C32266"},
               {"label": "Cervera de la Marenda", "value": "C32267"},
               {"label": "Cessenon-sur-Orb", "value": "C32268"},
               {"label": "Chalabre", "value": "C32269"},
               {"label": "Chanac", "value": "C32270"},
               {"label": "Chirac", "value": "C32271"},
               {"label": "Cintegabelle", "value": "C32272"},
               {"label": "Claira", "value": "C32273"},
               {"label": "Clapiers", "value": "C32274"},
               {"label": "Clarensac", "value": "C32275"},
               {"label": "Claret", "value": "C32276"},
               {"label": "Codognan", "value": "C32277"},
               {"label": "Collioure", "value": "C32278"},
               {"label": "Colombiers", "value": "C32279"},
               {"label": "Colombies", "value": "C32280"},
               {"label": "Colomiers", "value": "C32281"},
               {"label": "Combaillaux", "value": "C32282"},
               {"label": "Comps", "value": "C32283"},
               {"label": "Condom", "value": "C32284"},
               {"label": "Congenies", "value": "C32285"},
               {"label": "Connaux", "value": "C32286"},
               {"label": "Conques-sur-Orbiel", "value": "C32287"},
               {"label": "Corbarieu", "value": "C32288"},
               {"label": "Cordes-sur-Ciel", "value": "C32289"},
               {"label": "Cornebarrieu", "value": "C32290"},
               {"label": "Corneilhan", "value": "C32291"},
               {"label": "Corneilla-del-Vercol", "value": "C32292"},
               {"label": "Corneilla-la-Riviere", "value": "C32293"},
               {"label": "Coufouleux", "value": "C32294"},
               {"label": "Couiza", "value": "C32295"},
               {"label": "Cournonsec", "value": "C32296"},
               {"label": "Cournonterral", "value": "C32297"},
               {"label": "Coursan", "value": "C32298"},
               {"label": "Cransac", "value": "C32299"},
               {"label": "Creissan", "value": "C32300"},
               {"label": "Creissels", "value": "C32301"},
               {"label": "Cugnaux", "value": "C32302"},
               {"label": "Cunac", "value": "C32303"},
               {"label": "Cuxac-Cabardes", "value": "C32304"},
               {"label": "Daux", "value": "C32305"},
               {"label": "Decazeville", "value": "C32306"},
               {"label": "Departement de lAriege", "value": "C32307"},
               {"label": "Departement de lAude", "value": "C32308"},
               {"label": "Departement de lAveyron", "value": "C32309"},
               {"label": "Departement de lHerault", "value": "C32310"},
               {"label": "Departement de la Lozere", "value": "C32311"},
               {"label": "Departement des Hautes-Pyrenees", "value": "C32312"},
               {"label": "Departement des Pyrenees-Orientales",
                "value": "C32313"},
               {"label": "Departement du Tarn-et-Garonne", "value": "C32314"},
               {"label": "Dourgne", "value": "C32315"},
               {"label": "Dremil-Lafage", "value": "C32316"},
               {"label": "Druelle", "value": "C32317"},
               {"label": "Eaunes", "value": "C32318"},
               {"label": "Eauze", "value": "C32319"},
               {"label": "el Volo", "value": "C32320"},
               {"label": "Elne", "value": "C32321"},
               {"label": "els Banys dArles", "value": "C32322"},
               {"label": "Entraygues-sur-Truyere", "value": "C32323"},
               {"label": "Escalquens", "value": "C32324"},
               {"label": "Espalion", "value": "C32325"},
               {"label": "Esperaza", "value": "C32326"},
               {"label": "Estagel", "value": "C32327"},
               {"label": "Fabregues", "value": "C32328"},
               {"label": "Fabrezan", "value": "C32329"},
               {"label": "Fenouillet", "value": "C32330"},
               {"label": "Ferrals-les-Corbieres", "value": "C32331"},
               {"label": "Figeac", "value": "C32332"},
               {"label": "Finhan", "value": "C32333"},
               {"label": "Firmi", "value": "C32334"},
               {"label": "Flavin", "value": "C32335"},
               {"label": "Fleurance", "value": "C32336"},
               {"label": "Fleury", "value": "C32337"},
               {"label": "Florac", "value": "C32338"},
               {"label": "Florensac", "value": "C32339"},
               {"label": "Flourens", "value": "C32340"},
               {"label": "Foix", "value": "C32341"},
               {"label": "Fonbeauzard", "value": "C32342"},
               {"label": "Fonsorbes", "value": "C32343"},
               {"label": "Font-Romeu-Odeillo-Via", "value": "C32344"},
               {"label": "Fontenilles", "value": "C32345"},
               {"label": "Fourques", "value": "C32346"},
               {"label": "Frejairolles", "value": "C32347"},
               {"label": "Frontignan", "value": "C32348"},
               {"label": "Fronton", "value": "C32349"},
               {"label": "Frouzins", "value": "C32350"},
               {"label": "Gagnac-sur-Garonne", "value": "C32351"},
               {"label": "Gaillac", "value": "C32352"},
               {"label": "Gaillac-Toulza", "value": "C32353"},
               {"label": "Gallargues-le-Montueux", "value": "C32354"},
               {"label": "Ganges", "value": "C32355"},
               {"label": "Gard", "value": "C32356"},
               {"label": "Gardouch", "value": "C32357"},
               {"label": "Garidech", "value": "C32358"},
               {"label": "Garons", "value": "C32359"},
               {"label": "Generac", "value": "C32360"},
               {"label": "Gerde", "value": "C32361"},
               {"label": "Gers", "value": "C32362"},
               {"label": "Gigean", "value": "C32363"},
               {"label": "Gignac", "value": "C32364"},
               {"label": "Gimont", "value": "C32365"},
               {"label": "Ginestas", "value": "C32366"},
               {"label": "Giroussens", "value": "C32367"},
               {"label": "Gondrin", "value": "C32368"},
               {"label": "Goudargues", "value": "C32369"},
               {"label": "Gourdan-Polignan", "value": "C32370"},
               {"label": "Gourdon", "value": "C32371"},
               {"label": "Grabels", "value": "C32372"},
               {"label": "Gragnague", "value": "C32373"},
               {"label": "Gramat", "value": "C32374"},
               {"label": "Gratentour", "value": "C32375"},
               {"label": "Graulhet", "value": "C32376"},
               {"label": "Grenade", "value": "C32377"},
               {"label": "Grisolles", "value": "C32378"},
               {"label": "Gruissan", "value": "C32379"},
               {"label": "Haute-Garonne", "value": "C32380"},
               {"label": "Herepian", "value": "C32381"},
               {"label": "Horgues", "value": "C32382"},
               {"label": "Ibos", "value": "C32383"},
               {"label": "Ille-sur-Tet", "value": "C32384"},
               {"label": "Jacou", "value": "C32385"},
               {"label": "Jegun", "value": "C32386"},
               {"label": "Jonquieres-Saint-Vincent", "value": "C32387"},
               {"label": "Juillan", "value": "C32388"},
               {"label": "Juvignac", "value": "C32389"},
               {"label": "LUnion", "value": "C32390"},
               {"label": "LIsle-en-Dodon", "value": "C32391"},
               {"label": "LIsle-Jourdain", "value": "C32392"},
               {"label": "La Barthe-de-Neste", "value": "C32393"},
               {"label": "La Calmette", "value": "C32394"},
               {"label": "La Canourgue", "value": "C32395"},
               {"label": "La Cavalerie", "value": "C32396"},
               {"label": "La Fouillade", "value": "C32397"},
               {"label": "La Grande-Motte", "value": "C32398"},
               {"label": "la Guingueta dIx", "value": "C32399"},
               {"label": "La Loubiere", "value": "C32400"},
               {"label": "La Palme", "value": "C32401"},
               {"label": "la Roca dAlbera", "value": "C32402"},
               {"label": "La Salvetat-Peyrales", "value": "C32403"},
               {"label": "La Salvetat-Saint-Gilles", "value": "C32404"},
               {"label": "La Salvetat-sur-Agout", "value": "C32405"},
               {"label": "La Tour-du-Crieu", "value": "C32406"},
               {"label": "La Tour-sur-Orb", "value": "C32407"},
               {"label": "Labarthe-Riviere", "value": "C32408"},
               {"label": "Labarthe-sur-Leze", "value": "C32409"},
               {"label": "Labastide-Beauvoir", "value": "C32410"},
               {"label": "Labastide-Rouairoux", "value": "C32411"},
               {"label": "Labastide-Saint-Georges", "value": "C32412"},
               {"label": "Labastide-Saint-Pierre", "value": "C32413"},
               {"label": "Labastide-Saint-Sernin", "value": "C32414"},
               {"label": "Labastidette", "value": "C32415"},
               {"label": "Labege", "value": "C32416"},
               {"label": "Labruguiere", "value": "C32417"},
               {"label": "Lacapelle-Marival", "value": "C32418"},
               {"label": "Lacasse", "value": "C32419"},
               {"label": "Lacaune", "value": "C32420"},
               {"label": "Lacroix-Falgarde", "value": "C32421"},
               {"label": "Lacrouzette", "value": "C32422"},
               {"label": "Lafrancaise", "value": "C32423"},
               {"label": "Lagardelle-sur-Leze", "value": "C32424"},
               {"label": "Lagarrigue", "value": "C32425"},
               {"label": "Lagrave", "value": "C32426"},
               {"label": "Laguiole", "value": "C32427"},
               {"label": "Laissac", "value": "C32428"},
               {"label": "Lalbenque", "value": "C32429"},
               {"label": "Laloubere", "value": "C32430"},
               {"label": "Lamagistere", "value": "C32431"},
               {"label": "Lamalou-les-Bains", "value": "C32432"},
               {"label": "Langlade", "value": "C32433"},
               {"label": "Langogne", "value": "C32434"},
               {"label": "Lannemezan", "value": "C32435"},
               {"label": "Lansargues", "value": "C32436"},
               {"label": "Lanta", "value": "C32437"},
               {"label": "Lapeyrouse-Fossat", "value": "C32438"},
               {"label": "Laroque", "value": "C32439"},
               {"label": "Laroque-dOlmes", "value": "C32440"},
               {"label": "Lasalle", "value": "C32441"},
               {"label": "Latour-Bas-Elne", "value": "C32442"},
               {"label": "Lattes", "value": "C32443"},
               {"label": "Laudun-lArdoise", "value": "C32444"},
               {"label": "Launac", "value": "C32445"},
               {"label": "Launaguet", "value": "C32446"},
               {"label": "Laure-Minervois", "value": "C32447"},
               {"label": "Laurens", "value": "C32448"},
               {"label": "Lautrec", "value": "C32449"},
               {"label": "Lauzerte", "value": "C32450"},
               {"label": "Laval-Pradel", "value": "C32451"},
               {"label": "Lavalette", "value": "C32452"},
               {"label": "Lavaur", "value": "C32453"},
               {"label": "Lavelanet", "value": "C32454"},
               {"label": "Lavernose-Lacasse", "value": "C32455"},
               {"label": "Laverune", "value": "C32456"},
               {"label": "Lavit", "value": "C32457"},
               {"label": "Le Barcares", "value": "C32458"},
               {"label": "Le Bousquet-dOrb", "value": "C32459"},
               {"label": "Le Cailar", "value": "C32460"},
               {"label": "Le Cap dAgde", "value": "C32461"},
               {"label": "Le Cres", "value": "C32462"},
               {"label": "Le Fauga", "value": "C32463"},
               {"label": "Le Fousseret", "value": "C32464"},
               {"label": "Le Garric", "value": "C32465"},
               {"label": "Le Grau-du-Roi", "value": "C32466"},
               {"label": "Le Houga", "value": "C32467"},
               {"label": "Le Malzieu-Ville", "value": "C32468"},
               {"label": "Le Monastere", "value": "C32469"},
               {"label": "Le Pouget", "value": "C32470"},
               {"label": "Le Poujol-sur-Orb", "value": "C32471"},
               {"label": "Le Sequestre", "value": "C32472"},
               {"label": "Le Soler", "value": "C32473"},
               {"label": "Le Vigan", "value": "C32474"},
               {"label": "Lectoure", "value": "C32475"},
               {"label": "Ledenon", "value": "C32476"},
               {"label": "Ledignan", "value": "C32477"},
               {"label": "Leguevin", "value": "C32478"},
               {"label": "Les Angles", "value": "C32479"},
               {"label": "Les Mages", "value": "C32480"},
               {"label": "Les Matelles", "value": "C32481"},
               {"label": "Les Salles-du-Gardon", "value": "C32482"},
               {"label": "Lespignan", "value": "C32483"},
               {"label": "Lespinasse", "value": "C32484"},
               {"label": "Leucate", "value": "C32485"},
               {"label": "Levignac", "value": "C32486"},
               {"label": "Leyme", "value": "C32487"},
               {"label": "Lezan", "value": "C32488"},
               {"label": "Lezat-sur-Leze", "value": "C32489"},
               {"label": "Lezignan-Corbieres", "value": "C32490"},
               {"label": "Lezignan-la-Cebe", "value": "C32491"},
               {"label": "Lherm", "value": "C32492"},
               {"label": "Lieuran-les-Beziers", "value": "C32493"},
               {"label": "Lignan-sur-Orb", "value": "C32494"},
               {"label": "Limoux", "value": "C32495"},
               {"label": "Lisle-sur-Tarn", "value": "C32496"},
               {"label": "Livinhac-le-Haut", "value": "C32497"},
               {"label": "Llupia", "value": "C32498"},
               {"label": "Lodeve", "value": "C32499"},
               {"label": "Lombez", "value": "C32500"},
               {"label": "Longages", "value": "C32501"},
               {"label": "Lot", "value": "C32502"},
               {"label": "Loupian", "value": "C32503"},
               {"label": "Lourdes", "value": "C32504"},
               {"label": "Luc-la-Primaube", "value": "C32505"},
               {"label": "Lunel", "value": "C32506"},
               {"label": "Lunel-Viel", "value": "C32507"},
               {"label": "Luz-Saint-Sauveur", "value": "C32508"},
               {"label": "Luzech", "value": "C32509"},
               {"label": "Magalas", "value": "C32510"},
               {"label": "Manduel", "value": "C32511"},
               {"label": "Maraussan", "value": "C32512"},
               {"label": "Marciac", "value": "C32513"},
               {"label": "Marcillac-Vallon", "value": "C32514"},
               {"label": "Marcorignan", "value": "C32515"},
               {"label": "Marguerittes", "value": "C32516"},
               {"label": "Marquefave", "value": "C32517"},
               {"label": "Marseillan", "value": "C32518"},
               {"label": "Marsillargues", "value": "C32519"},
               {"label": "Marssac-sur-Tarn", "value": "C32520"},
               {"label": "Martel", "value": "C32521"},
               {"label": "Martres-Tolosane", "value": "C32522"},
               {"label": "Marvejols", "value": "C32523"},
               {"label": "Masseube", "value": "C32524"},
               {"label": "Maubourguet", "value": "C32525"},
               {"label": "Mauguio", "value": "C32526"},
               {"label": "Maureilhan", "value": "C32527"},
               {"label": "Maureillas-las-Illas", "value": "C32528"},
               {"label": "Mauvezin", "value": "C32529"},
               {"label": "Mazamet", "value": "C32530"},
               {"label": "Mazeres", "value": "C32531"},
               {"label": "Mende", "value": "C32532"},
               {"label": "Mercus-Garrabet", "value": "C32533"},
               {"label": "Merville", "value": "C32534"},
               {"label": "Meynes", "value": "C32535"},
               {"label": "Meyrueis", "value": "C32536"},
               {"label": "Meze", "value": "C32537"},
               {"label": "Mielan", "value": "C32538"},
               {"label": "Milhaud", "value": "C32539"},
               {"label": "Millas", "value": "C32540"},
               {"label": "Millau", "value": "C32541"},
               {"label": "Mirande", "value": "C32542"},
               {"label": "Mirandol-Bourgnounac", "value": "C32543"},
               {"label": "Miremont", "value": "C32544"},
               {"label": "Mirepoix", "value": "C32545"},
               {"label": "Mireval", "value": "C32546"},
               {"label": "Moissac", "value": "C32547"},
               {"label": "Molieres", "value": "C32548"},
               {"label": "Molieres-sur-Ceze", "value": "C32549"},
               {"label": "Monclar-de-Quercy", "value": "C32550"},
               {"label": "Mondonville", "value": "C32551"},
               {"label": "Monesties", "value": "C32552"},
               {"label": "Mons", "value": "C32553"},
               {"label": "Montady", "value": "C32554"},
               {"label": "Montagnac", "value": "C32555"},
               {"label": "Montaigu-de-Quercy", "value": "C32556"},
               {"label": "Montaigut-sur-Save", "value": "C32557"},
               {"label": "Montans", "value": "C32558"},
               {"label": "Montaren-et-Saint-Mediers", "value": "C32559"},
               {"label": "Montarnaud", "value": "C32560"},
               {"label": "Montastruc-la-Conseillere", "value": "C32561"},
               {"label": "Montauban", "value": "C32562"},
               {"label": "Montbazens", "value": "C32563"},
               {"label": "Montbazin", "value": "C32564"},
               {"label": "Montberon", "value": "C32565"},
               {"label": "Montbeton", "value": "C32566"},
               {"label": "Montblanc", "value": "C32567"},
               {"label": "Montcuq", "value": "C32568"},
               {"label": "Montech", "value": "C32569"},
               {"label": "Monteils", "value": "C32570"},
               {"label": "Montescot", "value": "C32571"},
               {"label": "Montesquieu-Volvestre", "value": "C32572"},
               {"label": "Montesquiu dAlbera", "value": "C32573"},
               {"label": "Montfaucon", "value": "C32574"},
               {"label": "Montferrier-sur-Lez", "value": "C32575"},
               {"label": "Montfrin", "value": "C32576"},
               {"label": "Montgaillard", "value": "C32577"},
               {"label": "Montgiscard", "value": "C32578"},
               {"label": "Montjoie-en-Couserans", "value": "C32579"},
               {"label": "Montjoire", "value": "C32580"},
               {"label": "Montlaur", "value": "C32581"},
               {"label": "Montpellier", "value": "C32582"},
               {"label": "Montpeyroux", "value": "C32583"},
               {"label": "Montpezat-de-Quercy", "value": "C32584"},
               {"label": "Montrabe", "value": "C32585"},
               {"label": "Montreal", "value": "C32586"},
               {"label": "Montredon-Labessonnie", "value": "C32587"},
               {"label": "Montrejeau", "value": "C32588"},
               {"label": "Montricoux", "value": "C32589"},
               {"label": "Montrodat", "value": "C32590"},
               {"label": "Moussac", "value": "C32591"},
               {"label": "Moussan", "value": "C32592"},
               {"label": "Moyrazes", "value": "C32593"},
               {"label": "Mudaison", "value": "C32594"},
               {"label": "Muret", "value": "C32595"},
               {"label": "Murviel-les-Beziers", "value": "C32596"},
               {"label": "Murviel-les-Montpellier", "value": "C32597"},
               {"label": "Mus", "value": "C32598"},
               {"label": "Nages-et-Solorgues", "value": "C32599"},
               {"label": "Nailloux", "value": "C32600"},
               {"label": "Narbonne", "value": "C32601"},
               {"label": "Naucelle", "value": "C32602"},
               {"label": "Nebian", "value": "C32603"},
               {"label": "Negrepelisse", "value": "C32604"},
               {"label": "Nevian", "value": "C32605"},
               {"label": "Nimes", "value": "C32606"},
               {"label": "Nissan-lez-Enserune", "value": "C32607"},
               {"label": "Noe", "value": "C32608"},
               {"label": "Nogaro", "value": "C32609"},
               {"label": "Nohic", "value": "C32610"},
               {"label": "Odos", "value": "C32611"},
               {"label": "Olemps", "value": "C32612"},
               {"label": "Olonzac", "value": "C32613"},
               {"label": "Ondes", "value": "C32614"},
               {"label": "Orgueil", "value": "C32615"},
               {"label": "Orleix", "value": "C32616"},
               {"label": "Ornaisons", "value": "C32617"},
               {"label": "Orsan", "value": "C32618"},
               {"label": "Ortaffa", "value": "C32619"},
               {"label": "Osseja", "value": "C32620"},
               {"label": "Ossun", "value": "C32621"},
               {"label": "Oursbelille", "value": "C32622"},
               {"label": "Ouveillan", "value": "C32623"},
               {"label": "Palaja", "value": "C32624"},
               {"label": "Palau-del-Vidre", "value": "C32625"},
               {"label": "Palavas-les-Flots", "value": "C32626"},
               {"label": "Pamiers", "value": "C32627"},
               {"label": "Paulhac", "value": "C32628"},
               {"label": "Paulhan", "value": "C32629"},
               {"label": "Pavie", "value": "C32630"},
               {"label": "Payrin-Augmontel", "value": "C32631"},
               {"label": "Pechabou", "value": "C32632"},
               {"label": "Pechbonnieu", "value": "C32633"},
               {"label": "Pennautier", "value": "C32634"},
               {"label": "Pepieux", "value": "C32635"},
               {"label": "Perols", "value": "C32636"},
               {"label": "Perpignan", "value": "C32637"},
               {"label": "Peyrestortes", "value": "C32638"},
               {"label": "Peyriac-de-Mer", "value": "C32639"},
               {"label": "Peyriac-Minervois", "value": "C32640"},
               {"label": "Pezenas", "value": "C32641"},
               {"label": "Pezens", "value": "C32642"},
               {"label": "Pezilla-la-Riviere", "value": "C32643"},
               {"label": "Pia", "value": "C32644"},
               {"label": "Pibrac", "value": "C32645"},
               {"label": "Pierrefitte-Nestalas", "value": "C32646"},
               {"label": "Pignan", "value": "C32647"},
               {"label": "Pinet", "value": "C32648"},
               {"label": "Pinsaguel", "value": "C32649"},
               {"label": "Plaisance", "value": "C32650"},
               {"label": "Plaisance-du-Touch", "value": "C32651"},
               {"label": "Pollestres", "value": "C32652"},
               {"label": "Pomerols", "value": "C32653"},
               {"label": "Pompertuzat", "value": "C32654"},
               {"label": "Pompignan", "value": "C32655"},
               {"label": "Pont-de-Larn", "value": "C32656"},
               {"label": "Pont-de-Salars", "value": "C32657"},
               {"label": "Pont-Saint-Esprit", "value": "C32658"},
               {"label": "Ponteilla", "value": "C32659"},
               {"label": "Port-la-Nouvelle", "value": "C32660"},
               {"label": "Portel-des-Corbieres", "value": "C32661"},
               {"label": "Portet-sur-Garonne", "value": "C32662"},
               {"label": "Portiragnes", "value": "C32663"},
               {"label": "Portvendres", "value": "C32664"},
               {"label": "Poulx", "value": "C32665"},
               {"label": "Poussan", "value": "C32666"},
               {"label": "Pouzac", "value": "C32667"},
               {"label": "Prades", "value": "C32668"},
               {"label": "Prades-le-Lez", "value": "C32669"},
               {"label": "Pradines", "value": "C32670"},
               {"label": "Prats de Mollo", "value": "C32671"},
               {"label": "Prayssac", "value": "C32672"},
               {"label": "Preignan", "value": "C32673"},
               {"label": "Puicheric", "value": "C32674"},
               {"label": "Puissalicon", "value": "C32675"},
               {"label": "Puisserguier", "value": "C32676"},
               {"label": "Pujaudran", "value": "C32677"},
               {"label": "Pujaut", "value": "C32678"},
               {"label": "Puy-leveque", "value": "C32679"},
               {"label": "Puygouzon", "value": "C32680"},
               {"label": "Puylaurens", "value": "C32681"},
               {"label": "Quarante", "value": "C32682"},
               {"label": "Quillan", "value": "C32683"},
               {"label": "Quissac", "value": "C32684"},
               {"label": "Rabastens", "value": "C32685"},
               {"label": "Rabastens-de-Bigorre", "value": "C32686"},
               {"label": "Ramonville-Saint-Agne", "value": "C32687"},
               {"label": "Realmont", "value": "C32688"},
               {"label": "Realville", "value": "C32689"},
               {"label": "Redessan", "value": "C32690"},
               {"label": "Remoulins", "value": "C32691"},
               {"label": "Requista", "value": "C32692"},
               {"label": "Restinclieres", "value": "C32693"},
               {"label": "Revel", "value": "C32694"},
               {"label": "Reynes", "value": "C32695"},
               {"label": "Ria-Sirach", "value": "C32696"},
               {"label": "Ribaute-les-Tavernes", "value": "C32697"},
               {"label": "Rieumes", "value": "C32698"},
               {"label": "Rieupeyroux", "value": "C32699"},
               {"label": "Rieux-Minervois", "value": "C32700"},
               {"label": "Rieux-Volvestre", "value": "C32701"},
               {"label": "Rignac", "value": "C32702"},
               {"label": "Riscle", "value": "C32703"},
               {"label": "Rivesaltes", "value": "C32704"},
               {"label": "Rochefort-du-Gard", "value": "C32705"},
               {"label": "Rodez", "value": "C32706"},
               {"label": "Rodilhan", "value": "C32707"},
               {"label": "Roquecourbe", "value": "C32708"},
               {"label": "Roquemaure", "value": "C32709"},
               {"label": "Roquettes", "value": "C32710"},
               {"label": "Rouffiac-Tolosan", "value": "C32711"},
               {"label": "Roujan", "value": "C32712"},
               {"label": "Rousson", "value": "C32713"},
               {"label": "Sabran", "value": "C32714"},
               {"label": "Saint-Affrique", "value": "C32715"},
               {"label": "Saint-Alban", "value": "C32716"},
               {"label": "Saint-Alban-sur-Limagnole", "value": "C32717"},
               {"label": "Saint-Alexandre", "value": "C32718"},
               {"label": "Saint-Amans-Soult", "value": "C32719"},
               {"label": "Saint-Amans-Valtoret", "value": "C32720"},
               {"label": "Saint-Ambroix", "value": "C32721"},
               {"label": "Saint-Andre-de-Sangonis", "value": "C32722"},
               {"label": "Saint-Aunes", "value": "C32723"},
               {"label": "Saint-Bauzille-de-Putois", "value": "C32724"},
               {"label": "Saint-Benoit-de-Carmaux", "value": "C32725"},
               {"label": "Saint-Bres", "value": "C32726"},
               {"label": "Saint-Cere", "value": "C32727"},
               {"label": "Saint-Chaptes", "value": "C32728"},
               {"label": "Saint-Chinian", "value": "C32729"},
               {"label": "Saint-Christol", "value": "C32730"},
               {"label": "Saint-Christol-les-Ales", "value": "C32731"},
               {"label": "Saint-Christophe-Vallon", "value": "C32732"},
               {"label": "Saint-Clement-de-Riviere", "value": "C32733"},
               {"label": "Saint-Cyprien-Plage", "value": "C32734"},
               {"label": "Saint-Drezery", "value": "C32735"},
               {"label": "Saint-Esteve", "value": "C32736"},
               {"label": "Saint-Etienne-de-Tulmont", "value": "C32737"},
               {"label": "Saint-Feliu-dAvall", "value": "C32738"},
               {"label": "Saint-Felix-Lauragais", "value": "C32739"},
               {"label": "Saint-Florent-sur-Auzonnet", "value": "C32740"},
               {"label": "Saint-Gaudens", "value": "C32741"},
               {"label": "Saint-Gely-du-Fesc", "value": "C32742"},
               {"label": "Saint-Genies-Bellevue", "value": "C32743"},
               {"label": "Saint-Genies-de-Comolas", "value": "C32744"},
               {"label": "Saint-Genies-de-Fontedit", "value": "C32745"},
               {"label": "Saint-Genies-de-Malgoires", "value": "C32746"},
               {"label": "Saint-Genies-des-Mourgues", "value": "C32747"},
               {"label": "Saint-Geniez-dOlt", "value": "C32748"},
               {"label": "Saint-Genis-des-Fontaines", "value": "C32749"},
               {"label": "Saint-Georges-de-Luzencon", "value": "C32750"},
               {"label": "Saint-Gervasy", "value": "C32751"},
               {"label": "Saint-Gilles", "value": "C32752"},
               {"label": "Saint-Girons", "value": "C32753"},
               {"label": "Saint-Hilaire-de-Brethmas", "value": "C32754"},
               {"label": "Saint-Hippolyte", "value": "C32755"},
               {"label": "Saint-Hippolyte-du-Fort", "value": "C32756"},
               {"label": "Saint-Jean", "value": "C32757"},
               {"label": "Saint-Jean-de-Fos", "value": "C32758"},
               {"label": "Saint-Jean-de-Vedas", "value": "C32759"},
               {"label": "Saint-Jean-du-Falga", "value": "C32760"},
               {"label": "Saint-Jean-du-Gard", "value": "C32761"},
               {"label": "Saint-Jory", "value": "C32762"},
               {"label": "Saint-Juery", "value": "C32763"},
               {"label": "Saint-Julien-de-Peyrolas", "value": "C32764"},
               {"label": "Saint-Julien-les-Rosiers", "value": "C32765"},
               {"label": "Saint-Just", "value": "C32766"},
               {"label": "Saint-Lary-Soulan", "value": "C32767"},
               {"label": "Saint-Laurent-de-la-Salanque", "value": "C32768"},
               {"label": "Saint-Laurent-des-Arbres", "value": "C32769"},
               {"label": "Saint-Lizier", "value": "C32770"},
               {"label": "Saint-Loup-Cammas", "value": "C32771"},
               {"label": "Saint-Lys", "value": "C32772"},
               {"label": "Saint-Mamert-du-Gard", "value": "C32773"},
               {"label": "Saint-Marcel-sur-Aude", "value": "C32774"},
               {"label": "Saint-Martin-de-Londres", "value": "C32775"},
               {"label": "Saint-Martin-de-Valgalgues", "value": "C32776"},
               {"label": "Saint-Martin-Lalande", "value": "C32777"},
               {"label": "Saint-Mathieu-de-Treviers", "value": "C32778"},
               {"label": "Saint-Nauphary", "value": "C32779"},
               {"label": "Saint-Nazaire", "value": "C32780"},
               {"label": "Saint-Nazaire-dAude", "value": "C32781"},
               {"label": "Saint-Nicolas-de-la-Grave", "value": "C32782"},
               {"label": "Saint-Orens-de-Gameville", "value": "C32783"},
               {"label": "Saint-Pargoire", "value": "C32784"},
               {"label": "Saint-Paul-Cap-de-Joux", "value": "C32785"},
               {"label": "Saint-Paul-de-Fenouillet", "value": "C32786"},
               {"label": "Saint-Paul-de-Jarrat", "value": "C32787"},
               {"label": "Saint-Paulet-de-Caisson", "value": "C32788"},
               {"label": "Saint-Pe-de-Bigorre", "value": "C32789"},
               {"label": "Saint-Pons-de-Thomieres", "value": "C32790"},
               {"label": "Saint-Porquier", "value": "C32791"},
               {"label": "Saint-Privat-des-Vieux", "value": "C32792"},
               {"label": "Saint-Quentin-la-Poterie", "value": "C32793"},
               {"label": "Saint-Sauveur", "value": "C32794"},
               {"label": "Saint-Sulpice-la-Pointe", "value": "C32795"},
               {"label": "Saint-Sulpice-sur-Leze", "value": "C32796"},
               {"label": "Saint-Thibery", "value": "C32797"},
               {"label": "Saint-Victor-la-Coste", "value": "C32798"},
               {"label": "Sainte-Foy-de-Peyrolieres", "value": "C32799"},
               {"label": "Sainte-Genevieve-sur-Argence", "value": "C32800"},
               {"label": "Sainte-Marie-Plage", "value": "C32801"},
               {"label": "Sainte-Radegonde", "value": "C32802"},
               {"label": "Saix", "value": "C32803"},
               {"label": "Saleilles", "value": "C32804"},
               {"label": "Salies-du-Salat", "value": "C32805"},
               {"label": "Salindres", "value": "C32806"},
               {"label": "Salles-Curan", "value": "C32807"},
               {"label": "Salles-la-Source", "value": "C32808"},
               {"label": "Salses-le-Chateau", "value": "C32809"},
               {"label": "Salviac", "value": "C32810"},
               {"label": "Samatan", "value": "C32811"},
               {"label": "Sant Andreu de Sureda", "value": "C32812"},
               {"label": "Sant Joan de Pladecorts", "value": "C32813"},
               {"label": "Sant Llorenc de Cerdans", "value": "C32814"},
               {"label": "Saubens", "value": "C32815"},
               {"label": "Saussan", "value": "C32816"},
               {"label": "Sauve", "value": "C32817"},
               {"label": "Sauveterre", "value": "C32818"},
               {"label": "Sauvian", "value": "C32819"},
               {"label": "Saverdun", "value": "C32820"},
               {"label": "Saze", "value": "C32821"},
               {"label": "Sebazac-Concoures", "value": "C32822"},
               {"label": "Seilh", "value": "C32823"},
               {"label": "Seissan", "value": "C32824"},
               {"label": "Semalens", "value": "C32825"},
               {"label": "Semeac", "value": "C32826"},
               {"label": "Septfonds", "value": "C32827"},
               {"label": "Serignan", "value": "C32828"},
               {"label": "Sernhac", "value": "C32829"},
               {"label": "Servian", "value": "C32830"},
               {"label": "Sete", "value": "C32831"},
               {"label": "Severac-le-Chateau", "value": "C32832"},
               {"label": "Seysses", "value": "C32833"},
               {"label": "Sigean", "value": "C32834"},
               {"label": "Sommieres", "value": "C32835"},
               {"label": "Sorede", "value": "C32836"},
               {"label": "Soreze", "value": "C32837"},
               {"label": "Soual", "value": "C32838"},
               {"label": "Soues", "value": "C32839"},
               {"label": "Souillac", "value": "C32840"},
               {"label": "Sumene", "value": "C32841"},
               {"label": "Sussargues", "value": "C32842"},
               {"label": "Tarascon-sur-Ariege", "value": "C32843"},
               {"label": "Tarbes", "value": "C32844"},
               {"label": "Tarn", "value": "C32845"},
               {"label": "Tavel", "value": "C32846"},
               {"label": "Teyran", "value": "C32847"},
               {"label": "Theza", "value": "C32848"},
               {"label": "Thezan-les-Beziers", "value": "C32849"},
               {"label": "Thuir", "value": "C32850"},
               {"label": "Torreilles", "value": "C32851"},
               {"label": "Toulouges", "value": "C32852"},
               {"label": "Toulouse", "value": "C32853"},
               {"label": "Tourbes", "value": "C32854"},
               {"label": "Tournay", "value": "C32855"},
               {"label": "Tournefeuille", "value": "C32856"},
               {"label": "Trebes", "value": "C32857"},
               {"label": "Tresques", "value": "C32858"},
               {"label": "Trie-sur-Baise", "value": "C32859"},
               {"label": "Trouillas", "value": "C32860"},
               {"label": "Uchaud", "value": "C32861"},
               {"label": "Uzes", "value": "C32862"},
               {"label": "Vacquiers", "value": "C32863"},
               {"label": "Vailhauques", "value": "C32864"},
               {"label": "Valady", "value": "C32865"},
               {"label": "Valence", "value": "C32866"},
               {"label": "Valence-dAlbigeois", "value": "C32867"},
               {"label": "Valence-sur-Baise", "value": "C32868"},
               {"label": "Valergues", "value": "C32869"},
               {"label": "Vallabregues", "value": "C32870"},
               {"label": "Valleraugue", "value": "C32871"},
               {"label": "Valras-Plage", "value": "C32872"},
               {"label": "Valros", "value": "C32873"},
               {"label": "Varilhes", "value": "C32874"},
               {"label": "Vauvert", "value": "C32875"},
               {"label": "Vayrac", "value": "C32876"},
               {"label": "Vendargues", "value": "C32877"},
               {"label": "Vendres", "value": "C32878"},
               {"label": "Venejan", "value": "C32879"},
               {"label": "Venerque", "value": "C32880"},
               {"label": "Verdun-sur-Garonne", "value": "C32881"},
               {"label": "Verfeil", "value": "C32882"},
               {"label": "Vergeze", "value": "C32883"},
               {"label": "Vernet", "value": "C32884"},
               {"label": "Vernet-les-Bains", "value": "C32885"},
               {"label": "Verniolle", "value": "C32886"},
               {"label": "Vers-Pont-du-Gard", "value": "C32887"},
               {"label": "Vestric-et-Candiac", "value": "C32888"},
               {"label": "Vezenobres", "value": "C32889"},
               {"label": "Vias", "value": "C32890"},
               {"label": "Vic-en-Bigorre", "value": "C32891"},
               {"label": "Vic-Fezensac", "value": "C32892"},
               {"label": "Vic-la-Gardiole", "value": "C32893"},
               {"label": "Vieille-Toulouse", "value": "C32894"},
               {"label": "Vielmur-sur-Agout", "value": "C32895"},
               {"label": "Vilallonga dels Monts", "value": "C32896"},
               {"label": "Villaudric", "value": "C32897"},
               {"label": "Villefranche-dAlbigeois", "value": "C32898"},
               {"label": "Villefranche-de-Lauragais", "value": "C32899"},
               {"label": "Villefranche-de-Rouergue", "value": "C32900"},
               {"label": "Villegailhenc", "value": "C32901"},
               {"label": "Villelongue-de-la-Salanque", "value": "C32902"},
               {"label": "Villemolaque", "value": "C32903"},
               {"label": "Villemoustaussou", "value": "C32904"},
               {"label": "Villemur-sur-Tarn", "value": "C32905"},
               {"label": "Villeneuve", "value": "C32906"},
               {"label": "Villeneuve-dOlmes", "value": "C32907"},
               {"label": "Villeneuve-de-la-Raho", "value": "C32908"},
               {"label": "Villeneuve-de-Riviere", "value": "C32909"},
               {"label": "Villeneuve-la-Comptal", "value": "C32910"},
               {"label": "Villeneuve-la-Riviere", "value": "C32911"},
               {"label": "Villeneuve-les-Avignon", "value": "C32912"},
               {"label": "Villeneuve-les-Beziers", "value": "C32913"},
               {"label": "Villeneuve-les-Bouloc", "value": "C32914"},
               {"label": "Villeneuve-les-Maguelone", "value": "C32915"},
               {"label": "Villeneuve-Tolosane", "value": "C32916"},
               {"label": "Villenouvelle", "value": "C32917"},
               {"label": "Villepinte", "value": "C32918"},
               {"label": "Villetelle", "value": "C32919"},
               {"label": "Villeveyrac", "value": "C32920"},
               {"label": "Villevieille", "value": "C32921"},
               {"label": "Vinassan", "value": "C32922"},
               {"label": "Vinca", "value": "C32923"},
               {"label": "Viviers-les-Montagnes", "value": "C32924"},
               {"label": "Viviez", "value": "C32925"}],
 "Ocnita District": [{"label": "Ocnita", "value": "C75526"},
                     {"label": "Otaci", "value": "C75527"}],
 "Ocotepeque Department": [{"label": "Antigua Ocotepeque", "value": "C43583"},
                           {"label": "Belen Gualcho", "value": "C43584"},
                           {"label": "Concepcion", "value": "C43585"},
                           {"label": "Dolores Merendon", "value": "C43586"},
                           {"label": "El Transito", "value": "C43587"},
                           {"label": "Fraternidad", "value": "C43588"},
                           {"label": "La Encarnacion", "value": "C43589"},
                           {"label": "La Labor", "value": "C43590"},
                           {"label": "Lucerna", "value": "C43591"},
                           {"label": "Mercedes", "value": "C43592"},
                           {"label": "Nueva Ocotepeque", "value": "C43593"},
                           {"label": "San Fernando", "value": "C43594"},
                           {"label": "San Francisco de Cones",
                            "value": "C43595"},
                           {"label": "San Francisco del Valle",
                            "value": "C43596"},
                           {"label": "San Jorge", "value": "C43597"},
                           {"label": "San Marcos", "value": "C43598"},
                           {"label": "Santa Fe", "value": "C43599"},
                           {"label": "Santa Lucia", "value": "C43600"},
                           {"label": "Sensenti", "value": "C43601"},
                           {"label": "Sinuapa", "value": "C43602"},
                           {"label": "Yaruchel", "value": "C43603"}],
 "Oddar Meanchey": [{"label": "Samraong", "value": "C16508"},
                    {"label": "Srok Banteay Ampil", "value": "C16509"},
                    {"label": "Srok Samraong", "value": "C16510"},
                    {"label": "Srok Trapeang Prasat", "value": "C16511"}],
 "Odeska oblast": [{"label": "Artsyz", "value": "C122424"},
                   {"label": "Balta", "value": "C122425"},
                   {"label": "Baltskyy Rayon", "value": "C122426"},
                   {"label": "Berezivka", "value": "C122427"},
                   {"label": "Berezyne", "value": "C122428"},
                   {"label": "Bilhorod-Dnistrovskyi", "value": "C122429"},
                   {"label": "Bilyayivka", "value": "C122430"},
                   {"label": "Bolhrad", "value": "C122431"},
                   {"label": "Borodino", "value": "C122432"},
                   {"label": "Broska", "value": "C122433"},
                   {"label": "Chornomorsk", "value": "C122434"},
                   {"label": "Dmytrivka", "value": "C122435"},
                   {"label": "Fontanka", "value": "C122436"},
                   {"label": "Horodnye", "value": "C122437"},
                   {"label": "Ivanivka", "value": "C122438"},
                   {"label": "Izmayil", "value": "C122439"},
                   {"label": "Kalcheva", "value": "C122440"},
                   {"label": "Kamyshevka Vtoraya", "value": "C122441"},
                   {"label": "Karolino-Buhaz", "value": "C122442"},
                   {"label": "Kiliya", "value": "C122443"},
                   {"label": "Kodyma", "value": "C122444"},
                   {"label": "Kodymskyy Rayon", "value": "C122445"},
                   {"label": "Krasnosilka", "value": "C122446"},
                   {"label": "Kremidivka", "value": "C122447"},
                   {"label": "Kryzhanivka", "value": "C122448"},
                   {"label": "Kuchurhan", "value": "C122449"},
                   {"label": "Kulevcha", "value": "C122450"},
                   {"label": "Kurisove", "value": "C122451"},
                   {"label": "Lymanske", "value": "C122452"},
                   {"label": "Lyubashivka", "value": "C122453"},
                   {"label": "Mayaky", "value": "C122454"},
                   {"label": "Mykolayivka", "value": "C122455"},
                   {"label": "Mykolayivskyy Rayon", "value": "C122456"},
                   {"label": "Novi Troyany", "value": "C122457"},
                   {"label": "Odesa", "value": "C122458"},
                   {"label": "Okny", "value": "C122459"},
                   {"label": "Oleksandrivka", "value": "C122460"},
                   {"label": "Ovidiopol", "value": "C122461"},
                   {"label": "Ozerne", "value": "C122462"},
                   {"label": "Peschana", "value": "C122463"},
                   {"label": "Plakhtiyivka", "value": "C122464"},
                   {"label": "Podilsk", "value": "C122465"},
                   {"label": "Podilsk Raion", "value": "C122466"},
                   {"label": "Reni", "value": "C122467"},
                   {"label": "Rozdilna", "value": "C122468"},
                   {"label": "Salhany", "value": "C122469"},
                   {"label": "Sarata", "value": "C122470"},
                   {"label": "Savran", "value": "C122471"},
                   {"label": "Serhiyivka", "value": "C122472"},
                   {"label": "Serpneve", "value": "C122473"},
                   {"label": "Shabo", "value": "C122474"},
                   {"label": "Shevchenkove", "value": "C122475"},
                   {"label": "Shyryayeve", "value": "C122476"},
                   {"label": "Slobidka", "value": "C122477"},
                   {"label": "Starokozache", "value": "C122478"},
                   {"label": "Stepanivka", "value": "C122479"},
                   {"label": "Stepove", "value": "C122480"},
                   {"label": "Tarutyne", "value": "C122481"},
                   {"label": "Tatarbunary", "value": "C122482"},
                   {"label": "Tayirove", "value": "C122483"},
                   {"label": "Teplodar", "value": "C122484"},
                   {"label": "Usatove", "value": "C122485"},
                   {"label": "Velyka Mykhaylivka", "value": "C122486"},
                   {"label": "Velykoploske", "value": "C122487"},
                   {"label": "Vylkove", "value": "C122488"},
                   {"label": "Vynohradivka", "value": "C122489"},
                   {"label": "Yuzhne", "value": "C122490"},
                   {"label": "Zagnitkiv", "value": "C122491"},
                   {"label": "Zakharivka", "value": "C122492"},
                   {"label": "Zaliznychne", "value": "C122493"},
                   {"label": "Zatoka", "value": "C122494"},
                   {"label": "Zatyshshya", "value": "C122495"},
                   {"label": "Zorya", "value": "C122496"}],
 "Odisha": [{"label": "Angul", "value": "C47310"},
            {"label": "Angul District", "value": "C47311"},
            {"label": "Asika", "value": "C47312"},
            {"label": "Athagarh", "value": "C47313"},
            {"label": "Bada Barabil", "value": "C47314"},
            {"label": "Balangir", "value": "C47315"},
            {"label": "Balasore", "value": "C47316"},
            {"label": "Baleshwar", "value": "C47317"},
            {"label": "Balimila", "value": "C47318"},
            {"label": "Balugaon", "value": "C47319"},
            {"label": "Banapur", "value": "C47320"},
            {"label": "Banki", "value": "C47321"},
            {"label": "Banposh", "value": "C47322"},
            {"label": "Baragarh", "value": "C47323"},
            {"label": "Barbil", "value": "C47324"},
            {"label": "Bargarh", "value": "C47325"},
            {"label": "Barpali", "value": "C47326"},
            {"label": "Basudebpur", "value": "C47327"},
            {"label": "Baud", "value": "C47328"},
            {"label": "Baudh", "value": "C47329"},
            {"label": "Belaguntha", "value": "C47330"},
            {"label": "Bhadrak", "value": "C47331"},
            {"label": "Bhadrakh", "value": "C47332"},
            {"label": "Bhanjanagar", "value": "C47333"},
            {"label": "Bhawanipatna", "value": "C47334"},
            {"label": "Bhuban", "value": "C47335"},
            {"label": "Bhubaneshwar", "value": "C47336"},
            {"label": "Binka", "value": "C47337"},
            {"label": "Birmitrapur", "value": "C47338"},
            {"label": "Bolanikhodan", "value": "C47339"},
            {"label": "Brahmapur", "value": "C47340"},
            {"label": "Brajarajnagar", "value": "C47341"},
            {"label": "Buguda", "value": "C47342"},
            {"label": "Burla", "value": "C47343"},
            {"label": "Champua", "value": "C47344"},
            {"label": "Chandbali", "value": "C47345"},
            {"label": "Chatrapur", "value": "C47346"},
            {"label": "Chikitigarh", "value": "C47347"},
            {"label": "Chittarkonda", "value": "C47348"},
            {"label": "Cuttack", "value": "C47349"},
            {"label": "Daitari", "value": "C47350"},
            {"label": "Deogarh", "value": "C47351"},
            {"label": "Dhenkanal", "value": "C47352"},
            {"label": "Digapahandi", "value": "C47353"},
            {"label": "Gajapati", "value": "C47354"},
            {"label": "Ganjam", "value": "C47355"},
            {"label": "Gopalpur", "value": "C47356"},
            {"label": "Gudari", "value": "C47357"},
            {"label": "Gunupur", "value": "C47358"},
            {"label": "Hinjilikatu", "value": "C47359"},
            {"label": "Hirakud", "value": "C47360"},
            {"label": "Jagatsinghapur", "value": "C47361"},
            {"label": "Jagatsinghpur", "value": "C47362"},
            {"label": "Jajpur", "value": "C47363"},
            {"label": "Jaleshwar", "value": "C47364"},
            {"label": "Jatani", "value": "C47365"},
            {"label": "Jeypore", "value": "C47366"},
            {"label": "Jharsuguda", "value": "C47367"},
            {"label": "Jharsuguda District", "value": "C47368"},
            {"label": "Kaintragarh", "value": "C47369"},
            {"label": "Kalahandi", "value": "C47370"},
            {"label": "Kamakhyanagar", "value": "C47371"},
            {"label": "Kandhamal", "value": "C47372"},
            {"label": "Kantabanji", "value": "C47373"},
            {"label": "Kantilo", "value": "C47374"},
            {"label": "Kendrapara", "value": "C47375"},
            {"label": "Kendraparha", "value": "C47376"},
            {"label": "Kendujhar", "value": "C47377"},
            {"label": "Kesinga", "value": "C47378"},
            {"label": "Khallikot", "value": "C47379"},
            {"label": "Kharhial", "value": "C47380"},
            {"label": "Khordha", "value": "C47381"},
            {"label": "Khurda", "value": "C47382"},
            {"label": "Kiri Buru", "value": "C47383"},
            {"label": "Kodala", "value": "C47384"},
            {"label": "Konarka", "value": "C47385"},
            {"label": "Koraput", "value": "C47386"},
            {"label": "Kuchaiburi", "value": "C47387"},
            {"label": "Kuchinda", "value": "C47388"},
            {"label": "Malakanagiri", "value": "C47389"},
            {"label": "Malkangiri", "value": "C47390"},
            {"label": "Mayurbhanj", "value": "C47391"},
            {"label": "Nabarangpur", "value": "C47392"},
            {"label": "Nayagarh", "value": "C47393"},
            {"label": "Nayagarh District", "value": "C47394"},
            {"label": "Nilgiri", "value": "C47395"},
            {"label": "Nimaparha", "value": "C47396"},
            {"label": "Nowrangapur", "value": "C47397"},
            {"label": "Nuapada", "value": "C47398"},
            {"label": "Padampur", "value": "C47399"},
            {"label": "Paradip Garh", "value": "C47400"},
            {"label": "Patamundai", "value": "C47401"},
            {"label": "Patnagarh", "value": "C47402"},
            {"label": "Phulbani", "value": "C47403"},
            {"label": "Pipili", "value": "C47404"},
            {"label": "Polasara", "value": "C47405"},
            {"label": "Puri", "value": "C47406"},
            {"label": "Purushottampur", "value": "C47407"},
            {"label": "Rambha", "value": "C47408"},
            {"label": "Raurkela", "value": "C47409"},
            {"label": "Rayagada", "value": "C47410"},
            {"label": "Remuna", "value": "C47411"},
            {"label": "Rengali", "value": "C47412"},
            {"label": "Sambalpur", "value": "C47413"},
            {"label": "Sonepur", "value": "C47414"},
            {"label": "Sorada", "value": "C47415"},
            {"label": "Soro", "value": "C47416"},
            {"label": "Subarnapur", "value": "C47417"},
            {"label": "Sundargarh", "value": "C47418"},
            {"label": "Talcher", "value": "C47419"},
            {"label": "Tarabha", "value": "C47420"},
            {"label": "Titlagarh", "value": "C47421"},
            {"label": "Udayagiri", "value": "C47422"}],
 "Odranci Municipality": [{"label": "Odranci", "value": "C107752"}],
 "Oghuz District": [{"label": "Oguz", "value": "C8489"}],
 "Ogooue-Ivindo Province": [{"label": "Booue", "value": "C34138"},
                            {"label": "Makokou", "value": "C34139"},
                            {"label": "Zadie", "value": "C34140"}],
 "Ogooue-Lolo Province": [{"label": "Koulamoutou", "value": "C34141"},
                          {"label": "Lastoursville", "value": "C34142"}],
 "Ogooue-Maritime Province": [{"label": "Gamba", "value": "C34143"},
                              {"label": "Omboue", "value": "C34144"},
                              {"label": "Port-Gentil", "value": "C34145"}],
 "Ogre Municipality": [{"label": "Jumprava", "value": "C64906"},
                       {"label": "Ogre", "value": "C64907"}],
 "Ogun": [{"label": "Abeokuta", "value": "C78414"},
          {"label": "Ado Odo", "value": "C78415"},
          {"label": "Idi Iroko", "value": "C78416"},
          {"label": "Ifo", "value": "C78417"},
          {"label": "Ijebu-Ife", "value": "C78418"},
          {"label": "Ijebu-Igbo", "value": "C78419"},
          {"label": "Ijebu-Ode", "value": "C78420"},
          {"label": "Ilaro", "value": "C78421"},
          {"label": "Imeko", "value": "C78422"},
          {"label": "Iperu", "value": "C78423"},
          {"label": "Isara", "value": "C78424"},
          {"label": "Owode", "value": "C78425"}],
 "Ohangwena Region": [{"label": "Oshikango", "value": "C75995"}],
 "Ohio": [{"label": "Ada", "value": "C139991"},
          {"label": "Adams County", "value": "C139992"},
          {"label": "Akron", "value": "C139993"},
          {"label": "Allen County", "value": "C139994"},
          {"label": "Alliance", "value": "C139995"},
          {"label": "Amberley", "value": "C139996"},
          {"label": "Amelia", "value": "C139997"},
          {"label": "Amherst", "value": "C139998"},
          {"label": "Andover", "value": "C139999"},
          {"label": "Anna", "value": "C140000"},
          {"label": "Ansonia", "value": "C140001"},
          {"label": "Antwerp", "value": "C140002"},
          {"label": "Apple Creek", "value": "C140003"},
          {"label": "Apple Valley", "value": "C140004"},
          {"label": "Arcanum", "value": "C140005"},
          {"label": "Archbold", "value": "C140006"},
          {"label": "Arlington", "value": "C140007"},
          {"label": "Ashland", "value": "C140008"},
          {"label": "Ashland County", "value": "C140009"},
          {"label": "Ashley", "value": "C140010"},
          {"label": "Ashtabula", "value": "C140011"},
          {"label": "Ashtabula County", "value": "C140012"},
          {"label": "Ashville", "value": "C140013"},
          {"label": "Athens", "value": "C140014"},
          {"label": "Athens County", "value": "C140015"},
          {"label": "Auglaize County", "value": "C140016"},
          {"label": "Aurora", "value": "C140017"},
          {"label": "Austintown", "value": "C140018"},
          {"label": "Avon", "value": "C140019"},
          {"label": "Avon Center", "value": "C140020"},
          {"label": "Avon Lake", "value": "C140021"},
          {"label": "Bainbridge", "value": "C140022"},
          {"label": "Ballville", "value": "C140023"},
          {"label": "Baltimore", "value": "C140024"},
          {"label": "Barberton", "value": "C140025"},
          {"label": "Barnesville", "value": "C140026"},
          {"label": "Batavia", "value": "C140027"},
          {"label": "Bay Village", "value": "C140028"},
          {"label": "Beach City", "value": "C140029"},
          {"label": "Beachwood", "value": "C140030"},
          {"label": "Beavercreek", "value": "C140031"},
          {"label": "Beckett Ridge", "value": "C140032"},
          {"label": "Bedford", "value": "C140033"},
          {"label": "Bedford Heights", "value": "C140034"},
          {"label": "Beechwood Trails", "value": "C140035"},
          {"label": "Bellaire", "value": "C140036"},
          {"label": "Bellbrook", "value": "C140037"},
          {"label": "Bellefontaine", "value": "C140038"},
          {"label": "Bellevue", "value": "C140039"},
          {"label": "Bellville", "value": "C140040"},
          {"label": "Belmont County", "value": "C140041"},
          {"label": "Belpre", "value": "C140042"},
          {"label": "Berea", "value": "C140043"},
          {"label": "Bethel", "value": "C140044"},
          {"label": "Bethesda", "value": "C140045"},
          {"label": "Beverly", "value": "C140046"},
          {"label": "Bexley", "value": "C140047"},
          {"label": "Blacklick Estates", "value": "C140048"},
          {"label": "Blanchester", "value": "C140049"},
          {"label": "Blue Ash", "value": "C140050"},
          {"label": "Bluffton", "value": "C140051"},
          {"label": "Boardman", "value": "C140052"},
          {"label": "Bolindale", "value": "C140053"},
          {"label": "Boston Heights", "value": "C140054"},
          {"label": "Botkins", "value": "C140055"},
          {"label": "Bowling Green", "value": "C140056"},
          {"label": "Bradford", "value": "C140057"},
          {"label": "Bradner", "value": "C140058"},
          {"label": "Bratenahl", "value": "C140059"},
          {"label": "Brecksville", "value": "C140060"},
          {"label": "Bremen", "value": "C140061"},
          {"label": "Brewster", "value": "C140062"},
          {"label": "Bridgeport", "value": "C140063"},
          {"label": "Bridgetown", "value": "C140064"},
          {"label": "Brilliant", "value": "C140065"},
          {"label": "Brimfield", "value": "C140066"},
          {"label": "Broadview Heights", "value": "C140067"},
          {"label": "Brook Park", "value": "C140068"},
          {"label": "Brookfield Center", "value": "C140069"},
          {"label": "Brooklyn", "value": "C140070"},
          {"label": "Brooklyn Heights", "value": "C140071"},
          {"label": "Brookville", "value": "C140072"},
          {"label": "Brown County", "value": "C140073"},
          {"label": "Brunswick", "value": "C140074"},
          {"label": "Bryan", "value": "C140075"},
          {"label": "Buckeye Lake", "value": "C140076"},
          {"label": "Bucyrus", "value": "C140077"},
          {"label": "Burlington", "value": "C140078"},
          {"label": "Burton", "value": "C140079"},
          {"label": "Butler County", "value": "C140080"},
          {"label": "Byesville", "value": "C140081"},
          {"label": "Cadiz", "value": "C140082"},
          {"label": "Calcutta", "value": "C140083"},
          {"label": "Caldwell", "value": "C140084"},
          {"label": "Cambridge", "value": "C140085"},
          {"label": "Camden", "value": "C140086"},
          {"label": "Campbell", "value": "C140087"},
          {"label": "Canal Fulton", "value": "C140088"},
          {"label": "Canal Winchester", "value": "C140089"},
          {"label": "Canfield", "value": "C140090"},
          {"label": "Canton", "value": "C140091"},
          {"label": "Cardington", "value": "C140092"},
          {"label": "Carey", "value": "C140093"},
          {"label": "Carlisle", "value": "C140094"},
          {"label": "Carroll County", "value": "C140095"},
          {"label": "Carrollton", "value": "C140096"},
          {"label": "Cedarville", "value": "C140097"},
          {"label": "Celina", "value": "C140098"},
          {"label": "Centerburg", "value": "C140099"},
          {"label": "Centerville", "value": "C140100"},
          {"label": "Chagrin Falls", "value": "C140101"},
          {"label": "Champaign County", "value": "C140102"},
          {"label": "Champion Heights", "value": "C140103"},
          {"label": "Chardon", "value": "C140104"},
          {"label": "Chauncey", "value": "C140105"},
          {"label": "Cherry Grove", "value": "C140106"},
          {"label": "Chesterland", "value": "C140107"},
          {"label": "Cheviot", "value": "C140108"},
          {"label": "Chillicothe", "value": "C140109"},
          {"label": "Choctaw Lake", "value": "C140110"},
          {"label": "Churchill", "value": "C140111"},
          {"label": "Cincinnati", "value": "C140112"},
          {"label": "Circleville", "value": "C140113"},
          {"label": "Clark County", "value": "C140114"},
          {"label": "Clark-Fulton", "value": "C140115"},
          {"label": "Clayton", "value": "C140116"},
          {"label": "Clermont County", "value": "C140117"},
          {"label": "Cleveland", "value": "C140118"},
          {"label": "Cleveland Heights", "value": "C140119"},
          {"label": "Cleves", "value": "C140120"},
          {"label": "Clinton", "value": "C140121"},
          {"label": "Clinton County", "value": "C140122"},
          {"label": "Clyde", "value": "C140123"},
          {"label": "Coal Grove", "value": "C140124"},
          {"label": "Coldwater", "value": "C140125"},
          {"label": "Collinwood", "value": "C140126"},
          {"label": "Columbiana", "value": "C140127"},
          {"label": "Columbiana County", "value": "C140128"},
          {"label": "Columbus", "value": "C140129"},
          {"label": "Columbus Grove", "value": "C140130"},
          {"label": "Commercial Point", "value": "C140131"},
          {"label": "Conneaut", "value": "C140132"},
          {"label": "Continental", "value": "C140133"},
          {"label": "Convoy", "value": "C140134"},
          {"label": "Copley", "value": "C140135"},
          {"label": "Cortland", "value": "C140136"},
          {"label": "Coshocton", "value": "C140137"},
          {"label": "Coshocton County", "value": "C140138"},
          {"label": "Covedale", "value": "C140139"},
          {"label": "Covington", "value": "C140140"},
          {"label": "Craig Beach", "value": "C140141"},
          {"label": "Crawford County", "value": "C140142"},
          {"label": "Crestline", "value": "C140143"},
          {"label": "Creston", "value": "C140144"},
          {"label": "Cridersville", "value": "C140145"},
          {"label": "Crooksville", "value": "C140146"},
          {"label": "Crystal Lakes", "value": "C140147"},
          {"label": "Curtice", "value": "C140148"},
          {"label": "Cuyahoga County", "value": "C140149"},
          {"label": "Cuyahoga Falls", "value": "C140150"},
          {"label": "Dalton", "value": "C140151"},
          {"label": "Danville", "value": "C140152"},
          {"label": "Darke County", "value": "C140153"},
          {"label": "Day Heights", "value": "C140154"},
          {"label": "Dayton", "value": "C140155"},
          {"label": "De Graff", "value": "C140156"},
          {"label": "Deer Park", "value": "C140157"},
          {"label": "Defiance", "value": "C140158"},
          {"label": "Defiance County", "value": "C140159"},
          {"label": "Delaware", "value": "C140160"},
          {"label": "Delaware County", "value": "C140161"},
          {"label": "Delhi Hills", "value": "C140162"},
          {"label": "Delphos", "value": "C140163"},
          {"label": "Delta", "value": "C140164"},
          {"label": "Dennison", "value": "C140165"},
          {"label": "Dent", "value": "C140166"},
          {"label": "Deshler", "value": "C140167"},
          {"label": "Detroit-Shoreway", "value": "C140168"},
          {"label": "Devola", "value": "C140169"},
          {"label": "Dillonvale", "value": "C140170"},
          {"label": "Dover", "value": "C140171"},
          {"label": "Doylestown", "value": "C140172"},
          {"label": "Dresden", "value": "C140173"},
          {"label": "Drexel", "value": "C140174"},
          {"label": "Dry Ridge", "value": "C140175"},
          {"label": "Dry Run", "value": "C140176"},
          {"label": "Dublin", "value": "C140177"},
          {"label": "Dunlap", "value": "C140178"},
          {"label": "East Canton", "value": "C140179"},
          {"label": "East Cleveland", "value": "C140180"},
          {"label": "East Liverpool", "value": "C140181"},
          {"label": "East Palestine", "value": "C140182"},
          {"label": "Eastlake", "value": "C140183"},
          {"label": "Eaton", "value": "C140184"},
          {"label": "Eaton Estates", "value": "C140185"},
          {"label": "Edgerton", "value": "C140186"},
          {"label": "Edgewood", "value": "C140187"},
          {"label": "Elida", "value": "C140188"},
          {"label": "Elmore", "value": "C140189"},
          {"label": "Elmwood Place", "value": "C140190"},
          {"label": "Elyria", "value": "C140191"},
          {"label": "Englewood", "value": "C140192"},
          {"label": "Enon", "value": "C140193"},
          {"label": "Erie County", "value": "C140194"},
          {"label": "Etna", "value": "C140195"},
          {"label": "Euclid", "value": "C140196"},
          {"label": "Evendale", "value": "C140197"},
          {"label": "Fairborn", "value": "C140198"},
          {"label": "Fairfax", "value": "C140199"},
          {"label": "Fairfield", "value": "C140200"},
          {"label": "Fairfield Beach", "value": "C140201"},
          {"label": "Fairfield County", "value": "C140202"},
          {"label": "Fairlawn", "value": "C140203"},
          {"label": "Fairport Harbor", "value": "C140204"},
          {"label": "Fairview Park", "value": "C140205"},
          {"label": "Farmersville", "value": "C140206"},
          {"label": "Fayette", "value": "C140207"},
          {"label": "Fayette County", "value": "C140208"},
          {"label": "Findlay", "value": "C140209"},
          {"label": "Finneytown", "value": "C140210"},
          {"label": "Five Points", "value": "C140211"},
          {"label": "Forest", "value": "C140212"},
          {"label": "Forest Park", "value": "C140213"},
          {"label": "Forestville", "value": "C140214"},
          {"label": "Fort Loramie", "value": "C140215"},
          {"label": "Fort McKinley", "value": "C140216"},
          {"label": "Fort Recovery", "value": "C140217"},
          {"label": "Fort Shawnee", "value": "C140218"},
          {"label": "Fostoria", "value": "C140219"},
          {"label": "Frankfort", "value": "C140220"},
          {"label": "Franklin", "value": "C140221"},
          {"label": "Franklin County", "value": "C140222"},
          {"label": "Franklin Furnace", "value": "C140223"},
          {"label": "Frazeysburg", "value": "C140224"},
          {"label": "Fredericktown", "value": "C140225"},
          {"label": "Fremont", "value": "C140226"},
          {"label": "Fruit Hill", "value": "C140227"},
          {"label": "Fulton County", "value": "C140228"},
          {"label": "Gahanna", "value": "C140229"},
          {"label": "Galion", "value": "C140230"},
          {"label": "Gallia County", "value": "C140231"},
          {"label": "Gallipolis", "value": "C140232"},
          {"label": "Gambier", "value": "C140233"},
          {"label": "Garfield Heights", "value": "C140234"},
          {"label": "Garrettsville", "value": "C140235"},
          {"label": "Gates Mills", "value": "C140236"},
          {"label": "Geauga County", "value": "C140237"},
          {"label": "Geneva", "value": "C140238"},
          {"label": "Geneva-on-the-Lake", "value": "C140239"},
          {"label": "Genoa", "value": "C140240"},
          {"label": "Georgetown", "value": "C140241"},
          {"label": "Germantown", "value": "C140242"},
          {"label": "Gibsonburg", "value": "C140243"},
          {"label": "Girard", "value": "C140244"},
          {"label": "Glandorf", "value": "C140245"},
          {"label": "Glendale", "value": "C140246"},
          {"label": "Glenmoor", "value": "C140247"},
          {"label": "Glenville", "value": "C140248"},
          {"label": "Glouster", "value": "C140249"},
          {"label": "Gnadenhutten", "value": "C140250"},
          {"label": "Golf Manor", "value": "C140251"},
          {"label": "Goshen", "value": "C140252"},
          {"label": "Grafton", "value": "C140253"},
          {"label": "Grandview", "value": "C140254"},
          {"label": "Grandview Heights", "value": "C140255"},
          {"label": "Granville", "value": "C140256"},
          {"label": "Granville South", "value": "C140257"},
          {"label": "Green", "value": "C140258"},
          {"label": "Green Meadows", "value": "C140259"},
          {"label": "Green Springs", "value": "C140260"},
          {"label": "Greene County", "value": "C140261"},
          {"label": "Greenfield", "value": "C140262"},
          {"label": "Greenhills", "value": "C140263"},
          {"label": "Greensburg", "value": "C140264"},
          {"label": "Greentown", "value": "C140265"},
          {"label": "Greenville", "value": "C140266"},
          {"label": "Greenwich", "value": "C140267"},
          {"label": "Groesbeck", "value": "C140268"},
          {"label": "Grove City", "value": "C140269"},
          {"label": "Groveport", "value": "C140270"},
          {"label": "Guernsey County", "value": "C140271"},
          {"label": "Hamilton", "value": "C140272"},
          {"label": "Hamilton County", "value": "C140273"},
          {"label": "Hancock County", "value": "C140274"},
          {"label": "Hanover", "value": "C140275"},
          {"label": "Harbor Hills", "value": "C140276"},
          {"label": "Hardin County", "value": "C140277"},
          {"label": "Harrison", "value": "C140278"},
          {"label": "Harrison County", "value": "C140279"},
          {"label": "Hartville", "value": "C140280"},
          {"label": "Haskins", "value": "C140281"},
          {"label": "Heath", "value": "C140282"},
          {"label": "Hebron", "value": "C140283"},
          {"label": "Henry County", "value": "C140284"},
          {"label": "Hicksville", "value": "C140285"},
          {"label": "Highland County", "value": "C140286"},
          {"label": "Highland Heights", "value": "C140287"},
          {"label": "Highpoint", "value": "C140288"},
          {"label": "Hilliard", "value": "C140289"},
          {"label": "Hillsboro", "value": "C140290"},
          {"label": "Hiram", "value": "C140291"},
          {"label": "Hocking County", "value": "C140292"},
          {"label": "Holgate", "value": "C140293"},
          {"label": "Holiday Valley", "value": "C140294"},
          {"label": "Holland", "value": "C140295"},
          {"label": "Holmes County", "value": "C140296"},
          {"label": "Hough", "value": "C140297"},
          {"label": "Howland Center", "value": "C140298"},
          {"label": "Hubbard", "value": "C140299"},
          {"label": "Huber Heights", "value": "C140300"},
          {"label": "Huber Ridge", "value": "C140301"},
          {"label": "Hudson", "value": "C140302"},
          {"label": "Hunter", "value": "C140303"},
          {"label": "Huron", "value": "C140304"},
          {"label": "Huron County", "value": "C140305"},
          {"label": "Independence", "value": "C140306"},
          {"label": "Ironton", "value": "C140307"},
          {"label": "Jackson", "value": "C140308"},
          {"label": "Jackson Center", "value": "C140309"},
          {"label": "Jackson County", "value": "C140310"},
          {"label": "Jamestown", "value": "C140311"},
          {"label": "Jefferson", "value": "C140312"},
          {"label": "Jefferson County", "value": "C140313"},
          {"label": "Jeffersonville", "value": "C140314"},
          {"label": "Johnstown", "value": "C140315"},
          {"label": "Kalida", "value": "C140316"},
          {"label": "Kent", "value": "C140317"},
          {"label": "Kenton", "value": "C140318"},
          {"label": "Kenwood", "value": "C140319"},
          {"label": "Kettering", "value": "C140320"},
          {"label": "Kings Mills", "value": "C140321"},
          {"label": "Kingston", "value": "C140322"},
          {"label": "Kirtland", "value": "C140323"},
          {"label": "Knox County", "value": "C140324"},
          {"label": "La Croft", "value": "C140325"},
          {"label": "Lagrange", "value": "C140326"},
          {"label": "Lake County", "value": "C140327"},
          {"label": "Lake Darby", "value": "C140328"},
          {"label": "Lake Lakengren", "value": "C140329"},
          {"label": "Lake Mohawk", "value": "C140330"},
          {"label": "Lakemore", "value": "C140331"},
          {"label": "Lakeview", "value": "C140332"},
          {"label": "Lakewood", "value": "C140333"},
          {"label": "Lancaster", "value": "C140334"},
          {"label": "Landen", "value": "C140335"},
          {"label": "Lawrence County", "value": "C140336"},
          {"label": "Leavittsburg", "value": "C140337"},
          {"label": "Lebanon", "value": "C140338"},
          {"label": "Leesburg", "value": "C140339"},
          {"label": "Leetonia", "value": "C140340"},
          {"label": "Leipsic", "value": "C140341"},
          {"label": "Lewis Center", "value": "C140342"},
          {"label": "Lewisburg", "value": "C140343"},
          {"label": "Lexington", "value": "C140344"},
          {"label": "Liberty Center", "value": "C140345"},
          {"label": "Licking County", "value": "C140346"},
          {"label": "Lima", "value": "C140347"},
          {"label": "Lincoln Heights", "value": "C140348"},
          {"label": "Lincoln Village", "value": "C140349"},
          {"label": "Lisbon", "value": "C140350"},
          {"label": "Lithopolis", "value": "C140351"},
          {"label": "Lockland", "value": "C140352"},
          {"label": "Lodi", "value": "C140353"},
          {"label": "Logan", "value": "C140354"},
          {"label": "Logan County", "value": "C140355"},
          {"label": "Logan Elm Village", "value": "C140356"},
          {"label": "London", "value": "C140357"},
          {"label": "Lorain", "value": "C140358"},
          {"label": "Lorain County", "value": "C140359"},
          {"label": "Lordstown", "value": "C140360"},
          {"label": "Loudonville", "value": "C140361"},
          {"label": "Louisville", "value": "C140362"},
          {"label": "Loveland", "value": "C140363"},
          {"label": "Loveland Park", "value": "C140364"},
          {"label": "Lowellville", "value": "C140365"},
          {"label": "Lucas County", "value": "C140366"},
          {"label": "Lucasville", "value": "C140367"},
          {"label": "Luckey", "value": "C140368"},
          {"label": "Lynchburg", "value": "C140369"},
          {"label": "Lyndhurst", "value": "C140370"},
          {"label": "Macedonia", "value": "C140371"},
          {"label": "Mack", "value": "C140372"},
          {"label": "Madeira", "value": "C140373"},
          {"label": "Madison", "value": "C140374"},
          {"label": "Madison County", "value": "C140375"},
          {"label": "Mahoning County", "value": "C140376"},
          {"label": "Malvern", "value": "C140377"},
          {"label": "Manchester", "value": "C140378"},
          {"label": "Mansfield", "value": "C140379"},
          {"label": "Mantua", "value": "C140380"},
          {"label": "Maple Heights", "value": "C140381"},
          {"label": "Mariemont", "value": "C140382"},
          {"label": "Marietta", "value": "C140383"},
          {"label": "Marion", "value": "C140384"},
          {"label": "Marion County", "value": "C140385"},
          {"label": "Martins Ferry", "value": "C140386"},
          {"label": "Marysville", "value": "C140387"},
          {"label": "Mason", "value": "C140388"},
          {"label": "Massillon", "value": "C140389"},
          {"label": "Masury", "value": "C140390"},
          {"label": "Maumee", "value": "C140391"},
          {"label": "Mayfield", "value": "C140392"},
          {"label": "Mayfield Heights", "value": "C140393"},
          {"label": "McArthur", "value": "C140394"},
          {"label": "McComb", "value": "C140395"},
          {"label": "McConnelsville", "value": "C140396"},
          {"label": "McDonald", "value": "C140397"},
          {"label": "McKinley Heights", "value": "C140398"},
          {"label": "Mechanicsburg", "value": "C140399"},
          {"label": "Medina", "value": "C140400"},
          {"label": "Medina County", "value": "C140401"},
          {"label": "Meigs County", "value": "C140402"},
          {"label": "Mentor", "value": "C140403"},
          {"label": "Mentor-on-the-Lake", "value": "C140404"},
          {"label": "Mercer County", "value": "C140405"},
          {"label": "Miami County", "value": "C140406"},
          {"label": "Miami Heights", "value": "C140407"},
          {"label": "Miamisburg", "value": "C140408"},
          {"label": "Miamitown", "value": "C140409"},
          {"label": "Middleburg Heights", "value": "C140410"},
          {"label": "Middlefield", "value": "C140411"},
          {"label": "Middleport", "value": "C140412"},
          {"label": "Middletown", "value": "C140413"},
          {"label": "Milan", "value": "C140414"},
          {"label": "Milford", "value": "C140415"},
          {"label": "Millbury", "value": "C140416"},
          {"label": "Millersburg", "value": "C140417"},
          {"label": "Millersport", "value": "C140418"},
          {"label": "Mineral Ridge", "value": "C140419"},
          {"label": "Minerva", "value": "C140420"},
          {"label": "Minerva Park", "value": "C140421"},
          {"label": "Mingo Junction", "value": "C140422"},
          {"label": "Minster", "value": "C140423"},
          {"label": "Mogadore", "value": "C140424"},
          {"label": "Monfort Heights", "value": "C140425"},
          {"label": "Monroe", "value": "C140426"},
          {"label": "Monroe County", "value": "C140427"},
          {"label": "Monroeville", "value": "C140428"},
          {"label": "Montgomery", "value": "C140429"},
          {"label": "Montgomery County", "value": "C140430"},
          {"label": "Montpelier", "value": "C140431"},
          {"label": "Montrose-Ghent", "value": "C140432"},
          {"label": "Moraine", "value": "C140433"},
          {"label": "Moreland Hills", "value": "C140434"},
          {"label": "Morgan County", "value": "C140435"},
          {"label": "Morgandale", "value": "C140436"},
          {"label": "Morrow", "value": "C140437"},
          {"label": "Morrow County", "value": "C140438"},
          {"label": "Mount Carmel", "value": "C140439"},
          {"label": "Mount Gilead", "value": "C140440"},
          {"label": "Mount Healthy", "value": "C140441"},
          {"label": "Mount Healthy Heights", "value": "C140442"},
          {"label": "Mount Orab", "value": "C140443"},
          {"label": "Mount Repose", "value": "C140444"},
          {"label": "Mount Sterling", "value": "C140445"},
          {"label": "Mount Vernon", "value": "C140446"},
          {"label": "Mulberry", "value": "C140447"},
          {"label": "Munroe Falls", "value": "C140448"},
          {"label": "Muskingum County", "value": "C140449"},
          {"label": "Napoleon", "value": "C140450"},
          {"label": "Navarre", "value": "C140451"},
          {"label": "Nelsonville", "value": "C140452"},
          {"label": "New Albany", "value": "C140453"},
          {"label": "New Boston", "value": "C140454"},
          {"label": "New Bremen", "value": "C140455"},
          {"label": "New Burlington", "value": "C140456"},
          {"label": "New California", "value": "C140457"},
          {"label": "New Carlisle", "value": "C140458"},
          {"label": "New Concord", "value": "C140459"},
          {"label": "New Franklin", "value": "C140460"},
          {"label": "New Lebanon", "value": "C140461"},
          {"label": "New Lexington", "value": "C140462"},
          {"label": "New London", "value": "C140463"},
          {"label": "New Matamoras", "value": "C140464"},
          {"label": "New Miami", "value": "C140465"},
          {"label": "New Middletown", "value": "C140466"},
          {"label": "New Paris", "value": "C140467"},
          {"label": "New Philadelphia", "value": "C140468"},
          {"label": "New Richmond", "value": "C140469"},
          {"label": "New Vienna", "value": "C140470"},
          {"label": "New Waterford", "value": "C140471"},
          {"label": "Newark", "value": "C140472"},
          {"label": "Newburgh Heights", "value": "C140473"},
          {"label": "Newcomerstown", "value": "C140474"},
          {"label": "Newport", "value": "C140475"},
          {"label": "Newton Falls", "value": "C140476"},
          {"label": "Newtown", "value": "C140477"},
          {"label": "Niles", "value": "C140478"},
          {"label": "Noble County", "value": "C140479"},
          {"label": "North Baltimore", "value": "C140480"},
          {"label": "North Canton", "value": "C140481"},
          {"label": "North College Hill", "value": "C140482"},
          {"label": "North Fork Village", "value": "C140483"},
          {"label": "North Kingsville", "value": "C140484"},
          {"label": "North Lewisburg", "value": "C140485"},
          {"label": "North Madison", "value": "C140486"},
          {"label": "North Olmsted", "value": "C140487"},
          {"label": "North Randall", "value": "C140488"},
          {"label": "North Ridgeville", "value": "C140489"},
          {"label": "North Royalton", "value": "C140490"},
          {"label": "North Zanesville", "value": "C140491"},
          {"label": "Northbrook", "value": "C140492"},
          {"label": "Northfield", "value": "C140493"},
          {"label": "Northgate", "value": "C140494"},
          {"label": "Northridge", "value": "C140495"},
          {"label": "Northwood", "value": "C140496"},
          {"label": "Norton", "value": "C140497"},
          {"label": "Norwalk", "value": "C140498"},
          {"label": "Norwood", "value": "C140499"},
          {"label": "Oak Harbor", "value": "C140500"},
          {"label": "Oak Hill", "value": "C140501"},
          {"label": "Oakwood", "value": "C140502"},
          {"label": "Oberlin", "value": "C140503"},
          {"label": "Obetz", "value": "C140504"},
          {"label": "Olmsted Falls", "value": "C140505"},
          {"label": "Ontario", "value": "C140506"},
          {"label": "Orange", "value": "C140507"},
          {"label": "Oregon", "value": "C140508"},
          {"label": "Orrville", "value": "C140509"},
          {"label": "Orwell", "value": "C140510"},
          {"label": "Ottawa", "value": "C140511"},
          {"label": "Ottawa County", "value": "C140512"},
          {"label": "Ottawa Hills", "value": "C140513"},
          {"label": "Oxford", "value": "C140514"},
          {"label": "Painesville", "value": "C140515"},
          {"label": "Pandora", "value": "C140516"},
          {"label": "Park Layne", "value": "C140517"},
          {"label": "Parma", "value": "C140518"},
          {"label": "Parma Heights", "value": "C140519"},
          {"label": "Pataskala", "value": "C140520"},
          {"label": "Paulding", "value": "C140521"},
          {"label": "Paulding County", "value": "C140522"},
          {"label": "Payne", "value": "C140523"},
          {"label": "Peebles", "value": "C140524"},
          {"label": "Pemberville", "value": "C140525"},
          {"label": "Pepper Pike", "value": "C140526"},
          {"label": "Perry", "value": "C140527"},
          {"label": "Perry County", "value": "C140528"},
          {"label": "Perry Heights", "value": "C140529"},
          {"label": "Perrysburg", "value": "C140530"},
          {"label": "Pickaway County", "value": "C140531"},
          {"label": "Pickerington", "value": "C140532"},
          {"label": "Pike County", "value": "C140533"},
          {"label": "Piketon", "value": "C140534"},
          {"label": "Pioneer", "value": "C140535"},
          {"label": "Piqua", "value": "C140536"},
          {"label": "Plain City", "value": "C140537"},
          {"label": "Pleasant Grove", "value": "C140538"},
          {"label": "Pleasant Hill", "value": "C140539"},
          {"label": "Pleasant Run", "value": "C140540"},
          {"label": "Pleasant Run Farm", "value": "C140541"},
          {"label": "Plymouth", "value": "C140542"},
          {"label": "Poland", "value": "C140543"},
          {"label": "Pomeroy", "value": "C140544"},
          {"label": "Port Clinton", "value": "C140545"},
          {"label": "Portage County", "value": "C140546"},
          {"label": "Portage Lakes", "value": "C140547"},
          {"label": "Portsmouth", "value": "C140548"},
          {"label": "Powell", "value": "C140549"},
          {"label": "Powhatan Point", "value": "C140550"},
          {"label": "Preble County", "value": "C140551"},
          {"label": "Prospect", "value": "C140552"},
          {"label": "Putnam County", "value": "C140553"},
          {"label": "Ravenna", "value": "C140554"},
          {"label": "Reading", "value": "C140555"},
          {"label": "Reminderville", "value": "C140556"},
          {"label": "Reno", "value": "C140557"},
          {"label": "Reynoldsburg", "value": "C140558"},
          {"label": "Richfield", "value": "C140559"},
          {"label": "Richland County", "value": "C140560"},
          {"label": "Richmond Heights", "value": "C140561"},
          {"label": "Richville", "value": "C140562"},
          {"label": "Richwood", "value": "C140563"},
          {"label": "Ripley", "value": "C140564"},
          {"label": "Rittman", "value": "C140565"},
          {"label": "Riverside", "value": "C140566"},
          {"label": "Roaming Shores", "value": "C140567"},
          {"label": "Rockford", "value": "C140568"},
          {"label": "Rocky River", "value": "C140569"},
          {"label": "Rosemount", "value": "C140570"},
          {"label": "Roseville", "value": "C140571"},
          {"label": "Ross", "value": "C140572"},
          {"label": "Ross County", "value": "C140573"},
          {"label": "Rossford", "value": "C140574"},
          {"label": "Rossmoyne", "value": "C140575"},
          {"label": "Russells Point", "value": "C140576"},
          {"label": "Sabina", "value": "C140577"},
          {"label": "Saint Bernard", "value": "C140578"},
          {"label": "Saint Clairsville", "value": "C140579"},
          {"label": "Saint Henry", "value": "C140580"},
          {"label": "Saint Marys", "value": "C140581"},
          {"label": "Saint Paris", "value": "C140582"},
          {"label": "Salem", "value": "C140583"},
          {"label": "Salem Heights", "value": "C140584"},
          {"label": "Salineville", "value": "C140585"},
          {"label": "Sandusky", "value": "C140586"},
          {"label": "Sandusky County", "value": "C140587"},
          {"label": "Sawyerwood", "value": "C140588"},
          {"label": "Scioto County", "value": "C140589"},
          {"label": "Sciotodale", "value": "C140590"},
          {"label": "Sebring", "value": "C140591"},
          {"label": "Seneca County", "value": "C140592"},
          {"label": "Seven Hills", "value": "C140593"},
          {"label": "Seville", "value": "C140594"},
          {"label": "Shadyside", "value": "C140595"},
          {"label": "Shaker Heights", "value": "C140596"},
          {"label": "Sharonville", "value": "C140597"},
          {"label": "Shawnee Hills", "value": "C140598"},
          {"label": "Sheffield", "value": "C140599"},
          {"label": "Sheffield Lake", "value": "C140600"},
          {"label": "Shelby", "value": "C140601"},
          {"label": "Shelby County", "value": "C140602"},
          {"label": "Sherwood", "value": "C140603"},
          {"label": "Shiloh", "value": "C140604"},
          {"label": "Shreve", "value": "C140605"},
          {"label": "Sidney", "value": "C140606"},
          {"label": "Silver Lake", "value": "C140607"},
          {"label": "Silverton", "value": "C140608"},
          {"label": "Sixteen Mile Stand", "value": "C140609"},
          {"label": "Skyline Acres", "value": "C140610"},
          {"label": "Smithville", "value": "C140611"},
          {"label": "Solon", "value": "C140612"},
          {"label": "Somerset", "value": "C140613"},
          {"label": "South Amherst", "value": "C140614"},
          {"label": "South Bloomfield", "value": "C140615"},
          {"label": "South Canal", "value": "C140616"},
          {"label": "South Charleston", "value": "C140617"},
          {"label": "South Euclid", "value": "C140618"},
          {"label": "South Lebanon", "value": "C140619"},
          {"label": "South Point", "value": "C140620"},
          {"label": "South Russell", "value": "C140621"},
          {"label": "South Zanesville", "value": "C140622"},
          {"label": "Spencerville", "value": "C140623"},
          {"label": "Springboro", "value": "C140624"},
          {"label": "Springdale", "value": "C140625"},
          {"label": "Springfield", "value": "C140626"},
          {"label": "Stark County", "value": "C140627"},
          {"label": "Steubenville", "value": "C140628"},
          {"label": "Stony Prairie", "value": "C140629"},
          {"label": "Stow", "value": "C140630"},
          {"label": "Strasburg", "value": "C140631"},
          {"label": "Streetsboro", "value": "C140632"},
          {"label": "Strongsville", "value": "C140633"},
          {"label": "Struthers", "value": "C140634"},
          {"label": "Stryker", "value": "C140635"},
          {"label": "Sugarcreek", "value": "C140636"},
          {"label": "Sugarcreek Police Dept", "value": "C140637"},
          {"label": "Summerside", "value": "C140638"},
          {"label": "Summit County", "value": "C140639"},
          {"label": "Sunbury", "value": "C140640"},
          {"label": "Swanton", "value": "C140641"},
          {"label": "Sylvania", "value": "C140642"},
          {"label": "Tallmadge", "value": "C140643"},
          {"label": "Terrace Park", "value": "C140644"},
          {"label": "The Plains", "value": "C140645"},
          {"label": "The Village of Indian Hill", "value": "C140646"},
          {"label": "Thornport", "value": "C140647"},
          {"label": "Tiffin", "value": "C140648"},
          {"label": "Tiltonsville", "value": "C140649"},
          {"label": "Tipp City", "value": "C140650"},
          {"label": "Toledo", "value": "C140651"},
          {"label": "Toronto", "value": "C140652"},
          {"label": "Trenton", "value": "C140653"},
          {"label": "Trotwood", "value": "C140654"},
          {"label": "Troy", "value": "C140655"},
          {"label": "Trumbull County", "value": "C140656"},
          {"label": "Turpin Hills", "value": "C140657"},
          {"label": "Tuscarawas", "value": "C140658"},
          {"label": "Tuscarawas County", "value": "C140659"},
          {"label": "Twinsburg", "value": "C140660"},
          {"label": "Uhrichsville", "value": "C140661"},
          {"label": "Union", "value": "C140662"},
          {"label": "Union City", "value": "C140663"},
          {"label": "Union County", "value": "C140664"},
          {"label": "Uniontown", "value": "C140665"},
          {"label": "University Heights", "value": "C140666"},
          {"label": "Upper Arlington", "value": "C140667"},
          {"label": "Upper Sandusky", "value": "C140668"},
          {"label": "Urbana", "value": "C140669"},
          {"label": "Urbancrest", "value": "C140670"},
          {"label": "Utica", "value": "C140671"},
          {"label": "Valley View", "value": "C140672"},
          {"label": "Van Wert", "value": "C140673"},
          {"label": "Van Wert County", "value": "C140674"},
          {"label": "Vandalia", "value": "C140675"},
          {"label": "Vermilion", "value": "C140676"},
          {"label": "Vermilion-on-the-Lake", "value": "C140677"},
          {"label": "Versailles", "value": "C140678"},
          {"label": "Vinton County", "value": "C140679"},
          {"label": "Wadsworth", "value": "C140680"},
          {"label": "Wakeman", "value": "C140681"},
          {"label": "Walbridge", "value": "C140682"},
          {"label": "Walton Hills", "value": "C140683"},
          {"label": "Wapakoneta", "value": "C140684"},
          {"label": "Warren", "value": "C140685"},
          {"label": "Warren County", "value": "C140686"},
          {"label": "Warrensville Heights", "value": "C140687"},
          {"label": "Washington County", "value": "C140688"},
          {"label": "Washington Court House", "value": "C140689"},
          {"label": "Waterville", "value": "C140690"},
          {"label": "Wauseon", "value": "C140691"},
          {"label": "Waverly", "value": "C140692"},
          {"label": "Wayne County", "value": "C140693"},
          {"label": "Waynesville", "value": "C140694"},
          {"label": "Wellington", "value": "C140695"},
          {"label": "Wellston", "value": "C140696"},
          {"label": "Wellsville", "value": "C140697"},
          {"label": "West Alexandria", "value": "C140698"},
          {"label": "West Carrollton City", "value": "C140699"},
          {"label": "West Hill", "value": "C140700"},
          {"label": "West Jefferson", "value": "C140701"},
          {"label": "West Lafayette", "value": "C140702"},
          {"label": "West Liberty", "value": "C140703"},
          {"label": "West Milton", "value": "C140704"},
          {"label": "West Portsmouth", "value": "C140705"},
          {"label": "West Salem", "value": "C140706"},
          {"label": "West Union", "value": "C140707"},
          {"label": "West Unity", "value": "C140708"},
          {"label": "Westerville", "value": "C140709"},
          {"label": "Westfield Center", "value": "C140710"},
          {"label": "Westlake", "value": "C140711"},
          {"label": "Weston", "value": "C140712"},
          {"label": "Wetherington", "value": "C140713"},
          {"label": "Wheelersburg", "value": "C140714"},
          {"label": "White Oak", "value": "C140715"},
          {"label": "Whitehall", "value": "C140716"},
          {"label": "Whitehouse", "value": "C140717"},
          {"label": "Wickliffe", "value": "C140718"},
          {"label": "Wilberforce", "value": "C140719"},
          {"label": "Willard", "value": "C140720"},
          {"label": "Williams County", "value": "C140721"},
          {"label": "Williamsburg", "value": "C140722"},
          {"label": "Williamsport", "value": "C140723"},
          {"label": "Willoughby", "value": "C140724"},
          {"label": "Willoughby Hills", "value": "C140725"},
          {"label": "Willowick", "value": "C140726"},
          {"label": "Wilmington", "value": "C140727"},
          {"label": "Winchester", "value": "C140728"},
          {"label": "Windham", "value": "C140729"},
          {"label": "Wintersville", "value": "C140730"},
          {"label": "Withamsville", "value": "C140731"},
          {"label": "Wolfhurst", "value": "C140732"},
          {"label": "Wood County", "value": "C140733"},
          {"label": "Woodlawn", "value": "C140734"},
          {"label": "Woodsfield", "value": "C140735"},
          {"label": "Woodville", "value": "C140736"},
          {"label": "Wooster", "value": "C140737"},
          {"label": "Worthington", "value": "C140738"},
          {"label": "Wright-Patterson AFB", "value": "C140739"},
          {"label": "Wyandot County", "value": "C140740"},
          {"label": "Wyoming", "value": "C140741"},
          {"label": "Xenia", "value": "C140742"},
          {"label": "Yellow Springs", "value": "C140743"},
          {"label": "Yorkville", "value": "C140744"},
          {"label": "Youngstown", "value": "C140745"},
          {"label": "Zanesville", "value": "C140746"}],
 "Ohrid Municipality": [{"label": "Kosel", "value": "C65470"},
                        {"label": "Ohrid", "value": "C65471"},
                        {"label": "Ohrid Opstina", "value": "C65472"}],
 "Oio Region": [{"label": "Bissora", "value": "C43059"},
                {"label": "Farim", "value": "C43060"},
                {"label": "Mansoa", "value": "C43061"}],
 "Oita Prefecture": [{"label": "Beppu", "value": "C63653"},
                     {"label": "Beppu Shi", "value": "C63654"},
                     {"label": "Bungo-ono Shi", "value": "C63655"},
                     {"label": "Bungo-Takada-shi", "value": "C63656"},
                     {"label": "Hiji", "value": "C63657"},
                     {"label": "Hita", "value": "C63658"},
                     {"label": "Hita Shi", "value": "C63659"},
                     {"label": "Kitsuki", "value": "C63660"},
                     {"label": "Kitsuki Shi", "value": "C63661"},
                     {"label": "Kunisaki-shi", "value": "C63662"},
                     {"label": "Nakatsu", "value": "C63663"},
                     {"label": "Nakatsu Shi", "value": "C63664"},
                     {"label": "Oita", "value": "C63665"},
                     {"label": "Oita-shi", "value": "C63666"},
                     {"label": "Saiki", "value": "C63667"},
                     {"label": "Saiki-shi", "value": "C63668"},
                     {"label": "Takedamachi", "value": "C63669"},
                     {"label": "Taketa-shi", "value": "C63670"},
                     {"label": "Tsukawaki", "value": "C63671"},
                     {"label": "Tsukumi-shi", "value": "C63672"},
                     {"label": "Tsukumiura", "value": "C63673"},
                     {"label": "Tsurusaki", "value": "C63674"},
                     {"label": "Usa Shi", "value": "C63675"},
                     {"label": "Usuki", "value": "C63676"},
                     {"label": "Usuki Shi", "value": "C63677"},
                     {"label": "Yufu-shi", "value": "C63678"}],
 "Okayama Prefecture": [{"label": "Akaiwa Shi", "value": "C63679"},
                        {"label": "Asakuchi Shi", "value": "C63680"},
                        {"label": "Bizen Shi", "value": "C63681"},
                        {"label": "Ibara", "value": "C63682"},
                        {"label": "Ibara Shi", "value": "C63683"},
                        {"label": "Kamogatacho-kamogata", "value": "C63684"},
                        {"label": "Kasaoka", "value": "C63685"},
                        {"label": "Kasaoka Shi", "value": "C63686"},
                        {"label": "Kurashiki", "value": "C63687"},
                        {"label": "Kurashiki Shi", "value": "C63688"},
                        {"label": "Maniwa-shi", "value": "C63689"},
                        {"label": "Mimasaka-shi", "value": "C63690"},
                        {"label": "Niimi", "value": "C63691"},
                        {"label": "Niimi Shi", "value": "C63692"},
                        {"label": "Okayama", "value": "C63693"},
                        {"label": "Okayama Shi", "value": "C63694"},
                        {"label": "Setouchi Shi", "value": "C63695"},
                        {"label": "Soja", "value": "C63696"},
                        {"label": "Soja Shi", "value": "C63697"},
                        {"label": "Takahashi", "value": "C63698"},
                        {"label": "Takahashi Shi", "value": "C63699"},
                        {"label": "Tamano", "value": "C63700"},
                        {"label": "Tamano Shi", "value": "C63701"},
                        {"label": "Tsuyama", "value": "C63702"},
                        {"label": "Tsuyama-shi", "value": "C63703"}],
 "Okinawa Prefecture": [{"label": "Chatan", "value": "C63704"},
                        {"label": "Ginowan Shi", "value": "C63705"},
                        {"label": "Gushikawa", "value": "C63706"},
                        {"label": "Hirara", "value": "C63707"},
                        {"label": "Ishigaki", "value": "C63708"},
                        {"label": "Ishigaki-shi", "value": "C63709"},
                        {"label": "Ishikawa", "value": "C63710"},
                        {"label": "Itoman", "value": "C63711"},
                        {"label": "Itoman Shi", "value": "C63712"},
                        {"label": "Katsuren-haebaru", "value": "C63713"},
                        {"label": "Miyakojima Shi", "value": "C63714"},
                        {"label": "Nago", "value": "C63715"},
                        {"label": "Nago Shi", "value": "C63716"},
                        {"label": "Naha", "value": "C63717"},
                        {"label": "Naha Shi", "value": "C63718"},
                        {"label": "Nakagami-gun", "value": "C63719"},
                        {"label": "Nanjo", "value": "C63720"},
                        {"label": "Nanjo Shi", "value": "C63721"},
                        {"label": "Okinawa", "value": "C63722"},
                        {"label": "Okinawa Shi", "value": "C63723"},
                        {"label": "Tomigusuku", "value": "C63724"},
                        {"label": "Tomigusuku-shi", "value": "C63725"},
                        {"label": "Urasoe Shi", "value": "C63726"},
                        {"label": "Uruma Shi", "value": "C63727"},
                        {"label": "Yonakuni", "value": "C63728"}],
 "Oklahoma": [{"label": "Ada", "value": "C140747"},
              {"label": "Adair County", "value": "C140748"},
              {"label": "Afton", "value": "C140749"},
              {"label": "Alfalfa County", "value": "C140750"},
              {"label": "Altus", "value": "C140751"},
              {"label": "Alva", "value": "C140752"},
              {"label": "Anadarko", "value": "C140753"},
              {"label": "Antlers", "value": "C140754"},
              {"label": "Apache", "value": "C140755"},
              {"label": "Arapaho", "value": "C140756"},
              {"label": "Ardmore", "value": "C140757"},
              {"label": "Arkoma", "value": "C140758"},
              {"label": "Arnett", "value": "C140759"},
              {"label": "Atoka", "value": "C140760"},
              {"label": "Atoka County", "value": "C140761"},
              {"label": "Barnsdall", "value": "C140762"},
              {"label": "Bartlesville", "value": "C140763"},
              {"label": "Beaver", "value": "C140764"},
              {"label": "Beaver County", "value": "C140765"},
              {"label": "Beckham County", "value": "C140766"},
              {"label": "Beggs", "value": "C140767"},
              {"label": "Bethany", "value": "C140768"},
              {"label": "Bethel Acres", "value": "C140769"},
              {"label": "Bixby", "value": "C140770"},
              {"label": "Blackwell", "value": "C140771"},
              {"label": "Blaine County", "value": "C140772"},
              {"label": "Blanchard", "value": "C140773"},
              {"label": "Boise City", "value": "C140774"},
              {"label": "Boley", "value": "C140775"},
              {"label": "Bray", "value": "C140776"},
              {"label": "Bristow", "value": "C140777"},
              {"label": "Broken Arrow", "value": "C140778"},
              {"label": "Broken Bow", "value": "C140779"},
              {"label": "Bryan County", "value": "C140780"},
              {"label": "Buffalo", "value": "C140781"},
              {"label": "Burns Flat", "value": "C140782"},
              {"label": "Bushyhead", "value": "C140783"},
              {"label": "Byng", "value": "C140784"},
              {"label": "Cache", "value": "C140785"},
              {"label": "Caddo", "value": "C140786"},
              {"label": "Caddo County", "value": "C140787"},
              {"label": "Calera", "value": "C140788"},
              {"label": "Canadian County", "value": "C140789"},
              {"label": "Carnegie", "value": "C140790"},
              {"label": "Carter County", "value": "C140791"},
              {"label": "Catoosa", "value": "C140792"},
              {"label": "Central High", "value": "C140793"},
              {"label": "Chandler", "value": "C140794"},
              {"label": "Checotah", "value": "C140795"},
              {"label": "Chelsea", "value": "C140796"},
              {"label": "Cherokee", "value": "C140797"},
              {"label": "Cherokee County", "value": "C140798"},
              {"label": "Cheyenne", "value": "C140799"},
              {"label": "Chickasha", "value": "C140800"},
              {"label": "Choctaw", "value": "C140801"},
              {"label": "Choctaw County", "value": "C140802"},
              {"label": "Chouteau", "value": "C140803"},
              {"label": "Cimarron County", "value": "C140804"},
              {"label": "Claremore", "value": "C140805"},
              {"label": "Cleora", "value": "C140806"},
              {"label": "Cleveland", "value": "C140807"},
              {"label": "Cleveland County", "value": "C140808"},
              {"label": "Clinton", "value": "C140809"},
              {"label": "Coal County", "value": "C140810"},
              {"label": "Coalgate", "value": "C140811"},
              {"label": "Colbert", "value": "C140812"},
              {"label": "Collinsville", "value": "C140813"},
              {"label": "Comanche", "value": "C140814"},
              {"label": "Comanche County", "value": "C140815"},
              {"label": "Commerce", "value": "C140816"},
              {"label": "Copeland", "value": "C140817"},
              {"label": "Cordell", "value": "C140818"},
              {"label": "Cotton County", "value": "C140819"},
              {"label": "Coweta", "value": "C140820"},
              {"label": "Craig County", "value": "C140821"},
              {"label": "Creek County", "value": "C140822"},
              {"label": "Crescent", "value": "C140823"},
              {"label": "Cushing", "value": "C140824"},
              {"label": "Custer County", "value": "C140825"},
              {"label": "Cyril", "value": "C140826"},
              {"label": "Davis", "value": "C140827"},
              {"label": "Del City", "value": "C140828"},
              {"label": "Delaware County", "value": "C140829"},
              {"label": "Dewey", "value": "C140830"},
              {"label": "Dewey County", "value": "C140831"},
              {"label": "Dickson", "value": "C140832"},
              {"label": "Drumright", "value": "C140833"},
              {"label": "Duncan", "value": "C140834"},
              {"label": "Durant", "value": "C140835"},
              {"label": "Edmond", "value": "C140836"},
              {"label": "El Reno", "value": "C140837"},
              {"label": "Elgin", "value": "C140838"},
              {"label": "Elk City", "value": "C140839"},
              {"label": "Ellis County", "value": "C140840"},
              {"label": "Enid", "value": "C140841"},
              {"label": "Erick", "value": "C140842"},
              {"label": "Eufaula", "value": "C140843"},
              {"label": "Fairfax", "value": "C140844"},
              {"label": "Fairland", "value": "C140845"},
              {"label": "Fairview", "value": "C140846"},
              {"label": "Fletcher", "value": "C140847"},
              {"label": "Forest Park", "value": "C140848"},
              {"label": "Fort Gibson", "value": "C140849"},
              {"label": "Frederick", "value": "C140850"},
              {"label": "Garfield County", "value": "C140851"},
              {"label": "Garvin County", "value": "C140852"},
              {"label": "Geary", "value": "C140853"},
              {"label": "Geronimo", "value": "C140854"},
              {"label": "Glenpool", "value": "C140855"},
              {"label": "Goldsby", "value": "C140856"},
              {"label": "Goodwell", "value": "C140857"},
              {"label": "Grady County", "value": "C140858"},
              {"label": "Granite", "value": "C140859"},
              {"label": "Grant County", "value": "C140860"},
              {"label": "Greer County", "value": "C140861"},
              {"label": "Grove", "value": "C140862"},
              {"label": "Guthrie", "value": "C140863"},
              {"label": "Guymon", "value": "C140864"},
              {"label": "Hall Park", "value": "C140865"},
              {"label": "Harmon County", "value": "C140866"},
              {"label": "Harper County", "value": "C140867"},
              {"label": "Harrah", "value": "C140868"},
              {"label": "Hartshorne", "value": "C140869"},
              {"label": "Haskell", "value": "C140870"},
              {"label": "Haskell County", "value": "C140871"},
              {"label": "Healdton", "value": "C140872"},
              {"label": "Heavener", "value": "C140873"},
              {"label": "Helena", "value": "C140874"},
              {"label": "Hennessey", "value": "C140875"},
              {"label": "Henryetta", "value": "C140876"},
              {"label": "Hinton", "value": "C140877"},
              {"label": "Hobart", "value": "C140878"},
              {"label": "Holdenville", "value": "C140879"},
              {"label": "Hollis", "value": "C140880"},
              {"label": "Hominy", "value": "C140881"},
              {"label": "Hooker", "value": "C140882"},
              {"label": "Hughes County", "value": "C140883"},
              {"label": "Hugo", "value": "C140884"},
              {"label": "Idabel", "value": "C140885"},
              {"label": "Inola", "value": "C140886"},
              {"label": "Jackson County", "value": "C140887"},
              {"label": "Jay", "value": "C140888"},
              {"label": "Jefferson County", "value": "C140889"},
              {"label": "Jenks", "value": "C140890"},
              {"label": "Johnston County", "value": "C140891"},
              {"label": "Jones", "value": "C140892"},
              {"label": "Justice", "value": "C140893"},
              {"label": "Kay County", "value": "C140894"},
              {"label": "Kellyville", "value": "C140895"},
              {"label": "Kenwood", "value": "C140896"},
              {"label": "Kiefer", "value": "C140897"},
              {"label": "Kingfisher", "value": "C140898"},
              {"label": "Kingfisher County", "value": "C140899"},
              {"label": "Kingston", "value": "C140900"},
              {"label": "Kiowa County", "value": "C140901"},
              {"label": "Konawa", "value": "C140902"},
              {"label": "Krebs", "value": "C140903"},
              {"label": "Langston", "value": "C140904"},
              {"label": "Latimer County", "value": "C140905"},
              {"label": "Laverne", "value": "C140906"},
              {"label": "Lawton", "value": "C140907"},
              {"label": "Le Flore County", "value": "C140908"},
              {"label": "Lexington", "value": "C140909"},
              {"label": "Lincoln County", "value": "C140910"},
              {"label": "Lindsay", "value": "C140911"},
              {"label": "Locust Grove", "value": "C140912"},
              {"label": "Logan County", "value": "C140913"},
              {"label": "Lone Grove", "value": "C140914"},
              {"label": "Longtown", "value": "C140915"},
              {"label": "Love County", "value": "C140916"},
              {"label": "Luther", "value": "C140917"},
              {"label": "Madill", "value": "C140918"},
              {"label": "Major County", "value": "C140919"},
              {"label": "Mangum", "value": "C140920"},
              {"label": "Mannford", "value": "C140921"},
              {"label": "Marietta", "value": "C140922"},
              {"label": "Marlow", "value": "C140923"},
              {"label": "Marshall County", "value": "C140924"},
              {"label": "Maud", "value": "C140925"},
              {"label": "Mayes County", "value": "C140926"},
              {"label": "Maysville", "value": "C140927"},
              {"label": "McAlester", "value": "C140928"},
              {"label": "McClain County", "value": "C140929"},
              {"label": "McCord", "value": "C140930"},
              {"label": "McCurtain County", "value": "C140931"},
              {"label": "McIntosh County", "value": "C140932"},
              {"label": "McLoud", "value": "C140933"},
              {"label": "Medford", "value": "C140934"},
              {"label": "Meeker", "value": "C140935"},
              {"label": "Meridian", "value": "C140936"},
              {"label": "Miami", "value": "C140937"},
              {"label": "Midwest City", "value": "C140938"},
              {"label": "Minco", "value": "C140939"},
              {"label": "Moore", "value": "C140940"},
              {"label": "Mooreland", "value": "C140941"},
              {"label": "Morris", "value": "C140942"},
              {"label": "Mounds", "value": "C140943"},
              {"label": "Muldrow", "value": "C140944"},
              {"label": "Murray County", "value": "C140945"},
              {"label": "Muskogee", "value": "C140946"},
              {"label": "Muskogee County", "value": "C140947"},
              {"label": "Mustang", "value": "C140948"},
              {"label": "Newcastle", "value": "C140949"},
              {"label": "Newkirk", "value": "C140950"},
              {"label": "Nichols Hills", "value": "C140951"},
              {"label": "Nicoma Park", "value": "C140952"},
              {"label": "Ninnekah", "value": "C140953"},
              {"label": "Noble", "value": "C140954"},
              {"label": "Noble County", "value": "C140955"},
              {"label": "Norman", "value": "C140956"},
              {"label": "Nowata", "value": "C140957"},
              {"label": "Nowata County", "value": "C140958"},
              {"label": "Oakhurst", "value": "C140959"},
              {"label": "Oakland", "value": "C140960"},
              {"label": "Oilton", "value": "C140961"},
              {"label": "Okarche", "value": "C140962"},
              {"label": "Okeene", "value": "C140963"},
              {"label": "Okemah", "value": "C140964"},
              {"label": "Okfuskee County", "value": "C140965"},
              {"label": "Oklahoma City", "value": "C140966"},
              {"label": "Oklahoma County", "value": "C140967"},
              {"label": "Okmulgee", "value": "C140968"},
              {"label": "Okmulgee County", "value": "C140969"},
              {"label": "Oologah", "value": "C140970"},
              {"label": "Osage County", "value": "C140971"},
              {"label": "Ottawa County", "value": "C140972"},
              {"label": "Owasso", "value": "C140973"},
              {"label": "Panama", "value": "C140974"},
              {"label": "Park Hill", "value": "C140975"},
              {"label": "Pauls Valley", "value": "C140976"},
              {"label": "Pawhuska", "value": "C140977"},
              {"label": "Pawnee", "value": "C140978"},
              {"label": "Pawnee County", "value": "C140979"},
              {"label": "Payne County", "value": "C140980"},
              {"label": "Perkins", "value": "C140981"},
              {"label": "Perry", "value": "C140982"},
              {"label": "Piedmont", "value": "C140983"},
              {"label": "Pink", "value": "C140984"},
              {"label": "Pittsburg County", "value": "C140985"},
              {"label": "Pocola", "value": "C140986"},
              {"label": "Ponca City", "value": "C140987"},
              {"label": "Pontotoc County", "value": "C140988"},
              {"label": "Poteau", "value": "C140989"},
              {"label": "Pottawatomie County", "value": "C140990"},
              {"label": "Prague", "value": "C140991"},
              {"label": "Pryor", "value": "C140992"},
              {"label": "Pryor Creek", "value": "C140993"},
              {"label": "Purcell", "value": "C140994"},
              {"label": "Pushmataha County", "value": "C140995"},
              {"label": "Quinton", "value": "C140996"},
              {"label": "Ringling", "value": "C140997"},
              {"label": "Roger Mills County", "value": "C140998"},
              {"label": "Rogers County", "value": "C140999"},
              {"label": "Roland", "value": "C141000"},
              {"label": "Rush Springs", "value": "C141001"},
              {"label": "Salina", "value": "C141002"},
              {"label": "Sallisaw", "value": "C141003"},
              {"label": "Sand Springs", "value": "C141004"},
              {"label": "Sapulpa", "value": "C141005"},
              {"label": "Sayre", "value": "C141006"},
              {"label": "Seminole", "value": "C141007"},
              {"label": "Seminole County", "value": "C141008"},
              {"label": "Sequoyah County", "value": "C141009"},
              {"label": "Shattuck", "value": "C141010"},
              {"label": "Shawnee", "value": "C141011"},
              {"label": "Skiatook", "value": "C141012"},
              {"label": "Slaughterville", "value": "C141013"},
              {"label": "Snyder", "value": "C141014"},
              {"label": "Spencer", "value": "C141015"},
              {"label": "Sperry", "value": "C141016"},
              {"label": "Spiro", "value": "C141017"},
              {"label": "Stephens County", "value": "C141018"},
              {"label": "Stigler", "value": "C141019"},
              {"label": "Stillwater", "value": "C141020"},
              {"label": "Stilwell", "value": "C141021"},
              {"label": "Stratford", "value": "C141022"},
              {"label": "Stroud", "value": "C141023"},
              {"label": "Sulphur", "value": "C141024"},
              {"label": "Tahlequah", "value": "C141025"},
              {"label": "Talihina", "value": "C141026"},
              {"label": "Taloga", "value": "C141027"},
              {"label": "Tecumseh", "value": "C141028"},
              {"label": "Texanna", "value": "C141029"},
              {"label": "Texas County", "value": "C141030"},
              {"label": "The Village", "value": "C141031"},
              {"label": "Thomas", "value": "C141032"},
              {"label": "Tillman County", "value": "C141033"},
              {"label": "Tishomingo", "value": "C141034"},
              {"label": "Tonkawa", "value": "C141035"},
              {"label": "Tulsa", "value": "C141036"},
              {"label": "Tulsa County", "value": "C141037"},
              {"label": "Turley", "value": "C141038"},
              {"label": "Tuttle", "value": "C141039"},
              {"label": "Union City", "value": "C141040"},
              {"label": "Verdigris", "value": "C141041"},
              {"label": "Vian", "value": "C141042"},
              {"label": "Vinita", "value": "C141043"},
              {"label": "Wagoner", "value": "C141044"},
              {"label": "Wagoner County", "value": "C141045"},
              {"label": "Walters", "value": "C141046"},
              {"label": "Warner", "value": "C141047"},
              {"label": "Warr Acres", "value": "C141048"},
              {"label": "Washington County", "value": "C141049"},
              {"label": "Washita County", "value": "C141050"},
              {"label": "Watonga", "value": "C141051"},
              {"label": "Waukomis", "value": "C141052"},
              {"label": "Waurika", "value": "C141053"},
              {"label": "Weatherford", "value": "C141054"},
              {"label": "Westville", "value": "C141055"},
              {"label": "Wetumka", "value": "C141056"},
              {"label": "Wewoka", "value": "C141057"},
              {"label": "Wilburton", "value": "C141058"},
              {"label": "Wilson", "value": "C141059"},
              {"label": "Wister", "value": "C141060"},
              {"label": "Woods County", "value": "C141061"},
              {"label": "Woodward", "value": "C141062"},
              {"label": "Woodward County", "value": "C141063"},
              {"label": "Wynnewood", "value": "C141064"},
              {"label": "Yale", "value": "C141065"},
              {"label": "Yukon", "value": "C141066"}],
 "Olaine Municipality": [{"label": "Olaine", "value": "C64908"}],
 "Olancho Department": [{"label": "Arimis", "value": "C43604"},
                        {"label": "Campamento", "value": "C43605"},
                        {"label": "Catacamas", "value": "C43606"},
                        {"label": "Concordia", "value": "C43607"},
                        {"label": "Dulce Nombre de Culmi", "value": "C43608"},
                        {"label": "El Guayabito", "value": "C43609"},
                        {"label": "El Rosario", "value": "C43610"},
                        {"label": "El Rusio", "value": "C43611"},
                        {"label": "Esquipulas del Norte", "value": "C43612"},
                        {"label": "Gualaco", "value": "C43613"},
                        {"label": "Guarizama", "value": "C43614"},
                        {"label": "Guata", "value": "C43615"},
                        {"label": "Guayape", "value": "C43616"},
                        {"label": "Jano", "value": "C43617"},
                        {"label": "Juticalpa", "value": "C43618"},
                        {"label": "Jutiquile", "value": "C43619"},
                        {"label": "La Concepcion", "value": "C43620"},
                        {"label": "La Estancia", "value": "C43621"},
                        {"label": "La Guata", "value": "C43622"},
                        {"label": "La Union", "value": "C43623"},
                        {"label": "Laguna Seca", "value": "C43624"},
                        {"label": "Mangulile", "value": "C43625"},
                        {"label": "Manto", "value": "C43626"},
                        {"label": "Municipio de San Francisco de La Paz",
                         "value": "C43627"},
                        {"label": "Patuca", "value": "C43628"},
                        {"label": "Punuare", "value": "C43629"},
                        {"label": "Salama", "value": "C43630"},
                        {"label": "San Esteban", "value": "C43631"},
                        {"label": "San Francisco de Becerra",
                         "value": "C43632"},
                        {"label": "San Francisco de la Paz", "value": "C43633"},
                        {"label": "San Jose de Rio Tinto", "value": "C43634"},
                        {"label": "San Nicolas", "value": "C43635"},
                        {"label": "Santa Maria del Real", "value": "C43636"},
                        {"label": "Silca", "value": "C43637"},
                        {"label": "Yocon", "value": "C43638"},
                        {"label": "Zopilotepe", "value": "C43639"}],
 "Old Royal Capital Cetinje": [{"label": "Cetinje", "value": "C75613"}],
 "Olomoucky kraj": [{"label": "Bedihost", "value": "C22624"},
                    {"label": "Belotin", "value": "C22625"},
                    {"label": "Bila Lhota", "value": "C22626"},
                    {"label": "Bludov", "value": "C22627"},
                    {"label": "Bohunovice", "value": "C22628"},
                    {"label": "Bouzov", "value": "C22629"},
                    {"label": "Brodek u Prerova", "value": "C22630"},
                    {"label": "Celechovice na Hane", "value": "C22631"},
                    {"label": "Cervenka", "value": "C22632"},
                    {"label": "Ceska Ves", "value": "C22633"},
                    {"label": "Dolany", "value": "C22634"},
                    {"label": "Dolni Bohdikov", "value": "C22635"},
                    {"label": "Dolni Studenky", "value": "C22636"},
                    {"label": "Dolni Ujezd", "value": "C22637"},
                    {"label": "Doloplazy", "value": "C22638"},
                    {"label": "Drahanovice", "value": "C22639"},
                    {"label": "Drevohostice", "value": "C22640"},
                    {"label": "Dub nad Moravou", "value": "C22641"},
                    {"label": "Dubicko", "value": "C22642"},
                    {"label": "Grygov", "value": "C22643"},
                    {"label": "Hanusovice", "value": "C22644"},
                    {"label": "Hlubocky", "value": "C22645"},
                    {"label": "Hnevotin", "value": "C22646"},
                    {"label": "Horka nad Moravou", "value": "C22647"},
                    {"label": "Horni Mostenice", "value": "C22648"},
                    {"label": "Horni Stepanov", "value": "C22649"},
                    {"label": "Hranice", "value": "C22650"},
                    {"label": "Hustopece Nad Becvou", "value": "C22651"},
                    {"label": "Javornik", "value": "C22652"},
                    {"label": "Jesenik", "value": "C22653"},
                    {"label": "Kojetin", "value": "C22654"},
                    {"label": "Kokory", "value": "C22655"},
                    {"label": "Konice", "value": "C22656"},
                    {"label": "Kostelec na Hane", "value": "C22657"},
                    {"label": "Kralice na Hane", "value": "C22658"},
                    {"label": "Lestina", "value": "C22659"},
                    {"label": "Lipnik nad Becvou", "value": "C22660"},
                    {"label": "Litovel", "value": "C22661"},
                    {"label": "Lostice", "value": "C22662"},
                    {"label": "Loucna nad Desnou", "value": "C22663"},
                    {"label": "Lutin", "value": "C22664"},
                    {"label": "Majetin", "value": "C22665"},
                    {"label": "Medlov", "value": "C22666"},
                    {"label": "Mesto Libava", "value": "C22667"},
                    {"label": "Mikulovice", "value": "C22668"},
                    {"label": "Mohelnice", "value": "C22669"},
                    {"label": "Moravicany", "value": "C22670"},
                    {"label": "Moravsky Beroun", "value": "C22671"},
                    {"label": "Mostkovice", "value": "C22672"},
                    {"label": "Naklo", "value": "C22673"},
                    {"label": "Namest na Hane", "value": "C22674"},
                    {"label": "Nemcice nad Hanou", "value": "C22675"},
                    {"label": "Nezamyslice", "value": "C22676"},
                    {"label": "Novy Malin", "value": "C22677"},
                    {"label": "Okres Jesenik", "value": "C22678"},
                    {"label": "Okres Olomouc", "value": "C22679"},
                    {"label": "Okres Prerov", "value": "C22680"},
                    {"label": "Okres Prostejov", "value": "C22681"},
                    {"label": "Okres Sumperk", "value": "C22682"},
                    {"label": "Olomouc", "value": "C22683"},
                    {"label": "Olsany", "value": "C22684"},
                    {"label": "Osek nad Becvou", "value": "C22685"},
                    {"label": "Oskava", "value": "C22686"},
                    {"label": "Otaslavice", "value": "C22687"},
                    {"label": "Paseka", "value": "C22688"},
                    {"label": "Pisecna", "value": "C22689"},
                    {"label": "Plumlov", "value": "C22690"},
                    {"label": "Postrelmov", "value": "C22691"},
                    {"label": "Potstat", "value": "C22692"},
                    {"label": "Praslavice", "value": "C22693"},
                    {"label": "Premyslovice", "value": "C22694"},
                    {"label": "Prerov", "value": "C22695"},
                    {"label": "Prikazy", "value": "C22696"},
                    {"label": "Prostejov", "value": "C22697"},
                    {"label": "Protivanov", "value": "C22698"},
                    {"label": "Pteni", "value": "C22699"},
                    {"label": "Radslavice", "value": "C22700"},
                    {"label": "Rapotin", "value": "C22701"},
                    {"label": "Rokytnice", "value": "C22702"},
                    {"label": "Ruda nad Moravou", "value": "C22703"},
                    {"label": "Samotisky", "value": "C22704"},
                    {"label": "Senice na Hane", "value": "C22705"},
                    {"label": "Skrben", "value": "C22706"},
                    {"label": "Slatinice", "value": "C22707"},
                    {"label": "Smrzice", "value": "C22708"},
                    {"label": "Sobotin", "value": "C22709"},
                    {"label": "Stare Mesto", "value": "C22710"},
                    {"label": "Stepanov", "value": "C22711"},
                    {"label": "Sternberk", "value": "C22712"},
                    {"label": "Stity", "value": "C22713"},
                    {"label": "Strazna", "value": "C22714"},
                    {"label": "Sudkov", "value": "C22715"},
                    {"label": "Sumperk", "value": "C22716"},
                    {"label": "Tesetice", "value": "C22717"},
                    {"label": "Tovacov", "value": "C22718"},
                    {"label": "Troubelice", "value": "C22719"},
                    {"label": "Troubky", "value": "C22720"},
                    {"label": "Trsice", "value": "C22721"},
                    {"label": "Ujezd", "value": "C22722"},
                    {"label": "Unicov", "value": "C22723"},
                    {"label": "Urcice", "value": "C22724"},
                    {"label": "Usov", "value": "C22725"},
                    {"label": "Vapenna", "value": "C22726"},
                    {"label": "Velka Bystrice", "value": "C22727"},
                    {"label": "Velke Losiny", "value": "C22728"},
                    {"label": "Velky Tynec", "value": "C22729"},
                    {"label": "Velky Ujezd", "value": "C22730"},
                    {"label": "Verovany", "value": "C22731"},
                    {"label": "Vidnava", "value": "C22732"},
                    {"label": "Vikyrovice", "value": "C22733"},
                    {"label": "Vrbatky", "value": "C22734"},
                    {"label": "Zabreh", "value": "C22735"},
                    {"label": "Zlate Hory", "value": "C22736"},
                    {"label": "Zulova", "value": "C22737"}],
 "Olt County": [{"label": "Alimanesti", "value": "C97736"},
                {"label": "Alunisu", "value": "C97737"},
                {"label": "Babiciu", "value": "C97738"},
                {"label": "Bacea", "value": "C97739"},
                {"label": "Balanesti", "value": "C97740"},
                {"label": "Baldovinesti", "value": "C97741"},
                {"label": "Bals", "value": "C97742"},
                {"label": "Balteni", "value": "C97743"},
                {"label": "Barastii de Vede", "value": "C97744"},
                {"label": "Barza", "value": "C97745"},
                {"label": "Bircii", "value": "C97746"},
                {"label": "Bobicesti", "value": "C97747"},
                {"label": "Brancoveni", "value": "C97748"},
                {"label": "Branet", "value": "C97749"},
                {"label": "Brastavatu", "value": "C97750"},
                {"label": "Brebeni", "value": "C97751"},
                {"label": "Bucinisu", "value": "C97752"},
                {"label": "Busca", "value": "C97753"},
                {"label": "Calinesti", "value": "C97754"},
                {"label": "Calui", "value": "C97755"},
                {"label": "Caracal", "value": "C97756"},
                {"label": "Carlogani", "value": "C97757"},
                {"label": "Catanele", "value": "C97758"},
                {"label": "Cepari", "value": "C97759"},
                {"label": "Cezieni", "value": "C97760"},
                {"label": "Cilieni", "value": "C97761"},
                {"label": "Colonesti", "value": "C97762"},
                {"label": "Comanca", "value": "C97763"},
                {"label": "Comani", "value": "C97764"},
                {"label": "Comuna Babiciu", "value": "C97765"},
                {"label": "Comuna Baldovinesti", "value": "C97766"},
                {"label": "Comuna Balteni", "value": "C97767"},
                {"label": "Comuna Barasti", "value": "C97768"},
                {"label": "Comuna Barza", "value": "C97769"},
                {"label": "Comuna Bobicesti", "value": "C97770"},
                {"label": "Comuna Brancoveni", "value": "C97771"},
                {"label": "Comuna Brastavatu", "value": "C97772"},
                {"label": "Comuna Brebeni", "value": "C97773"},
                {"label": "Comuna Bucinisu", "value": "C97774"},
                {"label": "Comuna Calui", "value": "C97775"},
                {"label": "Comuna Carlogani", "value": "C97776"},
                {"label": "Comuna Cezieni", "value": "C97777"},
                {"label": "Comuna Cilieni", "value": "C97778"},
                {"label": "Comuna Colonesti", "value": "C97779"},
                {"label": "Comuna Corbu", "value": "C97780"},
                {"label": "Comuna Coteana", "value": "C97781"},
                {"label": "Comuna Crampoia", "value": "C97782"},
                {"label": "Comuna Cungrea", "value": "C97783"},
                {"label": "Comuna Curtisoara", "value": "C97784"},
                {"label": "Comuna Daneasa", "value": "C97785"},
                {"label": "Comuna Deveselu", "value": "C97786"},
                {"label": "Comuna Dobretu", "value": "C97787"},
                {"label": "Comuna Dobrosloveni", "value": "C97788"},
                {"label": "Comuna Dobroteasa", "value": "C97789"},
                {"label": "Comuna Dobrun", "value": "C97790"},
                {"label": "Comuna Draghiceni", "value": "C97791"},
                {"label": "Comuna Fagetelu", "value": "C97792"},
                {"label": "Comuna Falcoiu", "value": "C97793"},
                {"label": "Comuna Farcasele", "value": "C97794"},
                {"label": "Comuna Ganeasa", "value": "C97795"},
                {"label": "Comuna Garcov", "value": "C97796"},
                {"label": "Comuna Gavanesti", "value": "C97797"},
                {"label": "Comuna Ghimpeteni", "value": "C97798"},
                {"label": "Comuna Giuvarasti", "value": "C97799"},
                {"label": "Comuna Gostavatu", "value": "C97800"},
                {"label": "Comuna Gradinari", "value": "C97801"},
                {"label": "Comuna Gradinile", "value": "C97802"},
                {"label": "Comuna Grojdibodu", "value": "C97803"},
                {"label": "Comuna Gura Padinii", "value": "C97804"},
                {"label": "Comuna Ianca", "value": "C97805"},
                {"label": "Comuna Iancu Jianu", "value": "C97806"},
                {"label": "Comuna Icoana", "value": "C97807"},
                {"label": "Comuna Ipotesti", "value": "C97808"},
                {"label": "Comuna Izbiceni", "value": "C97809"},
                {"label": "Comuna Izvoarele", "value": "C97810"},
                {"label": "Comuna Leleasca", "value": "C97811"},
                {"label": "Comuna Maruntei", "value": "C97812"},
                {"label": "Comuna Mihaesti", "value": "C97813"},
                {"label": "Comuna Milcov", "value": "C97814"},
                {"label": "Comuna Morunglav", "value": "C97815"},
                {"label": "Comuna Movileni", "value": "C97816"},
                {"label": "Comuna Nicolae Titulescu", "value": "C97817"},
                {"label": "Comuna Obarsia", "value": "C97818"},
                {"label": "Comuna Oboga", "value": "C97819"},
                {"label": "Comuna Oporelu", "value": "C97820"},
                {"label": "Comuna Optasi-Magura", "value": "C97821"},
                {"label": "Comuna Orlea", "value": "C97822"},
                {"label": "Comuna Osica de Jos", "value": "C97823"},
                {"label": "Comuna Osica de Sus", "value": "C97824"},
                {"label": "Comuna Parscoveni", "value": "C97825"},
                {"label": "Comuna Perieti", "value": "C97826"},
                {"label": "Comuna Plesoiu", "value": "C97827"},
                {"label": "Comuna Poboru", "value": "C97828"},
                {"label": "Comuna Priseaca", "value": "C97829"},
                {"label": "Comuna Radomiresti", "value": "C97830"},
                {"label": "Comuna Redea", "value": "C97831"},
                {"label": "Comuna Rotunda", "value": "C97832"},
                {"label": "Comuna Rusanesti", "value": "C97833"},
                {"label": "Comuna Samburesti", "value": "C97834"},
                {"label": "Comuna Sarbii-Magura", "value": "C97835"},
                {"label": "Comuna Scarisoara", "value": "C97836"},
                {"label": "Comuna Schitu", "value": "C97837"},
                {"label": "Comuna Seaca", "value": "C97838"},
                {"label": "Comuna Serbanesti", "value": "C97839"},
                {"label": "Comuna Slatioara", "value": "C97840"},
                {"label": "Comuna Soparlita", "value": "C97841"},
                {"label": "Comuna Spineni", "value": "C97842"},
                {"label": "Comuna Sprancenata", "value": "C97843"},
                {"label": "Comuna Stefan cel Mare", "value": "C97844"},
                {"label": "Comuna Stoenesti", "value": "C97845"},
                {"label": "Comuna Stoicanesti", "value": "C97846"},
                {"label": "Comuna Strejesti", "value": "C97847"},
                {"label": "Comuna Studina", "value": "C97848"},
                {"label": "Comuna Tatulesti", "value": "C97849"},
                {"label": "Comuna Teslui", "value": "C97850"},
                {"label": "Comuna Tia Mare", "value": "C97851"},
                {"label": "Comuna Topana", "value": "C97852"},
                {"label": "Comuna Traian", "value": "C97853"},
                {"label": "Comuna Tufeni", "value": "C97854"},
                {"label": "Comuna Urzica", "value": "C97855"},
                {"label": "Comuna Vadastra", "value": "C97856"},
                {"label": "Comuna Vadastrita", "value": "C97857"},
                {"label": "Comuna Valcele", "value": "C97858"},
                {"label": "Comuna Valea Mare", "value": "C97859"},
                {"label": "Comuna Valeni", "value": "C97860"},
                {"label": "Comuna Verguleasa", "value": "C97861"},
                {"label": "Comuna Visina", "value": "C97862"},
                {"label": "Comuna Visina Noua", "value": "C97863"},
                {"label": "Comuna Vitomiresti", "value": "C97864"},
                {"label": "Comuna Vladila", "value": "C97865"},
                {"label": "Comuna Voineasa", "value": "C97866"},
                {"label": "Comuna Vulpeni", "value": "C97867"},
                {"label": "Comuna Vulturesti", "value": "C97868"},
                {"label": "Corabia", "value": "C97869"},
                {"label": "Corbu", "value": "C97870"},
                {"label": "Coteana", "value": "C97871"},
                {"label": "Craciunei", "value": "C97872"},
                {"label": "Crampoia", "value": "C97873"},
                {"label": "Crusovu", "value": "C97874"},
                {"label": "Cungrea", "value": "C97875"},
                {"label": "Curtisoara", "value": "C97876"},
                {"label": "Daneasa", "value": "C97877"},
                {"label": "Dejesti", "value": "C97878"},
                {"label": "Deveselu", "value": "C97879"},
                {"label": "Doanca", "value": "C97880"},
                {"label": "Dobretu", "value": "C97881"},
                {"label": "Dobrosloveni", "value": "C97882"},
                {"label": "Dobroteasa", "value": "C97883"},
                {"label": "Dobrotinet", "value": "C97884"},
                {"label": "Dobrun", "value": "C97885"},
                {"label": "Draganesti-Olt", "value": "C97886"},
                {"label": "Draghiceni", "value": "C97887"},
                {"label": "Dranovatu", "value": "C97888"},
                {"label": "Dumitresti", "value": "C97889"},
                {"label": "Fagetelu", "value": "C97890"},
                {"label": "Falcoiu", "value": "C97891"},
                {"label": "Farcasele", "value": "C97892"},
                {"label": "Farcasu de Jos", "value": "C97893"},
                {"label": "Ganeasa", "value": "C97894"},
                {"label": "Garcov", "value": "C97895"},
                {"label": "Gavanesti", "value": "C97896"},
                {"label": "Ghimpati", "value": "C97897"},
                {"label": "Ghimpeteni", "value": "C97898"},
                {"label": "Ghioca", "value": "C97899"},
                {"label": "Giuvarasti", "value": "C97900"},
                {"label": "Gostavatu", "value": "C97901"},
                {"label": "Gradinari", "value": "C97902"},
                {"label": "Greci", "value": "C97903"},
                {"label": "Grojdibodu", "value": "C97904"},
                {"label": "Gropsani", "value": "C97905"},
                {"label": "Gura Caluiu", "value": "C97906"},
                {"label": "Gura Padinii", "value": "C97907"},
                {"label": "Ianca", "value": "C97908"},
                {"label": "Iancu Jianu", "value": "C97909"},
                {"label": "Icoana", "value": "C97910"},
                {"label": "Ipotesti", "value": "C97911"},
                {"label": "Izbiceni", "value": "C97912"},
                {"label": "Izvoarele", "value": "C97913"},
                {"label": "Jieni", "value": "C97914"},
                {"label": "Leleasca", "value": "C97915"},
                {"label": "Margineni Slobozia", "value": "C97916"},
                {"label": "Maruntei", "value": "C97917"},
                {"label": "Mihaesti", "value": "C97918"},
                {"label": "Morunglav", "value": "C97919"},
                {"label": "Movileni", "value": "C97920"},
                {"label": "Municipiul Caracal", "value": "C97921"},
                {"label": "Municipiul Slatina", "value": "C97922"},
                {"label": "Negreni", "value": "C97923"},
                {"label": "Nicolae Titulescu", "value": "C97924"},
                {"label": "Obarsia", "value": "C97925"},
                {"label": "Oboga", "value": "C97926"},
                {"label": "Olari", "value": "C97927"},
                {"label": "Oporelu", "value": "C97928"},
                {"label": "Optasi", "value": "C97929"},
                {"label": "Oras Bals", "value": "C97930"},
                {"label": "Oras Corabia", "value": "C97931"},
                {"label": "Oras Draganesti-Olt", "value": "C97932"},
                {"label": "Oras Piatra-Olt", "value": "C97933"},
                {"label": "Oras Potcoava", "value": "C97934"},
                {"label": "Oras Scornicesti", "value": "C97935"},
                {"label": "Orlea", "value": "C97936"},
                {"label": "Orlea Noua", "value": "C97937"},
                {"label": "Osica de Jos", "value": "C97938"},
                {"label": "Osica de Sus", "value": "C97939"},
                {"label": "Parscoveni", "value": "C97940"},
                {"label": "Perieti", "value": "C97941"},
                {"label": "Piatra", "value": "C97942"},
                {"label": "Piatra Olt", "value": "C97943"},
                {"label": "Plesoiu", "value": "C97944"},
                {"label": "Poboru", "value": "C97945"},
                {"label": "Potcoava", "value": "C97946"},
                {"label": "Potcoava Falcoeni", "value": "C97947"},
                {"label": "Potelu", "value": "C97948"},
                {"label": "Potlogeni", "value": "C97949"},
                {"label": "Priseaca", "value": "C97950"},
                {"label": "Radomiresti", "value": "C97951"},
                {"label": "Redea", "value": "C97952"},
                {"label": "Rotunda", "value": "C97953"},
                {"label": "Rusanesti", "value": "C97954"},
                {"label": "Samburesti", "value": "C97955"},
                {"label": "Scarisoara", "value": "C97956"},
                {"label": "Schitu", "value": "C97957"},
                {"label": "Scornicesti", "value": "C97958"},
                {"label": "Seaca", "value": "C97959"},
                {"label": "Serbanesti", "value": "C97960"},
                {"label": "Sinesti", "value": "C97961"},
                {"label": "Slatina", "value": "C97962"},
                {"label": "Slatioara", "value": "C97963"},
                {"label": "Slaveni", "value": "C97964"},
                {"label": "Soparlita", "value": "C97965"},
                {"label": "Sprancenata", "value": "C97966"},
                {"label": "Stavaru", "value": "C97967"},
                {"label": "Stefan cel Mare", "value": "C97968"},
                {"label": "Stoenesti", "value": "C97969"},
                {"label": "Stoicanesti", "value": "C97970"},
                {"label": "Strejesti", "value": "C97971"},
                {"label": "Strejestii de Sus", "value": "C97972"},
                {"label": "Studina", "value": "C97973"},
                {"label": "Studinita", "value": "C97974"},
                {"label": "Tatulesti", "value": "C97975"},
                {"label": "Teslui", "value": "C97976"},
                {"label": "Tia Mare", "value": "C97977"},
                {"label": "Topana", "value": "C97978"},
                {"label": "Traian", "value": "C97979"},
                {"label": "Tufeni", "value": "C97980"},
                {"label": "Ursa", "value": "C97981"},
                {"label": "Urzica", "value": "C97982"},
                {"label": "Vadastra", "value": "C97983"},
                {"label": "Vadastrita", "value": "C97984"},
                {"label": "Valcele", "value": "C97985"},
                {"label": "Valcelele de Sus", "value": "C97986"},
                {"label": "Valea Mare", "value": "C97987"},
                {"label": "Valea Merilor", "value": "C97988"},
                {"label": "Valeni", "value": "C97989"},
                {"label": "Verguleasa", "value": "C97990"},
                {"label": "Visina", "value": "C97991"},
                {"label": "Visina Noua", "value": "C97992"},
                {"label": "Vitanesti", "value": "C97993"},
                {"label": "Vitomiresti", "value": "C97994"},
                {"label": "Vladila", "value": "C97995"},
                {"label": "Voineasa", "value": "C97996"},
                {"label": "Vulpeni", "value": "C97997"},
                {"label": "Vulturesti", "value": "C97998"}],
 "Omaheke Region": [{"label": "Gobabis", "value": "C75996"}],
 "Ombella-MPoko Prefecture": [{"label": "Bimbo", "value": "C17834"},
                              {"label": "Boali", "value": "C17835"},
                              {"label": "Damara", "value": "C17836"}],
 "Omnogovi Province": [{"label": "Dalandzadgad", "value": "C75576"},
                       {"label": "Hanhongor", "value": "C75577"},
                       {"label": "Nomgon Sum", "value": "C75578"}],
 "Omsk Oblast": [{"label": "Azovo", "value": "C102905"},
                 {"label": "Beregovoy", "value": "C102906"},
                 {"label": "Bolsherechye", "value": "C102907"},
                 {"label": "Cherlak", "value": "C102908"},
                 {"label": "Irtyshskiy", "value": "C102909"},
                 {"label": "Kalachinsk", "value": "C102910"},
                 {"label": "Kolosovka", "value": "C102911"},
                 {"label": "Kormilovka", "value": "C102912"},
                 {"label": "Krasnoyarka", "value": "C102913"},
                 {"label": "Krasnyy Yar", "value": "C102914"},
                 {"label": "Krutaya Gorka", "value": "C102915"},
                 {"label": "Krutinka", "value": "C102916"},
                 {"label": "Luzino", "value": "C102917"},
                 {"label": "Lyubinskiy", "value": "C102918"},
                 {"label": "Muromtsevo", "value": "C102919"},
                 {"label": "Muromtsevskiy Rayon", "value": "C102920"},
                 {"label": "Nazyvayevsk", "value": "C102921"},
                 {"label": "Nizhnyaya Omka", "value": "C102922"},
                 {"label": "Novoomskiy", "value": "C102923"},
                 {"label": "Novovarshavka", "value": "C102924"},
                 {"label": "Odesskoye", "value": "C102925"},
                 {"label": "Okoneshnikovo", "value": "C102926"},
                 {"label": "Omsk", "value": "C102927"},
                 {"label": "Pavlogradka", "value": "C102928"},
                 {"label": "Poltavka", "value": "C102929"},
                 {"label": "Rostovka", "value": "C102930"},
                 {"label": "Russkaya Polyana", "value": "C102931"},
                 {"label": "Sargatskoye", "value": "C102932"},
                 {"label": "Sedelnikovskiy Rayon", "value": "C102933"},
                 {"label": "Sherbakul", "value": "C102934"},
                 {"label": "Tara", "value": "C102935"},
                 {"label": "Tavricheskoye", "value": "C102936"},
                 {"label": "Tevriz", "value": "C102937"},
                 {"label": "Troitsk", "value": "C102938"},
                 {"label": "Tyukalinsk", "value": "C102939"},
                 {"label": "Ust-Ishim", "value": "C102940"},
                 {"label": "Vkhodnoy", "value": "C102941"},
                 {"label": "Znamenskoye", "value": "C102942"}],
 "Omusati Region": [{"label": "Okahao", "value": "C75997"},
                    {"label": "Ongandjera", "value": "C75998"},
                    {"label": "Outapi", "value": "C75999"}],
 "Ondo": [{"label": "Agbabu", "value": "C78426"},
          {"label": "Akure", "value": "C78427"},
          {"label": "Idanre", "value": "C78428"},
          {"label": "Ifon", "value": "C78429"},
          {"label": "Ilare", "value": "C78430"},
          {"label": "Ode", "value": "C78431"},
          {"label": "Ondo", "value": "C78432"},
          {"label": "Ore", "value": "C78433"},
          {"label": "Owo", "value": "C78434"}],
 "Ontario": [{"label": "Ajax", "value": "C17126"},
             {"label": "Algoma", "value": "C17127"},
             {"label": "Alliston", "value": "C17128"},
             {"label": "Amherstburg", "value": "C17129"},
             {"label": "Amigo Beach", "value": "C17130"},
             {"label": "Ancaster", "value": "C17131"},
             {"label": "Angus", "value": "C17132"},
             {"label": "Arnprior", "value": "C17133"},
             {"label": "Atikokan", "value": "C17134"},
             {"label": "Attawapiskat", "value": "C17135"},
             {"label": "Aurora", "value": "C17136"},
             {"label": "Aylmer", "value": "C17137"},
             {"label": "Azilda", "value": "C17138"},
             {"label": "Ballantrae", "value": "C17139"},
             {"label": "Bancroft", "value": "C17140"},
             {"label": "Barrie", "value": "C17141"},
             {"label": "Bath", "value": "C17142"},
             {"label": "Belleville", "value": "C17143"},
             {"label": "Bells Corners", "value": "C17144"},
             {"label": "Belmont", "value": "C17145"},
             {"label": "Binbrook", "value": "C17146"},
             {"label": "Bluewater", "value": "C17147"},
             {"label": "Bourget", "value": "C17148"},
             {"label": "Bracebridge", "value": "C17149"},
             {"label": "Brampton", "value": "C17150"},
             {"label": "Brant", "value": "C17151"},
             {"label": "Brantford", "value": "C17152"},
             {"label": "Brockville", "value": "C17153"},
             {"label": "Brussels", "value": "C17154"},
             {"label": "Burford", "value": "C17155"},
             {"label": "Burlington", "value": "C17156"},
             {"label": "Cambridge", "value": "C17157"},
             {"label": "Camlachie", "value": "C17158"},
             {"label": "Capreol", "value": "C17159"},
             {"label": "Carleton Place", "value": "C17160"},
             {"label": "Casselman", "value": "C17161"},
             {"label": "Chatham", "value": "C17162"},
             {"label": "Chatham-Kent", "value": "C17163"},
             {"label": "Clarence-Rockland", "value": "C17164"},
             {"label": "Cobourg", "value": "C17165"},
             {"label": "Cochrane District", "value": "C17166"},
             {"label": "Collingwood", "value": "C17167"},
             {"label": "Concord", "value": "C17168"},
             {"label": "Constance Bay", "value": "C17169"},
             {"label": "Cookstown", "value": "C17170"},
             {"label": "Cornwall", "value": "C17171"},
             {"label": "Corunna", "value": "C17172"},
             {"label": "Deep River", "value": "C17173"},
             {"label": "Delaware", "value": "C17174"},
             {"label": "Deseronto", "value": "C17175"},
             {"label": "Dorchester", "value": "C17176"},
             {"label": "Dowling", "value": "C17177"},
             {"label": "Dryden", "value": "C17178"},
             {"label": "Durham", "value": "C17179"},
             {"label": "Ear Falls", "value": "C17180"},
             {"label": "East Gwillimbury", "value": "C17181"},
             {"label": "East York", "value": "C17182"},
             {"label": "Elliot Lake", "value": "C17183"},
             {"label": "Elmvale", "value": "C17184"},
             {"label": "Englehart", "value": "C17185"},
             {"label": "Espanola", "value": "C17186"},
             {"label": "Essex", "value": "C17187"},
             {"label": "Etobicoke", "value": "C17188"},
             {"label": "Fort Erie", "value": "C17189"},
             {"label": "Fort Frances", "value": "C17190"},
             {"label": "Gananoque", "value": "C17191"},
             {"label": "Glencoe", "value": "C17192"},
             {"label": "Goderich", "value": "C17193"},
             {"label": "Golden", "value": "C17194"},
             {"label": "Gravenhurst", "value": "C17195"},
             {"label": "Greater Napanee", "value": "C17196"},
             {"label": "Greater Sudbury", "value": "C17197"},
             {"label": "Greenstone", "value": "C17198"},
             {"label": "Guelph", "value": "C17199"},
             {"label": "Haldimand County", "value": "C17200"},
             {"label": "Haliburton Village", "value": "C17201"},
             {"label": "Halton", "value": "C17202"},
             {"label": "Hamilton", "value": "C17203"},
             {"label": "Hanover", "value": "C17204"},
             {"label": "Harriston", "value": "C17205"},
             {"label": "Hawkesbury", "value": "C17206"},
             {"label": "Hearst", "value": "C17207"},
             {"label": "Hornepayne", "value": "C17208"},
             {"label": "Huntsville", "value": "C17209"},
             {"label": "Huron East", "value": "C17210"},
             {"label": "Ingersoll", "value": "C17211"},
             {"label": "Innisfil", "value": "C17212"},
             {"label": "Iroquois Falls", "value": "C17213"},
             {"label": "Jarvis", "value": "C17214"},
             {"label": "Kanata", "value": "C17215"},
             {"label": "Kapuskasing", "value": "C17216"},
             {"label": "Kawartha Lakes", "value": "C17217"},
             {"label": "Kenora", "value": "C17218"},
             {"label": "Keswick", "value": "C17219"},
             {"label": "Kincardine", "value": "C17220"},
             {"label": "King", "value": "C17221"},
             {"label": "Kingston", "value": "C17222"},
             {"label": "Kirkland Lake", "value": "C17223"},
             {"label": "Kitchener", "value": "C17224"},
             {"label": "LOrignal", "value": "C17225"},
             {"label": "Lakefield", "value": "C17226"},
             {"label": "Lambton Shores", "value": "C17227"},
             {"label": "Lappe", "value": "C17228"},
             {"label": "Leamington", "value": "C17229"},
             {"label": "Limoges", "value": "C17230"},
             {"label": "Lindsay", "value": "C17231"},
             {"label": "Listowel", "value": "C17232"},
             {"label": "Little Current", "value": "C17233"},
             {"label": "Lively", "value": "C17234"},
             {"label": "London", "value": "C17235"},
             {"label": "Lucan", "value": "C17236"},
             {"label": "Madoc", "value": "C17237"},
             {"label": "Manitoulin District", "value": "C17238"},
             {"label": "Manitouwadge", "value": "C17239"},
             {"label": "Marathon", "value": "C17240"},
             {"label": "Markdale", "value": "C17241"},
             {"label": "Markham", "value": "C17242"},
             {"label": "Mattawa", "value": "C17243"},
             {"label": "Meaford", "value": "C17244"},
             {"label": "Metcalfe", "value": "C17245"},
             {"label": "Midland", "value": "C17246"},
             {"label": "Mildmay", "value": "C17247"},
             {"label": "Millbrook", "value": "C17248"},
             {"label": "Milton", "value": "C17249"},
             {"label": "Mississauga", "value": "C17250"},
             {"label": "Mississauga Beach", "value": "C17251"},
             {"label": "Moose Factory", "value": "C17252"},
             {"label": "Moosonee", "value": "C17253"},
             {"label": "Morrisburg", "value": "C17254"},
             {"label": "Mount Albert", "value": "C17255"},
             {"label": "Mount Brydges", "value": "C17256"},
             {"label": "Napanee", "value": "C17257"},
             {"label": "Napanee Downtown", "value": "C17258"},
             {"label": "Neebing", "value": "C17259"},
             {"label": "Nepean", "value": "C17260"},
             {"label": "New Hamburg", "value": "C17261"},
             {"label": "Newmarket", "value": "C17262"},
             {"label": "Niagara Falls", "value": "C17263"},
             {"label": "Nipissing District", "value": "C17264"},
             {"label": "Norfolk County", "value": "C17265"},
             {"label": "North Bay", "value": "C17266"},
             {"label": "North Perth", "value": "C17267"},
             {"label": "North York", "value": "C17268"},
             {"label": "Norwood", "value": "C17269"},
             {"label": "Oakville", "value": "C17270"},
             {"label": "Omemee", "value": "C17271"},
             {"label": "Orangeville", "value": "C17272"},
             {"label": "Orillia", "value": "C17273"},
             {"label": "Osgoode", "value": "C17274"},
             {"label": "Oshawa", "value": "C17275"},
             {"label": "Ottawa", "value": "C17276"},
             {"label": "Owen Sound", "value": "C17277"},
             {"label": "Paisley", "value": "C17278"},
             {"label": "Paris", "value": "C17279"},
             {"label": "Parkhill", "value": "C17280"},
             {"label": "Parry Sound", "value": "C17281"},
             {"label": "Parry Sound District", "value": "C17282"},
             {"label": "Peel", "value": "C17283"},
             {"label": "Pembroke", "value": "C17284"},
             {"label": "Perth", "value": "C17285"},
             {"label": "Petawawa", "value": "C17286"},
             {"label": "Peterborough", "value": "C17287"},
             {"label": "Petrolia", "value": "C17288"},
             {"label": "Pickering", "value": "C17289"},
             {"label": "Picton", "value": "C17290"},
             {"label": "Plantagenet", "value": "C17291"},
             {"label": "Plattsville", "value": "C17292"},
             {"label": "Port Colborne", "value": "C17293"},
             {"label": "Port Hope", "value": "C17294"},
             {"label": "Port Rowan", "value": "C17295"},
             {"label": "Port Stanley", "value": "C17296"},
             {"label": "Powassan", "value": "C17297"},
             {"label": "Prescott", "value": "C17298"},
             {"label": "Prince Edward", "value": "C17299"},
             {"label": "Queenswood Heights", "value": "C17300"},
             {"label": "Quinte West", "value": "C17301"},
             {"label": "Rainy River District", "value": "C17302"},
             {"label": "Rayside-Balfour", "value": "C17303"},
             {"label": "Red Lake", "value": "C17304"},
             {"label": "Regional Municipality of Waterloo", "value": "C17305"},
             {"label": "Renfrew", "value": "C17306"},
             {"label": "Richmond", "value": "C17307"},
             {"label": "Richmond Hill", "value": "C17308"},
             {"label": "Ridgetown", "value": "C17309"},
             {"label": "Rockwood", "value": "C17310"},
             {"label": "Russell", "value": "C17311"},
             {"label": "Sarnia", "value": "C17312"},
             {"label": "Sault Ste. Marie", "value": "C17313"},
             {"label": "Scarborough", "value": "C17314"},
             {"label": "Seaforth", "value": "C17315"},
             {"label": "Shelburne", "value": "C17316"},
             {"label": "Simcoe", "value": "C17317"},
             {"label": "Sioux Lookout", "value": "C17318"},
             {"label": "Skatepark", "value": "C17319"},
             {"label": "Smiths Falls", "value": "C17320"},
             {"label": "South Huron", "value": "C17321"},
             {"label": "South River", "value": "C17322"},
             {"label": "St. Catharines", "value": "C17323"},
             {"label": "St. George", "value": "C17324"},
             {"label": "St. Thomas", "value": "C17325"},
             {"label": "Stirling", "value": "C17326"},
             {"label": "Stoney Point", "value": "C17327"},
             {"label": "Stratford", "value": "C17328"},
             {"label": "Sudbury", "value": "C17329"},
             {"label": "Tavistock", "value": "C17330"},
             {"label": "Temiskaming Shores", "value": "C17331"},
             {"label": "Thessalon", "value": "C17332"},
             {"label": "Thornhill", "value": "C17333"},
             {"label": "Thorold", "value": "C17334"},
             {"label": "Thunder Bay", "value": "C17335"},
             {"label": "Thunder Bay District", "value": "C17336"},
             {"label": "Timiskaming District", "value": "C17337"},
             {"label": "Timmins", "value": "C17338"},
             {"label": "Tobermory", "value": "C17339"},
             {"label": "Toronto", "value": "C17340"},
             {"label": "Toronto county", "value": "C17341"},
             {"label": "Tottenham", "value": "C17342"},
             {"label": "Tweed", "value": "C17343"},
             {"label": "Uxbridge", "value": "C17344"},
             {"label": "Valley East", "value": "C17345"},
             {"label": "Vanier", "value": "C17346"},
             {"label": "Vaughan", "value": "C17347"},
             {"label": "Vineland", "value": "C17348"},
             {"label": "Virgil", "value": "C17349"},
             {"label": "Walpole Island", "value": "C17350"},
             {"label": "Wasaga Beach", "value": "C17351"},
             {"label": "Waterford", "value": "C17352"},
             {"label": "Waterloo", "value": "C17353"},
             {"label": "Watford", "value": "C17354"},
             {"label": "Wawa", "value": "C17355"},
             {"label": "Welland", "value": "C17356"},
             {"label": "Wellesley", "value": "C17357"},
             {"label": "Wendover", "value": "C17358"},
             {"label": "West Lorne", "value": "C17359"},
             {"label": "Willowdale", "value": "C17360"},
             {"label": "Winchester", "value": "C17361"},
             {"label": "Windsor", "value": "C17362"},
             {"label": "Wingham", "value": "C17363"},
             {"label": "Woodstock", "value": "C17364"},
             {"label": "York", "value": "C17365"}],
 "Oplotnica": [{"label": "Oplotnica", "value": "C107753"}],
 "Opole Voivodeship": [{"label": "Baborow", "value": "C89501"},
                       {"label": "Biala", "value": "C89502"},
                       {"label": "Bierawa", "value": "C89503"},
                       {"label": "Bierdzany", "value": "C89504"},
                       {"label": "Branice", "value": "C89505"},
                       {"label": "Brzeg", "value": "C89506"},
                       {"label": "Byczyna", "value": "C89507"},
                       {"label": "Chmielowice", "value": "C89508"},
                       {"label": "Chroscice", "value": "C89509"},
                       {"label": "Chroscina", "value": "C89510"},
                       {"label": "Chrzastowice", "value": "C89511"},
                       {"label": "Cisek", "value": "C89512"},
                       {"label": "Czarnowasy", "value": "C89513"},
                       {"label": "Dabrowa", "value": "C89514"},
                       {"label": "Dalachow", "value": "C89515"},
                       {"label": "Dlugomilowice", "value": "C89516"},
                       {"label": "Dobrodzien", "value": "C89517"},
                       {"label": "Dobrzen Wielki", "value": "C89518"},
                       {"label": "Domaszowice", "value": "C89519"},
                       {"label": "Dziergowice", "value": "C89520"},
                       {"label": "Glogowek", "value": "C89521"},
                       {"label": "Glubczyce", "value": "C89522"},
                       {"label": "Glucholazy", "value": "C89523"},
                       {"label": "Gogolin", "value": "C89524"},
                       {"label": "Gorazdze", "value": "C89525"},
                       {"label": "Gorzow Slaski", "value": "C89526"},
                       {"label": "Grodkow", "value": "C89527"},
                       {"label": "Izbicko", "value": "C89528"},
                       {"label": "Januszkowice", "value": "C89529"},
                       {"label": "Jemielnica", "value": "C89530"},
                       {"label": "Kamienica", "value": "C89531"},
                       {"label": "Kamiennik", "value": "C89532"},
                       {"label": "Kedzierzyn-Kozle", "value": "C89533"},
                       {"label": "Kietrz", "value": "C89534"},
                       {"label": "Kluczbork", "value": "C89535"},
                       {"label": "Kolonowskie", "value": "C89536"},
                       {"label": "Komprachcice", "value": "C89537"},
                       {"label": "Korfantow", "value": "C89538"},
                       {"label": "Kozle", "value": "C89539"},
                       {"label": "Krapkowice", "value": "C89540"},
                       {"label": "Kup", "value": "C89541"},
                       {"label": "Lesnica", "value": "C89542"},
                       {"label": "Lewin Brzeski", "value": "C89543"},
                       {"label": "Lubrza", "value": "C89544"},
                       {"label": "Lubsza", "value": "C89545"},
                       {"label": "Laka Prudnicka", "value": "C89546"},
                       {"label": "Lambinowice", "value": "C89547"},
                       {"label": "Losiow", "value": "C89548"},
                       {"label": "Lubniany", "value": "C89549"},
                       {"label": "Moszczanka", "value": "C89550"},
                       {"label": "Murow", "value": "C89551"},
                       {"label": "Naklo", "value": "C89552"},
                       {"label": "Namyslow", "value": "C89553"},
                       {"label": "Niemodlin", "value": "C89554"},
                       {"label": "Nysa", "value": "C89555"},
                       {"label": "Olesno", "value": "C89556"},
                       {"label": "Olszanka", "value": "C89557"},
                       {"label": "Opole", "value": "C89558"},
                       {"label": "Otmuchow", "value": "C89559"},
                       {"label": "Ozimek", "value": "C89560"},
                       {"label": "Paczkow", "value": "C89561"},
                       {"label": "Pakoslawice", "value": "C89562"},
                       {"label": "Pawlowiczki", "value": "C89563"},
                       {"label": "Pokoj", "value": "C89564"},
                       {"label": "Polska Cerekiew", "value": "C89565"},
                       {"label": "Popielow", "value": "C89566"},
                       {"label": "Powiat brzeski", "value": "C89567"},
                       {"label": "Powiat glubczycki", "value": "C89568"},
                       {"label": "Powiat kedzierzynsko-kozielski",
                        "value": "C89569"},
                       {"label": "Powiat kluczborski", "value": "C89570"},
                       {"label": "Powiat krapkowicki", "value": "C89571"},
                       {"label": "Powiat namyslowski", "value": "C89572"},
                       {"label": "Powiat nyski", "value": "C89573"},
                       {"label": "Powiat oleski", "value": "C89574"},
                       {"label": "Powiat opolski", "value": "C89575"},
                       {"label": "Powiat prudnicki", "value": "C89576"},
                       {"label": "Powiat strzelecki", "value": "C89577"},
                       {"label": "Praszka", "value": "C89578"},
                       {"label": "Proszkow", "value": "C89579"},
                       {"label": "Prudnik", "value": "C89580"},
                       {"label": "Raclawice Slaskie", "value": "C89581"},
                       {"label": "Raszowa", "value": "C89582"},
                       {"label": "Renska Wies", "value": "C89583"},
                       {"label": "Rozwadza", "value": "C89584"},
                       {"label": "Sidzina", "value": "C89585"},
                       {"label": "Skarbimierz Osiedle", "value": "C89586"},
                       {"label": "Skorogoszcz", "value": "C89587"},
                       {"label": "Skoroszyce", "value": "C89588"},
                       {"label": "Slawiecice", "value": "C89589"},
                       {"label": "Strzelce Opolskie", "value": "C89590"},
                       {"label": "Strzeleczki", "value": "C89591"},
                       {"label": "Swierczow", "value": "C89592"},
                       {"label": "Tarnow Opolski", "value": "C89593"},
                       {"label": "Tulowice", "value": "C89594"},
                       {"label": "Twardawa", "value": "C89595"},
                       {"label": "Ujazd", "value": "C89596"},
                       {"label": "Walce", "value": "C89597"},
                       {"label": "Wegry", "value": "C89598"},
                       {"label": "Wiekszyce", "value": "C89599"},
                       {"label": "Wilkow", "value": "C89600"},
                       {"label": "Wolczyn", "value": "C89601"},
                       {"label": "Zawadzkie", "value": "C89602"},
                       {"label": "Zdzieszowice", "value": "C89603"},
                       {"label": "Zebowice", "value": "C89604"}],
 "Oppland": [{"label": "Bagn", "value": "C78948"},
             {"label": "Dokka", "value": "C78949"},
             {"label": "Dombas", "value": "C78950"},
             {"label": "Dovre", "value": "C78951"},
             {"label": "Etnedal", "value": "C78952"},
             {"label": "Fagernes", "value": "C78953"},
             {"label": "Fossbergom", "value": "C78954"},
             {"label": "Gausdal", "value": "C78955"},
             {"label": "Gjovik", "value": "C78956"},
             {"label": "Gran", "value": "C78957"},
             {"label": "Grua", "value": "C78958"},
             {"label": "Hov", "value": "C78959"},
             {"label": "Hundorp", "value": "C78960"},
             {"label": "Jevnaker", "value": "C78961"},
             {"label": "Lena", "value": "C78962"},
             {"label": "Lesja", "value": "C78963"},
             {"label": "Lillehammer", "value": "C78964"},
             {"label": "Lom", "value": "C78965"},
             {"label": "Lunner", "value": "C78966"},
             {"label": "Nord-Aurdal", "value": "C78967"},
             {"label": "Nord-Fron", "value": "C78968"},
             {"label": "Nordre Land", "value": "C78969"},
             {"label": "Otta", "value": "C78970"},
             {"label": "Ostre Toten", "value": "C78971"},
             {"label": "Oyer", "value": "C78972"},
             {"label": "Oystre Slidre", "value": "C78973"},
             {"label": "Raufoss", "value": "C78974"},
             {"label": "Reinsvoll", "value": "C78975"},
             {"label": "Ringebu", "value": "C78976"},
             {"label": "Sel", "value": "C78977"},
             {"label": "Skjak", "value": "C78978"},
             {"label": "Skreia", "value": "C78979"},
             {"label": "Sondre Land", "value": "C78980"},
             {"label": "Sor-Aurdal", "value": "C78981"},
             {"label": "Sor-Fron", "value": "C78982"},
             {"label": "Tretten", "value": "C78983"},
             {"label": "Vaga", "value": "C78984"},
             {"label": "Vagamo", "value": "C78985"},
             {"label": "Vang", "value": "C78986"},
             {"label": "Vestre Slidre", "value": "C78987"},
             {"label": "Vestre Toten", "value": "C78988"},
             {"label": "Vinstra", "value": "C78989"}],
 "Oran": [{"label": "Ain el Turk", "value": "C373"},
          {"label": "Ain el Bya", "value": "C374"},
          {"label": "Bir el Djir", "value": "C375"},
          {"label": "Bou Tlelis", "value": "C376"},
          {"label": "Es Senia", "value": "C377"},
          {"label": "Mers el Kebir", "value": "C378"},
          {"label": "Oran", "value": "C379"},
          {"label": "Sidi ech Chahmi", "value": "C380"}],
 "Orange Walk District": [{"label": "Hopelchen", "value": "C9588"},
                          {"label": "Orange Walk", "value": "C9589"},
                          {"label": "Shipyard", "value": "C9590"}],
 "Ordino": [{"label": "Ordino", "value": "C524"}],
 "Ordu": [{"label": "Akkus", "value": "C120848"},
          {"label": "Altinordu", "value": "C120849"},
          {"label": "Aybasti Ilcesi", "value": "C120850"},
          {"label": "Camas", "value": "C120851"},
          {"label": "Catalpinar Ilcesi", "value": "C120852"},
          {"label": "Caybasi Ilcesi", "value": "C120853"},
          {"label": "Fatsa", "value": "C120854"},
          {"label": "Golkoy Ilcesi", "value": "C120855"},
          {"label": "Gulyali Ilcesi", "value": "C120856"},
          {"label": "Gurgentepe", "value": "C120857"},
          {"label": "Ikizce", "value": "C120858"},
          {"label": "Kabaduz", "value": "C120859"},
          {"label": "Kabatas", "value": "C120860"},
          {"label": "Korgan", "value": "C120861"},
          {"label": "Kumru", "value": "C120862"},
          {"label": "Mesudiye", "value": "C120863"},
          {"label": "Ordu", "value": "C120864"},
          {"label": "Persembe", "value": "C120865"},
          {"label": "Piraziz Ilcesi", "value": "C120866"},
          {"label": "Ulubey", "value": "C120867"},
          {"label": "unye Ilcesi", "value": "C120868"}],
 "Orebro County": [{"label": "Asbro", "value": "C115746"},
                   {"label": "Askersund", "value": "C115747"},
                   {"label": "Degerfors", "value": "C115748"},
                   {"label": "Ekeby-Almby", "value": "C115749"},
                   {"label": "Fellingsbro", "value": "C115750"},
                   {"label": "Fjugesta", "value": "C115751"},
                   {"label": "Frovi", "value": "C115752"},
                   {"label": "Garphyttan", "value": "C115753"},
                   {"label": "Hallabrottet", "value": "C115754"},
                   {"label": "Hallefors", "value": "C115755"},
                   {"label": "Hallsberg", "value": "C115756"},
                   {"label": "Hovsta", "value": "C115757"},
                   {"label": "Karlskoga", "value": "C115758"},
                   {"label": "Kopparberg", "value": "C115759"},
                   {"label": "Kumla", "value": "C115760"},
                   {"label": "Laxa", "value": "C115761"},
                   {"label": "Lindesberg", "value": "C115762"},
                   {"label": "Nora", "value": "C115763"},
                   {"label": "Odensbacken", "value": "C115764"},
                   {"label": "Orebro", "value": "C115765"},
                   {"label": "Palsboda", "value": "C115766"},
                   {"label": "Skollersta", "value": "C115767"},
                   {"label": "Stora", "value": "C115768"},
                   {"label": "Vintrosa", "value": "C115769"}],
 "Oregon": [{"label": "Albany", "value": "C141067"},
            {"label": "Aloha", "value": "C141068"},
            {"label": "Altamont", "value": "C141069"},
            {"label": "Amity", "value": "C141070"},
            {"label": "Ashland", "value": "C141071"},
            {"label": "Astoria", "value": "C141072"},
            {"label": "Athena", "value": "C141073"},
            {"label": "Aumsville", "value": "C141074"},
            {"label": "Baker City", "value": "C141075"},
            {"label": "Baker County", "value": "C141076"},
            {"label": "Bandon", "value": "C141077"},
            {"label": "Banks", "value": "C141078"},
            {"label": "Barview", "value": "C141079"},
            {"label": "Bay City", "value": "C141080"},
            {"label": "Beavercreek", "value": "C141081"},
            {"label": "Beaverton", "value": "C141082"},
            {"label": "Bend", "value": "C141083"},
            {"label": "Benton County", "value": "C141084"},
            {"label": "Bethany", "value": "C141085"},
            {"label": "Boardman", "value": "C141086"},
            {"label": "Brookings", "value": "C141087"},
            {"label": "Brownsville", "value": "C141088"},
            {"label": "Bunker Hill", "value": "C141089"},
            {"label": "Burns", "value": "C141090"},
            {"label": "Canby", "value": "C141091"},
            {"label": "Cannon Beach", "value": "C141092"},
            {"label": "Canyon City", "value": "C141093"},
            {"label": "Canyonville", "value": "C141094"},
            {"label": "Carlton", "value": "C141095"},
            {"label": "Cascade Locks", "value": "C141096"},
            {"label": "Cave Junction", "value": "C141097"},
            {"label": "Cedar Hills", "value": "C141098"},
            {"label": "Cedar Mill", "value": "C141099"},
            {"label": "Central Point", "value": "C141100"},
            {"label": "Chenoweth", "value": "C141101"},
            {"label": "Clackamas", "value": "C141102"},
            {"label": "Clackamas County", "value": "C141103"},
            {"label": "Clatskanie", "value": "C141104"},
            {"label": "Clatsop County", "value": "C141105"},
            {"label": "Coburg", "value": "C141106"},
            {"label": "Columbia City", "value": "C141107"},
            {"label": "Columbia County", "value": "C141108"},
            {"label": "Condon", "value": "C141109"},
            {"label": "Coos Bay", "value": "C141110"},
            {"label": "Coos County", "value": "C141111"},
            {"label": "Coquille", "value": "C141112"},
            {"label": "Cornelius", "value": "C141113"},
            {"label": "Corvallis", "value": "C141114"},
            {"label": "Cottage Grove", "value": "C141115"},
            {"label": "Creswell", "value": "C141116"},
            {"label": "Crook County", "value": "C141117"},
            {"label": "Culp Creek", "value": "C141118"},
            {"label": "Culver", "value": "C141119"},
            {"label": "Curry County", "value": "C141120"},
            {"label": "Dallas", "value": "C141121"},
            {"label": "Damascus", "value": "C141122"},
            {"label": "Dayton", "value": "C141123"},
            {"label": "Depoe Bay", "value": "C141124"},
            {"label": "Deschutes County", "value": "C141125"},
            {"label": "Deschutes River Woods", "value": "C141126"},
            {"label": "Donald", "value": "C141127"},
            {"label": "Douglas County", "value": "C141128"},
            {"label": "Drain", "value": "C141129"},
            {"label": "Dundee", "value": "C141130"},
            {"label": "Dunes City", "value": "C141131"},
            {"label": "Durham", "value": "C141132"},
            {"label": "Eagle Point", "value": "C141133"},
            {"label": "Elgin", "value": "C141134"},
            {"label": "Enterprise", "value": "C141135"},
            {"label": "Estacada", "value": "C141136"},
            {"label": "Eugene", "value": "C141137"},
            {"label": "Fairview", "value": "C141138"},
            {"label": "Florence", "value": "C141139"},
            {"label": "Forest Grove", "value": "C141140"},
            {"label": "Fossil", "value": "C141141"},
            {"label": "Four Corners", "value": "C141142"},
            {"label": "Fruitdale", "value": "C141143"},
            {"label": "Garden Home-Whitford", "value": "C141144"},
            {"label": "Gearhart", "value": "C141145"},
            {"label": "Gervais", "value": "C141146"},
            {"label": "Gilliam County", "value": "C141147"},
            {"label": "Gladstone", "value": "C141148"},
            {"label": "Glide", "value": "C141149"},
            {"label": "Gold Beach", "value": "C141150"},
            {"label": "Gold Hill", "value": "C141151"},
            {"label": "Grand Ronde", "value": "C141152"},
            {"label": "Grant County", "value": "C141153"},
            {"label": "Grants Pass", "value": "C141154"},
            {"label": "Green", "value": "C141155"},
            {"label": "Gresham", "value": "C141156"},
            {"label": "Happy Valley", "value": "C141157"},
            {"label": "Harbor", "value": "C141158"},
            {"label": "Harney County", "value": "C141159"},
            {"label": "Harrisburg", "value": "C141160"},
            {"label": "Hayesville", "value": "C141161"},
            {"label": "Heppner", "value": "C141162"},
            {"label": "Hermiston", "value": "C141163"},
            {"label": "Hillsboro", "value": "C141164"},
            {"label": "Hines", "value": "C141165"},
            {"label": "Hood River", "value": "C141166"},
            {"label": "Hood River County", "value": "C141167"},
            {"label": "Hubbard", "value": "C141168"},
            {"label": "Independence", "value": "C141169"},
            {"label": "Irrigon", "value": "C141170"},
            {"label": "Island City", "value": "C141171"},
            {"label": "Jackson County", "value": "C141172"},
            {"label": "Jacksonville", "value": "C141173"},
            {"label": "Jefferson", "value": "C141174"},
            {"label": "Jefferson County", "value": "C141175"},
            {"label": "Jennings Lodge", "value": "C141176"},
            {"label": "John Day", "value": "C141177"},
            {"label": "Joseph", "value": "C141178"},
            {"label": "Josephine County", "value": "C141179"},
            {"label": "Junction City", "value": "C141180"},
            {"label": "Keizer", "value": "C141181"},
            {"label": "Kenton", "value": "C141182"},
            {"label": "King City", "value": "C141183"},
            {"label": "Klamath County", "value": "C141184"},
            {"label": "Klamath Falls", "value": "C141185"},
            {"label": "La Grande", "value": "C141186"},
            {"label": "La Pine", "value": "C141187"},
            {"label": "Lafayette", "value": "C141188"},
            {"label": "Lake County", "value": "C141189"},
            {"label": "Lake Oswego", "value": "C141190"},
            {"label": "Lakeside", "value": "C141191"},
            {"label": "Lakeview", "value": "C141192"},
            {"label": "Lane County", "value": "C141193"},
            {"label": "Lebanon", "value": "C141194"},
            {"label": "Lents", "value": "C141195"},
            {"label": "Lincoln Beach", "value": "C141196"},
            {"label": "Lincoln City", "value": "C141197"},
            {"label": "Lincoln County", "value": "C141198"},
            {"label": "Linn County", "value": "C141199"},
            {"label": "Lowell", "value": "C141200"},
            {"label": "Lyons", "value": "C141201"},
            {"label": "Madras", "value": "C141202"},
            {"label": "Malheur County", "value": "C141203"},
            {"label": "Marion County", "value": "C141204"},
            {"label": "McMinnville", "value": "C141205"},
            {"label": "Medford", "value": "C141206"},
            {"label": "Merlin", "value": "C141207"},
            {"label": "Metzger", "value": "C141208"},
            {"label": "Mill City", "value": "C141209"},
            {"label": "Millersburg", "value": "C141210"},
            {"label": "Milton-Freewater", "value": "C141211"},
            {"label": "Milwaukie", "value": "C141212"},
            {"label": "Mission", "value": "C141213"},
            {"label": "Molalla", "value": "C141214"},
            {"label": "Monmouth", "value": "C141215"},
            {"label": "Moro", "value": "C141216"},
            {"label": "Morrow County", "value": "C141217"},
            {"label": "Mount Angel", "value": "C141218"},
            {"label": "Mount Hood Village", "value": "C141219"},
            {"label": "Mulino", "value": "C141220"},
            {"label": "Multnomah County", "value": "C141221"},
            {"label": "Myrtle Creek", "value": "C141222"},
            {"label": "Myrtle Point", "value": "C141223"},
            {"label": "New Hope", "value": "C141224"},
            {"label": "Newberg", "value": "C141225"},
            {"label": "Newport", "value": "C141226"},
            {"label": "North Bend", "value": "C141227"},
            {"label": "North Plains", "value": "C141228"},
            {"label": "North Portland", "value": "C141229"},
            {"label": "Nyssa", "value": "C141230"},
            {"label": "Oak Grove", "value": "C141231"},
            {"label": "Oak Hills", "value": "C141232"},
            {"label": "Oakridge", "value": "C141233"},
            {"label": "Oatfield", "value": "C141234"},
            {"label": "Odell", "value": "C141235"},
            {"label": "Ontario", "value": "C141236"},
            {"label": "Oregon City", "value": "C141237"},
            {"label": "Pacific City", "value": "C141238"},
            {"label": "Pendleton", "value": "C141239"},
            {"label": "Philomath", "value": "C141240"},
            {"label": "Phoenix", "value": "C141241"},
            {"label": "Pilot Rock", "value": "C141242"},
            {"label": "Polk County", "value": "C141243"},
            {"label": "Port Orford", "value": "C141244"},
            {"label": "Portland", "value": "C141245"},
            {"label": "Prineville", "value": "C141246"},
            {"label": "Rainier", "value": "C141247"},
            {"label": "Raleigh Hills", "value": "C141248"},
            {"label": "Redmond", "value": "C141249"},
            {"label": "Redwood", "value": "C141250"},
            {"label": "Reedsport", "value": "C141251"},
            {"label": "Riddle", "value": "C141252"},
            {"label": "Rockaway Beach", "value": "C141253"},
            {"label": "Rockcreek", "value": "C141254"},
            {"label": "Rogue River", "value": "C141255"},
            {"label": "Rose Lodge", "value": "C141256"},
            {"label": "Roseburg", "value": "C141257"},
            {"label": "Roseburg North", "value": "C141258"},
            {"label": "Saint Helens", "value": "C141259"},
            {"label": "Salem", "value": "C141260"},
            {"label": "Sandy", "value": "C141261"},
            {"label": "Scappoose", "value": "C141262"},
            {"label": "Seaside", "value": "C141263"},
            {"label": "Shady Cove", "value": "C141264"},
            {"label": "Sheridan", "value": "C141265"},
            {"label": "Sherman County", "value": "C141266"},
            {"label": "Sherwood", "value": "C141267"},
            {"label": "Siletz", "value": "C141268"},
            {"label": "Silverton", "value": "C141269"},
            {"label": "Sisters", "value": "C141270"},
            {"label": "South Lebanon", "value": "C141271"},
            {"label": "Springfield", "value": "C141272"},
            {"label": "Stafford", "value": "C141273"},
            {"label": "Stanfield", "value": "C141274"},
            {"label": "Stayton", "value": "C141275"},
            {"label": "Sublimity", "value": "C141276"},
            {"label": "Sunriver", "value": "C141277"},
            {"label": "Sutherlin", "value": "C141278"},
            {"label": "Sweet Home", "value": "C141279"},
            {"label": "Talent", "value": "C141280"},
            {"label": "Tangent", "value": "C141281"},
            {"label": "Terrebonne", "value": "C141282"},
            {"label": "The Dalles", "value": "C141283"},
            {"label": "Three Rivers", "value": "C141284"},
            {"label": "Tigard", "value": "C141285"},
            {"label": "Tillamook", "value": "C141286"},
            {"label": "Tillamook County", "value": "C141287"},
            {"label": "Toledo", "value": "C141288"},
            {"label": "Tri-City", "value": "C141289"},
            {"label": "Troutdale", "value": "C141290"},
            {"label": "Tualatin", "value": "C141291"},
            {"label": "Turner", "value": "C141292"},
            {"label": "Umatilla", "value": "C141293"},
            {"label": "Umatilla County", "value": "C141294"},
            {"label": "Union", "value": "C141295"},
            {"label": "Union County", "value": "C141296"},
            {"label": "Vale", "value": "C141297"},
            {"label": "Veneta", "value": "C141298"},
            {"label": "Vernonia", "value": "C141299"},
            {"label": "Waldport", "value": "C141300"},
            {"label": "Wallowa County", "value": "C141301"},
            {"label": "Warm Springs", "value": "C141302"},
            {"label": "Warren", "value": "C141303"},
            {"label": "Warrenton", "value": "C141304"},
            {"label": "Wasco County", "value": "C141305"},
            {"label": "Washington County", "value": "C141306"},
            {"label": "West Haven", "value": "C141307"},
            {"label": "West Haven-Sylvan", "value": "C141308"},
            {"label": "West Linn", "value": "C141309"},
            {"label": "West Slope", "value": "C141310"},
            {"label": "Wheeler County", "value": "C141311"},
            {"label": "White City", "value": "C141312"},
            {"label": "Willamina", "value": "C141313"},
            {"label": "Williams", "value": "C141314"},
            {"label": "Wilsonville", "value": "C141315"},
            {"label": "Winston", "value": "C141316"},
            {"label": "Wood Village", "value": "C141317"},
            {"label": "Woodburn", "value": "C141318"},
            {"label": "Yamhill", "value": "C141319"},
            {"label": "Yamhill County", "value": "C141320"},
            {"label": "Yoncalla", "value": "C141321"}],
 "Orellana": [{"label": "Boca Suno", "value": "C24186"},
              {"label": "Francisco de Orellana Canton", "value": "C24187"},
              {"label": "Loreto Canton", "value": "C24188"},
              {"label": "Puerto Francisco de Orellana", "value": "C24189"}],
 "Orenburg Oblast": [{"label": "Abdulino", "value": "C102943"},
                     {"label": "Adamovka", "value": "C102944"},
                     {"label": "Alandskoye", "value": "C102945"},
                     {"label": "Asekeyevo", "value": "C102946"},
                     {"label": "Belyayevka", "value": "C102947"},
                     {"label": "Buguruslan", "value": "C102948"},
                     {"label": "Buzuluk", "value": "C102949"},
                     {"label": "Dombarovskiy", "value": "C102950"},
                     {"label": "Energetik", "value": "C102951"},
                     {"label": "Gay", "value": "C102952"},
                     {"label": "Ilek", "value": "C102953"},
                     {"label": "Kardailovo", "value": "C102954"},
                     {"label": "Koltubanovskiy", "value": "C102955"},
                     {"label": "Krasnogvardeyets", "value": "C102956"},
                     {"label": "Krasnokholm", "value": "C102957"},
                     {"label": "Krasnoyarskiy", "value": "C102958"},
                     {"label": "Krasnyy Kommunar", "value": "C102959"},
                     {"label": "Kurmanayevka", "value": "C102960"},
                     {"label": "Kuvandyk", "value": "C102961"},
                     {"label": "Kvarkeno", "value": "C102962"},
                     {"label": "Kvarkenskiy Rayon", "value": "C102963"},
                     {"label": "Matveyevka", "value": "C102964"},
                     {"label": "Mednogorsk", "value": "C102965"},
                     {"label": "Nezhinka", "value": "C102966"},
                     {"label": "Nizhnepavlovka", "value": "C102967"},
                     {"label": "Novoorsk", "value": "C102968"},
                     {"label": "Novoorskiy Rayon", "value": "C102969"},
                     {"label": "Novorudnyy", "value": "C102970"},
                     {"label": "Novosergiyevka", "value": "C102971"},
                     {"label": "Novotroitsk", "value": "C102972"},
                     {"label": "Orenburg", "value": "C102973"},
                     {"label": "Orsk", "value": "C102974"},
                     {"label": "Perevolotskiy", "value": "C102975"},
                     {"label": "Pleshanovo", "value": "C102976"},
                     {"label": "Podgorodnyaya Pokrovka", "value": "C102977"},
                     {"label": "Prigorodnyy", "value": "C102978"},
                     {"label": "Sakmara", "value": "C102979"},
                     {"label": "Saraktash", "value": "C102980"},
                     {"label": "Saraktashskiy Rayon", "value": "C102981"},
                     {"label": "Severnoye", "value": "C102982"},
                     {"label": "Sharlyk", "value": "C102983"},
                     {"label": "Shilda", "value": "C102984"},
                     {"label": "Sol-Iletsk", "value": "C102985"},
                     {"label": "Sorochinsk", "value": "C102986"},
                     {"label": "Staraya Akkermanovka", "value": "C102987"},
                     {"label": "Svetlyy", "value": "C102988"},
                     {"label": "Tashla", "value": "C102989"},
                     {"label": "Tatarskaya Kargala", "value": "C102990"},
                     {"label": "Totskoye", "value": "C102991"},
                     {"label": "Tyulgan", "value": "C102992"},
                     {"label": "Yasnyy", "value": "C102993"},
                     {"label": "Yelizavetinka", "value": "C102994"}],
 "Orhei District": [{"label": "Orhei", "value": "C75528"}],
 "Oriental Mindoro": [{"label": "Aborlan", "value": "C87052"},
                      {"label": "Abra de Ilog", "value": "C87053"},
                      {"label": "Adela", "value": "C87054"},
                      {"label": "Agcogon", "value": "C87055"},
                      {"label": "Agutaya", "value": "C87056"},
                      {"label": "Alad", "value": "C87057"},
                      {"label": "Alcantara", "value": "C87058"},
                      {"label": "Alemanguan", "value": "C87059"},
                      {"label": "Algeciras", "value": "C87060"},
                      {"label": "Alibug", "value": "C87061"},
                      {"label": "Anilao", "value": "C87062"},
                      {"label": "Apitong", "value": "C87063"},
                      {"label": "Apurawan", "value": "C87064"},
                      {"label": "Araceli", "value": "C87065"},
                      {"label": "Aramawayan", "value": "C87066"},
                      {"label": "Aramayuan", "value": "C87067"},
                      {"label": "Aurora", "value": "C87068"},
                      {"label": "Babug", "value": "C87069"},
                      {"label": "Baco", "value": "C87070"},
                      {"label": "Bacungan", "value": "C87071"},
                      {"label": "Bagong Sikat", "value": "C87072"},
                      {"label": "Baheli", "value": "C87073"},
                      {"label": "Balabac", "value": "C87074"},
                      {"label": "Balanacan", "value": "C87075"},
                      {"label": "Balatero", "value": "C87076"},
                      {"label": "Balugo", "value": "C87077"},
                      {"label": "Banos", "value": "C87078"},
                      {"label": "Bansud", "value": "C87079"},
                      {"label": "Barahan", "value": "C87080"},
                      {"label": "Barong Barong", "value": "C87081"},
                      {"label": "Batarasa", "value": "C87082"},
                      {"label": "Batasan", "value": "C87083"},
                      {"label": "Bato", "value": "C87084"},
                      {"label": "Bayuin", "value": "C87085"},
                      {"label": "Bintacay", "value": "C87086"},
                      {"label": "Boac", "value": "C87087"},
                      {"label": "Buenavista", "value": "C87088"},
                      {"label": "Bulalacao", "value": "C87089"},
                      {"label": "Buluang", "value": "C87090"},
                      {"label": "Bunog", "value": "C87091"},
                      {"label": "Burirao", "value": "C87092"},
                      {"label": "Buyabod", "value": "C87093"},
                      {"label": "Cabacao", "value": "C87094"},
                      {"label": "Cabra", "value": "C87095"},
                      {"label": "Cagayan", "value": "C87096"},
                      {"label": "Cagayancillo", "value": "C87097"},
                      {"label": "Caigangan", "value": "C87098"},
                      {"label": "Cajidiocan", "value": "C87099"},
                      {"label": "Cajimos", "value": "C87100"},
                      {"label": "Calamundingan", "value": "C87101"},
                      {"label": "Calapan", "value": "C87102"},
                      {"label": "Calatrava", "value": "C87103"},
                      {"label": "Calatugas", "value": "C87104"},
                      {"label": "Calauag", "value": "C87105"},
                      {"label": "Calintaan", "value": "C87106"},
                      {"label": "Caminauit", "value": "C87107"},
                      {"label": "Cantel", "value": "C87108"},
                      {"label": "Canubing No 2", "value": "C87109"},
                      {"label": "Caramay", "value": "C87110"},
                      {"label": "Carmen", "value": "C87111"},
                      {"label": "Caruray", "value": "C87112"},
                      {"label": "Casian", "value": "C87113"},
                      {"label": "Cawayan", "value": "C87114"},
                      {"label": "Concepcion", "value": "C87115"},
                      {"label": "Conduaga", "value": "C87116"},
                      {"label": "Corcuera", "value": "C87117"},
                      {"label": "Coron", "value": "C87118"},
                      {"label": "Cuyo", "value": "C87119"},
                      {"label": "Dapawan", "value": "C87120"},
                      {"label": "Daykitin", "value": "C87121"},
                      {"label": "Dobdoban", "value": "C87122"},
                      {"label": "Dulangan", "value": "C87123"},
                      {"label": "Dumaran", "value": "C87124"},
                      {"label": "El Nido", "value": "C87125"},
                      {"label": "Eraan", "value": "C87126"},
                      {"label": "Espana", "value": "C87127"},
                      {"label": "Estrella", "value": "C87128"},
                      {"label": "Evangelista", "value": "C87129"},
                      {"label": "Ferrol", "value": "C87130"},
                      {"label": "Gabawan", "value": "C87131"},
                      {"label": "Gasan", "value": "C87132"},
                      {"label": "Gloria", "value": "C87133"},
                      {"label": "Guinlo", "value": "C87134"},
                      {"label": "Harrison", "value": "C87135"},
                      {"label": "Ipil", "value": "C87136"},
                      {"label": "Ipilan", "value": "C87137"},
                      {"label": "Irahuan", "value": "C87138"},
                      {"label": "Iraray", "value": "C87139"},
                      {"label": "Irirum", "value": "C87140"},
                      {"label": "Isugod", "value": "C87141"},
                      {"label": "La Curva", "value": "C87142"},
                      {"label": "Labasan", "value": "C87143"},
                      {"label": "Labog", "value": "C87144"},
                      {"label": "Lanas", "value": "C87145"},
                      {"label": "Laylay", "value": "C87146"},
                      {"label": "Leuteboro", "value": "C87147"},
                      {"label": "Libas", "value": "C87148"},
                      {"label": "Libertad", "value": "C87149"},
                      {"label": "Ligaya", "value": "C87150"},
                      {"label": "Limanancong", "value": "C87151"},
                      {"label": "Limon", "value": "C87152"},
                      {"label": "Looc", "value": "C87153"},
                      {"label": "Lubang", "value": "C87154"},
                      {"label": "Lumangbayan", "value": "C87155"},
                      {"label": "Magbay", "value": "C87156"},
                      {"label": "Magdiwang", "value": "C87157"},
                      {"label": "Magsaysay", "value": "C87158"},
                      {"label": "Mainit", "value": "C87159"},
                      {"label": "Malamig", "value": "C87160"},
                      {"label": "Malibago", "value": "C87161"},
                      {"label": "Maliig", "value": "C87162"},
                      {"label": "Malitbog", "value": "C87163"},
                      {"label": "Maluanluan", "value": "C87164"},
                      {"label": "Mamburao", "value": "C87165"},
                      {"label": "Manaul", "value": "C87166"},
                      {"label": "Mangarine", "value": "C87167"},
                      {"label": "Mansalay", "value": "C87168"},
                      {"label": "Masaguisi", "value": "C87169"},
                      {"label": "Masiga", "value": "C87170"},
                      {"label": "Mauhao", "value": "C87171"},
                      {"label": "Mogpog", "value": "C87172"},
                      {"label": "Nagiba", "value": "C87173"},
                      {"label": "Narra", "value": "C87174"},
                      {"label": "Naujan", "value": "C87175"},
                      {"label": "New Agutaya", "value": "C87176"},
                      {"label": "Odala", "value": "C87177"},
                      {"label": "Odiong", "value": "C87178"},
                      {"label": "Odiongan", "value": "C87179"},
                      {"label": "Osmena", "value": "C87180"},
                      {"label": "Paclolo", "value": "C87181"},
                      {"label": "Paluan", "value": "C87182"},
                      {"label": "Pambisan", "value": "C87183"},
                      {"label": "Panacan", "value": "C87184"},
                      {"label": "Panalingaan", "value": "C87185"},
                      {"label": "Pancol", "value": "C87186"},
                      {"label": "Pangobilian", "value": "C87187"},
                      {"label": "Pangulayan", "value": "C87188"},
                      {"label": "Panique", "value": "C87189"},
                      {"label": "Panitian", "value": "C87190"},
                      {"label": "Panlaitan", "value": "C87191"},
                      {"label": "Pato-o", "value": "C87192"},
                      {"label": "Pili", "value": "C87193"},
                      {"label": "Pinagsabangan", "value": "C87194"},
                      {"label": "Pinamalayan", "value": "C87195"},
                      {"label": "Pitogo", "value": "C87196"},
                      {"label": "Plaridel", "value": "C87197"},
                      {"label": "Pola", "value": "C87198"},
                      {"label": "Port Barton", "value": "C87199"},
                      {"label": "Province of Marinduque", "value": "C87200"},
                      {"label": "Province of Mindoro Occidental",
                       "value": "C87201"},
                      {"label": "Province of Mindoro Oriental",
                       "value": "C87202"},
                      {"label": "Province of Palawan", "value": "C87203"},
                      {"label": "Province of Romblon", "value": "C87204"},
                      {"label": "Puerto Galera", "value": "C87205"},
                      {"label": "Puerto Princesa", "value": "C87206"},
                      {"label": "Punang", "value": "C87207"},
                      {"label": "Punta", "value": "C87208"},
                      {"label": "Quezon", "value": "C87209"},
                      {"label": "Quinabigan", "value": "C87210"},
                      {"label": "Ransang", "value": "C87211"},
                      {"label": "Rio Tuba", "value": "C87212"},
                      {"label": "Rizal", "value": "C87213"},
                      {"label": "Romblon", "value": "C87214"},
                      {"label": "Roxas", "value": "C87215"},
                      {"label": "Saaban", "value": "C87216"},
                      {"label": "Sabang", "value": "C87217"},
                      {"label": "Sablayan", "value": "C87218"},
                      {"label": "Sagana", "value": "C87219"},
                      {"label": "Salcedo", "value": "C87220"},
                      {"label": "Salvacion", "value": "C87221"},
                      {"label": "San Agustin", "value": "C87222"},
                      {"label": "San Aquilino", "value": "C87223"},
                      {"label": "San Fernando", "value": "C87224"},
                      {"label": "San Jose", "value": "C87225"},
                      {"label": "San Mariano", "value": "C87226"},
                      {"label": "San Miguel", "value": "C87227"},
                      {"label": "San Pedro", "value": "C87228"},
                      {"label": "San Teodoro", "value": "C87229"},
                      {"label": "San Vicente", "value": "C87230"},
                      {"label": "Santa Brigida", "value": "C87231"},
                      {"label": "Santa Cruz", "value": "C87232"},
                      {"label": "Santa Fe", "value": "C87233"},
                      {"label": "Santa Maria", "value": "C87234"},
                      {"label": "Santa Teresa", "value": "C87235"},
                      {"label": "Saraza", "value": "C87236"},
                      {"label": "Socorro", "value": "C87237"},
                      {"label": "Sogod", "value": "C87238"},
                      {"label": "Suba", "value": "C87239"},
                      {"label": "Sumagui", "value": "C87240"},
                      {"label": "Tabinay", "value": "C87241"},
                      {"label": "Tabon", "value": "C87242"},
                      {"label": "Tacligan", "value": "C87243"},
                      {"label": "Taclobo", "value": "C87244"},
                      {"label": "Tagbak", "value": "C87245"},
                      {"label": "Tagbita", "value": "C87246"},
                      {"label": "Tagburos", "value": "C87247"},
                      {"label": "Tagusao", "value": "C87248"},
                      {"label": "Tambong", "value": "C87249"},
                      {"label": "Tampayan", "value": "C87250"},
                      {"label": "Tangal", "value": "C87251"},
                      {"label": "Tarusan", "value": "C87252"},
                      {"label": "Tayaman", "value": "C87253"},
                      {"label": "Taytay", "value": "C87254"},
                      {"label": "Tigui", "value": "C87255"},
                      {"label": "Tiguion", "value": "C87256"},
                      {"label": "Tiguisan", "value": "C87257"},
                      {"label": "Tilik", "value": "C87258"},
                      {"label": "Tiniguiban", "value": "C87259"},
                      {"label": "Tomingad", "value": "C87260"},
                      {"label": "Torrijos", "value": "C87261"},
                      {"label": "Tuban", "value": "C87262"},
                      {"label": "Tugdan", "value": "C87263"},
                      {"label": "Tumarbong", "value": "C87264"},
                      {"label": "Victoria", "value": "C87265"},
                      {"label": "Vigo", "value": "C87266"},
                      {"label": "Wawa", "value": "C87267"},
                      {"label": "Yook", "value": "C87268"}],
 "Orkhon Province": [{"label": "Erdenet", "value": "C75579"}],
 "Ormoz Municipality": [{"label": "Ormoz", "value": "C107754"}],
 "Oro Province": [{"label": "Ijivitari", "value": "C80454"},
                  {"label": "Kokoda", "value": "C80455"},
                  {"label": "Popondetta", "value": "C80456"},
                  {"label": "Sohe", "value": "C80457"}],
 "Oromia Region": [{"label": "adis Alem", "value": "C24704"},
                   {"label": "agaro", "value": "C24705"},
                   {"label": "Arsi Zone", "value": "C24706"},
                   {"label": "asasa", "value": "C24707"},
                   {"label": "asbe Teferi", "value": "C24708"},
                   {"label": "Bedele", "value": "C24709"},
                   {"label": "Bedesa", "value": "C24710"},
                   {"label": "Bishoftu", "value": "C24711"},
                   {"label": "Deder", "value": "C24712"},
                   {"label": "Dembi Dolo", "value": "C24713"},
                   {"label": "Dodola", "value": "C24714"},
                   {"label": "East Harerghe Zone", "value": "C24715"},
                   {"label": "East Shewa Zone", "value": "C24716"},
                   {"label": "East Wellega Zone", "value": "C24717"},
                   {"label": "Fiche", "value": "C24718"},
                   {"label": "Gebre Guracha", "value": "C24719"},
                   {"label": "Gedo", "value": "C24720"},
                   {"label": "Gelemso", "value": "C24721"},
                   {"label": "Genet", "value": "C24722"},
                   {"label": "Gimbi", "value": "C24723"},
                   {"label": "Ginir", "value": "C24724"},
                   {"label": "Goba", "value": "C24725"},
                   {"label": "Gore", "value": "C24726"},
                   {"label": "Guji Zone", "value": "C24727"},
                   {"label": "Hagere Hiywet", "value": "C24728"},
                   {"label": "Hagere Maryam", "value": "C24729"},
                   {"label": "Hirna", "value": "C24730"},
                   {"label": "Huruta", "value": "C24731"},
                   {"label": "Illubabor Zone", "value": "C24732"},
                   {"label": "Jimma", "value": "C24733"},
                   {"label": "Jimma Zone", "value": "C24734"},
                   {"label": "Kibre Mengist", "value": "C24735"},
                   {"label": "Kofele", "value": "C24736"},
                   {"label": "Mega", "value": "C24737"},
                   {"label": "Mendi", "value": "C24738"},
                   {"label": "Metahara", "value": "C24739"},
                   {"label": "Metu", "value": "C24740"},
                   {"label": "Mojo", "value": "C24741"},
                   {"label": "Nazret", "value": "C24742"},
                   {"label": "Nejo", "value": "C24743"},
                   {"label": "North Shewa Zone", "value": "C24744"},
                   {"label": "Sebeta", "value": "C24745"},
                   {"label": "Sendafa", "value": "C24746"},
                   {"label": "Shakiso", "value": "C24747"},
                   {"label": "Shambu", "value": "C24748"},
                   {"label": "Shashemene", "value": "C24749"},
                   {"label": "Sirre", "value": "C24750"},
                   {"label": "Tulu Bolo", "value": "C24751"},
                   {"label": "Waliso", "value": "C24752"},
                   {"label": "Wenji", "value": "C24753"},
                   {"label": "West Harerghe Zone", "value": "C24754"},
                   {"label": "West Wellega Zone", "value": "C24755"},
                   {"label": "Yabelo", "value": "C24756"},
                   {"label": "Ziway", "value": "C24757"}],
 "Oruro Department": [{"label": "Challapata", "value": "C9763"},
                      {"label": "Huanuni", "value": "C9764"},
                      {"label": "Litoral de Atacama", "value": "C9765"},
                      {"label": "Machacamarca", "value": "C9766"},
                      {"label": "Nor Carangas Province", "value": "C9767"},
                      {"label": "Oruro", "value": "C9768"},
                      {"label": "Poopo", "value": "C9769"},
                      {"label": "Provincia Avaroa", "value": "C9770"},
                      {"label": "Provincia Carangas", "value": "C9771"},
                      {"label": "Provincia Cercado", "value": "C9772"},
                      {"label": "Provincia Ladislao Cabrera", "value": "C9773"},
                      {"label": "Provincia Pantaleon Dalence",
                       "value": "C9774"},
                      {"label": "Provincia Poopo", "value": "C9775"},
                      {"label": "Provincia Sabaya", "value": "C9776"},
                      {"label": "Provincia Sajama", "value": "C9777"},
                      {"label": "Provincia San Pedro de Totora",
                       "value": "C9778"},
                      {"label": "Provincia Saucari", "value": "C9779"},
                      {"label": "Provincia Tomas Barron", "value": "C9780"},
                      {"label": "Puerto de Mejillones", "value": "C9781"},
                      {"label": "Sebastian Pagador Province", "value": "C9782"},
                      {"label": "Sud Carangas Province", "value": "C9783"},
                      {"label": "Totoral", "value": "C9784"}],
 "Oryol Oblast": [{"label": "Bolkhov", "value": "C102995"},
                  {"label": "Bolkhovskiy Rayon", "value": "C102996"},
                  {"label": "Dmitrovsk-Orlovskiy", "value": "C102997"},
                  {"label": "Glazunovka", "value": "C102998"},
                  {"label": "Khomutovo", "value": "C102999"},
                  {"label": "Khotynets", "value": "C103000"},
                  {"label": "Kolpny", "value": "C103001"},
                  {"label": "Korsakovo", "value": "C103002"},
                  {"label": "Krasnaya Zarya", "value": "C103003"},
                  {"label": "Kromy", "value": "C103004"},
                  {"label": "Livny", "value": "C103005"},
                  {"label": "Maloarkhangelsk", "value": "C103006"},
                  {"label": "Mtsensk", "value": "C103007"},
                  {"label": "Naryshkino", "value": "C103008"},
                  {"label": "Novosil", "value": "C103009"},
                  {"label": "Orel", "value": "C103010"},
                  {"label": "Pokrovskoye", "value": "C103011"},
                  {"label": "Shablykino", "value": "C103012"},
                  {"label": "Soskovo", "value": "C103013"},
                  {"label": "Spasskoye-Lutovinovo", "value": "C103014"},
                  {"label": "Trosna", "value": "C103015"},
                  {"label": "Verkhovye", "value": "C103016"},
                  {"label": "Vyshneye Dolgoye", "value": "C103017"},
                  {"label": "Zalegoshch", "value": "C103018"},
                  {"label": "Zmiyevka", "value": "C103019"},
                  {"label": "Znamenka", "value": "C103020"},
                  {"label": "Znamenskoye", "value": "C103021"}],
 "Osaka Prefecture": [{"label": "Daito Shi", "value": "C63729"},
                      {"label": "Daitocho", "value": "C63730"},
                      {"label": "Fujiidera-shi", "value": "C63731"},
                      {"label": "Habikino-shi", "value": "C63732"},
                      {"label": "Hannan", "value": "C63733"},
                      {"label": "Hannan Shi", "value": "C63734"},
                      {"label": "Higashiosaka Shi", "value": "C63735"},
                      {"label": "Hirakata", "value": "C63736"},
                      {"label": "Hirakata Shi", "value": "C63737"},
                      {"label": "Ibaraki", "value": "C63738"},
                      {"label": "Ibaraki Shi", "value": "C63739"},
                      {"label": "Ikeda", "value": "C63740"},
                      {"label": "Ikeda Shi", "value": "C63741"},
                      {"label": "Izumi", "value": "C63742"},
                      {"label": "Izumi Shi", "value": "C63743"},
                      {"label": "Izumiotsu", "value": "C63744"},
                      {"label": "Izumiotsu Shi", "value": "C63745"},
                      {"label": "Izumisano", "value": "C63746"},
                      {"label": "Izumisano Shi", "value": "C63747"},
                      {"label": "Kadoma", "value": "C63748"},
                      {"label": "Kadoma Shi", "value": "C63749"},
                      {"label": "Kaizuka", "value": "C63750"},
                      {"label": "Kaizuka Shi", "value": "C63751"},
                      {"label": "Kashihara", "value": "C63752"},
                      {"label": "Kashiwara-shi", "value": "C63753"},
                      {"label": "Katano Shi", "value": "C63754"},
                      {"label": "Kawachi-nagano Shi", "value": "C63755"},
                      {"label": "Kishiwada", "value": "C63756"},
                      {"label": "Kishiwada Shi", "value": "C63757"},
                      {"label": "Matsubara", "value": "C63758"},
                      {"label": "Matsubara-shi", "value": "C63759"},
                      {"label": "Mino", "value": "C63760"},
                      {"label": "Minoo Shi", "value": "C63761"},
                      {"label": "Mishima-gun", "value": "C63762"},
                      {"label": "Moriguchi", "value": "C63763"},
                      {"label": "Neyagawa", "value": "C63764"},
                      {"label": "Neyagawa Shi", "value": "C63765"},
                      {"label": "Osaka", "value": "C63766"},
                      {"label": "Osaka-sayama Shi", "value": "C63767"},
                      {"label": "Sakai", "value": "C63768"},
                      {"label": "Sakai Shi", "value": "C63769"},
                      {"label": "Sennan Shi", "value": "C63770"},
                      {"label": "Settsu Shi", "value": "C63771"},
                      {"label": "Shijonawate Shi", "value": "C63772"},
                      {"label": "Suita", "value": "C63773"},
                      {"label": "Suita Shi", "value": "C63774"},
                      {"label": "Tajiri", "value": "C63775"},
                      {"label": "Takaishi", "value": "C63776"},
                      {"label": "Takaishi Shi", "value": "C63777"},
                      {"label": "Takatsuki", "value": "C63778"},
                      {"label": "Takatsuki Shi", "value": "C63779"},
                      {"label": "Tondabayashi Shi", "value": "C63780"},
                      {"label": "Tondabayashicho", "value": "C63781"},
                      {"label": "Toyonaka", "value": "C63782"},
                      {"label": "Toyonaka Shi", "value": "C63783"},
                      {"label": "Yao", "value": "C63784"},
                      {"label": "Yao-shi", "value": "C63785"}],
 "Osh Region": [{"label": "Chong-Alay District", "value": "C64751"},
                {"label": "Daroot-Korgon", "value": "C64752"},
                {"label": "Kara Kulja", "value": "C64753"},
                {"label": "Kara Suu", "value": "C64754"},
                {"label": "Kyzyl-Eshme", "value": "C64755"},
                {"label": "Nookat", "value": "C64756"},
                {"label": "Osh", "value": "C64757"},
                {"label": "Uzgen", "value": "C64758"},
                {"label": "Uzgen District", "value": "C64759"}],
 "Oshana Region": [{"label": "Ondangwa", "value": "C76000"},
                   {"label": "Ongwediva", "value": "C76001"},
                   {"label": "Oshakati", "value": "C76002"}],
 "Oshikoto Region": [{"label": "Omuthiya", "value": "C76003"},
                     {"label": "Tsumeb", "value": "C76004"}],
 "Osijek-Baranja": [{"label": "Antunovac", "value": "C21225"},
                    {"label": "Beli Manastir", "value": "C21226"},
                    {"label": "Belisce", "value": "C21227"},
                    {"label": "Bijelo Brdo", "value": "C21228"},
                    {"label": "Bilje", "value": "C21229"},
                    {"label": "Bistrinci", "value": "C21230"},
                    {"label": "Bizovac", "value": "C21231"},
                    {"label": "Brijest", "value": "C21232"},
                    {"label": "Budrovci", "value": "C21233"},
                    {"label": "Ceminac", "value": "C21234"},
                    {"label": "Cepin", "value": "C21235"},
                    {"label": "Dalj", "value": "C21236"},
                    {"label": "Darda", "value": "C21237"},
                    {"label": "Donji Miholjac", "value": "C21238"},
                    {"label": "Draz", "value": "C21239"},
                    {"label": "Dakovo", "value": "C21240"},
                    {"label": "Durdenovac", "value": "C21241"},
                    {"label": "Erdut", "value": "C21242"},
                    {"label": "Ernestinovo", "value": "C21243"},
                    {"label": "Fericanci", "value": "C21244"},
                    {"label": "Gorjani", "value": "C21245"},
                    {"label": "Grad Beli Manastir", "value": "C21246"},
                    {"label": "Grad Donji Miholjac", "value": "C21247"},
                    {"label": "Grad Nasice", "value": "C21248"},
                    {"label": "Grad Osijek", "value": "C21249"},
                    {"label": "Grad Valpovo", "value": "C21250"},
                    {"label": "Jagodnjak", "value": "C21251"},
                    {"label": "Jelisavac", "value": "C21252"},
                    {"label": "Josipovac", "value": "C21253"},
                    {"label": "Karanac", "value": "C21254"},
                    {"label": "Knezevi Vinogradi", "value": "C21255"},
                    {"label": "Koska", "value": "C21256"},
                    {"label": "Kusevac", "value": "C21257"},
                    {"label": "Ladimirevci", "value": "C21258"},
                    {"label": "Laslovo", "value": "C21259"},
                    {"label": "Magadenovac", "value": "C21260"},
                    {"label": "Marijanci", "value": "C21261"},
                    {"label": "Marjanci", "value": "C21262"},
                    {"label": "Markovac Nasicki", "value": "C21263"},
                    {"label": "Martin", "value": "C21264"},
                    {"label": "Nasice", "value": "C21265"},
                    {"label": "Osijek", "value": "C21266"},
                    {"label": "Petlovac", "value": "C21267"},
                    {"label": "Petrijevci", "value": "C21268"},
                    {"label": "Piskorevci", "value": "C21269"},
                    {"label": "Podgorac", "value": "C21270"},
                    {"label": "Podravska Moslavina", "value": "C21271"},
                    {"label": "Sarvas", "value": "C21272"},
                    {"label": "Satnica Dakovacka", "value": "C21273"},
                    {"label": "Semeljci", "value": "C21274"},
                    {"label": "Siroko Polje", "value": "C21275"},
                    {"label": "Sodolovci", "value": "C21276"},
                    {"label": "Strizivojna", "value": "C21277"},
                    {"label": "Tenja", "value": "C21278"},
                    {"label": "Valpovo", "value": "C21279"},
                    {"label": "Velimirovac", "value": "C21280"},
                    {"label": "Viljevo", "value": "C21281"},
                    {"label": "Viskovci", "value": "C21282"},
                    {"label": "Visnjevac", "value": "C21283"},
                    {"label": "Vladislavci", "value": "C21284"},
                    {"label": "Vuka", "value": "C21285"}],
 "Osilnica Municipality": [{"label": "Osilnica", "value": "C107755"}],
 "Oslo": [{"label": "Oslo", "value": "C78990"},
          {"label": "Sjolyststranda", "value": "C78991"}],
 "Osmaniye": [{"label": "Bahce Ilcesi", "value": "C120869"},
              {"label": "Duzici Ilcesi", "value": "C120870"},
              {"label": "Hasanbeyli", "value": "C120871"},
              {"label": "Kadirli", "value": "C120872"},
              {"label": "Osmaniye", "value": "C120873"},
              {"label": "Sumbas Ilcesi", "value": "C120874"},
              {"label": "Toprakkale", "value": "C120875"}],
 "Ostergotland County": [{"label": "Aby", "value": "C115770"},
                         {"label": "Atvidaberg", "value": "C115771"},
                         {"label": "Berg", "value": "C115772"},
                         {"label": "Borensberg", "value": "C115773"},
                         {"label": "Boxholm", "value": "C115774"},
                         {"label": "Ekangen", "value": "C115775"},
                         {"label": "Finspang", "value": "C115776"},
                         {"label": "Grebo", "value": "C115777"},
                         {"label": "Gusum", "value": "C115778"},
                         {"label": "Herrestad", "value": "C115779"},
                         {"label": "Jursla", "value": "C115780"},
                         {"label": "Kimstad", "value": "C115781"},
                         {"label": "Kisa", "value": "C115782"},
                         {"label": "Krokek", "value": "C115783"},
                         {"label": "Lindo", "value": "C115784"},
                         {"label": "Linghem", "value": "C115785"},
                         {"label": "Linkoping", "value": "C115786"},
                         {"label": "Ljungsbro", "value": "C115787"},
                         {"label": "Malmslatt", "value": "C115788"},
                         {"label": "Mantorp", "value": "C115789"},
                         {"label": "Mjolby", "value": "C115790"},
                         {"label": "Motala", "value": "C115791"},
                         {"label": "Norrkoping", "value": "C115792"},
                         {"label": "Odeshog", "value": "C115793"},
                         {"label": "Osterbymo", "value": "C115794"},
                         {"label": "Rimforsa", "value": "C115795"},
                         {"label": "Ryd", "value": "C115796"},
                         {"label": "Skanninge", "value": "C115797"},
                         {"label": "Skarblacka", "value": "C115798"},
                         {"label": "Soderkoping", "value": "C115799"},
                         {"label": "Stenstorp", "value": "C115800"},
                         {"label": "Sturefors", "value": "C115801"},
                         {"label": "Svartinge", "value": "C115802"},
                         {"label": "Tallboda", "value": "C115803"},
                         {"label": "Vadstena", "value": "C115804"},
                         {"label": "Valdemarsvik", "value": "C115805"},
                         {"label": "Vikingstad", "value": "C115806"}],
 "Ostfold": [{"label": "Aremark", "value": "C78992"},
             {"label": "Askim", "value": "C78993"},
             {"label": "Eidsberg", "value": "C78994"},
             {"label": "Fossby", "value": "C78995"},
             {"label": "Fredrikstad", "value": "C78996"},
             {"label": "Halden", "value": "C78997"},
             {"label": "Hobol", "value": "C78998"},
             {"label": "Hvaler", "value": "C78999"},
             {"label": "Karlshus", "value": "C79000"},
             {"label": "Knappstad", "value": "C79001"},
             {"label": "Larkollen", "value": "C79002"},
             {"label": "Lervik", "value": "C79003"},
             {"label": "Marker", "value": "C79004"},
             {"label": "Moss", "value": "C79005"},
             {"label": "Mysen", "value": "C79006"},
             {"label": "Orje", "value": "C79007"},
             {"label": "Rade", "value": "C79008"},
             {"label": "Rakkestad", "value": "C79009"},
             {"label": "Romskog", "value": "C79010"},
             {"label": "Rygge", "value": "C79011"},
             {"label": "Ryggebyen", "value": "C79012"},
             {"label": "Sarpsborg", "value": "C79013"},
             {"label": "Skiptvet", "value": "C79014"},
             {"label": "Skjaerhalden", "value": "C79015"},
             {"label": "Skjeberg", "value": "C79016"},
             {"label": "Spydeberg", "value": "C79017"},
             {"label": "Tomter", "value": "C79018"},
             {"label": "Trogstad", "value": "C79019"},
             {"label": "Valer", "value": "C79020"}],
 "Ostrobothnia": [{"label": "Hietalahti", "value": "C25023"},
                  {"label": "Isokyro", "value": "C25024"},
                  {"label": "Jakobstad", "value": "C25025"},
                  {"label": "Kaskinen", "value": "C25026"},
                  {"label": "Korsholm", "value": "C25027"},
                  {"label": "Korsnas", "value": "C25028"},
                  {"label": "Kristinestad", "value": "C25029"},
                  {"label": "Kronoby", "value": "C25030"},
                  {"label": "Laihia", "value": "C25031"},
                  {"label": "Larsmo", "value": "C25032"},
                  {"label": "Malax", "value": "C25033"},
                  {"label": "Nykarleby", "value": "C25034"},
                  {"label": "Oravais", "value": "C25035"},
                  {"label": "Pedersore", "value": "C25036"},
                  {"label": "Replot", "value": "C25037"},
                  {"label": "Ristinummi", "value": "C25038"},
                  {"label": "Teeriniemi", "value": "C25039"},
                  {"label": "Vaasa", "value": "C25040"},
                  {"label": "Vahakyro", "value": "C25041"},
                  {"label": "Vora", "value": "C25042"}],
 "Osun": [{"label": "Apomu", "value": "C78435"},
          {"label": "Ejigbo", "value": "C78436"},
          {"label": "Gbongan", "value": "C78437"},
          {"label": "Ijebu-Jesa", "value": "C78438"},
          {"label": "Ikire", "value": "C78439"},
          {"label": "Ikirun", "value": "C78440"},
          {"label": "Ila Orangun", "value": "C78441"},
          {"label": "Ile-Ife", "value": "C78442"},
          {"label": "Ilesa", "value": "C78443"},
          {"label": "Ilobu", "value": "C78444"},
          {"label": "Inisa", "value": "C78445"},
          {"label": "Iwo", "value": "C78446"},
          {"label": "Modakeke", "value": "C78447"},
          {"label": "Oke Mesi", "value": "C78448"},
          {"label": "Olupona", "value": "C78449"},
          {"label": "Osogbo", "value": "C78450"},
          {"label": "Otan Ayegbaju", "value": "C78451"},
          {"label": "Oyan", "value": "C78452"}],
 "Otago Region": [{"label": "Arrowtown", "value": "C77816"},
                  {"label": "Balclutha", "value": "C77817"},
                  {"label": "Clutha District", "value": "C77818"},
                  {"label": "Cromwell", "value": "C77819"},
                  {"label": "Dunedin", "value": "C77820"},
                  {"label": "Kingston", "value": "C77821"},
                  {"label": "Milton", "value": "C77822"},
                  {"label": "Oamaru", "value": "C77823"},
                  {"label": "Papatowai", "value": "C77824"},
                  {"label": "Portobello", "value": "C77825"},
                  {"label": "Queenstown", "value": "C77826"},
                  {"label": "Wanaka", "value": "C77827"}],
 "Oti": [{"label": "Biakoye", "value": "C41540"},
         {"label": "Jasikan", "value": "C41541"},
         {"label": "Kadjebi", "value": "C41542"},
         {"label": "Krachi East", "value": "C41543"},
         {"label": "Krachi Nchumuru", "value": "C41544"},
         {"label": "Krachi West", "value": "C41545"},
         {"label": "Nkwanta North", "value": "C41546"},
         {"label": "Nkwanta South", "value": "C41547"}],
 "Otjozondjupa Region": [{"label": "Grootfontein", "value": "C76005"},
                         {"label": "Okahandja", "value": "C76006"},
                         {"label": "Okakarara", "value": "C76007"},
                         {"label": "Otavi", "value": "C76008"},
                         {"label": "Otjiwarongo", "value": "C76009"}],
 "Ouaddai Region": [{"label": "Abeche", "value": "C17881"},
                    {"label": "Adre", "value": "C17882"},
                    {"label": "Goz Beida", "value": "C17883"}],
 "Ouaka Prefecture": [{"label": "Bambari", "value": "C17837"},
                      {"label": "Grimari", "value": "C17838"},
                      {"label": "Ippy", "value": "C17839"},
                      {"label": "Kouango", "value": "C17840"}],
 "Ouargla": [{"label": "Djamaa", "value": "C381"},
             {"label": "El Hadjira", "value": "C382"},
             {"label": "Hassi Messaoud", "value": "C383"},
             {"label": "Megarine", "value": "C384"},
             {"label": "Ouargla", "value": "C385"},
             {"label": "Rouissat", "value": "C386"},
             {"label": "Sidi Amrane", "value": "C387"},
             {"label": "Tebesbest", "value": "C388"},
             {"label": "Touggourt", "value": "C389"}],
 "Oudomxay Province": [{"label": "Muang Xay", "value": "C64798"}],
 "Oued Ed-Dahab (EH)": [{"label": "Aousserd", "value": "C75800"},
                        {"label": "Imlili", "value": "C75801"},
                        {"label": "Oued-Ed-Dahab", "value": "C75802"}],
 "Oueme Department": [{"label": "Porto-Novo", "value": "C9622"}],
 "Ouest": [{"label": "Anse a Galets", "value": "C43158"},
           {"label": "Arcahaie", "value": "C43159"},
           {"label": "Arrondissement de Croix des Bouquets", "value": "C43160"},
           {"label": "Arrondissement de Leogane", "value": "C43161"},
           {"label": "Arrondissement de Port-au-Prince", "value": "C43162"},
           {"label": "Cabaret", "value": "C43163"},
           {"label": "Carrefour", "value": "C43164"},
           {"label": "Cornillon", "value": "C43165"},
           {"label": "Croix-des-Bouquets", "value": "C43166"},
           {"label": "Delmas 73", "value": "C43167"},
           {"label": "Fond Parisien", "value": "C43168"},
           {"label": "Fonds Verrettes", "value": "C43169"},
           {"label": "Grangwav", "value": "C43170"},
           {"label": "Gressier", "value": "C43171"},
           {"label": "Kenscoff", "value": "C43172"},
           {"label": "Lagonav", "value": "C43173"},
           {"label": "Leogane", "value": "C43174"},
           {"label": "Petionville", "value": "C43175"},
           {"label": "Port-au-Prince", "value": "C43176"},
           {"label": "Thomazeau", "value": "C43177"},
           {"label": "Tigwav", "value": "C43178"}],
 "Ouham Prefecture": [{"label": "Batangafo", "value": "C17841"},
                      {"label": "Bossangoa", "value": "C17842"},
                      {"label": "Bouca", "value": "C17843"},
                      {"label": "Kabo", "value": "C17844"}],
 "Ouham-Pende Prefecture": [{"label": "Bocaranga", "value": "C17845"},
                            {"label": "Bozoum", "value": "C17846"},
                            {"label": "Paoua", "value": "C17847"}],
 "Oum El Bouaghi": [{"label": "Ain Beida", "value": "C390"},
                    {"label": "Ain Fakroun", "value": "C391"},
                    {"label": "Ain Kercha", "value": "C392"},
                    {"label": "El Aouinet", "value": "C393"},
                    {"label": "Meskiana", "value": "C394"},
                    {"label": "Oum el Bouaghi", "value": "C395"}],
 "Overijssel": [{"label": "Almelo", "value": "C77363"},
                {"label": "Baalder", "value": "C77364"},
                {"label": "Berkum", "value": "C77365"},
                {"label": "Blokzijl", "value": "C77366"},
                {"label": "Borgele", "value": "C77367"},
                {"label": "Borne", "value": "C77368"},
                {"label": "Boskamp", "value": "C77369"},
                {"label": "Brunnepe", "value": "C77370"},
                {"label": "Dalfsen", "value": "C77371"},
                {"label": "De Hoven", "value": "C77372"},
                {"label": "Delden", "value": "C77373"},
                {"label": "Den Ham", "value": "C77374"},
                {"label": "Deventer", "value": "C77375"},
                {"label": "Diepenheim", "value": "C77376"},
                {"label": "Enschede", "value": "C77377"},
                {"label": "Flevowijk", "value": "C77378"},
                {"label": "Frankhuis", "value": "C77379"},
                {"label": "Giethoorn", "value": "C77380"},
                {"label": "Goor", "value": "C77381"},
                {"label": "Grafhorst", "value": "C77382"},
                {"label": "Haaksbergen", "value": "C77383"},
                {"label": "Haarle", "value": "C77384"},
                {"label": "Hardenberg", "value": "C77385"},
                {"label": "Hasselt", "value": "C77386"},
                {"label": "Heino", "value": "C77387"},
                {"label": "Hengelo", "value": "C77388"},
                {"label": "Hengevelde", "value": "C77389"},
                {"label": "Het Oostrik", "value": "C77390"},
                {"label": "Kampen", "value": "C77391"},
                {"label": "Klein Driene", "value": "C77392"},
                {"label": "Losser", "value": "C77393"},
                {"label": "Magele", "value": "C77394"},
                {"label": "Markelo", "value": "C77395"},
                {"label": "Mekkelholt", "value": "C77396"},
                {"label": "Nieuwoord", "value": "C77397"},
                {"label": "Nijrees", "value": "C77398"},
                {"label": "Oldemarkt", "value": "C77399"},
                {"label": "Oldenzaal", "value": "C77400"},
                {"label": "Olst", "value": "C77401"},
                {"label": "Ommen", "value": "C77402"},
                {"label": "Oosterholt", "value": "C77403"},
                {"label": "Oostermeenthe", "value": "C77404"},
                {"label": "Ootmarsum", "value": "C77405"},
                {"label": "Pathmos", "value": "C77406"},
                {"label": "Pierik", "value": "C77407"},
                {"label": "Raalte", "value": "C77408"},
                {"label": "Rossum", "value": "C77409"},
                {"label": "Schalkhaar", "value": "C77410"},
                {"label": "Sint Jansklooster", "value": "C77411"},
                {"label": "Staphorst", "value": "C77412"},
                {"label": "Steenwijk", "value": "C77413"},
                {"label": "Steenwijkerwold", "value": "C77414"},
                {"label": "Tubbergen", "value": "C77415"},
                {"label": "Tuk", "value": "C77416"},
                {"label": "Twekkelerveld", "value": "C77417"},
                {"label": "Vollenhove", "value": "C77418"},
                {"label": "Vriezenveen", "value": "C77419"},
                {"label": "Vroomshoop", "value": "C77420"},
                {"label": "Westerhaar-Vriezenveensewijk", "value": "C77421"},
                {"label": "Wierden", "value": "C77422"},
                {"label": "Wijhe", "value": "C77423"},
                {"label": "Woolde", "value": "C77424"},
                {"label": "Wooldrik", "value": "C77425"},
                {"label": "Zuid-Berghuizen", "value": "C77426"},
                {"label": "Zwolle", "value": "C77427"}],
 "Ovorkhangai Province": [{"label": "Arvayheer", "value": "C75580"},
                          {"label": "Harhorin", "value": "C75581"},
                          {"label": "Hovd", "value": "C75582"},
                          {"label": "Kharkhorin", "value": "C75583"},
                          {"label": "Nariynteel", "value": "C75584"}],
 "Oyo": [{"label": "Ago Are", "value": "C78453"},
         {"label": "Alapa", "value": "C78454"},
         {"label": "Fiditi", "value": "C78455"},
         {"label": "Ibadan", "value": "C78456"},
         {"label": "Igbeti", "value": "C78457"},
         {"label": "Igbo-Ora", "value": "C78458"},
         {"label": "Igboho", "value": "C78459"},
         {"label": "Kisi", "value": "C78460"},
         {"label": "Lalupon", "value": "C78461"},
         {"label": "Ogbomoso", "value": "C78462"},
         {"label": "Okeho", "value": "C78463"},
         {"label": "Orita Eruwa", "value": "C78464"},
         {"label": "Oyo", "value": "C78465"},
         {"label": "Saki", "value": "C78466"}],
 "Ozolnieki Municipality": [{"label": "Ozolnieki", "value": "C64909"}],
 "Pahang": [{"label": "Bentong Town", "value": "C65695"},
            {"label": "Jerantut", "value": "C65696"},
            {"label": "Kampung Bukit Tinggi, Bentong", "value": "C65697"},
            {"label": "Kampung Tekek", "value": "C65698"},
            {"label": "Kuala Lipis", "value": "C65699"},
            {"label": "Kuantan", "value": "C65700"},
            {"label": "Mentekab", "value": "C65701"},
            {"label": "Pekan", "value": "C65702"},
            {"label": "Raub", "value": "C65703"},
            {"label": "Tanah Rata", "value": "C65704"},
            {"label": "Temerluh", "value": "C65705"}],
 "Paijanne Tavastia": [{"label": "Artjarvi", "value": "C25043"},
                       {"label": "Asikkala", "value": "C25044"},
                       {"label": "Auttoinen", "value": "C25045"},
                       {"label": "Hameenkoski", "value": "C25046"},
                       {"label": "Hartola", "value": "C25047"},
                       {"label": "Heinola", "value": "C25048"},
                       {"label": "Hollola", "value": "C25049"},
                       {"label": "Lahti", "value": "C25050"},
                       {"label": "Nastola", "value": "C25051"},
                       {"label": "Orimattila", "value": "C25052"},
                       {"label": "Padasjoki", "value": "C25053"},
                       {"label": "Sysma", "value": "C25054"}],
 "Pailin": [{"label": "Khan Sala Krau", "value": "C16512"},
            {"label": "Pailin", "value": "C16513"}],
 "Paktia": [{"label": "Gardez", "value": "C73"}],
 "Paktika": [{"label": "Sarobi", "value": "C74"},
             {"label": "Zarah Sharan", "value": "C75"},
             {"label": "Zarghun Shahr", "value": "C76"}],
 "Palauli": [{"label": "Gataivai", "value": "C106236"},
             {"label": "Vailoa", "value": "C106237"}],
 "Pamplemousses District": [{"label": "Arsenal", "value": "C66018"},
                            {"label": "Calebasses", "value": "C66019"},
                            {"label": "Congomah", "value": "C66020"},
                            {"label": "Creve Coeur", "value": "C66021"},
                            {"label": "Fond du Sac", "value": "C66022"},
                            {"label": "Le Hochet", "value": "C66023"},
                            {"label": "Long Mountain", "value": "C66024"},
                            {"label": "Morcellement Saint Andre",
                             "value": "C66025"},
                            {"label": "Notre Dame", "value": "C66026"},
                            {"label": "Pamplemousses", "value": "C66027"},
                            {"label": "Plaine des Papayes", "value": "C66028"},
                            {"label": "Pointe aux Piments", "value": "C66029"},
                            {"label": "Terre Rouge", "value": "C66030"},
                            {"label": "Triolet", "value": "C66031"}],
 "Panama Oeste Province": [{"label": "Alto del Espino", "value": "C80194"},
                           {"label": "Altos de San Francisco",
                            "value": "C80195"},
                           {"label": "Arenosa", "value": "C80196"},
                           {"label": "Arosemena", "value": "C80197"},
                           {"label": "Arraijan", "value": "C80198"},
                           {"label": "Bejuco", "value": "C80199"},
                           {"label": "Buenos Aires", "value": "C80200"},
                           {"label": "Cabuya", "value": "C80201"},
                           {"label": "Caimito", "value": "C80202"},
                           {"label": "Capira", "value": "C80203"},
                           {"label": "Cermeno", "value": "C80204"},
                           {"label": "Cerro Cama", "value": "C80205"},
                           {"label": "Chame", "value": "C80206"},
                           {"label": "Ciri de Los Sotos", "value": "C80207"},
                           {"label": "Distrito Arraijan", "value": "C80208"},
                           {"label": "Distrito de Capira", "value": "C80209"},
                           {"label": "Distrito de Chame", "value": "C80210"},
                           {"label": "Distrito de La Chorrera",
                            "value": "C80211"},
                           {"label": "Distrito de San Carlos",
                            "value": "C80212"},
                           {"label": "El Cacao", "value": "C80213"},
                           {"label": "El Coco", "value": "C80214"},
                           {"label": "El Espave", "value": "C80215"},
                           {"label": "El Espino", "value": "C80216"},
                           {"label": "El Higo", "value": "C80217"},
                           {"label": "El Libano", "value": "C80218"},
                           {"label": "Guadalupe", "value": "C80219"},
                           {"label": "Guayabito", "value": "C80220"},
                           {"label": "Hato Montana", "value": "C80221"},
                           {"label": "La Chorrera", "value": "C80222"},
                           {"label": "La Ermita", "value": "C80223"},
                           {"label": "La Herradura", "value": "C80224"},
                           {"label": "La Laguna", "value": "C80225"},
                           {"label": "La Mitra", "value": "C80226"},
                           {"label": "La Pesa", "value": "C80227"},
                           {"label": "Las Colinas", "value": "C80228"},
                           {"label": "Las Lajas", "value": "C80229"},
                           {"label": "Las Uvas", "value": "C80230"},
                           {"label": "Llano Largo", "value": "C80231"},
                           {"label": "Los Llanitos", "value": "C80232"},
                           {"label": "Mendoza", "value": "C80233"},
                           {"label": "Nueva Arenosa", "value": "C80234"},
                           {"label": "Nueva Gorgona", "value": "C80235"},
                           {"label": "Nuevo Arraijan", "value": "C80236"},
                           {"label": "Nuevo Emperador", "value": "C80237"},
                           {"label": "Playa Leona", "value": "C80238"},
                           {"label": "Potrero Grande", "value": "C80239"},
                           {"label": "Puerto Caimito", "value": "C80240"},
                           {"label": "Rio Congo", "value": "C80241"},
                           {"label": "San Carlos", "value": "C80242"},
                           {"label": "San Jose", "value": "C80243"},
                           {"label": "San Vicente de Bique", "value": "C80244"},
                           {"label": "Santa Clara", "value": "C80245"},
                           {"label": "Santa Rita", "value": "C80246"},
                           {"label": "Sora", "value": "C80247"},
                           {"label": "Veracruz", "value": "C80248"},
                           {"label": "Villa Carmen", "value": "C80249"},
                           {"label": "Villa Rosario", "value": "C80250"},
                           {"label": "Vista Alegre", "value": "C80251"}],
 "Panama Province": [{"label": "Alcalde Diaz", "value": "C80252"},
                     {"label": "Ancon", "value": "C80253"},
                     {"label": "Brujas", "value": "C80254"},
                     {"label": "Cabra Numero Uno", "value": "C80255"},
                     {"label": "Caimitillo", "value": "C80256"},
                     {"label": "Calzada Larga", "value": "C80257"},
                     {"label": "Canita", "value": "C80258"},
                     {"label": "Cerro Azul", "value": "C80259"},
                     {"label": "Chepillo", "value": "C80260"},
                     {"label": "Chepo", "value": "C80261"},
                     {"label": "Chilibre", "value": "C80262"},
                     {"label": "Chiman", "value": "C80263"},
                     {"label": "Contadora", "value": "C80264"},
                     {"label": "Curundu", "value": "C80265"},
                     {"label": "Distrito de Balboa", "value": "C80266"},
                     {"label": "Distrito de Chepo", "value": "C80267"},
                     {"label": "Distrito de Chiman", "value": "C80268"},
                     {"label": "Distrito de Panama", "value": "C80269"},
                     {"label": "Distrito de Taboga", "value": "C80270"},
                     {"label": "El Chorrillo", "value": "C80271"},
                     {"label": "El Progreso", "value": "C80272"},
                     {"label": "Gonzalillo", "value": "C80273"},
                     {"label": "Howard", "value": "C80274"},
                     {"label": "Juan Diaz", "value": "C80275"},
                     {"label": "La Cabima", "value": "C80276"},
                     {"label": "La Colorada", "value": "C80277"},
                     {"label": "La Ensenada", "value": "C80278"},
                     {"label": "La Esmeralda", "value": "C80279"},
                     {"label": "La Guinea", "value": "C80280"},
                     {"label": "La Mesa", "value": "C80281"},
                     {"label": "Las Cumbres", "value": "C80282"},
                     {"label": "Las Margaritas", "value": "C80283"},
                     {"label": "Lidice", "value": "C80284"},
                     {"label": "Los Lotes", "value": "C80285"},
                     {"label": "Nueva Esperanza", "value": "C80286"},
                     {"label": "Nuevo Belen", "value": "C80287"},
                     {"label": "Pacora", "value": "C80288"},
                     {"label": "Panama", "value": "C80289"},
                     {"label": "Parque Lefevre", "value": "C80290"},
                     {"label": "Pasiga", "value": "C80291"},
                     {"label": "Pedregal", "value": "C80292"},
                     {"label": "Rio Abajo", "value": "C80293"},
                     {"label": "San Antonio", "value": "C80294"},
                     {"label": "San Felipe", "value": "C80295"},
                     {"label": "San Miguel", "value": "C80296"},
                     {"label": "San Miguelito", "value": "C80297"},
                     {"label": "San Vicente", "value": "C80298"},
                     {"label": "Tocumen", "value": "C80299"},
                     {"label": "Torti", "value": "C80300"},
                     {"label": "Union de Azuero", "value": "C80301"},
                     {"label": "Union Santena", "value": "C80302"},
                     {"label": "Villa Unida", "value": "C80303"},
                     {"label": "Vista Hermosa", "value": "C80304"}],
 "Pando Department": [{"label": "Cobija", "value": "C9785"},
                      {"label": "Provincia Abuna", "value": "C9786"},
                      {"label": "Provincia General Federico Roman",
                       "value": "C9787"},
                      {"label": "Provincia Madre de Dios", "value": "C9788"},
                      {"label": "Provincia Manuripi", "value": "C9789"},
                      {"label": "Provincia Nicolas Suarez", "value": "C9790"}],
 "Panevezys District Municipality": [{"label": "Birzai", "value": "C65138"},
                                     {"label": "Juodupe", "value": "C65139"},
                                     {"label": "Kupiskis", "value": "C65140"},
                                     {"label": "Obeliai", "value": "C65141"},
                                     {"label": "Panevezys", "value": "C65142"},
                                     {"label": "Panevezys City",
                                      "value": "C65143"},
                                     {"label": "Panevezys District Municipality",
                                      "value": "C65144"},
                                     {"label": "Pasvalys", "value": "C65145"},
                                     {"label": "Ramygala", "value": "C65146"},
                                     {"label": "Rokiskis", "value": "C65147"},
                                     {"label": "Vabalninkas",
                                      "value": "C65148"}],
 "Panjshir": [{"label": "Bazarak", "value": "C77"}],
 "Paola": [{"label": "Paola", "value": "C65905"}],
 "Paphos District (Pafos)": [{"label": "Argaka", "value": "C21938"},
                             {"label": "Chlorakas", "value": "C21939"},
                             {"label": "Emba", "value": "C21940"},
                             {"label": "Geroskipou", "value": "C21941"},
                             {"label": "Geroskipou (quarter)",
                              "value": "C21942"},
                             {"label": "Geroskipou Municipality",
                              "value": "C21943"},
                             {"label": "Kissonerga", "value": "C21944"},
                             {"label": "Koloni", "value": "C21945"},
                             {"label": "Konia", "value": "C21946"},
                             {"label": "Mesogi", "value": "C21947"},
                             {"label": "Paphos", "value": "C21948"},
                             {"label": "Pegeia", "value": "C21949"},
                             {"label": "Polis", "value": "C21950"},
                             {"label": "Tala", "value": "C21951"},
                             {"label": "Tsada", "value": "C21952"}],
 "Papua": [{"label": "Abepura", "value": "C49776"},
           {"label": "Biak", "value": "C49777"},
           {"label": "Insrom", "value": "C49778"},
           {"label": "Jayapura", "value": "C49779"},
           {"label": "Kabupaten Asmat", "value": "C49780"},
           {"label": "Kabupaten Biak Numfor", "value": "C49781"},
           {"label": "Kabupaten Boven Digoel", "value": "C49782"},
           {"label": "Kabupaten Deiyai", "value": "C49783"},
           {"label": "Kabupaten Dogiyai", "value": "C49784"},
           {"label": "Kabupaten Intan Jaya", "value": "C49785"},
           {"label": "Kabupaten Jayapura", "value": "C49786"},
           {"label": "Kabupaten Jayawijaya", "value": "C49787"},
           {"label": "Kabupaten Keerom", "value": "C49788"},
           {"label": "Kabupaten Kepulauan Yapen", "value": "C49789"},
           {"label": "Kabupaten Lanny Jaya", "value": "C49790"},
           {"label": "Kabupaten Mamberamo Raya", "value": "C49791"},
           {"label": "Kabupaten Mamberamo Tengah", "value": "C49792"},
           {"label": "Kabupaten Mappi", "value": "C49793"},
           {"label": "Kabupaten Merauke", "value": "C49794"},
           {"label": "Kabupaten Mimika", "value": "C49795"},
           {"label": "Kabupaten Nabire", "value": "C49796"},
           {"label": "Kabupaten Nduga", "value": "C49797"},
           {"label": "Kabupaten Paniai", "value": "C49798"},
           {"label": "Kabupaten Pegunungan Bintang", "value": "C49799"},
           {"label": "Kabupaten Puncak Jaya", "value": "C49800"},
           {"label": "Kabupaten Sarmi", "value": "C49801"},
           {"label": "Kabupaten Supiori", "value": "C49802"},
           {"label": "Kabupaten Tolikara", "value": "C49803"},
           {"label": "Kabupaten Waropen", "value": "C49804"},
           {"label": "Kabupaten Yahukimo", "value": "C49805"},
           {"label": "Kabupaten Yalimo", "value": "C49806"},
           {"label": "Kota Jayapura", "value": "C49807"},
           {"label": "Nabire", "value": "C49808"}],
 "Papua Barat": [{"label": "Kabupaten Fakfak", "value": "C49809"},
                 {"label": "Kabupaten Kaimana", "value": "C49810"},
                 {"label": "Kabupaten Manokwari", "value": "C49811"},
                 {"label": "Kabupaten Manokwari Selatan", "value": "C49812"},
                 {"label": "Kabupaten Maybrat", "value": "C49813"},
                 {"label": "Kabupaten Raja Ampat", "value": "C49814"},
                 {"label": "Kabupaten Sorong", "value": "C49815"},
                 {"label": "Kabupaten Sorong Selatan", "value": "C49816"},
                 {"label": "Kabupaten Tambrauw", "value": "C49817"},
                 {"label": "Kabupaten Teluk Bintuni", "value": "C49818"},
                 {"label": "Kabupaten Teluk Wondama", "value": "C49819"},
                 {"label": "Kota Sorong", "value": "C49820"},
                 {"label": "Manokwari", "value": "C49821"},
                 {"label": "Sorong", "value": "C49822"}],
 "Para": [{"label": "Abaetetuba", "value": "C12634"},
          {"label": "Abel Figueiredo", "value": "C12635"},
          {"label": "Acara", "value": "C12636"},
          {"label": "Afua", "value": "C12637"},
          {"label": "agua Azul do Norte", "value": "C12638"},
          {"label": "Alenquer", "value": "C12639"},
          {"label": "Almeirim", "value": "C12640"},
          {"label": "Altamira", "value": "C12641"},
          {"label": "Anajas", "value": "C12642"},
          {"label": "Ananindeua", "value": "C12643"},
          {"label": "Anapu", "value": "C12644"},
          {"label": "Augusto Correa", "value": "C12645"},
          {"label": "Aurora do Para", "value": "C12646"},
          {"label": "Aveiro", "value": "C12647"},
          {"label": "Bagre", "value": "C12648"},
          {"label": "Baiao", "value": "C12649"},
          {"label": "Bannach", "value": "C12650"},
          {"label": "Barcarena", "value": "C12651"},
          {"label": "Belem", "value": "C12652"},
          {"label": "Belterra", "value": "C12653"},
          {"label": "Benevides", "value": "C12654"},
          {"label": "Bom Jesus do Tocantins", "value": "C12655"},
          {"label": "Bonito", "value": "C12656"},
          {"label": "Braganca", "value": "C12657"},
          {"label": "Brasil Novo", "value": "C12658"},
          {"label": "Brejo Grande do Araguaia", "value": "C12659"},
          {"label": "Breu Branco", "value": "C12660"},
          {"label": "Breves", "value": "C12661"},
          {"label": "Bujaru", "value": "C12662"},
          {"label": "Cachoeira do Arari", "value": "C12663"},
          {"label": "Cachoeira do Piria", "value": "C12664"},
          {"label": "Cameta", "value": "C12665"},
          {"label": "Canaa dos Carajas", "value": "C12666"},
          {"label": "Capanema", "value": "C12667"},
          {"label": "Capitao Poco", "value": "C12668"},
          {"label": "Castanhal", "value": "C12669"},
          {"label": "Chaves", "value": "C12670"},
          {"label": "Colares", "value": "C12671"},
          {"label": "Conceicao do Araguaia", "value": "C12672"},
          {"label": "Concordia do Para", "value": "C12673"},
          {"label": "Cumaru do Norte", "value": "C12674"},
          {"label": "Curionopolis", "value": "C12675"},
          {"label": "Curralinho", "value": "C12676"},
          {"label": "Curua", "value": "C12677"},
          {"label": "Curuca", "value": "C12678"},
          {"label": "Dom Eliseu", "value": "C12679"},
          {"label": "Eldorado do Carajas", "value": "C12680"},
          {"label": "Faro", "value": "C12681"},
          {"label": "Floresta do Araguaia", "value": "C12682"},
          {"label": "Garrafao do Norte", "value": "C12683"},
          {"label": "Goianesia do Para", "value": "C12684"},
          {"label": "Gurupa", "value": "C12685"},
          {"label": "Igarape Acu", "value": "C12686"},
          {"label": "Igarape Miri", "value": "C12687"},
          {"label": "Igarape-Acu", "value": "C12688"},
          {"label": "Igarape-Miri", "value": "C12689"},
          {"label": "Inhangapi", "value": "C12690"},
          {"label": "Ipixuna do Para", "value": "C12691"},
          {"label": "Irituia", "value": "C12692"},
          {"label": "Itaituba", "value": "C12693"},
          {"label": "Itupiranga", "value": "C12694"},
          {"label": "Jacareacanga", "value": "C12695"},
          {"label": "Jacunda", "value": "C12696"},
          {"label": "Juruti", "value": "C12697"},
          {"label": "Limoeiro do Ajuru", "value": "C12698"},
          {"label": "Mae do Rio", "value": "C12699"},
          {"label": "Magalhaes Barata", "value": "C12700"},
          {"label": "Maraba", "value": "C12701"},
          {"label": "Maracana", "value": "C12702"},
          {"label": "Marapanim", "value": "C12703"},
          {"label": "Marituba", "value": "C12704"},
          {"label": "Medicilandia", "value": "C12705"},
          {"label": "Melgaco", "value": "C12706"},
          {"label": "Mocajuba", "value": "C12707"},
          {"label": "Moju", "value": "C12708"},
          {"label": "Mojui Dos Campos", "value": "C12709"},
          {"label": "Monte Alegre", "value": "C12710"},
          {"label": "Muana", "value": "C12711"},
          {"label": "Nova Esperanca do Piria", "value": "C12712"},
          {"label": "Nova Ipixuna", "value": "C12713"},
          {"label": "Nova Timboteua", "value": "C12714"},
          {"label": "Novo Progresso", "value": "C12715"},
          {"label": "Novo Repartimento", "value": "C12716"},
          {"label": "obidos", "value": "C12717"},
          {"label": "Oeiras do Para", "value": "C12718"},
          {"label": "Oriximina", "value": "C12719"},
          {"label": "Ourem", "value": "C12720"},
          {"label": "Ourilandia do Norte", "value": "C12721"},
          {"label": "Pacaja", "value": "C12722"},
          {"label": "Palestina do Para", "value": "C12723"},
          {"label": "Paragominas", "value": "C12724"},
          {"label": "Parauapebas", "value": "C12725"},
          {"label": "Pau dArco", "value": "C12726"},
          {"label": "Peixe-Boi", "value": "C12727"},
          {"label": "Picarra", "value": "C12728"},
          {"label": "Placas", "value": "C12729"},
          {"label": "Ponta de Pedras", "value": "C12730"},
          {"label": "Portel", "value": "C12731"},
          {"label": "Porto de Moz", "value": "C12732"},
          {"label": "Prainha", "value": "C12733"},
          {"label": "Primavera", "value": "C12734"},
          {"label": "Quatipuru", "value": "C12735"},
          {"label": "Redencao", "value": "C12736"},
          {"label": "Rio Maria", "value": "C12737"},
          {"label": "Rondon do Para", "value": "C12738"},
          {"label": "Ruropolis", "value": "C12739"},
          {"label": "Salinopolis", "value": "C12740"},
          {"label": "Salvaterra", "value": "C12741"},
          {"label": "Santa Barbara do Para", "value": "C12742"},
          {"label": "Santa Cruz do Arari", "value": "C12743"},
          {"label": "Santa Izabel do Para", "value": "C12744"},
          {"label": "Santa Luzia do Para", "value": "C12745"},
          {"label": "Santa Maria das Barreiras", "value": "C12746"},
          {"label": "Santa Maria do Para", "value": "C12747"},
          {"label": "Santana do Araguaia", "value": "C12748"},
          {"label": "Santarem", "value": "C12749"},
          {"label": "Santarem Novo", "value": "C12750"},
          {"label": "Santo Antonio do Taua", "value": "C12751"},
          {"label": "Sao Caetano de Odivelas", "value": "C12752"},
          {"label": "Sao Domingos do Araguaia", "value": "C12753"},
          {"label": "Sao Domingos do Capim", "value": "C12754"},
          {"label": "Sao Felix do Xingu", "value": "C12755"},
          {"label": "Sao Francisco do Para", "value": "C12756"},
          {"label": "Sao Geraldo do Araguaia", "value": "C12757"},
          {"label": "Sao Joao da Ponta", "value": "C12758"},
          {"label": "Sao Joao de Pirabas", "value": "C12759"},
          {"label": "Sao Joao do Araguaia", "value": "C12760"},
          {"label": "Sao Miguel do Guama", "value": "C12761"},
          {"label": "Sao Sebastiao da Boa Vista", "value": "C12762"},
          {"label": "Sapucaia", "value": "C12763"},
          {"label": "Senador Jose Porfirio", "value": "C12764"},
          {"label": "Soure", "value": "C12765"},
          {"label": "Tailandia", "value": "C12766"},
          {"label": "Terra Alta", "value": "C12767"},
          {"label": "Terra Santa", "value": "C12768"},
          {"label": "Tome Acu", "value": "C12769"},
          {"label": "Tome-Acu", "value": "C12770"},
          {"label": "Tracuateua", "value": "C12771"},
          {"label": "Trairao", "value": "C12772"},
          {"label": "Tucuma", "value": "C12773"},
          {"label": "Tucurui", "value": "C12774"},
          {"label": "Ulianopolis", "value": "C12775"},
          {"label": "Uruara", "value": "C12776"},
          {"label": "Vigia", "value": "C12777"},
          {"label": "Viseu", "value": "C12778"},
          {"label": "Vitoria do Xingu", "value": "C12779"},
          {"label": "Xinguara", "value": "C12780"}],
 "Para District": [{"label": "Onverwacht", "value": "C115469"}],
 "Paraguari Department": [{"label": "Acahay", "value": "C80601"},
                          {"label": "Caapucu", "value": "C80602"},
                          {"label": "Carapegua", "value": "C80603"},
                          {"label": "La Colmena", "value": "C80604"},
                          {"label": "Mbuyapey", "value": "C80605"},
                          {"label": "Paraguari", "value": "C80606"},
                          {"label": "Pirayu", "value": "C80607"},
                          {"label": "Quiindy", "value": "C80608"},
                          {"label": "Sapucai", "value": "C80609"},
                          {"label": "Yaguaron", "value": "C80610"},
                          {"label": "Ybycui", "value": "C80611"}],
 "Paraiba": [{"label": "agua Branca", "value": "C12781"},
             {"label": "Aguiar", "value": "C12782"},
             {"label": "Alagoa Grande", "value": "C12783"},
             {"label": "Alagoa Nova", "value": "C12784"},
             {"label": "Alagoinha", "value": "C12785"},
             {"label": "Alcantil", "value": "C12786"},
             {"label": "Algodao de Jandaira", "value": "C12787"},
             {"label": "Alhandra", "value": "C12788"},
             {"label": "Amparo", "value": "C12789"},
             {"label": "Aparecida", "value": "C12790"},
             {"label": "Aracagi", "value": "C12791"},
             {"label": "Arara", "value": "C12792"},
             {"label": "Araruna", "value": "C12793"},
             {"label": "Areia", "value": "C12794"},
             {"label": "Areia de Baraunas", "value": "C12795"},
             {"label": "Areial", "value": "C12796"},
             {"label": "Aroeiras", "value": "C12797"},
             {"label": "Assuncao", "value": "C12798"},
             {"label": "Baia da Traicao", "value": "C12799"},
             {"label": "Bananeiras", "value": "C12800"},
             {"label": "Barauna", "value": "C12801"},
             {"label": "Barra de Santa Rosa", "value": "C12802"},
             {"label": "Barra de Santana", "value": "C12803"},
             {"label": "Barra de Sao Miguel", "value": "C12804"},
             {"label": "Bayeux", "value": "C12805"},
             {"label": "Belem", "value": "C12806"},
             {"label": "Belem do Brejo do Cruz", "value": "C12807"},
             {"label": "Bernardino Batista", "value": "C12808"},
             {"label": "Boa Ventura", "value": "C12809"},
             {"label": "Boa Vista", "value": "C12810"},
             {"label": "Bom Jesus", "value": "C12811"},
             {"label": "Bom Sucesso", "value": "C12812"},
             {"label": "Bonito de Santa Fe", "value": "C12813"},
             {"label": "Boqueirao", "value": "C12814"},
             {"label": "Borborema", "value": "C12815"},
             {"label": "Brejo do Cruz", "value": "C12816"},
             {"label": "Brejo dos Santos", "value": "C12817"},
             {"label": "Caapora", "value": "C12818"},
             {"label": "Cabaceiras", "value": "C12819"},
             {"label": "Cabedelo", "value": "C12820"},
             {"label": "Cachoeira dos indios", "value": "C12821"},
             {"label": "Cacimba de Areia", "value": "C12822"},
             {"label": "Cacimba de Dentro", "value": "C12823"},
             {"label": "Cacimbas", "value": "C12824"},
             {"label": "Caicara", "value": "C12825"},
             {"label": "Cajazeiras", "value": "C12826"},
             {"label": "Cajazeirinhas", "value": "C12827"},
             {"label": "Caldas Brandao", "value": "C12828"},
             {"label": "Camalau", "value": "C12829"},
             {"label": "Campina Grande", "value": "C12830"},
             {"label": "Capim", "value": "C12831"},
             {"label": "Caraubas", "value": "C12832"},
             {"label": "Carrapateira", "value": "C12833"},
             {"label": "Casserengue", "value": "C12834"},
             {"label": "Catingueira", "value": "C12835"},
             {"label": "Catole do Rocha", "value": "C12836"},
             {"label": "Caturite", "value": "C12837"},
             {"label": "Conceicao", "value": "C12838"},
             {"label": "Condado", "value": "C12839"},
             {"label": "Conde", "value": "C12840"},
             {"label": "Congo", "value": "C12841"},
             {"label": "Coremas", "value": "C12842"},
             {"label": "Coxixola", "value": "C12843"},
             {"label": "Cruz do Espirito Santo", "value": "C12844"},
             {"label": "Cubati", "value": "C12845"},
             {"label": "Cuite", "value": "C12846"},
             {"label": "Cuite de Mamanguape", "value": "C12847"},
             {"label": "Cuitegi", "value": "C12848"},
             {"label": "Curral de Cima", "value": "C12849"},
             {"label": "Curral Velho", "value": "C12850"},
             {"label": "Damiao", "value": "C12851"},
             {"label": "Desterro", "value": "C12852"},
             {"label": "Diamante", "value": "C12853"},
             {"label": "Dona Ines", "value": "C12854"},
             {"label": "Duas Estradas", "value": "C12855"},
             {"label": "Emas", "value": "C12856"},
             {"label": "Esperanca", "value": "C12857"},
             {"label": "Fagundes", "value": "C12858"},
             {"label": "Frei Martinho", "value": "C12859"},
             {"label": "Gado Bravo", "value": "C12860"},
             {"label": "Guarabira", "value": "C12861"},
             {"label": "Gurinhem", "value": "C12862"},
             {"label": "Gurjao", "value": "C12863"},
             {"label": "Ibiara", "value": "C12864"},
             {"label": "Igaracy", "value": "C12865"},
             {"label": "Imaculada", "value": "C12866"},
             {"label": "Inga", "value": "C12867"},
             {"label": "Itabaiana", "value": "C12868"},
             {"label": "Itaporanga", "value": "C12869"},
             {"label": "Itapororoca", "value": "C12870"},
             {"label": "Itatuba", "value": "C12871"},
             {"label": "Jacarau", "value": "C12872"},
             {"label": "Jerico", "value": "C12873"},
             {"label": "Joao Pessoa", "value": "C12874"},
             {"label": "Joca Claudino", "value": "C12875"},
             {"label": "Juarez Tavora", "value": "C12876"},
             {"label": "Juazeirinho", "value": "C12877"},
             {"label": "Junco do Serido", "value": "C12878"},
             {"label": "Juripiranga", "value": "C12879"},
             {"label": "Juru", "value": "C12880"},
             {"label": "Lagoa", "value": "C12881"},
             {"label": "Lagoa de Dentro", "value": "C12882"},
             {"label": "Lagoa Seca", "value": "C12883"},
             {"label": "Lastro", "value": "C12884"},
             {"label": "Livramento", "value": "C12885"},
             {"label": "Logradouro", "value": "C12886"},
             {"label": "Lucena", "value": "C12887"},
             {"label": "Mae dagua", "value": "C12888"},
             {"label": "Malta", "value": "C12889"},
             {"label": "Mamanguape", "value": "C12890"},
             {"label": "Manaira", "value": "C12891"},
             {"label": "Marcacao", "value": "C12892"},
             {"label": "Mari", "value": "C12893"},
             {"label": "Marizopolis", "value": "C12894"},
             {"label": "Massaranduba", "value": "C12895"},
             {"label": "Mataraca", "value": "C12896"},
             {"label": "Matinhas", "value": "C12897"},
             {"label": "Mato Grosso", "value": "C12898"},
             {"label": "Matureia", "value": "C12899"},
             {"label": "Mogeiro", "value": "C12900"},
             {"label": "Montadas", "value": "C12901"},
             {"label": "Monte Horebe", "value": "C12902"},
             {"label": "Monteiro", "value": "C12903"},
             {"label": "Mulungu", "value": "C12904"},
             {"label": "Natuba", "value": "C12905"},
             {"label": "Nazarezinho", "value": "C12906"},
             {"label": "Nova Floresta", "value": "C12907"},
             {"label": "Nova Olinda", "value": "C12908"},
             {"label": "Nova Palmeira", "value": "C12909"},
             {"label": "Olho dagua", "value": "C12910"},
             {"label": "Olivedos", "value": "C12911"},
             {"label": "Ouro Velho", "value": "C12912"},
             {"label": "Parari", "value": "C12913"},
             {"label": "Passagem", "value": "C12914"},
             {"label": "Patos", "value": "C12915"},
             {"label": "Paulista", "value": "C12916"},
             {"label": "Pedra Branca", "value": "C12917"},
             {"label": "Pedra Lavrada", "value": "C12918"},
             {"label": "Pedras de Fogo", "value": "C12919"},
             {"label": "Pedro Regis", "value": "C12920"},
             {"label": "Pianco", "value": "C12921"},
             {"label": "Picui", "value": "C12922"},
             {"label": "Pilar", "value": "C12923"},
             {"label": "Piloes", "value": "C12924"},
             {"label": "Piloezinhos", "value": "C12925"},
             {"label": "Pirpirituba", "value": "C12926"},
             {"label": "Pitimbu", "value": "C12927"},
             {"label": "Pocinhos", "value": "C12928"},
             {"label": "Poco Dantas", "value": "C12929"},
             {"label": "Poco de Jose de Moura", "value": "C12930"},
             {"label": "Pombal", "value": "C12931"},
             {"label": "Prata", "value": "C12932"},
             {"label": "Princesa Isabel", "value": "C12933"},
             {"label": "Puxinana", "value": "C12934"},
             {"label": "Queimadas", "value": "C12935"},
             {"label": "Quixaba", "value": "C12936"},
             {"label": "Remigio", "value": "C12937"},
             {"label": "Riachao", "value": "C12938"},
             {"label": "Riachao do Bacamarte", "value": "C12939"},
             {"label": "Riachao do Poco", "value": "C12940"},
             {"label": "Riacho de Santo Antonio", "value": "C12941"},
             {"label": "Riacho dos Cavalos", "value": "C12942"},
             {"label": "Rio Tinto", "value": "C12943"},
             {"label": "Salgadinho", "value": "C12944"},
             {"label": "Salgado de Sao Felix", "value": "C12945"},
             {"label": "Santa Cecilia", "value": "C12946"},
             {"label": "Santa Cruz", "value": "C12947"},
             {"label": "Santa Helena", "value": "C12948"},
             {"label": "Santa Ines", "value": "C12949"},
             {"label": "Santa Luzia", "value": "C12950"},
             {"label": "Santa Rita", "value": "C12951"},
             {"label": "Santa Teresinha", "value": "C12952"},
             {"label": "Santana de Mangueira", "value": "C12953"},
             {"label": "Santana dos Garrotes", "value": "C12954"},
             {"label": "Santo Andre", "value": "C12955"},
             {"label": "Sao Bentinho", "value": "C12956"},
             {"label": "Sao Bento", "value": "C12957"},
             {"label": "Sao Domingos", "value": "C12958"},
             {"label": "Sao Domingos do Cariri", "value": "C12959"},
             {"label": "Sao Francisco", "value": "C12960"},
             {"label": "Sao Joao do Cariri", "value": "C12961"},
             {"label": "Sao Joao do Rio do Peixe", "value": "C12962"},
             {"label": "Sao Joao do Tigre", "value": "C12963"},
             {"label": "Sao Jose da Lagoa Tapada", "value": "C12964"},
             {"label": "Sao Jose de Caiana", "value": "C12965"},
             {"label": "Sao Jose de Espinharas", "value": "C12966"},
             {"label": "Sao Jose de Piranhas", "value": "C12967"},
             {"label": "Sao Jose de Princesa", "value": "C12968"},
             {"label": "Sao Jose do Bonfim", "value": "C12969"},
             {"label": "Sao Jose do Brejo do Cruz", "value": "C12970"},
             {"label": "Sao Jose do Sabugi", "value": "C12971"},
             {"label": "Sao Jose dos Cordeiros", "value": "C12972"},
             {"label": "Sao Jose dos Ramos", "value": "C12973"},
             {"label": "Sao Mamede", "value": "C12974"},
             {"label": "Sao Miguel de Taipu", "value": "C12975"},
             {"label": "Sao Sebastiao de Lagoa de Roca", "value": "C12976"},
             {"label": "Sao Sebastiao do Umbuzeiro", "value": "C12977"},
             {"label": "Sao Vicente do Serido", "value": "C12978"},
             {"label": "Sape", "value": "C12979"},
             {"label": "Serra Branca", "value": "C12980"},
             {"label": "Serra da Raiz", "value": "C12981"},
             {"label": "Serra Grande", "value": "C12982"},
             {"label": "Serra Redonda", "value": "C12983"},
             {"label": "Serraria", "value": "C12984"},
             {"label": "Sertaozinho", "value": "C12985"},
             {"label": "Sobrado", "value": "C12986"},
             {"label": "Solanea", "value": "C12987"},
             {"label": "Soledade", "value": "C12988"},
             {"label": "Sossego", "value": "C12989"},
             {"label": "Sousa", "value": "C12990"},
             {"label": "Sume", "value": "C12991"},
             {"label": "Tacima", "value": "C12992"},
             {"label": "Taperoa", "value": "C12993"},
             {"label": "Tavares", "value": "C12994"},
             {"label": "Teixeira", "value": "C12995"},
             {"label": "Tenorio", "value": "C12996"},
             {"label": "Triunfo", "value": "C12997"},
             {"label": "Uirauna", "value": "C12998"},
             {"label": "Umbuzeiro", "value": "C12999"},
             {"label": "Varzea", "value": "C13000"},
             {"label": "Vieiropolis", "value": "C13001"},
             {"label": "Vista Serrana", "value": "C13002"},
             {"label": "Zabele", "value": "C13003"}],
 "Paramaribo District": [{"label": "Paramaribo", "value": "C115470"}],
 "Parana": [{"label": "Abatia", "value": "C13004"},
            {"label": "Adrianopolis", "value": "C13005"},
            {"label": "Agudos do Sul", "value": "C13006"},
            {"label": "Almirante Tamandare", "value": "C13007"},
            {"label": "Altamira do Parana", "value": "C13008"},
            {"label": "Altania", "value": "C13009"},
            {"label": "Alto Paraiso", "value": "C13010"},
            {"label": "Alto Parana", "value": "C13011"},
            {"label": "Alto Piquiri", "value": "C13012"},
            {"label": "Altonia", "value": "C13013"},
            {"label": "Alvorada do Sul", "value": "C13014"},
            {"label": "Amapora", "value": "C13015"},
            {"label": "Ampere", "value": "C13016"},
            {"label": "Anahy", "value": "C13017"},
            {"label": "Andira", "value": "C13018"},
            {"label": "Angulo", "value": "C13019"},
            {"label": "Antonina", "value": "C13020"},
            {"label": "Antonio Olinto", "value": "C13021"},
            {"label": "Apucarana", "value": "C13022"},
            {"label": "Arapongas", "value": "C13023"},
            {"label": "Arapoti", "value": "C13024"},
            {"label": "Arapua", "value": "C13025"},
            {"label": "Araruna", "value": "C13026"},
            {"label": "Araucaria", "value": "C13027"},
            {"label": "Ariranha do Ivai", "value": "C13028"},
            {"label": "Assai", "value": "C13029"},
            {"label": "Assis Chateaubriand", "value": "C13030"},
            {"label": "Astorga", "value": "C13031"},
            {"label": "Atalaia", "value": "C13032"},
            {"label": "Balsa Nova", "value": "C13033"},
            {"label": "Bandeirantes", "value": "C13034"},
            {"label": "Barbosa Ferraz", "value": "C13035"},
            {"label": "Barra do Jacare", "value": "C13036"},
            {"label": "Barracao", "value": "C13037"},
            {"label": "Bela Vista da Caroba", "value": "C13038"},
            {"label": "Bela Vista do Paraiso", "value": "C13039"},
            {"label": "Bituruna", "value": "C13040"},
            {"label": "Boa Esperanca", "value": "C13041"},
            {"label": "Boa Esperanca do Iguacu", "value": "C13042"},
            {"label": "Boa Ventura de Sao Roque", "value": "C13043"},
            {"label": "Boa Vista da Aparecida", "value": "C13044"},
            {"label": "Bocaiuva do Sul", "value": "C13045"},
            {"label": "Bom Jesus do Sul", "value": "C13046"},
            {"label": "Bom Sucesso", "value": "C13047"},
            {"label": "Bom Sucesso do Sul", "value": "C13048"},
            {"label": "Borrazopolis", "value": "C13049"},
            {"label": "Braganey", "value": "C13050"},
            {"label": "Brasilandia do Sul", "value": "C13051"},
            {"label": "Cafeara", "value": "C13052"},
            {"label": "Cafelandia", "value": "C13053"},
            {"label": "Cafezal do Sul", "value": "C13054"},
            {"label": "California", "value": "C13055"},
            {"label": "Cambara", "value": "C13056"},
            {"label": "Cambe", "value": "C13057"},
            {"label": "Cambira", "value": "C13058"},
            {"label": "Campina da Lagoa", "value": "C13059"},
            {"label": "Campina do Simao", "value": "C13060"},
            {"label": "Campina Grande do Sul", "value": "C13061"},
            {"label": "Campo Bonito", "value": "C13062"},
            {"label": "Campo do Tenente", "value": "C13063"},
            {"label": "Campo Largo", "value": "C13064"},
            {"label": "Campo Magro", "value": "C13065"},
            {"label": "Campo Mourao", "value": "C13066"},
            {"label": "Candido de Abreu", "value": "C13067"},
            {"label": "Candoi", "value": "C13068"},
            {"label": "Cantagalo", "value": "C13069"},
            {"label": "Capanema", "value": "C13070"},
            {"label": "Capitao Leonidas Marques", "value": "C13071"},
            {"label": "Carambei", "value": "C13072"},
            {"label": "Carlopolis", "value": "C13073"},
            {"label": "Cascavel", "value": "C13074"},
            {"label": "Castro", "value": "C13075"},
            {"label": "Catanduvas", "value": "C13076"},
            {"label": "Centenario do Sul", "value": "C13077"},
            {"label": "Cerro Azul", "value": "C13078"},
            {"label": "Ceu Azul", "value": "C13079"},
            {"label": "Chopinzinho", "value": "C13080"},
            {"label": "Cianorte", "value": "C13081"},
            {"label": "Cidade Gaucha", "value": "C13082"},
            {"label": "Clevelandia", "value": "C13083"},
            {"label": "Colombo", "value": "C13084"},
            {"label": "Colorado", "value": "C13085"},
            {"label": "Congonhinhas", "value": "C13086"},
            {"label": "Conselheiro Mairinck", "value": "C13087"},
            {"label": "Contenda", "value": "C13088"},
            {"label": "Corbelia", "value": "C13089"},
            {"label": "Cornelio Procopio", "value": "C13090"},
            {"label": "Coronel Domingos Soares", "value": "C13091"},
            {"label": "Coronel Vivida", "value": "C13092"},
            {"label": "Corumbatai do Sul", "value": "C13093"},
            {"label": "Cruz Machado", "value": "C13094"},
            {"label": "Cruzeiro do Iguacu", "value": "C13095"},
            {"label": "Cruzeiro do Oeste", "value": "C13096"},
            {"label": "Cruzeiro do Sul", "value": "C13097"},
            {"label": "Cruzmaltina", "value": "C13098"},
            {"label": "Curitiba", "value": "C13099"},
            {"label": "Curiuva", "value": "C13100"},
            {"label": "Diamante dOeste", "value": "C13101"},
            {"label": "Diamante do Norte", "value": "C13102"},
            {"label": "Diamante do Sul", "value": "C13103"},
            {"label": "Dois Vizinhos", "value": "C13104"},
            {"label": "Douradina", "value": "C13105"},
            {"label": "Doutor Camargo", "value": "C13106"},
            {"label": "Doutor Ulysses", "value": "C13107"},
            {"label": "Eneas Marques", "value": "C13108"},
            {"label": "Engenheiro Beltrao", "value": "C13109"},
            {"label": "Entre Rios do Oeste", "value": "C13110"},
            {"label": "Esperanca Nova", "value": "C13111"},
            {"label": "Espigao Alto do Iguacu", "value": "C13112"},
            {"label": "Farol", "value": "C13113"},
            {"label": "Faxinal", "value": "C13114"},
            {"label": "Fazenda Rio Grande", "value": "C13115"},
            {"label": "Fenix", "value": "C13116"},
            {"label": "Fernandes Pinheiro", "value": "C13117"},
            {"label": "Figueira", "value": "C13118"},
            {"label": "Flor da Serra do Sul", "value": "C13119"},
            {"label": "Florai", "value": "C13120"},
            {"label": "Floresta", "value": "C13121"},
            {"label": "Florestopolis", "value": "C13122"},
            {"label": "Florida", "value": "C13123"},
            {"label": "Formosa do Oeste", "value": "C13124"},
            {"label": "Foz do Iguacu", "value": "C13125"},
            {"label": "Foz do Jordao", "value": "C13126"},
            {"label": "Francisco Alves", "value": "C13127"},
            {"label": "Francisco Beltrao", "value": "C13128"},
            {"label": "General Carneiro", "value": "C13129"},
            {"label": "Godoy Moreira", "value": "C13130"},
            {"label": "Goioere", "value": "C13131"},
            {"label": "Goioxim", "value": "C13132"},
            {"label": "Grandes Rios", "value": "C13133"},
            {"label": "Guaira", "value": "C13134"},
            {"label": "Guairaca", "value": "C13135"},
            {"label": "Guamiranga", "value": "C13136"},
            {"label": "Guapirama", "value": "C13137"},
            {"label": "Guaporema", "value": "C13138"},
            {"label": "Guaraci", "value": "C13139"},
            {"label": "Guaraniacu", "value": "C13140"},
            {"label": "Guarapuava", "value": "C13141"},
            {"label": "Guaraquecaba", "value": "C13142"},
            {"label": "Guaratuba", "value": "C13143"},
            {"label": "Honorio Serpa", "value": "C13144"},
            {"label": "Ibaiti", "value": "C13145"},
            {"label": "Ibema", "value": "C13146"},
            {"label": "Ibipora", "value": "C13147"},
            {"label": "Icaraima", "value": "C13148"},
            {"label": "Iguaracu", "value": "C13149"},
            {"label": "Iguatu", "value": "C13150"},
            {"label": "Imbau", "value": "C13151"},
            {"label": "Imbituva", "value": "C13152"},
            {"label": "Inacio Martins", "value": "C13153"},
            {"label": "Inaja", "value": "C13154"},
            {"label": "Indianopolis", "value": "C13155"},
            {"label": "Ipiranga", "value": "C13156"},
            {"label": "Ipora", "value": "C13157"},
            {"label": "Iracema do Oeste", "value": "C13158"},
            {"label": "Irati", "value": "C13159"},
            {"label": "Iretama", "value": "C13160"},
            {"label": "Itaguaje", "value": "C13161"},
            {"label": "Itaipulandia", "value": "C13162"},
            {"label": "Itambaraca", "value": "C13163"},
            {"label": "Itambe", "value": "C13164"},
            {"label": "Itapejara dOeste", "value": "C13165"},
            {"label": "Itaperucu", "value": "C13166"},
            {"label": "Itauna do Sul", "value": "C13167"},
            {"label": "Ivai", "value": "C13168"},
            {"label": "Ivaipora", "value": "C13169"},
            {"label": "Ivate", "value": "C13170"},
            {"label": "Ivatuba", "value": "C13171"},
            {"label": "Jaboti", "value": "C13172"},
            {"label": "Jacarezinho", "value": "C13173"},
            {"label": "Jaguapita", "value": "C13174"},
            {"label": "Jaguariaiva", "value": "C13175"},
            {"label": "Jandaia do Sul", "value": "C13176"},
            {"label": "Janiopolis", "value": "C13177"},
            {"label": "Japira", "value": "C13178"},
            {"label": "Japura", "value": "C13179"},
            {"label": "Jardim Alegre", "value": "C13180"},
            {"label": "Jardim Olinda", "value": "C13181"},
            {"label": "Jataizinho", "value": "C13182"},
            {"label": "Jesuitas", "value": "C13183"},
            {"label": "Joaquim Tavora", "value": "C13184"},
            {"label": "Jundiai do Sul", "value": "C13185"},
            {"label": "Juranda", "value": "C13186"},
            {"label": "Jussara", "value": "C13187"},
            {"label": "Kalore", "value": "C13188"},
            {"label": "Lapa", "value": "C13189"},
            {"label": "Laranjal", "value": "C13190"},
            {"label": "Laranjeiras do Sul", "value": "C13191"},
            {"label": "Leopolis", "value": "C13192"},
            {"label": "Lidianopolis", "value": "C13193"},
            {"label": "Lindoeste", "value": "C13194"},
            {"label": "Loanda", "value": "C13195"},
            {"label": "Lobato", "value": "C13196"},
            {"label": "Londrina", "value": "C13197"},
            {"label": "Luiziana", "value": "C13198"},
            {"label": "Lunardelli", "value": "C13199"},
            {"label": "Lupionopolis", "value": "C13200"},
            {"label": "Mallet", "value": "C13201"},
            {"label": "Mambore", "value": "C13202"},
            {"label": "Mandaguacu", "value": "C13203"},
            {"label": "Mandaguari", "value": "C13204"},
            {"label": "Mandirituba", "value": "C13205"},
            {"label": "Manfrinopolis", "value": "C13206"},
            {"label": "Mangueirinha", "value": "C13207"},
            {"label": "Manoel Ribas", "value": "C13208"},
            {"label": "Marechal Candido Rondon", "value": "C13209"},
            {"label": "Maria Helena", "value": "C13210"},
            {"label": "Marialva", "value": "C13211"},
            {"label": "Marilandia do Sul", "value": "C13212"},
            {"label": "Marilena", "value": "C13213"},
            {"label": "Mariluz", "value": "C13214"},
            {"label": "Maringa", "value": "C13215"},
            {"label": "Mariopolis", "value": "C13216"},
            {"label": "Maripa", "value": "C13217"},
            {"label": "Marmeleiro", "value": "C13218"},
            {"label": "Marquinho", "value": "C13219"},
            {"label": "Marumbi", "value": "C13220"},
            {"label": "Matelandia", "value": "C13221"},
            {"label": "Matinhos", "value": "C13222"},
            {"label": "Mato Rico", "value": "C13223"},
            {"label": "Maua da Serra", "value": "C13224"},
            {"label": "Medianeira", "value": "C13225"},
            {"label": "Mercedes", "value": "C13226"},
            {"label": "Mirador", "value": "C13227"},
            {"label": "Miraselva", "value": "C13228"},
            {"label": "Missal", "value": "C13229"},
            {"label": "Moreira Sales", "value": "C13230"},
            {"label": "Morretes", "value": "C13231"},
            {"label": "Munhoz de Melo", "value": "C13232"},
            {"label": "Nossa Senhora das Gracas", "value": "C13233"},
            {"label": "Nova Alianca do Ivai", "value": "C13234"},
            {"label": "Nova America da Colina", "value": "C13235"},
            {"label": "Nova Aurora", "value": "C13236"},
            {"label": "Nova Cantu", "value": "C13237"},
            {"label": "Nova Esperanca", "value": "C13238"},
            {"label": "Nova Esperanca do Sudoeste", "value": "C13239"},
            {"label": "Nova Fatima", "value": "C13240"},
            {"label": "Nova Laranjeiras", "value": "C13241"},
            {"label": "Nova Londrina", "value": "C13242"},
            {"label": "Nova Olimpia", "value": "C13243"},
            {"label": "Nova Prata do Iguacu", "value": "C13244"},
            {"label": "Nova Santa Barbara", "value": "C13245"},
            {"label": "Nova Santa Rosa", "value": "C13246"},
            {"label": "Nova Tebas", "value": "C13247"},
            {"label": "Novo Itacolomi", "value": "C13248"},
            {"label": "Ortigueira", "value": "C13249"},
            {"label": "Ourizona", "value": "C13250"},
            {"label": "Ouro Verde do Oeste", "value": "C13251"},
            {"label": "Paicandu", "value": "C13252"},
            {"label": "Palmas", "value": "C13253"},
            {"label": "Palmeira", "value": "C13254"},
            {"label": "Palmital", "value": "C13255"},
            {"label": "Palotina", "value": "C13256"},
            {"label": "Paraiso do Norte", "value": "C13257"},
            {"label": "Paranacity", "value": "C13258"},
            {"label": "Paranagua", "value": "C13259"},
            {"label": "Paranapoema", "value": "C13260"},
            {"label": "Paranavai", "value": "C13261"},
            {"label": "Pato Bragado", "value": "C13262"},
            {"label": "Pato Branco", "value": "C13263"},
            {"label": "Paula Freitas", "value": "C13264"},
            {"label": "Paulo Frontin", "value": "C13265"},
            {"label": "Peabiru", "value": "C13266"},
            {"label": "Perobal", "value": "C13267"},
            {"label": "Perola", "value": "C13268"},
            {"label": "Perola dOeste", "value": "C13269"},
            {"label": "Pien", "value": "C13270"},
            {"label": "Pinhais", "value": "C13271"},
            {"label": "Pinhal de Sao Bento", "value": "C13272"},
            {"label": "Pinhalao", "value": "C13273"},
            {"label": "Pinhao", "value": "C13274"},
            {"label": "Pirai do Sul", "value": "C13275"},
            {"label": "Piraquara", "value": "C13276"},
            {"label": "Pitanga", "value": "C13277"},
            {"label": "Pitangueiras", "value": "C13278"},
            {"label": "Planaltina do Parana", "value": "C13279"},
            {"label": "Planalto", "value": "C13280"},
            {"label": "Ponta Grossa", "value": "C13281"},
            {"label": "Pontal do Parana", "value": "C13282"},
            {"label": "Porecatu", "value": "C13283"},
            {"label": "Porto Amazonas", "value": "C13284"},
            {"label": "Porto Barreiro", "value": "C13285"},
            {"label": "Porto Rico", "value": "C13286"},
            {"label": "Porto Vitoria", "value": "C13287"},
            {"label": "Prado Ferreira", "value": "C13288"},
            {"label": "Pranchita", "value": "C13289"},
            {"label": "Presidente Castelo Branco", "value": "C13290"},
            {"label": "Primeiro de Maio", "value": "C13291"},
            {"label": "Prudentopolis", "value": "C13292"},
            {"label": "Quarto Centenario", "value": "C13293"},
            {"label": "Quatigua", "value": "C13294"},
            {"label": "Quatro Barras", "value": "C13295"},
            {"label": "Quatro Pontes", "value": "C13296"},
            {"label": "Quedas do Iguacu", "value": "C13297"},
            {"label": "Querencia do Norte", "value": "C13298"},
            {"label": "Quinta do Sol", "value": "C13299"},
            {"label": "Quitandinha", "value": "C13300"},
            {"label": "Ramilandia", "value": "C13301"},
            {"label": "Rancho Alegre", "value": "C13302"},
            {"label": "Rancho Alegre dOeste", "value": "C13303"},
            {"label": "Realeza", "value": "C13304"},
            {"label": "Reboucas", "value": "C13305"},
            {"label": "Renascenca", "value": "C13306"},
            {"label": "Reserva", "value": "C13307"},
            {"label": "Reserva do Iguacu", "value": "C13308"},
            {"label": "Ribeirao Claro", "value": "C13309"},
            {"label": "Ribeirao do Pinhal", "value": "C13310"},
            {"label": "Rio Azul", "value": "C13311"},
            {"label": "Rio Bom", "value": "C13312"},
            {"label": "Rio Bonito do Iguacu", "value": "C13313"},
            {"label": "Rio Branco do Ivai", "value": "C13314"},
            {"label": "Rio Branco do Sul", "value": "C13315"},
            {"label": "Rio Negro", "value": "C13316"},
            {"label": "Rolandia", "value": "C13317"},
            {"label": "Roncador", "value": "C13318"},
            {"label": "Rondon", "value": "C13319"},
            {"label": "Rosario do Ivai", "value": "C13320"},
            {"label": "Sabaudia", "value": "C13321"},
            {"label": "Salgado Filho", "value": "C13322"},
            {"label": "Salto do Itarare", "value": "C13323"},
            {"label": "Salto do Lontra", "value": "C13324"},
            {"label": "Santa Amelia", "value": "C13325"},
            {"label": "Santa Cecilia do Pavao", "value": "C13326"},
            {"label": "Santa Cruz de Monte Castelo", "value": "C13327"},
            {"label": "Santa Fe", "value": "C13328"},
            {"label": "Santa Helena", "value": "C13329"},
            {"label": "Santa Ines", "value": "C13330"},
            {"label": "Santa Isabel do Ivai", "value": "C13331"},
            {"label": "Santa Izabel do Oeste", "value": "C13332"},
            {"label": "Santa Lucia", "value": "C13333"},
            {"label": "Santa Maria do Oeste", "value": "C13334"},
            {"label": "Santa Mariana", "value": "C13335"},
            {"label": "Santa Monica", "value": "C13336"},
            {"label": "Santa Tereza do Oeste", "value": "C13337"},
            {"label": "Santa Terezinha de Itaipu", "value": "C13338"},
            {"label": "Santana do Itarare", "value": "C13339"},
            {"label": "Santo Antonio da Platina", "value": "C13340"},
            {"label": "Santo Antonio do Caiua", "value": "C13341"},
            {"label": "Santo Antonio do Paraiso", "value": "C13342"},
            {"label": "Santo Antonio do Sudoeste", "value": "C13343"},
            {"label": "Santo Inacio", "value": "C13344"},
            {"label": "Sao Carlos do Ivai", "value": "C13345"},
            {"label": "Sao Jeronimo da Serra", "value": "C13346"},
            {"label": "Sao Joao", "value": "C13347"},
            {"label": "Sao Joao do Caiua", "value": "C13348"},
            {"label": "Sao Joao do Ivai", "value": "C13349"},
            {"label": "Sao Joao do Triunfo", "value": "C13350"},
            {"label": "Sao Jorge dOeste", "value": "C13351"},
            {"label": "Sao Jorge do Ivai", "value": "C13352"},
            {"label": "Sao Jorge do Patrocinio", "value": "C13353"},
            {"label": "Sao Jose da Boa Vista", "value": "C13354"},
            {"label": "Sao Jose das Palmeiras", "value": "C13355"},
            {"label": "Sao Jose dos Pinhais", "value": "C13356"},
            {"label": "Sao Manoel do Parana", "value": "C13357"},
            {"label": "Sao Mateus do Sul", "value": "C13358"},
            {"label": "Sao Miguel do Iguacu", "value": "C13359"},
            {"label": "Sao Pedro do Iguacu", "value": "C13360"},
            {"label": "Sao Pedro do Ivai", "value": "C13361"},
            {"label": "Sao Pedro do Parana", "value": "C13362"},
            {"label": "Sao Sebastiao da Amoreira", "value": "C13363"},
            {"label": "Sao Tome", "value": "C13364"},
            {"label": "Sapopema", "value": "C13365"},
            {"label": "Sarandi", "value": "C13366"},
            {"label": "Saudade do Iguacu", "value": "C13367"},
            {"label": "Senges", "value": "C13368"},
            {"label": "Serranopolis do Iguacu", "value": "C13369"},
            {"label": "Sertaneja", "value": "C13370"},
            {"label": "Sertanopolis", "value": "C13371"},
            {"label": "Siqueira Campos", "value": "C13372"},
            {"label": "Sulina", "value": "C13373"},
            {"label": "Tamarana", "value": "C13374"},
            {"label": "Tamboara", "value": "C13375"},
            {"label": "Tapejara", "value": "C13376"},
            {"label": "Tapira", "value": "C13377"},
            {"label": "Teixeira Soares", "value": "C13378"},
            {"label": "Telemaco Borba", "value": "C13379"},
            {"label": "Terra Boa", "value": "C13380"},
            {"label": "Terra Rica", "value": "C13381"},
            {"label": "Terra Roxa", "value": "C13382"},
            {"label": "Tibagi", "value": "C13383"},
            {"label": "Tijucas do Sul", "value": "C13384"},
            {"label": "Toledo", "value": "C13385"},
            {"label": "Tomazina", "value": "C13386"},
            {"label": "Tres Barras do Parana", "value": "C13387"},
            {"label": "Tunas do Parana", "value": "C13388"},
            {"label": "Tuneiras do Oeste", "value": "C13389"},
            {"label": "Tupassi", "value": "C13390"},
            {"label": "Turvo", "value": "C13391"},
            {"label": "Ubirata", "value": "C13392"},
            {"label": "Umuarama", "value": "C13393"},
            {"label": "Uniao da Vitoria", "value": "C13394"},
            {"label": "Uniflor", "value": "C13395"},
            {"label": "Urai", "value": "C13396"},
            {"label": "Ventania", "value": "C13397"},
            {"label": "Vera Cruz do Oeste", "value": "C13398"},
            {"label": "Vere", "value": "C13399"},
            {"label": "Virmond", "value": "C13400"},
            {"label": "Vitorino", "value": "C13401"},
            {"label": "Wenceslau Braz", "value": "C13402"},
            {"label": "Xambre", "value": "C13403"}],
 "Pardubicky kraj": [{"label": "Brandys nad Orlici", "value": "C22738"},
                     {"label": "Brezova nad Svitavou", "value": "C22739"},
                     {"label": "Brnenec", "value": "C22740"},
                     {"label": "Byst", "value": "C22741"},
                     {"label": "Bystre", "value": "C22742"},
                     {"label": "Bystrec", "value": "C22743"},
                     {"label": "Cervena Voda", "value": "C22744"},
                     {"label": "Ceska Trebova", "value": "C22745"},
                     {"label": "Chocen", "value": "C22746"},
                     {"label": "Chrast", "value": "C22747"},
                     {"label": "Chroustovice", "value": "C22748"},
                     {"label": "Chrudim", "value": "C22749"},
                     {"label": "Chvaletice", "value": "C22750"},
                     {"label": "Dasice", "value": "C22751"},
                     {"label": "Dlouha Trebova", "value": "C22752"},
                     {"label": "Dolni Cermna", "value": "C22753"},
                     {"label": "Dolni Dobrouc", "value": "C22754"},
                     {"label": "Dolni Roven", "value": "C22755"},
                     {"label": "Dolni Sloupnice", "value": "C22756"},
                     {"label": "Dolni Ujezd", "value": "C22757"},
                     {"label": "Hermanuv Mestec", "value": "C22758"},
                     {"label": "Hlinsko", "value": "C22759"},
                     {"label": "Holice", "value": "C22760"},
                     {"label": "Horni Cermna", "value": "C22761"},
                     {"label": "Horni Jeleni", "value": "C22762"},
                     {"label": "Horni Sloupnice", "value": "C22763"},
                     {"label": "Hradec nad Svitavou", "value": "C22764"},
                     {"label": "Hrochuv Tynec", "value": "C22765"},
                     {"label": "Jablonne nad Orlici", "value": "C22766"},
                     {"label": "Jaromerice", "value": "C22767"},
                     {"label": "Jedlova", "value": "C22768"},
                     {"label": "Jevicko", "value": "C22769"},
                     {"label": "Kraliky", "value": "C22770"},
                     {"label": "Krouna", "value": "C22771"},
                     {"label": "Kuncina", "value": "C22772"},
                     {"label": "Kunvald", "value": "C22773"},
                     {"label": "Lanskroun", "value": "C22774"},
                     {"label": "Lazne Bohdanec", "value": "C22775"},
                     {"label": "Letohrad", "value": "C22776"},
                     {"label": "Litomysl", "value": "C22777"},
                     {"label": "Lukavice", "value": "C22778"},
                     {"label": "Luze", "value": "C22779"},
                     {"label": "Mestecko Trnavka", "value": "C22780"},
                     {"label": "Miretice", "value": "C22781"},
                     {"label": "Moravany", "value": "C22782"},
                     {"label": "Moravska Trebova", "value": "C22783"},
                     {"label": "Nasavrky", "value": "C22784"},
                     {"label": "Okres Chrudim", "value": "C22785"},
                     {"label": "Okres Pardubice", "value": "C22786"},
                     {"label": "Okres Svitavy", "value": "C22787"},
                     {"label": "Okres Usti nad Orlici", "value": "C22788"},
                     {"label": "Opatov", "value": "C22789"},
                     {"label": "Opatovice nad Labem", "value": "C22790"},
                     {"label": "Osik", "value": "C22791"},
                     {"label": "Pardubice", "value": "C22792"},
                     {"label": "Policka", "value": "C22793"},
                     {"label": "Pomezi", "value": "C22794"},
                     {"label": "Prachovice", "value": "C22795"},
                     {"label": "Prelouc", "value": "C22796"},
                     {"label": "Prosec", "value": "C22797"},
                     {"label": "Radimer", "value": "C22798"},
                     {"label": "Recany nad Labem", "value": "C22799"},
                     {"label": "Ronov nad Doubravou", "value": "C22800"},
                     {"label": "Rosice", "value": "C22801"},
                     {"label": "Rybitvi", "value": "C22802"},
                     {"label": "Sec", "value": "C22803"},
                     {"label": "Sezemice", "value": "C22804"},
                     {"label": "Skutec", "value": "C22805"},
                     {"label": "Slatinany", "value": "C22806"},
                     {"label": "Stare Hradiste", "value": "C22807"},
                     {"label": "Svitavy", "value": "C22808"},
                     {"label": "Tremosnice", "value": "C22809"},
                     {"label": "Usti nad Orlici", "value": "C22810"},
                     {"label": "Vysoke Myto", "value": "C22811"},
                     {"label": "Zamberk", "value": "C22812"}],
 "Pargauja Municipality": [{"label": "Stalbe", "value": "C64910"}],
 "Parnu County": [{"label": "Audru", "value": "C24588"},
                  {"label": "Kihnu vald", "value": "C24589"},
                  {"label": "Kilingi-Nomme", "value": "C24590"},
                  {"label": "Lihula", "value": "C24591"},
                  {"label": "Linakula", "value": "C24592"},
                  {"label": "Paikuse", "value": "C24593"},
                  {"label": "Parnu", "value": "C24594"},
                  {"label": "Parnu linn", "value": "C24595"},
                  {"label": "Parnu-Jaagupi", "value": "C24596"},
                  {"label": "Saarde vald", "value": "C24597"},
                  {"label": "Sauga", "value": "C24598"},
                  {"label": "Sindi", "value": "C24599"},
                  {"label": "Tootsi", "value": "C24600"},
                  {"label": "Tori vald", "value": "C24601"},
                  {"label": "Uulu", "value": "C24602"},
                  {"label": "Vandra", "value": "C24603"}],
 "Paro District": [{"label": "Paro", "value": "C9640"}],
 "Parwan": [{"label": "Charikar", "value": "C78"},
            {"label": "Jabal os Saraj", "value": "C79"}],
 "Pasco": [{"label": "Cerro de Pasco", "value": "C80994"},
           {"label": "Chaupimarca", "value": "C80995"},
           {"label": "Oxapampa", "value": "C80996"},
           {"label": "Paucartambo", "value": "C80997"},
           {"label": "Provincia de Daniel Carrion", "value": "C80998"},
           {"label": "Provincia de Oxapampa", "value": "C80999"},
           {"label": "Provincia de Pasco", "value": "C81000"},
           {"label": "Tinyahuarco", "value": "C81001"},
           {"label": "Villa Rica", "value": "C81002"}],
 "Pastaza": [{"label": "Puyo", "value": "C24190"}],
 "Pathum Thani": [{"label": "Amphoe Khlong Luang", "value": "C119000"},
                  {"label": "Amphoe Lam Luk Ka", "value": "C119001"},
                  {"label": "Amphoe Lat Lum Kaeo", "value": "C119002"},
                  {"label": "Amphoe Mueang Pathum Thani", "value": "C119003"},
                  {"label": "Amphoe Nong Suea", "value": "C119004"},
                  {"label": "Amphoe Thanyaburi", "value": "C119005"},
                  {"label": "Ban Lam Luk Ka", "value": "C119006"},
                  {"label": "Khlong Luang", "value": "C119007"},
                  {"label": "Pathum Thani", "value": "C119008"}],
 "Pattani": [{"label": "Amphoe Kapho", "value": "C119009"},
             {"label": "Amphoe Khok Pho", "value": "C119010"},
             {"label": "Amphoe Mae Lan", "value": "C119011"},
             {"label": "Amphoe Mai Kaen", "value": "C119012"},
             {"label": "Amphoe Mayo", "value": "C119013"},
             {"label": "Amphoe Mueang Pattani", "value": "C119014"},
             {"label": "Amphoe Nong Chik", "value": "C119015"},
             {"label": "Amphoe Panare", "value": "C119016"},
             {"label": "Amphoe Sai Buri", "value": "C119017"},
             {"label": "Amphoe Thung Yang Daeng", "value": "C119018"},
             {"label": "Amphoe Yarang", "value": "C119019"},
             {"label": "Amphoe Yaring", "value": "C119020"},
             {"label": "Nong Chik", "value": "C119021"},
             {"label": "Pattani", "value": "C119022"},
             {"label": "Sai Buri", "value": "C119023"},
             {"label": "Yaring", "value": "C119024"}],
 "Paul": [{"label": "Pombas", "value": "C17788"}],
 "Pavilosta Municipality": [{"label": "Pavilosta", "value": "C64911"}],
 "Pavlodar Region": [{"label": "Aksu", "value": "C64487"},
                     {"label": "Bayanaul", "value": "C64488"},
                     {"label": "Belogorye", "value": "C64489"},
                     {"label": "Ekibastuz", "value": "C64490"},
                     {"label": "Irtyshsk", "value": "C64491"},
                     {"label": "Kalkaman", "value": "C64492"},
                     {"label": "Leninskiy", "value": "C64493"},
                     {"label": "Mayqayyng", "value": "C64494"},
                     {"label": "Pavlodar", "value": "C64495"},
                     {"label": "Qashyr", "value": "C64496"},
                     {"label": "Zhelezinka", "value": "C64497"}],
 "Pays-de-la-Loire": [{"label": "Abbaretz", "value": "C32926"},
                      {"label": "Ahuille", "value": "C32927"},
                      {"label": "Aigne", "value": "C32928"},
                      {"label": "Aigrefeuille-sur-Maine", "value": "C32929"},
                      {"label": "Aizenay", "value": "C32930"},
                      {"label": "Allonnes", "value": "C32931"},
                      {"label": "Ambrieres-les-Vallees", "value": "C32932"},
                      {"label": "Ancenis", "value": "C32933"},
                      {"label": "Andard", "value": "C32934"},
                      {"label": "Andouille", "value": "C32935"},
                      {"label": "Andreze", "value": "C32936"},
                      {"label": "Anetz", "value": "C32937"},
                      {"label": "Angers", "value": "C32938"},
                      {"label": "Angles", "value": "C32939"},
                      {"label": "Antigny", "value": "C32940"},
                      {"label": "Apremont", "value": "C32941"},
                      {"label": "Arconnay", "value": "C32942"},
                      {"label": "Argentre", "value": "C32943"},
                      {"label": "Arnage", "value": "C32944"},
                      {"label": "Aron", "value": "C32945"},
                      {"label": "Arthon-en-Retz", "value": "C32946"},
                      {"label": "Asserac", "value": "C32947"},
                      {"label": "Aubigne-Racan", "value": "C32948"},
                      {"label": "Aubigny", "value": "C32949"},
                      {"label": "Auvers-le-Hamon", "value": "C32950"},
                      {"label": "Avessac", "value": "C32951"},
                      {"label": "Avrille", "value": "C32952"},
                      {"label": "Aze", "value": "C32953"},
                      {"label": "Bais", "value": "C32954"},
                      {"label": "Ballon", "value": "C32955"},
                      {"label": "Ballots", "value": "C32956"},
                      {"label": "Barbatre", "value": "C32957"},
                      {"label": "Barbechat", "value": "C32958"},
                      {"label": "Basse-Goulaine", "value": "C32959"},
                      {"label": "Batz-sur-Mer", "value": "C32960"},
                      {"label": "Bauge-en-Anjou", "value": "C32961"},
                      {"label": "Baune", "value": "C32962"},
                      {"label": "Bazoges-en-Pareds", "value": "C32963"},
                      {"label": "Bazouges-sur-le-Loir", "value": "C32964"},
                      {"label": "Beaucouze", "value": "C32965"},
                      {"label": "Beaufay", "value": "C32966"},
                      {"label": "Beaufort-en-Vallee", "value": "C32967"},
                      {"label": "Beaulieu-sous-la-Roche", "value": "C32968"},
                      {"label": "Beaulieu-sur-Layon", "value": "C32969"},
                      {"label": "Beaumont-sur-Sarthe", "value": "C32970"},
                      {"label": "Beaupreau", "value": "C32971"},
                      {"label": "Beaurepaire", "value": "C32972"},
                      {"label": "Beauvoir-sur-Mer", "value": "C32973"},
                      {"label": "Becon-les-Granits", "value": "C32974"},
                      {"label": "Begrolles-en-Mauges", "value": "C32975"},
                      {"label": "Belleville-sur-Vie", "value": "C32976"},
                      {"label": "Belligne", "value": "C32977"},
                      {"label": "Benet", "value": "C32978"},
                      {"label": "Besne", "value": "C32979"},
                      {"label": "Besse-sur-Braye", "value": "C32980"},
                      {"label": "Blain", "value": "C32981"},
                      {"label": "Blaison-Gohier", "value": "C32982"},
                      {"label": "Bois-de-Cene", "value": "C32983"},
                      {"label": "Bonchamp-les-Laval", "value": "C32984"},
                      {"label": "Bonnetable", "value": "C32985"},
                      {"label": "Bouaye", "value": "C32986"},
                      {"label": "Bouchemaine", "value": "C32987"},
                      {"label": "Bouffere", "value": "C32988"},
                      {"label": "Bouguenais", "value": "C32989"},
                      {"label": "Bouin", "value": "C32990"},
                      {"label": "Bouloire", "value": "C32991"},
                      {"label": "Bourg de Joue-sur-Erdre", "value": "C32992"},
                      {"label": "Bourgneuf-en-Retz", "value": "C32993"},
                      {"label": "Bournezeau", "value": "C32994"},
                      {"label": "Boussay", "value": "C32995"},
                      {"label": "Bouvron", "value": "C32996"},
                      {"label": "Bouzille", "value": "C32997"},
                      {"label": "Brain-sur-Allonnes", "value": "C32998"},
                      {"label": "Brains", "value": "C32999"},
                      {"label": "Bretignolles-sur-Mer", "value": "C33000"},
                      {"label": "Brette-les-Pins", "value": "C33001"},
                      {"label": "Breze", "value": "C33002"},
                      {"label": "Briollay", "value": "C33003"},
                      {"label": "Brion", "value": "C33004"},
                      {"label": "Brissac-Quince", "value": "C33005"},
                      {"label": "Brulon", "value": "C33006"},
                      {"label": "Campbon", "value": "C33007"},
                      {"label": "Cande", "value": "C33008"},
                      {"label": "Cantenay-epinard", "value": "C33009"},
                      {"label": "Carquefou", "value": "C33010"},
                      {"label": "Casson", "value": "C33011"},
                      {"label": "Cerans-Foulletourte", "value": "C33012"},
                      {"label": "Chace", "value": "C33013"},
                      {"label": "Chailland", "value": "C33014"},
                      {"label": "Chaille-les-Marais", "value": "C33015"},
                      {"label": "Challans", "value": "C33016"},
                      {"label": "Challes", "value": "C33017"},
                      {"label": "Chalonnes-sur-Loire", "value": "C33018"},
                      {"label": "Chambretaud", "value": "C33019"},
                      {"label": "Champagne", "value": "C33020"},
                      {"label": "Champagne-les-Marais", "value": "C33021"},
                      {"label": "Champfleur", "value": "C33022"},
                      {"label": "Champigne", "value": "C33023"},
                      {"label": "Champtoce-sur-Loire", "value": "C33024"},
                      {"label": "Champtoceaux", "value": "C33025"},
                      {"label": "Change", "value": "C33026"},
                      {"label": "Chantonnay", "value": "C33027"},
                      {"label": "Chateau-dOlonne", "value": "C33028"},
                      {"label": "Chateau-du-Loir", "value": "C33029"},
                      {"label": "Chateau-Gontier", "value": "C33030"},
                      {"label": "Chateau-Guibert", "value": "C33031"},
                      {"label": "Chateaubriant", "value": "C33032"},
                      {"label": "Chateauneuf-sur-Sarthe", "value": "C33033"},
                      {"label": "Chauche", "value": "C33034"},
                      {"label": "Chaudron-en-Mauges", "value": "C33035"},
                      {"label": "Chauve", "value": "C33036"},
                      {"label": "Chavagnes-en-Paillers", "value": "C33037"},
                      {"label": "Chemaze", "value": "C33038"},
                      {"label": "Chemere", "value": "C33039"},
                      {"label": "Chemille-Melay", "value": "C33040"},
                      {"label": "Chenehutte-Treves-Cunault", "value": "C33041"},
                      {"label": "Cherre", "value": "C33042"},
                      {"label": "Cholet", "value": "C33043"},
                      {"label": "Clermont-Creans", "value": "C33044"},
                      {"label": "Clisson", "value": "C33045"},
                      {"label": "Coex", "value": "C33046"},
                      {"label": "Combree", "value": "C33047"},
                      {"label": "Commequiers", "value": "C33048"},
                      {"label": "Commer", "value": "C33049"},
                      {"label": "Congrier", "value": "C33050"},
                      {"label": "Conlie", "value": "C33051"},
                      {"label": "Connerre", "value": "C33052"},
                      {"label": "Conquereuil", "value": "C33053"},
                      {"label": "Corcoue-sur-Logne", "value": "C33054"},
                      {"label": "Cordemais", "value": "C33055"},
                      {"label": "Corne", "value": "C33056"},
                      {"label": "Coron", "value": "C33057"},
                      {"label": "Corsept", "value": "C33058"},
                      {"label": "Corze", "value": "C33059"},
                      {"label": "Cosse-le-Vivien", "value": "C33060"},
                      {"label": "Coueron", "value": "C33061"},
                      {"label": "Couffe", "value": "C33062"},
                      {"label": "Coulaines", "value": "C33063"},
                      {"label": "Coulans-sur-Gee", "value": "C33064"},
                      {"label": "Courcite", "value": "C33065"},
                      {"label": "Craon", "value": "C33066"},
                      {"label": "Crossac", "value": "C33067"},
                      {"label": "Cugand", "value": "C33068"},
                      {"label": "Daumeray", "value": "C33069"},
                      {"label": "Denee", "value": "C33070"},
                      {"label": "Departement de la Vendee", "value": "C33071"},
                      {"label": "Departement de Maine-et-Loire",
                       "value": "C33072"},
                      {"label": "Derval", "value": "C33073"},
                      {"label": "Distre", "value": "C33074"},
                      {"label": "Dollon", "value": "C33075"},
                      {"label": "Dompierre-sur-Yon", "value": "C33076"},
                      {"label": "Donges", "value": "C33077"},
                      {"label": "Doue-la-Fontaine", "value": "C33078"},
                      {"label": "Drain", "value": "C33079"},
                      {"label": "Dreffeac", "value": "C33080"},
                      {"label": "Durtal", "value": "C33081"},
                      {"label": "ecommoy", "value": "C33082"},
                      {"label": "ecouflant", "value": "C33083"},
                      {"label": "Entrammes", "value": "C33084"},
                      {"label": "Erbray", "value": "C33085"},
                      {"label": "Ernee", "value": "C33086"},
                      {"label": "etival-les-le-Mans", "value": "C33087"},
                      {"label": "etriche", "value": "C33088"},
                      {"label": "evron", "value": "C33089"},
                      {"label": "Falleron", "value": "C33090"},
                      {"label": "Fay-de-Bretagne", "value": "C33091"},
                      {"label": "Fegreac", "value": "C33092"},
                      {"label": "Feneu", "value": "C33093"},
                      {"label": "Fille", "value": "C33094"},
                      {"label": "Fontenay-le-Comte", "value": "C33095"},
                      {"label": "Fontevraud-lAbbaye", "value": "C33096"},
                      {"label": "Fougerolles-du-Plessis", "value": "C33097"},
                      {"label": "Foussais-Payre", "value": "C33098"},
                      {"label": "Freigne", "value": "C33099"},
                      {"label": "Fresnay-sur-Sarthe", "value": "C33100"},
                      {"label": "Froidfond", "value": "C33101"},
                      {"label": "Frossay", "value": "C33102"},
                      {"label": "Geneston", "value": "C33103"},
                      {"label": "Gennes", "value": "C33104"},
                      {"label": "Geste", "value": "C33105"},
                      {"label": "Getigne", "value": "C33106"},
                      {"label": "Givrand", "value": "C33107"},
                      {"label": "Gorges", "value": "C33108"},
                      {"label": "Gorron", "value": "C33109"},
                      {"label": "Grez-Neuville", "value": "C33110"},
                      {"label": "Grosbreuil", "value": "C33111"},
                      {"label": "Guecelard", "value": "C33112"},
                      {"label": "Guemene-Penfao", "value": "C33113"},
                      {"label": "Guenrouet", "value": "C33114"},
                      {"label": "Guerande", "value": "C33115"},
                      {"label": "Haute-Goulaine", "value": "C33116"},
                      {"label": "Herbignac", "value": "C33117"},
                      {"label": "Heric", "value": "C33118"},
                      {"label": "Indre", "value": "C33119"},
                      {"label": "Ingrandes", "value": "C33120"},
                      {"label": "Isse", "value": "C33121"},
                      {"label": "Jallais", "value": "C33122"},
                      {"label": "Jans", "value": "C33123"},
                      {"label": "Jard-sur-Mer", "value": "C33124"},
                      {"label": "Jarze", "value": "C33125"},
                      {"label": "Javron-les-Chapelles", "value": "C33126"},
                      {"label": "Juigne", "value": "C33127"},
                      {"label": "Juigne-sur-Loire", "value": "C33128"},
                      {"label": "Juigne-sur-Sarthe", "value": "C33129"},
                      {"label": "Jumelles", "value": "C33130"},
                      {"label": "Juvigne", "value": "C33131"},
                      {"label": "La Baconniere", "value": "C33132"},
                      {"label": "La Barre-de-Monts", "value": "C33133"},
                      {"label": "La Baule-Escoublac", "value": "C33134"},
                      {"label": "La Bazoge", "value": "C33135"},
                      {"label": "La Bernardiere", "value": "C33136"},
                      {"label": "La Bernerie-en-Retz", "value": "C33137"},
                      {"label": "La Bohalle", "value": "C33138"},
                      {"label": "La Boissiere-de-Montaigu", "value": "C33139"},
                      {"label": "La Boissiere-des-Landes", "value": "C33140"},
                      {"label": "La Bruffiere", "value": "C33141"},
                      {"label": "La Chaize-le-Vicomte", "value": "C33142"},
                      {"label": "La Chapelle-Achard", "value": "C33143"},
                      {"label": "La Chapelle-Basse-Mer", "value": "C33144"},
                      {"label": "La Chapelle-des-Marais", "value": "C33145"},
                      {"label": "La Chapelle-du-Genet", "value": "C33146"},
                      {"label": "La Chapelle-Heulin", "value": "C33147"},
                      {"label": "La Chapelle-Launay", "value": "C33148"},
                      {"label": "La Chapelle-Saint-Aubin", "value": "C33149"},
                      {"label": "La Chapelle-Saint-Florent", "value": "C33150"},
                      {"label": "La Chapelle-sur-Erdre", "value": "C33151"},
                      {"label": "La Chartre-sur-le-Loir", "value": "C33152"},
                      {"label": "La Chataigneraie", "value": "C33153"},
                      {"label": "La Chevroliere", "value": "C33154"},
                      {"label": "La Dagueniere", "value": "C33155"},
                      {"label": "La Ferriere", "value": "C33156"},
                      {"label": "La Ferte-Bernard", "value": "C33157"},
                      {"label": "La Fleche", "value": "C33158"},
                      {"label": "La Flocelliere", "value": "C33159"},
                      {"label": "La Garnache", "value": "C33160"},
                      {"label": "La Gaubretiere", "value": "C33161"},
                      {"label": "La Genetouze", "value": "C33162"},
                      {"label": "La Grigonnais", "value": "C33163"},
                      {"label": "La Gueriniere", "value": "C33164"},
                      {"label": "La Guyonniere", "value": "C33165"},
                      {"label": "La Haie-Fouassiere", "value": "C33166"},
                      {"label": "La Jubaudiere", "value": "C33167"},
                      {"label": "La Jumelliere", "value": "C33168"},
                      {"label": "La Limouziniere", "value": "C33169"},
                      {"label": "La Meignanne", "value": "C33170"},
                      {"label": "La Meilleraie-Tillay", "value": "C33171"},
                      {"label": "La Meilleraye-de-Bretagne", "value": "C33172"},
                      {"label": "La Membrolle-sur-Longuenee",
                       "value": "C33173"},
                      {"label": "La Menitre", "value": "C33174"},
                      {"label": "La Milesse", "value": "C33175"},
                      {"label": "La Montagne", "value": "C33176"},
                      {"label": "La Mothe-Achard", "value": "C33177"},
                      {"label": "La Plaine-sur-Mer", "value": "C33178"},
                      {"label": "La Planche", "value": "C33179"},
                      {"label": "La Pommeraie-sur-Sevre", "value": "C33180"},
                      {"label": "La Pommeraye", "value": "C33181"},
                      {"label": "La Possonniere", "value": "C33182"},
                      {"label": "La Poueze", "value": "C33183"},
                      {"label": "La Regrippiere", "value": "C33184"},
                      {"label": "La Remaudiere", "value": "C33185"},
                      {"label": "La Roche-sur-Yon", "value": "C33186"},
                      {"label": "La Romagne", "value": "C33187"},
                      {"label": "La Salle-et-Chapelle-Aubry",
                       "value": "C33188"},
                      {"label": "La Seguiniere", "value": "C33189"},
                      {"label": "La Suze-sur-Sarthe", "value": "C33190"},
                      {"label": "La Tardiere", "value": "C33191"},
                      {"label": "La Tessoualle", "value": "C33192"},
                      {"label": "La Tourlandry", "value": "C33193"},
                      {"label": "La Tranche-sur-Mer", "value": "C33194"},
                      {"label": "La Turballe", "value": "C33195"},
                      {"label": "La Varenne", "value": "C33196"},
                      {"label": "La Verrie", "value": "C33197"},
                      {"label": "Laigne-en-Belin", "value": "C33198"},
                      {"label": "Landemont", "value": "C33199"},
                      {"label": "Landeronde", "value": "C33200"},
                      {"label": "Landivy", "value": "C33201"},
                      {"label": "Larchamp", "value": "C33202"},
                      {"label": "Lassay-les-Chateaux", "value": "C33203"},
                      {"label": "Laval", "value": "C33204"},
                      {"label": "Le Bignon", "value": "C33205"},
                      {"label": "Le Boupere", "value": "C33206"},
                      {"label": "Le Bourgneuf-la-Foret", "value": "C33207"},
                      {"label": "Le Breil-sur-Merize", "value": "C33208"},
                      {"label": "Le Cellier", "value": "C33209"},
                      {"label": "Le Champ-Saint-Pere", "value": "C33210"},
                      {"label": "Le Croisic", "value": "C33211"},
                      {"label": "Le Fenouiller", "value": "C33212"},
                      {"label": "Le Fief-Sauvin", "value": "C33213"},
                      {"label": "Le Fuilet", "value": "C33214"},
                      {"label": "Le Gavre", "value": "C33215"},
                      {"label": "Le Grand-Luce", "value": "C33216"},
                      {"label": "Le Landreau", "value": "C33217"},
                      {"label": "Le Langon", "value": "C33218"},
                      {"label": "Le Longeron", "value": "C33219"},
                      {"label": "Le Loroux-Bottereau", "value": "C33220"},
                      {"label": "Le Louroux-Beconnais", "value": "C33221"},
                      {"label": "Le Luart", "value": "C33222"},
                      {"label": "Le Lude", "value": "C33223"},
                      {"label": "Le Mans", "value": "C33224"},
                      {"label": "Le May-sur-Evre", "value": "C33225"},
                      {"label": "Le Mesnil-en-Vallee", "value": "C33226"},
                      {"label": "Le Pallet", "value": "C33227"},
                      {"label": "Le Pellerin", "value": "C33228"},
                      {"label": "Le Perrier", "value": "C33229"},
                      {"label": "Le Pin-en-Mauges", "value": "C33230"},
                      {"label": "Le Plessis-Grammoire", "value": "C33231"},
                      {"label": "Le Plessis-Mace", "value": "C33232"},
                      {"label": "Le Poire-sur-Vie", "value": "C33233"},
                      {"label": "Le Pouliguen", "value": "C33234"},
                      {"label": "Le Puy-Notre-Dame", "value": "C33235"},
                      {"label": "Le Temple-de-Bretagne", "value": "C33236"},
                      {"label": "Le Vieil-Bauge", "value": "C33237"},
                      {"label": "Lege", "value": "C33238"},
                      {"label": "Les Brouzils", "value": "C33239"},
                      {"label": "Les Clouzeaux", "value": "C33240"},
                      {"label": "Les Epesses", "value": "C33241"},
                      {"label": "Les Essarts", "value": "C33242"},
                      {"label": "Les Herbiers", "value": "C33243"},
                      {"label": "Les Landes-Genusson", "value": "C33244"},
                      {"label": "Les Lucs-sur-Boulogne", "value": "C33245"},
                      {"label": "Les Magnils-Reigniers", "value": "C33246"},
                      {"label": "Les Moutiers-en-Retz", "value": "C33247"},
                      {"label": "Les Ponts-de-Ce", "value": "C33248"},
                      {"label": "Les Rosiers-sur-Loire", "value": "C33249"},
                      {"label": "Les Sables-dOlonne", "value": "C33250"},
                      {"label": "Les Sorinieres", "value": "C33251"},
                      {"label": "Les Touches", "value": "C33252"},
                      {"label": "Ligne", "value": "C33253"},
                      {"label": "Lire", "value": "C33254"},
                      {"label": "Loire-Atlantique", "value": "C33255"},
                      {"label": "Loiron", "value": "C33256"},
                      {"label": "Lombron", "value": "C33257"},
                      {"label": "Longeville-sur-Mer", "value": "C33258"},
                      {"label": "Loue", "value": "C33259"},
                      {"label": "Louplande", "value": "C33260"},
                      {"label": "Louverne", "value": "C33261"},
                      {"label": "Luceau", "value": "C33262"},
                      {"label": "Luche-Pringe", "value": "C33263"},
                      {"label": "Lucon", "value": "C33264"},
                      {"label": "Lusanger", "value": "C33265"},
                      {"label": "Mache", "value": "C33266"},
                      {"label": "Machecoul", "value": "C33267"},
                      {"label": "Maillezais", "value": "C33268"},
                      {"label": "Maisdon-sur-Sevre", "value": "C33269"},
                      {"label": "Malicorne-sur-Sarthe", "value": "C33270"},
                      {"label": "Malville", "value": "C33271"},
                      {"label": "Mamers", "value": "C33272"},
                      {"label": "Mansigne", "value": "C33273"},
                      {"label": "Marcon", "value": "C33274"},
                      {"label": "Mareuil-sur-Lay-Dissais", "value": "C33275"},
                      {"label": "Marigne-Laille", "value": "C33276"},
                      {"label": "Marolles-les-Braults", "value": "C33277"},
                      {"label": "Marsac-sur-Don", "value": "C33278"},
                      {"label": "Martigne-Briand", "value": "C33279"},
                      {"label": "Martigne-sur-Mayenne", "value": "C33280"},
                      {"label": "Maulevrier", "value": "C33281"},
                      {"label": "Mauves-sur-Loire", "value": "C33282"},
                      {"label": "Mayenne", "value": "C33283"},
                      {"label": "Mayet", "value": "C33284"},
                      {"label": "Maze", "value": "C33285"},
                      {"label": "Mazieres-en-Mauges", "value": "C33286"},
                      {"label": "Melay", "value": "C33287"},
                      {"label": "Mervent", "value": "C33288"},
                      {"label": "Mesanger", "value": "C33289"},
                      {"label": "Meslay-du-Maine", "value": "C33290"},
                      {"label": "Mesquer", "value": "C33291"},
                      {"label": "Mezeray", "value": "C33292"},
                      {"label": "Mire", "value": "C33293"},
                      {"label": "Missillac", "value": "C33294"},
                      {"label": "Moisdon-la-Riviere", "value": "C33295"},
                      {"label": "Monce-en-Belin", "value": "C33296"},
                      {"label": "Monnieres", "value": "C33297"},
                      {"label": "Montaigu", "value": "C33298"},
                      {"label": "Montbert", "value": "C33299"},
                      {"label": "Montbizot", "value": "C33300"},
                      {"label": "Montenay", "value": "C33301"},
                      {"label": "Montfaucon-Montigne", "value": "C33302"},
                      {"label": "Montfort-le-Gesnois", "value": "C33303"},
                      {"label": "Montigne-le-Brillant", "value": "C33304"},
                      {"label": "Montjean-sur-Loire", "value": "C33305"},
                      {"label": "Montoir-de-Bretagne", "value": "C33306"},
                      {"label": "Montournais", "value": "C33307"},
                      {"label": "Montreuil-Bellay", "value": "C33308"},
                      {"label": "Montrevault", "value": "C33309"},
                      {"label": "Montsurs", "value": "C33310"},
                      {"label": "Morannes", "value": "C33311"},
                      {"label": "Mortagne-sur-Sevre", "value": "C33312"},
                      {"label": "Mouchamps", "value": "C33313"},
                      {"label": "Mouilleron-en-Pareds", "value": "C33314"},
                      {"label": "Mouilleron-le-Captif", "value": "C33315"},
                      {"label": "Moulay", "value": "C33316"},
                      {"label": "Moutiers-les-Mauxfaits", "value": "C33317"},
                      {"label": "Mouzeil", "value": "C33318"},
                      {"label": "Mouzillon", "value": "C33319"},
                      {"label": "Moze-sur-Louet", "value": "C33320"},
                      {"label": "Mulsanne", "value": "C33321"},
                      {"label": "Murs-Erigne", "value": "C33322"},
                      {"label": "Nalliers", "value": "C33323"},
                      {"label": "Nantes", "value": "C33324"},
                      {"label": "Nesmy", "value": "C33325"},
                      {"label": "Neuville-sur-Sarthe", "value": "C33326"},
                      {"label": "Nieul-le-Dolent", "value": "C33327"},
                      {"label": "Noirmoutier-en-lIle", "value": "C33328"},
                      {"label": "Nort-sur-Erdre", "value": "C33329"},
                      {"label": "Notre-Dame-de-Monts", "value": "C33330"},
                      {"label": "Notre-Dame-de-Riez", "value": "C33331"},
                      {"label": "Notre-Dame-des-Landes", "value": "C33332"},
                      {"label": "Noyant", "value": "C33333"},
                      {"label": "Noyant-la-Gravoyere", "value": "C33334"},
                      {"label": "Noyen-sur-Sarthe", "value": "C33335"},
                      {"label": "Nozay", "value": "C33336"},
                      {"label": "Nuaille", "value": "C33337"},
                      {"label": "Nueil-sur-Layon", "value": "C33338"},
                      {"label": "Nuille-sur-Vicoin", "value": "C33339"},
                      {"label": "Nyoiseau", "value": "C33340"},
                      {"label": "Oisseau", "value": "C33341"},
                      {"label": "Olonne-sur-Mer", "value": "C33342"},
                      {"label": "Orvault", "value": "C33343"},
                      {"label": "Oudon", "value": "C33344"},
                      {"label": "Paimboeuf", "value": "C33345"},
                      {"label": "Pannece", "value": "C33346"},
                      {"label": "Parcay-les-Pins", "value": "C33347"},
                      {"label": "Parce-sur-Sarthe", "value": "C33348"},
                      {"label": "Parigne-le-Polin", "value": "C33349"},
                      {"label": "Paulx", "value": "C33350"},
                      {"label": "Pellouailles-les-Vignes", "value": "C33351"},
                      {"label": "Petit-Mars", "value": "C33352"},
                      {"label": "Piriac-sur-Mer", "value": "C33353"},
                      {"label": "Pissotte", "value": "C33354"},
                      {"label": "Plesse", "value": "C33355"},
                      {"label": "Pont-Saint-Martin", "value": "C33356"},
                      {"label": "Pontchateau", "value": "C33357"},
                      {"label": "Pontvallain", "value": "C33358"},
                      {"label": "Pornic", "value": "C33359"},
                      {"label": "Pornichet", "value": "C33360"},
                      {"label": "Port-Brillet", "value": "C33361"},
                      {"label": "Port-Saint-Pere", "value": "C33362"},
                      {"label": "Pouance", "value": "C33363"},
                      {"label": "Pouzauges", "value": "C33364"},
                      {"label": "Pre-en-Pail", "value": "C33365"},
                      {"label": "Precigne", "value": "C33366"},
                      {"label": "Prefailles", "value": "C33367"},
                      {"label": "Prinquiau", "value": "C33368"},
                      {"label": "Pruille-le-Chetif", "value": "C33369"},
                      {"label": "Quelaines-Saint-Gault", "value": "C33370"},
                      {"label": "Quilly", "value": "C33371"},
                      {"label": "Remouille", "value": "C33372"},
                      {"label": "Renaze", "value": "C33373"},
                      {"label": "Requeil", "value": "C33374"},
                      {"label": "Reze", "value": "C33375"},
                      {"label": "Riaille", "value": "C33376"},
                      {"label": "Rochefort-sur-Loire", "value": "C33377"},
                      {"label": "Rocheserviere", "value": "C33378"},
                      {"label": "Roeze-sur-Sarthe", "value": "C33379"},
                      {"label": "Rouans", "value": "C33380"},
                      {"label": "Rouge", "value": "C33381"},
                      {"label": "Rouillon", "value": "C33382"},
                      {"label": "Roussay", "value": "C33383"},
                      {"label": "Ruaudin", "value": "C33384"},
                      {"label": "Ruille-sur-Loir", "value": "C33385"},
                      {"label": "Sable-sur-Sarthe", "value": "C33386"},
                      {"label": "Saffre", "value": "C33387"},
                      {"label": "Saint-Andre-de-la-Marche", "value": "C33388"},
                      {"label": "Saint-Andre-des-Eaux", "value": "C33389"},
                      {"label": "Saint-Andre-Treize-Voies", "value": "C33390"},
                      {"label": "Saint-Aubin-des-Chateaux", "value": "C33391"},
                      {"label": "Saint-Aubin-des-Ormeaux", "value": "C33392"},
                      {"label": "Saint-Baudelle", "value": "C33393"},
                      {"label": "Saint-Berthevin", "value": "C33394"},
                      {"label": "Saint-Brevin-les-Pins", "value": "C33395"},
                      {"label": "Saint-Calais", "value": "C33396"},
                      {"label": "Saint-Christophe-du-Bois", "value": "C33397"},
                      {"label": "Saint-Christophe-du-Ligneron",
                       "value": "C33398"},
                      {"label": "Saint-Clement-de-la-Place", "value": "C33399"},
                      {"label": "Saint-Clement-des-Levees", "value": "C33400"},
                      {"label": "Saint-Cosme-en-Vairais", "value": "C33401"},
                      {"label": "Saint-Crespin-sur-Moine", "value": "C33402"},
                      {"label": "Saint-Cyr-en-Bourg", "value": "C33403"},
                      {"label": "Saint-Denis-de-Gastines", "value": "C33404"},
                      {"label": "Saint-Denis-la-Chevasse", "value": "C33405"},
                      {"label": "Saint-etienne-de-Mer-Morte",
                       "value": "C33406"},
                      {"label": "Saint-etienne-de-Montluc", "value": "C33407"},
                      {"label": "Saint-etienne-du-Bois", "value": "C33408"},
                      {"label": "Saint-Fiacre-sur-Maine", "value": "C33409"},
                      {"label": "Saint-Florent-des-Bois", "value": "C33410"},
                      {"label": "Saint-Florent-le-Vieil", "value": "C33411"},
                      {"label": "Saint-Fort", "value": "C33412"},
                      {"label": "Saint-Fulgent", "value": "C33413"},
                      {"label": "Saint-Georges-Buttavent", "value": "C33414"},
                      {"label": "Saint-Georges-de-Montaigu", "value": "C33415"},
                      {"label": "Saint-Georges-de-Pointindoux",
                       "value": "C33416"},
                      {"label": "Saint-Georges-du-Bois", "value": "C33417"},
                      {"label": "Saint-Georges-sur-Loire", "value": "C33418"},
                      {"label": "Saint-Gereon", "value": "C33419"},
                      {"label": "Saint-Germain-de-Princay", "value": "C33420"},
                      {"label": "Saint-Germain-des-Pres", "value": "C33421"},
                      {"label": "Saint-Germain-sur-Moine", "value": "C33422"},
                      {"label": "Saint-Gervais", "value": "C33423"},
                      {"label": "Saint-Gervais-en-Belin", "value": "C33424"},
                      {"label": "Saint-Gildas-des-Bois", "value": "C33425"},
                      {"label": "Saint-Gilles-Croix-de-Vie", "value": "C33426"},
                      {"label": "Saint-Herblain", "value": "C33427"},
                      {"label": "Saint-Herblon", "value": "C33428"},
                      {"label": "Saint-Hilaire-de-Chaleons", "value": "C33429"},
                      {"label": "Saint-Hilaire-de-Clisson", "value": "C33430"},
                      {"label": "Saint-Hilaire-de-Loulay", "value": "C33431"},
                      {"label": "Saint-Hilaire-de-Riez", "value": "C33432"},
                      {"label": "Saint-Hilaire-de-Talmont", "value": "C33433"},
                      {"label": "Saint-Hilaire-des-Loges", "value": "C33434"},
                      {"label": "Saint-Jean", "value": "C33435"},
                      {"label": "Saint-Jean-de-Boiseau", "value": "C33436"},
                      {"label": "Saint-Jean-de-Monts", "value": "C33437"},
                      {"label": "Saint-Jean-des-Mauvrets", "value": "C33438"},
                      {"label": "Saint-Jean-sur-Mayenne", "value": "C33439"},
                      {"label": "Saint-Joachim", "value": "C33440"},
                      {"label": "Saint-Julien-de-Concelles", "value": "C33441"},
                      {"label": "Saint-Julien-des-Landes", "value": "C33442"},
                      {"label": "Saint-Lambert-du-Lattay", "value": "C33443"},
                      {"label": "Saint-Lambert-la-Potherie", "value": "C33444"},
                      {"label": "Saint-Laurent-de-la-Plaine",
                       "value": "C33445"},
                      {"label": "Saint-Laurent-des-Autels", "value": "C33446"},
                      {"label": "Saint-Laurent-sur-Sevre", "value": "C33447"},
                      {"label": "Saint-Leger-des-Bois", "value": "C33448"},
                      {"label": "Saint-Leger-les-Vignes", "value": "C33449"},
                      {"label": "Saint-Leger-sous-Cholet", "value": "C33450"},
                      {"label": "Saint-Lumine-de-Clisson", "value": "C33451"},
                      {"label": "Saint-Lumine-de-Coutais", "value": "C33452"},
                      {"label": "Saint-Lyphard", "value": "C33453"},
                      {"label": "Saint-Macaire-en-Mauges", "value": "C33454"},
                      {"label": "Saint-Malo-de-Guersac", "value": "C33455"},
                      {"label": "Saint-Malo-du-Bois", "value": "C33456"},
                      {"label": "Saint-Mars-dOutille", "value": "C33457"},
                      {"label": "Saint-Mars-de-Coutais", "value": "C33458"},
                      {"label": "Saint-Mars-du-Desert", "value": "C33459"},
                      {"label": "Saint-Mars-la-Briere", "value": "C33460"},
                      {"label": "Saint-Mars-la-Jaille", "value": "C33461"},
                      {"label": "Saint-Martin-de-la-Place", "value": "C33462"},
                      {"label": "Saint-Martin-des-Noyers", "value": "C33463"},
                      {"label": "Saint-Martin-du-Fouilloux", "value": "C33464"},
                      {"label": "Saint-Martin-sous-Mouzeuil",
                       "value": "C33465"},
                      {"label": "Saint-Mathurin", "value": "C33466"},
                      {"label": "Saint-Mathurin-sur-Loire", "value": "C33467"},
                      {"label": "Saint-Melaine-sur-Aubance", "value": "C33468"},
                      {"label": "Saint-Meme-le-Tenu", "value": "C33469"},
                      {"label": "Saint-Mesmin", "value": "C33470"},
                      {"label": "Saint-Michel-Chef-Chef", "value": "C33471"},
                      {"label": "Saint-Michel-le-Cloucq", "value": "C33472"},
                      {"label": "Saint-Michel-Mont-Mercure", "value": "C33473"},
                      {"label": "Saint-Molf", "value": "C33474"},
                      {"label": "Saint-Nazaire", "value": "C33475"},
                      {"label": "Saint-Nicolas-de-Redon", "value": "C33476"},
                      {"label": "Saint-Ouen-des-Toits", "value": "C33477"},
                      {"label": "Saint-Ouen-en-Belin", "value": "C33478"},
                      {"label": "Saint-Paterne", "value": "C33479"},
                      {"label": "Saint-Paul-en-Pareds", "value": "C33480"},
                      {"label": "Saint-Pere-en-Retz", "value": "C33481"},
                      {"label": "Saint-Philbert-de-Bouaine", "value": "C33482"},
                      {"label": "Saint-Philbert-de-Grand-Lieu",
                       "value": "C33483"},
                      {"label": "Saint-Philbert-du-Peuple", "value": "C33484"},
                      {"label": "Saint-Pierre-des-Nids", "value": "C33485"},
                      {"label": "Saint-Pierre-du-Chemin", "value": "C33486"},
                      {"label": "Saint-Pierre-la-Cour", "value": "C33487"},
                      {"label": "Saint-Pierre-Montlimart", "value": "C33488"},
                      {"label": "Saint-Prouant", "value": "C33489"},
                      {"label": "Saint-Quentin-en-Mauges", "value": "C33490"},
                      {"label": "Saint-Remy-en-Mauges", "value": "C33491"},
                      {"label": "Saint-Saturnin", "value": "C33492"},
                      {"label": "Saint-Saturnin-sur-Loire", "value": "C33493"},
                      {"label": "Saint-Sebastien-sur-Loire", "value": "C33494"},
                      {"label": "Saint-Urbain", "value": "C33495"},
                      {"label": "Saint-Viaud", "value": "C33496"},
                      {"label": "Saint-Vincent-des-Landes", "value": "C33497"},
                      {"label": "Saint-Vincent-sur-Graon", "value": "C33498"},
                      {"label": "Sainte-Anne-sur-Brivet", "value": "C33499"},
                      {"label": "Sainte-Cecile", "value": "C33500"},
                      {"label": "Sainte-Flaive-des-Loups", "value": "C33501"},
                      {"label": "Sainte-Foy", "value": "C33502"},
                      {"label": "Sainte-Gemme-la-Plaine", "value": "C33503"},
                      {"label": "Sainte-Gemmes-sur-Loire", "value": "C33504"},
                      {"label": "Sainte-Hermine", "value": "C33505"},
                      {"label": "Sainte-Jamme-sur-Sarthe", "value": "C33506"},
                      {"label": "Sainte-Luce-sur-Loire", "value": "C33507"},
                      {"label": "Sainte-Pazanne", "value": "C33508"},
                      {"label": "Sainte-Reine-de-Bretagne", "value": "C33509"},
                      {"label": "Sainte-Suzanne", "value": "C33510"},
                      {"label": "Saligny", "value": "C33511"},
                      {"label": "Sallertaine", "value": "C33512"},
                      {"label": "Sarge-les-le-Mans", "value": "C33513"},
                      {"label": "Sarthe", "value": "C33514"},
                      {"label": "Saumur", "value": "C33515"},
                      {"label": "Sautron", "value": "C33516"},
                      {"label": "Savenay", "value": "C33517"},
                      {"label": "Savennieres", "value": "C33518"},
                      {"label": "Segre", "value": "C33519"},
                      {"label": "Seiches-sur-le-Loir", "value": "C33520"},
                      {"label": "Serigne", "value": "C33521"},
                      {"label": "Severac", "value": "C33522"},
                      {"label": "Sille-le-Guillaume", "value": "C33523"},
                      {"label": "Sion-les-Mines", "value": "C33524"},
                      {"label": "Solesmes", "value": "C33525"},
                      {"label": "Soucelles", "value": "C33526"},
                      {"label": "Soudan", "value": "C33527"},
                      {"label": "Soulaines-sur-Aubance", "value": "C33528"},
                      {"label": "Soulaire-et-Bourg", "value": "C33529"},
                      {"label": "Soulge-sur-Ouette", "value": "C33530"},
                      {"label": "Souligne-sous-Ballon", "value": "C33531"},
                      {"label": "Soullans", "value": "C33532"},
                      {"label": "Spay", "value": "C33533"},
                      {"label": "Suce-sur-Erdre", "value": "C33534"},
                      {"label": "Teille", "value": "C33535"},
                      {"label": "Teloche", "value": "C33536"},
                      {"label": "Tennie", "value": "C33537"},
                      {"label": "Thorigne-sur-Due", "value": "C33538"},
                      {"label": "Thouarce", "value": "C33539"},
                      {"label": "Thouare-sur-Loire", "value": "C33540"},
                      {"label": "Tierce", "value": "C33541"},
                      {"label": "Tiffauges", "value": "C33542"},
                      {"label": "Tillieres", "value": "C33543"},
                      {"label": "Torfou", "value": "C33544"},
                      {"label": "Toutlemonde", "value": "C33545"},
                      {"label": "Touvois", "value": "C33546"},
                      {"label": "Trange", "value": "C33547"},
                      {"label": "Treillieres", "value": "C33548"},
                      {"label": "Treize-Septiers", "value": "C33549"},
                      {"label": "Trelaze", "value": "C33550"},
                      {"label": "Trementines", "value": "C33551"},
                      {"label": "Triaize", "value": "C33552"},
                      {"label": "Trignac", "value": "C33553"},
                      {"label": "Tuffe", "value": "C33554"},
                      {"label": "Vaas", "value": "C33555"},
                      {"label": "Vaiges", "value": "C33556"},
                      {"label": "Vaire", "value": "C33557"},
                      {"label": "Vallet", "value": "C33558"},
                      {"label": "Varades", "value": "C33559"},
                      {"label": "Varennes-sur-Loire", "value": "C33560"},
                      {"label": "Varrains", "value": "C33561"},
                      {"label": "Vauchretien", "value": "C33562"},
                      {"label": "Vaudelnay", "value": "C33563"},
                      {"label": "Vay", "value": "C33564"},
                      {"label": "Venansault", "value": "C33565"},
                      {"label": "Vendrennes", "value": "C33566"},
                      {"label": "Vern-dAnjou", "value": "C33567"},
                      {"label": "Vernantes", "value": "C33568"},
                      {"label": "Vernoil-le-Fourrier", "value": "C33569"},
                      {"label": "Vertou", "value": "C33570"},
                      {"label": "Vezins", "value": "C33571"},
                      {"label": "Vibraye", "value": "C33572"},
                      {"label": "Vieillevigne", "value": "C33573"},
                      {"label": "Vigneux-de-Bretagne", "value": "C33574"},
                      {"label": "Vihiers", "value": "C33575"},
                      {"label": "Villaines-la-Juhel", "value": "C33576"},
                      {"label": "Villebernier", "value": "C33577"},
                      {"label": "Villedieu-la-Blouere", "value": "C33578"},
                      {"label": "Villeveque", "value": "C33579"},
                      {"label": "Vion", "value": "C33580"},
                      {"label": "Vivy", "value": "C33581"},
                      {"label": "Vix", "value": "C33582"},
                      {"label": "Vue", "value": "C33583"},
                      {"label": "Yvre-le-Polin", "value": "C33584"},
                      {"label": "Yzernay", "value": "C33585"}],
 "Paysandu Department": [{"label": "Estacion Porvenir", "value": "C146935"},
                         {"label": "Guichon", "value": "C146936"},
                         {"label": "Paysandu", "value": "C146937"},
                         {"label": "Piedras Coloradas", "value": "C146938"},
                         {"label": "Quebracho", "value": "C146939"},
                         {"label": "San Felix", "value": "C146940"}],
 "Pazardzhik Province": [{"label": "Batak", "value": "C16006"},
                         {"label": "Belovo", "value": "C16007"},
                         {"label": "Bratsigovo", "value": "C16008"},
                         {"label": "Lesichovo", "value": "C16009"},
                         {"label": "Obshtina Batak", "value": "C16010"},
                         {"label": "Obshtina Belovo", "value": "C16011"},
                         {"label": "Obshtina Bratsigovo", "value": "C16012"},
                         {"label": "Obshtina Lesichovo", "value": "C16013"},
                         {"label": "Obshtina Panagyurishte", "value": "C16014"},
                         {"label": "Obshtina Pazardzhik", "value": "C16015"},
                         {"label": "Obshtina Peshtera", "value": "C16016"},
                         {"label": "Obshtina Rakitovo", "value": "C16017"},
                         {"label": "Obshtina Septemvri", "value": "C16018"},
                         {"label": "Obshtina Strelcha", "value": "C16019"},
                         {"label": "Obshtina Velingrad", "value": "C16020"},
                         {"label": "Panagyurishte", "value": "C16021"},
                         {"label": "Pazardzhik", "value": "C16022"},
                         {"label": "Peshtera", "value": "C16023"},
                         {"label": "Rakitovo", "value": "C16024"},
                         {"label": "Sarnitsa", "value": "C16025"},
                         {"label": "Sarnitsa Obshtina", "value": "C16026"},
                         {"label": "Septemvri", "value": "C16027"},
                         {"label": "Strelcha", "value": "C16028"},
                         {"label": "Velingrad", "value": "C16029"}],
 "Pedernales Province": [{"label": "Juancho", "value": "C23962"},
                         {"label": "Oviedo", "value": "C23963"},
                         {"label": "Pedernales", "value": "C23964"}],
 "Pehcevo Municipality": [{"label": "Pehcevo", "value": "C65473"}],
 "Peleliu": [{"label": "Kloulklubed", "value": "C79784"}],
 "Peloponnese Region": [{"label": "agioi Theodoroi", "value": "C42338"},
                        {"label": "agios Andreas", "value": "C42339"},
                        {"label": "Arfara", "value": "C42340"},
                        {"label": "argos", "value": "C42341"},
                        {"label": "Aria", "value": "C42342"},
                        {"label": "aris", "value": "C42343"},
                        {"label": "Arkhaia Korinthos", "value": "C42344"},
                        {"label": "Asini", "value": "C42345"},
                        {"label": "Asopos", "value": "C42346"},
                        {"label": "assos", "value": "C42347"},
                        {"label": "astros", "value": "C42348"},
                        {"label": "Athikia", "value": "C42349"},
                        {"label": "Ayia Trias", "value": "C42350"},
                        {"label": "ayios Adhrianos", "value": "C42351"},
                        {"label": "ayios Vasilios", "value": "C42352"},
                        {"label": "Chora", "value": "C42353"},
                        {"label": "Didyma", "value": "C42354"},
                        {"label": "Dimitsana", "value": "C42355"},
                        {"label": "Drepanon", "value": "C42356"},
                        {"label": "Ermioni", "value": "C42357"},
                        {"label": "Examilia", "value": "C42358"},
                        {"label": "Filiatra", "value": "C42359"},
                        {"label": "Gargalianoi", "value": "C42360"},
                        {"label": "Gefyra", "value": "C42361"},
                        {"label": "Geraki", "value": "C42362"},
                        {"label": "Gytheio", "value": "C42363"},
                        {"label": "Isthmia", "value": "C42364"},
                        {"label": "Kalamata", "value": "C42365"},
                        {"label": "Kardamyli", "value": "C42366"},
                        {"label": "Karyes", "value": "C42367"},
                        {"label": "Kato Dhiminio", "value": "C42368"},
                        {"label": "Kato Glykovrysi", "value": "C42369"},
                        {"label": "Khiliomodhi", "value": "C42370"},
                        {"label": "Kiato", "value": "C42371"},
                        {"label": "Koilas", "value": "C42372"},
                        {"label": "Kokkonion", "value": "C42373"},
                        {"label": "Kopanaki", "value": "C42374"},
                        {"label": "Korinthos", "value": "C42375"},
                        {"label": "Koroni", "value": "C42376"},
                        {"label": "Koutsopodi", "value": "C42377"},
                        {"label": "Kranidi", "value": "C42378"},
                        {"label": "Krokees", "value": "C42379"},
                        {"label": "Kyparissia", "value": "C42380"},
                        {"label": "Kyras Vrysi", "value": "C42381"},
                        {"label": "Langadhia", "value": "C42382"},
                        {"label": "Lekhaio", "value": "C42383"},
                        {"label": "Leonidio", "value": "C42384"},
                        {"label": "Levidion", "value": "C42385"},
                        {"label": "Ligourion", "value": "C42386"},
                        {"label": "Loutra Oraias Elenis", "value": "C42387"},
                        {"label": "Loutraki", "value": "C42388"},
                        {"label": "Magoula", "value": "C42389"},
                        {"label": "Megalopoli", "value": "C42390"},
                        {"label": "Meligalas", "value": "C42391"},
                        {"label": "Messini", "value": "C42392"},
                        {"label": "Methoni", "value": "C42393"},
                        {"label": "Molaoi", "value": "C42394"},
                        {"label": "Moulki", "value": "C42395"},
                        {"label": "Nafplio", "value": "C42396"},
                        {"label": "Nea Kios", "value": "C42397"},
                        {"label": "Nea Tirins", "value": "C42398"},
                        {"label": "Nemea", "value": "C42399"},
                        {"label": "Nomos Arkadias", "value": "C42400"},
                        {"label": "Palaia Epidavros", "value": "C42401"},
                        {"label": "Paralia Vergas", "value": "C42402"},
                        {"label": "Pasion", "value": "C42403"},
                        {"label": "Perachora", "value": "C42404"},
                        {"label": "Periyiali", "value": "C42405"},
                        {"label": "Porto Cheli", "value": "C42406"},
                        {"label": "Pylos", "value": "C42407"},
                        {"label": "Sikyon", "value": "C42408"},
                        {"label": "Skala", "value": "C42409"},
                        {"label": "Sofikon", "value": "C42410"},
                        {"label": "Sparti", "value": "C42411"},
                        {"label": "Sperchogeia", "value": "C42412"},
                        {"label": "Sykia", "value": "C42413"},
                        {"label": "Thouria", "value": "C42414"},
                        {"label": "Tolon", "value": "C42415"},
                        {"label": "Traganon", "value": "C42416"},
                        {"label": "Tripoli", "value": "C42417"},
                        {"label": "Velo", "value": "C42418"},
                        {"label": "Vlachiotis", "value": "C42419"},
                        {"label": "Vlachopoulo", "value": "C42420"},
                        {"label": "Vokhaiko", "value": "C42421"},
                        {"label": "Vrakhati", "value": "C42422"},
                        {"label": "Xylokastro", "value": "C42423"},
                        {"label": "Zevgolateio", "value": "C42424"}],
 "Pemagatshel District": [{"label": "Pemagatshel", "value": "C9641"}],
 "Pemba North": [{"label": "Konde", "value": "C118260"},
                 {"label": "Micheweni", "value": "C118261"},
                 {"label": "Wete", "value": "C118262"}],
 "Pemba South": [{"label": "Chake Chake", "value": "C118263"},
                 {"label": "Mtambile", "value": "C118264"},
                 {"label": "Uwelini", "value": "C118265"}],
 "Pembroke": [{"label": "Pembroke", "value": "C65906"}],
 "Penal-Debe Regional Corporation": [{"label": "Debe", "value": "C119663"},
                                     {"label": "Penal", "value": "C119664"}],
 "Penang": [{"label": "Batu Feringgi", "value": "C65706"},
            {"label": "Bukit Mertajam", "value": "C65707"},
            {"label": "Butterworth", "value": "C65708"},
            {"label": "George Town", "value": "C65709"},
            {"label": "Juru", "value": "C65710"},
            {"label": "Kampung Batu Feringgi", "value": "C65711"},
            {"label": "Kampung Sungai Ara", "value": "C65712"},
            {"label": "Kepala Batas", "value": "C65713"},
            {"label": "Nibong Tebal", "value": "C65714"},
            {"label": "Perai", "value": "C65715"},
            {"label": "Permatang Kuching", "value": "C65716"},
            {"label": "Seberang Perai", "value": "C65717"},
            {"label": "Tanjung Tokong", "value": "C65718"},
            {"label": "Tasek Glugor", "value": "C65719"},
            {"label": "Telaga Batu", "value": "C65720"}],
 "Penghu": [{"label": "Magong", "value": "C117974"},
            {"label": "Penghu County", "value": "C117975"}],
 "Pennsylvania": [{"label": "Abbottstown", "value": "C141322"},
                  {"label": "Adams County", "value": "C141323"},
                  {"label": "Adamstown", "value": "C141324"},
                  {"label": "Akron", "value": "C141325"},
                  {"label": "Albion", "value": "C141326"},
                  {"label": "Alburtis", "value": "C141327"},
                  {"label": "Aldan", "value": "C141328"},
                  {"label": "Aliquippa", "value": "C141329"},
                  {"label": "Allegheny County", "value": "C141330"},
                  {"label": "Alleghenyville", "value": "C141331"},
                  {"label": "Allentown", "value": "C141332"},
                  {"label": "Allison Park", "value": "C141333"},
                  {"label": "Almedia", "value": "C141334"},
                  {"label": "Altoona", "value": "C141335"},
                  {"label": "Ambler", "value": "C141336"},
                  {"label": "Ambridge", "value": "C141337"},
                  {"label": "Amity Gardens", "value": "C141338"},
                  {"label": "Ancient Oaks", "value": "C141339"},
                  {"label": "Annville", "value": "C141340"},
                  {"label": "Apollo", "value": "C141341"},
                  {"label": "Archbald", "value": "C141342"},
                  {"label": "Ardmore", "value": "C141343"},
                  {"label": "Arlington Heights", "value": "C141344"},
                  {"label": "Armstrong County", "value": "C141345"},
                  {"label": "Arnold", "value": "C141346"},
                  {"label": "Ashland", "value": "C141347"},
                  {"label": "Ashley", "value": "C141348"},
                  {"label": "Aspinwall", "value": "C141349"},
                  {"label": "Atglen", "value": "C141350"},
                  {"label": "Athens", "value": "C141351"},
                  {"label": "Audubon", "value": "C141352"},
                  {"label": "Avalon", "value": "C141353"},
                  {"label": "Avis", "value": "C141354"},
                  {"label": "Avoca", "value": "C141355"},
                  {"label": "Avon", "value": "C141356"},
                  {"label": "Avondale", "value": "C141357"},
                  {"label": "Avonia", "value": "C141358"},
                  {"label": "Back Mountain", "value": "C141359"},
                  {"label": "Baden", "value": "C141360"},
                  {"label": "Baidland", "value": "C141361"},
                  {"label": "Bainbridge", "value": "C141362"},
                  {"label": "Bakerstown", "value": "C141363"},
                  {"label": "Bala-Cynwyd", "value": "C141364"},
                  {"label": "Baldwin", "value": "C141365"},
                  {"label": "Bally", "value": "C141366"},
                  {"label": "Bangor", "value": "C141367"},
                  {"label": "Barnesboro", "value": "C141368"},
                  {"label": "Bath", "value": "C141369"},
                  {"label": "Bear Rocks", "value": "C141370"},
                  {"label": "Beaver", "value": "C141371"},
                  {"label": "Beaver County", "value": "C141372"},
                  {"label": "Beaver Falls", "value": "C141373"},
                  {"label": "Beaverdale", "value": "C141374"},
                  {"label": "Bedford", "value": "C141375"},
                  {"label": "Bedford County", "value": "C141376"},
                  {"label": "Bedminster", "value": "C141377"},
                  {"label": "Beech Mountain Lakes", "value": "C141378"},
                  {"label": "Belfast", "value": "C141379"},
                  {"label": "Bell Acres", "value": "C141380"},
                  {"label": "Belle Vernon", "value": "C141381"},
                  {"label": "Bellefonte", "value": "C141382"},
                  {"label": "Belleville", "value": "C141383"},
                  {"label": "Bellevue", "value": "C141384"},
                  {"label": "Bellwood", "value": "C141385"},
                  {"label": "Belmont", "value": "C141386"},
                  {"label": "Ben Avon", "value": "C141387"},
                  {"label": "Bentleyville", "value": "C141388"},
                  {"label": "Berks County", "value": "C141389"},
                  {"label": "Berlin", "value": "C141390"},
                  {"label": "Berwick", "value": "C141391"},
                  {"label": "Berwyn", "value": "C141392"},
                  {"label": "Bessemer", "value": "C141393"},
                  {"label": "Bethel Park", "value": "C141394"},
                  {"label": "Bethlehem", "value": "C141395"},
                  {"label": "Big Bass Lake", "value": "C141396"},
                  {"label": "Big Beaver", "value": "C141397"},
                  {"label": "Biglerville", "value": "C141398"},
                  {"label": "Birchwood Lakes", "value": "C141399"},
                  {"label": "Birdsboro", "value": "C141400"},
                  {"label": "Black Lick", "value": "C141401"},
                  {"label": "Blair County", "value": "C141402"},
                  {"label": "Blairsville", "value": "C141403"},
                  {"label": "Blakely", "value": "C141404"},
                  {"label": "Blandon", "value": "C141405"},
                  {"label": "Blawnox", "value": "C141406"},
                  {"label": "Bloomfield", "value": "C141407"},
                  {"label": "Bloomsburg", "value": "C141408"},
                  {"label": "Blossburg", "value": "C141409"},
                  {"label": "Blue Ball", "value": "C141410"},
                  {"label": "Blue Bell", "value": "C141411"},
                  {"label": "Boalsburg", "value": "C141412"},
                  {"label": "Boiling Springs", "value": "C141413"},
                  {"label": "Bonneauville", "value": "C141414"},
                  {"label": "Boothwyn", "value": "C141415"},
                  {"label": "Boswell", "value": "C141416"},
                  {"label": "Bowmansville", "value": "C141417"},
                  {"label": "Boyertown", "value": "C141418"},
                  {"label": "Brackenridge", "value": "C141419"},
                  {"label": "Braddock", "value": "C141420"},
                  {"label": "Braddock Hills", "value": "C141421"},
                  {"label": "Bradford", "value": "C141422"},
                  {"label": "Bradford County", "value": "C141423"},
                  {"label": "Bradford Woods", "value": "C141424"},
                  {"label": "Breinigsville", "value": "C141425"},
                  {"label": "Brentwood", "value": "C141426"},
                  {"label": "Bressler", "value": "C141427"},
                  {"label": "Brickerville", "value": "C141428"},
                  {"label": "Bridgeport", "value": "C141429"},
                  {"label": "Bridgeville", "value": "C141430"},
                  {"label": "Bristol", "value": "C141431"},
                  {"label": "Brittany Farms-Highlands", "value": "C141432"},
                  {"label": "Brockway", "value": "C141433"},
                  {"label": "Brodheadsville", "value": "C141434"},
                  {"label": "Brookhaven", "value": "C141435"},
                  {"label": "Brookville", "value": "C141436"},
                  {"label": "Broomall", "value": "C141437"},
                  {"label": "Brownstown", "value": "C141438"},
                  {"label": "Brownsville", "value": "C141439"},
                  {"label": "Browntown", "value": "C141440"},
                  {"label": "Bryn Athyn", "value": "C141441"},
                  {"label": "Bryn Mawr", "value": "C141442"},
                  {"label": "Bucks County", "value": "C141443"},
                  {"label": "Burgettstown", "value": "C141444"},
                  {"label": "Burnham", "value": "C141445"},
                  {"label": "Butler", "value": "C141446"},
                  {"label": "Butler County", "value": "C141447"},
                  {"label": "California", "value": "C141448"},
                  {"label": "Caln", "value": "C141449"},
                  {"label": "Calumet", "value": "C141450"},
                  {"label": "Cambria County", "value": "C141451"},
                  {"label": "Cambridge Springs", "value": "C141452"},
                  {"label": "Cameron County", "value": "C141453"},
                  {"label": "Camp Hill", "value": "C141454"},
                  {"label": "Campbelltown", "value": "C141455"},
                  {"label": "Canonsburg", "value": "C141456"},
                  {"label": "Canton", "value": "C141457"},
                  {"label": "Carbon County", "value": "C141458"},
                  {"label": "Carbondale", "value": "C141459"},
                  {"label": "Carlisle", "value": "C141460"},
                  {"label": "Carnegie", "value": "C141461"},
                  {"label": "Carnot-Moon", "value": "C141462"},
                  {"label": "Carroll Valley", "value": "C141463"},
                  {"label": "Castanea", "value": "C141464"},
                  {"label": "Castle Shannon", "value": "C141465"},
                  {"label": "Catasauqua", "value": "C141466"},
                  {"label": "Catawissa", "value": "C141467"},
                  {"label": "Cecil-Bishop", "value": "C141468"},
                  {"label": "Cementon", "value": "C141469"},
                  {"label": "Center City", "value": "C141470"},
                  {"label": "Centerville", "value": "C141471"},
                  {"label": "Central City", "value": "C141472"},
                  {"label": "Centre County", "value": "C141473"},
                  {"label": "Centre Hall", "value": "C141474"},
                  {"label": "Cetronia", "value": "C141475"},
                  {"label": "Chalfont", "value": "C141476"},
                  {"label": "Chambersburg", "value": "C141477"},
                  {"label": "Charleroi", "value": "C141478"},
                  {"label": "Cherryville", "value": "C141479"},
                  {"label": "Chester", "value": "C141480"},
                  {"label": "Chester County", "value": "C141481"},
                  {"label": "Chester Heights", "value": "C141482"},
                  {"label": "Chester Springs", "value": "C141483"},
                  {"label": "Chesterbrook", "value": "C141484"},
                  {"label": "Cheswick", "value": "C141485"},
                  {"label": "Chevy Chase Heights", "value": "C141486"},
                  {"label": "Chicora", "value": "C141487"},
                  {"label": "Chinchilla", "value": "C141488"},
                  {"label": "Christiana", "value": "C141489"},
                  {"label": "Church Hill", "value": "C141490"},
                  {"label": "Churchill", "value": "C141491"},
                  {"label": "Churchville", "value": "C141492"},
                  {"label": "Clairton", "value": "C141493"},
                  {"label": "Clarion", "value": "C141494"},
                  {"label": "Clarion County", "value": "C141495"},
                  {"label": "Clarks Green", "value": "C141496"},
                  {"label": "Clarks Summit", "value": "C141497"},
                  {"label": "Clay", "value": "C141498"},
                  {"label": "Claysburg", "value": "C141499"},
                  {"label": "Clearfield", "value": "C141500"},
                  {"label": "Clearfield County", "value": "C141501"},
                  {"label": "Cleona", "value": "C141502"},
                  {"label": "Clifton Heights", "value": "C141503"},
                  {"label": "Clinton County", "value": "C141504"},
                  {"label": "Clymer", "value": "C141505"},
                  {"label": "Coaldale", "value": "C141506"},
                  {"label": "Coatesville", "value": "C141507"},
                  {"label": "Cochranton", "value": "C141508"},
                  {"label": "Collegeville", "value": "C141509"},
                  {"label": "Collingdale", "value": "C141510"},
                  {"label": "Collinsburg", "value": "C141511"},
                  {"label": "Colonial Park", "value": "C141512"},
                  {"label": "Colony Park", "value": "C141513"},
                  {"label": "Columbia", "value": "C141514"},
                  {"label": "Columbia County", "value": "C141515"},
                  {"label": "Colwyn", "value": "C141516"},
                  {"label": "Conashaugh Lakes", "value": "C141517"},
                  {"label": "Conemaugh", "value": "C141518"},
                  {"label": "Conestoga", "value": "C141519"},
                  {"label": "Conneaut Lakeshore", "value": "C141520"},
                  {"label": "Connellsville", "value": "C141521"},
                  {"label": "Conshohocken", "value": "C141522"},
                  {"label": "Conway", "value": "C141523"},
                  {"label": "Conyngham", "value": "C141524"},
                  {"label": "Coopersburg", "value": "C141525"},
                  {"label": "Coplay", "value": "C141526"},
                  {"label": "Coraopolis", "value": "C141527"},
                  {"label": "Cornwall", "value": "C141528"},
                  {"label": "Cornwells Heights", "value": "C141529"},
                  {"label": "Corry", "value": "C141530"},
                  {"label": "Coudersport", "value": "C141531"},
                  {"label": "Crafton", "value": "C141532"},
                  {"label": "Cranberry Township", "value": "C141533"},
                  {"label": "Crawford County", "value": "C141534"},
                  {"label": "Cresson", "value": "C141535"},
                  {"label": "Cressona", "value": "C141536"},
                  {"label": "Croydon", "value": "C141537"},
                  {"label": "Cumberland County", "value": "C141538"},
                  {"label": "Curtisville", "value": "C141539"},
                  {"label": "Curwensville", "value": "C141540"},
                  {"label": "Dale", "value": "C141541"},
                  {"label": "Dallas", "value": "C141542"},
                  {"label": "Dallastown", "value": "C141543"},
                  {"label": "Dalton", "value": "C141544"},
                  {"label": "Danville", "value": "C141545"},
                  {"label": "Darby", "value": "C141546"},
                  {"label": "Dauphin County", "value": "C141547"},
                  {"label": "Davidsville", "value": "C141548"},
                  {"label": "Delaware County", "value": "C141549"},
                  {"label": "Delmont", "value": "C141550"},
                  {"label": "Denver", "value": "C141551"},
                  {"label": "Derry", "value": "C141552"},
                  {"label": "Devon", "value": "C141553"},
                  {"label": "Dewart", "value": "C141554"},
                  {"label": "Dickson City", "value": "C141555"},
                  {"label": "Dillsburg", "value": "C141556"},
                  {"label": "Donora", "value": "C141557"},
                  {"label": "Dormont", "value": "C141558"},
                  {"label": "Dorneyville", "value": "C141559"},
                  {"label": "Dover", "value": "C141560"},
                  {"label": "Downingtown", "value": "C141561"},
                  {"label": "Doylestown", "value": "C141562"},
                  {"label": "Dravosburg", "value": "C141563"},
                  {"label": "Dresher", "value": "C141564"},
                  {"label": "Drexel Hill", "value": "C141565"},
                  {"label": "Dublin", "value": "C141566"},
                  {"label": "DuBois", "value": "C141567"},
                  {"label": "Duboistown", "value": "C141568"},
                  {"label": "Dunbar", "value": "C141569"},
                  {"label": "Duncannon", "value": "C141570"},
                  {"label": "Duncansville", "value": "C141571"},
                  {"label": "Dunmore", "value": "C141572"},
                  {"label": "Dunnstown", "value": "C141573"},
                  {"label": "Dupont", "value": "C141574"},
                  {"label": "Duquesne", "value": "C141575"},
                  {"label": "Duryea", "value": "C141576"},
                  {"label": "Eagleview", "value": "C141577"},
                  {"label": "Eagleville", "value": "C141578"},
                  {"label": "Earlston", "value": "C141579"},
                  {"label": "East Bangor", "value": "C141580"},
                  {"label": "East Berlin", "value": "C141581"},
                  {"label": "East Berwick", "value": "C141582"},
                  {"label": "East Conemaugh", "value": "C141583"},
                  {"label": "East Earl", "value": "C141584"},
                  {"label": "East Greenville", "value": "C141585"},
                  {"label": "East Lansdowne", "value": "C141586"},
                  {"label": "East McKeesport", "value": "C141587"},
                  {"label": "East Petersburg", "value": "C141588"},
                  {"label": "East Pittsburgh", "value": "C141589"},
                  {"label": "East Stroudsburg", "value": "C141590"},
                  {"label": "East Uniontown", "value": "C141591"},
                  {"label": "East Washington", "value": "C141592"},
                  {"label": "East York", "value": "C141593"},
                  {"label": "Eastlawn Gardens", "value": "C141594"},
                  {"label": "Easton", "value": "C141595"},
                  {"label": "Ebensburg", "value": "C141596"},
                  {"label": "Economy", "value": "C141597"},
                  {"label": "Eddington", "value": "C141598"},
                  {"label": "Eddystone", "value": "C141599"},
                  {"label": "Edgewood", "value": "C141600"},
                  {"label": "Edgeworth", "value": "C141601"},
                  {"label": "Edinboro", "value": "C141602"},
                  {"label": "Edwardsville", "value": "C141603"},
                  {"label": "Effort", "value": "C141604"},
                  {"label": "Egypt", "value": "C141605"},
                  {"label": "Elim", "value": "C141606"},
                  {"label": "Elizabeth", "value": "C141607"},
                  {"label": "Elizabethtown", "value": "C141608"},
                  {"label": "Elizabethville", "value": "C141609"},
                  {"label": "Elk County", "value": "C141610"},
                  {"label": "Elkland", "value": "C141611"},
                  {"label": "Ellport", "value": "C141612"},
                  {"label": "Ellwood City", "value": "C141613"},
                  {"label": "Elverson", "value": "C141614"},
                  {"label": "Elysburg", "value": "C141615"},
                  {"label": "Emerald Lakes", "value": "C141616"},
                  {"label": "Emigsville", "value": "C141617"},
                  {"label": "Emmaus", "value": "C141618"},
                  {"label": "Emporium", "value": "C141619"},
                  {"label": "Emsworth", "value": "C141620"},
                  {"label": "Enhaut", "value": "C141621"},
                  {"label": "Enlow", "value": "C141622"},
                  {"label": "Enola", "value": "C141623"},
                  {"label": "Ephrata", "value": "C141624"},
                  {"label": "Erie", "value": "C141625"},
                  {"label": "Erie County", "value": "C141626"},
                  {"label": "Espy", "value": "C141627"},
                  {"label": "Etna", "value": "C141628"},
                  {"label": "Evans City", "value": "C141629"},
                  {"label": "Evansburg", "value": "C141630"},
                  {"label": "Everett", "value": "C141631"},
                  {"label": "Exeter", "value": "C141632"},
                  {"label": "Exton", "value": "C141633"},
                  {"label": "Factoryville", "value": "C141634"},
                  {"label": "Fairchance", "value": "C141635"},
                  {"label": "Fairdale", "value": "C141636"},
                  {"label": "Fairhope", "value": "C141637"},
                  {"label": "Fairless Hills", "value": "C141638"},
                  {"label": "Fairview", "value": "C141639"},
                  {"label": "Fairview-Ferndale", "value": "C141640"},
                  {"label": "Falls Creek", "value": "C141641"},
                  {"label": "Farrell", "value": "C141642"},
                  {"label": "Faxon", "value": "C141643"},
                  {"label": "Fayette County", "value": "C141644"},
                  {"label": "Fayetteville", "value": "C141645"},
                  {"label": "Feasterville", "value": "C141646"},
                  {"label": "Fellsburg", "value": "C141647"},
                  {"label": "Ferndale", "value": "C141648"},
                  {"label": "Fernway", "value": "C141649"},
                  {"label": "Fivepointville", "value": "C141650"},
                  {"label": "Fleetwood", "value": "C141651"},
                  {"label": "Flemington", "value": "C141652"},
                  {"label": "Flourtown", "value": "C141653"},
                  {"label": "Flying Hills", "value": "C141654"},
                  {"label": "Folcroft", "value": "C141655"},
                  {"label": "Folsom", "value": "C141656"},
                  {"label": "Ford City", "value": "C141657"},
                  {"label": "Forest City", "value": "C141658"},
                  {"label": "Forest County", "value": "C141659"},
                  {"label": "Forest Hills", "value": "C141660"},
                  {"label": "Fort Washington", "value": "C141661"},
                  {"label": "Forty Fort", "value": "C141662"},
                  {"label": "Foster Brook", "value": "C141663"},
                  {"label": "Fountain Hill", "value": "C141664"},
                  {"label": "Fox Chapel", "value": "C141665"},
                  {"label": "Fox Chase", "value": "C141666"},
                  {"label": "Fox Run", "value": "C141667"},
                  {"label": "Frackville", "value": "C141668"},
                  {"label": "Franklin", "value": "C141669"},
                  {"label": "Franklin County", "value": "C141670"},
                  {"label": "Franklin Park", "value": "C141671"},
                  {"label": "Fredericksburg", "value": "C141672"},
                  {"label": "Freedom", "value": "C141673"},
                  {"label": "Freeland", "value": "C141674"},
                  {"label": "Freemansburg", "value": "C141675"},
                  {"label": "Freeport", "value": "C141676"},
                  {"label": "Friedens", "value": "C141677"},
                  {"label": "Fullerton", "value": "C141678"},
                  {"label": "Fulton County", "value": "C141679"},
                  {"label": "Galeton", "value": "C141680"},
                  {"label": "Gallitzin", "value": "C141681"},
                  {"label": "Gap", "value": "C141682"},
                  {"label": "Garden View", "value": "C141683"},
                  {"label": "Gastonville", "value": "C141684"},
                  {"label": "Geistown", "value": "C141685"},
                  {"label": "Georgetown", "value": "C141686"},
                  {"label": "Gettysburg", "value": "C141687"},
                  {"label": "Gibsonia", "value": "C141688"},
                  {"label": "Gilbertsville", "value": "C141689"},
                  {"label": "Girard", "value": "C141690"},
                  {"label": "Girardville", "value": "C141691"},
                  {"label": "Glassport", "value": "C141692"},
                  {"label": "Glen Lyon", "value": "C141693"},
                  {"label": "Glen Rock", "value": "C141694"},
                  {"label": "Glenolden", "value": "C141695"},
                  {"label": "Glenshaw", "value": "C141696"},
                  {"label": "Glenside", "value": "C141697"},
                  {"label": "Gold Key Lake", "value": "C141698"},
                  {"label": "Grantley", "value": "C141699"},
                  {"label": "Green Tree", "value": "C141700"},
                  {"label": "Greencastle", "value": "C141701"},
                  {"label": "Greene County", "value": "C141702"},
                  {"label": "Greenfields", "value": "C141703"},
                  {"label": "Greenock", "value": "C141704"},
                  {"label": "Greensburg", "value": "C141705"},
                  {"label": "Greenville", "value": "C141706"},
                  {"label": "Greenwood", "value": "C141707"},
                  {"label": "Grill", "value": "C141708"},
                  {"label": "Grove City", "value": "C141709"},
                  {"label": "Guilford", "value": "C141710"},
                  {"label": "Guilford Siding", "value": "C141711"},
                  {"label": "Halfway House", "value": "C141712"},
                  {"label": "Hallam", "value": "C141713"},
                  {"label": "Hallstead", "value": "C141714"},
                  {"label": "Hamburg", "value": "C141715"},
                  {"label": "Hanover", "value": "C141716"},
                  {"label": "Harleigh", "value": "C141717"},
                  {"label": "Harleysville", "value": "C141718"},
                  {"label": "Harrisburg", "value": "C141719"},
                  {"label": "Harveys Lake", "value": "C141720"},
                  {"label": "Hasson Heights", "value": "C141721"},
                  {"label": "Hastings", "value": "C141722"},
                  {"label": "Hatboro", "value": "C141723"},
                  {"label": "Hatfield", "value": "C141724"},
                  {"label": "Hawley", "value": "C141725"},
                  {"label": "Hazleton", "value": "C141726"},
                  {"label": "Hebron", "value": "C141727"},
                  {"label": "Heidelberg", "value": "C141728"},
                  {"label": "Hellertown", "value": "C141729"},
                  {"label": "Hemlock Farms", "value": "C141730"},
                  {"label": "Hermitage", "value": "C141731"},
                  {"label": "Hershey", "value": "C141732"},
                  {"label": "Highland Park", "value": "C141733"},
                  {"label": "Highspire", "value": "C141734"},
                  {"label": "Hilldale", "value": "C141735"},
                  {"label": "Hiller", "value": "C141736"},
                  {"label": "Hokendauqua", "value": "C141737"},
                  {"label": "Hollidaysburg", "value": "C141738"},
                  {"label": "Homeacre-Lyndora", "value": "C141739"},
                  {"label": "Homer City", "value": "C141740"},
                  {"label": "Homestead", "value": "C141741"},
                  {"label": "Hometown", "value": "C141742"},
                  {"label": "Honesdale", "value": "C141743"},
                  {"label": "Honey Brook", "value": "C141744"},
                  {"label": "Hopwood", "value": "C141745"},
                  {"label": "Horsham", "value": "C141746"},
                  {"label": "Houserville", "value": "C141747"},
                  {"label": "Houston", "value": "C141748"},
                  {"label": "Hudson", "value": "C141749"},
                  {"label": "Hughestown", "value": "C141750"},
                  {"label": "Hughesville", "value": "C141751"},
                  {"label": "Hummels Wharf", "value": "C141752"},
                  {"label": "Hummelstown", "value": "C141753"},
                  {"label": "Huntingdon", "value": "C141754"},
                  {"label": "Huntingdon County", "value": "C141755"},
                  {"label": "Hyde", "value": "C141756"},
                  {"label": "Hyde Park", "value": "C141757"},
                  {"label": "Imperial", "value": "C141758"},
                  {"label": "Indian Mountain Lake", "value": "C141759"},
                  {"label": "Indiana", "value": "C141760"},
                  {"label": "Indiana County", "value": "C141761"},
                  {"label": "Industry", "value": "C141762"},
                  {"label": "Ingram", "value": "C141763"},
                  {"label": "Inkerman", "value": "C141764"},
                  {"label": "Intercourse", "value": "C141765"},
                  {"label": "Irwin", "value": "C141766"},
                  {"label": "Ivyland", "value": "C141767"},
                  {"label": "Jacksonwald", "value": "C141768"},
                  {"label": "Jacobus", "value": "C141769"},
                  {"label": "Jeannette", "value": "C141770"},
                  {"label": "Jefferson County", "value": "C141771"},
                  {"label": "Jefferson Hills", "value": "C141772"},
                  {"label": "Jenkintown", "value": "C141773"},
                  {"label": "Jermyn", "value": "C141774"},
                  {"label": "Jerome", "value": "C141775"},
                  {"label": "Jersey Shore", "value": "C141776"},
                  {"label": "Jessup", "value": "C141777"},
                  {"label": "Jim Thorpe", "value": "C141778"},
                  {"label": "Johnsonburg", "value": "C141779"},
                  {"label": "Johnstown", "value": "C141780"},
                  {"label": "Jonestown", "value": "C141781"},
                  {"label": "Juniata County", "value": "C141782"},
                  {"label": "Kane", "value": "C141783"},
                  {"label": "Kenhorst", "value": "C141784"},
                  {"label": "Kenilworth", "value": "C141785"},
                  {"label": "Kenmar", "value": "C141786"},
                  {"label": "Kennett Square", "value": "C141787"},
                  {"label": "King of Prussia", "value": "C141788"},
                  {"label": "Kingston", "value": "C141789"},
                  {"label": "Kittanning", "value": "C141790"},
                  {"label": "Knox", "value": "C141791"},
                  {"label": "Kulpmont", "value": "C141792"},
                  {"label": "Kulpsville", "value": "C141793"},
                  {"label": "Kutztown", "value": "C141794"},
                  {"label": "Lackawanna County", "value": "C141795"},
                  {"label": "Lafayette Hill", "value": "C141796"},
                  {"label": "Laflin", "value": "C141797"},
                  {"label": "Lake City", "value": "C141798"},
                  {"label": "Lake Heritage", "value": "C141799"},
                  {"label": "Lake Latonka", "value": "C141800"},
                  {"label": "Lake Meade", "value": "C141801"},
                  {"label": "Lake Wynonah", "value": "C141802"},
                  {"label": "Lakemont", "value": "C141803"},
                  {"label": "Lampeter", "value": "C141804"},
                  {"label": "Lancaster", "value": "C141805"},
                  {"label": "Lancaster County", "value": "C141806"},
                  {"label": "Landisville", "value": "C141807"},
                  {"label": "Langhorne", "value": "C141808"},
                  {"label": "Langhorne Manor", "value": "C141809"},
                  {"label": "Lansdale", "value": "C141810"},
                  {"label": "Lansdowne", "value": "C141811"},
                  {"label": "Lansford", "value": "C141812"},
                  {"label": "Laporte", "value": "C141813"},
                  {"label": "Larksville", "value": "C141814"},
                  {"label": "Latrobe", "value": "C141815"},
                  {"label": "Laureldale", "value": "C141816"},
                  {"label": "Laurys Station", "value": "C141817"},
                  {"label": "Lawnton", "value": "C141818"},
                  {"label": "Lawrence County", "value": "C141819"},
                  {"label": "Lawrence Park", "value": "C141820"},
                  {"label": "Lawson Heights", "value": "C141821"},
                  {"label": "Lebanon", "value": "C141822"},
                  {"label": "Lebanon County", "value": "C141823"},
                  {"label": "Lebanon South", "value": "C141824"},
                  {"label": "Leechburg", "value": "C141825"},
                  {"label": "Leesport", "value": "C141826"},
                  {"label": "Leetsdale", "value": "C141827"},
                  {"label": "Lehigh County", "value": "C141828"},
                  {"label": "Lehighton", "value": "C141829"},
                  {"label": "Leith-Hatfield", "value": "C141830"},
                  {"label": "Lemont", "value": "C141831"},
                  {"label": "Lemoyne", "value": "C141832"},
                  {"label": "Lenape Heights", "value": "C141833"},
                  {"label": "Leola", "value": "C141834"},
                  {"label": "Level Green", "value": "C141835"},
                  {"label": "Levittown", "value": "C141836"},
                  {"label": "Lewisburg", "value": "C141837"},
                  {"label": "Lewistown", "value": "C141838"},
                  {"label": "Liberty", "value": "C141839"},
                  {"label": "Light Street", "value": "C141840"},
                  {"label": "Ligonier", "value": "C141841"},
                  {"label": "Lima", "value": "C141842"},
                  {"label": "Limerick", "value": "C141843"},
                  {"label": "Lincoln", "value": "C141844"},
                  {"label": "Lincoln Park", "value": "C141845"},
                  {"label": "Linglestown", "value": "C141846"},
                  {"label": "Linntown", "value": "C141847"},
                  {"label": "Linwood", "value": "C141848"},
                  {"label": "Lionville", "value": "C141849"},
                  {"label": "Lititz", "value": "C141850"},
                  {"label": "Littlestown", "value": "C141851"},
                  {"label": "Lock Haven", "value": "C141852"},
                  {"label": "Loganville", "value": "C141853"},
                  {"label": "Lorane", "value": "C141854"},
                  {"label": "Loretto", "value": "C141855"},
                  {"label": "Lower Allen", "value": "C141856"},
                  {"label": "Lower Burrell", "value": "C141857"},
                  {"label": "Loyalhanna", "value": "C141858"},
                  {"label": "Luzerne", "value": "C141859"},
                  {"label": "Luzerne County", "value": "C141860"},
                  {"label": "Lycoming County", "value": "C141861"},
                  {"label": "Lykens", "value": "C141862"},
                  {"label": "Lynnwood-Pricedale", "value": "C141863"},
                  {"label": "Macungie", "value": "C141864"},
                  {"label": "Mahanoy City", "value": "C141865"},
                  {"label": "Malvern", "value": "C141866"},
                  {"label": "Manchester", "value": "C141867"},
                  {"label": "Manheim", "value": "C141868"},
                  {"label": "Manor", "value": "C141869"},
                  {"label": "Mansfield", "value": "C141870"},
                  {"label": "Maple Glen", "value": "C141871"},
                  {"label": "Marcus Hook", "value": "C141872"},
                  {"label": "Marianne", "value": "C141873"},
                  {"label": "Marienville", "value": "C141874"},
                  {"label": "Marietta", "value": "C141875"},
                  {"label": "Mars", "value": "C141876"},
                  {"label": "Marshallton", "value": "C141877"},
                  {"label": "Martinsburg", "value": "C141878"},
                  {"label": "Marysville", "value": "C141879"},
                  {"label": "Masontown", "value": "C141880"},
                  {"label": "Matamoras", "value": "C141881"},
                  {"label": "Mayfield", "value": "C141882"},
                  {"label": "Maytown", "value": "C141883"},
                  {"label": "McAdoo", "value": "C141884"},
                  {"label": "McConnellsburg", "value": "C141885"},
                  {"label": "McConnellstown", "value": "C141886"},
                  {"label": "McDonald", "value": "C141887"},
                  {"label": "McGovern", "value": "C141888"},
                  {"label": "McKean County", "value": "C141889"},
                  {"label": "McKees Rocks", "value": "C141890"},
                  {"label": "McKeesport", "value": "C141891"},
                  {"label": "McMurray", "value": "C141892"},
                  {"label": "McSherrystown", "value": "C141893"},
                  {"label": "Meadowood", "value": "C141894"},
                  {"label": "Meadville", "value": "C141895"},
                  {"label": "Mechanicsburg", "value": "C141896"},
                  {"label": "Mechanicsville", "value": "C141897"},
                  {"label": "Media", "value": "C141898"},
                  {"label": "Mercer", "value": "C141899"},
                  {"label": "Mercer County", "value": "C141900"},
                  {"label": "Mercersburg", "value": "C141901"},
                  {"label": "Meridian", "value": "C141902"},
                  {"label": "Meyersdale", "value": "C141903"},
                  {"label": "Middleburg", "value": "C141904"},
                  {"label": "Middletown", "value": "C141905"},
                  {"label": "Midland", "value": "C141906"},
                  {"label": "Midway", "value": "C141907"},
                  {"label": "Mifflin County", "value": "C141908"},
                  {"label": "Mifflinburg", "value": "C141909"},
                  {"label": "Mifflintown", "value": "C141910"},
                  {"label": "Mifflinville", "value": "C141911"},
                  {"label": "Milesburg", "value": "C141912"},
                  {"label": "Milford", "value": "C141913"},
                  {"label": "Mill Hall", "value": "C141914"},
                  {"label": "Millbourne", "value": "C141915"},
                  {"label": "Millersburg", "value": "C141916"},
                  {"label": "Millersville", "value": "C141917"},
                  {"label": "Millvale", "value": "C141918"},
                  {"label": "Milroy", "value": "C141919"},
                  {"label": "Milton", "value": "C141920"},
                  {"label": "Minersville", "value": "C141921"},
                  {"label": "Mohnton", "value": "C141922"},
                  {"label": "Monaca", "value": "C141923"},
                  {"label": "Monessen", "value": "C141924"},
                  {"label": "Monongahela", "value": "C141925"},
                  {"label": "Monroe County", "value": "C141926"},
                  {"label": "Monroeville", "value": "C141927"},
                  {"label": "Mont Alto", "value": "C141928"},
                  {"label": "Montgomery", "value": "C141929"},
                  {"label": "Montgomery County", "value": "C141930"},
                  {"label": "Montgomeryville", "value": "C141931"},
                  {"label": "Montour County", "value": "C141932"},
                  {"label": "Montoursville", "value": "C141933"},
                  {"label": "Montrose", "value": "C141934"},
                  {"label": "Moosic", "value": "C141935"},
                  {"label": "Morningside", "value": "C141936"},
                  {"label": "Morrisville", "value": "C141937"},
                  {"label": "Morton", "value": "C141938"},
                  {"label": "Moscow", "value": "C141939"},
                  {"label": "Mount Carmel", "value": "C141940"},
                  {"label": "Mount Cobb", "value": "C141941"},
                  {"label": "Mount Holly Springs", "value": "C141942"},
                  {"label": "Mount Joy", "value": "C141943"},
                  {"label": "Mount Lebanon", "value": "C141944"},
                  {"label": "Mount Oliver", "value": "C141945"},
                  {"label": "Mount Penn", "value": "C141946"},
                  {"label": "Mount Pleasant", "value": "C141947"},
                  {"label": "Mount Pocono", "value": "C141948"},
                  {"label": "Mount Union", "value": "C141949"},
                  {"label": "Mount Wolf", "value": "C141950"},
                  {"label": "Mountain Top", "value": "C141951"},
                  {"label": "Mountainhome", "value": "C141952"},
                  {"label": "Mountville", "value": "C141953"},
                  {"label": "Muhlenberg Park", "value": "C141954"},
                  {"label": "Muncy", "value": "C141955"},
                  {"label": "Mundys Corner", "value": "C141956"},
                  {"label": "Munhall", "value": "C141957"},
                  {"label": "Murrysville", "value": "C141958"},
                  {"label": "Muse", "value": "C141959"},
                  {"label": "Myerstown", "value": "C141960"},
                  {"label": "Nanticoke", "value": "C141961"},
                  {"label": "Nanty Glo", "value": "C141962"},
                  {"label": "Narberth", "value": "C141963"},
                  {"label": "Nazareth", "value": "C141964"},
                  {"label": "Nescopeck", "value": "C141965"},
                  {"label": "Nesquehoning", "value": "C141966"},
                  {"label": "New Beaver", "value": "C141967"},
                  {"label": "New Berlinville", "value": "C141968"},
                  {"label": "New Bloomfield", "value": "C141969"},
                  {"label": "New Brighton", "value": "C141970"},
                  {"label": "New Britain", "value": "C141971"},
                  {"label": "New Castle", "value": "C141972"},
                  {"label": "New Castle Northwest", "value": "C141973"},
                  {"label": "New Columbia", "value": "C141974"},
                  {"label": "New Cumberland", "value": "C141975"},
                  {"label": "New Eagle", "value": "C141976"},
                  {"label": "New Freedom", "value": "C141977"},
                  {"label": "New Holland", "value": "C141978"},
                  {"label": "New Hope", "value": "C141979"},
                  {"label": "New Kensington", "value": "C141980"},
                  {"label": "New Oxford", "value": "C141981"},
                  {"label": "New Philadelphia", "value": "C141982"},
                  {"label": "New Stanton", "value": "C141983"},
                  {"label": "New Wilmington", "value": "C141984"},
                  {"label": "Newmanstown", "value": "C141985"},
                  {"label": "Newport", "value": "C141986"},
                  {"label": "Newtown", "value": "C141987"},
                  {"label": "Newtown Grant", "value": "C141988"},
                  {"label": "Newville", "value": "C141989"},
                  {"label": "Nixon", "value": "C141990"},
                  {"label": "Norristown", "value": "C141991"},
                  {"label": "North Apollo", "value": "C141992"},
                  {"label": "North Belle Vernon", "value": "C141993"},
                  {"label": "North Braddock", "value": "C141994"},
                  {"label": "North Catasauqua", "value": "C141995"},
                  {"label": "North Charleroi", "value": "C141996"},
                  {"label": "North East", "value": "C141997"},
                  {"label": "North Versailles", "value": "C141998"},
                  {"label": "North Wales", "value": "C141999"},
                  {"label": "North Warren", "value": "C142000"},
                  {"label": "North York", "value": "C142001"},
                  {"label": "Northampton", "value": "C142002"},
                  {"label": "Northampton County", "value": "C142003"},
                  {"label": "Northern Cambria", "value": "C142004"},
                  {"label": "Northumberland", "value": "C142005"},
                  {"label": "Northumberland County", "value": "C142006"},
                  {"label": "Northwest Harborcreek", "value": "C142007"},
                  {"label": "Norwood", "value": "C142008"},
                  {"label": "Oak Hills", "value": "C142009"},
                  {"label": "Oakdale", "value": "C142010"},
                  {"label": "Oakland", "value": "C142011"},
                  {"label": "Oakmont", "value": "C142012"},
                  {"label": "Oakwood", "value": "C142013"},
                  {"label": "Ohioville", "value": "C142014"},
                  {"label": "Oil City", "value": "C142015"},
                  {"label": "Old Forge", "value": "C142016"},
                  {"label": "Old Orchard", "value": "C142017"},
                  {"label": "Oley", "value": "C142018"},
                  {"label": "Oliver", "value": "C142019"},
                  {"label": "Olyphant", "value": "C142020"},
                  {"label": "Orchard Hills", "value": "C142021"},
                  {"label": "Oreland", "value": "C142022"},
                  {"label": "Orwigsburg", "value": "C142023"},
                  {"label": "Osceola Mills", "value": "C142024"},
                  {"label": "Oxford", "value": "C142025"},
                  {"label": "Palmdale", "value": "C142026"},
                  {"label": "Palmer Heights", "value": "C142027"},
                  {"label": "Palmerton", "value": "C142028"},
                  {"label": "Palmyra", "value": "C142029"},
                  {"label": "Palo Alto", "value": "C142030"},
                  {"label": "Paoli", "value": "C142031"},
                  {"label": "Paradise", "value": "C142032"},
                  {"label": "Park Forest Village", "value": "C142033"},
                  {"label": "Parkesburg", "value": "C142034"},
                  {"label": "Parkside", "value": "C142035"},
                  {"label": "Parkville", "value": "C142036"},
                  {"label": "Patton", "value": "C142037"},
                  {"label": "Paxtang", "value": "C142038"},
                  {"label": "Paxtonia", "value": "C142039"},
                  {"label": "Pen Argyl", "value": "C142040"},
                  {"label": "Penbrook", "value": "C142041"},
                  {"label": "Penn Estates", "value": "C142042"},
                  {"label": "Penn Hills", "value": "C142043"},
                  {"label": "Penn Wynne", "value": "C142044"},
                  {"label": "Penndel", "value": "C142045"},
                  {"label": "Pennsburg", "value": "C142046"},
                  {"label": "Pennside", "value": "C142047"},
                  {"label": "Pennsport", "value": "C142048"},
                  {"label": "Pennville", "value": "C142049"},
                  {"label": "Penryn", "value": "C142050"},
                  {"label": "Perkasie", "value": "C142051"},
                  {"label": "Perry County", "value": "C142052"},
                  {"label": "Perryopolis", "value": "C142053"},
                  {"label": "Philadelphia", "value": "C142054"},
                  {"label": "Philadelphia County", "value": "C142055"},
                  {"label": "Philipsburg", "value": "C142056"},
                  {"label": "Phoenixville", "value": "C142057"},
                  {"label": "Pike County", "value": "C142058"},
                  {"label": "Pine Grove", "value": "C142059"},
                  {"label": "Pine Grove Mills", "value": "C142060"},
                  {"label": "Pine Ridge", "value": "C142061"},
                  {"label": "Pitcairn", "value": "C142062"},
                  {"label": "Pittsburgh", "value": "C142063"},
                  {"label": "Pittston", "value": "C142064"},
                  {"label": "Plains", "value": "C142065"},
                  {"label": "Pleasant Gap", "value": "C142066"},
                  {"label": "Pleasant Hill", "value": "C142067"},
                  {"label": "Pleasant Hills", "value": "C142068"},
                  {"label": "Plum", "value": "C142069"},
                  {"label": "Plumsteadville", "value": "C142070"},
                  {"label": "Plymouth", "value": "C142071"},
                  {"label": "Plymouth Meeting", "value": "C142072"},
                  {"label": "Pocono Pines", "value": "C142073"},
                  {"label": "Pocono Ranch Lands", "value": "C142074"},
                  {"label": "Point Marion", "value": "C142075"},
                  {"label": "Port Allegany", "value": "C142076"},
                  {"label": "Port Carbon", "value": "C142077"},
                  {"label": "Port Vue", "value": "C142078"},
                  {"label": "Portage", "value": "C142079"},
                  {"label": "Potter County", "value": "C142080"},
                  {"label": "Pottsgrove", "value": "C142081"},
                  {"label": "Pottstown", "value": "C142082"},
                  {"label": "Pottsville", "value": "C142083"},
                  {"label": "Progress", "value": "C142084"},
                  {"label": "Prospect", "value": "C142085"},
                  {"label": "Prospect Park", "value": "C142086"},
                  {"label": "Punxsutawney", "value": "C142087"},
                  {"label": "Pymatuning Central", "value": "C142088"},
                  {"label": "Quakertown", "value": "C142089"},
                  {"label": "Quarryville", "value": "C142090"},
                  {"label": "Radnor", "value": "C142091"},
                  {"label": "Rankin", "value": "C142092"},
                  {"label": "Raubsville", "value": "C142093"},
                  {"label": "Reading", "value": "C142094"},
                  {"label": "Reamstown", "value": "C142095"},
                  {"label": "Red Hill", "value": "C142096"},
                  {"label": "Red Lion", "value": "C142097"},
                  {"label": "Reiffton", "value": "C142098"},
                  {"label": "Reinholds", "value": "C142099"},
                  {"label": "Rennerdale", "value": "C142100"},
                  {"label": "Renovo", "value": "C142101"},
                  {"label": "Republic", "value": "C142102"},
                  {"label": "Reynolds Heights", "value": "C142103"},
                  {"label": "Reynoldsville", "value": "C142104"},
                  {"label": "Rheems", "value": "C142105"},
                  {"label": "Richboro", "value": "C142106"},
                  {"label": "Richland", "value": "C142107"},
                  {"label": "Richlandtown", "value": "C142108"},
                  {"label": "Ridgway", "value": "C142109"},
                  {"label": "Ridley Park", "value": "C142110"},
                  {"label": "River View Park", "value": "C142111"},
                  {"label": "Riverside", "value": "C142112"},
                  {"label": "Roaring Spring", "value": "C142113"},
                  {"label": "Robesonia", "value": "C142114"},
                  {"label": "Rochester", "value": "C142115"},
                  {"label": "Rockledge", "value": "C142116"},
                  {"label": "Roseto", "value": "C142117"},
                  {"label": "Rothsville", "value": "C142118"},
                  {"label": "Royalton", "value": "C142119"},
                  {"label": "Royersford", "value": "C142120"},
                  {"label": "Russell", "value": "C142121"},
                  {"label": "Russellton", "value": "C142122"},
                  {"label": "Rutherford", "value": "C142123"},
                  {"label": "Saint Clair", "value": "C142124"},
                  {"label": "Saint Lawrence", "value": "C142125"},
                  {"label": "Saint Marys", "value": "C142126"},
                  {"label": "Salix", "value": "C142127"},
                  {"label": "Salunga", "value": "C142128"},
                  {"label": "Sanatoga", "value": "C142129"},
                  {"label": "Sand Hill", "value": "C142130"},
                  {"label": "Sandy", "value": "C142131"},
                  {"label": "Saw Creek", "value": "C142132"},
                  {"label": "Saxonburg", "value": "C142133"},
                  {"label": "Saylorsburg", "value": "C142134"},
                  {"label": "Sayre", "value": "C142135"},
                  {"label": "Schlusser", "value": "C142136"},
                  {"label": "Schnecksville", "value": "C142137"},
                  {"label": "Schoeneck", "value": "C142138"},
                  {"label": "Schuylkill County", "value": "C142139"},
                  {"label": "Schuylkill Haven", "value": "C142140"},
                  {"label": "Schwenksville", "value": "C142141"},
                  {"label": "Scotland", "value": "C142142"},
                  {"label": "Scottdale", "value": "C142143"},
                  {"label": "Scranton", "value": "C142144"},
                  {"label": "Selinsgrove", "value": "C142145"},
                  {"label": "Sellersville", "value": "C142146"},
                  {"label": "Seneca", "value": "C142147"},
                  {"label": "Seven Fields", "value": "C142148"},
                  {"label": "Sewickley", "value": "C142149"},
                  {"label": "Shamokin", "value": "C142150"},
                  {"label": "Shamokin Dam", "value": "C142151"},
                  {"label": "Shanor-Northvue", "value": "C142152"},
                  {"label": "Sharon", "value": "C142153"},
                  {"label": "Sharon Hill", "value": "C142154"},
                  {"label": "Sharpsburg", "value": "C142155"},
                  {"label": "Sharpsville", "value": "C142156"},
                  {"label": "Shavertown", "value": "C142157"},
                  {"label": "Sheffield", "value": "C142158"},
                  {"label": "Shenandoah", "value": "C142159"},
                  {"label": "Shenandoah Heights", "value": "C142160"},
                  {"label": "Shillington", "value": "C142161"},
                  {"label": "Shiloh", "value": "C142162"},
                  {"label": "Shinglehouse", "value": "C142163"},
                  {"label": "Shippensburg", "value": "C142164"},
                  {"label": "Shiremanstown", "value": "C142165"},
                  {"label": "Shoemakersville", "value": "C142166"},
                  {"label": "Shrewsbury", "value": "C142167"},
                  {"label": "Sierra View", "value": "C142168"},
                  {"label": "Simpson", "value": "C142169"},
                  {"label": "Sinking Spring", "value": "C142170"},
                  {"label": "Skippack", "value": "C142171"},
                  {"label": "Skyline View", "value": "C142172"},
                  {"label": "Slatington", "value": "C142173"},
                  {"label": "Slippery Rock", "value": "C142174"},
                  {"label": "Smethport", "value": "C142175"},
                  {"label": "Snyder County", "value": "C142176"},
                  {"label": "Somerset", "value": "C142177"},
                  {"label": "Somerset County", "value": "C142178"},
                  {"label": "Souderton", "value": "C142179"},
                  {"label": "South Coatesville", "value": "C142180"},
                  {"label": "South Connellsville", "value": "C142181"},
                  {"label": "South Greensburg", "value": "C142182"},
                  {"label": "South Park Township", "value": "C142183"},
                  {"label": "South Pottstown", "value": "C142184"},
                  {"label": "South Temple", "value": "C142185"},
                  {"label": "South Uniontown", "value": "C142186"},
                  {"label": "South Waverly", "value": "C142187"},
                  {"label": "South Williamsport", "value": "C142188"},
                  {"label": "Southmont", "value": "C142189"},
                  {"label": "Southwest Greensburg", "value": "C142190"},
                  {"label": "Spangler", "value": "C142191"},
                  {"label": "Speers", "value": "C142192"},
                  {"label": "Spinnerstown", "value": "C142193"},
                  {"label": "Spring City", "value": "C142194"},
                  {"label": "Spring Grove", "value": "C142195"},
                  {"label": "Spring House", "value": "C142196"},
                  {"label": "Spring Mount", "value": "C142197"},
                  {"label": "Spring Ridge", "value": "C142198"},
                  {"label": "Springdale", "value": "C142199"},
                  {"label": "Springfield", "value": "C142200"},
                  {"label": "Spry", "value": "C142201"},
                  {"label": "State College", "value": "C142202"},
                  {"label": "State Line", "value": "C142203"},
                  {"label": "Steelton", "value": "C142204"},
                  {"label": "Stewartstown", "value": "C142205"},
                  {"label": "Stiles", "value": "C142206"},
                  {"label": "Stoneboro", "value": "C142207"},
                  {"label": "Stony Creek Mills", "value": "C142208"},
                  {"label": "Stonybrook", "value": "C142209"},
                  {"label": "Stormstown", "value": "C142210"},
                  {"label": "Stowe", "value": "C142211"},
                  {"label": "Strasburg", "value": "C142212"},
                  {"label": "Stroudsburg", "value": "C142213"},
                  {"label": "Sturgeon", "value": "C142214"},
                  {"label": "Sugarcreek", "value": "C142215"},
                  {"label": "Sullivan County", "value": "C142216"},
                  {"label": "Summit Hill", "value": "C142217"},
                  {"label": "Sun Valley", "value": "C142218"},
                  {"label": "Sunbury", "value": "C142219"},
                  {"label": "Sunrise Lake", "value": "C142220"},
                  {"label": "Susquehanna", "value": "C142221"},
                  {"label": "Susquehanna County", "value": "C142222"},
                  {"label": "Susquehanna Trails", "value": "C142223"},
                  {"label": "Swarthmore", "value": "C142224"},
                  {"label": "Swartzville", "value": "C142225"},
                  {"label": "Swissvale", "value": "C142226"},
                  {"label": "Swoyersville", "value": "C142227"},
                  {"label": "Sykesville", "value": "C142228"},
                  {"label": "Tacony", "value": "C142229"},
                  {"label": "Tamaqua", "value": "C142230"},
                  {"label": "Tannersville", "value": "C142231"},
                  {"label": "Tarentum", "value": "C142232"},
                  {"label": "Tatamy", "value": "C142233"},
                  {"label": "Taylor", "value": "C142234"},
                  {"label": "Telford", "value": "C142235"},
                  {"label": "Temple", "value": "C142236"},
                  {"label": "Terre Hill", "value": "C142237"},
                  {"label": "The Hideout", "value": "C142238"},
                  {"label": "Thompsonville", "value": "C142239"},
                  {"label": "Thorndale", "value": "C142240"},
                  {"label": "Throop", "value": "C142241"},
                  {"label": "Tinicum", "value": "C142242"},
                  {"label": "Tioga County", "value": "C142243"},
                  {"label": "Tionesta", "value": "C142244"},
                  {"label": "Tipton", "value": "C142245"},
                  {"label": "Titusville", "value": "C142246"},
                  {"label": "Toftrees", "value": "C142247"},
                  {"label": "Topton", "value": "C142248"},
                  {"label": "Toughkenamon", "value": "C142249"},
                  {"label": "Towamensing Trails", "value": "C142250"},
                  {"label": "Towanda", "value": "C142251"},
                  {"label": "Tower City", "value": "C142252"},
                  {"label": "Trafford", "value": "C142253"},
                  {"label": "Trainer", "value": "C142254"},
                  {"label": "Trappe", "value": "C142255"},
                  {"label": "Treasure Lake", "value": "C142256"},
                  {"label": "Tremont", "value": "C142257"},
                  {"label": "Trevorton", "value": "C142258"},
                  {"label": "Trevose", "value": "C142259"},
                  {"label": "Trexlertown", "value": "C142260"},
                  {"label": "Trooper", "value": "C142261"},
                  {"label": "Troy", "value": "C142262"},
                  {"label": "Trucksville", "value": "C142263"},
                  {"label": "Tullytown", "value": "C142264"},
                  {"label": "Tunkhannock", "value": "C142265"},
                  {"label": "Turtle Creek", "value": "C142266"},
                  {"label": "Tyrone", "value": "C142267"},
                  {"label": "Union City", "value": "C142268"},
                  {"label": "Union County", "value": "C142269"},
                  {"label": "Uniontown", "value": "C142270"},
                  {"label": "Upland", "value": "C142271"},
                  {"label": "Upper Saint Clair", "value": "C142272"},
                  {"label": "Valley Green", "value": "C142273"},
                  {"label": "Valley View", "value": "C142274"},
                  {"label": "Vandergrift", "value": "C142275"},
                  {"label": "Venango County", "value": "C142276"},
                  {"label": "Verona", "value": "C142277"},
                  {"label": "Versailles", "value": "C142278"},
                  {"label": "Village Green-Green Ridge", "value": "C142279"},
                  {"label": "Village Shires", "value": "C142280"},
                  {"label": "Vinco", "value": "C142281"},
                  {"label": "Wallenpaupack Lake Estates", "value": "C142282"},
                  {"label": "Walnutport", "value": "C142283"},
                  {"label": "Warminster Heights", "value": "C142284"},
                  {"label": "Warren", "value": "C142285"},
                  {"label": "Warren County", "value": "C142286"},
                  {"label": "Washington", "value": "C142287"},
                  {"label": "Washington County", "value": "C142288"},
                  {"label": "Waterford", "value": "C142289"},
                  {"label": "Watsontown", "value": "C142290"},
                  {"label": "Waymart", "value": "C142291"},
                  {"label": "Wayne", "value": "C142292"},
                  {"label": "Wayne County", "value": "C142293"},
                  {"label": "Wayne Heights", "value": "C142294"},
                  {"label": "Waynesboro", "value": "C142295"},
                  {"label": "Waynesburg", "value": "C142296"},
                  {"label": "Weatherly", "value": "C142297"},
                  {"label": "Weigelstown", "value": "C142298"},
                  {"label": "Weissport East", "value": "C142299"},
                  {"label": "Wellsboro", "value": "C142300"},
                  {"label": "Wernersville", "value": "C142301"},
                  {"label": "Wescosville", "value": "C142302"},
                  {"label": "Wesleyville", "value": "C142303"},
                  {"label": "West Chester", "value": "C142304"},
                  {"label": "West Conshohocken", "value": "C142305"},
                  {"label": "West Easton", "value": "C142306"},
                  {"label": "West Fairview", "value": "C142307"},
                  {"label": "West Grove", "value": "C142308"},
                  {"label": "West Hamburg", "value": "C142309"},
                  {"label": "West Hazleton", "value": "C142310"},
                  {"label": "West Hills", "value": "C142311"},
                  {"label": "West Homestead", "value": "C142312"},
                  {"label": "West Kittanning", "value": "C142313"},
                  {"label": "West Lawn", "value": "C142314"},
                  {"label": "West Leechburg", "value": "C142315"},
                  {"label": "West Mayfield", "value": "C142316"},
                  {"label": "West Mifflin", "value": "C142317"},
                  {"label": "West Newton", "value": "C142318"},
                  {"label": "West Norriton", "value": "C142319"},
                  {"label": "West Pittston", "value": "C142320"},
                  {"label": "West Reading", "value": "C142321"},
                  {"label": "West View", "value": "C142322"},
                  {"label": "West Wyoming", "value": "C142323"},
                  {"label": "West Wyomissing", "value": "C142324"},
                  {"label": "West York", "value": "C142325"},
                  {"label": "Westfield", "value": "C142326"},
                  {"label": "Westmont", "value": "C142327"},
                  {"label": "Westmoreland County", "value": "C142328"},
                  {"label": "Wharton", "value": "C142329"},
                  {"label": "Whitaker", "value": "C142330"},
                  {"label": "White Haven", "value": "C142331"},
                  {"label": "White Oak", "value": "C142332"},
                  {"label": "Whitehall", "value": "C142333"},
                  {"label": "Whitehall Township", "value": "C142334"},
                  {"label": "Whitfield", "value": "C142335"},
                  {"label": "Whitman", "value": "C142336"},
                  {"label": "Wickerham Manor-Fisher", "value": "C142337"},
                  {"label": "Wilkes-Barre", "value": "C142338"},
                  {"label": "Wilkinsburg", "value": "C142339"},
                  {"label": "Williamsburg", "value": "C142340"},
                  {"label": "Williamsport", "value": "C142341"},
                  {"label": "Williamstown", "value": "C142342"},
                  {"label": "Willow Grove", "value": "C142343"},
                  {"label": "Willow Street", "value": "C142344"},
                  {"label": "Wilmerding", "value": "C142345"},
                  {"label": "Wilson", "value": "C142346"},
                  {"label": "Wind Gap", "value": "C142347"},
                  {"label": "Windber", "value": "C142348"},
                  {"label": "Windsor", "value": "C142349"},
                  {"label": "Wolfdale", "value": "C142350"},
                  {"label": "Womelsdorf", "value": "C142351"},
                  {"label": "Woodbourne", "value": "C142352"},
                  {"label": "Woodland Heights", "value": "C142353"},
                  {"label": "Woodlyn", "value": "C142354"},
                  {"label": "Woodside", "value": "C142355"},
                  {"label": "Wormleysburg", "value": "C142356"},
                  {"label": "Woxall", "value": "C142357"},
                  {"label": "Wrightsville", "value": "C142358"},
                  {"label": "Wyncote", "value": "C142359"},
                  {"label": "Wyndmoor", "value": "C142360"},
                  {"label": "Wyoming", "value": "C142361"},
                  {"label": "Wyoming County", "value": "C142362"},
                  {"label": "Wyomissing", "value": "C142363"},
                  {"label": "Wyomissing Hills", "value": "C142364"},
                  {"label": "Yardley", "value": "C142365"},
                  {"label": "Yeadon", "value": "C142366"},
                  {"label": "Yeagertown", "value": "C142367"},
                  {"label": "Yoe", "value": "C142368"},
                  {"label": "York", "value": "C142369"},
                  {"label": "York County", "value": "C142370"},
                  {"label": "Yorklyn", "value": "C142371"},
                  {"label": "Youngsville", "value": "C142372"},
                  {"label": "Youngwood", "value": "C142373"},
                  {"label": "Zelienople", "value": "C142374"},
                  {"label": "Zion", "value": "C142375"}],
 "Penza Oblast": [{"label": "Bashmakovo", "value": "C103022"},
                  {"label": "Bekovo", "value": "C103023"},
                  {"label": "Belinskiy", "value": "C103024"},
                  {"label": "Bessonovka", "value": "C103025"},
                  {"label": "Bogoslovka", "value": "C103026"},
                  {"label": "Chaadayevka", "value": "C103027"},
                  {"label": "Chemodanovka", "value": "C103028"},
                  {"label": "Gorodishche", "value": "C103029"},
                  {"label": "Grabovo", "value": "C103030"},
                  {"label": "Inderka", "value": "C103031"},
                  {"label": "Issa", "value": "C103032"},
                  {"label": "Issinskiy Rayon", "value": "C103033"},
                  {"label": "Kanayevka", "value": "C103034"},
                  {"label": "Kolyshley", "value": "C103035"},
                  {"label": "Kondol", "value": "C103036"},
                  {"label": "Kuznetsk", "value": "C103037"},
                  {"label": "Kuznetskiy Rayon", "value": "C103038"},
                  {"label": "Lermontovo", "value": "C103039"},
                  {"label": "Lopatino", "value": "C103040"},
                  {"label": "Lunino", "value": "C103041"},
                  {"label": "Makhalino", "value": "C103042"},
                  {"label": "Malaya Serdoba", "value": "C103043"},
                  {"label": "Mokshan", "value": "C103044"},
                  {"label": "Narovchat", "value": "C103045"},
                  {"label": "Neverkino", "value": "C103046"},
                  {"label": "Neverkinskiy Rayon", "value": "C103047"},
                  {"label": "Nikolsk", "value": "C103048"},
                  {"label": "Nizhniy Lomov", "value": "C103049"},
                  {"label": "Pachelma", "value": "C103050"},
                  {"label": "Penza", "value": "C103051"},
                  {"label": "Poim", "value": "C103052"},
                  {"label": "Poselki", "value": "C103053"},
                  {"label": "Russkiy Kameshkir", "value": "C103054"},
                  {"label": "Serdobsk", "value": "C103055"},
                  {"label": "Shemysheyka", "value": "C103056"},
                  {"label": "Sosnovoborsk", "value": "C103057"},
                  {"label": "Spassk", "value": "C103058"},
                  {"label": "Srednyaya Yelyuzan", "value": "C103059"},
                  {"label": "Sura", "value": "C103060"},
                  {"label": "Sursk", "value": "C103061"},
                  {"label": "Tamala", "value": "C103062"},
                  {"label": "Vadinsk", "value": "C103063"},
                  {"label": "Verkhniy Lomov", "value": "C103064"},
                  {"label": "Verkhozim", "value": "C103065"},
                  {"label": "Zarechnyy", "value": "C103066"},
                  {"label": "Zasechnoye", "value": "C103067"},
                  {"label": "Zemetchino", "value": "C103068"}],
 "Perak": [{"label": "Bagan Serai", "value": "C65721"},
           {"label": "Batu Gajah", "value": "C65722"},
           {"label": "Bidur", "value": "C65723"},
           {"label": "Ipoh", "value": "C65724"},
           {"label": "Kampar", "value": "C65725"},
           {"label": "Kampong Dungun", "value": "C65726"},
           {"label": "Kuala Kangsar", "value": "C65727"},
           {"label": "Lumut", "value": "C65728"},
           {"label": "Pantai Remis", "value": "C65729"},
           {"label": "Parit Buntar", "value": "C65730"},
           {"label": "Simpang Empat", "value": "C65731"},
           {"label": "Taiping", "value": "C65732"},
           {"label": "Tapah Road", "value": "C65733"},
           {"label": "Teluk Intan", "value": "C65734"}],
 "Peravia Province": [{"label": "Bani", "value": "C23965"},
                      {"label": "Matanzas", "value": "C23966"},
                      {"label": "Nizao", "value": "C23967"},
                      {"label": "Paya", "value": "C23968"},
                      {"label": "Pizarrete", "value": "C23969"},
                      {"label": "Sabana Buey", "value": "C23970"}],
 "Perlis": [{"label": "Kangar", "value": "C65735"},
            {"label": "Kuala Perlis", "value": "C65736"},
            {"label": "Pantai Cenang", "value": "C65737"}],
 "Perm Krai": [{"label": "Aleksandrovsk", "value": "C103069"},
               {"label": "Barda", "value": "C103070"},
               {"label": "Berezniki", "value": "C103071"},
               {"label": "Bershet", "value": "C103072"},
               {"label": "Chastyye", "value": "C103073"},
               {"label": "Chaykovskaya", "value": "C103074"},
               {"label": "Chaykovskiy", "value": "C103075"},
               {"label": "Cherdyn", "value": "C103076"},
               {"label": "Chermoz", "value": "C103077"},
               {"label": "Chernushka", "value": "C103078"},
               {"label": "Chusovoy", "value": "C103079"},
               {"label": "Dobryanka", "value": "C103080"},
               {"label": "Ferma", "value": "C103081"},
               {"label": "Filippovka", "value": "C103082"},
               {"label": "Foki", "value": "C103083"},
               {"label": "Froly", "value": "C103084"},
               {"label": "Gamovo", "value": "C103085"},
               {"label": "Gayny", "value": "C103086"},
               {"label": "Gornozavodsk", "value": "C103087"},
               {"label": "Gremyachinsk", "value": "C103088"},
               {"label": "Gubakha", "value": "C103089"},
               {"label": "Kalino", "value": "C103090"},
               {"label": "Karagay", "value": "C103091"},
               {"label": "Kerchevskiy", "value": "C103092"},
               {"label": "Kizel", "value": "C103093"},
               {"label": "Kochevo", "value": "C103094"},
               {"label": "Komarikhinskiy", "value": "C103095"},
               {"label": "Kondratovo", "value": "C103096"},
               {"label": "Kosa", "value": "C103097"},
               {"label": "Krasnokamsk", "value": "C103098"},
               {"label": "Krasnovishersk", "value": "C103099"},
               {"label": "Kudymkar", "value": "C103100"},
               {"label": "Kukushtan", "value": "C103101"},
               {"label": "Kultayevo", "value": "C103102"},
               {"label": "Kungur", "value": "C103103"},
               {"label": "Kuyeda", "value": "C103104"},
               {"label": "Lobanovo", "value": "C103105"},
               {"label": "Lyamino", "value": "C103106"},
               {"label": "Lysva", "value": "C103107"},
               {"label": "Maykor", "value": "C103108"},
               {"label": "Mendeleyevo", "value": "C103109"},
               {"label": "Novyye Lyady", "value": "C103110"},
               {"label": "Nyrob", "value": "C103111"},
               {"label": "Nytva", "value": "C103112"},
               {"label": "Ocher", "value": "C103113"},
               {"label": "Okhansk", "value": "C103114"},
               {"label": "Orda", "value": "C103115"},
               {"label": "Osa", "value": "C103116"},
               {"label": "Overyata", "value": "C103117"},
               {"label": "Pashiya", "value": "C103118"},
               {"label": "Pavlovskiy", "value": "C103119"},
               {"label": "Perm", "value": "C103120"},
               {"label": "Polazna", "value": "C103121"},
               {"label": "Pozhva", "value": "C103122"},
               {"label": "Sarany", "value": "C103123"},
               {"label": "Sars", "value": "C103124"},
               {"label": "Severnyy-Kospashskiy", "value": "C103125"},
               {"label": "Shakhta", "value": "C103126"},
               {"label": "Shirokovskiy", "value": "C103127"},
               {"label": "Shumikhinskiy", "value": "C103128"},
               {"label": "Siva", "value": "C103129"},
               {"label": "Sokol", "value": "C103130"},
               {"label": "Solikamsk", "value": "C103131"},
               {"label": "Suksun", "value": "C103132"},
               {"label": "Sylva", "value": "C103133"},
               {"label": "Teplaya Gora", "value": "C103134"},
               {"label": "Ugleuralskiy", "value": "C103135"},
               {"label": "Uinskoye", "value": "C103136"},
               {"label": "Usolye", "value": "C103137"},
               {"label": "Ust-Kachka", "value": "C103138"},
               {"label": "Ust-Kishert", "value": "C103139"},
               {"label": "Vereshchagino", "value": "C103140"},
               {"label": "Verkhnechusovskiye Gorodki", "value": "C103141"},
               {"label": "Yayva", "value": "C103142"},
               {"label": "Yelovo", "value": "C103143"},
               {"label": "Yubileynyy", "value": "C103144"},
               {"label": "Yug", "value": "C103145"},
               {"label": "Yugo-Kamskiy", "value": "C103146"},
               {"label": "Yurla", "value": "C103147"},
               {"label": "Yusvinskiy Rayon", "value": "C103148"},
               {"label": "Yuzhnyy-Kospashskiy", "value": "C103149"},
               {"label": "Zvezdnyy", "value": "C103150"},
               {"label": "Zyukayka", "value": "C103151"}],
 "Pernambuco": [{"label": "Abreu e Lima", "value": "C13404"},
                {"label": "Afogados da Ingazeira", "value": "C13405"},
                {"label": "Afranio", "value": "C13406"},
                {"label": "Agrestina", "value": "C13407"},
                {"label": "agua Preta", "value": "C13408"},
                {"label": "aguas Belas", "value": "C13409"},
                {"label": "Alagoinha", "value": "C13410"},
                {"label": "Alianca", "value": "C13411"},
                {"label": "Altinho", "value": "C13412"},
                {"label": "Amaraji", "value": "C13413"},
                {"label": "Angelim", "value": "C13414"},
                {"label": "Aracoiaba", "value": "C13415"},
                {"label": "Araripina", "value": "C13416"},
                {"label": "Arcoverde", "value": "C13417"},
                {"label": "Barra de Guabiraba", "value": "C13418"},
                {"label": "Barreiros", "value": "C13419"},
                {"label": "Belem de Maria", "value": "C13420"},
                {"label": "Belem de Sao Francisco", "value": "C13421"},
                {"label": "Belem do Sao Francisco", "value": "C13422"},
                {"label": "Belo Jardim", "value": "C13423"},
                {"label": "Betania", "value": "C13424"},
                {"label": "Bezerros", "value": "C13425"},
                {"label": "Bodoco", "value": "C13426"},
                {"label": "Bom Conselho", "value": "C13427"},
                {"label": "Bom Jardim", "value": "C13428"},
                {"label": "Bonito", "value": "C13429"},
                {"label": "Brejao", "value": "C13430"},
                {"label": "Brejinho", "value": "C13431"},
                {"label": "Brejo da Madre de Deus", "value": "C13432"},
                {"label": "Buenos Aires", "value": "C13433"},
                {"label": "Buique", "value": "C13434"},
                {"label": "Cabo", "value": "C13435"},
                {"label": "Cabo de Santo Agostinho", "value": "C13436"},
                {"label": "Cabrobo", "value": "C13437"},
                {"label": "Cachoeirinha", "value": "C13438"},
                {"label": "Caetes", "value": "C13439"},
                {"label": "Calcado", "value": "C13440"},
                {"label": "Calumbi", "value": "C13441"},
                {"label": "Camaragibe", "value": "C13442"},
                {"label": "Camocim de Sao Felix", "value": "C13443"},
                {"label": "Camutanga", "value": "C13444"},
                {"label": "Canhotinho", "value": "C13445"},
                {"label": "Capoeiras", "value": "C13446"},
                {"label": "Carnaiba", "value": "C13447"},
                {"label": "Carnaubeira da Penha", "value": "C13448"},
                {"label": "Carpina", "value": "C13449"},
                {"label": "Caruaru", "value": "C13450"},
                {"label": "Casinhas", "value": "C13451"},
                {"label": "Catende", "value": "C13452"},
                {"label": "Cedro", "value": "C13453"},
                {"label": "Cha de Alegria", "value": "C13454"},
                {"label": "Cha Grande", "value": "C13455"},
                {"label": "Colonia Leopoldina", "value": "C13456"},
                {"label": "Condado", "value": "C13457"},
                {"label": "Correntes", "value": "C13458"},
                {"label": "Cortes", "value": "C13459"},
                {"label": "Cumaru", "value": "C13460"},
                {"label": "Cupira", "value": "C13461"},
                {"label": "Custodia", "value": "C13462"},
                {"label": "Dormentes", "value": "C13463"},
                {"label": "Escada", "value": "C13464"},
                {"label": "Exu", "value": "C13465"},
                {"label": "Feira Nova", "value": "C13466"},
                {"label": "Fernando de Noronha", "value": "C13467"},
                {"label": "Fernando de Noronha (Distrito Estadual)",
                 "value": "C13468"},
                {"label": "Ferreiros", "value": "C13469"},
                {"label": "Flores", "value": "C13470"},
                {"label": "Floresta", "value": "C13471"},
                {"label": "Frei Miguelinho", "value": "C13472"},
                {"label": "Gameleira", "value": "C13473"},
                {"label": "Garanhuns", "value": "C13474"},
                {"label": "Gloria do Goita", "value": "C13475"},
                {"label": "Goiana", "value": "C13476"},
                {"label": "Granito", "value": "C13477"},
                {"label": "Gravata", "value": "C13478"},
                {"label": "Guabiraba", "value": "C13479"},
                {"label": "Iati", "value": "C13480"},
                {"label": "Ibimirim", "value": "C13481"},
                {"label": "Ibirajuba", "value": "C13482"},
                {"label": "Igarassu", "value": "C13483"},
                {"label": "Iguaracy", "value": "C13484"},
                {"label": "Ilha de Itamaraca", "value": "C13485"},
                {"label": "Inaja", "value": "C13486"},
                {"label": "Ingazeira", "value": "C13487"},
                {"label": "Ipojuca", "value": "C13488"},
                {"label": "Ipubi", "value": "C13489"},
                {"label": "Itacuruba", "value": "C13490"},
                {"label": "Itaiba", "value": "C13491"},
                {"label": "Itamaraca", "value": "C13492"},
                {"label": "Itambe", "value": "C13493"},
                {"label": "Itapetim", "value": "C13494"},
                {"label": "Itapissuma", "value": "C13495"},
                {"label": "Itaquitinga", "value": "C13496"},
                {"label": "Jaboatao", "value": "C13497"},
                {"label": "Jaboatao dos Guararapes", "value": "C13498"},
                {"label": "Jaqueira", "value": "C13499"},
                {"label": "Jatauba", "value": "C13500"},
                {"label": "Jatoba", "value": "C13501"},
                {"label": "Joao Alfredo", "value": "C13502"},
                {"label": "Joaquim Nabuco", "value": "C13503"},
                {"label": "Jucati", "value": "C13504"},
                {"label": "Jupi", "value": "C13505"},
                {"label": "Jurema", "value": "C13506"},
                {"label": "Lagoa de Itaenga", "value": "C13507"},
                {"label": "Lagoa do Carro", "value": "C13508"},
                {"label": "Lagoa do Itaenga", "value": "C13509"},
                {"label": "Lagoa do Ouro", "value": "C13510"},
                {"label": "Lagoa dos Gatos", "value": "C13511"},
                {"label": "Lagoa Grande", "value": "C13512"},
                {"label": "Lajedo", "value": "C13513"},
                {"label": "Limoeiro", "value": "C13514"},
                {"label": "Macaparana", "value": "C13515"},
                {"label": "Machados", "value": "C13516"},
                {"label": "Manari", "value": "C13517"},
                {"label": "Maraial", "value": "C13518"},
                {"label": "Mirandiba", "value": "C13519"},
                {"label": "Moreilandia", "value": "C13520"},
                {"label": "Moreno", "value": "C13521"},
                {"label": "Nazare da Mata", "value": "C13522"},
                {"label": "Olinda", "value": "C13523"},
                {"label": "Orobo", "value": "C13524"},
                {"label": "Oroco", "value": "C13525"},
                {"label": "Ouricuri", "value": "C13526"},
                {"label": "Palmares", "value": "C13527"},
                {"label": "Palmeirina", "value": "C13528"},
                {"label": "Panelas", "value": "C13529"},
                {"label": "Paranatama", "value": "C13530"},
                {"label": "Parnamirim", "value": "C13531"},
                {"label": "Passira", "value": "C13532"},
                {"label": "Paudalho", "value": "C13533"},
                {"label": "Paulista", "value": "C13534"},
                {"label": "Pedra", "value": "C13535"},
                {"label": "Pesqueira", "value": "C13536"},
                {"label": "Petrolandia", "value": "C13537"},
                {"label": "Petrolina", "value": "C13538"},
                {"label": "Pocao", "value": "C13539"},
                {"label": "Pombos", "value": "C13540"},
                {"label": "Primavera", "value": "C13541"},
                {"label": "Quipapa", "value": "C13542"},
                {"label": "Quixaba", "value": "C13543"},
                {"label": "Recife", "value": "C13544"},
                {"label": "Riacho das Almas", "value": "C13545"},
                {"label": "Ribeirao", "value": "C13546"},
                {"label": "Rio Formoso", "value": "C13547"},
                {"label": "Saire", "value": "C13548"},
                {"label": "Salgadinho", "value": "C13549"},
                {"label": "Salgueiro", "value": "C13550"},
                {"label": "Saloa", "value": "C13551"},
                {"label": "Sanharo", "value": "C13552"},
                {"label": "Santa Cruz", "value": "C13553"},
                {"label": "Santa Cruz da Baixa Verde", "value": "C13554"},
                {"label": "Santa Cruz do Capibaribe", "value": "C13555"},
                {"label": "Santa Filomena", "value": "C13556"},
                {"label": "Santa Maria da Boa Vista", "value": "C13557"},
                {"label": "Santa Maria do Cambuca", "value": "C13558"},
                {"label": "Santa Terezinha", "value": "C13559"},
                {"label": "Sao Benedito do Sul", "value": "C13560"},
                {"label": "Sao Bento do Una", "value": "C13561"},
                {"label": "Sao Caitano", "value": "C13562"},
                {"label": "Sao Joao", "value": "C13563"},
                {"label": "Sao Joaquim do Monte", "value": "C13564"},
                {"label": "Sao Jose da Coroa Grande", "value": "C13565"},
                {"label": "Sao Jose do Belmonte", "value": "C13566"},
                {"label": "Sao Jose do Egito", "value": "C13567"},
                {"label": "Sao Lourenco da Mata", "value": "C13568"},
                {"label": "Sao Vicente Ferrer", "value": "C13569"},
                {"label": "Serra Talhada", "value": "C13570"},
                {"label": "Serrita", "value": "C13571"},
                {"label": "Sertania", "value": "C13572"},
                {"label": "Sirinhaem", "value": "C13573"},
                {"label": "Solidao", "value": "C13574"},
                {"label": "Surubim", "value": "C13575"},
                {"label": "Tabira", "value": "C13576"},
                {"label": "Tacaimbo", "value": "C13577"},
                {"label": "Tacaratu", "value": "C13578"},
                {"label": "Tamandare", "value": "C13579"},
                {"label": "Taquaritinga do Norte", "value": "C13580"},
                {"label": "Terezinha", "value": "C13581"},
                {"label": "Terra Nova", "value": "C13582"},
                {"label": "Timbauba", "value": "C13583"},
                {"label": "Toritama", "value": "C13584"},
                {"label": "Tracunhaem", "value": "C13585"},
                {"label": "Trindade", "value": "C13586"},
                {"label": "Triunfo", "value": "C13587"},
                {"label": "Tupanatinga", "value": "C13588"},
                {"label": "Tuparetama", "value": "C13589"},
                {"label": "Venturosa", "value": "C13590"},
                {"label": "Verdejante", "value": "C13591"},
                {"label": "Vertente do Lerio", "value": "C13592"},
                {"label": "Vertentes", "value": "C13593"},
                {"label": "Vicencia", "value": "C13594"},
                {"label": "Vitoria de Santo Antao", "value": "C13595"},
                {"label": "Xexeu", "value": "C13596"}],
 "Pernik Province": [{"label": "Batanovtsi", "value": "C16030"},
                     {"label": "Breznik", "value": "C16031"},
                     {"label": "Obshtina Kovachevtsi", "value": "C16032"},
                     {"label": "Obshtina Pernik", "value": "C16033"},
                     {"label": "Obshtina Radomir", "value": "C16034"},
                     {"label": "Obshtina Zemen", "value": "C16035"},
                     {"label": "Pernik", "value": "C16036"},
                     {"label": "Radomir", "value": "C16037"},
                     {"label": "Tran", "value": "C16038"},
                     {"label": "Zemen", "value": "C16039"}],
 "Pesnica Municipality": [{"label": "Pesnica pri Mariboru",
                           "value": "C107756"}],
 "Pest County": [{"label": "Abony", "value": "C44423"},
                 {"label": "Acsa", "value": "C44424"},
                 {"label": "Albertirsa", "value": "C44425"},
                 {"label": "Alsonemedi", "value": "C44426"},
                 {"label": "Aszod", "value": "C44427"},
                 {"label": "Aszodi Jaras", "value": "C44428"},
                 {"label": "Bag", "value": "C44429"},
                 {"label": "Biatorbagy", "value": "C44430"},
                 {"label": "Budakalasz", "value": "C44431"},
                 {"label": "Budakeszi", "value": "C44432"},
                 {"label": "Budakeszi Jaras", "value": "C44433"},
                 {"label": "Budaors", "value": "C44434"},
                 {"label": "Bugyi", "value": "C44435"},
                 {"label": "Cegled", "value": "C44436"},
                 {"label": "Cegledbercel", "value": "C44437"},
                 {"label": "Cegledi Jaras", "value": "C44438"},
                 {"label": "Csemo", "value": "C44439"},
                 {"label": "Csobanka", "value": "C44440"},
                 {"label": "Csomor", "value": "C44441"},
                 {"label": "Dabas", "value": "C44442"},
                 {"label": "Dabasi Jaras", "value": "C44443"},
                 {"label": "Danszentmiklos", "value": "C44444"},
                 {"label": "Dany", "value": "C44445"},
                 {"label": "Delegyhaza", "value": "C44446"},
                 {"label": "Diosd", "value": "C44447"},
                 {"label": "Domony", "value": "C44448"},
                 {"label": "Domsod", "value": "C44449"},
                 {"label": "Dunabogdany", "value": "C44450"},
                 {"label": "Dunaharaszti", "value": "C44451"},
                 {"label": "Dunakeszi", "value": "C44452"},
                 {"label": "Dunakeszi Jaras", "value": "C44453"},
                 {"label": "Dunavarsany", "value": "C44454"},
                 {"label": "Ecser", "value": "C44455"},
                 {"label": "erd", "value": "C44456"},
                 {"label": "erdi Jaras", "value": "C44457"},
                 {"label": "Erdokertes", "value": "C44458"},
                 {"label": "Farmos", "value": "C44459"},
                 {"label": "Felsopakony", "value": "C44460"},
                 {"label": "Forropuszta", "value": "C44461"},
                 {"label": "Fot", "value": "C44462"},
                 {"label": "Galgaheviz", "value": "C44463"},
                 {"label": "Galgamacsa", "value": "C44464"},
                 {"label": "God", "value": "C44465"},
                 {"label": "Godollo", "value": "C44466"},
                 {"label": "Godolloi Jaras", "value": "C44467"},
                 {"label": "Gomba", "value": "C44468"},
                 {"label": "Gyal", "value": "C44469"},
                 {"label": "Gyali Jaras", "value": "C44470"},
                 {"label": "Gyomro", "value": "C44471"},
                 {"label": "Halasztelek", "value": "C44472"},
                 {"label": "Hernad", "value": "C44473"},
                 {"label": "Hevizgyork", "value": "C44474"},
                 {"label": "Iklad", "value": "C44475"},
                 {"label": "Inarcs", "value": "C44476"},
                 {"label": "Isaszeg", "value": "C44477"},
                 {"label": "Jaszkarajeno", "value": "C44478"},
                 {"label": "Kakucs", "value": "C44479"},
                 {"label": "Kartal", "value": "C44480"},
                 {"label": "Kerepes", "value": "C44481"},
                 {"label": "Kiskunlachaza", "value": "C44482"},
                 {"label": "Kismaros", "value": "C44483"},
                 {"label": "Kistarcsa", "value": "C44484"},
                 {"label": "Kocser", "value": "C44485"},
                 {"label": "Koka", "value": "C44486"},
                 {"label": "Kosd", "value": "C44487"},
                 {"label": "Leanyfalu", "value": "C44488"},
                 {"label": "Maglod", "value": "C44489"},
                 {"label": "Mende", "value": "C44490"},
                 {"label": "Mogyorod", "value": "C44491"},
                 {"label": "Monor", "value": "C44492"},
                 {"label": "Monori Jaras", "value": "C44493"},
                 {"label": "Nagykata", "value": "C44494"},
                 {"label": "Nagykatai Jaras", "value": "C44495"},
                 {"label": "Nagykoros", "value": "C44496"},
                 {"label": "Nagykorosi Jaras", "value": "C44497"},
                 {"label": "Nagykovacsi", "value": "C44498"},
                 {"label": "Nagymaros", "value": "C44499"},
                 {"label": "Nagytarcsa", "value": "C44500"},
                 {"label": "Nyaregyhaza", "value": "C44501"},
                 {"label": "ocsa", "value": "C44502"},
                 {"label": "Orbottyan", "value": "C44503"},
                 {"label": "Orkeny", "value": "C44504"},
                 {"label": "Pand", "value": "C44505"},
                 {"label": "Paty", "value": "C44506"},
                 {"label": "Pecel", "value": "C44507"},
                 {"label": "Perbal", "value": "C44508"},
                 {"label": "Peteri", "value": "C44509"},
                 {"label": "Pilis", "value": "C44510"},
                 {"label": "Pilisborosjeno", "value": "C44511"},
                 {"label": "Piliscsaba", "value": "C44512"},
                 {"label": "Pilisszanto", "value": "C44513"},
                 {"label": "Pilisszentivan", "value": "C44514"},
                 {"label": "Pilisszentkereszt", "value": "C44515"},
                 {"label": "Pilisvorosvar", "value": "C44516"},
                 {"label": "Pilisvorosvari Jaras", "value": "C44517"},
                 {"label": "Pomaz", "value": "C44518"},
                 {"label": "Rackeve", "value": "C44519"},
                 {"label": "Rackevei Jaras", "value": "C44520"},
                 {"label": "Solymar", "value": "C44521"},
                 {"label": "Soskut", "value": "C44522"},
                 {"label": "Sulysap", "value": "C44523"},
                 {"label": "Szada", "value": "C44524"},
                 {"label": "Szazhalombatta", "value": "C44525"},
                 {"label": "Szentendre", "value": "C44526"},
                 {"label": "Szentendrei Jaras", "value": "C44527"},
                 {"label": "Szentlorinckata", "value": "C44528"},
                 {"label": "Szentmartonkata", "value": "C44529"},
                 {"label": "Szigetcsep", "value": "C44530"},
                 {"label": "Szigethalom", "value": "C44531"},
                 {"label": "Szigetszentmiklos", "value": "C44532"},
                 {"label": "Szigetszentmiklosi Jaras", "value": "C44533"},
                 {"label": "Szigetujfalu", "value": "C44534"},
                 {"label": "Szob", "value": "C44535"},
                 {"label": "Szobi Jaras", "value": "C44536"},
                 {"label": "Szod", "value": "C44537"},
                 {"label": "Szodliget", "value": "C44538"},
                 {"label": "Taborfalva", "value": "C44539"},
                 {"label": "Tahitotfalu", "value": "C44540"},
                 {"label": "Taksony", "value": "C44541"},
                 {"label": "Tapiobicske", "value": "C44542"},
                 {"label": "Tapiogyorgye", "value": "C44543"},
                 {"label": "Tapiosag", "value": "C44544"},
                 {"label": "Tapioszecso", "value": "C44545"},
                 {"label": "Tapioszele", "value": "C44546"},
                 {"label": "Tapioszentmarton", "value": "C44547"},
                 {"label": "Tapioszolos", "value": "C44548"},
                 {"label": "Tarnok", "value": "C44549"},
                 {"label": "Telki", "value": "C44550"},
                 {"label": "Toalmas", "value": "C44551"},
                 {"label": "Tokol", "value": "C44552"},
                 {"label": "Torokbalint", "value": "C44553"},
                 {"label": "Tortel", "value": "C44554"},
                 {"label": "Tura", "value": "C44555"},
                 {"label": "Ujhartyan", "value": "C44556"},
                 {"label": "Ujszilvas", "value": "C44557"},
                 {"label": "ullo", "value": "C44558"},
                 {"label": "Uri", "value": "C44559"},
                 {"label": "urom", "value": "C44560"},
                 {"label": "Vac", "value": "C44561"},
                 {"label": "Vaci Jaras", "value": "C44562"},
                 {"label": "Vacszentlaszlo", "value": "C44563"},
                 {"label": "Valko", "value": "C44564"},
                 {"label": "Vecses", "value": "C44565"},
                 {"label": "Vecsesi Jaras", "value": "C44566"},
                 {"label": "Veresegyhaz", "value": "C44567"},
                 {"label": "Veroce", "value": "C44568"},
                 {"label": "Visegrad", "value": "C44569"},
                 {"label": "Zsambek", "value": "C44570"},
                 {"label": "Zsambok", "value": "C44571"}],
 "Peten Department": [{"label": "Dolores", "value": "C42766"},
                      {"label": "Flores", "value": "C42767"},
                      {"label": "La Libertad", "value": "C42768"},
                      {"label": "Melchor de Mencos", "value": "C42769"},
                      {"label": "Municipio de Flores", "value": "C42770"},
                      {"label": "Municipio de Poptun", "value": "C42771"},
                      {"label": "Municipio de San Andres", "value": "C42772"},
                      {"label": "Municipio de San Benito", "value": "C42773"},
                      {"label": "Municipio de San Francisco",
                       "value": "C42774"},
                      {"label": "Municipio de Santa Ana", "value": "C42775"},
                      {"label": "Municipio de Sayaxche", "value": "C42776"},
                      {"label": "Poptun", "value": "C42777"},
                      {"label": "San Andres", "value": "C42778"},
                      {"label": "San Benito", "value": "C42779"},
                      {"label": "San Francisco", "value": "C42780"},
                      {"label": "San Jose", "value": "C42781"},
                      {"label": "San Luis", "value": "C42782"},
                      {"label": "Santa Ana", "value": "C42783"},
                      {"label": "Sayaxche", "value": "C42784"}],
 "Petrovec Municipality": [{"label": "Sredno Konjare", "value": "C65474"},
                           {"label": "Petrovets", "value": "C65475"}],
 "Phangnga": [{"label": "Amphoe Kapong", "value": "C119025"},
              {"label": "Amphoe Khura Buri", "value": "C119026"},
              {"label": "Amphoe Ko Yao", "value": "C119027"},
              {"label": "Amphoe Mueang Phangnga", "value": "C119028"},
              {"label": "Amphoe Takua Pa", "value": "C119029"},
              {"label": "Amphoe Takua Thung", "value": "C119030"},
              {"label": "Amphoe Thai Mueang", "value": "C119031"},
              {"label": "Amphoe Thap Put", "value": "C119032"},
              {"label": "Ban Ao Nang", "value": "C119033"},
              {"label": "Ban Khao Lak", "value": "C119034"},
              {"label": "Ban Phru Nai", "value": "C119035"},
              {"label": "Phang Nga", "value": "C119036"}],
 "Phatthalung": [{"label": "Amphoe Bang Kaeo", "value": "C119037"},
                 {"label": "Amphoe Khao Chaison", "value": "C119038"},
                 {"label": "Amphoe Khuan Khanun", "value": "C119039"},
                 {"label": "Amphoe Kong Ra", "value": "C119040"},
                 {"label": "Amphoe Mueang Phatthalung", "value": "C119041"},
                 {"label": "Amphoe Pa Bon", "value": "C119042"},
                 {"label": "Amphoe Pa Phayom", "value": "C119043"},
                 {"label": "Amphoe Pak Phayun", "value": "C119044"},
                 {"label": "Amphoe Si Banphot", "value": "C119045"},
                 {"label": "Amphoe Srinagarindra", "value": "C119046"},
                 {"label": "Amphoe Tamot", "value": "C119047"},
                 {"label": "Phatthalung", "value": "C119048"}],
 "Phayao": [{"label": "Amphoe Chiang Kham", "value": "C119049"},
            {"label": "Amphoe Chiang Muan", "value": "C119050"},
            {"label": "Amphoe Chun", "value": "C119051"},
            {"label": "Amphoe Dok Kham Tai", "value": "C119052"},
            {"label": "Amphoe Mae Chai", "value": "C119053"},
            {"label": "Amphoe Mueang Phayao", "value": "C119054"},
            {"label": "Amphoe Phu Kam Yao", "value": "C119055"},
            {"label": "Amphoe Phu Sang", "value": "C119056"},
            {"label": "Amphoe Pong", "value": "C119057"},
            {"label": "Chiang Kham", "value": "C119058"},
            {"label": "Dok Kham Tai", "value": "C119059"},
            {"label": "Mae Chai", "value": "C119060"},
            {"label": "Phayao", "value": "C119061"},
            {"label": "Pong", "value": "C119062"}],
 "Phetchabun": [{"label": "Amphoe Bueng Sam Phan", "value": "C119063"},
                {"label": "Amphoe Chon Daen", "value": "C119064"},
                {"label": "Amphoe Khao Kho", "value": "C119065"},
                {"label": "Amphoe Lom Kao", "value": "C119066"},
                {"label": "Amphoe Lom Sak", "value": "C119067"},
                {"label": "Amphoe Mueang Phetchabun", "value": "C119068"},
                {"label": "Amphoe Nam Nao", "value": "C119069"},
                {"label": "Amphoe Nong Phai", "value": "C119070"},
                {"label": "Amphoe Si Thep", "value": "C119071"},
                {"label": "Amphoe Wang Pong", "value": "C119072"},
                {"label": "Amphoe Wichian Buri", "value": "C119073"},
                {"label": "Chon Daen", "value": "C119074"},
                {"label": "Lom Sak", "value": "C119075"},
                {"label": "Nong Phai", "value": "C119076"},
                {"label": "Phetchabun", "value": "C119077"},
                {"label": "Wichian Buri", "value": "C119078"}],
 "Phetchaburi": [{"label": "Amphoe Ban Laem", "value": "C119079"},
                 {"label": "Amphoe Ban Lat", "value": "C119080"},
                 {"label": "Amphoe Cha-am", "value": "C119081"},
                 {"label": "Amphoe Kaeng Krachan", "value": "C119082"},
                 {"label": "Amphoe Khao Yoi", "value": "C119083"},
                 {"label": "Amphoe Mueang Phetchaburi", "value": "C119084"},
                 {"label": "Amphoe Nong Ya Plong", "value": "C119085"},
                 {"label": "Amphoe Tha Yang", "value": "C119086"},
                 {"label": "Cha-am", "value": "C119087"},
                 {"label": "Khao Yoi", "value": "C119088"},
                 {"label": "Phetchaburi", "value": "C119089"},
                 {"label": "Tha Yang", "value": "C119090"}],
 "Phichit": [{"label": "Amphoe Bang Mun Nak", "value": "C119091"},
             {"label": "Amphoe Bueng Na Rang", "value": "C119092"},
             {"label": "Amphoe Dong Charoen", "value": "C119093"},
             {"label": "Amphoe Mueang Phichit", "value": "C119094"},
             {"label": "Amphoe Pho Prathap Chang", "value": "C119095"},
             {"label": "Amphoe Pho Thale", "value": "C119096"},
             {"label": "Amphoe Sak Lek", "value": "C119097"},
             {"label": "Amphoe Taphan Hin", "value": "C119098"},
             {"label": "Amphoe Thap Khlo", "value": "C119099"},
             {"label": "Amphoe Wachira Barami", "value": "C119100"},
             {"label": "Amphoe Wang Sai Phun", "value": "C119101"},
             {"label": "Bang Mun Nak", "value": "C119102"},
             {"label": "Bueng Na Rang", "value": "C119103"},
             {"label": "Phichit", "value": "C119104"},
             {"label": "Taphan Hin", "value": "C119105"},
             {"label": "Thap Khlo", "value": "C119106"},
             {"label": "Wang Sai Phun", "value": "C119107"}],
 "Phitsanulok": [{"label": "Amphoe Bang Krathum", "value": "C119108"},
                 {"label": "Amphoe Bang Rakam", "value": "C119109"},
                 {"label": "Amphoe Chat Trakan", "value": "C119110"},
                 {"label": "Amphoe Mueang Phitsanulok", "value": "C119111"},
                 {"label": "Amphoe Nakhon Thai", "value": "C119112"},
                 {"label": "Amphoe Noen Maprang", "value": "C119113"},
                 {"label": "Amphoe Phrom Phiram", "value": "C119114"},
                 {"label": "Amphoe Wang Thong", "value": "C119115"},
                 {"label": "Amphoe Wat Bot", "value": "C119116"},
                 {"label": "Bang Krathum", "value": "C119117"},
                 {"label": "Bang Rakam", "value": "C119118"},
                 {"label": "Chat Trakan", "value": "C119119"},
                 {"label": "Nakhon Thai", "value": "C119120"},
                 {"label": "Phitsanulok", "value": "C119121"}],
 "Phnom Penh": [{"label": "Khan 7 Makara", "value": "C16514"},
                {"label": "Khan Chamkar Mon", "value": "C16515"},
                {"label": "Khan Dangkao", "value": "C16516"},
                {"label": "Khan Duon Penh", "value": "C16517"},
                {"label": "Khan Mean Chey", "value": "C16518"},
                {"label": "Khan Russey Keo", "value": "C16519"},
                {"label": "Khan Saen Sokh", "value": "C16520"},
                {"label": "Phnom Penh", "value": "C16521"}],
 "Phoenix Islands": [{"label": "Kanton", "value": "C64684"}],
 "Phongsaly Province": [{"label": "Phongsali", "value": "C64799"}],
 "Phra Nakhon Si Ayutthaya": [{"label": "Amphoe Ban Phraek",
                               "value": "C119122"},
                              {"label": "Amphoe Bang Ban", "value": "C119123"},
                              {"label": "Amphoe Bang Pa-in",
                               "value": "C119124"},
                              {"label": "Amphoe Bang Pahan",
                               "value": "C119125"},
                              {"label": "Amphoe Bang Sai", "value": "C119126"},
                              {"label": "Amphoe Lat Bua Luang",
                               "value": "C119127"},
                              {"label": "Amphoe Maha Rat", "value": "C119128"},
                              {"label": "Amphoe Nakhon Luang",
                               "value": "C119129"},
                              {"label": "Amphoe Phachi", "value": "C119130"},
                              {"label": "Amphoe Phak Hai", "value": "C119131"},
                              {"label": "Amphoe Phra Nakhon Si Ayutthaya",
                               "value": "C119132"},
                              {"label": "Amphoe Sena", "value": "C119133"},
                              {"label": "Amphoe Tha Ruea", "value": "C119134"},
                              {"label": "Amphoe Uthai", "value": "C119135"},
                              {"label": "Amphoe Wang Noi", "value": "C119136"},
                              {"label": "Ban Bang Kadi Pathum Thani",
                               "value": "C119137"},
                              {"label": "Bang Ban", "value": "C119138"},
                              {"label": "Bang Pa-in", "value": "C119139"},
                              {"label": "Nakhon Luang", "value": "C119140"},
                              {"label": "Phak Hai", "value": "C119141"},
                              {"label": "Phra Nakhon Si Ayutthaya",
                               "value": "C119142"},
                              {"label": "Tha Ruea", "value": "C119143"},
                              {"label": "Wang Noi", "value": "C119144"}],
 "Phrae": [{"label": "Amphoe Den Chai", "value": "C119145"},
           {"label": "Amphoe Long", "value": "C119146"},
           {"label": "Amphoe Mueang Phrae", "value": "C119147"},
           {"label": "Amphoe Nong Muang Khai", "value": "C119148"},
           {"label": "Amphoe Rong Kwang", "value": "C119149"},
           {"label": "Amphoe Song", "value": "C119150"},
           {"label": "Amphoe Sung Men", "value": "C119151"},
           {"label": "Amphoe Wang Chin", "value": "C119152"},
           {"label": "Den Chai", "value": "C119153"},
           {"label": "Long", "value": "C119154"},
           {"label": "Phrae", "value": "C119155"},
           {"label": "Rong Kwang", "value": "C119156"}],
 "Phu Tho": [{"label": "Huyen Cam Khe", "value": "C147556"},
             {"label": "Huyen Doan Hung", "value": "C147557"},
             {"label": "Huyen Ha Hoa", "value": "C147558"},
             {"label": "Huyen Lam Thao", "value": "C147559"},
             {"label": "Huyen Thanh Ba", "value": "C147560"},
             {"label": "Huyen Yen Lap", "value": "C147561"},
             {"label": "Thanh Pho Viet Tri", "value": "C147562"},
             {"label": "Thi xa Phu Tho", "value": "C147563"},
             {"label": "Viet Tri", "value": "C147564"}],
 "Phu Yen": [{"label": "Huyen Son Hoa", "value": "C147565"},
             {"label": "Huyen Song Hinh", "value": "C147566"},
             {"label": "Song Cau", "value": "C147567"},
             {"label": "Tuy Hoa", "value": "C147568"}],
 "Phuket": [{"label": "Amphoe Kathu", "value": "C119157"},
            {"label": "Amphoe Mueang Phuket", "value": "C119158"},
            {"label": "Amphoe Thalang", "value": "C119159"},
            {"label": "Ban Chalong", "value": "C119160"},
            {"label": "Ban Karon", "value": "C119161"},
            {"label": "Ban Kata", "value": "C119162"},
            {"label": "Ban Ko Kaeo", "value": "C119163"},
            {"label": "Ban Mai Khao", "value": "C119164"},
            {"label": "Ban Ratsada", "value": "C119165"},
            {"label": "Ban Talat Nua", "value": "C119166"},
            {"label": "Ban Talat Yai", "value": "C119167"},
            {"label": "Kathu", "value": "C119168"},
            {"label": "Nai Harn", "value": "C119169"},
            {"label": "Patong", "value": "C119170"},
            {"label": "Phuket", "value": "C119171"},
            {"label": "Rawai", "value": "C119172"},
            {"label": "Wichit", "value": "C119173"}],
 "Piaui": [{"label": "Acaua", "value": "C13597"},
           {"label": "Agricolandia", "value": "C13598"},
           {"label": "agua Branca", "value": "C13599"},
           {"label": "Alagoinha do Piaui", "value": "C13600"},
           {"label": "Alegrete do Piaui", "value": "C13601"},
           {"label": "Alto Longa", "value": "C13602"},
           {"label": "Altos", "value": "C13603"},
           {"label": "Alvorada do Gurgueia", "value": "C13604"},
           {"label": "Amarante", "value": "C13605"},
           {"label": "Angical do Piaui", "value": "C13606"},
           {"label": "Anisio de Abreu", "value": "C13607"},
           {"label": "Antonio Almeida", "value": "C13608"},
           {"label": "Aroazes", "value": "C13609"},
           {"label": "Aroeiras do Itaim", "value": "C13610"},
           {"label": "Arraial", "value": "C13611"},
           {"label": "Assuncao do Piaui", "value": "C13612"},
           {"label": "Avelino Lopes", "value": "C13613"},
           {"label": "Baixa Grande do Ribeiro", "value": "C13614"},
           {"label": "Barra dAlcantara", "value": "C13615"},
           {"label": "Barras", "value": "C13616"},
           {"label": "Barreiras do Piaui", "value": "C13617"},
           {"label": "Barro Duro", "value": "C13618"},
           {"label": "Batalha", "value": "C13619"},
           {"label": "Bela Vista do Piaui", "value": "C13620"},
           {"label": "Belem do Piaui", "value": "C13621"},
           {"label": "Beneditinos", "value": "C13622"},
           {"label": "Bertolinia", "value": "C13623"},
           {"label": "Betania do Piaui", "value": "C13624"},
           {"label": "Boa Hora", "value": "C13625"},
           {"label": "Bocaina", "value": "C13626"},
           {"label": "Bom Jesus", "value": "C13627"},
           {"label": "Bom Principio do Piaui", "value": "C13628"},
           {"label": "Bonfim do Piaui", "value": "C13629"},
           {"label": "Boqueirao do Piaui", "value": "C13630"},
           {"label": "Brasileira", "value": "C13631"},
           {"label": "Brejo do Piaui", "value": "C13632"},
           {"label": "Buriti dos Lopes", "value": "C13633"},
           {"label": "Buriti dos Montes", "value": "C13634"},
           {"label": "Cabeceiras do Piaui", "value": "C13635"},
           {"label": "Cajazeiras do Piaui", "value": "C13636"},
           {"label": "Cajueiro da Praia", "value": "C13637"},
           {"label": "Caldeirao Grande do Piaui", "value": "C13638"},
           {"label": "Campinas do Piaui", "value": "C13639"},
           {"label": "Campo Alegre do Fidalgo", "value": "C13640"},
           {"label": "Campo Grande do Piaui", "value": "C13641"},
           {"label": "Campo Largo do Piaui", "value": "C13642"},
           {"label": "Campo Maior", "value": "C13643"},
           {"label": "Canavieira", "value": "C13644"},
           {"label": "Canto do Buriti", "value": "C13645"},
           {"label": "Capitao de Campos", "value": "C13646"},
           {"label": "Capitao Gervasio Oliveira", "value": "C13647"},
           {"label": "Caracol", "value": "C13648"},
           {"label": "Caraubas do Piaui", "value": "C13649"},
           {"label": "Caridade do Piaui", "value": "C13650"},
           {"label": "Castelo do Piaui", "value": "C13651"},
           {"label": "Caxingo", "value": "C13652"},
           {"label": "Cocal", "value": "C13653"},
           {"label": "Cocal de Telha", "value": "C13654"},
           {"label": "Cocal dos Alves", "value": "C13655"},
           {"label": "Coivaras", "value": "C13656"},
           {"label": "Colonia do Gurgueia", "value": "C13657"},
           {"label": "Colonia do Piaui", "value": "C13658"},
           {"label": "Conceicao do Caninde", "value": "C13659"},
           {"label": "Coronel Jose Dias", "value": "C13660"},
           {"label": "Corrente", "value": "C13661"},
           {"label": "Cristalandia do Piaui", "value": "C13662"},
           {"label": "Cristino Castro", "value": "C13663"},
           {"label": "Curimata", "value": "C13664"},
           {"label": "Currais", "value": "C13665"},
           {"label": "Curral Novo do Piaui", "value": "C13666"},
           {"label": "Curralinhos", "value": "C13667"},
           {"label": "Demerval Lobao", "value": "C13668"},
           {"label": "Dirceu Arcoverde", "value": "C13669"},
           {"label": "Dom Expedito Lopes", "value": "C13670"},
           {"label": "Dom Inocencio", "value": "C13671"},
           {"label": "Domingos Mourao", "value": "C13672"},
           {"label": "Elesbao Veloso", "value": "C13673"},
           {"label": "Eliseu Martins", "value": "C13674"},
           {"label": "Esperantina", "value": "C13675"},
           {"label": "Fartura do Piaui", "value": "C13676"},
           {"label": "Flores do Piaui", "value": "C13677"},
           {"label": "Floresta do Piaui", "value": "C13678"},
           {"label": "Floriano", "value": "C13679"},
           {"label": "Francinopolis", "value": "C13680"},
           {"label": "Francisco Ayres", "value": "C13681"},
           {"label": "Francisco Macedo", "value": "C13682"},
           {"label": "Francisco Santos", "value": "C13683"},
           {"label": "Fronteiras", "value": "C13684"},
           {"label": "Geminiano", "value": "C13685"},
           {"label": "Gilbues", "value": "C13686"},
           {"label": "Guadalupe", "value": "C13687"},
           {"label": "Guaribas", "value": "C13688"},
           {"label": "Hugo Napoleao", "value": "C13689"},
           {"label": "Ilha Grande", "value": "C13690"},
           {"label": "Inhuma", "value": "C13691"},
           {"label": "Ipiranga do Piaui", "value": "C13692"},
           {"label": "Ipueiras", "value": "C13693"},
           {"label": "Isaias Coelho", "value": "C13694"},
           {"label": "Itainopolis", "value": "C13695"},
           {"label": "Itaueira", "value": "C13696"},
           {"label": "Jacobina do Piaui", "value": "C13697"},
           {"label": "Jaicos", "value": "C13698"},
           {"label": "Jardim do Mulato", "value": "C13699"},
           {"label": "Jatoba do Piaui", "value": "C13700"},
           {"label": "Jerumenha", "value": "C13701"},
           {"label": "Joao Costa", "value": "C13702"},
           {"label": "Joaquim Pires", "value": "C13703"},
           {"label": "Joca Marques", "value": "C13704"},
           {"label": "Jose de Freitas", "value": "C13705"},
           {"label": "Juazeiro do Piaui", "value": "C13706"},
           {"label": "Julio Borges", "value": "C13707"},
           {"label": "Jurema", "value": "C13708"},
           {"label": "Lagoa Alegre", "value": "C13709"},
           {"label": "Lagoa de Sao Francisco", "value": "C13710"},
           {"label": "Lagoa do Barro do Piaui", "value": "C13711"},
           {"label": "Lagoa do Piaui", "value": "C13712"},
           {"label": "Lagoa do Sitio", "value": "C13713"},
           {"label": "Lagoinha do Piaui", "value": "C13714"},
           {"label": "Landri Sales", "value": "C13715"},
           {"label": "Luis Correia", "value": "C13716"},
           {"label": "Luzilandia", "value": "C13717"},
           {"label": "Madeiro", "value": "C13718"},
           {"label": "Manoel Emidio", "value": "C13719"},
           {"label": "Marcolandia", "value": "C13720"},
           {"label": "Marcos Parente", "value": "C13721"},
           {"label": "Massape do Piaui", "value": "C13722"},
           {"label": "Matias Olimpio", "value": "C13723"},
           {"label": "Miguel Alves", "value": "C13724"},
           {"label": "Miguel Leao", "value": "C13725"},
           {"label": "Milton Brandao", "value": "C13726"},
           {"label": "Monsenhor Gil", "value": "C13727"},
           {"label": "Monsenhor Hipolito", "value": "C13728"},
           {"label": "Monte Alegre do Piaui", "value": "C13729"},
           {"label": "Morro Cabeca no Tempo", "value": "C13730"},
           {"label": "Morro do Chapeu do Piaui", "value": "C13731"},
           {"label": "Murici dos Portelas", "value": "C13732"},
           {"label": "Nazare do Piaui", "value": "C13733"},
           {"label": "Nazaria", "value": "C13734"},
           {"label": "Nossa Senhora de Nazare", "value": "C13735"},
           {"label": "Nossa Senhora dos Remedios", "value": "C13736"},
           {"label": "Nova Santa Rita", "value": "C13737"},
           {"label": "Novo Oriente do Piaui", "value": "C13738"},
           {"label": "Novo Santo Antonio", "value": "C13739"},
           {"label": "Oeiras", "value": "C13740"},
           {"label": "Olho dagua do Piaui", "value": "C13741"},
           {"label": "Padre Marcos", "value": "C13742"},
           {"label": "Paes Landim", "value": "C13743"},
           {"label": "Pajeu do Piaui", "value": "C13744"},
           {"label": "Palmeira do Piaui", "value": "C13745"},
           {"label": "Palmeirais", "value": "C13746"},
           {"label": "Paqueta", "value": "C13747"},
           {"label": "Parnagua", "value": "C13748"},
           {"label": "Parnaiba", "value": "C13749"},
           {"label": "Passagem Franca do Piaui", "value": "C13750"},
           {"label": "Patos do Piaui", "value": "C13751"},
           {"label": "Pau Darco do Piaui", "value": "C13752"},
           {"label": "Paulistana", "value": "C13753"},
           {"label": "Pavussu", "value": "C13754"},
           {"label": "Pedro II", "value": "C13755"},
           {"label": "Pedro Laurentino", "value": "C13756"},
           {"label": "Picos", "value": "C13757"},
           {"label": "Pimenteiras", "value": "C13758"},
           {"label": "Pio IX", "value": "C13759"},
           {"label": "Piracuruca", "value": "C13760"},
           {"label": "Piripiri", "value": "C13761"},
           {"label": "Porto", "value": "C13762"},
           {"label": "Porto Alegre do Piaui", "value": "C13763"},
           {"label": "Prata do Piaui", "value": "C13764"},
           {"label": "Queimada Nova", "value": "C13765"},
           {"label": "Redencao do Gurgueia", "value": "C13766"},
           {"label": "Regeneracao", "value": "C13767"},
           {"label": "Riacho Frio", "value": "C13768"},
           {"label": "Ribeira do Piaui", "value": "C13769"},
           {"label": "Ribeiro Goncalves", "value": "C13770"},
           {"label": "Rio Grande do Piaui", "value": "C13771"},
           {"label": "Santa Cruz do Piaui", "value": "C13772"},
           {"label": "Santa Cruz dos Milagres", "value": "C13773"},
           {"label": "Santa Filomena", "value": "C13774"},
           {"label": "Santa Luz", "value": "C13775"},
           {"label": "Santa Rosa do Piaui", "value": "C13776"},
           {"label": "Santana do Piaui", "value": "C13777"},
           {"label": "Santo Antonio de Lisboa", "value": "C13778"},
           {"label": "Santo Antonio dos Milagres", "value": "C13779"},
           {"label": "Santo Inacio do Piaui", "value": "C13780"},
           {"label": "Sao Braz do Piaui", "value": "C13781"},
           {"label": "Sao Felix do Piaui", "value": "C13782"},
           {"label": "Sao Francisco de Assis do Piaui", "value": "C13783"},
           {"label": "Sao Francisco do Piaui", "value": "C13784"},
           {"label": "Sao Goncalo do Gurgueia", "value": "C13785"},
           {"label": "Sao Goncalo do Piaui", "value": "C13786"},
           {"label": "Sao Joao da Canabrava", "value": "C13787"},
           {"label": "Sao Joao da Fronteira", "value": "C13788"},
           {"label": "Sao Joao da Serra", "value": "C13789"},
           {"label": "Sao Joao da Varjota", "value": "C13790"},
           {"label": "Sao Joao do Arraial", "value": "C13791"},
           {"label": "Sao Joao do Piaui", "value": "C13792"},
           {"label": "Sao Jose do Divino", "value": "C13793"},
           {"label": "Sao Jose do Peixe", "value": "C13794"},
           {"label": "Sao Jose do Piaui", "value": "C13795"},
           {"label": "Sao Juliao", "value": "C13796"},
           {"label": "Sao Lourenco do Piaui", "value": "C13797"},
           {"label": "Sao Luis do Piaui", "value": "C13798"},
           {"label": "Sao Miguel da Baixa Grande", "value": "C13799"},
           {"label": "Sao Miguel do Fidalgo", "value": "C13800"},
           {"label": "Sao Miguel do Tapuio", "value": "C13801"},
           {"label": "Sao Pedro do Piaui", "value": "C13802"},
           {"label": "Sao Raimundo Nonato", "value": "C13803"},
           {"label": "Sebastiao Barros", "value": "C13804"},
           {"label": "Sebastiao Leal", "value": "C13805"},
           {"label": "Sigefredo Pacheco", "value": "C13806"},
           {"label": "Simoes", "value": "C13807"},
           {"label": "Simplicio Mendes", "value": "C13808"},
           {"label": "Socorro do Piaui", "value": "C13809"},
           {"label": "Sussuapara", "value": "C13810"},
           {"label": "Tamboril do Piaui", "value": "C13811"},
           {"label": "Tanque do Piaui", "value": "C13812"},
           {"label": "Teresina", "value": "C13813"},
           {"label": "Uniao", "value": "C13814"},
           {"label": "Urucui", "value": "C13815"},
           {"label": "Valenca do Piaui", "value": "C13816"},
           {"label": "Varzea Branca", "value": "C13817"},
           {"label": "Varzea Grande", "value": "C13818"},
           {"label": "Vera Mendes", "value": "C13819"},
           {"label": "Vila Nova do Piaui", "value": "C13820"},
           {"label": "Wall Ferraz", "value": "C13821"}],
 "Pichincha": [{"label": "Cayambe", "value": "C24191"},
               {"label": "Machachi", "value": "C24192"},
               {"label": "Quito", "value": "C24193"},
               {"label": "Sangolqui", "value": "C24194"},
               {"label": "Tutamandahostel", "value": "C24195"}],
 "Piedmont": [{"label": "Acceglio", "value": "C57660"},
              {"label": "Acqui Terme", "value": "C57661"},
              {"label": "Agliano", "value": "C57662"},
              {"label": "Agliano Terme", "value": "C57663"},
              {"label": "Aglie", "value": "C57664"},
              {"label": "Agrate", "value": "C57665"},
              {"label": "Agrate Conturbia", "value": "C57666"},
              {"label": "Ailoche", "value": "C57667"},
              {"label": "Airali", "value": "C57668"},
              {"label": "Airasca", "value": "C57669"},
              {"label": "Aisone", "value": "C57670"},
              {"label": "Ala di Stura", "value": "C57671"},
              {"label": "Alagna Valsesia", "value": "C57672"},
              {"label": "Alba", "value": "C57673"},
              {"label": "Albano Vercellese", "value": "C57674"},
              {"label": "Albaretto della Torre", "value": "C57675"},
              {"label": "Albera Ligure", "value": "C57676"},
              {"label": "Albiano dIvrea", "value": "C57677"},
              {"label": "Albugnano", "value": "C57678"},
              {"label": "Alessandria", "value": "C57679"},
              {"label": "Alfiano Natta", "value": "C57680"},
              {"label": "Alice Bel Colle", "value": "C57681"},
              {"label": "Alice Castello", "value": "C57682"},
              {"label": "Alice Superiore", "value": "C57683"},
              {"label": "Almese-Rivera", "value": "C57684"},
              {"label": "Alpette", "value": "C57685"},
              {"label": "Alpignano", "value": "C57686"},
              {"label": "Altavilla Monferrato", "value": "C57687"},
              {"label": "Alto", "value": "C57688"},
              {"label": "Alzano Scrivia", "value": "C57689"},
              {"label": "Ameno", "value": "C57690"},
              {"label": "Andezeno", "value": "C57691"},
              {"label": "Andorno Cacciorna", "value": "C57692"},
              {"label": "Andrate", "value": "C57693"},
              {"label": "Angrogna", "value": "C57694"},
              {"label": "Antignano", "value": "C57695"},
              {"label": "Antronapiana", "value": "C57696"},
              {"label": "Anzola dOssola", "value": "C57697"},
              {"label": "Aramengo", "value": "C57698"},
              {"label": "Arborio", "value": "C57699"},
              {"label": "Arguello", "value": "C57700"},
              {"label": "Arignano", "value": "C57701"},
              {"label": "Arizzano", "value": "C57702"},
              {"label": "Armeno", "value": "C57703"},
              {"label": "Arola", "value": "C57704"},
              {"label": "Arona", "value": "C57705"},
              {"label": "Arquata Scrivia", "value": "C57706"},
              {"label": "Asigliano Vercellese", "value": "C57707"},
              {"label": "Asti", "value": "C57708"},
              {"label": "Aurano", "value": "C57709"},
              {"label": "Avigliana", "value": "C57710"},
              {"label": "Avolasca", "value": "C57711"},
              {"label": "Azeglio", "value": "C57712"},
              {"label": "Azzano dAsti", "value": "C57713"},
              {"label": "Baceno", "value": "C57714"},
              {"label": "Bagnasco", "value": "C57715"},
              {"label": "Bagnolo Piemonte", "value": "C57716"},
              {"label": "Bairo", "value": "C57717"},
              {"label": "Balangero", "value": "C57718"},
              {"label": "Baldichieri dAsti", "value": "C57719"},
              {"label": "Baldissero Canavese", "value": "C57720"},
              {"label": "Baldissero dAlba", "value": "C57721"},
              {"label": "Baldissero Torinese", "value": "C57722"},
              {"label": "Balme", "value": "C57723"},
              {"label": "Balmuccia", "value": "C57724"},
              {"label": "Balocco", "value": "C57725"},
              {"label": "Balzola", "value": "C57726"},
              {"label": "Banchette", "value": "C57727"},
              {"label": "Bandito", "value": "C57728"},
              {"label": "Bannio", "value": "C57729"},
              {"label": "Baratte", "value": "C57730"},
              {"label": "Barbania", "value": "C57731"},
              {"label": "Barbaresco", "value": "C57732"},
              {"label": "Bardonecchia", "value": "C57733"},
              {"label": "Barengo", "value": "C57734"},
              {"label": "Barge", "value": "C57735"},
              {"label": "Barolo", "value": "C57736"},
              {"label": "Barone Canavese", "value": "C57737"},
              {"label": "Barquedo", "value": "C57738"},
              {"label": "Basaluzzo", "value": "C57739"},
              {"label": "Bassignana", "value": "C57740"},
              {"label": "Bastia Mondovi", "value": "C57741"},
              {"label": "Battifollo", "value": "C57742"},
              {"label": "Baveno", "value": "C57743"},
              {"label": "Bee", "value": "C57744"},
              {"label": "Beinasco", "value": "C57745"},
              {"label": "Beinette", "value": "C57746"},
              {"label": "Belforte Monferrato", "value": "C57747"},
              {"label": "Belgirate", "value": "C57748"},
              {"label": "Bellinzago Novarese", "value": "C57749"},
              {"label": "Belvedere Langhe", "value": "C57750"},
              {"label": "Belveglio", "value": "C57751"},
              {"label": "Bene Vagienna", "value": "C57752"},
              {"label": "Benevello", "value": "C57753"},
              {"label": "Benna", "value": "C57754"},
              {"label": "Bergamasco", "value": "C57755"},
              {"label": "Bergolo", "value": "C57756"},
              {"label": "Bernezzo", "value": "C57757"},
              {"label": "Bersezio", "value": "C57758"},
              {"label": "Berzano di San Pietro", "value": "C57759"},
              {"label": "Berzano di Tortona", "value": "C57760"},
              {"label": "Beura", "value": "C57761"},
              {"label": "Biandrate", "value": "C57762"},
              {"label": "Bianze", "value": "C57763"},
              {"label": "Bibiana", "value": "C57764"},
              {"label": "Biella", "value": "C57765"},
              {"label": "Bioglio-Portula-Andre", "value": "C57766"},
              {"label": "Bistagno", "value": "C57767"},
              {"label": "Bobbio Pellice", "value": "C57768"},
              {"label": "Boca", "value": "C57769"},
              {"label": "Boccioleto", "value": "C57770"},
              {"label": "Bogogno", "value": "C57771"},
              {"label": "Bollengo", "value": "C57772"},
              {"label": "Bolzano Novarese", "value": "C57773"},
              {"label": "Bonvicino", "value": "C57774"},
              {"label": "Borgaro Torinese", "value": "C57775"},
              {"label": "Borghetto di Borbera", "value": "C57776"},
              {"label": "Borgiallo", "value": "C57777"},
              {"label": "Borgo", "value": "C57778"},
              {"label": "Borgo dAle", "value": "C57779"},
              {"label": "Borgo Melano", "value": "C57780"},
              {"label": "Borgo San Dalmazzo", "value": "C57781"},
              {"label": "Borgo San Martino", "value": "C57782"},
              {"label": "Borgo Ticino", "value": "C57783"},
              {"label": "Borgo Vercelli", "value": "C57784"},
              {"label": "Borgofranco dIvrea", "value": "C57785"},
              {"label": "Borgolavezzaro", "value": "C57786"},
              {"label": "Borgomale", "value": "C57787"},
              {"label": "Borgomanero", "value": "C57788"},
              {"label": "Borgomasino", "value": "C57789"},
              {"label": "Borgone Susa", "value": "C57790"},
              {"label": "Borgoratto Alessandrino", "value": "C57791"},
              {"label": "Borgosesia", "value": "C57792"},
              {"label": "Borriana", "value": "C57793"},
              {"label": "Bosco Marengo", "value": "C57794"},
              {"label": "Bosconero", "value": "C57795"},
              {"label": "Bosia", "value": "C57796"},
              {"label": "Bosio", "value": "C57797"},
              {"label": "Bossolasco", "value": "C57798"},
              {"label": "Boves", "value": "C57799"},
              {"label": "Bozzole", "value": "C57800"},
              {"label": "Bra", "value": "C57801"},
              {"label": "Brandizzo", "value": "C57802"},
              {"label": "Breia", "value": "C57803"},
              {"label": "Breo", "value": "C57804"},
              {"label": "Briaglia", "value": "C57805"},
              {"label": "Bricco di Neive", "value": "C57806"},
              {"label": "Bricherasio", "value": "C57807"},
              {"label": "Briga Alta", "value": "C57808"},
              {"label": "Briga Novarese", "value": "C57809"},
              {"label": "Brignano", "value": "C57810"},
              {"label": "Briona", "value": "C57811"},
              {"label": "Brondello", "value": "C57812"},
              {"label": "Brossasco", "value": "C57813"},
              {"label": "Brosso", "value": "C57814"},
              {"label": "Brovello", "value": "C57815"},
              {"label": "Brovello-Carpugnino", "value": "C57816"},
              {"label": "Brozolo", "value": "C57817"},
              {"label": "Bruino", "value": "C57818"},
              {"label": "Bruno", "value": "C57819"},
              {"label": "Brusasco", "value": "C57820"},
              {"label": "Brusnengo", "value": "C57821"},
              {"label": "Bruzolo", "value": "C57822"},
              {"label": "Bubbio", "value": "C57823"},
              {"label": "Buriasco", "value": "C57824"},
              {"label": "Burolo", "value": "C57825"},
              {"label": "Buronzo", "value": "C57826"},
              {"label": "Busano", "value": "C57827"},
              {"label": "Busca", "value": "C57828"},
              {"label": "Bussoleno", "value": "C57829"},
              {"label": "Buttigliera Alta", "value": "C57830"},
              {"label": "Buttigliera dAsti", "value": "C57831"},
              {"label": "Cabella Ligure", "value": "C57832"},
              {"label": "Cafasse", "value": "C57833"},
              {"label": "Calamandrana", "value": "C57834"},
              {"label": "Calasca-Castiglione", "value": "C57835"},
              {"label": "Callabiana - Chiesa", "value": "C57836"},
              {"label": "Calliano", "value": "C57837"},
              {"label": "Calosso", "value": "C57838"},
              {"label": "Caltignaga", "value": "C57839"},
              {"label": "Caluso", "value": "C57840"},
              {"label": "Camagna Monferrato", "value": "C57841"},
              {"label": "Camandona", "value": "C57842"},
              {"label": "Cambiano", "value": "C57843"},
              {"label": "Cambiasca", "value": "C57844"},
              {"label": "Camburzano", "value": "C57845"},
              {"label": "Camerana", "value": "C57846"},
              {"label": "Camerano", "value": "C57847"},
              {"label": "Camerano Casasco", "value": "C57848"},
              {"label": "Cameri", "value": "C57849"},
              {"label": "Camino", "value": "C57850"},
              {"label": "Camo", "value": "C57851"},
              {"label": "Campertogno", "value": "C57852"},
              {"label": "Campiglia Cervo", "value": "C57853"},
              {"label": "Campiglione", "value": "C57854"},
              {"label": "Campomolino", "value": "C57855"},
              {"label": "Canale", "value": "C57856"},
              {"label": "Candelo", "value": "C57857"},
              {"label": "Candia Canavese", "value": "C57858"},
              {"label": "Candiolo", "value": "C57859"},
              {"label": "Canelli", "value": "C57860"},
              {"label": "Canischio", "value": "C57861"},
              {"label": "Cannero Riviera", "value": "C57862"},
              {"label": "Cannobio", "value": "C57863"},
              {"label": "Canosio", "value": "C57864"},
              {"label": "Cantalupa", "value": "C57865"},
              {"label": "Cantalupo Ligure", "value": "C57866"},
              {"label": "Cantarana", "value": "C57867"},
              {"label": "Cantoira", "value": "C57868"},
              {"label": "Caprauna", "value": "C57869"},
              {"label": "Caprezzo", "value": "C57870"},
              {"label": "Capriata dOrba", "value": "C57871"},
              {"label": "Caprie", "value": "C57872"},
              {"label": "Capriglio", "value": "C57873"},
              {"label": "Caraglio", "value": "C57874"},
              {"label": "Caramagna Piemonte", "value": "C57875"},
              {"label": "Caravino", "value": "C57876"},
              {"label": "Carbonara Scrivia", "value": "C57877"},
              {"label": "Carcoforo", "value": "C57878"},
              {"label": "Carde", "value": "C57879"},
              {"label": "Carema", "value": "C57880"},
              {"label": "Carentino", "value": "C57881"},
              {"label": "Caresana", "value": "C57882"},
              {"label": "Caresanablot", "value": "C57883"},
              {"label": "Carezzano Maggiore", "value": "C57884"},
              {"label": "Carignano", "value": "C57885"},
              {"label": "Carisio", "value": "C57886"},
              {"label": "Carmagnola", "value": "C57887"},
              {"label": "Carpeneto", "value": "C57888"},
              {"label": "Carpignano Sesia", "value": "C57889"},
              {"label": "Carrega Ligure", "value": "C57890"},
              {"label": "Carrosio", "value": "C57891"},
              {"label": "Carru", "value": "C57892"},
              {"label": "Cartignano", "value": "C57893"},
              {"label": "Cartosio", "value": "C57894"},
              {"label": "Casal Cermelli", "value": "C57895"},
              {"label": "Casalbeltrame", "value": "C57896"},
              {"label": "Casalborgone", "value": "C57897"},
              {"label": "Casale Corte Cerro", "value": "C57898"},
              {"label": "Casale Monferrato", "value": "C57899"},
              {"label": "Casaleggio Boiro", "value": "C57900"},
              {"label": "Casaleggio Novara", "value": "C57901"},
              {"label": "Casalgrasso", "value": "C57902"},
              {"label": "Casalino", "value": "C57903"},
              {"label": "Casalnoceto", "value": "C57904"},
              {"label": "Casalvolone", "value": "C57905"},
              {"label": "Casanova Elvo", "value": "C57906"},
              {"label": "Casapinta", "value": "C57907"},
              {"label": "Casasco", "value": "C57908"},
              {"label": "Cascinette dIvrea", "value": "C57909"},
              {"label": "Caselette", "value": "C57910"},
              {"label": "Caselle Torinese", "value": "C57911"},
              {"label": "Casorzo", "value": "C57912"},
              {"label": "Cassano Spinola", "value": "C57913"},
              {"label": "Cassinasco", "value": "C57914"},
              {"label": "Cassine", "value": "C57915"},
              {"label": "Cassinelle-Concentrico", "value": "C57916"},
              {"label": "Castagneto Po", "value": "C57917"},
              {"label": "Castagnito", "value": "C57918"},
              {"label": "Castagnole delle Lanze", "value": "C57919"},
              {"label": "Castagnole Monferrato", "value": "C57920"},
              {"label": "Castagnole Piemonte", "value": "C57921"},
              {"label": "Castel Boglione", "value": "C57922"},
              {"label": "Castel Rocchero", "value": "C57923"},
              {"label": "Castelceriolo", "value": "C57924"},
              {"label": "Casteldelfino", "value": "C57925"},
              {"label": "CastellAlfero", "value": "C57926"},
              {"label": "Castellamonte", "value": "C57927"},
              {"label": "Castellania", "value": "C57928"},
              {"label": "Castellar", "value": "C57929"},
              {"label": "Castellar Guidobono", "value": "C57930"},
              {"label": "Castellazzo Bormida", "value": "C57931"},
              {"label": "Castellazzo Novarese", "value": "C57932"},
              {"label": "Castellero", "value": "C57933"},
              {"label": "Castelletto Cervo", "value": "C57934"},
              {"label": "Castelletto dErro", "value": "C57935"},
              {"label": "Castelletto dOrba", "value": "C57936"},
              {"label": "Castelletto Merli", "value": "C57937"},
              {"label": "Castelletto Molina", "value": "C57938"},
              {"label": "Castelletto Monferrato", "value": "C57939"},
              {"label": "Castelletto Sopra Ticino", "value": "C57940"},
              {"label": "Castelletto Stura", "value": "C57941"},
              {"label": "Castelletto Uzzone", "value": "C57942"},
              {"label": "Castellinaldo", "value": "C57943"},
              {"label": "Castellino Tanaro", "value": "C57944"},
              {"label": "Castello di Annone", "value": "C57945"},
              {"label": "Castelnuovo Belbo", "value": "C57946"},
              {"label": "Castelnuovo Bormida", "value": "C57947"},
              {"label": "Castelnuovo Calcea", "value": "C57948"},
              {"label": "Castelnuovo di Ceva", "value": "C57949"},
              {"label": "Castelnuovo Don Bosco", "value": "C57950"},
              {"label": "Castelnuovo Nigra", "value": "C57951"},
              {"label": "Castelnuovo Scrivia", "value": "C57952"},
              {"label": "Castelspina", "value": "C57953"},
              {"label": "Castiglione Falletto", "value": "C57954"},
              {"label": "Castiglione Tinella", "value": "C57955"},
              {"label": "Castiglione Torinese", "value": "C57956"},
              {"label": "Castino", "value": "C57957"},
              {"label": "Cavaglia", "value": "C57958"},
              {"label": "Cavaglietto", "value": "C57959"},
              {"label": "Cavaglio DAgogna", "value": "C57960"},
              {"label": "Cavaglio-Spoccia", "value": "C57961"},
              {"label": "Cavagnolo", "value": "C57962"},
              {"label": "Cavallerleone", "value": "C57963"},
              {"label": "Cavallermaggiore", "value": "C57964"},
              {"label": "Cavallirio", "value": "C57965"},
              {"label": "Cavatore", "value": "C57966"},
              {"label": "Cavour", "value": "C57967"},
              {"label": "Cella Monte", "value": "C57968"},
              {"label": "Cellarengo", "value": "C57969"},
              {"label": "Celle Enomondo", "value": "C57970"},
              {"label": "Cellio", "value": "C57971"},
              {"label": "Centallo", "value": "C57972"},
              {"label": "Ceppo Morelli", "value": "C57973"},
              {"label": "Cerano", "value": "C57974"},
              {"label": "Cercenasco", "value": "C57975"},
              {"label": "Ceres", "value": "C57976"},
              {"label": "Ceresane-Curanuova", "value": "C57977"},
              {"label": "Cereseto", "value": "C57978"},
              {"label": "Ceresole Alba", "value": "C57979"},
              {"label": "Ceresole Reale", "value": "C57980"},
              {"label": "Cerreto Castello", "value": "C57981"},
              {"label": "Cerreto dAsti", "value": "C57982"},
              {"label": "Cerreto Grue", "value": "C57983"},
              {"label": "Cerreto Langhe", "value": "C57984"},
              {"label": "Cerrina", "value": "C57985"},
              {"label": "Cerrione", "value": "C57986"},
              {"label": "Cerro Tanaro", "value": "C57987"},
              {"label": "Cervasca", "value": "C57988"},
              {"label": "Cervatto", "value": "C57989"},
              {"label": "Cervere", "value": "C57990"},
              {"label": "Cesana Torinese", "value": "C57991"},
              {"label": "Cesara", "value": "C57992"},
              {"label": "Cessole", "value": "C57993"},
              {"label": "Ceva", "value": "C57994"},
              {"label": "Cherasco", "value": "C57995"},
              {"label": "Chialamberto", "value": "C57996"},
              {"label": "Chianocco", "value": "C57997"},
              {"label": "Chiaverano", "value": "C57998"},
              {"label": "Chieri", "value": "C57999"},
              {"label": "Chiesa di Macra", "value": "C58000"},
              {"label": "Chiesanuova", "value": "C58001"},
              {"label": "Chiomonte", "value": "C58002"},
              {"label": "Chiusa di Pesio", "value": "C58003"},
              {"label": "Chiusa di San Michele", "value": "C58004"},
              {"label": "Chiusano dAsti", "value": "C58005"},
              {"label": "Chivasso", "value": "C58006"},
              {"label": "Ciconio", "value": "C58007"},
              {"label": "Cigliano", "value": "C58008"},
              {"label": "Ciglie", "value": "C58009"},
              {"label": "Cinaglio", "value": "C58010"},
              {"label": "Cintano", "value": "C58011"},
              {"label": "Cinzano", "value": "C58012"},
              {"label": "Cirie", "value": "C58013"},
              {"label": "Cissone", "value": "C58014"},
              {"label": "Cisterna dAsti", "value": "C58015"},
              {"label": "Civiasco", "value": "C58016"},
              {"label": "Clavesana", "value": "C58017"},
              {"label": "Claviere", "value": "C58018"},
              {"label": "Coassolo", "value": "C58019"},
              {"label": "Coassolo Torinese", "value": "C58020"},
              {"label": "Coazze", "value": "C58021"},
              {"label": "Coazzolo", "value": "C58022"},
              {"label": "Cocconato", "value": "C58023"},
              {"label": "Coggiola", "value": "C58024"},
              {"label": "Colazza", "value": "C58025"},
              {"label": "Collegno", "value": "C58026"},
              {"label": "Colleretto Castelnuovo", "value": "C58027"},
              {"label": "Colleretto Giacosa", "value": "C58028"},
              {"label": "Collobiano", "value": "C58029"},
              {"label": "Comignago", "value": "C58030"},
              {"label": "Condove", "value": "C58031"},
              {"label": "Coniolo Bricco", "value": "C58032"},
              {"label": "Conzano", "value": "C58033"},
              {"label": "Corio", "value": "C58034"},
              {"label": "Corneliano dAlba", "value": "C58035"},
              {"label": "Corsione", "value": "C58036"},
              {"label": "Cortandone", "value": "C58037"},
              {"label": "Cortanze", "value": "C58038"},
              {"label": "Cortazzone", "value": "C58039"},
              {"label": "Cortemilia", "value": "C58040"},
              {"label": "Cortiglione", "value": "C58041"},
              {"label": "Cossano Belbo", "value": "C58042"},
              {"label": "Cossano Canavese", "value": "C58043"},
              {"label": "Cossato", "value": "C58044"},
              {"label": "Cossogno", "value": "C58045"},
              {"label": "Cossombrato", "value": "C58046"},
              {"label": "Costa Vescovato", "value": "C58047"},
              {"label": "Costanzana", "value": "C58048"},
              {"label": "Costigliole dAsti", "value": "C58049"},
              {"label": "Costiglione Saluzzo", "value": "C58050"},
              {"label": "Crava", "value": "C58051"},
              {"label": "Cravagliana", "value": "C58052"},
              {"label": "Cravanzana", "value": "C58053"},
              {"label": "Craveggia", "value": "C58054"},
              {"label": "Cremolino", "value": "C58055"},
              {"label": "Crescentino", "value": "C58056"},
              {"label": "Cressa", "value": "C58057"},
              {"label": "Crevacuore", "value": "C58058"},
              {"label": "Crevoladossola", "value": "C58059"},
              {"label": "Crissolo", "value": "C58060"},
              {"label": "Crodo", "value": "C58061"},
              {"label": "Crova", "value": "C58062"},
              {"label": "Cuccaro Monferrato", "value": "C58063"},
              {"label": "Cuceglio", "value": "C58064"},
              {"label": "Cumiana", "value": "C58065"},
              {"label": "Cuneo", "value": "C58066"},
              {"label": "Cunico", "value": "C58067"},
              {"label": "Cuorgne", "value": "C58068"},
              {"label": "Cureggio", "value": "C58069"},
              {"label": "Curino", "value": "C58070"},
              {"label": "Cursolo", "value": "C58071"},
              {"label": "Demonte", "value": "C58072"},
              {"label": "Denice", "value": "C58073"},
              {"label": "Dernice", "value": "C58074"},
              {"label": "Desana", "value": "C58075"},
              {"label": "Diano dAlba", "value": "C58076"},
              {"label": "Divignano", "value": "C58077"},
              {"label": "Dogliani", "value": "C58078"},
              {"label": "Domodossola", "value": "C58079"},
              {"label": "Donato", "value": "C58080"},
              {"label": "Dormelletto", "value": "C58081"},
              {"label": "Dorzano", "value": "C58082"},
              {"label": "Dronero", "value": "C58083"},
              {"label": "Drubiaglio-Grangia", "value": "C58084"},
              {"label": "Druento", "value": "C58085"},
              {"label": "Druogno", "value": "C58086"},
              {"label": "Dusino", "value": "C58087"},
              {"label": "Entracque", "value": "C58088"},
              {"label": "Envie", "value": "C58089"},
              {"label": "Exilles", "value": "C58090"},
              {"label": "Falmenta", "value": "C58091"},
              {"label": "Fara Novarese", "value": "C58092"},
              {"label": "Farigliano", "value": "C58093"},
              {"label": "Faule", "value": "C58094"},
              {"label": "Favari-Avatanei", "value": "C58095"},
              {"label": "Favria", "value": "C58096"},
              {"label": "Feisoglio", "value": "C58097"},
              {"label": "Feletto", "value": "C58098"},
              {"label": "Felizzano", "value": "C58099"},
              {"label": "Fenestrelle", "value": "C58100"},
              {"label": "Ferrere", "value": "C58101"},
              {"label": "Ferriera", "value": "C58102"},
              {"label": "Fiano", "value": "C58103"},
              {"label": "Fiorano Canavese", "value": "C58104"},
              {"label": "Fleccia-Chianavasso", "value": "C58105"},
              {"label": "Fobello", "value": "C58106"},
              {"label": "Foglizzo", "value": "C58107"},
              {"label": "Fontanella-Ozino", "value": "C58108"},
              {"label": "Fontaneto DAgogna", "value": "C58109"},
              {"label": "Fontanetto Po", "value": "C58110"},
              {"label": "Fontanile", "value": "C58111"},
              {"label": "Formazza", "value": "C58112"},
              {"label": "Formigliana", "value": "C58113"},
              {"label": "Fornaci", "value": "C58114"},
              {"label": "Forno Canavese", "value": "C58115"},
              {"label": "Fossano", "value": "C58116"},
              {"label": "Frabosa Soprana", "value": "C58117"},
              {"label": "Frabosa Sottana", "value": "C58118"},
              {"label": "Fraconalto", "value": "C58119"},
              {"label": "Francavilla Bisio", "value": "C58120"},
              {"label": "Frascaro", "value": "C58121"},
              {"label": "Frassinello Monferrato", "value": "C58122"},
              {"label": "Frassineto Po", "value": "C58123"},
              {"label": "Frassinetto", "value": "C58124"},
              {"label": "Frassino", "value": "C58125"},
              {"label": "Frazione Chiesa", "value": "C58126"},
              {"label": "Fresonara", "value": "C58127"},
              {"label": "Frinco", "value": "C58128"},
              {"label": "Front", "value": "C58129"},
              {"label": "Frossasco", "value": "C58130"},
              {"label": "Frugarolo", "value": "C58131"},
              {"label": "Fubine", "value": "C58132"},
              {"label": "Gabbio-Cereda-Ramate", "value": "C58133"},
              {"label": "Gabiano", "value": "C58134"},
              {"label": "Gaglianico", "value": "C58135"},
              {"label": "Gaiola", "value": "C58136"},
              {"label": "Galliate", "value": "C58137"},
              {"label": "Gamalero", "value": "C58138"},
              {"label": "Gambasca", "value": "C58139"},
              {"label": "Garadassi", "value": "C58140"},
              {"label": "Garbagna", "value": "C58141"},
              {"label": "Garbagna Novarese", "value": "C58142"},
              {"label": "Garessio", "value": "C58143"},
              {"label": "Gargallo", "value": "C58144"},
              {"label": "Garino", "value": "C58145"},
              {"label": "Garzigliana", "value": "C58146"},
              {"label": "Gassino Torinese", "value": "C58147"},
              {"label": "Gattico", "value": "C58148"},
              {"label": "Gattinara", "value": "C58149"},
              {"label": "Gavazzana", "value": "C58150"},
              {"label": "Gavi", "value": "C58151"},
              {"label": "Genola", "value": "C58152"},
              {"label": "Gerbido", "value": "C58153"},
              {"label": "Gerbole", "value": "C58154"},
              {"label": "Gerbole-Zucche", "value": "C58155"},
              {"label": "Germagnano", "value": "C58156"},
              {"label": "Germagno", "value": "C58157"},
              {"label": "Ghemme", "value": "C58158"},
              {"label": "Ghiare-Madonna", "value": "C58159"},
              {"label": "Ghiffa", "value": "C58160"},
              {"label": "Ghislarengo", "value": "C58161"},
              {"label": "Giaglione", "value": "C58162"},
              {"label": "Giarole", "value": "C58163"},
              {"label": "Giaveno", "value": "C58164"},
              {"label": "Gifflenga", "value": "C58165"},
              {"label": "Gignese", "value": "C58166"},
              {"label": "Givoletto", "value": "C58167"},
              {"label": "Gonte", "value": "C58168"},
              {"label": "Gorzegno", "value": "C58169"},
              {"label": "Gottasecca", "value": "C58170"},
              {"label": "Govone", "value": "C58171"},
              {"label": "Gozzano", "value": "C58172"},
              {"label": "Graglia", "value": "C58173"},
              {"label": "Grana", "value": "C58174"},
              {"label": "Granozzo con Monticello", "value": "C58175"},
              {"label": "Gravellona Toce", "value": "C58176"},
              {"label": "Gravere", "value": "C58177"},
              {"label": "Grazzano Badoglio", "value": "C58178"},
              {"label": "Greggio", "value": "C58179"},
              {"label": "Gremiasco", "value": "C58180"},
              {"label": "Grignasco", "value": "C58181"},
              {"label": "Grinzane Cavour", "value": "C58182"},
              {"label": "Grognardo", "value": "C58183"},
              {"label": "Grondona", "value": "C58184"},
              {"label": "Groscavallo", "value": "C58185"},
              {"label": "Grosso", "value": "C58186"},
              {"label": "Grugliasco", "value": "C58187"},
              {"label": "Guardabosone", "value": "C58188"},
              {"label": "Guarene", "value": "C58189"},
              {"label": "Guazzora", "value": "C58190"},
              {"label": "Gurro", "value": "C58191"},
              {"label": "Igliano", "value": "C58192"},
              {"label": "Incisa Scapaccino", "value": "C58193"},
              {"label": "Ingria", "value": "C58194"},
              {"label": "Intragna", "value": "C58195"},
              {"label": "Inverso Pinasca", "value": "C58196"},
              {"label": "Invorio", "value": "C58197"},
              {"label": "Isasca", "value": "C58198"},
              {"label": "Isola dAsti", "value": "C58199"},
              {"label": "Isola SantAntonio", "value": "C58200"},
              {"label": "Isolabella", "value": "C58201"},
              {"label": "Issiglio", "value": "C58202"},
              {"label": "Ivrea", "value": "C58203"},
              {"label": "La Cassa", "value": "C58204"},
              {"label": "La Loggia", "value": "C58205"},
              {"label": "La Morra", "value": "C58206"},
              {"label": "La Villa", "value": "C58207"},
              {"label": "Lagnasco", "value": "C58208"},
              {"label": "Lamporo", "value": "C58209"},
              {"label": "Landiona", "value": "C58210"},
              {"label": "Lanvario", "value": "C58211"},
              {"label": "Lanzo Torinese", "value": "C58212"},
              {"label": "Lauriano", "value": "C58213"},
              {"label": "Leini", "value": "C58214"},
              {"label": "Lemie", "value": "C58215"},
              {"label": "Lenta", "value": "C58216"},
              {"label": "Lequio Berria", "value": "C58217"},
              {"label": "Lequio Tanaro", "value": "C58218"},
              {"label": "Lerma", "value": "C58219"},
              {"label": "Lesa", "value": "C58220"},
              {"label": "Lesegno", "value": "C58221"},
              {"label": "Lesna", "value": "C58222"},
              {"label": "Lessolo", "value": "C58223"},
              {"label": "Lessona", "value": "C58224"},
              {"label": "Levice", "value": "C58225"},
              {"label": "Levone", "value": "C58226"},
              {"label": "Lignana", "value": "C58227"},
              {"label": "Limone Piemonte", "value": "C58228"},
              {"label": "Lisio", "value": "C58229"},
              {"label": "Litta Parodi-Cascinagrossa", "value": "C58230"},
              {"label": "Livera", "value": "C58231"},
              {"label": "Livorno Ferraris", "value": "C58232"},
              {"label": "Loazzolo", "value": "C58233"},
              {"label": "Locana", "value": "C58234"},
              {"label": "Lombardore", "value": "C58235"},
              {"label": "Lombriasco", "value": "C58236"},
              {"label": "Loranze", "value": "C58237"},
              {"label": "Loreglia", "value": "C58238"},
              {"label": "Lozzolo", "value": "C58239"},
              {"label": "Lu", "value": "C58240"},
              {"label": "Lugnacco", "value": "C58241"},
              {"label": "Lumellogno", "value": "C58242"},
              {"label": "Luserna", "value": "C58243"},
              {"label": "Lusernetta", "value": "C58244"},
              {"label": "Lusiglie", "value": "C58245"},
              {"label": "Macello", "value": "C58246"},
              {"label": "Macra", "value": "C58247"},
              {"label": "Macugnaga", "value": "C58248"},
              {"label": "Madonna del Sasso", "value": "C58249"},
              {"label": "Maggiora", "value": "C58250"},
              {"label": "Magliano Alfieri", "value": "C58251"},
              {"label": "Magliano Alpi", "value": "C58252"},
              {"label": "Maglione", "value": "C58253"},
              {"label": "Maglione-Crosa", "value": "C58254"},
              {"label": "Magnano", "value": "C58255"},
              {"label": "Malesco", "value": "C58256"},
              {"label": "Malvicino", "value": "C58257"},
              {"label": "Mandello Vitta", "value": "C58258"},
              {"label": "Mandrogne", "value": "C58259"},
              {"label": "Mango", "value": "C58260"},
              {"label": "Manta", "value": "C58261"},
              {"label": "Mappano", "value": "C58262"},
              {"label": "Marano Ticino", "value": "C58263"},
              {"label": "Maranzana", "value": "C58264"},
              {"label": "Marene", "value": "C58265"},
              {"label": "Marentino", "value": "C58266"},
              {"label": "Maretto", "value": "C58267"},
              {"label": "Margarita", "value": "C58268"},
              {"label": "Marmora", "value": "C58269"},
              {"label": "Marocchi", "value": "C58270"},
              {"label": "Marsaglia", "value": "C58271"},
              {"label": "Martiniana Po", "value": "C58272"},
              {"label": "Masera", "value": "C58273"},
              {"label": "Masio", "value": "C58274"},
              {"label": "Massazza", "value": "C58275"},
              {"label": "Massello", "value": "C58276"},
              {"label": "Masserano", "value": "C58277"},
              {"label": "Massino Visconti", "value": "C58278"},
              {"label": "Massiola", "value": "C58279"},
              {"label": "Mathi", "value": "C58280"},
              {"label": "Mattie", "value": "C58281"},
              {"label": "Mazze", "value": "C58282"},
              {"label": "Meana di Susa", "value": "C58283"},
              {"label": "Meina", "value": "C58284"},
              {"label": "Melazzo", "value": "C58285"},
              {"label": "Melle", "value": "C58286"},
              {"label": "Merana", "value": "C58287"},
              {"label": "Mercenasco", "value": "C58288"},
              {"label": "Mergozzo", "value": "C58289"},
              {"label": "Meugliano", "value": "C58290"},
              {"label": "Mezzana Mortigliengo", "value": "C58291"},
              {"label": "Mezzenile", "value": "C58292"},
              {"label": "Mezzomerico", "value": "C58293"},
              {"label": "Miagliano", "value": "C58294"},
              {"label": "Miasino", "value": "C58295"},
              {"label": "Miazzina", "value": "C58296"},
              {"label": "Milanere", "value": "C58297"},
              {"label": "Mirabello Monferrato", "value": "C58298"},
              {"label": "Moasca", "value": "C58299"},
              {"label": "Moiola", "value": "C58300"},
              {"label": "Molare", "value": "C58301"},
              {"label": "Molino dei Torti", "value": "C58302"},
              {"label": "Mollia", "value": "C58303"},
              {"label": "Mombaldone", "value": "C58304"},
              {"label": "Mombarcaro", "value": "C58305"},
              {"label": "Mombaruzzo", "value": "C58306"},
              {"label": "Mombasiglio", "value": "C58307"},
              {"label": "Mombello di Torino", "value": "C58308"},
              {"label": "Mombello Monferrato", "value": "C58309"},
              {"label": "Mombercelli", "value": "C58310"},
              {"label": "Momo", "value": "C58311"},
              {"label": "Mompantero", "value": "C58312"},
              {"label": "Momperone", "value": "C58313"},
              {"label": "Monale", "value": "C58314"},
              {"label": "Monastero Bormida", "value": "C58315"},
              {"label": "Monastero di Lanzo", "value": "C58316"},
              {"label": "Monastero di Vasco", "value": "C58317"},
              {"label": "Monasterolo", "value": "C58318"},
              {"label": "Monasterolo Casotto", "value": "C58319"},
              {"label": "Monasterolo di Savigliano", "value": "C58320"},
              {"label": "Moncalieri", "value": "C58321"},
              {"label": "Moncalvo", "value": "C58322"},
              {"label": "Moncenisio", "value": "C58323"},
              {"label": "Moncestino", "value": "C58324"},
              {"label": "Monchiero Borgonuovo", "value": "C58325"},
              {"label": "Moncrivello", "value": "C58326"},
              {"label": "Moncucco Torinese", "value": "C58327"},
              {"label": "Mondovi", "value": "C58328"},
              {"label": "Monesiglio", "value": "C58329"},
              {"label": "Monforte dAlba", "value": "C58330"},
              {"label": "Mongardino", "value": "C58331"},
              {"label": "Mongiardino Ligure", "value": "C58332"},
              {"label": "Mongrando", "value": "C58333"},
              {"label": "Monleale", "value": "C58334"},
              {"label": "Monta", "value": "C58335"},
              {"label": "Montabone", "value": "C58336"},
              {"label": "Montacuto", "value": "C58337"},
              {"label": "Montafia", "value": "C58338"},
              {"label": "Montaldeo", "value": "C58339"},
              {"label": "Montaldo Bormida", "value": "C58340"},
              {"label": "Montaldo di Mondovi", "value": "C58341"},
              {"label": "Montaldo Roero", "value": "C58342"},
              {"label": "Montaldo Scarampi", "value": "C58343"},
              {"label": "Montaldo Torinese", "value": "C58344"},
              {"label": "Montalenghe", "value": "C58345"},
              {"label": "Montalto Dora", "value": "C58346"},
              {"label": "Montanaro", "value": "C58347"},
              {"label": "Montanera", "value": "C58348"},
              {"label": "Montariolo", "value": "C58349"},
              {"label": "Montecastello", "value": "C58350"},
              {"label": "Montechiaro dAcqui", "value": "C58351"},
              {"label": "Montechiaro dAsti", "value": "C58352"},
              {"label": "Montecrestese", "value": "C58353"},
              {"label": "Montegioco", "value": "C58354"},
              {"label": "Montegrosso", "value": "C58355"},
              {"label": "Montelera", "value": "C58356"},
              {"label": "Montelupo Albese", "value": "C58357"},
              {"label": "Montemagno", "value": "C58358"},
              {"label": "Montemale di Cuneo", "value": "C58359"},
              {"label": "Montemarzino", "value": "C58360"},
              {"label": "Monterosso Grana", "value": "C58361"},
              {"label": "Montescheno", "value": "C58362"},
              {"label": "Monteu da Po", "value": "C58363"},
              {"label": "Monteu Roero", "value": "C58364"},
              {"label": "Montezemolo", "value": "C58365"},
              {"label": "Monticello dAlba", "value": "C58366"},
              {"label": "Montiglio", "value": "C58367"},
              {"label": "Morano sul Po", "value": "C58368"},
              {"label": "Moransengo", "value": "C58369"},
              {"label": "Morbello", "value": "C58370"},
              {"label": "Moretta", "value": "C58371"},
              {"label": "Moriondo Torinese", "value": "C58372"},
              {"label": "Mornese", "value": "C58373"},
              {"label": "Morozzo", "value": "C58374"},
              {"label": "Morsasco", "value": "C58375"},
              {"label": "Mosso", "value": "C58376"},
              {"label": "Mosso Santa Maria", "value": "C58377"},
              {"label": "Motta De Conti", "value": "C58378"},
              {"label": "Mottalciata", "value": "C58379"},
              {"label": "Murazzano", "value": "C58380"},
              {"label": "Murello", "value": "C58381"},
              {"label": "Murisengo", "value": "C58382"},
              {"label": "Mussotto", "value": "C58383"},
              {"label": "Muzzano", "value": "C58384"},
              {"label": "Narzole", "value": "C58385"},
              {"label": "Nebbiuno", "value": "C58386"},
              {"label": "Neive-Borgonovo", "value": "C58387"},
              {"label": "Netro", "value": "C58388"},
              {"label": "Neviglie", "value": "C58389"},
              {"label": "Nibbiola", "value": "C58390"},
              {"label": "Nichelino", "value": "C58391"},
              {"label": "Niella Belbo", "value": "C58392"},
              {"label": "Niella Tanaro", "value": "C58393"},
              {"label": "Nizza Monferrato", "value": "C58394"},
              {"label": "Noasca", "value": "C58395"},
              {"label": "Nole", "value": "C58396"},
              {"label": "Nomaglio", "value": "C58397"},
              {"label": "None", "value": "C58398"},
              {"label": "Nonio", "value": "C58399"},
              {"label": "Novalesa", "value": "C58400"},
              {"label": "Novara", "value": "C58401"},
              {"label": "Novello", "value": "C58402"},
              {"label": "Novi Ligure", "value": "C58403"},
              {"label": "Nucetto", "value": "C58404"},
              {"label": "Occhieppo Inferiore", "value": "C58405"},
              {"label": "Occhieppo Superiore", "value": "C58406"},
              {"label": "Occimiano", "value": "C58407"},
              {"label": "Odalengo Grande", "value": "C58408"},
              {"label": "Odalengo Piccolo", "value": "C58409"},
              {"label": "Oggebbio", "value": "C58410"},
              {"label": "Oglianico", "value": "C58411"},
              {"label": "Olcenengo", "value": "C58412"},
              {"label": "Oldenico", "value": "C58413"},
              {"label": "Oleggio", "value": "C58414"},
              {"label": "Oleggio Castello", "value": "C58415"},
              {"label": "Olivola", "value": "C58416"},
              {"label": "Olmo Gentile", "value": "C58417"},
              {"label": "Omegna", "value": "C58418"},
              {"label": "Oncino", "value": "C58419"},
              {"label": "Orbassano", "value": "C58420"},
              {"label": "Orio Canavese", "value": "C58421"},
              {"label": "Ormea", "value": "C58422"},
              {"label": "Ornavasso", "value": "C58423"},
              {"label": "Orsara Bormida", "value": "C58424"},
              {"label": "Orta San Giulio", "value": "C58425"},
              {"label": "Osasco", "value": "C58426"},
              {"label": "Osasio", "value": "C58427"},
              {"label": "Ostana", "value": "C58428"},
              {"label": "Ottiglio", "value": "C58429"},
              {"label": "Oulx", "value": "C58430"},
              {"label": "Ovada", "value": "C58431"},
              {"label": "Oviglio", "value": "C58432"},
              {"label": "Ozegna", "value": "C58433"},
              {"label": "Ozzano Monferrato", "value": "C58434"},
              {"label": "Paderna", "value": "C58435"},
              {"label": "Paesana", "value": "C58436"},
              {"label": "Pagno", "value": "C58437"},
              {"label": "Palazzo Canavese", "value": "C58438"},
              {"label": "Palazzolo Vercellese", "value": "C58439"},
              {"label": "Pallanza-Intra-Suna", "value": "C58440"},
              {"label": "Pallanzeno", "value": "C58441"},
              {"label": "Pamparato", "value": "C58442"},
              {"label": "Pancalieri", "value": "C58443"},
              {"label": "Parella", "value": "C58444"},
              {"label": "Pareto", "value": "C58445"},
              {"label": "Parodi Ligure", "value": "C58446"},
              {"label": "Paroldo", "value": "C58447"},
              {"label": "Paruzzaro", "value": "C58448"},
              {"label": "Passerano Marmorito", "value": "C58449"},
              {"label": "Pasta", "value": "C58450"},
              {"label": "Pasturana", "value": "C58451"},
              {"label": "Pavarolo", "value": "C58452"},
              {"label": "Pavone Canavese", "value": "C58453"},
              {"label": "Pecco", "value": "C58454"},
              {"label": "Pecetto", "value": "C58455"},
              {"label": "Pecetto di Valenza", "value": "C58456"},
              {"label": "Pella", "value": "C58457"},
              {"label": "Penango", "value": "C58458"},
              {"label": "Perletto", "value": "C58459"},
              {"label": "Perlo", "value": "C58460"},
              {"label": "Pernate", "value": "C58461"},
              {"label": "Perosa Argentina", "value": "C58462"},
              {"label": "Perosa Canavese", "value": "C58463"},
              {"label": "Perrero", "value": "C58464"},
              {"label": "Pertengo", "value": "C58465"},
              {"label": "Pertusio", "value": "C58466"},
              {"label": "Pessinetto", "value": "C58467"},
              {"label": "Pessione", "value": "C58468"},
              {"label": "Pettenasco", "value": "C58469"},
              {"label": "Pettinengo", "value": "C58470"},
              {"label": "Peveragno", "value": "C58471"},
              {"label": "Pezzana", "value": "C58472"},
              {"label": "Pezzolo Valle Uzzone", "value": "C58473"},
              {"label": "Piana San Raffaele", "value": "C58474"},
              {"label": "Pianezza", "value": "C58475"},
              {"label": "Pianfei", "value": "C58476"},
              {"label": "Piano-Molini dIsola", "value": "C58477"},
              {"label": "Piasco", "value": "C58478"},
              {"label": "Piatto", "value": "C58479"},
              {"label": "Piazza", "value": "C58480"},
              {"label": "Piea", "value": "C58481"},
              {"label": "Piedicavallo", "value": "C58482"},
              {"label": "Piedimulera", "value": "C58483"},
              {"label": "Pietra Marazzi", "value": "C58484"},
              {"label": "Pietraporzio", "value": "C58485"},
              {"label": "Pieve Vergonte", "value": "C58486"},
              {"label": "Pila", "value": "C58487"},
              {"label": "Pinasca-Dubbione", "value": "C58488"},
              {"label": "Pinerolo", "value": "C58489"},
              {"label": "Pino dAsti", "value": "C58490"},
              {"label": "Pino Torinese", "value": "C58491"},
              {"label": "Piobesi dAlba", "value": "C58492"},
              {"label": "Piobesi Torinese", "value": "C58493"},
              {"label": "Piode", "value": "C58494"},
              {"label": "Piossasco", "value": "C58495"},
              {"label": "Piova Massaia", "value": "C58496"},
              {"label": "Piovera", "value": "C58497"},
              {"label": "Piozzo", "value": "C58498"},
              {"label": "Pisano", "value": "C58499"},
              {"label": "Piscina", "value": "C58500"},
              {"label": "Piverone", "value": "C58501"},
              {"label": "Pleyne", "value": "C58502"},
              {"label": "Pocapaglia", "value": "C58503"},
              {"label": "Pogno", "value": "C58504"},
              {"label": "Poirino", "value": "C58505"},
              {"label": "Pollone", "value": "C58506"},
              {"label": "Polonghera", "value": "C58507"},
              {"label": "Pomaretto", "value": "C58508"},
              {"label": "Pomaro Monferrato", "value": "C58509"},
              {"label": "Pombia", "value": "C58510"},
              {"label": "Ponderano", "value": "C58511"},
              {"label": "Pont-Canavese", "value": "C58512"},
              {"label": "Pontechianale", "value": "C58513"},
              {"label": "Pontecurone", "value": "C58514"},
              {"label": "Pontestura", "value": "C58515"},
              {"label": "Ponti", "value": "C58516"},
              {"label": "Ponzano Monferrato", "value": "C58517"},
              {"label": "Ponzone", "value": "C58518"},
              {"label": "Portacomaro", "value": "C58519"},
              {"label": "Porte", "value": "C58520"},
              {"label": "Portula", "value": "C58521"},
              {"label": "Postua", "value": "C58522"},
              {"label": "Pozzol Groppo", "value": "C58523"},
              {"label": "Pozzolo Formigaro", "value": "C58524"},
              {"label": "Pradleves", "value": "C58525"},
              {"label": "Pragelato-Rua", "value": "C58526"},
              {"label": "Prali", "value": "C58527"},
              {"label": "Pralormo", "value": "C58528"},
              {"label": "Pralungo", "value": "C58529"},
              {"label": "Pramollo", "value": "C58530"},
              {"label": "Prarolo", "value": "C58531"},
              {"label": "Prarostino", "value": "C58532"},
              {"label": "Prasco", "value": "C58533"},
              {"label": "Prascorsano", "value": "C58534"},
              {"label": "Pratiglione", "value": "C58535"},
              {"label": "Prato Sesia", "value": "C58536"},
              {"label": "Pray", "value": "C58537"},
              {"label": "Prazzo Superiore", "value": "C58538"},
              {"label": "Predosa", "value": "C58539"},
              {"label": "Premeno", "value": "C58540"},
              {"label": "Premia", "value": "C58541"},
              {"label": "Premosello-Chiovenda", "value": "C58542"},
              {"label": "Priero", "value": "C58543"},
              {"label": "Priocca", "value": "C58544"},
              {"label": "Priola", "value": "C58545"},
              {"label": "Provincia di Alessandria", "value": "C58546"},
              {"label": "Provincia di Asti", "value": "C58547"},
              {"label": "Provincia di Biella", "value": "C58548"},
              {"label": "Provincia di Cuneo", "value": "C58549"},
              {"label": "Provincia di Novara", "value": "C58550"},
              {"label": "Provincia di Vercelli", "value": "C58551"},
              {"label": "Provincia Verbano-Cusio-Ossola", "value": "C58552"},
              {"label": "Prunetto", "value": "C58553"},
              {"label": "Quagliuzzo", "value": "C58554"},
              {"label": "Quaranti", "value": "C58555"},
              {"label": "Quaregna", "value": "C58556"},
              {"label": "Quargnento", "value": "C58557"},
              {"label": "Quarna Sopra", "value": "C58558"},
              {"label": "Quarna Sotto", "value": "C58559"},
              {"label": "Quarona", "value": "C58560"},
              {"label": "Quassolo", "value": "C58561"},
              {"label": "Quattordio", "value": "C58562"},
              {"label": "Quincinetto", "value": "C58563"},
              {"label": "Quinto Vercellese", "value": "C58564"},
              {"label": "Racconigi", "value": "C58565"},
              {"label": "Rassa", "value": "C58566"},
              {"label": "Re", "value": "C58567"},
              {"label": "Reano", "value": "C58568"},
              {"label": "Recetto", "value": "C58569"},
              {"label": "Refrancore", "value": "C58570"},
              {"label": "Revello", "value": "C58571"},
              {"label": "Revigliasco", "value": "C58572"},
              {"label": "Revigliasco dAsti", "value": "C58573"},
              {"label": "Ribordone", "value": "C58574"},
              {"label": "Ricaldone", "value": "C58575"},
              {"label": "Ricca", "value": "C58576"},
              {"label": "Rifreddo", "value": "C58577"},
              {"label": "Rima", "value": "C58578"},
              {"label": "Rimasco", "value": "C58579"},
              {"label": "Rimella", "value": "C58580"},
              {"label": "Rittana", "value": "C58581"},
              {"label": "Riva", "value": "C58582"},
              {"label": "Riva Presso Chieri", "value": "C58583"},
              {"label": "Riva Valdobbia", "value": "C58584"},
              {"label": "Rivalba", "value": "C58585"},
              {"label": "Rivalta Bormida", "value": "C58586"},
              {"label": "Rivalta di Torino", "value": "C58587"},
              {"label": "Rivara", "value": "C58588"},
              {"label": "Rivarolo Canavese", "value": "C58589"},
              {"label": "Rivarone", "value": "C58590"},
              {"label": "Rivarossa", "value": "C58591"},
              {"label": "Rive", "value": "C58592"},
              {"label": "Rivoli", "value": "C58593"},
              {"label": "Roapiana", "value": "C58594"},
              {"label": "Roaschia", "value": "C58595"},
              {"label": "Roascio", "value": "C58596"},
              {"label": "Roasio", "value": "C58597"},
              {"label": "Roata Rossi", "value": "C58598"},
              {"label": "Roatto", "value": "C58599"},
              {"label": "Robassomero", "value": "C58600"},
              {"label": "Robella", "value": "C58601"},
              {"label": "Robilante", "value": "C58602"},
              {"label": "Roburent", "value": "C58603"},
              {"label": "Rocca Canavese", "value": "C58604"},
              {"label": "Rocca Ciglie", "value": "C58605"},
              {"label": "Rocca dArazzo", "value": "C58606"},
              {"label": "Rocca De Baldi", "value": "C58607"},
              {"label": "Rocca Grimalda", "value": "C58608"},
              {"label": "Roccabruna", "value": "C58609"},
              {"label": "Roccaforte Ligure", "value": "C58610"},
              {"label": "Roccaforte Mondovi", "value": "C58611"},
              {"label": "Roccasparvera", "value": "C58612"},
              {"label": "Roccaverano", "value": "C58613"},
              {"label": "Roccavione", "value": "C58614"},
              {"label": "Rocchetta Belbo", "value": "C58615"},
              {"label": "Rocchetta Ligure", "value": "C58616"},
              {"label": "Rocchetta Palafea", "value": "C58617"},
              {"label": "Rocchetta Tanaro", "value": "C58618"},
              {"label": "Roddi", "value": "C58619"},
              {"label": "Roddino", "value": "C58620"},
              {"label": "Rodello", "value": "C58621"},
              {"label": "Roletto", "value": "C58622"},
              {"label": "Romagnano Sesia", "value": "C58623"},
              {"label": "Romano Canavese", "value": "C58624"},
              {"label": "Romentino", "value": "C58625"},
              {"label": "Ronco Biellese", "value": "C58626"},
              {"label": "Ronco Canavese", "value": "C58627"},
              {"label": "Rondissone", "value": "C58628"},
              {"label": "Ronsecco", "value": "C58629"},
              {"label": "Roppolo", "value": "C58630"},
              {"label": "Rora", "value": "C58631"},
              {"label": "Roreto", "value": "C58632"},
              {"label": "Rosazza", "value": "C58633"},
              {"label": "Rosignano Monferrato", "value": "C58634"},
              {"label": "Rossa", "value": "C58635"},
              {"label": "Rossana", "value": "C58636"},
              {"label": "Rosta", "value": "C58637"},
              {"label": "Roure", "value": "C58638"},
              {"label": "Rovasenda", "value": "C58639"},
              {"label": "Rubiana", "value": "C58640"},
              {"label": "Rueglio", "value": "C58641"},
              {"label": "Ruffia", "value": "C58642"},
              {"label": "Sabbia", "value": "C58643"},
              {"label": "Sagliano Micca", "value": "C58644"},
              {"label": "Sala Biellese", "value": "C58645"},
              {"label": "Sala Monferrato", "value": "C58646"},
              {"label": "Salasco", "value": "C58647"},
              {"label": "Salassa", "value": "C58648"},
              {"label": "Salbertrand", "value": "C58649"},
              {"label": "Sale", "value": "C58650"},
              {"label": "Sale delle Langhe", "value": "C58651"},
              {"label": "Sale San Giovanni", "value": "C58652"},
              {"label": "Salerano Canavese", "value": "C58653"},
              {"label": "Sali Vercellese", "value": "C58654"},
              {"label": "Saliceto", "value": "C58655"},
              {"label": "Salmour", "value": "C58656"},
              {"label": "Saluggia", "value": "C58657"},
              {"label": "Salussola", "value": "C58658"},
              {"label": "Saluzzo", "value": "C58659"},
              {"label": "Salza di Pinerolo", "value": "C58660"},
              {"label": "Sambuco", "value": "C58661"},
              {"label": "Samone", "value": "C58662"},
              {"label": "Sampeyre", "value": "C58663"},
              {"label": "San Benedetto Belbo", "value": "C58664"},
              {"label": "San Benigno Canavese", "value": "C58665"},
              {"label": "San Bernardino Verbano", "value": "C58666"},
              {"label": "San Carlo Canavese", "value": "C58667"},
              {"label": "San Colombano Belmonte", "value": "C58668"},
              {"label": "San Cristoforo", "value": "C58669"},
              {"label": "San Damiano dAsti", "value": "C58670"},
              {"label": "San Damiano Macra", "value": "C58671"},
              {"label": "San Defendente", "value": "C58672"},
              {"label": "San Didero", "value": "C58673"},
              {"label": "San Francesco al Campo", "value": "C58674"},
              {"label": "San Germano Chisone", "value": "C58675"},
              {"label": "San Germano Vercellese", "value": "C58676"},
              {"label": "San Giacomo Vercellese", "value": "C58677"},
              {"label": "San Gillio", "value": "C58678"},
              {"label": "San Giorgio", "value": "C58679"},
              {"label": "San Giorgio Canavese", "value": "C58680"},
              {"label": "San Giorgio Monferrato", "value": "C58681"},
              {"label": "San Giorgio Scarampi", "value": "C58682"},
              {"label": "San Giorio", "value": "C58683"},
              {"label": "San Giuliano Vecchio", "value": "C58684"},
              {"label": "San Giuseppe", "value": "C58685"},
              {"label": "San Giusto Canavese", "value": "C58686"},
              {"label": "San Lorenzo", "value": "C58687"},
              {"label": "San Martino Alfieri", "value": "C58688"},
              {"label": "San Martino Canavese", "value": "C58689"},
              {"label": "San Marzano Oliveto", "value": "C58690"},
              {"label": "San Maurizio", "value": "C58691"},
              {"label": "San Maurizio DOpaglio", "value": "C58692"},
              {"label": "San Mauro Torinese", "value": "C58693"},
              {"label": "San Michele Mondovi", "value": "C58694"},
              {"label": "San Nazzaro Sesia", "value": "C58695"},
              {"label": "San Paolo Solbrito", "value": "C58696"},
              {"label": "San Pietro Mosezzo", "value": "C58697"},
              {"label": "San Pietro Val Lemina", "value": "C58698"},
              {"label": "San Ponso", "value": "C58699"},
              {"label": "San Raffaele Cimena", "value": "C58700"},
              {"label": "San Rocco", "value": "C58701"},
              {"label": "San Salvatore Monferrato", "value": "C58702"},
              {"label": "San Sebastiano Curone", "value": "C58703"},
              {"label": "San Sebastiano da Po", "value": "C58704"},
              {"label": "San Secondo di Pinerolo", "value": "C58705"},
              {"label": "Sandigliano", "value": "C58706"},
              {"label": "Sanfre", "value": "C58707"},
              {"label": "Sanfront", "value": "C58708"},
              {"label": "Sangano", "value": "C58709"},
              {"label": "SantAgata Fossili", "value": "C58710"},
              {"label": "SantAlbano Stura", "value": "C58711"},
              {"label": "SantAmbrogio di Torino", "value": "C58712"},
              {"label": "SantAntonino di Susa", "value": "C58713"},
              {"label": "SantAntonio", "value": "C58714"},
              {"label": "Santa Maria Maggiore", "value": "C58715"},
              {"label": "Santa Vittoria dAlba", "value": "C58716"},
              {"label": "Santena", "value": "C58717"},
              {"label": "Santhia", "value": "C58718"},
              {"label": "Santino", "value": "C58719"},
              {"label": "Santo Stefano Belbo", "value": "C58720"},
              {"label": "Santo Stefano Roero", "value": "C58721"},
              {"label": "Sardigliano", "value": "C58722"},
              {"label": "Sarezzano", "value": "C58723"},
              {"label": "Sauze dOulx", "value": "C58724"},
              {"label": "Sauze di Cesana", "value": "C58725"},
              {"label": "Savigliano", "value": "C58726"},
              {"label": "Savonera", "value": "C58727"},
              {"label": "Scagnello", "value": "C58728"},
              {"label": "Scalenghe", "value": "C58729"},
              {"label": "Scarmagno", "value": "C58730"},
              {"label": "Scarnafigi", "value": "C58731"},
              {"label": "Sciolze", "value": "C58732"},
              {"label": "Scopa", "value": "C58733"},
              {"label": "Scopello", "value": "C58734"},
              {"label": "Scurzolengo", "value": "C58735"},
              {"label": "Selve Marcone", "value": "C58736"},
              {"label": "Seppiana", "value": "C58737"},
              {"label": "Serole", "value": "C58738"},
              {"label": "Serralunga dAlba", "value": "C58739"},
              {"label": "Serralunga di Crea", "value": "C58740"},
              {"label": "Serravalle Langhe", "value": "C58741"},
              {"label": "Serravalle Scrivia", "value": "C58742"},
              {"label": "Serravalle Sesia", "value": "C58743"},
              {"label": "Serre", "value": "C58744"},
              {"label": "Sessame", "value": "C58745"},
              {"label": "Sestriere", "value": "C58746"},
              {"label": "Settime", "value": "C58747"},
              {"label": "Settimo Rottaro", "value": "C58748"},
              {"label": "Settimo Torinese", "value": "C58749"},
              {"label": "Settimo Vittone", "value": "C58750"},
              {"label": "Sezzadio", "value": "C58751"},
              {"label": "Sillavengo", "value": "C58752"},
              {"label": "Silvano dOrba", "value": "C58753"},
              {"label": "Sinio", "value": "C58754"},
              {"label": "Sizzano", "value": "C58755"},
              {"label": "Soglio", "value": "C58756"},
              {"label": "Solero", "value": "C58757"},
              {"label": "Solonghello", "value": "C58758"},
              {"label": "Somano", "value": "C58759"},
              {"label": "Sommariva del Bosco", "value": "C58760"},
              {"label": "Sommariva Perno", "value": "C58761"},
              {"label": "Soprana", "value": "C58762"},
              {"label": "Sordevolo", "value": "C58763"},
              {"label": "Soriso", "value": "C58764"},
              {"label": "Sostegno", "value": "C58765"},
              {"label": "Sozzago", "value": "C58766"},
              {"label": "Sparone", "value": "C58767"},
              {"label": "Spigno Monferrato", "value": "C58768"},
              {"label": "Spineto Scrivia", "value": "C58769"},
              {"label": "Spinetta Marengo", "value": "C58770"},
              {"label": "Staffa", "value": "C58771"},
              {"label": "Stazzano", "value": "C58772"},
              {"label": "Strambinello", "value": "C58773"},
              {"label": "Strambino", "value": "C58774"},
              {"label": "Stresa", "value": "C58775"},
              {"label": "Strevi", "value": "C58776"},
              {"label": "Stroppiana", "value": "C58777"},
              {"label": "Stroppo", "value": "C58778"},
              {"label": "Suno", "value": "C58779"},
              {"label": "Susa", "value": "C58780"},
              {"label": "Tagliaferro", "value": "C58781"},
              {"label": "Tagliolo Monferrato", "value": "C58782"},
              {"label": "Tarantasca", "value": "C58783"},
              {"label": "Tassarolo", "value": "C58784"},
              {"label": "Tavagnasco", "value": "C58785"},
              {"label": "Tavigliano", "value": "C58786"},
              {"label": "Terdobbiate", "value": "C58787"},
              {"label": "Ternengo", "value": "C58788"},
              {"label": "Terruggia", "value": "C58789"},
              {"label": "Terzo", "value": "C58790"},
              {"label": "Tetti Neirotti", "value": "C58791"},
              {"label": "Tettorosso", "value": "C58792"},
              {"label": "Ticineto", "value": "C58793"},
              {"label": "Tigliole", "value": "C58794"},
              {"label": "Toceno", "value": "C58795"},
              {"label": "Tollegno", "value": "C58796"},
              {"label": "Tonco", "value": "C58797"},
              {"label": "Tonengo", "value": "C58798"},
              {"label": "Tonengo-Casale", "value": "C58799"},
              {"label": "Torino", "value": "C58800"},
              {"label": "Tornaco", "value": "C58801"},
              {"label": "Torrazza Piemonte", "value": "C58802"},
              {"label": "Torrazzo", "value": "C58803"},
              {"label": "Torre Bormida", "value": "C58804"},
              {"label": "Torre Canavese", "value": "C58805"},
              {"label": "Torre Mondovi", "value": "C58806"},
              {"label": "Torre Pellice", "value": "C58807"},
              {"label": "Torre San Giorgio", "value": "C58808"},
              {"label": "Torresina", "value": "C58809"},
              {"label": "Torrion Quartara", "value": "C58810"},
              {"label": "Tortona", "value": "C58811"},
              {"label": "Trana", "value": "C58812"},
              {"label": "Trarego", "value": "C58813"},
              {"label": "Trasquera", "value": "C58814"},
              {"label": "Trausella", "value": "C58815"},
              {"label": "Traversella", "value": "C58816"},
              {"label": "Traves", "value": "C58817"},
              {"label": "Trecate", "value": "C58818"},
              {"label": "Treiso", "value": "C58819"},
              {"label": "Treville", "value": "C58820"},
              {"label": "Trezzo Tinella", "value": "C58821"},
              {"label": "Tricerro", "value": "C58822"},
              {"label": "Trinita", "value": "C58823"},
              {"label": "Trino", "value": "C58824"},
              {"label": "Trisobbio", "value": "C58825"},
              {"label": "Trivero-Prativero-Ponzone", "value": "C58826"},
              {"label": "Trofarello", "value": "C58827"},
              {"label": "Trontano", "value": "C58828"},
              {"label": "Tronzano Vercellese", "value": "C58829"},
              {"label": "Turin", "value": "C58830"},
              {"label": "Usseaux", "value": "C58831"},
              {"label": "Usseglio", "value": "C58832"},
              {"label": "Vaccheria", "value": "C58833"},
              {"label": "Vaglio Serra", "value": "C58834"},
              {"label": "Vaie", "value": "C58835"},
              {"label": "Val della Torre", "value": "C58836"},
              {"label": "Valdengo", "value": "C58837"},
              {"label": "Valdieri", "value": "C58838"},
              {"label": "Valduggia", "value": "C58839"},
              {"label": "Valentino", "value": "C58840"},
              {"label": "Valenza", "value": "C58841"},
              {"label": "Valfenera", "value": "C58842"},
              {"label": "Valgioie", "value": "C58843"},
              {"label": "Valgrana", "value": "C58844"},
              {"label": "Vallanzengo", "value": "C58845"},
              {"label": "Valle Mosso", "value": "C58846"},
              {"label": "Valle San Bartolomeo", "value": "C58847"},
              {"label": "Valle San Nicolao", "value": "C58848"},
              {"label": "Vallo Torinese", "value": "C58849"},
              {"label": "Valloriate", "value": "C58850"},
              {"label": "Valmacca", "value": "C58851"},
              {"label": "Valmadonna", "value": "C58852"},
              {"label": "Valmala", "value": "C58853"},
              {"label": "Valperga", "value": "C58854"},
              {"label": "Valprato Soana", "value": "C58855"},
              {"label": "Valstrona", "value": "C58856"},
              {"label": "Vanzone", "value": "C58857"},
              {"label": "Vaprio DAgogna", "value": "C58858"},
              {"label": "Varallo", "value": "C58859"},
              {"label": "Varallo Pombia", "value": "C58860"},
              {"label": "Varisella", "value": "C58861"},
              {"label": "Varzo", "value": "C58862"},
              {"label": "Vauda Canavese Superiore", "value": "C58863"},
              {"label": "Veglio", "value": "C58864"},
              {"label": "Venaria Reale", "value": "C58865"},
              {"label": "Venasca", "value": "C58866"},
              {"label": "Venaus", "value": "C58867"},
              {"label": "Verbania", "value": "C58868"},
              {"label": "Vercelli", "value": "C58869"},
              {"label": "Verduno", "value": "C58870"},
              {"label": "Vergnasco", "value": "C58871"},
              {"label": "Vernante", "value": "C58872"},
              {"label": "Verolengo", "value": "C58873"},
              {"label": "Verrone", "value": "C58874"},
              {"label": "Verrua Savoia", "value": "C58875"},
              {"label": "Veruno", "value": "C58876"},
              {"label": "Verzuolo", "value": "C58877"},
              {"label": "Vesime", "value": "C58878"},
              {"label": "Vespolate", "value": "C58879"},
              {"label": "Vestigne", "value": "C58880"},
              {"label": "Vezza dAlba", "value": "C58881"},
              {"label": "Viale", "value": "C58882"},
              {"label": "Vialfre", "value": "C58883"},
              {"label": "Viarigi", "value": "C58884"},
              {"label": "Vico Canavese", "value": "C58885"},
              {"label": "Vicoforte", "value": "C58886"},
              {"label": "Vicolungo", "value": "C58887"},
              {"label": "Vidracco", "value": "C58888"},
              {"label": "Viganella", "value": "C58889"},
              {"label": "Vigliano Biellese", "value": "C58890"},
              {"label": "Vigliano dAsti", "value": "C58891"},
              {"label": "Vignale Monferrato", "value": "C58892"},
              {"label": "Vignole Borbera", "value": "C58893"},
              {"label": "Vignolo", "value": "C58894"},
              {"label": "Vignone", "value": "C58895"},
              {"label": "Vigone", "value": "C58896"},
              {"label": "Viguzzolo", "value": "C58897"},
              {"label": "Villa", "value": "C58898"},
              {"label": "Villa del Bosco", "value": "C58899"},
              {"label": "Villa San Secondo", "value": "C58900"},
              {"label": "Villa-Borgo", "value": "C58901"},
              {"label": "Villadeati", "value": "C58902"},
              {"label": "Villadossola", "value": "C58903"},
              {"label": "Villafalletto", "value": "C58904"},
              {"label": "Villafranca dAsti", "value": "C58905"},
              {"label": "Villafranca Piemonte", "value": "C58906"},
              {"label": "Villalvernia", "value": "C58907"},
              {"label": "Villamiroglio", "value": "C58908"},
              {"label": "Villanova Biellese", "value": "C58909"},
              {"label": "Villanova Canavese", "value": "C58910"},
              {"label": "Villanova dAsti", "value": "C58911"},
              {"label": "Villanova Mondovi", "value": "C58912"},
              {"label": "Villanova Monferrato", "value": "C58913"},
              {"label": "Villanova Solaro", "value": "C58914"},
              {"label": "Villar Dora", "value": "C58915"},
              {"label": "Villar Focchiardo", "value": "C58916"},
              {"label": "Villar Pellice", "value": "C58917"},
              {"label": "Villar Perosa", "value": "C58918"},
              {"label": "Villar San Costanzo", "value": "C58919"},
              {"label": "Villarbasse", "value": "C58920"},
              {"label": "Villarboit", "value": "C58921"},
              {"label": "Villareggia", "value": "C58922"},
              {"label": "Villaromagnano", "value": "C58923"},
              {"label": "Villastellone", "value": "C58924"},
              {"label": "Villata", "value": "C58925"},
              {"label": "Villette", "value": "C58926"},
              {"label": "Vinadio", "value": "C58927"},
              {"label": "Vinchio", "value": "C58928"},
              {"label": "Vinovo", "value": "C58929"},
              {"label": "Vinzaglio", "value": "C58930"},
              {"label": "Viola", "value": "C58931"},
              {"label": "Virle Piemonte", "value": "C58932"},
              {"label": "Vische", "value": "C58933"},
              {"label": "Visone", "value": "C58934"},
              {"label": "Vistrorio", "value": "C58935"},
              {"label": "Viu", "value": "C58936"},
              {"label": "Viverone", "value": "C58937"},
              {"label": "Vocca", "value": "C58938"},
              {"label": "Vogogna", "value": "C58939"},
              {"label": "Volpedo", "value": "C58940"},
              {"label": "Volpeglino", "value": "C58941"},
              {"label": "Volpiano", "value": "C58942"},
              {"label": "Voltaggio", "value": "C58943"},
              {"label": "Volvera", "value": "C58944"},
              {"label": "Vottignasco", "value": "C58945"},
              {"label": "Zimone", "value": "C58946"},
              {"label": "Zubiena", "value": "C58947"},
              {"label": "Zumaglia", "value": "C58948"}],
 "Pieta": [{"label": "Pieta", "value": "C65907"}],
 "Pinar del Rio Province": [{"label": "Consolacion del Sur", "value": "C21804"},
                            {"label": "Guane", "value": "C21805"},
                            {"label": "Los Palacios", "value": "C21806"},
                            {"label": "Mantua", "value": "C21807"},
                            {"label": "Minas de Matahambre", "value": "C21808"},
                            {"label": "Municipio de Consolacion del Sur",
                             "value": "C21809"},
                            {"label": "Municipio de Guane", "value": "C21810"},
                            {"label": "Municipio de La Palma",
                             "value": "C21811"},
                            {"label": "Municipio de Los Palacios",
                             "value": "C21812"},
                            {"label": "Pinar del Rio", "value": "C21813"},
                            {"label": "Puerto Esperanza", "value": "C21814"},
                            {"label": "San Diego de Los Banos",
                             "value": "C21815"},
                            {"label": "San Luis", "value": "C21816"},
                            {"label": "Vinales", "value": "C21817"}],
 "Pingtung": [{"label": "Donggang", "value": "C117976"},
              {"label": "Hengchun", "value": "C117977"},
              {"label": "Pingtung", "value": "C117978"}],
 "Piran Municipality": [{"label": "Lucija", "value": "C107757"},
                        {"label": "Piran", "value": "C107758"},
                        {"label": "Portoroz", "value": "C107759"},
                        {"label": "Seca", "value": "C107760"}],
 "Pirkanmaa": [{"label": "Iisalmi", "value": "C25055"},
               {"label": "Juankoski", "value": "C25056"},
               {"label": "Kaavi", "value": "C25057"},
               {"label": "Kangaslampi", "value": "C25058"},
               {"label": "Karttula", "value": "C25059"},
               {"label": "Keitele", "value": "C25060"},
               {"label": "Kiuruvesi", "value": "C25061"},
               {"label": "Kuopio", "value": "C25062"},
               {"label": "Lapinlahti", "value": "C25063"},
               {"label": "Leppavirta", "value": "C25064"},
               {"label": "Maaninka", "value": "C25065"},
               {"label": "Nilsia", "value": "C25066"},
               {"label": "Pielavesi", "value": "C25067"},
               {"label": "Rautalampi", "value": "C25068"},
               {"label": "Rautavaara", "value": "C25069"},
               {"label": "Siilinjarvi", "value": "C25070"},
               {"label": "Sonkajarvi", "value": "C25071"},
               {"label": "Suonenjoki", "value": "C25072"},
               {"label": "Tervo", "value": "C25073"},
               {"label": "Tuusniemi", "value": "C25074"},
               {"label": "Varkaus", "value": "C25075"},
               {"label": "Varpaisjarvi", "value": "C25076"},
               {"label": "Vehmersalmi", "value": "C25077"},
               {"label": "Vesanto", "value": "C25078"},
               {"label": "Vierema", "value": "C25079"}],
 "Piura": [{"label": "Ayabaca", "value": "C81003"},
           {"label": "Bernal", "value": "C81004"},
           {"label": "Buenos Aires", "value": "C81005"},
           {"label": "Catacaos", "value": "C81006"},
           {"label": "Chulucanas", "value": "C81007"},
           {"label": "El Alto", "value": "C81008"},
           {"label": "Huancabamba", "value": "C81009"},
           {"label": "La Breita", "value": "C81010"},
           {"label": "La Huaca", "value": "C81011"},
           {"label": "La Union", "value": "C81012"},
           {"label": "Las Lomas", "value": "C81013"},
           {"label": "Mancora", "value": "C81014"},
           {"label": "Marcavelica", "value": "C81015"},
           {"label": "Morropon", "value": "C81016"},
           {"label": "Paita", "value": "C81017"},
           {"label": "Piura", "value": "C81018"},
           {"label": "Provincia de Ayabaca", "value": "C81019"},
           {"label": "Provincia de Huancabamba", "value": "C81020"},
           {"label": "Provincia de Paita", "value": "C81021"},
           {"label": "Provincia de Piura", "value": "C81022"},
           {"label": "Provincia de Sullana", "value": "C81023"},
           {"label": "Provincia de Talara", "value": "C81024"},
           {"label": "Querecotillo", "value": "C81025"},
           {"label": "Salinera Colan", "value": "C81026"},
           {"label": "Salitral", "value": "C81027"},
           {"label": "Sechura", "value": "C81028"},
           {"label": "Sullana", "value": "C81029"},
           {"label": "Talara", "value": "C81030"},
           {"label": "Tambo Grande", "value": "C81031"},
           {"label": "Vice", "value": "C81032"}],
 "Pivka Municipality": [{"label": "Pivka", "value": "C107761"}],
 "Plaines Wilhems District": [{"label": "Beau Bassin-Rose Hill",
                               "value": "C66032"},
                              {"label": "Curepipe", "value": "C66033"},
                              {"label": "Ebene", "value": "C66034"},
                              {"label": "Midlands", "value": "C66035"},
                              {"label": "Quatre Bornes", "value": "C66036"},
                              {"label": "Vacoas", "value": "C66037"}],
 "Planken": [{"label": "Planken", "value": "C65075"}],
 "Plasnica Municipality": [{"label": "Lisicani", "value": "C65476"},
                           {"label": "Plasnica", "value": "C65477"}],
 "Plateau": [{"label": "Amper", "value": "C78467"},
             {"label": "Bukuru", "value": "C78468"},
             {"label": "Dengi", "value": "C78469"},
             {"label": "Jos", "value": "C78470"},
             {"label": "Kwolla", "value": "C78471"},
             {"label": "Langtang", "value": "C78472"},
             {"label": "Pankshin", "value": "C78473"},
             {"label": "Panyam", "value": "C78474"},
             {"label": "Vom", "value": "C78475"},
             {"label": "Yelwa", "value": "C78476"}],
 "Plateau Department": [{"label": "Ketou", "value": "C9623"},
                        {"label": "Pobe", "value": "C9624"},
                        {"label": "Sakete", "value": "C9625"}],
 "Plateau-Central Region": [{"label": "Bousse", "value": "C16422"},
                            {"label": "Oubritenga", "value": "C16423"},
                            {"label": "Province du Ganzourgou",
                             "value": "C16424"},
                            {"label": "Province du Kourweogo",
                             "value": "C16425"},
                            {"label": "Ziniare", "value": "C16426"},
                            {"label": "Zorgo", "value": "C16427"}],
 "Plateaux Department": [{"label": "Djambala", "value": "C20756"},
                         {"label": "Gamboma", "value": "C20757"}],
 "Plateaux Region": [{"label": "Amlame", "value": "C119640"},
                     {"label": "Atakpame", "value": "C119641"},
                     {"label": "Badou", "value": "C119642"},
                     {"label": "Kpalime", "value": "C119643"},
                     {"label": "Notse", "value": "C119644"}],
 "Plav Municipality": [{"label": "Plav", "value": "C75614"}],
 "Plavinas Municipality": [{"label": "Plavinas", "value": "C64912"}],
 "Pleven Province": [{"label": "Belene", "value": "C16040"},
                     {"label": "Cherven Bryag", "value": "C16041"},
                     {"label": "Dolna Mitropolia", "value": "C16042"},
                     {"label": "Dolni Dabnik", "value": "C16043"},
                     {"label": "Gulyantsi", "value": "C16044"},
                     {"label": "Iskar", "value": "C16045"},
                     {"label": "Knezha", "value": "C16046"},
                     {"label": "Koynare", "value": "C16047"},
                     {"label": "Levski", "value": "C16048"},
                     {"label": "Nikopol", "value": "C16049"},
                     {"label": "Obshtina Belene", "value": "C16050"},
                     {"label": "Obshtina Cherven Bryag", "value": "C16051"},
                     {"label": "Obshtina Dolna Mitropolia", "value": "C16052"},
                     {"label": "Obshtina Dolni Dabnik", "value": "C16053"},
                     {"label": "Obshtina Gulyantsi", "value": "C16054"},
                     {"label": "Obshtina Iskar", "value": "C16055"},
                     {"label": "Obshtina Knezha", "value": "C16056"},
                     {"label": "Obshtina Levski", "value": "C16057"},
                     {"label": "Obshtina Nikopol", "value": "C16058"},
                     {"label": "Obshtina Pleven", "value": "C16059"},
                     {"label": "Obshtina Pordim", "value": "C16060"},
                     {"label": "Pleven", "value": "C16061"},
                     {"label": "Pordim", "value": "C16062"},
                     {"label": "Slavyanovo", "value": "C16063"}],
 "Pljevlja Municipality": [{"label": "Pljevlja", "value": "C75615"}],
 "Plovdiv Province": [{"label": "Asenovgrad", "value": "C16064"},
                      {"label": "Brezovo", "value": "C16065"},
                      {"label": "Hisarya", "value": "C16066"},
                      {"label": "Kalofer", "value": "C16067"},
                      {"label": "Kaloyanovo", "value": "C16068"},
                      {"label": "Karlovo", "value": "C16069"},
                      {"label": "Klisura", "value": "C16070"},
                      {"label": "Krichim", "value": "C16071"},
                      {"label": "Laki", "value": "C16072"},
                      {"label": "Obshtina Asenovgrad", "value": "C16073"},
                      {"label": "Obshtina Hisarya", "value": "C16074"},
                      {"label": "Obshtina Kaloyanovo", "value": "C16075"},
                      {"label": "Obshtina Karlovo", "value": "C16076"},
                      {"label": "Obshtina Krichim", "value": "C16077"},
                      {"label": "Obshtina Kuklen", "value": "C16078"},
                      {"label": "Obshtina Laki", "value": "C16079"},
                      {"label": "Obshtina Maritsa", "value": "C16080"},
                      {"label": "Obshtina Parvomay", "value": "C16081"},
                      {"label": "Obshtina Perushtitsa", "value": "C16082"},
                      {"label": "Obshtina Plovdiv", "value": "C16083"},
                      {"label": "Obshtina Rakovski", "value": "C16084"},
                      {"label": "Obshtina Rodopi", "value": "C16085"},
                      {"label": "Obshtina Sadovo", "value": "C16086"},
                      {"label": "Obshtina Saedinenie", "value": "C16087"},
                      {"label": "Obshtina Sopot", "value": "C16088"},
                      {"label": "Obshtina Stamboliyski", "value": "C16089"},
                      {"label": "Parvomay", "value": "C16090"},
                      {"label": "Perushtitsa", "value": "C16091"},
                      {"label": "Plovdiv", "value": "C16092"},
                      {"label": "Rakovski", "value": "C16093"},
                      {"label": "Sadovo", "value": "C16094"},
                      {"label": "Saedinenie", "value": "C16095"},
                      {"label": "Stamboliyski", "value": "C16096"},
                      {"label": "Topolovo", "value": "C16097"}],
 "Pluzine Municipality": [{"label": "Pluzine", "value": "C75616"}],
 "Plzen-jih": [{"label": "Bela nad Radbuzou", "value": "C22813"},
               {"label": "Bezdruzice", "value": "C22814"},
               {"label": "Blizejov", "value": "C22815"},
               {"label": "Blovice", "value": "C22816"},
               {"label": "Bor", "value": "C22817"},
               {"label": "Brasy", "value": "C22818"},
               {"label": "Cernosin", "value": "C22819"},
               {"label": "Chlumcany", "value": "C22820"},
               {"label": "Chodova Plana", "value": "C22821"},
               {"label": "Chotesov", "value": "C22822"},
               {"label": "Chrast", "value": "C22823"},
               {"label": "Dobrany", "value": "C22824"},
               {"label": "Dobriv", "value": "C22825"},
               {"label": "Domazlice", "value": "C22826"},
               {"label": "Dysina", "value": "C22827"},
               {"label": "Hartmanice", "value": "C22828"},
               {"label": "Hermanova Hut", "value": "C22829"},
               {"label": "Holoubkov", "value": "C22830"},
               {"label": "Holysov", "value": "C22831"},
               {"label": "Horazdovice", "value": "C22832"},
               {"label": "Horni Briza", "value": "C22833"},
               {"label": "Horsovsky Tyn", "value": "C22834"},
               {"label": "Hostoun", "value": "C22835"},
               {"label": "Hradek", "value": "C22836"},
               {"label": "Hromnice", "value": "C22837"},
               {"label": "Janovice nad Uhlavou", "value": "C22838"},
               {"label": "Kasejovice", "value": "C22839"},
               {"label": "Kasperske Hory", "value": "C22840"},
               {"label": "Kaznejov", "value": "C22841"},
               {"label": "Kdyne", "value": "C22842"},
               {"label": "Kladruby", "value": "C22843"},
               {"label": "Klatovy", "value": "C22844"},
               {"label": "Klenci pod Cerchovem", "value": "C22845"},
               {"label": "Kolinec", "value": "C22846"},
               {"label": "Kout na Sumave", "value": "C22847"},
               {"label": "Kozlany", "value": "C22848"},
               {"label": "Kralovice", "value": "C22849"},
               {"label": "Line", "value": "C22850"},
               {"label": "Manetin", "value": "C22851"},
               {"label": "Mecin", "value": "C22852"},
               {"label": "Meclov", "value": "C22853"},
               {"label": "Merklin", "value": "C22854"},
               {"label": "Mesto Touskov", "value": "C22855"},
               {"label": "Mirosov", "value": "C22856"},
               {"label": "Mrakov", "value": "C22857"},
               {"label": "Myto", "value": "C22858"},
               {"label": "Nepomuk", "value": "C22859"},
               {"label": "Nezvestice", "value": "C22860"},
               {"label": "Nyrany", "value": "C22861"},
               {"label": "Nyrsko", "value": "C22862"},
               {"label": "Okres Domazlice", "value": "C22863"},
               {"label": "Okres Klatovy", "value": "C22864"},
               {"label": "Okres Plzen-jih", "value": "C22865"},
               {"label": "Okres Plzen-mesto", "value": "C22866"},
               {"label": "Okres Plzen-sever", "value": "C22867"},
               {"label": "Okres Rokycany", "value": "C22868"},
               {"label": "Okres Tachov", "value": "C22869"},
               {"label": "Osek", "value": "C22870"},
               {"label": "Pilsen", "value": "C22871"},
               {"label": "Plana", "value": "C22872"},
               {"label": "Planice", "value": "C22873"},
               {"label": "Plasy", "value": "C22874"},
               {"label": "Pobezovice", "value": "C22875"},
               {"label": "Postrekov", "value": "C22876"},
               {"label": "Prestice", "value": "C22877"},
               {"label": "Primda", "value": "C22878"},
               {"label": "Radnice", "value": "C22879"},
               {"label": "Rokycany", "value": "C22880"},
               {"label": "Spalene Porici", "value": "C22881"},
               {"label": "Stahlavy", "value": "C22882"},
               {"label": "Stankov", "value": "C22883"},
               {"label": "Stary Plzenec", "value": "C22884"},
               {"label": "Stenovice", "value": "C22885"},
               {"label": "Stod", "value": "C22886"},
               {"label": "Strasice", "value": "C22887"},
               {"label": "Straz", "value": "C22888"},
               {"label": "Strazov", "value": "C22889"},
               {"label": "Stribro", "value": "C22890"},
               {"label": "Susice", "value": "C22891"},
               {"label": "Svihov", "value": "C22892"},
               {"label": "Tachov", "value": "C22893"},
               {"label": "Tlucna", "value": "C22894"},
               {"label": "Tremosna", "value": "C22895"},
               {"label": "Vejprnice", "value": "C22896"},
               {"label": "Vseruby", "value": "C22897"},
               {"label": "Zbiroh", "value": "C22898"},
               {"label": "Zbuch", "value": "C22899"},
               {"label": "Zelezna Ruda", "value": "C22900"},
               {"label": "Zihle", "value": "C22901"}],
 "Podcetrtek Municipality": [{"label": "Podcetrtek", "value": "C107762"}],
 "Podgorica Municipality": [{"label": "Golubovci", "value": "C75617"},
                            {"label": "Goricani", "value": "C75618"},
                            {"label": "Mataguzi", "value": "C75619"},
                            {"label": "Mojanovici", "value": "C75620"},
                            {"label": "Podgorica", "value": "C75621"},
                            {"label": "Tuzi", "value": "C75622"}],
 "Podkarpackie Voivodeship": [{"label": "Adamowka", "value": "C89605"},
                              {"label": "Albigowa", "value": "C89606"},
                              {"label": "Babica", "value": "C89607"},
                              {"label": "Baligrod", "value": "C89608"},
                              {"label": "Baranow Sandomierski",
                               "value": "C89609"},
                              {"label": "Besko", "value": "C89610"},
                              {"label": "Bialobrzegi", "value": "C89611"},
                              {"label": "Bieliny", "value": "C89612"},
                              {"label": "Bircza", "value": "C89613"},
                              {"label": "Blizne", "value": "C89614"},
                              {"label": "Blazowa", "value": "C89615"},
                              {"label": "Boguchwala", "value": "C89616"},
                              {"label": "Bojanow", "value": "C89617"},
                              {"label": "Borowa", "value": "C89618"},
                              {"label": "Brzeznica", "value": "C89619"},
                              {"label": "Brzostek", "value": "C89620"},
                              {"label": "Brzoza Krolewska", "value": "C89621"},
                              {"label": "Brzoza Stadnicka", "value": "C89622"},
                              {"label": "Brzozow", "value": "C89623"},
                              {"label": "Brzyska", "value": "C89624"},
                              {"label": "Bukowsko", "value": "C89625"},
                              {"label": "Chmielnik", "value": "C89626"},
                              {"label": "Chorkowka", "value": "C89627"},
                              {"label": "Chwalowice", "value": "C89628"},
                              {"label": "Cieszanow", "value": "C89629"},
                              {"label": "Cisna", "value": "C89630"},
                              {"label": "Cmolas", "value": "C89631"},
                              {"label": "Czarna", "value": "C89632"},
                              {"label": "Czaszyn", "value": "C89633"},
                              {"label": "Czermin", "value": "C89634"},
                              {"label": "Czudec", "value": "C89635"},
                              {"label": "Debica", "value": "C89636"},
                              {"label": "Debno", "value": "C89637"},
                              {"label": "Debow", "value": "C89638"},
                              {"label": "Debowiec", "value": "C89639"},
                              {"label": "Dlugie", "value": "C89640"},
                              {"label": "Domaradz", "value": "C89641"},
                              {"label": "Dubiecko", "value": "C89642"},
                              {"label": "Dukla", "value": "C89643"},
                              {"label": "Dydnia", "value": "C89644"},
                              {"label": "Dynow", "value": "C89645"},
                              {"label": "Dzikow Stary", "value": "C89646"},
                              {"label": "Dzikowiec", "value": "C89647"},
                              {"label": "Fredropol", "value": "C89648"},
                              {"label": "Frysztak", "value": "C89649"},
                              {"label": "Gac", "value": "C89650"},
                              {"label": "Giedlarowa", "value": "C89651"},
                              {"label": "Glogow Malopolski", "value": "C89652"},
                              {"label": "Gluchow", "value": "C89653"},
                              {"label": "Golcowa", "value": "C89654"},
                              {"label": "Gorki", "value": "C89655"},
                              {"label": "Gorliczyna", "value": "C89656"},
                              {"label": "Gorno", "value": "C89657"},
                              {"label": "Gorzyce", "value": "C89658"},
                              {"label": "Grabownica Starzenska",
                               "value": "C89659"},
                              {"label": "Grebow", "value": "C89660"},
                              {"label": "Grodzisko Dolne", "value": "C89661"},
                              {"label": "Grodzisko Gorne", "value": "C89662"},
                              {"label": "Grzeska", "value": "C89663"},
                              {"label": "Gwoznica Gorna", "value": "C89664"},
                              {"label": "Haczow", "value": "C89665"},
                              {"label": "Handzlowka", "value": "C89666"},
                              {"label": "Harasiuki", "value": "C89667"},
                              {"label": "Horyniec-Zdroj", "value": "C89668"},
                              {"label": "Humniska", "value": "C89669"},
                              {"label": "Husow", "value": "C89670"},
                              {"label": "Hyzne", "value": "C89671"},
                              {"label": "Iwierzyce", "value": "C89672"},
                              {"label": "Iwonicz-Zdroj", "value": "C89673"},
                              {"label": "Jablonica Polska", "value": "C89674"},
                              {"label": "Jablonka", "value": "C89675"},
                              {"label": "Jagiella", "value": "C89676"},
                              {"label": "Jarocin", "value": "C89677"},
                              {"label": "Jaroslaw", "value": "C89678"},
                              {"label": "Jasienica Rosielna",
                               "value": "C89679"},
                              {"label": "Jasionow", "value": "C89680"},
                              {"label": "Jasliska", "value": "C89681"},
                              {"label": "Jaslo", "value": "C89682"},
                              {"label": "Jawornik", "value": "C89683"},
                              {"label": "Jawornik Polski", "value": "C89684"},
                              {"label": "Jedlicze", "value": "C89685"},
                              {"label": "Jezowe", "value": "C89686"},
                              {"label": "Jodlowa", "value": "C89687"},
                              {"label": "Jodlowka", "value": "C89688"},
                              {"label": "Kamien", "value": "C89689"},
                              {"label": "Kanczuga", "value": "C89690"},
                              {"label": "Kepie Zaleszanskie",
                               "value": "C89691"},
                              {"label": "Kielanowka", "value": "C89692"},
                              {"label": "Kolbuszowa", "value": "C89693"},
                              {"label": "Kolaczyce", "value": "C89694"},
                              {"label": "Komancza", "value": "C89695"},
                              {"label": "Konczyce", "value": "C89696"},
                              {"label": "Konieczkowa", "value": "C89697"},
                              {"label": "Korczyna", "value": "C89698"},
                              {"label": "Kosina", "value": "C89699"},
                              {"label": "Kraczkowa", "value": "C89700"},
                              {"label": "Kramarzowka", "value": "C89701"},
                              {"label": "Krasiczyn", "value": "C89702"},
                              {"label": "Krasne", "value": "C89703"},
                              {"label": "Krempna", "value": "C89704"},
                              {"label": "Kroscienko Wyzne", "value": "C89705"},
                              {"label": "Krosno", "value": "C89706"},
                              {"label": "Krzeczowice", "value": "C89707"},
                              {"label": "Krzemienica", "value": "C89708"},
                              {"label": "Krzeszow", "value": "C89709"},
                              {"label": "Krzywcza", "value": "C89710"},
                              {"label": "Kurylowka", "value": "C89711"},
                              {"label": "Laszki", "value": "C89712"},
                              {"label": "Lecka", "value": "C89713"},
                              {"label": "Lesko", "value": "C89714"},
                              {"label": "Lezajsk", "value": "C89715"},
                              {"label": "Lipnica", "value": "C89716"},
                              {"label": "Lubaczow", "value": "C89717"},
                              {"label": "Lubenia", "value": "C89718"},
                              {"label": "Lutoryz", "value": "C89719"},
                              {"label": "Lutowiska", "value": "C89720"},
                              {"label": "Lancut", "value": "C89721"},
                              {"label": "Leki", "value": "C89722"},
                              {"label": "Leki Dolne", "value": "C89723"},
                              {"label": "Leki Gorne", "value": "C89724"},
                              {"label": "Letownia", "value": "C89725"},
                              {"label": "Lopuszka Wielka", "value": "C89726"},
                              {"label": "Majdan Krolewski", "value": "C89727"},
                              {"label": "Manasterz", "value": "C89728"},
                              {"label": "Markowa", "value": "C89729"},
                              {"label": "Medyka", "value": "C89730"},
                              {"label": "Miejsce Piastowe", "value": "C89731"},
                              {"label": "Mielec", "value": "C89732"},
                              {"label": "Mirocin", "value": "C89733"},
                              {"label": "Narol", "value": "C89734"},
                              {"label": "Niebieszczany", "value": "C89735"},
                              {"label": "Niebocko", "value": "C89736"},
                              {"label": "Niebylec", "value": "C89737"},
                              {"label": "Niechobrz", "value": "C89738"},
                              {"label": "Niedzwiada", "value": "C89739"},
                              {"label": "Nienadowa", "value": "C89740"},
                              {"label": "Nisko", "value": "C89741"},
                              {"label": "Niwiska", "value": "C89742"},
                              {"label": "Nowa Deba", "value": "C89743"},
                              {"label": "Nowa Sarzyna", "value": "C89744"},
                              {"label": "Nowe Siolo", "value": "C89745"},
                              {"label": "Nowosielce", "value": "C89746"},
                              {"label": "Nowosielce-Gniewosz",
                               "value": "C89747"},
                              {"label": "Nowy Zmigrod", "value": "C89748"},
                              {"label": "Nozdrzec", "value": "C89749"},
                              {"label": "Odrzykon", "value": "C89750"},
                              {"label": "Oleszyce", "value": "C89751"},
                              {"label": "Olszanica", "value": "C89752"},
                              {"label": "Orly", "value": "C89753"},
                              {"label": "Orzechowka", "value": "C89754"},
                              {"label": "Osiek Jasielski", "value": "C89755"},
                              {"label": "Ostrow", "value": "C89756"},
                              {"label": "Padew Narodowa", "value": "C89757"},
                              {"label": "Pantalowice", "value": "C89758"},
                              {"label": "Pawlosiow", "value": "C89759"},
                              {"label": "Pilzno", "value": "C89760"},
                              {"label": "Piskorowice", "value": "C89761"},
                              {"label": "Polanczyk", "value": "C89762"},
                              {"label": "Polomia", "value": "C89763"},
                              {"label": "Poraz", "value": "C89764"},
                              {"label": "Powiat bieszczadzki",
                               "value": "C89765"},
                              {"label": "Powiat brzozowski", "value": "C89766"},
                              {"label": "Powiat debicki", "value": "C89767"},
                              {"label": "Powiat jaroslawski",
                               "value": "C89768"},
                              {"label": "Powiat jasielski", "value": "C89769"},
                              {"label": "Powiat kolbuszowski",
                               "value": "C89770"},
                              {"label": "Powiat krosnienski",
                               "value": "C89771"},
                              {"label": "Powiat leski", "value": "C89772"},
                              {"label": "Powiat lezajski", "value": "C89773"},
                              {"label": "Powiat lubaczowski",
                               "value": "C89774"},
                              {"label": "Powiat lancucki", "value": "C89775"},
                              {"label": "Powiat mielecki", "value": "C89776"},
                              {"label": "Powiat nizanski", "value": "C89777"},
                              {"label": "Powiat przemyski", "value": "C89778"},
                              {"label": "Powiat przeworski", "value": "C89779"},
                              {"label": "Powiat ropczycko-sedziszowski",
                               "value": "C89780"},
                              {"label": "Powiat rzeszowski", "value": "C89781"},
                              {"label": "Powiat sanocki", "value": "C89782"},
                              {"label": "Powiat stalowowolski",
                               "value": "C89783"},
                              {"label": "Powiat strzyzowski",
                               "value": "C89784"},
                              {"label": "Powiat tarnobrzeski",
                               "value": "C89785"},
                              {"label": "Pruchnik", "value": "C89786"},
                              {"label": "Przeclaw", "value": "C89787"},
                              {"label": "Przedmiescie Dubieckie",
                               "value": "C89788"},
                              {"label": "Przedzel", "value": "C89789"},
                              {"label": "Przemysl", "value": "C89790"},
                              {"label": "Przeworsk", "value": "C89791"},
                              {"label": "Przybyszowka", "value": "C89792"},
                              {"label": "Pysznica", "value": "C89793"},
                              {"label": "Raclawice", "value": "C89794"},
                              {"label": "Radomysl", "value": "C89795"},
                              {"label": "Radomysl Wielki", "value": "C89796"},
                              {"label": "Radymno", "value": "C89797"},
                              {"label": "Rakszawa", "value": "C89798"},
                              {"label": "Ranizow", "value": "C89799"},
                              {"label": "Rogozno", "value": "C89800"},
                              {"label": "Rokietnica", "value": "C89801"},
                              {"label": "Ropczyce", "value": "C89802"},
                              {"label": "Rozborz", "value": "C89803"},
                              {"label": "Rudna Mala", "value": "C89804"},
                              {"label": "Rudna Wielka", "value": "C89805"},
                              {"label": "Rudnik nad Sanem", "value": "C89806"},
                              {"label": "Rymanow", "value": "C89807"},
                              {"label": "Rzepedz", "value": "C89808"},
                              {"label": "Rzeszow", "value": "C89809"},
                              {"label": "Sanok", "value": "C89810"},
                              {"label": "Sedziszow Malopolski",
                               "value": "C89811"},
                              {"label": "Siedleczka", "value": "C89812"},
                              {"label": "Siedliska", "value": "C89813"},
                              {"label": "Sieniawa", "value": "C89814"},
                              {"label": "Sietesz", "value": "C89815"},
                              {"label": "Skolyszyn", "value": "C89816"},
                              {"label": "Slotowa", "value": "C89817"},
                              {"label": "Sokolniki", "value": "C89818"},
                              {"label": "Sokolow Malopolski",
                               "value": "C89819"},
                              {"label": "Sonina", "value": "C89820"},
                              {"label": "Sosnica", "value": "C89821"},
                              {"label": "Stalowa Wola", "value": "C89822"},
                              {"label": "Stara Wies", "value": "C89823"},
                              {"label": "Stare Miasto", "value": "C89824"},
                              {"label": "Strachocina", "value": "C89825"},
                              {"label": "Straszydle", "value": "C89826"},
                              {"label": "Strzyzow", "value": "C89827"},
                              {"label": "Stubno", "value": "C89828"},
                              {"label": "Swilcza", "value": "C89829"},
                              {"label": "Tarnobrzeg", "value": "C89830"},
                              {"label": "Tarnowiec", "value": "C89831"},
                              {"label": "Tryncza", "value": "C89832"},
                              {"label": "Trzciana", "value": "C89833"},
                              {"label": "Trzcinica", "value": "C89834"},
                              {"label": "Trzebownisko", "value": "C89835"},
                              {"label": "Turze Pole", "value": "C89836"},
                              {"label": "Tyczyn", "value": "C89837"},
                              {"label": "Tyrawa Woloska", "value": "C89838"},
                              {"label": "Uherce Mineralne", "value": "C89839"},
                              {"label": "Ulanow", "value": "C89840"},
                              {"label": "Urzejowice", "value": "C89841"},
                              {"label": "Ustrzyki Dolne", "value": "C89842"},
                              {"label": "Wadowice Gorne", "value": "C89843"},
                              {"label": "Wesola", "value": "C89844"},
                              {"label": "Wiazownica", "value": "C89845"},
                              {"label": "Wielkie Oczy", "value": "C89846"},
                              {"label": "Wielopole Skrzynskie",
                               "value": "C89847"},
                              {"label": "Wierzawice", "value": "C89848"},
                              {"label": "Wierzbna", "value": "C89849"},
                              {"label": "Wisniowa", "value": "C89850"},
                              {"label": "Wojaszowka", "value": "C89851"},
                              {"label": "Wola Zarczycka", "value": "C89852"},
                              {"label": "Wolka Niedzwiedzka",
                               "value": "C89853"},
                              {"label": "Wolka Pelkinska", "value": "C89854"},
                              {"label": "Wolka Podlesna", "value": "C89855"},
                              {"label": "Wolka Tanewska", "value": "C89856"},
                              {"label": "Wysoka", "value": "C89857"},
                              {"label": "Wysoka Glogowska", "value": "C89858"},
                              {"label": "Wysoka Strzyzowska",
                               "value": "C89859"},
                              {"label": "Zagorz", "value": "C89860"},
                              {"label": "Zagorzyce", "value": "C89861"},
                              {"label": "Zaklikow", "value": "C89862"},
                              {"label": "Zalesie", "value": "C89863"},
                              {"label": "Zaleszany", "value": "C89864"},
                              {"label": "Zarszyn", "value": "C89865"},
                              {"label": "Zarzecze", "value": "C89866"},
                              {"label": "Zdziechowice Drugie",
                               "value": "C89867"},
                              {"label": "Zglobien", "value": "C89868"},
                              {"label": "Zmiennica", "value": "C89869"},
                              {"label": "Zolynia", "value": "C89870"},
                              {"label": "Zurawica", "value": "C89871"},
                              {"label": "Zurawiczki", "value": "C89872"},
                              {"label": "Zyrakow", "value": "C89873"}],
 "Podlaskie Voivodeship": [{"label": "Augustow", "value": "C89874"},
                           {"label": "Bialowieza", "value": "C89875"},
                           {"label": "Bialystok", "value": "C89876"},
                           {"label": "Bielsk Podlaski", "value": "C89877"},
                           {"label": "Bocki", "value": "C89878"},
                           {"label": "Bransk", "value": "C89879"},
                           {"label": "Choroszcz", "value": "C89880"},
                           {"label": "Ciechanowiec", "value": "C89881"},
                           {"label": "Czarna Bialostocka", "value": "C89882"},
                           {"label": "Czeremcha", "value": "C89883"},
                           {"label": "Czyzew", "value": "C89884"},
                           {"label": "Dabrowa Bialostocka", "value": "C89885"},
                           {"label": "Dobrzyniewo Duze", "value": "C89886"},
                           {"label": "Drohiczyn", "value": "C89887"},
                           {"label": "Filipow", "value": "C89888"},
                           {"label": "Goniadz", "value": "C89889"},
                           {"label": "Grajewo", "value": "C89890"},
                           {"label": "Grudki", "value": "C89891"},
                           {"label": "Hajnowka", "value": "C89892"},
                           {"label": "Jedwabne", "value": "C89893"},
                           {"label": "Kleszczele", "value": "C89894"},
                           {"label": "Knyszyn", "value": "C89895"},
                           {"label": "Kolno", "value": "C89896"},
                           {"label": "Krasnopol", "value": "C89897"},
                           {"label": "Krynki", "value": "C89898"},
                           {"label": "Kuznica", "value": "C89899"},
                           {"label": "Lipsk", "value": "C89900"},
                           {"label": "Lapy", "value": "C89901"},
                           {"label": "Lomza", "value": "C89902"},
                           {"label": "Maly Plock", "value": "C89903"},
                           {"label": "Michalowo", "value": "C89904"},
                           {"label": "Milejczyce", "value": "C89905"},
                           {"label": "Monki", "value": "C89906"},
                           {"label": "Narew", "value": "C89907"},
                           {"label": "Nowogrod", "value": "C89908"},
                           {"label": "Nurzec-Stacja", "value": "C89909"},
                           {"label": "Orla", "value": "C89910"},
                           {"label": "Piatnica", "value": "C89911"},
                           {"label": "Powiat augustowski", "value": "C89912"},
                           {"label": "Powiat bialostocki", "value": "C89913"},
                           {"label": "Powiat bielski", "value": "C89914"},
                           {"label": "Powiat grajewski", "value": "C89915"},
                           {"label": "Powiat hajnowski", "value": "C89916"},
                           {"label": "Powiat kolnenski", "value": "C89917"},
                           {"label": "Powiat lomzynski", "value": "C89918"},
                           {"label": "Powiat moniecki", "value": "C89919"},
                           {"label": "Powiat sejnenski", "value": "C89920"},
                           {"label": "Powiat siemiatycki", "value": "C89921"},
                           {"label": "Powiat sokolski", "value": "C89922"},
                           {"label": "Powiat suwalski", "value": "C89923"},
                           {"label": "Powiat wysokomazowiecki",
                            "value": "C89924"},
                           {"label": "Powiat zambrowski", "value": "C89925"},
                           {"label": "Punsk", "value": "C89926"},
                           {"label": "Raczki", "value": "C89927"},
                           {"label": "Radzilow", "value": "C89928"},
                           {"label": "Rajgrod", "value": "C89929"},
                           {"label": "Rudka", "value": "C89930"},
                           {"label": "Rutki-Kossaki", "value": "C89931"},
                           {"label": "Sejny", "value": "C89932"},
                           {"label": "Siemiatycze", "value": "C89933"},
                           {"label": "Sniadowo", "value": "C89934"},
                           {"label": "Sokolka", "value": "C89935"},
                           {"label": "Sokoly", "value": "C89936"},
                           {"label": "Stawiski", "value": "C89937"},
                           {"label": "Suchowola", "value": "C89938"},
                           {"label": "Suprasl", "value": "C89939"},
                           {"label": "Suraz", "value": "C89940"},
                           {"label": "Suwalki", "value": "C89941"},
                           {"label": "Szczuczyn", "value": "C89942"},
                           {"label": "Szepietowo", "value": "C89943"},
                           {"label": "Szumowo", "value": "C89944"},
                           {"label": "Turosn Koscielna", "value": "C89945"},
                           {"label": "Tykocin", "value": "C89946"},
                           {"label": "Wasilkow", "value": "C89947"},
                           {"label": "Wasosz", "value": "C89948"},
                           {"label": "Wizna", "value": "C89949"},
                           {"label": "Wysokie Mazowieckie", "value": "C89950"},
                           {"label": "Wyszki", "value": "C89951"},
                           {"label": "Zabludow", "value": "C89952"},
                           {"label": "Zambrow", "value": "C89953"},
                           {"label": "Zbojna", "value": "C89954"}],
 "Podlehnik Municipality": [{"label": "Podlehnik", "value": "C107763"}],
 "Podvelka Municipality": [{"label": "Podvelka", "value": "C107764"}],
 "Pohnpei State": [{"label": "Kitti Municipality", "value": "C75451"},
                   {"label": "Kolonia", "value": "C75452"},
                   {"label": "Kolonia Municipality", "value": "C75453"},
                   {"label": "Kolonia Town", "value": "C75454"},
                   {"label": "Madolenihm Municipality", "value": "C75455"},
                   {"label": "Mokil Municipality", "value": "C75456"},
                   {"label": "Nett Municipality", "value": "C75457"},
                   {"label": "Ngatik", "value": "C75458"},
                   {"label": "Nukuoro Municipality", "value": "C75459"},
                   {"label": "Palikir - National Government Center",
                    "value": "C75460"},
                   {"label": "Pingelap Municipality", "value": "C75461"},
                   {"label": "Sapwuahfik Municipality", "value": "C75462"},
                   {"label": "Sokehs Municipality", "value": "C75463"},
                   {"label": "U Municipality", "value": "C75464"}],
 "Point Fortin": [{"label": "Point Fortin", "value": "C119665"}],
 "Pointe-Noire": [{"label": "Loandjili", "value": "C20758"},
                  {"label": "Pointe-Noire", "value": "C20759"}],
 "Poltavska oblast": [{"label": "Abramok", "value": "C122497"},
                      {"label": "Adamivka", "value": "C122498"},
                      {"label": "Adamove", "value": "C122499"},
                      {"label": "Agativka", "value": "C122500"},
                      {"label": "Andriyivka", "value": "C122501"},
                      {"label": "Andrushivka", "value": "C122502"},
                      {"label": "Autozavodskoy Raion", "value": "C122503"},
                      {"label": "Avratin", "value": "C122504"},
                      {"label": "Bairak", "value": "C122505"},
                      {"label": "Baliasne", "value": "C122506"},
                      {"label": "Baranivka", "value": "C122507"},
                      {"label": "Baranivka", "value": "C122508"},
                      {"label": "Barashi", "value": "C122509"},
                      {"label": "Bazar", "value": "C122510"},
                      {"label": "Bekhi", "value": "C122511"},
                      {"label": "Berdychiv", "value": "C122512"},
                      {"label": "Berestivka", "value": "C122513"},
                      {"label": "Berestovets", "value": "C122514"},
                      {"label": "Berezianka", "value": "C122515"},
                      {"label": "Berezovii Grud", "value": "C122516"},
                      {"label": "Bereztsi", "value": "C122517"},
                      {"label": "Bicheva", "value": "C122518"},
                      {"label": "Bigun", "value": "C122519"},
                      {"label": "Bikiv", "value": "C122520"},
                      {"label": "Bila Krynytsia", "value": "C122521"},
                      {"label": "Bilii Bereg", "value": "C122522"},
                      {"label": "Bilka", "value": "C122523"},
                      {"label": "Bilylivka", "value": "C122524"},
                      {"label": "Bistrik", "value": "C122525"},
                      {"label": "Bistriyivka", "value": "C122526"},
                      {"label": "Borisivka", "value": "C122527"},
                      {"label": "Broniki", "value": "C122528"},
                      {"label": "Bronitsia", "value": "C122529"},
                      {"label": "Bronitska Guta", "value": "C122530"},
                      {"label": "Brovki Pershi", "value": "C122531"},
                      {"label": "Brusyliv", "value": "C122532"},
                      {"label": "Buchmany", "value": "C122533"},
                      {"label": "Buki", "value": "C122534"},
                      {"label": "Buldichiv", "value": "C122535"},
                      {"label": "Buriaki", "value": "C122536"},
                      {"label": "Burkivtsi", "value": "C122537"},
                      {"label": "Bykivka", "value": "C122538"},
                      {"label": "Chernechii Iar", "value": "C122539"},
                      {"label": "Cherniakhiv", "value": "C122540"},
                      {"label": "Chervone", "value": "C122541"},
                      {"label": "Chopovychi", "value": "C122542"},
                      {"label": "Chornukhy", "value": "C122543"},
                      {"label": "Chudniv", "value": "C122544"},
                      {"label": "Chutove", "value": "C122545"},
                      {"label": "Dashynka", "value": "C122546"},
                      {"label": "Davidivka", "value": "C122547"},
                      {"label": "Davydky", "value": "C122548"},
                      {"label": "Denyshi", "value": "C122549"},
                      {"label": "Derhanivka", "value": "C122550"},
                      {"label": "Dibrova", "value": "C122551"},
                      {"label": "Didkovichi", "value": "C122552"},
                      {"label": "Didovichi", "value": "C122553"},
                      {"label": "Divochki", "value": "C122554"},
                      {"label": "Dovbysh", "value": "C122555"},
                      {"label": "Druzhba", "value": "C122556"},
                      {"label": "Dubivka", "value": "C122557"},
                      {"label": "Dubnyki", "value": "C122558"},
                      {"label": "Dubrivka", "value": "C122559"},
                      {"label": "Dvorishche", "value": "C122560"},
                      {"label": "Dykanka", "value": "C122561"},
                      {"label": "Elivka", "value": "C122562"},
                      {"label": "Fedorivka", "value": "C122563"},
                      {"label": "Filenkove", "value": "C122564"},
                      {"label": "Glinivtsi", "value": "C122565"},
                      {"label": "Godikha", "value": "C122566"},
                      {"label": "Golovenka", "value": "C122567"},
                      {"label": "Golovki", "value": "C122568"},
                      {"label": "Golubyatin", "value": "C122569"},
                      {"label": "Gorbuliv", "value": "C122570"},
                      {"label": "Gordiyivka", "value": "C122571"},
                      {"label": "Gorianshchina", "value": "C122572"},
                      {"label": "Gorodkivka", "value": "C122573"},
                      {"label": "Gorodok", "value": "C122574"},
                      {"label": "Gorodske", "value": "C122575"},
                      {"label": "Goropayi", "value": "C122576"},
                      {"label": "Goshiv", "value": "C122577"},
                      {"label": "Gromada", "value": "C122578"},
                      {"label": "Grozyne", "value": "C122579"},
                      {"label": "Grushki", "value": "C122580"},
                      {"label": "Gubske", "value": "C122581"},
                      {"label": "Gulsk", "value": "C122582"},
                      {"label": "Gumenniki", "value": "C122583"},
                      {"label": "Guta-Potiyivka", "value": "C122584"},
                      {"label": "Guto-Mariatin", "value": "C122585"},
                      {"label": "Hadiach", "value": "C122586"},
                      {"label": "Hlobyne", "value": "C122587"},
                      {"label": "Holovyne", "value": "C122588"},
                      {"label": "Holubivka", "value": "C122589"},
                      {"label": "Horishni Plavni", "value": "C122590"},
                      {"label": "Horodets", "value": "C122591"},
                      {"label": "Horodnytsia", "value": "C122592"},
                      {"label": "Hradyzk", "value": "C122593"},
                      {"label": "Hranitne", "value": "C122594"},
                      {"label": "Hrebinka", "value": "C122595"},
                      {"label": "Hryshkivtsi", "value": "C122596"},
                      {"label": "Iemilivka", "value": "C122597"},
                      {"label": "Ievgenivka", "value": "C122598"},
                      {"label": "Irshansk", "value": "C122599"},
                      {"label": "Ivanivka", "value": "C122600"},
                      {"label": "Ivankiv", "value": "C122601"},
                      {"label": "Ivanopil", "value": "C122602"},
                      {"label": "Kamiani Potoky", "value": "C122603"},
                      {"label": "Kardashivka", "value": "C122604"},
                      {"label": "Karlivka", "value": "C122605"},
                      {"label": "Khodaky", "value": "C122606"},
                      {"label": "Khorol", "value": "C122607"},
                      {"label": "Khoroshiv", "value": "C122608"},
                      {"label": "Kobeliaky", "value": "C122609"},
                      {"label": "Kononenki", "value": "C122610"},
                      {"label": "Korchak", "value": "C122611"},
                      {"label": "Kornyn", "value": "C122612"},
                      {"label": "Korosten", "value": "C122613"},
                      {"label": "Korostyshiv", "value": "C122614"},
                      {"label": "Kotelva", "value": "C122615"},
                      {"label": "Kozelshchyna", "value": "C122616"},
                      {"label": "Kratova Govtva", "value": "C122617"},
                      {"label": "Kremenchuk", "value": "C122618"},
                      {"label": "Krukovskiy Raion", "value": "C122619"},
                      {"label": "Kucherivka", "value": "C122620"},
                      {"label": "Kupech", "value": "C122621"},
                      {"label": "Kvitneve", "value": "C122622"},
                      {"label": "Landari", "value": "C122623"},
                      {"label": "Lani", "value": "C122624"},
                      {"label": "Lasky", "value": "C122625"},
                      {"label": "Lazarivka", "value": "C122626"},
                      {"label": "Lisogirka", "value": "C122627"},
                      {"label": "Liubar", "value": "C122628"},
                      {"label": "Lokhvytsia", "value": "C122629"},
                      {"label": "Lubny", "value": "C122630"},
                      {"label": "Luhyny", "value": "C122631"},
                      {"label": "Lutovynivka", "value": "C122632"},
                      {"label": "Mala Rudka", "value": "C122633"},
                      {"label": "Malyn", "value": "C122634"},
                      {"label": "Marchenki", "value": "C122635"},
                      {"label": "Markivka", "value": "C122636"},
                      {"label": "Maschiwka", "value": "C122637"},
                      {"label": "Morozivka", "value": "C122638"},
                      {"label": "Myrhorod", "value": "C122639"},
                      {"label": "Myropil", "value": "C122640"},
                      {"label": "Narodychi", "value": "C122641"},
                      {"label": "Nedoharky", "value": "C122642"},
                      {"label": "Nemyryntsi", "value": "C122643"},
                      {"label": "Nova Borova", "value": "C122644"},
                      {"label": "Nova Chortoriia", "value": "C122645"},
                      {"label": "Novi Bilokorovychi", "value": "C122646"},
                      {"label": "Novi Sanzhary", "value": "C122647"},
                      {"label": "Novi Vorobyi", "value": "C122648"},
                      {"label": "Novohrad-Volynskyi", "value": "C122649"},
                      {"label": "Novoozerianka", "value": "C122650"},
                      {"label": "Nyvky", "value": "C122651"},
                      {"label": "Odariukivka", "value": "C122652"},
                      {"label": "Olefirshchina", "value": "C122653"},
                      {"label": "Olevsk", "value": "C122654"},
                      {"label": "Onatski", "value": "C122655"},
                      {"label": "Opishnia", "value": "C122656"},
                      {"label": "Orzhytsia", "value": "C122657"},
                      {"label": "Ovruch", "value": "C122658"},
                      {"label": "Ozerne", "value": "C122659"},
                      {"label": "Pavoloch", "value": "C122660"},
                      {"label": "Pershotravensk", "value": "C122661"},
                      {"label": "Pershotravneve", "value": "C122662"},
                      {"label": "Petrenki", "value": "C122663"},
                      {"label": "Pisarivshchina", "value": "C122664"},
                      {"label": "Pishchiv", "value": "C122665"},
                      {"label": "Polianka", "value": "C122666"},
                      {"label": "Poltava", "value": "C122667"},
                      {"label": "Popilnia", "value": "C122668"},
                      {"label": "Popivka", "value": "C122669"},
                      {"label": "Potiivka", "value": "C122670"},
                      {"label": "Proni", "value": "C122671"},
                      {"label": "Pyriatyn", "value": "C122672"},
                      {"label": "Radomyshl", "value": "C122673"},
                      {"label": "Reshetylivka", "value": "C122674"},
                      {"label": "Rohachi", "value": "C122675"},
                      {"label": "Romaniv", "value": "C122676"},
                      {"label": "Romodan", "value": "C122677"},
                      {"label": "Ruzhyn (settlement)", "value": "C122678"},
                      {"label": "Schyschaky", "value": "C122679"},
                      {"label": "Semenivka", "value": "C122680"},
                      {"label": "Sencha", "value": "C122681"},
                      {"label": "Shumsk", "value": "C122682"},
                      {"label": "Slovechne", "value": "C122683"},
                      {"label": "Smolovivshchina", "value": "C122684"},
                      {"label": "Sokhatska Balka", "value": "C122685"},
                      {"label": "Stepanivka", "value": "C122686"},
                      {"label": "Sudivka", "value": "C122687"},
                      {"label": "Tabory", "value": "C122688"},
                      {"label": "Taraschanka", "value": "C122689"},
                      {"label": "Topory", "value": "C122690"},
                      {"label": "Travneve", "value": "C122691"},
                      {"label": "Troiany", "value": "C122692"},
                      {"label": "Vasilivka", "value": "C122693"},
                      {"label": "Velika Rudka", "value": "C122694"},
                      {"label": "Veliki Budishcha", "value": "C122695"},
                      {"label": "Velyka Bahachka", "value": "C122696"},
                      {"label": "Velyki Korovyntsi", "value": "C122697"},
                      {"label": "Velyki Sorochyntsi", "value": "C122698"},
                      {"label": "Veresna", "value": "C122699"},
                      {"label": "Veselivka", "value": "C122700"},
                      {"label": "Virlya", "value": "C122701"},
                      {"label": "Vodiana Balka", "value": "C122702"},
                      {"label": "Yablunets", "value": "C122703"},
                      {"label": "Yarun", "value": "C122704"},
                      {"label": "Yemilchyne", "value": "C122705"},
                      {"label": "Zabaro-Davidivka", "value": "C122706"},
                      {"label": "Zadovga", "value": "C122707"},
                      {"label": "Zakrinichchia", "value": "C122708"},
                      {"label": "Zalissia", "value": "C122709"},
                      {"label": "Zaliznia", "value": "C122710"},
                      {"label": "Zaluzhne", "value": "C122711"},
                      {"label": "Zapadnia", "value": "C122712"},
                      {"label": "Zarichchia", "value": "C122713"},
                      {"label": "Zarudintsi", "value": "C122714"},
                      {"label": "Zavodske", "value": "C122715"},
                      {"label": "Zbranki", "value": "C122716"},
                      {"label": "Zdorovets", "value": "C122717"},
                      {"label": "Zherdeli", "value": "C122718"},
                      {"label": "Zhitintsi", "value": "C122719"},
                      {"label": "Zhovte", "value": "C122720"},
                      {"label": "Zhovtii Brid", "value": "C122721"},
                      {"label": "Zhupanivka", "value": "C122722"},
                      {"label": "Zhurbintsi", "value": "C122723"},
                      {"label": "Zhytomyr", "value": "C122724"},
                      {"label": "Zinkiv", "value": "C122725"},
                      {"label": "Zlobichi", "value": "C122726"},
                      {"label": "Zoriane", "value": "C122727"},
                      {"label": "Zorokiv", "value": "C122728"},
                      {"label": "Zosimivka", "value": "C122729"},
                      {"label": "Zubivshchina", "value": "C122730"},
                      {"label": "Zvizdal", "value": "C122731"}],
 "Polva County": [{"label": "Kanepi", "value": "C24604"},
                  {"label": "Kanepi vald", "value": "C24605"},
                  {"label": "Polva", "value": "C24606"},
                  {"label": "Polva vald", "value": "C24607"},
                  {"label": "Rapina", "value": "C24608"},
                  {"label": "Rapina vald", "value": "C24609"}],
 "Polzela Municipality": [{"label": "Polzela", "value": "C107765"}],
 "Pomeranian Voivodeship": [{"label": "Banino", "value": "C89955"},
                            {"label": "Bobowo", "value": "C89956"},
                            {"label": "Bojano", "value": "C89957"},
                            {"label": "Bolszewo", "value": "C89958"},
                            {"label": "Borzytuchom", "value": "C89959"},
                            {"label": "Brusy", "value": "C89960"},
                            {"label": "Bytow", "value": "C89961"},
                            {"label": "Cedry Wielkie", "value": "C89962"},
                            {"label": "Cewice", "value": "C89963"},
                            {"label": "Chlapowo", "value": "C89964"},
                            {"label": "Chmielno", "value": "C89965"},
                            {"label": "Choczewo", "value": "C89966"},
                            {"label": "Chojnice", "value": "C89967"},
                            {"label": "Chwaszczyno", "value": "C89968"},
                            {"label": "Czarna Dabrowka", "value": "C89969"},
                            {"label": "Czarna Woda", "value": "C89970"},
                            {"label": "Czarne", "value": "C89971"},
                            {"label": "Czersk", "value": "C89972"},
                            {"label": "Czluchow", "value": "C89973"},
                            {"label": "Damnica", "value": "C89974"},
                            {"label": "Debnica Kaszubska", "value": "C89975"},
                            {"label": "Debrzno", "value": "C89976"},
                            {"label": "Dziemiany", "value": "C89977"},
                            {"label": "Dzierzgon", "value": "C89978"},
                            {"label": "Garcz", "value": "C89979"},
                            {"label": "Gardeja", "value": "C89980"},
                            {"label": "Gdansk", "value": "C89981"},
                            {"label": "Gdynia", "value": "C89982"},
                            {"label": "Glowczyce", "value": "C89983"},
                            {"label": "Gniew", "value": "C89984"},
                            {"label": "Gniewino", "value": "C89985"},
                            {"label": "Gniezdzewo", "value": "C89986"},
                            {"label": "Goscicino", "value": "C89987"},
                            {"label": "Grabowo Koscierskie", "value": "C89988"},
                            {"label": "Hel", "value": "C89989"},
                            {"label": "Jastarnia", "value": "C89990"},
                            {"label": "Jastrzebia Gora", "value": "C89991"},
                            {"label": "Kaliska", "value": "C89992"},
                            {"label": "Karsin", "value": "C89993"},
                            {"label": "Kartuzy", "value": "C89994"},
                            {"label": "Kepice", "value": "C89995"},
                            {"label": "Kobylnica", "value": "C89996"},
                            {"label": "Koczala", "value": "C89997"},
                            {"label": "Kolbudy", "value": "C89998"},
                            {"label": "Koleczkowo", "value": "C89999"},
                            {"label": "Kolczyglowy", "value": "C90000"},
                            {"label": "Kosakowo", "value": "C90001"},
                            {"label": "Koscierzyna", "value": "C90002"},
                            {"label": "Kowale", "value": "C90003"},
                            {"label": "Krokowa", "value": "C90004"},
                            {"label": "Krynica Morska", "value": "C90005"},
                            {"label": "Kwidzyn", "value": "C90006"},
                            {"label": "Lebork", "value": "C90007"},
                            {"label": "Linia", "value": "C90008"},
                            {"label": "Liniewo", "value": "C90009"},
                            {"label": "Lipnica", "value": "C90010"},
                            {"label": "Lipusz", "value": "C90011"},
                            {"label": "Lisewo Malborskie", "value": "C90012"},
                            {"label": "Lubichowo", "value": "C90013"},
                            {"label": "Lublewo Gdanskie", "value": "C90014"},
                            {"label": "Luzino", "value": "C90015"},
                            {"label": "Leba", "value": "C90016"},
                            {"label": "Leczyce", "value": "C90017"},
                            {"label": "Legowo", "value": "C90018"},
                            {"label": "Malbork", "value": "C90019"},
                            {"label": "Miastko", "value": "C90020"},
                            {"label": "Mikolajki Pomorskie", "value": "C90021"},
                            {"label": "Miloradz", "value": "C90022"},
                            {"label": "Mosty", "value": "C90023"},
                            {"label": "Mrzezino", "value": "C90024"},
                            {"label": "Nowa Wies Leborska", "value": "C90025"},
                            {"label": "Nowy Dwor Gdanski", "value": "C90026"},
                            {"label": "Nowy Staw", "value": "C90027"},
                            {"label": "Orle", "value": "C90028"},
                            {"label": "Osiek", "value": "C90029"},
                            {"label": "Ostaszewo", "value": "C90030"},
                            {"label": "Parchowo", "value": "C90031"},
                            {"label": "Pelplin", "value": "C90032"},
                            {"label": "Pogorze", "value": "C90033"},
                            {"label": "Potegowo", "value": "C90034"},
                            {"label": "Powiat bytowski", "value": "C90035"},
                            {"label": "Powiat chojnicki", "value": "C90036"},
                            {"label": "Powiat czluchowski", "value": "C90037"},
                            {"label": "Powiat gdanski", "value": "C90038"},
                            {"label": "Powiat kartuski", "value": "C90039"},
                            {"label": "Powiat koscierski", "value": "C90040"},
                            {"label": "Powiat kwidzynski", "value": "C90041"},
                            {"label": "Powiat leborski", "value": "C90042"},
                            {"label": "Powiat malborski", "value": "C90043"},
                            {"label": "Powiat nowodworski", "value": "C90044"},
                            {"label": "Powiat pucki", "value": "C90045"},
                            {"label": "Powiat slupski", "value": "C90046"},
                            {"label": "Powiat starogardzki", "value": "C90047"},
                            {"label": "Powiat sztumski", "value": "C90048"},
                            {"label": "Powiat tczewski", "value": "C90049"},
                            {"label": "Powiat wejherowski", "value": "C90050"},
                            {"label": "Prabuty", "value": "C90051"},
                            {"label": "Pruszcz Gdanski", "value": "C90052"},
                            {"label": "Przechlewo", "value": "C90053"},
                            {"label": "Przodkowo", "value": "C90054"},
                            {"label": "Przywidz", "value": "C90055"},
                            {"label": "Pszczolki", "value": "C90056"},
                            {"label": "Puck", "value": "C90057"},
                            {"label": "Reda", "value": "C90058"},
                            {"label": "Rekowo Dolne", "value": "C90059"},
                            {"label": "Rotmanka", "value": "C90060"},
                            {"label": "Rumia", "value": "C90061"},
                            {"label": "Ryjewo", "value": "C90062"},
                            {"label": "Rzeczenica", "value": "C90063"},
                            {"label": "Sadlinki", "value": "C90064"},
                            {"label": "Sierakowice", "value": "C90065"},
                            {"label": "Skarszewy", "value": "C90066"},
                            {"label": "Skorcz", "value": "C90067"},
                            {"label": "Slupsk", "value": "C90068"},
                            {"label": "Smetowo Graniczne", "value": "C90069"},
                            {"label": "Smoldzino", "value": "C90070"},
                            {"label": "Somonino", "value": "C90071"},
                            {"label": "Sopot", "value": "C90072"},
                            {"label": "Stara Kiszewa", "value": "C90073"},
                            {"label": "Stare Pole", "value": "C90074"},
                            {"label": "Starogard Gdanski", "value": "C90075"},
                            {"label": "Stary Targ", "value": "C90076"},
                            {"label": "Stegna", "value": "C90077"},
                            {"label": "Stezyca", "value": "C90078"},
                            {"label": "Straszyn", "value": "C90079"},
                            {"label": "Strzelno", "value": "C90080"},
                            {"label": "Studzienice", "value": "C90081"},
                            {"label": "Subkowy", "value": "C90082"},
                            {"label": "Suchy Dab", "value": "C90083"},
                            {"label": "Suleczyno", "value": "C90084"},
                            {"label": "Szemud", "value": "C90085"},
                            {"label": "Szlachta", "value": "C90086"},
                            {"label": "Sztum", "value": "C90087"},
                            {"label": "Sztutowo", "value": "C90088"},
                            {"label": "Tczew", "value": "C90089"},
                            {"label": "Trabki Wielkie", "value": "C90090"},
                            {"label": "Trzebielino", "value": "C90091"},
                            {"label": "Tuchomie", "value": "C90092"},
                            {"label": "Ustka", "value": "C90093"},
                            {"label": "Wejherowo", "value": "C90094"},
                            {"label": "Wielki Kack", "value": "C90095"},
                            {"label": "Wierzchucino", "value": "C90096"},
                            {"label": "Wladyslawowo", "value": "C90097"},
                            {"label": "Zblewo", "value": "C90098"},
                            {"label": "Zelistrzewo", "value": "C90099"},
                            {"label": "Zukowo", "value": "C90100"}],
 "Pomeroon-Supenaam": [{"label": "Anna Regina", "value": "C43075"}],
 "Pool Department": [{"label": "Kinkala", "value": "C20760"}],
 "Port Glaud": [{"label": "Port Glaud", "value": "C107235"}],
 "Port Louis District": [{"label": "Port Louis", "value": "C66038"}],
 "Port Moresby": [{"label": "National Capital District", "value": "C80458"},
                  {"label": "Port Moresby", "value": "C80459"}],
 "Port Said": [{"label": "Port Said", "value": "C24314"}],
 "Port of Spain": [{"label": "Mucurapo", "value": "C119666"},
                   {"label": "Port of Spain", "value": "C119667"}],
 "Portalegre": [{"label": "Alter do Chao", "value": "C91792"},
                {"label": "Arronches", "value": "C91793"},
                {"label": "Atalaia", "value": "C91794"},
                {"label": "Avis", "value": "C91795"},
                {"label": "Campo Maior", "value": "C91796"},
                {"label": "Castelo de Vide", "value": "C91797"},
                {"label": "Crato", "value": "C91798"},
                {"label": "Elvas", "value": "C91799"},
                {"label": "Fronteira", "value": "C91800"},
                {"label": "Gaviao", "value": "C91801"},
                {"label": "Marvao", "value": "C91802"},
                {"label": "Monforte", "value": "C91803"},
                {"label": "Montargil", "value": "C91804"},
                {"label": "Nisa", "value": "C91805"},
                {"label": "Ponte de Sor", "value": "C91806"},
                {"label": "Portalegre", "value": "C91807"},
                {"label": "Santo Andre", "value": "C91808"},
                {"label": "Sousel", "value": "C91809"},
                {"label": "Vale da Amoreira", "value": "C91810"}],
 "Portland Parish": [{"label": "Balcarres", "value": "C61906"},
                     {"label": "Bangor Ridge", "value": "C61907"},
                     {"label": "Belvedere", "value": "C61908"},
                     {"label": "Black Hill", "value": "C61909"},
                     {"label": "Boundbrook", "value": "C61910"},
                     {"label": "Breastworks", "value": "C61911"},
                     {"label": "Buff Bay", "value": "C61912"},
                     {"label": "Bybrook", "value": "C61913"},
                     {"label": "Cascade", "value": "C61914"},
                     {"label": "Castle Comfort", "value": "C61915"},
                     {"label": "Central Port Antonio", "value": "C61916"},
                     {"label": "Charles Town", "value": "C61917"},
                     {"label": "Claverty Cottage", "value": "C61918"},
                     {"label": "Comfort Castle", "value": "C61919"},
                     {"label": "Drapers", "value": "C61920"},
                     {"label": "Durham", "value": "C61921"},
                     {"label": "Fairy Hill", "value": "C61922"},
                     {"label": "Fellowship", "value": "C61923"},
                     {"label": "Fruitfulvale", "value": "C61924"},
                     {"label": "Hectors River", "value": "C61925"},
                     {"label": "Hope Bay", "value": "C61926"},
                     {"label": "Kensington", "value": "C61927"},
                     {"label": "Long Bay", "value": "C61928"},
                     {"label": "Long Road", "value": "C61929"},
                     {"label": "Manchioneal", "value": "C61930"},
                     {"label": "Moore Town", "value": "C61931"},
                     {"label": "Mount Pleasant", "value": "C61932"},
                     {"label": "Nonsuch", "value": "C61933"},
                     {"label": "Norwich", "value": "C61934"},
                     {"label": "Orange Bay", "value": "C61935"},
                     {"label": "Port Antonio", "value": "C61936"},
                     {"label": "Prospect", "value": "C61937"},
                     {"label": "Rock Hall", "value": "C61938"},
                     {"label": "Sherwood Forest", "value": "C61939"},
                     {"label": "Shirley Castle", "value": "C61940"},
                     {"label": "Skibo", "value": "C61941"},
                     {"label": "Snow Hill", "value": "C61942"},
                     {"label": "Spring Hill", "value": "C61943"},
                     {"label": "St. Margarets Bay", "value": "C61944"},
                     {"label": "Swift River", "value": "C61945"},
                     {"label": "Tranquility", "value": "C61946"},
                     {"label": "Windsor", "value": "C61947"},
                     {"label": "Windsor Castle", "value": "C61948"},
                     {"label": "Windsor Forest", "value": "C61949"}],
 "Porto": [{"label": "agua Longa", "value": "C91811"},
           {"label": "aguas Santas", "value": "C91812"},
           {"label": "Agucadoura", "value": "C91813"},
           {"label": "Alfena", "value": "C91814"},
           {"label": "Alpendurada", "value": "C91815"},
           {"label": "Amarante", "value": "C91816"},
           {"label": "Amorim", "value": "C91817"},
           {"label": "Anta", "value": "C91818"},
           {"label": "Arcozelo", "value": "C91819"},
           {"label": "Argivai", "value": "C91820"},
           {"label": "arvore", "value": "C91821"},
           {"label": "Aver-o-Mar", "value": "C91822"},
           {"label": "Aves", "value": "C91823"},
           {"label": "Avintes", "value": "C91824"},
           {"label": "Azenha", "value": "C91825"},
           {"label": "Baguim do Monte", "value": "C91826"},
           {"label": "Baiao", "value": "C91827"},
           {"label": "Balazar", "value": "C91828"},
           {"label": "Baltar", "value": "C91829"},
           {"label": "Barrosas", "value": "C91830"},
           {"label": "Beiriz de Baixo", "value": "C91831"},
           {"label": "Bougado", "value": "C91832"},
           {"label": "Campo", "value": "C91833"},
           {"label": "Canelas", "value": "C91834"},
           {"label": "Canidelo", "value": "C91835"},
           {"label": "Carvalhosa", "value": "C91836"},
           {"label": "Casteloes de Cepeda", "value": "C91837"},
           {"label": "Ermesinde", "value": "C91838"},
           {"label": "Estela", "value": "C91839"},
           {"label": "Fanzeres", "value": "C91840"},
           {"label": "Felgueiras", "value": "C91841"},
           {"label": "Ferreira", "value": "C91842"},
           {"label": "Figueiro", "value": "C91843"},
           {"label": "Foz do Douro", "value": "C91844"},
           {"label": "Foz do Sousa", "value": "C91845"},
           {"label": "Frazao", "value": "C91846"},
           {"label": "Freamunde", "value": "C91847"},
           {"label": "Gandra", "value": "C91848"},
           {"label": "Gemunde", "value": "C91849"},
           {"label": "Gondomar", "value": "C91850"},
           {"label": "Grijo", "value": "C91851"},
           {"label": "Guifoes", "value": "C91852"},
           {"label": "Gulpilhares", "value": "C91853"},
           {"label": "Jovim", "value": "C91854"},
           {"label": "Lavra", "value": "C91855"},
           {"label": "Leca da Palmeira", "value": "C91856"},
           {"label": "Leca do Bailio", "value": "C91857"},
           {"label": "Lordelo", "value": "C91858"},
           {"label": "Lousada", "value": "C91859"},
           {"label": "Lustosa", "value": "C91860"},
           {"label": "Madalena", "value": "C91861"},
           {"label": "Maia", "value": "C91862"},
           {"label": "Marco de Canaveses", "value": "C91863"},
           {"label": "Marco de Canavezes", "value": "C91864"},
           {"label": "Margaride", "value": "C91865"},
           {"label": "Matosinhos", "value": "C91866"},
           {"label": "Meinedo", "value": "C91867"},
           {"label": "Melres", "value": "C91868"},
           {"label": "Milheiros", "value": "C91869"},
           {"label": "Mindelo", "value": "C91870"},
           {"label": "Moreira", "value": "C91871"},
           {"label": "Negrelos", "value": "C91872"},
           {"label": "Nogueira", "value": "C91873"},
           {"label": "Olival", "value": "C91874"},
           {"label": "Oliveira do Douro", "value": "C91875"},
           {"label": "Pacos de Ferreira", "value": "C91876"},
           {"label": "Paredes", "value": "C91877"},
           {"label": "Pedroso", "value": "C91878"},
           {"label": "Pedroucos", "value": "C91879"},
           {"label": "Penafiel", "value": "C91880"},
           {"label": "Perafita", "value": "C91881"},
           {"label": "Perozinho", "value": "C91882"},
           {"label": "Porto", "value": "C91883"},
           {"label": "Povoa de Varzim", "value": "C91884"},
           {"label": "Rebordoes", "value": "C91885"},
           {"label": "Rebordosa", "value": "C91886"},
           {"label": "Recarei", "value": "C91887"},
           {"label": "Rio Mau", "value": "C91888"},
           {"label": "Rio Tinto", "value": "C91889"},
           {"label": "Sandim", "value": "C91890"},
           {"label": "Santa Cruz do Bispo", "value": "C91891"},
           {"label": "Santo Izidoro", "value": "C91892"},
           {"label": "Santo Tirso", "value": "C91893"},
           {"label": "Sao Felix da Marinha", "value": "C91894"},
           {"label": "Sao Mamede de Infesta", "value": "C91895"},
           {"label": "Sao Miguel do Couto", "value": "C91896"},
           {"label": "Sao Pedro da Cova", "value": "C91897"},
           {"label": "Sao Romao do Coronado", "value": "C91898"},
           {"label": "Senhora da Hora", "value": "C91899"},
           {"label": "Sermonde", "value": "C91900"},
           {"label": "Seroa", "value": "C91901"},
           {"label": "Serzedo", "value": "C91902"},
           {"label": "Sobrado", "value": "C91903"},
           {"label": "Teloes", "value": "C91904"},
           {"label": "Trofa", "value": "C91905"},
           {"label": "Vairao", "value": "C91906"},
           {"label": "Valadares", "value": "C91907"},
           {"label": "Valbom", "value": "C91908"},
           {"label": "Valongo", "value": "C91909"},
           {"label": "Vila do Conde", "value": "C91910"},
           {"label": "Vila Mea", "value": "C91911"},
           {"label": "Vila Nova da Telha", "value": "C91912"},
           {"label": "Vila Nova de Gaia", "value": "C91913"},
           {"label": "Vilar de Andorinho", "value": "C91914"},
           {"label": "Vilar do Paraiso", "value": "C91915"},
           {"label": "Vilarinho", "value": "C91916"},
           {"label": "Vizela", "value": "C91917"}],
 "Porto Novo": [{"label": "Porto Novo", "value": "C17789"}],
 "Portuguesa": [{"label": "Acarigua", "value": "C147224"},
                {"label": "Araure", "value": "C147225"},
                {"label": "Guanare", "value": "C147226"},
                {"label": "Villa Bruzual", "value": "C147227"}],
 "Postojna Municipality": [{"label": "Postojna", "value": "C107766"}],
 "Potaro-Siparuni": [{"label": "Mahdia", "value": "C43076"}],
 "Potosi Department": [{"label": "Atocha", "value": "C9791"},
                       {"label": "Betanzos", "value": "C9792"},
                       {"label": "Colchani", "value": "C9793"},
                       {"label": "Colquechaca", "value": "C9794"},
                       {"label": "Enrique Baldivieso", "value": "C9795"},
                       {"label": "Llallagua", "value": "C9796"},
                       {"label": "Potosi", "value": "C9797"},
                       {"label": "Provincia Alonzo de Ibanez",
                        "value": "C9798"},
                       {"label": "Provincia Charcas", "value": "C9799"},
                       {"label": "Provincia Chayanta", "value": "C9800"},
                       {"label": "Provincia Daniel Campos", "value": "C9801"},
                       {"label": "Provincia General Bilbao", "value": "C9802"},
                       {"label": "Provincia Linares", "value": "C9803"},
                       {"label": "Provincia Modesto Omiste", "value": "C9804"},
                       {"label": "Provincia Nor Chichas", "value": "C9805"},
                       {"label": "Provincia Nor Lipez", "value": "C9806"},
                       {"label": "Provincia Quijarro", "value": "C9807"},
                       {"label": "Provincia Rafael Bustillo", "value": "C9808"},
                       {"label": "Provincia Saavedra", "value": "C9809"},
                       {"label": "Provincia Sud Chichas", "value": "C9810"},
                       {"label": "Provincia Sud Lipez", "value": "C9811"},
                       {"label": "Provincia Tomas Frias", "value": "C9812"},
                       {"label": "Santa Barbara", "value": "C9813"},
                       {"label": "Tupiza", "value": "C9814"},
                       {"label": "Uyuni", "value": "C9815"},
                       {"label": "Villazon", "value": "C9816"}],
 "Pozega-Slavonia": [{"label": "Brestovac", "value": "C21286"},
                     {"label": "Grad Pakrac", "value": "C21287"},
                     {"label": "Grad Pozega", "value": "C21288"},
                     {"label": "Jaksic", "value": "C21289"},
                     {"label": "Kaptol", "value": "C21290"},
                     {"label": "Kutjevo", "value": "C21291"},
                     {"label": "Lipik", "value": "C21292"},
                     {"label": "Pakrac", "value": "C21293"},
                     {"label": "Pleternica", "value": "C21294"},
                     {"label": "Pozega", "value": "C21295"},
                     {"label": "Velika", "value": "C21296"},
                     {"label": "Vidovci", "value": "C21297"}],
 "Prachin Buri": [{"label": "Amphoe Ban Sang", "value": "C119174"},
                  {"label": "Amphoe Kabin Buri", "value": "C119175"},
                  {"label": "Amphoe Mueang Prachin Buri", "value": "C119176"},
                  {"label": "Amphoe Na Di", "value": "C119177"},
                  {"label": "Amphoe Prachantakham", "value": "C119178"},
                  {"label": "Amphoe Si Maha Phot", "value": "C119179"},
                  {"label": "Amphoe Si Mahosot", "value": "C119180"},
                  {"label": "Kabin Buri", "value": "C119181"},
                  {"label": "Prachin Buri", "value": "C119182"}],
 "Prachuap Khiri Khan": [{"label": "Amphoe Bang Saphan", "value": "C119183"},
                         {"label": "Amphoe Bang Saphan Noi",
                          "value": "C119184"},
                         {"label": "Amphoe Hua Hin", "value": "C119185"},
                         {"label": "Amphoe Kui Buri", "value": "C119186"},
                         {"label": "Amphoe Mueang Prachuap Khiri Khan",
                          "value": "C119187"},
                         {"label": "Amphoe Pran Buri", "value": "C119188"},
                         {"label": "Amphoe Sam Roi Yot", "value": "C119189"},
                         {"label": "Amphoe Thap Sakae", "value": "C119190"},
                         {"label": "Bang Saphan", "value": "C119191"},
                         {"label": "Hua Hin", "value": "C119192"},
                         {"label": "Kui Buri", "value": "C119193"},
                         {"label": "Prachuap Khiri Khan", "value": "C119194"},
                         {"label": "Pran Buri", "value": "C119195"},
                         {"label": "Sam Roi Yot", "value": "C119196"}],
 "Praha, Hlavni mesto": [{"label": "Branik", "value": "C22902"},
                         {"label": "Cerny Most", "value": "C22903"},
                         {"label": "Dolni Pocernice", "value": "C22904"},
                         {"label": "Hodkovicky", "value": "C22905"},
                         {"label": "Horni Pocernice", "value": "C22906"},
                         {"label": "Hostavice", "value": "C22907"},
                         {"label": "Karlin", "value": "C22908"},
                         {"label": "Kbely", "value": "C22909"},
                         {"label": "Letnany", "value": "C22910"},
                         {"label": "Liben", "value": "C22911"},
                         {"label": "Mala Strana", "value": "C22912"},
                         {"label": "Modrany", "value": "C22913"},
                         {"label": "Prague", "value": "C22914"},
                         {"label": "Praha 1", "value": "C22915"},
                         {"label": "Praha 16", "value": "C22916"},
                         {"label": "Praha 20", "value": "C22917"},
                         {"label": "Praha 21", "value": "C22918"},
                         {"label": "Prosek", "value": "C22919"},
                         {"label": "Satalice", "value": "C22920"},
                         {"label": "Stare Mesto", "value": "C22921"},
                         {"label": "Strizkov", "value": "C22922"},
                         {"label": "Vysehrad", "value": "C22923"},
                         {"label": "Vysocany", "value": "C22924"}],
 "Prahova County": [{"label": "Adunati", "value": "C97999"},
                    {"label": "Albesti-Muru", "value": "C98000"},
                    {"label": "Albesti-Paleologu", "value": "C98001"},
                    {"label": "Alunis", "value": "C98002"},
                    {"label": "Apostolache", "value": "C98003"},
                    {"label": "Aricestii Zeletin", "value": "C98004"},
                    {"label": "Aricestii-Rahtivani", "value": "C98005"},
                    {"label": "Azuga", "value": "C98006"},
                    {"label": "Baba Ana", "value": "C98007"},
                    {"label": "Baicoi", "value": "C98008"},
                    {"label": "Balta Doamnei", "value": "C98009"},
                    {"label": "Baltesti", "value": "C98010"},
                    {"label": "Banesti", "value": "C98011"},
                    {"label": "Barcanesti", "value": "C98012"},
                    {"label": "Batesti", "value": "C98013"},
                    {"label": "Batrani", "value": "C98014"},
                    {"label": "Berceni", "value": "C98015"},
                    {"label": "Bertea", "value": "C98016"},
                    {"label": "Blejoi", "value": "C98017"},
                    {"label": "Bobolia", "value": "C98018"},
                    {"label": "Boldesti", "value": "C98019"},
                    {"label": "Boldesti-Scaeni", "value": "C98020"},
                    {"label": "Bordenii Mari", "value": "C98021"},
                    {"label": "Brazii de Sus", "value": "C98022"},
                    {"label": "Breaza", "value": "C98023"},
                    {"label": "Breaza de Jos", "value": "C98024"},
                    {"label": "Breaza de Sus", "value": "C98025"},
                    {"label": "Brebu Manastirei", "value": "C98026"},
                    {"label": "Brebu Megiesesc", "value": "C98027"},
                    {"label": "Bucov", "value": "C98028"},
                    {"label": "Buda", "value": "C98029"},
                    {"label": "Bughea de Jos", "value": "C98030"},
                    {"label": "Busteni", "value": "C98031"},
                    {"label": "Calugareni", "value": "C98032"},
                    {"label": "Campina", "value": "C98033"},
                    {"label": "Carbunesti", "value": "C98034"},
                    {"label": "Catina", "value": "C98035"},
                    {"label": "Ceptura de Jos", "value": "C98036"},
                    {"label": "Ceptura de Sus", "value": "C98037"},
                    {"label": "Cerasu", "value": "C98038"},
                    {"label": "Chiojdeanca", "value": "C98039"},
                    {"label": "Chitorani", "value": "C98040"},
                    {"label": "Cioranii de Jos", "value": "C98041"},
                    {"label": "Cioranii de Sus", "value": "C98042"},
                    {"label": "Coada Izvorului", "value": "C98043"},
                    {"label": "Cocorastii Colt", "value": "C98044"},
                    {"label": "Cocorastii Mislii", "value": "C98045"},
                    {"label": "Colceag", "value": "C98046"},
                    {"label": "Comarnic", "value": "C98047"},
                    {"label": "Comuna Adunati", "value": "C98048"},
                    {"label": "Comuna Albesti-Paleologu", "value": "C98049"},
                    {"label": "Comuna Alunis", "value": "C98050"},
                    {"label": "Comuna Apostolache", "value": "C98051"},
                    {"label": "Comuna Aricestii Zeletin", "value": "C98052"},
                    {"label": "Comuna Aricestii-Rahtivani", "value": "C98053"},
                    {"label": "Comuna Baba Ana", "value": "C98054"},
                    {"label": "Comuna Balta Doamnei", "value": "C98055"},
                    {"label": "Comuna Baltesti", "value": "C98056"},
                    {"label": "Comuna Banesti", "value": "C98057"},
                    {"label": "Comuna Barcanesti", "value": "C98058"},
                    {"label": "Comuna Batrani", "value": "C98059"},
                    {"label": "Comuna Berceni", "value": "C98060"},
                    {"label": "Comuna Bertea", "value": "C98061"},
                    {"label": "Comuna Blejoi", "value": "C98062"},
                    {"label": "Comuna Boldesti-Gradistea", "value": "C98063"},
                    {"label": "Comuna Brazi", "value": "C98064"},
                    {"label": "Comuna Brebu", "value": "C98065"},
                    {"label": "Comuna Bucov", "value": "C98066"},
                    {"label": "Comuna Calugareni", "value": "C98067"},
                    {"label": "Comuna Carbunesti", "value": "C98068"},
                    {"label": "Comuna Ceptura", "value": "C98069"},
                    {"label": "Comuna Cerasu", "value": "C98070"},
                    {"label": "Comuna Chiojdeanca", "value": "C98071"},
                    {"label": "Comuna Ciorani", "value": "C98072"},
                    {"label": "Comuna Cocorastii Mislii", "value": "C98073"},
                    {"label": "Comuna Cocorastii-Colt", "value": "C98074"},
                    {"label": "Comuna Colceag", "value": "C98075"},
                    {"label": "Comuna Cornu", "value": "C98076"},
                    {"label": "Comuna Cosminele", "value": "C98077"},
                    {"label": "Comuna Draganesti", "value": "C98078"},
                    {"label": "Comuna Drajna de Sus", "value": "C98079"},
                    {"label": "Comuna Dumbrava", "value": "C98080"},
                    {"label": "Comuna Dumbravesti", "value": "C98081"},
                    {"label": "Comuna Fantanele", "value": "C98082"},
                    {"label": "Comuna Filipestii de Padure", "value": "C98083"},
                    {"label": "Comuna Filipestii de Targ", "value": "C98084"},
                    {"label": "Comuna Floresti", "value": "C98085"},
                    {"label": "Comuna Fulga", "value": "C98086"},
                    {"label": "Comuna Gherghita", "value": "C98087"},
                    {"label": "Comuna Gorgota", "value": "C98088"},
                    {"label": "Comuna Gornet", "value": "C98089"},
                    {"label": "Comuna Gornet-Cricov", "value": "C98090"},
                    {"label": "Comuna Gura Vadului", "value": "C98091"},
                    {"label": "Comuna Gura Vitioarei", "value": "C98092"},
                    {"label": "Comuna Iordacheanu", "value": "C98093"},
                    {"label": "Comuna Izvoarele", "value": "C98094"},
                    {"label": "Comuna Jugureni", "value": "C98095"},
                    {"label": "Comuna Lapos", "value": "C98096"},
                    {"label": "Comuna Lipanesti", "value": "C98097"},
                    {"label": "Comuna Magurele", "value": "C98098"},
                    {"label": "Comuna Magureni", "value": "C98099"},
                    {"label": "Comuna Maneciu", "value": "C98100"},
                    {"label": "Comuna Manesti", "value": "C98101"},
                    {"label": "Comuna Olari", "value": "C98102"},
                    {"label": "Comuna Pacureti", "value": "C98103"},
                    {"label": "Comuna Paulesti", "value": "C98104"},
                    {"label": "Comuna Plopu", "value": "C98105"},
                    {"label": "Comuna Podenii Noi", "value": "C98106"},
                    {"label": "Comuna Poiana Campina", "value": "C98107"},
                    {"label": "Comuna Poienarii Burchii", "value": "C98108"},
                    {"label": "Comuna Posesti", "value": "C98109"},
                    {"label": "Comuna Predeal-Sarari", "value": "C98110"},
                    {"label": "Comuna Provita de Jos", "value": "C98111"},
                    {"label": "Comuna Provita de Sus", "value": "C98112"},
                    {"label": "Comuna Puchenii Mari", "value": "C98113"},
                    {"label": "Comuna Rafov", "value": "C98114"},
                    {"label": "Comuna Salcia", "value": "C98115"},
                    {"label": "Comuna Salciile", "value": "C98116"},
                    {"label": "Comuna Sangeru", "value": "C98117"},
                    {"label": "Comuna Scorteni", "value": "C98118"},
                    {"label": "Comuna Secaria", "value": "C98119"},
                    {"label": "Comuna Sirna", "value": "C98120"},
                    {"label": "Comuna Soimari", "value": "C98121"},
                    {"label": "Comuna Sotrile", "value": "C98122"},
                    {"label": "Comuna Starchiojd", "value": "C98123"},
                    {"label": "Comuna Stefesti", "value": "C98124"},
                    {"label": "Comuna Surani", "value": "C98125"},
                    {"label": "Comuna Talea", "value": "C98126"},
                    {"label": "Comuna Targsoru Vechi", "value": "C98127"},
                    {"label": "Comuna Tataru", "value": "C98128"},
                    {"label": "Comuna Teisani", "value": "C98129"},
                    {"label": "Comuna Telega", "value": "C98130"},
                    {"label": "Comuna Tinosu", "value": "C98131"},
                    {"label": "Comuna Tomsani", "value": "C98132"},
                    {"label": "Comuna Vadu Sapat", "value": "C98133"},
                    {"label": "Comuna Valcanesti", "value": "C98134"},
                    {"label": "Comuna Valea Calugareasca", "value": "C98135"},
                    {"label": "Comuna Valea Doftanei", "value": "C98136"},
                    {"label": "Comuna Varbilau", "value": "C98137"},
                    {"label": "Conduratu", "value": "C98138"},
                    {"label": "Corlatesti", "value": "C98139"},
                    {"label": "Cornu de Jos", "value": "C98140"},
                    {"label": "Cornu de Sus", "value": "C98141"},
                    {"label": "Cosmina de Jos", "value": "C98142"},
                    {"label": "Crivina", "value": "C98143"},
                    {"label": "Dambu", "value": "C98144"},
                    {"label": "Ditesti", "value": "C98145"},
                    {"label": "Doftana", "value": "C98146"},
                    {"label": "Draganeasa", "value": "C98147"},
                    {"label": "Draganesti", "value": "C98148"},
                    {"label": "Drajna de Jos", "value": "C98149"},
                    {"label": "Drajna de Sus", "value": "C98150"},
                    {"label": "Dumbrava", "value": "C98151"},
                    {"label": "Dumbravesti", "value": "C98152"},
                    {"label": "Fagetu", "value": "C98153"},
                    {"label": "Fantanele", "value": "C98154"},
                    {"label": "Filipestii de Padure", "value": "C98155"},
                    {"label": "Filipestii de Targ", "value": "C98156"},
                    {"label": "Floresti", "value": "C98157"},
                    {"label": "Fulga de Jos", "value": "C98158"},
                    {"label": "Fulga de Sus", "value": "C98159"},
                    {"label": "Gageni", "value": "C98160"},
                    {"label": "Gheaba", "value": "C98161"},
                    {"label": "Gherghita", "value": "C98162"},
                    {"label": "Ghiosesti", "value": "C98163"},
                    {"label": "Gorgota", "value": "C98164"},
                    {"label": "Gornet", "value": "C98165"},
                    {"label": "Gornet-Cricov", "value": "C98166"},
                    {"label": "Goruna", "value": "C98167"},
                    {"label": "Gradistea", "value": "C98168"},
                    {"label": "Grosani", "value": "C98169"},
                    {"label": "Gura Beliei", "value": "C98170"},
                    {"label": "Gura Vadului", "value": "C98171"},
                    {"label": "Gura Vitioarei", "value": "C98172"},
                    {"label": "Homoraciu", "value": "C98173"},
                    {"label": "Inotesti", "value": "C98174"},
                    {"label": "Iordacheanu", "value": "C98175"},
                    {"label": "Izvoarele", "value": "C98176"},
                    {"label": "Jugureni", "value": "C98177"},
                    {"label": "Lapos", "value": "C98178"},
                    {"label": "Liliesti", "value": "C98179"},
                    {"label": "Lipanesti", "value": "C98180"},
                    {"label": "Livadea", "value": "C98181"},
                    {"label": "Loloiasca", "value": "C98182"},
                    {"label": "Lunca Prahovei", "value": "C98183"},
                    {"label": "Magula", "value": "C98184"},
                    {"label": "Magurele", "value": "C98185"},
                    {"label": "Magureni", "value": "C98186"},
                    {"label": "Maneciu-Ungureni", "value": "C98187"},
                    {"label": "Manesti", "value": "C98188"},
                    {"label": "Marginenii de Jos", "value": "C98189"},
                    {"label": "Meri", "value": "C98190"},
                    {"label": "Minieri", "value": "C98191"},
                    {"label": "Miresu Mare", "value": "C98192"},
                    {"label": "Miroslavesti", "value": "C98193"},
                    {"label": "Mislea", "value": "C98194"},
                    {"label": "Mizil", "value": "C98195"},
                    {"label": "Mocesti", "value": "C98196"},
                    {"label": "Municipiul Campina", "value": "C98197"},
                    {"label": "Municipiul Ploiesti", "value": "C98198"},
                    {"label": "Nedelea", "value": "C98199"},
                    {"label": "Negoiesti", "value": "C98200"},
                    {"label": "Ocina de Sus", "value": "C98201"},
                    {"label": "Ogretin", "value": "C98202"},
                    {"label": "Olari", "value": "C98203"},
                    {"label": "Ologeni", "value": "C98204"},
                    {"label": "Oras Azuga", "value": "C98205"},
                    {"label": "Oras Baicoi", "value": "C98206"},
                    {"label": "Oras Boldesti-Scaeni", "value": "C98207"},
                    {"label": "Oras Breaza", "value": "C98208"},
                    {"label": "Oras Busteni", "value": "C98209"},
                    {"label": "Oras Comarnic", "value": "C98210"},
                    {"label": "Oras Mizil", "value": "C98211"},
                    {"label": "Oras Plopeni", "value": "C98212"},
                    {"label": "Oras Sinaia", "value": "C98213"},
                    {"label": "Oras Slanic", "value": "C98214"},
                    {"label": "Oras Urlati", "value": "C98215"},
                    {"label": "Oras Valenii De Munte", "value": "C98216"},
                    {"label": "Ostrovu", "value": "C98217"},
                    {"label": "Pacureti", "value": "C98218"},
                    {"label": "Palanca", "value": "C98219"},
                    {"label": "Pantazi", "value": "C98220"},
                    {"label": "Parepa-Rusani", "value": "C98221"},
                    {"label": "Paulesti", "value": "C98222"},
                    {"label": "Persunari", "value": "C98223"},
                    {"label": "Pietriceaua", "value": "C98224"},
                    {"label": "Pietrosani", "value": "C98225"},
                    {"label": "Pleasa", "value": "C98226"},
                    {"label": "Ploiesti", "value": "C98227"},
                    {"label": "Ploiestiori", "value": "C98228"},
                    {"label": "Plopeni", "value": "C98229"},
                    {"label": "Plopu", "value": "C98230"},
                    {"label": "Podenii Noi", "value": "C98231"},
                    {"label": "Podenii Vechi", "value": "C98232"},
                    {"label": "Poiana", "value": "C98233"},
                    {"label": "Poiana Campina", "value": "C98234"},
                    {"label": "Poiana Copaceni", "value": "C98235"},
                    {"label": "Poiana Tapului", "value": "C98236"},
                    {"label": "Poienarii Apostoli", "value": "C98237"},
                    {"label": "Poienarii Burchii", "value": "C98238"},
                    {"label": "Popesti", "value": "C98239"},
                    {"label": "Posada", "value": "C98240"},
                    {"label": "Posestii-Pamanteni", "value": "C98241"},
                    {"label": "Potigrafu", "value": "C98242"},
                    {"label": "Prajani", "value": "C98243"},
                    {"label": "Provita de Jos", "value": "C98244"},
                    {"label": "Provita de Sus", "value": "C98245"},
                    {"label": "Puchenii Mari", "value": "C98246"},
                    {"label": "Puchenii Mosneni", "value": "C98247"},
                    {"label": "Rachieri", "value": "C98248"},
                    {"label": "Rafov", "value": "C98249"},
                    {"label": "Romanesti", "value": "C98250"},
                    {"label": "Salcia", "value": "C98251"},
                    {"label": "Salciile", "value": "C98252"},
                    {"label": "Sangeru", "value": "C98253"},
                    {"label": "Schiulesti", "value": "C98254"},
                    {"label": "Scorteni", "value": "C98255"},
                    {"label": "Scurtesti", "value": "C98256"},
                    {"label": "Secaria", "value": "C98257"},
                    {"label": "Seciu", "value": "C98258"},
                    {"label": "Sinaia", "value": "C98259"},
                    {"label": "Sipotu", "value": "C98260"},
                    {"label": "Sirna", "value": "C98261"},
                    {"label": "Slanic", "value": "C98262"},
                    {"label": "Slon", "value": "C98263"},
                    {"label": "Soimari", "value": "C98264"},
                    {"label": "Sotrile", "value": "C98265"},
                    {"label": "Starchiojd", "value": "C98266"},
                    {"label": "Stefesti", "value": "C98267"},
                    {"label": "Stoenesti", "value": "C98268"},
                    {"label": "Strejnicu", "value": "C98269"},
                    {"label": "Surani", "value": "C98270"},
                    {"label": "Talea", "value": "C98271"},
                    {"label": "Targsoru Vechi", "value": "C98272"},
                    {"label": "Tariceni", "value": "C98273"},
                    {"label": "Tatarani", "value": "C98274"},
                    {"label": "Tataru", "value": "C98275"},
                    {"label": "Teisani", "value": "C98276"},
                    {"label": "Telega", "value": "C98277"},
                    {"label": "Tesila", "value": "C98278"},
                    {"label": "Tinosu", "value": "C98279"},
                    {"label": "Tintea", "value": "C98280"},
                    {"label": "Tomsani", "value": "C98281"},
                    {"label": "Traisteni", "value": "C98282"},
                    {"label": "Tufeni", "value": "C98283"},
                    {"label": "Urlati", "value": "C98284"},
                    {"label": "Urleta", "value": "C98285"},
                    {"label": "Vadu Parului", "value": "C98286"},
                    {"label": "Vadu Sapat", "value": "C98287"},
                    {"label": "Valcanesti", "value": "C98288"},
                    {"label": "Valea Calugareasca", "value": "C98289"},
                    {"label": "Valea Cucului", "value": "C98290"},
                    {"label": "Valea Dulce", "value": "C98291"},
                    {"label": "Valea Tarsei", "value": "C98292"},
                    {"label": "Valenii de Munte", "value": "C98293"},
                    {"label": "Varbila", "value": "C98294"},
                    {"label": "Varbilau", "value": "C98295"},
                    {"label": "Varnita", "value": "C98296"}],
 "Praia": [{"label": "Praia", "value": "C17790"}],
 "Preah Vihear": [{"label": "Sangkom Thmei", "value": "C16522"},
                  {"label": "Srok Cheh Sen", "value": "C16523"},
                  {"label": "Srok Chheb", "value": "C16524"},
                  {"label": "Srok Kulen", "value": "C16525"},
                  {"label": "Srok Rovieng", "value": "C16526"},
                  {"label": "Srok Tbeng Meanchey", "value": "C16527"},
                  {"label": "Tbeng Meanchey", "value": "C16528"}],
 "Prebold Municipality": [{"label": "Prebold", "value": "C107767"}],
 "Preddvor Municipality": [{"label": "Preddvor", "value": "C107768"}],
 "Preili Municipality": [{"label": "Jaunaglona", "value": "C64913"},
                         {"label": "Preili", "value": "C64914"}],
 "Presidente Hayes Department": [{"label": "Benjamin Aceval",
                                  "value": "C80612"},
                                 {"label": "Nanawua", "value": "C80613"},
                                 {"label": "Villa Hayes", "value": "C80614"}],
 "Presov Region": [{"label": "Bardejov", "value": "C107435"},
                   {"label": "Chlmec", "value": "C107436"},
                   {"label": "Giraltovce", "value": "C107437"},
                   {"label": "Humenne", "value": "C107438"},
                   {"label": "Kezmarok", "value": "C107439"},
                   {"label": "Levoca", "value": "C107440"},
                   {"label": "Lipany", "value": "C107441"},
                   {"label": "Lubica", "value": "C107442"},
                   {"label": "Medzilaborce", "value": "C107443"},
                   {"label": "Nova Lesna", "value": "C107444"},
                   {"label": "Okres Bardejov", "value": "C107445"},
                   {"label": "Okres Humenne", "value": "C107446"},
                   {"label": "Okres Kezmarok", "value": "C107447"},
                   {"label": "Okres Levoca", "value": "C107448"},
                   {"label": "Okres Medzilaborce", "value": "C107449"},
                   {"label": "Okres Poprad", "value": "C107450"},
                   {"label": "Okres Presov", "value": "C107451"},
                   {"label": "Okres Sabinov", "value": "C107452"},
                   {"label": "Okres Snina", "value": "C107453"},
                   {"label": "Okres Stara Lubovna", "value": "C107454"},
                   {"label": "Okres Stropkov", "value": "C107455"},
                   {"label": "Okres Svidnik", "value": "C107456"},
                   {"label": "Okres Vranov nad Toplou", "value": "C107457"},
                   {"label": "Podolinec", "value": "C107458"},
                   {"label": "Poprad", "value": "C107459"},
                   {"label": "Presov", "value": "C107460"},
                   {"label": "Sabinov", "value": "C107461"},
                   {"label": "Snina", "value": "C107462"},
                   {"label": "Spisska Bela", "value": "C107463"},
                   {"label": "Spisske Podhradie", "value": "C107464"},
                   {"label": "Stara Lubovna", "value": "C107465"},
                   {"label": "Strba", "value": "C107466"},
                   {"label": "Stropkov", "value": "C107467"},
                   {"label": "Svidnik", "value": "C107468"},
                   {"label": "Svit", "value": "C107469"},
                   {"label": "Vranov nad Toplou", "value": "C107470"},
                   {"label": "Vrbov", "value": "C107471"},
                   {"label": "Vysne Ruzbachy", "value": "C107472"},
                   {"label": "Vysoke Tatry", "value": "C107473"},
                   {"label": "Zdiar", "value": "C107474"}],
 "Prevalje Municipality": [{"label": "Prevalje", "value": "C107769"}],
 "Prey Veng": [{"label": "Prey Veng", "value": "C16529"},
               {"label": "Srok Kampong Leav", "value": "C16530"},
               {"label": "Srok Mesang", "value": "C16531"},
               {"label": "Srok Preah Sdech", "value": "C16532"}],
 "Priekule Municipality": [{"label": "Priekule", "value": "C64915"}],
 "Priekuli Municipality": [{"label": "Priekuli", "value": "C64916"}],
 "Prilep Municipality": [{"label": "Kanatlarci", "value": "C65478"},
                         {"label": "Markov Grad", "value": "C65479"},
                         {"label": "Prilep", "value": "C65480"},
                         {"label": "Topolcani", "value": "C65481"}],
 "Primorje-Gorski Kotar": [{"label": "Bakar", "value": "C21298"},
                           {"label": "Banjol", "value": "C21299"},
                           {"label": "Baska", "value": "C21300"},
                           {"label": "Bribir", "value": "C21301"},
                           {"label": "Buzdohanj", "value": "C21302"},
                           {"label": "Cavle", "value": "C21303"},
                           {"label": "Cernik", "value": "C21304"},
                           {"label": "Cres", "value": "C21305"},
                           {"label": "Crikvenica", "value": "C21306"},
                           {"label": "Delnice", "value": "C21307"},
                           {"label": "Drazice", "value": "C21308"},
                           {"label": "Drenova", "value": "C21309"},
                           {"label": "Fuzine", "value": "C21310"},
                           {"label": "Grad Cabar", "value": "C21311"},
                           {"label": "Grad Crikvenica", "value": "C21312"},
                           {"label": "Grad Delnice", "value": "C21313"},
                           {"label": "Grad Krk", "value": "C21314"},
                           {"label": "Grad Opatija", "value": "C21315"},
                           {"label": "Grad Rijeka", "value": "C21316"},
                           {"label": "Grad Vrbovsko", "value": "C21317"},
                           {"label": "Hreljin", "value": "C21318"},
                           {"label": "Jadranovo", "value": "C21319"},
                           {"label": "Kampor", "value": "C21320"},
                           {"label": "Kastav", "value": "C21321"},
                           {"label": "Klana", "value": "C21322"},
                           {"label": "Kraljevica", "value": "C21323"},
                           {"label": "Krasica", "value": "C21324"},
                           {"label": "Krk", "value": "C21325"},
                           {"label": "Lopar", "value": "C21326"},
                           {"label": "Lovran", "value": "C21327"},
                           {"label": "Mali Losinj", "value": "C21328"},
                           {"label": "Malinska-Dubasnica", "value": "C21329"},
                           {"label": "Marcelji", "value": "C21330"},
                           {"label": "Marinici", "value": "C21331"},
                           {"label": "Matulji", "value": "C21332"},
                           {"label": "Mihotici", "value": "C21333"},
                           {"label": "Mrkopalj", "value": "C21334"},
                           {"label": "Njivice", "value": "C21335"},
                           {"label": "Novi Vinodolski", "value": "C21336"},
                           {"label": "Omisalj", "value": "C21337"},
                           {"label": "Opatija", "value": "C21338"},
                           {"label": "Podhum", "value": "C21339"},
                           {"label": "Punat", "value": "C21340"},
                           {"label": "Rab", "value": "C21341"},
                           {"label": "Rijeka", "value": "C21342"},
                           {"label": "Rubesi", "value": "C21343"},
                           {"label": "Selce", "value": "C21344"},
                           {"label": "Skrad", "value": "C21345"},
                           {"label": "Skrljevo", "value": "C21346"},
                           {"label": "Supetarska Draga", "value": "C21347"},
                           {"label": "Vinodolska opcina", "value": "C21348"},
                           {"label": "Viskovo", "value": "C21349"},
                           {"label": "Vrbnik", "value": "C21350"},
                           {"label": "Vrbovsko", "value": "C21351"}],
 "Primorsky Krai": [{"label": "Anuchino", "value": "C103152"},
                    {"label": "Anuchinskiy Rayon", "value": "C103153"},
                    {"label": "Arsenyev", "value": "C103154"},
                    {"label": "Artem", "value": "C103155"},
                    {"label": "Barabash", "value": "C103156"},
                    {"label": "Blagodatnoye", "value": "C103157"},
                    {"label": "Bolshoy Kamen", "value": "C103158"},
                    {"label": "Chernigovka", "value": "C103159"},
                    {"label": "Chernigovskiy Rayon", "value": "C103160"},
                    {"label": "Chernyshevka", "value": "C103161"},
                    {"label": "Chkalovskoye", "value": "C103162"},
                    {"label": "Chuguyevka", "value": "C103163"},
                    {"label": "Dalnegorsk", "value": "C103164"},
                    {"label": "Dalnerechensk", "value": "C103165"},
                    {"label": "Dostoyevka", "value": "C103166"},
                    {"label": "Dunay", "value": "C103167"},
                    {"label": "Emar", "value": "C103168"},
                    {"label": "Fokino", "value": "C103169"},
                    {"label": "Gornorechenskiy", "value": "C103170"},
                    {"label": "Gornyye Klyuchi", "value": "C103171"},
                    {"label": "Grazhdanka", "value": "C103172"},
                    {"label": "Ivanovka", "value": "C103173"},
                    {"label": "Kamen-Rybolov", "value": "C103174"},
                    {"label": "Kamenka", "value": "C103175"},
                    {"label": "Kavalerovo", "value": "C103176"},
                    {"label": "Khasanskiy Rayon", "value": "C103177"},
                    {"label": "Khorol", "value": "C103178"},
                    {"label": "Kirovskiy", "value": "C103179"},
                    {"label": "Knevichi", "value": "C103180"},
                    {"label": "Kraskino", "value": "C103181"},
                    {"label": "Krasnorechenskiy", "value": "C103182"},
                    {"label": "Lazo", "value": "C103183"},
                    {"label": "Lazovskiy Rayon", "value": "C103184"},
                    {"label": "Lesozavodsk", "value": "C103185"},
                    {"label": "Lipovtsy", "value": "C103186"},
                    {"label": "Livadiya", "value": "C103187"},
                    {"label": "Luchegorsk", "value": "C103188"},
                    {"label": "Luchki", "value": "C103189"},
                    {"label": "Lyalichi", "value": "C103190"},
                    {"label": "Mikhaylovka", "value": "C103191"},
                    {"label": "Mikhaylovskiy Rayon", "value": "C103192"},
                    {"label": "Mnogoudobnoye", "value": "C103193"},
                    {"label": "Monastyrishche", "value": "C103194"},
                    {"label": "Nadezhdinskiy Rayon", "value": "C103195"},
                    {"label": "Nakhodka", "value": "C103196"},
                    {"label": "Novonikolsk", "value": "C103197"},
                    {"label": "Novopokrovka", "value": "C103198"},
                    {"label": "Novoshakhtinskiy", "value": "C103199"},
                    {"label": "Novosysoyevka", "value": "C103200"},
                    {"label": "Novyy", "value": "C103201"},
                    {"label": "Olga", "value": "C103202"},
                    {"label": "Partizansk", "value": "C103203"},
                    {"label": "Plastun", "value": "C103204"},
                    {"label": "Pogranichnyy", "value": "C103205"},
                    {"label": "Pokrovka", "value": "C103206"},
                    {"label": "Popova", "value": "C103207"},
                    {"label": "Preobrazheniye", "value": "C103208"},
                    {"label": "Primorskiy", "value": "C103209"},
                    {"label": "Putyatin", "value": "C103210"},
                    {"label": "Razdolnoye", "value": "C103211"},
                    {"label": "Rettikhovka", "value": "C103212"},
                    {"label": "Romanovka", "value": "C103213"},
                    {"label": "Rudnaya Pristan", "value": "C103214"},
                    {"label": "Rudnyy", "value": "C103215"},
                    {"label": "Russkiy", "value": "C103216"},
                    {"label": "Sergeyevka", "value": "C103217"},
                    {"label": "Shkotovo", "value": "C103218"},
                    {"label": "Shkotovskiy Rayon", "value": "C103219"},
                    {"label": "Shtykovo", "value": "C103220"},
                    {"label": "Sivakovka", "value": "C103221"},
                    {"label": "Slavyanka", "value": "C103222"},
                    {"label": "Smolyaninovo", "value": "C103223"},
                    {"label": "Spassk-Dalniy", "value": "C103224"},
                    {"label": "Spasskiy Rayon", "value": "C103225"},
                    {"label": "Spasskoye", "value": "C103226"},
                    {"label": "Svetlaya", "value": "C103227"},
                    {"label": "Tavrichanka", "value": "C103228"},
                    {"label": "Terney", "value": "C103229"},
                    {"label": "Trudovoye", "value": "C103230"},
                    {"label": "Uglekamensk", "value": "C103231"},
                    {"label": "Uglovoye", "value": "C103232"},
                    {"label": "Ussuriysk", "value": "C103233"},
                    {"label": "Vladimiro-Aleksandrovskoye", "value": "C103234"},
                    {"label": "Vladivostok", "value": "C103235"},
                    {"label": "Volno-Nadezhdinskoye", "value": "C103236"},
                    {"label": "Volchanets", "value": "C103237"},
                    {"label": "Vostok", "value": "C103238"},
                    {"label": "Vozdvizhenka", "value": "C103239"},
                    {"label": "Vrangel", "value": "C103240"},
                    {"label": "Yakovlevka", "value": "C103241"},
                    {"label": "Yakovlevskiy Rayon", "value": "C103242"},
                    {"label": "Yaroslavskiy", "value": "C103243"},
                    {"label": "Yekaterinovka", "value": "C103244"},
                    {"label": "Zarubino", "value": "C103245"},
                    {"label": "Zavodskoy", "value": "C103246"},
                    {"label": "Zharikovo", "value": "C103247"}],
 "Prince Edward Island": [{"label": "Alberton", "value": "C17366"},
                          {"label": "Belfast", "value": "C17367"},
                          {"label": "Charlottetown", "value": "C17368"},
                          {"label": "Cornwall", "value": "C17369"},
                          {"label": "Fallingbrook", "value": "C17370"},
                          {"label": "Kensington", "value": "C17371"},
                          {"label": "Montague", "value": "C17372"},
                          {"label": "Souris", "value": "C17373"},
                          {"label": "Summerside", "value": "C17374"}],
 "Princes Town Regional Corporation": [{"label": "Princes Town",
                                        "value": "C119668"}],
 "Principe Province": [{"label": "Santo Antonio", "value": "C106254"}],
 "Probistip Municipality": [{"label": "Probishtip", "value": "C65482"},
                            {"label": "Zletovo", "value": "C65483"}],
 "Provence-Alpes-Cote-dAzur": [{"label": "Aix-en-Provence", "value": "C33586"},
                               {"label": "Allauch", "value": "C33587"},
                               {"label": "Alleins", "value": "C33588"},
                               {"label": "Alpes-de-Haute-Provence",
                                "value": "C33589"},
                               {"label": "Alpes-Maritimes", "value": "C33590"},
                               {"label": "Althen-des-Paluds",
                                "value": "C33591"},
                               {"label": "Annot", "value": "C33592"},
                               {"label": "Ansouis", "value": "C33593"},
                               {"label": "Antibes", "value": "C33594"},
                               {"label": "Apt", "value": "C33595"},
                               {"label": "Arenc", "value": "C33596"},
                               {"label": "Arles", "value": "C33597"},
                               {"label": "Aspremont", "value": "C33598"},
                               {"label": "Aubagne", "value": "C33599"},
                               {"label": "Aubignan", "value": "C33600"},
                               {"label": "Aups", "value": "C33601"},
                               {"label": "Aureille", "value": "C33602"},
                               {"label": "Auribeau-sur-Siagne",
                                "value": "C33603"},
                               {"label": "Auriol", "value": "C33604"},
                               {"label": "Avignon", "value": "C33605"},
                               {"label": "Bagnols-en-Foret", "value": "C33606"},
                               {"label": "Baille", "value": "C33607"},
                               {"label": "Bandol", "value": "C33608"},
                               {"label": "Barbentane", "value": "C33609"},
                               {"label": "Barcelonnette", "value": "C33610"},
                               {"label": "Bargemon", "value": "C33611"},
                               {"label": "Barjols", "value": "C33612"},
                               {"label": "Beaulieu-sur-Mer", "value": "C33613"},
                               {"label": "Beaumes-de-Venise",
                                "value": "C33614"},
                               {"label": "Beaumont-de-Pertuis",
                                "value": "C33615"},
                               {"label": "Beausoleil", "value": "C33616"},
                               {"label": "Bedarrides", "value": "C33617"},
                               {"label": "Bedoin", "value": "C33618"},
                               {"label": "Belcodene", "value": "C33619"},
                               {"label": "Belgentier", "value": "C33620"},
                               {"label": "Belle de Mai", "value": "C33621"},
                               {"label": "Belsunce", "value": "C33622"},
                               {"label": "Berre-letang", "value": "C33623"},
                               {"label": "Berre-les-Alpes", "value": "C33624"},
                               {"label": "Besse-sur-Issole", "value": "C33625"},
                               {"label": "Biot", "value": "C33626"},
                               {"label": "Blausasc", "value": "C33627"},
                               {"label": "Bollene", "value": "C33628"},
                               {"label": "Bon-Secours", "value": "C33629"},
                               {"label": "Bonneveine", "value": "C33630"},
                               {"label": "Bonnieux", "value": "C33631"},
                               {"label": "Borel", "value": "C33632"},
                               {"label": "Bormes-les-Mimosas",
                                "value": "C33633"},
                               {"label": "Bouc-Bel-Air", "value": "C33634"},
                               {"label": "Boulbon", "value": "C33635"},
                               {"label": "Bras", "value": "C33636"},
                               {"label": "Breil-sur-Roya", "value": "C33637"},
                               {"label": "Briancon", "value": "C33638"},
                               {"label": "Brignoles", "value": "C33639"},
                               {"label": "Cabannes", "value": "C33640"},
                               {"label": "Cabasse", "value": "C33641"},
                               {"label": "Cabries", "value": "C33642"},
                               {"label": "Cabris", "value": "C33643"},
                               {"label": "Cadenet", "value": "C33644"},
                               {"label": "Caderousse", "value": "C33645"},
                               {"label": "Cadolive", "value": "C33646"},
                               {"label": "Cagnes-sur-Mer", "value": "C33647"},
                               {"label": "Callas", "value": "C33648"},
                               {"label": "Callian", "value": "C33649"},
                               {"label": "Camaret-sur-Aigues",
                                "value": "C33650"},
                               {"label": "Camps-la-Source", "value": "C33651"},
                               {"label": "Cannes", "value": "C33652"},
                               {"label": "Cantaron", "value": "C33653"},
                               {"label": "Cap-dAil", "value": "C33654"},
                               {"label": "Carces", "value": "C33655"},
                               {"label": "Carnoules", "value": "C33656"},
                               {"label": "Carnoux-en-Provence",
                                "value": "C33657"},
                               {"label": "Caromb", "value": "C33658"},
                               {"label": "Carpentras", "value": "C33659"},
                               {"label": "Carqueiranne", "value": "C33660"},
                               {"label": "Carros", "value": "C33661"},
                               {"label": "Carry-le-Rouet", "value": "C33662"},
                               {"label": "Cassis", "value": "C33663"},
                               {"label": "Castagniers", "value": "C33664"},
                               {"label": "Castellane", "value": "C33665"},
                               {"label": "Caumont-sur-Durance",
                                "value": "C33666"},
                               {"label": "Cavaillon", "value": "C33667"},
                               {"label": "Cavalaire-sur-Mer",
                                "value": "C33668"},
                               {"label": "Cereste", "value": "C33669"},
                               {"label": "Ceyreste", "value": "C33670"},
                               {"label": "Charleval", "value": "C33671"},
                               {"label": "Chateauneuf-de-Gadagne",
                                "value": "C33672"},
                               {"label": "Chateauneuf-du-Pape",
                                "value": "C33673"},
                               {"label": "Chateauneuf-Grasse",
                                "value": "C33674"},
                               {"label": "Chateauneuf-le-Rouge",
                                "value": "C33675"},
                               {"label": "Chateauneuf-les-Martigues",
                                "value": "C33676"},
                               {"label": "Chateaurenard", "value": "C33677"},
                               {"label": "Cheval-Blanc", "value": "C33678"},
                               {"label": "Chorges", "value": "C33679"},
                               {"label": "Chutes-Lavie", "value": "C33680"},
                               {"label": "Cinq Avenues", "value": "C33681"},
                               {"label": "Cogolin", "value": "C33682"},
                               {"label": "Collobrieres", "value": "C33683"},
                               {"label": "Colomars", "value": "C33684"},
                               {"label": "Contes", "value": "C33685"},
                               {"label": "Cornillon-Confoux",
                                "value": "C33686"},
                               {"label": "Cotignac", "value": "C33687"},
                               {"label": "Coudoux", "value": "C33688"},
                               {"label": "Courthezon", "value": "C33689"},
                               {"label": "Cucuron", "value": "C33690"},
                               {"label": "Cuers", "value": "C33691"},
                               {"label": "Cuges-les-Pins", "value": "C33692"},
                               {"label": "Departement des Bouches-du-Rhone",
                                "value": "C33693"},
                               {"label": "Departement du Vaucluse",
                                "value": "C33694"},
                               {"label": "Digne-les-Bains", "value": "C33695"},
                               {"label": "Draguignan", "value": "C33696"},
                               {"label": "Drap", "value": "C33697"},
                               {"label": "eguilles", "value": "C33698"},
                               {"label": "Embrun", "value": "C33699"},
                               {"label": "Ensues-la-Redonne",
                                "value": "C33700"},
                               {"label": "Entraigues-sur-la-Sorgue",
                                "value": "C33701"},
                               {"label": "eoures", "value": "C33702"},
                               {"label": "evenos", "value": "C33703"},
                               {"label": "Eygalieres", "value": "C33704"},
                               {"label": "Eyguieres", "value": "C33705"},
                               {"label": "Eyragues", "value": "C33706"},
                               {"label": "Eze", "value": "C33707"},
                               {"label": "Falicon", "value": "C33708"},
                               {"label": "Fayence", "value": "C33709"},
                               {"label": "Figanieres", "value": "C33710"},
                               {"label": "Flassans-sur-Issole",
                                "value": "C33711"},
                               {"label": "Flayosc", "value": "C33712"},
                               {"label": "Fontvieille", "value": "C33713"},
                               {"label": "Forcalqueiret", "value": "C33714"},
                               {"label": "Forcalquier", "value": "C33715"},
                               {"label": "Fos-sur-Mer", "value": "C33716"},
                               {"label": "Frejus", "value": "C33717"},
                               {"label": "Fuveau", "value": "C33718"},
                               {"label": "Gap", "value": "C33719"},
                               {"label": "Gardanne", "value": "C33720"},
                               {"label": "Gareoult", "value": "C33721"},
                               {"label": "Gargas", "value": "C33722"},
                               {"label": "Gassin", "value": "C33723"},
                               {"label": "Gattieres", "value": "C33724"},
                               {"label": "Gemenos", "value": "C33725"},
                               {"label": "Gignac-la-Nerthe", "value": "C33726"},
                               {"label": "Gilette", "value": "C33727"},
                               {"label": "Ginasservis", "value": "C33728"},
                               {"label": "Gonfaron", "value": "C33729"},
                               {"label": "Gorbio", "value": "C33730"},
                               {"label": "Gordes", "value": "C33731"},
                               {"label": "Goult", "value": "C33732"},
                               {"label": "Grambois", "value": "C33733"},
                               {"label": "Grans", "value": "C33734"},
                               {"label": "Grasse", "value": "C33735"},
                               {"label": "Graveson", "value": "C33736"},
                               {"label": "Greasque", "value": "C33737"},
                               {"label": "Greoux-les-Bains", "value": "C33738"},
                               {"label": "Grillon", "value": "C33739"},
                               {"label": "Grimaud", "value": "C33740"},
                               {"label": "Guillestre", "value": "C33741"},
                               {"label": "Hautes-Alpes", "value": "C33742"},
                               {"label": "Hyeres", "value": "C33743"},
                               {"label": "Istres", "value": "C33744"},
                               {"label": "Jausiers", "value": "C33745"},
                               {"label": "Jonquerettes", "value": "C33746"},
                               {"label": "Jonquieres", "value": "C33747"},
                               {"label": "Jouques", "value": "C33748"},
                               {"label": "LEstaque", "value": "C33749"},
                               {"label": "LEscale", "value": "C33750"},
                               {"label": "LEscarene", "value": "C33751"},
                               {"label": "LIsle-sur-la-Sorgue",
                                "value": "C33752"},
                               {"label": "La Barasse", "value": "C33753"},
                               {"label": "La Bastide-des-Jourdans",
                                "value": "C33754"},
                               {"label": "La Batie-Neuve", "value": "C33755"},
                               {"label": "La Blancarde", "value": "C33756"},
                               {"label": "La Bouilladisse", "value": "C33757"},
                               {"label": "La Cabucelle", "value": "C33758"},
                               {"label": "La Calade", "value": "C33759"},
                               {"label": "La Capelette", "value": "C33760"},
                               {"label": "La Celle", "value": "C33761"},
                               {"label": "La Ciotat", "value": "C33762"},
                               {"label": "La Colle-sur-Loup",
                                "value": "C33763"},
                               {"label": "La Conception", "value": "C33764"},
                               {"label": "La Crau", "value": "C33765"},
                               {"label": "La Croix-Rouge", "value": "C33766"},
                               {"label": "La Croix-Valmer", "value": "C33767"},
                               {"label": "La Delorme", "value": "C33768"},
                               {"label": "La Destrousse", "value": "C33769"},
                               {"label": "La Fare-les-Oliviers",
                                "value": "C33770"},
                               {"label": "La Farlede", "value": "C33771"},
                               {"label": "La Fourragere", "value": "C33772"},
                               {"label": "La Garde", "value": "C33773"},
                               {"label": "La Garde-Freinet", "value": "C33774"},
                               {"label": "La Gaude", "value": "C33775"},
                               {"label": "La Joliette", "value": "C33776"},
                               {"label": "La Londe-les-Maures",
                                "value": "C33777"},
                               {"label": "La Millere", "value": "C33778"},
                               {"label": "La Motte", "value": "C33779"},
                               {"label": "La Page", "value": "C33780"},
                               {"label": "La Panouse", "value": "C33781"},
                               {"label": "La Penne-sur-Huveaune",
                                "value": "C33782"},
                               {"label": "La Pointe Rouge", "value": "C33783"},
                               {"label": "La Pomme", "value": "C33784"},
                               {"label": "La Roche-des-Arnauds",
                                "value": "C33785"},
                               {"label": "La Roque-dAntheron",
                                "value": "C33786"},
                               {"label": "La Roquebrussanne",
                                "value": "C33787"},
                               {"label": "La Roquette-sur-Siagne",
                                "value": "C33788"},
                               {"label": "La Rose", "value": "C33789"},
                               {"label": "La Seyne-sur-Mer", "value": "C33790"},
                               {"label": "La Timone", "value": "C33791"},
                               {"label": "La Trinite", "value": "C33792"},
                               {"label": "La Turbie", "value": "C33793"},
                               {"label": "La Valbarelle", "value": "C33794"},
                               {"label": "La Valentine", "value": "C33795"},
                               {"label": "La Valette-du-Var",
                                "value": "C33796"},
                               {"label": "La Villette", "value": "C33797"},
                               {"label": "La Viste", "value": "C33798"},
                               {"label": "Lagnes", "value": "C33799"},
                               {"label": "Lamanon", "value": "C33800"},
                               {"label": "Lambesc", "value": "C33801"},
                               {"label": "Lancon-Provence", "value": "C33802"},
                               {"label": "Lantosque", "value": "C33803"},
                               {"label": "Lapalud", "value": "C33804"},
                               {"label": "Laragne-Monteglin",
                                "value": "C33805"},
                               {"label": "Lauris", "value": "C33806"},
                               {"label": "Le Bar-sur-Loup", "value": "C33807"},
                               {"label": "Le Beausset", "value": "C33808"},
                               {"label": "Le Broc", "value": "C33809"},
                               {"label": "Le Brusquet", "value": "C33810"},
                               {"label": "Le Cabot", "value": "C33811"},
                               {"label": "Le Camas", "value": "C33812"},
                               {"label": "Le Canet", "value": "C33813"},
                               {"label": "Le Cannet", "value": "C33814"},
                               {"label": "Le Cannet-des-Maures",
                                "value": "C33815"},
                               {"label": "Le Castellet", "value": "C33816"},
                               {"label": "Le Chapitre", "value": "C33817"},
                               {"label": "Le Lavandou", "value": "C33818"},
                               {"label": "Le Luc", "value": "C33819"},
                               {"label": "Le Merlan", "value": "C33820"},
                               {"label": "Le Monetier-les-Bains",
                                "value": "C33821"},
                               {"label": "Le Muy", "value": "C33822"},
                               {"label": "Le Pharo", "value": "C33823"},
                               {"label": "Le Plan-de-la-Tour",
                                "value": "C33824"},
                               {"label": "Le Pontet", "value": "C33825"},
                               {"label": "Le Pradet", "value": "C33826"},
                               {"label": "Le Puy-Sainte-Reparade",
                                "value": "C33827"},
                               {"label": "Le Redon", "value": "C33828"},
                               {"label": "Le Revest-les-Eaux",
                                "value": "C33829"},
                               {"label": "Le Rouret", "value": "C33830"},
                               {"label": "Le Rove", "value": "C33831"},
                               {"label": "Le Tholonet", "value": "C33832"},
                               {"label": "Le Thor", "value": "C33833"},
                               {"label": "Le Thoronet", "value": "C33834"},
                               {"label": "Le Tignet", "value": "C33835"},
                               {"label": "Le Val", "value": "C33836"},
                               {"label": "Les Accates", "value": "C33837"},
                               {"label": "Les Arcs", "value": "C33838"},
                               {"label": "Les Arnavaux", "value": "C33839"},
                               {"label": "Les Aygalades", "value": "C33840"},
                               {"label": "Les Baumettes", "value": "C33841"},
                               {"label": "Les Caillols", "value": "C33842"},
                               {"label": "Les Camoins", "value": "C33843"},
                               {"label": "Les Chartreux", "value": "C33844"},
                               {"label": "Les Crottes", "value": "C33845"},
                               {"label": "Les Grands Carmes",
                                "value": "C33846"},
                               {"label": "Les Medecins", "value": "C33847"},
                               {"label": "Les Mees", "value": "C33848"},
                               {"label": "Les Olives", "value": "C33849"},
                               {"label": "Les Pennes-Mirabeau",
                                "value": "C33850"},
                               {"label": "Les Trois-Lucs", "value": "C33851"},
                               {"label": "Levens", "value": "C33852"},
                               {"label": "Lodi", "value": "C33853"},
                               {"label": "Lorgues", "value": "C33854"},
                               {"label": "Loriol-du-Comtat", "value": "C33855"},
                               {"label": "Lourmarin", "value": "C33856"},
                               {"label": "Luceram", "value": "C33857"},
                               {"label": "Maillane", "value": "C33858"},
                               {"label": "Malaucene", "value": "C33859"},
                               {"label": "Malemort-du-Comtat",
                                "value": "C33860"},
                               {"label": "Malijai", "value": "C33861"},
                               {"label": "Mallemoisson", "value": "C33862"},
                               {"label": "Mallemort", "value": "C33863"},
                               {"label": "Malpasse", "value": "C33864"},
                               {"label": "Mandelieu-la-Napoule",
                                "value": "C33865"},
                               {"label": "Mane", "value": "C33866"},
                               {"label": "Manosque", "value": "C33867"},
                               {"label": "Marignane", "value": "C33868"},
                               {"label": "Marseille", "value": "C33869"},
                               {"label": "Marseille 01", "value": "C33870"},
                               {"label": "Marseille 02", "value": "C33871"},
                               {"label": "Marseille 03", "value": "C33872"},
                               {"label": "Marseille 04", "value": "C33873"},
                               {"label": "Marseille 05", "value": "C33874"},
                               {"label": "Marseille 06", "value": "C33875"},
                               {"label": "Marseille 07", "value": "C33876"},
                               {"label": "Marseille 08", "value": "C33877"},
                               {"label": "Marseille 09", "value": "C33878"},
                               {"label": "Marseille 10", "value": "C33879"},
                               {"label": "Marseille 11", "value": "C33880"},
                               {"label": "Marseille 12", "value": "C33881"},
                               {"label": "Marseille 13", "value": "C33882"},
                               {"label": "Marseille 14", "value": "C33883"},
                               {"label": "Marseille 15", "value": "C33884"},
                               {"label": "Marseille 16", "value": "C33885"},
                               {"label": "Marseille Bompard",
                                "value": "C33886"},
                               {"label": "Marseille Endoume",
                                "value": "C33887"},
                               {"label": "Marseille Prefecture",
                                "value": "C33888"},
                               {"label": "Marseille Roucas-Blanc",
                                "value": "C33889"},
                               {"label": "Marseille Saint-Victor",
                                "value": "C33890"},
                               {"label": "Marseille Vauban", "value": "C33891"},
                               {"label": "Martigues", "value": "C33892"},
                               {"label": "Maubec", "value": "C33893"},
                               {"label": "Maussane-les-Alpilles",
                                "value": "C33894"},
                               {"label": "Mazan", "value": "C33895"},
                               {"label": "Mazargues", "value": "C33896"},
                               {"label": "Menerbes", "value": "C33897"},
                               {"label": "Menpenti", "value": "C33898"},
                               {"label": "Menton", "value": "C33899"},
                               {"label": "Meounes-les-Montrieux",
                                "value": "C33900"},
                               {"label": "Merindol", "value": "C33901"},
                               {"label": "Meyrargues", "value": "C33902"},
                               {"label": "Meyreuil", "value": "C33903"},
                               {"label": "Mimet", "value": "C33904"},
                               {"label": "Miramas", "value": "C33905"},
                               {"label": "Molleges", "value": "C33906"},
                               {"label": "Mondragon", "value": "C33907"},
                               {"label": "Montauroux", "value": "C33908"},
                               {"label": "Monteux", "value": "C33909"},
                               {"label": "Montfavet", "value": "C33910"},
                               {"label": "Montferrat", "value": "C33911"},
                               {"label": "Montolivet", "value": "C33912"},
                               {"label": "Montredon", "value": "C33913"},
                               {"label": "Morieres-les-Avignon",
                                "value": "C33914"},
                               {"label": "Mormoiron", "value": "C33915"},
                               {"label": "Mornas", "value": "C33916"},
                               {"label": "Mouans-Sartoux", "value": "C33917"},
                               {"label": "Mougins", "value": "C33918"},
                               {"label": "Mouret", "value": "C33919"},
                               {"label": "Mouries", "value": "C33920"},
                               {"label": "Nans-les-Pins", "value": "C33921"},
                               {"label": "Neoules", "value": "C33922"},
                               {"label": "Nice", "value": "C33923"},
                               {"label": "Noailles", "value": "C33924"},
                               {"label": "Notre-Dame du Mont",
                                "value": "C33925"},
                               {"label": "Notre-Dame Limite",
                                "value": "C33926"},
                               {"label": "Noves", "value": "C33927"},
                               {"label": "Ollioules", "value": "C33928"},
                               {"label": "Opera", "value": "C33929"},
                               {"label": "Opio", "value": "C33930"},
                               {"label": "Oppede le Vieux", "value": "C33931"},
                               {"label": "Oraison", "value": "C33932"},
                               {"label": "Orange", "value": "C33933"},
                               {"label": "Orgon", "value": "C33934"},
                               {"label": "Palais de Justice",
                                "value": "C33935"},
                               {"label": "Palama", "value": "C33936"},
                               {"label": "Paradou", "value": "C33937"},
                               {"label": "Pegomas", "value": "C33938"},
                               {"label": "Peille", "value": "C33939"},
                               {"label": "Peillon", "value": "C33940"},
                               {"label": "Peipin", "value": "C33941"},
                               {"label": "Pelissanne", "value": "C33942"},
                               {"label": "Perier", "value": "C33943"},
                               {"label": "Pernes-les-Fontaines",
                                "value": "C33944"},
                               {"label": "Pertuis", "value": "C33945"},
                               {"label": "Peymeinade", "value": "C33946"},
                               {"label": "Peynier", "value": "C33947"},
                               {"label": "Peypin", "value": "C33948"},
                               {"label": "Peyrolles-en-Provence",
                                "value": "C33949"},
                               {"label": "Peyruis", "value": "C33950"},
                               {"label": "Pierrefeu-du-Var", "value": "C33951"},
                               {"label": "Pierrevert", "value": "C33952"},
                               {"label": "Pignans", "value": "C33953"},
                               {"label": "Piolenc", "value": "C33954"},
                               {"label": "Plan-dAups-Sainte-Baume",
                                "value": "C33955"},
                               {"label": "Plan-de-Cuques", "value": "C33956"},
                               {"label": "Pont de Vivaux", "value": "C33957"},
                               {"label": "Port-de-Bouc", "value": "C33958"},
                               {"label": "Port-Saint-Louis-du-Rhone",
                                "value": "C33959"},
                               {"label": "Pourrieres", "value": "C33960"},
                               {"label": "Puget-sur-Argens", "value": "C33961"},
                               {"label": "Puget-Theniers", "value": "C33962"},
                               {"label": "Puget-Ville", "value": "C33963"},
                               {"label": "Puyloubier", "value": "C33964"},
                               {"label": "Ramatuelle", "value": "C33965"},
                               {"label": "Regusse", "value": "C33966"},
                               {"label": "Reillanne", "value": "C33967"},
                               {"label": "Rians", "value": "C33968"},
                               {"label": "Riez", "value": "C33969"},
                               {"label": "Robion", "value": "C33970"},
                               {"label": "Rocbaron", "value": "C33971"},
                               {"label": "Rognac", "value": "C33972"},
                               {"label": "Rognes", "value": "C33973"},
                               {"label": "Rognonas", "value": "C33974"},
                               {"label": "Roquebilliere", "value": "C33975"},
                               {"label": "Roquebrune-Cap-Martin",
                                "value": "C33976"},
                               {"label": "Roquebrune-sur-Argens",
                                "value": "C33977"},
                               {"label": "Roquefort-la-Bedoule",
                                "value": "C33978"},
                               {"label": "Roquevaire", "value": "C33979"},
                               {"label": "Rouet", "value": "C33980"},
                               {"label": "Rougiers", "value": "C33981"},
                               {"label": "Rousset", "value": "C33982"},
                               {"label": "Roussillon", "value": "C33983"},
                               {"label": "Sablet", "value": "C33984"},
                               {"label": "Saignon", "value": "C33985"},
                               {"label": "Saint-Andiol", "value": "C33986"},
                               {"label": "Saint-Andre", "value": "C33987"},
                               {"label": "Saint-Andre-de-la-Roche",
                                "value": "C33988"},
                               {"label": "Saint-Antoine", "value": "C33989"},
                               {"label": "Saint-Barnabe", "value": "C33990"},
                               {"label": "Saint-Barthelemy", "value": "C33991"},
                               {"label": "Saint-Bonnet-en-Champsaur",
                                "value": "C33992"},
                               {"label": "Saint-Cannat", "value": "C33993"},
                               {"label": "Saint-Cezaire-sur-Siagne",
                                "value": "C33994"},
                               {"label": "Saint-Chaffrey", "value": "C33995"},
                               {"label": "Saint-Chamas", "value": "C33996"},
                               {"label": "Saint-Charles", "value": "C33997"},
                               {"label": "Saint-Cyr-sur-Mer",
                                "value": "C33998"},
                               {"label": "Saint-Didier", "value": "C33999"},
                               {"label": "Saint-etienne-de-Tinee",
                                "value": "C34000"},
                               {"label": "Saint-etienne-du-Gres",
                                "value": "C34001"},
                               {"label": "Saint-Giniez", "value": "C34002"},
                               {"label": "Saint-Henri", "value": "C34003"},
                               {"label": "Saint-Jean du Desert",
                                "value": "C34004"},
                               {"label": "Saint-Jean-Cap-Ferrat",
                                "value": "C34005"},
                               {"label": "Saint-Jeannet", "value": "C34006"},
                               {"label": "Saint-Jerome", "value": "C34007"},
                               {"label": "Saint-Joseph", "value": "C34008"},
                               {"label": "Saint-Julien", "value": "C34009"},
                               {"label": "Saint-Just", "value": "C34010"},
                               {"label": "Saint-Lambert", "value": "C34011"},
                               {"label": "Saint-Laurent-du-Var",
                                "value": "C34012"},
                               {"label": "Saint-Lazare", "value": "C34013"},
                               {"label": "Saint-Louis", "value": "C34014"},
                               {"label": "Saint-Loup", "value": "C34015"},
                               {"label": "Saint-Mandrier-sur-Mer",
                                "value": "C34016"},
                               {"label": "Saint-Marc-Jaumegarde",
                                "value": "C34017"},
                               {"label": "Saint-Marcel", "value": "C34018"},
                               {"label": "Saint-Martin-de-Crau",
                                "value": "C34019"},
                               {"label": "Saint-Martin-de-Queyrieres",
                                "value": "C34020"},
                               {"label": "Saint-Martin-du-Var",
                                "value": "C34021"},
                               {"label": "Saint-Martin-Vesubie",
                                "value": "C34022"},
                               {"label": "Saint-Mauront", "value": "C34023"},
                               {"label": "Saint-Maximin-la-Sainte-Baume",
                                "value": "C34024"},
                               {"label": "Saint-Menet", "value": "C34025"},
                               {"label": "Saint-Michel-lObservatoire",
                                "value": "C34026"},
                               {"label": "Saint-Mitre", "value": "C34027"},
                               {"label": "Saint-Mitre-les-Remparts",
                                "value": "C34028"},
                               {"label": "Saint-Paul-de-Vence",
                                "value": "C34029"},
                               {"label": "Saint-Paul-en-Foret",
                                "value": "C34030"},
                               {"label": "Saint-Pierre", "value": "C34031"},
                               {"label": "Saint-Raphael", "value": "C34032"},
                               {"label": "Saint-Remy-de-Provence",
                                "value": "C34033"},
                               {"label": "Saint-Saturnin-les-Apt",
                                "value": "C34034"},
                               {"label": "Saint-Saturnin-les-Avignon",
                                "value": "C34035"},
                               {"label": "Saint-Savournin", "value": "C34036"},
                               {"label": "Saint-Tronc", "value": "C34037"},
                               {"label": "Saint-Tropez", "value": "C34038"},
                               {"label": "Saint-Vallier-de-Thiey",
                                "value": "C34039"},
                               {"label": "Saint-Victoret", "value": "C34040"},
                               {"label": "Saint-Zacharie", "value": "C34041"},
                               {"label": "Sainte-Agnes", "value": "C34042"},
                               {"label": "Sainte-Anastasie-sur-Issole",
                                "value": "C34043"},
                               {"label": "Sainte-Anne", "value": "C34044"},
                               {"label": "Sainte-Cecile-les-Vignes",
                                "value": "C34045"},
                               {"label": "Sainte-Marguerite",
                                "value": "C34046"},
                               {"label": "Sainte-Marthe", "value": "C34047"},
                               {"label": "Sainte-Maxime", "value": "C34048"},
                               {"label": "Sainte-Tulle", "value": "C34049"},
                               {"label": "Saintes-Maries-de-la-Mer",
                                "value": "C34050"},
                               {"label": "Salernes", "value": "C34051"},
                               {"label": "Salon-de-Provence",
                                "value": "C34052"},
                               {"label": "Sanary-sur-Mer", "value": "C34053"},
                               {"label": "Sarrians", "value": "C34054"},
                               {"label": "Sault", "value": "C34055"},
                               {"label": "Sausset-les-Pins", "value": "C34056"},
                               {"label": "Seillans", "value": "C34057"},
                               {"label": "Senas", "value": "C34058"},
                               {"label": "Septemes-les-Vallons",
                                "value": "C34059"},
                               {"label": "Serignan-du-Comtat",
                                "value": "C34060"},
                               {"label": "Serres", "value": "C34061"},
                               {"label": "Seyne-les-Alpes", "value": "C34062"},
                               {"label": "Signes", "value": "C34063"},
                               {"label": "Simiane-Collongue",
                                "value": "C34064"},
                               {"label": "Sisteron", "value": "C34065"},
                               {"label": "Six-Fours-les-Plages",
                                "value": "C34066"},
                               {"label": "Sollies-Pont", "value": "C34067"},
                               {"label": "Sollies-Toucas", "value": "C34068"},
                               {"label": "Sollies-Ville", "value": "C34069"},
                               {"label": "Sorgues", "value": "C34070"},
                               {"label": "Sormiou", "value": "C34071"},
                               {"label": "Sospel", "value": "C34072"},
                               {"label": "Speracedes", "value": "C34073"},
                               {"label": "Taillades", "value": "C34074"},
                               {"label": "Tallard", "value": "C34075"},
                               {"label": "Tanneron", "value": "C34076"},
                               {"label": "Taradeau", "value": "C34077"},
                               {"label": "Tarascon", "value": "C34078"},
                               {"label": "Tende", "value": "C34079"},
                               {"label": "Theoule-sur-Mer", "value": "C34080"},
                               {"label": "Thiers", "value": "C34081"},
                               {"label": "Toulon", "value": "C34082"},
                               {"label": "Tourrette-Levens", "value": "C34083"},
                               {"label": "Tourrettes-sur-Loup",
                                "value": "C34084"},
                               {"label": "Tourves", "value": "C34085"},
                               {"label": "Trans-en-Provence",
                                "value": "C34086"},
                               {"label": "Trets", "value": "C34087"},
                               {"label": "Uchaux", "value": "C34088"},
                               {"label": "Vacqueyras", "value": "C34089"},
                               {"label": "Vaison-la-Romaine",
                                "value": "C34090"},
                               {"label": "Valbonne", "value": "C34091"},
                               {"label": "Valensole", "value": "C34092"},
                               {"label": "Vallauris", "value": "C34093"},
                               {"label": "Valreas", "value": "C34094"},
                               {"label": "Var", "value": "C34095"},
                               {"label": "Vedene", "value": "C34096"},
                               {"label": "Velaux", "value": "C34097"},
                               {"label": "Velleron", "value": "C34098"},
                               {"label": "Venasque", "value": "C34099"},
                               {"label": "Vence", "value": "C34100"},
                               {"label": "Venelles", "value": "C34101"},
                               {"label": "Ventabren", "value": "C34102"},
                               {"label": "Verduron", "value": "C34103"},
                               {"label": "Vernegues", "value": "C34104"},
                               {"label": "Veynes", "value": "C34105"},
                               {"label": "Vidauban", "value": "C34106"},
                               {"label": "Vieille Chapelle", "value": "C34107"},
                               {"label": "Villar-Saint-Pancrace",
                                "value": "C34108"},
                               {"label": "Villecroze", "value": "C34109"},
                               {"label": "Villefranche-sur-Mer",
                                "value": "C34110"},
                               {"label": "Villelaure", "value": "C34111"},
                               {"label": "Villeneuve", "value": "C34112"},
                               {"label": "Villeneuve-Loubet",
                                "value": "C34113"},
                               {"label": "Villes-sur-Auzon", "value": "C34114"},
                               {"label": "Vinon-sur-Verdon", "value": "C34115"},
                               {"label": "Violes", "value": "C34116"},
                               {"label": "Visan", "value": "C34117"},
                               {"label": "Vitrolles", "value": "C34118"},
                               {"label": "Volonne", "value": "C34119"},
                               {"label": "Volx", "value": "C34120"}],
 "Provincia de Cartago": [{"label": "Alvarado", "value": "C20836"},
                          {"label": "Cartago", "value": "C20837"},
                          {"label": "Concepcion", "value": "C20838"},
                          {"label": "Cot", "value": "C20839"},
                          {"label": "El Guarco", "value": "C20840"},
                          {"label": "Jimenez", "value": "C20841"},
                          {"label": "La Suiza", "value": "C20842"},
                          {"label": "La Union", "value": "C20843"},
                          {"label": "Oreamuno", "value": "C20844"},
                          {"label": "Orosi", "value": "C20845"},
                          {"label": "Pacayas", "value": "C20846"},
                          {"label": "Paraiso", "value": "C20847"},
                          {"label": "Pejibaye", "value": "C20848"},
                          {"label": "San Diego", "value": "C20849"},
                          {"label": "Tobosi", "value": "C20850"},
                          {"label": "Tres Rios", "value": "C20851"},
                          {"label": "Tucurrique", "value": "C20852"},
                          {"label": "Turrialba", "value": "C20853"}],
 "Pskov Oblast": [{"label": "Bezhanitsy", "value": "C103248"},
                  {"label": "Dedovichi", "value": "C103249"},
                  {"label": "Dno", "value": "C103250"},
                  {"label": "Gdov", "value": "C103251"},
                  {"label": "Gdovskiy Rayon", "value": "C103252"},
                  {"label": "Idritsa", "value": "C103253"},
                  {"label": "Izborsk", "value": "C103254"},
                  {"label": "Krasnogorodsk", "value": "C103255"},
                  {"label": "Krasnyy Luch", "value": "C103256"},
                  {"label": "Kuninskiy Rayon", "value": "C103257"},
                  {"label": "Loknya", "value": "C103258"},
                  {"label": "Nevel", "value": "C103259"},
                  {"label": "Novorzhev", "value": "C103260"},
                  {"label": "Novorzhevskiy Rayon", "value": "C103261"},
                  {"label": "Novosokolnicheskiy Rayon", "value": "C103262"},
                  {"label": "Novosokolniki", "value": "C103263"},
                  {"label": "Novyy Izborsk", "value": "C103264"},
                  {"label": "Opochka", "value": "C103265"},
                  {"label": "Ostrov", "value": "C103266"},
                  {"label": "Ostrovskiy Rayon", "value": "C103267"},
                  {"label": "Palkino", "value": "C103268"},
                  {"label": "Palkinskiy Rayon", "value": "C103269"},
                  {"label": "Pechorskiy Rayon", "value": "C103270"},
                  {"label": "Pechory", "value": "C103271"},
                  {"label": "Plyussa", "value": "C103272"},
                  {"label": "Porkhov", "value": "C103273"},
                  {"label": "Porkhovskiy Rayon", "value": "C103274"},
                  {"label": "Pskov", "value": "C103275"},
                  {"label": "Pskovskiy Rayon", "value": "C103276"},
                  {"label": "Pushkino-Gorskiy Rayon", "value": "C103277"},
                  {"label": "Pushkinskiye Gory", "value": "C103278"},
                  {"label": "Pustoshka", "value": "C103279"},
                  {"label": "Pustoshkinskiy Rayon", "value": "C103280"},
                  {"label": "Pytalovo", "value": "C103281"},
                  {"label": "Pytalovskiy Rayon", "value": "C103282"},
                  {"label": "Sebezh", "value": "C103283"},
                  {"label": "Sebezhskiy Rayon", "value": "C103284"},
                  {"label": "Seredka", "value": "C103285"},
                  {"label": "Strugi-Krasnyye", "value": "C103286"},
                  {"label": "Strugo-Krasnenskiy Rayon", "value": "C103287"},
                  {"label": "Usvyaty", "value": "C103288"},
                  {"label": "Velikiye Luki", "value": "C103289"},
                  {"label": "Velikolukskiy Rayon", "value": "C103290"},
                  {"label": "Vybor", "value": "C103291"}],
 "Ptuj City Municipality": [{"label": "Ptuj", "value": "C107770"}],
 "Puconci Municipality": [{"label": "Puconci", "value": "C107771"}],
 "Puducherry": [{"label": "Karaikal", "value": "C47423"},
                {"label": "Mahe", "value": "C47424"},
                {"label": "Puducherry", "value": "C47425"},
                {"label": "Yanam", "value": "C47426"}],
 "Puebla": [{"label": "18 de Marzo", "value": "C72201"},
            {"label": "Acajete", "value": "C72202"},
            {"label": "Acateno", "value": "C72203"},
            {"label": "Acatepec", "value": "C72204"},
            {"label": "Acatlan", "value": "C72205"},
            {"label": "Acatlan de Osorio", "value": "C72206"},
            {"label": "Acatzingo", "value": "C72207"},
            {"label": "Acaxtlahuacan de Albino Zertuche", "value": "C72208"},
            {"label": "Acteopan", "value": "C72209"},
            {"label": "Actipan", "value": "C72210"},
            {"label": "Actipan de Morelos", "value": "C72211"},
            {"label": "Acuaco", "value": "C72212"},
            {"label": "Acuexcomac", "value": "C72213"},
            {"label": "Ahuacatlan", "value": "C72214"},
            {"label": "Ahuatempan", "value": "C72215"},
            {"label": "Ahuatepec", "value": "C72216"},
            {"label": "Ahuatepec de Camino", "value": "C72217"},
            {"label": "Ahuatlan", "value": "C72218"},
            {"label": "Ahuaxintitla", "value": "C72219"},
            {"label": "Ahuazotepec", "value": "C72220"},
            {"label": "Ahuehuetitla", "value": "C72221"},
            {"label": "Ajalpan", "value": "C72222"},
            {"label": "alamos Tepetitlan", "value": "C72223"},
            {"label": "Albino Zertuche", "value": "C72224"},
            {"label": "Alhuaca", "value": "C72225"},
            {"label": "Aljojuca", "value": "C72226"},
            {"label": "Allende", "value": "C72227"},
            {"label": "Almecatla", "value": "C72228"},
            {"label": "Almolonga", "value": "C72229"},
            {"label": "Altepexi", "value": "C72230"},
            {"label": "alvaro Obregon", "value": "C72231"},
            {"label": "Amatitlan de Azueta", "value": "C72232"},
            {"label": "Ameluca", "value": "C72233"},
            {"label": "Amixtlan", "value": "C72234"},
            {"label": "Amozoc", "value": "C72235"},
            {"label": "Amozoc de Mota", "value": "C72236"},
            {"label": "Analco de Ponciano Arriaga (Santa Cruz Analco)",
             "value": "C72237"},
            {"label": "Apango de Zaragoza", "value": "C72238"},
            {"label": "Apanteopan", "value": "C72239"},
            {"label": "Apapantilla", "value": "C72240"},
            {"label": "Apatauyan", "value": "C72241"},
            {"label": "Aquixtla", "value": "C72242"},
            {"label": "Articulo Primero Constitucional", "value": "C72243"},
            {"label": "Atalpan", "value": "C72244"},
            {"label": "Atecax", "value": "C72245"},
            {"label": "Atempan", "value": "C72246"},
            {"label": "Atencingo", "value": "C72247"},
            {"label": "Atexcal", "value": "C72248"},
            {"label": "Atla", "value": "C72249"},
            {"label": "Atlequizayan", "value": "C72250"},
            {"label": "Atlixco", "value": "C72251"},
            {"label": "Atoluca", "value": "C72252"},
            {"label": "Atoyatempan", "value": "C72253"},
            {"label": "Atzala", "value": "C72254"},
            {"label": "Atzalan", "value": "C72255"},
            {"label": "Atzingo (La Cumbre)", "value": "C72256"},
            {"label": "Atzitzintla", "value": "C72257"},
            {"label": "Axutla", "value": "C72258"},
            {"label": "Axuxco", "value": "C72259"},
            {"label": "Ayotla", "value": "C72260"},
            {"label": "Ayotoxco de Guerrero", "value": "C72261"},
            {"label": "Ayutla", "value": "C72262"},
            {"label": "Azumbilla", "value": "C72263"},
            {"label": "Barrio de Nuevo Leon", "value": "C72264"},
            {"label": "Bellavista de Victoria (San Jose Bellavista)",
             "value": "C72265"},
            {"label": "Benito Juarez", "value": "C72266"},
            {"label": "Beristain", "value": "C72267"},
            {"label": "Bienvenido", "value": "C72268"},
            {"label": "Buena Vista", "value": "C72269"},
            {"label": "Buenavista", "value": "C72270"},
            {"label": "Buenavista de Juarez", "value": "C72271"},
            {"label": "Buenavista Tetela", "value": "C72272"},
            {"label": "Buenos Aires", "value": "C72273"},
            {"label": "Cacaloxuchitl", "value": "C72274"},
            {"label": "Cala Norte", "value": "C72275"},
            {"label": "Cala Sur", "value": "C72276"},
            {"label": "Calipan", "value": "C72277"},
            {"label": "Calmeca", "value": "C72278"},
            {"label": "Calpaneria Atezquilla", "value": "C72279"},
            {"label": "Camocuautla", "value": "C72280"},
            {"label": "Camotepec", "value": "C72281"},
            {"label": "Candelaria Portezuelo", "value": "C72282"},
            {"label": "Carmen Serdan", "value": "C72283"},
            {"label": "Casa Blanca", "value": "C72284"},
            {"label": "Caxhuacan", "value": "C72285"},
            {"label": "Chalchihuapan", "value": "C72286"},
            {"label": "Chapulco", "value": "C72287"},
            {"label": "Chiautla de Tapia", "value": "C72288"},
            {"label": "Chicahuaxtla", "value": "C72289"},
            {"label": "Chichicapa", "value": "C72290"},
            {"label": "Chichicuautla", "value": "C72291"},
            {"label": "Chichiquila", "value": "C72292"},
            {"label": "Chiconcuautla", "value": "C72293"},
            {"label": "Chicontla", "value": "C72294"},
            {"label": "Chietla", "value": "C72295"},
            {"label": "Chigmecatitlan", "value": "C72296"},
            {"label": "Chignahuapan", "value": "C72297"},
            {"label": "Chila", "value": "C72298"},
            {"label": "Chila de Juarez", "value": "C72299"},
            {"label": "Chila de la Sal", "value": "C72300"},
            {"label": "Chilchotla", "value": "C72301"},
            {"label": "Chinantla", "value": "C72302"},
            {"label": "Chinautla", "value": "C72303"},
            {"label": "Chipahuatlan", "value": "C72304"},
            {"label": "Chipilo de Francisco Javier Mina", "value": "C72305"},
            {"label": "Cholula", "value": "C72306"},
            {"label": "Cinco de Mayo", "value": "C72307"},
            {"label": "Ciudad de Chiautla de Tapia", "value": "C72308"},
            {"label": "Ciudad de Cuetzalan", "value": "C72309"},
            {"label": "Ciudad de Tlatlauquitepec", "value": "C72310"},
            {"label": "Ciudad Serdan", "value": "C72311"},
            {"label": "Coahuixco", "value": "C72312"},
            {"label": "Coatepec", "value": "C72313"},
            {"label": "Coatzingo", "value": "C72314"},
            {"label": "Cohuecan", "value": "C72315"},
            {"label": "Colonia Agricola de Ocotepec (Colonia San Jose)",
             "value": "C72316"},
            {"label": "Colonia Obrera", "value": "C72317"},
            {"label": "Colucan", "value": "C72318"},
            {"label": "Comaltepec", "value": "C72319"},
            {"label": "Concepcion Capulac (La Ex-Hacienda)", "value": "C72320"},
            {"label": "Concepcion Cuautla", "value": "C72321"},
            {"label": "Copila", "value": "C72322"},
            {"label": "Coronango", "value": "C72323"},
            {"label": "Coronel Tito Hernandez", "value": "C72324"},
            {"label": "Coxcatlan", "value": "C72325"},
            {"label": "Coxolico", "value": "C72326"},
            {"label": "Coyomeapan", "value": "C72327"},
            {"label": "Coyotepec", "value": "C72328"},
            {"label": "Cuacnopalan", "value": "C72329"},
            {"label": "Cuacuila", "value": "C72330"},
            {"label": "Cuanala", "value": "C72331"},
            {"label": "Cuapiaxtla de Madero", "value": "C72332"},
            {"label": "Cuatro Caminos", "value": "C72333"},
            {"label": "Cuauhtamazaco", "value": "C72334"},
            {"label": "Cuauhtemoc", "value": "C72335"},
            {"label": "Cuautempan", "value": "C72336"},
            {"label": "Cuautinchan", "value": "C72337"},
            {"label": "Cuautlancingo", "value": "C72338"},
            {"label": "Cuautotola", "value": "C72339"},
            {"label": "Cuautotolapan (San Jose)", "value": "C72340"},
            {"label": "Cuaxoxpan", "value": "C72341"},
            {"label": "Cuaxuxpa", "value": "C72342"},
            {"label": "Cuayuca de Andrade", "value": "C72343"},
            {"label": "Cuesta Blanca", "value": "C72344"},
            {"label": "Cuetzalan", "value": "C72345"},
            {"label": "Cuetzalan del Progreso", "value": "C72346"},
            {"label": "Cuexpala", "value": "C72347"},
            {"label": "Cuyoaco", "value": "C72348"},
            {"label": "Dolores Hidalgo", "value": "C72349"},
            {"label": "Domingo Arenas", "value": "C72350"},
            {"label": "El Capulo (La Quebradora)", "value": "C72351"},
            {"label": "El Carmen", "value": "C72352"},
            {"label": "El Chacal", "value": "C72353"},
            {"label": "El Encanto del Cerril", "value": "C72354"},
            {"label": "El Fuerte de la Union", "value": "C72355"},
            {"label": "El Mirador", "value": "C72356"},
            {"label": "El Molino", "value": "C72357"},
            {"label": "El Palmar", "value": "C72358"},
            {"label": "El Progreso", "value": "C72359"},
            {"label": "El Rincon Citlaltepetl", "value": "C72360"},
            {"label": "El Veladero", "value": "C72361"},
            {"label": "Eloxochitlan", "value": "C72362"},
            {"label": "Emancipacion Quetzalapa", "value": "C72363"},
            {"label": "Emilio Carranza (Santa Cruz)", "value": "C72364"},
            {"label": "Emilio Portes Gil", "value": "C72365"},
            {"label": "Esperanza", "value": "C72366"},
            {"label": "Francisco I. Madero", "value": "C72367"},
            {"label": "Francisco Ignacio Madero", "value": "C72368"},
            {"label": "Francisco Sarabia", "value": "C72369"},
            {"label": "Francisco Villa", "value": "C72370"},
            {"label": "Francisco Z. Mena", "value": "C72371"},
            {"label": "Galaxia la Calera", "value": "C72372"},
            {"label": "General Felipe angeles", "value": "C72373"},
            {"label": "Gilberto Camacho", "value": "C72374"},
            {"label": "Gomez Poniente", "value": "C72375"},
            {"label": "Gonzalez Ortega", "value": "C72376"},
            {"label": "Grajales", "value": "C72377"},
            {"label": "Guadalupe", "value": "C72378"},
            {"label": "Guadalupe Calderon", "value": "C72379"},
            {"label": "Guadalupe Enriquez", "value": "C72380"},
            {"label": "Guadalupe Libertad", "value": "C72381"},
            {"label": "Guadalupe Morelos", "value": "C72382"},
            {"label": "Guadalupe Santa Ana", "value": "C72383"},
            {"label": "Guadalupe Sarabia", "value": "C72384"},
            {"label": "Guadalupe Victoria", "value": "C72385"},
            {"label": "Guadalupe Zaragoza", "value": "C72386"},
            {"label": "Hermenegildo Galeana", "value": "C72387"},
            {"label": "Honey", "value": "C72388"},
            {"label": "Huachinantla", "value": "C72389"},
            {"label": "Huahuaxtla", "value": "C72390"},
            {"label": "Huajoyuca", "value": "C72391"},
            {"label": "Huaquechula", "value": "C72392"},
            {"label": "Huatlatlauca", "value": "C72393"},
            {"label": "Huauchinango", "value": "C72394"},
            {"label": "Huautla de Jimenez", "value": "C72395"},
            {"label": "Huaxcaleca", "value": "C72396"},
            {"label": "Huehuetla", "value": "C72397"},
            {"label": "Huehuetlan el Chico", "value": "C72398"},
            {"label": "Huehuetlan el Grande", "value": "C72399"},
            {"label": "Huejonapan", "value": "C72400"},
            {"label": "Huejotzingo", "value": "C72401"},
            {"label": "Hueyapan", "value": "C72402"},
            {"label": "Hueyotlipan", "value": "C72403"},
            {"label": "Hueytamalco", "value": "C72404"},
            {"label": "Hueytlalpan", "value": "C72405"},
            {"label": "Huilacapixtla", "value": "C72406"},
            {"label": "Huitzilac", "value": "C72407"},
            {"label": "Huitzilan", "value": "C72408"},
            {"label": "Huitziltepec", "value": "C72409"},
            {"label": "Huitzmaloc", "value": "C72410"},
            {"label": "Huixcolotla", "value": "C72411"},
            {"label": "Icxotitla", "value": "C72412"},
            {"label": "Ignacio Allende (Concepcion)", "value": "C72413"},
            {"label": "Ignacio Manuel Altamirano", "value": "C72414"},
            {"label": "Ignacio Zaragoza", "value": "C72415"},
            {"label": "Itzoteno", "value": "C72416"},
            {"label": "Ixcamilpa de Guerrero", "value": "C72417"},
            {"label": "Ixcaquixtla", "value": "C72418"},
            {"label": "Ixtacamaxtitlan", "value": "C72419"},
            {"label": "Ixtahuiata (La Legua)", "value": "C72420"},
            {"label": "Ixtepec", "value": "C72421"},
            {"label": "Ixticpan", "value": "C72422"},
            {"label": "Ixtlahuaca", "value": "C72423"},
            {"label": "Ixtlahuaca Barrio", "value": "C72424"},
            {"label": "Ixtolco de Morelos", "value": "C72425"},
            {"label": "Izucar de Matamoros", "value": "C72426"},
            {"label": "Jalpan", "value": "C72427"},
            {"label": "Jaltocan", "value": "C72428"},
            {"label": "Jesus Nazareno", "value": "C72429"},
            {"label": "Jicolapa", "value": "C72430"},
            {"label": "Jilotzingo", "value": "C72431"},
            {"label": "Jolalpan", "value": "C72432"},
            {"label": "Jonotla", "value": "C72433"},
            {"label": "Jopala", "value": "C72434"},
            {"label": "Jose Maria Morelos", "value": "C72435"},
            {"label": "Juan C. Bonilla", "value": "C72436"},
            {"label": "Juan de la Granja", "value": "C72437"},
            {"label": "Juan Galindo", "value": "C72438"},
            {"label": "Juan N. Mendez", "value": "C72439"},
            {"label": "Juarez Coronaco", "value": "C72440"},
            {"label": "La Canada", "value": "C72441"},
            {"label": "La Compania", "value": "C72442"},
            {"label": "La Galarza", "value": "C72443"},
            {"label": "La Gloria", "value": "C72444"},
            {"label": "La Laguna", "value": "C72445"},
            {"label": "La Libertad", "value": "C72446"},
            {"label": "La Magdalena", "value": "C72447"},
            {"label": "La Magdalena Tetela Morelos", "value": "C72448"},
            {"label": "La Magdalena Yancuitlalpan", "value": "C72449"},
            {"label": "La Pahua", "value": "C72450"},
            {"label": "La Purisima", "value": "C72451"},
            {"label": "La Resurreccion", "value": "C72452"},
            {"label": "La Soledad", "value": "C72453"},
            {"label": "La Trinidad Chautenco", "value": "C72454"},
            {"label": "La Trinidad Tianguismanalco", "value": "C72455"},
            {"label": "La Union", "value": "C72456"},
            {"label": "La Union Ejido Mexcaltepec", "value": "C72457"},
            {"label": "Lacapan Camallagne", "value": "C72458"},
            {"label": "Lafragua", "value": "C72459"},
            {"label": "Las Colonias de Hidalgo", "value": "C72460"},
            {"label": "Las Lajas", "value": "C72461"},
            {"label": "Las Lomas", "value": "C72462"},
            {"label": "Las Nieves", "value": "C72463"},
            {"label": "Lazaro Cardenas", "value": "C72464"},
            {"label": "Leacaman", "value": "C72465"},
            {"label": "Libertad alvaro Obregon", "value": "C72466"},
            {"label": "Libres", "value": "C72467"},
            {"label": "Lipuntahuaca", "value": "C72468"},
            {"label": "Loma Alta", "value": "C72469"},
            {"label": "Loma Bonita", "value": "C72470"},
            {"label": "Lomas de Romero", "value": "C72471"},
            {"label": "Lomas de Santa Cruz", "value": "C72472"},
            {"label": "Los angeles Tetela", "value": "C72473"},
            {"label": "Los Cristales (Talican)", "value": "C72474"},
            {"label": "Los Encinos", "value": "C72475"},
            {"label": "Los Garcias", "value": "C72476"},
            {"label": "Los Reyes de Juarez", "value": "C72477"},
            {"label": "Los Reyes Tlanechicolpan", "value": "C72478"},
            {"label": "Magdalena Cuayucatepec", "value": "C72479"},
            {"label": "Manantiales", "value": "C72480"},
            {"label": "Manuel Edgardo avalos (San Isidro)", "value": "C72481"},
            {"label": "Matlahuacales Aquiles Serdan", "value": "C72482"},
            {"label": "Matzaco", "value": "C72483"},
            {"label": "Maximo Serdan", "value": "C72484"},
            {"label": "Mazapiltepec", "value": "C72485"},
            {"label": "Mazatepec", "value": "C72486"},
            {"label": "Mecapalapa", "value": "C72487"},
            {"label": "Metlaltoyuca", "value": "C72488"},
            {"label": "Mexcalcuautla", "value": "C72489"},
            {"label": "Miahuatlan", "value": "C72490"},
            {"label": "Michac", "value": "C72491"},
            {"label": "Miguel Hidalgo", "value": "C72492"},
            {"label": "Miguel Negrete", "value": "C72493"},
            {"label": "Miravalles", "value": "C72494"},
            {"label": "Mixtla", "value": "C72495"},
            {"label": "Morelos", "value": "C72496"},
            {"label": "Morelos Canada", "value": "C72497"},
            {"label": "Moyotzingo", "value": "C72498"},
            {"label": "Nanacatlan", "value": "C72499"},
            {"label": "Naupan", "value": "C72500"},
            {"label": "Nauzontla", "value": "C72501"},
            {"label": "Nealtican", "value": "C72502"},
            {"label": "Necaxa", "value": "C72503"},
            {"label": "Necoxcalco", "value": "C72504"},
            {"label": "Necoxtla", "value": "C72505"},
            {"label": "Nenehuaca", "value": "C72506"},
            {"label": "Nexpan", "value": "C72507"},
            {"label": "Nexticapan", "value": "C72508"},
            {"label": "Nicolas Bravo", "value": "C72509"},
            {"label": "Nopalucan", "value": "C72510"},
            {"label": "Nuevo Mexico", "value": "C72511"},
            {"label": "Nuevo Necaxa", "value": "C72512"},
            {"label": "Ocotepec", "value": "C72513"},
            {"label": "Ocotlamanic", "value": "C72514"},
            {"label": "Ocotlan de Betancourt", "value": "C72515"},
            {"label": "Ocotlan de Venustiano Carranza", "value": "C72516"},
            {"label": "Olintla", "value": "C72517"},
            {"label": "Ometepec", "value": "C72518"},
            {"label": "Oriental", "value": "C72519"},
            {"label": "Oyameles de Hidalgo", "value": "C72520"},
            {"label": "Ozelonacaxtla", "value": "C72521"},
            {"label": "Pahuatlan de Valle", "value": "C72522"},
            {"label": "Palmar de Bravo", "value": "C72523"},
            {"label": "Palmarito Tochapan", "value": "C72524"},
            {"label": "Pantepec", "value": "C72525"},
            {"label": "Papaloctipan", "value": "C72526"},
            {"label": "Papatlazolco", "value": "C72527"},
            {"label": "Paso Carretas", "value": "C72528"},
            {"label": "Paso Nacional", "value": "C72529"},
            {"label": "Patla", "value": "C72530"},
            {"label": "Pericotepec", "value": "C72531"},
            {"label": "Petlalcingo", "value": "C72532"},
            {"label": "Pezmatlan", "value": "C72533"},
            {"label": "Piaxtla", "value": "C72534"},
            {"label": "Pochalcatl", "value": "C72535"},
            {"label": "Primero de Mayo", "value": "C72536"},
            {"label": "Progreso", "value": "C72537"},
            {"label": "Progreso de Juarez", "value": "C72538"},
            {"label": "Puebla", "value": "C72539"},
            {"label": "Putlunichuchut (Vista Hermosa)", "value": "C72540"},
            {"label": "Quamila", "value": "C72541"},
            {"label": "Quecholac", "value": "C72542"},
            {"label": "Quechulac", "value": "C72543"},
            {"label": "Rafael J. Garcia", "value": "C72544"},
            {"label": "Rancho Chico", "value": "C72545"},
            {"label": "Rancho Nuevo", "value": "C72546"},
            {"label": "Ricardo Flores Magon", "value": "C72547"},
            {"label": "Rincon de los Reyes", "value": "C72548"},
            {"label": "Saltillo", "value": "C72549"},
            {"label": "San Acateno", "value": "C72550"},
            {"label": "San Agustin Atzompa", "value": "C72551"},
            {"label": "San Agustin Huixaxtla", "value": "C72552"},
            {"label": "San Agustin Tlaxco", "value": "C72553"},
            {"label": "San Andres", "value": "C72554"},
            {"label": "San Andres Azumiatla", "value": "C72555"},
            {"label": "San Andres Cacaloapan", "value": "C72556"},
            {"label": "San Andres Calpan", "value": "C72557"},
            {"label": "San Andres Cholula", "value": "C72558"},
            {"label": "San Andres Hueyacatitla", "value": "C72559"},
            {"label": "San Andres Payuca", "value": "C72560"},
            {"label": "San Andres Yahuitlalpan", "value": "C72561"},
            {"label": "San Antonio", "value": "C72562"},
            {"label": "San Antonio Alpanocan", "value": "C72563"},
            {"label": "San Antonio Atotonilco", "value": "C72564"},
            {"label": "San Antonio Canada", "value": "C72565"},
            {"label": "San Antonio Juarez", "value": "C72566"},
            {"label": "San Antonio la Portilla", "value": "C72567"},
            {"label": "San Antonio Matlahuacales", "value": "C72568"},
            {"label": "San Antonio Mihuacan", "value": "C72569"},
            {"label": "San Antonio Ocopetlatlan", "value": "C72570"},
            {"label": "San Antonio Portezuelo", "value": "C72571"},
            {"label": "San Antonio Soledad", "value": "C72572"},
            {"label": "San Antonio Tecolco", "value": "C72573"},
            {"label": "San Antonio Tlacamilco", "value": "C72574"},
            {"label": "San Antonio Tlatenco", "value": "C72575"},
            {"label": "San Antonio Virreyes", "value": "C72576"},
            {"label": "San Baltazar Atlimeyaya", "value": "C72577"},
            {"label": "San Bartolome", "value": "C72578"},
            {"label": "San Bartolome Hueyapan", "value": "C72579"},
            {"label": "San Bernabe Temoxtitla", "value": "C72580"},
            {"label": "San Bernardino Lagunas", "value": "C72581"},
            {"label": "San Bernardino Tlaxcalancingo", "value": "C72582"},
            {"label": "San Buenaventura", "value": "C72583"},
            {"label": "San Buenaventura Tecaltzingo", "value": "C72584"},
            {"label": "San Carlos", "value": "C72585"},
            {"label": "San Cristobal Hidalgo", "value": "C72586"},
            {"label": "San Cristobal los Nava", "value": "C72587"},
            {"label": "San Cristobal Xochimilpa", "value": "C72588"},
            {"label": "San Diego", "value": "C72589"},
            {"label": "San Diego el Organal", "value": "C72590"},
            {"label": "San Diego la Mesa Tochimiltzingo", "value": "C72591"},
            {"label": "San Felipe Hidalgo", "value": "C72592"},
            {"label": "San Felipe Otlaltepec", "value": "C72593"},
            {"label": "San Felix Hidalgo", "value": "C72594"},
            {"label": "San Felix Rijo", "value": "C72595"},
            {"label": "San Francisco", "value": "C72596"},
            {"label": "San Francisco Cuapa", "value": "C72597"},
            {"label": "San Francisco Cuautlancingo", "value": "C72598"},
            {"label": "San Francisco Independencia (Santa Maria Aserradero)",
             "value": "C72599"},
            {"label": "San Francisco Ocotlan", "value": "C72600"},
            {"label": "San Francisco Tepeyecac", "value": "C72601"},
            {"label": "San Francisco Tlaloc", "value": "C72602"},
            {"label": "San Francisco Zacapexpan", "value": "C72603"},
            {"label": "San Gabriel Chilac", "value": "C72604"},
            {"label": "San Gabriel Tetzoyocan", "value": "C72605"},
            {"label": "San Gregorio Atzompa", "value": "C72606"},
            {"label": "San Gregorio Aztotoacan", "value": "C72607"},
            {"label": "San Hipolito", "value": "C72608"},
            {"label": "San Isidro", "value": "C72609"},
            {"label": "San Isidro Huilotepec", "value": "C72610"},
            {"label": "San Isidro Monterrosas", "value": "C72611"},
            {"label": "San Isidro Vista Hermosa", "value": "C72612"},
            {"label": "San Jeronimo Almoloya", "value": "C72613"},
            {"label": "San Jeronimo Axochitlan", "value": "C72614"},
            {"label": "San Jeronimo Coaltepec", "value": "C72615"},
            {"label": "San Jeronimo Coyula", "value": "C72616"},
            {"label": "San Jeronimo Ocotitla", "value": "C72617"},
            {"label": "San Jeronimo Xayacatlan", "value": "C72618"},
            {"label": "San Jose", "value": "C72619"},
            {"label": "San Jose Acateno", "value": "C72620"},
            {"label": "San Jose Alchichica", "value": "C72621"},
            {"label": "San Jose Buena Vista", "value": "C72622"},
            {"label": "San Jose Buenavista", "value": "C72623"},
            {"label": "San Jose Carpinteros", "value": "C72624"},
            {"label": "San Jose Chiapa", "value": "C72625"},
            {"label": "San Jose Cuyachapa", "value": "C72626"},
            {"label": "San Jose de Gracia", "value": "C72627"},
            {"label": "San Jose Ixtapa", "value": "C72628"},
            {"label": "San Jose Manzanitos", "value": "C72629"},
            {"label": "San Jose Monte Chiquito", "value": "C72630"},
            {"label": "San Jose Ozumba", "value": "C72631"},
            {"label": "San Jose Tilapa", "value": "C72632"},
            {"label": "San Jose Tlacuitlapan", "value": "C72633"},
            {"label": "San Juan Amecac", "value": "C72634"},
            {"label": "San Juan Atenco", "value": "C72635"},
            {"label": "San Juan Atzingo", "value": "C72636"},
            {"label": "San Juan Atzompa", "value": "C72637"},
            {"label": "San Juan Colon", "value": "C72638"},
            {"label": "San Juan Cuauhtemoc", "value": "C72639"},
            {"label": "San Juan Cuautla", "value": "C72640"},
            {"label": "San Juan Epatlan", "value": "C72641"},
            {"label": "San Juan Huiluco", "value": "C72642"},
            {"label": "San Juan Ixcaquixtla", "value": "C72643"},
            {"label": "San Juan Pancoac", "value": "C72644"},
            {"label": "San Juan Raboso", "value": "C72645"},
            {"label": "San Juan Tahitic", "value": "C72646"},
            {"label": "San Juan Tejaluca", "value": "C72647"},
            {"label": "San Juan Tejupa", "value": "C72648"},
            {"label": "San Juan Tepulco", "value": "C72649"},
            {"label": "San Juan Tetla", "value": "C72650"},
            {"label": "San Juan Tezongo", "value": "C72651"},
            {"label": "San Juan Tuxco", "value": "C72652"},
            {"label": "San Juan Xiutetelco", "value": "C72653"},
            {"label": "San Lorenzo", "value": "C72654"},
            {"label": "San Lorenzo Chiautzingo", "value": "C72655"},
            {"label": "San Lorenzo Joya de Rodriguez", "value": "C72656"},
            {"label": "San Lorenzo Tlaxipehuala", "value": "C72657"},
            {"label": "San Lucas Atzala", "value": "C72658"},
            {"label": "San Lucas el Grande", "value": "C72659"},
            {"label": "San Lucas el Viejo", "value": "C72660"},
            {"label": "San Luis Ajajalpan", "value": "C72661"},
            {"label": "San Luis Coyotzingo", "value": "C72662"},
            {"label": "San Luis Tehuiloyocan", "value": "C72663"},
            {"label": "San Marcos Eloxochitlan", "value": "C72664"},
            {"label": "San Marcos Tlacoyalco", "value": "C72665"},
            {"label": "San Martin", "value": "C72666"},
            {"label": "San Martin Caltenco", "value": "C72667"},
            {"label": "San Martin Esperilla", "value": "C72668"},
            {"label": "San Martin Mazateopan", "value": "C72669"},
            {"label": "San Martin Ojo de Agua", "value": "C72670"},
            {"label": "San Martin Texmelucan", "value": "C72671"},
            {"label": "San Martin Texmelucan de Labastida", "value": "C72672"},
            {"label": "San Martin Tlamapa", "value": "C72673"},
            {"label": "San Martin Tlapala", "value": "C72674"},
            {"label": "San Mateo Ayecac", "value": "C72675"},
            {"label": "San Mateo Capultitlan", "value": "C72676"},
            {"label": "San Mateo Ozolco", "value": "C72677"},
            {"label": "San Mateo Parra", "value": "C72678"},
            {"label": "San Mateo Soltepec", "value": "C72679"},
            {"label": "San Matias Atzala", "value": "C72680"},
            {"label": "San Matias Tlalancaleca", "value": "C72681"},
            {"label": "San Miguel Aguacomulican", "value": "C72682"},
            {"label": "San Miguel Analco", "value": "C72683"},
            {"label": "San Miguel Atlapulco", "value": "C72684"},
            {"label": "San Miguel Canoa", "value": "C72685"},
            {"label": "San Miguel del Milagro", "value": "C72686"},
            {"label": "San Miguel del Progreso", "value": "C72687"},
            {"label": "San Miguel Espejo", "value": "C72688"},
            {"label": "San Miguel Ixitlan", "value": "C72689"},
            {"label": "San Miguel Ocotenco", "value": "C72690"},
            {"label": "San Miguel Papaxtla", "value": "C72691"},
            {"label": "San Miguel Tecuitlapa", "value": "C72692"},
            {"label": "San Miguel Tenango", "value": "C72693"},
            {"label": "San Miguel Tenextatiloyan", "value": "C72694"},
            {"label": "San Miguel Tianguistenco", "value": "C72695"},
            {"label": "San Miguel Tianguizolco", "value": "C72696"},
            {"label": "San Miguel Xoxtla", "value": "C72697"},
            {"label": "San Miguel Zoapan", "value": "C72698"},
            {"label": "San Miguel Zozutla", "value": "C72699"},
            {"label": "San Nicolas Buenos Aires", "value": "C72700"},
            {"label": "San Nicolas de los Ranchos", "value": "C72701"},
            {"label": "San Nicolas Tolentino", "value": "C72702"},
            {"label": "San Nicolas Zecalacoayan", "value": "C72703"},
            {"label": "San Nicolas Zoyapetlayoca", "value": "C72704"},
            {"label": "San Pablito", "value": "C72705"},
            {"label": "San Pablo Ahuatempa", "value": "C72706"},
            {"label": "San Pablo Anicano", "value": "C72707"},
            {"label": "San Pablo de las Tunas", "value": "C72708"},
            {"label": "San Pedro Atmatla", "value": "C72709"},
            {"label": "San Pedro Benito Juarez", "value": "C72710"},
            {"label": "San Pedro Cholula", "value": "C72711"},
            {"label": "San Pedro Cuayuca", "value": "C72712"},
            {"label": "San Pedro Itztla", "value": "C72713"},
            {"label": "San Pedro la Joya", "value": "C72714"},
            {"label": "San Pedro Matamoros", "value": "C72715"},
            {"label": "San Pedro Petlacotla", "value": "C72716"},
            {"label": "San Pedro Temamatla", "value": "C72717"},
            {"label": "San Pedro Tetitlan", "value": "C72718"},
            {"label": "San Pedro Tlaolantongo", "value": "C72719"},
            {"label": "San Pedro Yancuitlalpan", "value": "C72720"},
            {"label": "San Pedro Yeloixtlahuaca", "value": "C72721"},
            {"label": "San Pedro Zacachimalpa", "value": "C72722"},
            {"label": "San Rafael Ixtapalucan", "value": "C72723"},
            {"label": "San Rafael Tlanalapan", "value": "C72724"},
            {"label": "San Salvador (Naranjillo)", "value": "C72725"},
            {"label": "San Salvador El Seco", "value": "C72726"},
            {"label": "San Salvador el Verde", "value": "C72727"},
            {"label": "San Sebastian", "value": "C72728"},
            {"label": "San Sebastian Alcomunga", "value": "C72729"},
            {"label": "San Sebastian de Aparicio", "value": "C72730"},
            {"label": "San Sebastian Teteles", "value": "C72731"},
            {"label": "San Sebastian Tlacotepec", "value": "C72732"},
            {"label": "San Sebastian Villanueva", "value": "C72733"},
            {"label": "San Simon", "value": "C72734"},
            {"label": "San Simon Atzitzintla", "value": "C72735"},
            {"label": "San Vicente Boqueron", "value": "C72736"},
            {"label": "San Vicente Coyotepec", "value": "C72737"},
            {"label": "Sanctorum", "value": "C72738"},
            {"label": "Santa Ana Acozautla", "value": "C72739"},
            {"label": "Santa Ana Coapan", "value": "C72740"},
            {"label": "Santa Ana Coatepec", "value": "C72741"},
            {"label": "Santa Ana Necoxtla", "value": "C72742"},
            {"label": "Santa Ana Xalmimilulco", "value": "C72743"},
            {"label": "Santa Catarina Tlaltempan", "value": "C72744"},
            {"label": "Santa Catarina Villanueva", "value": "C72745"},
            {"label": "Santa Cecilia Tepetitlan", "value": "C72746"},
            {"label": "Santa Clara", "value": "C72747"},
            {"label": "Santa Clara Huitziltepec", "value": "C72748"},
            {"label": "Santa Cruz Acapa", "value": "C72749"},
            {"label": "Santa Cruz Ajajalpan", "value": "C72750"},
            {"label": "Santa Cruz Cuautomatitla", "value": "C72751"},
            {"label": "Santa Cruz el Porvenir", "value": "C72752"},
            {"label": "Santa Cruz Huitziltepec", "value": "C72753"},
            {"label": "Santa Cruz Otlatla", "value": "C72754"},
            {"label": "Santa Cruz Texmalaquilla", "value": "C72755"},
            {"label": "Santa Cruz Xaltetela", "value": "C72756"},
            {"label": "Santa Ines Tecuexcomac", "value": "C72757"},
            {"label": "Santa Ines Varela la Luz", "value": "C72758"},
            {"label": "Santa Isabel Cholula", "value": "C72759"},
            {"label": "Santa Lucia Cosamaloapan", "value": "C72760"},
            {"label": "Santa Maria Atexcac", "value": "C72761"},
            {"label": "Santa Maria Coatepec", "value": "C72762"},
            {"label": "Santa Maria del Monte", "value": "C72763"},
            {"label": "Santa Maria Guadalupe Tecola", "value": "C72764"},
            {"label": "Santa Maria Ixtiyucan", "value": "C72765"},
            {"label": "Santa Maria la Alta", "value": "C72766"},
            {"label": "Santa Maria Malacatepec", "value": "C72767"},
            {"label": "Santa Maria Nenetzintla", "value": "C72768"},
            {"label": "Santa Maria Nepopualco", "value": "C72769"},
            {"label": "Santa Maria Techachalco", "value": "C72770"},
            {"label": "Santa Maria Texmelucan", "value": "C72771"},
            {"label": "Santa Maria Xonacatepec", "value": "C72772"},
            {"label": "Santa Maria Zacatepec", "value": "C72773"},
            {"label": "Santa Martha Hidalgo", "value": "C72774"},
            {"label": "Santa Rita", "value": "C72775"},
            {"label": "Santa Rita Tlahuapan", "value": "C72776"},
            {"label": "Santa Rosa", "value": "C72777"},
            {"label": "Santiago", "value": "C72778"},
            {"label": "Santiago Acatlan", "value": "C72779"},
            {"label": "Santiago Acozac", "value": "C72780"},
            {"label": "Santiago Alseseca", "value": "C72781"},
            {"label": "Santiago Atzitzihuacan", "value": "C72782"},
            {"label": "Santiago Coltzingo", "value": "C72783"},
            {"label": "Santiago Momoxpan", "value": "C72784"},
            {"label": "Santiago Tenango", "value": "C72785"},
            {"label": "Santiago Yancuictlalpan", "value": "C72786"},
            {"label": "Santo Domingo Atoyatempan", "value": "C72787"},
            {"label": "Santo Domingo Huehuetlan", "value": "C72788"},
            {"label": "Santo Nombre", "value": "C72789"},
            {"label": "Santo Tomas Chautla", "value": "C72790"},
            {"label": "Seccion 23", "value": "C72791"},
            {"label": "Soledad Morelos", "value": "C72792"},
            {"label": "Soltepec", "value": "C72793"},
            {"label": "Sosa", "value": "C72794"},
            {"label": "Tacopan", "value": "C72795"},
            {"label": "Tanamacoyan", "value": "C72796"},
            {"label": "Tanhuixco", "value": "C72797"},
            {"label": "Tatauzoquico", "value": "C72798"},
            {"label": "Tatoxcac", "value": "C72799"},
            {"label": "Teacalco de Dorantes (San Jose Teacalco)",
             "value": "C72800"},
            {"label": "Tecali", "value": "C72801"},
            {"label": "Tecamachalco", "value": "C72802"},
            {"label": "Tecoltepec", "value": "C72803"},
            {"label": "Tecomatlan", "value": "C72804"},
            {"label": "Tecpantzacoalco", "value": "C72805"},
            {"label": "Tecuanipa", "value": "C72806"},
            {"label": "Tecuanipan", "value": "C72807"},
            {"label": "Tehuacan", "value": "C72808"},
            {"label": "Tehuitzingo", "value": "C72809"},
            {"label": "Tejaluca", "value": "C72810"},
            {"label": "Teloxtoc", "value": "C72811"},
            {"label": "Telpatlan", "value": "C72812"},
            {"label": "Temalacayuca", "value": "C72813"},
            {"label": "Temextla", "value": "C72814"},
            {"label": "Tenampulco", "value": "C72815"},
            {"label": "Tenango de las Flores", "value": "C72816"},
            {"label": "Teontepec", "value": "C72817"},
            {"label": "Teopantlan", "value": "C72818"},
            {"label": "Teotlalco", "value": "C72819"},
            {"label": "Teotlaltzingo", "value": "C72820"},
            {"label": "Tepanco", "value": "C72821"},
            {"label": "Tepango", "value": "C72822"},
            {"label": "Tepango de Rodriguez", "value": "C72823"},
            {"label": "Tepapayeca", "value": "C72824"},
            {"label": "Tepatlaxco de Hidalgo", "value": "C72825"},
            {"label": "Tepazolco", "value": "C72826"},
            {"label": "Tepeaca", "value": "C72827"},
            {"label": "Tepeojuma", "value": "C72828"},
            {"label": "Tepeteno de Iturbide", "value": "C72829"},
            {"label": "Tepetitan Reyeshogpan de Hidalgo", "value": "C72830"},
            {"label": "Tepetlacolco", "value": "C72831"},
            {"label": "Tepetzingo", "value": "C72832"},
            {"label": "Tepetzintla", "value": "C72833"},
            {"label": "Tepetzitzintla", "value": "C72834"},
            {"label": "Tepexco", "value": "C72835"},
            {"label": "Tepexi de Rodriguez", "value": "C72836"},
            {"label": "Tepeyahualco", "value": "C72837"},
            {"label": "Tepeyahualco de Cuauhtemoc", "value": "C72838"},
            {"label": "Tepeyehualco de Hidalgo", "value": "C72839"},
            {"label": "Tetela de Ocampo", "value": "C72840"},
            {"label": "Tetelcingo", "value": "C72841"},
            {"label": "Teteles de Avila Castillo", "value": "C72842"},
            {"label": "Texcala", "value": "C72843"},
            {"label": "Texcalapa de Juarez", "value": "C72844"},
            {"label": "Texocoyohuac", "value": "C72845"},
            {"label": "Tezhuatepec", "value": "C72846"},
            {"label": "Teziutlan", "value": "C72847"},
            {"label": "Tezonteopan de Bonilla", "value": "C72848"},
            {"label": "Tianguismanalco", "value": "C72849"},
            {"label": "Tierra Negra", "value": "C72850"},
            {"label": "Tilapa", "value": "C72851"},
            {"label": "Tlacamilco", "value": "C72852"},
            {"label": "Tlachichuca", "value": "C72853"},
            {"label": "Tlacotepec", "value": "C72854"},
            {"label": "Tlacotepec (San Mateo)", "value": "C72855"},
            {"label": "Tlacotepec de Benito Juarez", "value": "C72856"},
            {"label": "Tlacotepec de Jose Manzo", "value": "C72857"},
            {"label": "Tlacuilotepec", "value": "C72858"},
            {"label": "Tlahuapan", "value": "C72859"},
            {"label": "Tlaixpan", "value": "C72860"},
            {"label": "Tlaltenango", "value": "C72861"},
            {"label": "Tlaltepango", "value": "C72862"},
            {"label": "Tlamanca", "value": "C72863"},
            {"label": "Tlamanca de Hernandez", "value": "C72864"},
            {"label": "Tlamaya Grande", "value": "C72865"},
            {"label": "Tlanalapan", "value": "C72866"},
            {"label": "Tlancualpican", "value": "C72867"},
            {"label": "Tlanepantla", "value": "C72868"},
            {"label": "Tlaola", "value": "C72869"},
            {"label": "Tlapacoya", "value": "C72870"},
            {"label": "Tlapanala", "value": "C72871"},
            {"label": "Tlatempa", "value": "C72872"},
            {"label": "Tlatlauquitepec", "value": "C72873"},
            {"label": "Tlaxcalantongo", "value": "C72874"},
            {"label": "Tlaxco", "value": "C72875"},
            {"label": "Tlaxco (Santiago Tlaxco)", "value": "C72876"},
            {"label": "Tlaxpanaloya", "value": "C72877"},
            {"label": "Tlayehualancingo", "value": "C72878"},
            {"label": "Tlixco", "value": "C72879"},
            {"label": "Tochimilco", "value": "C72880"},
            {"label": "Tochtepec", "value": "C72881"},
            {"label": "Tomaquilapa", "value": "C72882"},
            {"label": "Tomatlan", "value": "C72883"},
            {"label": "Tonalixco", "value": "C72884"},
            {"label": "Totoltepec", "value": "C72885"},
            {"label": "Totoltepec de Guerrero", "value": "C72886"},
            {"label": "Toxtla", "value": "C72887"},
            {"label": "Tozihuic", "value": "C72888"},
            {"label": "Tronconal", "value": "C72889"},
            {"label": "Tula", "value": "C72890"},
            {"label": "Tulcingo", "value": "C72891"},
            {"label": "Tulcingo de Valle", "value": "C72892"},
            {"label": "Tulimanca", "value": "C72893"},
            {"label": "Tuxtla", "value": "C72894"},
            {"label": "Tuzamapan", "value": "C72895"},
            {"label": "Tuzuapan", "value": "C72896"},
            {"label": "Tzicatlacoyan", "value": "C72897"},
            {"label": "Tzicatlan", "value": "C72898"},
            {"label": "Tzinacapan", "value": "C72899"},
            {"label": "Unidad Grajales INFONAVIT", "value": "C72900"},
            {"label": "Venta Grande", "value": "C72901"},
            {"label": "Venustiano Carranza", "value": "C72902"},
            {"label": "Vicente Guerrero", "value": "C72903"},
            {"label": "Villa Avila Camacho", "value": "C72904"},
            {"label": "Villa Cuauhtemoc", "value": "C72905"},
            {"label": "Villa Mariano Matamoros", "value": "C72906"},
            {"label": "Xacaxomulco", "value": "C72907"},
            {"label": "Xalacapan de Lucke", "value": "C72908"},
            {"label": "Xalitzintla", "value": "C72909"},
            {"label": "Xaltepec", "value": "C72910"},
            {"label": "Xaltepuxtla", "value": "C72911"},
            {"label": "Xalticpac", "value": "C72912"},
            {"label": "Xayacatlan de Bravo", "value": "C72913"},
            {"label": "Xicotepec", "value": "C72914"},
            {"label": "Xicotepec de Juarez", "value": "C72915"},
            {"label": "Xicotlan", "value": "C72916"},
            {"label": "Xilocuautla", "value": "C72917"},
            {"label": "Xiloxochico de Rafael avila Camacho", "value": "C72918"},
            {"label": "Xitlama", "value": "C72919"},
            {"label": "Xiutetelco", "value": "C72920"},
            {"label": "Xochiapulco", "value": "C72921"},
            {"label": "Xochicuautla", "value": "C72922"},
            {"label": "Xochiltepec", "value": "C72923"},
            {"label": "Xochimilco", "value": "C72924"},
            {"label": "Xochinanacatlan", "value": "C72925"},
            {"label": "Xochitepec", "value": "C72926"},
            {"label": "Xochitlan de Vicente Suarez", "value": "C72927"},
            {"label": "Xochitlan Todos Santos", "value": "C72928"},
            {"label": "Xochitlaxco (San Baltazar)", "value": "C72929"},
            {"label": "Xocotepec", "value": "C72930"},
            {"label": "Xoloateno", "value": "C72931"},
            {"label": "Xolotla", "value": "C72932"},
            {"label": "Xonalpu", "value": "C72933"},
            {"label": "Xonocuautla", "value": "C72934"},
            {"label": "Xoxonacatla", "value": "C72935"},
            {"label": "Xuchapa", "value": "C72936"},
            {"label": "Yaonahuac", "value": "C72937"},
            {"label": "Yehualtepec", "value": "C72938"},
            {"label": "Yopi", "value": "C72939"},
            {"label": "Zacaola", "value": "C72940"},
            {"label": "Zacapala", "value": "C72941"},
            {"label": "Zacapechpan", "value": "C72942"},
            {"label": "Zacapoaxtla", "value": "C72943"},
            {"label": "Zacatepec", "value": "C72944"},
            {"label": "Zacatlan", "value": "C72945"},
            {"label": "Zahuatlan de Morelos (San Jose)", "value": "C72946"},
            {"label": "Zapotitlan", "value": "C72947"},
            {"label": "Zaragoza", "value": "C72948"},
            {"label": "Zautla", "value": "C72949"},
            {"label": "Zempoala", "value": "C72950"},
            {"label": "Zihuateutla", "value": "C72951"},
            {"label": "Zinacatepec", "value": "C72952"},
            {"label": "Zoatecpan", "value": "C72953"},
            {"label": "Zolonquiapa", "value": "C72954"},
            {"label": "Zongozotla", "value": "C72955"},
            {"label": "Zoquiapan", "value": "C72956"},
            {"label": "Zoquiopan", "value": "C72957"},
            {"label": "Zoquitlan", "value": "C72958"},
            {"label": "Zoyatitla", "value": "C72959"},
            {"label": "Zoyotla", "value": "C72960"},
            {"label": "Zozocolco de Guerrero", "value": "C72961"}],
 "Puerto Plata Province": [{"label": "Altamira", "value": "C23971"},
                           {"label": "Cabarete", "value": "C23972"},
                           {"label": "Estero Hondo", "value": "C23973"},
                           {"label": "Guananico", "value": "C23974"},
                           {"label": "Imbert", "value": "C23975"},
                           {"label": "Los Hidalgos", "value": "C23976"},
                           {"label": "Luperon", "value": "C23977"},
                           {"label": "Monte Llano", "value": "C23978"},
                           {"label": "Puerto Plata", "value": "C23979"},
                           {"label": "Rio Grande", "value": "C23980"},
                           {"label": "Sosua", "value": "C23981"},
                           {"label": "Villa Isabela", "value": "C23982"}],
 "Puerto Rico": [{"label": "Adjuntas", "value": "C142376"},
                 {"label": "Aguada", "value": "C142377"},
                 {"label": "Aguadilla", "value": "C142378"},
                 {"label": "Aguas Buenas", "value": "C142379"},
                 {"label": "Aguas Claras", "value": "C142380"},
                 {"label": "Aguilita", "value": "C142381"},
                 {"label": "Aibonito", "value": "C142382"},
                 {"label": "Anasco", "value": "C142383"},
                 {"label": "Animas", "value": "C142384"},
                 {"label": "Anton Ruiz", "value": "C142385"},
                 {"label": "Arecibo", "value": "C142386"},
                 {"label": "Arroyo", "value": "C142387"},
                 {"label": "Bairoa", "value": "C142388"},
                 {"label": "Bajadero", "value": "C142389"},
                 {"label": "Bajandas", "value": "C142390"},
                 {"label": "Barahona", "value": "C142391"},
                 {"label": "Barceloneta", "value": "C142392"},
                 {"label": "Barranquitas", "value": "C142393"},
                 {"label": "Bartolo", "value": "C142394"},
                 {"label": "Bayamon", "value": "C142395"},
                 {"label": "Benitez", "value": "C142396"},
                 {"label": "Betances", "value": "C142397"},
                 {"label": "Boqueron", "value": "C142398"},
                 {"label": "Boqueron, Cabo Rojo", "value": "C142399"},
                 {"label": "Brenas", "value": "C142400"},
                 {"label": "Buena Vista", "value": "C142401"},
                 {"label": "Bufalo", "value": "C142402"},
                 {"label": "Caban", "value": "C142403"},
                 {"label": "Cabo Rojo", "value": "C142404"},
                 {"label": "Cabo Rojo Municipio", "value": "C142405"},
                 {"label": "Cacao", "value": "C142406"},
                 {"label": "Caguas", "value": "C142407"},
                 {"label": "Campanilla", "value": "C142408"},
                 {"label": "Campo Rico", "value": "C142409"},
                 {"label": "Camuy", "value": "C142410"},
                 {"label": "Candelaria", "value": "C142411"},
                 {"label": "Candelaria Arenas", "value": "C142412"},
                 {"label": "Candelero Arriba", "value": "C142413"},
                 {"label": "Canovanas", "value": "C142414"},
                 {"label": "Capitanejo", "value": "C142415"},
                 {"label": "Carolina", "value": "C142416"},
                 {"label": "Carrizales", "value": "C142417"},
                 {"label": "Catano", "value": "C142418"},
                 {"label": "Cayey", "value": "C142419"},
                 {"label": "Cayuco", "value": "C142420"},
                 {"label": "Ceiba", "value": "C142421"},
                 {"label": "Ceiba Municipio", "value": "C142422"},
                 {"label": "Celada", "value": "C142423"},
                 {"label": "Central Aguirre", "value": "C142424"},
                 {"label": "Ciales", "value": "C142425"},
                 {"label": "Ciales Municipio", "value": "C142426"},
                 {"label": "Cidra", "value": "C142427"},
                 {"label": "Coamo", "value": "C142428"},
                 {"label": "Coco", "value": "C142429"},
                 {"label": "Comerio", "value": "C142430"},
                 {"label": "Comerio Municipio", "value": "C142431"},
                 {"label": "Comunas", "value": "C142432"},
                 {"label": "Coqui", "value": "C142433"},
                 {"label": "Corazon", "value": "C142434"},
                 {"label": "Corcovado", "value": "C142435"},
                 {"label": "Corozal", "value": "C142436"},
                 {"label": "Corozal Municipio", "value": "C142437"},
                 {"label": "Coto Laurel", "value": "C142438"},
                 {"label": "Coto Norte", "value": "C142439"},
                 {"label": "Culebra", "value": "C142440"},
                 {"label": "Culebra barrio-pueblo", "value": "C142441"},
                 {"label": "Daguao", "value": "C142442"},
                 {"label": "Dorado", "value": "C142443"},
                 {"label": "Dorado Municipio", "value": "C142444"},
                 {"label": "El Mango", "value": "C142445"},
                 {"label": "El Negro", "value": "C142446"},
                 {"label": "El Ojo", "value": "C142447"},
                 {"label": "Emajagua", "value": "C142448"},
                 {"label": "Esperanza", "value": "C142449"},
                 {"label": "Espino", "value": "C142450"},
                 {"label": "Estancias de Florida", "value": "C142451"},
                 {"label": "Fajardo", "value": "C142452"},
                 {"label": "Fajardo Municipio", "value": "C142453"},
                 {"label": "Florida", "value": "C142454"},
                 {"label": "Franquez", "value": "C142455"},
                 {"label": "Fuig", "value": "C142456"},
                 {"label": "G. L. Garcia", "value": "C142457"},
                 {"label": "Galateo", "value": "C142458"},
                 {"label": "Garrochales", "value": "C142459"},
                 {"label": "Guanica", "value": "C142460"},
                 {"label": "Guanica Municipio", "value": "C142461"},
                 {"label": "Guayabal", "value": "C142462"},
                 {"label": "Guayama", "value": "C142463"},
                 {"label": "Guayama Municipio", "value": "C142464"},
                 {"label": "Guayanilla", "value": "C142465"},
                 {"label": "Guaynabo", "value": "C142466"},
                 {"label": "Gurabo", "value": "C142467"},
                 {"label": "Gurabo Municipio", "value": "C142468"},
                 {"label": "H. Rivera Colon", "value": "C142469"},
                 {"label": "Hatillo", "value": "C142470"},
                 {"label": "Hatillo Municipio", "value": "C142471"},
                 {"label": "Hato Arriba", "value": "C142472"},
                 {"label": "Hato Candal", "value": "C142473"},
                 {"label": "Hormigueros", "value": "C142474"},
                 {"label": "Hormigueros Municipio", "value": "C142475"},
                 {"label": "Humacao", "value": "C142476"},
                 {"label": "Imbery", "value": "C142477"},
                 {"label": "Indios", "value": "C142478"},
                 {"label": "Ingenio", "value": "C142479"},
                 {"label": "Isabel Segunda", "value": "C142480"},
                 {"label": "Isabela", "value": "C142481"},
                 {"label": "Jagual", "value": "C142482"},
                 {"label": "Jauca", "value": "C142483"},
                 {"label": "Jayuya", "value": "C142484"},
                 {"label": "Jobos", "value": "C142485"},
                 {"label": "Juana Diaz", "value": "C142486"},
                 {"label": "Juana Diaz Municipio", "value": "C142487"},
                 {"label": "Juncal", "value": "C142488"},
                 {"label": "Juncos", "value": "C142489"},
                 {"label": "La Alianza", "value": "C142490"},
                 {"label": "La Dolores", "value": "C142491"},
                 {"label": "La Fermina", "value": "C142492"},
                 {"label": "La Luisa", "value": "C142493"},
                 {"label": "La Parguera", "value": "C142494"},
                 {"label": "La Playa", "value": "C142495"},
                 {"label": "La Plena", "value": "C142496"},
                 {"label": "Lajas", "value": "C142497"},
                 {"label": "Lajas Municipio", "value": "C142498"},
                 {"label": "Lamboglia", "value": "C142499"},
                 {"label": "Lares", "value": "C142500"},
                 {"label": "Las Marias", "value": "C142501"},
                 {"label": "Las Marias Municipio", "value": "C142502"},
                 {"label": "Las Ochenta", "value": "C142503"},
                 {"label": "Las Ollas", "value": "C142504"},
                 {"label": "Las Piedras", "value": "C142505"},
                 {"label": "Las Piedras Municipio", "value": "C142506"},
                 {"label": "Levittown", "value": "C142507"},
                 {"label": "Liborio Negron Torres", "value": "C142508"},
                 {"label": "Lluveras", "value": "C142509"},
                 {"label": "Loiza", "value": "C142510"},
                 {"label": "Lomas", "value": "C142511"},
                 {"label": "Los Llanos", "value": "C142512"},
                 {"label": "Luis Llorens Torres", "value": "C142513"},
                 {"label": "Luis M. Cintron", "value": "C142514"},
                 {"label": "Luquillo", "value": "C142515"},
                 {"label": "Luquillo Municipio", "value": "C142516"},
                 {"label": "Luyando", "value": "C142517"},
                 {"label": "Magas Arriba", "value": "C142518"},
                 {"label": "Manati", "value": "C142519"},
                 {"label": "Maria Antonia", "value": "C142520"},
                 {"label": "Mariano Colon", "value": "C142521"},
                 {"label": "Maricao", "value": "C142522"},
                 {"label": "Maricao Municipio", "value": "C142523"},
                 {"label": "Martorell", "value": "C142524"},
                 {"label": "Marueno", "value": "C142525"},
                 {"label": "Maunabo", "value": "C142526"},
                 {"label": "Maunabo Municipio", "value": "C142527"},
                 {"label": "Mayaguez", "value": "C142528"},
                 {"label": "Miranda", "value": "C142529"},
                 {"label": "Moca", "value": "C142530"},
                 {"label": "Moca Municipio", "value": "C142531"},
                 {"label": "Monserrate", "value": "C142532"},
                 {"label": "Monte Grande", "value": "C142533"},
                 {"label": "Mora", "value": "C142534"},
                 {"label": "Morovis", "value": "C142535"},
                 {"label": "Mucarabones", "value": "C142536"},
                 {"label": "Naguabo", "value": "C142537"},
                 {"label": "Naguabo Municipio", "value": "C142538"},
                 {"label": "Naranjito", "value": "C142539"},
                 {"label": "Naranjito Municipio", "value": "C142540"},
                 {"label": "Olimpo", "value": "C142541"},
                 {"label": "Orocovis", "value": "C142542"},
                 {"label": "Orocovis Municipio", "value": "C142543"},
                 {"label": "Pajaros", "value": "C142544"},
                 {"label": "Pajonal", "value": "C142545"},
                 {"label": "Palmarejo", "value": "C142546"},
                 {"label": "Palmas", "value": "C142547"},
                 {"label": "Palmer", "value": "C142548"},
                 {"label": "Palo Seco", "value": "C142549"},
                 {"label": "Palomas", "value": "C142550"},
                 {"label": "Parcelas La Milagrosa", "value": "C142551"},
                 {"label": "Parcelas Nuevas", "value": "C142552"},
                 {"label": "Parcelas Penuelas", "value": "C142553"},
                 {"label": "Pastos", "value": "C142554"},
                 {"label": "Patillas", "value": "C142555"},
                 {"label": "Patillas Municipio", "value": "C142556"},
                 {"label": "Pena Pobre", "value": "C142557"},
                 {"label": "Penuelas", "value": "C142558"},
                 {"label": "Piedra Gorda", "value": "C142559"},
                 {"label": "Playa Fortuna", "value": "C142560"},
                 {"label": "Playita", "value": "C142561"},
                 {"label": "Playita Cortada", "value": "C142562"},
                 {"label": "Pole Ojea", "value": "C142563"},
                 {"label": "Ponce", "value": "C142564"},
                 {"label": "Potala Pastillo", "value": "C142565"},
                 {"label": "Pueblito del Rio", "value": "C142566"},
                 {"label": "Puerto Real", "value": "C142567"},
                 {"label": "Punta Santiago", "value": "C142568"},
                 {"label": "Quebrada", "value": "C142569"},
                 {"label": "Quebradillas", "value": "C142570"},
                 {"label": "Quebradillas Municipio", "value": "C142571"},
                 {"label": "Rafael Capo", "value": "C142572"},
                 {"label": "Rafael Gonzalez", "value": "C142573"},
                 {"label": "Rafael Hernandez", "value": "C142574"},
                 {"label": "Ramos", "value": "C142575"},
                 {"label": "Rincon", "value": "C142576"},
                 {"label": "Rincon Municipio", "value": "C142577"},
                 {"label": "Rio Blanco", "value": "C142578"},
                 {"label": "Rio Canas Abajo", "value": "C142579"},
                 {"label": "Rio Grande", "value": "C142580"},
                 {"label": "Rio Grande Municipio", "value": "C142581"},
                 {"label": "Rio Lajas", "value": "C142582"},
                 {"label": "Rio Piedras", "value": "C142583"},
                 {"label": "Rosa Sanchez", "value": "C142584"},
                 {"label": "Sabana", "value": "C142585"},
                 {"label": "Sabana Eneas", "value": "C142586"},
                 {"label": "Sabana Grande", "value": "C142587"},
                 {"label": "Sabana Grande Municipio", "value": "C142588"},
                 {"label": "Sabana Hoyos", "value": "C142589"},
                 {"label": "Sabana Seca", "value": "C142590"},
                 {"label": "Salinas", "value": "C142591"},
                 {"label": "San Antonio", "value": "C142592"},
                 {"label": "San German Municipio", "value": "C142593"},
                 {"label": "San Isidro", "value": "C142594"},
                 {"label": "San Jose", "value": "C142595"},
                 {"label": "San Juan", "value": "C142596"},
                 {"label": "San Lorenzo", "value": "C142597"},
                 {"label": "San Sebastian", "value": "C142598"},
                 {"label": "Santa Barbara", "value": "C142599"},
                 {"label": "Santa Clara", "value": "C142600"},
                 {"label": "Santa Isabel", "value": "C142601"},
                 {"label": "Santa Isabel Municipio", "value": "C142602"},
                 {"label": "Santo Domingo", "value": "C142603"},
                 {"label": "Santurce", "value": "C142604"},
                 {"label": "Stella", "value": "C142605"},
                 {"label": "Suarez", "value": "C142606"},
                 {"label": "Sumidero", "value": "C142607"},
                 {"label": "Tallaboa", "value": "C142608"},
                 {"label": "Tallaboa Alta", "value": "C142609"},
                 {"label": "Tiburones", "value": "C142610"},
                 {"label": "Tierras Nuevas Poniente", "value": "C142611"},
                 {"label": "Toa Alta", "value": "C142612"},
                 {"label": "Toa Alta Municipio", "value": "C142613"},
                 {"label": "Toa Baja", "value": "C142614"},
                 {"label": "Trujillo Alto", "value": "C142615"},
                 {"label": "Trujillo Alto Municipio", "value": "C142616"},
                 {"label": "Utuado", "value": "C142617"},
                 {"label": "Utuado barrio-pueblo", "value": "C142618"},
                 {"label": "Vazquez", "value": "C142619"},
                 {"label": "Vega Alta", "value": "C142620"},
                 {"label": "Vega Alta Municipio", "value": "C142621"},
                 {"label": "Vega Baja", "value": "C142622"},
                 {"label": "Vieques", "value": "C142623"},
                 {"label": "Vieques Municipality", "value": "C142624"},
                 {"label": "Villalba", "value": "C142625"},
                 {"label": "Villalba Municipio", "value": "C142626"},
                 {"label": "Yabucoa", "value": "C142627"},
                 {"label": "Yabucoa Municipio", "value": "C142628"},
                 {"label": "Yauco", "value": "C142629"},
                 {"label": "Yaurel", "value": "C142630"}],
 "Punakha District": [{"label": "Pajo", "value": "C9642"},
                      {"label": "Punakha", "value": "C9643"}],
 "Punjab": [{"label": "Abohar", "value": "C47427"},
            {"label": "Adampur", "value": "C47428"},
            {"label": "Ajitgarh", "value": "C47429"},
            {"label": "Ajnala", "value": "C47430"},
            {"label": "Akalgarh", "value": "C47431"},
            {"label": "Alawalpur", "value": "C47432"},
            {"label": "Amloh", "value": "C47433"},
            {"label": "Amritsar", "value": "C47434"},
            {"label": "Anandpur Sahib", "value": "C47435"},
            {"label": "Badhni Kalan", "value": "C47436"},
            {"label": "Bagha Purana", "value": "C47437"},
            {"label": "Bakloh", "value": "C47438"},
            {"label": "Balachor", "value": "C47439"},
            {"label": "Banga", "value": "C47440"},
            {"label": "Banur", "value": "C47441"},
            {"label": "Barnala", "value": "C47442"},
            {"label": "Batala", "value": "C47443"},
            {"label": "Begowal", "value": "C47444"},
            {"label": "Bhadaur", "value": "C47445"},
            {"label": "Bhatinda", "value": "C47446"},
            {"label": "Bhawanigarh", "value": "C47447"},
            {"label": "Bhikhi", "value": "C47448"},
            {"label": "Bhogpur", "value": "C47449"},
            {"label": "Bholath", "value": "C47450"},
            {"label": "Budhlada", "value": "C47451"},
            {"label": "Chima", "value": "C47452"},
            {"label": "Dasuya", "value": "C47453"},
            {"label": "Dera Baba Nanak", "value": "C47454"},
            {"label": "Dera Bassi", "value": "C47455"},
            {"label": "Dhanaula", "value": "C47456"},
            {"label": "Dhariwal", "value": "C47457"},
            {"label": "Dhilwan", "value": "C47458"},
            {"label": "Dhudi", "value": "C47459"},
            {"label": "Dhuri", "value": "C47460"},
            {"label": "Dina Nagar", "value": "C47461"},
            {"label": "Dirba", "value": "C47462"},
            {"label": "Doraha", "value": "C47463"},
            {"label": "Faridkot", "value": "C47464"},
            {"label": "Fatehgarh Churian", "value": "C47465"},
            {"label": "Fatehgarh Sahib", "value": "C47466"},
            {"label": "Fazilka", "value": "C47467"},
            {"label": "Firozpur", "value": "C47468"},
            {"label": "Firozpur District", "value": "C47469"},
            {"label": "Gardhiwala", "value": "C47470"},
            {"label": "Garhshankar", "value": "C47471"},
            {"label": "Ghanaur", "value": "C47472"},
            {"label": "Giddarbaha", "value": "C47473"},
            {"label": "Gurdaspur", "value": "C47474"},
            {"label": "Guru Har Sahai", "value": "C47475"},
            {"label": "Hajipur", "value": "C47476"},
            {"label": "Hariana", "value": "C47477"},
            {"label": "Hoshiarpur", "value": "C47478"},
            {"label": "Ishanpur", "value": "C47479"},
            {"label": "Jagraon", "value": "C47480"},
            {"label": "Jaito", "value": "C47481"},
            {"label": "Jalalabad", "value": "C47482"},
            {"label": "Jalandhar", "value": "C47483"},
            {"label": "Jandiala", "value": "C47484"},
            {"label": "Jandiala Guru", "value": "C47485"},
            {"label": "Kalanaur", "value": "C47486"},
            {"label": "Kapurthala", "value": "C47487"},
            {"label": "Kartarpur", "value": "C47488"},
            {"label": "Khamanon", "value": "C47489"},
            {"label": "Khanna", "value": "C47490"},
            {"label": "Kharar", "value": "C47491"},
            {"label": "Khemkaran", "value": "C47492"},
            {"label": "Kot Isa Khan", "value": "C47493"},
            {"label": "Kotkapura", "value": "C47494"},
            {"label": "Laungowal", "value": "C47495"},
            {"label": "Ludhiana", "value": "C47496"},
            {"label": "Machhiwara", "value": "C47497"},
            {"label": "Majitha", "value": "C47498"},
            {"label": "Makhu", "value": "C47499"},
            {"label": "Malaut", "value": "C47500"},
            {"label": "Malerkotla", "value": "C47501"},
            {"label": "Mansa", "value": "C47502"},
            {"label": "Maur Mandi", "value": "C47503"},
            {"label": "Moga", "value": "C47504"},
            {"label": "Mohali", "value": "C47505"},
            {"label": "Morinda", "value": "C47506"},
            {"label": "Mukerian", "value": "C47507"},
            {"label": "Nabha", "value": "C47508"},
            {"label": "Nakodar", "value": "C47509"},
            {"label": "Nangal", "value": "C47510"},
            {"label": "Nawanshahr", "value": "C47511"},
            {"label": "Nurmahal", "value": "C47512"},
            {"label": "Nurpur Kalan", "value": "C47513"},
            {"label": "Pathankot", "value": "C47514"},
            {"label": "Patiala", "value": "C47515"},
            {"label": "Patti", "value": "C47516"},
            {"label": "Phagwara", "value": "C47517"},
            {"label": "Phillaur", "value": "C47518"},
            {"label": "Qadian", "value": "C47519"},
            {"label": "Rahon", "value": "C47520"},
            {"label": "Raikot", "value": "C47521"},
            {"label": "Rajasansi", "value": "C47522"},
            {"label": "Rajpura", "value": "C47523"},
            {"label": "Ram Das", "value": "C47524"},
            {"label": "Rampura", "value": "C47525"},
            {"label": "Rupnagar", "value": "C47526"},
            {"label": "Samrala", "value": "C47527"},
            {"label": "Sanaur", "value": "C47528"},
            {"label": "Sangrur", "value": "C47529"},
            {"label": "Sardulgarh", "value": "C47530"},
            {"label": "Shahid Bhagat Singh Nagar", "value": "C47531"},
            {"label": "Shahkot", "value": "C47532"},
            {"label": "Sham Churasi", "value": "C47533"},
            {"label": "Sirhind-Fategarh", "value": "C47534"},
            {"label": "Sri Muktsar Sahib", "value": "C47535"},
            {"label": "Sultanpur Lodhi", "value": "C47536"},
            {"label": "Sunam", "value": "C47537"},
            {"label": "Talwandi Bhai", "value": "C47538"},
            {"label": "Talwara", "value": "C47539"},
            {"label": "Tarn Taran Sahib", "value": "C47540"},
            {"label": "Zira", "value": "C47541"},
            {"label": "Ahmadpur Sial", "value": "C79437"},
            {"label": "Ahmedpur East", "value": "C79438"},
            {"label": "Alipur Chatha", "value": "C79439"},
            {"label": "Arifwala", "value": "C79440"},
            {"label": "Attock Tehsil", "value": "C79441"},
            {"label": "Baddomalhi", "value": "C79442"},
            {"label": "Bahawalnagar", "value": "C79443"},
            {"label": "Bahawalpur", "value": "C79444"},
            {"label": "Bakhri Ahmad Khan", "value": "C79445"},
            {"label": "Basirpur", "value": "C79446"},
            {"label": "Basti Dosa", "value": "C79447"},
            {"label": "Begowala", "value": "C79448"},
            {"label": "Bhakkar", "value": "C79449"},
            {"label": "Bhalwal", "value": "C79450"},
            {"label": "Bhawana", "value": "C79451"},
            {"label": "Bhera", "value": "C79452"},
            {"label": "Bhopalwala", "value": "C79453"},
            {"label": "Burewala", "value": "C79454"},
            {"label": "Chak Azam Saffo", "value": "C79455"},
            {"label": "Chak Jhumra", "value": "C79456"},
            {"label": "Chak One Hundred Twenty Nine Left", "value": "C79457"},
            {"label": "Chak Thirty-one -Eleven Left", "value": "C79458"},
            {"label": "Chak Two Hundred Forty-Nine TDA", "value": "C79459"},
            {"label": "Chakwal", "value": "C79460"},
            {"label": "Chawinda", "value": "C79461"},
            {"label": "Chichawatni", "value": "C79462"},
            {"label": "Chiniot", "value": "C79463"},
            {"label": "Chishtian", "value": "C79464"},
            {"label": "Choa Saidanshah", "value": "C79465"},
            {"label": "Chuhar Kana", "value": "C79466"},
            {"label": "Chunian", "value": "C79467"},
            {"label": "Daira Din Panah", "value": "C79468"},
            {"label": "Dajal", "value": "C79469"},
            {"label": "Dandot RS", "value": "C79470"},
            {"label": "Darya Khan", "value": "C79471"},
            {"label": "Daska", "value": "C79472"},
            {"label": "Daud Khel", "value": "C79473"},
            {"label": "Daultala", "value": "C79474"},
            {"label": "Dera Ghazi Khan", "value": "C79475"},
            {"label": "Dhanot", "value": "C79476"},
            {"label": "Dhaunkal", "value": "C79477"},
            {"label": "Dhok Awan", "value": "C79478"},
            {"label": "Dijkot", "value": "C79479"},
            {"label": "Dinan Bashnoian Wala", "value": "C79480"},
            {"label": "Dinga", "value": "C79481"},
            {"label": "Dipalpur", "value": "C79482"},
            {"label": "Dullewala", "value": "C79483"},
            {"label": "Dunga Bunga", "value": "C79484"},
            {"label": "Dunyapur", "value": "C79485"},
            {"label": "Eminabad", "value": "C79486"},
            {"label": "Faisalabad", "value": "C79487"},
            {"label": "Faqirwali", "value": "C79488"},
            {"label": "Faruka", "value": "C79489"},
            {"label": "Fazilpur", "value": "C79490"},
            {"label": "Ferozewala", "value": "C79491"},
            {"label": "Fort Abbas", "value": "C79492"},
            {"label": "Garh Maharaja", "value": "C79493"},
            {"label": "Gojra", "value": "C79494"},
            {"label": "Gujar Khan", "value": "C79495"},
            {"label": "Gujranwala", "value": "C79496"},
            {"label": "Gujranwala Division", "value": "C79497"},
            {"label": "Gujrat", "value": "C79498"},
            {"label": "Hadali", "value": "C79499"},
            {"label": "Hafizabad", "value": "C79500"},
            {"label": "Harnoli", "value": "C79501"},
            {"label": "Harunabad", "value": "C79502"},
            {"label": "Hasan Abdal", "value": "C79503"},
            {"label": "Hasilpur", "value": "C79504"},
            {"label": "Haveli Lakha", "value": "C79505"},
            {"label": "Hazro", "value": "C79506"},
            {"label": "Hujra Shah Muqeem", "value": "C79507"},
            {"label": "Jahanian Shah", "value": "C79508"},
            {"label": "Jalalpur Jattan", "value": "C79509"},
            {"label": "Jalalpur Pirwala", "value": "C79510"},
            {"label": "Jampur", "value": "C79511"},
            {"label": "Jand", "value": "C79512"},
            {"label": "Jandiala Sher Khan", "value": "C79513"},
            {"label": "Jaranwala", "value": "C79514"},
            {"label": "Jatoi Shimali", "value": "C79515"},
            {"label": "Jauharabad", "value": "C79516"},
            {"label": "Jhang", "value": "C79517"},
            {"label": "Jhang Sadar", "value": "C79518"},
            {"label": "Jhawarian", "value": "C79519"},
            {"label": "Jhelum", "value": "C79520"},
            {"label": "Kabirwala", "value": "C79521"},
            {"label": "Kahna Nau", "value": "C79522"},
            {"label": "Kahuta", "value": "C79523"},
            {"label": "Kalabagh", "value": "C79524"},
            {"label": "Kalaswala", "value": "C79525"},
            {"label": "Kaleke Mandi", "value": "C79526"},
            {"label": "Kallar Kahar", "value": "C79527"},
            {"label": "Kalur Kot", "value": "C79528"},
            {"label": "Kamalia", "value": "C79529"},
            {"label": "Kamar Mushani", "value": "C79530"},
            {"label": "Kamoke", "value": "C79531"},
            {"label": "Kamra", "value": "C79532"},
            {"label": "Kanganpur", "value": "C79533"},
            {"label": "Karor", "value": "C79534"},
            {"label": "Kasur", "value": "C79535"},
            {"label": "Keshupur", "value": "C79536"},
            {"label": "Khairpur Tamiwali", "value": "C79537"},
            {"label": "Khandowa", "value": "C79538"},
            {"label": "Khanewal", "value": "C79539"},
            {"label": "Khanga Dogran", "value": "C79540"},
            {"label": "Khangarh", "value": "C79541"},
            {"label": "Khanpur", "value": "C79542"},
            {"label": "Kharian", "value": "C79543"},
            {"label": "Khewra", "value": "C79544"},
            {"label": "Khurrianwala", "value": "C79545"},
            {"label": "Khushab", "value": "C79546"},
            {"label": "Kohror Pakka", "value": "C79547"},
            {"label": "Kot Addu Tehsil", "value": "C79548"},
            {"label": "Kot Ghulam Muhammad", "value": "C79549"},
            {"label": "Kot Mumin", "value": "C79550"},
            {"label": "Kot Radha Kishan", "value": "C79551"},
            {"label": "Kot Rajkour", "value": "C79552"},
            {"label": "Kot Samaba", "value": "C79553"},
            {"label": "Kot Sultan", "value": "C79554"},
            {"label": "Kotli Loharan", "value": "C79555"},
            {"label": "Kundian", "value": "C79556"},
            {"label": "Kunjah", "value": "C79557"},
            {"label": "Ladhewala Waraich", "value": "C79558"},
            {"label": "Lahore", "value": "C79559"},
            {"label": "Lala Musa", "value": "C79560"},
            {"label": "Lalian", "value": "C79561"},
            {"label": "Layyah", "value": "C79562"},
            {"label": "Layyah District", "value": "C79563"},
            {"label": "Liliani", "value": "C79564"},
            {"label": "Lodhran", "value": "C79565"},
            {"label": "Mailsi", "value": "C79566"},
            {"label": "Malakwal", "value": "C79567"},
            {"label": "Malakwal City", "value": "C79568"},
            {"label": "Mamu Kanjan", "value": "C79569"},
            {"label": "Mananwala", "value": "C79570"},
            {"label": "Mandi Bahauddin", "value": "C79571"},
            {"label": "Mandi Bahauddin District", "value": "C79572"},
            {"label": "Mangla", "value": "C79573"},
            {"label": "Mankera", "value": "C79574"},
            {"label": "Mehmand Chak", "value": "C79575"},
            {"label": "Mian Channun", "value": "C79576"},
            {"label": "Mianke Mor", "value": "C79577"},
            {"label": "Mianwali", "value": "C79578"},
            {"label": "Minchinabad", "value": "C79579"},
            {"label": "Mitha Tiwana", "value": "C79580"},
            {"label": "Moza Shahwala", "value": "C79581"},
            {"label": "Multan", "value": "C79582"},
            {"label": "Multan District", "value": "C79583"},
            {"label": "Muridke", "value": "C79584"},
            {"label": "Murree", "value": "C79585"},
            {"label": "Mustafabad", "value": "C79586"},
            {"label": "Muzaffargarh", "value": "C79587"},
            {"label": "Nankana Sahib", "value": "C79588"},
            {"label": "Narang Mandi", "value": "C79589"},
            {"label": "Narowal", "value": "C79590"},
            {"label": "Naushahra Virkan", "value": "C79591"},
            {"label": "Nazir Town", "value": "C79592"},
            {"label": "Okara", "value": "C79593"},
            {"label": "Pakki Shagwanwali", "value": "C79594"},
            {"label": "Pakpattan", "value": "C79595"},
            {"label": "Pasrur", "value": "C79596"},
            {"label": "Pattoki", "value": "C79597"},
            {"label": "Phalia", "value": "C79598"},
            {"label": "Pind Dadan Khan", "value": "C79599"},
            {"label": "Pindi Bhattian", "value": "C79600"},
            {"label": "Pindi Gheb", "value": "C79601"},
            {"label": "Pir Mahal", "value": "C79602"},
            {"label": "Qadirpur Ran", "value": "C79603"},
            {"label": "Qila Didar Singh", "value": "C79604"},
            {"label": "Rabwah", "value": "C79605"},
            {"label": "Rahim Yar Khan", "value": "C79606"},
            {"label": "Rahimyar Khan District", "value": "C79607"},
            {"label": "Raiwind", "value": "C79608"},
            {"label": "Raja Jang", "value": "C79609"},
            {"label": "Rajanpur", "value": "C79610"},
            {"label": "Rasulnagar", "value": "C79611"},
            {"label": "Rawalpindi", "value": "C79612"},
            {"label": "Rawalpindi District", "value": "C79613"},
            {"label": "Renala Khurd", "value": "C79614"},
            {"label": "Rojhan", "value": "C79615"},
            {"label": "Sadiqabad", "value": "C79616"},
            {"label": "Sahiwal", "value": "C79617"},
            {"label": "Sambrial", "value": "C79618"},
            {"label": "Sangla Hill", "value": "C79619"},
            {"label": "Sanjwal", "value": "C79620"},
            {"label": "Sarai Alamgir", "value": "C79621"},
            {"label": "Sarai Sidhu", "value": "C79622"},
            {"label": "Sargodha", "value": "C79623"},
            {"label": "Shahkot Tehsil", "value": "C79624"},
            {"label": "Shahpur", "value": "C79625"},
            {"label": "Shahr Sultan", "value": "C79626"},
            {"label": "Shakargarh", "value": "C79627"},
            {"label": "Sharqpur", "value": "C79628"},
            {"label": "Sheikhupura", "value": "C79629"},
            {"label": "Shorkot", "value": "C79630"},
            {"label": "Shujaabad", "value": "C79631"},
            {"label": "Sialkot", "value": "C79632"},
            {"label": "Sillanwali", "value": "C79633"},
            {"label": "Sodhra", "value": "C79634"},
            {"label": "Sukheke Mandi", "value": "C79635"},
            {"label": "Surkhpur", "value": "C79636"},
            {"label": "Talagang", "value": "C79637"},
            {"label": "Talamba", "value": "C79638"},
            {"label": "Tandlianwala", "value": "C79639"},
            {"label": "Taunsa", "value": "C79640"},
            {"label": "Toba Tek Singh", "value": "C79641"},
            {"label": "Umerkot", "value": "C79642"},
            {"label": "Vihari", "value": "C79643"},
            {"label": "Wah", "value": "C79644"},
            {"label": "Warburton", "value": "C79645"},
            {"label": "Wazirabad", "value": "C79646"},
            {"label": "West Punjab", "value": "C79647"},
            {"label": "Yazman", "value": "C79648"},
            {"label": "Zafarwal", "value": "C79649"},
            {"label": "Zahir Pir", "value": "C79650"}],
 "Puno": [{"label": "Atuncolla", "value": "C81033"},
          {"label": "Ayaviri", "value": "C81034"},
          {"label": "Azangaro", "value": "C81035"},
          {"label": "Desaguadero", "value": "C81036"},
          {"label": "El Collao", "value": "C81037"},
          {"label": "Hacienda Huancane", "value": "C81038"},
          {"label": "Ilave", "value": "C81039"},
          {"label": "Juli", "value": "C81040"},
          {"label": "Juliaca", "value": "C81041"},
          {"label": "La Rinconada", "value": "C81042"},
          {"label": "Lampa", "value": "C81043"},
          {"label": "Macusani", "value": "C81044"},
          {"label": "nunoa", "value": "C81045"},
          {"label": "Provincia de Azangaro", "value": "C81046"},
          {"label": "Provincia de Carabaya", "value": "C81047"},
          {"label": "Provincia de Chucuito", "value": "C81048"},
          {"label": "Provincia de Huancane", "value": "C81049"},
          {"label": "Provincia de Lampa", "value": "C81050"},
          {"label": "Provincia de Melgar", "value": "C81051"},
          {"label": "Provincia de Puno", "value": "C81052"},
          {"label": "Provincia de Sandia", "value": "C81053"},
          {"label": "Puno", "value": "C81054"},
          {"label": "San Antonio De Putina", "value": "C81055"},
          {"label": "San Roman", "value": "C81056"},
          {"label": "Santa Lucia", "value": "C81057"},
          {"label": "Taquile", "value": "C81058"},
          {"label": "Yunguyo", "value": "C81059"}],
 "Puntarenas Province": [{"label": "Buenos Aires", "value": "C20854"},
                         {"label": "Canoas", "value": "C20855"},
                         {"label": "Chacarita", "value": "C20856"},
                         {"label": "Ciudad Cortes", "value": "C20857"},
                         {"label": "Corredor", "value": "C20858"},
                         {"label": "Corredores", "value": "C20859"},
                         {"label": "Coto Brus", "value": "C20860"},
                         {"label": "Esparza", "value": "C20861"},
                         {"label": "Garabito", "value": "C20862"},
                         {"label": "Golfito", "value": "C20863"},
                         {"label": "Jaco", "value": "C20864"},
                         {"label": "Miramar", "value": "C20865"},
                         {"label": "Montes de Oro", "value": "C20866"},
                         {"label": "Osa", "value": "C20867"},
                         {"label": "Paquera", "value": "C20868"},
                         {"label": "Parrita", "value": "C20869"},
                         {"label": "Puntarenas", "value": "C20870"},
                         {"label": "Quepos", "value": "C20871"},
                         {"label": "San Vito", "value": "C20872"}],
 "Pursat": [{"label": "Bakan", "value": "C16533"},
            {"label": "Krakor", "value": "C16534"},
            {"label": "Pursat", "value": "C16535"},
            {"label": "Sampov Meas", "value": "C16536"},
            {"label": "Srok Kandieng", "value": "C16537"},
            {"label": "Srok Veal Veng", "value": "C16538"}],
 "Putrajaya": [{"label": "Putrajaya", "value": "C65738"}],
 "Putumayo": [{"label": "Colon", "value": "C20407"},
              {"label": "Mocoa", "value": "C20408"},
              {"label": "Orito", "value": "C20409"},
              {"label": "Puerto Asis", "value": "C20410"},
              {"label": "Puerto Caicedo", "value": "C20411"},
              {"label": "Puerto Guzman", "value": "C20412"},
              {"label": "Puerto Leguizamo", "value": "C20413"},
              {"label": "San Francisco", "value": "C20414"},
              {"label": "San Miguel", "value": "C20415"},
              {"label": "Santiago", "value": "C20416"},
              {"label": "Sibundoy", "value": "C20417"},
              {"label": "Valle del Guamuez", "value": "C20418"},
              {"label": "Villagarzon", "value": "C20419"}],
 "Pwani": [{"label": "Bagamoyo", "value": "C118266"},
           {"label": "Bungu", "value": "C118267"},
           {"label": "Chalinze", "value": "C118268"},
           {"label": "Ikwiriri", "value": "C118269"},
           {"label": "Kibaha", "value": "C118270"},
           {"label": "Kibiti", "value": "C118271"},
           {"label": "Kilindoni", "value": "C118272"},
           {"label": "Kisarawe", "value": "C118273"},
           {"label": "Lugoba", "value": "C118274"},
           {"label": "Maneromango", "value": "C118275"},
           {"label": "Mbumi", "value": "C118276"},
           {"label": "Mkuranga", "value": "C118277"},
           {"label": "Mlandizi", "value": "C118278"},
           {"label": "Mvomero", "value": "C118279"},
           {"label": "Utete", "value": "C118280"},
           {"label": "Vikindu", "value": "C118281"}],
 "Pyongyang": [{"label": "Chunghwa", "value": "C78580"},
               {"label": "Kangdong-up", "value": "C78581"},
               {"label": "Pyongyang", "value": "C78582"},
               {"label": "Sunan", "value": "C78583"},
               {"label": "Sungho 1-tong", "value": "C78584"}],
 "Qabala District": [{"label": "Qutqashen", "value": "C8490"}],
 "Qachas Nek District": [{"label": "Qachas Nek", "value": "C64999"}],
 "Qakh District": [{"label": "Cinarli", "value": "C8491"},
                   {"label": "Qax", "value": "C8492"},
                   {"label": "Qax Ingiloy", "value": "C8493"},
                   {"label": "Qaxbas", "value": "C8494"}],
 "Qala": [{"label": "Qala", "value": "C65908"}],
 "Qalyubia": [{"label": "Al Khankah", "value": "C24315"},
              {"label": "Al Qanatir al Khayriyah", "value": "C24316"},
              {"label": "Banha", "value": "C24317"},
              {"label": "Qalyub", "value": "C24318"},
              {"label": "Shibin al Qanatir", "value": "C24319"},
              {"label": "Toukh", "value": "C24320"}],
 "Qashqadaryo Region": [{"label": "Beshkent", "value": "C147060"},
                        {"label": "Chiroqchi", "value": "C147061"},
                        {"label": "Guzor", "value": "C147062"},
                        {"label": "Kitob", "value": "C147063"},
                        {"label": "Koson", "value": "C147064"},
                        {"label": "Muborak", "value": "C147065"},
                        {"label": "Nishon Tumani", "value": "C147066"},
                        {"label": "Qarshi", "value": "C147067"},
                        {"label": "Shahrisabz", "value": "C147068"}],
 "Qazakh District": [{"label": "Qazax", "value": "C8495"}],
 "Qazvin": [{"label": "Alborz", "value": "C50954"},
            {"label": "Alvand", "value": "C50955"},
            {"label": "Avaj", "value": "C50956"},
            {"label": "Qazvin", "value": "C50957"},
            {"label": "Shahrestan-e Buin Zahra", "value": "C50958"},
            {"label": "Shahrestan-e Qazvin", "value": "C50959"},
            {"label": "Shahrestan-e Takestan", "value": "C50960"},
            {"label": "Takestan", "value": "C50961"}],
 "Qena": [{"label": "Dishna", "value": "C24321"},
          {"label": "Farshut", "value": "C24322"},
          {"label": "Isna", "value": "C24323"},
          {"label": "Kousa", "value": "C24324"},
          {"label": "Naja Hammadi", "value": "C24325"},
          {"label": "Qina", "value": "C24326"}],
 "Qinghai": [{"label": "Delingha", "value": "C19110"},
             {"label": "Golmud", "value": "C19111"},
             {"label": "Golog Tibetan Autonomous Prefecture",
              "value": "C19112"},
             {"label": "Haibei Tibetan Autonomous Prefecture",
              "value": "C19113"},
             {"label": "Huangnan Zangzu Zizhizhou", "value": "C19114"},
             {"label": "Xining", "value": "C19115"},
             {"label": "Xireg", "value": "C19116"},
             {"label": "Yushu Zangzu Zizhizhou", "value": "C19117"}],
 "Qom": [{"label": "Qom", "value": "C50962"}],
 "Qormi": [{"label": "Qormi", "value": "C65909"}],
 "Qrendi": [{"label": "Qrendi", "value": "C65910"}],
 "Quang Binh": [{"label": "Huyen Le Thuy", "value": "C147569"},
                {"label": "Huyen Quang Ninh", "value": "C147570"},
                {"label": "Huyen Quang Trach", "value": "C147571"},
                {"label": "Huyen Tuyen Hoa", "value": "C147572"},
                {"label": "Kwang Binh", "value": "C147573"}],
 "Quang Nam": [{"label": "Hoi An", "value": "C147574"},
               {"label": "Huyen Duy Xuyen", "value": "C147575"},
               {"label": "Huyen Dai Loc", "value": "C147576"},
               {"label": "Huyen Dien Ban", "value": "C147577"},
               {"label": "Huyen Hiep Duc", "value": "C147578"},
               {"label": "Huyen Nam Giang", "value": "C147579"},
               {"label": "Huyen Nui Thanh", "value": "C147580"},
               {"label": "Huyen Phuoc Son", "value": "C147581"},
               {"label": "Huyen Tien Phuoc", "value": "C147582"},
               {"label": "Huyen Tra My", "value": "C147583"},
               {"label": "Tam Ky", "value": "C147584"}],
 "Quang Ngai": [{"label": "Huyen Ba To", "value": "C147585"},
                {"label": "Huyen Binh Son", "value": "C147586"},
                {"label": "Huyen Duc Pho", "value": "C147587"},
                {"label": "Huyen Ly Son", "value": "C147588"},
                {"label": "Huyen Minh Long", "value": "C147589"},
                {"label": "Huyen Mo Duc", "value": "C147590"},
                {"label": "Huyen Nghia Hanh", "value": "C147591"},
                {"label": "Huyen Son Ha", "value": "C147592"},
                {"label": "Huyen Son Tay", "value": "C147593"},
                {"label": "Huyen Son Tinh", "value": "C147594"},
                {"label": "Huyen Tra Bong", "value": "C147595"},
                {"label": "Huyen Tu Nghia", "value": "C147596"},
                {"label": "Quang Ngai", "value": "C147597"}],
 "Quang Ninh": [{"label": "Cam Pha", "value": "C147598"},
                {"label": "Cam Pha Mines", "value": "C147599"},
                {"label": "Ha Long", "value": "C147600"},
                {"label": "Huyen Ba Che", "value": "C147601"},
                {"label": "Huyen Binh Lieu", "value": "C147602"},
                {"label": "Huyen Co To", "value": "C147603"},
                {"label": "Huyen Dam Ha", "value": "C147604"},
                {"label": "Huyen Dong Trieu", "value": "C147605"},
                {"label": "Huyen Hai Ha", "value": "C147606"},
                {"label": "Huyen Hoanh Bo", "value": "C147607"},
                {"label": "Huyen Tien Yen", "value": "C147608"},
                {"label": "Huyen Van Don", "value": "C147609"},
                {"label": "Mong Cai", "value": "C147610"},
                {"label": "Thanh Pho Uong Bi", "value": "C147611"}],
 "Quang Tri": [{"label": "Dong Ha", "value": "C147612"},
               {"label": "Huyen Cam Lo", "value": "C147613"},
               {"label": "Huyen Da Krong", "value": "C147614"},
               {"label": "Huyen Gio Linh", "value": "C147615"},
               {"label": "Huyen Hai Lang", "value": "C147616"},
               {"label": "Huyen Huong Hoa", "value": "C147617"},
               {"label": "Huyen Trieu Phong", "value": "C147618"},
               {"label": "Huyen Vinh Linh", "value": "C147619"}],
 "Quba District": [{"label": "Hacihuseynli", "value": "C8496"},
                   {"label": "Quba", "value": "C8497"}],
 "Qubadli District": [{"label": "Qubadli", "value": "C8498"}],
 "Quebec": [{"label": "Abitibi-Temiscamingue", "value": "C17375"},
            {"label": "Acton Vale", "value": "C17376"},
            {"label": "Adstock", "value": "C17377"},
            {"label": "Albanel", "value": "C17378"},
            {"label": "Alma", "value": "C17379"},
            {"label": "Amos", "value": "C17380"},
            {"label": "Amqui", "value": "C17381"},
            {"label": "Ange-Gardien", "value": "C17382"},
            {"label": "Asbestos", "value": "C17383"},
            {"label": "Baie-Comeau", "value": "C17384"},
            {"label": "Baie-DUrfe", "value": "C17385"},
            {"label": "Baie-Saint-Paul", "value": "C17386"},
            {"label": "Barraute", "value": "C17387"},
            {"label": "Bas-Saint-Laurent", "value": "C17388"},
            {"label": "Beaconsfield", "value": "C17389"},
            {"label": "Beauceville", "value": "C17390"},
            {"label": "Beauharnois", "value": "C17391"},
            {"label": "Beaupre", "value": "C17392"},
            {"label": "Becancour", "value": "C17393"},
            {"label": "Bedford", "value": "C17394"},
            {"label": "Beloeil", "value": "C17395"},
            {"label": "Berthierville", "value": "C17396"},
            {"label": "Blainville", "value": "C17397"},
            {"label": "Bois-des-Filion", "value": "C17398"},
            {"label": "Boisbriand", "value": "C17399"},
            {"label": "Bonaventure", "value": "C17400"},
            {"label": "Boucherville", "value": "C17401"},
            {"label": "Breakeyville", "value": "C17402"},
            {"label": "Bromont", "value": "C17403"},
            {"label": "Brossard", "value": "C17404"},
            {"label": "Brownsburg-Chatham", "value": "C17405"},
            {"label": "Buckingham", "value": "C17406"},
            {"label": "Cabano", "value": "C17407"},
            {"label": "Cacouna", "value": "C17408"},
            {"label": "Candiac", "value": "C17409"},
            {"label": "Cantley", "value": "C17410"},
            {"label": "Cap-Chat", "value": "C17411"},
            {"label": "Cap-Sante", "value": "C17412"},
            {"label": "Capitale-Nationale", "value": "C17413"},
            {"label": "Carignan", "value": "C17414"},
            {"label": "Carleton", "value": "C17415"},
            {"label": "Carleton-sur-Mer", "value": "C17416"},
            {"label": "Centre-du-Quebec", "value": "C17417"},
            {"label": "Chambly", "value": "C17418"},
            {"label": "Chambord", "value": "C17419"},
            {"label": "Chandler", "value": "C17420"},
            {"label": "Chapais", "value": "C17421"},
            {"label": "Charlemagne", "value": "C17422"},
            {"label": "Chateau-Richer", "value": "C17423"},
            {"label": "Chateauguay", "value": "C17424"},
            {"label": "Chaudiere-Appalaches", "value": "C17425"},
            {"label": "Chertsey", "value": "C17426"},
            {"label": "Chibougamau", "value": "C17427"},
            {"label": "Chute-aux-Outardes", "value": "C17428"},
            {"label": "Coaticook", "value": "C17429"},
            {"label": "Contrecoeur", "value": "C17430"},
            {"label": "Cookshire", "value": "C17431"},
            {"label": "Cookshire-Eaton", "value": "C17432"},
            {"label": "Cote-Nord", "value": "C17433"},
            {"label": "Cote-Saint-Luc", "value": "C17434"},
            {"label": "Coteau-du-Lac", "value": "C17435"},
            {"label": "Cowansville", "value": "C17436"},
            {"label": "Crabtree", "value": "C17437"},
            {"label": "Danville", "value": "C17438"},
            {"label": "Daveluyville", "value": "C17439"},
            {"label": "Delson", "value": "C17440"},
            {"label": "Deux-Montagnes", "value": "C17441"},
            {"label": "Disraeli", "value": "C17442"},
            {"label": "Dolbeau-Mistassini", "value": "C17443"},
            {"label": "Dollard-Des Ormeaux", "value": "C17444"},
            {"label": "Donnacona", "value": "C17445"},
            {"label": "Dorval", "value": "C17446"},
            {"label": "Drummondville", "value": "C17447"},
            {"label": "Dunham", "value": "C17448"},
            {"label": "East Angus", "value": "C17449"},
            {"label": "East Broughton", "value": "C17450"},
            {"label": "Farnham", "value": "C17451"},
            {"label": "Ferme-Neuve", "value": "C17452"},
            {"label": "Fermont", "value": "C17453"},
            {"label": "Forestville", "value": "C17454"},
            {"label": "Fort-Coulonge", "value": "C17455"},
            {"label": "Fossambault-sur-le-Lac", "value": "C17456"},
            {"label": "Franklin", "value": "C17457"},
            {"label": "Gaspe", "value": "C17458"},
            {"label": "Gaspesie-Iles-de-la-Madeleine", "value": "C17459"},
            {"label": "Gatineau", "value": "C17460"},
            {"label": "Godefroy", "value": "C17461"},
            {"label": "Granby", "value": "C17462"},
            {"label": "Hampstead", "value": "C17463"},
            {"label": "Hauterive", "value": "C17464"},
            {"label": "Havre-Saint-Pierre", "value": "C17465"},
            {"label": "Herouxville", "value": "C17466"},
            {"label": "Hudson", "value": "C17467"},
            {"label": "Huntingdon", "value": "C17468"},
            {"label": "Joliette", "value": "C17469"},
            {"label": "Jonquiere", "value": "C17470"},
            {"label": "Kingsey Falls", "value": "C17471"},
            {"label": "Kirkland", "value": "C17472"},
            {"label": "LAncienne-Lorette", "value": "C17473"},
            {"label": "LAnge-Gardien", "value": "C17474"},
            {"label": "LAscension-de-Notre-Seigneur", "value": "C17475"},
            {"label": "LAssomption", "value": "C17476"},
            {"label": "Lepiphanie", "value": "C17477"},
            {"label": "LIle-Perrot", "value": "C17478"},
            {"label": "La Conception", "value": "C17479"},
            {"label": "La Haute-Saint-Charles", "value": "C17480"},
            {"label": "La Malbaie", "value": "C17481"},
            {"label": "La Minerve", "value": "C17482"},
            {"label": "La Pocatiere", "value": "C17483"},
            {"label": "La Prairie", "value": "C17484"},
            {"label": "La Sarre", "value": "C17485"},
            {"label": "La Tuque", "value": "C17486"},
            {"label": "Labelle", "value": "C17487"},
            {"label": "Lac-Alouette", "value": "C17488"},
            {"label": "Lac-Brome", "value": "C17489"},
            {"label": "Lac-Connelly", "value": "C17490"},
            {"label": "Lac-Lapierre", "value": "C17491"},
            {"label": "Lac-Megantic", "value": "C17492"},
            {"label": "Lac-Simon", "value": "C17493"},
            {"label": "Lachute", "value": "C17494"},
            {"label": "Lacolle", "value": "C17495"},
            {"label": "Lanoraie", "value": "C17496"},
            {"label": "Laval", "value": "C17497"},
            {"label": "Lavaltrie", "value": "C17498"},
            {"label": "Le Bic", "value": "C17499"},
            {"label": "le Plateau", "value": "C17500"},
            {"label": "Lebel-sur-Quevillon", "value": "C17501"},
            {"label": "Leblanc", "value": "C17502"},
            {"label": "Les Cedres", "value": "C17503"},
            {"label": "Les Coteaux", "value": "C17504"},
            {"label": "Les Escoumins", "value": "C17505"},
            {"label": "Levis", "value": "C17506"},
            {"label": "Liniere", "value": "C17507"},
            {"label": "Longueuil", "value": "C17508"},
            {"label": "Lorraine", "value": "C17509"},
            {"label": "Louiseville", "value": "C17510"},
            {"label": "Luceville", "value": "C17511"},
            {"label": "Macamic", "value": "C17512"},
            {"label": "Magog", "value": "C17513"},
            {"label": "Malartic", "value": "C17514"},
            {"label": "Maliotenam", "value": "C17515"},
            {"label": "Manawan", "value": "C17516"},
            {"label": "Mandeville", "value": "C17517"},
            {"label": "Maniwaki", "value": "C17518"},
            {"label": "Maria", "value": "C17519"},
            {"label": "Marieville", "value": "C17520"},
            {"label": "Mascouche", "value": "C17521"},
            {"label": "Maskinonge", "value": "C17522"},
            {"label": "Matagami", "value": "C17523"},
            {"label": "Matane", "value": "C17524"},
            {"label": "Mauricie", "value": "C17525"},
            {"label": "Melocheville", "value": "C17526"},
            {"label": "Mercier", "value": "C17527"},
            {"label": "Metabetchouan", "value": "C17528"},
            {"label": "Metabetchouan-Lac-a-la-Croix", "value": "C17529"},
            {"label": "Mirabel", "value": "C17530"},
            {"label": "Mistissini", "value": "C17531"},
            {"label": "Mont-Joli", "value": "C17532"},
            {"label": "Mont-Laurier", "value": "C17533"},
            {"label": "Mont-Royal", "value": "C17534"},
            {"label": "Mont-Saint-Gregoire", "value": "C17535"},
            {"label": "Mont-Saint-Hilaire", "value": "C17536"},
            {"label": "Mont-Tremblant", "value": "C17537"},
            {"label": "Montmagny", "value": "C17538"},
            {"label": "Montreal", "value": "C17539"},
            {"label": "Montreal-Est", "value": "C17540"},
            {"label": "Montreal-Ouest", "value": "C17541"},
            {"label": "Morin-Heights", "value": "C17542"},
            {"label": "Napierville", "value": "C17543"},
            {"label": "Neuville", "value": "C17544"},
            {"label": "New Carlisle", "value": "C17545"},
            {"label": "New-Richmond", "value": "C17546"},
            {"label": "Nicolet", "value": "C17547"},
            {"label": "Nord-du-Quebec", "value": "C17548"},
            {"label": "Normandin", "value": "C17549"},
            {"label": "Notre-Dame-de-Grace", "value": "C17550"},
            {"label": "Notre-Dame-de-lIle-Perrot", "value": "C17551"},
            {"label": "Notre-Dame-des-Prairies", "value": "C17552"},
            {"label": "Notre-Dame-du-Lac", "value": "C17553"},
            {"label": "Notre-Dame-du-Mont-Carmel", "value": "C17554"},
            {"label": "Oka", "value": "C17555"},
            {"label": "Ormstown", "value": "C17556"},
            {"label": "Otterburn Park", "value": "C17557"},
            {"label": "Outaouais", "value": "C17558"},
            {"label": "Papineauville", "value": "C17559"},
            {"label": "Parc-Boutin", "value": "C17560"},
            {"label": "Piedmont", "value": "C17561"},
            {"label": "Pierreville", "value": "C17562"},
            {"label": "Pincourt", "value": "C17563"},
            {"label": "Plessisville", "value": "C17564"},
            {"label": "Pohenegamook", "value": "C17565"},
            {"label": "Pointe-Calumet", "value": "C17566"},
            {"label": "Pointe-Claire", "value": "C17567"},
            {"label": "Pointe-du-Lac", "value": "C17568"},
            {"label": "Pont Rouge", "value": "C17569"},
            {"label": "Pont-Rouge", "value": "C17570"},
            {"label": "Port-Cartier", "value": "C17571"},
            {"label": "Portneuf", "value": "C17572"},
            {"label": "Prevost", "value": "C17573"},
            {"label": "Princeville", "value": "C17574"},
            {"label": "Quebec", "value": "C17575"},
            {"label": "Rawdon", "value": "C17576"},
            {"label": "Repentigny", "value": "C17577"},
            {"label": "Richelieu", "value": "C17578"},
            {"label": "Richmond", "value": "C17579"},
            {"label": "Rigaud", "value": "C17580"},
            {"label": "Rimouski", "value": "C17581"},
            {"label": "Riviere-du-Loup", "value": "C17582"},
            {"label": "Riviere-Rouge", "value": "C17583"},
            {"label": "Roberval", "value": "C17584"},
            {"label": "Rock Forest", "value": "C17585"},
            {"label": "Rosemere", "value": "C17586"},
            {"label": "Rougemont", "value": "C17587"},
            {"label": "Rouyn-Noranda", "value": "C17588"},
            {"label": "Sacre-Coeur", "value": "C17589"},
            {"label": "Saguenay", "value": "C17590"},
            {"label": "Saint-Adolphe-dHoward", "value": "C17591"},
            {"label": "Saint-Alexandre", "value": "C17592"},
            {"label": "Saint-Amable", "value": "C17593"},
            {"label": "Saint-Ambroise", "value": "C17594"},
            {"label": "Saint-Andre-Avellin", "value": "C17595"},
            {"label": "Saint-Anselme", "value": "C17596"},
            {"label": "Saint-Antoine-de-Tilly", "value": "C17597"},
            {"label": "Saint-Augustin", "value": "C17598"},
            {"label": "Saint-Augustin-de-Desmaures", "value": "C17599"},
            {"label": "Saint-Barnabe-Sud", "value": "C17600"},
            {"label": "Saint-Basile-le-Grand", "value": "C17601"},
            {"label": "Saint-Boniface", "value": "C17602"},
            {"label": "Saint-Bruno", "value": "C17603"},
            {"label": "Saint-Bruno-de-Guigues", "value": "C17604"},
            {"label": "Saint-Bruno-de-Montarville", "value": "C17605"},
            {"label": "Saint-Canut", "value": "C17606"},
            {"label": "Saint-Cesaire", "value": "C17607"},
            {"label": "Saint-Charles", "value": "C17608"},
            {"label": "Saint-Come-Liniere", "value": "C17609"},
            {"label": "Saint-Constant", "value": "C17610"},
            {"label": "Saint-Cyrille-de-Wendover", "value": "C17611"},
            {"label": "Saint-Damase", "value": "C17612"},
            {"label": "Saint-Denis-sur-Richelieu", "value": "C17613"},
            {"label": "Saint-Donat-de-Montcalm", "value": "C17614"},
            {"label": "Saint-edouard", "value": "C17615"},
            {"label": "Saint-Elzear", "value": "C17616"},
            {"label": "Saint-ephrem-de-Beauce", "value": "C17617"},
            {"label": "Saint-Eustache", "value": "C17618"},
            {"label": "Saint-Felicien", "value": "C17619"},
            {"label": "Saint-Felix-de-Valois", "value": "C17620"},
            {"label": "Saint-Gabriel", "value": "C17621"},
            {"label": "Saint-Gedeon", "value": "C17622"},
            {"label": "Saint-Georges", "value": "C17623"},
            {"label": "Saint-Germain-de-Grantham", "value": "C17624"},
            {"label": "Saint-Henri", "value": "C17625"},
            {"label": "Saint-Hippolyte", "value": "C17626"},
            {"label": "Saint-Honore", "value": "C17627"},
            {"label": "Saint-Hyacinthe", "value": "C17628"},
            {"label": "Saint-Isidore", "value": "C17629"},
            {"label": "Saint-Jacques-le-Mineur", "value": "C17630"},
            {"label": "Saint-Jean-Baptiste", "value": "C17631"},
            {"label": "Saint-Jean-sur-Richelieu", "value": "C17632"},
            {"label": "Saint-Jerome", "value": "C17633"},
            {"label": "Saint-Joseph", "value": "C17634"},
            {"label": "Saint-Joseph-de-Beauce", "value": "C17635"},
            {"label": "Saint-Joseph-de-Coleraine", "value": "C17636"},
            {"label": "Saint-Joseph-du-Lac", "value": "C17637"},
            {"label": "Saint-Lambert-de-Lauzon", "value": "C17638"},
            {"label": "Saint-Laurent", "value": "C17639"},
            {"label": "Saint-Lazare", "value": "C17640"},
            {"label": "Saint-Leonard", "value": "C17641"},
            {"label": "Saint-Leonard-dAston", "value": "C17642"},
            {"label": "Saint-Liboire", "value": "C17643"},
            {"label": "Saint-Lin-Laurentides", "value": "C17644"},
            {"label": "Saint-Marc-des-Carrieres", "value": "C17645"},
            {"label": "Saint-Mathieu", "value": "C17646"},
            {"label": "Saint-Michel", "value": "C17647"},
            {"label": "Saint-Michel-des-Saints", "value": "C17648"},
            {"label": "Saint-Nazaire", "value": "C17649"},
            {"label": "Saint-Norbert", "value": "C17650"},
            {"label": "Saint-Pacome", "value": "C17651"},
            {"label": "Saint-Pascal", "value": "C17652"},
            {"label": "Saint-Philippe-de-La Prairie", "value": "C17653"},
            {"label": "Saint-Pie", "value": "C17654"},
            {"label": "Saint-Pierre-les-Becquets", "value": "C17655"},
            {"label": "Saint-Prime", "value": "C17656"},
            {"label": "Saint-Raphael", "value": "C17657"},
            {"label": "Saint-Raymond", "value": "C17658"},
            {"label": "Saint-Remi", "value": "C17659"},
            {"label": "Saint-Remi-de-Tingwick", "value": "C17660"},
            {"label": "Saint-Sauveur", "value": "C17661"},
            {"label": "Saint-Sauveur-des-Monts", "value": "C17662"},
            {"label": "Saint-Simeon", "value": "C17663"},
            {"label": "Saint-Thomas", "value": "C17664"},
            {"label": "Saint-Tite", "value": "C17665"},
            {"label": "Saint-Victor", "value": "C17666"},
            {"label": "Saint-Zotique", "value": "C17667"},
            {"label": "Sainte Catherine de la Jacques Cartier",
             "value": "C17668"},
            {"label": "Sainte-Adele", "value": "C17669"},
            {"label": "Sainte-Agathe-des-Monts", "value": "C17670"},
            {"label": "Sainte-Anne-de-Bellevue", "value": "C17671"},
            {"label": "Sainte-Anne-des-Monts", "value": "C17672"},
            {"label": "Sainte-Anne-des-Plaines", "value": "C17673"},
            {"label": "Sainte-Beatrix", "value": "C17674"},
            {"label": "Sainte-Catherine", "value": "C17675"},
            {"label": "Sainte-Croix", "value": "C17676"},
            {"label": "Sainte-elisabeth", "value": "C17677"},
            {"label": "Sainte-Julie", "value": "C17678"},
            {"label": "Sainte-Julienne", "value": "C17679"},
            {"label": "Sainte-Madeleine", "value": "C17680"},
            {"label": "Sainte-Marie", "value": "C17681"},
            {"label": "Sainte-Marthe-sur-le-Lac", "value": "C17682"},
            {"label": "Sainte-Martine", "value": "C17683"},
            {"label": "Sainte-Sophie", "value": "C17684"},
            {"label": "Sainte-Thecle", "value": "C17685"},
            {"label": "Sainte-Therese", "value": "C17686"},
            {"label": "Salaberry-de-Valleyfield", "value": "C17687"},
            {"label": "Salluit", "value": "C17688"},
            {"label": "Senneterre", "value": "C17689"},
            {"label": "Sept-Iles", "value": "C17690"},
            {"label": "Shannon", "value": "C17691"},
            {"label": "Shawinigan", "value": "C17692"},
            {"label": "Shawville", "value": "C17693"},
            {"label": "Sherbrooke", "value": "C17694"},
            {"label": "Sorel-Tracy", "value": "C17695"},
            {"label": "St-Jean-Port-Joli", "value": "C17696"},
            {"label": "Sutton", "value": "C17697"},
            {"label": "Temiscaming", "value": "C17698"},
            {"label": "Terrasse-des-Pins", "value": "C17699"},
            {"label": "Terrebonne", "value": "C17700"},
            {"label": "Thetford-Mines", "value": "C17701"},
            {"label": "Thurso", "value": "C17702"},
            {"label": "Trois-Rivieres", "value": "C17703"},
            {"label": "Val-dOr", "value": "C17704"},
            {"label": "Val-David", "value": "C17705"},
            {"label": "Val-des-Monts", "value": "C17706"},
            {"label": "Val-Morin", "value": "C17707"},
            {"label": "Valcourt", "value": "C17708"},
            {"label": "Vallee-Jonction", "value": "C17709"},
            {"label": "Varennes", "value": "C17710"},
            {"label": "Vaudreuil-Dorion", "value": "C17711"},
            {"label": "Venise-en-Quebec", "value": "C17712"},
            {"label": "Vercheres", "value": "C17713"},
            {"label": "Victoriaville", "value": "C17714"},
            {"label": "Ville-Marie", "value": "C17715"},
            {"label": "Wakefield", "value": "C17716"},
            {"label": "Warwick", "value": "C17717"},
            {"label": "Waskaganish", "value": "C17718"},
            {"label": "Waswanipi", "value": "C17719"},
            {"label": "Waterloo", "value": "C17720"},
            {"label": "Weedon Centre", "value": "C17721"},
            {"label": "Westmount", "value": "C17722"},
            {"label": "Weymontachie", "value": "C17723"},
            {"label": "Windsor", "value": "C17724"},
            {"label": "Yamachiche", "value": "C17725"}],
 "Queensland": [{"label": "Acacia Ridge", "value": "C3291"},
                {"label": "Agnes Water", "value": "C3292"},
                {"label": "Airlie Beach", "value": "C3293"},
                {"label": "Aitkenvale", "value": "C3294"},
                {"label": "Albany Creek", "value": "C3295"},
                {"label": "Alderley", "value": "C3296"},
                {"label": "Aldershot", "value": "C3297"},
                {"label": "Alexandra Headland", "value": "C3298"},
                {"label": "Alexandra Hills", "value": "C3299"},
                {"label": "Algester", "value": "C3300"},
                {"label": "Alice River", "value": "C3301"},
                {"label": "Allenstown", "value": "C3302"},
                {"label": "Alligator Creek", "value": "C3303"},
                {"label": "Allora", "value": "C3304"},
                {"label": "Alton Downs", "value": "C3305"},
                {"label": "Andergrove", "value": "C3306"},
                {"label": "Annandale", "value": "C3307"},
                {"label": "Annerley", "value": "C3308"},
                {"label": "Anstead", "value": "C3309"},
                {"label": "Arana Hills", "value": "C3310"},
                {"label": "Aroona", "value": "C3311"},
                {"label": "Arundel", "value": "C3312"},
                {"label": "Ascot", "value": "C3313"},
                {"label": "Ashgrove", "value": "C3314"},
                {"label": "Ashmore", "value": "C3315"},
                {"label": "Aspley", "value": "C3316"},
                {"label": "Atherton", "value": "C3317"},
                {"label": "Auchenflower", "value": "C3318"},
                {"label": "Augustine Heights", "value": "C3319"},
                {"label": "Aurukun", "value": "C3320"},
                {"label": "Avenell Heights", "value": "C3321"},
                {"label": "Avoca", "value": "C3322"},
                {"label": "Ayr", "value": "C3323"},
                {"label": "Babinda", "value": "C3324"},
                {"label": "Bahrs Scrub", "value": "C3325"},
                {"label": "Bakers Creek", "value": "C3326"},
                {"label": "Bald Hills", "value": "C3327"},
                {"label": "Balmoral", "value": "C3328"},
                {"label": "Balonne Shire", "value": "C3329"},
                {"label": "Bamaga", "value": "C3330"},
                {"label": "Banana", "value": "C3331"},
                {"label": "Banksia Beach", "value": "C3332"},
                {"label": "Banyo", "value": "C3333"},
                {"label": "Barcaldine", "value": "C3334"},
                {"label": "Barcoo", "value": "C3335"},
                {"label": "Bardon", "value": "C3336"},
                {"label": "Barellan Point", "value": "C3337"},
                {"label": "Bargara", "value": "C3338"},
                {"label": "Barney Point", "value": "C3339"},
                {"label": "Battery Hill", "value": "C3340"},
                {"label": "Bayview Heights", "value": "C3341"},
                {"label": "Beachmere", "value": "C3342"},
                {"label": "Beaconsfield", "value": "C3343"},
                {"label": "Beaudesert", "value": "C3344"},
                {"label": "Beenleigh", "value": "C3345"},
                {"label": "Beerwah", "value": "C3346"},
                {"label": "Belgian Gardens", "value": "C3347"},
                {"label": "Bellara", "value": "C3348"},
                {"label": "Bellbird Park", "value": "C3349"},
                {"label": "Bellbowrie", "value": "C3350"},
                {"label": "Bellmere", "value": "C3351"},
                {"label": "Belmont", "value": "C3352"},
                {"label": "Benaraby", "value": "C3353"},
                {"label": "Benowa", "value": "C3354"},
                {"label": "Bentley Park", "value": "C3355"},
                {"label": "Berrinba", "value": "C3356"},
                {"label": "Berserker", "value": "C3357"},
                {"label": "Biggera Waters", "value": "C3358"},
                {"label": "Bilinga", "value": "C3359"},
                {"label": "Biloela", "value": "C3360"},
                {"label": "Birkdale", "value": "C3361"},
                {"label": "Birtinya", "value": "C3362"},
                {"label": "Black Mountain", "value": "C3363"},
                {"label": "Black River", "value": "C3364"},
                {"label": "Blackall", "value": "C3365"},
                {"label": "Blackall Tambo", "value": "C3366"},
                {"label": "Blacks Beach", "value": "C3367"},
                {"label": "Blackwater", "value": "C3368"},
                {"label": "Bli Bli", "value": "C3369"},
                {"label": "Bluewater", "value": "C3370"},
                {"label": "Bohle Plains", "value": "C3371"},
                {"label": "Bokarina", "value": "C3372"},
                {"label": "Bongaree", "value": "C3373"},
                {"label": "Bonogin", "value": "C3374"},
                {"label": "Booie", "value": "C3375"},
                {"label": "Boonah", "value": "C3376"},
                {"label": "Boondall", "value": "C3377"},
                {"label": "Booral", "value": "C3378"},
                {"label": "Booval", "value": "C3379"},
                {"label": "Boronia Heights", "value": "C3380"},
                {"label": "Bouldercombe", "value": "C3381"},
                {"label": "Boulia", "value": "C3382"},
                {"label": "Bowen", "value": "C3383"},
                {"label": "Bowen Hills", "value": "C3384"},
                {"label": "Boyne Island", "value": "C3385"},
                {"label": "Bracken Ridge", "value": "C3386"},
                {"label": "Brandon", "value": "C3387"},
                {"label": "Branyan", "value": "C3388"},
                {"label": "Brassall", "value": "C3389"},
                {"label": "Bray Park", "value": "C3390"},
                {"label": "Brendale", "value": "C3391"},
                {"label": "Bridgeman Downs", "value": "C3392"},
                {"label": "Brighton", "value": "C3393"},
                {"label": "Brinsmead", "value": "C3394"},
                {"label": "Brisbane", "value": "C3395"},
                {"label": "Broadbeach", "value": "C3396"},
                {"label": "Broadbeach Waters", "value": "C3397"},
                {"label": "Brookfield", "value": "C3398"},
                {"label": "Brookwater", "value": "C3399"},
                {"label": "Bucasia", "value": "C3400"},
                {"label": "Bucca", "value": "C3401"},
                {"label": "Buccan", "value": "C3402"},
                {"label": "Buddina", "value": "C3403"},
                {"label": "Buderim", "value": "C3404"},
                {"label": "Bulimba", "value": "C3405"},
                {"label": "Bulloo", "value": "C3406"},
                {"label": "Bundaberg", "value": "C3407"},
                {"label": "Bundaberg East", "value": "C3408"},
                {"label": "Bundaberg North", "value": "C3409"},
                {"label": "Bundaberg South", "value": "C3410"},
                {"label": "Bundaberg West", "value": "C3411"},
                {"label": "Bundall", "value": "C3412"},
                {"label": "Bundamba", "value": "C3413"},
                {"label": "Bungalow", "value": "C3414"},
                {"label": "Bunya", "value": "C3415"},
                {"label": "Burbank", "value": "C3416"},
                {"label": "Burdekin", "value": "C3417"},
                {"label": "Burdell", "value": "C3418"},
                {"label": "Burke", "value": "C3419"},
                {"label": "Burleigh Heads", "value": "C3420"},
                {"label": "Burleigh Waters", "value": "C3421"},
                {"label": "Burnett Heads", "value": "C3422"},
                {"label": "Burnside", "value": "C3423"},
                {"label": "Burpengary", "value": "C3424"},
                {"label": "Burpengary East", "value": "C3425"},
                {"label": "Burrum Heads", "value": "C3426"},
                {"label": "Bushland Beach", "value": "C3427"},
                {"label": "Cabarlah", "value": "C3428"},
                {"label": "Caboolture", "value": "C3429"},
                {"label": "Caboolture South", "value": "C3430"},
                {"label": "Cairns", "value": "C3431"},
                {"label": "Cairns City", "value": "C3432"},
                {"label": "Cairns North", "value": "C3433"},
                {"label": "Calamvale", "value": "C3434"},
                {"label": "Calliope", "value": "C3435"},
                {"label": "Caloundra", "value": "C3436"},
                {"label": "Caloundra West", "value": "C3437"},
                {"label": "Cambooya", "value": "C3438"},
                {"label": "Camira", "value": "C3439"},
                {"label": "Camp Hill", "value": "C3440"},
                {"label": "Camp Mountain", "value": "C3441"},
                {"label": "Cannon Hill", "value": "C3442"},
                {"label": "Cannonvale", "value": "C3443"},
                {"label": "Canungra", "value": "C3444"},
                {"label": "Capalaba", "value": "C3445"},
                {"label": "Capella", "value": "C3446"},
                {"label": "Caravonica", "value": "C3447"},
                {"label": "Carbrook", "value": "C3448"},
                {"label": "Cardwell", "value": "C3449"},
                {"label": "Carina Heights", "value": "C3450"},
                {"label": "Carindale", "value": "C3451"},
                {"label": "Carpentaria", "value": "C3452"},
                {"label": "Carrara", "value": "C3453"},
                {"label": "Carseldine", "value": "C3454"},
                {"label": "Cashmere", "value": "C3455"},
                {"label": "Cassowary Coast", "value": "C3456"},
                {"label": "Cedar Grove", "value": "C3457"},
                {"label": "Cedar Vale", "value": "C3458"},
                {"label": "Centenary Heights", "value": "C3459"},
                {"label": "Central Highlands", "value": "C3460"},
                {"label": "Chambers Flat", "value": "C3461"},
                {"label": "Chandler", "value": "C3462"},
                {"label": "Chapel Hill", "value": "C3463"},
                {"label": "Charleville", "value": "C3464"},
                {"label": "Charters Towers", "value": "C3465"},
                {"label": "Charters Towers City", "value": "C3466"},
                {"label": "Chatsworth", "value": "C3467"},
                {"label": "Chelmer", "value": "C3468"},
                {"label": "Cherbourg", "value": "C3469"},
                {"label": "Chermside", "value": "C3470"},
                {"label": "Chermside West", "value": "C3471"},
                {"label": "Childers", "value": "C3472"},
                {"label": "Chinchilla", "value": "C3473"},
                {"label": "Churchill", "value": "C3474"},
                {"label": "Chuwar", "value": "C3475"},
                {"label": "Clayfield", "value": "C3476"},
                {"label": "Clear Island Waters", "value": "C3477"},
                {"label": "Clermont", "value": "C3478"},
                {"label": "Cleveland", "value": "C3479"},
                {"label": "Clifton", "value": "C3480"},
                {"label": "Clifton Beach", "value": "C3481"},
                {"label": "Clinton", "value": "C3482"},
                {"label": "Cloncurry", "value": "C3483"},
                {"label": "Clontarf", "value": "C3484"},
                {"label": "Coes Creek", "value": "C3485"},
                {"label": "Collingwood Park", "value": "C3486"},
                {"label": "Collinsville", "value": "C3487"},
                {"label": "Condon", "value": "C3488"},
                {"label": "Cooee Bay", "value": "C3489"},
                {"label": "Cook Shire", "value": "C3490"},
                {"label": "Cooktown", "value": "C3491"},
                {"label": "Coolangatta", "value": "C3492"},
                {"label": "Cooloola Cove", "value": "C3493"},
                {"label": "Coolum Beach", "value": "C3494"},
                {"label": "Coombabah", "value": "C3495"},
                {"label": "Coomera", "value": "C3496"},
                {"label": "Coominya", "value": "C3497"},
                {"label": "Coopers Plains", "value": "C3498"},
                {"label": "Cooran", "value": "C3499"},
                {"label": "Cooroibah", "value": "C3500"},
                {"label": "Cooroy", "value": "C3501"},
                {"label": "Coorparoo", "value": "C3502"},
                {"label": "Coppabella", "value": "C3503"},
                {"label": "Coral Cove", "value": "C3504"},
                {"label": "Corinda", "value": "C3505"},
                {"label": "Cornubia", "value": "C3506"},
                {"label": "Cotswold Hills", "value": "C3507"},
                {"label": "Craiglie", "value": "C3508"},
                {"label": "Craignish", "value": "C3509"},
                {"label": "Cranbrook", "value": "C3510"},
                {"label": "Cranley", "value": "C3511"},
                {"label": "Crestmead", "value": "C3512"},
                {"label": "Crows Nest", "value": "C3513"},
                {"label": "Croydon", "value": "C3514"},
                {"label": "Cunnamulla", "value": "C3515"},
                {"label": "Curra", "value": "C3516"},
                {"label": "Currajong", "value": "C3517"},
                {"label": "Currimundi", "value": "C3518"},
                {"label": "Currumbin", "value": "C3519"},
                {"label": "Currumbin Valley", "value": "C3520"},
                {"label": "Currumbin Waters", "value": "C3521"},
                {"label": "DAguilar", "value": "C3522"},
                {"label": "Daisy Hill", "value": "C3523"},
                {"label": "Dakabin", "value": "C3524"},
                {"label": "Dalby", "value": "C3525"},
                {"label": "Darling Heights", "value": "C3526"},
                {"label": "Darra", "value": "C3527"},
                {"label": "Dayboro", "value": "C3528"},
                {"label": "Deagon", "value": "C3529"},
                {"label": "Deception Bay", "value": "C3530"},
                {"label": "Deebing Heights", "value": "C3531"},
                {"label": "Deeragun", "value": "C3532"},
                {"label": "Delaneys Creek", "value": "C3533"},
                {"label": "Depot Hill", "value": "C3534"},
                {"label": "Diamantina", "value": "C3535"},
                {"label": "Dicky Beach", "value": "C3536"},
                {"label": "Diddillibah", "value": "C3537"},
                {"label": "Dimbulah", "value": "C3538"},
                {"label": "Doolandella", "value": "C3539"},
                {"label": "Doomadgee", "value": "C3540"},
                {"label": "Doonan", "value": "C3541"},
                {"label": "Douglas", "value": "C3542"},
                {"label": "Drayton", "value": "C3543"},
                {"label": "Drewvale", "value": "C3544"},
                {"label": "Dundowran Beach", "value": "C3545"},
                {"label": "Durack", "value": "C3546"},
                {"label": "Dutton Park", "value": "C3547"},
                {"label": "Dysart", "value": "C3548"},
                {"label": "Eagle Farm", "value": "C3549"},
                {"label": "Eagleby", "value": "C3550"},
                {"label": "Earlville", "value": "C3551"},
                {"label": "East Brisbane", "value": "C3552"},
                {"label": "East Innisfail", "value": "C3553"},
                {"label": "East Ipswich", "value": "C3554"},
                {"label": "East Mackay", "value": "C3555"},
                {"label": "East Toowoomba", "value": "C3556"},
                {"label": "Eastern Heights", "value": "C3557"},
                {"label": "Eatons Hill", "value": "C3558"},
                {"label": "Edens Landing", "value": "C3559"},
                {"label": "Edge Hill", "value": "C3560"},
                {"label": "Edmonton", "value": "C3561"},
                {"label": "Eight Mile Plains", "value": "C3562"},
                {"label": "Eimeo", "value": "C3563"},
                {"label": "Elanora", "value": "C3564"},
                {"label": "Eli Waters", "value": "C3565"},
                {"label": "Elimbah", "value": "C3566"},
                {"label": "Ellen Grove", "value": "C3567"},
                {"label": "Elliott Heads", "value": "C3568"},
                {"label": "Emerald", "value": "C3569"},
                {"label": "Emu Park", "value": "C3570"},
                {"label": "Enoggera", "value": "C3571"},
                {"label": "Esk", "value": "C3572"},
                {"label": "Etheridge", "value": "C3573"},
                {"label": "Eudlo", "value": "C3574"},
                {"label": "Eumundi", "value": "C3575"},
                {"label": "Everton Hills", "value": "C3576"},
                {"label": "Everton Park", "value": "C3577"},
                {"label": "Fairfield", "value": "C3578"},
                {"label": "Fernvale", "value": "C3579"},
                {"label": "Ferny Grove", "value": "C3580"},
                {"label": "Ferny Hills", "value": "C3581"},
                {"label": "Fig Tree Pocket", "value": "C3582"},
                {"label": "Fitzgibbon", "value": "C3583"},
                {"label": "Flinders", "value": "C3584"},
                {"label": "Flinders View", "value": "C3585"},
                {"label": "Forest Glen", "value": "C3586"},
                {"label": "Forest Lake", "value": "C3587"},
                {"label": "Forestdale", "value": "C3588"},
                {"label": "Fortitude Valley", "value": "C3589"},
                {"label": "Fraser Coast", "value": "C3590"},
                {"label": "Frenchville", "value": "C3591"},
                {"label": "Freshwater", "value": "C3592"},
                {"label": "Gailes", "value": "C3593"},
                {"label": "Garbutt", "value": "C3594"},
                {"label": "Gatton", "value": "C3595"},
                {"label": "Gaven", "value": "C3596"},
                {"label": "Gayndah", "value": "C3597"},
                {"label": "Gaythorne", "value": "C3598"},
                {"label": "Geebung", "value": "C3599"},
                {"label": "Gilston", "value": "C3600"},
                {"label": "Gin Gin", "value": "C3601"},
                {"label": "Gladstone", "value": "C3602"},
                {"label": "Gladstone Central", "value": "C3603"},
                {"label": "Glass House Mountains", "value": "C3604"},
                {"label": "Glen Eden", "value": "C3605"},
                {"label": "Gleneagle", "value": "C3606"},
                {"label": "Glenella", "value": "C3607"},
                {"label": "Glenvale", "value": "C3608"},
                {"label": "Glenview", "value": "C3609"},
                {"label": "Gold Coast", "value": "C3610"},
                {"label": "Golden Beach", "value": "C3611"},
                {"label": "Gooburrum", "value": "C3612"},
                {"label": "Goodna", "value": "C3613"},
                {"label": "Goondiwindi", "value": "C3614"},
                {"label": "Gordon Park", "value": "C3615"},
                {"label": "Gordonvale", "value": "C3616"},
                {"label": "Gowrie Junction", "value": "C3617"},
                {"label": "Gracemere", "value": "C3618"},
                {"label": "Graceville", "value": "C3619"},
                {"label": "Grange", "value": "C3620"},
                {"label": "Granville", "value": "C3621"},
                {"label": "Greenbank", "value": "C3622"},
                {"label": "Greenslopes", "value": "C3623"},
                {"label": "Griffin", "value": "C3624"},
                {"label": "Gulliver", "value": "C3625"},
                {"label": "Gumdale", "value": "C3626"},
                {"label": "Gympie", "value": "C3627"},
                {"label": "Gympie Regional Council", "value": "C3628"},
                {"label": "Hamilton", "value": "C3629"},
                {"label": "Harlaxton", "value": "C3630"},
                {"label": "Harristown", "value": "C3631"},
                {"label": "Hatton Vale", "value": "C3632"},
                {"label": "Hawthorne", "value": "C3633"},
                {"label": "Hay Point", "value": "C3634"},
                {"label": "Healy", "value": "C3635"},
                {"label": "Heathwood", "value": "C3636"},
                {"label": "Heatley", "value": "C3637"},
                {"label": "Helensvale", "value": "C3638"},
                {"label": "Helidon", "value": "C3639"},
                {"label": "Hemmant", "value": "C3640"},
                {"label": "Hendra", "value": "C3641"},
                {"label": "Heritage Park", "value": "C3642"},
                {"label": "Hermit Park", "value": "C3643"},
                {"label": "Herston", "value": "C3644"},
                {"label": "Hervey Bay", "value": "C3645"},
                {"label": "Highfields", "value": "C3646"},
                {"label": "Highgate Hill", "value": "C3647"},
                {"label": "Highland Park", "value": "C3648"},
                {"label": "Highvale", "value": "C3649"},
                {"label": "Hillcrest", "value": "C3650"},
                {"label": "Hinchinbrook", "value": "C3651"},
                {"label": "Hodgson Vale", "value": "C3652"},
                {"label": "Holland Park", "value": "C3653"},
                {"label": "Holland Park West", "value": "C3654"},
                {"label": "Holloways Beach", "value": "C3655"},
                {"label": "Hollywell", "value": "C3656"},
                {"label": "Holmview", "value": "C3657"},
                {"label": "Home Hill", "value": "C3658"},
                {"label": "Hope Island", "value": "C3659"},
                {"label": "Hope Vale", "value": "C3660"},
                {"label": "Howard", "value": "C3661"},
                {"label": "Hughenden", "value": "C3662"},
                {"label": "Hyde Park", "value": "C3663"},
                {"label": "Idalia", "value": "C3664"},
                {"label": "Inala", "value": "C3665"},
                {"label": "Indooroopilly", "value": "C3666"},
                {"label": "Ingham", "value": "C3667"},
                {"label": "Innes Park", "value": "C3668"},
                {"label": "Innisfail", "value": "C3669"},
                {"label": "Innisfail Estate", "value": "C3670"},
                {"label": "Ipswich", "value": "C3671"},
                {"label": "Isaac", "value": "C3672"},
                {"label": "Jacobs Well", "value": "C3673"},
                {"label": "Jamboree Heights", "value": "C3674"},
                {"label": "Jandowae", "value": "C3675"},
                {"label": "Jensen", "value": "C3676"},
                {"label": "Jimboomba", "value": "C3677"},
                {"label": "Jindalee", "value": "C3678"},
                {"label": "Joyner", "value": "C3679"},
                {"label": "Jubilee Pocket", "value": "C3680"},
                {"label": "Julatten", "value": "C3681"},
                {"label": "Kalbar", "value": "C3682"},
                {"label": "Kalinga", "value": "C3683"},
                {"label": "Kalkie", "value": "C3684"},
                {"label": "Kallangur", "value": "C3685"},
                {"label": "Kamerunga", "value": "C3686"},
                {"label": "Kangaroo Point", "value": "C3687"},
                {"label": "Kanimbla", "value": "C3688"},
                {"label": "Karalee", "value": "C3689"},
                {"label": "Karana Downs", "value": "C3690"},
                {"label": "Karumba", "value": "C3691"},
                {"label": "Kawana", "value": "C3692"},
                {"label": "Kawungan", "value": "C3693"},
                {"label": "Kearneys Spring", "value": "C3694"},
                {"label": "Kedron", "value": "C3695"},
                {"label": "Kelso", "value": "C3696"},
                {"label": "Kelvin Grove", "value": "C3697"},
                {"label": "Kenmore", "value": "C3698"},
                {"label": "Kenmore Hills", "value": "C3699"},
                {"label": "Kensington Grove", "value": "C3700"},
                {"label": "Keperra", "value": "C3701"},
                {"label": "Kepnock", "value": "C3702"},
                {"label": "Kewarra Beach", "value": "C3703"},
                {"label": "Kilcoy", "value": "C3704"},
                {"label": "Kin Kora", "value": "C3705"},
                {"label": "Kingaroy", "value": "C3706"},
                {"label": "Kings Beach", "value": "C3707"},
                {"label": "Kingsthorpe", "value": "C3708"},
                {"label": "Kingston", "value": "C3709"},
                {"label": "Kippa-Ring", "value": "C3710"},
                {"label": "Kirkwood", "value": "C3711"},
                {"label": "Kirwan", "value": "C3712"},
                {"label": "Kleinton", "value": "C3713"},
                {"label": "Koongal", "value": "C3714"},
                {"label": "Kooralbyn", "value": "C3715"},
                {"label": "Kowanyama", "value": "C3716"},
                {"label": "Kuluin", "value": "C3717"},
                {"label": "Kuraby", "value": "C3718"},
                {"label": "Kuranda", "value": "C3719"},
                {"label": "Kurwongbah", "value": "C3720"},
                {"label": "Labrador", "value": "C3721"},
                {"label": "Laidley", "value": "C3722"},
                {"label": "Lake Macdonald", "value": "C3723"},
                {"label": "Lammermoor", "value": "C3724"},
                {"label": "Landsborough", "value": "C3725"},
                {"label": "Lawnton", "value": "C3726"},
                {"label": "Leichhardt", "value": "C3727"},
                {"label": "Little Mountain", "value": "C3728"},
                {"label": "Lockhart River", "value": "C3729"},
                {"label": "Lockyer Valley", "value": "C3730"},
                {"label": "Logan", "value": "C3731"},
                {"label": "Logan Central", "value": "C3732"},
                {"label": "Logan City", "value": "C3733"},
                {"label": "Logan Reserve", "value": "C3734"},
                {"label": "Logan Village", "value": "C3735"},
                {"label": "Loganholme", "value": "C3736"},
                {"label": "Loganlea", "value": "C3737"},
                {"label": "Longreach", "value": "C3738"},
                {"label": "Lota", "value": "C3739"},
                {"label": "Lower Beechmont", "value": "C3740"},
                {"label": "Lowood", "value": "C3741"},
                {"label": "Lutwyche", "value": "C3742"},
                {"label": "Macgregor", "value": "C3743"},
                {"label": "Machans Beach", "value": "C3744"},
                {"label": "Mackay", "value": "C3745"},
                {"label": "Mackay City", "value": "C3746"},
                {"label": "Mackenzie", "value": "C3747"},
                {"label": "Macleay Island", "value": "C3748"},
                {"label": "Magnetic Island", "value": "C3749"},
                {"label": "Main Beach", "value": "C3750"},
                {"label": "Malanda", "value": "C3751"},
                {"label": "Maleny", "value": "C3752"},
                {"label": "Mango Hill", "value": "C3753"},
                {"label": "Manly West", "value": "C3754"},
                {"label": "Manoora", "value": "C3755"},
                {"label": "Mansfield", "value": "C3756"},
                {"label": "Manunda", "value": "C3757"},
                {"label": "Mapleton", "value": "C3758"},
                {"label": "Mapoon", "value": "C3759"},
                {"label": "Maranoa", "value": "C3760"},
                {"label": "Marcoola", "value": "C3761"},
                {"label": "Mareeba", "value": "C3762"},
                {"label": "Margate", "value": "C3763"},
                {"label": "Marian", "value": "C3764"},
                {"label": "Maroochy River", "value": "C3765"},
                {"label": "Maroochydore", "value": "C3766"},
                {"label": "Marsden", "value": "C3767"},
                {"label": "Maryborough", "value": "C3768"},
                {"label": "Maudsland", "value": "C3769"},
                {"label": "McDowall", "value": "C3770"},
                {"label": "McKinlay", "value": "C3771"},
                {"label": "Meadowbrook", "value": "C3772"},
                {"label": "Menzies", "value": "C3773"},
                {"label": "Meridan Plains", "value": "C3774"},
                {"label": "Meringandan West", "value": "C3775"},
                {"label": "Mermaid Beach", "value": "C3776"},
                {"label": "Mermaid Waters", "value": "C3777"},
                {"label": "Merrimac", "value": "C3778"},
                {"label": "Miami", "value": "C3779"},
                {"label": "Middle Park", "value": "C3780"},
                {"label": "Middle Ridge", "value": "C3781"},
                {"label": "Middlemount", "value": "C3782"},
                {"label": "Miles", "value": "C3783"},
                {"label": "Millbank", "value": "C3784"},
                {"label": "Millmerran", "value": "C3785"},
                {"label": "Millstream", "value": "C3786"},
                {"label": "Milton", "value": "C3787"},
                {"label": "Minden", "value": "C3788"},
                {"label": "Minyama", "value": "C3789"},
                {"label": "Mirani", "value": "C3790"},
                {"label": "Mission Beach", "value": "C3791"},
                {"label": "Mission River", "value": "C3792"},
                {"label": "Mitchell", "value": "C3793"},
                {"label": "Mitchelton", "value": "C3794"},
                {"label": "Moffat Beach", "value": "C3795"},
                {"label": "Moggill", "value": "C3796"},
                {"label": "Molendinar", "value": "C3797"},
                {"label": "Monkland", "value": "C3798"},
                {"label": "Monto", "value": "C3799"},
                {"label": "Montville", "value": "C3800"},
                {"label": "Mooloolaba", "value": "C3801"},
                {"label": "Moore Park Beach", "value": "C3802"},
                {"label": "Mooroobool", "value": "C3803"},
                {"label": "Moorooka", "value": "C3804"},
                {"label": "Moranbah", "value": "C3805"},
                {"label": "Morayfield", "value": "C3806"},
                {"label": "Moreton Bay", "value": "C3807"},
                {"label": "Morningside", "value": "C3808"},
                {"label": "Mornington", "value": "C3809"},
                {"label": "Mossman", "value": "C3810"},
                {"label": "Mount Coolum", "value": "C3811"},
                {"label": "Mount Cotton", "value": "C3812"},
                {"label": "Mount Crosby", "value": "C3813"},
                {"label": "Mount Gravatt", "value": "C3814"},
                {"label": "Mount Gravatt East", "value": "C3815"},
                {"label": "Mount Isa", "value": "C3816"},
                {"label": "Mount Lofty", "value": "C3817"},
                {"label": "Mount Louisa", "value": "C3818"},
                {"label": "Mount Low", "value": "C3819"},
                {"label": "Mount Morgan", "value": "C3820"},
                {"label": "Mount Nathan", "value": "C3821"},
                {"label": "Mount Ommaney", "value": "C3822"},
                {"label": "Mount Pleasant", "value": "C3823"},
                {"label": "Mount Sheridan", "value": "C3824"},
                {"label": "Mount Warren Park", "value": "C3825"},
                {"label": "Mountain Creek", "value": "C3826"},
                {"label": "Moura", "value": "C3827"},
                {"label": "Mudgeeraba", "value": "C3828"},
                {"label": "Mudjimba", "value": "C3829"},
                {"label": "Mulambin", "value": "C3830"},
                {"label": "Mundingburra", "value": "C3831"},
                {"label": "Mundoolun", "value": "C3832"},
                {"label": "Mundubbera", "value": "C3833"},
                {"label": "Munruben", "value": "C3834"},
                {"label": "Murarrie", "value": "C3835"},
                {"label": "Murgon", "value": "C3836"},
                {"label": "Murray", "value": "C3837"},
                {"label": "Murrumba Downs", "value": "C3838"},
                {"label": "Murweh", "value": "C3839"},
                {"label": "Nambour", "value": "C3840"},
                {"label": "Nanango", "value": "C3841"},
                {"label": "Nanum", "value": "C3842"},
                {"label": "Napranum", "value": "C3843"},
                {"label": "Narangba", "value": "C3844"},
                {"label": "Nathan", "value": "C3845"},
                {"label": "Nebo", "value": "C3846"},
                {"label": "Nelly Bay", "value": "C3847"},
                {"label": "Nerang", "value": "C3848"},
                {"label": "New Auckland", "value": "C3849"},
                {"label": "New Beith", "value": "C3850"},
                {"label": "New Farm", "value": "C3851"},
                {"label": "Newmarket", "value": "C3852"},
                {"label": "Newport", "value": "C3853"},
                {"label": "Newstead", "value": "C3854"},
                {"label": "Newtown", "value": "C3855"},
                {"label": "Ninderry", "value": "C3856"},
                {"label": "Ningi", "value": "C3857"},
                {"label": "Nome", "value": "C3858"},
                {"label": "Noosa Heads", "value": "C3859"},
                {"label": "Noosaville", "value": "C3860"},
                {"label": "Norman Gardens", "value": "C3861"},
                {"label": "Norman Park", "value": "C3862"},
                {"label": "Normanton", "value": "C3863"},
                {"label": "North Booval", "value": "C3864"},
                {"label": "North Burnett", "value": "C3865"},
                {"label": "North Ipswich", "value": "C3866"},
                {"label": "North Lakes", "value": "C3867"},
                {"label": "North Mackay", "value": "C3868"},
                {"label": "North Maclean", "value": "C3869"},
                {"label": "North Toowoomba", "value": "C3870"},
                {"label": "North Ward", "value": "C3871"},
                {"label": "Northern Peninsula Area", "value": "C3872"},
                {"label": "Norville", "value": "C3873"},
                {"label": "Nudgee", "value": "C3874"},
                {"label": "Nundah", "value": "C3875"},
                {"label": "Oakey", "value": "C3876"},
                {"label": "Oakhurst", "value": "C3877"},
                {"label": "One Mile", "value": "C3878"},
                {"label": "Oonoonba", "value": "C3879"},
                {"label": "Ooralea", "value": "C3880"},
                {"label": "Ormeau", "value": "C3881"},
                {"label": "Ormeau Hills", "value": "C3882"},
                {"label": "Ormiston", "value": "C3883"},
                {"label": "Oxenford", "value": "C3884"},
                {"label": "Oxley", "value": "C3885"},
                {"label": "Pacific Paradise", "value": "C3886"},
                {"label": "Pacific Pines", "value": "C3887"},
                {"label": "Paddington", "value": "C3888"},
                {"label": "Palm Beach", "value": "C3889"},
                {"label": "Palm Cove", "value": "C3890"},
                {"label": "Palm Island", "value": "C3891"},
                {"label": "Palmwoods", "value": "C3892"},
                {"label": "Paradise Point", "value": "C3893"},
                {"label": "Park Avenue", "value": "C3894"},
                {"label": "Park Ridge", "value": "C3895"},
                {"label": "Park Ridge South", "value": "C3896"},
                {"label": "Parkhurst", "value": "C3897"},
                {"label": "Parkinson", "value": "C3898"},
                {"label": "Parkside", "value": "C3899"},
                {"label": "Parkwood", "value": "C3900"},
                {"label": "Paroo", "value": "C3901"},
                {"label": "Parramatta Park", "value": "C3902"},
                {"label": "Parrearra", "value": "C3903"},
                {"label": "Peachester", "value": "C3904"},
                {"label": "Pelican Waters", "value": "C3905"},
                {"label": "Peregian Beach", "value": "C3906"},
                {"label": "Peregian Springs", "value": "C3907"},
                {"label": "Petrie", "value": "C3908"},
                {"label": "Petrie Terrace", "value": "C3909"},
                {"label": "Pialba", "value": "C3910"},
                {"label": "Pie Creek", "value": "C3911"},
                {"label": "Pimlico", "value": "C3912"},
                {"label": "Pimpama", "value": "C3913"},
                {"label": "Pine Mountain", "value": "C3914"},
                {"label": "Pioneer", "value": "C3915"},
                {"label": "Pittsworth", "value": "C3916"},
                {"label": "Plainland", "value": "C3917"},
                {"label": "Point Vernon", "value": "C3918"},
                {"label": "Pomona", "value": "C3919"},
                {"label": "Pormpuraaw", "value": "C3920"},
                {"label": "Port Douglas", "value": "C3921"},
                {"label": "Proserpine", "value": "C3922"},
                {"label": "Pullenvale", "value": "C3923"},
                {"label": "Queenton", "value": "C3924"},
                {"label": "Quilpie", "value": "C3925"},
                {"label": "Raceview", "value": "C3926"},
                {"label": "Railway Estate", "value": "C3927"},
                {"label": "Rainbow Beach", "value": "C3928"},
                {"label": "Rangeville", "value": "C3929"},
                {"label": "Rangewood", "value": "C3930"},
                {"label": "Rasmussen", "value": "C3931"},
                {"label": "Ravenshoe", "value": "C3932"},
                {"label": "Red Hill", "value": "C3933"},
                {"label": "Redbank", "value": "C3934"},
                {"label": "Redbank Plains", "value": "C3935"},
                {"label": "Redland", "value": "C3936"},
                {"label": "Redland Bay", "value": "C3937"},
                {"label": "Redlynch", "value": "C3938"},
                {"label": "Reedy Creek", "value": "C3939"},
                {"label": "Regency Downs", "value": "C3940"},
                {"label": "Regents Park", "value": "C3941"},
                {"label": "Richlands", "value": "C3942"},
                {"label": "Richmond", "value": "C3943"},
                {"label": "River Heads", "value": "C3944"},
                {"label": "Riverhills", "value": "C3945"},
                {"label": "Riverview", "value": "C3946"},
                {"label": "Robertson", "value": "C3947"},
                {"label": "Robina", "value": "C3948"},
                {"label": "Rochedale", "value": "C3949"},
                {"label": "Rochedale South", "value": "C3950"},
                {"label": "Rockhampton", "value": "C3951"},
                {"label": "Rocklea", "value": "C3952"},
                {"label": "Rockville", "value": "C3953"},
                {"label": "Roma", "value": "C3954"},
                {"label": "Rosemount", "value": "C3955"},
                {"label": "Rosenthal Heights", "value": "C3956"},
                {"label": "Rosewood", "value": "C3957"},
                {"label": "Rosslea", "value": "C3958"},
                {"label": "Rothwell", "value": "C3959"},
                {"label": "Runaway Bay", "value": "C3960"},
                {"label": "Runcorn", "value": "C3961"},
                {"label": "Rural View", "value": "C3962"},
                {"label": "Russell Island", "value": "C3963"},
                {"label": "Sadliers Crossing", "value": "C3964"},
                {"label": "Salisbury", "value": "C3965"},
                {"label": "Samford Valley", "value": "C3966"},
                {"label": "Sandgate", "value": "C3967"},
                {"label": "Sandstone Point", "value": "C3968"},
                {"label": "Sarina", "value": "C3969"},
                {"label": "Scarborough", "value": "C3970"},
                {"label": "Scarness", "value": "C3971"},
                {"label": "Scenic Rim", "value": "C3972"},
                {"label": "Seven Hills", "value": "C3973"},
                {"label": "Seventeen Mile Rocks", "value": "C3974"},
                {"label": "Shailer Park", "value": "C3975"},
                {"label": "Sharon", "value": "C3976"},
                {"label": "Sheldon", "value": "C3977"},
                {"label": "Shorncliffe", "value": "C3978"},
                {"label": "Silkstone", "value": "C3979"},
                {"label": "Sinnamon Park", "value": "C3980"},
                {"label": "Sippy Downs", "value": "C3981"},
                {"label": "Slacks Creek", "value": "C3982"},
                {"label": "Slade Point", "value": "C3983"},
                {"label": "Soldiers Hill", "value": "C3984"},
                {"label": "Somerset", "value": "C3985"},
                {"label": "South Brisbane", "value": "C3986"},
                {"label": "South Burnett", "value": "C3987"},
                {"label": "South Gladstone", "value": "C3988"},
                {"label": "South Kolan", "value": "C3989"},
                {"label": "South Mackay", "value": "C3990"},
                {"label": "South Maclean", "value": "C3991"},
                {"label": "South Toowoomba", "value": "C3992"},
                {"label": "South Townsville", "value": "C3993"},
                {"label": "Southern Downs", "value": "C3994"},
                {"label": "Southport", "value": "C3995"},
                {"label": "Southside", "value": "C3996"},
                {"label": "Spring Hill", "value": "C3997"},
                {"label": "Springfield", "value": "C3998"},
                {"label": "Springfield Lakes", "value": "C3999"},
                {"label": "Springsure", "value": "C4000"},
                {"label": "Springwood", "value": "C4001"},
                {"label": "St George", "value": "C4002"},
                {"label": "Stafford", "value": "C4003"},
                {"label": "Stafford Heights", "value": "C4004"},
                {"label": "Stanthorpe", "value": "C4005"},
                {"label": "Strathpine", "value": "C4006"},
                {"label": "Stretton", "value": "C4007"},
                {"label": "Stuart", "value": "C4008"},
                {"label": "Sun Valley", "value": "C4009"},
                {"label": "Sunnybank", "value": "C4010"},
                {"label": "Sunnybank Hills", "value": "C4011"},
                {"label": "Sunrise Beach", "value": "C4012"},
                {"label": "Sunset", "value": "C4013"},
                {"label": "Sunshine Beach", "value": "C4014"},
                {"label": "Sunshine Coast", "value": "C4015"},
                {"label": "Surfers Paradise", "value": "C4016"},
                {"label": "Svensson Heights", "value": "C4017"},
                {"label": "Taigum", "value": "C4018"},
                {"label": "Tallai", "value": "C4019"},
                {"label": "Tallebudgera", "value": "C4020"},
                {"label": "Tallebudgera Valley", "value": "C4021"},
                {"label": "Tamborine", "value": "C4022"},
                {"label": "Tamborine Mountain", "value": "C4023"},
                {"label": "Tanah Merah", "value": "C4024"},
                {"label": "Tanawha", "value": "C4025"},
                {"label": "Tannum Sands", "value": "C4026"},
                {"label": "Tara", "value": "C4027"},
                {"label": "Taranganba", "value": "C4028"},
                {"label": "Taringa", "value": "C4029"},
                {"label": "Tarragindi", "value": "C4030"},
                {"label": "Telina", "value": "C4031"},
                {"label": "Teneriffe", "value": "C4032"},
                {"label": "Tewantin", "value": "C4033"},
                {"label": "Thabeban", "value": "C4034"},
                {"label": "The Gap", "value": "C4035"},
                {"label": "The Gemfields", "value": "C4036"},
                {"label": "The Range", "value": "C4037"},
                {"label": "Thorneside", "value": "C4038"},
                {"label": "Thornlands", "value": "C4039"},
                {"label": "Thursday Island", "value": "C4040"},
                {"label": "Tieri", "value": "C4041"},
                {"label": "Tin Can Bay", "value": "C4042"},
                {"label": "Tinana", "value": "C4043"},
                {"label": "Tingalpa", "value": "C4044"},
                {"label": "Tivoli", "value": "C4045"},
                {"label": "Tolga", "value": "C4046"},
                {"label": "Toogoolawah", "value": "C4047"},
                {"label": "Toogoom", "value": "C4048"},
                {"label": "Toowong", "value": "C4049"},
                {"label": "Toowoomba", "value": "C4050"},
                {"label": "Torquay", "value": "C4051"},
                {"label": "Torres", "value": "C4052"},
                {"label": "Torres Strait Island Region", "value": "C4053"},
                {"label": "Townsville", "value": "C4054"},
                {"label": "Townview", "value": "C4055"},
                {"label": "Trinity Beach", "value": "C4056"},
                {"label": "Trinity Park", "value": "C4057"},
                {"label": "Trunding", "value": "C4058"},
                {"label": "Tugun", "value": "C4059"},
                {"label": "Tully", "value": "C4060"},
                {"label": "Twin Waters", "value": "C4061"},
                {"label": "Underwood", "value": "C4062"},
                {"label": "Upper Caboolture", "value": "C4063"},
                {"label": "Upper Coomera", "value": "C4064"},
                {"label": "Upper Kedron", "value": "C4065"},
                {"label": "Upper Mount Gravatt", "value": "C4066"},
                {"label": "Urangan", "value": "C4067"},
                {"label": "Urraween", "value": "C4068"},
                {"label": "Varsity Lakes", "value": "C4069"},
                {"label": "Victoria Point", "value": "C4070"},
                {"label": "Vincent", "value": "C4071"},
                {"label": "Virginia", "value": "C4072"},
                {"label": "Wacol", "value": "C4073"},
                {"label": "Wakerley", "value": "C4074"},
                {"label": "Walkerston", "value": "C4075"},
                {"label": "Walkervale", "value": "C4076"},
                {"label": "Walloon", "value": "C4077"},
                {"label": "Wamuran", "value": "C4078"},
                {"label": "Wandal", "value": "C4079"},
                {"label": "Wandoan", "value": "C4080"},
                {"label": "Warana", "value": "C4081"},
                {"label": "Warner", "value": "C4082"},
                {"label": "Warwick", "value": "C4083"},
                {"label": "Waterford West", "value": "C4084"},
                {"label": "Wavell Heights", "value": "C4085"},
                {"label": "Weipa", "value": "C4086"},
                {"label": "Wellesley Islands", "value": "C4087"},
                {"label": "Wellington Point", "value": "C4088"},
                {"label": "West End", "value": "C4089"},
                {"label": "West Gladstone", "value": "C4090"},
                {"label": "West Mackay", "value": "C4091"},
                {"label": "West Rockhampton", "value": "C4092"},
                {"label": "West Woombye", "value": "C4093"},
                {"label": "Westbrook", "value": "C4094"},
                {"label": "Westcourt", "value": "C4095"},
                {"label": "Western Downs", "value": "C4096"},
                {"label": "Westlake", "value": "C4097"},
                {"label": "White Rock", "value": "C4098"},
                {"label": "Whitfield", "value": "C4099"},
                {"label": "Whitsunday", "value": "C4100"},
                {"label": "Whitsundays", "value": "C4101"},
                {"label": "Willow Vale", "value": "C4102"},
                {"label": "Willowbank", "value": "C4103"},
                {"label": "Wilsonton", "value": "C4104"},
                {"label": "Wilsonton Heights", "value": "C4105"},
                {"label": "Wilston", "value": "C4106"},
                {"label": "Windaroo", "value": "C4107"},
                {"label": "Windsor", "value": "C4108"},
                {"label": "Winston", "value": "C4109"},
                {"label": "Winton", "value": "C4110"},
                {"label": "Wishart", "value": "C4111"},
                {"label": "Withcott", "value": "C4112"},
                {"label": "Witta", "value": "C4113"},
                {"label": "Wondai", "value": "C4114"},
                {"label": "Wondunna", "value": "C4115"},
                {"label": "Wongaling Beach", "value": "C4116"},
                {"label": "Wongawallan", "value": "C4117"},
                {"label": "Woodend", "value": "C4118"},
                {"label": "Woodford", "value": "C4119"},
                {"label": "Woodgate", "value": "C4120"},
                {"label": "Woodridge", "value": "C4121"},
                {"label": "Woody Point", "value": "C4122"},
                {"label": "Woolloongabba", "value": "C4123"},
                {"label": "Wooloowin", "value": "C4124"},
                {"label": "Woombye", "value": "C4125"},
                {"label": "Woorabinda", "value": "C4126"},
                {"label": "Woorim", "value": "C4127"},
                {"label": "Woree", "value": "C4128"},
                {"label": "Worongary", "value": "C4129"},
                {"label": "Wujal Wujal", "value": "C4130"},
                {"label": "Wulguru", "value": "C4131"},
                {"label": "Wulkuraka", "value": "C4132"},
                {"label": "Wurtulla", "value": "C4133"},
                {"label": "Wynnum", "value": "C4134"},
                {"label": "Wynnum West", "value": "C4135"},
                {"label": "Wyreema", "value": "C4136"},
                {"label": "Yamanto", "value": "C4137"},
                {"label": "Yandina", "value": "C4138"},
                {"label": "Yaroomba", "value": "C4139"},
                {"label": "Yarrabah", "value": "C4140"},
                {"label": "Yarrabilba", "value": "C4141"},
                {"label": "Yarraman", "value": "C4142"},
                {"label": "Yatala", "value": "C4143"},
                {"label": "Yeerongpilly", "value": "C4144"},
                {"label": "Yeppoon", "value": "C4145"},
                {"label": "Yeppoon city centre", "value": "C4146"},
                {"label": "Yeronga", "value": "C4147"},
                {"label": "Yorkeys Knob", "value": "C4148"},
                {"label": "Yungaburra", "value": "C4149"},
                {"label": "Zillmere", "value": "C4150"},
                {"label": "Zilzie", "value": "C4151"}],
 "Queretaro": [{"label": "Agua Azul", "value": "C72962"},
               {"label": "Agua Fria", "value": "C72963"},
               {"label": "Agua Zarca", "value": "C72964"},
               {"label": "Ahuacatlan de Guadalupe", "value": "C72965"},
               {"label": "Ajuchitlan", "value": "C72966"},
               {"label": "Ajuchitlancito", "value": "C72967"},
               {"label": "Alfajayucan", "value": "C72968"},
               {"label": "Amazcala", "value": "C72969"},
               {"label": "Amealco", "value": "C72970"},
               {"label": "Apapataro", "value": "C72971"},
               {"label": "Arcila", "value": "C72972"},
               {"label": "Arroyo Seco", "value": "C72973"},
               {"label": "Atongo", "value": "C72974"},
               {"label": "Bella Vista del Rio", "value": "C72975"},
               {"label": "Bernal", "value": "C72976"},
               {"label": "Bordo Blanco", "value": "C72977"},
               {"label": "Boxasni", "value": "C72978"},
               {"label": "Boye", "value": "C72979"},
               {"label": "Bravo", "value": "C72980"},
               {"label": "Buenavista", "value": "C72981"},
               {"label": "Cadereyta", "value": "C72982"},
               {"label": "Calamanda", "value": "C72983"},
               {"label": "Carbonera", "value": "C72984"},
               {"label": "Casa Blanca", "value": "C72985"},
               {"label": "Ceja de Bravo", "value": "C72986"},
               {"label": "Cerro de la Cruz", "value": "C72987"},
               {"label": "Cerro Gordo", "value": "C72988"},
               {"label": "Charco Blanco", "value": "C72989"},
               {"label": "Chichimequillas", "value": "C72990"},
               {"label": "Colinas de Santa Cruz Segunda Seccion",
                "value": "C72991"},
               {"label": "Colon", "value": "C72992"},
               {"label": "Colonia los angeles", "value": "C72993"},
               {"label": "Conca", "value": "C72994"},
               {"label": "Coyotillos", "value": "C72995"},
               {"label": "Dolores Cuadrilla de Enmedio", "value": "C72996"},
               {"label": "El Blanco", "value": "C72997"},
               {"label": "El Carmen", "value": "C72998"},
               {"label": "El Cazadero", "value": "C72999"},
               {"label": "El Ciervo", "value": "C73000"},
               {"label": "El Colorado", "value": "C73001"},
               {"label": "El Coto", "value": "C73002"},
               {"label": "El Gallo", "value": "C73003"},
               {"label": "El Jaral", "value": "C73004"},
               {"label": "El Jazmin", "value": "C73005"},
               {"label": "El Lobo", "value": "C73006"},
               {"label": "El Milagro", "value": "C73007"},
               {"label": "El Nabo", "value": "C73008"},
               {"label": "El Organal", "value": "C73009"},
               {"label": "El Palmar", "value": "C73010"},
               {"label": "El Paraiso", "value": "C73011"},
               {"label": "El Pinto", "value": "C73012"},
               {"label": "El Pozo", "value": "C73013"},
               {"label": "El Pueblito", "value": "C73014"},
               {"label": "El Rincon", "value": "C73015"},
               {"label": "El Rosario", "value": "C73016"},
               {"label": "El Salitre", "value": "C73017"},
               {"label": "El Sauz", "value": "C73018"},
               {"label": "El Tejocote", "value": "C73019"},
               {"label": "El Vegil", "value": "C73020"},
               {"label": "Epigmenio Gonzalez", "value": "C73021"},
               {"label": "Escolasticas", "value": "C73022"},
               {"label": "Esperanza", "value": "C73023"},
               {"label": "Ezequiel Montes", "value": "C73024"},
               {"label": "Fuentezuelas", "value": "C73025"},
               {"label": "Galeras", "value": "C73026"},
               {"label": "Galindo", "value": "C73027"},
               {"label": "General Lazaro Cardenas (El Colorado)",
                "value": "C73028"},
               {"label": "Guadalupe Septien", "value": "C73029"},
               {"label": "Hacienda la Cruz [Fraccionamiento]",
                "value": "C73030"},
               {"label": "Higuerillas", "value": "C73031"},
               {"label": "Huimilpan", "value": "C73032"},
               {"label": "Ignacio Perez", "value": "C73033"},
               {"label": "Jalpan", "value": "C73034"},
               {"label": "Jesus Maria", "value": "C73035"},
               {"label": "Joaquin Herrera", "value": "C73036"},
               {"label": "Jofrito", "value": "C73037"},
               {"label": "Juriquilla", "value": "C73038"},
               {"label": "La Barreta", "value": "C73039"},
               {"label": "La Canada", "value": "C73040"},
               {"label": "La Ceja", "value": "C73041"},
               {"label": "La D", "value": "C73042"},
               {"label": "La Estacada", "value": "C73043"},
               {"label": "La Estancia", "value": "C73044"},
               {"label": "La Fuente", "value": "C73045"},
               {"label": "La Gotera", "value": "C73046"},
               {"label": "La Griega", "value": "C73047"},
               {"label": "La Laja", "value": "C73048"},
               {"label": "La Lira", "value": "C73049"},
               {"label": "La Llave", "value": "C73050"},
               {"label": "La Loma", "value": "C73051"},
               {"label": "La Luz", "value": "C73052"},
               {"label": "La Monja", "value": "C73053"},
               {"label": "La Negreta", "value": "C73054"},
               {"label": "La Noria", "value": "C73055"},
               {"label": "La Palma", "value": "C73056"},
               {"label": "La Piedad", "value": "C73057"},
               {"label": "La Pradera", "value": "C73058"},
               {"label": "La Solana", "value": "C73059"},
               {"label": "La Soledad", "value": "C73060"},
               {"label": "La Tortuga", "value": "C73061"},
               {"label": "La Trinidad", "value": "C73062"},
               {"label": "La Valla", "value": "C73063"},
               {"label": "La Versolilla", "value": "C73064"},
               {"label": "Laguna de Vaquerias", "value": "C73065"},
               {"label": "Lagunillas", "value": "C73066"},
               {"label": "Landa de Matamoros", "value": "C73067"},
               {"label": "Las Lajitas", "value": "C73068"},
               {"label": "Las Taponas", "value": "C73069"},
               {"label": "Loma Linda", "value": "C73070"},
               {"label": "Los Banos", "value": "C73071"},
               {"label": "Los Benitos", "value": "C73072"},
               {"label": "Los Cerritos", "value": "C73073"},
               {"label": "Los Cues", "value": "C73074"},
               {"label": "Los Olvera", "value": "C73075"},
               {"label": "Los Pocitos", "value": "C73076"},
               {"label": "Lourdes", "value": "C73077"},
               {"label": "Mesillas", "value": "C73078"},
               {"label": "Mompani", "value": "C73079"},
               {"label": "Montenegro", "value": "C73080"},
               {"label": "Ninguno [CERESO]", "value": "C73081"},
               {"label": "Noria Nueva", "value": "C73082"},
               {"label": "Ojo de Agua", "value": "C73083"},
               {"label": "Palmillas", "value": "C73084"},
               {"label": "Palo Alto", "value": "C73085"},
               {"label": "Paseos del Marques", "value": "C73086"},
               {"label": "Paseos del Pedregal", "value": "C73087"},
               {"label": "Paso de Mata", "value": "C73088"},
               {"label": "Patria Nueva", "value": "C73089"},
               {"label": "Pedro Escobedo", "value": "C73090"},
               {"label": "Penamiller", "value": "C73091"},
               {"label": "Pie de Gallo", "value": "C73092"},
               {"label": "Pinal de Amoles", "value": "C73093"},
               {"label": "Pintillo", "value": "C73094"},
               {"label": "Presa de Rayas", "value": "C73095"},
               {"label": "Puerta de Palmillas", "value": "C73096"},
               {"label": "Puerto de Aguirre", "value": "C73097"},
               {"label": "Puerto de Carroza", "value": "C73098"},
               {"label": "Puerto de Chiquihuite", "value": "C73099"},
               {"label": "Puerto de Nieto", "value": "C73100"},
               {"label": "Purisima de Arista", "value": "C73101"},
               {"label": "Purisima de Cubos (La Purisima)", "value": "C73102"},
               {"label": "Queretaro", "value": "C73103"},
               {"label": "Rancho Bellavista [Fraccionamiento]",
                "value": "C73104"},
               {"label": "Saldarriaga", "value": "C73105"},
               {"label": "San Antonio de la Cal", "value": "C73106"},
               {"label": "San Clemente", "value": "C73107"},
               {"label": "San Francisco de la Palma", "value": "C73108"},
               {"label": "San Ildefonso", "value": "C73109"},
               {"label": "San Ildefonso Tultepec", "value": "C73110"},
               {"label": "San Isidro Buenavista", "value": "C73111"},
               {"label": "San Isidro Miranda", "value": "C73112"},
               {"label": "San Javier", "value": "C73113"},
               {"label": "San Jose Buenavista", "value": "C73114"},
               {"label": "San Jose de la Laja", "value": "C73115"},
               {"label": "San Jose de los Olvera", "value": "C73116"},
               {"label": "San Jose del Jaguey", "value": "C73117"},
               {"label": "San Jose el Alto", "value": "C73118"},
               {"label": "San Jose Itho", "value": "C73119"},
               {"label": "San Jose Tepuzas", "value": "C73120"},
               {"label": "San Juan del Rio", "value": "C73121"},
               {"label": "San Martin", "value": "C73122"},
               {"label": "San Martin Florida", "value": "C73123"},
               {"label": "San Miguel Amazcala", "value": "C73124"},
               {"label": "San Miguelito", "value": "C73125"},
               {"label": "San Nicolas", "value": "C73126"},
               {"label": "San Nicolas de la Torre", "value": "C73127"},
               {"label": "San Pablo Toliman", "value": "C73128"},
               {"label": "San Pedro", "value": "C73129"},
               {"label": "San Pedro Martir", "value": "C73130"},
               {"label": "San Rafael", "value": "C73131"},
               {"label": "San Sebastian de Las Barrancas Sur",
                "value": "C73132"},
               {"label": "San Vicente el Alto", "value": "C73133"},
               {"label": "San Vicente Ferrer", "value": "C73134"},
               {"label": "Santa Barbara de La Cueva", "value": "C73135"},
               {"label": "Santa Catarina", "value": "C73136"},
               {"label": "Santa Cruz", "value": "C73137"},
               {"label": "Santa Cruz Escandon", "value": "C73138"},
               {"label": "Santa Maria Begona", "value": "C73139"},
               {"label": "Santa Maria Magdalena", "value": "C73140"},
               {"label": "Santa Matilde", "value": "C73141"},
               {"label": "Santa Rosa de Lima", "value": "C73142"},
               {"label": "Santa Rosa Jauregui", "value": "C73143"},
               {"label": "Santa Rosa Xajay", "value": "C73144"},
               {"label": "Santiago de Queretaro", "value": "C73145"},
               {"label": "Santiago Mexquititlan Barrio 4to.",
                "value": "C73146"},
               {"label": "Santillan", "value": "C73147"},
               {"label": "Sergio Villasenor", "value": "C73148"},
               {"label": "Tequisquiapan", "value": "C73149"},
               {"label": "Tierra Blanca", "value": "C73150"},
               {"label": "Tlacote el Bajo", "value": "C73151"},
               {"label": "Toliman", "value": "C73152"},
               {"label": "Urecho", "value": "C73153"},
               {"label": "Venceremos", "value": "C73154"},
               {"label": "Viborillas", "value": "C73155"},
               {"label": "Villa Guerrero", "value": "C73156"},
               {"label": "Villa Progreso", "value": "C73157"},
               {"label": "Villas Fundadores [Fraccionamiento]",
                "value": "C73158"},
               {"label": "Vista Hermosa (Cuasinada)", "value": "C73159"},
               {"label": "Vistha", "value": "C73160"},
               {"label": "Vizarron", "value": "C73161"},
               {"label": "Yosphi", "value": "C73162"},
               {"label": "Zamorano", "value": "C73163"}],
 "Quetzaltenango Department": [{"label": "Almolonga", "value": "C42785"},
                               {"label": "Cabrican", "value": "C42786"},
                               {"label": "Cajola", "value": "C42787"},
                               {"label": "Cantel", "value": "C42788"},
                               {"label": "Coatepeque", "value": "C42789"},
                               {"label": "Colomba", "value": "C42790"},
                               {"label": "Concepcion Chiquirichapa",
                                "value": "C42791"},
                               {"label": "El Palmar", "value": "C42792"},
                               {"label": "Flores Costa Cuca",
                                "value": "C42793"},
                               {"label": "Genova", "value": "C42794"},
                               {"label": "Huitan", "value": "C42795"},
                               {"label": "La Esperanza", "value": "C42796"},
                               {"label": "Municipio de Almolonga",
                                "value": "C42797"},
                               {"label": "Municipio de Cabrican",
                                "value": "C42798"},
                               {"label": "Municipio de Cantel",
                                "value": "C42799"},
                               {"label": "Municipio de Coatepeque",
                                "value": "C42800"},
                               {"label": "Municipio de Colomba",
                                "value": "C42801"},
                               {"label": "Municipio de Concepcion Chiquirichapa",
                                "value": "C42802"},
                               {"label": "Municipio de Flores Costa Cuca",
                                "value": "C42803"},
                               {"label": "Municipio de San Juan Ostuncalco",
                                "value": "C42804"},
                               {"label": "Olintepeque", "value": "C42805"},
                               {"label": "Ostuncalco", "value": "C42806"},
                               {"label": "Palestina de los Altos",
                                "value": "C42807"},
                               {"label": "Quetzaltenango", "value": "C42808"},
                               {"label": "Salcaja", "value": "C42809"},
                               {"label": "Samayac", "value": "C42810"},
                               {"label": "San Carlos Sija", "value": "C42811"},
                               {"label": "San Francisco la Union",
                                "value": "C42812"},
                               {"label": "San Martin Sacatepequez",
                                "value": "C42813"},
                               {"label": "San Mateo", "value": "C42814"},
                               {"label": "San Miguel Siguila",
                                "value": "C42815"},
                               {"label": "Sibilia", "value": "C42816"},
                               {"label": "Zunil", "value": "C42817"}],
 "Quiche Department": [{"label": "Canilla", "value": "C42818"},
                       {"label": "Chajul", "value": "C42819"},
                       {"label": "Chicaman", "value": "C42820"},
                       {"label": "Chiche", "value": "C42821"},
                       {"label": "Chichicastenango", "value": "C42822"},
                       {"label": "Chinique", "value": "C42823"},
                       {"label": "Cunen", "value": "C42824"},
                       {"label": "Joyabaj", "value": "C42825"},
                       {"label": "Municipio de Canilla", "value": "C42826"},
                       {"label": "Municipio de Chajul", "value": "C42827"},
                       {"label": "Municipio de Chicaman", "value": "C42828"},
                       {"label": "Municipio de Chiche", "value": "C42829"},
                       {"label": "Municipio de Chichicastenango",
                        "value": "C42830"},
                       {"label": "Municipio de Chinique", "value": "C42831"},
                       {"label": "Municipio de Cunen", "value": "C42832"},
                       {"label": "Municipio de Ixcan", "value": "C42833"},
                       {"label": "Municipio de Joyabaj", "value": "C42834"},
                       {"label": "Municipio de Pachalum", "value": "C42835"},
                       {"label": "Municipio de Patzite", "value": "C42836"},
                       {"label": "Municipio de San Andres Sajcabaja",
                        "value": "C42837"},
                       {"label": "Municipio de San Antonio Ilotenango",
                        "value": "C42838"},
                       {"label": "Municipio de San Juan Cotzal",
                        "value": "C42839"},
                       {"label": "Municipio de San Pedro Jocopilas",
                        "value": "C42840"},
                       {"label": "Municipio de Uspantan", "value": "C42841"},
                       {"label": "Municipio de Zacualpa", "value": "C42842"},
                       {"label": "Nebaj", "value": "C42843"},
                       {"label": "Pachalum", "value": "C42844"},
                       {"label": "Patzite", "value": "C42845"},
                       {"label": "Playa Grande", "value": "C42846"},
                       {"label": "Sacapulas", "value": "C42847"},
                       {"label": "San Andres Sajcabaja", "value": "C42848"},
                       {"label": "San Antonio Ilotenango", "value": "C42849"},
                       {"label": "San Bartolome Jocotenango",
                        "value": "C42850"},
                       {"label": "San Juan Cotzal", "value": "C42851"},
                       {"label": "San Luis Ixcan", "value": "C42852"},
                       {"label": "San Pedro Jocopilas", "value": "C42853"},
                       {"label": "Santa Cruz del Quiche", "value": "C42854"},
                       {"label": "Uspantan", "value": "C42855"},
                       {"label": "Zacualpa", "value": "C42856"}],
 "Quinara Region": [{"label": "Buba", "value": "C43062"}],
 "Quindio": [{"label": "Armenia", "value": "C20420"},
             {"label": "Buenavista", "value": "C20421"},
             {"label": "Calarca", "value": "C20422"},
             {"label": "Circasia", "value": "C20423"},
             {"label": "Cordoba", "value": "C20424"},
             {"label": "Filandia", "value": "C20425"},
             {"label": "Genova", "value": "C20426"},
             {"label": "La Tebaida", "value": "C20427"},
             {"label": "Montenegro", "value": "C20428"},
             {"label": "Pijao", "value": "C20429"},
             {"label": "Quimbaya", "value": "C20430"},
             {"label": "Salento", "value": "C20431"}],
 "Quintana Roo": [{"label": "Akumal", "value": "C73164"},
                  {"label": "Alfredo V. Bonfil", "value": "C73165"},
                  {"label": "alvaro Obregon", "value": "C73166"},
                  {"label": "Bacalar", "value": "C73167"},
                  {"label": "Benito Juarez", "value": "C73168"},
                  {"label": "Cacao", "value": "C73169"},
                  {"label": "Calderitas", "value": "C73170"},
                  {"label": "Cancun", "value": "C73171"},
                  {"label": "Caobas", "value": "C73172"},
                  {"label": "Carlos A. Madrazo", "value": "C73173"},
                  {"label": "Chetumal", "value": "C73174"},
                  {"label": "Chiquila", "value": "C73175"},
                  {"label": "Chunhuhub", "value": "C73176"},
                  {"label": "Ciudad Chemuyil", "value": "C73177"},
                  {"label": "Coba", "value": "C73178"},
                  {"label": "Cocoyol", "value": "C73179"},
                  {"label": "Cozumel", "value": "C73180"},
                  {"label": "Dziuche", "value": "C73181"},
                  {"label": "Dzula", "value": "C73182"},
                  {"label": "El Tintal", "value": "C73183"},
                  {"label": "Felipe Carrillo Puerto", "value": "C73184"},
                  {"label": "Huay Max", "value": "C73185"},
                  {"label": "Huay-Pix", "value": "C73186"},
                  {"label": "Ignacio Zaragoza", "value": "C73187"},
                  {"label": "Isla Holbox", "value": "C73188"},
                  {"label": "Isla Mujeres", "value": "C73189"},
                  {"label": "Javier Rojo Gomez", "value": "C73190"},
                  {"label": "Jose Maria Morelos", "value": "C73191"},
                  {"label": "Jose Narciso Rovirosa", "value": "C73192"},
                  {"label": "Juan Sarabia", "value": "C73193"},
                  {"label": "Kancabchen", "value": "C73194"},
                  {"label": "Kantunilkin", "value": "C73195"},
                  {"label": "La Presumida", "value": "C73196"},
                  {"label": "La Union", "value": "C73197"},
                  {"label": "Lazaro Cardenas", "value": "C73198"},
                  {"label": "Leona Vicario", "value": "C73199"},
                  {"label": "Los Divorciados", "value": "C73200"},
                  {"label": "Maya Balam", "value": "C73201"},
                  {"label": "Morocoy", "value": "C73202"},
                  {"label": "Nicolas Bravo", "value": "C73203"},
                  {"label": "Nuevo Xcan", "value": "C73204"},
                  {"label": "Othon P. Blanco", "value": "C73205"},
                  {"label": "Playa del Carmen", "value": "C73206"},
                  {"label": "Polyuc", "value": "C73207"},
                  {"label": "Presidente Juarez", "value": "C73208"},
                  {"label": "Pucte", "value": "C73209"},
                  {"label": "Puerto Aventuras", "value": "C73210"},
                  {"label": "Saban", "value": "C73211"},
                  {"label": "Sabidos", "value": "C73212"},
                  {"label": "San Angel", "value": "C73213"},
                  {"label": "Santa Rosa Segundo", "value": "C73214"},
                  {"label": "Senor", "value": "C73215"},
                  {"label": "Sergio Butron Casas", "value": "C73216"},
                  {"label": "Subteniente Lopez", "value": "C73217"},
                  {"label": "Tepich", "value": "C73218"},
                  {"label": "Tihosuco", "value": "C73219"},
                  {"label": "Tulum", "value": "C73220"},
                  {"label": "Ucum", "value": "C73221"},
                  {"label": "X Cabil", "value": "C73222"},
                  {"label": "X-Hazil Sur", "value": "C73223"},
                  {"label": "X-pichil", "value": "C73224"},
                  {"label": "Xul-Ha", "value": "C73225"},
                  {"label": "Zacalaca", "value": "C73226"},
                  {"label": "Zona Urbana Ejido Isla Mujeres",
                   "value": "C73227"}],
 "Quneitra Governorate": [{"label": "Al Qunaytirah", "value": "C117929"}],
 "Qusar District": [{"label": "Qusar", "value": "C8499"},
                    {"label": "Samur", "value": "C8500"}],
 "Quthing District": [{"label": "Quthing", "value": "C65000"}],
 "Raa Atoll": [{"label": "Ugoofaaru", "value": "C65817"}],
 "Race-Fram Municipality": [{"label": "Fram", "value": "C107772"},
                            {"label": "Morje", "value": "C107773"},
                            {"label": "Race", "value": "C107774"}],
 "Racha-Lechkhumi and Kvemo Svaneti": [{"label": "Ambrolauri",
                                        "value": "C34328"},
                                       {"label": "Ambrolauris Munitsipaliteti",
                                        "value": "C34329"},
                                       {"label": "Lentekhi", "value": "C34330"},
                                       {"label": "Oni", "value": "C34331"}],
 "Radece Municipality": [{"label": "Radece", "value": "C107775"}],
 "Radenci Municipality": [{"label": "Radenci", "value": "C107776"}],
 "Radlje ob Dravi Municipality": [{"label": "Radlje ob Dravi",
                                   "value": "C107777"}],
 "Radovis Municipality": [{"label": "Oraovica", "value": "C65484"},
                          {"label": "Podares", "value": "C65485"},
                          {"label": "Radovis", "value": "C65486"}],
 "Radovljica Municipality": [{"label": "Lesce", "value": "C107778"},
                             {"label": "Radovljica", "value": "C107779"}],
 "Ragged Island": [{"label": "Duncan Town", "value": "C119620"}],
 "Rajasthan": [{"label": "Abhaneri", "value": "C47542"},
               {"label": "Abu", "value": "C47543"},
               {"label": "Abu Road", "value": "C47544"},
               {"label": "Ajmer", "value": "C47545"},
               {"label": "Aklera", "value": "C47546"},
               {"label": "Alwar", "value": "C47547"},
               {"label": "Amet", "value": "C47548"},
               {"label": "Anta", "value": "C47549"},
               {"label": "Anupgarh", "value": "C47550"},
               {"label": "Asind", "value": "C47551"},
               {"label": "Bagar", "value": "C47552"},
               {"label": "Bakani", "value": "C47553"},
               {"label": "Bali", "value": "C47554"},
               {"label": "Balotra", "value": "C47555"},
               {"label": "Bandikui", "value": "C47556"},
               {"label": "Banswara", "value": "C47557"},
               {"label": "Baran", "value": "C47558"},
               {"label": "Bari", "value": "C47559"},
               {"label": "Bari Sadri", "value": "C47560"},
               {"label": "Barmer", "value": "C47561"},
               {"label": "Basi", "value": "C47562"},
               {"label": "Basni", "value": "C47563"},
               {"label": "Baswa", "value": "C47564"},
               {"label": "Bayana", "value": "C47565"},
               {"label": "Beawar", "value": "C47566"},
               {"label": "Begun", "value": "C47567"},
               {"label": "Behror", "value": "C47568"},
               {"label": "Bhadasar", "value": "C47569"},
               {"label": "Bhadra", "value": "C47570"},
               {"label": "Bharatpur", "value": "C47571"},
               {"label": "Bhasawar", "value": "C47572"},
               {"label": "Bhilwara", "value": "C47573"},
               {"label": "Bhindar", "value": "C47574"},
               {"label": "Bhinmal", "value": "C47575"},
               {"label": "Bhiwadi", "value": "C47576"},
               {"label": "Bhuma", "value": "C47577"},
               {"label": "Bikaner", "value": "C47578"},
               {"label": "Bilara", "value": "C47579"},
               {"label": "Bissau", "value": "C47580"},
               {"label": "Borkhera", "value": "C47581"},
               {"label": "Bundi", "value": "C47582"},
               {"label": "Chaksu", "value": "C47583"},
               {"label": "Chechat", "value": "C47584"},
               {"label": "Chhabra", "value": "C47585"},
               {"label": "Chhapar", "value": "C47586"},
               {"label": "Chhoti Sadri", "value": "C47587"},
               {"label": "Chidawa", "value": "C47588"},
               {"label": "Chittaurgarh", "value": "C47589"},
               {"label": "Churu", "value": "C47590"},
               {"label": "Dariba", "value": "C47591"},
               {"label": "Dausa", "value": "C47592"},
               {"label": "Deoli", "value": "C47593"},
               {"label": "Deshnoke", "value": "C47594"},
               {"label": "Devgarh", "value": "C47595"},
               {"label": "Dhaulpur", "value": "C47596"},
               {"label": "Didwana", "value": "C47597"},
               {"label": "Dig", "value": "C47598"},
               {"label": "Dungarpur", "value": "C47599"},
               {"label": "Fatehpur", "value": "C47600"},
               {"label": "Galiakot", "value": "C47601"},
               {"label": "Ganganagar", "value": "C47602"},
               {"label": "Gangapur", "value": "C47603"},
               {"label": "Govindgarh", "value": "C47604"},
               {"label": "Gulabpura", "value": "C47605"},
               {"label": "Hanumangarh", "value": "C47606"},
               {"label": "Hindaun", "value": "C47607"},
               {"label": "Jahazpur", "value": "C47608"},
               {"label": "Jaipur", "value": "C47609"},
               {"label": "Jaisalmer", "value": "C47610"},
               {"label": "Jaitaran", "value": "C47611"},
               {"label": "Jalor", "value": "C47612"},
               {"label": "Jalore", "value": "C47613"},
               {"label": "Jhalawar", "value": "C47614"},
               {"label": "Jhalrapatan", "value": "C47615"},
               {"label": "Jhunjhunun", "value": "C47616"},
               {"label": "Jobner", "value": "C47617"},
               {"label": "Jodhpur", "value": "C47618"},
               {"label": "Kaman", "value": "C47619"},
               {"label": "Kanor", "value": "C47620"},
               {"label": "Kapren", "value": "C47621"},
               {"label": "Karanpur", "value": "C47622"},
               {"label": "Karauli", "value": "C47623"},
               {"label": "Kekri", "value": "C47624"},
               {"label": "Keshorai Patan", "value": "C47625"},
               {"label": "Khandela", "value": "C47626"},
               {"label": "Khanpur", "value": "C47627"},
               {"label": "Khetri", "value": "C47628"},
               {"label": "Kishangarh", "value": "C47629"},
               {"label": "Kota", "value": "C47630"},
               {"label": "Kotputli", "value": "C47631"},
               {"label": "Kuchaman", "value": "C47632"},
               {"label": "Kuchera", "value": "C47633"},
               {"label": "Kumher", "value": "C47634"},
               {"label": "Kushalgarh", "value": "C47635"},
               {"label": "Lachhmangarh Sikar", "value": "C47636"},
               {"label": "Ladnun", "value": "C47637"},
               {"label": "Lakheri", "value": "C47638"},
               {"label": "Lalsot", "value": "C47639"},
               {"label": "Losal", "value": "C47640"},
               {"label": "Mahwah", "value": "C47641"},
               {"label": "Makrana", "value": "C47642"},
               {"label": "Malpura", "value": "C47643"},
               {"label": "Mandal", "value": "C47644"},
               {"label": "Mandalgarh", "value": "C47645"},
               {"label": "Mandawar", "value": "C47646"},
               {"label": "Mangrol", "value": "C47647"},
               {"label": "Manohar Thana", "value": "C47648"},
               {"label": "Manoharpur", "value": "C47649"},
               {"label": "Meethari Marwar", "value": "C47650"},
               {"label": "Merta", "value": "C47651"},
               {"label": "Mundwa", "value": "C47652"},
               {"label": "Nadbai", "value": "C47653"},
               {"label": "Nagar", "value": "C47654"},
               {"label": "Nagaur", "value": "C47655"},
               {"label": "Nainwa", "value": "C47656"},
               {"label": "Napasar", "value": "C47657"},
               {"label": "Naraina", "value": "C47658"},
               {"label": "Nasirabad", "value": "C47659"},
               {"label": "Nathdwara", "value": "C47660"},
               {"label": "Nawa", "value": "C47661"},
               {"label": "Nawalgarh", "value": "C47662"},
               {"label": "Neem ka Thana", "value": "C47663"},
               {"label": "Nimaj", "value": "C47664"},
               {"label": "Nimbahera", "value": "C47665"},
               {"label": "Niwai", "value": "C47666"},
               {"label": "Nohar", "value": "C47667"},
               {"label": "Nokha", "value": "C47668"},
               {"label": "Padampur", "value": "C47669"},
               {"label": "Pali", "value": "C47670"},
               {"label": "Partapur", "value": "C47671"},
               {"label": "Parvatsar", "value": "C47672"},
               {"label": "Phalodi", "value": "C47673"},
               {"label": "Phulera", "value": "C47674"},
               {"label": "Pilani", "value": "C47675"},
               {"label": "Pilibangan", "value": "C47676"},
               {"label": "Pindwara", "value": "C47677"},
               {"label": "Pipar", "value": "C47678"},
               {"label": "Pirawa", "value": "C47679"},
               {"label": "Pokaran", "value": "C47680"},
               {"label": "Pratapgarh", "value": "C47681"},
               {"label": "Pushkar", "value": "C47682"},
               {"label": "Raipur", "value": "C47683"},
               {"label": "Raisinghnagar", "value": "C47684"},
               {"label": "Rajakhera", "value": "C47685"},
               {"label": "Rajaldesar", "value": "C47686"},
               {"label": "Rajgarh", "value": "C47687"},
               {"label": "Rajsamand", "value": "C47688"},
               {"label": "Ramganj Mandi", "value": "C47689"},
               {"label": "Ramgarh", "value": "C47690"},
               {"label": "Rani", "value": "C47691"},
               {"label": "Ratangarh", "value": "C47692"},
               {"label": "Rawatbhata", "value": "C47693"},
               {"label": "Rawatsar", "value": "C47694"},
               {"label": "Ringas", "value": "C47695"},
               {"label": "Sadri", "value": "C47696"},
               {"label": "Salumbar", "value": "C47697"},
               {"label": "Sambhar", "value": "C47698"},
               {"label": "Samdari", "value": "C47699"},
               {"label": "Sanchor", "value": "C47700"},
               {"label": "Sangaria", "value": "C47701"},
               {"label": "Sangod", "value": "C47702"},
               {"label": "Sardarshahr", "value": "C47703"},
               {"label": "Sarwar", "value": "C47704"},
               {"label": "Sawai Madhopur", "value": "C47705"},
               {"label": "Shahpura", "value": "C47706"},
               {"label": "Sheoganj", "value": "C47707"},
               {"label": "Sikar", "value": "C47708"},
               {"label": "Sirohi", "value": "C47709"},
               {"label": "Siwana", "value": "C47710"},
               {"label": "Sojat", "value": "C47711"},
               {"label": "Sri Dungargarh", "value": "C47712"},
               {"label": "Sri Madhopur", "value": "C47713"},
               {"label": "Sujangarh", "value": "C47714"},
               {"label": "Suket", "value": "C47715"},
               {"label": "Sunel", "value": "C47716"},
               {"label": "Surajgarh", "value": "C47717"},
               {"label": "Suratgarh", "value": "C47718"},
               {"label": "Takhatgarh", "value": "C47719"},
               {"label": "Taranagar", "value": "C47720"},
               {"label": "Tijara", "value": "C47721"},
               {"label": "Todabhim", "value": "C47722"},
               {"label": "Todaraisingh", "value": "C47723"},
               {"label": "Tonk", "value": "C47724"},
               {"label": "Udaipur", "value": "C47725"},
               {"label": "Udpura", "value": "C47726"},
               {"label": "Uniara", "value": "C47727"},
               {"label": "Wer", "value": "C47728"}],
 "Rajshahi District": [{"label": "Bera", "value": "C8655"},
                       {"label": "Bogra", "value": "C8656"},
                       {"label": "Chapai Nababganj", "value": "C8657"},
                       {"label": "Ishurdi", "value": "C8658"},
                       {"label": "Joypur Hat", "value": "C8659"},
                       {"label": "Joypurhat", "value": "C8660"},
                       {"label": "Mahasthangarh", "value": "C8661"},
                       {"label": "Naogaon", "value": "C8662"},
                       {"label": "Natore", "value": "C8663"},
                       {"label": "Nawabganj", "value": "C8664"},
                       {"label": "Pabna", "value": "C8665"},
                       {"label": "Par Naogaon", "value": "C8666"},
                       {"label": "Puthia", "value": "C8667"},
                       {"label": "Rajshahi", "value": "C8668"},
                       {"label": "Saidpur", "value": "C8669"},
                       {"label": "Shahzadpur", "value": "C8670"},
                       {"label": "Shibganj", "value": "C8671"},
                       {"label": "Sirajganj", "value": "C8672"}],
 "Rakhine State": [{"label": "Sittwe", "value": "C75947"}],
 "Rangpur District": [{"label": "Badarganj", "value": "C8673"},
                      {"label": "Chilmari", "value": "C8674"},
                      {"label": "Dinajpur", "value": "C8675"},
                      {"label": "Gaibandha", "value": "C8676"},
                      {"label": "Kurigram", "value": "C8677"},
                      {"label": "Lalmonirhat", "value": "C8678"},
                      {"label": "Lalmonirhat District", "value": "C8679"},
                      {"label": "Nageswari", "value": "C8680"},
                      {"label": "Nilphamari Zila", "value": "C8681"},
                      {"label": "Panchagarh", "value": "C8682"},
                      {"label": "Parbatipur", "value": "C8683"},
                      {"label": "Pirgaaj", "value": "C8684"},
                      {"label": "Rangpur", "value": "C8685"},
                      {"label": "Thakurgaon", "value": "C8686"}],
 "Rankovce Municipality": [{"label": "Rankovtse", "value": "C65487"}],
 "Ranong": [{"label": "Amphoe Kapoe", "value": "C119197"},
            {"label": "Amphoe Kra Buri", "value": "C119198"},
            {"label": "Amphoe La-un", "value": "C119199"},
            {"label": "Amphoe Mueang Ranong", "value": "C119200"},
            {"label": "Amphoe Suk Samran", "value": "C119201"},
            {"label": "Ranong", "value": "C119202"}],
 "Rapla County": [{"label": "Jarvakandi", "value": "C24610"},
                  {"label": "Kehtna", "value": "C24611"},
                  {"label": "Kehtna vald", "value": "C24612"},
                  {"label": "Kohila", "value": "C24613"},
                  {"label": "Kohila vald", "value": "C24614"},
                  {"label": "Marjamaa", "value": "C24615"},
                  {"label": "Marjamaa vald", "value": "C24616"},
                  {"label": "Rapla", "value": "C24617"},
                  {"label": "Rapla vald", "value": "C24618"}],
 "Ras al-Khaimah": [{"label": "Ras Al Khaimah", "value": "C123118"},
                    {"label": "Ras Al Khaimah City", "value": "C123119"}],
 "Rason": [{"label": "Sonbong", "value": "C78585"},
           {"label": "Ungsang-nodongjagu", "value": "C78586"}],
 "Ratanakiri": [{"label": "Banlung", "value": "C16539"},
                {"label": "Lumphat", "value": "C16540"},
                {"label": "Srok Andong Meas", "value": "C16541"},
                {"label": "Srok Ba Kev", "value": "C16542"},
                {"label": "Srok Ban Lung", "value": "C16543"},
                {"label": "Srok Koun Mom", "value": "C16544"},
                {"label": "Srok Ou Chum", "value": "C16545"},
                {"label": "Srok Ou Ya Dav", "value": "C16546"},
                {"label": "Srok Ta Veng", "value": "C16547"}],
 "Ratchaburi": [{"label": "Amphoe Ban Kha", "value": "C119203"},
                {"label": "Amphoe Ban Pong", "value": "C119204"},
                {"label": "Amphoe Bang Phae", "value": "C119205"},
                {"label": "Amphoe Chom Bueng", "value": "C119206"},
                {"label": "Amphoe Damnoen Saduak", "value": "C119207"},
                {"label": "Amphoe Mueang Ratchaburi", "value": "C119208"},
                {"label": "Amphoe Pak Tho", "value": "C119209"},
                {"label": "Amphoe Photharam", "value": "C119210"},
                {"label": "Amphoe Suan Phueng", "value": "C119211"},
                {"label": "Amphoe Wat Phleng", "value": "C119212"},
                {"label": "Ban Pong", "value": "C119213"},
                {"label": "Bang Phae", "value": "C119214"},
                {"label": "Chom Bueng", "value": "C119215"},
                {"label": "Damnoen Saduak", "value": "C119216"},
                {"label": "Photharam", "value": "C119217"},
                {"label": "Ratchaburi", "value": "C119218"}],
 "Rauna Municipality": [{"label": "Rauna", "value": "C64917"}],
 "Ravenna": [{"label": "Alfonsine", "value": "C58949"},
             {"label": "Bagnacavallo", "value": "C58950"},
             {"label": "Bagnara di Romagna", "value": "C58951"},
             {"label": "Brisighella", "value": "C58952"},
             {"label": "Casola Valsenio", "value": "C58953"},
             {"label": "Castel Bolognese", "value": "C58954"},
             {"label": "Cervia", "value": "C58955"},
             {"label": "Conselice", "value": "C58956"},
             {"label": "Cotignola", "value": "C58957"},
             {"label": "Faenza", "value": "C58958"},
             {"label": "Fusignano", "value": "C58959"},
             {"label": "Lugo", "value": "C58960"},
             {"label": "Massa Lombarda", "value": "C58961"},
             {"label": "Ravenna", "value": "C58962"},
             {"label": "Riolo Terme", "value": "C58963"},
             {"label": "Russi", "value": "C58964"},
             {"label": "SantAgata sul Santerno", "value": "C58965"},
             {"label": "Solarolo", "value": "C58966"}],
 "Ravne na Koroskem Municipality": [{"label": "Kotlje", "value": "C107780"},
                                    {"label": "Ravne na Koroskem",
                                     "value": "C107781"}],
 "Raymah Governorate": [{"label": "Al Jabin", "value": "C147995"},
                        {"label": "Al Jafariyah", "value": "C147996"},
                        {"label": "As Salafiyah", "value": "C147997"},
                        {"label": "Bilad At Taam", "value": "C147998"},
                        {"label": "Kusmah", "value": "C147999"},
                        {"label": "Mazhar", "value": "C148000"}],
 "Rayong": [{"label": "Amphoe Ban Chang", "value": "C119219"},
            {"label": "Amphoe Ban Khai", "value": "C119220"},
            {"label": "Amphoe Khao Chamao", "value": "C119221"},
            {"label": "Amphoe Klaeng", "value": "C119222"},
            {"label": "Amphoe Mueang Rayong", "value": "C119223"},
            {"label": "Amphoe Nikhom Phattana", "value": "C119224"},
            {"label": "Amphoe Pluak Daeng", "value": "C119225"},
            {"label": "Amphoe Wang Chan", "value": "C119226"},
            {"label": "Ban Chang", "value": "C119227"},
            {"label": "Ban Phe", "value": "C119228"},
            {"label": "Klaeng", "value": "C119229"},
            {"label": "Rayong", "value": "C119230"}],
 "Razavi Khorasan": [{"label": "Bardaskan", "value": "C50963"},
                     {"label": "Chenaran", "value": "C50964"},
                     {"label": "Dargaz", "value": "C50965"},
                     {"label": "Davarzan", "value": "C50966"},
                     {"label": "Gonabad", "value": "C50967"},
                     {"label": "Joveyn", "value": "C50968"},
                     {"label": "Kashmar", "value": "C50969"},
                     {"label": "Mashhad", "value": "C50970"},
                     {"label": "Neyshabur", "value": "C50971"},
                     {"label": "Quchan", "value": "C50972"},
                     {"label": "Sabzevar", "value": "C50973"},
                     {"label": "Sarakhs", "value": "C50974"},
                     {"label": "Shahrestan-e Bajestan", "value": "C50975"},
                     {"label": "Shahrestan-e Bakharz", "value": "C50976"},
                     {"label": "Shahrestan-e Binalud", "value": "C50977"},
                     {"label": "Shahrestan-e Chenaran", "value": "C50978"},
                     {"label": "Shahrestan-e Fariman", "value": "C50979"},
                     {"label": "Shahrestan-e Firuzeh", "value": "C50980"},
                     {"label": "Shahrestan-e Gonabad", "value": "C50981"},
                     {"label": "Shahrestan-e Joghatay", "value": "C50982"},
                     {"label": "Shahrestan-e Kalat", "value": "C50983"},
                     {"label": "Shahrestan-e Kashmar", "value": "C50984"},
                     {"label": "Shahrestan-e Khalilabad", "value": "C50985"},
                     {"label": "Shahrestan-e Khowshab", "value": "C50986"},
                     {"label": "Shahrestan-e Khvaf", "value": "C50987"},
                     {"label": "Shahrestan-e Mah Velat", "value": "C50988"},
                     {"label": "Shahrestan-e Neyshabur", "value": "C50989"},
                     {"label": "Shahrestan-e Quchan", "value": "C50990"},
                     {"label": "Shahrestan-e Roshtkhvar", "value": "C50991"},
                     {"label": "Shahrestan-e Sabzevar", "value": "C50992"},
                     {"label": "Shahrestan-e Taybad", "value": "C50993"},
                     {"label": "Shahrestan-e Torbat-e Heydariyeh",
                      "value": "C50994"},
                     {"label": "Shahrestan-e Torbat-e Jam", "value": "C50995"},
                     {"label": "Shahrestan-e Zaveh", "value": "C50996"},
                     {"label": "Taybad", "value": "C50997"},
                     {"label": "Torbat-e Heydariyeh", "value": "C50998"},
                     {"label": "Torbat-e Jam", "value": "C50999"}],
 "Razgrad Province": [{"label": "Isperih", "value": "C16098"},
                      {"label": "Kubrat", "value": "C16099"},
                      {"label": "Loznitsa", "value": "C16100"},
                      {"label": "Medovene", "value": "C16101"},
                      {"label": "Obshtina Isperih", "value": "C16102"},
                      {"label": "Obshtina Kubrat", "value": "C16103"},
                      {"label": "Obshtina Loznitsa", "value": "C16104"},
                      {"label": "Obshtina Razgrad", "value": "C16105"},
                      {"label": "Obshtina Samuil", "value": "C16106"},
                      {"label": "Obshtina Tsar Kaloyan", "value": "C16107"},
                      {"label": "Obshtina Zavet", "value": "C16108"},
                      {"label": "Razgrad", "value": "C16109"},
                      {"label": "Samuil", "value": "C16110"},
                      {"label": "Tsar Kaloyan", "value": "C16111"},
                      {"label": "Zavet", "value": "C16112"}],
 "Razkrizje Municipality": [{"label": "Razkrizje", "value": "C107782"}],
 "Red Sea": [{"label": "Al Qusayr", "value": "C24327"},
             {"label": "El Gouna", "value": "C24328"},
             {"label": "Hurghada", "value": "C24329"},
             {"label": "Makadi Bay", "value": "C24330"},
             {"label": "Marsa Alam", "value": "C24331"},
             {"label": "Ras Gharib", "value": "C24332"},
             {"label": "Safaga", "value": "C24333"},
             {"label": "Gebeit", "value": "C115422"},
             {"label": "Port Sudan", "value": "C115423"},
             {"label": "Sawakin", "value": "C115424"},
             {"label": "Tokar", "value": "C115425"}],
 "Region Metropolitana de Santiago": [{"label": "Alhue", "value": "C18141"},
                                      {"label": "Buin", "value": "C18142"},
                                      {"label": "Calera de Tango",
                                       "value": "C18143"},
                                      {"label": "Cerrillos", "value": "C18144"},
                                      {"label": "Cerro Navia",
                                       "value": "C18145"},
                                      {"label": "Colina", "value": "C18146"},
                                      {"label": "Conchali", "value": "C18147"},
                                      {"label": "Curacavi", "value": "C18148"},
                                      {"label": "El Bosque", "value": "C18149"},
                                      {"label": "El Monte", "value": "C18150"},
                                      {"label": "Estacion Central",
                                       "value": "C18151"},
                                      {"label": "Huechuraba",
                                       "value": "C18152"},
                                      {"label": "Independencia",
                                       "value": "C18153"},
                                      {"label": "Isla de Maipo",
                                       "value": "C18154"},
                                      {"label": "La Cisterna",
                                       "value": "C18155"},
                                      {"label": "La Florida",
                                       "value": "C18156"},
                                      {"label": "La Granja", "value": "C18157"},
                                      {"label": "La Pintana",
                                       "value": "C18158"},
                                      {"label": "La Reina", "value": "C18159"},
                                      {"label": "Lampa", "value": "C18160"},
                                      {"label": "Las Condes",
                                       "value": "C18161"},
                                      {"label": "Lo Barnechea",
                                       "value": "C18162"},
                                      {"label": "Lo Espejo", "value": "C18163"},
                                      {"label": "Lo Prado", "value": "C18164"},
                                      {"label": "Macul", "value": "C18165"},
                                      {"label": "Maipu", "value": "C18166"},
                                      {"label": "Maria Pinto",
                                       "value": "C18167"},
                                      {"label": "Melipilla", "value": "C18168"},
                                      {"label": "nunoa", "value": "C18169"},
                                      {"label": "Padre Hurtado",
                                       "value": "C18170"},
                                      {"label": "Paine", "value": "C18171"},
                                      {"label": "Pedro Aguirre Cerda",
                                       "value": "C18172"},
                                      {"label": "Penaflor", "value": "C18173"},
                                      {"label": "Penalolen", "value": "C18174"},
                                      {"label": "Pirque", "value": "C18175"},
                                      {"label": "Providencia",
                                       "value": "C18176"},
                                      {"label": "Pudahuel", "value": "C18177"},
                                      {"label": "Puente Alto",
                                       "value": "C18178"},
                                      {"label": "Quilicura", "value": "C18179"},
                                      {"label": "Quinta Normal",
                                       "value": "C18180"},
                                      {"label": "Recoleta", "value": "C18181"},
                                      {"label": "Renca", "value": "C18182"},
                                      {"label": "San Bernardo",
                                       "value": "C18183"},
                                      {"label": "San Joaquin",
                                       "value": "C18184"},
                                      {"label": "San Jose de Maipo",
                                       "value": "C18185"},
                                      {"label": "San Miguel",
                                       "value": "C18186"},
                                      {"label": "San Pedro", "value": "C18187"},
                                      {"label": "San Ramon", "value": "C18188"},
                                      {"label": "Santiago", "value": "C18189"},
                                      {"label": "Talagante", "value": "C18190"},
                                      {"label": "Tiltil", "value": "C18191"},
                                      {"label": "Vitacura", "value": "C18192"}],
 "Region Zealand": [{"label": "Asnaes", "value": "C23723"},
                    {"label": "Bjaeverskov", "value": "C23724"},
                    {"label": "Borup", "value": "C23725"},
                    {"label": "Dianalund", "value": "C23726"},
                    {"label": "Ejby", "value": "C23727"},
                    {"label": "Faxe", "value": "C23728"},
                    {"label": "Faxe Kommune", "value": "C23729"},
                    {"label": "Faxe Ladeplads", "value": "C23730"},
                    {"label": "Fensmark", "value": "C23731"},
                    {"label": "Forlev", "value": "C23732"},
                    {"label": "Frederiksberg", "value": "C23733"},
                    {"label": "Fuglebjerg", "value": "C23734"},
                    {"label": "Gorlev", "value": "C23735"},
                    {"label": "Greve", "value": "C23736"},
                    {"label": "Greve Kommune", "value": "C23737"},
                    {"label": "Guldborgsund Kommune", "value": "C23738"},
                    {"label": "Gundsomagle", "value": "C23739"},
                    {"label": "Harlev", "value": "C23740"},
                    {"label": "Haslev", "value": "C23741"},
                    {"label": "Havdrup", "value": "C23742"},
                    {"label": "Holbaek", "value": "C23743"},
                    {"label": "Holbaek Kommune", "value": "C23744"},
                    {"label": "Holeby", "value": "C23745"},
                    {"label": "Hong", "value": "C23746"},
                    {"label": "Horve", "value": "C23747"},
                    {"label": "Jyderup", "value": "C23748"},
                    {"label": "Jyllinge", "value": "C23749"},
                    {"label": "Kalundborg", "value": "C23750"},
                    {"label": "Kalundborg Kommune", "value": "C23751"},
                    {"label": "Kirke Hvalso", "value": "C23752"},
                    {"label": "Korsor", "value": "C23753"},
                    {"label": "Koge", "value": "C23754"},
                    {"label": "Koge Kommune", "value": "C23755"},
                    {"label": "Lejre", "value": "C23756"},
                    {"label": "Lejre Kommune", "value": "C23757"},
                    {"label": "Lolland Kommune", "value": "C23758"},
                    {"label": "Maribo", "value": "C23759"},
                    {"label": "Nakskov", "value": "C23760"},
                    {"label": "Naestved", "value": "C23761"},
                    {"label": "Naestved Kommune", "value": "C23762"},
                    {"label": "Neder Vindinge", "value": "C23763"},
                    {"label": "Norre Alslev", "value": "C23764"},
                    {"label": "Nykobing Falster", "value": "C23765"},
                    {"label": "Nykobing Sjaelland", "value": "C23766"},
                    {"label": "Nyrad", "value": "C23767"},
                    {"label": "Odsherred Kommune", "value": "C23768"},
                    {"label": "Osted", "value": "C23769"},
                    {"label": "Orslev", "value": "C23770"},
                    {"label": "Praesto", "value": "C23771"},
                    {"label": "Ringsted", "value": "C23772"},
                    {"label": "Ringsted Kommune", "value": "C23773"},
                    {"label": "Roskilde", "value": "C23774"},
                    {"label": "Roskilde Kommune", "value": "C23775"},
                    {"label": "Rodby", "value": "C23776"},
                    {"label": "Rodbyhavn", "value": "C23777"},
                    {"label": "Rodvig", "value": "C23778"},
                    {"label": "Ronnede", "value": "C23779"},
                    {"label": "Sakskobing", "value": "C23780"},
                    {"label": "Skaelskor", "value": "C23781"},
                    {"label": "Slagelse", "value": "C23782"},
                    {"label": "Slagelse Kommune", "value": "C23783"},
                    {"label": "Solrod", "value": "C23784"},
                    {"label": "Solrod Kommune", "value": "C23785"},
                    {"label": "Solrod Strand", "value": "C23786"},
                    {"label": "Soro", "value": "C23787"},
                    {"label": "Soro Kommune", "value": "C23788"},
                    {"label": "Stege", "value": "C23789"},
                    {"label": "Stenlille", "value": "C23790"},
                    {"label": "Stevns Kommune", "value": "C23791"},
                    {"label": "Store Heddinge", "value": "C23792"},
                    {"label": "Stroby Egede", "value": "C23793"},
                    {"label": "Stubbekobing", "value": "C23794"},
                    {"label": "Sundby", "value": "C23795"},
                    {"label": "Svebolle", "value": "C23796"},
                    {"label": "Svinninge", "value": "C23797"},
                    {"label": "Svogerslev", "value": "C23798"},
                    {"label": "Tollose", "value": "C23799"},
                    {"label": "Tune", "value": "C23800"},
                    {"label": "Viby", "value": "C23801"},
                    {"label": "Vindinge", "value": "C23802"},
                    {"label": "Vipperod", "value": "C23803"},
                    {"label": "Vordingborg", "value": "C23804"},
                    {"label": "Vordingborg Kommune", "value": "C23805"}],
 "Region of Southern Denmark": [{"label": "Aabenraa", "value": "C23614"},
                                {"label": "Aabenraa Kommune",
                                 "value": "C23615"},
                                {"label": "Arslev", "value": "C23616"},
                                {"label": "Arup", "value": "C23617"},
                                {"label": "Assens", "value": "C23618"},
                                {"label": "Assens Kommune", "value": "C23619"},
                                {"label": "Augustenborg", "value": "C23620"},
                                {"label": "AEro Kommune", "value": "C23621"},
                                {"label": "AEroskobing", "value": "C23622"},
                                {"label": "Bellinge", "value": "C23623"},
                                {"label": "Billund", "value": "C23624"},
                                {"label": "Billund Kommune", "value": "C23625"},
                                {"label": "Bogense", "value": "C23626"},
                                {"label": "Borkop", "value": "C23627"},
                                {"label": "Bramming", "value": "C23628"},
                                {"label": "Brejning", "value": "C23629"},
                                {"label": "Brenderup", "value": "C23630"},
                                {"label": "Broager", "value": "C23631"},
                                {"label": "Brorup", "value": "C23632"},
                                {"label": "Bullerup", "value": "C23633"},
                                {"label": "Christiansfeld", "value": "C23634"},
                                {"label": "Dybbol", "value": "C23635"},
                                {"label": "Egtved", "value": "C23636"},
                                {"label": "Ejby", "value": "C23637"},
                                {"label": "Esbjerg", "value": "C23638"},
                                {"label": "Esbjerg Kommune", "value": "C23639"},
                                {"label": "Faaborg", "value": "C23640"},
                                {"label": "Faaborg-Midtfyn Kommune",
                                 "value": "C23641"},
                                {"label": "Fano Kommune", "value": "C23642"},
                                {"label": "Fredericia", "value": "C23643"},
                                {"label": "Fredericia Kommune",
                                 "value": "C23644"},
                                {"label": "Give", "value": "C23645"},
                                {"label": "Glamsbjerg", "value": "C23646"},
                                {"label": "Gram", "value": "C23647"},
                                {"label": "Grasten", "value": "C23648"},
                                {"label": "Grindsted", "value": "C23649"},
                                {"label": "Guderup", "value": "C23650"},
                                {"label": "Haderslev", "value": "C23651"},
                                {"label": "Haderslev Kommune",
                                 "value": "C23652"},
                                {"label": "Harby", "value": "C23653"},
                                {"label": "Holsted", "value": "C23654"},
                                {"label": "Hojby", "value": "C23655"},
                                {"label": "Horuphav", "value": "C23656"},
                                {"label": "Jelling", "value": "C23657"},
                                {"label": "Kerteminde", "value": "C23658"},
                                {"label": "Kerteminde Kommune",
                                 "value": "C23659"},
                                {"label": "Kolding", "value": "C23660"},
                                {"label": "Kolding Kommune", "value": "C23661"},
                                {"label": "Krusa", "value": "C23662"},
                                {"label": "Langeland Kommune",
                                 "value": "C23663"},
                                {"label": "Langeskov", "value": "C23664"},
                                {"label": "Logumkloster", "value": "C23665"},
                                {"label": "Lojt Kirkeby", "value": "C23666"},
                                {"label": "Lunderskov", "value": "C23667"},
                                {"label": "Marstal", "value": "C23668"},
                                {"label": "Middelfart", "value": "C23669"},
                                {"label": "Middelfart Kommune",
                                 "value": "C23670"},
                                {"label": "Munkebo", "value": "C23671"},
                                {"label": "Neder Holluf", "value": "C23672"},
                                {"label": "Nordborg", "value": "C23673"},
                                {"label": "Nordby", "value": "C23674"},
                                {"label": "Nordfyns Kommune",
                                 "value": "C23675"},
                                {"label": "Norre Aby", "value": "C23676"},
                                {"label": "Nyborg", "value": "C23677"},
                                {"label": "Nyborg Kommune", "value": "C23678"},
                                {"label": "Odense", "value": "C23679"},
                                {"label": "Odense Kommune", "value": "C23680"},
                                {"label": "Oksbol", "value": "C23681"},
                                {"label": "Otterup", "value": "C23682"},
                                {"label": "Olgod", "value": "C23683"},
                                {"label": "Padborg", "value": "C23684"},
                                {"label": "Ribe", "value": "C23685"},
                                {"label": "Ringe", "value": "C23686"},
                                {"label": "Rodding", "value": "C23687"},
                                {"label": "Rodekro", "value": "C23688"},
                                {"label": "Rudkobing", "value": "C23689"},
                                {"label": "Seden", "value": "C23690"},
                                {"label": "Skaerbaek", "value": "C23691"},
                                {"label": "Snoghoj", "value": "C23692"},
                                {"label": "Sonder Bjert", "value": "C23693"},
                                {"label": "Sonderborg", "value": "C23694"},
                                {"label": "Sonderborg Kommune",
                                 "value": "C23695"},
                                {"label": "Sonderso", "value": "C23696"},
                                {"label": "Starup", "value": "C23697"},
                                {"label": "Stige", "value": "C23698"},
                                {"label": "Strib", "value": "C23699"},
                                {"label": "Svendborg", "value": "C23700"},
                                {"label": "Svendborg Kommune",
                                 "value": "C23701"},
                                {"label": "Taulov", "value": "C23702"},
                                {"label": "Thuro By", "value": "C23703"},
                                {"label": "Tinglev", "value": "C23704"},
                                {"label": "Tjaereborg", "value": "C23705"},
                                {"label": "Toftlund", "value": "C23706"},
                                {"label": "Tommerup", "value": "C23707"},
                                {"label": "Tommerup Stationsby",
                                 "value": "C23708"},
                                {"label": "Tonder", "value": "C23709"},
                                {"label": "Tonder Kommune", "value": "C23710"},
                                {"label": "Ullerslev", "value": "C23711"},
                                {"label": "Vamdrup", "value": "C23712"},
                                {"label": "Varde", "value": "C23713"},
                                {"label": "Varde Kommune", "value": "C23714"},
                                {"label": "Vejen", "value": "C23715"},
                                {"label": "Vejen Kommune", "value": "C23716"},
                                {"label": "Vejle", "value": "C23717"},
                                {"label": "Vejle Kommune", "value": "C23718"},
                                {"label": "Vester-Skerninge",
                                 "value": "C23719"},
                                {"label": "Vindeby", "value": "C23720"},
                                {"label": "Vissenbjerg", "value": "C23721"},
                                {"label": "Vojens", "value": "C23722"}],
 "Relizane": [{"label": "Ain Merane", "value": "C396"},
              {"label": "Ammi Moussa", "value": "C397"},
              {"label": "Djidiouia", "value": "C398"},
              {"label": "Mazouna", "value": "C399"},
              {"label": "Oued Rhiou", "value": "C400"},
              {"label": "Relizane", "value": "C401"},
              {"label": "Smala", "value": "C402"},
              {"label": "Zemoura", "value": "C403"}],
 "Rence-Vogrsko Municipality": [{"label": "Rence", "value": "C107783"},
                                {"label": "Volcja Draga", "value": "C107784"}],
 "Republic of Adygea": [{"label": "Abadzekhskaya", "value": "C103292"},
                        {"label": "Adygeysk", "value": "C103293"},
                        {"label": "Adygeysk Republican Urban Okrug",
                         "value": "C103294"},
                        {"label": "Beloye", "value": "C103295"},
                        {"label": "Blechepsin", "value": "C103296"},
                        {"label": "Dondukovskaya", "value": "C103297"},
                        {"label": "Enem", "value": "C103298"},
                        {"label": "Giaginskaya", "value": "C103299"},
                        {"label": "Giaginskiy Rayon", "value": "C103300"},
                        {"label": "Kamennomostskiy", "value": "C103301"},
                        {"label": "Khanskaya", "value": "C103302"},
                        {"label": "Khatukay", "value": "C103303"},
                        {"label": "Khodz", "value": "C103304"},
                        {"label": "Koshekhabl", "value": "C103305"},
                        {"label": "Koshekhablskiy Rayon", "value": "C103306"},
                        {"label": "Krasnogvardeyskiy Rayon",
                         "value": "C103307"},
                        {"label": "Krasnogvardeyskoye", "value": "C103308"},
                        {"label": "Kuzhorskaya", "value": "C103309"},
                        {"label": "Maykop", "value": "C103310"},
                        {"label": "Maykop Republican Urban Okrug",
                         "value": "C103311"},
                        {"label": "Maykopskiy Rayon", "value": "C103312"},
                        {"label": "Natyrbovo", "value": "C103313"},
                        {"label": "Ponezhukay", "value": "C103314"},
                        {"label": "Shovgenovskiy", "value": "C103315"},
                        {"label": "Shovgenovskiy Rayon", "value": "C103316"},
                        {"label": "Takhtamukay", "value": "C103317"},
                        {"label": "Takhtamukayskiy Rayon", "value": "C103318"},
                        {"label": "Teuchezhskiy Rayon", "value": "C103319"},
                        {"label": "Tlyustenkhabl", "value": "C103320"},
                        {"label": "Yablonovskiy", "value": "C103321"}],
 "Republic of Bashkortostan": [{"label": "Abzakovo", "value": "C103322"},
                               {"label": "Abzelilovskiy Rayon",
                                "value": "C103323"},
                               {"label": "Agidel", "value": "C103324"},
                               {"label": "Akyar", "value": "C103325"},
                               {"label": "Akhunovo", "value": "C103326"},
                               {"label": "Aksakovo", "value": "C103327"},
                               {"label": "Alsheyevskiy Rayon",
                                "value": "C103328"},
                               {"label": "Alekseyevka", "value": "C103329"},
                               {"label": "Amzya", "value": "C103330"},
                               {"label": "Asanovo", "value": "C103331"},
                               {"label": "Askino", "value": "C103332"},
                               {"label": "Askinskiy Rayon", "value": "C103333"},
                               {"label": "Avdon", "value": "C103334"},
                               {"label": "Bakalinskiy Rayon",
                                "value": "C103335"},
                               {"label": "Bakaly", "value": "C103336"},
                               {"label": "Baymak", "value": "C103337"},
                               {"label": "Bedeyeva Polyana",
                                "value": "C103338"},
                               {"label": "Belebey", "value": "C103339"},
                               {"label": "Beloretsk", "value": "C103340"},
                               {"label": "Beloretskiy Rayon",
                                "value": "C103341"},
                               {"label": "Birsk", "value": "C103342"},
                               {"label": "Bizhbulyak", "value": "C103343"},
                               {"label": "Blagoveshchensk", "value": "C103344"},
                               {"label": "Bulgakovo", "value": "C103345"},
                               {"label": "Burayevo", "value": "C103346"},
                               {"label": "Buribay", "value": "C103347"},
                               {"label": "Buzdyak", "value": "C103348"},
                               {"label": "Buzdyakskiy Rayon",
                                "value": "C103349"},
                               {"label": "Chekmagush", "value": "C103350"},
                               {"label": "Chesnokovka", "value": "C103351"},
                               {"label": "Chishmy", "value": "C103352"},
                               {"label": "Davlekanovo", "value": "C103353"},
                               {"label": "Dmitriyevka", "value": "C103354"},
                               {"label": "Duvan", "value": "C103355"},
                               {"label": "Dyurtyuli", "value": "C103356"},
                               {"label": "Fedorovskiy Rayon",
                                "value": "C103357"},
                               {"label": "Iglino", "value": "C103358"},
                               {"label": "Inzer", "value": "C103359"},
                               {"label": "Ishimbay", "value": "C103360"},
                               {"label": "Isyangulovo", "value": "C103361"},
                               {"label": "Kabakovo", "value": "C103362"},
                               {"label": "Kaltasy", "value": "C103363"},
                               {"label": "Kandry", "value": "C103364"},
                               {"label": "Karaidel", "value": "C103365"},
                               {"label": "Karmaskaly", "value": "C103366"},
                               {"label": "Khaybullinskiy Rayon",
                                "value": "C103367"},
                               {"label": "Kirgiz-Miyaki", "value": "C103368"},
                               {"label": "Krasnaya Gorka", "value": "C103369"},
                               {"label": "Krasnokholmskiy", "value": "C103370"},
                               {"label": "Krasnyy Klyuch", "value": "C103371"},
                               {"label": "Kudeyevskiy", "value": "C103372"},
                               {"label": "Kumertau", "value": "C103373"},
                               {"label": "Kushnarenkovo", "value": "C103374"},
                               {"label": "Lomovka", "value": "C103375"},
                               {"label": "Maginsk", "value": "C103376"},
                               {"label": "Mayachnyy", "value": "C103377"},
                               {"label": "Mechetlinskiy Rayon",
                                "value": "C103378"},
                               {"label": "Meleuz", "value": "C103379"},
                               {"label": "Mesyagutovo", "value": "C103380"},
                               {"label": "Mezgore", "value": "C103381"},
                               {"label": "Mikhaylovka", "value": "C103382"},
                               {"label": "Mindyak", "value": "C103383"},
                               {"label": "Mishkino", "value": "C103384"},
                               {"label": "Mishkinskiy Rayon",
                                "value": "C103385"},
                               {"label": "Miyakinskiy Rayon",
                                "value": "C103386"},
                               {"label": "Mrakovo", "value": "C103387"},
                               {"label": "Mursalimkino", "value": "C103388"},
                               {"label": "Neftekamsk", "value": "C103389"},
                               {"label": "Nikolo-Berezovka",
                                "value": "C103390"},
                               {"label": "Nizhnetroitskiy", "value": "C103391"},
                               {"label": "Novobelokatay", "value": "C103392"},
                               {"label": "Nurimanovskiy Rayon",
                                "value": "C103393"},
                               {"label": "Oktyabrskiy", "value": "C103394"},
                               {"label": "Pavlovka", "value": "C103395"},
                               {"label": "Priyutovo", "value": "C103396"},
                               {"label": "Rayevskiy", "value": "C103397"},
                               {"label": "Salavat", "value": "C103398"},
                               {"label": "Semiletka", "value": "C103399"},
                               {"label": "Serafimovskiy", "value": "C103400"},
                               {"label": "Shafranovo", "value": "C103401"},
                               {"label": "Sharan", "value": "C103402"},
                               {"label": "Sibay", "value": "C103403"},
                               {"label": "Starobaltachevo", "value": "C103404"},
                               {"label": "Starosubkhangulovo",
                                "value": "C103405"},
                               {"label": "Sterlibashevo", "value": "C103406"},
                               {"label": "Sterlitamak", "value": "C103407"},
                               {"label": "Subkhankulovo", "value": "C103408"},
                               {"label": "Temyasovo", "value": "C103409"},
                               {"label": "Tirlyanskiy", "value": "C103410"},
                               {"label": "Tolbazy", "value": "C103411"},
                               {"label": "Tubinskiy", "value": "C103412"},
                               {"label": "Tukan", "value": "C103413"},
                               {"label": "Tuymazy", "value": "C103414"},
                               {"label": "Uchaly", "value": "C103415"},
                               {"label": "Ufa", "value": "C103416"},
                               {"label": "Ulu-Telyak", "value": "C103417"},
                               {"label": "Ulukulevo", "value": "C103418"},
                               {"label": "Urman", "value": "C103419"},
                               {"label": "Verkhneyarkeyevo",
                                "value": "C103420"},
                               {"label": "Verkhniy Avzyan", "value": "C103421"},
                               {"label": "Verkhniye Kigi", "value": "C103422"},
                               {"label": "Verkhniye Tatyshly",
                                "value": "C103423"},
                               {"label": "Voskresenskoye", "value": "C103424"},
                               {"label": "Yanaul", "value": "C103425"},
                               {"label": "Yanaulskiy Rayon",
                                "value": "C103426"},
                               {"label": "Yazykovo", "value": "C103427"},
                               {"label": "Yermekeyevo", "value": "C103428"},
                               {"label": "Yermolayevo", "value": "C103429"},
                               {"label": "Yumaguzino", "value": "C103430"},
                               {"label": "Zilair", "value": "C103431"},
                               {"label": "Zirgan", "value": "C103432"}],
 "Republic of Buryatia": [{"label": "Babushkin", "value": "C103433"},
                          {"label": "Bagdarin", "value": "C103434"},
                          {"label": "Barguzin", "value": "C103435"},
                          {"label": "Bayangol", "value": "C103436"},
                          {"label": "Bichura", "value": "C103437"},
                          {"label": "Bolshoy Kunaley", "value": "C103438"},
                          {"label": "Dzhida", "value": "C103439"},
                          {"label": "Gusinoozyorsk", "value": "C103440"},
                          {"label": "Gusinoye Ozero", "value": "C103441"},
                          {"label": "Ilinka", "value": "C103442"},
                          {"label": "Ilka", "value": "C103443"},
                          {"label": "Istok", "value": "C103444"},
                          {"label": "Ivolginsk", "value": "C103445"},
                          {"label": "Ivolginskiy Rayon", "value": "C103446"},
                          {"label": "Kabansk", "value": "C103447"},
                          {"label": "Kholtoson", "value": "C103448"},
                          {"label": "Khonkholoy", "value": "C103449"},
                          {"label": "Khorinsk", "value": "C103450"},
                          {"label": "Kichera", "value": "C103451"},
                          {"label": "Kizhinga", "value": "C103452"},
                          {"label": "Kudara-Somon", "value": "C103453"},
                          {"label": "Kurumkan", "value": "C103454"},
                          {"label": "Kyakhta", "value": "C103455"},
                          {"label": "Kyren", "value": "C103456"},
                          {"label": "Mukhorshibir", "value": "C103457"},
                          {"label": "Mukhorshibirskiy Rayon",
                           "value": "C103458"},
                          {"label": "Muyskiy Rayon", "value": "C103459"},
                          {"label": "Naushki", "value": "C103460"},
                          {"label": "Nikolsk", "value": "C103461"},
                          {"label": "Nikolayevskiy", "value": "C103462"},
                          {"label": "Nizhneangarsk", "value": "C103463"},
                          {"label": "Nizhniy Sayantuy", "value": "C103464"},
                          {"label": "Novaya Bryan", "value": "C103465"},
                          {"label": "Novokizhinginsk", "value": "C103466"},
                          {"label": "Novyy Uoyan", "value": "C103467"},
                          {"label": "Novyy Zagan", "value": "C103468"},
                          {"label": "Okino-Klyuchi", "value": "C103469"},
                          {"label": "Okinskiy Rayon", "value": "C103470"},
                          {"label": "Onokhoy", "value": "C103471"},
                          {"label": "Orlik", "value": "C103472"},
                          {"label": "Petropavlovka", "value": "C103473"},
                          {"label": "Pribaykalskiy Rayon", "value": "C103474"},
                          {"label": "Rechka-Vydrino", "value": "C103475"},
                          {"label": "Sagan-Nur", "value": "C103476"},
                          {"label": "Selenduma", "value": "C103477"},
                          {"label": "Selenginskiy Rayon", "value": "C103478"},
                          {"label": "Severo-Baykalskiy Rayon",
                           "value": "C103479"},
                          {"label": "Severobaykalsk", "value": "C103480"},
                          {"label": "Severomuysk", "value": "C103481"},
                          {"label": "Sharalday", "value": "C103482"},
                          {"label": "Sokol", "value": "C103483"},
                          {"label": "Sosnovo-Ozerskoye", "value": "C103484"},
                          {"label": "Sotnikovo", "value": "C103485"},
                          {"label": "Taksimo", "value": "C103486"},
                          {"label": "Tankhoy", "value": "C103487"},
                          {"label": "Tarbagatay", "value": "C103488"},
                          {"label": "Tataurovo", "value": "C103489"},
                          {"label": "Turka", "value": "C103490"},
                          {"label": "Turuntayevo", "value": "C103491"},
                          {"label": "Ulan-Ude", "value": "C103492"},
                          {"label": "Ust-Barguzin", "value": "C103493"},
                          {"label": "Vydrino", "value": "C103494"},
                          {"label": "Zaigrayevo", "value": "C103495"},
                          {"label": "Zakamensk", "value": "C103496"},
                          {"label": "Zarechnyy", "value": "C103497"},
                          {"label": "Zhemchug", "value": "C103498"}],
 "Republic of Dagestan": [{"label": "Achisu", "value": "C103499"},
                          {"label": "Adil-Yangiyurt", "value": "C103500"},
                          {"label": "Agulskiy Rayon", "value": "C103501"},
                          {"label": "Agvali", "value": "C103502"},
                          {"label": "Akhty", "value": "C103503"},
                          {"label": "Akhtynskiy Rayon", "value": "C103504"},
                          {"label": "Akhvakhskiy Rayon", "value": "C103505"},
                          {"label": "Aknada", "value": "C103506"},
                          {"label": "Aksay", "value": "C103507"},
                          {"label": "Akusha", "value": "C103508"},
                          {"label": "Akushinskiy Rayon", "value": "C103509"},
                          {"label": "Andi", "value": "C103510"},
                          {"label": "Ansalta", "value": "C103511"},
                          {"label": "Ashilta", "value": "C103512"},
                          {"label": "Babayurt", "value": "C103513"},
                          {"label": "Babayurtovskiy Rayon", "value": "C103514"},
                          {"label": "Bammatyurt", "value": "C103515"},
                          {"label": "Bavtugay", "value": "C103516"},
                          {"label": "Belidzhi", "value": "C103517"},
                          {"label": "Bezhta", "value": "C103518"},
                          {"label": "Botashyurt", "value": "C103519"},
                          {"label": "Botayurt", "value": "C103520"},
                          {"label": "Botlikhskiy Rayon", "value": "C103521"},
                          {"label": "Burtunay", "value": "C103522"},
                          {"label": "Buynaksk", "value": "C103523"},
                          {"label": "Buynakskiy Rayon", "value": "C103524"},
                          {"label": "Charodinskiy Rayon", "value": "C103525"},
                          {"label": "Chinar", "value": "C103526"},
                          {"label": "Chirkey", "value": "C103527"},
                          {"label": "Chontaul", "value": "C103528"},
                          {"label": "Dagestanskiye Ogni", "value": "C103529"},
                          {"label": "Dakhadayevskiy Rayon", "value": "C103530"},
                          {"label": "Derbent", "value": "C103531"},
                          {"label": "Derbentskiy Rayon", "value": "C103532"},
                          {"label": "Dokuzparinskiy Rayon", "value": "C103533"},
                          {"label": "Dorgeli", "value": "C103534"},
                          {"label": "Druzhba", "value": "C103535"},
                          {"label": "Dubki", "value": "C103536"},
                          {"label": "Dylym", "value": "C103537"},
                          {"label": "Endirey", "value": "C103538"},
                          {"label": "Erpeli", "value": "C103539"},
                          {"label": "Gagatli", "value": "C103540"},
                          {"label": "Gaptsakh", "value": "C103541"},
                          {"label": "Gedzhukh", "value": "C103542"},
                          {"label": "Gereykhanovskoye", "value": "C103543"},
                          {"label": "Gerga", "value": "C103544"},
                          {"label": "Gergebil", "value": "C103545"},
                          {"label": "Gergebilskiy Rayon", "value": "C103546"},
                          {"label": "Gimry", "value": "C103547"},
                          {"label": "Gubden", "value": "C103548"},
                          {"label": "Gumbetovskiy Rayon", "value": "C103549"},
                          {"label": "Gunib", "value": "C103550"},
                          {"label": "Gunibskiy Rayon", "value": "C103551"},
                          {"label": "Gurbuki", "value": "C103552"},
                          {"label": "Irganay", "value": "C103553"},
                          {"label": "Izberbash", "value": "C103554"},
                          {"label": "Kafyr-Kumukh", "value": "C103555"},
                          {"label": "Kalininaul", "value": "C103556"},
                          {"label": "Karabudakhkent", "value": "C103557"},
                          {"label": "Karabudakhkentskiy Rayon",
                           "value": "C103558"},
                          {"label": "Karamakhi", "value": "C103559"},
                          {"label": "Karata", "value": "C103560"},
                          {"label": "Kaspiysk", "value": "C103561"},
                          {"label": "Kasumkent", "value": "C103562"},
                          {"label": "Kayakent", "value": "C103563"},
                          {"label": "Kayakentskiy Rayon", "value": "C103564"},
                          {"label": "Kaytagskiy Rayon", "value": "C103565"},
                          {"label": "Kazbekovskiy Rayon", "value": "C103566"},
                          {"label": "Khadzhalmakhi", "value": "C103567"},
                          {"label": "Khamamatyurt", "value": "C103568"},
                          {"label": "Khasavyurt", "value": "C103569"},
                          {"label": "Khasavyurtovskiy Rayon",
                           "value": "C103570"},
                          {"label": "Khazar", "value": "C103571"},
                          {"label": "Khebda", "value": "C103572"},
                          {"label": "Khiv", "value": "C103573"},
                          {"label": "Khivskiy Rayon", "value": "C103574"},
                          {"label": "Khuchni", "value": "C103575"},
                          {"label": "Khunzakh", "value": "C103576"},
                          {"label": "Khunzakhskiy Rayon", "value": "C103577"},
                          {"label": "Kishcha", "value": "C103578"},
                          {"label": "Kizilyurt", "value": "C103579"},
                          {"label": "Kizilyurtovskiy Rayon",
                           "value": "C103580"},
                          {"label": "Kizlyar", "value": "C103581"},
                          {"label": "Kizlyarskiy Rayon", "value": "C103582"},
                          {"label": "Kochubey", "value": "C103583"},
                          {"label": "Kokrek", "value": "C103584"},
                          {"label": "Komsomolskiy", "value": "C103585"},
                          {"label": "Korkmaskala", "value": "C103586"},
                          {"label": "Kostek", "value": "C103587"},
                          {"label": "Kubachi", "value": "C103588"},
                          {"label": "Kuli", "value": "C103589"},
                          {"label": "Kulinskiy Rayon", "value": "C103590"},
                          {"label": "Kumukh", "value": "C103591"},
                          {"label": "Kurakh", "value": "C103592"},
                          {"label": "Kurakhskiy Rayon", "value": "C103593"},
                          {"label": "Kurkent", "value": "C103594"},
                          {"label": "Kurush", "value": "C103595"},
                          {"label": "Kyakhulay", "value": "C103596"},
                          {"label": "Lakskiy Rayon", "value": "C103597"},
                          {"label": "Leninaul", "value": "C103598"},
                          {"label": "Leninkent", "value": "C103599"},
                          {"label": "Levashi", "value": "C103600"},
                          {"label": "Levashinskiy Rayon", "value": "C103601"},
                          {"label": "Lutkun", "value": "C103602"},
                          {"label": "Madzhalis", "value": "C103603"},
                          {"label": "Magaramkent", "value": "C103604"},
                          {"label": "Magaramkentskiy Rayon",
                           "value": "C103605"},
                          {"label": "Makhachkala", "value": "C103606"},
                          {"label": "Mamedkala", "value": "C103607"},
                          {"label": "Manas", "value": "C103608"},
                          {"label": "Manaskent", "value": "C103609"},
                          {"label": "Miatli", "value": "C103610"},
                          {"label": "Miskindzha", "value": "C103611"},
                          {"label": "Mugi", "value": "C103612"},
                          {"label": "Mutsalaul", "value": "C103613"},
                          {"label": "Myurego", "value": "C103614"},
                          {"label": "Nizhneye Kazanishche", "value": "C103615"},
                          {"label": "Nizhniy Dzhengutay", "value": "C103616"},
                          {"label": "Nogayskiy Rayon", "value": "C103617"},
                          {"label": "Novaya Maka", "value": "C103618"},
                          {"label": "Novogagatli", "value": "C103619"},
                          {"label": "Novokayakent", "value": "C103620"},
                          {"label": "Novolakskiy Rayon", "value": "C103621"},
                          {"label": "Novolakskoye", "value": "C103622"},
                          {"label": "Novyy Chirkey", "value": "C103623"},
                          {"label": "Novyy Khushet", "value": "C103624"},
                          {"label": "Novyy Kostek", "value": "C103625"},
                          {"label": "Novyy Sulak", "value": "C103626"},
                          {"label": "Nuradilovo", "value": "C103627"},
                          {"label": "Paraul", "value": "C103628"},
                          {"label": "Pervomayskoye", "value": "C103629"},
                          {"label": "Pokrovskoye", "value": "C103630"},
                          {"label": "Rutul", "value": "C103631"},
                          {"label": "Rutulskiy Rayon", "value": "C103632"},
                          {"label": "Sabnova", "value": "C103633"},
                          {"label": "Samur", "value": "C103634"},
                          {"label": "Sergokala", "value": "C103635"},
                          {"label": "Sergokalinskiy Rayon", "value": "C103636"},
                          {"label": "Shamilkala", "value": "C103637"},
                          {"label": "Shamilskiy Rayon", "value": "C103638"},
                          {"label": "Shamkhal", "value": "C103639"},
                          {"label": "Shamkhal-Termen", "value": "C103640"},
                          {"label": "Siukh", "value": "C103641"},
                          {"label": "Solnechnoye", "value": "C103642"},
                          {"label": "Sulak", "value": "C103643"},
                          {"label": "Suleyman-Stalskiy Rayon",
                           "value": "C103644"},
                          {"label": "Syrtych", "value": "C103645"},
                          {"label": "Tabasaranskiy Rayon", "value": "C103646"},
                          {"label": "Tarki", "value": "C103647"},
                          {"label": "Tarumovka", "value": "C103648"},
                          {"label": "Tarumovskiy Rayon", "value": "C103649"},
                          {"label": "Temiraul", "value": "C103650"},
                          {"label": "Terekli-Mekteb", "value": "C103651"},
                          {"label": "Tlyarata", "value": "C103652"},
                          {"label": "Tlyaratinskiy Rayon", "value": "C103653"},
                          {"label": "Tpig", "value": "C103654"},
                          {"label": "Tsumadinskiy Rayon", "value": "C103655"},
                          {"label": "Tsuntinskiy Rayon", "value": "C103656"},
                          {"label": "Tsurib", "value": "C103657"},
                          {"label": "Tyube", "value": "C103658"},
                          {"label": "Uchkent", "value": "C103659"},
                          {"label": "Ulluaya", "value": "C103660"},
                          {"label": "Ullubiyaul", "value": "C103661"},
                          {"label": "Untsukul", "value": "C103662"},
                          {"label": "Untsukulskiy Rayon", "value": "C103663"},
                          {"label": "Urkarakh", "value": "C103664"},
                          {"label": "Urma", "value": "C103665"},
                          {"label": "Usisha", "value": "C103666"},
                          {"label": "Usukhchay", "value": "C103667"},
                          {"label": "Utamysh", "value": "C103668"},
                          {"label": "Utsmiyurt", "value": "C103669"},
                          {"label": "Velikent", "value": "C103670"},
                          {"label": "Verkhneye Kazanishche",
                           "value": "C103671"},
                          {"label": "Yuzhno-Sukhokumsk", "value": "C103672"},
                          {"label": "Zubutli-Miatli", "value": "C103673"}],
 "Republic of Ingushetia": [{"label": "Ali-Yurt", "value": "C103674"},
                            {"label": "Alkhan-Churt", "value": "C103675"},
                            {"label": "Dalakovo", "value": "C103676"},
                            {"label": "Dzhayrakh", "value": "C103677"},
                            {"label": "Dzhayrakhskiy Rayon",
                             "value": "C103678"},
                            {"label": "Ekazhevo", "value": "C103679"},
                            {"label": "Galashki", "value": "C103680"},
                            {"label": "Kantyshevo", "value": "C103681"},
                            {"label": "Karabulak", "value": "C103682"},
                            {"label": "Magas", "value": "C103683"},
                            {"label": "Malgobek", "value": "C103684"},
                            {"label": "Malgobekskiy Rayon", "value": "C103685"},
                            {"label": "Nazran", "value": "C103686"},
                            {"label": "Nazranovskiy Rayon", "value": "C103687"},
                            {"label": "Nesterovskaya", "value": "C103688"},
                            {"label": "Nizhniye Achaluki", "value": "C103689"},
                            {"label": "Psedakh", "value": "C103690"},
                            {"label": "Sagopshi", "value": "C103691"},
                            {"label": "Staryy Malgobek", "value": "C103692"},
                            {"label": "Sunzha", "value": "C103693"},
                            {"label": "Sunzhenskiy Rayon", "value": "C103694"},
                            {"label": "Surkhakhi", "value": "C103695"},
                            {"label": "Troitskaya", "value": "C103696"},
                            {"label": "Verkhniye Achaluki", "value": "C103697"},
                            {"label": "Voznesenskaya", "value": "C103698"}],
 "Republic of Kalmykia": [{"label": "Arshan", "value": "C103699"},
                          {"label": "Elista", "value": "C103700"},
                          {"label": "Gorodoviki", "value": "C103701"},
                          {"label": "Gorodovikovsk", "value": "C103702"},
                          {"label": "Iki-Burul", "value": "C103703"},
                          {"label": "Iki-Burulskiy Rayon", "value": "C103704"},
                          {"label": "Kaspiyskiy", "value": "C103705"},
                          {"label": "Komsomolskiy", "value": "C103706"},
                          {"label": "Malyye Derbety", "value": "C103707"},
                          {"label": "Priyutnoye", "value": "C103708"},
                          {"label": "Sadovoye", "value": "C103709"},
                          {"label": "Sarpinskiy Rayon", "value": "C103710"},
                          {"label": "Sovetskoye", "value": "C103711"},
                          {"label": "Troitskoye", "value": "C103712"},
                          {"label": "Tsagan Aman", "value": "C103713"},
                          {"label": "Yashalta", "value": "C103714"},
                          {"label": "Yashaltinskiy Rayon", "value": "C103715"},
                          {"label": "Yashkul", "value": "C103716"}],
 "Republic of Karelia": [{"label": "Belomorsk", "value": "C103717"},
                         {"label": "Borovoy", "value": "C103718"},
                         {"label": "Chupa", "value": "C103719"},
                         {"label": "Essoyla", "value": "C103720"},
                         {"label": "Girvas", "value": "C103721"},
                         {"label": "Kalevala", "value": "C103722"},
                         {"label": "Kem", "value": "C103723"},
                         {"label": "Kharlu", "value": "C103724"},
                         {"label": "Khelyulya", "value": "C103725"},
                         {"label": "Kondopoga", "value": "C103726"},
                         {"label": "Kostomuksha", "value": "C103727"},
                         {"label": "Kvartsitnyy", "value": "C103728"},
                         {"label": "Lakhdenpokhya", "value": "C103729"},
                         {"label": "Ledmozero", "value": "C103730"},
                         {"label": "Loukhi", "value": "C103731"},
                         {"label": "Lyaskelya", "value": "C103732"},
                         {"label": "Matrosy", "value": "C103733"},
                         {"label": "Medvezhyegorsk", "value": "C103734"},
                         {"label": "Muyezerskiy", "value": "C103735"},
                         {"label": "Nadvoitsy", "value": "C103736"},
                         {"label": "Olonets", "value": "C103737"},
                         {"label": "Petrozavodsk", "value": "C103738"},
                         {"label": "Pindushi", "value": "C103739"},
                         {"label": "Pitkyaranta", "value": "C103740"},
                         {"label": "Pitkyarantskiy Rayon", "value": "C103741"},
                         {"label": "Porosozero", "value": "C103742"},
                         {"label": "Povenets", "value": "C103743"},
                         {"label": "Prionezhskiy Rayon", "value": "C103744"},
                         {"label": "Pryazha", "value": "C103745"},
                         {"label": "Pudozh", "value": "C103746"},
                         {"label": "Pyaozerskiy", "value": "C103747"},
                         {"label": "Rabocheostrovsk", "value": "C103748"},
                         {"label": "Ruskeala", "value": "C103749"},
                         {"label": "Salmi", "value": "C103750"},
                         {"label": "Segezha", "value": "C103751"},
                         {"label": "Shalskiy", "value": "C103752"},
                         {"label": "Sheltozero", "value": "C103753"},
                         {"label": "Shunga", "value": "C103754"},
                         {"label": "Shuya", "value": "C103755"},
                         {"label": "Sortavalskiy Rayon", "value": "C103756"},
                         {"label": "Sortavala", "value": "C103757"},
                         {"label": "Sukkozero", "value": "C103758"},
                         {"label": "Suoyarvi", "value": "C103759"},
                         {"label": "Suoyarvskiy Rayon", "value": "C103760"},
                         {"label": "Vyartsilya", "value": "C103761"},
                         {"label": "Yanisyarvi", "value": "C103762"},
                         {"label": "Yanishpole", "value": "C103763"},
                         {"label": "Yushkozero", "value": "C103764"}],
 "Republic of Khakassia": [{"label": "Abakan", "value": "C103765"},
                           {"label": "Abakan Gorod", "value": "C103766"},
                           {"label": "Abaza", "value": "C103767"},
                           {"label": "Altayskiy Rayon", "value": "C103768"},
                           {"label": "Askiz", "value": "C103769"},
                           {"label": "Belyy Yar", "value": "C103770"},
                           {"label": "Beya", "value": "C103771"},
                           {"label": "Biskamzha", "value": "C103772"},
                           {"label": "Bograd", "value": "C103773"},
                           {"label": "Bogradskiy Rayon", "value": "C103774"},
                           {"label": "Cheremushki", "value": "C103775"},
                           {"label": "Chernogorsk", "value": "C103776"},
                           {"label": "Kommunar", "value": "C103777"},
                           {"label": "Mayna", "value": "C103778"},
                           {"label": "Podsineye", "value": "C103779"},
                           {"label": "Sayanogorsk", "value": "C103780"},
                           {"label": "Shira", "value": "C103781"},
                           {"label": "Sorsk", "value": "C103782"},
                           {"label": "Tashtyp", "value": "C103783"},
                           {"label": "Tuim", "value": "C103784"},
                           {"label": "Ust-Abakan", "value": "C103785"},
                           {"label": "Vershina Tei", "value": "C103786"}],
 "Republic of Mordovia": [{"label": "Ardatov", "value": "C103787"},
                          {"label": "Ardatovskiy Rayon", "value": "C103788"},
                          {"label": "Atemar", "value": "C103789"},
                          {"label": "Atyashevo", "value": "C103790"},
                          {"label": "Atyashevskiy Rayon", "value": "C103791"},
                          {"label": "Atyuryevskiy Rayon", "value": "C103792"},
                          {"label": "Barashevo", "value": "C103793"},
                          {"label": "Bolshebereznikovskiy Rayon",
                           "value": "C103794"},
                          {"label": "Chamzinka", "value": "C103795"},
                          {"label": "Chamzinskiy Rayon", "value": "C103796"},
                          {"label": "Insar", "value": "C103797"},
                          {"label": "Insarskiy Rayon", "value": "C103798"},
                          {"label": "Kadoshkino", "value": "C103799"},
                          {"label": "Kemlya", "value": "C103800"},
                          {"label": "Kochkurovo", "value": "C103801"},
                          {"label": "Kochkurovskiy Rayon", "value": "C103802"},
                          {"label": "Kovylkino", "value": "C103803"},
                          {"label": "Krasnoslobodsk", "value": "C103804"},
                          {"label": "Krasnoslobodskiy Rayon",
                           "value": "C103805"},
                          {"label": "Lepley", "value": "C103806"},
                          {"label": "Lukhovka", "value": "C103807"},
                          {"label": "Lyambir", "value": "C103808"},
                          {"label": "Lyambirskiy Rayon", "value": "C103809"},
                          {"label": "Nikolayevka", "value": "C103810"},
                          {"label": "Partsa", "value": "C103811"},
                          {"label": "Romodanovo", "value": "C103812"},
                          {"label": "Romodanovskiy Rayon", "value": "C103813"},
                          {"label": "Ruzayevka", "value": "C103814"},
                          {"label": "Ruzayevskiy Rayon", "value": "C103815"},
                          {"label": "Saransk", "value": "C103816"},
                          {"label": "Shiringushi", "value": "C103817"},
                          {"label": "Sosnovka", "value": "C103818"},
                          {"label": "Staroshaygovskiy Rayon",
                           "value": "C103819"},
                          {"label": "Tatarskaya Pishlya", "value": "C103820"},
                          {"label": "Temnikov", "value": "C103821"},
                          {"label": "Temnikovskiy Rayon", "value": "C103822"},
                          {"label": "Tengushevo", "value": "C103823"},
                          {"label": "Tengushevskiy Rayon", "value": "C103824"},
                          {"label": "Torbeyevo", "value": "C103825"},
                          {"label": "Turgenevo", "value": "C103826"},
                          {"label": "Udarnyy", "value": "C103827"},
                          {"label": "Umet", "value": "C103828"},
                          {"label": "Vysha", "value": "C103829"},
                          {"label": "Yavas", "value": "C103830"},
                          {"label": "Yelnikovskiy Rayon", "value": "C103831"},
                          {"label": "Zubova Polyana", "value": "C103832"},
                          {"label": "Zubovo-Polyanskiy Rayon",
                           "value": "C103833"},
                          {"label": "Zykovo", "value": "C103834"}],
 "Republic of North Ossetia-Alania": [{"label": "Alagir", "value": "C103835"},
                                      {"label": "Alagirskiy Rayon",
                                       "value": "C103836"},
                                      {"label": "Ardon", "value": "C103837"},
                                      {"label": "Arkhonskaya",
                                       "value": "C103838"},
                                      {"label": "Beslan", "value": "C103839"},
                                      {"label": "Chermen", "value": "C103840"},
                                      {"label": "Chikola", "value": "C103841"},
                                      {"label": "Dachnoye", "value": "C103842"},
                                      {"label": "Digora", "value": "C103843"},
                                      {"label": "Digorskiy Rayon",
                                       "value": "C103844"},
                                      {"label": "Gizel", "value": "C103845"},
                                      {"label": "Irafskiy Rayon",
                                       "value": "C103846"},
                                      {"label": "Kambileyevskoye",
                                       "value": "C103847"},
                                      {"label": "Kardzhin", "value": "C103848"},
                                      {"label": "Khumalag", "value": "C103849"},
                                      {"label": "Kirovskiy Rayon",
                                       "value": "C103850"},
                                      {"label": "Kizlyar", "value": "C103851"},
                                      {"label": "Mikhaylovskoye",
                                       "value": "C103852"},
                                      {"label": "Mizur", "value": "C103853"},
                                      {"label": "Mozdok", "value": "C103854"},
                                      {"label": "Nogir", "value": "C103855"},
                                      {"label": "Pavlodolskaya",
                                       "value": "C103856"},
                                      {"label": "Pravoberezhnyy Rayon",
                                       "value": "C103857"},
                                      {"label": "Prigorodnyy Rayon",
                                       "value": "C103858"},
                                      {"label": "Sunzha", "value": "C103859"},
                                      {"label": "Tarskoye", "value": "C103860"},
                                      {"label": "Terskaya", "value": "C103861"},
                                      {"label": "Troitskoye",
                                       "value": "C103862"},
                                      {"label": "Verkhniy Fiagdon",
                                       "value": "C103863"},
                                      {"label": "Vladikavkaz",
                                       "value": "C103864"},
                                      {"label": "Zmeyskaya",
                                       "value": "C103865"}],
 "Republic of Tatarstan": [{"label": "Agryz", "value": "C103866"},
                           {"label": "Agryzskiy Rayon", "value": "C103867"},
                           {"label": "Aksubayevskiy Rayon", "value": "C103868"},
                           {"label": "Aktanysh", "value": "C103869"},
                           {"label": "Aktanyshskiy Rayon", "value": "C103870"},
                           {"label": "Aktyubinskiy", "value": "C103871"},
                           {"label": "Alkeyevskiy Rayon", "value": "C103872"},
                           {"label": "Almetyevsk", "value": "C103873"},
                           {"label": "Almetyevskiy Rayon", "value": "C103874"},
                           {"label": "Alekseyevskiy Rayon", "value": "C103875"},
                           {"label": "Alekseyevskoye", "value": "C103876"},
                           {"label": "Apastovo", "value": "C103877"},
                           {"label": "Apastovskiy Rayon", "value": "C103878"},
                           {"label": "Arsk", "value": "C103879"},
                           {"label": "Arskiy Rayon", "value": "C103880"},
                           {"label": "Atninskiy Rayon", "value": "C103881"},
                           {"label": "Aysha", "value": "C103882"},
                           {"label": "Aznakayevskiy Rayon", "value": "C103883"},
                           {"label": "Baltasi", "value": "C103884"},
                           {"label": "Baltasinskiy Rayon", "value": "C103885"},
                           {"label": "Bavlinskiy Rayon", "value": "C103886"},
                           {"label": "Bavly", "value": "C103887"},
                           {"label": "Bazarnyye Mataki", "value": "C103888"},
                           {"label": "Bilyarsk", "value": "C103889"},
                           {"label": "Bogatyye Saby", "value": "C103890"},
                           {"label": "Bolgar", "value": "C103891"},
                           {"label": "Bugulma", "value": "C103892"},
                           {"label": "Bugulminskiy Rayon", "value": "C103893"},
                           {"label": "Buinsk", "value": "C103894"},
                           {"label": "Buinskiy Rayon", "value": "C103895"},
                           {"label": "Cheremshan", "value": "C103896"},
                           {"label": "Cheremshanskiy Rayon",
                            "value": "C103897"},
                           {"label": "Chistopol", "value": "C103898"},
                           {"label": "Chistopolskiy Rayon", "value": "C103899"},
                           {"label": "Drozhzhanovskiy Rayon",
                            "value": "C103900"},
                           {"label": "Dzhalil", "value": "C103901"},
                           {"label": "Gorod Buinsk", "value": "C103902"},
                           {"label": "Gorod Kazan", "value": "C103903"},
                           {"label": "Gorod Nizhnekamsk", "value": "C103904"},
                           {"label": "Gorod Yelabuga", "value": "C103905"},
                           {"label": "Gorod Zainsk", "value": "C103906"},
                           {"label": "Gorod Zelenodolsk", "value": "C103907"},
                           {"label": "Kamsko-Ustinskiy Rayon",
                            "value": "C103908"},
                           {"label": "Karabash", "value": "C103909"},
                           {"label": "Kaybitskiy Rayon", "value": "C103910"},
                           {"label": "Kazan", "value": "C103911"},
                           {"label": "Kukmor", "value": "C103912"},
                           {"label": "Kukmorskiy Rayon", "value": "C103913"},
                           {"label": "Kuybyshevskiy Zaton", "value": "C103914"},
                           {"label": "Laishevo", "value": "C103915"},
                           {"label": "Laishevskiy Rayon", "value": "C103916"},
                           {"label": "Leninogorsk", "value": "C103917"},
                           {"label": "Leninogorskiy Rayon", "value": "C103918"},
                           {"label": "Lubyany", "value": "C103919"},
                           {"label": "Mamadysh", "value": "C103920"},
                           {"label": "Mamadyshskiy Rayon", "value": "C103921"},
                           {"label": "Mendeleyevsk", "value": "C103922"},
                           {"label": "Mendeleyevskiy Rayon",
                            "value": "C103923"},
                           {"label": "Menzelinsk", "value": "C103924"},
                           {"label": "Menzelinskiy Rayon", "value": "C103925"},
                           {"label": "Muslyumovo", "value": "C103926"},
                           {"label": "Muslyumovskiy Rayon", "value": "C103927"},
                           {"label": "Naberezhnyye Chelny", "value": "C103928"},
                           {"label": "Nizhnekamsk", "value": "C103929"},
                           {"label": "Nizhnekamskiy Rayon", "value": "C103930"},
                           {"label": "Nizhniye Vyazovyye", "value": "C103931"},
                           {"label": "Nizhnyaya Maktama", "value": "C103932"},
                           {"label": "Novosheshminsk", "value": "C103933"},
                           {"label": "Novosheshminskiy Rayon",
                            "value": "C103934"},
                           {"label": "Nurlat", "value": "C103935"},
                           {"label": "Nurlatskiy rayon", "value": "C103936"},
                           {"label": "Osinovo", "value": "C103937"},
                           {"label": "Pestrechinskiy Rayon",
                            "value": "C103938"},
                           {"label": "Pestretsy", "value": "C103939"},
                           {"label": "Russkiy Aktash", "value": "C103940"},
                           {"label": "Rybnaya Sloboda", "value": "C103941"},
                           {"label": "Rybno-Slobodskiy Rayon",
                            "value": "C103942"},
                           {"label": "Sabinskiy Rayon", "value": "C103943"},
                           {"label": "Sarmanovo", "value": "C103944"},
                           {"label": "Sarmanovskiy Rayon", "value": "C103945"},
                           {"label": "Shemordan", "value": "C103946"},
                           {"label": "Shugurovo", "value": "C103947"},
                           {"label": "Spasskiy Rayon", "value": "C103948"},
                           {"label": "Staroye Arakchino", "value": "C103949"},
                           {"label": "Staroye Drozhzhanoye",
                            "value": "C103950"},
                           {"label": "Stolbishchi", "value": "C103951"},
                           {"label": "Sviyazhsk", "value": "C103952"},
                           {"label": "Tetyushi", "value": "C103953"},
                           {"label": "Tetyushskiy Rayon", "value": "C103954"},
                           {"label": "Tukayevskiy Rayon", "value": "C103955"},
                           {"label": "Tyulyachi", "value": "C103956"},
                           {"label": "Tyulyachinskiy Rayon",
                            "value": "C103957"},
                           {"label": "Urussu", "value": "C103958"},
                           {"label": "Vasilyevo", "value": "C103959"},
                           {"label": "Verkhneuslonskiy Rayon",
                            "value": "C103960"},
                           {"label": "Verkhniy Uslon", "value": "C103961"},
                           {"label": "Vysokaya Gora", "value": "C103962"},
                           {"label": "Vysokogorskiy Rayon", "value": "C103963"},
                           {"label": "Yelabuga", "value": "C103964"},
                           {"label": "Yelabuzhskiy Rayon", "value": "C103965"},
                           {"label": "Yutazinskiy Rayon", "value": "C103966"},
                           {"label": "Zainsk", "value": "C103967"},
                           {"label": "Zainskiy Rayon", "value": "C103968"},
                           {"label": "Zelenodolskiy Rayon", "value": "C103969"},
                           {"label": "Zelenodolsk", "value": "C103970"}],
 "Republika Srpska": [{"label": "Balatun", "value": "C10047"},
                      {"label": "Banja Luka", "value": "C10048"},
                      {"label": "Bijeljina", "value": "C10049"},
                      {"label": "Bileca", "value": "C10050"},
                      {"label": "Blatnica", "value": "C10051"},
                      {"label": "Brod", "value": "C10052"},
                      {"label": "Bronzani Majdan", "value": "C10053"},
                      {"label": "Cajnice", "value": "C10054"},
                      {"label": "Cecava", "value": "C10055"},
                      {"label": "Celinac", "value": "C10056"},
                      {"label": "Derventa", "value": "C10057"},
                      {"label": "Doboj", "value": "C10058"},
                      {"label": "Dobrljin", "value": "C10059"},
                      {"label": "Dvorovi", "value": "C10060"},
                      {"label": "Foca", "value": "C10061"},
                      {"label": "Gacko", "value": "C10062"},
                      {"label": "Gradiska", "value": "C10063"},
                      {"label": "Hiseti", "value": "C10064"},
                      {"label": "Istocni Mostar", "value": "C10065"},
                      {"label": "Janja", "value": "C10066"},
                      {"label": "Kalenderovci Donji", "value": "C10067"},
                      {"label": "Knezevo", "value": "C10068"},
                      {"label": "Knezica", "value": "C10069"},
                      {"label": "Koran", "value": "C10070"},
                      {"label": "Kostajnica", "value": "C10071"},
                      {"label": "Kotor Varos", "value": "C10072"},
                      {"label": "Kozarska Dubica", "value": "C10073"},
                      {"label": "Krupa na Vrbasu", "value": "C10074"},
                      {"label": "Laktasi", "value": "C10075"},
                      {"label": "Lamovita", "value": "C10076"},
                      {"label": "Ljubinje", "value": "C10077"},
                      {"label": "Lopare", "value": "C10078"},
                      {"label": "Maglajani", "value": "C10079"},
                      {"label": "Maricka", "value": "C10080"},
                      {"label": "Maslovare", "value": "C10081"},
                      {"label": "Mejdan - Obilicevo", "value": "C10082"},
                      {"label": "Milici", "value": "C10083"},
                      {"label": "Modrica", "value": "C10084"},
                      {"label": "Mrkonjic Grad", "value": "C10085"},
                      {"label": "Nevesinje", "value": "C10086"},
                      {"label": "Novi Grad", "value": "C10087"},
                      {"label": "Obudovac", "value": "C10088"},
                      {"label": "Omarska", "value": "C10089"},
                      {"label": "Opstina Ostra Luka", "value": "C10090"},
                      {"label": "Opstina Visegrad", "value": "C10091"},
                      {"label": "Ostra Luka", "value": "C10092"},
                      {"label": "Pale", "value": "C10093"},
                      {"label": "Pelagicevo", "value": "C10094"},
                      {"label": "Petkovci", "value": "C10095"},
                      {"label": "Piskavica", "value": "C10096"},
                      {"label": "Podbrdo", "value": "C10097"},
                      {"label": "Popovi", "value": "C10098"},
                      {"label": "Pribinic", "value": "C10099"},
                      {"label": "Priboj", "value": "C10100"},
                      {"label": "Prijedor", "value": "C10101"},
                      {"label": "Rogatica", "value": "C10102"},
                      {"label": "Rudo", "value": "C10103"},
                      {"label": "Samac", "value": "C10104"},
                      {"label": "Sekovici", "value": "C10105"},
                      {"label": "Sipovo", "value": "C10106"},
                      {"label": "Sokolac", "value": "C10107"},
                      {"label": "Srbac", "value": "C10108"},
                      {"label": "Srebrenica", "value": "C10109"},
                      {"label": "Stanari", "value": "C10110"},
                      {"label": "Starcevica", "value": "C10111"},
                      {"label": "Svodna", "value": "C10112"},
                      {"label": "Teslic", "value": "C10113"},
                      {"label": "Trebinje", "value": "C10114"},
                      {"label": "Trn", "value": "C10115"},
                      {"label": "Ugljevik", "value": "C10116"},
                      {"label": "Velika Obarska", "value": "C10117"},
                      {"label": "Visegrad", "value": "C10118"},
                      {"label": "Vlasenica", "value": "C10119"},
                      {"label": "Zivinice", "value": "C10120"},
                      {"label": "Zvornik", "value": "C10121"}],
 "Resen Municipality": [{"label": "Grncari", "value": "C65488"},
                        {"label": "Jankovec", "value": "C65489"},
                        {"label": "Krani", "value": "C65490"},
                        {"label": "Resen", "value": "C65491"}],
 "Retalhuleu Department": [{"label": "Champerico", "value": "C42857"},
                           {"label": "El Asintal", "value": "C42858"},
                           {"label": "Municipio de San Felipe",
                            "value": "C42859"},
                           {"label": "Nuevo San Carlos", "value": "C42860"},
                           {"label": "Retalhuleu", "value": "C42861"},
                           {"label": "San Andres Villa Seca",
                            "value": "C42862"},
                           {"label": "San Felipe", "value": "C42863"},
                           {"label": "San Martin Zapotitlan",
                            "value": "C42864"},
                           {"label": "San Sebastian", "value": "C42865"},
                           {"label": "Santa Cruz Mulua", "value": "C42866"}],
 "Rezekne": [{"label": "Rezekne", "value": "C64918"}],
 "Rezina District": [{"label": "Rezina", "value": "C75529"},
                     {"label": "Saharna", "value": "C75530"}],
 "Rhineland-Palatinate": [{"label": "Aach", "value": "C39199"},
                          {"label": "Adenau", "value": "C39200"},
                          {"label": "Ahrbruck", "value": "C39201"},
                          {"label": "Albersweiler", "value": "C39202"},
                          {"label": "Albig", "value": "C39203"},
                          {"label": "Albisheim", "value": "C39204"},
                          {"label": "Alpenrod", "value": "C39205"},
                          {"label": "Alsdorf", "value": "C39206"},
                          {"label": "Alsenz", "value": "C39207"},
                          {"label": "Alsheim", "value": "C39208"},
                          {"label": "Altenahr", "value": "C39209"},
                          {"label": "Altendiez", "value": "C39210"},
                          {"label": "Altenglan", "value": "C39211"},
                          {"label": "Altenkirchen", "value": "C39212"},
                          {"label": "Altleiningen", "value": "C39213"},
                          {"label": "Altrich", "value": "C39214"},
                          {"label": "Altrip", "value": "C39215"},
                          {"label": "Alzey", "value": "C39216"},
                          {"label": "Andernach", "value": "C39217"},
                          {"label": "Anhausen", "value": "C39218"},
                          {"label": "Annweiler am Trifels", "value": "C39219"},
                          {"label": "Appenheim", "value": "C39220"},
                          {"label": "Argenthal", "value": "C39221"},
                          {"label": "Armsheim", "value": "C39222"},
                          {"label": "Arzbach", "value": "C39223"},
                          {"label": "Arzfeld", "value": "C39224"},
                          {"label": "Asbach", "value": "C39225"},
                          {"label": "Ayl", "value": "C39226"},
                          {"label": "Bacharach", "value": "C39227"},
                          {"label": "Bad Bergzabern", "value": "C39228"},
                          {"label": "Bad Breisig", "value": "C39229"},
                          {"label": "Bad Durkheim", "value": "C39230"},
                          {"label": "Bad Ems", "value": "C39231"},
                          {"label": "Bad Honningen", "value": "C39232"},
                          {"label": "Bad Kreuznach", "value": "C39233"},
                          {"label": "Bad Marienberg", "value": "C39234"},
                          {"label": "Bad Munster am Stein-Ebernburg",
                           "value": "C39235"},
                          {"label": "Bad Neuenahr-Ahrweiler",
                           "value": "C39236"},
                          {"label": "Badem", "value": "C39237"},
                          {"label": "Bann", "value": "C39238"},
                          {"label": "Bassenheim", "value": "C39239"},
                          {"label": "Baumholder", "value": "C39240"},
                          {"label": "Bausendorf", "value": "C39241"},
                          {"label": "Bechhofen", "value": "C39242"},
                          {"label": "Bechtheim", "value": "C39243"},
                          {"label": "Bechtolsheim", "value": "C39244"},
                          {"label": "Beindersheim", "value": "C39245"},
                          {"label": "Bell", "value": "C39246"},
                          {"label": "Bellheim", "value": "C39247"},
                          {"label": "Beltheim", "value": "C39248"},
                          {"label": "Bendorf", "value": "C39249"},
                          {"label": "Berg", "value": "C39250"},
                          {"label": "Bernkastel-Kues", "value": "C39251"},
                          {"label": "Bettingen", "value": "C39252"},
                          {"label": "Betzdorf", "value": "C39253"},
                          {"label": "Billigheim-Ingenheim", "value": "C39254"},
                          {"label": "Bingen am Rhein", "value": "C39255"},
                          {"label": "Binsfeld", "value": "C39256"},
                          {"label": "Birken-Honigsessen", "value": "C39257"},
                          {"label": "Birkenfeld", "value": "C39258"},
                          {"label": "Birkenheide", "value": "C39259"},
                          {"label": "Birlenbach", "value": "C39260"},
                          {"label": "Birresborn", "value": "C39261"},
                          {"label": "Bitburg", "value": "C39262"},
                          {"label": "Blankenrath", "value": "C39263"},
                          {"label": "Bleialf", "value": "C39264"},
                          {"label": "Bobenheim-Roxheim", "value": "C39265"},
                          {"label": "Bockenau", "value": "C39266"},
                          {"label": "Bockenheim", "value": "C39267"},
                          {"label": "Bodenheim", "value": "C39268"},
                          {"label": "Bohl-Iggelheim", "value": "C39269"},
                          {"label": "Bolanden", "value": "C39270"},
                          {"label": "Bollendorf", "value": "C39271"},
                          {"label": "Bonefeld", "value": "C39272"},
                          {"label": "Boppard", "value": "C39273"},
                          {"label": "Bornheim", "value": "C39274"},
                          {"label": "Bornich", "value": "C39275"},
                          {"label": "Brachbach", "value": "C39276"},
                          {"label": "Braubach", "value": "C39277"},
                          {"label": "Brauneberg", "value": "C39278"},
                          {"label": "Breitscheidt", "value": "C39279"},
                          {"label": "Bretzenheim", "value": "C39280"},
                          {"label": "Brey", "value": "C39281"},
                          {"label": "Briedel", "value": "C39282"},
                          {"label": "Brohl-Lutzing", "value": "C39283"},
                          {"label": "Bruchmuhlbach-Miesau", "value": "C39284"},
                          {"label": "Bruchweiler-Barenbach", "value": "C39285"},
                          {"label": "Brucken", "value": "C39286"},
                          {"label": "Bruttig-Fankel", "value": "C39287"},
                          {"label": "Buchel", "value": "C39288"},
                          {"label": "Buchenbeuren", "value": "C39289"},
                          {"label": "Budenheim", "value": "C39290"},
                          {"label": "Bullay", "value": "C39291"},
                          {"label": "Bundenbach", "value": "C39292"},
                          {"label": "Bundenthal", "value": "C39293"},
                          {"label": "Burgbrohl", "value": "C39294"},
                          {"label": "Burgschwalbach", "value": "C39295"},
                          {"label": "Busenberg", "value": "C39296"},
                          {"label": "Carlsberg", "value": "C39297"},
                          {"label": "Clausen", "value": "C39298"},
                          {"label": "Cochem", "value": "C39299"},
                          {"label": "Contwig", "value": "C39300"},
                          {"label": "Daaden", "value": "C39301"},
                          {"label": "Dachsenhausen", "value": "C39302"},
                          {"label": "Dahn", "value": "C39303"},
                          {"label": "Dalheim", "value": "C39304"},
                          {"label": "Dannstadt-Schauernheim",
                           "value": "C39305"},
                          {"label": "Dattenberg", "value": "C39306"},
                          {"label": "Daun", "value": "C39307"},
                          {"label": "Dausenau", "value": "C39308"},
                          {"label": "Deidesheim", "value": "C39309"},
                          {"label": "Dellfeld", "value": "C39310"},
                          {"label": "Dernau", "value": "C39311"},
                          {"label": "Dernbach", "value": "C39312"},
                          {"label": "Derschen", "value": "C39313"},
                          {"label": "Dexheim", "value": "C39314"},
                          {"label": "Dieblich", "value": "C39315"},
                          {"label": "Dienheim", "value": "C39316"},
                          {"label": "Dierdorf", "value": "C39317"},
                          {"label": "Diez", "value": "C39318"},
                          {"label": "Dirmstein", "value": "C39319"},
                          {"label": "Dittelsheim-Hessloch", "value": "C39320"},
                          {"label": "Dommershausen", "value": "C39321"},
                          {"label": "Dorrenbach", "value": "C39322"},
                          {"label": "Dreikirchen", "value": "C39323"},
                          {"label": "Dreis", "value": "C39324"},
                          {"label": "Dreisen", "value": "C39325"},
                          {"label": "Dudeldorf", "value": "C39326"},
                          {"label": "Dudenhofen", "value": "C39327"},
                          {"label": "Dungenheim", "value": "C39328"},
                          {"label": "Ebernhahn", "value": "C39329"},
                          {"label": "Ebertsheim", "value": "C39330"},
                          {"label": "Edenkoben", "value": "C39331"},
                          {"label": "Edesheim", "value": "C39332"},
                          {"label": "Ediger-Eller", "value": "C39333"},
                          {"label": "Ehlscheid", "value": "C39334"},
                          {"label": "Eisenberg", "value": "C39335"},
                          {"label": "Eitelborn", "value": "C39336"},
                          {"label": "Elkenroth", "value": "C39337"},
                          {"label": "Ellerstadt", "value": "C39338"},
                          {"label": "Elmstein", "value": "C39339"},
                          {"label": "Emmelshausen", "value": "C39340"},
                          {"label": "Enkenbach-Alsenborn", "value": "C39341"},
                          {"label": "Enkirch", "value": "C39342"},
                          {"label": "Eppelsheim", "value": "C39343"},
                          {"label": "Eppenbrunn", "value": "C39344"},
                          {"label": "Erbes-Budesheim", "value": "C39345"},
                          {"label": "Erfweiler", "value": "C39346"},
                          {"label": "Erpel", "value": "C39347"},
                          {"label": "Erpolzheim", "value": "C39348"},
                          {"label": "Essenheim", "value": "C39349"},
                          {"label": "Essingen", "value": "C39350"},
                          {"label": "Esthal", "value": "C39351"},
                          {"label": "Ettringen", "value": "C39352"},
                          {"label": "Etzbach", "value": "C39353"},
                          {"label": "Fachbach", "value": "C39354"},
                          {"label": "Faid", "value": "C39355"},
                          {"label": "Feilbingert", "value": "C39356"},
                          {"label": "Fell", "value": "C39357"},
                          {"label": "Fischbach", "value": "C39358"},
                          {"label": "Flacht", "value": "C39359"},
                          {"label": "Flammersfeld", "value": "C39360"},
                          {"label": "Flomborn", "value": "C39361"},
                          {"label": "Flonheim", "value": "C39362"},
                          {"label": "Fohren", "value": "C39363"},
                          {"label": "Framersheim", "value": "C39364"},
                          {"label": "Frankenstein", "value": "C39365"},
                          {"label": "Frankenthal", "value": "C39366"},
                          {"label": "Freckenfeld", "value": "C39367"},
                          {"label": "Frei-Laubersheim", "value": "C39368"},
                          {"label": "Freinsheim", "value": "C39369"},
                          {"label": "Freisbach", "value": "C39370"},
                          {"label": "Freudenburg", "value": "C39371"},
                          {"label": "Friedelsheim", "value": "C39372"},
                          {"label": "Friedewald", "value": "C39373"},
                          {"label": "Friesenhagen", "value": "C39374"},
                          {"label": "Furfeld", "value": "C39375"},
                          {"label": "Furthen", "value": "C39376"},
                          {"label": "Fussgonheim", "value": "C39377"},
                          {"label": "Gartenstadt", "value": "C39378"},
                          {"label": "Gau-Algesheim", "value": "C39379"},
                          {"label": "Gau-Bickelheim", "value": "C39380"},
                          {"label": "Gau-Bischofsheim", "value": "C39381"},
                          {"label": "Gau-Odernheim", "value": "C39382"},
                          {"label": "Gebhardshain", "value": "C39383"},
                          {"label": "Gemunden", "value": "C39384"},
                          {"label": "Gensingen", "value": "C39385"},
                          {"label": "Germersheim", "value": "C39386"},
                          {"label": "Gerolsheim", "value": "C39387"},
                          {"label": "Gerolstein", "value": "C39388"},
                          {"label": "Gillenfeld", "value": "C39389"},
                          {"label": "Gimbsheim", "value": "C39390"},
                          {"label": "Girod", "value": "C39391"},
                          {"label": "Glan-Munchweiler", "value": "C39392"},
                          {"label": "Gollheim", "value": "C39393"},
                          {"label": "Gommersheim", "value": "C39394"},
                          {"label": "Gondershausen", "value": "C39395"},
                          {"label": "Gonnheim", "value": "C39396"},
                          {"label": "Gossersweiler-Stein", "value": "C39397"},
                          {"label": "Greimerath", "value": "C39398"},
                          {"label": "Gries", "value": "C39399"},
                          {"label": "Grolsheim", "value": "C39400"},
                          {"label": "Grossholbach", "value": "C39401"},
                          {"label": "Grosskarlbach", "value": "C39402"},
                          {"label": "Grosslittgen", "value": "C39403"},
                          {"label": "Grossmaischeid", "value": "C39404"},
                          {"label": "Grossniedesheim", "value": "C39405"},
                          {"label": "Grunstadt", "value": "C39406"},
                          {"label": "Guckingen", "value": "C39407"},
                          {"label": "Gundersheim", "value": "C39408"},
                          {"label": "Guntersblum", "value": "C39409"},
                          {"label": "Gusenburg", "value": "C39410"},
                          {"label": "Gusterath", "value": "C39411"},
                          {"label": "Gutenberg", "value": "C39412"},
                          {"label": "Hachenburg", "value": "C39413"},
                          {"label": "Hackenheim", "value": "C39414"},
                          {"label": "Hagenbach", "value": "C39415"},
                          {"label": "Hahnheim", "value": "C39416"},
                          {"label": "Hahnstatten", "value": "C39417"},
                          {"label": "Halsenbach", "value": "C39418"},
                          {"label": "Hamm", "value": "C39419"},
                          {"label": "Hanhofen", "value": "C39420"},
                          {"label": "Hargesheim", "value": "C39421"},
                          {"label": "Harthausen", "value": "C39422"},
                          {"label": "Harxheim", "value": "C39423"},
                          {"label": "Hassloch", "value": "C39424"},
                          {"label": "Hattert", "value": "C39425"},
                          {"label": "Hatzenbuhl", "value": "C39426"},
                          {"label": "Hauenstein", "value": "C39427"},
                          {"label": "Hauptstuhl", "value": "C39428"},
                          {"label": "Hausen", "value": "C39429"},
                          {"label": "Heidesheim", "value": "C39430"},
                          {"label": "Heiligenroth", "value": "C39431"},
                          {"label": "Heimbach", "value": "C39432"},
                          {"label": "Heistenbach", "value": "C39433"},
                          {"label": "Helferskirchen", "value": "C39434"},
                          {"label": "Hellenhahn-Schellenberg",
                           "value": "C39435"},
                          {"label": "Heltersberg", "value": "C39436"},
                          {"label": "Hennweiler", "value": "C39437"},
                          {"label": "Herdorf", "value": "C39438"},
                          {"label": "Herforst", "value": "C39439"},
                          {"label": "Hermersberg", "value": "C39440"},
                          {"label": "Hermeskeil", "value": "C39441"},
                          {"label": "Herschweiler-Pettersheim",
                           "value": "C39442"},
                          {"label": "Herxheim am Berg", "value": "C39443"},
                          {"label": "Hessheim", "value": "C39444"},
                          {"label": "Hettenleidelheim", "value": "C39445"},
                          {"label": "Hetzerath", "value": "C39446"},
                          {"label": "Heuchelheim bei Frankenthal",
                           "value": "C39447"},
                          {"label": "Hilgert", "value": "C39448"},
                          {"label": "Hillesheim", "value": "C39449"},
                          {"label": "Hillscheid", "value": "C39450"},
                          {"label": "Hinterweidenthal", "value": "C39451"},
                          {"label": "Hochdorf-Assenheim", "value": "C39452"},
                          {"label": "Hochspeyer", "value": "C39453"},
                          {"label": "Hochstadt", "value": "C39454"},
                          {"label": "Hochstetten-Dhaun", "value": "C39455"},
                          {"label": "Hof", "value": "C39456"},
                          {"label": "Hoheinod", "value": "C39457"},
                          {"label": "Hoheischweiler", "value": "C39458"},
                          {"label": "Hohn", "value": "C39459"},
                          {"label": "Hohr-Grenzhausen", "value": "C39460"},
                          {"label": "Holler", "value": "C39461"},
                          {"label": "Holzappel", "value": "C39462"},
                          {"label": "Holzhausen an der Haide",
                           "value": "C39463"},
                          {"label": "Hoppstadten-Weiersbach",
                           "value": "C39464"},
                          {"label": "Hordt", "value": "C39465"},
                          {"label": "Horhausen", "value": "C39466"},
                          {"label": "Hornbach", "value": "C39467"},
                          {"label": "Huffelsheim", "value": "C39468"},
                          {"label": "Hundsangen", "value": "C39469"},
                          {"label": "Hutschenhausen", "value": "C39470"},
                          {"label": "Idar-Oberstein", "value": "C39471"},
                          {"label": "Igel", "value": "C39472"},
                          {"label": "Ilbesheim", "value": "C39473"},
                          {"label": "Imsbach", "value": "C39474"},
                          {"label": "Ingelheim am Rhein", "value": "C39475"},
                          {"label": "Insheim", "value": "C39476"},
                          {"label": "Irrel", "value": "C39477"},
                          {"label": "Irsch", "value": "C39478"},
                          {"label": "Jockgrim", "value": "C39479"},
                          {"label": "Jugenheim", "value": "C39480"},
                          {"label": "Junkerath", "value": "C39481"},
                          {"label": "Kadenbach", "value": "C39482"},
                          {"label": "Kaisersesch", "value": "C39483"},
                          {"label": "Kaiserslautern", "value": "C39484"},
                          {"label": "Kallstadt", "value": "C39485"},
                          {"label": "Kaltenengers", "value": "C39486"},
                          {"label": "Kamp-Bornhofen", "value": "C39487"},
                          {"label": "Kandel", "value": "C39488"},
                          {"label": "Kapsweyer", "value": "C39489"},
                          {"label": "Kasbach-Ohlenberg", "value": "C39490"},
                          {"label": "Kasel", "value": "C39491"},
                          {"label": "Kastellaun", "value": "C39492"},
                          {"label": "Katzenelnbogen", "value": "C39493"},
                          {"label": "Katzweiler", "value": "C39494"},
                          {"label": "Katzwinkel", "value": "C39495"},
                          {"label": "Kaub", "value": "C39496"},
                          {"label": "Kehrig", "value": "C39497"},
                          {"label": "Kelberg", "value": "C39498"},
                          {"label": "Kell", "value": "C39499"},
                          {"label": "Kempenich", "value": "C39500"},
                          {"label": "Kenn", "value": "C39501"},
                          {"label": "Kerzenheim", "value": "C39502"},
                          {"label": "Kettig", "value": "C39503"},
                          {"label": "Kindenheim", "value": "C39504"},
                          {"label": "Kinderbeuern", "value": "C39505"},
                          {"label": "Kindsbach", "value": "C39506"},
                          {"label": "Kirchberg", "value": "C39507"},
                          {"label": "Kirchen", "value": "C39508"},
                          {"label": "Kirchheim an der Weinstrasse",
                           "value": "C39509"},
                          {"label": "Kirchheimbolanden", "value": "C39510"},
                          {"label": "Kirchwald", "value": "C39511"},
                          {"label": "Kirn", "value": "C39512"},
                          {"label": "Kirrweiler", "value": "C39513"},
                          {"label": "Kirschweiler", "value": "C39514"},
                          {"label": "Klausen", "value": "C39515"},
                          {"label": "Klein-Winternheim", "value": "C39516"},
                          {"label": "Kleinmaischeid", "value": "C39517"},
                          {"label": "Klingenmunster", "value": "C39518"},
                          {"label": "Klotten", "value": "C39519"},
                          {"label": "Klusserath", "value": "C39520"},
                          {"label": "Knittelsheim", "value": "C39521"},
                          {"label": "Kobern-Gondorf", "value": "C39522"},
                          {"label": "Koblenz", "value": "C39523"},
                          {"label": "Kolbingen", "value": "C39524"},
                          {"label": "Kongernheim", "value": "C39525"},
                          {"label": "Konz", "value": "C39526"},
                          {"label": "Kordel", "value": "C39527"},
                          {"label": "Korperich", "value": "C39528"},
                          {"label": "Kottenheim", "value": "C39529"},
                          {"label": "Kottweiler-Schwanden", "value": "C39530"},
                          {"label": "Krickenbach", "value": "C39531"},
                          {"label": "Kriegsfeld", "value": "C39532"},
                          {"label": "Krov", "value": "C39533"},
                          {"label": "Kruft", "value": "C39534"},
                          {"label": "Kuhardt", "value": "C39535"},
                          {"label": "Kusel", "value": "C39536"},
                          {"label": "Kyllburg", "value": "C39537"},
                          {"label": "Lachen-Speyerdorf", "value": "C39538"},
                          {"label": "Lahnstein", "value": "C39539"},
                          {"label": "Lambrecht", "value": "C39540"},
                          {"label": "Lambsheim", "value": "C39541"},
                          {"label": "Landau in der Pfalz", "value": "C39542"},
                          {"label": "Landscheid", "value": "C39543"},
                          {"label": "Landstuhl", "value": "C39544"},
                          {"label": "Langenbach bei Marienberg",
                           "value": "C39545"},
                          {"label": "Langenhahn", "value": "C39546"},
                          {"label": "Langenlonsheim", "value": "C39547"},
                          {"label": "Langsur", "value": "C39548"},
                          {"label": "Laubach", "value": "C39549"},
                          {"label": "Lauterecken", "value": "C39550"},
                          {"label": "Lehmen", "value": "C39551"},
                          {"label": "Leimersheim", "value": "C39552"},
                          {"label": "Leiwen", "value": "C39553"},
                          {"label": "Lemberg", "value": "C39554"},
                          {"label": "Leubsdorf", "value": "C39555"},
                          {"label": "Leutesdorf", "value": "C39556"},
                          {"label": "Lieser", "value": "C39557"},
                          {"label": "Limburgerhof", "value": "C39558"},
                          {"label": "Linden", "value": "C39559"},
                          {"label": "Lindenberg", "value": "C39560"},
                          {"label": "Lingenfeld", "value": "C39561"},
                          {"label": "Linz am Rhein", "value": "C39562"},
                          {"label": "Lissendorf", "value": "C39563"},
                          {"label": "Lof", "value": "C39564"},
                          {"label": "Longkamp", "value": "C39565"},
                          {"label": "Longuich", "value": "C39566"},
                          {"label": "Lonnig", "value": "C39567"},
                          {"label": "Lorzweiler", "value": "C39568"},
                          {"label": "Ludwigshafen am Rhein", "value": "C39569"},
                          {"label": "Lustadt", "value": "C39570"},
                          {"label": "Lutzerath", "value": "C39571"},
                          {"label": "Mackenbach", "value": "C39572"},
                          {"label": "Maikammer", "value": "C39573"},
                          {"label": "Mainz", "value": "C39574"},
                          {"label": "Malborn", "value": "C39575"},
                          {"label": "Mammelzen", "value": "C39576"},
                          {"label": "Manderscheid", "value": "C39577"},
                          {"label": "Marienrachdorf", "value": "C39578"},
                          {"label": "Maring-Noviand", "value": "C39579"},
                          {"label": "Marnheim", "value": "C39580"},
                          {"label": "Martinshohe", "value": "C39581"},
                          {"label": "Masburg", "value": "C39582"},
                          {"label": "Massweiler", "value": "C39583"},
                          {"label": "Mastershausen", "value": "C39584"},
                          {"label": "Maxdorf", "value": "C39585"},
                          {"label": "Maxsain", "value": "C39586"},
                          {"label": "Mayen", "value": "C39587"},
                          {"label": "Meckenheim", "value": "C39588"},
                          {"label": "Meddersheim", "value": "C39589"},
                          {"label": "Mehlbach", "value": "C39590"},
                          {"label": "Mehlingen", "value": "C39591"},
                          {"label": "Mehren", "value": "C39592"},
                          {"label": "Mehring", "value": "C39593"},
                          {"label": "Meisenheim", "value": "C39594"},
                          {"label": "Melsbach", "value": "C39595"},
                          {"label": "Mendig", "value": "C39596"},
                          {"label": "Mertesdorf", "value": "C39597"},
                          {"label": "Mertloch", "value": "C39598"},
                          {"label": "Merxheim", "value": "C39599"},
                          {"label": "Merzalben", "value": "C39600"},
                          {"label": "Mettendorf", "value": "C39601"},
                          {"label": "Mettenheim", "value": "C39602"},
                          {"label": "Meudt", "value": "C39603"},
                          {"label": "Miehlen", "value": "C39604"},
                          {"label": "Minfeld", "value": "C39605"},
                          {"label": "Mittelhof", "value": "C39606"},
                          {"label": "Mogendorf", "value": "C39607"},
                          {"label": "Mommenheim", "value": "C39608"},
                          {"label": "Monsheim", "value": "C39609"},
                          {"label": "Montabaur", "value": "C39610"},
                          {"label": "Monzelfeld", "value": "C39611"},
                          {"label": "Monzingen", "value": "C39612"},
                          {"label": "Morbach", "value": "C39613"},
                          {"label": "Mudersbach", "value": "C39614"},
                          {"label": "Mulheim-Karlich", "value": "C39615"},
                          {"label": "Munchweiler an der Alsenz",
                           "value": "C39616"},
                          {"label": "Munchweiler an der Rodalbe",
                           "value": "C39617"},
                          {"label": "Munster-Sarmsheim", "value": "C39618"},
                          {"label": "Munstermaifeld", "value": "C39619"},
                          {"label": "Muschenbach", "value": "C39620"},
                          {"label": "Mutterstadt", "value": "C39621"},
                          {"label": "Nackenheim", "value": "C39622"},
                          {"label": "Nanzdietschweiler", "value": "C39623"},
                          {"label": "Nassau", "value": "C39624"},
                          {"label": "Nastatten", "value": "C39625"},
                          {"label": "Nauort", "value": "C39626"},
                          {"label": "Nauroth", "value": "C39627"},
                          {"label": "Nentershausen", "value": "C39628"},
                          {"label": "Neuburg", "value": "C39629"},
                          {"label": "Neuerburg", "value": "C39630"},
                          {"label": "Neuhausel", "value": "C39631"},
                          {"label": "Neuhofen", "value": "C39632"},
                          {"label": "Neumagen-Dhron", "value": "C39633"},
                          {"label": "Neunkhausen", "value": "C39634"},
                          {"label": "Neupotz", "value": "C39635"},
                          {"label": "Neustadt", "value": "C39636"},
                          {"label": "Neuwied", "value": "C39637"},
                          {"label": "Newel", "value": "C39638"},
                          {"label": "Nickenich", "value": "C39639"},
                          {"label": "Nieder-Ingelheim", "value": "C39640"},
                          {"label": "Nieder-Olm", "value": "C39641"},
                          {"label": "Niederbreitbach", "value": "C39642"},
                          {"label": "Niederdreisbach", "value": "C39643"},
                          {"label": "Niederdurenbach", "value": "C39644"},
                          {"label": "Niederelbert", "value": "C39645"},
                          {"label": "Niedererbach", "value": "C39646"},
                          {"label": "Niederfell", "value": "C39647"},
                          {"label": "Niederfischbach", "value": "C39648"},
                          {"label": "Niederkirchen", "value": "C39649"},
                          {"label": "Niederkirchen bei Deidesheim",
                           "value": "C39650"},
                          {"label": "Niedermohr", "value": "C39651"},
                          {"label": "Niederneisen", "value": "C39652"},
                          {"label": "Niederwerth", "value": "C39653"},
                          {"label": "Niederzissen", "value": "C39654"},
                          {"label": "Nierstein", "value": "C39655"},
                          {"label": "Nister", "value": "C39656"},
                          {"label": "Nittel", "value": "C39657"},
                          {"label": "Norheim", "value": "C39658"},
                          {"label": "Nortershausen", "value": "C39659"},
                          {"label": "Ober-Florsheim", "value": "C39660"},
                          {"label": "Ober-Olm", "value": "C39661"},
                          {"label": "Ober-Saulheim", "value": "C39662"},
                          {"label": "Oberelbert", "value": "C39663"},
                          {"label": "Oberfell", "value": "C39664"},
                          {"label": "Obermoschel", "value": "C39665"},
                          {"label": "Obernheim-Kirchenarnbach",
                           "value": "C39666"},
                          {"label": "Oberotterbach", "value": "C39667"},
                          {"label": "Oberwesel", "value": "C39668"},
                          {"label": "Oberzissen", "value": "C39669"},
                          {"label": "Obrigheim", "value": "C39670"},
                          {"label": "Ochtendung", "value": "C39671"},
                          {"label": "Ockenfels", "value": "C39672"},
                          {"label": "Ockenheim", "value": "C39673"},
                          {"label": "Odernheim", "value": "C39674"},
                          {"label": "Offenbach an der Queich",
                           "value": "C39675"},
                          {"label": "Offenbach-Hundheim", "value": "C39676"},
                          {"label": "Offstein", "value": "C39677"},
                          {"label": "Olsbrucken", "value": "C39678"},
                          {"label": "Oppenheim", "value": "C39679"},
                          {"label": "Orenhofen", "value": "C39680"},
                          {"label": "Osann-Monzel", "value": "C39681"},
                          {"label": "Osburg", "value": "C39682"},
                          {"label": "Osterspai", "value": "C39683"},
                          {"label": "Osthofen", "value": "C39684"},
                          {"label": "Otterbach", "value": "C39685"},
                          {"label": "Otterberg", "value": "C39686"},
                          {"label": "Ottersheim", "value": "C39687"},
                          {"label": "Otterstadt", "value": "C39688"},
                          {"label": "Otzingen", "value": "C39689"},
                          {"label": "Palzem", "value": "C39690"},
                          {"label": "Partenheim", "value": "C39691"},
                          {"label": "Pellingen", "value": "C39692"},
                          {"label": "Pelm", "value": "C39693"},
                          {"label": "Pfaffen-Schwabenheim", "value": "C39694"},
                          {"label": "Pfeffelbach", "value": "C39695"},
                          {"label": "Piesport", "value": "C39696"},
                          {"label": "Pirmasens", "value": "C39697"},
                          {"label": "Plaidt", "value": "C39698"},
                          {"label": "Pluwig", "value": "C39699"},
                          {"label": "Polch", "value": "C39700"},
                          {"label": "Pottum", "value": "C39701"},
                          {"label": "Pracht", "value": "C39702"},
                          {"label": "Prum", "value": "C39703"},
                          {"label": "Puderbach", "value": "C39704"},
                          {"label": "Queidersbach", "value": "C39705"},
                          {"label": "Ralingen", "value": "C39706"},
                          {"label": "Ramberg", "value": "C39707"},
                          {"label": "Rammelsbach", "value": "C39708"},
                          {"label": "Ramsen", "value": "C39709"},
                          {"label": "Ramstein-Miesenbach", "value": "C39710"},
                          {"label": "Ransbach-Baumbach", "value": "C39711"},
                          {"label": "Raubach", "value": "C39712"},
                          {"label": "Rehe", "value": "C39713"},
                          {"label": "Reichenbach-Steegen", "value": "C39714"},
                          {"label": "Reil", "value": "C39715"},
                          {"label": "Reinsfeld", "value": "C39716"},
                          {"label": "Remagen", "value": "C39717"},
                          {"label": "Rengsdorf", "value": "C39718"},
                          {"label": "Rennerod", "value": "C39719"},
                          {"label": "Rhaunen", "value": "C39720"},
                          {"label": "Rheinbollen", "value": "C39721"},
                          {"label": "Rheinbreitbach", "value": "C39722"},
                          {"label": "Rheinbrohl", "value": "C39723"},
                          {"label": "Rheinzabern", "value": "C39724"},
                          {"label": "Rhens", "value": "C39725"},
                          {"label": "Rhodt unter Rietburg", "value": "C39726"},
                          {"label": "Rieden", "value": "C39727"},
                          {"label": "Rieschweiler-Muhlbach", "value": "C39728"},
                          {"label": "Rimschweiler", "value": "C39729"},
                          {"label": "Riol", "value": "C39730"},
                          {"label": "Rittersdorf", "value": "C39731"},
                          {"label": "Rockenhausen", "value": "C39732"},
                          {"label": "Rodalben", "value": "C39733"},
                          {"label": "Rodenbach", "value": "C39734"},
                          {"label": "Rodersheim-Gronau", "value": "C39735"},
                          {"label": "Rohrbach", "value": "C39736"},
                          {"label": "Roth", "value": "C39737"},
                          {"label": "Roxheim", "value": "C39738"},
                          {"label": "Rudesheim", "value": "C39739"},
                          {"label": "Rulzheim", "value": "C39740"},
                          {"label": "Rummelsheim", "value": "C39741"},
                          {"label": "Ruppach-Goldhausen", "value": "C39742"},
                          {"label": "Ruppertsberg", "value": "C39743"},
                          {"label": "Ruppertsweiler", "value": "C39744"},
                          {"label": "Saarburg", "value": "C39745"},
                          {"label": "Saffig", "value": "C39746"},
                          {"label": "Salmtal", "value": "C39747"},
                          {"label": "Sankt Goar", "value": "C39748"},
                          {"label": "Sankt Goarshausen", "value": "C39749"},
                          {"label": "Sankt Julian", "value": "C39750"},
                          {"label": "Sankt Katharinen", "value": "C39751"},
                          {"label": "Sankt Martin", "value": "C39752"},
                          {"label": "Sankt Sebastian", "value": "C39753"},
                          {"label": "Scheuerfeld", "value": "C39754"},
                          {"label": "Schifferstadt", "value": "C39755"},
                          {"label": "Schillingen", "value": "C39756"},
                          {"label": "Schonecken", "value": "C39757"},
                          {"label": "Schonenberg-Kubelberg", "value": "C39758"},
                          {"label": "Schopp", "value": "C39759"},
                          {"label": "Schornsheim", "value": "C39760"},
                          {"label": "Schwabenheim", "value": "C39761"},
                          {"label": "Schwedelbach", "value": "C39762"},
                          {"label": "Schwegenheim", "value": "C39763"},
                          {"label": "Schweich", "value": "C39764"},
                          {"label": "Schweigen-Rechtenbach", "value": "C39765"},
                          {"label": "Seck", "value": "C39766"},
                          {"label": "Seibersbach", "value": "C39767"},
                          {"label": "Selters", "value": "C39768"},
                          {"label": "Selzen", "value": "C39769"},
                          {"label": "Sembach", "value": "C39770"},
                          {"label": "Serrig", "value": "C39771"},
                          {"label": "Siebeldingen", "value": "C39772"},
                          {"label": "Siefersheim", "value": "C39773"},
                          {"label": "Siershahn", "value": "C39774"},
                          {"label": "Simmern", "value": "C39775"},
                          {"label": "Simmertal", "value": "C39776"},
                          {"label": "Singhofen", "value": "C39777"},
                          {"label": "Sinzig", "value": "C39778"},
                          {"label": "Sippersfeld", "value": "C39779"},
                          {"label": "Sohren", "value": "C39780"},
                          {"label": "Sorgenloch", "value": "C39781"},
                          {"label": "Spabrucken", "value": "C39782"},
                          {"label": "Spay", "value": "C39783"},
                          {"label": "Speicher", "value": "C39784"},
                          {"label": "Speyer", "value": "C39785"},
                          {"label": "Spiesheim", "value": "C39786"},
                          {"label": "Sprendlingen", "value": "C39787"},
                          {"label": "Stadecken-Elsheim", "value": "C39788"},
                          {"label": "Stadtkyll", "value": "C39789"},
                          {"label": "Staudernheim", "value": "C39790"},
                          {"label": "Steimel", "value": "C39791"},
                          {"label": "Steinfeld", "value": "C39792"},
                          {"label": "Steinweiler", "value": "C39793"},
                          {"label": "Steinwenden", "value": "C39794"},
                          {"label": "Stelzenberg", "value": "C39795"},
                          {"label": "Stromberg", "value": "C39796"},
                          {"label": "Sulzheim", "value": "C39797"},
                          {"label": "Tawern", "value": "C39798"},
                          {"label": "Thaleischweiler-Froschen",
                           "value": "C39799"},
                          {"label": "Thalfang", "value": "C39800"},
                          {"label": "Thomm", "value": "C39801"},
                          {"label": "Thur", "value": "C39802"},
                          {"label": "Traben-Trarbach", "value": "C39803"},
                          {"label": "Trassem", "value": "C39804"},
                          {"label": "Trechtingshausen", "value": "C39805"},
                          {"label": "Treis-Karden", "value": "C39806"},
                          {"label": "Trier", "value": "C39807"},
                          {"label": "Trierweiler", "value": "C39808"},
                          {"label": "Trippstadt", "value": "C39809"},
                          {"label": "Trittenheim", "value": "C39810"},
                          {"label": "Trulben", "value": "C39811"},
                          {"label": "Udenheim", "value": "C39812"},
                          {"label": "udersdorf", "value": "C39813"},
                          {"label": "Uelversheim", "value": "C39814"},
                          {"label": "Ulmen", "value": "C39815"},
                          {"label": "Undenheim", "value": "C39816"},
                          {"label": "Unkel", "value": "C39817"},
                          {"label": "Unnau", "value": "C39818"},
                          {"label": "Urbach-uberdorf", "value": "C39819"},
                          {"label": "Urbar", "value": "C39820"},
                          {"label": "Urmitz", "value": "C39821"},
                          {"label": "uxheim", "value": "C39822"},
                          {"label": "Vallendar", "value": "C39823"},
                          {"label": "Vettelschoss", "value": "C39824"},
                          {"label": "Vinningen", "value": "C39825"},
                          {"label": "Volxheim", "value": "C39826"},
                          {"label": "Wachenheim", "value": "C39827"},
                          {"label": "Wackernheim", "value": "C39828"},
                          {"label": "Waldalgesheim", "value": "C39829"},
                          {"label": "Waldbockelheim", "value": "C39830"},
                          {"label": "Waldbreitbach", "value": "C39831"},
                          {"label": "Waldfischbach-Burgalben",
                           "value": "C39832"},
                          {"label": "Waldmohr", "value": "C39833"},
                          {"label": "Waldrach", "value": "C39834"},
                          {"label": "Waldsee", "value": "C39835"},
                          {"label": "Wallertheim", "value": "C39836"},
                          {"label": "Wallhausen", "value": "C39837"},
                          {"label": "Wallmenroth", "value": "C39838"},
                          {"label": "Wallmerod", "value": "C39839"},
                          {"label": "Wassenach", "value": "C39840"},
                          {"label": "Wasserliesch", "value": "C39841"},
                          {"label": "Wattenheim", "value": "C39842"},
                          {"label": "Waxweiler", "value": "C39843"},
                          {"label": "Wehr", "value": "C39844"},
                          {"label": "Weibern", "value": "C39845"},
                          {"label": "Weidenthal", "value": "C39846"},
                          {"label": "Weiler", "value": "C39847"},
                          {"label": "Weilerbach", "value": "C39848"},
                          {"label": "Weingarten", "value": "C39849"},
                          {"label": "Weinsheim", "value": "C39850"},
                          {"label": "Weisel", "value": "C39851"},
                          {"label": "Weissenthurm", "value": "C39852"},
                          {"label": "Weitefeld", "value": "C39853"},
                          {"label": "Weitersburg", "value": "C39854"},
                          {"label": "Welschbillig", "value": "C39855"},
                          {"label": "Wendelsheim", "value": "C39856"},
                          {"label": "Wernersberg", "value": "C39857"},
                          {"label": "Weselberg", "value": "C39858"},
                          {"label": "Westerburg", "value": "C39859"},
                          {"label": "Westheim", "value": "C39860"},
                          {"label": "Westhofen", "value": "C39861"},
                          {"label": "Weyerbusch", "value": "C39862"},
                          {"label": "Wilgartswiesen", "value": "C39863"},
                          {"label": "Wiltingen", "value": "C39864"},
                          {"label": "Wincheringen", "value": "C39865"},
                          {"label": "Winden", "value": "C39866"},
                          {"label": "Windesheim", "value": "C39867"},
                          {"label": "Windhagen", "value": "C39868"},
                          {"label": "Winningen", "value": "C39869"},
                          {"label": "Winnweiler", "value": "C39870"},
                          {"label": "Wirges", "value": "C39871"},
                          {"label": "Wissen", "value": "C39872"},
                          {"label": "Wittlich", "value": "C39873"},
                          {"label": "Wolfstein", "value": "C39874"},
                          {"label": "Wolken", "value": "C39875"},
                          {"label": "Wollstein", "value": "C39876"},
                          {"label": "Worms", "value": "C39877"},
                          {"label": "Worrstadt", "value": "C39878"},
                          {"label": "Worth am Rhein", "value": "C39879"},
                          {"label": "Zeiskam", "value": "C39880"},
                          {"label": "Zell", "value": "C39881"},
                          {"label": "Zeltingen-Rachtig", "value": "C39882"},
                          {"label": "Zemmer", "value": "C39883"},
                          {"label": "Zerf", "value": "C39884"},
                          {"label": "Zornheim", "value": "C39885"},
                          {"label": "Zweibrucken", "value": "C39886"}],
 "Rhode Island": [{"label": "Ashaway", "value": "C142631"},
                  {"label": "Barrington", "value": "C142632"},
                  {"label": "Bradford", "value": "C142633"},
                  {"label": "Bristol", "value": "C142634"},
                  {"label": "Bristol County", "value": "C142635"},
                  {"label": "Central Falls", "value": "C142636"},
                  {"label": "Charlestown", "value": "C142637"},
                  {"label": "Chepachet", "value": "C142638"},
                  {"label": "Coventry", "value": "C142639"},
                  {"label": "Cranston", "value": "C142640"},
                  {"label": "Cumberland", "value": "C142641"},
                  {"label": "Cumberland Hill", "value": "C142642"},
                  {"label": "East Greenwich", "value": "C142643"},
                  {"label": "East Providence", "value": "C142644"},
                  {"label": "Exeter", "value": "C142645"},
                  {"label": "Foster", "value": "C142646"},
                  {"label": "Greenville", "value": "C142647"},
                  {"label": "Harrisville", "value": "C142648"},
                  {"label": "Hope Valley", "value": "C142649"},
                  {"label": "Hopkinton", "value": "C142650"},
                  {"label": "Jamestown", "value": "C142651"},
                  {"label": "Johnston", "value": "C142652"},
                  {"label": "Kent County", "value": "C142653"},
                  {"label": "Kingston", "value": "C142654"},
                  {"label": "Lincoln", "value": "C142655"},
                  {"label": "Melville", "value": "C142656"},
                  {"label": "Middletown", "value": "C142657"},
                  {"label": "Narragansett", "value": "C142658"},
                  {"label": "Narragansett Pier", "value": "C142659"},
                  {"label": "New Shoreham", "value": "C142660"},
                  {"label": "Newport", "value": "C142661"},
                  {"label": "Newport County", "value": "C142662"},
                  {"label": "Newport East", "value": "C142663"},
                  {"label": "North Kingstown", "value": "C142664"},
                  {"label": "North Providence", "value": "C142665"},
                  {"label": "North Scituate", "value": "C142666"},
                  {"label": "North Smithfield", "value": "C142667"},
                  {"label": "Pascoag", "value": "C142668"},
                  {"label": "Pawtucket", "value": "C142669"},
                  {"label": "Portsmouth", "value": "C142670"},
                  {"label": "Providence", "value": "C142671"},
                  {"label": "Providence County", "value": "C142672"},
                  {"label": "Smithfield", "value": "C142673"},
                  {"label": "South Kingstown", "value": "C142674"},
                  {"label": "Tiverton", "value": "C142675"},
                  {"label": "Valley Falls", "value": "C142676"},
                  {"label": "Wakefield-Peacedale", "value": "C142677"},
                  {"label": "Warren", "value": "C142678"},
                  {"label": "Warwick", "value": "C142679"},
                  {"label": "Washington County", "value": "C142680"},
                  {"label": "West Greenwich", "value": "C142681"},
                  {"label": "West Warwick", "value": "C142682"},
                  {"label": "Westerly", "value": "C142683"},
                  {"label": "Woonsocket", "value": "C142684"}],
 "Riau": [{"label": "Balaipungut", "value": "C49823"},
          {"label": "Batam", "value": "C49824"},
          {"label": "Dumai", "value": "C49825"},
          {"label": "Kabupaten Bengkalis", "value": "C49826"},
          {"label": "Kabupaten Indragiri Hilir", "value": "C49827"},
          {"label": "Kabupaten Indragiri Hulu", "value": "C49828"},
          {"label": "Kabupaten Kampar", "value": "C49829"},
          {"label": "Kabupaten Kepulauan Meranti", "value": "C49830"},
          {"label": "Kabupaten Kuantan Singingi", "value": "C49831"},
          {"label": "Kabupaten Pelalawan", "value": "C49832"},
          {"label": "Kabupaten Rokan Hilir", "value": "C49833"},
          {"label": "Kabupaten Rokan Hulu", "value": "C49834"},
          {"label": "Kabupaten Siak", "value": "C49835"},
          {"label": "Kota Dumai", "value": "C49836"},
          {"label": "Kota Pekanbaru", "value": "C49837"},
          {"label": "Pekanbaru", "value": "C49838"}],
 "Ribeira Brava Municipality": [{"label": "Ribeira Brava", "value": "C17791"}],
 "Ribeira Grande": [{"label": "Ponta do Sol", "value": "C17792"},
                    {"label": "Ribeira Grande", "value": "C17793"}],
 "Ribeira Grande de Santiago": [{"label": "Cidade Velha", "value": "C17794"}],
 "Ribnica Municipality": [{"label": "Ribnica", "value": "C107785"}],
 "Ribnica na Pohorju Municipality": [{"label": "Ribnica na Pohorju",
                                      "value": "C107786"}],
 "Riebini Municipality": [{"label": "Riebini", "value": "C64919"}],
 "Rif Dimashq Governorate": [{"label": "Irbin", "value": "C117930"},
                             {"label": "Al Kiswah", "value": "C117931"},
                             {"label": "Al Qutayfah", "value": "C117932"},
                             {"label": "Al-Zabadani District",
                              "value": "C117933"},
                             {"label": "An Nabk", "value": "C117934"},
                             {"label": "At Tall", "value": "C117935"},
                             {"label": "Az Zabadani", "value": "C117936"},
                             {"label": "Darayya", "value": "C117937"},
                             {"label": "Dayr al Asafir", "value": "C117938"},
                             {"label": "Douma", "value": "C117939"},
                             {"label": "Harasta", "value": "C117940"},
                             {"label": "Jaramana", "value": "C117941"},
                             {"label": "Jayrud", "value": "C117942"},
                             {"label": "Malula", "value": "C117943"},
                             {"label": "Medaya", "value": "C117944"},
                             {"label": "Qarah", "value": "C117945"},
                             {"label": "Qatana", "value": "C117946"},
                             {"label": "Saydnaya", "value": "C117947"},
                             {"label": "Yabrud", "value": "C117948"}],
 "Riga": [{"label": "Bolderaja", "value": "C64920"},
          {"label": "Daugavgriva", "value": "C64921"},
          {"label": "Jaunciems", "value": "C64922"},
          {"label": "Mezaparks", "value": "C64923"},
          {"label": "Riga", "value": "C64924"}],
 "Rio Grande do Norte": [{"label": "Acari", "value": "C13916"},
                         {"label": "Acu", "value": "C13917"},
                         {"label": "Afonso Bezerra", "value": "C13918"},
                         {"label": "agua Nova", "value": "C13919"},
                         {"label": "Alexandria", "value": "C13920"},
                         {"label": "Almino Afonso", "value": "C13921"},
                         {"label": "Alto do Rodrigues", "value": "C13922"},
                         {"label": "Angicos", "value": "C13923"},
                         {"label": "Antonio Martins", "value": "C13924"},
                         {"label": "Apodi", "value": "C13925"},
                         {"label": "Areia Branca", "value": "C13926"},
                         {"label": "Ares", "value": "C13927"},
                         {"label": "Augusto Severo", "value": "C13928"},
                         {"label": "Baia Formosa", "value": "C13929"},
                         {"label": "Barauna", "value": "C13930"},
                         {"label": "Barcelona", "value": "C13931"},
                         {"label": "Bento Fernandes", "value": "C13932"},
                         {"label": "Bodo", "value": "C13933"},
                         {"label": "Bom Jesus", "value": "C13934"},
                         {"label": "Brejinho", "value": "C13935"},
                         {"label": "Caicara do Norte", "value": "C13936"},
                         {"label": "Caicara do Rio do Vento",
                          "value": "C13937"},
                         {"label": "Caico", "value": "C13938"},
                         {"label": "Campo Redondo", "value": "C13939"},
                         {"label": "Canguaretama", "value": "C13940"},
                         {"label": "Caraubas", "value": "C13941"},
                         {"label": "Carnauba dos Dantas", "value": "C13942"},
                         {"label": "Carnaubais", "value": "C13943"},
                         {"label": "Ceara Mirim", "value": "C13944"},
                         {"label": "Ceara-Mirim", "value": "C13945"},
                         {"label": "Cerro Cora", "value": "C13946"},
                         {"label": "Coronel Ezequiel", "value": "C13947"},
                         {"label": "Coronel Joao Pessoa", "value": "C13948"},
                         {"label": "Cruzeta", "value": "C13949"},
                         {"label": "Currais Novos", "value": "C13950"},
                         {"label": "Doutor Severiano", "value": "C13951"},
                         {"label": "Encanto", "value": "C13952"},
                         {"label": "Equador", "value": "C13953"},
                         {"label": "Espirito Santo", "value": "C13954"},
                         {"label": "Extremoz", "value": "C13955"},
                         {"label": "Felipe Guerra", "value": "C13956"},
                         {"label": "Fernando Pedroza", "value": "C13957"},
                         {"label": "Florania", "value": "C13958"},
                         {"label": "Francisco Dantas", "value": "C13959"},
                         {"label": "Frutuoso Gomes", "value": "C13960"},
                         {"label": "Galinhos", "value": "C13961"},
                         {"label": "Goianinha", "value": "C13962"},
                         {"label": "Governador Dix Sept Rosado",
                          "value": "C13963"},
                         {"label": "Governador Dix-Sept Rosado",
                          "value": "C13964"},
                         {"label": "Grossos", "value": "C13965"},
                         {"label": "Guamare", "value": "C13966"},
                         {"label": "Ielmo Marinho", "value": "C13967"},
                         {"label": "Ipanguacu", "value": "C13968"},
                         {"label": "Ipueira", "value": "C13969"},
                         {"label": "Itaja", "value": "C13970"},
                         {"label": "Itau", "value": "C13971"},
                         {"label": "Jacana", "value": "C13972"},
                         {"label": "Jandaira", "value": "C13973"},
                         {"label": "Janduis", "value": "C13974"},
                         {"label": "Januario Cicco", "value": "C13975"},
                         {"label": "Japi", "value": "C13976"},
                         {"label": "Jardim de Angicos", "value": "C13977"},
                         {"label": "Jardim de Piranhas", "value": "C13978"},
                         {"label": "Jardim do Serido", "value": "C13979"},
                         {"label": "Joao Camara", "value": "C13980"},
                         {"label": "Joao Dias", "value": "C13981"},
                         {"label": "Jose da Penha", "value": "C13982"},
                         {"label": "Jucurutu", "value": "C13983"},
                         {"label": "Jundia", "value": "C13984"},
                         {"label": "Lagoa dAnta", "value": "C13985"},
                         {"label": "Lagoa de Pedras", "value": "C13986"},
                         {"label": "Lagoa de Velhos", "value": "C13987"},
                         {"label": "Lagoa Nova", "value": "C13988"},
                         {"label": "Lagoa Salgada", "value": "C13989"},
                         {"label": "Lajes", "value": "C13990"},
                         {"label": "Lajes Pintadas", "value": "C13991"},
                         {"label": "Lucrecia", "value": "C13992"},
                         {"label": "Luis Gomes", "value": "C13993"},
                         {"label": "Macaiba", "value": "C13994"},
                         {"label": "Macau", "value": "C13995"},
                         {"label": "Major Sales", "value": "C13996"},
                         {"label": "Marcelino Vieira", "value": "C13997"},
                         {"label": "Martins", "value": "C13998"},
                         {"label": "Maxaranguape", "value": "C13999"},
                         {"label": "Messias Targino", "value": "C14000"},
                         {"label": "Montanhas", "value": "C14001"},
                         {"label": "Monte Alegre", "value": "C14002"},
                         {"label": "Monte das Gameleiras", "value": "C14003"},
                         {"label": "Mossoro", "value": "C14004"},
                         {"label": "Natal", "value": "C14005"},
                         {"label": "Nisia Floresta", "value": "C14006"},
                         {"label": "Nova Cruz", "value": "C14007"},
                         {"label": "Olho-dagua do Borges", "value": "C14008"},
                         {"label": "Ouro Branco", "value": "C14009"},
                         {"label": "Parana", "value": "C14010"},
                         {"label": "Parau", "value": "C14011"},
                         {"label": "Parazinho", "value": "C14012"},
                         {"label": "Parelhas", "value": "C14013"},
                         {"label": "Parnamirim", "value": "C14014"},
                         {"label": "Passa e Fica", "value": "C14015"},
                         {"label": "Passagem", "value": "C14016"},
                         {"label": "Patu", "value": "C14017"},
                         {"label": "Pau dos Ferros", "value": "C14018"},
                         {"label": "Pedra Grande", "value": "C14019"},
                         {"label": "Pedra Preta", "value": "C14020"},
                         {"label": "Pedro Avelino", "value": "C14021"},
                         {"label": "Pedro Velho", "value": "C14022"},
                         {"label": "Pendencias", "value": "C14023"},
                         {"label": "Piloes", "value": "C14024"},
                         {"label": "Poco Branco", "value": "C14025"},
                         {"label": "Portalegre", "value": "C14026"},
                         {"label": "Porto do Mangue", "value": "C14027"},
                         {"label": "Pureza", "value": "C14028"},
                         {"label": "Rafael Fernandes", "value": "C14029"},
                         {"label": "Rafael Godeiro", "value": "C14030"},
                         {"label": "Riacho da Cruz", "value": "C14031"},
                         {"label": "Riacho de Santana", "value": "C14032"},
                         {"label": "Riachuelo", "value": "C14033"},
                         {"label": "Rio do Fogo", "value": "C14034"},
                         {"label": "Rodolfo Fernandes", "value": "C14035"},
                         {"label": "Ruy Barbosa", "value": "C14036"},
                         {"label": "Santa Cruz", "value": "C14037"},
                         {"label": "Santa Maria", "value": "C14038"},
                         {"label": "Santana do Matos", "value": "C14039"},
                         {"label": "Santana do Serido", "value": "C14040"},
                         {"label": "Santo Antonio", "value": "C14041"},
                         {"label": "Sao Bento do Norte", "value": "C14042"},
                         {"label": "Sao Bento do Trairi", "value": "C14043"},
                         {"label": "Sao Fernando", "value": "C14044"},
                         {"label": "Sao Francisco do Oeste", "value": "C14045"},
                         {"label": "Sao Goncalo do Amarante",
                          "value": "C14046"},
                         {"label": "Sao Joao do Sabugi", "value": "C14047"},
                         {"label": "Sao Jose de Mipibu", "value": "C14048"},
                         {"label": "Sao Jose do Campestre", "value": "C14049"},
                         {"label": "Sao Jose do Serido", "value": "C14050"},
                         {"label": "Sao Miguel", "value": "C14051"},
                         {"label": "Sao Miguel do Gostoso", "value": "C14052"},
                         {"label": "Sao Paulo do Potengi", "value": "C14053"},
                         {"label": "Sao Pedro", "value": "C14054"},
                         {"label": "Sao Rafael", "value": "C14055"},
                         {"label": "Sao Tome", "value": "C14056"},
                         {"label": "Sao Vicente", "value": "C14057"},
                         {"label": "Senador Eloi de Souza", "value": "C14058"},
                         {"label": "Senador Georgino Avelino",
                          "value": "C14059"},
                         {"label": "Serra Caiada", "value": "C14060"},
                         {"label": "Serra de Sao Bento", "value": "C14061"},
                         {"label": "Serra do Mel", "value": "C14062"},
                         {"label": "Serra Negra do Norte", "value": "C14063"},
                         {"label": "Serrinha", "value": "C14064"},
                         {"label": "Serrinha dos Pintos", "value": "C14065"},
                         {"label": "Severiano Melo", "value": "C14066"},
                         {"label": "Sitio Novo", "value": "C14067"},
                         {"label": "Taboleiro Grande", "value": "C14068"},
                         {"label": "Taipu", "value": "C14069"},
                         {"label": "Tangara", "value": "C14070"},
                         {"label": "Tenente Ananias", "value": "C14071"},
                         {"label": "Tenente Laurentino Cruz",
                          "value": "C14072"},
                         {"label": "Tibau", "value": "C14073"},
                         {"label": "Tibau do Sul", "value": "C14074"},
                         {"label": "Timbauba dos Batistas", "value": "C14075"},
                         {"label": "Touros", "value": "C14076"},
                         {"label": "Triunfo Potiguar", "value": "C14077"},
                         {"label": "Umarizal", "value": "C14078"},
                         {"label": "Upanema", "value": "C14079"},
                         {"label": "Varzea", "value": "C14080"},
                         {"label": "Venha-Ver", "value": "C14081"},
                         {"label": "Vera Cruz", "value": "C14082"},
                         {"label": "Vicosa", "value": "C14083"},
                         {"label": "Vila Flor", "value": "C14084"}],
 "Rio Grande do Sul": [{"label": "Acegua", "value": "C14085"},
                       {"label": "agua Santa", "value": "C14086"},
                       {"label": "Agudo", "value": "C14087"},
                       {"label": "Ajuricaba", "value": "C14088"},
                       {"label": "Alecrim", "value": "C14089"},
                       {"label": "Alegrete", "value": "C14090"},
                       {"label": "Alegria", "value": "C14091"},
                       {"label": "Almirante Tamandare do Sul",
                        "value": "C14092"},
                       {"label": "Alpestre", "value": "C14093"},
                       {"label": "Alto Alegre", "value": "C14094"},
                       {"label": "Alto Feliz", "value": "C14095"},
                       {"label": "Alvorada", "value": "C14096"},
                       {"label": "Amaral Ferrador", "value": "C14097"},
                       {"label": "Ametista do Sul", "value": "C14098"},
                       {"label": "Andre da Rocha", "value": "C14099"},
                       {"label": "Anta Gorda", "value": "C14100"},
                       {"label": "Antonio Prado", "value": "C14101"},
                       {"label": "Arambare", "value": "C14102"},
                       {"label": "Ararica", "value": "C14103"},
                       {"label": "Aratiba", "value": "C14104"},
                       {"label": "Arroio do Meio", "value": "C14105"},
                       {"label": "Arroio do Padre", "value": "C14106"},
                       {"label": "Arroio do Sal", "value": "C14107"},
                       {"label": "Arroio do Tigre", "value": "C14108"},
                       {"label": "Arroio dos Ratos", "value": "C14109"},
                       {"label": "Arroio Grande", "value": "C14110"},
                       {"label": "Arvorezinha", "value": "C14111"},
                       {"label": "Augusto Pestana", "value": "C14112"},
                       {"label": "aurea", "value": "C14113"},
                       {"label": "Bage", "value": "C14114"},
                       {"label": "Balneario Pinhal", "value": "C14115"},
                       {"label": "Barao", "value": "C14116"},
                       {"label": "Barao de Cotegipe", "value": "C14117"},
                       {"label": "Barao do Triunfo", "value": "C14118"},
                       {"label": "Barra do Guarita", "value": "C14119"},
                       {"label": "Barra do Quarai", "value": "C14120"},
                       {"label": "Barra do Ribeiro", "value": "C14121"},
                       {"label": "Barra do Rio Azul", "value": "C14122"},
                       {"label": "Barra Funda", "value": "C14123"},
                       {"label": "Barracao", "value": "C14124"},
                       {"label": "Barros Cassal", "value": "C14125"},
                       {"label": "Benjamin Constant do Sul", "value": "C14126"},
                       {"label": "Bento Goncalves", "value": "C14127"},
                       {"label": "Boa Vista das Missoes", "value": "C14128"},
                       {"label": "Boa Vista do Burica", "value": "C14129"},
                       {"label": "Boa Vista do Cadeado", "value": "C14130"},
                       {"label": "Boa Vista do Incra", "value": "C14131"},
                       {"label": "Boa Vista do Sul", "value": "C14132"},
                       {"label": "Bom Jesus", "value": "C14133"},
                       {"label": "Bom Principio", "value": "C14134"},
                       {"label": "Bom Progresso", "value": "C14135"},
                       {"label": "Bom Retiro do Sul", "value": "C14136"},
                       {"label": "Boqueirao do Leao", "value": "C14137"},
                       {"label": "Bossoroca", "value": "C14138"},
                       {"label": "Bozano", "value": "C14139"},
                       {"label": "Braga", "value": "C14140"},
                       {"label": "Brochier", "value": "C14141"},
                       {"label": "Butia", "value": "C14142"},
                       {"label": "Cacapava do Sul", "value": "C14143"},
                       {"label": "Cacequi", "value": "C14144"},
                       {"label": "Cachoeira do Sul", "value": "C14145"},
                       {"label": "Cachoeirinha", "value": "C14146"},
                       {"label": "Cacique Doble", "value": "C14147"},
                       {"label": "Caibate", "value": "C14148"},
                       {"label": "Caicara", "value": "C14149"},
                       {"label": "Camaqua", "value": "C14150"},
                       {"label": "Camargo", "value": "C14151"},
                       {"label": "Cambara do Sul", "value": "C14152"},
                       {"label": "Campestre da Serra", "value": "C14153"},
                       {"label": "Campina das Missoes", "value": "C14154"},
                       {"label": "Campinas do Sul", "value": "C14155"},
                       {"label": "Campo Bom", "value": "C14156"},
                       {"label": "Campo Novo", "value": "C14157"},
                       {"label": "Campos Borges", "value": "C14158"},
                       {"label": "Candelaria", "value": "C14159"},
                       {"label": "Candido Godoi", "value": "C14160"},
                       {"label": "Candiota", "value": "C14161"},
                       {"label": "Canela", "value": "C14162"},
                       {"label": "Cangucu", "value": "C14163"},
                       {"label": "Canoas", "value": "C14164"},
                       {"label": "Canudos do Vale", "value": "C14165"},
                       {"label": "Capao Bonito do Sul", "value": "C14166"},
                       {"label": "Capao da Canoa", "value": "C14167"},
                       {"label": "Capao do Cipo", "value": "C14168"},
                       {"label": "Capao do Leao", "value": "C14169"},
                       {"label": "Capela de Santana", "value": "C14170"},
                       {"label": "Capitao", "value": "C14171"},
                       {"label": "Capivari do Sul", "value": "C14172"},
                       {"label": "Caraa", "value": "C14173"},
                       {"label": "Carazinho", "value": "C14174"},
                       {"label": "Carlos Barbosa", "value": "C14175"},
                       {"label": "Carlos Gomes", "value": "C14176"},
                       {"label": "Casca", "value": "C14177"},
                       {"label": "Caseiros", "value": "C14178"},
                       {"label": "Catuipe", "value": "C14179"},
                       {"label": "Caxias do Sul", "value": "C14180"},
                       {"label": "Centenario", "value": "C14181"},
                       {"label": "Cerrito", "value": "C14182"},
                       {"label": "Cerro Branco", "value": "C14183"},
                       {"label": "Cerro Grande", "value": "C14184"},
                       {"label": "Cerro Grande do Sul", "value": "C14185"},
                       {"label": "Cerro Largo", "value": "C14186"},
                       {"label": "Chapada", "value": "C14187"},
                       {"label": "Charqueadas", "value": "C14188"},
                       {"label": "Charrua", "value": "C14189"},
                       {"label": "Chiapetta", "value": "C14190"},
                       {"label": "Chui", "value": "C14191"},
                       {"label": "Chuvisca", "value": "C14192"},
                       {"label": "Cidreira", "value": "C14193"},
                       {"label": "Ciriaco", "value": "C14194"},
                       {"label": "Colinas", "value": "C14195"},
                       {"label": "Colorado", "value": "C14196"},
                       {"label": "Condor", "value": "C14197"},
                       {"label": "Constantina", "value": "C14198"},
                       {"label": "Coqueiro Baixo", "value": "C14199"},
                       {"label": "Coqueiros do Sul", "value": "C14200"},
                       {"label": "Coronel Barros", "value": "C14201"},
                       {"label": "Coronel Bicaco", "value": "C14202"},
                       {"label": "Coronel Pilar", "value": "C14203"},
                       {"label": "Cotipora", "value": "C14204"},
                       {"label": "Coxilha", "value": "C14205"},
                       {"label": "Crissiumal", "value": "C14206"},
                       {"label": "Cristal", "value": "C14207"},
                       {"label": "Cristal do Sul", "value": "C14208"},
                       {"label": "Cruz Alta", "value": "C14209"},
                       {"label": "Cruzaltense", "value": "C14210"},
                       {"label": "Cruzeiro do Sul", "value": "C14211"},
                       {"label": "David Canabarro", "value": "C14212"},
                       {"label": "Derrubadas", "value": "C14213"},
                       {"label": "Dezesseis de Novembro", "value": "C14214"},
                       {"label": "Dilermando de Aguiar", "value": "C14215"},
                       {"label": "Dois Irmaos", "value": "C14216"},
                       {"label": "Dois Irmaos das Missoes", "value": "C14217"},
                       {"label": "Dois Lajeados", "value": "C14218"},
                       {"label": "Dom Feliciano", "value": "C14219"},
                       {"label": "Dom Pedrito", "value": "C14220"},
                       {"label": "Dom Pedro de Alcantara", "value": "C14221"},
                       {"label": "Dona Francisca", "value": "C14222"},
                       {"label": "Doutor Mauricio Cardoso", "value": "C14223"},
                       {"label": "Doutor Ricardo", "value": "C14224"},
                       {"label": "Eldorado do Sul", "value": "C14225"},
                       {"label": "Encantado", "value": "C14226"},
                       {"label": "Encruzilhada do Sul", "value": "C14227"},
                       {"label": "Engenho Velho", "value": "C14228"},
                       {"label": "Entre Rios do Sul", "value": "C14229"},
                       {"label": "Entre-Ijuis", "value": "C14230"},
                       {"label": "Erebango", "value": "C14231"},
                       {"label": "Erechim", "value": "C14232"},
                       {"label": "Ernestina", "value": "C14233"},
                       {"label": "Erval Grande", "value": "C14234"},
                       {"label": "Erval Seco", "value": "C14235"},
                       {"label": "Esmeralda", "value": "C14236"},
                       {"label": "Esperanca do Sul", "value": "C14237"},
                       {"label": "Espumoso", "value": "C14238"},
                       {"label": "Estacao", "value": "C14239"},
                       {"label": "Estancia Velha", "value": "C14240"},
                       {"label": "Esteio", "value": "C14241"},
                       {"label": "Estrela", "value": "C14242"},
                       {"label": "Estrela Velha", "value": "C14243"},
                       {"label": "Eugenio de Castro", "value": "C14244"},
                       {"label": "Fagundes Varela", "value": "C14245"},
                       {"label": "Farroupilha", "value": "C14246"},
                       {"label": "Faxinal do Soturno", "value": "C14247"},
                       {"label": "Faxinalzinho", "value": "C14248"},
                       {"label": "Fazenda Vilanova", "value": "C14249"},
                       {"label": "Feliz", "value": "C14250"},
                       {"label": "Flores da Cunha", "value": "C14251"},
                       {"label": "Floriano Peixoto", "value": "C14252"},
                       {"label": "Fontoura Xavier", "value": "C14253"},
                       {"label": "Formigueiro", "value": "C14254"},
                       {"label": "Forquetinha", "value": "C14255"},
                       {"label": "Fortaleza dos Valos", "value": "C14256"},
                       {"label": "Frederico Westphalen", "value": "C14257"},
                       {"label": "Garibaldi", "value": "C14258"},
                       {"label": "Garruchos", "value": "C14259"},
                       {"label": "Gaurama", "value": "C14260"},
                       {"label": "General Camara", "value": "C14261"},
                       {"label": "Gentil", "value": "C14262"},
                       {"label": "Getulio Vargas", "value": "C14263"},
                       {"label": "Girua", "value": "C14264"},
                       {"label": "Glorinha", "value": "C14265"},
                       {"label": "Gramado", "value": "C14266"},
                       {"label": "Gramado dos Loureiros", "value": "C14267"},
                       {"label": "Gramado Xavier", "value": "C14268"},
                       {"label": "Gravatai", "value": "C14269"},
                       {"label": "Guabiju", "value": "C14270"},
                       {"label": "Guaiba", "value": "C14271"},
                       {"label": "Guapore", "value": "C14272"},
                       {"label": "Guarani das Missoes", "value": "C14273"},
                       {"label": "Harmonia", "value": "C14274"},
                       {"label": "Herval", "value": "C14275"},
                       {"label": "Herveiras", "value": "C14276"},
                       {"label": "Horizontina", "value": "C14277"},
                       {"label": "Hulha Negra", "value": "C14278"},
                       {"label": "Humaita", "value": "C14279"},
                       {"label": "Ibarama", "value": "C14280"},
                       {"label": "Ibiaca", "value": "C14281"},
                       {"label": "Ibiraiaras", "value": "C14282"},
                       {"label": "Ibirapuita", "value": "C14283"},
                       {"label": "Ibiruba", "value": "C14284"},
                       {"label": "Igrejinha", "value": "C14285"},
                       {"label": "Ijui", "value": "C14286"},
                       {"label": "Ilopolis", "value": "C14287"},
                       {"label": "Imbe", "value": "C14288"},
                       {"label": "Imigrante", "value": "C14289"},
                       {"label": "Independencia", "value": "C14290"},
                       {"label": "Inhacora", "value": "C14291"},
                       {"label": "Ipe", "value": "C14292"},
                       {"label": "Ipiranga do Sul", "value": "C14293"},
                       {"label": "Irai", "value": "C14294"},
                       {"label": "Itaara", "value": "C14295"},
                       {"label": "Itacurubi", "value": "C14296"},
                       {"label": "Itapuca", "value": "C14297"},
                       {"label": "Itaqui", "value": "C14298"},
                       {"label": "Itati", "value": "C14299"},
                       {"label": "Itatiba do Sul", "value": "C14300"},
                       {"label": "Ivora", "value": "C14301"},
                       {"label": "Ivoti", "value": "C14302"},
                       {"label": "Jaboticaba", "value": "C14303"},
                       {"label": "Jacuizinho", "value": "C14304"},
                       {"label": "Jacutinga", "value": "C14305"},
                       {"label": "Jaguarao", "value": "C14306"},
                       {"label": "Jaguari", "value": "C14307"},
                       {"label": "Jaquirana", "value": "C14308"},
                       {"label": "Jari", "value": "C14309"},
                       {"label": "Joia", "value": "C14310"},
                       {"label": "Julio de Castilhos", "value": "C14311"},
                       {"label": "Lagoa Bonita do Sul", "value": "C14312"},
                       {"label": "Lagoa dos Tres Cantos", "value": "C14313"},
                       {"label": "Lagoa Vermelha", "value": "C14314"},
                       {"label": "Lagoao", "value": "C14315"},
                       {"label": "Lajeado", "value": "C14316"},
                       {"label": "Lajeado do Bugre", "value": "C14317"},
                       {"label": "Lavras do Sul", "value": "C14318"},
                       {"label": "Liberato Salzano", "value": "C14319"},
                       {"label": "Lindolfo Collor", "value": "C14320"},
                       {"label": "Linha Nova", "value": "C14321"},
                       {"label": "Macambara", "value": "C14322"},
                       {"label": "Machadinho", "value": "C14323"},
                       {"label": "Mampituba", "value": "C14324"},
                       {"label": "Manoel Viana", "value": "C14325"},
                       {"label": "Maquine", "value": "C14326"},
                       {"label": "Marata", "value": "C14327"},
                       {"label": "Marau", "value": "C14328"},
                       {"label": "Marcelino Ramos", "value": "C14329"},
                       {"label": "Mariana Pimentel", "value": "C14330"},
                       {"label": "Mariano Moro", "value": "C14331"},
                       {"label": "Marques de Souza", "value": "C14332"},
                       {"label": "Mata", "value": "C14333"},
                       {"label": "Mato Castelhano", "value": "C14334"},
                       {"label": "Mato Leitao", "value": "C14335"},
                       {"label": "Mato Queimado", "value": "C14336"},
                       {"label": "Maximiliano de Almeida", "value": "C14337"},
                       {"label": "Minas do Leao", "value": "C14338"},
                       {"label": "Miraguai", "value": "C14339"},
                       {"label": "Montauri", "value": "C14340"},
                       {"label": "Monte Alegre dos Campos", "value": "C14341"},
                       {"label": "Monte Belo do Sul", "value": "C14342"},
                       {"label": "Montenegro", "value": "C14343"},
                       {"label": "Mormaco", "value": "C14344"},
                       {"label": "Morrinhos do Sul", "value": "C14345"},
                       {"label": "Morro Redondo", "value": "C14346"},
                       {"label": "Morro Reuter", "value": "C14347"},
                       {"label": "Mostardas", "value": "C14348"},
                       {"label": "Mucum", "value": "C14349"},
                       {"label": "Muitos Capoes", "value": "C14350"},
                       {"label": "Muliterno", "value": "C14351"},
                       {"label": "Nao Me Toque", "value": "C14352"},
                       {"label": "Nao-Me-Toque", "value": "C14353"},
                       {"label": "Nicolau Vergueiro", "value": "C14354"},
                       {"label": "Nonoai", "value": "C14355"},
                       {"label": "Nova Alvorada", "value": "C14356"},
                       {"label": "Nova Araca", "value": "C14357"},
                       {"label": "Nova Bassano", "value": "C14358"},
                       {"label": "Nova Boa Vista", "value": "C14359"},
                       {"label": "Nova Brescia", "value": "C14360"},
                       {"label": "Nova Candelaria", "value": "C14361"},
                       {"label": "Nova Esperanca do Sul", "value": "C14362"},
                       {"label": "Nova Hartz", "value": "C14363"},
                       {"label": "Nova Padua", "value": "C14364"},
                       {"label": "Nova Palma", "value": "C14365"},
                       {"label": "Nova Petropolis", "value": "C14366"},
                       {"label": "Nova Prata", "value": "C14367"},
                       {"label": "Nova Ramada", "value": "C14368"},
                       {"label": "Nova Roma do Sul", "value": "C14369"},
                       {"label": "Nova Santa Rita", "value": "C14370"},
                       {"label": "Novo Barreiro", "value": "C14371"},
                       {"label": "Novo Cabrais", "value": "C14372"},
                       {"label": "Novo Hamburgo", "value": "C14373"},
                       {"label": "Novo Machado", "value": "C14374"},
                       {"label": "Novo Tiradentes", "value": "C14375"},
                       {"label": "Novo Xingu", "value": "C14376"},
                       {"label": "Osorio", "value": "C14377"},
                       {"label": "Paim Filho", "value": "C14378"},
                       {"label": "Palmares do Sul", "value": "C14379"},
                       {"label": "Palmeira das Missoes", "value": "C14380"},
                       {"label": "Palmitinho", "value": "C14381"},
                       {"label": "Panambi", "value": "C14382"},
                       {"label": "Pantano Grande", "value": "C14383"},
                       {"label": "Parai", "value": "C14384"},
                       {"label": "Paraiso do Sul", "value": "C14385"},
                       {"label": "Pareci Novo", "value": "C14386"},
                       {"label": "Parobe", "value": "C14387"},
                       {"label": "Passa Sete", "value": "C14388"},
                       {"label": "Passo do Sobrado", "value": "C14389"},
                       {"label": "Passo Fundo", "value": "C14390"},
                       {"label": "Paulo Bento", "value": "C14391"},
                       {"label": "Paverama", "value": "C14392"},
                       {"label": "Pedras Altas", "value": "C14393"},
                       {"label": "Pedro Osorio", "value": "C14394"},
                       {"label": "Pejucara", "value": "C14395"},
                       {"label": "Pelotas", "value": "C14396"},
                       {"label": "Picada Cafe", "value": "C14397"},
                       {"label": "Pinhal", "value": "C14398"},
                       {"label": "Pinhal da Serra", "value": "C14399"},
                       {"label": "Pinhal Grande", "value": "C14400"},
                       {"label": "Pinheirinho do Vale", "value": "C14401"},
                       {"label": "Pinheiro Machado", "value": "C14402"},
                       {"label": "Pinheiros", "value": "C14403"},
                       {"label": "Pinto Bandeira", "value": "C14404"},
                       {"label": "Pirapo", "value": "C14405"},
                       {"label": "Piratini", "value": "C14406"},
                       {"label": "Planalto", "value": "C14407"},
                       {"label": "Poco das Antas", "value": "C14408"},
                       {"label": "Pontao", "value": "C14409"},
                       {"label": "Ponte Preta", "value": "C14410"},
                       {"label": "Portao", "value": "C14411"},
                       {"label": "Porto Alegre", "value": "C14412"},
                       {"label": "Porto Lucena", "value": "C14413"},
                       {"label": "Porto Maua", "value": "C14414"},
                       {"label": "Porto Vera Cruz", "value": "C14415"},
                       {"label": "Porto Xavier", "value": "C14416"},
                       {"label": "Pouso Novo", "value": "C14417"},
                       {"label": "Presidente Lucena", "value": "C14418"},
                       {"label": "Progresso", "value": "C14419"},
                       {"label": "Protasio Alves", "value": "C14420"},
                       {"label": "Putinga", "value": "C14421"},
                       {"label": "Quarai", "value": "C14422"},
                       {"label": "Quatro Irmaos", "value": "C14423"},
                       {"label": "Quevedos", "value": "C14424"},
                       {"label": "Quinze de Novembro", "value": "C14425"},
                       {"label": "Redentora", "value": "C14426"},
                       {"label": "Relvado", "value": "C14427"},
                       {"label": "Restinga Seca", "value": "C14428"},
                       {"label": "Rio dos indios", "value": "C14429"},
                       {"label": "Rio Grande", "value": "C14430"},
                       {"label": "Rio Pardo", "value": "C14431"},
                       {"label": "Riozinho", "value": "C14432"},
                       {"label": "Roca Sales", "value": "C14433"},
                       {"label": "Rodeio Bonito", "value": "C14434"},
                       {"label": "Rolador", "value": "C14435"},
                       {"label": "Rolante", "value": "C14436"},
                       {"label": "Ronda Alta", "value": "C14437"},
                       {"label": "Rondinha", "value": "C14438"},
                       {"label": "Roque Gonzales", "value": "C14439"},
                       {"label": "Rosario do Sul", "value": "C14440"},
                       {"label": "Sagrada Familia", "value": "C14441"},
                       {"label": "Saldanha Marinho", "value": "C14442"},
                       {"label": "Salto do Jacui", "value": "C14443"},
                       {"label": "Salvador das Missoes", "value": "C14444"},
                       {"label": "Salvador do Sul", "value": "C14445"},
                       {"label": "Sananduva", "value": "C14446"},
                       {"label": "SantAna do Livramento", "value": "C14447"},
                       {"label": "Santa Barbara do Sul", "value": "C14448"},
                       {"label": "Santa Cecilia do Sul", "value": "C14449"},
                       {"label": "Santa Clara do Sul", "value": "C14450"},
                       {"label": "Santa Cruz do Sul", "value": "C14451"},
                       {"label": "Santa Margarida do Sul", "value": "C14452"},
                       {"label": "Santa Maria", "value": "C14453"},
                       {"label": "Santa Maria do Herval", "value": "C14454"},
                       {"label": "Santa Rosa", "value": "C14455"},
                       {"label": "Santa Tereza", "value": "C14456"},
                       {"label": "Santa Vitoria do Palmar", "value": "C14457"},
                       {"label": "Santana da Boa Vista", "value": "C14458"},
                       {"label": "Santana do Livramento", "value": "C14459"},
                       {"label": "Santiago", "value": "C14460"},
                       {"label": "Santo Angelo", "value": "C14461"},
                       {"label": "Santo Antonio da Patrulha",
                        "value": "C14462"},
                       {"label": "Santo Antonio das Missoes",
                        "value": "C14463"},
                       {"label": "Santo Antonio do Palma", "value": "C14464"},
                       {"label": "Santo Antonio do Planalto",
                        "value": "C14465"},
                       {"label": "Santo Augusto", "value": "C14466"},
                       {"label": "Santo Cristo", "value": "C14467"},
                       {"label": "Santo Expedito do Sul", "value": "C14468"},
                       {"label": "Sao Borja", "value": "C14469"},
                       {"label": "Sao Domingos do Sul", "value": "C14470"},
                       {"label": "Sao Francisco de Assis", "value": "C14471"},
                       {"label": "Sao Francisco de Paula", "value": "C14472"},
                       {"label": "Sao Gabriel", "value": "C14473"},
                       {"label": "Sao Jeronimo", "value": "C14474"},
                       {"label": "Sao Joao da Urtiga", "value": "C14475"},
                       {"label": "Sao Joao do Polesine", "value": "C14476"},
                       {"label": "Sao Jorge", "value": "C14477"},
                       {"label": "Sao Jose das Missoes", "value": "C14478"},
                       {"label": "Sao Jose do Herval", "value": "C14479"},
                       {"label": "Sao Jose do Hortencio", "value": "C14480"},
                       {"label": "Sao Jose do Inhacora", "value": "C14481"},
                       {"label": "Sao Jose do Norte", "value": "C14482"},
                       {"label": "Sao Jose do Ouro", "value": "C14483"},
                       {"label": "Sao Jose do Sul", "value": "C14484"},
                       {"label": "Sao Jose dos Ausentes", "value": "C14485"},
                       {"label": "Sao Leopoldo", "value": "C14486"},
                       {"label": "Sao Lourenco do Sul", "value": "C14487"},
                       {"label": "Sao Luiz Gonzaga", "value": "C14488"},
                       {"label": "Sao Marcos", "value": "C14489"},
                       {"label": "Sao Martinho", "value": "C14490"},
                       {"label": "Sao Martinho da Serra", "value": "C14491"},
                       {"label": "Sao Miguel das Missoes", "value": "C14492"},
                       {"label": "Sao Nicolau", "value": "C14493"},
                       {"label": "Sao Paulo das Missoes", "value": "C14494"},
                       {"label": "Sao Pedro da Serra", "value": "C14495"},
                       {"label": "Sao Pedro das Missoes", "value": "C14496"},
                       {"label": "Sao Pedro do Butia", "value": "C14497"},
                       {"label": "Sao Pedro do Sul", "value": "C14498"},
                       {"label": "Sao Sebastiao do Cai", "value": "C14499"},
                       {"label": "Sao Sepe", "value": "C14500"},
                       {"label": "Sao Valentim", "value": "C14501"},
                       {"label": "Sao Valentim do Sul", "value": "C14502"},
                       {"label": "Sao Valerio do Sul", "value": "C14503"},
                       {"label": "Sao Vendelino", "value": "C14504"},
                       {"label": "Sao Vicente do Sul", "value": "C14505"},
                       {"label": "Sapiranga", "value": "C14506"},
                       {"label": "Sapucaia", "value": "C14507"},
                       {"label": "Sapucaia do Sul", "value": "C14508"},
                       {"label": "Sarandi", "value": "C14509"},
                       {"label": "Seberi", "value": "C14510"},
                       {"label": "Sede Nova", "value": "C14511"},
                       {"label": "Segredo", "value": "C14512"},
                       {"label": "Selbach", "value": "C14513"},
                       {"label": "Senador Salgado Filho", "value": "C14514"},
                       {"label": "Sentinela do Sul", "value": "C14515"},
                       {"label": "Serafina Correa", "value": "C14516"},
                       {"label": "Serio", "value": "C14517"},
                       {"label": "Sertao", "value": "C14518"},
                       {"label": "Sertao Santana", "value": "C14519"},
                       {"label": "Sete de Setembro", "value": "C14520"},
                       {"label": "Severiano de Almeida", "value": "C14521"},
                       {"label": "Silveira Martins", "value": "C14522"},
                       {"label": "Sinimbu", "value": "C14523"},
                       {"label": "Sobradinho", "value": "C14524"},
                       {"label": "Soledade", "value": "C14525"},
                       {"label": "Tabai", "value": "C14526"},
                       {"label": "Tapejara", "value": "C14527"},
                       {"label": "Tapera", "value": "C14528"},
                       {"label": "Tapes", "value": "C14529"},
                       {"label": "Taquara", "value": "C14530"},
                       {"label": "Taquari", "value": "C14531"},
                       {"label": "Taquarucu do Sul", "value": "C14532"},
                       {"label": "Tavares", "value": "C14533"},
                       {"label": "Tenente Portela", "value": "C14534"},
                       {"label": "Terra de Areia", "value": "C14535"},
                       {"label": "Teutonia", "value": "C14536"},
                       {"label": "Tio Hugo", "value": "C14537"},
                       {"label": "Tiradentes do Sul", "value": "C14538"},
                       {"label": "Toropi", "value": "C14539"},
                       {"label": "Torres", "value": "C14540"},
                       {"label": "Tramandai", "value": "C14541"},
                       {"label": "Travesseiro", "value": "C14542"},
                       {"label": "Tres Arroios", "value": "C14543"},
                       {"label": "Tres Cachoeiras", "value": "C14544"},
                       {"label": "Tres Coroas", "value": "C14545"},
                       {"label": "Tres de Maio", "value": "C14546"},
                       {"label": "Tres Forquilhas", "value": "C14547"},
                       {"label": "Tres Palmeiras", "value": "C14548"},
                       {"label": "Tres Passos", "value": "C14549"},
                       {"label": "Trindade do Sul", "value": "C14550"},
                       {"label": "Triunfo", "value": "C14551"},
                       {"label": "Tucunduva", "value": "C14552"},
                       {"label": "Tunas", "value": "C14553"},
                       {"label": "Tupanci do Sul", "value": "C14554"},
                       {"label": "Tupancireta", "value": "C14555"},
                       {"label": "Tupandi", "value": "C14556"},
                       {"label": "Tuparendi", "value": "C14557"},
                       {"label": "Turucu", "value": "C14558"},
                       {"label": "Ubiretama", "value": "C14559"},
                       {"label": "Uniao da Serra", "value": "C14560"},
                       {"label": "Unistalda", "value": "C14561"},
                       {"label": "Uruguaiana", "value": "C14562"},
                       {"label": "Vacaria", "value": "C14563"},
                       {"label": "Vale do Sol", "value": "C14564"},
                       {"label": "Vale Real", "value": "C14565"},
                       {"label": "Vale Verde", "value": "C14566"},
                       {"label": "Vanini", "value": "C14567"},
                       {"label": "Venancio Aires", "value": "C14568"},
                       {"label": "Vera Cruz", "value": "C14569"},
                       {"label": "Veranopolis", "value": "C14570"},
                       {"label": "Vespasiano Correa", "value": "C14571"},
                       {"label": "Viadutos", "value": "C14572"},
                       {"label": "Viamao", "value": "C14573"},
                       {"label": "Vicente Dutra", "value": "C14574"},
                       {"label": "Victor Graeff", "value": "C14575"},
                       {"label": "Vila Flores", "value": "C14576"},
                       {"label": "Vila Langaro", "value": "C14577"},
                       {"label": "Vila Maria", "value": "C14578"},
                       {"label": "Vila Nova do Sul", "value": "C14579"},
                       {"label": "Vista Alegre", "value": "C14580"},
                       {"label": "Vista Alegre do Prata", "value": "C14581"},
                       {"label": "Vista Gaucha", "value": "C14582"},
                       {"label": "Vitoria das Missoes", "value": "C14583"},
                       {"label": "Westfalia", "value": "C14584"},
                       {"label": "Xangri-la", "value": "C14585"}],
 "Rio Negro": [{"label": "Allen", "value": "C1281"},
               {"label": "Catriel", "value": "C1282"},
               {"label": "Cervantes", "value": "C1283"},
               {"label": "Chichinales", "value": "C1284"},
               {"label": "Chimpay", "value": "C1285"},
               {"label": "Choele Choel", "value": "C1286"},
               {"label": "Cinco Saltos", "value": "C1287"},
               {"label": "Cipolletti", "value": "C1288"},
               {"label": "Comallo", "value": "C1289"},
               {"label": "Contraalmirante Cordero", "value": "C1290"},
               {"label": "Coronel Belisle", "value": "C1291"},
               {"label": "Darwin", "value": "C1292"},
               {"label": "Departamento de Avellaneda", "value": "C1293"},
               {"label": "Departamento de Veinticinco de Mayo",
                "value": "C1294"},
               {"label": "El Bolson", "value": "C1295"},
               {"label": "El Cuy", "value": "C1296"},
               {"label": "Fray Luis Beltran", "value": "C1297"},
               {"label": "General Conesa", "value": "C1298"},
               {"label": "General Enrique Godoy", "value": "C1299"},
               {"label": "General Fernandez Oro", "value": "C1300"},
               {"label": "General Roca", "value": "C1301"},
               {"label": "Ingeniero Jacobacci", "value": "C1302"},
               {"label": "Ingeniero Luis A. Huergo", "value": "C1303"},
               {"label": "Lamarque", "value": "C1304"},
               {"label": "Los Menucos", "value": "C1305"},
               {"label": "Mainque", "value": "C1306"},
               {"label": "Maquinchao", "value": "C1307"},
               {"label": "norquinco", "value": "C1308"},
               {"label": "Pilcaniyeu", "value": "C1309"},
               {"label": "Rio Colorado", "value": "C1310"},
               {"label": "San Antonio Oeste", "value": "C1311"},
               {"label": "San Carlos de Bariloche", "value": "C1312"},
               {"label": "Sierra Colorada", "value": "C1313"},
               {"label": "Sierra Grande", "value": "C1314"},
               {"label": "Valcheta", "value": "C1315"},
               {"label": "Viedma", "value": "C1316"},
               {"label": "Villa Regina", "value": "C1317"}],
 "Rio Negro Department": [{"label": "Fray Bentos", "value": "C146941"},
                          {"label": "Nuevo Berlin", "value": "C146942"},
                          {"label": "San Javier", "value": "C146943"},
                          {"label": "Young", "value": "C146944"}],
 "Rio San Juan": [{"label": "El Almendro", "value": "C78007"},
                  {"label": "Greytown", "value": "C78008"},
                  {"label": "Morrito", "value": "C78009"},
                  {"label": "San Carlos", "value": "C78010"},
                  {"label": "San Miguelito", "value": "C78011"}],
 "Rio de Janeiro": [{"label": "Angra dos Reis", "value": "C13822"},
                    {"label": "Aperibe", "value": "C13823"},
                    {"label": "Araruama", "value": "C13824"},
                    {"label": "Areal", "value": "C13825"},
                    {"label": "Armacao dos Buzios", "value": "C13826"},
                    {"label": "Arraial do Cabo", "value": "C13827"},
                    {"label": "Barra do Pirai", "value": "C13828"},
                    {"label": "Barra Mansa", "value": "C13829"},
                    {"label": "Belford Roxo", "value": "C13830"},
                    {"label": "Bom Jardim", "value": "C13831"},
                    {"label": "Bom Jesus do Itabapoana", "value": "C13832"},
                    {"label": "Cabo Frio", "value": "C13833"},
                    {"label": "Cachoeiras de Macacu", "value": "C13834"},
                    {"label": "Cambuci", "value": "C13835"},
                    {"label": "Campos dos Goytacazes", "value": "C13836"},
                    {"label": "Cantagalo", "value": "C13837"},
                    {"label": "Carapebus", "value": "C13838"},
                    {"label": "Cardoso Moreira", "value": "C13839"},
                    {"label": "Carmo", "value": "C13840"},
                    {"label": "Casimiro de Abreu", "value": "C13841"},
                    {"label": "Comendador Levy Gasparian", "value": "C13842"},
                    {"label": "Conceicao de Macabu", "value": "C13843"},
                    {"label": "Cordeiro", "value": "C13844"},
                    {"label": "Duas Barras", "value": "C13845"},
                    {"label": "Duque de Caxias", "value": "C13846"},
                    {"label": "Engenheiro Paulo de Frontin", "value": "C13847"},
                    {"label": "Guapimirim", "value": "C13848"},
                    {"label": "Iguaba Grande", "value": "C13849"},
                    {"label": "Ilha Grande", "value": "C13850"},
                    {"label": "Itaborai", "value": "C13851"},
                    {"label": "Itaguai", "value": "C13852"},
                    {"label": "Italva", "value": "C13853"},
                    {"label": "Itaocara", "value": "C13854"},
                    {"label": "Itaperuna", "value": "C13855"},
                    {"label": "Itatiaia", "value": "C13856"},
                    {"label": "Japeri", "value": "C13857"},
                    {"label": "Laje do Muriae", "value": "C13858"},
                    {"label": "Macae", "value": "C13859"},
                    {"label": "Macuco", "value": "C13860"},
                    {"label": "Mage", "value": "C13861"},
                    {"label": "Mangaratiba", "value": "C13862"},
                    {"label": "Marica", "value": "C13863"},
                    {"label": "Mendes", "value": "C13864"},
                    {"label": "Mesquita", "value": "C13865"},
                    {"label": "Miguel Pereira", "value": "C13866"},
                    {"label": "Miracema", "value": "C13867"},
                    {"label": "Natividade", "value": "C13868"},
                    {"label": "Nilopolis", "value": "C13869"},
                    {"label": "Niteroi", "value": "C13870"},
                    {"label": "Nova Friburgo", "value": "C13871"},
                    {"label": "Nova Iguacu", "value": "C13872"},
                    {"label": "Paracambi", "value": "C13873"},
                    {"label": "Paraiba do Sul", "value": "C13874"},
                    {"label": "Paraty", "value": "C13875"},
                    {"label": "Paty do Alferes", "value": "C13876"},
                    {"label": "Petropolis", "value": "C13877"},
                    {"label": "Pinheiral", "value": "C13878"},
                    {"label": "Pirai", "value": "C13879"},
                    {"label": "Porciuncula", "value": "C13880"},
                    {"label": "Porto Real", "value": "C13881"},
                    {"label": "Quatis", "value": "C13882"},
                    {"label": "Queimados", "value": "C13883"},
                    {"label": "Quissama", "value": "C13884"},
                    {"label": "Resende", "value": "C13885"},
                    {"label": "Rio Bonito", "value": "C13886"},
                    {"label": "Rio Claro", "value": "C13887"},
                    {"label": "Rio das Flores", "value": "C13888"},
                    {"label": "Rio das Ostras", "value": "C13889"},
                    {"label": "Rio de Janeiro", "value": "C13890"},
                    {"label": "Santa Maria Madalena", "value": "C13891"},
                    {"label": "Santo Antonio de Padua", "value": "C13892"},
                    {"label": "Sao Fidelis", "value": "C13893"},
                    {"label": "Sao Francisco de Itabapoana", "value": "C13894"},
                    {"label": "Sao Goncalo", "value": "C13895"},
                    {"label": "Sao Joao da Barra", "value": "C13896"},
                    {"label": "Sao Joao de Meriti", "value": "C13897"},
                    {"label": "Sao Jose de Uba", "value": "C13898"},
                    {"label": "Sao Jose do Vale do Rio Preto",
                     "value": "C13899"},
                    {"label": "Sao Pedro", "value": "C13900"},
                    {"label": "Sao Pedro da Aldeia", "value": "C13901"},
                    {"label": "Sao Sebastiao do Alto", "value": "C13902"},
                    {"label": "Sapucaia", "value": "C13903"},
                    {"label": "Saquarema", "value": "C13904"},
                    {"label": "Seropedica", "value": "C13905"},
                    {"label": "Silva Jardim", "value": "C13906"},
                    {"label": "Sumidouro", "value": "C13907"},
                    {"label": "Tangua", "value": "C13908"},
                    {"label": "Teresopolis", "value": "C13909"},
                    {"label": "Trajano de Moraes", "value": "C13910"},
                    {"label": "Tres Rios", "value": "C13911"},
                    {"label": "Valenca", "value": "C13912"},
                    {"label": "Varre-Sai", "value": "C13913"},
                    {"label": "Vassouras", "value": "C13914"},
                    {"label": "Volta Redonda", "value": "C13915"}],
 "Risaralda": [{"label": "Apia", "value": "C20432"},
               {"label": "Balboa", "value": "C20433"},
               {"label": "Belen de Umbria", "value": "C20434"},
               {"label": "Dosquebradas", "value": "C20435"},
               {"label": "Guatica", "value": "C20436"},
               {"label": "La Celia", "value": "C20437"},
               {"label": "La Virginia", "value": "C20438"},
               {"label": "Marsella", "value": "C20439"},
               {"label": "Mistrato", "value": "C20440"},
               {"label": "Pereira", "value": "C20441"},
               {"label": "Pueblo Rico", "value": "C20442"},
               {"label": "Quinchia", "value": "C20443"},
               {"label": "Santa Rosa de Cabal", "value": "C20444"},
               {"label": "Santuario", "value": "C20445"}],
 "Riscani District": [{"label": "Riscani", "value": "C75531"}],
 "Rivas": [{"label": "Altagracia", "value": "C78012"},
           {"label": "Belen", "value": "C78013"},
           {"label": "Buenos Aires", "value": "C78014"},
           {"label": "Cardenas", "value": "C78015"},
           {"label": "Moyogalpa", "value": "C78016"},
           {"label": "Municipio de Altagracia", "value": "C78017"},
           {"label": "Municipio de Belen", "value": "C78018"},
           {"label": "Municipio de Buenos Aires", "value": "C78019"},
           {"label": "Municipio de Cardenas", "value": "C78020"},
           {"label": "Municipio de Moyogalpa", "value": "C78021"},
           {"label": "Municipio de Potosi", "value": "C78022"},
           {"label": "Municipio de Rivas", "value": "C78023"},
           {"label": "Municipio de San Jorge", "value": "C78024"},
           {"label": "Municipio de San Juan del Sur", "value": "C78025"},
           {"label": "Municipio de Tola", "value": "C78026"},
           {"label": "Potosi", "value": "C78027"},
           {"label": "Rivas", "value": "C78028"},
           {"label": "San Jorge", "value": "C78029"},
           {"label": "San Juan del Sur", "value": "C78030"},
           {"label": "Tola", "value": "C78031"}],
 "River Cess County": [{"label": "Cestos City", "value": "C65017"}],
 "River Gee County": [{"label": "Fish Town", "value": "C65018"}],
 "River Nile": [{"label": "Atbara", "value": "C115426"},
                {"label": "Berber", "value": "C115427"},
                {"label": "Ed Damer", "value": "C115428"},
                {"label": "El Bauga", "value": "C115429"},
                {"label": "El Matama", "value": "C115430"},
                {"label": "Shendi", "value": "C115431"}],
 "Rivera Department": [{"label": "Minas de Corrales", "value": "C146945"},
                       {"label": "Rivera", "value": "C146946"},
                       {"label": "Tranqueras", "value": "C146947"},
                       {"label": "Vichadero", "value": "C146948"}],
 "Rivers": [{"label": "Abalama", "value": "C78477"},
            {"label": "Abonnema", "value": "C78478"},
            {"label": "Ahoada", "value": "C78479"},
            {"label": "Bori", "value": "C78480"},
            {"label": "Buguma", "value": "C78481"},
            {"label": "Chokocho", "value": "C78482"},
            {"label": "Elele", "value": "C78483"},
            {"label": "Obonoma", "value": "C78484"},
            {"label": "Omoku", "value": "C78485"},
            {"label": "Onne", "value": "C78486"},
            {"label": "Opobo", "value": "C78487"},
            {"label": "Port Harcourt", "value": "C78488"},
            {"label": "Rumuokoro", "value": "C78489"}],
 "Riviere Noire District": [{"label": "Albion", "value": "C66054"},
                            {"label": "Bambous", "value": "C66055"},
                            {"label": "Cascavelle", "value": "C66056"},
                            {"label": "Flic en Flac", "value": "C66057"},
                            {"label": "Grande Riviere Noire",
                             "value": "C66058"},
                            {"label": "Gros Cailloux", "value": "C66059"},
                            {"label": "Petite Case Noyale", "value": "C66060"},
                            {"label": "Petite Riviere", "value": "C66061"},
                            {"label": "Tamarin", "value": "C66062"}],
 "Riviere du Rempart District": [{"label": "Amaury", "value": "C66039"},
                                 {"label": "Cap Malheureux", "value": "C66040"},
                                 {"label": "Cottage", "value": "C66041"},
                                 {"label": "Esperance Trebuchet",
                                  "value": "C66042"},
                                 {"label": "Goodlands", "value": "C66043"},
                                 {"label": "Grand Baie", "value": "C66044"},
                                 {"label": "Grand Gaube", "value": "C66045"},
                                 {"label": "Mapou", "value": "C66046"},
                                 {"label": "Petit Raffray", "value": "C66047"},
                                 {"label": "Piton", "value": "C66048"},
                                 {"label": "Plaines des Roches",
                                  "value": "C66049"},
                                 {"label": "Riviere du Rempart",
                                  "value": "C66050"},
                                 {"label": "Roche Terre", "value": "C66051"},
                                 {"label": "Roches Noire", "value": "C66052"},
                                 {"label": "The Vale", "value": "C66053"}],
 "Rivnenska oblast": [{"label": "Demydivskyy Rayon", "value": "C122732"},
                      {"label": "Dubno", "value": "C122733"},
                      {"label": "Dubrovytsya", "value": "C122734"},
                      {"label": "Horodyshche", "value": "C122735"},
                      {"label": "Hoshcha", "value": "C122736"},
                      {"label": "Klevan", "value": "C122737"},
                      {"label": "Kolodenka", "value": "C122738"},
                      {"label": "Koloniya Zastavye", "value": "C122739"},
                      {"label": "Korets", "value": "C122740"},
                      {"label": "Kostopil", "value": "C122741"},
                      {"label": "Mizoch", "value": "C122742"},
                      {"label": "Mlyniv", "value": "C122743"},
                      {"label": "Mlynivskyy Rayon", "value": "C122744"},
                      {"label": "Orzhiv", "value": "C122745"},
                      {"label": "Ostroh", "value": "C122746"},
                      {"label": "Ostrozka Miskrada", "value": "C122747"},
                      {"label": "Radyvyliv", "value": "C122748"},
                      {"label": "Rivne", "value": "C122749"},
                      {"label": "Rivnenska Miskrada", "value": "C122750"},
                      {"label": "Rokytne", "value": "C122751"},
                      {"label": "Sarny", "value": "C122752"},
                      {"label": "Tarakaniv", "value": "C122753"},
                      {"label": "Tynne", "value": "C122754"},
                      {"label": "Varash", "value": "C122755"},
                      {"label": "Velyki Mezhyrichi", "value": "C122756"},
                      {"label": "Volodymyrets", "value": "C122757"},
                      {"label": "Zarichne", "value": "C122758"},
                      {"label": "Zdolbuniv", "value": "C122759"},
                      {"label": "Zdovbytsya", "value": "C122760"}],
 "Riyadh": [{"label": "Ad Dawadimi", "value": "C106792"},
            {"label": "Ad Dilam", "value": "C106793"},
            {"label": "Afif", "value": "C106794"},
            {"label": "Ain AlBaraha", "value": "C106795"},
            {"label": "Al Artawiyah", "value": "C106796"},
            {"label": "Al Bir", "value": "C106797"},
            {"label": "Al Hair", "value": "C106798"},
            {"label": "Al Jurayfah", "value": "C106799"},
            {"label": "Al Kharj", "value": "C106800"},
            {"label": "Ar Rayn", "value": "C106801"},
            {"label": "As Salamiyah", "value": "C106802"},
            {"label": "As Sulayyil", "value": "C106803"},
            {"label": "Az Zulfi", "value": "C106804"},
            {"label": "Dawadmi", "value": "C106805"},
            {"label": "Diriyah", "value": "C106806"},
            {"label": "Harmah", "value": "C106807"},
            {"label": "Jalajil", "value": "C106808"},
            {"label": "Layla", "value": "C106809"},
            {"label": "Manfuha", "value": "C106810"},
            {"label": "Marat", "value": "C106811"},
            {"label": "Najan", "value": "C106812"},
            {"label": "Najran", "value": "C106813"},
            {"label": "Riyadh", "value": "C106814"},
            {"label": "Sajir", "value": "C106815"},
            {"label": "shokhaibin", "value": "C106816"},
            {"label": "Tumayr", "value": "C106817"}],
 "Rize": [{"label": "Ardesen", "value": "C120876"},
          {"label": "Cayeli", "value": "C120877"},
          {"label": "Findikli", "value": "C120878"},
          {"label": "Guneysu", "value": "C120879"},
          {"label": "Hemsin Ilcesi", "value": "C120880"},
          {"label": "Ikizdere Ilcesi", "value": "C120881"},
          {"label": "Iyidere", "value": "C120882"},
          {"label": "Kalkandere", "value": "C120883"},
          {"label": "Nurluca", "value": "C120884"},
          {"label": "Pazar", "value": "C120885"},
          {"label": "Rize", "value": "C120886"}],
 "Rocha Department": [{"label": "Castillos", "value": "C146949"},
                      {"label": "Cebollati", "value": "C146950"},
                      {"label": "Chui", "value": "C146951"},
                      {"label": "Dieciocho de Julio", "value": "C146952"},
                      {"label": "La Paloma", "value": "C146953"},
                      {"label": "Lascano", "value": "C146954"},
                      {"label": "Rocha", "value": "C146955"},
                      {"label": "Velazquez", "value": "C146956"}],
 "Rodrigues": [{"label": "Baie aux Huitres", "value": "C66063"},
               {"label": "Port Mathurin", "value": "C66064"}],
 "Rogaland": [{"label": "Akrehamn", "value": "C79021"},
              {"label": "Bjerkreim", "value": "C79022"},
              {"label": "Bokn", "value": "C79023"},
              {"label": "Bryne", "value": "C79024"},
              {"label": "Egersund", "value": "C79025"},
              {"label": "Eigersund", "value": "C79026"},
              {"label": "Eike", "value": "C79027"},
              {"label": "Finnoy", "value": "C79028"},
              {"label": "Forsand", "value": "C79029"},
              {"label": "Gjesdal", "value": "C79030"},
              {"label": "Ha", "value": "C79031"},
              {"label": "Hauge i Dalane", "value": "C79032"},
              {"label": "Haugesund", "value": "C79033"},
              {"label": "Hjelmeland", "value": "C79034"},
              {"label": "Hommersak", "value": "C79035"},
              {"label": "Jorpeland", "value": "C79036"},
              {"label": "Judaberg", "value": "C79037"},
              {"label": "Karmoy", "value": "C79038"},
              {"label": "Klepp", "value": "C79039"},
              {"label": "Kopervik", "value": "C79040"},
              {"label": "Kvitsoy", "value": "C79041"},
              {"label": "Lund", "value": "C79042"},
              {"label": "Lyefjell", "value": "C79043"},
              {"label": "Moi", "value": "C79044"},
              {"label": "Naerbo", "value": "C79045"},
              {"label": "Olen", "value": "C79046"},
              {"label": "Randaberg", "value": "C79047"},
              {"label": "Rennesoy", "value": "C79048"},
              {"label": "Sandnes", "value": "C79049"},
              {"label": "Sauda", "value": "C79050"},
              {"label": "Saeveland", "value": "C79051"},
              {"label": "Skudeneshavn", "value": "C79052"},
              {"label": "Sokndal", "value": "C79053"},
              {"label": "Sola", "value": "C79054"},
              {"label": "Stavanger", "value": "C79055"},
              {"label": "Strand", "value": "C79056"},
              {"label": "Suldal", "value": "C79057"},
              {"label": "Tananger", "value": "C79058"},
              {"label": "Tau", "value": "C79059"},
              {"label": "Time", "value": "C79060"},
              {"label": "Tysvaer", "value": "C79061"},
              {"label": "Utsira", "value": "C79062"},
              {"label": "Varhaug", "value": "C79063"},
              {"label": "Vedavagen", "value": "C79064"},
              {"label": "Vigrestad", "value": "C79065"},
              {"label": "Vikesa", "value": "C79066"},
              {"label": "Vikevag", "value": "C79067"},
              {"label": "Vindafjord", "value": "C79068"},
              {"label": "Vormedal", "value": "C79069"}],
 "Rogaska Slatina Municipality": [{"label": "Rogaska Slatina",
                                   "value": "C107787"}],
 "Rogasovci Municipality": [{"label": "Rogasovci", "value": "C107788"}],
 "Rogatec Municipality": [{"label": "Rogatec", "value": "C107789"}],
 "Roi Et": [{"label": "Amphoe At Samat", "value": "C119231"},
            {"label": "Amphoe Changhan", "value": "C119232"},
            {"label": "Amphoe Chaturaphak Phiman", "value": "C119233"},
            {"label": "Amphoe Chiang Khwan", "value": "C119234"},
            {"label": "Amphoe Kaset Wisai", "value": "C119235"},
            {"label": "Amphoe Moeiwadi", "value": "C119236"},
            {"label": "Amphoe Mueang Roi Et", "value": "C119237"},
            {"label": "Amphoe Mueang Suang", "value": "C119238"},
            {"label": "Amphoe Nong Hi", "value": "C119239"},
            {"label": "Amphoe Nong Phok", "value": "C119240"},
            {"label": "Amphoe Pathum Rat", "value": "C119241"},
            {"label": "Amphoe Phanom Phrai", "value": "C119242"},
            {"label": "Amphoe Pho Chai", "value": "C119243"},
            {"label": "Amphoe Phon Sai", "value": "C119244"},
            {"label": "Amphoe Phon Thong", "value": "C119245"},
            {"label": "Amphoe Selaphum", "value": "C119246"},
            {"label": "Amphoe Si Somdet", "value": "C119247"},
            {"label": "Amphoe Suwannaphum", "value": "C119248"},
            {"label": "Amphoe Thawatchaburi", "value": "C119249"},
            {"label": "Amphoe Thung Khao Luang", "value": "C119250"},
            {"label": "Ban Selaphum", "value": "C119251"},
            {"label": "Kaset Wisai", "value": "C119252"},
            {"label": "Moeiwadi", "value": "C119253"},
            {"label": "Pho Chai", "value": "C119254"},
            {"label": "Roi Et", "value": "C119255"},
            {"label": "Suwannaphum", "value": "C119256"},
            {"label": "Waeng", "value": "C119257"}],
 "Roja Municipality": [{"label": "Roja", "value": "C64925"}],
 "Rondonia": [{"label": "Alta Floresta dOeste", "value": "C14586"},
              {"label": "Alto Alegre dos Parecis", "value": "C14587"},
              {"label": "Alto Paraiso", "value": "C14588"},
              {"label": "Alvorada dOeste", "value": "C14589"},
              {"label": "Ariquemes", "value": "C14590"},
              {"label": "Buritis", "value": "C14591"},
              {"label": "Cabixi", "value": "C14592"},
              {"label": "Cacaulandia", "value": "C14593"},
              {"label": "Cacoal", "value": "C14594"},
              {"label": "Campo Novo de Rondonia", "value": "C14595"},
              {"label": "Candeias do Jamari", "value": "C14596"},
              {"label": "Castanheiras", "value": "C14597"},
              {"label": "Cerejeiras", "value": "C14598"},
              {"label": "Chupinguaia", "value": "C14599"},
              {"label": "Colorado do Oeste", "value": "C14600"},
              {"label": "Corumbiara", "value": "C14601"},
              {"label": "Costa Marques", "value": "C14602"},
              {"label": "Cujubim", "value": "C14603"},
              {"label": "Espigao dOeste", "value": "C14604"},
              {"label": "Extrema", "value": "C14605"},
              {"label": "Governador Jorge Teixeira", "value": "C14606"},
              {"label": "Guajara Mirim", "value": "C14607"},
              {"label": "Guajara-Mirim", "value": "C14608"},
              {"label": "Itapua do Oeste", "value": "C14609"},
              {"label": "Jaru", "value": "C14610"},
              {"label": "Ji Parana", "value": "C14611"},
              {"label": "Ji-Parana", "value": "C14612"},
              {"label": "Machadinho dOeste", "value": "C14613"},
              {"label": "Ministro Andreazza", "value": "C14614"},
              {"label": "Mirante da Serra", "value": "C14615"},
              {"label": "Monte Negro", "value": "C14616"},
              {"label": "Nova Brasilandia dOeste", "value": "C14617"},
              {"label": "Nova Mamore", "value": "C14618"},
              {"label": "Nova Uniao", "value": "C14619"},
              {"label": "Novo Horizonte do Oeste", "value": "C14620"},
              {"label": "Ouro Preto do Oeste", "value": "C14621"},
              {"label": "Parecis", "value": "C14622"},
              {"label": "Pimenta Bueno", "value": "C14623"},
              {"label": "Pimenteiras do Oeste", "value": "C14624"},
              {"label": "Porto Velho", "value": "C14625"},
              {"label": "Posto Fiscal Rolim de Moura", "value": "C14626"},
              {"label": "Presidente Medici", "value": "C14627"},
              {"label": "Primavera de Rondonia", "value": "C14628"},
              {"label": "Rio Crespo", "value": "C14629"},
              {"label": "Rolim de Moura", "value": "C14630"},
              {"label": "Santa Luzia dOeste", "value": "C14631"},
              {"label": "Sao Felipe dOeste", "value": "C14632"},
              {"label": "Sao Francisco do Guapore", "value": "C14633"},
              {"label": "Sao Miguel do Guapore", "value": "C14634"},
              {"label": "Seringueiras", "value": "C14635"},
              {"label": "Teixeiropolis", "value": "C14636"},
              {"label": "Theobroma", "value": "C14637"},
              {"label": "Urupa", "value": "C14638"},
              {"label": "Vale do Anari", "value": "C14639"},
              {"label": "Vale do Paraiso", "value": "C14640"},
              {"label": "Vilhena", "value": "C14641"}],
 "Ropazi Municipality": [{"label": "Ropazi", "value": "C64926"}],
 "Roraima": [{"label": "Amajari", "value": "C14642"},
             {"label": "Boa Vista", "value": "C14643"},
             {"label": "Bonfim", "value": "C14644"},
             {"label": "Canta", "value": "C14645"},
             {"label": "Caracarai", "value": "C14646"},
             {"label": "Caroebe", "value": "C14647"},
             {"label": "Iracema", "value": "C14648"},
             {"label": "Mucajai", "value": "C14649"},
             {"label": "Normandia", "value": "C14650"},
             {"label": "Pacaraima", "value": "C14651"},
             {"label": "Rorainopolis", "value": "C14652"},
             {"label": "Sao Joao da Baliza", "value": "C14653"},
             {"label": "Sao Luis", "value": "C14654"},
             {"label": "Uiramuta", "value": "C14655"}],
 "Rosoman Municipality": [{"label": "Rosoman", "value": "C65492"}],
 "Rostov Oblast": [{"label": "Aksay", "value": "C103971"},
                   {"label": "Almaznyy", "value": "C103972"},
                   {"label": "Anastasiyevka", "value": "C103973"},
                   {"label": "Ayutinskiy", "value": "C103974"},
                   {"label": "Azov", "value": "C103975"},
                   {"label": "Bagayevskaya", "value": "C103976"},
                   {"label": "Bataysk", "value": "C103977"},
                   {"label": "Belaya Kalitva", "value": "C103978"},
                   {"label": "Bokovskaya", "value": "C103979"},
                   {"label": "Bystrogorskiy", "value": "C103980"},
                   {"label": "Chaltyr", "value": "C103981"},
                   {"label": "Chertkovo", "value": "C103982"},
                   {"label": "Donetsk", "value": "C103983"},
                   {"label": "Donskoy", "value": "C103984"},
                   {"label": "Dubovskoye", "value": "C103985"},
                   {"label": "Gigant", "value": "C103986"},
                   {"label": "Glubokiy", "value": "C103987"},
                   {"label": "Gornyatskiy", "value": "C103988"},
                   {"label": "Gornyy", "value": "C103989"},
                   {"label": "Grushevskaya", "value": "C103990"},
                   {"label": "Gukovo", "value": "C103991"},
                   {"label": "Gundorovskiy", "value": "C103992"},
                   {"label": "Kagalnitskaya", "value": "C103993"},
                   {"label": "Kalinin", "value": "C103994"},
                   {"label": "Kamenolomni", "value": "C103995"},
                   {"label": "Kamensk-Shakhtinskiy", "value": "C103996"},
                   {"label": "Kashary", "value": "C103997"},
                   {"label": "Kazanskaya", "value": "C103998"},
                   {"label": "Kirovskaya", "value": "C103999"},
                   {"label": "Koksovyy", "value": "C104000"},
                   {"label": "Konstantinovsk", "value": "C104001"},
                   {"label": "Krasnaya Polyana", "value": "C104002"},
                   {"label": "Krasnoarmeyskiy", "value": "C104003"},
                   {"label": "Krasnovka", "value": "C104004"},
                   {"label": "Krasnoyarskaya", "value": "C104005"},
                   {"label": "Krasnyy Sulin", "value": "C104006"},
                   {"label": "Krasyukovskaya", "value": "C104007"},
                   {"label": "Krivyanskaya", "value": "C104008"},
                   {"label": "Krym", "value": "C104009"},
                   {"label": "Kuleshovka", "value": "C104010"},
                   {"label": "Kuybyshevo", "value": "C104011"},
                   {"label": "Letnik", "value": "C104012"},
                   {"label": "Likhovskoy", "value": "C104013"},
                   {"label": "Likhoy", "value": "C104014"},
                   {"label": "Martynovskiy Rayon", "value": "C104015"},
                   {"label": "Masalovka", "value": "C104016"},
                   {"label": "Matveyev Kurgan", "value": "C104017"},
                   {"label": "Mayskiy", "value": "C104018"},
                   {"label": "Mechetinskaya", "value": "C104019"},
                   {"label": "Melikhovskaya", "value": "C104020"},
                   {"label": "Millerovo", "value": "C104021"},
                   {"label": "Milyutinskaya", "value": "C104022"},
                   {"label": "Milyutinskiy Rayon", "value": "C104023"},
                   {"label": "Morozovsk", "value": "C104024"},
                   {"label": "Nedvigovka", "value": "C104025"},
                   {"label": "Nikolayevskaya", "value": "C104026"},
                   {"label": "Novobataysk", "value": "C104027"},
                   {"label": "Novobessergenovka", "value": "C104028"},
                   {"label": "Novocherkassk", "value": "C104029"},
                   {"label": "Novoshakhtinsk", "value": "C104030"},
                   {"label": "Novyy Yegorlyk", "value": "C104031"},
                   {"label": "Oblivskaya", "value": "C104032"},
                   {"label": "Olginskaya", "value": "C104033"},
                   {"label": "Orlovskiy", "value": "C104034"},
                   {"label": "Persianovka", "value": "C104035"},
                   {"label": "Peschanokopskoye", "value": "C104036"},
                   {"label": "Peshkovo", "value": "C104037"},
                   {"label": "Pokrovskoye", "value": "C104038"},
                   {"label": "Port-Katon", "value": "C104039"},
                   {"label": "Primorka", "value": "C104040"},
                   {"label": "Proletarsk", "value": "C104041"},
                   {"label": "Rassvet", "value": "C104042"},
                   {"label": "Remontnoye", "value": "C104043"},
                   {"label": "Rodionovo-Nesvetaiskoye", "value": "C104044"},
                   {"label": "Rodionovo-Nesvetayskaya", "value": "C104045"},
                   {"label": "Romanovskaya", "value": "C104046"},
                   {"label": "Rostov-na-Donu", "value": "C104047"},
                   {"label": "Salsk", "value": "C104048"},
                   {"label": "Samarskoye", "value": "C104049"},
                   {"label": "Sambek", "value": "C104050"},
                   {"label": "Sandata", "value": "C104051"},
                   {"label": "Semikarakorsk", "value": "C104052"},
                   {"label": "Shakhty", "value": "C104053"},
                   {"label": "Sholokhovskiy", "value": "C104054"},
                   {"label": "Sinegorskiy", "value": "C104055"},
                   {"label": "Sinyavskoye", "value": "C104056"},
                   {"label": "Sokolovo-Kundryuchenskiy", "value": "C104057"},
                   {"label": "Sovetskaya", "value": "C104058"},
                   {"label": "Staraya Stanitsa", "value": "C104059"},
                   {"label": "Starocherkasskaya", "value": "C104060"},
                   {"label": "Taganrog", "value": "C104061"},
                   {"label": "Talovyy", "value": "C104062"},
                   {"label": "Tanais", "value": "C104063"},
                   {"label": "Tarasovskiy", "value": "C104064"},
                   {"label": "Tatsinskiy", "value": "C104065"},
                   {"label": "Tselina", "value": "C104066"},
                   {"label": "Tsimlyansk", "value": "C104067"},
                   {"label": "Uglegorskiy", "value": "C104068"},
                   {"label": "Uglerodovskiy", "value": "C104069"},
                   {"label": "Ust-Donetskiy", "value": "C104070"},
                   {"label": "Veselyy", "value": "C104071"},
                   {"label": "Veshenskaya", "value": "C104072"},
                   {"label": "Volgodonsk", "value": "C104073"},
                   {"label": "Yegorlykskaya", "value": "C104074"},
                   {"label": "Zavetnoye", "value": "C104075"},
                   {"label": "Zernograd", "value": "C104076"},
                   {"label": "Zhirnov", "value": "C104077"},
                   {"label": "Zimovniki", "value": "C104078"},
                   {"label": "Zverevo", "value": "C104079"}],
 "Rozaje Municipality": [{"label": "Rozaje", "value": "C75623"}],
 "Rucava Municipality": [{"label": "Rucava", "value": "C64927"}],
 "Rugaji Municipality": [{"label": "Rugaji", "value": "C64928"}],
 "Ruggell": [{"label": "Ruggell", "value": "C65076"}],
 "Rujiena Municipality": [{"label": "Rujiena", "value": "C64929"}],
 "Rukwa": [{"label": "Chala", "value": "C118282"},
           {"label": "Kirando", "value": "C118283"},
           {"label": "Laela", "value": "C118284"},
           {"label": "Matai", "value": "C118285"},
           {"label": "Namanyere", "value": "C118286"},
           {"label": "Nkove", "value": "C118287"},
           {"label": "Sumbawanga", "value": "C118288"}],
 "Rum Cay District": [{"label": "Port Nelson", "value": "C119621"}],
 "Rumonge Province": [{"label": "Rumonge", "value": "C16456"}],
 "Rundale Municipality": [{"label": "Pilsrundale", "value": "C64930"}],
 "Ruse Municipality": [{"label": "Bistrica ob Dravi", "value": "C107790"},
                       {"label": "Ruse", "value": "C107791"}],
 "Ruse Province": [{"label": "Borovo", "value": "C16113"},
                   {"label": "Dve Mogili", "value": "C16114"},
                   {"label": "Ivanovo", "value": "C16115"},
                   {"label": "Obshtina Borovo", "value": "C16116"},
                   {"label": "Obshtina Byala", "value": "C16117"},
                   {"label": "Obshtina Dve Mogili", "value": "C16118"},
                   {"label": "Obshtina Ivanovo", "value": "C16119"},
                   {"label": "Obshtina Ruse", "value": "C16120"},
                   {"label": "Obshtina Slivo Pole", "value": "C16121"},
                   {"label": "Obshtina Tsenovo", "value": "C16122"},
                   {"label": "Obshtina Vetovo", "value": "C16123"},
                   {"label": "Ruse", "value": "C16124"},
                   {"label": "Senovo", "value": "C16125"},
                   {"label": "Slivo Pole", "value": "C16126"},
                   {"label": "Tsenovo", "value": "C16127"},
                   {"label": "Vetovo", "value": "C16128"}],
 "Rutana Province": [{"label": "Rutana", "value": "C16457"}],
 "Ruvuma": [{"label": "Kigonsera", "value": "C118289"},
            {"label": "Liuli", "value": "C118290"},
            {"label": "Mahanje", "value": "C118291"},
            {"label": "Maposeni", "value": "C118292"},
            {"label": "Matiri", "value": "C118293"},
            {"label": "Mbamba Bay", "value": "C118294"},
            {"label": "Mbinga", "value": "C118295"},
            {"label": "Songea", "value": "C118296"},
            {"label": "Tingi", "value": "C118297"}],
 "Ruyigi Province": [{"label": "Ruyigi", "value": "C16458"}],
 "Ryanggang Province": [{"label": "Hyesan", "value": "C78587"},
                        {"label": "Hyesan-dong", "value": "C78588"},
                        {"label": "Kapsan-up", "value": "C78589"},
                        {"label": "Sungjibaegam", "value": "C78590"}],
 "Ryazan Oblast": [{"label": "Aleksandro-Nevskiy", "value": "C104080"},
                   {"label": "Bagramovo", "value": "C104081"},
                   {"label": "Chuchkovo", "value": "C104082"},
                   {"label": "Gus-Zheleznyy", "value": "C104083"},
                   {"label": "Izhevskoye", "value": "C104084"},
                   {"label": "Kadom", "value": "C104085"},
                   {"label": "Kasimov", "value": "C104086"},
                   {"label": "Kasimovskiy Rayon", "value": "C104087"},
                   {"label": "Kiritsy", "value": "C104088"},
                   {"label": "Klepikovskiy Rayon", "value": "C104089"},
                   {"label": "Korablino", "value": "C104090"},
                   {"label": "Lashma", "value": "C104091"},
                   {"label": "Lesnoy", "value": "C104092"},
                   {"label": "Mikhaylov", "value": "C104093"},
                   {"label": "Miloslavskoye", "value": "C104094"},
                   {"label": "Murmino", "value": "C104095"},
                   {"label": "Novomichurinsk", "value": "C104096"},
                   {"label": "Oktyabrskiy", "value": "C104097"},
                   {"label": "Pavelets", "value": "C104098"},
                   {"label": "Pitelino", "value": "C104099"},
                   {"label": "Polyany", "value": "C104100"},
                   {"label": "Pronsk", "value": "C104101"},
                   {"label": "Putyatino", "value": "C104102"},
                   {"label": "Ryazan", "value": "C104103"},
                   {"label": "Ryazanskiy Rayon", "value": "C104104"},
                   {"label": "Ryazhsk", "value": "C104105"},
                   {"label": "Rybnovskiy Rayon", "value": "C104106"},
                   {"label": "Rybnoye", "value": "C104107"},
                   {"label": "Sapozhok", "value": "C104108"},
                   {"label": "Sarai", "value": "C104109"},
                   {"label": "Sasovo", "value": "C104110"},
                   {"label": "Shatsk", "value": "C104111"},
                   {"label": "Shilovo", "value": "C104112"},
                   {"label": "Skopin", "value": "C104113"},
                   {"label": "Solotcha", "value": "C104114"},
                   {"label": "Spas-Klepiki", "value": "C104115"},
                   {"label": "Spassk-Ryazanskiy", "value": "C104116"},
                   {"label": "Starozhilovo", "value": "C104117"},
                   {"label": "Syntul", "value": "C104118"},
                   {"label": "Tuma", "value": "C104119"},
                   {"label": "Ukholovo", "value": "C104120"},
                   {"label": "Vilenka", "value": "C104121"},
                   {"label": "Yambirno", "value": "C104122"},
                   {"label": "Yelatma", "value": "C104123"},
                   {"label": "Yermish", "value": "C104124"},
                   {"label": "Zakharovo", "value": "C104125"},
                   {"label": "Zakharovskiy Rayon", "value": "C104126"},
                   {"label": "Zarechnyy", "value": "C104127"}],
 "Sa Kaeo": [{"label": "Amphoe Aranyaprathet", "value": "C119258"},
             {"label": "Amphoe Khao Chakan", "value": "C119259"},
             {"label": "Amphoe Khlong Hat", "value": "C119260"},
             {"label": "Amphoe Khok Sung", "value": "C119261"},
             {"label": "Amphoe Mueang Sa Kaeo", "value": "C119262"},
             {"label": "Amphoe Ta Phraya", "value": "C119263"},
             {"label": "Amphoe Wang Nam Yen", "value": "C119264"},
             {"label": "Amphoe Wang Sombun", "value": "C119265"},
             {"label": "Amphoe Watthana Nakhon", "value": "C119266"},
             {"label": "Aranyaprathet", "value": "C119267"},
             {"label": "Sa Kaeo", "value": "C119268"},
             {"label": "Wang Nam Yen", "value": "C119269"}],
 "Saada Governorate": [{"label": "Al Dhaher", "value": "C148001"},
                       {"label": "Al Hashwah", "value": "C148002"},
                       {"label": "As Safra", "value": "C148003"},
                       {"label": "Ash Shawati", "value": "C148004"},
                       {"label": "Baqim", "value": "C148005"},
                       {"label": "Ghamr", "value": "C148006"},
                       {"label": "Haydan", "value": "C148007"},
                       {"label": "Kitaf wa Al Boqee", "value": "C148008"},
                       {"label": "Majz", "value": "C148009"},
                       {"label": "Monabbih", "value": "C148010"},
                       {"label": "Qatabir", "value": "C148011"},
                       {"label": "Razih", "value": "C148012"},
                       {"label": "Sadah", "value": "C148013"},
                       {"label": "Sadah", "value": "C148014"},
                       {"label": "Sahar", "value": "C148015"},
                       {"label": "Saqayn", "value": "C148016"},
                       {"label": "Shadaa", "value": "C148017"}],
 "Saare County": [{"label": "Kuressaare", "value": "C24619"},
                  {"label": "Liiva", "value": "C24620"},
                  {"label": "Muhu vald", "value": "C24621"},
                  {"label": "Orissaare", "value": "C24622"},
                  {"label": "Ruhnu", "value": "C24623"},
                  {"label": "Ruhnu vald", "value": "C24624"},
                  {"label": "Tehumardi", "value": "C24625"}],
 "Saarland": [{"label": "Beckingen", "value": "C39887"},
              {"label": "Bexbach", "value": "C39888"},
              {"label": "Blieskastel", "value": "C39889"},
              {"label": "Bous", "value": "C39890"},
              {"label": "Britten", "value": "C39891"},
              {"label": "Dillingen", "value": "C39892"},
              {"label": "Ensdorf", "value": "C39893"},
              {"label": "Eppelborn", "value": "C39894"},
              {"label": "Freisen", "value": "C39895"},
              {"label": "Friedrichsthal", "value": "C39896"},
              {"label": "Furstenhausen", "value": "C39897"},
              {"label": "Gersheim", "value": "C39898"},
              {"label": "Grossrosseln", "value": "C39899"},
              {"label": "Hangard", "value": "C39900"},
              {"label": "Heidstock", "value": "C39901"},
              {"label": "Heusweiler", "value": "C39902"},
              {"label": "Homburg", "value": "C39903"},
              {"label": "Illingen", "value": "C39904"},
              {"label": "Kirkel", "value": "C39905"},
              {"label": "Kleinblittersdorf", "value": "C39906"},
              {"label": "Lebach", "value": "C39907"},
              {"label": "Losheim", "value": "C39908"},
              {"label": "Ludweiler-Warndt", "value": "C39909"},
              {"label": "Luisenthal", "value": "C39910"},
              {"label": "Mainzweiler", "value": "C39911"},
              {"label": "Marpingen", "value": "C39912"},
              {"label": "Merchweiler", "value": "C39913"},
              {"label": "Merzig", "value": "C39914"},
              {"label": "Mettlach", "value": "C39915"},
              {"label": "Nalbach", "value": "C39916"},
              {"label": "Namborn", "value": "C39917"},
              {"label": "Neunkirchen", "value": "C39918"},
              {"label": "Nohfelden", "value": "C39919"},
              {"label": "Nonnweiler", "value": "C39920"},
              {"label": "Oberthal", "value": "C39921"},
              {"label": "Orscholz", "value": "C39922"},
              {"label": "Ottweiler", "value": "C39923"},
              {"label": "Puttlingen", "value": "C39924"},
              {"label": "Quierschied", "value": "C39925"},
              {"label": "Riegelsberg", "value": "C39926"},
              {"label": "Rochling-Hohe", "value": "C39927"},
              {"label": "Saarbrucken", "value": "C39928"},
              {"label": "Saarholzbach", "value": "C39929"},
              {"label": "Saarlouis", "value": "C39930"},
              {"label": "Saarwellingen", "value": "C39931"},
              {"label": "Sankt Ingbert", "value": "C39932"},
              {"label": "Sankt Wendel", "value": "C39933"},
              {"label": "Schiffweiler", "value": "C39934"},
              {"label": "Schmelz", "value": "C39935"},
              {"label": "Schwalbach", "value": "C39936"},
              {"label": "Spiesen-Elversberg", "value": "C39937"},
              {"label": "Sulzbach", "value": "C39938"},
              {"label": "Tholey", "value": "C39939"},
              {"label": "uberherrn", "value": "C39940"},
              {"label": "Volklingen", "value": "C39941"},
              {"label": "Wadern", "value": "C39942"},
              {"label": "Wadgassen", "value": "C39943"},
              {"label": "Wallerfangen", "value": "C39944"},
              {"label": "Weiskirchen", "value": "C39945"},
              {"label": "Weiten", "value": "C39946"}],
 "Saatly District": [{"label": "@hm@db@yli", "value": "C8501"},
                     {"label": "Saatli", "value": "C8502"}],
 "Sabah": [{"label": "Bandar Labuan", "value": "C65739"},
           {"label": "Beaufort", "value": "C65740"},
           {"label": "Donggongon", "value": "C65741"},
           {"label": "Keningau", "value": "C65742"},
           {"label": "Kinarut", "value": "C65743"},
           {"label": "Kota Belud", "value": "C65744"},
           {"label": "Kota Kinabalu", "value": "C65745"},
           {"label": "Kudat", "value": "C65746"},
           {"label": "Lahad Datu", "value": "C65747"},
           {"label": "Papar", "value": "C65748"},
           {"label": "Putatan", "value": "C65749"},
           {"label": "Ranau", "value": "C65750"},
           {"label": "Sandakan", "value": "C65751"},
           {"label": "Semporna", "value": "C65752"},
           {"label": "Taman Rajawali", "value": "C65753"},
           {"label": "Tawau", "value": "C65754"}],
 "Sabaragamuwa Province": [{"label": "Kegalle", "value": "C115337"},
                           {"label": "Kegalle District", "value": "C115338"},
                           {"label": "Ratnapura", "value": "C115339"},
                           {"label": "Ratnapura District", "value": "C115340"}],
 "Sabha District": [{"label": "Al Jadid", "value": "C65058"},
                    {"label": "Sabha", "value": "C65059"}],
 "Sabirabad District": [{"label": "Sabirabad", "value": "C8503"}],
 "Sacatepequez Department": [{"label": "Alotenango", "value": "C42867"},
                             {"label": "Antigua Guatemala", "value": "C42868"},
                             {"label": "Ciudad Vieja", "value": "C42869"},
                             {"label": "Jocotenango", "value": "C42870"},
                             {"label": "Magdalena Milpas Altas",
                              "value": "C42871"},
                             {"label": "Municipio de Alotenango",
                              "value": "C42872"},
                             {"label": "Municipio de Antigua Guatemala",
                              "value": "C42873"},
                             {"label": "Municipio de Ciudad Vieja",
                              "value": "C42874"},
                             {"label": "Municipio de Jocotenango",
                              "value": "C42875"},
                             {"label": "Municipio de Magdalena Milpas Altas",
                              "value": "C42876"},
                             {"label": "Municipio de Santa Lucia Milpas Altas",
                              "value": "C42877"},
                             {"label": "Municipio de Santa Maria de Jesus",
                              "value": "C42878"},
                             {"label": "Pastores", "value": "C42879"},
                             {"label": "San Antonio Aguas Calientes",
                              "value": "C42880"},
                             {"label": "San Bartolome Milpas Altas",
                              "value": "C42881"},
                             {"label": "San Lucas Sacatepequez",
                              "value": "C42882"},
                             {"label": "San Miguel Duenas", "value": "C42883"},
                             {"label": "Santa Catarina Barahona",
                              "value": "C42884"},
                             {"label": "Santa Lucia Milpas Altas",
                              "value": "C42885"},
                             {"label": "Santa Maria de Jesus",
                              "value": "C42886"},
                             {"label": "Santiago Sacatepequez",
                              "value": "C42887"},
                             {"label": "Santo Domingo Xenacoj",
                              "value": "C42888"},
                             {"label": "Sumpango", "value": "C42889"}],
 "Safi": [{"label": "Abadou", "value": "C75803"},
          {"label": "Adassil", "value": "C75804"},
          {"label": "Al-Haouz", "value": "C75805"},
          {"label": "Bouabout", "value": "C75806"},
          {"label": "Chichaoua", "value": "C75807"},
          {"label": "Essaouira", "value": "C75808"},
          {"label": "Kelaa-Des-Sraghna", "value": "C75809"},
          {"label": "Marrakech", "value": "C75810"},
          {"label": "Marrakesh", "value": "C75811"},
          {"label": "Oukaimedene", "value": "C75812"},
          {"label": "Rehamna", "value": "C75813"},
          {"label": "Safi", "value": "C75814"},
          {"label": "Setti Fatma", "value": "C75815"},
          {"label": "Sidi Rahhal", "value": "C75816"},
          {"label": "Smimou", "value": "C75817"},
          {"label": "Tamanar", "value": "C75818"},
          {"label": "Taouloukoult", "value": "C75819"},
          {"label": "Tidili Mesfioua", "value": "C75820"},
          {"label": "Timezgadiouine", "value": "C75821"},
          {"label": "Youssoufia", "value": "C75822"},
          {"label": "Zerkten", "value": "C75823"}],
 "Saga Prefecture": [{"label": "Imari Shi", "value": "C63786"},
                     {"label": "Imaricho-ko", "value": "C63787"},
                     {"label": "Kanzaki Shi", "value": "C63788"},
                     {"label": "Karatsu", "value": "C63789"},
                     {"label": "Karatsu Shi", "value": "C63790"},
                     {"label": "Kashima", "value": "C63791"},
                     {"label": "Kashima Shi", "value": "C63792"},
                     {"label": "Miyaki-gun", "value": "C63793"},
                     {"label": "Ogi-shi", "value": "C63794"},
                     {"label": "Saga", "value": "C63795"},
                     {"label": "Saga Shi", "value": "C63796"},
                     {"label": "Takeo Shi", "value": "C63797"},
                     {"label": "Takeocho-takeo", "value": "C63798"},
                     {"label": "Taku Shi", "value": "C63799"},
                     {"label": "Tosu Shi", "value": "C63800"},
                     {"label": "Ureshino Shi", "value": "C63801"}],
 "Sagaing Region": [{"label": "Mawlaik", "value": "C75948"},
                    {"label": "Monywa", "value": "C75949"},
                    {"label": "Sagaing", "value": "C75950"},
                    {"label": "Shwebo", "value": "C75951"}],
 "Sahel Region": [{"label": "Djibo", "value": "C16428"},
                  {"label": "Dori", "value": "C16429"},
                  {"label": "Gorom-Gorom", "value": "C16430"},
                  {"label": "Province de lOudalan", "value": "C16431"},
                  {"label": "Province du Seno", "value": "C16432"},
                  {"label": "Province du Soum", "value": "C16433"},
                  {"label": "Province du Yagha", "value": "C16434"}],
 "Saida": [{"label": "Ain el Hadjar", "value": "C404"},
           {"label": "Saida", "value": "C405"}],
 "Saint Andrew": [{"label": "Greenland", "value": "C8696"},
                  {"label": "Arcadia", "value": "C61950"},
                  {"label": "Arlene Gardens", "value": "C61951"},
                  {"label": "Arnett Gardens", "value": "C61952"},
                  {"label": "August Town", "value": "C61953"},
                  {"label": "Barbican", "value": "C61954"},
                  {"label": "Beverley Hills", "value": "C61955"},
                  {"label": "Bito", "value": "C61956"},
                  {"label": "Bloxborough", "value": "C61957"},
                  {"label": "Boucher Park", "value": "C61958"},
                  {"label": "Brandon Hill", "value": "C61959"},
                  {"label": "Bull Bay Seven Mile", "value": "C61960"},
                  {"label": "Cane River", "value": "C61961"},
                  {"label": "Cassia Park", "value": "C61962"},
                  {"label": "Cavaliers", "value": "C61963"},
                  {"label": "Cherry Gardens", "value": "C61964"},
                  {"label": "Cockburn Gardens", "value": "C61965"},
                  {"label": "Constant Spring", "value": "C61966"},
                  {"label": "Constant Spring Gardens", "value": "C61967"},
                  {"label": "Constitution Hill", "value": "C61968"},
                  {"label": "Content Gap", "value": "C61969"},
                  {"label": "Cooreville Gardens", "value": "C61970"},
                  {"label": "Cross Roads", "value": "C61971"},
                  {"label": "Dallas", "value": "C61972"},
                  {"label": "Delacree Park Union Gardens", "value": "C61973"},
                  {"label": "Delacree Pen", "value": "C61974"},
                  {"label": "Drewsland", "value": "C61975"},
                  {"label": "Drumblair", "value": "C61976"},
                  {"label": "Duhaney Park", "value": "C61977"},
                  {"label": "Eastwood Park Gardens", "value": "C61978"},
                  {"label": "Elleston Flats Mona Commons", "value": "C61979"},
                  {"label": "Ferry", "value": "C61980"},
                  {"label": "Forest Hills Gardens", "value": "C61981"},
                  {"label": "Forest Hills Plantation Height",
                   "value": "C61982"},
                  {"label": "Golden Spring", "value": "C61983"},
                  {"label": "Gordon Town", "value": "C61984"},
                  {"label": "Grants Pen", "value": "C61985"},
                  {"label": "Greenwich Town Newport West", "value": "C61986"},
                  {"label": "Half Way Tree", "value": "C61987"},
                  {"label": "Half-Way-Tree", "value": "C61988"},
                  {"label": "Harbour View", "value": "C61989"},
                  {"label": "Havendale", "value": "C61990"},
                  {"label": "Hermitage", "value": "C61991"},
                  {"label": "Hope Pastures UTECH", "value": "C61992"},
                  {"label": "Hope Tavern", "value": "C61993"},
                  {"label": "Hughenden", "value": "C61994"},
                  {"label": "Irish Town", "value": "C61995"},
                  {"label": "Jacks Hill", "value": "C61996"},
                  {"label": "Jones Town", "value": "C61997"},
                  {"label": "Kencot", "value": "C61998"},
                  {"label": "Kintyre", "value": "C61999"},
                  {"label": "Lawrence Tavern", "value": "C62000"},
                  {"label": "Liguanea", "value": "C62001"},
                  {"label": "Majestic Gardens", "value": "C62002"},
                  {"label": "Mannings Hill", "value": "C62003"},
                  {"label": "Mannings Hill (Rural)", "value": "C62004"},
                  {"label": "Marverley", "value": "C62005"},
                  {"label": "Maryland", "value": "C62006"},
                  {"label": "Mavis Bank", "value": "C62007"},
                  {"label": "Maxfield Park", "value": "C62008"},
                  {"label": "Meadowbrook", "value": "C62009"},
                  {"label": "Meadowbrook Estate", "value": "C62010"},
                  {"label": "Molynes Four Roads", "value": "C62011"},
                  {"label": "Molynes Gardens", "value": "C62012"},
                  {"label": "Mona Heights", "value": "C62013"},
                  {"label": "Mount James", "value": "C62014"},
                  {"label": "Mountain View Gardens", "value": "C62015"},
                  {"label": "Nannyville", "value": "C62016"},
                  {"label": "New Haven", "value": "C62017"},
                  {"label": "New Kingston", "value": "C62018"},
                  {"label": "Norbrook", "value": "C62019"},
                  {"label": "Oaklands", "value": "C62020"},
                  {"label": "Papine", "value": "C62021"},
                  {"label": "Parks Road", "value": "C62022"},
                  {"label": "Patrick City", "value": "C62023"},
                  {"label": "Pembroke Hall", "value": "C62024"},
                  {"label": "Penwood", "value": "C62025"},
                  {"label": "Queensborough Tunbridge", "value": "C62026"},
                  {"label": "Red Hills (Rural)", "value": "C62027"},
                  {"label": "Red Hills Gardens", "value": "C62028"},
                  {"label": "Red Hills Sterling Castle", "value": "C62029"},
                  {"label": "Richmond Park", "value": "C62030"},
                  {"label": "Riverton City", "value": "C62031"},
                  {"label": "Roehampton", "value": "C62032"},
                  {"label": "Rose Town", "value": "C62033"},
                  {"label": "Seaview Gardens", "value": "C62034"},
                  {"label": "Seaward Pen", "value": "C62035"},
                  {"label": "Seymour Lands", "value": "C62036"},
                  {"label": "St. Peters", "value": "C62037"},
                  {"label": "Stadium Gardens", "value": "C62038"},
                  {"label": "Stony Hill", "value": "C62039"},
                  {"label": "Swallowfield", "value": "C62040"},
                  {"label": "Temple Hall", "value": "C62041"},
                  {"label": "Three Oaks Glendale", "value": "C62042"},
                  {"label": "Tower Hill", "value": "C62043"},
                  {"label": "Trafalgar Park", "value": "C62044"},
                  {"label": "Trench Town", "value": "C62045"},
                  {"label": "University", "value": "C62046"},
                  {"label": "Vineyard Town", "value": "C62047"},
                  {"label": "Waltham Gardens", "value": "C62048"},
                  {"label": "Washington Gardens", "value": "C62049"},
                  {"label": "Waterhouse", "value": "C62050"},
                  {"label": "Whitehall", "value": "C62051"},
                  {"label": "Whitfield Town", "value": "C62052"},
                  {"label": "Wilton Gardens Rema", "value": "C62053"},
                  {"label": "Woodford", "value": "C62054"},
                  {"label": "Woodford Park", "value": "C62055"},
                  {"label": "Zaidie Gardens", "value": "C62056"}],
 "Saint Andrew Parish": [{"label": "Calibishie", "value": "C23818"},
                         {"label": "Marigot", "value": "C23819"},
                         {"label": "Wesley", "value": "C23820"},
                         {"label": "Woodford Hill", "value": "C23821"},
                         {"label": "Grenville", "value": "C42609"},
                         {"label": "Layou", "value": "C106220"}],
 "Saint Ann Parish": [{"label": "Aboukir", "value": "C62057"},
                      {"label": "Alderton", "value": "C62058"},
                      {"label": "Alexandria", "value": "C62059"},
                      {"label": "Alva", "value": "C62060"},
                      {"label": "Bamboo", "value": "C62061"},
                      {"label": "Beecher Town", "value": "C62062"},
                      {"label": "Bensonton", "value": "C62063"},
                      {"label": "Blackstonedge", "value": "C62064"},
                      {"label": "Bohemia", "value": "C62065"},
                      {"label": "Borobridge", "value": "C62066"},
                      {"label": "Browns Town", "value": "C62067"},
                      {"label": "Calderwood", "value": "C62068"},
                      {"label": "Cascade", "value": "C62069"},
                      {"label": "Cave Valley", "value": "C62070"},
                      {"label": "Chalky Hill", "value": "C62071"},
                      {"label": "Chester", "value": "C62072"},
                      {"label": "Claremont", "value": "C62073"},
                      {"label": "Cole Gate", "value": "C62074"},
                      {"label": "Content Garden", "value": "C62075"},
                      {"label": "Cuffie Ridge", "value": "C62076"},
                      {"label": "Discovery Bay", "value": "C62077"},
                      {"label": "Epworth", "value": "C62078"},
                      {"label": "Exchange", "value": "C62079"},
                      {"label": "Faiths Pen", "value": "C62080"},
                      {"label": "Gibraltar", "value": "C62081"},
                      {"label": "Golden Grove", "value": "C62082"},
                      {"label": "Great Pond", "value": "C62083"},
                      {"label": "Higgin Land", "value": "C62084"},
                      {"label": "Hinds Town", "value": "C62085"},
                      {"label": "Inverness", "value": "C62086"},
                      {"label": "Lime Hall", "value": "C62087"},
                      {"label": "Lime Tree Gardens", "value": "C62088"},
                      {"label": "Linton Park", "value": "C62089"},
                      {"label": "Macknie", "value": "C62090"},
                      {"label": "Madras", "value": "C62091"},
                      {"label": "Mansfield", "value": "C62092"},
                      {"label": "Moneague", "value": "C62093"},
                      {"label": "Mount Moriah", "value": "C62094"},
                      {"label": "Mount Zion", "value": "C62095"},
                      {"label": "Ocho Rios", "value": "C62096"},
                      {"label": "Parry Town", "value": "C62097"},
                      {"label": "Philadelphia", "value": "C62098"},
                      {"label": "Pimento Walk", "value": "C62099"},
                      {"label": "Priory", "value": "C62100"},
                      {"label": "River Head", "value": "C62101"},
                      {"label": "Runaway Bay", "value": "C62102"},
                      {"label": "Saint Anns Bay", "value": "C62103"},
                      {"label": "St. Anns Bay", "value": "C62104"},
                      {"label": "St. DAcre", "value": "C62105"},
                      {"label": "Steer Town", "value": "C62106"},
                      {"label": "Sturge Town", "value": "C62107"},
                      {"label": "Walkers Wood", "value": "C62108"},
                      {"label": "Watt Town", "value": "C62109"},
                      {"label": "York Castle", "value": "C62110"}],
 "Saint Anne Sandy Point Parish": [{"label": "Sandy Point Town",
                                    "value": "C105723"}],
 "Saint Catherine Parish": [{"label": "Above Rocks", "value": "C62111"},
                            {"label": "Bartons", "value": "C62112"},
                            {"label": "Bellas Gate", "value": "C62113"},
                            {"label": "Bog Walk", "value": "C62114"},
                            {"label": "Bogwalk", "value": "C62115"},
                            {"label": "Braeton", "value": "C62116"},
                            {"label": "Bridgeport", "value": "C62117"},
                            {"label": "Browns Hill", "value": "C62118"},
                            {"label": "Caymanas", "value": "C62119"},
                            {"label": "Central Village", "value": "C62120"},
                            {"label": "Crescent", "value": "C62121"},
                            {"label": "Cromarty", "value": "C62122"},
                            {"label": "Cumberland", "value": "C62123"},
                            {"label": "Edgewater", "value": "C62124"},
                            {"label": "Ensom", "value": "C62125"},
                            {"label": "Ewarton", "value": "C62126"},
                            {"label": "Ginger Ridge", "value": "C62127"},
                            {"label": "Glengoffe", "value": "C62128"},
                            {"label": "Golden Acres", "value": "C62129"},
                            {"label": "Greater Portmore", "value": "C62130"},
                            {"label": "Greendale", "value": "C62131"},
                            {"label": "Gregory Park", "value": "C62132"},
                            {"label": "Hampton Green", "value": "C62133"},
                            {"label": "Harewood", "value": "C62134"},
                            {"label": "Harkers Hall", "value": "C62135"},
                            {"label": "Hellshire", "value": "C62136"},
                            {"label": "Independence City", "value": "C62137"},
                            {"label": "Keystone", "value": "C62138"},
                            {"label": "Kitson Town", "value": "C62139"},
                            {"label": "Linstead", "value": "C62140"},
                            {"label": "Lluidas Vale", "value": "C62141"},
                            {"label": "Naggo Head", "value": "C62142"},
                            {"label": "Old Harbour", "value": "C62143"},
                            {"label": "Old Harbour Bay", "value": "C62144"},
                            {"label": "Old Harbour Road", "value": "C62145"},
                            {"label": "Part of Guys Hill", "value": "C62146"},
                            {"label": "Part of Keystone", "value": "C62147"},
                            {"label": "Part of Rock Hall", "value": "C62148"},
                            {"label": "Passage Fort", "value": "C62149"},
                            {"label": "Pear Tree Grove", "value": "C62150"},
                            {"label": "Point Hill", "value": "C62151"},
                            {"label": "Portmore", "value": "C62152"},
                            {"label": "Redwood", "value": "C62153"},
                            {"label": "Riversdale", "value": "C62154"},
                            {"label": "Sligoville", "value": "C62155"},
                            {"label": "Spanish Town", "value": "C62156"},
                            {"label": "Spanish Town Central",
                             "value": "C62157"},
                            {"label": "St. Johns East", "value": "C62158"},
                            {"label": "St. Johns West", "value": "C62159"},
                            {"label": "Troja", "value": "C62160"},
                            {"label": "Waterford", "value": "C62161"},
                            {"label": "Watermount", "value": "C62162"},
                            {"label": "West Cumberland", "value": "C62163"},
                            {"label": "Willowdene", "value": "C62164"}],
 "Saint Croix": [{"label": "Christiansted", "value": "C147725"},
                 {"label": "East End", "value": "C147726"},
                 {"label": "Frederiksted", "value": "C147727"},
                 {"label": "Good Hope", "value": "C147728"},
                 {"label": "Northcentral", "value": "C147729"},
                 {"label": "Northwest", "value": "C147730"},
                 {"label": "Sion Farm", "value": "C147731"},
                 {"label": "Southcentral", "value": "C147732"},
                 {"label": "Southwest", "value": "C147733"}],
 "Saint David Parish": [{"label": "Castle Bruce", "value": "C23822"},
                        {"label": "Rosalie", "value": "C23823"},
                        {"label": "Saint Davids", "value": "C42610"},
                        {"label": "Chateaubelair", "value": "C106221"}],
 "Saint Elizabeth Parish": [{"label": "Aberdeen", "value": "C62165"},
                            {"label": "Accompong", "value": "C62166"},
                            {"label": "Alligator Pond", "value": "C62167"},
                            {"label": "Balaclava", "value": "C62168"},
                            {"label": "Ballards  Valley", "value": "C62169"},
                            {"label": "Barbary Hall", "value": "C62170"},
                            {"label": "Bigwoods", "value": "C62171"},
                            {"label": "Black River", "value": "C62172"},
                            {"label": "Bogue", "value": "C62173"},
                            {"label": "Braes River", "value": "C62174"},
                            {"label": "Brighton", "value": "C62175"},
                            {"label": "Brompton", "value": "C62176"},
                            {"label": "Bull Savanna", "value": "C62177"},
                            {"label": "Bull Savannah", "value": "C62178"},
                            {"label": "Burnt Savannah", "value": "C62179"},
                            {"label": "Carisbrook", "value": "C62180"},
                            {"label": "Cotterwood", "value": "C62181"},
                            {"label": "Crawford", "value": "C62182"},
                            {"label": "Elderslie", "value": "C62183"},
                            {"label": "Fullerswood", "value": "C62184"},
                            {"label": "Giddy Hall", "value": "C62185"},
                            {"label": "Ginger Hill", "value": "C62186"},
                            {"label": "Goshen", "value": "C62187"},
                            {"label": "Holland", "value": "C62188"},
                            {"label": "Hopeton", "value": "C62189"},
                            {"label": "Junction", "value": "C62190"},
                            {"label": "Lacovia", "value": "C62191"},
                            {"label": "Leeds", "value": "C62192"},
                            {"label": "Lititz", "value": "C62193"},
                            {"label": "Maggotty", "value": "C62194"},
                            {"label": "Malvern", "value": "C62195"},
                            {"label": "Merrywood", "value": "C62196"},
                            {"label": "Middle Quarters", "value": "C62197"},
                            {"label": "Mountainside", "value": "C62198"},
                            {"label": "Mulgrave", "value": "C62199"},
                            {"label": "Myersville", "value": "C62200"},
                            {"label": "Nain", "value": "C62201"},
                            {"label": "New Market", "value": "C62202"},
                            {"label": "Newell", "value": "C62203"},
                            {"label": "Newton", "value": "C62204"},
                            {"label": "Parottee", "value": "C62205"},
                            {"label": "Pedro Plains", "value": "C62206"},
                            {"label": "Pepper", "value": "C62207"},
                            {"label": "Pisgah", "value": "C62208"},
                            {"label": "Pondside", "value": "C62209"},
                            {"label": "Potsdam", "value": "C62210"},
                            {"label": "Quick Step", "value": "C62211"},
                            {"label": "Red Bank", "value": "C62212"},
                            {"label": "Retirement", "value": "C62213"},
                            {"label": "Rose Hall", "value": "C62214"},
                            {"label": "Russels", "value": "C62215"},
                            {"label": "Santa Cruz", "value": "C62216"},
                            {"label": "Scholefield", "value": "C62217"},
                            {"label": "Siloah", "value": "C62218"},
                            {"label": "Slipe", "value": "C62219"},
                            {"label": "Southfield", "value": "C62220"},
                            {"label": "Springfield", "value": "C62221"},
                            {"label": "Thornton", "value": "C62222"},
                            {"label": "Top Hill", "value": "C62223"},
                            {"label": "Treasure Beach", "value": "C62224"},
                            {"label": "Warminister", "value": "C62225"},
                            {"label": "Watchwell", "value": "C62226"},
                            {"label": "White Hill", "value": "C62227"}],
 "Saint George Gingerland Parish": [{"label": "Market Shop",
                                     "value": "C105724"}],
 "Saint George Parish": [{"label": "Piggotts", "value": "C575"},
                         {"label": "Roseau", "value": "C23824"},
                         {"label": "Saint Georges", "value": "C42611"},
                         {"label": "Kingstown", "value": "C106222"},
                         {"label": "Kingstown Park", "value": "C106223"}],
 "Saint James": [{"label": "Holetown", "value": "C8697"}],
 "Saint James Parish": [{"label": "Adelphi", "value": "C62228"},
                        {"label": "Albion", "value": "C62229"},
                        {"label": "Anchovy", "value": "C62230"},
                        {"label": "Arcadia", "value": "C62231"},
                        {"label": "Barrett Town", "value": "C62232"},
                        {"label": "Bickersteth", "value": "C62233"},
                        {"label": "Bogue", "value": "C62234"},
                        {"label": "Brandon Hill", "value": "C62235"},
                        {"label": "Cambridge", "value": "C62236"},
                        {"label": "Canterbury", "value": "C62237"},
                        {"label": "Catadupa", "value": "C62238"},
                        {"label": "Catherine Hall", "value": "C62239"},
                        {"label": "Catherine Mount", "value": "C62240"},
                        {"label": "Comfort Hall", "value": "C62241"},
                        {"label": "Coral Gardens", "value": "C62242"},
                        {"label": "Cornwall", "value": "C62243"},
                        {"label": "Down Town Montego Bay", "value": "C62244"},
                        {"label": "Dumfries", "value": "C62245"},
                        {"label": "Fairfield", "value": "C62246"},
                        {"label": "Farm Heights", "value": "C62247"},
                        {"label": "Flagstaff", "value": "C62248"},
                        {"label": "Flamstead", "value": "C62249"},
                        {"label": "Flankers", "value": "C62250"},
                        {"label": "Flower Hill", "value": "C62251"},
                        {"label": "Friendship", "value": "C62252"},
                        {"label": "Garlands", "value": "C62253"},
                        {"label": "Glendevon", "value": "C62254"},
                        {"label": "Goodwill", "value": "C62255"},
                        {"label": "Granville", "value": "C62256"},
                        {"label": "Green Pond", "value": "C62257"},
                        {"label": "Greenwood", "value": "C62258"},
                        {"label": "Hopeton", "value": "C62259"},
                        {"label": "Ironshore", "value": "C62260"},
                        {"label": "Irwin", "value": "C62261"},
                        {"label": "Johns Hall", "value": "C62262"},
                        {"label": "Lilliput", "value": "C62263"},
                        {"label": "Lottery", "value": "C62264"},
                        {"label": "Maldon", "value": "C62265"},
                        {"label": "Maroon Town", "value": "C62266"},
                        {"label": "Mocho", "value": "C62267"},
                        {"label": "Montego Bay", "value": "C62268"},
                        {"label": "Montego Hill", "value": "C62269"},
                        {"label": "Montpelier", "value": "C62270"},
                        {"label": "Mount Carey", "value": "C62271"},
                        {"label": "Mount Horeb", "value": "C62272"},
                        {"label": "Mount Salem", "value": "C62273"},
                        {"label": "Niagara", "value": "C62274"},
                        {"label": "Norwood", "value": "C62275"},
                        {"label": "Orange", "value": "C62276"},
                        {"label": "Paradise", "value": "C62277"},
                        {"label": "Pitfour", "value": "C62278"},
                        {"label": "Porto Bello", "value": "C62279"},
                        {"label": "Reading", "value": "C62280"},
                        {"label": "Retirement", "value": "C62281"},
                        {"label": "Retrieve", "value": "C62282"},
                        {"label": "Roehampton", "value": "C62283"},
                        {"label": "Rose Hall", "value": "C62284"},
                        {"label": "Rose Heights", "value": "C62285"},
                        {"label": "Rose Mount Garden", "value": "C62286"},
                        {"label": "Rosemount", "value": "C62287"},
                        {"label": "Salt Spring", "value": "C62288"},
                        {"label": "Seven Rivers", "value": "C62289"},
                        {"label": "Somerton", "value": "C62290"},
                        {"label": "Spot Valley", "value": "C62291"},
                        {"label": "Stonehenge", "value": "C62292"},
                        {"label": "Summer Hill", "value": "C62293"},
                        {"label": "Sunderland", "value": "C62294"},
                        {"label": "Tangle River", "value": "C62295"},
                        {"label": "Tower HillMoy Hall", "value": "C62296"},
                        {"label": "Tucker", "value": "C62297"},
                        {"label": "Unity Hall", "value": "C62298"},
                        {"label": "Vaughnsfield", "value": "C62299"},
                        {"label": "Welcome Hall", "value": "C62300"},
                        {"label": "West Green", "value": "C62301"}],
 "Saint James Windward Parish": [{"label": "Newcastle", "value": "C105725"}],
 "Saint John": [{"label": "Central", "value": "C147734"},
                {"label": "Coral Bay", "value": "C147735"},
                {"label": "Cruz Bay", "value": "C147736"},
                {"label": "East End", "value": "C147737"}],
 "Saint John Capisterre Parish": [{"label": "Dieppe Bay Town",
                                   "value": "C105726"}],
 "Saint John Figtree Parish": [{"label": "Fig Tree", "value": "C105727"}],
 "Saint John Parish": [{"label": "Potters Village", "value": "C576"},
                       {"label": "Saint Johns", "value": "C577"},
                       {"label": "Portsmouth", "value": "C23825"},
                       {"label": "Gouyave", "value": "C42612"}],
 "Saint Joseph": [{"label": "Bathsheba", "value": "C8698"}],
 "Saint Joseph Parish": [{"label": "Saint Joseph", "value": "C23826"},
                         {"label": "Salisbury", "value": "C23827"}],
 "Saint Lawrence": [{"label": "San Lawrenz", "value": "C65911"}],
 "Saint Luke Parish": [{"label": "Pointe Michel", "value": "C23828"}],
 "Saint Mark Parish": [{"label": "Soufriere", "value": "C23829"},
                       {"label": "Victoria", "value": "C42613"}],
 "Saint Mary Cayon Parish": [{"label": "Cayon", "value": "C105728"}],
 "Saint Mary Parish": [{"label": "Bolands", "value": "C578"},
                       {"label": "Amiel Town", "value": "C62302"},
                       {"label": "Annotto Bay", "value": "C62303"},
                       {"label": "Baileys Vale", "value": "C62304"},
                       {"label": "Baxter Mountain", "value": "C62305"},
                       {"label": "Belfield", "value": "C62306"},
                       {"label": "Bonnygate", "value": "C62307"},
                       {"label": "Brainerd", "value": "C62308"},
                       {"label": "Broadgate", "value": "C62309"},
                       {"label": "Camberwell", "value": "C62310"},
                       {"label": "Carron Hall", "value": "C62311"},
                       {"label": "Castleton", "value": "C62312"},
                       {"label": "Charles Town", "value": "C62313"},
                       {"label": "Clonmel", "value": "C62314"},
                       {"label": "Devon Pen", "value": "C62315"},
                       {"label": "Dover", "value": "C62316"},
                       {"label": "Enfield", "value": "C62317"},
                       {"label": "Epson", "value": "C62318"},
                       {"label": "Flint River", "value": "C62319"},
                       {"label": "Free Hill", "value": "C62320"},
                       {"label": "Friendship Gap", "value": "C62321"},
                       {"label": "Galina", "value": "C62322"},
                       {"label": "Gayle", "value": "C62323"},
                       {"label": "Guys Hill", "value": "C62324"},
                       {"label": "Hampstead", "value": "C62325"},
                       {"label": "Highgate", "value": "C62326"},
                       {"label": "Islington", "value": "C62327"},
                       {"label": "Jacks River", "value": "C62328"},
                       {"label": "Jeffery Town", "value": "C62329"},
                       {"label": "Labyrinth", "value": "C62330"},
                       {"label": "Long Road", "value": "C62331"},
                       {"label": "Lucky Hill", "value": "C62332"},
                       {"label": "Mango Valley", "value": "C62333"},
                       {"label": "Martin", "value": "C62334"},
                       {"label": "Mason Hall", "value": "C62335"},
                       {"label": "Mile Gully", "value": "C62336"},
                       {"label": "Oracabessa", "value": "C62337"},
                       {"label": "Port Maria", "value": "C62338"},
                       {"label": "Prospect", "value": "C62339"},
                       {"label": "Retreat", "value": "C62340"},
                       {"label": "Richmond", "value": "C62341"},
                       {"label": "Robins Bay", "value": "C62342"},
                       {"label": "Sandside", "value": "C62343"},
                       {"label": "Scotts Hall", "value": "C62344"},
                       {"label": "Woodpark", "value": "C62345"},
                       {"label": "Woodside", "value": "C62346"}],
 "Saint Michael": [{"label": "Bridgetown", "value": "C8699"}],
 "Saint Patrick Parish": [{"label": "Berekua", "value": "C23830"},
                          {"label": "La Plaine", "value": "C23831"},
                          {"label": "Sauteurs", "value": "C42614"},
                          {"label": "Barrouallie", "value": "C106224"}],
 "Saint Paul Capisterre Parish": [{"label": "Saint Pauls", "value": "C105729"}],
 "Saint Paul Charlestown Parish": [{"label": "Charlestown",
                                    "value": "C105730"}],
 "Saint Paul Parish": [{"label": "Falmouth", "value": "C579"},
                       {"label": "Liberta", "value": "C580"},
                       {"label": "Mahaut", "value": "C23832"},
                       {"label": "Pont Casse", "value": "C23833"}],
 "Saint Peter": [{"label": "Speightstown", "value": "C8700"}],
 "Saint Peter Basseterre Parish": [{"label": "Monkey Hill",
                                    "value": "C105731"}],
 "Saint Peter Parish": [{"label": "All Saints", "value": "C581"},
                        {"label": "Parham", "value": "C582"},
                        {"label": "Colihaut", "value": "C23834"}],
 "Saint Petersburg": [{"label": "Admiralteisky", "value": "C104128"},
                      {"label": "Aleksandrovskaya", "value": "C104129"},
                      {"label": "Avtovo", "value": "C104130"},
                      {"label": "Beloostrov", "value": "C104131"},
                      {"label": "Centralniy", "value": "C104132"},
                      {"label": "Dachnoye", "value": "C104133"},
                      {"label": "Frunzenskiy Rayon", "value": "C104134"},
                      {"label": "Gorelovo", "value": "C104135"},
                      {"label": "Grazhdanka", "value": "C104136"},
                      {"label": "Kolomyagi", "value": "C104137"},
                      {"label": "Kolpino", "value": "C104138"},
                      {"label": "Komarovo", "value": "C104139"},
                      {"label": "Komendantsky aerodrom", "value": "C104140"},
                      {"label": "Krasnogvargeisky", "value": "C104141"},
                      {"label": "Krasnoye Selo", "value": "C104142"},
                      {"label": "Kronshtadt", "value": "C104143"},
                      {"label": "Kupchino", "value": "C104144"},
                      {"label": "Kurortnyy Rayon", "value": "C104145"},
                      {"label": "Kushelevka", "value": "C104146"},
                      {"label": "Lakhtinskiy", "value": "C104147"},
                      {"label": "Levashovo", "value": "C104148"},
                      {"label": "Lisiy Nos", "value": "C104149"},
                      {"label": "Lomonosov", "value": "C104150"},
                      {"label": "Metallostroy", "value": "C104151"},
                      {"label": "Molodezhnoye", "value": "C104152"},
                      {"label": "Novaya Derevnya", "value": "C104153"},
                      {"label": "Obukhovo", "value": "C104154"},
                      {"label": "Olgino", "value": "C104155"},
                      {"label": "Ozerki", "value": "C104156"},
                      {"label": "Pargolovo", "value": "C104157"},
                      {"label": "Pavlovsk", "value": "C104158"},
                      {"label": "Pesochnyy", "value": "C104159"},
                      {"label": "Peterhof", "value": "C104160"},
                      {"label": "Petro-Slavyanka", "value": "C104161"},
                      {"label": "Petrodvorets", "value": "C104162"},
                      {"label": "Petrogradka", "value": "C104163"},
                      {"label": "Pontonnyy", "value": "C104164"},
                      {"label": "Primorskiy Rayon", "value": "C104165"},
                      {"label": "Pushkin", "value": "C104166"},
                      {"label": "Razliv", "value": "C104167"},
                      {"label": "Repino", "value": "C104168"},
                      {"label": "Saint Petersburg", "value": "C104169"},
                      {"label": "Sapernyy", "value": "C104170"},
                      {"label": "Sestroretsk", "value": "C104171"},
                      {"label": "Shushary", "value": "C104172"},
                      {"label": "Shuvalovo", "value": "C104173"},
                      {"label": "Solnechnoye", "value": "C104174"},
                      {"label": "Sosnovaya Polyana", "value": "C104175"},
                      {"label": "Sosnovka", "value": "C104176"},
                      {"label": "Staraya Derevnya", "value": "C104177"},
                      {"label": "Strelna", "value": "C104178"},
                      {"label": "Tarkhovka", "value": "C104179"},
                      {"label": "Tyarlevo", "value": "C104180"},
                      {"label": "Uritsk", "value": "C104181"},
                      {"label": "Ust-Izhora", "value": "C104182"},
                      {"label": "Vasileostrovskiy Rayon", "value": "C104183"},
                      {"label": "Vasylevsky Ostrov", "value": "C104184"},
                      {"label": "Zelenogorsk", "value": "C104185"}],
 "Saint Philip": [{"label": "Crane", "value": "C8701"}],
 "Saint Thomas": [{"label": "Charlotte Amalie", "value": "C147738"},
                  {"label": "East End", "value": "C147739"},
                  {"label": "Northside", "value": "C147740"},
                  {"label": "Southside", "value": "C147741"},
                  {"label": "Tutu", "value": "C147742"},
                  {"label": "Water Island", "value": "C147743"},
                  {"label": "West End", "value": "C147744"}],
 "Saint Thomas Lowland Parish": [{"label": "Cotton Ground",
                                  "value": "C105732"}],
 "Saint Thomas Middle Island Parish": [{"label": "Middle Island",
                                        "value": "C105733"}],
 "Saint Thomas Parish": [{"label": "Airy Castle", "value": "C62347"},
                         {"label": "Albion", "value": "C62348"},
                         {"label": "Amity Hall", "value": "C62349"},
                         {"label": "Arcadia", "value": "C62350"},
                         {"label": "Baptist", "value": "C62351"},
                         {"label": "Barking Lodge", "value": "C62352"},
                         {"label": "Bath", "value": "C62353"},
                         {"label": "Bethel", "value": "C62354"},
                         {"label": "Cedar Valley", "value": "C62355"},
                         {"label": "Church Corner", "value": "C62356"},
                         {"label": "Dalvey", "value": "C62357"},
                         {"label": "Danvers Pen", "value": "C62358"},
                         {"label": "Duckenfield", "value": "C62359"},
                         {"label": "Duhaney Pen", "value": "C62360"},
                         {"label": "Easington", "value": "C62361"},
                         {"label": "Eleven Miles", "value": "C62362"},
                         {"label": "Font Hill", "value": "C62363"},
                         {"label": "Golden Grove", "value": "C62364"},
                         {"label": "Hagley Gap", "value": "C62365"},
                         {"label": "Hampton Court", "value": "C62366"},
                         {"label": "Hayfield", "value": "C62367"},
                         {"label": "Hillside", "value": "C62368"},
                         {"label": "Johns Town", "value": "C62369"},
                         {"label": "Johnson Mountain", "value": "C62370"},
                         {"label": "Jones Pen", "value": "C62371"},
                         {"label": "Leith Hall", "value": "C62372"},
                         {"label": "Llandewey", "value": "C62373"},
                         {"label": "Lloyds", "value": "C62374"},
                         {"label": "Lyssons", "value": "C62375"},
                         {"label": "Middleton", "value": "C62376"},
                         {"label": "Morant Bay", "value": "C62377"},
                         {"label": "Mount Lebanus", "value": "C62378"},
                         {"label": "New Monkland", "value": "C62379"},
                         {"label": "Pamphret", "value": "C62380"},
                         {"label": "Pear Tree", "value": "C62381"},
                         {"label": "Poormans Corner", "value": "C62382"},
                         {"label": "Port Morant", "value": "C62383"},
                         {"label": "Prospect", "value": "C62384"},
                         {"label": "Ramble", "value": "C62385"},
                         {"label": "Retreat", "value": "C62386"},
                         {"label": "Richmond Vale", "value": "C62387"},
                         {"label": "River Head", "value": "C62388"},
                         {"label": "Rowlandsfield", "value": "C62389"},
                         {"label": "Seaforth", "value": "C62390"},
                         {"label": "Somerset", "value": "C62391"},
                         {"label": "Spring Bank", "value": "C62392"},
                         {"label": "Spring Garden", "value": "C62393"},
                         {"label": "Sunning Hill", "value": "C62394"},
                         {"label": "Trinity Ville", "value": "C62395"},
                         {"label": "Trinityville", "value": "C62396"},
                         {"label": "Wheelerfield", "value": "C62397"},
                         {"label": "White Hall", "value": "C62398"},
                         {"label": "White Horses", "value": "C62399"},
                         {"label": "Wilmington", "value": "C62400"},
                         {"label": "Winchester", "value": "C62401"},
                         {"label": "Yallahs", "value": "C62402"},
                         {"label": "York", "value": "C62403"}],
 "Saint-Louis": [{"label": "Golere", "value": "C106868"},
                 {"label": "Ndioum", "value": "C106869"},
                 {"label": "Polel Diaoube", "value": "C106870"},
                 {"label": "Richard-Toll", "value": "C106871"},
                 {"label": "Rosso", "value": "C106872"},
                 {"label": "Saint-Louis", "value": "C106873"}],
 "Sainyabuli Province": [{"label": "Sainyabuli", "value": "C64800"}],
 "Saitama Prefecture": [{"label": "Ageo Shi", "value": "C63802"},
                        {"label": "Ageoshimo", "value": "C63803"},
                        {"label": "Asaka", "value": "C63804"},
                        {"label": "Asaka-shi", "value": "C63805"},
                        {"label": "Chichibu", "value": "C63806"},
                        {"label": "Chichibu-shi", "value": "C63807"},
                        {"label": "Fujimi-shi", "value": "C63808"},
                        {"label": "Fujimino Shi", "value": "C63809"},
                        {"label": "Fukaya-shi", "value": "C63810"},
                        {"label": "Fukayacho", "value": "C63811"},
                        {"label": "Fukiage-fujimi", "value": "C63812"},
                        {"label": "Gyoda", "value": "C63813"},
                        {"label": "Hanno", "value": "C63814"},
                        {"label": "Hanno-shi", "value": "C63815"},
                        {"label": "Hanyu", "value": "C63816"},
                        {"label": "Hanyu-shi", "value": "C63817"},
                        {"label": "Hasuda", "value": "C63818"},
                        {"label": "Hasuda-shi", "value": "C63819"},
                        {"label": "Hatogaya-honcho", "value": "C63820"},
                        {"label": "Hidaka-shi", "value": "C63821"},
                        {"label": "Higashimatsuyama Shi", "value": "C63822"},
                        {"label": "Hiki-gun", "value": "C63823"},
                        {"label": "Honjo", "value": "C63824"},
                        {"label": "Honjo Shi", "value": "C63825"},
                        {"label": "Iruma-shi", "value": "C63826"},
                        {"label": "Iwatsuki", "value": "C63827"},
                        {"label": "Kamifukuoka", "value": "C63828"},
                        {"label": "Kasukabe", "value": "C63829"},
                        {"label": "Kasukabe Shi", "value": "C63830"},
                        {"label": "Kawagoe", "value": "C63831"},
                        {"label": "Kawagoe-shi", "value": "C63832"},
                        {"label": "Kawaguchi", "value": "C63833"},
                        {"label": "Kawaguchi-shi", "value": "C63834"},
                        {"label": "Kazo", "value": "C63835"},
                        {"label": "Kazo-shi", "value": "C63836"},
                        {"label": "Kisai", "value": "C63837"},
                        {"label": "Kitamoto Shi", "value": "C63838"},
                        {"label": "Kodamacho-kodamaminami", "value": "C63839"},
                        {"label": "Konosu", "value": "C63840"},
                        {"label": "Konosu-Shi", "value": "C63841"},
                        {"label": "Koshigaya Shi", "value": "C63842"},
                        {"label": "Kuki-shi", "value": "C63843"},
                        {"label": "Kukichuo", "value": "C63844"},
                        {"label": "Kumagaya Shi", "value": "C63845"},
                        {"label": "Kurihashi", "value": "C63846"},
                        {"label": "Menuma", "value": "C63847"},
                        {"label": "Misato Shi", "value": "C63848"},
                        {"label": "Morohongo", "value": "C63849"},
                        {"label": "Niiza-shi", "value": "C63850"},
                        {"label": "Ogawa", "value": "C63851"},
                        {"label": "Oi", "value": "C63852"},
                        {"label": "Okegawa", "value": "C63853"},
                        {"label": "Okegawa-shi", "value": "C63854"},
                        {"label": "Osato-gun", "value": "C63855"},
                        {"label": "Saitama", "value": "C63856"},
                        {"label": "Saitama-shi", "value": "C63857"},
                        {"label": "Sakado", "value": "C63858"},
                        {"label": "Sakado-shi", "value": "C63859"},
                        {"label": "Satte", "value": "C63860"},
                        {"label": "Satte Shi", "value": "C63861"},
                        {"label": "Sayama", "value": "C63862"},
                        {"label": "Shiki", "value": "C63863"},
                        {"label": "Shiki Shi", "value": "C63864"},
                        {"label": "Shimotoda", "value": "C63865"},
                        {"label": "Shiraoka", "value": "C63866"},
                        {"label": "Shiraoka-shi", "value": "C63867"},
                        {"label": "Shobu", "value": "C63868"},
                        {"label": "Soka", "value": "C63869"},
                        {"label": "Soka Shi", "value": "C63870"},
                        {"label": "Sugito", "value": "C63871"},
                        {"label": "Toda-shi", "value": "C63872"},
                        {"label": "Tokorozawa", "value": "C63873"},
                        {"label": "Tokorozawa-shi", "value": "C63874"},
                        {"label": "Tsurugashima-shi", "value": "C63875"},
                        {"label": "Wako", "value": "C63876"},
                        {"label": "Wako-shi", "value": "C63877"},
                        {"label": "Warabi-shi", "value": "C63878"},
                        {"label": "Yashio", "value": "C63879"},
                        {"label": "Yashio-shi", "value": "C63880"},
                        {"label": "Yono", "value": "C63881"},
                        {"label": "Yorii", "value": "C63882"},
                        {"label": "Yoshikawa", "value": "C63883"},
                        {"label": "Yoshikawa Shi", "value": "C63884"}],
 "Sakarya": [{"label": "Adapazari", "value": "C120887"},
             {"label": "Akyazi", "value": "C120888"},
             {"label": "Arifiye", "value": "C120889"},
             {"label": "Erenler", "value": "C120890"},
             {"label": "Ferizli", "value": "C120891"},
             {"label": "Geyve", "value": "C120892"},
             {"label": "Hendek", "value": "C120893"},
             {"label": "Karapurcek", "value": "C120894"},
             {"label": "Karasu Ilcesi", "value": "C120895"},
             {"label": "Karasu Mahallesi", "value": "C120896"},
             {"label": "Kaynarca", "value": "C120897"},
             {"label": "Kocaali", "value": "C120898"},
             {"label": "Pamukova", "value": "C120899"},
             {"label": "Sapanca", "value": "C120900"},
             {"label": "Serdivan", "value": "C120901"},
             {"label": "Sogutlu", "value": "C120902"},
             {"label": "Tarakli", "value": "C120903"}],
 "Sakha Republic": [{"label": "Abyysky District", "value": "C104186"},
                    {"label": "Aldan", "value": "C104187"},
                    {"label": "Allaikhovskiy Rayon", "value": "C104188"},
                    {"label": "Almaznyy", "value": "C104189"},
                    {"label": "Amga", "value": "C104190"},
                    {"label": "Anabarskiy Rayon", "value": "C104191"},
                    {"label": "Aykhal", "value": "C104192"},
                    {"label": "Batagay", "value": "C104193"},
                    {"label": "Batagay-Alyta", "value": "C104194"},
                    {"label": "Belaya Gora", "value": "C104195"},
                    {"label": "Berdigestyakh", "value": "C104196"},
                    {"label": "Berkakit", "value": "C104197"},
                    {"label": "Borogontsy", "value": "C104198"},
                    {"label": "Bulunskiy Rayon", "value": "C104199"},
                    {"label": "Chernyshevskiy", "value": "C104200"},
                    {"label": "Cherskiy", "value": "C104201"},
                    {"label": "Chokurdakh", "value": "C104202"},
                    {"label": "Chulman", "value": "C104203"},
                    {"label": "Churapcha", "value": "C104204"},
                    {"label": "Deputatsky", "value": "C104205"},
                    {"label": "Dzhebariki-Khaya", "value": "C104206"},
                    {"label": "Eveno-Bytantaysky National District",
                     "value": "C104207"},
                    {"label": "Gornyy Rayon", "value": "C104208"},
                    {"label": "Kangalassy", "value": "C104209"},
                    {"label": "Khandyga", "value": "C104210"},
                    {"label": "Khatassy", "value": "C104211"},
                    {"label": "Kobyayskiy Rayon", "value": "C104212"},
                    {"label": "Kysyl-Syr", "value": "C104213"},
                    {"label": "Lebedinyy", "value": "C104214"},
                    {"label": "Leninskiy", "value": "C104215"},
                    {"label": "Lensk", "value": "C104216"},
                    {"label": "Magan", "value": "C104217"},
                    {"label": "Markha", "value": "C104218"},
                    {"label": "Mayya", "value": "C104219"},
                    {"label": "Mirninskiy Rayon", "value": "C104220"},
                    {"label": "Mirny", "value": "C104221"},
                    {"label": "Mokhsogollokh", "value": "C104222"},
                    {"label": "Momsky District", "value": "C104223"},
                    {"label": "Namskiy Rayon", "value": "C104224"},
                    {"label": "Namtsy", "value": "C104225"},
                    {"label": "Neryungri", "value": "C104226"},
                    {"label": "Nezhdaninskoe", "value": "C104227"},
                    {"label": "Nizhniy Bestyakh", "value": "C104228"},
                    {"label": "Nizhniy Kuranakh", "value": "C104229"},
                    {"label": "Nyurba", "value": "C104230"},
                    {"label": "Nyurbinsky District", "value": "C104231"},
                    {"label": "Olenyok", "value": "C104232"},
                    {"label": "Olenyoksky District", "value": "C104233"},
                    {"label": "Olyokminsk", "value": "C104234"},
                    {"label": "Olyokminsky District", "value": "C104235"},
                    {"label": "Oymyakonskiy District", "value": "C104236"},
                    {"label": "Peleduy", "value": "C104237"},
                    {"label": "Pokrovsk", "value": "C104238"},
                    {"label": "Sangar", "value": "C104239"},
                    {"label": "Saskylakh", "value": "C104240"},
                    {"label": "Serebryanyy Bor", "value": "C104241"},
                    {"label": "Solnechnyy", "value": "C104242"},
                    {"label": "Srednekolymsk", "value": "C104243"},
                    {"label": "Srednekolymsky District", "value": "C104244"},
                    {"label": "Suntar", "value": "C104245"},
                    {"label": "Suntarskiy District", "value": "C104246"},
                    {"label": "Tabaga", "value": "C104247"},
                    {"label": "Tiksi", "value": "C104248"},
                    {"label": "Tommot", "value": "C104249"},
                    {"label": "Tomponskiy District", "value": "C104250"},
                    {"label": "Udachny", "value": "C104251"},
                    {"label": "Ust-Aldansky District", "value": "C104252"},
                    {"label": "Ust-Kuyga", "value": "C104253"},
                    {"label": "Ust-Maya", "value": "C104254"},
                    {"label": "Ust-Nera", "value": "C104255"},
                    {"label": "Ust-Yanskiy Rayon", "value": "C104256"},
                    {"label": "Verkhnekolymsky District", "value": "C104257"},
                    {"label": "Verkhnevilyuysk", "value": "C104258"},
                    {"label": "Verkhnevilyuysky District", "value": "C104259"},
                    {"label": "Verkhoyansk", "value": "C104260"},
                    {"label": "Verkhoyansky District", "value": "C104261"},
                    {"label": "Vilyuysk", "value": "C104262"},
                    {"label": "Vilyuyskiy Rayon", "value": "C104263"},
                    {"label": "Vilyuysky District", "value": "C104264"},
                    {"label": "Vitim", "value": "C104265"},
                    {"label": "Yakutsk", "value": "C104266"},
                    {"label": "Ytyk-Kyuyel", "value": "C104267"},
                    {"label": "Zhatay", "value": "C104268"},
                    {"label": "Zhigansk", "value": "C104269"},
                    {"label": "Zhigansky District", "value": "C104270"},
                    {"label": "Zyryanka", "value": "C104271"}],
 "Sakhalin": [{"label": "Aleksandrovsk-Sakhalinskiy", "value": "C104272"},
              {"label": "Aniva", "value": "C104273"},
              {"label": "Boshnyakovo", "value": "C104274"},
              {"label": "Bykov", "value": "C104275"},
              {"label": "Chekhov", "value": "C104276"},
              {"label": "Dolinsk", "value": "C104277"},
              {"label": "Gastello", "value": "C104278"},
              {"label": "Gornozavodsk", "value": "C104279"},
              {"label": "Kholmsk", "value": "C104280"},
              {"label": "Korsakov", "value": "C104281"},
              {"label": "Krasnogorsk", "value": "C104282"},
              {"label": "Kurilsk", "value": "C104283"},
              {"label": "Kurilsky District", "value": "C104284"},
              {"label": "Leonidovo", "value": "C104285"},
              {"label": "Makarov", "value": "C104286"},
              {"label": "Mgachi", "value": "C104287"},
              {"label": "Nevelsk", "value": "C104288"},
              {"label": "Nogliki", "value": "C104289"},
              {"label": "Okha", "value": "C104290"},
              {"label": "Otrada", "value": "C104291"},
              {"label": "Poronaysk", "value": "C104292"},
              {"label": "Pravda", "value": "C104293"},
              {"label": "Severo-Kurilsk", "value": "C104294"},
              {"label": "Shakhtersk", "value": "C104295"},
              {"label": "Shebunino", "value": "C104296"},
              {"label": "Shikotan", "value": "C104297"},
              {"label": "Sinegorsk", "value": "C104298"},
              {"label": "Smirnykh", "value": "C104299"},
              {"label": "Sokol", "value": "C104300"},
              {"label": "Tomari", "value": "C104301"},
              {"label": "Troitskoye", "value": "C104302"},
              {"label": "Tungor", "value": "C104303"},
              {"label": "Tymovskoye", "value": "C104304"},
              {"label": "Uglegorsk", "value": "C104305"},
              {"label": "Uglegorskiy Rayon", "value": "C104306"},
              {"label": "Uglezavodsk", "value": "C104307"},
              {"label": "Vakhrushev", "value": "C104308"},
              {"label": "Vostok", "value": "C104309"},
              {"label": "Yablochnyy", "value": "C104310"},
              {"label": "Yuzhno-Kurilsk", "value": "C104311"},
              {"label": "Yuzhno-Kurilsky District", "value": "C104312"},
              {"label": "Yuzhno-Sakhalinsk", "value": "C104313"}],
 "Sakon Nakhon": [{"label": "Amphoe Akat Amnuai", "value": "C119270"},
                  {"label": "Amphoe Ban Muang", "value": "C119271"},
                  {"label": "Amphoe Charoen Sin", "value": "C119272"},
                  {"label": "Amphoe Kham Ta Kla", "value": "C119273"},
                  {"label": "Amphoe Khok Si Suphan", "value": "C119274"},
                  {"label": "Amphoe Kusuman", "value": "C119275"},
                  {"label": "Amphoe Kut Bak", "value": "C119276"},
                  {"label": "Amphoe Mueang Sakon Nakhon", "value": "C119277"},
                  {"label": "Amphoe Nikhom Nam Un", "value": "C119278"},
                  {"label": "Amphoe Phang Khon", "value": "C119279"},
                  {"label": "Amphoe Phanna Nikhom", "value": "C119280"},
                  {"label": "Amphoe Phon Na Kaeo", "value": "C119281"},
                  {"label": "Amphoe Phu Phan", "value": "C119282"},
                  {"label": "Amphoe Sawang Daen Din", "value": "C119283"},
                  {"label": "Amphoe Song Dao", "value": "C119284"},
                  {"label": "Amphoe Tao Ngoi", "value": "C119285"},
                  {"label": "Amphoe Wanon Niwat", "value": "C119286"},
                  {"label": "Amphoe Waritchaphum", "value": "C119287"},
                  {"label": "Sakon Nakhon", "value": "C119288"},
                  {"label": "Sawang Daen Din", "value": "C119289"}],
 "Sal": [{"label": "Espargos", "value": "C17795"},
         {"label": "Santa Maria", "value": "C17796"}],
 "Salacgriva Municipality": [{"label": "Ainazi", "value": "C64931"},
                             {"label": "Salacgriva", "value": "C64932"}],
 "Saladin Governorate": [{"label": "Ad Dujayl", "value": "C51200"},
                         {"label": "Balad", "value": "C51201"},
                         {"label": "Bayji", "value": "C51202"},
                         {"label": "Samarra", "value": "C51203"},
                         {"label": "Tikrit", "value": "C51204"},
                         {"label": "Tozkhurmato", "value": "C51205"}],
 "Salaj County": [{"label": "Aghires", "value": "C98297"},
                  {"label": "Agrij", "value": "C98298"},
                  {"label": "Almasu", "value": "C98299"},
                  {"label": "Babeni", "value": "C98300"},
                  {"label": "Balan", "value": "C98301"},
                  {"label": "Banisor", "value": "C98302"},
                  {"label": "Benesat", "value": "C98303"},
                  {"label": "Bobota", "value": "C98304"},
                  {"label": "Bocsa", "value": "C98305"},
                  {"label": "Boghis", "value": "C98306"},
                  {"label": "Buciumi", "value": "C98307"},
                  {"label": "Camar", "value": "C98308"},
                  {"label": "Carastelec", "value": "C98309"},
                  {"label": "Cehei", "value": "C98310"},
                  {"label": "Cehu Silvaniei", "value": "C98311"},
                  {"label": "Chendrea", "value": "C98312"},
                  {"label": "Chiesd", "value": "C98313"},
                  {"label": "Cizer", "value": "C98314"},
                  {"label": "Comuna Agrij", "value": "C98315"},
                  {"label": "Comuna Almasu", "value": "C98316"},
                  {"label": "Comuna Babeni", "value": "C98317"},
                  {"label": "Comuna Balan", "value": "C98318"},
                  {"label": "Comuna Banisor", "value": "C98319"},
                  {"label": "Comuna Benesat", "value": "C98320"},
                  {"label": "Comuna Bobota", "value": "C98321"},
                  {"label": "Comuna Bocsa", "value": "C98322"},
                  {"label": "Comuna Boghis", "value": "C98323"},
                  {"label": "Comuna Buciumi", "value": "C98324"},
                  {"label": "Comuna Camar", "value": "C98325"},
                  {"label": "Comuna Carastelec", "value": "C98326"},
                  {"label": "Comuna Cehu Silvaniei", "value": "C98327"},
                  {"label": "Comuna Chiesd", "value": "C98328"},
                  {"label": "Comuna Cizer", "value": "C98329"},
                  {"label": "Comuna Coseiu", "value": "C98330"},
                  {"label": "Comuna Crasna", "value": "C98331"},
                  {"label": "Comuna Creaca", "value": "C98332"},
                  {"label": "Comuna Criseni", "value": "C98333"},
                  {"label": "Comuna Cristolt", "value": "C98334"},
                  {"label": "Comuna Cuzaplac", "value": "C98335"},
                  {"label": "Comuna Dobrin", "value": "C98336"},
                  {"label": "Comuna Dragu", "value": "C98337"},
                  {"label": "Comuna Fildu De Jos", "value": "C98338"},
                  {"label": "Comuna Galgau", "value": "C98339"},
                  {"label": "Comuna Garbou", "value": "C98340"},
                  {"label": "Comuna Halmasd", "value": "C98341"},
                  {"label": "Comuna Hereclean", "value": "C98342"},
                  {"label": "Comuna Hida", "value": "C98343"},
                  {"label": "Comuna Horoatu Crasnei", "value": "C98344"},
                  {"label": "Comuna Ileanda", "value": "C98345"},
                  {"label": "Comuna Ip", "value": "C98346"},
                  {"label": "Comuna Letca", "value": "C98347"},
                  {"label": "Comuna Lozna", "value": "C98348"},
                  {"label": "Comuna Maeriste", "value": "C98349"},
                  {"label": "Comuna Marca", "value": "C98350"},
                  {"label": "Comuna Mesesenii de Jos", "value": "C98351"},
                  {"label": "Comuna Mirsid", "value": "C98352"},
                  {"label": "Comuna Napradea", "value": "C98353"},
                  {"label": "Comuna Nusfalau", "value": "C98354"},
                  {"label": "Comuna Pericei", "value": "C98355"},
                  {"label": "Comuna Plopis", "value": "C98356"},
                  {"label": "Comuna Poiana Blenchii", "value": "C98357"},
                  {"label": "Comuna Romanasi", "value": "C98358"},
                  {"label": "Comuna Rus", "value": "C98359"},
                  {"label": "Comuna Sag", "value": "C98360"},
                  {"label": "Comuna Salatig", "value": "C98361"},
                  {"label": "Comuna Samsud", "value": "C98362"},
                  {"label": "Comuna Sanmihaiu Almasului", "value": "C98363"},
                  {"label": "Comuna Sarmasag", "value": "C98364"},
                  {"label": "Comuna Simisna", "value": "C98365"},
                  {"label": "Comuna Somes-Odorhei", "value": "C98366"},
                  {"label": "Comuna Surduc", "value": "C98367"},
                  {"label": "Comuna Treznea", "value": "C98368"},
                  {"label": "Comuna Valcau De Jos", "value": "C98369"},
                  {"label": "Comuna Varsolt", "value": "C98370"},
                  {"label": "Comuna Zalha", "value": "C98371"},
                  {"label": "Comuna Zimbor", "value": "C98372"},
                  {"label": "Coseiu", "value": "C98373"},
                  {"label": "Crasna", "value": "C98374"},
                  {"label": "Creaca", "value": "C98375"},
                  {"label": "Criseni", "value": "C98376"},
                  {"label": "Cristolt", "value": "C98377"},
                  {"label": "Cuzaplac", "value": "C98378"},
                  {"label": "Deja", "value": "C98379"},
                  {"label": "Dersida", "value": "C98380"},
                  {"label": "Diosod", "value": "C98381"},
                  {"label": "Dobrin", "value": "C98382"},
                  {"label": "Dragu", "value": "C98383"},
                  {"label": "Fildu de Jos", "value": "C98384"},
                  {"label": "Galgau", "value": "C98385"},
                  {"label": "Galgau Almasului", "value": "C98386"},
                  {"label": "Garbou", "value": "C98387"},
                  {"label": "Giurtelecu Simleului", "value": "C98388"},
                  {"label": "Halmasd", "value": "C98389"},
                  {"label": "Hereclean", "value": "C98390"},
                  {"label": "Hida", "value": "C98391"},
                  {"label": "Horoatu Crasnei", "value": "C98392"},
                  {"label": "Ileanda", "value": "C98393"},
                  {"label": "Ip", "value": "C98394"},
                  {"label": "Jibou", "value": "C98395"},
                  {"label": "Letca", "value": "C98396"},
                  {"label": "Lozna", "value": "C98397"},
                  {"label": "Maeriste", "value": "C98398"},
                  {"label": "Mal", "value": "C98399"},
                  {"label": "Marca", "value": "C98400"},
                  {"label": "Marin", "value": "C98401"},
                  {"label": "Mesesenii de Jos", "value": "C98402"},
                  {"label": "Mirsid", "value": "C98403"},
                  {"label": "Municipiul Zalau", "value": "C98404"},
                  {"label": "Napradea", "value": "C98405"},
                  {"label": "Nusfalau", "value": "C98406"},
                  {"label": "Oras Jibou", "value": "C98407"},
                  {"label": "Oras Simleu Silvaniei", "value": "C98408"},
                  {"label": "Peceiu", "value": "C98409"},
                  {"label": "Pericei", "value": "C98410"},
                  {"label": "Plopis", "value": "C98411"},
                  {"label": "Poiana Blenchii", "value": "C98412"},
                  {"label": "Pusta", "value": "C98413"},
                  {"label": "Romanasi", "value": "C98414"},
                  {"label": "Rus", "value": "C98415"},
                  {"label": "Sag", "value": "C98416"},
                  {"label": "Salatig", "value": "C98417"},
                  {"label": "Samsud", "value": "C98418"},
                  {"label": "Sanmihaiu Almasului", "value": "C98419"},
                  {"label": "Sarmasag", "value": "C98420"},
                  {"label": "Simisna", "value": "C98421"},
                  {"label": "Simleu Silvaniei", "value": "C98422"},
                  {"label": "Somes-Odorhei", "value": "C98423"},
                  {"label": "Starciu", "value": "C98424"},
                  {"label": "Surduc", "value": "C98425"},
                  {"label": "Tihau", "value": "C98426"},
                  {"label": "Treznea", "value": "C98427"},
                  {"label": "Valcau de Jos", "value": "C98428"},
                  {"label": "Varsolt", "value": "C98429"},
                  {"label": "Zalau", "value": "C98430"},
                  {"label": "Zalha", "value": "C98431"},
                  {"label": "Zauan", "value": "C98432"},
                  {"label": "Zimbor", "value": "C98433"}],
 "Salamat Region": [{"label": "Am Timan", "value": "C17884"}],
 "Salaspils Municipality": [{"label": "Salaspils", "value": "C64933"}],
 "Salavan Province": [{"label": "Muang Khongxedon", "value": "C64801"},
                      {"label": "Muang Lakhonpheng", "value": "C64802"},
                      {"label": "Muang Laongam", "value": "C64803"},
                      {"label": "Muang Samouay", "value": "C64804"},
                      {"label": "Muang Saravan", "value": "C64805"},
                      {"label": "Muang Ta-Oy", "value": "C64806"},
                      {"label": "Muang Toumlan", "value": "C64807"},
                      {"label": "Muang Vapi", "value": "C64808"},
                      {"label": "Salavan", "value": "C64809"}],
 "Saldus Municipality": [{"label": "Saldus", "value": "C64934"}],
 "Salovci Municipality": [{"label": "Salovci", "value": "C107792"}],
 "Salta": [{"label": "Apolinario Saravia", "value": "C1318"},
           {"label": "Cachi", "value": "C1319"},
           {"label": "Cafayate", "value": "C1320"},
           {"label": "Campo Quijano", "value": "C1321"},
           {"label": "Chicoana", "value": "C1322"},
           {"label": "Departamento Capital", "value": "C1323"},
           {"label": "Departamento de Anta", "value": "C1324"},
           {"label": "Departamento de Cerrillos", "value": "C1325"},
           {"label": "Departamento de Chicoana", "value": "C1326"},
           {"label": "Departamento de General Guemes", "value": "C1327"},
           {"label": "Departamento de Guachipas", "value": "C1328"},
           {"label": "Departamento de Iruya", "value": "C1329"},
           {"label": "Departamento de La Poma", "value": "C1330"},
           {"label": "Departamento de La Vina", "value": "C1331"},
           {"label": "Departamento de Los Andes", "value": "C1332"},
           {"label": "Departamento de Metan", "value": "C1333"},
           {"label": "Departamento de Rivadavia", "value": "C1334"},
           {"label": "Departamento de Rosario de la Frontera",
            "value": "C1335"},
           {"label": "Departamento de Rosario de Lerma", "value": "C1336"},
           {"label": "Departamento de San Carlos", "value": "C1337"},
           {"label": "El Carril", "value": "C1338"},
           {"label": "El Galpon", "value": "C1339"},
           {"label": "El Quebrachal", "value": "C1340"},
           {"label": "Embarcacion", "value": "C1341"},
           {"label": "General Enrique Mosconi", "value": "C1342"},
           {"label": "Joaquin V. Gonzalez", "value": "C1343"},
           {"label": "La Caldera", "value": "C1344"},
           {"label": "Las Lajitas", "value": "C1345"},
           {"label": "Salta", "value": "C1346"},
           {"label": "San Antonio de los Cobres", "value": "C1347"},
           {"label": "San Ramon de la Nueva Oran", "value": "C1348"},
           {"label": "Santa Rosa de Tastil", "value": "C1349"},
           {"label": "Tartagal", "value": "C1350"}],
 "Salto Department": [{"label": "Belen", "value": "C146957"},
                      {"label": "Salto", "value": "C146958"},
                      {"label": "Villa Constitucion", "value": "C146959"}],
 "Salyan District": [{"label": "Qaracala", "value": "C8504"},
                     {"label": "Salyan", "value": "C8505"}],
 "Salzburg": [{"label": "Abtenau", "value": "C6973"},
              {"label": "Adnet", "value": "C6974"},
              {"label": "Altenmarkt im Pongau", "value": "C6975"},
              {"label": "Anger", "value": "C6976"},
              {"label": "Anif", "value": "C6977"},
              {"label": "Anthering", "value": "C6978"},
              {"label": "Au", "value": "C6979"},
              {"label": "Bad Gastein", "value": "C6980"},
              {"label": "Bad Hofgastein", "value": "C6981"},
              {"label": "Bergheim", "value": "C6982"},
              {"label": "Berndorf bei Salzburg", "value": "C6983"},
              {"label": "Bischofshofen", "value": "C6984"},
              {"label": "Bramberg am Wildkogel", "value": "C6985"},
              {"label": "Bruck an der Grossglocknerstrasse", "value": "C6986"},
              {"label": "Burgfried", "value": "C6987"},
              {"label": "Burmoos", "value": "C6988"},
              {"label": "Dienten am Hochkonig", "value": "C6989"},
              {"label": "Dorfbeuern", "value": "C6990"},
              {"label": "Dorfgastein", "value": "C6991"},
              {"label": "Eben im Pongau", "value": "C6992"},
              {"label": "Ebenau", "value": "C6993"},
              {"label": "Eching", "value": "C6994"},
              {"label": "Elixhausen", "value": "C6995"},
              {"label": "Elsbethen", "value": "C6996"},
              {"label": "Esch", "value": "C6997"},
              {"label": "Eugendorf", "value": "C6998"},
              {"label": "Faistenau", "value": "C6999"},
              {"label": "Filzmoos", "value": "C7000"},
              {"label": "Flachau", "value": "C7001"},
              {"label": "Forstau", "value": "C7002"},
              {"label": "Fuschl am See", "value": "C7003"},
              {"label": "Georgenberg", "value": "C7004"},
              {"label": "Glanegg", "value": "C7005"},
              {"label": "Glasenbach", "value": "C7006"},
              {"label": "Golling an der Salzach", "value": "C7007"},
              {"label": "Grodig", "value": "C7008"},
              {"label": "Grossarl", "value": "C7009"},
              {"label": "Grossgmain", "value": "C7010"},
              {"label": "Habach", "value": "C7011"},
              {"label": "Hallein", "value": "C7012"},
              {"label": "Hallwang", "value": "C7013"},
              {"label": "Henndorf am Wallersee", "value": "C7014"},
              {"label": "Himmelreich", "value": "C7015"},
              {"label": "Hinterglemm", "value": "C7016"},
              {"label": "Hintersee", "value": "C7017"},
              {"label": "Hof", "value": "C7018"},
              {"label": "Hof bei Salzburg", "value": "C7019"},
              {"label": "Hollersbach im Pinzgau", "value": "C7020"},
              {"label": "Huttau", "value": "C7021"},
              {"label": "Huttschlag", "value": "C7022"},
              {"label": "Irrsdorf", "value": "C7023"},
              {"label": "Kaprun", "value": "C7024"},
              {"label": "Kleinarl", "value": "C7025"},
              {"label": "Koppl", "value": "C7026"},
              {"label": "Kostendorf", "value": "C7027"},
              {"label": "Krimml", "value": "C7028"},
              {"label": "Krispl", "value": "C7029"},
              {"label": "Kuchl", "value": "C7030"},
              {"label": "Lamprechtshausen", "value": "C7031"},
              {"label": "Lehen", "value": "C7032"},
              {"label": "Lend", "value": "C7033"},
              {"label": "Lengfelden", "value": "C7034"},
              {"label": "Leogang", "value": "C7035"},
              {"label": "Lofer", "value": "C7036"},
              {"label": "Maishofen", "value": "C7037"},
              {"label": "Maria Alm am Steinernen Meer", "value": "C7038"},
              {"label": "Mariapfarr", "value": "C7039"},
              {"label": "Mattsee", "value": "C7040"},
              {"label": "Mauterndorf", "value": "C7041"},
              {"label": "Mitterberghutten", "value": "C7042"},
              {"label": "Mitterhofen", "value": "C7043"},
              {"label": "Mittersill", "value": "C7044"},
              {"label": "Muhlbach am Hochkonig", "value": "C7045"},
              {"label": "Neualm", "value": "C7046"},
              {"label": "Neukirchen am Grossvenediger", "value": "C7047"},
              {"label": "Neumarkt am Wallersee", "value": "C7048"},
              {"label": "Niederalm", "value": "C7049"},
              {"label": "Niedernsill", "value": "C7050"},
              {"label": "Nussdorf am Haunsberg", "value": "C7051"},
              {"label": "Oberalm", "value": "C7052"},
              {"label": "Obergau", "value": "C7053"},
              {"label": "Oberndorf bei Salzburg", "value": "C7054"},
              {"label": "Obertrum am See", "value": "C7055"},
              {"label": "Pfarrwerfen", "value": "C7056"},
              {"label": "Pichl", "value": "C7057"},
              {"label": "Piesendorf", "value": "C7058"},
              {"label": "Plainfeld", "value": "C7059"},
              {"label": "Plankenau", "value": "C7060"},
              {"label": "Politischer Bezirk Hallein", "value": "C7061"},
              {"label": "Politischer Bezirk Salzburg-Umgebung",
               "value": "C7062"},
              {"label": "Politischer Bezirk Sankt Johann im Pongau",
               "value": "C7063"},
              {"label": "Politischer Bezirk Tamsweg", "value": "C7064"},
              {"label": "Puch bei Hallein", "value": "C7065"},
              {"label": "Radstadt", "value": "C7066"},
              {"label": "Ramingstein", "value": "C7067"},
              {"label": "Rauris", "value": "C7068"},
              {"label": "Reinbach", "value": "C7069"},
              {"label": "Reitberg", "value": "C7070"},
              {"label": "Saalbach", "value": "C7071"},
              {"label": "Saalfelden am Steinernen Meer", "value": "C7072"},
              {"label": "Salzburg", "value": "C7073"},
              {"label": "Salzburg Stadt", "value": "C7074"},
              {"label": "Sankt Andra im Lungau", "value": "C7075"},
              {"label": "Sankt Gilgen", "value": "C7076"},
              {"label": "Sankt Johann im Pongau", "value": "C7077"},
              {"label": "Sankt Leonhard", "value": "C7078"},
              {"label": "Sankt Margarethen im Lungau", "value": "C7079"},
              {"label": "Sankt Martin bei Lofer", "value": "C7080"},
              {"label": "Sankt Michael im Lungau", "value": "C7081"},
              {"label": "Sankt Veit im Pongau", "value": "C7082"},
              {"label": "Scheffau am Tennengebirge", "value": "C7083"},
              {"label": "Schleedorf", "value": "C7084"},
              {"label": "Schwarzach im Pongau", "value": "C7085"},
              {"label": "Seeham", "value": "C7086"},
              {"label": "Seekirchen am Wallersee", "value": "C7087"},
              {"label": "Seewalchen", "value": "C7088"},
              {"label": "Siezenheim", "value": "C7089"},
              {"label": "St. Martin of Tennengebirge", "value": "C7090"},
              {"label": "Strasswalchen", "value": "C7091"},
              {"label": "Strobl", "value": "C7092"},
              {"label": "Stuhlfelden", "value": "C7093"},
              {"label": "Tamsweg", "value": "C7094"},
              {"label": "Taugl", "value": "C7095"},
              {"label": "Taxach", "value": "C7096"},
              {"label": "Taxenbach", "value": "C7097"},
              {"label": "Thalgau", "value": "C7098"},
              {"label": "Thomatal", "value": "C7099"},
              {"label": "Thumersbach", "value": "C7100"},
              {"label": "Tweng", "value": "C7101"},
              {"label": "Unken", "value": "C7102"},
              {"label": "Unternberg", "value": "C7103"},
              {"label": "Untertauern", "value": "C7104"},
              {"label": "Uttendorf", "value": "C7105"},
              {"label": "Viehhausen", "value": "C7106"},
              {"label": "Viehhofen", "value": "C7107"},
              {"label": "Vigaun", "value": "C7108"},
              {"label": "Wagnergraben", "value": "C7109"},
              {"label": "Wagrain", "value": "C7110"},
              {"label": "Walchen", "value": "C7111"},
              {"label": "Wald im Pinzgau", "value": "C7112"},
              {"label": "Waldprechting", "value": "C7113"},
              {"label": "Wals", "value": "C7114"},
              {"label": "Walserfeld", "value": "C7115"},
              {"label": "Werfen", "value": "C7116"},
              {"label": "Werfenweng", "value": "C7117"},
              {"label": "Zederhaus", "value": "C7118"},
              {"label": "Zell am See", "value": "C7119"}],
 "Samana Province": [{"label": "Las Terrenas", "value": "C23983"},
                     {"label": "Samana", "value": "C23984"},
                     {"label": "Sanchez", "value": "C23985"}],
 "Samangan": [{"label": "Aibak", "value": "C80"}],
 "Samara Oblast": [{"label": "Bakhilovo", "value": "C104314"},
                   {"label": "Balasheyka", "value": "C104315"},
                   {"label": "Bereza", "value": "C104316"},
                   {"label": "Bezenchuk", "value": "C104317"},
                   {"label": "Bogatoye", "value": "C104318"},
                   {"label": "Bogatyr", "value": "C104319"},
                   {"label": "Borskoye", "value": "C104320"},
                   {"label": "Chapayevsk", "value": "C104321"},
                   {"label": "Chelno-Vershiny", "value": "C104322"},
                   {"label": "Dubovyy Umet", "value": "C104323"},
                   {"label": "Georgiyevka", "value": "C104324"},
                   {"label": "Isakly", "value": "C104325"},
                   {"label": "Kamyshla", "value": "C104326"},
                   {"label": "Khryashchevka", "value": "C104327"},
                   {"label": "Khvorostyanka", "value": "C104328"},
                   {"label": "Kinel", "value": "C104329"},
                   {"label": "Kinel-Cherkassy", "value": "C104330"},
                   {"label": "Kinelskiy Rayon", "value": "C104331"},
                   {"label": "Klyavlino", "value": "C104332"},
                   {"label": "Koshki", "value": "C104333"},
                   {"label": "Krasnoarmeyskoye", "value": "C104334"},
                   {"label": "Krasnoyarskiy Rayon", "value": "C104335"},
                   {"label": "Krasnyy Yar", "value": "C104336"},
                   {"label": "Krotovka", "value": "C104337"},
                   {"label": "Kurumoch", "value": "C104338"},
                   {"label": "Mayskoye", "value": "C104339"},
                   {"label": "Mezhdurechensk", "value": "C104340"},
                   {"label": "Mirnyy", "value": "C104341"},
                   {"label": "Neftegorsk", "value": "C104342"},
                   {"label": "Neftegorskiy Rayon", "value": "C104343"},
                   {"label": "Novokuybyshevsk", "value": "C104344"},
                   {"label": "Novosemeykino", "value": "C104345"},
                   {"label": "Novyy Buyan", "value": "C104346"},
                   {"label": "Obsharovka", "value": "C104347"},
                   {"label": "Oktyabrsk", "value": "C104348"},
                   {"label": "Osinki", "value": "C104349"},
                   {"label": "Otradnyy", "value": "C104350"},
                   {"label": "Perevoloki", "value": "C104351"},
                   {"label": "Pestravka", "value": "C104352"},
                   {"label": "Petra-Dubrava", "value": "C104353"},
                   {"label": "Podsolnechnoye", "value": "C104354"},
                   {"label": "Podstepki", "value": "C104355"},
                   {"label": "Pokhvistnevo", "value": "C104356"},
                   {"label": "Povolzhskiy", "value": "C104357"},
                   {"label": "Pribrezhnyy", "value": "C104358"},
                   {"label": "Roschinskiy", "value": "C104359"},
                   {"label": "Rozhdestveno", "value": "C104360"},
                   {"label": "Samara", "value": "C104361"},
                   {"label": "Sernovodsk", "value": "C104362"},
                   {"label": "Shentala", "value": "C104363"},
                   {"label": "Shigony", "value": "C104364"},
                   {"label": "Smyshlyayevka", "value": "C104365"},
                   {"label": "Spiridonovka", "value": "C104366"},
                   {"label": "Sukhodol", "value": "C104367"},
                   {"label": "Surgut", "value": "C104368"},
                   {"label": "Syzran", "value": "C104369"},
                   {"label": "Tolyatti", "value": "C104370"},
                   {"label": "Utevka", "value": "C104371"},
                   {"label": "Varlamovo", "value": "C104372"},
                   {"label": "Volzhskiy", "value": "C104373"},
                   {"label": "Yelkhovka", "value": "C104374"},
                   {"label": "Zhigulevsk", "value": "C104375"},
                   {"label": "Zhiguli", "value": "C104376"},
                   {"label": "Zolnoye", "value": "C104377"}],
 "Samarqand Region": [{"label": "Bulungur", "value": "C147069"},
                      {"label": "Charxin", "value": "C147070"},
                      {"label": "Chelak", "value": "C147071"},
                      {"label": "Daxbet", "value": "C147072"},
                      {"label": "Ishtixon", "value": "C147073"},
                      {"label": "Jomboy", "value": "C147074"},
                      {"label": "Juma", "value": "C147075"},
                      {"label": "Kattaqorgon", "value": "C147076"},
                      {"label": "Oqtosh", "value": "C147077"},
                      {"label": "Payshanba", "value": "C147078"},
                      {"label": "Samarkand", "value": "C147079"},
                      {"label": "Urgut", "value": "C147080"}],
 "Samburu": [{"label": "Maralal", "value": "C64631"}],
 "Samdrup Jongkhar District": [{"label": "Samdrup Jongkhar", "value": "C9644"}],
 "Samegrelo-Zemo Svaneti": [{"label": "Abasha", "value": "C34332"},
                            {"label": "Jvari", "value": "C34333"},
                            {"label": "Khobi", "value": "C34334"},
                            {"label": "Kveda Chkhorotsqu", "value": "C34335"},
                            {"label": "Martvili", "value": "C34336"},
                            {"label": "Mestia", "value": "C34337"},
                            {"label": "Mestiis Munitsipaliteti",
                             "value": "C34338"},
                            {"label": "Orsantia", "value": "C34339"},
                            {"label": "Poti", "value": "C34340"},
                            {"label": "Senaki", "value": "C34341"},
                            {"label": "Tsalenjikha", "value": "C34342"},
                            {"label": "Zugdidi", "value": "C34343"}],
 "Samsun": [{"label": "Alacam Ilcesi", "value": "C120904"},
            {"label": "Asarcik", "value": "C120905"},
            {"label": "Atakum", "value": "C120906"},
            {"label": "Ayvacik", "value": "C120907"},
            {"label": "Bafra", "value": "C120908"},
            {"label": "Canik", "value": "C120909"},
            {"label": "Carsamba", "value": "C120910"},
            {"label": "Havza", "value": "C120911"},
            {"label": "Ilkadim", "value": "C120912"},
            {"label": "Kavak", "value": "C120913"},
            {"label": "Ladik", "value": "C120914"},
            {"label": "Ondokuzmayis Ilcesi", "value": "C120915"},
            {"label": "Salipazari Ilcesi", "value": "C120916"},
            {"label": "Samsun", "value": "C120917"},
            {"label": "Tekkekoy", "value": "C120918"},
            {"label": "Terme", "value": "C120919"},
            {"label": "Vezirkopru", "value": "C120920"},
            {"label": "Yakakent", "value": "C120921"}],
 "Samtse District": [{"label": "Samtse", "value": "C9645"}],
 "Samtskhe-Javakheti": [{"label": "Adigeni", "value": "C34344"},
                        {"label": "Adigeni Municipality", "value": "C34345"},
                        {"label": "Akhaldaba", "value": "C34346"},
                        {"label": "Akhalkalaki", "value": "C34347"},
                        {"label": "Akhaltsikhe", "value": "C34348"},
                        {"label": "Akhaltsikhis Munitsipaliteti",
                         "value": "C34349"},
                        {"label": "Aspindzis Munitsipaliteti",
                         "value": "C34350"},
                        {"label": "Aspindza", "value": "C34351"},
                        {"label": "Bakuriani", "value": "C34352"},
                        {"label": "Borjomi", "value": "C34353"},
                        {"label": "Ninotsminda", "value": "C34354"},
                        {"label": "Tsaghveri", "value": "C34355"},
                        {"label": "Vale", "value": "C34356"}],
 "Samukh District": [{"label": "Qarayeri", "value": "C8506"},
                     {"label": "Qirmizi Samux", "value": "C8507"},
                     {"label": "Samux", "value": "C8508"}],
 "Samut Prakan": [{"label": "Amphoe Bang Bo", "value": "C119290"},
                  {"label": "Amphoe Bang Phli", "value": "C119291"},
                  {"label": "Amphoe Bang Sao Thong", "value": "C119292"},
                  {"label": "Amphoe Mueang Samut Prakan", "value": "C119293"},
                  {"label": "Amphoe Phra Pradaeng", "value": "C119294"},
                  {"label": "Amphoe Phra Samut Chedi", "value": "C119295"},
                  {"label": "Ban Khlong Bang Sao Thong", "value": "C119296"},
                  {"label": "Bang Bo District", "value": "C119297"},
                  {"label": "Phra Pradaeng", "value": "C119298"},
                  {"label": "Samut Prakan", "value": "C119299"}],
 "Samut Sakhon": [{"label": "Amphoe Ban Phaeo", "value": "C119300"},
                  {"label": "Amphoe Krathum Baen", "value": "C119301"},
                  {"label": "Amphoe Mueang Samut Sakhon", "value": "C119302"},
                  {"label": "Ban Phaeo", "value": "C119303"},
                  {"label": "Krathum Baen", "value": "C119304"},
                  {"label": "Samut Sakhon", "value": "C119305"}],
 "Samut Songkhram": [{"label": "Amphoe Amphawa", "value": "C119306"},
                     {"label": "Amphoe Bang Khonthi", "value": "C119307"},
                     {"label": "Amphoe Mueang Samut Songkhram",
                      "value": "C119308"},
                     {"label": "Samut Songkhram", "value": "C119309"}],
 "San Cristobal Province": [{"label": "Bajos de Haina", "value": "C23986"},
                            {"label": "Cambita Garabitos", "value": "C23987"},
                            {"label": "El Cacao", "value": "C23988"},
                            {"label": "El Carril", "value": "C23989"},
                            {"label": "Sabana Grande de Palenque",
                             "value": "C23990"},
                            {"label": "San Cristobal", "value": "C23991"},
                            {"label": "San Gregorio de Nigua",
                             "value": "C23992"},
                            {"label": "Villa Altagracia", "value": "C23993"},
                            {"label": "Yaguate", "value": "C23994"}],
 "San Fernando": [{"label": "Marabella", "value": "C119669"},
                  {"label": "Mon Repos", "value": "C119670"},
                  {"label": "San Fernando", "value": "C119671"}],
 "San Gwann": [{"label": "San Giljan", "value": "C65912"}],
 "San Jose Department": [{"label": "Delta del Tigre", "value": "C146960"},
                         {"label": "Ecilda Paullier", "value": "C146961"},
                         {"label": "Libertad", "value": "C146962"},
                         {"label": "Puntas de Valdez", "value": "C146963"},
                         {"label": "Rafael Perazza", "value": "C146964"},
                         {"label": "Rodriguez", "value": "C146965"},
                         {"label": "San Jose de Mayo", "value": "C146966"}],
 "San Jose Province": [{"label": "Acosta", "value": "C20873"},
                       {"label": "Alajuelita", "value": "C20874"},
                       {"label": "Aserri", "value": "C20875"},
                       {"label": "Calle Blancos", "value": "C20876"},
                       {"label": "Colima", "value": "C20877"},
                       {"label": "Colon", "value": "C20878"},
                       {"label": "Curridabat", "value": "C20879"},
                       {"label": "Daniel Flores", "value": "C20880"},
                       {"label": "Desamparados", "value": "C20881"},
                       {"label": "Dota", "value": "C20882"},
                       {"label": "Escazu", "value": "C20883"},
                       {"label": "Goicoechea", "value": "C20884"},
                       {"label": "Granadilla", "value": "C20885"},
                       {"label": "Guadalupe", "value": "C20886"},
                       {"label": "Ipis", "value": "C20887"},
                       {"label": "Leon Cortes", "value": "C20888"},
                       {"label": "Mercedes", "value": "C20889"},
                       {"label": "Montes de Oca", "value": "C20890"},
                       {"label": "Mora", "value": "C20891"},
                       {"label": "Moravia", "value": "C20892"},
                       {"label": "Palmichal", "value": "C20893"},
                       {"label": "Patarra", "value": "C20894"},
                       {"label": "Perez Zeledon", "value": "C20895"},
                       {"label": "Puriscal", "value": "C20896"},
                       {"label": "Purral", "value": "C20897"},
                       {"label": "Sabanilla", "value": "C20898"},
                       {"label": "Salitral", "value": "C20899"},
                       {"label": "Salitrillos", "value": "C20900"},
                       {"label": "San Felipe", "value": "C20901"},
                       {"label": "San Ignacio", "value": "C20902"},
                       {"label": "San Isidro", "value": "C20903"},
                       {"label": "San Jose", "value": "C20904"},
                       {"label": "San Juan", "value": "C20905"},
                       {"label": "San Juan de Dios", "value": "C20906"},
                       {"label": "San Marcos", "value": "C20907"},
                       {"label": "San Miguel", "value": "C20908"},
                       {"label": "San Pedro", "value": "C20909"},
                       {"label": "San Rafael", "value": "C20910"},
                       {"label": "San Rafael Abajo", "value": "C20911"},
                       {"label": "San Rafael Arriba", "value": "C20912"},
                       {"label": "San Vicente", "value": "C20913"},
                       {"label": "San Vicente de Moravia", "value": "C20914"},
                       {"label": "Santa Ana", "value": "C20915"},
                       {"label": "Santiago", "value": "C20916"},
                       {"label": "Tarrazu", "value": "C20917"},
                       {"label": "Tejar", "value": "C20918"},
                       {"label": "Tibas", "value": "C20919"},
                       {"label": "Turrubares", "value": "C20920"},
                       {"label": "Vazquez de Coronado", "value": "C20921"}],
 "San Jose de Ocoa Province": [{"label": "San Jose de Ocoa",
                                "value": "C23995"}],
 "San Juan": [{"label": "Albardon", "value": "C1351"},
              {"label": "Calingasta", "value": "C1352"},
              {"label": "Caucete", "value": "C1353"},
              {"label": "Chimbas", "value": "C1354"},
              {"label": "Departamento de Albardon", "value": "C1355"},
              {"label": "Departamento de Angaco", "value": "C1356"},
              {"label": "Departamento de Calingasta", "value": "C1357"},
              {"label": "Departamento de Capital", "value": "C1358"},
              {"label": "Departamento de Caucete", "value": "C1359"},
              {"label": "Departamento de Chimbas", "value": "C1360"},
              {"label": "Departamento de Iglesia", "value": "C1361"},
              {"label": "Departamento de Jachal", "value": "C1362"},
              {"label": "Departamento de Nueve de Julio", "value": "C1363"},
              {"label": "Departamento de Rawson", "value": "C1364"},
              {"label": "Departamento de Rivadavia", "value": "C1365"},
              {"label": "Departamento de San Martin", "value": "C1366"},
              {"label": "Departamento de Santa Lucia", "value": "C1367"},
              {"label": "Departamento de Sarmiento", "value": "C1368"},
              {"label": "Departamento de Ullum", "value": "C1369"},
              {"label": "Departamento de Zonda", "value": "C1370"},
              {"label": "Nueve de Julio", "value": "C1371"},
              {"label": "Pocito", "value": "C1372"},
              {"label": "San Agustin de Valle Fertil", "value": "C1373"},
              {"label": "San Jose de Jachal", "value": "C1374"},
              {"label": "San Juan", "value": "C1375"},
              {"label": "San Martin", "value": "C1376"},
              {"label": "Santa Lucia", "value": "C1377"},
              {"label": "Villa Basilio Nievas", "value": "C1378"},
              {"label": "Villa Paula de Sarmiento", "value": "C1379"}],
 "San Juan Province": [{"label": "Bohechio", "value": "C23996"},
                       {"label": "Cercado Abajo", "value": "C23997"},
                       {"label": "El Cercado", "value": "C23998"},
                       {"label": "Juan de Herrera", "value": "C23999"},
                       {"label": "Las Matas de Farfan", "value": "C24000"},
                       {"label": "Matayaya", "value": "C24001"},
                       {"label": "Pedro Corto", "value": "C24002"},
                       {"label": "San Juan de la Maguana", "value": "C24003"},
                       {"label": "Vallejuelo", "value": "C24004"}],
 "San Juan-Laventille Regional Corporation": [{"label": "Laventille",
                                               "value": "C119672"}],
 "San Luis": [{"label": "Buena Esperanza", "value": "C1380"},
              {"label": "Candelaria", "value": "C1381"},
              {"label": "Concaran", "value": "C1382"},
              {"label": "Juan Martin de Pueyrredon", "value": "C1383"},
              {"label": "Justo Daract", "value": "C1384"},
              {"label": "La Punta", "value": "C1385"},
              {"label": "La Toma", "value": "C1386"},
              {"label": "Lujan", "value": "C1387"},
              {"label": "Merlo", "value": "C1388"},
              {"label": "Naschel", "value": "C1389"},
              {"label": "San Francisco del Monte de Oro", "value": "C1390"},
              {"label": "San Luis", "value": "C1391"},
              {"label": "Santa Rosa del Conlara", "value": "C1392"},
              {"label": "Tilisarao", "value": "C1393"},
              {"label": "Union", "value": "C1394"},
              {"label": "Villa General Roca", "value": "C1395"},
              {"label": "Villa Mercedes", "value": "C1396"}],
 "San Luis Potosi": [{"label": "Agua Buena", "value": "C73228"},
                     {"label": "Agua Senora", "value": "C73229"},
                     {"label": "Ahualulco del Sonido Trece", "value": "C73230"},
                     {"label": "Ahuehueyo Primero Centro", "value": "C73231"},
                     {"label": "Alaquines", "value": "C73232"},
                     {"label": "Alberto Carrera Torres", "value": "C73233"},
                     {"label": "Ampliacion la Hincada", "value": "C73234"},
                     {"label": "Aquismon", "value": "C73235"},
                     {"label": "Arroyos", "value": "C73236"},
                     {"label": "Aurelio Manrique", "value": "C73237"},
                     {"label": "Axtla de Terrazas", "value": "C73238"},
                     {"label": "Bledos", "value": "C73239"},
                     {"label": "Bocas", "value": "C73240"},
                     {"label": "Cardenas", "value": "C73241"},
                     {"label": "Carranco", "value": "C73242"},
                     {"label": "Cerrito de Jaral", "value": "C73243"},
                     {"label": "Cerrito de Zavala", "value": "C73244"},
                     {"label": "Cerritos", "value": "C73245"},
                     {"label": "Cerro de San Pedro", "value": "C73246"},
                     {"label": "Cerro Gordo", "value": "C73247"},
                     {"label": "Chalchocoyo", "value": "C73248"},
                     {"label": "Charcas", "value": "C73249"},
                     {"label": "Chiconamel", "value": "C73250"},
                     {"label": "Ciudad del Maiz", "value": "C73251"},
                     {"label": "Ciudad Fernandez", "value": "C73252"},
                     {"label": "Ciudad Satelite", "value": "C73253"},
                     {"label": "Ciudad Valles", "value": "C73254"},
                     {"label": "Colonia alvaro Obregon", "value": "C73255"},
                     {"label": "Colonia Veinte de Noviembre",
                      "value": "C73256"},
                     {"label": "Corcovado", "value": "C73257"},
                     {"label": "Damian Carmona", "value": "C73258"},
                     {"label": "Derramaderos", "value": "C73259"},
                     {"label": "Dulce Grande", "value": "C73260"},
                     {"label": "ebano", "value": "C73261"},
                     {"label": "Ejido el Saucillo", "value": "C73262"},
                     {"label": "Ejido Gogorron (Ex-Hacienda de Gogorron)",
                      "value": "C73263"},
                     {"label": "Ejido la Pitahaya (Santo Domingo)",
                      "value": "C73264"},
                     {"label": "Ejido los Huastecos", "value": "C73265"},
                     {"label": "Ejido San Jose Xilatzen", "value": "C73266"},
                     {"label": "El Barril", "value": "C73267"},
                     {"label": "El Capulin", "value": "C73268"},
                     {"label": "El Carrizal", "value": "C73269"},
                     {"label": "El Fuerte", "value": "C73270"},
                     {"label": "El Huexco", "value": "C73271"},
                     {"label": "El Jabali", "value": "C73272"},
                     {"label": "El Jaralito", "value": "C73273"},
                     {"label": "El Naranjo", "value": "C73274"},
                     {"label": "El Penasco", "value": "C73275"},
                     {"label": "El Rosario", "value": "C73276"},
                     {"label": "El Tepetate", "value": "C73277"},
                     {"label": "Emiliano Zapata", "value": "C73278"},
                     {"label": "Enramadas", "value": "C73279"},
                     {"label": "Enrique Estrada", "value": "C73280"},
                     {"label": "Entronque de Matehuala (El Huizache)",
                      "value": "C73281"},
                     {"label": "Escalerillas", "value": "C73282"},
                     {"label": "Estacion Tamuin", "value": "C73283"},
                     {"label": "Estanzuela", "value": "C73284"},
                     {"label": "Fraccion Milpillas", "value": "C73285"},
                     {"label": "Granjenal", "value": "C73286"},
                     {"label": "Guadalcazar", "value": "C73287"},
                     {"label": "Guadalupe Victoria", "value": "C73288"},
                     {"label": "Hernandez", "value": "C73289"},
                     {"label": "Higinio Olivo", "value": "C73290"},
                     {"label": "Huichihuayan", "value": "C73291"},
                     {"label": "Illescas", "value": "C73292"},
                     {"label": "Iztacapa", "value": "C73293"},
                     {"label": "Jalpilla", "value": "C73294"},
                     {"label": "Jesus Maria", "value": "C73295"},
                     {"label": "La Esperanza", "value": "C73296"},
                     {"label": "La Herradura", "value": "C73297"},
                     {"label": "La Lima", "value": "C73298"},
                     {"label": "La Mesa de los Conejos", "value": "C73299"},
                     {"label": "La Pila", "value": "C73300"},
                     {"label": "La Reforma", "value": "C73301"},
                     {"label": "La Subida", "value": "C73302"},
                     {"label": "La Ventilla", "value": "C73303"},
                     {"label": "Laguna de San Vicente", "value": "C73304"},
                     {"label": "Laguna de Santa Rita", "value": "C73305"},
                     {"label": "Laguna del Mante", "value": "C73306"},
                     {"label": "Las Armas", "value": "C73307"},
                     {"label": "Las Palmas", "value": "C73308"},
                     {"label": "Los Zacatones", "value": "C73309"},
                     {"label": "Maitinez", "value": "C73310"},
                     {"label": "Maravillas", "value": "C73311"},
                     {"label": "Martinez", "value": "C73312"},
                     {"label": "Matehuala", "value": "C73313"},
                     {"label": "Moctezuma", "value": "C73314"},
                     {"label": "Monte Obscuro", "value": "C73315"},
                     {"label": "Negritas", "value": "C73316"},
                     {"label": "Norias del Refugio", "value": "C73317"},
                     {"label": "Nueva Primavera", "value": "C73318"},
                     {"label": "Nuevo Crucitas", "value": "C73319"},
                     {"label": "Nuevo Tampaon", "value": "C73320"},
                     {"label": "Ocuiltzapoyo", "value": "C73321"},
                     {"label": "Ojo Caliente", "value": "C73322"},
                     {"label": "Ojo de Agua", "value": "C73323"},
                     {"label": "Ojo de Agua de Solano", "value": "C73324"},
                     {"label": "Paisanos", "value": "C73325"},
                     {"label": "Palma Pegada", "value": "C73326"},
                     {"label": "Palmar Primero", "value": "C73327"},
                     {"label": "Palo Seco (El Gato)", "value": "C73328"},
                     {"label": "Palomas", "value": "C73329"},
                     {"label": "Pardo", "value": "C73330"},
                     {"label": "Paso Blanco", "value": "C73331"},
                     {"label": "Paso Bonito", "value": "C73332"},
                     {"label": "Pastora", "value": "C73333"},
                     {"label": "Pemucho", "value": "C73334"},
                     {"label": "Peotillos", "value": "C73335"},
                     {"label": "Picholco", "value": "C73336"},
                     {"label": "Ponciano Arriaga", "value": "C73337"},
                     {"label": "Portezuelo", "value": "C73338"},
                     {"label": "Pozuelos", "value": "C73339"},
                     {"label": "Progreso", "value": "C73340"},
                     {"label": "Puerto de Providencia", "value": "C73341"},
                     {"label": "Pujal-Coy", "value": "C73342"},
                     {"label": "Rancho Nuevo", "value": "C73343"},
                     {"label": "Rascon", "value": "C73344"},
                     {"label": "Rayon", "value": "C73345"},
                     {"label": "Real de Catorce", "value": "C73346"},
                     {"label": "Rincon del Porvenir", "value": "C73347"},
                     {"label": "Rinconada", "value": "C73348"},
                     {"label": "Rioverde", "value": "C73349"},
                     {"label": "Rodrigo", "value": "C73350"},
                     {"label": "Salinas de Hidalgo", "value": "C73351"},
                     {"label": "Salitral de Carrera", "value": "C73352"},
                     {"label": "San Ciro de Acosta", "value": "C73353"},
                     {"label": "San Diego", "value": "C73354"},
                     {"label": "San Francisco", "value": "C73355"},
                     {"label": "San Francisco Cuayalab", "value": "C73356"},
                     {"label": "San Isidro", "value": "C73357"},
                     {"label": "San Jose del Tapanco", "value": "C73358"},
                     {"label": "San Luis Potosi", "value": "C73359"},
                     {"label": "San Martin Chalchicuautla", "value": "C73360"},
                     {"label": "San Nicolas", "value": "C73361"},
                     {"label": "San Nicolas Tolentino", "value": "C73362"},
                     {"label": "San Vicente Tancuayalab", "value": "C73363"},
                     {"label": "Sanchez", "value": "C73364"},
                     {"label": "Santa Catarina", "value": "C73365"},
                     {"label": "Santa Maria del Rio", "value": "C73366"},
                     {"label": "Santa Martha", "value": "C73367"},
                     {"label": "Santa Rita", "value": "C73368"},
                     {"label": "Santiago Centro", "value": "C73369"},
                     {"label": "Santo Domingo", "value": "C73370"},
                     {"label": "Sauz de Calera", "value": "C73371"},
                     {"label": "Socavon (El Carmen)", "value": "C73372"},
                     {"label": "Soledad de Graciano Sanchez",
                      "value": "C73373"},
                     {"label": "Suspiro Picacho", "value": "C73374"},
                     {"label": "Taman", "value": "C73375"},
                     {"label": "Tamapatz", "value": "C73376"},
                     {"label": "Tamasopo", "value": "C73377"},
                     {"label": "Tamazunchale", "value": "C73378"},
                     {"label": "Tambaca", "value": "C73379"},
                     {"label": "Tamcuime", "value": "C73380"},
                     {"label": "Tampacan", "value": "C73381"},
                     {"label": "Tampate", "value": "C73382"},
                     {"label": "Tampemoche", "value": "C73383"},
                     {"label": "Tamuin", "value": "C73384"},
                     {"label": "Tancanhuitz", "value": "C73385"},
                     {"label": "Tandzumadz", "value": "C73386"},
                     {"label": "Tanquian de Escobedo", "value": "C73387"},
                     {"label": "Tanute", "value": "C73388"},
                     {"label": "Tazaquil", "value": "C73389"},
                     {"label": "Temalacaco", "value": "C73390"},
                     {"label": "Tenexio", "value": "C73391"},
                     {"label": "Tierra Nueva", "value": "C73392"},
                     {"label": "Tlacuilola", "value": "C73393"},
                     {"label": "Tlalnepantla", "value": "C73394"},
                     {"label": "Tocoy", "value": "C73395"},
                     {"label": "Vanegas", "value": "C73396"},
                     {"label": "Villa de Ramos", "value": "C73397"},
                     {"label": "Villa de Reyes", "value": "C73398"},
                     {"label": "Villa Hidalgo", "value": "C73399"},
                     {"label": "Villa Zaragoza", "value": "C73400"},
                     {"label": "Xilitla", "value": "C73401"},
                     {"label": "Yerbabuena", "value": "C73402"},
                     {"label": "Zamachihue", "value": "C73403"},
                     {"label": "Zapuyo", "value": "C73404"}],
 "San Marcos Department": [{"label": "Catarina", "value": "C42890"},
                           {"label": "Ciudad Tecun Uman", "value": "C42891"},
                           {"label": "Comitancillo", "value": "C42892"},
                           {"label": "Concepcion Tutuapa", "value": "C42893"},
                           {"label": "El Quetzal", "value": "C42894"},
                           {"label": "El Rodeo", "value": "C42895"},
                           {"label": "El Tumbador", "value": "C42896"},
                           {"label": "Esquipulas Palo Gordo",
                            "value": "C42897"},
                           {"label": "Ixchiguan", "value": "C42898"},
                           {"label": "La Reforma", "value": "C42899"},
                           {"label": "Malacatan", "value": "C42900"},
                           {"label": "Municipio de Concepcion Tutuapa",
                            "value": "C42901"},
                           {"label": "Municipio de Malacatan",
                            "value": "C42902"},
                           {"label": "Municipio de Sipacapa",
                            "value": "C42903"},
                           {"label": "Municipio de Tejutla", "value": "C42904"},
                           {"label": "Nuevo Progreso", "value": "C42905"},
                           {"label": "Ocos", "value": "C42906"},
                           {"label": "Pajapita", "value": "C42907"},
                           {"label": "Rio Blanco", "value": "C42908"},
                           {"label": "San Antonio Sacatepequez",
                            "value": "C42909"},
                           {"label": "San Cristobal Cucho", "value": "C42910"},
                           {"label": "San Jose Ojetenam", "value": "C42911"},
                           {"label": "San Jose Ojetenan", "value": "C42912"},
                           {"label": "San Lorenzo", "value": "C42913"},
                           {"label": "San Marcos", "value": "C42914"},
                           {"label": "San Miguel Ixtahuacan",
                            "value": "C42915"},
                           {"label": "San Pablo", "value": "C42916"},
                           {"label": "San Pedro Sacatepequez",
                            "value": "C42917"},
                           {"label": "San Rafael Pie de la Cuesta",
                            "value": "C42918"},
                           {"label": "Sibinal", "value": "C42919"},
                           {"label": "Sipacapa", "value": "C42920"},
                           {"label": "Tacana", "value": "C42921"},
                           {"label": "Tajumulco", "value": "C42922"},
                           {"label": "Tejutla", "value": "C42923"}],
 "San Marino": [{"label": "San Marino", "value": "C106252"}],
 "San Martin": [{"label": "Bellavista", "value": "C81060"},
                {"label": "Chazuta", "value": "C81061"},
                {"label": "El Dorado", "value": "C81062"},
                {"label": "Huicungo", "value": "C81063"},
                {"label": "Jepelacio", "value": "C81064"},
                {"label": "Juanjui", "value": "C81065"},
                {"label": "Lamas", "value": "C81066"},
                {"label": "Moyobamba", "value": "C81067"},
                {"label": "Picota", "value": "C81068"},
                {"label": "Provincia de Huallaga", "value": "C81069"},
                {"label": "Provincia de Lamas", "value": "C81070"},
                {"label": "Provincia de Mariscal Caceres", "value": "C81071"},
                {"label": "Provincia de Moyobamba", "value": "C81072"},
                {"label": "Provincia de Rioja", "value": "C81073"},
                {"label": "Provincia de San Martin", "value": "C81074"},
                {"label": "Rioja", "value": "C81075"},
                {"label": "Saposoa", "value": "C81076"},
                {"label": "Sisa", "value": "C81077"},
                {"label": "Soritor", "value": "C81078"},
                {"label": "Tabalosos", "value": "C81079"},
                {"label": "Tarapoto", "value": "C81080"},
                {"label": "Tocache", "value": "C81081"},
                {"label": "Uchiza", "value": "C81082"}],
 "San Miguel Department": [{"label": "Chapeltique", "value": "C24408"},
                           {"label": "Chinameca", "value": "C24409"},
                           {"label": "Chirilagua", "value": "C24410"},
                           {"label": "Ciudad Barrios", "value": "C24411"},
                           {"label": "El Transito", "value": "C24412"},
                           {"label": "Lolotique", "value": "C24413"},
                           {"label": "Moncagua", "value": "C24414"},
                           {"label": "Nueva Guadalupe", "value": "C24415"},
                           {"label": "San Miguel", "value": "C24416"},
                           {"label": "San Rafael Oriente", "value": "C24417"},
                           {"label": "Sesori", "value": "C24418"}],
 "San Pedro Department": [{"label": "Antequera", "value": "C80615"},
                          {"label": "Capiibary", "value": "C80616"},
                          {"label": "Colonia Nueva Germania",
                           "value": "C80617"},
                          {"label": "General Elizardo Aquino",
                           "value": "C80618"},
                          {"label": "Guayaybi", "value": "C80619"},
                          {"label": "Itacurubi del Rosario", "value": "C80620"},
                          {"label": "Lima", "value": "C80621"},
                          {"label": "Puerto Rosario", "value": "C80622"},
                          {"label": "San Pedro de Ycuamandiyu",
                           "value": "C80623"},
                          {"label": "Tacuati", "value": "C80624"},
                          {"label": "Union", "value": "C80625"},
                          {"label": "Yataity del Norte", "value": "C80626"}],
 "San Pedro de Macoris": [{"label": "El Puerto", "value": "C24005"},
                          {"label": "Los Llanos", "value": "C24006"},
                          {"label": "Quisqueya", "value": "C24007"},
                          {"label": "Ramon Santana", "value": "C24008"},
                          {"label": "San Pedro de Macoris", "value": "C24009"}],
 "San Salvador Department": [{"label": "Aguilares", "value": "C24419"},
                             {"label": "Apopa", "value": "C24420"},
                             {"label": "Ayutuxtepeque", "value": "C24421"},
                             {"label": "Cuscatancingo", "value": "C24422"},
                             {"label": "Delgado", "value": "C24423"},
                             {"label": "El Paisnal", "value": "C24424"},
                             {"label": "Guazapa", "value": "C24425"},
                             {"label": "Ilopango", "value": "C24426"},
                             {"label": "Mejicanos", "value": "C24427"},
                             {"label": "Panchimalco", "value": "C24428"},
                             {"label": "Rosario de Mora", "value": "C24429"},
                             {"label": "San Marcos", "value": "C24430"},
                             {"label": "San Salvador", "value": "C24431"},
                             {"label": "Santo Tomas", "value": "C24432"},
                             {"label": "Soyapango", "value": "C24433"},
                             {"label": "Tonacatepeque", "value": "C24434"}],
 "San Salvador Island": [{"label": "Cockburn Town", "value": "C119622"}],
 "San Vicente Department": [{"label": "Apastepeque", "value": "C24435"},
                            {"label": "San Sebastian", "value": "C24436"},
                            {"label": "San Vicente", "value": "C24437"}],
 "Sanaa Governorate": [{"label": "Al Haymah Ad Dakhiliyah", "value": "C148018"},
                       {"label": "Al Haymah Al Kharijiyah", "value": "C148019"},
                       {"label": "Al Husn", "value": "C148020"},
                       {"label": "Al Khaniq", "value": "C148021"},
                       {"label": "Arhab", "value": "C148022"},
                       {"label": "Attyal", "value": "C148023"},
                       {"label": "Bani Dhabyan", "value": "C148024"},
                       {"label": "Bani Hushaysh", "value": "C148025"},
                       {"label": "Bani Matar", "value": "C148026"},
                       {"label": "Bilad Ar Rus", "value": "C148027"},
                       {"label": "Hamdan", "value": "C148028"},
                       {"label": "Jihanah", "value": "C148029"},
                       {"label": "Khwlan", "value": "C148030"},
                       {"label": "Manakhah", "value": "C148031"},
                       {"label": "Nihm", "value": "C148032"},
                       {"label": "Safan", "value": "C148033"},
                       {"label": "Sahar", "value": "C148034"},
                       {"label": "Sanaa", "value": "C148035"},
                       {"label": "Sanhan", "value": "C148036"},
                       {"label": "Sayyan", "value": "C148037"}],
 "Sanaag Region": [{"label": "Ceerigaabo", "value": "C107921"},
                   {"label": "Las Khorey", "value": "C107922"}],
 "Sanchez Ramirez Province": [{"label": "Cevicos", "value": "C24010"},
                              {"label": "Cotui", "value": "C24011"},
                              {"label": "Fantino", "value": "C24012"}],
 "Sancti Spiritus Province": [{"label": "Cabaiguan", "value": "C21818"},
                              {"label": "Condado", "value": "C21819"},
                              {"label": "Fomento", "value": "C21820"},
                              {"label": "Guayos", "value": "C21821"},
                              {"label": "Jatibonico", "value": "C21822"},
                              {"label": "La Sierpe", "value": "C21823"},
                              {"label": "Municipio de Cabaiguan",
                               "value": "C21824"},
                              {"label": "Municipio de Jatibonico",
                               "value": "C21825"},
                              {"label": "Municipio de Sancti Spiritus",
                               "value": "C21826"},
                              {"label": "Municipio de Trinidad",
                               "value": "C21827"},
                              {"label": "Sancti Spiritus", "value": "C21828"},
                              {"label": "Topes de Collantes",
                               "value": "C21829"},
                              {"label": "Trinidad", "value": "C21830"},
                              {"label": "Yaguajay", "value": "C21831"},
                              {"label": "Zaza del Medio", "value": "C21832"}],
 "Sandaun Province": [{"label": "Aitape", "value": "C80460"},
                      {"label": "Aitape Lumi", "value": "C80461"},
                      {"label": "Nuku", "value": "C80462"},
                      {"label": "Telefomin", "value": "C80463"},
                      {"label": "Vanimo", "value": "C80464"},
                      {"label": "Vanimo Green", "value": "C80465"}],
 "Sangha Department": [{"label": "Ouesso", "value": "C20761"},
                       {"label": "Sembe", "value": "C20762"}],
 "Sangha-Mbaere": [{"label": "Nola", "value": "C17848"}],
 "Sangre Grande Regional Corporation": [{"label": "Sangre Grande",
                                         "value": "C119673"}],
 "Sankuru": [{"label": "Lodja", "value": "C23363"},
             {"label": "Lusambo", "value": "C23364"}],
 "Sanliurfa": [{"label": "Akcakale", "value": "C120922"},
               {"label": "Akdiken", "value": "C120923"},
               {"label": "Asagi Begdes", "value": "C120924"},
               {"label": "Birecik", "value": "C120925"},
               {"label": "Bozova", "value": "C120926"},
               {"label": "Bulutlu", "value": "C120927"},
               {"label": "Ceylanpinar", "value": "C120928"},
               {"label": "Dorumali", "value": "C120929"},
               {"label": "Eyyubiye", "value": "C120930"},
               {"label": "Guneren", "value": "C120931"},
               {"label": "Halfeti", "value": "C120932"},
               {"label": "Haliliye", "value": "C120933"},
               {"label": "Hankoy", "value": "C120934"},
               {"label": "Harran", "value": "C120935"},
               {"label": "Hilvan", "value": "C120936"},
               {"label": "Isiklar", "value": "C120937"},
               {"label": "Karakopru", "value": "C120938"},
               {"label": "Koyunluca", "value": "C120939"},
               {"label": "Kucukkendirci", "value": "C120940"},
               {"label": "Magarali", "value": "C120941"},
               {"label": "Meydankapi", "value": "C120942"},
               {"label": "Minare", "value": "C120943"},
               {"label": "Muratli", "value": "C120944"},
               {"label": "Oncul", "value": "C120945"},
               {"label": "Pekmezli", "value": "C120946"},
               {"label": "Sanliurfa", "value": "C120947"},
               {"label": "Seksenoren", "value": "C120948"},
               {"label": "Siverek", "value": "C120949"},
               {"label": "Suruc", "value": "C120950"},
               {"label": "Viransehir", "value": "C120951"},
               {"label": "Yukari Tasyalak", "value": "C120952"}],
 "Sanma": [{"label": "Luganville", "value": "C147118"},
           {"label": "Port-Olry", "value": "C147119"}],
 "Sannat": [{"label": "San Pawl il-Bahar", "value": "C65913"},
            {"label": "Sannat", "value": "C65914"}],
 "Sant Julia de Loria": [{"label": "Sant Julia de Loria", "value": "C525"}],
 "Santa Ana Department": [{"label": "Candelaria de La Frontera",
                           "value": "C24438"},
                          {"label": "Chalchuapa", "value": "C24439"},
                          {"label": "Coatepeque", "value": "C24440"},
                          {"label": "El Congo", "value": "C24441"},
                          {"label": "Metapan", "value": "C24442"},
                          {"label": "Santa Ana", "value": "C24443"},
                          {"label": "Texistepeque", "value": "C24444"}],
 "Santa Barbara Department": [{"label": "Agualote", "value": "C43640"},
                              {"label": "Arada", "value": "C43641"},
                              {"label": "Atima", "value": "C43642"},
                              {"label": "Azacualpa", "value": "C43643"},
                              {"label": "Berlin", "value": "C43644"},
                              {"label": "Callejones", "value": "C43645"},
                              {"label": "Camalote", "value": "C43646"},
                              {"label": "Casa Quemada", "value": "C43647"},
                              {"label": "Ceguaca", "value": "C43648"},
                              {"label": "Chinda", "value": "C43649"},
                              {"label": "Concepcion del Norte",
                               "value": "C43650"},
                              {"label": "Concepcion del Sur",
                               "value": "C43651"},
                              {"label": "Correderos", "value": "C43652"},
                              {"label": "El Ciruelo", "value": "C43653"},
                              {"label": "El Corozal", "value": "C43654"},
                              {"label": "El Mochito", "value": "C43655"},
                              {"label": "El Nispero", "value": "C43656"},
                              {"label": "Guacamaya", "value": "C43657"},
                              {"label": "Gualala", "value": "C43658"},
                              {"label": "Gualjoco", "value": "C43659"},
                              {"label": "Ilama", "value": "C43660"},
                              {"label": "Joconal", "value": "C43661"},
                              {"label": "La Flecha", "value": "C43662"},
                              {"label": "Laguna Verde", "value": "C43663"},
                              {"label": "Las Vegas", "value": "C43664"},
                              {"label": "Las Vegas, Santa Barbara",
                               "value": "C43665"},
                              {"label": "Loma Alta", "value": "C43666"},
                              {"label": "Macuelizo", "value": "C43667"},
                              {"label": "Naco", "value": "C43668"},
                              {"label": "Naranjito", "value": "C43669"},
                              {"label": "Nueva Frontera", "value": "C43670"},
                              {"label": "Nueva Jalapa", "value": "C43671"},
                              {"label": "Nuevo Celilac", "value": "C43672"},
                              {"label": "Petoa", "value": "C43673"},
                              {"label": "Pinalejo", "value": "C43674"},
                              {"label": "Proteccion", "value": "C43675"},
                              {"label": "Quimistan", "value": "C43676"},
                              {"label": "San Francisco de Ojuera",
                               "value": "C43677"},
                              {"label": "San Jose de Colinas",
                               "value": "C43678"},
                              {"label": "San Jose de Tarros",
                               "value": "C43679"},
                              {"label": "San Luis", "value": "C43680"},
                              {"label": "San Luis de Planes",
                               "value": "C43681"},
                              {"label": "San Marcos", "value": "C43682"},
                              {"label": "San Nicolas", "value": "C43683"},
                              {"label": "San Pedro Zacapa", "value": "C43684"},
                              {"label": "San Vicente Centenario",
                               "value": "C43685"},
                              {"label": "Santa Barbara", "value": "C43686"},
                              {"label": "Santa Rita", "value": "C43687"},
                              {"label": "Sula", "value": "C43688"},
                              {"label": "Tras Cerros", "value": "C43689"},
                              {"label": "Trinidad", "value": "C43690"}],
 "Santa Catarina": [{"label": "Abdon Batista", "value": "C14656"},
                    {"label": "Abelardo Luz", "value": "C14657"},
                    {"label": "Agrolandia", "value": "C14658"},
                    {"label": "Agronomica", "value": "C14659"},
                    {"label": "agua Doce", "value": "C14660"},
                    {"label": "aguas de Chapeco", "value": "C14661"},
                    {"label": "aguas Frias", "value": "C14662"},
                    {"label": "aguas Mornas", "value": "C14663"},
                    {"label": "Alfredo Wagner", "value": "C14664"},
                    {"label": "Alto Bela Vista", "value": "C14665"},
                    {"label": "Anchieta", "value": "C14666"},
                    {"label": "Angelina", "value": "C14667"},
                    {"label": "Anita Garibaldi", "value": "C14668"},
                    {"label": "Anitapolis", "value": "C14669"},
                    {"label": "Antonio Carlos", "value": "C14670"},
                    {"label": "Apiuna", "value": "C14671"},
                    {"label": "Arabuta", "value": "C14672"},
                    {"label": "Araquari", "value": "C14673"},
                    {"label": "Ararangua", "value": "C14674"},
                    {"label": "Armacao", "value": "C14675"},
                    {"label": "Armazem", "value": "C14676"},
                    {"label": "Arroio Trinta", "value": "C14677"},
                    {"label": "Arvoredo", "value": "C14678"},
                    {"label": "Ascurra", "value": "C14679"},
                    {"label": "Atalanta", "value": "C14680"},
                    {"label": "Aurora", "value": "C14681"},
                    {"label": "Balneario Arroio do Silva", "value": "C14682"},
                    {"label": "Balneario Barra do Sul", "value": "C14683"},
                    {"label": "Balneario Camboriu", "value": "C14684"},
                    {"label": "Balneario Gaivota", "value": "C14685"},
                    {"label": "Balneario Picarras", "value": "C14686"},
                    {"label": "Balneario Rincao", "value": "C14687"},
                    {"label": "Bandeirante", "value": "C14688"},
                    {"label": "Barra Bonita", "value": "C14689"},
                    {"label": "Barra Velha", "value": "C14690"},
                    {"label": "Bela Vista do Toldo", "value": "C14691"},
                    {"label": "Belmonte", "value": "C14692"},
                    {"label": "Benedito Novo", "value": "C14693"},
                    {"label": "Biguacu", "value": "C14694"},
                    {"label": "Blumenau", "value": "C14695"},
                    {"label": "Bocaina do Sul", "value": "C14696"},
                    {"label": "Bom Jardim da Serra", "value": "C14697"},
                    {"label": "Bom Jesus", "value": "C14698"},
                    {"label": "Bom Jesus do Oeste", "value": "C14699"},
                    {"label": "Bom Retiro", "value": "C14700"},
                    {"label": "Bombinhas", "value": "C14701"},
                    {"label": "Botuvera", "value": "C14702"},
                    {"label": "Braco do Norte", "value": "C14703"},
                    {"label": "Braco do Trombudo", "value": "C14704"},
                    {"label": "Brunopolis", "value": "C14705"},
                    {"label": "Brusque", "value": "C14706"},
                    {"label": "Cacador", "value": "C14707"},
                    {"label": "Caibi", "value": "C14708"},
                    {"label": "Calmon", "value": "C14709"},
                    {"label": "Camboriu", "value": "C14710"},
                    {"label": "Campinas", "value": "C14711"},
                    {"label": "Campo Alegre", "value": "C14712"},
                    {"label": "Campo Belo do Sul", "value": "C14713"},
                    {"label": "Campo Ere", "value": "C14714"},
                    {"label": "Campos Novos", "value": "C14715"},
                    {"label": "Canelinha", "value": "C14716"},
                    {"label": "Canoinhas", "value": "C14717"},
                    {"label": "Capao Alto", "value": "C14718"},
                    {"label": "Capinzal", "value": "C14719"},
                    {"label": "Capivari de Baixo", "value": "C14720"},
                    {"label": "Carianos", "value": "C14721"},
                    {"label": "Carvoeira", "value": "C14722"},
                    {"label": "Catanduvas", "value": "C14723"},
                    {"label": "Caxambu do Sul", "value": "C14724"},
                    {"label": "Celso Ramos", "value": "C14725"},
                    {"label": "Cerro Negro", "value": "C14726"},
                    {"label": "Chapadao do Lageado", "value": "C14727"},
                    {"label": "Chapeco", "value": "C14728"},
                    {"label": "Cocal", "value": "C14729"},
                    {"label": "Cocal do Sul", "value": "C14730"},
                    {"label": "Concordia", "value": "C14731"},
                    {"label": "Cordilheira Alta", "value": "C14732"},
                    {"label": "Coronel Freitas", "value": "C14733"},
                    {"label": "Coronel Martins", "value": "C14734"},
                    {"label": "Corrego Grande", "value": "C14735"},
                    {"label": "Correia Pinto", "value": "C14736"},
                    {"label": "Corupa", "value": "C14737"},
                    {"label": "Costeira do Pirajubae", "value": "C14738"},
                    {"label": "Criciuma", "value": "C14739"},
                    {"label": "Cunha Pora", "value": "C14740"},
                    {"label": "Cunhatai", "value": "C14741"},
                    {"label": "Curitibanos", "value": "C14742"},
                    {"label": "Descanso", "value": "C14743"},
                    {"label": "Dionisio Cerqueira", "value": "C14744"},
                    {"label": "Dona Emma", "value": "C14745"},
                    {"label": "Doutor Pedrinho", "value": "C14746"},
                    {"label": "Entre Rios", "value": "C14747"},
                    {"label": "Ermo", "value": "C14748"},
                    {"label": "Erval Velho", "value": "C14749"},
                    {"label": "Faxinal dos Guedes", "value": "C14750"},
                    {"label": "Flor do Sertao", "value": "C14751"},
                    {"label": "Florianopolis", "value": "C14752"},
                    {"label": "Formosa do Sul", "value": "C14753"},
                    {"label": "Forquilhinha", "value": "C14754"},
                    {"label": "Fraiburgo", "value": "C14755"},
                    {"label": "Freguesia do Ribeirao da Ilha",
                     "value": "C14756"},
                    {"label": "Frei Rogerio", "value": "C14757"},
                    {"label": "Galvao", "value": "C14758"},
                    {"label": "Garopaba", "value": "C14759"},
                    {"label": "Garuva", "value": "C14760"},
                    {"label": "Gaspar", "value": "C14761"},
                    {"label": "Governador Celso Ramos", "value": "C14762"},
                    {"label": "Grao Para", "value": "C14763"},
                    {"label": "Gravatal", "value": "C14764"},
                    {"label": "Guabiruba", "value": "C14765"},
                    {"label": "Guaraciaba", "value": "C14766"},
                    {"label": "Guaramirim", "value": "C14767"},
                    {"label": "Guaruja do Sul", "value": "C14768"},
                    {"label": "Guatambu", "value": "C14769"},
                    {"label": "Herval dOeste", "value": "C14770"},
                    {"label": "Ibiam", "value": "C14771"},
                    {"label": "Ibicare", "value": "C14772"},
                    {"label": "Ibirama", "value": "C14773"},
                    {"label": "Icara", "value": "C14774"},
                    {"label": "Ilhota", "value": "C14775"},
                    {"label": "Imarui", "value": "C14776"},
                    {"label": "Imbituba", "value": "C14777"},
                    {"label": "Imbuia", "value": "C14778"},
                    {"label": "Indaial", "value": "C14779"},
                    {"label": "Iomere", "value": "C14780"},
                    {"label": "Ipira", "value": "C14781"},
                    {"label": "Ipora do Oeste", "value": "C14782"},
                    {"label": "Ipuacu", "value": "C14783"},
                    {"label": "Ipumirim", "value": "C14784"},
                    {"label": "Iraceminha", "value": "C14785"},
                    {"label": "Irani", "value": "C14786"},
                    {"label": "Irati", "value": "C14787"},
                    {"label": "Irineopolis", "value": "C14788"},
                    {"label": "Ita", "value": "C14789"},
                    {"label": "Itacorubi", "value": "C14790"},
                    {"label": "Itaiopolis", "value": "C14791"},
                    {"label": "Itajai", "value": "C14792"},
                    {"label": "Itapema", "value": "C14793"},
                    {"label": "Itapiranga", "value": "C14794"},
                    {"label": "Itapoa", "value": "C14795"},
                    {"label": "Ituporanga", "value": "C14796"},
                    {"label": "Jabora", "value": "C14797"},
                    {"label": "Jacinto Machado", "value": "C14798"},
                    {"label": "Jaguaruna", "value": "C14799"},
                    {"label": "Jaragua do Sul", "value": "C14800"},
                    {"label": "Jardinopolis", "value": "C14801"},
                    {"label": "Joacaba", "value": "C14802"},
                    {"label": "Joinville", "value": "C14803"},
                    {"label": "Jose Boiteux", "value": "C14804"},
                    {"label": "Jupia", "value": "C14805"},
                    {"label": "Lacerdopolis", "value": "C14806"},
                    {"label": "Lages", "value": "C14807"},
                    {"label": "Lagoa", "value": "C14808"},
                    {"label": "Laguna", "value": "C14809"},
                    {"label": "Lajeado Grande", "value": "C14810"},
                    {"label": "Laurentino", "value": "C14811"},
                    {"label": "Lauro Muller", "value": "C14812"},
                    {"label": "Lebon Regis", "value": "C14813"},
                    {"label": "Leoberto Leal", "value": "C14814"},
                    {"label": "Lindoia do Sul", "value": "C14815"},
                    {"label": "Lontras", "value": "C14816"},
                    {"label": "Luiz Alves", "value": "C14817"},
                    {"label": "Luzerna", "value": "C14818"},
                    {"label": "Macieira", "value": "C14819"},
                    {"label": "Mafra", "value": "C14820"},
                    {"label": "Major Gercino", "value": "C14821"},
                    {"label": "Major Vieira", "value": "C14822"},
                    {"label": "Maracaja", "value": "C14823"},
                    {"label": "Maravilha", "value": "C14824"},
                    {"label": "Marema", "value": "C14825"},
                    {"label": "Massaranduba", "value": "C14826"},
                    {"label": "Matos Costa", "value": "C14827"},
                    {"label": "Meleiro", "value": "C14828"},
                    {"label": "Mirim Doce", "value": "C14829"},
                    {"label": "Modelo", "value": "C14830"},
                    {"label": "Mondai", "value": "C14831"},
                    {"label": "Monte Carlo", "value": "C14832"},
                    {"label": "Monte Castelo", "value": "C14833"},
                    {"label": "Morro da Cruz", "value": "C14834"},
                    {"label": "Morro da Fumaca", "value": "C14835"},
                    {"label": "Morro Grande", "value": "C14836"},
                    {"label": "Navegantes", "value": "C14837"},
                    {"label": "Nova Erechim", "value": "C14838"},
                    {"label": "Nova Itaberaba", "value": "C14839"},
                    {"label": "Nova Trento", "value": "C14840"},
                    {"label": "Nova Veneza", "value": "C14841"},
                    {"label": "Novo Horizonte", "value": "C14842"},
                    {"label": "Orleans", "value": "C14843"},
                    {"label": "Otacilio Costa", "value": "C14844"},
                    {"label": "Ouro", "value": "C14845"},
                    {"label": "Ouro Verde", "value": "C14846"},
                    {"label": "Paial", "value": "C14847"},
                    {"label": "Painel", "value": "C14848"},
                    {"label": "Palhoca", "value": "C14849"},
                    {"label": "Palma Sola", "value": "C14850"},
                    {"label": "Palmeira", "value": "C14851"},
                    {"label": "Palmitos", "value": "C14852"},
                    {"label": "Pantanal", "value": "C14853"},
                    {"label": "Pantano do Sul", "value": "C14854"},
                    {"label": "Papanduva", "value": "C14855"},
                    {"label": "Paraiso", "value": "C14856"},
                    {"label": "Passo de Torres", "value": "C14857"},
                    {"label": "Passos Maia", "value": "C14858"},
                    {"label": "Paulo Lopes", "value": "C14859"},
                    {"label": "Pedras Grandes", "value": "C14860"},
                    {"label": "Penha", "value": "C14861"},
                    {"label": "Peritiba", "value": "C14862"},
                    {"label": "Petrolandia", "value": "C14863"},
                    {"label": "Pinhalzinho", "value": "C14864"},
                    {"label": "Pinheiro Preto", "value": "C14865"},
                    {"label": "Piratuba", "value": "C14866"},
                    {"label": "Planalto Alegre", "value": "C14867"},
                    {"label": "Pomerode", "value": "C14868"},
                    {"label": "Ponte Alta", "value": "C14869"},
                    {"label": "Ponte Alta do Norte", "value": "C14870"},
                    {"label": "Ponte Serrada", "value": "C14871"},
                    {"label": "Porto Belo", "value": "C14872"},
                    {"label": "Porto Uniao", "value": "C14873"},
                    {"label": "Pouso Redondo", "value": "C14874"},
                    {"label": "Praia Grande", "value": "C14875"},
                    {"label": "Presidente Castello Branco", "value": "C14876"},
                    {"label": "Presidente Getulio", "value": "C14877"},
                    {"label": "Presidente Nereu", "value": "C14878"},
                    {"label": "Princesa", "value": "C14879"},
                    {"label": "Quilombo", "value": "C14880"},
                    {"label": "Rancho Queimado", "value": "C14881"},
                    {"label": "Residencia Moacir PU5BHV", "value": "C14882"},
                    {"label": "Ribeirao da Ilha", "value": "C14883"},
                    {"label": "Rio das Antas", "value": "C14884"},
                    {"label": "Rio do Campo", "value": "C14885"},
                    {"label": "Rio do Oeste", "value": "C14886"},
                    {"label": "Rio do Sul", "value": "C14887"},
                    {"label": "Rio dos Cedros", "value": "C14888"},
                    {"label": "Rio Fortuna", "value": "C14889"},
                    {"label": "Rio Negrinho", "value": "C14890"},
                    {"label": "Rio Rufino", "value": "C14891"},
                    {"label": "Rio Tavares", "value": "C14892"},
                    {"label": "Riqueza", "value": "C14893"},
                    {"label": "Rodeio", "value": "C14894"},
                    {"label": "Romelandia", "value": "C14895"},
                    {"label": "Saco dos Limoes", "value": "C14896"},
                    {"label": "Salete", "value": "C14897"},
                    {"label": "Saltinho", "value": "C14898"},
                    {"label": "Salto Veloso", "value": "C14899"},
                    {"label": "Sangao", "value": "C14900"},
                    {"label": "Santa Cecilia", "value": "C14901"},
                    {"label": "Santa Helena", "value": "C14902"},
                    {"label": "Santa Monica", "value": "C14903"},
                    {"label": "Santa Rosa de Lima", "value": "C14904"},
                    {"label": "Santa Rosa do Sul", "value": "C14905"},
                    {"label": "Santa Terezinha", "value": "C14906"},
                    {"label": "Santa Terezinha do Progresso",
                     "value": "C14907"},
                    {"label": "Santiago do Sul", "value": "C14908"},
                    {"label": "Santo Amaro da Imperatriz", "value": "C14909"},
                    {"label": "Sao Bento do Sul", "value": "C14910"},
                    {"label": "Sao Bernardino", "value": "C14911"},
                    {"label": "Sao Bonifacio", "value": "C14912"},
                    {"label": "Sao Carlos", "value": "C14913"},
                    {"label": "Sao Cristovao do Sul", "value": "C14914"},
                    {"label": "Sao Domingos", "value": "C14915"},
                    {"label": "Sao Francisco do Sul", "value": "C14916"},
                    {"label": "Sao Joao Batista", "value": "C14917"},
                    {"label": "Sao Joao do Itaperiu", "value": "C14918"},
                    {"label": "Sao Joao do Oeste", "value": "C14919"},
                    {"label": "Sao Joao do Sul", "value": "C14920"},
                    {"label": "Sao Joaquim", "value": "C14921"},
                    {"label": "Sao Jose", "value": "C14922"},
                    {"label": "Sao Jose do Cedro", "value": "C14923"},
                    {"label": "Sao Jose do Cerrito", "value": "C14924"},
                    {"label": "Sao Lourenco do Oeste", "value": "C14925"},
                    {"label": "Sao Ludgero", "value": "C14926"},
                    {"label": "Sao Martinho", "value": "C14927"},
                    {"label": "Sao Miguel da Boa Vista", "value": "C14928"},
                    {"label": "Sao Miguel do Oeste", "value": "C14929"},
                    {"label": "Sao Pedro de Alcantara", "value": "C14930"},
                    {"label": "Saudades", "value": "C14931"},
                    {"label": "Schroeder", "value": "C14932"},
                    {"label": "Seara", "value": "C14933"},
                    {"label": "Serra Alta", "value": "C14934"},
                    {"label": "Sideropolis", "value": "C14935"},
                    {"label": "Sombrio", "value": "C14936"},
                    {"label": "Sul Brasil", "value": "C14937"},
                    {"label": "Taio", "value": "C14938"},
                    {"label": "Tangara", "value": "C14939"},
                    {"label": "Tapera", "value": "C14940"},
                    {"label": "Tigrinhos", "value": "C14941"},
                    {"label": "Tijucas", "value": "C14942"},
                    {"label": "Timbe do Sul", "value": "C14943"},
                    {"label": "Timbo", "value": "C14944"},
                    {"label": "Timbo Grande", "value": "C14945"},
                    {"label": "Tres Barras", "value": "C14946"},
                    {"label": "Treviso", "value": "C14947"},
                    {"label": "Treze de Maio", "value": "C14948"},
                    {"label": "Treze Tilias", "value": "C14949"},
                    {"label": "Trindade", "value": "C14950"},
                    {"label": "Trombudo Central", "value": "C14951"},
                    {"label": "Tubarao", "value": "C14952"},
                    {"label": "Tunapolis", "value": "C14953"},
                    {"label": "Turvo", "value": "C14954"},
                    {"label": "Uniao do Oeste", "value": "C14955"},
                    {"label": "Urubici", "value": "C14956"},
                    {"label": "Urupema", "value": "C14957"},
                    {"label": "Urussanga", "value": "C14958"},
                    {"label": "Vargeao", "value": "C14959"},
                    {"label": "Vargem", "value": "C14960"},
                    {"label": "Vargem Bonita", "value": "C14961"},
                    {"label": "Vidal Ramos", "value": "C14962"},
                    {"label": "Videira", "value": "C14963"},
                    {"label": "Vitor Meireles", "value": "C14964"},
                    {"label": "Witmarsum", "value": "C14965"},
                    {"label": "Xanxere", "value": "C14966"},
                    {"label": "Xavantina", "value": "C14967"},
                    {"label": "Xaxim", "value": "C14968"},
                    {"label": "Zortea", "value": "C14969"},
                    {"label": "Assomada", "value": "C17797"}],
 "Santa Catarina do Fogo": [{"label": "Cova Figueira", "value": "C17798"}],
 "Santa Cruz": [{"label": "28 de Noviembre", "value": "C1397"},
                {"label": "Caleta Olivia", "value": "C1398"},
                {"label": "Comandante Luis Piedra Buena", "value": "C1399"},
                {"label": "Departamento de Deseado", "value": "C1400"},
                {"label": "Departamento de Guer Aike", "value": "C1401"},
                {"label": "Departamento de Lago Argentino", "value": "C1402"},
                {"label": "Departamento de Magallanes", "value": "C1403"},
                {"label": "Departamento de Rio Chico", "value": "C1404"},
                {"label": "El Calafate", "value": "C1405"},
                {"label": "Gobernador Gregores", "value": "C1406"},
                {"label": "Las Heras", "value": "C1407"},
                {"label": "Los Antiguos", "value": "C1408"},
                {"label": "Perito Moreno", "value": "C1409"},
                {"label": "Pico Truncado", "value": "C1410"},
                {"label": "Puerto Deseado", "value": "C1411"},
                {"label": "Puerto Santa Cruz", "value": "C1412"},
                {"label": "Rio Gallegos", "value": "C1413"},
                {"label": "Rio Turbio", "value": "C1414"},
                {"label": "San Julian", "value": "C1415"},
                {"label": "Yacimiento Rio Turbio", "value": "C1416"},
                {"label": "Pedra Badejo", "value": "C17799"},
                {"label": "Santa Cruz", "value": "C17800"}],
 "Santa Cruz Department": [{"label": "Abapo", "value": "C9817"},
                           {"label": "Ascencion de Guarayos", "value": "C9818"},
                           {"label": "Ascension", "value": "C9819"},
                           {"label": "Boyuibe", "value": "C9820"},
                           {"label": "Buena Vista", "value": "C9821"},
                           {"label": "Camiri", "value": "C9822"},
                           {"label": "Charagua", "value": "C9823"},
                           {"label": "Comarapa", "value": "C9824"},
                           {"label": "Concepcion", "value": "C9825"},
                           {"label": "Cotoca", "value": "C9826"},
                           {"label": "German Busch", "value": "C9827"},
                           {"label": "Guarayos", "value": "C9828"},
                           {"label": "Jorochito", "value": "C9829"},
                           {"label": "La Belgica", "value": "C9830"},
                           {"label": "Limoncito", "value": "C9831"},
                           {"label": "Los Negros", "value": "C9832"},
                           {"label": "Mairana", "value": "C9833"},
                           {"label": "Mineros", "value": "C9834"},
                           {"label": "Montero", "value": "C9835"},
                           {"label": "Okinawa Numero Uno", "value": "C9836"},
                           {"label": "Pailon", "value": "C9837"},
                           {"label": "Paurito", "value": "C9838"},
                           {"label": "Portachuelo", "value": "C9839"},
                           {"label": "Provincia Andres Ibanez",
                            "value": "C9840"},
                           {"label": "Provincia angel Sandoval",
                            "value": "C9841"},
                           {"label": "Provincia Chiquitos", "value": "C9842"},
                           {"label": "Provincia Cordillera", "value": "C9843"},
                           {"label": "Provincia Florida", "value": "C9844"},
                           {"label": "Provincia Ichilo", "value": "C9845"},
                           {"label": "Provincia Manuel Maria Caballero",
                            "value": "C9846"},
                           {"label": "Provincia nuflo de Chavez",
                            "value": "C9847"},
                           {"label": "Provincia Santiesteban",
                            "value": "C9848"},
                           {"label": "Provincia Sara", "value": "C9849"},
                           {"label": "Provincia Vallegrande", "value": "C9850"},
                           {"label": "Provincia Velasco", "value": "C9851"},
                           {"label": "Provincia Warnes", "value": "C9852"},
                           {"label": "Puerto Quijarro", "value": "C9853"},
                           {"label": "Puesto de Pailas", "value": "C9854"},
                           {"label": "Robore", "value": "C9855"},
                           {"label": "Samaipata", "value": "C9856"},
                           {"label": "San Carlos", "value": "C9857"},
                           {"label": "San Ignacio de Velasco",
                            "value": "C9858"},
                           {"label": "San Juan del Surutu", "value": "C9859"},
                           {"label": "San Julian", "value": "C9860"},
                           {"label": "San Matias", "value": "C9861"},
                           {"label": "San Pedro", "value": "C9862"},
                           {"label": "Santa Cruz de la Sierra",
                            "value": "C9863"},
                           {"label": "Santa Rita", "value": "C9864"},
                           {"label": "Santa Rosa del Sara", "value": "C9865"},
                           {"label": "Santiago del Torno", "value": "C9866"},
                           {"label": "Urubicha", "value": "C9867"},
                           {"label": "Vallegrande", "value": "C9868"},
                           {"label": "Villa Yapacani", "value": "C9869"},
                           {"label": "Warnes", "value": "C9870"}],
 "Santa Elena": [{"label": "La Libertad", "value": "C24196"},
                 {"label": "Salinas", "value": "C24197"},
                 {"label": "Santa Elena", "value": "C24198"}],
 "Santa Fe": [{"label": "Armstrong", "value": "C1417"},
              {"label": "Arroyo Seco", "value": "C1418"},
              {"label": "Arrufo", "value": "C1419"},
              {"label": "Avellaneda", "value": "C1420"},
              {"label": "Bella Italia", "value": "C1421"},
              {"label": "Calchaqui", "value": "C1422"},
              {"label": "Canada de Gomez", "value": "C1423"},
              {"label": "Capitan Bermudez", "value": "C1424"},
              {"label": "Carcarana", "value": "C1425"},
              {"label": "Casilda", "value": "C1426"},
              {"label": "Ceres", "value": "C1427"},
              {"label": "Chanar Ladeado", "value": "C1428"},
              {"label": "Coronda", "value": "C1429"},
              {"label": "Departamento de Belgrano", "value": "C1430"},
              {"label": "Departamento de Caseros", "value": "C1431"},
              {"label": "Departamento de Castellanos", "value": "C1432"},
              {"label": "Departamento de Constitucion", "value": "C1433"},
              {"label": "Departamento de La Capital", "value": "C1434"},
              {"label": "Departamento de Nueve de Julio", "value": "C1435"},
              {"label": "Departamento de San Cristobal", "value": "C1436"},
              {"label": "Departamento de San Javier", "value": "C1437"},
              {"label": "Departamento de San Justo", "value": "C1438"},
              {"label": "Departamento de San Lorenzo", "value": "C1439"},
              {"label": "Departamento de San Martin", "value": "C1440"},
              {"label": "Departamento de Vera", "value": "C1441"},
              {"label": "El Trebol", "value": "C1442"},
              {"label": "Esperanza", "value": "C1443"},
              {"label": "Firmat", "value": "C1444"},
              {"label": "Fray Luis A. Beltran", "value": "C1445"},
              {"label": "Funes", "value": "C1446"},
              {"label": "Galvez", "value": "C1447"},
              {"label": "Gato Colorado", "value": "C1448"},
              {"label": "Gobernador Galvez", "value": "C1449"},
              {"label": "Granadero Baigorria", "value": "C1450"},
              {"label": "Helvecia", "value": "C1451"},
              {"label": "Hersilia", "value": "C1452"},
              {"label": "Iriondo Department", "value": "C1453"},
              {"label": "Laguna Paiva", "value": "C1454"},
              {"label": "Las Parejas", "value": "C1455"},
              {"label": "Las Rosas", "value": "C1456"},
              {"label": "Las Toscas", "value": "C1457"},
              {"label": "Los Laureles", "value": "C1458"},
              {"label": "Malabrigo", "value": "C1459"},
              {"label": "Melincue", "value": "C1460"},
              {"label": "Perez", "value": "C1461"},
              {"label": "Rafaela", "value": "C1462"},
              {"label": "Reconquista", "value": "C1463"},
              {"label": "Recreo", "value": "C1464"},
              {"label": "Roldan", "value": "C1465"},
              {"label": "Rosario", "value": "C1466"},
              {"label": "Rufino", "value": "C1467"},
              {"label": "San Carlos Centro", "value": "C1468"},
              {"label": "San Cristobal", "value": "C1469"},
              {"label": "San Javier", "value": "C1470"},
              {"label": "San Jorge", "value": "C1471"},
              {"label": "San Justo", "value": "C1472"},
              {"label": "Santa Fe", "value": "C1473"},
              {"label": "Santo Tome", "value": "C1474"},
              {"label": "Sastre", "value": "C1475"},
              {"label": "Sunchales", "value": "C1476"},
              {"label": "Tacuarendi", "value": "C1477"},
              {"label": "Tostado", "value": "C1478"},
              {"label": "Totoras", "value": "C1479"},
              {"label": "Venado Tuerto", "value": "C1480"},
              {"label": "Vera", "value": "C1481"},
              {"label": "Villa Canas", "value": "C1482"},
              {"label": "Villa Constitucion", "value": "C1483"},
              {"label": "Villa Mugueta", "value": "C1484"},
              {"label": "Villa Ocampo", "value": "C1485"},
              {"label": "Villa Trinidad", "value": "C1486"}],
 "Santa Lucija": [{"label": "Santa Venera", "value": "C65915"}],
 "Santa Rosa Department": [{"label": "Barberena", "value": "C42924"},
                           {"label": "Casillas", "value": "C42925"},
                           {"label": "Chiquimulilla", "value": "C42926"},
                           {"label": "Cuilapa", "value": "C42927"},
                           {"label": "Guazacapan", "value": "C42928"},
                           {"label": "Municipio de Casillas",
                            "value": "C42929"},
                           {"label": "Municipio de Chiquimulilla",
                            "value": "C42930"},
                           {"label": "Municipio de Guazacapan",
                            "value": "C42931"},
                           {"label": "Nueva Santa Rosa", "value": "C42932"},
                           {"label": "Oratorio", "value": "C42933"},
                           {"label": "Pueblo Nuevo Vinas", "value": "C42934"},
                           {"label": "San Juan Tecuaco", "value": "C42935"},
                           {"label": "San Rafael Las Flores",
                            "value": "C42936"},
                           {"label": "Santa Cruz Naranjo", "value": "C42937"},
                           {"label": "Santa Maria Ixhuatan", "value": "C42938"},
                           {"label": "Santa Rosa de Lima", "value": "C42939"},
                           {"label": "Taxisco", "value": "C42940"}],
 "Santander": [{"label": "Aguada", "value": "C20446"},
               {"label": "Albania", "value": "C20447"},
               {"label": "Aratoca", "value": "C20448"},
               {"label": "Barbosa", "value": "C20449"},
               {"label": "Barichara", "value": "C20450"},
               {"label": "Barrancabermeja", "value": "C20451"},
               {"label": "Betulia", "value": "C20452"},
               {"label": "Bolivar", "value": "C20453"},
               {"label": "Bucaramanga", "value": "C20454"},
               {"label": "Cabrera", "value": "C20455"},
               {"label": "California", "value": "C20456"},
               {"label": "Capitanejo", "value": "C20457"},
               {"label": "Carcasi", "value": "C20458"},
               {"label": "Cepita", "value": "C20459"},
               {"label": "Cerrito", "value": "C20460"},
               {"label": "Charala", "value": "C20461"},
               {"label": "Charta", "value": "C20462"},
               {"label": "Chima", "value": "C20463"},
               {"label": "Chipata", "value": "C20464"},
               {"label": "Cimitarra", "value": "C20465"},
               {"label": "Concepcion", "value": "C20466"},
               {"label": "Confines", "value": "C20467"},
               {"label": "Contratacion", "value": "C20468"},
               {"label": "Coromoro", "value": "C20469"},
               {"label": "Curiti", "value": "C20470"},
               {"label": "El Carmen de Chucuri", "value": "C20471"},
               {"label": "El Guacamayo", "value": "C20472"},
               {"label": "El Penon", "value": "C20473"},
               {"label": "El Playon", "value": "C20474"},
               {"label": "Encino", "value": "C20475"},
               {"label": "Enciso", "value": "C20476"},
               {"label": "Florian", "value": "C20477"},
               {"label": "Floridablanca", "value": "C20478"},
               {"label": "Galan", "value": "C20479"},
               {"label": "Gambita", "value": "C20480"},
               {"label": "Giron", "value": "C20481"},
               {"label": "Guaca", "value": "C20482"},
               {"label": "Guadalupe", "value": "C20483"},
               {"label": "Guapota", "value": "C20484"},
               {"label": "Guavata", "value": "C20485"},
               {"label": "Guepsa", "value": "C20486"},
               {"label": "Hato", "value": "C20487"},
               {"label": "Jesus Maria", "value": "C20488"},
               {"label": "Jordan", "value": "C20489"},
               {"label": "La Belleza", "value": "C20490"},
               {"label": "La Paz", "value": "C20491"},
               {"label": "Landazuri", "value": "C20492"},
               {"label": "Lebrija", "value": "C20493"},
               {"label": "Los Santos", "value": "C20494"},
               {"label": "Macaravita", "value": "C20495"},
               {"label": "Malaga", "value": "C20496"},
               {"label": "Matanza", "value": "C20497"},
               {"label": "Mogotes", "value": "C20498"},
               {"label": "Molagavita", "value": "C20499"},
               {"label": "Ocamonte", "value": "C20500"},
               {"label": "Oiba", "value": "C20501"},
               {"label": "Onzaga", "value": "C20502"},
               {"label": "Palmar", "value": "C20503"},
               {"label": "Palmas del Socorro", "value": "C20504"},
               {"label": "Paramo", "value": "C20505"},
               {"label": "Piedecuesta", "value": "C20506"},
               {"label": "Pinchote", "value": "C20507"},
               {"label": "Puente Nacional", "value": "C20508"},
               {"label": "Puerto Parra", "value": "C20509"},
               {"label": "Puerto Wilches", "value": "C20510"},
               {"label": "Rionegro", "value": "C20511"},
               {"label": "Sabana de Torres", "value": "C20512"},
               {"label": "San Andres", "value": "C20513"},
               {"label": "San Benito", "value": "C20514"},
               {"label": "San Gil", "value": "C20515"},
               {"label": "San Joaquin", "value": "C20516"},
               {"label": "San Jose de Miranda", "value": "C20517"},
               {"label": "San Miguel", "value": "C20518"},
               {"label": "San Vicente de Chucuri", "value": "C20519"},
               {"label": "Santa Barbara", "value": "C20520"},
               {"label": "Santa Helena Del Opon", "value": "C20521"},
               {"label": "Simacota", "value": "C20522"},
               {"label": "Socorro", "value": "C20523"},
               {"label": "Suaita", "value": "C20524"},
               {"label": "Sucre", "value": "C20525"},
               {"label": "Surata", "value": "C20526"},
               {"label": "Tona", "value": "C20527"},
               {"label": "Valle de San Jose", "value": "C20528"},
               {"label": "Velez", "value": "C20529"},
               {"label": "Vetas", "value": "C20530"},
               {"label": "Villanueva", "value": "C20531"},
               {"label": "Zapatoca", "value": "C20532"}],
 "Santarem": [{"label": "Abrantes", "value": "C91918"},
              {"label": "Alcanede", "value": "C91919"},
              {"label": "Alcanena", "value": "C91920"},
              {"label": "Alcanhoes", "value": "C91921"},
              {"label": "Alferrarede", "value": "C91922"},
              {"label": "Almeirim", "value": "C91923"},
              {"label": "Alpiarca", "value": "C91924"},
              {"label": "Amiaes de Baixo", "value": "C91925"},
              {"label": "Azinhaga", "value": "C91926"},
              {"label": "Bemfica", "value": "C91927"},
              {"label": "Benavente", "value": "C91928"},
              {"label": "Cartaxo", "value": "C91929"},
              {"label": "Chamusca", "value": "C91930"},
              {"label": "Constancia", "value": "C91931"},
              {"label": "Coruche", "value": "C91932"},
              {"label": "Entroncamento", "value": "C91933"},
              {"label": "Fatima", "value": "C91934"},
              {"label": "Fazendas de Almeirim", "value": "C91935"},
              {"label": "Ferreira do Zezere", "value": "C91936"},
              {"label": "Golega", "value": "C91937"},
              {"label": "Macao", "value": "C91938"},
              {"label": "Madalena", "value": "C91939"},
              {"label": "Marinhais", "value": "C91940"},
              {"label": "Minde", "value": "C91941"},
              {"label": "Monsanto", "value": "C91942"},
              {"label": "Montalvinho", "value": "C91943"},
              {"label": "Montalvo", "value": "C91944"},
              {"label": "Ourem", "value": "C91945"},
              {"label": "Paialvo", "value": "C91946"},
              {"label": "Pego", "value": "C91947"},
              {"label": "Pinheiro Grande", "value": "C91948"},
              {"label": "Poceirao", "value": "C91949"},
              {"label": "Pontevel", "value": "C91950"},
              {"label": "Praia do Ribatejo", "value": "C91951"},
              {"label": "Riachos", "value": "C91952"},
              {"label": "Rio Maior", "value": "C91953"},
              {"label": "Salvaterra de Magos", "value": "C91954"},
              {"label": "Samora Correia", "value": "C91955"},
              {"label": "Santa Margarida da Coutada", "value": "C91956"},
              {"label": "Santarem", "value": "C91957"},
              {"label": "Sao Miguel de Rio Torto", "value": "C91958"},
              {"label": "Sao Vicente do Paul", "value": "C91959"},
              {"label": "Sardoal", "value": "C91960"},
              {"label": "Tomar", "value": "C91961"},
              {"label": "Torres Novas", "value": "C91962"},
              {"label": "Tramagal", "value": "C91963"},
              {"label": "Vale de Figueira", "value": "C91964"},
              {"label": "Vale de Santarem", "value": "C91965"},
              {"label": "Vila Cha de Ourique", "value": "C91966"},
              {"label": "Vila Nova da Barquinha", "value": "C91967"}],
 "Santiago Province": [{"label": "Baitoa", "value": "C24013"},
                       {"label": "Bisono", "value": "C24014"},
                       {"label": "Juncalito Abajo", "value": "C24015"},
                       {"label": "La Canela", "value": "C24016"},
                       {"label": "Licey al Medio", "value": "C24017"},
                       {"label": "Palmar Arriba", "value": "C24018"},
                       {"label": "Pedro Garcia", "value": "C24019"},
                       {"label": "Sabana Iglesia", "value": "C24020"},
                       {"label": "San Jose de Las Matas", "value": "C24021"},
                       {"label": "Santiago de los Caballeros",
                        "value": "C24022"},
                       {"label": "Santo Tomas de Janico", "value": "C24023"},
                       {"label": "Tamboril", "value": "C24024"},
                       {"label": "Villa Bisono", "value": "C24025"},
                       {"label": "Villa Gonzalez", "value": "C24026"}],
 "Santiago Rodriguez Province": [{"label": "Moncion", "value": "C24027"},
                                 {"label": "Sabaneta", "value": "C24028"},
                                 {"label": "San Ignacio de Sabaneta",
                                  "value": "C24029"}],
 "Santiago de Cuba Province": [{"label": "Contramaestre", "value": "C21833"},
                               {"label": "El Cobre", "value": "C21834"},
                               {"label": "Municipio de Palma Soriano",
                                "value": "C21835"},
                               {"label": "Municipio de Santiago de Cuba",
                                "value": "C21836"},
                               {"label": "Palma Soriano", "value": "C21837"},
                               {"label": "San Luis", "value": "C21838"},
                               {"label": "Santiago de Cuba",
                                "value": "C21839"}],
 "Santiago del Estero": [{"label": "Anatuya", "value": "C1487"},
                         {"label": "Beltran", "value": "C1488"},
                         {"label": "Campo Gallo", "value": "C1489"},
                         {"label": "Clodomira", "value": "C1490"},
                         {"label": "Colonia Dora", "value": "C1491"},
                         {"label": "Departamento de Aguirre", "value": "C1492"},
                         {"label": "Departamento de Banda", "value": "C1493"},
                         {"label": "Departamento de Choya", "value": "C1494"},
                         {"label": "Departamento de Guasayan",
                          "value": "C1495"},
                         {"label": "Departamento de Loreto", "value": "C1496"},
                         {"label": "Departamento de Moreno", "value": "C1497"},
                         {"label": "Departamento de Rio Hondo",
                          "value": "C1498"},
                         {"label": "Departamento de Robles", "value": "C1499"},
                         {"label": "Departamento de San Martin",
                          "value": "C1500"},
                         {"label": "Departamento de Sarmiento",
                          "value": "C1501"},
                         {"label": "El Hoyo", "value": "C1502"},
                         {"label": "La Banda", "value": "C1503"},
                         {"label": "Los Juries", "value": "C1504"},
                         {"label": "Los Telares", "value": "C1505"},
                         {"label": "Pampa de los Guanacos", "value": "C1506"},
                         {"label": "Quimili", "value": "C1507"},
                         {"label": "San Pedro", "value": "C1508"},
                         {"label": "Santiago del Estero", "value": "C1509"},
                         {"label": "Sumampa", "value": "C1510"},
                         {"label": "Suncho Corral", "value": "C1511"},
                         {"label": "Termas de Rio Hondo", "value": "C1512"},
                         {"label": "Tintina", "value": "C1513"},
                         {"label": "Villa Atamisqui", "value": "C1514"},
                         {"label": "Villa General Mitre", "value": "C1515"},
                         {"label": "Villa Ojo de Agua", "value": "C1516"}],
 "Santo Domingo Province": [{"label": "Boca Chica", "value": "C24030"},
                            {"label": "Santo Domingo Este", "value": "C24031"},
                            {"label": "Santo Domingo Oeste",
                             "value": "C24032"}],
 "Santo Domingo de los Tsachilas": [{"label": "Santo Domingo de los Colorados",
                                     "value": "C24199"}],
 "Sao Domingos": [{"label": "Sao Domingos", "value": "C17801"}],
 "Sao Filipe": [{"label": "Sao Filipe", "value": "C17802"}],
 "Sao Lourenco dos orgaos": [{"label": "Joao Teves", "value": "C17803"}],
 "Sao Miguel": [{"label": "Calheta", "value": "C17804"}],
 "Sao Paulo": [{"label": "Adamantina", "value": "C14970"},
               {"label": "Adolfo", "value": "C14971"},
               {"label": "Aguai", "value": "C14972"},
               {"label": "aguas da Prata", "value": "C14973"},
               {"label": "aguas de Lindoia", "value": "C14974"},
               {"label": "aguas de Santa Barbara", "value": "C14975"},
               {"label": "aguas de Sao Pedro", "value": "C14976"},
               {"label": "Agudos", "value": "C14977"},
               {"label": "Alambari", "value": "C14978"},
               {"label": "Alfredo Marcondes", "value": "C14979"},
               {"label": "Altair", "value": "C14980"},
               {"label": "Altinopolis", "value": "C14981"},
               {"label": "Alto Alegre", "value": "C14982"},
               {"label": "Aluminio", "value": "C14983"},
               {"label": "alvares Florence", "value": "C14984"},
               {"label": "alvares Machado", "value": "C14985"},
               {"label": "alvaro de Carvalho", "value": "C14986"},
               {"label": "Alvinlandia", "value": "C14987"},
               {"label": "Americana", "value": "C14988"},
               {"label": "Americo Brasiliense", "value": "C14989"},
               {"label": "Americo de Campos", "value": "C14990"},
               {"label": "Amparo", "value": "C14991"},
               {"label": "Analandia", "value": "C14992"},
               {"label": "Andradina", "value": "C14993"},
               {"label": "Angatuba", "value": "C14994"},
               {"label": "Anhembi", "value": "C14995"},
               {"label": "Anhumas", "value": "C14996"},
               {"label": "Aparecida", "value": "C14997"},
               {"label": "Aparecida dOeste", "value": "C14998"},
               {"label": "Apiai", "value": "C14999"},
               {"label": "Aracariguama", "value": "C15000"},
               {"label": "Aracatuba", "value": "C15001"},
               {"label": "Aracoiaba da Serra", "value": "C15002"},
               {"label": "Aramina", "value": "C15003"},
               {"label": "Arandu", "value": "C15004"},
               {"label": "Arapei", "value": "C15005"},
               {"label": "Araraquara", "value": "C15006"},
               {"label": "Araras", "value": "C15007"},
               {"label": "Arco-iris", "value": "C15008"},
               {"label": "Arealva", "value": "C15009"},
               {"label": "Areias", "value": "C15010"},
               {"label": "Areiopolis", "value": "C15011"},
               {"label": "Ariranha", "value": "C15012"},
               {"label": "Artur Nogueira", "value": "C15013"},
               {"label": "Aruja", "value": "C15014"},
               {"label": "Aspasia", "value": "C15015"},
               {"label": "Assis", "value": "C15016"},
               {"label": "Atibaia", "value": "C15017"},
               {"label": "Auriflama", "value": "C15018"},
               {"label": "Avai", "value": "C15019"},
               {"label": "Avanhandava", "value": "C15020"},
               {"label": "Avare", "value": "C15021"},
               {"label": "Bady Bassitt", "value": "C15022"},
               {"label": "Balbinos", "value": "C15023"},
               {"label": "Balsamo", "value": "C15024"},
               {"label": "Bananal", "value": "C15025"},
               {"label": "Barao de Antonina", "value": "C15026"},
               {"label": "Barbosa", "value": "C15027"},
               {"label": "Bariri", "value": "C15028"},
               {"label": "Barra Bonita", "value": "C15029"},
               {"label": "Barra do Chapeu", "value": "C15030"},
               {"label": "Barra do Turvo", "value": "C15031"},
               {"label": "Barretos", "value": "C15032"},
               {"label": "Barrinha", "value": "C15033"},
               {"label": "Barueri", "value": "C15034"},
               {"label": "Bastos", "value": "C15035"},
               {"label": "Batatais", "value": "C15036"},
               {"label": "Bauru", "value": "C15037"},
               {"label": "Bebedouro", "value": "C15038"},
               {"label": "Bento de Abreu", "value": "C15039"},
               {"label": "Bernardino de Campos", "value": "C15040"},
               {"label": "Bertioga", "value": "C15041"},
               {"label": "Bilac", "value": "C15042"},
               {"label": "Birigui", "value": "C15043"},
               {"label": "Biritiba Mirim", "value": "C15044"},
               {"label": "Biritiba-Mirim", "value": "C15045"},
               {"label": "Boa Esperanca do Sul", "value": "C15046"},
               {"label": "Bocaina", "value": "C15047"},
               {"label": "Bofete", "value": "C15048"},
               {"label": "Boituva", "value": "C15049"},
               {"label": "Bom Jesus dos Perdoes", "value": "C15050"},
               {"label": "Bom Sucesso de Itarare", "value": "C15051"},
               {"label": "Bora", "value": "C15052"},
               {"label": "Boraceia", "value": "C15053"},
               {"label": "Borborema", "value": "C15054"},
               {"label": "Borebi", "value": "C15055"},
               {"label": "Botucatu", "value": "C15056"},
               {"label": "Braganca Paulista", "value": "C15057"},
               {"label": "Brauna", "value": "C15058"},
               {"label": "Brejo Alegre", "value": "C15059"},
               {"label": "Brodosqui", "value": "C15060"},
               {"label": "Brodowski", "value": "C15061"},
               {"label": "Brotas", "value": "C15062"},
               {"label": "Buri", "value": "C15063"},
               {"label": "Buritama", "value": "C15064"},
               {"label": "Buritizal", "value": "C15065"},
               {"label": "Cabralia Paulista", "value": "C15066"},
               {"label": "Cabreuva", "value": "C15067"},
               {"label": "Cacapava", "value": "C15068"},
               {"label": "Cachoeira Paulista", "value": "C15069"},
               {"label": "Caconde", "value": "C15070"},
               {"label": "Cafelandia", "value": "C15071"},
               {"label": "Caiabu", "value": "C15072"},
               {"label": "Caieiras", "value": "C15073"},
               {"label": "Caiua", "value": "C15074"},
               {"label": "Cajamar", "value": "C15075"},
               {"label": "Cajati", "value": "C15076"},
               {"label": "Cajobi", "value": "C15077"},
               {"label": "Cajuru", "value": "C15078"},
               {"label": "Campina do Monte Alegre", "value": "C15079"},
               {"label": "Campinas", "value": "C15080"},
               {"label": "Campo Limpo Paulista", "value": "C15081"},
               {"label": "Campos do Jordao", "value": "C15082"},
               {"label": "Campos Novos Paulista", "value": "C15083"},
               {"label": "Cananeia", "value": "C15084"},
               {"label": "Canas", "value": "C15085"},
               {"label": "Candido Mota", "value": "C15086"},
               {"label": "Candido Rodrigues", "value": "C15087"},
               {"label": "Canitar", "value": "C15088"},
               {"label": "Capao Bonito", "value": "C15089"},
               {"label": "Capela do Alto", "value": "C15090"},
               {"label": "Capivari", "value": "C15091"},
               {"label": "Caraguatatuba", "value": "C15092"},
               {"label": "Carapicuiba", "value": "C15093"},
               {"label": "Cardoso", "value": "C15094"},
               {"label": "Casa Branca", "value": "C15095"},
               {"label": "Cassia dos Coqueiros", "value": "C15096"},
               {"label": "Castilho", "value": "C15097"},
               {"label": "Catanduva", "value": "C15098"},
               {"label": "Catigua", "value": "C15099"},
               {"label": "Cedral", "value": "C15100"},
               {"label": "Cerqueira Cesar", "value": "C15101"},
               {"label": "Cerquilho", "value": "C15102"},
               {"label": "Cesario Lange", "value": "C15103"},
               {"label": "Charqueada", "value": "C15104"},
               {"label": "Chavantes", "value": "C15105"},
               {"label": "Clementina", "value": "C15106"},
               {"label": "Colina", "value": "C15107"},
               {"label": "Colombia", "value": "C15108"},
               {"label": "Conchal", "value": "C15109"},
               {"label": "Conchas", "value": "C15110"},
               {"label": "Cordeiropolis", "value": "C15111"},
               {"label": "Coroados", "value": "C15112"},
               {"label": "Coronel Macedo", "value": "C15113"},
               {"label": "Corumbatai", "value": "C15114"},
               {"label": "Cosmopolis", "value": "C15115"},
               {"label": "Cosmorama", "value": "C15116"},
               {"label": "Cotia", "value": "C15117"},
               {"label": "Cravinhos", "value": "C15118"},
               {"label": "Cristais Paulista", "value": "C15119"},
               {"label": "Cruzalia", "value": "C15120"},
               {"label": "Cruzeiro", "value": "C15121"},
               {"label": "Cubatao", "value": "C15122"},
               {"label": "Cunha", "value": "C15123"},
               {"label": "Descalvado", "value": "C15124"},
               {"label": "Diadema", "value": "C15125"},
               {"label": "Dirce Reis", "value": "C15126"},
               {"label": "Divinolandia", "value": "C15127"},
               {"label": "Dobrada", "value": "C15128"},
               {"label": "Dois Corregos", "value": "C15129"},
               {"label": "Dolcinopolis", "value": "C15130"},
               {"label": "Dourado", "value": "C15131"},
               {"label": "Dracena", "value": "C15132"},
               {"label": "Duartina", "value": "C15133"},
               {"label": "Dumont", "value": "C15134"},
               {"label": "Echapora", "value": "C15135"},
               {"label": "Eldorado", "value": "C15136"},
               {"label": "Elias Fausto", "value": "C15137"},
               {"label": "Elisiario", "value": "C15138"},
               {"label": "Embauba", "value": "C15139"},
               {"label": "Embu", "value": "C15140"},
               {"label": "Embu das Artes", "value": "C15141"},
               {"label": "Embu Guacu", "value": "C15142"},
               {"label": "Embu-Guacu", "value": "C15143"},
               {"label": "Emilianopolis", "value": "C15144"},
               {"label": "Engenheiro Coelho", "value": "C15145"},
               {"label": "Espirito Santo do Pinhal", "value": "C15146"},
               {"label": "Espirito Santo do Turvo", "value": "C15147"},
               {"label": "Estiva Gerbi", "value": "C15148"},
               {"label": "Estrela dOeste", "value": "C15149"},
               {"label": "Estrela do Norte", "value": "C15150"},
               {"label": "Euclides da Cunha Paulista", "value": "C15151"},
               {"label": "Fartura", "value": "C15152"},
               {"label": "Fernando Prestes", "value": "C15153"},
               {"label": "Fernandopolis", "value": "C15154"},
               {"label": "Fernao", "value": "C15155"},
               {"label": "Ferraz de Vasconcelos", "value": "C15156"},
               {"label": "Flora Rica", "value": "C15157"},
               {"label": "Floreal", "value": "C15158"},
               {"label": "Florida Paulista", "value": "C15159"},
               {"label": "Florinea", "value": "C15160"},
               {"label": "Franca", "value": "C15161"},
               {"label": "Francisco Morato", "value": "C15162"},
               {"label": "Franco da Rocha", "value": "C15163"},
               {"label": "Gabriel Monteiro", "value": "C15164"},
               {"label": "Galia", "value": "C15165"},
               {"label": "Garca", "value": "C15166"},
               {"label": "Gastao Vidigal", "value": "C15167"},
               {"label": "Gaviao Peixoto", "value": "C15168"},
               {"label": "General Salgado", "value": "C15169"},
               {"label": "Getulina", "value": "C15170"},
               {"label": "Glicerio", "value": "C15171"},
               {"label": "Guaicara", "value": "C15172"},
               {"label": "Guaimbe", "value": "C15173"},
               {"label": "Guaira", "value": "C15174"},
               {"label": "Guapiacu", "value": "C15175"},
               {"label": "Guapiara", "value": "C15176"},
               {"label": "Guara", "value": "C15177"},
               {"label": "Guaracai", "value": "C15178"},
               {"label": "Guaraci", "value": "C15179"},
               {"label": "Guarani dOeste", "value": "C15180"},
               {"label": "Guaranta", "value": "C15181"},
               {"label": "Guararapes", "value": "C15182"},
               {"label": "Guararema", "value": "C15183"},
               {"label": "Guaratingueta", "value": "C15184"},
               {"label": "Guarei", "value": "C15185"},
               {"label": "Guariba", "value": "C15186"},
               {"label": "Guaruja", "value": "C15187"},
               {"label": "Guarulhos", "value": "C15188"},
               {"label": "Guatapara", "value": "C15189"},
               {"label": "Guzolandia", "value": "C15190"},
               {"label": "Herculandia", "value": "C15191"},
               {"label": "Holambra", "value": "C15192"},
               {"label": "Hortolandia", "value": "C15193"},
               {"label": "Iacanga", "value": "C15194"},
               {"label": "Iacri", "value": "C15195"},
               {"label": "Iaras", "value": "C15196"},
               {"label": "Ibate", "value": "C15197"},
               {"label": "Ibira", "value": "C15198"},
               {"label": "Ibirarema", "value": "C15199"},
               {"label": "Ibitinga", "value": "C15200"},
               {"label": "Ibiuna", "value": "C15201"},
               {"label": "Icem", "value": "C15202"},
               {"label": "Iepe", "value": "C15203"},
               {"label": "Igaracu do Tiete", "value": "C15204"},
               {"label": "Igarapava", "value": "C15205"},
               {"label": "Igarata", "value": "C15206"},
               {"label": "Iguape", "value": "C15207"},
               {"label": "Ilha Comprida", "value": "C15208"},
               {"label": "Ilha Solteira", "value": "C15209"},
               {"label": "Ilhabela", "value": "C15210"},
               {"label": "Indaiatuba", "value": "C15211"},
               {"label": "Indiana", "value": "C15212"},
               {"label": "Indiapora", "value": "C15213"},
               {"label": "Inubia Paulista", "value": "C15214"},
               {"label": "Ipaucu", "value": "C15215"},
               {"label": "Ipaussu", "value": "C15216"},
               {"label": "Ipero", "value": "C15217"},
               {"label": "Ipeuna", "value": "C15218"},
               {"label": "Ipigua", "value": "C15219"},
               {"label": "Iporanga", "value": "C15220"},
               {"label": "Ipua", "value": "C15221"},
               {"label": "Iracemapolis", "value": "C15222"},
               {"label": "Irapua", "value": "C15223"},
               {"label": "Irapuru", "value": "C15224"},
               {"label": "Itabera", "value": "C15225"},
               {"label": "Itai", "value": "C15226"},
               {"label": "Itajobi", "value": "C15227"},
               {"label": "Itaju", "value": "C15228"},
               {"label": "Itanhaem", "value": "C15229"},
               {"label": "Itaoca", "value": "C15230"},
               {"label": "Itapecerica da Serra", "value": "C15231"},
               {"label": "Itapetininga", "value": "C15232"},
               {"label": "Itapeva", "value": "C15233"},
               {"label": "Itapevi", "value": "C15234"},
               {"label": "Itapira", "value": "C15235"},
               {"label": "Itapirapua Paulista", "value": "C15236"},
               {"label": "Itapolis", "value": "C15237"},
               {"label": "Itaporanga", "value": "C15238"},
               {"label": "Itapui", "value": "C15239"},
               {"label": "Itapura", "value": "C15240"},
               {"label": "Itaquaquecetuba", "value": "C15241"},
               {"label": "Itarare", "value": "C15242"},
               {"label": "Itariri", "value": "C15243"},
               {"label": "Itatiba", "value": "C15244"},
               {"label": "Itatinga", "value": "C15245"},
               {"label": "Itirapina", "value": "C15246"},
               {"label": "Itirapua", "value": "C15247"},
               {"label": "Itobi", "value": "C15248"},
               {"label": "Itu", "value": "C15249"},
               {"label": "Itupeva", "value": "C15250"},
               {"label": "Ituverava", "value": "C15251"},
               {"label": "Jaborandi", "value": "C15252"},
               {"label": "Jaboticabal", "value": "C15253"},
               {"label": "Jacarei", "value": "C15254"},
               {"label": "Jaci", "value": "C15255"},
               {"label": "Jacupiranga", "value": "C15256"},
               {"label": "Jaguariuna", "value": "C15257"},
               {"label": "Jales", "value": "C15258"},
               {"label": "Jambeiro", "value": "C15259"},
               {"label": "Jandira", "value": "C15260"},
               {"label": "Jardim Paulista", "value": "C15261"},
               {"label": "Jardinopolis", "value": "C15262"},
               {"label": "Jarinu", "value": "C15263"},
               {"label": "Jau", "value": "C15264"},
               {"label": "Jeriquara", "value": "C15265"},
               {"label": "Joanopolis", "value": "C15266"},
               {"label": "Joao Ramalho", "value": "C15267"},
               {"label": "Jose Bonifacio", "value": "C15268"},
               {"label": "Julio Mesquita", "value": "C15269"},
               {"label": "Jumirim", "value": "C15270"},
               {"label": "Jundiai", "value": "C15271"},
               {"label": "Junqueiropolis", "value": "C15272"},
               {"label": "Juquia", "value": "C15273"},
               {"label": "Juquitiba", "value": "C15274"},
               {"label": "Lagoinha", "value": "C15275"},
               {"label": "Laranjal Paulista", "value": "C15276"},
               {"label": "Lavinia", "value": "C15277"},
               {"label": "Lavrinhas", "value": "C15278"},
               {"label": "Leme", "value": "C15279"},
               {"label": "Lencois Paulista", "value": "C15280"},
               {"label": "Limeira", "value": "C15281"},
               {"label": "Lindoia", "value": "C15282"},
               {"label": "Lins", "value": "C15283"},
               {"label": "Lorena", "value": "C15284"},
               {"label": "Lourdes", "value": "C15285"},
               {"label": "Louveira", "value": "C15286"},
               {"label": "Lucelia", "value": "C15287"},
               {"label": "Lucianopolis", "value": "C15288"},
               {"label": "Luis Antonio", "value": "C15289"},
               {"label": "Luiziania", "value": "C15290"},
               {"label": "Lupercio", "value": "C15291"},
               {"label": "Lutecia", "value": "C15292"},
               {"label": "Macatuba", "value": "C15293"},
               {"label": "Macaubal", "value": "C15294"},
               {"label": "Macedonia", "value": "C15295"},
               {"label": "Magda", "value": "C15296"},
               {"label": "Mairinque", "value": "C15297"},
               {"label": "Mairipora", "value": "C15298"},
               {"label": "Manduri", "value": "C15299"},
               {"label": "Maraba Paulista", "value": "C15300"},
               {"label": "Maracai", "value": "C15301"},
               {"label": "Marapoama", "value": "C15302"},
               {"label": "Mariapolis", "value": "C15303"},
               {"label": "Marilia", "value": "C15304"},
               {"label": "Marinopolis", "value": "C15305"},
               {"label": "Martinopolis", "value": "C15306"},
               {"label": "Matao", "value": "C15307"},
               {"label": "Maua", "value": "C15308"},
               {"label": "Mendonca", "value": "C15309"},
               {"label": "Meridiano", "value": "C15310"},
               {"label": "Mesopolis", "value": "C15311"},
               {"label": "Miguelopolis", "value": "C15312"},
               {"label": "Mineiros do Tiete", "value": "C15313"},
               {"label": "Mira Estrela", "value": "C15314"},
               {"label": "Miracatu", "value": "C15315"},
               {"label": "Mirandopolis", "value": "C15316"},
               {"label": "Mirante do Paranapanema", "value": "C15317"},
               {"label": "Mirassol", "value": "C15318"},
               {"label": "Mirassolandia", "value": "C15319"},
               {"label": "Mococa", "value": "C15320"},
               {"label": "Mogi das Cruzes", "value": "C15321"},
               {"label": "Mogi Guacu", "value": "C15322"},
               {"label": "Mogi Mirim", "value": "C15323"},
               {"label": "Mombuca", "value": "C15324"},
               {"label": "Moncoes", "value": "C15325"},
               {"label": "Mongagua", "value": "C15326"},
               {"label": "Monte Alegre do Sul", "value": "C15327"},
               {"label": "Monte Alto", "value": "C15328"},
               {"label": "Monte Aprazivel", "value": "C15329"},
               {"label": "Monte Azul Paulista", "value": "C15330"},
               {"label": "Monte Castelo", "value": "C15331"},
               {"label": "Monte Mor", "value": "C15332"},
               {"label": "Monteiro Lobato", "value": "C15333"},
               {"label": "Morro Agudo", "value": "C15334"},
               {"label": "Morungaba", "value": "C15335"},
               {"label": "Motuca", "value": "C15336"},
               {"label": "Murutinga do Sul", "value": "C15337"},
               {"label": "Nantes", "value": "C15338"},
               {"label": "Narandiba", "value": "C15339"},
               {"label": "Natividade da Serra", "value": "C15340"},
               {"label": "Nazare Paulista", "value": "C15341"},
               {"label": "Neves Paulista", "value": "C15342"},
               {"label": "Nhandeara", "value": "C15343"},
               {"label": "Nipoa", "value": "C15344"},
               {"label": "Nova Alianca", "value": "C15345"},
               {"label": "Nova Campina", "value": "C15346"},
               {"label": "Nova Canaa Paulista", "value": "C15347"},
               {"label": "Nova Castilho", "value": "C15348"},
               {"label": "Nova Europa", "value": "C15349"},
               {"label": "Nova Granada", "value": "C15350"},
               {"label": "Nova Guataporanga", "value": "C15351"},
               {"label": "Nova Independencia", "value": "C15352"},
               {"label": "Nova Luzitania", "value": "C15353"},
               {"label": "Nova Odessa", "value": "C15354"},
               {"label": "Novais", "value": "C15355"},
               {"label": "Novo Horizonte", "value": "C15356"},
               {"label": "Nuporanga", "value": "C15357"},
               {"label": "Ocaucu", "value": "C15358"},
               {"label": "oleo", "value": "C15359"},
               {"label": "Olimpia", "value": "C15360"},
               {"label": "Onda Verde", "value": "C15361"},
               {"label": "Oriente", "value": "C15362"},
               {"label": "Orindiuva", "value": "C15363"},
               {"label": "Orlandia", "value": "C15364"},
               {"label": "Osasco", "value": "C15365"},
               {"label": "Oscar Bressane", "value": "C15366"},
               {"label": "Osvaldo Cruz", "value": "C15367"},
               {"label": "Ourinhos", "value": "C15368"},
               {"label": "Ouro Verde", "value": "C15369"},
               {"label": "Ouroeste", "value": "C15370"},
               {"label": "Pacaembu", "value": "C15371"},
               {"label": "Palestina", "value": "C15372"},
               {"label": "Palmares Paulista", "value": "C15373"},
               {"label": "Palmeira dOeste", "value": "C15374"},
               {"label": "Palmital", "value": "C15375"},
               {"label": "Panorama", "value": "C15376"},
               {"label": "Paraguacu Paulista", "value": "C15377"},
               {"label": "Paraibuna", "value": "C15378"},
               {"label": "Paraiso", "value": "C15379"},
               {"label": "Paranapanema", "value": "C15380"},
               {"label": "Paranapua", "value": "C15381"},
               {"label": "Parapua", "value": "C15382"},
               {"label": "Pardinho", "value": "C15383"},
               {"label": "Pariquera Acu", "value": "C15384"},
               {"label": "Pariquera-Acu", "value": "C15385"},
               {"label": "Parisi", "value": "C15386"},
               {"label": "Patrocinio Paulista", "value": "C15387"},
               {"label": "Pauliceia", "value": "C15388"},
               {"label": "Paulinia", "value": "C15389"},
               {"label": "Paulista Florida", "value": "C15390"},
               {"label": "Paulistania", "value": "C15391"},
               {"label": "Paulo de Faria", "value": "C15392"},
               {"label": "Pederneiras", "value": "C15393"},
               {"label": "Pedra Bela", "value": "C15394"},
               {"label": "Pedranopolis", "value": "C15395"},
               {"label": "Pedregulho", "value": "C15396"},
               {"label": "Pedreira", "value": "C15397"},
               {"label": "Pedrinhas Paulista", "value": "C15398"},
               {"label": "Pedro de Toledo", "value": "C15399"},
               {"label": "Penapolis", "value": "C15400"},
               {"label": "Pereira Barreto", "value": "C15401"},
               {"label": "Pereiras", "value": "C15402"},
               {"label": "Peruibe", "value": "C15403"},
               {"label": "Piacatu", "value": "C15404"},
               {"label": "Piedade", "value": "C15405"},
               {"label": "Pilar do Sul", "value": "C15406"},
               {"label": "Pindamonhangaba", "value": "C15407"},
               {"label": "Pindorama", "value": "C15408"},
               {"label": "Pinhalzinho", "value": "C15409"},
               {"label": "Piquerobi", "value": "C15410"},
               {"label": "Piquete", "value": "C15411"},
               {"label": "Piracaia", "value": "C15412"},
               {"label": "Piracicaba", "value": "C15413"},
               {"label": "Piraju", "value": "C15414"},
               {"label": "Pirajui", "value": "C15415"},
               {"label": "Pirangi", "value": "C15416"},
               {"label": "Pirapora do Bom Jesus", "value": "C15417"},
               {"label": "Pirapozinho", "value": "C15418"},
               {"label": "Pirassununga", "value": "C15419"},
               {"label": "Piratininga", "value": "C15420"},
               {"label": "Pitangueiras", "value": "C15421"},
               {"label": "Planalto", "value": "C15422"},
               {"label": "Platina", "value": "C15423"},
               {"label": "Poa", "value": "C15424"},
               {"label": "Poloni", "value": "C15425"},
               {"label": "Pompeia", "value": "C15426"},
               {"label": "Pongai", "value": "C15427"},
               {"label": "Pontal", "value": "C15428"},
               {"label": "Pontalinda", "value": "C15429"},
               {"label": "Pontes Gestal", "value": "C15430"},
               {"label": "Populina", "value": "C15431"},
               {"label": "Porangaba", "value": "C15432"},
               {"label": "Porto Feliz", "value": "C15433"},
               {"label": "Porto Ferreira", "value": "C15434"},
               {"label": "Potim", "value": "C15435"},
               {"label": "Potirendaba", "value": "C15436"},
               {"label": "Pracinha", "value": "C15437"},
               {"label": "Pradopolis", "value": "C15438"},
               {"label": "Praia Grande", "value": "C15439"},
               {"label": "Pratania", "value": "C15440"},
               {"label": "Presidente Alves", "value": "C15441"},
               {"label": "Presidente Bernardes", "value": "C15442"},
               {"label": "Presidente Epitacio", "value": "C15443"},
               {"label": "Presidente Prudente", "value": "C15444"},
               {"label": "Presidente Venceslau", "value": "C15445"},
               {"label": "Promissao", "value": "C15446"},
               {"label": "Quadra", "value": "C15447"},
               {"label": "Quata", "value": "C15448"},
               {"label": "Queiroz", "value": "C15449"},
               {"label": "Queluz", "value": "C15450"},
               {"label": "Quintana", "value": "C15451"},
               {"label": "Rafard", "value": "C15452"},
               {"label": "Rancharia", "value": "C15453"},
               {"label": "Redencao da Serra", "value": "C15454"},
               {"label": "Regente Feijo", "value": "C15455"},
               {"label": "Reginopolis", "value": "C15456"},
               {"label": "Registro", "value": "C15457"},
               {"label": "Restinga", "value": "C15458"},
               {"label": "Ribeira", "value": "C15459"},
               {"label": "Ribeirao Bonito", "value": "C15460"},
               {"label": "Ribeirao Branco", "value": "C15461"},
               {"label": "Ribeirao Corrente", "value": "C15462"},
               {"label": "Ribeirao do Sul", "value": "C15463"},
               {"label": "Ribeirao dos indios", "value": "C15464"},
               {"label": "Ribeirao Grande", "value": "C15465"},
               {"label": "Ribeirao Pires", "value": "C15466"},
               {"label": "Ribeirao Preto", "value": "C15467"},
               {"label": "Rifaina", "value": "C15468"},
               {"label": "Rincao", "value": "C15469"},
               {"label": "Rinopolis", "value": "C15470"},
               {"label": "Rio Claro", "value": "C15471"},
               {"label": "Rio das Pedras", "value": "C15472"},
               {"label": "Rio Grande da Serra", "value": "C15473"},
               {"label": "Riolandia", "value": "C15474"},
               {"label": "Riversul", "value": "C15475"},
               {"label": "Rosana", "value": "C15476"},
               {"label": "Roseira", "value": "C15477"},
               {"label": "Rubiacea", "value": "C15478"},
               {"label": "Rubineia", "value": "C15479"},
               {"label": "Sabino", "value": "C15480"},
               {"label": "Sagres", "value": "C15481"},
               {"label": "Sales", "value": "C15482"},
               {"label": "Sales Oliveira", "value": "C15483"},
               {"label": "Salesopolis", "value": "C15484"},
               {"label": "Salmourao", "value": "C15485"},
               {"label": "Saltinho", "value": "C15486"},
               {"label": "Salto", "value": "C15487"},
               {"label": "Salto de Pirapora", "value": "C15488"},
               {"label": "Salto Grande", "value": "C15489"},
               {"label": "Sandovalina", "value": "C15490"},
               {"label": "Santa Adelia", "value": "C15491"},
               {"label": "Santa Albertina", "value": "C15492"},
               {"label": "Santa Barbara dOeste", "value": "C15493"},
               {"label": "Santa Branca", "value": "C15494"},
               {"label": "Santa Clara dOeste", "value": "C15495"},
               {"label": "Santa Cruz da Conceicao", "value": "C15496"},
               {"label": "Santa Cruz da Esperanca", "value": "C15497"},
               {"label": "Santa Cruz das Palmeiras", "value": "C15498"},
               {"label": "Santa Cruz do Rio Pardo", "value": "C15499"},
               {"label": "Santa Ernestina", "value": "C15500"},
               {"label": "Santa Fe do Sul", "value": "C15501"},
               {"label": "Santa Gertrudes", "value": "C15502"},
               {"label": "Santa Isabel", "value": "C15503"},
               {"label": "Santa Lucia", "value": "C15504"},
               {"label": "Santa Maria da Serra", "value": "C15505"},
               {"label": "Santa Mercedes", "value": "C15506"},
               {"label": "Santa Rita dOeste", "value": "C15507"},
               {"label": "Santa Rita do Passa Quatro", "value": "C15508"},
               {"label": "Santa Rosa de Viterbo", "value": "C15509"},
               {"label": "Santa Salete", "value": "C15510"},
               {"label": "Santana da Ponte Pensa", "value": "C15511"},
               {"label": "Santana de Parnaiba", "value": "C15512"},
               {"label": "Santo Anastacio", "value": "C15513"},
               {"label": "Santo Andre", "value": "C15514"},
               {"label": "Santo Antonio da Alegria", "value": "C15515"},
               {"label": "Santo Antonio de Posse", "value": "C15516"},
               {"label": "Santo Antonio do Aracangua", "value": "C15517"},
               {"label": "Santo Antonio do Jardim", "value": "C15518"},
               {"label": "Santo Antonio do Pinhal", "value": "C15519"},
               {"label": "Santo Expedito", "value": "C15520"},
               {"label": "Santopolis do Aguapei", "value": "C15521"},
               {"label": "Santos", "value": "C15522"},
               {"label": "Sao Bento do Sapucai", "value": "C15523"},
               {"label": "Sao Bernardo do Campo", "value": "C15524"},
               {"label": "Sao Caetano do Sul", "value": "C15525"},
               {"label": "Sao Carlos", "value": "C15526"},
               {"label": "Sao Francisco", "value": "C15527"},
               {"label": "Sao Joao da Boa Vista", "value": "C15528"},
               {"label": "Sao Joao das Duas Pontes", "value": "C15529"},
               {"label": "Sao Joao de Iracema", "value": "C15530"},
               {"label": "Sao Joao do Pau dAlho", "value": "C15531"},
               {"label": "Sao Joaquim da Barra", "value": "C15532"},
               {"label": "Sao Jose da Bela Vista", "value": "C15533"},
               {"label": "Sao Jose do Barreiro", "value": "C15534"},
               {"label": "Sao Jose do Rio Pardo", "value": "C15535"},
               {"label": "Sao Jose do Rio Preto", "value": "C15536"},
               {"label": "Sao Jose dos Campos", "value": "C15537"},
               {"label": "Sao Lourenco da Serra", "value": "C15538"},
               {"label": "Sao Luiz do Paraitinga", "value": "C15539"},
               {"label": "Sao Manuel", "value": "C15540"},
               {"label": "Sao Miguel Arcanjo", "value": "C15541"},
               {"label": "Sao Paulo", "value": "C15542"},
               {"label": "Sao Pedro", "value": "C15543"},
               {"label": "Sao Pedro do Turvo", "value": "C15544"},
               {"label": "Sao Roque", "value": "C15545"},
               {"label": "Sao Sebastiao", "value": "C15546"},
               {"label": "Sao Sebastiao da Grama", "value": "C15547"},
               {"label": "Sao Simao", "value": "C15548"},
               {"label": "Sao Vicente", "value": "C15549"},
               {"label": "Sarapui", "value": "C15550"},
               {"label": "Sarutaia", "value": "C15551"},
               {"label": "Sebastianopolis do Sul", "value": "C15552"},
               {"label": "Serra Azul", "value": "C15553"},
               {"label": "Serra Negra", "value": "C15554"},
               {"label": "Serrana", "value": "C15555"},
               {"label": "Sertaozinho", "value": "C15556"},
               {"label": "Sete Barras", "value": "C15557"},
               {"label": "Severinia", "value": "C15558"},
               {"label": "Silveiras", "value": "C15559"},
               {"label": "Socorro", "value": "C15560"},
               {"label": "Sorocaba", "value": "C15561"},
               {"label": "Sud Mennucci", "value": "C15562"},
               {"label": "Sumare", "value": "C15563"},
               {"label": "Suzanapolis", "value": "C15564"},
               {"label": "Suzano", "value": "C15565"},
               {"label": "Tabapua", "value": "C15566"},
               {"label": "Tabatinga", "value": "C15567"},
               {"label": "Taboao da Serra", "value": "C15568"},
               {"label": "Taciba", "value": "C15569"},
               {"label": "Taguai", "value": "C15570"},
               {"label": "Taiacu", "value": "C15571"},
               {"label": "Taiuva", "value": "C15572"},
               {"label": "Tambau", "value": "C15573"},
               {"label": "Tanabi", "value": "C15574"},
               {"label": "Tapirai", "value": "C15575"},
               {"label": "Tapiratiba", "value": "C15576"},
               {"label": "Taquaral", "value": "C15577"},
               {"label": "Taquaritinga", "value": "C15578"},
               {"label": "Taquarituba", "value": "C15579"},
               {"label": "Taquarivai", "value": "C15580"},
               {"label": "Tarabai", "value": "C15581"},
               {"label": "Taruma", "value": "C15582"},
               {"label": "Tatui", "value": "C15583"},
               {"label": "Taubate", "value": "C15584"},
               {"label": "Tejupa", "value": "C15585"},
               {"label": "Teodoro Sampaio", "value": "C15586"},
               {"label": "Terra Roxa", "value": "C15587"},
               {"label": "Tiete", "value": "C15588"},
               {"label": "Timburi", "value": "C15589"},
               {"label": "Torre de Pedra", "value": "C15590"},
               {"label": "Torrinha", "value": "C15591"},
               {"label": "Trabiju", "value": "C15592"},
               {"label": "Tremembe", "value": "C15593"},
               {"label": "Tres Fronteiras", "value": "C15594"},
               {"label": "Tuiuti", "value": "C15595"},
               {"label": "Tupa", "value": "C15596"},
               {"label": "Tupi Paulista", "value": "C15597"},
               {"label": "Turiuba", "value": "C15598"},
               {"label": "Turmalina", "value": "C15599"},
               {"label": "Ubarana", "value": "C15600"},
               {"label": "Ubatuba", "value": "C15601"},
               {"label": "Ubirajara", "value": "C15602"},
               {"label": "Uchoa", "value": "C15603"},
               {"label": "Uniao Paulista", "value": "C15604"},
               {"label": "Urania", "value": "C15605"},
               {"label": "Uru", "value": "C15606"},
               {"label": "Urupes", "value": "C15607"},
               {"label": "Valentim Gentil", "value": "C15608"},
               {"label": "Valinhos", "value": "C15609"},
               {"label": "Valparaiso", "value": "C15610"},
               {"label": "Vargem", "value": "C15611"},
               {"label": "Vargem Grande do Sul", "value": "C15612"},
               {"label": "Vargem Grande Paulista", "value": "C15613"},
               {"label": "Varzea Paulista", "value": "C15614"},
               {"label": "Vera Cruz", "value": "C15615"},
               {"label": "Vinhedo", "value": "C15616"},
               {"label": "Viradouro", "value": "C15617"},
               {"label": "Vista Alegre do Alto", "value": "C15618"},
               {"label": "Vitoria Brasil", "value": "C15619"},
               {"label": "Votorantim", "value": "C15620"},
               {"label": "Votuporanga", "value": "C15621"},
               {"label": "Zacarias", "value": "C15622"}],
 "Sao Tome Province": [{"label": "Cantagalo District", "value": "C106255"},
                       {"label": "Caue District", "value": "C106256"},
                       {"label": "Sao Tome", "value": "C106257"},
                       {"label": "Trindade", "value": "C106258"}],
 "Sao Vicente": [{"label": "Mindelo", "value": "C17805"}],
 "Sar-e Pol": [{"label": "Chiras", "value": "C81"},
               {"label": "Larkird", "value": "C82"},
               {"label": "Qalah-ye Shahr", "value": "C83"},
               {"label": "Sang-e Charak", "value": "C84"},
               {"label": "Sar-e Pul", "value": "C85"},
               {"label": "Tagaw-Bay", "value": "C86"},
               {"label": "Tukzar", "value": "C87"}],
 "Saraburi": [{"label": "Amphoe Ban Mo", "value": "C119310"},
              {"label": "Amphoe Chaloem Phra Kiat", "value": "C119311"},
              {"label": "Amphoe Don Phut", "value": "C119312"},
              {"label": "Amphoe Kaeng Khoi", "value": "C119313"},
              {"label": "Amphoe Muak Lek", "value": "C119314"},
              {"label": "Amphoe Mueang Sara Buri", "value": "C119315"},
              {"label": "Amphoe Nong Don", "value": "C119316"},
              {"label": "Amphoe Nong Khae", "value": "C119317"},
              {"label": "Amphoe Nong Saeng", "value": "C119318"},
              {"label": "Amphoe Phra Phutthabat", "value": "C119319"},
              {"label": "Amphoe Sao Hai", "value": "C119320"},
              {"label": "Amphoe Wang Muang", "value": "C119321"},
              {"label": "Amphoe Wihan Daeng", "value": "C119322"},
              {"label": "Ban Mo", "value": "C119323"},
              {"label": "Kaeng Khoi", "value": "C119324"},
              {"label": "Nong Khae", "value": "C119325"},
              {"label": "Phra Phutthabat", "value": "C119326"},
              {"label": "Saraburi", "value": "C119327"}],
 "Saraj Municipality": [{"label": "Bukovik", "value": "C65493"},
                        {"label": "Grcec", "value": "C65494"},
                        {"label": "Kondovo", "value": "C65495"},
                        {"label": "Krusopek", "value": "C65496"}],
 "Saramacca District": [{"label": "Groningen", "value": "C115471"}],
 "Saratov Oblast": [{"label": "Aleksandrov Gay", "value": "C104378"},
                    {"label": "Alekseyevka", "value": "C104379"},
                    {"label": "Arkadak", "value": "C104380"},
                    {"label": "Atkarsk", "value": "C104381"},
                    {"label": "Atkarskiy Rayon", "value": "C104382"},
                    {"label": "Balakovo", "value": "C104383"},
                    {"label": "Balashov", "value": "C104384"},
                    {"label": "Baltay", "value": "C104385"},
                    {"label": "Baltayskiy Rayon", "value": "C104386"},
                    {"label": "Bazarno-Karabulakskiy Rayon",
                     "value": "C104387"},
                    {"label": "Bazarnyy Karabulak", "value": "C104388"},
                    {"label": "Bolshoy Karay", "value": "C104389"},
                    {"label": "Cherkasskoye", "value": "C104390"},
                    {"label": "Dergachi", "value": "C104391"},
                    {"label": "Dukhovnitskoye", "value": "C104392"},
                    {"label": "Engels", "value": "C104393"},
                    {"label": "Engelsskiy Rayon", "value": "C104394"},
                    {"label": "Gornyy", "value": "C104395"},
                    {"label": "Ivanteyevka", "value": "C104396"},
                    {"label": "Kalininsk", "value": "C104397"},
                    {"label": "Kamenskiy", "value": "C104398"},
                    {"label": "Khvalynsk", "value": "C104399"},
                    {"label": "Khvalynskiy Rayon", "value": "C104400"},
                    {"label": "Khvatovka", "value": "C104401"},
                    {"label": "Kormezhka", "value": "C104402"},
                    {"label": "Krasnoarmeysk", "value": "C104403"},
                    {"label": "Krasnyy Kut", "value": "C104404"},
                    {"label": "Krasnyy Oktyabr", "value": "C104405"},
                    {"label": "Krasnyy Yar", "value": "C104406"},
                    {"label": "Lysyye Gory", "value": "C104407"},
                    {"label": "Marks", "value": "C104408"},
                    {"label": "Marksovskiy Rayon", "value": "C104409"},
                    {"label": "Mokrous", "value": "C104410"},
                    {"label": "Novouzensk", "value": "C104411"},
                    {"label": "Novyye Burasy", "value": "C104412"},
                    {"label": "Perelyub", "value": "C104413"},
                    {"label": "Petrovsk", "value": "C104414"},
                    {"label": "Pinerovka", "value": "C104415"},
                    {"label": "Piterka", "value": "C104416"},
                    {"label": "Podlesnoye", "value": "C104417"},
                    {"label": "Privolzhskiy", "value": "C104418"},
                    {"label": "Privolzhskoye", "value": "C104419"},
                    {"label": "Pugachev", "value": "C104420"},
                    {"label": "Pushkino", "value": "C104421"},
                    {"label": "Repnoye", "value": "C104422"},
                    {"label": "Romanovka", "value": "C104423"},
                    {"label": "Rovnoye", "value": "C104424"},
                    {"label": "Rtishchevo", "value": "C104425"},
                    {"label": "Samoylovka", "value": "C104426"},
                    {"label": "Saratov", "value": "C104427"},
                    {"label": "Saratovskiy Rayon", "value": "C104428"},
                    {"label": "Sennoy", "value": "C104429"},
                    {"label": "Shikhany", "value": "C104430"},
                    {"label": "Shumeyka", "value": "C104431"},
                    {"label": "Sinodskoye", "value": "C104432"},
                    {"label": "Sokolovyy", "value": "C104433"},
                    {"label": "Sovetskoye", "value": "C104434"},
                    {"label": "Staryye Ozinki", "value": "C104435"},
                    {"label": "Stepnoye", "value": "C104436"},
                    {"label": "Svetlyy", "value": "C104437"},
                    {"label": "Svobodnyy", "value": "C104438"},
                    {"label": "Tatishchevo", "value": "C104439"},
                    {"label": "Tersa", "value": "C104440"},
                    {"label": "Turki", "value": "C104441"},
                    {"label": "Volsk", "value": "C104442"},
                    {"label": "Volskiy Rayon", "value": "C104443"},
                    {"label": "Voskresenskiy Rayon", "value": "C104444"},
                    {"label": "Voskresenskoye", "value": "C104445"},
                    {"label": "Yekaterinovka", "value": "C104446"},
                    {"label": "Yelshanka", "value": "C104447"},
                    {"label": "Yershov", "value": "C104448"}],
 "Sarawak": [{"label": "Bintulu", "value": "C65755"},
             {"label": "Data Kakus", "value": "C65756"},
             {"label": "Kapit", "value": "C65757"},
             {"label": "Kuching", "value": "C65758"},
             {"label": "Lawas", "value": "C65759"},
             {"label": "Lidung Jelo", "value": "C65760"},
             {"label": "Limbang", "value": "C65761"},
             {"label": "Long Ampan Aing or Abanang", "value": "C65762"},
             {"label": "Marudi", "value": "C65763"},
             {"label": "Miri", "value": "C65764"},
             {"label": "Mukah", "value": "C65765"},
             {"label": "Sarikei", "value": "C65766"},
             {"label": "Sibu", "value": "C65767"},
             {"label": "Simanggang", "value": "C65768"}],
 "Sardinia": [{"label": "Abbasanta", "value": "C58967"},
              {"label": "Aggius", "value": "C58968"},
              {"label": "Aglientu", "value": "C58969"},
              {"label": "Aidomaggiore", "value": "C58970"},
              {"label": "Ala dei Sardi", "value": "C58971"},
              {"label": "Albagiara", "value": "C58972"},
              {"label": "Ales", "value": "C58973"},
              {"label": "Alghero", "value": "C58974"},
              {"label": "Allai", "value": "C58975"},
              {"label": "Anela", "value": "C58976"},
              {"label": "Arbatax", "value": "C58977"},
              {"label": "Arborea", "value": "C58978"},
              {"label": "Arbus", "value": "C58979"},
              {"label": "Ardara", "value": "C58980"},
              {"label": "Ardauli", "value": "C58981"},
              {"label": "Aritzo", "value": "C58982"},
              {"label": "Armungia", "value": "C58983"},
              {"label": "Arzachena", "value": "C58984"},
              {"label": "Arzana", "value": "C58985"},
              {"label": "Assemini", "value": "C58986"},
              {"label": "Assolo", "value": "C58987"},
              {"label": "Asuni", "value": "C58988"},
              {"label": "Atzara", "value": "C58989"},
              {"label": "Austis", "value": "C58990"},
              {"label": "Bacu Abis", "value": "C58991"},
              {"label": "Badesi", "value": "C58992"},
              {"label": "Ballao", "value": "C58993"},
              {"label": "Banari", "value": "C58994"},
              {"label": "Bancali", "value": "C58995"},
              {"label": "Baradili", "value": "C58996"},
              {"label": "Baratili San Pietro", "value": "C58997"},
              {"label": "Baressa", "value": "C58998"},
              {"label": "Bari Sardo", "value": "C58999"},
              {"label": "Barrali", "value": "C59000"},
              {"label": "Barumini", "value": "C59001"},
              {"label": "Bauladu", "value": "C59002"},
              {"label": "Baunei", "value": "C59003"},
              {"label": "Belvi", "value": "C59004"},
              {"label": "Benetutti", "value": "C59005"},
              {"label": "Berchidda", "value": "C59006"},
              {"label": "Bessude", "value": "C59007"},
              {"label": "Bidoni", "value": "C59008"},
              {"label": "Birori", "value": "C59009"},
              {"label": "Bitti", "value": "C59010"},
              {"label": "Bolotana", "value": "C59011"},
              {"label": "Bonarcado", "value": "C59012"},
              {"label": "Bonnanaro", "value": "C59013"},
              {"label": "Bono", "value": "C59014"},
              {"label": "Bonorva", "value": "C59015"},
              {"label": "Boroneddu", "value": "C59016"},
              {"label": "Borore", "value": "C59017"},
              {"label": "Bortigali", "value": "C59018"},
              {"label": "Bortigiadas", "value": "C59019"},
              {"label": "Borutta", "value": "C59020"},
              {"label": "Bosa", "value": "C59021"},
              {"label": "Bottidda", "value": "C59022"},
              {"label": "Budduso", "value": "C59023"},
              {"label": "Budoni", "value": "C59024"},
              {"label": "Buggerru", "value": "C59025"},
              {"label": "Bultei", "value": "C59026"},
              {"label": "Bulzi", "value": "C59027"},
              {"label": "Burcei", "value": "C59028"},
              {"label": "Burgos", "value": "C59029"},
              {"label": "Busachi", "value": "C59030"},
              {"label": "Cabras", "value": "C59031"},
              {"label": "Cagliari", "value": "C59032"},
              {"label": "Cala Gonone", "value": "C59033"},
              {"label": "Calangianus", "value": "C59034"},
              {"label": "Calasetta", "value": "C59035"},
              {"label": "Capoterra", "value": "C59036"},
              {"label": "Carbonia", "value": "C59037"},
              {"label": "Cardedu", "value": "C59038"},
              {"label": "Cargeghe", "value": "C59039"},
              {"label": "Carloforte", "value": "C59040"},
              {"label": "Castelsardo", "value": "C59041"},
              {"label": "Castiadas", "value": "C59042"},
              {"label": "Cheremule", "value": "C59043"},
              {"label": "Chiaramonti", "value": "C59044"},
              {"label": "Codaruina", "value": "C59045"},
              {"label": "Codrongianos", "value": "C59046"},
              {"label": "Collinas", "value": "C59047"},
              {"label": "Cortoghiana", "value": "C59048"},
              {"label": "Cossoine", "value": "C59049"},
              {"label": "Cuglieri", "value": "C59050"},
              {"label": "Curcuris", "value": "C59051"},
              {"label": "Decimomannu", "value": "C59052"},
              {"label": "Decimoputzu", "value": "C59053"},
              {"label": "Desulo", "value": "C59054"},
              {"label": "Dolianova", "value": "C59055"},
              {"label": "Domus de Maria", "value": "C59056"},
              {"label": "Domusnovas", "value": "C59057"},
              {"label": "Donigala Fenugheddu", "value": "C59058"},
              {"label": "Donori", "value": "C59059"},
              {"label": "Dorgali", "value": "C59060"},
              {"label": "Dualchi", "value": "C59061"},
              {"label": "Elini", "value": "C59062"},
              {"label": "Elmas", "value": "C59063"},
              {"label": "Erula", "value": "C59064"},
              {"label": "Escalaplano", "value": "C59065"},
              {"label": "Escolca", "value": "C59066"},
              {"label": "Esporlatu", "value": "C59067"},
              {"label": "Esterzili", "value": "C59068"},
              {"label": "Florinas", "value": "C59069"},
              {"label": "Fluminimaggiore", "value": "C59070"},
              {"label": "Flussio", "value": "C59071"},
              {"label": "Fonni", "value": "C59072"},
              {"label": "Fordongianus", "value": "C59073"},
              {"label": "Furtei", "value": "C59074"},
              {"label": "Gadoni", "value": "C59075"},
              {"label": "Gairo SantElena", "value": "C59076"},
              {"label": "Galtelli", "value": "C59077"},
              {"label": "Gavoi", "value": "C59078"},
              {"label": "Genoni", "value": "C59079"},
              {"label": "Genuri", "value": "C59080"},
              {"label": "Gergei", "value": "C59081"},
              {"label": "Gesico", "value": "C59082"},
              {"label": "Gesturi", "value": "C59083"},
              {"label": "Ghilarza", "value": "C59084"},
              {"label": "Giave", "value": "C59085"},
              {"label": "Giba", "value": "C59086"},
              {"label": "Girasole", "value": "C59087"},
              {"label": "Golfo Aranci", "value": "C59088"},
              {"label": "Goni", "value": "C59089"},
              {"label": "Gonnesa", "value": "C59090"},
              {"label": "Gonnoscodina", "value": "C59091"},
              {"label": "Gonnosfanadiga", "value": "C59092"},
              {"label": "Gonnosno", "value": "C59093"},
              {"label": "Gonnostramatza", "value": "C59094"},
              {"label": "Guamaggiore", "value": "C59095"},
              {"label": "Guasila", "value": "C59096"},
              {"label": "Guspini", "value": "C59097"},
              {"label": "Iglesias", "value": "C59098"},
              {"label": "Ilbono", "value": "C59099"},
              {"label": "Illorai", "value": "C59100"},
              {"label": "Irgoli", "value": "C59101"},
              {"label": "Isili", "value": "C59102"},
              {"label": "Ittireddu", "value": "C59103"},
              {"label": "Ittiri", "value": "C59104"},
              {"label": "Jerzu", "value": "C59105"},
              {"label": "La Caletta", "value": "C59106"},
              {"label": "La Maddalena", "value": "C59107"},
              {"label": "Laconi", "value": "C59108"},
              {"label": "Laerru", "value": "C59109"},
              {"label": "Lanusei", "value": "C59110"},
              {"label": "Las Plassas", "value": "C59111"},
              {"label": "Lei", "value": "C59112"},
              {"label": "Li Punti-San Giovanni", "value": "C59113"},
              {"label": "Loceri", "value": "C59114"},
              {"label": "Loculi", "value": "C59115"},
              {"label": "Lode", "value": "C59116"},
              {"label": "Lodine", "value": "C59117"},
              {"label": "Loiri Porto San Paolo", "value": "C59118"},
              {"label": "Lotzorai", "value": "C59119"},
              {"label": "Lu Bagnu", "value": "C59120"},
              {"label": "Lula", "value": "C59121"},
              {"label": "Lunamatrona", "value": "C59122"},
              {"label": "Luogosanto", "value": "C59123"},
              {"label": "Luras", "value": "C59124"},
              {"label": "Macomer", "value": "C59125"},
              {"label": "Magomadas", "value": "C59126"},
              {"label": "Mamoiada", "value": "C59127"},
              {"label": "Mandas", "value": "C59128"},
              {"label": "Mara", "value": "C59129"},
              {"label": "Maracalagonis", "value": "C59130"},
              {"label": "Marrubiu", "value": "C59131"},
              {"label": "Martis", "value": "C59132"},
              {"label": "Masainas", "value": "C59133"},
              {"label": "Masullas", "value": "C59134"},
              {"label": "Meana Sardo", "value": "C59135"},
              {"label": "Milis", "value": "C59136"},
              {"label": "Modolo", "value": "C59137"},
              {"label": "Mogorella", "value": "C59138"},
              {"label": "Mogoro", "value": "C59139"},
              {"label": "Monastir", "value": "C59140"},
              {"label": "Monserrato", "value": "C59141"},
              {"label": "Monteleone Rocca Doria", "value": "C59142"},
              {"label": "Monti", "value": "C59143"},
              {"label": "Montresta", "value": "C59144"},
              {"label": "Mores", "value": "C59145"},
              {"label": "Morgongiori", "value": "C59146"},
              {"label": "Muravera", "value": "C59147"},
              {"label": "Muros", "value": "C59148"},
              {"label": "Musei", "value": "C59149"},
              {"label": "Narbolia", "value": "C59150"},
              {"label": "Narcao", "value": "C59151"},
              {"label": "Neoneli", "value": "C59152"},
              {"label": "Noragugume", "value": "C59153"},
              {"label": "Norbello", "value": "C59154"},
              {"label": "Nughedu San Nicolo", "value": "C59155"},
              {"label": "Nughedu Santa Vittoria", "value": "C59156"},
              {"label": "Nule", "value": "C59157"},
              {"label": "Nulvi", "value": "C59158"},
              {"label": "Nuoro", "value": "C59159"},
              {"label": "Nurachi", "value": "C59160"},
              {"label": "Nuragus", "value": "C59161"},
              {"label": "Nurallao", "value": "C59162"},
              {"label": "Nuraminis", "value": "C59163"},
              {"label": "Nuraxinieddu", "value": "C59164"},
              {"label": "Nureci", "value": "C59165"},
              {"label": "Nurri", "value": "C59166"},
              {"label": "Nuxis", "value": "C59167"},
              {"label": "Olbia", "value": "C59168"},
              {"label": "Olia Speciosa", "value": "C59169"},
              {"label": "Oliena", "value": "C59170"},
              {"label": "Ollastra", "value": "C59171"},
              {"label": "Ollolai", "value": "C59172"},
              {"label": "Olmedo", "value": "C59173"},
              {"label": "Olzai", "value": "C59174"},
              {"label": "Onani", "value": "C59175"},
              {"label": "Onifai", "value": "C59176"},
              {"label": "Oniferi", "value": "C59177"},
              {"label": "Orani", "value": "C59178"},
              {"label": "Orgosolo", "value": "C59179"},
              {"label": "Oristano", "value": "C59180"},
              {"label": "Orosei", "value": "C59181"},
              {"label": "Orotelli", "value": "C59182"},
              {"label": "Orroli", "value": "C59183"},
              {"label": "Ortacesus", "value": "C59184"},
              {"label": "Ortueri", "value": "C59185"},
              {"label": "Orune", "value": "C59186"},
              {"label": "Oschiri", "value": "C59187"},
              {"label": "Osidda", "value": "C59188"},
              {"label": "Osilo", "value": "C59189"},
              {"label": "Osini", "value": "C59190"},
              {"label": "Ossi", "value": "C59191"},
              {"label": "Ottana", "value": "C59192"},
              {"label": "Ottava", "value": "C59193"},
              {"label": "Ovodda", "value": "C59194"},
              {"label": "Ozieri", "value": "C59195"},
              {"label": "Pabillonis", "value": "C59196"},
              {"label": "Padria", "value": "C59197"},
              {"label": "Padru", "value": "C59198"},
              {"label": "Palau", "value": "C59199"},
              {"label": "Palmas Arborea", "value": "C59200"},
              {"label": "Pattada", "value": "C59201"},
              {"label": "Pau", "value": "C59202"},
              {"label": "Pauli Arbarei", "value": "C59203"},
              {"label": "Paulilatino", "value": "C59204"},
              {"label": "Perdasdefogu", "value": "C59205"},
              {"label": "Perdaxius", "value": "C59206"},
              {"label": "Perfugas", "value": "C59207"},
              {"label": "Pimentel", "value": "C59208"},
              {"label": "Piscinas", "value": "C59209"},
              {"label": "Ploaghe", "value": "C59210"},
              {"label": "Poggio dei Pini", "value": "C59211"},
              {"label": "Pompu", "value": "C59212"},
              {"label": "Porto Cervo", "value": "C59213"},
              {"label": "Porto Torres", "value": "C59214"},
              {"label": "Portoscuso", "value": "C59215"},
              {"label": "Posada", "value": "C59216"},
              {"label": "Pozzomaggiore", "value": "C59217"},
              {"label": "Provincia di Cagliari", "value": "C59218"},
              {"label": "Provincia di Nuoro", "value": "C59219"},
              {"label": "Provincia di Oristano", "value": "C59220"},
              {"label": "Provincia di Sassari", "value": "C59221"},
              {"label": "Pula", "value": "C59222"},
              {"label": "Putifigari", "value": "C59223"},
              {"label": "Quartu SantElena", "value": "C59224"},
              {"label": "Quartucciu", "value": "C59225"},
              {"label": "Riola Sardo", "value": "C59226"},
              {"label": "Romana", "value": "C59227"},
              {"label": "Ruinas", "value": "C59228"},
              {"label": "Sadali", "value": "C59229"},
              {"label": "Sagama", "value": "C59230"},
              {"label": "Samassi", "value": "C59231"},
              {"label": "Samatzai", "value": "C59232"},
              {"label": "Samugheo", "value": "C59233"},
              {"label": "San Basilio", "value": "C59234"},
              {"label": "San Gavino Monreale", "value": "C59235"},
              {"label": "San Giovanni Suergiu", "value": "C59236"},
              {"label": "San Nicola", "value": "C59237"},
              {"label": "San Nicolo dArcidano", "value": "C59238"},
              {"label": "San NicoloGerrei", "value": "C59239"},
              {"label": "San Sperate", "value": "C59240"},
              {"label": "San Teodoro", "value": "C59241"},
              {"label": "San Vero Milis", "value": "C59242"},
              {"label": "San Vito", "value": "C59243"},
              {"label": "Sanluri", "value": "C59244"},
              {"label": "SantAndrea Frius", "value": "C59245"},
              {"label": "SantAnna Arresi", "value": "C59246"},
              {"label": "SantAntioco", "value": "C59247"},
              {"label": "SantAntonio di Gallura", "value": "C59248"},
              {"label": "Santa Giusta", "value": "C59249"},
              {"label": "Santa Maria Coghinas", "value": "C59250"},
              {"label": "Santa Maria Navarrese", "value": "C59251"},
              {"label": "Santa Teresa Gallura", "value": "C59252"},
              {"label": "Santadi", "value": "C59253"},
              {"label": "Santu Lussurgiu", "value": "C59254"},
              {"label": "Sardara", "value": "C59255"},
              {"label": "Sarroch", "value": "C59256"},
              {"label": "Sarule", "value": "C59257"},
              {"label": "Sassari", "value": "C59258"},
              {"label": "Scano di Montiferro", "value": "C59259"},
              {"label": "Sedilo", "value": "C59260"},
              {"label": "Sedini", "value": "C59261"},
              {"label": "Segariu", "value": "C59262"},
              {"label": "Selargius", "value": "C59263"},
              {"label": "Selegas", "value": "C59264"},
              {"label": "Semestene", "value": "C59265"},
              {"label": "Seneghe", "value": "C59266"},
              {"label": "Senis", "value": "C59267"},
              {"label": "Sennariolo", "value": "C59268"},
              {"label": "Sennori", "value": "C59269"},
              {"label": "Senorbi", "value": "C59270"},
              {"label": "Serdiana", "value": "C59271"},
              {"label": "Serramanna", "value": "C59272"},
              {"label": "Serrenti", "value": "C59273"},
              {"label": "Serri", "value": "C59274"},
              {"label": "Sestu", "value": "C59275"},
              {"label": "Settimo San Pietro", "value": "C59276"},
              {"label": "Setzu", "value": "C59277"},
              {"label": "Seui", "value": "C59278"},
              {"label": "Seulo", "value": "C59279"},
              {"label": "Siamaggiore", "value": "C59280"},
              {"label": "Siamanna", "value": "C59281"},
              {"label": "Siapiccia", "value": "C59282"},
              {"label": "Siddi", "value": "C59283"},
              {"label": "Silanus", "value": "C59284"},
              {"label": "Sili", "value": "C59285"},
              {"label": "Siligo", "value": "C59286"},
              {"label": "Siliqua", "value": "C59287"},
              {"label": "Silius", "value": "C59288"},
              {"label": "Simala", "value": "C59289"},
              {"label": "Simaxis", "value": "C59290"},
              {"label": "Sindia", "value": "C59291"},
              {"label": "Sini", "value": "C59292"},
              {"label": "Siniscola", "value": "C59293"},
              {"label": "Sinnai", "value": "C59294"},
              {"label": "Siris", "value": "C59295"},
              {"label": "Siurgus Donigala", "value": "C59296"},
              {"label": "Soddi", "value": "C59297"},
              {"label": "Solanas", "value": "C59298"},
              {"label": "Solarussa", "value": "C59299"},
              {"label": "Soleminis", "value": "C59300"},
              {"label": "Sorgono", "value": "C59301"},
              {"label": "Sorradile", "value": "C59302"},
              {"label": "Sorso", "value": "C59303"},
              {"label": "Stintino", "value": "C59304"},
              {"label": "Su Planu", "value": "C59305"},
              {"label": "Suelli", "value": "C59306"},
              {"label": "Suni", "value": "C59307"},
              {"label": "Tadasuni", "value": "C59308"},
              {"label": "Talana", "value": "C59309"},
              {"label": "Telti", "value": "C59310"},
              {"label": "Tempio Pausania", "value": "C59311"},
              {"label": "Tergu", "value": "C59312"},
              {"label": "Terralba", "value": "C59313"},
              {"label": "Tertenia", "value": "C59314"},
              {"label": "Teti", "value": "C59315"},
              {"label": "Teulada", "value": "C59316"},
              {"label": "Thiesi", "value": "C59317"},
              {"label": "Tiana", "value": "C59318"},
              {"label": "Tinnura", "value": "C59319"},
              {"label": "Tissi", "value": "C59320"},
              {"label": "Tonara", "value": "C59321"},
              {"label": "Torpe", "value": "C59322"},
              {"label": "Torralba", "value": "C59323"},
              {"label": "Tortoli", "value": "C59324"},
              {"label": "Tramatza", "value": "C59325"},
              {"label": "Tratalias", "value": "C59326"},
              {"label": "Tresnuraghes", "value": "C59327"},
              {"label": "Triei", "value": "C59328"},
              {"label": "Trinita dAgultu", "value": "C59329"},
              {"label": "Trinita dAgultu e Vignola", "value": "C59330"},
              {"label": "Tuili", "value": "C59331"},
              {"label": "Tula", "value": "C59332"},
              {"label": "Turri", "value": "C59333"},
              {"label": "Ula Tirso", "value": "C59334"},
              {"label": "Ulassai", "value": "C59335"},
              {"label": "Uras", "value": "C59336"},
              {"label": "Uri", "value": "C59337"},
              {"label": "Urzulei", "value": "C59338"},
              {"label": "Usellus", "value": "C59339"},
              {"label": "Usini", "value": "C59340"},
              {"label": "Ussana", "value": "C59341"},
              {"label": "Ussaramanna", "value": "C59342"},
              {"label": "Ussassai", "value": "C59343"},
              {"label": "Uta", "value": "C59344"},
              {"label": "Valledoria", "value": "C59345"},
              {"label": "Vallermosa", "value": "C59346"},
              {"label": "Viddalba", "value": "C59347"},
              {"label": "Villa San Pietro", "value": "C59348"},
              {"label": "Villa SantAntonio", "value": "C59349"},
              {"label": "Villa Verde", "value": "C59350"},
              {"label": "Villacidro", "value": "C59351"},
              {"label": "Villagrande Strisaili", "value": "C59352"},
              {"label": "Villamar", "value": "C59353"},
              {"label": "Villamassargia", "value": "C59354"},
              {"label": "Villanova Monteleone", "value": "C59355"},
              {"label": "Villanova Truschedu", "value": "C59356"},
              {"label": "Villanova Tulo", "value": "C59357"},
              {"label": "Villanovaforru", "value": "C59358"},
              {"label": "Villanovafranca", "value": "C59359"},
              {"label": "Villaperuccio", "value": "C59360"},
              {"label": "Villaputzu", "value": "C59361"},
              {"label": "Villasalto", "value": "C59362"},
              {"label": "Villasimius", "value": "C59363"},
              {"label": "Villasor", "value": "C59364"},
              {"label": "Villaspeciosa", "value": "C59365"},
              {"label": "Villaurbana", "value": "C59366"},
              {"label": "Zeddiani", "value": "C59367"},
              {"label": "Zerfaliu", "value": "C59368"}],
 "Sarpang District": [{"label": "Sarpang", "value": "C9646"}],
 "Saskatchewan": [{"label": "Assiniboia", "value": "C17726"},
                  {"label": "Biggar", "value": "C17727"},
                  {"label": "Canora", "value": "C17728"},
                  {"label": "Carlyle", "value": "C17729"},
                  {"label": "Dalmeny", "value": "C17730"},
                  {"label": "Esterhazy", "value": "C17731"},
                  {"label": "Estevan", "value": "C17732"},
                  {"label": "Foam Lake", "value": "C17733"},
                  {"label": "Gravelbourg", "value": "C17734"},
                  {"label": "Hudson Bay", "value": "C17735"},
                  {"label": "Humboldt", "value": "C17736"},
                  {"label": "Indian Head", "value": "C17737"},
                  {"label": "Kamsack", "value": "C17738"},
                  {"label": "Kerrobert", "value": "C17739"},
                  {"label": "Kindersley", "value": "C17740"},
                  {"label": "La Ronge", "value": "C17741"},
                  {"label": "Langenburg", "value": "C17742"},
                  {"label": "Langham", "value": "C17743"},
                  {"label": "Lanigan", "value": "C17744"},
                  {"label": "Lumsden", "value": "C17745"},
                  {"label": "Macklin", "value": "C17746"},
                  {"label": "Maple Creek", "value": "C17747"},
                  {"label": "Martensville", "value": "C17748"},
                  {"label": "Meadow Lake", "value": "C17749"},
                  {"label": "Melfort", "value": "C17750"},
                  {"label": "Melville", "value": "C17751"},
                  {"label": "Moose Jaw", "value": "C17752"},
                  {"label": "Moosomin", "value": "C17753"},
                  {"label": "Nipawin", "value": "C17754"},
                  {"label": "North Battleford", "value": "C17755"},
                  {"label": "Outlook", "value": "C17756"},
                  {"label": "Oxbow", "value": "C17757"},
                  {"label": "Pelican Narrows", "value": "C17758"},
                  {"label": "Pilot Butte", "value": "C17759"},
                  {"label": "Preeceville", "value": "C17760"},
                  {"label": "Prince Albert", "value": "C17761"},
                  {"label": "Regina", "value": "C17762"},
                  {"label": "Regina Beach", "value": "C17763"},
                  {"label": "Rosetown", "value": "C17764"},
                  {"label": "Rosthern", "value": "C17765"},
                  {"label": "Saskatoon", "value": "C17766"},
                  {"label": "Shaunavon", "value": "C17767"},
                  {"label": "Shellbrook", "value": "C17768"},
                  {"label": "Swift Current", "value": "C17769"},
                  {"label": "Tisdale", "value": "C17770"},
                  {"label": "Unity", "value": "C17771"},
                  {"label": "Wadena", "value": "C17772"},
                  {"label": "Warman", "value": "C17773"},
                  {"label": "Watrous", "value": "C17774"},
                  {"label": "Weyburn", "value": "C17775"},
                  {"label": "White City", "value": "C17776"},
                  {"label": "Wilkie", "value": "C17777"},
                  {"label": "Wynyard", "value": "C17778"},
                  {"label": "Yorkton", "value": "C17779"}],
 "Sassandra-Marahoue District": [{"label": "Bouafle", "value": "C20980"},
                                 {"label": "Daloa", "value": "C20981"},
                                 {"label": "Haut-Sassandra", "value": "C20982"},
                                 {"label": "Issia", "value": "C20983"},
                                 {"label": "Marahoue", "value": "C20984"},
                                 {"label": "Vavoua", "value": "C20985"},
                                 {"label": "Zuenoula", "value": "C20986"}],
 "Satakunta": [{"label": "Eura", "value": "C25080"},
               {"label": "Eurajoki", "value": "C25081"},
               {"label": "Harjavalta", "value": "C25082"},
               {"label": "Huittinen", "value": "C25083"},
               {"label": "Jamijarvi", "value": "C25084"},
               {"label": "Kankaanpaa", "value": "C25085"},
               {"label": "Karvia", "value": "C25086"},
               {"label": "Kiukainen", "value": "C25087"},
               {"label": "Kokemaki", "value": "C25088"},
               {"label": "Koylio", "value": "C25089"},
               {"label": "Kullaa", "value": "C25090"},
               {"label": "Langelmaki", "value": "C25091"},
               {"label": "Lappi", "value": "C25092"},
               {"label": "Lavia", "value": "C25093"},
               {"label": "Luvia", "value": "C25094"},
               {"label": "Merikarvia", "value": "C25095"},
               {"label": "Nakkila", "value": "C25096"},
               {"label": "Noormarkku", "value": "C25097"},
               {"label": "Pomarkku", "value": "C25098"},
               {"label": "Pori", "value": "C25099"},
               {"label": "Rauma", "value": "C25100"},
               {"label": "Sakyla", "value": "C25101"},
               {"label": "Siikainen", "value": "C25102"},
               {"label": "Ulvila", "value": "C25103"},
               {"label": "Vampula", "value": "C25104"}],
 "Satu Mare County": [{"label": "Acas", "value": "C98434"},
                      {"label": "Agris", "value": "C98435"},
                      {"label": "Andrid", "value": "C98436"},
                      {"label": "Apa", "value": "C98437"},
                      {"label": "Ardud", "value": "C98438"},
                      {"label": "Babta", "value": "C98439"},
                      {"label": "Barsau de Sus", "value": "C98440"},
                      {"label": "Batarci", "value": "C98441"},
                      {"label": "Beltiug", "value": "C98442"},
                      {"label": "Berveni", "value": "C98443"},
                      {"label": "Bixad", "value": "C98444"},
                      {"label": "Bogdand", "value": "C98445"},
                      {"label": "Boinesti", "value": "C98446"},
                      {"label": "Botiz", "value": "C98447"},
                      {"label": "Calinesti-Oas", "value": "C98448"},
                      {"label": "Camarzana", "value": "C98449"},
                      {"label": "Camin", "value": "C98450"},
                      {"label": "Capleni", "value": "C98451"},
                      {"label": "Caraseu", "value": "C98452"},
                      {"label": "Carei", "value": "C98453"},
                      {"label": "Cauas", "value": "C98454"},
                      {"label": "Cehal", "value": "C98455"},
                      {"label": "Certeze", "value": "C98456"},
                      {"label": "Cidreag", "value": "C98457"},
                      {"label": "Ciumesti", "value": "C98458"},
                      {"label": "Comuna Acas", "value": "C98459"},
                      {"label": "Comuna Agris", "value": "C98460"},
                      {"label": "Comuna Andrid", "value": "C98461"},
                      {"label": "Comuna Apa", "value": "C98462"},
                      {"label": "Comuna Barsau", "value": "C98463"},
                      {"label": "Comuna Batarci", "value": "C98464"},
                      {"label": "Comuna Beltiug", "value": "C98465"},
                      {"label": "Comuna Berveni", "value": "C98466"},
                      {"label": "Comuna Bixad", "value": "C98467"},
                      {"label": "Comuna Bogdand", "value": "C98468"},
                      {"label": "Comuna Botiz", "value": "C98469"},
                      {"label": "Comuna Calinesti-Oas", "value": "C98470"},
                      {"label": "Comuna Camarzana", "value": "C98471"},
                      {"label": "Comuna Camin", "value": "C98472"},
                      {"label": "Comuna Capleni", "value": "C98473"},
                      {"label": "Comuna Cauas", "value": "C98474"},
                      {"label": "Comuna Cehal", "value": "C98475"},
                      {"label": "Comuna Certeze", "value": "C98476"},
                      {"label": "Comuna Ciumesti", "value": "C98477"},
                      {"label": "Comuna Craidorolt", "value": "C98478"},
                      {"label": "Comuna Crucisor", "value": "C98479"},
                      {"label": "Comuna Culciu", "value": "C98480"},
                      {"label": "Comuna Doba", "value": "C98481"},
                      {"label": "Comuna Dorolt", "value": "C98482"},
                      {"label": "Comuna Foieni", "value": "C98483"},
                      {"label": "Comuna Gherta Mica", "value": "C98484"},
                      {"label": "Comuna Halmeu", "value": "C98485"},
                      {"label": "Comuna Hodod", "value": "C98486"},
                      {"label": "Comuna Homoroade", "value": "C98487"},
                      {"label": "Comuna Lazuri", "value": "C98488"},
                      {"label": "Comuna Mediesu Aurit", "value": "C98489"},
                      {"label": "Comuna Micula", "value": "C98490"},
                      {"label": "Comuna Moftinu", "value": "C98491"},
                      {"label": "Comuna Odoreu", "value": "C98492"},
                      {"label": "Comuna Orasu Nou", "value": "C98493"},
                      {"label": "Comuna Paulesti", "value": "C98494"},
                      {"label": "Comuna Petresti", "value": "C98495"},
                      {"label": "Comuna Pir", "value": "C98496"},
                      {"label": "Comuna Piscolt", "value": "C98497"},
                      {"label": "Comuna Pomi", "value": "C98498"},
                      {"label": "Comuna Porumbesti", "value": "C98499"},
                      {"label": "Comuna Racsa", "value": "C98500"},
                      {"label": "Comuna Sacaseni", "value": "C98501"},
                      {"label": "Comuna Sanislau", "value": "C98502"},
                      {"label": "Comuna Santau", "value": "C98503"},
                      {"label": "Comuna Sauca", "value": "C98504"},
                      {"label": "Comuna Socond", "value": "C98505"},
                      {"label": "Comuna Supur", "value": "C98506"},
                      {"label": "Comuna Tarna Mare", "value": "C98507"},
                      {"label": "Comuna Tarsolt", "value": "C98508"},
                      {"label": "Comuna Terebesti", "value": "C98509"},
                      {"label": "Comuna Tiream", "value": "C98510"},
                      {"label": "Comuna Turt", "value": "C98511"},
                      {"label": "Comuna Turulung", "value": "C98512"},
                      {"label": "Comuna Urziceni", "value": "C98513"},
                      {"label": "Comuna Valea Vinului", "value": "C98514"},
                      {"label": "Comuna Vama", "value": "C98515"},
                      {"label": "Comuna Vetis", "value": "C98516"},
                      {"label": "Comuna Viile Satu Mare", "value": "C98517"},
                      {"label": "Craidorolt", "value": "C98518"},
                      {"label": "Crucisor", "value": "C98519"},
                      {"label": "Culciu Mic", "value": "C98520"},
                      {"label": "Dara", "value": "C98521"},
                      {"label": "Decebal", "value": "C98522"},
                      {"label": "Doba", "value": "C98523"},
                      {"label": "Dobra", "value": "C98524"},
                      {"label": "Domanesti", "value": "C98525"},
                      {"label": "Dorolt", "value": "C98526"},
                      {"label": "Draguseni", "value": "C98527"},
                      {"label": "Dumbrava", "value": "C98528"},
                      {"label": "Foieni", "value": "C98529"},
                      {"label": "Gherta Mare", "value": "C98530"},
                      {"label": "Gherta Mica", "value": "C98531"},
                      {"label": "Halmeu", "value": "C98532"},
                      {"label": "Hodod", "value": "C98533"},
                      {"label": "Homorodu de Jos", "value": "C98534"},
                      {"label": "Huta Certeze", "value": "C98535"},
                      {"label": "Iojib", "value": "C98536"},
                      {"label": "Lazuri", "value": "C98537"},
                      {"label": "Livada", "value": "C98538"},
                      {"label": "Lucaceni", "value": "C98539"},
                      {"label": "Madaras", "value": "C98540"},
                      {"label": "Mediesu Aurit", "value": "C98541"},
                      {"label": "Micula", "value": "C98542"},
                      {"label": "Moftinu Mare", "value": "C98543"},
                      {"label": "Moftinu Mic", "value": "C98544"},
                      {"label": "Moiseni", "value": "C98545"},
                      {"label": "Municipiul Carei", "value": "C98546"},
                      {"label": "Municipiul Satu Mare", "value": "C98547"},
                      {"label": "Negresti-Oas", "value": "C98548"},
                      {"label": "Oar", "value": "C98549"},
                      {"label": "Odoreu", "value": "C98550"},
                      {"label": "Oras Ardud", "value": "C98551"},
                      {"label": "Oras Livada", "value": "C98552"},
                      {"label": "Oras Negresti-Oas", "value": "C98553"},
                      {"label": "Oras Tasnad", "value": "C98554"},
                      {"label": "Orasu Nou", "value": "C98555"},
                      {"label": "Paulesti", "value": "C98556"},
                      {"label": "Petresti", "value": "C98557"},
                      {"label": "Pir", "value": "C98558"},
                      {"label": "Piscolt", "value": "C98559"},
                      {"label": "Poiana Codrului", "value": "C98560"},
                      {"label": "Pomi", "value": "C98561"},
                      {"label": "Porumbesti", "value": "C98562"},
                      {"label": "Potau", "value": "C98563"},
                      {"label": "Racsa", "value": "C98564"},
                      {"label": "Sacaseni", "value": "C98565"},
                      {"label": "Sanislau", "value": "C98566"},
                      {"label": "Santau", "value": "C98567"},
                      {"label": "Sarauad", "value": "C98568"},
                      {"label": "Satmarel", "value": "C98569"},
                      {"label": "Satu Mare", "value": "C98570"},
                      {"label": "Sauca", "value": "C98571"},
                      {"label": "Socond", "value": "C98572"},
                      {"label": "Stana", "value": "C98573"},
                      {"label": "Supuru de Jos", "value": "C98574"},
                      {"label": "Tarna Mare", "value": "C98575"},
                      {"label": "Tarsolt", "value": "C98576"},
                      {"label": "Tarsoltel", "value": "C98577"},
                      {"label": "Tasnad", "value": "C98578"},
                      {"label": "Terebesti", "value": "C98579"},
                      {"label": "Tiream", "value": "C98580"},
                      {"label": "Trip", "value": "C98581"},
                      {"label": "Tur", "value": "C98582"},
                      {"label": "Turt", "value": "C98583"},
                      {"label": "Turulung", "value": "C98584"},
                      {"label": "Urziceni", "value": "C98585"},
                      {"label": "Valea Seaca", "value": "C98586"},
                      {"label": "Valea Vinului", "value": "C98587"},
                      {"label": "Vama", "value": "C98588"},
                      {"label": "Vetis", "value": "C98589"},
                      {"label": "Viile Satu Mare", "value": "C98590"}],
 "Satun": [{"label": "Amphoe Khuan Don", "value": "C119328"},
           {"label": "Amphoe Khuan Kalong", "value": "C119329"},
           {"label": "Amphoe La-Ngu", "value": "C119330"},
           {"label": "Amphoe Manang", "value": "C119331"},
           {"label": "Amphoe Mueang Satun", "value": "C119332"},
           {"label": "Amphoe Tha Phae", "value": "C119333"},
           {"label": "Amphoe Thung Wa", "value": "C119334"},
           {"label": "Satun", "value": "C119335"}],
 "Saulkrasti Municipality": [{"label": "Saulkrasti", "value": "C64935"}],
 "Savanes Region": [{"label": "Dapaong", "value": "C119645"},
                    {"label": "Sansanne-Mango", "value": "C119646"}],
 "Savannah": [{"label": "Bole", "value": "C41548"},
              {"label": "Central Gonja", "value": "C41549"},
              {"label": "East Gonja", "value": "C41550"},
              {"label": "North East Gonja", "value": "C41551"},
              {"label": "North Gonja", "value": "C41552"},
              {"label": "Sawla-Tuna-Kalba", "value": "C41553"},
              {"label": "West Gonja", "value": "C41554"}],
 "Savannakhet Province": [{"label": "Kaysone Phomvihane", "value": "C64810"},
                          {"label": "Muang Alsaphangthong", "value": "C64811"},
                          {"label": "Muang Atsaphan", "value": "C64812"},
                          {"label": "Muang Champhon", "value": "C64813"},
                          {"label": "Muang Nong", "value": "C64814"},
                          {"label": "Muang Outhoumphon", "value": "C64815"},
                          {"label": "Muang Phin", "value": "C64816"},
                          {"label": "Muang Songkhon", "value": "C64817"},
                          {"label": "Muang Thapangthong", "value": "C64818"},
                          {"label": "Muang Vilabouli", "value": "C64819"},
                          {"label": "Muang Xaibouli", "value": "C64820"},
                          {"label": "Muang Xayphoothong", "value": "C64821"},
                          {"label": "Muang Xonbouli", "value": "C64822"},
                          {"label": "Savannakhet", "value": "C64823"},
                          {"label": "Thaphalanxay", "value": "C64824"}],
 "Savanne District": [{"label": "Camp Diable", "value": "C66065"},
                      {"label": "Chamouny", "value": "C66066"},
                      {"label": "Chemin Grenier", "value": "C66067"},
                      {"label": "Grand Bois", "value": "C66068"},
                      {"label": "Riviere des Anguilles", "value": "C66069"},
                      {"label": "Saint Aubin", "value": "C66070"},
                      {"label": "Souillac", "value": "C66071"},
                      {"label": "Surinam", "value": "C66072"}],
 "Savnik Municipality": [{"label": "Savnik", "value": "C75624"}],
 "Saxony": [{"label": "Adorf", "value": "C39947"},
            {"label": "Albertstadt", "value": "C39948"},
            {"label": "Altenberg", "value": "C39949"},
            {"label": "Altmittweida", "value": "C39950"},
            {"label": "Annaberg-Buchholz", "value": "C39951"},
            {"label": "Arzberg", "value": "C39952"},
            {"label": "Aue", "value": "C39953"},
            {"label": "Auerbach", "value": "C39954"},
            {"label": "Augustusburg", "value": "C39955"},
            {"label": "Bad Brambach", "value": "C39956"},
            {"label": "Bad Duben", "value": "C39957"},
            {"label": "Bad Elster", "value": "C39958"},
            {"label": "Bad Lausick", "value": "C39959"},
            {"label": "Bad Muskau", "value": "C39960"},
            {"label": "Bad Schandau", "value": "C39961"},
            {"label": "Bad Schlema", "value": "C39962"},
            {"label": "Bannewitz", "value": "C39963"},
            {"label": "Barenstein", "value": "C39964"},
            {"label": "Bautzen", "value": "C39965"},
            {"label": "Beierfeld", "value": "C39966"},
            {"label": "Beiersdorf", "value": "C39967"},
            {"label": "Beilrode", "value": "C39968"},
            {"label": "Belgern", "value": "C39969"},
            {"label": "Belgershain", "value": "C39970"},
            {"label": "Bennewitz", "value": "C39971"},
            {"label": "Bergen", "value": "C39972"},
            {"label": "Bernsbach", "value": "C39973"},
            {"label": "Bernsdorf", "value": "C39974"},
            {"label": "Bernstadt", "value": "C39975"},
            {"label": "Berthelsdorf", "value": "C39976"},
            {"label": "Bertsdorf-Hornitz", "value": "C39977"},
            {"label": "Bischofswerda", "value": "C39978"},
            {"label": "Bockau", "value": "C39979"},
            {"label": "Bockelwitz", "value": "C39980"},
            {"label": "Bohlen", "value": "C39981"},
            {"label": "Borna", "value": "C39982"},
            {"label": "Bornichen", "value": "C39983"},
            {"label": "Borsdorf", "value": "C39984"},
            {"label": "Borstendorf", "value": "C39985"},
            {"label": "Bosenbrunn", "value": "C39986"},
            {"label": "Boxberg", "value": "C39987"},
            {"label": "Brand-Erbisdorf", "value": "C39988"},
            {"label": "Brandis", "value": "C39989"},
            {"label": "Breitenbrunn", "value": "C39990"},
            {"label": "Burgstadt", "value": "C39991"},
            {"label": "Burkau", "value": "C39992"},
            {"label": "Burkhardtsdorf", "value": "C39993"},
            {"label": "Callenberg", "value": "C39994"},
            {"label": "Cavertitz", "value": "C39995"},
            {"label": "Chemnitz", "value": "C39996"},
            {"label": "Claussnitz", "value": "C39997"},
            {"label": "Colditz", "value": "C39998"},
            {"label": "Coswig", "value": "C39999"},
            {"label": "Crimmitschau", "value": "C40000"},
            {"label": "Crostau", "value": "C40001"},
            {"label": "Crostwitz", "value": "C40002"},
            {"label": "Crottendorf", "value": "C40003"},
            {"label": "Cunewalde", "value": "C40004"},
            {"label": "Dahlen", "value": "C40005"},
            {"label": "Delitzsch", "value": "C40006"},
            {"label": "Demitz-Thumitz", "value": "C40007"},
            {"label": "Dennheritz", "value": "C40008"},
            {"label": "Deutzen", "value": "C40009"},
            {"label": "Dippoldiswalde", "value": "C40010"},
            {"label": "Dobeln", "value": "C40011"},
            {"label": "Dobernitz", "value": "C40012"},
            {"label": "Doberschutz", "value": "C40013"},
            {"label": "Dohma", "value": "C40014"},
            {"label": "Dohna", "value": "C40015"},
            {"label": "Dommitzsch", "value": "C40016"},
            {"label": "Dorfchemnitz", "value": "C40017"},
            {"label": "Dorfhain", "value": "C40018"},
            {"label": "Drebach", "value": "C40019"},
            {"label": "Dresden", "value": "C40020"},
            {"label": "Durrhennersdorf", "value": "C40021"},
            {"label": "Durrrohrsdorf", "value": "C40022"},
            {"label": "Ebersbach", "value": "C40023"},
            {"label": "Ehrenfriedersdorf", "value": "C40024"},
            {"label": "Eibau", "value": "C40025"},
            {"label": "Eibenstock", "value": "C40026"},
            {"label": "Eichigt", "value": "C40027"},
            {"label": "Eilenburg", "value": "C40028"},
            {"label": "Ellefeld", "value": "C40029"},
            {"label": "Elsnig", "value": "C40030"},
            {"label": "Elsterberg", "value": "C40031"},
            {"label": "Elstertrebnitz", "value": "C40032"},
            {"label": "Elstra", "value": "C40033"},
            {"label": "Elterlein", "value": "C40034"},
            {"label": "Eppendorf", "value": "C40035"},
            {"label": "Erlau", "value": "C40036"},
            {"label": "Erlbach", "value": "C40037"},
            {"label": "Espenhain", "value": "C40038"},
            {"label": "Falkenau", "value": "C40039"},
            {"label": "Falkenhain", "value": "C40040"},
            {"label": "Falkenstein", "value": "C40041"},
            {"label": "Floha", "value": "C40042"},
            {"label": "Frankenberg", "value": "C40043"},
            {"label": "Frankenstein", "value": "C40044"},
            {"label": "Frankenthal", "value": "C40045"},
            {"label": "Frauenstein", "value": "C40046"},
            {"label": "Fraureuth", "value": "C40047"},
            {"label": "Freiberg", "value": "C40048"},
            {"label": "Freital", "value": "C40049"},
            {"label": "Friedersdorf", "value": "C40050"},
            {"label": "Frohburg", "value": "C40051"},
            {"label": "Gablenz", "value": "C40052"},
            {"label": "Geising", "value": "C40053"},
            {"label": "Geithain", "value": "C40054"},
            {"label": "Gelenau", "value": "C40055"},
            {"label": "Geringswalde", "value": "C40056"},
            {"label": "Gersdorf", "value": "C40057"},
            {"label": "Geyer", "value": "C40058"},
            {"label": "Glashutte", "value": "C40059"},
            {"label": "Glaubitz", "value": "C40060"},
            {"label": "Glauchau", "value": "C40061"},
            {"label": "Goda", "value": "C40062"},
            {"label": "Gorlitz", "value": "C40063"},
            {"label": "Gornau", "value": "C40064"},
            {"label": "Gornsdorf", "value": "C40065"},
            {"label": "Grimma", "value": "C40066"},
            {"label": "Groitzsch", "value": "C40067"},
            {"label": "Gross Duben", "value": "C40068"},
            {"label": "Grossbardau", "value": "C40069"},
            {"label": "Grossbothen", "value": "C40070"},
            {"label": "Grossdubrau", "value": "C40071"},
            {"label": "Grossenhain", "value": "C40072"},
            {"label": "Grossharthau", "value": "C40073"},
            {"label": "Grosshartmannsdorf", "value": "C40074"},
            {"label": "Grosshennersdorf", "value": "C40075"},
            {"label": "Grosslehna", "value": "C40076"},
            {"label": "Grossnaundorf", "value": "C40077"},
            {"label": "Grossolbersdorf", "value": "C40078"},
            {"label": "Grossposna", "value": "C40079"},
            {"label": "Grosspostwitz", "value": "C40080"},
            {"label": "Grossrohrsdorf", "value": "C40081"},
            {"label": "Grossruckerswalde", "value": "C40082"},
            {"label": "Grossschirma", "value": "C40083"},
            {"label": "Grossschonau", "value": "C40084"},
            {"label": "Grossschweidnitz", "value": "C40085"},
            {"label": "Grossweitzschen", "value": "C40086"},
            {"label": "Grunbach", "value": "C40087"},
            {"label": "Grunhain", "value": "C40088"},
            {"label": "Grunhainichen", "value": "C40089"},
            {"label": "Guttau", "value": "C40090"},
            {"label": "Hahnichen", "value": "C40091"},
            {"label": "Hainewalde", "value": "C40092"},
            {"label": "Hainichen", "value": "C40093"},
            {"label": "Halsbrucke", "value": "C40094"},
            {"label": "Hammerbrucke", "value": "C40095"},
            {"label": "Hartenstein", "value": "C40096"},
            {"label": "Hartha", "value": "C40097"},
            {"label": "Hartmannsdorf", "value": "C40098"},
            {"label": "Haselbachtal", "value": "C40099"},
            {"label": "Hauswalde", "value": "C40100"},
            {"label": "Heidenau", "value": "C40101"},
            {"label": "Hermsdorf", "value": "C40102"},
            {"label": "Herrnhut", "value": "C40103"},
            {"label": "Hilbersdorf", "value": "C40104"},
            {"label": "Hirschfeld", "value": "C40105"},
            {"label": "Hirschfelde", "value": "C40106"},
            {"label": "Hochkirch", "value": "C40107"},
            {"label": "Hockendorf", "value": "C40108"},
            {"label": "Hohburg", "value": "C40109"},
            {"label": "Hohenstein-Ernstthal", "value": "C40110"},
            {"label": "Hohndorf", "value": "C40111"},
            {"label": "Hohnstein", "value": "C40112"},
            {"label": "Horka", "value": "C40113"},
            {"label": "Hormersdorf", "value": "C40114"},
            {"label": "Hoyerswerda", "value": "C40115"},
            {"label": "Jahnsdorf", "value": "C40116"},
            {"label": "Jesewitz", "value": "C40117"},
            {"label": "Johanngeorgenstadt", "value": "C40118"},
            {"label": "Johstadt", "value": "C40119"},
            {"label": "Kamenz", "value": "C40120"},
            {"label": "Kirchberg", "value": "C40121"},
            {"label": "Kirschau", "value": "C40122"},
            {"label": "Kitzen", "value": "C40123"},
            {"label": "Kitzscher", "value": "C40124"},
            {"label": "Klingenthal", "value": "C40125"},
            {"label": "Klipphausen", "value": "C40126"},
            {"label": "Klitten", "value": "C40127"},
            {"label": "Kodersdorf", "value": "C40128"},
            {"label": "Konigsbruck", "value": "C40129"},
            {"label": "Konigsfeld", "value": "C40130"},
            {"label": "Konigshain", "value": "C40131"},
            {"label": "Konigstein", "value": "C40132"},
            {"label": "Konigswalde", "value": "C40133"},
            {"label": "Konigswartha", "value": "C40134"},
            {"label": "Kossa", "value": "C40135"},
            {"label": "Krauschwitz", "value": "C40136"},
            {"label": "Kreischa", "value": "C40137"},
            {"label": "Kriebstein", "value": "C40138"},
            {"label": "Krostitz", "value": "C40139"},
            {"label": "Kubschutz", "value": "C40140"},
            {"label": "Kurort Gohrisch", "value": "C40141"},
            {"label": "Kurort Jonsdorf", "value": "C40142"},
            {"label": "Kurort Oberwiesenthal", "value": "C40143"},
            {"label": "Kurort Oybin", "value": "C40144"},
            {"label": "Lampertswalde", "value": "C40145"},
            {"label": "Langenbernsdorf", "value": "C40146"},
            {"label": "Laussig", "value": "C40147"},
            {"label": "Laussnitz", "value": "C40148"},
            {"label": "Lauter", "value": "C40149"},
            {"label": "Lawalde", "value": "C40150"},
            {"label": "Leipzig", "value": "C40151"},
            {"label": "Leisnig", "value": "C40152"},
            {"label": "Lengefeld", "value": "C40153"},
            {"label": "Lengenfeld", "value": "C40154"},
            {"label": "Leubnitz", "value": "C40155"},
            {"label": "Leubsdorf", "value": "C40156"},
            {"label": "Leutersdorf", "value": "C40157"},
            {"label": "Lichtenberg", "value": "C40158"},
            {"label": "Lichtenstein", "value": "C40159"},
            {"label": "Lichtentanne", "value": "C40160"},
            {"label": "Liebstadt", "value": "C40161"},
            {"label": "Limbach", "value": "C40162"},
            {"label": "Limbach-Oberfrohna", "value": "C40163"},
            {"label": "Lobau", "value": "C40164"},
            {"label": "Lobnitz", "value": "C40165"},
            {"label": "Lobstadt", "value": "C40166"},
            {"label": "Lohmen", "value": "C40167"},
            {"label": "Lohsa", "value": "C40168"},
            {"label": "Lommatzsch", "value": "C40169"},
            {"label": "Lossnitz", "value": "C40170"},
            {"label": "Lugau", "value": "C40171"},
            {"label": "Lunzenau", "value": "C40172"},
            {"label": "Machern", "value": "C40173"},
            {"label": "Malschwitz", "value": "C40174"},
            {"label": "Marienberg", "value": "C40175"},
            {"label": "Markersbach", "value": "C40176"},
            {"label": "Markersdorf", "value": "C40177"},
            {"label": "Markkleeberg", "value": "C40178"},
            {"label": "Markneukirchen", "value": "C40179"},
            {"label": "Markranstadt", "value": "C40180"},
            {"label": "Meerane", "value": "C40181"},
            {"label": "Mehltheuer", "value": "C40182"},
            {"label": "Meissen", "value": "C40183"},
            {"label": "Mildenau", "value": "C40184"},
            {"label": "Mittelherwigsdorf", "value": "C40185"},
            {"label": "Mittweida", "value": "C40186"},
            {"label": "Mochau", "value": "C40187"},
            {"label": "Mockrehna", "value": "C40188"},
            {"label": "Moritzburg", "value": "C40189"},
            {"label": "Mucka", "value": "C40190"},
            {"label": "Mugeln", "value": "C40191"},
            {"label": "Muhlau", "value": "C40192"},
            {"label": "Muhltroff", "value": "C40193"},
            {"label": "Mulda", "value": "C40194"},
            {"label": "Mulsen", "value": "C40195"},
            {"label": "Mutzschen", "value": "C40196"},
            {"label": "Mylau", "value": "C40197"},
            {"label": "Narsdorf", "value": "C40198"},
            {"label": "Naundorf", "value": "C40199"},
            {"label": "Naunhof", "value": "C40200"},
            {"label": "Nauwalde", "value": "C40201"},
            {"label": "Nebelschutz", "value": "C40202"},
            {"label": "Nerchau", "value": "C40203"},
            {"label": "Neschwitz", "value": "C40204"},
            {"label": "Netzschkau", "value": "C40205"},
            {"label": "Neuensalz", "value": "C40206"},
            {"label": "Neugersdorf", "value": "C40207"},
            {"label": "Neuhausen", "value": "C40208"},
            {"label": "Neukieritzsch", "value": "C40209"},
            {"label": "Neukirch", "value": "C40210"},
            {"label": "NeukirchLausitz", "value": "C40211"},
            {"label": "Neukirchen", "value": "C40212"},
            {"label": "Neumark", "value": "C40213"},
            {"label": "Neusalza-Spremberg", "value": "C40214"},
            {"label": "Neustadt in Sachsen", "value": "C40215"},
            {"label": "Neustadt Vogtland", "value": "C40216"},
            {"label": "Niederau", "value": "C40217"},
            {"label": "Niedercunnersdorf", "value": "C40218"},
            {"label": "Niederdorf", "value": "C40219"},
            {"label": "Niederfrohna", "value": "C40220"},
            {"label": "Niederschona", "value": "C40221"},
            {"label": "Niederstriegis", "value": "C40222"},
            {"label": "Niederwiesa", "value": "C40223"},
            {"label": "Niesky", "value": "C40224"},
            {"label": "Nossen", "value": "C40225"},
            {"label": "Nunchritz", "value": "C40226"},
            {"label": "Obercunnersdorf", "value": "C40227"},
            {"label": "Obergurig", "value": "C40228"},
            {"label": "Oberlichtenau", "value": "C40229"},
            {"label": "Oberlungwitz", "value": "C40230"},
            {"label": "Oberschona", "value": "C40231"},
            {"label": "Oberwiera", "value": "C40232"},
            {"label": "Oederan", "value": "C40233"},
            {"label": "Oelsnitz", "value": "C40234"},
            {"label": "Ohorn", "value": "C40235"},
            {"label": "Olbernhau", "value": "C40236"},
            {"label": "Olbersdorf", "value": "C40237"},
            {"label": "Oppach", "value": "C40238"},
            {"label": "Oschatz", "value": "C40239"},
            {"label": "Ossling", "value": "C40240"},
            {"label": "Ostrau", "value": "C40241"},
            {"label": "Ostritz", "value": "C40242"},
            {"label": "Ottendorf-Okrilla", "value": "C40243"},
            {"label": "Otterwisch", "value": "C40244"},
            {"label": "Panschwitz-Kuckau", "value": "C40245"},
            {"label": "Pausa", "value": "C40246"},
            {"label": "Pegau", "value": "C40247"},
            {"label": "Penig", "value": "C40248"},
            {"label": "Pfaffroda", "value": "C40249"},
            {"label": "Pirna", "value": "C40250"},
            {"label": "Plauen", "value": "C40251"},
            {"label": "Pobershau", "value": "C40252"},
            {"label": "Pockau", "value": "C40253"},
            {"label": "Pohl", "value": "C40254"},
            {"label": "Pohla", "value": "C40255"},
            {"label": "Porschdorf", "value": "C40256"},
            {"label": "Pretzschendorf", "value": "C40257"},
            {"label": "Priestewitz", "value": "C40258"},
            {"label": "Puschwitz", "value": "C40259"},
            {"label": "Quitzdorf", "value": "C40260"},
            {"label": "Rabenau", "value": "C40261"},
            {"label": "Rackelwitz", "value": "C40262"},
            {"label": "Rackwitz", "value": "C40263"},
            {"label": "Radeberg", "value": "C40264"},
            {"label": "Radebeul", "value": "C40265"},
            {"label": "Radeburg", "value": "C40266"},
            {"label": "Radibor", "value": "C40267"},
            {"label": "Rammenau", "value": "C40268"},
            {"label": "Raschau", "value": "C40269"},
            {"label": "Rathmannsdorf", "value": "C40270"},
            {"label": "Rechenberg-Bienenmuhle", "value": "C40271"},
            {"label": "Regis-Breitingen", "value": "C40272"},
            {"label": "Reichenbach", "value": "C40273"},
            {"label": "ReichenbachVogtland", "value": "C40274"},
            {"label": "Reinhardtsgrimma", "value": "C40275"},
            {"label": "Reinsberg", "value": "C40276"},
            {"label": "Reinsdorf", "value": "C40277"},
            {"label": "Remse", "value": "C40278"},
            {"label": "Reuth", "value": "C40279"},
            {"label": "Riesa", "value": "C40280"},
            {"label": "Rietschen", "value": "C40281"},
            {"label": "Rittersgrun", "value": "C40282"},
            {"label": "Rochlitz", "value": "C40283"},
            {"label": "Rodewisch", "value": "C40284"},
            {"label": "Rossau", "value": "C40285"},
            {"label": "Rosswein", "value": "C40286"},
            {"label": "Rotha", "value": "C40287"},
            {"label": "Rothenburg", "value": "C40288"},
            {"label": "Sankt Egidien", "value": "C40289"},
            {"label": "Sayda", "value": "C40290"},
            {"label": "Scharfenstein", "value": "C40291"},
            {"label": "Scheibenberg", "value": "C40292"},
            {"label": "Schildau", "value": "C40293"},
            {"label": "Schirgiswalde", "value": "C40294"},
            {"label": "Schkeuditz", "value": "C40295"},
            {"label": "Schlegel", "value": "C40296"},
            {"label": "Schleife", "value": "C40297"},
            {"label": "Schlettau", "value": "C40298"},
            {"label": "Schmiedeberg", "value": "C40299"},
            {"label": "Schneeberg", "value": "C40300"},
            {"label": "Schonau-Berzdorf", "value": "C40301"},
            {"label": "Schonbach", "value": "C40302"},
            {"label": "Schonberg", "value": "C40303"},
            {"label": "Schoneck", "value": "C40304"},
            {"label": "Schonfeld", "value": "C40305"},
            {"label": "Schonheide", "value": "C40306"},
            {"label": "Schwarzenberg", "value": "C40307"},
            {"label": "Schwepnitz", "value": "C40308"},
            {"label": "Sebnitz", "value": "C40309"},
            {"label": "Seelitz", "value": "C40310"},
            {"label": "Seiffen", "value": "C40311"},
            {"label": "Seifhennersdorf", "value": "C40312"},
            {"label": "Sohland", "value": "C40313"},
            {"label": "Sohland am Rotstein", "value": "C40314"},
            {"label": "Sosa", "value": "C40315"},
            {"label": "Stadt Wehlen", "value": "C40316"},
            {"label": "Stauchitz", "value": "C40317"},
            {"label": "Steina", "value": "C40318"},
            {"label": "Steinigtwolmsdorf", "value": "C40319"},
            {"label": "Stollberg", "value": "C40320"},
            {"label": "Stolpen", "value": "C40321"},
            {"label": "Strehla", "value": "C40322"},
            {"label": "Struppen", "value": "C40323"},
            {"label": "Stutzengrun", "value": "C40324"},
            {"label": "Syrau", "value": "C40325"},
            {"label": "Tannenberg", "value": "C40326"},
            {"label": "Tannenbergsthal", "value": "C40327"},
            {"label": "Taucha", "value": "C40328"},
            {"label": "Taura", "value": "C40329"},
            {"label": "Tauscha", "value": "C40330"},
            {"label": "Thalheim", "value": "C40331"},
            {"label": "Thallwitz", "value": "C40332"},
            {"label": "Tharandt", "value": "C40333"},
            {"label": "Theuma", "value": "C40334"},
            {"label": "Thiendorf", "value": "C40335"},
            {"label": "Thum", "value": "C40336"},
            {"label": "Tirpersdorf", "value": "C40337"},
            {"label": "Torgau", "value": "C40338"},
            {"label": "Trebendorf", "value": "C40339"},
            {"label": "Trebsen", "value": "C40340"},
            {"label": "Treuen", "value": "C40341"},
            {"label": "Triebel", "value": "C40342"},
            {"label": "Trossin", "value": "C40343"},
            {"label": "Uhyst", "value": "C40344"},
            {"label": "Venusberg", "value": "C40345"},
            {"label": "Wachau", "value": "C40346"},
            {"label": "Waldenburg", "value": "C40347"},
            {"label": "Waldheim", "value": "C40348"},
            {"label": "Waldkirchen", "value": "C40349"},
            {"label": "Wechselburg", "value": "C40350"},
            {"label": "Weinbohla", "value": "C40351"},
            {"label": "Weischlitz", "value": "C40352"},
            {"label": "Weissenberg", "value": "C40353"},
            {"label": "Weissenborn", "value": "C40354"},
            {"label": "Weissig", "value": "C40355"},
            {"label": "Weisskeissel", "value": "C40356"},
            {"label": "Weisswasser", "value": "C40357"},
            {"label": "Werda", "value": "C40358"},
            {"label": "Werdau", "value": "C40359"},
            {"label": "Wermsdorf", "value": "C40360"},
            {"label": "Wiedemar", "value": "C40361"},
            {"label": "Wiednitz", "value": "C40362"},
            {"label": "Wiesa", "value": "C40363"},
            {"label": "Wildenfels", "value": "C40364"},
            {"label": "Wildenhain", "value": "C40365"},
            {"label": "Wilkau-Hasslau", "value": "C40366"},
            {"label": "Wilsdruff", "value": "C40367"},
            {"label": "Wilthen", "value": "C40368"},
            {"label": "Wittgensdorf", "value": "C40369"},
            {"label": "Wittichenau", "value": "C40370"},
            {"label": "Wolkenstein", "value": "C40371"},
            {"label": "Wulknitz", "value": "C40372"},
            {"label": "Wurzen", "value": "C40373"},
            {"label": "Zabeltitz", "value": "C40374"},
            {"label": "Zeithain", "value": "C40375"},
            {"label": "Zinna", "value": "C40376"},
            {"label": "Zittau", "value": "C40377"},
            {"label": "Zoblitz", "value": "C40378"},
            {"label": "Zschadrass", "value": "C40379"},
            {"label": "Zschepplin", "value": "C40380"},
            {"label": "Zschopau", "value": "C40381"},
            {"label": "Zschorlau", "value": "C40382"},
            {"label": "Zschortau", "value": "C40383"},
            {"label": "Zwenkau", "value": "C40384"},
            {"label": "Zwickau", "value": "C40385"},
            {"label": "Zwochau", "value": "C40386"},
            {"label": "Zwonitz", "value": "C40387"},
            {"label": "Zwota", "value": "C40388"}],
 "Saxony-Anhalt": [{"label": "Abtsdorf", "value": "C40389"},
                   {"label": "Ahlsdorf", "value": "C40390"},
                   {"label": "Aken", "value": "C40391"},
                   {"label": "Allstedt", "value": "C40392"},
                   {"label": "Alsleben", "value": "C40393"},
                   {"label": "Angern", "value": "C40394"},
                   {"label": "Angersdorf", "value": "C40395"},
                   {"label": "Annaburg", "value": "C40396"},
                   {"label": "Apollensdorf", "value": "C40397"},
                   {"label": "Arneburg", "value": "C40398"},
                   {"label": "Aschersleben", "value": "C40399"},
                   {"label": "Atzendorf", "value": "C40400"},
                   {"label": "Ausleben", "value": "C40401"},
                   {"label": "Baalberge", "value": "C40402"},
                   {"label": "Bad Bibra", "value": "C40403"},
                   {"label": "Bad Durrenberg", "value": "C40404"},
                   {"label": "Bad Kosen", "value": "C40405"},
                   {"label": "Bad Lauchstadt", "value": "C40406"},
                   {"label": "Bad Schmiedeberg", "value": "C40407"},
                   {"label": "Bad Suderode", "value": "C40408"},
                   {"label": "Ballenstedt", "value": "C40409"},
                   {"label": "Barby", "value": "C40410"},
                   {"label": "Barleben", "value": "C40411"},
                   {"label": "Barnstadt", "value": "C40412"},
                   {"label": "Beesenlaublingen", "value": "C40413"},
                   {"label": "Beesenstedt", "value": "C40414"},
                   {"label": "Beetzendorf", "value": "C40415"},
                   {"label": "Belleben", "value": "C40416"},
                   {"label": "Benndorf", "value": "C40417"},
                   {"label": "Benneckenstein", "value": "C40418"},
                   {"label": "Bennstedt", "value": "C40419"},
                   {"label": "Bennungen", "value": "C40420"},
                   {"label": "Berga", "value": "C40421"},
                   {"label": "Bergwitz", "value": "C40422"},
                   {"label": "Bernburg", "value": "C40423"},
                   {"label": "Beuna", "value": "C40424"},
                   {"label": "Biederitz", "value": "C40425"},
                   {"label": "Biere", "value": "C40426"},
                   {"label": "Bismark", "value": "C40427"},
                   {"label": "Bitterfeld-Wolfen", "value": "C40428"},
                   {"label": "Blankenburg", "value": "C40429"},
                   {"label": "Blankenheim", "value": "C40430"},
                   {"label": "Bobbau", "value": "C40431"},
                   {"label": "Borne", "value": "C40432"},
                   {"label": "Braschwitz", "value": "C40433"},
                   {"label": "Braunsbedra", "value": "C40434"},
                   {"label": "Brehna", "value": "C40435"},
                   {"label": "Brumby", "value": "C40436"},
                   {"label": "Burg bei Magdeburg", "value": "C40437"},
                   {"label": "Burgwerben", "value": "C40438"},
                   {"label": "Calbe", "value": "C40439"},
                   {"label": "Calvorde", "value": "C40440"},
                   {"label": "Cochstedt", "value": "C40441"},
                   {"label": "Colbitz", "value": "C40442"},
                   {"label": "Coswig", "value": "C40443"},
                   {"label": "Dahlenwarsleben", "value": "C40444"},
                   {"label": "Darlingerode", "value": "C40445"},
                   {"label": "Derenburg", "value": "C40446"},
                   {"label": "Dessau", "value": "C40447"},
                   {"label": "Deuben", "value": "C40448"},
                   {"label": "Diesdorf", "value": "C40449"},
                   {"label": "Dieskau", "value": "C40450"},
                   {"label": "Ditfurt", "value": "C40451"},
                   {"label": "Dobien", "value": "C40452"},
                   {"label": "Dolbau", "value": "C40453"},
                   {"label": "Dollnitz", "value": "C40454"},
                   {"label": "Domersleben", "value": "C40455"},
                   {"label": "Droyssig", "value": "C40456"},
                   {"label": "Drubeck", "value": "C40457"},
                   {"label": "Ebendorf", "value": "C40458"},
                   {"label": "Eckartsberga", "value": "C40459"},
                   {"label": "Edderitz", "value": "C40460"},
                   {"label": "Edersleben", "value": "C40461"},
                   {"label": "Egeln", "value": "C40462"},
                   {"label": "Eggersdorf", "value": "C40463"},
                   {"label": "Eichenbarleben", "value": "C40464"},
                   {"label": "Eickendorf", "value": "C40465"},
                   {"label": "Eilsleben", "value": "C40466"},
                   {"label": "Eisleben Lutherstadt", "value": "C40467"},
                   {"label": "Elbingerode", "value": "C40468"},
                   {"label": "Elster", "value": "C40469"},
                   {"label": "Erdeborn", "value": "C40470"},
                   {"label": "Ermlitz", "value": "C40471"},
                   {"label": "Erxleben", "value": "C40472"},
                   {"label": "Farnstadt", "value": "C40473"},
                   {"label": "Flechtingen", "value": "C40474"},
                   {"label": "Flessau", "value": "C40475"},
                   {"label": "Forderstedt", "value": "C40476"},
                   {"label": "Frankleben", "value": "C40477"},
                   {"label": "Freyburg", "value": "C40478"},
                   {"label": "Friedersdorf", "value": "C40479"},
                   {"label": "Friedrichsbrunn", "value": "C40480"},
                   {"label": "Friedrichstadt", "value": "C40481"},
                   {"label": "Frose", "value": "C40482"},
                   {"label": "Gardelegen", "value": "C40483"},
                   {"label": "Gatersleben", "value": "C40484"},
                   {"label": "Genthin", "value": "C40485"},
                   {"label": "Gerbstedt", "value": "C40486"},
                   {"label": "Gernrode", "value": "C40487"},
                   {"label": "Gerwisch", "value": "C40488"},
                   {"label": "Geusa", "value": "C40489"},
                   {"label": "Giersleben", "value": "C40490"},
                   {"label": "Glindenberg", "value": "C40491"},
                   {"label": "Glothe", "value": "C40492"},
                   {"label": "Goldbeck", "value": "C40493"},
                   {"label": "Gommern", "value": "C40494"},
                   {"label": "Gorzig", "value": "C40495"},
                   {"label": "Goseck", "value": "C40496"},
                   {"label": "Grafenhainichen", "value": "C40497"},
                   {"label": "Granschutz", "value": "C40498"},
                   {"label": "Greppin", "value": "C40499"},
                   {"label": "Grobers", "value": "C40500"},
                   {"label": "Grobzig", "value": "C40501"},
                   {"label": "Groningen", "value": "C40502"},
                   {"label": "Gross Ammensleben", "value": "C40503"},
                   {"label": "Gross Bornecke", "value": "C40504"},
                   {"label": "Gross Quenstedt", "value": "C40505"},
                   {"label": "Gross Rodensleben", "value": "C40506"},
                   {"label": "Gross Rosenburg", "value": "C40507"},
                   {"label": "Gross Santersleben", "value": "C40508"},
                   {"label": "Grosskayna", "value": "C40509"},
                   {"label": "Grosskorbetha", "value": "C40510"},
                   {"label": "Grosskugel", "value": "C40511"},
                   {"label": "Grossorner", "value": "C40512"},
                   {"label": "Gunthersdorf", "value": "C40513"},
                   {"label": "Gusten", "value": "C40514"},
                   {"label": "Gutenberg", "value": "C40515"},
                   {"label": "Hadmersleben", "value": "C40516"},
                   {"label": "Halberstadt", "value": "C40517"},
                   {"label": "Haldensleben I", "value": "C40518"},
                   {"label": "Halle (Saale)", "value": "C40519"},
                   {"label": "Halle Neustadt", "value": "C40520"},
                   {"label": "Harbke", "value": "C40521"},
                   {"label": "Harsleben", "value": "C40522"},
                   {"label": "Harzgerode", "value": "C40523"},
                   {"label": "Hasselfelde", "value": "C40524"},
                   {"label": "Havelberg", "value": "C40525"},
                   {"label": "Hecklingen", "value": "C40526"},
                   {"label": "Hedersleben", "value": "C40527"},
                   {"label": "Helbra", "value": "C40528"},
                   {"label": "Hergisdorf", "value": "C40529"},
                   {"label": "Hermsdorf", "value": "C40530"},
                   {"label": "Hettstedt", "value": "C40531"},
                   {"label": "Heudeber", "value": "C40532"},
                   {"label": "Hohendodeleben", "value": "C40533"},
                   {"label": "Hohenmolsen", "value": "C40534"},
                   {"label": "Hohenthurm", "value": "C40535"},
                   {"label": "Hohenwarsleben", "value": "C40536"},
                   {"label": "Hohenwarthe", "value": "C40537"},
                   {"label": "Hohnstedt", "value": "C40538"},
                   {"label": "Holleben", "value": "C40539"},
                   {"label": "Holzdorf", "value": "C40540"},
                   {"label": "Holzweissig", "value": "C40541"},
                   {"label": "Hornhausen", "value": "C40542"},
                   {"label": "Hotensleben", "value": "C40543"},
                   {"label": "Hoym", "value": "C40544"},
                   {"label": "Huttenrode", "value": "C40545"},
                   {"label": "Huy-Neinstedt", "value": "C40546"},
                   {"label": "Ilberstedt", "value": "C40547"},
                   {"label": "Ilsenburg", "value": "C40548"},
                   {"label": "Irxleben", "value": "C40549"},
                   {"label": "Javenitz", "value": "C40550"},
                   {"label": "Jerichow", "value": "C40551"},
                   {"label": "Jessen", "value": "C40552"},
                   {"label": "Jessnitz", "value": "C40553"},
                   {"label": "Kalbe", "value": "C40554"},
                   {"label": "Karsdorf", "value": "C40555"},
                   {"label": "Kayna", "value": "C40556"},
                   {"label": "Kelbra", "value": "C40557"},
                   {"label": "Kemberg", "value": "C40558"},
                   {"label": "Klein Wanzleben", "value": "C40559"},
                   {"label": "Klieken", "value": "C40560"},
                   {"label": "Klietz", "value": "C40561"},
                   {"label": "Klostermansfeld", "value": "C40562"},
                   {"label": "Klotze", "value": "C40563"},
                   {"label": "Konnern", "value": "C40564"},
                   {"label": "Kothen", "value": "C40565"},
                   {"label": "Kotzschau", "value": "C40566"},
                   {"label": "Kretzschau", "value": "C40567"},
                   {"label": "Kroppenstedt", "value": "C40568"},
                   {"label": "Kropstadt", "value": "C40569"},
                   {"label": "Krumpa", "value": "C40570"},
                   {"label": "Kusey", "value": "C40571"},
                   {"label": "Landsberg", "value": "C40572"},
                   {"label": "Langeln", "value": "C40573"},
                   {"label": "Langenbogen", "value": "C40574"},
                   {"label": "Langendorf", "value": "C40575"},
                   {"label": "Langeneichstadt", "value": "C40576"},
                   {"label": "Langenstein", "value": "C40577"},
                   {"label": "Laucha", "value": "C40578"},
                   {"label": "Leissling", "value": "C40579"},
                   {"label": "Leitzkau", "value": "C40580"},
                   {"label": "Letzlingen", "value": "C40581"},
                   {"label": "Leuna", "value": "C40582"},
                   {"label": "Lieskau", "value": "C40583"},
                   {"label": "Lindau", "value": "C40584"},
                   {"label": "Lobejun", "value": "C40585"},
                   {"label": "Loberitz", "value": "C40586"},
                   {"label": "Loburg", "value": "C40587"},
                   {"label": "Lochau", "value": "C40588"},
                   {"label": "Lostau", "value": "C40589"},
                   {"label": "Luderitz", "value": "C40590"},
                   {"label": "Luftkurort Arendsee", "value": "C40591"},
                   {"label": "Lutzen", "value": "C40592"},
                   {"label": "Magdeburg", "value": "C40593"},
                   {"label": "Mansfeld", "value": "C40594"},
                   {"label": "Mehringen", "value": "C40595"},
                   {"label": "Meitzendorf", "value": "C40596"},
                   {"label": "Merseburg", "value": "C40597"},
                   {"label": "Mieste", "value": "C40598"},
                   {"label": "Mockern", "value": "C40599"},
                   {"label": "Mohlau", "value": "C40600"},
                   {"label": "Moser", "value": "C40601"},
                   {"label": "Mucheln", "value": "C40602"},
                   {"label": "Muhlanger", "value": "C40603"},
                   {"label": "Muldenstein", "value": "C40604"},
                   {"label": "Muschwitz", "value": "C40605"},
                   {"label": "Nachterstedt", "value": "C40606"},
                   {"label": "Nauendorf", "value": "C40607"},
                   {"label": "Naumburg", "value": "C40608"},
                   {"label": "Nebra", "value": "C40609"},
                   {"label": "Neinstedt", "value": "C40610"},
                   {"label": "Nessa", "value": "C40611"},
                   {"label": "Neue Neustadt", "value": "C40612"},
                   {"label": "Neundorf", "value": "C40613"},
                   {"label": "Niederndodeleben", "value": "C40614"},
                   {"label": "Niemberg", "value": "C40615"},
                   {"label": "NienburgSaale", "value": "C40616"},
                   {"label": "Nudersdorf", "value": "C40617"},
                   {"label": "Oberroblingen", "value": "C40618"},
                   {"label": "Obhausen", "value": "C40619"},
                   {"label": "Oebisfelde", "value": "C40620"},
                   {"label": "Oppin", "value": "C40621"},
                   {"label": "Oranienbaum", "value": "C40622"},
                   {"label": "Oschersleben", "value": "C40623"},
                   {"label": "Osterburg", "value": "C40624"},
                   {"label": "Osterfeld", "value": "C40625"},
                   {"label": "Osterhausen", "value": "C40626"},
                   {"label": "Osternienburg", "value": "C40627"},
                   {"label": "Osternienburger Land", "value": "C40628"},
                   {"label": "Osterwieck", "value": "C40629"},
                   {"label": "Peissen", "value": "C40630"},
                   {"label": "Piesteritz", "value": "C40631"},
                   {"label": "Plotzkau", "value": "C40632"},
                   {"label": "Plotzky", "value": "C40633"},
                   {"label": "Polleben", "value": "C40634"},
                   {"label": "Pouch", "value": "C40635"},
                   {"label": "Pratau", "value": "C40636"},
                   {"label": "Prettin", "value": "C40637"},
                   {"label": "Pretzier", "value": "C40638"},
                   {"label": "Pretzsch", "value": "C40639"},
                   {"label": "Prittitz", "value": "C40640"},
                   {"label": "Quedlinburg", "value": "C40641"},
                   {"label": "Queis", "value": "C40642"},
                   {"label": "Quellendorf", "value": "C40643"},
                   {"label": "Querfurt", "value": "C40644"},
                   {"label": "Radegast", "value": "C40645"},
                   {"label": "Radis", "value": "C40646"},
                   {"label": "Raguhn", "value": "C40647"},
                   {"label": "Ramsin", "value": "C40648"},
                   {"label": "Rassnitz", "value": "C40649"},
                   {"label": "Reichardtswerben", "value": "C40650"},
                   {"label": "Reussen", "value": "C40651"},
                   {"label": "Rieder", "value": "C40652"},
                   {"label": "Riestedt", "value": "C40653"},
                   {"label": "Roblingen am See", "value": "C40654"},
                   {"label": "Rodleben", "value": "C40655"},
                   {"label": "Rogatz", "value": "C40656"},
                   {"label": "Roitzsch", "value": "C40657"},
                   {"label": "Rossla", "value": "C40658"},
                   {"label": "Rosslau", "value": "C40659"},
                   {"label": "Rottleberode", "value": "C40660"},
                   {"label": "Rubeland", "value": "C40661"},
                   {"label": "Salzmunde", "value": "C40662"},
                   {"label": "Salzwedel", "value": "C40663"},
                   {"label": "Samswegen", "value": "C40664"},
                   {"label": "Sandau", "value": "C40665"},
                   {"label": "Sandersdorf", "value": "C40666"},
                   {"label": "Sandersleben", "value": "C40667"},
                   {"label": "Sangerhausen", "value": "C40668"},
                   {"label": "Schafstadt", "value": "C40669"},
                   {"label": "Schermen", "value": "C40670"},
                   {"label": "Schkopau", "value": "C40671"},
                   {"label": "Schlaitz", "value": "C40672"},
                   {"label": "Schneidlingen", "value": "C40673"},
                   {"label": "Schochwitz", "value": "C40674"},
                   {"label": "Schonburg", "value": "C40675"},
                   {"label": "Schonebeck", "value": "C40676"},
                   {"label": "Schonhausen", "value": "C40677"},
                   {"label": "Schraplau", "value": "C40678"},
                   {"label": "Schwanebeck", "value": "C40679"},
                   {"label": "Seehausen", "value": "C40680"},
                   {"label": "Sennewitz", "value": "C40681"},
                   {"label": "Seyda", "value": "C40682"},
                   {"label": "Siersleben", "value": "C40683"},
                   {"label": "Sommersdorf", "value": "C40684"},
                   {"label": "Spergau", "value": "C40685"},
                   {"label": "Stapelburg", "value": "C40686"},
                   {"label": "Stassfurt", "value": "C40687"},
                   {"label": "Stedten", "value": "C40688"},
                   {"label": "Stendal", "value": "C40689"},
                   {"label": "Stolberg", "value": "C40690"},
                   {"label": "Stossen", "value": "C40691"},
                   {"label": "Strobeck", "value": "C40692"},
                   {"label": "Suplingen", "value": "C40693"},
                   {"label": "Tangerhutte", "value": "C40694"},
                   {"label": "Tangermunde", "value": "C40695"},
                   {"label": "Teicha", "value": "C40696"},
                   {"label": "Teuchel", "value": "C40697"},
                   {"label": "Teuchern", "value": "C40698"},
                   {"label": "Teutschenthal", "value": "C40699"},
                   {"label": "Thale", "value": "C40700"},
                   {"label": "Thalheim", "value": "C40701"},
                   {"label": "Theissen", "value": "C40702"},
                   {"label": "Timmenrode", "value": "C40703"},
                   {"label": "Tollwitz", "value": "C40704"},
                   {"label": "Trebitz", "value": "C40705"},
                   {"label": "Tucheim", "value": "C40706"},
                   {"label": "Uchtspringe", "value": "C40707"},
                   {"label": "Uenglingen", "value": "C40708"},
                   {"label": "Uftrungen", "value": "C40709"},
                   {"label": "Uichteritz", "value": "C40710"},
                   {"label": "Ummendorf", "value": "C40711"},
                   {"label": "Unseburg", "value": "C40712"},
                   {"label": "Veckenstedt", "value": "C40713"},
                   {"label": "Vockerode", "value": "C40714"},
                   {"label": "Volkstedt", "value": "C40715"},
                   {"label": "Volpke", "value": "C40716"},
                   {"label": "Wahlitz", "value": "C40717"},
                   {"label": "Wallendorf", "value": "C40718"},
                   {"label": "Wallhausen", "value": "C40719"},
                   {"label": "Wallwitz", "value": "C40720"},
                   {"label": "Wansleben", "value": "C40721"},
                   {"label": "Wanzleben", "value": "C40722"},
                   {"label": "Wasserleben", "value": "C40723"},
                   {"label": "Weddersleben", "value": "C40724"},
                   {"label": "Wefensleben", "value": "C40725"},
                   {"label": "Weferlingen", "value": "C40726"},
                   {"label": "Wegeleben", "value": "C40727"},
                   {"label": "Weissandt-Golzau", "value": "C40728"},
                   {"label": "Weissenfels", "value": "C40729"},
                   {"label": "Wellen", "value": "C40730"},
                   {"label": "Welsleben", "value": "C40731"},
                   {"label": "Wernigerode", "value": "C40732"},
                   {"label": "Westeregeln", "value": "C40733"},
                   {"label": "Westerhausen", "value": "C40734"},
                   {"label": "Wetterzeube", "value": "C40735"},
                   {"label": "Wettin", "value": "C40736"},
                   {"label": "Wiederstedt", "value": "C40737"},
                   {"label": "Wimmelburg", "value": "C40738"},
                   {"label": "Wippra", "value": "C40739"},
                   {"label": "Wittenberg", "value": "C40740"},
                   {"label": "Wolfen", "value": "C40741"},
                   {"label": "Wolferode", "value": "C40742"},
                   {"label": "Wolmirsleben", "value": "C40743"},
                   {"label": "Wolmirstedt", "value": "C40744"},
                   {"label": "Worlitz", "value": "C40745"},
                   {"label": "Wulfen", "value": "C40746"},
                   {"label": "Zahna", "value": "C40747"},
                   {"label": "Zappendorf", "value": "C40748"},
                   {"label": "Zeitz", "value": "C40749"},
                   {"label": "Zerbst", "value": "C40750"},
                   {"label": "Zielitz", "value": "C40751"},
                   {"label": "Zorbig", "value": "C40752"},
                   {"label": "Zoschen", "value": "C40753"},
                   {"label": "Zscherben", "value": "C40754"},
                   {"label": "Zscherndorf", "value": "C40755"},
                   {"label": "Zschornewitz", "value": "C40756"}],
 "Schaan": [{"label": "Schaan", "value": "C65077"}],
 "Schaffhausen": [{"label": "Beringen", "value": "C116947"},
                  {"label": "Bezirk Oberklettgau", "value": "C116948"},
                  {"label": "Bezirk Reiat", "value": "C116949"},
                  {"label": "Bezirk Schaffhausen", "value": "C116950"},
                  {"label": "Bezirk Schleitheim", "value": "C116951"},
                  {"label": "Bezirk Stein", "value": "C116952"},
                  {"label": "Bezirk Unterklettgau", "value": "C116953"},
                  {"label": "Hallau", "value": "C116954"},
                  {"label": "Lohningen", "value": "C116955"},
                  {"label": "Neuhausen", "value": "C116956"},
                  {"label": "Neunkirch", "value": "C116957"},
                  {"label": "Ramsen", "value": "C116958"},
                  {"label": "Schaffhausen", "value": "C116959"},
                  {"label": "Schleitheim", "value": "C116960"},
                  {"label": "Stein am Rhein", "value": "C116961"},
                  {"label": "Stetten", "value": "C116962"},
                  {"label": "Thayngen", "value": "C116963"},
                  {"label": "Wilchingen", "value": "C116964"}],
 "Schellenberg": [{"label": "Schellenberg", "value": "C65078"}],
 "Schleswig-Holstein": [{"label": "Achtrup", "value": "C40757"},
                        {"label": "Ahrensbok", "value": "C40758"},
                        {"label": "Ahrensburg", "value": "C40759"},
                        {"label": "Albersdorf", "value": "C40760"},
                        {"label": "Alt Duvenstedt", "value": "C40761"},
                        {"label": "Altenholz", "value": "C40762"},
                        {"label": "Altenkrempe", "value": "C40763"},
                        {"label": "Alveslohe", "value": "C40764"},
                        {"label": "Appen", "value": "C40765"},
                        {"label": "Ascheberg", "value": "C40766"},
                        {"label": "Aukrug", "value": "C40767"},
                        {"label": "Bad Bramstedt", "value": "C40768"},
                        {"label": "Bad Oldesloe", "value": "C40769"},
                        {"label": "Bad Schwartau", "value": "C40770"},
                        {"label": "Bad Segeberg", "value": "C40771"},
                        {"label": "Bargfeld-Stegen", "value": "C40772"},
                        {"label": "Bargteheide", "value": "C40773"},
                        {"label": "Bark", "value": "C40774"},
                        {"label": "Barkelsby", "value": "C40775"},
                        {"label": "Barmstedt", "value": "C40776"},
                        {"label": "Barsbuttel", "value": "C40777"},
                        {"label": "Berkenthin", "value": "C40778"},
                        {"label": "Blekendorf", "value": "C40779"},
                        {"label": "Bokholt-Hanredder", "value": "C40780"},
                        {"label": "Bollingstedt", "value": "C40781"},
                        {"label": "Bonebuttel", "value": "C40782"},
                        {"label": "Bonningstedt", "value": "C40783"},
                        {"label": "Boostedt", "value": "C40784"},
                        {"label": "Bordelum", "value": "C40785"},
                        {"label": "Bordesholm", "value": "C40786"},
                        {"label": "Borgstedt", "value": "C40787"},
                        {"label": "Bornhoved", "value": "C40788"},
                        {"label": "Bornsen", "value": "C40789"},
                        {"label": "Borstel-Hohenraden", "value": "C40790"},
                        {"label": "Bosau", "value": "C40791"},
                        {"label": "Bosdorf", "value": "C40792"},
                        {"label": "Bovenau", "value": "C40793"},
                        {"label": "Brande-Hornerkirchen", "value": "C40794"},
                        {"label": "Bredenbek", "value": "C40795"},
                        {"label": "Bredstedt", "value": "C40796"},
                        {"label": "Breiholz", "value": "C40797"},
                        {"label": "Breitenburg", "value": "C40798"},
                        {"label": "Breitenfelde", "value": "C40799"},
                        {"label": "Brekendorf", "value": "C40800"},
                        {"label": "Breklum", "value": "C40801"},
                        {"label": "Brokdorf", "value": "C40802"},
                        {"label": "Brokstedt", "value": "C40803"},
                        {"label": "Brugge", "value": "C40804"},
                        {"label": "Brunsbuttel", "value": "C40805"},
                        {"label": "Buchen", "value": "C40806"},
                        {"label": "Buchholz", "value": "C40807"},
                        {"label": "Budelsdorf", "value": "C40808"},
                        {"label": "Burg", "value": "C40809"},
                        {"label": "Burg auf Fehmarn", "value": "C40810"},
                        {"label": "Busdorf", "value": "C40811"},
                        {"label": "Busum", "value": "C40812"},
                        {"label": "Dageling", "value": "C40813"},
                        {"label": "Dahme", "value": "C40814"},
                        {"label": "Damp", "value": "C40815"},
                        {"label": "Danischenhagen", "value": "C40816"},
                        {"label": "Dannewerk", "value": "C40817"},
                        {"label": "Dassendorf", "value": "C40818"},
                        {"label": "Delingsdorf", "value": "C40819"},
                        {"label": "Dobersdorf", "value": "C40820"},
                        {"label": "Dollerup", "value": "C40821"},
                        {"label": "Drelsdorf", "value": "C40822"},
                        {"label": "Eckernforde", "value": "C40823"},
                        {"label": "Eddelak", "value": "C40824"},
                        {"label": "Eggebek", "value": "C40825"},
                        {"label": "Ellerau", "value": "C40826"},
                        {"label": "Ellerbek", "value": "C40827"},
                        {"label": "Ellerhoop", "value": "C40828"},
                        {"label": "Elmenhorst", "value": "C40829"},
                        {"label": "Elmshorn", "value": "C40830"},
                        {"label": "Elsdorf-Westermuhlen", "value": "C40831"},
                        {"label": "Emkendorf", "value": "C40832"},
                        {"label": "Emmelsbull-Horsbull", "value": "C40833"},
                        {"label": "Erfde", "value": "C40834"},
                        {"label": "Escheburg", "value": "C40835"},
                        {"label": "Eutin", "value": "C40836"},
                        {"label": "Fahrdorf", "value": "C40837"},
                        {"label": "Fahrenkrug", "value": "C40838"},
                        {"label": "Fehmarn", "value": "C40839"},
                        {"label": "Felde", "value": "C40840"},
                        {"label": "Felm", "value": "C40841"},
                        {"label": "Fleckeby", "value": "C40842"},
                        {"label": "Flensburg", "value": "C40843"},
                        {"label": "Flintbek", "value": "C40844"},
                        {"label": "Fockbek", "value": "C40845"},
                        {"label": "Freienwill", "value": "C40846"},
                        {"label": "Friedrichskoog", "value": "C40847"},
                        {"label": "Friedrichstadt", "value": "C40848"},
                        {"label": "Garding", "value": "C40849"},
                        {"label": "Geesthacht", "value": "C40850"},
                        {"label": "Gelting", "value": "C40851"},
                        {"label": "Gettorf", "value": "C40852"},
                        {"label": "Giekau", "value": "C40853"},
                        {"label": "Glinde", "value": "C40854"},
                        {"label": "Glucksburg", "value": "C40855"},
                        {"label": "Gluckstadt", "value": "C40856"},
                        {"label": "Gnutz", "value": "C40857"},
                        {"label": "Goel", "value": "C40858"},
                        {"label": "Grebin", "value": "C40859"},
                        {"label": "Gremersdorf", "value": "C40860"},
                        {"label": "Gromitz", "value": "C40861"},
                        {"label": "Gronwohld", "value": "C40862"},
                        {"label": "Gross Gronau", "value": "C40863"},
                        {"label": "Gross Kummerfeld", "value": "C40864"},
                        {"label": "Gross Rheide", "value": "C40865"},
                        {"label": "Gross Wittensee", "value": "C40866"},
                        {"label": "Grossenaspe", "value": "C40867"},
                        {"label": "Grossenbrode", "value": "C40868"},
                        {"label": "Grossensee", "value": "C40869"},
                        {"label": "Grossenwiehe", "value": "C40870"},
                        {"label": "Grosshansdorf", "value": "C40871"},
                        {"label": "Grosssolt", "value": "C40872"},
                        {"label": "Grube", "value": "C40873"},
                        {"label": "Gudow", "value": "C40874"},
                        {"label": "Gulzow", "value": "C40875"},
                        {"label": "Guster", "value": "C40876"},
                        {"label": "Halstenbek", "value": "C40877"},
                        {"label": "Hamberge", "value": "C40878"},
                        {"label": "Hamdorf", "value": "C40879"},
                        {"label": "Hammoor", "value": "C40880"},
                        {"label": "Handewitt", "value": "C40881"},
                        {"label": "Hanerau-Hademarschen", "value": "C40882"},
                        {"label": "Harrislee", "value": "C40883"},
                        {"label": "Hartenholm", "value": "C40884"},
                        {"label": "Haselau", "value": "C40885"},
                        {"label": "Haseldorf", "value": "C40886"},
                        {"label": "Hasloh", "value": "C40887"},
                        {"label": "Hattstedt", "value": "C40888"},
                        {"label": "Heide", "value": "C40889"},
                        {"label": "Heidgraben", "value": "C40890"},
                        {"label": "Heikendorf", "value": "C40891"},
                        {"label": "Heiligenhafen", "value": "C40892"},
                        {"label": "Heiligenstedten", "value": "C40893"},
                        {"label": "Heist", "value": "C40894"},
                        {"label": "Helgoland", "value": "C40895"},
                        {"label": "Hemdingen", "value": "C40896"},
                        {"label": "Hemmingstedt", "value": "C40897"},
                        {"label": "Hennstedt", "value": "C40898"},
                        {"label": "Henstedt-Ulzburg", "value": "C40899"},
                        {"label": "Heringsdorf", "value": "C40900"},
                        {"label": "Herzhorn", "value": "C40901"},
                        {"label": "Hetlingen", "value": "C40902"},
                        {"label": "Hitzhusen", "value": "C40903"},
                        {"label": "Hochdonn", "value": "C40904"},
                        {"label": "Hohenaspe", "value": "C40905"},
                        {"label": "Hohenfelde", "value": "C40906"},
                        {"label": "Hohenlockstedt", "value": "C40907"},
                        {"label": "Hohenwestedt", "value": "C40908"},
                        {"label": "Hohn", "value": "C40909"},
                        {"label": "Hoisdorf", "value": "C40910"},
                        {"label": "Hollingstedt", "value": "C40911"},
                        {"label": "Holm", "value": "C40912"},
                        {"label": "Holtsee", "value": "C40913"},
                        {"label": "Horst", "value": "C40914"},
                        {"label": "Hurup", "value": "C40915"},
                        {"label": "Husum", "value": "C40916"},
                        {"label": "Itzehoe", "value": "C40917"},
                        {"label": "Itzstedt", "value": "C40918"},
                        {"label": "Jarplund-Weding", "value": "C40919"},
                        {"label": "Jerrishoe", "value": "C40920"},
                        {"label": "Jersbek", "value": "C40921"},
                        {"label": "Jevenstedt", "value": "C40922"},
                        {"label": "Jubek", "value": "C40923"},
                        {"label": "Kaltenkirchen", "value": "C40924"},
                        {"label": "Kappeln", "value": "C40925"},
                        {"label": "Kasseedorf", "value": "C40926"},
                        {"label": "Kastorf", "value": "C40927"},
                        {"label": "Kayhude", "value": "C40928"},
                        {"label": "Keitum", "value": "C40929"},
                        {"label": "Kellenhusen", "value": "C40930"},
                        {"label": "Kellinghusen", "value": "C40931"},
                        {"label": "Kiebitzreihe", "value": "C40932"},
                        {"label": "Kiel", "value": "C40933"},
                        {"label": "Kisdorf", "value": "C40934"},
                        {"label": "Klausdorf", "value": "C40935"},
                        {"label": "Klein Nordende", "value": "C40936"},
                        {"label": "Klein Offenseth-Sparrieshoop",
                         "value": "C40937"},
                        {"label": "Klein Ronnau", "value": "C40938"},
                        {"label": "Kollmar", "value": "C40939"},
                        {"label": "Kolln-Reisiek", "value": "C40940"},
                        {"label": "Kosel", "value": "C40941"},
                        {"label": "Krempe", "value": "C40942"},
                        {"label": "Kremperheide", "value": "C40943"},
                        {"label": "Kronshagen", "value": "C40944"},
                        {"label": "Kropp", "value": "C40945"},
                        {"label": "Kroppelshagen-Fahrendorf",
                         "value": "C40946"},
                        {"label": "Krummesse", "value": "C40947"},
                        {"label": "Kuddeworde", "value": "C40948"},
                        {"label": "Kummerfeld", "value": "C40949"},
                        {"label": "Laboe", "value": "C40950"},
                        {"label": "Ladelund", "value": "C40951"},
                        {"label": "Lagerdorf", "value": "C40952"},
                        {"label": "Langballig", "value": "C40953"},
                        {"label": "Langenhorn", "value": "C40954"},
                        {"label": "Langstedt", "value": "C40955"},
                        {"label": "Langwedel", "value": "C40956"},
                        {"label": "Lasbek", "value": "C40957"},
                        {"label": "Lauenburg", "value": "C40958"},
                        {"label": "Leck", "value": "C40959"},
                        {"label": "Leezen", "value": "C40960"},
                        {"label": "Lehe", "value": "C40961"},
                        {"label": "Lehmkuhlen", "value": "C40962"},
                        {"label": "Lensahn", "value": "C40963"},
                        {"label": "Lentfohrden", "value": "C40964"},
                        {"label": "Linau", "value": "C40965"},
                        {"label": "Lindau", "value": "C40966"},
                        {"label": "Lindewitt", "value": "C40967"},
                        {"label": "List", "value": "C40968"},
                        {"label": "Lohe-Rickelshof", "value": "C40969"},
                        {"label": "Lubeck", "value": "C40970"},
                        {"label": "Lunden", "value": "C40971"},
                        {"label": "Lurschau", "value": "C40972"},
                        {"label": "Lutjenburg", "value": "C40973"},
                        {"label": "Lutjensee", "value": "C40974"},
                        {"label": "Malente", "value": "C40975"},
                        {"label": "Martensrade", "value": "C40976"},
                        {"label": "Meldorf", "value": "C40977"},
                        {"label": "Melsdorf", "value": "C40978"},
                        {"label": "Mielkendorf", "value": "C40979"},
                        {"label": "Mildstedt", "value": "C40980"},
                        {"label": "Mohrkirch", "value": "C40981"},
                        {"label": "Molfsee", "value": "C40982"},
                        {"label": "Molln", "value": "C40983"},
                        {"label": "Monkeberg", "value": "C40984"},
                        {"label": "Moorrege", "value": "C40985"},
                        {"label": "Munkbrarup", "value": "C40986"},
                        {"label": "Munsterdorf", "value": "C40987"},
                        {"label": "Nahe", "value": "C40988"},
                        {"label": "Negernbotel", "value": "C40989"},
                        {"label": "Neuberend", "value": "C40990"},
                        {"label": "Neudorf-Bornstein", "value": "C40991"},
                        {"label": "Neukirchen", "value": "C40992"},
                        {"label": "Neumunster", "value": "C40993"},
                        {"label": "Neustadt in Holstein", "value": "C40994"},
                        {"label": "Neuwittenbek", "value": "C40995"},
                        {"label": "Niebull", "value": "C40996"},
                        {"label": "Nindorf", "value": "C40997"},
                        {"label": "Norderstedt", "value": "C40998"},
                        {"label": "Nordhastedt", "value": "C40999"},
                        {"label": "Nortorf", "value": "C41000"},
                        {"label": "Nubbel", "value": "C41001"},
                        {"label": "Nusse", "value": "C41002"},
                        {"label": "Nutzen", "value": "C41003"},
                        {"label": "Oelixdorf", "value": "C41004"},
                        {"label": "Oeversee", "value": "C41005"},
                        {"label": "Oldenburg in Holstein", "value": "C41006"},
                        {"label": "Oldendorf", "value": "C41007"},
                        {"label": "Oldenswort", "value": "C41008"},
                        {"label": "Osdorf", "value": "C41009"},
                        {"label": "Ostenfeld", "value": "C41010"},
                        {"label": "Osterronfeld", "value": "C41011"},
                        {"label": "Oststeinbek", "value": "C41012"},
                        {"label": "Owschlag", "value": "C41013"},
                        {"label": "Padenstedt", "value": "C41014"},
                        {"label": "Pahlen", "value": "C41015"},
                        {"label": "Panker", "value": "C41016"},
                        {"label": "Pellworm", "value": "C41017"},
                        {"label": "Pinneberg", "value": "C41018"},
                        {"label": "Plon", "value": "C41019"},
                        {"label": "Politz", "value": "C41020"},
                        {"label": "Preetz", "value": "C41021"},
                        {"label": "Prisdorf", "value": "C41022"},
                        {"label": "Probsteierhagen", "value": "C41023"},
                        {"label": "Pronstorf", "value": "C41024"},
                        {"label": "Quarnbek", "value": "C41025"},
                        {"label": "Quern", "value": "C41026"},
                        {"label": "Quickborn", "value": "C41027"},
                        {"label": "Raisdorf", "value": "C41028"},
                        {"label": "Rantrum", "value": "C41029"},
                        {"label": "Ratekau", "value": "C41030"},
                        {"label": "Ratzeburg", "value": "C41031"},
                        {"label": "Reinbek", "value": "C41032"},
                        {"label": "Reinfeld", "value": "C41033"},
                        {"label": "Rellingen", "value": "C41034"},
                        {"label": "Rendsburg", "value": "C41035"},
                        {"label": "Rickert", "value": "C41036"},
                        {"label": "Rickling", "value": "C41037"},
                        {"label": "Riepsdorf", "value": "C41038"},
                        {"label": "Rieseby", "value": "C41039"},
                        {"label": "Risum-Lindholm", "value": "C41040"},
                        {"label": "Ruhwinkel", "value": "C41041"},
                        {"label": "Rumpel", "value": "C41042"},
                        {"label": "Sandesneben", "value": "C41043"},
                        {"label": "Sankelmark", "value": "C41044"},
                        {"label": "Sankt Margarethen", "value": "C41045"},
                        {"label": "Sankt Michaelisdonn", "value": "C41046"},
                        {"label": "Sankt Peter-Ording", "value": "C41047"},
                        {"label": "Satrup", "value": "C41048"},
                        {"label": "Schaalby", "value": "C41049"},
                        {"label": "Schacht-Audorf", "value": "C41050"},
                        {"label": "Schafflund", "value": "C41051"},
                        {"label": "Scharbeutz", "value": "C41052"},
                        {"label": "Schashagen", "value": "C41053"},
                        {"label": "Schellhorn", "value": "C41054"},
                        {"label": "Schenefeld", "value": "C41055"},
                        {"label": "Schleswig", "value": "C41056"},
                        {"label": "Schmalfeld", "value": "C41057"},
                        {"label": "Schobull", "value": "C41058"},
                        {"label": "Schonberg", "value": "C41059"},
                        {"label": "Schonkirchen", "value": "C41060"},
                        {"label": "Schonwalde am Bungsberg", "value": "C41061"},
                        {"label": "Schuby", "value": "C41062"},
                        {"label": "Schulp", "value": "C41063"},
                        {"label": "Schwabstedt", "value": "C41064"},
                        {"label": "Schwarzenbek", "value": "C41065"},
                        {"label": "Seedorf", "value": "C41066"},
                        {"label": "Selent", "value": "C41067"},
                        {"label": "Seth", "value": "C41068"},
                        {"label": "Siek", "value": "C41069"},
                        {"label": "Sierksdorf", "value": "C41070"},
                        {"label": "Sievershutten", "value": "C41071"},
                        {"label": "Sieverstedt", "value": "C41072"},
                        {"label": "Silberstedt", "value": "C41073"},
                        {"label": "Sorup", "value": "C41074"},
                        {"label": "Stadum", "value": "C41075"},
                        {"label": "Stapelfeld", "value": "C41076"},
                        {"label": "Steinberg", "value": "C41077"},
                        {"label": "Steinbergkirche", "value": "C41078"},
                        {"label": "Sterup", "value": "C41079"},
                        {"label": "Stockelsdorf", "value": "C41080"},
                        {"label": "Stolpe", "value": "C41081"},
                        {"label": "Strande", "value": "C41082"},
                        {"label": "Struvenhutten", "value": "C41083"},
                        {"label": "Suderbrarup", "value": "C41084"},
                        {"label": "Suderlugum", "value": "C41085"},
                        {"label": "Suderstapel", "value": "C41086"},
                        {"label": "Sulfeld", "value": "C41087"},
                        {"label": "Susel", "value": "C41088"},
                        {"label": "Sylt-Ost", "value": "C41089"},
                        {"label": "Tangstedt", "value": "C41090"},
                        {"label": "Tarp", "value": "C41091"},
                        {"label": "Tating", "value": "C41092"},
                        {"label": "Tellingstedt", "value": "C41093"},
                        {"label": "Timmaspe", "value": "C41094"},
                        {"label": "Timmendorfer Strand", "value": "C41095"},
                        {"label": "Tinnum", "value": "C41096"},
                        {"label": "Todenbuttel", "value": "C41097"},
                        {"label": "Todendorf", "value": "C41098"},
                        {"label": "Todesfelde", "value": "C41099"},
                        {"label": "Tolk", "value": "C41100"},
                        {"label": "Tonning", "value": "C41101"},
                        {"label": "Tornesch", "value": "C41102"},
                        {"label": "Trappenkamp", "value": "C41103"},
                        {"label": "Travemunde", "value": "C41104"},
                        {"label": "Treia", "value": "C41105"},
                        {"label": "Tremsbuttel", "value": "C41106"},
                        {"label": "Trittau", "value": "C41107"},
                        {"label": "Tuttendorf", "value": "C41108"},
                        {"label": "Uetersen", "value": "C41109"},
                        {"label": "Vaale", "value": "C41110"},
                        {"label": "Viol", "value": "C41111"},
                        {"label": "Waabs", "value": "C41112"},
                        {"label": "Wacken", "value": "C41113"},
                        {"label": "Wahlstedt", "value": "C41114"},
                        {"label": "Wanderup", "value": "C41115"},
                        {"label": "Wangels", "value": "C41116"},
                        {"label": "Wankendorf", "value": "C41117"},
                        {"label": "Wasbek", "value": "C41118"},
                        {"label": "Wattenbek", "value": "C41119"},
                        {"label": "Weddelbrook", "value": "C41120"},
                        {"label": "Weddingstedt", "value": "C41121"},
                        {"label": "Wedel", "value": "C41122"},
                        {"label": "Wees", "value": "C41123"},
                        {"label": "Wendtorf", "value": "C41124"},
                        {"label": "Wentorf bei Hamburg", "value": "C41125"},
                        {"label": "Wesselburen", "value": "C41126"},
                        {"label": "Wesseln", "value": "C41127"},
                        {"label": "Westensee", "value": "C41128"},
                        {"label": "Wester-Ohrstedt", "value": "C41129"},
                        {"label": "Westerhorn", "value": "C41130"},
                        {"label": "Westerland", "value": "C41131"},
                        {"label": "Westerronfeld", "value": "C41132"},
                        {"label": "Wewelsfleth", "value": "C41133"},
                        {"label": "Wiemersdorf", "value": "C41134"},
                        {"label": "Wilster", "value": "C41135"},
                        {"label": "Windeby", "value": "C41136"},
                        {"label": "Witzhave", "value": "C41137"},
                        {"label": "Wohltorf", "value": "C41138"},
                        {"label": "Wohrden", "value": "C41139"},
                        {"label": "Wrist", "value": "C41140"},
                        {"label": "Wyk auf Fohr", "value": "C41141"},
                        {"label": "Zarpen", "value": "C41142"}],
 "Schwyz": [{"label": "Altendorf", "value": "C116965"},
            {"label": "Arth", "value": "C116966"},
            {"label": "Bach", "value": "C116967"},
            {"label": "Bezirk Einsiedeln", "value": "C116968"},
            {"label": "Bezirk Gersau", "value": "C116969"},
            {"label": "Bezirk Hofe", "value": "C116970"},
            {"label": "Bezirk Kussnacht", "value": "C116971"},
            {"label": "Bezirk March", "value": "C116972"},
            {"label": "Bezirk Schwyz", "value": "C116973"},
            {"label": "Einsiedeln", "value": "C116974"},
            {"label": "Feusisberg", "value": "C116975"},
            {"label": "Freienbach", "value": "C116976"},
            {"label": "Gersau", "value": "C116977"},
            {"label": "Goldau", "value": "C116978"},
            {"label": "Ibach", "value": "C116979"},
            {"label": "Ingenbohl", "value": "C116980"},
            {"label": "Kussnacht", "value": "C116981"},
            {"label": "Lachen", "value": "C116982"},
            {"label": "Muotathal", "value": "C116983"},
            {"label": "Pfaffikon", "value": "C116984"},
            {"label": "Reichenburg", "value": "C116985"},
            {"label": "Rothenthurm", "value": "C116986"},
            {"label": "Sattel", "value": "C116987"},
            {"label": "Schindellegi", "value": "C116988"},
            {"label": "Schubelbach", "value": "C116989"},
            {"label": "Schwyz", "value": "C116990"},
            {"label": "Siebnen", "value": "C116991"},
            {"label": "Steinen", "value": "C116992"},
            {"label": "Tuggen", "value": "C116993"},
            {"label": "Unteriberg", "value": "C116994"},
            {"label": "Vorderthal", "value": "C116995"},
            {"label": "Wollerau", "value": "C116996"}],
 "Scotland": [{"label": "Aberchirder", "value": "C126173"},
              {"label": "Aberdeen", "value": "C126174"},
              {"label": "Aberdeen City", "value": "C126175"},
              {"label": "Aberdeenshire", "value": "C126176"},
              {"label": "Aberdour", "value": "C126177"},
              {"label": "Aberfeldy", "value": "C126178"},
              {"label": "Aberlady", "value": "C126179"},
              {"label": "Abernethy", "value": "C126180"},
              {"label": "Aboyne", "value": "C126181"},
              {"label": "Addiebrownhill", "value": "C126182"},
              {"label": "Airdrie", "value": "C126183"},
              {"label": "Airth", "value": "C126184"},
              {"label": "Alexandria", "value": "C126185"},
              {"label": "Alford", "value": "C126186"},
              {"label": "Allanton", "value": "C126187"},
              {"label": "Alloa", "value": "C126188"},
              {"label": "Almondbank", "value": "C126189"},
              {"label": "Alness", "value": "C126190"},
              {"label": "Alva", "value": "C126191"},
              {"label": "Alyth", "value": "C126192"},
              {"label": "Angus", "value": "C126193"},
              {"label": "Annan", "value": "C126194"},
              {"label": "Anstruther", "value": "C126195"},
              {"label": "Arbroath", "value": "C126196"},
              {"label": "Ardersier", "value": "C126197"},
              {"label": "Ardrishaig", "value": "C126198"},
              {"label": "Ardrossan", "value": "C126199"},
              {"label": "Argyll and Bute", "value": "C126200"},
              {"label": "Armadale", "value": "C126201"},
              {"label": "Ashgill", "value": "C126202"},
              {"label": "Auchinleck", "value": "C126203"},
              {"label": "Auchterarder", "value": "C126204"},
              {"label": "Auchtermuchty", "value": "C126205"},
              {"label": "Aviemore", "value": "C126206"},
              {"label": "Ayr", "value": "C126207"},
              {"label": "Balerno", "value": "C126208"},
              {"label": "Balfron", "value": "C126209"},
              {"label": "Balintore", "value": "C126210"},
              {"label": "Ballater", "value": "C126211"},
              {"label": "Ballingry", "value": "C126212"},
              {"label": "Balloch", "value": "C126213"},
              {"label": "Balmedie", "value": "C126214"},
              {"label": "Balmullo", "value": "C126215"},
              {"label": "Banchory", "value": "C126216"},
              {"label": "Banff", "value": "C126217"},
              {"label": "Bankfoot", "value": "C126218"},
              {"label": "Banknock", "value": "C126219"},
              {"label": "Bannockburn", "value": "C126220"},
              {"label": "Bargeddie", "value": "C126221"},
              {"label": "Barra", "value": "C126222"},
              {"label": "Barrhead", "value": "C126223"},
              {"label": "Bathgate", "value": "C126224"},
              {"label": "Bearsden", "value": "C126225"},
              {"label": "Beauly", "value": "C126226"},
              {"label": "Beith", "value": "C126227"},
              {"label": "Bellsbank", "value": "C126228"},
              {"label": "Bellshill", "value": "C126229"},
              {"label": "Benbecula", "value": "C126230"},
              {"label": "Biggar", "value": "C126231"},
              {"label": "Bilston", "value": "C126232"},
              {"label": "Bishopbriggs", "value": "C126233"},
              {"label": "Bishopton", "value": "C126234"},
              {"label": "Blackburn", "value": "C126235"},
              {"label": "Blackridge", "value": "C126236"},
              {"label": "Blackwood", "value": "C126237"},
              {"label": "Blairgowrie", "value": "C126238"},
              {"label": "Blantyre", "value": "C126239"},
              {"label": "Boness", "value": "C126240"},
              {"label": "Boddam", "value": "C126241"},
              {"label": "Bonhill", "value": "C126242"},
              {"label": "Bonnybridge", "value": "C126243"},
              {"label": "Bonnyrigg", "value": "C126244"},
              {"label": "Bothwell", "value": "C126245"},
              {"label": "Brechin", "value": "C126246"},
              {"label": "Bridge of Allan", "value": "C126247"},
              {"label": "Bridge of Earn", "value": "C126248"},
              {"label": "Bridge of Weir", "value": "C126249"},
              {"label": "Brightons", "value": "C126250"},
              {"label": "Brora", "value": "C126251"},
              {"label": "Broxburn", "value": "C126252"},
              {"label": "Buckhaven", "value": "C126253"},
              {"label": "Buckie", "value": "C126254"},
              {"label": "Burghead", "value": "C126255"},
              {"label": "Burntisland", "value": "C126256"},
              {"label": "Busby", "value": "C126257"},
              {"label": "Cairneyhill", "value": "C126258"},
              {"label": "Cairnryan", "value": "C126259"},
              {"label": "Calderbank", "value": "C126260"},
              {"label": "Caldercruix", "value": "C126261"},
              {"label": "Callander", "value": "C126262"},
              {"label": "Cambuslang", "value": "C126263"},
              {"label": "Campbeltown", "value": "C126264"},
              {"label": "Caol", "value": "C126265"},
              {"label": "Cardenden", "value": "C126266"},
              {"label": "Cardross", "value": "C126267"},
              {"label": "Carfin", "value": "C126268"},
              {"label": "Carluke", "value": "C126269"},
              {"label": "Carmunnock", "value": "C126270"},
              {"label": "Carnoustie", "value": "C126271"},
              {"label": "Carnwath", "value": "C126272"},
              {"label": "Carron", "value": "C126273"},
              {"label": "Carronshore", "value": "C126274"},
              {"label": "Castle Douglas", "value": "C126275"},
              {"label": "Catrine", "value": "C126276"},
              {"label": "Chapelhall", "value": "C126277"},
              {"label": "Charlestown of Aberlour", "value": "C126278"},
              {"label": "Chirnside", "value": "C126279"},
              {"label": "Chryston", "value": "C126280"},
              {"label": "City of Edinburgh", "value": "C126281"},
              {"label": "Clackmannan", "value": "C126282"},
              {"label": "Clackmannanshire", "value": "C126283"},
              {"label": "Clarkston", "value": "C126284"},
              {"label": "Cleland", "value": "C126285"},
              {"label": "Clydebank", "value": "C126286"},
              {"label": "Coalburn", "value": "C126287"},
              {"label": "Coaltown of Balgonie", "value": "C126288"},
              {"label": "Coatbridge", "value": "C126289"},
              {"label": "Cockenzie", "value": "C126290"},
              {"label": "Coldstream", "value": "C126291"},
              {"label": "Colinton", "value": "C126292"},
              {"label": "Comrie", "value": "C126293"},
              {"label": "Conon Bridge", "value": "C126294"},
              {"label": "Coupar Angus", "value": "C126295"},
              {"label": "Cove", "value": "C126296"},
              {"label": "Cowdenbeath", "value": "C126297"},
              {"label": "Cowie", "value": "C126298"},
              {"label": "Coylton", "value": "C126299"},
              {"label": "Crail", "value": "C126300"},
              {"label": "Crieff", "value": "C126301"},
              {"label": "Crossford", "value": "C126302"},
              {"label": "Crossgates", "value": "C126303"},
              {"label": "Crosshouse", "value": "C126304"},
              {"label": "Croy", "value": "C126305"},
              {"label": "Cruden Bay", "value": "C126306"},
              {"label": "Cullen", "value": "C126307"},
              {"label": "Culloden", "value": "C126308"},
              {"label": "Cults", "value": "C126309"},
              {"label": "Cumbernauld", "value": "C126310"},
              {"label": "Cumnock", "value": "C126311"},
              {"label": "Cupar", "value": "C126312"},
              {"label": "Currie", "value": "C126313"},
              {"label": "Dalbeattie", "value": "C126314"},
              {"label": "Dalgety Bay", "value": "C126315"},
              {"label": "Dalkeith", "value": "C126316"},
              {"label": "Dalmellington", "value": "C126317"},
              {"label": "Dalry", "value": "C126318"},
              {"label": "Dalrymple", "value": "C126319"},
              {"label": "Dalserf", "value": "C126320"},
              {"label": "Danderhall", "value": "C126321"},
              {"label": "Darvel", "value": "C126322"},
              {"label": "Denny", "value": "C126323"},
              {"label": "Dennyloanhead", "value": "C126324"},
              {"label": "Dingwall", "value": "C126325"},
              {"label": "Dollar", "value": "C126326"},
              {"label": "Dornoch", "value": "C126327"},
              {"label": "Douglas", "value": "C126328"},
              {"label": "Doune", "value": "C126329"},
              {"label": "Dreghorn", "value": "C126330"},
              {"label": "Drongan", "value": "C126331"},
              {"label": "Drumnadrochit", "value": "C126332"},
              {"label": "Dufftown", "value": "C126333"},
              {"label": "Dumbarton", "value": "C126334"},
              {"label": "Dumfries", "value": "C126335"},
              {"label": "Dumfries and Galloway", "value": "C126336"},
              {"label": "Dunbar", "value": "C126337"},
              {"label": "Dunblane", "value": "C126338"},
              {"label": "Dundee", "value": "C126339"},
              {"label": "Dundee City", "value": "C126340"},
              {"label": "Dundonald", "value": "C126341"},
              {"label": "Dunfermline", "value": "C126342"},
              {"label": "Dunipace", "value": "C126343"},
              {"label": "Dunlop", "value": "C126344"},
              {"label": "Dunoon", "value": "C126345"},
              {"label": "Duns", "value": "C126346"},
              {"label": "Duntocher", "value": "C126347"},
              {"label": "Dyce", "value": "C126348"},
              {"label": "Eaglesham", "value": "C126349"},
              {"label": "Earlston", "value": "C126350"},
              {"label": "East Ayrshire", "value": "C126351"},
              {"label": "East Calder", "value": "C126352"},
              {"label": "East Dunbartonshire", "value": "C126353"},
              {"label": "East Kilbride", "value": "C126354"},
              {"label": "East Linton", "value": "C126355"},
              {"label": "East Lothian", "value": "C126356"},
              {"label": "East Renfrewshire", "value": "C126357"},
              {"label": "East Wemyss", "value": "C126358"},
              {"label": "East Whitburn", "value": "C126359"},
              {"label": "Eastriggs", "value": "C126360"},
              {"label": "Edinburgh", "value": "C126361"},
              {"label": "Eilean Siar", "value": "C126362"},
              {"label": "Elderslie", "value": "C126363"},
              {"label": "Elgin", "value": "C126364"},
              {"label": "Ellon", "value": "C126365"},
              {"label": "Errol", "value": "C126366"},
              {"label": "Erskine", "value": "C126367"},
              {"label": "Evanton", "value": "C126368"},
              {"label": "Eyemouth", "value": "C126369"},
              {"label": "Faifley", "value": "C126370"},
              {"label": "Fairlie", "value": "C126371"},
              {"label": "Falkirk", "value": "C126372"},
              {"label": "Falkland", "value": "C126373"},
              {"label": "Fallin", "value": "C126374"},
              {"label": "Fauldhouse", "value": "C126375"},
              {"label": "Fenwick", "value": "C126376"},
              {"label": "Fife", "value": "C126377"},
              {"label": "Findochty", "value": "C126378"},
              {"label": "Fochabers", "value": "C126379"},
              {"label": "Forfar", "value": "C126380"},
              {"label": "Forres", "value": "C126381"},
              {"label": "Fort William", "value": "C126382"},
              {"label": "Forth", "value": "C126383"},
              {"label": "Fortrose", "value": "C126384"},
              {"label": "Fraserburgh", "value": "C126385"},
              {"label": "Freuchie", "value": "C126386"},
              {"label": "Friockheim", "value": "C126387"},
              {"label": "Galashiels", "value": "C126388"},
              {"label": "Galston", "value": "C126389"},
              {"label": "Garelochhead", "value": "C126390"},
              {"label": "Gartcosh", "value": "C126391"},
              {"label": "Giffnock", "value": "C126392"},
              {"label": "Girvan", "value": "C126393"},
              {"label": "Glasgow", "value": "C126394"},
              {"label": "Glasgow City", "value": "C126395"},
              {"label": "Glenboig", "value": "C126396"},
              {"label": "Glenmavis", "value": "C126397"},
              {"label": "Glenrothes", "value": "C126398"},
              {"label": "Golspie", "value": "C126399"},
              {"label": "Gorebridge", "value": "C126400"},
              {"label": "Gourock", "value": "C126401"},
              {"label": "Grangemouth", "value": "C126402"},
              {"label": "Grantown on Spey", "value": "C126403"},
              {"label": "Greenhill", "value": "C126404"},
              {"label": "Greenock", "value": "C126405"},
              {"label": "Gretna", "value": "C126406"},
              {"label": "Gullane", "value": "C126407"},
              {"label": "Haddington", "value": "C126408"},
              {"label": "Halkirk", "value": "C126409"},
              {"label": "Hallglen", "value": "C126410"},
              {"label": "Hamilton", "value": "C126411"},
              {"label": "Harthill", "value": "C126412"},
              {"label": "Hawick", "value": "C126413"},
              {"label": "Head of Muir", "value": "C126414"},
              {"label": "Helensburgh", "value": "C126415"},
              {"label": "High Blantyre", "value": "C126416"},
              {"label": "High Valleyfield", "value": "C126417"},
              {"label": "Highland", "value": "C126418"},
              {"label": "Hillside", "value": "C126419"},
              {"label": "Holytown", "value": "C126420"},
              {"label": "Hopeman", "value": "C126421"},
              {"label": "Houston", "value": "C126422"},
              {"label": "Howwood", "value": "C126423"},
              {"label": "Huntly", "value": "C126424"},
              {"label": "Inchinnan", "value": "C126425"},
              {"label": "Inchture", "value": "C126426"},
              {"label": "Innerleithen", "value": "C126427"},
              {"label": "Insch", "value": "C126428"},
              {"label": "Inverbervie", "value": "C126429"},
              {"label": "Inverclyde", "value": "C126430"},
              {"label": "Invergordon", "value": "C126431"},
              {"label": "Invergowrie", "value": "C126432"},
              {"label": "Inverkeithing", "value": "C126433"},
              {"label": "Inverkip", "value": "C126434"},
              {"label": "Inverness", "value": "C126435"},
              {"label": "Inverurie", "value": "C126436"},
              {"label": "Irvine", "value": "C126437"},
              {"label": "Isle of Arran", "value": "C126438"},
              {"label": "Isle of Bute", "value": "C126439"},
              {"label": "Isle of Cumbrae", "value": "C126440"},
              {"label": "Isle of Islay", "value": "C126441"},
              {"label": "Isle of Lewis", "value": "C126442"},
              {"label": "Isle Of Mull", "value": "C126443"},
              {"label": "Isle of North Uist", "value": "C126444"},
              {"label": "Isle of South Uist", "value": "C126445"},
              {"label": "Jedburgh", "value": "C126446"},
              {"label": "Johnstone", "value": "C126447"},
              {"label": "Keith", "value": "C126448"},
              {"label": "Kelso", "value": "C126449"},
              {"label": "Kelty", "value": "C126450"},
              {"label": "Kemnay", "value": "C126451"},
              {"label": "Kennoway", "value": "C126452"},
              {"label": "Kilbarchan", "value": "C126453"},
              {"label": "Kilbirnie", "value": "C126454"},
              {"label": "Kilcreggan", "value": "C126455"},
              {"label": "Killearn", "value": "C126456"},
              {"label": "Kilmacolm", "value": "C126457"},
              {"label": "Kilmarnock", "value": "C126458"},
              {"label": "Kilmaurs", "value": "C126459"},
              {"label": "Kilsyth", "value": "C126460"},
              {"label": "Kilwinning", "value": "C126461"},
              {"label": "Kincardine", "value": "C126462"},
              {"label": "Kinghorn", "value": "C126463"},
              {"label": "Kinglassie", "value": "C126464"},
              {"label": "Kingskettle", "value": "C126465"},
              {"label": "Kingswells", "value": "C126466"},
              {"label": "Kingussie", "value": "C126467"},
              {"label": "Kinloss", "value": "C126468"},
              {"label": "Kinross", "value": "C126469"},
              {"label": "Kintore", "value": "C126470"},
              {"label": "Kippen", "value": "C126471"},
              {"label": "Kirkcaldy", "value": "C126472"},
              {"label": "Kirkconnel", "value": "C126473"},
              {"label": "Kirkcudbright", "value": "C126474"},
              {"label": "Kirkintilloch", "value": "C126475"},
              {"label": "Kirkliston", "value": "C126476"},
              {"label": "Kirknewton", "value": "C126477"},
              {"label": "Kirkwall", "value": "C126478"},
              {"label": "Kirriemuir", "value": "C126479"},
              {"label": "Ladybank", "value": "C126480"},
              {"label": "Lamlash", "value": "C126481"},
              {"label": "Lanark", "value": "C126482"},
              {"label": "Langholm", "value": "C126483"},
              {"label": "Larbert", "value": "C126484"},
              {"label": "Largs", "value": "C126485"},
              {"label": "Larkhall", "value": "C126486"},
              {"label": "Lauder", "value": "C126487"},
              {"label": "Laurencekirk", "value": "C126488"},
              {"label": "Laurieston", "value": "C126489"},
              {"label": "Law", "value": "C126490"},
              {"label": "Lennoxtown", "value": "C126491"},
              {"label": "Lenzie", "value": "C126492"},
              {"label": "Lerwick", "value": "C126493"},
              {"label": "Leslie", "value": "C126494"},
              {"label": "Lesmahagow", "value": "C126495"},
              {"label": "Letham", "value": "C126496"},
              {"label": "Leuchars", "value": "C126497"},
              {"label": "Leven", "value": "C126498"},
              {"label": "Lhanbryde", "value": "C126499"},
              {"label": "Limekilns", "value": "C126500"},
              {"label": "Linlithgow", "value": "C126501"},
              {"label": "Linwood", "value": "C126502"},
              {"label": "Livingston", "value": "C126503"},
              {"label": "Loanhead", "value": "C126504"},
              {"label": "Locharbriggs", "value": "C126505"},
              {"label": "Lochgelly", "value": "C126506"},
              {"label": "Lochgilphead", "value": "C126507"},
              {"label": "Lochmaben", "value": "C126508"},
              {"label": "Lochwinnoch", "value": "C126509"},
              {"label": "Lockerbie", "value": "C126510"},
              {"label": "Logan", "value": "C126511"},
              {"label": "Longforgan", "value": "C126512"},
              {"label": "Longniddry", "value": "C126513"},
              {"label": "Lossiemouth", "value": "C126514"},
              {"label": "Luncarty", "value": "C126515"},
              {"label": "Lundin Links", "value": "C126516"},
              {"label": "Macduff", "value": "C126517"},
              {"label": "Macmerry", "value": "C126518"},
              {"label": "Maddiston", "value": "C126519"},
              {"label": "Markinch", "value": "C126520"},
              {"label": "Maryburgh", "value": "C126521"},
              {"label": "Mauchline", "value": "C126522"},
              {"label": "Maxwellheugh", "value": "C126523"},
              {"label": "Maybole", "value": "C126524"},
              {"label": "Mayfield", "value": "C126525"},
              {"label": "Melrose", "value": "C126526"},
              {"label": "Menstrie", "value": "C126527"},
              {"label": "Methil", "value": "C126528"},
              {"label": "Methven", "value": "C126529"},
              {"label": "Mid Calder", "value": "C126530"},
              {"label": "Midlothian", "value": "C126531"},
              {"label": "Millport", "value": "C126532"},
              {"label": "Milltimber", "value": "C126533"},
              {"label": "Milnathort", "value": "C126534"},
              {"label": "Milngavie", "value": "C126535"},
              {"label": "Milton of Campsie", "value": "C126536"},
              {"label": "Milton of Leys", "value": "C126537"},
              {"label": "Mintlaw", "value": "C126538"},
              {"label": "Moffat", "value": "C126539"},
              {"label": "Monifieth", "value": "C126540"},
              {"label": "Montrose", "value": "C126541"},
              {"label": "Moodiesburn", "value": "C126542"},
              {"label": "Moray", "value": "C126543"},
              {"label": "Mossblown", "value": "C126544"},
              {"label": "Motherwell", "value": "C126545"},
              {"label": "Muir of Ord", "value": "C126546"},
              {"label": "Muirhead", "value": "C126547"},
              {"label": "Muirkirk", "value": "C126548"},
              {"label": "Musselburgh", "value": "C126549"},
              {"label": "Nairn", "value": "C126550"},
              {"label": "Neilston", "value": "C126551"},
              {"label": "Netherlee", "value": "C126552"},
              {"label": "New Cumnock", "value": "C126553"},
              {"label": "New Pitsligo", "value": "C126554"},
              {"label": "New Stevenston", "value": "C126555"},
              {"label": "Newarthill", "value": "C126556"},
              {"label": "Newbridge", "value": "C126557"},
              {"label": "Newburgh", "value": "C126558"},
              {"label": "Newmacher", "value": "C126559"},
              {"label": "Newmains", "value": "C126560"},
              {"label": "Newmilns", "value": "C126561"},
              {"label": "Newport-on-Tay", "value": "C126562"},
              {"label": "Newton Mearns", "value": "C126563"},
              {"label": "Newton Stewart", "value": "C126564"},
              {"label": "Newtonhill", "value": "C126565"},
              {"label": "Newtonmore", "value": "C126566"},
              {"label": "Newtown St Boswells", "value": "C126567"},
              {"label": "North Ayrshire", "value": "C126568"},
              {"label": "North Berwick", "value": "C126569"},
              {"label": "North Lanarkshire", "value": "C126570"},
              {"label": "North Queensferry", "value": "C126571"},
              {"label": "Oakley", "value": "C126572"},
              {"label": "Oban", "value": "C126573"},
              {"label": "Ochiltree", "value": "C126574"},
              {"label": "Old Kilpatrick", "value": "C126575"},
              {"label": "Oldmeldrum", "value": "C126576"},
              {"label": "Orkney", "value": "C126577"},
              {"label": "Orkney Islands", "value": "C126578"},
              {"label": "Ormiston", "value": "C126579"},
              {"label": "Overtown", "value": "C126580"},
              {"label": "Paisley", "value": "C126581"},
              {"label": "Patna", "value": "C126582"},
              {"label": "Peebles", "value": "C126583"},
              {"label": "Pencaitland", "value": "C126584"},
              {"label": "Penicuik", "value": "C126585"},
              {"label": "Perth", "value": "C126586"},
              {"label": "Perth and Kinross", "value": "C126587"},
              {"label": "Peterculter", "value": "C126588"},
              {"label": "Peterhead", "value": "C126589"},
              {"label": "Pitlochry", "value": "C126590"},
              {"label": "Pitmedden", "value": "C126591"},
              {"label": "Pittenweem", "value": "C126592"},
              {"label": "Plains", "value": "C126593"},
              {"label": "Plean", "value": "C126594"},
              {"label": "Polbeth", "value": "C126595"},
              {"label": "Polmont", "value": "C126596"},
              {"label": "Port Bannatyne", "value": "C126597"},
              {"label": "Port Erroll", "value": "C126598"},
              {"label": "Port Glasgow", "value": "C126599"},
              {"label": "Portknockie", "value": "C126600"},
              {"label": "Portlethen", "value": "C126601"},
              {"label": "Portree", "value": "C126602"},
              {"label": "Portsoy", "value": "C126603"},
              {"label": "Prestonpans", "value": "C126604"},
              {"label": "Prestwick", "value": "C126605"},
              {"label": "Queensferry", "value": "C126606"},
              {"label": "Ratho", "value": "C126607"},
              {"label": "Ratho Station", "value": "C126608"},
              {"label": "Redding", "value": "C126609"},
              {"label": "Reddingmuirhead", "value": "C126610"},
              {"label": "Renfrew", "value": "C126611"},
              {"label": "Renfrewshire", "value": "C126612"},
              {"label": "Renton", "value": "C126613"},
              {"label": "Rhu", "value": "C126614"},
              {"label": "Rosehearty", "value": "C126615"},
              {"label": "Rosewell", "value": "C126616"},
              {"label": "Roslin", "value": "C126617"},
              {"label": "Rosneath", "value": "C126618"},
              {"label": "Rosyth", "value": "C126619"},
              {"label": "Rothes", "value": "C126620"},
              {"label": "Rothesay", "value": "C126621"},
              {"label": "Rothienorman", "value": "C126622"},
              {"label": "Rutherglen", "value": "C126623"},
              {"label": "Saint Andrews", "value": "C126624"},
              {"label": "Saint Boswells", "value": "C126625"},
              {"label": "Saint Cyrus", "value": "C126626"},
              {"label": "Saint Monans", "value": "C126627"},
              {"label": "Saline", "value": "C126628"},
              {"label": "Salsburgh", "value": "C126629"},
              {"label": "Saltcoats", "value": "C126630"},
              {"label": "Sandbank", "value": "C126631"},
              {"label": "Sandwick", "value": "C126632"},
              {"label": "Sanquhar", "value": "C126633"},
              {"label": "Scalloway", "value": "C126634"},
              {"label": "Scone", "value": "C126635"},
              {"label": "Seafield", "value": "C126636"},
              {"label": "Selkirk", "value": "C126637"},
              {"label": "Shetland Islands", "value": "C126638"},
              {"label": "Shieldhill", "value": "C126639"},
              {"label": "Shotts", "value": "C126640"},
              {"label": "Skelmorlie", "value": "C126641"},
              {"label": "Slamannan", "value": "C126642"},
              {"label": "Smithton", "value": "C126643"},
              {"label": "South Ayrshire", "value": "C126644"},
              {"label": "South Lanarkshire", "value": "C126645"},
              {"label": "Springside", "value": "C126646"},
              {"label": "Stanley", "value": "C126647"},
              {"label": "Stenhousemuir", "value": "C126648"},
              {"label": "Stepps", "value": "C126649"},
              {"label": "Stevenston", "value": "C126650"},
              {"label": "Stewarton", "value": "C126651"},
              {"label": "Stirling", "value": "C126652"},
              {"label": "Stonehaven", "value": "C126653"},
              {"label": "Stonehouse", "value": "C126654"},
              {"label": "Stoneyburn", "value": "C126655"},
              {"label": "Stornoway", "value": "C126656"},
              {"label": "Stranraer", "value": "C126657"},
              {"label": "Strathaven", "value": "C126658"},
              {"label": "Strathblane", "value": "C126659"},
              {"label": "Strathpeffer", "value": "C126660"},
              {"label": "Stromness", "value": "C126661"},
              {"label": "Symington", "value": "C126662"},
              {"label": "Tain", "value": "C126663"},
              {"label": "Tarbert", "value": "C126664"},
              {"label": "Tarbolton", "value": "C126665"},
              {"label": "Tayport", "value": "C126666"},
              {"label": "The Scottish Borders", "value": "C126667"},
              {"label": "Thornhill", "value": "C126668"},
              {"label": "Thornliebank", "value": "C126669"},
              {"label": "Thornton", "value": "C126670"},
              {"label": "Thurso", "value": "C126671"},
              {"label": "Tillicoultry", "value": "C126672"},
              {"label": "Tobermory", "value": "C126673"},
              {"label": "Torphins", "value": "C126674"},
              {"label": "Torrance", "value": "C126675"},
              {"label": "Townhill", "value": "C126676"},
              {"label": "Tranent", "value": "C126677"},
              {"label": "Troon", "value": "C126678"},
              {"label": "Tullibody", "value": "C126679"},
              {"label": "Turriff", "value": "C126680"},
              {"label": "Twechar", "value": "C126681"},
              {"label": "Tweedbank", "value": "C126682"},
              {"label": "Uddingston", "value": "C126683"},
              {"label": "Ullapool", "value": "C126684"},
              {"label": "Vale of Leven", "value": "C126685"},
              {"label": "Viewpark", "value": "C126686"},
              {"label": "Waterfoot", "value": "C126687"},
              {"label": "Wemyss Bay", "value": "C126688"},
              {"label": "West Calder", "value": "C126689"},
              {"label": "West Dunbartonshire", "value": "C126690"},
              {"label": "West Kilbride", "value": "C126691"},
              {"label": "West Linton", "value": "C126692"},
              {"label": "West Lothian", "value": "C126693"},
              {"label": "Westhill", "value": "C126694"},
              {"label": "Westquarter", "value": "C126695"},
              {"label": "Whitburn", "value": "C126696"},
              {"label": "Whitecraig", "value": "C126697"},
              {"label": "Whitehills", "value": "C126698"},
              {"label": "Wick", "value": "C126699"},
              {"label": "Winchburgh", "value": "C126700"},
              {"label": "Windygates", "value": "C126701"},
              {"label": "Wishaw", "value": "C126702"}],
 "Sedhiou": [{"label": "Goudomp Department", "value": "C106874"},
             {"label": "Sedhiou", "value": "C106875"}],
 "Segou Region": [{"label": "Baroueli", "value": "C65847"},
                  {"label": "Cercle de San", "value": "C65848"},
                  {"label": "Ke-Macina", "value": "C65849"},
                  {"label": "Kinmparana", "value": "C65850"},
                  {"label": "Markala", "value": "C65851"},
                  {"label": "San", "value": "C65852"},
                  {"label": "Segou", "value": "C65853"},
                  {"label": "Sokolo", "value": "C65854"}],
 "Seja Municipality": [{"label": "Engure", "value": "C64936"},
                       {"label": "Tukums", "value": "C64937"}],
 "Sejong City": [{"label": "Sejong", "value": "C108426"}],
 "Sekong Province": [{"label": "Ban Thateng", "value": "C64825"},
                     {"label": "Lamam", "value": "C64826"},
                     {"label": "Muang Dakchung", "value": "C64827"},
                     {"label": "Muang Khaleum", "value": "C64828"},
                     {"label": "Muang Laman", "value": "C64829"},
                     {"label": "Muang Thateng", "value": "C64830"}],
 "Selangor": [{"label": "Ampang", "value": "C65769"},
              {"label": "Bagan Pulau Ketam", "value": "C65770"},
              {"label": "Banting", "value": "C65771"},
              {"label": "Batang Berjuntai", "value": "C65772"},
              {"label": "Batu Arang", "value": "C65773"},
              {"label": "Jenjarum", "value": "C65774"},
              {"label": "Kampong Baharu Balakong", "value": "C65775"},
              {"label": "Kampung Baru Subang", "value": "C65776"},
              {"label": "Kampung Tanjung Karang", "value": "C65777"},
              {"label": "Klang", "value": "C65778"},
              {"label": "Kuala Selangor", "value": "C65779"},
              {"label": "Kuang", "value": "C65780"},
              {"label": "Ladang Seri Kundang", "value": "C65781"},
              {"label": "Pelabuhan Klang", "value": "C65782"},
              {"label": "Petaling Jaya", "value": "C65783"},
              {"label": "Putra Heights", "value": "C65784"},
              {"label": "Rawang", "value": "C65785"},
              {"label": "Semenyih", "value": "C65786"},
              {"label": "Serendah", "value": "C65787"},
              {"label": "Shah Alam", "value": "C65788"},
              {"label": "Subang Jaya", "value": "C65789"},
              {"label": "Sungai Besar", "value": "C65790"},
              {"label": "Sungai Pelek New Village", "value": "C65791"},
              {"label": "Tanjung Sepat", "value": "C65792"}],
 "Selenge Province": [{"label": "Dzuunharaa", "value": "C75585"},
                      {"label": "Suhbaatar", "value": "C75586"}],
 "Selnica ob Dravi Municipality": [{"label": "Selnica ob Dravi",
                                    "value": "C107793"}],
 "Semic Municipality": [{"label": "Semic", "value": "C107794"}],
 "Semnan": [{"label": "Damghan", "value": "C51000"},
            {"label": "istgah-e Rah ahan-e Garmsar", "value": "C51001"},
            {"label": "Mahdishahr", "value": "C51002"},
            {"label": "Semnan", "value": "C51003"},
            {"label": "Shahrestan-e aradan", "value": "C51004"},
            {"label": "Shahrestan-e Damghan", "value": "C51005"},
            {"label": "Shahrestan-e Garmsar", "value": "C51006"},
            {"label": "Shahrestan-e Mayamey", "value": "C51007"},
            {"label": "Shahrestan-e Semnan", "value": "C51008"},
            {"label": "Shahrestan-e Shahrud", "value": "C51009"},
            {"label": "Shahrud", "value": "C51010"}],
 "Sempeter-Vrtojba Municipality": [{"label": "Rozna Dolina",
                                    "value": "C107795"},
                                   {"label": "Sempeter pri Gorici",
                                    "value": "C107796"},
                                   {"label": "Vrtojba", "value": "C107797"}],
 "Sencur Municipality": [{"label": "Hrastje", "value": "C107798"},
                         {"label": "Sencur", "value": "C107799"}],
 "Senglea": [{"label": "Imdina", "value": "C65916"}],
 "Sennar": [{"label": "Ad Dindar", "value": "C115432"},
            {"label": "As Suki", "value": "C115433"},
            {"label": "Jalqani", "value": "C115434"},
            {"label": "Kinana", "value": "C115435"},
            {"label": "Maiurno", "value": "C115436"},
            {"label": "Singa", "value": "C115437"},
            {"label": "Sinnar", "value": "C115438"}],
 "Sentilj Municipality": [{"label": "Selnica ob Muri", "value": "C107800"},
                          {"label": "Sentilj v Slov. Goricah",
                           "value": "C107801"}],
 "Sentjernej Municipality": [{"label": "Sentjernej", "value": "C107802"}],
 "Sentjur Municipality": [{"label": "Sentjur", "value": "C107803"}],
 "Sentrupert Municipality": [{"label": "Sentrupert", "value": "C107804"}],
 "Seoul": [{"label": "Dobong-gu", "value": "C108427"},
           {"label": "Dongdaemun-gu", "value": "C108428"},
           {"label": "Dongjak-gu", "value": "C108429"},
           {"label": "Eunpyeong-gu", "value": "C108430"},
           {"label": "Gangbuk-gu", "value": "C108431"},
           {"label": "Gangdong-gu", "value": "C108432"},
           {"label": "Gangnam-gu", "value": "C108433"},
           {"label": "Gangseo-gu", "value": "C108434"},
           {"label": "Geumcheon-gu", "value": "C108435"},
           {"label": "Guro-gu", "value": "C108436"},
           {"label": "Gwanak-gu", "value": "C108437"},
           {"label": "Gwangjin-gu", "value": "C108438"},
           {"label": "Jongno-gu", "value": "C108439"},
           {"label": "Jung-gu", "value": "C108440"},
           {"label": "Jungnang-gu", "value": "C108441"},
           {"label": "Mapo-gu", "value": "C108442"},
           {"label": "Nowon-gu", "value": "C108443"},
           {"label": "Seocho-gu", "value": "C108444"},
           {"label": "Seodaemun-gu", "value": "C108445"},
           {"label": "Seongbuk-gu", "value": "C108446"},
           {"label": "Seongdong-gu", "value": "C108447"},
           {"label": "Seoul", "value": "C108448"},
           {"label": "Songpa-gu", "value": "C108449"},
           {"label": "Yangcheon-gu", "value": "C108450"},
           {"label": "Yeongdeungpo-gu", "value": "C108451"},
           {"label": "Yongsan-dong", "value": "C108452"},
           {"label": "Yongsan-gu", "value": "C108453"}],
 "Sergipe": [{"label": "Amparo de Sao Francisco", "value": "C15623"},
             {"label": "Aquidaba", "value": "C15624"},
             {"label": "Aracaju", "value": "C15625"},
             {"label": "Araua", "value": "C15626"},
             {"label": "Areia Branca", "value": "C15627"},
             {"label": "Barra dos Coqueiros", "value": "C15628"},
             {"label": "Boquim", "value": "C15629"},
             {"label": "Brejo Grande", "value": "C15630"},
             {"label": "Campo do Brito", "value": "C15631"},
             {"label": "Canhoba", "value": "C15632"},
             {"label": "Caninde de Sao Francisco", "value": "C15633"},
             {"label": "Capela", "value": "C15634"},
             {"label": "Carira", "value": "C15635"},
             {"label": "Carmopolis", "value": "C15636"},
             {"label": "Cedro de Sao Joao", "value": "C15637"},
             {"label": "Cristinapolis", "value": "C15638"},
             {"label": "Cumbe", "value": "C15639"},
             {"label": "Divina Pastora", "value": "C15640"},
             {"label": "Estancia", "value": "C15641"},
             {"label": "Feira Nova", "value": "C15642"},
             {"label": "Frei Paulo", "value": "C15643"},
             {"label": "Gararu", "value": "C15644"},
             {"label": "General Maynard", "value": "C15645"},
             {"label": "Gracho Cardoso", "value": "C15646"},
             {"label": "Ilha das Flores", "value": "C15647"},
             {"label": "Indiaroba", "value": "C15648"},
             {"label": "Itabaiana", "value": "C15649"},
             {"label": "Itabaianinha", "value": "C15650"},
             {"label": "Itabi", "value": "C15651"},
             {"label": "Itaporanga dAjuda", "value": "C15652"},
             {"label": "Japaratuba", "value": "C15653"},
             {"label": "Japoata", "value": "C15654"},
             {"label": "Lagarto", "value": "C15655"},
             {"label": "Laranjeiras", "value": "C15656"},
             {"label": "Macambira", "value": "C15657"},
             {"label": "Malhada dos Bois", "value": "C15658"},
             {"label": "Malhador", "value": "C15659"},
             {"label": "Maruim", "value": "C15660"},
             {"label": "Moita Bonita", "value": "C15661"},
             {"label": "Monte Alegre de Sergipe", "value": "C15662"},
             {"label": "Muribeca", "value": "C15663"},
             {"label": "Neopolis", "value": "C15664"},
             {"label": "Nossa Senhora Aparecida", "value": "C15665"},
             {"label": "Nossa Senhora da Gloria", "value": "C15666"},
             {"label": "Nossa Senhora das Dores", "value": "C15667"},
             {"label": "Nossa Senhora de Lourdes", "value": "C15668"},
             {"label": "Nossa Senhora do Socorro", "value": "C15669"},
             {"label": "Pacatuba", "value": "C15670"},
             {"label": "Pedra Mole", "value": "C15671"},
             {"label": "Pedrinhas", "value": "C15672"},
             {"label": "Pinhao", "value": "C15673"},
             {"label": "Pirambu", "value": "C15674"},
             {"label": "Poco Redondo", "value": "C15675"},
             {"label": "Poco Verde", "value": "C15676"},
             {"label": "Porto da Folha", "value": "C15677"},
             {"label": "Propria", "value": "C15678"},
             {"label": "Riachao do Dantas", "value": "C15679"},
             {"label": "Riachuelo", "value": "C15680"},
             {"label": "Ribeiropolis", "value": "C15681"},
             {"label": "Rosario do Catete", "value": "C15682"},
             {"label": "Salgado", "value": "C15683"},
             {"label": "Santa Luzia do Itanhy", "value": "C15684"},
             {"label": "Santa Rosa de Lima", "value": "C15685"},
             {"label": "Santana do Sao Francisco", "value": "C15686"},
             {"label": "Santo Amaro das Brotas", "value": "C15687"},
             {"label": "Sao Cristovao", "value": "C15688"},
             {"label": "Sao Domingos", "value": "C15689"},
             {"label": "Sao Francisco", "value": "C15690"},
             {"label": "Sao Miguel do Aleixo", "value": "C15691"},
             {"label": "Simao Dias", "value": "C15692"},
             {"label": "Siriri", "value": "C15693"},
             {"label": "Telha", "value": "C15694"},
             {"label": "Tobias Barreto", "value": "C15695"},
             {"label": "Tomar do Geru", "value": "C15696"},
             {"label": "Umbauba", "value": "C15697"}],
 "Serravalle": [{"label": "Serravalle", "value": "C106253"}],
 "Setif": [{"label": "Ain Arnat", "value": "C406"},
           {"label": "BABOR - VILLE", "value": "C407"},
           {"label": "Bougaa", "value": "C408"},
           {"label": "El Eulma", "value": "C409"},
           {"label": "Salah Bey", "value": "C410"},
           {"label": "Setif", "value": "C411"}],
 "Setubal": [{"label": "Alcacer do Sal", "value": "C91968"},
             {"label": "Alcochete", "value": "C91969"},
             {"label": "Aldeia de Paio Pires", "value": "C91970"},
             {"label": "Alhos Vedros", "value": "C91971"},
             {"label": "Almada", "value": "C91972"},
             {"label": "Amora", "value": "C91973"},
             {"label": "Arrentela", "value": "C91974"},
             {"label": "Azeitao", "value": "C91975"},
             {"label": "Barreiro", "value": "C91976"},
             {"label": "Cacilhas", "value": "C91977"},
             {"label": "Caparica", "value": "C91978"},
             {"label": "Cercal", "value": "C91979"},
             {"label": "Charneca de Caparica", "value": "C91980"},
             {"label": "Comporta", "value": "C91981"},
             {"label": "Corroios", "value": "C91982"},
             {"label": "Costa de Caparica", "value": "C91983"},
             {"label": "Grandola", "value": "C91984"},
             {"label": "Laranjeira", "value": "C91985"},
             {"label": "Lavradio", "value": "C91986"},
             {"label": "Moita", "value": "C91987"},
             {"label": "Montijo", "value": "C91988"},
             {"label": "Palmela", "value": "C91989"},
             {"label": "Piedade", "value": "C91990"},
             {"label": "Pinhal Novo", "value": "C91991"},
             {"label": "Porto Covo", "value": "C91992"},
             {"label": "Pragal", "value": "C91993"},
             {"label": "Quinta do Anjo", "value": "C91994"},
             {"label": "Quinta Do Conde", "value": "C91995"},
             {"label": "Rosairinho", "value": "C91996"},
             {"label": "Samouco", "value": "C91997"},
             {"label": "Santiago do Cacem", "value": "C91998"},
             {"label": "Santo Andre", "value": "C91999"},
             {"label": "Santo Antonio da Charneca", "value": "C92000"},
             {"label": "Sarilhos Pequenos", "value": "C92001"},
             {"label": "Seixal", "value": "C92002"},
             {"label": "Sesimbra", "value": "C92003"},
             {"label": "Setubal", "value": "C92004"},
             {"label": "Sines", "value": "C92005"},
             {"label": "Sobreda", "value": "C92006"},
             {"label": "Trafaria", "value": "C92007"}],
 "Sevastopol": [{"label": "Afanasyeva", "value": "C104449"},
                {"label": "Alarskiy Rayon", "value": "C104450"},
                {"label": "Alekseyevskaya", "value": "C104451"},
                {"label": "Algatuy", "value": "C104452"},
                {"label": "Alzamay", "value": "C104453"},
                {"label": "Andreyevka", "value": "C104454"},
                {"label": "Angarsk", "value": "C104455"},
                {"label": "Angarskiy Rayon", "value": "C104456"},
                {"label": "Atagay", "value": "C104457"},
                {"label": "Badar", "value": "C104458"},
                {"label": "Baklashi", "value": "C104459"},
                {"label": "Balagansk", "value": "C104460"},
                {"label": "Balaganskiy Rayon", "value": "C104461"},
                {"label": "Balakhninskiy", "value": "C104462"},
                {"label": "Balaklava", "value": "C104463"},
                {"label": "Balaklava District", "value": "C104464"},
                {"label": "Bayanday", "value": "C104465"},
                {"label": "Bayandayevskiy Rayon", "value": "C104466"},
                {"label": "Baykalsk", "value": "C104467"},
                {"label": "Bereznyaki", "value": "C104468"},
                {"label": "Biryusinsk", "value": "C104469"},
                {"label": "Bodaybo", "value": "C104470"},
                {"label": "Bokhan", "value": "C104471"},
                {"label": "Bokhanskiy Rayon", "value": "C104472"},
                {"label": "Bolshaya Rechka", "value": "C104473"},
                {"label": "Bratsk", "value": "C104474"},
                {"label": "Bratskiy Rayon", "value": "C104475"},
                {"label": "Budagovo", "value": "C104476"},
                {"label": "Cheremkhovo", "value": "C104477"},
                {"label": "Cheremkhovskiy Rayon", "value": "C104478"},
                {"label": "Chunskiy", "value": "C104479"},
                {"label": "Dzerzhinsk", "value": "C104480"},
                {"label": "Ekhirit-Bulagatskiy Rayon", "value": "C104481"},
                {"label": "Frontovoye", "value": "C104482"},
                {"label": "Gadaley", "value": "C104483"},
                {"label": "Guran", "value": "C104484"},
                {"label": "Haharinskyi District", "value": "C104485"},
                {"label": "Ikey", "value": "C104486"},
                {"label": "Ilir", "value": "C104487"},
                {"label": "Inkerman", "value": "C104488"},
                {"label": "Irkutsk", "value": "C104489"},
                {"label": "Irkutskiy Rayon", "value": "C104490"},
                {"label": "Kacha", "value": "C104491"},
                {"label": "Kachug", "value": "C104492"},
                {"label": "Kachugskiy Rayon", "value": "C104493"},
                {"label": "Katangskiy Rayon", "value": "C104494"},
                {"label": "Kazachinsko-Lenskiy Rayon", "value": "C104495"},
                {"label": "Kazachinskoye", "value": "C104496"},
                {"label": "Khomutovo", "value": "C104497"},
                {"label": "Khrebtovaya", "value": "C104498"},
                {"label": "Khudoyelanskoye", "value": "C104499"},
                {"label": "Khuzhir", "value": "C104500"},
                {"label": "Kimiltey", "value": "C104501"},
                {"label": "Kirensk", "value": "C104502"},
                {"label": "Kirenskiy Rayon", "value": "C104503"},
                {"label": "Kitoy", "value": "C104504"},
                {"label": "Kotik", "value": "C104505"},
                {"label": "Kropotkin", "value": "C104506"},
                {"label": "Kultuk", "value": "C104507"},
                {"label": "Kutulik", "value": "C104508"},
                {"label": "Kuytun", "value": "C104509"},
                {"label": "Kuytunskiy Rayon", "value": "C104510"},
                {"label": "Kvitok", "value": "C104511"},
                {"label": "Lenin District", "value": "C104512"},
                {"label": "Lesogorsk", "value": "C104513"},
                {"label": "Listvyanka", "value": "C104514"},
                {"label": "Magistralnyy", "value": "C104515"},
                {"label": "Mama", "value": "C104516"},
                {"label": "Mamakan", "value": "C104517"},
                {"label": "Mamsko-Chuyskiy Rayon", "value": "C104518"},
                {"label": "Manzurka", "value": "C104519"},
                {"label": "Markova", "value": "C104520"},
                {"label": "Markovo", "value": "C104521"},
                {"label": "Meget", "value": "C104522"},
                {"label": "Mishelevka", "value": "C104523"},
                {"label": "Mugun", "value": "C104524"},
                {"label": "Nakhimovskiy rayon", "value": "C104525"},
                {"label": "Nizhneilimskiy Rayon", "value": "C104526"},
                {"label": "Nizhneudinsk", "value": "C104527"},
                {"label": "Nizhneudinskiy Rayon", "value": "C104528"},
                {"label": "Novaya Igirma", "value": "C104529"},
                {"label": "Novobiryusinskiy", "value": "C104530"},
                {"label": "Novonukutskiy", "value": "C104531"},
                {"label": "Olkhonskiy Rayon", "value": "C104532"},
                {"label": "Orlinoye", "value": "C104533"},
                {"label": "Osa", "value": "C104534"},
                {"label": "Oyek", "value": "C104535"},
                {"label": "Pad Melnichnaya", "value": "C104536"},
                {"label": "Perfilovo", "value": "C104537"},
                {"label": "Pivovarikha", "value": "C104538"},
                {"label": "Pokosnoye", "value": "C104539"},
                {"label": "Polyushko", "value": "C104540"},
                {"label": "Rudnogorsk", "value": "C104541"},
                {"label": "Sakharnaya Golovka", "value": "C104542"},
                {"label": "Sayansk", "value": "C104543"},
                {"label": "Sevastopol", "value": "C104544"},
                {"label": "Shamanka", "value": "C104545"},
                {"label": "Sheberta", "value": "C104546"},
                {"label": "Shelekhov", "value": "C104547"},
                {"label": "Sheragul", "value": "C104548"},
                {"label": "Shestakovo", "value": "C104549"},
                {"label": "Shitkino", "value": "C104550"},
                {"label": "Shturmovoye", "value": "C104551"},
                {"label": "Shumskiy", "value": "C104552"},
                {"label": "Slyudyanka", "value": "C104553"},
                {"label": "Slyudyanskiy Rayon", "value": "C104554"},
                {"label": "Smolenshchina", "value": "C104555"},
                {"label": "Solnechniy", "value": "C104556"},
                {"label": "Sredniy", "value": "C104557"},
                {"label": "Svirsk", "value": "C104558"},
                {"label": "Tayshet", "value": "C104559"},
                {"label": "Tayshetskiy Rayon", "value": "C104560"},
                {"label": "Tayturka", "value": "C104561"},
                {"label": "Telma", "value": "C104562"},
                {"label": "Ternovka", "value": "C104563"},
                {"label": "Tulun", "value": "C104564"},
                {"label": "Tulunskiy Rayon", "value": "C104565"},
                {"label": "Tulyushka", "value": "C104566"},
                {"label": "Tyret Pervaya", "value": "C104567"},
                {"label": "Uk", "value": "C104568"},
                {"label": "Urik", "value": "C104569"},
                {"label": "Usolskiy Rayon", "value": "C104570"},
                {"label": "Usolye-Sibirskoye", "value": "C104571"},
                {"label": "Ust-Ilimsk", "value": "C104572"},
                {"label": "Ust-Kut", "value": "C104573"},
                {"label": "Ust-Kutskiy Rayon", "value": "C104574"},
                {"label": "Ust-Ordynskiy", "value": "C104575"},
                {"label": "Ust-Uda", "value": "C104576"},
                {"label": "Ust-Udinskiy Rayon", "value": "C104577"},
                {"label": "Verkhnesadovoye", "value": "C104578"},
                {"label": "Vidim", "value": "C104579"},
                {"label": "Vikhorevka", "value": "C104580"},
                {"label": "Voznesenskiy", "value": "C104581"},
                {"label": "Yantal", "value": "C104582"},
                {"label": "Yedogon", "value": "C104583"},
                {"label": "Yelantsy", "value": "C104584"},
                {"label": "Yerbogachen", "value": "C104585"},
                {"label": "Yurty", "value": "C104586"},
                {"label": "Zalari", "value": "C104587"},
                {"label": "Zalarinskiy Rayon", "value": "C104588"},
                {"label": "Zheleznodorozhnyy", "value": "C104589"},
                {"label": "Zheleznogorsk-Ilimskiy", "value": "C104590"},
                {"label": "Zhigalovo", "value": "C104591"},
                {"label": "Zhigalovskiy Rayon", "value": "C104592"},
                {"label": "Zima", "value": "C104593"},
                {"label": "Ziminskiy Rayon", "value": "C104594"},
                {"label": "Zvezdnyy", "value": "C104595"}],
 "Sevnica Municipality": [{"label": "Sevnica", "value": "C107805"}],
 "Sezana Municipality": [{"label": "Sezana", "value": "C107806"}],
 "Sfax Governorate": [{"label": "Agareb", "value": "C119774"},
                      {"label": "Bir Ali Ben Khalifa", "value": "C119775"},
                      {"label": "Djebeniana", "value": "C119776"},
                      {"label": "Gremda", "value": "C119777"},
                      {"label": "Sfax", "value": "C119778"},
                      {"label": "Skhira", "value": "C119779"}],
 "Shaanxi": [{"label": "Ankang", "value": "C19118"},
             {"label": "Baoji Shi", "value": "C19119"},
             {"label": "Guozhen", "value": "C19120"},
             {"label": "Hancheng", "value": "C19121"},
             {"label": "Hanzhong", "value": "C19122"},
             {"label": "Huayin", "value": "C19123"},
             {"label": "Lintong", "value": "C19124"},
             {"label": "Tongchuanshi", "value": "C19125"},
             {"label": "Weinan", "value": "C19126"},
             {"label": "Xian", "value": "C19127"},
             {"label": "Xianyang", "value": "C19128"},
             {"label": "Yanliang", "value": "C19129"},
             {"label": "Yulinshi", "value": "C19130"},
             {"label": "Yuxia", "value": "C19131"}],
 "Shabran District": [{"label": "Divichibazar", "value": "C8509"}],
 "Shabwah Governorate": [{"label": "Ain", "value": "C148038"},
                         {"label": "Al aqir", "value": "C148039"},
                         {"label": "Al Talh", "value": "C148040"},
                         {"label": "AL-khasha upper", "value": "C148041"},
                         {"label": "Ar Rawdah", "value": "C148042"},
                         {"label": "Arma", "value": "C148043"},
                         {"label": "As Said", "value": "C148044"},
                         {"label": "Ataq", "value": "C148045"},
                         {"label": "Bayhan", "value": "C148046"},
                         {"label": "Dhar", "value": "C148047"},
                         {"label": "Habban", "value": "C148048"},
                         {"label": "Hatib", "value": "C148049"},
                         {"label": "Jardan", "value": "C148050"},
                         {"label": "Khimar", "value": "C148051"},
                         {"label": "Mayfaa", "value": "C148052"},
                         {"label": "Merkhah Al Ulya", "value": "C148053"},
                         {"label": "Merkhah As Sufla", "value": "C148054"},
                         {"label": "Nisab", "value": "C148055"},
                         {"label": "Rudum", "value": "C148056"},
                         {"label": "Usaylan", "value": "C148057"}],
 "Shaki": [{"label": "Sheki", "value": "C8510"}],
 "Shaki District": [{"label": "Bas Goynuk", "value": "C8511"}],
 "Shamakhi District": [{"label": "Shamakhi", "value": "C8512"}],
 "Shamkir District": [{"label": "Dolyar", "value": "C8513"},
                      {"label": "Dzagam", "value": "C8514"},
                      {"label": "Qasim Ismayilov", "value": "C8515"},
                      {"label": "Shamkhor", "value": "C8516"}],
 "Shan State": [{"label": "Lashio", "value": "C75952"},
                {"label": "Tachilek", "value": "C75953"},
                {"label": "Taunggyi", "value": "C75954"}],
 "Shandong": [{"label": "Anqiu", "value": "C19132"},
              {"label": "Beizhai", "value": "C19133"},
              {"label": "Bianzhuang", "value": "C19134"},
              {"label": "Binzhou", "value": "C19135"},
              {"label": "Boshan", "value": "C19136"},
              {"label": "Changqing", "value": "C19137"},
              {"label": "Chengqu", "value": "C19138"},
              {"label": "Chengtangcun", "value": "C19139"},
              {"label": "Chengyang", "value": "C19140"},
              {"label": "Dengying", "value": "C19141"},
              {"label": "Dengzhou", "value": "C19142"},
              {"label": "Dezhou", "value": "C19143"},
              {"label": "Dingtao", "value": "C19144"},
              {"label": "Dongcun", "value": "C19145"},
              {"label": "Dongdu", "value": "C19146"},
              {"label": "Feicheng", "value": "C19147"},
              {"label": "Gaomi", "value": "C19148"},
              {"label": "Hanting", "value": "C19149"},
              {"label": "Heze", "value": "C19150"},
              {"label": "Jiamaying", "value": "C19151"},
              {"label": "Jiaozhou", "value": "C19152"},
              {"label": "Jiehu", "value": "C19153"},
              {"label": "Jimo", "value": "C19154"},
              {"label": "Jinan", "value": "C19155"},
              {"label": "Jining", "value": "C19156"},
              {"label": "Juye", "value": "C19157"},
              {"label": "Kuiju", "value": "C19158"},
              {"label": "Kutao", "value": "C19159"},
              {"label": "Laiwu", "value": "C19160"},
              {"label": "Laixi", "value": "C19161"},
              {"label": "Laiyang", "value": "C19162"},
              {"label": "Laizhou", "value": "C19163"},
              {"label": "Laocheng", "value": "C19164"},
              {"label": "Liaocheng", "value": "C19165"},
              {"label": "Linqu", "value": "C19166"},
              {"label": "Linyi", "value": "C19167"},
              {"label": "Longgang", "value": "C19168"},
              {"label": "Mengyin", "value": "C19169"},
              {"label": "Mingshui", "value": "C19170"},
              {"label": "Mizhou", "value": "C19171"},
              {"label": "Nanding", "value": "C19172"},
              {"label": "Nanma", "value": "C19173"},
              {"label": "Ninghai", "value": "C19174"},
              {"label": "Ningyang", "value": "C19175"},
              {"label": "Pingdu", "value": "C19176"},
              {"label": "Pingyi", "value": "C19177"},
              {"label": "Pingyin", "value": "C19178"},
              {"label": "Qingdao", "value": "C19179"},
              {"label": "Qingnian", "value": "C19180"},
              {"label": "Qingshancun", "value": "C19181"},
              {"label": "Qingyang", "value": "C19182"},
              {"label": "Qingzhou", "value": "C19183"},
              {"label": "Qufu", "value": "C19184"},
              {"label": "Rizhao", "value": "C19185"},
              {"label": "Shancheng", "value": "C19186"},
              {"label": "Shanting", "value": "C19187"},
              {"label": "Shazikou", "value": "C19188"},
              {"label": "Shengli", "value": "C19189"},
              {"label": "Shilaorencun", "value": "C19190"},
              {"label": "Shizilu", "value": "C19191"},
              {"label": "Shouguang", "value": "C19192"},
              {"label": "Sishui", "value": "C19193"},
              {"label": "Suozhen", "value": "C19194"},
              {"label": "Taian", "value": "C19195"},
              {"label": "Taozhuang", "value": "C19196"},
              {"label": "Tianfu", "value": "C19197"},
              {"label": "Weifang", "value": "C19198"},
              {"label": "Weihai", "value": "C19199"},
              {"label": "Wenshang", "value": "C19200"},
              {"label": "Xiazhen", "value": "C19201"},
              {"label": "Xiazhuang", "value": "C19202"},
              {"label": "Xiliguantun", "value": "C19203"},
              {"label": "Xindian", "value": "C19204"},
              {"label": "Xintai", "value": "C19205"},
              {"label": "Yanggu", "value": "C19206"},
              {"label": "Yanta", "value": "C19207"},
              {"label": "Yantai", "value": "C19208"},
              {"label": "Yanzhou", "value": "C19209"},
              {"label": "Yatou", "value": "C19210"},
              {"label": "Yinzhu", "value": "C19211"},
              {"label": "Yishui", "value": "C19212"},
              {"label": "Yucheng", "value": "C19213"},
              {"label": "Zaozhuang", "value": "C19214"},
              {"label": "Zhaoyuan", "value": "C19215"},
              {"label": "Zhoucheng", "value": "C19216"},
              {"label": "Zhoucun", "value": "C19217"},
              {"label": "Zhu Cheng City", "value": "C19218"},
              {"label": "Zhuangyuan", "value": "C19219"},
              {"label": "Zibo", "value": "C19220"},
              {"label": "Zoucheng", "value": "C19221"}],
 "Shanghai": [{"label": "Shanghai", "value": "C19222"},
              {"label": "Songjiang", "value": "C19223"},
              {"label": "Zhabei", "value": "C19224"},
              {"label": "Zhujiajiao", "value": "C19225"}],
 "Shanxi": [{"label": "Changzhi", "value": "C19226"},
            {"label": "Datong", "value": "C19227"},
            {"label": "Datong Shi", "value": "C19228"},
            {"label": "Gutao", "value": "C19229"},
            {"label": "Jiexiu", "value": "C19230"},
            {"label": "Jincheng", "value": "C19231"},
            {"label": "Jinzhong Shi", "value": "C19232"},
            {"label": "Linfen", "value": "C19233"},
            {"label": "Luliang", "value": "C19234"},
            {"label": "Shuozhou", "value": "C19235"},
            {"label": "Taiyuan", "value": "C19236"},
            {"label": "Xintian", "value": "C19237"},
            {"label": "Xinzhi", "value": "C19238"},
            {"label": "Xinzhou", "value": "C19239"},
            {"label": "Yangquan", "value": "C19240"},
            {"label": "Yuanping", "value": "C19241"},
            {"label": "Yuci", "value": "C19242"},
            {"label": "Yuncheng", "value": "C19243"}],
 "Sharjah Emirate": [{"label": "Adh Dhayd", "value": "C123120"},
                     {"label": "Al Batayih", "value": "C123121"},
                     {"label": "Al Hamriyah", "value": "C123122"},
                     {"label": "Al Madam", "value": "C123123"},
                     {"label": "Dhaid", "value": "C123124"},
                     {"label": "Dibba Al Hesn", "value": "C123125"},
                     {"label": "Kalba", "value": "C123126"},
                     {"label": "Khawr Fakkan", "value": "C123127"},
                     {"label": "Khor Fakkan", "value": "C123128"},
                     {"label": "Milehah", "value": "C123129"},
                     {"label": "Murbah", "value": "C123130"},
                     {"label": "Sharjah", "value": "C123131"}],
 "Sharqia": [{"label": "10th of Ramadan", "value": "C24334"},
             {"label": "Al Qurein", "value": "C24335"},
             {"label": "Awlad Saqr", "value": "C24336"},
             {"label": "Bilbeis", "value": "C24337"},
             {"label": "Diyarb Negm", "value": "C24338"},
             {"label": "El Husseiniya", "value": "C24339"},
             {"label": "Faqous", "value": "C24340"},
             {"label": "Hihya", "value": "C24341"},
             {"label": "Kafr Saqr", "value": "C24342"},
             {"label": "Markaz Abu Hammad", "value": "C24343"},
             {"label": "Mashtoul El Souk", "value": "C24344"},
             {"label": "Minya El Qamh", "value": "C24345"},
             {"label": "New Salhia", "value": "C24346"},
             {"label": "Zagazig", "value": "C24347"}],
 "Shaviyani Atoll": [{"label": "Funadhoo", "value": "C65818"}],
 "Shefa": [{"label": "Port-Vila", "value": "C147120"}],
 "Shida Kartli": [{"label": "Agara", "value": "C34357"},
                  {"label": "Gori", "value": "C34358"},
                  {"label": "Goris Munitsipaliteti", "value": "C34359"},
                  {"label": "Kaspi", "value": "C34360"},
                  {"label": "Khashuri", "value": "C34361"},
                  {"label": "Surami", "value": "C34362"},
                  {"label": "Tskhinvali", "value": "C34363"}],
 "Shiga Prefecture": [{"label": "Higashiomi-shi", "value": "C63885"},
                      {"label": "Hikone", "value": "C63886"},
                      {"label": "Hikone-shi", "value": "C63887"},
                      {"label": "Hino", "value": "C63888"},
                      {"label": "Kitahama", "value": "C63889"},
                      {"label": "Koka-shi", "value": "C63890"},
                      {"label": "Konan-shi", "value": "C63891"},
                      {"label": "Kusatsu", "value": "C63892"},
                      {"label": "Kusatsu-shi", "value": "C63893"},
                      {"label": "Maibara", "value": "C63894"},
                      {"label": "Maibara-shi", "value": "C63895"},
                      {"label": "Minakuchicho-matoba", "value": "C63896"},
                      {"label": "Moriyama", "value": "C63897"},
                      {"label": "Moriyama-shi", "value": "C63898"},
                      {"label": "Nagahama", "value": "C63899"},
                      {"label": "Nagahama-shi", "value": "C63900"},
                      {"label": "Omihachiman", "value": "C63901"},
                      {"label": "Omihachiman-shi", "value": "C63902"},
                      {"label": "Otsu-shi", "value": "C63903"},
                      {"label": "Ritto-shi", "value": "C63904"},
                      {"label": "Takashima", "value": "C63905"},
                      {"label": "Takashima-shi", "value": "C63906"},
                      {"label": "Yasu-shi", "value": "C63907"},
                      {"label": "Youkaichi", "value": "C63908"}],
 "Shimane Prefecture": [{"label": "Gotsu Shi", "value": "C63909"},
                        {"label": "Gotsucho", "value": "C63910"},
                        {"label": "Hamada", "value": "C63911"},
                        {"label": "Hamada Shi", "value": "C63912"},
                        {"label": "Hiratacho", "value": "C63913"},
                        {"label": "Izumo", "value": "C63914"},
                        {"label": "Izumo Shi", "value": "C63915"},
                        {"label": "Masuda", "value": "C63916"},
                        {"label": "Masuda Shi", "value": "C63917"},
                        {"label": "Matsue", "value": "C63918"},
                        {"label": "Matsue Shi", "value": "C63919"},
                        {"label": "Oda Shi", "value": "C63920"},
                        {"label": "Odacho-oda", "value": "C63921"},
                        {"label": "Unnan Shi", "value": "C63922"},
                        {"label": "Yasugi Shi", "value": "C63923"},
                        {"label": "Yasugicho", "value": "C63924"}],
 "Shinyanga": [{"label": "Isaka", "value": "C118298"},
               {"label": "Kahama", "value": "C118299"},
               {"label": "Kishapu", "value": "C118300"},
               {"label": "Mhango", "value": "C118301"},
               {"label": "Mwadui", "value": "C118302"},
               {"label": "Old Shinyanga", "value": "C118303"},
               {"label": "Shinyanga", "value": "C118304"},
               {"label": "Songwa", "value": "C118305"},
               {"label": "Tinde", "value": "C118306"}],
 "Shirak Region": [{"label": "Akhuryan", "value": "C1777"},
                   {"label": "Amasia", "value": "C1778"},
                   {"label": "Anushavan", "value": "C1779"},
                   {"label": "Arevik", "value": "C1780"},
                   {"label": "Arevshat", "value": "C1781"},
                   {"label": "Arrapi", "value": "C1782"},
                   {"label": "Azatan", "value": "C1783"},
                   {"label": "Basen", "value": "C1784"},
                   {"label": "Dzithankov", "value": "C1785"},
                   {"label": "Gyumri", "value": "C1786"},
                   {"label": "Haykavan", "value": "C1787"},
                   {"label": "Horrom", "value": "C1788"},
                   {"label": "Kamo", "value": "C1789"},
                   {"label": "Lerrnakert", "value": "C1790"},
                   {"label": "Maralik", "value": "C1791"},
                   {"label": "Marmashen", "value": "C1792"},
                   {"label": "Mayisyan", "value": "C1793"},
                   {"label": "Meghrashen", "value": "C1794"},
                   {"label": "Mets Mantash", "value": "C1795"},
                   {"label": "Pokr Mantash", "value": "C1796"},
                   {"label": "Pemzashen", "value": "C1797"},
                   {"label": "Saratak", "value": "C1798"},
                   {"label": "Shirak", "value": "C1799"},
                   {"label": "Spandaryan", "value": "C1800"},
                   {"label": "Voskehask", "value": "C1801"},
                   {"label": "Yerazgavors", "value": "C1802"}],
 "Shirvan": [{"label": "Sirvan", "value": "C8517"}],
 "Shiselweni District": [{"label": "Hlatikulu", "value": "C115497"},
                         {"label": "Hluti", "value": "C115498"},
                         {"label": "Kubuta", "value": "C115499"},
                         {"label": "Lavumisa", "value": "C115500"},
                         {"label": "Matsanjeni", "value": "C115501"},
                         {"label": "Ngudzeni", "value": "C115502"},
                         {"label": "Nhlangano", "value": "C115503"},
                         {"label": "Nkwene", "value": "C115504"},
                         {"label": "Sigwe Inkhundla", "value": "C115505"},
                         {"label": "Zombodze Ikhundla", "value": "C115506"}],
 "Shizuoka Prefecture": [{"label": "Atami", "value": "C63925"},
                         {"label": "Atami-shi", "value": "C63926"},
                         {"label": "Fuji Shi", "value": "C63927"},
                         {"label": "Fujieda Shi", "value": "C63928"},
                         {"label": "Fujinomiya", "value": "C63929"},
                         {"label": "Fujinomiya Shi", "value": "C63930"},
                         {"label": "Fukuroi", "value": "C63931"},
                         {"label": "Fukuroi-shi", "value": "C63932"},
                         {"label": "Gotenba Shi", "value": "C63933"},
                         {"label": "Hamakita", "value": "C63934"},
                         {"label": "Hamamatsu", "value": "C63935"},
                         {"label": "Hamamatsu-shi", "value": "C63936"},
                         {"label": "Heda", "value": "C63937"},
                         {"label": "Ito", "value": "C63938"},
                         {"label": "Ito Shi", "value": "C63939"},
                         {"label": "Iwata", "value": "C63940"},
                         {"label": "Iwata-shi", "value": "C63941"},
                         {"label": "Izu", "value": "C63942"},
                         {"label": "Izu-shi", "value": "C63943"},
                         {"label": "Izunokuni-shi", "value": "C63944"},
                         {"label": "Kakegawa", "value": "C63945"},
                         {"label": "Kakegawa Shi", "value": "C63946"},
                         {"label": "Kanaya", "value": "C63947"},
                         {"label": "Kikugawa-shi", "value": "C63948"},
                         {"label": "Kosai", "value": "C63949"},
                         {"label": "Kosai-shi", "value": "C63950"},
                         {"label": "Makinohara Shi", "value": "C63951"},
                         {"label": "Mishima", "value": "C63952"},
                         {"label": "Mishima Shi", "value": "C63953"},
                         {"label": "Mori", "value": "C63954"},
                         {"label": "Numazu-shi", "value": "C63955"},
                         {"label": "Omaezaki-shi", "value": "C63956"},
                         {"label": "Oyama", "value": "C63957"},
                         {"label": "Sagara", "value": "C63958"},
                         {"label": "Shimada", "value": "C63959"},
                         {"label": "Shimada-shi", "value": "C63960"},
                         {"label": "Shimoda", "value": "C63961"},
                         {"label": "Shimoda-shi", "value": "C63962"},
                         {"label": "Shizuoka", "value": "C63963"},
                         {"label": "Shizuoka-shi", "value": "C63964"},
                         {"label": "Susono", "value": "C63965"},
                         {"label": "Susono Shi", "value": "C63966"},
                         {"label": "Yaizu", "value": "C63967"},
                         {"label": "Yaizu Shi", "value": "C63968"}],
 "Shkoder District": [{"label": "Bashkia Malesi e Madhe", "value": "C187"},
                      {"label": "Bashkia Puke", "value": "C188"},
                      {"label": "Bashkia Vau i Dejes", "value": "C189"},
                      {"label": "Fushe-Arrez", "value": "C190"},
                      {"label": "Koplik", "value": "C191"},
                      {"label": "Puke", "value": "C192"},
                      {"label": "Rrethi i Malesia e Madhe", "value": "C193"},
                      {"label": "Rrethi i Shkodres", "value": "C194"},
                      {"label": "Shkoder", "value": "C195"},
                      {"label": "Vau i Dejes", "value": "C196"},
                      {"label": "Vukatane", "value": "C197"}],
 "Shumen": [{"label": "Shumen", "value": "C16129"}],
 "Shusha District": [{"label": "Shushi", "value": "C8518"}],
 "Si Sa Ket": [{"label": "Amphoe Benchalak", "value": "C119336"},
               {"label": "Amphoe Bueng Bun", "value": "C119337"},
               {"label": "Amphoe Huai Thap Than", "value": "C119338"},
               {"label": "Amphoe Kantharalak", "value": "C119339"},
               {"label": "Amphoe Kanthararom", "value": "C119340"},
               {"label": "Amphoe Khukhan", "value": "C119341"},
               {"label": "Amphoe Khun Han", "value": "C119342"},
               {"label": "Amphoe Mueang Chan", "value": "C119343"},
               {"label": "Amphoe Mueang Sisaket", "value": "C119344"},
               {"label": "Amphoe Nam Kliang", "value": "C119345"},
               {"label": "Amphoe Non Khun", "value": "C119346"},
               {"label": "Amphoe Phayu", "value": "C119347"},
               {"label": "Amphoe Pho Si Suwan", "value": "C119348"},
               {"label": "Amphoe Phrai Bueng", "value": "C119349"},
               {"label": "Amphoe Phu Sing", "value": "C119350"},
               {"label": "Amphoe Prang Ku", "value": "C119351"},
               {"label": "Amphoe Rasi Salai", "value": "C119352"},
               {"label": "Amphoe Si Rattana", "value": "C119353"},
               {"label": "Amphoe Sila Lat", "value": "C119354"},
               {"label": "Amphoe Uthumphon Phisai", "value": "C119355"},
               {"label": "Amphoe Wang Hin", "value": "C119356"},
               {"label": "Amphoe Yang Chum Noi", "value": "C119357"},
               {"label": "Kantharalak", "value": "C119358"},
               {"label": "Phrai Bueng", "value": "C119359"},
               {"label": "Si Sa Ket", "value": "C119360"}],
 "Siauliai City Municipality": [{"label": "Akmene", "value": "C65149"},
                                {"label": "Joniskis", "value": "C65150"},
                                {"label": "Kelme", "value": "C65151"},
                                {"label": "Kursenai", "value": "C65152"},
                                {"label": "Linkuva", "value": "C65153"},
                                {"label": "Naujoji Akmene", "value": "C65154"},
                                {"label": "Pakruojis", "value": "C65155"},
                                {"label": "Radviliskis", "value": "C65156"},
                                {"label": "Seduva", "value": "C65157"},
                                {"label": "Siauliai", "value": "C65158"},
                                {"label": "Tytuveneliai", "value": "C65159"},
                                {"label": "Venta", "value": "C65160"},
                                {"label": "Zagare", "value": "C65161"}],
 "Siaya": [{"label": "Bondo", "value": "C64632"},
           {"label": "Siaya", "value": "C64633"},
           {"label": "Yala", "value": "C64634"}],
 "Siazan District": [{"label": "Gilgilcay", "value": "C8519"},
                     {"label": "Kyzyl-Burun", "value": "C8520"}],
 "Sibenik-Knin": [{"label": "Brodarica", "value": "C21352"},
                  {"label": "Drnis", "value": "C21353"},
                  {"label": "Grad Drnis", "value": "C21354"},
                  {"label": "Grad Sibenik", "value": "C21355"},
                  {"label": "Kistanje", "value": "C21356"},
                  {"label": "Knin", "value": "C21357"},
                  {"label": "Murter", "value": "C21358"},
                  {"label": "Murter-Kornati", "value": "C21359"},
                  {"label": "Pirovac", "value": "C21360"},
                  {"label": "Primosten", "value": "C21361"},
                  {"label": "Promina", "value": "C21362"},
                  {"label": "Rogoznica", "value": "C21363"},
                  {"label": "Rogoznica Opcina", "value": "C21364"},
                  {"label": "Sibenik", "value": "C21365"},
                  {"label": "Skradin", "value": "C21366"},
                  {"label": "Tisno", "value": "C21367"},
                  {"label": "Tribunj", "value": "C21368"},
                  {"label": "Vodice", "value": "C21369"}],
 "Sibiu County": [{"label": "Agarbiciu", "value": "C98591"},
                  {"label": "Agnita", "value": "C98592"},
                  {"label": "Alamor", "value": "C98593"},
                  {"label": "Alma", "value": "C98594"},
                  {"label": "Altana", "value": "C98595"},
                  {"label": "Apoldu de Jos", "value": "C98596"},
                  {"label": "Arpasu de Jos", "value": "C98597"},
                  {"label": "Arpasu de Sus", "value": "C98598"},
                  {"label": "Atel", "value": "C98599"},
                  {"label": "Avrig", "value": "C98600"},
                  {"label": "Axente Sever", "value": "C98601"},
                  {"label": "Barghis", "value": "C98602"},
                  {"label": "Bazna", "value": "C98603"},
                  {"label": "Biertan", "value": "C98604"},
                  {"label": "Blajel", "value": "C98605"},
                  {"label": "Boian", "value": "C98606"},
                  {"label": "Boita", "value": "C98607"},
                  {"label": "Bradeni", "value": "C98608"},
                  {"label": "Bradu", "value": "C98609"},
                  {"label": "Brateiu", "value": "C98610"},
                  {"label": "Bruiu", "value": "C98611"},
                  {"label": "Carta", "value": "C98612"},
                  {"label": "Cartisoara", "value": "C98613"},
                  {"label": "Chirpar", "value": "C98614"},
                  {"label": "Cisnadie", "value": "C98615"},
                  {"label": "Comuna Alma", "value": "C98616"},
                  {"label": "Comuna Altana", "value": "C98617"},
                  {"label": "Comuna Apoldu de Jos", "value": "C98618"},
                  {"label": "Comuna Arpasu de Jos", "value": "C98619"},
                  {"label": "Comuna Atel", "value": "C98620"},
                  {"label": "Comuna Axente Sever", "value": "C98621"},
                  {"label": "Comuna Barghis", "value": "C98622"},
                  {"label": "Comuna Bazna", "value": "C98623"},
                  {"label": "Comuna Biertan", "value": "C98624"},
                  {"label": "Comuna Blajel", "value": "C98625"},
                  {"label": "Comuna Boita", "value": "C98626"},
                  {"label": "Comuna Bradeni", "value": "C98627"},
                  {"label": "Comuna Brateiu", "value": "C98628"},
                  {"label": "Comuna Bruiu", "value": "C98629"},
                  {"label": "Comuna Carta", "value": "C98630"},
                  {"label": "Comuna Cartisoara", "value": "C98631"},
                  {"label": "Comuna Chirpar", "value": "C98632"},
                  {"label": "Comuna Cristian", "value": "C98633"},
                  {"label": "Comuna Darlos", "value": "C98634"},
                  {"label": "Comuna Gura Raului", "value": "C98635"},
                  {"label": "Comuna Hoghilag", "value": "C98636"},
                  {"label": "Comuna Iacobeni", "value": "C98637"},
                  {"label": "Comuna Jina", "value": "C98638"},
                  {"label": "Comuna Laslea", "value": "C98639"},
                  {"label": "Comuna Loamnes", "value": "C98640"},
                  {"label": "Comuna Ludos", "value": "C98641"},
                  {"label": "Comuna Marpod", "value": "C98642"},
                  {"label": "Comuna Merghindeal", "value": "C98643"},
                  {"label": "Comuna Micasasa", "value": "C98644"},
                  {"label": "Comuna Mihaileni", "value": "C98645"},
                  {"label": "Comuna Mosna", "value": "C98646"},
                  {"label": "Comuna Nocrich", "value": "C98647"},
                  {"label": "Comuna Orlat", "value": "C98648"},
                  {"label": "Comuna Pauca", "value": "C98649"},
                  {"label": "Comuna Poiana Sibiului", "value": "C98650"},
                  {"label": "Comuna Poplaca", "value": "C98651"},
                  {"label": "Comuna Porumbacu de Jos", "value": "C98652"},
                  {"label": "Comuna Racovita", "value": "C98653"},
                  {"label": "Comuna Rasinari", "value": "C98654"},
                  {"label": "Comuna Rau Sadului", "value": "C98655"},
                  {"label": "Comuna Rosia", "value": "C98656"},
                  {"label": "Comuna Sadu", "value": "C98657"},
                  {"label": "Comuna Seica Mare", "value": "C98658"},
                  {"label": "Comuna Seica Mica", "value": "C98659"},
                  {"label": "Comuna Selimbar", "value": "C98660"},
                  {"label": "Comuna Slimnic", "value": "C98661"},
                  {"label": "Comuna Sura Mare", "value": "C98662"},
                  {"label": "Comuna Sura Mica", "value": "C98663"},
                  {"label": "Comuna Tarnava", "value": "C98664"},
                  {"label": "Comuna Tilisca", "value": "C98665"},
                  {"label": "Comuna Turnu Rosu", "value": "C98666"},
                  {"label": "Comuna Valea Viilor", "value": "C98667"},
                  {"label": "Comuna Vurpar", "value": "C98668"},
                  {"label": "Copsa Mica", "value": "C98669"},
                  {"label": "Cristian", "value": "C98670"},
                  {"label": "Darlos", "value": "C98671"},
                  {"label": "Dumbraveni", "value": "C98672"},
                  {"label": "Gura Raului", "value": "C98673"},
                  {"label": "Hoghilag", "value": "C98674"},
                  {"label": "Iacobeni", "value": "C98675"},
                  {"label": "Ighisu Nou", "value": "C98676"},
                  {"label": "Jina", "value": "C98677"},
                  {"label": "Laslea", "value": "C98678"},
                  {"label": "Loamnes", "value": "C98679"},
                  {"label": "Ludos", "value": "C98680"},
                  {"label": "Malancrav", "value": "C98681"},
                  {"label": "Marpod", "value": "C98682"},
                  {"label": "Marsa", "value": "C98683"},
                  {"label": "Medias", "value": "C98684"},
                  {"label": "Merghindeal", "value": "C98685"},
                  {"label": "Micasasa", "value": "C98686"},
                  {"label": "Miercurea Sibiului", "value": "C98687"},
                  {"label": "Mihaileni", "value": "C98688"},
                  {"label": "Mosna", "value": "C98689"},
                  {"label": "Municipiul Medias", "value": "C98690"},
                  {"label": "Municipiul Sibiu", "value": "C98691"},
                  {"label": "Nocrich", "value": "C98692"},
                  {"label": "Nou", "value": "C98693"},
                  {"label": "Ocna Sibiului", "value": "C98694"},
                  {"label": "Oras Agnita", "value": "C98695"},
                  {"label": "Oras Avrig", "value": "C98696"},
                  {"label": "Oras Cisnadie", "value": "C98697"},
                  {"label": "Oras Copsa Mica", "value": "C98698"},
                  {"label": "Oras Dumbraveni", "value": "C98699"},
                  {"label": "Oras Miercurea Sibiului", "value": "C98700"},
                  {"label": "Oras Ocna Sibiului", "value": "C98701"},
                  {"label": "Oras Saliste", "value": "C98702"},
                  {"label": "Oras Talmaciu", "value": "C98703"},
                  {"label": "Orlat", "value": "C98704"},
                  {"label": "Pauca", "value": "C98705"},
                  {"label": "Poiana Sibiului", "value": "C98706"},
                  {"label": "Poplaca", "value": "C98707"},
                  {"label": "Porumbacu de Jos", "value": "C98708"},
                  {"label": "Racovita", "value": "C98709"},
                  {"label": "Rasinari", "value": "C98710"},
                  {"label": "Rau Sadului", "value": "C98711"},
                  {"label": "Rosia", "value": "C98712"},
                  {"label": "Ruja", "value": "C98713"},
                  {"label": "Sadu", "value": "C98714"},
                  {"label": "Saliste", "value": "C98715"},
                  {"label": "Saros pe Tarnave", "value": "C98716"},
                  {"label": "Seica Mare", "value": "C98717"},
                  {"label": "Seica Mica", "value": "C98718"},
                  {"label": "Selimbar", "value": "C98719"},
                  {"label": "Sibiu", "value": "C98720"},
                  {"label": "Slimnic", "value": "C98721"},
                  {"label": "Sura Mare", "value": "C98722"},
                  {"label": "Sura Mica", "value": "C98723"},
                  {"label": "Talmacel", "value": "C98724"},
                  {"label": "Talmaciu", "value": "C98725"},
                  {"label": "Tarnava", "value": "C98726"},
                  {"label": "Tilisca", "value": "C98727"},
                  {"label": "Turnu Rosu", "value": "C98728"},
                  {"label": "Valea Viilor", "value": "C98729"},
                  {"label": "Vestem", "value": "C98730"},
                  {"label": "Vurpar", "value": "C98731"}],
 "Sichuan": [{"label": "Aba Zangzu Qiangzu Zizhizhou", "value": "C19244"},
             {"label": "Barkam", "value": "C19245"},
             {"label": "Bazhong Shi", "value": "C19246"},
             {"label": "Changchi", "value": "C19247"},
             {"label": "Chengdu", "value": "C19248"},
             {"label": "Chonglong", "value": "C19249"},
             {"label": "Dadukou", "value": "C19250"},
             {"label": "Dazhou", "value": "C19251"},
             {"label": "Deyang", "value": "C19252"},
             {"label": "Dongxi", "value": "C19253"},
             {"label": "Fangting", "value": "C19254"},
             {"label": "Fubao", "value": "C19255"},
             {"label": "Gaoping", "value": "C19256"},
             {"label": "Garze Zangzu Zizhizhou", "value": "C19257"},
             {"label": "Guangan", "value": "C19258"},
             {"label": "Guangyuan", "value": "C19259"},
             {"label": "Jiancheng", "value": "C19260"},
             {"label": "Jiangyou", "value": "C19261"},
             {"label": "Jiannan", "value": "C19262"},
             {"label": "Kangding", "value": "C19263"},
             {"label": "Langzhong", "value": "C19264"},
             {"label": "Leshan", "value": "C19265"},
             {"label": "Liangshan Yizu Zizhizhou", "value": "C19266"},
             {"label": "Linqiong", "value": "C19267"},
             {"label": "Luocheng", "value": "C19268"},
             {"label": "Luzhou", "value": "C19269"},
             {"label": "Meishan Shi", "value": "C19270"},
             {"label": "Mianyang", "value": "C19271"},
             {"label": "Nanchong", "value": "C19272"},
             {"label": "Nanlong", "value": "C19273"},
             {"label": "Neijiang", "value": "C19274"},
             {"label": "Panzhihua", "value": "C19275"},
             {"label": "Puji", "value": "C19276"},
             {"label": "Shuanghejiedao", "value": "C19277"},
             {"label": "Suining", "value": "C19278"},
             {"label": "Taihe", "value": "C19279"},
             {"label": "Taiping", "value": "C19280"},
             {"label": "Tianpeng", "value": "C19281"},
             {"label": "Tongchuan", "value": "C19282"},
             {"label": "Xialiang", "value": "C19283"},
             {"label": "Xiantan", "value": "C19284"},
             {"label": "Xichang", "value": "C19285"},
             {"label": "Xunchang", "value": "C19286"},
             {"label": "Yanjiang", "value": "C19287"},
             {"label": "Yibin", "value": "C19288"},
             {"label": "Yucheng", "value": "C19289"},
             {"label": "Zengjia", "value": "C19290"},
             {"label": "Zhongba", "value": "C19291"},
             {"label": "Zigong", "value": "C19292"}],
 "Sicily": [{"label": "Acate", "value": "C59369"},
            {"label": "Aci Bonaccorsi", "value": "C59370"},
            {"label": "Aci Castello", "value": "C59371"},
            {"label": "Aci Catena", "value": "C59372"},
            {"label": "Aci SantAntonio", "value": "C59373"},
            {"label": "Aci Trezza", "value": "C59374"},
            {"label": "Acireale", "value": "C59375"},
            {"label": "Acitrezza", "value": "C59376"},
            {"label": "Acquaviva Platani", "value": "C59377"},
            {"label": "Acquedolci", "value": "C59378"},
            {"label": "Adrano", "value": "C59379"},
            {"label": "Agira", "value": "C59380"},
            {"label": "Agliandroni-Paternella", "value": "C59381"},
            {"label": "Agrigento", "value": "C59382"},
            {"label": "Aidone", "value": "C59383"},
            {"label": "Alcamo", "value": "C59384"},
            {"label": "Alcara Li Fusi", "value": "C59385"},
            {"label": "Alessandria della Rocca", "value": "C59386"},
            {"label": "Ali", "value": "C59387"},
            {"label": "Ali Terme", "value": "C59388"},
            {"label": "Alia", "value": "C59389"},
            {"label": "Alimena", "value": "C59390"},
            {"label": "Aliminusa", "value": "C59391"},
            {"label": "Altavilla Milicia", "value": "C59392"},
            {"label": "Altofonte", "value": "C59393"},
            {"label": "Antillo", "value": "C59394"},
            {"label": "Aragona", "value": "C59395"},
            {"label": "Assoro", "value": "C59396"},
            {"label": "Augusta", "value": "C59397"},
            {"label": "Avola", "value": "C59398"},
            {"label": "Bagheria", "value": "C59399"},
            {"label": "Balestrate", "value": "C59400"},
            {"label": "Barcellona Pozzo di Gotto", "value": "C59401"},
            {"label": "Barrafranca", "value": "C59402"},
            {"label": "Basico", "value": "C59403"},
            {"label": "Baucina", "value": "C59404"},
            {"label": "Belmonte Mezzagno", "value": "C59405"},
            {"label": "Belpasso", "value": "C59406"},
            {"label": "Belvedere", "value": "C59407"},
            {"label": "Belvedere-Piano Tavola", "value": "C59408"},
            {"label": "Biancavilla", "value": "C59409"},
            {"label": "Bisacquino", "value": "C59410"},
            {"label": "Bivona", "value": "C59411"},
            {"label": "Blufi", "value": "C59412"},
            {"label": "Bolognetta", "value": "C59413"},
            {"label": "Bompensiere", "value": "C59414"},
            {"label": "Bompietro", "value": "C59415"},
            {"label": "Borgetto", "value": "C59416"},
            {"label": "Brolo", "value": "C59417"},
            {"label": "Bronte", "value": "C59418"},
            {"label": "Brucoli", "value": "C59419"},
            {"label": "Buccheri", "value": "C59420"},
            {"label": "Burgio", "value": "C59421"},
            {"label": "Buscemi", "value": "C59422"},
            {"label": "Buseto Palizzolo", "value": "C59423"},
            {"label": "Butera", "value": "C59424"},
            {"label": "Caccamo", "value": "C59425"},
            {"label": "Calamonaci", "value": "C59426"},
            {"label": "Calascibetta", "value": "C59427"},
            {"label": "Calatabiano", "value": "C59428"},
            {"label": "Calatafimi", "value": "C59429"},
            {"label": "Caltabellotta", "value": "C59430"},
            {"label": "Caltagirone", "value": "C59431"},
            {"label": "Caltanissetta", "value": "C59432"},
            {"label": "Caltavuturo", "value": "C59433"},
            {"label": "Camastra", "value": "C59434"},
            {"label": "Cammarata", "value": "C59435"},
            {"label": "Campanella-Gianforma", "value": "C59436"},
            {"label": "Campobello di Licata", "value": "C59437"},
            {"label": "Campobello di Mazara", "value": "C59438"},
            {"label": "Campofelice di Fitalia", "value": "C59439"},
            {"label": "Campofelice di Roccella", "value": "C59440"},
            {"label": "Campofiorito", "value": "C59441"},
            {"label": "Campofranco", "value": "C59442"},
            {"label": "Camporeale", "value": "C59443"},
            {"label": "Camporotondo Etneo", "value": "C59444"},
            {"label": "Canalicchio", "value": "C59445"},
            {"label": "Canicatti", "value": "C59446"},
            {"label": "Canicattini Bagni", "value": "C59447"},
            {"label": "Canneto", "value": "C59448"},
            {"label": "Cannizzaro-Favara", "value": "C59449"},
            {"label": "Capaci", "value": "C59450"},
            {"label": "Capizzi", "value": "C59451"},
            {"label": "Capo dOrlando", "value": "C59452"},
            {"label": "Capri Leone", "value": "C59453"},
            {"label": "Carini", "value": "C59454"},
            {"label": "Carlentini", "value": "C59455"},
            {"label": "Caronia", "value": "C59456"},
            {"label": "Carrozziere", "value": "C59457"},
            {"label": "Carruba", "value": "C59458"},
            {"label": "Carrubazza-Motta", "value": "C59459"},
            {"label": "Casa Santa", "value": "C59460"},
            {"label": "Casalvecchio Siculo", "value": "C59461"},
            {"label": "Cassaro", "value": "C59462"},
            {"label": "Cassibile", "value": "C59463"},
            {"label": "Castanea delle Furie", "value": "C59464"},
            {"label": "Castel di Judica", "value": "C59465"},
            {"label": "Castel di Lucio", "value": "C59466"},
            {"label": "Castelbuono", "value": "C59467"},
            {"label": "Casteldaccia", "value": "C59468"},
            {"label": "CastellUmberto", "value": "C59469"},
            {"label": "Castellammare del Golfo", "value": "C59470"},
            {"label": "Castellana Sicula", "value": "C59471"},
            {"label": "Castelluzzo", "value": "C59472"},
            {"label": "Castelmola", "value": "C59473"},
            {"label": "Casteltermini", "value": "C59474"},
            {"label": "Castelvetrano", "value": "C59475"},
            {"label": "Castiglione di Sicilia", "value": "C59476"},
            {"label": "Castrofilippo", "value": "C59477"},
            {"label": "Castronuovo di Sicilia", "value": "C59478"},
            {"label": "Castroreale", "value": "C59479"},
            {"label": "Catania", "value": "C59480"},
            {"label": "Catenanuova", "value": "C59481"},
            {"label": "Cattolica Eraclea", "value": "C59482"},
            {"label": "Cava dAliga", "value": "C59483"},
            {"label": "Cefala Diana", "value": "C59484"},
            {"label": "Cefalu", "value": "C59485"},
            {"label": "Centuripe", "value": "C59486"},
            {"label": "Cerami", "value": "C59487"},
            {"label": "Cerda", "value": "C59488"},
            {"label": "Cerza", "value": "C59489"},
            {"label": "Cesaro", "value": "C59490"},
            {"label": "Chianchitta-Pallio", "value": "C59491"},
            {"label": "Chianchitta-Trappitello", "value": "C59492"},
            {"label": "Chiaramonte Gulfi", "value": "C59493"},
            {"label": "Chiusa Sclafani", "value": "C59494"},
            {"label": "Ciaculli", "value": "C59495"},
            {"label": "Cianciana", "value": "C59496"},
            {"label": "Ciavolo", "value": "C59497"},
            {"label": "Ciminna", "value": "C59498"},
            {"label": "Cinisi", "value": "C59499"},
            {"label": "Citta Giardino", "value": "C59500"},
            {"label": "Collesano", "value": "C59501"},
            {"label": "Comiso", "value": "C59502"},
            {"label": "Comitini", "value": "C59503"},
            {"label": "Condro", "value": "C59504"},
            {"label": "Contessa Entellina", "value": "C59505"},
            {"label": "Corleone", "value": "C59506"},
            {"label": "Custonaci", "value": "C59507"},
            {"label": "Delia", "value": "C59508"},
            {"label": "Donnalucata", "value": "C59509"},
            {"label": "Enna", "value": "C59510"},
            {"label": "Erice", "value": "C59511"},
            {"label": "Evangelisti-Rubino", "value": "C59512"},
            {"label": "Falcone", "value": "C59513"},
            {"label": "Faro Superiore", "value": "C59514"},
            {"label": "Favara", "value": "C59515"},
            {"label": "Favignana", "value": "C59516"},
            {"label": "Ferla", "value": "C59517"},
            {"label": "Ficarazzi", "value": "C59518"},
            {"label": "Ficarra", "value": "C59519"},
            {"label": "Finale", "value": "C59520"},
            {"label": "Fiumedinisi", "value": "C59521"},
            {"label": "Fiumefreddo Sicilia", "value": "C59522"},
            {"label": "Floresta", "value": "C59523"},
            {"label": "Floridia", "value": "C59524"},
            {"label": "Fondachelli-Fantina", "value": "C59525"},
            {"label": "Forza dAgro", "value": "C59526"},
            {"label": "Francavilla di Sicilia", "value": "C59527"},
            {"label": "Francofonte", "value": "C59528"},
            {"label": "Frazzano", "value": "C59529"},
            {"label": "Fulgatore-Torretta", "value": "C59530"},
            {"label": "Furci Siculo", "value": "C59531"},
            {"label": "Furnari", "value": "C59532"},
            {"label": "Gaggi", "value": "C59533"},
            {"label": "Gagliano Castelferrato", "value": "C59534"},
            {"label": "Galati Mamertino", "value": "C59535"},
            {"label": "Gallodoro", "value": "C59536"},
            {"label": "Gangi", "value": "C59537"},
            {"label": "Gela", "value": "C59538"},
            {"label": "Geraci Siculo", "value": "C59539"},
            {"label": "Giacalone", "value": "C59540"},
            {"label": "Giammoro", "value": "C59541"},
            {"label": "Giardina Gallotti", "value": "C59542"},
            {"label": "Giardinello", "value": "C59543"},
            {"label": "Giardini-Naxos", "value": "C59544"},
            {"label": "Giarratana", "value": "C59545"},
            {"label": "Giarre", "value": "C59546"},
            {"label": "Gioiosa Marea", "value": "C59547"},
            {"label": "Giuliana", "value": "C59548"},
            {"label": "Gliaca", "value": "C59549"},
            {"label": "Godrano", "value": "C59550"},
            {"label": "Grammichele", "value": "C59551"},
            {"label": "Graniti", "value": "C59552"},
            {"label": "Gratteri", "value": "C59553"},
            {"label": "Gravina di Catania", "value": "C59554"},
            {"label": "Grotte", "value": "C59555"},
            {"label": "Gualtieri Sicamino", "value": "C59556"},
            {"label": "Guidomandri Marina", "value": "C59557"},
            {"label": "Isnello", "value": "C59558"},
            {"label": "Isola delle Femmine", "value": "C59559"},
            {"label": "Ispica", "value": "C59560"},
            {"label": "Itala", "value": "C59561"},
            {"label": "Joppolo Giancaxio", "value": "C59562"},
            {"label": "Kamma", "value": "C59563"},
            {"label": "Lampedusa", "value": "C59564"},
            {"label": "Larderia", "value": "C59565"},
            {"label": "Lascari", "value": "C59566"},
            {"label": "Lavinaio-Monterosso", "value": "C59567"},
            {"label": "Leni", "value": "C59568"},
            {"label": "Lentini", "value": "C59569"},
            {"label": "Leonforte", "value": "C59570"},
            {"label": "Lercara Friddi", "value": "C59571"},
            {"label": "Letojanni", "value": "C59572"},
            {"label": "Librizzi", "value": "C59573"},
            {"label": "Licata", "value": "C59574"},
            {"label": "Licodia Eubea", "value": "C59575"},
            {"label": "Limina", "value": "C59576"},
            {"label": "Linera", "value": "C59577"},
            {"label": "Linguaglossa", "value": "C59578"},
            {"label": "Lipari", "value": "C59579"},
            {"label": "Longi", "value": "C59580"},
            {"label": "Lucca Sicula", "value": "C59581"},
            {"label": "Maletto", "value": "C59582"},
            {"label": "Malfa", "value": "C59583"},
            {"label": "Malvagna", "value": "C59584"},
            {"label": "Mandanici", "value": "C59585"},
            {"label": "Maniace", "value": "C59586"},
            {"label": "Marausa", "value": "C59587"},
            {"label": "Marianopoli", "value": "C59588"},
            {"label": "Marina di Caronia", "value": "C59589"},
            {"label": "Marina di Ragusa", "value": "C59590"},
            {"label": "Marinella", "value": "C59591"},
            {"label": "Marineo", "value": "C59592"},
            {"label": "Marsala", "value": "C59593"},
            {"label": "Mascali", "value": "C59594"},
            {"label": "Mascalucia", "value": "C59595"},
            {"label": "Maugeri", "value": "C59596"},
            {"label": "Mazara del Vallo", "value": "C59597"},
            {"label": "Mazara II", "value": "C59598"},
            {"label": "Mazzarino", "value": "C59599"},
            {"label": "Mazzarra SantAndrea", "value": "C59600"},
            {"label": "Mazzarrone", "value": "C59601"},
            {"label": "Melia", "value": "C59602"},
            {"label": "Melilli", "value": "C59603"},
            {"label": "Menfi", "value": "C59604"},
            {"label": "Meri", "value": "C59605"},
            {"label": "Messina", "value": "C59606"},
            {"label": "Mezzojuso", "value": "C59607"},
            {"label": "Milazzo", "value": "C59608"},
            {"label": "Milena", "value": "C59609"},
            {"label": "Militello in Val di Catania", "value": "C59610"},
            {"label": "Militello Rosmarino", "value": "C59611"},
            {"label": "Milo", "value": "C59612"},
            {"label": "Mineo", "value": "C59613"},
            {"label": "Mirabella Imbaccari", "value": "C59614"},
            {"label": "Mirto", "value": "C59615"},
            {"label": "Misilmeri", "value": "C59616"},
            {"label": "Misterbianco", "value": "C59617"},
            {"label": "Mistretta", "value": "C59618"},
            {"label": "Modica", "value": "C59619"},
            {"label": "Moio Alcantara", "value": "C59620"},
            {"label": "Monforte San Giorgio", "value": "C59621"},
            {"label": "Mongiuffi Melia", "value": "C59622"},
            {"label": "Monreale", "value": "C59623"},
            {"label": "Montagnareale", "value": "C59624"},
            {"label": "Montalbano Elicona", "value": "C59625"},
            {"label": "Montallegro", "value": "C59626"},
            {"label": "Montedoro", "value": "C59627"},
            {"label": "Montelepre", "value": "C59628"},
            {"label": "Montemaggiore Belsito", "value": "C59629"},
            {"label": "Monterosso Almo", "value": "C59630"},
            {"label": "Montevago", "value": "C59631"},
            {"label": "Motta Camastra", "value": "C59632"},
            {"label": "Motta dAffermo", "value": "C59633"},
            {"label": "Motta SantAnastasia", "value": "C59634"},
            {"label": "Mussomeli", "value": "C59635"},
            {"label": "Naro", "value": "C59636"},
            {"label": "Naso", "value": "C59637"},
            {"label": "Nicolosi", "value": "C59638"},
            {"label": "Nicosia", "value": "C59639"},
            {"label": "Niscemi", "value": "C59640"},
            {"label": "Nissoria", "value": "C59641"},
            {"label": "Nizza di Sicilia", "value": "C59642"},
            {"label": "Noto", "value": "C59643"},
            {"label": "Novara di Sicilia", "value": "C59644"},
            {"label": "Nunziata", "value": "C59645"},
            {"label": "Nuova Gibellina", "value": "C59646"},
            {"label": "Nuovo Centro Urbano Poggioreale", "value": "C59647"},
            {"label": "Oliveri", "value": "C59648"},
            {"label": "Pace del Mela", "value": "C59649"},
            {"label": "Paceco", "value": "C59650"},
            {"label": "Pachino", "value": "C59651"},
            {"label": "Pagliara", "value": "C59652"},
            {"label": "Palagonia", "value": "C59653"},
            {"label": "Palazzo Adriano", "value": "C59654"},
            {"label": "Palazzolo", "value": "C59655"},
            {"label": "Palazzolo Acreide", "value": "C59656"},
            {"label": "Palermo", "value": "C59657"},
            {"label": "Palma di Montechiaro", "value": "C59658"},
            {"label": "Pantelleria", "value": "C59659"},
            {"label": "Partanna", "value": "C59660"},
            {"label": "Partinico", "value": "C59661"},
            {"label": "Pasteria-Lapide", "value": "C59662"},
            {"label": "Paterno", "value": "C59663"},
            {"label": "Patti", "value": "C59664"},
            {"label": "Pedagaggi", "value": "C59665"},
            {"label": "Pedalino", "value": "C59666"},
            {"label": "Pedara", "value": "C59667"},
            {"label": "Pennisi", "value": "C59668"},
            {"label": "Petralia Soprana", "value": "C59669"},
            {"label": "Petralia Sottana", "value": "C59670"},
            {"label": "Petrosino", "value": "C59671"},
            {"label": "Pettineo", "value": "C59672"},
            {"label": "Piana degli Albanesi", "value": "C59673"},
            {"label": "Piano dei Geli", "value": "C59674"},
            {"label": "Piano Maglio-Blandino", "value": "C59675"},
            {"label": "Pianoconte", "value": "C59676"},
            {"label": "Piazza Armerina", "value": "C59677"},
            {"label": "Piedimonte Etneo", "value": "C59678"},
            {"label": "Pietraperzia", "value": "C59679"},
            {"label": "Pioppo", "value": "C59680"},
            {"label": "Piraino", "value": "C59681"},
            {"label": "Polizzi Generosa", "value": "C59682"},
            {"label": "Pollina", "value": "C59683"},
            {"label": "Portella di Mare", "value": "C59684"},
            {"label": "Porto Empedocle", "value": "C59685"},
            {"label": "Portopalo di Capo Passero", "value": "C59686"},
            {"label": "Pozzallo", "value": "C59687"},
            {"label": "Priolo Gargallo", "value": "C59688"},
            {"label": "Prizzi", "value": "C59689"},
            {"label": "Provincia di Caltanissetta", "value": "C59690"},
            {"label": "Provincia di Siracusa", "value": "C59691"},
            {"label": "Racalmuto", "value": "C59692"},
            {"label": "Raccuja", "value": "C59693"},
            {"label": "Raddusa", "value": "C59694"},
            {"label": "Raffadali", "value": "C59695"},
            {"label": "Ragalna", "value": "C59696"},
            {"label": "Ragusa", "value": "C59697"},
            {"label": "Ramacca", "value": "C59698"},
            {"label": "Randazzo", "value": "C59699"},
            {"label": "Ravanusa", "value": "C59700"},
            {"label": "Realmonte", "value": "C59701"},
            {"label": "Regalbuto", "value": "C59702"},
            {"label": "Reitano", "value": "C59703"},
            {"label": "Resuttano", "value": "C59704"},
            {"label": "Ribera", "value": "C59705"},
            {"label": "Riesi", "value": "C59706"},
            {"label": "Rilievo", "value": "C59707"},
            {"label": "Riposto", "value": "C59708"},
            {"label": "Rocca di Capri Leone", "value": "C59709"},
            {"label": "Roccafiorita", "value": "C59710"},
            {"label": "Roccalumera", "value": "C59711"},
            {"label": "Roccamena", "value": "C59712"},
            {"label": "Roccapalumba", "value": "C59713"},
            {"label": "Roccavaldina", "value": "C59714"},
            {"label": "Roccella Valdemone", "value": "C59715"},
            {"label": "Rodi", "value": "C59716"},
            {"label": "Rometta", "value": "C59717"},
            {"label": "Rometta Marea", "value": "C59718"},
            {"label": "Rosolini", "value": "C59719"},
            {"label": "Salaparuta", "value": "C59720"},
            {"label": "Salemi", "value": "C59721"},
            {"label": "Sambuca di Sicilia", "value": "C59722"},
            {"label": "San Biagio Platani", "value": "C59723"},
            {"label": "San Cataldo", "value": "C59724"},
            {"label": "San Cipirello", "value": "C59725"},
            {"label": "San Ciro-Ulmi-Filci", "value": "C59726"},
            {"label": "San Cono", "value": "C59727"},
            {"label": "San Filippo del Mela", "value": "C59728"},
            {"label": "San Fratello", "value": "C59729"},
            {"label": "San Giorgio", "value": "C59730"},
            {"label": "San Giovanni", "value": "C59731"},
            {"label": "San Giovanni Gemini", "value": "C59732"},
            {"label": "San Giovanni la Punta", "value": "C59733"},
            {"label": "San Giuseppe Jato", "value": "C59734"},
            {"label": "San Gregorio di Catania", "value": "C59735"},
            {"label": "San Leone Mose", "value": "C59736"},
            {"label": "San Marco dAlunzio", "value": "C59737"},
            {"label": "San Mauro Castelverde", "value": "C59738"},
            {"label": "San Michele di Ganzaria", "value": "C59739"},
            {"label": "San Pier Niceto", "value": "C59740"},
            {"label": "San Piero Patti", "value": "C59741"},
            {"label": "San Pietro Clarenza", "value": "C59742"},
            {"label": "San Salvatore di Fitalia", "value": "C59743"},
            {"label": "San Teodoro", "value": "C59744"},
            {"label": "San Vito Lo Capo", "value": "C59745"},
            {"label": "SantAgata di Militello", "value": "C59746"},
            {"label": "SantAgata Li Battiati", "value": "C59747"},
            {"label": "SantAlessio Siculo", "value": "C59748"},
            {"label": "SantAlfio", "value": "C59749"},
            {"label": "SantAngelo di Brolo", "value": "C59750"},
            {"label": "SantAngelo Muxaro", "value": "C59751"},
            {"label": "Santa Caterina Villarmosa", "value": "C59752"},
            {"label": "Santa Cristina Gela", "value": "C59753"},
            {"label": "Santa Croce Camerina", "value": "C59754"},
            {"label": "Santa Domenica Vittoria", "value": "C59755"},
            {"label": "Santa Elisabetta", "value": "C59756"},
            {"label": "Santa Flavia", "value": "C59757"},
            {"label": "Santa Lucia del Mela", "value": "C59758"},
            {"label": "Santa Margherita di Belice", "value": "C59759"},
            {"label": "Santa Maria di Licodia", "value": "C59760"},
            {"label": "Santa Maria la Stella", "value": "C59761"},
            {"label": "Santa Marina Salina", "value": "C59762"},
            {"label": "Santa Ninfa", "value": "C59763"},
            {"label": "Santa Teresa di Riva", "value": "C59764"},
            {"label": "Santa Venerina", "value": "C59765"},
            {"label": "Santo Stefano di Camastra", "value": "C59766"},
            {"label": "Santo Stefano Quisquina", "value": "C59767"},
            {"label": "Saponara", "value": "C59768"},
            {"label": "Saponara Marittima", "value": "C59769"},
            {"label": "Sasi", "value": "C59770"},
            {"label": "Savoca", "value": "C59771"},
            {"label": "Scaletta Zanclea", "value": "C59772"},
            {"label": "Sciacca", "value": "C59773"},
            {"label": "Sciara", "value": "C59774"},
            {"label": "Scicli", "value": "C59775"},
            {"label": "Scillato", "value": "C59776"},
            {"label": "Sclafani Bagni", "value": "C59777"},
            {"label": "Scoglitti", "value": "C59778"},
            {"label": "Scordia", "value": "C59779"},
            {"label": "Serradifalco", "value": "C59780"},
            {"label": "Sfaranda", "value": "C59781"},
            {"label": "Sferracavallo", "value": "C59782"},
            {"label": "Siculiana", "value": "C59783"},
            {"label": "Sinagra", "value": "C59784"},
            {"label": "Siracusa", "value": "C59785"},
            {"label": "Solarino", "value": "C59786"},
            {"label": "Sommatino", "value": "C59787"},
            {"label": "Sortino", "value": "C59788"},
            {"label": "Spadafora", "value": "C59789"},
            {"label": "Sparta", "value": "C59790"},
            {"label": "Sperlinga", "value": "C59791"},
            {"label": "Sutera", "value": "C59792"},
            {"label": "Taormina", "value": "C59793"},
            {"label": "Terme", "value": "C59794"},
            {"label": "Termini Imerese", "value": "C59795"},
            {"label": "Terrasini", "value": "C59796"},
            {"label": "Tonnara di Bonagia", "value": "C59797"},
            {"label": "Tonnarella", "value": "C59798"},
            {"label": "Torre Colonna-Sperone", "value": "C59799"},
            {"label": "Torregrotta", "value": "C59800"},
            {"label": "Torrenova", "value": "C59801"},
            {"label": "Torretta", "value": "C59802"},
            {"label": "Tortorici", "value": "C59803"},
            {"label": "Trabia", "value": "C59804"},
            {"label": "Trapani", "value": "C59805"},
            {"label": "Trappeto", "value": "C59806"},
            {"label": "Tre Fontane", "value": "C59807"},
            {"label": "Trecastagni", "value": "C59808"},
            {"label": "Tremestieri Etneo", "value": "C59809"},
            {"label": "Tripi", "value": "C59810"},
            {"label": "Triscina", "value": "C59811"},
            {"label": "Troina", "value": "C59812"},
            {"label": "Tusa", "value": "C59813"},
            {"label": "Ucria", "value": "C59814"},
            {"label": "Ustica", "value": "C59815"},
            {"label": "Valderice", "value": "C59816"},
            {"label": "Valdina", "value": "C59817"},
            {"label": "Valguarnera Caropepe", "value": "C59818"},
            {"label": "Valledolmo", "value": "C59819"},
            {"label": "Vallelunga Pratameno", "value": "C59820"},
            {"label": "Valverde", "value": "C59821"},
            {"label": "Vambolieri", "value": "C59822"},
            {"label": "Venetico Marina", "value": "C59823"},
            {"label": "Venetico Superiore", "value": "C59824"},
            {"label": "Ventimiglia di Sicilia", "value": "C59825"},
            {"label": "Viagrande", "value": "C59826"},
            {"label": "Vicari", "value": "C59827"},
            {"label": "Vigliatore 2", "value": "C59828"},
            {"label": "Villa Ciambra", "value": "C59829"},
            {"label": "Villabate", "value": "C59830"},
            {"label": "Villafranca Sicula", "value": "C59831"},
            {"label": "Villafranca Tirrena", "value": "C59832"},
            {"label": "Villafrati", "value": "C59833"},
            {"label": "Villaggio del Pino-Le Ginestre", "value": "C59834"},
            {"label": "Villalba", "value": "C59835"},
            {"label": "Villarosa", "value": "C59836"},
            {"label": "Villaseta", "value": "C59837"},
            {"label": "Villasmundo", "value": "C59838"},
            {"label": "Vita", "value": "C59839"},
            {"label": "Vittoria", "value": "C59840"},
            {"label": "Vizzini", "value": "C59841"},
            {"label": "Zafferana Etnea", "value": "C59842"}],
 "Sidi Bel Abbes": [{"label": "Ain El Berd District", "value": "C412"},
                    {"label": "Balidat Ameur", "value": "C413"},
                    {"label": "Belarbi", "value": "C414"},
                    {"label": "Ben Badis, Sid Bel Abbes", "value": "C415"},
                    {"label": "Djamaa", "value": "C416"},
                    {"label": "El Bour", "value": "C417"},
                    {"label": "El Hadjira", "value": "C418"},
                    {"label": "Haoud El Hamra", "value": "C419"},
                    {"label": "Hassi Messaoud", "value": "C420"},
                    {"label": "Lamtar", "value": "C421"},
                    {"label": "Marhoum", "value": "C422"},
                    {"label": "Megarine", "value": "C423"},
                    {"label": "Merine", "value": "C424"},
                    {"label": "Mezaourou", "value": "C425"},
                    {"label": "Moggar", "value": "C426"},
                    {"label": "Moulay Slissen", "value": "C427"},
                    {"label": "NGoussa", "value": "C428"},
                    {"label": "Ouargla", "value": "C429"},
                    {"label": "Rouissat", "value": "C430"},
                    {"label": "Sfissef", "value": "C431"},
                    {"label": "Sidi Ali Boussidi", "value": "C432"},
                    {"label": "Sidi Amrane", "value": "C433"},
                    {"label": "Sidi Bel Abbes", "value": "C434"},
                    {"label": "Sidi Brahim", "value": "C435"},
                    {"label": "Sidi Hamadouche", "value": "C436"},
                    {"label": "Sidi Slimane", "value": "C437"},
                    {"label": "Sidi Yacoub", "value": "C438"},
                    {"label": "Sidi Yahia", "value": "C439"},
                    {"label": "Tabia, Sid Bel Abbes", "value": "C440"},
                    {"label": "Taibet", "value": "C441"},
                    {"label": "Tamellaht", "value": "C442"},
                    {"label": "Tamerna Djedida", "value": "C443"},
                    {"label": "Tebesbest", "value": "C444"},
                    {"label": "Teghalimet", "value": "C445"},
                    {"label": "Telagh", "value": "C446"},
                    {"label": "Tenezara", "value": "C447"},
                    {"label": "Tenira", "value": "C448"},
                    {"label": "Tessala", "value": "C449"},
                    {"label": "Touggourt", "value": "C450"},
                    {"label": "Zerouala", "value": "C451"}],
 "Sidi Bouzid Governorate": [{"label": "Bir el Hafey", "value": "C119780"},
                             {"label": "Er Regueb", "value": "C119781"},
                             {"label": "Jilma", "value": "C119782"},
                             {"label": "Mezzouna", "value": "C119783"},
                             {"label": "Sidi Bouzid", "value": "C119784"}],
 "Siem Reap": [{"label": "Siem Reap", "value": "C16548"},
               {"label": "Srok Angkor Thum", "value": "C16549"},
               {"label": "Srok Prasat Bakong", "value": "C16550"},
               {"label": "Svay Leu", "value": "C16551"},
               {"label": "Varin", "value": "C16552"}],
 "Siggiewi": [{"label": "Siggiewi", "value": "C65917"}],
 "Sigulda Municipality": [{"label": "Sigulda", "value": "C64938"}],
 "Sihanoukville": [{"label": "Sihanoukville", "value": "C16553"},
                   {"label": "Srok Stueng Hav", "value": "C16554"}],
 "Siirt": [{"label": "Baykan", "value": "C120953"},
           {"label": "Civankan", "value": "C120954"},
           {"label": "Doganca", "value": "C120955"},
           {"label": "Dogankoy", "value": "C120956"},
           {"label": "Eruh", "value": "C120957"},
           {"label": "Gokbudak", "value": "C120958"},
           {"label": "Gokcekoru", "value": "C120959"},
           {"label": "Golgelikonak", "value": "C120960"},
           {"label": "Gozpinar", "value": "C120961"},
           {"label": "Gulecler", "value": "C120962"},
           {"label": "Kayabaglar", "value": "C120963"},
           {"label": "Kurtalan", "value": "C120964"},
           {"label": "Lodi", "value": "C120965"},
           {"label": "Okcular", "value": "C120966"},
           {"label": "Palamutlu", "value": "C120967"},
           {"label": "Pervari", "value": "C120968"},
           {"label": "Serhatta", "value": "C120969"},
           {"label": "Siirt", "value": "C120970"},
           {"label": "Sirvan", "value": "C120971"},
           {"label": "Taliban", "value": "C120972"},
           {"label": "Tasli", "value": "C120973"},
           {"label": "Tillo", "value": "C120974"}],
 "Sikasso Region": [{"label": "Bougouni", "value": "C65855"},
                    {"label": "Kolondieba", "value": "C65856"},
                    {"label": "Koutiala", "value": "C65857"},
                    {"label": "Koutiala Cercle", "value": "C65858"},
                    {"label": "Ntossoni", "value": "C65859"},
                    {"label": "Sikasso", "value": "C65860"},
                    {"label": "Yorosso", "value": "C65861"}],
 "Sikkim": [{"label": "East District", "value": "C47729"},
            {"label": "Gangtok", "value": "C47730"},
            {"label": "Gyalshing", "value": "C47731"},
            {"label": "Jorethang", "value": "C47732"},
            {"label": "Mangan", "value": "C47733"},
            {"label": "Namchi", "value": "C47734"},
            {"label": "Naya Bazar", "value": "C47735"},
            {"label": "North District", "value": "C47736"},
            {"label": "Rangpo", "value": "C47737"},
            {"label": "Singtam", "value": "C47738"},
            {"label": "South District", "value": "C47739"},
            {"label": "West District", "value": "C47740"}],
 "Sila Region": [{"label": "Goz Beida", "value": "C17885"}],
 "Silesian Voivodeship": [{"label": "Bakow", "value": "C90101"},
                          {"label": "Bazanowice", "value": "C90102"},
                          {"label": "Bedzin", "value": "C90103"},
                          {"label": "Belk", "value": "C90104"},
                          {"label": "Belsznica", "value": "C90105"},
                          {"label": "Bestwina", "value": "C90106"},
                          {"label": "Bestwinka", "value": "C90107"},
                          {"label": "Bielsko-Biala", "value": "C90108"},
                          {"label": "Bielsko-Biala", "value": "C90109"},
                          {"label": "Bierun", "value": "C90110"},
                          {"label": "Blachownia", "value": "C90111"},
                          {"label": "Bobrowniki", "value": "C90112"},
                          {"label": "Bojszowy", "value": "C90113"},
                          {"label": "Bojszowy Nowe", "value": "C90114"},
                          {"label": "Boronow", "value": "C90115"},
                          {"label": "Borowno", "value": "C90116"},
                          {"label": "Borucin", "value": "C90117"},
                          {"label": "Brenna", "value": "C90118"},
                          {"label": "Bronow", "value": "C90119"},
                          {"label": "Brzeziny Slaskie", "value": "C90120"},
                          {"label": "Buczkowice", "value": "C90121"},
                          {"label": "Bujakow", "value": "C90122"},
                          {"label": "Bystra", "value": "C90123"},
                          {"label": "Bytom", "value": "C90124"},
                          {"label": "Chalupki", "value": "C90125"},
                          {"label": "Chelm Slaski", "value": "C90126"},
                          {"label": "Choron", "value": "C90127"},
                          {"label": "Chorzow", "value": "C90128"},
                          {"label": "Chruszczobrod", "value": "C90129"},
                          {"label": "Chybie", "value": "C90130"},
                          {"label": "Ciasna", "value": "C90131"},
                          {"label": "Ciecina", "value": "C90132"},
                          {"label": "Cielmice", "value": "C90133"},
                          {"label": "Cieszyn", "value": "C90134"},
                          {"label": "Cisiec", "value": "C90135"},
                          {"label": "Cisownica", "value": "C90136"},
                          {"label": "Cynkow", "value": "C90137"},
                          {"label": "Czaniec", "value": "C90138"},
                          {"label": "Czarkow", "value": "C90139"},
                          {"label": "Czechowice-Dziedzice", "value": "C90140"},
                          {"label": "Czeladz", "value": "C90141"},
                          {"label": "Czernica", "value": "C90142"},
                          {"label": "Czernichow", "value": "C90143"},
                          {"label": "Czerwionka-Leszczyny", "value": "C90144"},
                          {"label": "Czestochowa", "value": "C90145"},
                          {"label": "Czyzowice", "value": "C90146"},
                          {"label": "Dabrowa Gornicza", "value": "C90147"},
                          {"label": "Dabrowa Zielona", "value": "C90148"},
                          {"label": "Debowiec", "value": "C90149"},
                          {"label": "Dobieszowice", "value": "C90150"},
                          {"label": "Drogomysl", "value": "C90151"},
                          {"label": "Dziegielow", "value": "C90152"},
                          {"label": "Frydek", "value": "C90153"},
                          {"label": "Gardawice", "value": "C90154"},
                          {"label": "Gaszowice", "value": "C90155"},
                          {"label": "Gieraltowice", "value": "C90156"},
                          {"label": "Gilowice", "value": "C90157"},
                          {"label": "Gliwice", "value": "C90158"},
                          {"label": "Gniazdow", "value": "C90159"},
                          {"label": "Goczalkowice Zdroj", "value": "C90160"},
                          {"label": "Godow", "value": "C90161"},
                          {"label": "Godziszka", "value": "C90162"},
                          {"label": "Goleszow", "value": "C90163"},
                          {"label": "Golkowice", "value": "C90164"},
                          {"label": "Gora", "value": "C90165"},
                          {"label": "Gorki Wielkie", "value": "C90166"},
                          {"label": "Gorzyce", "value": "C90167"},
                          {"label": "Gorzyczki", "value": "C90168"},
                          {"label": "Gostyn", "value": "C90169"},
                          {"label": "Hazlach", "value": "C90170"},
                          {"label": "Herby", "value": "C90171"},
                          {"label": "Huta Stara B", "value": "C90172"},
                          {"label": "Imielin", "value": "C90173"},
                          {"label": "Istebna", "value": "C90174"},
                          {"label": "Jankowice", "value": "C90175"},
                          {"label": "Jankowice Rybnickie", "value": "C90176"},
                          {"label": "Janowice", "value": "C90177"},
                          {"label": "Jasienica", "value": "C90178"},
                          {"label": "Jastrzab", "value": "C90179"},
                          {"label": "Jastrzebie Zdroj", "value": "C90180"},
                          {"label": "Jastrzebie-Zdroj", "value": "C90181"},
                          {"label": "Jaworze", "value": "C90182"},
                          {"label": "Jaworzno", "value": "C90183"},
                          {"label": "Jaworzynka", "value": "C90184"},
                          {"label": "Jejkowice", "value": "C90185"},
                          {"label": "Jelesnia", "value": "C90186"},
                          {"label": "Juszczyna", "value": "C90187"},
                          {"label": "Kaczyce", "value": "C90188"},
                          {"label": "Kalej", "value": "C90189"},
                          {"label": "Kalety", "value": "C90190"},
                          {"label": "Kamesznica", "value": "C90191"},
                          {"label": "Kamienica Polska", "value": "C90192"},
                          {"label": "Kamyk", "value": "C90193"},
                          {"label": "Kaniow", "value": "C90194"},
                          {"label": "Katowice", "value": "C90195"},
                          {"label": "Klobuck", "value": "C90196"},
                          {"label": "Klomnice", "value": "C90197"},
                          {"label": "Knurow", "value": "C90198"},
                          {"label": "Kobiernice", "value": "C90199"},
                          {"label": "Kobior", "value": "C90200"},
                          {"label": "Kochanowice", "value": "C90201"},
                          {"label": "Konczyce Male", "value": "C90202"},
                          {"label": "Konczyce Wielkie", "value": "C90203"},
                          {"label": "Koniakow", "value": "C90204"},
                          {"label": "Koniecpol", "value": "C90205"},
                          {"label": "Konopiska", "value": "C90206"},
                          {"label": "Korbielow", "value": "C90207"},
                          {"label": "Kornowac", "value": "C90208"},
                          {"label": "Koscielec", "value": "C90209"},
                          {"label": "Koszarawa", "value": "C90210"},
                          {"label": "Koszecin", "value": "C90211"},
                          {"label": "Kozieglowy", "value": "C90212"},
                          {"label": "Kozy", "value": "C90213"},
                          {"label": "Kroczyce", "value": "C90214"},
                          {"label": "Krupski Mlyn", "value": "C90215"},
                          {"label": "Kryry", "value": "C90216"},
                          {"label": "Krzanowice", "value": "C90217"},
                          {"label": "Krzepice", "value": "C90218"},
                          {"label": "Krzyzanowice", "value": "C90219"},
                          {"label": "Krzyzowa", "value": "C90220"},
                          {"label": "Krzyzowice", "value": "C90221"},
                          {"label": "Ksiazenice", "value": "C90222"},
                          {"label": "Kuznia Raciborska", "value": "C90223"},
                          {"label": "Laliki", "value": "C90224"},
                          {"label": "Ledziny", "value": "C90225"},
                          {"label": "Lelow", "value": "C90226"},
                          {"label": "Lesna", "value": "C90227"},
                          {"label": "Ligota", "value": "C90228"},
                          {"label": "Lipie", "value": "C90229"},
                          {"label": "Lipowa", "value": "C90230"},
                          {"label": "Lubliniec", "value": "C90231"},
                          {"label": "Lubomia", "value": "C90232"},
                          {"label": "Lyski", "value": "C90233"},
                          {"label": "Laziska", "value": "C90234"},
                          {"label": "Laziska Gorne", "value": "C90235"},
                          {"label": "Lazy", "value": "C90236"},
                          {"label": "Lekawica", "value": "C90237"},
                          {"label": "Lobodno", "value": "C90238"},
                          {"label": "Lodygowice", "value": "C90239"},
                          {"label": "Marklowice", "value": "C90240"},
                          {"label": "Mazancowice", "value": "C90241"},
                          {"label": "Miasteczko Slaskie", "value": "C90242"},
                          {"label": "Miedzna", "value": "C90243"},
                          {"label": "Miedzno", "value": "C90244"},
                          {"label": "Miedzybrodzie Bialskie",
                           "value": "C90245"},
                          {"label": "Mierzecice", "value": "C90246"},
                          {"label": "Mikolow", "value": "C90247"},
                          {"label": "Milowka", "value": "C90248"},
                          {"label": "Mnich", "value": "C90249"},
                          {"label": "Mstow", "value": "C90250"},
                          {"label": "Mszana", "value": "C90251"},
                          {"label": "Murcki", "value": "C90252"},
                          {"label": "Mykanow", "value": "C90253"},
                          {"label": "Myslowice", "value": "C90254"},
                          {"label": "Myszkow", "value": "C90255"},
                          {"label": "Naklo", "value": "C90256"},
                          {"label": "Nedza", "value": "C90257"},
                          {"label": "Niedobczyce", "value": "C90258"},
                          {"label": "Niegowonice", "value": "C90259"},
                          {"label": "Ochaby", "value": "C90260"},
                          {"label": "Ogrodzieniec", "value": "C90261"},
                          {"label": "Olsztyn", "value": "C90262"},
                          {"label": "Olza", "value": "C90263"},
                          {"label": "Opatow", "value": "C90264"},
                          {"label": "Ornontowice", "value": "C90265"},
                          {"label": "Orzech", "value": "C90266"},
                          {"label": "Orzesze", "value": "C90267"},
                          {"label": "Ostrowy nad Oksza", "value": "C90268"},
                          {"label": "Ozarowice", "value": "C90269"},
                          {"label": "Paniowki", "value": "C90270"},
                          {"label": "Panki", "value": "C90271"},
                          {"label": "Pawlowice", "value": "C90272"},
                          {"label": "Pawonkow", "value": "C90273"},
                          {"label": "Pewel Slemienska", "value": "C90274"},
                          {"label": "Pewel Wielka", "value": "C90275"},
                          {"label": "Piasek", "value": "C90276"},
                          {"label": "Piekary Slaskie", "value": "C90277"},
                          {"label": "Pielgrzymowice", "value": "C90278"},
                          {"label": "Piersciec", "value": "C90279"},
                          {"label": "Pietrowice Wielkie", "value": "C90280"},
                          {"label": "Pietrzykowice", "value": "C90281"},
                          {"label": "Pilchowice", "value": "C90282"},
                          {"label": "Pilica", "value": "C90283"},
                          {"label": "Pisarzowice", "value": "C90284"},
                          {"label": "Pogorze", "value": "C90285"},
                          {"label": "Pogrzebien", "value": "C90286"},
                          {"label": "Pogwizdow", "value": "C90287"},
                          {"label": "Polomia", "value": "C90288"},
                          {"label": "Porabka", "value": "C90289"},
                          {"label": "Poraj", "value": "C90290"},
                          {"label": "Poreba", "value": "C90291"},
                          {"label": "Potok Zloty", "value": "C90292"},
                          {"label": "Powiat bedzinski", "value": "C90293"},
                          {"label": "Powiat bielski", "value": "C90294"},
                          {"label": "Powiat bierunsko-ledzinski",
                           "value": "C90295"},
                          {"label": "Powiat cieszynski", "value": "C90296"},
                          {"label": "Powiat czestochowski", "value": "C90297"},
                          {"label": "Powiat gliwicki", "value": "C90298"},
                          {"label": "Powiat klobucki", "value": "C90299"},
                          {"label": "Powiat lubliniecki", "value": "C90300"},
                          {"label": "Powiat mikolowski", "value": "C90301"},
                          {"label": "Powiat myszkowski", "value": "C90302"},
                          {"label": "Powiat pszczynski", "value": "C90303"},
                          {"label": "Powiat raciborski", "value": "C90304"},
                          {"label": "Powiat rybnicki", "value": "C90305"},
                          {"label": "Powiat tarnogorski", "value": "C90306"},
                          {"label": "Powiat wodzislawski", "value": "C90307"},
                          {"label": "Powiat zawiercianski", "value": "C90308"},
                          {"label": "Powiat zywiecki", "value": "C90309"},
                          {"label": "Pruchna", "value": "C90310"},
                          {"label": "Przyborow", "value": "C90311"},
                          {"label": "Przyrow", "value": "C90312"},
                          {"label": "Przystajn", "value": "C90313"},
                          {"label": "Przyszowice", "value": "C90314"},
                          {"label": "Psary", "value": "C90315"},
                          {"label": "Pszczyna", "value": "C90316"},
                          {"label": "Pszow", "value": "C90317"},
                          {"label": "Puncow", "value": "C90318"},
                          {"label": "Pyskowice", "value": "C90319"},
                          {"label": "Raciborz", "value": "C90320"},
                          {"label": "Radlin", "value": "C90321"},
                          {"label": "Radostowice", "value": "C90322"},
                          {"label": "Radziechowy", "value": "C90323"},
                          {"label": "Radzionkow", "value": "C90324"},
                          {"label": "Rajcza", "value": "C90325"},
                          {"label": "Raszczyce", "value": "C90326"},
                          {"label": "Redziny", "value": "C90327"},
                          {"label": "Rogow", "value": "C90328"},
                          {"label": "Rogoznik", "value": "C90329"},
                          {"label": "Rokitno Szlacheckie", "value": "C90330"},
                          {"label": "Ruda Slaska", "value": "C90331"},
                          {"label": "Rudnik", "value": "C90332"},
                          {"label": "Rudniki", "value": "C90333"},
                          {"label": "Rudy", "value": "C90334"},
                          {"label": "Rudziczka", "value": "C90335"},
                          {"label": "Rudziniec", "value": "C90336"},
                          {"label": "Rybarzowice", "value": "C90337"},
                          {"label": "Rybnik", "value": "C90338"},
                          {"label": "Rycerka Dolna", "value": "C90339"},
                          {"label": "Rycerka Gorna", "value": "C90340"},
                          {"label": "Rydultowy", "value": "C90341"},
                          {"label": "Saczow", "value": "C90342"},
                          {"label": "Sarnow", "value": "C90343"},
                          {"label": "Siemianowice Slaskie", "value": "C90344"},
                          {"label": "Sierakow Slaski", "value": "C90345"},
                          {"label": "Siewierz", "value": "C90346"},
                          {"label": "Skoczow", "value": "C90347"},
                          {"label": "Skrzyszow", "value": "C90348"},
                          {"label": "Slemien", "value": "C90349"},
                          {"label": "Slawkow", "value": "C90350"},
                          {"label": "Sol", "value": "C90351"},
                          {"label": "Sopotnia Wielka", "value": "C90352"},
                          {"label": "Sosnicowice", "value": "C90353"},
                          {"label": "Sosnowiec", "value": "C90354"},
                          {"label": "Stanowice", "value": "C90355"},
                          {"label": "Starcza", "value": "C90356"},
                          {"label": "Starokrzepice", "value": "C90357"},
                          {"label": "Strumien", "value": "C90358"},
                          {"label": "Strzebin", "value": "C90359"},
                          {"label": "Strzyzowice", "value": "C90360"},
                          {"label": "Studzionka", "value": "C90361"},
                          {"label": "Suszec", "value": "C90362"},
                          {"label": "Swierklaniec", "value": "C90363"},
                          {"label": "Swierklany Dolne", "value": "C90364"},
                          {"label": "Swierklany Gorne", "value": "C90365"},
                          {"label": "Swietochlowice", "value": "C90366"},
                          {"label": "Swinna", "value": "C90367"},
                          {"label": "Syrynia", "value": "C90368"},
                          {"label": "Szczekociny", "value": "C90369"},
                          {"label": "Szczerbice", "value": "C90370"},
                          {"label": "Szczyrk", "value": "C90371"},
                          {"label": "Tarnowskie Gory", "value": "C90372"},
                          {"label": "Toszek", "value": "C90373"},
                          {"label": "Truskolasy", "value": "C90374"},
                          {"label": "Trzebinia", "value": "C90375"},
                          {"label": "Turza Slaska", "value": "C90376"},
                          {"label": "Tworkow", "value": "C90377"},
                          {"label": "Tworog", "value": "C90378"},
                          {"label": "Tychy", "value": "C90379"},
                          {"label": "Ujsoly", "value": "C90380"},
                          {"label": "Ustron", "value": "C90381"},
                          {"label": "Wegierska Gorka", "value": "C90382"},
                          {"label": "Wielowies", "value": "C90383"},
                          {"label": "Wieprz", "value": "C90384"},
                          {"label": "Wieszowa", "value": "C90385"},
                          {"label": "Wilamowice", "value": "C90386"},
                          {"label": "Wilcza", "value": "C90387"},
                          {"label": "Wilkowice", "value": "C90388"},
                          {"label": "Wisla", "value": "C90389"},
                          {"label": "Wlodowice", "value": "C90390"},
                          {"label": "Wodzislaw Slaski", "value": "C90391"},
                          {"label": "Wojkowice", "value": "C90392"},
                          {"label": "Wola", "value": "C90393"},
                          {"label": "Wozniki", "value": "C90394"},
                          {"label": "Wreczyca Wielka", "value": "C90395"},
                          {"label": "Wyry", "value": "C90396"},
                          {"label": "Wysoka", "value": "C90397"},
                          {"label": "Zablocie", "value": "C90398"},
                          {"label": "Zabnica", "value": "C90399"},
                          {"label": "Zaborze", "value": "C90400"},
                          {"label": "Zabrze", "value": "C90401"},
                          {"label": "Zabrzeg", "value": "C90402"},
                          {"label": "Zamarski", "value": "C90403"},
                          {"label": "Zarki", "value": "C90404"},
                          {"label": "Zarki-Letnisko", "value": "C90405"},
                          {"label": "Zarzecze", "value": "C90406"},
                          {"label": "Zawidow", "value": "C90407"},
                          {"label": "Zawiercie", "value": "C90408"},
                          {"label": "Zbroslawice", "value": "C90409"},
                          {"label": "Zbytkow", "value": "C90410"},
                          {"label": "Zebrzydowice", "value": "C90411"},
                          {"label": "Zernica", "value": "C90412"},
                          {"label": "Zory", "value": "C90413"},
                          {"label": "Zywiec", "value": "C90414"}],
 "Siliana Governorate": [{"label": "Bou Arada", "value": "C119785"},
                         {"label": "Gafour", "value": "C119786"},
                         {"label": "Kesra", "value": "C119787"},
                         {"label": "Le Krib", "value": "C119788"},
                         {"label": "Maktar", "value": "C119789"},
                         {"label": "Siliana", "value": "C119790"}],
 "Silistra Province": [{"label": "Alfatar", "value": "C16130"},
                       {"label": "Dulovo", "value": "C16131"},
                       {"label": "Glavinitsa", "value": "C16132"},
                       {"label": "Kaynardzha", "value": "C16133"},
                       {"label": "Obshtina Alfatar", "value": "C16134"},
                       {"label": "Obshtina Dulovo", "value": "C16135"},
                       {"label": "Obshtina Glavinitsa", "value": "C16136"},
                       {"label": "Obshtina Kaynardzha", "value": "C16137"},
                       {"label": "Obshtina Silistra", "value": "C16138"},
                       {"label": "Obshtina Sitovo", "value": "C16139"},
                       {"label": "Obshtina Tutrakan", "value": "C16140"},
                       {"label": "Silistra", "value": "C16141"},
                       {"label": "Sitovo", "value": "C16142"},
                       {"label": "Tutrakan", "value": "C16143"}],
 "Simiyu": [{"label": "Bariadi", "value": "C118307"},
            {"label": "Kisesa", "value": "C118308"},
            {"label": "Lalago", "value": "C118309"},
            {"label": "Malampaka", "value": "C118310"},
            {"label": "Maswa", "value": "C118311"},
            {"label": "Matonga", "value": "C118312"},
            {"label": "Nyakabindi", "value": "C118313"},
            {"label": "Nyalikungu", "value": "C118314"},
            {"label": "Somanda", "value": "C118315"}],
 "Sinaloa": [{"label": "Adolfo Lopez Mateos (El Tamarindo)", "value": "C73405"},
             {"label": "Adolfo Ruiz Cortines", "value": "C73406"},
             {"label": "Agua Caliente Grande (De Gastelum)", "value": "C73407"},
             {"label": "Agua Verde", "value": "C73408"},
             {"label": "Ahome", "value": "C73409"},
             {"label": "Alfonso G. Calderon (Poblado Siete)",
              "value": "C73410"},
             {"label": "Alfonso G. Calderon Velarde", "value": "C73411"},
             {"label": "Alfredo V. Bonfil (Siete Ejidos)", "value": "C73412"},
             {"label": "Alhuey", "value": "C73413"},
             {"label": "Altata", "value": "C73414"},
             {"label": "Angostura", "value": "C73415"},
             {"label": "Apoderado", "value": "C73416"},
             {"label": "Baburia", "value": "C73417"},
             {"label": "Bachigualatito", "value": "C73418"},
             {"label": "Bachoco", "value": "C73419"},
             {"label": "Bachomobampo Numero Dos", "value": "C73420"},
             {"label": "Bacorehuis", "value": "C73421"},
             {"label": "Bacubirito", "value": "C73422"},
             {"label": "Badiraguato", "value": "C73423"},
             {"label": "Bagojo Colectivo", "value": "C73424"},
             {"label": "Bamoa", "value": "C73425"},
             {"label": "Bariometo", "value": "C73426"},
             {"label": "Barron", "value": "C73427"},
             {"label": "Bellavista", "value": "C73428"},
             {"label": "Benito Juarez", "value": "C73429"},
             {"label": "Benito Juarez (Vinateria)", "value": "C73430"},
             {"label": "Buen Retiro (El Retiro)", "value": "C73431"},
             {"label": "Cacalotan", "value": "C73432"},
             {"label": "Caimanero", "value": "C73433"},
             {"label": "Campo Balbuena", "value": "C73434"},
             {"label": "Campo la Arrocera", "value": "C73435"},
             {"label": "Campo Pesquero el Colorado", "value": "C73436"},
             {"label": "Carricitos", "value": "C73437"},
             {"label": "Casa Blanca", "value": "C73438"},
             {"label": "CERESO Nuevo", "value": "C73439"},
             {"label": "Cerrillos (Campo 35)", "value": "C73440"},
             {"label": "Cerro Agudo", "value": "C73441"},
             {"label": "Ceuta", "value": "C73442"},
             {"label": "Chametla", "value": "C73443"},
             {"label": "Charay", "value": "C73444"},
             {"label": "Chihuahuita", "value": "C73445"},
             {"label": "Chinobampo", "value": "C73446"},
             {"label": "Choix", "value": "C73447"},
             {"label": "Cofradia de Navolato (Cofradia de los Rocha)",
              "value": "C73448"},
             {"label": "Colonia 24 de Febrero", "value": "C73449"},
             {"label": "Colonia Agricola Mexico (Palmitas)", "value": "C73450"},
             {"label": "Colonia Michoacana", "value": "C73451"},
             {"label": "Concentracion 5 de Febrero", "value": "C73452"},
             {"label": "Concordia", "value": "C73453"},
             {"label": "Constancia", "value": "C73454"},
             {"label": "Corerepe", "value": "C73455"},
             {"label": "Cosala", "value": "C73456"},
             {"label": "Costa Azul", "value": "C73457"},
             {"label": "Coyotitan", "value": "C73458"},
             {"label": "Cristo Rey", "value": "C73459"},
             {"label": "Cruz Blanca", "value": "C73460"},
             {"label": "Cubiri de Portelas", "value": "C73461"},
             {"label": "Culiacan", "value": "C73462"},
             {"label": "Culiacancito", "value": "C73463"},
             {"label": "Dautillos", "value": "C73464"},
             {"label": "Dos de Abril", "value": "C73465"},
             {"label": "Ejido Cajon Ojo de Agua Numero Dos", "value": "C73466"},
             {"label": "Ejido Cinco de Mayo", "value": "C73467"},
             {"label": "Ejido Cohuibampo", "value": "C73468"},
             {"label": "Ejido Mayocoba", "value": "C73469"},
             {"label": "Ejido Ohuira", "value": "C73470"},
             {"label": "Ejido Tosalibampo", "value": "C73471"},
             {"label": "El Aguajito", "value": "C73472"},
             {"label": "El Burrion", "value": "C73473"},
             {"label": "El Carrizo", "value": "C73474"},
             {"label": "El Castillo", "value": "C73475"},
             {"label": "El Diez", "value": "C73476"},
             {"label": "El Dorado", "value": "C73477"},
             {"label": "El Fuerte", "value": "C73478"},
             {"label": "El Guayabo", "value": "C73479"},
             {"label": "El Habal", "value": "C73480"},
             {"label": "El Higueral", "value": "C73481"},
             {"label": "El Huajote", "value": "C73482"},
             {"label": "El Huitusi", "value": "C73483"},
             {"label": "El Limon de los Ramos", "value": "C73484"},
             {"label": "El Molino", "value": "C73485"},
             {"label": "El Nio", "value": "C73486"},
             {"label": "El Pochotal", "value": "C73487"},
             {"label": "El Potrero de Sataya", "value": "C73488"},
             {"label": "El Pozole", "value": "C73489"},
             {"label": "El Progreso", "value": "C73490"},
             {"label": "El Quelite", "value": "C73491"},
             {"label": "El Refugio", "value": "C73492"},
             {"label": "El Roble", "value": "C73493"},
             {"label": "El Rosario", "value": "C73494"},
             {"label": "El Sabino", "value": "C73495"},
             {"label": "El Saladito", "value": "C73496"},
             {"label": "El Varal (San Sebastian Numero Uno)",
              "value": "C73497"},
             {"label": "El Verde", "value": "C73498"},
             {"label": "El Walamo", "value": "C73499"},
             {"label": "Eldorado", "value": "C73500"},
             {"label": "Elota", "value": "C73501"},
             {"label": "Empaque Tarriba", "value": "C73502"},
             {"label": "Escamillas", "value": "C73503"},
             {"label": "Escuinapa", "value": "C73504"},
             {"label": "Estacion Bamoa", "value": "C73505"},
             {"label": "Estacion Capomas", "value": "C73506"},
             {"label": "Estacion Chimaneco", "value": "C73507"},
             {"label": "Estacion Obispo", "value": "C73508"},
             {"label": "Estacion Rosales", "value": "C73509"},
             {"label": "Felipe Angeles", "value": "C73510"},
             {"label": "Flor Azul", "value": "C73511"},
             {"label": "Fraccionamiento los angeles", "value": "C73512"},
             {"label": "Gabriel Leyva Solano (Zapotillo Dos)",
              "value": "C73513"},
             {"label": "Gabriel Leyva Velazquez", "value": "C73514"},
             {"label": "Gambino", "value": "C73515"},
             {"label": "Genaro Estrada", "value": "C73516"},
             {"label": "General angel Flores (La Palma)", "value": "C73517"},
             {"label": "Goros Numero Dos", "value": "C73518"},
             {"label": "Guadalupe Victoria", "value": "C73519"},
             {"label": "Guamuchil", "value": "C73520"},
             {"label": "Guasave", "value": "C73521"},
             {"label": "Guasavito", "value": "C73522"},
             {"label": "Gustavo Diaz Ordaz", "value": "C73523"},
             {"label": "Heriberto Valdez Romero (El Guayabo)",
              "value": "C73524"},
             {"label": "Higuera de los Vega", "value": "C73525"},
             {"label": "Higuera de Zaragoza", "value": "C73526"},
             {"label": "Higueras de Abuya", "value": "C73527"},
             {"label": "Higueras de los Natoches", "value": "C73528"},
             {"label": "Huepaco", "value": "C73529"},
             {"label": "Isla del Bosque", "value": "C73530"},
             {"label": "Javier Rojo Gomez", "value": "C73531"},
             {"label": "Jesus Maria", "value": "C73532"},
             {"label": "Jitzamuri", "value": "C73533"},
             {"label": "Juan Aldama (El Tigre)", "value": "C73534"},
             {"label": "Juan Jose Rios", "value": "C73535"},
             {"label": "La Brecha", "value": "C73536"},
             {"label": "La Concepcion", "value": "C73537"},
             {"label": "La Concha (La Concepcion)", "value": "C73538"},
             {"label": "La Cruz", "value": "C73539"},
             {"label": "La Despensa", "value": "C73540"},
             {"label": "La Esperanza", "value": "C73541"},
             {"label": "La Loma (La Loma de Quila)", "value": "C73542"},
             {"label": "La Noria", "value": "C73543"},
             {"label": "La Noria de San Antonio", "value": "C73544"},
             {"label": "La Palma", "value": "C73545"},
             {"label": "La Palmita y Anexos (La Presita)", "value": "C73546"},
             {"label": "La Presita", "value": "C73547"},
             {"label": "La Reforma", "value": "C73548"},
             {"label": "La Trinidad", "value": "C73549"},
             {"label": "Ladrilleras de Ocoro", "value": "C73550"},
             {"label": "Las Aguamitas", "value": "C73551"},
             {"label": "Las Arenitas", "value": "C73552"},
             {"label": "Las Brisas", "value": "C73553"},
             {"label": "Las Compuertas", "value": "C73554"},
             {"label": "Las Grullas Margen Derecha", "value": "C73555"},
             {"label": "Las Grullas Margen Izquierda", "value": "C73556"},
             {"label": "Las Moras", "value": "C73557"},
             {"label": "Las Quemazones", "value": "C73558"},
             {"label": "Lazaro Cardenas", "value": "C73559"},
             {"label": "Leyva Solano", "value": "C73560"},
             {"label": "Licenciado Benito Juarez (Campo Gobierno)",
              "value": "C73561"},
             {"label": "Llano Grande", "value": "C73562"},
             {"label": "Lo de Jesus (Campo Romero)", "value": "C73563"},
             {"label": "Los Angeles", "value": "C73564"},
             {"label": "Los Mochis", "value": "C73565"},
             {"label": "Los Pozos", "value": "C73566"},
             {"label": "Macapule", "value": "C73567"},
             {"label": "Marcol", "value": "C73568"},
             {"label": "Maripa", "value": "C73569"},
             {"label": "Mazatlan", "value": "C73570"},
             {"label": "Melchor Ocampo", "value": "C73571"},
             {"label": "Mesillas", "value": "C73572"},
             {"label": "Miguel Valdez Quintero (El Corazon)",
              "value": "C73573"},
             {"label": "Mochicahui", "value": "C73574"},
             {"label": "Mochis", "value": "C73575"},
             {"label": "Mocorito", "value": "C73576"},
             {"label": "Naranjo", "value": "C73577"},
             {"label": "Navolato", "value": "C73578"},
             {"label": "Nuevo San Miguel", "value": "C73579"},
             {"label": "Ojo de Agua de Palmillas", "value": "C73580"},
             {"label": "Orba (Infiernito)", "value": "C73581"},
             {"label": "Oso Viejo", "value": "C73582"},
             {"label": "Palmito del Verde", "value": "C73583"},
             {"label": "Palos Verdes", "value": "C73584"},
             {"label": "Pericos", "value": "C73585"},
             {"label": "Piaxtla de Abajo", "value": "C73586"},
             {"label": "Pitahayal", "value": "C73587"},
             {"label": "Plan de Ayala (Campo Cinco)", "value": "C73588"},
             {"label": "Poblado Numero Cinco", "value": "C73589"},
             {"label": "Portaceli", "value": "C73590"},
             {"label": "Portugues de Galvez", "value": "C73591"},
             {"label": "Potrerillo del Norote", "value": "C73592"},
             {"label": "Potrero de los Sanchez (Estacion Techa)",
              "value": "C73593"},
             {"label": "Primero de Mayo", "value": "C73594"},
             {"label": "Pueblo Nuevo", "value": "C73595"},
             {"label": "Pueblo Viejo", "value": "C73596"},
             {"label": "Pueblos Unidos", "value": "C73597"},
             {"label": "Quila", "value": "C73598"},
             {"label": "Ranchito de Castro", "value": "C73599"},
             {"label": "Recoveco", "value": "C73600"},
             {"label": "Ricardo Flores Magon", "value": "C73601"},
             {"label": "Salado", "value": "C73602"},
             {"label": "San Blas", "value": "C73603"},
             {"label": "San Diego", "value": "C73604"},
             {"label": "San Francisco de Tacuichamona", "value": "C73605"},
             {"label": "San Ignacio", "value": "C73606"},
             {"label": "San Isidro", "value": "C73607"},
             {"label": "San Miguel Zapotitlan", "value": "C73608"},
             {"label": "San Pedro Guasave", "value": "C73609"},
             {"label": "San Rafael", "value": "C73610"},
             {"label": "Sanalona", "value": "C73611"},
             {"label": "Sinaloa", "value": "C73612"},
             {"label": "Sinaloa de Leyva", "value": "C73613"},
             {"label": "Siqueros", "value": "C73614"},
             {"label": "Tamazula", "value": "C73615"},
             {"label": "Tanques", "value": "C73616"},
             {"label": "Taxtes", "value": "C73617"},
             {"label": "Teacapan", "value": "C73618"},
             {"label": "Tecualilla", "value": "C73619"},
             {"label": "Topolobampo", "value": "C73620"},
             {"label": "Tres Garantias", "value": "C73621"},
             {"label": "Vallejo (Porvenir Vallejo)", "value": "C73622"},
             {"label": "Villa de Costa Rica", "value": "C73623"},
             {"label": "Villa Juarez", "value": "C73624"},
             {"label": "Villa Union", "value": "C73625"}],
 "Sindh": [{"label": "Adilpur", "value": "C79651"},
           {"label": "Badin", "value": "C79652"},
           {"label": "Bagarji", "value": "C79653"},
           {"label": "Bandhi", "value": "C79654"},
           {"label": "Berani", "value": "C79655"},
           {"label": "Bhan", "value": "C79656"},
           {"label": "Bhiria", "value": "C79657"},
           {"label": "Bhit Shah", "value": "C79658"},
           {"label": "Bozdar Wada", "value": "C79659"},
           {"label": "Bulri", "value": "C79660"},
           {"label": "Chak", "value": "C79661"},
           {"label": "Chamber", "value": "C79662"},
           {"label": "Chhor", "value": "C79663"},
           {"label": "Chuhar Jamali", "value": "C79664"},
           {"label": "Dadu", "value": "C79665"},
           {"label": "Daromehar", "value": "C79666"},
           {"label": "Darya Khan Marri", "value": "C79667"},
           {"label": "Daulatpur", "value": "C79668"},
           {"label": "Daur", "value": "C79669"},
           {"label": "Dhoro Naro", "value": "C79670"},
           {"label": "Digri", "value": "C79671"},
           {"label": "Diplo", "value": "C79672"},
           {"label": "Dokri", "value": "C79673"},
           {"label": "Gambat", "value": "C79674"},
           {"label": "Garhiyasin", "value": "C79675"},
           {"label": "Gharo", "value": "C79676"},
           {"label": "Ghauspur", "value": "C79677"},
           {"label": "Ghotki", "value": "C79678"},
           {"label": "Goth Garelo", "value": "C79679"},
           {"label": "Goth Phulji", "value": "C79680"},
           {"label": "Goth Radhan", "value": "C79681"},
           {"label": "Hala", "value": "C79682"},
           {"label": "Hingorja", "value": "C79683"},
           {"label": "Hyderabad", "value": "C79684"},
           {"label": "Islamkot", "value": "C79685"},
           {"label": "Jacobabad", "value": "C79686"},
           {"label": "Jam Sahib", "value": "C79687"},
           {"label": "Jamshoro", "value": "C79688"},
           {"label": "Jati", "value": "C79689"},
           {"label": "Jhol", "value": "C79690"},
           {"label": "Johi", "value": "C79691"},
           {"label": "Kadhan", "value": "C79692"},
           {"label": "Kambar", "value": "C79693"},
           {"label": "Kandhkot", "value": "C79694"},
           {"label": "Kandiari", "value": "C79695"},
           {"label": "Kandiaro", "value": "C79696"},
           {"label": "Karachi", "value": "C79697"},
           {"label": "Karaundi", "value": "C79698"},
           {"label": "Kario Ghanwar", "value": "C79699"},
           {"label": "Kashmor", "value": "C79700"},
           {"label": "Keti Bandar", "value": "C79701"},
           {"label": "Khadro", "value": "C79702"},
           {"label": "Khairpur", "value": "C79703"},
           {"label": "Khairpur Mirs", "value": "C79704"},
           {"label": "Khairpur Nathan Shah", "value": "C79705"},
           {"label": "Khanpur Mahar", "value": "C79706"},
           {"label": "Kot Diji", "value": "C79707"},
           {"label": "Kotri", "value": "C79708"},
           {"label": "Kunri", "value": "C79709"},
           {"label": "Lakhi", "value": "C79710"},
           {"label": "Larkana", "value": "C79711"},
           {"label": "Madeji", "value": "C79712"},
           {"label": "Malir Cantonment", "value": "C79713"},
           {"label": "Matiari", "value": "C79714"},
           {"label": "Matli", "value": "C79715"},
           {"label": "Mehar", "value": "C79716"},
           {"label": "Miro Khan", "value": "C79717"},
           {"label": "Mirpur Bhtoro", "value": "C79718"},
           {"label": "Mirpur Khas", "value": "C79719"},
           {"label": "Mirpur Mathelo", "value": "C79720"},
           {"label": "Mirpur Sakro", "value": "C79721"},
           {"label": "Mirwah Gorchani", "value": "C79722"},
           {"label": "Mithi", "value": "C79723"},
           {"label": "Moro", "value": "C79724"},
           {"label": "Nabisar", "value": "C79725"},
           {"label": "Nasirabad", "value": "C79726"},
           {"label": "Naudero", "value": "C79727"},
           {"label": "Naukot", "value": "C79728"},
           {"label": "Naushahro Firoz", "value": "C79729"},
           {"label": "Nawabshah", "value": "C79730"},
           {"label": "New Badah", "value": "C79731"},
           {"label": "Pad Idan", "value": "C79732"},
           {"label": "Pano Aqil", "value": "C79733"},
           {"label": "Pir Jo Goth", "value": "C79734"},
           {"label": "Pithoro", "value": "C79735"},
           {"label": "Rajo Khanani", "value": "C79736"},
           {"label": "Ranipur", "value": "C79737"},
           {"label": "Ratodero", "value": "C79738"},
           {"label": "Rohri", "value": "C79739"},
           {"label": "Rustam", "value": "C79740"},
           {"label": "Sakrand", "value": "C79741"},
           {"label": "Samaro", "value": "C79742"},
           {"label": "Sanghar", "value": "C79743"},
           {"label": "Sann", "value": "C79744"},
           {"label": "Sehwan", "value": "C79745"},
           {"label": "Setharja Old", "value": "C79746"},
           {"label": "Shahdad Kot", "value": "C79747"},
           {"label": "Shahdadpur", "value": "C79748"},
           {"label": "Shahpur Chakar", "value": "C79749"},
           {"label": "Shikarpur", "value": "C79750"},
           {"label": "Sinjhoro", "value": "C79751"},
           {"label": "Sita Road", "value": "C79752"},
           {"label": "Sobhodero", "value": "C79753"},
           {"label": "Sukkur", "value": "C79754"},
           {"label": "Talhar", "value": "C79755"},
           {"label": "Tando Adam", "value": "C79756"},
           {"label": "Tando Allahyar", "value": "C79757"},
           {"label": "Tando Bago", "value": "C79758"},
           {"label": "Tando Jam", "value": "C79759"},
           {"label": "Tando Mitha Khan", "value": "C79760"},
           {"label": "Tando Muhammad Khan", "value": "C79761"},
           {"label": "Tangwani", "value": "C79762"},
           {"label": "Tharu Shah", "value": "C79763"},
           {"label": "Thatta", "value": "C79764"},
           {"label": "Thul", "value": "C79765"},
           {"label": "Ubauro", "value": "C79766"},
           {"label": "Umarkot", "value": "C79767"},
           {"label": "Umerkot District", "value": "C79768"},
           {"label": "Warah", "value": "C79769"}],
 "Sing Buri": [{"label": "Amphoe Bang Rachan", "value": "C119361"},
               {"label": "Amphoe In Buri", "value": "C119362"},
               {"label": "Amphoe Khai Bang Rachan", "value": "C119363"},
               {"label": "Amphoe Mueang Sing Buri", "value": "C119364"},
               {"label": "Amphoe Phrom Buri", "value": "C119365"},
               {"label": "Amphoe Tha Chang", "value": "C119366"},
               {"label": "Bang Racham", "value": "C119367"},
               {"label": "In Buri", "value": "C119368"},
               {"label": "Sing Buri", "value": "C119369"}],
 "Singerei District": [{"label": "Bilicenii Vechi", "value": "C75532"},
                       {"label": "Biruinta", "value": "C75533"},
                       {"label": "Singerei", "value": "C75534"}],
 "Singida": [{"label": "Igugunu", "value": "C118316"},
             {"label": "Ikungi", "value": "C118317"},
             {"label": "Ilongero", "value": "C118318"},
             {"label": "Itigi", "value": "C118319"},
             {"label": "Kilimatinde", "value": "C118320"},
             {"label": "Kintinku", "value": "C118321"},
             {"label": "Kiomboi", "value": "C118322"},
             {"label": "Mgandu", "value": "C118323"},
             {"label": "Mtinko", "value": "C118324"},
             {"label": "Mungaa", "value": "C118325"},
             {"label": "Ndago", "value": "C118326"},
             {"label": "Puma", "value": "C118327"},
             {"label": "Sepuka", "value": "C118328"},
             {"label": "Shelui", "value": "C118329"},
             {"label": "Singida", "value": "C118330"}],
 "Sinoe County": [{"label": "Greenville", "value": "C65019"}],
 "Sinop": [{"label": "Boyabat", "value": "C120975"},
           {"label": "Dikmen", "value": "C120976"},
           {"label": "Duragan", "value": "C120977"},
           {"label": "Erfelek", "value": "C120978"},
           {"label": "Gerze", "value": "C120979"},
           {"label": "Merkez", "value": "C120980"},
           {"label": "Sarayduzu", "value": "C120981"},
           {"label": "Sinop", "value": "C120982"},
           {"label": "Turkeli", "value": "C120983"}],
 "Siparia Regional Corporation": [{"label": "Siparia", "value": "C119674"},
                                  {"label": "Ward of Siparia",
                                   "value": "C119675"}],
 "Sirdaryo Region": [{"label": "Guliston", "value": "C147081"},
                     {"label": "Sirdaryo", "value": "C147082"},
                     {"label": "Yangiyer", "value": "C147083"}],
 "Sirnak": [{"label": "Andac", "value": "C121003"},
            {"label": "Baglica", "value": "C121004"},
            {"label": "Balli", "value": "C121005"},
            {"label": "Balveren", "value": "C121006"},
            {"label": "Baraniferho", "value": "C121007"},
            {"label": "Basaran", "value": "C121008"},
            {"label": "Becuh", "value": "C121009"},
            {"label": "Beytussebap", "value": "C121010"},
            {"label": "Bisbin", "value": "C121011"},
            {"label": "Bogazoren", "value": "C121012"},
            {"label": "Bostanci", "value": "C121013"},
            {"label": "Bozalan", "value": "C121014"},
            {"label": "Caliskan", "value": "C121015"},
            {"label": "Cardakli", "value": "C121016"},
            {"label": "Cizre", "value": "C121017"},
            {"label": "Dicle", "value": "C121018"},
            {"label": "Doruklu", "value": "C121019"},
            {"label": "Duzova", "value": "C121020"},
            {"label": "Girikbedro", "value": "C121021"},
            {"label": "Guclukonak Ilcesi", "value": "C121022"},
            {"label": "Hilal", "value": "C121023"},
            {"label": "Idil", "value": "C121024"},
            {"label": "Kizilsu", "value": "C121025"},
            {"label": "Kumcati", "value": "C121026"},
            {"label": "Mezraa", "value": "C121027"},
            {"label": "Ortabag", "value": "C121028"},
            {"label": "Ortakoy", "value": "C121029"},
            {"label": "Pinarbasi", "value": "C121030"},
            {"label": "Razvaliny Ayinvan", "value": "C121031"},
            {"label": "Senoba", "value": "C121032"},
            {"label": "Silopi", "value": "C121033"},
            {"label": "Sirnak", "value": "C121034"},
            {"label": "Sulak", "value": "C121035"},
            {"label": "Tililan", "value": "C121036"},
            {"label": "Uludere", "value": "C121037"},
            {"label": "Uzungecit", "value": "C121038"},
            {"label": "Yemisli", "value": "C121039"},
            {"label": "Yenikoy", "value": "C121040"}],
 "Sirte District": [{"label": "Qasr Abu Hadi", "value": "C65060"},
                    {"label": "Sirte", "value": "C65061"}],
 "Sisak-Moslavina": [{"label": "Budasevo", "value": "C21370"},
                     {"label": "Dvor", "value": "C21371"},
                     {"label": "Glina", "value": "C21372"},
                     {"label": "Grad Glina", "value": "C21373"},
                     {"label": "Grad Hrvatska Kostajnica", "value": "C21374"},
                     {"label": "Grad Kutina", "value": "C21375"},
                     {"label": "Grad Novska", "value": "C21376"},
                     {"label": "Grad Petrinja", "value": "C21377"},
                     {"label": "Grad Sisak", "value": "C21378"},
                     {"label": "Gvozd", "value": "C21379"},
                     {"label": "Hrvatska Kostajnica", "value": "C21380"},
                     {"label": "Kutina", "value": "C21381"},
                     {"label": "Lekenik", "value": "C21382"},
                     {"label": "Lipovljani", "value": "C21383"},
                     {"label": "Martinska Ves", "value": "C21384"},
                     {"label": "Novska", "value": "C21385"},
                     {"label": "Opcina Dvor", "value": "C21386"},
                     {"label": "Opcina Gvozd", "value": "C21387"},
                     {"label": "Petrinja", "value": "C21388"},
                     {"label": "Popovaca", "value": "C21389"},
                     {"label": "Repusnica", "value": "C21390"},
                     {"label": "Sisak", "value": "C21391"},
                     {"label": "Sunja", "value": "C21392"},
                     {"label": "Voloder", "value": "C21393"}],
 "Sistan and Baluchestan": [{"label": "Chabahar", "value": "C51011"},
                            {"label": "Fannuj", "value": "C51012"},
                            {"label": "Fanuj", "value": "C51013"},
                            {"label": "Hamoon", "value": "C51014"},
                            {"label": "Iranshahr", "value": "C51015"},
                            {"label": "Khash", "value": "C51016"},
                            {"label": "Mirjaveh", "value": "C51017"},
                            {"label": "Nikshahr", "value": "C51018"},
                            {"label": "Nimruz", "value": "C51019"},
                            {"label": "Nosratabad", "value": "C51020"},
                            {"label": "Qaser-e Qand", "value": "C51021"},
                            {"label": "Qasr-e Qand", "value": "C51022"},
                            {"label": "Shahrestan-e Chabahar",
                             "value": "C51023"},
                            {"label": "Shahrestan-e Dalgan", "value": "C51024"},
                            {"label": "Shahrestan-e Hirmand",
                             "value": "C51025"},
                            {"label": "Shahrestan-e iranshahr",
                             "value": "C51026"},
                            {"label": "Shahrestan-e Khash", "value": "C51027"},
                            {"label": "Shahrestan-e Konarak",
                             "value": "C51028"},
                            {"label": "Shahrestan-e Mehrestan",
                             "value": "C51029"},
                            {"label": "Shahrestan-e Nikshahr",
                             "value": "C51030"},
                            {"label": "Shahrestan-e Saravan",
                             "value": "C51031"},
                            {"label": "Shahrestan-e Sarbaz", "value": "C51032"},
                            {"label": "Shahrestan-e Sib va Suran",
                             "value": "C51033"},
                            {"label": "Shahrestan-e Zabol", "value": "C51034"},
                            {"label": "Shahrestan-e Zahedan",
                             "value": "C51035"},
                            {"label": "Zabol", "value": "C51036"},
                            {"label": "Zahedan", "value": "C51037"},
                            {"label": "Zehak", "value": "C51038"}],
 "Sivas": [{"label": "Akincilar", "value": "C120984"},
           {"label": "Aksu", "value": "C120985"},
           {"label": "Altinyayla", "value": "C120986"},
           {"label": "Divrigi", "value": "C120987"},
           {"label": "Dogansar", "value": "C120988"},
           {"label": "Gemerek", "value": "C120989"},
           {"label": "Golova", "value": "C120990"},
           {"label": "Gurun", "value": "C120991"},
           {"label": "Hafik", "value": "C120992"},
           {"label": "Imranli", "value": "C120993"},
           {"label": "Kangal", "value": "C120994"},
           {"label": "Koyulhisar", "value": "C120995"},
           {"label": "Merkez", "value": "C120996"},
           {"label": "Sarkisla", "value": "C120997"},
           {"label": "Sivas", "value": "C120998"},
           {"label": "Susehri", "value": "C120999"},
           {"label": "Ulas", "value": "C121000"},
           {"label": "Yildizeli Ilcesi", "value": "C121001"},
           {"label": "Zara", "value": "C121002"}],
 "Skane County": [{"label": "Ahus", "value": "C115807"},
                  {"label": "Akarp", "value": "C115808"},
                  {"label": "Anderslov", "value": "C115809"},
                  {"label": "Angelholm", "value": "C115810"},
                  {"label": "Arlov", "value": "C115811"},
                  {"label": "Asmundtorp", "value": "C115812"},
                  {"label": "Astorp", "value": "C115813"},
                  {"label": "Bara", "value": "C115814"},
                  {"label": "Barslov", "value": "C115815"},
                  {"label": "Bastad", "value": "C115816"},
                  {"label": "Billeberga", "value": "C115817"},
                  {"label": "Billesholm", "value": "C115818"},
                  {"label": "Bjarnum", "value": "C115819"},
                  {"label": "Bjarred", "value": "C115820"},
                  {"label": "Bjuv", "value": "C115821"},
                  {"label": "Blentarp", "value": "C115822"},
                  {"label": "Broby", "value": "C115823"},
                  {"label": "Bromolla", "value": "C115824"},
                  {"label": "Bunkeflostrand", "value": "C115825"},
                  {"label": "Dalby", "value": "C115826"},
                  {"label": "Degeberga", "value": "C115827"},
                  {"label": "Ekeby", "value": "C115828"},
                  {"label": "Eslov", "value": "C115829"},
                  {"label": "Farlov", "value": "C115830"},
                  {"label": "Fjalkinge", "value": "C115831"},
                  {"label": "Forslov", "value": "C115832"},
                  {"label": "Furulund", "value": "C115833"},
                  {"label": "Gantofta", "value": "C115834"},
                  {"label": "Garsnas", "value": "C115835"},
                  {"label": "Genarp", "value": "C115836"},
                  {"label": "Glimakra", "value": "C115837"},
                  {"label": "Glumslov", "value": "C115838"},
                  {"label": "Haljarp", "value": "C115839"},
                  {"label": "Hammar", "value": "C115840"},
                  {"label": "Hanaskog", "value": "C115841"},
                  {"label": "Hassleholm", "value": "C115842"},
                  {"label": "Hastveda", "value": "C115843"},
                  {"label": "Helsingborg", "value": "C115844"},
                  {"label": "Hittarp", "value": "C115845"},
                  {"label": "Hjarnarp", "value": "C115846"},
                  {"label": "Hjarup", "value": "C115847"},
                  {"label": "Hofterup", "value": "C115848"},
                  {"label": "Hoganas", "value": "C115849"},
                  {"label": "Hokopinge", "value": "C115850"},
                  {"label": "Hollviken", "value": "C115851"},
                  {"label": "Hoor", "value": "C115852"},
                  {"label": "Horby", "value": "C115853"},
                  {"label": "Hyllinge", "value": "C115854"},
                  {"label": "Jonstorp", "value": "C115855"},
                  {"label": "Kagerod", "value": "C115856"},
                  {"label": "Kavlinge", "value": "C115857"},
                  {"label": "Klagerup", "value": "C115858"},
                  {"label": "Klippan", "value": "C115859"},
                  {"label": "Knislinge", "value": "C115860"},
                  {"label": "Kopingebro", "value": "C115861"},
                  {"label": "Kristianstad", "value": "C115862"},
                  {"label": "Kvidinge", "value": "C115863"},
                  {"label": "Landskrona", "value": "C115864"},
                  {"label": "Ljungbyhed", "value": "C115865"},
                  {"label": "Ljunghusen", "value": "C115866"},
                  {"label": "Loberod", "value": "C115867"},
                  {"label": "Loddekopinge", "value": "C115868"},
                  {"label": "Lomma", "value": "C115869"},
                  {"label": "Lonsboda", "value": "C115870"},
                  {"label": "Lund", "value": "C115871"},
                  {"label": "Malmo", "value": "C115872"},
                  {"label": "Marieholm", "value": "C115873"},
                  {"label": "Morarp", "value": "C115874"},
                  {"label": "Munka-Ljungby", "value": "C115875"},
                  {"label": "Nasum", "value": "C115876"},
                  {"label": "Norra Asum", "value": "C115877"},
                  {"label": "Odakra", "value": "C115878"},
                  {"label": "Onnestad", "value": "C115879"},
                  {"label": "Orkelljunga", "value": "C115880"},
                  {"label": "Osby", "value": "C115881"},
                  {"label": "Ostra Ljungby", "value": "C115882"},
                  {"label": "Oxie", "value": "C115883"},
                  {"label": "Paarp", "value": "C115884"},
                  {"label": "Perstorp", "value": "C115885"},
                  {"label": "Rydeback", "value": "C115886"},
                  {"label": "Rydsgard", "value": "C115887"},
                  {"label": "Satofta", "value": "C115888"},
                  {"label": "Saxtorpsskogen", "value": "C115889"},
                  {"label": "Sibbhult", "value": "C115890"},
                  {"label": "Simrishamn", "value": "C115891"},
                  {"label": "Sjobo", "value": "C115892"},
                  {"label": "Skanor med Falsterbo", "value": "C115893"},
                  {"label": "Skare", "value": "C115894"},
                  {"label": "Skegrie", "value": "C115895"},
                  {"label": "Skivarp", "value": "C115896"},
                  {"label": "Skurup", "value": "C115897"},
                  {"label": "Smygehamn", "value": "C115898"},
                  {"label": "Sodra Sandby", "value": "C115899"},
                  {"label": "Sosdala", "value": "C115900"},
                  {"label": "Staffanstorp", "value": "C115901"},
                  {"label": "Stangby", "value": "C115902"},
                  {"label": "Stehag", "value": "C115903"},
                  {"label": "Strovelstorp", "value": "C115904"},
                  {"label": "Svalov", "value": "C115905"},
                  {"label": "Svedala", "value": "C115906"},
                  {"label": "Teckomatorp", "value": "C115907"},
                  {"label": "Tollarp", "value": "C115908"},
                  {"label": "Tollarp1", "value": "C115909"},
                  {"label": "Tomelilla", "value": "C115910"},
                  {"label": "Torekov", "value": "C115911"},
                  {"label": "Tormestorp", "value": "C115912"},
                  {"label": "Trelleborg", "value": "C115913"},
                  {"label": "Tygelsjo", "value": "C115914"},
                  {"label": "Tyringe", "value": "C115915"},
                  {"label": "Valje", "value": "C115916"},
                  {"label": "Veberod", "value": "C115917"},
                  {"label": "Vejbystrand", "value": "C115918"},
                  {"label": "Vellinge", "value": "C115919"},
                  {"label": "Viken", "value": "C115920"},
                  {"label": "Vinslov", "value": "C115921"},
                  {"label": "Vittsjo", "value": "C115922"},
                  {"label": "Ystad", "value": "C115923"}],
 "Skikda": [{"label": "Azzaba", "value": "C452"},
            {"label": "Karkira", "value": "C453"},
            {"label": "Skikda", "value": "C454"},
            {"label": "Tamalous", "value": "C455"}],
 "Skocjan Municipality": [{"label": "Skocjan", "value": "C107807"}],
 "Skofja Loka Municipality": [{"label": "Skofja Loka", "value": "C107808"},
                              {"label": "Sv. Duh", "value": "C107809"}],
 "Skriveri Municipality": [{"label": "Skriveri", "value": "C64939"}],
 "Skrunda Municipality": [{"label": "Skrunda", "value": "C64940"}],
 "Sliema": [{"label": "Sliema", "value": "C65918"}],
 "Sliven Province": [{"label": "Kermen", "value": "C16144"},
                     {"label": "Kotel", "value": "C16145"},
                     {"label": "Nova Zagora", "value": "C16146"},
                     {"label": "Obshtina Kotel", "value": "C16147"},
                     {"label": "Obshtina Nova Zagora", "value": "C16148"},
                     {"label": "Obshtina Sliven", "value": "C16149"},
                     {"label": "Obshtina Tvarditsa", "value": "C16150"},
                     {"label": "Sliven", "value": "C16151"},
                     {"label": "Tvarditsa", "value": "C16152"}],
 "Slovenj Gradec City Municipality": [{"label": "Legen", "value": "C107810"},
                                      {"label": "Pamece", "value": "C107811"},
                                      {"label": "Slovenj Gradec",
                                       "value": "C107812"},
                                      {"label": "Smartno pri Slovenj Gradcu",
                                       "value": "C107813"}],
 "Slovenska Bistrica Municipality": [{"label": "Pragersko", "value": "C107814"},
                                     {"label": "Slovenska Bistrica",
                                      "value": "C107815"},
                                     {"label": "Zgornja Polskava",
                                      "value": "C107816"}],
 "Slovenske Konjice Municipality": [{"label": "Slovenske Konjice",
                                     "value": "C107817"}],
 "Smarje pri Jelsah Municipality": [{"label": "Smarje pri Jelsah",
                                     "value": "C107818"}],
 "Smarjeske Toplice Municipality": [{"label": "Smarjeta", "value": "C107819"}],
 "Smartno ob Paki Municipality": [{"label": "Smartno ob Paki",
                                   "value": "C107820"}],
 "Smartno pri Litiji Municipality": [{"label": "Smartno pri Litiji",
                                      "value": "C107821"}],
 "Smiltene Municipality": [{"label": "Smiltene", "value": "C64941"}],
 "Smolensk Oblast": [{"label": "Demidov", "value": "C104596"},
                     {"label": "Desnogorsk", "value": "C104597"},
                     {"label": "Dorogobuzh", "value": "C104598"},
                     {"label": "Dukhovshchina", "value": "C104599"},
                     {"label": "Gagarin", "value": "C104600"},
                     {"label": "Glinka", "value": "C104601"},
                     {"label": "Golynki", "value": "C104602"},
                     {"label": "Kardymovo", "value": "C104603"},
                     {"label": "Katyn", "value": "C104604"},
                     {"label": "Khislavichi", "value": "C104605"},
                     {"label": "Kholm-Zhirkovskiy", "value": "C104606"},
                     {"label": "Monastyrshchina", "value": "C104607"},
                     {"label": "Novodugino", "value": "C104608"},
                     {"label": "Oster", "value": "C104609"},
                     {"label": "Ozernyy", "value": "C104610"},
                     {"label": "Pechersk", "value": "C104611"},
                     {"label": "Pochinok", "value": "C104612"},
                     {"label": "Roslavl", "value": "C104613"},
                     {"label": "Rudnya", "value": "C104614"},
                     {"label": "Safonovo", "value": "C104615"},
                     {"label": "Safonovskiy Rayon", "value": "C104616"},
                     {"label": "Shatalovo", "value": "C104617"},
                     {"label": "Shumyachi", "value": "C104618"},
                     {"label": "Smolensk", "value": "C104619"},
                     {"label": "Stodolishche", "value": "C104620"},
                     {"label": "Sychevka", "value": "C104621"},
                     {"label": "Talashkino", "value": "C104622"},
                     {"label": "Temkino", "value": "C104623"},
                     {"label": "Ugra", "value": "C104624"},
                     {"label": "Velizh", "value": "C104625"},
                     {"label": "Verkhnedneprovskiy", "value": "C104626"},
                     {"label": "Vyazma", "value": "C104627"},
                     {"label": "Yartsevo", "value": "C104628"},
                     {"label": "Yekimovichi", "value": "C104629"},
                     {"label": "Yelnya", "value": "C104630"},
                     {"label": "Yershichi", "value": "C104631"}],
 "Smolyan Province": [{"label": "Banite", "value": "C16153"},
                      {"label": "Borino", "value": "C16154"},
                      {"label": "Chepelare", "value": "C16155"},
                      {"label": "Devin", "value": "C16156"},
                      {"label": "Dospat", "value": "C16157"},
                      {"label": "Gyovren", "value": "C16158"},
                      {"label": "Madan", "value": "C16159"},
                      {"label": "Nedelino", "value": "C16160"},
                      {"label": "Obshtina Banite", "value": "C16161"},
                      {"label": "Obshtina Borino", "value": "C16162"},
                      {"label": "Obshtina Chepelare", "value": "C16163"},
                      {"label": "Obshtina Devin", "value": "C16164"},
                      {"label": "Obshtina Dospat", "value": "C16165"},
                      {"label": "Obshtina Madan", "value": "C16166"},
                      {"label": "Obshtina Nedelino", "value": "C16167"},
                      {"label": "Obshtina Rudozem", "value": "C16168"},
                      {"label": "Obshtina Smolyan", "value": "C16169"},
                      {"label": "Obshtina Zlatograd", "value": "C16170"},
                      {"label": "Rudozem", "value": "C16171"},
                      {"label": "Smolyan", "value": "C16172"},
                      {"label": "Zlatograd", "value": "C16173"}],
 "Soc Trang": [{"label": "Huyen Ke Sach", "value": "C147620"},
               {"label": "Huyen Long Phu", "value": "C147621"},
               {"label": "Huyen My Tu", "value": "C147622"},
               {"label": "Huyen My Xuyen", "value": "C147623"},
               {"label": "Huyen Nga Nam", "value": "C147624"},
               {"label": "Huyen Thanh Tri", "value": "C147625"},
               {"label": "Soc Trang", "value": "C147626"}],
 "Soccsksargen": [{"label": "Alabel", "value": "C87269"},
                  {"label": "Alamada", "value": "C87270"},
                  {"label": "Amas", "value": "C87271"},
                  {"label": "Bagontapay", "value": "C87272"},
                  {"label": "Baguer", "value": "C87273"},
                  {"label": "Baliton", "value": "C87274"},
                  {"label": "Balogo", "value": "C87275"},
                  {"label": "Banawa", "value": "C87276"},
                  {"label": "Banga", "value": "C87277"},
                  {"label": "Banisilan", "value": "C87278"},
                  {"label": "Bantogon", "value": "C87279"},
                  {"label": "Barongis", "value": "C87280"},
                  {"label": "Basak", "value": "C87281"},
                  {"label": "Batasan", "value": "C87282"},
                  {"label": "Batutitik", "value": "C87283"},
                  {"label": "Bau", "value": "C87284"},
                  {"label": "Bayasong", "value": "C87285"},
                  {"label": "Bialong", "value": "C87286"},
                  {"label": "Biwang", "value": "C87287"},
                  {"label": "Blingkong", "value": "C87288"},
                  {"label": "Buadtasan", "value": "C87289"},
                  {"label": "Bual", "value": "C87290"},
                  {"label": "Buawan", "value": "C87291"},
                  {"label": "Buayan", "value": "C87292"},
                  {"label": "Bukay Pait", "value": "C87293"},
                  {"label": "Bulatukan", "value": "C87294"},
                  {"label": "Burias", "value": "C87295"},
                  {"label": "Busok", "value": "C87296"},
                  {"label": "Carmen", "value": "C87297"},
                  {"label": "Carpenter Hill", "value": "C87298"},
                  {"label": "Cebuano", "value": "C87299"},
                  {"label": "Colongulo", "value": "C87300"},
                  {"label": "Columbio", "value": "C87301"},
                  {"label": "Conel", "value": "C87302"},
                  {"label": "Cotabato City", "value": "C87303"},
                  {"label": "Daguma", "value": "C87304"},
                  {"label": "Dahay", "value": "C87305"},
                  {"label": "Daliao", "value": "C87306"},
                  {"label": "Damawato", "value": "C87307"},
                  {"label": "Dansuli", "value": "C87308"},
                  {"label": "Digkilaan", "value": "C87309"},
                  {"label": "Dualing", "value": "C87310"},
                  {"label": "Dukay", "value": "C87311"},
                  {"label": "Dumaguil", "value": "C87312"},
                  {"label": "Dunguan", "value": "C87313"},
                  {"label": "Esperanza", "value": "C87314"},
                  {"label": "Gansing", "value": "C87315"},
                  {"label": "General Santos", "value": "C87316"},
                  {"label": "Glad", "value": "C87317"},
                  {"label": "Glamang", "value": "C87318"},
                  {"label": "Glan", "value": "C87319"},
                  {"label": "Glan Peidu", "value": "C87320"},
                  {"label": "Gocoton", "value": "C87321"},
                  {"label": "Guinsang-an", "value": "C87322"},
                  {"label": "Ilaya", "value": "C87323"},
                  {"label": "Iligan City", "value": "C87324"},
                  {"label": "Isulan", "value": "C87325"},
                  {"label": "Kabacan", "value": "C87326"},
                  {"label": "Kabalen", "value": "C87327"},
                  {"label": "Kablalan", "value": "C87328"},
                  {"label": "Kalaisan", "value": "C87329"},
                  {"label": "Kalamangog", "value": "C87330"},
                  {"label": "Kalamansig", "value": "C87331"},
                  {"label": "Kalandagan", "value": "C87332"},
                  {"label": "Kamanga", "value": "C87333"},
                  {"label": "Kapatan", "value": "C87334"},
                  {"label": "Kapaya", "value": "C87335"},
                  {"label": "Kapingkong", "value": "C87336"},
                  {"label": "Katangawan", "value": "C87337"},
                  {"label": "Katubao", "value": "C87338"},
                  {"label": "Kiamba", "value": "C87339"},
                  {"label": "Kidapawan", "value": "C87340"},
                  {"label": "Kipalbig", "value": "C87341"},
                  {"label": "Kisante", "value": "C87342"},
                  {"label": "Kiupo", "value": "C87343"},
                  {"label": "Klinan", "value": "C87344"},
                  {"label": "Kolumbug", "value": "C87345"},
                  {"label": "Koronadal", "value": "C87346"},
                  {"label": "Kudanding", "value": "C87347"},
                  {"label": "Kulaman", "value": "C87348"},
                  {"label": "Labu-o", "value": "C87349"},
                  {"label": "Laguilayan", "value": "C87350"},
                  {"label": "Lake Sebu", "value": "C87351"},
                  {"label": "Lala", "value": "C87352"},
                  {"label": "Lamba", "value": "C87353"},
                  {"label": "Lambayong", "value": "C87354"},
                  {"label": "Lambontong", "value": "C87355"},
                  {"label": "Lamian", "value": "C87356"},
                  {"label": "Lampari", "value": "C87357"},
                  {"label": "Lampitak", "value": "C87358"},
                  {"label": "Lapuz", "value": "C87359"},
                  {"label": "Libas", "value": "C87360"},
                  {"label": "Liberty", "value": "C87361"},
                  {"label": "Libungan", "value": "C87362"},
                  {"label": "Liliongan", "value": "C87363"},
                  {"label": "Limbalod", "value": "C87364"},
                  {"label": "Limulan", "value": "C87365"},
                  {"label": "Linan", "value": "C87366"},
                  {"label": "Linao", "value": "C87367"},
                  {"label": "Lopez Jaena", "value": "C87368"},
                  {"label": "Lumatil", "value": "C87369"},
                  {"label": "Lumazal", "value": "C87370"},
                  {"label": "Lumuyon", "value": "C87371"},
                  {"label": "Lun Pequeno", "value": "C87372"},
                  {"label": "Lunen", "value": "C87373"},
                  {"label": "Maan", "value": "C87374"},
                  {"label": "Maasim", "value": "C87375"},
                  {"label": "Mabay", "value": "C87376"},
                  {"label": "Mabini", "value": "C87377"},
                  {"label": "Magpet", "value": "C87378"},
                  {"label": "Maguling", "value": "C87379"},
                  {"label": "Maibu", "value": "C87380"},
                  {"label": "Maindang", "value": "C87381"},
                  {"label": "Maitum", "value": "C87382"},
                  {"label": "Makilala", "value": "C87383"},
                  {"label": "Malamote", "value": "C87384"},
                  {"label": "Malandag", "value": "C87385"},
                  {"label": "Malapag", "value": "C87386"},
                  {"label": "Malapatan", "value": "C87387"},
                  {"label": "Malasila", "value": "C87388"},
                  {"label": "Malbang", "value": "C87389"},
                  {"label": "Malinao", "value": "C87390"},
                  {"label": "Malingao", "value": "C87391"},
                  {"label": "Malisbeng", "value": "C87392"},
                  {"label": "Malitubog", "value": "C87393"},
                  {"label": "Maltana", "value": "C87394"},
                  {"label": "Malungun", "value": "C87395"},
                  {"label": "Mamali", "value": "C87396"},
                  {"label": "Manaulanan", "value": "C87397"},
                  {"label": "Manga", "value": "C87398"},
                  {"label": "Manuangan", "value": "C87399"},
                  {"label": "Manuel Roxas", "value": "C87400"},
                  {"label": "Marbel", "value": "C87401"},
                  {"label": "Mariano Marcos", "value": "C87402"},
                  {"label": "Matalam", "value": "C87403"},
                  {"label": "Matiompong", "value": "C87404"},
                  {"label": "Midsayap", "value": "C87405"},
                  {"label": "Minapan", "value": "C87406"},
                  {"label": "Mindupok", "value": "C87407"},
                  {"label": "Nalus", "value": "C87408"},
                  {"label": "New Cebu", "value": "C87409"},
                  {"label": "New Iloilo", "value": "C87410"},
                  {"label": "New Lagao", "value": "C87411"},
                  {"label": "New Panay", "value": "C87412"},
                  {"label": "Noling", "value": "C87413"},
                  {"label": "Norala", "value": "C87414"},
                  {"label": "Nunguan", "value": "C87415"},
                  {"label": "Osias", "value": "C87416"},
                  {"label": "Paatan", "value": "C87417"},
                  {"label": "Pagalungan", "value": "C87418"},
                  {"label": "Pagangan", "value": "C87419"},
                  {"label": "Palian", "value": "C87420"},
                  {"label": "Palimbang", "value": "C87421"},
                  {"label": "Palkan", "value": "C87422"},
                  {"label": "Pamantingan", "value": "C87423"},
                  {"label": "Panay", "value": "C87424"},
                  {"label": "Pangyan", "value": "C87425"},
                  {"label": "Paraiso", "value": "C87426"},
                  {"label": "Patindeguen", "value": "C87427"},
                  {"label": "Pedtad", "value": "C87428"},
                  {"label": "Pigcawayan", "value": "C87429"},
                  {"label": "Pikit", "value": "C87430"},
                  {"label": "Pimbalayan", "value": "C87431"},
                  {"label": "Polo", "value": "C87432"},
                  {"label": "Polomolok", "value": "C87433"},
                  {"label": "Polonoling", "value": "C87434"},
                  {"label": "President Quirino", "value": "C87435"},
                  {"label": "President Roxas", "value": "C87436"},
                  {"label": "Province of Cotabato", "value": "C87437"},
                  {"label": "Province of Sarangani", "value": "C87438"},
                  {"label": "Province of South Cotabato", "value": "C87439"},
                  {"label": "Province of Sultan Kudarat", "value": "C87440"},
                  {"label": "Puloypuloy", "value": "C87441"},
                  {"label": "Punolu", "value": "C87442"},
                  {"label": "Puricay", "value": "C87443"},
                  {"label": "Ragandang", "value": "C87444"},
                  {"label": "Rogongon", "value": "C87445"},
                  {"label": "Rotonda", "value": "C87446"},
                  {"label": "Sadsalan", "value": "C87447"},
                  {"label": "Saguing", "value": "C87448"},
                  {"label": "Salimbao", "value": "C87449"},
                  {"label": "Salunayan", "value": "C87450"},
                  {"label": "Salvador", "value": "C87451"},
                  {"label": "Sampao", "value": "C87452"},
                  {"label": "San Miguel", "value": "C87453"},
                  {"label": "San Vicente", "value": "C87454"},
                  {"label": "Sangay", "value": "C87455"},
                  {"label": "Santo Nino", "value": "C87456"},
                  {"label": "Sapad", "value": "C87457"},
                  {"label": "Sapu Padidu", "value": "C87458"},
                  {"label": "Silway 7", "value": "C87459"},
                  {"label": "Sinolon", "value": "C87460"},
                  {"label": "Sulit", "value": "C87461"},
                  {"label": "Surallah", "value": "C87462"},
                  {"label": "Suyan", "value": "C87463"},
                  {"label": "Tacurong", "value": "C87464"},
                  {"label": "Taguisa", "value": "C87465"},
                  {"label": "Taluya", "value": "C87466"},
                  {"label": "Tambak", "value": "C87467"},
                  {"label": "Tambilil", "value": "C87468"},
                  {"label": "Tamnag", "value": "C87469"},
                  {"label": "Tamontaka", "value": "C87470"},
                  {"label": "Tampakan", "value": "C87471"},
                  {"label": "Tango", "value": "C87472"},
                  {"label": "Tantangan", "value": "C87473"},
                  {"label": "Telafas", "value": "C87474"},
                  {"label": "Teresita", "value": "C87475"},
                  {"label": "Tinagacan", "value": "C87476"},
                  {"label": "Tinoto", "value": "C87477"},
                  {"label": "Tomado", "value": "C87478"},
                  {"label": "Tran", "value": "C87479"},
                  {"label": "Tuka", "value": "C87480"},
                  {"label": "Tulunan", "value": "C87481"},
                  {"label": "Tupi", "value": "C87482"},
                  {"label": "Tuyan", "value": "C87483"},
                  {"label": "Upper Klinan", "value": "C87484"},
                  {"label": "Upper San Mateo", "value": "C87485"},
                  {"label": "Villamor", "value": "C87486"}],
 "Socotra Governorate": [{"label": "Hadibu", "value": "C148058"},
                         {"label": "Hidaybu", "value": "C148059"},
                         {"label": "Qalansiyah", "value": "C148060"},
                         {"label": "Qulensya Wa Abd Al Kuri",
                          "value": "C148061"}],
 "Sodermanland County": [{"label": "Akers Styckebruk", "value": "C115924"},
                         {"label": "Arla", "value": "C115925"},
                         {"label": "Arno", "value": "C115926"},
                         {"label": "Bara", "value": "C115927"},
                         {"label": "Eskilstuna", "value": "C115928"},
                         {"label": "Flen", "value": "C115929"},
                         {"label": "Gnesta", "value": "C115930"},
                         {"label": "Hallbybrunn", "value": "C115931"},
                         {"label": "Halleforsnas", "value": "C115932"},
                         {"label": "Katrineholm", "value": "C115933"},
                         {"label": "Kvicksund", "value": "C115934"},
                         {"label": "Malmkoping", "value": "C115935"},
                         {"label": "Mariefred", "value": "C115936"},
                         {"label": "Nykoping", "value": "C115937"},
                         {"label": "Olstorp", "value": "C115938"},
                         {"label": "Oxelosund", "value": "C115939"},
                         {"label": "Skogstorp", "value": "C115940"},
                         {"label": "Stallarholmen", "value": "C115941"},
                         {"label": "Stigtomta", "value": "C115942"},
                         {"label": "Strangnas", "value": "C115943"},
                         {"label": "Svalsta", "value": "C115944"},
                         {"label": "Torshalla", "value": "C115945"},
                         {"label": "Trosa", "value": "C115946"},
                         {"label": "Vagnharad", "value": "C115947"},
                         {"label": "Valla", "value": "C115948"},
                         {"label": "Vingaker", "value": "C115949"}],
 "Sodrazica Municipality": [{"label": "Sodrazica", "value": "C107822"}],
 "Sofala Province": [{"label": "Beira", "value": "C75883"},
                     {"label": "Dondo", "value": "C75884"},
                     {"label": "Nhamatanda District", "value": "C75885"}],
 "Sofia City Province": [{"label": "Buhovo", "value": "C16174"},
                         {"label": "Sofia", "value": "C16175"},
                         {"label": "Stolichna Obshtina", "value": "C16176"}],
 "Sofia Province": [{"label": "Anton", "value": "C16177"},
                    {"label": "Botevgrad", "value": "C16178"},
                    {"label": "Bozhurishte", "value": "C16179"},
                    {"label": "Chavdar", "value": "C16180"},
                    {"label": "Chelopech", "value": "C16181"},
                    {"label": "Dolna Banya", "value": "C16182"},
                    {"label": "Dragoman", "value": "C16183"},
                    {"label": "Elin Pelin", "value": "C16184"},
                    {"label": "Etropole", "value": "C16185"},
                    {"label": "Godech", "value": "C16186"},
                    {"label": "Gorna Malina", "value": "C16187"},
                    {"label": "Ihtiman", "value": "C16188"},
                    {"label": "Koprivshtitsa", "value": "C16189"},
                    {"label": "Kostinbrod", "value": "C16190"},
                    {"label": "Lakatnik", "value": "C16191"},
                    {"label": "Mirkovo", "value": "C16192"},
                    {"label": "Obshtina Anton", "value": "C16193"},
                    {"label": "Obshtina Botevgrad", "value": "C16194"},
                    {"label": "Obshtina Bozhurishte", "value": "C16195"},
                    {"label": "Obshtina Chavdar", "value": "C16196"},
                    {"label": "Obshtina Chelopech", "value": "C16197"},
                    {"label": "Obshtina Dolna Banya", "value": "C16198"},
                    {"label": "Obshtina Dragoman", "value": "C16199"},
                    {"label": "Obshtina Elin Pelin", "value": "C16200"},
                    {"label": "Obshtina Etropole", "value": "C16201"},
                    {"label": "Obshtina Gorna Malina", "value": "C16202"},
                    {"label": "Obshtina Koprivshtitsa", "value": "C16203"},
                    {"label": "Obshtina Kostenets", "value": "C16204"},
                    {"label": "Obshtina Kostinbrod", "value": "C16205"},
                    {"label": "Obshtina Mirkovo", "value": "C16206"},
                    {"label": "Obshtina Pirdop", "value": "C16207"},
                    {"label": "Obshtina Pravets", "value": "C16208"},
                    {"label": "Obshtina Samokov", "value": "C16209"},
                    {"label": "Obshtina Slivnitsa", "value": "C16210"},
                    {"label": "Obshtina Svoge", "value": "C16211"},
                    {"label": "Obshtina Zlatitsa", "value": "C16212"},
                    {"label": "Pirdop", "value": "C16213"},
                    {"label": "Pravets", "value": "C16214"},
                    {"label": "Samokov", "value": "C16215"},
                    {"label": "Slivnitsa", "value": "C16216"},
                    {"label": "Svoge", "value": "C16217"},
                    {"label": "Zlatitsa", "value": "C16218"}],
 "Sogn og Fjordane": [{"label": "Ardal", "value": "C79070"},
                      {"label": "Ardalstangen", "value": "C79071"},
                      {"label": "Askvoll", "value": "C79072"},
                      {"label": "Aurland", "value": "C79073"},
                      {"label": "Balestrand", "value": "C79074"},
                      {"label": "Bremanger", "value": "C79075"},
                      {"label": "Dale", "value": "C79076"},
                      {"label": "Eid", "value": "C79077"},
                      {"label": "Eivindvik", "value": "C79078"},
                      {"label": "Farnes", "value": "C79079"},
                      {"label": "Fjaler", "value": "C79080"},
                      {"label": "Flora", "value": "C79081"},
                      {"label": "Floro", "value": "C79082"},
                      {"label": "Forde", "value": "C79083"},
                      {"label": "Gaular", "value": "C79084"},
                      {"label": "Gaupne", "value": "C79085"},
                      {"label": "Gloppen", "value": "C79086"},
                      {"label": "Gulen", "value": "C79087"},
                      {"label": "Hardbakke", "value": "C79088"},
                      {"label": "Hermansverk", "value": "C79089"},
                      {"label": "Hornindal", "value": "C79090"},
                      {"label": "Hoyanger", "value": "C79091"},
                      {"label": "Hyllestad", "value": "C79092"},
                      {"label": "Jolster", "value": "C79093"},
                      {"label": "Laerdal", "value": "C79094"},
                      {"label": "Laerdalsoyri", "value": "C79095"},
                      {"label": "Leikanger", "value": "C79096"},
                      {"label": "Luster", "value": "C79097"},
                      {"label": "Maloy", "value": "C79098"},
                      {"label": "Naustdal", "value": "C79099"},
                      {"label": "Nordfjordeid", "value": "C79100"},
                      {"label": "Sandane", "value": "C79101"},
                      {"label": "Sande", "value": "C79102"},
                      {"label": "Selje", "value": "C79103"},
                      {"label": "Sogndal", "value": "C79104"},
                      {"label": "Solund", "value": "C79105"},
                      {"label": "Stryn", "value": "C79106"},
                      {"label": "Svelgen", "value": "C79107"},
                      {"label": "Vagsoy", "value": "C79108"},
                      {"label": "Vik", "value": "C79109"}],
 "Sohag": [{"label": "Akhmim", "value": "C24348"},
           {"label": "Al Balyana", "value": "C24349"},
           {"label": "Al Manshah", "value": "C24350"},
           {"label": "Jirja", "value": "C24351"},
           {"label": "Juhaynah", "value": "C24352"},
           {"label": "Markaz Jirja", "value": "C24353"},
           {"label": "Markaz Suhaj", "value": "C24354"},
           {"label": "Sohag", "value": "C24355"},
           {"label": "Tahta", "value": "C24356"}],
 "Sokoto": [{"label": "Binji", "value": "C78490"},
            {"label": "Dange", "value": "C78491"},
            {"label": "Gandi", "value": "C78492"},
            {"label": "Goronyo", "value": "C78493"},
            {"label": "Gwadabawa", "value": "C78494"},
            {"label": "Illela", "value": "C78495"},
            {"label": "Rabah", "value": "C78496"},
            {"label": "Sokoto", "value": "C78497"},
            {"label": "Tambuwal", "value": "C78498"},
            {"label": "Wurno", "value": "C78499"}],
 "Solcava Municipality": [{"label": "Solcava", "value": "C107823"}],
 "Soldanesti District": [{"label": "Soldanesti", "value": "C75535"}],
 "Solola Department": [{"label": "Concepcion", "value": "C42941"},
                       {"label": "Municipio de Nahuala", "value": "C42942"},
                       {"label": "Municipio de Panajachel", "value": "C42943"},
                       {"label": "Municipio de Santa Catarina Palopo",
                        "value": "C42944"},
                       {"label": "Municipio de Santa Cruz La Laguna",
                        "value": "C42945"},
                       {"label": "Nahuala", "value": "C42946"},
                       {"label": "Panajachel", "value": "C42947"},
                       {"label": "San Andres Semetabaj", "value": "C42948"},
                       {"label": "San Antonio Palopo", "value": "C42949"},
                       {"label": "San Jose Chacaya", "value": "C42950"},
                       {"label": "San Juan La Laguna", "value": "C42951"},
                       {"label": "San Lucas Toliman", "value": "C42952"},
                       {"label": "San Marcos La Laguna", "value": "C42953"},
                       {"label": "San Pablo La Laguna", "value": "C42954"},
                       {"label": "San Pedro La Laguna", "value": "C42955"},
                       {"label": "Santa Catarina Ixtahuacan",
                        "value": "C42956"},
                       {"label": "Santa Catarina Palopo", "value": "C42957"},
                       {"label": "Santa Clara La Laguna", "value": "C42958"},
                       {"label": "Santa Cruz La Laguna", "value": "C42959"},
                       {"label": "Santa Lucia Utatlan", "value": "C42960"},
                       {"label": "Santa Maria Visitacion", "value": "C42961"},
                       {"label": "Santiago Atitlan", "value": "C42962"},
                       {"label": "Solola", "value": "C42963"}],
 "Solothurn": [{"label": "Balsthal", "value": "C116997"},
               {"label": "Bettlach", "value": "C116998"},
               {"label": "Bezirk Bucheggberg", "value": "C116999"},
               {"label": "Bezirk Dorneck", "value": "C117000"},
               {"label": "Bezirk Gau", "value": "C117001"},
               {"label": "Bezirk Gosgen", "value": "C117002"},
               {"label": "Bezirk Lebern", "value": "C117003"},
               {"label": "Bezirk Olten", "value": "C117004"},
               {"label": "Bezirk Solothurn", "value": "C117005"},
               {"label": "Bezirk Thal", "value": "C117006"},
               {"label": "Bezirk Thierstein", "value": "C117007"},
               {"label": "Bezirk Wasseramt", "value": "C117008"},
               {"label": "Biberist", "value": "C117009"},
               {"label": "Breitenbach", "value": "C117010"},
               {"label": "Busserach", "value": "C117011"},
               {"label": "Deitingen", "value": "C117012"},
               {"label": "Derendingen", "value": "C117013"},
               {"label": "Dornach", "value": "C117014"},
               {"label": "Egerkingen", "value": "C117015"},
               {"label": "Erlinsbach", "value": "C117016"},
               {"label": "Fulenbach", "value": "C117017"},
               {"label": "Gerlafingen", "value": "C117018"},
               {"label": "Grenchen", "value": "C117019"},
               {"label": "Gunzgen", "value": "C117020"},
               {"label": "Hagendorf", "value": "C117021"},
               {"label": "Himmelried", "value": "C117022"},
               {"label": "Hochwald", "value": "C117023"},
               {"label": "Kleinlutzel", "value": "C117024"},
               {"label": "Kriegstetten", "value": "C117025"},
               {"label": "Langendorf", "value": "C117026"},
               {"label": "Laupersdorf", "value": "C117027"},
               {"label": "Lostorf", "value": "C117028"},
               {"label": "Luterbach", "value": "C117029"},
               {"label": "Matzendorf", "value": "C117030"},
               {"label": "Messen", "value": "C117031"},
               {"label": "Niedergosgen", "value": "C117032"},
               {"label": "Nunningen", "value": "C117033"},
               {"label": "Oberbuchsiten", "value": "C117034"},
               {"label": "Obergosgen", "value": "C117035"},
               {"label": "Oensingen", "value": "C117036"},
               {"label": "Olten", "value": "C117037"},
               {"label": "Riedholz", "value": "C117038"},
               {"label": "Rodersdorf", "value": "C117039"},
               {"label": "Rohr", "value": "C117040"},
               {"label": "Schonenwerd", "value": "C117041"},
               {"label": "Selzach", "value": "C117042"},
               {"label": "Solothurn", "value": "C117043"},
               {"label": "Subingen", "value": "C117044"},
               {"label": "Trimbach", "value": "C117045"},
               {"label": "Wangen", "value": "C117046"},
               {"label": "Welschenrohr", "value": "C117047"},
               {"label": "Wolfwil", "value": "C117048"},
               {"label": "Zuchwil", "value": "C117049"}],
 "Somali Region": [{"label": "Afder Zone", "value": "C24758"},
                   {"label": "Degehabur Zone", "value": "C24759"},
                   {"label": "Gode Zone", "value": "C24760"},
                   {"label": "Jijiga", "value": "C24761"},
                   {"label": "Liben zone", "value": "C24762"},
                   {"label": "Shinile Zone", "value": "C24763"}],
 "Somogy County": [{"label": "adand", "value": "C44572"},
                   {"label": "Babocsa", "value": "C44573"},
                   {"label": "Balatonbereny", "value": "C44574"},
                   {"label": "Balatonboglar", "value": "C44575"},
                   {"label": "Balatonfenyves", "value": "C44576"},
                   {"label": "Balatonfoldvar", "value": "C44577"},
                   {"label": "Balatonlelle", "value": "C44578"},
                   {"label": "Balatonszabadi", "value": "C44579"},
                   {"label": "Balatonszarszo", "value": "C44580"},
                   {"label": "Barcs", "value": "C44581"},
                   {"label": "Barcsi Jaras", "value": "C44582"},
                   {"label": "Berzence", "value": "C44583"},
                   {"label": "Bohonye", "value": "C44584"},
                   {"label": "Csurgo", "value": "C44585"},
                   {"label": "Csurgoi Jaras", "value": "C44586"},
                   {"label": "Fonyod", "value": "C44587"},
                   {"label": "Fonyodi Jaras", "value": "C44588"},
                   {"label": "Kadarkut", "value": "C44589"},
                   {"label": "Kaposmero", "value": "C44590"},
                   {"label": "Kaposvar", "value": "C44591"},
                   {"label": "Kaposvari Jaras", "value": "C44592"},
                   {"label": "Karad", "value": "C44593"},
                   {"label": "Kethely", "value": "C44594"},
                   {"label": "Labod", "value": "C44595"},
                   {"label": "Lengyeltoti", "value": "C44596"},
                   {"label": "Marcali", "value": "C44597"},
                   {"label": "Marcali Jaras", "value": "C44598"},
                   {"label": "Nagyatad", "value": "C44599"},
                   {"label": "Nagyatadi Jaras", "value": "C44600"},
                   {"label": "Nagybajom", "value": "C44601"},
                   {"label": "Segesd", "value": "C44602"},
                   {"label": "Siofok", "value": "C44603"},
                   {"label": "Siofoki Jaras", "value": "C44604"},
                   {"label": "Somogyvar", "value": "C44605"},
                   {"label": "Tab", "value": "C44606"},
                   {"label": "Tabi Jaras", "value": "C44607"},
                   {"label": "Taszar", "value": "C44608"},
                   {"label": "Zamardi", "value": "C44609"}],
 "Son La": [{"label": "Huyen Bac Yen", "value": "C147627"},
            {"label": "Huyen Mai Son", "value": "C147628"},
            {"label": "Huyen Moc Chau", "value": "C147629"},
            {"label": "Huyen Muong La", "value": "C147630"},
            {"label": "Huyen Phu Yen", "value": "C147631"},
            {"label": "Huyen Quynh Nhai", "value": "C147632"},
            {"label": "Huyen Song Ma", "value": "C147633"},
            {"label": "Huyen Thuan Chau", "value": "C147634"},
            {"label": "Huyen Yen Chau", "value": "C147635"},
            {"label": "Son La", "value": "C147636"}],
 "Songkhla": [{"label": "Amphoe Bang Klam", "value": "C119370"},
              {"label": "Amphoe Chana", "value": "C119371"},
              {"label": "Amphoe Hat Yai", "value": "C119372"},
              {"label": "Amphoe Khlong Hoi Khong", "value": "C119373"},
              {"label": "Amphoe Khuan Niang", "value": "C119374"},
              {"label": "Amphoe Krasae Sin", "value": "C119375"},
              {"label": "Amphoe Mueang Songkhla", "value": "C119376"},
              {"label": "Amphoe Na Mom", "value": "C119377"},
              {"label": "Amphoe Na Thawi", "value": "C119378"},
              {"label": "Amphoe Ranot", "value": "C119379"},
              {"label": "Amphoe Rattaphum", "value": "C119380"},
              {"label": "Amphoe Saba Yoi", "value": "C119381"},
              {"label": "Amphoe Sadao", "value": "C119382"},
              {"label": "Amphoe Sathing Phra", "value": "C119383"},
              {"label": "Amphoe Singhanakhon", "value": "C119384"},
              {"label": "Amphoe Thepha", "value": "C119385"},
              {"label": "Ban Mai", "value": "C119386"},
              {"label": "Hat Yai", "value": "C119387"},
              {"label": "Na Mom", "value": "C119388"},
              {"label": "Ranot", "value": "C119389"},
              {"label": "Sadao", "value": "C119390"},
              {"label": "Songkhla", "value": "C119391"}],
 "Sonora": [{"label": "31 de Octubre", "value": "C73626"},
            {"label": "Aconchi", "value": "C73627"},
            {"label": "Aduana del Sasabe", "value": "C73628"},
            {"label": "Agiabampo Uno", "value": "C73629"},
            {"label": "Agua Prieta", "value": "C73630"},
            {"label": "alamos", "value": "C73631"},
            {"label": "Allende", "value": "C73632"},
            {"label": "Antonio Rosales", "value": "C73633"},
            {"label": "Arivechi", "value": "C73634"},
            {"label": "Arizpe", "value": "C73635"},
            {"label": "Atil", "value": "C73636"},
            {"label": "Bacabachi", "value": "C73637"},
            {"label": "Bacadehuachi", "value": "C73638"},
            {"label": "Bacame Nuevo", "value": "C73639"},
            {"label": "Bacanora", "value": "C73640"},
            {"label": "Bacerac", "value": "C73641"},
            {"label": "Bacobampo", "value": "C73642"},
            {"label": "Bacum", "value": "C73643"},
            {"label": "Bahia de Kino", "value": "C73644"},
            {"label": "Bahia de Lobos", "value": "C73645"},
            {"label": "Basconcobe", "value": "C73646"},
            {"label": "Bavispe", "value": "C73647"},
            {"label": "Benjamin Hill", "value": "C73648"},
            {"label": "Buaysiacobe", "value": "C73649"},
            {"label": "Buayums", "value": "C73650"},
            {"label": "Campo Carretero", "value": "C73651"},
            {"label": "Campo Sesenta", "value": "C73652"},
            {"label": "Cananea", "value": "C73653"},
            {"label": "Carbo", "value": "C73654"},
            {"label": "Centro de Readaptacion Social Nuevo", "value": "C73655"},
            {"label": "Chucarit", "value": "C73656"},
            {"label": "Ciudad Obregon", "value": "C73657"},
            {"label": "Cocorit", "value": "C73658"},
            {"label": "Cuauhtemoc (Campo Cinco)", "value": "C73659"},
            {"label": "Cucurpe", "value": "C73660"},
            {"label": "Cumpas", "value": "C73661"},
            {"label": "Ejido Lagunitas", "value": "C73662"},
            {"label": "El Coyote", "value": "C73663"},
            {"label": "El Diamante (La Retranca)", "value": "C73664"},
            {"label": "El Paredoncito", "value": "C73665"},
            {"label": "El Recodo", "value": "C73666"},
            {"label": "El Rodeo", "value": "C73667"},
            {"label": "El Sahuaral", "value": "C73668"},
            {"label": "El Saneal", "value": "C73669"},
            {"label": "El Siviral (Jigica)", "value": "C73670"},
            {"label": "El Tobarito", "value": "C73671"},
            {"label": "Empalme", "value": "C73672"},
            {"label": "Esperanza", "value": "C73673"},
            {"label": "Esqueda", "value": "C73674"},
            {"label": "Estacion Corral", "value": "C73675"},
            {"label": "Estacion Llano", "value": "C73676"},
            {"label": "Estacion Zamora", "value": "C73677"},
            {"label": "Etchojoa", "value": "C73678"},
            {"label": "Etchoropo", "value": "C73679"},
            {"label": "Francisco Javier Mina", "value": "C73680"},
            {"label": "Fronteras", "value": "C73681"},
            {"label": "Golfo de Santa Clara", "value": "C73682"},
            {"label": "Granados", "value": "C73683"},
            {"label": "Guasimas (De Belem)", "value": "C73684"},
            {"label": "Guaymitas", "value": "C73685"},
            {"label": "Hermosillo", "value": "C73686"},
            {"label": "Heroica Caborca", "value": "C73687"},
            {"label": "Heroica Guaymas", "value": "C73688"},
            {"label": "Huachinera", "value": "C73689"},
            {"label": "Huasabas", "value": "C73690"},
            {"label": "Huatabampo", "value": "C73691"},
            {"label": "Huepac", "value": "C73692"},
            {"label": "Imuris", "value": "C73693"},
            {"label": "Islita", "value": "C73694"},
            {"label": "Jecopaco", "value": "C73695"},
            {"label": "Jitonhueca", "value": "C73696"},
            {"label": "Jose Maria Morelos y Pavon", "value": "C73697"},
            {"label": "Jurare", "value": "C73698"},
            {"label": "La Bocana", "value": "C73699"},
            {"label": "La Colorada", "value": "C73700"},
            {"label": "La Libertad", "value": "C73701"},
            {"label": "La Mesa", "value": "C73702"},
            {"label": "La Palma", "value": "C73703"},
            {"label": "La Providencia", "value": "C73704"},
            {"label": "La Union", "value": "C73705"},
            {"label": "La Victoria", "value": "C73706"},
            {"label": "Las Bocas", "value": "C73707"},
            {"label": "Las Mercedes [Agropecuaria]", "value": "C73708"},
            {"label": "Loma de Bacum", "value": "C73709"},
            {"label": "Loma de Guamuchil", "value": "C73710"},
            {"label": "Loma del Refugio", "value": "C73711"},
            {"label": "Los Bahuises", "value": "C73712"},
            {"label": "Los Hoyos", "value": "C73713"},
            {"label": "Magdalena de Kino", "value": "C73714"},
            {"label": "Marte R. Gomez (Tobarito)", "value": "C73715"},
            {"label": "Masiaca", "value": "C73716"},
            {"label": "Mazatan", "value": "C73717"},
            {"label": "Mi Patria es Primero", "value": "C73718"},
            {"label": "Miguel Aleman (La Doce)", "value": "C73719"},
            {"label": "Milpillas", "value": "C73720"},
            {"label": "Moctezuma", "value": "C73721"},
            {"label": "Molino de Camou", "value": "C73722"},
            {"label": "Mora Villalobos", "value": "C73723"},
            {"label": "Moroncarit", "value": "C73724"},
            {"label": "Naco", "value": "C73725"},
            {"label": "Nacori Chico", "value": "C73726"},
            {"label": "Nacozari de Garcia", "value": "C73727"},
            {"label": "Nacozari Viejo", "value": "C73728"},
            {"label": "Navojoa", "value": "C73729"},
            {"label": "Nogales", "value": "C73730"},
            {"label": "Nuevo Michoacan (Estacion Riito)", "value": "C73731"},
            {"label": "Onavas", "value": "C73732"},
            {"label": "Opodepe", "value": "C73733"},
            {"label": "Oquitoa", "value": "C73734"},
            {"label": "Ortiz", "value": "C73735"},
            {"label": "Paredon Colorado", "value": "C73736"},
            {"label": "Pesqueira", "value": "C73737"},
            {"label": "Pitiquito", "value": "C73738"},
            {"label": "Plutarco Elias Calles (La Y Griega)", "value": "C73739"},
            {"label": "Potam", "value": "C73740"},
            {"label": "Pozo Dulce", "value": "C73741"},
            {"label": "Primero de Mayo", "value": "C73742"},
            {"label": "Progreso (Campo 47)", "value": "C73743"},
            {"label": "Providencia", "value": "C73744"},
            {"label": "Pueblo Mayo", "value": "C73745"},
            {"label": "Pueblo Yaqui", "value": "C73746"},
            {"label": "Puerto Penasco", "value": "C73747"},
            {"label": "Querobabi", "value": "C73748"},
            {"label": "Quetchehueca", "value": "C73749"},
            {"label": "Quiriego", "value": "C73750"},
            {"label": "Rayon", "value": "C73751"},
            {"label": "Rosales", "value": "C73752"},
            {"label": "Rosario", "value": "C73753"},
            {"label": "Sahuaral de Otero", "value": "C73754"},
            {"label": "Sahuaripa", "value": "C73755"},
            {"label": "San Bernardo", "value": "C73756"},
            {"label": "San Carlos", "value": "C73757"},
            {"label": "San Ignacio", "value": "C73758"},
            {"label": "San Ignacio Rio Muerto", "value": "C73759"},
            {"label": "San Jose de Bacum", "value": "C73760"},
            {"label": "San Jose de Guaymas", "value": "C73761"},
            {"label": "San Luis Rio Colorado", "value": "C73762"},
            {"label": "San Miguel de Horcasitas", "value": "C73763"},
            {"label": "San Pedro de la Cueva", "value": "C73764"},
            {"label": "San Pedro Rio Mayo", "value": "C73765"},
            {"label": "Santa Ana", "value": "C73766"},
            {"label": "Santa Cruz", "value": "C73767"},
            {"label": "Santa Maria de Guaymas", "value": "C73768"},
            {"label": "Santa Maria del Buaraje", "value": "C73769"},
            {"label": "Saric", "value": "C73770"},
            {"label": "Sinahuiza", "value": "C73771"},
            {"label": "Siviral", "value": "C73772"},
            {"label": "Sonoita", "value": "C73773"},
            {"label": "Soyopa", "value": "C73774"},
            {"label": "Suaqui Grande", "value": "C73775"},
            {"label": "Tepache", "value": "C73776"},
            {"label": "Tierra Blanca", "value": "C73777"},
            {"label": "Tubutama", "value": "C73778"},
            {"label": "Ures", "value": "C73779"},
            {"label": "Vicam", "value": "C73780"},
            {"label": "Vicente Guerrero", "value": "C73781"},
            {"label": "Villa Hidalgo", "value": "C73782"},
            {"label": "Villa Juarez", "value": "C73783"},
            {"label": "Villa Pesqueira", "value": "C73784"},
            {"label": "Yavaros", "value": "C73785"}],
 "Sonsonate Department": [{"label": "Acajutla", "value": "C24445"},
                          {"label": "Armenia", "value": "C24446"},
                          {"label": "Izalco", "value": "C24447"},
                          {"label": "Juayua", "value": "C24448"},
                          {"label": "Nahuizalco", "value": "C24449"},
                          {"label": "San Antonio del Monte", "value": "C24450"},
                          {"label": "Sonsonate", "value": "C24451"},
                          {"label": "Sonzacate", "value": "C24452"}],
 "Sonsorol": [{"label": "Sonsorol Village", "value": "C79785"}],
 "Sopiste Municipality": [{"label": "Sopishte", "value": "C65497"}],
 "Soriano Department": [{"label": "Cardona", "value": "C146967"},
                        {"label": "Dolores", "value": "C146968"},
                        {"label": "Jose Enrique Rodo", "value": "C146969"},
                        {"label": "Mercedes", "value": "C146970"},
                        {"label": "Palmitas", "value": "C146971"},
                        {"label": "Santa Catalina", "value": "C146972"},
                        {"label": "Villa Soriano", "value": "C146973"}],
 "Soroca District": [{"label": "Soroca", "value": "C75536"}],
 "Sostanj Municipality": [{"label": "Ravne", "value": "C107824"},
                          {"label": "Sostanj", "value": "C107825"},
                          {"label": "Topolsica", "value": "C107826"}],
 "Soufriere Quarter": [{"label": "Anse Chastanet", "value": "C106123"},
                       {"label": "Barons DriveCoin De LAnse",
                        "value": "C106124"},
                       {"label": "BeasejourMyers Bridge", "value": "C106125"},
                       {"label": "Belle Plaine", "value": "C106126"},
                       {"label": "Bellefond", "value": "C106127"},
                       {"label": "Belvedere", "value": "C106128"},
                       {"label": "Bois DInde", "value": "C106129"},
                       {"label": "Bouton", "value": "C106130"},
                       {"label": "CalvaryCalvaire", "value": "C106131"},
                       {"label": "Cenac", "value": "C106132"},
                       {"label": "Chateau Belair", "value": "C106133"},
                       {"label": "Colombette", "value": "C106134"},
                       {"label": "Compar", "value": "C106135"},
                       {"label": "Cresslands", "value": "C106136"},
                       {"label": "Deville", "value": "C106137"},
                       {"label": "DiamondDiamond Estate", "value": "C106138"},
                       {"label": "Esperance", "value": "C106139"},
                       {"label": "Etangs", "value": "C106140"},
                       {"label": "Fond Bernier", "value": "C106141"},
                       {"label": "Fond Cacoa", "value": "C106142"},
                       {"label": "Fond Doux", "value": "C106143"},
                       {"label": "Fond Gens Libre", "value": "C106144"},
                       {"label": "Jalousie", "value": "C106145"},
                       {"label": "La Haut", "value": "C106146"},
                       {"label": "La Pearle", "value": "C106147"},
                       {"label": "Lenny Hill", "value": "C106148"},
                       {"label": "Malgretoute", "value": "C106149"},
                       {"label": "Migny", "value": "C106150"},
                       {"label": "Mocha", "value": "C106151"},
                       {"label": "Morne Bonin", "value": "C106152"},
                       {"label": "Morne La Croix", "value": "C106153"},
                       {"label": "Morne LasticDesruisseaux",
                        "value": "C106154"},
                       {"label": "New Development", "value": "C106155"},
                       {"label": "Palmiste", "value": "C106156"},
                       {"label": "Plat Pays", "value": "C106157"},
                       {"label": "Rabot", "value": "C106158"},
                       {"label": "Ravine Claire", "value": "C106159"},
                       {"label": "Ruby Estate", "value": "C106160"},
                       {"label": "Soufriere", "value": "C106161"},
                       {"label": "Soufriere Estate", "value": "C106162"},
                       {"label": "St Phillip", "value": "C106163"},
                       {"label": "Stonefield", "value": "C106164"},
                       {"label": "Sulphur Springs", "value": "C106165"},
                       {"label": "Ti Boug", "value": "C106166"},
                       {"label": "Ti Delcer", "value": "C106167"},
                       {"label": "Toraille", "value": "C106168"},
                       {"label": "Town", "value": "C106169"},
                       {"label": "Union Vale", "value": "C106170"},
                       {"label": "Zenon", "value": "C106171"}],
 "Souk Ahras": [{"label": "Sedrata", "value": "C456"},
                {"label": "Souk Ahras", "value": "C457"}],
 "Sousse Governorate": [{"label": "Akouda", "value": "C119791"},
                        {"label": "Hammam Sousse", "value": "C119792"},
                        {"label": "Harqalah", "value": "C119793"},
                        {"label": "Msaken", "value": "C119794"},
                        {"label": "Port el Kantaoui", "value": "C119795"},
                        {"label": "Sidi Bou Ali", "value": "C119796"},
                        {"label": "Sidi el Hani", "value": "C119797"},
                        {"label": "Sousse", "value": "C119798"}],
 "South": [{"label": "Akom II", "value": "C16665"},
           {"label": "Ambam", "value": "C16666"},
           {"label": "ebolowa", "value": "C16667"},
           {"label": "Kribi", "value": "C16668"},
           {"label": "Lolodorf", "value": "C16669"},
           {"label": "Mvangue", "value": "C16670"},
           {"label": "Mvila", "value": "C16671"},
           {"label": "Sangmelima", "value": "C16672"}],
 "South Aegean": [{"label": "Adamas", "value": "C42425"},
                  {"label": "Afantou", "value": "C42426"},
                  {"label": "Agia Marina", "value": "C42427"},
                  {"label": "Amorgos", "value": "C42428"},
                  {"label": "Anafi", "value": "C42429"},
                  {"label": "Andros", "value": "C42430"},
                  {"label": "ano Mera", "value": "C42431"},
                  {"label": "ano Syros", "value": "C42432"},
                  {"label": "Antimacheia", "value": "C42433"},
                  {"label": "Antiparos", "value": "C42434"},
                  {"label": "Archangelos", "value": "C42435"},
                  {"label": "Astypalaia", "value": "C42436"},
                  {"label": "Chalki", "value": "C42437"},
                  {"label": "emponas", "value": "C42438"},
                  {"label": "Emporeio", "value": "C42439"},
                  {"label": "Ermoupolis", "value": "C42440"},
                  {"label": "Faliraki", "value": "C42441"},
                  {"label": "Filotion", "value": "C42442"},
                  {"label": "Fira", "value": "C42443"},
                  {"label": "Folegandros", "value": "C42444"},
                  {"label": "Fry", "value": "C42445"},
                  {"label": "Ialysos", "value": "C42446"},
                  {"label": "ios", "value": "C42447"},
                  {"label": "Kalymnos", "value": "C42448"},
                  {"label": "Kardamaina", "value": "C42449"},
                  {"label": "Karpathos", "value": "C42450"},
                  {"label": "Kefalos", "value": "C42451"},
                  {"label": "Kimolos", "value": "C42452"},
                  {"label": "Kos", "value": "C42453"},
                  {"label": "Kremasti", "value": "C42454"},
                  {"label": "Kythnos", "value": "C42455"},
                  {"label": "Lakki", "value": "C42456"},
                  {"label": "Lardos", "value": "C42457"},
                  {"label": "Mandraki", "value": "C42458"},
                  {"label": "Megalo Chorio", "value": "C42459"},
                  {"label": "Megisti", "value": "C42460"},
                  {"label": "Mesaria", "value": "C42461"},
                  {"label": "Milos", "value": "C42462"},
                  {"label": "Mykonos", "value": "C42463"},
                  {"label": "Naousa", "value": "C42464"},
                  {"label": "Naxos", "value": "C42465"},
                  {"label": "Nomos Kykladon", "value": "C42466"},
                  {"label": "Oia", "value": "C42467"},
                  {"label": "Ornos", "value": "C42468"},
                  {"label": "Panormos", "value": "C42469"},
                  {"label": "Paros", "value": "C42470"},
                  {"label": "Patmos", "value": "C42471"},
                  {"label": "Pyli", "value": "C42472"},
                  {"label": "Rodos", "value": "C42473"},
                  {"label": "Serifos", "value": "C42474"},
                  {"label": "Skala", "value": "C42475"},
                  {"label": "Symi", "value": "C42476"},
                  {"label": "Tinos", "value": "C42477"},
                  {"label": "Vari", "value": "C42478"},
                  {"label": "Zipari", "value": "C42479"}],
 "South Australia": [{"label": "Aberfoyle Park", "value": "C4152"},
                     {"label": "Adelaide", "value": "C4153"},
                     {"label": "Adelaide city centre", "value": "C4154"},
                     {"label": "Adelaide Hills", "value": "C4155"},
                     {"label": "Albert Park", "value": "C4156"},
                     {"label": "Alberton", "value": "C4157"},
                     {"label": "Aldgate", "value": "C4158"},
                     {"label": "Aldinga Beach", "value": "C4159"},
                     {"label": "Alexandrina", "value": "C4160"},
                     {"label": "Allenby Gardens", "value": "C4161"},
                     {"label": "Anangu Pitjantjatjara", "value": "C4162"},
                     {"label": "Andrews Farm", "value": "C4163"},
                     {"label": "Angaston", "value": "C4164"},
                     {"label": "Angle Park", "value": "C4165"},
                     {"label": "Angle Vale", "value": "C4166"},
                     {"label": "Ardrossan", "value": "C4167"},
                     {"label": "Ascot Park", "value": "C4168"},
                     {"label": "Ashford", "value": "C4169"},
                     {"label": "Athelstone", "value": "C4170"},
                     {"label": "Athol Park", "value": "C4171"},
                     {"label": "Balaklava", "value": "C4172"},
                     {"label": "Balhannah", "value": "C4173"},
                     {"label": "Banksia Park", "value": "C4174"},
                     {"label": "Barmera", "value": "C4175"},
                     {"label": "Barossa", "value": "C4176"},
                     {"label": "Barunga West", "value": "C4177"},
                     {"label": "Beaumont", "value": "C4178"},
                     {"label": "Bedford Park", "value": "C4179"},
                     {"label": "Belair", "value": "C4180"},
                     {"label": "Bellevue Heights", "value": "C4181"},
                     {"label": "Berri", "value": "C4182"},
                     {"label": "Berri and Barmera", "value": "C4183"},
                     {"label": "Beulah Park", "value": "C4184"},
                     {"label": "Beverley", "value": "C4185"},
                     {"label": "Birdwood", "value": "C4186"},
                     {"label": "Birkenhead", "value": "C4187"},
                     {"label": "Black Forest", "value": "C4188"},
                     {"label": "Blackwood", "value": "C4189"},
                     {"label": "Blair Athol", "value": "C4190"},
                     {"label": "Blakeview", "value": "C4191"},
                     {"label": "Bordertown", "value": "C4192"},
                     {"label": "Brahma Lodge", "value": "C4193"},
                     {"label": "Bridgewater", "value": "C4194"},
                     {"label": "Brighton", "value": "C4195"},
                     {"label": "Broadview", "value": "C4196"},
                     {"label": "Brompton", "value": "C4197"},
                     {"label": "Brooklyn Park", "value": "C4198"},
                     {"label": "Burnside", "value": "C4199"},
                     {"label": "Burra", "value": "C4200"},
                     {"label": "Burton", "value": "C4201"},
                     {"label": "Camden Park", "value": "C4202"},
                     {"label": "Campbelltown", "value": "C4203"},
                     {"label": "Ceduna", "value": "C4204"},
                     {"label": "Charles Sturt", "value": "C4205"},
                     {"label": "Cheltenham", "value": "C4206"},
                     {"label": "Christie Downs", "value": "C4207"},
                     {"label": "Christies Beach", "value": "C4208"},
                     {"label": "City of West Torrens", "value": "C4209"},
                     {"label": "Clapham", "value": "C4210"},
                     {"label": "Clare", "value": "C4211"},
                     {"label": "Clare and Gilbert Valleys", "value": "C4212"},
                     {"label": "Clarence Gardens", "value": "C4213"},
                     {"label": "Clarence Park", "value": "C4214"},
                     {"label": "Clearview", "value": "C4215"},
                     {"label": "Cleve", "value": "C4216"},
                     {"label": "Clovelly Park", "value": "C4217"},
                     {"label": "Collinswood", "value": "C4218"},
                     {"label": "Colonel Light Gardens", "value": "C4219"},
                     {"label": "Coober Pedy", "value": "C4220"},
                     {"label": "Copper Coast", "value": "C4221"},
                     {"label": "Coromandel Valley", "value": "C4222"},
                     {"label": "Cowandilla", "value": "C4223"},
                     {"label": "Cowell", "value": "C4224"},
                     {"label": "Crafers", "value": "C4225"},
                     {"label": "Crafers West", "value": "C4226"},
                     {"label": "Craigburn Farm", "value": "C4227"},
                     {"label": "Craigmore", "value": "C4228"},
                     {"label": "Croydon Park", "value": "C4229"},
                     {"label": "Crystal Brook", "value": "C4230"},
                     {"label": "Cumberland Park", "value": "C4231"},
                     {"label": "Darlington", "value": "C4232"},
                     {"label": "Davoren Park", "value": "C4233"},
                     {"label": "Daw Park", "value": "C4234"},
                     {"label": "Dernancourt", "value": "C4235"},
                     {"label": "Dover Gardens", "value": "C4236"},
                     {"label": "Dulwich", "value": "C4237"},
                     {"label": "Echunga", "value": "C4238"},
                     {"label": "Eden Hills", "value": "C4239"},
                     {"label": "Edwardstown", "value": "C4240"},
                     {"label": "Elizabeth Downs", "value": "C4241"},
                     {"label": "Elizabeth East", "value": "C4242"},
                     {"label": "Elizabeth Grove", "value": "C4243"},
                     {"label": "Elizabeth North", "value": "C4244"},
                     {"label": "Elizabeth Park", "value": "C4245"},
                     {"label": "Elizabeth South", "value": "C4246"},
                     {"label": "Elizabeth Vale", "value": "C4247"},
                     {"label": "Elliston", "value": "C4248"},
                     {"label": "Encounter Bay", "value": "C4249"},
                     {"label": "Enfield", "value": "C4250"},
                     {"label": "Erindale", "value": "C4251"},
                     {"label": "Ethelton", "value": "C4252"},
                     {"label": "Evandale", "value": "C4253"},
                     {"label": "Evanston", "value": "C4254"},
                     {"label": "Evanston Gardens", "value": "C4255"},
                     {"label": "Evanston Park", "value": "C4256"},
                     {"label": "Everard Park", "value": "C4257"},
                     {"label": "Exeter", "value": "C4258"},
                     {"label": "Fairview Park", "value": "C4259"},
                     {"label": "Felixstow", "value": "C4260"},
                     {"label": "Ferryden Park", "value": "C4261"},
                     {"label": "Findon", "value": "C4262"},
                     {"label": "Firle", "value": "C4263"},
                     {"label": "Flagstaff Hill", "value": "C4264"},
                     {"label": "Flinders Park", "value": "C4265"},
                     {"label": "Flinders Ranges", "value": "C4266"},
                     {"label": "Forestville", "value": "C4267"},
                     {"label": "Franklin Harbour", "value": "C4268"},
                     {"label": "Freeling", "value": "C4269"},
                     {"label": "Fulham", "value": "C4270"},
                     {"label": "Fulham Gardens", "value": "C4271"},
                     {"label": "Fullarton", "value": "C4272"},
                     {"label": "Gawler", "value": "C4273"},
                     {"label": "Gawler East", "value": "C4274"},
                     {"label": "Gawler South", "value": "C4275"},
                     {"label": "Gilberton", "value": "C4276"},
                     {"label": "Gilles Plains", "value": "C4277"},
                     {"label": "Glandore", "value": "C4278"},
                     {"label": "Glen Osmond", "value": "C4279"},
                     {"label": "Glenalta", "value": "C4280"},
                     {"label": "Glenelg", "value": "C4281"},
                     {"label": "Glenelg East", "value": "C4282"},
                     {"label": "Glenelg North", "value": "C4283"},
                     {"label": "Glenelg South", "value": "C4284"},
                     {"label": "Glengowrie", "value": "C4285"},
                     {"label": "Glenside", "value": "C4286"},
                     {"label": "Glenunga", "value": "C4287"},
                     {"label": "Glynde", "value": "C4288"},
                     {"label": "Golden Grove", "value": "C4289"},
                     {"label": "Goodwood", "value": "C4290"},
                     {"label": "Goolwa", "value": "C4291"},
                     {"label": "Goolwa Beach", "value": "C4292"},
                     {"label": "Goyder", "value": "C4293"},
                     {"label": "Grange", "value": "C4294"},
                     {"label": "Grant", "value": "C4295"},
                     {"label": "Greenacres", "value": "C4296"},
                     {"label": "Greenock", "value": "C4297"},
                     {"label": "Greenwith", "value": "C4298"},
                     {"label": "Gulfview Heights", "value": "C4299"},
                     {"label": "Hackham", "value": "C4300"},
                     {"label": "Hackham West", "value": "C4301"},
                     {"label": "Hahndorf", "value": "C4302"},
                     {"label": "Hallett Cove", "value": "C4303"},
                     {"label": "Hampstead Gardens", "value": "C4304"},
                     {"label": "Happy Valley", "value": "C4305"},
                     {"label": "Hawthorn", "value": "C4306"},
                     {"label": "Hawthorndene", "value": "C4307"},
                     {"label": "Hayborough", "value": "C4308"},
                     {"label": "Hazelwood Park", "value": "C4309"},
                     {"label": "Hectorville", "value": "C4310"},
                     {"label": "Henley Beach", "value": "C4311"},
                     {"label": "Henley Beach South", "value": "C4312"},
                     {"label": "Hewett", "value": "C4313"},
                     {"label": "Highbury", "value": "C4314"},
                     {"label": "Highgate", "value": "C4315"},
                     {"label": "Hillbank", "value": "C4316"},
                     {"label": "Hillcrest", "value": "C4317"},
                     {"label": "Hindmarsh Island", "value": "C4318"},
                     {"label": "Holden Hill", "value": "C4319"},
                     {"label": "Holdfast Bay", "value": "C4320"},
                     {"label": "Hope Valley", "value": "C4321"},
                     {"label": "Hove", "value": "C4322"},
                     {"label": "Huntfield Heights", "value": "C4323"},
                     {"label": "Hyde Park", "value": "C4324"},
                     {"label": "Ingle Farm", "value": "C4325"},
                     {"label": "Jamestown", "value": "C4326"},
                     {"label": "Joslin", "value": "C4327"},
                     {"label": "Kadina", "value": "C4328"},
                     {"label": "Kangaroo Island", "value": "C4329"},
                     {"label": "Kapunda", "value": "C4330"},
                     {"label": "Karoonda East Murray", "value": "C4331"},
                     {"label": "Keith", "value": "C4332"},
                     {"label": "Kensington Gardens", "value": "C4333"},
                     {"label": "Kensington Park", "value": "C4334"},
                     {"label": "Kent Town", "value": "C4335"},
                     {"label": "Kersbrook", "value": "C4336"},
                     {"label": "Kidman Park", "value": "C4337"},
                     {"label": "Kilburn", "value": "C4338"},
                     {"label": "Kilkenny", "value": "C4339"},
                     {"label": "Kimba", "value": "C4340"},
                     {"label": "Kingscote", "value": "C4341"},
                     {"label": "Kingston", "value": "C4342"},
                     {"label": "Kingston South East", "value": "C4343"},
                     {"label": "Klemzig", "value": "C4344"},
                     {"label": "Kurralta Park", "value": "C4345"},
                     {"label": "Largs Bay", "value": "C4346"},
                     {"label": "Largs North", "value": "C4347"},
                     {"label": "Leabrook", "value": "C4348"},
                     {"label": "Lewiston", "value": "C4349"},
                     {"label": "Light", "value": "C4350"},
                     {"label": "Linden Park", "value": "C4351"},
                     {"label": "Little Hampton", "value": "C4352"},
                     {"label": "Lobethal", "value": "C4353"},
                     {"label": "Lockleys", "value": "C4354"},
                     {"label": "Lower Eyre Peninsula", "value": "C4355"},
                     {"label": "Lower Mitcham", "value": "C4356"},
                     {"label": "Loxton", "value": "C4357"},
                     {"label": "Loxton Waikerie", "value": "C4358"},
                     {"label": "Lyndoch", "value": "C4359"},
                     {"label": "Macclesfield", "value": "C4360"},
                     {"label": "Magill", "value": "C4361"},
                     {"label": "Maitland", "value": "C4362"},
                     {"label": "Mallala", "value": "C4363"},
                     {"label": "Malvern", "value": "C4364"},
                     {"label": "Manningham", "value": "C4365"},
                     {"label": "Mannum", "value": "C4366"},
                     {"label": "Mansfield Park", "value": "C4367"},
                     {"label": "Maralinga Tjarutja", "value": "C4368"},
                     {"label": "Marden", "value": "C4369"},
                     {"label": "Marino", "value": "C4370"},
                     {"label": "Marion", "value": "C4371"},
                     {"label": "Marleston", "value": "C4372"},
                     {"label": "Maslin Beach", "value": "C4373"},
                     {"label": "Mawson Lakes", "value": "C4374"},
                     {"label": "Maylands", "value": "C4375"},
                     {"label": "McCracken", "value": "C4376"},
                     {"label": "McLaren Flat", "value": "C4377"},
                     {"label": "McLaren Vale", "value": "C4378"},
                     {"label": "Meadows", "value": "C4379"},
                     {"label": "Medindie", "value": "C4380"},
                     {"label": "Melrose Park", "value": "C4381"},
                     {"label": "Meningie", "value": "C4382"},
                     {"label": "Mid Murray", "value": "C4383"},
                     {"label": "Middleton", "value": "C4384"},
                     {"label": "Mile End", "value": "C4385"},
                     {"label": "Millicent", "value": "C4386"},
                     {"label": "Millswood", "value": "C4387"},
                     {"label": "Minlaton", "value": "C4388"},
                     {"label": "Mitcham", "value": "C4389"},
                     {"label": "Mitchell Park", "value": "C4390"},
                     {"label": "Moana", "value": "C4391"},
                     {"label": "Modbury", "value": "C4392"},
                     {"label": "Modbury Heights", "value": "C4393"},
                     {"label": "Modbury North", "value": "C4394"},
                     {"label": "Monash", "value": "C4395"},
                     {"label": "Moonta Bay", "value": "C4396"},
                     {"label": "Moorak", "value": "C4397"},
                     {"label": "Morphett Vale", "value": "C4398"},
                     {"label": "Morphettville", "value": "C4399"},
                     {"label": "Mount Barker", "value": "C4400"},
                     {"label": "Mount Compass", "value": "C4401"},
                     {"label": "Mount Gambier", "value": "C4402"},
                     {"label": "Mount Remarkable", "value": "C4403"},
                     {"label": "Munno Para", "value": "C4404"},
                     {"label": "Munno Para West", "value": "C4405"},
                     {"label": "Murray Bridge", "value": "C4406"},
                     {"label": "Mylor", "value": "C4407"},
                     {"label": "Myrtle Bank", "value": "C4408"},
                     {"label": "Nailsworth", "value": "C4409"},
                     {"label": "Nairne", "value": "C4410"},
                     {"label": "Naracoorte", "value": "C4411"},
                     {"label": "Naracoorte and Lucindale", "value": "C4412"},
                     {"label": "Netherby", "value": "C4413"},
                     {"label": "Netley", "value": "C4414"},
                     {"label": "Newton", "value": "C4415"},
                     {"label": "Noarlunga Downs", "value": "C4416"},
                     {"label": "Normanville", "value": "C4417"},
                     {"label": "North Adelaide", "value": "C4418"},
                     {"label": "North Brighton", "value": "C4419"},
                     {"label": "North Haven", "value": "C4420"},
                     {"label": "North Plympton", "value": "C4421"},
                     {"label": "Northern Areas", "value": "C4422"},
                     {"label": "Northfield", "value": "C4423"},
                     {"label": "Northgate", "value": "C4424"},
                     {"label": "Norwood", "value": "C4425"},
                     {"label": "Norwood Payneham St Peters", "value": "C4426"},
                     {"label": "Novar Gardens", "value": "C4427"},
                     {"label": "Nuriootpa", "value": "C4428"},
                     {"label": "OSullivan Beach", "value": "C4429"},
                     {"label": "OHalloran Hill", "value": "C4430"},
                     {"label": "Oakden", "value": "C4431"},
                     {"label": "Oaklands Park", "value": "C4432"},
                     {"label": "Old Noarlunga", "value": "C4433"},
                     {"label": "Old Reynella", "value": "C4434"},
                     {"label": "One Tree Hill", "value": "C4435"},
                     {"label": "Onkaparinga", "value": "C4436"},
                     {"label": "Onkaparinga Hills", "value": "C4437"},
                     {"label": "OrrorooCarrieton", "value": "C4438"},
                     {"label": "Osborne", "value": "C4439"},
                     {"label": "Ottoway", "value": "C4440"},
                     {"label": "Panorama", "value": "C4441"},
                     {"label": "Para Hills", "value": "C4442"},
                     {"label": "Para Hills West", "value": "C4443"},
                     {"label": "Para Vista", "value": "C4444"},
                     {"label": "Paradise", "value": "C4445"},
                     {"label": "Parafield Gardens", "value": "C4446"},
                     {"label": "Paralowie", "value": "C4447"},
                     {"label": "Paringa", "value": "C4448"},
                     {"label": "Park Holme", "value": "C4449"},
                     {"label": "Parkside", "value": "C4450"},
                     {"label": "Pasadena", "value": "C4451"},
                     {"label": "Payneham", "value": "C4452"},
                     {"label": "Payneham South", "value": "C4453"},
                     {"label": "Pennington", "value": "C4454"},
                     {"label": "Penola", "value": "C4455"},
                     {"label": "Peterborough", "value": "C4456"},
                     {"label": "Peterhead", "value": "C4457"},
                     {"label": "Playford", "value": "C4458"},
                     {"label": "Plympton", "value": "C4459"},
                     {"label": "Plympton Park", "value": "C4460"},
                     {"label": "Pooraka", "value": "C4461"},
                     {"label": "Port Adelaide", "value": "C4462"},
                     {"label": "Port Adelaide Enfield", "value": "C4463"},
                     {"label": "Port Augusta", "value": "C4464"},
                     {"label": "Port Augusta West", "value": "C4465"},
                     {"label": "Port Broughton", "value": "C4466"},
                     {"label": "Port Elliot", "value": "C4467"},
                     {"label": "Port Lincoln", "value": "C4468"},
                     {"label": "Port Noarlunga", "value": "C4469"},
                     {"label": "Port Noarlunga South", "value": "C4470"},
                     {"label": "Port Pirie", "value": "C4471"},
                     {"label": "Port Pirie City and Dists", "value": "C4472"},
                     {"label": "Port Pirie South", "value": "C4473"},
                     {"label": "Port Pirie West", "value": "C4474"},
                     {"label": "Port Willunga", "value": "C4475"},
                     {"label": "Prospect", "value": "C4476"},
                     {"label": "Queenstown", "value": "C4477"},
                     {"label": "Quorn", "value": "C4478"},
                     {"label": "Redwood Park", "value": "C4479"},
                     {"label": "Renmark", "value": "C4480"},
                     {"label": "Renmark Paringa", "value": "C4481"},
                     {"label": "Renmark West", "value": "C4482"},
                     {"label": "Renown Park", "value": "C4483"},
                     {"label": "Reynella", "value": "C4484"},
                     {"label": "Reynella East", "value": "C4485"},
                     {"label": "Richmond", "value": "C4486"},
                     {"label": "Ridgehaven", "value": "C4487"},
                     {"label": "Ridleyton", "value": "C4488"},
                     {"label": "Risdon Park", "value": "C4489"},
                     {"label": "Risdon Park South", "value": "C4490"},
                     {"label": "Robe", "value": "C4491"},
                     {"label": "Rose Park", "value": "C4492"},
                     {"label": "Rosewater", "value": "C4493"},
                     {"label": "Rosslyn Park", "value": "C4494"},
                     {"label": "Rostrevor", "value": "C4495"},
                     {"label": "Roxby Downs", "value": "C4496"},
                     {"label": "Royal Park", "value": "C4497"},
                     {"label": "Royston Park", "value": "C4498"},
                     {"label": "Salisbury", "value": "C4499"},
                     {"label": "Salisbury Downs", "value": "C4500"},
                     {"label": "Salisbury East", "value": "C4501"},
                     {"label": "Salisbury Heights", "value": "C4502"},
                     {"label": "Salisbury North", "value": "C4503"},
                     {"label": "Salisbury Park", "value": "C4504"},
                     {"label": "Salisbury Plain", "value": "C4505"},
                     {"label": "Seacliff", "value": "C4506"},
                     {"label": "Seacliff Park", "value": "C4507"},
                     {"label": "Seacombe Gardens", "value": "C4508"},
                     {"label": "Seacombe Heights", "value": "C4509"},
                     {"label": "Seaford", "value": "C4510"},
                     {"label": "Seaford Meadows", "value": "C4511"},
                     {"label": "Seaford Rise", "value": "C4512"},
                     {"label": "Seaton", "value": "C4513"},
                     {"label": "Seaview Downs", "value": "C4514"},
                     {"label": "Sefton Park", "value": "C4515"},
                     {"label": "Sellicks Beach", "value": "C4516"},
                     {"label": "Semaphore", "value": "C4517"},
                     {"label": "Semaphore Park", "value": "C4518"},
                     {"label": "Semaphore South", "value": "C4519"},
                     {"label": "Sheidow Park", "value": "C4520"},
                     {"label": "Smithfield", "value": "C4521"},
                     {"label": "Smithfield Plains", "value": "C4522"},
                     {"label": "Solomontown", "value": "C4523"},
                     {"label": "Somerton Park", "value": "C4524"},
                     {"label": "South Brighton", "value": "C4525"},
                     {"label": "South Plympton", "value": "C4526"},
                     {"label": "Southern Mallee", "value": "C4527"},
                     {"label": "St Agnes", "value": "C4528"},
                     {"label": "St Georges", "value": "C4529"},
                     {"label": "St Marys", "value": "C4530"},
                     {"label": "St Morris", "value": "C4531"},
                     {"label": "St Peters", "value": "C4532"},
                     {"label": "Stirling", "value": "C4533"},
                     {"label": "Stirling North", "value": "C4534"},
                     {"label": "Stonyfell", "value": "C4535"},
                     {"label": "Strathalbyn", "value": "C4536"},
                     {"label": "Streaky Bay", "value": "C4537"},
                     {"label": "Sturt", "value": "C4538"},
                     {"label": "Surrey Downs", "value": "C4539"},
                     {"label": "Tailem Bend", "value": "C4540"},
                     {"label": "Tanunda", "value": "C4541"},
                     {"label": "Taperoo", "value": "C4542"},
                     {"label": "Tatiara", "value": "C4543"},
                     {"label": "Tea Tree Gully", "value": "C4544"},
                     {"label": "Tennyson", "value": "C4545"},
                     {"label": "The Coorong", "value": "C4546"},
                     {"label": "Thebarton", "value": "C4547"},
                     {"label": "Toorak Gardens", "value": "C4548"},
                     {"label": "Torrens Park", "value": "C4549"},
                     {"label": "Torrensville", "value": "C4550"},
                     {"label": "Tranmere", "value": "C4551"},
                     {"label": "Trinity Gardens", "value": "C4552"},
                     {"label": "Trott Park", "value": "C4553"},
                     {"label": "Tumby Bay", "value": "C4554"},
                     {"label": "Tusmore", "value": "C4555"},
                     {"label": "Two Wells", "value": "C4556"},
                     {"label": "Underdale", "value": "C4557"},
                     {"label": "Unley", "value": "C4558"},
                     {"label": "Unley Park", "value": "C4559"},
                     {"label": "Vale Park", "value": "C4560"},
                     {"label": "Valley View", "value": "C4561"},
                     {"label": "Victor Harbor", "value": "C4562"},
                     {"label": "Virginia", "value": "C4563"},
                     {"label": "Waikerie", "value": "C4564"},
                     {"label": "Wakefield", "value": "C4565"},
                     {"label": "Walkerville", "value": "C4566"},
                     {"label": "Walkley Heights", "value": "C4567"},
                     {"label": "Wallaroo", "value": "C4568"},
                     {"label": "Warradale", "value": "C4569"},
                     {"label": "Waterloo Corner", "value": "C4570"},
                     {"label": "Wattle Park", "value": "C4571"},
                     {"label": "Wattle Range", "value": "C4572"},
                     {"label": "Wayville", "value": "C4573"},
                     {"label": "West Beach", "value": "C4574"},
                     {"label": "West Croydon", "value": "C4575"},
                     {"label": "West Hindmarsh", "value": "C4576"},
                     {"label": "West Lakes", "value": "C4577"},
                     {"label": "West Lakes Shore", "value": "C4578"},
                     {"label": "Westbourne Park", "value": "C4579"},
                     {"label": "Whyalla", "value": "C4580"},
                     {"label": "Whyalla Jenkins", "value": "C4581"},
                     {"label": "Whyalla Norrie", "value": "C4582"},
                     {"label": "Whyalla Playford", "value": "C4583"},
                     {"label": "Whyalla Stuart", "value": "C4584"},
                     {"label": "Willaston", "value": "C4585"},
                     {"label": "Williamstown", "value": "C4586"},
                     {"label": "Willunga", "value": "C4587"},
                     {"label": "Windsor Gardens", "value": "C4588"},
                     {"label": "Woodcroft", "value": "C4589"},
                     {"label": "Woodside", "value": "C4590"},
                     {"label": "Woodville", "value": "C4591"},
                     {"label": "Woodville Gardens", "value": "C4592"},
                     {"label": "Woodville North", "value": "C4593"},
                     {"label": "Woodville Park", "value": "C4594"},
                     {"label": "Woodville South", "value": "C4595"},
                     {"label": "Woodville West", "value": "C4596"},
                     {"label": "Wudinna", "value": "C4597"},
                     {"label": "Wynn Vale", "value": "C4598"},
                     {"label": "Yankalilla", "value": "C4599"},
                     {"label": "Yorke Peninsula", "value": "C4600"}],
 "South Caribbean Coast": [{"label": "Bluefields", "value": "C78032"},
                           {"label": "Bocana de Paiwas", "value": "C78033"},
                           {"label": "Corn Island", "value": "C78034"},
                           {"label": "El Rama", "value": "C78035"},
                           {"label": "El Tortuguero", "value": "C78036"},
                           {"label": "Kukrahill", "value": "C78037"},
                           {"label": "La Cruz de Rio Grande",
                            "value": "C78038"},
                           {"label": "Laguna de Perlas", "value": "C78039"},
                           {"label": "Muelle de los Bueyes", "value": "C78040"},
                           {"label": "Nueva Guinea", "value": "C78041"}],
 "South Carolina": [{"label": "Abbeville", "value": "C142685"},
                    {"label": "Abbeville County", "value": "C142686"},
                    {"label": "Aiken", "value": "C142687"},
                    {"label": "Aiken County", "value": "C142688"},
                    {"label": "Allendale", "value": "C142689"},
                    {"label": "Allendale County", "value": "C142690"},
                    {"label": "Anderson", "value": "C142691"},
                    {"label": "Anderson County", "value": "C142692"},
                    {"label": "Andrews", "value": "C142693"},
                    {"label": "Arcadia", "value": "C142694"},
                    {"label": "Arial", "value": "C142695"},
                    {"label": "Awendaw", "value": "C142696"},
                    {"label": "Bamberg", "value": "C142697"},
                    {"label": "Bamberg County", "value": "C142698"},
                    {"label": "Barnwell", "value": "C142699"},
                    {"label": "Barnwell County", "value": "C142700"},
                    {"label": "Batesburg", "value": "C142701"},
                    {"label": "Batesburg-Leesville", "value": "C142702"},
                    {"label": "Beaufort", "value": "C142703"},
                    {"label": "Beaufort County", "value": "C142704"},
                    {"label": "Belton", "value": "C142705"},
                    {"label": "Belvedere", "value": "C142706"},
                    {"label": "Bennettsville", "value": "C142707"},
                    {"label": "Berea", "value": "C142708"},
                    {"label": "Berkeley County", "value": "C142709"},
                    {"label": "Bishopville", "value": "C142710"},
                    {"label": "Blacksburg", "value": "C142711"},
                    {"label": "Blackville", "value": "C142712"},
                    {"label": "Bluffton", "value": "C142713"},
                    {"label": "Blythewood", "value": "C142714"},
                    {"label": "Boiling Springs", "value": "C142715"},
                    {"label": "Bonneau Beach", "value": "C142716"},
                    {"label": "Brookdale", "value": "C142717"},
                    {"label": "Buffalo", "value": "C142718"},
                    {"label": "Burnettown", "value": "C142719"},
                    {"label": "Burton", "value": "C142720"},
                    {"label": "Calhoun County", "value": "C142721"},
                    {"label": "Calhoun Falls", "value": "C142722"},
                    {"label": "Camden", "value": "C142723"},
                    {"label": "Cane Savannah", "value": "C142724"},
                    {"label": "Catawba", "value": "C142725"},
                    {"label": "Cayce", "value": "C142726"},
                    {"label": "Centerville", "value": "C142727"},
                    {"label": "Central", "value": "C142728"},
                    {"label": "Chapin", "value": "C142729"},
                    {"label": "Charleston", "value": "C142730"},
                    {"label": "Charleston County", "value": "C142731"},
                    {"label": "Cheraw", "value": "C142732"},
                    {"label": "Cherokee County", "value": "C142733"},
                    {"label": "Cherryvale", "value": "C142734"},
                    {"label": "Chester", "value": "C142735"},
                    {"label": "Chester County", "value": "C142736"},
                    {"label": "Chesterfield", "value": "C142737"},
                    {"label": "Chesterfield County", "value": "C142738"},
                    {"label": "City View", "value": "C142739"},
                    {"label": "Clarendon County", "value": "C142740"},
                    {"label": "Clearwater", "value": "C142741"},
                    {"label": "Clemson", "value": "C142742"},
                    {"label": "Clinton", "value": "C142743"},
                    {"label": "Clover", "value": "C142744"},
                    {"label": "Colleton County", "value": "C142745"},
                    {"label": "Columbia", "value": "C142746"},
                    {"label": "Conway", "value": "C142747"},
                    {"label": "Cowpens", "value": "C142748"},
                    {"label": "Dalzell", "value": "C142749"},
                    {"label": "Darlington", "value": "C142750"},
                    {"label": "Darlington County", "value": "C142751"},
                    {"label": "Denmark", "value": "C142752"},
                    {"label": "Dentsville", "value": "C142753"},
                    {"label": "Dillon", "value": "C142754"},
                    {"label": "Dillon County", "value": "C142755"},
                    {"label": "Dorchester County", "value": "C142756"},
                    {"label": "Due West", "value": "C142757"},
                    {"label": "Duncan", "value": "C142758"},
                    {"label": "Dunean", "value": "C142759"},
                    {"label": "Easley", "value": "C142760"},
                    {"label": "East Gaffney", "value": "C142761"},
                    {"label": "East Sumter", "value": "C142762"},
                    {"label": "Edgefield", "value": "C142763"},
                    {"label": "Edgefield County", "value": "C142764"},
                    {"label": "Edisto", "value": "C142765"},
                    {"label": "Elgin", "value": "C142766"},
                    {"label": "Estill", "value": "C142767"},
                    {"label": "Eureka Mill", "value": "C142768"},
                    {"label": "Fairfax", "value": "C142769"},
                    {"label": "Fairfield County", "value": "C142770"},
                    {"label": "Fairforest", "value": "C142771"},
                    {"label": "Five Forks", "value": "C142772"},
                    {"label": "Florence", "value": "C142773"},
                    {"label": "Florence County", "value": "C142774"},
                    {"label": "Folly Beach", "value": "C142775"},
                    {"label": "Forest Acres", "value": "C142776"},
                    {"label": "Forestbrook", "value": "C142777"},
                    {"label": "Fort Mill", "value": "C142778"},
                    {"label": "Fountain Inn", "value": "C142779"},
                    {"label": "Gadsden", "value": "C142780"},
                    {"label": "Gaffney", "value": "C142781"},
                    {"label": "Gantt", "value": "C142782"},
                    {"label": "Garden City", "value": "C142783"},
                    {"label": "Gaston", "value": "C142784"},
                    {"label": "Georgetown", "value": "C142785"},
                    {"label": "Georgetown County", "value": "C142786"},
                    {"label": "Gloverville", "value": "C142787"},
                    {"label": "Golden Grove", "value": "C142788"},
                    {"label": "Goose Creek", "value": "C142789"},
                    {"label": "Graniteville", "value": "C142790"},
                    {"label": "Great Falls", "value": "C142791"},
                    {"label": "Greenville", "value": "C142792"},
                    {"label": "Greenville County", "value": "C142793"},
                    {"label": "Greenwood", "value": "C142794"},
                    {"label": "Greenwood County", "value": "C142795"},
                    {"label": "Greer", "value": "C142796"},
                    {"label": "Hampton", "value": "C142797"},
                    {"label": "Hampton County", "value": "C142798"},
                    {"label": "Hanahan", "value": "C142799"},
                    {"label": "Hardeeville", "value": "C142800"},
                    {"label": "Hartsville", "value": "C142801"},
                    {"label": "Hilton Head", "value": "C142802"},
                    {"label": "Hilton Head Island", "value": "C142803"},
                    {"label": "Holly Hill", "value": "C142804"},
                    {"label": "Hollywood", "value": "C142805"},
                    {"label": "Homeland Park", "value": "C142806"},
                    {"label": "Honea Path", "value": "C142807"},
                    {"label": "Hopkins", "value": "C142808"},
                    {"label": "Horry County", "value": "C142809"},
                    {"label": "India Hook", "value": "C142810"},
                    {"label": "Inman", "value": "C142811"},
                    {"label": "Inman Mills", "value": "C142812"},
                    {"label": "Irmo", "value": "C142813"},
                    {"label": "Irwin", "value": "C142814"},
                    {"label": "Isle of Palms", "value": "C142815"},
                    {"label": "Iva", "value": "C142816"},
                    {"label": "Jackson", "value": "C142817"},
                    {"label": "James Island", "value": "C142818"},
                    {"label": "Jasper County", "value": "C142819"},
                    {"label": "Joanna", "value": "C142820"},
                    {"label": "Johnsonville", "value": "C142821"},
                    {"label": "Johnston", "value": "C142822"},
                    {"label": "Judson", "value": "C142823"},
                    {"label": "Kershaw", "value": "C142824"},
                    {"label": "Kershaw County", "value": "C142825"},
                    {"label": "Kiawah Island", "value": "C142826"},
                    {"label": "Kingstree", "value": "C142827"},
                    {"label": "Ladson", "value": "C142828"},
                    {"label": "Lake City", "value": "C142829"},
                    {"label": "Lake Murray of Richland", "value": "C142830"},
                    {"label": "Lake Secession", "value": "C142831"},
                    {"label": "Lake Wylie", "value": "C142832"},
                    {"label": "Lakewood", "value": "C142833"},
                    {"label": "Lancaster", "value": "C142834"},
                    {"label": "Lancaster County", "value": "C142835"},
                    {"label": "Lancaster Mill", "value": "C142836"},
                    {"label": "Landrum", "value": "C142837"},
                    {"label": "Langley", "value": "C142838"},
                    {"label": "Latta", "value": "C142839"},
                    {"label": "Laurel Bay", "value": "C142840"},
                    {"label": "Laurens", "value": "C142841"},
                    {"label": "Laurens County", "value": "C142842"},
                    {"label": "Lee County", "value": "C142843"},
                    {"label": "Leesville", "value": "C142844"},
                    {"label": "Lesslie", "value": "C142845"},
                    {"label": "Lexington", "value": "C142846"},
                    {"label": "Lexington County", "value": "C142847"},
                    {"label": "Liberty", "value": "C142848"},
                    {"label": "Lincolnville", "value": "C142849"},
                    {"label": "Little River", "value": "C142850"},
                    {"label": "Loris", "value": "C142851"},
                    {"label": "Lugoff", "value": "C142852"},
                    {"label": "Lyman", "value": "C142853"},
                    {"label": "Manning", "value": "C142854"},
                    {"label": "Marion", "value": "C142855"},
                    {"label": "Marion County", "value": "C142856"},
                    {"label": "Marlboro County", "value": "C142857"},
                    {"label": "Mauldin", "value": "C142858"},
                    {"label": "Mayo", "value": "C142859"},
                    {"label": "McColl", "value": "C142860"},
                    {"label": "McCormick", "value": "C142861"},
                    {"label": "McCormick County", "value": "C142862"},
                    {"label": "Meggett", "value": "C142863"},
                    {"label": "Monarch Mill", "value": "C142864"},
                    {"label": "Moncks Corner", "value": "C142865"},
                    {"label": "Mount Pleasant", "value": "C142866"},
                    {"label": "Mullins", "value": "C142867"},
                    {"label": "Murphys Estates", "value": "C142868"},
                    {"label": "Murrells Inlet", "value": "C142869"},
                    {"label": "Myrtle Beach", "value": "C142870"},
                    {"label": "New Ellenton", "value": "C142871"},
                    {"label": "Newberry", "value": "C142872"},
                    {"label": "Newberry County", "value": "C142873"},
                    {"label": "Newport", "value": "C142874"},
                    {"label": "Ninety Six", "value": "C142875"},
                    {"label": "North Augusta", "value": "C142876"},
                    {"label": "North Charleston", "value": "C142877"},
                    {"label": "North Hartsville", "value": "C142878"},
                    {"label": "North Myrtle Beach", "value": "C142879"},
                    {"label": "Northlake", "value": "C142880"},
                    {"label": "Oak Grove", "value": "C142881"},
                    {"label": "Oakland", "value": "C142882"},
                    {"label": "Oconee County", "value": "C142883"},
                    {"label": "Orangeburg", "value": "C142884"},
                    {"label": "Orangeburg County", "value": "C142885"},
                    {"label": "Pacolet", "value": "C142886"},
                    {"label": "Pageland", "value": "C142887"},
                    {"label": "Pamplico", "value": "C142888"},
                    {"label": "Parker", "value": "C142889"},
                    {"label": "Pendleton", "value": "C142890"},
                    {"label": "Pickens", "value": "C142891"},
                    {"label": "Pickens County", "value": "C142892"},
                    {"label": "Piedmont", "value": "C142893"},
                    {"label": "Pineridge", "value": "C142894"},
                    {"label": "Port Royal", "value": "C142895"},
                    {"label": "Powdersville", "value": "C142896"},
                    {"label": "Privateer", "value": "C142897"},
                    {"label": "Prosperity", "value": "C142898"},
                    {"label": "Ravenel", "value": "C142899"},
                    {"label": "Red Bank", "value": "C142900"},
                    {"label": "Red Hill", "value": "C142901"},
                    {"label": "Richland County", "value": "C142902"},
                    {"label": "Ridgeland", "value": "C142903"},
                    {"label": "Ridgeville", "value": "C142904"},
                    {"label": "Rock Hill", "value": "C142905"},
                    {"label": "Roebuck", "value": "C142906"},
                    {"label": "Saint Andrews", "value": "C142907"},
                    {"label": "Saint George", "value": "C142908"},
                    {"label": "Saint Matthews", "value": "C142909"},
                    {"label": "Saint Stephen", "value": "C142910"},
                    {"label": "Saluda", "value": "C142911"},
                    {"label": "Saluda County", "value": "C142912"},
                    {"label": "Sangaree", "value": "C142913"},
                    {"label": "Sans Souci", "value": "C142914"},
                    {"label": "Saxon", "value": "C142915"},
                    {"label": "Seabrook Island", "value": "C142916"},
                    {"label": "Seneca", "value": "C142917"},
                    {"label": "Seven Oaks", "value": "C142918"},
                    {"label": "Shell Point", "value": "C142919"},
                    {"label": "Simpsonville", "value": "C142920"},
                    {"label": "Slater-Marietta", "value": "C142921"},
                    {"label": "Socastee", "value": "C142922"},
                    {"label": "South Congaree", "value": "C142923"},
                    {"label": "South Sumter", "value": "C142924"},
                    {"label": "Southern Shops", "value": "C142925"},
                    {"label": "Spartanburg", "value": "C142926"},
                    {"label": "Spartanburg County", "value": "C142927"},
                    {"label": "Springdale", "value": "C142928"},
                    {"label": "Stateburg", "value": "C142929"},
                    {"label": "Sullivans Island", "value": "C142930"},
                    {"label": "Summerville", "value": "C142931"},
                    {"label": "Sumter", "value": "C142932"},
                    {"label": "Sumter County", "value": "C142933"},
                    {"label": "Surfside Beach", "value": "C142934"},
                    {"label": "Taylors", "value": "C142935"},
                    {"label": "Tega Cay", "value": "C142936"},
                    {"label": "Tigerville", "value": "C142937"},
                    {"label": "Timmonsville", "value": "C142938"},
                    {"label": "Travelers Rest", "value": "C142939"},
                    {"label": "Union", "value": "C142940"},
                    {"label": "Union County", "value": "C142941"},
                    {"label": "Utica", "value": "C142942"},
                    {"label": "Valley Falls", "value": "C142943"},
                    {"label": "Varnville", "value": "C142944"},
                    {"label": "Wade Hampton", "value": "C142945"},
                    {"label": "Walhalla", "value": "C142946"},
                    {"label": "Walterboro", "value": "C142947"},
                    {"label": "Ware Shoals", "value": "C142948"},
                    {"label": "Warrenville", "value": "C142949"},
                    {"label": "Watts Mills", "value": "C142950"},
                    {"label": "Wedgefield", "value": "C142951"},
                    {"label": "Wedgewood", "value": "C142952"},
                    {"label": "Welcome", "value": "C142953"},
                    {"label": "Wellford", "value": "C142954"},
                    {"label": "West Columbia", "value": "C142955"},
                    {"label": "Westminster", "value": "C142956"},
                    {"label": "Whitmire", "value": "C142957"},
                    {"label": "Wilkinson Heights", "value": "C142958"},
                    {"label": "Williamsburg County", "value": "C142959"},
                    {"label": "Williamston", "value": "C142960"},
                    {"label": "Williston", "value": "C142961"},
                    {"label": "Winnsboro", "value": "C142962"},
                    {"label": "Winnsboro Mills", "value": "C142963"},
                    {"label": "Woodfield", "value": "C142964"},
                    {"label": "Woodruff", "value": "C142965"},
                    {"label": "York", "value": "C142966"},
                    {"label": "York County", "value": "C142967"}],
 "South Central Province": [{"label": "Mahibadhoo", "value": "C65819"}],
 "South Chungcheong Province": [{"label": "Asan", "value": "C108454"},
                                {"label": "Asan-si", "value": "C108455"},
                                {"label": "Boryeong", "value": "C108456"},
                                {"label": "Boryeong-si", "value": "C108457"},
                                {"label": "Buyeo", "value": "C108458"},
                                {"label": "Buyeo-gun", "value": "C108459"},
                                {"label": "Cheonan", "value": "C108460"},
                                {"label": "Cheonan-si", "value": "C108461"},
                                {"label": "Cheongyang-gun", "value": "C108462"},
                                {"label": "Geumsan-gun", "value": "C108463"},
                                {"label": "Gongju", "value": "C108464"},
                                {"label": "Gongju-si", "value": "C108465"},
                                {"label": "Gyeryong-si", "value": "C108466"},
                                {"label": "Hongseong", "value": "C108467"},
                                {"label": "Hongseong-gun", "value": "C108468"},
                                {"label": "Kinzan", "value": "C108469"},
                                {"label": "Nonsan", "value": "C108470"},
                                {"label": "Nonsan-si", "value": "C108471"},
                                {"label": "Seocheon-gun", "value": "C108472"},
                                {"label": "Seonghwan", "value": "C108473"},
                                {"label": "Seosan", "value": "C108474"},
                                {"label": "Seosan-si", "value": "C108475"},
                                {"label": "Taean-gun", "value": "C108476"},
                                {"label": "Taesal-li", "value": "C108477"},
                                {"label": "Tangjin", "value": "C108478"},
                                {"label": "Yesan", "value": "C108479"},
                                {"label": "Yesan-gun", "value": "C108480"},
                                {"label": "Yonmu", "value": "C108481"}],
 "South Dakota": [{"label": "Aberdeen", "value": "C142968"},
                  {"label": "Alexandria", "value": "C142969"},
                  {"label": "Armour", "value": "C142970"},
                  {"label": "Aurora County", "value": "C142971"},
                  {"label": "Baltic", "value": "C142972"},
                  {"label": "Beadle County", "value": "C142973"},
                  {"label": "Belle Fourche", "value": "C142974"},
                  {"label": "Bennett County", "value": "C142975"},
                  {"label": "Beresford", "value": "C142976"},
                  {"label": "Bison", "value": "C142977"},
                  {"label": "Blackhawk", "value": "C142978"},
                  {"label": "Bon Homme County", "value": "C142979"},
                  {"label": "Box Elder", "value": "C142980"},
                  {"label": "Brandon", "value": "C142981"},
                  {"label": "Britton", "value": "C142982"},
                  {"label": "Brookings", "value": "C142983"},
                  {"label": "Brookings County", "value": "C142984"},
                  {"label": "Brown County", "value": "C142985"},
                  {"label": "Brule County", "value": "C142986"},
                  {"label": "Buffalo", "value": "C142987"},
                  {"label": "Buffalo County", "value": "C142988"},
                  {"label": "Burke", "value": "C142989"},
                  {"label": "Butte County", "value": "C142990"},
                  {"label": "Campbell County", "value": "C142991"},
                  {"label": "Canton", "value": "C142992"},
                  {"label": "Chamberlain", "value": "C142993"},
                  {"label": "Charles Mix County", "value": "C142994"},
                  {"label": "Clark", "value": "C142995"},
                  {"label": "Clark County", "value": "C142996"},
                  {"label": "Clay County", "value": "C142997"},
                  {"label": "Clear Lake", "value": "C142998"},
                  {"label": "Codington County", "value": "C142999"},
                  {"label": "Colonial Pine Hills", "value": "C143000"},
                  {"label": "Corson County", "value": "C143001"},
                  {"label": "Crooks", "value": "C143002"},
                  {"label": "Custer", "value": "C143003"},
                  {"label": "Custer County", "value": "C143004"},
                  {"label": "Dakota Dunes", "value": "C143005"},
                  {"label": "Davison County", "value": "C143006"},
                  {"label": "Day County", "value": "C143007"},
                  {"label": "De Smet", "value": "C143008"},
                  {"label": "Deadwood", "value": "C143009"},
                  {"label": "Dell Rapids", "value": "C143010"},
                  {"label": "Deuel County", "value": "C143011"},
                  {"label": "Dewey County", "value": "C143012"},
                  {"label": "Douglas County", "value": "C143013"},
                  {"label": "Dupree", "value": "C143014"},
                  {"label": "Eagle Butte", "value": "C143015"},
                  {"label": "Edmunds County", "value": "C143016"},
                  {"label": "Elk Point", "value": "C143017"},
                  {"label": "Fall River County", "value": "C143018"},
                  {"label": "Faulk County", "value": "C143019"},
                  {"label": "Faulkton", "value": "C143020"},
                  {"label": "Flandreau", "value": "C143021"},
                  {"label": "Fort Pierre", "value": "C143022"},
                  {"label": "Fort Thompson", "value": "C143023"},
                  {"label": "Freeman", "value": "C143024"},
                  {"label": "Garretson", "value": "C143025"},
                  {"label": "Gettysburg", "value": "C143026"},
                  {"label": "Grant County", "value": "C143027"},
                  {"label": "Gregory", "value": "C143028"},
                  {"label": "Gregory County", "value": "C143029"},
                  {"label": "Groton", "value": "C143030"},
                  {"label": "Haakon County", "value": "C143031"},
                  {"label": "Hamlin County", "value": "C143032"},
                  {"label": "Hand County", "value": "C143033"},
                  {"label": "Hanson County", "value": "C143034"},
                  {"label": "Harding County", "value": "C143035"},
                  {"label": "Harrisburg", "value": "C143036"},
                  {"label": "Hartford", "value": "C143037"},
                  {"label": "Hayti", "value": "C143038"},
                  {"label": "Highmore", "value": "C143039"},
                  {"label": "Hot Springs", "value": "C143040"},
                  {"label": "Howard", "value": "C143041"},
                  {"label": "Hughes County", "value": "C143042"},
                  {"label": "Huron", "value": "C143043"},
                  {"label": "Hutchinson County", "value": "C143044"},
                  {"label": "Hyde County", "value": "C143045"},
                  {"label": "Ipswich", "value": "C143046"},
                  {"label": "Jackson County", "value": "C143047"},
                  {"label": "Jerauld County", "value": "C143048"},
                  {"label": "Jones County", "value": "C143049"},
                  {"label": "Kadoka", "value": "C143050"},
                  {"label": "Kennebec", "value": "C143051"},
                  {"label": "Kingsbury County", "value": "C143052"},
                  {"label": "Lake Andes", "value": "C143053"},
                  {"label": "Lake County", "value": "C143054"},
                  {"label": "Lawrence County", "value": "C143055"},
                  {"label": "Lead", "value": "C143056"},
                  {"label": "Lemmon", "value": "C143057"},
                  {"label": "Lennox", "value": "C143058"},
                  {"label": "Leola", "value": "C143059"},
                  {"label": "Lincoln County", "value": "C143060"},
                  {"label": "Lyman County", "value": "C143061"},
                  {"label": "Madison", "value": "C143062"},
                  {"label": "Marshall County", "value": "C143063"},
                  {"label": "Martin", "value": "C143064"},
                  {"label": "McCook County", "value": "C143065"},
                  {"label": "McIntosh", "value": "C143066"},
                  {"label": "McPherson County", "value": "C143067"},
                  {"label": "Meade County", "value": "C143068"},
                  {"label": "Mellette County", "value": "C143069"},
                  {"label": "Milbank", "value": "C143070"},
                  {"label": "Miller", "value": "C143071"},
                  {"label": "Miner County", "value": "C143072"},
                  {"label": "Minnehaha County", "value": "C143073"},
                  {"label": "Mission", "value": "C143074"},
                  {"label": "Mitchell", "value": "C143075"},
                  {"label": "Mobridge", "value": "C143076"},
                  {"label": "Moody County", "value": "C143077"},
                  {"label": "Mound City", "value": "C143078"},
                  {"label": "Murdo", "value": "C143079"},
                  {"label": "North Eagle Butte", "value": "C143080"},
                  {"label": "North Sioux City", "value": "C143081"},
                  {"label": "North Spearfish", "value": "C143082"},
                  {"label": "Oglala", "value": "C143083"},
                  {"label": "Oglala Lakota County", "value": "C143084"},
                  {"label": "Olivet", "value": "C143085"},
                  {"label": "Onida", "value": "C143086"},
                  {"label": "Parker", "value": "C143087"},
                  {"label": "Parkston", "value": "C143088"},
                  {"label": "Pennington County", "value": "C143089"},
                  {"label": "Perkins County", "value": "C143090"},
                  {"label": "Philip", "value": "C143091"},
                  {"label": "Pierre", "value": "C143092"},
                  {"label": "Pine Ridge", "value": "C143093"},
                  {"label": "Plankinton", "value": "C143094"},
                  {"label": "Platte", "value": "C143095"},
                  {"label": "Porcupine", "value": "C143096"},
                  {"label": "Potter County", "value": "C143097"},
                  {"label": "Rapid City", "value": "C143098"},
                  {"label": "Rapid Valley", "value": "C143099"},
                  {"label": "Redfield", "value": "C143100"},
                  {"label": "Roberts County", "value": "C143101"},
                  {"label": "Rosebud", "value": "C143102"},
                  {"label": "Salem", "value": "C143103"},
                  {"label": "Sanborn County", "value": "C143104"},
                  {"label": "Selby", "value": "C143105"},
                  {"label": "Sioux Falls", "value": "C143106"},
                  {"label": "Sisseton", "value": "C143107"},
                  {"label": "Spearfish", "value": "C143108"},
                  {"label": "Spink County", "value": "C143109"},
                  {"label": "Springfield", "value": "C143110"},
                  {"label": "Stanley County", "value": "C143111"},
                  {"label": "Sturgis", "value": "C143112"},
                  {"label": "Sully County", "value": "C143113"},
                  {"label": "Summerset", "value": "C143114"},
                  {"label": "Tea", "value": "C143115"},
                  {"label": "Timber Lake", "value": "C143116"},
                  {"label": "Todd County", "value": "C143117"},
                  {"label": "Tripp County", "value": "C143118"},
                  {"label": "Turner County", "value": "C143119"},
                  {"label": "Tyndall", "value": "C143120"},
                  {"label": "Union County", "value": "C143121"},
                  {"label": "Vermillion", "value": "C143122"},
                  {"label": "Volga", "value": "C143123"},
                  {"label": "Wagner", "value": "C143124"},
                  {"label": "Walworth County", "value": "C143125"},
                  {"label": "Watertown", "value": "C143126"},
                  {"label": "Webster", "value": "C143127"},
                  {"label": "Wessington Springs", "value": "C143128"},
                  {"label": "White River", "value": "C143129"},
                  {"label": "Winner", "value": "C143130"},
                  {"label": "Woonsocket", "value": "C143131"},
                  {"label": "Yankton", "value": "C143132"},
                  {"label": "Yankton County", "value": "C143133"},
                  {"label": "Ziebach County", "value": "C143134"}],
 "South Darfur": [{"label": "Gereida", "value": "C115439"},
                  {"label": "Nyala", "value": "C115440"}],
 "South Governorate": [{"label": "En Naqoura", "value": "C64986"},
                       {"label": "Ghazieh", "value": "C64987"},
                       {"label": "Sidon", "value": "C64988"},
                       {"label": "Tyre", "value": "C64989"}],
 "South Gyeongsang Province": [{"label": "Changnyeong", "value": "C108482"},
                               {"label": "Changnyeong-gun", "value": "C108483"},
                               {"label": "Changwon", "value": "C108484"},
                               {"label": "Changwon-si", "value": "C108485"},
                               {"label": "Chinju", "value": "C108486"},
                               {"label": "Geochang-gun", "value": "C108487"},
                               {"label": "Geoje-si", "value": "C108488"},
                               {"label": "Gimhae-si", "value": "C108489"},
                               {"label": "Goseong", "value": "C108490"},
                               {"label": "Goseong-gun", "value": "C108491"},
                               {"label": "Hadong-gun", "value": "C108492"},
                               {"label": "Haman-gun", "value": "C108493"},
                               {"label": "Hapcheon-gun", "value": "C108494"},
                               {"label": "Jinju-si", "value": "C108495"},
                               {"label": "Kimhae", "value": "C108496"},
                               {"label": "Kyosai", "value": "C108497"},
                               {"label": "Miryang", "value": "C108498"},
                               {"label": "Miryang-si", "value": "C108499"},
                               {"label": "Naeso", "value": "C108500"},
                               {"label": "Namhae-gun", "value": "C108501"},
                               {"label": "Sacheon-si", "value": "C108502"},
                               {"label": "Sancheong-gun", "value": "C108503"},
                               {"label": "Sinhyeon", "value": "C108504"},
                               {"label": "Uiryeong-gun", "value": "C108505"},
                               {"label": "Ungsang", "value": "C108506"},
                               {"label": "Yangsan", "value": "C108507"}],
 "South Hamgyong Province": [{"label": "Hamhung", "value": "C78591"},
                             {"label": "Hongwon", "value": "C78592"},
                             {"label": "Hungnam", "value": "C78593"},
                             {"label": "Iwon-up", "value": "C78594"},
                             {"label": "Kowon-up", "value": "C78595"},
                             {"label": "Samho-rodongjagu", "value": "C78596"},
                             {"label": "Sinsang-ni", "value": "C78597"},
                             {"label": "Yonggwang-up", "value": "C78598"},
                             {"label": "Yuktae-dong", "value": "C78599"}],
 "South Holland": [{"label": "s-Gravenland", "value": "C77428"},
                   {"label": "s-Gravenzande", "value": "C77429"},
                   {"label": "Adegeest", "value": "C77430"},
                   {"label": "Alblasserdam", "value": "C77431"},
                   {"label": "Alphen aan den Rijn", "value": "C77432"},
                   {"label": "Ammerstol", "value": "C77433"},
                   {"label": "Arkel", "value": "C77434"},
                   {"label": "Barendrecht", "value": "C77435"},
                   {"label": "Benthuizen", "value": "C77436"},
                   {"label": "Bergschenhoek", "value": "C77437"},
                   {"label": "Bergstoep", "value": "C77438"},
                   {"label": "Berkel en Rodenrijs", "value": "C77439"},
                   {"label": "Berkenwoude", "value": "C77440"},
                   {"label": "Binnenhof", "value": "C77441"},
                   {"label": "Bleiswijk", "value": "C77442"},
                   {"label": "Bleskensgraaf", "value": "C77443"},
                   {"label": "Bloemendaal", "value": "C77444"},
                   {"label": "Bloemhof", "value": "C77445"},
                   {"label": "Bodegraven", "value": "C77446"},
                   {"label": "Bodegraven-Reeuwijk", "value": "C77447"},
                   {"label": "Bolnes", "value": "C77448"},
                   {"label": "Boskoop", "value": "C77449"},
                   {"label": "Boven-Hardinxveld", "value": "C77450"},
                   {"label": "Brielle", "value": "C77451"},
                   {"label": "Capelle aan den IJssel", "value": "C77452"},
                   {"label": "Capelle-West", "value": "C77453"},
                   {"label": "Carnisse", "value": "C77454"},
                   {"label": "De Kieviet", "value": "C77455"},
                   {"label": "De Lier", "value": "C77456"},
                   {"label": "De Peulen", "value": "C77457"},
                   {"label": "Delfshaven", "value": "C77458"},
                   {"label": "Delft", "value": "C77459"},
                   {"label": "Dirksland", "value": "C77460"},
                   {"label": "Dordrecht", "value": "C77461"},
                   {"label": "Driebruggen", "value": "C77462"},
                   {"label": "Driemanspolder", "value": "C77463"},
                   {"label": "Duindorp", "value": "C77464"},
                   {"label": "Duinzigt", "value": "C77465"},
                   {"label": "Essesteijn", "value": "C77466"},
                   {"label": "Feijenoord", "value": "C77467"},
                   {"label": "Giessenburg", "value": "C77468"},
                   {"label": "Giessendam", "value": "C77469"},
                   {"label": "Goedereede", "value": "C77470"},
                   {"label": "Gorinchem", "value": "C77471"},
                   {"label": "Gouda", "value": "C77472"},
                   {"label": "Gouderak", "value": "C77473"},
                   {"label": "Goudswaard", "value": "C77474"},
                   {"label": "Gouwsluis", "value": "C77475"},
                   {"label": "Groenswaard", "value": "C77476"},
                   {"label": "Groot IJsselmonde", "value": "C77477"},
                   {"label": "Groot-Ammers", "value": "C77478"},
                   {"label": "Haastrecht", "value": "C77479"},
                   {"label": "Heerjansdam", "value": "C77480"},
                   {"label": "Heijplaat", "value": "C77481"},
                   {"label": "Hellevoetsluis", "value": "C77482"},
                   {"label": "Hendrik-Ido-Ambacht", "value": "C77483"},
                   {"label": "Hillegom", "value": "C77484"},
                   {"label": "Hoek van Holland", "value": "C77485"},
                   {"label": "Hoge Mors", "value": "C77486"},
                   {"label": "Honselersdijk", "value": "C77487"},
                   {"label": "Hoogblokland", "value": "C77488"},
                   {"label": "Hoogmade", "value": "C77489"},
                   {"label": "Katendrecht", "value": "C77490"},
                   {"label": "Katwijk aan den Rijn", "value": "C77491"},
                   {"label": "Katwijk aan Zee", "value": "C77492"},
                   {"label": "Kerkehout", "value": "C77493"},
                   {"label": "Kijkduin", "value": "C77494"},
                   {"label": "Klaaswaal", "value": "C77495"},
                   {"label": "Kop van Zuid", "value": "C77496"},
                   {"label": "Krimpen aan den IJssel", "value": "C77497"},
                   {"label": "Krimpenerwaard", "value": "C77498"},
                   {"label": "Kwintsheul", "value": "C77499"},
                   {"label": "Leiden", "value": "C77500"},
                   {"label": "Leiderdorp", "value": "C77501"},
                   {"label": "Leimuiden", "value": "C77502"},
                   {"label": "Liesveld", "value": "C77503"},
                   {"label": "Lisse", "value": "C77504"},
                   {"label": "Lombardijen", "value": "C77505"},
                   {"label": "Maasdijk", "value": "C77506"},
                   {"label": "Maasland", "value": "C77507"},
                   {"label": "Maassluis", "value": "C77508"},
                   {"label": "Merenwijk", "value": "C77509"},
                   {"label": "Middelharnis", "value": "C77510"},
                   {"label": "Middelsluis", "value": "C77511"},
                   {"label": "Mijnsheerenland", "value": "C77512"},
                   {"label": "Monster", "value": "C77513"},
                   {"label": "Naaldwijk", "value": "C77514"},
                   {"label": "Neder-Hardinxveld", "value": "C77515"},
                   {"label": "Nieuw-Beijerland", "value": "C77516"},
                   {"label": "Nieuw-Lekkerland", "value": "C77517"},
                   {"label": "Nieuwenhoorn", "value": "C77518"},
                   {"label": "Nieuwkoop", "value": "C77519"},
                   {"label": "Nieuwpoort", "value": "C77520"},
                   {"label": "Nieuwveen", "value": "C77521"},
                   {"label": "Nissewaard", "value": "C77522"},
                   {"label": "Noord-Hofland", "value": "C77523"},
                   {"label": "Noordeinde", "value": "C77524"},
                   {"label": "Noordeloos", "value": "C77525"},
                   {"label": "Noordwijk-Binnen", "value": "C77526"},
                   {"label": "Noordwijkerhout", "value": "C77527"},
                   {"label": "Numansdorp", "value": "C77528"},
                   {"label": "Oegstgeest", "value": "C77529"},
                   {"label": "Ommoord", "value": "C77530"},
                   {"label": "Oostdorp", "value": "C77531"},
                   {"label": "Oostvoorne", "value": "C77532"},
                   {"label": "Oranjewijk", "value": "C77533"},
                   {"label": "Oud-Beijerland", "value": "C77534"},
                   {"label": "Ouddorp", "value": "C77535"},
                   {"label": "Oude Wetering", "value": "C77536"},
                   {"label": "Ouderkerk aan den IJssel", "value": "C77537"},
                   {"label": "Overschie", "value": "C77538"},
                   {"label": "Palenstein", "value": "C77539"},
                   {"label": "Papendrecht", "value": "C77540"},
                   {"label": "Papenveer", "value": "C77541"},
                   {"label": "Pendrecht", "value": "C77542"},
                   {"label": "Pernis", "value": "C77543"},
                   {"label": "Piershil", "value": "C77544"},
                   {"label": "Pijnacker", "value": "C77545"},
                   {"label": "Poeldijk", "value": "C77546"},
                   {"label": "Reeuwijk", "value": "C77547"},
                   {"label": "Rhoon", "value": "C77548"},
                   {"label": "Ridderkerk", "value": "C77549"},
                   {"label": "Rijnsaterwoude", "value": "C77550"},
                   {"label": "Rijnsburg", "value": "C77551"},
                   {"label": "Rijpwetering", "value": "C77552"},
                   {"label": "Rijsoord", "value": "C77553"},
                   {"label": "Rijswijk", "value": "C77554"},
                   {"label": "Rockanje", "value": "C77555"},
                   {"label": "Roelofarendsveen", "value": "C77556"},
                   {"label": "Rotterdam", "value": "C77557"},
                   {"label": "Rozenburg", "value": "C77558"},
                   {"label": "Rugge", "value": "C77559"},
                   {"label": "Sassenheim", "value": "C77560"},
                   {"label": "Schelluinen", "value": "C77561"},
                   {"label": "Scheveningen", "value": "C77562"},
                   {"label": "Schiebroek", "value": "C77563"},
                   {"label": "Schiedam", "value": "C77564"},
                   {"label": "Schipluiden", "value": "C77565"},
                   {"label": "Schoonhoven", "value": "C77566"},
                   {"label": "Sliedrecht", "value": "C77567"},
                   {"label": "Slikkerveer", "value": "C77568"},
                   {"label": "Spangen", "value": "C77569"},
                   {"label": "Spijkenisse", "value": "C77570"},
                   {"label": "Spoorwijk", "value": "C77571"},
                   {"label": "Statenkwartier", "value": "C77572"},
                   {"label": "Stein", "value": "C77573"},
                   {"label": "Stolwijk", "value": "C77574"},
                   {"label": "Strijen", "value": "C77575"},
                   {"label": "Strijp", "value": "C77576"},
                   {"label": "Terbregge", "value": "C77577"},
                   {"label": "The Hague", "value": "C77578"},
                   {"label": "Tuindorp", "value": "C77579"},
                   {"label": "Valkenburg", "value": "C77580"},
                   {"label": "Vierpolders", "value": "C77581"},
                   {"label": "Vlaardingen", "value": "C77582"},
                   {"label": "Vlietwijk", "value": "C77583"},
                   {"label": "Vogelwijk", "value": "C77584"},
                   {"label": "Vondelwijk", "value": "C77585"},
                   {"label": "Voorburg", "value": "C77586"},
                   {"label": "Voorhout", "value": "C77587"},
                   {"label": "Voorschoten", "value": "C77588"},
                   {"label": "Vreewijk", "value": "C77589"},
                   {"label": "Waarder", "value": "C77590"},
                   {"label": "Waddinxveen", "value": "C77591"},
                   {"label": "Warmond", "value": "C77592"},
                   {"label": "Wassenaar", "value": "C77593"},
                   {"label": "Weerestein", "value": "C77594"},
                   {"label": "Weijpoort", "value": "C77595"},
                   {"label": "Westmaas", "value": "C77596"},
                   {"label": "Woubrugge", "value": "C77597"},
                   {"label": "Ypenburg", "value": "C77598"},
                   {"label": "Zevenhoven", "value": "C77599"},
                   {"label": "Zoetermeer", "value": "C77600"},
                   {"label": "Zoeterwoude-Dorp", "value": "C77601"},
                   {"label": "Zuid-Beijerland", "value": "C77602"},
                   {"label": "Zuidwijk", "value": "C77603"},
                   {"label": "Zwijndrecht", "value": "C77604"}],
 "South Hwanghae Province": [{"label": "Ayang-ni", "value": "C78600"},
                             {"label": "Chaeryong-up", "value": "C78601"},
                             {"label": "Changyon", "value": "C78602"},
                             {"label": "Haeju", "value": "C78603"},
                             {"label": "Kaesong", "value": "C78604"},
                             {"label": "Ongjin", "value": "C78605"},
                             {"label": "Pyoksong-up", "value": "C78606"},
                             {"label": "Yonan-up", "value": "C78607"}],
 "South Jeolla Province": [{"label": "Beolgyo", "value": "C108508"},
                           {"label": "Boseong-gun", "value": "C108509"},
                           {"label": "Damyang-gun", "value": "C108510"},
                           {"label": "Gangjin-gun", "value": "C108511"},
                           {"label": "Goheung-gun", "value": "C108512"},
                           {"label": "Gokseong-gun", "value": "C108513"},
                           {"label": "Gurye-gun", "value": "C108514"},
                           {"label": "Gwangyang", "value": "C108515"},
                           {"label": "Gwangyang-si", "value": "C108516"},
                           {"label": "Haenam", "value": "C108517"},
                           {"label": "Haenam-gun", "value": "C108518"},
                           {"label": "Hampyeong-gun", "value": "C108519"},
                           {"label": "Hwasun", "value": "C108520"},
                           {"label": "Hwasun-gun", "value": "C108521"},
                           {"label": "Jangheung-gun", "value": "C108522"},
                           {"label": "Jangseong-gun", "value": "C108523"},
                           {"label": "Jindo-gun", "value": "C108524"},
                           {"label": "Kurye", "value": "C108525"},
                           {"label": "Kwangyang", "value": "C108526"},
                           {"label": "Mokpo", "value": "C108527"},
                           {"label": "Mokpo-si", "value": "C108528"},
                           {"label": "Muan", "value": "C108529"},
                           {"label": "Muan-gun", "value": "C108530"},
                           {"label": "Naju", "value": "C108531"},
                           {"label": "Naju-si", "value": "C108532"},
                           {"label": "Sinan", "value": "C108533"},
                           {"label": "Sinan-gun", "value": "C108534"},
                           {"label": "Suncheon", "value": "C108535"},
                           {"label": "Suncheon-si", "value": "C108536"},
                           {"label": "Wando-gun", "value": "C108537"},
                           {"label": "Yeongam", "value": "C108538"},
                           {"label": "Yeongam-gun", "value": "C108539"},
                           {"label": "Yeonggwang", "value": "C108540"},
                           {"label": "Yeonggwang-gun", "value": "C108541"},
                           {"label": "Yeosu", "value": "C108542"}],
 "South Karelia": [{"label": "Imatra", "value": "C25105"},
                   {"label": "Joutseno", "value": "C25106"},
                   {"label": "Lappeenranta", "value": "C25107"},
                   {"label": "Lemi", "value": "C25108"},
                   {"label": "Luumaki", "value": "C25109"},
                   {"label": "Nuijamaa", "value": "C25110"},
                   {"label": "Parikkala", "value": "C25111"},
                   {"label": "Rautjarvi", "value": "C25112"},
                   {"label": "Ruokolahti", "value": "C25113"},
                   {"label": "Saari", "value": "C25114"},
                   {"label": "Savitaipale", "value": "C25115"},
                   {"label": "Taipalsaari", "value": "C25116"},
                   {"label": "Ylamaa", "value": "C25117"}],
 "South Khorasan": [{"label": "Birjand", "value": "C51039"},
                    {"label": "Boshruyeh", "value": "C51040"},
                    {"label": "Qaen", "value": "C51041"},
                    {"label": "Shahrestan-e Birjand", "value": "C51042"},
                    {"label": "Shahrestan-e Darmian", "value": "C51043"},
                    {"label": "Shahrestan-e Khusf", "value": "C51044"},
                    {"label": "Shahrestan-e Nehbandan", "value": "C51045"},
                    {"label": "Shahrestan-e Qaen", "value": "C51046"},
                    {"label": "Shahrestan-e Sarayan", "value": "C51047"},
                    {"label": "Shahrestan-e Sarbisheh", "value": "C51048"},
                    {"label": "Shahrestan-e Zirkuh", "value": "C51049"},
                    {"label": "Tabas", "value": "C51050"}],
 "South Kordofan": [{"label": "Abu Jibeha", "value": "C115441"},
                    {"label": "Al Fulah", "value": "C115442"},
                    {"label": "Dilling", "value": "C115443"},
                    {"label": "Kadugli", "value": "C115444"},
                    {"label": "Talodi", "value": "C115445"}],
 "South Pyongan Province": [{"label": "Anju", "value": "C78608"},
                            {"label": "Nampo", "value": "C78609"},
                            {"label": "Pyongsong", "value": "C78610"},
                            {"label": "Sangsong-ni", "value": "C78611"},
                            {"label": "Sil-li", "value": "C78612"},
                            {"label": "Sinanju", "value": "C78613"},
                            {"label": "Sunchon", "value": "C78614"},
                            {"label": "Yonggang-up", "value": "C78615"}],
 "South Sinai": [{"label": "Dahab", "value": "C24357"},
                 {"label": "El-Tor", "value": "C24358"},
                 {"label": "Nuwaybia", "value": "C24359"},
                 {"label": "Saint Catherine", "value": "C24360"},
                 {"label": "Sharm el-Sheikh", "value": "C24361"}],
 "South-East District": [{"label": "Gaborone", "value": "C10188"},
                         {"label": "Janeng", "value": "C10189"},
                         {"label": "Kopong", "value": "C10190"},
                         {"label": "Otse", "value": "C10191"},
                         {"label": "Ramotswa", "value": "C10192"}],
 "Southern District": [{"label": "Kanye", "value": "C10193"},
                       {"label": "Khakhea", "value": "C10194"},
                       {"label": "Mosopa", "value": "C10195"},
                       {"label": "Sekoma", "value": "C10196"},
                       {"label": "En Boqeq", "value": "C51705"},
                       {"label": "Arad", "value": "C51706"},
                       {"label": "Ashdod", "value": "C51707"},
                       {"label": "Ashkelon", "value": "C51708"},
                       {"label": "Beersheba", "value": "C51709"},
                       {"label": "Dimona", "value": "C51710"},
                       {"label": "Eilat", "value": "C51711"},
                       {"label": "Lehavim", "value": "C51712"},
                       {"label": "Midreshet Ben-Gurion", "value": "C51713"},
                       {"label": "Mitzpe Ramon", "value": "C51714"},
                       {"label": "Netivot", "value": "C51715"},
                       {"label": "Ofaqim", "value": "C51716"},
                       {"label": "Qiryat Gat", "value": "C51717"},
                       {"label": "Rahat", "value": "C51718"},
                       {"label": "Sederot", "value": "C51719"},
                       {"label": "Yeroham", "value": "C51720"}],
 "Southern Governorate": [{"label": "Ar Rifa", "value": "C8551"},
                          {"label": "Dar Kulayb", "value": "C8552"},
                          {"label": "Madinat isa", "value": "C8553"}],
 "Southern Highlands Province": [{"label": "Ialibu", "value": "C80466"},
                                 {"label": "Ialibu Pangia", "value": "C80467"},
                                 {"label": "Imbonggu", "value": "C80468"},
                                 {"label": "Kagua Erave", "value": "C80469"},
                                 {"label": "Mendi", "value": "C80470"},
                                 {"label": "Nipa Kutubu", "value": "C80471"}],
 "Southern Nations, Nationalities, and Peoples Region": [{"label": "Alaba ",
                                                          "value": "C24764"},
                                                         {"label": "Arba Minch",
                                                          "value": "C24765"},
                                                         {"label": "areka",
                                                          "value": "C24766"},
                                                         {"label": "Bako",
                                                          "value": "C24767"},
                                                         {"label": "Bench Maji Zone",
                                                          "value": "C24768"},
                                                         {"label": "Boditi",
                                                          "value": "C24769"},
                                                         {"label": "Bonga",
                                                          "value": "C24770"},
                                                         {"label": "Butajira",
                                                          "value": "C24771"},
                                                         {"label": "Dila",
                                                          "value": "C24772"},
                                                         {"label": "Felege Neway",
                                                          "value": "C24773"},
                                                         {"label": "Gedeo Zone",
                                                          "value": "C24774"},
                                                         {"label": "Gidole",
                                                          "value": "C24775"},
                                                         {"label": "Guraghe Zone",
                                                          "value": "C24776"},
                                                         {"label": "Hadiya Zone",
                                                          "value": "C24777"},
                                                         {"label": "Hagere Selam",
                                                          "value": "C24778"},
                                                         {"label": "Hawassa",
                                                          "value": "C24779"},
                                                         {"label": "Hosaina",
                                                          "value": "C24780"},
                                                         {"label": "Jinka",
                                                          "value": "C24781"},
                                                         {"label": "Kolito",
                                                          "value": "C24782"},
                                                         {"label": "Kembata Alaba embaro Zone",
                                                          "value": "C24783"},
                                                         {"label": "Konso",
                                                          "value": "C24784"},
                                                         {"label": "Leku",
                                                          "value": "C24785"},
                                                         {"label": "Lobuni",
                                                          "value": "C24786"},
                                                         {"label": "Mizan Teferi",
                                                          "value": "C24787"},
                                                         {"label": "Sheka Zone",
                                                          "value": "C24788"},
                                                         {"label": "Sidama Zone",
                                                          "value": "C24789"},
                                                         {"label": "Sodo",
                                                          "value": "C24790"},
                                                         {"label": "Tippi",
                                                          "value": "C24791"},
                                                         {"label": "Turmi",
                                                          "value": "C24792"},
                                                         {"label": "Wendo",
                                                          "value": "C24793"},
                                                         {"label": "Wolayita Zone",
                                                          "value": "C24794"},
                                                         {"label": "Yem",
                                                          "value": "C24795"},
                                                         {"label": "Yirga Alem",
                                                          "value": "C24796"}],
 "Southern Ostrobothnia": [{"label": "Ahtari", "value": "C25118"},
                           {"label": "Alaharma", "value": "C25119"},
                           {"label": "Alajarvi", "value": "C25120"},
                           {"label": "Alavus", "value": "C25121"},
                           {"label": "Evijarvi", "value": "C25122"},
                           {"label": "Ilmajoki", "value": "C25123"},
                           {"label": "Isojoki", "value": "C25124"},
                           {"label": "Jalasjarvi", "value": "C25125"},
                           {"label": "Jurva", "value": "C25126"},
                           {"label": "Karijoki", "value": "C25127"},
                           {"label": "Kauhajoki", "value": "C25128"},
                           {"label": "Kauhava", "value": "C25129"},
                           {"label": "Kortesjarvi", "value": "C25130"},
                           {"label": "Kuortane", "value": "C25131"},
                           {"label": "Kurikka", "value": "C25132"},
                           {"label": "Lappajarvi", "value": "C25133"},
                           {"label": "Lapua", "value": "C25134"},
                           {"label": "Lehtimaki", "value": "C25135"},
                           {"label": "Nurmo", "value": "C25136"},
                           {"label": "Seinajoki", "value": "C25137"},
                           {"label": "Soini", "value": "C25138"},
                           {"label": "Teuva", "value": "C25139"},
                           {"label": "Toysa", "value": "C25140"},
                           {"label": "Vimpeli", "value": "C25141"},
                           {"label": "Yliharma", "value": "C25142"},
                           {"label": "Ylistaro", "value": "C25143"}],
 "Southern Peninsula Region": [{"label": "Gardur", "value": "C44861"},
                               {"label": "Grindavik", "value": "C44862"},
                               {"label": "Keflavik", "value": "C44863"},
                               {"label": "Reykjanesbaer", "value": "C44864"},
                               {"label": "Sandgerdi", "value": "C44865"},
                               {"label": "Vogar", "value": "C44866"}],
 "Southern Province": [{"label": "Butare", "value": "C105715"},
                       {"label": "Eglise Catholique, Centrale GIKO",
                        "value": "C105716"},
                       {"label": "Gitarama", "value": "C105717"},
                       {"label": "Nzega", "value": "C105718"},
                       {"label": "Baiima", "value": "C107297"},
                       {"label": "Baoma", "value": "C107298"},
                       {"label": "Bo", "value": "C107299"},
                       {"label": "Bo District", "value": "C107300"},
                       {"label": "Bomi", "value": "C107301"},
                       {"label": "Bonthe", "value": "C107302"},
                       {"label": "Bonthe District", "value": "C107303"},
                       {"label": "Bumpe", "value": "C107304"},
                       {"label": "Foindu", "value": "C107305"},
                       {"label": "Gandorhun", "value": "C107306"},
                       {"label": "Gbewebu", "value": "C107307"},
                       {"label": "Koribundu", "value": "C107308"},
                       {"label": "Largo", "value": "C107309"},
                       {"label": "Mamboma", "value": "C107310"},
                       {"label": "Mogbwemo", "value": "C107311"},
                       {"label": "Moyamba", "value": "C107312"},
                       {"label": "Moyamba District", "value": "C107313"},
                       {"label": "Palima", "value": "C107314"},
                       {"label": "Potoru", "value": "C107315"},
                       {"label": "Pujehun", "value": "C107316"},
                       {"label": "Pujehun District", "value": "C107317"},
                       {"label": "Rotifunk", "value": "C107318"},
                       {"label": "Serabu", "value": "C107319"},
                       {"label": "Sumbuya", "value": "C107320"},
                       {"label": "Tongole", "value": "C107321"},
                       {"label": "Zimmi", "value": "C107322"},
                       {"label": "Ambalangoda", "value": "C115341"},
                       {"label": "Bentota", "value": "C115342"},
                       {"label": "Devinuwara", "value": "C115343"},
                       {"label": "Galle", "value": "C115344"},
                       {"label": "Galle District", "value": "C115345"},
                       {"label": "Hambantota District", "value": "C115346"},
                       {"label": "Hikkaduwa", "value": "C115347"},
                       {"label": "Koggala", "value": "C115348"},
                       {"label": "Matara", "value": "C115349"},
                       {"label": "Matara District", "value": "C115350"},
                       {"label": "Mirissa city", "value": "C115351"},
                       {"label": "Talpe", "value": "C115352"},
                       {"label": "Tangalle", "value": "C115353"},
                       {"label": "Unawatuna", "value": "C115354"},
                       {"label": "Weligama", "value": "C115355"},
                       {"label": "Choma", "value": "C148139"},
                       {"label": "Gwembe", "value": "C148140"},
                       {"label": "Itezhi-Tezhi District", "value": "C148141"},
                       {"label": "Livingstone", "value": "C148142"},
                       {"label": "Maamba", "value": "C148143"},
                       {"label": "Mazabuka", "value": "C148144"},
                       {"label": "Monze", "value": "C148145"},
                       {"label": "Nakambala", "value": "C148146"},
                       {"label": "Namwala", "value": "C148147"},
                       {"label": "Siavonga", "value": "C148148"},
                       {"label": "Siavonga District", "value": "C148149"},
                       {"label": "Sinazongwe", "value": "C148150"}],
 "Southern Red Sea Region": [{"label": "Assab", "value": "C24497"},
                             {"label": "Edd", "value": "C24498"}],
 "Southern Region": [{"label": "asahreppur", "value": "C44867"},
                     {"label": "Blaskogabyggd", "value": "C44868"},
                     {"label": "Floahreppur", "value": "C44869"},
                     {"label": "Grimsnes- og Grafningshreppur",
                      "value": "C44870"},
                     {"label": "Hrunamannahreppur", "value": "C44871"},
                     {"label": "Hveragerdi", "value": "C44872"},
                     {"label": "Myrdalshreppur", "value": "C44873"},
                     {"label": "Selfoss", "value": "C44874"},
                     {"label": "Skaftarhreppur", "value": "C44875"},
                     {"label": "Skeida- og Gnupverjahreppur",
                      "value": "C44876"},
                     {"label": "Vestmannaeyjabaer", "value": "C44877"},
                     {"label": "Vestmannaeyjar", "value": "C44878"},
                     {"label": "Thorlakshofn", "value": "C44879"},
                     {"label": "Balaka", "value": "C65594"},
                     {"label": "Balaka District", "value": "C65595"},
                     {"label": "Blantyre", "value": "C65596"},
                     {"label": "Blantyre District", "value": "C65597"},
                     {"label": "Chikwawa", "value": "C65598"},
                     {"label": "Chikwawa District", "value": "C65599"},
                     {"label": "Chiradzulu", "value": "C65600"},
                     {"label": "Chiradzulu District", "value": "C65601"},
                     {"label": "Liwonde", "value": "C65602"},
                     {"label": "Luchenza", "value": "C65603"},
                     {"label": "Machinga", "value": "C65604"},
                     {"label": "Machinga District", "value": "C65605"},
                     {"label": "Mangochi", "value": "C65606"},
                     {"label": "Mangochi District", "value": "C65607"},
                     {"label": "Monkey Bay", "value": "C65608"},
                     {"label": "Mulanje", "value": "C65609"},
                     {"label": "Mulanje District", "value": "C65610"},
                     {"label": "Mwanza", "value": "C65611"},
                     {"label": "Mwanza District", "value": "C65612"},
                     {"label": "Neno District", "value": "C65613"},
                     {"label": "Nsanje", "value": "C65614"},
                     {"label": "Nsanje District", "value": "C65615"},
                     {"label": "Phalombe", "value": "C65616"},
                     {"label": "Phalombe District", "value": "C65617"},
                     {"label": "Thyolo", "value": "C65618"},
                     {"label": "Thyolo District", "value": "C65619"},
                     {"label": "Zomba", "value": "C65620"},
                     {"label": "Zomba District", "value": "C65621"}],
 "Southern Savonia": [{"label": "Enonkoski", "value": "C25144"},
                      {"label": "Haukivuori", "value": "C25145"},
                      {"label": "Heinavesi", "value": "C25146"},
                      {"label": "Hirvensalmi", "value": "C25147"},
                      {"label": "Jappila", "value": "C25148"},
                      {"label": "Joroinen", "value": "C25149"},
                      {"label": "Juva", "value": "C25150"},
                      {"label": "Kangasniemi", "value": "C25151"},
                      {"label": "Kerimaki", "value": "C25152"},
                      {"label": "Mantyharju", "value": "C25153"},
                      {"label": "Mikkeli", "value": "C25154"},
                      {"label": "Pertunmaa", "value": "C25155"},
                      {"label": "Pieksamaki", "value": "C25156"},
                      {"label": "Punkaharju", "value": "C25157"},
                      {"label": "Puumala", "value": "C25158"},
                      {"label": "Rantasalmi", "value": "C25159"},
                      {"label": "Ristiina", "value": "C25160"},
                      {"label": "Savonlinna", "value": "C25161"},
                      {"label": "Savonranta", "value": "C25162"},
                      {"label": "Sulkava", "value": "C25163"},
                      {"label": "Virtasalmi", "value": "C25164"}],
 "Southland Region": [{"label": "Bluff", "value": "C77828"},
                      {"label": "Gore", "value": "C77829"},
                      {"label": "Invercargill", "value": "C77830"},
                      {"label": "Riverton", "value": "C77831"},
                      {"label": "Southland District", "value": "C77832"},
                      {"label": "Te Anau", "value": "C77833"},
                      {"label": "Winton", "value": "C77834"}],
 "Southwest": [{"label": "Bamusso", "value": "C16673"},
               {"label": "Bekondo", "value": "C16674"},
               {"label": "Buea", "value": "C16675"},
               {"label": "Fako Division", "value": "C16676"},
               {"label": "Fontem", "value": "C16677"},
               {"label": "Kumba", "value": "C16678"},
               {"label": "Lebialem", "value": "C16679"},
               {"label": "Limbe", "value": "C16680"},
               {"label": "Mamfe", "value": "C16681"},
               {"label": "Mundemba", "value": "C16682"},
               {"label": "Mutengene", "value": "C16683"},
               {"label": "Muyuka", "value": "C16684"},
               {"label": "Nguti", "value": "C16685"},
               {"label": "Tiko", "value": "C16686"}],
 "Spanish Wells": [{"label": "Spanish Wells", "value": "C119623"}],
 "Split-Dalmatia": [{"label": "Baska Voda", "value": "C21394"},
                    {"label": "Bol", "value": "C21395"},
                    {"label": "Brela", "value": "C21396"},
                    {"label": "Brnaze", "value": "C21397"},
                    {"label": "Dicmo", "value": "C21398"},
                    {"label": "Donji Vinjani", "value": "C21399"},
                    {"label": "Duce", "value": "C21400"},
                    {"label": "Dugi Rat Opcina", "value": "C21401"},
                    {"label": "Dugopolje", "value": "C21402"},
                    {"label": "Gala", "value": "C21403"},
                    {"label": "Grabovci", "value": "C21404"},
                    {"label": "Grad Hvar", "value": "C21405"},
                    {"label": "Grad Imotski", "value": "C21406"},
                    {"label": "Grad Makarska", "value": "C21407"},
                    {"label": "Grad Omis", "value": "C21408"},
                    {"label": "Grad Sinj", "value": "C21409"},
                    {"label": "Grad Split", "value": "C21410"},
                    {"label": "Grad Supetar", "value": "C21411"},
                    {"label": "Grad Trogir", "value": "C21412"},
                    {"label": "Grad Vis", "value": "C21413"},
                    {"label": "Grad Vrgorac", "value": "C21414"},
                    {"label": "Gradac", "value": "C21415"},
                    {"label": "Greda", "value": "C21416"},
                    {"label": "Grubine", "value": "C21417"},
                    {"label": "Hrvace", "value": "C21418"},
                    {"label": "Hvar", "value": "C21419"},
                    {"label": "Imotski", "value": "C21420"},
                    {"label": "Jelsa", "value": "C21421"},
                    {"label": "Jesenice", "value": "C21422"},
                    {"label": "Kamen", "value": "C21423"},
                    {"label": "Kastel Gomilica", "value": "C21424"},
                    {"label": "Kastel Kambelovac", "value": "C21425"},
                    {"label": "Kastel Luksic", "value": "C21426"},
                    {"label": "Kastel Novi", "value": "C21427"},
                    {"label": "Kastel Stafilic", "value": "C21428"},
                    {"label": "Kastel Stari", "value": "C21429"},
                    {"label": "Kastela", "value": "C21430"},
                    {"label": "Klis", "value": "C21431"},
                    {"label": "Komiza", "value": "C21432"},
                    {"label": "Kosute", "value": "C21433"},
                    {"label": "Lokvicici", "value": "C21434"},
                    {"label": "Lovrec", "value": "C21435"},
                    {"label": "Makarska", "value": "C21436"},
                    {"label": "Milna", "value": "C21437"},
                    {"label": "Mravince", "value": "C21438"},
                    {"label": "Muc", "value": "C21439"},
                    {"label": "Nerezisca", "value": "C21440"},
                    {"label": "Okrug", "value": "C21441"},
                    {"label": "Okrug Gornji", "value": "C21442"},
                    {"label": "Omis", "value": "C21443"},
                    {"label": "Otok", "value": "C21444"},
                    {"label": "Podstrana", "value": "C21445"},
                    {"label": "Postira", "value": "C21446"},
                    {"label": "Prolozac", "value": "C21447"},
                    {"label": "Pucisca", "value": "C21448"},
                    {"label": "Runovic", "value": "C21449"},
                    {"label": "Runovici", "value": "C21450"},
                    {"label": "Seget", "value": "C21451"},
                    {"label": "Seget Vranjica", "value": "C21452"},
                    {"label": "Selca", "value": "C21453"},
                    {"label": "Sinj", "value": "C21454"},
                    {"label": "Slatine", "value": "C21455"},
                    {"label": "Solin", "value": "C21456"},
                    {"label": "Solta", "value": "C21457"},
                    {"label": "Split", "value": "C21458"},
                    {"label": "Srinjine", "value": "C21459"},
                    {"label": "Stari Grad", "value": "C21460"},
                    {"label": "Stobrec", "value": "C21461"},
                    {"label": "Supetar", "value": "C21462"},
                    {"label": "Trilj", "value": "C21463"},
                    {"label": "Trogir", "value": "C21464"},
                    {"label": "Tucepi", "value": "C21465"},
                    {"label": "Turjaci", "value": "C21466"},
                    {"label": "Veliki Drvenik", "value": "C21467"},
                    {"label": "Vis", "value": "C21468"},
                    {"label": "Vranjic", "value": "C21469"},
                    {"label": "Vrgorac", "value": "C21470"},
                    {"label": "Vrlika", "value": "C21471"},
                    {"label": "Zagvozd", "value": "C21472"},
                    {"label": "Zmijavci", "value": "C21473"},
                    {"label": "Zrnovnica", "value": "C21474"}],
 "Sredisce ob Dravi": [{"label": "Sredisce ob Dravi", "value": "C107827"}],
 "St. Gallen": [{"label": "Altstatten", "value": "C117050"},
                {"label": "Amden", "value": "C117051"},
                {"label": "Andwil", "value": "C117052"},
                {"label": "Au", "value": "C117053"},
                {"label": "Bad Ragaz", "value": "C117054"},
                {"label": "Balgach", "value": "C117055"},
                {"label": "Benken", "value": "C117056"},
                {"label": "Bronschhofen", "value": "C117057"},
                {"label": "Buchs", "value": "C117058"},
                {"label": "Butschwil", "value": "C117059"},
                {"label": "Degersheim", "value": "C117060"},
                {"label": "Diepoldsau", "value": "C117061"},
                {"label": "Ebnat-Kappel", "value": "C117062"},
                {"label": "Eggersriet", "value": "C117063"},
                {"label": "Eichberg", "value": "C117064"},
                {"label": "Eschenbach", "value": "C117065"},
                {"label": "Flawil", "value": "C117066"},
                {"label": "Flums", "value": "C117067"},
                {"label": "Gams", "value": "C117068"},
                {"label": "Ganterschwil", "value": "C117069"},
                {"label": "Goldach", "value": "C117070"},
                {"label": "Goldingen", "value": "C117071"},
                {"label": "Gommiswald", "value": "C117072"},
                {"label": "Gossau", "value": "C117073"},
                {"label": "Grabs", "value": "C117074"},
                {"label": "Haag (Rheintal)", "value": "C117075"},
                {"label": "Haggenschwil", "value": "C117076"},
                {"label": "Horn", "value": "C117077"},
                {"label": "Jona", "value": "C117078"},
                {"label": "Jonschwil", "value": "C117079"},
                {"label": "Kaltbrunn", "value": "C117080"},
                {"label": "Kirchberg", "value": "C117081"},
                {"label": "Krummenau", "value": "C117082"},
                {"label": "Lichtensteig", "value": "C117083"},
                {"label": "Lutisburg", "value": "C117084"},
                {"label": "Mogelsberg", "value": "C117085"},
                {"label": "Morschwil", "value": "C117086"},
                {"label": "Mosnang", "value": "C117087"},
                {"label": "Muolen", "value": "C117088"},
                {"label": "Niederburen", "value": "C117089"},
                {"label": "Niederhelfenschwil", "value": "C117090"},
                {"label": "Oberhelfenschwil", "value": "C117091"},
                {"label": "Oberriet", "value": "C117092"},
                {"label": "Oberuzwil", "value": "C117093"},
                {"label": "Quarten", "value": "C117094"},
                {"label": "Rapperswil", "value": "C117095"},
                {"label": "Rebstein", "value": "C117096"},
                {"label": "Rheineck", "value": "C117097"},
                {"label": "Rorschach", "value": "C117098"},
                {"label": "Ruthi", "value": "C117099"},
                {"label": "Sankt Gallen", "value": "C117100"},
                {"label": "Sankt Gallenkappel", "value": "C117101"},
                {"label": "Sankt Margrethen", "value": "C117102"},
                {"label": "Sankt Peterzell", "value": "C117103"},
                {"label": "Sargans", "value": "C117104"},
                {"label": "Schanis", "value": "C117105"},
                {"label": "Schmerikon", "value": "C117106"},
                {"label": "Sennwald", "value": "C117107"},
                {"label": "Sevelen", "value": "C117108"},
                {"label": "Thal", "value": "C117109"},
                {"label": "Uznach", "value": "C117110"},
                {"label": "Uzwil", "value": "C117111"},
                {"label": "Wahlkreis Rheintal", "value": "C117112"},
                {"label": "Wahlkreis Rorschach", "value": "C117113"},
                {"label": "Wahlkreis Sarganserland", "value": "C117114"},
                {"label": "Wahlkreis See-Gaster", "value": "C117115"},
                {"label": "Wahlkreis St. Gallen", "value": "C117116"},
                {"label": "Wahlkreis Toggenburg", "value": "C117117"},
                {"label": "Wahlkreis Werdenberg", "value": "C117118"},
                {"label": "Wahlkreis Wil", "value": "C117119"},
                {"label": "Waldkirch", "value": "C117120"},
                {"label": "Walenstadt", "value": "C117121"},
                {"label": "Wattwil", "value": "C117122"},
                {"label": "Weesen", "value": "C117123"},
                {"label": "Wil", "value": "C117124"},
                {"label": "Wildhaus", "value": "C117125"},
                {"label": "Wittenbach", "value": "C117126"},
                {"label": "Zuzwil", "value": "C117127"}],
 "St. Julians": [{"label": "Saint John", "value": "C65919"}],
 "St. Pauls Bay": [{"label": "Mosta", "value": "C65920"},
                   {"label": "Saint Lucia", "value": "C65921"},
                   {"label": "Santa Lucija", "value": "C65922"}],
 "Stann Creek District": [{"label": "Dangriga", "value": "C9591"},
                          {"label": "Placencia", "value": "C9592"}],
 "Stara Zagora Province": [{"label": "Asen", "value": "C16219"},
                           {"label": "Chirpan", "value": "C16220"},
                           {"label": "Gulubovo", "value": "C16221"},
                           {"label": "Gurkovo", "value": "C16222"},
                           {"label": "Kazanlak", "value": "C16223"},
                           {"label": "Maglizh", "value": "C16224"},
                           {"label": "Nikolaevo", "value": "C16225"},
                           {"label": "Obshtina Bratya Daskalovi",
                            "value": "C16226"},
                           {"label": "Obshtina Chirpan", "value": "C16227"},
                           {"label": "Obshtina Galabovo", "value": "C16228"},
                           {"label": "Obshtina Gurkovo", "value": "C16229"},
                           {"label": "Obshtina Kazanluk", "value": "C16230"},
                           {"label": "Obshtina Maglizh", "value": "C16231"},
                           {"label": "Obshtina Nikolaevo", "value": "C16232"},
                           {"label": "Obshtina Opan", "value": "C16233"},
                           {"label": "Obshtina Pavel Banya", "value": "C16234"},
                           {"label": "Obshtina Radnevo", "value": "C16235"},
                           {"label": "Obshtina Stara Zagora",
                            "value": "C16236"},
                           {"label": "Pavel Banya", "value": "C16237"},
                           {"label": "Radnevo", "value": "C16238"},
                           {"label": "Shipka", "value": "C16239"},
                           {"label": "Stara Zagora", "value": "C16240"}],
 "Staro Nagoricane Municipality": [{"label": "Staro Nagorichane",
                                    "value": "C65498"}],
 "Starse Municipality": [{"label": "Starse", "value": "C107828"}],
 "Stavropol Krai": [{"label": "Achikulak", "value": "C104632"},
                    {"label": "Aleksandriya", "value": "C104633"},
                    {"label": "Aleksandriyskaya", "value": "C104634"},
                    {"label": "Aleksandrovskoye", "value": "C104635"},
                    {"label": "Andzhiyevskiy", "value": "C104636"},
                    {"label": "Arzgir", "value": "C104637"},
                    {"label": "Balakhonovskoye", "value": "C104638"},
                    {"label": "Barsukovskaya", "value": "C104639"},
                    {"label": "Bekeshevskaya", "value": "C104640"},
                    {"label": "Beshpagir", "value": "C104641"},
                    {"label": "Bezopasnoye", "value": "C104642"},
                    {"label": "Blagodarnyy", "value": "C104643"},
                    {"label": "Blagodatnoye", "value": "C104644"},
                    {"label": "Borgustanskaya", "value": "C104645"},
                    {"label": "Budennovsk", "value": "C104646"},
                    {"label": "Burlatskoye", "value": "C104647"},
                    {"label": "Chernolesskoye", "value": "C104648"},
                    {"label": "Divnoye", "value": "C104649"},
                    {"label": "Dmitriyevskoye", "value": "C104650"},
                    {"label": "Donskoye", "value": "C104651"},
                    {"label": "Edissiya", "value": "C104652"},
                    {"label": "Etoka", "value": "C104653"},
                    {"label": "Galyugayevskaya", "value": "C104654"},
                    {"label": "Georgiyevsk", "value": "C104655"},
                    {"label": "Georgiyevskaya", "value": "C104656"},
                    {"label": "Gofitskoye", "value": "C104657"},
                    {"label": "Goryachevodskiy", "value": "C104658"},
                    {"label": "Grazhdanskoye", "value": "C104659"},
                    {"label": "Grecheskoye", "value": "C104660"},
                    {"label": "Grigoropolisskaya", "value": "C104661"},
                    {"label": "Inozemtsevo", "value": "C104662"},
                    {"label": "Ipatovo", "value": "C104663"},
                    {"label": "Irgakly", "value": "C104664"},
                    {"label": "Ivanovskoye", "value": "C104665"},
                    {"label": "Izobilnyy", "value": "C104666"},
                    {"label": "Kalinovskoye", "value": "C104667"},
                    {"label": "Kangly", "value": "C104668"},
                    {"label": "Kara-Tyube", "value": "C104669"},
                    {"label": "Kayasula", "value": "C104670"},
                    {"label": "Kevsala", "value": "C104671"},
                    {"label": "Kislovodsk", "value": "C104672"},
                    {"label": "Kochubeyevskoye", "value": "C104673"},
                    {"label": "Komsomolets", "value": "C104674"},
                    {"label": "Konstantinovskaya", "value": "C104675"},
                    {"label": "Konstantinovskoye", "value": "C104676"},
                    {"label": "Krasnogvardeyskoye", "value": "C104677"},
                    {"label": "Krasnokumskoye", "value": "C104678"},
                    {"label": "Kruglolesskoye", "value": "C104679"},
                    {"label": "Kursavka", "value": "C104680"},
                    {"label": "Kurskaya", "value": "C104681"},
                    {"label": "Ladovskaya Balka", "value": "C104682"},
                    {"label": "Lermontov", "value": "C104683"},
                    {"label": "Letnyaya Stavka", "value": "C104684"},
                    {"label": "Levokumka", "value": "C104685"},
                    {"label": "Levokumskoye", "value": "C104686"},
                    {"label": "Lysogorskaya", "value": "C104687"},
                    {"label": "Mikhaylovsk", "value": "C104688"},
                    {"label": "Mineralnye Vody", "value": "C104689"},
                    {"label": "Moskovskoye", "value": "C104690"},
                    {"label": "Nadezhda", "value": "C104691"},
                    {"label": "Nagutskoye", "value": "C104692"},
                    {"label": "Neftekumsk", "value": "C104693"},
                    {"label": "Nevinnomyssk", "value": "C104694"},
                    {"label": "Nezhinskiy", "value": "C104695"},
                    {"label": "Nezlobnaya", "value": "C104696"},
                    {"label": "Niny", "value": "C104697"},
                    {"label": "Novoaleksandrovsk", "value": "C104698"},
                    {"label": "Novoblagodarnoye", "value": "C104699"},
                    {"label": "Novopavlovsk", "value": "C104700"},
                    {"label": "Novoselitskoye", "value": "C104701"},
                    {"label": "Novoterskiy", "value": "C104702"},
                    {"label": "Novotroitskaya", "value": "C104703"},
                    {"label": "Novozavedennoye", "value": "C104704"},
                    {"label": "Orlovka", "value": "C104705"},
                    {"label": "Otkaznoye", "value": "C104706"},
                    {"label": "Ovoshchi", "value": "C104707"},
                    {"label": "Pelagiada", "value": "C104708"},
                    {"label": "Podgornaya", "value": "C104709"},
                    {"label": "Podkumskiy", "value": "C104710"},
                    {"label": "Pokoynoye", "value": "C104711"},
                    {"label": "Praskoveya", "value": "C104712"},
                    {"label": "Pravokumskoye", "value": "C104713"},
                    {"label": "Pregradnoye", "value": "C104714"},
                    {"label": "Pyatigorsk", "value": "C104715"},
                    {"label": "Pyatigorskiy", "value": "C104716"},
                    {"label": "Rasshevatskaya", "value": "C104717"},
                    {"label": "Ryzdvyanyy", "value": "C104718"},
                    {"label": "Severnoye", "value": "C104719"},
                    {"label": "Soldato-Aleksandrovskoye", "value": "C104720"},
                    {"label": "Solomenskoye", "value": "C104721"},
                    {"label": "Soluno-Dmitriyevskoye", "value": "C104722"},
                    {"label": "Sotnikovskoye", "value": "C104723"},
                    {"label": "Sovetskaya", "value": "C104724"},
                    {"label": "Spitsevka", "value": "C104725"},
                    {"label": "Starodubskoye", "value": "C104726"},
                    {"label": "Staropavlovskaya", "value": "C104727"},
                    {"label": "Stavropol", "value": "C104728"},
                    {"label": "Stepnoye", "value": "C104729"},
                    {"label": "Sukhaya Buyvola", "value": "C104730"},
                    {"label": "Suvorovskaya", "value": "C104731"},
                    {"label": "Svetlograd", "value": "C104732"},
                    {"label": "Svobody", "value": "C104733"},
                    {"label": "Tatarka", "value": "C104734"},
                    {"label": "Temizhbekskaya", "value": "C104735"},
                    {"label": "Tishchenskoye", "value": "C104736"},
                    {"label": "Trunovskoye", "value": "C104737"},
                    {"label": "Urozhaynoye", "value": "C104738"},
                    {"label": "Vinsady", "value": "C104739"},
                    {"label": "Vostochny", "value": "C104740"},
                    {"label": "Vysotskoye", "value": "C104741"},
                    {"label": "Yasnaya Polyana", "value": "C104742"},
                    {"label": "Yelizavetinskoye", "value": "C104743"},
                    {"label": "Yessentuki", "value": "C104744"},
                    {"label": "Yessentukskaya", "value": "C104745"},
                    {"label": "Yutsa", "value": "C104746"},
                    {"label": "Zaterechnyy", "value": "C104747"},
                    {"label": "Zavetnoye", "value": "C104748"},
                    {"label": "Zelenokumsk", "value": "C104749"},
                    {"label": "Zheleznovodsk", "value": "C104750"},
                    {"label": "Zmeyka", "value": "C104751"}],
 "Stefan Voda District": [{"label": "Stefan Voda", "value": "C75537"}],
 "Stip Municipality": [{"label": "Shtip", "value": "C65499"}],
 "Stockholm County": [{"label": "Akalla", "value": "C115950"},
                      {"label": "Akersberga", "value": "C115951"},
                      {"label": "Alby", "value": "C115952"},
                      {"label": "Almsta", "value": "C115953"},
                      {"label": "Alta", "value": "C115954"},
                      {"label": "Arno", "value": "C115955"},
                      {"label": "Arsta", "value": "C115956"},
                      {"label": "Bergshamra", "value": "C115957"},
                      {"label": "Bollmora", "value": "C115958"},
                      {"label": "Boo", "value": "C115959"},
                      {"label": "Brevik", "value": "C115960"},
                      {"label": "Bro", "value": "C115961"},
                      {"label": "Bromma", "value": "C115962"},
                      {"label": "Brunn", "value": "C115963"},
                      {"label": "Brunna", "value": "C115964"},
                      {"label": "Dalaro", "value": "C115965"},
                      {"label": "Djuro", "value": "C115966"},
                      {"label": "Djursholm", "value": "C115967"},
                      {"label": "Ekero", "value": "C115968"},
                      {"label": "Enebyberg", "value": "C115969"},
                      {"label": "Eriksberg", "value": "C115970"},
                      {"label": "Fisksatra", "value": "C115971"},
                      {"label": "Fittja", "value": "C115972"},
                      {"label": "Gamla Stan", "value": "C115973"},
                      {"label": "Gustavsberg", "value": "C115974"},
                      {"label": "Hallstavik", "value": "C115975"},
                      {"label": "Handen", "value": "C115976"},
                      {"label": "Haninge", "value": "C115977"},
                      {"label": "Hemmesta", "value": "C115978"},
                      {"label": "Holo", "value": "C115979"},
                      {"label": "Huddinge", "value": "C115980"},
                      {"label": "Jakobsberg", "value": "C115981"},
                      {"label": "Jarna", "value": "C115982"},
                      {"label": "Jordbro", "value": "C115983"},
                      {"label": "Kista", "value": "C115984"},
                      {"label": "Kopparmora", "value": "C115985"},
                      {"label": "Kummelnas", "value": "C115986"},
                      {"label": "Kungsangen", "value": "C115987"},
                      {"label": "Kungsholmen", "value": "C115988"},
                      {"label": "Langvik", "value": "C115989"},
                      {"label": "Lidingo", "value": "C115990"},
                      {"label": "Lindholmen", "value": "C115991"},
                      {"label": "Marsta", "value": "C115992"},
                      {"label": "Molnbo", "value": "C115993"},
                      {"label": "Mortnas", "value": "C115994"},
                      {"label": "Nacka", "value": "C115995"},
                      {"label": "Norrtalje", "value": "C115996"},
                      {"label": "Nykvarn", "value": "C115997"},
                      {"label": "Nynashamn", "value": "C115998"},
                      {"label": "Osmo", "value": "C115999"},
                      {"label": "Ostermalm", "value": "C116000"},
                      {"label": "Pershagen", "value": "C116001"},
                      {"label": "Rasunda", "value": "C116002"},
                      {"label": "Resaro", "value": "C116003"},
                      {"label": "Rimbo", "value": "C116004"},
                      {"label": "Rindo", "value": "C116005"},
                      {"label": "Ronninge", "value": "C116006"},
                      {"label": "Rosersberg", "value": "C116007"},
                      {"label": "Saltsjobaden", "value": "C116008"},
                      {"label": "Segeltorp", "value": "C116009"},
                      {"label": "Sigtuna", "value": "C116010"},
                      {"label": "Skanninge", "value": "C116011"},
                      {"label": "Sodermalm", "value": "C116012"},
                      {"label": "Sodertalje", "value": "C116013"},
                      {"label": "Sollentuna", "value": "C116014"},
                      {"label": "Solna", "value": "C116015"},
                      {"label": "Stavsnas", "value": "C116016"},
                      {"label": "Stenhamra", "value": "C116017"},
                      {"label": "Stockholm", "value": "C116018"},
                      {"label": "Stromma", "value": "C116019"},
                      {"label": "Sundbyberg", "value": "C116020"},
                      {"label": "Tensta", "value": "C116021"},
                      {"label": "Tullinge", "value": "C116022"},
                      {"label": "Tumba", "value": "C116023"},
                      {"label": "Upplands Vasby", "value": "C116024"},
                      {"label": "Vallentuna", "value": "C116025"},
                      {"label": "Varby", "value": "C116026"},
                      {"label": "Varsta", "value": "C116027"},
                      {"label": "Vasastan", "value": "C116028"},
                      {"label": "Vaxholm", "value": "C116029"}],
 "Stopini Municipality": [{"label": "Ulbroka", "value": "C64942"}],
 "Store Municipality": [{"label": "Store", "value": "C107829"}],
 "Straseni District": [{"label": "Bucovat", "value": "C75538"},
                       {"label": "Straseni", "value": "C75539"}],
 "Straza Municipality": [{"label": "Straza", "value": "C107830"}],
 "Stredocesky kraj": [{"label": "Bakov nad Jizerou", "value": "C22925"},
                      {"label": "Bela pod Bezdezem", "value": "C22926"},
                      {"label": "Benatky nad Jizerou", "value": "C22927"},
                      {"label": "Benesov", "value": "C22928"},
                      {"label": "Beroun", "value": "C22929"},
                      {"label": "Bohutin", "value": "C22930"},
                      {"label": "Brandys nad Labem-Stara Boleslav",
                       "value": "C22931"},
                      {"label": "Brandysek", "value": "C22932"},
                      {"label": "Breznice", "value": "C22933"},
                      {"label": "Bustehrad", "value": "C22934"},
                      {"label": "Bysice", "value": "C22935"},
                      {"label": "Bystrice", "value": "C22936"},
                      {"label": "Caslav", "value": "C22937"},
                      {"label": "Cechtice", "value": "C22938"},
                      {"label": "Celakovice", "value": "C22939"},
                      {"label": "Cercany", "value": "C22940"},
                      {"label": "Cerhenice", "value": "C22941"},
                      {"label": "Cernosice", "value": "C22942"},
                      {"label": "Cervene Pecky", "value": "C22943"},
                      {"label": "Cesky Brod", "value": "C22944"},
                      {"label": "Chynava", "value": "C22945"},
                      {"label": "Cirkvice", "value": "C22946"},
                      {"label": "Citov", "value": "C22947"},
                      {"label": "Davle", "value": "C22948"},
                      {"label": "Divisov", "value": "C22949"},
                      {"label": "Dobrichovice", "value": "C22950"},
                      {"label": "Dobris", "value": "C22951"},
                      {"label": "Dobrovice", "value": "C22952"},
                      {"label": "Doksy", "value": "C22953"},
                      {"label": "Dolni Berkovice", "value": "C22954"},
                      {"label": "Dolni Bousov", "value": "C22955"},
                      {"label": "Dolni Brezany", "value": "C22956"},
                      {"label": "Horni Pocaply", "value": "C22957"},
                      {"label": "Horomerice", "value": "C22958"},
                      {"label": "Horovice", "value": "C22959"},
                      {"label": "Hostivice", "value": "C22960"},
                      {"label": "Hostomice", "value": "C22961"},
                      {"label": "Hovorcovice", "value": "C22962"},
                      {"label": "Hradistko", "value": "C22963"},
                      {"label": "Hrebec", "value": "C22964"},
                      {"label": "Hudlice", "value": "C22965"},
                      {"label": "Hyskov", "value": "C22966"},
                      {"label": "Jenec", "value": "C22967"},
                      {"label": "Jesenice", "value": "C22968"},
                      {"label": "Jilove u Prahy", "value": "C22969"},
                      {"label": "Jince", "value": "C22970"},
                      {"label": "Jirny", "value": "C22971"},
                      {"label": "Kacice", "value": "C22972"},
                      {"label": "Kamenice", "value": "C22973"},
                      {"label": "Kamenne Zehrovice", "value": "C22974"},
                      {"label": "Kamenny Privoz", "value": "C22975"},
                      {"label": "Kladno", "value": "C22976"},
                      {"label": "Klecany", "value": "C22977"},
                      {"label": "Klobuky", "value": "C22978"},
                      {"label": "Knezmost", "value": "C22979"},
                      {"label": "Kolin", "value": "C22980"},
                      {"label": "Komarov", "value": "C22981"},
                      {"label": "Kosmonosy", "value": "C22982"},
                      {"label": "Kosova Hora", "value": "C22983"},
                      {"label": "Kostelec nad Cernymi Lesy", "value": "C22984"},
                      {"label": "Kostelec nad Labem", "value": "C22985"},
                      {"label": "Kourim", "value": "C22986"},
                      {"label": "Kralupy nad Vltavou", "value": "C22987"},
                      {"label": "Kraluv Dvur", "value": "C22988"},
                      {"label": "Krasna Hora nad Vltavou", "value": "C22989"},
                      {"label": "Krinec", "value": "C22990"},
                      {"label": "Kutna Hora", "value": "C22991"},
                      {"label": "Lany", "value": "C22992"},
                      {"label": "Libcice nad Vltavou", "value": "C22993"},
                      {"label": "Libeznice", "value": "C22994"},
                      {"label": "Libice nad Cidlinou", "value": "C22995"},
                      {"label": "Libis", "value": "C22996"},
                      {"label": "Libusin", "value": "C22997"},
                      {"label": "Lochovice", "value": "C22998"},
                      {"label": "Lodenice", "value": "C22999"},
                      {"label": "Loucen", "value": "C23000"},
                      {"label": "Lustenice", "value": "C23001"},
                      {"label": "Luzec nad Vltavou", "value": "C23002"},
                      {"label": "Luzna", "value": "C23003"},
                      {"label": "Lysa nad Labem", "value": "C23004"},
                      {"label": "Melnik", "value": "C23005"},
                      {"label": "Mesice", "value": "C23006"},
                      {"label": "Mestec Kralove", "value": "C23007"},
                      {"label": "Milin", "value": "C23008"},
                      {"label": "Milovice", "value": "C23009"},
                      {"label": "Mlada Boleslav", "value": "C23010"},
                      {"label": "Mnichovice", "value": "C23011"},
                      {"label": "Mnichovo Hradiste", "value": "C23012"},
                      {"label": "Mnisek pod Brdy", "value": "C23013"},
                      {"label": "Mseno", "value": "C23014"},
                      {"label": "Mukarov", "value": "C23015"},
                      {"label": "Naceradec", "value": "C23016"},
                      {"label": "Nehvizdy", "value": "C23017"},
                      {"label": "Nelahozeves", "value": "C23018"},
                      {"label": "Neratovice", "value": "C23019"},
                      {"label": "Netvorice", "value": "C23020"},
                      {"label": "Neveklov", "value": "C23021"},
                      {"label": "Nizbor", "value": "C23022"},
                      {"label": "Nove Straseci", "value": "C23023"},
                      {"label": "Novy Knin", "value": "C23024"},
                      {"label": "Nymburk", "value": "C23025"},
                      {"label": "Obecnice", "value": "C23026"},
                      {"label": "Odolena Voda", "value": "C23027"},
                      {"label": "Okres Benesov", "value": "C23028"},
                      {"label": "Okres Beroun", "value": "C23029"},
                      {"label": "Okres Kladno", "value": "C23030"},
                      {"label": "Okres Kolin", "value": "C23031"},
                      {"label": "Okres Kutna Hora", "value": "C23032"},
                      {"label": "Okres Melnik", "value": "C23033"},
                      {"label": "Okres Mlada Boleslav", "value": "C23034"},
                      {"label": "Okres Nymburk", "value": "C23035"},
                      {"label": "Okres Praha-vychod", "value": "C23036"},
                      {"label": "Okres Praha-zapad", "value": "C23037"},
                      {"label": "Okres Pribram", "value": "C23038"},
                      {"label": "Okres Rakovnik", "value": "C23039"},
                      {"label": "Ondrejov", "value": "C23040"},
                      {"label": "Pavlikov", "value": "C23041"},
                      {"label": "Pchery", "value": "C23042"},
                      {"label": "Pecky", "value": "C23043"},
                      {"label": "Petrovice", "value": "C23044"},
                      {"label": "Planany", "value": "C23045"},
                      {"label": "Podebrady", "value": "C23046"},
                      {"label": "Poricany", "value": "C23047"},
                      {"label": "Porici nad Sazavou", "value": "C23048"},
                      {"label": "Postupice", "value": "C23049"},
                      {"label": "Prerov nad Labem", "value": "C23050"},
                      {"label": "Pribram", "value": "C23051"},
                      {"label": "Pruhonice", "value": "C23052"},
                      {"label": "Psary", "value": "C23053"},
                      {"label": "Pysely", "value": "C23054"},
                      {"label": "Rakovnik", "value": "C23055"},
                      {"label": "Revnice", "value": "C23056"},
                      {"label": "Revnicov", "value": "C23057"},
                      {"label": "Rez", "value": "C23058"},
                      {"label": "Ricany", "value": "C23059"},
                      {"label": "Rozdalovice", "value": "C23060"},
                      {"label": "Rozmital pod Tremsinem", "value": "C23061"},
                      {"label": "Roztoky", "value": "C23062"},
                      {"label": "Rudna", "value": "C23063"},
                      {"label": "Sadska", "value": "C23064"},
                      {"label": "Sazava", "value": "C23065"},
                      {"label": "Sedlcany", "value": "C23066"},
                      {"label": "Sedlec", "value": "C23067"},
                      {"label": "Sestajovice", "value": "C23068"},
                      {"label": "Slany", "value": "C23069"},
                      {"label": "Smecno", "value": "C23070"},
                      {"label": "Stara Hut", "value": "C23071"},
                      {"label": "Stary Kolin", "value": "C23072"},
                      {"label": "Stechovice", "value": "C23073"},
                      {"label": "Stochov", "value": "C23074"},
                      {"label": "Strancice", "value": "C23075"},
                      {"label": "Suchdol", "value": "C23076"},
                      {"label": "Tisice", "value": "C23077"},
                      {"label": "Trebotov", "value": "C23078"},
                      {"label": "Trhovy Stepanov", "value": "C23079"},
                      {"label": "Tuchlovice", "value": "C23080"},
                      {"label": "Tuchomerice", "value": "C23081"},
                      {"label": "Tynec nad Labem", "value": "C23082"},
                      {"label": "Tynec nad Sazavou", "value": "C23083"},
                      {"label": "Uhlirske Janovice", "value": "C23084"},
                      {"label": "Unhost", "value": "C23085"},
                      {"label": "Uvaly", "value": "C23086"},
                      {"label": "Velim", "value": "C23087"},
                      {"label": "Velka Dobra", "value": "C23088"},
                      {"label": "Velke Popovice", "value": "C23089"},
                      {"label": "Velke Prilepy", "value": "C23090"},
                      {"label": "Velky Osek", "value": "C23091"},
                      {"label": "Veltruby", "value": "C23092"},
                      {"label": "Veltrusy", "value": "C23093"},
                      {"label": "Velvary", "value": "C23094"},
                      {"label": "Vestec", "value": "C23095"},
                      {"label": "Vinarice", "value": "C23096"},
                      {"label": "Vlasim", "value": "C23097"},
                      {"label": "Votice", "value": "C23098"},
                      {"label": "Vrane nad Vltavou", "value": "C23099"},
                      {"label": "Vrdy", "value": "C23100"},
                      {"label": "Vsenory", "value": "C23101"},
                      {"label": "Vsetaty", "value": "C23102"},
                      {"label": "Zajecov", "value": "C23103"},
                      {"label": "Zasmuky", "value": "C23104"},
                      {"label": "Zbraslavice", "value": "C23105"},
                      {"label": "Zdar", "value": "C23106"},
                      {"label": "Zdiby", "value": "C23107"},
                      {"label": "Zdice", "value": "C23108"},
                      {"label": "Zebrak", "value": "C23109"},
                      {"label": "Zelenec", "value": "C23110"},
                      {"label": "Zizelice", "value": "C23111"},
                      {"label": "Zleby", "value": "C23112"},
                      {"label": "Zlonice", "value": "C23113"},
                      {"label": "Zruc nad Sazavou", "value": "C23114"},
                      {"label": "Zvole", "value": "C23115"}],
 "Strenci Municipality": [{"label": "Seda", "value": "C64943"},
                          {"label": "Strenci", "value": "C64944"}],
 "Struga Municipality": [{"label": "Delogozda", "value": "C65500"},
                         {"label": "Labunista", "value": "C65501"},
                         {"label": "Lukovo", "value": "C65502"},
                         {"label": "Mislesevo", "value": "C65503"},
                         {"label": "Oktisi", "value": "C65504"},
                         {"label": "Radolista", "value": "C65505"},
                         {"label": "Struga", "value": "C65506"},
                         {"label": "Velesta", "value": "C65507"}],
 "Strumica Municipality": [{"label": "Kuklis", "value": "C65508"},
                           {"label": "Murtino", "value": "C65509"},
                           {"label": "Strumica", "value": "C65510"},
                           {"label": "Veljusa", "value": "C65511"}],
 "Studenicani Municipality": [{"label": "Batinci", "value": "C65512"},
                              {"label": "Dolno Kolicani", "value": "C65513"},
                              {"label": "Morani", "value": "C65514"},
                              {"label": "Studenicane", "value": "C65515"}],
 "Stung Treng": [{"label": "Srok Sresen", "value": "C16555"},
                 {"label": "Stueng Traeng", "value": "C16556"},
                 {"label": "Stung Treng", "value": "C16557"}],
 "Styria": [{"label": "Abtissendorf", "value": "C7120"},
            {"label": "Admont", "value": "C7121"},
            {"label": "Aflenz Kurort", "value": "C7122"},
            {"label": "Aibl", "value": "C7123"},
            {"label": "Aigen im Ennstal", "value": "C7124"},
            {"label": "Albersdorf", "value": "C7125"},
            {"label": "Allerheiligen bei Wildon", "value": "C7126"},
            {"label": "Allerheiligen im Murztal", "value": "C7127"},
            {"label": "Altaussee", "value": "C7128"},
            {"label": "Altenmarkt bei Furstenfeld", "value": "C7129"},
            {"label": "Altenmarkt bei Sankt Gallen", "value": "C7130"},
            {"label": "Andritz", "value": "C7131"},
            {"label": "Anger", "value": "C7132"},
            {"label": "Apfelberg", "value": "C7133"},
            {"label": "Ardning", "value": "C7134"},
            {"label": "Arnfels", "value": "C7135"},
            {"label": "Arzberg", "value": "C7136"},
            {"label": "Attendorf", "value": "C7137"},
            {"label": "Auersbach", "value": "C7138"},
            {"label": "Bad Aussee", "value": "C7139"},
            {"label": "Bad Blumau", "value": "C7140"},
            {"label": "Bad Gams", "value": "C7141"},
            {"label": "Bad Gleichenberg", "value": "C7142"},
            {"label": "Bad Radkersburg", "value": "C7143"},
            {"label": "Bad Waltersdorf", "value": "C7144"},
            {"label": "Baierdorf-Umgebung", "value": "C7145"},
            {"label": "Bairisch Kolldorf", "value": "C7146"},
            {"label": "Barnbach", "value": "C7147"},
            {"label": "Baumgarten bei Gnas", "value": "C7148"},
            {"label": "Berndorf", "value": "C7149"},
            {"label": "Bierbaum am Auersbach", "value": "C7150"},
            {"label": "Birkfeld", "value": "C7151"},
            {"label": "Blaindorf", "value": "C7152"},
            {"label": "Breitenfeld am Tannenriegel", "value": "C7153"},
            {"label": "Breitenfeld an der Rittschein", "value": "C7154"},
            {"label": "Bretstein", "value": "C7155"},
            {"label": "Bruck an der Mur", "value": "C7156"},
            {"label": "Brunn", "value": "C7157"},
            {"label": "Burgau", "value": "C7158"},
            {"label": "Dechantskirchen", "value": "C7159"},
            {"label": "Deuchendorf", "value": "C7160"},
            {"label": "Deutsch Goritz", "value": "C7161"},
            {"label": "Deutschfeistritz", "value": "C7162"},
            {"label": "Deutschlandsberg", "value": "C7163"},
            {"label": "Diemlach", "value": "C7164"},
            {"label": "Dienersdorf", "value": "C7165"},
            {"label": "Dietersdorf", "value": "C7166"},
            {"label": "Dietersdorf am Gnasbach", "value": "C7167"},
            {"label": "Donawitz", "value": "C7168"},
            {"label": "Donnersbach", "value": "C7169"},
            {"label": "Durnstein in der Steiermark", "value": "C7170"},
            {"label": "Ebersdorf", "value": "C7171"},
            {"label": "Edelsbach bei Feldbach", "value": "C7172"},
            {"label": "Edelschrott", "value": "C7173"},
            {"label": "Edelsgrub", "value": "C7174"},
            {"label": "Edelstauden", "value": "C7175"},
            {"label": "Eggenberg", "value": "C7176"},
            {"label": "Eggersdorf bei Graz", "value": "C7177"},
            {"label": "Ehrenhausen", "value": "C7178"},
            {"label": "Eibiswald", "value": "C7179"},
            {"label": "Eichberg", "value": "C7180"},
            {"label": "Eichfeld", "value": "C7181"},
            {"label": "Eisbach", "value": "C7182"},
            {"label": "Eisenerz", "value": "C7183"},
            {"label": "Empersdorf", "value": "C7184"},
            {"label": "Eppenstein", "value": "C7185"},
            {"label": "Falkenburg", "value": "C7186"},
            {"label": "Farrach", "value": "C7187"},
            {"label": "Fehring", "value": "C7188"},
            {"label": "Feistritz bei Knittelfeld", "value": "C7189"},
            {"label": "Feldbach", "value": "C7190"},
            {"label": "Feldkirchen bei Graz", "value": "C7191"},
            {"label": "Fernitz", "value": "C7192"},
            {"label": "Fischbach", "value": "C7193"},
            {"label": "Fladnitz im Raabtal", "value": "C7194"},
            {"label": "Flatschach", "value": "C7195"},
            {"label": "Floing", "value": "C7196"},
            {"label": "Fohnsdorf", "value": "C7197"},
            {"label": "Frannach", "value": "C7198"},
            {"label": "Frauenberg", "value": "C7199"},
            {"label": "Freidorf an der Lassnitz", "value": "C7200"},
            {"label": "Freiland bei Deutschlandsberg", "value": "C7201"},
            {"label": "Friedberg", "value": "C7202"},
            {"label": "Frohnleiten", "value": "C7203"},
            {"label": "Furstenfeld", "value": "C7204"},
            {"label": "Gaal", "value": "C7205"},
            {"label": "Gabersdorf", "value": "C7206"},
            {"label": "Gallmannsegg", "value": "C7207"},
            {"label": "Gamlitz", "value": "C7208"},
            {"label": "Gams bei Hieflau", "value": "C7209"},
            {"label": "Ganz", "value": "C7210"},
            {"label": "Garanas", "value": "C7211"},
            {"label": "Gasen", "value": "C7212"},
            {"label": "Geidorf", "value": "C7213"},
            {"label": "Geistthal", "value": "C7214"},
            {"label": "Gersdorf an der Feistritz", "value": "C7215"},
            {"label": "Gleinstatten", "value": "C7216"},
            {"label": "Gleisdorf", "value": "C7217"},
            {"label": "Glojach", "value": "C7218"},
            {"label": "Gnas", "value": "C7219"},
            {"label": "Gniebing", "value": "C7220"},
            {"label": "Gosdorf", "value": "C7221"},
            {"label": "Goss", "value": "C7222"},
            {"label": "Gossenberg", "value": "C7223"},
            {"label": "Gossendorf", "value": "C7224"},
            {"label": "Gosting", "value": "C7225"},
            {"label": "Gottelsberg", "value": "C7226"},
            {"label": "Grabersdorf", "value": "C7227"},
            {"label": "Grafendorf bei Hartberg", "value": "C7228"},
            {"label": "Gralla", "value": "C7229"},
            {"label": "Grambach", "value": "C7230"},
            {"label": "Gratkorn", "value": "C7231"},
            {"label": "Gratwein", "value": "C7232"},
            {"label": "Graz", "value": "C7233"},
            {"label": "Graz Stadt", "value": "C7234"},
            {"label": "Greisdorf", "value": "C7235"},
            {"label": "Gressenberg", "value": "C7236"},
            {"label": "Gries", "value": "C7237"},
            {"label": "Grobming", "value": "C7238"},
            {"label": "Gross Sankt Florian", "value": "C7239"},
            {"label": "Grossklein", "value": "C7240"},
            {"label": "Grosslobming", "value": "C7241"},
            {"label": "Grosssolk", "value": "C7242"},
            {"label": "Grosssulz", "value": "C7243"},
            {"label": "Gundersdorf", "value": "C7244"},
            {"label": "Hafendorf", "value": "C7245"},
            {"label": "Hainersdorf", "value": "C7246"},
            {"label": "Hainsdorf im Schwarzautal", "value": "C7247"},
            {"label": "Halbenrain", "value": "C7248"},
            {"label": "Hall bei Admont", "value": "C7249"},
            {"label": "Hart bei Graz", "value": "C7250"},
            {"label": "Hartberg", "value": "C7251"},
            {"label": "Hartl", "value": "C7252"},
            {"label": "Hartmannsdorf", "value": "C7253"},
            {"label": "Hatzendorf", "value": "C7254"},
            {"label": "Haus", "value": "C7255"},
            {"label": "Haus im Ennstal", "value": "C7256"},
            {"label": "Hausmannstatten", "value": "C7257"},
            {"label": "Heiligenkreuz am Waasen", "value": "C7258"},
            {"label": "Heimschuh", "value": "C7259"},
            {"label": "Hengsberg", "value": "C7260"},
            {"label": "Hetzendorf", "value": "C7261"},
            {"label": "Hieflau", "value": "C7262"},
            {"label": "Hinterberg", "value": "C7263"},
            {"label": "Hirnsdorf", "value": "C7264"},
            {"label": "Hitzendorf", "value": "C7265"},
            {"label": "Hochtregist", "value": "C7266"},
            {"label": "Hof", "value": "C7267"},
            {"label": "Hof bei Straden", "value": "C7268"},
            {"label": "Hofstatten an der Raab", "value": "C7269"},
            {"label": "Hohenau an der Raab", "value": "C7270"},
            {"label": "Hohentauern", "value": "C7271"},
            {"label": "Hollenegg", "value": "C7272"},
            {"label": "Honigsberg", "value": "C7273"},
            {"label": "Ilz", "value": "C7274"},
            {"label": "Innere Stadt", "value": "C7275"},
            {"label": "Irdning", "value": "C7276"},
            {"label": "Jagerberg", "value": "C7277"},
            {"label": "Jakomini", "value": "C7278"},
            {"label": "Johnsbach", "value": "C7279"},
            {"label": "Judenburg", "value": "C7280"},
            {"label": "Judendorf", "value": "C7281"},
            {"label": "Kaibing", "value": "C7282"},
            {"label": "Kainach bei Voitsberg", "value": "C7283"},
            {"label": "Kainbach", "value": "C7284"},
            {"label": "Kaindorf", "value": "C7285"},
            {"label": "Kaindorf an der Sulm", "value": "C7286"},
            {"label": "Kalsdorf bei Graz", "value": "C7287"},
            {"label": "Kalwang", "value": "C7288"},
            {"label": "Kammern im Liesingtal", "value": "C7289"},
            {"label": "Kapellen", "value": "C7290"},
            {"label": "Kapfenberg", "value": "C7291"},
            {"label": "Kapfenstein", "value": "C7292"},
            {"label": "Kindberg", "value": "C7293"},
            {"label": "Kirchbach in Steiermark", "value": "C7294"},
            {"label": "Kirchberg an der Raab", "value": "C7295"},
            {"label": "Kirchenviertel", "value": "C7296"},
            {"label": "Kitzeck im Sausal", "value": "C7297"},
            {"label": "Kleinlobming", "value": "C7298"},
            {"label": "Kleinsoding", "value": "C7299"},
            {"label": "Kleinsolk", "value": "C7300"},
            {"label": "Kloch", "value": "C7301"},
            {"label": "Kloster", "value": "C7302"},
            {"label": "Knittelfeld", "value": "C7303"},
            {"label": "Kobenz", "value": "C7304"},
            {"label": "Koflach", "value": "C7305"},
            {"label": "Kohlberg", "value": "C7306"},
            {"label": "Kohlschwarz", "value": "C7307"},
            {"label": "Krakaudorf", "value": "C7308"},
            {"label": "Krakauhintermuhlen", "value": "C7309"},
            {"label": "Krakauschatten", "value": "C7310"},
            {"label": "Kraubath an der Mur", "value": "C7311"},
            {"label": "Krieglach", "value": "C7312"},
            {"label": "Krottendorf", "value": "C7313"},
            {"label": "Krottendorf bei Ligist", "value": "C7314"},
            {"label": "Krumegg", "value": "C7315"},
            {"label": "Krusdorf", "value": "C7316"},
            {"label": "Kulm am Zirbitz", "value": "C7317"},
            {"label": "Kumberg", "value": "C7318"},
            {"label": "Labuch", "value": "C7319"},
            {"label": "Lafnitz", "value": "C7320"},
            {"label": "Landl", "value": "C7321"},
            {"label": "Lang", "value": "C7322"},
            {"label": "Langegg bei Graz", "value": "C7323"},
            {"label": "Langenwang", "value": "C7324"},
            {"label": "Lannach", "value": "C7325"},
            {"label": "Lassnitzhohe", "value": "C7326"},
            {"label": "Leibnitz", "value": "C7327"},
            {"label": "Leitendorf", "value": "C7328"},
            {"label": "Leitersdorf im Raabtal", "value": "C7329"},
            {"label": "Leitring", "value": "C7330"},
            {"label": "Lend", "value": "C7331"},
            {"label": "Leoben", "value": "C7332"},
            {"label": "Leutschach", "value": "C7333"},
            {"label": "Liebenau", "value": "C7334"},
            {"label": "Lieboch", "value": "C7335"},
            {"label": "Liesingtal", "value": "C7336"},
            {"label": "Liezen", "value": "C7337"},
            {"label": "Ligist", "value": "C7338"},
            {"label": "Lodersdorf", "value": "C7339"},
            {"label": "Loipersdorf bei Furstenfeld", "value": "C7340"},
            {"label": "Maierdorf", "value": "C7341"},
            {"label": "Maria Lankowitz", "value": "C7342"},
            {"label": "Mariahof", "value": "C7343"},
            {"label": "Mariatrost", "value": "C7344"},
            {"label": "Mariazell", "value": "C7345"},
            {"label": "Mautern in Steiermark", "value": "C7346"},
            {"label": "Mellach", "value": "C7347"},
            {"label": "Merkendorf", "value": "C7348"},
            {"label": "Mettersdorf am Sassbach", "value": "C7349"},
            {"label": "Michaelerberg", "value": "C7350"},
            {"label": "Mitterdorf an der Raab", "value": "C7351"},
            {"label": "Mitterdorf im Murztal", "value": "C7352"},
            {"label": "Mitterlabill", "value": "C7353"},
            {"label": "Mitterndorf im Steirischen Salzkammergut",
             "value": "C7354"},
            {"label": "Modriach", "value": "C7355"},
            {"label": "Monichwald", "value": "C7356"},
            {"label": "Mooskirchen", "value": "C7357"},
            {"label": "Mortantsch", "value": "C7358"},
            {"label": "Muhldorf bei Feldbach", "value": "C7359"},
            {"label": "Muhlen", "value": "C7360"},
            {"label": "Murau", "value": "C7361"},
            {"label": "Mureck", "value": "C7362"},
            {"label": "Murzhofen", "value": "C7363"},
            {"label": "Murzsteg", "value": "C7364"},
            {"label": "Murzzuschlag", "value": "C7365"},
            {"label": "Naas", "value": "C7366"},
            {"label": "Nestelbach bei Graz", "value": "C7367"},
            {"label": "Nestelbach im Ilztal", "value": "C7368"},
            {"label": "Neuberg an der Murz", "value": "C7369"},
            {"label": "Neudau", "value": "C7370"},
            {"label": "Neumarkt in Steiermark", "value": "C7371"},
            {"label": "Neutillmitsch", "value": "C7372"},
            {"label": "Niederoblarn", "value": "C7373"},
            {"label": "Niederschockl", "value": "C7374"},
            {"label": "Niederwolz", "value": "C7375"},
            {"label": "Niklasdorf", "value": "C7376"},
            {"label": "Nitscha", "value": "C7377"},
            {"label": "Obdach", "value": "C7378"},
            {"label": "Oberaich", "value": "C7379"},
            {"label": "Oberdorf am Hochegg", "value": "C7380"},
            {"label": "Oberhaag", "value": "C7381"},
            {"label": "Oberkurzheim", "value": "C7382"},
            {"label": "Oberpremstatten", "value": "C7383"},
            {"label": "Oberrettenbach", "value": "C7384"},
            {"label": "Oberstorcha", "value": "C7385"},
            {"label": "Obervogau", "value": "C7386"},
            {"label": "Oberweg", "value": "C7387"},
            {"label": "Oberwolz Stadt", "value": "C7388"},
            {"label": "Oberzeiring", "value": "C7389"},
            {"label": "Oblarn", "value": "C7390"},
            {"label": "Oppenberg", "value": "C7391"},
            {"label": "Ortgraben", "value": "C7392"},
            {"label": "Osterwitz", "value": "C7393"},
            {"label": "Pack", "value": "C7394"},
            {"label": "Paldau", "value": "C7395"},
            {"label": "Palfau", "value": "C7396"},
            {"label": "Parschlug", "value": "C7397"},
            {"label": "Passail", "value": "C7398"},
            {"label": "Peggau", "value": "C7399"},
            {"label": "Perchau am Sattel", "value": "C7400"},
            {"label": "Perlsdorf", "value": "C7401"},
            {"label": "Pernegg an der Mur", "value": "C7402"},
            {"label": "Pertlstein", "value": "C7403"},
            {"label": "Piberegg", "value": "C7404"},
            {"label": "Pichling bei Koflach", "value": "C7405"},
            {"label": "Pinggau", "value": "C7406"},
            {"label": "Pirching am Traubenberg", "value": "C7407"},
            {"label": "Pirka", "value": "C7408"},
            {"label": "Pischelsdorf in der Steiermark", "value": "C7409"},
            {"label": "Pistorf", "value": "C7410"},
            {"label": "Pitschgau", "value": "C7411"},
            {"label": "Politischer Bezirk Bruck-Murzzuschlag",
             "value": "C7412"},
            {"label": "Politischer Bezirk Deutschlandsberg", "value": "C7413"},
            {"label": "Politischer Bezirk Graz-Umgebung", "value": "C7414"},
            {"label": "Politischer Bezirk Hartberg-Furstenfeld",
             "value": "C7415"},
            {"label": "Politischer Bezirk Leibnitz", "value": "C7416"},
            {"label": "Politischer Bezirk Leoben", "value": "C7417"},
            {"label": "Politischer Bezirk Liezen", "value": "C7418"},
            {"label": "Politischer Bezirk Murau", "value": "C7419"},
            {"label": "Politischer Bezirk Murtal", "value": "C7420"},
            {"label": "Politischer Bezirk Sudoststeiermark", "value": "C7421"},
            {"label": "Politischer Bezirk Voitsberg", "value": "C7422"},
            {"label": "Politischer Bezirk Weiz", "value": "C7423"},
            {"label": "Pollau", "value": "C7424"},
            {"label": "Pollauberg", "value": "C7425"},
            {"label": "Pols", "value": "C7426"},
            {"label": "Poppendorf", "value": "C7427"},
            {"label": "Preding", "value": "C7428"},
            {"label": "Proleb", "value": "C7429"},
            {"label": "Pruggern", "value": "C7430"},
            {"label": "Puch bei Weiz", "value": "C7431"},
            {"label": "Puntigam", "value": "C7432"},
            {"label": "Pusterwald", "value": "C7433"},
            {"label": "Raaba", "value": "C7434"},
            {"label": "Raabau", "value": "C7435"},
            {"label": "Rabenwald", "value": "C7436"},
            {"label": "Rachau", "value": "C7437"},
            {"label": "Ragnitz", "value": "C7438"},
            {"label": "Ramsau am Dachstein", "value": "C7439"},
            {"label": "Raning", "value": "C7440"},
            {"label": "Ranten", "value": "C7441"},
            {"label": "Rassach", "value": "C7442"},
            {"label": "Ratsch an der Weinstrasse", "value": "C7443"},
            {"label": "Ratschendorf", "value": "C7444"},
            {"label": "Ratten", "value": "C7445"},
            {"label": "Reichendorf", "value": "C7446"},
            {"label": "Reifling", "value": "C7447"},
            {"label": "Rettenegg", "value": "C7448"},
            {"label": "Retznei", "value": "C7449"},
            {"label": "Riegersburg", "value": "C7450"},
            {"label": "Ries", "value": "C7451"},
            {"label": "Rohrbach an der Lafnitz", "value": "C7452"},
            {"label": "Rosental an der Kainach", "value": "C7453"},
            {"label": "Rothelstein", "value": "C7454"},
            {"label": "Rottenmann", "value": "C7455"},
            {"label": "Salla", "value": "C7456"},
            {"label": "Sankt Anna am Aigen", "value": "C7457"},
            {"label": "Sankt Bartholoma", "value": "C7458"},
            {"label": "Sankt Blasen", "value": "C7459"},
            {"label": "Sankt Gallen", "value": "C7460"},
            {"label": "Sankt Georgen an der Stiefing", "value": "C7461"},
            {"label": "Sankt Georgen ob Judenburg", "value": "C7462"},
            {"label": "Sankt Georgen ob Murau", "value": "C7463"},
            {"label": "Sankt Ilgen", "value": "C7464"},
            {"label": "Sankt Jakob im Walde", "value": "C7465"},
            {"label": "Sankt Jakob-Breitenau", "value": "C7466"},
            {"label": "Sankt Johann am Tauern", "value": "C7467"},
            {"label": "Sankt Johann bei Herberstein", "value": "C7468"},
            {"label": "Sankt Johann im Saggautal", "value": "C7469"},
            {"label": "Sankt Johann in der Haide", "value": "C7470"},
            {"label": "Sankt Josef (Weststeiermark)", "value": "C7471"},
            {"label": "Sankt Katharein an der Laming", "value": "C7472"},
            {"label": "Sankt Kathrein am Hauenstein", "value": "C7473"},
            {"label": "Sankt Kathrein am Offenegg", "value": "C7474"},
            {"label": "Sankt Lambrecht", "value": "C7475"},
            {"label": "Sankt Leonhard", "value": "C7476"},
            {"label": "Sankt Lorenzen am Wechsel", "value": "C7477"},
            {"label": "Sankt Lorenzen bei Knittelfeld", "value": "C7478"},
            {"label": "Sankt Lorenzen bei Scheifling", "value": "C7479"},
            {"label": "Sankt Lorenzen im Murztal", "value": "C7480"},
            {"label": "Sankt Marein bei Graz", "value": "C7481"},
            {"label": "Sankt Marein bei Knittelfeld", "value": "C7482"},
            {"label": "Sankt Marein bei Neumarkt", "value": "C7483"},
            {"label": "Sankt Marein im Murztal", "value": "C7484"},
            {"label": "Sankt Margarethen an der Raab", "value": "C7485"},
            {"label": "Sankt Margarethen bei Knittelfeld", "value": "C7486"},
            {"label": "Sankt Martin", "value": "C7487"},
            {"label": "Sankt Martin am Grimming", "value": "C7488"},
            {"label": "Sankt Martin im Sulmtal", "value": "C7489"},
            {"label": "Sankt Michael in Obersteiermark", "value": "C7490"},
            {"label": "Sankt Nikolai im Sausal", "value": "C7491"},
            {"label": "Sankt Nikolai im Solktal", "value": "C7492"},
            {"label": "Sankt Nikolai ob Drassling", "value": "C7493"},
            {"label": "Sankt Oswald bei Plankenwarth", "value": "C7494"},
            {"label": "Sankt Oswald ob Eibiswald", "value": "C7495"},
            {"label": "Sankt Peter", "value": "C7496"},
            {"label": "Sankt Peter am Kammersberg", "value": "C7497"},
            {"label": "Sankt Peter am Ottersbach", "value": "C7498"},
            {"label": "Sankt Peter im Sulmtal", "value": "C7499"},
            {"label": "Sankt Peter ob Judenburg", "value": "C7500"},
            {"label": "Sankt Peter-Freienstein", "value": "C7501"},
            {"label": "Sankt Radegund bei Graz", "value": "C7502"},
            {"label": "Sankt Ruprecht an der Raab", "value": "C7503"},
            {"label": "Sankt Stefan im Rosental", "value": "C7504"},
            {"label": "Sankt Stefan ob Leoben", "value": "C7505"},
            {"label": "Sankt Stefan ob Stainz", "value": "C7506"},
            {"label": "Sankt Ulrich am Waasen", "value": "C7507"},
            {"label": "Sankt Veit am Vogau", "value": "C7508"},
            {"label": "Schachen bei Vorau", "value": "C7509"},
            {"label": "Schaffern", "value": "C7510"},
            {"label": "Scheifling", "value": "C7511"},
            {"label": "Schladming", "value": "C7512"},
            {"label": "Schoder", "value": "C7513"},
            {"label": "Schrems bei Frohnleiten", "value": "C7514"},
            {"label": "Schwanberg", "value": "C7515"},
            {"label": "Schwarzau im Schwarzautal", "value": "C7516"},
            {"label": "Sebersdorf", "value": "C7517"},
            {"label": "Seckau", "value": "C7518"},
            {"label": "Seggauberg", "value": "C7519"},
            {"label": "Seiersberg", "value": "C7520"},
            {"label": "Selzthal", "value": "C7521"},
            {"label": "Semriach", "value": "C7522"},
            {"label": "Siegersdorf bei Herberstein", "value": "C7523"},
            {"label": "Sinabelkirchen", "value": "C7524"},
            {"label": "Soboth", "value": "C7525"},
            {"label": "Sochau", "value": "C7526"},
            {"label": "Sodingberg", "value": "C7527"},
            {"label": "Spatenhof", "value": "C7528"},
            {"label": "Spielberg bei Knittelfeld", "value": "C7529"},
            {"label": "Spielfeld", "value": "C7530"},
            {"label": "Spital am Semmering", "value": "C7531"},
            {"label": "Stadl an der Mur", "value": "C7532"},
            {"label": "Stainach", "value": "C7533"},
            {"label": "Stainz", "value": "C7534"},
            {"label": "Stainz bei Straden", "value": "C7535"},
            {"label": "Stallhof", "value": "C7536"},
            {"label": "Stallhofen", "value": "C7537"},
            {"label": "Stambach", "value": "C7538"},
            {"label": "Stattegg", "value": "C7539"},
            {"label": "Stein", "value": "C7540"},
            {"label": "Stenzengreith", "value": "C7541"},
            {"label": "Stiwoll", "value": "C7542"},
            {"label": "Straden", "value": "C7543"},
            {"label": "Strallegg", "value": "C7544"},
            {"label": "Strass in Steiermark", "value": "C7545"},
            {"label": "Strassengel", "value": "C7546"},
            {"label": "Strassgang", "value": "C7547"},
            {"label": "Stubenberg", "value": "C7548"},
            {"label": "Studenzen", "value": "C7549"},
            {"label": "Sulztal an der Weinstrasse", "value": "C7550"},
            {"label": "Tauplitz", "value": "C7551"},
            {"label": "Teufenbach", "value": "C7552"},
            {"label": "Thal", "value": "C7553"},
            {"label": "Thorl", "value": "C7554"},
            {"label": "Tieschen", "value": "C7555"},
            {"label": "Tillmitsch", "value": "C7556"},
            {"label": "Tillmitsch Links der Lassnitz", "value": "C7557"},
            {"label": "Traboch", "value": "C7558"},
            {"label": "Trahutten", "value": "C7559"},
            {"label": "Trautmannsdorf in Oststeiermark", "value": "C7560"},
            {"label": "Treglwang", "value": "C7561"},
            {"label": "Trieben", "value": "C7562"},
            {"label": "Triebendorf", "value": "C7563"},
            {"label": "Trofaiach", "value": "C7564"},
            {"label": "Trossing", "value": "C7565"},
            {"label": "Turnau", "value": "C7566"},
            {"label": "ubelbach", "value": "C7567"},
            {"label": "ubersbach", "value": "C7568"},
            {"label": "Ungerdorf", "value": "C7569"},
            {"label": "Unterauersbach", "value": "C7570"},
            {"label": "Unterbergla", "value": "C7571"},
            {"label": "Unterfladnitz", "value": "C7572"},
            {"label": "Unterlamm", "value": "C7573"},
            {"label": "Unterpremstatten", "value": "C7574"},
            {"label": "Utschtal", "value": "C7575"},
            {"label": "Vasoldsberg", "value": "C7576"},
            {"label": "Veitsch", "value": "C7577"},
            {"label": "Vogau", "value": "C7578"},
            {"label": "Voitsberg", "value": "C7579"},
            {"label": "Vorau", "value": "C7580"},
            {"label": "Vordernberg", "value": "C7581"},
            {"label": "Wagna", "value": "C7582"},
            {"label": "Wagnitz", "value": "C7583"},
            {"label": "Wald am Schoberpass", "value": "C7584"},
            {"label": "Waltendorf", "value": "C7585"},
            {"label": "Wartberg", "value": "C7586"},
            {"label": "Weinburg am Sassbach", "value": "C7587"},
            {"label": "Weissenbach bei Liezen", "value": "C7588"},
            {"label": "Weisskirchen in Steiermark", "value": "C7589"},
            {"label": "Weiz", "value": "C7590"},
            {"label": "Werndorf", "value": "C7591"},
            {"label": "Wernersdorf", "value": "C7592"},
            {"label": "Wettmannstatten", "value": "C7593"},
            {"label": "Wetzelsdorf", "value": "C7594"},
            {"label": "Wies", "value": "C7595"},
            {"label": "Wildalpen", "value": "C7596"},
            {"label": "Wildon", "value": "C7597"},
            {"label": "Winklern bei Oberwolz", "value": "C7598"},
            {"label": "Wolfsberg im Schwarzautal", "value": "C7599"},
            {"label": "Worschach", "value": "C7600"},
            {"label": "Worth an der Lafnitz", "value": "C7601"},
            {"label": "Wundschuh", "value": "C7602"},
            {"label": "Zeltweg", "value": "C7603"},
            {"label": "Zerlach", "value": "C7604"},
            {"label": "Zettling", "value": "C7605"},
            {"label": "Zeutschach", "value": "C7606"}],
 "Suceava County": [{"label": "Adancata", "value": "C98732"},
                    {"label": "Arbore", "value": "C98733"},
                    {"label": "Argel", "value": "C98734"},
                    {"label": "Arghira", "value": "C98735"},
                    {"label": "Badeuti", "value": "C98736"},
                    {"label": "Baia", "value": "C98737"},
                    {"label": "Baisesti", "value": "C98738"},
                    {"label": "Balaceana", "value": "C98739"},
                    {"label": "Balcauti", "value": "C98740"},
                    {"label": "Banesti", "value": "C98741"},
                    {"label": "Basarabi", "value": "C98742"},
                    {"label": "Berchisesti", "value": "C98743"},
                    {"label": "Bilca", "value": "C98744"},
                    {"label": "Bivolarie", "value": "C98745"},
                    {"label": "Bogata", "value": "C98746"},
                    {"label": "Bogdanesti", "value": "C98747"},
                    {"label": "Boroaia", "value": "C98748"},
                    {"label": "Bosanci", "value": "C98749"},
                    {"label": "Botosana", "value": "C98750"},
                    {"label": "Braiesti", "value": "C98751"},
                    {"label": "Breaza", "value": "C98752"},
                    {"label": "Brodina", "value": "C98753"},
                    {"label": "Brosteni", "value": "C98754"},
                    {"label": "Bucsoaia", "value": "C98755"},
                    {"label": "Budeni", "value": "C98756"},
                    {"label": "Bunesti", "value": "C98757"},
                    {"label": "Burla", "value": "C98758"},
                    {"label": "Bursuceni", "value": "C98759"},
                    {"label": "Cacica", "value": "C98760"},
                    {"label": "Cajvana", "value": "C98761"},
                    {"label": "Calafindesti", "value": "C98762"},
                    {"label": "Calinesti", "value": "C98763"},
                    {"label": "Campulung Moldovenesc", "value": "C98764"},
                    {"label": "Capu Campului", "value": "C98765"},
                    {"label": "Capu Codrului", "value": "C98766"},
                    {"label": "Carlibaba", "value": "C98767"},
                    {"label": "Ciocanesti", "value": "C98768"},
                    {"label": "Ciprian Porumbescu", "value": "C98769"},
                    {"label": "Clit", "value": "C98770"},
                    {"label": "Colacu", "value": "C98771"},
                    {"label": "Comanesti", "value": "C98772"},
                    {"label": "Comuna Adancata", "value": "C98773"},
                    {"label": "Comuna Arbore", "value": "C98774"},
                    {"label": "Comuna Baia", "value": "C98775"},
                    {"label": "Comuna Balaceana", "value": "C98776"},
                    {"label": "Comuna Balcauti", "value": "C98777"},
                    {"label": "Comuna Berchisesti", "value": "C98778"},
                    {"label": "Comuna Bilca", "value": "C98779"},
                    {"label": "Comuna Bogdanesti", "value": "C98780"},
                    {"label": "Comuna Boroaia", "value": "C98781"},
                    {"label": "Comuna Bosanci", "value": "C98782"},
                    {"label": "Comuna Botosana", "value": "C98783"},
                    {"label": "Comuna Breaza", "value": "C98784"},
                    {"label": "Comuna Brodina", "value": "C98785"},
                    {"label": "Comuna Bunesti", "value": "C98786"},
                    {"label": "Comuna Burla", "value": "C98787"},
                    {"label": "Comuna Cacica", "value": "C98788"},
                    {"label": "Comuna Calafindesti", "value": "C98789"},
                    {"label": "Comuna Capu Campului", "value": "C98790"},
                    {"label": "Comuna Carlibaba", "value": "C98791"},
                    {"label": "Comuna Ciocanesti", "value": "C98792"},
                    {"label": "Comuna Ciprian Porumbescu", "value": "C98793"},
                    {"label": "Comuna Comanesti", "value": "C98794"},
                    {"label": "Comuna Cornu Luncii", "value": "C98795"},
                    {"label": "Comuna Cosna", "value": "C98796"},
                    {"label": "Comuna Crucea", "value": "C98797"},
                    {"label": "Comuna Darmanesti", "value": "C98798"},
                    {"label": "Comuna Dolhesti", "value": "C98799"},
                    {"label": "Comuna Dorna Candrenilor", "value": "C98800"},
                    {"label": "Comuna Dorna-Arini", "value": "C98801"},
                    {"label": "Comuna Dornesti", "value": "C98802"},
                    {"label": "Comuna Dragoiesti", "value": "C98803"},
                    {"label": "Comuna Draguseni", "value": "C98804"},
                    {"label": "Comuna Dumbraveni", "value": "C98805"},
                    {"label": "Comuna Fantana Mare", "value": "C98806"},
                    {"label": "Comuna Fantanele", "value": "C98807"},
                    {"label": "Comuna Forasti", "value": "C98808"},
                    {"label": "Comuna Fratautii Noi", "value": "C98809"},
                    {"label": "Comuna Fratautii Vechi", "value": "C98810"},
                    {"label": "Comuna Frumosu", "value": "C98811"},
                    {"label": "Comuna Fundu Moldovei", "value": "C98812"},
                    {"label": "Comuna Galanesti", "value": "C98813"},
                    {"label": "Comuna Gramesti", "value": "C98814"},
                    {"label": "Comuna Granicesti", "value": "C98815"},
                    {"label": "Comuna Hantesti", "value": "C98816"},
                    {"label": "Comuna Hartop", "value": "C98817"},
                    {"label": "Comuna Horodnic De Jos", "value": "C98818"},
                    {"label": "Comuna Horodnic De Sus", "value": "C98819"},
                    {"label": "Comuna Horodniceni", "value": "C98820"},
                    {"label": "Comuna Iacobeni", "value": "C98821"},
                    {"label": "Comuna Iaslovat", "value": "C98822"},
                    {"label": "Comuna Ilisesti", "value": "C98823"},
                    {"label": "Comuna Ipotesti", "value": "C98824"},
                    {"label": "Comuna Izvoarele Sucevei", "value": "C98825"},
                    {"label": "Comuna Malini", "value": "C98826"},
                    {"label": "Comuna Manastirea Humorului", "value": "C98827"},
                    {"label": "Comuna Marginea", "value": "C98828"},
                    {"label": "Comuna Mitocu Dragomirnei", "value": "C98829"},
                    {"label": "Comuna Moara", "value": "C98830"},
                    {"label": "Comuna Moldova Sulita", "value": "C98831"},
                    {"label": "Comuna Moldovita", "value": "C98832"},
                    {"label": "Comuna Musenita", "value": "C98833"},
                    {"label": "Comuna Ostra", "value": "C98834"},
                    {"label": "Comuna Paltinoasa", "value": "C98835"},
                    {"label": "Comuna Panaci", "value": "C98836"},
                    {"label": "Comuna Partestii de Jos", "value": "C98837"},
                    {"label": "Comuna Patrauti", "value": "C98838"},
                    {"label": "Comuna Poiana Stampei", "value": "C98839"},
                    {"label": "Comuna Poieni-Solca", "value": "C98840"},
                    {"label": "Comuna Pojorata", "value": "C98841"},
                    {"label": "Comuna Preutesti", "value": "C98842"},
                    {"label": "Comuna Putna", "value": "C98843"},
                    {"label": "Comuna Radaseni", "value": "C98844"},
                    {"label": "Comuna Rasca", "value": "C98845"},
                    {"label": "Comuna Sadova", "value": "C98846"},
                    {"label": "Comuna Saru Dornei", "value": "C98847"},
                    {"label": "Comuna Satu Mare", "value": "C98848"},
                    {"label": "Comuna Scheia", "value": "C98849"},
                    {"label": "Comuna Serbauti", "value": "C98850"},
                    {"label": "Comuna Siminicea", "value": "C98851"},
                    {"label": "Comuna Slatina", "value": "C98852"},
                    {"label": "Comuna Straja", "value": "C98853"},
                    {"label": "Comuna Stroiesti", "value": "C98854"},
                    {"label": "Comuna Stulpicani", "value": "C98855"},
                    {"label": "Comuna Sucevita", "value": "C98856"},
                    {"label": "Comuna Todiresti", "value": "C98857"},
                    {"label": "Comuna Udesti", "value": "C98858"},
                    {"label": "Comuna Ulma", "value": "C98859"},
                    {"label": "Comuna Vadu Moldovei", "value": "C98860"},
                    {"label": "Comuna Valea Moldovei", "value": "C98861"},
                    {"label": "Comuna Vama", "value": "C98862"},
                    {"label": "Comuna Vatra Moldovitei", "value": "C98863"},
                    {"label": "Comuna Veresti", "value": "C98864"},
                    {"label": "Comuna Vicovu de Jos", "value": "C98865"},
                    {"label": "Comuna Voitinel", "value": "C98866"},
                    {"label": "Comuna Volovat", "value": "C98867"},
                    {"label": "Comuna Vulturesti", "value": "C98868"},
                    {"label": "Comuna Zamostea", "value": "C98869"},
                    {"label": "Comuna Zvoristea", "value": "C98870"},
                    {"label": "Cornu Luncii", "value": "C98871"},
                    {"label": "Corocaiesti", "value": "C98872"},
                    {"label": "Cosna", "value": "C98873"},
                    {"label": "Costana", "value": "C98874"},
                    {"label": "Crucea", "value": "C98875"},
                    {"label": "Darmanesti", "value": "C98876"},
                    {"label": "Demacusa", "value": "C98877"},
                    {"label": "Dolhasca", "value": "C98878"},
                    {"label": "Dolhestii Mici", "value": "C98879"},
                    {"label": "Dolhestii-Mari", "value": "C98880"},
                    {"label": "Dorna Candrenilor", "value": "C98881"},
                    {"label": "Dorna-Arini", "value": "C98882"},
                    {"label": "Dornesti", "value": "C98883"},
                    {"label": "Dragoiesti", "value": "C98884"},
                    {"label": "Draguseni", "value": "C98885"},
                    {"label": "Dumbraveni", "value": "C98886"},
                    {"label": "Dumbravita", "value": "C98887"},
                    {"label": "Falcau", "value": "C98888"},
                    {"label": "Falticeni", "value": "C98889"},
                    {"label": "Fantana Mare", "value": "C98890"},
                    {"label": "Fantanele", "value": "C98891"},
                    {"label": "Forasti", "value": "C98892"},
                    {"label": "Frasin", "value": "C98893"},
                    {"label": "Fratautii Noi", "value": "C98894"},
                    {"label": "Fratautii Vechi", "value": "C98895"},
                    {"label": "Frumosu", "value": "C98896"},
                    {"label": "Fundu Moldovei", "value": "C98897"},
                    {"label": "Gainesti", "value": "C98898"},
                    {"label": "Galanesti", "value": "C98899"},
                    {"label": "Gramesti", "value": "C98900"},
                    {"label": "Granicesti", "value": "C98901"},
                    {"label": "Gulia", "value": "C98902"},
                    {"label": "Gura Humorului", "value": "C98903"},
                    {"label": "Gura Putnei", "value": "C98904"},
                    {"label": "Hantesti", "value": "C98905"},
                    {"label": "Hartop", "value": "C98906"},
                    {"label": "Herla", "value": "C98907"},
                    {"label": "Horodnic de Jos", "value": "C98908"},
                    {"label": "Horodnic de Sus", "value": "C98909"},
                    {"label": "Horodniceni", "value": "C98910"},
                    {"label": "Humoreni", "value": "C98911"},
                    {"label": "Hurjuieni", "value": "C98912"},
                    {"label": "Iacobeni", "value": "C98913"},
                    {"label": "Iaslovat", "value": "C98914"},
                    {"label": "Ilisesti", "value": "C98915"},
                    {"label": "Ipotesti", "value": "C98916"},
                    {"label": "Izvoarele Sucevei", "value": "C98917"},
                    {"label": "Lamaseni", "value": "C98918"},
                    {"label": "Liteni", "value": "C98919"},
                    {"label": "Malini", "value": "C98920"},
                    {"label": "Manastirea Humorului", "value": "C98921"},
                    {"label": "Marginea", "value": "C98922"},
                    {"label": "Maritei", "value": "C98923"},
                    {"label": "Mazanaesti", "value": "C98924"},
                    {"label": "Mihaiesti", "value": "C98925"},
                    {"label": "Mihoveni", "value": "C98926"},
                    {"label": "Milisauti", "value": "C98927"},
                    {"label": "Mironu", "value": "C98928"},
                    {"label": "Mitocu Dragomirnei", "value": "C98929"},
                    {"label": "Moara Carp", "value": "C98930"},
                    {"label": "Moara Nica", "value": "C98931"},
                    {"label": "Moldova Sulita", "value": "C98932"},
                    {"label": "Moldovita", "value": "C98933"},
                    {"label": "Municipiul Campulung Moldovenesc",
                     "value": "C98934"},
                    {"label": "Municipiul Falticeni", "value": "C98935"},
                    {"label": "Municipiul Radauti", "value": "C98936"},
                    {"label": "Municipiul Suceava", "value": "C98937"},
                    {"label": "Municipiul Vatra Dornei", "value": "C98938"},
                    {"label": "Musenita", "value": "C98939"},
                    {"label": "Neagra Sarului", "value": "C98940"},
                    {"label": "Negostina", "value": "C98941"},
                    {"label": "Negrileasa", "value": "C98942"},
                    {"label": "Oniceni", "value": "C98943"},
                    {"label": "Oras Brosteni", "value": "C98944"},
                    {"label": "Oras Cajvana", "value": "C98945"},
                    {"label": "Oras Dolhasca", "value": "C98946"},
                    {"label": "Oras Frasin", "value": "C98947"},
                    {"label": "Oras Gura Humorului", "value": "C98948"},
                    {"label": "Oras Liteni", "value": "C98949"},
                    {"label": "Oras Milisauti", "value": "C98950"},
                    {"label": "Oras Salcea", "value": "C98951"},
                    {"label": "Oras Siret", "value": "C98952"},
                    {"label": "Oras Solca", "value": "C98953"},
                    {"label": "Oras Vicovu De Sus", "value": "C98954"},
                    {"label": "Ostra", "value": "C98955"},
                    {"label": "Paltinoasa", "value": "C98956"},
                    {"label": "Panaci", "value": "C98957"},
                    {"label": "Paraie", "value": "C98958"},
                    {"label": "Parhauti", "value": "C98959"},
                    {"label": "Partestii de Jos", "value": "C98960"},
                    {"label": "Patrauti", "value": "C98961"},
                    {"label": "Plavalari", "value": "C98962"},
                    {"label": "Plopeni", "value": "C98963"},
                    {"label": "Plutonita", "value": "C98964"},
                    {"label": "Poiana Marului", "value": "C98965"},
                    {"label": "Poiana Stampei", "value": "C98966"},
                    {"label": "Poieni-Solca", "value": "C98967"},
                    {"label": "Pojorata", "value": "C98968"},
                    {"label": "Prelipca", "value": "C98969"},
                    {"label": "Preutesti", "value": "C98970"},
                    {"label": "Probota", "value": "C98971"},
                    {"label": "Putna", "value": "C98972"},
                    {"label": "Radaseni", "value": "C98973"},
                    {"label": "Radauti", "value": "C98974"},
                    {"label": "Rasca", "value": "C98975"},
                    {"label": "Roscani", "value": "C98976"},
                    {"label": "Rotunda", "value": "C98977"},
                    {"label": "Sadova", "value": "C98978"},
                    {"label": "Salcea", "value": "C98979"},
                    {"label": "Saru Dornei", "value": "C98980"},
                    {"label": "Satu Mare", "value": "C98981"},
                    {"label": "Scheia", "value": "C98982"},
                    {"label": "Serbanesti", "value": "C98983"},
                    {"label": "Serbauti", "value": "C98984"},
                    {"label": "Siminicea", "value": "C98985"},
                    {"label": "Siret", "value": "C98986"},
                    {"label": "Slatina", "value": "C98987"},
                    {"label": "Solca", "value": "C98988"},
                    {"label": "Solonet", "value": "C98989"},
                    {"label": "Stamate", "value": "C98990"},
                    {"label": "Straja", "value": "C98991"},
                    {"label": "Stramtura", "value": "C98992"},
                    {"label": "Stroiesti", "value": "C98993"},
                    {"label": "Stulpicani", "value": "C98994"},
                    {"label": "Suceava", "value": "C98995"},
                    {"label": "Sucevita", "value": "C98996"},
                    {"label": "Tibeni", "value": "C98997"},
                    {"label": "Todiresti", "value": "C98998"},
                    {"label": "Udesti", "value": "C98999"},
                    {"label": "Ulma", "value": "C99000"},
                    {"label": "Vadu Moldovei", "value": "C99001"},
                    {"label": "Valea Moldovei", "value": "C99002"},
                    {"label": "Vama", "value": "C99003"},
                    {"label": "Varatec", "value": "C99004"},
                    {"label": "Vatra Dornei", "value": "C99005"},
                    {"label": "Vatra Moldovitei", "value": "C99006"},
                    {"label": "Veresti", "value": "C99007"},
                    {"label": "Vicovu de Jos", "value": "C99008"},
                    {"label": "Vicovu de Sus", "value": "C99009"},
                    {"label": "Voievodeasa", "value": "C99010"},
                    {"label": "Voitinel", "value": "C99011"},
                    {"label": "Volovat", "value": "C99012"},
                    {"label": "Vulturesti", "value": "C99013"},
                    {"label": "Zamostea", "value": "C99014"},
                    {"label": "Zvoristea", "value": "C99015"}],
 "Suchitepequez Department": [{"label": "Chicacao", "value": "C42964"},
                              {"label": "Cuyotenango", "value": "C42965"},
                              {"label": "Mazatenango", "value": "C42966"},
                              {"label": "Municipio de San Antonio Suchitepequez",
                               "value": "C42967"},
                              {"label": "Municipio de San Miguel Panan",
                               "value": "C42968"},
                              {"label": "Municipio de Santa Barbara",
                               "value": "C42969"},
                              {"label": "Patulul", "value": "C42970"},
                              {"label": "Pueblo Nuevo", "value": "C42971"},
                              {"label": "Rio Bravo", "value": "C42972"},
                              {"label": "San Antonio Suchitepequez",
                               "value": "C42973"},
                              {"label": "San Bernardino", "value": "C42974"},
                              {"label": "San Francisco Zapotitlan",
                               "value": "C42975"},
                              {"label": "San Gabriel", "value": "C42976"},
                              {"label": "San Jose El idolo", "value": "C42977"},
                              {"label": "San Juan Bautista", "value": "C42978"},
                              {"label": "San Lorenzo", "value": "C42979"},
                              {"label": "San Miguel Panan", "value": "C42980"},
                              {"label": "San Pablo Jocopilas",
                               "value": "C42981"},
                              {"label": "Santa Barbara", "value": "C42982"},
                              {"label": "Santo Domingo Suchitepequez",
                               "value": "C42983"},
                              {"label": "Santo Tomas La Union",
                               "value": "C42984"},
                              {"label": "Zunilito", "value": "C42985"}],
 "Sucre": [{"label": "Buenavista", "value": "C20533"},
           {"label": "Caimito", "value": "C20534"},
           {"label": "Chalan", "value": "C20535"},
           {"label": "Coloso", "value": "C20536"},
           {"label": "Corozal", "value": "C20537"},
           {"label": "Covenas", "value": "C20538"},
           {"label": "El Roble", "value": "C20539"},
           {"label": "Galeras", "value": "C20540"},
           {"label": "Guaranda", "value": "C20541"},
           {"label": "La Union", "value": "C20542"},
           {"label": "Los Palmitos", "value": "C20543"},
           {"label": "Majagual", "value": "C20544"},
           {"label": "Morroa", "value": "C20545"},
           {"label": "Ovejas", "value": "C20546"},
           {"label": "Palmito", "value": "C20547"},
           {"label": "Sampues", "value": "C20548"},
           {"label": "San Benito Abad", "value": "C20549"},
           {"label": "San Juan de Betulia", "value": "C20550"},
           {"label": "San Luis de Since", "value": "C20551"},
           {"label": "San Marcos", "value": "C20552"},
           {"label": "San Onofre", "value": "C20553"},
           {"label": "San Pedro", "value": "C20554"},
           {"label": "Santiago de Tolu", "value": "C20555"},
           {"label": "Sincelejo", "value": "C20556"},
           {"label": "Sucre", "value": "C20557"},
           {"label": "Tolu Viejo", "value": "C20558"},
           {"label": "Carupano", "value": "C147228"},
           {"label": "Cumana", "value": "C147229"},
           {"label": "Guiria", "value": "C147230"},
           {"label": "Municipio Valdez", "value": "C147231"}],
 "Sucumbios": [{"label": "Nueva Loja", "value": "C24200"}],
 "Sud": [{"label": "Aquin", "value": "C43179"},
         {"label": "Arrondissement de Port-Salut", "value": "C43180"},
         {"label": "Arrondissement des Cayes", "value": "C43181"},
         {"label": "Cavaillon", "value": "C43182"},
         {"label": "Chantal", "value": "C43183"},
         {"label": "Chardonniere", "value": "C43184"},
         {"label": "Fond des Blancs", "value": "C43185"},
         {"label": "Koto", "value": "C43186"},
         {"label": "Les Anglais", "value": "C43187"},
         {"label": "Les Cayes", "value": "C43188"},
         {"label": "Port-a-Piment", "value": "C43189"},
         {"label": "Roche-a-Bateau", "value": "C43190"},
         {"label": "Saint-Louis du Sud", "value": "C43191"},
         {"label": "Tiburon", "value": "C43192"},
         {"label": "Torbeck", "value": "C43193"}],
 "Sud-Est": [{"label": "Anse-a-Pitre", "value": "C43194"},
             {"label": "Arrondissement de Bainet", "value": "C43195"},
             {"label": "Arrondissement de Jacmel", "value": "C43196"},
             {"label": "Belle-Anse", "value": "C43197"},
             {"label": "Cayes-Jacmel", "value": "C43198"},
             {"label": "Jacmel", "value": "C43199"},
             {"label": "Kotdefe", "value": "C43200"},
             {"label": "Marigot", "value": "C43201"},
             {"label": "Thiotte", "value": "C43202"}],
 "Sud-Kivu": [{"label": "Bukavu", "value": "C23365"},
              {"label": "Kabare", "value": "C23366"},
              {"label": "Uvira", "value": "C23367"}],
 "Sud-Ouest Region": [{"label": "Batie", "value": "C16435"},
                      {"label": "Dano", "value": "C16436"},
                      {"label": "Diebougou", "value": "C16437"},
                      {"label": "Province de la Bougouriba", "value": "C16438"},
                      {"label": "Province du Ioba", "value": "C16439"},
                      {"label": "Province du Noumbiel", "value": "C16440"},
                      {"label": "Province du Poni", "value": "C16441"}],
 "Sud-Ubangi": [{"label": "Bongandanga", "value": "C23368"},
                {"label": "Libenge", "value": "C23369"}],
 "Suez": [{"label": "Ain Sukhna", "value": "C24362"},
          {"label": "Suez", "value": "C24363"}],
 "Sughd Province": [{"label": "Adrasmon", "value": "C118041"},
                    {"label": "Ayni", "value": "C118042"},
                    {"label": "Buston", "value": "C118043"},
                    {"label": "Chkalov", "value": "C118044"},
                    {"label": "Ghafurov", "value": "C118045"},
                    {"label": "Isfara", "value": "C118046"},
                    {"label": "Istaravshan", "value": "C118047"},
                    {"label": "Khujand", "value": "C118048"},
                    {"label": "Kim", "value": "C118049"},
                    {"label": "Konibodom", "value": "C118050"},
                    {"label": "Konsoy", "value": "C118051"},
                    {"label": "Neftobod", "value": "C118052"},
                    {"label": "Nohiyai Konibodom", "value": "C118053"},
                    {"label": "Nov", "value": "C118054"},
                    {"label": "Oltintopkan", "value": "C118055"},
                    {"label": "Pakhtakoron", "value": "C118056"},
                    {"label": "Palos", "value": "C118057"},
                    {"label": "Panjakent", "value": "C118058"},
                    {"label": "Proletar", "value": "C118059"},
                    {"label": "Quruqsoy", "value": "C118060"},
                    {"label": "Shaydon", "value": "C118061"},
                    {"label": "Shurob", "value": "C118062"},
                    {"label": "Taboshar", "value": "C118063"},
                    {"label": "Vorukh", "value": "C118064"}],
 "Sukhbaatar Province": [{"label": "Baruun-Urt", "value": "C75587"}],
 "Sukhothai": [{"label": "Amphoe Ban Dan Lan Hoi", "value": "C119392"},
               {"label": "Amphoe Khiri Mat", "value": "C119393"},
               {"label": "Amphoe Kong Krailat", "value": "C119394"},
               {"label": "Amphoe Mueang Sukhothai", "value": "C119395"},
               {"label": "Amphoe Sawankhalok", "value": "C119396"},
               {"label": "Amphoe Si Nakhon", "value": "C119397"},
               {"label": "Amphoe Si Samrong", "value": "C119398"},
               {"label": "Amphoe Si Satchanalai", "value": "C119399"},
               {"label": "Amphoe Thung Saliam", "value": "C119400"},
               {"label": "Ban Dan Lan Hoi", "value": "C119401"},
               {"label": "Ban Na", "value": "C119402"},
               {"label": "Khiri Mat", "value": "C119403"},
               {"label": "Sawankhalok", "value": "C119404"},
               {"label": "Si Satchanalai", "value": "C119405"},
               {"label": "Sukhothai", "value": "C119406"}],
 "Sulawesi Barat": [{"label": "Kabupaten Majene", "value": "C49839"},
                    {"label": "Kabupaten Mamasa", "value": "C49840"},
                    {"label": "Kabupaten Mamuju", "value": "C49841"},
                    {"label": "Kabupaten Mamuju Tengah", "value": "C49842"},
                    {"label": "Kabupaten Mamuju Utara", "value": "C49843"},
                    {"label": "Kabupaten Polewali Mandar", "value": "C49844"},
                    {"label": "Majene", "value": "C49845"},
                    {"label": "Mamuju", "value": "C49846"},
                    {"label": "Polewali", "value": "C49847"}],
 "Sulawesi Selatan": [{"label": "Galesong", "value": "C49848"},
                      {"label": "Kabupaten Bantaeng", "value": "C49849"},
                      {"label": "Kabupaten Barru", "value": "C49850"},
                      {"label": "Kabupaten Bone", "value": "C49851"},
                      {"label": "Kabupaten Bulukumba", "value": "C49852"},
                      {"label": "Kabupaten Enrekang", "value": "C49853"},
                      {"label": "Kabupaten Gowa", "value": "C49854"},
                      {"label": "Kabupaten Jeneponto", "value": "C49855"},
                      {"label": "Kabupaten Luwu", "value": "C49856"},
                      {"label": "Kabupaten Luwu Timur", "value": "C49857"},
                      {"label": "Kabupaten Luwu Utara", "value": "C49858"},
                      {"label": "Kabupaten Maros", "value": "C49859"},
                      {"label": "Kabupaten Pangkajene Dan Kepulauan",
                       "value": "C49860"},
                      {"label": "Kabupaten Pinrang", "value": "C49861"},
                      {"label": "Kabupaten Sidenreng Rappang",
                       "value": "C49862"},
                      {"label": "Kabupaten Sinjai", "value": "C49863"},
                      {"label": "Kabupaten Soppeng", "value": "C49864"},
                      {"label": "Kabupaten Takalar", "value": "C49865"},
                      {"label": "Kabupaten Tana Toraja", "value": "C49866"},
                      {"label": "Kabupaten Toraja Utara", "value": "C49867"},
                      {"label": "Kabupaten Wajo", "value": "C49868"},
                      {"label": "Kota Makassar", "value": "C49869"},
                      {"label": "Kota Palopo", "value": "C49870"},
                      {"label": "Kota Parepare", "value": "C49871"},
                      {"label": "Makassar", "value": "C49872"},
                      {"label": "Maros", "value": "C49873"},
                      {"label": "Palopo", "value": "C49874"},
                      {"label": "Parepare", "value": "C49875"},
                      {"label": "Rantepao", "value": "C49876"},
                      {"label": "Selayar Islands Regency", "value": "C49877"},
                      {"label": "Sengkang", "value": "C49878"},
                      {"label": "Sinjai", "value": "C49879"},
                      {"label": "Watampone", "value": "C49880"}],
 "Sulawesi Tengah": [{"label": "Kabupaten Banggai", "value": "C49881"},
                     {"label": "Kabupaten Banggai Kepulauan",
                      "value": "C49882"},
                     {"label": "Kabupaten Banggai Laut", "value": "C49883"},
                     {"label": "Kabupaten Buol", "value": "C49884"},
                     {"label": "Kabupaten Donggala", "value": "C49885"},
                     {"label": "Kabupaten Morowali Utara", "value": "C49886"},
                     {"label": "Kabupaten Parigi Moutong", "value": "C49887"},
                     {"label": "Kabupaten Poso", "value": "C49888"},
                     {"label": "Kabupaten Sigi", "value": "C49889"},
                     {"label": "Kabupaten Toli-Toli", "value": "C49890"},
                     {"label": "Kota Palu", "value": "C49891"},
                     {"label": "Luwuk", "value": "C49892"},
                     {"label": "Morowali Regency", "value": "C49893"},
                     {"label": "Palu", "value": "C49894"},
                     {"label": "Poso", "value": "C49895"},
                     {"label": "Tojo Una-Una Regency", "value": "C49896"}],
 "Sulawesi Tenggara": [{"label": "Kabupaten Bombana", "value": "C49897"},
                       {"label": "Kabupaten Buton", "value": "C49898"},
                       {"label": "Kabupaten Buton Selatan", "value": "C49899"},
                       {"label": "Kabupaten Buton Tengah", "value": "C49900"},
                       {"label": "Kabupaten Buton Utara", "value": "C49901"},
                       {"label": "Kabupaten Kolaka", "value": "C49902"},
                       {"label": "Kabupaten Kolaka Timur", "value": "C49903"},
                       {"label": "Kabupaten Kolaka Utara", "value": "C49904"},
                       {"label": "Kabupaten Konawe", "value": "C49905"},
                       {"label": "Kabupaten Konawe Kepulauan",
                        "value": "C49906"},
                       {"label": "Kabupaten Konawe Selatan", "value": "C49907"},
                       {"label": "Kabupaten Konawe Utara", "value": "C49908"},
                       {"label": "Kabupaten Muna", "value": "C49909"},
                       {"label": "Kabupaten Muna Barat", "value": "C49910"},
                       {"label": "Katabu", "value": "C49911"},
                       {"label": "Kendari", "value": "C49912"},
                       {"label": "Kota Baubau", "value": "C49913"},
                       {"label": "Kota Kendari", "value": "C49914"},
                       {"label": "Wakatobi Regency", "value": "C49915"}],
 "Sulawesi Utara": [{"label": "Kabupaten Bolaang Mongondow", "value": "C49916"},
                    {"label": "Kabupaten Bolaang Mongondow Selatan",
                     "value": "C49917"},
                    {"label": "Kabupaten Bolaang Mongondow Timur",
                     "value": "C49918"},
                    {"label": "Kabupaten Bolaang Mongondow Utara",
                     "value": "C49919"},
                    {"label": "Kabupaten Kepulauan Sangihe", "value": "C49920"},
                    {"label": "Kabupaten Kepulauan Talaud", "value": "C49921"},
                    {"label": "Kabupaten Minahasa", "value": "C49922"},
                    {"label": "Kabupaten Minahasa Selatan", "value": "C49923"},
                    {"label": "Kabupaten Minahasa Tenggara", "value": "C49924"},
                    {"label": "Kabupaten Minahasa Utara", "value": "C49925"},
                    {"label": "Kabupaten Siau Tagulandang Biaro",
                     "value": "C49926"},
                    {"label": "Kota Bitung", "value": "C49927"},
                    {"label": "Kota Kotamobagu", "value": "C49928"},
                    {"label": "Kota Manado", "value": "C49929"},
                    {"label": "Kota Tomohon", "value": "C49930"},
                    {"label": "Laikit, Laikit II (Dimembe)", "value": "C49931"},
                    {"label": "Manado", "value": "C49932"},
                    {"label": "Tomohon", "value": "C49933"},
                    {"label": "Tondano", "value": "C49934"}],
 "Sulaymaniyah Governorate": [{"label": "As Sulaymaniyah", "value": "C51206"},
                              {"label": "Baynjiwayn", "value": "C51207"},
                              {"label": "Halabjah", "value": "C51208"},
                              {"label": "Jamjamal", "value": "C51209"}],
 "Sumatera Barat": [{"label": "Bukittinggi", "value": "C49935"},
                    {"label": "Kabupaten Agam", "value": "C49936"},
                    {"label": "Kabupaten Dharmasraya", "value": "C49937"},
                    {"label": "Kabupaten Kepulauan Mentawai",
                     "value": "C49938"},
                    {"label": "Kabupaten Lima Puluh Kota", "value": "C49939"},
                    {"label": "Kabupaten Padang Pariaman", "value": "C49940"},
                    {"label": "Kabupaten Pasaman", "value": "C49941"},
                    {"label": "Kabupaten Pasaman Barat", "value": "C49942"},
                    {"label": "Kabupaten Pesisir Selatan", "value": "C49943"},
                    {"label": "Kabupaten Sijunjung", "value": "C49944"},
                    {"label": "Kabupaten Solok", "value": "C49945"},
                    {"label": "Kabupaten Solok Selatan", "value": "C49946"},
                    {"label": "Kabupaten Tanah Datar", "value": "C49947"},
                    {"label": "Kota Bukittinggi", "value": "C49948"},
                    {"label": "Kota Padang", "value": "C49949"},
                    {"label": "Kota Padang Panjang", "value": "C49950"},
                    {"label": "Kota Pariaman", "value": "C49951"},
                    {"label": "Kota Payakumbuh", "value": "C49952"},
                    {"label": "Kota Sawah Lunto", "value": "C49953"},
                    {"label": "Kota Solok", "value": "C49954"},
                    {"label": "Padang", "value": "C49955"},
                    {"label": "Pariaman", "value": "C49956"},
                    {"label": "Payakumbuh", "value": "C49957"},
                    {"label": "Sijunjung", "value": "C49958"},
                    {"label": "Solok", "value": "C49959"}],
 "Sumatera Selatan": [{"label": "Baturaja", "value": "C49960"},
                      {"label": "Kabupaten Empat Lawang", "value": "C49961"},
                      {"label": "Kabupaten Muara Enim", "value": "C49962"},
                      {"label": "Kabupaten Musi Banyuasin", "value": "C49963"},
                      {"label": "Kabupaten Musi Rawas", "value": "C49964"},
                      {"label": "Kabupaten Musi Rawas Utara",
                       "value": "C49965"},
                      {"label": "Kabupaten Ogan Ilir", "value": "C49966"},
                      {"label": "Kabupaten Ogan Komering Ilir",
                       "value": "C49967"},
                      {"label": "Kabupaten Ogan Komering Ulu",
                       "value": "C49968"},
                      {"label": "Kabupaten Ogan Komering Ulu Selatan",
                       "value": "C49969"},
                      {"label": "Kabupaten Ogan Komering Ulu Timur",
                       "value": "C49970"},
                      {"label": "Kabupaten Penukal Abab Lematang Ilir",
                       "value": "C49971"},
                      {"label": "Kota Lubuklinggau", "value": "C49972"},
                      {"label": "Kota Pagar Alam", "value": "C49973"},
                      {"label": "Kota Palembang", "value": "C49974"},
                      {"label": "Kota Prabumulih", "value": "C49975"},
                      {"label": "Lahat", "value": "C49976"},
                      {"label": "Lahat Regency", "value": "C49977"},
                      {"label": "Lubuklinggau", "value": "C49978"},
                      {"label": "Pagar Alam", "value": "C49979"},
                      {"label": "Palembang", "value": "C49980"},
                      {"label": "Prabumulih", "value": "C49981"},
                      {"label": "Tanjungagung", "value": "C49982"}],
 "Sumatera Utara": [{"label": "Ambarita", "value": "C49983"},
                    {"label": "Bandar", "value": "C49984"},
                    {"label": "Belawan", "value": "C49985"},
                    {"label": "Berastagi", "value": "C49986"},
                    {"label": "Binjai", "value": "C49987"},
                    {"label": "Deli Tua", "value": "C49988"},
                    {"label": "Gunungsitoli", "value": "C49989"},
                    {"label": "Kabanjahe", "value": "C49990"},
                    {"label": "Kabupaten Asahan", "value": "C49991"},
                    {"label": "Kabupaten Batu Bara", "value": "C49992"},
                    {"label": "Kabupaten Dairi", "value": "C49993"},
                    {"label": "Kabupaten Deli Serdang", "value": "C49994"},
                    {"label": "Kabupaten Humbang Hasundutan",
                     "value": "C49995"},
                    {"label": "Kabupaten Karo", "value": "C49996"},
                    {"label": "Kabupaten Labuhan Batu", "value": "C49997"},
                    {"label": "Kabupaten Labuhan Batu Selatan",
                     "value": "C49998"},
                    {"label": "Kabupaten Labuhan Batu Utara",
                     "value": "C49999"},
                    {"label": "Kabupaten Langkat", "value": "C50000"},
                    {"label": "Kabupaten Mandailing Natal", "value": "C50001"},
                    {"label": "Kabupaten Nias", "value": "C50002"},
                    {"label": "Kabupaten Nias Barat", "value": "C50003"},
                    {"label": "Kabupaten Nias Utara", "value": "C50004"},
                    {"label": "Kabupaten Padang Lawas", "value": "C50005"},
                    {"label": "Kabupaten Padang Lawas Utara",
                     "value": "C50006"},
                    {"label": "Kabupaten Pakpak Bharat", "value": "C50007"},
                    {"label": "Kabupaten Samosir", "value": "C50008"},
                    {"label": "Kabupaten Serdang Bedagai", "value": "C50009"},
                    {"label": "Kabupaten Simalungun", "value": "C50010"},
                    {"label": "Kabupaten Tapanuli Selatan", "value": "C50011"},
                    {"label": "Kabupaten Tapanuli Tengah", "value": "C50012"},
                    {"label": "Kabupaten Tapanuli Utara", "value": "C50013"},
                    {"label": "Kisaran", "value": "C50014"},
                    {"label": "Kota Binjai", "value": "C50015"},
                    {"label": "Kota Gunungsitoli", "value": "C50016"},
                    {"label": "Kota Medan", "value": "C50017"},
                    {"label": "Kota Padangsidimpuan", "value": "C50018"},
                    {"label": "Kota Pematang Siantar", "value": "C50019"},
                    {"label": "Kota Sibolga", "value": "C50020"},
                    {"label": "Kota Tanjung Balai", "value": "C50021"},
                    {"label": "Kota Tebing Tinggi", "value": "C50022"},
                    {"label": "Labuhan Deli", "value": "C50023"},
                    {"label": "Medan", "value": "C50024"},
                    {"label": "Padangsidempuan", "value": "C50025"},
                    {"label": "Pangkalan Brandan", "value": "C50026"},
                    {"label": "Parapat", "value": "C50027"},
                    {"label": "Pekan Bahapal", "value": "C50028"},
                    {"label": "Pematangsiantar", "value": "C50029"},
                    {"label": "Perbaungan", "value": "C50030"},
                    {"label": "Percut", "value": "C50031"},
                    {"label": "Rantauprapat", "value": "C50032"},
                    {"label": "Sibolga", "value": "C50033"},
                    {"label": "Stabat", "value": "C50034"},
                    {"label": "Sunggal", "value": "C50035"},
                    {"label": "Tanjungbalai", "value": "C50036"},
                    {"label": "Tanjungtiram", "value": "C50037"},
                    {"label": "Tebingtinggi", "value": "C50038"},
                    {"label": "Teluk Nibung", "value": "C50039"},
                    {"label": "Tomok Bolon", "value": "C50040"},
                    {"label": "Tongging", "value": "C50041"},
                    {"label": "Tuktuk Sonak", "value": "C50042"}],
 "Sumqayit": [{"label": "Corat", "value": "C8521"},
              {"label": "Haci Zeynalabdin", "value": "C8522"},
              {"label": "Sumqayit", "value": "C8523"}],
 "Sumska oblast": [{"label": "Bilopillya", "value": "C122761"},
                   {"label": "Boromlya", "value": "C122762"},
                   {"label": "Buryn", "value": "C122763"},
                   {"label": "Druzhba", "value": "C122764"},
                   {"label": "Hlukhiv", "value": "C122765"},
                   {"label": "Konotop", "value": "C122766"},
                   {"label": "Krasnopillya", "value": "C122767"},
                   {"label": "Krolevets", "value": "C122768"},
                   {"label": "Kyrykivka", "value": "C122769"},
                   {"label": "Lebedyn", "value": "C122770"},
                   {"label": "Lypova Dolyna", "value": "C122771"},
                   {"label": "Mezenivka", "value": "C122772"},
                   {"label": "Nedryhayliv", "value": "C122773"},
                   {"label": "Nyzy", "value": "C122774"},
                   {"label": "Okhtyrka", "value": "C122775"},
                   {"label": "Putyvl", "value": "C122776"},
                   {"label": "Romenska Miskrada", "value": "C122777"},
                   {"label": "Romny", "value": "C122778"},
                   {"label": "Seredyna-Buda", "value": "C122779"},
                   {"label": "Shalyhyne", "value": "C122780"},
                   {"label": "Shostka", "value": "C122781"},
                   {"label": "Sloboda", "value": "C122782"},
                   {"label": "Sumy", "value": "C122783"},
                   {"label": "Terny", "value": "C122784"},
                   {"label": "Trostyanets", "value": "C122785"},
                   {"label": "Velyka Pysarivka", "value": "C122786"},
                   {"label": "Verkhnya Syrovatka", "value": "C122787"},
                   {"label": "Vorozhba", "value": "C122788"},
                   {"label": "Yampil", "value": "C122789"},
                   {"label": "Yesman", "value": "C122790"}],
 "Suphan Buri": [{"label": "Amphoe Bang Pla Ma", "value": "C119407"},
                 {"label": "Amphoe Dan Chang", "value": "C119408"},
                 {"label": "Amphoe Doembang Nangbuat", "value": "C119409"},
                 {"label": "Amphoe Don Chedi", "value": "C119410"},
                 {"label": "Amphoe Mueang Suphan Buri", "value": "C119411"},
                 {"label": "Amphoe Nong Yasai", "value": "C119412"},
                 {"label": "Amphoe Si Prachan", "value": "C119413"},
                 {"label": "Amphoe Song Phi Nong", "value": "C119414"},
                 {"label": "Amphoe U Thong", "value": "C119415"},
                 {"label": "Ban Sam Chuk", "value": "C119416"},
                 {"label": "Doembang Nangbuat", "value": "C119417"},
                 {"label": "Suphan Buri", "value": "C119418"}],
 "Surat Thani": [{"label": "Amphoe Ban Na Doem", "value": "C119419"},
                 {"label": "Amphoe Ban Na San", "value": "C119420"},
                 {"label": "Amphoe Ban Takhun", "value": "C119421"},
                 {"label": "Amphoe Chai Buri", "value": "C119422"},
                 {"label": "Amphoe Chaiya", "value": "C119423"},
                 {"label": "Amphoe Don Sak", "value": "C119424"},
                 {"label": "Amphoe Kanchanadit", "value": "C119425"},
                 {"label": "Amphoe Khian Sa", "value": "C119426"},
                 {"label": "Amphoe Khiri Rat Nikhom", "value": "C119427"},
                 {"label": "Amphoe Ko Pha-ngan", "value": "C119428"},
                 {"label": "Amphoe Ko Samui", "value": "C119429"},
                 {"label": "Amphoe Mueang Surat Thani", "value": "C119430"},
                 {"label": "Amphoe Phanom", "value": "C119431"},
                 {"label": "Amphoe Phrasaeng", "value": "C119432"},
                 {"label": "Amphoe Phunphin", "value": "C119433"},
                 {"label": "Amphoe Tha Chana", "value": "C119434"},
                 {"label": "Amphoe Tha Chang", "value": "C119435"},
                 {"label": "Amphoe Wiang Sa", "value": "C119436"},
                 {"label": "Amphoe Wiphawadi", "value": "C119437"},
                 {"label": "Ban Na San", "value": "C119438"},
                 {"label": "Chai Buri", "value": "C119439"},
                 {"label": "Chaiya", "value": "C119440"},
                 {"label": "Don Sak", "value": "C119441"},
                 {"label": "Kanchanadit", "value": "C119442"},
                 {"label": "Ko Pha Ngan", "value": "C119443"},
                 {"label": "Ko Samui", "value": "C119444"},
                 {"label": "Koh Tao", "value": "C119445"},
                 {"label": "Surat Thani", "value": "C119446"},
                 {"label": "Tha Kham", "value": "C119447"},
                 {"label": "Tha Khanon", "value": "C119448"},
                 {"label": "Wiang Sa", "value": "C119449"}],
 "Surin": [{"label": "Amphoe Bua Chet", "value": "C119450"},
           {"label": "Amphoe Chom Phra", "value": "C119451"},
           {"label": "Amphoe Chumphon Buri", "value": "C119452"},
           {"label": "Amphoe Kap Choeng", "value": "C119453"},
           {"label": "Amphoe Khwao Sinarin", "value": "C119454"},
           {"label": "Amphoe Lamduan", "value": "C119455"},
           {"label": "Amphoe Mueang Surin", "value": "C119456"},
           {"label": "Amphoe Non Narai", "value": "C119457"},
           {"label": "Amphoe Phanom Dong Rak", "value": "C119458"},
           {"label": "Amphoe Prasat", "value": "C119459"},
           {"label": "Amphoe Rattanaburi", "value": "C119460"},
           {"label": "Amphoe Samrong Thap", "value": "C119461"},
           {"label": "Amphoe Sangkha", "value": "C119462"},
           {"label": "Amphoe Sanom", "value": "C119463"},
           {"label": "Amphoe Si Khoraphum", "value": "C119464"},
           {"label": "Amphoe Si Narong", "value": "C119465"},
           {"label": "Amphoe Tha Tum", "value": "C119466"},
           {"label": "Surin", "value": "C119467"}],
 "Surxondaryo Region": [{"label": "Boysun", "value": "C147084"},
                        {"label": "Denov", "value": "C147085"},
                        {"label": "Shorchi", "value": "C147086"},
                        {"label": "Tirmiz", "value": "C147087"}],
 "Suto Orizari Municipality": [{"label": "Suto Orizare", "value": "C65516"}],
 "Svay Rieng": [{"label": "Srok Svay Chrum", "value": "C16558"},
                {"label": "Svay Rieng", "value": "C16559"}],
 "Sverdlovsk": [{"label": "Achit", "value": "C104752"},
                {"label": "Alapayevsk", "value": "C104753"},
                {"label": "Aramil", "value": "C104754"},
                {"label": "Artemovskiy", "value": "C104755"},
                {"label": "Arti", "value": "C104756"},
                {"label": "Asbest", "value": "C104757"},
                {"label": "Asbestovskiy", "value": "C104758"},
                {"label": "Atig", "value": "C104759"},
                {"label": "Azanka", "value": "C104760"},
                {"label": "Baraba", "value": "C104761"},
                {"label": "Baranchinskiy", "value": "C104762"},
                {"label": "Baykalovo", "value": "C104763"},
                {"label": "Beloyarskiy", "value": "C104764"},
                {"label": "Beryozovsky", "value": "C104765"},
                {"label": "Bilimbay", "value": "C104766"},
                {"label": "Bisert", "value": "C104767"},
                {"label": "Bobrovskiy", "value": "C104768"},
                {"label": "Bogdanovich", "value": "C104769"},
                {"label": "Bulanash", "value": "C104770"},
                {"label": "Butka", "value": "C104771"},
                {"label": "Cheremukhovo", "value": "C104772"},
                {"label": "Chernoistochinsk", "value": "C104773"},
                {"label": "Degtyarsk", "value": "C104774"},
                {"label": "Druzhinino", "value": "C104775"},
                {"label": "Dvurechensk", "value": "C104776"},
                {"label": "Gari", "value": "C104777"},
                {"label": "Gornyy Shchit", "value": "C104778"},
                {"label": "Gryaznovskoye", "value": "C104779"},
                {"label": "Irbit", "value": "C104780"},
                {"label": "Is", "value": "C104781"},
                {"label": "Iset", "value": "C104782"},
                {"label": "Istok", "value": "C104783"},
                {"label": "Ivdel", "value": "C104784"},
                {"label": "Izumrud", "value": "C104785"},
                {"label": "Kachkanar", "value": "C104786"},
                {"label": "Kalinovo", "value": "C104787"},
                {"label": "Kamensk-Uralskiy", "value": "C104788"},
                {"label": "Kamyshlov", "value": "C104789"},
                {"label": "Karpinsk", "value": "C104790"},
                {"label": "Karpushikha", "value": "C104791"},
                {"label": "Kedrovoye", "value": "C104792"},
                {"label": "Kirovgrad", "value": "C104793"},
                {"label": "Klyuchevsk", "value": "C104794"},
                {"label": "Krasnogvardeyskiy", "value": "C104795"},
                {"label": "Krasnoturinsk", "value": "C104796"},
                {"label": "Krasnoufimsk", "value": "C104797"},
                {"label": "Krasnouralsk", "value": "C104798"},
                {"label": "Kushva", "value": "C104799"},
                {"label": "Kuzino", "value": "C104800"},
                {"label": "Lesnoy", "value": "C104801"},
                {"label": "Levikha", "value": "C104802"},
                {"label": "Lobva", "value": "C104803"},
                {"label": "Losinyy", "value": "C104804"},
                {"label": "Lugovskoy", "value": "C104805"},
                {"label": "Malysheva", "value": "C104806"},
                {"label": "Martyush", "value": "C104807"},
                {"label": "Mikhaylovsk", "value": "C104808"},
                {"label": "Monetnyy", "value": "C104809"},
                {"label": "Nevyansk", "value": "C104810"},
                {"label": "Neyvo-Rudyanka", "value": "C104811"},
                {"label": "Neyvo-Shaytanskiy", "value": "C104812"},
                {"label": "Nikolo-Pavlovskoye", "value": "C104813"},
                {"label": "Nizhniye Sergi", "value": "C104814"},
                {"label": "Nizhny Tagil", "value": "C104815"},
                {"label": "Nizhnyaya Irga", "value": "C104816"},
                {"label": "Nizhnyaya Salda", "value": "C104817"},
                {"label": "Nizhnyaya Tura", "value": "C104818"},
                {"label": "Novaya Lyalya", "value": "C104819"},
                {"label": "Novoasbest", "value": "C104820"},
                {"label": "Novouralsk", "value": "C104821"},
                {"label": "Novoutkinsk", "value": "C104822"},
                {"label": "Obukhovskoye", "value": "C104823"},
                {"label": "Ous", "value": "C104824"},
                {"label": "Pelym", "value": "C104825"},
                {"label": "Pervouralsk", "value": "C104826"},
                {"label": "Petrokamenskoye", "value": "C104827"},
                {"label": "Pokrovskoye", "value": "C104828"},
                {"label": "Polevskoy", "value": "C104829"},
                {"label": "Polunochnoye", "value": "C104830"},
                {"label": "Pyshma", "value": "C104831"},
                {"label": "Reftinskiy", "value": "C104832"},
                {"label": "Revda", "value": "C104833"},
                {"label": "Rezh", "value": "C104834"},
                {"label": "Rudnichnyy", "value": "C104835"},
                {"label": "Sarana", "value": "C104836"},
                {"label": "Serov", "value": "C104837"},
                {"label": "Severka", "value": "C104838"},
                {"label": "Severouralsk", "value": "C104839"},
                {"label": "Shabrovskiy", "value": "C104840"},
                {"label": "Shalya", "value": "C104841"},
                {"label": "Shamary", "value": "C104842"},
                {"label": "Shchelkun", "value": "C104843"},
                {"label": "Shirokaya Rechka", "value": "C104844"},
                {"label": "Shuvakish", "value": "C104845"},
                {"label": "Sosva", "value": "C104846"},
                {"label": "Sovkhoznyy", "value": "C104847"},
                {"label": "Sredneuralsk", "value": "C104848"},
                {"label": "Staropyshminsk", "value": "C104849"},
                {"label": "Staroutkinsk", "value": "C104850"},
                {"label": "Sukhoy Log", "value": "C104851"},
                {"label": "Svobodnyy", "value": "C104852"},
                {"label": "Sylva", "value": "C104853"},
                {"label": "Sysert", "value": "C104854"},
                {"label": "Sysertskiy Rayon", "value": "C104855"},
                {"label": "Tabory", "value": "C104856"},
                {"label": "Talitsa", "value": "C104857"},
                {"label": "Tavda", "value": "C104858"},
                {"label": "Tretiy Severnyy", "value": "C104859"},
                {"label": "Troitskiy", "value": "C104860"},
                {"label": "Tsementnyy", "value": "C104861"},
                {"label": "Tugulym", "value": "C104862"},
                {"label": "Turinsk", "value": "C104863"},
                {"label": "Turinskaya Sloboda", "value": "C104864"},
                {"label": "Ufimskiy", "value": "C104865"},
                {"label": "Uralets", "value": "C104866"},
                {"label": "Valerianovsk", "value": "C104867"},
                {"label": "Verkh-Neyvinskiy", "value": "C104868"},
                {"label": "Verkhneye Dubrovo", "value": "C104869"},
                {"label": "Verkhniy Tagil", "value": "C104870"},
                {"label": "Verkhniye Sergi", "value": "C104871"},
                {"label": "Verkhnyaya Pyshma", "value": "C104872"},
                {"label": "Verkhnyaya Salda", "value": "C104873"},
                {"label": "Verkhnyaya Sinyachikha", "value": "C104874"},
                {"label": "Verkhnyaya Sysert", "value": "C104875"},
                {"label": "Verkhnyaya Tura", "value": "C104876"},
                {"label": "Verkhoturye", "value": "C104877"},
                {"label": "Visim", "value": "C104878"},
                {"label": "Volchansk", "value": "C104879"},
                {"label": "Vostochnyy", "value": "C104880"},
                {"label": "Yekaterinburg", "value": "C104881"},
                {"label": "Yelanskiy", "value": "C104882"},
                {"label": "Yelkino", "value": "C104883"},
                {"label": "Yertarskiy", "value": "C104884"},
                {"label": "Yushala", "value": "C104885"},
                {"label": "Zarechnyy", "value": "C104886"},
                {"label": "Zavodouspenskoye", "value": "C104887"},
                {"label": "Zaykovo", "value": "C104888"},
                {"label": "Zyuzelskiy", "value": "C104889"}],
 "Sveta Ana Municipality": [{"label": "Sv. Ana v Slov. Goricah",
                             "value": "C107831"}],
 "Sveta Trojica v Slovenskih Goricah Municipality": [{"label": "Sv. Trojica v Slov. Goricah",
                                                      "value": "C107832"}],
 "Sveti Andraz v Slovenskih Goricah Municipality": [{"label": "Vitomarci",
                                                     "value": "C107833"}],
 "Sveti Jurij v Slovenskih Goricah Municipality": [{"label": "Sveti Jurij ob Scavnici",
                                                    "value": "C107834"}],
 "Sveti Nikole Municipality": [{"label": "Gorobinci", "value": "C65517"},
                               {"label": "Sveti Nikole", "value": "C65518"}],
 "Sveti Tomaz Municipality": [{"label": "Sveti Tomaz", "value": "C107835"}],
 "Swieqi": [{"label": "Swieqi", "value": "C65923"}],
 "Swietokrzyskie Voivodeship": [{"label": "Backowice", "value": "C90415"},
                                {"label": "Baltow", "value": "C90416"},
                                {"label": "Bejsce", "value": "C90417"},
                                {"label": "Bieliny", "value": "C90418"},
                                {"label": "Blizyn", "value": "C90419"},
                                {"label": "Bodzentyn", "value": "C90420"},
                                {"label": "Bogoria", "value": "C90421"},
                                {"label": "Brody", "value": "C90422"},
                                {"label": "Brzeziny", "value": "C90423"},
                                {"label": "Busko-Zdroj", "value": "C90424"},
                                {"label": "Checiny", "value": "C90425"},
                                {"label": "Chmielnik", "value": "C90426"},
                                {"label": "Cmielow", "value": "C90427"},
                                {"label": "Czarnocin", "value": "C90428"},
                                {"label": "Daleszyce", "value": "C90429"},
                                {"label": "Domaszowice", "value": "C90430"},
                                {"label": "Dwikozy", "value": "C90431"},
                                {"label": "Dzialoszyce", "value": "C90432"},
                                {"label": "Falkow", "value": "C90433"},
                                {"label": "Gnojno", "value": "C90434"},
                                {"label": "Gorno", "value": "C90435"},
                                {"label": "Gowarczow", "value": "C90436"},
                                {"label": "Grzybowa Gora", "value": "C90437"},
                                {"label": "Imielno", "value": "C90438"},
                                {"label": "Iwaniska", "value": "C90439"},
                                {"label": "Jedrzejow", "value": "C90440"},
                                {"label": "Kaniow", "value": "C90441"},
                                {"label": "Kazimierza Wielka",
                                 "value": "C90442"},
                                {"label": "Kielce", "value": "C90443"},
                                {"label": "Kije", "value": "C90444"},
                                {"label": "Klimontow", "value": "C90445"},
                                {"label": "Konskie", "value": "C90446"},
                                {"label": "Koprzywnica", "value": "C90447"},
                                {"label": "Kostomloty Drugie",
                                 "value": "C90448"},
                                {"label": "Kostomloty Pierwsze",
                                 "value": "C90449"},
                                {"label": "Krasocin", "value": "C90450"},
                                {"label": "Kunow", "value": "C90451"},
                                {"label": "Lipnik", "value": "C90452"},
                                {"label": "Laczna", "value": "C90453"},
                                {"label": "Lagow", "value": "C90454"},
                                {"label": "Loniow", "value": "C90455"},
                                {"label": "Lopuszno", "value": "C90456"},
                                {"label": "Lubnice", "value": "C90457"},
                                {"label": "Malogoszcz", "value": "C90458"},
                                {"label": "Maslow", "value": "C90459"},
                                {"label": "Michalow", "value": "C90460"},
                                {"label": "Miedziana Gora", "value": "C90461"},
                                {"label": "Mirzec", "value": "C90462"},
                                {"label": "Mniow", "value": "C90463"},
                                {"label": "Morawica", "value": "C90464"},
                                {"label": "Naglowice", "value": "C90465"},
                                {"label": "Nowa Slupia", "value": "C90466"},
                                {"label": "Nowy Korczyn", "value": "C90467"},
                                {"label": "Obrazow", "value": "C90468"},
                                {"label": "Oksa", "value": "C90469"},
                                {"label": "Olesnica", "value": "C90470"},
                                {"label": "Opatow", "value": "C90471"},
                                {"label": "Opatowiec", "value": "C90472"},
                                {"label": "Osiedle-Nowiny", "value": "C90473"},
                                {"label": "Osiek", "value": "C90474"},
                                {"label": "Ostrowiec Swietokrzyski",
                                 "value": "C90475"},
                                {"label": "Ozarow", "value": "C90476"},
                                {"label": "Pacanow", "value": "C90477"},
                                {"label": "Pawlow", "value": "C90478"},
                                {"label": "Piekoszow", "value": "C90479"},
                                {"label": "Pierzchnica", "value": "C90480"},
                                {"label": "Pinczow", "value": "C90481"},
                                {"label": "Polaniec", "value": "C90482"},
                                {"label": "Powiat buski", "value": "C90483"},
                                {"label": "Powiat jedrzejowski",
                                 "value": "C90484"},
                                {"label": "Powiat kazimierski",
                                 "value": "C90485"},
                                {"label": "Powiat kielecki", "value": "C90486"},
                                {"label": "Powiat konecki", "value": "C90487"},
                                {"label": "Powiat opatowski",
                                 "value": "C90488"},
                                {"label": "Powiat ostrowiecki",
                                 "value": "C90489"},
                                {"label": "Powiat pinczowski",
                                 "value": "C90490"},
                                {"label": "Powiat sandomierski",
                                 "value": "C90491"},
                                {"label": "Powiat skarzyski",
                                 "value": "C90492"},
                                {"label": "Powiat starachowicki",
                                 "value": "C90493"},
                                {"label": "Powiat staszowski",
                                 "value": "C90494"},
                                {"label": "Powiat wloszczowski",
                                 "value": "C90495"},
                                {"label": "Radoszyce", "value": "C90496"},
                                {"label": "Rakow", "value": "C90497"},
                                {"label": "Rogow", "value": "C90498"},
                                {"label": "Ruda Maleniecka", "value": "C90499"},
                                {"label": "Rytwiany", "value": "C90500"},
                                {"label": "Sadowie", "value": "C90501"},
                                {"label": "Samborzec", "value": "C90502"},
                                {"label": "Sandomierz", "value": "C90503"},
                                {"label": "Secemin", "value": "C90504"},
                                {"label": "Sedziszow", "value": "C90505"},
                                {"label": "Skalbmierz", "value": "C90506"},
                                {"label": "Skarzysko Koscielne",
                                 "value": "C90507"},
                                {"label": "Skarzysko-Kamienna",
                                 "value": "C90508"},
                                {"label": "Slupia", "value": "C90509"},
                                {"label": "Smykow", "value": "C90510"},
                                {"label": "Sobkow", "value": "C90511"},
                                {"label": "Solec-Zdroj", "value": "C90512"},
                                {"label": "Staporkow", "value": "C90513"},
                                {"label": "Starachowice", "value": "C90514"},
                                {"label": "Staszow", "value": "C90515"},
                                {"label": "Stopnica", "value": "C90516"},
                                {"label": "Strawczyn", "value": "C90517"},
                                {"label": "Suchedniow", "value": "C90518"},
                                {"label": "Szydlow", "value": "C90519"},
                                {"label": "Tarlow", "value": "C90520"},
                                {"label": "Tuczepy", "value": "C90521"},
                                {"label": "Wachock", "value": "C90522"},
                                {"label": "Wasniow", "value": "C90523"},
                                {"label": "Wielka Wies", "value": "C90524"},
                                {"label": "Wilczyce", "value": "C90525"},
                                {"label": "Wislica", "value": "C90526"},
                                {"label": "Wloszczowa", "value": "C90527"},
                                {"label": "Wodzislaw", "value": "C90528"},
                                {"label": "Wojciechowice", "value": "C90529"},
                                {"label": "Wola Jachowa", "value": "C90530"},
                                {"label": "Zagnansk", "value": "C90531"},
                                {"label": "Zawichost", "value": "C90532"},
                                {"label": "Zlota", "value": "C90533"}],
 "Sylhet District": [{"label": "Baniachang", "value": "C8687"},
                     {"label": "Chhatak", "value": "C8688"},
                     {"label": "Habiganj", "value": "C8689"},
                     {"label": "Jahedpur", "value": "C8690"},
                     {"label": "Maulavi Bazar", "value": "C8691"},
                     {"label": "Maulvibazar", "value": "C8692"},
                     {"label": "Sunamganj", "value": "C8693"},
                     {"label": "Sylhet", "value": "C8694"}],
 "Syunik Province": [{"label": "Agarak", "value": "C1803"},
                     {"label": "Akner", "value": "C1804"},
                     {"label": "Angeghakot", "value": "C1805"},
                     {"label": "Brrnakot", "value": "C1806"},
                     {"label": "Dzorastan", "value": "C1807"},
                     {"label": "Goris", "value": "C1808"},
                     {"label": "Hatsavan", "value": "C1809"},
                     {"label": "Kapan", "value": "C1810"},
                     {"label": "Khndzoresk", "value": "C1811"},
                     {"label": "Meghri", "value": "C1812"},
                     {"label": "Shaghat", "value": "C1813"},
                     {"label": "Shinuhayr", "value": "C1814"},
                     {"label": "Tegh", "value": "C1815"},
                     {"label": "Verishen", "value": "C1816"}],
 "Szabolcs-Szatmar-Bereg County": [{"label": "Ajak", "value": "C44610"},
                                   {"label": "Anarcs", "value": "C44611"},
                                   {"label": "Apagy", "value": "C44612"},
                                   {"label": "Aranyosapati", "value": "C44613"},
                                   {"label": "Baktaloranthaza",
                                    "value": "C44614"},
                                   {"label": "Baktaloranthazai Jaras",
                                    "value": "C44615"},
                                   {"label": "Balkany", "value": "C44616"},
                                   {"label": "Bokony", "value": "C44617"},
                                   {"label": "Buj", "value": "C44618"},
                                   {"label": "Csenger", "value": "C44619"},
                                   {"label": "Csengeri Jaras",
                                    "value": "C44620"},
                                   {"label": "Demecser", "value": "C44621"},
                                   {"label": "Doge", "value": "C44622"},
                                   {"label": "Dombrad", "value": "C44623"},
                                   {"label": "Encsencs", "value": "C44624"},
                                   {"label": "Fehergyarmat", "value": "C44625"},
                                   {"label": "Fehergyarmati Jaras",
                                    "value": "C44626"},
                                   {"label": "Fenyeslitke", "value": "C44627"},
                                   {"label": "Gegeny", "value": "C44628"},
                                   {"label": "Gyulahaza", "value": "C44629"},
                                   {"label": "Hodasz", "value": "C44630"},
                                   {"label": "Ibrany", "value": "C44631"},
                                   {"label": "Ibranyi Jaras",
                                    "value": "C44632"},
                                   {"label": "Kallosemjen", "value": "C44633"},
                                   {"label": "Kalmanhaza", "value": "C44634"},
                                   {"label": "Kantorjanosi", "value": "C44635"},
                                   {"label": "Kek", "value": "C44636"},
                                   {"label": "Kemecse", "value": "C44637"},
                                   {"label": "Kemecsei Jaras",
                                    "value": "C44638"},
                                   {"label": "Kisleta", "value": "C44639"},
                                   {"label": "Kisvarda", "value": "C44640"},
                                   {"label": "Kisvardai Jaras",
                                    "value": "C44641"},
                                   {"label": "Kocsord", "value": "C44642"},
                                   {"label": "Kotaj", "value": "C44643"},
                                   {"label": "Levelek", "value": "C44644"},
                                   {"label": "Mandok", "value": "C44645"},
                                   {"label": "Mariapocs", "value": "C44646"},
                                   {"label": "Mateszalka", "value": "C44647"},
                                   {"label": "Mateszalkai Jaras",
                                    "value": "C44648"},
                                   {"label": "Merk", "value": "C44649"},
                                   {"label": "Nagycserkesz", "value": "C44650"},
                                   {"label": "Nagydobos", "value": "C44651"},
                                   {"label": "Nagyecsed", "value": "C44652"},
                                   {"label": "Nagyhalasz", "value": "C44653"},
                                   {"label": "Nagykallo", "value": "C44654"},
                                   {"label": "Nagykalloi Jaras",
                                    "value": "C44655"},
                                   {"label": "Napkor", "value": "C44656"},
                                   {"label": "Nyirbator", "value": "C44657"},
                                   {"label": "Nyirbatori Jaras",
                                    "value": "C44658"},
                                   {"label": "Nyirbeltek", "value": "C44659"},
                                   {"label": "Nyirbogat", "value": "C44660"},
                                   {"label": "Nyirbogdany", "value": "C44661"},
                                   {"label": "Nyircsaholy", "value": "C44662"},
                                   {"label": "Nyiregyhaza", "value": "C44663"},
                                   {"label": "Nyiregyhazi Jaras",
                                    "value": "C44664"},
                                   {"label": "Nyirgyulaj", "value": "C44665"},
                                   {"label": "Nyirkarasz", "value": "C44666"},
                                   {"label": "Nyirlugos", "value": "C44667"},
                                   {"label": "Nyirmada", "value": "C44668"},
                                   {"label": "Nyirmeggyes", "value": "C44669"},
                                   {"label": "Nyirmihalydi", "value": "C44670"},
                                   {"label": "Nyirpazony", "value": "C44671"},
                                   {"label": "Nyirtass", "value": "C44672"},
                                   {"label": "Nyirtelek", "value": "C44673"},
                                   {"label": "Nyirvasvari", "value": "C44674"},
                                   {"label": "ofeherto", "value": "C44675"},
                                   {"label": "Okoritofulpos",
                                    "value": "C44676"},
                                   {"label": "opalyi", "value": "C44677"},
                                   {"label": "Patroha", "value": "C44678"},
                                   {"label": "Petnehaza", "value": "C44679"},
                                   {"label": "Porcsalma", "value": "C44680"},
                                   {"label": "Rakamaz", "value": "C44681"},
                                   {"label": "Szakoly", "value": "C44682"},
                                   {"label": "Szamosszeg", "value": "C44683"},
                                   {"label": "Tarpa", "value": "C44684"},
                                   {"label": "Tiszabercel", "value": "C44685"},
                                   {"label": "Tiszabezded", "value": "C44686"},
                                   {"label": "Tiszadada", "value": "C44687"},
                                   {"label": "Tiszadob", "value": "C44688"},
                                   {"label": "Tiszaeszlar", "value": "C44689"},
                                   {"label": "Tiszalok", "value": "C44690"},
                                   {"label": "Tiszanagyfalu",
                                    "value": "C44691"},
                                   {"label": "Tiszavasvari", "value": "C44692"},
                                   {"label": "Tiszavasvari Jaras",
                                    "value": "C44693"},
                                   {"label": "Tornyospalca", "value": "C44694"},
                                   {"label": "Tunyogmatolcs",
                                    "value": "C44695"},
                                   {"label": "Tuzser", "value": "C44696"},
                                   {"label": "Tyukod", "value": "C44697"},
                                   {"label": "Ujfeherto", "value": "C44698"},
                                   {"label": "Vaja", "value": "C44699"},
                                   {"label": "Vasarosnameny",
                                    "value": "C44700"},
                                   {"label": "Vasarosnamenyi Jaras",
                                    "value": "C44701"},
                                   {"label": "Zahony", "value": "C44702"},
                                   {"label": "Zahonyi Jaras",
                                    "value": "C44703"}],
 "Ta Xbiex": [{"label": "Ta Xbiex", "value": "C65924"}],
 "Tabasco": [{"label": "Acachapan y Colmena 3ra. Seccion", "value": "C73786"},
             {"label": "alvaro Obregon (Santa Cruz)", "value": "C73787"},
             {"label": "alvaro Obregon 2da. Seccion (El Lechugal)",
              "value": "C73788"},
             {"label": "Amado Gomez", "value": "C73789"},
             {"label": "Anacleto Canabal 2da. Seccion", "value": "C73790"},
             {"label": "Anacleto Canabal 3ra. Seccion", "value": "C73791"},
             {"label": "Andres Quintana Roo", "value": "C73792"},
             {"label": "Apatzingan", "value": "C73793"},
             {"label": "Aquiles Serdan", "value": "C73794"},
             {"label": "Aquiles Serdan (San Fernando)", "value": "C73795"},
             {"label": "Aquiles Serdan 1ra. Seccion", "value": "C73796"},
             {"label": "Arena 1ra. Seccion", "value": "C73797"},
             {"label": "Arena 6ta. Seccion (La Bolsa)", "value": "C73798"},
             {"label": "Arena de Hidalgo", "value": "C73799"},
             {"label": "Arroyo Hondo Abejonal", "value": "C73800"},
             {"label": "Astapa", "value": "C73801"},
             {"label": "Ayapa", "value": "C73802"},
             {"label": "Balancan", "value": "C73803"},
             {"label": "Banderas (Guatacalca 2da. Seccion)", "value": "C73804"},
             {"label": "Barrancas y Amate 3ra. Seccion", "value": "C73805"},
             {"label": "Belen", "value": "C73806"},
             {"label": "Benito Gonzalez", "value": "C73807"},
             {"label": "Benito Juarez", "value": "C73808"},
             {"label": "Benito Juarez (La Playita)", "value": "C73809"},
             {"label": "Betania", "value": "C73810"},
             {"label": "Blasillo 1ra. Seccion (Nicolas Bravo)",
              "value": "C73811"},
             {"label": "Boquiapa", "value": "C73812"},
             {"label": "Bosque de Saloya", "value": "C73813"},
             {"label": "Brisas del Carrizal", "value": "C73814"},
             {"label": "Buena Vista (Apasco)", "value": "C73815"},
             {"label": "Buena Vista (Puxcatan)", "value": "C73816"},
             {"label": "Buena Vista 1ra. Seccion", "value": "C73817"},
             {"label": "Buenavista", "value": "C73818"},
             {"label": "C-32 (Licenciado Francisco Trujillo Gurria)",
              "value": "C73819"},
             {"label": "C-41 (Licenciado Carlos A. Madrazo)",
              "value": "C73820"},
             {"label": "Caobanal 1ra. Seccion (Mezcalapa)", "value": "C73821"},
             {"label": "Caparroso", "value": "C73822"},
             {"label": "Capitan Felipe Castellanos Diaz (San Pedro)",
              "value": "C73823"},
             {"label": "Cardenas", "value": "C73824"},
             {"label": "Ceiba 1ra. Seccion (Jahuactal)", "value": "C73825"},
             {"label": "Centro", "value": "C73826"},
             {"label": "Chable", "value": "C73827"},
             {"label": "Chichicapa", "value": "C73828"},
             {"label": "Chichicastle 1ra. Seccion", "value": "C73829"},
             {"label": "Chontalpa", "value": "C73830"},
             {"label": "Ciudad Pemex", "value": "C73831"},
             {"label": "Cocohital", "value": "C73832"},
             {"label": "Comalcalco", "value": "C73833"},
             {"label": "Constitucion", "value": "C73834"},
             {"label": "Corriente 1ra. Seccion", "value": "C73835"},
             {"label": "Cucuyulapa Primera Seccion", "value": "C73836"},
             {"label": "Culico 2da. Seccion", "value": "C73837"},
             {"label": "Cumuapa 1ra. Seccion", "value": "C73838"},
             {"label": "Cunduacan", "value": "C73839"},
             {"label": "Cupilco", "value": "C73840"},
             {"label": "Dos Ceibas", "value": "C73841"},
             {"label": "Dos Montes", "value": "C73842"},
             {"label": "El Bajio", "value": "C73843"},
             {"label": "El Bellote (Miguel de la Madrid)", "value": "C73844"},
             {"label": "El Cedro", "value": "C73845"},
             {"label": "El Congo", "value": "C73846"},
             {"label": "El Dorado", "value": "C73847"},
             {"label": "El Escribano", "value": "C73848"},
             {"label": "El Recreo", "value": "C73849"},
             {"label": "El Tigre", "value": "C73850"},
             {"label": "El Tortuguero", "value": "C73851"},
             {"label": "El Triunfo", "value": "C73852"},
             {"label": "El Tular", "value": "C73853"},
             {"label": "Encrucijada 3ra. Seccion (Las Calzadas)",
              "value": "C73854"},
             {"label": "Estapilla", "value": "C73855"},
             {"label": "Eureka y Belen", "value": "C73856"},
             {"label": "Fraccionamiento Ocuiltzapotlan Dos", "value": "C73857"},
             {"label": "Francisco I. Madero", "value": "C73858"},
             {"label": "Francisco Rueda", "value": "C73859"},
             {"label": "Frontera", "value": "C73860"},
             {"label": "Gaviotas Sur (El Cedral)", "value": "C73861"},
             {"label": "General Luis Felipe Dominguez Suarez",
              "value": "C73862"},
             {"label": "Gobernador Cruz", "value": "C73863"},
             {"label": "Gobernadores", "value": "C73864"},
             {"label": "Gregorio Mendez", "value": "C73865"},
             {"label": "Guatacalca", "value": "C73866"},
             {"label": "Guaytalpa", "value": "C73867"},
             {"label": "Habanero 1ra. Seccion (Venustiano Carranza)",
              "value": "C73868"},
             {"label": "Hermenegildo Galeana 2da. Seccion", "value": "C73869"},
             {"label": "Huacapa y Amestoy", "value": "C73870"},
             {"label": "Huapacal 1ra. Seccion", "value": "C73871"},
             {"label": "Huapacal 2da. Seccion (Punta Brava)",
              "value": "C73872"},
             {"label": "Huapinol", "value": "C73873"},
             {"label": "Huimanguillo", "value": "C73874"},
             {"label": "Ignacio Allende", "value": "C73875"},
             {"label": "Ignacio Gutierrez Gomez", "value": "C73876"},
             {"label": "Ignacio Zaragoza", "value": "C73877"},
             {"label": "Ignacio Zaragoza 1ra. Seccion", "value": "C73878"},
             {"label": "Iquinuapa", "value": "C73879"},
             {"label": "Jalapa", "value": "C73880"},
             {"label": "Jalapita", "value": "C73881"},
             {"label": "Jalpa de Mendez", "value": "C73882"},
             {"label": "Jalupa", "value": "C73883"},
             {"label": "Jimenez", "value": "C73884"},
             {"label": "Jolochero 2da. Seccion", "value": "C73885"},
             {"label": "Jonuta", "value": "C73886"},
             {"label": "Jose Colomo", "value": "C73887"},
             {"label": "La Ceiba", "value": "C73888"},
             {"label": "La Curva", "value": "C73889"},
             {"label": "La Escalera", "value": "C73890"},
             {"label": "La Estrella", "value": "C73891"},
             {"label": "La Lima", "value": "C73892"},
             {"label": "La Lucha", "value": "C73893"},
             {"label": "La Manga 2da. Seccion (El Jobal)", "value": "C73894"},
             {"label": "La Palma", "value": "C73895"},
             {"label": "La Peninsula", "value": "C73896"},
             {"label": "La Sabana", "value": "C73897"},
             {"label": "La Selva", "value": "C73898"},
             {"label": "La Union", "value": "C73899"},
             {"label": "La Victoria", "value": "C73900"},
             {"label": "Lagartera 1ra. Seccion", "value": "C73901"},
             {"label": "Libertad", "value": "C73902"},
             {"label": "Lomitas", "value": "C73903"},
             {"label": "Los Cenotes", "value": "C73904"},
             {"label": "Los Sauces", "value": "C73905"},
             {"label": "Luis Gil Perez", "value": "C73906"},
             {"label": "Mactun", "value": "C73907"},
             {"label": "Macultepec", "value": "C73908"},
             {"label": "Macuspana", "value": "C73909"},
             {"label": "Mazateupa", "value": "C73910"},
             {"label": "Mecatepec", "value": "C73911"},
             {"label": "Mecoacan", "value": "C73912"},
             {"label": "Mecoacan 2da. Seccion (San Lorenzo)",
              "value": "C73913"},
             {"label": "Medellin y Madero Segunda Seccion", "value": "C73914"},
             {"label": "Melchor Ocampo", "value": "C73915"},
             {"label": "Miguel Hidalgo", "value": "C73916"},
             {"label": "Miguel Hidalgo 2da. Seccion B (La Natividad)",
              "value": "C73917"},
             {"label": "Monte Grande", "value": "C73918"},
             {"label": "Monte Largo 1ra. Seccion", "value": "C73919"},
             {"label": "Morelos Piedra 3ra. Seccion", "value": "C73920"},
             {"label": "Multe", "value": "C73921"},
             {"label": "Nacajuca", "value": "C73922"},
             {"label": "Netzahualcoyotl", "value": "C73923"},
             {"label": "Nicolas Bravo", "value": "C73924"},
             {"label": "Nicolas Bravo 5ta. Seccion (Punta Brava)",
              "value": "C73925"},
             {"label": "Norte 1ra. Seccion (San Julian)", "value": "C73926"},
             {"label": "Nueva Division del Bayo (Guatemala)",
              "value": "C73927"},
             {"label": "Nuevo Torno Largo", "value": "C73928"},
             {"label": "Occidente (San Francisco)", "value": "C73929"},
             {"label": "Occidente 4ta. Seccion", "value": "C73930"},
             {"label": "Ocuapan", "value": "C73931"},
             {"label": "Olcuatitan", "value": "C73932"},
             {"label": "Oriente 1ra. Seccion", "value": "C73933"},
             {"label": "Oriente 1ra. Seccion (Santo Domingo)",
              "value": "C73934"},
             {"label": "Oriente 2da. Seccion (Palma Huaca)", "value": "C73935"},
             {"label": "Oriente 2da. Seccion (San Cayetano)",
              "value": "C73936"},
             {"label": "Oriente 6ta. Seccion (Los Mulatos)", "value": "C73937"},
             {"label": "Oxiacaque", "value": "C73938"},
             {"label": "Oxolotan", "value": "C73939"},
             {"label": "Pablo L. Sidar", "value": "C73940"},
             {"label": "Palo Mulato", "value": "C73941"},
             {"label": "Palomas", "value": "C73942"},
             {"label": "Paraiso", "value": "C73943"},
             {"label": "Parilla", "value": "C73944"},
             {"label": "Parrilla II", "value": "C73945"},
             {"label": "Paso de la Mina 2da. Seccion (Barrial)",
              "value": "C73946"},
             {"label": "Paso de la Mina 3ra. Seccion", "value": "C73947"},
             {"label": "Pechucalco", "value": "C73948"},
             {"label": "Pechucalco 2da. Seccion (Las Cruces)",
              "value": "C73949"},
             {"label": "Pejelagartero 1ra. Seccion (Plataforma)",
              "value": "C73950"},
             {"label": "Penjamo", "value": "C73951"},
             {"label": "Playas del Rosario", "value": "C73952"},
             {"label": "Poblado C-11 Jose Maria Morelos y Pavon",
              "value": "C73953"},
             {"label": "Poblado C-21 Licenciado Benito Juarez Garcia",
              "value": "C73954"},
             {"label": "Poblado C-33 20 de Noviembre", "value": "C73955"},
             {"label": "Pomoca", "value": "C73956"},
             {"label": "Potrerillo", "value": "C73957"},
             {"label": "Pueblo Nuevo de las Raices", "value": "C73958"},
             {"label": "Pueblo Viejo", "value": "C73959"},
             {"label": "Puerto Ceiba", "value": "C73960"},
             {"label": "Puxcatan", "value": "C73961"},
             {"label": "Quintin Arauz", "value": "C73962"},
             {"label": "Rancho Nuevo", "value": "C73963"},
             {"label": "Raya Zaragoza", "value": "C73964"},
             {"label": "Reforma", "value": "C73965"},
             {"label": "Rio de Teapa", "value": "C73966"},
             {"label": "Rio Seco 2da. Seccion (Santiaguito)",
              "value": "C73967"},
             {"label": "Rio Viejo Primera Seccion", "value": "C73968"},
             {"label": "Saloya 2da. Seccion", "value": "C73969"},
             {"label": "Samarkanda", "value": "C73970"},
             {"label": "San Carlos", "value": "C73971"},
             {"label": "San Simon", "value": "C73972"},
             {"label": "Santa Rosalia", "value": "C73973"},
             {"label": "Santana 2da. Seccion B (La Palma)", "value": "C73974"},
             {"label": "Sargento Lopez 2da. Seccion (El Chuzo)",
              "value": "C73975"},
             {"label": "Simon Sarlat", "value": "C73976"},
             {"label": "Soyataco", "value": "C73977"},
             {"label": "Tacotalpa", "value": "C73978"},
             {"label": "Tamulte de las Sabanas", "value": "C73979"},
             {"label": "Tapijulapa", "value": "C73980"},
             {"label": "Tapotzingo", "value": "C73981"},
             {"label": "Taxco", "value": "C73982"},
             {"label": "Teapa", "value": "C73983"},
             {"label": "Tecoluta 2da. Seccion", "value": "C73984"},
             {"label": "Tecominoacan", "value": "C73985"},
             {"label": "Tenosique", "value": "C73986"},
             {"label": "Tepetitan", "value": "C73987"},
             {"label": "Tequila 1ra. Seccion (La Aurora)", "value": "C73988"},
             {"label": "Transito Tular", "value": "C73989"},
             {"label": "Tres Bocas 1ra. Seccion", "value": "C73990"},
             {"label": "Tucta", "value": "C73991"},
             {"label": "Veinte de Noviembre", "value": "C73992"},
             {"label": "Vicente Guerrero", "value": "C73993"},
             {"label": "Villa Aldama", "value": "C73994"},
             {"label": "Villa la Venta", "value": "C73995"},
             {"label": "Villa Tecolutilla", "value": "C73996"},
             {"label": "Villa Vicente Guerrero", "value": "C73997"},
             {"label": "Villahermosa", "value": "C73998"},
             {"label": "Xicotencatl", "value": "C73999"}],
 "Tabor Municipality": [{"label": "Tabor", "value": "C107836"}],
 "Tabora": [{"label": "Bukene", "value": "C118331"},
            {"label": "Igurubi", "value": "C118332"},
            {"label": "Kaliua", "value": "C118333"},
            {"label": "Mabama", "value": "C118334"},
            {"label": "Sikonge", "value": "C118335"},
            {"label": "Tabora", "value": "C118336"},
            {"label": "Tumbi", "value": "C118337"},
            {"label": "Usoke", "value": "C118338"}],
 "Tabuk": [{"label": "Al Wajh", "value": "C106818"},
           {"label": "Duba", "value": "C106819"},
           {"label": "Tabuk", "value": "C106820"},
           {"label": "Umm Lajj", "value": "C106821"}],
 "Tachira": [{"label": "Colon", "value": "C147232"},
             {"label": "La Fria", "value": "C147233"},
             {"label": "La Grita", "value": "C147234"},
             {"label": "Municipio Jose Maria Vargas", "value": "C147235"},
             {"label": "Rubio", "value": "C147236"},
             {"label": "San Antonio del Tachira", "value": "C147237"},
             {"label": "San Cristobal", "value": "C147238"},
             {"label": "Tariba", "value": "C147239"}],
 "Tacna": [{"label": "Calana", "value": "C81083"},
           {"label": "Candarave", "value": "C81084"},
           {"label": "Chipispaya", "value": "C81085"},
           {"label": "Chuquitira", "value": "C81086"},
           {"label": "Ilabaya", "value": "C81087"},
           {"label": "Jorge Basadre", "value": "C81088"},
           {"label": "Provincia de Tacna", "value": "C81089"},
           {"label": "Provincia de Tarata", "value": "C81090"},
           {"label": "Pujocucho", "value": "C81091"},
           {"label": "Quilahuani", "value": "C81092"},
           {"label": "Tacna", "value": "C81093"},
           {"label": "Tarata", "value": "C81094"},
           {"label": "Totoral", "value": "C81095"}],
 "Tacuarembo Department": [{"label": "Curtina", "value": "C146974"},
                           {"label": "Paso de los Toros", "value": "C146975"},
                           {"label": "Tacuarembo", "value": "C146976"}],
 "Tadjourah Region": [{"label": "Dorra", "value": "C23816"},
                      {"label": "Tadjourah", "value": "C23817"}],
 "Tafea": [{"label": "Isangel", "value": "C147121"}],
 "Tafilah Governorate": [{"label": "At Tafilah", "value": "C64272"},
                         {"label": "Busayra", "value": "C64273"}],
 "Tahoua Region": [{"label": "Abalak", "value": "C78074"},
                   {"label": "Birni N Konni", "value": "C78075"},
                   {"label": "Bouza", "value": "C78076"},
                   {"label": "Departement d Illela", "value": "C78077"},
                   {"label": "Departement de Birni Nkonni", "value": "C78078"},
                   {"label": "Departement de Bouza", "value": "C78079"},
                   {"label": "Departement de Keita", "value": "C78080"},
                   {"label": "Departement de Madaoua", "value": "C78081"},
                   {"label": "Departement de Tahoua", "value": "C78082"},
                   {"label": "Departement de Tchin-Tabaraden",
                    "value": "C78083"},
                   {"label": "Illela", "value": "C78084"},
                   {"label": "Keita", "value": "C78085"},
                   {"label": "Madaoua", "value": "C78086"},
                   {"label": "Tahoua", "value": "C78087"},
                   {"label": "Tchintabaraden", "value": "C78088"}],
 "Taichung": [{"label": "Taichung", "value": "C117979"},
              {"label": "Taichung City", "value": "C117980"}],
 "Tainan": [{"label": "Tainan", "value": "C117981"},
            {"label": "Yujing", "value": "C117982"}],
 "Taipei": [{"label": "Banqiao", "value": "C117983"},
            {"label": "Jiufen", "value": "C117984"},
            {"label": "Taipei", "value": "C117985"},
            {"label": "Taipei City", "value": "C117986"}],
 "Taitung": [{"label": "Taitung", "value": "C117987"},
             {"label": "Taitung City", "value": "C117988"}],
 "Taiwan": [{"label": "Baoying", "value": "C19293"},
            {"label": "Changshu City", "value": "C19294"},
            {"label": "Changzhou", "value": "C19295"},
            {"label": "Chengxiang", "value": "C19296"},
            {"label": "Dazhong", "value": "C19297"},
            {"label": "Dongkan", "value": "C19298"},
            {"label": "Dongtai", "value": "C19299"},
            {"label": "Fengxian", "value": "C19300"},
            {"label": "Gaogou", "value": "C19301"},
            {"label": "Gaoyou", "value": "C19302"},
            {"label": "Guiren", "value": "C19303"},
            {"label": "Haizhou", "value": "C19304"},
            {"label": "Hede", "value": "C19305"},
            {"label": "Huaian", "value": "C19306"},
            {"label": "Huaian Shi", "value": "C19307"},
            {"label": "Huilong", "value": "C19308"},
            {"label": "Hutang", "value": "C19309"},
            {"label": "Jiangyan", "value": "C19310"},
            {"label": "Jiangyin", "value": "C19311"},
            {"label": "Jingjiang", "value": "C19312"},
            {"label": "Jinsha", "value": "C19313"},
            {"label": "Juegang", "value": "C19314"},
            {"label": "Kunshan", "value": "C19315"},
            {"label": "Lianyungang Shi", "value": "C19316"},
            {"label": "Licheng", "value": "C19317"},
            {"label": "Mudu", "value": "C19318"},
            {"label": "Nanjing", "value": "C19319"},
            {"label": "Nantong", "value": "C19320"},
            {"label": "Pizhou", "value": "C19321"},
            {"label": "Qinnan", "value": "C19322"},
            {"label": "Rucheng", "value": "C19323"},
            {"label": "Sanmao", "value": "C19324"},
            {"label": "Songling", "value": "C19325"},
            {"label": "Suicheng", "value": "C19326"},
            {"label": "Suzhou", "value": "C19327"},
            {"label": "Taixing", "value": "C19328"},
            {"label": "Taizhou", "value": "C19329"},
            {"label": "Tongshan", "value": "C19330"},
            {"label": "Wuxi", "value": "C19331"},
            {"label": "Xiannu", "value": "C19332"},
            {"label": "Xiaolingwei", "value": "C19333"},
            {"label": "Xinghua", "value": "C19334"},
            {"label": "Xinpu", "value": "C19335"},
            {"label": "Yancheng", "value": "C19336"},
            {"label": "Yangzhou", "value": "C19337"},
            {"label": "Yicheng", "value": "C19338"},
            {"label": "Yushan", "value": "C19339"},
            {"label": "Zhangjiagang", "value": "C19340"},
            {"label": "Zhenjiang", "value": "C19341"},
            {"label": "Zhenzhou", "value": "C19342"},
            {"label": "Zhongxing", "value": "C19343"},
            {"label": "Zhouzhuang", "value": "C19344"}],
 "Taizz Governorate": [{"label": "Al Maafer", "value": "C148062"},
                       {"label": "Al Mawasit", "value": "C148063"},
                       {"label": "Al Misrakh", "value": "C148064"},
                       {"label": "Al Mudhaffar", "value": "C148065"},
                       {"label": "Al Mukha", "value": "C148066"},
                       {"label": "Al Qahirah", "value": "C148067"},
                       {"label": "Al Waziiyah", "value": "C148068"},
                       {"label": "As Silw", "value": "C148069"},
                       {"label": "Ash Shamayatayn", "value": "C148070"},
                       {"label": "At Taizziyah", "value": "C148071"},
                       {"label": "Dhubab", "value": "C148072"},
                       {"label": "Dimnat Khadir", "value": "C148073"},
                       {"label": "Hayfan", "value": "C148074"},
                       {"label": "Jabal Habashy", "value": "C148075"},
                       {"label": "Maqbanah", "value": "C148076"},
                       {"label": "Mashraa Wa Hadnan", "value": "C148077"},
                       {"label": "Mawiyah", "value": "C148078"},
                       {"label": "Mawza", "value": "C148079"},
                       {"label": "Sabir Al Mawadim", "value": "C148080"},
                       {"label": "Salh", "value": "C148081"},
                       {"label": "Sama", "value": "C148082"},
                       {"label": "Sharab Ar Rawnah", "value": "C148083"},
                       {"label": "Sharab As Salam", "value": "C148084"},
                       {"label": "Taizz", "value": "C148085"},
                       {"label": "Village of ALAMRAH", "value": "C148086"}],
 "Tak": [{"label": "Amphoe Ban Tak", "value": "C119468"},
         {"label": "Amphoe Mae Ramat", "value": "C119469"},
         {"label": "Amphoe Mae Sot", "value": "C119470"},
         {"label": "Amphoe Mueang Tak", "value": "C119471"},
         {"label": "Amphoe Phop Phra", "value": "C119472"},
         {"label": "Amphoe Tha Song Yang", "value": "C119473"},
         {"label": "Amphoe Umphang", "value": "C119474"},
         {"label": "Amphoe Wang Chao", "value": "C119475"},
         {"label": "Ban Tak", "value": "C119476"},
         {"label": "Mae Ramat", "value": "C119477"},
         {"label": "Mae Sot", "value": "C119478"},
         {"label": "Tak", "value": "C119479"}],
 "Takamaka": [{"label": "Takamaka", "value": "C107236"}],
 "Takeo": [{"label": "Krong Doun Kaev", "value": "C16560"},
           {"label": "Phumi Veal Sre", "value": "C16561"},
           {"label": "Srok Angkor Borei", "value": "C16562"},
           {"label": "Srok Borei Cholsar", "value": "C16563"},
           {"label": "Takeo", "value": "C16564"}],
 "Takhar": [{"label": "art Khwajah", "value": "C88"},
            {"label": "Taloqan", "value": "C89"}],
 "Talas Region": [{"label": "Kara-Buurinskiy Rayon", "value": "C64760"},
                  {"label": "Talas", "value": "C64761"},
                  {"label": "Talasskiy Rayon", "value": "C64762"}],
 "Talsi Municipality": [{"label": "Sabile", "value": "C64945"},
                        {"label": "Stende", "value": "C64946"},
                        {"label": "Talsi", "value": "C64947"},
                        {"label": "Valdemarpils", "value": "C64948"}],
 "Tamanghasset": [{"label": "I-n-Salah", "value": "C458"},
                  {"label": "Tamanrasset", "value": "C459"}],
 "Tamaulipas": [{"label": "Abasolo", "value": "C74000"},
                {"label": "Aldama", "value": "C74001"},
                {"label": "Alfredo V. Bonfil", "value": "C74002"},
                {"label": "Altamira", "value": "C74003"},
                {"label": "Anahuac", "value": "C74004"},
                {"label": "Antiguo Morelos", "value": "C74005"},
                {"label": "Barretal", "value": "C74006"},
                {"label": "Burgos", "value": "C74007"},
                {"label": "Bustamante", "value": "C74008"},
                {"label": "Camargo", "value": "C74009"},
                {"label": "Carboneras", "value": "C74010"},
                {"label": "Carrillo Puerto", "value": "C74011"},
                {"label": "Casas", "value": "C74012"},
                {"label": "CEFERESO Numero 3", "value": "C74013"},
                {"label": "Ciudad Camargo", "value": "C74014"},
                {"label": "Ciudad Gustavo Diaz Ordaz", "value": "C74015"},
                {"label": "Ciudad Madero", "value": "C74016"},
                {"label": "Ciudad Mante", "value": "C74017"},
                {"label": "Ciudad Miguel Aleman", "value": "C74018"},
                {"label": "Ciudad Rio Bravo", "value": "C74019"},
                {"label": "Ciudad Tula", "value": "C74020"},
                {"label": "Ciudad Victoria", "value": "C74021"},
                {"label": "Comales", "value": "C74022"},
                {"label": "Cruillas", "value": "C74023"},
                {"label": "Cuauhtemoc", "value": "C74024"},
                {"label": "El Abra", "value": "C74025"},
                {"label": "El Barrancon del Tio Blas (El Barrancon)",
                 "value": "C74026"},
                {"label": "El Campanario y Oradel", "value": "C74027"},
                {"label": "El Control", "value": "C74028"},
                {"label": "El Galaneno", "value": "C74029"},
                {"label": "El Limon", "value": "C74030"},
                {"label": "El Mante", "value": "C74031"},
                {"label": "El Realito", "value": "C74032"},
                {"label": "Estacion Santa Engracia", "value": "C74033"},
                {"label": "Esteros", "value": "C74034"},
                {"label": "Gonzalez", "value": "C74035"},
                {"label": "Graciano Sanchez", "value": "C74036"},
                {"label": "Guadalupe Victoria", "value": "C74037"},
                {"label": "Guemes", "value": "C74038"},
                {"label": "Guemez", "value": "C74039"},
                {"label": "Guerrero", "value": "C74040"},
                {"label": "Guillermo Zuniga", "value": "C74041"},
                {"label": "Gustavo Diaz Ordaz", "value": "C74042"},
                {"label": "Heroica Matamoros", "value": "C74043"},
                {"label": "Hidalgo", "value": "C74044"},
                {"label": "Jaumave", "value": "C74045"},
                {"label": "Jimenez", "value": "C74046"},
                {"label": "La Colonia", "value": "C74047"},
                {"label": "La Libertad", "value": "C74048"},
                {"label": "La Mision", "value": "C74049"},
                {"label": "La Pedrera", "value": "C74050"},
                {"label": "La Pesca", "value": "C74051"},
                {"label": "Las Higuerillas", "value": "C74052"},
                {"label": "Lazaro Cardenas", "value": "C74053"},
                {"label": "Llera", "value": "C74054"},
                {"label": "Llera de Canales", "value": "C74055"},
                {"label": "Lomas del Real", "value": "C74056"},
                {"label": "Lopez Rayon", "value": "C74057"},
                {"label": "Los Aztecas", "value": "C74058"},
                {"label": "Los Cavazos", "value": "C74059"},
                {"label": "Los Guerra", "value": "C74060"},
                {"label": "Maclovio Herrera", "value": "C74061"},
                {"label": "Magdaleno Cedillo", "value": "C74062"},
                {"label": "Mainero", "value": "C74063"},
                {"label": "Matamoros", "value": "C74064"},
                {"label": "Mendez", "value": "C74065"},
                {"label": "Mier", "value": "C74066"},
                {"label": "Miguel Aleman", "value": "C74067"},
                {"label": "Miquihuana", "value": "C74068"},
                {"label": "Miramar", "value": "C74069"},
                {"label": "Nicolas Bravo", "value": "C74070"},
                {"label": "Nueva Apolonia", "value": "C74071"},
                {"label": "Nueva Ciudad Guerrero", "value": "C74072"},
                {"label": "Nueva Villa de Padilla", "value": "C74073"},
                {"label": "Nuevo Cereso Regional de Altamira",
                 "value": "C74074"},
                {"label": "Nuevo Laredo", "value": "C74075"},
                {"label": "Nuevo Morelos", "value": "C74076"},
                {"label": "Nuevo Progreso", "value": "C74077"},
                {"label": "Nuevo Tantoan", "value": "C74078"},
                {"label": "Ocampo", "value": "C74079"},
                {"label": "Padilla", "value": "C74080"},
                {"label": "Palmillas", "value": "C74081"},
                {"label": "Plan de Ayala", "value": "C74082"},
                {"label": "Primero de Mayo", "value": "C74083"},
                {"label": "Quintero", "value": "C74084"},
                {"label": "Ramirez", "value": "C74085"},
                {"label": "Reynosa", "value": "C74086"},
                {"label": "Ricardo Flores Magon", "value": "C74087"},
                {"label": "Rio Bravo", "value": "C74088"},
                {"label": "San Antonio Rayon", "value": "C74089"},
                {"label": "San Carlos", "value": "C74090"},
                {"label": "San Fernando", "value": "C74091"},
                {"label": "San German", "value": "C74092"},
                {"label": "San Nicolas", "value": "C74093"},
                {"label": "Santa Adelaida", "value": "C74094"},
                {"label": "Santa Apolonia", "value": "C74095"},
                {"label": "Santander Jimenez", "value": "C74096"},
                {"label": "Soto la Marina", "value": "C74097"},
                {"label": "Tampico", "value": "C74098"},
                {"label": "Tampiquito", "value": "C74099"},
                {"label": "Tula", "value": "C74100"},
                {"label": "Unidos Avanzamos", "value": "C74101"},
                {"label": "Ursulo Galvan", "value": "C74102"},
                {"label": "Valadeces", "value": "C74103"},
                {"label": "Valle Hermoso", "value": "C74104"},
                {"label": "Vamos Tamaulipas", "value": "C74105"},
                {"label": "Victoria", "value": "C74106"},
                {"label": "Villa Mainero", "value": "C74107"},
                {"label": "Villagran", "value": "C74108"},
                {"label": "Xicotencatl", "value": "C74109"}],
 "Tambacounda Region": [{"label": "Tambacounda", "value": "C106876"},
                        {"label": "Tambacounda Department",
                         "value": "C106877"}],
 "Tambov Oblast": [{"label": "Bokino", "value": "C104890"},
                   {"label": "Bondarskiy Rayon", "value": "C104891"},
                   {"label": "Donskoye", "value": "C104892"},
                   {"label": "Gavrilovka Vtoraya", "value": "C104893"},
                   {"label": "Goreloye", "value": "C104894"},
                   {"label": "Inzhavino", "value": "C104895"},
                   {"label": "Kirsanov", "value": "C104896"},
                   {"label": "Kirsanovskiy Rayon", "value": "C104897"},
                   {"label": "Komsomolets", "value": "C104898"},
                   {"label": "Kotovsk", "value": "C104899"},
                   {"label": "Michurinsk", "value": "C104900"},
                   {"label": "Mordovo", "value": "C104901"},
                   {"label": "Morshansk", "value": "C104902"},
                   {"label": "Muchkapskiy", "value": "C104903"},
                   {"label": "Novaya Lyada", "value": "C104904"},
                   {"label": "Novopokrovka", "value": "C104905"},
                   {"label": "Pervomayskiy", "value": "C104906"},
                   {"label": "Pichayevo", "value": "C104907"},
                   {"label": "Platonovka", "value": "C104908"},
                   {"label": "Pokrovo-Prigorodnoye", "value": "C104909"},
                   {"label": "Rasskazovo", "value": "C104910"},
                   {"label": "Rasskazovskiy Rayon", "value": "C104911"},
                   {"label": "Rzhaksa", "value": "C104912"},
                   {"label": "Sampurskiy Rayon", "value": "C104913"},
                   {"label": "Satinka", "value": "C104914"},
                   {"label": "Selezni", "value": "C104915"},
                   {"label": "Sosnovka", "value": "C104916"},
                   {"label": "Staroyuryevo", "value": "C104917"},
                   {"label": "Tambov", "value": "C104918"},
                   {"label": "Tokarevskiy Rayon", "value": "C104919"},
                   {"label": "Uvarovo", "value": "C104920"},
                   {"label": "Zavoronezhskoye", "value": "C104921"},
                   {"label": "Zherdevka", "value": "C104922"},
                   {"label": "Znamenka", "value": "C104923"},
                   {"label": "Znamenskiy Rayon", "value": "C104924"}],
 "Tamil Nadu": [{"label": "Abiramam", "value": "C47741"},
                {"label": "Adirampattinam", "value": "C47742"},
                {"label": "Aduthurai", "value": "C47743"},
                {"label": "Alagapuram", "value": "C47744"},
                {"label": "Alandur", "value": "C47745"},
                {"label": "Alanganallur", "value": "C47746"},
                {"label": "Alangayam", "value": "C47747"},
                {"label": "Alangudi", "value": "C47748"},
                {"label": "Alangulam", "value": "C47749"},
                {"label": "Alappakkam", "value": "C47750"},
                {"label": "Alwa Tirunagari", "value": "C47751"},
                {"label": "Ambasamudram", "value": "C47752"},
                {"label": "Ambattur", "value": "C47753"},
                {"label": "Ambur", "value": "C47754"},
                {"label": "Ammapettai", "value": "C47755"},
                {"label": "Anamalais", "value": "C47756"},
                {"label": "Andippatti", "value": "C47757"},
                {"label": "Annamalainagar", "value": "C47758"},
                {"label": "Annavasal", "value": "C47759"},
                {"label": "Annur", "value": "C47760"},
                {"label": "Anthiyur", "value": "C47761"},
                {"label": "Arakkonam", "value": "C47762"},
                {"label": "Arantangi", "value": "C47763"},
                {"label": "Arcot", "value": "C47764"},
                {"label": "Arimalam", "value": "C47765"},
                {"label": "Ariyalur", "value": "C47766"},
                {"label": "Arni", "value": "C47767"},
                {"label": "Arumbavur", "value": "C47768"},
                {"label": "Arumuganeri", "value": "C47769"},
                {"label": "Aruppukkottai", "value": "C47770"},
                {"label": "Aruvankad", "value": "C47771"},
                {"label": "Attayyampatti", "value": "C47772"},
                {"label": "Attur", "value": "C47773"},
                {"label": "Auroville", "value": "C47774"},
                {"label": "Avadi", "value": "C47775"},
                {"label": "Avinashi", "value": "C47776"},
                {"label": "Ayakudi", "value": "C47777"},
                {"label": "Ayyampettai", "value": "C47778"},
                {"label": "Belur", "value": "C47779"},
                {"label": "Bhavani", "value": "C47780"},
                {"label": "Bodinayakkanur", "value": "C47781"},
                {"label": "Chengam", "value": "C47782"},
                {"label": "Chennai", "value": "C47783"},
                {"label": "Chennimalai", "value": "C47784"},
                {"label": "Chetput", "value": "C47785"},
                {"label": "Chettipalaiyam", "value": "C47786"},
                {"label": "Cheyyar", "value": "C47787"},
                {"label": "Cheyyur", "value": "C47788"},
                {"label": "Chidambaram", "value": "C47789"},
                {"label": "Chingleput", "value": "C47790"},
                {"label": "Chinna Salem", "value": "C47791"},
                {"label": "Chinnamanur", "value": "C47792"},
                {"label": "Chinnasekkadu", "value": "C47793"},
                {"label": "Cholapuram", "value": "C47794"},
                {"label": "Coimbatore", "value": "C47795"},
                {"label": "Colachel", "value": "C47796"},
                {"label": "Cuddalore", "value": "C47797"},
                {"label": "Cumbum", "value": "C47798"},
                {"label": "Denkanikota", "value": "C47799"},
                {"label": "Desur", "value": "C47800"},
                {"label": "Devadanappatti", "value": "C47801"},
                {"label": "Devakottai", "value": "C47802"},
                {"label": "Dhali", "value": "C47803"},
                {"label": "Dharapuram", "value": "C47804"},
                {"label": "Dharmapuri", "value": "C47805"},
                {"label": "Dindigul", "value": "C47806"},
                {"label": "Dusi", "value": "C47807"},
                {"label": "Elayirampannai", "value": "C47808"},
                {"label": "Elumalai", "value": "C47809"},
                {"label": "Eral", "value": "C47810"},
                {"label": "Eraniel", "value": "C47811"},
                {"label": "Erode", "value": "C47812"},
                {"label": "Erumaippatti", "value": "C47813"},
                {"label": "Ettaiyapuram", "value": "C47814"},
                {"label": "Gandhi Nagar", "value": "C47815"},
                {"label": "Gangaikondan", "value": "C47816"},
                {"label": "Gangavalli", "value": "C47817"},
                {"label": "Gingee", "value": "C47818"},
                {"label": "Gobichettipalayam", "value": "C47819"},
                {"label": "Gudalur", "value": "C47820"},
                {"label": "Gudiyatham", "value": "C47821"},
                {"label": "Guduvancheri", "value": "C47822"},
                {"label": "Gummidipundi", "value": "C47823"},
                {"label": "Harur", "value": "C47824"},
                {"label": "Hosur", "value": "C47825"},
                {"label": "Idappadi", "value": "C47826"},
                {"label": "Ilampillai", "value": "C47827"},
                {"label": "Iluppur", "value": "C47828"},
                {"label": "Injambakkam", "value": "C47829"},
                {"label": "Irugur", "value": "C47830"},
                {"label": "Jalakandapuram", "value": "C47831"},
                {"label": "Jalarpet", "value": "C47832"},
                {"label": "Jayamkondacholapuram", "value": "C47833"},
                {"label": "Kadambur", "value": "C47834"},
                {"label": "Kadayanallur", "value": "C47835"},
                {"label": "Kalakkadu", "value": "C47836"},
                {"label": "Kalavai", "value": "C47837"},
                {"label": "Kallakkurichchi", "value": "C47838"},
                {"label": "Kallidaikurichi", "value": "C47839"},
                {"label": "Kallupatti", "value": "C47840"},
                {"label": "Kalugumalai", "value": "C47841"},
                {"label": "Kamuthi", "value": "C47842"},
                {"label": "Kanadukattan", "value": "C47843"},
                {"label": "Kancheepuram", "value": "C47844"},
                {"label": "Kanchipuram", "value": "C47845"},
                {"label": "Kangayam", "value": "C47846"},
                {"label": "Kanniyakumari", "value": "C47847"},
                {"label": "Karaikkudi", "value": "C47848"},
                {"label": "Karamadai", "value": "C47849"},
                {"label": "Karambakkudi", "value": "C47850"},
                {"label": "Kariapatti", "value": "C47851"},
                {"label": "Karumbakkam", "value": "C47852"},
                {"label": "Karur", "value": "C47853"},
                {"label": "Katpadi", "value": "C47854"},
                {"label": "Kattivakkam", "value": "C47855"},
                {"label": "Kattupputtur", "value": "C47856"},
                {"label": "Kaveripatnam", "value": "C47857"},
                {"label": "Kayalpattinam", "value": "C47858"},
                {"label": "Kayattar", "value": "C47859"},
                {"label": "Keelakarai", "value": "C47860"},
                {"label": "Kelamangalam", "value": "C47861"},
                {"label": "Kil Bhuvanagiri", "value": "C47862"},
                {"label": "Kilvelur", "value": "C47863"},
                {"label": "Kiranur", "value": "C47864"},
                {"label": "Kodaikanal", "value": "C47865"},
                {"label": "Kodumudi", "value": "C47866"},
                {"label": "Kombai", "value": "C47867"},
                {"label": "Konganapuram", "value": "C47868"},
                {"label": "Koothanallur", "value": "C47869"},
                {"label": "Koradachcheri", "value": "C47870"},
                {"label": "Korampallam", "value": "C47871"},
                {"label": "Kotagiri", "value": "C47872"},
                {"label": "Kottaiyur", "value": "C47873"},
                {"label": "Kovilpatti", "value": "C47874"},
                {"label": "Krishnagiri", "value": "C47875"},
                {"label": "Kulattur", "value": "C47876"},
                {"label": "Kulittalai", "value": "C47877"},
                {"label": "Kumaralingam", "value": "C47878"},
                {"label": "Kumbakonam", "value": "C47879"},
                {"label": "Kunnattur", "value": "C47880"},
                {"label": "Kurinjippadi", "value": "C47881"},
                {"label": "Kuttalam", "value": "C47882"},
                {"label": "Kuzhithurai", "value": "C47883"},
                {"label": "Lalgudi", "value": "C47884"},
                {"label": "Madambakkam", "value": "C47885"},
                {"label": "Madipakkam", "value": "C47886"},
                {"label": "Madukkarai", "value": "C47887"},
                {"label": "Madukkur", "value": "C47888"},
                {"label": "Madurai", "value": "C47889"},
                {"label": "Madurantakam", "value": "C47890"},
                {"label": "Mallapuram", "value": "C47891"},
                {"label": "Mallasamudram", "value": "C47892"},
                {"label": "Mallur", "value": "C47893"},
                {"label": "Manali", "value": "C47894"},
                {"label": "Manalurpettai", "value": "C47895"},
                {"label": "Manamadurai", "value": "C47896"},
                {"label": "Manappakkam", "value": "C47897"},
                {"label": "Manapparai", "value": "C47898"},
                {"label": "Manavalakurichi", "value": "C47899"},
                {"label": "Mandapam", "value": "C47900"},
                {"label": "Mangalam", "value": "C47901"},
                {"label": "Mannargudi", "value": "C47902"},
                {"label": "Marakkanam", "value": "C47903"},
                {"label": "Marandahalli", "value": "C47904"},
                {"label": "Masinigudi", "value": "C47905"},
                {"label": "Mattur", "value": "C47906"},
                {"label": "Mayiladuthurai", "value": "C47907"},
                {"label": "Melur", "value": "C47908"},
                {"label": "Mettuppalaiyam", "value": "C47909"},
                {"label": "Mettur", "value": "C47910"},
                {"label": "Minjur", "value": "C47911"},
                {"label": "Mohanur", "value": "C47912"},
                {"label": "Mudukulattur", "value": "C47913"},
                {"label": "Mulanur", "value": "C47914"},
                {"label": "Musiri", "value": "C47915"},
                {"label": "Muttupet", "value": "C47916"},
                {"label": "Naduvattam", "value": "C47917"},
                {"label": "Nagapattinam", "value": "C47918"},
                {"label": "Nagercoil", "value": "C47919"},
                {"label": "Namagiripettai", "value": "C47920"},
                {"label": "Namakkal", "value": "C47921"},
                {"label": "Nambiyur", "value": "C47922"},
                {"label": "Nambutalai", "value": "C47923"},
                {"label": "Nandambakkam", "value": "C47924"},
                {"label": "Nangavalli", "value": "C47925"},
                {"label": "Nangilickondan", "value": "C47926"},
                {"label": "Nanguneri", "value": "C47927"},
                {"label": "Nannilam", "value": "C47928"},
                {"label": "Naravarikuppam", "value": "C47929"},
                {"label": "Nattam", "value": "C47930"},
                {"label": "Nattarasankottai", "value": "C47931"},
                {"label": "Needamangalam", "value": "C47932"},
                {"label": "Neelankarai", "value": "C47933"},
                {"label": "Negapatam", "value": "C47934"},
                {"label": "Nellikkuppam", "value": "C47935"},
                {"label": "Nilakottai", "value": "C47936"},
                {"label": "Nilgiris", "value": "C47937"},
                {"label": "Odugattur", "value": "C47938"},
                {"label": "Omalur", "value": "C47939"},
                {"label": "Ooty", "value": "C47940"},
                {"label": "Padmanabhapuram", "value": "C47941"},
                {"label": "Palakkodu", "value": "C47942"},
                {"label": "Palamedu", "value": "C47943"},
                {"label": "Palani", "value": "C47944"},
                {"label": "Palavakkam", "value": "C47945"},
                {"label": "Palladam", "value": "C47946"},
                {"label": "Pallappatti", "value": "C47947"},
                {"label": "Pallattur", "value": "C47948"},
                {"label": "Pallavaram", "value": "C47949"},
                {"label": "Pallikondai", "value": "C47950"},
                {"label": "Pallipattu", "value": "C47951"},
                {"label": "Pallippatti", "value": "C47952"},
                {"label": "Panruti", "value": "C47953"},
                {"label": "Papanasam", "value": "C47954"},
                {"label": "Papireddippatti", "value": "C47955"},
                {"label": "Papparappatti", "value": "C47956"},
                {"label": "Paramagudi", "value": "C47957"},
                {"label": "Pattukkottai", "value": "C47958"},
                {"label": "Pennadam", "value": "C47959"},
                {"label": "Pennagaram", "value": "C47960"},
                {"label": "Pennathur", "value": "C47961"},
                {"label": "Peraiyur", "value": "C47962"},
                {"label": "Perambalur", "value": "C47963"},
                {"label": "Peranamallur", "value": "C47964"},
                {"label": "Peranampattu", "value": "C47965"},
                {"label": "Peravurani", "value": "C47966"},
                {"label": "Periyakulam", "value": "C47967"},
                {"label": "Periyanayakkanpalaiyam", "value": "C47968"},
                {"label": "Periyanegamam", "value": "C47969"},
                {"label": "Periyapatti", "value": "C47970"},
                {"label": "Periyapattinam", "value": "C47971"},
                {"label": "Perundurai", "value": "C47972"},
                {"label": "Perungudi", "value": "C47973"},
                {"label": "Perur", "value": "C47974"},
                {"label": "Pollachi", "value": "C47975"},
                {"label": "Polur", "value": "C47976"},
                {"label": "Ponnamaravati", "value": "C47977"},
                {"label": "Ponneri", "value": "C47978"},
                {"label": "Poonamalle", "value": "C47979"},
                {"label": "Porur", "value": "C47980"},
                {"label": "Pudukkottai", "value": "C47981"},
                {"label": "Puduppatti", "value": "C47982"},
                {"label": "Pudur", "value": "C47983"},
                {"label": "Puduvayal", "value": "C47984"},
                {"label": "Puliyangudi", "value": "C47985"},
                {"label": "Puliyur", "value": "C47986"},
                {"label": "Pullambadi", "value": "C47987"},
                {"label": "Punjai Puliyampatti", "value": "C47988"},
                {"label": "Rajapalaiyam", "value": "C47989"},
                {"label": "Ramanathapuram", "value": "C47990"},
                {"label": "Rameswaram", "value": "C47991"},
                {"label": "Ranipet", "value": "C47992"},
                {"label": "Rasipuram", "value": "C47993"},
                {"label": "Saint Thomas Mount", "value": "C47994"},
                {"label": "Salem", "value": "C47995"},
                {"label": "Sathankulam", "value": "C47996"},
                {"label": "Sathyamangalam", "value": "C47997"},
                {"label": "Sattur", "value": "C47998"},
                {"label": "Sayalkudi", "value": "C47999"},
                {"label": "Seven Pagodas", "value": "C48000"},
                {"label": "Sholinghur", "value": "C48001"},
                {"label": "Singanallur", "value": "C48002"},
                {"label": "Singapperumalkovil", "value": "C48003"},
                {"label": "Sirkazhi", "value": "C48004"},
                {"label": "Sirumugai", "value": "C48005"},
                {"label": "Sivaganga", "value": "C48006"},
                {"label": "Sivagiri", "value": "C48007"},
                {"label": "Sivakasi", "value": "C48008"},
                {"label": "Srimushnam", "value": "C48009"},
                {"label": "Sriperumbudur", "value": "C48010"},
                {"label": "Srivaikuntam", "value": "C48011"},
                {"label": "Srivilliputhur", "value": "C48012"},
                {"label": "Suchindram", "value": "C48013"},
                {"label": "Sulur", "value": "C48014"},
                {"label": "Surandai", "value": "C48015"},
                {"label": "Swamimalai", "value": "C48016"},
                {"label": "Tambaram", "value": "C48017"},
                {"label": "Tanjore", "value": "C48018"},
                {"label": "Taramangalam", "value": "C48019"},
                {"label": "Tattayyangarpettai", "value": "C48020"},
                {"label": "Thanjavur", "value": "C48021"},
                {"label": "Tharangambadi", "value": "C48022"},
                {"label": "Theni", "value": "C48023"},
                {"label": "Thenkasi", "value": "C48024"},
                {"label": "Thirukattupalli", "value": "C48025"},
                {"label": "Thiruthani", "value": "C48026"},
                {"label": "Thiruvaiyaru", "value": "C48027"},
                {"label": "Thiruvallur", "value": "C48028"},
                {"label": "Thiruvarur", "value": "C48029"},
                {"label": "Thiruvidaimaruthur", "value": "C48030"},
                {"label": "Thoothukudi", "value": "C48031"},
                {"label": "Tindivanam", "value": "C48032"},
                {"label": "Tinnanur", "value": "C48033"},
                {"label": "Tiruchchendur", "value": "C48034"},
                {"label": "Tiruchengode", "value": "C48035"},
                {"label": "Tiruchirappalli", "value": "C48036"},
                {"label": "Tirukkoyilur", "value": "C48037"},
                {"label": "Tirumullaivasal", "value": "C48038"},
                {"label": "Tirunelveli", "value": "C48039"},
                {"label": "Tirunelveli Kattabo", "value": "C48040"},
                {"label": "Tiruppalaikudi", "value": "C48041"},
                {"label": "Tirupparangunram", "value": "C48042"},
                {"label": "Tiruppur", "value": "C48043"},
                {"label": "Tiruppuvanam", "value": "C48044"},
                {"label": "Tiruttangal", "value": "C48045"},
                {"label": "Tiruvannamalai", "value": "C48046"},
                {"label": "Tiruvottiyur", "value": "C48047"},
                {"label": "Tisaiyanvilai", "value": "C48048"},
                {"label": "Tondi", "value": "C48049"},
                {"label": "Turaiyur", "value": "C48050"},
                {"label": "Udangudi", "value": "C48051"},
                {"label": "Udumalaippettai", "value": "C48052"},
                {"label": "Uppiliyapuram", "value": "C48053"},
                {"label": "Usilampatti", "value": "C48054"},
                {"label": "Uttamapalaiyam", "value": "C48055"},
                {"label": "Uttiramerur", "value": "C48056"},
                {"label": "Uttukkuli", "value": "C48057"},
                {"label": "V.S.K.Valasai (Dindigul-Dist.)", "value": "C48058"},
                {"label": "Vadakku Valliyur", "value": "C48059"},
                {"label": "Vadakku Viravanallur", "value": "C48060"},
                {"label": "Vadamadurai", "value": "C48061"},
                {"label": "Vadippatti", "value": "C48062"},
                {"label": "Valangaiman", "value": "C48063"},
                {"label": "Valavanur", "value": "C48064"},
                {"label": "Vallam", "value": "C48065"},
                {"label": "Valparai", "value": "C48066"},
                {"label": "Vandalur", "value": "C48067"},
                {"label": "Vandavasi", "value": "C48068"},
                {"label": "Vaniyambadi", "value": "C48069"},
                {"label": "Vasudevanallur", "value": "C48070"},
                {"label": "Vattalkundu", "value": "C48071"},
                {"label": "Vedaraniyam", "value": "C48072"},
                {"label": "Vedasandur", "value": "C48073"},
                {"label": "Velankanni", "value": "C48074"},
                {"label": "Vellanur", "value": "C48075"},
                {"label": "Vellore", "value": "C48076"},
                {"label": "Velur", "value": "C48077"},
                {"label": "Vengavasal", "value": "C48078"},
                {"label": "Vettaikkaranpudur", "value": "C48079"},
                {"label": "Vettavalam", "value": "C48080"},
                {"label": "Vijayapuri", "value": "C48081"},
                {"label": "Vikravandi", "value": "C48082"},
                {"label": "Vilattikulam", "value": "C48083"},
                {"label": "Villupuram", "value": "C48084"},
                {"label": "Viraganur", "value": "C48085"},
                {"label": "Virudhunagar", "value": "C48086"},
                {"label": "Vriddhachalam", "value": "C48087"},
                {"label": "Walajapet", "value": "C48088"},
                {"label": "Wallajahbad", "value": "C48089"},
                {"label": "Wellington", "value": "C48090"}],
 "Tana River": [{"label": "Hola", "value": "C64635"},
                {"label": "Kipini", "value": "C64636"}],
 "Tandjile Region": [{"label": "Bere", "value": "C17886"},
                     {"label": "Kelo", "value": "C17887"},
                     {"label": "Lai", "value": "C17888"}],
 "Tanga": [{"label": "Chanika", "value": "C118339"},
           {"label": "Lushoto", "value": "C118340"},
           {"label": "Magomeni", "value": "C118341"},
           {"label": "Majengo", "value": "C118342"},
           {"label": "Makuyuni", "value": "C118343"},
           {"label": "Maramba", "value": "C118344"},
           {"label": "Matui", "value": "C118345"},
           {"label": "Mazinde", "value": "C118346"},
           {"label": "Mlalo", "value": "C118347"},
           {"label": "Muheza", "value": "C118348"},
           {"label": "Mwanga", "value": "C118349"},
           {"label": "Pangani", "value": "C118350"},
           {"label": "Soni", "value": "C118351"},
           {"label": "Tanga", "value": "C118352"}],
 "Tanganyika": [{"label": "Kabalo", "value": "C23370"},
                {"label": "Kalemie", "value": "C23371"},
                {"label": "Kongolo", "value": "C23372"}],
 "Tanger-Tetouan-Al Hoceima": [{"label": "Al Hoceima", "value": "C75824"},
                               {"label": "Al-Hoceima", "value": "C75825"},
                               {"label": "Asilah", "value": "C75826"},
                               {"label": "Bni Bouayach", "value": "C75827"},
                               {"label": "Brikcha", "value": "C75828"},
                               {"label": "Cap Negro II", "value": "C75829"},
                               {"label": "Chefchaouen Province",
                                "value": "C75830"},
                               {"label": "Chefchaouene", "value": "C75831"},
                               {"label": "Derdara", "value": "C75832"},
                               {"label": "Fahs-Anjra", "value": "C75833"},
                               {"label": "Fnidek", "value": "C75834"},
                               {"label": "Imzouren", "value": "C75835"},
                               {"label": "Ksar El Kebir", "value": "C75836"},
                               {"label": "Larache", "value": "C75837"},
                               {"label": "MDiq-Fnideq", "value": "C75838"},
                               {"label": "Martil", "value": "C75839"},
                               {"label": "Oued Laou", "value": "C75840"},
                               {"label": "Ouezzane", "value": "C75841"},
                               {"label": "Ouezzane Province",
                                "value": "C75842"},
                               {"label": "Senada", "value": "C75843"},
                               {"label": "Tamorot", "value": "C75844"},
                               {"label": "Tanger-Assilah", "value": "C75845"},
                               {"label": "Tangier", "value": "C75846"},
                               {"label": "Targuist", "value": "C75847"},
                               {"label": "Tetouan", "value": "C75848"},
                               {"label": "Tirhanimine", "value": "C75849"},
                               {"label": "Zinat", "value": "C75850"},
                               {"label": "Zoumi", "value": "C75851"}],
 "Tanintharyi Region": [{"label": "Dawei", "value": "C75955"},
                        {"label": "Kawthoung", "value": "C75956"},
                        {"label": "Myeik", "value": "C75957"}],
 "Taoyuan": [{"label": "Daxi", "value": "C117989"},
             {"label": "Taoyuan", "value": "C117990"},
             {"label": "Taoyuan City", "value": "C117991"}],
 "Taraba": [{"label": "Baissa", "value": "C78500"},
            {"label": "Beli", "value": "C78501"},
            {"label": "Gassol", "value": "C78502"},
            {"label": "Gembu", "value": "C78503"},
            {"label": "Ibi", "value": "C78504"},
            {"label": "Jalingo", "value": "C78505"},
            {"label": "Lau", "value": "C78506"},
            {"label": "Mutum Biyu", "value": "C78507"},
            {"label": "Riti", "value": "C78508"},
            {"label": "Wukari", "value": "C78509"}],
 "Taraclia District": [{"label": "Taraclia", "value": "C75540"},
                       {"label": "Tvardita", "value": "C75541"}],
 "Taranaki Region": [{"label": "Eltham", "value": "C77835"},
                     {"label": "Hawera", "value": "C77836"},
                     {"label": "New Plymouth", "value": "C77837"},
                     {"label": "New Plymouth District", "value": "C77838"},
                     {"label": "Opunake", "value": "C77839"},
                     {"label": "Patea", "value": "C77840"},
                     {"label": "South Taranaki District", "value": "C77841"},
                     {"label": "Waitara", "value": "C77842"}],
 "Tarapaca": [{"label": "Alto Hospicio", "value": "C18193"},
              {"label": "Camina", "value": "C18194"},
              {"label": "Colchane", "value": "C18195"},
              {"label": "Huara", "value": "C18196"},
              {"label": "Iquique", "value": "C18197"},
              {"label": "Pica", "value": "C18198"},
              {"label": "Pozo Almonte", "value": "C18199"}],
 "Targovishte Province": [{"label": "Antonovo", "value": "C16241"},
                          {"label": "Obshtina Antonovo", "value": "C16242"},
                          {"label": "Obshtina Omurtag", "value": "C16243"},
                          {"label": "Obshtina Opaka", "value": "C16244"},
                          {"label": "Obshtina Popovo", "value": "C16245"},
                          {"label": "Obshtina Targovishte", "value": "C16246"},
                          {"label": "Omurtag", "value": "C16247"},
                          {"label": "Opaka", "value": "C16248"},
                          {"label": "Popovo", "value": "C16249"},
                          {"label": "Targovishte", "value": "C16250"}],
 "Tarija Department": [{"label": "Bermejo", "value": "C9871"},
                       {"label": "Entre Rios", "value": "C9872"},
                       {"label": "Provincia Arce", "value": "C9873"},
                       {"label": "Provincia Avilez", "value": "C9874"},
                       {"label": "Provincia Cercado", "value": "C9875"},
                       {"label": "Provincia Gran Chaco", "value": "C9876"},
                       {"label": "Provincia Mendez", "value": "C9877"},
                       {"label": "Provincia OConnor", "value": "C9878"},
                       {"label": "Tarija", "value": "C9879"},
                       {"label": "Villamontes", "value": "C9880"},
                       {"label": "Yacuiba", "value": "C9881"}],
 "Tarrafal": [{"label": "Tarrafal", "value": "C17806"}],
 "Tarrafal de Sao Nicolau": [{"label": "Tarrafal de Sao Nicolau",
                              "value": "C17807"}],
 "Tartar District": [{"label": "Martakert", "value": "C8524"},
                     {"label": "Terter", "value": "C8525"}],
 "Tartu County": [{"label": "Alatskivi", "value": "C24626"},
                  {"label": "Elva", "value": "C24627"},
                  {"label": "Kallaste", "value": "C24628"},
                  {"label": "Kambja vald", "value": "C24629"},
                  {"label": "Korvekula", "value": "C24630"},
                  {"label": "Kurepalu", "value": "C24631"},
                  {"label": "Luunja", "value": "C24632"},
                  {"label": "Luunja vald", "value": "C24633"},
                  {"label": "Noo", "value": "C24634"},
                  {"label": "Noo vald", "value": "C24635"},
                  {"label": "Peipsiaare vald", "value": "C24636"},
                  {"label": "Puhja", "value": "C24637"},
                  {"label": "Tartu", "value": "C24638"},
                  {"label": "Tartu linn", "value": "C24639"},
                  {"label": "Tartu vald", "value": "C24640"},
                  {"label": "ulenurme", "value": "C24641"}],
 "Tartus Governorate": [{"label": "Ad Duraykish", "value": "C117949"},
                        {"label": "Baniyas", "value": "C117950"},
                        {"label": "Kaff al-Jaa", "value": "C117951"},
                        {"label": "Safita District", "value": "C117952"},
                        {"label": "Satita", "value": "C117953"},
                        {"label": "Tartouss", "value": "C117954"}],
 "Tarxien": [{"label": "Tarxien", "value": "C65925"}],
 "Tashkent": [{"label": "Bektemir", "value": "C147088"},
              {"label": "Tashkent", "value": "C147089"}],
 "Tashkent Region": [{"label": "Angren", "value": "C147090"},
                     {"label": "Bekobod", "value": "C147091"},
                     {"label": "Boka", "value": "C147092"},
                     {"label": "Chinoz", "value": "C147093"},
                     {"label": "Chirchiq", "value": "C147094"},
                     {"label": "Gazalkent", "value": "C147095"},
                     {"label": "Iskandar", "value": "C147096"},
                     {"label": "Kyzyldzhar", "value": "C147097"},
                     {"label": "Ohangaron", "value": "C147098"},
                     {"label": "Olmaliq", "value": "C147099"},
                     {"label": "Parkent", "value": "C147100"},
                     {"label": "Piskent", "value": "C147101"},
                     {"label": "Qibray", "value": "C147102"},
                     {"label": "Salor", "value": "C147103"},
                     {"label": "Tuytepa", "value": "C147104"},
                     {"label": "Urtaowul", "value": "C147105"},
                     {"label": "Yangiobod", "value": "C147106"},
                     {"label": "Yangiyul", "value": "C147107"},
                     {"label": "Zafar", "value": "C147108"}],
 "Tasman District": [{"label": "Brightwater", "value": "C77843"},
                     {"label": "Mapua", "value": "C77844"},
                     {"label": "Motueka", "value": "C77845"},
                     {"label": "Richmond", "value": "C77846"},
                     {"label": "Takaka", "value": "C77847"},
                     {"label": "Wakefield", "value": "C77848"}],
 "Tasmania": [{"label": "Acton Park", "value": "C4601"},
              {"label": "Austins Ferry", "value": "C4602"},
              {"label": "Bagdad", "value": "C4603"},
              {"label": "Battery Point", "value": "C4604"},
              {"label": "Beaconsfield", "value": "C4605"},
              {"label": "Beauty Point", "value": "C4606"},
              {"label": "Bellerive", "value": "C4607"},
              {"label": "Berriedale", "value": "C4608"},
              {"label": "Blackmans Bay", "value": "C4609"},
              {"label": "Blackstone Heights", "value": "C4610"},
              {"label": "Break ODay", "value": "C4611"},
              {"label": "Bridgewater", "value": "C4612"},
              {"label": "Bridport", "value": "C4613"},
              {"label": "Brighton", "value": "C4614"},
              {"label": "Burnie", "value": "C4615"},
              {"label": "Cambridge", "value": "C4616"},
              {"label": "Central Coast", "value": "C4617"},
              {"label": "Central Highlands", "value": "C4618"},
              {"label": "Chigwell", "value": "C4619"},
              {"label": "Circular Head", "value": "C4620"},
              {"label": "Claremont", "value": "C4621"},
              {"label": "Clarence", "value": "C4622"},
              {"label": "Clarendon Vale", "value": "C4623"},
              {"label": "Cressy", "value": "C4624"},
              {"label": "Currie", "value": "C4625"},
              {"label": "Cygnet", "value": "C4626"},
              {"label": "Deloraine", "value": "C4627"},
              {"label": "Derwent Valley", "value": "C4628"},
              {"label": "Devonport", "value": "C4629"},
              {"label": "Dodges Ferry", "value": "C4630"},
              {"label": "Dorset", "value": "C4631"},
              {"label": "Dynnyrne", "value": "C4632"},
              {"label": "East Devonport", "value": "C4633"},
              {"label": "East Launceston", "value": "C4634"},
              {"label": "Evandale", "value": "C4635"},
              {"label": "Flinders", "value": "C4636"},
              {"label": "Franklin", "value": "C4637"},
              {"label": "Gagebrook", "value": "C4638"},
              {"label": "Geeveston", "value": "C4639"},
              {"label": "Geilston Bay", "value": "C4640"},
              {"label": "George Town", "value": "C4641"},
              {"label": "GlamorganSpring Bay", "value": "C4642"},
              {"label": "Glenorchy", "value": "C4643"},
              {"label": "Goodwood", "value": "C4644"},
              {"label": "Granton", "value": "C4645"},
              {"label": "Hadspen", "value": "C4646"},
              {"label": "Herdsmans Cove", "value": "C4647"},
              {"label": "Hillcrest", "value": "C4648"},
              {"label": "Hobart", "value": "C4649"},
              {"label": "Hobart city centre", "value": "C4650"},
              {"label": "Howrah", "value": "C4651"},
              {"label": "Huon Valley", "value": "C4652"},
              {"label": "Huonville", "value": "C4653"},
              {"label": "Invermay", "value": "C4654"},
              {"label": "Kentish", "value": "C4655"},
              {"label": "King Island", "value": "C4656"},
              {"label": "Kingborough", "value": "C4657"},
              {"label": "Kings Meadows", "value": "C4658"},
              {"label": "Kingston", "value": "C4659"},
              {"label": "Kingston Beach", "value": "C4660"},
              {"label": "Latrobe", "value": "C4661"},
              {"label": "Lauderdale", "value": "C4662"},
              {"label": "Launceston", "value": "C4663"},
              {"label": "Launceston city centre", "value": "C4664"},
              {"label": "Legana", "value": "C4665"},
              {"label": "Lenah Valley", "value": "C4666"},
              {"label": "Lindisfarne", "value": "C4667"},
              {"label": "Longford", "value": "C4668"},
              {"label": "Lutana", "value": "C4669"},
              {"label": "Margate", "value": "C4670"},
              {"label": "Mayfield", "value": "C4671"},
              {"label": "Meander Valley", "value": "C4672"},
              {"label": "Miandetta", "value": "C4673"},
              {"label": "Midway Point", "value": "C4674"},
              {"label": "Montello", "value": "C4675"},
              {"label": "Montrose", "value": "C4676"},
              {"label": "Moonah", "value": "C4677"},
              {"label": "Mornington", "value": "C4678"},
              {"label": "Mount Nelson", "value": "C4679"},
              {"label": "Mount Stuart", "value": "C4680"},
              {"label": "Mowbray", "value": "C4681"},
              {"label": "New Norfolk", "value": "C4682"},
              {"label": "New Town", "value": "C4683"},
              {"label": "Newnham", "value": "C4684"},
              {"label": "Newstead", "value": "C4685"},
              {"label": "North Hobart", "value": "C4686"},
              {"label": "Northern Midlands", "value": "C4687"},
              {"label": "Norwood", "value": "C4688"},
              {"label": "Oakdowns", "value": "C4689"},
              {"label": "Old Beach", "value": "C4690"},
              {"label": "Park Grove", "value": "C4691"},
              {"label": "Penguin", "value": "C4692"},
              {"label": "Perth", "value": "C4693"},
              {"label": "Port Sorell", "value": "C4694"},
              {"label": "Prospect Vale", "value": "C4695"},
              {"label": "Queenstown", "value": "C4696"},
              {"label": "Ranelagh", "value": "C4697"},
              {"label": "Ravenswood", "value": "C4698"},
              {"label": "Richmond", "value": "C4699"},
              {"label": "Risdon Vale", "value": "C4700"},
              {"label": "Riverside", "value": "C4701"},
              {"label": "Rocherlea", "value": "C4702"},
              {"label": "Rokeby", "value": "C4703"},
              {"label": "Romaine", "value": "C4704"},
              {"label": "Rosetta", "value": "C4705"},
              {"label": "Saint Leonards", "value": "C4706"},
              {"label": "Sandford", "value": "C4707"},
              {"label": "Sandy Bay", "value": "C4708"},
              {"label": "Scottsdale", "value": "C4709"},
              {"label": "Seven Mile Beach", "value": "C4710"},
              {"label": "Shearwater", "value": "C4711"},
              {"label": "Sheffield", "value": "C4712"},
              {"label": "Shorewell Park", "value": "C4713"},
              {"label": "Smithton", "value": "C4714"},
              {"label": "Snug", "value": "C4715"},
              {"label": "Somerset", "value": "C4716"},
              {"label": "Sorell", "value": "C4717"},
              {"label": "South Hobart", "value": "C4718"},
              {"label": "South Launceston", "value": "C4719"},
              {"label": "Southern Midlands", "value": "C4720"},
              {"label": "Spreyton", "value": "C4721"},
              {"label": "St Helens", "value": "C4722"},
              {"label": "Summerhill", "value": "C4723"},
              {"label": "Taroona", "value": "C4724"},
              {"label": "Tasman Peninsula", "value": "C4725"},
              {"label": "Tranmere", "value": "C4726"},
              {"label": "Trevallyn", "value": "C4727"},
              {"label": "Turners Beach", "value": "C4728"},
              {"label": "Ulverstone", "value": "C4729"},
              {"label": "Upper Burnie", "value": "C4730"},
              {"label": "WaratahWynyard", "value": "C4731"},
              {"label": "Warrane", "value": "C4732"},
              {"label": "Waverley", "value": "C4733"},
              {"label": "West Coast", "value": "C4734"},
              {"label": "West Hobart", "value": "C4735"},
              {"label": "West Launceston", "value": "C4736"},
              {"label": "West Moonah", "value": "C4737"},
              {"label": "West Tamar", "value": "C4738"},
              {"label": "West Ulverstone", "value": "C4739"},
              {"label": "Westbury", "value": "C4740"},
              {"label": "Wynyard", "value": "C4741"},
              {"label": "Youngtown", "value": "C4742"}],
 "Tataouine Governorate": [{"label": "Remada", "value": "C119799"},
                           {"label": "Tataouine", "value": "C119800"}],
 "Taurage County": [{"label": "Bugai", "value": "C65162"},
                    {"label": "Jurbarkas", "value": "C65163"},
                    {"label": "Pagegiai", "value": "C65164"},
                    {"label": "Silale", "value": "C65165"},
                    {"label": "Taurage", "value": "C65166"}],
 "Tavastia Proper": [{"label": "Forssa", "value": "C25165"},
                     {"label": "Hameenlinna", "value": "C25166"},
                     {"label": "Hauho", "value": "C25167"},
                     {"label": "Hausjarvi", "value": "C25168"},
                     {"label": "Humppila", "value": "C25169"},
                     {"label": "Janakkala", "value": "C25170"},
                     {"label": "Jokioinen", "value": "C25171"},
                     {"label": "Kalvola", "value": "C25172"},
                     {"label": "Lammi", "value": "C25173"},
                     {"label": "Loppi", "value": "C25174"},
                     {"label": "Renko", "value": "C25175"},
                     {"label": "Riihimaki", "value": "C25176"},
                     {"label": "Tammela", "value": "C25177"},
                     {"label": "Tervakoski", "value": "C25178"},
                     {"label": "Tuulos", "value": "C25179"},
                     {"label": "Ypaja", "value": "C25180"}],
 "Tavush Region": [{"label": "Archis", "value": "C1817"},
                   {"label": "Artsvaberd", "value": "C1818"},
                   {"label": "Aygehovit", "value": "C1819"},
                   {"label": "Azatamut", "value": "C1820"},
                   {"label": "Bagratashen", "value": "C1821"},
                   {"label": "Berd", "value": "C1822"},
                   {"label": "Berdavan", "value": "C1823"},
                   {"label": "Dilijan", "value": "C1824"},
                   {"label": "Haghartsin", "value": "C1825"},
                   {"label": "Ijevan", "value": "C1826"},
                   {"label": "Khashtarrak", "value": "C1827"},
                   {"label": "Mosesgegh", "value": "C1828"},
                   {"label": "Navur", "value": "C1829"},
                   {"label": "Noyemberyan", "value": "C1830"},
                   {"label": "Parravakar", "value": "C1831"},
                   {"label": "Sarigyugh", "value": "C1832"},
                   {"label": "Voskevan", "value": "C1833"}],
 "Tay Ninh": [{"label": "Huyen Ben Cau", "value": "C147637"},
              {"label": "Huyen Duong Minh Chau", "value": "C147638"},
              {"label": "Huyen Go Dau", "value": "C147639"},
              {"label": "Huyen Hoa Thanh", "value": "C147640"},
              {"label": "Huyen Tan Chau", "value": "C147641"},
              {"label": "Huyen Trang Bang", "value": "C147642"},
              {"label": "Phu Khuong", "value": "C147643"},
              {"label": "Tay Ninh", "value": "C147644"}],
 "Tbilisi": [{"label": "Tbilisi", "value": "C34364"}],
 "Tearce Municipality": [{"label": "Neraste", "value": "C65519"},
                         {"label": "Orashac", "value": "C65520"},
                         {"label": "Prsovce", "value": "C65521"},
                         {"label": "Slatino", "value": "C65522"},
                         {"label": "Tearce", "value": "C65523"}],
 "Tebessa": [{"label": "Bir el Ater", "value": "C460"},
             {"label": "Cheria", "value": "C461"},
             {"label": "Hammamet", "value": "C462"},
             {"label": "Tebessa", "value": "C463"}],
 "Tehran": [{"label": "Damavand", "value": "C51051"},
            {"label": "Eqbaliyeh", "value": "C51052"},
            {"label": "Eslamshahr", "value": "C51053"},
            {"label": "Malard", "value": "C51054"},
            {"label": "Pardis", "value": "C51055"},
            {"label": "Pishva", "value": "C51056"},
            {"label": "Qarchak", "value": "C51057"},
            {"label": "Qods", "value": "C51058"},
            {"label": "Rey", "value": "C51059"},
            {"label": "Robat Karim", "value": "C51060"},
            {"label": "Shahrak-e Emam Hasan", "value": "C51061"},
            {"label": "Shahre Jadide Andisheh", "value": "C51062"},
            {"label": "Shahrestan-e Baharestan", "value": "C51063"},
            {"label": "Shahrestan-e Damavand", "value": "C51064"},
            {"label": "Shahrestan-e Eslamshahr", "value": "C51065"},
            {"label": "Shahrestan-e Firuzkuh", "value": "C51066"},
            {"label": "Shahrestan-e Malard", "value": "C51067"},
            {"label": "Shahrestan-e Pakdasht", "value": "C51068"},
            {"label": "Shahrestan-e Pishva", "value": "C51069"},
            {"label": "Shahrestan-e Robat Karim", "value": "C51070"},
            {"label": "Shahrestan-e Shahriar", "value": "C51071"},
            {"label": "Shahrestan-e Shemiranat", "value": "C51072"},
            {"label": "Shahrestan-e Tehran", "value": "C51073"},
            {"label": "Shahrestan-e Varamin", "value": "C51074"},
            {"label": "Shahriar", "value": "C51075"},
            {"label": "Sharifabad", "value": "C51076"},
            {"label": "Soleh Bon", "value": "C51077"},
            {"label": "Taleb abad", "value": "C51078"},
            {"label": "Tehran", "value": "C51079"},
            {"label": "Varamin", "value": "C51080"}],
 "Tekirdag": [{"label": "Cerkezkoy", "value": "C121041"},
              {"label": "Corlu", "value": "C121042"},
              {"label": "Ergene", "value": "C121043"},
              {"label": "Hayrabolu", "value": "C121044"},
              {"label": "Kapakli", "value": "C121045"},
              {"label": "Kumbag", "value": "C121046"},
              {"label": "Malkara", "value": "C121047"},
              {"label": "Marmara Ereglisi", "value": "C121048"},
              {"label": "Marmaracik", "value": "C121049"},
              {"label": "Muratli Ilcesi", "value": "C121050"},
              {"label": "Saray", "value": "C121051"},
              {"label": "Sarkoy Ilcesi", "value": "C121052"},
              {"label": "Suleymanpasa", "value": "C121053"},
              {"label": "Sultankoy", "value": "C121054"},
              {"label": "Tekirdag", "value": "C121055"},
              {"label": "Velimese", "value": "C121056"}],
 "Tel Aviv District": [{"label": "Azor", "value": "C51721"},
                       {"label": "Bat Yam", "value": "C51722"},
                       {"label": "Bnei Brak", "value": "C51723"},
                       {"label": "Givat Shmuel", "value": "C51724"},
                       {"label": "Givatayim", "value": "C51725"},
                       {"label": "Herzliya", "value": "C51726"},
                       {"label": "Herzliya Pituah", "value": "C51727"},
                       {"label": "Holon", "value": "C51728"},
                       {"label": "Jaffa", "value": "C51729"},
                       {"label": "Kefar Shemaryahu", "value": "C51730"},
                       {"label": "Or Yehuda", "value": "C51731"},
                       {"label": "Ramat Gan", "value": "C51732"},
                       {"label": "Ramat HaSharon", "value": "C51733"},
                       {"label": "Tel Aviv", "value": "C51734"},
                       {"label": "Yehud-Monosson", "value": "C51735"}],
 "Telangana": [{"label": "Adilabad", "value": "C48091"},
               {"label": "Alampur", "value": "C48092"},
               {"label": "Andol", "value": "C48093"},
               {"label": "Asifabad", "value": "C48094"},
               {"label": "Balapur", "value": "C48095"},
               {"label": "Banswada", "value": "C48096"},
               {"label": "Bellampalli", "value": "C48097"},
               {"label": "Bhadrachalam", "value": "C48098"},
               {"label": "Bhadradri Kothagudem", "value": "C48099"},
               {"label": "Bhaisa", "value": "C48100"},
               {"label": "Bhongir", "value": "C48101"},
               {"label": "Bodhan", "value": "C48102"},
               {"label": "Chandur", "value": "C48103"},
               {"label": "Chatakonda", "value": "C48104"},
               {"label": "Dasnapur", "value": "C48105"},
               {"label": "Devarkonda", "value": "C48106"},
               {"label": "Dornakal", "value": "C48107"},
               {"label": "Farrukhnagar", "value": "C48108"},
               {"label": "Gaddi Annaram", "value": "C48109"},
               {"label": "Gadwal", "value": "C48110"},
               {"label": "Ghatkesar", "value": "C48111"},
               {"label": "Gopalur", "value": "C48112"},
               {"label": "Gudur", "value": "C48113"},
               {"label": "Hyderabad", "value": "C48114"},
               {"label": "Jagtial", "value": "C48115"},
               {"label": "Jangaon", "value": "C48116"},
               {"label": "Jangoan", "value": "C48117"},
               {"label": "Jayashankar Bhupalapally", "value": "C48118"},
               {"label": "Jogulamba Gadwal", "value": "C48119"},
               {"label": "Kagaznagar", "value": "C48120"},
               {"label": "Kamareddi", "value": "C48121"},
               {"label": "Kamareddy", "value": "C48122"},
               {"label": "Karimnagar", "value": "C48123"},
               {"label": "Khammam", "value": "C48124"},
               {"label": "Kodar", "value": "C48125"},
               {"label": "Koratla", "value": "C48126"},
               {"label": "Kothapet", "value": "C48127"},
               {"label": "Kottagudem", "value": "C48128"},
               {"label": "Kottapalli", "value": "C48129"},
               {"label": "Kukatpalli", "value": "C48130"},
               {"label": "Kyathampalle", "value": "C48131"},
               {"label": "Lakshettipet", "value": "C48132"},
               {"label": "Lal Bahadur Nagar", "value": "C48133"},
               {"label": "Mahabubabad", "value": "C48134"},
               {"label": "Mahbubnagar", "value": "C48135"},
               {"label": "Malkajgiri", "value": "C48136"},
               {"label": "Mancheral", "value": "C48137"},
               {"label": "Mandamarri", "value": "C48138"},
               {"label": "Manthani", "value": "C48139"},
               {"label": "Manuguru", "value": "C48140"},
               {"label": "Medak", "value": "C48141"},
               {"label": "Medchal", "value": "C48142"},
               {"label": "Medchal Malkajgiri", "value": "C48143"},
               {"label": "Mirialguda", "value": "C48144"},
               {"label": "Nagar Karnul", "value": "C48145"},
               {"label": "Nalgonda", "value": "C48146"},
               {"label": "Narayanpet", "value": "C48147"},
               {"label": "Narsingi", "value": "C48148"},
               {"label": "Naspur", "value": "C48149"},
               {"label": "Nirmal", "value": "C48150"},
               {"label": "Nizamabad", "value": "C48151"},
               {"label": "Paloncha", "value": "C48152"},
               {"label": "Palwancha", "value": "C48153"},
               {"label": "Patancheru", "value": "C48154"},
               {"label": "Peddapalli", "value": "C48155"},
               {"label": "Quthbullapur", "value": "C48156"},
               {"label": "Rajanna Sircilla", "value": "C48157"},
               {"label": "Ramagundam", "value": "C48158"},
               {"label": "Ramgundam", "value": "C48159"},
               {"label": "Rangareddi", "value": "C48160"},
               {"label": "Sadaseopet", "value": "C48161"},
               {"label": "Sangareddi", "value": "C48162"},
               {"label": "Sathupalli", "value": "C48163"},
               {"label": "Secunderabad", "value": "C48164"},
               {"label": "Serilingampalle", "value": "C48165"},
               {"label": "Siddipet", "value": "C48166"},
               {"label": "Singapur", "value": "C48167"},
               {"label": "Sirpur", "value": "C48168"},
               {"label": "Sirsilla", "value": "C48169"},
               {"label": "Sriramnagar", "value": "C48170"},
               {"label": "Suriapet", "value": "C48171"},
               {"label": "Tandur", "value": "C48172"},
               {"label": "Uppal Kalan", "value": "C48173"},
               {"label": "Vemalwada", "value": "C48174"},
               {"label": "Vikarabad", "value": "C48175"},
               {"label": "Wanparti", "value": "C48176"},
               {"label": "Warangal", "value": "C48177"},
               {"label": "Yellandu", "value": "C48178"},
               {"label": "Zahirabad", "value": "C48179"}],
 "Telemark": [{"label": "Bamble", "value": "C79110"},
              {"label": "Bo", "value": "C79111"},
              {"label": "Dalen", "value": "C79112"},
              {"label": "Drangedal", "value": "C79113"},
              {"label": "Fyresdal", "value": "C79114"},
              {"label": "Gvarv", "value": "C79115"},
              {"label": "Herre", "value": "C79116"},
              {"label": "Hjartdal", "value": "C79117"},
              {"label": "Kragero", "value": "C79118"},
              {"label": "Kviteseid", "value": "C79119"},
              {"label": "Lunde", "value": "C79120"},
              {"label": "Nissedal", "value": "C79121"},
              {"label": "Nome", "value": "C79122"},
              {"label": "Notodden", "value": "C79123"},
              {"label": "Porsgrunn", "value": "C79124"},
              {"label": "Prestestranda", "value": "C79125"},
              {"label": "Rjukan", "value": "C79126"},
              {"label": "Sauherad", "value": "C79127"},
              {"label": "Seljord", "value": "C79128"},
              {"label": "Siljan", "value": "C79129"},
              {"label": "Skien", "value": "C79130"},
              {"label": "Tinn", "value": "C79131"},
              {"label": "Tokke", "value": "C79132"},
              {"label": "Ulefoss", "value": "C79133"},
              {"label": "Vinje", "value": "C79134"}],
 "Telenesti District": [{"label": "Mindresti", "value": "C75542"},
                        {"label": "Telenesti", "value": "C75543"}],
 "Teleorman County": [{"label": "Alexandria", "value": "C99016"},
                      {"label": "Babaita", "value": "C99017"},
                      {"label": "Bacalesti", "value": "C99018"},
                      {"label": "Baciu", "value": "C99019"},
                      {"label": "Baduleasa", "value": "C99020"},
                      {"label": "Balaci", "value": "C99021"},
                      {"label": "Baneasa", "value": "C99022"},
                      {"label": "Bascoveni", "value": "C99023"},
                      {"label": "Beciu", "value": "C99024"},
                      {"label": "Beiu", "value": "C99025"},
                      {"label": "Beuca", "value": "C99026"},
                      {"label": "Blejesti", "value": "C99027"},
                      {"label": "Bogdana", "value": "C99028"},
                      {"label": "Botoroaga", "value": "C99029"},
                      {"label": "Bragadiru", "value": "C99030"},
                      {"label": "Branceni", "value": "C99031"},
                      {"label": "Brancoveanca", "value": "C99032"},
                      {"label": "Bujoreni", "value": "C99033"},
                      {"label": "Bujoru", "value": "C99034"},
                      {"label": "Buzescu", "value": "C99035"},
                      {"label": "Calinesti", "value": "C99036"},
                      {"label": "Calmatuiu", "value": "C99037"},
                      {"label": "Calmatuiu de Sus", "value": "C99038"},
                      {"label": "Calomfiresti", "value": "C99039"},
                      {"label": "Cernetu", "value": "C99040"},
                      {"label": "Cervenia", "value": "C99041"},
                      {"label": "Ciuani", "value": "C99042"},
                      {"label": "Ciuperceni", "value": "C99043"},
                      {"label": "Comuna Babaita", "value": "C99044"},
                      {"label": "Comuna Balaci", "value": "C99045"},
                      {"label": "Comuna Beciu", "value": "C99046"},
                      {"label": "Comuna Beuca", "value": "C99047"},
                      {"label": "Comuna Blejesti", "value": "C99048"},
                      {"label": "Comuna Bogdana", "value": "C99049"},
                      {"label": "Comuna Botoroaga", "value": "C99050"},
                      {"label": "Comuna Bragadiru", "value": "C99051"},
                      {"label": "Comuna Branceni", "value": "C99052"},
                      {"label": "Comuna Bujoreni", "value": "C99053"},
                      {"label": "Comuna Bujoru", "value": "C99054"},
                      {"label": "Comuna Buzescu", "value": "C99055"},
                      {"label": "Comuna Calinesti", "value": "C99056"},
                      {"label": "Comuna Calmatuiu", "value": "C99057"},
                      {"label": "Comuna Calmatuiu De Sus", "value": "C99058"},
                      {"label": "Comuna Cervenia", "value": "C99059"},
                      {"label": "Comuna Ciolanesti", "value": "C99060"},
                      {"label": "Comuna Ciuperceni", "value": "C99061"},
                      {"label": "Comuna Contesti", "value": "C99062"},
                      {"label": "Comuna Cosmesti", "value": "C99063"},
                      {"label": "Comuna Crangeni", "value": "C99064"},
                      {"label": "Comuna Crangu", "value": "C99065"},
                      {"label": "Comuna Crevenicu", "value": "C99066"},
                      {"label": "Comuna Didesti", "value": "C99067"},
                      {"label": "Comuna Dobrotesti", "value": "C99068"},
                      {"label": "Comuna Dracea", "value": "C99069"},
                      {"label": "Comuna Dracsenei", "value": "C99070"},
                      {"label": "Comuna Draganesti de Vede", "value": "C99071"},
                      {"label": "Comuna Draganesti-Vlasca", "value": "C99072"},
                      {"label": "Comuna Fantanele", "value": "C99073"},
                      {"label": "Comuna Frasinet", "value": "C99074"},
                      {"label": "Comuna Frumoasa", "value": "C99075"},
                      {"label": "Comuna Furculesti", "value": "C99076"},
                      {"label": "Comuna Galateni", "value": "C99077"},
                      {"label": "Comuna Gratia", "value": "C99078"},
                      {"label": "Comuna Islaz", "value": "C99079"},
                      {"label": "Comuna Izvoarele", "value": "C99080"},
                      {"label": "Comuna Lisa", "value": "C99081"},
                      {"label": "Comuna Lita", "value": "C99082"},
                      {"label": "Comuna Lunca", "value": "C99083"},
                      {"label": "Comuna Magura", "value": "C99084"},
                      {"label": "Comuna Maldaeni", "value": "C99085"},
                      {"label": "Comuna Marzanesti", "value": "C99086"},
                      {"label": "Comuna Mavrodin", "value": "C99087"},
                      {"label": "Comuna Mereni", "value": "C99088"},
                      {"label": "Comuna Mosteni", "value": "C99089"},
                      {"label": "Comuna Nanov", "value": "C99090"},
                      {"label": "Comuna Nasturelu", "value": "C99091"},
                      {"label": "Comuna Necsesti", "value": "C99092"},
                      {"label": "Comuna Nenciulesti", "value": "C99093"},
                      {"label": "Comuna Olteni", "value": "C99094"},
                      {"label": "Comuna Orbeasca", "value": "C99095"},
                      {"label": "Comuna Peretu", "value": "C99096"},
                      {"label": "Comuna Piatra", "value": "C99097"},
                      {"label": "Comuna Pietrosani", "value": "C99098"},
                      {"label": "Comuna Plopii Slavitesti", "value": "C99099"},
                      {"label": "Comuna Plosca", "value": "C99100"},
                      {"label": "Comuna Poeni", "value": "C99101"},
                      {"label": "Comuna Poroschia", "value": "C99102"},
                      {"label": "Comuna Purani", "value": "C99103"},
                      {"label": "Comuna Putineiu", "value": "C99104"},
                      {"label": "Comuna Radoiesti", "value": "C99105"},
                      {"label": "Comuna Rasmiresti", "value": "C99106"},
                      {"label": "Comuna Saceni", "value": "C99107"},
                      {"label": "Comuna Saelele", "value": "C99108"},
                      {"label": "Comuna Salcia", "value": "C99109"},
                      {"label": "Comuna Sarbeni", "value": "C99110"},
                      {"label": "Comuna Scrioastea", "value": "C99111"},
                      {"label": "Comuna Scurtu Mare", "value": "C99112"},
                      {"label": "Comuna Seaca", "value": "C99113"},
                      {"label": "Comuna Segarcea Vale", "value": "C99114"},
                      {"label": "Comuna Sfintesti", "value": "C99115"},
                      {"label": "Comuna Silistea", "value": "C99116"},
                      {"label": "Comuna Silistea Gumesti", "value": "C99117"},
                      {"label": "Comuna Slobozia-Mandra", "value": "C99118"},
                      {"label": "Comuna Smardioasa", "value": "C99119"},
                      {"label": "Comuna Stejaru", "value": "C99120"},
                      {"label": "Comuna Storobaneasa", "value": "C99121"},
                      {"label": "Comuna Suhaia", "value": "C99122"},
                      {"label": "Comuna Talpa", "value": "C99123"},
                      {"label": "Comuna Tatarastii de Jos", "value": "C99124"},
                      {"label": "Comuna Tatarastii de Sus", "value": "C99125"},
                      {"label": "Comuna Tiganesti", "value": "C99126"},
                      {"label": "Comuna Traian", "value": "C99127"},
                      {"label": "Comuna Trivalea-Mosteni", "value": "C99128"},
                      {"label": "Comuna Troianul", "value": "C99129"},
                      {"label": "Comuna Uda-Clocociov", "value": "C99130"},
                      {"label": "Comuna Vartoape", "value": "C99131"},
                      {"label": "Comuna Vedea", "value": "C99132"},
                      {"label": "Comuna Viisoara", "value": "C99133"},
                      {"label": "Comuna Vitanesti", "value": "C99134"},
                      {"label": "Comuna Zambreasca", "value": "C99135"},
                      {"label": "Contesti", "value": "C99136"},
                      {"label": "Cosmesti", "value": "C99137"},
                      {"label": "Crangeni", "value": "C99138"},
                      {"label": "Crangu", "value": "C99139"},
                      {"label": "Crevenicu", "value": "C99140"},
                      {"label": "Cucueti", "value": "C99141"},
                      {"label": "Deparati", "value": "C99142"},
                      {"label": "Didesti", "value": "C99143"},
                      {"label": "Dobreni", "value": "C99144"},
                      {"label": "Dobrotesti", "value": "C99145"},
                      {"label": "Dracea", "value": "C99146"},
                      {"label": "Dracsani", "value": "C99147"},
                      {"label": "Dracsenei", "value": "C99148"},
                      {"label": "Draganesti de Vede", "value": "C99149"},
                      {"label": "Draganesti-Vlasca", "value": "C99150"},
                      {"label": "Draghinesti", "value": "C99151"},
                      {"label": "Dudu", "value": "C99152"},
                      {"label": "Fantanele", "value": "C99153"},
                      {"label": "Frasinet", "value": "C99154"},
                      {"label": "Frumoasa", "value": "C99155"},
                      {"label": "Furculesti", "value": "C99156"},
                      {"label": "Galateni", "value": "C99157"},
                      {"label": "Garagau", "value": "C99158"},
                      {"label": "Gratia", "value": "C99159"},
                      {"label": "Guruieni", "value": "C99160"},
                      {"label": "Islaz", "value": "C99161"},
                      {"label": "Izvoarele", "value": "C99162"},
                      {"label": "Laceni", "value": "C99163"},
                      {"label": "Lisa", "value": "C99164"},
                      {"label": "Lita", "value": "C99165"},
                      {"label": "Lunca", "value": "C99166"},
                      {"label": "Magura", "value": "C99167"},
                      {"label": "Maldaeni", "value": "C99168"},
                      {"label": "Marzanesti", "value": "C99169"},
                      {"label": "Mavrodin", "value": "C99170"},
                      {"label": "Merenii de Jos", "value": "C99171"},
                      {"label": "Merenii de Sus", "value": "C99172"},
                      {"label": "Merisani", "value": "C99173"},
                      {"label": "Moldoveni", "value": "C99174"},
                      {"label": "Mosteni", "value": "C99175"},
                      {"label": "Municipiul Alexandria", "value": "C99176"},
                      {"label": "Municipiul Rosiorii de Vede",
                       "value": "C99177"},
                      {"label": "Municipiul Turnu Magurele", "value": "C99178"},
                      {"label": "Nanov", "value": "C99179"},
                      {"label": "Nasturelu", "value": "C99180"},
                      {"label": "Navodari", "value": "C99181"},
                      {"label": "Necsesti", "value": "C99182"},
                      {"label": "Nenciulesti", "value": "C99183"},
                      {"label": "Olteanca", "value": "C99184"},
                      {"label": "Olteni", "value": "C99185"},
                      {"label": "Oras Videle", "value": "C99186"},
                      {"label": "Oras Zimnicea", "value": "C99187"},
                      {"label": "Orbeasca de Jos", "value": "C99188"},
                      {"label": "Orbeasca de Sus", "value": "C99189"},
                      {"label": "Peretu", "value": "C99190"},
                      {"label": "Perii Brosteni", "value": "C99191"},
                      {"label": "Piatra", "value": "C99192"},
                      {"label": "Pietrosani", "value": "C99193"},
                      {"label": "Pleasov", "value": "C99194"},
                      {"label": "Plopii Slavitesti", "value": "C99195"},
                      {"label": "Plosca", "value": "C99196"},
                      {"label": "Poeni", "value": "C99197"},
                      {"label": "Poiana", "value": "C99198"},
                      {"label": "Poporogi", "value": "C99199"},
                      {"label": "Poroschia", "value": "C99200"},
                      {"label": "Prundu", "value": "C99201"},
                      {"label": "Purani", "value": "C99202"},
                      {"label": "Putineiu", "value": "C99203"},
                      {"label": "Radoiesti-Deal", "value": "C99204"},
                      {"label": "Rasmiresti", "value": "C99205"},
                      {"label": "Rosiorii de Vede", "value": "C99206"},
                      {"label": "Saceni", "value": "C99207"},
                      {"label": "Saelele", "value": "C99208"},
                      {"label": "Salcia", "value": "C99209"},
                      {"label": "Sarbeni", "value": "C99210"},
                      {"label": "Scrioastea", "value": "C99211"},
                      {"label": "Scurtu Mare", "value": "C99212"},
                      {"label": "Seaca", "value": "C99213"},
                      {"label": "Segarcea Vale", "value": "C99214"},
                      {"label": "Segarcea-Deal", "value": "C99215"},
                      {"label": "Sfintesti", "value": "C99216"},
                      {"label": "Silistea", "value": "C99217"},
                      {"label": "Silistea Gumesti", "value": "C99218"},
                      {"label": "Slobozia-Mandra", "value": "C99219"},
                      {"label": "Smardioasa", "value": "C99220"},
                      {"label": "Socetu", "value": "C99221"},
                      {"label": "Spatarei", "value": "C99222"},
                      {"label": "Stejaru", "value": "C99223"},
                      {"label": "Storobaneasa", "value": "C99224"},
                      {"label": "Suhaia", "value": "C99225"},
                      {"label": "Talpa-Ograzile", "value": "C99226"},
                      {"label": "Talpa-Trivalea", "value": "C99227"},
                      {"label": "Tarnava", "value": "C99228"},
                      {"label": "Tatarastii de Jos", "value": "C99229"},
                      {"label": "Tatarastii de Sus", "value": "C99230"},
                      {"label": "Tecuci", "value": "C99231"},
                      {"label": "Teleormanu", "value": "C99232"},
                      {"label": "Tiganesti", "value": "C99233"},
                      {"label": "Traian", "value": "C99234"},
                      {"label": "Trivalea-Mosteni", "value": "C99235"},
                      {"label": "Troianul", "value": "C99236"},
                      {"label": "Tudor Vladimirescu", "value": "C99237"},
                      {"label": "Turnu Magurele", "value": "C99238"},
                      {"label": "Uda-Clocociov", "value": "C99239"},
                      {"label": "Uda-Paciurea", "value": "C99240"},
                      {"label": "Udupu", "value": "C99241"},
                      {"label": "Valea Ciresului", "value": "C99242"},
                      {"label": "Valea Parului", "value": "C99243"},
                      {"label": "Vartoapele de Jos", "value": "C99244"},
                      {"label": "Vartoapele de Sus", "value": "C99245"},
                      {"label": "Vedea", "value": "C99246"},
                      {"label": "Videle", "value": "C99247"},
                      {"label": "Viisoara", "value": "C99248"},
                      {"label": "Vitanesti", "value": "C99249"},
                      {"label": "Zambreasca", "value": "C99250"},
                      {"label": "Zimnicea", "value": "C99251"},
                      {"label": "Zimnicele", "value": "C99252"}],
 "Telsiai District Municipality": [{"label": "Mazeikiai", "value": "C65167"},
                                   {"label": "Plateliai", "value": "C65168"},
                                   {"label": "Plunge", "value": "C65169"},
                                   {"label": "Rietavas", "value": "C65170"},
                                   {"label": "Seda", "value": "C65171"},
                                   {"label": "Telsiai", "value": "C65172"},
                                   {"label": "Varniai", "value": "C65173"},
                                   {"label": "Vieksniai", "value": "C65174"}],
 "Temburong District": [{"label": "Bangar", "value": "C15844"}],
 "Temotu Province": [{"label": "Lata", "value": "C107878"}],
 "Tennessee": [{"label": "Adamsville", "value": "C143135"},
               {"label": "Alamo", "value": "C143136"},
               {"label": "Alcoa", "value": "C143137"},
               {"label": "Algood", "value": "C143138"},
               {"label": "Altamont", "value": "C143139"},
               {"label": "Anderson County", "value": "C143140"},
               {"label": "Apison", "value": "C143141"},
               {"label": "Ardmore", "value": "C143142"},
               {"label": "Arlington", "value": "C143143"},
               {"label": "Ashland City", "value": "C143144"},
               {"label": "Athens", "value": "C143145"},
               {"label": "Atoka", "value": "C143146"},
               {"label": "Banner Hill", "value": "C143147"},
               {"label": "Bartlett", "value": "C143148"},
               {"label": "Baxter", "value": "C143149"},
               {"label": "Bean Station", "value": "C143150"},
               {"label": "Bedford County", "value": "C143151"},
               {"label": "Belle Meade", "value": "C143152"},
               {"label": "Bells", "value": "C143153"},
               {"label": "Benton", "value": "C143154"},
               {"label": "Benton County", "value": "C143155"},
               {"label": "Blaine", "value": "C143156"},
               {"label": "Bledsoe County", "value": "C143157"},
               {"label": "Bloomingdale", "value": "C143158"},
               {"label": "Blount County", "value": "C143159"},
               {"label": "Blountville", "value": "C143160"},
               {"label": "Bluff City", "value": "C143161"},
               {"label": "Bolivar", "value": "C143162"},
               {"label": "Bon Aqua Junction", "value": "C143163"},
               {"label": "Bradford", "value": "C143164"},
               {"label": "Bradley County", "value": "C143165"},
               {"label": "Brentwood", "value": "C143166"},
               {"label": "Brentwood Estates", "value": "C143167"},
               {"label": "Brighton", "value": "C143168"},
               {"label": "Bristol", "value": "C143169"},
               {"label": "Brownsville", "value": "C143170"},
               {"label": "Bruceton", "value": "C143171"},
               {"label": "Burns", "value": "C143172"},
               {"label": "Byrdstown", "value": "C143173"},
               {"label": "Camden", "value": "C143174"},
               {"label": "Campbell County", "value": "C143175"},
               {"label": "Cannon County", "value": "C143176"},
               {"label": "Carroll County", "value": "C143177"},
               {"label": "Carter County", "value": "C143178"},
               {"label": "Carthage", "value": "C143179"},
               {"label": "Caryville", "value": "C143180"},
               {"label": "Celina", "value": "C143181"},
               {"label": "Centerville", "value": "C143182"},
               {"label": "Central", "value": "C143183"},
               {"label": "Chapel Hill", "value": "C143184"},
               {"label": "Charlotte", "value": "C143185"},
               {"label": "Chattanooga", "value": "C143186"},
               {"label": "Cheatham County", "value": "C143187"},
               {"label": "Chester County", "value": "C143188"},
               {"label": "Christiana", "value": "C143189"},
               {"label": "Church Hill", "value": "C143190"},
               {"label": "Claiborne County", "value": "C143191"},
               {"label": "Clarksville", "value": "C143192"},
               {"label": "Clay County", "value": "C143193"},
               {"label": "Cleveland", "value": "C143194"},
               {"label": "Clifton", "value": "C143195"},
               {"label": "Clinton", "value": "C143196"},
               {"label": "Coalfield", "value": "C143197"},
               {"label": "Cocke County", "value": "C143198"},
               {"label": "Coffee County", "value": "C143199"},
               {"label": "Collegedale", "value": "C143200"},
               {"label": "Collierville", "value": "C143201"},
               {"label": "Colonial Heights", "value": "C143202"},
               {"label": "Columbia", "value": "C143203"},
               {"label": "Condon", "value": "C143204"},
               {"label": "Cookeville", "value": "C143205"},
               {"label": "Coopertown", "value": "C143206"},
               {"label": "Cornersville", "value": "C143207"},
               {"label": "Covington", "value": "C143208"},
               {"label": "Cowan", "value": "C143209"},
               {"label": "Crockett County", "value": "C143210"},
               {"label": "Cross Plains", "value": "C143211"},
               {"label": "Crossville", "value": "C143212"},
               {"label": "Crump", "value": "C143213"},
               {"label": "Cumberland County", "value": "C143214"},
               {"label": "Dandridge", "value": "C143215"},
               {"label": "Davidson County", "value": "C143216"},
               {"label": "Dayton", "value": "C143217"},
               {"label": "Decatur", "value": "C143218"},
               {"label": "Decatur County", "value": "C143219"},
               {"label": "Decaturville", "value": "C143220"},
               {"label": "Decherd", "value": "C143221"},
               {"label": "DeKalb County", "value": "C143222"},
               {"label": "Dickson", "value": "C143223"},
               {"label": "Dickson County", "value": "C143224"},
               {"label": "Dodson Branch", "value": "C143225"},
               {"label": "Dover", "value": "C143226"},
               {"label": "Dresden", "value": "C143227"},
               {"label": "Dunlap", "value": "C143228"},
               {"label": "Dyer", "value": "C143229"},
               {"label": "Dyer County", "value": "C143230"},
               {"label": "Dyersburg", "value": "C143231"},
               {"label": "Eagleton Village", "value": "C143232"},
               {"label": "East Brainerd", "value": "C143233"},
               {"label": "East Chattanooga", "value": "C143234"},
               {"label": "East Cleveland", "value": "C143235"},
               {"label": "East Ridge", "value": "C143236"},
               {"label": "Elizabethton", "value": "C143237"},
               {"label": "Englewood", "value": "C143238"},
               {"label": "Erin", "value": "C143239"},
               {"label": "Erwin", "value": "C143240"},
               {"label": "Estill Springs", "value": "C143241"},
               {"label": "Etowah", "value": "C143242"},
               {"label": "Fairfield Glade", "value": "C143243"},
               {"label": "Fairmount", "value": "C143244"},
               {"label": "Fairview", "value": "C143245"},
               {"label": "Fall Branch", "value": "C143246"},
               {"label": "Falling Water", "value": "C143247"},
               {"label": "Farragut", "value": "C143248"},
               {"label": "Fayette County", "value": "C143249"},
               {"label": "Fayetteville", "value": "C143250"},
               {"label": "Fentress County", "value": "C143251"},
               {"label": "Fincastle", "value": "C143252"},
               {"label": "Forest Hills", "value": "C143253"},
               {"label": "Franklin", "value": "C143254"},
               {"label": "Franklin County", "value": "C143255"},
               {"label": "Gainesboro", "value": "C143256"},
               {"label": "Gallatin", "value": "C143257"},
               {"label": "Gatlinburg", "value": "C143258"},
               {"label": "Germantown", "value": "C143259"},
               {"label": "Gibson County", "value": "C143260"},
               {"label": "Giles County", "value": "C143261"},
               {"label": "Gleason", "value": "C143262"},
               {"label": "Goodlettsville", "value": "C143263"},
               {"label": "Gordonsville", "value": "C143264"},
               {"label": "Grainger County", "value": "C143265"},
               {"label": "Gray", "value": "C143266"},
               {"label": "Graysville", "value": "C143267"},
               {"label": "Green Hill", "value": "C143268"},
               {"label": "Greenback", "value": "C143269"},
               {"label": "Greenbrier", "value": "C143270"},
               {"label": "Greene County", "value": "C143271"},
               {"label": "Greeneville", "value": "C143272"},
               {"label": "Greenfield", "value": "C143273"},
               {"label": "Grimsley", "value": "C143274"},
               {"label": "Gruetli-Laager", "value": "C143275"},
               {"label": "Grundy County", "value": "C143276"},
               {"label": "Halls", "value": "C143277"},
               {"label": "Hamblen County", "value": "C143278"},
               {"label": "Hamilton County", "value": "C143279"},
               {"label": "Hancock County", "value": "C143280"},
               {"label": "Hardeman County", "value": "C143281"},
               {"label": "Hardin County", "value": "C143282"},
               {"label": "Harriman", "value": "C143283"},
               {"label": "Harrison", "value": "C143284"},
               {"label": "Harrogate", "value": "C143285"},
               {"label": "Hartsville", "value": "C143286"},
               {"label": "Hawkins County", "value": "C143287"},
               {"label": "Haywood County", "value": "C143288"},
               {"label": "Henderson", "value": "C143289"},
               {"label": "Henderson County", "value": "C143290"},
               {"label": "Hendersonville", "value": "C143291"},
               {"label": "Henry County", "value": "C143292"},
               {"label": "Hickman County", "value": "C143293"},
               {"label": "Hickory Withe", "value": "C143294"},
               {"label": "Hohenwald", "value": "C143295"},
               {"label": "Hopewell", "value": "C143296"},
               {"label": "Houston County", "value": "C143297"},
               {"label": "Humboldt", "value": "C143298"},
               {"label": "Humphreys County", "value": "C143299"},
               {"label": "Hunter", "value": "C143300"},
               {"label": "Huntingdon", "value": "C143301"},
               {"label": "Huntsville", "value": "C143302"},
               {"label": "Jacksboro", "value": "C143303"},
               {"label": "Jackson", "value": "C143304"},
               {"label": "Jackson County", "value": "C143305"},
               {"label": "Jamestown", "value": "C143306"},
               {"label": "Jasper", "value": "C143307"},
               {"label": "Jefferson City", "value": "C143308"},
               {"label": "Jefferson County", "value": "C143309"},
               {"label": "Jellico", "value": "C143310"},
               {"label": "Johnson City", "value": "C143311"},
               {"label": "Johnson County", "value": "C143312"},
               {"label": "Johnsonville", "value": "C143313"},
               {"label": "Jonesborough", "value": "C143314"},
               {"label": "Kenton", "value": "C143315"},
               {"label": "Kimball", "value": "C143316"},
               {"label": "Kingsport", "value": "C143317"},
               {"label": "Kingston", "value": "C143318"},
               {"label": "Kingston Springs", "value": "C143319"},
               {"label": "Knox County", "value": "C143320"},
               {"label": "Knoxville", "value": "C143321"},
               {"label": "La Vergne", "value": "C143322"},
               {"label": "Lafayette", "value": "C143323"},
               {"label": "LaFollette", "value": "C143324"},
               {"label": "Lake County", "value": "C143325"},
               {"label": "Lake Tansi", "value": "C143326"},
               {"label": "Lakeland", "value": "C143327"},
               {"label": "Lakesite", "value": "C143328"},
               {"label": "Lakewood", "value": "C143329"},
               {"label": "Lauderdale County", "value": "C143330"},
               {"label": "Lawrence County", "value": "C143331"},
               {"label": "Lawrenceburg", "value": "C143332"},
               {"label": "Lebanon", "value": "C143333"},
               {"label": "Lenoir City", "value": "C143334"},
               {"label": "Lewis County", "value": "C143335"},
               {"label": "Lewisburg", "value": "C143336"},
               {"label": "Lexington", "value": "C143337"},
               {"label": "Lincoln County", "value": "C143338"},
               {"label": "Linden", "value": "C143339"},
               {"label": "Livingston", "value": "C143340"},
               {"label": "Lone Oak", "value": "C143341"},
               {"label": "Lookout Mountain", "value": "C143342"},
               {"label": "Loretto", "value": "C143343"},
               {"label": "Loudon", "value": "C143344"},
               {"label": "Loudon County", "value": "C143345"},
               {"label": "Louisville", "value": "C143346"},
               {"label": "Luttrell", "value": "C143347"},
               {"label": "Lynchburg", "value": "C143348"},
               {"label": "Macon County", "value": "C143349"},
               {"label": "Madison County", "value": "C143350"},
               {"label": "Madisonville", "value": "C143351"},
               {"label": "Manchester", "value": "C143352"},
               {"label": "Marion County", "value": "C143353"},
               {"label": "Marshall County", "value": "C143354"},
               {"label": "Martin", "value": "C143355"},
               {"label": "Maryville", "value": "C143356"},
               {"label": "Mascot", "value": "C143357"},
               {"label": "Mason", "value": "C143358"},
               {"label": "Maury County", "value": "C143359"},
               {"label": "Maynardville", "value": "C143360"},
               {"label": "McEwen", "value": "C143361"},
               {"label": "McKenzie", "value": "C143362"},
               {"label": "McMinn County", "value": "C143363"},
               {"label": "McMinnville", "value": "C143364"},
               {"label": "McNairy County", "value": "C143365"},
               {"label": "Medina", "value": "C143366"},
               {"label": "Meigs County", "value": "C143367"},
               {"label": "Memphis", "value": "C143368"},
               {"label": "Middle Valley", "value": "C143369"},
               {"label": "Midtown", "value": "C143370"},
               {"label": "Midway", "value": "C143371"},
               {"label": "Milan", "value": "C143372"},
               {"label": "Millersville", "value": "C143373"},
               {"label": "Millington", "value": "C143374"},
               {"label": "Monroe County", "value": "C143375"},
               {"label": "Monteagle", "value": "C143376"},
               {"label": "Monterey", "value": "C143377"},
               {"label": "Montgomery County", "value": "C143378"},
               {"label": "Moore County", "value": "C143379"},
               {"label": "Morgan County", "value": "C143380"},
               {"label": "Morristown", "value": "C143381"},
               {"label": "Mosheim", "value": "C143382"},
               {"label": "Mount Carmel", "value": "C143383"},
               {"label": "Mount Juliet", "value": "C143384"},
               {"label": "Mount Pleasant", "value": "C143385"},
               {"label": "Mountain City", "value": "C143386"},
               {"label": "Mowbray Mountain", "value": "C143387"},
               {"label": "Munford", "value": "C143388"},
               {"label": "Murfreesboro", "value": "C143389"},
               {"label": "Nashville", "value": "C143390"},
               {"label": "New Hope", "value": "C143391"},
               {"label": "New Johnsonville", "value": "C143392"},
               {"label": "New Market", "value": "C143393"},
               {"label": "New South Memphis", "value": "C143394"},
               {"label": "New Tazewell", "value": "C143395"},
               {"label": "New Union", "value": "C143396"},
               {"label": "Newbern", "value": "C143397"},
               {"label": "Newport", "value": "C143398"},
               {"label": "Nolensville", "value": "C143399"},
               {"label": "Norris", "value": "C143400"},
               {"label": "Oak Grove", "value": "C143401"},
               {"label": "Oak Hill", "value": "C143402"},
               {"label": "Oak Ridge", "value": "C143403"},
               {"label": "Oakland", "value": "C143404"},
               {"label": "Obion", "value": "C143405"},
               {"label": "Obion County", "value": "C143406"},
               {"label": "Oliver Springs", "value": "C143407"},
               {"label": "Olivet", "value": "C143408"},
               {"label": "Oneida", "value": "C143409"},
               {"label": "Overton County", "value": "C143410"},
               {"label": "Paris", "value": "C143411"},
               {"label": "Park City", "value": "C143412"},
               {"label": "Parsons", "value": "C143413"},
               {"label": "Pegram", "value": "C143414"},
               {"label": "Perry County", "value": "C143415"},
               {"label": "Pickett County", "value": "C143416"},
               {"label": "Pigeon Forge", "value": "C143417"},
               {"label": "Pikeville", "value": "C143418"},
               {"label": "Pine Crest", "value": "C143419"},
               {"label": "Piperton", "value": "C143420"},
               {"label": "Plainview", "value": "C143421"},
               {"label": "Pleasant View", "value": "C143422"},
               {"label": "Polk County", "value": "C143423"},
               {"label": "Portland", "value": "C143424"},
               {"label": "Powells Crossroads", "value": "C143425"},
               {"label": "Pulaski", "value": "C143426"},
               {"label": "Putnam County", "value": "C143427"},
               {"label": "Red Bank", "value": "C143428"},
               {"label": "Red Boiling Springs", "value": "C143429"},
               {"label": "Rhea County", "value": "C143430"},
               {"label": "Ridgely", "value": "C143431"},
               {"label": "Ridgetop", "value": "C143432"},
               {"label": "Ripley", "value": "C143433"},
               {"label": "Roan Mountain", "value": "C143434"},
               {"label": "Roane County", "value": "C143435"},
               {"label": "Robertson County", "value": "C143436"},
               {"label": "Rockwood", "value": "C143437"},
               {"label": "Rocky Top", "value": "C143438"},
               {"label": "Rogersville", "value": "C143439"},
               {"label": "Rural Hill", "value": "C143440"},
               {"label": "Rutherford", "value": "C143441"},
               {"label": "Rutherford County", "value": "C143442"},
               {"label": "Rutledge", "value": "C143443"},
               {"label": "Sale Creek", "value": "C143444"},
               {"label": "Savannah", "value": "C143445"},
               {"label": "Scott County", "value": "C143446"},
               {"label": "Selmer", "value": "C143447"},
               {"label": "Sequatchie County", "value": "C143448"},
               {"label": "Sevier County", "value": "C143449"},
               {"label": "Sevierville", "value": "C143450"},
               {"label": "Sewanee", "value": "C143451"},
               {"label": "Seymour", "value": "C143452"},
               {"label": "Shackle Island", "value": "C143453"},
               {"label": "Shelby County", "value": "C143454"},
               {"label": "Shelbyville", "value": "C143455"},
               {"label": "Signal Mountain", "value": "C143456"},
               {"label": "Smith County", "value": "C143457"},
               {"label": "Smithville", "value": "C143458"},
               {"label": "Smyrna", "value": "C143459"},
               {"label": "Sneedville", "value": "C143460"},
               {"label": "Soddy-Daisy", "value": "C143461"},
               {"label": "Somerville", "value": "C143462"},
               {"label": "South Carthage", "value": "C143463"},
               {"label": "South Cleveland", "value": "C143464"},
               {"label": "South Fulton", "value": "C143465"},
               {"label": "South Pittsburg", "value": "C143466"},
               {"label": "Sparta", "value": "C143467"},
               {"label": "Spencer", "value": "C143468"},
               {"label": "Spring City", "value": "C143469"},
               {"label": "Spring Hill", "value": "C143470"},
               {"label": "Springfield", "value": "C143471"},
               {"label": "Spurgeon", "value": "C143472"},
               {"label": "Stewart County", "value": "C143473"},
               {"label": "Sullivan County", "value": "C143474"},
               {"label": "Sumner County", "value": "C143475"},
               {"label": "Surgoinsville", "value": "C143476"},
               {"label": "Sweetwater", "value": "C143477"},
               {"label": "Tazewell", "value": "C143478"},
               {"label": "Tellico Village", "value": "C143479"},
               {"label": "Tennessee Ridge", "value": "C143480"},
               {"label": "Thompsons Station", "value": "C143481"},
               {"label": "Three Way", "value": "C143482"},
               {"label": "Tipton County", "value": "C143483"},
               {"label": "Tiptonville", "value": "C143484"},
               {"label": "Tracy City", "value": "C143485"},
               {"label": "Trenton", "value": "C143486"},
               {"label": "Trousdale County", "value": "C143487"},
               {"label": "Troy", "value": "C143488"},
               {"label": "Tullahoma", "value": "C143489"},
               {"label": "Tusculum", "value": "C143490"},
               {"label": "Unicoi", "value": "C143491"},
               {"label": "Unicoi County", "value": "C143492"},
               {"label": "Union City", "value": "C143493"},
               {"label": "Union County", "value": "C143494"},
               {"label": "Unionville", "value": "C143495"},
               {"label": "Van Buren County", "value": "C143496"},
               {"label": "Vonore", "value": "C143497"},
               {"label": "Walden", "value": "C143498"},
               {"label": "Walnut Hill", "value": "C143499"},
               {"label": "Warren County", "value": "C143500"},
               {"label": "Wartburg", "value": "C143501"},
               {"label": "Washington County", "value": "C143502"},
               {"label": "Watertown", "value": "C143503"},
               {"label": "Waverly", "value": "C143504"},
               {"label": "Wayne County", "value": "C143505"},
               {"label": "Waynesboro", "value": "C143506"},
               {"label": "Weakley County", "value": "C143507"},
               {"label": "Westmoreland", "value": "C143508"},
               {"label": "White Bluff", "value": "C143509"},
               {"label": "White County", "value": "C143510"},
               {"label": "White House", "value": "C143511"},
               {"label": "White Pine", "value": "C143512"},
               {"label": "Whiteville", "value": "C143513"},
               {"label": "Whitwell", "value": "C143514"},
               {"label": "Wildwood", "value": "C143515"},
               {"label": "Wildwood Lake", "value": "C143516"},
               {"label": "Williamson County", "value": "C143517"},
               {"label": "Wilson County", "value": "C143518"},
               {"label": "Winchester", "value": "C143519"},
               {"label": "Woodbury", "value": "C143520"}],
 "Terengganu": [{"label": "Cukai", "value": "C65793"},
                {"label": "Jertih", "value": "C65794"},
                {"label": "Kampung Kuala Besut", "value": "C65795"},
                {"label": "Kertih", "value": "C65796"},
                {"label": "Kuala Besut", "value": "C65797"},
                {"label": "Kuala Terengganu", "value": "C65798"},
                {"label": "Marang", "value": "C65799"},
                {"label": "Paka", "value": "C65800"}],
 "Ternopilska oblast": [{"label": "Belaya", "value": "C122791"},
                        {"label": "Borshchiv", "value": "C122792"},
                        {"label": "Buchach", "value": "C122793"},
                        {"label": "Chortkiv", "value": "C122794"},
                        {"label": "Druzhba", "value": "C122795"},
                        {"label": "Hrymayliv", "value": "C122796"},
                        {"label": "Husiatyn", "value": "C122797"},
                        {"label": "Khorostkiv", "value": "C122798"},
                        {"label": "Kopychyntsi", "value": "C122799"},
                        {"label": "Kozova", "value": "C122800"},
                        {"label": "Kremenets", "value": "C122801"},
                        {"label": "Lanivtsi", "value": "C122802"},
                        {"label": "Mikulintsy", "value": "C122803"},
                        {"label": "Nyrkiv", "value": "C122804"},
                        {"label": "Ozerna", "value": "C122805"},
                        {"label": "Petrikov", "value": "C122806"},
                        {"label": "Pidhaytsi", "value": "C122807"},
                        {"label": "Pidvolochysk", "value": "C122808"},
                        {"label": "Pochaiv", "value": "C122809"},
                        {"label": "Skala-Podilska", "value": "C122810"},
                        {"label": "Skalat", "value": "C122811"},
                        {"label": "Terebovlya", "value": "C122812"},
                        {"label": "Ternopil", "value": "C122813"},
                        {"label": "Tovste", "value": "C122814"},
                        {"label": "Velikiye Borki", "value": "C122815"},
                        {"label": "Yahilnytsya", "value": "C122816"},
                        {"label": "Zalishchyky", "value": "C122817"},
                        {"label": "Zaliztsi", "value": "C122818"},
                        {"label": "Zbarazh", "value": "C122819"},
                        {"label": "Zboriv", "value": "C122820"},
                        {"label": "Zolotyy Potik", "value": "C122821"},
                        {"label": "Berezhani", "value": "C122822"}],
 "Tervete Municipality": [{"label": "Tervete", "value": "C64949"},
                          {"label": "Zelmeni", "value": "C64950"}],
 "Tete Province": [{"label": "Tete", "value": "C75886"}],
 "Tetovo Municipality": [{"label": "Dobroste", "value": "C65524"},
                         {"label": "Dzepciste", "value": "C65525"},
                         {"label": "Golema Recica", "value": "C65526"},
                         {"label": "Pirok", "value": "C65527"},
                         {"label": "Poroj", "value": "C65528"},
                         {"label": "Selce", "value": "C65529"},
                         {"label": "Sipkovica", "value": "C65530"},
                         {"label": "Tetovo", "value": "C65531"}],
 "Texas": [{"label": "Abernathy", "value": "C143521"},
           {"label": "Abilene", "value": "C143522"},
           {"label": "Abram", "value": "C143523"},
           {"label": "Addison", "value": "C143524"},
           {"label": "Agua Dulce", "value": "C143525"},
           {"label": "Alamo", "value": "C143526"},
           {"label": "Alamo Heights", "value": "C143527"},
           {"label": "Albany", "value": "C143528"},
           {"label": "Aldine", "value": "C143529"},
           {"label": "Aledo", "value": "C143530"},
           {"label": "Alice", "value": "C143531"},
           {"label": "Alief", "value": "C143532"},
           {"label": "Allen", "value": "C143533"},
           {"label": "Alpine", "value": "C143534"},
           {"label": "Alto", "value": "C143535"},
           {"label": "Alton", "value": "C143536"},
           {"label": "Alton North (historical)", "value": "C143537"},
           {"label": "Alvarado", "value": "C143538"},
           {"label": "Alvin", "value": "C143539"},
           {"label": "Alvord", "value": "C143540"},
           {"label": "Amarillo", "value": "C143541"},
           {"label": "Ames", "value": "C143542"},
           {"label": "Anahuac", "value": "C143543"},
           {"label": "Anderson", "value": "C143544"},
           {"label": "Anderson County", "value": "C143545"},
           {"label": "Anderson Mill", "value": "C143546"},
           {"label": "Andrews", "value": "C143547"},
           {"label": "Andrews County", "value": "C143548"},
           {"label": "Angelina County", "value": "C143549"},
           {"label": "Angleton", "value": "C143550"},
           {"label": "Anna", "value": "C143551"},
           {"label": "Annetta", "value": "C143552"},
           {"label": "Anson", "value": "C143553"},
           {"label": "Anthony", "value": "C143554"},
           {"label": "Anton", "value": "C143555"},
           {"label": "Aransas County", "value": "C143556"},
           {"label": "Aransas Pass", "value": "C143557"},
           {"label": "Archer City", "value": "C143558"},
           {"label": "Archer County", "value": "C143559"},
           {"label": "Arcola", "value": "C143560"},
           {"label": "Argyle", "value": "C143561"},
           {"label": "Arlington", "value": "C143562"},
           {"label": "Armstrong County", "value": "C143563"},
           {"label": "Asherton", "value": "C143564"},
           {"label": "Aspermont", "value": "C143565"},
           {"label": "Atascocita", "value": "C143566"},
           {"label": "Atascosa County", "value": "C143567"},
           {"label": "Athens", "value": "C143568"},
           {"label": "Atlanta", "value": "C143569"},
           {"label": "Aubrey", "value": "C143570"},
           {"label": "Aurora", "value": "C143571"},
           {"label": "Austin", "value": "C143572"},
           {"label": "Austin County", "value": "C143573"},
           {"label": "Azle", "value": "C143574"},
           {"label": "Bacliff", "value": "C143575"},
           {"label": "Bailey County", "value": "C143576"},
           {"label": "Baird", "value": "C143577"},
           {"label": "Balch Springs", "value": "C143578"},
           {"label": "Balcones Heights", "value": "C143579"},
           {"label": "Ballinger", "value": "C143580"},
           {"label": "Bandera", "value": "C143581"},
           {"label": "Bandera County", "value": "C143582"},
           {"label": "Bangs", "value": "C143583"},
           {"label": "Barrett", "value": "C143584"},
           {"label": "Bartlett", "value": "C143585"},
           {"label": "Barton Creek", "value": "C143586"},
           {"label": "Bartonville", "value": "C143587"},
           {"label": "Bastrop", "value": "C143588"},
           {"label": "Bastrop County", "value": "C143589"},
           {"label": "Batesville", "value": "C143590"},
           {"label": "Bay City", "value": "C143591"},
           {"label": "Baylor County", "value": "C143592"},
           {"label": "Bayou Vista", "value": "C143593"},
           {"label": "Baytown", "value": "C143594"},
           {"label": "Beach City", "value": "C143595"},
           {"label": "Beaumont", "value": "C143596"},
           {"label": "Bedford", "value": "C143597"},
           {"label": "Bee Cave", "value": "C143598"},
           {"label": "Bee County", "value": "C143599"},
           {"label": "Beeville", "value": "C143600"},
           {"label": "Bell County", "value": "C143601"},
           {"label": "Bellaire", "value": "C143602"},
           {"label": "Bellmead", "value": "C143603"},
           {"label": "Bells", "value": "C143604"},
           {"label": "Bellville", "value": "C143605"},
           {"label": "Belton", "value": "C143606"},
           {"label": "Benavides", "value": "C143607"},
           {"label": "Benbrook", "value": "C143608"},
           {"label": "Benjamin", "value": "C143609"},
           {"label": "Berryville", "value": "C143610"},
           {"label": "Bertram", "value": "C143611"},
           {"label": "Beverly", "value": "C143612"},
           {"label": "Beverly Hills", "value": "C143613"},
           {"label": "Bevil Oaks", "value": "C143614"},
           {"label": "Bexar County", "value": "C143615"},
           {"label": "Big Lake", "value": "C143616"},
           {"label": "Big Sandy", "value": "C143617"},
           {"label": "Big Spring", "value": "C143618"},
           {"label": "Bishop", "value": "C143619"},
           {"label": "Blanco", "value": "C143620"},
           {"label": "Blanco County", "value": "C143621"},
           {"label": "Bloomington", "value": "C143622"},
           {"label": "Blossom", "value": "C143623"},
           {"label": "Blue Mound", "value": "C143624"},
           {"label": "Boerne", "value": "C143625"},
           {"label": "Bogata", "value": "C143626"},
           {"label": "Boling", "value": "C143627"},
           {"label": "Bolivar Peninsula", "value": "C143628"},
           {"label": "Bonham", "value": "C143629"},
           {"label": "Booker", "value": "C143630"},
           {"label": "Borden County", "value": "C143631"},
           {"label": "Borger", "value": "C143632"},
           {"label": "Bosque County", "value": "C143633"},
           {"label": "Bovina", "value": "C143634"},
           {"label": "Bowie", "value": "C143635"},
           {"label": "Bowie County", "value": "C143636"},
           {"label": "Boyd", "value": "C143637"},
           {"label": "Brackettville", "value": "C143638"},
           {"label": "Brady", "value": "C143639"},
           {"label": "Brazoria", "value": "C143640"},
           {"label": "Brazoria County", "value": "C143641"},
           {"label": "Brazos County", "value": "C143642"},
           {"label": "Breckenridge", "value": "C143643"},
           {"label": "Brenham", "value": "C143644"},
           {"label": "Brewster County", "value": "C143645"},
           {"label": "Briar", "value": "C143646"},
           {"label": "Briarcliff", "value": "C143647"},
           {"label": "Bridge City", "value": "C143648"},
           {"label": "Bridgeport", "value": "C143649"},
           {"label": "Briscoe County", "value": "C143650"},
           {"label": "Brooks County", "value": "C143651"},
           {"label": "Brookshire", "value": "C143652"},
           {"label": "Brookside Village", "value": "C143653"},
           {"label": "Brown County", "value": "C143654"},
           {"label": "Brownfield", "value": "C143655"},
           {"label": "Brownsboro", "value": "C143656"},
           {"label": "Brownsville", "value": "C143657"},
           {"label": "Brownwood", "value": "C143658"},
           {"label": "Bruceville-Eddy", "value": "C143659"},
           {"label": "Brushy Creek", "value": "C143660"},
           {"label": "Bryan", "value": "C143661"},
           {"label": "Buchanan Dam", "value": "C143662"},
           {"label": "Buda", "value": "C143663"},
           {"label": "Buffalo", "value": "C143664"},
           {"label": "Bullard", "value": "C143665"},
           {"label": "Bulverde", "value": "C143666"},
           {"label": "Buna", "value": "C143667"},
           {"label": "Bunker Hill Village", "value": "C143668"},
           {"label": "Burkburnett", "value": "C143669"},
           {"label": "Burleson", "value": "C143670"},
           {"label": "Burleson County", "value": "C143671"},
           {"label": "Burnet", "value": "C143672"},
           {"label": "Burnet County", "value": "C143673"},
           {"label": "Bushland", "value": "C143674"},
           {"label": "Cactus", "value": "C143675"},
           {"label": "Caddo Mills", "value": "C143676"},
           {"label": "Caldwell", "value": "C143677"},
           {"label": "Caldwell County", "value": "C143678"},
           {"label": "Calhoun County", "value": "C143679"},
           {"label": "Callahan County", "value": "C143680"},
           {"label": "Calvert", "value": "C143681"},
           {"label": "Cameron", "value": "C143682"},
           {"label": "Cameron County", "value": "C143683"},
           {"label": "Cameron Park", "value": "C143684"},
           {"label": "Cameron Park Colonia", "value": "C143685"},
           {"label": "Camp County", "value": "C143686"},
           {"label": "Camp Swift", "value": "C143687"},
           {"label": "Canadian", "value": "C143688"},
           {"label": "Canton", "value": "C143689"},
           {"label": "Canutillo", "value": "C143690"},
           {"label": "Canyon", "value": "C143691"},
           {"label": "Canyon Lake", "value": "C143692"},
           {"label": "Carrizo Springs", "value": "C143693"},
           {"label": "Carrollton", "value": "C143694"},
           {"label": "Carson County", "value": "C143695"},
           {"label": "Carthage", "value": "C143696"},
           {"label": "Cass County", "value": "C143697"},
           {"label": "Castle Hills", "value": "C143698"},
           {"label": "Castro County", "value": "C143699"},
           {"label": "Castroville", "value": "C143700"},
           {"label": "Cedar Hill", "value": "C143701"},
           {"label": "Cedar Park", "value": "C143702"},
           {"label": "Celina", "value": "C143703"},
           {"label": "Center", "value": "C143704"},
           {"label": "Centerville", "value": "C143705"},
           {"label": "Central Gardens", "value": "C143706"},
           {"label": "Cesar Chavez", "value": "C143707"},
           {"label": "Chambers County", "value": "C143708"},
           {"label": "Chandler", "value": "C143709"},
           {"label": "Channelview", "value": "C143710"},
           {"label": "Channing", "value": "C143711"},
           {"label": "Charlotte", "value": "C143712"},
           {"label": "Cherokee County", "value": "C143713"},
           {"label": "Chico", "value": "C143714"},
           {"label": "Childress", "value": "C143715"},
           {"label": "Childress County", "value": "C143716"},
           {"label": "China", "value": "C143717"},
           {"label": "China Grove", "value": "C143718"},
           {"label": "Cibolo", "value": "C143719"},
           {"label": "Cienegas Terrace", "value": "C143720"},
           {"label": "Cinco Ranch", "value": "C143721"},
           {"label": "Circle D-KC Estates", "value": "C143722"},
           {"label": "Cisco", "value": "C143723"},
           {"label": "Citrus City", "value": "C143724"},
           {"label": "Clarendon", "value": "C143725"},
           {"label": "Clarksville", "value": "C143726"},
           {"label": "Claude", "value": "C143727"},
           {"label": "Clay County", "value": "C143728"},
           {"label": "Clear Lake Shores", "value": "C143729"},
           {"label": "Cleburne", "value": "C143730"},
           {"label": "Cleveland", "value": "C143731"},
           {"label": "Clifton", "value": "C143732"},
           {"label": "Clint", "value": "C143733"},
           {"label": "Cloverleaf", "value": "C143734"},
           {"label": "Clute", "value": "C143735"},
           {"label": "Clyde", "value": "C143736"},
           {"label": "Cochran County", "value": "C143737"},
           {"label": "Cockrell Hill", "value": "C143738"},
           {"label": "Coke County", "value": "C143739"},
           {"label": "Coldspring", "value": "C143740"},
           {"label": "Coleman", "value": "C143741"},
           {"label": "Coleman County", "value": "C143742"},
           {"label": "College Station", "value": "C143743"},
           {"label": "Colleyville", "value": "C143744"},
           {"label": "Collin County", "value": "C143745"},
           {"label": "Collingsworth County", "value": "C143746"},
           {"label": "Collinsville", "value": "C143747"},
           {"label": "Colorado City", "value": "C143748"},
           {"label": "Colorado County", "value": "C143749"},
           {"label": "Columbus", "value": "C143750"},
           {"label": "Comal County", "value": "C143751"},
           {"label": "Comanche", "value": "C143752"},
           {"label": "Comanche County", "value": "C143753"},
           {"label": "Combes", "value": "C143754"},
           {"label": "Combine", "value": "C143755"},
           {"label": "Comfort", "value": "C143756"},
           {"label": "Commerce", "value": "C143757"},
           {"label": "Concho County", "value": "C143758"},
           {"label": "Conroe", "value": "C143759"},
           {"label": "Converse", "value": "C143760"},
           {"label": "Cooke County", "value": "C143761"},
           {"label": "Cooper", "value": "C143762"},
           {"label": "Coppell", "value": "C143763"},
           {"label": "Copper Canyon", "value": "C143764"},
           {"label": "Copperas Cove", "value": "C143765"},
           {"label": "Corinth", "value": "C143766"},
           {"label": "Corpus Christi", "value": "C143767"},
           {"label": "Corrigan", "value": "C143768"},
           {"label": "Corsicana", "value": "C143769"},
           {"label": "Coryell County", "value": "C143770"},
           {"label": "Cottle County", "value": "C143771"},
           {"label": "Cottonwood Shores", "value": "C143772"},
           {"label": "Cotulla", "value": "C143773"},
           {"label": "Crandall", "value": "C143774"},
           {"label": "Crane", "value": "C143775"},
           {"label": "Crane County", "value": "C143776"},
           {"label": "Crockett", "value": "C143777"},
           {"label": "Crockett County", "value": "C143778"},
           {"label": "Crosby", "value": "C143779"},
           {"label": "Crosby County", "value": "C143780"},
           {"label": "Crosbyton", "value": "C143781"},
           {"label": "Cross Mountain", "value": "C143782"},
           {"label": "Crowell", "value": "C143783"},
           {"label": "Crowley", "value": "C143784"},
           {"label": "Crystal City", "value": "C143785"},
           {"label": "Cuero", "value": "C143786"},
           {"label": "Culberson County", "value": "C143787"},
           {"label": "Cut and Shoot", "value": "C143788"},
           {"label": "Cypress", "value": "C143789"},
           {"label": "Daingerfield", "value": "C143790"},
           {"label": "Dalhart", "value": "C143791"},
           {"label": "Dallam County", "value": "C143792"},
           {"label": "Dallas", "value": "C143793"},
           {"label": "Dallas County", "value": "C143794"},
           {"label": "Dalworthington Gardens", "value": "C143795"},
           {"label": "Danbury", "value": "C143796"},
           {"label": "Dawson County", "value": "C143797"},
           {"label": "Dayton", "value": "C143798"},
           {"label": "De Kalb", "value": "C143799"},
           {"label": "De Leon", "value": "C143800"},
           {"label": "Deaf Smith County", "value": "C143801"},
           {"label": "Decatur", "value": "C143802"},
           {"label": "DeCordova", "value": "C143803"},
           {"label": "Deer Park", "value": "C143804"},
           {"label": "Del Rio", "value": "C143805"},
           {"label": "Delta County", "value": "C143806"},
           {"label": "Denison", "value": "C143807"},
           {"label": "Denton", "value": "C143808"},
           {"label": "Denton County", "value": "C143809"},
           {"label": "Denver City", "value": "C143810"},
           {"label": "DeSoto", "value": "C143811"},
           {"label": "Devine", "value": "C143812"},
           {"label": "Deweyville", "value": "C143813"},
           {"label": "DeWitt County", "value": "C143814"},
           {"label": "Diboll", "value": "C143815"},
           {"label": "Dickens", "value": "C143816"},
           {"label": "Dickens County", "value": "C143817"},
           {"label": "Dickinson", "value": "C143818"},
           {"label": "Dilley", "value": "C143819"},
           {"label": "Dimmit County", "value": "C143820"},
           {"label": "Dimmitt", "value": "C143821"},
           {"label": "Doffing", "value": "C143822"},
           {"label": "Donley County", "value": "C143823"},
           {"label": "Donna", "value": "C143824"},
           {"label": "Doolittle", "value": "C143825"},
           {"label": "Double Oak", "value": "C143826"},
           {"label": "Dripping Springs", "value": "C143827"},
           {"label": "Dublin", "value": "C143828"},
           {"label": "Dumas", "value": "C143829"},
           {"label": "Duncanville", "value": "C143830"},
           {"label": "Duval County", "value": "C143831"},
           {"label": "Eagle Lake", "value": "C143832"},
           {"label": "Eagle Mountain", "value": "C143833"},
           {"label": "Eagle Pass", "value": "C143834"},
           {"label": "Early", "value": "C143835"},
           {"label": "Earth", "value": "C143836"},
           {"label": "East Bernard", "value": "C143837"},
           {"label": "Eastland", "value": "C143838"},
           {"label": "Eastland County", "value": "C143839"},
           {"label": "Ector County", "value": "C143840"},
           {"label": "Edcouch", "value": "C143841"},
           {"label": "Eden", "value": "C143842"},
           {"label": "Edgecliff Village", "value": "C143843"},
           {"label": "Edgewood", "value": "C143844"},
           {"label": "Edinburg", "value": "C143845"},
           {"label": "Edna", "value": "C143846"},
           {"label": "Edwards County", "value": "C143847"},
           {"label": "Eidson Road", "value": "C143848"},
           {"label": "El Campo", "value": "C143849"},
           {"label": "El Cenizo", "value": "C143850"},
           {"label": "El Lago", "value": "C143851"},
           {"label": "El Paso", "value": "C143852"},
           {"label": "El Paso County", "value": "C143853"},
           {"label": "Eldorado", "value": "C143854"},
           {"label": "Electra", "value": "C143855"},
           {"label": "Elgin", "value": "C143856"},
           {"label": "Elkhart", "value": "C143857"},
           {"label": "Ellis County", "value": "C143858"},
           {"label": "Elm Creek", "value": "C143859"},
           {"label": "Elmendorf", "value": "C143860"},
           {"label": "Elsa", "value": "C143861"},
           {"label": "Emory", "value": "C143862"},
           {"label": "Encantada-Ranchito-El Calaboz", "value": "C143863"},
           {"label": "Ennis", "value": "C143864"},
           {"label": "Erath County", "value": "C143865"},
           {"label": "Escobares", "value": "C143866"},
           {"label": "Euless", "value": "C143867"},
           {"label": "Evadale", "value": "C143868"},
           {"label": "Everman", "value": "C143869"},
           {"label": "Fabens", "value": "C143870"},
           {"label": "Fair Oaks Ranch", "value": "C143871"},
           {"label": "Fairchilds", "value": "C143872"},
           {"label": "Fairfield", "value": "C143873"},
           {"label": "Fairview", "value": "C143874"},
           {"label": "Falcon Lake Estates", "value": "C143875"},
           {"label": "Falfurrias", "value": "C143876"},
           {"label": "Falls County", "value": "C143877"},
           {"label": "Fannett", "value": "C143878"},
           {"label": "Fannin County", "value": "C143879"},
           {"label": "Farmers Branch", "value": "C143880"},
           {"label": "Farmersville", "value": "C143881"},
           {"label": "Farwell", "value": "C143882"},
           {"label": "Fate", "value": "C143883"},
           {"label": "Fayette County", "value": "C143884"},
           {"label": "Ferris", "value": "C143885"},
           {"label": "Fifth Street", "value": "C143886"},
           {"label": "Fisher County", "value": "C143887"},
           {"label": "Flatonia", "value": "C143888"},
           {"label": "Florence", "value": "C143889"},
           {"label": "Floresville", "value": "C143890"},
           {"label": "Flower Mound", "value": "C143891"},
           {"label": "Floyd County", "value": "C143892"},
           {"label": "Floydada", "value": "C143893"},
           {"label": "Foard County", "value": "C143894"},
           {"label": "Forest Hill", "value": "C143895"},
           {"label": "Forney", "value": "C143896"},
           {"label": "Fort Bend County", "value": "C143897"},
           {"label": "Fort Bliss", "value": "C143898"},
           {"label": "Fort Clark Springs", "value": "C143899"},
           {"label": "Fort Davis", "value": "C143900"},
           {"label": "Fort Hancock", "value": "C143901"},
           {"label": "Fort Hood", "value": "C143902"},
           {"label": "Fort Stockton", "value": "C143903"},
           {"label": "Fort Worth", "value": "C143904"},
           {"label": "Four Corners", "value": "C143905"},
           {"label": "Franklin", "value": "C143906"},
           {"label": "Franklin County", "value": "C143907"},
           {"label": "Frankston", "value": "C143908"},
           {"label": "Fredericksburg", "value": "C143909"},
           {"label": "Freeport", "value": "C143910"},
           {"label": "Freer", "value": "C143911"},
           {"label": "Freestone County", "value": "C143912"},
           {"label": "Fresno", "value": "C143913"},
           {"label": "Friendswood", "value": "C143914"},
           {"label": "Frio County", "value": "C143915"},
           {"label": "Friona", "value": "C143916"},
           {"label": "Frisco", "value": "C143917"},
           {"label": "Fritch", "value": "C143918"},
           {"label": "Fulshear", "value": "C143919"},
           {"label": "Fulton", "value": "C143920"},
           {"label": "Gail", "value": "C143921"},
           {"label": "Gaines County", "value": "C143922"},
           {"label": "Gainesville", "value": "C143923"},
           {"label": "Galena Park", "value": "C143924"},
           {"label": "Galveston", "value": "C143925"},
           {"label": "Galveston County", "value": "C143926"},
           {"label": "Ganado", "value": "C143927"},
           {"label": "Garden City", "value": "C143928"},
           {"label": "Garden Ridge", "value": "C143929"},
           {"label": "Gardendale", "value": "C143930"},
           {"label": "Garfield", "value": "C143931"},
           {"label": "Garland", "value": "C143932"},
           {"label": "Garza County", "value": "C143933"},
           {"label": "Gatesville", "value": "C143934"},
           {"label": "George West", "value": "C143935"},
           {"label": "Georgetown", "value": "C143936"},
           {"label": "Geronimo", "value": "C143937"},
           {"label": "Gholson", "value": "C143938"},
           {"label": "Giddings", "value": "C143939"},
           {"label": "Gillespie County", "value": "C143940"},
           {"label": "Gilmer", "value": "C143941"},
           {"label": "Gladewater", "value": "C143942"},
           {"label": "Glasscock County", "value": "C143943"},
           {"label": "Glen Rose", "value": "C143944"},
           {"label": "Glenn Heights", "value": "C143945"},
           {"label": "Godley", "value": "C143946"},
           {"label": "Goldthwaite", "value": "C143947"},
           {"label": "Goliad", "value": "C143948"},
           {"label": "Goliad County", "value": "C143949"},
           {"label": "Gonzales", "value": "C143950"},
           {"label": "Gonzales County", "value": "C143951"},
           {"label": "Gorman", "value": "C143952"},
           {"label": "Graham", "value": "C143953"},
           {"label": "Granbury", "value": "C143954"},
           {"label": "Grand Prairie", "value": "C143955"},
           {"label": "Grand Saline", "value": "C143956"},
           {"label": "Grandview", "value": "C143957"},
           {"label": "Granger", "value": "C143958"},
           {"label": "Granite Shoals", "value": "C143959"},
           {"label": "Grape Creek", "value": "C143960"},
           {"label": "Grapeland", "value": "C143961"},
           {"label": "Grapevine", "value": "C143962"},
           {"label": "Gray County", "value": "C143963"},
           {"label": "Grayson County", "value": "C143964"},
           {"label": "Greatwood", "value": "C143965"},
           {"label": "Green Valley Farms", "value": "C143966"},
           {"label": "Greenville", "value": "C143967"},
           {"label": "Gregg County", "value": "C143968"},
           {"label": "Gregory", "value": "C143969"},
           {"label": "Grimes County", "value": "C143970"},
           {"label": "Groesbeck", "value": "C143971"},
           {"label": "Groves", "value": "C143972"},
           {"label": "Groveton", "value": "C143973"},
           {"label": "Gruver", "value": "C143974"},
           {"label": "Guadalupe County", "value": "C143975"},
           {"label": "Gun Barrel City", "value": "C143976"},
           {"label": "Gunter", "value": "C143977"},
           {"label": "Guthrie", "value": "C143978"},
           {"label": "Hackberry", "value": "C143979"},
           {"label": "Hale Center", "value": "C143980"},
           {"label": "Hale County", "value": "C143981"},
           {"label": "Hall County", "value": "C143982"},
           {"label": "Hallettsville", "value": "C143983"},
           {"label": "Hallsville", "value": "C143984"},
           {"label": "Haltom City", "value": "C143985"},
           {"label": "Hamilton", "value": "C143986"},
           {"label": "Hamilton County", "value": "C143987"},
           {"label": "Hamlin", "value": "C143988"},
           {"label": "Hansford County", "value": "C143989"},
           {"label": "Hardeman County", "value": "C143990"},
           {"label": "Hardin County", "value": "C143991"},
           {"label": "Harker Heights", "value": "C143992"},
           {"label": "Harlingen", "value": "C143993"},
           {"label": "Harper", "value": "C143994"},
           {"label": "Harris County", "value": "C143995"},
           {"label": "Harrison County", "value": "C143996"},
           {"label": "Hart", "value": "C143997"},
           {"label": "Hartley County", "value": "C143998"},
           {"label": "Haskell", "value": "C143999"},
           {"label": "Haskell County", "value": "C144000"},
           {"label": "Haslet", "value": "C144001"},
           {"label": "Hawkins", "value": "C144002"},
           {"label": "Hays County", "value": "C144003"},
           {"label": "Hearne", "value": "C144004"},
           {"label": "Heath", "value": "C144005"},
           {"label": "Hebbronville", "value": "C144006"},
           {"label": "Hedwig Village", "value": "C144007"},
           {"label": "Heidelberg", "value": "C144008"},
           {"label": "Helotes", "value": "C144009"},
           {"label": "Hemphill", "value": "C144010"},
           {"label": "Hemphill County", "value": "C144011"},
           {"label": "Hempstead", "value": "C144012"},
           {"label": "Henderson", "value": "C144013"},
           {"label": "Henderson County", "value": "C144014"},
           {"label": "Henrietta", "value": "C144015"},
           {"label": "Hereford", "value": "C144016"},
           {"label": "Hewitt", "value": "C144017"},
           {"label": "Hickory Creek", "value": "C144018"},
           {"label": "Hico", "value": "C144019"},
           {"label": "Hidalgo", "value": "C144020"},
           {"label": "Hidalgo County", "value": "C144021"},
           {"label": "Hideaway", "value": "C144022"},
           {"label": "Highland Park", "value": "C144023"},
           {"label": "Highland Village", "value": "C144024"},
           {"label": "Highlands", "value": "C144025"},
           {"label": "Hill Country Village", "value": "C144026"},
           {"label": "Hill County", "value": "C144027"},
           {"label": "Hillsboro", "value": "C144028"},
           {"label": "Hilltop Lakes", "value": "C144029"},
           {"label": "Hitchcock", "value": "C144030"},
           {"label": "Hockley County", "value": "C144031"},
           {"label": "Holiday Lakes", "value": "C144032"},
           {"label": "Holland", "value": "C144033"},
           {"label": "Holliday", "value": "C144034"},
           {"label": "Holly Lake Ranch", "value": "C144035"},
           {"label": "Hollywood Park", "value": "C144036"},
           {"label": "Homestead Meadows North", "value": "C144037"},
           {"label": "Homestead Meadows South", "value": "C144038"},
           {"label": "Hondo", "value": "C144039"},
           {"label": "Honey Grove", "value": "C144040"},
           {"label": "Hood County", "value": "C144041"},
           {"label": "Hooks", "value": "C144042"},
           {"label": "Hopkins County", "value": "C144043"},
           {"label": "Horizon City", "value": "C144044"},
           {"label": "Hornsby Bend", "value": "C144045"},
           {"label": "Horseshoe Bay", "value": "C144046"},
           {"label": "Houston", "value": "C144047"},
           {"label": "Houston County", "value": "C144048"},
           {"label": "Howard County", "value": "C144049"},
           {"label": "Howe", "value": "C144050"},
           {"label": "Hubbard", "value": "C144051"},
           {"label": "Hudson", "value": "C144052"},
           {"label": "Hudson Bend", "value": "C144053"},
           {"label": "Hudson Oaks", "value": "C144054"},
           {"label": "Hudspeth County", "value": "C144055"},
           {"label": "Hughes Springs", "value": "C144056"},
           {"label": "Humble", "value": "C144057"},
           {"label": "Hunt County", "value": "C144058"},
           {"label": "Hunters Creek Village", "value": "C144059"},
           {"label": "Huntington", "value": "C144060"},
           {"label": "Huntsville", "value": "C144061"},
           {"label": "Hurst", "value": "C144062"},
           {"label": "Hutchins", "value": "C144063"},
           {"label": "Hutchinson County", "value": "C144064"},
           {"label": "Hutto", "value": "C144065"},
           {"label": "Idalou", "value": "C144066"},
           {"label": "Indian Hills", "value": "C144067"},
           {"label": "Inez", "value": "C144068"},
           {"label": "Ingleside", "value": "C144069"},
           {"label": "Ingram", "value": "C144070"},
           {"label": "Iowa Colony", "value": "C144071"},
           {"label": "Iowa Park", "value": "C144072"},
           {"label": "Iraan", "value": "C144073"},
           {"label": "Irion County", "value": "C144074"},
           {"label": "Irving", "value": "C144075"},
           {"label": "Italy", "value": "C144076"},
           {"label": "Itasca", "value": "C144077"},
           {"label": "Jacinto City", "value": "C144078"},
           {"label": "Jack County", "value": "C144079"},
           {"label": "Jacksboro", "value": "C144080"},
           {"label": "Jackson County", "value": "C144081"},
           {"label": "Jacksonville", "value": "C144082"},
           {"label": "Jamaica Beach", "value": "C144083"},
           {"label": "Jarrell", "value": "C144084"},
           {"label": "Jasper", "value": "C144085"},
           {"label": "Jasper County", "value": "C144086"},
           {"label": "Jayton", "value": "C144087"},
           {"label": "Jeff Davis County", "value": "C144088"},
           {"label": "Jefferson", "value": "C144089"},
           {"label": "Jefferson County", "value": "C144090"},
           {"label": "Jersey Village", "value": "C144091"},
           {"label": "Jewett", "value": "C144092"},
           {"label": "Jim Hogg County", "value": "C144093"},
           {"label": "Jim Wells County", "value": "C144094"},
           {"label": "Johnson City", "value": "C144095"},
           {"label": "Johnson County", "value": "C144096"},
           {"label": "Jollyville", "value": "C144097"},
           {"label": "Jones County", "value": "C144098"},
           {"label": "Jones Creek", "value": "C144099"},
           {"label": "Jonestown", "value": "C144100"},
           {"label": "Josephine", "value": "C144101"},
           {"label": "Joshua", "value": "C144102"},
           {"label": "Jourdanton", "value": "C144103"},
           {"label": "Junction", "value": "C144104"},
           {"label": "Justin", "value": "C144105"},
           {"label": "Karnes City", "value": "C144106"},
           {"label": "Karnes County", "value": "C144107"},
           {"label": "Katy", "value": "C144108"},
           {"label": "Kaufman", "value": "C144109"},
           {"label": "Kaufman County", "value": "C144110"},
           {"label": "Keene", "value": "C144111"},
           {"label": "Keller", "value": "C144112"},
           {"label": "Kemah", "value": "C144113"},
           {"label": "Kemp", "value": "C144114"},
           {"label": "Kempner", "value": "C144115"},
           {"label": "Kendall County", "value": "C144116"},
           {"label": "Kenedy", "value": "C144117"},
           {"label": "Kenedy County", "value": "C144118"},
           {"label": "Kennedale", "value": "C144119"},
           {"label": "Kent County", "value": "C144120"},
           {"label": "Kerens", "value": "C144121"},
           {"label": "Kermit", "value": "C144122"},
           {"label": "Kerr County", "value": "C144123"},
           {"label": "Kerrville", "value": "C144124"},
           {"label": "Kilgore", "value": "C144125"},
           {"label": "Killeen", "value": "C144126"},
           {"label": "Kimble County", "value": "C144127"},
           {"label": "King County", "value": "C144128"},
           {"label": "Kingsland", "value": "C144129"},
           {"label": "Kingsville", "value": "C144130"},
           {"label": "Kingwood Area", "value": "C144131"},
           {"label": "Kinney County", "value": "C144132"},
           {"label": "Kirby", "value": "C144133"},
           {"label": "Kirbyville", "value": "C144134"},
           {"label": "Kleberg County", "value": "C144135"},
           {"label": "Knox City", "value": "C144136"},
           {"label": "Knox County", "value": "C144137"},
           {"label": "Kountze", "value": "C144138"},
           {"label": "Krugerville", "value": "C144139"},
           {"label": "Krum", "value": "C144140"},
           {"label": "Kyle", "value": "C144141"},
           {"label": "La Blanca", "value": "C144142"},
           {"label": "La Coste", "value": "C144143"},
           {"label": "La Feria", "value": "C144144"},
           {"label": "La Grange", "value": "C144145"},
           {"label": "La Grulla", "value": "C144146"},
           {"label": "La Homa", "value": "C144147"},
           {"label": "La Joya", "value": "C144148"},
           {"label": "La Marque", "value": "C144149"},
           {"label": "La Paloma", "value": "C144150"},
           {"label": "La Porte", "value": "C144151"},
           {"label": "La Pryor", "value": "C144152"},
           {"label": "La Salle County", "value": "C144153"},
           {"label": "La Vernia", "value": "C144154"},
           {"label": "La Villa", "value": "C144155"},
           {"label": "Lackland Air Force Base", "value": "C144156"},
           {"label": "Lacy-Lakeview", "value": "C144157"},
           {"label": "Lago Vista", "value": "C144158"},
           {"label": "Laguna Heights", "value": "C144159"},
           {"label": "Laguna Park", "value": "C144160"},
           {"label": "Laguna Vista", "value": "C144161"},
           {"label": "Lake Brownwood", "value": "C144162"},
           {"label": "Lake Dallas", "value": "C144163"},
           {"label": "Lake Dunlap", "value": "C144164"},
           {"label": "Lake Jackson", "value": "C144165"},
           {"label": "Lake Kiowa", "value": "C144166"},
           {"label": "Lake Worth", "value": "C144167"},
           {"label": "Lakehills", "value": "C144168"},
           {"label": "Lakeside", "value": "C144169"},
           {"label": "Lakeway", "value": "C144170"},
           {"label": "Lamar County", "value": "C144171"},
           {"label": "Lamb County", "value": "C144172"},
           {"label": "Lamesa", "value": "C144173"},
           {"label": "Lampasas", "value": "C144174"},
           {"label": "Lampasas County", "value": "C144175"},
           {"label": "Lancaster", "value": "C144176"},
           {"label": "Lantana", "value": "C144177"},
           {"label": "Laredo", "value": "C144178"},
           {"label": "Las Lomas", "value": "C144179"},
           {"label": "Las Palmas II", "value": "C144180"},
           {"label": "Las Quintas Fronterizas", "value": "C144181"},
           {"label": "Las Quintas Fronterizas Colonia", "value": "C144182"},
           {"label": "Lasara", "value": "C144183"},
           {"label": "Laughlin Air Force Base", "value": "C144184"},
           {"label": "Laureles", "value": "C144185"},
           {"label": "Lavaca County", "value": "C144186"},
           {"label": "Lavon", "value": "C144187"},
           {"label": "League City", "value": "C144188"},
           {"label": "Leakey", "value": "C144189"},
           {"label": "Leander", "value": "C144190"},
           {"label": "Lee County", "value": "C144191"},
           {"label": "Leon County", "value": "C144192"},
           {"label": "Leon Valley", "value": "C144193"},
           {"label": "Leonard", "value": "C144194"},
           {"label": "Levelland", "value": "C144195"},
           {"label": "Lewisville", "value": "C144196"},
           {"label": "Lexington", "value": "C144197"},
           {"label": "Liberty", "value": "C144198"},
           {"label": "Liberty City", "value": "C144199"},
           {"label": "Liberty County", "value": "C144200"},
           {"label": "Liberty Hill", "value": "C144201"},
           {"label": "Limestone County", "value": "C144202"},
           {"label": "Lindale", "value": "C144203"},
           {"label": "Linden", "value": "C144204"},
           {"label": "Lindsay", "value": "C144205"},
           {"label": "Lipscomb County", "value": "C144206"},
           {"label": "Little Elm", "value": "C144207"},
           {"label": "Little River-Academy", "value": "C144208"},
           {"label": "Littlefield", "value": "C144209"},
           {"label": "Live Oak", "value": "C144210"},
           {"label": "Live Oak County", "value": "C144211"},
           {"label": "Livingston", "value": "C144212"},
           {"label": "Llano", "value": "C144213"},
           {"label": "Llano County", "value": "C144214"},
           {"label": "Llano Grande", "value": "C144215"},
           {"label": "Lockhart", "value": "C144216"},
           {"label": "Lockney", "value": "C144217"},
           {"label": "Lone Star", "value": "C144218"},
           {"label": "Longview", "value": "C144219"},
           {"label": "Lopezville", "value": "C144220"},
           {"label": "Lorena", "value": "C144221"},
           {"label": "Lorenzo", "value": "C144222"},
           {"label": "Los Fresnos", "value": "C144223"},
           {"label": "Los Indios", "value": "C144224"},
           {"label": "Lost Creek", "value": "C144225"},
           {"label": "Loving County", "value": "C144226"},
           {"label": "Lowry Crossing", "value": "C144227"},
           {"label": "Lubbock", "value": "C144228"},
           {"label": "Lubbock County", "value": "C144229"},
           {"label": "Lucas", "value": "C144230"},
           {"label": "Lufkin", "value": "C144231"},
           {"label": "Luling", "value": "C144232"},
           {"label": "Lumberton", "value": "C144233"},
           {"label": "Lyford", "value": "C144234"},
           {"label": "Lynn County", "value": "C144235"},
           {"label": "Lytle", "value": "C144236"},
           {"label": "Mabank", "value": "C144237"},
           {"label": "Madison County", "value": "C144238"},
           {"label": "Madisonville", "value": "C144239"},
           {"label": "Magnolia", "value": "C144240"},
           {"label": "Malakoff", "value": "C144241"},
           {"label": "Manchaca", "value": "C144242"},
           {"label": "Manor", "value": "C144243"},
           {"label": "Mansfield", "value": "C144244"},
           {"label": "Manvel", "value": "C144245"},
           {"label": "Marble Falls", "value": "C144246"},
           {"label": "Marfa", "value": "C144247"},
           {"label": "Marion", "value": "C144248"},
           {"label": "Marion County", "value": "C144249"},
           {"label": "Markham", "value": "C144250"},
           {"label": "Marlin", "value": "C144251"},
           {"label": "Marshall", "value": "C144252"},
           {"label": "Mart", "value": "C144253"},
           {"label": "Martin County", "value": "C144254"},
           {"label": "Martindale", "value": "C144255"},
           {"label": "Mason", "value": "C144256"},
           {"label": "Mason County", "value": "C144257"},
           {"label": "Matador", "value": "C144258"},
           {"label": "Matagorda County", "value": "C144259"},
           {"label": "Mathis", "value": "C144260"},
           {"label": "Maud", "value": "C144261"},
           {"label": "Mauriceville", "value": "C144262"},
           {"label": "Maverick County", "value": "C144263"},
           {"label": "McAllen", "value": "C144264"},
           {"label": "McCamey", "value": "C144265"},
           {"label": "McCulloch County", "value": "C144266"},
           {"label": "McGregor", "value": "C144267"},
           {"label": "McKinney", "value": "C144268"},
           {"label": "McLendon-Chisholm", "value": "C144269"},
           {"label": "McLennan County", "value": "C144270"},
           {"label": "McMullen County", "value": "C144271"},
           {"label": "McQueeney", "value": "C144272"},
           {"label": "Meadowlakes", "value": "C144273"},
           {"label": "Meadows Place", "value": "C144274"},
           {"label": "Medina", "value": "C144275"},
           {"label": "Medina County", "value": "C144276"},
           {"label": "Melissa", "value": "C144277"},
           {"label": "Memphis", "value": "C144278"},
           {"label": "Menard", "value": "C144279"},
           {"label": "Menard County", "value": "C144280"},
           {"label": "Mentone", "value": "C144281"},
           {"label": "Mercedes", "value": "C144282"},
           {"label": "Meridian", "value": "C144283"},
           {"label": "Merkel", "value": "C144284"},
           {"label": "Mertzon", "value": "C144285"},
           {"label": "Mesquite", "value": "C144286"},
           {"label": "Mexia", "value": "C144287"},
           {"label": "Miami", "value": "C144288"},
           {"label": "Midland", "value": "C144289"},
           {"label": "Midland County", "value": "C144290"},
           {"label": "Midlothian", "value": "C144291"},
           {"label": "Midway North", "value": "C144292"},
           {"label": "Midway South", "value": "C144293"},
           {"label": "Mila Doce", "value": "C144294"},
           {"label": "Milam", "value": "C144295"},
           {"label": "Milam County", "value": "C144296"},
           {"label": "Mills County", "value": "C144297"},
           {"label": "Mineola", "value": "C144298"},
           {"label": "Mineral Wells", "value": "C144299"},
           {"label": "Mission", "value": "C144300"},
           {"label": "Mission Bend", "value": "C144301"},
           {"label": "Missouri City", "value": "C144302"},
           {"label": "Mitchell County", "value": "C144303"},
           {"label": "Monahans", "value": "C144304"},
           {"label": "Mont Belvieu", "value": "C144305"},
           {"label": "Montague", "value": "C144306"},
           {"label": "Montague County", "value": "C144307"},
           {"label": "Monte Alto", "value": "C144308"},
           {"label": "Montgomery County", "value": "C144309"},
           {"label": "Moody", "value": "C144310"},
           {"label": "Moore County", "value": "C144311"},
           {"label": "Morgans Point Resort", "value": "C144312"},
           {"label": "Morris County", "value": "C144313"},
           {"label": "Morton", "value": "C144314"},
           {"label": "Motley County", "value": "C144315"},
           {"label": "Mount Pleasant", "value": "C144316"},
           {"label": "Mount Vernon", "value": "C144317"},
           {"label": "Muenster", "value": "C144318"},
           {"label": "Muleshoe", "value": "C144319"},
           {"label": "Munday", "value": "C144320"},
           {"label": "Muniz", "value": "C144321"},
           {"label": "Murillo Colonia", "value": "C144322"},
           {"label": "Murphy", "value": "C144323"},
           {"label": "Nacogdoches", "value": "C144324"},
           {"label": "Nacogdoches County", "value": "C144325"},
           {"label": "Naples", "value": "C144326"},
           {"label": "Nash", "value": "C144327"},
           {"label": "Nassau Bay", "value": "C144328"},
           {"label": "Natalia", "value": "C144329"},
           {"label": "Navarro County", "value": "C144330"},
           {"label": "Navasota", "value": "C144331"},
           {"label": "Nederland", "value": "C144332"},
           {"label": "Needville", "value": "C144333"},
           {"label": "Nevada", "value": "C144334"},
           {"label": "New Boston", "value": "C144335"},
           {"label": "New Braunfels", "value": "C144336"},
           {"label": "New Summerfield", "value": "C144337"},
           {"label": "New Territory", "value": "C144338"},
           {"label": "New Waverly", "value": "C144339"},
           {"label": "Newark", "value": "C144340"},
           {"label": "Newton", "value": "C144341"},
           {"label": "Newton County", "value": "C144342"},
           {"label": "Nixon", "value": "C144343"},
           {"label": "Nocona", "value": "C144344"},
           {"label": "Nolan County", "value": "C144345"},
           {"label": "Nolanville", "value": "C144346"},
           {"label": "North Alamo", "value": "C144347"},
           {"label": "North Richland Hills", "value": "C144348"},
           {"label": "Northcliff", "value": "C144349"},
           {"label": "Northcrest", "value": "C144350"},
           {"label": "Northlake", "value": "C144351"},
           {"label": "Nueces County", "value": "C144352"},
           {"label": "Nurillo", "value": "C144353"},
           {"label": "Oak Cliff Place", "value": "C144354"},
           {"label": "Oak Leaf", "value": "C144355"},
           {"label": "Oak Point", "value": "C144356"},
           {"label": "Oak Ridge North", "value": "C144357"},
           {"label": "Oak Trail Shores", "value": "C144358"},
           {"label": "Ochiltree County", "value": "C144359"},
           {"label": "Odem", "value": "C144360"},
           {"label": "Odessa", "value": "C144361"},
           {"label": "Old River-Winfree", "value": "C144362"},
           {"label": "Oldham County", "value": "C144363"},
           {"label": "Olivarez", "value": "C144364"},
           {"label": "Olmito", "value": "C144365"},
           {"label": "Olmos Park", "value": "C144366"},
           {"label": "Olney", "value": "C144367"},
           {"label": "Olton", "value": "C144368"},
           {"label": "Onalaska", "value": "C144369"},
           {"label": "Onion Creek", "value": "C144370"},
           {"label": "Orange", "value": "C144371"},
           {"label": "Orange County", "value": "C144372"},
           {"label": "Orange Grove", "value": "C144373"},
           {"label": "Ore City", "value": "C144374"},
           {"label": "Overton", "value": "C144375"},
           {"label": "Ovilla", "value": "C144376"},
           {"label": "Oyster Creek", "value": "C144377"},
           {"label": "Ozona", "value": "C144378"},
           {"label": "Paducah", "value": "C144379"},
           {"label": "Paint Rock", "value": "C144380"},
           {"label": "Palacios", "value": "C144381"},
           {"label": "Palestine", "value": "C144382"},
           {"label": "Palm Valley", "value": "C144383"},
           {"label": "Palmer", "value": "C144384"},
           {"label": "Palmhurst", "value": "C144385"},
           {"label": "Palmview", "value": "C144386"},
           {"label": "Palmview South", "value": "C144387"},
           {"label": "Palo Pinto", "value": "C144388"},
           {"label": "Palo Pinto County", "value": "C144389"},
           {"label": "Paloma Creek", "value": "C144390"},
           {"label": "Paloma Creek South", "value": "C144391"},
           {"label": "Pampa", "value": "C144392"},
           {"label": "Panhandle", "value": "C144393"},
           {"label": "Panola County", "value": "C144394"},
           {"label": "Panorama Village", "value": "C144395"},
           {"label": "Pantego", "value": "C144396"},
           {"label": "Paris", "value": "C144397"},
           {"label": "Parker", "value": "C144398"},
           {"label": "Parker County", "value": "C144399"},
           {"label": "Parmer County", "value": "C144400"},
           {"label": "Pasadena", "value": "C144401"},
           {"label": "Patton Village", "value": "C144402"},
           {"label": "Pearland", "value": "C144403"},
           {"label": "Pearsall", "value": "C144404"},
           {"label": "Pecan Acres", "value": "C144405"},
           {"label": "Pecan Grove", "value": "C144406"},
           {"label": "Pecan Plantation", "value": "C144407"},
           {"label": "Pecos", "value": "C144408"},
           {"label": "Pecos County", "value": "C144409"},
           {"label": "Pelican Bay", "value": "C144410"},
           {"label": "Penitas", "value": "C144411"},
           {"label": "Perezville", "value": "C144412"},
           {"label": "Perryton", "value": "C144413"},
           {"label": "Petersburg", "value": "C144414"},
           {"label": "Pflugerville", "value": "C144415"},
           {"label": "Pharr", "value": "C144416"},
           {"label": "Pilot Point", "value": "C144417"},
           {"label": "Pine Island", "value": "C144418"},
           {"label": "Pinehurst", "value": "C144419"},
           {"label": "Pinewood Estates", "value": "C144420"},
           {"label": "Piney Point Village", "value": "C144421"},
           {"label": "Pittsburg", "value": "C144422"},
           {"label": "Plains", "value": "C144423"},
           {"label": "Plainview", "value": "C144424"},
           {"label": "Plano", "value": "C144425"},
           {"label": "Pleak", "value": "C144426"},
           {"label": "Pleasanton", "value": "C144427"},
           {"label": "Plum Grove", "value": "C144428"},
           {"label": "Polk County", "value": "C144429"},
           {"label": "Ponder", "value": "C144430"},
           {"label": "Port Aransas", "value": "C144431"},
           {"label": "Port Arthur", "value": "C144432"},
           {"label": "Port Isabel", "value": "C144433"},
           {"label": "Port Lavaca", "value": "C144434"},
           {"label": "Port Neches", "value": "C144435"},
           {"label": "Port OConnor", "value": "C144436"},
           {"label": "Porter Heights", "value": "C144437"},
           {"label": "Portland", "value": "C144438"},
           {"label": "Post", "value": "C144439"},
           {"label": "Poteet", "value": "C144440"},
           {"label": "Poth", "value": "C144441"},
           {"label": "Potosi", "value": "C144442"},
           {"label": "Potter County", "value": "C144443"},
           {"label": "Pottsboro", "value": "C144444"},
           {"label": "Powderly", "value": "C144445"},
           {"label": "Prairie View", "value": "C144446"},
           {"label": "Premont", "value": "C144447"},
           {"label": "Presidio", "value": "C144448"},
           {"label": "Presidio County", "value": "C144449"},
           {"label": "Preston", "value": "C144450"},
           {"label": "Primera", "value": "C144451"},
           {"label": "Princeton", "value": "C144452"},
           {"label": "Progreso", "value": "C144453"},
           {"label": "Prosper", "value": "C144454"},
           {"label": "Providence", "value": "C144455"},
           {"label": "Quail Creek", "value": "C144456"},
           {"label": "Quanah", "value": "C144457"},
           {"label": "Queen City", "value": "C144458"},
           {"label": "Quinlan", "value": "C144459"},
           {"label": "Quitman", "value": "C144460"},
           {"label": "Rains County", "value": "C144461"},
           {"label": "Ralls", "value": "C144462"},
           {"label": "Rancho Alegre", "value": "C144463"},
           {"label": "Rancho Viejo", "value": "C144464"},
           {"label": "Randall County", "value": "C144465"},
           {"label": "Ranger", "value": "C144466"},
           {"label": "Rankin", "value": "C144467"},
           {"label": "Ransom Canyon", "value": "C144468"},
           {"label": "Raymondville", "value": "C144469"},
           {"label": "Reagan County", "value": "C144470"},
           {"label": "Real County", "value": "C144471"},
           {"label": "Red Lick", "value": "C144472"},
           {"label": "Red Oak", "value": "C144473"},
           {"label": "Red River County", "value": "C144474"},
           {"label": "Redland", "value": "C144475"},
           {"label": "Redwater", "value": "C144476"},
           {"label": "Redwood", "value": "C144477"},
           {"label": "Reeves County", "value": "C144478"},
           {"label": "Refugio", "value": "C144479"},
           {"label": "Refugio County", "value": "C144480"},
           {"label": "Rendon", "value": "C144481"},
           {"label": "Reno", "value": "C144482"},
           {"label": "Rhome", "value": "C144483"},
           {"label": "Ricardo", "value": "C144484"},
           {"label": "Richardson", "value": "C144485"},
           {"label": "Richland Hills", "value": "C144486"},
           {"label": "Richmond", "value": "C144487"},
           {"label": "Richwood", "value": "C144488"},
           {"label": "Riesel", "value": "C144489"},
           {"label": "Rio Bravo", "value": "C144490"},
           {"label": "Rio Grande City", "value": "C144491"},
           {"label": "Rio Hondo", "value": "C144492"},
           {"label": "River Oaks", "value": "C144493"},
           {"label": "Roanoke", "value": "C144494"},
           {"label": "Robert Lee", "value": "C144495"},
           {"label": "Roberts County", "value": "C144496"},
           {"label": "Robertson County", "value": "C144497"},
           {"label": "Robinson", "value": "C144498"},
           {"label": "Robstown", "value": "C144499"},
           {"label": "Roby", "value": "C144500"},
           {"label": "Rockdale", "value": "C144501"},
           {"label": "Rockport", "value": "C144502"},
           {"label": "Rocksprings", "value": "C144503"},
           {"label": "Rockwall", "value": "C144504"},
           {"label": "Rockwall County", "value": "C144505"},
           {"label": "Rogers", "value": "C144506"},
           {"label": "Rollingwood", "value": "C144507"},
           {"label": "Roma", "value": "C144508"},
           {"label": "Roma-Los Saenz", "value": "C144509"},
           {"label": "Roman Forest", "value": "C144510"},
           {"label": "Roscoe", "value": "C144511"},
           {"label": "Rosebud", "value": "C144512"},
           {"label": "Rosenberg", "value": "C144513"},
           {"label": "Rosharon", "value": "C144514"},
           {"label": "Rosita North", "value": "C144515"},
           {"label": "Rosita South", "value": "C144516"},
           {"label": "Rotan", "value": "C144517"},
           {"label": "Round Rock", "value": "C144518"},
           {"label": "Rowlett", "value": "C144519"},
           {"label": "Royse City", "value": "C144520"},
           {"label": "Runaway Bay", "value": "C144521"},
           {"label": "Runge", "value": "C144522"},
           {"label": "Runnels County", "value": "C144523"},
           {"label": "Rusk", "value": "C144524"},
           {"label": "Rusk County", "value": "C144525"},
           {"label": "Sabinal", "value": "C144526"},
           {"label": "Sabine County", "value": "C144527"},
           {"label": "Sachse", "value": "C144528"},
           {"label": "Saginaw", "value": "C144529"},
           {"label": "Saint Hedwig", "value": "C144530"},
           {"label": "Saint Jo", "value": "C144531"},
           {"label": "Saint Paul", "value": "C144532"},
           {"label": "Salado", "value": "C144533"},
           {"label": "Sam Rayburn", "value": "C144534"},
           {"label": "San Angelo", "value": "C144535"},
           {"label": "San Antonio", "value": "C144536"},
           {"label": "San Augustine", "value": "C144537"},
           {"label": "San Augustine County", "value": "C144538"},
           {"label": "San Benito", "value": "C144539"},
           {"label": "San Carlos", "value": "C144540"},
           {"label": "San Diego", "value": "C144541"},
           {"label": "San Elizario", "value": "C144542"},
           {"label": "San Jacinto County", "value": "C144543"},
           {"label": "San Juan", "value": "C144544"},
           {"label": "San Leon", "value": "C144545"},
           {"label": "San Marcos", "value": "C144546"},
           {"label": "San Patricio County", "value": "C144547"},
           {"label": "San Saba", "value": "C144548"},
           {"label": "San Saba County", "value": "C144549"},
           {"label": "Sanderson", "value": "C144550"},
           {"label": "Sanger", "value": "C144551"},
           {"label": "Sansom Park", "value": "C144552"},
           {"label": "Santa Anna", "value": "C144553"},
           {"label": "Santa Fe", "value": "C144554"},
           {"label": "Santa Rosa", "value": "C144555"},
           {"label": "Sarita", "value": "C144556"},
           {"label": "Savannah", "value": "C144557"},
           {"label": "Scenic Oaks", "value": "C144558"},
           {"label": "Schertz", "value": "C144559"},
           {"label": "Schleicher County", "value": "C144560"},
           {"label": "Schulenburg", "value": "C144561"},
           {"label": "Scissors", "value": "C144562"},
           {"label": "Scurry County", "value": "C144563"},
           {"label": "Seabrook", "value": "C144564"},
           {"label": "Seadrift", "value": "C144565"},
           {"label": "Seagoville", "value": "C144566"},
           {"label": "Seagraves", "value": "C144567"},
           {"label": "Sealy", "value": "C144568"},
           {"label": "Sebastian", "value": "C144569"},
           {"label": "Seguin", "value": "C144570"},
           {"label": "Selma", "value": "C144571"},
           {"label": "Seminole", "value": "C144572"},
           {"label": "Serenada", "value": "C144573"},
           {"label": "Seth Ward", "value": "C144574"},
           {"label": "Seven Points", "value": "C144575"},
           {"label": "Seymour", "value": "C144576"},
           {"label": "Shackelford County", "value": "C144577"},
           {"label": "Shady Hollow", "value": "C144578"},
           {"label": "Shady Shores", "value": "C144579"},
           {"label": "Shallowater", "value": "C144580"},
           {"label": "Shamrock", "value": "C144581"},
           {"label": "Shavano Park", "value": "C144582"},
           {"label": "Shelby County", "value": "C144583"},
           {"label": "Sheldon", "value": "C144584"},
           {"label": "Shenandoah", "value": "C144585"},
           {"label": "Shepherd", "value": "C144586"},
           {"label": "Sherman", "value": "C144587"},
           {"label": "Sherman County", "value": "C144588"},
           {"label": "Sherwood Shores", "value": "C144589"},
           {"label": "Shiner", "value": "C144590"},
           {"label": "Shoreacres", "value": "C144591"},
           {"label": "Sienna Plantation", "value": "C144592"},
           {"label": "Sierra Blanca", "value": "C144593"},
           {"label": "Siesta Acres", "value": "C144594"},
           {"label": "Siesta Shores", "value": "C144595"},
           {"label": "Silsbee", "value": "C144596"},
           {"label": "Silverton", "value": "C144597"},
           {"label": "Sinton", "value": "C144598"},
           {"label": "Slaton", "value": "C144599"},
           {"label": "Smith County", "value": "C144600"},
           {"label": "Smithville", "value": "C144601"},
           {"label": "Snyder", "value": "C144602"},
           {"label": "Socorro", "value": "C144603"},
           {"label": "Socorro Mission Number 1 Colonia", "value": "C144604"},
           {"label": "Somerset", "value": "C144605"},
           {"label": "Somervell County", "value": "C144606"},
           {"label": "Somerville", "value": "C144607"},
           {"label": "Sonora", "value": "C144608"},
           {"label": "Sour Lake", "value": "C144609"},
           {"label": "South Alamo", "value": "C144610"},
           {"label": "South Houston", "value": "C144611"},
           {"label": "South Padre Island", "value": "C144612"},
           {"label": "South Point", "value": "C144613"},
           {"label": "Southlake", "value": "C144614"},
           {"label": "Southmayd", "value": "C144615"},
           {"label": "Southside Place", "value": "C144616"},
           {"label": "Sparks", "value": "C144617"},
           {"label": "Spearman", "value": "C144618"},
           {"label": "Splendora", "value": "C144619"},
           {"label": "Spring", "value": "C144620"},
           {"label": "Spring Valley", "value": "C144621"},
           {"label": "Springtown", "value": "C144622"},
           {"label": "Spur", "value": "C144623"},
           {"label": "Stafford", "value": "C144624"},
           {"label": "Stamford", "value": "C144625"},
           {"label": "Stanton", "value": "C144626"},
           {"label": "Starr County", "value": "C144627"},
           {"label": "Stephens County", "value": "C144628"},
           {"label": "Stephenville", "value": "C144629"},
           {"label": "Sterling City", "value": "C144630"},
           {"label": "Sterling County", "value": "C144631"},
           {"label": "Stinnett", "value": "C144632"},
           {"label": "Stockdale", "value": "C144633"},
           {"label": "Stonewall County", "value": "C144634"},
           {"label": "Stowell", "value": "C144635"},
           {"label": "Stratford", "value": "C144636"},
           {"label": "Sugar Land", "value": "C144637"},
           {"label": "Sullivan City", "value": "C144638"},
           {"label": "Sulphur Springs", "value": "C144639"},
           {"label": "Sundown", "value": "C144640"},
           {"label": "Sunnyvale", "value": "C144641"},
           {"label": "Sunray", "value": "C144642"},
           {"label": "Sutton County", "value": "C144643"},
           {"label": "Sweeny", "value": "C144644"},
           {"label": "Sweetwater", "value": "C144645"},
           {"label": "Swisher County", "value": "C144646"},
           {"label": "Taft", "value": "C144647"},
           {"label": "Taft Southwest (historical)", "value": "C144648"},
           {"label": "Tahoka", "value": "C144649"},
           {"label": "Talty", "value": "C144650"},
           {"label": "Tarrant County", "value": "C144651"},
           {"label": "Tatum", "value": "C144652"},
           {"label": "Taylor", "value": "C144653"},
           {"label": "Taylor County", "value": "C144654"},
           {"label": "Taylor Lake Village", "value": "C144655"},
           {"label": "Teague", "value": "C144656"},
           {"label": "Temple", "value": "C144657"},
           {"label": "Tenaha", "value": "C144658"},
           {"label": "Terrell", "value": "C144659"},
           {"label": "Terrell County", "value": "C144660"},
           {"label": "Terrell Hills", "value": "C144661"},
           {"label": "Terry County", "value": "C144662"},
           {"label": "Texarkana", "value": "C144663"},
           {"label": "Texas City", "value": "C144664"},
           {"label": "The Colony", "value": "C144665"},
           {"label": "The Hills", "value": "C144666"},
           {"label": "The Woodlands", "value": "C144667"},
           {"label": "Thorndale", "value": "C144668"},
           {"label": "Three Rivers", "value": "C144669"},
           {"label": "Throckmorton", "value": "C144670"},
           {"label": "Throckmorton County", "value": "C144671"},
           {"label": "Tiki Island", "value": "C144672"},
           {"label": "Tilden", "value": "C144673"},
           {"label": "Timberwood Park", "value": "C144674"},
           {"label": "Timpson", "value": "C144675"},
           {"label": "Titus County", "value": "C144676"},
           {"label": "Tom Bean", "value": "C144677"},
           {"label": "Tom Green County", "value": "C144678"},
           {"label": "Tomball", "value": "C144679"},
           {"label": "Tool", "value": "C144680"},
           {"label": "Tornillo", "value": "C144681"},
           {"label": "Travis County", "value": "C144682"},
           {"label": "Travis Ranch", "value": "C144683"},
           {"label": "Trinity", "value": "C144684"},
           {"label": "Trinity County", "value": "C144685"},
           {"label": "Trophy Club", "value": "C144686"},
           {"label": "Troup", "value": "C144687"},
           {"label": "Troy", "value": "C144688"},
           {"label": "Tulia", "value": "C144689"},
           {"label": "Tye", "value": "C144690"},
           {"label": "Tyler", "value": "C144691"},
           {"label": "Tyler County", "value": "C144692"},
           {"label": "Uhland", "value": "C144693"},
           {"label": "Universal City", "value": "C144694"},
           {"label": "University Park", "value": "C144695"},
           {"label": "Upshur County", "value": "C144696"},
           {"label": "Upton County", "value": "C144697"},
           {"label": "Uvalde", "value": "C144698"},
           {"label": "Uvalde County", "value": "C144699"},
           {"label": "Uvalde Estates", "value": "C144700"},
           {"label": "Val Verde County", "value": "C144701"},
           {"label": "Val Verde Park", "value": "C144702"},
           {"label": "Valley Mills", "value": "C144703"},
           {"label": "Van", "value": "C144704"},
           {"label": "Van Alstyne", "value": "C144705"},
           {"label": "Van Horn", "value": "C144706"},
           {"label": "Van Vleck", "value": "C144707"},
           {"label": "Van Zandt County", "value": "C144708"},
           {"label": "Vega", "value": "C144709"},
           {"label": "Venus", "value": "C144710"},
           {"label": "Vernon", "value": "C144711"},
           {"label": "Victoria", "value": "C144712"},
           {"label": "Victoria County", "value": "C144713"},
           {"label": "Vidor", "value": "C144714"},
           {"label": "Vinton", "value": "C144715"},
           {"label": "Von Ormy", "value": "C144716"},
           {"label": "Waco", "value": "C144717"},
           {"label": "Wake Village", "value": "C144718"},
           {"label": "Walker County", "value": "C144719"},
           {"label": "Waller", "value": "C144720"},
           {"label": "Waller County", "value": "C144721"},
           {"label": "Wallis", "value": "C144722"},
           {"label": "Ward County", "value": "C144723"},
           {"label": "Washington County", "value": "C144724"},
           {"label": "Waskom", "value": "C144725"},
           {"label": "Watauga", "value": "C144726"},
           {"label": "Waxahachie", "value": "C144727"},
           {"label": "Weatherford", "value": "C144728"},
           {"label": "Webb County", "value": "C144729"},
           {"label": "Webster", "value": "C144730"},
           {"label": "Weimar", "value": "C144731"},
           {"label": "Wellington", "value": "C144732"},
           {"label": "Wells Branch", "value": "C144733"},
           {"label": "Weslaco", "value": "C144734"},
           {"label": "West", "value": "C144735"},
           {"label": "West Columbia", "value": "C144736"},
           {"label": "West Lake Hills", "value": "C144737"},
           {"label": "West Livingston", "value": "C144738"},
           {"label": "West Odessa", "value": "C144739"},
           {"label": "West Orange", "value": "C144740"},
           {"label": "West Sharyland", "value": "C144741"},
           {"label": "West Tawakoni", "value": "C144742"},
           {"label": "West University Place", "value": "C144743"},
           {"label": "Western Lake", "value": "C144744"},
           {"label": "Westlake", "value": "C144745"},
           {"label": "Weston Lakes", "value": "C144746"},
           {"label": "Westway", "value": "C144747"},
           {"label": "Westworth", "value": "C144748"},
           {"label": "Wharton", "value": "C144749"},
           {"label": "Wharton County", "value": "C144750"},
           {"label": "Wheeler", "value": "C144751"},
           {"label": "Wheeler County", "value": "C144752"},
           {"label": "White Oak", "value": "C144753"},
           {"label": "White Settlement", "value": "C144754"},
           {"label": "Whitehouse", "value": "C144755"},
           {"label": "Whitesboro", "value": "C144756"},
           {"label": "Whitewright", "value": "C144757"},
           {"label": "Whitney", "value": "C144758"},
           {"label": "Wichita County", "value": "C144759"},
           {"label": "Wichita Falls", "value": "C144760"},
           {"label": "Wilbarger County", "value": "C144761"},
           {"label": "Wild Peach Village", "value": "C144762"},
           {"label": "Wildwood", "value": "C144763"},
           {"label": "Willacy County", "value": "C144764"},
           {"label": "Williamson County", "value": "C144765"},
           {"label": "Willis", "value": "C144766"},
           {"label": "Willow Park", "value": "C144767"},
           {"label": "Wills Point", "value": "C144768"},
           {"label": "Wilmer", "value": "C144769"},
           {"label": "Wilson County", "value": "C144770"},
           {"label": "Wimberley", "value": "C144771"},
           {"label": "Windcrest", "value": "C144772"},
           {"label": "Windemere", "value": "C144773"},
           {"label": "Wink", "value": "C144774"},
           {"label": "Winkler County", "value": "C144775"},
           {"label": "Winnie", "value": "C144776"},
           {"label": "Winnsboro", "value": "C144777"},
           {"label": "Winters", "value": "C144778"},
           {"label": "Wise County", "value": "C144779"},
           {"label": "Wolfe City", "value": "C144780"},
           {"label": "Wolfforth", "value": "C144781"},
           {"label": "Wood County", "value": "C144782"},
           {"label": "Woodbranch", "value": "C144783"},
           {"label": "Woodcreek", "value": "C144784"},
           {"label": "Woodsboro", "value": "C144785"},
           {"label": "Woodville", "value": "C144786"},
           {"label": "Woodway", "value": "C144787"},
           {"label": "Wortham", "value": "C144788"},
           {"label": "Wyldwood", "value": "C144789"},
           {"label": "Wylie", "value": "C144790"},
           {"label": "Yoakum", "value": "C144791"},
           {"label": "Yoakum County", "value": "C144792"},
           {"label": "Yorktown", "value": "C144793"},
           {"label": "Young County", "value": "C144794"},
           {"label": "Zapata", "value": "C144795"},
           {"label": "Zapata County", "value": "C144796"},
           {"label": "Zavala County", "value": "C144797"}],
 "Thaa Atoll": [{"label": "Veymandoo", "value": "C65820"}],
 "Thaba-Tseka District": [{"label": "Thaba-Tseka", "value": "C65001"}],
 "Thai Binh": [{"label": "Huyen Dong Hung", "value": "C147645"},
               {"label": "Huyen Hung Ha", "value": "C147646"},
               {"label": "Huyen Kien Xuong", "value": "C147647"},
               {"label": "Huyen Quynh Phu", "value": "C147648"},
               {"label": "Huyen Thai Thuy", "value": "C147649"},
               {"label": "Huyen Tien Hai", "value": "C147650"},
               {"label": "Huyen Vu Thu", "value": "C147651"},
               {"label": "Thai Binh", "value": "C147652"}],
 "Thai Nguyen": [{"label": "Thai Nguyen", "value": "C147653"}],
 "Thanh Hoa": [{"label": "Bim Son", "value": "C147654"},
               {"label": "Huyen Ba Thuoc", "value": "C147655"},
               {"label": "Huyen Cam Thuy", "value": "C147656"},
               {"label": "Huyen Dong Son", "value": "C147657"},
               {"label": "Huyen Ha Trung", "value": "C147658"},
               {"label": "Huyen Hau Loc", "value": "C147659"},
               {"label": "Huyen Lang Chanh", "value": "C147660"},
               {"label": "Huyen Muong Lat", "value": "C147661"},
               {"label": "Huyen Nga Son", "value": "C147662"},
               {"label": "Huyen Ngoc Lac", "value": "C147663"},
               {"label": "Huyen Nhu Thanh", "value": "C147664"},
               {"label": "Huyen Nhu Xuan", "value": "C147665"},
               {"label": "Huyen Nong Cong", "value": "C147666"},
               {"label": "Huyen Quan Hoa", "value": "C147667"},
               {"label": "Huyen Quan Son", "value": "C147668"},
               {"label": "Huyen Quang Xuong", "value": "C147669"},
               {"label": "Huyen Thieu Hoa", "value": "C147670"},
               {"label": "Huyen Tho Xuan", "value": "C147671"},
               {"label": "Huyen Thuong Xuan", "value": "C147672"},
               {"label": "Huyen Trieu Son", "value": "C147673"},
               {"label": "Huyen Vinh Loc", "value": "C147674"},
               {"label": "Huyen Yen Dinh", "value": "C147675"},
               {"label": "Thanh Hoa", "value": "C147676"}],
 "Tharaka-Nithi": [{"label": "Chuka", "value": "C64637"}],
 "Thies Region": [{"label": "Joal-Fadiout", "value": "C106878"},
                  {"label": "Kayar", "value": "C106879"},
                  {"label": "Khombole", "value": "C106880"},
                  {"label": "Mbour", "value": "C106881"},
                  {"label": "Mekhe", "value": "C106882"},
                  {"label": "Nguekhokh", "value": "C106883"},
                  {"label": "Pout", "value": "C106884"},
                  {"label": "Thies", "value": "C106885"},
                  {"label": "Thies Nones", "value": "C106886"},
                  {"label": "Tiadiaye", "value": "C106887"},
                  {"label": "Tivaouane", "value": "C106888"},
                  {"label": "Warang", "value": "C106889"}],
 "Thimphu District": [{"label": "Thimphu", "value": "C9647"}],
 "Thua Thien-Hue": [{"label": "Hue", "value": "C147677"},
                    {"label": "Huyen A Luoi", "value": "C147678"},
                    {"label": "Huyen Nam Dong", "value": "C147679"},
                    {"label": "Huyen Phong Dien", "value": "C147680"},
                    {"label": "Huyen Phu Loc", "value": "C147681"},
                    {"label": "Huyen Phu Vang", "value": "C147682"},
                    {"label": "Huyen Quang Dien", "value": "C147683"}],
 "Thurgau": [{"label": "Aadorf", "value": "C117128"},
             {"label": "Affeltrangen", "value": "C117129"},
             {"label": "Altnau", "value": "C117130"},
             {"label": "Amriswil", "value": "C117131"},
             {"label": "Arbon", "value": "C117132"},
             {"label": "Arbon District", "value": "C117133"},
             {"label": "Berg", "value": "C117134"},
             {"label": "Bottighofen", "value": "C117135"},
             {"label": "Burglen", "value": "C117136"},
             {"label": "Diessenhofen", "value": "C117137"},
             {"label": "Egnach", "value": "C117138"},
             {"label": "Erlen", "value": "C117139"},
             {"label": "Ermatingen", "value": "C117140"},
             {"label": "Eschenz", "value": "C117141"},
             {"label": "Eschlikon", "value": "C117142"},
             {"label": "Fischingen", "value": "C117143"},
             {"label": "Frauenfeld", "value": "C117144"},
             {"label": "Frauenfeld District", "value": "C117145"},
             {"label": "Gachnang", "value": "C117146"},
             {"label": "Gottlieben", "value": "C117147"},
             {"label": "Guttingen", "value": "C117148"},
             {"label": "Homburg", "value": "C117149"},
             {"label": "Huttwilen", "value": "C117150"},
             {"label": "Kreuzlingen", "value": "C117151"},
             {"label": "Kreuzlingen District", "value": "C117152"},
             {"label": "Langrickenbach", "value": "C117153"},
             {"label": "Marstetten-Dorf", "value": "C117154"},
             {"label": "Matzingen", "value": "C117155"},
             {"label": "Mullheim", "value": "C117156"},
             {"label": "Munchwilen", "value": "C117157"},
             {"label": "Munchwilen District", "value": "C117158"},
             {"label": "Munsterlingen", "value": "C117159"},
             {"label": "Pfyn", "value": "C117160"},
             {"label": "Rickenbach bei Wil", "value": "C117161"},
             {"label": "Roggwil", "value": "C117162"},
             {"label": "Romanshorn", "value": "C117163"},
             {"label": "Salmsach", "value": "C117164"},
             {"label": "Sirnach", "value": "C117165"},
             {"label": "Steckborn", "value": "C117166"},
             {"label": "Stettfurt", "value": "C117167"},
             {"label": "Sulgen", "value": "C117168"},
             {"label": "Tagerwilen", "value": "C117169"},
             {"label": "Thundorf", "value": "C117170"},
             {"label": "Uttwil", "value": "C117171"},
             {"label": "Wagenhausen", "value": "C117172"},
             {"label": "Wangi", "value": "C117173"},
             {"label": "Weinfelden", "value": "C117174"},
             {"label": "Weinfelden District", "value": "C117175"},
             {"label": "Wigoltingen", "value": "C117176"}],
 "Thuringia": [{"label": "Altenburg", "value": "C41143"},
               {"label": "Altenfeld", "value": "C41144"},
               {"label": "Altengottern", "value": "C41145"},
               {"label": "Altkirchen", "value": "C41146"},
               {"label": "Anrode", "value": "C41147"},
               {"label": "Apfelstadt", "value": "C41148"},
               {"label": "Apolda", "value": "C41149"},
               {"label": "Arenshausen", "value": "C41150"},
               {"label": "Arnstadt", "value": "C41151"},
               {"label": "Artern", "value": "C41152"},
               {"label": "Auleben", "value": "C41153"},
               {"label": "Auma", "value": "C41154"},
               {"label": "Bad Berka", "value": "C41155"},
               {"label": "Bad Blankenburg", "value": "C41156"},
               {"label": "Bad Frankenhausen", "value": "C41157"},
               {"label": "Bad Klosterlausnitz", "value": "C41158"},
               {"label": "Bad Kostritz", "value": "C41159"},
               {"label": "Bad Langensalza", "value": "C41160"},
               {"label": "Bad Liebenstein", "value": "C41161"},
               {"label": "Bad Lobenstein", "value": "C41162"},
               {"label": "Bad Salzungen", "value": "C41163"},
               {"label": "Bad Sulza", "value": "C41164"},
               {"label": "Bad Tennstedt", "value": "C41165"},
               {"label": "Barchfeld", "value": "C41166"},
               {"label": "Behringen", "value": "C41167"},
               {"label": "Benshausen", "value": "C41168"},
               {"label": "Berga", "value": "C41169"},
               {"label": "Berlingerode", "value": "C41170"},
               {"label": "Berlstedt", "value": "C41171"},
               {"label": "Bischofferode", "value": "C41172"},
               {"label": "Blankenhain", "value": "C41173"},
               {"label": "Bleicherode", "value": "C41174"},
               {"label": "Brahmenau", "value": "C41175"},
               {"label": "Brehme", "value": "C41176"},
               {"label": "Breitenbach", "value": "C41177"},
               {"label": "Breitenworbis", "value": "C41178"},
               {"label": "Breitungen", "value": "C41179"},
               {"label": "Brotterode", "value": "C41180"},
               {"label": "Bucha", "value": "C41181"},
               {"label": "Bufleben", "value": "C41182"},
               {"label": "Burgel", "value": "C41183"},
               {"label": "Buttelstedt", "value": "C41184"},
               {"label": "Buttlar", "value": "C41185"},
               {"label": "Buttstadt", "value": "C41186"},
               {"label": "Buttstedt", "value": "C41187"},
               {"label": "Camburg", "value": "C41188"},
               {"label": "Clingen", "value": "C41189"},
               {"label": "Crawinkel", "value": "C41190"},
               {"label": "Creuzburg", "value": "C41191"},
               {"label": "Dachwig", "value": "C41192"},
               {"label": "Dankmarshausen", "value": "C41193"},
               {"label": "Dermbach", "value": "C41194"},
               {"label": "Deuna", "value": "C41195"},
               {"label": "Dingelstadt", "value": "C41196"},
               {"label": "Dippach", "value": "C41197"},
               {"label": "Dollstadt", "value": "C41198"},
               {"label": "Dorndorf", "value": "C41199"},
               {"label": "Ebeleben", "value": "C41200"},
               {"label": "Effelder", "value": "C41201"},
               {"label": "Eisenach", "value": "C41202"},
               {"label": "Eisenberg", "value": "C41203"},
               {"label": "Eisfeld", "value": "C41204"},
               {"label": "Elgersburg", "value": "C41205"},
               {"label": "Ellrich", "value": "C41206"},
               {"label": "Elxleben", "value": "C41207"},
               {"label": "Erfurt", "value": "C41208"},
               {"label": "Ernstroda", "value": "C41209"},
               {"label": "Fambach", "value": "C41210"},
               {"label": "Finsterbergen", "value": "C41211"},
               {"label": "Foritz", "value": "C41212"},
               {"label": "Frankenheim", "value": "C41213"},
               {"label": "Frauenpriessnitz", "value": "C41214"},
               {"label": "Frauenwald", "value": "C41215"},
               {"label": "Freienbessingen", "value": "C41216"},
               {"label": "Friedrichroda", "value": "C41217"},
               {"label": "Friemar", "value": "C41218"},
               {"label": "Gangloffsommern", "value": "C41219"},
               {"label": "Gebesee", "value": "C41220"},
               {"label": "Gehren", "value": "C41221"},
               {"label": "Geisa", "value": "C41222"},
               {"label": "Geisleden", "value": "C41223"},
               {"label": "Geismar", "value": "C41224"},
               {"label": "Georgenthal", "value": "C41225"},
               {"label": "Gera", "value": "C41226"},
               {"label": "Geraberg", "value": "C41227"},
               {"label": "Gernrode", "value": "C41228"},
               {"label": "Gerstungen", "value": "C41229"},
               {"label": "Geschwenda", "value": "C41230"},
               {"label": "Gleichamberg", "value": "C41231"},
               {"label": "Goldbach", "value": "C41232"},
               {"label": "Gorsbach", "value": "C41233"},
               {"label": "Gossnitz", "value": "C41234"},
               {"label": "Gosswitz", "value": "C41235"},
               {"label": "Gotha", "value": "C41236"},
               {"label": "Grabsleben", "value": "C41237"},
               {"label": "Grafenhain", "value": "C41238"},
               {"label": "Grafenroda", "value": "C41239"},
               {"label": "Greiz", "value": "C41240"},
               {"label": "Greussen", "value": "C41241"},
               {"label": "Grossbartloff", "value": "C41242"},
               {"label": "Grossbodungen", "value": "C41243"},
               {"label": "Grossbreitenbach", "value": "C41244"},
               {"label": "Grossenehrich", "value": "C41245"},
               {"label": "Grossengottern", "value": "C41246"},
               {"label": "Grossenstein", "value": "C41247"},
               {"label": "Grossmonra", "value": "C41248"},
               {"label": "Grossrudestedt", "value": "C41249"},
               {"label": "Haina", "value": "C41250"},
               {"label": "Hassleben", "value": "C41251"},
               {"label": "Heilbad Heiligenstadt", "value": "C41252"},
               {"label": "Heldrungen", "value": "C41253"},
               {"label": "Hellingen", "value": "C41254"},
               {"label": "Herbsleben", "value": "C41255"},
               {"label": "Heringen", "value": "C41256"},
               {"label": "Hermsdorf", "value": "C41257"},
               {"label": "Herschdorf", "value": "C41258"},
               {"label": "Heyerode", "value": "C41259"},
               {"label": "Hildburghausen", "value": "C41260"},
               {"label": "Hohenleuben", "value": "C41261"},
               {"label": "Horselgau", "value": "C41262"},
               {"label": "Hundeshagen", "value": "C41263"},
               {"label": "Ichtershausen", "value": "C41264"},
               {"label": "Ifta", "value": "C41265"},
               {"label": "Ilfeld", "value": "C41266"},
               {"label": "Ilmenau", "value": "C41267"},
               {"label": "Immelborn", "value": "C41268"},
               {"label": "Ingersleben", "value": "C41269"},
               {"label": "Jena", "value": "C41270"},
               {"label": "Juchsen", "value": "C41271"},
               {"label": "Judenbach", "value": "C41272"},
               {"label": "Kahla", "value": "C41273"},
               {"label": "Kaltennordheim", "value": "C41274"},
               {"label": "Kaltenwestheim", "value": "C41275"},
               {"label": "Kamsdorf", "value": "C41276"},
               {"label": "Kannawurf", "value": "C41277"},
               {"label": "Katharinenberg", "value": "C41278"},
               {"label": "Katzhutte", "value": "C41279"},
               {"label": "Kaulsdorf", "value": "C41280"},
               {"label": "Kindelbruck", "value": "C41281"},
               {"label": "Kirchheim", "value": "C41282"},
               {"label": "Kirchworbis", "value": "C41283"},
               {"label": "Kleinfurra", "value": "C41284"},
               {"label": "Kleinwenden", "value": "C41285"},
               {"label": "Klettbach", "value": "C41286"},
               {"label": "Kolleda", "value": "C41287"},
               {"label": "Konigsee", "value": "C41288"},
               {"label": "Konitz", "value": "C41289"},
               {"label": "Korner", "value": "C41290"},
               {"label": "Kraftsdorf", "value": "C41291"},
               {"label": "Kranichfeld", "value": "C41292"},
               {"label": "Krauthausen", "value": "C41293"},
               {"label": "Kriebitzsch", "value": "C41294"},
               {"label": "Krolpa", "value": "C41295"},
               {"label": "Kromsdorf", "value": "C41296"},
               {"label": "Kuhndorf", "value": "C41297"},
               {"label": "Kullstedt", "value": "C41298"},
               {"label": "Langenorla", "value": "C41299"},
               {"label": "Langenwetzendorf", "value": "C41300"},
               {"label": "Langewiesen", "value": "C41301"},
               {"label": "Langula", "value": "C41302"},
               {"label": "Lauchroden", "value": "C41303"},
               {"label": "Lauscha", "value": "C41304"},
               {"label": "Lehesten", "value": "C41305"},
               {"label": "Leimbach", "value": "C41306"},
               {"label": "Leinefelde-Worbis", "value": "C41307"},
               {"label": "Leutenberg", "value": "C41308"},
               {"label": "Lichte", "value": "C41309"},
               {"label": "Lobichau", "value": "C41310"},
               {"label": "Lucka", "value": "C41311"},
               {"label": "Luisenthal", "value": "C41312"},
               {"label": "Magdala", "value": "C41313"},
               {"label": "Marksuhl", "value": "C41314"},
               {"label": "Masserberg", "value": "C41315"},
               {"label": "Mechterstadt", "value": "C41316"},
               {"label": "Meiningen", "value": "C41317"},
               {"label": "Mellenbach-Glasbach", "value": "C41318"},
               {"label": "Mellingen", "value": "C41319"},
               {"label": "Mengersgereuth-Hammern", "value": "C41320"},
               {"label": "Menteroda", "value": "C41321"},
               {"label": "Meuselbach", "value": "C41322"},
               {"label": "Meuselwitz", "value": "C41323"},
               {"label": "Mihla", "value": "C41324"},
               {"label": "Milz", "value": "C41325"},
               {"label": "Mohlsdorf", "value": "C41326"},
               {"label": "Molschleben", "value": "C41327"},
               {"label": "Monchenholzhausen", "value": "C41328"},
               {"label": "Muhlhausen", "value": "C41329"},
               {"label": "Munchenbernsdorf", "value": "C41330"},
               {"label": "Neudietendorf", "value": "C41331"},
               {"label": "Neuhaus", "value": "C41332"},
               {"label": "Neuhaus am Rennweg", "value": "C41333"},
               {"label": "Neuhaus-Schierschnitz", "value": "C41334"},
               {"label": "Neustadt am Rennsteig", "value": "C41335"},
               {"label": "Neustadt an der Orla", "value": "C41336"},
               {"label": "Niederdorla", "value": "C41337"},
               {"label": "Niederorschel", "value": "C41338"},
               {"label": "Niederrossla", "value": "C41339"},
               {"label": "Niedersachswerfen", "value": "C41340"},
               {"label": "Niederzimmern", "value": "C41341"},
               {"label": "Nobdenitz", "value": "C41342"},
               {"label": "Nobitz", "value": "C41343"},
               {"label": "Nohra", "value": "C41344"},
               {"label": "Nordhausen", "value": "C41345"},
               {"label": "Oberdorla", "value": "C41346"},
               {"label": "Oberhof", "value": "C41347"},
               {"label": "Obermassfeld-Grimmenthal", "value": "C41348"},
               {"label": "Obermehler", "value": "C41349"},
               {"label": "Oberweissbach", "value": "C41350"},
               {"label": "Oldisleben", "value": "C41351"},
               {"label": "Oppurg", "value": "C41352"},
               {"label": "Orlamunde", "value": "C41353"},
               {"label": "Ossmanstedt", "value": "C41354"},
               {"label": "Pappenheim", "value": "C41355"},
               {"label": "Plaue", "value": "C41356"},
               {"label": "Polzig", "value": "C41357"},
               {"label": "Ponitz", "value": "C41358"},
               {"label": "Possneck", "value": "C41359"},
               {"label": "Probstzella", "value": "C41360"},
               {"label": "Ranis", "value": "C41361"},
               {"label": "Rastenberg", "value": "C41362"},
               {"label": "Remptendorf", "value": "C41363"},
               {"label": "Riethnordhausen", "value": "C41364"},
               {"label": "Ringleben", "value": "C41365"},
               {"label": "Rohr", "value": "C41366"},
               {"label": "Romhild", "value": "C41367"},
               {"label": "Ronneburg", "value": "C41368"},
               {"label": "Rositz", "value": "C41369"},
               {"label": "Rossleben", "value": "C41370"},
               {"label": "Rothenstein", "value": "C41371"},
               {"label": "Rottenbach", "value": "C41372"},
               {"label": "Rudolstadt", "value": "C41373"},
               {"label": "Ruhla", "value": "C41374"},
               {"label": "Saalfeld", "value": "C41375"},
               {"label": "Saara", "value": "C41376"},
               {"label": "Sankt Gangloff", "value": "C41377"},
               {"label": "Sankt Kilian", "value": "C41378"},
               {"label": "Schalkau", "value": "C41379"},
               {"label": "Schernberg", "value": "C41380"},
               {"label": "Schkolen", "value": "C41381"},
               {"label": "Schleid", "value": "C41382"},
               {"label": "Schleiz", "value": "C41383"},
               {"label": "Schleusingen", "value": "C41384"},
               {"label": "Schlossvippach", "value": "C41385"},
               {"label": "Schlotheim", "value": "C41386"},
               {"label": "Schmalkalden", "value": "C41387"},
               {"label": "Schmiedefeld", "value": "C41388"},
               {"label": "Schmiedefeld am Rennsteig", "value": "C41389"},
               {"label": "Schmolln", "value": "C41390"},
               {"label": "Schonstedt", "value": "C41391"},
               {"label": "Schwallungen", "value": "C41392"},
               {"label": "Schwarza", "value": "C41393"},
               {"label": "Schweina", "value": "C41394"},
               {"label": "Seebach", "value": "C41395"},
               {"label": "Seebergen", "value": "C41396"},
               {"label": "Seelingstadt", "value": "C41397"},
               {"label": "Sitzendorf", "value": "C41398"},
               {"label": "Sollstedt", "value": "C41399"},
               {"label": "Sommerda", "value": "C41400"},
               {"label": "Sondershausen", "value": "C41401"},
               {"label": "Sonneberg", "value": "C41402"},
               {"label": "Sonneborn", "value": "C41403"},
               {"label": "Stadtilm", "value": "C41404"},
               {"label": "Stadtlengsfeld", "value": "C41405"},
               {"label": "Stadtroda", "value": "C41406"},
               {"label": "Steinach", "value": "C41407"},
               {"label": "Steinbach", "value": "C41408"},
               {"label": "Steinbach-Hallenberg", "value": "C41409"},
               {"label": "Steinheid", "value": "C41410"},
               {"label": "Straussfurt", "value": "C41411"},
               {"label": "Stutzerbach", "value": "C41412"},
               {"label": "Suhl", "value": "C41413"},
               {"label": "Tabarz", "value": "C41414"},
               {"label": "Tambach-Dietharz", "value": "C41415"},
               {"label": "Tanna", "value": "C41416"},
               {"label": "Tautenhain", "value": "C41417"},
               {"label": "Teichwolframsdorf", "value": "C41418"},
               {"label": "Teistungen", "value": "C41419"},
               {"label": "Thal", "value": "C41420"},
               {"label": "Themar", "value": "C41421"},
               {"label": "Tiefenort", "value": "C41422"},
               {"label": "Treffurt", "value": "C41423"},
               {"label": "Triebes", "value": "C41424"},
               {"label": "Triptis", "value": "C41425"},
               {"label": "Trusetal", "value": "C41426"},
               {"label": "Uder", "value": "C41427"},
               {"label": "Unterbreizbach", "value": "C41428"},
               {"label": "Untermassfeld", "value": "C41429"},
               {"label": "Unterwellenborn", "value": "C41430"},
               {"label": "Urbach", "value": "C41431"},
               {"label": "Uthleben", "value": "C41432"},
               {"label": "Vacha", "value": "C41433"},
               {"label": "Veilsdorf", "value": "C41434"},
               {"label": "Viernau", "value": "C41435"},
               {"label": "Voigtstedt", "value": "C41436"},
               {"label": "Volkershausen", "value": "C41437"},
               {"label": "Walldorf", "value": "C41438"},
               {"label": "Walschleben", "value": "C41439"},
               {"label": "Waltershausen", "value": "C41440"},
               {"label": "Wandersleben", "value": "C41441"},
               {"label": "Wasungen", "value": "C41442"},
               {"label": "Weida", "value": "C41443"},
               {"label": "Weimar", "value": "C41444"},
               {"label": "Weissenborn", "value": "C41445"},
               {"label": "Weissenborn-Luderode", "value": "C41446"},
               {"label": "Weissensee", "value": "C41447"},
               {"label": "Wernshausen", "value": "C41448"},
               {"label": "Wiehe", "value": "C41449"},
               {"label": "Windischleuba", "value": "C41450"},
               {"label": "Wingerode", "value": "C41451"},
               {"label": "Wintersdorf", "value": "C41452"},
               {"label": "Wipperdorf", "value": "C41453"},
               {"label": "Witterda", "value": "C41454"},
               {"label": "Wolfis", "value": "C41455"},
               {"label": "Wolkramshausen", "value": "C41456"},
               {"label": "Worbis", "value": "C41457"},
               {"label": "Wunschendorf", "value": "C41458"},
               {"label": "Wurzbach", "value": "C41459"},
               {"label": "Zella-Mehlis", "value": "C41460"},
               {"label": "Zeulenroda", "value": "C41461"}],
 "Tianjin": [{"label": "Badaogu", "value": "C19345"},
             {"label": "Baijian", "value": "C19346"},
             {"label": "Bamencheng", "value": "C19347"},
             {"label": "Bangjun", "value": "C19348"},
             {"label": "Beicang", "value": "C19349"},
             {"label": "Beihuaidian", "value": "C19350"},
             {"label": "Beilizigu", "value": "C19351"},
             {"label": "Biaokou", "value": "C19352"},
             {"label": "Binhai New Area", "value": "C19353"},
             {"label": "Caijiapu", "value": "C19354"},
             {"label": "Caodian", "value": "C19355"},
             {"label": "Chabaihu", "value": "C19356"},
             {"label": "Changtun", "value": "C19357"},
             {"label": "Chengtougu", "value": "C19358"},
             {"label": "Chitu", "value": "C19359"},
             {"label": "Cuijiamatou", "value": "C19360"},
             {"label": "Dadunqiu", "value": "C19361"},
             {"label": "Dakoutun", "value": "C19362"},
             {"label": "Dashentang", "value": "C19363"},
             {"label": "Dawangtai", "value": "C19364"},
             {"label": "Daxinzhuang", "value": "C19365"},
             {"label": "Dazhongzhuang", "value": "C19366"},
             {"label": "Dongditou", "value": "C19367"},
             {"label": "Dongshigu", "value": "C19368"},
             {"label": "Erwangzhuang", "value": "C19369"},
             {"label": "Fanzhuang", "value": "C19370"},
             {"label": "Fengtai (Ninghe)", "value": "C19371"},
             {"label": "Fuzhuang", "value": "C19372"},
             {"label": "Gaojingzhuang", "value": "C19373"},
             {"label": "Hanjiashu", "value": "C19374"},
             {"label": "Hebeitun", "value": "C19375"},
             {"label": "Hexiwu", "value": "C19376"},
             {"label": "Huangcaotuo", "value": "C19377"},
             {"label": "Huantuo", "value": "C19378"},
             {"label": "Huogezhuang", "value": "C19379"},
             {"label": "Jiangwakou", "value": "C19380"},
             {"label": "Lianzhuang", "value": "C19381"},
             {"label": "Lintingkou", "value": "C19382"},
             {"label": "Liujiading", "value": "C19383"},
             {"label": "Liukuaizhuang", "value": "C19384"},
             {"label": "Liuzikou", "value": "C19385"},
             {"label": "Luotuofangzi", "value": "C19386"},
             {"label": "Meichang", "value": "C19387"},
             {"label": "Mengquan", "value": "C19388"},
             {"label": "Panzhuang", "value": "C19389"},
             {"label": "Qingguang", "value": "C19390"},
             {"label": "Sangzi", "value": "C19391"},
             {"label": "Shangcang", "value": "C19392"},
             {"label": "Shimianzhuang", "value": "C19393"},
             {"label": "Shuangjiang", "value": "C19394"},
             {"label": "Sigaozhuang", "value": "C19395"},
             {"label": "Tianjin", "value": "C19396"},
             {"label": "Touying", "value": "C19397"},
             {"label": "Wangqinzhuang", "value": "C19398"},
             {"label": "Weiwangzhuang", "value": "C19399"},
             {"label": "Xiawuqi", "value": "C19400"},
             {"label": "Xiditou", "value": "C19401"},
             {"label": "Xinkaikou", "value": "C19402"},
             {"label": "Xitazhuang", "value": "C19403"},
             {"label": "Yangjinzhuang", "value": "C19404"},
             {"label": "Yangliuqing", "value": "C19405"},
             {"label": "Yinliu", "value": "C19406"},
             {"label": "Yixingfu", "value": "C19407"},
             {"label": "Youguzhuang", "value": "C19408"},
             {"label": "Yuelongzhuang", "value": "C19409"},
             {"label": "Yuguzhuang", "value": "C19410"},
             {"label": "Zaojiacheng", "value": "C19411"},
             {"label": "Zhangjiawo", "value": "C19412"},
             {"label": "Zhaoguli", "value": "C19413"},
             {"label": "Zhaoguli", "value": "C19414"}],
 "Tiaret": [{"label": "Ain Deheb", "value": "C464"},
            {"label": "Djebilet Rosfa", "value": "C465"},
            {"label": "Frenda", "value": "C466"},
            {"label": "Ksar Chellala", "value": "C467"},
            {"label": "Mehdia daira de meghila", "value": "C468"},
            {"label": "Sougueur", "value": "C469"},
            {"label": "Tiaret", "value": "C470"}],
 "Tibesti Region": [{"label": "Aozou", "value": "C17889"}],
 "Ticino": [{"label": "Acquarossa", "value": "C117177"},
            {"label": "Agno", "value": "C117178"},
            {"label": "Airolo", "value": "C117179"},
            {"label": "Arzo", "value": "C117180"},
            {"label": "Ascona", "value": "C117181"},
            {"label": "Balerna", "value": "C117182"},
            {"label": "Bellinzona", "value": "C117183"},
            {"label": "Bellinzona District", "value": "C117184"},
            {"label": "Biasca", "value": "C117185"},
            {"label": "Bioggio", "value": "C117186"},
            {"label": "Blenio District", "value": "C117187"},
            {"label": "Bodio", "value": "C117188"},
            {"label": "Brissago", "value": "C117189"},
            {"label": "Cadempino", "value": "C117190"},
            {"label": "Cadenazzo", "value": "C117191"},
            {"label": "Cadro", "value": "C117192"},
            {"label": "Camorino", "value": "C117193"},
            {"label": "Canobbio", "value": "C117194"},
            {"label": "Caslano", "value": "C117195"},
            {"label": "Castel San Pietro", "value": "C117196"},
            {"label": "Cevio", "value": "C117197"},
            {"label": "Chiasso", "value": "C117198"},
            {"label": "Claro", "value": "C117199"},
            {"label": "Comano", "value": "C117200"},
            {"label": "Cugnasco", "value": "C117201"},
            {"label": "Faido", "value": "C117202"},
            {"label": "Giubiasco", "value": "C117203"},
            {"label": "Gordola", "value": "C117204"},
            {"label": "Gravesano", "value": "C117205"},
            {"label": "Lavertezzo", "value": "C117206"},
            {"label": "Leventina District", "value": "C117207"},
            {"label": "Ligornetto", "value": "C117208"},
            {"label": "Locarno", "value": "C117209"},
            {"label": "Locarno District", "value": "C117210"},
            {"label": "Lodrino", "value": "C117211"},
            {"label": "Losone", "value": "C117212"},
            {"label": "Lugano", "value": "C117213"},
            {"label": "Lugano District", "value": "C117214"},
            {"label": "Lumino", "value": "C117215"},
            {"label": "Magadino", "value": "C117216"},
            {"label": "Malvaglia", "value": "C117217"},
            {"label": "Massagno", "value": "C117218"},
            {"label": "Melano", "value": "C117219"},
            {"label": "Melide", "value": "C117220"},
            {"label": "Mendrisio", "value": "C117221"},
            {"label": "Mendrisio District", "value": "C117222"},
            {"label": "Minusio", "value": "C117223"},
            {"label": "Montagnola", "value": "C117224"},
            {"label": "Monte Carasso", "value": "C117225"},
            {"label": "Morbio Inferiore", "value": "C117226"},
            {"label": "Muralto", "value": "C117227"},
            {"label": "Novazzano", "value": "C117228"},
            {"label": "Pregassona", "value": "C117229"},
            {"label": "Pura", "value": "C117230"},
            {"label": "Riva San Vitale", "value": "C117231"},
            {"label": "Riviera District", "value": "C117232"},
            {"label": "Sementina", "value": "C117233"},
            {"label": "Sorengo", "value": "C117234"},
            {"label": "Stabio", "value": "C117235"},
            {"label": "Tesserete", "value": "C117236"},
            {"label": "Vallemaggia District", "value": "C117237"},
            {"label": "Verscio", "value": "C117238"},
            {"label": "Viganello", "value": "C117239"}],
 "Tien Giang": [{"label": "Huyen Cai Be", "value": "C147684"},
                {"label": "Huyen Cai Lay", "value": "C147685"},
                {"label": "Huyen Chau Thanh", "value": "C147686"},
                {"label": "Huyen Cho Gao", "value": "C147687"},
                {"label": "Huyen Go Cong Dong", "value": "C147688"},
                {"label": "Huyen Go Cong Tay", "value": "C147689"},
                {"label": "Huyen Tan Phuoc", "value": "C147690"},
                {"label": "My Tho", "value": "C147691"},
                {"label": "Thanh Pho My Tho", "value": "C147692"},
                {"label": "Thi Xa Go Cong", "value": "C147693"}],
 "Tierra del Fuego": [{"label": "Rio Grande", "value": "C1517"},
                      {"label": "Tolhuin", "value": "C1518"},
                      {"label": "Ushuaia", "value": "C1519"}],
 "Tigray Region": [{"label": "adigrat", "value": "C24797"},
                   {"label": "Axum", "value": "C24798"},
                   {"label": "Inda Silase", "value": "C24799"},
                   {"label": "Korem", "value": "C24800"},
                   {"label": "Maychew", "value": "C24801"},
                   {"label": "Mekele", "value": "C24802"},
                   {"label": "Southeastern Tigray Zone", "value": "C24803"},
                   {"label": "Southern Tigray Zone", "value": "C24804"}],
 "Tillaberi Region": [{"label": "Ayorou", "value": "C78089"},
                      {"label": "Balleyara", "value": "C78090"},
                      {"label": "Departement de Filingue", "value": "C78091"},
                      {"label": "Departement de Ouallam", "value": "C78092"},
                      {"label": "Departement de Say", "value": "C78093"},
                      {"label": "Filingue", "value": "C78094"},
                      {"label": "Kollo", "value": "C78095"},
                      {"label": "Kollo Department", "value": "C78096"},
                      {"label": "Ouallam", "value": "C78097"},
                      {"label": "Say", "value": "C78098"},
                      {"label": "Tera", "value": "C78099"},
                      {"label": "Tera Department", "value": "C78100"},
                      {"label": "Tillaberi", "value": "C78101"},
                      {"label": "Tillaberi Department", "value": "C78102"}],
 "Timis County": [{"label": "Bacova", "value": "C99253"},
                  {"label": "Balint", "value": "C99254"},
                  {"label": "Banloc", "value": "C99255"},
                  {"label": "Bara", "value": "C99256"},
                  {"label": "Barna", "value": "C99257"},
                  {"label": "Beba Veche", "value": "C99258"},
                  {"label": "Becicherecu Mic", "value": "C99259"},
                  {"label": "Belint", "value": "C99260"},
                  {"label": "Beregsau Mare", "value": "C99261"},
                  {"label": "Bethausen", "value": "C99262"},
                  {"label": "Biled", "value": "C99263"},
                  {"label": "Birda", "value": "C99264"},
                  {"label": "Bogda", "value": "C99265"},
                  {"label": "Boldur", "value": "C99266"},
                  {"label": "Brestovat", "value": "C99267"},
                  {"label": "Bucovat", "value": "C99268"},
                  {"label": "Bulgarus", "value": "C99269"},
                  {"label": "Buzias", "value": "C99270"},
                  {"label": "Carani", "value": "C99271"},
                  {"label": "Carpinis", "value": "C99272"},
                  {"label": "Cenad", "value": "C99273"},
                  {"label": "Cenei", "value": "C99274"},
                  {"label": "Checea", "value": "C99275"},
                  {"label": "Cheveresu Mare", "value": "C99276"},
                  {"label": "Chisoda", "value": "C99277"},
                  {"label": "Ciacova", "value": "C99278"},
                  {"label": "Colonia Fabricii", "value": "C99279"},
                  {"label": "Comlosu Mare", "value": "C99280"},
                  {"label": "Comuna Balint", "value": "C99281"},
                  {"label": "Comuna Banloc", "value": "C99282"},
                  {"label": "Comuna Bara", "value": "C99283"},
                  {"label": "Comuna Barna", "value": "C99284"},
                  {"label": "Comuna Beba Veche", "value": "C99285"},
                  {"label": "Comuna Becicherecu Mic", "value": "C99286"},
                  {"label": "Comuna Belint", "value": "C99287"},
                  {"label": "Comuna Bethausen", "value": "C99288"},
                  {"label": "Comuna Biled", "value": "C99289"},
                  {"label": "Comuna Birda", "value": "C99290"},
                  {"label": "Comuna Bogda", "value": "C99291"},
                  {"label": "Comuna Boldur", "value": "C99292"},
                  {"label": "Comuna Brestovat", "value": "C99293"},
                  {"label": "Comuna Bucovat", "value": "C99294"},
                  {"label": "Comuna Carpinis", "value": "C99295"},
                  {"label": "Comuna Cenad", "value": "C99296"},
                  {"label": "Comuna Cenei", "value": "C99297"},
                  {"label": "Comuna Checea", "value": "C99298"},
                  {"label": "Comuna Cheveresu Mare", "value": "C99299"},
                  {"label": "Comuna Comlosu Mare", "value": "C99300"},
                  {"label": "Comuna Costeiu", "value": "C99301"},
                  {"label": "Comuna Criciova", "value": "C99302"},
                  {"label": "Comuna Curtea", "value": "C99303"},
                  {"label": "Comuna Darova", "value": "C99304"},
                  {"label": "Comuna Denta", "value": "C99305"},
                  {"label": "Comuna Dudestii Noi", "value": "C99306"},
                  {"label": "Comuna Dudestii Vechi", "value": "C99307"},
                  {"label": "Comuna Dumbrava", "value": "C99308"},
                  {"label": "Comuna Dumbravita", "value": "C99309"},
                  {"label": "Comuna Fardea", "value": "C99310"},
                  {"label": "Comuna Fibis", "value": "C99311"},
                  {"label": "Comuna Foeni", "value": "C99312"},
                  {"label": "Comuna Gavojdia", "value": "C99313"},
                  {"label": "Comuna Ghilad", "value": "C99314"},
                  {"label": "Comuna Ghiroda", "value": "C99315"},
                  {"label": "Comuna Ghizela", "value": "C99316"},
                  {"label": "Comuna Giarmata", "value": "C99317"},
                  {"label": "Comuna Giera", "value": "C99318"},
                  {"label": "Comuna Giroc", "value": "C99319"},
                  {"label": "Comuna Giulvaz", "value": "C99320"},
                  {"label": "Comuna Gottlob", "value": "C99321"},
                  {"label": "Comuna Iecea Mare", "value": "C99322"},
                  {"label": "Comuna Jamu Mare", "value": "C99323"},
                  {"label": "Comuna Jebel", "value": "C99324"},
                  {"label": "Comuna Lenauheim", "value": "C99325"},
                  {"label": "Comuna Liebling", "value": "C99326"},
                  {"label": "Comuna Livezile", "value": "C99327"},
                  {"label": "Comuna Lovrin", "value": "C99328"},
                  {"label": "Comuna Manastiur", "value": "C99329"},
                  {"label": "Comuna Margina", "value": "C99330"},
                  {"label": "Comuna Masloc", "value": "C99331"},
                  {"label": "Comuna Moravita", "value": "C99332"},
                  {"label": "Comuna Mosnita Noua", "value": "C99333"},
                  {"label": "Comuna Nadrag", "value": "C99334"},
                  {"label": "Comuna Nitchidorf", "value": "C99335"},
                  {"label": "Comuna Ohaba Lunga", "value": "C99336"},
                  {"label": "Comuna Ortisoara", "value": "C99337"},
                  {"label": "Comuna Otelec", "value": "C99338"},
                  {"label": "Comuna Padureni", "value": "C99339"},
                  {"label": "Comuna Parta", "value": "C99340"},
                  {"label": "Comuna Peciu Nou", "value": "C99341"},
                  {"label": "Comuna Periam", "value": "C99342"},
                  {"label": "Comuna Pesac", "value": "C99343"},
                  {"label": "Comuna Pietroasa", "value": "C99344"},
                  {"label": "Comuna Pischia", "value": "C99345"},
                  {"label": "Comuna Racovita", "value": "C99346"},
                  {"label": "Comuna Remetea Mare", "value": "C99347"},
                  {"label": "Comuna Sacalaz", "value": "C99348"},
                  {"label": "Comuna Sacosu Turcesc", "value": "C99349"},
                  {"label": "Comuna Sag", "value": "C99350"},
                  {"label": "Comuna Sanandrei", "value": "C99351"},
                  {"label": "Comuna Sandra", "value": "C99352"},
                  {"label": "Comuna Sanmihaiu Roman", "value": "C99353"},
                  {"label": "Comuna Sanpetru Mare", "value": "C99354"},
                  {"label": "Comuna Saravale", "value": "C99355"},
                  {"label": "Comuna Satchinez", "value": "C99356"},
                  {"label": "Comuna Secas", "value": "C99357"},
                  {"label": "Comuna Stiuca", "value": "C99358"},
                  {"label": "Comuna Teremia Mare", "value": "C99359"},
                  {"label": "Comuna Tomesti", "value": "C99360"},
                  {"label": "Comuna Tomnatic", "value": "C99361"},
                  {"label": "Comuna Topolovatu Mare", "value": "C99362"},
                  {"label": "Comuna Tormac", "value": "C99363"},
                  {"label": "Comuna Traian Vuia", "value": "C99364"},
                  {"label": "Comuna Uivar", "value": "C99365"},
                  {"label": "Comuna Valcani", "value": "C99366"},
                  {"label": "Comuna Varias", "value": "C99367"},
                  {"label": "Comuna Victor Vlad Delamarina", "value": "C99368"},
                  {"label": "Comuna Voiteg", "value": "C99369"},
                  {"label": "Costeiu", "value": "C99370"},
                  {"label": "Criciova", "value": "C99371"},
                  {"label": "Curtea", "value": "C99372"},
                  {"label": "Darova", "value": "C99373"},
                  {"label": "Darova Noua", "value": "C99374"},
                  {"label": "Denta", "value": "C99375"},
                  {"label": "Deta", "value": "C99376"},
                  {"label": "Dudestii Noi", "value": "C99377"},
                  {"label": "Dudestii Vechi", "value": "C99378"},
                  {"label": "Dumbrava", "value": "C99379"},
                  {"label": "Dumbravita", "value": "C99380"},
                  {"label": "Faget", "value": "C99381"},
                  {"label": "Fardea", "value": "C99382"},
                  {"label": "Fibis", "value": "C99383"},
                  {"label": "Foeni", "value": "C99384"},
                  {"label": "Gataia", "value": "C99385"},
                  {"label": "Gavojdia", "value": "C99386"},
                  {"label": "Gelu", "value": "C99387"},
                  {"label": "Ghilad", "value": "C99388"},
                  {"label": "Ghiroda", "value": "C99389"},
                  {"label": "Ghizela", "value": "C99390"},
                  {"label": "Giarmata", "value": "C99391"},
                  {"label": "Giarmata-Vii", "value": "C99392"},
                  {"label": "Giera", "value": "C99393"},
                  {"label": "Giroc", "value": "C99394"},
                  {"label": "Giulvaz", "value": "C99395"},
                  {"label": "Gottlob", "value": "C99396"},
                  {"label": "Grabat", "value": "C99397"},
                  {"label": "Hodoni", "value": "C99398"},
                  {"label": "Iecea Mare", "value": "C99399"},
                  {"label": "Iecea Mica", "value": "C99400"},
                  {"label": "Igris", "value": "C99401"},
                  {"label": "Izvin", "value": "C99402"},
                  {"label": "Jamu Mare", "value": "C99403"},
                  {"label": "Jebel", "value": "C99404"},
                  {"label": "Jimbolia", "value": "C99405"},
                  {"label": "Lenauheim", "value": "C99406"},
                  {"label": "Liebling", "value": "C99407"},
                  {"label": "Livezile", "value": "C99408"},
                  {"label": "Lovrin", "value": "C99409"},
                  {"label": "Lugoj", "value": "C99410"},
                  {"label": "Manastiur", "value": "C99411"},
                  {"label": "Margina", "value": "C99412"},
                  {"label": "Masloc", "value": "C99413"},
                  {"label": "Moravita", "value": "C99414"},
                  {"label": "Mosnita Noua", "value": "C99415"},
                  {"label": "Mosnita Veche", "value": "C99416"},
                  {"label": "Municipiul Lugoj", "value": "C99417"},
                  {"label": "Municipiul Timisoara", "value": "C99418"},
                  {"label": "Nadrag", "value": "C99419"},
                  {"label": "Nerau", "value": "C99420"},
                  {"label": "Nitchidorf", "value": "C99421"},
                  {"label": "Ohaba Lunga", "value": "C99422"},
                  {"label": "Oras Buzias", "value": "C99423"},
                  {"label": "Oras Ciacova", "value": "C99424"},
                  {"label": "Oras Deta", "value": "C99425"},
                  {"label": "Oras Faget", "value": "C99426"},
                  {"label": "Oras Gataia", "value": "C99427"},
                  {"label": "Oras Jimbolia", "value": "C99428"},
                  {"label": "Oras Recas", "value": "C99429"},
                  {"label": "Oras Sannicolau Mare", "value": "C99430"},
                  {"label": "Ortisoara", "value": "C99431"},
                  {"label": "Otelec", "value": "C99432"},
                  {"label": "Padureni", "value": "C99433"},
                  {"label": "Parta", "value": "C99434"},
                  {"label": "Peciu Nou", "value": "C99435"},
                  {"label": "Periam", "value": "C99436"},
                  {"label": "Pesac", "value": "C99437"},
                  {"label": "Pietroasa", "value": "C99438"},
                  {"label": "Pischia", "value": "C99439"},
                  {"label": "Rachita", "value": "C99440"},
                  {"label": "Racovita", "value": "C99441"},
                  {"label": "Recas", "value": "C99442"},
                  {"label": "Remetea Mare", "value": "C99443"},
                  {"label": "Sacalaz", "value": "C99444"},
                  {"label": "Sacosu Mare", "value": "C99445"},
                  {"label": "Sacosu Turcesc", "value": "C99446"},
                  {"label": "Sag", "value": "C99447"},
                  {"label": "Sanandrei", "value": "C99448"},
                  {"label": "Sandra", "value": "C99449"},
                  {"label": "Sanmihaiu Roman", "value": "C99450"},
                  {"label": "Sannicolau Mare", "value": "C99451"},
                  {"label": "Sanpetru Mare", "value": "C99452"},
                  {"label": "Saravale", "value": "C99453"},
                  {"label": "Satchinez", "value": "C99454"},
                  {"label": "Sculia", "value": "C99455"},
                  {"label": "Secas", "value": "C99456"},
                  {"label": "Stiuca", "value": "C99457"},
                  {"label": "Teremia Mare", "value": "C99458"},
                  {"label": "Timisoara", "value": "C99459"},
                  {"label": "Tomesti", "value": "C99460"},
                  {"label": "Tomnatic", "value": "C99461"},
                  {"label": "Topolovatu Mare", "value": "C99462"},
                  {"label": "Tormac", "value": "C99463"},
                  {"label": "Traian Vuia", "value": "C99464"},
                  {"label": "Uivar", "value": "C99465"},
                  {"label": "Urseni", "value": "C99466"},
                  {"label": "Utvin", "value": "C99467"},
                  {"label": "Valcani", "value": "C99468"},
                  {"label": "Varias", "value": "C99469"},
                  {"label": "Victor Vlad Delamarina", "value": "C99470"},
                  {"label": "Voiteg", "value": "C99471"}],
 "Tindouf": [{"label": "Tindouf", "value": "C471"}],
 "Tipasa": [{"label": "Ain Benian", "value": "C472"},
            {"label": "Baraki", "value": "C473"},
            {"label": "Bou Ismail", "value": "C474"},
            {"label": "Cheraga", "value": "C475"},
            {"label": "Douera", "value": "C476"},
            {"label": "El Affroun", "value": "C477"},
            {"label": "Hadjout", "value": "C478"},
            {"label": "Kolea", "value": "C479"},
            {"label": "Mouzaia", "value": "C480"},
            {"label": "Oued el Alleug", "value": "C481"},
            {"label": "Saoula", "value": "C482"},
            {"label": "Tipasa", "value": "C483"},
            {"label": "Zeralda", "value": "C484"}],
 "Tirana District": [{"label": "Bashkia Kavaje", "value": "C198"},
                     {"label": "Bashkia Vore", "value": "C199"},
                     {"label": "Kamez", "value": "C200"},
                     {"label": "Kavaje", "value": "C201"},
                     {"label": "Krrabe", "value": "C202"},
                     {"label": "Rrethi i Kavajes", "value": "C203"},
                     {"label": "Rrethi i Tiranes", "value": "C204"},
                     {"label": "Rrogozhine", "value": "C205"},
                     {"label": "Sinaballaj", "value": "C206"},
                     {"label": "Tirana", "value": "C207"},
                     {"label": "Vore", "value": "C208"}],
 "Tiris Zemmour": [{"label": "Ain Ben Tili", "value": "C65968"},
                   {"label": "Chegga", "value": "C65969"},
                   {"label": "Fderick", "value": "C65970"},
                   {"label": "Zouerat", "value": "C65971"},
                   {"label": "Zouerate", "value": "C65972"}],
 "Tisina Municipality": [{"label": "Tisina", "value": "C107837"}],
 "Tissemsilt": [{"label": "Lardjem", "value": "C485"},
                {"label": "Tissemsilt", "value": "C486"}],
 "Tivat Municipality": [{"label": "Tivat", "value": "C75625"}],
 "Tizi Ouzou": [{"label": "Ain el Hammam", "value": "C487"},
                {"label": "Arhribs", "value": "C488"},
                {"label": "Azazga", "value": "C489"},
                {"label": "Beni Douala", "value": "C490"},
                {"label": "Boghni", "value": "C491"},
                {"label": "Boudjima", "value": "C492"},
                {"label": "Chemini", "value": "C493"},
                {"label": "Draa Ben Khedda", "value": "C494"},
                {"label": "Freha", "value": "C495"},
                {"label": "Ighram", "value": "C496"},
                {"label": "LArbaa Nait Irathen", "value": "C497"},
                {"label": "Mekla", "value": "C498"},
                {"label": "Timizart", "value": "C499"},
                {"label": "Tirmitine", "value": "C500"},
                {"label": "Tizi Ouzou", "value": "C501"},
                {"label": "Tizi Rached", "value": "C502"},
                {"label": "Tizi-n-Tleta", "value": "C503"}],
 "Tlaxcala": [{"label": "Acopinalco del Penon", "value": "C74110"},
              {"label": "Acuamanala", "value": "C74111"},
              {"label": "Acuitlapilco", "value": "C74112"},
              {"label": "Acxotla del Monte", "value": "C74113"},
              {"label": "Acxotla del Rio", "value": "C74114"},
              {"label": "Altzayanca", "value": "C74115"},
              {"label": "Amaxac de Guerrero", "value": "C74116"},
              {"label": "Apetatitlan Antonio Carbajal", "value": "C74117"},
              {"label": "Apizaco", "value": "C74118"},
              {"label": "Atexcatzingo", "value": "C74119"},
              {"label": "Atlangatepec", "value": "C74120"},
              {"label": "Atlzayanca", "value": "C74121"},
              {"label": "Ayometitla", "value": "C74122"},
              {"label": "Belen Atzitzimititlan", "value": "C74123"},
              {"label": "Benito Juarez", "value": "C74124"},
              {"label": "Calpulalpan", "value": "C74125"},
              {"label": "Capula", "value": "C74126"},
              {"label": "Ciudad de Nanacamilpa", "value": "C74127"},
              {"label": "Colonia Cuauhtemoc", "value": "C74128"},
              {"label": "Colonia Ignacio Allende", "value": "C74129"},
              {"label": "Colonia San Isidro", "value": "C74130"},
              {"label": "Colonia Venustiano Carranza", "value": "C74131"},
              {"label": "Concepcion Chimalpa", "value": "C74132"},
              {"label": "Concepcion Hidalgo", "value": "C74133"},
              {"label": "Contla", "value": "C74134"},
              {"label": "Cuapiaxtla", "value": "C74135"},
              {"label": "Cuaxomulco", "value": "C74136"},
              {"label": "El Carmen Aztama", "value": "C74137"},
              {"label": "El Carmen Xalpatlahuaya", "value": "C74138"},
              {"label": "El Rosario", "value": "C74139"},
              {"label": "Emiliano Zapata", "value": "C74140"},
              {"label": "Felipe Carrillo Puerto", "value": "C74141"},
              {"label": "Fraccionamiento la Virgen", "value": "C74142"},
              {"label": "Francisco I. Madero", "value": "C74143"},
              {"label": "Francisco Villa", "value": "C74144"},
              {"label": "Guadalupe Hidalgo", "value": "C74145"},
              {"label": "Guadalupe Texcalac", "value": "C74146"},
              {"label": "Guadalupe Tlachco", "value": "C74147"},
              {"label": "Hualcaltzinco", "value": "C74148"},
              {"label": "Huamantla", "value": "C74149"},
              {"label": "Hueyotlipan", "value": "C74150"},
              {"label": "Huiloapan", "value": "C74151"},
              {"label": "Ignacio Zaragoza", "value": "C74152"},
              {"label": "Jesus Huitznahuac", "value": "C74153"},
              {"label": "Jesus Tepactepec", "value": "C74154"},
              {"label": "Jose Maria Morelos", "value": "C74155"},
              {"label": "La Aurora", "value": "C74156"},
              {"label": "La Candelaria Teotlalpan", "value": "C74157"},
              {"label": "La Magdalena Tlaltelulco", "value": "C74158"},
              {"label": "La Soledad", "value": "C74159"},
              {"label": "La Trinidad Chimalpa", "value": "C74160"},
              {"label": "La Trinidad Tenexyecac", "value": "C74161"},
              {"label": "Lagunilla", "value": "C74162"},
              {"label": "Lazaro Cardenas", "value": "C74163"},
              {"label": "Los Pilares", "value": "C74164"},
              {"label": "Los Reyes Quiahuixtlan", "value": "C74165"},
              {"label": "Mazapa", "value": "C74166"},
              {"label": "Mazatecochco", "value": "C74167"},
              {"label": "Munoz", "value": "C74168"},
              {"label": "Nativitas", "value": "C74169"},
              {"label": "Nicolas Bravo", "value": "C74170"},
              {"label": "Panotla", "value": "C74171"},
              {"label": "Papalotla", "value": "C74172"},
              {"label": "Rancheria de Pocitos", "value": "C74173"},
              {"label": "San Andres Ahuashuatepec", "value": "C74174"},
              {"label": "San Bartolome Cuahuixmatlac", "value": "C74175"},
              {"label": "San Bartolome Tenango", "value": "C74176"},
              {"label": "San Benito Xaltocan", "value": "C74177"},
              {"label": "San Buenaventura Atempan", "value": "C74178"},
              {"label": "San Cosme Atlamaxac", "value": "C74179"},
              {"label": "San Damian Texoloc", "value": "C74180"},
              {"label": "San Esteban Tizatlan", "value": "C74181"},
              {"label": "San Felipe Sultepec", "value": "C74182"},
              {"label": "San Francisco Cuexcontzi", "value": "C74183"},
              {"label": "San Francisco Temetzontla", "value": "C74184"},
              {"label": "San Francisco Tlacuilohcan", "value": "C74185"},
              {"label": "San Hipolito Chimalpa", "value": "C74186"},
              {"label": "San Isidro Buen Suceso", "value": "C74187"},
              {"label": "San Jeronimo Zacualpan", "value": "C74188"},
              {"label": "San Jorge Tezoquipan", "value": "C74189"},
              {"label": "San Jose Atoyatenco", "value": "C74190"},
              {"label": "San Jose Aztatla", "value": "C74191"},
              {"label": "San Jose Cuamantzingo", "value": "C74192"},
              {"label": "San Jose Teacalco", "value": "C74193"},
              {"label": "San Jose Tepeyahualco", "value": "C74194"},
              {"label": "San Jose Tetel", "value": "C74195"},
              {"label": "San Jose Villarreal", "value": "C74196"},
              {"label": "San Jose Xicohtencatl", "value": "C74197"},
              {"label": "San Juan Quetzalcoapan", "value": "C74198"},
              {"label": "San Lorenzo Axocomanitla", "value": "C74199"},
              {"label": "San Lorenzo Soltepec", "value": "C74200"},
              {"label": "San Lorenzo Xaltelulco", "value": "C74201"},
              {"label": "San Lucas Tecopilco", "value": "C74202"},
              {"label": "San Lucas Tlacochcalco", "value": "C74203"},
              {"label": "San Luis Apizaquito", "value": "C74204"},
              {"label": "San Marcos Contla", "value": "C74205"},
              {"label": "San Matias Tepetomatitlan", "value": "C74206"},
              {"label": "San Miguel Contla", "value": "C74207"},
              {"label": "San Miguel Tlamahuco", "value": "C74208"},
              {"label": "San Miguel Xochitecatitla", "value": "C74209"},
              {"label": "San Pedro Ecatepec", "value": "C74210"},
              {"label": "San Pedro Munoztla", "value": "C74211"},
              {"label": "San Pedro Tlacotepec", "value": "C74212"},
              {"label": "San Pedro Xalcaltzinco", "value": "C74213"},
              {"label": "San Pedro Xochiteotla", "value": "C74214"},
              {"label": "San Rafael Tenanyecac", "value": "C74215"},
              {"label": "San Rafael Tepatlaxco", "value": "C74216"},
              {"label": "San Simeon Xipetzingo", "value": "C74217"},
              {"label": "San Simon Tlatlahuquitepec", "value": "C74218"},
              {"label": "Sanctorum", "value": "C74219"},
              {"label": "Santa Ana Chiautempan", "value": "C74220"},
              {"label": "Santa Anita Huiloac", "value": "C74221"},
              {"label": "Santa Apolonia Teacalco", "value": "C74222"},
              {"label": "Santa Catarina Ayometla", "value": "C74223"},
              {"label": "Santa Cruz Aquiahuac", "value": "C74224"},
              {"label": "Santa Cruz Pocitos", "value": "C74225"},
              {"label": "Santa Cruz Tetela", "value": "C74226"},
              {"label": "Santa Isabel Xiloxoxtla", "value": "C74227"},
              {"label": "Santa Justina Ecatepec", "value": "C74228"},
              {"label": "Santa Maria Atlihuetzian", "value": "C74229"},
              {"label": "Santa Maria Ixtulco", "value": "C74230"},
              {"label": "Santa Maria Texcalac", "value": "C74231"},
              {"label": "Santiago Michac", "value": "C74232"},
              {"label": "Santiago Tepeticpac", "value": "C74233"},
              {"label": "Santiago Tlacochcalco", "value": "C74234"},
              {"label": "Santo Tomas la Concordia", "value": "C74235"},
              {"label": "Tenancingo", "value": "C74236"},
              {"label": "Teolocholco", "value": "C74237"},
              {"label": "Tepetitla", "value": "C74238"},
              {"label": "Tepeyanco", "value": "C74239"},
              {"label": "Terrenate", "value": "C74240"},
              {"label": "Tetla", "value": "C74241"},
              {"label": "Tetlanohcan", "value": "C74242"},
              {"label": "Tlatempan", "value": "C74243"},
              {"label": "Tlaxcala", "value": "C74244"},
              {"label": "Tlaxco", "value": "C74245"},
              {"label": "Tocatlan", "value": "C74246"},
              {"label": "Toluca de Guadalupe", "value": "C74247"},
              {"label": "Topilco de Juarez", "value": "C74248"},
              {"label": "Totolac", "value": "C74249"},
              {"label": "Tzompantepec", "value": "C74250"},
              {"label": "Union Ejidal Tierra y Libertad", "value": "C74251"},
              {"label": "Villa Alta", "value": "C74252"},
              {"label": "Villa de El Carmen Tequexquitla", "value": "C74253"},
              {"label": "Villa Vicente Guerrero", "value": "C74254"},
              {"label": "Xaltocan", "value": "C74255"},
              {"label": "Xicohtzinco", "value": "C74256"},
              {"label": "Xocoyucan", "value": "C74257"},
              {"label": "Yauhquemehcan", "value": "C74258"},
              {"label": "Zacatelco", "value": "C74259"},
              {"label": "Zaragoza", "value": "C74260"},
              {"label": "Zimatepec", "value": "C74261"},
              {"label": "Zitlaltepec", "value": "C74262"},
              {"label": "Zumpango", "value": "C74263"}],
 "Tlemcen": [{"label": "Beni Mester", "value": "C504"},
             {"label": "Bensekrane", "value": "C505"},
             {"label": "Chetouane", "value": "C506"},
             {"label": "Hennaya", "value": "C507"},
             {"label": "Mansoura", "value": "C508"},
             {"label": "Nedroma", "value": "C509"},
             {"label": "Ouled Mimoun", "value": "C510"},
             {"label": "Remchi", "value": "C511"},
             {"label": "Sebdou", "value": "C512"},
             {"label": "Sidi Abdelli", "value": "C513"},
             {"label": "Sidi Senoussi sydy snwsy", "value": "C514"},
             {"label": "Tlemcen", "value": "C515"}],
 "Tocantins": [{"label": "Abreulandia", "value": "C15698"},
               {"label": "Aguiarnopolis", "value": "C15699"},
               {"label": "Alianca do Tocantins", "value": "C15700"},
               {"label": "Almas", "value": "C15701"},
               {"label": "Alvorada", "value": "C15702"},
               {"label": "Ananas", "value": "C15703"},
               {"label": "Angico", "value": "C15704"},
               {"label": "Aparecida do Rio Negro", "value": "C15705"},
               {"label": "Aragominas", "value": "C15706"},
               {"label": "Araguacema", "value": "C15707"},
               {"label": "Araguacu", "value": "C15708"},
               {"label": "Araguaina", "value": "C15709"},
               {"label": "Araguana", "value": "C15710"},
               {"label": "Araguatins", "value": "C15711"},
               {"label": "Arapoema", "value": "C15712"},
               {"label": "Arraias", "value": "C15713"},
               {"label": "Augustinopolis", "value": "C15714"},
               {"label": "Aurora do Tocantins", "value": "C15715"},
               {"label": "Axixa do Tocantins", "value": "C15716"},
               {"label": "Babaculandia", "value": "C15717"},
               {"label": "Bandeirantes do Tocantins", "value": "C15718"},
               {"label": "Barra do Ouro", "value": "C15719"},
               {"label": "Barrolandia", "value": "C15720"},
               {"label": "Bernardo Sayao", "value": "C15721"},
               {"label": "Bom Jesus do Tocantins", "value": "C15722"},
               {"label": "Brasilandia do Tocantins", "value": "C15723"},
               {"label": "Brejinho de Nazare", "value": "C15724"},
               {"label": "Buriti do Tocantins", "value": "C15725"},
               {"label": "Cachoeirinha", "value": "C15726"},
               {"label": "Campos Lindos", "value": "C15727"},
               {"label": "Cariri do Tocantins", "value": "C15728"},
               {"label": "Carmolandia", "value": "C15729"},
               {"label": "Carrasco Bonito", "value": "C15730"},
               {"label": "Caseara", "value": "C15731"},
               {"label": "Centenario", "value": "C15732"},
               {"label": "Chapada da Natividade", "value": "C15733"},
               {"label": "Chapada de Areia", "value": "C15734"},
               {"label": "Colinas do Tocantins", "value": "C15735"},
               {"label": "Colmeia", "value": "C15736"},
               {"label": "Combinado", "value": "C15737"},
               {"label": "Conceicao do Tocantins", "value": "C15738"},
               {"label": "Couto Magalhaes", "value": "C15739"},
               {"label": "Cristalandia", "value": "C15740"},
               {"label": "Crixas do Tocantins", "value": "C15741"},
               {"label": "Darcinopolis", "value": "C15742"},
               {"label": "Dianopolis", "value": "C15743"},
               {"label": "Divinopolis do Tocantins", "value": "C15744"},
               {"label": "Dois Irmaos do Tocantins", "value": "C15745"},
               {"label": "Duere", "value": "C15746"},
               {"label": "Esperantina", "value": "C15747"},
               {"label": "Fatima", "value": "C15748"},
               {"label": "Figueiropolis", "value": "C15749"},
               {"label": "Filadelfia", "value": "C15750"},
               {"label": "Formoso do Araguaia", "value": "C15751"},
               {"label": "Fortaleza do Tabocao", "value": "C15752"},
               {"label": "Goianorte", "value": "C15753"},
               {"label": "Goiatins", "value": "C15754"},
               {"label": "Guarai", "value": "C15755"},
               {"label": "Gurupi", "value": "C15756"},
               {"label": "Ipueiras", "value": "C15757"},
               {"label": "Itacaja", "value": "C15758"},
               {"label": "Itaguatins", "value": "C15759"},
               {"label": "Itapiratins", "value": "C15760"},
               {"label": "Itapora do Tocantins", "value": "C15761"},
               {"label": "Jau do Tocantins", "value": "C15762"},
               {"label": "Juarina", "value": "C15763"},
               {"label": "Lagoa da Confusao", "value": "C15764"},
               {"label": "Lagoa do Tocantins", "value": "C15765"},
               {"label": "Lajeado", "value": "C15766"},
               {"label": "Lavandeira", "value": "C15767"},
               {"label": "Lizarda", "value": "C15768"},
               {"label": "Luzinopolis", "value": "C15769"},
               {"label": "Marianopolis do Tocantins", "value": "C15770"},
               {"label": "Mateiros", "value": "C15771"},
               {"label": "Maurilandia do Tocantins", "value": "C15772"},
               {"label": "Miracema do Tocantins", "value": "C15773"},
               {"label": "Miranorte", "value": "C15774"},
               {"label": "Monte do Carmo", "value": "C15775"},
               {"label": "Monte Santo do Tocantins", "value": "C15776"},
               {"label": "Muricilandia", "value": "C15777"},
               {"label": "Natividade", "value": "C15778"},
               {"label": "Nazare", "value": "C15779"},
               {"label": "Nova Olinda", "value": "C15780"},
               {"label": "Nova Rosalandia", "value": "C15781"},
               {"label": "Novo Acordo", "value": "C15782"},
               {"label": "Novo Alegre", "value": "C15783"},
               {"label": "Novo Jardim", "value": "C15784"},
               {"label": "Oliveira de Fatima", "value": "C15785"},
               {"label": "Palmas", "value": "C15786"},
               {"label": "Palmeirante", "value": "C15787"},
               {"label": "Palmeiras do Tocantins", "value": "C15788"},
               {"label": "Palmeiropolis", "value": "C15789"},
               {"label": "Paraiso do Tocantins", "value": "C15790"},
               {"label": "Parana", "value": "C15791"},
               {"label": "Pau dArco", "value": "C15792"},
               {"label": "Pedro Afonso", "value": "C15793"},
               {"label": "Peixe", "value": "C15794"},
               {"label": "Pequizeiro", "value": "C15795"},
               {"label": "Pindorama do Tocantins", "value": "C15796"},
               {"label": "Piraque", "value": "C15797"},
               {"label": "Pium", "value": "C15798"},
               {"label": "Ponte Alta do Bom Jesus", "value": "C15799"},
               {"label": "Ponte Alta do Tocantins", "value": "C15800"},
               {"label": "Porto Alegre do Tocantins", "value": "C15801"},
               {"label": "Porto Nacional", "value": "C15802"},
               {"label": "Praia Norte", "value": "C15803"},
               {"label": "Presidente Kennedy", "value": "C15804"},
               {"label": "Pugmil", "value": "C15805"},
               {"label": "Recursolandia", "value": "C15806"},
               {"label": "Riachinho", "value": "C15807"},
               {"label": "Rio da Conceicao", "value": "C15808"},
               {"label": "Rio dos Bois", "value": "C15809"},
               {"label": "Rio Sono", "value": "C15810"},
               {"label": "Sampaio", "value": "C15811"},
               {"label": "Sandolandia", "value": "C15812"},
               {"label": "Santa Fe do Araguaia", "value": "C15813"},
               {"label": "Santa Maria do Tocantins", "value": "C15814"},
               {"label": "Santa Rita do Tocantins", "value": "C15815"},
               {"label": "Santa Rosa do Tocantins", "value": "C15816"},
               {"label": "Santa Tereza do Tocantins", "value": "C15817"},
               {"label": "Santa Terezinha do Tocantins", "value": "C15818"},
               {"label": "Sao Bento do Tocantins", "value": "C15819"},
               {"label": "Sao Felix do Tocantins", "value": "C15820"},
               {"label": "Sao Miguel do Tocantins", "value": "C15821"},
               {"label": "Sao Salvador do Tocantins", "value": "C15822"},
               {"label": "Sao Sebastiao do Tocantins", "value": "C15823"},
               {"label": "Sao Valerio", "value": "C15824"},
               {"label": "Silvanopolis", "value": "C15825"},
               {"label": "Sitio Novo do Tocantins", "value": "C15826"},
               {"label": "Sucupira", "value": "C15827"},
               {"label": "Taguatinga", "value": "C15828"},
               {"label": "Taipas do Tocantins", "value": "C15829"},
               {"label": "Talisma", "value": "C15830"},
               {"label": "Tocantinia", "value": "C15831"},
               {"label": "Tocantinopolis", "value": "C15832"},
               {"label": "Tupirama", "value": "C15833"},
               {"label": "Tupiratins", "value": "C15834"},
               {"label": "Wanderlandia", "value": "C15835"},
               {"label": "Xambioa", "value": "C15836"}],
 "Tochigi Prefecture": [{"label": "Ashikaga", "value": "C63969"},
                        {"label": "Fujioka", "value": "C63970"},
                        {"label": "Imaichi", "value": "C63971"},
                        {"label": "Kaminokawa", "value": "C63972"},
                        {"label": "Kanuma", "value": "C63973"},
                        {"label": "Kanuma-shi", "value": "C63974"},
                        {"label": "Karasuyama", "value": "C63975"},
                        {"label": "Kuroiso", "value": "C63976"},
                        {"label": "Mashiko", "value": "C63977"},
                        {"label": "Mibu", "value": "C63978"},
                        {"label": "Mooka", "value": "C63979"},
                        {"label": "Mooka-shi", "value": "C63980"},
                        {"label": "Motegi", "value": "C63981"},
                        {"label": "Nasukarasuyama", "value": "C63982"},
                        {"label": "Nasukarasuyama-shi", "value": "C63983"},
                        {"label": "Nasushiobara-shi", "value": "C63984"},
                        {"label": "Nikko", "value": "C63985"},
                        {"label": "Nikko-shi", "value": "C63986"},
                        {"label": "Otawara", "value": "C63987"},
                        {"label": "Otawara-shi", "value": "C63988"},
                        {"label": "Oyama", "value": "C63989"},
                        {"label": "Oyama-shi", "value": "C63990"},
                        {"label": "Sakura-shi", "value": "C63991"},
                        {"label": "Sano", "value": "C63992"},
                        {"label": "Sano-shi", "value": "C63993"},
                        {"label": "Shimotsuke-shi", "value": "C63994"},
                        {"label": "Tanuma", "value": "C63995"},
                        {"label": "Tochigi-shi", "value": "C63996"},
                        {"label": "Ujiie", "value": "C63997"},
                        {"label": "Utsunomiya", "value": "C63998"},
                        {"label": "Utsunomiya-shi", "value": "C63999"},
                        {"label": "Yaita", "value": "C64000"},
                        {"label": "Yaita-shi", "value": "C64001"}],
 "Togdheer Region": [{"label": "Burao", "value": "C107923"},
                     {"label": "Ceek", "value": "C107924"},
                     {"label": "Oodweyne", "value": "C107925"}],
 "Tokat": [{"label": "Almus", "value": "C121057"},
           {"label": "Artova", "value": "C121058"},
           {"label": "Basciftlik", "value": "C121059"},
           {"label": "Erbaa", "value": "C121060"},
           {"label": "Niksar", "value": "C121061"},
           {"label": "Pazar", "value": "C121062"},
           {"label": "Resadiye", "value": "C121063"},
           {"label": "Sulusaray", "value": "C121064"},
           {"label": "Tokat", "value": "C121065"},
           {"label": "Turhal", "value": "C121066"},
           {"label": "Yesilyurt Ilcesi", "value": "C121067"},
           {"label": "Zile", "value": "C121068"}],
 "Tokushima Prefecture": [{"label": "Anan Shi", "value": "C64002"},
                          {"label": "Awa-shi", "value": "C64003"},
                          {"label": "Ikedacho", "value": "C64004"},
                          {"label": "Ishii", "value": "C64005"},
                          {"label": "Kamojimacho-jogejima", "value": "C64006"},
                          {"label": "Katsuura Gun", "value": "C64007"},
                          {"label": "Komatsushima Shi", "value": "C64008"},
                          {"label": "Komatsushimacho", "value": "C64009"},
                          {"label": "Mima Shi", "value": "C64010"},
                          {"label": "Miyoshi Shi", "value": "C64011"},
                          {"label": "Miyoshi-gun", "value": "C64012"},
                          {"label": "Naruto-shi", "value": "C64013"},
                          {"label": "Narutocho-mitsuishi", "value": "C64014"},
                          {"label": "Tokushima", "value": "C64015"},
                          {"label": "Tokushima Shi", "value": "C64016"},
                          {"label": "Wakimachi", "value": "C64017"},
                          {"label": "Yoshinogawa Shi", "value": "C64018"}],
 "Tokyo": [{"label": "Adachi Ku", "value": "C64019"},
           {"label": "Akiruno-shi", "value": "C64020"},
           {"label": "Akishima-shi", "value": "C64021"},
           {"label": "Arakawa Ku", "value": "C64022"},
           {"label": "Bunkyo-ku", "value": "C64023"},
           {"label": "Chiyoda-ku", "value": "C64024"},
           {"label": "Chofu", "value": "C64025"},
           {"label": "Chofu-shi", "value": "C64026"},
           {"label": "Chuo Ku", "value": "C64027"},
           {"label": "Edogawa Ku", "value": "C64028"},
           {"label": "Fuchu-shi", "value": "C64029"},
           {"label": "Fussa", "value": "C64030"},
           {"label": "Hachioji", "value": "C64031"},
           {"label": "Hamura-shi", "value": "C64032"},
           {"label": "Higashi-murayama-shi", "value": "C64033"},
           {"label": "Higashikurume-shi", "value": "C64034"},
           {"label": "Higashimurayama", "value": "C64035"},
           {"label": "Higashiyamato", "value": "C64036"},
           {"label": "Higashiyamato-shi", "value": "C64037"},
           {"label": "Hino", "value": "C64038"},
           {"label": "Hino-shi", "value": "C64039"},
           {"label": "Inagi-shi", "value": "C64040"},
           {"label": "Itabashi-ku", "value": "C64041"},
           {"label": "Itsukaichi", "value": "C64042"},
           {"label": "Kamirenjaku", "value": "C64043"},
           {"label": "Katsushika Ku", "value": "C64044"},
           {"label": "Kita-ku", "value": "C64045"},
           {"label": "Kiyose-shi", "value": "C64046"},
           {"label": "Kodaira-shi", "value": "C64047"},
           {"label": "Koganei-shi", "value": "C64048"},
           {"label": "Kokubunji", "value": "C64049"},
           {"label": "Kokubunji-shi", "value": "C64050"},
           {"label": "Komae-shi", "value": "C64051"},
           {"label": "Koto-ku", "value": "C64052"},
           {"label": "Kunitachi-shi", "value": "C64053"},
           {"label": "Machida", "value": "C64054"},
           {"label": "Machida-shi", "value": "C64055"},
           {"label": "Meguro-ku", "value": "C64056"},
           {"label": "Minato-ku", "value": "C64057"},
           {"label": "Mitaka-shi", "value": "C64058"},
           {"label": "Musashimurayama-shi", "value": "C64059"},
           {"label": "Musashino", "value": "C64060"},
           {"label": "Musashino-shi", "value": "C64061"},
           {"label": "Nakano-ku", "value": "C64062"},
           {"label": "Nerima-ku", "value": "C64063"},
           {"label": "Nishi-Tokyo-shi", "value": "C64064"},
           {"label": "Nishitokyo-shi", "value": "C64065"},
           {"label": "Ome", "value": "C64066"},
           {"label": "Ome-shi", "value": "C64067"},
           {"label": "Ota-ku", "value": "C64068"},
           {"label": "Setagaya-ku", "value": "C64069"},
           {"label": "Shibuya-ku", "value": "C64070"},
           {"label": "Shinagawa-ku", "value": "C64071"},
           {"label": "Shinjuku-ku", "value": "C64072"},
           {"label": "Suginami-ku", "value": "C64073"},
           {"label": "Sumida-ku", "value": "C64074"},
           {"label": "Tachikawa-shi", "value": "C64075"},
           {"label": "Taito-ku", "value": "C64076"},
           {"label": "Tama-shi", "value": "C64077"},
           {"label": "Tanashicho", "value": "C64078"},
           {"label": "Tokyo", "value": "C64079"},
           {"label": "Toshima-ku", "value": "C64080"},
           {"label": "Urayasu", "value": "C64081"}],
 "Toledo District": [{"label": "Punta Gorda", "value": "C9593"}],
 "Tolima": [{"label": "Alpujarra", "value": "C20559"},
            {"label": "Alvarado", "value": "C20560"},
            {"label": "Ambalema", "value": "C20561"},
            {"label": "Anzoategui", "value": "C20562"},
            {"label": "Armero", "value": "C20563"},
            {"label": "Ataco", "value": "C20564"},
            {"label": "Cajamarca", "value": "C20565"},
            {"label": "Carmen de Apicala", "value": "C20566"},
            {"label": "Casabianca", "value": "C20567"},
            {"label": "Chaparral", "value": "C20568"},
            {"label": "Coello", "value": "C20569"},
            {"label": "Coyaima", "value": "C20570"},
            {"label": "Cunday", "value": "C20571"},
            {"label": "Dolores", "value": "C20572"},
            {"label": "Espinal", "value": "C20573"},
            {"label": "Falan", "value": "C20574"},
            {"label": "Flandes", "value": "C20575"},
            {"label": "Fresno", "value": "C20576"},
            {"label": "Guamo", "value": "C20577"},
            {"label": "Herveo", "value": "C20578"},
            {"label": "Honda", "value": "C20579"},
            {"label": "Ibague", "value": "C20580"},
            {"label": "Icononzo", "value": "C20581"},
            {"label": "Lerida", "value": "C20582"},
            {"label": "Libano", "value": "C20583"},
            {"label": "Melgar", "value": "C20584"},
            {"label": "Murillo", "value": "C20585"},
            {"label": "Natagaima", "value": "C20586"},
            {"label": "Ortega", "value": "C20587"},
            {"label": "Palocabildo", "value": "C20588"},
            {"label": "Piedras", "value": "C20589"},
            {"label": "Planadas", "value": "C20590"},
            {"label": "Prado", "value": "C20591"},
            {"label": "Purificacion", "value": "C20592"},
            {"label": "Rioblanco", "value": "C20593"},
            {"label": "Roncesvalles", "value": "C20594"},
            {"label": "Rovira", "value": "C20595"},
            {"label": "Saldana", "value": "C20596"},
            {"label": "San Antonio", "value": "C20597"},
            {"label": "San Luis", "value": "C20598"},
            {"label": "San Sebastian de Mariquita", "value": "C20599"},
            {"label": "Santa Isabel", "value": "C20600"},
            {"label": "Suarez", "value": "C20601"},
            {"label": "Valle de San Juan", "value": "C20602"},
            {"label": "Venadillo", "value": "C20603"},
            {"label": "Villahermosa", "value": "C20604"},
            {"label": "Villarrica", "value": "C20605"}],
 "Tolmin Municipality": [{"label": "Tolmin", "value": "C107838"}],
 "Tolna County": [{"label": "Bata", "value": "C44704"},
                  {"label": "Bataszek", "value": "C44705"},
                  {"label": "Bogyiszlo", "value": "C44706"},
                  {"label": "Bolcske", "value": "C44707"},
                  {"label": "Bonyhad", "value": "C44708"},
                  {"label": "Bonyhadi Jaras", "value": "C44709"},
                  {"label": "Decs", "value": "C44710"},
                  {"label": "Dobrokoz", "value": "C44711"},
                  {"label": "Dombovar", "value": "C44712"},
                  {"label": "Dombovari Jaras", "value": "C44713"},
                  {"label": "Dunafoldvar", "value": "C44714"},
                  {"label": "Dunaszentgyorgy", "value": "C44715"},
                  {"label": "Fadd", "value": "C44716"},
                  {"label": "Gyonk", "value": "C44717"},
                  {"label": "Hogyesz", "value": "C44718"},
                  {"label": "Iregszemcse", "value": "C44719"},
                  {"label": "Madocsa", "value": "C44720"},
                  {"label": "Nagydorog", "value": "C44721"},
                  {"label": "Nagymanyok", "value": "C44722"},
                  {"label": "Nemetker", "value": "C44723"},
                  {"label": "Ocseny", "value": "C44724"},
                  {"label": "Ozora", "value": "C44725"},
                  {"label": "Paks", "value": "C44726"},
                  {"label": "Paksi Jaras", "value": "C44727"},
                  {"label": "Pincehely", "value": "C44728"},
                  {"label": "Simontornya", "value": "C44729"},
                  {"label": "Szedres", "value": "C44730"},
                  {"label": "Szekszard", "value": "C44731"},
                  {"label": "Szekszardi Jaras", "value": "C44732"},
                  {"label": "Szentgalpuszta", "value": "C44733"},
                  {"label": "Tamasi", "value": "C44734"},
                  {"label": "Tamasi Jaras", "value": "C44735"},
                  {"label": "Tengelic", "value": "C44736"},
                  {"label": "Tolna", "value": "C44737"},
                  {"label": "Tolnai Jaras", "value": "C44738"},
                  {"label": "Zomba", "value": "C44739"}],
 "Tombali Region": [{"label": "Catio", "value": "C43063"},
                    {"label": "Quebo", "value": "C43064"}],
 "Tombouctou Region": [{"label": "Araouane", "value": "C65862"},
                       {"label": "Cercle de Goundam", "value": "C65863"},
                       {"label": "Dire", "value": "C65864"},
                       {"label": "Goundam", "value": "C65865"},
                       {"label": "Gourma-Rharous Cercle", "value": "C65866"},
                       {"label": "Niafunke", "value": "C65867"},
                       {"label": "Timbuktu", "value": "C65868"}],
 "Tomsk Oblast": [{"label": "Aleksandrovskoye", "value": "C104925"},
                  {"label": "Asino", "value": "C104926"},
                  {"label": "Bakchar", "value": "C104927"},
                  {"label": "Belyy Yar", "value": "C104928"},
                  {"label": "Bogashevo", "value": "C104929"},
                  {"label": "Kaltay", "value": "C104930"},
                  {"label": "Kargasok", "value": "C104931"},
                  {"label": "Kedrovyy", "value": "C104932"},
                  {"label": "Kolpashevo", "value": "C104933"},
                  {"label": "Kozhevnikovo", "value": "C104934"},
                  {"label": "Krasnyy Yar", "value": "C104935"},
                  {"label": "Krivosheino", "value": "C104936"},
                  {"label": "Melnikovo", "value": "C104937"},
                  {"label": "Molchanovo", "value": "C104938"},
                  {"label": "Molchanovskiy Rayon", "value": "C104939"},
                  {"label": "Moryakovskiy Zaton", "value": "C104940"},
                  {"label": "Parabel", "value": "C104941"},
                  {"label": "Pervomayskoye", "value": "C104942"},
                  {"label": "Podgornoye", "value": "C104943"},
                  {"label": "Samus", "value": "C104944"},
                  {"label": "Seversk", "value": "C104945"},
                  {"label": "Strezhevoy", "value": "C104946"},
                  {"label": "Svetlyy", "value": "C104947"},
                  {"label": "Teguldet", "value": "C104948"},
                  {"label": "Teguldetskiy Rayon", "value": "C104949"},
                  {"label": "Timiryazevskoye", "value": "C104950"},
                  {"label": "Togur", "value": "C104951"},
                  {"label": "Tomsk", "value": "C104952"},
                  {"label": "Tomskiy Rayon", "value": "C104953"},
                  {"label": "Zyryanskoye", "value": "C104954"}],
 "Tongatapu": [{"label": "Haveluloto", "value": "C119650"},
               {"label": "Kolonga", "value": "C119651"},
               {"label": "Nukualofa", "value": "C119652"},
               {"label": "Vaini", "value": "C119653"}],
 "Torba": [{"label": "Sola", "value": "C147122"}],
 "Totonicapan Department": [{"label": "Momostenango", "value": "C42986"},
                            {"label": "Municipio de Momostenango",
                             "value": "C42987"},
                            {"label": "Municipio de Santa Maria Chiquimula",
                             "value": "C42988"},
                            {"label": "Municipio de Totonicapan",
                             "value": "C42989"},
                            {"label": "San Andres Xecul", "value": "C42990"},
                            {"label": "San Bartolo", "value": "C42991"},
                            {"label": "San Cristobal Totonicapan",
                             "value": "C42992"},
                            {"label": "San Francisco El Alto",
                             "value": "C42993"},
                            {"label": "Santa Lucia La Reforma",
                             "value": "C42994"},
                            {"label": "Santa Maria Chiquimula",
                             "value": "C42995"},
                            {"label": "Totonicapan", "value": "C42996"}],
 "Tottori Prefecture": [{"label": "Kurayoshi-shi", "value": "C64082"},
                        {"label": "Sakaiminato", "value": "C64083"},
                        {"label": "Sakaiminato Shi", "value": "C64084"},
                        {"label": "Tottori-shi", "value": "C64085"},
                        {"label": "Yonago Shi", "value": "C64086"}],
 "Tov Province": [{"label": "Dzuunmod", "value": "C75588"},
                  {"label": "Mongonmorit", "value": "C75589"},
                  {"label": "Ulaanhudag", "value": "C75590"},
                  {"label": "Zuunmod", "value": "C75591"}],
 "Tovuz District": [{"label": "Catax", "value": "C8526"},
                    {"label": "Cobansignaq", "value": "C8527"},
                    {"label": "Dondar Quscu", "value": "C8528"},
                    {"label": "Qaraxanli", "value": "C8529"},
                    {"label": "Tovuz", "value": "C8530"},
                    {"label": "Yaniqli", "value": "C8531"}],
 "Toyama Prefecture": [{"label": "Fukumitsu", "value": "C64087"},
                       {"label": "Himi Shi", "value": "C64088"},
                       {"label": "Himimachi", "value": "C64089"},
                       {"label": "Imizu Shi", "value": "C64090"},
                       {"label": "Kamiichi", "value": "C64091"},
                       {"label": "Kurobe-shi", "value": "C64092"},
                       {"label": "Namerikawa", "value": "C64093"},
                       {"label": "Namerikawa-shi", "value": "C64094"},
                       {"label": "Nanto Shi", "value": "C64095"},
                       {"label": "Nanto-shi", "value": "C64096"},
                       {"label": "Nishishinminato", "value": "C64097"},
                       {"label": "Nyuzen", "value": "C64098"},
                       {"label": "Oyabe", "value": "C64099"},
                       {"label": "Oyabe Shi", "value": "C64100"},
                       {"label": "Takaoka", "value": "C64101"},
                       {"label": "Takaoka Shi", "value": "C64102"},
                       {"label": "Tonami Shi", "value": "C64103"},
                       {"label": "Toyama Shi", "value": "C64104"},
                       {"label": "Uozu", "value": "C64105"},
                       {"label": "Uozu Shi", "value": "C64106"},
                       {"label": "Yatsuomachi-higashikumisaka",
                        "value": "C64107"}],
 "Tozeur Governorate": [{"label": "Chebika", "value": "C119801"},
                        {"label": "Degache", "value": "C119802"},
                        {"label": "Nefta", "value": "C119803"},
                        {"label": "Tamaghzah", "value": "C119804"},
                        {"label": "Tozeur", "value": "C119805"}],
 "Tra Vinh": [{"label": "Huyen Cang Long", "value": "C147694"},
              {"label": "Huyen Cau Ke", "value": "C147695"},
              {"label": "Huyen Cau Ngang", "value": "C147696"},
              {"label": "Huyen Tieu Can", "value": "C147697"},
              {"label": "Huyen Tra Cu", "value": "C147698"},
              {"label": "Tra Vinh", "value": "C147699"}],
 "Trabzon": [{"label": "Akcaabat", "value": "C121069"},
             {"label": "Arakli", "value": "C121070"},
             {"label": "Arsin", "value": "C121071"},
             {"label": "Besikduzu", "value": "C121072"},
             {"label": "Carsibasi", "value": "C121073"},
             {"label": "Caykara", "value": "C121074"},
             {"label": "Dernekpazari", "value": "C121075"},
             {"label": "Duzkoy", "value": "C121076"},
             {"label": "Hayrat", "value": "C121077"},
             {"label": "Koprubasi", "value": "C121078"},
             {"label": "Macka", "value": "C121079"},
             {"label": "Of", "value": "C121080"},
             {"label": "Ortahisar", "value": "C121081"},
             {"label": "Salpazari", "value": "C121082"},
             {"label": "Surmene", "value": "C121083"},
             {"label": "Tonya", "value": "C121084"},
             {"label": "Trabzon", "value": "C121085"},
             {"label": "Vakfikebir", "value": "C121086"},
             {"label": "Yomra", "value": "C121087"}],
 "Trang": [{"label": "Amphoe Hat Samran", "value": "C119480"},
           {"label": "Amphoe Huai Yot", "value": "C119481"},
           {"label": "Amphoe Kantang", "value": "C119482"},
           {"label": "Amphoe Mueang Trang", "value": "C119483"},
           {"label": "Amphoe Na Yong", "value": "C119484"},
           {"label": "Amphoe Palian", "value": "C119485"},
           {"label": "Amphoe Ratsada", "value": "C119486"},
           {"label": "Amphoe Sikao", "value": "C119487"},
           {"label": "Amphoe Wang Wiset", "value": "C119488"},
           {"label": "Amphoe Yan Ta Khao", "value": "C119489"},
           {"label": "Huai Yot", "value": "C119490"},
           {"label": "Kantang", "value": "C119491"},
           {"label": "Trang", "value": "C119492"}],
 "Trans Nzoia": [{"label": "Kitale", "value": "C64638"}],
 "Transnistria autonomous territorial unit": [{"label": "Camenca",
                                               "value": "C75544"},
                                              {"label": "Crasnoe",
                                               "value": "C75545"},
                                              {"label": "Dnestrovsc",
                                               "value": "C75546"},
                                              {"label": "Dubasari",
                                               "value": "C75547"},
                                              {"label": "Hryhoriopol",
                                               "value": "C75548"},
                                              {"label": "Maiac",
                                               "value": "C75549"},
                                              {"label": "Pervomaisc",
                                               "value": "C75550"},
                                              {"label": "Ribnita",
                                               "value": "C75551"},
                                              {"label": "Slobozia",
                                               "value": "C75552"},
                                              {"label": "Tiraspol",
                                               "value": "C75553"},
                                              {"label": "Tiraspolul Nou",
                                               "value": "C75554"}],
 "Trarza": [{"label": "Legat", "value": "C65973"},
            {"label": "Rosso", "value": "C65974"},
            {"label": "Tekane", "value": "C65975"}],
 "Trashigang District": [{"label": "Trashigang", "value": "C9648"}],
 "Trat": [{"label": "Amphoe Bo Rai", "value": "C119493"},
          {"label": "Amphoe Khao Saming", "value": "C119494"},
          {"label": "Amphoe Khlong Yai", "value": "C119495"},
          {"label": "Amphoe Ko Chang", "value": "C119496"},
          {"label": "Amphoe Ko Kut", "value": "C119497"},
          {"label": "Amphoe Laem Ngop", "value": "C119498"},
          {"label": "Amphoe Mueang Trat", "value": "C119499"},
          {"label": "Bo Rai", "value": "C119500"},
          {"label": "Khlong Yai", "value": "C119501"},
          {"label": "Ko Chang Tai", "value": "C119502"},
          {"label": "Trat", "value": "C119503"}],
 "Trbovlje Municipality": [{"label": "Trbovlje", "value": "C107839"}],
 "Trebnje Municipality": [{"label": "Trebnje", "value": "C107840"}],
 "Treinta y Tres Department": [{"label": "Santa Clara de Olimar",
                                "value": "C146977"},
                               {"label": "Treinta y Tres", "value": "C146978"},
                               {"label": "Vergara", "value": "C146979"},
                               {"label": "Villa Sara", "value": "C146980"}],
 "Trelawny Parish": [{"label": "Albert Town", "value": "C62404"},
                     {"label": "Alps", "value": "C62405"},
                     {"label": "Bounty Hall", "value": "C62406"},
                     {"label": "Brampton", "value": "C62407"},
                     {"label": "Bunkers Hill", "value": "C62408"},
                     {"label": "Clarks Town", "value": "C62409"},
                     {"label": "Daniel Town", "value": "C62410"},
                     {"label": "Deeside", "value": "C62411"},
                     {"label": "Duanvale", "value": "C62412"},
                     {"label": "Duncans", "value": "C62413"},
                     {"label": "Falmouth", "value": "C62414"},
                     {"label": "Freemans Hall", "value": "C62415"},
                     {"label": "Granville", "value": "C62416"},
                     {"label": "Hampden", "value": "C62417"},
                     {"label": "Jackson Town", "value": "C62418"},
                     {"label": "Joe Hut", "value": "C62419"},
                     {"label": "Kinloss", "value": "C62420"},
                     {"label": "Lorrimers", "value": "C62421"},
                     {"label": "Martha Brae", "value": "C62422"},
                     {"label": "Perth Town", "value": "C62423"},
                     {"label": "Refuge", "value": "C62424"},
                     {"label": "Rio Bueno", "value": "C62425"},
                     {"label": "Salt Marsh", "value": "C62426"},
                     {"label": "Samuels Prospect", "value": "C62427"},
                     {"label": "Sawyers", "value": "C62428"},
                     {"label": "Sherwood Content", "value": "C62429"},
                     {"label": "Spicy Hill", "value": "C62430"},
                     {"label": "Spring Garden", "value": "C62431"},
                     {"label": "St. Vincent", "value": "C62432"},
                     {"label": "Stettin", "value": "C62433"},
                     {"label": "Stewart Town", "value": "C62434"},
                     {"label": "Troy", "value": "C62435"},
                     {"label": "Ulster Spring", "value": "C62436"},
                     {"label": "Wait-A-Bit", "value": "C62437"},
                     {"label": "Wakefield", "value": "C62438"},
                     {"label": "Warsop", "value": "C62439"},
                     {"label": "Wilsons Run", "value": "C62440"},
                     {"label": "Wirefence", "value": "C62441"}],
 "Trencin Region": [{"label": "Banovce nad Bebravou", "value": "C107475"},
                    {"label": "Bojnice", "value": "C107476"},
                    {"label": "Brezova pod Bradlom", "value": "C107477"},
                    {"label": "Cachtice", "value": "C107478"},
                    {"label": "Dubnica nad Vahom", "value": "C107479"},
                    {"label": "Handlova", "value": "C107480"},
                    {"label": "Ilava", "value": "C107481"},
                    {"label": "Lehota pod Vtacnikom", "value": "C107482"},
                    {"label": "Myjava", "value": "C107483"},
                    {"label": "Nemsova", "value": "C107484"},
                    {"label": "Nova Dubnica", "value": "C107485"},
                    {"label": "Novaky", "value": "C107486"},
                    {"label": "Nove Mesto nad Vahom", "value": "C107487"},
                    {"label": "Okres Banovce nad Bebravou", "value": "C107488"},
                    {"label": "Okres Ilava", "value": "C107489"},
                    {"label": "Okres Myjava", "value": "C107490"},
                    {"label": "Okres Nove Mesto nad Vahom", "value": "C107491"},
                    {"label": "Okres Partizanske", "value": "C107492"},
                    {"label": "Okres Povazska Bystrica", "value": "C107493"},
                    {"label": "Okres Prievidza", "value": "C107494"},
                    {"label": "Okres Puchov", "value": "C107495"},
                    {"label": "Okres Trencin", "value": "C107496"},
                    {"label": "Partizanske", "value": "C107497"},
                    {"label": "Povazska Bystrica", "value": "C107498"},
                    {"label": "Prievidza", "value": "C107499"},
                    {"label": "Puchov", "value": "C107500"},
                    {"label": "Stara Tura", "value": "C107501"},
                    {"label": "Trencianske Teplice", "value": "C107502"},
                    {"label": "Trencin", "value": "C107503"}],
 "Trentino-South Tyrol": [{"label": "Ala", "value": "C59843"},
                          {"label": "Albiano", "value": "C59844"},
                          {"label": "Aldeno", "value": "C59845"},
                          {"label": "Aldino", "value": "C59846"},
                          {"label": "Amblar", "value": "C59847"},
                          {"label": "Andalo", "value": "C59848"},
                          {"label": "Andriano", "value": "C59849"},
                          {"label": "Anterivo", "value": "C59850"},
                          {"label": "Arco", "value": "C59851"},
                          {"label": "Avelengo", "value": "C59852"},
                          {"label": "Avio", "value": "C59853"},
                          {"label": "Badia", "value": "C59854"},
                          {"label": "Barbiano - Barbian", "value": "C59855"},
                          {"label": "Baselga di Pine", "value": "C59856"},
                          {"label": "Bersone", "value": "C59857"},
                          {"label": "Besenello", "value": "C59858"},
                          {"label": "Bieno", "value": "C59859"},
                          {"label": "Bleggio Superiore", "value": "C59860"},
                          {"label": "Bocenago", "value": "C59861"},
                          {"label": "Bolbeno", "value": "C59862"},
                          {"label": "Bolognano-Vignole", "value": "C59863"},
                          {"label": "Bolzano", "value": "C59864"},
                          {"label": "Bondo", "value": "C59865"},
                          {"label": "Bondone", "value": "C59866"},
                          {"label": "Borgo Valsugana", "value": "C59867"},
                          {"label": "Bosentino-Migazzone", "value": "C59868"},
                          {"label": "Braies", "value": "C59869"},
                          {"label": "Breguzzo", "value": "C59870"},
                          {"label": "Brennero - Brenner", "value": "C59871"},
                          {"label": "Brentonico", "value": "C59872"},
                          {"label": "Bresimo", "value": "C59873"},
                          {"label": "Bressanone", "value": "C59874"},
                          {"label": "Brez", "value": "C59875"},
                          {"label": "Brione", "value": "C59876"},
                          {"label": "Bronzolo", "value": "C59877"},
                          {"label": "Brunico", "value": "C59878"},
                          {"label": "Caderzone Terme", "value": "C59879"},
                          {"label": "Cadine", "value": "C59880"},
                          {"label": "Cadipietra", "value": "C59881"},
                          {"label": "Cagno", "value": "C59882"},
                          {"label": "Caines", "value": "C59883"},
                          {"label": "Caines - Kuens", "value": "C59884"},
                          {"label": "Calavino", "value": "C59885"},
                          {"label": "Calceranica al Lago", "value": "C59886"},
                          {"label": "Caldaro sulla Strada del Vino",
                           "value": "C59887"},
                          {"label": "Caldes", "value": "C59888"},
                          {"label": "Caldonazzo", "value": "C59889"},
                          {"label": "Calliano", "value": "C59890"},
                          {"label": "Campitello di Fassa", "value": "C59891"},
                          {"label": "Campo di Trens", "value": "C59892"},
                          {"label": "Campo Tures", "value": "C59893"},
                          {"label": "Campodenno", "value": "C59894"},
                          {"label": "Canal San Bovo", "value": "C59895"},
                          {"label": "Canazei", "value": "C59896"},
                          {"label": "Capriana", "value": "C59897"},
                          {"label": "Carano", "value": "C59898"},
                          {"label": "Cardano", "value": "C59899"},
                          {"label": "Carisolo", "value": "C59900"},
                          {"label": "Carzano", "value": "C59901"},
                          {"label": "Castel Condino", "value": "C59902"},
                          {"label": "Castelbello", "value": "C59903"},
                          {"label": "Castelbello-Ciardes - Kastelbell-Tschars",
                           "value": "C59904"},
                          {"label": "Castelfondo", "value": "C59905"},
                          {"label": "Castello Molina di Fiemme",
                           "value": "C59906"},
                          {"label": "Castello Tesino", "value": "C59907"},
                          {"label": "Castelnuovo", "value": "C59908"},
                          {"label": "Castelrotto", "value": "C59909"},
                          {"label": "Cavalese", "value": "C59910"},
                          {"label": "Cavareno", "value": "C59911"},
                          {"label": "Cavedago", "value": "C59912"},
                          {"label": "Cavedine", "value": "C59913"},
                          {"label": "Cavizzana", "value": "C59914"},
                          {"label": "Cembra", "value": "C59915"},
                          {"label": "Centa San Nicolo", "value": "C59916"},
                          {"label": "Centrale", "value": "C59917"},
                          {"label": "Cermes", "value": "C59918"},
                          {"label": "Certosa", "value": "C59919"},
                          {"label": "Chienes", "value": "C59920"},
                          {"label": "Chiusa", "value": "C59921"},
                          {"label": "Cimego", "value": "C59922"},
                          {"label": "Cimoneri", "value": "C59923"},
                          {"label": "Cinte Tesino", "value": "C59924"},
                          {"label": "Cis", "value": "C59925"},
                          {"label": "Civezzano", "value": "C59926"},
                          {"label": "Cles", "value": "C59927"},
                          {"label": "Cloz", "value": "C59928"},
                          {"label": "Cogolo", "value": "C59929"},
                          {"label": "Collalbo", "value": "C59930"},
                          {"label": "Colle Isarco", "value": "C59931"},
                          {"label": "Commezzadura", "value": "C59932"},
                          {"label": "Condino", "value": "C59933"},
                          {"label": "Coredo", "value": "C59934"},
                          {"label": "Cornaiano", "value": "C59935"},
                          {"label": "Cornedo AllIsarco", "value": "C59936"},
                          {"label": "Cortaccia sulla Strada del Vino",
                           "value": "C59937"},
                          {"label": "Cortina sulla Strada del Vino",
                           "value": "C59938"},
                          {"label": "Corvara in Badia", "value": "C59939"},
                          {"label": "Covelo", "value": "C59940"},
                          {"label": "Creto", "value": "C59941"},
                          {"label": "Croviana", "value": "C59942"},
                          {"label": "Cunevo", "value": "C59943"},
                          {"label": "Curon Venosta", "value": "C59944"},
                          {"label": "Daiano", "value": "C59945"},
                          {"label": "Dambel", "value": "C59946"},
                          {"label": "Daone", "value": "C59947"},
                          {"label": "Denno", "value": "C59948"},
                          {"label": "Dimaro", "value": "C59949"},
                          {"label": "Dobbiaco", "value": "C59950"},
                          {"label": "Don", "value": "C59951"},
                          {"label": "Dorsino", "value": "C59952"},
                          {"label": "Drena", "value": "C59953"},
                          {"label": "Dro", "value": "C59954"},
                          {"label": "Egna", "value": "C59955"},
                          {"label": "Faedo", "value": "C59956"},
                          {"label": "Fai della Paganella", "value": "C59957"},
                          {"label": "Falzes", "value": "C59958"},
                          {"label": "Faver", "value": "C59959"},
                          {"label": "Fiave", "value": "C59960"},
                          {"label": "Fie Allo Sciliar", "value": "C59961"},
                          {"label": "Fiera di Primiero", "value": "C59962"},
                          {"label": "Fierozzo", "value": "C59963"},
                          {"label": "Flavon", "value": "C59964"},
                          {"label": "Folgaria", "value": "C59965"},
                          {"label": "Fondo", "value": "C59966"},
                          {"label": "Fontana Nuova-Bevia", "value": "C59967"},
                          {"label": "Fornace", "value": "C59968"},
                          {"label": "Fortezza", "value": "C59969"},
                          {"label": "Frassilongo", "value": "C59970"},
                          {"label": "Fraviano", "value": "C59971"},
                          {"label": "Funes - Villnoess", "value": "C59972"},
                          {"label": "Gais", "value": "C59973"},
                          {"label": "Ganda", "value": "C59974"},
                          {"label": "Gargazzone", "value": "C59975"},
                          {"label": "Garniga Nuova", "value": "C59976"},
                          {"label": "Gionghi-Cappella", "value": "C59977"},
                          {"label": "Giovo", "value": "C59978"},
                          {"label": "Giustino", "value": "C59979"},
                          {"label": "Glorenza", "value": "C59980"},
                          {"label": "Grauno", "value": "C59981"},
                          {"label": "Grigno", "value": "C59982"},
                          {"label": "Grumes", "value": "C59983"},
                          {"label": "Imer", "value": "C59984"},
                          {"label": "Isera", "value": "C59985"},
                          {"label": "La Valle - Wengen", "value": "C59986"},
                          {"label": "Laces", "value": "C59987"},
                          {"label": "Laghetti", "value": "C59988"},
                          {"label": "Lagundo", "value": "C59989"},
                          {"label": "Laion", "value": "C59990"},
                          {"label": "Laives", "value": "C59991"},
                          {"label": "Lana", "value": "C59992"},
                          {"label": "Lardaro", "value": "C59993"},
                          {"label": "Lasa", "value": "C59994"},
                          {"label": "Lases", "value": "C59995"},
                          {"label": "Lasino", "value": "C59996"},
                          {"label": "Lauregno", "value": "C59997"},
                          {"label": "Lavis", "value": "C59998"},
                          {"label": "Leone-Santa Elisabetta",
                           "value": "C59999"},
                          {"label": "Levico Terme", "value": "C60000"},
                          {"label": "Lisignago", "value": "C60001"},
                          {"label": "Livo", "value": "C60002"},
                          {"label": "Lodrone-Darzo", "value": "C60003"},
                          {"label": "Lona-Lases", "value": "C60004"},
                          {"label": "Luserna", "value": "C60005"},
                          {"label": "Luson - Luesen", "value": "C60006"},
                          {"label": "Madrano-Canzolino", "value": "C60007"},
                          {"label": "Magre sulla Strada del Vino",
                           "value": "C60008"},
                          {"label": "Male", "value": "C60009"},
                          {"label": "Malles Venosta", "value": "C60010"},
                          {"label": "Malosco", "value": "C60011"},
                          {"label": "Marco", "value": "C60012"},
                          {"label": "Marlengo", "value": "C60013"},
                          {"label": "Massimeno", "value": "C60014"},
                          {"label": "Mattarello", "value": "C60015"},
                          {"label": "Mazzin", "value": "C60016"},
                          {"label": "Meano", "value": "C60017"},
                          {"label": "Meiern", "value": "C60018"},
                          {"label": "Meltina", "value": "C60019"},
                          {"label": "Merano", "value": "C60020"},
                          {"label": "Mezzana", "value": "C60021"},
                          {"label": "Mezzano", "value": "C60022"},
                          {"label": "Mezzocorona", "value": "C60023"},
                          {"label": "Mezzolombardo", "value": "C60024"},
                          {"label": "Miola di Pine", "value": "C60025"},
                          {"label": "Moena", "value": "C60026"},
                          {"label": "Molina di Ledro", "value": "C60027"},
                          {"label": "Molveno", "value": "C60028"},
                          {"label": "Monclassico", "value": "C60029"},
                          {"label": "Monguelfo", "value": "C60030"},
                          {"label": "Montagna", "value": "C60031"},
                          {"label": "Montagne", "value": "C60032"},
                          {"label": "Mori", "value": "C60033"},
                          {"label": "Moscheri", "value": "C60034"},
                          {"label": "Moso in Passiria", "value": "C60035"},
                          {"label": "Muhlen in Taufers", "value": "C60036"},
                          {"label": "Nago-Torbole", "value": "C60037"},
                          {"label": "Nalles", "value": "C60038"},
                          {"label": "Nanno", "value": "C60039"},
                          {"label": "Naturno", "value": "C60040"},
                          {"label": "Nave San Rocco", "value": "C60041"},
                          {"label": "Naz-Sciaves - Natz-Schabs",
                           "value": "C60042"},
                          {"label": "Nogaredo", "value": "C60043"},
                          {"label": "Nomi", "value": "C60044"},
                          {"label": "Nova Levante", "value": "C60045"},
                          {"label": "Nova Ponente", "value": "C60046"},
                          {"label": "Novaledo", "value": "C60047"},
                          {"label": "Ora", "value": "C60048"},
                          {"label": "Ortisei", "value": "C60049"},
                          {"label": "Ospedaletto", "value": "C60050"},
                          {"label": "Ossana", "value": "C60051"},
                          {"label": "Padergnone", "value": "C60052"},
                          {"label": "Palu del Fersina", "value": "C60053"},
                          {"label": "Panchia", "value": "C60054"},
                          {"label": "Parcines", "value": "C60055"},
                          {"label": "Pellizzano", "value": "C60056"},
                          {"label": "Pelugo", "value": "C60057"},
                          {"label": "Perca", "value": "C60058"},
                          {"label": "Pergine Valsugana", "value": "C60059"},
                          {"label": "Piazza", "value": "C60060"},
                          {"label": "Pietramurata", "value": "C60061"},
                          {"label": "Pieve di Bono", "value": "C60062"},
                          {"label": "Pieve di Ledro", "value": "C60063"},
                          {"label": "Pieve Tesino", "value": "C60064"},
                          {"label": "Pineta", "value": "C60065"},
                          {"label": "Pinzolo", "value": "C60066"},
                          {"label": "Plaus", "value": "C60067"},
                          {"label": "Pomarolo", "value": "C60068"},
                          {"label": "Ponte Arche", "value": "C60069"},
                          {"label": "Ponte Gardena", "value": "C60070"},
                          {"label": "Postal", "value": "C60071"},
                          {"label": "Povo", "value": "C60072"},
                          {"label": "Pozza di Fassa", "value": "C60073"},
                          {"label": "Praso", "value": "C60074"},
                          {"label": "Prati", "value": "C60075"},
                          {"label": "Prato Allo Stelvio", "value": "C60076"},
                          {"label": "Predazzo", "value": "C60077"},
                          {"label": "Predoi", "value": "C60078"},
                          {"label": "Preore", "value": "C60079"},
                          {"label": "Prezzo", "value": "C60080"},
                          {"label": "Proves - Proveis", "value": "C60081"},
                          {"label": "Provincia autonoma di Trento",
                           "value": "C60082"},
                          {"label": "Rabbi Fonti", "value": "C60083"},
                          {"label": "Rabla", "value": "C60084"},
                          {"label": "Racines", "value": "C60085"},
                          {"label": "Racines - Ratschings", "value": "C60086"},
                          {"label": "Ragoli", "value": "C60087"},
                          {"label": "Rasun Anterselva - Rasen-Antholz",
                           "value": "C60088"},
                          {"label": "Ravina", "value": "C60089"},
                          {"label": "Renon - Ritten", "value": "C60090"},
                          {"label": "Revo", "value": "C60091"},
                          {"label": "Ried", "value": "C60092"},
                          {"label": "Rifiano", "value": "C60093"},
                          {"label": "Rio di Pusteria", "value": "C60094"},
                          {"label": "Riscone", "value": "C60095"},
                          {"label": "Riva del Garda", "value": "C60096"},
                          {"label": "Rodengo", "value": "C60097"},
                          {"label": "Rodengo - Rodeneck", "value": "C60098"},
                          {"label": "Romagnano", "value": "C60099"},
                          {"label": "Romallo", "value": "C60100"},
                          {"label": "Romeno", "value": "C60101"},
                          {"label": "Roncegno", "value": "C60102"},
                          {"label": "Ronchi Valsugana", "value": "C60103"},
                          {"label": "Roncone", "value": "C60104"},
                          {"label": "Ronzo-Chienis", "value": "C60105"},
                          {"label": "Ronzone", "value": "C60106"},
                          {"label": "Rovere della Luna", "value": "C60107"},
                          {"label": "Rovereto", "value": "C60108"},
                          {"label": "Ruffre", "value": "C60109"},
                          {"label": "Rumo", "value": "C60110"},
                          {"label": "Sabbionara", "value": "C60111"},
                          {"label": "Sagron Mis", "value": "C60112"},
                          {"label": "Salorno", "value": "C60113"},
                          {"label": "Samone", "value": "C60114"},
                          {"label": "San Bernardo", "value": "C60115"},
                          {"label": "San Candido", "value": "C60116"},
                          {"label": "San Felice", "value": "C60117"},
                          {"label": "San Genesio", "value": "C60118"},
                          {"label": "San Genesio Atesino", "value": "C60119"},
                          {"label": "San Giacomo", "value": "C60120"},
                          {"label": "San Leonardo in Passiria",
                           "value": "C60121"},
                          {"label": "San Lorenzo di Sebato", "value": "C60122"},
                          {"label": "San Lorenzo in Banale", "value": "C60123"},
                          {"label": "San Martino", "value": "C60124"},
                          {"label": "San Martino in Badia", "value": "C60125"},
                          {"label": "San Martino in Passiria",
                           "value": "C60126"},
                          {"label": "San Michele", "value": "C60127"},
                          {"label": "San Michele AllAdige", "value": "C60128"},
                          {"label": "San Pancrazio", "value": "C60129"},
                          {"label": "San Paolo", "value": "C60130"},
                          {"label": "San Pietro", "value": "C60131"},
                          {"label": "San Vigilio", "value": "C60132"},
                          {"label": "SantOrsola", "value": "C60133"},
                          {"label": "SantOrsola Terme", "value": "C60134"},
                          {"label": "Santa Cristina Valgardena",
                           "value": "C60135"},
                          {"label": "Santa Valburga", "value": "C60136"},
                          {"label": "Sanzeno", "value": "C60137"},
                          {"label": "Sarentino", "value": "C60138"},
                          {"label": "Sarnonico", "value": "C60139"},
                          {"label": "Scena", "value": "C60140"},
                          {"label": "Scena - Schenna", "value": "C60141"},
                          {"label": "Sciaves", "value": "C60142"},
                          {"label": "Scurelle", "value": "C60143"},
                          {"label": "Segonzano", "value": "C60144"},
                          {"label": "Selva", "value": "C60145"},
                          {"label": "Selva dei Molini", "value": "C60146"},
                          {"label": "Senale", "value": "C60147"},
                          {"label": "Senales", "value": "C60148"},
                          {"label": "Sesto", "value": "C60149"},
                          {"label": "Sfruz", "value": "C60150"},
                          {"label": "Silandro", "value": "C60151"},
                          {"label": "Sinigo", "value": "C60152"},
                          {"label": "Siror", "value": "C60153"},
                          {"label": "Siusi", "value": "C60154"},
                          {"label": "Sluderno", "value": "C60155"},
                          {"label": "Smarano", "value": "C60156"},
                          {"label": "Soprabolzano", "value": "C60157"},
                          {"label": "Soraga", "value": "C60158"},
                          {"label": "Sover", "value": "C60159"},
                          {"label": "Spera", "value": "C60160"},
                          {"label": "Spiazzo", "value": "C60161"},
                          {"label": "Spormaggiore", "value": "C60162"},
                          {"label": "Sporminore", "value": "C60163"},
                          {"label": "Stanghe", "value": "C60164"},
                          {"label": "Stelvio", "value": "C60165"},
                          {"label": "Stenico", "value": "C60166"},
                          {"label": "Storo", "value": "C60167"},
                          {"label": "Strembo", "value": "C60168"},
                          {"label": "Strigno", "value": "C60169"},
                          {"label": "Taio", "value": "C60170"},
                          {"label": "Tassullo", "value": "C60171"},
                          {"label": "Telve", "value": "C60172"},
                          {"label": "Telve di Sopra", "value": "C60173"},
                          {"label": "Tenna", "value": "C60174"},
                          {"label": "Tenno", "value": "C60175"},
                          {"label": "Terento", "value": "C60176"},
                          {"label": "Terlago", "value": "C60177"},
                          {"label": "Terlano", "value": "C60178"},
                          {"label": "Termeno", "value": "C60179"},
                          {"label": "Terragnolo", "value": "C60180"},
                          {"label": "Terres", "value": "C60181"},
                          {"label": "Terzolas", "value": "C60182"},
                          {"label": "Tesero", "value": "C60183"},
                          {"label": "Tesimo", "value": "C60184"},
                          {"label": "Tione di Trento", "value": "C60185"},
                          {"label": "Tires", "value": "C60186"},
                          {"label": "Tirolo", "value": "C60187"},
                          {"label": "Tonadico", "value": "C60188"},
                          {"label": "Torcegno", "value": "C60189"},
                          {"label": "Trambileno", "value": "C60190"},
                          {"label": "Transacqua", "value": "C60191"},
                          {"label": "Trento", "value": "C60192"},
                          {"label": "Tres", "value": "C60193"},
                          {"label": "Trodena", "value": "C60194"},
                          {"label": "Tubre", "value": "C60195"},
                          {"label": "Tuenno", "value": "C60196"},
                          {"label": "Ultimo - Ulten", "value": "C60197"},
                          {"label": "Vadena", "value": "C60198"},
                          {"label": "Valda", "value": "C60199"},
                          {"label": "Valdaora di Mezzo", "value": "C60200"},
                          {"label": "Valfloriana", "value": "C60201"},
                          {"label": "Vallarsa", "value": "C60202"},
                          {"label": "Valle Aurina - Ahrntal",
                           "value": "C60203"},
                          {"label": "Valle di Casies - Gsies",
                           "value": "C60204"},
                          {"label": "Vandoies - Vintl", "value": "C60205"},
                          {"label": "Vandoies di Sotto", "value": "C60206"},
                          {"label": "Varena", "value": "C60207"},
                          {"label": "Varna", "value": "C60208"},
                          {"label": "Vattaro", "value": "C60209"},
                          {"label": "Velturno", "value": "C60210"},
                          {"label": "Verano", "value": "C60211"},
                          {"label": "Verano - Voeran", "value": "C60212"},
                          {"label": "Verla", "value": "C60213"},
                          {"label": "Vermiglio", "value": "C60214"},
                          {"label": "Vervo", "value": "C60215"},
                          {"label": "Vezzano", "value": "C60216"},
                          {"label": "Vignola", "value": "C60217"},
                          {"label": "Vigo di Fassa", "value": "C60218"},
                          {"label": "Vigo di Ton", "value": "C60219"},
                          {"label": "Vigolo Vattaro", "value": "C60220"},
                          {"label": "Villa", "value": "C60221"},
                          {"label": "Villa Lagarina", "value": "C60222"},
                          {"label": "Villa Rendena", "value": "C60223"},
                          {"label": "Villa-Agnedo", "value": "C60224"},
                          {"label": "Villabassa", "value": "C60225"},
                          {"label": "Villandro", "value": "C60226"},
                          {"label": "Villandro - Villanders",
                           "value": "C60227"},
                          {"label": "Vipiteno", "value": "C60228"},
                          {"label": "Volano", "value": "C60229"},
                          {"label": "Zambana", "value": "C60230"},
                          {"label": "Ziano di Fiemme", "value": "C60231"},
                          {"label": "Zuclo", "value": "C60232"}],
 "Triesen": [{"label": "Triesen", "value": "C65079"}],
 "Triesenberg": [{"label": "Triesenberg", "value": "C65080"}],
 "Trinity Palmetto Point Parish": [{"label": "Trinity", "value": "C105734"}],
 "Tripoli District": [{"label": "Tagiura", "value": "C65062"},
                      {"label": "Tripoli", "value": "C65063"}],
 "Tripura": [{"label": "Agartala", "value": "C48180"},
             {"label": "Amarpur", "value": "C48181"},
             {"label": "Ambasa", "value": "C48182"},
             {"label": "Barjala", "value": "C48183"},
             {"label": "Belonia", "value": "C48184"},
             {"label": "Dhalai", "value": "C48185"},
             {"label": "Dharmanagar", "value": "C48186"},
             {"label": "Gomati", "value": "C48187"},
             {"label": "Kailashahar", "value": "C48188"},
             {"label": "Kamalpur", "value": "C48189"},
             {"label": "Khowai", "value": "C48190"},
             {"label": "North Tripura", "value": "C48191"},
             {"label": "Ranir Bazar", "value": "C48192"},
             {"label": "Sabrum", "value": "C48193"},
             {"label": "Sonamura", "value": "C48194"},
             {"label": "South Tripura", "value": "C48195"},
             {"label": "Udaipur", "value": "C48196"},
             {"label": "Unakoti", "value": "C48197"},
             {"label": "West Tripura", "value": "C48198"}],
 "Trnava Region": [{"label": "Dunajska Streda", "value": "C107504"},
                   {"label": "Gabcikovo", "value": "C107505"},
                   {"label": "Galanta", "value": "C107506"},
                   {"label": "Gbely", "value": "C107507"},
                   {"label": "Hlohovec", "value": "C107508"},
                   {"label": "Holic", "value": "C107509"},
                   {"label": "Leopoldov", "value": "C107510"},
                   {"label": "Okres Dunajska Streda", "value": "C107511"},
                   {"label": "Okres Galanta", "value": "C107512"},
                   {"label": "Okres Hlohovec", "value": "C107513"},
                   {"label": "Okres Piestany", "value": "C107514"},
                   {"label": "Okres Senica", "value": "C107515"},
                   {"label": "Okres Skalica", "value": "C107516"},
                   {"label": "Okres Trnava", "value": "C107517"},
                   {"label": "Piestany", "value": "C107518"},
                   {"label": "Samorin", "value": "C107519"},
                   {"label": "Senica", "value": "C107520"},
                   {"label": "Skalica", "value": "C107521"},
                   {"label": "Sladkovicovo", "value": "C107522"},
                   {"label": "Smolenice", "value": "C107523"},
                   {"label": "Trnava", "value": "C107524"},
                   {"label": "Velky Meder", "value": "C107525"},
                   {"label": "Vrbove", "value": "C107526"}],
 "Trnovska Vas Municipality": [{"label": "Trnovska Vas", "value": "C107841"}],
 "Troms": [{"label": "Balsfjord", "value": "C79135"},
           {"label": "Bardu", "value": "C79136"},
           {"label": "Berg", "value": "C79137"},
           {"label": "Borkenes", "value": "C79138"},
           {"label": "Dyroy", "value": "C79139"},
           {"label": "Evenskjer", "value": "C79140"},
           {"label": "Finnsnes", "value": "C79141"},
           {"label": "Gratangen", "value": "C79142"},
           {"label": "Gryllefjord", "value": "C79143"},
           {"label": "Hansnes", "value": "C79144"},
           {"label": "Harstad", "value": "C79145"},
           {"label": "Ibestad", "value": "C79146"},
           {"label": "Kafjord", "value": "C79147"},
           {"label": "Karlsoy", "value": "C79148"},
           {"label": "Kvaefjord", "value": "C79149"},
           {"label": "Kvaenangen", "value": "C79150"},
           {"label": "Lavangen", "value": "C79151"},
           {"label": "Lenvik", "value": "C79152"},
           {"label": "Lyngen", "value": "C79153"},
           {"label": "Lyngseidet", "value": "C79154"},
           {"label": "Malselv", "value": "C79155"},
           {"label": "Moen", "value": "C79156"},
           {"label": "Nordreisa", "value": "C79157"},
           {"label": "Olderdalen", "value": "C79158"},
           {"label": "Salangen", "value": "C79159"},
           {"label": "Setermoen", "value": "C79160"},
           {"label": "Sjovegan", "value": "C79161"},
           {"label": "Skanland", "value": "C79162"},
           {"label": "Skjervoy", "value": "C79163"},
           {"label": "Sorreisa", "value": "C79164"},
           {"label": "Storfjord", "value": "C79165"},
           {"label": "Storslett", "value": "C79166"},
           {"label": "Storsteinnes", "value": "C79167"},
           {"label": "Torsken", "value": "C79168"},
           {"label": "Tranoy", "value": "C79169"},
           {"label": "Tromso", "value": "C79170"}],
 "Trondelag": [{"label": "A i Afjord", "value": "C79171"},
               {"label": "Afjord", "value": "C79172"},
               {"label": "Agdenes", "value": "C79173"},
               {"label": "Berkak", "value": "C79174"},
               {"label": "Bjugn", "value": "C79175"},
               {"label": "Botngard", "value": "C79176"},
               {"label": "Brekstad", "value": "C79177"},
               {"label": "Fillan", "value": "C79178"},
               {"label": "Flatanger", "value": "C79179"},
               {"label": "Fosnes", "value": "C79180"},
               {"label": "Frosta", "value": "C79181"},
               {"label": "Froya", "value": "C79182"},
               {"label": "Grong", "value": "C79183"},
               {"label": "Hemne", "value": "C79184"},
               {"label": "Hitra", "value": "C79185"},
               {"label": "Holtalen", "value": "C79186"},
               {"label": "Hommelvik", "value": "C79187"},
               {"label": "Hoylandet", "value": "C79188"},
               {"label": "Inderoy", "value": "C79189"},
               {"label": "Indre Fosen", "value": "C79190"},
               {"label": "Klaebu", "value": "C79191"},
               {"label": "Kolvereid", "value": "C79192"},
               {"label": "Kyrksaeterora", "value": "C79193"},
               {"label": "Lauvsnes", "value": "C79194"},
               {"label": "Leka", "value": "C79195"},
               {"label": "Levanger", "value": "C79196"},
               {"label": "Lierne", "value": "C79197"},
               {"label": "Lundamo", "value": "C79198"},
               {"label": "Malm", "value": "C79199"},
               {"label": "Malvik", "value": "C79200"},
               {"label": "Meldal", "value": "C79201"},
               {"label": "Melhus", "value": "C79202"},
               {"label": "Meraker", "value": "C79203"},
               {"label": "Midtre Gauldal", "value": "C79204"},
               {"label": "Namdalseid", "value": "C79205"},
               {"label": "Namsos", "value": "C79206"},
               {"label": "Namsskogan", "value": "C79207"},
               {"label": "Naeroy", "value": "C79208"},
               {"label": "Oppdal", "value": "C79209"},
               {"label": "Orkdal", "value": "C79210"},
               {"label": "Osen", "value": "C79211"},
               {"label": "Overhalla", "value": "C79212"},
               {"label": "Orland", "value": "C79213"},
               {"label": "Raarvihke - Royrvik", "value": "C79214"},
               {"label": "Ranemsletta", "value": "C79215"},
               {"label": "Rennebu", "value": "C79216"},
               {"label": "Rindal", "value": "C79217"},
               {"label": "Roan", "value": "C79218"},
               {"label": "Roros", "value": "C79219"},
               {"label": "Rorvik", "value": "C79220"},
               {"label": "Royrvik", "value": "C79221"},
               {"label": "Selbu", "value": "C79222"},
               {"label": "Sistranda", "value": "C79223"},
               {"label": "Skaun", "value": "C79224"},
               {"label": "Skogn", "value": "C79225"},
               {"label": "Snaase", "value": "C79226"},
               {"label": "Snaase - Snasa", "value": "C79227"},
               {"label": "Snillfjord", "value": "C79228"},
               {"label": "Steinkjer", "value": "C79229"},
               {"label": "Stjordal", "value": "C79230"},
               {"label": "Stjordalshalsen", "value": "C79231"},
               {"label": "Straumen", "value": "C79232"},
               {"label": "Trondheim", "value": "C79233"},
               {"label": "Tydal", "value": "C79234"},
               {"label": "Verdal", "value": "C79235"},
               {"label": "Verran", "value": "C79236"},
               {"label": "Vikna", "value": "C79237"}],
 "Trujillo": [{"label": "Bocono", "value": "C147240"},
              {"label": "Municipio Pampanito", "value": "C147241"},
              {"label": "Municipio San Rafael de Carvajal", "value": "C147242"},
              {"label": "Trujillo", "value": "C147243"},
              {"label": "Valera", "value": "C147244"}],
 "Trzic Municipality": [{"label": "Bistrica pri Trzicu", "value": "C107842"},
                        {"label": "Trzic", "value": "C107843"}],
 "Trzin Municipality": [{"label": "Crnuce District", "value": "C107844"},
                        {"label": "Trzin", "value": "C107845"}],
 "Tshopo": [{"label": "Basoko", "value": "C23373"},
            {"label": "Kisangani", "value": "C23374"},
            {"label": "Yangambi", "value": "C23375"}],
 "Tshuapa": [{"label": "Boende", "value": "C23376"}],
 "Tsirang District": [{"label": "Trongsa", "value": "C9649"},
                      {"label": "Tsirang", "value": "C9650"}],
 "Tuamasaga": [{"label": "Afega", "value": "C106238"},
               {"label": "Apia", "value": "C106239"},
               {"label": "Malie", "value": "C106240"},
               {"label": "Vailima", "value": "C106241"},
               {"label": "Vaiusu", "value": "C106242"}],
 "Tucuman": [{"label": "Aguilares", "value": "C1520"},
             {"label": "Alderetes", "value": "C1521"},
             {"label": "Bella Vista", "value": "C1522"},
             {"label": "Burruyacu", "value": "C1523"},
             {"label": "Departamento de Burruyacu", "value": "C1524"},
             {"label": "Departamento de Capital", "value": "C1525"},
             {"label": "Departamento de Cruz Alta", "value": "C1526"},
             {"label": "Departamento de Famailla", "value": "C1527"},
             {"label": "Departamento de Graneros", "value": "C1528"},
             {"label": "Departamento de La Cocha", "value": "C1529"},
             {"label": "Departamento de Lules", "value": "C1530"},
             {"label": "Departamento de Monteros", "value": "C1531"},
             {"label": "Departamento de Rio Chico", "value": "C1532"},
             {"label": "Departamento de Simoca", "value": "C1533"},
             {"label": "Departamento de Trancas", "value": "C1534"},
             {"label": "Departamento de Yerba Buena", "value": "C1535"},
             {"label": "Famailla", "value": "C1536"},
             {"label": "Graneros", "value": "C1537"},
             {"label": "La Cocha", "value": "C1538"},
             {"label": "Monteros", "value": "C1539"},
             {"label": "San Miguel de Tucuman", "value": "C1540"},
             {"label": "Simoca", "value": "C1541"},
             {"label": "Tafi del Valle", "value": "C1542"},
             {"label": "Tafi Viejo", "value": "C1543"},
             {"label": "Trancas", "value": "C1544"},
             {"label": "Yerba Buena", "value": "C1545"}],
 "Tula Oblast": [{"label": "Ageyevo", "value": "C104955"},
                 {"label": "Aleksin", "value": "C104956"},
                 {"label": "Aleksinskiy Rayon", "value": "C104957"},
                 {"label": "Arsenyevo", "value": "C104958"},
                 {"label": "Barsuki", "value": "C104959"},
                 {"label": "Begichevskiy", "value": "C104960"},
                 {"label": "Belev", "value": "C104961"},
                 {"label": "Bogoroditsk", "value": "C104962"},
                 {"label": "Bolshoye Skuratovo", "value": "C104963"},
                 {"label": "Bolokhovo", "value": "C104964"},
                 {"label": "Borodinskiy", "value": "C104965"},
                 {"label": "Brusyanka", "value": "C104966"},
                 {"label": "Chekalin", "value": "C104967"},
                 {"label": "Cherepet", "value": "C104968"},
                 {"label": "Chern", "value": "C104969"},
                 {"label": "Donskoy", "value": "C104970"},
                 {"label": "Dubna", "value": "C104971"},
                 {"label": "Dubovka", "value": "C104972"},
                 {"label": "Gorelki", "value": "C104973"},
                 {"label": "Gritsovskiy", "value": "C104974"},
                 {"label": "Kamenetskiy", "value": "C104975"},
                 {"label": "Kazachka", "value": "C104976"},
                 {"label": "Khanino", "value": "C104977"},
                 {"label": "Kimovsk", "value": "C104978"},
                 {"label": "Kireyevsk", "value": "C104979"},
                 {"label": "Kosaya Gora", "value": "C104980"},
                 {"label": "Krapivna", "value": "C104981"},
                 {"label": "Kurkino", "value": "C104982"},
                 {"label": "Leninskiy", "value": "C104983"},
                 {"label": "Lomintsevskiy", "value": "C104984"},
                 {"label": "Mayskiy", "value": "C104985"},
                 {"label": "Mendeleyevskiy", "value": "C104986"},
                 {"label": "Novogurovskiy", "value": "C104987"},
                 {"label": "Novomoskovsk", "value": "C104988"},
                 {"label": "Odoyev", "value": "C104989"},
                 {"label": "Partizan", "value": "C104990"},
                 {"label": "Pervomayskiy", "value": "C104991"},
                 {"label": "Plavsk", "value": "C104992"},
                 {"label": "Podlesnyy", "value": "C104993"},
                 {"label": "Priupskiy", "value": "C104994"},
                 {"label": "Revyakino", "value": "C104995"},
                 {"label": "Rozhdestvenskiy", "value": "C104996"},
                 {"label": "Severo-Zadonsk", "value": "C104997"},
                 {"label": "Shchekino", "value": "C104998"},
                 {"label": "Shvartsevskiy", "value": "C104999"},
                 {"label": "Skuratovskiy", "value": "C105000"},
                 {"label": "Slavniy", "value": "C105001"},
                 {"label": "Suvorov", "value": "C105002"},
                 {"label": "Suvorovskiy Rayon", "value": "C105003"},
                 {"label": "Teplo-Ogarevskiy Rayon", "value": "C105004"},
                 {"label": "Teploye", "value": "C105005"},
                 {"label": "Tovarkovskiy", "value": "C105006"},
                 {"label": "Tula", "value": "C105007"},
                 {"label": "Uzlovaya", "value": "C105008"},
                 {"label": "Venev", "value": "C105009"},
                 {"label": "Venevskiy Rayon", "value": "C105010"},
                 {"label": "Volovo", "value": "C105011"},
                 {"label": "Yasnogorsk", "value": "C105012"},
                 {"label": "Yasnogorskiy Rayon", "value": "C105013"},
                 {"label": "Yefremov", "value": "C105014"},
                 {"label": "Yepifan", "value": "C105015"},
                 {"label": "Zaokskiy", "value": "C105016"},
                 {"label": "Zaokskiy Rayon", "value": "C105017"},
                 {"label": "Zhdankovskiy", "value": "C105018"}],
 "Tulcea County": [{"label": "Agighiol", "value": "C99472"},
                   {"label": "Babadag", "value": "C99473"},
                   {"label": "Baia", "value": "C99474"},
                   {"label": "Beidaud", "value": "C99475"},
                   {"label": "Bestepe", "value": "C99476"},
                   {"label": "C.A. Rosetti", "value": "C99477"},
                   {"label": "Carcaliu", "value": "C99478"},
                   {"label": "Casimcea", "value": "C99479"},
                   {"label": "Cataloi", "value": "C99480"},
                   {"label": "Ceamurlia de Jos", "value": "C99481"},
                   {"label": "Ceamurlia de Sus", "value": "C99482"},
                   {"label": "Ceatalchioi", "value": "C99483"},
                   {"label": "Cerna", "value": "C99484"},
                   {"label": "Chilia Veche", "value": "C99485"},
                   {"label": "Ciucurova", "value": "C99486"},
                   {"label": "Comuna Baia", "value": "C99487"},
                   {"label": "Comuna Beidaud", "value": "C99488"},
                   {"label": "Comuna Bestepe", "value": "C99489"},
                   {"label": "Comuna C.A. Rosetti", "value": "C99490"},
                   {"label": "Comuna Carcaliu", "value": "C99491"},
                   {"label": "Comuna Casimcea", "value": "C99492"},
                   {"label": "Comuna Ceamurlia de Jos", "value": "C99493"},
                   {"label": "Comuna Ceatalchioi", "value": "C99494"},
                   {"label": "Comuna Cerna", "value": "C99495"},
                   {"label": "Comuna Chilia Veche", "value": "C99496"},
                   {"label": "Comuna Ciucurova", "value": "C99497"},
                   {"label": "Comuna Crisan", "value": "C99498"},
                   {"label": "Comuna Daeni", "value": "C99499"},
                   {"label": "Comuna Dorobantu", "value": "C99500"},
                   {"label": "Comuna Frecatei", "value": "C99501"},
                   {"label": "Comuna Greci", "value": "C99502"},
                   {"label": "Comuna Grindu", "value": "C99503"},
                   {"label": "Comuna Hamcearca", "value": "C99504"},
                   {"label": "Comuna Horia", "value": "C99505"},
                   {"label": "Comuna I.C.Bratianu", "value": "C99506"},
                   {"label": "Comuna Izvoarele", "value": "C99507"},
                   {"label": "Comuna Jijila", "value": "C99508"},
                   {"label": "Comuna Jurilovca", "value": "C99509"},
                   {"label": "Comuna Luncavita", "value": "C99510"},
                   {"label": "Comuna Mahmudia", "value": "C99511"},
                   {"label": "Comuna Maliuc", "value": "C99512"},
                   {"label": "Comuna Mihai Bravu", "value": "C99513"},
                   {"label": "Comuna Mihail Kogalniceanu", "value": "C99514"},
                   {"label": "Comuna Murighiol", "value": "C99515"},
                   {"label": "Comuna Nalbant", "value": "C99516"},
                   {"label": "Comuna Niculitel", "value": "C99517"},
                   {"label": "Comuna Nufaru", "value": "C99518"},
                   {"label": "Comuna Ostrov", "value": "C99519"},
                   {"label": "Comuna Pardina", "value": "C99520"},
                   {"label": "Comuna Peceneaga", "value": "C99521"},
                   {"label": "Comuna Sarichioi", "value": "C99522"},
                   {"label": "Comuna Sfantu Gheorghe", "value": "C99523"},
                   {"label": "Comuna Slava Cercheza", "value": "C99524"},
                   {"label": "Comuna Smardan", "value": "C99525"},
                   {"label": "Comuna Somova", "value": "C99526"},
                   {"label": "Comuna Stejaru", "value": "C99527"},
                   {"label": "Comuna Topolog", "value": "C99528"},
                   {"label": "Comuna Turcoaia", "value": "C99529"},
                   {"label": "Comuna Vacareni", "value": "C99530"},
                   {"label": "Comuna Valea Nucarilor", "value": "C99531"},
                   {"label": "Comuna Valea Teilor", "value": "C99532"},
                   {"label": "Crisan", "value": "C99533"},
                   {"label": "Daeni", "value": "C99534"},
                   {"label": "Dorobantu", "value": "C99535"},
                   {"label": "Enisala", "value": "C99536"},
                   {"label": "Frecatei", "value": "C99537"},
                   {"label": "Garvan", "value": "C99538"},
                   {"label": "Greci", "value": "C99539"},
                   {"label": "Grindu", "value": "C99540"},
                   {"label": "Hamcearca", "value": "C99541"},
                   {"label": "Horia", "value": "C99542"},
                   {"label": "I. C. Bratianu", "value": "C99543"},
                   {"label": "Iazurile", "value": "C99544"},
                   {"label": "Isaccea", "value": "C99545"},
                   {"label": "Izvoarele", "value": "C99546"},
                   {"label": "Jijila", "value": "C99547"},
                   {"label": "Jurilovca", "value": "C99548"},
                   {"label": "Lunca", "value": "C99549"},
                   {"label": "Luncavita", "value": "C99550"},
                   {"label": "Macin", "value": "C99551"},
                   {"label": "Mahmudia", "value": "C99552"},
                   {"label": "Malcoci", "value": "C99553"},
                   {"label": "Maliuc", "value": "C99554"},
                   {"label": "Mihai Bravu", "value": "C99555"},
                   {"label": "Mihail Kogalniceanu", "value": "C99556"},
                   {"label": "Mineri", "value": "C99557"},
                   {"label": "Municipiul Tulcea", "value": "C99558"},
                   {"label": "Murighiol", "value": "C99559"},
                   {"label": "Nalbant", "value": "C99560"},
                   {"label": "Nicolae Balcescu", "value": "C99561"},
                   {"label": "Niculitel", "value": "C99562"},
                   {"label": "Nufaru", "value": "C99563"},
                   {"label": "Oras Babadag", "value": "C99564"},
                   {"label": "Oras Isaccea", "value": "C99565"},
                   {"label": "Oras Macin", "value": "C99566"},
                   {"label": "Oras Sulina", "value": "C99567"},
                   {"label": "Ostrov", "value": "C99568"},
                   {"label": "Pardina", "value": "C99569"},
                   {"label": "Peceneaga", "value": "C99570"},
                   {"label": "Rachelu", "value": "C99571"},
                   {"label": "Salcioara", "value": "C99572"},
                   {"label": "Sarichioi", "value": "C99573"},
                   {"label": "Sfantu Gheorghe", "value": "C99574"},
                   {"label": "Slava Cercheza", "value": "C99575"},
                   {"label": "Slava Rusa", "value": "C99576"},
                   {"label": "Smardan", "value": "C99577"},
                   {"label": "Somova", "value": "C99578"},
                   {"label": "Stejaru", "value": "C99579"},
                   {"label": "Sulina", "value": "C99580"},
                   {"label": "Topolog", "value": "C99581"},
                   {"label": "Traian", "value": "C99582"},
                   {"label": "Tulcea", "value": "C99583"},
                   {"label": "Turcoaia", "value": "C99584"},
                   {"label": "Turda", "value": "C99585"},
                   {"label": "Vacareni", "value": "C99586"},
                   {"label": "Valea Nucarilor", "value": "C99587"},
                   {"label": "Valea Teilor", "value": "C99588"},
                   {"label": "Zebil", "value": "C99589"}],
 "Tumbes": [{"label": "Aguas Verdes", "value": "C81096"},
            {"label": "Papayal", "value": "C81097"},
            {"label": "Provincia de Contralmirante Villar", "value": "C81098"},
            {"label": "Provincia de Tumbes", "value": "C81099"},
            {"label": "Provincia de Zarumilla", "value": "C81100"},
            {"label": "Tumbes", "value": "C81101"},
            {"label": "Zarumilla", "value": "C81102"},
            {"label": "Zorritos", "value": "C81103"}],
 "Tunapuna-Piarco Regional Corporation": [{"label": "Arouca",
                                           "value": "C119676"},
                                          {"label": "Paradise",
                                           "value": "C119677"},
                                          {"label": "Tunapuna",
                                           "value": "C119678"}],
 "Tunceli": [{"label": "Cemisgezek Ilcesi", "value": "C121088"},
             {"label": "Hozat", "value": "C121089"},
             {"label": "Mazgirt", "value": "C121090"},
             {"label": "Merkez", "value": "C121091"},
             {"label": "Nazimiye", "value": "C121092"},
             {"label": "Nazimiye Ilcesi", "value": "C121093"},
             {"label": "Ovacik", "value": "C121094"},
             {"label": "Pertek", "value": "C121095"},
             {"label": "Pulumer", "value": "C121096"},
             {"label": "Pulumur Ilcesi", "value": "C121097"},
             {"label": "Tunceli", "value": "C121098"}],
 "Tungurahua": [{"label": "Ambato", "value": "C24201"},
                {"label": "Banos", "value": "C24202"},
                {"label": "Pelileo", "value": "C24203"},
                {"label": "Pillaro", "value": "C24204"}],
 "Tunis Governorate": [{"label": "Al Marsa", "value": "C119806"},
                       {"label": "Carthage", "value": "C119807"},
                       {"label": "La Goulette", "value": "C119808"},
                       {"label": "La Mohammedia", "value": "C119809"},
                       {"label": "Sidi Bou Said", "value": "C119810"},
                       {"label": "Tunis", "value": "C119811"}],
 "Turkana": [{"label": "Lodwar", "value": "C64639"}],
 "Turkestan Region": [{"label": "Arys", "value": "C64498"},
                      {"label": "Ashchysay", "value": "C64499"},
                      {"label": "Asyqata", "value": "C64500"},
                      {"label": "Atakent", "value": "C64501"},
                      {"label": "Bayzhansay", "value": "C64502"},
                      {"label": "Belyye Vody", "value": "C64503"},
                      {"label": "Chardara", "value": "C64504"},
                      {"label": "Chayan", "value": "C64505"},
                      {"label": "Chulakkurgan", "value": "C64506"},
                      {"label": "Kantagi", "value": "C64507"},
                      {"label": "Kentau", "value": "C64508"},
                      {"label": "Kokterek", "value": "C64509"},
                      {"label": "Lenger", "value": "C64510"},
                      {"label": "Leninskoye", "value": "C64511"},
                      {"label": "Maqtaaral Audany", "value": "C64512"},
                      {"label": "Myrzakent", "value": "C64513"},
                      {"label": "Qogham", "value": "C64514"},
                      {"label": "Saryaghash", "value": "C64515"},
                      {"label": "Saryaghash Audany", "value": "C64516"},
                      {"label": "Sastobe", "value": "C64517"},
                      {"label": "Sozaq Audany", "value": "C64518"},
                      {"label": "Temirlanovka", "value": "C64519"},
                      {"label": "Turar Ryskulov", "value": "C64520"},
                      {"label": "Turkestan", "value": "C64521"},
                      {"label": "Tyulkubas", "value": "C64522"},
                      {"label": "Zhabagly", "value": "C64523"}],
 "Turnisce Municipality": [{"label": "Turnisce", "value": "C107846"}],
 "Tuscany": [{"label": "Abbadia San Salvatore", "value": "C60233"},
             {"label": "Abetone", "value": "C60234"},
             {"label": "Acquaviva", "value": "C60235"},
             {"label": "Agliana", "value": "C60236"},
             {"label": "Albiano Magra", "value": "C60237"},
             {"label": "Albinia", "value": "C60238"},
             {"label": "Altopascio", "value": "C60239"},
             {"label": "Ambra", "value": "C60240"},
             {"label": "Anchione", "value": "C60241"},
             {"label": "Anghiari", "value": "C60242"},
             {"label": "Antella", "value": "C60243"},
             {"label": "Arbia", "value": "C60244"},
             {"label": "Arcidosso", "value": "C60245"},
             {"label": "Arezzo", "value": "C60246"},
             {"label": "Asciano", "value": "C60247"},
             {"label": "Aulla", "value": "C60248"},
             {"label": "Avane", "value": "C60249"},
             {"label": "Badia al Pino", "value": "C60250"},
             {"label": "Badia Tedalda", "value": "C60251"},
             {"label": "Bagni di Lucca", "value": "C60252"},
             {"label": "Bagno a Ripoli", "value": "C60253"},
             {"label": "Bagno Roselle", "value": "C60254"},
             {"label": "Bagnone", "value": "C60255"},
             {"label": "Barba", "value": "C60256"},
             {"label": "Barbarasco", "value": "C60257"},
             {"label": "Barberino di Mugello", "value": "C60258"},
             {"label": "Barberino Val dElsa", "value": "C60259"},
             {"label": "Barga", "value": "C60260"},
             {"label": "Battifolle-Ruscello-Poggiola", "value": "C60261"},
             {"label": "Bellavista", "value": "C60262"},
             {"label": "Belverde", "value": "C60263"},
             {"label": "Bettolle", "value": "C60264"},
             {"label": "Bibbiena", "value": "C60265"},
             {"label": "Bibbona", "value": "C60266"},
             {"label": "Bientina", "value": "C60267"},
             {"label": "Borghetto-Melara", "value": "C60268"},
             {"label": "Borgo a Buggiano", "value": "C60269"},
             {"label": "Borgo a Mozzano", "value": "C60270"},
             {"label": "Borgo San Lorenzo", "value": "C60271"},
             {"label": "Braccagni", "value": "C60272"},
             {"label": "Bucine", "value": "C60273"},
             {"label": "Buonconvento", "value": "C60274"},
             {"label": "Buti", "value": "C60275"},
             {"label": "Calci", "value": "C60276"},
             {"label": "Calcinaia", "value": "C60277"},
             {"label": "Caldine", "value": "C60278"},
             {"label": "Calenzano", "value": "C60279"},
             {"label": "Camaiore", "value": "C60280"},
             {"label": "Campagnatico", "value": "C60281"},
             {"label": "Campi Bisenzio", "value": "C60282"},
             {"label": "Campiglia Marittima", "value": "C60283"},
             {"label": "Campo", "value": "C60284"},
             {"label": "Campo nellElba", "value": "C60285"},
             {"label": "Campo Tizzoro", "value": "C60286"},
             {"label": "Camporgiano", "value": "C60287"},
             {"label": "Camucia-Monsigliolo", "value": "C60288"},
             {"label": "Cantagallo", "value": "C60289"},
             {"label": "Cantagrillo-Casalguidi", "value": "C60290"},
             {"label": "Capalbio", "value": "C60291"},
             {"label": "Capanne-Prato-Cinquale", "value": "C60292"},
             {"label": "Capannoli", "value": "C60293"},
             {"label": "Capannori", "value": "C60294"},
             {"label": "Capoliveri", "value": "C60295"},
             {"label": "Capolona", "value": "C60296"},
             {"label": "Capraia e Limite", "value": "C60297"},
             {"label": "Capraia Isola", "value": "C60298"},
             {"label": "Caprese Michelangelo", "value": "C60299"},
             {"label": "Careggine", "value": "C60300"},
             {"label": "Carmignano", "value": "C60301"},
             {"label": "Carraia", "value": "C60302"},
             {"label": "Carrara", "value": "C60303"},
             {"label": "Casale Marittimo", "value": "C60304"},
             {"label": "Casciana Terme", "value": "C60305"},
             {"label": "Cascina", "value": "C60306"},
             {"label": "Cascine-La Croce", "value": "C60307"},
             {"label": "Casola in Lunigiana", "value": "C60308"},
             {"label": "Casole dElsa", "value": "C60309"},
             {"label": "Castagneto Carducci", "value": "C60310"},
             {"label": "Castel del Piano", "value": "C60311"},
             {"label": "Castel San Niccolo", "value": "C60312"},
             {"label": "Castelfiorentino", "value": "C60313"},
             {"label": "Castelfranco di Sopra", "value": "C60314"},
             {"label": "Castelfranco di Sotto", "value": "C60315"},
             {"label": "CastellAzzara", "value": "C60316"},
             {"label": "Castellina in Chianti", "value": "C60317"},
             {"label": "Castellina Marittima", "value": "C60318"},
             {"label": "Castellina Scalo", "value": "C60319"},
             {"label": "Castelnuovo Berardenga", "value": "C60320"},
             {"label": "Castelnuovo dei Sabbioni", "value": "C60321"},
             {"label": "Castelnuovo di Garfagnana", "value": "C60322"},
             {"label": "Castelnuovo di Val di Cecina", "value": "C60323"},
             {"label": "Castiglion Fibocchi", "value": "C60324"},
             {"label": "Castiglion Fiorentino", "value": "C60325"},
             {"label": "Castiglione dOrcia", "value": "C60326"},
             {"label": "Castiglione della Pescaia", "value": "C60327"},
             {"label": "Castiglione di Garfagnana", "value": "C60328"},
             {"label": "Catena", "value": "C60329"},
             {"label": "Cavallina", "value": "C60330"},
             {"label": "Cavriglia-Monastero", "value": "C60331"},
             {"label": "Cecina", "value": "C60332"},
             {"label": "Cenaia", "value": "C60333"},
             {"label": "Cerbaia", "value": "C60334"},
             {"label": "Cerreto Guidi", "value": "C60335"},
             {"label": "Certaldo", "value": "C60336"},
             {"label": "Cesa", "value": "C60337"},
             {"label": "Cetona", "value": "C60338"},
             {"label": "Chianciano Terme", "value": "C60339"},
             {"label": "Chianni", "value": "C60340"},
             {"label": "Chiassa-Tregozzano", "value": "C60341"},
             {"label": "Chiesina Uzzanese", "value": "C60342"},
             {"label": "Chiesino-Collodi", "value": "C60343"},
             {"label": "Chitignano", "value": "C60344"},
             {"label": "Chiusdino", "value": "C60345"},
             {"label": "Chiusi", "value": "C60346"},
             {"label": "Chiusi della Verna", "value": "C60347"},
             {"label": "Chiusi Scalo", "value": "C60348"},
             {"label": "Cinigiano", "value": "C60349"},
             {"label": "Cintolese", "value": "C60350"},
             {"label": "Civitella in Val di Chiana", "value": "C60351"},
             {"label": "Civitella Marittima", "value": "C60352"},
             {"label": "Colle di Val dElsa", "value": "C60353"},
             {"label": "Collesalvetti", "value": "C60354"},
             {"label": "Comano", "value": "C60355"},
             {"label": "Comeana", "value": "C60356"},
             {"label": "Compiobbi", "value": "C60357"},
             {"label": "Coreglia Antelminelli", "value": "C60358"},
             {"label": "Corsanico-Bargecchia", "value": "C60359"},
             {"label": "Cortona", "value": "C60360"},
             {"label": "Crespina", "value": "C60361"},
             {"label": "Cutigliano", "value": "C60362"},
             {"label": "Dicomano", "value": "C60363"},
             {"label": "Donoratico", "value": "C60364"},
             {"label": "Empoli", "value": "C60365"},
             {"label": "Fabbriche di Vallico", "value": "C60366"},
             {"label": "Faella", "value": "C60367"},
             {"label": "Fauglia", "value": "C60368"},
             {"label": "Fiesole", "value": "C60369"},
             {"label": "Figline Valdarno", "value": "C60370"},
             {"label": "Filattiera", "value": "C60371"},
             {"label": "Filettole", "value": "C60372"},
             {"label": "Firenzuola", "value": "C60373"},
             {"label": "Fivizzano", "value": "C60374"},
             {"label": "Florence", "value": "C60375"},
             {"label": "Foiano della Chiana", "value": "C60376"},
             {"label": "Follonica", "value": "C60377"},
             {"label": "Fontana delle Monache", "value": "C60378"},
             {"label": "Fonteblanda", "value": "C60379"},
             {"label": "Forcoli", "value": "C60380"},
             {"label": "Fornacelle", "value": "C60381"},
             {"label": "Fornacette", "value": "C60382"},
             {"label": "Fornaci di Barga", "value": "C60383"},
             {"label": "Forte dei Marmi", "value": "C60384"},
             {"label": "Fosciandora", "value": "C60385"},
             {"label": "Fosdinovo", "value": "C60386"},
             {"label": "Fucecchio", "value": "C60387"},
             {"label": "Gabella", "value": "C60388"},
             {"label": "Gaiole in Chianti", "value": "C60389"},
             {"label": "Galliano", "value": "C60390"},
             {"label": "Gallicano", "value": "C60391"},
             {"label": "Gambassi Terme", "value": "C60392"},
             {"label": "Gavorrano", "value": "C60393"},
             {"label": "Gello", "value": "C60394"},
             {"label": "Giglio Castello", "value": "C60395"},
             {"label": "Ginestra Fiorentina", "value": "C60396"},
             {"label": "Giovi-Ponte alla Chiassa", "value": "C60397"},
             {"label": "Giuncugnano", "value": "C60398"},
             {"label": "Grassina Ponte a Ema", "value": "C60399"},
             {"label": "Greve in Chianti", "value": "C60400"},
             {"label": "Grosseto", "value": "C60401"},
             {"label": "Guardistallo", "value": "C60402"},
             {"label": "Guasticce", "value": "C60403"},
             {"label": "Guazzino", "value": "C60404"},
             {"label": "Il Romito", "value": "C60405"},
             {"label": "Impruneta", "value": "C60406"},
             {"label": "Incisa in Val dArno", "value": "C60407"},
             {"label": "Isola DArbia", "value": "C60408"},
             {"label": "Isola del Giglio", "value": "C60409"},
             {"label": "Istia DOmbrone-Le Stiacciole", "value": "C60410"},
             {"label": "La California", "value": "C60411"},
             {"label": "La Pieve-Molino", "value": "C60412"},
             {"label": "La Rotta", "value": "C60413"},
             {"label": "Lajatico", "value": "C60414"},
             {"label": "Lamporecchio", "value": "C60415"},
             {"label": "Larciano", "value": "C60416"},
             {"label": "Lari", "value": "C60417"},
             {"label": "Lastra a Signa", "value": "C60418"},
             {"label": "Laterina", "value": "C60419"},
             {"label": "Lazzeretto", "value": "C60420"},
             {"label": "Le Casine-Perignano-Spinelli", "value": "C60421"},
             {"label": "Licciana Nardi", "value": "C60422"},
             {"label": "Limite", "value": "C60423"},
             {"label": "Livorno", "value": "C60424"},
             {"label": "Londa", "value": "C60425"},
             {"label": "Lorenzana", "value": "C60426"},
             {"label": "Loro Ciuffenna", "value": "C60427"},
             {"label": "Lucca", "value": "C60428"},
             {"label": "Lucignano", "value": "C60429"},
             {"label": "Luco Mugello", "value": "C60430"},
             {"label": "Madonna dellAcqua", "value": "C60431"},
             {"label": "Magliano in Toscana", "value": "C60432"},
             {"label": "Malmantile", "value": "C60433"},
             {"label": "Manciano", "value": "C60434"},
             {"label": "Mantignano-Ugnano", "value": "C60435"},
             {"label": "Marciana", "value": "C60436"},
             {"label": "Marciana Marina", "value": "C60437"},
             {"label": "Marciano", "value": "C60438"},
             {"label": "Marciano della Chiana", "value": "C60439"},
             {"label": "Marcignana", "value": "C60440"},
             {"label": "Margine Coperta-Traversagna", "value": "C60441"},
             {"label": "Marina di Campo", "value": "C60442"},
             {"label": "Marina di Carrara", "value": "C60443"},
             {"label": "Marina di Grosseto", "value": "C60444"},
             {"label": "Marina di Massa", "value": "C60445"},
             {"label": "Marina di Pisa", "value": "C60446"},
             {"label": "Marina di Pisa-Tirrenia-Calambrone", "value": "C60447"},
             {"label": "Marliana", "value": "C60448"},
             {"label": "Marradi", "value": "C60449"},
             {"label": "Marti", "value": "C60450"},
             {"label": "Massa", "value": "C60451"},
             {"label": "Massa Marittima", "value": "C60452"},
             {"label": "Massarosa", "value": "C60453"},
             {"label": "Mercatale", "value": "C60454"},
             {"label": "Mercatale-San Quirico", "value": "C60455"},
             {"label": "Metato", "value": "C60456"},
             {"label": "Migliarino", "value": "C60457"},
             {"label": "Minucciano", "value": "C60458"},
             {"label": "Molazzana", "value": "C60459"},
             {"label": "Molino del Piano", "value": "C60460"},
             {"label": "Monsummano Terme", "value": "C60461"},
             {"label": "Montaione", "value": "C60462"},
             {"label": "Montalcino", "value": "C60463"},
             {"label": "Montale", "value": "C60464"},
             {"label": "Montalto", "value": "C60465"},
             {"label": "Monte Argentario", "value": "C60466"},
             {"label": "Monte San Savino", "value": "C60467"},
             {"label": "Montebonello", "value": "C60468"},
             {"label": "Montecalvoli", "value": "C60469"},
             {"label": "Montecarlo", "value": "C60470"},
             {"label": "Montecatini Val di Cecina", "value": "C60471"},
             {"label": "Montecatini-Terme", "value": "C60472"},
             {"label": "Montelupo Fiorentino", "value": "C60473"},
             {"label": "Montemignaio", "value": "C60474"},
             {"label": "Montemurlo", "value": "C60475"},
             {"label": "Montepulciano", "value": "C60476"},
             {"label": "Montepulciano Stazione", "value": "C60477"},
             {"label": "Monterchi", "value": "C60478"},
             {"label": "Monteriggioni", "value": "C60479"},
             {"label": "Monteroni dArbia", "value": "C60480"},
             {"label": "Monterotondo Marittimo", "value": "C60481"},
             {"label": "Montescudaio", "value": "C60482"},
             {"label": "Montespertoli", "value": "C60483"},
             {"label": "Montevarchi", "value": "C60484"},
             {"label": "Monteverdi Marittimo", "value": "C60485"},
             {"label": "Monti", "value": "C60486"},
             {"label": "Monticiano", "value": "C60487"},
             {"label": "Montieri", "value": "C60488"},
             {"label": "Montignoso", "value": "C60489"},
             {"label": "Montopoli", "value": "C60490"},
             {"label": "Mulazzo", "value": "C60491"},
             {"label": "Murlo", "value": "C60492"},
             {"label": "Orbetello", "value": "C60493"},
             {"label": "Orbetello Scalo", "value": "C60494"},
             {"label": "Orciano Pisano", "value": "C60495"},
             {"label": "Orentano", "value": "C60496"},
             {"label": "Ortignano Raggiolo", "value": "C60497"},
             {"label": "Orzignano", "value": "C60498"},
             {"label": "Palaia", "value": "C60499"},
             {"label": "Palazzuolo sul Senio", "value": "C60500"},
             {"label": "Pallerone", "value": "C60501"},
             {"label": "Panzano in Chianti", "value": "C60502"},
             {"label": "Patigno", "value": "C60503"},
             {"label": "Peccioli", "value": "C60504"},
             {"label": "Pelago", "value": "C60505"},
             {"label": "Pergine Valdarno", "value": "C60506"},
             {"label": "Pescaglia", "value": "C60507"},
             {"label": "Pescia", "value": "C60508"},
             {"label": "Pian di Mugnone", "value": "C60509"},
             {"label": "Pian di Sco", "value": "C60510"},
             {"label": "Piancastagnaio", "value": "C60511"},
             {"label": "Piano di Conca", "value": "C60512"},
             {"label": "Piano di Coreglia-Ghivizzano", "value": "C60513"},
             {"label": "Piano di Mommio", "value": "C60514"},
             {"label": "Piazza al Serchio-San Michele", "value": "C60515"},
             {"label": "Pienza", "value": "C60516"},
             {"label": "Pietrasanta", "value": "C60517"},
             {"label": "Pieve a Nievole", "value": "C60518"},
             {"label": "Pieve al Toppo", "value": "C60519"},
             {"label": "Pieve Fosciana", "value": "C60520"},
             {"label": "Pieve Santo Stefano", "value": "C60521"},
             {"label": "Piombino", "value": "C60522"},
             {"label": "Pisa", "value": "C60523"},
             {"label": "Pistoia", "value": "C60524"},
             {"label": "Piteglio", "value": "C60525"},
             {"label": "Pitigliano", "value": "C60526"},
             {"label": "Podenzana", "value": "C60527"},
             {"label": "Poggetto", "value": "C60528"},
             {"label": "Poggibonsi", "value": "C60529"},
             {"label": "Poggio A Caiano", "value": "C60530"},
             {"label": "Pomarance", "value": "C60531"},
             {"label": "Ponsacco", "value": "C60532"},
             {"label": "Pontasserchio", "value": "C60533"},
             {"label": "Pontassieve", "value": "C60534"},
             {"label": "Ponte A Elsa", "value": "C60535"},
             {"label": "Ponte a Poppi", "value": "C60536"},
             {"label": "Ponte A Tressa", "value": "C60537"},
             {"label": "Ponte Buggianese", "value": "C60538"},
             {"label": "Pontedera", "value": "C60539"},
             {"label": "Pontestazzemese", "value": "C60540"},
             {"label": "Ponticino", "value": "C60541"},
             {"label": "Pontremoli", "value": "C60542"},
             {"label": "Poppi", "value": "C60543"},
             {"label": "Porcari", "value": "C60544"},
             {"label": "Porto Azzurro", "value": "C60545"},
             {"label": "Porto Ercole", "value": "C60546"},
             {"label": "Porto Santo Stefano", "value": "C60547"},
             {"label": "Portoferraio", "value": "C60548"},
             {"label": "Pozzale-Case Nuove", "value": "C60549"},
             {"label": "Pratantico-Indicatore", "value": "C60550"},
             {"label": "Prato", "value": "C60551"},
             {"label": "Pratovecchio", "value": "C60552"},
             {"label": "Province of Arezzo", "value": "C60553"},
             {"label": "Province of Florence", "value": "C60554"},
             {"label": "Province of Pisa", "value": "C60555"},
             {"label": "Provincia di Grosseto", "value": "C60556"},
             {"label": "Provincia di Livorno", "value": "C60557"},
             {"label": "Provincia di Lucca", "value": "C60558"},
             {"label": "Provincia di Massa-Carrara", "value": "C60559"},
             {"label": "Provincia di Pistoia", "value": "C60560"},
             {"label": "Provincia di Prato", "value": "C60561"},
             {"label": "Provincia di Siena", "value": "C60562"},
             {"label": "Quarata", "value": "C60563"},
             {"label": "Quarrata", "value": "C60564"},
             {"label": "Quattro Strade", "value": "C60565"},
             {"label": "Quercianella", "value": "C60566"},
             {"label": "Radda in Chianti", "value": "C60567"},
             {"label": "Radicofani", "value": "C60568"},
             {"label": "Radicondoli", "value": "C60569"},
             {"label": "Rapolano Terme", "value": "C60570"},
             {"label": "Rassina", "value": "C60571"},
             {"label": "Reggello", "value": "C60572"},
             {"label": "Ribolla", "value": "C60573"},
             {"label": "Rignano sullArno", "value": "C60574"},
             {"label": "Rio Marina", "value": "C60575"},
             {"label": "Rio nellElba", "value": "C60576"},
             {"label": "Ripa-Pozzi-Querceta-Ponterosso", "value": "C60577"},
             {"label": "Riparbella", "value": "C60578"},
             {"label": "Roccalbegna", "value": "C60579"},
             {"label": "Roccastrada", "value": "C60580"},
             {"label": "Ronta", "value": "C60581"},
             {"label": "Rosia", "value": "C60582"},
             {"label": "Rosignano Marittimo", "value": "C60583"},
             {"label": "Rosignano Solvay-Castiglioncello", "value": "C60584"},
             {"label": "Rufina", "value": "C60585"},
             {"label": "S.P. in Palazzi", "value": "C60586"},
             {"label": "Saline", "value": "C60587"},
             {"label": "Sambuca", "value": "C60588"},
             {"label": "Sambuca Pistoiese", "value": "C60589"},
             {"label": "San Casciano dei Bagni", "value": "C60590"},
             {"label": "San Casciano in Val di Pesa", "value": "C60591"},
             {"label": "San Cipriano-S.Barbara-Centinale", "value": "C60592"},
             {"label": "San Donato", "value": "C60593"},
             {"label": "San Donnino", "value": "C60594"},
             {"label": "San Francesco", "value": "C60595"},
             {"label": "San Gimignano", "value": "C60596"},
             {"label": "San Giovanni dAsso", "value": "C60597"},
             {"label": "San Giovanni Valdarno", "value": "C60598"},
             {"label": "San Giuliano Terme", "value": "C60599"},
             {"label": "San Giustino Valdarno", "value": "C60600"},
             {"label": "San Godenzo", "value": "C60601"},
             {"label": "San Jacopo al Girone", "value": "C60602"},
             {"label": "San Marcello Pistoiese", "value": "C60603"},
             {"label": "San Martino in Freddana-Monsagrati", "value": "C60604"},
             {"label": "San Mauro", "value": "C60605"},
             {"label": "San Miniato", "value": "C60606"},
             {"label": "San Miniato Basso", "value": "C60607"},
             {"label": "San Pierino", "value": "C60608"},
             {"label": "San Piero a Sieve", "value": "C60609"},
             {"label": "San Pietro Belvedere", "value": "C60610"},
             {"label": "San Quirico dOrcia", "value": "C60611"},
             {"label": "San Rocco", "value": "C60612"},
             {"label": "San Romano", "value": "C60613"},
             {"label": "San Romano in Garfagnana", "value": "C60614"},
             {"label": "San Vincenzo", "value": "C60615"},
             {"label": "San Vito-Cerreto", "value": "C60616"},
             {"label": "Sansepolcro", "value": "C60617"},
             {"label": "SantAlbino", "value": "C60618"},
             {"label": "Santa Croce sullArno", "value": "C60619"},
             {"label": "Santa Fiora", "value": "C60620"},
             {"label": "Santa Luce", "value": "C60621"},
             {"label": "Santa Lucia", "value": "C60622"},
             {"label": "Santa Maria a Monte", "value": "C60623"},
             {"label": "Sarteano", "value": "C60624"},
             {"label": "Sassetta", "value": "C60625"},
             {"label": "Scandicci", "value": "C60626"},
             {"label": "Scansano", "value": "C60627"},
             {"label": "Scarlino", "value": "C60628"},
             {"label": "Scarlino Scalo", "value": "C60629"},
             {"label": "Scarperia", "value": "C60630"},
             {"label": "Seano", "value": "C60631"},
             {"label": "Seggiano", "value": "C60632"},
             {"label": "Selvatelle", "value": "C60633"},
             {"label": "Semproniano", "value": "C60634"},
             {"label": "Seravezza", "value": "C60635"},
             {"label": "Serravalle Pistoiese", "value": "C60636"},
             {"label": "Serre di Rapolano", "value": "C60637"},
             {"label": "Sestino", "value": "C60638"},
             {"label": "Sesto Fiorentino", "value": "C60639"},
             {"label": "Sieci", "value": "C60640"},
             {"label": "Siena", "value": "C60641"},
             {"label": "Signa", "value": "C60642"},
             {"label": "Sillano", "value": "C60643"},
             {"label": "Sinalunga", "value": "C60644"},
             {"label": "Soci", "value": "C60645"},
             {"label": "Sorano", "value": "C60646"},
             {"label": "Sovicille", "value": "C60647"},
             {"label": "Spianate", "value": "C60648"},
             {"label": "Spicchio-Sovigliana", "value": "C60649"},
             {"label": "Stabbia", "value": "C60650"},
             {"label": "Staggia", "value": "C60651"},
             {"label": "Stazione", "value": "C60652"},
             {"label": "Stazione Masotti", "value": "C60653"},
             {"label": "Stia", "value": "C60654"},
             {"label": "Stiava", "value": "C60655"},
             {"label": "Strada", "value": "C60656"},
             {"label": "Strada in Chianti", "value": "C60657"},
             {"label": "Strettoia", "value": "C60658"},
             {"label": "Subbiano", "value": "C60659"},
             {"label": "Suvereto", "value": "C60660"},
             {"label": "Talla", "value": "C60661"},
             {"label": "Tavarnelle Val di Pesa", "value": "C60662"},
             {"label": "Tavarnuzze", "value": "C60663"},
             {"label": "Taverne DArbia", "value": "C60664"},
             {"label": "Tegoleto", "value": "C60665"},
             {"label": "Terontola", "value": "C60666"},
             {"label": "Terranuova Bracciolini", "value": "C60667"},
             {"label": "Terrarossa", "value": "C60668"},
             {"label": "Terricciola", "value": "C60669"},
             {"label": "Torre del Lago Puccini", "value": "C60670"},
             {"label": "Torrenieri", "value": "C60671"},
             {"label": "Torrita di Siena", "value": "C60672"},
             {"label": "Treggiaia", "value": "C60673"},
             {"label": "Trequanda", "value": "C60674"},
             {"label": "Tresana", "value": "C60675"},
             {"label": "Uggia-Pazzera-Bizzarrino", "value": "C60676"},
             {"label": "Uliveto Terme", "value": "C60677"},
             {"label": "Uzzano", "value": "C60678"},
             {"label": "Vacchereccia", "value": "C60679"},
             {"label": "Vada", "value": "C60680"},
             {"label": "Vaglia", "value": "C60681"},
             {"label": "Vaiano", "value": "C60682"},
             {"label": "Val di Cava", "value": "C60683"},
             {"label": "Valdottavo", "value": "C60684"},
             {"label": "Valenzatico", "value": "C60685"},
             {"label": "Vecchiano-Nodica", "value": "C60686"},
             {"label": "Venturina", "value": "C60687"},
             {"label": "Vernio", "value": "C60688"},
             {"label": "Vescovado", "value": "C60689"},
             {"label": "Viareggio", "value": "C60690"},
             {"label": "Vicarello", "value": "C60691"},
             {"label": "Vicchio", "value": "C60692"},
             {"label": "Viciomaggio", "value": "C60693"},
             {"label": "Vicopisano", "value": "C60694"},
             {"label": "Vignale Riotorto", "value": "C60695"},
             {"label": "Vignole-Olmi", "value": "C60696"},
             {"label": "Villa Basilica", "value": "C60697"},
             {"label": "Villa Collemandina", "value": "C60698"},
             {"label": "Villafranca in Lunigiana", "value": "C60699"},
             {"label": "Vinci", "value": "C60700"},
             {"label": "Volterra", "value": "C60701"},
             {"label": "Zeri", "value": "C60702"}],
 "Tutong District": [{"label": "Tutong", "value": "C15845"}],
 "Tuva Republic": [{"label": "Ak-Dovurak", "value": "C105019"},
                   {"label": "Balgazyn", "value": "C105020"},
                   {"label": "Bay-Khaak", "value": "C105021"},
                   {"label": "Chadan", "value": "C105022"},
                   {"label": "Erzin", "value": "C105023"},
                   {"label": "Kaa-Khem", "value": "C105024"},
                   {"label": "Khandagayty", "value": "C105025"},
                   {"label": "Khovu-Aksy", "value": "C105026"},
                   {"label": "Kungurtug", "value": "C105027"},
                   {"label": "Kyzyl", "value": "C105028"},
                   {"label": "Kyzyl-Khaya", "value": "C105029"},
                   {"label": "Kyzyl-Mazhalyk", "value": "C105030"},
                   {"label": "Mugur-Aksy", "value": "C105031"},
                   {"label": "Samagaltay", "value": "C105032"},
                   {"label": "Saryg-Sep", "value": "C105033"},
                   {"label": "Shagonar", "value": "C105034"},
                   {"label": "Sukpak", "value": "C105035"},
                   {"label": "Sut-Khol", "value": "C105036"},
                   {"label": "Teeli", "value": "C105037"},
                   {"label": "Toora-Khem", "value": "C105038"},
                   {"label": "Turan", "value": "C105039"}],
 "Tuyen Quang": [{"label": "Huyen Chiem Hoa", "value": "C147700"},
                 {"label": "Huyen Ham Yen", "value": "C147701"},
                 {"label": "Huyen Lam Binh", "value": "C147702"},
                 {"label": "Huyen Na Hang", "value": "C147703"},
                 {"label": "Huyen Son Duong", "value": "C147704"},
                 {"label": "Huyen Yen Son", "value": "C147705"},
                 {"label": "Tuyen Quang", "value": "C147706"}],
 "Tver Oblast": [{"label": "Andreapol", "value": "C105040"},
                 {"label": "Andreapolskiy Rayon", "value": "C105041"},
                 {"label": "Belskiy Rayon", "value": "C105042"},
                 {"label": "Belyy", "value": "C105043"},
                 {"label": "Belyy Gorodok", "value": "C105044"},
                 {"label": "Berezayka", "value": "C105045"},
                 {"label": "Bernovo", "value": "C105046"},
                 {"label": "Bezhetsk", "value": "C105047"},
                 {"label": "Bezhetskiy Rayon", "value": "C105048"},
                 {"label": "Bologovskiy Rayon", "value": "C105049"},
                 {"label": "Bologoye", "value": "C105050"},
                 {"label": "Bologoye-4", "value": "C105051"},
                 {"label": "Emmaus", "value": "C105052"},
                 {"label": "Firovo", "value": "C105053"},
                 {"label": "Firovskiy Rayon", "value": "C105054"},
                 {"label": "Gorodnya", "value": "C105055"},
                 {"label": "Ilinskoye", "value": "C105056"},
                 {"label": "Izoplit", "value": "C105057"},
                 {"label": "Kalashnikovo", "value": "C105058"},
                 {"label": "Kalininskiy Rayon", "value": "C105059"},
                 {"label": "Kalyazin", "value": "C105060"},
                 {"label": "Kalyazinskiy Rayon", "value": "C105061"},
                 {"label": "Kashin", "value": "C105062"},
                 {"label": "Kashinskiy Rayon", "value": "C105063"},
                 {"label": "Kesova Gora", "value": "C105064"},
                 {"label": "Kesovogorskiy Rayon", "value": "C105065"},
                 {"label": "Kimrskiy Rayon", "value": "C105066"},
                 {"label": "Kimry", "value": "C105067"},
                 {"label": "Konakovo", "value": "C105068"},
                 {"label": "Konakovskiy Rayon", "value": "C105069"},
                 {"label": "Kozlovo", "value": "C105070"},
                 {"label": "Krasnokholmskiy Rayon", "value": "C105071"},
                 {"label": "Krasnomayskiy", "value": "C105072"},
                 {"label": "Krasnyy Kholm", "value": "C105073"},
                 {"label": "Kuvshinovo", "value": "C105074"},
                 {"label": "Kuvshinovskiy Rayon", "value": "C105075"},
                 {"label": "Kuzhenkino", "value": "C105076"},
                 {"label": "Lesnoy Rayon", "value": "C105077"},
                 {"label": "Lesnoye", "value": "C105078"},
                 {"label": "Likhoslavl", "value": "C105079"},
                 {"label": "Likhoslavlskiy Rayon", "value": "C105080"},
                 {"label": "Maksatikha", "value": "C105081"},
                 {"label": "Maksatikhinskiy Rayon", "value": "C105082"},
                 {"label": "Molokovo", "value": "C105083"},
                 {"label": "Nelidovo", "value": "C105084"},
                 {"label": "Novozavidovskiy", "value": "C105085"},
                 {"label": "Olenino", "value": "C105086"},
                 {"label": "Orsha", "value": "C105087"},
                 {"label": "Ostashkov", "value": "C105088"},
                 {"label": "Ostashkovskiy Rayon", "value": "C105089"},
                 {"label": "Peno", "value": "C105090"},
                 {"label": "Penovskiy Rayon", "value": "C105091"},
                 {"label": "Radchenko", "value": "C105092"},
                 {"label": "Rameshki", "value": "C105093"},
                 {"label": "Rameshkovskiy Rayon", "value": "C105094"},
                 {"label": "Redkino", "value": "C105095"},
                 {"label": "Rzhev", "value": "C105096"},
                 {"label": "Sakharovo", "value": "C105097"},
                 {"label": "Sandovo", "value": "C105098"},
                 {"label": "Sandovskiy Rayon", "value": "C105099"},
                 {"label": "Selishche", "value": "C105100"},
                 {"label": "Selizharovo", "value": "C105101"},
                 {"label": "Selizharovskiy Rayon", "value": "C105102"},
                 {"label": "Sonkovo", "value": "C105103"},
                 {"label": "Spirovo", "value": "C105104"},
                 {"label": "Spirovskiy Rayon", "value": "C105105"},
                 {"label": "Stantsia Staritsa", "value": "C105106"},
                 {"label": "Staraya Toropa", "value": "C105107"},
                 {"label": "Staritsa", "value": "C105108"},
                 {"label": "Staritskiy Rayon", "value": "C105109"},
                 {"label": "Toropets", "value": "C105110"},
                 {"label": "Toropetskiy Rayon", "value": "C105111"},
                 {"label": "Torzhok", "value": "C105112"},
                 {"label": "Torzhokskiy Rayon", "value": "C105113"},
                 {"label": "Tver", "value": "C105114"},
                 {"label": "Udomlya", "value": "C105115"},
                 {"label": "Vasilyevskiy Mokh", "value": "C105116"},
                 {"label": "Velikooktyabrskiy", "value": "C105117"},
                 {"label": "Vesyegonsk", "value": "C105118"},
                 {"label": "Vesyegonskiy Rayon", "value": "C105119"},
                 {"label": "Vydropuzhsk", "value": "C105120"},
                 {"label": "Vypolzovo", "value": "C105121"},
                 {"label": "Vyshnevolotskiy Rayon", "value": "C105122"},
                 {"label": "Vyshniy Volochek", "value": "C105123"},
                 {"label": "Zapadnaya Dvina", "value": "C105124"},
                 {"label": "Zavidovo", "value": "C105125"},
                 {"label": "Zharkovskiy", "value": "C105126"},
                 {"label": "Zubtsov", "value": "C105127"},
                 {"label": "Zubtsovskiy Rayon", "value": "C105128"}],
 "Tyrol": [{"label": "Abfaltersbach", "value": "C7607"},
           {"label": "Absam", "value": "C7608"},
           {"label": "Achenkirch", "value": "C7609"},
           {"label": "Ainet", "value": "C7610"},
           {"label": "Aldrans", "value": "C7611"},
           {"label": "Alpbach", "value": "C7612"},
           {"label": "Amlach", "value": "C7613"},
           {"label": "Ampass", "value": "C7614"},
           {"label": "Amras", "value": "C7615"},
           {"label": "Angath", "value": "C7616"},
           {"label": "Angerberg", "value": "C7617"},
           {"label": "Anras", "value": "C7618"},
           {"label": "Arzl", "value": "C7619"},
           {"label": "Arzl im Pitztal", "value": "C7620"},
           {"label": "Aschau", "value": "C7621"},
           {"label": "Aschau im Zillertal", "value": "C7622"},
           {"label": "Aurach", "value": "C7623"},
           {"label": "Ausservillgraten", "value": "C7624"},
           {"label": "Axams", "value": "C7625"},
           {"label": "Bad Haring", "value": "C7626"},
           {"label": "Barwies", "value": "C7627"},
           {"label": "Baumkirchen", "value": "C7628"},
           {"label": "Berwang", "value": "C7629"},
           {"label": "Biberwier", "value": "C7630"},
           {"label": "Bichlbach", "value": "C7631"},
           {"label": "Birgitz", "value": "C7632"},
           {"label": "Brandenberg", "value": "C7633"},
           {"label": "Breitenbach am Inn", "value": "C7634"},
           {"label": "Breitenwang", "value": "C7635"},
           {"label": "Brixen im Thale", "value": "C7636"},
           {"label": "Brixlegg", "value": "C7637"},
           {"label": "Bruck am Ziller", "value": "C7638"},
           {"label": "Buch in Tirol", "value": "C7639"},
           {"label": "Debant", "value": "C7640"},
           {"label": "Dolsach", "value": "C7641"},
           {"label": "Ebbs", "value": "C7642"},
           {"label": "Eben am Achensee", "value": "C7643"},
           {"label": "Ehenbichl", "value": "C7644"},
           {"label": "Ehrwald", "value": "C7645"},
           {"label": "Elbigenalp", "value": "C7646"},
           {"label": "Ellbogen", "value": "C7647"},
           {"label": "Ellmau", "value": "C7648"},
           {"label": "Elmen", "value": "C7649"},
           {"label": "Endach", "value": "C7650"},
           {"label": "Erl", "value": "C7651"},
           {"label": "Erpfendorf", "value": "C7652"},
           {"label": "Fendels", "value": "C7653"},
           {"label": "Fieberbrunn", "value": "C7654"},
           {"label": "Fiecht", "value": "C7655"},
           {"label": "Finkenberg", "value": "C7656"},
           {"label": "Fiss", "value": "C7657"},
           {"label": "Flaurling", "value": "C7658"},
           {"label": "Fliess", "value": "C7659"},
           {"label": "Flirsch", "value": "C7660"},
           {"label": "Forchach", "value": "C7661"},
           {"label": "Fritzens", "value": "C7662"},
           {"label": "Fugen", "value": "C7663"},
           {"label": "Fugenberg", "value": "C7664"},
           {"label": "Fulpmes", "value": "C7665"},
           {"label": "Gallzein", "value": "C7666"},
           {"label": "Galtur", "value": "C7667"},
           {"label": "Gerlos", "value": "C7668"},
           {"label": "Gerlosberg", "value": "C7669"},
           {"label": "Gnadenwald", "value": "C7670"},
           {"label": "Going", "value": "C7671"},
           {"label": "Gotzens", "value": "C7672"},
           {"label": "Gramais", "value": "C7673"},
           {"label": "Gran", "value": "C7674"},
           {"label": "Gries am Brenner", "value": "C7675"},
           {"label": "Gries im Sellrain", "value": "C7676"},
           {"label": "Grins", "value": "C7677"},
           {"label": "Grinzens", "value": "C7678"},
           {"label": "Gschnitz", "value": "C7679"},
           {"label": "Haiming", "value": "C7680"},
           {"label": "Hainzenberg", "value": "C7681"},
           {"label": "Hall in Tirol", "value": "C7682"},
           {"label": "Hart im Zillertal", "value": "C7683"},
           {"label": "Haselgehr", "value": "C7684"},
           {"label": "Hatting", "value": "C7685"},
           {"label": "Heinfels", "value": "C7686"},
           {"label": "Heiterwang", "value": "C7687"},
           {"label": "Hippach", "value": "C7688"},
           {"label": "Hochfilzen", "value": "C7689"},
           {"label": "Hofen", "value": "C7690"},
           {"label": "Holzgau", "value": "C7691"},
           {"label": "Hopfgarten im Brixental", "value": "C7692"},
           {"label": "Hopfgarten in Defereggen", "value": "C7693"},
           {"label": "Hotting", "value": "C7694"},
           {"label": "Igls", "value": "C7695"},
           {"label": "Imst", "value": "C7696"},
           {"label": "Imsterberg", "value": "C7697"},
           {"label": "Innervillgraten", "value": "C7698"},
           {"label": "Innsbruck", "value": "C7699"},
           {"label": "Innsbruck Stadt", "value": "C7700"},
           {"label": "Inzing", "value": "C7701"},
           {"label": "Ischgl", "value": "C7702"},
           {"label": "Itter", "value": "C7703"},
           {"label": "Jenbach", "value": "C7704"},
           {"label": "Jerzens", "value": "C7705"},
           {"label": "Jochberg", "value": "C7706"},
           {"label": "Jungholz", "value": "C7707"},
           {"label": "Kaltenbach", "value": "C7708"},
           {"label": "Kapfing", "value": "C7709"},
           {"label": "Kappl", "value": "C7710"},
           {"label": "Karres", "value": "C7711"},
           {"label": "Karrosten", "value": "C7712"},
           {"label": "Kartitsch", "value": "C7713"},
           {"label": "Kauns", "value": "C7714"},
           {"label": "Kematen in Tirol", "value": "C7715"},
           {"label": "Kirchberg in Tirol", "value": "C7716"},
           {"label": "Kirchbichl", "value": "C7717"},
           {"label": "Kirchdorf in Tirol", "value": "C7718"},
           {"label": "Kitzbuhel", "value": "C7719"},
           {"label": "Kolsass", "value": "C7720"},
           {"label": "Kolsassberg", "value": "C7721"},
           {"label": "Kossen", "value": "C7722"},
           {"label": "Kramsach", "value": "C7723"},
           {"label": "Kufstein", "value": "C7724"},
           {"label": "Kundl", "value": "C7725"},
           {"label": "Ladis", "value": "C7726"},
           {"label": "Landeck", "value": "C7727"},
           {"label": "Langenfeld", "value": "C7728"},
           {"label": "Lans", "value": "C7729"},
           {"label": "Lavant", "value": "C7730"},
           {"label": "Lechaschau", "value": "C7731"},
           {"label": "Leisach", "value": "C7732"},
           {"label": "Lermoos", "value": "C7733"},
           {"label": "Leutasch", "value": "C7734"},
           {"label": "Lienz", "value": "C7735"},
           {"label": "Mariastein", "value": "C7736"},
           {"label": "Mariatal", "value": "C7737"},
           {"label": "Matrei am Brenner", "value": "C7738"},
           {"label": "Matrei in Osttirol", "value": "C7739"},
           {"label": "Maurach", "value": "C7740"},
           {"label": "Mayrhofen", "value": "C7741"},
           {"label": "Medraz", "value": "C7742"},
           {"label": "Mieders", "value": "C7743"},
           {"label": "Mieming", "value": "C7744"},
           {"label": "Mils bei Imst", "value": "C7745"},
           {"label": "Mils bei Solbad Hall", "value": "C7746"},
           {"label": "Mitterndorf", "value": "C7747"},
           {"label": "Motz", "value": "C7748"},
           {"label": "Muhlau", "value": "C7749"},
           {"label": "Muhlbachl", "value": "C7750"},
           {"label": "Munster", "value": "C7751"},
           {"label": "Musau", "value": "C7752"},
           {"label": "Mutters", "value": "C7753"},
           {"label": "Namlos", "value": "C7754"},
           {"label": "Nassereith", "value": "C7755"},
           {"label": "Natters", "value": "C7756"},
           {"label": "Nauders", "value": "C7757"},
           {"label": "Nesselwangle", "value": "C7758"},
           {"label": "Neustift im Stubaital", "value": "C7759"},
           {"label": "Niederau", "value": "C7760"},
           {"label": "Niederbreitenbach", "value": "C7761"},
           {"label": "Niederndorf", "value": "C7762"},
           {"label": "Nikolsdorf", "value": "C7763"},
           {"label": "Oberau", "value": "C7764"},
           {"label": "Oberhofen im Inntal", "value": "C7765"},
           {"label": "Oberlienz", "value": "C7766"},
           {"label": "Obermieming", "value": "C7767"},
           {"label": "Obernberg am Brenner", "value": "C7768"},
           {"label": "Oberndorf in Tirol", "value": "C7769"},
           {"label": "Oberperfuss", "value": "C7770"},
           {"label": "Obertilliach", "value": "C7771"},
           {"label": "Obsteig", "value": "C7772"},
           {"label": "Oetz", "value": "C7773"},
           {"label": "Otztal-Bahnhof", "value": "C7774"},
           {"label": "Patsch", "value": "C7775"},
           {"label": "Pettnau", "value": "C7776"},
           {"label": "Pettneu", "value": "C7777"},
           {"label": "Pfaffenhofen", "value": "C7778"},
           {"label": "Pflach", "value": "C7779"},
           {"label": "Pfons", "value": "C7780"},
           {"label": "Pfunds", "value": "C7781"},
           {"label": "Pians", "value": "C7782"},
           {"label": "Pill", "value": "C7783"},
           {"label": "Politischer Bezirk Imst", "value": "C7784"},
           {"label": "Politischer Bezirk Innsbruck Land", "value": "C7785"},
           {"label": "Politischer Bezirk Kitzbuhel", "value": "C7786"},
           {"label": "Politischer Bezirk Kufstein", "value": "C7787"},
           {"label": "Politischer Bezirk Landeck", "value": "C7788"},
           {"label": "Politischer Bezirk Lienz", "value": "C7789"},
           {"label": "Politischer Bezirk Reutte", "value": "C7790"},
           {"label": "Politischer Bezirk Schwaz", "value": "C7791"},
           {"label": "Polling in Tirol", "value": "C7792"},
           {"label": "Pradl", "value": "C7793"},
           {"label": "Prutz", "value": "C7794"},
           {"label": "Radfeld", "value": "C7795"},
           {"label": "Ramsau im Zillertal", "value": "C7796"},
           {"label": "Ranggen", "value": "C7797"},
           {"label": "Rattenberg", "value": "C7798"},
           {"label": "Reith bei Kitzbuhel", "value": "C7799"},
           {"label": "Reith bei Seefeld", "value": "C7800"},
           {"label": "Reith im Alpbachtal", "value": "C7801"},
           {"label": "Rettenschoss", "value": "C7802"},
           {"label": "Reutte", "value": "C7803"},
           {"label": "Ried im Oberinntal", "value": "C7804"},
           {"label": "Ried im Zillertal", "value": "C7805"},
           {"label": "Rietz", "value": "C7806"},
           {"label": "Rinn", "value": "C7807"},
           {"label": "Rohrberg", "value": "C7808"},
           {"label": "Roppen", "value": "C7809"},
           {"label": "Rum", "value": "C7810"},
           {"label": "Sankt Johann in Tirol", "value": "C7811"},
           {"label": "Sankt Ulrich am Pillersee", "value": "C7812"},
           {"label": "Sankt Veit in Defereggen", "value": "C7813"},
           {"label": "Sautens", "value": "C7814"},
           {"label": "Scharnitz", "value": "C7815"},
           {"label": "Schattwald", "value": "C7816"},
           {"label": "Scheffau am Wilden Kaiser", "value": "C7817"},
           {"label": "Schlaiten", "value": "C7818"},
           {"label": "Schlitters", "value": "C7819"},
           {"label": "Schonberg im Stubaital", "value": "C7820"},
           {"label": "Schonwies", "value": "C7821"},
           {"label": "Schwaz", "value": "C7822"},
           {"label": "Schwendau", "value": "C7823"},
           {"label": "Schwendt", "value": "C7824"},
           {"label": "Schwoich", "value": "C7825"},
           {"label": "See", "value": "C7826"},
           {"label": "Seefeld in Tirol", "value": "C7827"},
           {"label": "Sellrain", "value": "C7828"},
           {"label": "Serfaus", "value": "C7829"},
           {"label": "Sillian", "value": "C7830"},
           {"label": "Silz", "value": "C7831"},
           {"label": "Sistrans", "value": "C7832"},
           {"label": "Solden", "value": "C7833"},
           {"label": "Soll", "value": "C7834"},
           {"label": "St Anton am Arlberg", "value": "C7835"},
           {"label": "Stams", "value": "C7836"},
           {"label": "Stans", "value": "C7837"},
           {"label": "Stanz bei Landeck", "value": "C7838"},
           {"label": "Stanzach", "value": "C7839"},
           {"label": "Steeg", "value": "C7840"},
           {"label": "Steinach am Brenner", "value": "C7841"},
           {"label": "Strass im Zillertal", "value": "C7842"},
           {"label": "Strassen", "value": "C7843"},
           {"label": "Strengen", "value": "C7844"},
           {"label": "Stumm", "value": "C7845"},
           {"label": "Stummerberg", "value": "C7846"},
           {"label": "Tannheim", "value": "C7847"},
           {"label": "Tarrenz", "value": "C7848"},
           {"label": "Telfes im Stubai", "value": "C7849"},
           {"label": "Telfs", "value": "C7850"},
           {"label": "Terfens", "value": "C7851"},
           {"label": "Thaur", "value": "C7852"},
           {"label": "Thurn", "value": "C7853"},
           {"label": "Tobadill", "value": "C7854"},
           {"label": "Trins", "value": "C7855"},
           {"label": "Tristach", "value": "C7856"},
           {"label": "Tulfes", "value": "C7857"},
           {"label": "Tux", "value": "C7858"},
           {"label": "Uderns", "value": "C7859"},
           {"label": "Umhausen", "value": "C7860"},
           {"label": "Unterlangkampfen", "value": "C7861"},
           {"label": "Unterperfuss", "value": "C7862"},
           {"label": "Untertilliach", "value": "C7863"},
           {"label": "Vals", "value": "C7864"},
           {"label": "Vils", "value": "C7865"},
           {"label": "Virgen", "value": "C7866"},
           {"label": "Volders", "value": "C7867"},
           {"label": "Voldopp", "value": "C7868"},
           {"label": "Vols", "value": "C7869"},
           {"label": "Vomp", "value": "C7870"},
           {"label": "Vorderhornbach", "value": "C7871"},
           {"label": "Vorderthiersee", "value": "C7872"},
           {"label": "Waidring", "value": "C7873"},
           {"label": "Walchsee", "value": "C7874"},
           {"label": "Wangle", "value": "C7875"},
           {"label": "Wattenberg", "value": "C7876"},
           {"label": "Wattens", "value": "C7877"},
           {"label": "Weer", "value": "C7878"},
           {"label": "Weerberg", "value": "C7879"},
           {"label": "Weissach", "value": "C7880"},
           {"label": "Weissenbach am Lech", "value": "C7881"},
           {"label": "Wenns", "value": "C7882"},
           {"label": "Westendorf", "value": "C7883"},
           {"label": "Wiesing", "value": "C7884"},
           {"label": "Wildermieming", "value": "C7885"},
           {"label": "Wildschonau", "value": "C7886"},
           {"label": "Wilten", "value": "C7887"},
           {"label": "Worgl", "value": "C7888"},
           {"label": "Zams", "value": "C7889"},
           {"label": "Zell", "value": "C7890"},
           {"label": "Zell am Ziller", "value": "C7891"},
           {"label": "Zellberg", "value": "C7892"},
           {"label": "Zirl", "value": "C7893"},
           {"label": "Zoblen", "value": "C7894"}],
 "Tyumen Oblast": [{"label": "Abalak", "value": "C105129"},
                   {"label": "Abatskoye", "value": "C105130"},
                   {"label": "Antipino", "value": "C105131"},
                   {"label": "Armizonskiy Rayon", "value": "C105132"},
                   {"label": "Armizonskoye", "value": "C105133"},
                   {"label": "Aromashevo", "value": "C105134"},
                   {"label": "Berdyuzhskiy Rayon", "value": "C105135"},
                   {"label": "Bogandinskiy", "value": "C105136"},
                   {"label": "Borovskiy", "value": "C105137"},
                   {"label": "Chervishevo", "value": "C105138"},
                   {"label": "Golyshmanovo", "value": "C105139"},
                   {"label": "Golyshmanovskiy Rayon", "value": "C105140"},
                   {"label": "Irtyshskiy", "value": "C105141"},
                   {"label": "Isetskiy Rayon", "value": "C105142"},
                   {"label": "Isetskoye", "value": "C105143"},
                   {"label": "Ishim", "value": "C105144"},
                   {"label": "Ishimskiy Rayon", "value": "C105145"},
                   {"label": "Kazanskiy Rayon", "value": "C105146"},
                   {"label": "Kazanskoye", "value": "C105147"},
                   {"label": "Melioratorov", "value": "C105148"},
                   {"label": "Mendeleyevo", "value": "C105149"},
                   {"label": "Moskovskiy", "value": "C105150"},
                   {"label": "Nizhnyaya Tavda", "value": "C105151"},
                   {"label": "Novaya Zaimka", "value": "C105152"},
                   {"label": "Novoseleznevo", "value": "C105153"},
                   {"label": "Omutinskiy", "value": "C105154"},
                   {"label": "Omutinskiy Rayon", "value": "C105155"},
                   {"label": "Onokhino", "value": "C105156"},
                   {"label": "Sladkovo", "value": "C105157"},
                   {"label": "Sladkovskiy Rayon", "value": "C105158"},
                   {"label": "Sumkino", "value": "C105159"},
                   {"label": "Tobolsk", "value": "C105160"},
                   {"label": "Turtas", "value": "C105161"},
                   {"label": "Tyumen", "value": "C105162"},
                   {"label": "Uporovo", "value": "C105163"},
                   {"label": "Uporovskiy Rayon", "value": "C105164"},
                   {"label": "Uspenka", "value": "C105165"},
                   {"label": "Uvat", "value": "C105166"},
                   {"label": "Vagay", "value": "C105167"},
                   {"label": "Vagayskiy Rayon", "value": "C105168"},
                   {"label": "Vikulovo", "value": "C105169"},
                   {"label": "Vinzili", "value": "C105170"},
                   {"label": "Yalutorovsk", "value": "C105171"},
                   {"label": "Yarkovo", "value": "C105172"},
                   {"label": "Yurginskoye", "value": "C105173"},
                   {"label": "Zavodoukovsk", "value": "C105174"}],
 "Uaboe District": [{"label": "Uaboe", "value": "C76017"}],
 "Uasin Gishu": [{"label": "Eldoret", "value": "C64640"}],
 "Ubon Ratchathani": [{"label": "Amphoe Buntharik", "value": "C119504"},
                      {"label": "Amphoe Det Udom", "value": "C119505"},
                      {"label": "Amphoe Don Mot Daeng", "value": "C119506"},
                      {"label": "Amphoe Khemmarat", "value": "C119507"},
                      {"label": "Amphoe Khong Chiam", "value": "C119508"},
                      {"label": "Amphoe Khueang Nai", "value": "C119509"},
                      {"label": "Amphoe Kut Khaopun", "value": "C119510"},
                      {"label": "Amphoe Lao Suea Kok", "value": "C119511"},
                      {"label": "Amphoe Muang Sam Sip", "value": "C119512"},
                      {"label": "Amphoe Mueang Ubon Ratchathani",
                       "value": "C119513"},
                      {"label": "Amphoe Na Chaluai", "value": "C119514"},
                      {"label": "Amphoe Na Tan", "value": "C119515"},
                      {"label": "Amphoe Na Yia", "value": "C119516"},
                      {"label": "Amphoe Nam Khun", "value": "C119517"},
                      {"label": "Amphoe Nam Yuen", "value": "C119518"},
                      {"label": "Amphoe Phibun Mangsahan", "value": "C119519"},
                      {"label": "Amphoe Pho Sai", "value": "C119520"},
                      {"label": "Amphoe Samrong", "value": "C119521"},
                      {"label": "Amphoe Sawang Wirawong", "value": "C119522"},
                      {"label": "Amphoe Si Mueang Mai", "value": "C119523"},
                      {"label": "Amphoe Sirindhorn", "value": "C119524"},
                      {"label": "Amphoe Tan Sum", "value": "C119525"},
                      {"label": "Amphoe Thung Si Udom", "value": "C119526"},
                      {"label": "Amphoe Trakan Phuet Phon", "value": "C119527"},
                      {"label": "Amphoe Warin Chamrap", "value": "C119528"},
                      {"label": "Det Udom", "value": "C119529"},
                      {"label": "Phibun Mangsahan", "value": "C119530"},
                      {"label": "Sawang Wirawong", "value": "C119531"},
                      {"label": "Ubon Ratchathani", "value": "C119532"},
                      {"label": "Warin Chamrap", "value": "C119533"}],
 "Ucayali": [{"label": "Atalaya", "value": "C81104"},
             {"label": "Campoverde", "value": "C81105"},
             {"label": "Padre Abad", "value": "C81106"},
             {"label": "Provincia de Coronel Portillo", "value": "C81107"},
             {"label": "Pucallpa", "value": "C81108"},
             {"label": "Purus", "value": "C81109"}],
 "Udmurt Republic": [{"label": "Alnashi", "value": "C105175"},
                     {"label": "Balezino", "value": "C105176"},
                     {"label": "Debesy", "value": "C105177"},
                     {"label": "Fakel", "value": "C105178"},
                     {"label": "Glazov", "value": "C105179"},
                     {"label": "Grakhovo", "value": "C105180"},
                     {"label": "Igra", "value": "C105181"},
                     {"label": "Izhevsk", "value": "C105182"},
                     {"label": "Kama", "value": "C105183"},
                     {"label": "Kambarka", "value": "C105184"},
                     {"label": "Karakulino", "value": "C105185"},
                     {"label": "Karakulinskiy Rayon", "value": "C105186"},
                     {"label": "Kez", "value": "C105187"},
                     {"label": "Khokhryaki", "value": "C105188"},
                     {"label": "Kiyasovo", "value": "C105189"},
                     {"label": "Kiyasovskiy Rayon", "value": "C105190"},
                     {"label": "Kizner", "value": "C105191"},
                     {"label": "Krasnogorskoye", "value": "C105192"},
                     {"label": "Malaya Purga", "value": "C105193"},
                     {"label": "Malopurginskiy Rayon", "value": "C105194"},
                     {"label": "Mozhga", "value": "C105195"},
                     {"label": "Pirogovo", "value": "C105196"},
                     {"label": "Pudem", "value": "C105197"},
                     {"label": "Pychas", "value": "C105198"},
                     {"label": "Sarapul", "value": "C105199"},
                     {"label": "Sarapulskiy Rayon", "value": "C105200"},
                     {"label": "Selty", "value": "C105201"},
                     {"label": "Sharkan", "value": "C105202"},
                     {"label": "Sigayevo", "value": "C105203"},
                     {"label": "Syumsi", "value": "C105204"},
                     {"label": "Uva", "value": "C105205"},
                     {"label": "Vavozh", "value": "C105206"},
                     {"label": "Votkinsk", "value": "C105207"},
                     {"label": "Yar", "value": "C105208"},
                     {"label": "Yukamenskoye", "value": "C105209"},
                     {"label": "Zavyalovskiy Rayon", "value": "C105210"}],
 "Udon Thani": [{"label": "Amphoe Ban Dung", "value": "C119534"},
                {"label": "Amphoe Ban Phue", "value": "C119535"},
                {"label": "Amphoe Chai Wan", "value": "C119536"},
                {"label": "Amphoe Ku Kaeo", "value": "C119537"},
                {"label": "Amphoe Kumphawapi", "value": "C119538"},
                {"label": "Amphoe Kut Chap", "value": "C119539"},
                {"label": "Amphoe Mueang Udon Thani", "value": "C119540"},
                {"label": "Amphoe Na Yung", "value": "C119541"},
                {"label": "Amphoe Nam Som", "value": "C119542"},
                {"label": "Amphoe Non Sa-at", "value": "C119543"},
                {"label": "Amphoe Nong Han", "value": "C119544"},
                {"label": "Amphoe Nong Saeng", "value": "C119545"},
                {"label": "Amphoe Nong Wua So", "value": "C119546"},
                {"label": "Amphoe Phen", "value": "C119547"},
                {"label": "Amphoe Phibun Rak", "value": "C119548"},
                {"label": "Amphoe Prachak Sinlapakhom", "value": "C119549"},
                {"label": "Amphoe Sang Khom", "value": "C119550"},
                {"label": "Amphoe Si That", "value": "C119551"},
                {"label": "Amphoe Thung Fon", "value": "C119552"},
                {"label": "Amphoe Wang Sam Mo", "value": "C119553"},
                {"label": "Ban Dung", "value": "C119554"},
                {"label": "Ban Nong Wua So", "value": "C119555"},
                {"label": "Ban Phan Don", "value": "C119556"},
                {"label": "Kumphawapi", "value": "C119557"},
                {"label": "Kut Chap", "value": "C119558"},
                {"label": "Nam Som", "value": "C119559"},
                {"label": "Nong Wua So", "value": "C119560"},
                {"label": "Udon Thani", "value": "C119561"}],
 "Uige Province": [{"label": "Uige", "value": "C570"}],
 "Ujar District": [{"label": "Ujar", "value": "C8532"}],
 "Ulcinj Municipality": [{"label": "Ulcinj", "value": "C75626"}],
 "Ulsan": [{"label": "Buk-gu", "value": "C108543"},
           {"label": "Dong-gu", "value": "C108544"},
           {"label": "Jung-gu", "value": "C108545"},
           {"label": "Nam-gu", "value": "C108546"},
           {"label": "Ulju-gun", "value": "C108547"},
           {"label": "Ulsan", "value": "C108548"}],
 "Ulster": [{"label": "An Cabhan", "value": "C51551"},
            {"label": "Bailieborough", "value": "C51552"},
            {"label": "Ballybofey", "value": "C51553"},
            {"label": "Ballyconnell", "value": "C51554"},
            {"label": "Ballyjamesduff", "value": "C51555"},
            {"label": "Ballyshannon", "value": "C51556"},
            {"label": "Belturbet", "value": "C51557"},
            {"label": "Buncrana", "value": "C51558"},
            {"label": "Bundoran", "value": "C51559"},
            {"label": "Carndonagh", "value": "C51560"},
            {"label": "Carrickmacross", "value": "C51561"},
            {"label": "Castleblayney", "value": "C51562"},
            {"label": "Cavan", "value": "C51563"},
            {"label": "Clones", "value": "C51564"},
            {"label": "Convoy", "value": "C51565"},
            {"label": "Cootehill", "value": "C51566"},
            {"label": "County Donegal", "value": "C51567"},
            {"label": "County Monaghan", "value": "C51568"},
            {"label": "Derrybeg", "value": "C51569"},
            {"label": "Donegal", "value": "C51570"},
            {"label": "Dungloe", "value": "C51571"},
            {"label": "Dunlewy", "value": "C51572"},
            {"label": "Gweedore", "value": "C51573"},
            {"label": "Killybegs", "value": "C51574"},
            {"label": "Kingscourt", "value": "C51575"},
            {"label": "Leifear", "value": "C51576"},
            {"label": "Letterkenny", "value": "C51577"},
            {"label": "Monaghan", "value": "C51578"},
            {"label": "Moville", "value": "C51579"},
            {"label": "Muff", "value": "C51580"},
            {"label": "Mullagh", "value": "C51581"},
            {"label": "Newtown Cunningham", "value": "C51582"},
            {"label": "Ramelton", "value": "C51583"},
            {"label": "Raphoe", "value": "C51584"},
            {"label": "Virginia", "value": "C51585"}],
 "Ulyanovsk Oblast": [{"label": "Barysh", "value": "C105211"},
                      {"label": "Bazarnyy Syzgan", "value": "C105212"},
                      {"label": "Cherdaklinskiy Rayon", "value": "C105213"},
                      {"label": "Cherdakly", "value": "C105214"},
                      {"label": "Chufarovo", "value": "C105215"},
                      {"label": "Dimitrovgrad", "value": "C105216"},
                      {"label": "Glotovka", "value": "C105217"},
                      {"label": "Ignatovka", "value": "C105218"},
                      {"label": "Imeni Vladimira Ilicha Lenina",
                       "value": "C105219"},
                      {"label": "Inza", "value": "C105220"},
                      {"label": "Isheyevka", "value": "C105221"},
                      {"label": "Izmaylovo", "value": "C105222"},
                      {"label": "Kanadey", "value": "C105223"},
                      {"label": "Karsun", "value": "C105224"},
                      {"label": "Krasnyy Gulyay", "value": "C105225"},
                      {"label": "Kuzovatovo", "value": "C105226"},
                      {"label": "Mayna", "value": "C105227"},
                      {"label": "Maynskiy Rayon", "value": "C105228"},
                      {"label": "Mirnyy", "value": "C105229"},
                      {"label": "Mullovka", "value": "C105230"},
                      {"label": "Nikolayevka", "value": "C105231"},
                      {"label": "Novaya Malykla", "value": "C105232"},
                      {"label": "Novaya Mayna", "value": "C105233"},
                      {"label": "Novocheremshansk", "value": "C105234"},
                      {"label": "Novospasskoye", "value": "C105235"},
                      {"label": "Novoulyanovsk", "value": "C105236"},
                      {"label": "Pavlovka", "value": "C105237"},
                      {"label": "Pavlovskiy Rayon", "value": "C105238"},
                      {"label": "Radishchevo", "value": "C105239"},
                      {"label": "Sengiley", "value": "C105240"},
                      {"label": "Silikatnyy", "value": "C105241"},
                      {"label": "Staraya Kulatka", "value": "C105242"},
                      {"label": "Staraya Mayna", "value": "C105243"},
                      {"label": "Starokulatkinskiy Rayon", "value": "C105244"},
                      {"label": "Starotimoshkino", "value": "C105245"},
                      {"label": "Surskoye", "value": "C105246"},
                      {"label": "Terengulskiy Rayon", "value": "C105247"},
                      {"label": "Tsilna", "value": "C105248"},
                      {"label": "Tsilninskiy Rayon", "value": "C105249"},
                      {"label": "Ulyanovsk", "value": "C105250"},
                      {"label": "Ulyanovskiy Rayon", "value": "C105251"},
                      {"label": "Undory", "value": "C105252"},
                      {"label": "Veshkayma", "value": "C105253"},
                      {"label": "Yazykovo", "value": "C105254"},
                      {"label": "Zhadovka", "value": "C105255"}],
 "Umbria": [{"label": "Acquasparta", "value": "C60703"},
            {"label": "Allerona", "value": "C60704"},
            {"label": "Alviano", "value": "C60705"},
            {"label": "Amelia", "value": "C60706"},
            {"label": "Arrone", "value": "C60707"},
            {"label": "Assisi", "value": "C60708"},
            {"label": "Attigliano", "value": "C60709"},
            {"label": "Avigliano Umbro", "value": "C60710"},
            {"label": "Baschi", "value": "C60711"},
            {"label": "Bastardo", "value": "C60712"},
            {"label": "Bastia umbra", "value": "C60713"},
            {"label": "Bettona", "value": "C60714"},
            {"label": "Bevagna", "value": "C60715"},
            {"label": "Bruna", "value": "C60716"},
            {"label": "Calvi dellUmbria", "value": "C60717"},
            {"label": "Campello sul Clitunno", "value": "C60718"},
            {"label": "Cannara", "value": "C60719"},
            {"label": "Capitan Loreto", "value": "C60720"},
            {"label": "Casa del Diavolo", "value": "C60721"},
            {"label": "Cascia", "value": "C60722"},
            {"label": "Castel Giorgio", "value": "C60723"},
            {"label": "Castel Ritaldi", "value": "C60724"},
            {"label": "Castel Viscardo", "value": "C60725"},
            {"label": "Casteltodino", "value": "C60726"},
            {"label": "Castiglione del Lago", "value": "C60727"},
            {"label": "Cerbara", "value": "C60728"},
            {"label": "Cerreto di Spoleto", "value": "C60729"},
            {"label": "Chiugiana-La Commenda", "value": "C60730"},
            {"label": "Ciconia", "value": "C60731"},
            {"label": "Cipolleto", "value": "C60732"},
            {"label": "Citerna", "value": "C60733"},
            {"label": "Citta della Pieve", "value": "C60734"},
            {"label": "Citta di Castello", "value": "C60735"},
            {"label": "Collazzone", "value": "C60736"},
            {"label": "Collepepe", "value": "C60737"},
            {"label": "Colombella", "value": "C60738"},
            {"label": "Corciano", "value": "C60739"},
            {"label": "Costacciaro", "value": "C60740"},
            {"label": "Costano", "value": "C60741"},
            {"label": "Deruta", "value": "C60742"},
            {"label": "Fabro", "value": "C60743"},
            {"label": "Fabro Scalo", "value": "C60744"},
            {"label": "Ferentillo", "value": "C60745"},
            {"label": "Ficulle", "value": "C60746"},
            {"label": "Foligno", "value": "C60747"},
            {"label": "Fontignano", "value": "C60748"},
            {"label": "Fornole", "value": "C60749"},
            {"label": "Fossato di Vico", "value": "C60750"},
            {"label": "Fratta Todina", "value": "C60751"},
            {"label": "Giano dellUmbria", "value": "C60752"},
            {"label": "Giove", "value": "C60753"},
            {"label": "Gualdo Cattaneo", "value": "C60754"},
            {"label": "Gualdo Tadino", "value": "C60755"},
            {"label": "Guardea", "value": "C60756"},
            {"label": "Gubbio", "value": "C60757"},
            {"label": "Lacugnano", "value": "C60758"},
            {"label": "Lisciano Niccone", "value": "C60759"},
            {"label": "Lugnano in Teverina", "value": "C60760"},
            {"label": "Magione", "value": "C60761"},
            {"label": "Mantignana", "value": "C60762"},
            {"label": "Marsciano", "value": "C60763"},
            {"label": "Massa Martana", "value": "C60764"},
            {"label": "Meggiano", "value": "C60765"},
            {"label": "Monte Castello di Vibio", "value": "C60766"},
            {"label": "Monte Grimano", "value": "C60767"},
            {"label": "Monte Santa Maria Tiberina", "value": "C60768"},
            {"label": "Montecastrilli", "value": "C60769"},
            {"label": "Montecchio", "value": "C60770"},
            {"label": "Montefalco", "value": "C60771"},
            {"label": "Montefranco", "value": "C60772"},
            {"label": "Montegabbione", "value": "C60773"},
            {"label": "Monteleone dOrvieto", "value": "C60774"},
            {"label": "Monteleone di Spoleto", "value": "C60775"},
            {"label": "Montone", "value": "C60776"},
            {"label": "Narni", "value": "C60777"},
            {"label": "Narni Scalo", "value": "C60778"},
            {"label": "Nocera Umbra", "value": "C60779"},
            {"label": "Norcia", "value": "C60780"},
            {"label": "Orvieto", "value": "C60781"},
            {"label": "Orvieto Scalo", "value": "C60782"},
            {"label": "Oscano", "value": "C60783"},
            {"label": "Ospedalicchio", "value": "C60784"},
            {"label": "Osteria del Gatto", "value": "C60785"},
            {"label": "Otricoli", "value": "C60786"},
            {"label": "Paciano", "value": "C60787"},
            {"label": "Padule-San Marco", "value": "C60788"},
            {"label": "Palazzo", "value": "C60789"},
            {"label": "Panicale", "value": "C60790"},
            {"label": "Pantalla", "value": "C60791"},
            {"label": "Parrano", "value": "C60792"},
            {"label": "Passaggio", "value": "C60793"},
            {"label": "Passignano sul Trasimeno", "value": "C60794"},
            {"label": "Penna in Teverina", "value": "C60795"},
            {"label": "Perugia", "value": "C60796"},
            {"label": "Petrignano", "value": "C60797"},
            {"label": "Piegaro", "value": "C60798"},
            {"label": "Pietralunga", "value": "C60799"},
            {"label": "Pila", "value": "C60800"},
            {"label": "Pistrino", "value": "C60801"},
            {"label": "Poggiodomo", "value": "C60802"},
            {"label": "Polino", "value": "C60803"},
            {"label": "Ponte Felcino", "value": "C60804"},
            {"label": "Ponte Pattoli", "value": "C60805"},
            {"label": "Ponterio-Pian di Porto", "value": "C60806"},
            {"label": "Porano", "value": "C60807"},
            {"label": "Pozzuolo", "value": "C60808"},
            {"label": "Preci", "value": "C60809"},
            {"label": "Provincia di Perugia", "value": "C60810"},
            {"label": "Provincia di Terni", "value": "C60811"},
            {"label": "Rivotorto", "value": "C60812"},
            {"label": "San Gemini", "value": "C60813"},
            {"label": "San Giacomo", "value": "C60814"},
            {"label": "San Giustino", "value": "C60815"},
            {"label": "San Martino in Campo", "value": "C60816"},
            {"label": "San Martino in Trignano", "value": "C60817"},
            {"label": "San Niccolo di Celle", "value": "C60818"},
            {"label": "San Secondo", "value": "C60819"},
            {"label": "San Terenziano", "value": "C60820"},
            {"label": "San Venanzo", "value": "C60821"},
            {"label": "SantAnatolia di Narco", "value": "C60822"},
            {"label": "Santa Maria degli Angeli", "value": "C60823"},
            {"label": "Scheggia", "value": "C60824"},
            {"label": "Scheggino", "value": "C60825"},
            {"label": "Selci-Lama", "value": "C60826"},
            {"label": "Sellano", "value": "C60827"},
            {"label": "Semonte-Casamorcia", "value": "C60828"},
            {"label": "Sferracavallo", "value": "C60829"},
            {"label": "Sigillo", "value": "C60830"},
            {"label": "Spello", "value": "C60831"},
            {"label": "Spoleto", "value": "C60832"},
            {"label": "Stazione di Allerona", "value": "C60833"},
            {"label": "Stazione di Padule", "value": "C60834"},
            {"label": "Stroncone", "value": "C60835"},
            {"label": "Tavernelle", "value": "C60836"},
            {"label": "Terni", "value": "C60837"},
            {"label": "Todi", "value": "C60838"},
            {"label": "Torgiano", "value": "C60839"},
            {"label": "Trestina", "value": "C60840"},
            {"label": "Trevi", "value": "C60841"},
            {"label": "Tuoro sul Trasimeno", "value": "C60842"},
            {"label": "Umbertide", "value": "C60843"},
            {"label": "Valfabbrica", "value": "C60844"},
            {"label": "Valtopina", "value": "C60845"},
            {"label": "Via Lippia", "value": "C60846"}],
 "Umm Salal Municipality": [{"label": "Umm Salal Muhammad", "value": "C92083"}],
 "Umm al-Quwain": [{"label": "Umm AL Quwain", "value": "C123132"},
                   {"label": "Umm Al Quwain City", "value": "C123133"}],
 "Ungheni District": [{"label": "Ungheni", "value": "C75555"}],
 "Upper Austria": [{"label": "Abwinden", "value": "C7895"},
                   {"label": "Adlwang", "value": "C7896"},
                   {"label": "Afiesl", "value": "C7897"},
                   {"label": "Ahorn", "value": "C7898"},
                   {"label": "Aichkirchen", "value": "C7899"},
                   {"label": "Aigen im Muhlkreis", "value": "C7900"},
                   {"label": "Aistersheim", "value": "C7901"},
                   {"label": "Alberndorf in der Riedmark", "value": "C7902"},
                   {"label": "Alkoven", "value": "C7903"},
                   {"label": "Allerheiligen im Muhlkreis", "value": "C7904"},
                   {"label": "Allhaming", "value": "C7905"},
                   {"label": "Altenberg bei Linz", "value": "C7906"},
                   {"label": "Altenfelden", "value": "C7907"},
                   {"label": "Altheim", "value": "C7908"},
                   {"label": "Altlichtenberg", "value": "C7909"},
                   {"label": "Altmunster", "value": "C7910"},
                   {"label": "Ampflwang", "value": "C7911"},
                   {"label": "Andorf", "value": "C7912"},
                   {"label": "Ansfelden", "value": "C7913"},
                   {"label": "Arnreit", "value": "C7914"},
                   {"label": "Aschach an der Donau", "value": "C7915"},
                   {"label": "Aschach an der Steyr", "value": "C7916"},
                   {"label": "Asten", "value": "C7917"},
                   {"label": "Attersee", "value": "C7918"},
                   {"label": "Attnang", "value": "C7919"},
                   {"label": "Attnang-Puchheim", "value": "C7920"},
                   {"label": "Atzbach", "value": "C7921"},
                   {"label": "Atzesberg", "value": "C7922"},
                   {"label": "Au an der Traun", "value": "C7923"},
                   {"label": "Audorf", "value": "C7924"},
                   {"label": "Aurach am Hongar", "value": "C7925"},
                   {"label": "Aurolzmunster", "value": "C7926"},
                   {"label": "Bachmanning", "value": "C7927"},
                   {"label": "Bad Goisern", "value": "C7928"},
                   {"label": "Bad Hall", "value": "C7929"},
                   {"label": "Bad Ischl", "value": "C7930"},
                   {"label": "Bad Kreuzen", "value": "C7931"},
                   {"label": "Bad Leonfelden", "value": "C7932"},
                   {"label": "Bad Schallerbach", "value": "C7933"},
                   {"label": "Bad Zell", "value": "C7934"},
                   {"label": "Berg", "value": "C7935"},
                   {"label": "Bergern", "value": "C7936"},
                   {"label": "Bergham", "value": "C7937"},
                   {"label": "Bernardin", "value": "C7938"},
                   {"label": "Brandln", "value": "C7939"},
                   {"label": "Braunau am Inn", "value": "C7940"},
                   {"label": "Braunau Neustadt", "value": "C7941"},
                   {"label": "Buchkirchen", "value": "C7942"},
                   {"label": "Burgfried", "value": "C7943"},
                   {"label": "Desselbrunn", "value": "C7944"},
                   {"label": "Dickerldorf", "value": "C7945"},
                   {"label": "Diesseits", "value": "C7946"},
                   {"label": "Dietach", "value": "C7947"},
                   {"label": "Dietachdorf", "value": "C7948"},
                   {"label": "Doppl", "value": "C7949"},
                   {"label": "Dorfl", "value": "C7950"},
                   {"label": "Durnau", "value": "C7951"},
                   {"label": "Ebensee", "value": "C7952"},
                   {"label": "Eberschwang", "value": "C7953"},
                   {"label": "Eberstalzell", "value": "C7954"},
                   {"label": "Edt bei Lambach", "value": "C7955"},
                   {"label": "Eferding", "value": "C7956"},
                   {"label": "Eidenberg", "value": "C7957"},
                   {"label": "Engerwitzdorf", "value": "C7958"},
                   {"label": "Enns", "value": "C7959"},
                   {"label": "Feldkirchen an der Donau", "value": "C7960"},
                   {"label": "Fisching", "value": "C7961"},
                   {"label": "Fornach", "value": "C7962"},
                   {"label": "Frankenburg", "value": "C7963"},
                   {"label": "Frankenmarkt", "value": "C7964"},
                   {"label": "Freindorf", "value": "C7965"},
                   {"label": "Freistadt", "value": "C7966"},
                   {"label": "Gaflenz", "value": "C7967"},
                   {"label": "Gallneukirchen", "value": "C7968"},
                   {"label": "Gallspach", "value": "C7969"},
                   {"label": "Gampern", "value": "C7970"},
                   {"label": "Garsten", "value": "C7971"},
                   {"label": "Gaspoltshofen", "value": "C7972"},
                   {"label": "Gaumberg", "value": "C7973"},
                   {"label": "Geboltskirchen", "value": "C7974"},
                   {"label": "Gmunden", "value": "C7975"},
                   {"label": "Goldworth", "value": "C7976"},
                   {"label": "Gosau", "value": "C7977"},
                   {"label": "Gramastetten", "value": "C7978"},
                   {"label": "Grein", "value": "C7979"},
                   {"label": "Grieskirchen", "value": "C7980"},
                   {"label": "Grossraming", "value": "C7981"},
                   {"label": "Grunau im Almtal", "value": "C7982"},
                   {"label": "Grunburg", "value": "C7983"},
                   {"label": "Gschwandt", "value": "C7984"},
                   {"label": "Gunskirchen", "value": "C7985"},
                   {"label": "Gutau", "value": "C7986"},
                   {"label": "Haag", "value": "C7987"},
                   {"label": "Haag am Hausruck", "value": "C7988"},
                   {"label": "Hagenberg im Muhlkreis", "value": "C7989"},
                   {"label": "Haibach im Muhlkreis", "value": "C7990"},
                   {"label": "Haid", "value": "C7991"},
                   {"label": "Haiden", "value": "C7992"},
                   {"label": "Haidl", "value": "C7993"},
                   {"label": "Handenberg", "value": "C7994"},
                   {"label": "Hart", "value": "C7995"},
                   {"label": "Hartheim", "value": "C7996"},
                   {"label": "Hartkirchen", "value": "C7997"},
                   {"label": "Haselbach", "value": "C7998"},
                   {"label": "Haslach an der Muhl", "value": "C7999"},
                   {"label": "Hellmonsodt", "value": "C8000"},
                   {"label": "Herzogsdorf", "value": "C8001"},
                   {"label": "Hochburg-Ach", "value": "C8002"},
                   {"label": "Hochpoint", "value": "C8003"},
                   {"label": "Hof", "value": "C8004"},
                   {"label": "Hofkirchen im Traunkreis", "value": "C8005"},
                   {"label": "Holzhausen", "value": "C8006"},
                   {"label": "Horsching", "value": "C8007"},
                   {"label": "Innerschwand", "value": "C8008"},
                   {"label": "Inzersdorf im Kremstal", "value": "C8009"},
                   {"label": "Kaltenbach", "value": "C8010"},
                   {"label": "Katsdorf", "value": "C8011"},
                   {"label": "Kematen an der Krems", "value": "C8012"},
                   {"label": "Kirchberg ob der Donau", "value": "C8013"},
                   {"label": "Kirchdorf an der Krems", "value": "C8014"},
                   {"label": "Kirchschlag bei Linz", "value": "C8015"},
                   {"label": "Klaffer am Hochficht", "value": "C8016"},
                   {"label": "Konigswiesen", "value": "C8017"},
                   {"label": "Kopfing im Innkreis", "value": "C8018"},
                   {"label": "Kremsdorf", "value": "C8019"},
                   {"label": "Kremsmunster", "value": "C8020"},
                   {"label": "Kronstorf", "value": "C8021"},
                   {"label": "Laab", "value": "C8022"},
                   {"label": "Laahen", "value": "C8023"},
                   {"label": "Laakirchen", "value": "C8024"},
                   {"label": "Lambach", "value": "C8025"},
                   {"label": "Langenstein", "value": "C8026"},
                   {"label": "Langholzfeld", "value": "C8027"},
                   {"label": "Laussa", "value": "C8028"},
                   {"label": "Lembach im Muhlkreis", "value": "C8029"},
                   {"label": "Lenzing", "value": "C8030"},
                   {"label": "Leonding", "value": "C8031"},
                   {"label": "Leonstein", "value": "C8032"},
                   {"label": "Lichtenegg", "value": "C8033"},
                   {"label": "Linz", "value": "C8034"},
                   {"label": "Linz Stadt", "value": "C8035"},
                   {"label": "Losenstein", "value": "C8036"},
                   {"label": "Luftenberg an der Donau", "value": "C8037"},
                   {"label": "Manning", "value": "C8038"},
                   {"label": "Marchtrenk", "value": "C8039"},
                   {"label": "Maria Neustift", "value": "C8040"},
                   {"label": "Maria Schmolln", "value": "C8041"},
                   {"label": "Mattighofen", "value": "C8042"},
                   {"label": "Mauerkirchen", "value": "C8043"},
                   {"label": "Meggenhofen", "value": "C8044"},
                   {"label": "Mehrnbach", "value": "C8045"},
                   {"label": "Michaelnbach", "value": "C8046"},
                   {"label": "Micheldorf in Oberosterreich", "value": "C8047"},
                   {"label": "Mitterkirchen im Machland", "value": "C8048"},
                   {"label": "Mittertreffling", "value": "C8049"},
                   {"label": "Molln", "value": "C8050"},
                   {"label": "Mondsee", "value": "C8051"},
                   {"label": "Moosdorf", "value": "C8052"},
                   {"label": "Muhldorf", "value": "C8053"},
                   {"label": "Munderfing", "value": "C8054"},
                   {"label": "Munzbach", "value": "C8055"},
                   {"label": "Munzkirchen", "value": "C8056"},
                   {"label": "Naarn im Machlande", "value": "C8057"},
                   {"label": "Natternbach", "value": "C8058"},
                   {"label": "Nebelberg", "value": "C8059"},
                   {"label": "Neubau", "value": "C8060"},
                   {"label": "Neue Heimat", "value": "C8061"},
                   {"label": "Neuhofen an der Krems", "value": "C8062"},
                   {"label": "Neukirchen", "value": "C8063"},
                   {"label": "Neukirchen am Walde", "value": "C8064"},
                   {"label": "Neukirchen an der Vockla", "value": "C8065"},
                   {"label": "Neukirchen bei Lambach", "value": "C8066"},
                   {"label": "Neumarkt im Hausruckkreis", "value": "C8067"},
                   {"label": "Neumarkt im Muhlkreis", "value": "C8068"},
                   {"label": "Neuzeug", "value": "C8069"},
                   {"label": "Niederneukirchen", "value": "C8070"},
                   {"label": "Niederstrass", "value": "C8071"},
                   {"label": "Niederthalheim", "value": "C8072"},
                   {"label": "Nostlbach", "value": "C8073"},
                   {"label": "Oberhaid", "value": "C8074"},
                   {"label": "Oberhart", "value": "C8075"},
                   {"label": "Oberhofen am Irrsee", "value": "C8076"},
                   {"label": "Obernberg am Inn", "value": "C8077"},
                   {"label": "Oberndorf bei Schwanenstadt", "value": "C8078"},
                   {"label": "Oberneukirchen", "value": "C8079"},
                   {"label": "Oberwang", "value": "C8080"},
                   {"label": "Oedt", "value": "C8081"},
                   {"label": "Oepping", "value": "C8082"},
                   {"label": "Offenhausen", "value": "C8083"},
                   {"label": "Ohlsdorf", "value": "C8084"},
                   {"label": "Ort im Innkreis", "value": "C8085"},
                   {"label": "Ostermiething", "value": "C8086"},
                   {"label": "Ottenschlag im Muhlkreis", "value": "C8087"},
                   {"label": "Ottensheim", "value": "C8088"},
                   {"label": "Ottnang am Hausruck", "value": "C8089"},
                   {"label": "Pabneukirchen", "value": "C8090"},
                   {"label": "Palting", "value": "C8091"},
                   {"label": "Pasching", "value": "C8092"},
                   {"label": "Pattigham", "value": "C8093"},
                   {"label": "Pennewang", "value": "C8094"},
                   {"label": "Perg", "value": "C8095"},
                   {"label": "Perwang am Grabensee", "value": "C8096"},
                   {"label": "Pettenbach", "value": "C8097"},
                   {"label": "Peuerbach", "value": "C8098"},
                   {"label": "Pfaffing", "value": "C8099"},
                   {"label": "Pfarrkirchen bei Bad Hall", "value": "C8100"},
                   {"label": "Pichl bei Wels", "value": "C8101"},
                   {"label": "Pierbach", "value": "C8102"},
                   {"label": "Pinsdorf", "value": "C8103"},
                   {"label": "Pitzenberg", "value": "C8104"},
                   {"label": "Plesching", "value": "C8105"},
                   {"label": "Politischer Bezirk Braunau am Inn",
                    "value": "C8106"},
                   {"label": "Politischer Bezirk Eferding", "value": "C8107"},
                   {"label": "Politischer Bezirk Freistadt", "value": "C8108"},
                   {"label": "Politischer Bezirk Gmunden", "value": "C8109"},
                   {"label": "Politischer Bezirk Grieskirchen",
                    "value": "C8110"},
                   {"label": "Politischer Bezirk Kirchdorf an der Krems",
                    "value": "C8111"},
                   {"label": "Politischer Bezirk Linz-Land", "value": "C8112"},
                   {"label": "Politischer Bezirk Perg", "value": "C8113"},
                   {"label": "Politischer Bezirk Ried im Innkreis",
                    "value": "C8114"},
                   {"label": "Politischer Bezirk Rohrbach", "value": "C8115"},
                   {"label": "Politischer Bezirk Scharding", "value": "C8116"},
                   {"label": "Politischer Bezirk Steyr-Land", "value": "C8117"},
                   {"label": "Politischer Bezirk Urfahr-Umgebung",
                    "value": "C8118"},
                   {"label": "Politischer Bezirk Vocklabruck",
                    "value": "C8119"},
                   {"label": "Potting", "value": "C8120"},
                   {"label": "Pram", "value": "C8121"},
                   {"label": "Prambachkirchen", "value": "C8122"},
                   {"label": "Pramet", "value": "C8123"},
                   {"label": "Pregarten", "value": "C8124"},
                   {"label": "Puchenau", "value": "C8125"},
                   {"label": "Puchheim", "value": "C8126"},
                   {"label": "Puchlkirchen am Trattberg", "value": "C8127"},
                   {"label": "Pucking", "value": "C8128"},
                   {"label": "Puhret", "value": "C8129"},
                   {"label": "Raab", "value": "C8130"},
                   {"label": "Raffelstetten", "value": "C8131"},
                   {"label": "Rainbach im Muhlkreis", "value": "C8132"},
                   {"label": "Ranshofen", "value": "C8133"},
                   {"label": "Redleiten", "value": "C8134"},
                   {"label": "Redlham", "value": "C8135"},
                   {"label": "Regau", "value": "C8136"},
                   {"label": "Reichenau im Muhlkreis", "value": "C8137"},
                   {"label": "Reichenthal", "value": "C8138"},
                   {"label": "Reichraming", "value": "C8139"},
                   {"label": "Reiterndorf", "value": "C8140"},
                   {"label": "Ried im Innkreis", "value": "C8141"},
                   {"label": "Ried im Traunkreis", "value": "C8142"},
                   {"label": "Ried in der Riedmark", "value": "C8143"},
                   {"label": "Rohr im Kremstal", "value": "C8144"},
                   {"label": "Rohrbach-Berg", "value": "C8145"},
                   {"label": "Roitham", "value": "C8146"},
                   {"label": "Rufling", "value": "C8147"},
                   {"label": "Rustorf", "value": "C8148"},
                   {"label": "Rutzenham", "value": "C8149"},
                   {"label": "Sammersdorf", "value": "C8150"},
                   {"label": "Sankt Aegidi", "value": "C8151"},
                   {"label": "Sankt Dionysen", "value": "C8152"},
                   {"label": "Sankt Florian", "value": "C8153"},
                   {"label": "Sankt Georgen am Fillmannsbach",
                    "value": "C8154"},
                   {"label": "Sankt Georgen an der Gusen", "value": "C8155"},
                   {"label": "Sankt Georgen bei Obernberg am Inn",
                    "value": "C8156"},
                   {"label": "Sankt Georgen im Attergau", "value": "C8157"},
                   {"label": "Sankt Gotthard im Muhlkreis", "value": "C8158"},
                   {"label": "Sankt Lorenz", "value": "C8159"},
                   {"label": "Sankt Marien", "value": "C8160"},
                   {"label": "Sankt Martin", "value": "C8161"},
                   {"label": "Sankt Martin im Muhlkreis", "value": "C8162"},
                   {"label": "Sankt Oswald bei Freistadt", "value": "C8163"},
                   {"label": "Sankt Peter am Hart", "value": "C8164"},
                   {"label": "Sankt Ulrich bei Steyr", "value": "C8165"},
                   {"label": "Sankt Wolfgang im Salzkammergut",
                    "value": "C8166"},
                   {"label": "Sarleinsbach", "value": "C8167"},
                   {"label": "Sattledt", "value": "C8168"},
                   {"label": "Schafwiesen", "value": "C8169"},
                   {"label": "Schalchen", "value": "C8170"},
                   {"label": "Schalchham", "value": "C8171"},
                   {"label": "Schardenberg", "value": "C8172"},
                   {"label": "Scharding", "value": "C8173"},
                   {"label": "Scharding Vorstadt", "value": "C8174"},
                   {"label": "Scharnstein", "value": "C8175"},
                   {"label": "Schenkenfelden", "value": "C8176"},
                   {"label": "Schildorn", "value": "C8177"},
                   {"label": "Schlatt", "value": "C8178"},
                   {"label": "Schlierbach", "value": "C8179"},
                   {"label": "Schlusslberg", "value": "C8180"},
                   {"label": "Schondorf", "value": "C8181"},
                   {"label": "Schonering", "value": "C8182"},
                   {"label": "Schorfling", "value": "C8183"},
                   {"label": "Schwanenstadt", "value": "C8184"},
                   {"label": "Schweinbach", "value": "C8185"},
                   {"label": "Schwertberg", "value": "C8186"},
                   {"label": "Seewalchen", "value": "C8187"},
                   {"label": "Sierning", "value": "C8188"},
                   {"label": "Sonnberg im Muhlkreis", "value": "C8189"},
                   {"label": "Spital am Pyhrn", "value": "C8190"},
                   {"label": "Stadl-Hausruck", "value": "C8191"},
                   {"label": "Stadl-Paura", "value": "C8192"},
                   {"label": "Stadl-Traun", "value": "C8193"},
                   {"label": "Steinbach am Attersee", "value": "C8194"},
                   {"label": "Steinerkirchen an der Traun", "value": "C8195"},
                   {"label": "Steinfeld", "value": "C8196"},
                   {"label": "Steinhaus", "value": "C8197"},
                   {"label": "Steyr", "value": "C8198"},
                   {"label": "Steyr Stadt", "value": "C8199"},
                   {"label": "Steyregg", "value": "C8200"},
                   {"label": "Strassham", "value": "C8201"},
                   {"label": "Taiskirchen im Innkreis", "value": "C8202"},
                   {"label": "Taufkirchen an der Trattnach", "value": "C8203"},
                   {"label": "Ternberg", "value": "C8204"},
                   {"label": "Thalheim bei Wels", "value": "C8205"},
                   {"label": "Tiefgraben", "value": "C8206"},
                   {"label": "Timelkam", "value": "C8207"},
                   {"label": "Tragwein", "value": "C8208"},
                   {"label": "Traun", "value": "C8209"},
                   {"label": "Traunkirchen", "value": "C8210"},
                   {"label": "Ufer", "value": "C8211"},
                   {"label": "Ulrichsberg", "value": "C8212"},
                   {"label": "Ungenach", "value": "C8213"},
                   {"label": "Untereisenfeld", "value": "C8214"},
                   {"label": "Unterjosefstal", "value": "C8215"},
                   {"label": "Uttendorf", "value": "C8216"},
                   {"label": "Utzenaich", "value": "C8217"},
                   {"label": "Viechtwang", "value": "C8218"},
                   {"label": "Vocklabruck", "value": "C8219"},
                   {"label": "Vocklamarkt", "value": "C8220"},
                   {"label": "Vorchdorf", "value": "C8221"},
                   {"label": "Wagrain", "value": "C8222"},
                   {"label": "Wagram", "value": "C8223"},
                   {"label": "Waizenkirchen", "value": "C8224"},
                   {"label": "Walding", "value": "C8225"},
                   {"label": "Waldkirchen am Wesen", "value": "C8226"},
                   {"label": "Waldneukirchen", "value": "C8227"},
                   {"label": "Waldzell", "value": "C8228"},
                   {"label": "Wallern an der Trattnach", "value": "C8229"},
                   {"label": "Wartberg an der Krems", "value": "C8230"},
                   {"label": "Wartberg ob der Aist", "value": "C8231"},
                   {"label": "Weibern", "value": "C8232"},
                   {"label": "Weilbach", "value": "C8233"},
                   {"label": "Weissenkirchen im Attergau", "value": "C8234"},
                   {"label": "Weisskirchen an der Traun", "value": "C8235"},
                   {"label": "Weitersfelden", "value": "C8236"},
                   {"label": "Wels", "value": "C8237"},
                   {"label": "Wels Stadt", "value": "C8238"},
                   {"label": "Wels-Land", "value": "C8239"},
                   {"label": "Wendling", "value": "C8240"},
                   {"label": "Weng im Innkreis", "value": "C8241"},
                   {"label": "Wernstein am Inn", "value": "C8242"},
                   {"label": "Weyer", "value": "C8243"},
                   {"label": "Weyregg", "value": "C8244"},
                   {"label": "Wimpassing", "value": "C8245"},
                   {"label": "Wimsbach", "value": "C8246"},
                   {"label": "Windhaag bei Freistadt", "value": "C8247"},
                   {"label": "Windhaag bei Perg", "value": "C8248"},
                   {"label": "Windischgarsten", "value": "C8249"},
                   {"label": "Wippenham", "value": "C8250"},
                   {"label": "Wispl", "value": "C8251"},
                   {"label": "Wolfern", "value": "C8252"},
                   {"label": "Wolfsegg am Hausruck", "value": "C8253"},
                   {"label": "Zell am Moos", "value": "C8254"},
                   {"label": "Zell am Pettenfirst", "value": "C8255"},
                   {"label": "Zell an der Pram", "value": "C8256"},
                   {"label": "Zwettl an der Rodl", "value": "C8257"}],
 "Upper Demerara-Berbice": [{"label": "Linden", "value": "C43077"}],
 "Upper East": [{"label": "Bawku", "value": "C41555"},
                {"label": "Bolgatanga", "value": "C41556"},
                {"label": "Navrongo", "value": "C41557"}],
 "Upper River Division": [{"label": "Bakadagy", "value": "C34220"},
                          {"label": "Basse Santa Su", "value": "C34221"},
                          {"label": "Brifu", "value": "C34222"},
                          {"label": "Daba Kunda", "value": "C34223"},
                          {"label": "Demba Kunda", "value": "C34224"},
                          {"label": "Diabugu", "value": "C34225"},
                          {"label": "Diabugu Basilla", "value": "C34226"},
                          {"label": "Fulladu East", "value": "C34227"},
                          {"label": "Gunjur Kuta", "value": "C34228"},
                          {"label": "Kantora", "value": "C34229"},
                          {"label": "Koina", "value": "C34230"},
                          {"label": "Kumbija", "value": "C34231"},
                          {"label": "Nyamanari", "value": "C34232"},
                          {"label": "Perai", "value": "C34233"},
                          {"label": "Sabi", "value": "C34234"},
                          {"label": "Sandu", "value": "C34235"},
                          {"label": "Sudowol", "value": "C34236"},
                          {"label": "Sun Kunda", "value": "C34237"},
                          {"label": "Sutukoba", "value": "C34238"},
                          {"label": "Wuli", "value": "C34239"}],
 "Upper Takutu-Upper Essequibo": [{"label": "Lethem", "value": "C43078"}],
 "Upper West": [{"label": "Wa", "value": "C41558"}],
 "Uppsala County": [{"label": "Alsike", "value": "C116030"},
                    {"label": "Alunda", "value": "C116031"},
                    {"label": "Alvkarleby", "value": "C116032"},
                    {"label": "Anneberg", "value": "C116033"},
                    {"label": "Balinge", "value": "C116034"},
                    {"label": "Balsta", "value": "C116035"},
                    {"label": "Bjorklinge", "value": "C116036"},
                    {"label": "Enkoping", "value": "C116037"},
                    {"label": "Gamla Uppsala", "value": "C116038"},
                    {"label": "Gimo", "value": "C116039"},
                    {"label": "Grillby", "value": "C116040"},
                    {"label": "Heby", "value": "C116041"},
                    {"label": "Irsta", "value": "C116042"},
                    {"label": "Karlholmsbruk", "value": "C116043"},
                    {"label": "Knivsta", "value": "C116044"},
                    {"label": "Lovstalot", "value": "C116045"},
                    {"label": "Marieberg", "value": "C116046"},
                    {"label": "Morgongava", "value": "C116047"},
                    {"label": "Orbyhus", "value": "C116048"},
                    {"label": "Oregrund", "value": "C116049"},
                    {"label": "Orsundsbro", "value": "C116050"},
                    {"label": "Osterbybruk", "value": "C116051"},
                    {"label": "Ostervala", "value": "C116052"},
                    {"label": "Osthammar", "value": "C116053"},
                    {"label": "Savja", "value": "C116054"},
                    {"label": "Skutskar", "value": "C116055"},
                    {"label": "Soderfors", "value": "C116056"},
                    {"label": "Storvreta", "value": "C116057"},
                    {"label": "Tarnsjo", "value": "C116058"},
                    {"label": "Tierp", "value": "C116059"},
                    {"label": "Uppsala", "value": "C116060"},
                    {"label": "Vattholma", "value": "C116061"}],
 "Uri": [{"label": "Altdorf", "value": "C117240"},
         {"label": "Andermatt", "value": "C117241"},
         {"label": "Attinghausen", "value": "C117242"},
         {"label": "Bauen", "value": "C117243"},
         {"label": "Burglen", "value": "C117244"},
         {"label": "Erstfeld", "value": "C117245"},
         {"label": "Fluelen", "value": "C117246"},
         {"label": "Schattdorf", "value": "C117247"},
         {"label": "Seedorf", "value": "C117248"},
         {"label": "Silenen", "value": "C117249"}],
 "Urozgan": [{"label": "Tarinkot", "value": "C90"},
             {"label": "Uruzgan", "value": "C91"}],
 "Usak": [{"label": "Banaz", "value": "C121099"},
          {"label": "Bolme", "value": "C121100"},
          {"label": "Esme", "value": "C121101"},
          {"label": "Gullu", "value": "C121102"},
          {"label": "Ilyasli", "value": "C121103"},
          {"label": "Karahalli", "value": "C121104"},
          {"label": "Kizilcasogut", "value": "C121105"},
          {"label": "Merkez", "value": "C121106"},
          {"label": "Selcikler", "value": "C121107"},
          {"label": "Sivasli", "value": "C121108"},
          {"label": "Ulubey", "value": "C121109"},
          {"label": "Usak", "value": "C121110"}],
 "Ustecky kraj": [{"label": "Bechlin", "value": "C23116"},
                  {"label": "Becov", "value": "C23117"},
                  {"label": "Benesov nad Ploucnici", "value": "C23118"},
                  {"label": "Bilina", "value": "C23119"},
                  {"label": "Bilina Kyselka", "value": "C23120"},
                  {"label": "Bohusovice nad Ohri", "value": "C23121"},
                  {"label": "Branany", "value": "C23122"},
                  {"label": "Brezno", "value": "C23123"},
                  {"label": "Budyne nad Ohri", "value": "C23124"},
                  {"label": "Bystrany", "value": "C23125"},
                  {"label": "Cerncice", "value": "C23126"},
                  {"label": "Ceska Kamenice", "value": "C23127"},
                  {"label": "Chabarovice", "value": "C23128"},
                  {"label": "Chlumec", "value": "C23129"},
                  {"label": "Chomutov", "value": "C23130"},
                  {"label": "Chribska", "value": "C23131"},
                  {"label": "Cizkovice", "value": "C23132"},
                  {"label": "Decin", "value": "C23133"},
                  {"label": "Dobromerice", "value": "C23134"},
                  {"label": "Dolni Podluzi", "value": "C23135"},
                  {"label": "Dolni Poustevna", "value": "C23136"},
                  {"label": "Dubi", "value": "C23137"},
                  {"label": "Duchcov", "value": "C23138"},
                  {"label": "Horni Jiretin", "value": "C23139"},
                  {"label": "Hostka", "value": "C23140"},
                  {"label": "Hostomice", "value": "C23141"},
                  {"label": "Hrob", "value": "C23142"},
                  {"label": "Jilove", "value": "C23143"},
                  {"label": "Jirikov", "value": "C23144"},
                  {"label": "Jirkov", "value": "C23145"},
                  {"label": "Kadan", "value": "C23146"},
                  {"label": "Klasterec nad Ohri", "value": "C23147"},
                  {"label": "Kostany", "value": "C23148"},
                  {"label": "Kovarska", "value": "C23149"},
                  {"label": "Krasna Lipa", "value": "C23150"},
                  {"label": "Kresice", "value": "C23151"},
                  {"label": "Krupka", "value": "C23152"},
                  {"label": "Kryry", "value": "C23153"},
                  {"label": "Lenesice", "value": "C23154"},
                  {"label": "Libesice", "value": "C23155"},
                  {"label": "Libochovice", "value": "C23156"},
                  {"label": "Libouchec", "value": "C23157"},
                  {"label": "Litomerice", "value": "C23158"},
                  {"label": "Litvinov", "value": "C23159"},
                  {"label": "Lom u Mostu", "value": "C23160"},
                  {"label": "Louny", "value": "C23161"},
                  {"label": "Lovosice", "value": "C23162"},
                  {"label": "Lubenec", "value": "C23163"},
                  {"label": "Mecholupy", "value": "C23164"},
                  {"label": "Mezibori", "value": "C23165"},
                  {"label": "Mikulasovice", "value": "C23166"},
                  {"label": "Most", "value": "C23167"},
                  {"label": "Novosedlice", "value": "C23168"},
                  {"label": "Obrnice", "value": "C23169"},
                  {"label": "Okres Chomutov", "value": "C23170"},
                  {"label": "Okres Decin", "value": "C23171"},
                  {"label": "Okres Litomerice", "value": "C23172"},
                  {"label": "Okres Louny", "value": "C23173"},
                  {"label": "Okres Most", "value": "C23174"},
                  {"label": "Okres Teplice", "value": "C23175"},
                  {"label": "Okres Usti nad Labem", "value": "C23176"},
                  {"label": "Osek", "value": "C23177"},
                  {"label": "Perstejn", "value": "C23178"},
                  {"label": "Peruc", "value": "C23179"},
                  {"label": "Podborany", "value": "C23180"},
                  {"label": "Polepy", "value": "C23181"},
                  {"label": "Postoloprty", "value": "C23182"},
                  {"label": "Povrly", "value": "C23183"},
                  {"label": "Probostov", "value": "C23184"},
                  {"label": "Radonice", "value": "C23185"},
                  {"label": "Rehlovice", "value": "C23186"},
                  {"label": "Roudnice nad Labem", "value": "C23187"},
                  {"label": "Rumburk", "value": "C23188"},
                  {"label": "Sluknov", "value": "C23189"},
                  {"label": "Stare Krecany", "value": "C23190"},
                  {"label": "Steti", "value": "C23191"},
                  {"label": "Teplice", "value": "C23192"},
                  {"label": "Terezin", "value": "C23193"},
                  {"label": "Trebenice", "value": "C23194"},
                  {"label": "Trmice", "value": "C23195"},
                  {"label": "Udlice", "value": "C23196"},
                  {"label": "Ustek", "value": "C23197"},
                  {"label": "Varnsdorf", "value": "C23198"},
                  {"label": "Vejprty", "value": "C23199"},
                  {"label": "Velemin", "value": "C23200"},
                  {"label": "Velke Brezno", "value": "C23201"},
                  {"label": "Velky Senov", "value": "C23202"},
                  {"label": "Vernerice", "value": "C23203"},
                  {"label": "Vilemov", "value": "C23204"},
                  {"label": "Vroutek", "value": "C23205"},
                  {"label": "Zabrusany", "value": "C23206"},
                  {"label": "Zatec", "value": "C23207"},
                  {"label": "Zitenice", "value": "C23208"}],
 "Usulutan Department": [{"label": "Berlin", "value": "C24453"},
                         {"label": "Concepcion Batres", "value": "C24454"},
                         {"label": "Jiquilisco", "value": "C24455"},
                         {"label": "Jucuapa", "value": "C24456"},
                         {"label": "Jucuaran", "value": "C24457"},
                         {"label": "Ozatlan", "value": "C24458"},
                         {"label": "Puerto El Triunfo", "value": "C24459"},
                         {"label": "San Agustin", "value": "C24460"},
                         {"label": "Santa Elena", "value": "C24461"},
                         {"label": "Santiago de Maria", "value": "C24462"},
                         {"label": "Usulutan", "value": "C24463"}],
 "Utah": [{"label": "Alpine", "value": "C144798"},
          {"label": "American Fork", "value": "C144799"},
          {"label": "Aurora", "value": "C144800"},
          {"label": "Ballard", "value": "C144801"},
          {"label": "Beaver", "value": "C144802"},
          {"label": "Beaver County", "value": "C144803"},
          {"label": "Benjamin", "value": "C144804"},
          {"label": "Benson", "value": "C144805"},
          {"label": "Blanding", "value": "C144806"},
          {"label": "Bluffdale", "value": "C144807"},
          {"label": "Bountiful", "value": "C144808"},
          {"label": "Box Elder County", "value": "C144809"},
          {"label": "Brigham City", "value": "C144810"},
          {"label": "Cache County", "value": "C144811"},
          {"label": "Canyon Rim", "value": "C144812"},
          {"label": "Carbon County", "value": "C144813"},
          {"label": "Carbonville", "value": "C144814"},
          {"label": "Castle Dale", "value": "C144815"},
          {"label": "Cedar City", "value": "C144816"},
          {"label": "Cedar Hills", "value": "C144817"},
          {"label": "Centerfield", "value": "C144818"},
          {"label": "Centerville", "value": "C144819"},
          {"label": "Clearfield", "value": "C144820"},
          {"label": "Clinton", "value": "C144821"},
          {"label": "Coalville", "value": "C144822"},
          {"label": "Cottonwood Heights", "value": "C144823"},
          {"label": "Daggett County", "value": "C144824"},
          {"label": "Daniel", "value": "C144825"},
          {"label": "Davis County", "value": "C144826"},
          {"label": "Delta", "value": "C144827"},
          {"label": "Draper", "value": "C144828"},
          {"label": "Duchesne", "value": "C144829"},
          {"label": "Duchesne County", "value": "C144830"},
          {"label": "Eagle Mountain", "value": "C144831"},
          {"label": "East Carbon City", "value": "C144832"},
          {"label": "East Millcreek", "value": "C144833"},
          {"label": "Elk Ridge", "value": "C144834"},
          {"label": "Elwood", "value": "C144835"},
          {"label": "Emery County", "value": "C144836"},
          {"label": "Enoch", "value": "C144837"},
          {"label": "Enterprise", "value": "C144838"},
          {"label": "Ephraim", "value": "C144839"},
          {"label": "Erda", "value": "C144840"},
          {"label": "Fairview", "value": "C144841"},
          {"label": "Farmington", "value": "C144842"},
          {"label": "Farr West", "value": "C144843"},
          {"label": "Ferron", "value": "C144844"},
          {"label": "Fillmore", "value": "C144845"},
          {"label": "Fountain Green", "value": "C144846"},
          {"label": "Francis", "value": "C144847"},
          {"label": "Fruit Heights", "value": "C144848"},
          {"label": "Garfield County", "value": "C144849"},
          {"label": "Garland", "value": "C144850"},
          {"label": "Genola", "value": "C144851"},
          {"label": "Grand County", "value": "C144852"},
          {"label": "Granite", "value": "C144853"},
          {"label": "Grantsville", "value": "C144854"},
          {"label": "Gunnison", "value": "C144855"},
          {"label": "Harrisville", "value": "C144856"},
          {"label": "Heber City", "value": "C144857"},
          {"label": "Helper", "value": "C144858"},
          {"label": "Herriman", "value": "C144859"},
          {"label": "Highland", "value": "C144860"},
          {"label": "Hildale", "value": "C144861"},
          {"label": "Hill Air Force Base", "value": "C144862"},
          {"label": "Holladay", "value": "C144863"},
          {"label": "Honeyville", "value": "C144864"},
          {"label": "Hooper", "value": "C144865"},
          {"label": "Huntington", "value": "C144866"},
          {"label": "Hurricane", "value": "C144867"},
          {"label": "Hyde Park", "value": "C144868"},
          {"label": "Hyrum", "value": "C144869"},
          {"label": "Iron County", "value": "C144870"},
          {"label": "Ivins", "value": "C144871"},
          {"label": "Juab County", "value": "C144872"},
          {"label": "Junction", "value": "C144873"},
          {"label": "Kamas", "value": "C144874"},
          {"label": "Kanab", "value": "C144875"},
          {"label": "Kane County", "value": "C144876"},
          {"label": "Kaysville", "value": "C144877"},
          {"label": "Kearns", "value": "C144878"},
          {"label": "LaVerkin", "value": "C144879"},
          {"label": "Layton", "value": "C144880"},
          {"label": "Lehi", "value": "C144881"},
          {"label": "Lewiston", "value": "C144882"},
          {"label": "Liberty", "value": "C144883"},
          {"label": "Lindon", "value": "C144884"},
          {"label": "Little Cottonwood Creek Valley", "value": "C144885"},
          {"label": "Loa", "value": "C144886"},
          {"label": "Logan", "value": "C144887"},
          {"label": "Maeser", "value": "C144888"},
          {"label": "Magna", "value": "C144889"},
          {"label": "Manila", "value": "C144890"},
          {"label": "Manti", "value": "C144891"},
          {"label": "Mapleton", "value": "C144892"},
          {"label": "Marriott-Slaterville", "value": "C144893"},
          {"label": "Mendon", "value": "C144894"},
          {"label": "Midvale", "value": "C144895"},
          {"label": "Midway", "value": "C144896"},
          {"label": "Milford", "value": "C144897"},
          {"label": "Millard County", "value": "C144898"},
          {"label": "Millcreek", "value": "C144899"},
          {"label": "Millville", "value": "C144900"},
          {"label": "Moab", "value": "C144901"},
          {"label": "Mona", "value": "C144902"},
          {"label": "Monroe", "value": "C144903"},
          {"label": "Monticello", "value": "C144904"},
          {"label": "Morgan", "value": "C144905"},
          {"label": "Morgan County", "value": "C144906"},
          {"label": "Moroni", "value": "C144907"},
          {"label": "Mount Olympus", "value": "C144908"},
          {"label": "Mount Pleasant", "value": "C144909"},
          {"label": "Mountain Green", "value": "C144910"},
          {"label": "Murray", "value": "C144911"},
          {"label": "Naples", "value": "C144912"},
          {"label": "Nephi", "value": "C144913"},
          {"label": "Nibley", "value": "C144914"},
          {"label": "North Logan", "value": "C144915"},
          {"label": "North Ogden", "value": "C144916"},
          {"label": "North Salt Lake", "value": "C144917"},
          {"label": "Oakley", "value": "C144918"},
          {"label": "Ogden", "value": "C144919"},
          {"label": "Oquirrh", "value": "C144920"},
          {"label": "Orangeville", "value": "C144921"},
          {"label": "Orem", "value": "C144922"},
          {"label": "Panguitch", "value": "C144923"},
          {"label": "Park City", "value": "C144924"},
          {"label": "Parowan", "value": "C144925"},
          {"label": "Payson", "value": "C144926"},
          {"label": "Perry", "value": "C144927"},
          {"label": "Piute County", "value": "C144928"},
          {"label": "Plain City", "value": "C144929"},
          {"label": "Pleasant Grove", "value": "C144930"},
          {"label": "Pleasant View", "value": "C144931"},
          {"label": "Price", "value": "C144932"},
          {"label": "Providence", "value": "C144933"},
          {"label": "Provo", "value": "C144934"},
          {"label": "Randolph", "value": "C144935"},
          {"label": "Rich County", "value": "C144936"},
          {"label": "Richfield", "value": "C144937"},
          {"label": "Richmond", "value": "C144938"},
          {"label": "River Heights", "value": "C144939"},
          {"label": "Riverdale", "value": "C144940"},
          {"label": "Riverton", "value": "C144941"},
          {"label": "Roosevelt", "value": "C144942"},
          {"label": "Roy", "value": "C144943"},
          {"label": "Saint George", "value": "C144944"},
          {"label": "Salem", "value": "C144945"},
          {"label": "Salina", "value": "C144946"},
          {"label": "Salt Lake City", "value": "C144947"},
          {"label": "Salt Lake County", "value": "C144948"},
          {"label": "San Juan County", "value": "C144949"},
          {"label": "Sandy", "value": "C144950"},
          {"label": "Sandy Hills", "value": "C144951"},
          {"label": "Sanpete County", "value": "C144952"},
          {"label": "Santa Clara", "value": "C144953"},
          {"label": "Santaquin", "value": "C144954"},
          {"label": "Saratoga Springs", "value": "C144955"},
          {"label": "Sevier County", "value": "C144956"},
          {"label": "Silver Summit", "value": "C144957"},
          {"label": "Smithfield", "value": "C144958"},
          {"label": "Snyderville", "value": "C144959"},
          {"label": "South Jordan", "value": "C144960"},
          {"label": "South Jordan Heights", "value": "C144961"},
          {"label": "South Ogden", "value": "C144962"},
          {"label": "South Salt Lake", "value": "C144963"},
          {"label": "South Weber", "value": "C144964"},
          {"label": "South Willard", "value": "C144965"},
          {"label": "Spanish Fork", "value": "C144966"},
          {"label": "Spring City", "value": "C144967"},
          {"label": "Spring Glen", "value": "C144968"},
          {"label": "Springville", "value": "C144969"},
          {"label": "Stansbury park", "value": "C144970"},
          {"label": "Summit County", "value": "C144971"},
          {"label": "Summit Park", "value": "C144972"},
          {"label": "Sunset", "value": "C144973"},
          {"label": "Syracuse", "value": "C144974"},
          {"label": "Taylorsville", "value": "C144975"},
          {"label": "Tooele", "value": "C144976"},
          {"label": "Tooele County", "value": "C144977"},
          {"label": "Toquerville", "value": "C144978"},
          {"label": "Tremonton", "value": "C144979"},
          {"label": "Uintah", "value": "C144980"},
          {"label": "Uintah County", "value": "C144981"},
          {"label": "Utah County", "value": "C144982"},
          {"label": "Vernal", "value": "C144983"},
          {"label": "Vineyard", "value": "C144984"},
          {"label": "Wasatch County", "value": "C144985"},
          {"label": "Washington", "value": "C144986"},
          {"label": "Washington County", "value": "C144987"},
          {"label": "Washington Terrace", "value": "C144988"},
          {"label": "Wayne County", "value": "C144989"},
          {"label": "Weber County", "value": "C144990"},
          {"label": "Wellington", "value": "C144991"},
          {"label": "Wellsville", "value": "C144992"},
          {"label": "Wendover", "value": "C144993"},
          {"label": "West Bountiful", "value": "C144994"},
          {"label": "West Haven", "value": "C144995"},
          {"label": "West Jordan", "value": "C144996"},
          {"label": "West Mountain", "value": "C144997"},
          {"label": "West Point", "value": "C144998"},
          {"label": "West Valley City", "value": "C144999"},
          {"label": "White City", "value": "C145000"},
          {"label": "Willard", "value": "C145001"},
          {"label": "Wolf Creek", "value": "C145002"},
          {"label": "Woodland Hills", "value": "C145003"},
          {"label": "Woods Cross", "value": "C145004"}],
 "Utena County": [{"label": "Anyksciai", "value": "C65175"},
                  {"label": "Dukstas", "value": "C65176"},
                  {"label": "Ignalina", "value": "C65177"},
                  {"label": "Moletai", "value": "C65178"},
                  {"label": "Utena", "value": "C65179"},
                  {"label": "Visaginas", "value": "C65180"},
                  {"label": "Zarasai", "value": "C65181"}],
 "Uthai Thani": [{"label": "Amphoe Ban Rai", "value": "C119562"},
                 {"label": "Amphoe Huai Khot", "value": "C119563"},
                 {"label": "Amphoe Lan Sak", "value": "C119564"},
                 {"label": "Amphoe Mueang Uthai Thani", "value": "C119565"},
                 {"label": "Amphoe Nong Chang", "value": "C119566"},
                 {"label": "Amphoe Nong Khayang", "value": "C119567"},
                 {"label": "Amphoe Sawang Arom", "value": "C119568"},
                 {"label": "Amphoe Thap Than", "value": "C119569"},
                 {"label": "Huai Khot", "value": "C119570"},
                 {"label": "Lan Sak", "value": "C119571"},
                 {"label": "Thap Than", "value": "C119572"},
                 {"label": "Uthai Thani", "value": "C119573"}],
 "Utrecht": [{"label": "Abcoude", "value": "C77605"},
             {"label": "Amerongen", "value": "C77606"},
             {"label": "Amersfoort", "value": "C77607"},
             {"label": "Austerlitz", "value": "C77608"},
             {"label": "Baambrugge", "value": "C77609"},
             {"label": "Baarn", "value": "C77610"},
             {"label": "Bosch en Duin", "value": "C77611"},
             {"label": "Breukelen", "value": "C77612"},
             {"label": "Bunnik", "value": "C77613"},
             {"label": "Bunschoten", "value": "C77614"},
             {"label": "Cothen", "value": "C77615"},
             {"label": "De Bilt", "value": "C77616"},
             {"label": "De Hagen", "value": "C77617"},
             {"label": "De Uithof", "value": "C77618"},
             {"label": "Doorn", "value": "C77619"},
             {"label": "Driebergen-Rijsenburg", "value": "C77620"},
             {"label": "Groenekan", "value": "C77621"},
             {"label": "Hollandsche Rading", "value": "C77622"},
             {"label": "Houten", "value": "C77623"},
             {"label": "IJsselstein", "value": "C77624"},
             {"label": "Kedichem", "value": "C77625"},
             {"label": "Kerckebosch", "value": "C77626"},
             {"label": "Langenoord", "value": "C77627"},
             {"label": "Leerdam", "value": "C77628"},
             {"label": "Leersum", "value": "C77629"},
             {"label": "Leusden", "value": "C77630"},
             {"label": "Linschoten", "value": "C77631"},
             {"label": "Lopik", "value": "C77632"},
             {"label": "Lunetten", "value": "C77633"},
             {"label": "Maarn", "value": "C77634"},
             {"label": "Maarssen", "value": "C77635"},
             {"label": "Maartensdijk", "value": "C77636"},
             {"label": "Mijdrecht", "value": "C77637"},
             {"label": "Montfoort", "value": "C77638"},
             {"label": "Nieuwegein", "value": "C77639"},
             {"label": "Nijenheim", "value": "C77640"},
             {"label": "Odijk", "value": "C77641"},
             {"label": "Oog in Al", "value": "C77642"},
             {"label": "Oudewater", "value": "C77643"},
             {"label": "Overberg", "value": "C77644"},
             {"label": "Randenbroek", "value": "C77645"},
             {"label": "Renswoude", "value": "C77646"},
             {"label": "Rhenen", "value": "C77647"},
             {"label": "Schoonrewoerd", "value": "C77648"},
             {"label": "Soest", "value": "C77649"},
             {"label": "Soestdijk", "value": "C77650"},
             {"label": "Spakenburg", "value": "C77651"},
             {"label": "Spechtenkamp", "value": "C77652"},
             {"label": "Stichtse Vecht", "value": "C77653"},
             {"label": "Utrecht", "value": "C77654"},
             {"label": "Veenendaal", "value": "C77655"},
             {"label": "Veldhuizen", "value": "C77656"},
             {"label": "Vianen", "value": "C77657"},
             {"label": "Vinkeveen", "value": "C77658"},
             {"label": "Vleuten", "value": "C77659"},
             {"label": "Vreeswijk", "value": "C77660"},
             {"label": "Werkhoven", "value": "C77661"},
             {"label": "Wijk bij Duurstede", "value": "C77662"},
             {"label": "Wilnis", "value": "C77663"},
             {"label": "Woerden", "value": "C77664"},
             {"label": "Woudenberg", "value": "C77665"},
             {"label": "Zeist", "value": "C77666"}],
 "Uttar Pradesh": [{"label": "Achhnera", "value": "C48199"},
                   {"label": "Afzalgarh", "value": "C48200"},
                   {"label": "Agra", "value": "C48201"},
                   {"label": "Ahraura", "value": "C48202"},
                   {"label": "Aidalpur", "value": "C48203"},
                   {"label": "Airwa", "value": "C48204"},
                   {"label": "Akbarpur", "value": "C48205"},
                   {"label": "Akola", "value": "C48206"},
                   {"label": "Aliganj", "value": "C48207"},
                   {"label": "Aligarh", "value": "C48208"},
                   {"label": "Allahabad", "value": "C48209"},
                   {"label": "Allahganj", "value": "C48210"},
                   {"label": "Amanpur", "value": "C48211"},
                   {"label": "Amauli", "value": "C48212"},
                   {"label": "Ambahta", "value": "C48213"},
                   {"label": "Ambedkar Nagar", "value": "C48214"},
                   {"label": "Amethi", "value": "C48215"},
                   {"label": "Amroha", "value": "C48216"},
                   {"label": "Anandnagar", "value": "C48217"},
                   {"label": "Antu", "value": "C48218"},
                   {"label": "Anupshahr", "value": "C48219"},
                   {"label": "Aonla", "value": "C48220"},
                   {"label": "Araul", "value": "C48221"},
                   {"label": "Asalatganj", "value": "C48222"},
                   {"label": "Atarra", "value": "C48223"},
                   {"label": "Atrauli", "value": "C48224"},
                   {"label": "Atraulia", "value": "C48225"},
                   {"label": "Auraiya", "value": "C48226"},
                   {"label": "Auras", "value": "C48227"},
                   {"label": "Ayodhya", "value": "C48228"},
                   {"label": "Azamgarh", "value": "C48229"},
                   {"label": "Azizpur", "value": "C48230"},
                   {"label": "Baberu", "value": "C48231"},
                   {"label": "Babina", "value": "C48232"},
                   {"label": "Babrala", "value": "C48233"},
                   {"label": "Babugarh", "value": "C48234"},
                   {"label": "Bachhraon", "value": "C48235"},
                   {"label": "Bachhrawan", "value": "C48236"},
                   {"label": "Baghpat", "value": "C48237"},
                   {"label": "Baghra", "value": "C48238"},
                   {"label": "Bah", "value": "C48239"},
                   {"label": "Baheri", "value": "C48240"},
                   {"label": "Bahjoi", "value": "C48241"},
                   {"label": "Bahraich", "value": "C48242"},
                   {"label": "Bahraigh", "value": "C48243"},
                   {"label": "Bahsuma", "value": "C48244"},
                   {"label": "Bahua", "value": "C48245"},
                   {"label": "Bajna", "value": "C48246"},
                   {"label": "Bakewar", "value": "C48247"},
                   {"label": "Baksar", "value": "C48248"},
                   {"label": "Balamau", "value": "C48249"},
                   {"label": "Baldeo", "value": "C48250"},
                   {"label": "Baldev", "value": "C48251"},
                   {"label": "Ballia", "value": "C48252"},
                   {"label": "Balrampur", "value": "C48253"},
                   {"label": "Banat", "value": "C48254"},
                   {"label": "Banbasa", "value": "C48255"},
                   {"label": "Banda", "value": "C48256"},
                   {"label": "Bangarmau", "value": "C48257"},
                   {"label": "Bansdih", "value": "C48258"},
                   {"label": "Bansgaon", "value": "C48259"},
                   {"label": "Bansi", "value": "C48260"},
                   {"label": "Banthra", "value": "C48261"},
                   {"label": "Bara Banki", "value": "C48262"},
                   {"label": "Baragaon", "value": "C48263"},
                   {"label": "Baraut", "value": "C48264"},
                   {"label": "Bareilly", "value": "C48265"},
                   {"label": "Barhalganj", "value": "C48266"},
                   {"label": "Barkhera", "value": "C48267"},
                   {"label": "Barkhera Kalan", "value": "C48268"},
                   {"label": "Barokhar", "value": "C48269"},
                   {"label": "Barsana", "value": "C48270"},
                   {"label": "Barwar (Lakhimpur Kheri)", "value": "C48271"},
                   {"label": "Basti", "value": "C48272"},
                   {"label": "Behat", "value": "C48273"},
                   {"label": "Bela", "value": "C48274"},
                   {"label": "Belthara", "value": "C48275"},
                   {"label": "Beniganj", "value": "C48276"},
                   {"label": "Beswan", "value": "C48277"},
                   {"label": "Bewar", "value": "C48278"},
                   {"label": "Bhadarsa", "value": "C48279"},
                   {"label": "Bhadohi", "value": "C48280"},
                   {"label": "Bhagwantnagar", "value": "C48281"},
                   {"label": "Bharatpura", "value": "C48282"},
                   {"label": "Bhargain", "value": "C48283"},
                   {"label": "Bharthana", "value": "C48284"},
                   {"label": "Bharwari", "value": "C48285"},
                   {"label": "Bhaupur", "value": "C48286"},
                   {"label": "Bhimtal", "value": "C48287"},
                   {"label": "Bhinga", "value": "C48288"},
                   {"label": "Bhognipur", "value": "C48289"},
                   {"label": "Bhongaon", "value": "C48290"},
                   {"label": "Bidhnu", "value": "C48291"},
                   {"label": "Bidhuna", "value": "C48292"},
                   {"label": "Bighapur", "value": "C48293"},
                   {"label": "Bighapur Khurd", "value": "C48294"},
                   {"label": "Bijnor", "value": "C48295"},
                   {"label": "Bikapur", "value": "C48296"},
                   {"label": "Bilari", "value": "C48297"},
                   {"label": "Bilariaganj", "value": "C48298"},
                   {"label": "Bilaspur", "value": "C48299"},
                   {"label": "Bilgram", "value": "C48300"},
                   {"label": "Bilhaur", "value": "C48301"},
                   {"label": "Bilsanda", "value": "C48302"},
                   {"label": "Bilsi", "value": "C48303"},
                   {"label": "Bilthra", "value": "C48304"},
                   {"label": "Binauli", "value": "C48305"},
                   {"label": "Binaur", "value": "C48306"},
                   {"label": "Bindki", "value": "C48307"},
                   {"label": "Birdpur", "value": "C48308"},
                   {"label": "Birpur", "value": "C48309"},
                   {"label": "Bisalpur", "value": "C48310"},
                   {"label": "Bisanda Buzurg", "value": "C48311"},
                   {"label": "Bisauli", "value": "C48312"},
                   {"label": "Bisenda Buzurg", "value": "C48313"},
                   {"label": "Bishunpur Urf Maharajganj", "value": "C48314"},
                   {"label": "Biswan", "value": "C48315"},
                   {"label": "Bithur", "value": "C48316"},
                   {"label": "Budaun", "value": "C48317"},
                   {"label": "Budhana", "value": "C48318"},
                   {"label": "Bulandshahr", "value": "C48319"},
                   {"label": "Captainganj", "value": "C48320"},
                   {"label": "Chail", "value": "C48321"},
                   {"label": "Chakia", "value": "C48322"},
                   {"label": "Chandauli", "value": "C48323"},
                   {"label": "Chandauli District", "value": "C48324"},
                   {"label": "Chandausi", "value": "C48325"},
                   {"label": "Chandpur", "value": "C48326"},
                   {"label": "Chanduasi", "value": "C48327"},
                   {"label": "Charkhari", "value": "C48328"},
                   {"label": "Charthawal", "value": "C48329"},
                   {"label": "Chhaprauli", "value": "C48330"},
                   {"label": "Chharra", "value": "C48331"},
                   {"label": "Chhata", "value": "C48332"},
                   {"label": "Chhibramau", "value": "C48333"},
                   {"label": "Chhitauni", "value": "C48334"},
                   {"label": "Chhutmalpur", "value": "C48335"},
                   {"label": "Chillupar", "value": "C48336"},
                   {"label": "Chirgaon", "value": "C48337"},
                   {"label": "Chitrakoot", "value": "C48338"},
                   {"label": "Chitrakoot Dham", "value": "C48339"},
                   {"label": "Chopan", "value": "C48340"},
                   {"label": "Chunar", "value": "C48341"},
                   {"label": "Churk", "value": "C48342"},
                   {"label": "Colonelganj", "value": "C48343"},
                   {"label": "Dadri", "value": "C48344"},
                   {"label": "Dalmau", "value": "C48345"},
                   {"label": "Dankaur", "value": "C48346"},
                   {"label": "Daraganj", "value": "C48347"},
                   {"label": "Daranagar", "value": "C48348"},
                   {"label": "Dasna", "value": "C48349"},
                   {"label": "Dataganj", "value": "C48350"},
                   {"label": "Daurala", "value": "C48351"},
                   {"label": "Dayal Bagh", "value": "C48352"},
                   {"label": "Deoband", "value": "C48353"},
                   {"label": "Deogarh", "value": "C48354"},
                   {"label": "Deoranian", "value": "C48355"},
                   {"label": "Deoria", "value": "C48356"},
                   {"label": "Derapur", "value": "C48357"},
                   {"label": "Dewa", "value": "C48358"},
                   {"label": "Dhampur", "value": "C48359"},
                   {"label": "Dhanaura", "value": "C48360"},
                   {"label": "Dhanghata", "value": "C48361"},
                   {"label": "Dharau", "value": "C48362"},
                   {"label": "Dhaurahra", "value": "C48363"},
                   {"label": "Dibai", "value": "C48364"},
                   {"label": "Divrasai", "value": "C48365"},
                   {"label": "Dohrighat", "value": "C48366"},
                   {"label": "Domariaganj", "value": "C48367"},
                   {"label": "Dostpur", "value": "C48368"},
                   {"label": "Dudhi", "value": "C48369"},
                   {"label": "Etah", "value": "C48370"},
                   {"label": "Etawah", "value": "C48371"},
                   {"label": "Etmadpur", "value": "C48372"},
                   {"label": "Faizabad", "value": "C48373"},
                   {"label": "Farah", "value": "C48374"},
                   {"label": "Faridnagar", "value": "C48375"},
                   {"label": "Faridpur", "value": "C48376"},
                   {"label": "Farrukhabad", "value": "C48377"},
                   {"label": "Fatehabad", "value": "C48378"},
                   {"label": "Fatehganj West", "value": "C48379"},
                   {"label": "Fatehgarh", "value": "C48380"},
                   {"label": "Fatehpur", "value": "C48381"},
                   {"label": "Fatehpur (Barabanki)", "value": "C48382"},
                   {"label": "Fatehpur Chaurasi", "value": "C48383"},
                   {"label": "Fatehpur Sikri", "value": "C48384"},
                   {"label": "Firozabad", "value": "C48385"},
                   {"label": "Fyzabad", "value": "C48386"},
                   {"label": "Gahlon", "value": "C48387"},
                   {"label": "Gahmar", "value": "C48388"},
                   {"label": "Gaini", "value": "C48389"},
                   {"label": "Gajraula", "value": "C48390"},
                   {"label": "Gangoh", "value": "C48391"},
                   {"label": "Ganj Dundawara", "value": "C48392"},
                   {"label": "Ganj Dundwara", "value": "C48393"},
                   {"label": "Ganj Muradabad", "value": "C48394"},
                   {"label": "Garautha", "value": "C48395"},
                   {"label": "Garhi Pukhta", "value": "C48396"},
                   {"label": "Garhmuktesar", "value": "C48397"},
                   {"label": "Garhwa", "value": "C48398"},
                   {"label": "Gauriganj", "value": "C48399"},
                   {"label": "Gautam Buddha Nagar", "value": "C48400"},
                   {"label": "Gawan", "value": "C48401"},
                   {"label": "Ghatampur", "value": "C48402"},
                   {"label": "Ghaziabad", "value": "C48403"},
                   {"label": "Ghazipur", "value": "C48404"},
                   {"label": "Ghiror", "value": "C48405"},
                   {"label": "Ghorawal", "value": "C48406"},
                   {"label": "Ghosi", "value": "C48407"},
                   {"label": "Gohand", "value": "C48408"},
                   {"label": "Gokul", "value": "C48409"},
                   {"label": "Gola Bazar", "value": "C48410"},
                   {"label": "Gola Gokarannath", "value": "C48411"},
                   {"label": "Gonda", "value": "C48412"},
                   {"label": "Gonda (Aligarh)", "value": "C48413"},
                   {"label": "Gonda City", "value": "C48414"},
                   {"label": "Gopamau", "value": "C48415"},
                   {"label": "Gorakhpur", "value": "C48416"},
                   {"label": "Gosainganj", "value": "C48417"},
                   {"label": "Goshainganj", "value": "C48418"},
                   {"label": "Govardhan", "value": "C48419"},
                   {"label": "Greater Noida", "value": "C48420"},
                   {"label": "Gulaothi", "value": "C48421"},
                   {"label": "Gunnaur", "value": "C48422"},
                   {"label": "Gursahaiganj", "value": "C48423"},
                   {"label": "Gursarai", "value": "C48424"},
                   {"label": "Gyanpur", "value": "C48425"},
                   {"label": "Haldaur", "value": "C48426"},
                   {"label": "Hamirpur", "value": "C48427"},
                   {"label": "Handia", "value": "C48428"},
                   {"label": "Hapur", "value": "C48429"},
                   {"label": "Haraipur", "value": "C48430"},
                   {"label": "Haraiya", "value": "C48431"},
                   {"label": "Harchandpur", "value": "C48432"},
                   {"label": "Hardoi", "value": "C48433"},
                   {"label": "Harduaganj", "value": "C48434"},
                   {"label": "Hasanpur", "value": "C48435"},
                   {"label": "Hastinapur", "value": "C48436"},
                   {"label": "Hata", "value": "C48437"},
                   {"label": "Hata (India)", "value": "C48438"},
                   {"label": "Hathras", "value": "C48439"},
                   {"label": "Hulas", "value": "C48440"},
                   {"label": "Ibrahimpur", "value": "C48441"},
                   {"label": "Iglas", "value": "C48442"},
                   {"label": "Ikauna", "value": "C48443"},
                   {"label": "Indergarh", "value": "C48444"},
                   {"label": "Indragarh", "value": "C48445"},
                   {"label": "Islamnagar", "value": "C48446"},
                   {"label": "Islamnagar (Badaun)", "value": "C48447"},
                   {"label": "Itaunja", "value": "C48448"},
                   {"label": "Itimadpur", "value": "C48449"},
                   {"label": "Jagdishpur", "value": "C48450"},
                   {"label": "Jagnair", "value": "C48451"},
                   {"label": "Jahanabad", "value": "C48452"},
                   {"label": "Jahanabad (Pilibhit)", "value": "C48453"},
                   {"label": "Jahangirabad", "value": "C48454"},
                   {"label": "Jahangirpur", "value": "C48455"},
                   {"label": "Jainpur", "value": "C48456"},
                   {"label": "Jais", "value": "C48457"},
                   {"label": "Jalalabad", "value": "C48458"},
                   {"label": "Jalali", "value": "C48459"},
                   {"label": "Jalalpur", "value": "C48460"},
                   {"label": "Jalaun", "value": "C48461"},
                   {"label": "Jalesar", "value": "C48462"},
                   {"label": "Janghai", "value": "C48463"},
                   {"label": "Jansath", "value": "C48464"},
                   {"label": "Jarwa", "value": "C48465"},
                   {"label": "Jarwal", "value": "C48466"},
                   {"label": "Jasrana", "value": "C48467"},
                   {"label": "Jaswantnagar", "value": "C48468"},
                   {"label": "Jaunpur", "value": "C48469"},
                   {"label": "Jewar", "value": "C48470"},
                   {"label": "Jhajhar", "value": "C48471"},
                   {"label": "Jhalu", "value": "C48472"},
                   {"label": "Jhansi", "value": "C48473"},
                   {"label": "Jhinjhak", "value": "C48474"},
                   {"label": "Jhinjhana", "value": "C48475"},
                   {"label": "Jhusi", "value": "C48476"},
                   {"label": "Jiyanpur", "value": "C48477"},
                   {"label": "Jyotiba Phule Nagar", "value": "C48478"},
                   {"label": "Kabrai", "value": "C48479"},
                   {"label": "Kachhwa", "value": "C48480"},
                   {"label": "Kadaura", "value": "C48481"},
                   {"label": "Kadipur", "value": "C48482"},
                   {"label": "Kagarol", "value": "C48483"},
                   {"label": "Kaimganj", "value": "C48484"},
                   {"label": "Kairana", "value": "C48485"},
                   {"label": "Kakori", "value": "C48486"},
                   {"label": "Kakrala", "value": "C48487"},
                   {"label": "Kalinagar", "value": "C48488"},
                   {"label": "Kalpi", "value": "C48489"},
                   {"label": "Kalyanpur", "value": "C48490"},
                   {"label": "Kamalganj", "value": "C48491"},
                   {"label": "Kampil", "value": "C48492"},
                   {"label": "Kandhla", "value": "C48493"},
                   {"label": "Kannauj", "value": "C48494"},
                   {"label": "Kanpur", "value": "C48495"},
                   {"label": "Kanpur Dehat", "value": "C48496"},
                   {"label": "Kant", "value": "C48497"},
                   {"label": "Kanth", "value": "C48498"},
                   {"label": "Kaptanganj", "value": "C48499"},
                   {"label": "Kara", "value": "C48500"},
                   {"label": "Karari", "value": "C48501"},
                   {"label": "Karbigwan", "value": "C48502"},
                   {"label": "Karchana", "value": "C48503"},
                   {"label": "Karhal", "value": "C48504"},
                   {"label": "Kasganj", "value": "C48505"},
                   {"label": "Katra", "value": "C48506"},
                   {"label": "Kausani", "value": "C48507"},
                   {"label": "Kaushambi District", "value": "C48508"},
                   {"label": "Kemri", "value": "C48509"},
                   {"label": "Khada", "value": "C48510"},
                   {"label": "Khaga", "value": "C48511"},
                   {"label": "Khailar", "value": "C48512"},
                   {"label": "Khair", "value": "C48513"},
                   {"label": "Khairabad", "value": "C48514"},
                   {"label": "Khalilabad", "value": "C48515"},
                   {"label": "Khanpur", "value": "C48516"},
                   {"label": "Kharela", "value": "C48517"},
                   {"label": "Khargupur", "value": "C48518"},
                   {"label": "Kharkhauda", "value": "C48519"},
                   {"label": "Khatauli", "value": "C48520"},
                   {"label": "Khekra", "value": "C48521"},
                   {"label": "Kheri", "value": "C48522"},
                   {"label": "Khudaganj", "value": "C48523"},
                   {"label": "Khurja", "value": "C48524"},
                   {"label": "Khutar", "value": "C48525"},
                   {"label": "Kirakat", "value": "C48526"},
                   {"label": "Kiraoli", "value": "C48527"},
                   {"label": "Kiratpur", "value": "C48528"},
                   {"label": "Kishanpur", "value": "C48529"},
                   {"label": "Kishanpur baral", "value": "C48530"},
                   {"label": "Kishni", "value": "C48531"},
                   {"label": "Kithor", "value": "C48532"},
                   {"label": "Konch", "value": "C48533"},
                   {"label": "Kopaganj", "value": "C48534"},
                   {"label": "Kosi", "value": "C48535"},
                   {"label": "Kota", "value": "C48536"},
                   {"label": "Kotra", "value": "C48537"},
                   {"label": "Kuchesar", "value": "C48538"},
                   {"label": "Kudarkot", "value": "C48539"},
                   {"label": "Kulpahar", "value": "C48540"},
                   {"label": "Kunda", "value": "C48541"},
                   {"label": "Kundarkhi", "value": "C48542"},
                   {"label": "Kundarki", "value": "C48543"},
                   {"label": "Kurara", "value": "C48544"},
                   {"label": "Kurebhar,saidkhanpur", "value": "C48545"},
                   {"label": "Kushinagar", "value": "C48546"},
                   {"label": "Kusmara", "value": "C48547"},
                   {"label": "Kuthaund", "value": "C48548"},
                   {"label": "Laharpur", "value": "C48549"},
                   {"label": "Lakhimpur", "value": "C48550"},
                   {"label": "Lakhna", "value": "C48551"},
                   {"label": "Lalganj", "value": "C48552"},
                   {"label": "Lalitpur", "value": "C48553"},
                   {"label": "Lambhua", "value": "C48554"},
                   {"label": "Lar", "value": "C48555"},
                   {"label": "Lawar", "value": "C48556"},
                   {"label": "Lawar Khas", "value": "C48557"},
                   {"label": "Loni", "value": "C48558"},
                   {"label": "Lucknow", "value": "C48559"},
                   {"label": "Lucknow District", "value": "C48560"},
                   {"label": "Machhali Shahar", "value": "C48561"},
                   {"label": "Machhlishahr", "value": "C48562"},
                   {"label": "Madhoganj", "value": "C48563"},
                   {"label": "Madhogarh", "value": "C48564"},
                   {"label": "Maghar", "value": "C48565"},
                   {"label": "Mahaban", "value": "C48566"},
                   {"label": "Maharajganj", "value": "C48567"},
                   {"label": "Mahmudabad", "value": "C48568"},
                   {"label": "Mahoba", "value": "C48569"},
                   {"label": "Maholi", "value": "C48570"},
                   {"label": "Mahrajganj", "value": "C48571"},
                   {"label": "Mahrajganj (Raebareli)", "value": "C48572"},
                   {"label": "Mahroni", "value": "C48573"},
                   {"label": "Mahul", "value": "C48574"},
                   {"label": "Mailani", "value": "C48575"},
                   {"label": "Mainpuri", "value": "C48576"},
                   {"label": "Majhupur", "value": "C48577"},
                   {"label": "Makanpur", "value": "C48578"},
                   {"label": "Malasa", "value": "C48579"},
                   {"label": "Malihabad", "value": "C48580"},
                   {"label": "Mandawar", "value": "C48581"},
                   {"label": "Maniar", "value": "C48582"},
                   {"label": "Manikpur", "value": "C48583"},
                   {"label": "Manjhanpur", "value": "C48584"},
                   {"label": "Mankapur", "value": "C48585"},
                   {"label": "Marahra", "value": "C48586"},
                   {"label": "Mariahu", "value": "C48587"},
                   {"label": "Mataundh", "value": "C48588"},
                   {"label": "Mathura", "value": "C48589"},
                   {"label": "Mau", "value": "C48590"},
                   {"label": "Mau Aima", "value": "C48591"},
                   {"label": "Mau Aimma", "value": "C48592"},
                   {"label": "Maudaha", "value": "C48593"},
                   {"label": "Mauranwan", "value": "C48594"},
                   {"label": "Maurawan", "value": "C48595"},
                   {"label": "Mawana", "value": "C48596"},
                   {"label": "Mawar", "value": "C48597"},
                   {"label": "Meerut", "value": "C48598"},
                   {"label": "Mehdawal", "value": "C48599"},
                   {"label": "Mehnagar", "value": "C48600"},
                   {"label": "Mehndawal", "value": "C48601"},
                   {"label": "Milak", "value": "C48602"},
                   {"label": "Milkipur", "value": "C48603"},
                   {"label": "Miranpur", "value": "C48604"},
                   {"label": "Miranpur Katra", "value": "C48605"},
                   {"label": "Mirganj", "value": "C48606"},
                   {"label": "Mirzapur", "value": "C48607"},
                   {"label": "Misrikh", "value": "C48608"},
                   {"label": "Mohan", "value": "C48609"},
                   {"label": "Mohanpur", "value": "C48610"},
                   {"label": "Moradabad", "value": "C48611"},
                   {"label": "Moth", "value": "C48612"},
                   {"label": "Mubarakpur", "value": "C48613"},
                   {"label": "Mughal Sarai", "value": "C48614"},
                   {"label": "Muhammadabad", "value": "C48615"},
                   {"label": "Mukteshwar", "value": "C48616"},
                   {"label": "Mungra Badshahpur", "value": "C48617"},
                   {"label": "Munsyari", "value": "C48618"},
                   {"label": "Muradabad", "value": "C48619"},
                   {"label": "Muradnagar", "value": "C48620"},
                   {"label": "Mursan", "value": "C48621"},
                   {"label": "Musafir-Khana", "value": "C48622"},
                   {"label": "Musafirkhana", "value": "C48623"},
                   {"label": "Muzaffarnagar", "value": "C48624"},
                   {"label": "Nadigaon", "value": "C48625"},
                   {"label": "Nagina", "value": "C48626"},
                   {"label": "Nagla", "value": "C48627"},
                   {"label": "Nagram", "value": "C48628"},
                   {"label": "Najibabad", "value": "C48629"},
                   {"label": "Nakur", "value": "C48630"},
                   {"label": "Nanauta", "value": "C48631"},
                   {"label": "Nandgaon", "value": "C48632"},
                   {"label": "Nanpara", "value": "C48633"},
                   {"label": "Narauli", "value": "C48634"},
                   {"label": "Naraura", "value": "C48635"},
                   {"label": "Narora", "value": "C48636"},
                   {"label": "Naugama", "value": "C48637"},
                   {"label": "Naurangpur", "value": "C48638"},
                   {"label": "Nautanwa", "value": "C48639"},
                   {"label": "Nawabganj", "value": "C48640"},
                   {"label": "Nawabganj (Barabanki)", "value": "C48641"},
                   {"label": "Nawabganj (Bareilly)", "value": "C48642"},
                   {"label": "Newara", "value": "C48643"},
                   {"label": "Nichlaul", "value": "C48644"},
                   {"label": "Nigoh", "value": "C48645"},
                   {"label": "Nihtaur", "value": "C48646"},
                   {"label": "Niwari", "value": "C48647"},
                   {"label": "Nizamabad", "value": "C48648"},
                   {"label": "Noida", "value": "C48649"},
                   {"label": "Nurpur", "value": "C48650"},
                   {"label": "Obra", "value": "C48651"},
                   {"label": "Orai", "value": "C48652"},
                   {"label": "Oran", "value": "C48653"},
                   {"label": "Pachperwa", "value": "C48654"},
                   {"label": "Padrauna", "value": "C48655"},
                   {"label": "Pahasu", "value": "C48656"},
                   {"label": "Paigaon", "value": "C48657"},
                   {"label": "Pali", "value": "C48658"},
                   {"label": "Palia Kalan", "value": "C48659"},
                   {"label": "Paras Rampur", "value": "C48660"},
                   {"label": "Parichha", "value": "C48661"},
                   {"label": "Parichhatgarh", "value": "C48662"},
                   {"label": "Parshadepur", "value": "C48663"},
                   {"label": "Pathakpura", "value": "C48664"},
                   {"label": "Patiali", "value": "C48665"},
                   {"label": "Patti", "value": "C48666"},
                   {"label": "Pawayan", "value": "C48667"},
                   {"label": "Payagpur", "value": "C48668"},
                   {"label": "Phalauda", "value": "C48669"},
                   {"label": "Phaphamau", "value": "C48670"},
                   {"label": "Phaphund", "value": "C48671"},
                   {"label": "Phariha", "value": "C48672"},
                   {"label": "Pheona", "value": "C48673"},
                   {"label": "Phulpur", "value": "C48674"},
                   {"label": "Pichhaura", "value": "C48675"},
                   {"label": "Pihani", "value": "C48676"},
                   {"label": "Pilibhit", "value": "C48677"},
                   {"label": "Pilkhua", "value": "C48678"},
                   {"label": "Pilkhuwa", "value": "C48679"},
                   {"label": "Pinahat", "value": "C48680"},
                   {"label": "Pipraich", "value": "C48681"},
                   {"label": "Pipri", "value": "C48682"},
                   {"label": "Pratapgarh", "value": "C48683"},
                   {"label": "Prayagraj (Allahabad)", "value": "C48684"},
                   {"label": "Pukhrayan", "value": "C48685"},
                   {"label": "Puranpur", "value": "C48686"},
                   {"label": "Purmafi", "value": "C48687"},
                   {"label": "Purwa", "value": "C48688"},
                   {"label": "Qadirganj", "value": "C48689"},
                   {"label": "Rabupura", "value": "C48690"},
                   {"label": "Radha Kund", "value": "C48691"},
                   {"label": "Radhakund", "value": "C48692"},
                   {"label": "Raebareli", "value": "C48693"},
                   {"label": "Rajapur", "value": "C48694"},
                   {"label": "Ramkola", "value": "C48695"},
                   {"label": "Ramnagar", "value": "C48696"},
                   {"label": "Rampur", "value": "C48697"},
                   {"label": "Rampura", "value": "C48698"},
                   {"label": "Ranipur", "value": "C48699"},
                   {"label": "Ranipur Barsi", "value": "C48700"},
                   {"label": "Rasra", "value": "C48701"},
                   {"label": "Rasulabad", "value": "C48702"},
                   {"label": "Rath", "value": "C48703"},
                   {"label": "Raya", "value": "C48704"},
                   {"label": "Rehar", "value": "C48705"},
                   {"label": "Renukoot", "value": "C48706"},
                   {"label": "Renukut", "value": "C48707"},
                   {"label": "Reoti", "value": "C48708"},
                   {"label": "Reotipur", "value": "C48709"},
                   {"label": "Richha", "value": "C48710"},
                   {"label": "Robertsganj", "value": "C48711"},
                   {"label": "Rudarpur", "value": "C48712"},
                   {"label": "Rudauli", "value": "C48713"},
                   {"label": "Rura", "value": "C48714"},
                   {"label": "Sabalpur", "value": "C48715"},
                   {"label": "Sachendi", "value": "C48716"},
                   {"label": "Sadabad", "value": "C48717"},
                   {"label": "Sadat", "value": "C48718"},
                   {"label": "Safipur", "value": "C48719"},
                   {"label": "Saharanpur", "value": "C48720"},
                   {"label": "Sahaspur", "value": "C48721"},
                   {"label": "Sahaswan", "value": "C48722"},
                   {"label": "Sahawar", "value": "C48723"},
                   {"label": "Sahibabad", "value": "C48724"},
                   {"label": "Sahpau", "value": "C48725"},
                   {"label": "Saidpur", "value": "C48726"},
                   {"label": "Sakhanu", "value": "C48727"},
                   {"label": "Sakit", "value": "C48728"},
                   {"label": "Salempur", "value": "C48729"},
                   {"label": "Salon", "value": "C48730"},
                   {"label": "Sambhal", "value": "C48731"},
                   {"label": "Samthar", "value": "C48732"},
                   {"label": "Sandi", "value": "C48733"},
                   {"label": "Sandila", "value": "C48734"},
                   {"label": "Sant Kabir Nagar", "value": "C48735"},
                   {"label": "Sant Ravi Das Nagar", "value": "C48736"},
                   {"label": "Sarai Akil", "value": "C48737"},
                   {"label": "Sarai Ekdil", "value": "C48738"},
                   {"label": "Sarai Mir", "value": "C48739"},
                   {"label": "Sarauli", "value": "C48740"},
                   {"label": "Sardhana", "value": "C48741"},
                   {"label": "Sarila", "value": "C48742"},
                   {"label": "Sarurpur", "value": "C48743"},
                   {"label": "Sasni", "value": "C48744"},
                   {"label": "Satrikh", "value": "C48745"},
                   {"label": "Saurikh", "value": "C48746"},
                   {"label": "Sector", "value": "C48747"},
                   {"label": "Seohara", "value": "C48748"},
                   {"label": "Shahabad", "value": "C48749"},
                   {"label": "Shahganj", "value": "C48750"},
                   {"label": "Shahi", "value": "C48751"},
                   {"label": "Shahjahanpur", "value": "C48752"},
                   {"label": "Shahpur", "value": "C48753"},
                   {"label": "Shamli", "value": "C48754"},
                   {"label": "Shamsabad", "value": "C48755"},
                   {"label": "Shankargarh", "value": "C48756"},
                   {"label": "Shergarh", "value": "C48757"},
                   {"label": "Sherkot", "value": "C48758"},
                   {"label": "Shibnagar", "value": "C48759"},
                   {"label": "Shikarpur", "value": "C48760"},
                   {"label": "Shikarpur (Bulandshahr)", "value": "C48761"},
                   {"label": "Shikohabad", "value": "C48762"},
                   {"label": "Shishgarh", "value": "C48763"},
                   {"label": "Shivrajpur", "value": "C48764"},
                   {"label": "Shrawasti", "value": "C48765"},
                   {"label": "Siddharthnagar", "value": "C48766"},
                   {"label": "Siddhaur", "value": "C48767"},
                   {"label": "Sidhauli", "value": "C48768"},
                   {"label": "Sidhpura", "value": "C48769"},
                   {"label": "Sikandarabad", "value": "C48770"},
                   {"label": "Sikandarpur", "value": "C48771"},
                   {"label": "Sikandra", "value": "C48772"},
                   {"label": "Sikandra Rao", "value": "C48773"},
                   {"label": "Sikandrabad", "value": "C48774"},
                   {"label": "Sirathu", "value": "C48775"},
                   {"label": "Sirsa", "value": "C48776"},
                   {"label": "Sirsaganj", "value": "C48777"},
                   {"label": "Sirsi", "value": "C48778"},
                   {"label": "Sisauli", "value": "C48779"},
                   {"label": "Siswa Bazar", "value": "C48780"},
                   {"label": "Sitapur", "value": "C48781"},
                   {"label": "Sonbhadra", "value": "C48782"},
                   {"label": "Soron", "value": "C48783"},
                   {"label": "Suar", "value": "C48784"},
                   {"label": "Sultanpur", "value": "C48785"},
                   {"label": "Surianwan", "value": "C48786"},
                   {"label": "Tajpur", "value": "C48787"},
                   {"label": "Talbahat", "value": "C48788"},
                   {"label": "Talgram", "value": "C48789"},
                   {"label": "Tanda", "value": "C48790"},
                   {"label": "Terha", "value": "C48791"},
                   {"label": "Thakurdwara", "value": "C48792"},
                   {"label": "Thana Bhawan", "value": "C48793"},
                   {"label": "Tigri", "value": "C48794"},
                   {"label": "Tikaitnagar", "value": "C48795"},
                   {"label": "Tikri", "value": "C48796"},
                   {"label": "Tilhar", "value": "C48797"},
                   {"label": "Tilsahri", "value": "C48798"},
                   {"label": "Tindwari", "value": "C48799"},
                   {"label": "Titron", "value": "C48800"},
                   {"label": "Tori Fatehpur", "value": "C48801"},
                   {"label": "Tori-Fatehpur", "value": "C48802"},
                   {"label": "Tulsipur", "value": "C48803"},
                   {"label": "Tundla", "value": "C48804"},
                   {"label": "Ugu", "value": "C48805"},
                   {"label": "Ujhani", "value": "C48806"},
                   {"label": "Umri", "value": "C48807"},
                   {"label": "Un", "value": "C48808"},
                   {"label": "Unnao", "value": "C48809"},
                   {"label": "Usawan", "value": "C48810"},
                   {"label": "Usehat", "value": "C48811"},
                   {"label": "Uska", "value": "C48812"},
                   {"label": "Utraula", "value": "C48813"},
                   {"label": "Varanasi", "value": "C48814"},
                   {"label": "Vindhyachal", "value": "C48815"},
                   {"label": "Vrindavan", "value": "C48816"},
                   {"label": "Walterganj", "value": "C48817"},
                   {"label": "Wazirganj", "value": "C48818"},
                   {"label": "Yusufpur", "value": "C48819"},
                   {"label": "Zafarabad", "value": "C48820"},
                   {"label": "Zaidpur", "value": "C48821"},
                   {"label": "Zamania", "value": "C48822"}],
 "Uttaradit": [{"label": "Amphoe Ban Khok", "value": "C119574"},
               {"label": "Amphoe Fak Tha", "value": "C119575"},
               {"label": "Amphoe Lap Lae", "value": "C119576"},
               {"label": "Amphoe Mueang Uttaradit", "value": "C119577"},
               {"label": "Amphoe Nam Pat", "value": "C119578"},
               {"label": "Amphoe Phichai", "value": "C119579"},
               {"label": "Amphoe Tha Pla", "value": "C119580"},
               {"label": "Amphoe Thong Saen Khan", "value": "C119581"},
               {"label": "Amphoe Tron", "value": "C119582"},
               {"label": "Uttaradit", "value": "C119583"}],
 "Uttarakhand": [{"label": "Almora", "value": "C48823"},
                 {"label": "Bageshwar", "value": "C48824"},
                 {"label": "Barkot", "value": "C48825"},
                 {"label": "Bazpur", "value": "C48826"},
                 {"label": "Bhim Tal", "value": "C48827"},
                 {"label": "Bhowali", "value": "C48828"},
                 {"label": "Birbhaddar", "value": "C48829"},
                 {"label": "Chakrata", "value": "C48830"},
                 {"label": "Chamoli", "value": "C48831"},
                 {"label": "Champawat", "value": "C48832"},
                 {"label": "Clement Town", "value": "C48833"},
                 {"label": "Dehradun", "value": "C48834"},
                 {"label": "Devaprayag", "value": "C48835"},
                 {"label": "Dharchula", "value": "C48836"},
                 {"label": "Doiwala", "value": "C48837"},
                 {"label": "Dugadda", "value": "C48838"},
                 {"label": "Dwarahat", "value": "C48839"},
                 {"label": "Garhwal", "value": "C48840"},
                 {"label": "Haldwani", "value": "C48841"},
                 {"label": "Harbatpur", "value": "C48842"},
                 {"label": "Haridwar", "value": "C48843"},
                 {"label": "Jaspur", "value": "C48844"},
                 {"label": "Joshimath", "value": "C48845"},
                 {"label": "Kaladhungi", "value": "C48846"},
                 {"label": "Kalagarh Project Colony", "value": "C48847"},
                 {"label": "Kashipur", "value": "C48848"},
                 {"label": "Khatima", "value": "C48849"},
                 {"label": "Kichha", "value": "C48850"},
                 {"label": "Kotdwara", "value": "C48851"},
                 {"label": "Laksar", "value": "C48852"},
                 {"label": "Lansdowne", "value": "C48853"},
                 {"label": "Lohaghat", "value": "C48854"},
                 {"label": "Manglaur", "value": "C48855"},
                 {"label": "Mussoorie", "value": "C48856"},
                 {"label": "Naini Tal", "value": "C48857"},
                 {"label": "Narendranagar", "value": "C48858"},
                 {"label": "Pauri", "value": "C48859"},
                 {"label": "Pipalkoti", "value": "C48860"},
                 {"label": "Pithoragarh", "value": "C48861"},
                 {"label": "Raipur", "value": "C48862"},
                 {"label": "Raiwala Bara", "value": "C48863"},
                 {"label": "Ramnagar", "value": "C48864"},
                 {"label": "Ranikhet", "value": "C48865"},
                 {"label": "Rishikesh", "value": "C48866"},
                 {"label": "Roorkee", "value": "C48867"},
                 {"label": "Rudraprayag", "value": "C48868"},
                 {"label": "Sitarganj", "value": "C48869"},
                 {"label": "Srinagar", "value": "C48870"},
                 {"label": "Sultanpur", "value": "C48871"},
                 {"label": "Tanakpur", "value": "C48872"},
                 {"label": "Tehri", "value": "C48873"},
                 {"label": "Tehri-Garhwal", "value": "C48874"},
                 {"label": "Udham Singh Nagar", "value": "C48875"},
                 {"label": "Uttarkashi", "value": "C48876"},
                 {"label": "Vikasnagar", "value": "C48877"}],
 "Uusimaa": [{"label": "Askola", "value": "C25181"},
             {"label": "Ekenas", "value": "C25182"},
             {"label": "Espoo", "value": "C25183"},
             {"label": "Gumbole", "value": "C25184"},
             {"label": "Hanko", "value": "C25185"},
             {"label": "Helsinki", "value": "C25186"},
             {"label": "Hyvinge", "value": "C25187"},
             {"label": "Inga", "value": "C25188"},
             {"label": "Jarvenpaa", "value": "C25189"},
             {"label": "Kaarela", "value": "C25190"},
             {"label": "Kallio", "value": "C25191"},
             {"label": "Karis", "value": "C25192"},
             {"label": "Karjalohja", "value": "C25193"},
             {"label": "Karkkila", "value": "C25194"},
             {"label": "Karkola", "value": "C25195"},
             {"label": "Kauniainen", "value": "C25196"},
             {"label": "Kellokoski", "value": "C25197"},
             {"label": "Kerava", "value": "C25198"},
             {"label": "Kilo", "value": "C25199"},
             {"label": "Kirkkonummi", "value": "C25200"},
             {"label": "Koukkuniemi", "value": "C25201"},
             {"label": "Lapinjarvi", "value": "C25202"},
             {"label": "Lauttasaari", "value": "C25203"},
             {"label": "Liljendal", "value": "C25204"},
             {"label": "Lohja", "value": "C25205"},
             {"label": "Lovisa", "value": "C25206"},
             {"label": "Mantsala", "value": "C25207"},
             {"label": "Mellunkyla", "value": "C25208"},
             {"label": "Munkkiniemi", "value": "C25209"},
             {"label": "Myrskyla", "value": "C25210"},
             {"label": "Nurmijarvi", "value": "C25211"},
             {"label": "Otaniemi", "value": "C25212"},
             {"label": "Perna", "value": "C25213"},
             {"label": "Pohja", "value": "C25214"},
             {"label": "Pornainen", "value": "C25215"},
             {"label": "Porvoo", "value": "C25216"},
             {"label": "Pukkila", "value": "C25217"},
             {"label": "Raaseporin", "value": "C25218"},
             {"label": "Ruotsinpyhtaa", "value": "C25219"},
             {"label": "Sammatti", "value": "C25220"},
             {"label": "Sibbo", "value": "C25221"},
             {"label": "Siuntio", "value": "C25222"},
             {"label": "Tuusula", "value": "C25223"},
             {"label": "Vantaa", "value": "C25224"},
             {"label": "Vihti", "value": "C25225"},
             {"label": "Vuosaari", "value": "C25226"}],
 "Uva Province": [{"label": "Badulla", "value": "C115356"},
                  {"label": "Badulla District", "value": "C115357"},
                  {"label": "Ella Town", "value": "C115358"},
                  {"label": "Haputale", "value": "C115359"},
                  {"label": "Kataragama", "value": "C115360"},
                  {"label": "Monaragala", "value": "C115361"},
                  {"label": "Moneragala District", "value": "C115362"},
                  {"label": "Wellawaya", "value": "C115363"}],
 "Uvs Province": [{"label": "Ulaangom", "value": "C75592"}],
 "Vaa-o-Fonoti": [{"label": "Samamea", "value": "C106243"}],
 "Vaavu Atoll": [{"label": "Felidhoo", "value": "C65821"}],
 "Vaduz": [{"label": "Vaduz", "value": "C65081"}],
 "Vainode Municipality": [{"label": "Vainode", "value": "C64951"}],
 "Vaisigano": [{"label": "Asau", "value": "C106244"}],
 "Vaitupu": [{"label": "Asau Village", "value": "C121199"}],
 "Vakaga Prefecture": [{"label": "Birao", "value": "C17849"},
                       {"label": "Ouanda-Djalle", "value": "C17850"}],
 "Valais": [{"label": "Ardon", "value": "C117250"},
            {"label": "Ayent", "value": "C117251"},
            {"label": "Bagnes", "value": "C117252"},
            {"label": "Baltschieder", "value": "C117253"},
            {"label": "Basse-Nendaz", "value": "C117254"},
            {"label": "Brig", "value": "C117255"},
            {"label": "Brig District", "value": "C117256"},
            {"label": "Chalais", "value": "C117257"},
            {"label": "Chamoson", "value": "C117258"},
            {"label": "Champery", "value": "C117259"},
            {"label": "Charrat", "value": "C117260"},
            {"label": "Chermignon-den Haut", "value": "C117261"},
            {"label": "Chippis", "value": "C117262"},
            {"label": "Collombey", "value": "C117263"},
            {"label": "Conthey", "value": "C117264"},
            {"label": "Conthey District", "value": "C117265"},
            {"label": "Entremont District", "value": "C117266"},
            {"label": "Evionnaz", "value": "C117267"},
            {"label": "Evolene", "value": "C117268"},
            {"label": "Fiesch", "value": "C117269"},
            {"label": "Fully", "value": "C117270"},
            {"label": "Gampel", "value": "C117271"},
            {"label": "Goms District", "value": "C117272"},
            {"label": "Grachen", "value": "C117273"},
            {"label": "Grimisuat", "value": "C117274"},
            {"label": "Grone", "value": "C117275"},
            {"label": "Heremence", "value": "C117276"},
            {"label": "Herens District", "value": "C117277"},
            {"label": "Lens", "value": "C117278"},
            {"label": "Leuk", "value": "C117279"},
            {"label": "Leuk District", "value": "C117280"},
            {"label": "Leukerbad", "value": "C117281"},
            {"label": "Leytron", "value": "C117282"},
            {"label": "Martigny District", "value": "C117283"},
            {"label": "Martigny-Combe", "value": "C117284"},
            {"label": "Martigny-Ville", "value": "C117285"},
            {"label": "Montana", "value": "C117286"},
            {"label": "Monthey", "value": "C117287"},
            {"label": "Monthey District", "value": "C117288"},
            {"label": "Naters", "value": "C117289"},
            {"label": "Orsieres", "value": "C117290"},
            {"label": "Randogne", "value": "C117291"},
            {"label": "Raron", "value": "C117292"},
            {"label": "Raron District", "value": "C117293"},
            {"label": "Riddes", "value": "C117294"},
            {"label": "Saas-Fee", "value": "C117295"},
            {"label": "Saas-Grund", "value": "C117296"},
            {"label": "Saillon", "value": "C117297"},
            {"label": "Saint-Leonard", "value": "C117298"},
            {"label": "Saint-Maurice", "value": "C117299"},
            {"label": "Saint-Maurice District", "value": "C117300"},
            {"label": "Salgesch", "value": "C117301"},
            {"label": "Salvan", "value": "C117302"},
            {"label": "Sankt Niklaus", "value": "C117303"},
            {"label": "Saviese", "value": "C117304"},
            {"label": "Saxon", "value": "C117305"},
            {"label": "Sierre", "value": "C117306"},
            {"label": "Sierre District", "value": "C117307"},
            {"label": "Sion District", "value": "C117308"},
            {"label": "Sitten", "value": "C117309"},
            {"label": "Stalden", "value": "C117310"},
            {"label": "Tanay", "value": "C117311"},
            {"label": "Troistorrents", "value": "C117312"},
            {"label": "Turtmann", "value": "C117313"},
            {"label": "Varen", "value": "C117314"},
            {"label": "Verbier", "value": "C117315"},
            {"label": "Vernayaz", "value": "C117316"},
            {"label": "Vetroz", "value": "C117317"},
            {"label": "Vex", "value": "C117318"},
            {"label": "Vionnaz", "value": "C117319"},
            {"label": "Visp", "value": "C117320"},
            {"label": "Visp District", "value": "C117321"},
            {"label": "Visperterminen", "value": "C117322"},
            {"label": "Vouvry", "value": "C117323"},
            {"label": "Zermatt", "value": "C117324"}],
 "Valandovo Municipality": [{"label": "Pirava", "value": "C65532"},
                            {"label": "Valandovo", "value": "C65533"}],
 "Valcea County": [{"label": "Alunu", "value": "C99590"},
                   {"label": "Amarasti", "value": "C99591"},
                   {"label": "Babeni", "value": "C99592"},
                   {"label": "Babeni-Oltetu", "value": "C99593"},
                   {"label": "Baile Govora", "value": "C99594"},
                   {"label": "Baile Olanesti", "value": "C99595"},
                   {"label": "Balcesti", "value": "C99596"},
                   {"label": "Barbatesti", "value": "C99597"},
                   {"label": "Batarasti", "value": "C99598"},
                   {"label": "Berbesti", "value": "C99599"},
                   {"label": "Berislavesti", "value": "C99600"},
                   {"label": "Bistrita", "value": "C99601"},
                   {"label": "Bodesti", "value": "C99602"},
                   {"label": "Boisoara", "value": "C99603"},
                   {"label": "Brezoi", "value": "C99604"},
                   {"label": "Budesti", "value": "C99605"},
                   {"label": "Bujoreni", "value": "C99606"},
                   {"label": "Bunesti", "value": "C99607"},
                   {"label": "Cainenii Mici", "value": "C99608"},
                   {"label": "Calimanesti", "value": "C99609"},
                   {"label": "Calina", "value": "C99610"},
                   {"label": "Cernisoara", "value": "C99611"},
                   {"label": "Cheia", "value": "C99612"},
                   {"label": "Comuna Alunu", "value": "C99613"},
                   {"label": "Comuna Amarasti", "value": "C99614"},
                   {"label": "Comuna Barbatesti", "value": "C99615"},
                   {"label": "Comuna Berislavesti", "value": "C99616"},
                   {"label": "Comuna Boisoara", "value": "C99617"},
                   {"label": "Comuna Budesti", "value": "C99618"},
                   {"label": "Comuna Bujoreni", "value": "C99619"},
                   {"label": "Comuna Bunesti", "value": "C99620"},
                   {"label": "Comuna Caineni", "value": "C99621"},
                   {"label": "Comuna Cernisoara", "value": "C99622"},
                   {"label": "Comuna Copaceni", "value": "C99623"},
                   {"label": "Comuna Costesti", "value": "C99624"},
                   {"label": "Comuna Creteni", "value": "C99625"},
                   {"label": "Comuna Daesti", "value": "C99626"},
                   {"label": "Comuna Danicei", "value": "C99627"},
                   {"label": "Comuna Diculesti", "value": "C99628"},
                   {"label": "Comuna Dragoesti", "value": "C99629"},
                   {"label": "Comuna Fartatesti", "value": "C99630"},
                   {"label": "Comuna Fauresti", "value": "C99631"},
                   {"label": "Comuna Francesti-Manastireni", "value": "C99632"},
                   {"label": "Comuna Galicea", "value": "C99633"},
                   {"label": "Comuna Ghioroiu", "value": "C99634"},
                   {"label": "Comuna Glavile", "value": "C99635"},
                   {"label": "Comuna Golesti", "value": "C99636"},
                   {"label": "Comuna Gradistea", "value": "C99637"},
                   {"label": "Comuna Gusoeni", "value": "C99638"},
                   {"label": "Comuna Ionesti", "value": "C99639"},
                   {"label": "Comuna Lacusteni", "value": "C99640"},
                   {"label": "Comuna Ladesti", "value": "C99641"},
                   {"label": "Comuna Lalosu", "value": "C99642"},
                   {"label": "Comuna Lapusata", "value": "C99643"},
                   {"label": "Comuna Livezi", "value": "C99644"},
                   {"label": "Comuna Lungesti", "value": "C99645"},
                   {"label": "Comuna Maciuca", "value": "C99646"},
                   {"label": "Comuna Madulari-Beica", "value": "C99647"},
                   {"label": "Comuna Malaia", "value": "C99648"},
                   {"label": "Comuna Maldaresti", "value": "C99649"},
                   {"label": "Comuna Mateesti", "value": "C99650"},
                   {"label": "Comuna Mihaesti", "value": "C99651"},
                   {"label": "Comuna Milcoiu", "value": "C99652"},
                   {"label": "Comuna Mitrofani", "value": "C99653"},
                   {"label": "Comuna Muereasca", "value": "C99654"},
                   {"label": "Comuna Nicolae Balcescu", "value": "C99655"},
                   {"label": "Comuna Olanu", "value": "C99656"},
                   {"label": "Comuna Orlesti", "value": "C99657"},
                   {"label": "Comuna Otesani", "value": "C99658"},
                   {"label": "Comuna Pausesti", "value": "C99659"},
                   {"label": "Comuna Pausesti-Maglasi", "value": "C99660"},
                   {"label": "Comuna Perisani", "value": "C99661"},
                   {"label": "Comuna Pesceana", "value": "C99662"},
                   {"label": "Comuna Pietrari", "value": "C99663"},
                   {"label": "Comuna Popesti", "value": "C99664"},
                   {"label": "Comuna Prundeni", "value": "C99665"},
                   {"label": "Comuna Racovita", "value": "C99666"},
                   {"label": "Comuna Roesti", "value": "C99667"},
                   {"label": "Comuna Rosiile", "value": "C99668"},
                   {"label": "Comuna Runcu", "value": "C99669"},
                   {"label": "Comuna Salatrucel", "value": "C99670"},
                   {"label": "Comuna Scundu", "value": "C99671"},
                   {"label": "Comuna Sinesti", "value": "C99672"},
                   {"label": "Comuna Sirineasa", "value": "C99673"},
                   {"label": "Comuna Slatioara", "value": "C99674"},
                   {"label": "Comuna Stanesti", "value": "C99675"},
                   {"label": "Comuna Stefanesti", "value": "C99676"},
                   {"label": "Comuna Stoenesti", "value": "C99677"},
                   {"label": "Comuna Stoilesti", "value": "C99678"},
                   {"label": "Comuna Stroesti", "value": "C99679"},
                   {"label": "Comuna Susani", "value": "C99680"},
                   {"label": "Comuna Sutesti", "value": "C99681"},
                   {"label": "Comuna Tetoiu", "value": "C99682"},
                   {"label": "Comuna Titesti", "value": "C99683"},
                   {"label": "Comuna Tomsani", "value": "C99684"},
                   {"label": "Comuna Vaideeni", "value": "C99685"},
                   {"label": "Comuna Valea Mare", "value": "C99686"},
                   {"label": "Comuna Vladesti", "value": "C99687"},
                   {"label": "Comuna Voicesti", "value": "C99688"},
                   {"label": "Comuna Voineasa", "value": "C99689"},
                   {"label": "Comuna Zatreni", "value": "C99690"},
                   {"label": "Copacelu", "value": "C99691"},
                   {"label": "Copaceni", "value": "C99692"},
                   {"label": "Cosani", "value": "C99693"},
                   {"label": "Costesti", "value": "C99694"},
                   {"label": "Creteni", "value": "C99695"},
                   {"label": "Daesti", "value": "C99696"},
                   {"label": "Dealu Alunis", "value": "C99697"},
                   {"label": "Dealu Danicei", "value": "C99698"},
                   {"label": "Diculesti", "value": "C99699"},
                   {"label": "Dobrusa", "value": "C99700"},
                   {"label": "Dragasani", "value": "C99701"},
                   {"label": "Dragoesti", "value": "C99702"},
                   {"label": "Fartatesti", "value": "C99703"},
                   {"label": "Fauresti", "value": "C99704"},
                   {"label": "Galicea", "value": "C99705"},
                   {"label": "Ghioroiu", "value": "C99706"},
                   {"label": "Glavile", "value": "C99707"},
                   {"label": "Goranu", "value": "C99708"},
                   {"label": "Gradistea", "value": "C99709"},
                   {"label": "Greblesti", "value": "C99710"},
                   {"label": "Gura Suhasului", "value": "C99711"},
                   {"label": "Gusoeni", "value": "C99712"},
                   {"label": "Horezu", "value": "C99713"},
                   {"label": "Igoiu", "value": "C99714"},
                   {"label": "Ionesti", "value": "C99715"},
                   {"label": "Jiblea Veche", "value": "C99716"},
                   {"label": "Lacusteni", "value": "C99717"},
                   {"label": "Ladesti", "value": "C99718"},
                   {"label": "Lalosu", "value": "C99719"},
                   {"label": "Livadia", "value": "C99720"},
                   {"label": "Livezi", "value": "C99721"},
                   {"label": "Lungesti", "value": "C99722"},
                   {"label": "Madulari", "value": "C99723"},
                   {"label": "Malaia", "value": "C99724"},
                   {"label": "Maldaresti", "value": "C99725"},
                   {"label": "Manailesti", "value": "C99726"},
                   {"label": "Mateesti", "value": "C99727"},
                   {"label": "Mihaesti", "value": "C99728"},
                   {"label": "Milcoiu", "value": "C99729"},
                   {"label": "Mitrofani", "value": "C99730"},
                   {"label": "Mogesti", "value": "C99731"},
                   {"label": "Muereasca", "value": "C99732"},
                   {"label": "Municipiul Dragasani", "value": "C99733"},
                   {"label": "Municipiul Ramnicu Valcea", "value": "C99734"},
                   {"label": "Olanesti", "value": "C99735"},
                   {"label": "Olanu", "value": "C99736"},
                   {"label": "Oras Babeni", "value": "C99737"},
                   {"label": "Oras Baile Govora", "value": "C99738"},
                   {"label": "Oras Baile Olanesti", "value": "C99739"},
                   {"label": "Oras Balcesti", "value": "C99740"},
                   {"label": "Oras Berbesti", "value": "C99741"},
                   {"label": "Oras Brezoi", "value": "C99742"},
                   {"label": "Oras Calimanesti", "value": "C99743"},
                   {"label": "Oras Horezu", "value": "C99744"},
                   {"label": "Oras Ocnele Mari", "value": "C99745"},
                   {"label": "Orlesti", "value": "C99746"},
                   {"label": "Otesani", "value": "C99747"},
                   {"label": "Otetelisu", "value": "C99748"},
                   {"label": "Oveselu", "value": "C99749"},
                   {"label": "Pausesti", "value": "C99750"},
                   {"label": "Pausesti-Maglasi", "value": "C99751"},
                   {"label": "Perisani", "value": "C99752"},
                   {"label": "Pesceana", "value": "C99753"},
                   {"label": "Pietrari", "value": "C99754"},
                   {"label": "Pietrarii de Sus", "value": "C99755"},
                   {"label": "Popesti", "value": "C99756"},
                   {"label": "Prajila", "value": "C99757"},
                   {"label": "Prundeni", "value": "C99758"},
                   {"label": "Racovita", "value": "C99759"},
                   {"label": "Ramesti", "value": "C99760"},
                   {"label": "Ramnicu Valcea", "value": "C99761"},
                   {"label": "Roesti", "value": "C99762"},
                   {"label": "Romani", "value": "C99763"},
                   {"label": "Rosiile", "value": "C99764"},
                   {"label": "Runcu", "value": "C99765"},
                   {"label": "Salatrucel", "value": "C99766"},
                   {"label": "Scundu", "value": "C99767"},
                   {"label": "Sinesti", "value": "C99768"},
                   {"label": "Sirineasa", "value": "C99769"},
                   {"label": "Stanesti", "value": "C99770"},
                   {"label": "Stefanesti", "value": "C99771"},
                   {"label": "Stoenesti", "value": "C99772"},
                   {"label": "Stoilesti", "value": "C99773"},
                   {"label": "Stolniceni", "value": "C99774"},
                   {"label": "Stroesti", "value": "C99775"},
                   {"label": "Susani", "value": "C99776"},
                   {"label": "Sutesti", "value": "C99777"},
                   {"label": "Tetoiu", "value": "C99778"},
                   {"label": "Titesti", "value": "C99779"},
                   {"label": "Tomsani", "value": "C99780"},
                   {"label": "Turcesti", "value": "C99781"},
                   {"label": "Ursi", "value": "C99782"},
                   {"label": "Vaideeni", "value": "C99783"},
                   {"label": "Valea Mare", "value": "C99784"},
                   {"label": "Vladesti", "value": "C99785"},
                   {"label": "Voicesti", "value": "C99786"},
                   {"label": "Voineasa", "value": "C99787"},
                   {"label": "Zatreni", "value": "C99788"},
                   {"label": "Zlatarei", "value": "C99789"}],
 "Valencia": [{"label": "Ademuz", "value": "C114765"},
              {"label": "Ador", "value": "C114766"},
              {"label": "Adsubia", "value": "C114767"},
              {"label": "Adzaneta", "value": "C114768"},
              {"label": "Agost", "value": "C114769"},
              {"label": "Agres", "value": "C114770"},
              {"label": "Agullent", "value": "C114771"},
              {"label": "Aielo de Malferit", "value": "C114772"},
              {"label": "Alaquas", "value": "C114773"},
              {"label": "Albaida", "value": "C114774"},
              {"label": "Albal", "value": "C114775"},
              {"label": "Albalat de la Ribera", "value": "C114776"},
              {"label": "Albalat dels Sorells", "value": "C114777"},
              {"label": "Albalat dels Tarongers", "value": "C114778"},
              {"label": "Albatera", "value": "C114779"},
              {"label": "Alberic", "value": "C114780"},
              {"label": "Albocasser", "value": "C114781"},
              {"label": "Alborache", "value": "C114782"},
              {"label": "Alboraya", "value": "C114783"},
              {"label": "Albuixech", "value": "C114784"},
              {"label": "Alcala de Xivert", "value": "C114785"},
              {"label": "Alcalali", "value": "C114786"},
              {"label": "Alcantera de Xuquer", "value": "C114787"},
              {"label": "Alcasser", "value": "C114788"},
              {"label": "Alcoceber", "value": "C114789"},
              {"label": "Alcocer de Planes", "value": "C114790"},
              {"label": "Alcoy", "value": "C114791"},
              {"label": "Alcublas", "value": "C114792"},
              {"label": "Alcudia de Veo", "value": "C114793"},
              {"label": "Aldaia", "value": "C114794"},
              {"label": "Alfafar", "value": "C114795"},
              {"label": "Alfafara", "value": "C114796"},
              {"label": "Alfara de Algimia", "value": "C114797"},
              {"label": "Alfara del Patriarca", "value": "C114798"},
              {"label": "Alfarp", "value": "C114799"},
              {"label": "Alfarrasi", "value": "C114800"},
              {"label": "Alfauir", "value": "C114801"},
              {"label": "Alfondeguilla", "value": "C114802"},
              {"label": "Algemesi", "value": "C114803"},
              {"label": "Algimia de Alfara", "value": "C114804"},
              {"label": "Algimia de Almonacid", "value": "C114805"},
              {"label": "Alginet", "value": "C114806"},
              {"label": "Algorfa", "value": "C114807"},
              {"label": "Alguena", "value": "C114808"},
              {"label": "Alicante", "value": "C114809"},
              {"label": "Almassora", "value": "C114810"},
              {"label": "Almedijar", "value": "C114811"},
              {"label": "Almenara", "value": "C114812"},
              {"label": "Almisera", "value": "C114813"},
              {"label": "Almoines", "value": "C114814"},
              {"label": "Almoradi", "value": "C114815"},
              {"label": "Almudaina", "value": "C114816"},
              {"label": "Almussafes", "value": "C114817"},
              {"label": "Alpuente", "value": "C114818"},
              {"label": "Alquerias del Nino Perdido", "value": "C114819"},
              {"label": "Altea", "value": "C114820"},
              {"label": "Altura", "value": "C114821"},
              {"label": "Alzira", "value": "C114822"},
              {"label": "Andilla", "value": "C114823"},
              {"label": "Anna", "value": "C114824"},
              {"label": "Antella", "value": "C114825"},
              {"label": "Aranuel", "value": "C114826"},
              {"label": "Ares del Maestre", "value": "C114827"},
              {"label": "Argelita", "value": "C114828"},
              {"label": "Artana", "value": "C114829"},
              {"label": "Aspe", "value": "C114830"},
              {"label": "Atzeneta dAlbaida", "value": "C114831"},
              {"label": "Ayodar", "value": "C114832"},
              {"label": "Ayora", "value": "C114833"},
              {"label": "Azuebar", "value": "C114834"},
              {"label": "Balones", "value": "C114835"},
              {"label": "Barracas", "value": "C114836"},
              {"label": "Barx", "value": "C114837"},
              {"label": "Barxeta", "value": "C114838"},
              {"label": "Belgida", "value": "C114839"},
              {"label": "Bellreguard", "value": "C114840"},
              {"label": "Bellus", "value": "C114841"},
              {"label": "Benafigos", "value": "C114842"},
              {"label": "Benageber", "value": "C114843"},
              {"label": "Benaguasil", "value": "C114844"},
              {"label": "Benasau", "value": "C114845"},
              {"label": "Benassal", "value": "C114846"},
              {"label": "Benavites", "value": "C114847"},
              {"label": "Beneixama", "value": "C114848"},
              {"label": "Beneixida", "value": "C114849"},
              {"label": "Benejuzar", "value": "C114850"},
              {"label": "Benetusser", "value": "C114851"},
              {"label": "Benferri", "value": "C114852"},
              {"label": "Beniarbeig", "value": "C114853"},
              {"label": "Beniarda", "value": "C114854"},
              {"label": "Beniarjo", "value": "C114855"},
              {"label": "Beniarres", "value": "C114856"},
              {"label": "Beniatjar", "value": "C114857"},
              {"label": "Benicarlo", "value": "C114858"},
              {"label": "Benicassim", "value": "C114859"},
              {"label": "Benicolet", "value": "C114860"},
              {"label": "Benidoleig", "value": "C114861"},
              {"label": "Benidorm", "value": "C114862"},
              {"label": "Benifaio", "value": "C114863"},
              {"label": "Benifairo de les Valls", "value": "C114864"},
              {"label": "Benifallim", "value": "C114865"},
              {"label": "Benifla", "value": "C114866"},
              {"label": "Beniganim", "value": "C114867"},
              {"label": "Benijofar", "value": "C114868"},
              {"label": "Benilloba", "value": "C114869"},
              {"label": "Benillup", "value": "C114870"},
              {"label": "Benimantell", "value": "C114871"},
              {"label": "Benimarfull", "value": "C114872"},
              {"label": "Benimassot", "value": "C114873"},
              {"label": "Benimeli", "value": "C114874"},
              {"label": "Benimodo", "value": "C114875"},
              {"label": "Benimuslem", "value": "C114876"},
              {"label": "Beniparrell", "value": "C114877"},
              {"label": "Benirredra", "value": "C114878"},
              {"label": "Benisano", "value": "C114879"},
              {"label": "Benissa", "value": "C114880"},
              {"label": "Benissoda", "value": "C114881"},
              {"label": "Benisuera", "value": "C114882"},
              {"label": "Benitachell", "value": "C114883"},
              {"label": "Benlloch", "value": "C114884"},
              {"label": "Betera", "value": "C114885"},
              {"label": "Betxi", "value": "C114886"},
              {"label": "Biar", "value": "C114887"},
              {"label": "Bicorp", "value": "C114888"},
              {"label": "Bigastro", "value": "C114889"},
              {"label": "Bocairent", "value": "C114890"},
              {"label": "Bolbaite", "value": "C114891"},
              {"label": "Bolulla", "value": "C114892"},
              {"label": "Borriol", "value": "C114893"},
              {"label": "Bufali", "value": "C114894"},
              {"label": "Bugarra", "value": "C114895"},
              {"label": "Bunol", "value": "C114896"},
              {"label": "Burjassot", "value": "C114897"},
              {"label": "Burriana", "value": "C114898"},
              {"label": "Busot", "value": "C114899"},
              {"label": "Cabanes", "value": "C114900"},
              {"label": "Calig", "value": "C114901"},
              {"label": "Calles", "value": "C114902"},
              {"label": "Callosa de Segura", "value": "C114903"},
              {"label": "Calp", "value": "C114904"},
              {"label": "Camporrobles", "value": "C114905"},
              {"label": "Canals", "value": "C114906"},
              {"label": "Canet dEn Berenguer", "value": "C114907"},
              {"label": "Canet lo Roig", "value": "C114908"},
              {"label": "Carcaixent", "value": "C114909"},
              {"label": "Carcer", "value": "C114910"},
              {"label": "Carlet", "value": "C114911"},
              {"label": "Carricola", "value": "C114912"},
              {"label": "Casas Altas", "value": "C114913"},
              {"label": "Casas Bajas", "value": "C114914"},
              {"label": "Casinos", "value": "C114915"},
              {"label": "Castalla", "value": "C114916"},
              {"label": "Castell de Cabres", "value": "C114917"},
              {"label": "Castell de Castells", "value": "C114918"},
              {"label": "Castellfort", "value": "C114919"},
              {"label": "Castellnovo", "value": "C114920"},
              {"label": "Castello de la Plana", "value": "C114921"},
              {"label": "Castello de Rugat", "value": "C114922"},
              {"label": "Castellonet de la Conquesta", "value": "C114923"},
              {"label": "Castielfabib", "value": "C114924"},
              {"label": "Castillo de Villamalefa", "value": "C114925"},
              {"label": "Catadau", "value": "C114926"},
              {"label": "Catarroja", "value": "C114927"},
              {"label": "Cati", "value": "C114928"},
              {"label": "Catral", "value": "C114929"},
              {"label": "Caudete de las Fuentes", "value": "C114930"},
              {"label": "Cerda", "value": "C114931"},
              {"label": "Cervera del Maestre", "value": "C114932"},
              {"label": "Chella", "value": "C114933"},
              {"label": "Chelva", "value": "C114934"},
              {"label": "Chera", "value": "C114935"},
              {"label": "ChertXert", "value": "C114936"},
              {"label": "Cheste", "value": "C114937"},
              {"label": "Chilches", "value": "C114938"},
              {"label": "Chiva", "value": "C114939"},
              {"label": "Chovar", "value": "C114940"},
              {"label": "Chulilla", "value": "C114941"},
              {"label": "Cinctorres", "value": "C114942"},
              {"label": "Cirat", "value": "C114943"},
              {"label": "Cocentaina", "value": "C114944"},
              {"label": "Cofrentes", "value": "C114945"},
              {"label": "Confrides", "value": "C114946"},
              {"label": "Corbera", "value": "C114947"},
              {"label": "Cortes de Arenoso", "value": "C114948"},
              {"label": "Cortes de Pallas", "value": "C114949"},
              {"label": "Costur", "value": "C114950"},
              {"label": "Cotes", "value": "C114951"},
              {"label": "Cox", "value": "C114952"},
              {"label": "Crevillente", "value": "C114953"},
              {"label": "Cuevas de Vinroma", "value": "C114954"},
              {"label": "Culla", "value": "C114955"},
              {"label": "Cullera", "value": "C114956"},
              {"label": "Daimus", "value": "C114957"},
              {"label": "Daya Nueva", "value": "C114958"},
              {"label": "Daya Vieja", "value": "C114959"},
              {"label": "Denia", "value": "C114960"},
              {"label": "Dolores", "value": "C114961"},
              {"label": "Domeno", "value": "C114962"},
              {"label": "Dos Aguas", "value": "C114963"},
              {"label": "El Campello", "value": "C114964"},
              {"label": "El Grao", "value": "C114965"},
              {"label": "El Perello", "value": "C114966"},
              {"label": "Elche", "value": "C114967"},
              {"label": "Elda", "value": "C114968"},
              {"label": "els Poblets", "value": "C114969"},
              {"label": "Emperador", "value": "C114970"},
              {"label": "Enguera", "value": "C114971"},
              {"label": "Eslida", "value": "C114972"},
              {"label": "Espadilla", "value": "C114973"},
              {"label": "Estivella", "value": "C114974"},
              {"label": "Estubeny", "value": "C114975"},
              {"label": "Facheca", "value": "C114976"},
              {"label": "Famorca", "value": "C114977"},
              {"label": "Fanzara", "value": "C114978"},
              {"label": "Faura", "value": "C114979"},
              {"label": "Favara", "value": "C114980"},
              {"label": "Figueroles", "value": "C114981"},
              {"label": "Finestrat", "value": "C114982"},
              {"label": "Foios", "value": "C114983"},
              {"label": "Fontanars dels Alforins", "value": "C114984"},
              {"label": "Forcall", "value": "C114985"},
              {"label": "Formentera de Segura", "value": "C114986"},
              {"label": "Fortaleny", "value": "C114987"},
              {"label": "Fuente la Reina", "value": "C114988"},
              {"label": "Fuenterrobles", "value": "C114989"},
              {"label": "Fuentes de Ayodar", "value": "C114990"},
              {"label": "Gaibiel", "value": "C114991"},
              {"label": "Gandia", "value": "C114992"},
              {"label": "Gata de Gorgos", "value": "C114993"},
              {"label": "Gavarda", "value": "C114994"},
              {"label": "Geldo", "value": "C114995"},
              {"label": "Genoves", "value": "C114996"},
              {"label": "Gestalgar", "value": "C114997"},
              {"label": "Gilet", "value": "C114998"},
              {"label": "Godella", "value": "C114999"},
              {"label": "Godelleta", "value": "C115000"},
              {"label": "Gorga", "value": "C115001"},
              {"label": "Granja de Rocamora", "value": "C115002"},
              {"label": "Grao de Murviedro", "value": "C115003"},
              {"label": "Guadasequies", "value": "C115004"},
              {"label": "Guadassuar", "value": "C115005"},
              {"label": "Guardamar del Segura", "value": "C115006"},
              {"label": "Herbes", "value": "C115007"},
              {"label": "Higueras", "value": "C115008"},
              {"label": "Higueruelas", "value": "C115009"},
              {"label": "Hondon de las Nieves", "value": "C115010"},
              {"label": "Hondon de los Frailes", "value": "C115011"},
              {"label": "Ibi", "value": "C115012"},
              {"label": "Jacarilla", "value": "C115013"},
              {"label": "Jalance", "value": "C115014"},
              {"label": "Jalon", "value": "C115015"},
              {"label": "Jarafuel", "value": "C115016"},
              {"label": "Javea", "value": "C115017"},
              {"label": "Jerica", "value": "C115018"},
              {"label": "Jijona", "value": "C115019"},
              {"label": "lAlcora", "value": "C115020"},
              {"label": "LAlcudia", "value": "C115021"},
              {"label": "LAlcudia de Crespins", "value": "C115022"},
              {"label": "lAlfas del Pi", "value": "C115023"},
              {"label": "LAlqueria de la Comtessa", "value": "C115024"},
              {"label": "LEliana", "value": "C115025"},
              {"label": "LOlleria", "value": "C115026"},
              {"label": "La Font de la Figuera", "value": "C115027"},
              {"label": "la Nucia", "value": "C115028"},
              {"label": "La Pobla de Farnals", "value": "C115029"},
              {"label": "La Pobla de Vallbona", "value": "C115030"},
              {"label": "La Pobla Llarga", "value": "C115031"},
              {"label": "La Romana", "value": "C115032"},
              {"label": "La Yesa", "value": "C115033"},
              {"label": "Llanera de Ranes", "value": "C115034"},
              {"label": "Llauri", "value": "C115035"},
              {"label": "Lliria", "value": "C115036"},
              {"label": "Llocnou de Sant Jeroni", "value": "C115037"},
              {"label": "Llombai", "value": "C115038"},
              {"label": "Llosa de Ranes", "value": "C115039"},
              {"label": "Llutxent", "value": "C115040"},
              {"label": "Loriguilla", "value": "C115041"},
              {"label": "Los Montesinos", "value": "C115042"},
              {"label": "Losa del Obispo", "value": "C115043"},
              {"label": "Lucena del Cid", "value": "C115044"},
              {"label": "Ludiente", "value": "C115045"},
              {"label": "Macastre", "value": "C115046"},
              {"label": "Manises", "value": "C115047"},
              {"label": "Manuel", "value": "C115048"},
              {"label": "Marines", "value": "C115049"},
              {"label": "Masalaves", "value": "C115050"},
              {"label": "Massamagrell", "value": "C115051"},
              {"label": "Matet", "value": "C115052"},
              {"label": "Meliana", "value": "C115053"},
              {"label": "Millares", "value": "C115054"},
              {"label": "Millena", "value": "C115055"},
              {"label": "Miramar", "value": "C115056"},
              {"label": "Mislata", "value": "C115057"},
              {"label": "Mogente", "value": "C115058"},
              {"label": "Moncada", "value": "C115059"},
              {"label": "Moncofa", "value": "C115060"},
              {"label": "Monforte del Cid", "value": "C115061"},
              {"label": "Monovar", "value": "C115062"},
              {"label": "Monserrat", "value": "C115063"},
              {"label": "Montaberner", "value": "C115064"},
              {"label": "Montan", "value": "C115065"},
              {"label": "Montanejos", "value": "C115066"},
              {"label": "Montesa", "value": "C115067"},
              {"label": "Montichelvo", "value": "C115068"},
              {"label": "Montroy", "value": "C115069"},
              {"label": "Moraira", "value": "C115070"},
              {"label": "Morella", "value": "C115071"},
              {"label": "Murla", "value": "C115072"},
              {"label": "Muro del Alcoy", "value": "C115073"},
              {"label": "Museros", "value": "C115074"},
              {"label": "Mutxamel", "value": "C115075"},
              {"label": "Naquera", "value": "C115076"},
              {"label": "Navajas", "value": "C115077"},
              {"label": "Navarres", "value": "C115078"},
              {"label": "Novelda", "value": "C115079"},
              {"label": "Nules", "value": "C115080"},
              {"label": "Oliva", "value": "C115081"},
              {"label": "Olocau", "value": "C115082"},
              {"label": "Olocau del Rey", "value": "C115083"},
              {"label": "Onda", "value": "C115084"},
              {"label": "Ondara", "value": "C115085"},
              {"label": "Onil", "value": "C115086"},
              {"label": "Ontinyent", "value": "C115087"},
              {"label": "Orba", "value": "C115088"},
              {"label": "Orcheta", "value": "C115089"},
              {"label": "Orihuela", "value": "C115090"},
              {"label": "Oropesa del Mar", "value": "C115091"},
              {"label": "Otos", "value": "C115092"},
              {"label": "Paiporta", "value": "C115093"},
              {"label": "Palanques", "value": "C115094"},
              {"label": "Palma de Gandia", "value": "C115095"},
              {"label": "Palmera", "value": "C115096"},
              {"label": "Parcent", "value": "C115097"},
              {"label": "Paterna", "value": "C115098"},
              {"label": "Pavias", "value": "C115099"},
              {"label": "Pedralba", "value": "C115100"},
              {"label": "Pedreguer", "value": "C115101"},
              {"label": "Pego", "value": "C115102"},
              {"label": "Peniscola", "value": "C115103"},
              {"label": "Petres", "value": "C115104"},
              {"label": "Picanya", "value": "C115105"},
              {"label": "Picassent", "value": "C115106"},
              {"label": "Pilar de la Horadada", "value": "C115107"},
              {"label": "Piles", "value": "C115108"},
              {"label": "Pina de Montalgrao", "value": "C115109"},
              {"label": "Pinet", "value": "C115110"},
              {"label": "Pinoso", "value": "C115111"},
              {"label": "Planes", "value": "C115112"},
              {"label": "Polinya de Xuquer", "value": "C115113"},
              {"label": "Polop", "value": "C115114"},
              {"label": "Portell de Morella", "value": "C115115"},
              {"label": "Potries", "value": "C115116"},
              {"label": "Provincia de Alicante", "value": "C115117"},
              {"label": "Provincia de Castello", "value": "C115118"},
              {"label": "Provincia de Valencia", "value": "C115119"},
              {"label": "Pucol", "value": "C115120"},
              {"label": "Puebla de Arenoso", "value": "C115121"},
              {"label": "Puebla de San Miguel", "value": "C115122"},
              {"label": "Puig", "value": "C115123"},
              {"label": "Quart de les Valls", "value": "C115124"},
              {"label": "Quart de Poblet", "value": "C115125"},
              {"label": "Quartell", "value": "C115126"},
              {"label": "Quatretonda", "value": "C115127"},
              {"label": "Quesa", "value": "C115128"},
              {"label": "Rafal", "value": "C115129"},
              {"label": "Rafelcofer", "value": "C115130"},
              {"label": "Rafelguaraf", "value": "C115131"},
              {"label": "Rafol de Salem", "value": "C115132"},
              {"label": "Real de Gandia", "value": "C115133"},
              {"label": "Real de Montroi", "value": "C115134"},
              {"label": "Redovan", "value": "C115135"},
              {"label": "Relleu", "value": "C115136"},
              {"label": "Requena", "value": "C115137"},
              {"label": "Ribarroja del Turia", "value": "C115138"},
              {"label": "Ribesalbes", "value": "C115139"},
              {"label": "Riola", "value": "C115140"},
              {"label": "Rocafort", "value": "C115141"},
              {"label": "Rojales", "value": "C115142"},
              {"label": "Rosell", "value": "C115143"},
              {"label": "Rotgla y Corbera", "value": "C115144"},
              {"label": "Rotova", "value": "C115145"},
              {"label": "Rugat", "value": "C115146"},
              {"label": "Sacanet", "value": "C115147"},
              {"label": "Sagra", "value": "C115148"},
              {"label": "Sagunto", "value": "C115149"},
              {"label": "Salinas", "value": "C115150"},
              {"label": "San Juan de Alicante", "value": "C115151"},
              {"label": "San Juan de enova", "value": "C115152"},
              {"label": "San Juan de Moro", "value": "C115153"},
              {"label": "San Miguel de Salinas", "value": "C115154"},
              {"label": "San Vicent del Raspeig", "value": "C115155"},
              {"label": "Sanet y Negrals", "value": "C115156"},
              {"label": "Sant Jordi", "value": "C115157"},
              {"label": "Santa Magdalena de Pulpis", "value": "C115158"},
              {"label": "Santa Pola", "value": "C115159"},
              {"label": "Sarratella", "value": "C115160"},
              {"label": "Sax", "value": "C115161"},
              {"label": "Sedavi", "value": "C115162"},
              {"label": "Segart", "value": "C115163"},
              {"label": "Segorbe", "value": "C115164"},
              {"label": "Sella", "value": "C115165"},
              {"label": "Sellent", "value": "C115166"},
              {"label": "Sempere", "value": "C115167"},
              {"label": "Senija", "value": "C115168"},
              {"label": "Senyera", "value": "C115169"},
              {"label": "Serra", "value": "C115170"},
              {"label": "Sierra-Engarceran", "value": "C115171"},
              {"label": "Siete Aguas", "value": "C115172"},
              {"label": "Silla", "value": "C115173"},
              {"label": "Simat de la Valldigna", "value": "C115174"},
              {"label": "Sinarcas", "value": "C115175"},
              {"label": "Sollana", "value": "C115176"},
              {"label": "Soneja", "value": "C115177"},
              {"label": "Sot de Chera", "value": "C115178"},
              {"label": "Sot de Ferrer", "value": "C115179"},
              {"label": "Sueca", "value": "C115180"},
              {"label": "Sumacarcer", "value": "C115181"},
              {"label": "Tales", "value": "C115182"},
              {"label": "Tavernes Blanques", "value": "C115183"},
              {"label": "Tavernes de la Valldigna", "value": "C115184"},
              {"label": "Terrateig", "value": "C115185"},
              {"label": "Teulada", "value": "C115186"},
              {"label": "Tibi", "value": "C115187"},
              {"label": "Titaguas", "value": "C115188"},
              {"label": "Todolella", "value": "C115189"},
              {"label": "Toga", "value": "C115190"},
              {"label": "Tollos", "value": "C115191"},
              {"label": "Toras", "value": "C115192"},
              {"label": "Tormos", "value": "C115193"},
              {"label": "Torralba del Pinar", "value": "C115194"},
              {"label": "Torre de la Horadada", "value": "C115195"},
              {"label": "Torreblanca", "value": "C115196"},
              {"label": "Torrechiva", "value": "C115197"},
              {"label": "Torrella", "value": "C115198"},
              {"label": "Torrent", "value": "C115199"},
              {"label": "Torrevieja", "value": "C115200"},
              {"label": "Tous", "value": "C115201"},
              {"label": "Traiguera", "value": "C115202"},
              {"label": "Tuejar", "value": "C115203"},
              {"label": "Turis", "value": "C115204"},
              {"label": "Utiel", "value": "C115205"},
              {"label": "Valencia", "value": "C115206"},
              {"label": "Vall de Almonacid", "value": "C115207"},
              {"label": "Vall de Ebo", "value": "C115208"},
              {"label": "Vallada", "value": "C115209"},
              {"label": "Vallanca", "value": "C115210"},
              {"label": "Valles", "value": "C115211"},
              {"label": "Vallibona", "value": "C115212"},
              {"label": "Venta del Moro", "value": "C115213"},
              {"label": "Vergel", "value": "C115214"},
              {"label": "Vila-real", "value": "C115215"},
              {"label": "Vilamarxant", "value": "C115216"},
              {"label": "Villafames", "value": "C115217"},
              {"label": "Villafranca del Cid", "value": "C115218"},
              {"label": "Villahermosa del Rio", "value": "C115219"},
              {"label": "Villajoyosa", "value": "C115220"},
              {"label": "Villalonga", "value": "C115221"},
              {"label": "Villamalur", "value": "C115222"},
              {"label": "Villanueva de Castellon", "value": "C115223"},
              {"label": "Villanueva de Viver", "value": "C115224"},
              {"label": "Villar del Arzobispo", "value": "C115225"},
              {"label": "Villargordo del Cabriel", "value": "C115226"},
              {"label": "Villavieja", "value": "C115227"},
              {"label": "Villena", "value": "C115228"},
              {"label": "Villores", "value": "C115229"},
              {"label": "Vinalesa", "value": "C115230"},
              {"label": "Vinaros", "value": "C115231"},
              {"label": "Vistabella del Maestrazgo", "value": "C115232"},
              {"label": "Viver", "value": "C115233"},
              {"label": "Xativa", "value": "C115234"},
              {"label": "Xeraco,Jaraco", "value": "C115235"},
              {"label": "Xeresa", "value": "C115236"},
              {"label": "Xirivella", "value": "C115237"},
              {"label": "Yatova", "value": "C115238"},
              {"label": "Zarra", "value": "C115239"},
              {"label": "Zorita del Maestrazgo", "value": "C115240"},
              {"label": "Zucaina", "value": "C115241"}],
 "Valga County": [{"label": "Otepaa vald", "value": "C24642"},
                  {"label": "Torva", "value": "C24643"},
                  {"label": "Valga", "value": "C24644"}],
 "Valka Municipality": [{"label": "Valka", "value": "C64952"}],
 "Valle Department": [{"label": "Agua Fria", "value": "C43691"},
                      {"label": "Alianza", "value": "C43692"},
                      {"label": "Amapala", "value": "C43693"},
                      {"label": "Aramecina", "value": "C43694"},
                      {"label": "Caridad", "value": "C43695"},
                      {"label": "El Cubolero", "value": "C43696"},
                      {"label": "El Tular", "value": "C43697"},
                      {"label": "Goascoran", "value": "C43698"},
                      {"label": "Jicaro Galan", "value": "C43699"},
                      {"label": "La Alianza", "value": "C43700"},
                      {"label": "La Criba", "value": "C43701"},
                      {"label": "Langue", "value": "C43702"},
                      {"label": "Nacaome", "value": "C43703"},
                      {"label": "San Francisco de Coray", "value": "C43704"},
                      {"label": "San Lorenzo", "value": "C43705"}],
 "Valle del Cauca": [{"label": "Alcala", "value": "C20606"},
                     {"label": "Andalucia", "value": "C20607"},
                     {"label": "Ansermanuevo", "value": "C20608"},
                     {"label": "Argelia", "value": "C20609"},
                     {"label": "Bolivar", "value": "C20610"},
                     {"label": "Buenaventura", "value": "C20611"},
                     {"label": "Bugalagrande", "value": "C20612"},
                     {"label": "Caicedonia", "value": "C20613"},
                     {"label": "Cali", "value": "C20614"},
                     {"label": "Calima", "value": "C20615"},
                     {"label": "Candelaria", "value": "C20616"},
                     {"label": "Cartago", "value": "C20617"},
                     {"label": "Dagua", "value": "C20618"},
                     {"label": "El aguila", "value": "C20619"},
                     {"label": "El Cairo", "value": "C20620"},
                     {"label": "El Cerrito", "value": "C20621"},
                     {"label": "El Dovio", "value": "C20622"},
                     {"label": "Florida", "value": "C20623"},
                     {"label": "Ginebra", "value": "C20624"},
                     {"label": "Guacari", "value": "C20625"},
                     {"label": "Guadalajara de Buga", "value": "C20626"},
                     {"label": "Jamundi", "value": "C20627"},
                     {"label": "La Cumbre", "value": "C20628"},
                     {"label": "La Union", "value": "C20629"},
                     {"label": "La Victoria", "value": "C20630"},
                     {"label": "Obando", "value": "C20631"},
                     {"label": "Palmira", "value": "C20632"},
                     {"label": "Pradera", "value": "C20633"},
                     {"label": "Restrepo", "value": "C20634"},
                     {"label": "Riofrio", "value": "C20635"},
                     {"label": "Roldanillo", "value": "C20636"},
                     {"label": "San Pedro", "value": "C20637"},
                     {"label": "Sevilla", "value": "C20638"},
                     {"label": "Toro", "value": "C20639"},
                     {"label": "Trujillo", "value": "C20640"},
                     {"label": "Tulua", "value": "C20641"},
                     {"label": "Ulloa", "value": "C20642"},
                     {"label": "Versalles", "value": "C20643"},
                     {"label": "Vijes", "value": "C20644"},
                     {"label": "Yotoco", "value": "C20645"},
                     {"label": "Yumbo", "value": "C20646"},
                     {"label": "Zarzal", "value": "C20647"}],
 "Vallee du Bandama Region": [{"label": "Beoumi", "value": "C20987"},
                              {"label": "Botro", "value": "C20988"},
                              {"label": "Bouake", "value": "C20989"},
                              {"label": "Dabakala", "value": "C20990"},
                              {"label": "Gbeke", "value": "C20991"},
                              {"label": "Hambol", "value": "C20992"},
                              {"label": "Katiola", "value": "C20993"},
                              {"label": "Sakassou", "value": "C20994"}],
 "Valletta": [{"label": "Valletta", "value": "C65926"}],
 "Valmiera": [{"label": "Valmiera", "value": "C64953"}],
 "Valparaiso": [{"label": "Algarrobo", "value": "C18200"},
                {"label": "Cabildo", "value": "C18201"},
                {"label": "Calle Larga", "value": "C18202"},
                {"label": "Cartagena", "value": "C18203"},
                {"label": "Casablanca", "value": "C18204"},
                {"label": "Catemu", "value": "C18205"},
                {"label": "Concon", "value": "C18206"},
                {"label": "El Quisco", "value": "C18207"},
                {"label": "El Tabo", "value": "C18208"},
                {"label": "Hijuelas", "value": "C18209"},
                {"label": "Isla de Pascua", "value": "C18210"},
                {"label": "Juan Fernandez", "value": "C18211"},
                {"label": "La Calera", "value": "C18212"},
                {"label": "La Cruz", "value": "C18213"},
                {"label": "La Ligua", "value": "C18214"},
                {"label": "Limache", "value": "C18215"},
                {"label": "Llaillay", "value": "C18216"},
                {"label": "Los Andes", "value": "C18217"},
                {"label": "Nogales", "value": "C18218"},
                {"label": "Olmue", "value": "C18219"},
                {"label": "Panquehue", "value": "C18220"},
                {"label": "Papudo", "value": "C18221"},
                {"label": "Petorca", "value": "C18222"},
                {"label": "Puchuncavi", "value": "C18223"},
                {"label": "Putaendo", "value": "C18224"},
                {"label": "Quillota", "value": "C18225"},
                {"label": "Quilpue", "value": "C18226"},
                {"label": "Quintero", "value": "C18227"},
                {"label": "Rinconada", "value": "C18228"},
                {"label": "San Antonio", "value": "C18229"},
                {"label": "San Esteban", "value": "C18230"},
                {"label": "San Felipe", "value": "C18231"},
                {"label": "Santa Maria", "value": "C18232"},
                {"label": "Santo Domingo", "value": "C18233"},
                {"label": "Valparaiso", "value": "C18234"},
                {"label": "Villa Alemana", "value": "C18235"},
                {"label": "Vina del Mar", "value": "C18236"},
                {"label": "Zapallar", "value": "C18237"}],
 "Valverde Province": [{"label": "Amina", "value": "C24033"},
                       {"label": "Esperanza", "value": "C24034"},
                       {"label": "Guatapanal", "value": "C24035"},
                       {"label": "Jaibon", "value": "C24036"},
                       {"label": "Jicome", "value": "C24037"},
                       {"label": "La Caya", "value": "C24038"},
                       {"label": "Laguna Salada", "value": "C24039"},
                       {"label": "Maizal", "value": "C24040"},
                       {"label": "Mao", "value": "C24041"}],
 "Van": [{"label": "Bahcesaray", "value": "C121111"},
         {"label": "Baskale", "value": "C121112"},
         {"label": "Caldiran", "value": "C121113"},
         {"label": "Catak Ilcesi", "value": "C121114"},
         {"label": "Edremit", "value": "C121115"},
         {"label": "Ercis", "value": "C121116"},
         {"label": "Gevas", "value": "C121117"},
         {"label": "Gurpinar", "value": "C121118"},
         {"label": "Ipekyolu", "value": "C121119"},
         {"label": "Konalga", "value": "C121120"},
         {"label": "Muradiye", "value": "C121121"},
         {"label": "Ozalp", "value": "C121122"},
         {"label": "Saray", "value": "C121123"},
         {"label": "Tuspa", "value": "C121124"},
         {"label": "Van", "value": "C121125"}],
 "Varaklani Municipality": [{"label": "Varaklani", "value": "C64954"}],
 "Varazdin": [{"label": "Beretinec", "value": "C21475"},
              {"label": "Breznica", "value": "C21476"},
              {"label": "Breznicki Hum", "value": "C21477"},
              {"label": "Cestica", "value": "C21478"},
              {"label": "Donje Ladanje", "value": "C21479"},
              {"label": "Gornje Vratno", "value": "C21480"},
              {"label": "Gornji Kneginec", "value": "C21481"},
              {"label": "Grad Ivanec", "value": "C21482"},
              {"label": "Grad Ludbreg", "value": "C21483"},
              {"label": "Grad Novi Marof", "value": "C21484"},
              {"label": "Grad Varazdin", "value": "C21485"},
              {"label": "Hrascica", "value": "C21486"},
              {"label": "Ivanec", "value": "C21487"},
              {"label": "Jalkovec", "value": "C21488"},
              {"label": "Jalzabet", "value": "C21489"},
              {"label": "Klenovnik", "value": "C21490"},
              {"label": "Kucan Marof", "value": "C21491"},
              {"label": "Lepoglava", "value": "C21492"},
              {"label": "Ljubescica", "value": "C21493"},
              {"label": "Ludbreg", "value": "C21494"},
              {"label": "Nedeljanec", "value": "C21495"},
              {"label": "Petrijanec", "value": "C21496"},
              {"label": "Remetinec", "value": "C21497"},
              {"label": "Sracinec", "value": "C21498"},
              {"label": "Sveti Durd", "value": "C21499"},
              {"label": "Tuzno", "value": "C21500"},
              {"label": "Varazdin", "value": "C21501"},
              {"label": "Vidovec", "value": "C21502"},
              {"label": "Vinica", "value": "C21503"}],
 "Varkava Municipality": [{"label": "Vecvarkava", "value": "C64955"}],
 "Varmland County": [{"label": "Amotfors", "value": "C116062"},
                     {"label": "Arjang", "value": "C116063"},
                     {"label": "Arvika", "value": "C116064"},
                     {"label": "Bjorneborg", "value": "C116065"},
                     {"label": "Charlottenberg", "value": "C116066"},
                     {"label": "Deje", "value": "C116067"},
                     {"label": "Eksharad", "value": "C116068"},
                     {"label": "Filipstad", "value": "C116069"},
                     {"label": "Forshaga", "value": "C116070"},
                     {"label": "Grums", "value": "C116071"},
                     {"label": "Hagfors", "value": "C116072"},
                     {"label": "Karlstad", "value": "C116073"},
                     {"label": "Kil", "value": "C116074"},
                     {"label": "Kristinehamn", "value": "C116075"},
                     {"label": "Molkom", "value": "C116076"},
                     {"label": "Munkfors", "value": "C116077"},
                     {"label": "Ratorp", "value": "C116078"},
                     {"label": "Saffle", "value": "C116079"},
                     {"label": "Skare", "value": "C116080"},
                     {"label": "Skattkarr", "value": "C116081"},
                     {"label": "Skoghall", "value": "C116082"},
                     {"label": "Slottsbron", "value": "C116083"},
                     {"label": "Storfors", "value": "C116084"},
                     {"label": "Sunne", "value": "C116085"},
                     {"label": "Tocksfors", "value": "C116086"},
                     {"label": "Torsby", "value": "C116087"},
                     {"label": "Valberg", "value": "C116088"}],
 "Varna Province": [{"label": "Aksakovo", "value": "C16251"},
                    {"label": "Asparuhovo", "value": "C16252"},
                    {"label": "Balgarevo", "value": "C16253"},
                    {"label": "Beloslav", "value": "C16254"},
                    {"label": "Byala", "value": "C16255"},
                    {"label": "Dalgopol", "value": "C16256"},
                    {"label": "Devnya", "value": "C16257"},
                    {"label": "Dolni Chiflik", "value": "C16258"},
                    {"label": "Kiten", "value": "C16259"},
                    {"label": "Obshtina Aksakovo", "value": "C16260"},
                    {"label": "Obshtina Avren", "value": "C16261"},
                    {"label": "Obshtina Beloslav", "value": "C16262"},
                    {"label": "Obshtina Byala", "value": "C16263"},
                    {"label": "Obshtina Dalgopol", "value": "C16264"},
                    {"label": "Obshtina Devnya", "value": "C16265"},
                    {"label": "Obshtina Dolni Chiflik", "value": "C16266"},
                    {"label": "Obshtina Provadia", "value": "C16267"},
                    {"label": "Obshtina Suvorovo", "value": "C16268"},
                    {"label": "Obshtina Valchidol", "value": "C16269"},
                    {"label": "Obshtina Varna", "value": "C16270"},
                    {"label": "Obshtina Vetrino", "value": "C16271"},
                    {"label": "Provadia", "value": "C16272"},
                    {"label": "Suvorovo", "value": "C16273"},
                    {"label": "Valchidol", "value": "C16274"},
                    {"label": "Varna", "value": "C16275"},
                    {"label": "Vetrino", "value": "C16276"},
                    {"label": "Zlatni Pyasatsi", "value": "C16277"}],
 "Vas County": [{"label": "Buk", "value": "C44740"},
                {"label": "Celldomolk", "value": "C44741"},
                {"label": "Celldomolki Jaras", "value": "C44742"},
                {"label": "Csepreg", "value": "C44743"},
                {"label": "Gencsapati", "value": "C44744"},
                {"label": "Jak", "value": "C44745"},
                {"label": "Janoshaza", "value": "C44746"},
                {"label": "Kormend", "value": "C44747"},
                {"label": "Kormendi Jaras", "value": "C44748"},
                {"label": "Koszeg", "value": "C44749"},
                {"label": "Koszegi Jaras", "value": "C44750"},
                {"label": "Repcelak", "value": "C44751"},
                {"label": "Sarvar", "value": "C44752"},
                {"label": "Sarvari Jaras", "value": "C44753"},
                {"label": "Szentgotthard", "value": "C44754"},
                {"label": "Szentgotthardi Jaras", "value": "C44755"},
                {"label": "Szombathely", "value": "C44756"},
                {"label": "Szombathelyi Jaras", "value": "C44757"},
                {"label": "Taplanszentkereszt", "value": "C44758"},
                {"label": "Vasvar", "value": "C44759"},
                {"label": "Vasvari Jaras", "value": "C44760"},
                {"label": "Vep", "value": "C44761"}],
 "Vasilevo Municipality": [{"label": "Vasilevo", "value": "C65534"}],
 "Vaslui County": [{"label": "Albesti", "value": "C99790"},
                   {"label": "Alexandru Vlahuta", "value": "C99791"},
                   {"label": "Arsura", "value": "C99792"},
                   {"label": "Bacani", "value": "C99793"},
                   {"label": "Bacesti", "value": "C99794"},
                   {"label": "Badeana", "value": "C99795"},
                   {"label": "Baile Dranceni", "value": "C99796"},
                   {"label": "Balteni", "value": "C99797"},
                   {"label": "Banca", "value": "C99798"},
                   {"label": "Barlad", "value": "C99799"},
                   {"label": "Barzesti", "value": "C99800"},
                   {"label": "Berezeni", "value": "C99801"},
                   {"label": "Blagesti", "value": "C99802"},
                   {"label": "Bogdana", "value": "C99803"},
                   {"label": "Bogdanesti", "value": "C99804"},
                   {"label": "Bogdanita", "value": "C99805"},
                   {"label": "Botesti", "value": "C99806"},
                   {"label": "Brodoc", "value": "C99807"},
                   {"label": "Buda", "value": "C99808"},
                   {"label": "Bunesti", "value": "C99809"},
                   {"label": "Carja", "value": "C99810"},
                   {"label": "Chircesti", "value": "C99811"},
                   {"label": "Chitoc", "value": "C99812"},
                   {"label": "Ciocani", "value": "C99813"},
                   {"label": "Codaesti", "value": "C99814"},
                   {"label": "Comuna Albesti", "value": "C99815"},
                   {"label": "Comuna Alexandru Vlahuta", "value": "C99816"},
                   {"label": "Comuna Arsura", "value": "C99817"},
                   {"label": "Comuna Bacani", "value": "C99818"},
                   {"label": "Comuna Bacesti", "value": "C99819"},
                   {"label": "Comuna Balteni", "value": "C99820"},
                   {"label": "Comuna Banca", "value": "C99821"},
                   {"label": "Comuna Berezeni", "value": "C99822"},
                   {"label": "Comuna Blagesti", "value": "C99823"},
                   {"label": "Comuna Bogdana", "value": "C99824"},
                   {"label": "Comuna Bogdanesti", "value": "C99825"},
                   {"label": "Comuna Bogdanita", "value": "C99826"},
                   {"label": "Comuna Botesti", "value": "C99827"},
                   {"label": "Comuna Bunesti Averesti", "value": "C99828"},
                   {"label": "Comuna Ciocani", "value": "C99829"},
                   {"label": "Comuna Codaesti", "value": "C99830"},
                   {"label": "Comuna Coroiesti", "value": "C99831"},
                   {"label": "Comuna Costesti", "value": "C99832"},
                   {"label": "Comuna Cozmesti", "value": "C99833"},
                   {"label": "Comuna Cretesti", "value": "C99834"},
                   {"label": "Comuna Danesti", "value": "C99835"},
                   {"label": "Comuna Deleni", "value": "C99836"},
                   {"label": "Comuna Delesti", "value": "C99837"},
                   {"label": "Comuna Dimitrie Cantemir", "value": "C99838"},
                   {"label": "Comuna Dodesti", "value": "C99839"},
                   {"label": "Comuna Dragomiresti", "value": "C99840"},
                   {"label": "Comuna Dranceni", "value": "C99841"},
                   {"label": "Comuna Duda  Epureni", "value": "C99842"},
                   {"label": "Comuna Dumesti", "value": "C99843"},
                   {"label": "Comuna Epureni", "value": "C99844"},
                   {"label": "Comuna Falciu", "value": "C99845"},
                   {"label": "Comuna Feresti", "value": "C99846"},
                   {"label": "Comuna Fruntiseni", "value": "C99847"},
                   {"label": "Comuna Gagesti", "value": "C99848"},
                   {"label": "Comuna Garceni", "value": "C99849"},
                   {"label": "Comuna Gherghesti", "value": "C99850"},
                   {"label": "Comuna Grivita", "value": "C99851"},
                   {"label": "Comuna Hoceni", "value": "C99852"},
                   {"label": "Comuna Iana", "value": "C99853"},
                   {"label": "Comuna Ibanesti", "value": "C99854"},
                   {"label": "Comuna Ivanesti", "value": "C99855"},
                   {"label": "Comuna Ivesti", "value": "C99856"},
                   {"label": "Comuna Laza", "value": "C99857"},
                   {"label": "Comuna Lipovat", "value": "C99858"},
                   {"label": "Comuna Lunca Banului", "value": "C99859"},
                   {"label": "Comuna Malusteni", "value": "C99860"},
                   {"label": "Comuna Miclesti", "value": "C99861"},
                   {"label": "Comuna Muntenii de Jos", "value": "C99862"},
                   {"label": "Comuna Muntenii de Sus", "value": "C99863"},
                   {"label": "Comuna Oltenesti", "value": "C99864"},
                   {"label": "Comuna Osesti", "value": "C99865"},
                   {"label": "Comuna Padureni", "value": "C99866"},
                   {"label": "Comuna Perieni", "value": "C99867"},
                   {"label": "Comuna Pochidia", "value": "C99868"},
                   {"label": "Comuna Pogana", "value": "C99869"},
                   {"label": "Comuna Pogonesti", "value": "C99870"},
                   {"label": "Comuna Poienesti", "value": "C99871"},
                   {"label": "Comuna Puiesti", "value": "C99872"},
                   {"label": "Comuna Pungesti", "value": "C99873"},
                   {"label": "Comuna Puscasi", "value": "C99874"},
                   {"label": "Comuna Rafaila", "value": "C99875"},
                   {"label": "Comuna Rebricea", "value": "C99876"},
                   {"label": "Comuna Rosiesti", "value": "C99877"},
                   {"label": "Comuna Solesti", "value": "C99878"},
                   {"label": "Comuna Stanilesti", "value": "C99879"},
                   {"label": "Comuna Stefan cel Mare", "value": "C99880"},
                   {"label": "Comuna Suletea", "value": "C99881"},
                   {"label": "Comuna Tacuta", "value": "C99882"},
                   {"label": "Comuna Tanacu", "value": "C99883"},
                   {"label": "Comuna Tatarani", "value": "C99884"},
                   {"label": "Comuna Todiresti", "value": "C99885"},
                   {"label": "Comuna Tutova", "value": "C99886"},
                   {"label": "Comuna Valeni", "value": "C99887"},
                   {"label": "Comuna Vetrisoaia", "value": "C99888"},
                   {"label": "Comuna Viisoara", "value": "C99889"},
                   {"label": "Comuna Vinderei", "value": "C99890"},
                   {"label": "Comuna Voinesti", "value": "C99891"},
                   {"label": "Comuna Vulturesti", "value": "C99892"},
                   {"label": "Comuna Vutcani", "value": "C99893"},
                   {"label": "Comuna Zapodeni", "value": "C99894"},
                   {"label": "Comuna Zorleni", "value": "C99895"},
                   {"label": "Corni-Albesti", "value": "C99896"},
                   {"label": "Coroiesti", "value": "C99897"},
                   {"label": "Costesti", "value": "C99898"},
                   {"label": "Cozmesti", "value": "C99899"},
                   {"label": "Cretesti", "value": "C99900"},
                   {"label": "Danesti", "value": "C99901"},
                   {"label": "Deleni", "value": "C99902"},
                   {"label": "Delesti", "value": "C99903"},
                   {"label": "Dodesti", "value": "C99904"},
                   {"label": "Dragomiresti", "value": "C99905"},
                   {"label": "Dranceni", "value": "C99906"},
                   {"label": "Duda", "value": "C99907"},
                   {"label": "Dumesti", "value": "C99908"},
                   {"label": "Emil Racovita", "value": "C99909"},
                   {"label": "Epureni", "value": "C99910"},
                   {"label": "Falciu", "value": "C99911"},
                   {"label": "Fastaci", "value": "C99912"},
                   {"label": "Feresti", "value": "C99913"},
                   {"label": "Fruntiseni", "value": "C99914"},
                   {"label": "Gagesti", "value": "C99915"},
                   {"label": "Garceni", "value": "C99916"},
                   {"label": "Gherghesti", "value": "C99917"},
                   {"label": "Ghermanesti", "value": "C99918"},
                   {"label": "Grivita", "value": "C99919"},
                   {"label": "Gugesti", "value": "C99920"},
                   {"label": "Halaresti", "value": "C99921"},
                   {"label": "Hoceni", "value": "C99922"},
                   {"label": "Hurdugi", "value": "C99923"},
                   {"label": "Husi", "value": "C99924"},
                   {"label": "Iana", "value": "C99925"},
                   {"label": "Ibanesti", "value": "C99926"},
                   {"label": "Ivanesti", "value": "C99927"},
                   {"label": "Ivesti", "value": "C99928"},
                   {"label": "Laza", "value": "C99929"},
                   {"label": "Lipovat", "value": "C99930"},
                   {"label": "Lunca Banului", "value": "C99931"},
                   {"label": "Malusteni", "value": "C99932"},
                   {"label": "Miclesti", "value": "C99933"},
                   {"label": "Moara Domneasca", "value": "C99934"},
                   {"label": "Moara Grecilor", "value": "C99935"},
                   {"label": "Municipiul Barlad", "value": "C99936"},
                   {"label": "Municipiul Husi", "value": "C99937"},
                   {"label": "Municipiul Vaslui", "value": "C99938"},
                   {"label": "Muntenii de Jos", "value": "C99939"},
                   {"label": "Muntenii de Sus", "value": "C99940"},
                   {"label": "Murgeni", "value": "C99941"},
                   {"label": "Negresti", "value": "C99942"},
                   {"label": "Oltenesti", "value": "C99943"},
                   {"label": "Oras Murgeni", "value": "C99944"},
                   {"label": "Oras Negresti", "value": "C99945"},
                   {"label": "Osesti", "value": "C99946"},
                   {"label": "Padureni", "value": "C99947"},
                   {"label": "Perieni", "value": "C99948"},
                   {"label": "Pochidia", "value": "C99949"},
                   {"label": "Pogana", "value": "C99950"},
                   {"label": "Poganesti", "value": "C99951"},
                   {"label": "Pogonesti", "value": "C99952"},
                   {"label": "Poienesti", "value": "C99953"},
                   {"label": "Popeni", "value": "C99954"},
                   {"label": "Pribesti", "value": "C99955"},
                   {"label": "Puiesti", "value": "C99956"},
                   {"label": "Pungesti", "value": "C99957"},
                   {"label": "Puscasi", "value": "C99958"},
                   {"label": "Rafaila", "value": "C99959"},
                   {"label": "Ranzesti", "value": "C99960"},
                   {"label": "Rasesti", "value": "C99961"},
                   {"label": "Rebricea", "value": "C99962"},
                   {"label": "Rediu", "value": "C99963"},
                   {"label": "Rosiesti", "value": "C99964"},
                   {"label": "Satu Nou", "value": "C99965"},
                   {"label": "Sauca", "value": "C99966"},
                   {"label": "Solesti", "value": "C99967"},
                   {"label": "Stanilesti", "value": "C99968"},
                   {"label": "Stefan cel Mare", "value": "C99969"},
                   {"label": "Suletea", "value": "C99970"},
                   {"label": "Tacuta", "value": "C99971"},
                   {"label": "Tanacu", "value": "C99972"},
                   {"label": "Tatarani", "value": "C99973"},
                   {"label": "Todiresti", "value": "C99974"},
                   {"label": "Trestiana", "value": "C99975"},
                   {"label": "Tutova", "value": "C99976"},
                   {"label": "Valea Grecului", "value": "C99977"},
                   {"label": "Valeni", "value": "C99978"},
                   {"label": "Vaslui", "value": "C99979"},
                   {"label": "Vetrisoaia", "value": "C99980"},
                   {"label": "Viisoara", "value": "C99981"},
                   {"label": "Vinderei", "value": "C99982"},
                   {"label": "Voinesti", "value": "C99983"},
                   {"label": "Vulturesti", "value": "C99984"},
                   {"label": "Vutcani", "value": "C99985"},
                   {"label": "Zapodeni", "value": "C99986"},
                   {"label": "Zorleni", "value": "C99987"}],
 "Vasterbotten County": [{"label": "Asele", "value": "C116089"},
                         {"label": "Backa", "value": "C116090"},
                         {"label": "Bjurholm", "value": "C116091"},
                         {"label": "Boliden", "value": "C116092"},
                         {"label": "Burea", "value": "C116093"},
                         {"label": "Burtrask", "value": "C116094"},
                         {"label": "Byske", "value": "C116095"},
                         {"label": "Dorotea", "value": "C116096"},
                         {"label": "Ersmark", "value": "C116097"},
                         {"label": "Forsbacka", "value": "C116098"},
                         {"label": "Holmsund", "value": "C116099"},
                         {"label": "Hornefors", "value": "C116100"},
                         {"label": "Insjon", "value": "C116101"},
                         {"label": "Kage", "value": "C116102"},
                         {"label": "Langsele", "value": "C116103"},
                         {"label": "Lycksele", "value": "C116104"},
                         {"label": "Mala", "value": "C116105"},
                         {"label": "Nordmaling", "value": "C116106"},
                         {"label": "Norsjo", "value": "C116107"},
                         {"label": "Obbola", "value": "C116108"},
                         {"label": "Roback", "value": "C116109"},
                         {"label": "Robertsfors", "value": "C116110"},
                         {"label": "Savar", "value": "C116111"},
                         {"label": "Skelleftea", "value": "C116112"},
                         {"label": "Skelleftehamn", "value": "C116113"},
                         {"label": "Soderfors", "value": "C116114"},
                         {"label": "Sorsele", "value": "C116115"},
                         {"label": "Storuman", "value": "C116116"},
                         {"label": "Storvik", "value": "C116117"},
                         {"label": "Taftea", "value": "C116118"},
                         {"label": "Umea", "value": "C116119"},
                         {"label": "Ursviken", "value": "C116120"},
                         {"label": "Vannas", "value": "C116121"},
                         {"label": "Vannasby", "value": "C116122"},
                         {"label": "Viken", "value": "C116123"},
                         {"label": "Vilhelmina", "value": "C116124"},
                         {"label": "Vindeln", "value": "C116125"}],
 "Vasternorrland County": [{"label": "Ange", "value": "C116126"},
                           {"label": "As", "value": "C116127"},
                           {"label": "Bergeforsen", "value": "C116128"},
                           {"label": "Bjasta", "value": "C116129"},
                           {"label": "Bollstabruk", "value": "C116130"},
                           {"label": "Bredbyn", "value": "C116131"},
                           {"label": "Fransta", "value": "C116132"},
                           {"label": "Harnosand", "value": "C116133"},
                           {"label": "Husum", "value": "C116134"},
                           {"label": "Johannedal", "value": "C116135"},
                           {"label": "Kopmanholmen", "value": "C116136"},
                           {"label": "Kramfors", "value": "C116137"},
                           {"label": "Kvissleby", "value": "C116138"},
                           {"label": "Langsele", "value": "C116139"},
                           {"label": "Matfors", "value": "C116140"},
                           {"label": "Njurundabommen", "value": "C116141"},
                           {"label": "Nolby", "value": "C116142"},
                           {"label": "Ornskoldsvik", "value": "C116143"},
                           {"label": "Skottsund", "value": "C116144"},
                           {"label": "Solleftea", "value": "C116145"},
                           {"label": "Soraker", "value": "C116146"},
                           {"label": "Stockvik", "value": "C116147"},
                           {"label": "Sundsbruk", "value": "C116148"},
                           {"label": "Sundsvall", "value": "C116149"},
                           {"label": "Timra", "value": "C116150"},
                           {"label": "Vi", "value": "C116151"}],
 "Vastmanland County": [{"label": "Arboga", "value": "C116152"},
                        {"label": "Barkaro", "value": "C116153"},
                        {"label": "Dingtuna", "value": "C116154"},
                        {"label": "Enhagen-Ekbacken", "value": "C116155"},
                        {"label": "Fagersta", "value": "C116156"},
                        {"label": "Hallstahammar", "value": "C116157"},
                        {"label": "Hokasen", "value": "C116158"},
                        {"label": "Irsta", "value": "C116159"},
                        {"label": "Kolback", "value": "C116160"},
                        {"label": "Kolsva", "value": "C116161"},
                        {"label": "Koping", "value": "C116162"},
                        {"label": "Kungsor", "value": "C116163"},
                        {"label": "Norberg", "value": "C116164"},
                        {"label": "Ramnas", "value": "C116165"},
                        {"label": "Sala", "value": "C116166"},
                        {"label": "Skinnskatteberg", "value": "C116167"},
                        {"label": "Skultuna", "value": "C116168"},
                        {"label": "Surahammar", "value": "C116169"},
                        {"label": "Tillberga", "value": "C116170"},
                        {"label": "Vasteras", "value": "C116171"}],
 "Vastra Gotaland County": [{"label": "Akarp", "value": "C116172"},
                            {"label": "Alafors", "value": "C116173"},
                            {"label": "Alingsas", "value": "C116174"},
                            {"label": "Alvangen", "value": "C116175"},
                            {"label": "Amal", "value": "C116176"},
                            {"label": "Andalen", "value": "C116177"},
                            {"label": "Anderstorp", "value": "C116178"},
                            {"label": "Angered", "value": "C116179"},
                            {"label": "Annelund", "value": "C116180"},
                            {"label": "Axvall", "value": "C116181"},
                            {"label": "Bengtsfors", "value": "C116182"},
                            {"label": "Billdal", "value": "C116183"},
                            {"label": "Billingsfors", "value": "C116184"},
                            {"label": "Bjorboholm", "value": "C116185"},
                            {"label": "Bjorko", "value": "C116186"},
                            {"label": "Bjorlanda", "value": "C116187"},
                            {"label": "Bollebygd", "value": "C116188"},
                            {"label": "Boras", "value": "C116189"},
                            {"label": "Bralanda", "value": "C116190"},
                            {"label": "Brastad", "value": "C116191"},
                            {"label": "Dals Langed", "value": "C116192"},
                            {"label": "Dalsjofors", "value": "C116193"},
                            {"label": "Diserod", "value": "C116194"},
                            {"label": "Donso", "value": "C116195"},
                            {"label": "Ed", "value": "C116196"},
                            {"label": "Ellos", "value": "C116197"},
                            {"label": "Eriksbo", "value": "C116198"},
                            {"label": "Falkoping", "value": "C116199"},
                            {"label": "Fargelanda", "value": "C116200"},
                            {"label": "Floby", "value": "C116201"},
                            {"label": "Fristad", "value": "C116202"},
                            {"label": "Fritsla", "value": "C116203"},
                            {"label": "Furulund", "value": "C116204"},
                            {"label": "Ganghester", "value": "C116205"},
                            {"label": "Gardsten", "value": "C116206"},
                            {"label": "Gota", "value": "C116207"},
                            {"label": "Goteborg", "value": "C116208"},
                            {"label": "Goteborgs stad", "value": "C116209"},
                            {"label": "Gotene", "value": "C116210"},
                            {"label": "Grabo", "value": "C116211"},
                            {"label": "Grastorp", "value": "C116212"},
                            {"label": "Grebbestad", "value": "C116213"},
                            {"label": "Gullspang", "value": "C116214"},
                            {"label": "Hammarkullen", "value": "C116215"},
                            {"label": "Henan", "value": "C116216"},
                            {"label": "Herrljunga", "value": "C116217"},
                            {"label": "Hindas", "value": "C116218"},
                            {"label": "Hjo", "value": "C116219"},
                            {"label": "Hjuvik", "value": "C116220"},
                            {"label": "Hono", "value": "C116221"},
                            {"label": "Horred", "value": "C116222"},
                            {"label": "Hova", "value": "C116223"},
                            {"label": "Hoviksnas", "value": "C116224"},
                            {"label": "Hunnebostrand", "value": "C116225"},
                            {"label": "Ingared", "value": "C116226"},
                            {"label": "Jonstorp", "value": "C116227"},
                            {"label": "Jorlanda", "value": "C116228"},
                            {"label": "Kallby", "value": "C116229"},
                            {"label": "Kallered", "value": "C116230"},
                            {"label": "Karlsborg", "value": "C116231"},
                            {"label": "Kinna", "value": "C116232"},
                            {"label": "Kode", "value": "C116233"},
                            {"label": "Kungalv", "value": "C116234"},
                            {"label": "Kungshamn", "value": "C116235"},
                            {"label": "Kvanum", "value": "C116236"},
                            {"label": "Landvetter", "value": "C116237"},
                            {"label": "Langhem", "value": "C116238"},
                            {"label": "Lerum", "value": "C116239"},
                            {"label": "Lidkoping", "value": "C116240"},
                            {"label": "Lilla Edet", "value": "C116241"},
                            {"label": "Limmared", "value": "C116242"},
                            {"label": "Lindome", "value": "C116243"},
                            {"label": "Ljungskile", "value": "C116244"},
                            {"label": "Lodose", "value": "C116245"},
                            {"label": "Lovgardet", "value": "C116246"},
                            {"label": "Lysekil", "value": "C116247"},
                            {"label": "Majorna", "value": "C116248"},
                            {"label": "Mariestad", "value": "C116249"},
                            {"label": "Mellerud", "value": "C116250"},
                            {"label": "Molltorp", "value": "C116251"},
                            {"label": "Molndal", "value": "C116252"},
                            {"label": "Molnlycke", "value": "C116253"},
                            {"label": "Munkedal", "value": "C116254"},
                            {"label": "Myggenas", "value": "C116255"},
                            {"label": "Nolvik", "value": "C116256"},
                            {"label": "Nossebro", "value": "C116257"},
                            {"label": "Ockero", "value": "C116258"},
                            {"label": "Odsmal", "value": "C116259"},
                            {"label": "Ojersjo", "value": "C116260"},
                            {"label": "Olofstorp", "value": "C116261"},
                            {"label": "Olstorp", "value": "C116262"},
                            {"label": "Orust", "value": "C116263"},
                            {"label": "Partille", "value": "C116264"},
                            {"label": "Rannebergen", "value": "C116265"},
                            {"label": "Ravlanda", "value": "C116266"},
                            {"label": "Ronnang", "value": "C116267"},
                            {"label": "Salanda", "value": "C116268"},
                            {"label": "Sandared", "value": "C116269"},
                            {"label": "Satila", "value": "C116270"},
                            {"label": "Sjomarken", "value": "C116271"},
                            {"label": "Sjuntorp", "value": "C116272"},
                            {"label": "Skara", "value": "C116273"},
                            {"label": "Skarhamn", "value": "C116274"},
                            {"label": "Skepplanda", "value": "C116275"},
                            {"label": "Skovde", "value": "C116276"},
                            {"label": "Skultorp", "value": "C116277"},
                            {"label": "Smogen", "value": "C116278"},
                            {"label": "Sollebrunn", "value": "C116279"},
                            {"label": "Stenkullen", "value": "C116280"},
                            {"label": "Stenstorp", "value": "C116281"},
                            {"label": "Stenungsund", "value": "C116282"},
                            {"label": "Stopen", "value": "C116283"},
                            {"label": "Stora Hoga", "value": "C116284"},
                            {"label": "Stromstad", "value": "C116285"},
                            {"label": "Styrso", "value": "C116286"},
                            {"label": "Surte", "value": "C116287"},
                            {"label": "Svanesund", "value": "C116288"},
                            {"label": "Svenljunga", "value": "C116289"},
                            {"label": "Tanumshede", "value": "C116290"},
                            {"label": "Tibro", "value": "C116291"},
                            {"label": "Tidaholm", "value": "C116292"},
                            {"label": "Tjuvkil", "value": "C116293"},
                            {"label": "Toreboda", "value": "C116294"},
                            {"label": "Torslanda", "value": "C116295"},
                            {"label": "Tranemo", "value": "C116296"},
                            {"label": "Trollhattan", "value": "C116297"},
                            {"label": "Uddevalla", "value": "C116298"},
                            {"label": "Ulricehamn", "value": "C116299"},
                            {"label": "Vanersborg", "value": "C116300"},
                            {"label": "Vara", "value": "C116301"},
                            {"label": "Vargarda", "value": "C116302"},
                            {"label": "Vargon", "value": "C116303"},
                            {"label": "Vastra Frolunda", "value": "C116304"},
                            {"label": "Vinninga", "value": "C116305"},
                            {"label": "Viskafors", "value": "C116306"}],
 "Vaud": [{"label": "Aigle", "value": "C117325"},
          {"label": "Aigle District", "value": "C117326"},
          {"label": "Apples", "value": "C117327"},
          {"label": "Arzier", "value": "C117328"},
          {"label": "Aubonne", "value": "C117329"},
          {"label": "Avenches", "value": "C117330"},
          {"label": "Ballens", "value": "C117331"},
          {"label": "Bavois", "value": "C117332"},
          {"label": "Begnins", "value": "C117333"},
          {"label": "Belmont-sur-Lausanne", "value": "C117334"},
          {"label": "Berolle", "value": "C117335"},
          {"label": "Bex", "value": "C117336"},
          {"label": "Biere", "value": "C117337"},
          {"label": "Blecherette", "value": "C117338"},
          {"label": "Blonay", "value": "C117339"},
          {"label": "Bottens", "value": "C117340"},
          {"label": "Broye-Vully District", "value": "C117341"},
          {"label": "Bussigny", "value": "C117342"},
          {"label": "Caux", "value": "C117343"},
          {"label": "Chardonne", "value": "C117344"},
          {"label": "Chateau-dOex", "value": "C117345"},
          {"label": "Chavannes", "value": "C117346"},
          {"label": "Chavannes-le-Veyron", "value": "C117347"},
          {"label": "Chavornay", "value": "C117348"},
          {"label": "Cheseaux", "value": "C117349"},
          {"label": "Chevilly", "value": "C117350"},
          {"label": "Chexbres", "value": "C117351"},
          {"label": "Colombier", "value": "C117352"},
          {"label": "Coppet", "value": "C117353"},
          {"label": "Cossonay", "value": "C117354"},
          {"label": "Crissier", "value": "C117355"},
          {"label": "Cuarnens", "value": "C117356"},
          {"label": "Cully", "value": "C117357"},
          {"label": "Echallens", "value": "C117358"},
          {"label": "Ecublens", "value": "C117359"},
          {"label": "Epalinges", "value": "C117360"},
          {"label": "Ferreyres", "value": "C117361"},
          {"label": "Founex", "value": "C117362"},
          {"label": "Froideville", "value": "C117363"},
          {"label": "Genolier", "value": "C117364"},
          {"label": "Gimel", "value": "C117365"},
          {"label": "Gland", "value": "C117366"},
          {"label": "Grancy", "value": "C117367"},
          {"label": "Grandson", "value": "C117368"},
          {"label": "Gros-de-Vaud District", "value": "C117369"},
          {"label": "Gryon", "value": "C117370"},
          {"label": "Jongny", "value": "C117371"},
          {"label": "Jura-Nord vaudois District", "value": "C117372"},
          {"label": "La Sarraz", "value": "C117373"},
          {"label": "La Tour-de-Peilz", "value": "C117374"},
          {"label": "Lausanne", "value": "C117375"},
          {"label": "Lausanne District", "value": "C117376"},
          {"label": "Lavaux-Oron District", "value": "C117377"},
          {"label": "Le Chenit", "value": "C117378"},
          {"label": "Le Mont-sur-Lausanne", "value": "C117379"},
          {"label": "Le Vaud", "value": "C117380"},
          {"label": "Leysin", "value": "C117381"},
          {"label": "Lucens", "value": "C117382"},
          {"label": "Lutry", "value": "C117383"},
          {"label": "Mauraz", "value": "C117384"},
          {"label": "Mollens", "value": "C117385"},
          {"label": "Montagny", "value": "C117386"},
          {"label": "Montreux", "value": "C117387"},
          {"label": "Morges", "value": "C117388"},
          {"label": "Morges District", "value": "C117389"},
          {"label": "Moudon", "value": "C117390"},
          {"label": "Nyon", "value": "C117391"},
          {"label": "Nyon District", "value": "C117392"},
          {"label": "Ollon", "value": "C117393"},
          {"label": "Orbe", "value": "C117394"},
          {"label": "Oron-la-Ville", "value": "C117395"},
          {"label": "Ouest Lausannois District", "value": "C117396"},
          {"label": "Pailly", "value": "C117397"},
          {"label": "Palezieux", "value": "C117398"},
          {"label": "Pampigny", "value": "C117399"},
          {"label": "Paudex", "value": "C117400"},
          {"label": "Payerne", "value": "C117401"},
          {"label": "Penthalaz", "value": "C117402"},
          {"label": "Penthereaz", "value": "C117403"},
          {"label": "Perroy", "value": "C117404"},
          {"label": "Pompaples", "value": "C117405"},
          {"label": "Prangins", "value": "C117406"},
          {"label": "Preverenges", "value": "C117407"},
          {"label": "Prilly", "value": "C117408"},
          {"label": "Puidoux", "value": "C117409"},
          {"label": "Pully", "value": "C117410"},
          {"label": "Renens", "value": "C117411"},
          {"label": "Riviera-Pays-dEnhaut District", "value": "C117412"},
          {"label": "Rolle", "value": "C117413"},
          {"label": "Romanel-sur-Lausanne", "value": "C117414"},
          {"label": "Saint-Cergue", "value": "C117415"},
          {"label": "Saint-Livres", "value": "C117416"},
          {"label": "Saint-Prex", "value": "C117417"},
          {"label": "Sainte-Croix", "value": "C117418"},
          {"label": "Saubraz", "value": "C117419"},
          {"label": "Savigny", "value": "C117420"},
          {"label": "Sottens", "value": "C117421"},
          {"label": "Trelex", "value": "C117422"},
          {"label": "Vallorbe", "value": "C117423"},
          {"label": "Vevey", "value": "C117424"},
          {"label": "Villars-sur-Ollon", "value": "C117425"},
          {"label": "Villeneuve", "value": "C117426"},
          {"label": "Vuarrens", "value": "C117427"},
          {"label": "Yverdon-les-Bains", "value": "C117428"},
          {"label": "Yvonand", "value": "C117429"}],
 "Vaupes": [{"label": "Caruru", "value": "C20648"},
            {"label": "Mitu", "value": "C20649"},
            {"label": "Pacoa", "value": "C20650"},
            {"label": "Papunaua", "value": "C20651"},
            {"label": "Taraira", "value": "C20652"},
            {"label": "Yavarate", "value": "C20653"}],
 "Vava`u": [{"label": "Neiafu", "value": "C119654"}],
 "Vayots Dzor Region": [{"label": "Agarakadzor", "value": "C1834"},
                        {"label": "Aghavnadzor", "value": "C1835"},
                        {"label": "Areni", "value": "C1836"},
                        {"label": "Getap", "value": "C1837"},
                        {"label": "Gladzor", "value": "C1838"},
                        {"label": "Jermuk", "value": "C1839"},
                        {"label": "Malishka", "value": "C1840"},
                        {"label": "Rrind", "value": "C1841"},
                        {"label": "Shatin", "value": "C1842"},
                        {"label": "Vayk", "value": "C1843"},
                        {"label": "Vernashen", "value": "C1844"},
                        {"label": "Yeghegis", "value": "C1845"},
                        {"label": "Yeghegnadzor", "value": "C1846"},
                        {"label": "Zarritap", "value": "C1847"}],
 "Vecpiebalga Municipality": [{"label": "Vecpiebalga", "value": "C64956"}],
 "Vecumnieki Municipality": [{"label": "Vecumnieki", "value": "C64957"}],
 "Veles Municipality": [{"label": "Gorno Orizari", "value": "C65535"},
                        {"label": "Ivankovci", "value": "C65536"},
                        {"label": "Veles", "value": "C65537"}],
 "Velika Polana Municipality": [{"label": "Velika Polana", "value": "C107847"}],
 "Velike Lasce Municipality": [{"label": "Velike Lasce", "value": "C107848"}],
 "Veliko Tarnovo Province": [{"label": "Byala Cherkva", "value": "C16278"},
                             {"label": "Debelets", "value": "C16279"},
                             {"label": "Elena", "value": "C16280"},
                             {"label": "Gorna Oryahovitsa", "value": "C16281"},
                             {"label": "Kilifarevo", "value": "C16282"},
                             {"label": "Lyaskovets", "value": "C16283"},
                             {"label": "Obshtina Elena", "value": "C16284"},
                             {"label": "Obshtina Gorna Oryahovitsa",
                              "value": "C16285"},
                             {"label": "Obshtina Lyaskovets",
                              "value": "C16286"},
                             {"label": "Obshtina Pavlikeni", "value": "C16287"},
                             {"label": "Obshtina Polski Trambesh",
                              "value": "C16288"},
                             {"label": "Obshtina Strazhitsa",
                              "value": "C16289"},
                             {"label": "Obshtina Suhindol", "value": "C16290"},
                             {"label": "Obshtina Svishtov", "value": "C16291"},
                             {"label": "Obshtina Veliko Turnovo",
                              "value": "C16292"},
                             {"label": "Obshtina Zlataritsa",
                              "value": "C16293"},
                             {"label": "Parvomaytsi", "value": "C16294"},
                             {"label": "Pavlikeni", "value": "C16295"},
                             {"label": "Polski Trambesh", "value": "C16296"},
                             {"label": "Strazhitsa", "value": "C16297"},
                             {"label": "Suhindol", "value": "C16298"},
                             {"label": "Svishtov", "value": "C16299"},
                             {"label": "Veliko Turnovo", "value": "C16300"},
                             {"label": "Zlataritsa", "value": "C16301"}],
 "Veneto": [{"label": "Abano Terme", "value": "C60847"},
            {"label": "Abbazia Pisani", "value": "C60848"},
            {"label": "Adria", "value": "C60849"},
            {"label": "Affi", "value": "C60850"},
            {"label": "Agna", "value": "C60851"},
            {"label": "Agordo", "value": "C60852"},
            {"label": "Agugliaro", "value": "C60853"},
            {"label": "Alano di Piave", "value": "C60854"},
            {"label": "Albaredo", "value": "C60855"},
            {"label": "Albaredo dAdige", "value": "C60856"},
            {"label": "Albettone", "value": "C60857"},
            {"label": "Albignasego", "value": "C60858"},
            {"label": "Alleghe", "value": "C60859"},
            {"label": "Alonte", "value": "C60860"},
            {"label": "Alpo", "value": "C60861"},
            {"label": "Altavilla Vicentina", "value": "C60862"},
            {"label": "Altissimo", "value": "C60863"},
            {"label": "Altivole", "value": "C60864"},
            {"label": "Angiari", "value": "C60865"},
            {"label": "Anguillara Veneta", "value": "C60866"},
            {"label": "Annone Veneto", "value": "C60867"},
            {"label": "Arbizzano-Santa Maria", "value": "C60868"},
            {"label": "Arcade", "value": "C60869"},
            {"label": "Arcole", "value": "C60870"},
            {"label": "Arcugnano-Torri", "value": "C60871"},
            {"label": "Ariano", "value": "C60872"},
            {"label": "Arino", "value": "C60873"},
            {"label": "Arlesega", "value": "C60874"},
            {"label": "Arqua Petrarca", "value": "C60875"},
            {"label": "Arqua Polesine", "value": "C60876"},
            {"label": "Arre", "value": "C60877"},
            {"label": "Arsie", "value": "C60878"},
            {"label": "Arsiero", "value": "C60879"},
            {"label": "Arzergrande", "value": "C60880"},
            {"label": "Arzignano", "value": "C60881"},
            {"label": "Asiago", "value": "C60882"},
            {"label": "Asigliano Veneto", "value": "C60883"},
            {"label": "Asolo", "value": "C60884"},
            {"label": "Asparetto", "value": "C60885"},
            {"label": "Auronzo", "value": "C60886"},
            {"label": "Badia Calavena", "value": "C60887"},
            {"label": "Badia Polesine", "value": "C60888"},
            {"label": "Badoere", "value": "C60889"},
            {"label": "Bagnoli di Sopra", "value": "C60890"},
            {"label": "Bagnolo", "value": "C60891"},
            {"label": "Bagnolo di Po", "value": "C60892"},
            {"label": "Ballo", "value": "C60893"},
            {"label": "Baone", "value": "C60894"},
            {"label": "Barbarano Vicentino", "value": "C60895"},
            {"label": "Barbisano", "value": "C60896"},
            {"label": "Barbona", "value": "C60897"},
            {"label": "Bardolino", "value": "C60898"},
            {"label": "Baruchella", "value": "C60899"},
            {"label": "Bassano del Grappa", "value": "C60900"},
            {"label": "Bassone", "value": "C60901"},
            {"label": "Bastia", "value": "C60902"},
            {"label": "Battaglia Terme", "value": "C60903"},
            {"label": "Beccacivetta-Azzano", "value": "C60904"},
            {"label": "Belfiore", "value": "C60905"},
            {"label": "Belluno", "value": "C60906"},
            {"label": "Belvedere", "value": "C60907"},
            {"label": "Bergantino", "value": "C60908"},
            {"label": "Bertipaglia", "value": "C60909"},
            {"label": "Bevilacqua", "value": "C60910"},
            {"label": "Biancade", "value": "C60911"},
            {"label": "Bibano", "value": "C60912"},
            {"label": "Bibione", "value": "C60913"},
            {"label": "Bigolino", "value": "C60914"},
            {"label": "Boara Pisani", "value": "C60915"},
            {"label": "Bojon-Lova", "value": "C60916"},
            {"label": "Bolzano Vicentino", "value": "C60917"},
            {"label": "Bonavicina", "value": "C60918"},
            {"label": "Bonavigo", "value": "C60919"},
            {"label": "Bonferraro", "value": "C60920"},
            {"label": "Borca", "value": "C60921"},
            {"label": "Borgoricco-San Michele delle Badesse-SantEufemia",
             "value": "C60922"},
            {"label": "Borso del Grappa", "value": "C60923"},
            {"label": "Bortolot", "value": "C60924"},
            {"label": "Bosaro", "value": "C60925"},
            {"label": "Boschi SantAnna", "value": "C60926"},
            {"label": "Bosco", "value": "C60927"},
            {"label": "Bosco Chiesanuova", "value": "C60928"},
            {"label": "Boscochiaro", "value": "C60929"},
            {"label": "Bottrighe", "value": "C60930"},
            {"label": "Bovolenta", "value": "C60931"},
            {"label": "Bovolone", "value": "C60932"},
            {"label": "Braglia", "value": "C60933"},
            {"label": "Breda", "value": "C60934"},
            {"label": "Breganze", "value": "C60935"},
            {"label": "Brendola", "value": "C60936"},
            {"label": "Brentino Belluno", "value": "C60937"},
            {"label": "Brenzone", "value": "C60938"},
            {"label": "Bressanvido", "value": "C60939"},
            {"label": "Brogliano", "value": "C60940"},
            {"label": "Brognoligo-Costalunga", "value": "C60941"},
            {"label": "Brugine", "value": "C60942"},
            {"label": "Burano", "value": "C60943"},
            {"label": "Buso", "value": "C60944"},
            {"label": "Bussolengo", "value": "C60945"},
            {"label": "Buttapietra", "value": "C60946"},
            {"label": "Ca degli Oppi", "value": "C60947"},
            {"label": "Ca Rainati", "value": "C60948"},
            {"label": "Ca Savio", "value": "C60949"},
            {"label": "Ca Tiepolo", "value": "C60950"},
            {"label": "Cadoneghe", "value": "C60951"},
            {"label": "Caerano di San Marco", "value": "C60952"},
            {"label": "Calalzo di Cadore", "value": "C60953"},
            {"label": "Caldierino-Rota", "value": "C60954"},
            {"label": "Caldiero", "value": "C60955"},
            {"label": "Caldogno-Rettorgole-Cresole", "value": "C60956"},
            {"label": "Calmasino", "value": "C60957"},
            {"label": "Caltana", "value": "C60958"},
            {"label": "Calto", "value": "C60959"},
            {"label": "Caltrano", "value": "C60960"},
            {"label": "Calvene", "value": "C60961"},
            {"label": "Camalo", "value": "C60962"},
            {"label": "Camisano Vicentino", "value": "C60963"},
            {"label": "Campagna Lupia", "value": "C60964"},
            {"label": "Campagnola", "value": "C60965"},
            {"label": "Campalto", "value": "C60966"},
            {"label": "Campese", "value": "C60967"},
            {"label": "Campiglia dei Berici", "value": "C60968"},
            {"label": "Campo San Martino", "value": "C60969"},
            {"label": "Campodarsego", "value": "C60970"},
            {"label": "Campodoro", "value": "C60971"},
            {"label": "Campolongo Maggiore Liettoli", "value": "C60972"},
            {"label": "Campolongo sul Brenta", "value": "C60973"},
            {"label": "Camponogara", "value": "C60974"},
            {"label": "Camposampiero", "value": "C60975"},
            {"label": "Canale dAgordo", "value": "C60976"},
            {"label": "Canaro", "value": "C60977"},
            {"label": "Canda", "value": "C60978"},
            {"label": "Candelu", "value": "C60979"},
            {"label": "Candiana", "value": "C60980"},
            {"label": "Candide", "value": "C60981"},
            {"label": "Caniezza", "value": "C60982"},
            {"label": "Canove di Roana", "value": "C60983"},
            {"label": "Caorle", "value": "C60984"},
            {"label": "Capitello", "value": "C60985"},
            {"label": "Cappella Maggiore", "value": "C60986"},
            {"label": "Caprino Veronese", "value": "C60987"},
            {"label": "Carbonera", "value": "C60988"},
            {"label": "Carceri", "value": "C60989"},
            {"label": "Carmignano di Brenta", "value": "C60990"},
            {"label": "Carpesica", "value": "C60991"},
            {"label": "Carrara San Giorgio", "value": "C60992"},
            {"label": "Carre", "value": "C60993"},
            {"label": "Cartigliano", "value": "C60994"},
            {"label": "Cartura", "value": "C60995"},
            {"label": "Casacorba", "value": "C60996"},
            {"label": "Casale di Scodosia", "value": "C60997"},
            {"label": "Casale sul Sile", "value": "C60998"},
            {"label": "Casaleone", "value": "C60999"},
            {"label": "Casalserugo", "value": "C61000"},
            {"label": "Caselle", "value": "C61001"},
            {"label": "Casier", "value": "C61002"},
            {"label": "Casoni", "value": "C61003"},
            {"label": "Cassola", "value": "C61004"},
            {"label": "Castagnaro", "value": "C61005"},
            {"label": "Castagnole", "value": "C61006"},
            {"label": "Castelbaldo", "value": "C61007"},
            {"label": "Castelcucco", "value": "C61008"},
            {"label": "Castelfranco Veneto", "value": "C61009"},
            {"label": "Castelgomberto", "value": "C61010"},
            {"label": "Castelguglielmo", "value": "C61011"},
            {"label": "Castello di Godego", "value": "C61012"},
            {"label": "Castelmassa", "value": "C61013"},
            {"label": "Castelminio", "value": "C61014"},
            {"label": "Castelnovo", "value": "C61015"},
            {"label": "Castelnovo Bariano", "value": "C61016"},
            {"label": "Castelnuovo del Garda", "value": "C61017"},
            {"label": "Castion", "value": "C61018"},
            {"label": "Cavajon Veronese", "value": "C61019"},
            {"label": "Cavalcaselle", "value": "C61020"},
            {"label": "Cavallino", "value": "C61021"},
            {"label": "Cavarzere", "value": "C61022"},
            {"label": "Cavaso del Tomba", "value": "C61023"},
            {"label": "Cavino", "value": "C61024"},
            {"label": "Cazzago-Ex Polo", "value": "C61025"},
            {"label": "Cazzano di Tramigna", "value": "C61026"},
            {"label": "Ceggia", "value": "C61027"},
            {"label": "Celat-San Tomaso Agordino", "value": "C61028"},
            {"label": "Cellore", "value": "C61029"},
            {"label": "Cencenighe Agordino", "value": "C61030"},
            {"label": "Cendon", "value": "C61031"},
            {"label": "Ceneselli", "value": "C61032"},
            {"label": "Centrale", "value": "C61033"},
            {"label": "Cerea", "value": "C61034"},
            {"label": "Cereda-Cozza Cornedo", "value": "C61035"},
            {"label": "Ceregnano", "value": "C61036"},
            {"label": "Cerro Veronese", "value": "C61037"},
            {"label": "Cervarese Santa Croce", "value": "C61038"},
            {"label": "Cesarolo", "value": "C61039"},
            {"label": "Cesiomaggiore", "value": "C61040"},
            {"label": "Cessalto", "value": "C61041"},
            {"label": "Chiampo", "value": "C61042"},
            {"label": "Chiarano", "value": "C61043"},
            {"label": "Chioggia", "value": "C61044"},
            {"label": "Chiuppano", "value": "C61045"},
            {"label": "Ciano", "value": "C61046"},
            {"label": "Cibiana", "value": "C61047"},
            {"label": "Cimadolmo", "value": "C61048"},
            {"label": "Cinto Caomaggiore", "value": "C61049"},
            {"label": "Cismon del Grappa", "value": "C61050"},
            {"label": "Cison di Valmarino", "value": "C61051"},
            {"label": "Cittadella", "value": "C61052"},
            {"label": "Codevigo", "value": "C61053"},
            {"label": "Codiverno", "value": "C61054"},
            {"label": "Codogne", "value": "C61055"},
            {"label": "Cogollo del Cengio", "value": "C61056"},
            {"label": "Col San Martino", "value": "C61057"},
            {"label": "Cola", "value": "C61058"},
            {"label": "Colle Santa Lucia", "value": "C61059"},
            {"label": "Colle Umberto", "value": "C61060"},
            {"label": "Cologna Veneta", "value": "C61061"},
            {"label": "Colognola ai Colli", "value": "C61062"},
            {"label": "Conco", "value": "C61063"},
            {"label": "Concordia Sagittaria", "value": "C61064"},
            {"label": "Conegliano", "value": "C61065"},
            {"label": "Conscio", "value": "C61066"},
            {"label": "Conselve", "value": "C61067"},
            {"label": "Corbanese", "value": "C61068"},
            {"label": "Corbola", "value": "C61069"},
            {"label": "Corbolone", "value": "C61070"},
            {"label": "Cordignano", "value": "C61071"},
            {"label": "Cornedo Vicentino", "value": "C61072"},
            {"label": "Cornuda", "value": "C61073"},
            {"label": "Correzzola", "value": "C61074"},
            {"label": "Corrubbio", "value": "C61075"},
            {"label": "Corte", "value": "C61076"},
            {"label": "Cortina dAmpezzo", "value": "C61077"},
            {"label": "Cosniga-Zoppe", "value": "C61078"},
            {"label": "Costa di Rovigo", "value": "C61079"},
            {"label": "Costabissara", "value": "C61080"},
            {"label": "Costermano", "value": "C61081"},
            {"label": "Covolo-Levada", "value": "C61082"},
            {"label": "Creazzo", "value": "C61083"},
            {"label": "Crepaldo", "value": "C61084"},
            {"label": "Crespadoro", "value": "C61085"},
            {"label": "Crespano del Grappa", "value": "C61086"},
            {"label": "Crespino", "value": "C61087"},
            {"label": "Crispi Cavour", "value": "C61088"},
            {"label": "Crocetta del Montello", "value": "C61089"},
            {"label": "Crocetta-Nogare", "value": "C61090"},
            {"label": "Curtarolo", "value": "C61091"},
            {"label": "Danta", "value": "C61092"},
            {"label": "Dese", "value": "C61093"},
            {"label": "Dolce", "value": "C61094"},
            {"label": "Dolo", "value": "C61095"},
            {"label": "Domegge di Cadore", "value": "C61096"},
            {"label": "Don", "value": "C61097"},
            {"label": "Donzella", "value": "C61098"},
            {"label": "Dossobuono", "value": "C61099"},
            {"label": "Dosson", "value": "C61100"},
            {"label": "Due Carrare", "value": "C61101"},
            {"label": "Dueville", "value": "C61102"},
            {"label": "Enego", "value": "C61103"},
            {"label": "Eraclea", "value": "C61104"},
            {"label": "Erbe", "value": "C61105"},
            {"label": "Erbezzo", "value": "C61106"},
            {"label": "Este", "value": "C61107"},
            {"label": "Falcade Alto", "value": "C61108"},
            {"label": "Falze di Piave", "value": "C61109"},
            {"label": "Falze-Signoressa", "value": "C61110"},
            {"label": "Fane", "value": "C61111"},
            {"label": "Fanzolo", "value": "C61112"},
            {"label": "Fara Vicentino", "value": "C61113"},
            {"label": "Farra dAlpago", "value": "C61114"},
            {"label": "Farra di Soligo", "value": "C61115"},
            {"label": "Feltre", "value": "C61116"},
            {"label": "Ferrara di Monte Baldo", "value": "C61117"},
            {"label": "Ficarolo", "value": "C61118"},
            {"label": "Fiesso", "value": "C61119"},
            {"label": "Fiesso dArtico", "value": "C61120"},
            {"label": "Fiesso Umbertiano", "value": "C61121"},
            {"label": "Follina", "value": "C61122"},
            {"label": "Fontanafredda", "value": "C61123"},
            {"label": "Fontanelle", "value": "C61124"},
            {"label": "Fontaniva", "value": "C61125"},
            {"label": "Fonzaso", "value": "C61126"},
            {"label": "Forette", "value": "C61127"},
            {"label": "Fornaci", "value": "C61128"},
            {"label": "Fornase", "value": "C61129"},
            {"label": "Forno di Zoldo", "value": "C61130"},
            {"label": "Fossalta di Piave", "value": "C61131"},
            {"label": "Fossalta di Portogruaro", "value": "C61132"},
            {"label": "Fossalunga", "value": "C61133"},
            {"label": "Fosso", "value": "C61134"},
            {"label": "Foza", "value": "C61135"},
            {"label": "Francenigo", "value": "C61136"},
            {"label": "Frassinelle Polesine", "value": "C61137"},
            {"label": "Fratta Polesine", "value": "C61138"},
            {"label": "Fregona", "value": "C61139"},
            {"label": "Frescada", "value": "C61140"},
            {"label": "Fumane", "value": "C61141"},
            {"label": "Fusine", "value": "C61142"},
            {"label": "Gaiarine", "value": "C61143"},
            {"label": "Gaiba", "value": "C61144"},
            {"label": "Galliera Veneta", "value": "C61145"},
            {"label": "Gallio", "value": "C61146"},
            {"label": "Galta", "value": "C61147"},
            {"label": "Galzignano", "value": "C61148"},
            {"label": "Gambellara", "value": "C61149"},
            {"label": "Gambugliano", "value": "C61150"},
            {"label": "Garda", "value": "C61151"},
            {"label": "Gavello", "value": "C61152"},
            {"label": "Gazzo", "value": "C61153"},
            {"label": "Gazzolo-Volpino", "value": "C61154"},
            {"label": "Giacciano con Baruchella", "value": "C61155"},
            {"label": "Giavenale", "value": "C61156"},
            {"label": "Giavera del Montello", "value": "C61157"},
            {"label": "Giudecca", "value": "C61158"},
            {"label": "Godega", "value": "C61159"},
            {"label": "Gorgo al Monticano", "value": "C61160"},
            {"label": "Gorgo della Chiesa", "value": "C61161"},
            {"label": "Gosaldo", "value": "C61162"},
            {"label": "Grancona", "value": "C61163"},
            {"label": "Grantorto", "value": "C61164"},
            {"label": "Granze", "value": "C61165"},
            {"label": "Grezzana", "value": "C61166"},
            {"label": "Grignano Polesine", "value": "C61167"},
            {"label": "Grisignano di Zocco", "value": "C61168"},
            {"label": "Gruaro", "value": "C61169"},
            {"label": "Gruaro-Bagnara", "value": "C61170"},
            {"label": "Grumolo delle Abbadesse", "value": "C61171"},
            {"label": "Grumolo Pedemonte", "value": "C61172"},
            {"label": "Guarda Veneta", "value": "C61173"},
            {"label": "Iesolo", "value": "C61174"},
            {"label": "Illasi", "value": "C61175"},
            {"label": "Isola della Scala", "value": "C61176"},
            {"label": "Isola Rizza", "value": "C61177"},
            {"label": "Isola Vicentina", "value": "C61178"},
            {"label": "Istrana", "value": "C61179"},
            {"label": "La Salute di Livenza", "value": "C61180"},
            {"label": "La Valle Agordina", "value": "C61181"},
            {"label": "Laghi", "value": "C61182"},
            {"label": "Lama Pezzoli", "value": "C61183"},
            {"label": "Lamon", "value": "C61184"},
            {"label": "Lamosano", "value": "C61185"},
            {"label": "Lancenigo-Villorba", "value": "C61186"},
            {"label": "Lastebasse", "value": "C61187"},
            {"label": "Lavagno", "value": "C61188"},
            {"label": "Lazise", "value": "C61189"},
            {"label": "Legnago", "value": "C61190"},
            {"label": "Legnaro", "value": "C61191"},
            {"label": "Lendinara", "value": "C61192"},
            {"label": "Lentiai", "value": "C61193"},
            {"label": "Levada", "value": "C61194"},
            {"label": "Lido", "value": "C61195"},
            {"label": "Lido di Jesolo", "value": "C61196"},
            {"label": "Limana", "value": "C61197"},
            {"label": "Limena", "value": "C61198"},
            {"label": "Lisiera", "value": "C61199"},
            {"label": "Livinallongo del Col di Lana", "value": "C61200"},
            {"label": "Locara", "value": "C61201"},
            {"label": "Longare", "value": "C61202"},
            {"label": "Longarone", "value": "C61203"},
            {"label": "Longhi", "value": "C61204"},
            {"label": "Lonigo", "value": "C61205"},
            {"label": "Loreggia", "value": "C61206"},
            {"label": "Loreggiola", "value": "C61207"},
            {"label": "Lorenzago di Cadore", "value": "C61208"},
            {"label": "Loreo", "value": "C61209"},
            {"label": "Loria Bessica", "value": "C61210"},
            {"label": "Lozzo Atestino", "value": "C61211"},
            {"label": "Lozzo di Cadore", "value": "C61212"},
            {"label": "Lugagnano", "value": "C61213"},
            {"label": "Lughignano", "value": "C61214"},
            {"label": "Lugo di Vicenza", "value": "C61215"},
            {"label": "Lugugnana", "value": "C61216"},
            {"label": "Lumignano", "value": "C61217"},
            {"label": "Lusia", "value": "C61218"},
            {"label": "Lusiana", "value": "C61219"},
            {"label": "Lutrano", "value": "C61220"},
            {"label": "Maccacari", "value": "C61221"},
            {"label": "Maerne", "value": "C61222"},
            {"label": "Magugnano", "value": "C61223"},
            {"label": "Malcesine", "value": "C61224"},
            {"label": "Malo", "value": "C61225"},
            {"label": "Mandriola-SantAgostino", "value": "C61226"},
            {"label": "Mansue", "value": "C61227"},
            {"label": "Marano", "value": "C61228"},
            {"label": "Marano di Valpolicella", "value": "C61229"},
            {"label": "Marano Vicentino", "value": "C61230"},
            {"label": "Marchesino-Bovo", "value": "C61231"},
            {"label": "Marcon-Gaggio-Colmello", "value": "C61232"},
            {"label": "Mardimago", "value": "C61233"},
            {"label": "Mareno di Piave", "value": "C61234"},
            {"label": "Marola", "value": "C61235"},
            {"label": "Marostica", "value": "C61236"},
            {"label": "Martellago", "value": "C61237"},
            {"label": "Maser", "value": "C61238"},
            {"label": "Masera di Padova", "value": "C61239"},
            {"label": "Maserada sul Piave", "value": "C61240"},
            {"label": "Masi", "value": "C61241"},
            {"label": "Mason Vicentino", "value": "C61242"},
            {"label": "Massanzago-Ca Baglioni-San Dono", "value": "C61243"},
            {"label": "Meduna di Livenza", "value": "C61244"},
            {"label": "Megliadino San Fidenzio", "value": "C61245"},
            {"label": "Megliadino San Vitale", "value": "C61246"},
            {"label": "Mejaniga", "value": "C61247"},
            {"label": "Mel", "value": "C61248"},
            {"label": "Melara", "value": "C61249"},
            {"label": "Meledo", "value": "C61250"},
            {"label": "Meolo", "value": "C61251"},
            {"label": "Merlara", "value": "C61252"},
            {"label": "Mestre", "value": "C61253"},
            {"label": "Mestrino", "value": "C61254"},
            {"label": "Mezzane di Sotto", "value": "C61255"},
            {"label": "Miane", "value": "C61256"},
            {"label": "Mignagola", "value": "C61257"},
            {"label": "Minerbe", "value": "C61258"},
            {"label": "Mira Taglio", "value": "C61259"},
            {"label": "Mirano", "value": "C61260"},
            {"label": "Mogliano Veneto", "value": "C61261"},
            {"label": "Molino-Mozzi-Bittarelli", "value": "C61262"},
            {"label": "Monastier di Treviso", "value": "C61263"},
            {"label": "Monfumo", "value": "C61264"},
            {"label": "Moniego", "value": "C61265"},
            {"label": "Monselice", "value": "C61266"},
            {"label": "Montagnana", "value": "C61267"},
            {"label": "Monte di Malo", "value": "C61268"},
            {"label": "Montebello Vicentino", "value": "C61269"},
            {"label": "Montebelluna", "value": "C61270"},
            {"label": "Montecchia di Crosara", "value": "C61271"},
            {"label": "Montecchio Maggiore-Alte Ceccato", "value": "C61272"},
            {"label": "Montecchio Precalcino", "value": "C61273"},
            {"label": "Monteforte dAlpone", "value": "C61274"},
            {"label": "Montegalda", "value": "C61275"},
            {"label": "Montegaldella", "value": "C61276"},
            {"label": "Montegrotto Terme", "value": "C61277"},
            {"label": "Montemerlo", "value": "C61278"},
            {"label": "Monteviale", "value": "C61279"},
            {"label": "Monticelli-Fontana", "value": "C61280"},
            {"label": "Monticello Conte Otto", "value": "C61281"},
            {"label": "Monticello di Fara", "value": "C61282"},
            {"label": "Montorio", "value": "C61283"},
            {"label": "Montorso Vicentino", "value": "C61284"},
            {"label": "Moriago della Battaglia", "value": "C61285"},
            {"label": "Mosnigo", "value": "C61286"},
            {"label": "Mossano", "value": "C61287"},
            {"label": "Motta", "value": "C61288"},
            {"label": "Motta di Livenza", "value": "C61289"},
            {"label": "Mozzecane", "value": "C61290"},
            {"label": "Murano", "value": "C61291"},
            {"label": "Mure", "value": "C61292"},
            {"label": "Musano", "value": "C61293"},
            {"label": "Musestre", "value": "C61294"},
            {"label": "Musile di Piave", "value": "C61295"},
            {"label": "Mussolente", "value": "C61296"},
            {"label": "Negrar", "value": "C61297"},
            {"label": "Nervesa della Battaglia", "value": "C61298"},
            {"label": "Noale", "value": "C61299"},
            {"label": "Nogara", "value": "C61300"},
            {"label": "Nogarole Rocca", "value": "C61301"},
            {"label": "Nogarole Vicentino", "value": "C61302"},
            {"label": "Nove", "value": "C61303"},
            {"label": "Noventa", "value": "C61304"},
            {"label": "Noventa di Piave", "value": "C61305"},
            {"label": "Noventa Vicentina", "value": "C61306"},
            {"label": "Novoledo", "value": "C61307"},
            {"label": "Occhiobello", "value": "C61308"},
            {"label": "Oderzo", "value": "C61309"},
            {"label": "Oltre Brenta", "value": "C61310"},
            {"label": "One", "value": "C61311"},
            {"label": "Onigo", "value": "C61312"},
            {"label": "Oppeano", "value": "C61313"},
            {"label": "Orgiano", "value": "C61314"},
            {"label": "Ormelle", "value": "C61315"},
            {"label": "Orsago", "value": "C61316"},
            {"label": "Ospedaletto", "value": "C61317"},
            {"label": "Ospedaletto Euganeo", "value": "C61318"},
            {"label": "Ospitale di Cadore", "value": "C61319"},
            {"label": "Padernello", "value": "C61320"},
            {"label": "Paderno", "value": "C61321"},
            {"label": "Paderno del Grappa", "value": "C61322"},
            {"label": "Padova", "value": "C61323"},
            {"label": "Paese", "value": "C61324"},
            {"label": "Palu", "value": "C61325"},
            {"label": "Papozze", "value": "C61326"},
            {"label": "Parona", "value": "C61327"},
            {"label": "Passarella", "value": "C61328"},
            {"label": "Pastrengo", "value": "C61329"},
            {"label": "Pedavena", "value": "C61330"},
            {"label": "Pedemonte", "value": "C61331"},
            {"label": "Pederiva", "value": "C61332"},
            {"label": "Pederobba", "value": "C61333"},
            {"label": "Pegolotte", "value": "C61334"},
            {"label": "Pellestrina", "value": "C61335"},
            {"label": "Perarolo di Cadore", "value": "C61336"},
            {"label": "Pernumia", "value": "C61337"},
            {"label": "Pero", "value": "C61338"},
            {"label": "Pescantina", "value": "C61339"},
            {"label": "Peschiera del Garda", "value": "C61340"},
            {"label": "Peseggia-Gardigiano", "value": "C61341"},
            {"label": "Pettorazza Grimani", "value": "C61342"},
            {"label": "Pezzan", "value": "C61343"},
            {"label": "Piacenza dAdige", "value": "C61344"},
            {"label": "Pianezze", "value": "C61345"},
            {"label": "Pianiga", "value": "C61346"},
            {"label": "Piavon", "value": "C61347"},
            {"label": "Piazza", "value": "C61348"},
            {"label": "Piazzola sul Brenta", "value": "C61349"},
            {"label": "Pie Falcade", "value": "C61350"},
            {"label": "Pieve", "value": "C61351"},
            {"label": "Pieve dAlpago", "value": "C61352"},
            {"label": "Pieve di Cadore", "value": "C61353"},
            {"label": "Pieve di Soligo", "value": "C61354"},
            {"label": "Pincara", "value": "C61355"},
            {"label": "Piombino Dese", "value": "C61356"},
            {"label": "Pionca", "value": "C61357"},
            {"label": "Piove di Sacco-Piovega", "value": "C61358"},
            {"label": "Piovene Rocchette", "value": "C61359"},
            {"label": "Poiana Maggiore", "value": "C61360"},
            {"label": "Poianella", "value": "C61361"},
            {"label": "Poiano", "value": "C61362"},
            {"label": "Polesella", "value": "C61363"},
            {"label": "Polverara", "value": "C61364"},
            {"label": "Ponso", "value": "C61365"},
            {"label": "Ponte di Barbarano", "value": "C61366"},
            {"label": "Ponte di Castegnero", "value": "C61367"},
            {"label": "Ponte di Nanto", "value": "C61368"},
            {"label": "Ponte di Piave", "value": "C61369"},
            {"label": "Ponte nelle Alpi", "value": "C61370"},
            {"label": "Ponte nelle Alpi-Polpet", "value": "C61371"},
            {"label": "Ponte San Nicolo", "value": "C61372"},
            {"label": "Pontecchio Polesine", "value": "C61373"},
            {"label": "Pontelongo", "value": "C61374"},
            {"label": "Ponzano", "value": "C61375"},
            {"label": "Porcellengo", "value": "C61376"},
            {"label": "Porto Tolle", "value": "C61377"},
            {"label": "Porto Viro", "value": "C61378"},
            {"label": "Portobuffole", "value": "C61379"},
            {"label": "Portogruaro", "value": "C61380"},
            {"label": "Posina", "value": "C61381"},
            {"label": "Possagno", "value": "C61382"},
            {"label": "Postioma", "value": "C61383"},
            {"label": "Pove del Grappa", "value": "C61384"},
            {"label": "Povegliano", "value": "C61385"},
            {"label": "Povegliano Veronese", "value": "C61386"},
            {"label": "Pozzoleone", "value": "C61387"},
            {"label": "Pozzonovo", "value": "C61388"},
            {"label": "Pradelle", "value": "C61389"},
            {"label": "Pramaggiore", "value": "C61390"},
            {"label": "Pramaggiore Blessaglia", "value": "C61391"},
            {"label": "Preara-Moraro-Leva Nord", "value": "C61392"},
            {"label": "Preganziol", "value": "C61393"},
            {"label": "Pressana", "value": "C61394"},
            {"label": "Priula-Colfosco", "value": "C61395"},
            {"label": "Provincia di Belluno", "value": "C61396"},
            {"label": "Provincia di Padova", "value": "C61397"},
            {"label": "Provincia di Rovigo", "value": "C61398"},
            {"label": "Provincia di Treviso", "value": "C61399"},
            {"label": "Provincia di Venezia", "value": "C61400"},
            {"label": "Provincia di Verona", "value": "C61401"},
            {"label": "Provincia di Vicenza", "value": "C61402"},
            {"label": "Puos dAlpago", "value": "C61403"},
            {"label": "Quaderni", "value": "C61404"},
            {"label": "Quarto dAltino", "value": "C61405"},
            {"label": "Quero", "value": "C61406"},
            {"label": "Quinto di Treviso", "value": "C61407"},
            {"label": "Quinto Vicentino", "value": "C61408"},
            {"label": "Raldon", "value": "C61409"},
            {"label": "Ramon", "value": "C61410"},
            {"label": "Recoaro Terme", "value": "C61411"},
            {"label": "Refrontolo", "value": "C61412"},
            {"label": "Resana", "value": "C61413"},
            {"label": "Revine", "value": "C61414"},
            {"label": "Riese Pio X", "value": "C61415"},
            {"label": "Rivalta", "value": "C61416"},
            {"label": "Rivamonte Agordino", "value": "C61417"},
            {"label": "Rivoli Veronese", "value": "C61418"},
            {"label": "Roana", "value": "C61419"},
            {"label": "Robegano", "value": "C61420"},
            {"label": "Rocca Pietore", "value": "C61421"},
            {"label": "Romano dEzzelino", "value": "C61422"},
            {"label": "Ronca", "value": "C61423"},
            {"label": "Roncade", "value": "C61424"},
            {"label": "Roncanova", "value": "C61425"},
            {"label": "Ronchi di Campanile", "value": "C61426"},
            {"label": "Ronco AllAdige", "value": "C61427"},
            {"label": "Rosa", "value": "C61428"},
            {"label": "Rosegaferro", "value": "C61429"},
            {"label": "Rosolina", "value": "C61430"},
            {"label": "Rossano Veneto", "value": "C61431"},
            {"label": "Rottanova", "value": "C61432"},
            {"label": "Rotzo", "value": "C61433"},
            {"label": "Roverchiara", "value": "C61434"},
            {"label": "Rovere Veronese", "value": "C61435"},
            {"label": "Roveredo di Gua", "value": "C61436"},
            {"label": "Rovigo", "value": "C61437"},
            {"label": "Rubano", "value": "C61438"},
            {"label": "Saccolongo", "value": "C61439"},
            {"label": "Sachet", "value": "C61440"},
            {"label": "Salara", "value": "C61441"},
            {"label": "Salcedo", "value": "C61442"},
            {"label": "Saletto", "value": "C61443"},
            {"label": "Saletto-San Bartolomeo", "value": "C61444"},
            {"label": "Salgareda", "value": "C61445"},
            {"label": "Salionze", "value": "C61446"},
            {"label": "Salizzole", "value": "C61447"},
            {"label": "Salvatronda", "value": "C61448"},
            {"label": "Salzano", "value": "C61449"},
            {"label": "Sambruson", "value": "C61450"},
            {"label": "Sambughe", "value": "C61451"},
            {"label": "San Bellino", "value": "C61452"},
            {"label": "San Biagio", "value": "C61453"},
            {"label": "San Biagio di Callalta", "value": "C61454"},
            {"label": "San Bonifacio", "value": "C61455"},
            {"label": "San Dona di Piave", "value": "C61456"},
            {"label": "San Fidenzio", "value": "C61457"},
            {"label": "San Fior di Sopra", "value": "C61458"},
            {"label": "San Floriano", "value": "C61459"},
            {"label": "San Floriano-Olmi", "value": "C61460"},
            {"label": "San Germano dei Berici", "value": "C61461"},
            {"label": "San Giorgio al Tagliamento-Pozzi", "value": "C61462"},
            {"label": "San Giorgio delle Pertiche", "value": "C61463"},
            {"label": "San Giorgio di Livenza", "value": "C61464"},
            {"label": "San Giorgio in Bosco", "value": "C61465"},
            {"label": "San Giorgio in Salici", "value": "C61466"},
            {"label": "San Giovanni Ilarione", "value": "C61467"},
            {"label": "San Giovanni Lupatoto", "value": "C61468"},
            {"label": "San Gregorio", "value": "C61469"},
            {"label": "San Gregorio nelle Alpi", "value": "C61470"},
            {"label": "San Liberale", "value": "C61471"},
            {"label": "San Martino", "value": "C61472"},
            {"label": "San Martino Buon Albergo", "value": "C61473"},
            {"label": "San Martino di Lupari", "value": "C61474"},
            {"label": "San Martino di Venezze", "value": "C61475"},
            {"label": "San Mauro di Saline", "value": "C61476"},
            {"label": "San Michele al Tagliamento", "value": "C61477"},
            {"label": "San Michele di Piave", "value": "C61478"},
            {"label": "San Nazario", "value": "C61479"},
            {"label": "San Nicolo Comelico", "value": "C61480"},
            {"label": "San Pietro", "value": "C61481"},
            {"label": "San Pietro di Cadore", "value": "C61482"},
            {"label": "San Pietro di Feletto", "value": "C61483"},
            {"label": "San Pietro di Morubio", "value": "C61484"},
            {"label": "San Pietro in Cariano", "value": "C61485"},
            {"label": "San Pietro in Gu", "value": "C61486"},
            {"label": "San Pietro in Volta", "value": "C61487"},
            {"label": "San Pietro Mussolino", "value": "C61488"},
            {"label": "San Pietro Valdastico", "value": "C61489"},
            {"label": "San Pietro Viminario", "value": "C61490"},
            {"label": "San Polo di Piave", "value": "C61491"},
            {"label": "San Quirico", "value": "C61492"},
            {"label": "San Stino di Livenza", "value": "C61493"},
            {"label": "San Vendemiano", "value": "C61494"},
            {"label": "San Vendemiano-Fossamerlo", "value": "C61495"},
            {"label": "San Vito", "value": "C61496"},
            {"label": "San Vito al Mantico", "value": "C61497"},
            {"label": "San Vito di Leguzzano", "value": "C61498"},
            {"label": "San Zeno", "value": "C61499"},
            {"label": "San Zeno di Montagna", "value": "C61500"},
            {"label": "San Zeno-San Giuseppe", "value": "C61501"},
            {"label": "San Zenone degli Ezzelini", "value": "C61502"},
            {"label": "Sandra", "value": "C61503"},
            {"label": "Sandrigo", "value": "C61504"},
            {"label": "Sanguinetto", "value": "C61505"},
            {"label": "SantAlberto", "value": "C61506"},
            {"label": "SantAmbrogio di Valpollicella", "value": "C61507"},
            {"label": "SantAndrea", "value": "C61508"},
            {"label": "SantAngelo", "value": "C61509"},
            {"label": "SantAngelo di Piove di Sacco", "value": "C61510"},
            {"label": "SantAnna", "value": "C61511"},
            {"label": "SantAnna dAlfaedo", "value": "C61512"},
            {"label": "SantApollinare", "value": "C61513"},
            {"label": "SantElena", "value": "C61514"},
            {"label": "Santa Cristina", "value": "C61515"},
            {"label": "Santa Giustina", "value": "C61516"},
            {"label": "Santa Giustina in Colle", "value": "C61517"},
            {"label": "Santa Lucia di Piave", "value": "C61518"},
            {"label": "Santa Margherita dAdige", "value": "C61519"},
            {"label": "Santa Maria", "value": "C61520"},
            {"label": "Santa Maria di Non", "value": "C61521"},
            {"label": "Santa Maria di Sala", "value": "C61522"},
            {"label": "Santa Maria Maddalena", "value": "C61523"},
            {"label": "Santandra", "value": "C61524"},
            {"label": "Santo Stefano di Cadore", "value": "C61525"},
            {"label": "Santo Stefano-Bonaldo", "value": "C61526"},
            {"label": "Santorso", "value": "C61527"},
            {"label": "Saonara", "value": "C61528"},
            {"label": "Sarcedo", "value": "C61529"},
            {"label": "Sarego", "value": "C61530"},
            {"label": "Sarmede", "value": "C61531"},
            {"label": "Sarmeola", "value": "C61532"},
            {"label": "Scaltenigo", "value": "C61533"},
            {"label": "Scardovari", "value": "C61534"},
            {"label": "Schiavon", "value": "C61535"},
            {"label": "Schio", "value": "C61536"},
            {"label": "Scomigo", "value": "C61537"},
            {"label": "Scorze", "value": "C61538"},
            {"label": "Sedico", "value": "C61539"},
            {"label": "Segusino", "value": "C61540"},
            {"label": "Selva del Montello", "value": "C61541"},
            {"label": "Selva di Cadore", "value": "C61542"},
            {"label": "Selva di Progno", "value": "C61543"},
            {"label": "Selvazzano Dentro", "value": "C61544"},
            {"label": "Seren del Grappa", "value": "C61545"},
            {"label": "Sernaglia della Battaglia", "value": "C61546"},
            {"label": "Servo", "value": "C61547"},
            {"label": "Settimo", "value": "C61548"},
            {"label": "Silea", "value": "C61549"},
            {"label": "Soave", "value": "C61550"},
            {"label": "Soccher-Paiane-Casan-Arsie", "value": "C61551"},
            {"label": "Solagna", "value": "C61552"},
            {"label": "Solesino", "value": "C61553"},
            {"label": "Sommacampagna", "value": "C61554"},
            {"label": "Sona", "value": "C61555"},
            {"label": "Sorga", "value": "C61556"},
            {"label": "Sospirolo", "value": "C61557"},
            {"label": "Sossano", "value": "C61558"},
            {"label": "Soverzene", "value": "C61559"},
            {"label": "Sovizzo", "value": "C61560"},
            {"label": "Sovramonte", "value": "C61561"},
            {"label": "Spinea-Orgnano", "value": "C61562"},
            {"label": "Spinimbecco", "value": "C61563"},
            {"label": "Spresiano", "value": "C61564"},
            {"label": "Stallavena-Lugo", "value": "C61565"},
            {"label": "Stanghella", "value": "C61566"},
            {"label": "Stienta", "value": "C61567"},
            {"label": "Stigliano", "value": "C61568"},
            {"label": "Stra", "value": "C61569"},
            {"label": "Stra-Montanara-Pieve", "value": "C61570"},
            {"label": "Summaga", "value": "C61571"},
            {"label": "Susegana", "value": "C61572"},
            {"label": "Taggi", "value": "C61573"},
            {"label": "Taglio", "value": "C61574"},
            {"label": "Taglio di Po", "value": "C61575"},
            {"label": "Taibon Agordino", "value": "C61576"},
            {"label": "Talponada", "value": "C61577"},
            {"label": "Tambre", "value": "C61578"},
            {"label": "Tarzo", "value": "C61579"},
            {"label": "Tavernelle", "value": "C61580"},
            {"label": "Tavo", "value": "C61581"},
            {"label": "Teglio Veneto", "value": "C61582"},
            {"label": "Tencarola", "value": "C61583"},
            {"label": "Terradura", "value": "C61584"},
            {"label": "Terrassa Padovana", "value": "C61585"},
            {"label": "Terrazzo", "value": "C61586"},
            {"label": "Terrossa", "value": "C61587"},
            {"label": "Tessera", "value": "C61588"},
            {"label": "Tezze", "value": "C61589"},
            {"label": "Thiene", "value": "C61590"},
            {"label": "Tombelle", "value": "C61591"},
            {"label": "Tombolo", "value": "C61592"},
            {"label": "Tonezza del Cimone", "value": "C61593"},
            {"label": "Torre di Mosto", "value": "C61594"},
            {"label": "Torrebelvicino", "value": "C61595"},
            {"label": "Torreglia", "value": "C61596"},
            {"label": "Torreselle", "value": "C61597"},
            {"label": "Torri del Benaco", "value": "C61598"},
            {"label": "Torri di Quartesolo", "value": "C61599"},
            {"label": "Trebaseleghe", "value": "C61600"},
            {"label": "Trecenta", "value": "C61601"},
            {"label": "Tregnago", "value": "C61602"},
            {"label": "Tremignon", "value": "C61603"},
            {"label": "Treponti", "value": "C61604"},
            {"label": "Trevenzuolo", "value": "C61605"},
            {"label": "Trevignano", "value": "C61606"},
            {"label": "Treviso", "value": "C61607"},
            {"label": "Tribano", "value": "C61608"},
            {"label": "Trichiana", "value": "C61609"},
            {"label": "Trissino", "value": "C61610"},
            {"label": "Trivignano", "value": "C61611"},
            {"label": "Urbana", "value": "C61612"},
            {"label": "Vago", "value": "C61613"},
            {"label": "Valdagno", "value": "C61614"},
            {"label": "Valdobbiadene", "value": "C61615"},
            {"label": "Valeggio sul Mincio", "value": "C61616"},
            {"label": "Valgatara", "value": "C61617"},
            {"label": "Valla", "value": "C61618"},
            {"label": "Vallada Agordina", "value": "C61619"},
            {"label": "Valle di Cadore", "value": "C61620"},
            {"label": "Vallese", "value": "C61621"},
            {"label": "Valli", "value": "C61622"},
            {"label": "Valli del Pasubio", "value": "C61623"},
            {"label": "Vallonga", "value": "C61624"},
            {"label": "Valstagna", "value": "C61625"},
            {"label": "Varago", "value": "C61626"},
            {"label": "Vas", "value": "C61627"},
            {"label": "Vazzola", "value": "C61628"},
            {"label": "Vedelago", "value": "C61629"},
            {"label": "Veggiano", "value": "C61630"},
            {"label": "Velo", "value": "C61631"},
            {"label": "Velo dAstico", "value": "C61632"},
            {"label": "Velo Veronese", "value": "C61633"},
            {"label": "Venegazzu", "value": "C61634"},
            {"label": "Venice", "value": "C61635"},
            {"label": "Verona", "value": "C61636"},
            {"label": "Veronella", "value": "C61637"},
            {"label": "Vescovana", "value": "C61638"},
            {"label": "Vestenanova", "value": "C61639"},
            {"label": "Veternigo", "value": "C61640"},
            {"label": "Vicenza", "value": "C61641"},
            {"label": "Vidor", "value": "C61642"},
            {"label": "Vigardolo", "value": "C61643"},
            {"label": "Vigasio", "value": "C61644"},
            {"label": "Vighizzolo dEste", "value": "C61645"},
            {"label": "Vigo di Cadore", "value": "C61646"},
            {"label": "Vigodarzere", "value": "C61647"},
            {"label": "Vigonovo", "value": "C61648"},
            {"label": "Vigonza", "value": "C61649"},
            {"label": "Villa Bartolomea", "value": "C61650"},
            {"label": "Villa dAsolo", "value": "C61651"},
            {"label": "Villa del Conte", "value": "C61652"},
            {"label": "Villa Estense", "value": "C61653"},
            {"label": "Villabruna-Umin", "value": "C61654"},
            {"label": "Villadose", "value": "C61655"},
            {"label": "Villafranca di Verona", "value": "C61656"},
            {"label": "Villafranca Padovana", "value": "C61657"},
            {"label": "Villaga", "value": "C61658"},
            {"label": "Villaganzerla", "value": "C61659"},
            {"label": "Villaggio Montegrappa", "value": "C61660"},
            {"label": "Villaguattera", "value": "C61661"},
            {"label": "Villamarzana", "value": "C61662"},
            {"label": "Villanova", "value": "C61663"},
            {"label": "Villanova del Ghebbo Canton", "value": "C61664"},
            {"label": "Villanova Marchesana", "value": "C61665"},
            {"label": "Villatora", "value": "C61666"},
            {"label": "Villaverla", "value": "C61667"},
            {"label": "Vittorio Veneto", "value": "C61668"},
            {"label": "Vo", "value": "C61669"},
            {"label": "Vodo", "value": "C61670"},
            {"label": "Volargne", "value": "C61671"},
            {"label": "Volpago del Montello", "value": "C61672"},
            {"label": "Voltago", "value": "C61673"},
            {"label": "Voltago Agordino", "value": "C61674"},
            {"label": "Zane", "value": "C61675"},
            {"label": "Zelarino", "value": "C61676"},
            {"label": "Zenson di Piave", "value": "C61677"},
            {"label": "Zermeghedo", "value": "C61678"},
            {"label": "Zero Branco", "value": "C61679"},
            {"label": "Zevio", "value": "C61680"},
            {"label": "Zoppe di Cadore", "value": "C61681"},
            {"label": "Zovencedo", "value": "C61682"},
            {"label": "Zugliano", "value": "C61683"}],
 "Ventspils": [{"label": "Ventspils", "value": "C64958"}],
 "Ventspils Municipality": [{"label": "Piltene", "value": "C64959"}],
 "Veracruz de Ignacio de la Llave": [{"label": "Abasolo del Valle",
                                      "value": "C74264"},
                                     {"label": "Abrevadero", "value": "C74265"},
                                     {"label": "Acajete", "value": "C74266"},
                                     {"label": "Acatla", "value": "C74267"},
                                     {"label": "Acatlan", "value": "C74268"},
                                     {"label": "Acayucan", "value": "C74269"},
                                     {"label": "Acazonica", "value": "C74270"},
                                     {"label": "Achotal de Moreno",
                                      "value": "C74271"},
                                     {"label": "Acontitla", "value": "C74272"},
                                     {"label": "Actopan", "value": "C74273"},
                                     {"label": "Acula", "value": "C74274"},
                                     {"label": "Acultzingo", "value": "C74275"},
                                     {"label": "Adolfo Moreno",
                                      "value": "C74276"},
                                     {"label": "Adolfo Ruiz Cortines",
                                      "value": "C74277"},
                                     {"label": "Agricola Lazaro Cardenas",
                                      "value": "C74278"},
                                     {"label": "Agua Dulce", "value": "C74279"},
                                     {"label": "Aguilera", "value": "C74280"},
                                     {"label": "Ahuacatan", "value": "C74281"},
                                     {"label": "Ahuateno", "value": "C74282"},
                                     {"label": "Ahueyahualco",
                                      "value": "C74283"},
                                     {"label": "alamo", "value": "C74284"},
                                     {"label": "alamo Temapache",
                                      "value": "C74285"},
                                     {"label": "Alborada", "value": "C74286"},
                                     {"label": "Allende", "value": "C74287"},
                                     {"label": "Almagres", "value": "C74288"},
                                     {"label": "Alpatlahuac",
                                      "value": "C74289"},
                                     {"label": "Alto Lucero",
                                      "value": "C74290"},
                                     {"label": "Altotonga", "value": "C74291"},
                                     {"label": "Alvarado", "value": "C74292"},
                                     {"label": "Amatitlan", "value": "C74293"},
                                     {"label": "Amatlan", "value": "C74294"},
                                     {"label": "Amatlan de los Reyes",
                                      "value": "C74295"},
                                     {"label": "Anahuac", "value": "C74296"},
                                     {"label": "Angel R. Cabada",
                                      "value": "C74297"},
                                     {"label": "Anton Lizardo",
                                      "value": "C74298"},
                                     {"label": "Antonio J Bermudez",
                                      "value": "C74299"},
                                     {"label": "Apazapan", "value": "C74300"},
                                     {"label": "Aquila", "value": "C74301"},
                                     {"label": "Aquiles Serdan",
                                      "value": "C74302"},
                                     {"label": "Arboledas", "value": "C74303"},
                                     {"label": "Arbolillo", "value": "C74304"},
                                     {"label": "Arenal Santa Ana",
                                      "value": "C74305"},
                                     {"label": "Arroyo del Maiz Uno",
                                      "value": "C74306"},
                                     {"label": "Arroyo del Potrero",
                                      "value": "C74307"},
                                     {"label": "Arroyo Hondo",
                                      "value": "C74308"},
                                     {"label": "Arroyo San Isidro",
                                      "value": "C74309"},
                                     {"label": "Astacinga", "value": "C74310"},
                                     {"label": "Atlahuilco", "value": "C74311"},
                                     {"label": "Atoyac", "value": "C74312"},
                                     {"label": "Atzacan", "value": "C74313"},
                                     {"label": "Atzalan", "value": "C74314"},
                                     {"label": "Axochio", "value": "C74315"},
                                     {"label": "Ayahualulco",
                                      "value": "C74316"},
                                     {"label": "Ayotuxtla", "value": "C74317"},
                                     {"label": "Banderas", "value": "C74318"},
                                     {"label": "Banderilla", "value": "C74319"},
                                     {"label": "Bara de Chachalacas",
                                      "value": "C74320"},
                                     {"label": "Barra de Cazones",
                                      "value": "C74321"},
                                     {"label": "Barrancas", "value": "C74322"},
                                     {"label": "Barrio de San Miguel",
                                      "value": "C74323"},
                                     {"label": "Bella Esperanza",
                                      "value": "C74324"},
                                     {"label": "Benito Juarez",
                                      "value": "C74325"},
                                     {"label": "Blanca Espuma",
                                      "value": "C74326"},
                                     {"label": "Boca del Monte",
                                      "value": "C74327"},
                                     {"label": "Boca del Rio",
                                      "value": "C74328"},
                                     {"label": "Buena Vista",
                                      "value": "C74329"},
                                     {"label": "Buenavista", "value": "C74330"},
                                     {"label": "Buenos Aires",
                                      "value": "C74331"},
                                     {"label": "Buenos Aires (San Isidro)",
                                      "value": "C74332"},
                                     {"label": "Cabezas", "value": "C74333"},
                                     {"label": "Cacahuatal", "value": "C74334"},
                                     {"label": "Calcahualco",
                                      "value": "C74335"},
                                     {"label": "Caleria", "value": "C74336"},
                                     {"label": "Calichar Palma Sola",
                                      "value": "C74337"},
                                     {"label": "Calzadas", "value": "C74338"},
                                     {"label": "Camaron de Tejeda",
                                      "value": "C74339"},
                                     {"label": "Camerino Z. Mendoza",
                                      "value": "C74340"},
                                     {"label": "Campo Chico",
                                      "value": "C74341"},
                                     {"label": "Campo de aguila",
                                      "value": "C74342"},
                                     {"label": "Campo Grande",
                                      "value": "C74343"},
                                     {"label": "Capoluca", "value": "C74344"},
                                     {"label": "Caristay", "value": "C74345"},
                                     {"label": "Carlos A. Carrillo",
                                      "value": "C74346"},
                                     {"label": "Carrillo Puerto",
                                      "value": "C74347"},
                                     {"label": "Carrizal", "value": "C74348"},
                                     {"label": "Casitas", "value": "C74349"},
                                     {"label": "Castillo de Teayo",
                                      "value": "C74350"},
                                     {"label": "Catemaco", "value": "C74351"},
                                     {"label": "Caxapa", "value": "C74352"},
                                     {"label": "Cazones de Herrera",
                                      "value": "C74353"},
                                     {"label": "Cecilio Teran",
                                      "value": "C74354"},
                                     {"label": "Cementeras del Pital",
                                      "value": "C74355"},
                                     {"label": "Centro de Readaptacion Social",
                                      "value": "C74356"},
                                     {"label": "Cerritos", "value": "C74357"},
                                     {"label": "Cerro Azul", "value": "C74358"},
                                     {"label": "Cerro Gordo",
                                      "value": "C74359"},
                                     {"label": "Cerro Grande",
                                      "value": "C74360"},
                                     {"label": "Cerro Guzman",
                                      "value": "C74361"},
                                     {"label": "Cerro las Iguanas",
                                      "value": "C74362"},
                                     {"label": "Chacalapa", "value": "C74363"},
                                     {"label": "Chacaltianguis",
                                      "value": "C74364"},
                                     {"label": "Chalma", "value": "C74365"},
                                     {"label": "Chapopote Chico",
                                      "value": "C74366"},
                                     {"label": "Chapopote Nunez",
                                      "value": "C74367"},
                                     {"label": "Chavarrillo",
                                      "value": "C74368"},
                                     {"label": "Chavaxtla", "value": "C74369"},
                                     {"label": "Chichicaxtle",
                                      "value": "C74370"},
                                     {"label": "Chicola", "value": "C74371"},
                                     {"label": "Chiconamel", "value": "C74372"},
                                     {"label": "Chiconquiaco",
                                      "value": "C74373"},
                                     {"label": "Chicontepec",
                                      "value": "C74374"},
                                     {"label": "Chicualoque",
                                      "value": "C74375"},
                                     {"label": "Chicuasen", "value": "C74376"},
                                     {"label": "Chilapa", "value": "C74377"},
                                     {"label": "Chiltoyac", "value": "C74378"},
                                     {"label": "Chinameca", "value": "C74379"},
                                     {"label": "Chinampa de Gorostiza",
                                      "value": "C74380"},
                                     {"label": "Chocaman", "value": "C74381"},
                                     {"label": "Chonegal", "value": "C74382"},
                                     {"label": "Chontla", "value": "C74383"},
                                     {"label": "Chumatlan", "value": "C74384"},
                                     {"label": "Chuniapan de Arriba",
                                      "value": "C74385"},
                                     {"label": "Citlaltepec",
                                      "value": "C74386"},
                                     {"label": "Citlaltepetl",
                                      "value": "C74387"},
                                     {"label": "Ciudad Cuauhtemoc",
                                      "value": "C74388"},
                                     {"label": "Ciudad Mendoza",
                                      "value": "C74389"},
                                     {"label": "Coacoatzintla",
                                      "value": "C74390"},
                                     {"label": "Coacotla", "value": "C74391"},
                                     {"label": "Coahuitlan", "value": "C74392"},
                                     {"label": "Coatepec", "value": "C74393"},
                                     {"label": "Coatzacoalcos",
                                      "value": "C74394"},
                                     {"label": "Coatzintla", "value": "C74395"},
                                     {"label": "Coetzala", "value": "C74396"},
                                     {"label": "Colatlan", "value": "C74397"},
                                     {"label": "Colipa", "value": "C74398"},
                                     {"label": "Colonia Adolfo Ruiz Cortines (Colonia Obrera)",
                                      "value": "C74399"},
                                     {"label": "Colonia Chalchihuecan",
                                      "value": "C74400"},
                                     {"label": "Colonia el Renacimiento",
                                      "value": "C74401"},
                                     {"label": "Colonia las Flores",
                                      "value": "C74402"},
                                     {"label": "Colonia Lealtad",
                                      "value": "C74403"},
                                     {"label": "Colonia Libertad",
                                      "value": "C74404"},
                                     {"label": "Colonia Manuel Gonzalez",
                                      "value": "C74405"},
                                     {"label": "Colonia Santa Barbara",
                                      "value": "C74406"},
                                     {"label": "Colonia Ursulo Galvan",
                                      "value": "C74407"},
                                     {"label": "Colonias Pedernales",
                                      "value": "C74408"},
                                     {"label": "Colorines", "value": "C74409"},
                                     {"label": "Comapa", "value": "C74410"},
                                     {"label": "Comejen", "value": "C74411"},
                                     {"label": "Comoapan", "value": "C74412"},
                                     {"label": "Congregacion el Tajin",
                                      "value": "C74413"},
                                     {"label": "Congregacion Hidalgo",
                                      "value": "C74414"},
                                     {"label": "Cordoba", "value": "C74415"},
                                     {"label": "Cordoba (Santa Leticia)",
                                      "value": "C74416"},
                                     {"label": "Corozal", "value": "C74417"},
                                     {"label": "Corral Nuevo",
                                      "value": "C74418"},
                                     {"label": "Corralillos",
                                      "value": "C74419"},
                                     {"label": "Cosamaloapan",
                                      "value": "C74420"},
                                     {"label": "Cosamaloapan de Carpio",
                                      "value": "C74421"},
                                     {"label": "Cosautlan", "value": "C74422"},
                                     {"label": "Cosautlan de Carvajal",
                                      "value": "C74423"},
                                     {"label": "Coscomatepec de Bravo",
                                      "value": "C74424"},
                                     {"label": "Cosolapa", "value": "C74425"},
                                     {"label": "Cosoleacaque",
                                      "value": "C74426"},
                                     {"label": "Cotaxtla", "value": "C74427"},
                                     {"label": "Coxquihui", "value": "C74428"},
                                     {"label": "Coyolito", "value": "C74429"},
                                     {"label": "Coyutla", "value": "C74430"},
                                     {"label": "Cruz Blanca",
                                      "value": "C74431"},
                                     {"label": "Cruz del Milagro",
                                      "value": "C74432"},
                                     {"label": "Cruz Verde", "value": "C74433"},
                                     {"label": "Cuauhtamingo",
                                      "value": "C74434"},
                                     {"label": "Cuautlapan", "value": "C74435"},
                                     {"label": "Cucharas", "value": "C74436"},
                                     {"label": "Cuesta Amarilla",
                                      "value": "C74437"},
                                     {"label": "Cuesta de Laja",
                                      "value": "C74438"},
                                     {"label": "Cuichapa", "value": "C74439"},
                                     {"label": "Cuitlahuac", "value": "C74440"},
                                     {"label": "Cuiyachapa", "value": "C74441"},
                                     {"label": "Dehesa", "value": "C74442"},
                                     {"label": "Delfino Victoria (Santa Fe)",
                                      "value": "C74443"},
                                     {"label": "Doctor Montes de Oca",
                                      "value": "C74444"},
                                     {"label": "Dos Bocas", "value": "C74445"},
                                     {"label": "Dos Rios", "value": "C74446"},
                                     {"label": "Ejidal", "value": "C74447"},
                                     {"label": "Ejido la Pina (Maromilla)",
                                      "value": "C74448"},
                                     {"label": "El Aguacate",
                                      "value": "C74449"},
                                     {"label": "El aguila", "value": "C74450"},
                                     {"label": "El Anono", "value": "C74451"},
                                     {"label": "El Arenal", "value": "C74452"},
                                     {"label": "El Blanco", "value": "C74453"},
                                     {"label": "El Castillo",
                                      "value": "C74454"},
                                     {"label": "El Chico", "value": "C74455"},
                                     {"label": "El Chote", "value": "C74456"},
                                     {"label": "El Cocuite", "value": "C74457"},
                                     {"label": "El Conejo", "value": "C74458"},
                                     {"label": "El Corte", "value": "C74459"},
                                     {"label": "El Escobillo",
                                      "value": "C74460"},
                                     {"label": "El Espinal", "value": "C74461"},
                                     {"label": "El Hatito", "value": "C74462"},
                                     {"label": "El Higo", "value": "C74463"},
                                     {"label": "El Huerfano",
                                      "value": "C74464"},
                                     {"label": "El Huidero", "value": "C74465"},
                                     {"label": "El Humo", "value": "C74466"},
                                     {"label": "El Jobo", "value": "C74467"},
                                     {"label": "El Juile", "value": "C74468"},
                                     {"label": "El Laurel", "value": "C74469"},
                                     {"label": "El Lencero", "value": "C74470"},
                                     {"label": "El Lindero", "value": "C74471"},
                                     {"label": "El Maguey", "value": "C74472"},
                                     {"label": "El Mirador", "value": "C74473"},
                                     {"label": "El Molino", "value": "C74474"},
                                     {"label": "El Mollejon",
                                      "value": "C74475"},
                                     {"label": "El Naranjito",
                                      "value": "C74476"},
                                     {"label": "El Nigromante",
                                      "value": "C74477"},
                                     {"label": "El Palmar", "value": "C74478"},
                                     {"label": "El Panorama",
                                      "value": "C74479"},
                                     {"label": "El Paraiso", "value": "C74480"},
                                     {"label": "El Paraiso (La Charca)",
                                      "value": "C74481"},
                                     {"label": "El Pital", "value": "C74482"},
                                     {"label": "El Porvenir",
                                      "value": "C74483"},
                                     {"label": "El Potrero", "value": "C74484"},
                                     {"label": "El Progreso",
                                      "value": "C74485"},
                                     {"label": "El Pueblito",
                                      "value": "C74486"},
                                     {"label": "El Pueblito (Garbanzal)",
                                      "value": "C74487"},
                                     {"label": "El Remolino",
                                      "value": "C74488"},
                                     {"label": "El Salto de Eyipantla",
                                      "value": "C74489"},
                                     {"label": "El Tejar", "value": "C74490"},
                                     {"label": "El Terrero", "value": "C74491"},
                                     {"label": "El Triunfo", "value": "C74492"},
                                     {"label": "El Tulin", "value": "C74493"},
                                     {"label": "El Volador", "value": "C74494"},
                                     {"label": "El Zapote Bravo",
                                      "value": "C74495"},
                                     {"label": "Elotepec", "value": "C74496"},
                                     {"label": "Emiliano Zapata",
                                      "value": "C74497"},
                                     {"label": "Entabladero",
                                      "value": "C74498"},
                                     {"label": "Escolin de Olarte",
                                      "value": "C74499"},
                                     {"label": "Espinal", "value": "C74500"},
                                     {"label": "Estacion Dobladero",
                                      "value": "C74501"},
                                     {"label": "Estacion Juanita",
                                      "value": "C74502"},
                                     {"label": "Estanzuela", "value": "C74503"},
                                     {"label": "Estero de Milpas",
                                      "value": "C74504"},
                                     {"label": "Estero del idolo",
                                      "value": "C74505"},
                                     {"label": "Ex-hacienda la Concepcion",
                                      "value": "C74506"},
                                     {"label": "Excola", "value": "C74507"},
                                     {"label": "Felipe Carrillo Puerto",
                                      "value": "C74508"},
                                     {"label": "Filomeno Mata",
                                      "value": "C74509"},
                                     {"label": "Fortin de las Flores",
                                      "value": "C74510"},
                                     {"label": "Fraccionamiento Arboledas San Ramon",
                                      "value": "C74511"},
                                     {"label": "Fraccionamiento Ciudad Olmeca",
                                      "value": "C74512"},
                                     {"label": "Fraccionamiento Costa Dorada",
                                      "value": "C74513"},
                                     {"label": "Fraccionamiento Geovillas los Pinos",
                                      "value": "C74514"},
                                     {"label": "Fraccionamiento la Florida",
                                      "value": "C74515"},
                                     {"label": "Fraccionamiento las Fuentes",
                                      "value": "C74516"},
                                     {"label": "Fraccionamiento los alamos",
                                      "value": "C74517"},
                                     {"label": "Fraccionamiento los Prados",
                                      "value": "C74518"},
                                     {"label": "Fraccionamiento Santa Cruz",
                                      "value": "C74519"},
                                     {"label": "Fraccionamiento Valle Dorado",
                                      "value": "C74520"},
                                     {"label": "Fraccionamiento Villas de la Llave",
                                      "value": "C74521"},
                                     {"label": "Francisco I. Madero",
                                      "value": "C74522"},
                                     {"label": "Fredepo", "value": "C74523"},
                                     {"label": "Gabino Barreda",
                                      "value": "C74524"},
                                     {"label": "General Alatriste (San Joaquin)",
                                      "value": "C74525"},
                                     {"label": "General Juan Jose Baz (San Jose del Corral)",
                                      "value": "C74526"},
                                     {"label": "General Miguel Aleman",
                                      "value": "C74527"},
                                     {"label": "Guadalupe", "value": "C74528"},
                                     {"label": "Guadalupe Victoria",
                                      "value": "C74529"},
                                     {"label": "Guayalejo", "value": "C74530"},
                                     {"label": "Guillermo Prieto",
                                      "value": "C74531"},
                                     {"label": "Gutierrez Zamora",
                                      "value": "C74532"},
                                     {"label": "Hacienda Sotavento",
                                      "value": "C74533"},
                                     {"label": "Helio Garcia Alfaro",
                                      "value": "C74534"},
                                     {"label": "Hermanos Cedillo",
                                      "value": "C74535"},
                                     {"label": "Hermenegildo J. Aldana",
                                      "value": "C74536"},
                                     {"label": "Hidalgo Amajac",
                                      "value": "C74537"},
                                     {"label": "Hidalgotitlan",
                                      "value": "C74538"},
                                     {"label": "Hornitos", "value": "C74539"},
                                     {"label": "Huatusco", "value": "C74540"},
                                     {"label": "Huayacanes", "value": "C74541"},
                                     {"label": "Huayacocotla",
                                      "value": "C74542"},
                                     {"label": "Huazuntlan", "value": "C74543"},
                                     {"label": "Huexotitla", "value": "C74544"},
                                     {"label": "Hueyapan de Ocampo",
                                      "value": "C74545"},
                                     {"label": "Hueytepec", "value": "C74546"},
                                     {"label": "Huiloapan", "value": "C74547"},
                                     {"label": "Huiloapan de Cuauhtemoc",
                                      "value": "C74548"},
                                     {"label": "Huitzila", "value": "C74549"},
                                     {"label": "Huixcolotla",
                                      "value": "C74550"},
                                     {"label": "Ignacio de la Llave",
                                      "value": "C74551"},
                                     {"label": "Ignacio Zaragoza",
                                      "value": "C74552"},
                                     {"label": "Ilamatlan", "value": "C74553"},
                                     {"label": "Isla", "value": "C74554"},
                                     {"label": "Ixcapantla", "value": "C74555"},
                                     {"label": "Ixcatepec", "value": "C74556"},
                                     {"label": "Ixcatla", "value": "C74557"},
                                     {"label": "Ixhuacan de los Reyes",
                                      "value": "C74558"},
                                     {"label": "Ixhuapan", "value": "C74559"},
                                     {"label": "Ixhuatlan de Madero",
                                      "value": "C74560"},
                                     {"label": "Ixhuatlan del Cafe",
                                      "value": "C74561"},
                                     {"label": "Ixhuatlan del Sureste",
                                      "value": "C74562"},
                                     {"label": "Ixhuatlancillo",
                                      "value": "C74563"},
                                     {"label": "Ixpila", "value": "C74564"},
                                     {"label": "Ixtacapa el Chico",
                                      "value": "C74565"},
                                     {"label": "Ixtaczoquitlan",
                                      "value": "C74566"},
                                     {"label": "Jacarandas", "value": "C74567"},
                                     {"label": "Jalacingo", "value": "C74568"},
                                     {"label": "Jalapilla", "value": "C74569"},
                                     {"label": "Jalcomulco", "value": "C74570"},
                                     {"label": "Jaltipan de Morelos",
                                      "value": "C74571"},
                                     {"label": "Jamapa", "value": "C74572"},
                                     {"label": "Jesus Carranza",
                                      "value": "C74573"},
                                     {"label": "Jilotepec", "value": "C74574"},
                                     {"label": "Joachin", "value": "C74575"},
                                     {"label": "Jose Cardel",
                                      "value": "C74576"},
                                     {"label": "Jose F. Gutierrez",
                                      "value": "C74577"},
                                     {"label": "Juan Diaz Covarrubias",
                                      "value": "C74578"},
                                     {"label": "Juan Jacobo Torres [Bodega de Totontepec]",
                                      "value": "C74579"},
                                     {"label": "Juan Marcos (San Jose Buenavista)",
                                      "value": "C74580"},
                                     {"label": "Juan Rodriguez Clara",
                                      "value": "C74581"},
                                     {"label": "Juchique de Ferrer",
                                      "value": "C74582"},
                                     {"label": "La Antigua", "value": "C74583"},
                                     {"label": "La Camelia", "value": "C74584"},
                                     {"label": "La Capilla", "value": "C74585"},
                                     {"label": "La Cerquilla",
                                      "value": "C74586"},
                                     {"label": "La Colonia Guadalupe",
                                      "value": "C74587"},
                                     {"label": "La Concepcion",
                                      "value": "C74588"},
                                     {"label": "La Cuesta", "value": "C74589"},
                                     {"label": "La Defensa", "value": "C74590"},
                                     {"label": "La Gloria", "value": "C74591"},
                                     {"label": "La Guadalupe",
                                      "value": "C74592"},
                                     {"label": "La Guasima", "value": "C74593"},
                                     {"label": "La Horqueta (Poblado Doce)",
                                      "value": "C74594"},
                                     {"label": "La Isla", "value": "C74595"},
                                     {"label": "La Isla (Kilometro 10)",
                                      "value": "C74596"},
                                     {"label": "La Isla de Chapachapa",
                                      "value": "C74597"},
                                     {"label": "La Isla Km 10",
                                      "value": "C74598"},
                                     {"label": "La Joya", "value": "C74599"},
                                     {"label": "La Junta", "value": "C74600"},
                                     {"label": "La Laguna", "value": "C74601"},
                                     {"label": "La Laguna y Monte del Castillo",
                                      "value": "C74602"},
                                     {"label": "La Libertad",
                                      "value": "C74603"},
                                     {"label": "La Nueva Era",
                                      "value": "C74604"},
                                     {"label": "La Nueva Victoria",
                                      "value": "C74605"},
                                     {"label": "La Orduna", "value": "C74606"},
                                     {"label": "La Palma", "value": "C74607"},
                                     {"label": "La Palmilla",
                                      "value": "C74608"},
                                     {"label": "La Perla", "value": "C74609"},
                                     {"label": "La Providencia",
                                      "value": "C74610"},
                                     {"label": "La Reforma", "value": "C74611"},
                                     {"label": "La Sidra", "value": "C74612"},
                                     {"label": "La Sombra", "value": "C74613"},
                                     {"label": "La Tinaja", "value": "C74614"},
                                     {"label": "La Toma", "value": "C74615"},
                                     {"label": "La Union", "value": "C74616"},
                                     {"label": "La Union Paso Largo",
                                      "value": "C74617"},
                                     {"label": "La Victoria",
                                      "value": "C74618"},
                                     {"label": "La Victoria (La Penita)",
                                      "value": "C74619"},
                                     {"label": "Laguna Chica (Pueblo Nuevo)",
                                      "value": "C74620"},
                                     {"label": "Laguna de Farfan",
                                      "value": "C74621"},
                                     {"label": "Laguneta", "value": "C74622"},
                                     {"label": "Landero y Coss",
                                      "value": "C74623"},
                                     {"label": "Las Amapolas",
                                      "value": "C74624"},
                                     {"label": "Las Barrillas",
                                      "value": "C74625"},
                                     {"label": "Las Choapas",
                                      "value": "C74626"},
                                     {"label": "Las Higueras",
                                      "value": "C74627"},
                                     {"label": "Las Lomas", "value": "C74628"},
                                     {"label": "Las Lomas de Tacamichapan",
                                      "value": "C74629"},
                                     {"label": "Las Minas", "value": "C74630"},
                                     {"label": "Las Sabanetas",
                                      "value": "C74631"},
                                     {"label": "Las Sabinas",
                                      "value": "C74632"},
                                     {"label": "Las Trancas",
                                      "value": "C74633"},
                                     {"label": "Las Vigas de Ramirez",
                                      "value": "C74634"},
                                     {"label": "Lazaro Cardenas (Santana)",
                                      "value": "C74635"},
                                     {"label": "Lerdo de Tejada",
                                      "value": "C74636"},
                                     {"label": "Limones", "value": "C74637"},
                                     {"label": "Lindavista", "value": "C74638"},
                                     {"label": "Llano de Enmedio",
                                      "value": "C74639"},
                                     {"label": "Llano Grande",
                                      "value": "C74640"},
                                     {"label": "Loma Angosta",
                                      "value": "C74641"},
                                     {"label": "Loma de los Carmona",
                                      "value": "C74642"},
                                     {"label": "Loma de Sogotegoyo",
                                      "value": "C74643"},
                                     {"label": "Loma Grande",
                                      "value": "C74644"},
                                     {"label": "Lomas de Barrillas",
                                      "value": "C74645"},
                                     {"label": "Lomas de Rio Medio Cuatro",
                                      "value": "C74646"},
                                     {"label": "Lomas Verdes",
                                      "value": "C74647"},
                                     {"label": "Los Altos", "value": "C74648"},
                                     {"label": "Los Cerritos",
                                      "value": "C74649"},
                                     {"label": "Los idolos", "value": "C74650"},
                                     {"label": "Los Lirios", "value": "C74651"},
                                     {"label": "Los Mangos", "value": "C74652"},
                                     {"label": "Los Molinos",
                                      "value": "C74653"},
                                     {"label": "Los Naranjos",
                                      "value": "C74654"},
                                     {"label": "Los Pescados",
                                      "value": "C74655"},
                                     {"label": "Los Pinos", "value": "C74656"},
                                     {"label": "Los Reyes", "value": "C74657"},
                                     {"label": "Los Robles", "value": "C74658"},
                                     {"label": "Los Tigres (San Marcos)",
                                      "value": "C74659"},
                                     {"label": "Los Torrentes",
                                      "value": "C74660"},
                                     {"label": "Macedonio Alonso",
                                      "value": "C74661"},
                                     {"label": "Mafafas", "value": "C74662"},
                                     {"label": "Magdalena", "value": "C74663"},
                                     {"label": "Mahuixtlan", "value": "C74664"},
                                     {"label": "Maltrata", "value": "C74665"},
                                     {"label": "Manuel Leon",
                                      "value": "C74666"},
                                     {"label": "Manuel Maria Contreras",
                                      "value": "C74667"},
                                     {"label": "Mapachapa", "value": "C74668"},
                                     {"label": "Maria de la Torre",
                                      "value": "C74669"},
                                     {"label": "Mariano Escobedo",
                                      "value": "C74670"},
                                     {"label": "Martinez de la Torre",
                                      "value": "C74671"},
                                     {"label": "Mata de Indio",
                                      "value": "C74672"},
                                     {"label": "Mata Loma", "value": "C74673"},
                                     {"label": "Mata Naranjo",
                                      "value": "C74674"},
                                     {"label": "Mata Obscura",
                                      "value": "C74675"},
                                     {"label": "Mata Tenatito (Casco Hacienda)",
                                      "value": "C74676"},
                                     {"label": "Matacapan", "value": "C74677"},
                                     {"label": "Mazumiapam", "value": "C74678"},
                                     {"label": "Mecatlan", "value": "C74679"},
                                     {"label": "Mecayapan", "value": "C74680"},
                                     {"label": "Medellin", "value": "C74681"},
                                     {"label": "Medellin de Bravo",
                                      "value": "C74682"},
                                     {"label": "Medias Aguas",
                                      "value": "C74683"},
                                     {"label": "Melchor Ocampo",
                                      "value": "C74684"},
                                     {"label": "Mequetla", "value": "C74685"},
                                     {"label": "Mesa de Guadalupe",
                                      "value": "C74686"},
                                     {"label": "Metlac Hernandez (Metlac Primero)",
                                      "value": "C74687"},
                                     {"label": "Mexcala", "value": "C74688"},
                                     {"label": "Miahuatlan", "value": "C74689"},
                                     {"label": "Miguel Hidalgo",
                                      "value": "C74690"},
                                     {"label": "Minatitlan", "value": "C74691"},
                                     {"label": "Minzapan", "value": "C74692"},
                                     {"label": "Misantla", "value": "C74693"},
                                     {"label": "Mixquiapan", "value": "C74694"},
                                     {"label": "Mixtla de Altamirano",
                                      "value": "C74695"},
                                     {"label": "Moloacan", "value": "C74696"},
                                     {"label": "Monte Blanco",
                                      "value": "C74697"},
                                     {"label": "Monte Salas",
                                      "value": "C74698"},
                                     {"label": "Monte Verde Chiveria",
                                      "value": "C74699"},
                                     {"label": "Moralillo", "value": "C74700"},
                                     {"label": "Morelos", "value": "C74701"},
                                     {"label": "Motzorongo", "value": "C74702"},
                                     {"label": "Moyoapan", "value": "C74703"},
                                     {"label": "Mozomboa", "value": "C74704"},
                                     {"label": "Mundo Nuevo",
                                      "value": "C74705"},
                                     {"label": "Nanchital de Lazaro Cardenas del Rio",
                                      "value": "C74706"},
                                     {"label": "Naolinco", "value": "C74707"},
                                     {"label": "Naolinco de Victoria",
                                      "value": "C74708"},
                                     {"label": "Naranjal", "value": "C74709"},
                                     {"label": "Naranjos", "value": "C74710"},
                                     {"label": "Naranjos Amatlan",
                                      "value": "C74711"},
                                     {"label": "Nautla", "value": "C74712"},
                                     {"label": "Necoxtla", "value": "C74713"},
                                     {"label": "Nicolas Blanco (San Pancho)",
                                      "value": "C74714"},
                                     {"label": "Ninos Heroes",
                                      "value": "C74715"},
                                     {"label": "Nogales", "value": "C74716"},
                                     {"label": "Nopalapan", "value": "C74717"},
                                     {"label": "Nopaltepec", "value": "C74718"},
                                     {"label": "Novara", "value": "C74719"},
                                     {"label": "Nuevo Progreso",
                                      "value": "C74720"},
                                     {"label": "Nuevo San Jose Independencia",
                                      "value": "C74721"},
                                     {"label": "Oasis", "value": "C74722"},
                                     {"label": "Ocelota", "value": "C74723"},
                                     {"label": "Ocotal Chico",
                                      "value": "C74724"},
                                     {"label": "Ocotitlan", "value": "C74725"},
                                     {"label": "Ocozotepec", "value": "C74726"},
                                     {"label": "Ohuapan", "value": "C74727"},
                                     {"label": "Ohuilapam", "value": "C74728"},
                                     {"label": "Ojite Rancho Nuevo",
                                      "value": "C74729"},
                                     {"label": "Oluta", "value": "C74730"},
                                     {"label": "Omealca", "value": "C74731"},
                                     {"label": "Orilla del Monte",
                                      "value": "C74732"},
                                     {"label": "Orizaba", "value": "C74733"},
                                     {"label": "Otates", "value": "C74734"},
                                     {"label": "Otatitlan", "value": "C74735"},
                                     {"label": "Oteapan", "value": "C74736"},
                                     {"label": "Otilpan", "value": "C74737"},
                                     {"label": "Oviedo", "value": "C74738"},
                                     {"label": "Ozuluama de Mascarenas",
                                      "value": "C74739"},
                                     {"label": "Pacho Nuevo",
                                      "value": "C74740"},
                                     {"label": "Pacho Viejo",
                                      "value": "C74741"},
                                     {"label": "Pahua Hueca",
                                      "value": "C74742"},
                                     {"label": "Pajapan", "value": "C74743"},
                                     {"label": "Palma Sola", "value": "C74744"},
                                     {"label": "Palmar Grande",
                                      "value": "C74745"},
                                     {"label": "Palmas de Abajo",
                                      "value": "C74746"},
                                     {"label": "Palmillas", "value": "C74747"},
                                     {"label": "Palmira", "value": "C74748"},
                                     {"label": "Palo Bendito",
                                      "value": "C74749"},
                                     {"label": "Palo Gacho", "value": "C74750"},
                                     {"label": "Panuco", "value": "C74751"},
                                     {"label": "Papantla de Olarte",
                                      "value": "C74752"},
                                     {"label": "Paraiso Novillero",
                                      "value": "C74753"},
                                     {"label": "Paraje Nuevo",
                                      "value": "C74754"},
                                     {"label": "Paso de Ovejas",
                                      "value": "C74755"},
                                     {"label": "Paso de Valencia",
                                      "value": "C74756"},
                                     {"label": "Paso del Correo",
                                      "value": "C74757"},
                                     {"label": "Paso del Macho",
                                      "value": "C74758"},
                                     {"label": "Paso del Toro",
                                      "value": "C74759"},
                                     {"label": "Paso Nacional",
                                      "value": "C74760"},
                                     {"label": "Penuela", "value": "C74761"},
                                     {"label": "Perote", "value": "C74762"},
                                     {"label": "Perseverancia",
                                      "value": "C74763"},
                                     {"label": "Piedra Parada",
                                      "value": "C74764"},
                                     {"label": "Piedra Pinta",
                                      "value": "C74765"},
                                     {"label": "Piedras Negras",
                                      "value": "C74766"},
                                     {"label": "Piletas", "value": "C74767"},
                                     {"label": "Pisaflores", "value": "C74768"},
                                     {"label": "Plan de Arroyos",
                                      "value": "C74769"},
                                     {"label": "Plan de Ayala",
                                      "value": "C74770"},
                                     {"label": "Plan de Iguala",
                                      "value": "C74771"},
                                     {"label": "Plan de las Hayas",
                                      "value": "C74772"},
                                     {"label": "Plan del Rio",
                                      "value": "C74773"},
                                     {"label": "Platon Sanchez",
                                      "value": "C74774"},
                                     {"label": "Playa de Chachalacas",
                                      "value": "C74775"},
                                     {"label": "Playa de la Libertad",
                                      "value": "C74776"},
                                     {"label": "Playa Vicente",
                                      "value": "C74777"},
                                     {"label": "Poblado 10", "value": "C74778"},
                                     {"label": "Poblado Cinco",
                                      "value": "C74779"},
                                     {"label": "Poblado Dos",
                                      "value": "C74780"},
                                     {"label": "Poblado Tres",
                                      "value": "C74781"},
                                     {"label": "Polutla", "value": "C74782"},
                                     {"label": "Potrero del Llano",
                                      "value": "C74783"},
                                     {"label": "Potrero Viejo",
                                      "value": "C74784"},
                                     {"label": "Poza Rica de Hidalgo",
                                      "value": "C74785"},
                                     {"label": "Presidio", "value": "C74786"},
                                     {"label": "Presidio (Plan de Libres)",
                                      "value": "C74787"},
                                     {"label": "Primero de Mayo",
                                      "value": "C74788"},
                                     {"label": "Progreso de Zaragoza",
                                      "value": "C74789"},
                                     {"label": "Pueblillo", "value": "C74790"},
                                     {"label": "Pueblo Viejo",
                                      "value": "C74791"},
                                     {"label": "Puente de Piedra",
                                      "value": "C74792"},
                                     {"label": "Puente Jula",
                                      "value": "C74793"},
                                     {"label": "Puerto Esmeralda",
                                      "value": "C74794"},
                                     {"label": "Puntilla Aldama",
                                      "value": "C74795"},
                                     {"label": "Quiamoloapan",
                                      "value": "C74796"},
                                     {"label": "Rafael Delgado",
                                      "value": "C74797"},
                                     {"label": "Rafael Lucio",
                                      "value": "C74798"},
                                     {"label": "Rancho Alegre",
                                      "value": "C74799"},
                                     {"label": "Rancho del Padre",
                                      "value": "C74800"},
                                     {"label": "Rancho Nuevo",
                                      "value": "C74801"},
                                     {"label": "Rancho Viejo",
                                      "value": "C74802"},
                                     {"label": "Residencial las Olas",
                                      "value": "C74803"},
                                     {"label": "Residencial Tajin",
                                      "value": "C74804"},
                                     {"label": "Ricardo Flores Magon",
                                      "value": "C74805"},
                                     {"label": "Rincon de Barrabas",
                                      "value": "C74806"},
                                     {"label": "Rincon de Buena Vista",
                                      "value": "C74807"},
                                     {"label": "Rinconada", "value": "C74808"},
                                     {"label": "Rio Blanco", "value": "C74809"},
                                     {"label": "Rio Medio [Granja]",
                                      "value": "C74810"},
                                     {"label": "Rodriguez Tejeda",
                                      "value": "C74811"},
                                     {"label": "Sabanas de Xalostoc",
                                      "value": "C74812"},
                                     {"label": "Sabaneta", "value": "C74813"},
                                     {"label": "Saladero", "value": "C74814"},
                                     {"label": "Salinas", "value": "C74815"},
                                     {"label": "Saltabarranca",
                                      "value": "C74816"},
                                     {"label": "Saltillo", "value": "C74817"},
                                     {"label": "Salvador Diaz Miron",
                                      "value": "C74818"},
                                     {"label": "San Agustin del Palmar",
                                      "value": "C74819"},
                                     {"label": "San Andres Tenejapan",
                                      "value": "C74820"},
                                     {"label": "San Andres Tuxtla",
                                      "value": "C74821"},
                                     {"label": "San Antonio Xoquitla",
                                      "value": "C74822"},
                                     {"label": "San Cristobal",
                                      "value": "C74823"},
                                     {"label": "San Fernando",
                                      "value": "C74824"},
                                     {"label": "San Francisco",
                                      "value": "C74825"},
                                     {"label": "San Francisco (Mata Clara)",
                                      "value": "C74826"},
                                     {"label": "San Francisco Nacaxtle",
                                      "value": "C74827"},
                                     {"label": "San Isidro", "value": "C74828"},
                                     {"label": "San Isidro el Berro",
                                      "value": "C74829"},
                                     {"label": "San Isidro Palotal",
                                      "value": "C74830"},
                                     {"label": "San Isidro Xoteapan (San Isidro Texcaltitan)",
                                      "value": "C74831"},
                                     {"label": "San Jose de Abajo",
                                      "value": "C74832"},
                                     {"label": "San Jose de Abajo [Unidad Habitacional]",
                                      "value": "C74833"},
                                     {"label": "San Jose de Tapia",
                                      "value": "C74834"},
                                     {"label": "San Jose Independencia",
                                      "value": "C74835"},
                                     {"label": "San Jose Neria",
                                      "value": "C74836"},
                                     {"label": "San Jose Suchil",
                                      "value": "C74837"},
                                     {"label": "San Jose Tenejapa",
                                      "value": "C74838"},
                                     {"label": "San Juan de los Reyes (Luis Valenzuela)",
                                      "value": "C74839"},
                                     {"label": "San Juan Evangelista",
                                      "value": "C74840"},
                                     {"label": "San Juan Seco de Valencia",
                                      "value": "C74841"},
                                     {"label": "San Juan Volador",
                                      "value": "C74842"},
                                     {"label": "San Julian", "value": "C74843"},
                                     {"label": "San Leoncio Jamaya",
                                      "value": "C74844"},
                                     {"label": "San Lorenzo Tenochtitlan",
                                      "value": "C74845"},
                                     {"label": "San Marcos Atesquilapan",
                                      "value": "C74846"},
                                     {"label": "San Marcos de Leon",
                                      "value": "C74847"},
                                     {"label": "San Miguel Mecatepec",
                                      "value": "C74848"},
                                     {"label": "San Miguel Tlalpoalan",
                                      "value": "C74849"},
                                     {"label": "San Pedro Coyutla",
                                      "value": "C74850"},
                                     {"label": "San Pedro Martir",
                                      "value": "C74851"},
                                     {"label": "San Pedro Tlanixco",
                                      "value": "C74852"},
                                     {"label": "San Pedro Tlapacoyan",
                                      "value": "C74853"},
                                     {"label": "San Rafael", "value": "C74854"},
                                     {"label": "San Rafael Caleria",
                                      "value": "C74855"},
                                     {"label": "San Rafael Rio Seco",
                                      "value": "C74856"},
                                     {"label": "San Sebastian",
                                      "value": "C74857"},
                                     {"label": "Santa Catalina",
                                      "value": "C74858"},
                                     {"label": "Santa Isabel",
                                      "value": "C74859"},
                                     {"label": "Santa Lucia Potrerillo",
                                      "value": "C74860"},
                                     {"label": "Santa Maria Tatetla",
                                      "value": "C74861"},
                                     {"label": "Santa Rita", "value": "C74862"},
                                     {"label": "Santa Rosa", "value": "C74863"},
                                     {"label": "Santa Rosa Abata",
                                      "value": "C74864"},
                                     {"label": "Santa Rosa Loma Larga",
                                      "value": "C74865"},
                                     {"label": "Santa Teresa",
                                      "value": "C74866"},
                                     {"label": "Santiago", "value": "C74867"},
                                     {"label": "Santiago de la Pena",
                                      "value": "C74868"},
                                     {"label": "Santiago Sochiapan",
                                      "value": "C74869"},
                                     {"label": "Santiago Tuxtla",
                                      "value": "C74870"},
                                     {"label": "Sasaltitla", "value": "C74871"},
                                     {"label": "Sayula de Aleman",
                                      "value": "C74872"},
                                     {"label": "Sierra de Agua",
                                      "value": "C74873"},
                                     {"label": "Sihuapan", "value": "C74874"},
                                     {"label": "Sinapan", "value": "C74875"},
                                     {"label": "Sochiapa", "value": "C74876"},
                                     {"label": "Soconusco", "value": "C74877"},
                                     {"label": "Soledad Atzompa",
                                      "value": "C74878"},
                                     {"label": "Soledad de Doblado",
                                      "value": "C74879"},
                                     {"label": "Sonora", "value": "C74880"},
                                     {"label": "Sontecomapan",
                                      "value": "C74881"},
                                     {"label": "Soteapan", "value": "C74882"},
                                     {"label": "Soyata", "value": "C74883"},
                                     {"label": "Suchilapan del Rio",
                                      "value": "C74884"},
                                     {"label": "Sumidero", "value": "C74885"},
                                     {"label": "Tamalin", "value": "C74886"},
                                     {"label": "Tamiahua", "value": "C74887"},
                                     {"label": "Tampico Alto",
                                      "value": "C74888"},
                                     {"label": "Tancoco", "value": "C74889"},
                                     {"label": "Tantima", "value": "C74890"},
                                     {"label": "Tantoyuca", "value": "C74891"},
                                     {"label": "Tapalapan", "value": "C74892"},
                                     {"label": "Tatahuicapan",
                                      "value": "C74893"},
                                     {"label": "Tatatila", "value": "C74894"},
                                     {"label": "Taza de Agua Ojo Zarco",
                                      "value": "C74895"},
                                     {"label": "Teayo", "value": "C74896"},
                                     {"label": "Tecama", "value": "C74897"},
                                     {"label": "Tecamalucan",
                                      "value": "C74898"},
                                     {"label": "Tecolapan", "value": "C74899"},
                                     {"label": "Tecolutla", "value": "C74900"},
                                     {"label": "Tehuipango", "value": "C74901"},
                                     {"label": "Temapache", "value": "C74902"},
                                     {"label": "Tempoal", "value": "C74903"},
                                     {"label": "Tempoal de Sanchez",
                                      "value": "C74904"},
                                     {"label": "Tenampa", "value": "C74905"},
                                     {"label": "Tenantitla", "value": "C74906"},
                                     {"label": "Tenenexpan", "value": "C74907"},
                                     {"label": "Tenixtepec", "value": "C74908"},
                                     {"label": "Tenochtitlan",
                                      "value": "C74909"},
                                     {"label": "Teocelo", "value": "C74910"},
                                     {"label": "Tepatlaxco", "value": "C74911"},
                                     {"label": "Tepetlan", "value": "C74912"},
                                     {"label": "Tepetzintla",
                                      "value": "C74913"},
                                     {"label": "Tequila", "value": "C74914"},
                                     {"label": "Tesechoacan",
                                      "value": "C74915"},
                                     {"label": "Teteltzingo",
                                      "value": "C74916"},
                                     {"label": "Tetla", "value": "C74917"},
                                     {"label": "Tetlatzinga",
                                      "value": "C74918"},
                                     {"label": "Tetlaxco", "value": "C74919"},
                                     {"label": "Tetzacual", "value": "C74920"},
                                     {"label": "Texalpan de Abajo",
                                      "value": "C74921"},
                                     {"label": "Texalpan de Arriba",
                                      "value": "C74922"},
                                     {"label": "Texcaltitan Xoteapan (Texcaltitan)",
                                      "value": "C74923"},
                                     {"label": "Texcatepec", "value": "C74924"},
                                     {"label": "Texhuacan", "value": "C74925"},
                                     {"label": "Texin", "value": "C74926"},
                                     {"label": "Texistepec", "value": "C74927"},
                                     {"label": "Texmola", "value": "C74928"},
                                     {"label": "Tezonapa", "value": "C74929"},
                                     {"label": "Tierra Blanca",
                                      "value": "C74930"},
                                     {"label": "Tierra Blanca Booxter",
                                      "value": "C74931"},
                                     {"label": "Tierra Colorada",
                                      "value": "C74932"},
                                     {"label": "Tierra Nueva",
                                      "value": "C74933"},
                                     {"label": "Tihuatlan", "value": "C74934"},
                                     {"label": "Tilapan", "value": "C74935"},
                                     {"label": "Tinajitas", "value": "C74936"},
                                     {"label": "Tincontlan", "value": "C74937"},
                                     {"label": "Tlachichilco",
                                      "value": "C74938"},
                                     {"label": "Tlacojalpan",
                                      "value": "C74939"},
                                     {"label": "Tlacolula", "value": "C74940"},
                                     {"label": "Tlacolulan", "value": "C74941"},
                                     {"label": "Tlacotalpan",
                                      "value": "C74942"},
                                     {"label": "Tlacotepec de Mejia",
                                      "value": "C74943"},
                                     {"label": "Tlacuilolapan",
                                      "value": "C74944"},
                                     {"label": "Tlalconteno",
                                      "value": "C74945"},
                                     {"label": "Tlalixcoyan",
                                      "value": "C74946"},
                                     {"label": "Tlalnelhuayocan",
                                      "value": "C74947"},
                                     {"label": "Tlaltetela", "value": "C74948"},
                                     {"label": "Tlamatoca", "value": "C74949"},
                                     {"label": "Tlapacoyan", "value": "C74950"},
                                     {"label": "Tlapala", "value": "C74951"},
                                     {"label": "Tlatilpa", "value": "C74952"},
                                     {"label": "Tlatzala", "value": "C74953"},
                                     {"label": "Tlilapan", "value": "C74954"},
                                     {"label": "Tocuila", "value": "C74955"},
                                     {"label": "Tolome", "value": "C74956"},
                                     {"label": "Tomatlan", "value": "C74957"},
                                     {"label": "Tonala", "value": "C74958"},
                                     {"label": "Tonalaco", "value": "C74959"},
                                     {"label": "Tonalapan", "value": "C74960"},
                                     {"label": "Tonalixco", "value": "C74961"},
                                     {"label": "Tonayan", "value": "C74962"},
                                     {"label": "Totolapa", "value": "C74963"},
                                     {"label": "Totutla", "value": "C74964"},
                                     {"label": "Tozongo", "value": "C74965"},
                                     {"label": "Trapiche del Rosario",
                                      "value": "C74966"},
                                     {"label": "Trapiche Viejo",
                                      "value": "C74967"},
                                     {"label": "Tres Valles",
                                      "value": "C74968"},
                                     {"label": "Tres Zapotes",
                                      "value": "C74969"},
                                     {"label": "Tronconal", "value": "C74970"},
                                     {"label": "Tula", "value": "C74971"},
                                     {"label": "Tulapam", "value": "C74972"},
                                     {"label": "Tuxpam de Rodriguez Cano",
                                      "value": "C74973"},
                                     {"label": "Tuxpan", "value": "C74974"},
                                     {"label": "Tuxpanguillo",
                                      "value": "C74975"},
                                     {"label": "Tuxtilla", "value": "C74976"},
                                     {"label": "Tuzamapan", "value": "C74977"},
                                     {"label": "Tzicatlan", "value": "C74978"},
                                     {"label": "Union y Progreso",
                                      "value": "C74979"},
                                     {"label": "Ursulo Galvan",
                                      "value": "C74980"},
                                     {"label": "Uxpanapa", "value": "C74981"},
                                     {"label": "Valente Diaz",
                                      "value": "C74982"},
                                     {"label": "Vargas", "value": "C74983"},
                                     {"label": "Vega de Alatorre",
                                      "value": "C74984"},
                                     {"label": "Vegas de la Soledad y Soledad Dos",
                                      "value": "C74985"},
                                     {"label": "Veinte de Noviembre",
                                      "value": "C74986"},
                                     {"label": "Veintiuno de Agosto",
                                      "value": "C74987"},
                                     {"label": "Venustiano Carranza (Pena Blanca)",
                                      "value": "C74988"},
                                     {"label": "Veracruz", "value": "C74989"},
                                     {"label": "Vicente Guerrero",
                                      "value": "C74990"},
                                     {"label": "Vicente Herrera",
                                      "value": "C74991"},
                                     {"label": "Villa Aldama",
                                      "value": "C74992"},
                                     {"label": "Villa Azueta",
                                      "value": "C74993"},
                                     {"label": "Villa de las Flores",
                                      "value": "C74994"},
                                     {"label": "Villa Emiliano Zapata",
                                      "value": "C74995"},
                                     {"label": "Villa Emilio Carranza",
                                      "value": "C74996"},
                                     {"label": "Villa Independencia",
                                      "value": "C74997"},
                                     {"label": "Villa Lazaro Cardenas",
                                      "value": "C74998"},
                                     {"label": "Villa Union",
                                      "value": "C74999"},
                                     {"label": "Villa Zempoala",
                                      "value": "C75000"},
                                     {"label": "Vista Hermosa",
                                      "value": "C75001"},
                                     {"label": "Xalapa de Enriquez",
                                      "value": "C75002"},
                                     {"label": "Xalatlaco", "value": "C75003"},
                                     {"label": "Xico", "value": "C75004"},
                                     {"label": "Xoampolco", "value": "C75005"},
                                     {"label": "Xochimilco", "value": "C75006"},
                                     {"label": "Xococapa", "value": "C75007"},
                                     {"label": "Xocotla", "value": "C75008"},
                                     {"label": "Xopilapa", "value": "C75009"},
                                     {"label": "Xoteapan", "value": "C75010"},
                                     {"label": "Xoxocotla", "value": "C75011"},
                                     {"label": "Yanga", "value": "C75012"},
                                     {"label": "Yecuatla", "value": "C75013"},
                                     {"label": "Zacamixtle", "value": "C75014"},
                                     {"label": "Zacate Colorado",
                                      "value": "C75015"},
                                     {"label": "Zacate Colorado Segundo (Fraternidad)",
                                      "value": "C75016"},
                                     {"label": "Zacatla", "value": "C75017"},
                                     {"label": "Zacualpan", "value": "C75018"},
                                     {"label": "Zamora", "value": "C75019"},
                                     {"label": "Zapoapan", "value": "C75020"},
                                     {"label": "Zapoapan de Amapan",
                                      "value": "C75021"},
                                     {"label": "Zapoapan de Cabanas",
                                      "value": "C75022"},
                                     {"label": "Zaragoza", "value": "C75023"},
                                     {"label": "Zentla", "value": "C75024"},
                                     {"label": "Zongolica", "value": "C75025"},
                                     {"label": "Zontecomatlan de Lopez y Fuentes",
                                      "value": "C75026"},
                                     {"label": "Zozocolco de Hidalgo",
                                      "value": "C75027"}],
 "Veraguas Province": [{"label": "Alto de Jesus", "value": "C80305"},
                       {"label": "Arenas", "value": "C80306"},
                       {"label": "Atalaya", "value": "C80307"},
                       {"label": "Barnizal", "value": "C80308"},
                       {"label": "Bisvalles", "value": "C80309"},
                       {"label": "Boqueron", "value": "C80310"},
                       {"label": "Boro", "value": "C80311"},
                       {"label": "Calabacito", "value": "C80312"},
                       {"label": "Calidonia", "value": "C80313"},
                       {"label": "Calobre", "value": "C80314"},
                       {"label": "Calovebora", "value": "C80315"},
                       {"label": "Canazas", "value": "C80316"},
                       {"label": "Canto del Llano", "value": "C80317"},
                       {"label": "Corozal", "value": "C80318"},
                       {"label": "Distrito de Atalaya", "value": "C80319"},
                       {"label": "Distrito de Calobre", "value": "C80320"},
                       {"label": "Distrito de Canazas", "value": "C80321"},
                       {"label": "Distrito de La Mesa", "value": "C80322"},
                       {"label": "Distrito de Las Palmas", "value": "C80323"},
                       {"label": "Distrito de Montijo", "value": "C80324"},
                       {"label": "Distrito de Rio de Jesus", "value": "C80325"},
                       {"label": "Distrito de San Francisco",
                        "value": "C80326"},
                       {"label": "Distrito de Santa Fe", "value": "C80327"},
                       {"label": "Distrito de Santiago", "value": "C80328"},
                       {"label": "Distrito de Sona", "value": "C80329"},
                       {"label": "El Alto", "value": "C80330"},
                       {"label": "El Barrito", "value": "C80331"},
                       {"label": "El Cocla", "value": "C80332"},
                       {"label": "El Espino de Santa Rosa", "value": "C80333"},
                       {"label": "El Maranon", "value": "C80334"},
                       {"label": "El Maria", "value": "C80335"},
                       {"label": "El Pajaro", "value": "C80336"},
                       {"label": "El Pantano", "value": "C80337"},
                       {"label": "El Penon", "value": "C80338"},
                       {"label": "El Picador", "value": "C80339"},
                       {"label": "El Potrero", "value": "C80340"},
                       {"label": "El Rincon", "value": "C80341"},
                       {"label": "El Uvito", "value": "C80342"},
                       {"label": "Guarumal", "value": "C80343"},
                       {"label": "La Colorada", "value": "C80344"},
                       {"label": "La Garceana", "value": "C80345"},
                       {"label": "La Laguna", "value": "C80346"},
                       {"label": "La Loma", "value": "C80347"},
                       {"label": "La Mesa", "value": "C80348"},
                       {"label": "La Montanuela", "value": "C80349"},
                       {"label": "La Pena", "value": "C80350"},
                       {"label": "La Raya de Calobre", "value": "C80351"},
                       {"label": "La Raya de Santa Maria", "value": "C80352"},
                       {"label": "La Yeguada", "value": "C80353"},
                       {"label": "Las Guias Abajo", "value": "C80354"},
                       {"label": "Las Huacas", "value": "C80355"},
                       {"label": "Las Palmas", "value": "C80356"},
                       {"label": "Leones Arriba", "value": "C80357"},
                       {"label": "Llano Grande", "value": "C80358"},
                       {"label": "Lola", "value": "C80359"},
                       {"label": "Los Algarrobos", "value": "C80360"},
                       {"label": "Los Castillos", "value": "C80361"},
                       {"label": "Los Higos", "value": "C80362"},
                       {"label": "Mariato District", "value": "C80363"},
                       {"label": "Monjaras", "value": "C80364"},
                       {"label": "Montijo", "value": "C80365"},
                       {"label": "Piedras Gordas", "value": "C80366"},
                       {"label": "Ponuga", "value": "C80367"},
                       {"label": "Puerto Vidal", "value": "C80368"},
                       {"label": "Quebrada de Oro", "value": "C80369"},
                       {"label": "Rio de Jesus", "value": "C80370"},
                       {"label": "Rio Grande", "value": "C80371"},
                       {"label": "Rodeo Viejo", "value": "C80372"},
                       {"label": "San Bartolo", "value": "C80373"},
                       {"label": "San Francisco", "value": "C80374"},
                       {"label": "San Jose", "value": "C80375"},
                       {"label": "San Juan", "value": "C80376"},
                       {"label": "San Marcelo", "value": "C80377"},
                       {"label": "San Pedro del Espino", "value": "C80378"},
                       {"label": "Santa Fe", "value": "C80379"},
                       {"label": "Santiago de Veraguas", "value": "C80380"},
                       {"label": "Sona", "value": "C80381"},
                       {"label": "Tebario", "value": "C80382"},
                       {"label": "Utira", "value": "C80383"},
                       {"label": "Vigui", "value": "C80384"},
                       {"label": "Zapotillo", "value": "C80385"}],
 "Vermont": [{"label": "Addison", "value": "C145005"},
             {"label": "Addison County", "value": "C145006"},
             {"label": "Arlington", "value": "C145007"},
             {"label": "Barre", "value": "C145008"},
             {"label": "Bellows Falls", "value": "C145009"},
             {"label": "Bennington", "value": "C145010"},
             {"label": "Bennington County", "value": "C145011"},
             {"label": "Brandon", "value": "C145012"},
             {"label": "Brattleboro", "value": "C145013"},
             {"label": "Bridport", "value": "C145014"},
             {"label": "Bristol", "value": "C145015"},
             {"label": "Burlington", "value": "C145016"},
             {"label": "Caledonia County", "value": "C145017"},
             {"label": "Castleton", "value": "C145018"},
             {"label": "Charlotte", "value": "C145019"},
             {"label": "Chelsea", "value": "C145020"},
             {"label": "Chester", "value": "C145021"},
             {"label": "Chittenden", "value": "C145022"},
             {"label": "Chittenden County", "value": "C145023"},
             {"label": "Clarendon", "value": "C145024"},
             {"label": "Colchester", "value": "C145025"},
             {"label": "Danby", "value": "C145026"},
             {"label": "Dover", "value": "C145027"},
             {"label": "Enosburg Falls", "value": "C145028"},
             {"label": "Essex County", "value": "C145029"},
             {"label": "Essex Junction", "value": "C145030"},
             {"label": "Fair Haven", "value": "C145031"},
             {"label": "Ferrisburgh", "value": "C145032"},
             {"label": "Franklin County", "value": "C145033"},
             {"label": "Grand Isle County", "value": "C145034"},
             {"label": "Guildhall", "value": "C145035"},
             {"label": "Hardwick", "value": "C145036"},
             {"label": "Hartford", "value": "C145037"},
             {"label": "Hinesburg", "value": "C145038"},
             {"label": "Hyde Park", "value": "C145039"},
             {"label": "Jamaica", "value": "C145040"},
             {"label": "Jericho", "value": "C145041"},
             {"label": "Johnson", "value": "C145042"},
             {"label": "Lamoille County", "value": "C145043"},
             {"label": "Leicester", "value": "C145044"},
             {"label": "Lincoln", "value": "C145045"},
             {"label": "Londonderry", "value": "C145046"},
             {"label": "Lunenburg", "value": "C145047"},
             {"label": "Lyndon", "value": "C145048"},
             {"label": "Lyndonville", "value": "C145049"},
             {"label": "Manchester Center", "value": "C145050"},
             {"label": "Mendon", "value": "C145051"},
             {"label": "Middlebury (village)", "value": "C145052"},
             {"label": "Milton", "value": "C145053"},
             {"label": "Montgomery", "value": "C145054"},
             {"label": "Montpelier", "value": "C145055"},
             {"label": "Moretown", "value": "C145056"},
             {"label": "Morristown", "value": "C145057"},
             {"label": "Morrisville", "value": "C145058"},
             {"label": "Mount Holly", "value": "C145059"},
             {"label": "Newfane", "value": "C145060"},
             {"label": "Newport", "value": "C145061"},
             {"label": "North Bennington", "value": "C145062"},
             {"label": "North Hero", "value": "C145063"},
             {"label": "Northfield", "value": "C145064"},
             {"label": "Orange County", "value": "C145065"},
             {"label": "Orleans County", "value": "C145066"},
             {"label": "Pawlet", "value": "C145067"},
             {"label": "Poultney", "value": "C145068"},
             {"label": "Pownal", "value": "C145069"},
             {"label": "Randolph", "value": "C145070"},
             {"label": "Richford", "value": "C145071"},
             {"label": "Rockingham", "value": "C145072"},
             {"label": "Rutland", "value": "C145073"},
             {"label": "Rutland County", "value": "C145074"},
             {"label": "Saint Albans", "value": "C145075"},
             {"label": "Saint Johnsbury", "value": "C145076"},
             {"label": "Salisbury", "value": "C145077"},
             {"label": "South Barre", "value": "C145078"},
             {"label": "South Burlington", "value": "C145079"},
             {"label": "Springfield", "value": "C145080"},
             {"label": "St Johnsbury", "value": "C145081"},
             {"label": "Starksboro", "value": "C145082"},
             {"label": "Stowe", "value": "C145083"},
             {"label": "Swanton", "value": "C145084"},
             {"label": "Townshend", "value": "C145085"},
             {"label": "Vergennes", "value": "C145086"},
             {"label": "Washington", "value": "C145087"},
             {"label": "Washington County", "value": "C145088"},
             {"label": "Waterbury", "value": "C145089"},
             {"label": "West Brattleboro", "value": "C145090"},
             {"label": "West Rutland", "value": "C145091"},
             {"label": "White River Junction", "value": "C145092"},
             {"label": "Wilder", "value": "C145093"},
             {"label": "Williamstown", "value": "C145094"},
             {"label": "Williston", "value": "C145095"},
             {"label": "Windham County", "value": "C145096"},
             {"label": "Windsor", "value": "C145097"},
             {"label": "Windsor County", "value": "C145098"},
             {"label": "Winooski", "value": "C145099"},
             {"label": "Woodstock", "value": "C145100"}],
 "Verzej Municipality": [{"label": "Verzej", "value": "C107849"}],
 "Vest-Agder": [{"label": "Aseral", "value": "C79238"},
                {"label": "Audnedal", "value": "C79239"},
                {"label": "Farsund", "value": "C79240"},
                {"label": "Flekkefjord", "value": "C79241"},
                {"label": "Haegebostad", "value": "C79242"},
                {"label": "Justvik", "value": "C79243"},
                {"label": "Kristiansand", "value": "C79244"},
                {"label": "Kvinesdal", "value": "C79245"},
                {"label": "Liknes", "value": "C79246"},
                {"label": "Lindesnes", "value": "C79247"},
                {"label": "Lyngdal", "value": "C79248"},
                {"label": "Mandal", "value": "C79249"},
                {"label": "Marnardal", "value": "C79250"},
                {"label": "Nodeland", "value": "C79251"},
                {"label": "Sirdal", "value": "C79252"},
                {"label": "Skalevik", "value": "C79253"},
                {"label": "Songdalen", "value": "C79254"},
                {"label": "Sogne", "value": "C79255"},
                {"label": "Strai", "value": "C79256"},
                {"label": "Tonstad", "value": "C79257"},
                {"label": "Tveit", "value": "C79258"},
                {"label": "Vanse", "value": "C79259"},
                {"label": "Vennesla", "value": "C79260"},
                {"label": "Vestbygd", "value": "C79261"},
                {"label": "Vigeland", "value": "C79262"}],
 "Vestfold": [{"label": "Aroysund", "value": "C79263"},
              {"label": "Asgardstrand", "value": "C79264"},
              {"label": "Barkaker", "value": "C79265"},
              {"label": "Berger", "value": "C79266"},
              {"label": "Faerder", "value": "C79267"},
              {"label": "Gullhaug", "value": "C79268"},
              {"label": "Holmestrand", "value": "C79269"},
              {"label": "Horten", "value": "C79270"},
              {"label": "Larvik", "value": "C79271"},
              {"label": "Melsomvik", "value": "C79272"},
              {"label": "Re", "value": "C79273"},
              {"label": "Sande", "value": "C79274"},
              {"label": "Sandefjord", "value": "C79275"},
              {"label": "Selvik", "value": "C79276"},
              {"label": "Sem", "value": "C79277"},
              {"label": "Skoppum", "value": "C79278"},
              {"label": "Stavern", "value": "C79279"},
              {"label": "Stokke", "value": "C79280"},
              {"label": "Svelvik", "value": "C79281"},
              {"label": "Tjome", "value": "C79282"},
              {"label": "Tonsberg", "value": "C79283"}],
 "Veszprem County": [{"label": "Ajka", "value": "C44762"},
                     {"label": "Ajkai Jaras", "value": "C44763"},
                     {"label": "Badacsonytomaj", "value": "C44764"},
                     {"label": "Balatonalmadi", "value": "C44765"},
                     {"label": "Balatonalmadi Jaras", "value": "C44766"},
                     {"label": "Balatonfured", "value": "C44767"},
                     {"label": "Balatonfuredi Jaras", "value": "C44768"},
                     {"label": "Balatonkenese", "value": "C44769"},
                     {"label": "Berhida", "value": "C44770"},
                     {"label": "Csabrendek", "value": "C44771"},
                     {"label": "Cseteny", "value": "C44772"},
                     {"label": "Csopak", "value": "C44773"},
                     {"label": "Devecser", "value": "C44774"},
                     {"label": "Devecseri Jaras", "value": "C44775"},
                     {"label": "Hajmasker", "value": "C44776"},
                     {"label": "Herend", "value": "C44777"},
                     {"label": "Liter", "value": "C44778"},
                     {"label": "Nemesvamos", "value": "C44779"},
                     {"label": "Osi", "value": "C44780"},
                     {"label": "Papa", "value": "C44781"},
                     {"label": "Papai Jaras", "value": "C44782"},
                     {"label": "Petfurdo", "value": "C44783"},
                     {"label": "Revfulop", "value": "C44784"},
                     {"label": "Sumeg", "value": "C44785"},
                     {"label": "Sumegi Jaras", "value": "C44786"},
                     {"label": "Szentkiralyszabadja", "value": "C44787"},
                     {"label": "Tapolca", "value": "C44788"},
                     {"label": "Tapolcai Jaras", "value": "C44789"},
                     {"label": "Tihany", "value": "C44790"},
                     {"label": "Urkut", "value": "C44791"},
                     {"label": "Varpalota", "value": "C44792"},
                     {"label": "Varpalotai Jaras", "value": "C44793"},
                     {"label": "Veszprem", "value": "C44794"},
                     {"label": "Veszpremi Jaras", "value": "C44795"},
                     {"label": "Zanka", "value": "C44796"},
                     {"label": "Zirc", "value": "C44797"},
                     {"label": "Zirci Jaras", "value": "C44798"}],
 "Vevcani Municipality": [{"label": "Vevcani", "value": "C65538"}],
 "Viana do Castelo": [{"label": "Arcos de Valdevez", "value": "C92008"},
                      {"label": "Caminha", "value": "C92009"},
                      {"label": "Melgaco", "value": "C92010"},
                      {"label": "Moncao", "value": "C92011"},
                      {"label": "Paredes de Coura", "value": "C92012"},
                      {"label": "Ponte da Barca", "value": "C92013"},
                      {"label": "Ponte de Lima", "value": "C92014"},
                      {"label": "Valenca", "value": "C92015"},
                      {"label": "Viana do Castelo", "value": "C92016"}],
 "Vichada": [{"label": "Cumaribo", "value": "C20654"},
             {"label": "La Primavera", "value": "C20655"},
             {"label": "Puerto Carreno", "value": "C20656"},
             {"label": "Santa Rosalia", "value": "C20657"}],
 "Victoria": [{"label": "Abbotsford", "value": "C4743"},
              {"label": "Aberfeldie", "value": "C4744"},
              {"label": "Airport West", "value": "C4745"},
              {"label": "Albanvale", "value": "C4746"},
              {"label": "Albert Park", "value": "C4747"},
              {"label": "Albion", "value": "C4748"},
              {"label": "Alexandra", "value": "C4749"},
              {"label": "Alfredton", "value": "C4750"},
              {"label": "Allansford", "value": "C4751"},
              {"label": "Alphington", "value": "C4752"},
              {"label": "Alpine", "value": "C4753"},
              {"label": "Altona", "value": "C4754"},
              {"label": "Altona Meadows", "value": "C4755"},
              {"label": "Altona North", "value": "C4756"},
              {"label": "Anglesea", "value": "C4757"},
              {"label": "Apollo Bay", "value": "C4758"},
              {"label": "Ararat", "value": "C4759"},
              {"label": "Ardeer", "value": "C4760"},
              {"label": "Armadale", "value": "C4761"},
              {"label": "Armstrong Creek", "value": "C4762"},
              {"label": "Ascot", "value": "C4763"},
              {"label": "Ascot Vale", "value": "C4764"},
              {"label": "Ashburton", "value": "C4765"},
              {"label": "Ashwood", "value": "C4766"},
              {"label": "Aspendale", "value": "C4767"},
              {"label": "Aspendale Gardens", "value": "C4768"},
              {"label": "Attwood", "value": "C4769"},
              {"label": "Avenel", "value": "C4770"},
              {"label": "Avoca", "value": "C4771"},
              {"label": "Avondale Heights", "value": "C4772"},
              {"label": "Bacchus Marsh", "value": "C4773"},
              {"label": "Badger Creek", "value": "C4774"},
              {"label": "Bairnsdale", "value": "C4775"},
              {"label": "Balaclava", "value": "C4776"},
              {"label": "Ballan", "value": "C4777"},
              {"label": "Ballarat", "value": "C4778"},
              {"label": "Ballarat Central", "value": "C4779"},
              {"label": "Ballarat East", "value": "C4780"},
              {"label": "Ballarat North", "value": "C4781"},
              {"label": "Balnarring", "value": "C4782"},
              {"label": "Balwyn", "value": "C4783"},
              {"label": "Balwyn North", "value": "C4784"},
              {"label": "Bannockburn", "value": "C4785"},
              {"label": "Banyule", "value": "C4786"},
              {"label": "Baranduda", "value": "C4787"},
              {"label": "Barwon Heads", "value": "C4788"},
              {"label": "Bass Coast", "value": "C4789"},
              {"label": "Baw Baw", "value": "C4790"},
              {"label": "Baxter", "value": "C4791"},
              {"label": "Bayside", "value": "C4792"},
              {"label": "Bayswater", "value": "C4793"},
              {"label": "Bayswater North", "value": "C4794"},
              {"label": "Beaconsfield", "value": "C4795"},
              {"label": "Beaconsfield Upper", "value": "C4796"},
              {"label": "Beaufort", "value": "C4797"},
              {"label": "Beaumaris", "value": "C4798"},
              {"label": "Beechworth", "value": "C4799"},
              {"label": "Belgrave", "value": "C4800"},
              {"label": "Belgrave Heights", "value": "C4801"},
              {"label": "Belgrave South", "value": "C4802"},
              {"label": "Bell Park", "value": "C4803"},
              {"label": "Bell Post Hill", "value": "C4804"},
              {"label": "Bellfield", "value": "C4805"},
              {"label": "Belmont", "value": "C4806"},
              {"label": "Benalla", "value": "C4807"},
              {"label": "Bendigo", "value": "C4808"},
              {"label": "Bendigo city centre", "value": "C4809"},
              {"label": "Bentleigh", "value": "C4810"},
              {"label": "Bentleigh East", "value": "C4811"},
              {"label": "Berwick", "value": "C4812"},
              {"label": "Beveridge", "value": "C4813"},
              {"label": "Bittern", "value": "C4814"},
              {"label": "Black Hill", "value": "C4815"},
              {"label": "Black Rock", "value": "C4816"},
              {"label": "Blackburn", "value": "C4817"},
              {"label": "Blackburn North", "value": "C4818"},
              {"label": "Blackburn South", "value": "C4819"},
              {"label": "Blairgowrie", "value": "C4820"},
              {"label": "Blind Bight", "value": "C4821"},
              {"label": "Bonbeach", "value": "C4822"},
              {"label": "Boronia", "value": "C4823"},
              {"label": "Boroondara", "value": "C4824"},
              {"label": "Botanic Ridge", "value": "C4825"},
              {"label": "Box Hill", "value": "C4826"},
              {"label": "Box Hill North", "value": "C4827"},
              {"label": "Box Hill South", "value": "C4828"},
              {"label": "Braybrook", "value": "C4829"},
              {"label": "Briagolong", "value": "C4830"},
              {"label": "Briar Hill", "value": "C4831"},
              {"label": "Bright", "value": "C4832"},
              {"label": "Brighton", "value": "C4833"},
              {"label": "Brighton East", "value": "C4834"},
              {"label": "Brimbank", "value": "C4835"},
              {"label": "Broadford", "value": "C4836"},
              {"label": "Broadmeadows", "value": "C4837"},
              {"label": "Brookfield", "value": "C4838"},
              {"label": "Brooklyn", "value": "C4839"},
              {"label": "Brown Hill", "value": "C4840"},
              {"label": "Brunswick", "value": "C4841"},
              {"label": "Brunswick East", "value": "C4842"},
              {"label": "Brunswick West", "value": "C4843"},
              {"label": "Bulleen", "value": "C4844"},
              {"label": "Buloke", "value": "C4845"},
              {"label": "Bundoora", "value": "C4846"},
              {"label": "Buninyong", "value": "C4847"},
              {"label": "Bunyip", "value": "C4848"},
              {"label": "Burnside", "value": "C4849"},
              {"label": "Burnside Heights", "value": "C4850"},
              {"label": "Burwood", "value": "C4851"},
              {"label": "Burwood East", "value": "C4852"},
              {"label": "Cairnlea", "value": "C4853"},
              {"label": "California Gully", "value": "C4854"},
              {"label": "Camberwell", "value": "C4855"},
              {"label": "Campaspe", "value": "C4856"},
              {"label": "Campbellfield", "value": "C4857"},
              {"label": "Campbells Creek", "value": "C4858"},
              {"label": "Camperdown", "value": "C4859"},
              {"label": "Canadian", "value": "C4860"},
              {"label": "Canterbury", "value": "C4861"},
              {"label": "Cape Woolamai", "value": "C4862"},
              {"label": "Cardinia", "value": "C4863"},
              {"label": "Carisbrook", "value": "C4864"},
              {"label": "Carlton", "value": "C4865"},
              {"label": "Carlton North", "value": "C4866"},
              {"label": "Carnegie", "value": "C4867"},
              {"label": "Caroline Springs", "value": "C4868"},
              {"label": "Carrum", "value": "C4869"},
              {"label": "Carrum Downs", "value": "C4870"},
              {"label": "Casey", "value": "C4871"},
              {"label": "Casterton", "value": "C4872"},
              {"label": "Castlemaine", "value": "C4873"},
              {"label": "Caulfield", "value": "C4874"},
              {"label": "Caulfield East", "value": "C4875"},
              {"label": "Caulfield North", "value": "C4876"},
              {"label": "Caulfield South", "value": "C4877"},
              {"label": "Central Goldfields", "value": "C4878"},
              {"label": "Chadstone", "value": "C4879"},
              {"label": "Charlton", "value": "C4880"},
              {"label": "Chelsea", "value": "C4881"},
              {"label": "Chelsea Heights", "value": "C4882"},
              {"label": "Cheltenham", "value": "C4883"},
              {"label": "Chewton", "value": "C4884"},
              {"label": "Chiltern", "value": "C4885"},
              {"label": "Chirnside Park", "value": "C4886"},
              {"label": "Churchill", "value": "C4887"},
              {"label": "Clarinda", "value": "C4888"},
              {"label": "Clayton", "value": "C4889"},
              {"label": "Clayton South", "value": "C4890"},
              {"label": "Clifton Hill", "value": "C4891"},
              {"label": "Clifton Springs", "value": "C4892"},
              {"label": "Clunes", "value": "C4893"},
              {"label": "Clyde", "value": "C4894"},
              {"label": "Clyde North", "value": "C4895"},
              {"label": "Cobden", "value": "C4896"},
              {"label": "Cobram", "value": "C4897"},
              {"label": "Coburg", "value": "C4898"},
              {"label": "Coburg North", "value": "C4899"},
              {"label": "Cockatoo", "value": "C4900"},
              {"label": "Cohuna", "value": "C4901"},
              {"label": "Colac", "value": "C4902"},
              {"label": "Colac-Otway", "value": "C4903"},
              {"label": "Coldstream", "value": "C4904"},
              {"label": "Collingwood", "value": "C4905"},
              {"label": "Coolaroo", "value": "C4906"},
              {"label": "Corangamite", "value": "C4907"},
              {"label": "Corio", "value": "C4908"},
              {"label": "Corryong", "value": "C4909"},
              {"label": "Cowes", "value": "C4910"},
              {"label": "Craigieburn", "value": "C4911"},
              {"label": "Cranbourne", "value": "C4912"},
              {"label": "Cranbourne East", "value": "C4913"},
              {"label": "Cranbourne North", "value": "C4914"},
              {"label": "Cranbourne South", "value": "C4915"},
              {"label": "Cranbourne West", "value": "C4916"},
              {"label": "Cremorne", "value": "C4917"},
              {"label": "Creswick", "value": "C4918"},
              {"label": "Crib Point", "value": "C4919"},
              {"label": "Croydon", "value": "C4920"},
              {"label": "Croydon Hills", "value": "C4921"},
              {"label": "Croydon North", "value": "C4922"},
              {"label": "Croydon South", "value": "C4923"},
              {"label": "Dallas", "value": "C4924"},
              {"label": "Dandenong", "value": "C4925"},
              {"label": "Dandenong North", "value": "C4926"},
              {"label": "Darebin", "value": "C4927"},
              {"label": "Darley", "value": "C4928"},
              {"label": "Daylesford", "value": "C4929"},
              {"label": "Deer Park", "value": "C4930"},
              {"label": "Delacombe", "value": "C4931"},
              {"label": "Delahey", "value": "C4932"},
              {"label": "Dennington", "value": "C4933"},
              {"label": "Derrimut", "value": "C4934"},
              {"label": "Devon Meadows", "value": "C4935"},
              {"label": "Diamond Creek", "value": "C4936"},
              {"label": "Diggers Rest", "value": "C4937"},
              {"label": "Dimboola", "value": "C4938"},
              {"label": "Dingley Village", "value": "C4939"},
              {"label": "Dinner Plain", "value": "C4940"},
              {"label": "Docklands", "value": "C4941"},
              {"label": "Donald", "value": "C4942"},
              {"label": "Doncaster", "value": "C4943"},
              {"label": "Doncaster East", "value": "C4944"},
              {"label": "Donvale", "value": "C4945"},
              {"label": "Doreen", "value": "C4946"},
              {"label": "Doveton", "value": "C4947"},
              {"label": "Dromana", "value": "C4948"},
              {"label": "Drouin", "value": "C4949"},
              {"label": "Drysdale", "value": "C4950"},
              {"label": "Eagle Point", "value": "C4951"},
              {"label": "Eaglehawk", "value": "C4952"},
              {"label": "Eaglemont", "value": "C4953"},
              {"label": "East Bairnsdale", "value": "C4954"},
              {"label": "East Bendigo", "value": "C4955"},
              {"label": "East Geelong", "value": "C4956"},
              {"label": "East Gippsland", "value": "C4957"},
              {"label": "East Melbourne", "value": "C4958"},
              {"label": "Echuca", "value": "C4959"},
              {"label": "Eden Park", "value": "C4960"},
              {"label": "Edithvale", "value": "C4961"},
              {"label": "Elliminyt", "value": "C4962"},
              {"label": "Elsternwick", "value": "C4963"},
              {"label": "Eltham", "value": "C4964"},
              {"label": "Eltham North", "value": "C4965"},
              {"label": "Elwood", "value": "C4966"},
              {"label": "Emerald", "value": "C4967"},
              {"label": "Endeavour Hills", "value": "C4968"},
              {"label": "Epping", "value": "C4969"},
              {"label": "Epsom", "value": "C4970"},
              {"label": "Essendon", "value": "C4971"},
              {"label": "Essendon North", "value": "C4972"},
              {"label": "Essendon West", "value": "C4973"},
              {"label": "Eumemmerring", "value": "C4974"},
              {"label": "Euroa", "value": "C4975"},
              {"label": "Eynesbury", "value": "C4976"},
              {"label": "Fairfield", "value": "C4977"},
              {"label": "Falls Creek", "value": "C4978"},
              {"label": "Fawkner", "value": "C4979"},
              {"label": "Ferntree Gully", "value": "C4980"},
              {"label": "Ferny Creek", "value": "C4981"},
              {"label": "Fitzroy", "value": "C4982"},
              {"label": "Fitzroy North", "value": "C4983"},
              {"label": "Flemington", "value": "C4984"},
              {"label": "Flora Hill", "value": "C4985"},
              {"label": "Footscray", "value": "C4986"},
              {"label": "Forest Hill", "value": "C4987"},
              {"label": "Foster", "value": "C4988"},
              {"label": "Frankston", "value": "C4989"},
              {"label": "Frankston East", "value": "C4990"},
              {"label": "Frankston North", "value": "C4991"},
              {"label": "Frankston South", "value": "C4992"},
              {"label": "Gannawarra", "value": "C4993"},
              {"label": "Garfield", "value": "C4994"},
              {"label": "Geelong", "value": "C4995"},
              {"label": "Geelong city centre", "value": "C4996"},
              {"label": "Geelong West", "value": "C4997"},
              {"label": "Gembrook", "value": "C4998"},
              {"label": "Gisborne", "value": "C4999"},
              {"label": "Gladstone Park", "value": "C5000"},
              {"label": "Glen Eira", "value": "C5001"},
              {"label": "Glen Huntly", "value": "C5002"},
              {"label": "Glen Iris", "value": "C5003"},
              {"label": "Glen Waverley", "value": "C5004"},
              {"label": "Glenelg", "value": "C5005"},
              {"label": "Glenferrie", "value": "C5006"},
              {"label": "Glengarry", "value": "C5007"},
              {"label": "Glenroy", "value": "C5008"},
              {"label": "Golden Plains", "value": "C5009"},
              {"label": "Golden Point", "value": "C5010"},
              {"label": "Golden Square", "value": "C5011"},
              {"label": "Gordon", "value": "C5012"},
              {"label": "Gowanbrae", "value": "C5013"},
              {"label": "Greater Bendigo", "value": "C5014"},
              {"label": "Greater Dandenong", "value": "C5015"},
              {"label": "Greater Geelong", "value": "C5016"},
              {"label": "Greater Shepparton", "value": "C5017"},
              {"label": "Greensborough", "value": "C5018"},
              {"label": "Greenvale", "value": "C5019"},
              {"label": "Grovedale", "value": "C5020"},
              {"label": "Haddon", "value": "C5021"},
              {"label": "Hadfield", "value": "C5022"},
              {"label": "Hallam", "value": "C5023"},
              {"label": "Hamilton", "value": "C5024"},
              {"label": "Hamlyn Heights", "value": "C5025"},
              {"label": "Hampton", "value": "C5026"},
              {"label": "Hampton East", "value": "C5027"},
              {"label": "Hampton Park", "value": "C5028"},
              {"label": "Hastings", "value": "C5029"},
              {"label": "Haven", "value": "C5030"},
              {"label": "Hawthorn", "value": "C5031"},
              {"label": "Hawthorn East", "value": "C5032"},
              {"label": "Hawthorn South", "value": "C5033"},
              {"label": "Hazelwood North", "value": "C5034"},
              {"label": "Healesville", "value": "C5035"},
              {"label": "Heathcote", "value": "C5036"},
              {"label": "Heatherton", "value": "C5037"},
              {"label": "Heathmont", "value": "C5038"},
              {"label": "Heidelberg", "value": "C5039"},
              {"label": "Heidelberg Heights", "value": "C5040"},
              {"label": "Heidelberg West", "value": "C5041"},
              {"label": "Hepburn", "value": "C5042"},
              {"label": "Herne Hill", "value": "C5043"},
              {"label": "Heyfield", "value": "C5044"},
              {"label": "Heywood", "value": "C5045"},
              {"label": "Highett", "value": "C5046"},
              {"label": "Highton", "value": "C5047"},
              {"label": "Hillside", "value": "C5048"},
              {"label": "Hindmarsh", "value": "C5049"},
              {"label": "Hmas Cerberus", "value": "C5050"},
              {"label": "Hobsons Bay", "value": "C5051"},
              {"label": "Hoppers Crossing", "value": "C5052"},
              {"label": "Horsham", "value": "C5053"},
              {"label": "Hotham Heights", "value": "C5054"},
              {"label": "Hughesdale", "value": "C5055"},
              {"label": "Hume", "value": "C5056"},
              {"label": "Huntingdale", "value": "C5057"},
              {"label": "Huntly", "value": "C5058"},
              {"label": "Hurstbridge", "value": "C5059"},
              {"label": "Indented Head", "value": "C5060"},
              {"label": "Indigo", "value": "C5061"},
              {"label": "Inverleigh", "value": "C5062"},
              {"label": "Inverloch", "value": "C5063"},
              {"label": "Invermay Park", "value": "C5064"},
              {"label": "Ironbark", "value": "C5065"},
              {"label": "Irymple", "value": "C5066"},
              {"label": "Ivanhoe", "value": "C5067"},
              {"label": "Ivanhoe East", "value": "C5068"},
              {"label": "Jacana", "value": "C5069"},
              {"label": "Jackass Flat", "value": "C5070"},
              {"label": "Jan Juc", "value": "C5071"},
              {"label": "Junction Village", "value": "C5072"},
              {"label": "Junortoun", "value": "C5073"},
              {"label": "Kalimna", "value": "C5074"},
              {"label": "Kallista", "value": "C5075"},
              {"label": "Kalorama", "value": "C5076"},
              {"label": "Kangaroo Flat", "value": "C5077"},
              {"label": "Kangaroo Ground", "value": "C5078"},
              {"label": "Kealba", "value": "C5079"},
              {"label": "Keilor", "value": "C5080"},
              {"label": "Keilor Downs", "value": "C5081"},
              {"label": "Keilor East", "value": "C5082"},
              {"label": "Keilor Lodge", "value": "C5083"},
              {"label": "Keilor Park", "value": "C5084"},
              {"label": "Kennington", "value": "C5085"},
              {"label": "Kensington", "value": "C5086"},
              {"label": "Kerang", "value": "C5087"},
              {"label": "Kew", "value": "C5088"},
              {"label": "Kew East", "value": "C5089"},
              {"label": "Keysborough", "value": "C5090"},
              {"label": "Kialla", "value": "C5091"},
              {"label": "Kilmore", "value": "C5092"},
              {"label": "Kilsyth", "value": "C5093"},
              {"label": "Kilsyth South", "value": "C5094"},
              {"label": "Kinglake", "value": "C5095"},
              {"label": "Kinglake West", "value": "C5096"},
              {"label": "Kings Park", "value": "C5097"},
              {"label": "Kingsbury", "value": "C5098"},
              {"label": "Kingston", "value": "C5099"},
              {"label": "Kingsville", "value": "C5100"},
              {"label": "Knox", "value": "C5101"},
              {"label": "Knoxfield", "value": "C5102"},
              {"label": "Koo-Wee-Rup", "value": "C5103"},
              {"label": "Koroit", "value": "C5104"},
              {"label": "Korumburra", "value": "C5105"},
              {"label": "Kurunjang", "value": "C5106"},
              {"label": "Kyabram", "value": "C5107"},
              {"label": "Kyneton", "value": "C5108"},
              {"label": "Lake Gardens", "value": "C5109"},
              {"label": "Lake Wendouree", "value": "C5110"},
              {"label": "Lakes Entrance", "value": "C5111"},
              {"label": "Lalor", "value": "C5112"},
              {"label": "Lancefield", "value": "C5113"},
              {"label": "Lang Lang", "value": "C5114"},
              {"label": "Langwarrin", "value": "C5115"},
              {"label": "Langwarrin South", "value": "C5116"},
              {"label": "Lara", "value": "C5117"},
              {"label": "Latrobe", "value": "C5118"},
              {"label": "Launching Place", "value": "C5119"},
              {"label": "Laverton", "value": "C5120"},
              {"label": "Leongatha", "value": "C5121"},
              {"label": "Leopold", "value": "C5122"},
              {"label": "Lilydale", "value": "C5123"},
              {"label": "Little River", "value": "C5124"},
              {"label": "Loddon", "value": "C5125"},
              {"label": "Long Gully", "value": "C5126"},
              {"label": "Longford", "value": "C5127"},
              {"label": "Longwarry", "value": "C5128"},
              {"label": "Lorne", "value": "C5129"},
              {"label": "Lovely Banks", "value": "C5130"},
              {"label": "Lower Plenty", "value": "C5131"},
              {"label": "Lucknow", "value": "C5132"},
              {"label": "Lynbrook", "value": "C5133"},
              {"label": "Lysterfield", "value": "C5134"},
              {"label": "Macedon", "value": "C5135"},
              {"label": "Macedon Ranges", "value": "C5136"},
              {"label": "Macleod", "value": "C5137"},
              {"label": "Maddingley", "value": "C5138"},
              {"label": "Maffra", "value": "C5139"},
              {"label": "Maiden Gully", "value": "C5140"},
              {"label": "Maidstone", "value": "C5141"},
              {"label": "Maldon", "value": "C5142"},
              {"label": "Mallacoota", "value": "C5143"},
              {"label": "Malvern", "value": "C5144"},
              {"label": "Malvern East", "value": "C5145"},
              {"label": "Manifold Heights", "value": "C5146"},
              {"label": "Manningham", "value": "C5147"},
              {"label": "Mansfield", "value": "C5148"},
              {"label": "Maribyrnong", "value": "C5149"},
              {"label": "Marong", "value": "C5150"},
              {"label": "Maroondah", "value": "C5151"},
              {"label": "Maryborough", "value": "C5152"},
              {"label": "McCrae", "value": "C5153"},
              {"label": "McKinnon", "value": "C5154"},
              {"label": "Meadow Heights", "value": "C5155"},
              {"label": "Melbourne", "value": "C5156"},
              {"label": "Melbourne City Centre", "value": "C5157"},
              {"label": "Melton", "value": "C5158"},
              {"label": "Melton South", "value": "C5159"},
              {"label": "Melton West", "value": "C5160"},
              {"label": "Mentone", "value": "C5161"},
              {"label": "Merbein", "value": "C5162"},
              {"label": "Mernda", "value": "C5163"},
              {"label": "Metung", "value": "C5164"},
              {"label": "Mickleham", "value": "C5165"},
              {"label": "Middle Park", "value": "C5166"},
              {"label": "Mildura", "value": "C5167"},
              {"label": "Mildura Shire", "value": "C5168"},
              {"label": "Mill Park", "value": "C5169"},
              {"label": "Millgrove", "value": "C5170"},
              {"label": "Miners Rest", "value": "C5171"},
              {"label": "Mirboo North", "value": "C5172"},
              {"label": "Mitcham", "value": "C5173"},
              {"label": "Mitchell", "value": "C5174"},
              {"label": "Moe", "value": "C5175"},
              {"label": "Moira", "value": "C5176"},
              {"label": "Monash", "value": "C5177"},
              {"label": "Monbulk", "value": "C5178"},
              {"label": "Mont Albert", "value": "C5179"},
              {"label": "Mont Albert North", "value": "C5180"},
              {"label": "Montmorency", "value": "C5181"},
              {"label": "Montrose", "value": "C5182"},
              {"label": "Moolap", "value": "C5183"},
              {"label": "Moonee Ponds", "value": "C5184"},
              {"label": "Moonee Valley", "value": "C5185"},
              {"label": "Moorabbin", "value": "C5186"},
              {"label": "Moorabool", "value": "C5187"},
              {"label": "Moorooduc", "value": "C5188"},
              {"label": "Mooroolbark", "value": "C5189"},
              {"label": "Mooroopna", "value": "C5190"},
              {"label": "Mordialloc", "value": "C5191"},
              {"label": "Moreland", "value": "C5192"},
              {"label": "Mornington", "value": "C5193"},
              {"label": "Mornington Peninsula", "value": "C5194"},
              {"label": "Mortlake", "value": "C5195"},
              {"label": "Morwell", "value": "C5196"},
              {"label": "Mount Alexander", "value": "C5197"},
              {"label": "Mount Buller", "value": "C5198"},
              {"label": "Mount Clear", "value": "C5199"},
              {"label": "Mount Dandenong", "value": "C5200"},
              {"label": "Mount Duneed", "value": "C5201"},
              {"label": "Mount Eliza", "value": "C5202"},
              {"label": "Mount Evelyn", "value": "C5203"},
              {"label": "Mount Helen", "value": "C5204"},
              {"label": "Mount Macedon", "value": "C5205"},
              {"label": "Mount Martha", "value": "C5206"},
              {"label": "Mount Pleasant", "value": "C5207"},
              {"label": "Mount Waverley", "value": "C5208"},
              {"label": "Moyne", "value": "C5209"},
              {"label": "Mulgrave", "value": "C5210"},
              {"label": "Murrindindi", "value": "C5211"},
              {"label": "Murrumbeena", "value": "C5212"},
              {"label": "Myrtleford", "value": "C5213"},
              {"label": "Nagambie", "value": "C5214"},
              {"label": "Narre Warren", "value": "C5215"},
              {"label": "Narre Warren North", "value": "C5216"},
              {"label": "Narre Warren South", "value": "C5217"},
              {"label": "Nathalia", "value": "C5218"},
              {"label": "Neerim South", "value": "C5219"},
              {"label": "New Gisborne", "value": "C5220"},
              {"label": "Newborough", "value": "C5221"},
              {"label": "Newcomb", "value": "C5222"},
              {"label": "Newington", "value": "C5223"},
              {"label": "Newport", "value": "C5224"},
              {"label": "Newtown", "value": "C5225"},
              {"label": "Nhill", "value": "C5226"},
              {"label": "Nichols Point", "value": "C5227"},
              {"label": "Nicholson", "value": "C5228"},
              {"label": "Niddrie", "value": "C5229"},
              {"label": "Nillumbik", "value": "C5230"},
              {"label": "Noble Park", "value": "C5231"},
              {"label": "Noble Park North", "value": "C5232"},
              {"label": "Norlane", "value": "C5233"},
              {"label": "North Bendigo", "value": "C5234"},
              {"label": "North Brighton", "value": "C5235"},
              {"label": "North Geelong", "value": "C5236"},
              {"label": "North Melbourne", "value": "C5237"},
              {"label": "North Warrandyte", "value": "C5238"},
              {"label": "North Wonthaggi", "value": "C5239"},
              {"label": "Northcote", "value": "C5240"},
              {"label": "Northern Grampians", "value": "C5241"},
              {"label": "Notting Hill", "value": "C5242"},
              {"label": "Numurkah", "value": "C5243"},
              {"label": "Nunawading", "value": "C5244"},
              {"label": "Nyora", "value": "C5245"},
              {"label": "Oak Park", "value": "C5246"},
              {"label": "Oakleigh", "value": "C5247"},
              {"label": "Oakleigh East", "value": "C5248"},
              {"label": "Oakleigh South", "value": "C5249"},
              {"label": "Ocean Grove", "value": "C5250"},
              {"label": "Officer", "value": "C5251"},
              {"label": "Olinda", "value": "C5252"},
              {"label": "Orbost", "value": "C5253"},
              {"label": "Ormond", "value": "C5254"},
              {"label": "Ouyen", "value": "C5255"},
              {"label": "Pakenham", "value": "C5256"},
              {"label": "Pakenham Upper", "value": "C5257"},
              {"label": "Panton Hill", "value": "C5258"},
              {"label": "Park Orchards", "value": "C5259"},
              {"label": "Parkdale", "value": "C5260"},
              {"label": "Parkville", "value": "C5261"},
              {"label": "Pascoe Vale", "value": "C5262"},
              {"label": "Pascoe Vale South", "value": "C5263"},
              {"label": "Patterson Lakes", "value": "C5264"},
              {"label": "Paynesville", "value": "C5265"},
              {"label": "Pearcedale", "value": "C5266"},
              {"label": "Phillip Island", "value": "C5267"},
              {"label": "Plenty", "value": "C5268"},
              {"label": "Plumpton", "value": "C5269"},
              {"label": "Point Cook", "value": "C5270"},
              {"label": "Point Lonsdale", "value": "C5271"},
              {"label": "Port Fairy", "value": "C5272"},
              {"label": "Port Melbourne", "value": "C5273"},
              {"label": "Port Phillip", "value": "C5274"},
              {"label": "Portarlington", "value": "C5275"},
              {"label": "Portland", "value": "C5276"},
              {"label": "Prahran", "value": "C5277"},
              {"label": "Preston", "value": "C5278"},
              {"label": "Princes Hill", "value": "C5279"},
              {"label": "Puckapunyal", "value": "C5280"},
              {"label": "Pyrenees", "value": "C5281"},
              {"label": "Quarry Hill", "value": "C5282"},
              {"label": "Queenscliff", "value": "C5283"},
              {"label": "Queenscliffe", "value": "C5284"},
              {"label": "Ravenhall", "value": "C5285"},
              {"label": "Red Cliffs", "value": "C5286"},
              {"label": "Redan", "value": "C5287"},
              {"label": "Research", "value": "C5288"},
              {"label": "Reservoir", "value": "C5289"},
              {"label": "Richmond", "value": "C5290"},
              {"label": "Ringwood", "value": "C5291"},
              {"label": "Ringwood East", "value": "C5292"},
              {"label": "Ringwood North", "value": "C5293"},
              {"label": "Ripponlea", "value": "C5294"},
              {"label": "Robinvale", "value": "C5295"},
              {"label": "Rochester", "value": "C5296"},
              {"label": "Rockbank", "value": "C5297"},
              {"label": "Romsey", "value": "C5298"},
              {"label": "Rosanna", "value": "C5299"},
              {"label": "Rosebud", "value": "C5300"},
              {"label": "Rosebud West", "value": "C5301"},
              {"label": "Rosedale", "value": "C5302"},
              {"label": "Ross Creek", "value": "C5303"},
              {"label": "Rowville", "value": "C5304"},
              {"label": "Roxburgh Park", "value": "C5305"},
              {"label": "Rushworth", "value": "C5306"},
              {"label": "Rutherglen", "value": "C5307"},
              {"label": "Rye", "value": "C5308"},
              {"label": "Safety Beach", "value": "C5309"},
              {"label": "Saint Albans", "value": "C5310"},
              {"label": "Saint Andrews", "value": "C5311"},
              {"label": "Saint Andrews Beach", "value": "C5312"},
              {"label": "Saint Helena", "value": "C5313"},
              {"label": "Saint Kilda", "value": "C5314"},
              {"label": "Saint Leonards", "value": "C5315"},
              {"label": "Sale", "value": "C5316"},
              {"label": "San Remo", "value": "C5317"},
              {"label": "Sandhurst", "value": "C5318"},
              {"label": "Sandringham", "value": "C5319"},
              {"label": "Sassafras", "value": "C5320"},
              {"label": "Scoresby", "value": "C5321"},
              {"label": "Seabrook", "value": "C5322"},
              {"label": "Seaford", "value": "C5323"},
              {"label": "Seaholme", "value": "C5324"},
              {"label": "Sebastopol", "value": "C5325"},
              {"label": "Seddon", "value": "C5326"},
              {"label": "Selby", "value": "C5327"},
              {"label": "Seville", "value": "C5328"},
              {"label": "Seymour", "value": "C5329"},
              {"label": "Shepparton", "value": "C5330"},
              {"label": "Shepparton East", "value": "C5331"},
              {"label": "Silvan", "value": "C5332"},
              {"label": "Skye", "value": "C5333"},
              {"label": "Smythes Creek", "value": "C5334"},
              {"label": "Smythesdale", "value": "C5335"},
              {"label": "Soldiers Hill", "value": "C5336"},
              {"label": "Somers", "value": "C5337"},
              {"label": "Somerville", "value": "C5338"},
              {"label": "Sorrento", "value": "C5339"},
              {"label": "South Gippsland", "value": "C5340"},
              {"label": "South Kingsville", "value": "C5341"},
              {"label": "South Melbourne", "value": "C5342"},
              {"label": "South Morang", "value": "C5343"},
              {"label": "South Yarra", "value": "C5344"},
              {"label": "Southbank", "value": "C5345"},
              {"label": "Southern Grampians", "value": "C5346"},
              {"label": "Spotswood", "value": "C5347"},
              {"label": "Springvale", "value": "C5348"},
              {"label": "Springvale South", "value": "C5349"},
              {"label": "St Albans Park", "value": "C5350"},
              {"label": "St Helena", "value": "C5351"},
              {"label": "St Kilda East", "value": "C5352"},
              {"label": "St Kilda West", "value": "C5353"},
              {"label": "Stawell", "value": "C5354"},
              {"label": "Stonnington", "value": "C5355"},
              {"label": "Stratford", "value": "C5356"},
              {"label": "Strathbogie", "value": "C5357"},
              {"label": "Strathdale", "value": "C5358"},
              {"label": "Strathfieldsaye", "value": "C5359"},
              {"label": "Strathmerton", "value": "C5360"},
              {"label": "Strathmore", "value": "C5361"},
              {"label": "Sunbury", "value": "C5362"},
              {"label": "Sunshine", "value": "C5363"},
              {"label": "Sunshine North", "value": "C5364"},
              {"label": "Sunshine West", "value": "C5365"},
              {"label": "Surf Coast", "value": "C5366"},
              {"label": "Surrey Hills", "value": "C5367"},
              {"label": "Swan Hill", "value": "C5368"},
              {"label": "Sydenham", "value": "C5369"},
              {"label": "Tallangatta", "value": "C5370"},
              {"label": "Tarneit", "value": "C5371"},
              {"label": "Tatura", "value": "C5372"},
              {"label": "Taylors Hill", "value": "C5373"},
              {"label": "Taylors Lakes", "value": "C5374"},
              {"label": "Tecoma", "value": "C5375"},
              {"label": "Teesdale", "value": "C5376"},
              {"label": "Templestowe", "value": "C5377"},
              {"label": "Templestowe Lower", "value": "C5378"},
              {"label": "Terang", "value": "C5379"},
              {"label": "The Basin", "value": "C5380"},
              {"label": "The Patch", "value": "C5381"},
              {"label": "Thomastown", "value": "C5382"},
              {"label": "Thomson", "value": "C5383"},
              {"label": "Thornbury", "value": "C5384"},
              {"label": "Timboon", "value": "C5385"},
              {"label": "Tongala", "value": "C5386"},
              {"label": "Tooradin", "value": "C5387"},
              {"label": "Toorak", "value": "C5388"},
              {"label": "Tootgarook", "value": "C5389"},
              {"label": "Torquay", "value": "C5390"},
              {"label": "Towong", "value": "C5391"},
              {"label": "Trafalgar", "value": "C5392"},
              {"label": "Traralgon", "value": "C5393"},
              {"label": "Travancore", "value": "C5394"},
              {"label": "Trentham", "value": "C5395"},
              {"label": "Truganina", "value": "C5396"},
              {"label": "Tullamarine", "value": "C5397"},
              {"label": "Tyabb", "value": "C5398"},
              {"label": "Upwey", "value": "C5399"},
              {"label": "Vermont", "value": "C5400"},
              {"label": "Vermont South", "value": "C5401"},
              {"label": "Viewbank", "value": "C5402"},
              {"label": "Wahgunyah", "value": "C5403"},
              {"label": "Wallan", "value": "C5404"},
              {"label": "Wallington", "value": "C5405"},
              {"label": "Wandana Heights", "value": "C5406"},
              {"label": "Wandin North", "value": "C5407"},
              {"label": "Wandong", "value": "C5408"},
              {"label": "Wangaratta", "value": "C5409"},
              {"label": "Wantirna", "value": "C5410"},
              {"label": "Wantirna South", "value": "C5411"},
              {"label": "Warburton", "value": "C5412"},
              {"label": "Warracknabeal", "value": "C5413"},
              {"label": "Warragul", "value": "C5414"},
              {"label": "Warrandyte", "value": "C5415"},
              {"label": "Warranwood", "value": "C5416"},
              {"label": "Warrnambool", "value": "C5417"},
              {"label": "Waterways", "value": "C5418"},
              {"label": "Watsonia", "value": "C5419"},
              {"label": "Watsonia North", "value": "C5420"},
              {"label": "Wattleglen", "value": "C5421"},
              {"label": "Waurn Ponds", "value": "C5422"},
              {"label": "Wellington", "value": "C5423"},
              {"label": "Wendouree", "value": "C5424"},
              {"label": "Werribee", "value": "C5425"},
              {"label": "Werribee South", "value": "C5426"},
              {"label": "Wesburn", "value": "C5427"},
              {"label": "West Footscray", "value": "C5428"},
              {"label": "West Melbourne", "value": "C5429"},
              {"label": "West Wimmera", "value": "C5430"},
              {"label": "West Wodonga", "value": "C5431"},
              {"label": "Westmeadows", "value": "C5432"},
              {"label": "Wheelers Hill", "value": "C5433"},
              {"label": "White Hills", "value": "C5434"},
              {"label": "Whitehorse", "value": "C5435"},
              {"label": "Whittington", "value": "C5436"},
              {"label": "Whittlesea", "value": "C5437"},
              {"label": "Williams Landing", "value": "C5438"},
              {"label": "Williamstown", "value": "C5439"},
              {"label": "Williamstown North", "value": "C5440"},
              {"label": "Winchelsea", "value": "C5441"},
              {"label": "Windsor", "value": "C5442"},
              {"label": "Wodonga", "value": "C5443"},
              {"label": "Wollert", "value": "C5444"},
              {"label": "Wonga Park", "value": "C5445"},
              {"label": "Wonthaggi", "value": "C5446"},
              {"label": "Woodend", "value": "C5447"},
              {"label": "Woori Yallock", "value": "C5448"},
              {"label": "Wurruk", "value": "C5449"},
              {"label": "Wy Yung", "value": "C5450"},
              {"label": "Wyndham", "value": "C5451"},
              {"label": "Wyndham Vale", "value": "C5452"},
              {"label": "Yackandandah", "value": "C5453"},
              {"label": "Yallambie", "value": "C5454"},
              {"label": "Yallourn North", "value": "C5455"},
              {"label": "Yarra", "value": "C5456"},
              {"label": "Yarra Glen", "value": "C5457"},
              {"label": "Yarra Junction", "value": "C5458"},
              {"label": "Yarra Ranges", "value": "C5459"},
              {"label": "Yarragon", "value": "C5460"},
              {"label": "Yarram", "value": "C5461"},
              {"label": "Yarrambat", "value": "C5462"},
              {"label": "Yarraville", "value": "C5463"},
              {"label": "Yarrawonga", "value": "C5464"},
              {"label": "Yarriambiack", "value": "C5465"},
              {"label": "Yea", "value": "C5466"},
              {"label": "Rabat", "value": "C65927"},
              {"label": "Victoria", "value": "C65928"}],
 "Videm Municipality": [{"label": "Videm pri Ptuju", "value": "C107850"}],
 "Vidin Province": [{"label": "Belogradchik", "value": "C16302"},
                    {"label": "Boynitsa", "value": "C16303"},
                    {"label": "Bregovo", "value": "C16304"},
                    {"label": "Chuprene", "value": "C16305"},
                    {"label": "Dimovo", "value": "C16306"},
                    {"label": "Drenovets", "value": "C16307"},
                    {"label": "Dunavtsi", "value": "C16308"},
                    {"label": "Gramada", "value": "C16309"},
                    {"label": "Kula", "value": "C16310"},
                    {"label": "Makresh", "value": "C16311"},
                    {"label": "Novo Selo", "value": "C16312"},
                    {"label": "Obshtina Belogradchik", "value": "C16313"},
                    {"label": "Obshtina Boynitsa", "value": "C16314"},
                    {"label": "Obshtina Dimovo", "value": "C16315"},
                    {"label": "Obshtina Gramada", "value": "C16316"},
                    {"label": "Obshtina Kula", "value": "C16317"},
                    {"label": "Obshtina Ruzhintsi", "value": "C16318"},
                    {"label": "Obshtina Vidin", "value": "C16319"},
                    {"label": "Ruzhintsi", "value": "C16320"},
                    {"label": "Vidin", "value": "C16321"}],
 "Vienna": [{"label": "Donaustadt", "value": "C8258"},
            {"label": "Favoriten", "value": "C8259"},
            {"label": "Floridsdorf", "value": "C8260"},
            {"label": "Hernals", "value": "C8261"},
            {"label": "Hietzing", "value": "C8262"},
            {"label": "Innere Stadt", "value": "C8263"},
            {"label": "Meidling", "value": "C8264"},
            {"label": "Ottakring", "value": "C8265"},
            {"label": "Simmering", "value": "C8266"},
            {"label": "Vienna", "value": "C8267"}],
 "Vientiane Prefecture": [{"label": "Vientiane", "value": "C64831"}],
 "Vientiane Province": [{"label": "Muang Phon-Hong", "value": "C64832"},
                        {"label": "Vangviang", "value": "C64833"}],
 "Viesite Municipality": [{"label": "Viesite", "value": "C64960"}],
 "Vieux Fort Quarter": [{"label": "Augier", "value": "C106172"},
                        {"label": "Beane Field", "value": "C106173"},
                        {"label": "Beausejour", "value": "C106174"},
                        {"label": "Belle Vue", "value": "C106175"},
                        {"label": "Black Bay", "value": "C106176"},
                        {"label": "BrucevilleShanty Town", "value": "C106177"},
                        {"label": "Cacoa", "value": "C106178"},
                        {"label": "Cantonement", "value": "C106179"},
                        {"label": "Carierre", "value": "C106180"},
                        {"label": "Catin", "value": "C106181"},
                        {"label": "Cocoa Dan", "value": "C106182"},
                        {"label": "Coolie Town", "value": "C106183"},
                        {"label": "De Mailly", "value": "C106184"},
                        {"label": "Derierre Bois", "value": "C106185"},
                        {"label": "Derierre Morne", "value": "C106186"},
                        {"label": "DocamelLa Resource", "value": "C106187"},
                        {"label": "Eau PiquantSt Urbain", "value": "C106188"},
                        {"label": "En Bamboo", "value": "C106189"},
                        {"label": "Esperance", "value": "C106190"},
                        {"label": "Fond Capeche", "value": "C106191"},
                        {"label": "Fond Sabot", "value": "C106192"},
                        {"label": "Grace", "value": "C106193"},
                        {"label": "Hewanorra Orchard", "value": "C106194"},
                        {"label": "Hope Estate", "value": "C106195"},
                        {"label": "Industrial Estate", "value": "C106196"},
                        {"label": "Joyeux", "value": "C106197"},
                        {"label": "La Resource", "value": "C106198"},
                        {"label": "La Retraite", "value": "C106199"},
                        {"label": "La TourneyCedar Heights",
                         "value": "C106200"},
                        {"label": "Maganier", "value": "C106201"},
                        {"label": "Morne Cayenne", "value": "C106202"},
                        {"label": "Morne Vert", "value": "C106203"},
                        {"label": "Moule A Chique", "value": "C106204"},
                        {"label": "Obrier", "value": "C106205"},
                        {"label": "Pierrot", "value": "C106206"},
                        {"label": "Plut", "value": "C106207"},
                        {"label": "Pomme", "value": "C106208"},
                        {"label": "St JudeS Highway", "value": "C106209"},
                        {"label": "Tete MorneMorne Andrew", "value": "C106210"},
                        {"label": "Town", "value": "C106211"},
                        {"label": "Vieux Fort", "value": "C106212"},
                        {"label": "Vieux FortLaborie Highway",
                         "value": "C106213"},
                        {"label": "Vige", "value": "C106214"},
                        {"label": "Westall GroupThe Mangue",
                         "value": "C106215"}],
 "Vila Real": [{"label": "Alijo", "value": "C92017"},
               {"label": "Boticas", "value": "C92018"},
               {"label": "Chaves", "value": "C92019"},
               {"label": "Favaios", "value": "C92020"},
               {"label": "Godim", "value": "C92021"},
               {"label": "Mesao Frio", "value": "C92022"},
               {"label": "Mondim de Basto", "value": "C92023"},
               {"label": "Montalegre", "value": "C92024"},
               {"label": "Murca", "value": "C92025"},
               {"label": "Peso da Regua", "value": "C92026"},
               {"label": "Ribeira de Pena", "value": "C92027"},
               {"label": "Sabrosa", "value": "C92028"},
               {"label": "Santa Cruz", "value": "C92029"},
               {"label": "Santa Marta de Penaguiao", "value": "C92030"},
               {"label": "Sobreira", "value": "C92031"},
               {"label": "Valpacos", "value": "C92032"},
               {"label": "Vila Pouca de Aguiar", "value": "C92033"},
               {"label": "Vila Real", "value": "C92034"},
               {"label": "Vilela", "value": "C92035"}],
 "Vilaka Municipality": [{"label": "Vilaka", "value": "C64961"}],
 "Vilani Municipality": [{"label": "Vilani", "value": "C64962"}],
 "Viljandi County": [{"label": "Abja-Paluoja", "value": "C24645"},
                     {"label": "Karksi-Nuia", "value": "C24646"},
                     {"label": "Moisakula", "value": "C24647"},
                     {"label": "Suure-Jaani", "value": "C24648"},
                     {"label": "Viiratsi", "value": "C24649"},
                     {"label": "Viljandi", "value": "C24650"},
                     {"label": "Viljandi vald", "value": "C24651"},
                     {"label": "Vohma", "value": "C24652"}],
 "Villa Clara Province": [{"label": "Caibarien", "value": "C21840"},
                          {"label": "Calabazar de Sagua", "value": "C21841"},
                          {"label": "Camajuani", "value": "C21842"},
                          {"label": "Cifuentes", "value": "C21843"},
                          {"label": "Corralillo", "value": "C21844"},
                          {"label": "Encrucijada", "value": "C21845"},
                          {"label": "Esperanza", "value": "C21846"},
                          {"label": "Isabela de Sagua", "value": "C21847"},
                          {"label": "Manicaragua", "value": "C21848"},
                          {"label": "Municipio de Placetas", "value": "C21849"},
                          {"label": "Municipio de Santa Clara",
                           "value": "C21850"},
                          {"label": "Placetas", "value": "C21851"},
                          {"label": "Quemado de Guines", "value": "C21852"},
                          {"label": "Rancho Veloz", "value": "C21853"},
                          {"label": "Ranchuelo", "value": "C21854"},
                          {"label": "Sagua la Grande", "value": "C21855"},
                          {"label": "Santa Clara", "value": "C21856"},
                          {"label": "Santo Domingo", "value": "C21857"}],
 "Vilnius City Municipality": [{"label": "Aukstadvaris", "value": "C65182"},
                               {"label": "Baltoji Voke", "value": "C65183"},
                               {"label": "Eisiskes", "value": "C65184"},
                               {"label": "Elektrenai", "value": "C65185"},
                               {"label": "Fabijoniskes", "value": "C65186"},
                               {"label": "Grigiskes", "value": "C65187"},
                               {"label": "Justiniskes", "value": "C65188"},
                               {"label": "Lazdynai", "value": "C65189"},
                               {"label": "Lentvaris", "value": "C65190"},
                               {"label": "Naujamiestis", "value": "C65191"},
                               {"label": "Nemencine", "value": "C65192"},
                               {"label": "Pabrade", "value": "C65193"},
                               {"label": "Pasilaiciai", "value": "C65194"},
                               {"label": "Pilaite", "value": "C65195"},
                               {"label": "Rasos", "value": "C65196"},
                               {"label": "Rudiskes", "value": "C65197"},
                               {"label": "Salcininkai", "value": "C65198"},
                               {"label": "Senieji Trakai", "value": "C65199"},
                               {"label": "Seskine", "value": "C65200"},
                               {"label": "Sirvintos", "value": "C65201"},
                               {"label": "Skaidiskes", "value": "C65202"},
                               {"label": "Svencioneliai", "value": "C65203"},
                               {"label": "Svencionys", "value": "C65204"},
                               {"label": "Trakai", "value": "C65205"},
                               {"label": "Ukmerge", "value": "C65206"},
                               {"label": "Vievis", "value": "C65207"},
                               {"label": "Vilkpede", "value": "C65208"},
                               {"label": "Vilnius", "value": "C65209"},
                               {"label": "Vilnius District Municipality",
                                "value": "C65210"}],
 "Vinh Long": [{"label": "Huyen Long Ho", "value": "C147707"},
               {"label": "Huyen Mang Thit", "value": "C147708"},
               {"label": "Huyen Tam Binh", "value": "C147709"},
               {"label": "Huyen Tra On", "value": "C147710"},
               {"label": "Huyen Vung Liem", "value": "C147711"},
               {"label": "Vinh Long", "value": "C147712"}],
 "Vinh Phuc": [{"label": "Huyen Binh Xuyen", "value": "C147713"},
               {"label": "Huyen Tam Duong", "value": "C147714"},
               {"label": "Huyen Tam Dao", "value": "C147715"},
               {"label": "Huyen Yen Lac", "value": "C147716"},
               {"label": "Vinh Yen", "value": "C147717"}],
 "Vinica Municipality": [{"label": "Blatec", "value": "C65539"},
                         {"label": "Gradec", "value": "C65540"},
                         {"label": "Vinica", "value": "C65541"}],
 "Vinnytska oblast": [{"label": "Bar", "value": "C122823"},
                      {"label": "Barskiy Rayon", "value": "C122824"},
                      {"label": "Bershad", "value": "C122825"},
                      {"label": "Brailiv", "value": "C122826"},
                      {"label": "Bratslav", "value": "C122827"},
                      {"label": "Chechelnyk", "value": "C122828"},
                      {"label": "Chernivetskyy Rayon", "value": "C122829"},
                      {"label": "Chernivtsi", "value": "C122830"},
                      {"label": "Dashiv", "value": "C122831"},
                      {"label": "Haisyn", "value": "C122832"},
                      {"label": "Illintsi", "value": "C122833"},
                      {"label": "Kalynivka", "value": "C122834"},
                      {"label": "Khmilnyk", "value": "C122835"},
                      {"label": "Klembivka", "value": "C122836"},
                      {"label": "Kopayhorod", "value": "C122837"},
                      {"label": "Kozyatyn", "value": "C122838"},
                      {"label": "Kryzhopil", "value": "C122839"},
                      {"label": "Ladyzhyn", "value": "C122840"},
                      {"label": "Lityn", "value": "C122841"},
                      {"label": "Litynskyy Rayon", "value": "C122842"},
                      {"label": "Lypovetskyy Rayon", "value": "C122843"},
                      {"label": "Mohyliv-Podilskyi", "value": "C122844"},
                      {"label": "Murafa", "value": "C122845"},
                      {"label": "Murovani Kurylivtsi", "value": "C122846"},
                      {"label": "Nemyriv", "value": "C122847"},
                      {"label": "Nova Pryluka", "value": "C122848"},
                      {"label": "Obodivka", "value": "C122849"},
                      {"label": "Orativ", "value": "C122850"},
                      {"label": "Pavlivka", "value": "C122851"},
                      {"label": "Pohrebyshche", "value": "C122852"},
                      {"label": "Pohrebyshchenskyy Rayon", "value": "C122853"},
                      {"label": "Serebriya", "value": "C122854"},
                      {"label": "Sharhorod", "value": "C122855"},
                      {"label": "Sobolivka", "value": "C122856"},
                      {"label": "Sutysky", "value": "C122857"},
                      {"label": "Teplyk", "value": "C122858"},
                      {"label": "Tomashpil", "value": "C122859"},
                      {"label": "Torkanivka", "value": "C122860"},
                      {"label": "Tsybulevka", "value": "C122861"},
                      {"label": "Tulchyn", "value": "C122862"},
                      {"label": "Turbiv", "value": "C122863"},
                      {"label": "Tyvriv", "value": "C122864"},
                      {"label": "Ulaniv", "value": "C122865"},
                      {"label": "Vapnyarka", "value": "C122866"},
                      {"label": "Vendychany", "value": "C122867"},
                      {"label": "Vinnitskiy Rayon", "value": "C122868"},
                      {"label": "Vinnytsia", "value": "C122869"},
                      {"label": "Viytivka", "value": "C122870"},
                      {"label": "Voronovytsya", "value": "C122871"},
                      {"label": "Yampil", "value": "C122872"},
                      {"label": "Zhmerynka", "value": "C122873"}],
 "Vipava Municipality": [{"label": "Vipava", "value": "C107851"}],
 "Viqueque Municipality": [{"label": "Lacluta", "value": "C24087"},
                           {"label": "Ossu", "value": "C24088"},
                           {"label": "Uatocarabau", "value": "C24089"},
                           {"label": "Uatolari", "value": "C24090"},
                           {"label": "Viqueque", "value": "C24091"}],
 "Virginia": [{"label": "Abingdon", "value": "C145101"},
              {"label": "Accomac", "value": "C145102"},
              {"label": "Accomack County", "value": "C145103"},
              {"label": "Adwolf", "value": "C145104"},
              {"label": "Albemarle County", "value": "C145105"},
              {"label": "Alexandria", "value": "C145106"},
              {"label": "Alleghany County", "value": "C145107"},
              {"label": "Altavista", "value": "C145108"},
              {"label": "Amelia County", "value": "C145109"},
              {"label": "Amelia Court House", "value": "C145110"},
              {"label": "Amherst", "value": "C145111"},
              {"label": "Amherst County", "value": "C145112"},
              {"label": "Annandale", "value": "C145113"},
              {"label": "Appalachia", "value": "C145114"},
              {"label": "Apple Mountain Lake", "value": "C145115"},
              {"label": "Appomattox", "value": "C145116"},
              {"label": "Appomattox County", "value": "C145117"},
              {"label": "Aquia Harbour", "value": "C145118"},
              {"label": "Arlington", "value": "C145119"},
              {"label": "Arlington County", "value": "C145120"},
              {"label": "Ashburn", "value": "C145121"},
              {"label": "Ashland", "value": "C145122"},
              {"label": "Atkins", "value": "C145123"},
              {"label": "Augusta County", "value": "C145124"},
              {"label": "Baileys Crossroads", "value": "C145125"},
              {"label": "Bassett", "value": "C145126"},
              {"label": "Basye", "value": "C145127"},
              {"label": "Bath County", "value": "C145128"},
              {"label": "Bealeton", "value": "C145129"},
              {"label": "Bedford", "value": "C145130"},
              {"label": "Bedford County", "value": "C145131"},
              {"label": "Belle Haven", "value": "C145132"},
              {"label": "Bellwood", "value": "C145133"},
              {"label": "Belmont", "value": "C145134"},
              {"label": "Belmont Estates", "value": "C145135"},
              {"label": "Bensley", "value": "C145136"},
              {"label": "Berryville", "value": "C145137"},
              {"label": "Big Stone Gap", "value": "C145138"},
              {"label": "Blacksburg", "value": "C145139"},
              {"label": "Blackstone", "value": "C145140"},
              {"label": "Bland", "value": "C145141"},
              {"label": "Bland County", "value": "C145142"},
              {"label": "Blue Ridge", "value": "C145143"},
              {"label": "Bluefield", "value": "C145144"},
              {"label": "Bon Air", "value": "C145145"},
              {"label": "Boswells Corner", "value": "C145146"},
              {"label": "Botetourt County", "value": "C145147"},
              {"label": "Bowling Green", "value": "C145148"},
              {"label": "Boydton", "value": "C145149"},
              {"label": "Bracey", "value": "C145150"},
              {"label": "Brambleton", "value": "C145151"},
              {"label": "Brandermill", "value": "C145152"},
              {"label": "Bridgewater", "value": "C145153"},
              {"label": "Brightwood", "value": "C145154"},
              {"label": "Bristol", "value": "C145155"},
              {"label": "Broadlands", "value": "C145156"},
              {"label": "Broadway", "value": "C145157"},
              {"label": "Brookneal", "value": "C145158"},
              {"label": "Brunswick County", "value": "C145159"},
              {"label": "Buchanan", "value": "C145160"},
              {"label": "Buchanan County", "value": "C145161"},
              {"label": "Buckhall", "value": "C145162"},
              {"label": "Buckingham", "value": "C145163"},
              {"label": "Buckingham County", "value": "C145164"},
              {"label": "Buena Vista", "value": "C145165"},
              {"label": "Bull Run", "value": "C145166"},
              {"label": "Bull Run Mountain Estates", "value": "C145167"},
              {"label": "Burke", "value": "C145168"},
              {"label": "Campbell County", "value": "C145169"},
              {"label": "Cana", "value": "C145170"},
              {"label": "Cape Charles", "value": "C145171"},
              {"label": "Captains Cove", "value": "C145172"},
              {"label": "Caroline County", "value": "C145173"},
              {"label": "Carroll County", "value": "C145174"},
              {"label": "Carrollton", "value": "C145175"},
              {"label": "Castlewood", "value": "C145176"},
              {"label": "Cave Spring", "value": "C145177"},
              {"label": "Cedar Bluff", "value": "C145178"},
              {"label": "Central Garage", "value": "C145179"},
              {"label": "Centreville", "value": "C145180"},
              {"label": "Chamberlayne", "value": "C145181"},
              {"label": "Chantilly", "value": "C145182"},
              {"label": "Charles City", "value": "C145183"},
              {"label": "Charles City County", "value": "C145184"},
              {"label": "Charlotte County", "value": "C145185"},
              {"label": "Charlotte Court House", "value": "C145186"},
              {"label": "Charlottesville", "value": "C145187"},
              {"label": "Chase City", "value": "C145188"},
              {"label": "Chatham", "value": "C145189"},
              {"label": "Chatmoss", "value": "C145190"},
              {"label": "Cherry Hill", "value": "C145191"},
              {"label": "Chesapeake", "value": "C145192"},
              {"label": "Chester", "value": "C145193"},
              {"label": "Chesterfield County", "value": "C145194"},
              {"label": "Chesterfield Court House", "value": "C145195"},
              {"label": "Chilhowie", "value": "C145196"},
              {"label": "Chincoteague", "value": "C145197"},
              {"label": "Christiansburg", "value": "C145198"},
              {"label": "City of Alexandria", "value": "C145199"},
              {"label": "City of Bedford", "value": "C145200"},
              {"label": "City of Bristol", "value": "C145201"},
              {"label": "City of Buena Vista", "value": "C145202"},
              {"label": "City of Charlottesville", "value": "C145203"},
              {"label": "City of Chesapeake", "value": "C145204"},
              {"label": "City of Colonial Heights", "value": "C145205"},
              {"label": "City of Covington", "value": "C145206"},
              {"label": "City of Danville", "value": "C145207"},
              {"label": "City of Emporia", "value": "C145208"},
              {"label": "City of Fairfax", "value": "C145209"},
              {"label": "City of Falls Church", "value": "C145210"},
              {"label": "City of Franklin", "value": "C145211"},
              {"label": "City of Fredericksburg", "value": "C145212"},
              {"label": "City of Galax", "value": "C145213"},
              {"label": "City of Hampton", "value": "C145214"},
              {"label": "City of Harrisonburg", "value": "C145215"},
              {"label": "City of Hopewell", "value": "C145216"},
              {"label": "City of Lexington", "value": "C145217"},
              {"label": "City of Lynchburg", "value": "C145218"},
              {"label": "City of Manassas", "value": "C145219"},
              {"label": "City of Manassas Park", "value": "C145220"},
              {"label": "City of Martinsville", "value": "C145221"},
              {"label": "City of Newport News", "value": "C145222"},
              {"label": "City of Norfolk", "value": "C145223"},
              {"label": "City of Norton", "value": "C145224"},
              {"label": "City of Petersburg", "value": "C145225"},
              {"label": "City of Poquoson", "value": "C145226"},
              {"label": "City of Portsmouth", "value": "C145227"},
              {"label": "City of Radford", "value": "C145228"},
              {"label": "City of Richmond", "value": "C145229"},
              {"label": "City of Roanoke", "value": "C145230"},
              {"label": "City of Salem", "value": "C145231"},
              {"label": "City of Staunton", "value": "C145232"},
              {"label": "City of Suffolk", "value": "C145233"},
              {"label": "City of Virginia Beach", "value": "C145234"},
              {"label": "City of Waynesboro", "value": "C145235"},
              {"label": "City of Williamsburg", "value": "C145236"},
              {"label": "City of Winchester", "value": "C145237"},
              {"label": "Clarke County", "value": "C145238"},
              {"label": "Clarksville", "value": "C145239"},
              {"label": "Claypool Hill", "value": "C145240"},
              {"label": "Clifton Forge", "value": "C145241"},
              {"label": "Clintwood", "value": "C145242"},
              {"label": "Cloverdale", "value": "C145243"},
              {"label": "Coeburn", "value": "C145244"},
              {"label": "Collinsville", "value": "C145245"},
              {"label": "Colonial Beach", "value": "C145246"},
              {"label": "Colonial Heights", "value": "C145247"},
              {"label": "Concord", "value": "C145248"},
              {"label": "Countryside", "value": "C145249"},
              {"label": "Courtland", "value": "C145250"},
              {"label": "Covington", "value": "C145251"},
              {"label": "Craig County", "value": "C145252"},
              {"label": "Crewe", "value": "C145253"},
              {"label": "Crimora", "value": "C145254"},
              {"label": "Crozet", "value": "C145255"},
              {"label": "Culpeper", "value": "C145256"},
              {"label": "Culpeper County", "value": "C145257"},
              {"label": "Cumberland", "value": "C145258"},
              {"label": "Cumberland County", "value": "C145259"},
              {"label": "Dahlgren", "value": "C145260"},
              {"label": "Dale City", "value": "C145261"},
              {"label": "Daleville", "value": "C145262"},
              {"label": "Danville", "value": "C145263"},
              {"label": "Dayton", "value": "C145264"},
              {"label": "Deltaville", "value": "C145265"},
              {"label": "Dickenson County", "value": "C145266"},
              {"label": "Dinwiddie County", "value": "C145267"},
              {"label": "Dooms", "value": "C145268"},
              {"label": "Dranesville", "value": "C145269"},
              {"label": "Dryden", "value": "C145270"},
              {"label": "Dublin", "value": "C145271"},
              {"label": "Dulles Town Center", "value": "C145272"},
              {"label": "Dumbarton", "value": "C145273"},
              {"label": "Dumfries", "value": "C145274"},
              {"label": "Dunn Loring", "value": "C145275"},
              {"label": "East Hampton", "value": "C145276"},
              {"label": "East Highland Park", "value": "C145277"},
              {"label": "East Lexington", "value": "C145278"},
              {"label": "Eastville", "value": "C145279"},
              {"label": "Edinburg", "value": "C145280"},
              {"label": "Elkton", "value": "C145281"},
              {"label": "Emory", "value": "C145282"},
              {"label": "Emporia", "value": "C145283"},
              {"label": "Enon", "value": "C145284"},
              {"label": "Essex County", "value": "C145285"},
              {"label": "Ettrick", "value": "C145286"},
              {"label": "Exmore", "value": "C145287"},
              {"label": "Fairfax", "value": "C145288"},
              {"label": "Fairfax County", "value": "C145289"},
              {"label": "Fairfax Station", "value": "C145290"},
              {"label": "Fairlawn", "value": "C145291"},
              {"label": "Falls Church", "value": "C145292"},
              {"label": "Falmouth", "value": "C145293"},
              {"label": "Farmville", "value": "C145294"},
              {"label": "Fauquier County", "value": "C145295"},
              {"label": "Ferrum", "value": "C145296"},
              {"label": "Fincastle", "value": "C145297"},
              {"label": "Fishersville", "value": "C145298"},
              {"label": "Floris", "value": "C145299"},
              {"label": "Floyd", "value": "C145300"},
              {"label": "Floyd County", "value": "C145301"},
              {"label": "Fluvanna County", "value": "C145302"},
              {"label": "Forest", "value": "C145303"},
              {"label": "Fort Belvoir", "value": "C145304"},
              {"label": "Fort Hunt", "value": "C145305"},
              {"label": "Fort Lee", "value": "C145306"},
              {"label": "Franconia", "value": "C145307"},
              {"label": "Franklin", "value": "C145308"},
              {"label": "Franklin County", "value": "C145309"},
              {"label": "Frederick County", "value": "C145310"},
              {"label": "Fredericksburg", "value": "C145311"},
              {"label": "Front Royal", "value": "C145312"},
              {"label": "Gainesville", "value": "C145313"},
              {"label": "Galax", "value": "C145314"},
              {"label": "Gate City", "value": "C145315"},
              {"label": "Giles County", "value": "C145316"},
              {"label": "Glade Spring", "value": "C145317"},
              {"label": "Glasgow", "value": "C145318"},
              {"label": "Glen Allen", "value": "C145319"},
              {"label": "Gloucester County", "value": "C145320"},
              {"label": "Gloucester Courthouse", "value": "C145321"},
              {"label": "Gloucester Point", "value": "C145322"},
              {"label": "Goochland", "value": "C145323"},
              {"label": "Goochland County", "value": "C145324"},
              {"label": "Gordonsville", "value": "C145325"},
              {"label": "Grayson County", "value": "C145326"},
              {"label": "Great Falls", "value": "C145327"},
              {"label": "Greenbriar", "value": "C145328"},
              {"label": "Greene County", "value": "C145329"},
              {"label": "Greensville County", "value": "C145330"},
              {"label": "Gretna", "value": "C145331"},
              {"label": "Grottoes", "value": "C145332"},
              {"label": "Groveton", "value": "C145333"},
              {"label": "Grundy", "value": "C145334"},
              {"label": "Halifax", "value": "C145335"},
              {"label": "Halifax County", "value": "C145336"},
              {"label": "Hampden Sydney", "value": "C145337"},
              {"label": "Hampton", "value": "C145338"},
              {"label": "Hanover", "value": "C145339"},
              {"label": "Hanover County", "value": "C145340"},
              {"label": "Harrisonburg", "value": "C145341"},
              {"label": "Hayfield", "value": "C145342"},
              {"label": "Haymarket", "value": "C145343"},
              {"label": "Heathsville", "value": "C145344"},
              {"label": "Henrico County", "value": "C145345"},
              {"label": "Henry County", "value": "C145346"},
              {"label": "Henry Fork", "value": "C145347"},
              {"label": "Herndon", "value": "C145348"},
              {"label": "Highland County", "value": "C145349"},
              {"label": "Highland Springs", "value": "C145350"},
              {"label": "Hillsville", "value": "C145351"},
              {"label": "Hollins", "value": "C145352"},
              {"label": "Hollymead", "value": "C145353"},
              {"label": "Honaker", "value": "C145354"},
              {"label": "Hopewell", "value": "C145355"},
              {"label": "Horse Pasture", "value": "C145356"},
              {"label": "Huntington", "value": "C145357"},
              {"label": "Hurt", "value": "C145358"},
              {"label": "Hybla Valley", "value": "C145359"},
              {"label": "Idylwood", "value": "C145360"},
              {"label": "Independence", "value": "C145361"},
              {"label": "Independent Hill", "value": "C145362"},
              {"label": "Isle of Wight County", "value": "C145363"},
              {"label": "James City County", "value": "C145364"},
              {"label": "Jefferson", "value": "C145365"},
              {"label": "Jolivue", "value": "C145366"},
              {"label": "Jonesville", "value": "C145367"},
              {"label": "Kenbridge", "value": "C145368"},
              {"label": "Kilmarnock", "value": "C145369"},
              {"label": "King and Queen County", "value": "C145370"},
              {"label": "King and Queen Court House", "value": "C145371"},
              {"label": "King George", "value": "C145372"},
              {"label": "King George County", "value": "C145373"},
              {"label": "King William", "value": "C145374"},
              {"label": "King William County", "value": "C145375"},
              {"label": "Kings Park", "value": "C145376"},
              {"label": "Kings Park West", "value": "C145377"},
              {"label": "Lake Barcroft", "value": "C145378"},
              {"label": "Lake Monticello", "value": "C145379"},
              {"label": "Lake Ridge", "value": "C145380"},
              {"label": "Lakeside", "value": "C145381"},
              {"label": "Lancaster County", "value": "C145382"},
              {"label": "Laurel", "value": "C145383"},
              {"label": "Laurel Hill", "value": "C145384"},
              {"label": "Lawrenceville", "value": "C145385"},
              {"label": "Laymantown", "value": "C145386"},
              {"label": "Lebanon", "value": "C145387"},
              {"label": "Lee County", "value": "C145388"},
              {"label": "Leesburg", "value": "C145389"},
              {"label": "Lexington", "value": "C145390"},
              {"label": "Lincolnia", "value": "C145391"},
              {"label": "Linton Hall", "value": "C145392"},
              {"label": "Loch Lomond", "value": "C145393"},
              {"label": "Lorton", "value": "C145394"},
              {"label": "Loudoun County", "value": "C145395"},
              {"label": "Loudoun Valley Estates", "value": "C145396"},
              {"label": "Louisa", "value": "C145397"},
              {"label": "Louisa County", "value": "C145398"},
              {"label": "Lovettsville", "value": "C145399"},
              {"label": "Lovingston", "value": "C145400"},
              {"label": "Lowes Island", "value": "C145401"},
              {"label": "Lunenburg", "value": "C145402"},
              {"label": "Lunenburg County", "value": "C145403"},
              {"label": "Luray", "value": "C145404"},
              {"label": "Lynchburg", "value": "C145405"},
              {"label": "Lyndhurst", "value": "C145406"},
              {"label": "Madison", "value": "C145407"},
              {"label": "Madison County", "value": "C145408"},
              {"label": "Madison Heights", "value": "C145409"},
              {"label": "Manassas", "value": "C145410"},
              {"label": "Manassas Park", "value": "C145411"},
              {"label": "Mantua", "value": "C145412"},
              {"label": "Marion", "value": "C145413"},
              {"label": "Marshall", "value": "C145414"},
              {"label": "Martinsville", "value": "C145415"},
              {"label": "Massanetta Springs", "value": "C145416"},
              {"label": "Massanutten", "value": "C145417"},
              {"label": "Mathews", "value": "C145418"},
              {"label": "Mathews County", "value": "C145419"},
              {"label": "Matoaca", "value": "C145420"},
              {"label": "McLean", "value": "C145421"},
              {"label": "Meadowbrook", "value": "C145422"},
              {"label": "Mechanicsville", "value": "C145423"},
              {"label": "Mecklenburg County", "value": "C145424"},
              {"label": "Merrifield", "value": "C145425"},
              {"label": "Merrimac", "value": "C145426"},
              {"label": "Middlesex County", "value": "C145427"},
              {"label": "Middletown", "value": "C145428"},
              {"label": "Montclair", "value": "C145429"},
              {"label": "Monterey", "value": "C145430"},
              {"label": "Montgomery County", "value": "C145431"},
              {"label": "Montrose", "value": "C145432"},
              {"label": "Montross", "value": "C145433"},
              {"label": "Motley", "value": "C145434"},
              {"label": "Mount Hermon", "value": "C145435"},
              {"label": "Mount Jackson", "value": "C145436"},
              {"label": "Mount Vernon", "value": "C145437"},
              {"label": "Mountain Road", "value": "C145438"},
              {"label": "Narrows", "value": "C145439"},
              {"label": "Nellysford", "value": "C145440"},
              {"label": "Nelson County", "value": "C145441"},
              {"label": "New Baltimore", "value": "C145442"},
              {"label": "New Castle", "value": "C145443"},
              {"label": "New Kent", "value": "C145444"},
              {"label": "New Kent County", "value": "C145445"},
              {"label": "New Market", "value": "C145446"},
              {"label": "Newington", "value": "C145447"},
              {"label": "Newport News", "value": "C145448"},
              {"label": "Nokesville", "value": "C145449"},
              {"label": "Norfolk", "value": "C145450"},
              {"label": "North Shore", "value": "C145451"},
              {"label": "North Springfield", "value": "C145452"},
              {"label": "Northampton County", "value": "C145453"},
              {"label": "Northumberland County", "value": "C145454"},
              {"label": "Norton", "value": "C145455"},
              {"label": "Nottoway County", "value": "C145456"},
              {"label": "Oak Grove", "value": "C145457"},
              {"label": "Oak Hill", "value": "C145458"},
              {"label": "Oakton", "value": "C145459"},
              {"label": "Occoquan", "value": "C145460"},
              {"label": "Onancock", "value": "C145461"},
              {"label": "Orange", "value": "C145462"},
              {"label": "Orange County", "value": "C145463"},
              {"label": "Page County", "value": "C145464"},
              {"label": "Palmyra", "value": "C145465"},
              {"label": "Pannill Fork", "value": "C145466"},
              {"label": "Pantops", "value": "C145467"},
              {"label": "Passapatanzy", "value": "C145468"},
              {"label": "Patrick County", "value": "C145469"},
              {"label": "Patrick Springs", "value": "C145470"},
              {"label": "Pearisburg", "value": "C145471"},
              {"label": "Pembroke", "value": "C145472"},
              {"label": "Pennington Gap", "value": "C145473"},
              {"label": "Petersburg", "value": "C145474"},
              {"label": "Pimmit Hills", "value": "C145475"},
              {"label": "Pittsylvania County", "value": "C145476"},
              {"label": "Plum Creek", "value": "C145477"},
              {"label": "Poquoson", "value": "C145478"},
              {"label": "Portsmouth", "value": "C145479"},
              {"label": "Portsmouth Heights", "value": "C145480"},
              {"label": "Potomac Mills", "value": "C145481"},
              {"label": "Powhatan County", "value": "C145482"},
              {"label": "Prices Fork", "value": "C145483"},
              {"label": "Prince Edward County", "value": "C145484"},
              {"label": "Prince George", "value": "C145485"},
              {"label": "Prince George County", "value": "C145486"},
              {"label": "Prince William County", "value": "C145487"},
              {"label": "Pulaski", "value": "C145488"},
              {"label": "Pulaski County", "value": "C145489"},
              {"label": "Purcellville", "value": "C145490"},
              {"label": "Quantico Station", "value": "C145491"},
              {"label": "Radford", "value": "C145492"},
              {"label": "Rappahannock County", "value": "C145493"},
              {"label": "Raven", "value": "C145494"},
              {"label": "Ravensworth", "value": "C145495"},
              {"label": "Reston", "value": "C145496"},
              {"label": "Richlands", "value": "C145497"},
              {"label": "Richmond", "value": "C145498"},
              {"label": "Richmond County", "value": "C145499"},
              {"label": "Roanoke", "value": "C145500"},
              {"label": "Roanoke County", "value": "C145501"},
              {"label": "Rockbridge County", "value": "C145502"},
              {"label": "Rockingham County", "value": "C145503"},
              {"label": "Rocky Mount", "value": "C145504"},
              {"label": "Rose Hill", "value": "C145505"},
              {"label": "Rosslyn", "value": "C145506"},
              {"label": "Ruckersville", "value": "C145507"},
              {"label": "Rural Retreat", "value": "C145508"},
              {"label": "Rushmere", "value": "C145509"},
              {"label": "Russell County", "value": "C145510"},
              {"label": "Rustburg", "value": "C145511"},
              {"label": "Salem", "value": "C145512"},
              {"label": "Saltville", "value": "C145513"},
              {"label": "Saluda", "value": "C145514"},
              {"label": "Sandston", "value": "C145515"},
              {"label": "Scott County", "value": "C145516"},
              {"label": "Seven Corners", "value": "C145517"},
              {"label": "Shawnee Land", "value": "C145518"},
              {"label": "Shawsville", "value": "C145519"},
              {"label": "Shenandoah", "value": "C145520"},
              {"label": "Shenandoah County", "value": "C145521"},
              {"label": "Shenandoah Farms", "value": "C145522"},
              {"label": "Short Pump", "value": "C145523"},
              {"label": "Smithfield", "value": "C145524"},
              {"label": "Smyth County", "value": "C145525"},
              {"label": "South Boston", "value": "C145526"},
              {"label": "South Hill", "value": "C145527"},
              {"label": "South Riding", "value": "C145528"},
              {"label": "South Suffolk", "value": "C145529"},
              {"label": "Southampton County", "value": "C145530"},
              {"label": "Southern Gateway", "value": "C145531"},
              {"label": "Spotsylvania County", "value": "C145532"},
              {"label": "Spotsylvania Courthouse", "value": "C145533"},
              {"label": "Springfield", "value": "C145534"},
              {"label": "Springville", "value": "C145535"},
              {"label": "Stafford", "value": "C145536"},
              {"label": "Stafford County", "value": "C145537"},
              {"label": "Stanardsville", "value": "C145538"},
              {"label": "Stanley", "value": "C145539"},
              {"label": "Stanleytown", "value": "C145540"},
              {"label": "Staunton", "value": "C145541"},
              {"label": "Stephens City", "value": "C145542"},
              {"label": "Sterling", "value": "C145543"},
              {"label": "Strasburg", "value": "C145544"},
              {"label": "Stuart", "value": "C145545"},
              {"label": "Stuarts Draft", "value": "C145546"},
              {"label": "Sudley", "value": "C145547"},
              {"label": "Suffolk", "value": "C145548"},
              {"label": "Sugarland Run", "value": "C145549"},
              {"label": "Surry", "value": "C145550"},
              {"label": "Surry County", "value": "C145551"},
              {"label": "Sussex", "value": "C145552"},
              {"label": "Sussex County", "value": "C145553"},
              {"label": "Tappahannock", "value": "C145554"},
              {"label": "Tazewell", "value": "C145555"},
              {"label": "Tazewell County", "value": "C145556"},
              {"label": "Timberlake", "value": "C145557"},
              {"label": "Timberville", "value": "C145558"},
              {"label": "Triangle", "value": "C145559"},
              {"label": "Tuckahoe", "value": "C145560"},
              {"label": "Twin Lakes", "value": "C145561"},
              {"label": "Tysons Corner", "value": "C145562"},
              {"label": "Union Hall", "value": "C145563"},
              {"label": "University Center", "value": "C145564"},
              {"label": "Verona", "value": "C145565"},
              {"label": "Victoria", "value": "C145566"},
              {"label": "Vienna", "value": "C145567"},
              {"label": "Vinton", "value": "C145568"},
              {"label": "Virginia Beach", "value": "C145569"},
              {"label": "Warm Springs", "value": "C145570"},
              {"label": "Warren County", "value": "C145571"},
              {"label": "Warrenton", "value": "C145572"},
              {"label": "Warsaw", "value": "C145573"},
              {"label": "Washington", "value": "C145574"},
              {"label": "Washington County", "value": "C145575"},
              {"label": "Wattsville", "value": "C145576"},
              {"label": "Waverly", "value": "C145577"},
              {"label": "Waynesboro", "value": "C145578"},
              {"label": "Weber City", "value": "C145579"},
              {"label": "West Falls Church", "value": "C145580"},
              {"label": "West Gate", "value": "C145581"},
              {"label": "West Lynchburg", "value": "C145582"},
              {"label": "West Point", "value": "C145583"},
              {"label": "West Springfield", "value": "C145584"},
              {"label": "Westmoreland County", "value": "C145585"},
              {"label": "Weyers Cave", "value": "C145586"},
              {"label": "Williamsburg", "value": "C145587"},
              {"label": "Winchester", "value": "C145588"},
              {"label": "Windsor", "value": "C145589"},
              {"label": "Wise", "value": "C145590"},
              {"label": "Wise County", "value": "C145591"},
              {"label": "Wolf Trap", "value": "C145592"},
              {"label": "Woodbridge", "value": "C145593"},
              {"label": "Woodburn", "value": "C145594"},
              {"label": "Woodlake", "value": "C145595"},
              {"label": "Woodlawn", "value": "C145596"},
              {"label": "Woodstock", "value": "C145597"},
              {"label": "Wyndham", "value": "C145598"},
              {"label": "Wythe County", "value": "C145599"},
              {"label": "Wytheville", "value": "C145600"},
              {"label": "York County", "value": "C145601"},
              {"label": "Yorkshire", "value": "C145602"},
              {"label": "Yorktown", "value": "C145603"}],
 "Virovitica-Podravina": [{"label": "Cacinci", "value": "C21504"},
                          {"label": "Cadavica", "value": "C21505"},
                          {"label": "Crnac", "value": "C21506"},
                          {"label": "Grad Orahovica", "value": "C21507"},
                          {"label": "Grad Slatina", "value": "C21508"},
                          {"label": "Grad Virovitica", "value": "C21509"},
                          {"label": "Gradina", "value": "C21510"},
                          {"label": "Mikleus", "value": "C21511"},
                          {"label": "Nova Bukovica", "value": "C21512"},
                          {"label": "Orahovica", "value": "C21513"},
                          {"label": "Pitomaca", "value": "C21514"},
                          {"label": "Rezovac", "value": "C21515"},
                          {"label": "Slatina", "value": "C21516"},
                          {"label": "Sopje", "value": "C21517"},
                          {"label": "Spisic Bukovica", "value": "C21518"},
                          {"label": "Suhopolje", "value": "C21519"},
                          {"label": "Virovitica", "value": "C21520"},
                          {"label": "Vocin", "value": "C21521"},
                          {"label": "Zdenci", "value": "C21522"}],
 "Viseu": [{"label": "Abraveses", "value": "C92036"},
           {"label": "Armamar", "value": "C92037"},
           {"label": "Cabanas de Viriato", "value": "C92038"},
           {"label": "Campo", "value": "C92039"},
           {"label": "Campo de Besteiros", "value": "C92040"},
           {"label": "Canas de Senhorim", "value": "C92041"},
           {"label": "Carregal do Sal", "value": "C92042"},
           {"label": "Castro Daire", "value": "C92043"},
           {"label": "Cinfaes", "value": "C92044"},
           {"label": "Ervedosa do Douro", "value": "C92045"},
           {"label": "Lamego", "value": "C92046"},
           {"label": "Mangualde", "value": "C92047"},
           {"label": "Moimenta da Beira", "value": "C92048"},
           {"label": "Mortagua", "value": "C92049"},
           {"label": "Nelas", "value": "C92050"},
           {"label": "Oliveira de Frades", "value": "C92051"},
           {"label": "Penalva do Castelo", "value": "C92052"},
           {"label": "Penedono", "value": "C92053"},
           {"label": "Resende", "value": "C92054"},
           {"label": "Rio de Loba", "value": "C92055"},
           {"label": "Santa Comba Dao", "value": "C92056"},
           {"label": "Sao Joao da Pesqueira", "value": "C92057"},
           {"label": "Sao Joao de Areias", "value": "C92058"},
           {"label": "Sao Pedro do Sul", "value": "C92059"},
           {"label": "Satao", "value": "C92060"},
           {"label": "Sernancelhe", "value": "C92061"},
           {"label": "Tabuaco", "value": "C92062"},
           {"label": "Tarouca", "value": "C92063"},
           {"label": "Tondela", "value": "C92064"},
           {"label": "Treixedo", "value": "C92065"},
           {"label": "Vila Nova de Paiva", "value": "C92066"},
           {"label": "Viseu", "value": "C92067"},
           {"label": "Vouzela", "value": "C92068"}],
 "Vitanje Municipality": [{"label": "Vitanje", "value": "C107852"}],
 "Vitebsk Region": [{"label": "Balbasava", "value": "C8983"},
                    {"label": "Baran", "value": "C8984"},
                    {"label": "Braslaw", "value": "C8985"},
                    {"label": "Braslawski Rayon", "value": "C8986"},
                    {"label": "Byahoml", "value": "C8987"},
                    {"label": "Byeshankovitski Rayon", "value": "C8988"},
                    {"label": "Chashniki", "value": "C8989"},
                    {"label": "Chashnitski Rayon", "value": "C8990"},
                    {"label": "Dokshytski Rayon", "value": "C8991"},
                    {"label": "Dokshytsy", "value": "C8992"},
                    {"label": "Druya", "value": "C8993"},
                    {"label": "Dubrowna", "value": "C8994"},
                    {"label": "Dzisna", "value": "C8995"},
                    {"label": "Haradok", "value": "C8996"},
                    {"label": "Haradotski Rayon", "value": "C8997"},
                    {"label": "Hlybokaye", "value": "C8998"},
                    {"label": "Hlybotski Rayon", "value": "C8999"},
                    {"label": "Kokhanava", "value": "C9000"},
                    {"label": "Konstantinovo", "value": "C9001"},
                    {"label": "Lyepyel", "value": "C9002"},
                    {"label": "Lyepyelski Rayon", "value": "C9003"},
                    {"label": "Lyntupy", "value": "C9004"},
                    {"label": "Lyozna", "value": "C9005"},
                    {"label": "Lyoznyenski Rayon", "value": "C9006"},
                    {"label": "Mosar", "value": "C9007"},
                    {"label": "Myorski Rayon", "value": "C9008"},
                    {"label": "Myory", "value": "C9009"},
                    {"label": "Navapolatsk", "value": "C9010"},
                    {"label": "Novolukoml", "value": "C9011"},
                    {"label": "Orsha", "value": "C9012"},
                    {"label": "Osveya", "value": "C9013"},
                    {"label": "Pastavy", "value": "C9014"},
                    {"label": "Pastawski Rayon", "value": "C9015"},
                    {"label": "Polatsk", "value": "C9016"},
                    {"label": "Polatski Rayon", "value": "C9017"},
                    {"label": "Rasonski Rayon", "value": "C9018"},
                    {"label": "Rasony", "value": "C9019"},
                    {"label": "Sharkawshchyna", "value": "C9020"},
                    {"label": "Sharkawshchynski Rayon", "value": "C9021"},
                    {"label": "Shumilinski Rayon", "value": "C9022"},
                    {"label": "Syanno", "value": "C9023"},
                    {"label": "Syennyenski Rayon", "value": "C9024"},
                    {"label": "Talachyn", "value": "C9025"},
                    {"label": "Ushachy", "value": "C9026"},
                    {"label": "Vidzy", "value": "C9027"},
                    {"label": "Vitebsk", "value": "C9028"},
                    {"label": "Vyerkhnyadzvinsk", "value": "C9029"},
                    {"label": "Vyerkhnyadzvinski Rayon", "value": "C9030"}],
 "Vladimir Oblast": [{"label": "Aleksandrov", "value": "C105256"},
                     {"label": "Aleksandrovskiy Rayon", "value": "C105257"},
                     {"label": "Andreyevo", "value": "C105258"},
                     {"label": "Anopino", "value": "C105259"},
                     {"label": "Arsaki", "value": "C105260"},
                     {"label": "Balakirevo", "value": "C105261"},
                     {"label": "Bavleny", "value": "C105262"},
                     {"label": "Belaya Rechka", "value": "C105263"},
                     {"label": "Bogolyubovo", "value": "C105264"},
                     {"label": "Dobryatino", "value": "C105265"},
                     {"label": "Galitsy", "value": "C105266"},
                     {"label": "Golovino", "value": "C105267"},
                     {"label": "Gorokhovets", "value": "C105268"},
                     {"label": "Gus-Khrustalnyy", "value": "C105269"},
                     {"label": "Gusevskiy", "value": "C105270"},
                     {"label": "Imeni Vorovskogo", "value": "C105271"},
                     {"label": "Ivanishchi", "value": "C105272"},
                     {"label": "Kameshkovo", "value": "C105273"},
                     {"label": "Karabanovo", "value": "C105274"},
                     {"label": "Kideksha", "value": "C105275"},
                     {"label": "Kirzhach", "value": "C105276"},
                     {"label": "Kolchugino", "value": "C105277"},
                     {"label": "Kolchuginskiy Rayon", "value": "C105278"},
                     {"label": "Kommunar", "value": "C105279"},
                     {"label": "Kosterevo", "value": "C105280"},
                     {"label": "Kovrov", "value": "C105281"},
                     {"label": "Krasnaya Gorbatka", "value": "C105282"},
                     {"label": "Krasnyy Bogatyr", "value": "C105283"},
                     {"label": "Krasnyy Oktyabr", "value": "C105284"},
                     {"label": "Kurlovo", "value": "C105285"},
                     {"label": "Lakinsk", "value": "C105286"},
                     {"label": "Luknovo", "value": "C105287"},
                     {"label": "Melekhovo", "value": "C105288"},
                     {"label": "Melenki", "value": "C105289"},
                     {"label": "Melenkovskiy Rayon", "value": "C105290"},
                     {"label": "Mezinovskiy", "value": "C105291"},
                     {"label": "Mstera", "value": "C105292"},
                     {"label": "Murom", "value": "C105293"},
                     {"label": "Muromskiy Rayon", "value": "C105294"},
                     {"label": "Muromtsevo", "value": "C105295"},
                     {"label": "Nikologory", "value": "C105296"},
                     {"label": "Novki", "value": "C105297"},
                     {"label": "Novovyazniki", "value": "C105298"},
                     {"label": "Oktyabrskiy", "value": "C105299"},
                     {"label": "Orgtrud", "value": "C105300"},
                     {"label": "Petushinskiy Rayon", "value": "C105301"},
                     {"label": "Petushki", "value": "C105302"},
                     {"label": "Pokrov", "value": "C105303"},
                     {"label": "Raduzhnyy", "value": "C105304"},
                     {"label": "Sobinka", "value": "C105305"},
                     {"label": "Sobinskiy Rayon", "value": "C105306"},
                     {"label": "Stavrovo", "value": "C105307"},
                     {"label": "Stepantsevo", "value": "C105308"},
                     {"label": "Strunino", "value": "C105309"},
                     {"label": "Sudogda", "value": "C105310"},
                     {"label": "Sudogodskiy Rayon", "value": "C105311"},
                     {"label": "Suzdal", "value": "C105312"},
                     {"label": "Suzdalskiy Rayon", "value": "C105313"},
                     {"label": "Urshelskiy", "value": "C105314"},
                     {"label": "Velikodvorskiy", "value": "C105315"},
                     {"label": "Vladimir", "value": "C105316"},
                     {"label": "Vorsha", "value": "C105317"},
                     {"label": "Vyazniki", "value": "C105318"},
                     {"label": "Yuryev-Polskiy", "value": "C105319"},
                     {"label": "Yuryev-Polskiy Rayon", "value": "C105320"},
                     {"label": "Zolotkovo", "value": "C105321"}],
 "Vlore County": [{"label": "Bashkia Finiq", "value": "C209"},
                  {"label": "Bashkia Himare", "value": "C210"},
                  {"label": "Bashkia Konispol", "value": "C211"},
                  {"label": "Bashkia Selenice", "value": "C212"},
                  {"label": "Bashkia Vlore", "value": "C213"},
                  {"label": "Delvine", "value": "C214"},
                  {"label": "Himare", "value": "C215"},
                  {"label": "Konispol", "value": "C216"},
                  {"label": "Ksamil", "value": "C217"},
                  {"label": "Orikum", "value": "C218"},
                  {"label": "Rrethi i Delvines", "value": "C219"},
                  {"label": "Sarande", "value": "C220"},
                  {"label": "Selenice", "value": "C221"},
                  {"label": "Vlore", "value": "C222"}],
 "Vodice Municipality": [{"label": "Vodice", "value": "C107853"}],
 "Vojnik Municipality": [{"label": "Vojnik", "value": "C107854"}],
 "Vojvodina": [{"label": "Adorjan", "value": "C107038"},
               {"label": "Aleksandrovo", "value": "C107039"},
               {"label": "Alibunar", "value": "C107040"},
               {"label": "Apatin", "value": "C107041"},
               {"label": "Aradac", "value": "C107042"},
               {"label": "Backa Palanka", "value": "C107043"},
               {"label": "Backa Topola", "value": "C107044"},
               {"label": "Backi Breg", "value": "C107045"},
               {"label": "Backi Petrovac", "value": "C107046"},
               {"label": "Backo Gradiste", "value": "C107047"},
               {"label": "Backo Petrovo Selo", "value": "C107048"},
               {"label": "Banatska Topola", "value": "C107049"},
               {"label": "Banatski Despotovac", "value": "C107050"},
               {"label": "Banatski Dvor", "value": "C107051"},
               {"label": "Banatski Karlovac", "value": "C107052"},
               {"label": "Banatsko Karadordevo", "value": "C107053"},
               {"label": "Banatsko Veliko Selo", "value": "C107054"},
               {"label": "Baranda", "value": "C107055"},
               {"label": "Barice", "value": "C107056"},
               {"label": "Basaid", "value": "C107057"},
               {"label": "Becej", "value": "C107058"},
               {"label": "Bela Crkva", "value": "C107059"},
               {"label": "Belegis", "value": "C107060"},
               {"label": "Belo Blato", "value": "C107061"},
               {"label": "Beska", "value": "C107062"},
               {"label": "Bocar", "value": "C107063"},
               {"label": "Bogojevo", "value": "C107064"},
               {"label": "Boka", "value": "C107065"},
               {"label": "Bosut", "value": "C107066"},
               {"label": "Botos", "value": "C107067"},
               {"label": "Budanovci", "value": "C107068"},
               {"label": "Celarevo", "value": "C107069"},
               {"label": "Centa", "value": "C107070"},
               {"label": "Cestereg", "value": "C107071"},
               {"label": "Coka", "value": "C107072"},
               {"label": "Cortanovci", "value": "C107073"},
               {"label": "Crepaja", "value": "C107074"},
               {"label": "Curug", "value": "C107075"},
               {"label": "Debeljaca", "value": "C107076"},
               {"label": "Despotovo", "value": "C107077"},
               {"label": "Dobrica", "value": "C107078"},
               {"label": "Doroslovo", "value": "C107079"},
               {"label": "Durdevo", "value": "C107080"},
               {"label": "Ecka", "value": "C107081"},
               {"label": "Elemir", "value": "C107082"},
               {"label": "Farkazdin", "value": "C107083"},
               {"label": "Gakovo", "value": "C107084"},
               {"label": "Gardinovci", "value": "C107085"},
               {"label": "Glozan", "value": "C107086"},
               {"label": "Golubinci", "value": "C107087"},
               {"label": "Gornji Breg", "value": "C107088"},
               {"label": "Grabovci", "value": "C107089"},
               {"label": "Gudurica", "value": "C107090"},
               {"label": "Hajducica", "value": "C107091"},
               {"label": "Hetin", "value": "C107092"},
               {"label": "Hrtkovci", "value": "C107093"},
               {"label": "Idvor", "value": "C107094"},
               {"label": "Ilandza", "value": "C107095"},
               {"label": "Indija", "value": "C107096"},
               {"label": "Irig", "value": "C107097"},
               {"label": "Izbiste", "value": "C107098"},
               {"label": "Janosik", "value": "C107099"},
               {"label": "Jarak", "value": "C107100"},
               {"label": "Jarkovac", "value": "C107101"},
               {"label": "Jasa Tomic", "value": "C107102"},
               {"label": "Jazovo", "value": "C107103"},
               {"label": "Jermenovci", "value": "C107104"},
               {"label": "Kanjiza", "value": "C107105"},
               {"label": "Kikinda", "value": "C107106"},
               {"label": "Kisac", "value": "C107107"},
               {"label": "Klek", "value": "C107108"},
               {"label": "Klenak", "value": "C107109"},
               {"label": "Knicanin", "value": "C107110"},
               {"label": "Kolut", "value": "C107111"},
               {"label": "Konak", "value": "C107112"},
               {"label": "Kovacica", "value": "C107113"},
               {"label": "Kovilj", "value": "C107114"},
               {"label": "Kovin", "value": "C107115"},
               {"label": "Kozjak", "value": "C107116"},
               {"label": "Krajisnik", "value": "C107117"},
               {"label": "Krcedin", "value": "C107118"},
               {"label": "Kulpin", "value": "C107119"},
               {"label": "Kumane", "value": "C107120"},
               {"label": "Kupinovo", "value": "C107121"},
               {"label": "Kupusina", "value": "C107122"},
               {"label": "Kustilj", "value": "C107123"},
               {"label": "Lazarevo", "value": "C107124"},
               {"label": "Ljukovo", "value": "C107125"},
               {"label": "Lok", "value": "C107126"},
               {"label": "Lokve", "value": "C107127"},
               {"label": "Lukicevo", "value": "C107128"},
               {"label": "Macvanska Mitrovica", "value": "C107129"},
               {"label": "Maglic", "value": "C107130"},
               {"label": "Margita", "value": "C107131"},
               {"label": "Meda", "value": "C107132"},
               {"label": "Melenci", "value": "C107133"},
               {"label": "Mihajlovo", "value": "C107134"},
               {"label": "Mokrin", "value": "C107135"},
               {"label": "Mol", "value": "C107136"},
               {"label": "Mosorin", "value": "C107137"},
               {"label": "Nakovo", "value": "C107138"},
               {"label": "Neuzina", "value": "C107139"},
               {"label": "Nikinci", "value": "C107140"},
               {"label": "Nikolinci", "value": "C107141"},
               {"label": "Nova Crnja", "value": "C107142"},
               {"label": "Nova Pazova", "value": "C107143"},
               {"label": "Novi Banovci", "value": "C107144"},
               {"label": "Novi Itebej", "value": "C107145"},
               {"label": "Novi Karlovci", "value": "C107146"},
               {"label": "Novi Knezevac", "value": "C107147"},
               {"label": "Novi Kozarci", "value": "C107148"},
               {"label": "Novi Sad", "value": "C107149"},
               {"label": "Novi Slankamen", "value": "C107150"},
               {"label": "Novo Milosevo", "value": "C107151"},
               {"label": "Obrovac", "value": "C107152"},
               {"label": "Opovo", "value": "C107153"},
               {"label": "Orlovat", "value": "C107154"},
               {"label": "Ostojicevo", "value": "C107155"},
               {"label": "Padej", "value": "C107156"},
               {"label": "Padina", "value": "C107157"},
               {"label": "Pancevo", "value": "C107158"},
               {"label": "Pavlis", "value": "C107159"},
               {"label": "Pecinci", "value": "C107160"},
               {"label": "Perlez", "value": "C107161"},
               {"label": "Petrovaradin", "value": "C107162"},
               {"label": "Plandiste", "value": "C107163"},
               {"label": "Platicevo", "value": "C107164"},
               {"label": "Prigrevica", "value": "C107165"},
               {"label": "Putinci", "value": "C107166"},
               {"label": "Radenkovic", "value": "C107167"},
               {"label": "Radojevo", "value": "C107168"},
               {"label": "Ravni Topolovac", "value": "C107169"},
               {"label": "Ravnje", "value": "C107170"},
               {"label": "Ravno Selo", "value": "C107171"},
               {"label": "Ridica", "value": "C107172"},
               {"label": "Ruma", "value": "C107173"},
               {"label": "Rumenka", "value": "C107174"},
               {"label": "Rusko Selo", "value": "C107175"},
               {"label": "Sajan", "value": "C107176"},
               {"label": "Sajkas", "value": "C107177"},
               {"label": "Sakule", "value": "C107178"},
               {"label": "Salas Nocajski", "value": "C107179"},
               {"label": "Samos", "value": "C107180"},
               {"label": "Sanad", "value": "C107181"},
               {"label": "Sasinci", "value": "C107182"},
               {"label": "Secanj", "value": "C107183"},
               {"label": "Sefkerin", "value": "C107184"},
               {"label": "Seleus", "value": "C107185"},
               {"label": "Senta", "value": "C107186"},
               {"label": "Simanovci", "value": "C107187"},
               {"label": "Sombor", "value": "C107188"},
               {"label": "Sonta", "value": "C107189"},
               {"label": "Sremska Mitrovica", "value": "C107190"},
               {"label": "Sremski Karlovci", "value": "C107191"},
               {"label": "Srpska Crnja", "value": "C107192"},
               {"label": "Srpski Itebej", "value": "C107193"},
               {"label": "Stajicevo", "value": "C107194"},
               {"label": "Stanisic", "value": "C107195"},
               {"label": "Stara Pazova", "value": "C107196"},
               {"label": "Stari Banovci", "value": "C107197"},
               {"label": "Stari Lec", "value": "C107198"},
               {"label": "Stepanovicevo", "value": "C107199"},
               {"label": "Subotica", "value": "C107200"},
               {"label": "Surduk", "value": "C107201"},
               {"label": "Sutjeska", "value": "C107202"},
               {"label": "Taras", "value": "C107203"},
               {"label": "Tiszahegyes", "value": "C107204"},
               {"label": "Titel", "value": "C107205"},
               {"label": "Toba", "value": "C107206"},
               {"label": "Tomasevac", "value": "C107207"},
               {"label": "Torak", "value": "C107208"},
               {"label": "Torda", "value": "C107209"},
               {"label": "Uljma", "value": "C107210"},
               {"label": "Uzdin", "value": "C107211"},
               {"label": "Velika Greda", "value": "C107212"},
               {"label": "Veliki Gaj", "value": "C107213"},
               {"label": "Veliko Srediste", "value": "C107214"},
               {"label": "Veternik", "value": "C107215"},
               {"label": "Vilovo", "value": "C107216"},
               {"label": "Visnjicevo", "value": "C107217"},
               {"label": "Vladimirovac", "value": "C107218"},
               {"label": "Vlajkovac", "value": "C107219"},
               {"label": "Vojka", "value": "C107220"},
               {"label": "Vojvoda Stepa", "value": "C107221"},
               {"label": "Vrbas", "value": "C107222"},
               {"label": "Vrdnik", "value": "C107223"},
               {"label": "Vrsac", "value": "C107224"},
               {"label": "Zabalj", "value": "C107225"},
               {"label": "Zitiste", "value": "C107226"},
               {"label": "Zmajevo", "value": "C107227"},
               {"label": "Zrenjanin", "value": "C107228"}],
 "Volgograd Oblast": [{"label": "Alimov-Lyubimovsky", "value": "C105322"},
                      {"label": "Amochayevsky", "value": "C105323"},
                      {"label": "Antipovka", "value": "C105324"},
                      {"label": "Archedino-Chernushinsky", "value": "C105325"},
                      {"label": "Archedinskaya", "value": "C105326"},
                      {"label": "Beluzhino-Koldairov", "value": "C105327"},
                      {"label": "Bereslavka", "value": "C105328"},
                      {"label": "Bobrovsky 2-y", "value": "C105329"},
                      {"label": "Burnatsky", "value": "C105330"},
                      {"label": "Buyerak-Popovsky", "value": "C105331"},
                      {"label": "Bykovo, Volgograd Oblast", "value": "C105332"},
                      {"label": "Chernyshkovsky", "value": "C105333"},
                      {"label": "Chiganaki 1-ye", "value": "C105334"},
                      {"label": "Chiganaki 2-ye", "value": "C105335"},
                      {"label": "Danilovka, Volgograd Oblast",
                       "value": "C105336"},
                      {"label": "Dubovka", "value": "C105337"},
                      {"label": "Elton", "value": "C105338"},
                      {"label": "Fastov", "value": "C105339"},
                      {"label": "Frolovo", "value": "C105340"},
                      {"label": "Glubokovsky", "value": "C105341"},
                      {"label": "Gorbatovsky", "value": "C105342"},
                      {"label": "Gornaya Polyana", "value": "C105343"},
                      {"label": "Gorodishche, Volgograd Oblast",
                       "value": "C105344"},
                      {"label": "Ilovlya", "value": "C105345"},
                      {"label": "Kalach-na-Donu", "value": "C105346"},
                      {"label": "Kamyshin", "value": "C105347"},
                      {"label": "Karpovsky", "value": "C105348"},
                      {"label": "Kletskaya", "value": "C105349"},
                      {"label": "Kolotsky", "value": "C105350"},
                      {"label": "Kotelnikovo", "value": "C105351"},
                      {"label": "Kotovo", "value": "C105352"},
                      {"label": "Krasnoslobodsk", "value": "C105353"},
                      {"label": "Krasny Yar, Volgograd Oblast",
                       "value": "C105354"},
                      {"label": "Kumylzhenskaya", "value": "C105355"},
                      {"label": "Leninsk", "value": "C105356"},
                      {"label": "Log", "value": "C105357"},
                      {"label": "Medveditsky", "value": "C105358"},
                      {"label": "Mikhaylovka", "value": "C105359"},
                      {"label": "Mikhaylovka Urban Okrug", "value": "C105360"},
                      {"label": "Mitkin", "value": "C105361"},
                      {"label": "Nekhayevskaya", "value": "C105362"},
                      {"label": "Nikolayevsk", "value": "C105363"},
                      {"label": "Nizhny Chir", "value": "C105364"},
                      {"label": "Novoanninsky", "value": "C105365"},
                      {"label": "Novonikolayevsky, Volgograd Oblast",
                       "value": "C105366"},
                      {"label": "Novonikolskoe", "value": "C105367"},
                      {"label": "Novy Rogachik", "value": "C105368"},
                      {"label": "Oktyabrsky", "value": "C105369"},
                      {"label": "Olkhovka, Volgograd Oblast",
                       "value": "C105370"},
                      {"label": "Otruba", "value": "C105371"},
                      {"label": "Pallasovka", "value": "C105372"},
                      {"label": "Petrov Val", "value": "C105373"},
                      {"label": "Poltavsky", "value": "C105374"},
                      {"label": "Popovsky", "value": "C105375"},
                      {"label": "Preobrazhenskaya, Volgograd Oblast",
                       "value": "C105376"},
                      {"label": "Primorsk", "value": "C105377"},
                      {"label": "Repino", "value": "C105378"},
                      {"label": "Rogachev", "value": "C105379"},
                      {"label": "Rubyozhny", "value": "C105380"},
                      {"label": "Rudnya, Volgograd Oblast", "value": "C105381"},
                      {"label": "Russko-Osinovsky", "value": "C105382"},
                      {"label": "Samoylovsky", "value": "C105383"},
                      {"label": "Satarovsky", "value": "C105384"},
                      {"label": "Savinka", "value": "C105385"},
                      {"label": "Sebrovo", "value": "C105386"},
                      {"label": "Serafimovich", "value": "C105387"},
                      {"label": "Shirokov", "value": "C105388"},
                      {"label": "Slashchyovskaya", "value": "C105389"},
                      {"label": "Sovetsky District", "value": "C105390"},
                      {"label": "Srednyaya Akhtuba", "value": "C105391"},
                      {"label": "Staraya Poltavka", "value": "C105392"},
                      {"label": "Surovikino", "value": "C105393"},
                      {"label": "Svetly Yar", "value": "C105394"},
                      {"label": "Tochilkin", "value": "C105395"},
                      {"label": "Uryupinsk", "value": "C105396"},
                      {"label": "Vodstroy", "value": "C105397"},
                      {"label": "Volgograd", "value": "C105398"},
                      {"label": "Volzhsky", "value": "C105399"},
                      {"label": "Voroshilovskiy District, Volgograd",
                       "value": "C105400"},
                      {"label": "Yelan", "value": "C105401"},
                      {"label": "Zaplavnoye", "value": "C105402"},
                      {"label": "Zhirnovsk", "value": "C105403"}],
 "Vologda Oblast": [{"label": "Babayevo", "value": "C105404"},
                    {"label": "Babayevskiy Rayon", "value": "C105405"},
                    {"label": "Belozersk", "value": "C105406"},
                    {"label": "Belozerskiy Rayon", "value": "C105407"},
                    {"label": "Chagoda", "value": "C105408"},
                    {"label": "Chagodoshchenskiy Rayon", "value": "C105409"},
                    {"label": "Chebsara", "value": "C105410"},
                    {"label": "Cherepovets", "value": "C105411"},
                    {"label": "Cherepovetskiy Rayon", "value": "C105412"},
                    {"label": "Ferapontovo", "value": "C105413"},
                    {"label": "Goritsy", "value": "C105414"},
                    {"label": "Gryazovets", "value": "C105415"},
                    {"label": "Gryazovetskiy Rayon", "value": "C105416"},
                    {"label": "Imeni Babushkina", "value": "C105417"},
                    {"label": "Imeni Zhelyabova", "value": "C105418"},
                    {"label": "Kadnikov", "value": "C105419"},
                    {"label": "Kaduy", "value": "C105420"},
                    {"label": "Kaduyskiy Rayon", "value": "C105421"},
                    {"label": "Kharovsk", "value": "C105422"},
                    {"label": "Kharovskiy Rayon", "value": "C105423"},
                    {"label": "Khokhlovo", "value": "C105424"},
                    {"label": "Kichmengskiy Gorodok", "value": "C105425"},
                    {"label": "Kichmengsko-Gorodetskiy Rayon",
                     "value": "C105426"},
                    {"label": "Kirillov", "value": "C105427"},
                    {"label": "Kirillovskiy Rayon", "value": "C105428"},
                    {"label": "Krasavino", "value": "C105429"},
                    {"label": "Kuzino", "value": "C105430"},
                    {"label": "Lipin Bor", "value": "C105431"},
                    {"label": "Mezhdurechenskiy Rayon", "value": "C105432"},
                    {"label": "Molochnoye", "value": "C105433"},
                    {"label": "Myaksa", "value": "C105434"},
                    {"label": "Nelazskoye", "value": "C105435"},
                    {"label": "Nikolsk", "value": "C105436"},
                    {"label": "Nyuksenitsa", "value": "C105437"},
                    {"label": "Sazonovo", "value": "C105438"},
                    {"label": "Sheksna", "value": "C105439"},
                    {"label": "Sheksninskiy Rayon", "value": "C105440"},
                    {"label": "Shuyskoye", "value": "C105441"},
                    {"label": "Sokol", "value": "C105442"},
                    {"label": "Sokolskiy Rayon", "value": "C105443"},
                    {"label": "Suda", "value": "C105444"},
                    {"label": "Syamzha", "value": "C105445"},
                    {"label": "Syamzhenskiy Rayon", "value": "C105446"},
                    {"label": "Tarnogskiy Gorodok", "value": "C105447"},
                    {"label": "Tarnogskiy Rayon", "value": "C105448"},
                    {"label": "Tonshalovo", "value": "C105449"},
                    {"label": "Totma", "value": "C105450"},
                    {"label": "Totemskiy Rayon", "value": "C105451"},
                    {"label": "Ust-Kubinskiy Rayon", "value": "C105452"},
                    {"label": "Ustye", "value": "C105453"},
                    {"label": "Ustyuzhenskiy Rayon", "value": "C105454"},
                    {"label": "Ustyuzhna", "value": "C105455"},
                    {"label": "Velikiy Ustyug", "value": "C105456"},
                    {"label": "Velikoustyugskiy Rayon", "value": "C105457"},
                    {"label": "Verkhovazhye", "value": "C105458"},
                    {"label": "Verkhovazhskiy Rayon", "value": "C105459"},
                    {"label": "Vokhtoga", "value": "C105460"},
                    {"label": "Vologda", "value": "C105461"},
                    {"label": "Vologodskiy Rayon", "value": "C105462"},
                    {"label": "Vozhega", "value": "C105463"},
                    {"label": "Vytegorskiy Rayon", "value": "C105464"},
                    {"label": "Vytegra", "value": "C105465"},
                    {"label": "Zubovo", "value": "C105466"}],
 "Volta": [{"label": "Aflao", "value": "C41559"},
           {"label": "Anloga", "value": "C41560"},
           {"label": "Ho", "value": "C41561"},
           {"label": "Hohoe", "value": "C41562"},
           {"label": "Keta", "value": "C41563"},
           {"label": "Kete Krachi", "value": "C41564"},
           {"label": "Kpandu", "value": "C41565"}],
 "Volynska oblast": [{"label": "Berestechko", "value": "C122874"},
                     {"label": "Blahodatne", "value": "C122875"},
                     {"label": "Holoby", "value": "C122876"},
                     {"label": "Horokhiv", "value": "C122877"},
                     {"label": "Kamin-Kashyrskyi", "value": "C122878"},
                     {"label": "Kivertsi", "value": "C122879"},
                     {"label": "Kovel", "value": "C122880"},
                     {"label": "Kovelska Miskrada", "value": "C122881"},
                     {"label": "Liuboml", "value": "C122882"},
                     {"label": "Lokachi", "value": "C122883"},
                     {"label": "Lukiv", "value": "C122884"},
                     {"label": "Lutsk", "value": "C122885"},
                     {"label": "Lyubeshivskyy Rayon", "value": "C122886"},
                     {"label": "Lyubomlskyy Rayon", "value": "C122887"},
                     {"label": "Manevychi", "value": "C122888"},
                     {"label": "Manevytskyy Rayon", "value": "C122889"},
                     {"label": "Novovolynsk", "value": "C122890"},
                     {"label": "Nuyno", "value": "C122891"},
                     {"label": "Olyka", "value": "C122892"},
                     {"label": "Pishcha", "value": "C122893"},
                     {"label": "Rakiv Lis", "value": "C122894"},
                     {"label": "Ratne", "value": "C122895"},
                     {"label": "Ratnivskyy Rayon", "value": "C122896"},
                     {"label": "Rozhyshche", "value": "C122897"},
                     {"label": "Shatsk", "value": "C122898"},
                     {"label": "Shatskyy Rayon", "value": "C122899"},
                     {"label": "Stara Vyzhivka", "value": "C122900"},
                     {"label": "Svityaz", "value": "C122901"},
                     {"label": "Volodymyr-Volynskyi", "value": "C122902"}],
 "Vorarlberg": [{"label": "Alberschwende", "value": "C8268"},
                {"label": "Altach", "value": "C8269"},
                {"label": "Andelsbuch", "value": "C8270"},
                {"label": "Au", "value": "C8271"},
                {"label": "Ausserbraz", "value": "C8272"},
                {"label": "Bartholomaberg", "value": "C8273"},
                {"label": "Batschuns", "value": "C8274"},
                {"label": "Bezau", "value": "C8275"},
                {"label": "Bildstein", "value": "C8276"},
                {"label": "Bizau", "value": "C8277"},
                {"label": "Blons", "value": "C8278"},
                {"label": "Bludenz", "value": "C8279"},
                {"label": "Bludesch", "value": "C8280"},
                {"label": "Brand", "value": "C8281"},
                {"label": "Bregenz", "value": "C8282"},
                {"label": "Burs", "value": "C8283"},
                {"label": "Burserberg", "value": "C8284"},
                {"label": "Dalaas", "value": "C8285"},
                {"label": "Damuls", "value": "C8286"},
                {"label": "Doren", "value": "C8287"},
                {"label": "Dornbirn", "value": "C8288"},
                {"label": "Duns", "value": "C8289"},
                {"label": "Dunserberg", "value": "C8290"},
                {"label": "Egg", "value": "C8291"},
                {"label": "Eichenberg", "value": "C8292"},
                {"label": "Feldkirch", "value": "C8293"},
                {"label": "Frastanz", "value": "C8294"},
                {"label": "Fraxern", "value": "C8295"},
                {"label": "Fussach", "value": "C8296"},
                {"label": "Gaissau", "value": "C8297"},
                {"label": "Gaschurn", "value": "C8298"},
                {"label": "Gofis", "value": "C8299"},
                {"label": "Gotzis", "value": "C8300"},
                {"label": "Grossdorf", "value": "C8301"},
                {"label": "Hard", "value": "C8302"},
                {"label": "Hirschegg", "value": "C8303"},
                {"label": "Hittisau", "value": "C8304"},
                {"label": "Hochst", "value": "C8305"},
                {"label": "Hohenems", "value": "C8306"},
                {"label": "Hohenweiler", "value": "C8307"},
                {"label": "Horbranz", "value": "C8308"},
                {"label": "Innerbraz", "value": "C8309"},
                {"label": "Kennelbach", "value": "C8310"},
                {"label": "Klaus", "value": "C8311"},
                {"label": "Klosterle", "value": "C8312"},
                {"label": "Koblach", "value": "C8313"},
                {"label": "Krumbach", "value": "C8314"},
                {"label": "Langen", "value": "C8315"},
                {"label": "Langenegg", "value": "C8316"},
                {"label": "Lauterach", "value": "C8317"},
                {"label": "Lech", "value": "C8318"},
                {"label": "Lingenau", "value": "C8319"},
                {"label": "Lochau", "value": "C8320"},
                {"label": "Loruns", "value": "C8321"},
                {"label": "Ludesch", "value": "C8322"},
                {"label": "Lustenau", "value": "C8323"},
                {"label": "Mader", "value": "C8324"},
                {"label": "Meiningen", "value": "C8325"},
                {"label": "Mellau", "value": "C8326"},
                {"label": "Mittelberg", "value": "C8327"},
                {"label": "Moggers", "value": "C8328"},
                {"label": "Muntlix", "value": "C8329"},
                {"label": "Nenzing", "value": "C8330"},
                {"label": "Nuziders", "value": "C8331"},
                {"label": "Politischer Bezirk Bludenz", "value": "C8332"},
                {"label": "Politischer Bezirk Bregenz", "value": "C8333"},
                {"label": "Politischer Bezirk Dornbirn", "value": "C8334"},
                {"label": "Politischer Bezirk Feldkirch", "value": "C8335"},
                {"label": "Raggal", "value": "C8336"},
                {"label": "Rankweil", "value": "C8337"},
                {"label": "Reuthe", "value": "C8338"},
                {"label": "Riefensberg", "value": "C8339"},
                {"label": "Riezlern", "value": "C8340"},
                {"label": "Rons", "value": "C8341"},
                {"label": "Rothis", "value": "C8342"},
                {"label": "Sankt Anton im Montafon", "value": "C8343"},
                {"label": "Sankt Gallenkirch", "value": "C8344"},
                {"label": "Satteins", "value": "C8345"},
                {"label": "Schlins", "value": "C8346"},
                {"label": "Schnepfau", "value": "C8347"},
                {"label": "Schnifis", "value": "C8348"},
                {"label": "Schoppernau", "value": "C8349"},
                {"label": "Schrocken", "value": "C8350"},
                {"label": "Schruns", "value": "C8351"},
                {"label": "Schwarzach", "value": "C8352"},
                {"label": "Schwarzenberg", "value": "C8353"},
                {"label": "Sibratsgfall", "value": "C8354"},
                {"label": "Silbertal", "value": "C8355"},
                {"label": "Stallehr", "value": "C8356"},
                {"label": "Sulz", "value": "C8357"},
                {"label": "Sulzberg", "value": "C8358"},
                {"label": "Thuringen", "value": "C8359"},
                {"label": "Tschagguns", "value": "C8360"},
                {"label": "ubersaxen", "value": "C8361"},
                {"label": "Vandans", "value": "C8362"},
                {"label": "Viktorsberg", "value": "C8363"},
                {"label": "Warth", "value": "C8364"},
                {"label": "Weiler", "value": "C8365"},
                {"label": "Wolfurt", "value": "C8366"}],
 "Voronezh Oblast": [{"label": "Abramovka", "value": "C105467"},
                     {"label": "Anna", "value": "C105468"},
                     {"label": "Anninskiy Rayon", "value": "C105469"},
                     {"label": "Belogorye", "value": "C105470"},
                     {"label": "Bobrov", "value": "C105471"},
                     {"label": "Bobrovskiy Rayon", "value": "C105472"},
                     {"label": "Boguchar", "value": "C105473"},
                     {"label": "Borisoglebsk", "value": "C105474"},
                     {"label": "Buturlinovka", "value": "C105475"},
                     {"label": "Buturlinovskiy Rayon", "value": "C105476"},
                     {"label": "Davydovka", "value": "C105477"},
                     {"label": "Devitsa", "value": "C105478"},
                     {"label": "Drakino", "value": "C105479"},
                     {"label": "Ertil", "value": "C105480"},
                     {"label": "Ertilskiy Rayon", "value": "C105481"},
                     {"label": "Gribanovskiy", "value": "C105482"},
                     {"label": "Gribanovskiy Rayon", "value": "C105483"},
                     {"label": "Imeni Pervogo Maya", "value": "C105484"},
                     {"label": "Kalach", "value": "C105485"},
                     {"label": "Kamenka", "value": "C105486"},
                     {"label": "Kamenskiy Rayon", "value": "C105487"},
                     {"label": "Kantemirovka", "value": "C105488"},
                     {"label": "Kashirskiy Rayon", "value": "C105489"},
                     {"label": "Kashirskoye", "value": "C105490"},
                     {"label": "Khrenovoye", "value": "C105491"},
                     {"label": "Kolodeznyy", "value": "C105492"},
                     {"label": "Korotoyak", "value": "C105493"},
                     {"label": "Kozlovka", "value": "C105494"},
                     {"label": "Krasnolesnyy", "value": "C105495"},
                     {"label": "Latnaya", "value": "C105496"},
                     {"label": "Liski", "value": "C105497"},
                     {"label": "Liskinskiy Rayon", "value": "C105498"},
                     {"label": "Losevo", "value": "C105499"},
                     {"label": "Maslovka", "value": "C105500"},
                     {"label": "Mitrofanovka", "value": "C105501"},
                     {"label": "Nizhnedevitsk", "value": "C105502"},
                     {"label": "Nizhnedevitskiy Rayon", "value": "C105503"},
                     {"label": "Nizhniy Kislyay", "value": "C105504"},
                     {"label": "Nizhniy Mamon", "value": "C105505"},
                     {"label": "Novaya Chigla", "value": "C105506"},
                     {"label": "Novaya Usman", "value": "C105507"},
                     {"label": "Novokhopersk", "value": "C105508"},
                     {"label": "Novokhoperskiy Rayon", "value": "C105509"},
                     {"label": "Novousmanskiy Rayon", "value": "C105510"},
                     {"label": "Novovoronezh", "value": "C105511"},
                     {"label": "Novozhivotinnoye", "value": "C105512"},
                     {"label": "Olkhovatka", "value": "C105513"},
                     {"label": "Olkhovatskiy Rayon", "value": "C105514"},
                     {"label": "Orlovo", "value": "C105515"},
                     {"label": "Ostrogozhsk", "value": "C105516"},
                     {"label": "Panino", "value": "C105517"},
                     {"label": "Paninskiy Rayon", "value": "C105518"},
                     {"label": "Pavlovsk", "value": "C105519"},
                     {"label": "Pavlovskiy Rayon", "value": "C105520"},
                     {"label": "Pereleshino", "value": "C105521"},
                     {"label": "Pereleshinskiy", "value": "C105522"},
                     {"label": "Podgorenskiy", "value": "C105523"},
                     {"label": "Podgorenskiy Rayon", "value": "C105524"},
                     {"label": "Podgornoye", "value": "C105525"},
                     {"label": "Podkletnoye", "value": "C105526"},
                     {"label": "Povorino", "value": "C105527"},
                     {"label": "Povorinskiy Rayon", "value": "C105528"},
                     {"label": "Pridonskoy", "value": "C105529"},
                     {"label": "Ramon", "value": "C105530"},
                     {"label": "Ramonskiy Rayon", "value": "C105531"},
                     {"label": "Repyevskiy Rayon", "value": "C105532"},
                     {"label": "Rossosh", "value": "C105533"},
                     {"label": "Rossoshanskiy Rayon", "value": "C105534"},
                     {"label": "Sadovoye", "value": "C105535"},
                     {"label": "Semiluki", "value": "C105536"},
                     {"label": "Semilukskiy Rayon", "value": "C105537"},
                     {"label": "Shilovo", "value": "C105538"},
                     {"label": "Sloboda", "value": "C105539"},
                     {"label": "Somovo", "value": "C105540"},
                     {"label": "Sredniy Ikorets", "value": "C105541"},
                     {"label": "Strelitsa", "value": "C105542"},
                     {"label": "Talovaya", "value": "C105543"},
                     {"label": "Ternovka", "value": "C105544"},
                     {"label": "Ternovskiy Rayon", "value": "C105545"},
                     {"label": "Uglyanets", "value": "C105546"},
                     {"label": "Uryv-Pokrovka", "value": "C105547"},
                     {"label": "Verkhniy Mamon", "value": "C105548"},
                     {"label": "Verkhnyaya Khava", "value": "C105549"},
                     {"label": "Verkhnyaya Tishanka", "value": "C105550"},
                     {"label": "Volya", "value": "C105551"},
                     {"label": "Vorobyevskiy Rayon", "value": "C105552"},
                     {"label": "Voronezh", "value": "C105553"},
                     {"label": "Vorontsovka", "value": "C105554"},
                     {"label": "Yelan-Koleno", "value": "C105555"},
                     {"label": "Yelan-Kolenovskiy", "value": "C105556"},
                     {"label": "Zabolotovka", "value": "C105557"},
                     {"label": "Zemlyansk", "value": "C105558"}],
 "Voru County": [{"label": "Antsla", "value": "C24653"},
                 {"label": "Antsla vald", "value": "C24654"},
                 {"label": "Rouge", "value": "C24655"},
                 {"label": "Rouge vald", "value": "C24656"},
                 {"label": "Vana-Antsla", "value": "C24657"},
                 {"label": "Varska", "value": "C24658"},
                 {"label": "Voru", "value": "C24659"},
                 {"label": "Voru vald", "value": "C24660"}],
 "Vrancea County": [{"label": "Adjud", "value": "C99988"},
                    {"label": "Adjudu Vechi", "value": "C99989"},
                    {"label": "Andreiasu de Jos", "value": "C99990"},
                    {"label": "Anghelesti", "value": "C99991"},
                    {"label": "Balesti", "value": "C99992"},
                    {"label": "Barsesti", "value": "C99993"},
                    {"label": "Biliesti", "value": "C99994"},
                    {"label": "Boghesti", "value": "C99995"},
                    {"label": "Bogza", "value": "C99996"},
                    {"label": "Bolotesti", "value": "C99997"},
                    {"label": "Bordeasca Veche", "value": "C99998"},
                    {"label": "Bordesti", "value": "C99999"},
                    {"label": "Brosteni", "value": "C100000"},
                    {"label": "Budesti", "value": "C100001"},
                    {"label": "Burca", "value": "C100002"},
                    {"label": "Campineanca", "value": "C100003"},
                    {"label": "Campuri", "value": "C100004"},
                    {"label": "Campurile de Jos", "value": "C100005"},
                    {"label": "Carligele", "value": "C100006"},
                    {"label": "Ceardac", "value": "C100007"},
                    {"label": "Chiojdeni", "value": "C100008"},
                    {"label": "Ciorani", "value": "C100009"},
                    {"label": "Ciorasti", "value": "C100010"},
                    {"label": "Ciuslea", "value": "C100011"},
                    {"label": "Comuna Andreiasu de Jos", "value": "C100012"},
                    {"label": "Comuna Balesti", "value": "C100013"},
                    {"label": "Comuna Barsesti", "value": "C100014"},
                    {"label": "Comuna Biliesti", "value": "C100015"},
                    {"label": "Comuna Boghesti", "value": "C100016"},
                    {"label": "Comuna Bolotesti", "value": "C100017"},
                    {"label": "Comuna Bordesti", "value": "C100018"},
                    {"label": "Comuna Brosteni", "value": "C100019"},
                    {"label": "Comuna Campineanca", "value": "C100020"},
                    {"label": "Comuna Campuri", "value": "C100021"},
                    {"label": "Comuna Carligele", "value": "C100022"},
                    {"label": "Comuna Chiojdeni", "value": "C100023"},
                    {"label": "Comuna Ciorasti", "value": "C100024"},
                    {"label": "Comuna Corbita", "value": "C100025"},
                    {"label": "Comuna Cotesti", "value": "C100026"},
                    {"label": "Comuna Dumbraveni", "value": "C100027"},
                    {"label": "Comuna Dumitresti", "value": "C100028"},
                    {"label": "Comuna Fitionesti", "value": "C100029"},
                    {"label": "Comuna Garoafa", "value": "C100030"},
                    {"label": "Comuna Golesti", "value": "C100031"},
                    {"label": "Comuna Gologanu", "value": "C100032"},
                    {"label": "Comuna Gugesti", "value": "C100033"},
                    {"label": "Comuna Gura Calitei", "value": "C100034"},
                    {"label": "Comuna Homocea", "value": "C100035"},
                    {"label": "Comuna Jaristea", "value": "C100036"},
                    {"label": "Comuna Jitia", "value": "C100037"},
                    {"label": "Comuna Maicanesti", "value": "C100038"},
                    {"label": "Comuna Mera", "value": "C100039"},
                    {"label": "Comuna Milcovul", "value": "C100040"},
                    {"label": "Comuna Movilita", "value": "C100041"},
                    {"label": "Comuna Nanesti", "value": "C100042"},
                    {"label": "Comuna Naruja", "value": "C100043"},
                    {"label": "Comuna Negrilesti", "value": "C100044"},
                    {"label": "Comuna Nereju", "value": "C100045"},
                    {"label": "Comuna Nistoresti", "value": "C100046"},
                    {"label": "Comuna Obrejita", "value": "C100047"},
                    {"label": "Comuna Paltin", "value": "C100048"},
                    {"label": "Comuna Paulesti", "value": "C100049"},
                    {"label": "Comuna Paunesti", "value": "C100050"},
                    {"label": "Comuna Ploscuteni", "value": "C100051"},
                    {"label": "Comuna Poiana Cristei", "value": "C100052"},
                    {"label": "Comuna Popesti", "value": "C100053"},
                    {"label": "Comuna Pufesti", "value": "C100054"},
                    {"label": "Comuna Racoasa", "value": "C100055"},
                    {"label": "Comuna Rastoaca", "value": "C100056"},
                    {"label": "Comuna Reghiu", "value": "C100057"},
                    {"label": "Comuna Ruginesti", "value": "C100058"},
                    {"label": "Comuna Sihlea", "value": "C100059"},
                    {"label": "Comuna Slobozia Bradului", "value": "C100060"},
                    {"label": "Comuna Slobozia-Ciorasti", "value": "C100061"},
                    {"label": "Comuna Soveja", "value": "C100062"},
                    {"label": "Comuna Spulber", "value": "C100063"},
                    {"label": "Comuna Straoane", "value": "C100064"},
                    {"label": "Comuna Suraia", "value": "C100065"},
                    {"label": "Comuna Tamboesti", "value": "C100066"},
                    {"label": "Comuna Tanasoaia", "value": "C100067"},
                    {"label": "Comuna Tataranu", "value": "C100068"},
                    {"label": "Comuna Tifesti", "value": "C100069"},
                    {"label": "Comuna Tulnici", "value": "C100070"},
                    {"label": "Comuna Urechesti", "value": "C100071"},
                    {"label": "Comuna Valea Sarii", "value": "C100072"},
                    {"label": "Comuna Vanatori", "value": "C100073"},
                    {"label": "Comuna Vartescoiu", "value": "C100074"},
                    {"label": "Comuna Vidra", "value": "C100075"},
                    {"label": "Comuna Vintileasca", "value": "C100076"},
                    {"label": "Comuna Vizantea-Livezi", "value": "C100077"},
                    {"label": "Comuna Vrancioaia", "value": "C100078"},
                    {"label": "Comuna Vulturu", "value": "C100079"},
                    {"label": "Corbita", "value": "C100080"},
                    {"label": "Cornetu", "value": "C100081"},
                    {"label": "Cotesti", "value": "C100082"},
                    {"label": "Domnesti-Targ", "value": "C100083"},
                    {"label": "Dragosloveni", "value": "C100084"},
                    {"label": "Dumbraveni", "value": "C100085"},
                    {"label": "Dumitresti", "value": "C100086"},
                    {"label": "Fitionesti", "value": "C100087"},
                    {"label": "Focsani", "value": "C100088"},
                    {"label": "Gagesti", "value": "C100089"},
                    {"label": "Garoafa", "value": "C100090"},
                    {"label": "Golesti", "value": "C100091"},
                    {"label": "Gologanu", "value": "C100092"},
                    {"label": "Gugesti", "value": "C100093"},
                    {"label": "Gura Calitei", "value": "C100094"},
                    {"label": "Homocea", "value": "C100095"},
                    {"label": "Igesti", "value": "C100096"},
                    {"label": "Iresti", "value": "C100097"},
                    {"label": "Jaristea", "value": "C100098"},
                    {"label": "Jitia", "value": "C100099"},
                    {"label": "Jorasti", "value": "C100100"},
                    {"label": "Lespezi", "value": "C100101"},
                    {"label": "Maicanesti", "value": "C100102"},
                    {"label": "Marasesti", "value": "C100103"},
                    {"label": "Matacina", "value": "C100104"},
                    {"label": "Mera", "value": "C100105"},
                    {"label": "Mihalceni", "value": "C100106"},
                    {"label": "Milcovul", "value": "C100107"},
                    {"label": "Mircestii Noi", "value": "C100108"},
                    {"label": "Movilita", "value": "C100109"},
                    {"label": "Municipiul  Adjud", "value": "C100110"},
                    {"label": "Municipiul Focsani", "value": "C100111"},
                    {"label": "Nanesti", "value": "C100112"},
                    {"label": "Naruja", "value": "C100113"},
                    {"label": "Negrilesti", "value": "C100114"},
                    {"label": "Nereju", "value": "C100115"},
                    {"label": "Nereju Mic", "value": "C100116"},
                    {"label": "Nistoresti", "value": "C100117"},
                    {"label": "Obrejita", "value": "C100118"},
                    {"label": "Odobesti", "value": "C100119"},
                    {"label": "Olesesti", "value": "C100120"},
                    {"label": "Oras Marasesti", "value": "C100121"},
                    {"label": "Oras Odobesti", "value": "C100122"},
                    {"label": "Oras Panciu", "value": "C100123"},
                    {"label": "Paltin", "value": "C100124"},
                    {"label": "Panciu", "value": "C100125"},
                    {"label": "Paulesti", "value": "C100126"},
                    {"label": "Paunesti", "value": "C100127"},
                    {"label": "Ploscuteni", "value": "C100128"},
                    {"label": "Poiana Cristei", "value": "C100129"},
                    {"label": "Popesti", "value": "C100130"},
                    {"label": "Pufesti", "value": "C100131"},
                    {"label": "Racoasa", "value": "C100132"},
                    {"label": "Ramniceni", "value": "C100133"},
                    {"label": "Rastoaca", "value": "C100134"},
                    {"label": "Reghiu", "value": "C100135"},
                    {"label": "Rucareni", "value": "C100136"},
                    {"label": "Ruginesti", "value": "C100137"},
                    {"label": "Sihlea", "value": "C100138"},
                    {"label": "Sindrilari", "value": "C100139"},
                    {"label": "Slobozia Bradului", "value": "C100140"},
                    {"label": "Slobozia-Campineanca", "value": "C100141"},
                    {"label": "Slobozia-Ciorasti", "value": "C100142"},
                    {"label": "Spulber", "value": "C100143"},
                    {"label": "Straoane", "value": "C100144"},
                    {"label": "Suraia", "value": "C100145"},
                    {"label": "Tamboesti", "value": "C100146"},
                    {"label": "Tanasoaia", "value": "C100147"},
                    {"label": "Tataranu", "value": "C100148"},
                    {"label": "Tifesti", "value": "C100149"},
                    {"label": "Tulnici", "value": "C100150"},
                    {"label": "Unirea", "value": "C100151"},
                    {"label": "Urechesti", "value": "C100152"},
                    {"label": "Vadu Rosca", "value": "C100153"},
                    {"label": "Valea Sarii", "value": "C100154"},
                    {"label": "Vanatori", "value": "C100155"},
                    {"label": "Vartescoiu", "value": "C100156"},
                    {"label": "Vidra", "value": "C100157"},
                    {"label": "Viisoara", "value": "C100158"},
                    {"label": "Vintileasca", "value": "C100159"},
                    {"label": "Vizantea-Manastireasca", "value": "C100160"},
                    {"label": "Voetin", "value": "C100161"},
                    {"label": "Vrancioaia", "value": "C100162"},
                    {"label": "Vulcaneasa", "value": "C100163"},
                    {"label": "Vulturu", "value": "C100164"}],
 "Vransko Municipality": [{"label": "Vransko", "value": "C107855"}],
 "Vrapciste Municipality": [{"label": "Dobri Dol", "value": "C65542"},
                            {"label": "Negotino", "value": "C65543"},
                            {"label": "Vrapciste", "value": "C65544"}],
 "Vratsa Province": [{"label": "Borovan", "value": "C16322"},
                     {"label": "Byala Slatina", "value": "C16323"},
                     {"label": "Hayredin", "value": "C16324"},
                     {"label": "Kozloduy", "value": "C16325"},
                     {"label": "Krivodol", "value": "C16326"},
                     {"label": "Mezdra", "value": "C16327"},
                     {"label": "Mizia", "value": "C16328"},
                     {"label": "Obshtina Borovan", "value": "C16329"},
                     {"label": "Obshtina Hayredin", "value": "C16330"},
                     {"label": "Obshtina Kozloduy", "value": "C16331"},
                     {"label": "Obshtina Krivodol", "value": "C16332"},
                     {"label": "Obshtina Mezdra", "value": "C16333"},
                     {"label": "Obshtina Mizia", "value": "C16334"},
                     {"label": "Obshtina Oryahovo", "value": "C16335"},
                     {"label": "Obshtina Roman", "value": "C16336"},
                     {"label": "Obshtina Vratsa", "value": "C16337"},
                     {"label": "Oryahovo", "value": "C16338"},
                     {"label": "Roman", "value": "C16339"},
                     {"label": "Vratsa", "value": "C16340"}],
 "Vrhnika Municipality": [{"label": "Verd", "value": "C107856"},
                          {"label": "Vrhnika", "value": "C107857"}],
 "Vukovar-Syrmia": [{"label": "Andrijasevci", "value": "C21523"},
                    {"label": "Babina Greda", "value": "C21524"},
                    {"label": "Bobota", "value": "C21525"},
                    {"label": "Bogdanovci", "value": "C21526"},
                    {"label": "Borovo", "value": "C21527"},
                    {"label": "Borovo Selo", "value": "C21528"},
                    {"label": "Bosnjaci", "value": "C21529"},
                    {"label": "Brsadin", "value": "C21530"},
                    {"label": "Ceric", "value": "C21531"},
                    {"label": "Cerna", "value": "C21532"},
                    {"label": "Drenovci", "value": "C21533"},
                    {"label": "Grad Vinkovci", "value": "C21534"},
                    {"label": "Grad Vukovar", "value": "C21535"},
                    {"label": "Grad Zupanja", "value": "C21536"},
                    {"label": "Gradiste", "value": "C21537"},
                    {"label": "Gunja", "value": "C21538"},
                    {"label": "Ilok", "value": "C21539"},
                    {"label": "Ivankovo", "value": "C21540"},
                    {"label": "Jarmina", "value": "C21541"},
                    {"label": "Komletinci", "value": "C21542"},
                    {"label": "Lovas", "value": "C21543"},
                    {"label": "Markusica", "value": "C21544"},
                    {"label": "Mirkovci", "value": "C21545"},
                    {"label": "Negoslavci", "value": "C21546"},
                    {"label": "Nijemci", "value": "C21547"},
                    {"label": "Nustar", "value": "C21548"},
                    {"label": "Otok", "value": "C21549"},
                    {"label": "Privlaka", "value": "C21550"},
                    {"label": "Retkovci", "value": "C21551"},
                    {"label": "Rokovci", "value": "C21552"},
                    {"label": "Soljani", "value": "C21553"},
                    {"label": "Stari Jankovci", "value": "C21554"},
                    {"label": "Stitar", "value": "C21555"},
                    {"label": "Tordinci", "value": "C21556"},
                    {"label": "Tovarnik", "value": "C21557"},
                    {"label": "Trpinja", "value": "C21558"},
                    {"label": "Vinkovci", "value": "C21559"},
                    {"label": "Vodinci", "value": "C21560"},
                    {"label": "Vrbanja", "value": "C21561"},
                    {"label": "Vukovar", "value": "C21562"},
                    {"label": "Zupanja", "value": "C21563"}],
 "Vuzenica Municipality": [{"label": "Vuzenica", "value": "C107858"}],
 "Wadi Fira Region": [{"label": "Biltine", "value": "C17890"},
                      {"label": "Iriba", "value": "C17891"}],
 "Wadi al Hayaa District": [{"label": "Ubari", "value": "C65064"}],
 "Wadi al Shatii District": [{"label": "Brak", "value": "C65065"},
                             {"label": "Idri", "value": "C65066"}],
 "Waikato Region": [{"label": "Cambridge", "value": "C77849"},
                    {"label": "Coromandel", "value": "C77850"},
                    {"label": "Hamilton", "value": "C77851"},
                    {"label": "Matamata", "value": "C77852"},
                    {"label": "Ngaruawahia", "value": "C77853"},
                    {"label": "Ngatea", "value": "C77854"},
                    {"label": "Otorohanga", "value": "C77855"},
                    {"label": "Paeroa", "value": "C77856"},
                    {"label": "Raglan", "value": "C77857"},
                    {"label": "South Waikato District", "value": "C77858"},
                    {"label": "Tairua", "value": "C77859"},
                    {"label": "Taupo", "value": "C77860"},
                    {"label": "Te Kauwhata", "value": "C77861"},
                    {"label": "Thames", "value": "C77862"},
                    {"label": "Tokoroa", "value": "C77863"},
                    {"label": "Turangi", "value": "C77864"},
                    {"label": "Waihi", "value": "C77865"},
                    {"label": "Whangamata", "value": "C77866"},
                    {"label": "Whitianga", "value": "C77867"}],
 "Wajir": [{"label": "Wajir", "value": "C64641"}],
 "Wakayama Prefecture": [{"label": "Arida Shi", "value": "C64108"},
                         {"label": "Gobo", "value": "C64109"},
                         {"label": "Hashimoto", "value": "C64110"},
                         {"label": "Hashimoto Shi", "value": "C64111"},
                         {"label": "Iwade", "value": "C64112"},
                         {"label": "Iwade Shi", "value": "C64113"},
                         {"label": "Kainan", "value": "C64114"},
                         {"label": "Kainan Shi", "value": "C64115"},
                         {"label": "Kinokawa Shi", "value": "C64116"},
                         {"label": "Koya", "value": "C64117"},
                         {"label": "Minato", "value": "C64118"},
                         {"label": "Shingu", "value": "C64119"},
                         {"label": "Shingu-shi", "value": "C64120"},
                         {"label": "Tanabe-shi", "value": "C64121"},
                         {"label": "Wakayama", "value": "C64122"},
                         {"label": "Wakayama Shi", "value": "C64123"}],
 "Wales": [{"label": "Aberaeron", "value": "C126703"},
           {"label": "Abercanaid", "value": "C126704"},
           {"label": "Abercarn", "value": "C126705"},
           {"label": "Abercynon", "value": "C126706"},
           {"label": "Aberdare", "value": "C126707"},
           {"label": "Aberfan", "value": "C126708"},
           {"label": "Abergavenny", "value": "C126709"},
           {"label": "Abergele", "value": "C126710"},
           {"label": "Aberkenfig", "value": "C126711"},
           {"label": "Aberporth", "value": "C126712"},
           {"label": "Abertillery", "value": "C126713"},
           {"label": "Abertridwr", "value": "C126714"},
           {"label": "Aberystwyth", "value": "C126715"},
           {"label": "Amlwch", "value": "C126716"},
           {"label": "Ammanford", "value": "C126717"},
           {"label": "Anglesey", "value": "C126718"},
           {"label": "Bagillt", "value": "C126719"},
           {"label": "Bala", "value": "C126720"},
           {"label": "Bangor", "value": "C126721"},
           {"label": "Bargoed", "value": "C126722"},
           {"label": "Barmouth", "value": "C126723"},
           {"label": "Barry", "value": "C126724"},
           {"label": "Beaumaris", "value": "C126725"},
           {"label": "Beddau", "value": "C126726"},
           {"label": "Bedlinog", "value": "C126727"},
           {"label": "Bedwas", "value": "C126728"},
           {"label": "Benllech", "value": "C126729"},
           {"label": "Bethesda", "value": "C126730"},
           {"label": "Betws", "value": "C126731"},
           {"label": "Betws-y-Coed", "value": "C126732"},
           {"label": "Bishopston", "value": "C126733"},
           {"label": "Blackwood", "value": "C126734"},
           {"label": "Blaenau Gwent", "value": "C126735"},
           {"label": "Blaenau-Ffestiniog", "value": "C126736"},
           {"label": "Blaenavon", "value": "C126737"},
           {"label": "Blaengwynfi", "value": "C126738"},
           {"label": "Bodedern", "value": "C126739"},
           {"label": "Bodelwyddan", "value": "C126740"},
           {"label": "Borth", "value": "C126741"},
           {"label": "Bow Street", "value": "C126742"},
           {"label": "Brecon", "value": "C126743"},
           {"label": "Bridgend", "value": "C126744"},
           {"label": "Bridgend county borough", "value": "C126745"},
           {"label": "Briton Ferry", "value": "C126746"},
           {"label": "Broughton", "value": "C126747"},
           {"label": "Brymbo", "value": "C126748"},
           {"label": "Bryn", "value": "C126749"},
           {"label": "Brynamman", "value": "C126750"},
           {"label": "Brynmawr", "value": "C126751"},
           {"label": "Brynna", "value": "C126752"},
           {"label": "Buckley", "value": "C126753"},
           {"label": "Builth Wells", "value": "C126754"},
           {"label": "Burry Port", "value": "C126755"},
           {"label": "Caergwrle", "value": "C126756"},
           {"label": "Caerleon", "value": "C126757"},
           {"label": "Caernarfon", "value": "C126758"},
           {"label": "Caerphilly", "value": "C126759"},
           {"label": "Caerphilly County Borough", "value": "C126760"},
           {"label": "Caerwent", "value": "C126761"},
           {"label": "Caldicot", "value": "C126762"},
           {"label": "Cardiff", "value": "C126763"},
           {"label": "Cardigan", "value": "C126764"},
           {"label": "Carmarthen", "value": "C126765"},
           {"label": "Carmarthenshire", "value": "C126766"},
           {"label": "Cefn Cribwr", "value": "C126767"},
           {"label": "Cemaes Bay", "value": "C126768"},
           {"label": "Chepstow", "value": "C126769"},
           {"label": "Chirk", "value": "C126770"},
           {"label": "City and County of Swansea", "value": "C126771"},
           {"label": "Clydach", "value": "C126772"},
           {"label": "Coedpoeth", "value": "C126773"},
           {"label": "Coity", "value": "C126774"},
           {"label": "Colwyn Bay", "value": "C126775"},
           {"label": "Conwy", "value": "C126776"},
           {"label": "County of Ceredigion", "value": "C126777"},
           {"label": "County of Flintshire", "value": "C126778"},
           {"label": "Cowbridge", "value": "C126779"},
           {"label": "Criccieth", "value": "C126780"},
           {"label": "Crickhowell", "value": "C126781"},
           {"label": "Croeserw", "value": "C126782"},
           {"label": "Crofty", "value": "C126783"},
           {"label": "Cross Hands", "value": "C126784"},
           {"label": "Crumlin", "value": "C126785"},
           {"label": "Crymych", "value": "C126786"},
           {"label": "Crynant", "value": "C126787"},
           {"label": "Cwm", "value": "C126788"},
           {"label": "Cwmafan", "value": "C126789"},
           {"label": "Cwmbach", "value": "C126790"},
           {"label": "Cwmbran", "value": "C126791"},
           {"label": "Deeside", "value": "C126792"},
           {"label": "Deganwy", "value": "C126793"},
           {"label": "Deiniolen", "value": "C126794"},
           {"label": "Denbigh", "value": "C126795"},
           {"label": "Denbighshire", "value": "C126796"},
           {"label": "Deri", "value": "C126797"},
           {"label": "Dinas Powys", "value": "C126798"},
           {"label": "Dolgellau", "value": "C126799"},
           {"label": "Dyffryn Ardudwy", "value": "C126800"},
           {"label": "Dyserth", "value": "C126801"},
           {"label": "Ebbw Vale", "value": "C126802"},
           {"label": "Ferndale", "value": "C126803"},
           {"label": "Fishguard", "value": "C126804"},
           {"label": "Flint", "value": "C126805"},
           {"label": "Fochriw", "value": "C126806"},
           {"label": "Four Crosses", "value": "C126807"},
           {"label": "Gaerwen", "value": "C126808"},
           {"label": "Gelligaer", "value": "C126809"},
           {"label": "Gilfach Goch", "value": "C126810"},
           {"label": "Gilwern", "value": "C126811"},
           {"label": "Glanamman", "value": "C126812"},
           {"label": "Glandwr", "value": "C126813"},
           {"label": "Glyn-neath", "value": "C126814"},
           {"label": "Glyncorrwg", "value": "C126815"},
           {"label": "Goodwick", "value": "C126816"},
           {"label": "Gorseinon", "value": "C126817"},
           {"label": "Govilon", "value": "C126818"},
           {"label": "Greenfield", "value": "C126819"},
           {"label": "Gresford", "value": "C126820"},
           {"label": "Gronant", "value": "C126821"},
           {"label": "Grovesend", "value": "C126822"},
           {"label": "Guilsfield", "value": "C126823"},
           {"label": "Gwynedd", "value": "C126824"},
           {"label": "Harlech", "value": "C126825"},
           {"label": "Haverfordwest", "value": "C126826"},
           {"label": "Hawarden", "value": "C126827"},
           {"label": "Hay", "value": "C126828"},
           {"label": "Hengoed", "value": "C126829"},
           {"label": "Henllan", "value": "C126830"},
           {"label": "Hirwaun", "value": "C126831"},
           {"label": "Holyhead", "value": "C126832"},
           {"label": "Holywell", "value": "C126833"},
           {"label": "Hook", "value": "C126834"},
           {"label": "Hope", "value": "C126835"},
           {"label": "Hundleton", "value": "C126836"},
           {"label": "Johnston", "value": "C126837"},
           {"label": "Kidwelly", "value": "C126838"},
           {"label": "Kilgetty", "value": "C126839"},
           {"label": "Knighton", "value": "C126840"},
           {"label": "Lampeter", "value": "C126841"},
           {"label": "Leeswood", "value": "C126842"},
           {"label": "Letterston", "value": "C126843"},
           {"label": "Llanarth", "value": "C126844"},
           {"label": "Llanbedr", "value": "C126845"},
           {"label": "Llanberis", "value": "C126846"},
           {"label": "Llanbradach", "value": "C126847"},
           {"label": "Llandeilo", "value": "C126848"},
           {"label": "Llandovery", "value": "C126849"},
           {"label": "Llandrindod Wells", "value": "C126850"},
           {"label": "Llandudno", "value": "C126851"},
           {"label": "Llandybie", "value": "C126852"},
           {"label": "Llandysul", "value": "C126853"},
           {"label": "Llanelli", "value": "C126854"},
           {"label": "Llanerchymedd", "value": "C126855"},
           {"label": "Llanfair Caereinion", "value": "C126856"},
           {"label": "Llanfairfechan", "value": "C126857"},
           {"label": "Llanfairpwllgwyngyll", "value": "C126858"},
           {"label": "Llanfyllin", "value": "C126859"},
           {"label": "Llangefni", "value": "C126860"},
           {"label": "Llangoed", "value": "C126861"},
           {"label": "Llangollen", "value": "C126862"},
           {"label": "Llangwm", "value": "C126863"},
           {"label": "Llangybi", "value": "C126864"},
           {"label": "Llangynidr", "value": "C126865"},
           {"label": "Llanharan", "value": "C126866"},
           {"label": "Llanharry", "value": "C126867"},
           {"label": "Llanidloes", "value": "C126868"},
           {"label": "Llanilar", "value": "C126869"},
           {"label": "Llanrhaeadr-ym-Mochnant", "value": "C126870"},
           {"label": "Llanrug", "value": "C126871"},
           {"label": "Llanrwst", "value": "C126872"},
           {"label": "Llansantffraid Glan Conwy", "value": "C126873"},
           {"label": "Llansteffan", "value": "C126874"},
           {"label": "Llantrisant", "value": "C126875"},
           {"label": "Llantwit Fardre", "value": "C126876"},
           {"label": "Llantwit Major", "value": "C126877"},
           {"label": "Llwynypia", "value": "C126878"},
           {"label": "Machen", "value": "C126879"},
           {"label": "Machynlleth", "value": "C126880"},
           {"label": "Maesteg", "value": "C126881"},
           {"label": "Magor", "value": "C126882"},
           {"label": "Marchwiel", "value": "C126883"},
           {"label": "Marshfield", "value": "C126884"},
           {"label": "Menai Bridge", "value": "C126885"},
           {"label": "Merthyr Tydfil", "value": "C126886"},
           {"label": "Merthyr Tydfil County Borough", "value": "C126887"},
           {"label": "Milford Haven", "value": "C126888"},
           {"label": "Moelfre", "value": "C126889"},
           {"label": "Mold", "value": "C126890"},
           {"label": "Monmouth", "value": "C126891"},
           {"label": "Monmouthshire", "value": "C126892"},
           {"label": "Mostyn", "value": "C126893"},
           {"label": "Mountain Ash", "value": "C126894"},
           {"label": "Narberth", "value": "C126895"},
           {"label": "Neath", "value": "C126896"},
           {"label": "Neath Port Talbot", "value": "C126897"},
           {"label": "Nefyn", "value": "C126898"},
           {"label": "Nelson", "value": "C126899"},
           {"label": "New Quay", "value": "C126900"},
           {"label": "New Tredegar", "value": "C126901"},
           {"label": "Newbridge", "value": "C126902"},
           {"label": "Newcastle Emlyn", "value": "C126903"},
           {"label": "Newport", "value": "C126904"},
           {"label": "Newtown", "value": "C126905"},
           {"label": "Neyland", "value": "C126906"},
           {"label": "Northop", "value": "C126907"},
           {"label": "Ogmore Vale", "value": "C126908"},
           {"label": "Overton", "value": "C126909"},
           {"label": "Pembroke", "value": "C126910"},
           {"label": "Pembroke Dock", "value": "C126911"},
           {"label": "Pembrokeshire", "value": "C126912"},
           {"label": "Pen-clawdd", "value": "C126913"},
           {"label": "Penally", "value": "C126914"},
           {"label": "Penarth", "value": "C126915"},
           {"label": "Pencader", "value": "C126916"},
           {"label": "Pencoed", "value": "C126917"},
           {"label": "Penmaenmawr", "value": "C126918"},
           {"label": "Penparcau", "value": "C126919"},
           {"label": "Penrhyndeudraeth", "value": "C126920"},
           {"label": "Pentre", "value": "C126921"},
           {"label": "Pentyrch", "value": "C126922"},
           {"label": "Penyffordd", "value": "C126923"},
           {"label": "Penygroes", "value": "C126924"},
           {"label": "Pont Rhyd-y-cyff", "value": "C126925"},
           {"label": "Pontarddulais", "value": "C126926"},
           {"label": "Pontlliw", "value": "C126927"},
           {"label": "Pontyates", "value": "C126928"},
           {"label": "Pontyberem", "value": "C126929"},
           {"label": "Pontyclun", "value": "C126930"},
           {"label": "Pontycymer", "value": "C126931"},
           {"label": "Pontypool", "value": "C126932"},
           {"label": "Pontypridd", "value": "C126933"},
           {"label": "Port Talbot", "value": "C126934"},
           {"label": "Porthcawl", "value": "C126935"},
           {"label": "Porthmadog", "value": "C126936"},
           {"label": "Prestatyn", "value": "C126937"},
           {"label": "Presteigne", "value": "C126938"},
           {"label": "Price Town", "value": "C126939"},
           {"label": "Pwllheli", "value": "C126940"},
           {"label": "Pyle", "value": "C126941"},
           {"label": "Radyr", "value": "C126942"},
           {"label": "Raglan", "value": "C126943"},
           {"label": "Resolven", "value": "C126944"},
           {"label": "Rhayader", "value": "C126945"},
           {"label": "Rhondda", "value": "C126946"},
           {"label": "Rhondda Cynon Taf", "value": "C126947"},
           {"label": "Rhoose", "value": "C126948"},
           {"label": "Rhosllanerchrugog", "value": "C126949"},
           {"label": "Rhosneigr", "value": "C126950"},
           {"label": "Rhuddlan", "value": "C126951"},
           {"label": "Rhyl", "value": "C126952"},
           {"label": "Rhymney", "value": "C126953"},
           {"label": "Risca", "value": "C126954"},
           {"label": "Rogiet", "value": "C126955"},
           {"label": "Rossett", "value": "C126956"},
           {"label": "Ruabon", "value": "C126957"},
           {"label": "Ruthin", "value": "C126958"},
           {"label": "Saint Asaph", "value": "C126959"},
           {"label": "Saint Clears", "value": "C126960"},
           {"label": "Saint Davids", "value": "C126961"},
           {"label": "Saundersfoot", "value": "C126962"},
           {"label": "Seven Sisters", "value": "C126963"},
           {"label": "Sir Powys", "value": "C126964"},
           {"label": "Southgate", "value": "C126965"},
           {"label": "Spittal", "value": "C126966"},
           {"label": "Steynton", "value": "C126967"},
           {"label": "Swansea", "value": "C126968"},
           {"label": "Taibach", "value": "C126969"},
           {"label": "Tal-y-bont", "value": "C126970"},
           {"label": "Talgarth", "value": "C126971"},
           {"label": "Talysarn", "value": "C126972"},
           {"label": "Templeton", "value": "C126973"},
           {"label": "Tenby", "value": "C126974"},
           {"label": "Three Crosses", "value": "C126975"},
           {"label": "Tintern", "value": "C126976"},
           {"label": "Tonypandy", "value": "C126977"},
           {"label": "Tonyrefail", "value": "C126978"},
           {"label": "Torfaen County Borough", "value": "C126979"},
           {"label": "Trawsfynydd", "value": "C126980"},
           {"label": "Tredegar", "value": "C126981"},
           {"label": "Trefnant", "value": "C126982"},
           {"label": "Tregaron", "value": "C126983"},
           {"label": "Tregarth", "value": "C126984"},
           {"label": "Treharris", "value": "C126985"},
           {"label": "Treorchy", "value": "C126986"},
           {"label": "Treuddyn", "value": "C126987"},
           {"label": "Trimsaran", "value": "C126988"},
           {"label": "Tumble", "value": "C126989"},
           {"label": "Tycroes", "value": "C126990"},
           {"label": "Tywyn", "value": "C126991"},
           {"label": "Undy", "value": "C126992"},
           {"label": "Usk", "value": "C126993"},
           {"label": "Vale of Glamorgan", "value": "C126994"},
           {"label": "Valley", "value": "C126995"},
           {"label": "Victoria", "value": "C126996"},
           {"label": "Welshpool", "value": "C126997"},
           {"label": "Wenvoe", "value": "C126998"},
           {"label": "Whitland", "value": "C126999"},
           {"label": "Wick", "value": "C127000"},
           {"label": "Wrexham", "value": "C127001"},
           {"label": "Y Felinheli", "value": "C127002"},
           {"label": "Ynysybwl", "value": "C127003"},
           {"label": "Ystalyfera", "value": "C127004"}],
 "Wallonia": [{"label": "Aiseau", "value": "C9332"},
              {"label": "Amay", "value": "C9333"},
              {"label": "Ambleve", "value": "C9334"},
              {"label": "Andenne", "value": "C9335"},
              {"label": "Anderlues", "value": "C9336"},
              {"label": "Anhee", "value": "C9337"},
              {"label": "Ans", "value": "C9338"},
              {"label": "Anthisnes", "value": "C9339"},
              {"label": "Antoing", "value": "C9340"},
              {"label": "Arlon", "value": "C9341"},
              {"label": "Assesse", "value": "C9342"},
              {"label": "Ath", "value": "C9343"},
              {"label": "Attert", "value": "C9344"},
              {"label": "Aubange", "value": "C9345"},
              {"label": "Aubel", "value": "C9346"},
              {"label": "Awans", "value": "C9347"},
              {"label": "Aywaille", "value": "C9348"},
              {"label": "Baelen", "value": "C9349"},
              {"label": "Basse Lasne", "value": "C9350"},
              {"label": "Bassenge", "value": "C9351"},
              {"label": "Bastogne", "value": "C9352"},
              {"label": "Beaumont", "value": "C9353"},
              {"label": "Beauraing", "value": "C9354"},
              {"label": "Beauvechain", "value": "C9355"},
              {"label": "Beloeil", "value": "C9356"},
              {"label": "Berloz", "value": "C9357"},
              {"label": "Bernissart", "value": "C9358"},
              {"label": "Bertogne", "value": "C9359"},
              {"label": "Bertrix", "value": "C9360"},
              {"label": "Beyne-Heusay", "value": "C9361"},
              {"label": "Bievre", "value": "C9362"},
              {"label": "Binche", "value": "C9363"},
              {"label": "Blegny", "value": "C9364"},
              {"label": "Bouillon", "value": "C9365"},
              {"label": "Boussu", "value": "C9366"},
              {"label": "Braine-lAlleud", "value": "C9367"},
              {"label": "Braine-le-Chateau", "value": "C9368"},
              {"label": "Braine-le-Comte", "value": "C9369"},
              {"label": "Braives", "value": "C9370"},
              {"label": "Brugelette", "value": "C9371"},
              {"label": "Brunehault", "value": "C9372"},
              {"label": "Bullange", "value": "C9373"},
              {"label": "Burdinne", "value": "C9374"},
              {"label": "Butgenbach", "value": "C9375"},
              {"label": "Celles", "value": "C9376"},
              {"label": "Cerfontaine", "value": "C9377"},
              {"label": "Chapelle-lez-Herlaimont", "value": "C9378"},
              {"label": "Charleroi", "value": "C9379"},
              {"label": "Chastre-Villeroux-Blanmont", "value": "C9380"},
              {"label": "Chatelet", "value": "C9381"},
              {"label": "Chaudfontaine", "value": "C9382"},
              {"label": "Chaumont-Gistoux", "value": "C9383"},
              {"label": "Chievres", "value": "C9384"},
              {"label": "Chimay", "value": "C9385"},
              {"label": "Chiny", "value": "C9386"},
              {"label": "Ciney", "value": "C9387"},
              {"label": "Clavier", "value": "C9388"},
              {"label": "Colfontaine", "value": "C9389"},
              {"label": "Comblain-au-Pont", "value": "C9390"},
              {"label": "Courcelles", "value": "C9391"},
              {"label": "Court-Saint-etienne", "value": "C9392"},
              {"label": "Couvin", "value": "C9393"},
              {"label": "Crisnee", "value": "C9394"},
              {"label": "Dalhem", "value": "C9395"},
              {"label": "Daverdisse", "value": "C9396"},
              {"label": "Dinant", "value": "C9397"},
              {"label": "Dison", "value": "C9398"},
              {"label": "Doische", "value": "C9399"},
              {"label": "Donceel", "value": "C9400"},
              {"label": "Dour", "value": "C9401"},
              {"label": "Durbuy", "value": "C9402"},
              {"label": "ecaussinnes-dEnghien", "value": "C9403"},
              {"label": "eghezee", "value": "C9404"},
              {"label": "Ellezelles", "value": "C9405"},
              {"label": "Enghien", "value": "C9406"},
              {"label": "Engis", "value": "C9407"},
              {"label": "erezee", "value": "C9408"},
              {"label": "Erquelinnes", "value": "C9409"},
              {"label": "Esneux", "value": "C9410"},
              {"label": "Estaimpuis", "value": "C9411"},
              {"label": "Estinnes-au-Val", "value": "C9412"},
              {"label": "etalle", "value": "C9413"},
              {"label": "Eupen", "value": "C9414"},
              {"label": "Faimes", "value": "C9415"},
              {"label": "Farciennes", "value": "C9416"},
              {"label": "Fauvillers", "value": "C9417"},
              {"label": "Ferrieres", "value": "C9418"},
              {"label": "Fexhe-le-Haut-Clocher", "value": "C9419"},
              {"label": "Flemalle-Haute", "value": "C9420"},
              {"label": "Fleron", "value": "C9421"},
              {"label": "Fleurus", "value": "C9422"},
              {"label": "Flobecq", "value": "C9423"},
              {"label": "Floreffe", "value": "C9424"},
              {"label": "Florennes", "value": "C9425"},
              {"label": "Florenville", "value": "C9426"},
              {"label": "Forville", "value": "C9427"},
              {"label": "Fosses-la-Ville", "value": "C9428"},
              {"label": "Frameries", "value": "C9429"},
              {"label": "Frasnes-lez-Buissenal", "value": "C9430"},
              {"label": "Froidchapelle", "value": "C9431"},
              {"label": "Gedinne", "value": "C9432"},
              {"label": "Geer", "value": "C9433"},
              {"label": "Gembloux", "value": "C9434"},
              {"label": "Genappe", "value": "C9435"},
              {"label": "Gerpinnes", "value": "C9436"},
              {"label": "Gesves", "value": "C9437"},
              {"label": "Gouvy", "value": "C9438"},
              {"label": "Grez-Doiceau", "value": "C9439"},
              {"label": "Habay-la-Vieille", "value": "C9440"},
              {"label": "Hamoir", "value": "C9441"},
              {"label": "Hamois", "value": "C9442"},
              {"label": "Hannut", "value": "C9443"},
              {"label": "Hastiere-Lavaux", "value": "C9444"},
              {"label": "Havelange", "value": "C9445"},
              {"label": "Hensies", "value": "C9446"},
              {"label": "Herbeumont", "value": "C9447"},
              {"label": "Heron", "value": "C9448"},
              {"label": "Herstal", "value": "C9449"},
              {"label": "Herve", "value": "C9450"},
              {"label": "Hotton", "value": "C9451"},
              {"label": "Houffalize", "value": "C9452"},
              {"label": "Houyet", "value": "C9453"},
              {"label": "Huy", "value": "C9454"},
              {"label": "Incourt", "value": "C9455"},
              {"label": "Ittre", "value": "C9456"},
              {"label": "Jalhay", "value": "C9457"},
              {"label": "Jodoigne", "value": "C9458"},
              {"label": "Juprelle", "value": "C9459"},
              {"label": "Jurbise", "value": "C9460"},
              {"label": "La Bruyere", "value": "C9461"},
              {"label": "La Calamine", "value": "C9462"},
              {"label": "La Hulpe", "value": "C9463"},
              {"label": "La Louviere", "value": "C9464"},
              {"label": "La Roche-en-Ardenne", "value": "C9465"},
              {"label": "Leglise", "value": "C9466"},
              {"label": "Lens", "value": "C9467"},
              {"label": "Lessines", "value": "C9468"},
              {"label": "Libin", "value": "C9469"},
              {"label": "Liege", "value": "C9470"},
              {"label": "Lierneux", "value": "C9471"},
              {"label": "Limbourg", "value": "C9472"},
              {"label": "Lincent", "value": "C9473"},
              {"label": "Lobbes", "value": "C9474"},
              {"label": "Lontzen", "value": "C9475"},
              {"label": "Louvain-la-Neuve", "value": "C9476"},
              {"label": "Malmedy", "value": "C9477"},
              {"label": "Manage", "value": "C9478"},
              {"label": "Manhay", "value": "C9479"},
              {"label": "Marche-en-Famenne", "value": "C9480"},
              {"label": "Marchin", "value": "C9481"},
              {"label": "Martelange", "value": "C9482"},
              {"label": "Meix-devant-Virton", "value": "C9483"},
              {"label": "Merbes-le-Chateau", "value": "C9484"},
              {"label": "Messancy", "value": "C9485"},
              {"label": "Mettet", "value": "C9486"},
              {"label": "Modave", "value": "C9487"},
              {"label": "Momignies", "value": "C9488"},
              {"label": "Mons", "value": "C9489"},
              {"label": "Mons-lez-Liege", "value": "C9490"},
              {"label": "Mont-Saint-Guibert", "value": "C9491"},
              {"label": "Morlanwelz-Mariemont", "value": "C9492"},
              {"label": "Mouscron", "value": "C9493"},
              {"label": "Musson", "value": "C9494"},
              {"label": "Namur", "value": "C9495"},
              {"label": "Nandrin", "value": "C9496"},
              {"label": "Nassogne", "value": "C9497"},
              {"label": "Neufchateau", "value": "C9498"},
              {"label": "Nivelles", "value": "C9499"},
              {"label": "Noville-les-Bois", "value": "C9500"},
              {"label": "Ohey", "value": "C9501"},
              {"label": "Olne", "value": "C9502"},
              {"label": "Onhaye", "value": "C9503"},
              {"label": "Oreye", "value": "C9504"},
              {"label": "Ottignies", "value": "C9505"},
              {"label": "Ouffet", "value": "C9506"},
              {"label": "Oupeye", "value": "C9507"},
              {"label": "Paliseul", "value": "C9508"},
              {"label": "Pecq", "value": "C9509"},
              {"label": "Pepinster", "value": "C9510"},
              {"label": "Peruwelz", "value": "C9511"},
              {"label": "Perwez", "value": "C9512"},
              {"label": "Philippeville", "value": "C9513"},
              {"label": "Plombieres", "value": "C9514"},
              {"label": "Pont-a-Celles", "value": "C9515"},
              {"label": "Profondeville", "value": "C9516"},
              {"label": "Province de Liege", "value": "C9517"},
              {"label": "Province de Namur", "value": "C9518"},
              {"label": "Province du Brabant Wallon", "value": "C9519"},
              {"label": "Province du Hainaut", "value": "C9520"},
              {"label": "Province du Luxembourg", "value": "C9521"},
              {"label": "Quaregnon", "value": "C9522"},
              {"label": "Quevy-le-Petit", "value": "C9523"},
              {"label": "Quievrain", "value": "C9524"},
              {"label": "Raeren", "value": "C9525"},
              {"label": "Ramillies", "value": "C9526"},
              {"label": "Rebecq-Rognon", "value": "C9527"},
              {"label": "Remicourt", "value": "C9528"},
              {"label": "Rendeux", "value": "C9529"},
              {"label": "Rixensart", "value": "C9530"},
              {"label": "Rochefort", "value": "C9531"},
              {"label": "Roeulx", "value": "C9532"},
              {"label": "Rouvroy", "value": "C9533"},
              {"label": "Rumes", "value": "C9534"},
              {"label": "Saint-Ghislain", "value": "C9535"},
              {"label": "Saint-Hubert", "value": "C9536"},
              {"label": "Saint-Leger", "value": "C9537"},
              {"label": "Saint-Nicolas", "value": "C9538"},
              {"label": "Saint-Vith", "value": "C9539"},
              {"label": "Sainte-Ode", "value": "C9540"},
              {"label": "Seneffe", "value": "C9541"},
              {"label": "Seraing", "value": "C9542"},
              {"label": "Silly", "value": "C9543"},
              {"label": "Soignies", "value": "C9544"},
              {"label": "Sombreffe", "value": "C9545"},
              {"label": "Somme-Leuze", "value": "C9546"},
              {"label": "Soumagne", "value": "C9547"},
              {"label": "Spa", "value": "C9548"},
              {"label": "Sprimont", "value": "C9549"},
              {"label": "Stavelot", "value": "C9550"},
              {"label": "Stoumont", "value": "C9551"},
              {"label": "Tellin", "value": "C9552"},
              {"label": "Tenneville", "value": "C9553"},
              {"label": "Theux", "value": "C9554"},
              {"label": "Thuin", "value": "C9555"},
              {"label": "Tinlot", "value": "C9556"},
              {"label": "Tintigny", "value": "C9557"},
              {"label": "Tournai", "value": "C9558"},
              {"label": "Trois-Ponts", "value": "C9559"},
              {"label": "Trooz", "value": "C9560"},
              {"label": "Tubize", "value": "C9561"},
              {"label": "Vaux-sur-Sure", "value": "C9562"},
              {"label": "Verlaine", "value": "C9563"},
              {"label": "Verviers", "value": "C9564"},
              {"label": "Vielsalm", "value": "C9565"},
              {"label": "Villers-la-Ville", "value": "C9566"},
              {"label": "Villers-le-Bouillet", "value": "C9567"},
              {"label": "Virton", "value": "C9568"},
              {"label": "Vise", "value": "C9569"},
              {"label": "Waimes", "value": "C9570"},
              {"label": "Walcourt", "value": "C9571"},
              {"label": "Walhain-Saint-Paul", "value": "C9572"},
              {"label": "Wanze", "value": "C9573"},
              {"label": "Waremme", "value": "C9574"},
              {"label": "Wasseiges", "value": "C9575"},
              {"label": "Waterloo", "value": "C9576"},
              {"label": "Wavre", "value": "C9577"},
              {"label": "Welkenraedt", "value": "C9578"},
              {"label": "Wellin", "value": "C9579"},
              {"label": "Yvoir", "value": "C9580"}],
 "Wangdue Phodrang District": [{"label": "Trashi Yangtse", "value": "C9651"}],
 "Wanica District": [{"label": "Lelydorp", "value": "C115472"}],
 "Warmian-Masurian Voivodeship": [{"label": "Banie Mazurskie",
                                   "value": "C90534"},
                                  {"label": "Barciany", "value": "C90535"},
                                  {"label": "Barczewo", "value": "C90536"},
                                  {"label": "Bartoszyce", "value": "C90537"},
                                  {"label": "Biala Piska", "value": "C90538"},
                                  {"label": "Biskupiec", "value": "C90539"},
                                  {"label": "Bisztynek", "value": "C90540"},
                                  {"label": "Braniewo", "value": "C90541"},
                                  {"label": "Czerwonka", "value": "C90542"},
                                  {"label": "Dabrowno", "value": "C90543"},
                                  {"label": "Dobre Miasto", "value": "C90544"},
                                  {"label": "Dywity", "value": "C90545"},
                                  {"label": "Dzialdowo", "value": "C90546"},
                                  {"label": "Dzwierzuty", "value": "C90547"},
                                  {"label": "Elblag", "value": "C90548"},
                                  {"label": "Elk", "value": "C90549"},
                                  {"label": "Frombork", "value": "C90550"},
                                  {"label": "Gierloz", "value": "C90551"},
                                  {"label": "Gierzwald", "value": "C90552"},
                                  {"label": "Gietrzwald", "value": "C90553"},
                                  {"label": "Gizycko", "value": "C90554"},
                                  {"label": "Goldap", "value": "C90555"},
                                  {"label": "Gorowo Ilaweckie",
                                   "value": "C90556"},
                                  {"label": "Gronowo Elblaskie",
                                   "value": "C90557"},
                                  {"label": "Gronowo Gorne", "value": "C90558"},
                                  {"label": "Ilawa", "value": "C90559"},
                                  {"label": "Ilowo -Osada", "value": "C90560"},
                                  {"label": "Jedwabno", "value": "C90561"},
                                  {"label": "Jeziorany", "value": "C90562"},
                                  {"label": "Jonkowo", "value": "C90563"},
                                  {"label": "Ketrzyn", "value": "C90564"},
                                  {"label": "Kisielice", "value": "C90565"},
                                  {"label": "Korsze", "value": "C90566"},
                                  {"label": "Kortowo", "value": "C90567"},
                                  {"label": "Kowale Oleckie",
                                   "value": "C90568"},
                                  {"label": "Kozlowo", "value": "C90569"},
                                  {"label": "Kruklanki", "value": "C90570"},
                                  {"label": "Kurzetnik", "value": "C90571"},
                                  {"label": "Lelkowo", "value": "C90572"},
                                  {"label": "Lidzbark", "value": "C90573"},
                                  {"label": "Lidzbark Warminski",
                                   "value": "C90574"},
                                  {"label": "Lubawa", "value": "C90575"},
                                  {"label": "Lubomino", "value": "C90576"},
                                  {"label": "Maldyty", "value": "C90577"},
                                  {"label": "Mikolajki", "value": "C90578"},
                                  {"label": "Milakowo", "value": "C90579"},
                                  {"label": "Milomlyn", "value": "C90580"},
                                  {"label": "Mlynary", "value": "C90581"},
                                  {"label": "Morag", "value": "C90582"},
                                  {"label": "Mragowo", "value": "C90583"},
                                  {"label": "Nidzica", "value": "C90584"},
                                  {"label": "Nowe Miasto Lubawskie",
                                   "value": "C90585"},
                                  {"label": "Olecko", "value": "C90586"},
                                  {"label": "Olsztyn", "value": "C90587"},
                                  {"label": "Olsztynek", "value": "C90588"},
                                  {"label": "Orneta", "value": "C90589"},
                                  {"label": "Orzysz", "value": "C90590"},
                                  {"label": "Ostroda", "value": "C90591"},
                                  {"label": "Paslek", "value": "C90592"},
                                  {"label": "Pasym", "value": "C90593"},
                                  {"label": "Piecki", "value": "C90594"},
                                  {"label": "Pieniezno", "value": "C90595"},
                                  {"label": "Pisz", "value": "C90596"},
                                  {"label": "Powiat bartoszycki",
                                   "value": "C90597"},
                                  {"label": "Powiat braniewski",
                                   "value": "C90598"},
                                  {"label": "Powiat dzialdowski",
                                   "value": "C90599"},
                                  {"label": "Powiat elblaski",
                                   "value": "C90600"},
                                  {"label": "Powiat elcki", "value": "C90601"},
                                  {"label": "Powiat gizycki",
                                   "value": "C90602"},
                                  {"label": "Powiat goldapski",
                                   "value": "C90603"},
                                  {"label": "Powiat ilawski",
                                   "value": "C90604"},
                                  {"label": "Powiat ketrzynski",
                                   "value": "C90605"},
                                  {"label": "Powiat lidzbarski",
                                   "value": "C90606"},
                                  {"label": "Powiat mragowski",
                                   "value": "C90607"},
                                  {"label": "Powiat nidzicki",
                                   "value": "C90608"},
                                  {"label": "Powiat nowomiejski",
                                   "value": "C90609"},
                                  {"label": "Powiat olecki", "value": "C90610"},
                                  {"label": "Powiat olsztynski",
                                   "value": "C90611"},
                                  {"label": "Powiat ostrodzki",
                                   "value": "C90612"},
                                  {"label": "Powiat piski", "value": "C90613"},
                                  {"label": "Powiat szczycienski",
                                   "value": "C90614"},
                                  {"label": "Powiat wegorzewski",
                                   "value": "C90615"},
                                  {"label": "Pozezdrze", "value": "C90616"},
                                  {"label": "Prostki", "value": "C90617"},
                                  {"label": "Purda", "value": "C90618"},
                                  {"label": "Reszel", "value": "C90619"},
                                  {"label": "Rozogi", "value": "C90620"},
                                  {"label": "Ruciane-Nida", "value": "C90621"},
                                  {"label": "Rybno", "value": "C90622"},
                                  {"label": "Ryn", "value": "C90623"},
                                  {"label": "Sepopol", "value": "C90624"},
                                  {"label": "Srokowo", "value": "C90625"},
                                  {"label": "Stare Juchy", "value": "C90626"},
                                  {"label": "Stawiguda", "value": "C90627"},
                                  {"label": "Susz", "value": "C90628"},
                                  {"label": "Swietajno", "value": "C90629"},
                                  {"label": "Szczytno", "value": "C90630"},
                                  {"label": "Tolkmicko", "value": "C90631"},
                                  {"label": "Wegorzewo", "value": "C90632"},
                                  {"label": "Wielbark", "value": "C90633"},
                                  {"label": "Wydminy", "value": "C90634"},
                                  {"label": "Zalewo", "value": "C90635"}],
 "Washington": [{"label": "Aberdeen", "value": "C145604"},
                {"label": "Adams County", "value": "C145605"},
                {"label": "Ahtanum", "value": "C145606"},
                {"label": "Airway Heights", "value": "C145607"},
                {"label": "Alderton", "value": "C145608"},
                {"label": "Alderwood Manor", "value": "C145609"},
                {"label": "Algona", "value": "C145610"},
                {"label": "Allyn", "value": "C145611"},
                {"label": "Amboy", "value": "C145612"},
                {"label": "Ames Lake", "value": "C145613"},
                {"label": "Anacortes", "value": "C145614"},
                {"label": "Arlington", "value": "C145615"},
                {"label": "Arlington Heights", "value": "C145616"},
                {"label": "Artondale", "value": "C145617"},
                {"label": "Asotin", "value": "C145618"},
                {"label": "Asotin County", "value": "C145619"},
                {"label": "Auburn", "value": "C145620"},
                {"label": "Ault Field", "value": "C145621"},
                {"label": "Bainbridge Island", "value": "C145622"},
                {"label": "Bangor Trident Base", "value": "C145623"},
                {"label": "Barberton", "value": "C145624"},
                {"label": "Basin City", "value": "C145625"},
                {"label": "Battle Ground", "value": "C145626"},
                {"label": "Belfair", "value": "C145627"},
                {"label": "Bellevue", "value": "C145628"},
                {"label": "Bellingham", "value": "C145629"},
                {"label": "Benton City", "value": "C145630"},
                {"label": "Benton County", "value": "C145631"},
                {"label": "Bethel", "value": "C145632"},
                {"label": "Big Lake", "value": "C145633"},
                {"label": "Birch Bay", "value": "C145634"},
                {"label": "Black Diamond", "value": "C145635"},
                {"label": "Blaine", "value": "C145636"},
                {"label": "Bonney Lake", "value": "C145637"},
                {"label": "Bothell", "value": "C145638"},
                {"label": "Bothell East", "value": "C145639"},
                {"label": "Bothell West", "value": "C145640"},
                {"label": "Boulevard Park", "value": "C145641"},
                {"label": "Bremerton", "value": "C145642"},
                {"label": "Brewster", "value": "C145643"},
                {"label": "Bridgeport", "value": "C145644"},
                {"label": "Brier", "value": "C145645"},
                {"label": "Browns Point", "value": "C145646"},
                {"label": "Brush Prairie", "value": "C145647"},
                {"label": "Bryant", "value": "C145648"},
                {"label": "Bryn Mawr-Skyway", "value": "C145649"},
                {"label": "Buckley", "value": "C145650"},
                {"label": "Bunk Foss", "value": "C145651"},
                {"label": "Burbank", "value": "C145652"},
                {"label": "Burien", "value": "C145653"},
                {"label": "Burley", "value": "C145654"},
                {"label": "Burlington", "value": "C145655"},
                {"label": "Camano", "value": "C145656"},
                {"label": "Camas", "value": "C145657"},
                {"label": "Canterwood", "value": "C145658"},
                {"label": "Carnation", "value": "C145659"},
                {"label": "Carson", "value": "C145660"},
                {"label": "Cascade Valley", "value": "C145661"},
                {"label": "Cashmere", "value": "C145662"},
                {"label": "Castle Rock", "value": "C145663"},
                {"label": "Cathcart", "value": "C145664"},
                {"label": "Cathlamet", "value": "C145665"},
                {"label": "Central Park", "value": "C145666"},
                {"label": "Centralia", "value": "C145667"},
                {"label": "Chehalis", "value": "C145668"},
                {"label": "Chelan", "value": "C145669"},
                {"label": "Chelan County", "value": "C145670"},
                {"label": "Cheney", "value": "C145671"},
                {"label": "Chewelah", "value": "C145672"},
                {"label": "Chico", "value": "C145673"},
                {"label": "City of Sammamish", "value": "C145674"},
                {"label": "Clallam County", "value": "C145675"},
                {"label": "Clark County", "value": "C145676"},
                {"label": "Clarkston", "value": "C145677"},
                {"label": "Clarkston Heights-Vineland", "value": "C145678"},
                {"label": "Cle Elum", "value": "C145679"},
                {"label": "Clear Lake", "value": "C145680"},
                {"label": "Clearview", "value": "C145681"},
                {"label": "Clyde Hill", "value": "C145682"},
                {"label": "Colfax", "value": "C145683"},
                {"label": "College Place", "value": "C145684"},
                {"label": "Columbia County", "value": "C145685"},
                {"label": "Colville", "value": "C145686"},
                {"label": "Connell", "value": "C145687"},
                {"label": "Cosmopolis", "value": "C145688"},
                {"label": "Cottage Lake", "value": "C145689"},
                {"label": "Coulee Dam", "value": "C145690"},
                {"label": "Country Homes", "value": "C145691"},
                {"label": "Coupeville", "value": "C145692"},
                {"label": "Covington", "value": "C145693"},
                {"label": "Cowlitz County", "value": "C145694"},
                {"label": "Crocker", "value": "C145695"},
                {"label": "Dallesport", "value": "C145696"},
                {"label": "Darrington", "value": "C145697"},
                {"label": "Davenport", "value": "C145698"},
                {"label": "Dayton", "value": "C145699"},
                {"label": "Deer Park", "value": "C145700"},
                {"label": "Des Moines", "value": "C145701"},
                {"label": "Desert Aire", "value": "C145702"},
                {"label": "Dishman", "value": "C145703"},
                {"label": "Dollar Corner", "value": "C145704"},
                {"label": "Douglas County", "value": "C145705"},
                {"label": "DuPont", "value": "C145706"},
                {"label": "Duvall", "value": "C145707"},
                {"label": "East Hill-Meridian", "value": "C145708"},
                {"label": "East Port Orchard", "value": "C145709"},
                {"label": "East Renton Highlands", "value": "C145710"},
                {"label": "East Wenatchee", "value": "C145711"},
                {"label": "East Wenatchee Bench", "value": "C145712"},
                {"label": "Eastgate", "value": "C145713"},
                {"label": "Eastmont", "value": "C145714"},
                {"label": "Eatonville", "value": "C145715"},
                {"label": "Edgewood", "value": "C145716"},
                {"label": "Edmonds", "value": "C145717"},
                {"label": "Electric City", "value": "C145718"},
                {"label": "Elk Plain", "value": "C145719"},
                {"label": "Ellensburg", "value": "C145720"},
                {"label": "Elma", "value": "C145721"},
                {"label": "Enetai", "value": "C145722"},
                {"label": "Entiat", "value": "C145723"},
                {"label": "Enumclaw", "value": "C145724"},
                {"label": "Ephrata", "value": "C145725"},
                {"label": "Erlands Point-Kitsap Lake", "value": "C145726"},
                {"label": "Esperance", "value": "C145727"},
                {"label": "Everett", "value": "C145728"},
                {"label": "Everson", "value": "C145729"},
                {"label": "Fairchild Air Force Base", "value": "C145730"},
                {"label": "Fairwood", "value": "C145731"},
                {"label": "Fall City", "value": "C145732"},
                {"label": "Federal Way", "value": "C145733"},
                {"label": "Felida", "value": "C145734"},
                {"label": "Fern Prairie", "value": "C145735"},
                {"label": "Ferndale", "value": "C145736"},
                {"label": "Ferry County", "value": "C145737"},
                {"label": "Fife", "value": "C145738"},
                {"label": "Fife Heights", "value": "C145739"},
                {"label": "Finley", "value": "C145740"},
                {"label": "Fircrest", "value": "C145741"},
                {"label": "Five Corners", "value": "C145742"},
                {"label": "Fobes Hill", "value": "C145743"},
                {"label": "Fords Prairie", "value": "C145744"},
                {"label": "Forks", "value": "C145745"},
                {"label": "Fox Island", "value": "C145746"},
                {"label": "Franklin County", "value": "C145747"},
                {"label": "Frederickson", "value": "C145748"},
                {"label": "Freeland", "value": "C145749"},
                {"label": "Friday Harbor", "value": "C145750"},
                {"label": "Garfield County", "value": "C145751"},
                {"label": "Garrett", "value": "C145752"},
                {"label": "Geneva", "value": "C145753"},
                {"label": "Gig Harbor", "value": "C145754"},
                {"label": "Gleed", "value": "C145755"},
                {"label": "Gold Bar", "value": "C145756"},
                {"label": "Goldendale", "value": "C145757"},
                {"label": "Graham", "value": "C145758"},
                {"label": "Grand Coulee", "value": "C145759"},
                {"label": "Grand Mound", "value": "C145760"},
                {"label": "Grandview", "value": "C145761"},
                {"label": "Granger", "value": "C145762"},
                {"label": "Granite Falls", "value": "C145763"},
                {"label": "Grant County", "value": "C145764"},
                {"label": "Grays Harbor County", "value": "C145765"},
                {"label": "Hansville", "value": "C145766"},
                {"label": "Hazel Dell", "value": "C145767"},
                {"label": "Highland", "value": "C145768"},
                {"label": "Hobart", "value": "C145769"},
                {"label": "Hockinson", "value": "C145770"},
                {"label": "Home", "value": "C145771"},
                {"label": "Hoquiam", "value": "C145772"},
                {"label": "Indianola", "value": "C145773"},
                {"label": "Inglewood-Finn Hill", "value": "C145774"},
                {"label": "Island County", "value": "C145775"},
                {"label": "Issaquah", "value": "C145776"},
                {"label": "Jefferson County", "value": "C145777"},
                {"label": "Joint Base Lewis McChord", "value": "C145778"},
                {"label": "Kalama", "value": "C145779"},
                {"label": "Kelso", "value": "C145780"},
                {"label": "Kenmore", "value": "C145781"},
                {"label": "Kennewick", "value": "C145782"},
                {"label": "Kent", "value": "C145783"},
                {"label": "Kettle Falls", "value": "C145784"},
                {"label": "Key Center", "value": "C145785"},
                {"label": "King County", "value": "C145786"},
                {"label": "Kingsgate", "value": "C145787"},
                {"label": "Kingston", "value": "C145788"},
                {"label": "Kirkland", "value": "C145789"},
                {"label": "Kitsap County", "value": "C145790"},
                {"label": "Kittitas", "value": "C145791"},
                {"label": "Kittitas County", "value": "C145792"},
                {"label": "Klahanie", "value": "C145793"},
                {"label": "Klickitat County", "value": "C145794"},
                {"label": "La Center", "value": "C145795"},
                {"label": "Lacey", "value": "C145796"},
                {"label": "Lake Forest Park", "value": "C145797"},
                {"label": "Lake Marcel-Stillwater", "value": "C145798"},
                {"label": "Lake Morton-Berrydale", "value": "C145799"},
                {"label": "Lake Shore", "value": "C145800"},
                {"label": "Lake Stevens", "value": "C145801"},
                {"label": "Lake Stickney", "value": "C145802"},
                {"label": "Lakeland North", "value": "C145803"},
                {"label": "Lakeland South", "value": "C145804"},
                {"label": "Lakewood", "value": "C145805"},
                {"label": "Langley", "value": "C145806"},
                {"label": "Larch Way", "value": "C145807"},
                {"label": "Lea Hill", "value": "C145808"},
                {"label": "Leavenworth", "value": "C145809"},
                {"label": "Lewis County", "value": "C145810"},
                {"label": "Lewisville", "value": "C145811"},
                {"label": "Liberty Lake", "value": "C145812"},
                {"label": "Lincoln County", "value": "C145813"},
                {"label": "Lochsloy", "value": "C145814"},
                {"label": "Lofall", "value": "C145815"},
                {"label": "Long Beach", "value": "C145816"},
                {"label": "Longbranch", "value": "C145817"},
                {"label": "Longview", "value": "C145818"},
                {"label": "Longview Heights", "value": "C145819"},
                {"label": "Lynden", "value": "C145820"},
                {"label": "Lynnwood", "value": "C145821"},
                {"label": "Mabton", "value": "C145822"},
                {"label": "Machias", "value": "C145823"},
                {"label": "Maltby", "value": "C145824"},
                {"label": "Manchester", "value": "C145825"},
                {"label": "Manson", "value": "C145826"},
                {"label": "Maple Heights-Lake Desire", "value": "C145827"},
                {"label": "Maple Valley", "value": "C145828"},
                {"label": "Maplewood", "value": "C145829"},
                {"label": "Marietta", "value": "C145830"},
                {"label": "Marietta-Alderwood", "value": "C145831"},
                {"label": "Martha Lake", "value": "C145832"},
                {"label": "Marysville", "value": "C145833"},
                {"label": "Mason County", "value": "C145834"},
                {"label": "Mattawa", "value": "C145835"},
                {"label": "McChord Air Force Base", "value": "C145836"},
                {"label": "McCleary", "value": "C145837"},
                {"label": "McMillin", "value": "C145838"},
                {"label": "Mead", "value": "C145839"},
                {"label": "Meadow Glade", "value": "C145840"},
                {"label": "Meadowdale", "value": "C145841"},
                {"label": "Medical Lake", "value": "C145842"},
                {"label": "Medina", "value": "C145843"},
                {"label": "Mercer Island", "value": "C145844"},
                {"label": "Midland", "value": "C145845"},
                {"label": "Mill Creek", "value": "C145846"},
                {"label": "Mill Creek East", "value": "C145847"},
                {"label": "Mill Plain", "value": "C145848"},
                {"label": "Millwood", "value": "C145849"},
                {"label": "Milton", "value": "C145850"},
                {"label": "Minnehaha", "value": "C145851"},
                {"label": "Mirrormont", "value": "C145852"},
                {"label": "Monroe", "value": "C145853"},
                {"label": "Monroe North", "value": "C145854"},
                {"label": "Montesano", "value": "C145855"},
                {"label": "Morton", "value": "C145856"},
                {"label": "Moses Lake", "value": "C145857"},
                {"label": "Moses Lake North", "value": "C145858"},
                {"label": "Mount Vernon", "value": "C145859"},
                {"label": "Mount Vista", "value": "C145860"},
                {"label": "Mountlake Terrace", "value": "C145861"},
                {"label": "Mukilteo", "value": "C145862"},
                {"label": "Napavine", "value": "C145863"},
                {"label": "Navy Yard City", "value": "C145864"},
                {"label": "Newcastle", "value": "C145865"},
                {"label": "Newport", "value": "C145866"},
                {"label": "Nooksack", "value": "C145867"},
                {"label": "Normandy Park", "value": "C145868"},
                {"label": "North Bend", "value": "C145869"},
                {"label": "North Creek", "value": "C145870"},
                {"label": "North Fort Lewis", "value": "C145871"},
                {"label": "North Puyallup", "value": "C145872"},
                {"label": "North Yelm", "value": "C145873"},
                {"label": "Oak Harbor", "value": "C145874"},
                {"label": "Ocean Park", "value": "C145875"},
                {"label": "Ocean Shores", "value": "C145876"},
                {"label": "Okanogan", "value": "C145877"},
                {"label": "Okanogan County", "value": "C145878"},
                {"label": "Olympia", "value": "C145879"},
                {"label": "Omak", "value": "C145880"},
                {"label": "Opportunity", "value": "C145881"},
                {"label": "Orchards", "value": "C145882"},
                {"label": "Oroville", "value": "C145883"},
                {"label": "Orting", "value": "C145884"},
                {"label": "Othello", "value": "C145885"},
                {"label": "Otis Orchards-East Farms", "value": "C145886"},
                {"label": "Pacific", "value": "C145887"},
                {"label": "Pacific County", "value": "C145888"},
                {"label": "Palouse", "value": "C145889"},
                {"label": "Parkland", "value": "C145890"},
                {"label": "Parkwood", "value": "C145891"},
                {"label": "Pasco", "value": "C145892"},
                {"label": "Peaceful Valley", "value": "C145893"},
                {"label": "Pend Oreille County", "value": "C145894"},
                {"label": "Picnic Point", "value": "C145895"},
                {"label": "Picnic Point-North Lynnwood", "value": "C145896"},
                {"label": "Pierce County", "value": "C145897"},
                {"label": "Point Roberts", "value": "C145898"},
                {"label": "Pomeroy", "value": "C145899"},
                {"label": "Port Angeles", "value": "C145900"},
                {"label": "Port Angeles East", "value": "C145901"},
                {"label": "Port Hadlock-Irondale", "value": "C145902"},
                {"label": "Port Ludlow", "value": "C145903"},
                {"label": "Port Orchard", "value": "C145904"},
                {"label": "Port Townsend", "value": "C145905"},
                {"label": "Poulsbo", "value": "C145906"},
                {"label": "Prairie Heights", "value": "C145907"},
                {"label": "Prairie Ridge", "value": "C145908"},
                {"label": "Prosser", "value": "C145909"},
                {"label": "Pullman", "value": "C145910"},
                {"label": "Purdy", "value": "C145911"},
                {"label": "Puyallup", "value": "C145912"},
                {"label": "Quincy", "value": "C145913"},
                {"label": "Rainier", "value": "C145914"},
                {"label": "Ravensdale", "value": "C145915"},
                {"label": "Raymond", "value": "C145916"},
                {"label": "Redmond", "value": "C145917"},
                {"label": "Renton", "value": "C145918"},
                {"label": "Republic", "value": "C145919"},
                {"label": "Richland", "value": "C145920"},
                {"label": "Ridgefield", "value": "C145921"},
                {"label": "Ritzville", "value": "C145922"},
                {"label": "Riverbend", "value": "C145923"},
                {"label": "Riverton", "value": "C145924"},
                {"label": "Rochester", "value": "C145925"},
                {"label": "Rocky Point", "value": "C145926"},
                {"label": "Rosedale", "value": "C145927"},
                {"label": "Royal City", "value": "C145928"},
                {"label": "Salmon Creek", "value": "C145929"},
                {"label": "Sammamish", "value": "C145930"},
                {"label": "San Juan County", "value": "C145931"},
                {"label": "Seabeck", "value": "C145932"},
                {"label": "SeaTac", "value": "C145933"},
                {"label": "Seattle", "value": "C145934"},
                {"label": "Sedro-Woolley", "value": "C145935"},
                {"label": "Selah", "value": "C145936"},
                {"label": "Sequim", "value": "C145937"},
                {"label": "Shelton", "value": "C145938"},
                {"label": "Shoreline", "value": "C145939"},
                {"label": "Silver Firs", "value": "C145940"},
                {"label": "Silverdale", "value": "C145941"},
                {"label": "Sisco Heights", "value": "C145942"},
                {"label": "Skagit County", "value": "C145943"},
                {"label": "Skamania County", "value": "C145944"},
                {"label": "Smokey Point", "value": "C145945"},
                {"label": "Snohomish", "value": "C145946"},
                {"label": "Snohomish County", "value": "C145947"},
                {"label": "Snoqualmie", "value": "C145948"},
                {"label": "Soap Lake", "value": "C145949"},
                {"label": "South Bend", "value": "C145950"},
                {"label": "South Hill", "value": "C145951"},
                {"label": "South Wenatchee", "value": "C145952"},
                {"label": "Southworth", "value": "C145953"},
                {"label": "Spanaway", "value": "C145954"},
                {"label": "Spokane", "value": "C145955"},
                {"label": "Spokane County", "value": "C145956"},
                {"label": "Spokane Valley", "value": "C145957"},
                {"label": "Stanwood", "value": "C145958"},
                {"label": "Steilacoom", "value": "C145959"},
                {"label": "Stevens County", "value": "C145960"},
                {"label": "Stevenson", "value": "C145961"},
                {"label": "Sudden Valley", "value": "C145962"},
                {"label": "Sultan", "value": "C145963"},
                {"label": "Sumas", "value": "C145964"},
                {"label": "Summit", "value": "C145965"},
                {"label": "Summit View", "value": "C145966"},
                {"label": "Sumner", "value": "C145967"},
                {"label": "Sunnyside", "value": "C145968"},
                {"label": "Sunnyslope", "value": "C145969"},
                {"label": "Suquamish", "value": "C145970"},
                {"label": "Tacoma", "value": "C145971"},
                {"label": "Tanglewilde", "value": "C145972"},
                {"label": "Tanglewilde-Thompson Place", "value": "C145973"},
                {"label": "Tanner", "value": "C145974"},
                {"label": "Tenino", "value": "C145975"},
                {"label": "Terrace Heights", "value": "C145976"},
                {"label": "Three Lakes", "value": "C145977"},
                {"label": "Thurston County", "value": "C145978"},
                {"label": "Tieton", "value": "C145979"},
                {"label": "Tonasket", "value": "C145980"},
                {"label": "Toppenish", "value": "C145981"},
                {"label": "Town and Country", "value": "C145982"},
                {"label": "Tracyton", "value": "C145983"},
                {"label": "Trentwood", "value": "C145984"},
                {"label": "Tukwila", "value": "C145985"},
                {"label": "Tulalip", "value": "C145986"},
                {"label": "Tulalip Bay", "value": "C145987"},
                {"label": "Tumwater", "value": "C145988"},
                {"label": "Union Gap", "value": "C145989"},
                {"label": "Union Hill-Novelty Hill", "value": "C145990"},
                {"label": "University Place", "value": "C145991"},
                {"label": "Vancouver", "value": "C145992"},
                {"label": "Vashon", "value": "C145993"},
                {"label": "Venersborg", "value": "C145994"},
                {"label": "Veradale", "value": "C145995"},
                {"label": "Wahkiakum County", "value": "C145996"},
                {"label": "Waitsburg", "value": "C145997"},
                {"label": "Walla Walla", "value": "C145998"},
                {"label": "Walla Walla County", "value": "C145999"},
                {"label": "Walla Walla East", "value": "C146000"},
                {"label": "Waller", "value": "C146001"},
                {"label": "Walnut Grove", "value": "C146002"},
                {"label": "Wapato", "value": "C146003"},
                {"label": "Warden", "value": "C146004"},
                {"label": "Warm Beach", "value": "C146005"},
                {"label": "Washougal", "value": "C146006"},
                {"label": "Waterville", "value": "C146007"},
                {"label": "Wauna", "value": "C146008"},
                {"label": "Wenatchee", "value": "C146009"},
                {"label": "West Clarkston-Highland", "value": "C146010"},
                {"label": "West Lake Sammamish", "value": "C146011"},
                {"label": "West Lake Stevens", "value": "C146012"},
                {"label": "West Longview", "value": "C146013"},
                {"label": "West Pasco", "value": "C146014"},
                {"label": "West Richland", "value": "C146015"},
                {"label": "West Side Highway", "value": "C146016"},
                {"label": "West Valley", "value": "C146017"},
                {"label": "West Wenatchee", "value": "C146018"},
                {"label": "Westport", "value": "C146019"},
                {"label": "Whatcom County", "value": "C146020"},
                {"label": "White Center", "value": "C146021"},
                {"label": "White Salmon", "value": "C146022"},
                {"label": "Whitman County", "value": "C146023"},
                {"label": "Wilderness Rim", "value": "C146024"},
                {"label": "Winlock", "value": "C146025"},
                {"label": "Wollochet", "value": "C146026"},
                {"label": "Woodinville", "value": "C146027"},
                {"label": "Woodland", "value": "C146028"},
                {"label": "Woods Creek", "value": "C146029"},
                {"label": "Woodway", "value": "C146030"},
                {"label": "Yacolt", "value": "C146031"},
                {"label": "Yakima", "value": "C146032"},
                {"label": "Yakima County", "value": "C146033"},
                {"label": "Yarrow Point", "value": "C146034"},
                {"label": "Yelm", "value": "C146035"},
                {"label": "Zillah", "value": "C146036"}],
 "Wasit Governorate": [{"label": "Al Aziziyah", "value": "C51210"},
                       {"label": "Al Hayy", "value": "C51211"},
                       {"label": "Al Kut", "value": "C51212"},
                       {"label": "As Suwayrah", "value": "C51213"}],
 "Wele-Nzas Province": [{"label": "Aconibe", "value": "C24482"},
                        {"label": "Anisoc", "value": "C24483"},
                        {"label": "Ayene", "value": "C24484"},
                        {"label": "Mengomeyen", "value": "C24485"},
                        {"label": "Mongomo", "value": "C24486"},
                        {"label": "Nsok", "value": "C24487"}],
 "Wellington Region": [{"label": "Brooklyn", "value": "C77868"},
                       {"label": "Castlepoint", "value": "C77869"},
                       {"label": "Kapiti Coast District", "value": "C77870"},
                       {"label": "Kelburn", "value": "C77871"},
                       {"label": "Khandallah", "value": "C77872"},
                       {"label": "Lower Hutt", "value": "C77873"},
                       {"label": "Masterton", "value": "C77874"},
                       {"label": "Otaki", "value": "C77875"},
                       {"label": "Paraparaumu", "value": "C77876"},
                       {"label": "Petone", "value": "C77877"},
                       {"label": "Porirua", "value": "C77878"},
                       {"label": "South Wairarapa District", "value": "C77879"},
                       {"label": "Upper Hutt", "value": "C77880"},
                       {"label": "Waipawa", "value": "C77881"},
                       {"label": "Wellington", "value": "C77882"},
                       {"label": "Wellington City", "value": "C77883"}],
 "West": [{"label": "Bafang", "value": "C16687"},
          {"label": "Bafoussam", "value": "C16688"},
          {"label": "Bamendjou", "value": "C16689"},
          {"label": "Bana", "value": "C16690"},
          {"label": "Bandjoun", "value": "C16691"},
          {"label": "Bangangte", "value": "C16692"},
          {"label": "Bansoa", "value": "C16693"},
          {"label": "Bazou", "value": "C16694"},
          {"label": "Dschang", "value": "C16695"},
          {"label": "Foumban", "value": "C16696"},
          {"label": "Foumbot", "value": "C16697"},
          {"label": "Hauts-Plateaux", "value": "C16698"},
          {"label": "Koung-Khi", "value": "C16699"},
          {"label": "Mbouda", "value": "C16700"},
          {"label": "Ngou", "value": "C16701"},
          {"label": "Noun", "value": "C16702"},
          {"label": "Tonga", "value": "C16703"}],
 "West Azarbaijan": [{"label": "Bukan", "value": "C51081"},
                     {"label": "Chaypareh", "value": "C51082"},
                     {"label": "Khowy", "value": "C51083"},
                     {"label": "Mahabad", "value": "C51084"},
                     {"label": "Miandoab", "value": "C51085"},
                     {"label": "Naqadeh", "value": "C51086"},
                     {"label": "Orumiyeh", "value": "C51087"},
                     {"label": "Oshnaviyeh", "value": "C51088"},
                     {"label": "Piranshahr", "value": "C51089"},
                     {"label": "Poldasht", "value": "C51090"},
                     {"label": "Qarah Zia od Din", "value": "C51091"},
                     {"label": "Salmas", "value": "C51092"},
                     {"label": "Sardasht", "value": "C51093"},
                     {"label": "Shahin Dezh", "value": "C51094"},
                     {"label": "Shahrestan-e Bukan", "value": "C51095"},
                     {"label": "Shahrestan-e Chaldoran", "value": "C51096"},
                     {"label": "Shahrestan-e Mahabad", "value": "C51097"},
                     {"label": "Shahrestan-e Maku", "value": "C51098"},
                     {"label": "Shahrestan-e Miandoab", "value": "C51099"},
                     {"label": "Shahrestan-e Orumiyeh", "value": "C51100"},
                     {"label": "Shahrestan-e Oshnaviyeh", "value": "C51101"},
                     {"label": "Shahrestan-e Piranshahr", "value": "C51102"},
                     {"label": "Shahrestan-e Salmas", "value": "C51103"},
                     {"label": "Shahrestan-e Shahin Dezh", "value": "C51104"},
                     {"label": "Shahrestan-e Takab", "value": "C51105"},
                     {"label": "Takab", "value": "C51106"}],
 "West Bengal": [{"label": "Ahmedpur", "value": "C48878"},
                 {"label": "Aistala", "value": "C48879"},
                 {"label": "Aknapur", "value": "C48880"},
                 {"label": "Alipurduar", "value": "C48881"},
                 {"label": "Amlagora", "value": "C48882"},
                 {"label": "Amta", "value": "C48883"},
                 {"label": "Amtala", "value": "C48884"},
                 {"label": "Andal", "value": "C48885"},
                 {"label": "Arambagh community development block",
                  "value": "C48886"},
                 {"label": "Asansol", "value": "C48887"},
                 {"label": "Ashoknagar Kalyangarh", "value": "C48888"},
                 {"label": "Badkulla", "value": "C48889"},
                 {"label": "Baduria", "value": "C48890"},
                 {"label": "Bagdogra", "value": "C48891"},
                 {"label": "Bagnan", "value": "C48892"},
                 {"label": "Bagula", "value": "C48893"},
                 {"label": "Bahula", "value": "C48894"},
                 {"label": "Baidyabati", "value": "C48895"},
                 {"label": "Bakreswar", "value": "C48896"},
                 {"label": "Balarampur", "value": "C48897"},
                 {"label": "Bali Chak", "value": "C48898"},
                 {"label": "Bally", "value": "C48899"},
                 {"label": "Balurghat", "value": "C48900"},
                 {"label": "Bamangola community development block",
                  "value": "C48901"},
                 {"label": "Baneswar", "value": "C48902"},
                 {"label": "Bangaon", "value": "C48903"},
                 {"label": "Bankra", "value": "C48904"},
                 {"label": "Bankura", "value": "C48905"},
                 {"label": "Bansberia", "value": "C48906"},
                 {"label": "Bansihari community development block",
                  "value": "C48907"},
                 {"label": "Barabazar", "value": "C48908"},
                 {"label": "Baranagar", "value": "C48909"},
                 {"label": "Barasat", "value": "C48910"},
                 {"label": "Bardhaman", "value": "C48911"},
                 {"label": "Barjora", "value": "C48912"},
                 {"label": "Barrackpore", "value": "C48913"},
                 {"label": "Baruipur", "value": "C48914"},
                 {"label": "Basanti", "value": "C48915"},
                 {"label": "Basirhat", "value": "C48916"},
                 {"label": "Bawali", "value": "C48917"},
                 {"label": "Begampur", "value": "C48918"},
                 {"label": "Belda", "value": "C48919"},
                 {"label": "Beldanga", "value": "C48920"},
                 {"label": "Beliatore", "value": "C48921"},
                 {"label": "Berhampore", "value": "C48922"},
                 {"label": "Bhadreswar", "value": "C48923"},
                 {"label": "Bhandardaha", "value": "C48924"},
                 {"label": "Bhatpara", "value": "C48925"},
                 {"label": "Birbhum district", "value": "C48926"},
                 {"label": "Birpara", "value": "C48927"},
                 {"label": "Bishnupur", "value": "C48928"},
                 {"label": "Bolpur", "value": "C48929"},
                 {"label": "Budge Budge", "value": "C48930"},
                 {"label": "Canning", "value": "C48931"},
                 {"label": "Chakapara", "value": "C48932"},
                 {"label": "Chakdaha", "value": "C48933"},
                 {"label": "Champadanga", "value": "C48934"},
                 {"label": "Champahati", "value": "C48935"},
                 {"label": "Champdani", "value": "C48936"},
                 {"label": "Chandannagar", "value": "C48937"},
                 {"label": "Chandrakona", "value": "C48938"},
                 {"label": "Chittaranjan", "value": "C48939"},
                 {"label": "Churulia", "value": "C48940"},
                 {"label": "Contai", "value": "C48941"},
                 {"label": "Cooch Behar", "value": "C48942"},
                 {"label": "Cossimbazar", "value": "C48943"},
                 {"label": "Dakshin Dinajpur district", "value": "C48944"},
                 {"label": "Dalkola", "value": "C48945"},
                 {"label": "Dam Dam", "value": "C48946"},
                 {"label": "Darjeeling", "value": "C48947"},
                 {"label": "Daulatpur", "value": "C48948"},
                 {"label": "Debagram", "value": "C48949"},
                 {"label": "Debipur", "value": "C48950"},
                 {"label": "Dhaniakhali community development block",
                  "value": "C48951"},
                 {"label": "Dhulagari", "value": "C48952"},
                 {"label": "Dhulian", "value": "C48953"},
                 {"label": "Dhupguri", "value": "C48954"},
                 {"label": "Diamond Harbour", "value": "C48955"},
                 {"label": "Digha", "value": "C48956"},
                 {"label": "Dinhata", "value": "C48957"},
                 {"label": "Domjur", "value": "C48958"},
                 {"label": "Dubrajpur", "value": "C48959"},
                 {"label": "Durgapur", "value": "C48960"},
                 {"label": "Egra", "value": "C48961"},
                 {"label": "Falakata", "value": "C48962"},
                 {"label": "Farakka", "value": "C48963"},
                 {"label": "Fort Gloster", "value": "C48964"},
                 {"label": "Gaighata community development block",
                  "value": "C48965"},
                 {"label": "Gairkata", "value": "C48966"},
                 {"label": "Gangadharpur", "value": "C48967"},
                 {"label": "Gangarampur", "value": "C48968"},
                 {"label": "Garui", "value": "C48969"},
                 {"label": "Garulia", "value": "C48970"},
                 {"label": "Ghatal", "value": "C48971"},
                 {"label": "Giria", "value": "C48972"},
                 {"label": "Gobardanga", "value": "C48973"},
                 {"label": "Gobindapur", "value": "C48974"},
                 {"label": "Gopalpur", "value": "C48975"},
                 {"label": "Gopinathpur", "value": "C48976"},
                 {"label": "Gorubathan", "value": "C48977"},
                 {"label": "Gosaba", "value": "C48978"},
                 {"label": "Gosanimari", "value": "C48979"},
                 {"label": "Gurdaha", "value": "C48980"},
                 {"label": "Guskhara", "value": "C48981"},
                 {"label": "Habra", "value": "C48982"},
                 {"label": "Haldia", "value": "C48983"},
                 {"label": "Haldibari", "value": "C48984"},
                 {"label": "Halisahar", "value": "C48985"},
                 {"label": "Harindanga", "value": "C48986"},
                 {"label": "Haringhata", "value": "C48987"},
                 {"label": "Haripur", "value": "C48988"},
                 {"label": "Hasimara", "value": "C48989"},
                 {"label": "Hindusthan Cables Town", "value": "C48990"},
                 {"label": "Hooghly district", "value": "C48991"},
                 {"label": "Howrah", "value": "C48992"},
                 {"label": "Ichapur", "value": "C48993"},
                 {"label": "Indpur community development block",
                  "value": "C48994"},
                 {"label": "Ingraj Bazar", "value": "C48995"},
                 {"label": "Islampur", "value": "C48996"},
                 {"label": "Jafarpur", "value": "C48997"},
                 {"label": "Jaigaon", "value": "C48998"},
                 {"label": "Jalpaiguri", "value": "C48999"},
                 {"label": "Jamuria", "value": "C49000"},
                 {"label": "Jangipur", "value": "C49001"},
                 {"label": "Jaynagar Majilpur", "value": "C49002"},
                 {"label": "Jejur", "value": "C49003"},
                 {"label": "Jhalida", "value": "C49004"},
                 {"label": "Jhargram", "value": "C49005"},
                 {"label": "Jhilimili", "value": "C49006"},
                 {"label": "Kakdwip", "value": "C49007"},
                 {"label": "Kalaikunda", "value": "C49008"},
                 {"label": "Kaliaganj", "value": "C49009"},
                 {"label": "Kalimpong", "value": "C49010"},
                 {"label": "Kalna", "value": "C49011"},
                 {"label": "Kalyani", "value": "C49012"},
                 {"label": "Kamarhati", "value": "C49013"},
                 {"label": "Kamarpukur", "value": "C49014"},
                 {"label": "Kanchrapara", "value": "C49015"},
                 {"label": "Kandi", "value": "C49016"},
                 {"label": "Karimpur", "value": "C49017"},
                 {"label": "Katwa", "value": "C49018"},
                 {"label": "Kenda", "value": "C49019"},
                 {"label": "Keshabpur", "value": "C49020"},
                 {"label": "Kharagpur", "value": "C49021"},
                 {"label": "Kharar", "value": "C49022"},
                 {"label": "Kharba", "value": "C49023"},
                 {"label": "Khardaha", "value": "C49024"},
                 {"label": "Khatra", "value": "C49025"},
                 {"label": "Kirnahar", "value": "C49026"},
                 {"label": "Kolkata", "value": "C49027"},
                 {"label": "Konnagar", "value": "C49028"},
                 {"label": "Krishnanagar", "value": "C49029"},
                 {"label": "Krishnapur", "value": "C49030"},
                 {"label": "Kshirpai", "value": "C49031"},
                 {"label": "Kulpi", "value": "C49032"},
                 {"label": "Kultali", "value": "C49033"},
                 {"label": "Kulti", "value": "C49034"},
                 {"label": "Kurseong", "value": "C49035"},
                 {"label": "Lalgarh", "value": "C49036"},
                 {"label": "Lalgola", "value": "C49037"},
                 {"label": "Loyabad", "value": "C49038"},
                 {"label": "Madanpur", "value": "C49039"},
                 {"label": "Madhyamgram", "value": "C49040"},
                 {"label": "Mahiari", "value": "C49041"},
                 {"label": "Mahishadal community development block",
                  "value": "C49042"},
                 {"label": "Mainaguri", "value": "C49043"},
                 {"label": "Manikpara", "value": "C49044"},
                 {"label": "Masila", "value": "C49045"},
                 {"label": "Mathabhanga", "value": "C49046"},
                 {"label": "Matiali community development block",
                  "value": "C49047"},
                 {"label": "Matigara community development block",
                  "value": "C49048"},
                 {"label": "Medinipur", "value": "C49049"},
                 {"label": "Mejia community development block",
                  "value": "C49050"},
                 {"label": "Memari", "value": "C49051"},
                 {"label": "Mirik", "value": "C49052"},
                 {"label": "Mohanpur community development block",
                  "value": "C49053"},
                 {"label": "Monoharpur", "value": "C49054"},
                 {"label": "Muragacha", "value": "C49055"},
                 {"label": "Muri", "value": "C49056"},
                 {"label": "Murshidabad", "value": "C49057"},
                 {"label": "Nabadwip", "value": "C49058"},
                 {"label": "Nabagram", "value": "C49059"},
                 {"label": "Nadia district", "value": "C49060"},
                 {"label": "Nagarukhra", "value": "C49061"},
                 {"label": "Nagrakata", "value": "C49062"},
                 {"label": "Naihati", "value": "C49063"},
                 {"label": "Naksalbari", "value": "C49064"},
                 {"label": "Nalhati", "value": "C49065"},
                 {"label": "Nalpur", "value": "C49066"},
                 {"label": "Namkhana community development block",
                  "value": "C49067"},
                 {"label": "Nandigram", "value": "C49068"},
                 {"label": "Nangi", "value": "C49069"},
                 {"label": "Nayagram community development block",
                  "value": "C49070"},
                 {"label": "North 24 Parganas district", "value": "C49071"},
                 {"label": "Odlabari", "value": "C49072"},
                 {"label": "Paikpara", "value": "C49073"},
                 {"label": "Panagarh", "value": "C49074"},
                 {"label": "Panchla", "value": "C49075"},
                 {"label": "Panchmura", "value": "C49076"},
                 {"label": "Pandua", "value": "C49077"},
                 {"label": "Panihati", "value": "C49078"},
                 {"label": "Panskura", "value": "C49079"},
                 {"label": "Parbatipur", "value": "C49080"},
                 {"label": "Paschim Medinipur district", "value": "C49081"},
                 {"label": "Patiram", "value": "C49082"},
                 {"label": "Patrasaer", "value": "C49083"},
                 {"label": "Patuli", "value": "C49084"},
                 {"label": "Pujali", "value": "C49085"},
                 {"label": "Puncha community development block",
                  "value": "C49086"},
                 {"label": "Purba Medinipur district", "value": "C49087"},
                 {"label": "Purulia", "value": "C49088"},
                 {"label": "Raghudebbati", "value": "C49089"},
                 {"label": "Raghunathpur", "value": "C49090"},
                 {"label": "Raiganj", "value": "C49091"},
                 {"label": "Rajmahal", "value": "C49092"},
                 {"label": "Rajnagar community development block",
                  "value": "C49093"},
                 {"label": "Ramchandrapur", "value": "C49094"},
                 {"label": "Ramjibanpur", "value": "C49095"},
                 {"label": "Ramnagar", "value": "C49096"},
                 {"label": "Rampur Hat", "value": "C49097"},
                 {"label": "Ranaghat", "value": "C49098"},
                 {"label": "Raniganj", "value": "C49099"},
                 {"label": "Raypur", "value": "C49100"},
                 {"label": "Rishra", "value": "C49101"},
                 {"label": "Sahapur", "value": "C49102"},
                 {"label": "Sainthia", "value": "C49103"},
                 {"label": "Salanpur community development block",
                  "value": "C49104"},
                 {"label": "Sankarpur", "value": "C49105"},
                 {"label": "Sankrail", "value": "C49106"},
                 {"label": "Santipur", "value": "C49107"},
                 {"label": "Santoshpur", "value": "C49108"},
                 {"label": "Santuri community development block",
                  "value": "C49109"},
                 {"label": "Sarenga", "value": "C49110"},
                 {"label": "Serampore", "value": "C49111"},
                 {"label": "Serpur", "value": "C49112"},
                 {"label": "Shyamnagar, West Bengal", "value": "C49113"},
                 {"label": "Siliguri", "value": "C49114"},
                 {"label": "Singur", "value": "C49115"},
                 {"label": "Sodpur", "value": "C49116"},
                 {"label": "Solap", "value": "C49117"},
                 {"label": "Sonada", "value": "C49118"},
                 {"label": "Sonamukhi", "value": "C49119"},
                 {"label": "Sonarpur community development block",
                  "value": "C49120"},
                 {"label": "South 24 Parganas district", "value": "C49121"},
                 {"label": "Srikhanda", "value": "C49122"},
                 {"label": "Srirampur", "value": "C49123"},
                 {"label": "Suri", "value": "C49124"},
                 {"label": "Swarupnagar community development block",
                  "value": "C49125"},
                 {"label": "Takdah", "value": "C49126"},
                 {"label": "Taki", "value": "C49127"},
                 {"label": "Tamluk", "value": "C49128"},
                 {"label": "Tarakeswar", "value": "C49129"},
                 {"label": "Titagarh", "value": "C49130"},
                 {"label": "Tufanganj", "value": "C49131"},
                 {"label": "Tulin", "value": "C49132"},
                 {"label": "Uchalan", "value": "C49133"},
                 {"label": "Ula", "value": "C49134"},
                 {"label": "Uluberia", "value": "C49135"},
                 {"label": "Uttar Dinajpur district", "value": "C49136"},
                 {"label": "Uttarpara Kotrung", "value": "C49137"}],
 "West Coast Division": [{"label": "Abuko", "value": "C34240"},
                         {"label": "Brikama", "value": "C34241"},
                         {"label": "Foni Bondali", "value": "C34242"},
                         {"label": "Foni Brefet", "value": "C34243"},
                         {"label": "Foni Jarrol", "value": "C34244"},
                         {"label": "Foni Kansala", "value": "C34245"},
                         {"label": "Gunjur", "value": "C34246"},
                         {"label": "Kombo Central District", "value": "C34247"},
                         {"label": "Kombo East District", "value": "C34248"},
                         {"label": "Kombo North District", "value": "C34249"},
                         {"label": "Kombo South District", "value": "C34250"},
                         {"label": "Somita", "value": "C34251"},
                         {"label": "Sukuta", "value": "C34252"}],
 "West Coast Region": [{"label": "Greymouth", "value": "C77884"},
                       {"label": "Hokitika", "value": "C77885"},
                       {"label": "Westport", "value": "C77886"}],
 "West Darfur": [{"label": "Geneina", "value": "C115446"}],
 "West Grand Bahama": [{"label": "West End", "value": "C119624"}],
 "West Greece Region": [{"label": "Agrinio", "value": "C42480"},
                        {"label": "Aigio", "value": "C42481"},
                        {"label": "Aitoliko", "value": "C42482"},
                        {"label": "Aiyira", "value": "C42483"},
                        {"label": "Akrata", "value": "C42484"},
                        {"label": "Aktaio", "value": "C42485"},
                        {"label": "Alfeiousa", "value": "C42486"},
                        {"label": "Amaliada", "value": "C42487"},
                        {"label": "Amfilochia", "value": "C42488"},
                        {"label": "Andravida", "value": "C42489"},
                        {"label": "Angelokastro", "value": "C42490"},
                        {"label": "ano Kastritsi", "value": "C42491"},
                        {"label": "Antirrio", "value": "C42492"},
                        {"label": "Archaia Olympia", "value": "C42493"},
                        {"label": "Archontochori", "value": "C42494"},
                        {"label": "Arkoudi", "value": "C42495"},
                        {"label": "Astakos", "value": "C42496"},
                        {"label": "ayios Konstandinos", "value": "C42497"},
                        {"label": "Chalandritsa", "value": "C42498"},
                        {"label": "Chavari", "value": "C42499"},
                        {"label": "Dhokimion", "value": "C42500"},
                        {"label": "Epitalio", "value": "C42501"},
                        {"label": "Fyteies", "value": "C42502"},
                        {"label": "Galatas", "value": "C42503"},
                        {"label": "Gastouni", "value": "C42504"},
                        {"label": "Gavalou", "value": "C42505"},
                        {"label": "Goumero", "value": "C42506"},
                        {"label": "Kainouryion", "value": "C42507"},
                        {"label": "Kalavryta", "value": "C42508"},
                        {"label": "Kamarai", "value": "C42509"},
                        {"label": "Kandila", "value": "C42510"},
                        {"label": "Karatoula", "value": "C42511"},
                        {"label": "Kardamas", "value": "C42512"},
                        {"label": "Kato Achaia", "value": "C42513"},
                        {"label": "Kato Mazaraki", "value": "C42514"},
                        {"label": "Katochi", "value": "C42515"},
                        {"label": "Katouna", "value": "C42516"},
                        {"label": "Kavasila", "value": "C42517"},
                        {"label": "Krestena", "value": "C42518"},
                        {"label": "Krikellos", "value": "C42519"},
                        {"label": "Kyllini", "value": "C42520"},
                        {"label": "Lalas", "value": "C42521"},
                        {"label": "Lapas", "value": "C42522"},
                        {"label": "Lechaina", "value": "C42523"},
                        {"label": "Lepenou", "value": "C42524"},
                        {"label": "Limnokhorion", "value": "C42525"},
                        {"label": "Makrisia", "value": "C42526"},
                        {"label": "Manolada", "value": "C42527"},
                        {"label": "Mataranga", "value": "C42528"},
                        {"label": "Megali Khora", "value": "C42529"},
                        {"label": "Menidi", "value": "C42530"},
                        {"label": "Mesolongi", "value": "C42531"},
                        {"label": "Mindiloglion", "value": "C42532"},
                        {"label": "Monastiraki", "value": "C42533"},
                        {"label": "Myrsini", "value": "C42534"},
                        {"label": "Nafpaktos", "value": "C42535"},
                        {"label": "Nea Manolada", "value": "C42536"},
                        {"label": "Neapolis", "value": "C42537"},
                        {"label": "Neochori", "value": "C42538"},
                        {"label": "Neochorion", "value": "C42539"},
                        {"label": "Nomos Achaias", "value": "C42540"},
                        {"label": "Nomos Aitolias kai Akarnanias",
                         "value": "C42541"},
                        {"label": "Nomos Ileias", "value": "C42542"},
                        {"label": "Ovria", "value": "C42543"},
                        {"label": "Palairos", "value": "C42544"},
                        {"label": "Panaitolion", "value": "C42545"},
                        {"label": "Pappadhatai", "value": "C42546"},
                        {"label": "Paralia", "value": "C42547"},
                        {"label": "Paravola", "value": "C42548"},
                        {"label": "Patra", "value": "C42549"},
                        {"label": "Pelopi", "value": "C42550"},
                        {"label": "Platanos", "value": "C42551"},
                        {"label": "Pyrgos", "value": "C42552"},
                        {"label": "Rio", "value": "C42553"},
                        {"label": "Rododafni", "value": "C42554"},
                        {"label": "Royitika", "value": "C42555"},
                        {"label": "Sardinia", "value": "C42556"},
                        {"label": "Savalia", "value": "C42557"},
                        {"label": "Stanos", "value": "C42558"},
                        {"label": "Sylivainiotika", "value": "C42559"},
                        {"label": "Synoikismos Chavariou", "value": "C42560"},
                        {"label": "Temeni", "value": "C42561"},
                        {"label": "Thermo", "value": "C42562"},
                        {"label": "Triandaiika", "value": "C42563"},
                        {"label": "Varda", "value": "C42564"},
                        {"label": "Vartholomio", "value": "C42565"},
                        {"label": "Varvasaina", "value": "C42566"},
                        {"label": "Vonitsa", "value": "C42567"},
                        {"label": "Vrachnaiika", "value": "C42568"},
                        {"label": "Zacharo", "value": "C42569"}],
 "West Kazakhstan Province": [{"label": "Aqsay", "value": "C64524"},
                              {"label": "Burlin", "value": "C64525"},
                              {"label": "Chapaev", "value": "C64526"},
                              {"label": "Chingirlau", "value": "C64527"},
                              {"label": "Dzhambeyty", "value": "C64528"},
                              {"label": "Fedorovka", "value": "C64529"},
                              {"label": "Kaztalovka", "value": "C64530"},
                              {"label": "Krugloozernoe", "value": "C64531"},
                              {"label": "Oral", "value": "C64532"},
                              {"label": "Peremetnoe", "value": "C64533"},
                              {"label": "Saykhin", "value": "C64534"},
                              {"label": "Tasqala", "value": "C64535"},
                              {"label": "Zhanibek", "value": "C64536"}],
 "West Kordofan": [{"label": "Abu Zabad", "value": "C115447"},
                   {"label": "Al Lagowa", "value": "C115448"},
                   {"label": "Al Mijlad", "value": "C115449"},
                   {"label": "An Nuhud", "value": "C115450"}],
 "West Macedonia Region": [{"label": "Aiani", "value": "C42570"},
                           {"label": "Akrini", "value": "C42571"},
                           {"label": "Ammochori", "value": "C42572"},
                           {"label": "Amyntaio", "value": "C42573"},
                           {"label": "Anarachi", "value": "C42574"},
                           {"label": "ano Komi", "value": "C42575"},
                           {"label": "Argos Orestiko", "value": "C42576"},
                           {"label": "Chloi", "value": "C42577"},
                           {"label": "Deskati", "value": "C42578"},
                           {"label": "Emporio", "value": "C42579"},
                           {"label": "Eratyra", "value": "C42580"},
                           {"label": "Filotas", "value": "C42581"},
                           {"label": "Florina", "value": "C42582"},
                           {"label": "Galatini", "value": "C42583"},
                           {"label": "Grevena", "value": "C42584"},
                           {"label": "Kastoria", "value": "C42585"},
                           {"label": "Kleitos", "value": "C42586"},
                           {"label": "Koila", "value": "C42587"},
                           {"label": "Komnina", "value": "C42588"},
                           {"label": "Kozani", "value": "C42589"},
                           {"label": "Krokos", "value": "C42590"},
                           {"label": "Laimos", "value": "C42591"},
                           {"label": "Lechovo", "value": "C42592"},
                           {"label": "Livadero", "value": "C42593"},
                           {"label": "Maniakoi", "value": "C42594"},
                           {"label": "Mavrochori", "value": "C42595"},
                           {"label": "Meliti", "value": "C42596"},
                           {"label": "Mesopotamia", "value": "C42597"},
                           {"label": "Nea Lava", "value": "C42598"},
                           {"label": "Nestorio", "value": "C42599"},
                           {"label": "Nomos Kozanis", "value": "C42600"},
                           {"label": "Platanorevma", "value": "C42601"},
                           {"label": "Ptolemaida", "value": "C42602"},
                           {"label": "Servia", "value": "C42603"},
                           {"label": "Siatista", "value": "C42604"},
                           {"label": "Tsotili", "value": "C42605"},
                           {"label": "Velventos", "value": "C42606"},
                           {"label": "Xino Nero", "value": "C42607"}],
 "West New Britain Province": [{"label": "Kandrian", "value": "C80472"},
                               {"label": "Kandrian Gloucester",
                                "value": "C80473"},
                               {"label": "Kimbe", "value": "C80474"},
                               {"label": "Talasea", "value": "C80475"}],
 "West Pokot": [{"label": "Chepareria", "value": "C64642"},
                {"label": "Kapenguria", "value": "C64643"},
                {"label": "Taveta", "value": "C64644"},
                {"label": "Voi", "value": "C64645"},
                {"label": "Wundanyi", "value": "C64646"}],
 "West Pomeranian Voivodeship": [{"label": "Banie", "value": "C90636"},
                                 {"label": "Barlinek", "value": "C90637"},
                                 {"label": "Barwice", "value": "C90638"},
                                 {"label": "Bialogard", "value": "C90639"},
                                 {"label": "Bialy Bor", "value": "C90640"},
                                 {"label": "Bielice", "value": "C90641"},
                                 {"label": "Bierzwnik", "value": "C90642"},
                                 {"label": "Bobolice", "value": "C90643"},
                                 {"label": "Boleszkowice", "value": "C90644"},
                                 {"label": "Borne Sulinowo", "value": "C90645"},
                                 {"label": "Brojce", "value": "C90646"},
                                 {"label": "Cedynia", "value": "C90647"},
                                 {"label": "Cerkwica", "value": "C90648"},
                                 {"label": "Chociwel", "value": "C90649"},
                                 {"label": "Chojna", "value": "C90650"},
                                 {"label": "Choszczno", "value": "C90651"},
                                 {"label": "Czaplinek", "value": "C90652"},
                                 {"label": "Czlopa", "value": "C90653"},
                                 {"label": "Darlowo", "value": "C90654"},
                                 {"label": "Debno", "value": "C90655"},
                                 {"label": "Dobra", "value": "C90656"},
                                 {"label": "Dobrzany", "value": "C90657"},
                                 {"label": "Dolice", "value": "C90658"},
                                 {"label": "Drawno", "value": "C90659"},
                                 {"label": "Drawsko Pomorskie",
                                  "value": "C90660"},
                                 {"label": "Dygowo", "value": "C90661"},
                                 {"label": "Dziwnow", "value": "C90662"},
                                 {"label": "Golczewo", "value": "C90663"},
                                 {"label": "Goleniow", "value": "C90664"},
                                 {"label": "Goscino", "value": "C90665"},
                                 {"label": "Gryfice", "value": "C90666"},
                                 {"label": "Gryfino", "value": "C90667"},
                                 {"label": "Grzmiaca", "value": "C90668"},
                                 {"label": "Grzybowo", "value": "C90669"},
                                 {"label": "Insko", "value": "C90670"},
                                 {"label": "Kalisz Pomorski",
                                  "value": "C90671"},
                                 {"label": "Kamien Pomorski",
                                  "value": "C90672"},
                                 {"label": "Karlino", "value": "C90673"},
                                 {"label": "Karnice", "value": "C90674"},
                                 {"label": "Kobylanka", "value": "C90675"},
                                 {"label": "Kolbaskowo", "value": "C90676"},
                                 {"label": "Kolobrzeg", "value": "C90677"},
                                 {"label": "Koszalin", "value": "C90678"},
                                 {"label": "Krzecin", "value": "C90679"},
                                 {"label": "Lipiany", "value": "C90680"},
                                 {"label": "Lobez", "value": "C90681"},
                                 {"label": "Lubowo", "value": "C90682"},
                                 {"label": "Marianowo", "value": "C90683"},
                                 {"label": "Maszewo", "value": "C90684"},
                                 {"label": "Miedzyzdroje", "value": "C90685"},
                                 {"label": "Mielno", "value": "C90686"},
                                 {"label": "Mieszkowice", "value": "C90687"},
                                 {"label": "Miroslawiec", "value": "C90688"},
                                 {"label": "Moryn", "value": "C90689"},
                                 {"label": "Mosty", "value": "C90690"},
                                 {"label": "Mrzezyno", "value": "C90691"},
                                 {"label": "Mysliborz", "value": "C90692"},
                                 {"label": "Nowe Warpno", "value": "C90693"},
                                 {"label": "Nowogard", "value": "C90694"},
                                 {"label": "Nowogrodek Pomorski",
                                  "value": "C90695"},
                                 {"label": "Osina", "value": "C90696"},
                                 {"label": "Pelczyce", "value": "C90697"},
                                 {"label": "Ploty", "value": "C90698"},
                                 {"label": "Pobierowo", "value": "C90699"},
                                 {"label": "Polanow", "value": "C90700"},
                                 {"label": "Police", "value": "C90701"},
                                 {"label": "Polczyn-Zdroj", "value": "C90702"},
                                 {"label": "Powiat bialogardzki",
                                  "value": "C90703"},
                                 {"label": "Powiat choszczenski",
                                  "value": "C90704"},
                                 {"label": "Powiat drawski", "value": "C90705"},
                                 {"label": "Powiat goleniowski",
                                  "value": "C90706"},
                                 {"label": "Powiat gryficki",
                                  "value": "C90707"},
                                 {"label": "Powiat gryfinski",
                                  "value": "C90708"},
                                 {"label": "Powiat kamienski",
                                  "value": "C90709"},
                                 {"label": "Powiat kolobrzeski",
                                  "value": "C90710"},
                                 {"label": "Powiat koszalinski",
                                  "value": "C90711"},
                                 {"label": "Powiat lobeski", "value": "C90712"},
                                 {"label": "Powiat mysliborski",
                                  "value": "C90713"},
                                 {"label": "Powiat policki", "value": "C90714"},
                                 {"label": "Powiat pyrzycki",
                                  "value": "C90715"},
                                 {"label": "Powiat slawienski",
                                  "value": "C90716"},
                                 {"label": "Powiat stargardzki",
                                  "value": "C90717"},
                                 {"label": "Powiat swidwinski",
                                  "value": "C90718"},
                                 {"label": "Powiat szczecinecki",
                                  "value": "C90719"},
                                 {"label": "Powiat walecki", "value": "C90720"},
                                 {"label": "Przeclaw", "value": "C90721"},
                                 {"label": "Przelewice", "value": "C90722"},
                                 {"label": "Przybiernow", "value": "C90723"},
                                 {"label": "Pyrzyce", "value": "C90724"},
                                 {"label": "Rabino", "value": "C90725"},
                                 {"label": "Radowo Male", "value": "C90726"},
                                 {"label": "Recz", "value": "C90727"},
                                 {"label": "Resko", "value": "C90728"},
                                 {"label": "Rewal", "value": "C90729"},
                                 {"label": "Ryman", "value": "C90730"},
                                 {"label": "Sianow", "value": "C90731"},
                                 {"label": "Slawno", "value": "C90732"},
                                 {"label": "Slawoborze", "value": "C90733"},
                                 {"label": "Stara Dabrowa", "value": "C90734"},
                                 {"label": "Stare Czarnowo", "value": "C90735"},
                                 {"label": "Stargard", "value": "C90736"},
                                 {"label": "Stepnica", "value": "C90737"},
                                 {"label": "Suchan", "value": "C90738"},
                                 {"label": "Swidwin", "value": "C90739"},
                                 {"label": "Swierzno", "value": "C90740"},
                                 {"label": "Swinoujscie", "value": "C90741"},
                                 {"label": "Szczecin", "value": "C90742"},
                                 {"label": "Szczecinek", "value": "C90743"},
                                 {"label": "Trzcinsko Zdroj",
                                  "value": "C90744"},
                                 {"label": "Trzebiatow", "value": "C90745"},
                                 {"label": "Tuczno", "value": "C90746"},
                                 {"label": "Tychowo", "value": "C90747"},
                                 {"label": "Ustronie Morskie",
                                  "value": "C90748"},
                                 {"label": "Walcz", "value": "C90749"},
                                 {"label": "Warnice", "value": "C90750"},
                                 {"label": "Wegorzyno", "value": "C90751"},
                                 {"label": "Widuchowa", "value": "C90752"},
                                 {"label": "Wierzchowo", "value": "C90753"},
                                 {"label": "Wolin", "value": "C90754"},
                                 {"label": "Zlocieniec", "value": "C90755"}],
 "West Virginia": [{"label": "Alderson", "value": "C146037"},
                   {"label": "Alum Creek", "value": "C146038"},
                   {"label": "Ansted", "value": "C146039"},
                   {"label": "Barbour County", "value": "C146040"},
                   {"label": "Barboursville", "value": "C146041"},
                   {"label": "Barrackville", "value": "C146042"},
                   {"label": "Beaver", "value": "C146043"},
                   {"label": "Beckley", "value": "C146044"},
                   {"label": "Belington", "value": "C146045"},
                   {"label": "Belle", "value": "C146046"},
                   {"label": "Benwood", "value": "C146047"},
                   {"label": "Berkeley County", "value": "C146048"},
                   {"label": "Berkeley Springs", "value": "C146049"},
                   {"label": "Bethany", "value": "C146050"},
                   {"label": "Bethlehem", "value": "C146051"},
                   {"label": "Blennerhassett", "value": "C146052"},
                   {"label": "Bluefield", "value": "C146053"},
                   {"label": "Bluewell", "value": "C146054"},
                   {"label": "Boaz", "value": "C146055"},
                   {"label": "Bolivar", "value": "C146056"},
                   {"label": "Boone County", "value": "C146057"},
                   {"label": "Bradley", "value": "C146058"},
                   {"label": "Braxton County", "value": "C146059"},
                   {"label": "Bridgeport", "value": "C146060"},
                   {"label": "Brooke County", "value": "C146061"},
                   {"label": "Brookhaven", "value": "C146062"},
                   {"label": "Brush Fork", "value": "C146063"},
                   {"label": "Buckhannon", "value": "C146064"},
                   {"label": "Buffalo", "value": "C146065"},
                   {"label": "Cabell County", "value": "C146066"},
                   {"label": "Calhoun County", "value": "C146067"},
                   {"label": "Ceredo", "value": "C146068"},
                   {"label": "Chapmanville", "value": "C146069"},
                   {"label": "Charles Town", "value": "C146070"},
                   {"label": "Charleston", "value": "C146071"},
                   {"label": "Cheat Lake", "value": "C146072"},
                   {"label": "Chesapeake", "value": "C146073"},
                   {"label": "Chester", "value": "C146074"},
                   {"label": "Clarksburg", "value": "C146075"},
                   {"label": "Clay", "value": "C146076"},
                   {"label": "Clay County", "value": "C146077"},
                   {"label": "Clendenin", "value": "C146078"},
                   {"label": "Coal City", "value": "C146079"},
                   {"label": "Coal Fork", "value": "C146080"},
                   {"label": "Crab Orchard", "value": "C146081"},
                   {"label": "Craigsville", "value": "C146082"},
                   {"label": "Cross Lanes", "value": "C146083"},
                   {"label": "Culloden", "value": "C146084"},
                   {"label": "Daniels", "value": "C146085"},
                   {"label": "Despard", "value": "C146086"},
                   {"label": "Doddridge County", "value": "C146087"},
                   {"label": "Dunbar", "value": "C146088"},
                   {"label": "Eleanor", "value": "C146089"},
                   {"label": "Elizabeth", "value": "C146090"},
                   {"label": "Elkins", "value": "C146091"},
                   {"label": "Elkview", "value": "C146092"},
                   {"label": "Fairlea", "value": "C146093"},
                   {"label": "Fairmont", "value": "C146094"},
                   {"label": "Fayette County", "value": "C146095"},
                   {"label": "Fayetteville", "value": "C146096"},
                   {"label": "Follansbee", "value": "C146097"},
                   {"label": "Fort Ashby", "value": "C146098"},
                   {"label": "Franklin", "value": "C146099"},
                   {"label": "Gilbert Creek", "value": "C146100"},
                   {"label": "Gilmer County", "value": "C146101"},
                   {"label": "Glendale", "value": "C146102"},
                   {"label": "Glenville", "value": "C146103"},
                   {"label": "Grafton", "value": "C146104"},
                   {"label": "Grant County", "value": "C146105"},
                   {"label": "Grantsville", "value": "C146106"},
                   {"label": "Granville", "value": "C146107"},
                   {"label": "Greenbrier County", "value": "C146108"},
                   {"label": "Hamlin", "value": "C146109"},
                   {"label": "Hampshire County", "value": "C146110"},
                   {"label": "Hancock County", "value": "C146111"},
                   {"label": "Hardy County", "value": "C146112"},
                   {"label": "Harrison County", "value": "C146113"},
                   {"label": "Harrisville", "value": "C146114"},
                   {"label": "Hinton", "value": "C146115"},
                   {"label": "Hooverson Heights", "value": "C146116"},
                   {"label": "Huntington", "value": "C146117"},
                   {"label": "Hurricane", "value": "C146118"},
                   {"label": "Inwood", "value": "C146119"},
                   {"label": "Jackson County", "value": "C146120"},
                   {"label": "Jefferson County", "value": "C146121"},
                   {"label": "Kanawha County", "value": "C146122"},
                   {"label": "Kenova", "value": "C146123"},
                   {"label": "Keyser", "value": "C146124"},
                   {"label": "Kingwood", "value": "C146125"},
                   {"label": "Lavalette", "value": "C146126"},
                   {"label": "Lesage", "value": "C146127"},
                   {"label": "Lewis County", "value": "C146128"},
                   {"label": "Lewisburg", "value": "C146129"},
                   {"label": "Lincoln County", "value": "C146130"},
                   {"label": "Logan", "value": "C146131"},
                   {"label": "Logan County", "value": "C146132"},
                   {"label": "Lubeck", "value": "C146133"},
                   {"label": "Mabscott", "value": "C146134"},
                   {"label": "MacArthur", "value": "C146135"},
                   {"label": "Madison", "value": "C146136"},
                   {"label": "Mallory", "value": "C146137"},
                   {"label": "Mannington", "value": "C146138"},
                   {"label": "Marion County", "value": "C146139"},
                   {"label": "Marlinton", "value": "C146140"},
                   {"label": "Marmet", "value": "C146141"},
                   {"label": "Marshall County", "value": "C146142"},
                   {"label": "Martinsburg", "value": "C146143"},
                   {"label": "Mason County", "value": "C146144"},
                   {"label": "McDowell County", "value": "C146145"},
                   {"label": "McMechen", "value": "C146146"},
                   {"label": "Mercer County", "value": "C146147"},
                   {"label": "Middlebourne", "value": "C146148"},
                   {"label": "Milton", "value": "C146149"},
                   {"label": "Mineral County", "value": "C146150"},
                   {"label": "Mineral Wells", "value": "C146151"},
                   {"label": "Mingo County", "value": "C146152"},
                   {"label": "Monongah", "value": "C146153"},
                   {"label": "Monongalia County", "value": "C146154"},
                   {"label": "Monroe County", "value": "C146155"},
                   {"label": "Montgomery", "value": "C146156"},
                   {"label": "Moorefield", "value": "C146157"},
                   {"label": "Morgan County", "value": "C146158"},
                   {"label": "Morgantown", "value": "C146159"},
                   {"label": "Moundsville", "value": "C146160"},
                   {"label": "Mount Gay-Shamrock", "value": "C146161"},
                   {"label": "Mount Hope", "value": "C146162"},
                   {"label": "Mullens", "value": "C146163"},
                   {"label": "New Cumberland", "value": "C146164"},
                   {"label": "New Haven", "value": "C146165"},
                   {"label": "New Martinsville", "value": "C146166"},
                   {"label": "Newell", "value": "C146167"},
                   {"label": "Nicholas County", "value": "C146168"},
                   {"label": "Nitro", "value": "C146169"},
                   {"label": "Nutter Fort", "value": "C146170"},
                   {"label": "Oak Hill", "value": "C146171"},
                   {"label": "Oceana", "value": "C146172"},
                   {"label": "Ohio County", "value": "C146173"},
                   {"label": "Paden City", "value": "C146174"},
                   {"label": "Parkersburg", "value": "C146175"},
                   {"label": "Parsons", "value": "C146176"},
                   {"label": "Pea Ridge", "value": "C146177"},
                   {"label": "Pendleton County", "value": "C146178"},
                   {"label": "Pennsboro", "value": "C146179"},
                   {"label": "Petersburg", "value": "C146180"},
                   {"label": "Philippi", "value": "C146181"},
                   {"label": "Pinch", "value": "C146182"},
                   {"label": "Pineville", "value": "C146183"},
                   {"label": "Pleasant Valley", "value": "C146184"},
                   {"label": "Pleasants County", "value": "C146185"},
                   {"label": "Pocahontas County", "value": "C146186"},
                   {"label": "Point Pleasant", "value": "C146187"},
                   {"label": "Preston County", "value": "C146188"},
                   {"label": "Princeton", "value": "C146189"},
                   {"label": "Prosperity", "value": "C146190"},
                   {"label": "Putnam County", "value": "C146191"},
                   {"label": "Rainelle", "value": "C146192"},
                   {"label": "Raleigh County", "value": "C146193"},
                   {"label": "Rand", "value": "C146194"},
                   {"label": "Randolph County", "value": "C146195"},
                   {"label": "Ranson", "value": "C146196"},
                   {"label": "Ravenswood", "value": "C146197"},
                   {"label": "Richwood", "value": "C146198"},
                   {"label": "Ripley", "value": "C146199"},
                   {"label": "Ritchie County", "value": "C146200"},
                   {"label": "Roane County", "value": "C146201"},
                   {"label": "Romney", "value": "C146202"},
                   {"label": "Ronceverte", "value": "C146203"},
                   {"label": "Saint Albans", "value": "C146204"},
                   {"label": "Saint Marys", "value": "C146205"},
                   {"label": "Salem", "value": "C146206"},
                   {"label": "Shady Spring", "value": "C146207"},
                   {"label": "Shannondale", "value": "C146208"},
                   {"label": "Shepherdstown", "value": "C146209"},
                   {"label": "Shinnston", "value": "C146210"},
                   {"label": "Sissonville", "value": "C146211"},
                   {"label": "Sistersville", "value": "C146212"},
                   {"label": "Sophia", "value": "C146213"},
                   {"label": "South Charleston", "value": "C146214"},
                   {"label": "Spencer", "value": "C146215"},
                   {"label": "Stanaford", "value": "C146216"},
                   {"label": "Star City", "value": "C146217"},
                   {"label": "Stonewood", "value": "C146218"},
                   {"label": "Summers County", "value": "C146219"},
                   {"label": "Summersville", "value": "C146220"},
                   {"label": "Sutton", "value": "C146221"},
                   {"label": "Taylor County", "value": "C146222"},
                   {"label": "Teays Valley", "value": "C146223"},
                   {"label": "Terra Alta", "value": "C146224"},
                   {"label": "Tornado", "value": "C146225"},
                   {"label": "Tucker County", "value": "C146226"},
                   {"label": "Tyler County", "value": "C146227"},
                   {"label": "Union", "value": "C146228"},
                   {"label": "Upshur County", "value": "C146229"},
                   {"label": "Vienna", "value": "C146230"},
                   {"label": "Washington", "value": "C146231"},
                   {"label": "Wayne", "value": "C146232"},
                   {"label": "Wayne County", "value": "C146233"},
                   {"label": "Webster County", "value": "C146234"},
                   {"label": "Webster Springs", "value": "C146235"},
                   {"label": "Weirton", "value": "C146236"},
                   {"label": "Weirton Heights", "value": "C146237"},
                   {"label": "Welch", "value": "C146238"},
                   {"label": "Wellsburg", "value": "C146239"},
                   {"label": "West Liberty", "value": "C146240"},
                   {"label": "West Union", "value": "C146241"},
                   {"label": "Weston", "value": "C146242"},
                   {"label": "Westover", "value": "C146243"},
                   {"label": "Wetzel County", "value": "C146244"},
                   {"label": "Wheeling", "value": "C146245"},
                   {"label": "White Sulphur Springs", "value": "C146246"},
                   {"label": "Wiley Ford", "value": "C146247"},
                   {"label": "Williamson", "value": "C146248"},
                   {"label": "Williamstown", "value": "C146249"},
                   {"label": "Winfield", "value": "C146250"},
                   {"label": "Wirt County", "value": "C146251"},
                   {"label": "Wood County", "value": "C146252"},
                   {"label": "Wyoming County", "value": "C146253"}],
 "Western": [{"label": "Aboso", "value": "C41566"},
             {"label": "Axim", "value": "C41567"},
             {"label": "Bibiani", "value": "C41568"},
             {"label": "Prestea", "value": "C41569"},
             {"label": "Sekondi-Takoradi", "value": "C41570"},
             {"label": "Shama Junction", "value": "C41571"},
             {"label": "Takoradi", "value": "C41572"},
             {"label": "Tarkwa", "value": "C41573"}],
 "Western Area": [{"label": "Freetown", "value": "C107323"},
                  {"label": "Hastings", "value": "C107324"},
                  {"label": "Kent", "value": "C107325"},
                  {"label": "Waterloo", "value": "C107326"}],
 "Western Australia": [{"label": "Abbey", "value": "C5467"},
                       {"label": "Albany", "value": "C5468"},
                       {"label": "Albany city centre", "value": "C5469"},
                       {"label": "Alexander Heights", "value": "C5470"},
                       {"label": "Alfred Cove", "value": "C5471"},
                       {"label": "Alkimos", "value": "C5472"},
                       {"label": "Applecross", "value": "C5473"},
                       {"label": "Ardross", "value": "C5474"},
                       {"label": "Armadale", "value": "C5475"},
                       {"label": "Ascot", "value": "C5476"},
                       {"label": "Ashburton", "value": "C5477"},
                       {"label": "Ashby", "value": "C5478"},
                       {"label": "Ashfield", "value": "C5479"},
                       {"label": "Attadale", "value": "C5480"},
                       {"label": "Atwell", "value": "C5481"},
                       {"label": "Aubin Grove", "value": "C5482"},
                       {"label": "Augusta", "value": "C5483"},
                       {"label": "Augusta-Margaret River Shire",
                        "value": "C5484"},
                       {"label": "Australind", "value": "C5485"},
                       {"label": "Aveley", "value": "C5486"},
                       {"label": "Badgingarra", "value": "C5487"},
                       {"label": "Bakers Hill", "value": "C5488"},
                       {"label": "Balcatta", "value": "C5489"},
                       {"label": "Baldivis", "value": "C5490"},
                       {"label": "Balga", "value": "C5491"},
                       {"label": "Ballajura", "value": "C5492"},
                       {"label": "Banjup", "value": "C5493"},
                       {"label": "Banksia Grove", "value": "C5494"},
                       {"label": "Bassendean", "value": "C5495"},
                       {"label": "Bateman", "value": "C5496"},
                       {"label": "Baynton", "value": "C5497"},
                       {"label": "Bayonet Head", "value": "C5498"},
                       {"label": "Bayswater", "value": "C5499"},
                       {"label": "Beachlands", "value": "C5500"},
                       {"label": "Beaconsfield", "value": "C5501"},
                       {"label": "Beckenham", "value": "C5502"},
                       {"label": "Bedford", "value": "C5503"},
                       {"label": "Bedfordale", "value": "C5504"},
                       {"label": "Beechboro", "value": "C5505"},
                       {"label": "Beeliar", "value": "C5506"},
                       {"label": "Beldon", "value": "C5507"},
                       {"label": "Bellevue", "value": "C5508"},
                       {"label": "Belmont", "value": "C5509"},
                       {"label": "Bennett Springs", "value": "C5510"},
                       {"label": "Bentley", "value": "C5511"},
                       {"label": "Beresford", "value": "C5512"},
                       {"label": "Bertram", "value": "C5513"},
                       {"label": "Beverley", "value": "C5514"},
                       {"label": "Bibra Lake", "value": "C5515"},
                       {"label": "Bicton", "value": "C5516"},
                       {"label": "Bilingurr", "value": "C5517"},
                       {"label": "Bindoon", "value": "C5518"},
                       {"label": "Binningup", "value": "C5519"},
                       {"label": "Bluff Point", "value": "C5520"},
                       {"label": "Boddington", "value": "C5521"},
                       {"label": "Booragoon", "value": "C5522"},
                       {"label": "Boulder", "value": "C5523"},
                       {"label": "Boyanup", "value": "C5524"},
                       {"label": "Boyup Brook", "value": "C5525"},
                       {"label": "Brabham", "value": "C5526"},
                       {"label": "Brentwood", "value": "C5527"},
                       {"label": "Bridgetown", "value": "C5528"},
                       {"label": "Bridgetown-Greenbushes", "value": "C5529"},
                       {"label": "Broadwater", "value": "C5530"},
                       {"label": "Brockman", "value": "C5531"},
                       {"label": "Brookdale", "value": "C5532"},
                       {"label": "Brookton", "value": "C5533"},
                       {"label": "Broome", "value": "C5534"},
                       {"label": "Broomehill-Tambellup", "value": "C5535"},
                       {"label": "Bruce Rock", "value": "C5536"},
                       {"label": "Brunswick", "value": "C5537"},
                       {"label": "Bulgarra", "value": "C5538"},
                       {"label": "Bull Creek", "value": "C5539"},
                       {"label": "Bullsbrook", "value": "C5540"},
                       {"label": "Bunbury", "value": "C5541"},
                       {"label": "Burns Beach", "value": "C5542"},
                       {"label": "Burswood", "value": "C5543"},
                       {"label": "Busselton", "value": "C5544"},
                       {"label": "Busselton city cenre", "value": "C5545"},
                       {"label": "Butler", "value": "C5546"},
                       {"label": "Byford", "value": "C5547"},
                       {"label": "Cable Beach", "value": "C5548"},
                       {"label": "Calista", "value": "C5549"},
                       {"label": "Cambridge", "value": "C5550"},
                       {"label": "Camillo", "value": "C5551"},
                       {"label": "Canning", "value": "C5552"},
                       {"label": "Canning Vale", "value": "C5553"},
                       {"label": "Cannington", "value": "C5554"},
                       {"label": "Capel", "value": "C5555"},
                       {"label": "Cardup", "value": "C5556"},
                       {"label": "Carey Park", "value": "C5557"},
                       {"label": "Carine", "value": "C5558"},
                       {"label": "Carlisle", "value": "C5559"},
                       {"label": "Carnamah", "value": "C5560"},
                       {"label": "Carnarvon", "value": "C5561"},
                       {"label": "Carramar", "value": "C5562"},
                       {"label": "Castletown", "value": "C5563"},
                       {"label": "Casuarina", "value": "C5564"},
                       {"label": "Cataby", "value": "C5565"},
                       {"label": "Caversham", "value": "C5566"},
                       {"label": "Cervantes", "value": "C5567"},
                       {"label": "Champion Lakes", "value": "C5568"},
                       {"label": "Chapman Valley", "value": "C5569"},
                       {"label": "Chidlow", "value": "C5570"},
                       {"label": "Chittering", "value": "C5571"},
                       {"label": "Churchlands", "value": "C5572"},
                       {"label": "City Beach", "value": "C5573"},
                       {"label": "City of Cockburn", "value": "C5574"},
                       {"label": "City of Perth", "value": "C5575"},
                       {"label": "Claremont", "value": "C5576"},
                       {"label": "Clarkson", "value": "C5577"},
                       {"label": "Cloverdale", "value": "C5578"},
                       {"label": "Cockburn Central", "value": "C5579"},
                       {"label": "College Grove", "value": "C5580"},
                       {"label": "Collie", "value": "C5581"},
                       {"label": "Como", "value": "C5582"},
                       {"label": "Connolly", "value": "C5583"},
                       {"label": "Coodanup", "value": "C5584"},
                       {"label": "Coogee", "value": "C5585"},
                       {"label": "Coolbellup", "value": "C5586"},
                       {"label": "Coolbinia", "value": "C5587"},
                       {"label": "Coolgardie", "value": "C5588"},
                       {"label": "Cooloongup", "value": "C5589"},
                       {"label": "Coorow", "value": "C5590"},
                       {"label": "Corrigin", "value": "C5591"},
                       {"label": "Cottesloe", "value": "C5592"},
                       {"label": "Cowaramup", "value": "C5593"},
                       {"label": "Craigie", "value": "C5594"},
                       {"label": "Cranbrook", "value": "C5595"},
                       {"label": "Crawley", "value": "C5596"},
                       {"label": "Cuballing", "value": "C5597"},
                       {"label": "Cue", "value": "C5598"},
                       {"label": "Cunderdin", "value": "C5599"},
                       {"label": "Currambine", "value": "C5600"},
                       {"label": "Daglish", "value": "C5601"},
                       {"label": "Dalkeith", "value": "C5602"},
                       {"label": "Dalwallinu", "value": "C5603"},
                       {"label": "Dalyellup", "value": "C5604"},
                       {"label": "Dampier", "value": "C5605"},
                       {"label": "Dampier Peninsula", "value": "C5606"},
                       {"label": "Dandaragan", "value": "C5607"},
                       {"label": "Darch", "value": "C5608"},
                       {"label": "Dardanup", "value": "C5609"},
                       {"label": "Darling Downs", "value": "C5610"},
                       {"label": "Darlington", "value": "C5611"},
                       {"label": "Dawesville", "value": "C5612"},
                       {"label": "Dayton", "value": "C5613"},
                       {"label": "Denham", "value": "C5614"},
                       {"label": "Denmark", "value": "C5615"},
                       {"label": "Derby", "value": "C5616"},
                       {"label": "Derby-West Kimberley", "value": "C5617"},
                       {"label": "Dianella", "value": "C5618"},
                       {"label": "Djugun", "value": "C5619"},
                       {"label": "Dongara", "value": "C5620"},
                       {"label": "Donnybrook", "value": "C5621"},
                       {"label": "Donnybrook-Balingup", "value": "C5622"},
                       {"label": "Doubleview", "value": "C5623"},
                       {"label": "Dowerin", "value": "C5624"},
                       {"label": "Drummond Cove", "value": "C5625"},
                       {"label": "Dudley Park", "value": "C5626"},
                       {"label": "Dumbleyung Shire", "value": "C5627"},
                       {"label": "Duncraig", "value": "C5628"},
                       {"label": "Dundas", "value": "C5629"},
                       {"label": "Dunsborough", "value": "C5630"},
                       {"label": "East Bunbury", "value": "C5631"},
                       {"label": "East Cannington", "value": "C5632"},
                       {"label": "East Carnarvon", "value": "C5633"},
                       {"label": "East Fremantle", "value": "C5634"},
                       {"label": "East Perth", "value": "C5635"},
                       {"label": "East Pilbara", "value": "C5636"},
                       {"label": "East Victoria Park", "value": "C5637"},
                       {"label": "Eaton", "value": "C5638"},
                       {"label": "Eden Hill", "value": "C5639"},
                       {"label": "Edgewater", "value": "C5640"},
                       {"label": "Eglinton", "value": "C5641"},
                       {"label": "Ellenbrook", "value": "C5642"},
                       {"label": "Embleton", "value": "C5643"},
                       {"label": "Erskine", "value": "C5644"},
                       {"label": "Esperance", "value": "C5645"},
                       {"label": "Esperance Shire", "value": "C5646"},
                       {"label": "Exmouth", "value": "C5647"},
                       {"label": "Falcon", "value": "C5648"},
                       {"label": "Ferndale", "value": "C5649"},
                       {"label": "Fitzroy Crossing", "value": "C5650"},
                       {"label": "Floreat", "value": "C5651"},
                       {"label": "Forrestdale", "value": "C5652"},
                       {"label": "Forrestfield", "value": "C5653"},
                       {"label": "Fremantle", "value": "C5654"},
                       {"label": "Garden Island", "value": "C5655"},
                       {"label": "Gelorup", "value": "C5656"},
                       {"label": "Geographe", "value": "C5657"},
                       {"label": "Geraldton", "value": "C5658"},
                       {"label": "Geraldton city centre", "value": "C5659"},
                       {"label": "Gidgegannup", "value": "C5660"},
                       {"label": "Gingin", "value": "C5661"},
                       {"label": "Girrawheen", "value": "C5662"},
                       {"label": "Glen Forrest", "value": "C5663"},
                       {"label": "Glen Iris", "value": "C5664"},
                       {"label": "Glendalough", "value": "C5665"},
                       {"label": "Gnangara", "value": "C5666"},
                       {"label": "Gnowangerup", "value": "C5667"},
                       {"label": "Golden Bay", "value": "C5668"},
                       {"label": "Goomalling", "value": "C5669"},
                       {"label": "Gooseberry Hill", "value": "C5670"},
                       {"label": "Gosnells", "value": "C5671"},
                       {"label": "Grasmere", "value": "C5672"},
                       {"label": "Green Head", "value": "C5673"},
                       {"label": "Greenfields", "value": "C5674"},
                       {"label": "Greenmount", "value": "C5675"},
                       {"label": "Greenwood", "value": "C5676"},
                       {"label": "Guildford", "value": "C5677"},
                       {"label": "Gwelup", "value": "C5678"},
                       {"label": "Halls Creek", "value": "C5679"},
                       {"label": "Halls Head", "value": "C5680"},
                       {"label": "Hamersley", "value": "C5681"},
                       {"label": "Hamilton Hill", "value": "C5682"},
                       {"label": "Hammond Park", "value": "C5683"},
                       {"label": "Hannans", "value": "C5684"},
                       {"label": "Harrisdale", "value": "C5685"},
                       {"label": "Harvey", "value": "C5686"},
                       {"label": "Heathridge", "value": "C5687"},
                       {"label": "Helena Valley", "value": "C5688"},
                       {"label": "Henley Brook", "value": "C5689"},
                       {"label": "Herne Hill", "value": "C5690"},
                       {"label": "High Wycombe", "value": "C5691"},
                       {"label": "Highgate", "value": "C5692"},
                       {"label": "Hilbert", "value": "C5693"},
                       {"label": "Hill River", "value": "C5694"},
                       {"label": "Hillarys", "value": "C5695"},
                       {"label": "Hillman", "value": "C5696"},
                       {"label": "Hilton", "value": "C5697"},
                       {"label": "Hocking", "value": "C5698"},
                       {"label": "Huntingdale", "value": "C5699"},
                       {"label": "Iluka", "value": "C5700"},
                       {"label": "Inglewood", "value": "C5701"},
                       {"label": "Innaloo", "value": "C5702"},
                       {"label": "Irwin", "value": "C5703"},
                       {"label": "Jandakot", "value": "C5704"},
                       {"label": "Jane Brook", "value": "C5705"},
                       {"label": "Jarrahdale", "value": "C5706"},
                       {"label": "Jerramungup", "value": "C5707"},
                       {"label": "Jindalee", "value": "C5708"},
                       {"label": "Jolimont", "value": "C5709"},
                       {"label": "Joondalup", "value": "C5710"},
                       {"label": "Joondanna", "value": "C5711"},
                       {"label": "Jurien Bay", "value": "C5712"},
                       {"label": "Kalamunda", "value": "C5713"},
                       {"label": "Kalbarri", "value": "C5714"},
                       {"label": "Kalgoorlie", "value": "C5715"},
                       {"label": "KalgoorlieBoulder", "value": "C5716"},
                       {"label": "Kallaroo", "value": "C5717"},
                       {"label": "Kambalda East", "value": "C5718"},
                       {"label": "Kambalda West", "value": "C5719"},
                       {"label": "Karawara", "value": "C5720"},
                       {"label": "Kardinya", "value": "C5721"},
                       {"label": "Karnup", "value": "C5722"},
                       {"label": "Karratha", "value": "C5723"},
                       {"label": "Karrinyup", "value": "C5724"},
                       {"label": "Katanning", "value": "C5725"},
                       {"label": "Kellerberrin", "value": "C5726"},
                       {"label": "Kelmscott", "value": "C5727"},
                       {"label": "Kent Shire", "value": "C5728"},
                       {"label": "Kenwick", "value": "C5729"},
                       {"label": "Kewdale", "value": "C5730"},
                       {"label": "Kiara", "value": "C5731"},
                       {"label": "Kingsley", "value": "C5732"},
                       {"label": "Kinross", "value": "C5733"},
                       {"label": "Kojonup", "value": "C5734"},
                       {"label": "Kondinin", "value": "C5735"},
                       {"label": "Koondoola", "value": "C5736"},
                       {"label": "Koorda", "value": "C5737"},
                       {"label": "Kulin", "value": "C5738"},
                       {"label": "Kununurra", "value": "C5739"},
                       {"label": "Kwinana", "value": "C5740"},
                       {"label": "Lake Grace", "value": "C5741"},
                       {"label": "Lakelands", "value": "C5742"},
                       {"label": "Lamington", "value": "C5743"},
                       {"label": "Lancelin", "value": "C5744"},
                       {"label": "Landsdale", "value": "C5745"},
                       {"label": "Langford", "value": "C5746"},
                       {"label": "Lathlain", "value": "C5747"},
                       {"label": "Laverton", "value": "C5748"},
                       {"label": "Leda", "value": "C5749"},
                       {"label": "Leederville", "value": "C5750"},
                       {"label": "Leeman", "value": "C5751"},
                       {"label": "Leeming", "value": "C5752"},
                       {"label": "Leinster", "value": "C5753"},
                       {"label": "Leonora", "value": "C5754"},
                       {"label": "Leschenault", "value": "C5755"},
                       {"label": "Lesmurdie", "value": "C5756"},
                       {"label": "Little Grove", "value": "C5757"},
                       {"label": "Lockridge", "value": "C5758"},
                       {"label": "Lockyer", "value": "C5759"},
                       {"label": "Lower Chittering", "value": "C5760"},
                       {"label": "Lower King", "value": "C5761"},
                       {"label": "Lynwood", "value": "C5762"},
                       {"label": "Maddington", "value": "C5763"},
                       {"label": "Madeley", "value": "C5764"},
                       {"label": "Madora Bay", "value": "C5765"},
                       {"label": "Maida Vale", "value": "C5766"},
                       {"label": "Malaga", "value": "C5767"},
                       {"label": "Mandurah", "value": "C5768"},
                       {"label": "Mandurah city centre", "value": "C5769"},
                       {"label": "Manjimup", "value": "C5770"},
                       {"label": "Manning", "value": "C5771"},
                       {"label": "Marangaroo", "value": "C5772"},
                       {"label": "Marble Bar", "value": "C5773"},
                       {"label": "Margaret River", "value": "C5774"},
                       {"label": "Marmion", "value": "C5775"},
                       {"label": "Martin", "value": "C5776"},
                       {"label": "Maylands", "value": "C5777"},
                       {"label": "McKail", "value": "C5778"},
                       {"label": "Meadow Springs", "value": "C5779"},
                       {"label": "Medina", "value": "C5780"},
                       {"label": "Meekatharra", "value": "C5781"},
                       {"label": "Melville", "value": "C5782"},
                       {"label": "Menora", "value": "C5783"},
                       {"label": "Menzies", "value": "C5784"},
                       {"label": "Merredin", "value": "C5785"},
                       {"label": "Merriwa", "value": "C5786"},
                       {"label": "Middle Swan", "value": "C5787"},
                       {"label": "Midland", "value": "C5788"},
                       {"label": "Midvale", "value": "C5789"},
                       {"label": "Millars Well", "value": "C5790"},
                       {"label": "Millbridge", "value": "C5791"},
                       {"label": "Mindarie", "value": "C5792"},
                       {"label": "Mingenew", "value": "C5793"},
                       {"label": "Mira Mar", "value": "C5794"},
                       {"label": "Mirrabooka", "value": "C5795"},
                       {"label": "Moora", "value": "C5796"},
                       {"label": "Morawa", "value": "C5797"},
                       {"label": "Morley", "value": "C5798"},
                       {"label": "Mosman Park", "value": "C5799"},
                       {"label": "Mount Barker", "value": "C5800"},
                       {"label": "Mount Claremont", "value": "C5801"},
                       {"label": "Mount Hawthorn", "value": "C5802"},
                       {"label": "Mount Helena", "value": "C5803"},
                       {"label": "Mount Lawley", "value": "C5804"},
                       {"label": "Mount Magnet", "value": "C5805"},
                       {"label": "Mount Marshall", "value": "C5806"},
                       {"label": "Mount Melville", "value": "C5807"},
                       {"label": "Mount Nasura", "value": "C5808"},
                       {"label": "Mount Pleasant", "value": "C5809"},
                       {"label": "Mount Richon", "value": "C5810"},
                       {"label": "Mount Tarcoola", "value": "C5811"},
                       {"label": "Muchea", "value": "C5812"},
                       {"label": "Mukinbudin", "value": "C5813"},
                       {"label": "Mullaloo", "value": "C5814"},
                       {"label": "Mundaring", "value": "C5815"},
                       {"label": "Mundijong", "value": "C5816"},
                       {"label": "Munster", "value": "C5817"},
                       {"label": "Murchison", "value": "C5818"},
                       {"label": "Murdoch", "value": "C5819"},
                       {"label": "Murray", "value": "C5820"},
                       {"label": "Myaree", "value": "C5821"},
                       {"label": "Nannup", "value": "C5822"},
                       {"label": "Narembeen", "value": "C5823"},
                       {"label": "Narrogin", "value": "C5824"},
                       {"label": "Nedlands", "value": "C5825"},
                       {"label": "Newman", "value": "C5826"},
                       {"label": "Ngaanyatjarraku", "value": "C5827"},
                       {"label": "Nickol", "value": "C5828"},
                       {"label": "Nollamara", "value": "C5829"},
                       {"label": "Noranda", "value": "C5830"},
                       {"label": "North Beach", "value": "C5831"},
                       {"label": "North Coogee", "value": "C5832"},
                       {"label": "North Fremantle", "value": "C5833"},
                       {"label": "North Lake", "value": "C5834"},
                       {"label": "North Perth", "value": "C5835"},
                       {"label": "Northam", "value": "C5836"},
                       {"label": "Northampton Shire", "value": "C5837"},
                       {"label": "Northbridge", "value": "C5838"},
                       {"label": "Nullagine", "value": "C5839"},
                       {"label": "Nulsen", "value": "C5840"},
                       {"label": "Nungarin", "value": "C5841"},
                       {"label": "Oakford", "value": "C5842"},
                       {"label": "Ocean Reef", "value": "C5843"},
                       {"label": "Onslow", "value": "C5844"},
                       {"label": "Orana", "value": "C5845"},
                       {"label": "Orelia", "value": "C5846"},
                       {"label": "Osborne Park", "value": "C5847"},
                       {"label": "Padbury", "value": "C5848"},
                       {"label": "Palmyra", "value": "C5849"},
                       {"label": "Paraburdoo", "value": "C5850"},
                       {"label": "Parkerville", "value": "C5851"},
                       {"label": "Parkwood", "value": "C5852"},
                       {"label": "Parmelia", "value": "C5853"},
                       {"label": "Pearce", "value": "C5854"},
                       {"label": "Pearsall", "value": "C5855"},
                       {"label": "Pegs Creek", "value": "C5856"},
                       {"label": "Pemberton", "value": "C5857"},
                       {"label": "Peppermint Grove", "value": "C5858"},
                       {"label": "Perenjori", "value": "C5859"},
                       {"label": "Perth", "value": "C5860"},
                       {"label": "Perth city centre", "value": "C5861"},
                       {"label": "Piara Waters", "value": "C5862"},
                       {"label": "Piccadilly", "value": "C5863"},
                       {"label": "Pingelly", "value": "C5864"},
                       {"label": "Pinjarra", "value": "C5865"},
                       {"label": "Plantagenet Shire", "value": "C5866"},
                       {"label": "Port Denison", "value": "C5867"},
                       {"label": "Port Hedland", "value": "C5868"},
                       {"label": "Port Kennedy", "value": "C5869"},
                       {"label": "Quairading", "value": "C5870"},
                       {"label": "Queens Park", "value": "C5871"},
                       {"label": "Quindalup", "value": "C5872"},
                       {"label": "Quinns Rocks", "value": "C5873"},
                       {"label": "Rangeway", "value": "C5874"},
                       {"label": "Ravensthorpe", "value": "C5875"},
                       {"label": "Ravenswood", "value": "C5876"},
                       {"label": "Redcliffe", "value": "C5877"},
                       {"label": "Regans Ford", "value": "C5878"},
                       {"label": "Ridgewood", "value": "C5879"},
                       {"label": "Riverton", "value": "C5880"},
                       {"label": "Rivervale", "value": "C5881"},
                       {"label": "Rockingham", "value": "C5882"},
                       {"label": "Rockingham city centre", "value": "C5883"},
                       {"label": "Roebuck", "value": "C5884"},
                       {"label": "Roleystone", "value": "C5885"},
                       {"label": "Rossmoyne", "value": "C5886"},
                       {"label": "Safety Bay", "value": "C5887"},
                       {"label": "Salter Point", "value": "C5888"},
                       {"label": "Samson", "value": "C5889"},
                       {"label": "Sandstone", "value": "C5890"},
                       {"label": "Scarborough", "value": "C5891"},
                       {"label": "Secret Harbour", "value": "C5892"},
                       {"label": "Serpentine", "value": "C5893"},
                       {"label": "Serpentine-Jarrahdale", "value": "C5894"},
                       {"label": "Seville Grove", "value": "C5895"},
                       {"label": "Shark Bay", "value": "C5896"},
                       {"label": "Shelley", "value": "C5897"},
                       {"label": "Shenton Park", "value": "C5898"},
                       {"label": "Shoalwater", "value": "C5899"},
                       {"label": "Silver Sands", "value": "C5900"},
                       {"label": "Sinagra", "value": "C5901"},
                       {"label": "Singleton", "value": "C5902"},
                       {"label": "Somerville", "value": "C5903"},
                       {"label": "Sorrento", "value": "C5904"},
                       {"label": "South Bunbury", "value": "C5905"},
                       {"label": "South Carnarvon", "value": "C5906"},
                       {"label": "South Fremantle", "value": "C5907"},
                       {"label": "South Guildford", "value": "C5908"},
                       {"label": "South Hedland", "value": "C5909"},
                       {"label": "South Kalgoorlie", "value": "C5910"},
                       {"label": "South Lake", "value": "C5911"},
                       {"label": "South Perth", "value": "C5912"},
                       {"label": "South Yunderup", "value": "C5913"},
                       {"label": "Southern River", "value": "C5914"},
                       {"label": "Spalding", "value": "C5915"},
                       {"label": "Spearwood", "value": "C5916"},
                       {"label": "Spencer Park", "value": "C5917"},
                       {"label": "St George Ranges", "value": "C5918"},
                       {"label": "St James", "value": "C5919"},
                       {"label": "Stirling", "value": "C5920"},
                       {"label": "Stoneville", "value": "C5921"},
                       {"label": "Strathalbyn", "value": "C5922"},
                       {"label": "Stratton", "value": "C5923"},
                       {"label": "Subiaco", "value": "C5924"},
                       {"label": "Success", "value": "C5925"},
                       {"label": "Sunset Beach", "value": "C5926"},
                       {"label": "Swan", "value": "C5927"},
                       {"label": "Swan View", "value": "C5928"},
                       {"label": "Swanbourne", "value": "C5929"},
                       {"label": "Tammin", "value": "C5930"},
                       {"label": "Tapping", "value": "C5931"},
                       {"label": "Tarcoola Beach", "value": "C5932"},
                       {"label": "Telfer", "value": "C5933"},
                       {"label": "The Vines", "value": "C5934"},
                       {"label": "Thornlie", "value": "C5935"},
                       {"label": "Three Springs", "value": "C5936"},
                       {"label": "Tom Price", "value": "C5937"},
                       {"label": "Toodyay", "value": "C5938"},
                       {"label": "Trayning", "value": "C5939"},
                       {"label": "Trigg", "value": "C5940"},
                       {"label": "Tuart Hill", "value": "C5941"},
                       {"label": "Two Rocks", "value": "C5942"},
                       {"label": "Upper Gascoyne", "value": "C5943"},
                       {"label": "Usher", "value": "C5944"},
                       {"label": "Utakarra", "value": "C5945"},
                       {"label": "Vasse", "value": "C5946"},
                       {"label": "Victoria Park", "value": "C5947"},
                       {"label": "Victoria Plains", "value": "C5948"},
                       {"label": "Vincent", "value": "C5949"},
                       {"label": "Viveash", "value": "C5950"},
                       {"label": "Waggrakine", "value": "C5951"},
                       {"label": "Wagin", "value": "C5952"},
                       {"label": "Waikiki", "value": "C5953"},
                       {"label": "Wandering", "value": "C5954"},
                       {"label": "Wandi", "value": "C5955"},
                       {"label": "Wandina", "value": "C5956"},
                       {"label": "Wannanup", "value": "C5957"},
                       {"label": "Wanneroo", "value": "C5958"},
                       {"label": "Warnbro", "value": "C5959"},
                       {"label": "Waroona", "value": "C5960"},
                       {"label": "Warwick", "value": "C5961"},
                       {"label": "Waterford", "value": "C5962"},
                       {"label": "Watermans Bay", "value": "C5963"},
                       {"label": "Wattle Grove", "value": "C5964"},
                       {"label": "Wellard", "value": "C5965"},
                       {"label": "Wembley", "value": "C5966"},
                       {"label": "Wembley Downs", "value": "C5967"},
                       {"label": "West Arthur", "value": "C5968"},
                       {"label": "West Beach", "value": "C5969"},
                       {"label": "West Busselton", "value": "C5970"},
                       {"label": "West Lamington", "value": "C5971"},
                       {"label": "West Leederville", "value": "C5972"},
                       {"label": "West Perth", "value": "C5973"},
                       {"label": "Westminster", "value": "C5974"},
                       {"label": "Westonia", "value": "C5975"},
                       {"label": "White Gum Valley", "value": "C5976"},
                       {"label": "Wickepin", "value": "C5977"},
                       {"label": "Wickham", "value": "C5978"},
                       {"label": "Willagee", "value": "C5979"},
                       {"label": "Willetton", "value": "C5980"},
                       {"label": "Williams", "value": "C5981"},
                       {"label": "Wilson", "value": "C5982"},
                       {"label": "Wiluna", "value": "C5983"},
                       {"label": "Winthrop", "value": "C5984"},
                       {"label": "Withers", "value": "C5985"},
                       {"label": "Wongan-Ballidu", "value": "C5986"},
                       {"label": "Wonthella", "value": "C5987"},
                       {"label": "Woodanilling", "value": "C5988"},
                       {"label": "Woodbridge", "value": "C5989"},
                       {"label": "Woodridge", "value": "C5990"},
                       {"label": "Woodvale", "value": "C5991"},
                       {"label": "Wooroloo", "value": "C5992"},
                       {"label": "Woorree", "value": "C5993"},
                       {"label": "Wundowie", "value": "C5994"},
                       {"label": "Wyalkatchem", "value": "C5995"},
                       {"label": "Wyndham-East Kimberley", "value": "C5996"},
                       {"label": "Yakamia", "value": "C5997"},
                       {"label": "Yalgoo", "value": "C5998"},
                       {"label": "Yallingup", "value": "C5999"},
                       {"label": "Yalyalup", "value": "C6000"},
                       {"label": "Yanchep", "value": "C6001"},
                       {"label": "Yangebup", "value": "C6002"},
                       {"label": "Yilgarn", "value": "C6003"},
                       {"label": "Yokine", "value": "C6004"},
                       {"label": "York", "value": "C6005"}],
 "Western Cape": [{"label": "Albertina", "value": "C108188"},
                  {"label": "Arniston", "value": "C108189"},
                  {"label": "Atlantis", "value": "C108190"},
                  {"label": "Beaufort West", "value": "C108191"},
                  {"label": "Bergvliet", "value": "C108192"},
                  {"label": "Bredasdorp", "value": "C108193"},
                  {"label": "Caledon", "value": "C108194"},
                  {"label": "Calitzdorp", "value": "C108195"},
                  {"label": "Cape Town", "value": "C108196"},
                  {"label": "Cape Winelands District Municipality",
                   "value": "C108197"},
                  {"label": "Central Karoo District Municipality",
                   "value": "C108198"},
                  {"label": "Ceres", "value": "C108199"},
                  {"label": "City of Cape Town", "value": "C108200"},
                  {"label": "Clanwilliam", "value": "C108201"},
                  {"label": "Claremont", "value": "C108202"},
                  {"label": "Constantia", "value": "C108203"},
                  {"label": "De Rust", "value": "C108204"},
                  {"label": "Eden District Municipality", "value": "C108205"},
                  {"label": "George", "value": "C108206"},
                  {"label": "Grabouw", "value": "C108207"},
                  {"label": "Hardys Memories of Africa", "value": "C108208"},
                  {"label": "Hermanus", "value": "C108209"},
                  {"label": "Knysna", "value": "C108210"},
                  {"label": "Kraaifontein", "value": "C108211"},
                  {"label": "Ladismith", "value": "C108212"},
                  {"label": "Lansdowne", "value": "C108213"},
                  {"label": "Malmesbury", "value": "C108214"},
                  {"label": "Montagu", "value": "C108215"},
                  {"label": "Moorreesburg", "value": "C108216"},
                  {"label": "Mossel Bay", "value": "C108217"},
                  {"label": "Newlands", "value": "C108218"},
                  {"label": "Oudtshoorn", "value": "C108219"},
                  {"label": "Overberg District Municipality",
                   "value": "C108220"},
                  {"label": "Paarl", "value": "C108221"},
                  {"label": "Piketberg", "value": "C108222"},
                  {"label": "Plettenberg Bay", "value": "C108223"},
                  {"label": "Prince Albert", "value": "C108224"},
                  {"label": "Retreat", "value": "C108225"},
                  {"label": "Riversdale", "value": "C108226"},
                  {"label": "Robertson", "value": "C108227"},
                  {"label": "Rondebosch", "value": "C108228"},
                  {"label": "Rosebank", "value": "C108229"},
                  {"label": "Saldanha", "value": "C108230"},
                  {"label": "Stellenbosch", "value": "C108231"},
                  {"label": "Sunset Beach", "value": "C108232"},
                  {"label": "Swellendam", "value": "C108233"},
                  {"label": "Vredenburg", "value": "C108234"},
                  {"label": "Vredendal", "value": "C108235"},
                  {"label": "Wellington", "value": "C108236"},
                  {"label": "West Coast District Municipality",
                   "value": "C108237"},
                  {"label": "Worcester", "value": "C108238"},
                  {"label": "Zoar", "value": "C108239"}],
 "Western Division": [{"label": "Ba", "value": "C24819"},
                      {"label": "Ba Province", "value": "C24820"},
                      {"label": "Lautoka", "value": "C24821"},
                      {"label": "Nadi", "value": "C24822"},
                      {"label": "Nandronga and Navosa Province",
                       "value": "C24823"},
                      {"label": "Ra Province", "value": "C24824"}],
 "Western Highlands Province": [{"label": "Baiyer Mul", "value": "C80476"},
                                {"label": "Dei", "value": "C80477"},
                                {"label": "Hagen", "value": "C80478"},
                                {"label": "Mount Hagen", "value": "C80479"},
                                {"label": "Tambul Nebilyer",
                                 "value": "C80480"}],
 "Western North": [{"label": "Aowin", "value": "C41574"},
                   {"label": "Bia East", "value": "C41575"},
                   {"label": "Bia West", "value": "C41576"},
                   {"label": "Bibiani-Anhwiaso-Bekwai", "value": "C41577"},
                   {"label": "Bodi", "value": "C41578"},
                   {"label": "Juaboso", "value": "C41579"},
                   {"label": "Sefwi-Akontombra", "value": "C41580"},
                   {"label": "Sefwi-Wiawso", "value": "C41581"},
                   {"label": "Suaman", "value": "C41582"}],
 "Western Province": [{"label": "Daru", "value": "C80481"},
                      {"label": "Kiunga", "value": "C80482"},
                      {"label": "Middle Fly", "value": "C80483"},
                      {"label": "Morehead", "value": "C80484"},
                      {"label": "North Fly", "value": "C80485"},
                      {"label": "South Fly", "value": "C80486"},
                      {"label": "Cyangugu", "value": "C105719"},
                      {"label": "Gisenyi", "value": "C105720"},
                      {"label": "Kibuye", "value": "C105721"},
                      {"label": "Gizo", "value": "C107879"},
                      {"label": "Battaramulla South", "value": "C115364"},
                      {"label": "Beruwala", "value": "C115365"},
                      {"label": "Colombo", "value": "C115366"},
                      {"label": "Colombo District", "value": "C115367"},
                      {"label": "Gampaha", "value": "C115368"},
                      {"label": "Gampaha District", "value": "C115369"},
                      {"label": "Hanwella Ihala", "value": "C115370"},
                      {"label": "Hendala", "value": "C115371"},
                      {"label": "Horana South", "value": "C115372"},
                      {"label": "Horawala Junction", "value": "C115373"},
                      {"label": "Ja Ela", "value": "C115374"},
                      {"label": "Kalutara", "value": "C115375"},
                      {"label": "Kandana", "value": "C115376"},
                      {"label": "Katunayaka", "value": "C115377"},
                      {"label": "Kelaniya", "value": "C115378"},
                      {"label": "Kotikawatta", "value": "C115379"},
                      {"label": "Minuwangoda", "value": "C115380"},
                      {"label": "Mulleriyawa", "value": "C115381"},
                      {"label": "Negombo", "value": "C115382"},
                      {"label": "Panadura", "value": "C115383"},
                      {"label": "Peliyagoda", "value": "C115384"},
                      {"label": "Pita Kotte", "value": "C115385"},
                      {"label": "Sri Jayewardenepura Kotte",
                       "value": "C115386"},
                      {"label": "Wattala", "value": "C115387"},
                      {"label": "Welisara", "value": "C115388"},
                      {"label": "Kalabo", "value": "C148151"},
                      {"label": "Kaoma", "value": "C148152"},
                      {"label": "Limulunga", "value": "C148153"},
                      {"label": "Lukulu", "value": "C148154"},
                      {"label": "Mongu", "value": "C148155"},
                      {"label": "Senanga", "value": "C148156"},
                      {"label": "Sesheke", "value": "C148157"}],
 "Western Region": [{"label": "Akranes", "value": "C44880"},
                    {"label": "Borgarbyggd", "value": "C44881"},
                    {"label": "Borgarnes", "value": "C44882"},
                    {"label": "Dalabyggd", "value": "C44883"},
                    {"label": "Eyja- og Miklaholtshreppur", "value": "C44884"},
                    {"label": "Helgafellssveit", "value": "C44885"},
                    {"label": "Hvalfjardarsveit", "value": "C44886"},
                    {"label": "olafsvik", "value": "C44887"},
                    {"label": "Skorradalshreppur", "value": "C44888"},
                    {"label": "Snaefellsbaer", "value": "C44889"},
                    {"label": "Stykkisholmur", "value": "C44890"},
                    {"label": "Baglung", "value": "C76064"},
                    {"label": "Bhattarai Danda", "value": "C76065"},
                    {"label": "Butwal", "value": "C76066"},
                    {"label": "Chitre", "value": "C76067"},
                    {"label": "Darchula", "value": "C76068"},
                    {"label": "Dihi", "value": "C76069"},
                    {"label": "Pokhara", "value": "C76070"},
                    {"label": "Siddharthanagar", "value": "C76071"},
                    {"label": "Tansen", "value": "C76072"},
                    {"label": "Walin", "value": "C76073"},
                    {"label": "Bundibugyo", "value": "C121266"},
                    {"label": "Bwizibwera", "value": "C121267"},
                    {"label": "Fort Portal", "value": "C121268"},
                    {"label": "Hoima", "value": "C121269"},
                    {"label": "Ibanda", "value": "C121270"},
                    {"label": "Ibanda District", "value": "C121271"},
                    {"label": "Kabale", "value": "C121272"},
                    {"label": "Kagadi", "value": "C121273"},
                    {"label": "Kamwenge", "value": "C121274"},
                    {"label": "Kanungu", "value": "C121275"},
                    {"label": "Kasese", "value": "C121276"},
                    {"label": "Kibale", "value": "C121277"},
                    {"label": "Kigorobya", "value": "C121278"},
                    {"label": "Kilembe", "value": "C121279"},
                    {"label": "Kiruhura", "value": "C121280"},
                    {"label": "Kisoro", "value": "C121281"},
                    {"label": "Kyenjojo", "value": "C121282"},
                    {"label": "Margherita", "value": "C121283"},
                    {"label": "Masindi", "value": "C121284"},
                    {"label": "Masindi Port", "value": "C121285"},
                    {"label": "Mbarara", "value": "C121286"},
                    {"label": "Muhororo", "value": "C121287"},
                    {"label": "Ntungamo", "value": "C121288"},
                    {"label": "Nyachera", "value": "C121289"},
                    {"label": "Rukungiri", "value": "C121290"}],
 "Western Tobago": [{"label": "Rio Claro", "value": "C119679"}],
 "Westfjords": [{"label": "isafjardarbaer", "value": "C44891"},
                {"label": "isafjordur", "value": "C44892"},
                {"label": "Reykholahreppur", "value": "C44893"},
                {"label": "Strandabyggd", "value": "C44894"},
                {"label": "Talknafjardarhreppur", "value": "C44895"}],
 "Westmoreland Parish": [{"label": "12th Street", "value": "C62442"},
                         {"label": "Amity", "value": "C62443"},
                         {"label": "Ashton", "value": "C62444"},
                         {"label": "Barneyside", "value": "C62445"},
                         {"label": "Bath", "value": "C62446"},
                         {"label": "Bath Mountain", "value": "C62447"},
                         {"label": "Beaufort", "value": "C62448"},
                         {"label": "Beeston Spring", "value": "C62449"},
                         {"label": "Belvedere", "value": "C62450"},
                         {"label": "Berkshire", "value": "C62451"},
                         {"label": "Bethel Town", "value": "C62452"},
                         {"label": "Big Bridge", "value": "C62453"},
                         {"label": "Bluefields", "value": "C62454"},
                         {"label": "Bog", "value": "C62455"},
                         {"label": "Broughton", "value": "C62456"},
                         {"label": "Burnt Savannah", "value": "C62457"},
                         {"label": "Cairn Curran", "value": "C62458"},
                         {"label": "Caledonia", "value": "C62459"},
                         {"label": "Carmel", "value": "C62460"},
                         {"label": "Cave", "value": "C62461"},
                         {"label": "Chantilly", "value": "C62462"},
                         {"label": "Content", "value": "C62463"},
                         {"label": "Cornwall Mountian", "value": "C62464"},
                         {"label": "Darliston", "value": "C62465"},
                         {"label": "Delveland", "value": "C62466"},
                         {"label": "Dillion Bigwoods", "value": "C62467"},
                         {"label": "Dundee", "value": "C62468"},
                         {"label": "Enfield", "value": "C62469"},
                         {"label": "Ferris", "value": "C62470"},
                         {"label": "Fort Williams", "value": "C62471"},
                         {"label": "Frome", "value": "C62472"},
                         {"label": "Fullersfield", "value": "C62473"},
                         {"label": "Georges Plain", "value": "C62474"},
                         {"label": "Goodens River", "value": "C62475"},
                         {"label": "Grange", "value": "C62476"},
                         {"label": "Grange Hill", "value": "C62477"},
                         {"label": "Haddo", "value": "C62478"},
                         {"label": "Harmony Town", "value": "C62479"},
                         {"label": "Hertford", "value": "C62480"},
                         {"label": "Jerusalem", "value": "C62481"},
                         {"label": "Kentucky", "value": "C62482"},
                         {"label": "Kilmarnoch", "value": "C62483"},
                         {"label": "Lambs River", "value": "C62484"},
                         {"label": "Leamington", "value": "C62485"},
                         {"label": "Lenox Bigwoods", "value": "C62486"},
                         {"label": "Little London", "value": "C62487"},
                         {"label": "Llandilo", "value": "C62488"},
                         {"label": "Mackfield", "value": "C62489"},
                         {"label": "Mearnsville", "value": "C62490"},
                         {"label": "Mount Airy", "value": "C62491"},
                         {"label": "Mount Stewart", "value": "C62492"},
                         {"label": "Negril", "value": "C62493"},
                         {"label": "New Market Oval", "value": "C62494"},
                         {"label": "New Roads", "value": "C62495"},
                         {"label": "New Works", "value": "C62496"},
                         {"label": "Orange Hill", "value": "C62497"},
                         {"label": "Paul Island", "value": "C62498"},
                         {"label": "Petersfield", "value": "C62499"},
                         {"label": "Petersville", "value": "C62500"},
                         {"label": "Porters Mountain", "value": "C62501"},
                         {"label": "Red Hills", "value": "C62502"},
                         {"label": "Revival", "value": "C62503"},
                         {"label": "Roaring River", "value": "C62504"},
                         {"label": "Russia", "value": "C62505"},
                         {"label": "Savanna-la-Mar", "value": "C62506"},
                         {"label": "Savannah-la-mar Business Dist.",
                          "value": "C62507"},
                         {"label": "Seaford Town", "value": "C62508"},
                         {"label": "Seaton Crescent", "value": "C62509"},
                         {"label": "Shefield", "value": "C62510"},
                         {"label": "Shoalin Grotto", "value": "C62511"},
                         {"label": "Smithfield", "value": "C62512"},
                         {"label": "St. Leonards", "value": "C62513"},
                         {"label": "Strathbogie", "value": "C62514"},
                         {"label": "Strawberry", "value": "C62515"},
                         {"label": "Struie", "value": "C62516"},
                         {"label": "Three Mile River", "value": "C62517"},
                         {"label": "Town Head", "value": "C62518"},
                         {"label": "Truro", "value": "C62519"},
                         {"label": "Water Works", "value": "C62520"},
                         {"label": "Whitehouse", "value": "C62521"},
                         {"label": "Whithorn", "value": "C62522"},
                         {"label": "Williamsfield", "value": "C62523"}],
 "White Nile": [{"label": "Ad Douiem", "value": "C115451"},
                {"label": "Al Kawa", "value": "C115452"},
                {"label": "Al Qitena", "value": "C115453"},
                {"label": "Kosti", "value": "C115454"},
                {"label": "Marabba", "value": "C115455"},
                {"label": "Rabak", "value": "C115456"},
                {"label": "Tandalti", "value": "C115457"},
                {"label": "Um Jar Al Gharbiyya", "value": "C115458"},
                {"label": "Wad az Zaki", "value": "C115459"}],
 "Wisconsin": [{"label": "Abbotsford", "value": "C146254"},
               {"label": "Adams", "value": "C146255"},
               {"label": "Adams County", "value": "C146256"},
               {"label": "Addison", "value": "C146257"},
               {"label": "Albany", "value": "C146258"},
               {"label": "Algoma", "value": "C146259"},
               {"label": "Allouez", "value": "C146260"},
               {"label": "Alma", "value": "C146261"},
               {"label": "Alto", "value": "C146262"},
               {"label": "Altoona", "value": "C146263"},
               {"label": "Amery", "value": "C146264"},
               {"label": "Amherst", "value": "C146265"},
               {"label": "Antigo", "value": "C146266"},
               {"label": "Appleton", "value": "C146267"},
               {"label": "Arcadia", "value": "C146268"},
               {"label": "Ashford", "value": "C146269"},
               {"label": "Ashland", "value": "C146270"},
               {"label": "Ashland County", "value": "C146271"},
               {"label": "Ashwaubenon", "value": "C146272"},
               {"label": "Athens", "value": "C146273"},
               {"label": "Augusta", "value": "C146274"},
               {"label": "Aztalan", "value": "C146275"},
               {"label": "Baldwin", "value": "C146276"},
               {"label": "Balsam Lake", "value": "C146277"},
               {"label": "Bangor", "value": "C146278"},
               {"label": "Baraboo", "value": "C146279"},
               {"label": "Barneveld", "value": "C146280"},
               {"label": "Barron", "value": "C146281"},
               {"label": "Barron County", "value": "C146282"},
               {"label": "Barton", "value": "C146283"},
               {"label": "Bayfield County", "value": "C146284"},
               {"label": "Bayside", "value": "C146285"},
               {"label": "Beaver Dam", "value": "C146286"},
               {"label": "Belgium", "value": "C146287"},
               {"label": "Belle Plaine", "value": "C146288"},
               {"label": "Belleville", "value": "C146289"},
               {"label": "Bellevue", "value": "C146290"},
               {"label": "Beloit", "value": "C146291"},
               {"label": "Berlin", "value": "C146292"},
               {"label": "Bevent", "value": "C146293"},
               {"label": "Big Bend", "value": "C146294"},
               {"label": "Black Creek", "value": "C146295"},
               {"label": "Black Earth", "value": "C146296"},
               {"label": "Black River Falls", "value": "C146297"},
               {"label": "Blair", "value": "C146298"},
               {"label": "Bloomer", "value": "C146299"},
               {"label": "Bohners Lake", "value": "C146300"},
               {"label": "Bonduel", "value": "C146301"},
               {"label": "Boscobel", "value": "C146302"},
               {"label": "Boyceville", "value": "C146303"},
               {"label": "Brice Prairie", "value": "C146304"},
               {"label": "Brillion", "value": "C146305"},
               {"label": "Bristol", "value": "C146306"},
               {"label": "Brodhead", "value": "C146307"},
               {"label": "Brookfield", "value": "C146308"},
               {"label": "Brooklyn", "value": "C146309"},
               {"label": "Brothertown", "value": "C146310"},
               {"label": "Brown County", "value": "C146311"},
               {"label": "Brown Deer", "value": "C146312"},
               {"label": "Browns Lake", "value": "C146313"},
               {"label": "Brussels", "value": "C146314"},
               {"label": "Buffalo County", "value": "C146315"},
               {"label": "Burlington", "value": "C146316"},
               {"label": "Burnett County", "value": "C146317"},
               {"label": "Butler", "value": "C146318"},
               {"label": "Cadott", "value": "C146319"},
               {"label": "Caledonia", "value": "C146320"},
               {"label": "Calumet County", "value": "C146321"},
               {"label": "Cambridge", "value": "C146322"},
               {"label": "Cameron", "value": "C146323"},
               {"label": "Camp Lake", "value": "C146324"},
               {"label": "Campbellsport", "value": "C146325"},
               {"label": "Cashton", "value": "C146326"},
               {"label": "Cato", "value": "C146327"},
               {"label": "Cedar Grove", "value": "C146328"},
               {"label": "Cedarburg", "value": "C146329"},
               {"label": "Chetek", "value": "C146330"},
               {"label": "Chilton", "value": "C146331"},
               {"label": "Chippewa County", "value": "C146332"},
               {"label": "Chippewa Falls", "value": "C146333"},
               {"label": "Clark County", "value": "C146334"},
               {"label": "Clear Lake", "value": "C146335"},
               {"label": "Cleveland", "value": "C146336"},
               {"label": "Clinton", "value": "C146337"},
               {"label": "Clintonville", "value": "C146338"},
               {"label": "Colby", "value": "C146339"},
               {"label": "Colfax", "value": "C146340"},
               {"label": "Columbia County", "value": "C146341"},
               {"label": "Columbus", "value": "C146342"},
               {"label": "Combined Locks", "value": "C146343"},
               {"label": "Como", "value": "C146344"},
               {"label": "Concord", "value": "C146345"},
               {"label": "Cooperstown", "value": "C146346"},
               {"label": "Cornell", "value": "C146347"},
               {"label": "Cottage Grove", "value": "C146348"},
               {"label": "Crandon", "value": "C146349"},
               {"label": "Crawford County", "value": "C146350"},
               {"label": "Cross Plains", "value": "C146351"},
               {"label": "Cuba City", "value": "C146352"},
               {"label": "Cudahy", "value": "C146353"},
               {"label": "Cumberland", "value": "C146354"},
               {"label": "Dakota", "value": "C146355"},
               {"label": "Dane", "value": "C146356"},
               {"label": "Dane County", "value": "C146357"},
               {"label": "Darien", "value": "C146358"},
               {"label": "Darlington", "value": "C146359"},
               {"label": "De Forest", "value": "C146360"},
               {"label": "De Pere", "value": "C146361"},
               {"label": "Decatur", "value": "C146362"},
               {"label": "Deerfield", "value": "C146363"},
               {"label": "Delafield", "value": "C146364"},
               {"label": "Delavan", "value": "C146365"},
               {"label": "Delavan Lake", "value": "C146366"},
               {"label": "Denmark", "value": "C146367"},
               {"label": "Dickeyville", "value": "C146368"},
               {"label": "Dodge County", "value": "C146369"},
               {"label": "Dodgeville", "value": "C146370"},
               {"label": "Door County", "value": "C146371"},
               {"label": "Douglas County", "value": "C146372"},
               {"label": "Dousman", "value": "C146373"},
               {"label": "Dunn County", "value": "C146374"},
               {"label": "Durand", "value": "C146375"},
               {"label": "Eagle", "value": "C146376"},
               {"label": "Eagle Lake", "value": "C146377"},
               {"label": "Eagle River", "value": "C146378"},
               {"label": "East Troy", "value": "C146379"},
               {"label": "Easton", "value": "C146380"},
               {"label": "Eau Claire", "value": "C146381"},
               {"label": "Eau Claire County", "value": "C146382"},
               {"label": "Edgar", "value": "C146383"},
               {"label": "Edgerton", "value": "C146384"},
               {"label": "Eldorado", "value": "C146385"},
               {"label": "Elkhorn", "value": "C146386"},
               {"label": "Ellsworth", "value": "C146387"},
               {"label": "Elm Grove", "value": "C146388"},
               {"label": "Elroy", "value": "C146389"},
               {"label": "Evansville", "value": "C146390"},
               {"label": "Evergreen", "value": "C146391"},
               {"label": "Fall Creek", "value": "C146392"},
               {"label": "Fall River", "value": "C146393"},
               {"label": "Fennimore", "value": "C146394"},
               {"label": "Fitchburg", "value": "C146395"},
               {"label": "Florence", "value": "C146396"},
               {"label": "Florence County", "value": "C146397"},
               {"label": "Fond du Lac", "value": "C146398"},
               {"label": "Fond du Lac County", "value": "C146399"},
               {"label": "Fontana", "value": "C146400"},
               {"label": "Forest County", "value": "C146401"},
               {"label": "Fort Atkinson", "value": "C146402"},
               {"label": "Fox Lake", "value": "C146403"},
               {"label": "Fox Point", "value": "C146404"},
               {"label": "Franklin", "value": "C146405"},
               {"label": "Franksville", "value": "C146406"},
               {"label": "Frederic", "value": "C146407"},
               {"label": "Fredonia", "value": "C146408"},
               {"label": "French Island", "value": "C146409"},
               {"label": "Friendship", "value": "C146410"},
               {"label": "Galesville", "value": "C146411"},
               {"label": "Genoa City", "value": "C146412"},
               {"label": "Germantown", "value": "C146413"},
               {"label": "Gillett", "value": "C146414"},
               {"label": "Glendale", "value": "C146415"},
               {"label": "Glenmore", "value": "C146416"},
               {"label": "Glenwood City", "value": "C146417"},
               {"label": "Grafton", "value": "C146418"},
               {"label": "Grant County", "value": "C146419"},
               {"label": "Grantsburg", "value": "C146420"},
               {"label": "Green Bay", "value": "C146421"},
               {"label": "Green County", "value": "C146422"},
               {"label": "Green Lake", "value": "C146423"},
               {"label": "Green Lake County", "value": "C146424"},
               {"label": "Greendale", "value": "C146425"},
               {"label": "Greenfield", "value": "C146426"},
               {"label": "Greenwood", "value": "C146427"},
               {"label": "Hales Corners", "value": "C146428"},
               {"label": "Hammond", "value": "C146429"},
               {"label": "Harrison", "value": "C146430"},
               {"label": "Hartford", "value": "C146431"},
               {"label": "Hartland", "value": "C146432"},
               {"label": "Hayward", "value": "C146433"},
               {"label": "Hazel Green", "value": "C146434"},
               {"label": "Hilbert", "value": "C146435"},
               {"label": "Hillsboro", "value": "C146436"},
               {"label": "Hobart", "value": "C146437"},
               {"label": "Holmen", "value": "C146438"},
               {"label": "Horicon", "value": "C146439"},
               {"label": "Hortonville", "value": "C146440"},
               {"label": "Howard", "value": "C146441"},
               {"label": "Howards Grove", "value": "C146442"},
               {"label": "Hudson", "value": "C146443"},
               {"label": "Hurley", "value": "C146444"},
               {"label": "Hustisford", "value": "C146445"},
               {"label": "Independence", "value": "C146446"},
               {"label": "Iola", "value": "C146447"},
               {"label": "Iowa County", "value": "C146448"},
               {"label": "Iron County", "value": "C146449"},
               {"label": "Ixonia", "value": "C146450"},
               {"label": "Jackson", "value": "C146451"},
               {"label": "Jackson County", "value": "C146452"},
               {"label": "Janesville", "value": "C146453"},
               {"label": "Jefferson", "value": "C146454"},
               {"label": "Jefferson County", "value": "C146455"},
               {"label": "Johnson Creek", "value": "C146456"},
               {"label": "Juneau", "value": "C146457"},
               {"label": "Juneau County", "value": "C146458"},
               {"label": "Kaukauna", "value": "C146459"},
               {"label": "Kenosha", "value": "C146460"},
               {"label": "Kenosha County", "value": "C146461"},
               {"label": "Keshena", "value": "C146462"},
               {"label": "Kewaskum", "value": "C146463"},
               {"label": "Kewaunee", "value": "C146464"},
               {"label": "Kewaunee County", "value": "C146465"},
               {"label": "Kiel", "value": "C146466"},
               {"label": "Kimberly", "value": "C146467"},
               {"label": "King", "value": "C146468"},
               {"label": "Kohler", "value": "C146469"},
               {"label": "Kronenwetter", "value": "C146470"},
               {"label": "La Crosse", "value": "C146471"},
               {"label": "La Crosse County", "value": "C146472"},
               {"label": "Lac du Flambeau", "value": "C146473"},
               {"label": "Ladysmith", "value": "C146474"},
               {"label": "Lafayette County", "value": "C146475"},
               {"label": "Lake Delton", "value": "C146476"},
               {"label": "Lake Geneva", "value": "C146477"},
               {"label": "Lake Hallie", "value": "C146478"},
               {"label": "Lake Koshkonong", "value": "C146479"},
               {"label": "Lake Mills", "value": "C146480"},
               {"label": "Lake Nebagamon", "value": "C146481"},
               {"label": "Lake Ripley", "value": "C146482"},
               {"label": "Lake Wazeecha", "value": "C146483"},
               {"label": "Lake Wisconsin", "value": "C146484"},
               {"label": "Lake Wissota", "value": "C146485"},
               {"label": "Lamartine", "value": "C146486"},
               {"label": "Lancaster", "value": "C146487"},
               {"label": "Langlade County", "value": "C146488"},
               {"label": "Lannon", "value": "C146489"},
               {"label": "Legend Lake", "value": "C146490"},
               {"label": "Lincoln County", "value": "C146491"},
               {"label": "Little Chute", "value": "C146492"},
               {"label": "Little Round Lake", "value": "C146493"},
               {"label": "Lodi", "value": "C146494"},
               {"label": "Lomira", "value": "C146495"},
               {"label": "Loyal", "value": "C146496"},
               {"label": "Luck", "value": "C146497"},
               {"label": "Luxemburg", "value": "C146498"},
               {"label": "Madison", "value": "C146499"},
               {"label": "Maine", "value": "C146500"},
               {"label": "Manawa", "value": "C146501"},
               {"label": "Manchester", "value": "C146502"},
               {"label": "Manitowoc", "value": "C146503"},
               {"label": "Manitowoc County", "value": "C146504"},
               {"label": "Maple Bluff", "value": "C146505"},
               {"label": "Marathon", "value": "C146506"},
               {"label": "Marathon County", "value": "C146507"},
               {"label": "Marinette", "value": "C146508"},
               {"label": "Marinette County", "value": "C146509"},
               {"label": "Marion", "value": "C146510"},
               {"label": "Markesan", "value": "C146511"},
               {"label": "Marquette County", "value": "C146512"},
               {"label": "Marshall", "value": "C146513"},
               {"label": "Marshfield", "value": "C146514"},
               {"label": "Mauston", "value": "C146515"},
               {"label": "Mayville", "value": "C146516"},
               {"label": "Mazomanie", "value": "C146517"},
               {"label": "McFarland", "value": "C146518"},
               {"label": "Medford", "value": "C146519"},
               {"label": "Menasha", "value": "C146520"},
               {"label": "Menominee County", "value": "C146521"},
               {"label": "Menomonee Falls", "value": "C146522"},
               {"label": "Menomonie", "value": "C146523"},
               {"label": "Mequon", "value": "C146524"},
               {"label": "Merrill", "value": "C146525"},
               {"label": "Merton", "value": "C146526"},
               {"label": "Middleton", "value": "C146527"},
               {"label": "Milford", "value": "C146528"},
               {"label": "Milton", "value": "C146529"},
               {"label": "Milwaukee", "value": "C146530"},
               {"label": "Milwaukee County", "value": "C146531"},
               {"label": "Mineral Point", "value": "C146532"},
               {"label": "Mishicot", "value": "C146533"},
               {"label": "Mondovi", "value": "C146534"},
               {"label": "Monona", "value": "C146535"},
               {"label": "Monroe", "value": "C146536"},
               {"label": "Monroe County", "value": "C146537"},
               {"label": "Montello", "value": "C146538"},
               {"label": "Monticello", "value": "C146539"},
               {"label": "Mosinee", "value": "C146540"},
               {"label": "Mount Horeb", "value": "C146541"},
               {"label": "Mount Morris", "value": "C146542"},
               {"label": "Mount Pleasant", "value": "C146543"},
               {"label": "Mukwonago", "value": "C146544"},
               {"label": "Muscoda", "value": "C146545"},
               {"label": "Muskego", "value": "C146546"},
               {"label": "Nashotah", "value": "C146547"},
               {"label": "Nashville", "value": "C146548"},
               {"label": "Neenah", "value": "C146549"},
               {"label": "Neillsville", "value": "C146550"},
               {"label": "Nekoosa", "value": "C146551"},
               {"label": "New Berlin", "value": "C146552"},
               {"label": "New Glarus", "value": "C146553"},
               {"label": "New Holstein", "value": "C146554"},
               {"label": "New Lisbon", "value": "C146555"},
               {"label": "New London", "value": "C146556"},
               {"label": "New Richmond", "value": "C146557"},
               {"label": "Newburg", "value": "C146558"},
               {"label": "Niagara", "value": "C146559"},
               {"label": "North Fond du Lac", "value": "C146560"},
               {"label": "North Hudson", "value": "C146561"},
               {"label": "North La Crosse", "value": "C146562"},
               {"label": "North Prairie", "value": "C146563"},
               {"label": "Oak Creek", "value": "C146564"},
               {"label": "Oakfield", "value": "C146565"},
               {"label": "Oconomowoc", "value": "C146566"},
               {"label": "Oconto", "value": "C146567"},
               {"label": "Oconto County", "value": "C146568"},
               {"label": "Oconto Falls", "value": "C146569"},
               {"label": "Okauchee Lake", "value": "C146570"},
               {"label": "Omro", "value": "C146571"},
               {"label": "Onalaska", "value": "C146572"},
               {"label": "Oneida", "value": "C146573"},
               {"label": "Oneida County", "value": "C146574"},
               {"label": "Oostburg", "value": "C146575"},
               {"label": "Oregon", "value": "C146576"},
               {"label": "Orfordville", "value": "C146577"},
               {"label": "Osceola", "value": "C146578"},
               {"label": "Oshkosh", "value": "C146579"},
               {"label": "Osseo", "value": "C146580"},
               {"label": "Outagamie County", "value": "C146581"},
               {"label": "Ozaukee County", "value": "C146582"},
               {"label": "Paddock Lake", "value": "C146583"},
               {"label": "Palmyra", "value": "C146584"},
               {"label": "Pardeeville", "value": "C146585"},
               {"label": "Park Falls", "value": "C146586"},
               {"label": "Pell Lake", "value": "C146587"},
               {"label": "Pepin County", "value": "C146588"},
               {"label": "Peshtigo", "value": "C146589"},
               {"label": "Pewaukee", "value": "C146590"},
               {"label": "Phillips", "value": "C146591"},
               {"label": "Pierce County", "value": "C146592"},
               {"label": "Pittsfield", "value": "C146593"},
               {"label": "Platteville", "value": "C146594"},
               {"label": "Pleasant Prairie", "value": "C146595"},
               {"label": "Plover", "value": "C146596"},
               {"label": "Plymouth", "value": "C146597"},
               {"label": "Polk County", "value": "C146598"},
               {"label": "Port Edwards", "value": "C146599"},
               {"label": "Port Washington", "value": "C146600"},
               {"label": "Portage", "value": "C146601"},
               {"label": "Portage County", "value": "C146602"},
               {"label": "Portland", "value": "C146603"},
               {"label": "Potter Lake", "value": "C146604"},
               {"label": "Powers Lake", "value": "C146605"},
               {"label": "Poynette", "value": "C146606"},
               {"label": "Prairie du Chien", "value": "C146607"},
               {"label": "Prairie du Sac", "value": "C146608"},
               {"label": "Prescott", "value": "C146609"},
               {"label": "Price County", "value": "C146610"},
               {"label": "Princeton", "value": "C146611"},
               {"label": "Pulaski", "value": "C146612"},
               {"label": "Racine", "value": "C146613"},
               {"label": "Racine County", "value": "C146614"},
               {"label": "Randolph", "value": "C146615"},
               {"label": "Random Lake", "value": "C146616"},
               {"label": "Redgranite", "value": "C146617"},
               {"label": "Reedsburg", "value": "C146618"},
               {"label": "Reedsville", "value": "C146619"},
               {"label": "Rhinelander", "value": "C146620"},
               {"label": "Rib Mountain", "value": "C146621"},
               {"label": "Rice Lake", "value": "C146622"},
               {"label": "Richfield", "value": "C146623"},
               {"label": "Richland Center", "value": "C146624"},
               {"label": "Richland County", "value": "C146625"},
               {"label": "Richmond", "value": "C146626"},
               {"label": "Rio", "value": "C146627"},
               {"label": "Ripon", "value": "C146628"},
               {"label": "River Falls", "value": "C146629"},
               {"label": "River Hills", "value": "C146630"},
               {"label": "Roberts", "value": "C146631"},
               {"label": "Rochester", "value": "C146632"},
               {"label": "Rock County", "value": "C146633"},
               {"label": "Rome", "value": "C146634"},
               {"label": "Rosendale", "value": "C146635"},
               {"label": "Rothschild", "value": "C146636"},
               {"label": "Roxbury", "value": "C146637"},
               {"label": "Rusk County", "value": "C146638"},
               {"label": "Rutland", "value": "C146639"},
               {"label": "Saint Croix County", "value": "C146640"},
               {"label": "Saint Croix Falls", "value": "C146641"},
               {"label": "Saint Francis", "value": "C146642"},
               {"label": "Saint Peter", "value": "C146643"},
               {"label": "Salem", "value": "C146644"},
               {"label": "Sauk City", "value": "C146645"},
               {"label": "Sauk County", "value": "C146646"},
               {"label": "Saukville", "value": "C146647"},
               {"label": "Sawyer County", "value": "C146648"},
               {"label": "Schofield", "value": "C146649"},
               {"label": "Seymour", "value": "C146650"},
               {"label": "Sharon", "value": "C146651"},
               {"label": "Shawano", "value": "C146652"},
               {"label": "Shawano County", "value": "C146653"},
               {"label": "Sheboygan", "value": "C146654"},
               {"label": "Sheboygan County", "value": "C146655"},
               {"label": "Sheboygan Falls", "value": "C146656"},
               {"label": "Shell Lake", "value": "C146657"},
               {"label": "Sherwood", "value": "C146658"},
               {"label": "Shorewood", "value": "C146659"},
               {"label": "Shorewood Hills", "value": "C146660"},
               {"label": "Shullsburg", "value": "C146661"},
               {"label": "Silver Lake", "value": "C146662"},
               {"label": "Siren", "value": "C146663"},
               {"label": "Slinger", "value": "C146664"},
               {"label": "Somers", "value": "C146665"},
               {"label": "Somerset", "value": "C146666"},
               {"label": "South Milwaukee", "value": "C146667"},
               {"label": "Sparta", "value": "C146668"},
               {"label": "Spencer", "value": "C146669"},
               {"label": "Spooner", "value": "C146670"},
               {"label": "Spring Green", "value": "C146671"},
               {"label": "Spring Valley", "value": "C146672"},
               {"label": "Stanley", "value": "C146673"},
               {"label": "Stevens Point", "value": "C146674"},
               {"label": "Stoughton", "value": "C146675"},
               {"label": "Stratford", "value": "C146676"},
               {"label": "Strum", "value": "C146677"},
               {"label": "Sturgeon Bay", "value": "C146678"},
               {"label": "Sturtevant", "value": "C146679"},
               {"label": "Suamico", "value": "C146680"},
               {"label": "Sun Prairie", "value": "C146681"},
               {"label": "Superior", "value": "C146682"},
               {"label": "Sussex", "value": "C146683"},
               {"label": "Tainter Lake", "value": "C146684"},
               {"label": "Taylor County", "value": "C146685"},
               {"label": "Theresa", "value": "C146686"},
               {"label": "Thiensville", "value": "C146687"},
               {"label": "Thorp", "value": "C146688"},
               {"label": "Tichigan", "value": "C146689"},
               {"label": "Tomah", "value": "C146690"},
               {"label": "Tomahawk", "value": "C146691"},
               {"label": "Trempealeau", "value": "C146692"},
               {"label": "Trempealeau County", "value": "C146693"},
               {"label": "Turtle Lake", "value": "C146694"},
               {"label": "Twin Lakes", "value": "C146695"},
               {"label": "Two Rivers", "value": "C146696"},
               {"label": "Union Grove", "value": "C146697"},
               {"label": "Vernon County", "value": "C146698"},
               {"label": "Verona", "value": "C146699"},
               {"label": "Vilas County", "value": "C146700"},
               {"label": "Viroqua", "value": "C146701"},
               {"label": "Wales", "value": "C146702"},
               {"label": "Walworth", "value": "C146703"},
               {"label": "Walworth County", "value": "C146704"},
               {"label": "Washburn", "value": "C146705"},
               {"label": "Washburn County", "value": "C146706"},
               {"label": "Washington County", "value": "C146707"},
               {"label": "Waterford", "value": "C146708"},
               {"label": "Waterloo", "value": "C146709"},
               {"label": "Watertown", "value": "C146710"},
               {"label": "Waukesha", "value": "C146711"},
               {"label": "Waukesha County", "value": "C146712"},
               {"label": "Waunakee", "value": "C146713"},
               {"label": "Waupaca", "value": "C146714"},
               {"label": "Waupaca County", "value": "C146715"},
               {"label": "Waupun", "value": "C146716"},
               {"label": "Wausau", "value": "C146717"},
               {"label": "Waushara County", "value": "C146718"},
               {"label": "Wautoma", "value": "C146719"},
               {"label": "Wauwatosa", "value": "C146720"},
               {"label": "West Allis", "value": "C146721"},
               {"label": "West Baraboo", "value": "C146722"},
               {"label": "West Bend", "value": "C146723"},
               {"label": "West Milwaukee", "value": "C146724"},
               {"label": "West Salem", "value": "C146725"},
               {"label": "Westby", "value": "C146726"},
               {"label": "Westfield", "value": "C146727"},
               {"label": "Weston", "value": "C146728"},
               {"label": "Weyauwega", "value": "C146729"},
               {"label": "Whitefish Bay", "value": "C146730"},
               {"label": "Whitehall", "value": "C146731"},
               {"label": "Whitewater", "value": "C146732"},
               {"label": "Whiting", "value": "C146733"},
               {"label": "Williams Bay", "value": "C146734"},
               {"label": "Wind Lake", "value": "C146735"},
               {"label": "Wind Point", "value": "C146736"},
               {"label": "Windsor", "value": "C146737"},
               {"label": "Winnebago County", "value": "C146738"},
               {"label": "Winneconne", "value": "C146739"},
               {"label": "Wisconsin Dells", "value": "C146740"},
               {"label": "Wisconsin Rapids", "value": "C146741"},
               {"label": "Wittenberg", "value": "C146742"},
               {"label": "Wood County", "value": "C146743"},
               {"label": "Woodville", "value": "C146744"},
               {"label": "Wrightstown", "value": "C146745"}],
 "Woleu-Ntem Province": [{"label": "Bitam", "value": "C34146"},
                         {"label": "Mitzic", "value": "C34147"},
                         {"label": "Oyem", "value": "C34148"}],
 "Woroba District": [{"label": "Bafing", "value": "C20995"},
                     {"label": "Bere", "value": "C20996"},
                     {"label": "Mankono", "value": "C20997"},
                     {"label": "Seguela", "value": "C20998"},
                     {"label": "Touba", "value": "C20999"},
                     {"label": "Worodougou", "value": "C21000"}],
 "Wyoming": [{"label": "Afton", "value": "C146746"},
             {"label": "Albany County", "value": "C146747"},
             {"label": "Antelope Valley-Crestview", "value": "C146748"},
             {"label": "Arapahoe", "value": "C146749"},
             {"label": "Bar Nunn", "value": "C146750"},
             {"label": "Basin", "value": "C146751"},
             {"label": "Big Horn County", "value": "C146752"},
             {"label": "Buffalo", "value": "C146753"},
             {"label": "Campbell County", "value": "C146754"},
             {"label": "Carbon County", "value": "C146755"},
             {"label": "Casper", "value": "C146756"},
             {"label": "Cheyenne", "value": "C146757"},
             {"label": "Cody", "value": "C146758"},
             {"label": "Converse County", "value": "C146759"},
             {"label": "Crook County", "value": "C146760"},
             {"label": "Douglas", "value": "C146761"},
             {"label": "Ethete", "value": "C146762"},
             {"label": "Evanston", "value": "C146763"},
             {"label": "Evansville", "value": "C146764"},
             {"label": "Fort Washakie", "value": "C146765"},
             {"label": "Fox Farm-College", "value": "C146766"},
             {"label": "Fremont County", "value": "C146767"},
             {"label": "Gillette", "value": "C146768"},
             {"label": "Glenrock", "value": "C146769"},
             {"label": "Goshen County", "value": "C146770"},
             {"label": "Green River", "value": "C146771"},
             {"label": "Greybull", "value": "C146772"},
             {"label": "Guernsey", "value": "C146773"},
             {"label": "Hoback", "value": "C146774"},
             {"label": "Hot Springs County", "value": "C146775"},
             {"label": "Jackson", "value": "C146776"},
             {"label": "Johnson County", "value": "C146777"},
             {"label": "Kemmerer", "value": "C146778"},
             {"label": "Lander", "value": "C146779"},
             {"label": "Laramie", "value": "C146780"},
             {"label": "Laramie County", "value": "C146781"},
             {"label": "Lincoln County", "value": "C146782"},
             {"label": "Lovell", "value": "C146783"},
             {"label": "Lusk", "value": "C146784"},
             {"label": "Lyman", "value": "C146785"},
             {"label": "Marbleton", "value": "C146786"},
             {"label": "Mills", "value": "C146787"},
             {"label": "Moorcroft", "value": "C146788"},
             {"label": "Moose Wilson Road", "value": "C146789"},
             {"label": "Mountain View", "value": "C146790"},
             {"label": "Natrona County", "value": "C146791"},
             {"label": "Newcastle", "value": "C146792"},
             {"label": "Niobrara County", "value": "C146793"},
             {"label": "North Rock Springs", "value": "C146794"},
             {"label": "Park County", "value": "C146795"},
             {"label": "Pine Bluffs", "value": "C146796"},
             {"label": "Pinedale", "value": "C146797"},
             {"label": "Platte County", "value": "C146798"},
             {"label": "Powell", "value": "C146799"},
             {"label": "Rafter J Ranch", "value": "C146800"},
             {"label": "Ranchettes", "value": "C146801"},
             {"label": "Rawlins", "value": "C146802"},
             {"label": "Riverton", "value": "C146803"},
             {"label": "Rock Springs", "value": "C146804"},
             {"label": "Saratoga", "value": "C146805"},
             {"label": "Sheridan", "value": "C146806"},
             {"label": "Sheridan County", "value": "C146807"},
             {"label": "Sleepy Hollow", "value": "C146808"},
             {"label": "South Greeley", "value": "C146809"},
             {"label": "South Park", "value": "C146810"},
             {"label": "Star Valley Ranch", "value": "C146811"},
             {"label": "Sublette County", "value": "C146812"},
             {"label": "Sundance", "value": "C146813"},
             {"label": "Sweetwater County", "value": "C146814"},
             {"label": "Teton County", "value": "C146815"},
             {"label": "Thermopolis", "value": "C146816"},
             {"label": "Torrington", "value": "C146817"},
             {"label": "Uinta County", "value": "C146818"},
             {"label": "Upton", "value": "C146819"},
             {"label": "Washakie County", "value": "C146820"},
             {"label": "Weston County", "value": "C146821"},
             {"label": "Wheatland", "value": "C146822"},
             {"label": "Wilson", "value": "C146823"},
             {"label": "Worland", "value": "C146824"},
             {"label": "Wright", "value": "C146825"}],
 "Xaghra": [{"label": "Xaghra", "value": "C65929"}],
 "Xaisomboun Province": [{"label": "Anouvong district", "value": "C64834"},
                         {"label": "Longchaeng", "value": "C64835"},
                         {"label": "Muang Longxan", "value": "C64836"},
                         {"label": "Muang Thathom", "value": "C64837"}],
 "Xewkija": [{"label": "Xewkija", "value": "C65930"}],
 "Xghajra": [{"label": "Xghajra", "value": "C65931"}],
 "Xiangkhouang Province": [{"label": "Muang Phonsavan", "value": "C64838"}],
 "Xinjiang": [{"label": "Ailan Mubage", "value": "C19415"},
              {"label": "Aksu", "value": "C19416"},
              {"label": "Aksu Diqu", "value": "C19417"},
              {"label": "Altay", "value": "C19418"},
              {"label": "Altay Diqu", "value": "C19419"},
              {"label": "Aral", "value": "C19420"},
              {"label": "Aykol", "value": "C19421"},
              {"label": "Baijiantan", "value": "C19422"},
              {"label": "Baluntaicun", "value": "C19423"},
              {"label": "Bayingolin Mongol Zizhizhou", "value": "C19424"},
              {"label": "Changji", "value": "C19425"},
              {"label": "Changji Huizu Zizhizhou", "value": "C19426"},
              {"label": "Fukang", "value": "C19427"},
              {"label": "Hami", "value": "C19428"},
              {"label": "Hotan", "value": "C19429"},
              {"label": "Hoxtolgay", "value": "C19430"},
              {"label": "Huocheng", "value": "C19431"},
              {"label": "Ili Kazak Zizhizhou", "value": "C19432"},
              {"label": "Karamay", "value": "C19433"},
              {"label": "Karamay Shi", "value": "C19434"},
              {"label": "Kashgar", "value": "C19435"},
              {"label": "Kaxgar Diqu", "value": "C19436"},
              {"label": "Korla", "value": "C19437"},
              {"label": "Kuqa", "value": "C19438"},
              {"label": "Kuytun", "value": "C19439"},
              {"label": "Qapqal", "value": "C19440"},
              {"label": "Shache", "value": "C19441"},
              {"label": "Shihezi", "value": "C19442"},
              {"label": "Sishilichengzi", "value": "C19443"},
              {"label": "Tacheng", "value": "C19444"},
              {"label": "Tacheng Diqu", "value": "C19445"},
              {"label": "Turpan", "value": "C19446"},
              {"label": "Turpan Diqu", "value": "C19447"},
              {"label": "urumqi", "value": "C19448"},
              {"label": "Urumqi Shi", "value": "C19449"},
              {"label": "Xinyuan", "value": "C19450"},
              {"label": "Zangguy", "value": "C19451"}],
 "Xizang": [{"label": "Burang", "value": "C19452"},
            {"label": "Deqen", "value": "C19453"},
            {"label": "Jiangzi", "value": "C19454"},
            {"label": "Lhasa", "value": "C19455"},
            {"label": "Nagqu", "value": "C19456"},
            {"label": "Nagqu Diqu", "value": "C19457"},
            {"label": "Ngari Diqu", "value": "C19458"},
            {"label": "Nyingchi Prefecture", "value": "C19459"},
            {"label": "Qamdo", "value": "C19460"},
            {"label": "Qamdo Shi", "value": "C19461"},
            {"label": "Rikaze", "value": "C19462"},
            {"label": "Saga", "value": "C19463"},
            {"label": "Shannan Diqu", "value": "C19464"}],
 "Xorazm Region": [{"label": "Boghot Tumani", "value": "C147109"},
                   {"label": "Gurlan", "value": "C147110"},
                   {"label": "Hazorasp", "value": "C147111"},
                   {"label": "Khiwa", "value": "C147112"},
                   {"label": "Qushkupir", "value": "C147113"},
                   {"label": "Showot", "value": "C147114"},
                   {"label": "Urganch", "value": "C147115"}],
 "Yala": [{"label": "Amphoe Bannang Sata", "value": "C119584"},
          {"label": "Amphoe Betong", "value": "C119585"},
          {"label": "Amphoe Kabang", "value": "C119586"},
          {"label": "Amphoe Krong Pinang", "value": "C119587"},
          {"label": "Amphoe Mueang Yala", "value": "C119588"},
          {"label": "Amphoe Raman", "value": "C119589"},
          {"label": "Amphoe Than To", "value": "C119590"},
          {"label": "Amphoe Yaha", "value": "C119591"},
          {"label": "Betong", "value": "C119592"},
          {"label": "Yala", "value": "C119593"}],
 "Yalova": [{"label": "Altinova", "value": "C121126"},
            {"label": "Armutlu Ilcesi", "value": "C121127"},
            {"label": "Ciftlikkoy", "value": "C121128"},
            {"label": "Cinarcik", "value": "C121129"},
            {"label": "Kadikoy", "value": "C121130"},
            {"label": "Kaytazdere", "value": "C121131"},
            {"label": "Kilic", "value": "C121132"},
            {"label": "Kocadere", "value": "C121133"},
            {"label": "Korukoy", "value": "C121134"},
            {"label": "Taskopru", "value": "C121135"},
            {"label": "Termal Ilcesi", "value": "C121136"},
            {"label": "Yalova", "value": "C121137"}],
 "Yamagata Prefecture": [{"label": "Higashine", "value": "C64124"},
                         {"label": "Higashine Shi", "value": "C64125"},
                         {"label": "Kaminoyama", "value": "C64126"},
                         {"label": "Kaminoyama-shi", "value": "C64127"},
                         {"label": "Murayama", "value": "C64128"},
                         {"label": "Murayama Shi", "value": "C64129"},
                         {"label": "Nagai", "value": "C64130"},
                         {"label": "Nagai-shi", "value": "C64131"},
                         {"label": "Nanyo Shi", "value": "C64132"},
                         {"label": "Obanazawa", "value": "C64133"},
                         {"label": "Obanazawa Shi", "value": "C64134"},
                         {"label": "Sagae", "value": "C64135"},
                         {"label": "Sagae-shi", "value": "C64136"},
                         {"label": "Sakata", "value": "C64137"},
                         {"label": "Sakata Shi", "value": "C64138"},
                         {"label": "Shinjo", "value": "C64139"},
                         {"label": "Shinjo Shi", "value": "C64140"},
                         {"label": "Takahata", "value": "C64141"},
                         {"label": "Tendo", "value": "C64142"},
                         {"label": "Tendo Shi", "value": "C64143"},
                         {"label": "Tsuruoka", "value": "C64144"},
                         {"label": "Tsuruoka Shi", "value": "C64145"},
                         {"label": "Yamagata", "value": "C64146"},
                         {"label": "Yamagata Shi", "value": "C64147"},
                         {"label": "Yonezawa", "value": "C64148"},
                         {"label": "Yonezawa Shi", "value": "C64149"},
                         {"label": "Yuza", "value": "C64150"}],
 "Yamaguchi Prefecture": [{"label": "Hagi", "value": "C64151"},
                          {"label": "Hagi Shi", "value": "C64152"},
                          {"label": "Hikari", "value": "C64153"},
                          {"label": "Hikari Shi", "value": "C64154"},
                          {"label": "Hofu", "value": "C64155"},
                          {"label": "Hofu Shi", "value": "C64156"},
                          {"label": "Iwakuni Shi", "value": "C64157"},
                          {"label": "Kudamatsu", "value": "C64158"},
                          {"label": "Kudamatsu Shi", "value": "C64159"},
                          {"label": "Mine Shi", "value": "C64160"},
                          {"label": "Nagato", "value": "C64161"},
                          {"label": "Nagato Shi", "value": "C64162"},
                          {"label": "Ogori-shimogo", "value": "C64163"},
                          {"label": "Onoda", "value": "C64164"},
                          {"label": "Sanyoonoda Shi", "value": "C64165"},
                          {"label": "Shimonoseki Shi", "value": "C64166"},
                          {"label": "Shunan Shi", "value": "C64167"},
                          {"label": "Tokuyama", "value": "C64168"},
                          {"label": "Ube", "value": "C64169"},
                          {"label": "Yamaguchi Shi", "value": "C64170"},
                          {"label": "Yanai Shi", "value": "C64171"}],
 "Yamalo-Nenets Autonomous Okrug": [{"label": "Aksarka", "value": "C105559"},
                                    {"label": "Gubkinskiy", "value": "C105560"},
                                    {"label": "Kharp", "value": "C105561"},
                                    {"label": "Korotchaevo",
                                     "value": "C105562"},
                                    {"label": "Labytnangi", "value": "C105563"},
                                    {"label": "Muravlenko", "value": "C105564"},
                                    {"label": "Muzhi", "value": "C105565"},
                                    {"label": "Mys-Kamennyy",
                                     "value": "C105566"},
                                    {"label": "Nadym", "value": "C105567"},
                                    {"label": "Nadymskiy Rayon",
                                     "value": "C105568"},
                                    {"label": "Novyy Urengoy",
                                     "value": "C105569"},
                                    {"label": "Noyabrsk", "value": "C105570"},
                                    {"label": "Nyda", "value": "C105571"},
                                    {"label": "Pangody", "value": "C105572"},
                                    {"label": "Priuralskiy Rayon",
                                     "value": "C105573"},
                                    {"label": "Purovskiy Rayon",
                                     "value": "C105574"},
                                    {"label": "Purpe", "value": "C105575"},
                                    {"label": "Salekhard", "value": "C105576"},
                                    {"label": "Shuryshkarskiy Rayon",
                                     "value": "C105577"},
                                    {"label": "Staryy Nadym",
                                     "value": "C105578"},
                                    {"label": "Tarko-Sale", "value": "C105579"},
                                    {"label": "Tazovskiy", "value": "C105580"},
                                    {"label": "Urengoy", "value": "C105581"},
                                    {"label": "Yar-Sale", "value": "C105582"}],
 "Yamanashi Prefecture": [{"label": "Chuo-shi", "value": "C64172"},
                          {"label": "Enzan", "value": "C64173"},
                          {"label": "Fuefuki-shi", "value": "C64174"},
                          {"label": "Fuji-yoshida Shi", "value": "C64175"},
                          {"label": "Fujikawaguchiko", "value": "C64176"},
                          {"label": "Fujiyoshida", "value": "C64177"},
                          {"label": "Hokuto", "value": "C64178"},
                          {"label": "Hokuto-shi", "value": "C64179"},
                          {"label": "Isawa", "value": "C64180"},
                          {"label": "Kai-shi", "value": "C64181"},
                          {"label": "Kofu", "value": "C64182"},
                          {"label": "Kofu-shi", "value": "C64183"},
                          {"label": "Koshu-shi", "value": "C64184"},
                          {"label": "Minami Alps-shi", "value": "C64185"},
                          {"label": "Nirasaki", "value": "C64186"},
                          {"label": "Nirasaki-shi", "value": "C64187"},
                          {"label": "Otsuki", "value": "C64188"},
                          {"label": "Otsuki-shi", "value": "C64189"},
                          {"label": "Ryuo", "value": "C64190"},
                          {"label": "Tsuru-shi", "value": "C64191"},
                          {"label": "Uenohara", "value": "C64192"},
                          {"label": "Uenohara-shi", "value": "C64193"},
                          {"label": "Yamanashi-shi", "value": "C64194"}],
 "Yambol Province": [{"label": "Bolyarovo", "value": "C16341"},
                     {"label": "Elhovo", "value": "C16342"},
                     {"label": "Obshtina Bolyarovo", "value": "C16343"},
                     {"label": "Obshtina Elhovo", "value": "C16344"},
                     {"label": "Obshtina Straldzha", "value": "C16345"},
                     {"label": "Obshtina Tundzha", "value": "C16346"},
                     {"label": "Obshtina Yambol", "value": "C16347"},
                     {"label": "Straldzha", "value": "C16348"},
                     {"label": "Yambol", "value": "C16349"}],
 "Yangon Region": [{"label": "Kanbe", "value": "C75958"},
                   {"label": "Kayan", "value": "C75959"},
                   {"label": "Syriam", "value": "C75960"},
                   {"label": "Thongwa", "value": "C75961"},
                   {"label": "Twante", "value": "C75962"},
                   {"label": "Yangon", "value": "C75963"}],
 "Yap State": [{"label": "Colonia", "value": "C75465"},
               {"label": "Dalipebinaw Municipality", "value": "C75466"},
               {"label": "Fais", "value": "C75467"},
               {"label": "Fais Municipality", "value": "C75468"},
               {"label": "Fanif Municipality", "value": "C75469"},
               {"label": "Faraulep Municipality", "value": "C75470"},
               {"label": "Gagil Municipality", "value": "C75471"},
               {"label": "Gilman Municipality", "value": "C75472"},
               {"label": "Kanifay Municipality", "value": "C75473"},
               {"label": "Lamotrek Municipality", "value": "C75474"},
               {"label": "Maap Municipality", "value": "C75475"},
               {"label": "Ngulu Municipality", "value": "C75476"},
               {"label": "Rull Municipality", "value": "C75477"},
               {"label": "Rumung Municipality", "value": "C75478"},
               {"label": "Satawal Municipality", "value": "C75479"},
               {"label": "Tomil Municipality", "value": "C75480"},
               {"label": "Ulithi Municipality", "value": "C75481"},
               {"label": "Weloy Municipality", "value": "C75482"},
               {"label": "Woleai Municipality", "value": "C75483"}],
 "Yaracuy": [{"label": "Chivacoa", "value": "C147245"},
             {"label": "Municipio Independencia", "value": "C147246"},
             {"label": "Nirgua", "value": "C147247"},
             {"label": "San Felipe", "value": "C147248"},
             {"label": "Yaritagua", "value": "C147249"}],
 "Yardymli District": [{"label": "Yardimli", "value": "C8533"}],
 "Yaren District": [{"label": "Yaren", "value": "C76018"}],
 "Yaroslavl Oblast": [{"label": "Berendeyevo", "value": "C105583"},
                      {"label": "Bolsheselskiy Rayon", "value": "C105584"},
                      {"label": "Bolshoye Selo", "value": "C105585"},
                      {"label": "Borisoglebskiy", "value": "C105586"},
                      {"label": "Borisoglebskiy Rayon", "value": "C105587"},
                      {"label": "Borok", "value": "C105588"},
                      {"label": "Breytovo", "value": "C105589"},
                      {"label": "Breytovskiy Rayon", "value": "C105590"},
                      {"label": "Burmakino", "value": "C105591"},
                      {"label": "Danilov", "value": "C105592"},
                      {"label": "Danilovskiy Rayon", "value": "C105593"},
                      {"label": "Dubki", "value": "C105594"},
                      {"label": "Gavrilov-Yam", "value": "C105595"},
                      {"label": "Gavrilov-Yamskiy Rayon", "value": "C105596"},
                      {"label": "Ishnya", "value": "C105597"},
                      {"label": "Konstantinovskiy", "value": "C105598"},
                      {"label": "Krasnyy Profintern", "value": "C105599"},
                      {"label": "Krasnyye Tkachi", "value": "C105600"},
                      {"label": "Kukoboy", "value": "C105601"},
                      {"label": "Kurba", "value": "C105602"},
                      {"label": "Levashevo", "value": "C105603"},
                      {"label": "Lyubim", "value": "C105604"},
                      {"label": "Lyubimskiy Rayon", "value": "C105605"},
                      {"label": "Myshkin", "value": "C105606"},
                      {"label": "Myshkinskiy Rayon", "value": "C105607"},
                      {"label": "Nekouzskiy Rayon", "value": "C105608"},
                      {"label": "Nekrasovskiy Rayon", "value": "C105609"},
                      {"label": "Nekrasovskoye", "value": "C105610"},
                      {"label": "Novyy Nekouz", "value": "C105611"},
                      {"label": "Pereslavl-Zalesskiy", "value": "C105612"},
                      {"label": "Pereslavskiy Rayon", "value": "C105613"},
                      {"label": "Pervomayskiy Rayon", "value": "C105614"},
                      {"label": "Pesochnoye", "value": "C105615"},
                      {"label": "Petrovsk", "value": "C105616"},
                      {"label": "Porechye-Rybnoye", "value": "C105617"},
                      {"label": "Poshekhonye", "value": "C105618"},
                      {"label": "Poshekhonskiy Rayon", "value": "C105619"},
                      {"label": "Prechistoye", "value": "C105620"},
                      {"label": "Rostov", "value": "C105621"},
                      {"label": "Rostovskiy Rayon", "value": "C105622"},
                      {"label": "Rybinsk", "value": "C105623"},
                      {"label": "Rybinskiy Rayon", "value": "C105624"},
                      {"label": "Semibratovo", "value": "C105625"},
                      {"label": "Sudoverf", "value": "C105626"},
                      {"label": "Tunoshna", "value": "C105627"},
                      {"label": "Tutayev", "value": "C105628"},
                      {"label": "Tutayevskiy Rayon", "value": "C105629"},
                      {"label": "Uglich", "value": "C105630"},
                      {"label": "Uglichskiy Rayon", "value": "C105631"},
                      {"label": "Volga", "value": "C105632"},
                      {"label": "Yaroslavl", "value": "C105633"},
                      {"label": "Yaroslavskiy Rayon", "value": "C105634"}],
 "Yasothon": [{"label": "Amphoe Kham Khuan Kaeo", "value": "C119594"},
              {"label": "Amphoe Kho Wang", "value": "C119595"},
              {"label": "Amphoe Kut Chum", "value": "C119596"},
              {"label": "Amphoe Loeng Nok Tha", "value": "C119597"},
              {"label": "Amphoe Maha Chana Chai", "value": "C119598"},
              {"label": "Amphoe Mueang Yasothon", "value": "C119599"},
              {"label": "Amphoe Pa Tio", "value": "C119600"},
              {"label": "Amphoe Sai Mun", "value": "C119601"},
              {"label": "Amphoe Thai Charoen", "value": "C119602"},
              {"label": "Yasothon", "value": "C119603"}],
 "Yazd": [{"label": "Ardakan", "value": "C51107"},
          {"label": "Bafq", "value": "C51108"},
          {"label": "Khavas Kuh", "value": "C51109"},
          {"label": "Mahriz", "value": "C51110"},
          {"label": "Meybod", "value": "C51111"},
          {"label": "Shahrestan-e Abarkuh", "value": "C51112"},
          {"label": "Shahrestan-e Ardakan", "value": "C51113"},
          {"label": "Shahrestan-e Ashkezar", "value": "C51114"},
          {"label": "Shahrestan-e Bafq", "value": "C51115"},
          {"label": "Shahrestan-e Behabad", "value": "C51116"},
          {"label": "Shahrestan-e Khatam", "value": "C51117"},
          {"label": "Shahrestan-e Mehriz", "value": "C51118"},
          {"label": "Shahrestan-e Yazd", "value": "C51119"},
          {"label": "Tabas", "value": "C51120"},
          {"label": "Taft", "value": "C51121"},
          {"label": "Yazd", "value": "C51122"}],
 "Yen Bai": [{"label": "Huyen Luc Yen", "value": "C147718"},
             {"label": "Huyen Mu Cang Chai", "value": "C147719"},
             {"label": "Huyen Tram Tau", "value": "C147720"},
             {"label": "Huyen Tran Yen", "value": "C147721"},
             {"label": "Huyen Van Chan", "value": "C147722"},
             {"label": "Huyen Van Yen", "value": "C147723"},
             {"label": "Yen Bai", "value": "C147724"}],
 "Yerevan": [{"label": "Arabkir", "value": "C1848"},
             {"label": "Argavand", "value": "C1849"},
             {"label": "Jrashen", "value": "C1850"},
             {"label": "Kanakerravan", "value": "C1851"},
             {"label": "Vardadzor", "value": "C1852"},
             {"label": "Yerevan", "value": "C1853"}],
 "Yevlakh": [{"label": "Yevlakh", "value": "C8534"}],
 "Yevlakh District": [{"label": "Aran", "value": "C8535"},
                      {"label": "Qaramanli", "value": "C8536"}],
 "Yilan": [{"label": "Yilan", "value": "C117992"}],
 "Yobe": [{"label": "Damaturu", "value": "C78510"},
          {"label": "Dankalwa", "value": "C78511"},
          {"label": "Dapchi", "value": "C78512"},
          {"label": "Daura", "value": "C78513"},
          {"label": "Fika", "value": "C78514"},
          {"label": "Gashua", "value": "C78515"},
          {"label": "Geidam", "value": "C78516"},
          {"label": "Goniri", "value": "C78517"},
          {"label": "Gorgoram", "value": "C78518"},
          {"label": "Gujba", "value": "C78519"},
          {"label": "Gwio Kura", "value": "C78520"},
          {"label": "Kumagunnam", "value": "C78521"},
          {"label": "Lajere", "value": "C78522"},
          {"label": "Machina", "value": "C78523"},
          {"label": "Nguru", "value": "C78524"},
          {"label": "Potiskum", "value": "C78525"}],
 "Yoro Department": [{"label": "Agua Blanca Sur", "value": "C43706"},
                     {"label": "Arenal", "value": "C43707"},
                     {"label": "Armenia", "value": "C43708"},
                     {"label": "Ayapa", "value": "C43709"},
                     {"label": "Balsamo Oriental", "value": "C43710"},
                     {"label": "Carbajales", "value": "C43711"},
                     {"label": "Coyoles Central", "value": "C43712"},
                     {"label": "El Balsamo", "value": "C43713"},
                     {"label": "El Juncal", "value": "C43714"},
                     {"label": "El Negrito", "value": "C43715"},
                     {"label": "El Ocote", "value": "C43716"},
                     {"label": "El Progreso", "value": "C43717"},
                     {"label": "Guaimitas", "value": "C43718"},
                     {"label": "Jocon", "value": "C43719"},
                     {"label": "La Estancia", "value": "C43720"},
                     {"label": "La Guacamaya", "value": "C43721"},
                     {"label": "La Mina", "value": "C43722"},
                     {"label": "La Rosa", "value": "C43723"},
                     {"label": "La Sarrosa", "value": "C43724"},
                     {"label": "La Trinidad", "value": "C43725"},
                     {"label": "Las Vegas", "value": "C43726"},
                     {"label": "Lomitas", "value": "C43727"},
                     {"label": "Mojiman", "value": "C43728"},
                     {"label": "Morazan", "value": "C43729"},
                     {"label": "Nombre de Jesus", "value": "C43730"},
                     {"label": "Nueva Esperanza", "value": "C43731"},
                     {"label": "Ocote Paulino", "value": "C43732"},
                     {"label": "Olanchito", "value": "C43733"},
                     {"label": "Paujiles", "value": "C43734"},
                     {"label": "Punta Ocote", "value": "C43735"},
                     {"label": "San Antonio", "value": "C43736"},
                     {"label": "San Jose", "value": "C43737"},
                     {"label": "Santa Rita", "value": "C43738"},
                     {"label": "Subirana", "value": "C43739"},
                     {"label": "Sulaco", "value": "C43740"},
                     {"label": "Teguajinal", "value": "C43741"},
                     {"label": "Tepusteca", "value": "C43742"},
                     {"label": "Toyos", "value": "C43743"},
                     {"label": "Trojas", "value": "C43744"},
                     {"label": "Victoria", "value": "C43745"},
                     {"label": "Yorito", "value": "C43746"},
                     {"label": "Yoro", "value": "C43747"}],
 "Yozgat": [{"label": "Akdagmadeni", "value": "C121138"},
            {"label": "Aydincik", "value": "C121139"},
            {"label": "Bogazliyan Ilcesi", "value": "C121140"},
            {"label": "Candir Ilcesi", "value": "C121141"},
            {"label": "Cayiralan", "value": "C121142"},
            {"label": "Cekerek", "value": "C121143"},
            {"label": "Kadisehri", "value": "C121144"},
            {"label": "Saraykent", "value": "C121145"},
            {"label": "Sarikaya", "value": "C121146"},
            {"label": "Sefaatli Ilcesi", "value": "C121147"},
            {"label": "Sefaatli", "value": "C121148"},
            {"label": "Sorgun", "value": "C121149"},
            {"label": "Yenifakili", "value": "C121150"},
            {"label": "Yerkoy", "value": "C121151"},
            {"label": "Yozgat", "value": "C121152"}],
 "Yucatan": [{"label": "Abala", "value": "C75028"},
             {"label": "Acanceh", "value": "C75029"},
             {"label": "Akil", "value": "C75030"},
             {"label": "Baca", "value": "C75031"},
             {"label": "Becanchen", "value": "C75032"},
             {"label": "Bokoba", "value": "C75033"},
             {"label": "Bolon", "value": "C75034"},
             {"label": "Buctzotz", "value": "C75035"},
             {"label": "Cacalchen", "value": "C75036"},
             {"label": "Calcehtoc", "value": "C75037"},
             {"label": "Calotmul", "value": "C75038"},
             {"label": "Campestre Flamboyanes", "value": "C75039"},
             {"label": "Cansahcab", "value": "C75040"},
             {"label": "Cantamayec", "value": "C75041"},
             {"label": "Caucel", "value": "C75042"},
             {"label": "Celestun", "value": "C75043"},
             {"label": "Cenotillo", "value": "C75044"},
             {"label": "Cepeda", "value": "C75045"},
             {"label": "Chablekal", "value": "C75046"},
             {"label": "Chacsinkin", "value": "C75047"},
             {"label": "Chan Cenote", "value": "C75048"},
             {"label": "Chankom", "value": "C75049"},
             {"label": "Chapab", "value": "C75050"},
             {"label": "Chelem", "value": "C75051"},
             {"label": "Chemax", "value": "C75052"},
             {"label": "Chichen-Itza", "value": "C75053"},
             {"label": "Chichimila", "value": "C75054"},
             {"label": "Chicxulub Pueblo", "value": "C75055"},
             {"label": "Chicxulub Puerto", "value": "C75056"},
             {"label": "Chikindzonot", "value": "C75057"},
             {"label": "Chochola", "value": "C75058"},
             {"label": "Cholul", "value": "C75059"},
             {"label": "Cholul Canton", "value": "C75060"},
             {"label": "Chuburna", "value": "C75061"},
             {"label": "Chumayel", "value": "C75062"},
             {"label": "Chunchucmil", "value": "C75063"},
             {"label": "Citilcum", "value": "C75064"},
             {"label": "Colonia Yucatan", "value": "C75065"},
             {"label": "Conkal", "value": "C75066"},
             {"label": "Cuch Holoch", "value": "C75067"},
             {"label": "Cuncunul", "value": "C75068"},
             {"label": "Cuzama", "value": "C75069"},
             {"label": "Dzan", "value": "C75070"},
             {"label": "Dzemul", "value": "C75071"},
             {"label": "Dzibikak", "value": "C75072"},
             {"label": "Dzidzantun", "value": "C75073"},
             {"label": "Dzilam de Bravo", "value": "C75074"},
             {"label": "Dzilam Gonzalez", "value": "C75075"},
             {"label": "Dzitas", "value": "C75076"},
             {"label": "Dzitnup", "value": "C75077"},
             {"label": "Dzitya", "value": "C75078"},
             {"label": "Dzoncauich", "value": "C75079"},
             {"label": "Dzonot Carretero", "value": "C75080"},
             {"label": "Dzununcan", "value": "C75081"},
             {"label": "Ekmul", "value": "C75082"},
             {"label": "Ekpedz", "value": "C75083"},
             {"label": "El Cuyo", "value": "C75084"},
             {"label": "Emiliano Zapata", "value": "C75085"},
             {"label": "Espita", "value": "C75086"},
             {"label": "Euan", "value": "C75087"},
             {"label": "Halacho", "value": "C75088"},
             {"label": "Hocaba", "value": "C75089"},
             {"label": "Hoctun", "value": "C75090"},
             {"label": "Holca", "value": "C75091"},
             {"label": "Homun", "value": "C75092"},
             {"label": "Huhi", "value": "C75093"},
             {"label": "Hunucma", "value": "C75094"},
             {"label": "Hunuku", "value": "C75095"},
             {"label": "Itzincab Palomeque", "value": "C75096"},
             {"label": "Ixil", "value": "C75097"},
             {"label": "Izamal", "value": "C75098"},
             {"label": "Kanasin", "value": "C75099"},
             {"label": "Kancab", "value": "C75100"},
             {"label": "Kantunil", "value": "C75101"},
             {"label": "Kanxoc", "value": "C75102"},
             {"label": "Kaua", "value": "C75103"},
             {"label": "Kimbila", "value": "C75104"},
             {"label": "Kinchil", "value": "C75105"},
             {"label": "Kini", "value": "C75106"},
             {"label": "Kinil", "value": "C75107"},
             {"label": "Kochol", "value": "C75108"},
             {"label": "Komchen", "value": "C75109"},
             {"label": "Kopoma", "value": "C75110"},
             {"label": "Las Coloradas", "value": "C75111"},
             {"label": "Leona Vicario", "value": "C75112"},
             {"label": "Lepan", "value": "C75113"},
             {"label": "Libre Union", "value": "C75114"},
             {"label": "Loche", "value": "C75115"},
             {"label": "Mama", "value": "C75116"},
             {"label": "Mani", "value": "C75117"},
             {"label": "Maxcanu", "value": "C75118"},
             {"label": "Mayapan", "value": "C75119"},
             {"label": "Merida", "value": "C75120"},
             {"label": "Mococha", "value": "C75121"},
             {"label": "Molas", "value": "C75122"},
             {"label": "Motul", "value": "C75123"},
             {"label": "Muna", "value": "C75124"},
             {"label": "Muxupip", "value": "C75125"},
             {"label": "Nacuche", "value": "C75126"},
             {"label": "Nolo", "value": "C75127"},
             {"label": "Opichen", "value": "C75128"},
             {"label": "Oxcum", "value": "C75129"},
             {"label": "Oxkutzcab", "value": "C75130"},
             {"label": "Oxkutzkab", "value": "C75131"},
             {"label": "Panaba", "value": "C75132"},
             {"label": "Pencuyut", "value": "C75133"},
             {"label": "Peto", "value": "C75134"},
             {"label": "Piste", "value": "C75135"},
             {"label": "Pixoy", "value": "C75136"},
             {"label": "Popola", "value": "C75137"},
             {"label": "Popolnah", "value": "C75138"},
             {"label": "Progreso", "value": "C75139"},
             {"label": "Pustunich", "value": "C75140"},
             {"label": "Quintana Roo", "value": "C75141"},
             {"label": "Rio Lagartos", "value": "C75142"},
             {"label": "Sacalum", "value": "C75143"},
             {"label": "Sahcaba", "value": "C75144"},
             {"label": "Samahil", "value": "C75145"},
             {"label": "San Antonio Tedzidz", "value": "C75146"},
             {"label": "San Francisco Grande", "value": "C75147"},
             {"label": "San Jose Tzal", "value": "C75148"},
             {"label": "San Pedro Chimay", "value": "C75149"},
             {"label": "San Rafael", "value": "C75150"},
             {"label": "Sanahcat", "value": "C75151"},
             {"label": "Santa Elena", "value": "C75152"},
             {"label": "Santa Maria Acu", "value": "C75153"},
             {"label": "Santo Domingo", "value": "C75154"},
             {"label": "Seye", "value": "C75155"},
             {"label": "Sierra Papacal", "value": "C75156"},
             {"label": "Sinanche", "value": "C75157"},
             {"label": "Sisal", "value": "C75158"},
             {"label": "Sitilpech", "value": "C75159"},
             {"label": "Sitpach", "value": "C75160"},
             {"label": "Sotuta", "value": "C75161"},
             {"label": "Sucila", "value": "C75162"},
             {"label": "Sucopo", "value": "C75163"},
             {"label": "Sudzal", "value": "C75164"},
             {"label": "Suma", "value": "C75165"},
             {"label": "Suma de Hidalgo", "value": "C75166"},
             {"label": "Tahdzibichen", "value": "C75167"},
             {"label": "Tahdziu", "value": "C75168"},
             {"label": "Tahmek", "value": "C75169"},
             {"label": "Teabo", "value": "C75170"},
             {"label": "Tecax", "value": "C75171"},
             {"label": "Tecoh", "value": "C75172"},
             {"label": "Tekal de Venegas", "value": "C75173"},
             {"label": "Tekanto", "value": "C75174"},
             {"label": "Tekax", "value": "C75175"},
             {"label": "Tekik de Regil", "value": "C75176"},
             {"label": "Tekit", "value": "C75177"},
             {"label": "Tekoh", "value": "C75178"},
             {"label": "Tekom", "value": "C75179"},
             {"label": "Telchac Pueblo", "value": "C75180"},
             {"label": "Telchac Puerto", "value": "C75181"},
             {"label": "Telchaquillo", "value": "C75182"},
             {"label": "Temax", "value": "C75183"},
             {"label": "Temozon", "value": "C75184"},
             {"label": "Tepakan", "value": "C75185"},
             {"label": "Tesoco", "value": "C75186"},
             {"label": "Tetiz", "value": "C75187"},
             {"label": "Texan de Palomeque", "value": "C75188"},
             {"label": "Teya", "value": "C75189"},
             {"label": "Tibolon", "value": "C75190"},
             {"label": "Ticopo", "value": "C75191"},
             {"label": "Ticul", "value": "C75192"},
             {"label": "Tiholop", "value": "C75193"},
             {"label": "Tikuch", "value": "C75194"},
             {"label": "Timucuy", "value": "C75195"},
             {"label": "Tinum", "value": "C75196"},
             {"label": "Tixcacalcupul", "value": "C75197"},
             {"label": "Tixcacaltuyub", "value": "C75198"},
             {"label": "Tixcancal", "value": "C75199"},
             {"label": "Tixhualactun", "value": "C75200"},
             {"label": "Tixkokob", "value": "C75201"},
             {"label": "Tixmehuac", "value": "C75202"},
             {"label": "Tixpehual", "value": "C75203"},
             {"label": "Tizimin", "value": "C75204"},
             {"label": "Tunkas", "value": "C75205"},
             {"label": "Tzucacab", "value": "C75206"},
             {"label": "Uayalceh de Peon", "value": "C75207"},
             {"label": "Uayma", "value": "C75208"},
             {"label": "Uci", "value": "C75209"},
             {"label": "Ucu", "value": "C75210"},
             {"label": "Uman", "value": "C75211"},
             {"label": "Valladolid", "value": "C75212"},
             {"label": "X-Can", "value": "C75213"},
             {"label": "Xanaba", "value": "C75214"},
             {"label": "Xaya", "value": "C75215"},
             {"label": "Xcanatun", "value": "C75216"},
             {"label": "Xocchel", "value": "C75217"},
             {"label": "Xocen", "value": "C75218"},
             {"label": "Xohuayan", "value": "C75219"},
             {"label": "Xul", "value": "C75220"},
             {"label": "Yalkoba", "value": "C75221"},
             {"label": "Yaxcaba", "value": "C75222"},
             {"label": "Yaxcopoil", "value": "C75223"},
             {"label": "Yaxhachen", "value": "C75224"},
             {"label": "Yaxkukul", "value": "C75225"},
             {"label": "Yobain", "value": "C75226"},
             {"label": "Yotholin", "value": "C75227"}],
 "Yukon": [{"label": "Dawson City", "value": "C17780"},
           {"label": "Haines Junction", "value": "C17781"},
           {"label": "Watson Lake", "value": "C17782"},
           {"label": "Whitehorse", "value": "C17783"}],
 "Yunlin": [{"label": "Douliu", "value": "C117993"},
            {"label": "Yunlin", "value": "C117994"}],
 "Yunnan": [{"label": "Chuxiong Yizu Zizhizhou", "value": "C19465"},
            {"label": "Dali", "value": "C19466"},
            {"label": "Dali Baizu Zizhizhou", "value": "C19467"},
            {"label": "Dehong Daizu Jingpozu Zizhizhou", "value": "C19468"},
            {"label": "Deqen Tibetan Autonomous Prefecture", "value": "C19469"},
            {"label": "Gejiu", "value": "C19470"},
            {"label": "Haikou", "value": "C19471"},
            {"label": "Honghe Hanizu Yizu Zizhizhou", "value": "C19472"},
            {"label": "Jinghong", "value": "C19473"},
            {"label": "Kaihua", "value": "C19474"},
            {"label": "Kaiyuan", "value": "C19475"},
            {"label": "Kunming", "value": "C19476"},
            {"label": "Lianran", "value": "C19477"},
            {"label": "Lijiang", "value": "C19478"},
            {"label": "Lincang Shi", "value": "C19479"},
            {"label": "Longquan", "value": "C19480"},
            {"label": "Mabai", "value": "C19481"},
            {"label": "Majie", "value": "C19482"},
            {"label": "Miyang", "value": "C19483"},
            {"label": "Nujiang Lisuzu Zizhizhou", "value": "C19484"},
            {"label": "Qujing", "value": "C19485"},
            {"label": "Shangri-La", "value": "C19486"},
            {"label": "Shilin", "value": "C19487"},
            {"label": "Wenlan", "value": "C19488"},
            {"label": "Wenshan City", "value": "C19489"},
            {"label": "Wenshan Zhuangzu Miaozu Zizhizhou", "value": "C19490"},
            {"label": "Yuxi", "value": "C19491"},
            {"label": "Zhaotong", "value": "C19492"},
            {"label": "Zhongshu", "value": "C19493"}],
 "Zabaykalsky Krai": [{"label": "Aginskoye", "value": "C105635"},
                      {"label": "Aksha", "value": "C105636"},
                      {"label": "Akshinskiy Rayon", "value": "C105637"},
                      {"label": "Aleksandrovo-Zavodskiy Rayon",
                       "value": "C105638"},
                      {"label": "Aleksandrovskiy Zavod", "value": "C105639"},
                      {"label": "Amazar", "value": "C105640"},
                      {"label": "Atamanovka", "value": "C105641"},
                      {"label": "Bada", "value": "C105642"},
                      {"label": "Baley", "value": "C105643"},
                      {"label": "Baleyskiy Rayon", "value": "C105644"},
                      {"label": "Balyaga", "value": "C105645"},
                      {"label": "Bolshaya Tura", "value": "C105646"},
                      {"label": "Borzinskiy Rayon", "value": "C105647"},
                      {"label": "Borzya", "value": "C105648"},
                      {"label": "Bukachacha", "value": "C105649"},
                      {"label": "Chara", "value": "C105650"},
                      {"label": "Chernyshevsk", "value": "C105651"},
                      {"label": "Chita", "value": "C105652"},
                      {"label": "Chitinskiy Rayon", "value": "C105653"},
                      {"label": "Darasun", "value": "C105654"},
                      {"label": "Dauriya", "value": "C105655"},
                      {"label": "Domna", "value": "C105656"},
                      {"label": "Drovyanaya", "value": "C105657"},
                      {"label": "Duldurga", "value": "C105658"},
                      {"label": "Gazimuro-Zavodskiy Rayon", "value": "C105659"},
                      {"label": "Gazimurskiy Zavod", "value": "C105660"},
                      {"label": "Kadaya", "value": "C105661"},
                      {"label": "Kalanguy", "value": "C105662"},
                      {"label": "Kalga", "value": "C105663"},
                      {"label": "Karymskoye", "value": "C105664"},
                      {"label": "Khilok", "value": "C105665"},
                      {"label": "Kholbon", "value": "C105666"},
                      {"label": "Klichka", "value": "C105667"},
                      {"label": "Klyuchevskiy", "value": "C105668"},
                      {"label": "Kokuy", "value": "C105669"},
                      {"label": "Krasnokamensk", "value": "C105670"},
                      {"label": "Krasnyy Chikoy", "value": "C105671"},
                      {"label": "Kuanda", "value": "C105672"},
                      {"label": "Kurort-Darasun", "value": "C105673"},
                      {"label": "Kyra", "value": "C105674"},
                      {"label": "Makkaveyevo", "value": "C105675"},
                      {"label": "Mogocha", "value": "C105676"},
                      {"label": "Mogoytuy", "value": "C105677"},
                      {"label": "Mogzon", "value": "C105678"},
                      {"label": "Nerchinsk", "value": "C105679"},
                      {"label": "Nerchinskiy Zavod", "value": "C105680"},
                      {"label": "Nerchinsko-Zavodskiy Rayon",
                       "value": "C105681"},
                      {"label": "Nizhniy Tsasuchey", "value": "C105682"},
                      {"label": "Novaya Chara", "value": "C105683"},
                      {"label": "Novokruchininskiy", "value": "C105684"},
                      {"label": "Novoorlovsk", "value": "C105685"},
                      {"label": "Novopavlovka", "value": "C105686"},
                      {"label": "Olovyannaya", "value": "C105687"},
                      {"label": "Olovyanninskiy Rayon", "value": "C105688"},
                      {"label": "Orlovskiy", "value": "C105689"},
                      {"label": "Pervomayskiy", "value": "C105690"},
                      {"label": "Petrovsk-Zabaykalskiy", "value": "C105691"},
                      {"label": "Priargunsk", "value": "C105692"},
                      {"label": "Priargunskiy Rayon", "value": "C105693"},
                      {"label": "Priiskovyy", "value": "C105694"},
                      {"label": "Shelopugino", "value": "C105695"},
                      {"label": "Shilka", "value": "C105696"},
                      {"label": "Shilkinskiy Rayon", "value": "C105697"},
                      {"label": "Smolenka", "value": "C105698"},
                      {"label": "Sretensk", "value": "C105699"},
                      {"label": "Sretenskiy Rayon", "value": "C105700"},
                      {"label": "Tarbagatay", "value": "C105701"},
                      {"label": "Uletovskiy Rayon", "value": "C105702"},
                      {"label": "Ulety", "value": "C105703"},
                      {"label": "Ust-Karsk", "value": "C105704"},
                      {"label": "Verkh-Usugli", "value": "C105705"},
                      {"label": "Vershino-Darasunskiy", "value": "C105706"},
                      {"label": "Vershino-Shakhtaminskiy", "value": "C105707"},
                      {"label": "Zabaykalsk", "value": "C105708"},
                      {"label": "Zhireken", "value": "C105709"}],
 "Zabbar": [{"label": "Zabbar", "value": "C65932"}],
 "Zabljak Municipality": [{"label": "Zabljak", "value": "C75627"}],
 "Zabul": [{"label": "Qalat", "value": "C92"}],
 "Zacatecas": [{"label": "Agua Gorda", "value": "C75228"},
               {"label": "Altamira", "value": "C75229"},
               {"label": "Apozol", "value": "C75230"},
               {"label": "Atolinga", "value": "C75231"},
               {"label": "Bajio de San Nicolas", "value": "C75232"},
               {"label": "Buenavista de Trujillo", "value": "C75233"},
               {"label": "Canas", "value": "C75234"},
               {"label": "Canitas de Felipe Pescador", "value": "C75235"},
               {"label": "Cantuna", "value": "C75236"},
               {"label": "Carrillo", "value": "C75237"},
               {"label": "Casa de Cerros", "value": "C75238"},
               {"label": "Cedros", "value": "C75239"},
               {"label": "Cerrito de la Cruz", "value": "C75240"},
               {"label": "Chalchihuites", "value": "C75241"},
               {"label": "Chaparrosa", "value": "C75242"},
               {"label": "Charco Blanco", "value": "C75243"},
               {"label": "Chichimequillas", "value": "C75244"},
               {"label": "Chupaderos", "value": "C75245"},
               {"label": "Cicacalco", "value": "C75246"},
               {"label": "Cieneguillas", "value": "C75247"},
               {"label": "Cieneguitas", "value": "C75248"},
               {"label": "Colonia Felipe angeles", "value": "C75249"},
               {"label": "Colonia Francisco Garcia Salinas", "value": "C75250"},
               {"label": "Colonia Hidalgo (El Tecolote)", "value": "C75251"},
               {"label": "Colonia Jose Maria Morelos", "value": "C75252"},
               {"label": "Colonia Montemariana (Colonia Mariana)",
                "value": "C75253"},
               {"label": "Colonia Plenitud", "value": "C75254"},
               {"label": "Colonia San Francisco (San Francisco)",
                "value": "C75255"},
               {"label": "Concepcion del Oro", "value": "C75256"},
               {"label": "Crisostomos", "value": "C75257"},
               {"label": "Ejido Hidalgo", "value": "C75258"},
               {"label": "Ejido Zaragoza", "value": "C75259"},
               {"label": "El Cazadero", "value": "C75260"},
               {"label": "El Copetillo", "value": "C75261"},
               {"label": "El Fuerte", "value": "C75262"},
               {"label": "El Lampotal", "value": "C75263"},
               {"label": "El Nigromante", "value": "C75264"},
               {"label": "El Obraje", "value": "C75265"},
               {"label": "El Plateado de Joaquin Amaro", "value": "C75266"},
               {"label": "El Refugio", "value": "C75267"},
               {"label": "El Rucio", "value": "C75268"},
               {"label": "El Salto", "value": "C75269"},
               {"label": "El Salvador", "value": "C75270"},
               {"label": "El Saucito (El Horno)", "value": "C75271"},
               {"label": "El Sitio", "value": "C75272"},
               {"label": "El Tule", "value": "C75273"},
               {"label": "Emiliano Zapata", "value": "C75274"},
               {"label": "Emilio Carranza", "value": "C75275"},
               {"label": "Ermita de Guadalupe", "value": "C75276"},
               {"label": "Estacion Camacho", "value": "C75277"},
               {"label": "Estacion San Jose", "value": "C75278"},
               {"label": "Estancia de animas", "value": "C75279"},
               {"label": "Estancia de Guadalupe", "value": "C75280"},
               {"label": "Florencia", "value": "C75281"},
               {"label": "Fresnillo", "value": "C75282"},
               {"label": "General Enrique Estrada", "value": "C75283"},
               {"label": "General Juan Jose Rios", "value": "C75284"},
               {"label": "General Lauro G. Caloca (El Rascon)",
                "value": "C75285"},
               {"label": "General Panfilo Natera", "value": "C75286"},
               {"label": "Gonzales Ortega", "value": "C75287"},
               {"label": "Granadas", "value": "C75288"},
               {"label": "Guadalupe", "value": "C75289"},
               {"label": "Guanajuatillo", "value": "C75290"},
               {"label": "Hacienda Nueva", "value": "C75291"},
               {"label": "Hacienda Toribio", "value": "C75292"},
               {"label": "Hidalgo", "value": "C75293"},
               {"label": "Ignacio Zaragoza", "value": "C75294"},
               {"label": "J. Jesus Gonzalez Ortega (San Mateo)",
                "value": "C75295"},
               {"label": "Jalpa", "value": "C75296"},
               {"label": "Jaula de Abajo", "value": "C75297"},
               {"label": "Jerez de Garcia Salinas", "value": "C75298"},
               {"label": "Jimenez del Teul", "value": "C75299"},
               {"label": "Jose Maria Morelos", "value": "C75300"},
               {"label": "Jose Maria Morelos (Santa Monica)",
                "value": "C75301"},
               {"label": "Jose Maria Morelos y Pavon", "value": "C75302"},
               {"label": "Juan Aldama", "value": "C75303"},
               {"label": "Juchipila", "value": "C75304"},
               {"label": "La Ballena", "value": "C75305"},
               {"label": "La Capilla", "value": "C75306"},
               {"label": "La Concepcion", "value": "C75307"},
               {"label": "La Florida", "value": "C75308"},
               {"label": "La Laguna", "value": "C75309"},
               {"label": "La Luz", "value": "C75310"},
               {"label": "La Pendencia", "value": "C75311"},
               {"label": "La Tesorera", "value": "C75312"},
               {"label": "La Victoria", "value": "C75313"},
               {"label": "La Zacatecana", "value": "C75314"},
               {"label": "Laguna Seca", "value": "C75315"},
               {"label": "Las Catarinas", "value": "C75316"},
               {"label": "Las Esperanzas (El Ranchito)", "value": "C75317"},
               {"label": "Las Pilas", "value": "C75318"},
               {"label": "Lazaro Cardenas (Rancho Grande)", "value": "C75319"},
               {"label": "Lobatos", "value": "C75320"},
               {"label": "Loreto", "value": "C75321"},
               {"label": "Los Condes", "value": "C75322"},
               {"label": "Los Ramirez", "value": "C75323"},
               {"label": "Luis Moya", "value": "C75324"},
               {"label": "Malpaso", "value": "C75325"},
               {"label": "Maravillas", "value": "C75326"},
               {"label": "Martinez Dominguez", "value": "C75327"},
               {"label": "Mazapil", "value": "C75328"},
               {"label": "Mezquital del Oro", "value": "C75329"},
               {"label": "Miguel Auza", "value": "C75330"},
               {"label": "Milagros", "value": "C75331"},
               {"label": "Momax", "value": "C75332"},
               {"label": "Monte Escobedo", "value": "C75333"},
               {"label": "Morelos", "value": "C75334"},
               {"label": "Moyahua de Estrada", "value": "C75335"},
               {"label": "Nieves", "value": "C75336"},
               {"label": "Nochistlan de Mejia", "value": "C75337"},
               {"label": "Noria de angeles", "value": "C75338"},
               {"label": "Ojitos", "value": "C75339"},
               {"label": "Paso de Mendez", "value": "C75340"},
               {"label": "Pastelera", "value": "C75341"},
               {"label": "Pastoria", "value": "C75342"},
               {"label": "Pedregoso", "value": "C75343"},
               {"label": "Piedra Gorda", "value": "C75344"},
               {"label": "Pinos", "value": "C75345"},
               {"label": "Plateros", "value": "C75346"},
               {"label": "Pozo de Jarillas", "value": "C75347"},
               {"label": "Pozos de Gamboa", "value": "C75348"},
               {"label": "Rafael Yanez Sosa (El Mezquite)", "value": "C75349"},
               {"label": "Rancho Nuevo", "value": "C75350"},
               {"label": "Rancho Nuevo de Morelos (De Guadalupe)",
                "value": "C75351"},
               {"label": "Rio de Medina", "value": "C75352"},
               {"label": "Rio Florido", "value": "C75353"},
               {"label": "Rio Grande", "value": "C75354"},
               {"label": "Sain Alto", "value": "C75355"},
               {"label": "Sain Bajo", "value": "C75356"},
               {"label": "San Antonio del Cipres", "value": "C75357"},
               {"label": "San Blas", "value": "C75358"},
               {"label": "San Cristobal", "value": "C75359"},
               {"label": "San Jeronimo", "value": "C75360"},
               {"label": "San Jose de Castellanos", "value": "C75361"},
               {"label": "San Jose de la Era", "value": "C75362"},
               {"label": "San Jose de Lourdes", "value": "C75363"},
               {"label": "San Jose el Saladillo (El Saladillo)",
                "value": "C75364"},
               {"label": "San Marcos", "value": "C75365"},
               {"label": "San Martin", "value": "C75366"},
               {"label": "San Pablo", "value": "C75367"},
               {"label": "San Pedro Piedra Gorda", "value": "C75368"},
               {"label": "Santa Ana", "value": "C75369"},
               {"label": "Santa Elena", "value": "C75370"},
               {"label": "Santa Maria de los angeles", "value": "C75371"},
               {"label": "Santa Monica", "value": "C75372"},
               {"label": "Santa Rita", "value": "C75373"},
               {"label": "Santiago", "value": "C75374"},
               {"label": "Santiaguillo", "value": "C75375"},
               {"label": "Sauceda de La Borda", "value": "C75376"},
               {"label": "Seis de Enero", "value": "C75377"},
               {"label": "Susticacan", "value": "C75378"},
               {"label": "Tabasco", "value": "C75379"},
               {"label": "Tacoaleche", "value": "C75380"},
               {"label": "Tapias de Santa Cruz (Pedro Ruiz Gonzalez)",
                "value": "C75381"},
               {"label": "Tayahua", "value": "C75382"},
               {"label": "Tepechitlan", "value": "C75383"},
               {"label": "Tepetongo", "value": "C75384"},
               {"label": "Terminal de Providencia", "value": "C75385"},
               {"label": "Tetillas", "value": "C75386"},
               {"label": "Teul de Gonzalez Ortega", "value": "C75387"},
               {"label": "Tierra Blanca", "value": "C75388"},
               {"label": "Tlachichila", "value": "C75389"},
               {"label": "Tlaltenango de Sanchez Roman", "value": "C75390"},
               {"label": "Trancoso", "value": "C75391"},
               {"label": "Trinidad Garcia de la Cadena", "value": "C75392"},
               {"label": "Valparaiso", "value": "C75393"},
               {"label": "Victor Rosales", "value": "C75394"},
               {"label": "Villa de Cos", "value": "C75395"},
               {"label": "Villa Garcia", "value": "C75396"},
               {"label": "Villa Gonzalez Ortega", "value": "C75397"},
               {"label": "Villa Hidalgo", "value": "C75398"},
               {"label": "Villa Insurgentes (El Calabazal)", "value": "C75399"},
               {"label": "Villanueva", "value": "C75400"},
               {"label": "Zacatecas", "value": "C75401"},
               {"label": "Zoquite", "value": "C75402"}],
 "Zadar": [{"label": "Benkovac", "value": "C21564"},
           {"label": "Bibinje", "value": "C21565"},
           {"label": "Biograd na Moru", "value": "C21566"},
           {"label": "Galovac", "value": "C21567"},
           {"label": "Gornji Karin", "value": "C21568"},
           {"label": "Gracac", "value": "C21569"},
           {"label": "Grad Biograd na Moru", "value": "C21570"},
           {"label": "Jasenice", "value": "C21571"},
           {"label": "Kali", "value": "C21572"},
           {"label": "Krusevo", "value": "C21573"},
           {"label": "Nin", "value": "C21574"},
           {"label": "Novigrad Opcina", "value": "C21575"},
           {"label": "Obrovac", "value": "C21576"},
           {"label": "Osljak", "value": "C21577"},
           {"label": "Pag", "value": "C21578"},
           {"label": "Pakostane", "value": "C21579"},
           {"label": "Polaca", "value": "C21580"},
           {"label": "Policnik", "value": "C21581"},
           {"label": "Posedarje", "value": "C21582"},
           {"label": "Preko", "value": "C21583"},
           {"label": "Pridraga", "value": "C21584"},
           {"label": "Privlaka", "value": "C21585"},
           {"label": "Razanac", "value": "C21586"},
           {"label": "Sali", "value": "C21587"},
           {"label": "Skabrnja", "value": "C21588"},
           {"label": "Stari Grad", "value": "C21589"},
           {"label": "Starigrad", "value": "C21590"},
           {"label": "Sukosan", "value": "C21591"},
           {"label": "Sveti Filip i Jakov", "value": "C21592"},
           {"label": "Tkon", "value": "C21593"},
           {"label": "Turanj", "value": "C21594"},
           {"label": "Ugljan", "value": "C21595"},
           {"label": "Vir", "value": "C21596"},
           {"label": "Vrsi", "value": "C21597"},
           {"label": "Zadar", "value": "C21598"}],
 "Zaghouan Governorate": [{"label": "El Fahs", "value": "C119812"},
                          {"label": "Zaghouan", "value": "C119813"}],
 "Zagorje ob Savi Municipality": [{"label": "Izlake", "value": "C107859"},
                                  {"label": "Kisovec", "value": "C107860"},
                                  {"label": "Zagorje ob Savi",
                                   "value": "C107861"}],
 "Zagreb": [{"label": "Bestovje", "value": "C21599"},
            {"label": "Bistra", "value": "C21600"},
            {"label": "Brckovljani", "value": "C21601"},
            {"label": "Brdovec", "value": "C21602"},
            {"label": "Bregana", "value": "C21603"},
            {"label": "Donja Bistra", "value": "C21604"},
            {"label": "Donja Lomnica", "value": "C21605"},
            {"label": "Donja Zdencina", "value": "C21606"},
            {"label": "Donji Stupnik", "value": "C21607"},
            {"label": "Farkasevac", "value": "C21608"},
            {"label": "Gornja Bistra", "value": "C21609"},
            {"label": "Gracec", "value": "C21610"},
            {"label": "Grad Dugo Selo", "value": "C21611"},
            {"label": "Grad Jastrebarsko", "value": "C21612"},
            {"label": "Grad Samobor", "value": "C21613"},
            {"label": "Grad Sveti Ivan Zelina", "value": "C21614"},
            {"label": "Grad Velika Gorica", "value": "C21615"},
            {"label": "Grad Vrbovec", "value": "C21616"},
            {"label": "Grad Zapresic", "value": "C21617"},
            {"label": "Gradec", "value": "C21618"},
            {"label": "Gradici", "value": "C21619"},
            {"label": "Jablanovec", "value": "C21620"},
            {"label": "Jakovlje", "value": "C21621"},
            {"label": "Jastrebarsko", "value": "C21622"},
            {"label": "Kerestinec", "value": "C21623"},
            {"label": "Kriz", "value": "C21624"},
            {"label": "Kuce", "value": "C21625"},
            {"label": "Lonjica", "value": "C21626"},
            {"label": "Luka", "value": "C21627"},
            {"label": "Lukavec", "value": "C21628"},
            {"label": "Lupoglav", "value": "C21629"},
            {"label": "Micevec", "value": "C21630"},
            {"label": "Mraclin", "value": "C21631"},
            {"label": "Novo Cice", "value": "C21632"},
            {"label": "Novoselec", "value": "C21633"},
            {"label": "Opcina Dubrava", "value": "C21634"},
            {"label": "Oresje", "value": "C21635"},
            {"label": "Pojatno", "value": "C21636"},
            {"label": "Preseka", "value": "C21637"},
            {"label": "Prigorje Brdovecko", "value": "C21638"},
            {"label": "Pusca", "value": "C21639"},
            {"label": "Rakitje", "value": "C21640"},
            {"label": "Rakov Potok", "value": "C21641"},
            {"label": "Rude", "value": "C21642"},
            {"label": "Samobor", "value": "C21643"},
            {"label": "Stupnik", "value": "C21644"},
            {"label": "Sveta Nedelja", "value": "C21645"},
            {"label": "Sveta Nedjelja", "value": "C21646"},
            {"label": "Velika Gorica", "value": "C21647"},
            {"label": "Velika Mlaka", "value": "C21648"},
            {"label": "Velika Ostrna", "value": "C21649"},
            {"label": "Vrbovec", "value": "C21650"},
            {"label": "Zapresic", "value": "C21651"},
            {"label": "Zdenci Brdovecki", "value": "C21652"},
            {"label": "Brezovica", "value": "C21653"},
            {"label": "Centar", "value": "C21654"},
            {"label": "Dubrava", "value": "C21655"},
            {"label": "Gradska cetvrt Donji grad", "value": "C21656"},
            {"label": "Gradska cetvrt Gornji Grad - Medvescak",
             "value": "C21657"},
            {"label": "Gradska cetvrt Podsljeme", "value": "C21658"},
            {"label": "Horvati", "value": "C21659"},
            {"label": "Jankomir", "value": "C21660"},
            {"label": "Jezdovec", "value": "C21661"},
            {"label": "Kasina", "value": "C21662"},
            {"label": "Lucko", "value": "C21663"},
            {"label": "Novi Zagreb", "value": "C21664"},
            {"label": "Odra", "value": "C21665"},
            {"label": "Sesvete", "value": "C21666"},
            {"label": "Stenjevec", "value": "C21667"},
            {"label": "Strmec", "value": "C21668"},
            {"label": "Zadvorsko", "value": "C21669"},
            {"label": "Zagreb", "value": "C21670"}],
 "Zaire Province": [{"label": "Mbanza Congo", "value": "C571"},
                    {"label": "Nzeto", "value": "C572"},
                    {"label": "Soio", "value": "C573"}],
 "Zakarpatska Oblast": [{"label": "Batiovo", "value": "C122903"},
                        {"label": "Berehivska Miskrada", "value": "C122904"},
                        {"label": "Berehove", "value": "C122905"},
                        {"label": "Berehove Raion", "value": "C122906"},
                        {"label": "Bereznyky", "value": "C122907"},
                        {"label": "Berezovo", "value": "C122908"},
                        {"label": "Bilky", "value": "C122909"},
                        {"label": "Bobovo", "value": "C122910"},
                        {"label": "Bohdan", "value": "C122911"},
                        {"label": "Bushtyno", "value": "C122912"},
                        {"label": "Chop", "value": "C122913"},
                        {"label": "Chynadiyovo", "value": "C122914"},
                        {"label": "Dovhe", "value": "C122915"},
                        {"label": "Drahovo", "value": "C122916"},
                        {"label": "Dubove", "value": "C122917"},
                        {"label": "Horinchovo", "value": "C122918"},
                        {"label": "Irshava", "value": "C122919"},
                        {"label": "Irshava Raion", "value": "C122920"},
                        {"label": "Iza", "value": "C122921"},
                        {"label": "Kalyny", "value": "C122922"},
                        {"label": "Kamianytsia", "value": "C122923"},
                        {"label": "Khust", "value": "C122924"},
                        {"label": "Khust Raion", "value": "C122925"},
                        {"label": "Khustska Miskrada", "value": "C122926"},
                        {"label": "Kolochava", "value": "C122927"},
                        {"label": "Kopashnovo", "value": "C122928"},
                        {"label": "Korolevo", "value": "C122929"},
                        {"label": "Koson", "value": "C122930"},
                        {"label": "Kryva", "value": "C122931"},
                        {"label": "Kushnytsya", "value": "C122932"},
                        {"label": "Kvasy", "value": "C122933"},
                        {"label": "Lazeshchyna", "value": "C122934"},
                        {"label": "Lazi", "value": "C122935"},
                        {"label": "Lopukhiv", "value": "C122936"},
                        {"label": "Lypcha", "value": "C122937"},
                        {"label": "Lysychovo", "value": "C122938"},
                        {"label": "Mizhhirya", "value": "C122939"},
                        {"label": "Mizhhirya Raion", "value": "C122940"},
                        {"label": "Mukacheve", "value": "C122941"},
                        {"label": "Mukachevo Raion", "value": "C122942"},
                        {"label": "Muzhiyevo", "value": "C122943"},
                        {"label": "Mynay", "value": "C122944"},
                        {"label": "Nelipyno", "value": "C122945"},
                        {"label": "Neresnytsya", "value": "C122946"},
                        {"label": "Nevytske", "value": "C122947"},
                        {"label": "Nove Davydkovo", "value": "C122948"},
                        {"label": "Nyzhnye Selyshche", "value": "C122949"},
                        {"label": "Oleshnyk", "value": "C122950"},
                        {"label": "Onokivtsi", "value": "C122951"},
                        {"label": "Perechyn", "value": "C122952"},
                        {"label": "Perechyn Raion", "value": "C122953"},
                        {"label": "Pidvynohradiv", "value": "C122954"},
                        {"label": "Poroshkovo", "value": "C122955"},
                        {"label": "Pylypets", "value": "C122956"},
                        {"label": "Rakhiv", "value": "C122957"},
                        {"label": "Rakhiv Raion", "value": "C122958"},
                        {"label": "Rakoshyno", "value": "C122959"},
                        {"label": "Rokosovo", "value": "C122960"},
                        {"label": "Serednye", "value": "C122961"},
                        {"label": "Serednye Vodyane", "value": "C122962"},
                        {"label": "Solotvyno", "value": "C122963"},
                        {"label": "Storozhnytsya", "value": "C122964"},
                        {"label": "Strabychovo", "value": "C122965"},
                        {"label": "Svaliava Raion", "value": "C122966"},
                        {"label": "Svalyava", "value": "C122967"},
                        {"label": "Synevyr", "value": "C122968"},
                        {"label": "Synevyrska Poliana", "value": "C122969"},
                        {"label": "Tiachiv Raion", "value": "C122970"},
                        {"label": "Tyachiv", "value": "C122971"},
                        {"label": "Uzhgorod", "value": "C122972"},
                        {"label": "Uzhhorod Raion", "value": "C122973"},
                        {"label": "Vary", "value": "C122974"},
                        {"label": "Velyki Berehy", "value": "C122975"},
                        {"label": "Velykyi Bereznyi", "value": "C122976"},
                        {"label": "Velykyi Bereznyi Raion", "value": "C122977"},
                        {"label": "Volovets", "value": "C122978"},
                        {"label": "Volovets Raion", "value": "C122979"},
                        {"label": "Vylok", "value": "C122980"},
                        {"label": "Vynohradiv", "value": "C122981"},
                        {"label": "Vynohradiv Raion", "value": "C122982"},
                        {"label": "Vyshkovo", "value": "C122983"},
                        {"label": "Yasinya", "value": "C122984"}],
 "Zala County": [{"label": "Becsehely", "value": "C44799"},
                 {"label": "Cserszegtomaj", "value": "C44800"},
                 {"label": "Gyenesdias", "value": "C44801"},
                 {"label": "Heviz", "value": "C44802"},
                 {"label": "Keszthely", "value": "C44803"},
                 {"label": "Keszthelyi Jaras", "value": "C44804"},
                 {"label": "Lenti", "value": "C44805"},
                 {"label": "Lenti Jaras", "value": "C44806"},
                 {"label": "Letenye", "value": "C44807"},
                 {"label": "Letenyei Jaras", "value": "C44808"},
                 {"label": "Murakeresztur", "value": "C44809"},
                 {"label": "Nagykanizsa", "value": "C44810"},
                 {"label": "Nagykanizsai Jaras", "value": "C44811"},
                 {"label": "Pacsa", "value": "C44812"},
                 {"label": "Sarmellek", "value": "C44813"},
                 {"label": "Turje", "value": "C44814"},
                 {"label": "Vonyarcvashegy", "value": "C44815"},
                 {"label": "Zalaegerszeg", "value": "C44816"},
                 {"label": "Zalaegerszegi Jaras", "value": "C44817"},
                 {"label": "Zalakomar", "value": "C44818"},
                 {"label": "Zalalovo", "value": "C44819"},
                 {"label": "Zalaszentgrot", "value": "C44820"},
                 {"label": "Zalaszentgroti Jaras", "value": "C44821"}],
 "Zalec Municipality": [{"label": "Gotovlje", "value": "C107862"},
                        {"label": "Sempeter v Savinj. Dolini",
                         "value": "C107863"},
                        {"label": "Zabukovica", "value": "C107864"},
                        {"label": "Zalec", "value": "C107865"}],
 "Zambezi Region": [{"label": "Bagani", "value": "C76010"},
                    {"label": "Katima Mulilo", "value": "C76011"}],
 "Zambezia Province": [{"label": "Alto Molocue", "value": "C75887"},
                       {"label": "Chinde", "value": "C75888"},
                       {"label": "Quelimane", "value": "C75889"}],
 "Zamboanga Sibugay": [{"label": "Alicia", "value": "C87487"},
                       {"label": "Aurora", "value": "C87488"},
                       {"label": "Balagon", "value": "C87489"},
                       {"label": "Barcelona", "value": "C87490"},
                       {"label": "Batu", "value": "C87491"},
                       {"label": "Bayog", "value": "C87492"},
                       {"label": "Binuatan", "value": "C87493"},
                       {"label": "Bolong", "value": "C87494"},
                       {"label": "Buenavista", "value": "C87495"},
                       {"label": "Bunguiao", "value": "C87496"},
                       {"label": "Buug", "value": "C87497"},
                       {"label": "Cabaluay", "value": "C87498"},
                       {"label": "Calabasa", "value": "C87499"},
                       {"label": "Caracal", "value": "C87500"},
                       {"label": "Culianan", "value": "C87501"},
                       {"label": "Curuan", "value": "C87502"},
                       {"label": "Dalangin", "value": "C87503"},
                       {"label": "Danlugan", "value": "C87504"},
                       {"label": "Dapitan", "value": "C87505"},
                       {"label": "Dawa-Dawa", "value": "C87506"},
                       {"label": "Del Monte", "value": "C87507"},
                       {"label": "Dicayong", "value": "C87508"},
                       {"label": "Dimataling", "value": "C87509"},
                       {"label": "Diplahan", "value": "C87510"},
                       {"label": "Dipolo", "value": "C87511"},
                       {"label": "Dipolog", "value": "C87512"},
                       {"label": "Disod", "value": "C87513"},
                       {"label": "Dulian", "value": "C87514"},
                       {"label": "Dumalinao", "value": "C87515"},
                       {"label": "Dumingag", "value": "C87516"},
                       {"label": "East Migpulao", "value": "C87517"},
                       {"label": "Ganyangan", "value": "C87518"},
                       {"label": "Gubaan", "value": "C87519"},
                       {"label": "Guiniculalay", "value": "C87520"},
                       {"label": "Guipos", "value": "C87521"},
                       {"label": "Gutalac", "value": "C87522"},
                       {"label": "Ilaya", "value": "C87523"},
                       {"label": "Ipil", "value": "C87524"},
                       {"label": "Irasan", "value": "C87525"},
                       {"label": "Josefina", "value": "C87526"},
                       {"label": "Kabasalan", "value": "C87527"},
                       {"label": "Kagawasan", "value": "C87528"},
                       {"label": "Kalawit", "value": "C87529"},
                       {"label": "Kalian", "value": "C87530"},
                       {"label": "Katipunan", "value": "C87531"},
                       {"label": "Kawayan", "value": "C87532"},
                       {"label": "Kipit", "value": "C87533"},
                       {"label": "Kumalarang", "value": "C87534"},
                       {"label": "La Dicha", "value": "C87535"},
                       {"label": "Labangan", "value": "C87536"},
                       {"label": "Labason", "value": "C87537"},
                       {"label": "Labuan", "value": "C87538"},
                       {"label": "Lamisahan", "value": "C87539"},
                       {"label": "Landang Laum", "value": "C87540"},
                       {"label": "Langatian", "value": "C87541"},
                       {"label": "Laparay", "value": "C87542"},
                       {"label": "Lapuyan", "value": "C87543"},
                       {"label": "Legrada", "value": "C87544"},
                       {"label": "Leon Postigo", "value": "C87545"},
                       {"label": "Liloy", "value": "C87546"},
                       {"label": "Limaong", "value": "C87547"},
                       {"label": "Limpapa", "value": "C87548"},
                       {"label": "Linay", "value": "C87549"},
                       {"label": "Lingasan", "value": "C87550"},
                       {"label": "Lintangan", "value": "C87551"},
                       {"label": "Lumbayan", "value": "C87552"},
                       {"label": "Lumbog", "value": "C87553"},
                       {"label": "Mabuhay", "value": "C87554"},
                       {"label": "Mahayag", "value": "C87555"},
                       {"label": "Malangas", "value": "C87556"},
                       {"label": "Malayal", "value": "C87557"},
                       {"label": "Malim", "value": "C87558"},
                       {"label": "Mandih", "value": "C87559"},
                       {"label": "Mangusu", "value": "C87560"},
                       {"label": "Manicahan", "value": "C87561"},
                       {"label": "Manukan", "value": "C87562"},
                       {"label": "Margos", "value": "C87563"},
                       {"label": "Margosatubig", "value": "C87564"},
                       {"label": "Mercedes", "value": "C87565"},
                       {"label": "Midsalip", "value": "C87566"},
                       {"label": "Molave", "value": "C87567"},
                       {"label": "Monching", "value": "C87568"},
                       {"label": "Muricay", "value": "C87569"},
                       {"label": "Muti", "value": "C87570"},
                       {"label": "Naga", "value": "C87571"},
                       {"label": "Olingan", "value": "C87572"},
                       {"label": "Olutanga", "value": "C87573"},
                       {"label": "Pagadian", "value": "C87574"},
                       {"label": "Palomoc", "value": "C87575"},
                       {"label": "Panubigan", "value": "C87576"},
                       {"label": "Patawag", "value": "C87577"},
                       {"label": "Payao", "value": "C87578"},
                       {"label": "Pitogo", "value": "C87579"},
                       {"label": "Polanco", "value": "C87580"},
                       {"label": "Ponot", "value": "C87581"},
                       {"label": "Province of  Zamboanga del Sur",
                        "value": "C87582"},
                       {"label": "Province of Zamboanga del Norte",
                        "value": "C87583"},
                       {"label": "Province of Zamboanga Sibugay",
                        "value": "C87584"},
                       {"label": "Quinipot", "value": "C87585"},
                       {"label": "Ramon Magsaysay", "value": "C87586"},
                       {"label": "Rancheria Payau", "value": "C87587"},
                       {"label": "Recodo", "value": "C87588"},
                       {"label": "Robonkon", "value": "C87589"},
                       {"label": "Sagacad", "value": "C87590"},
                       {"label": "Salug", "value": "C87591"},
                       {"label": "San Miguel", "value": "C87592"},
                       {"label": "San Pablo", "value": "C87593"},
                       {"label": "Sangali", "value": "C87594"},
                       {"label": "Seres", "value": "C87595"},
                       {"label": "Sergio Osmena Sr", "value": "C87596"},
                       {"label": "Siari", "value": "C87597"},
                       {"label": "Siay", "value": "C87598"},
                       {"label": "Siayan", "value": "C87599"},
                       {"label": "Sibuco", "value": "C87600"},
                       {"label": "Sibulao", "value": "C87601"},
                       {"label": "Sibutao", "value": "C87602"},
                       {"label": "Sindangan", "value": "C87603"},
                       {"label": "Sinubong", "value": "C87604"},
                       {"label": "Siocon", "value": "C87605"},
                       {"label": "Siraway", "value": "C87606"},
                       {"label": "Sumalig", "value": "C87607"},
                       {"label": "Tabina", "value": "C87608"},
                       {"label": "Tagasilay", "value": "C87609"},
                       {"label": "Taguitic", "value": "C87610"},
                       {"label": "Talabaan", "value": "C87611"},
                       {"label": "Talisayan", "value": "C87612"},
                       {"label": "Taluksangay", "value": "C87613"},
                       {"label": "Talusan", "value": "C87614"},
                       {"label": "Tambulig", "value": "C87615"},
                       {"label": "Tampilisan", "value": "C87616"},
                       {"label": "Tawagan", "value": "C87617"},
                       {"label": "Tigbao", "value": "C87618"},
                       {"label": "Tigpalay", "value": "C87619"},
                       {"label": "Tigtabon", "value": "C87620"},
                       {"label": "Tiguha", "value": "C87621"},
                       {"label": "Timonan", "value": "C87622"},
                       {"label": "Tiparak", "value": "C87623"},
                       {"label": "Titay", "value": "C87624"},
                       {"label": "Tolosa", "value": "C87625"},
                       {"label": "Tucuran", "value": "C87626"},
                       {"label": "Tungawan", "value": "C87627"},
                       {"label": "Vitali", "value": "C87628"},
                       {"label": "Zamboanga", "value": "C87629"}],
 "Zamfara": [{"label": "Anka", "value": "C78526"},
             {"label": "Dan Sadau", "value": "C78527"},
             {"label": "Gummi", "value": "C78528"},
             {"label": "Gusau", "value": "C78529"},
             {"label": "Kaura Namoda", "value": "C78530"},
             {"label": "Kwatarkwashi", "value": "C78531"},
             {"label": "Maru", "value": "C78532"},
             {"label": "Moriki", "value": "C78533"},
             {"label": "Sauri", "value": "C78534"},
             {"label": "Tsafe", "value": "C78535"}],
 "Zamora Chinchipe": [{"label": "Yantzaza", "value": "C24205"},
                      {"label": "Zamora", "value": "C24206"}],
 "Zangilan District": [{"label": "Mincivan", "value": "C8537"},
                       {"label": "Zangilan", "value": "C8538"}],
 "Zanjan": [{"label": "Abhar", "value": "C51123"},
            {"label": "Alvand", "value": "C51124"},
            {"label": "Khorramdarreh", "value": "C51125"},
            {"label": "Shahrestan-e ijrud", "value": "C51126"},
            {"label": "Shahrestan-e Khodabandeh", "value": "C51127"},
            {"label": "Shahrestan-e Mahneshan", "value": "C51128"},
            {"label": "Shahrestan-e Tarom", "value": "C51129"},
            {"label": "Shahrestan-e Zanjan", "value": "C51130"},
            {"label": "Soltaniyeh", "value": "C51131"},
            {"label": "Zanjan", "value": "C51132"}],
 "Zanzan Region": [{"label": "Bondoukou", "value": "C21001"},
                   {"label": "Bouna", "value": "C21002"},
                   {"label": "Bounkani", "value": "C21003"},
                   {"label": "Gontougo", "value": "C21004"},
                   {"label": "Sinfra", "value": "C21005"},
                   {"label": "Tanda", "value": "C21006"}],
 "Zanzibar North": [{"label": "Gamba", "value": "C118353"},
                    {"label": "Kijini", "value": "C118354"},
                    {"label": "Kiwengwa", "value": "C118355"},
                    {"label": "Mkokotoni", "value": "C118356"},
                    {"label": "Nungwi", "value": "C118357"}],
 "Zanzibar South": [{"label": "Koani", "value": "C118358"},
                    {"label": "Koani Ndogo", "value": "C118359"},
                    {"label": "Mahonda", "value": "C118360"},
                    {"label": "Nganane", "value": "C118361"},
                    {"label": "Sokoni", "value": "C118362"}],
 "Zanzibar West": [{"label": "Zanzibar", "value": "C118363"}],
 "Zaporizka oblast": [{"label": "Balky", "value": "C122985"},
                      {"label": "Berdiansk Raion", "value": "C122986"},
                      {"label": "Berdyanska Miskrada", "value": "C122987"},
                      {"label": "Berdyansk", "value": "C122988"},
                      {"label": "Chapayevka", "value": "C122989"},
                      {"label": "Chernihivka Raion", "value": "C122990"},
                      {"label": "Dniprorudne", "value": "C122991"},
                      {"label": "Dniprovka", "value": "C122992"},
                      {"label": "Energodar", "value": "C122993"},
                      {"label": "Hulyaypole", "value": "C122994"},
                      {"label": "Kirove", "value": "C122995"},
                      {"label": "Komysh-Zorya", "value": "C122996"},
                      {"label": "Komyshuvakha", "value": "C122997"},
                      {"label": "Kostyantynivka", "value": "C122998"},
                      {"label": "Kushuhum", "value": "C122999"},
                      {"label": "Kuybysheve", "value": "C123000"},
                      {"label": "Kyrylivka", "value": "C123001"},
                      {"label": "Mala Bilozerka", "value": "C123002"},
                      {"label": "Mala Tokmachka", "value": "C123003"},
                      {"label": "Malokaterynivka", "value": "C123004"},
                      {"label": "Matviyivka", "value": "C123005"},
                      {"label": "Melitopol", "value": "C123006"},
                      {"label": "Melitopolskyy Rayon", "value": "C123007"},
                      {"label": "Mykhaylivka", "value": "C123008"},
                      {"label": "Mykhaylivskyy Rayon", "value": "C123009"},
                      {"label": "Novobohdanivka", "value": "C123010"},
                      {"label": "Novomykolayivka", "value": "C123011"},
                      {"label": "Novovasylivka", "value": "C123012"},
                      {"label": "Orikhiv", "value": "C123013"},
                      {"label": "Osypenko", "value": "C123014"},
                      {"label": "Polohy", "value": "C123015"},
                      {"label": "Preobrazhenka", "value": "C123016"},
                      {"label": "Pryazovske Raion", "value": "C123017"},
                      {"label": "Prymorsk", "value": "C123018"},
                      {"label": "Prymorsk Raion", "value": "C123019"},
                      {"label": "Pryshyb", "value": "C123020"},
                      {"label": "Rozivka", "value": "C123021"},
                      {"label": "Rozivskyy Rayon", "value": "C123022"},
                      {"label": "Ternuvate", "value": "C123023"},
                      {"label": "Terpinnya", "value": "C123024"},
                      {"label": "Tokmak", "value": "C123025"},
                      {"label": "Tokmak Raion", "value": "C123026"},
                      {"label": "Tymoshivka", "value": "C123027"},
                      {"label": "Vasylivka", "value": "C123028"},
                      {"label": "Velyka Bilozerka", "value": "C123029"},
                      {"label": "Velykobilozerskyy Rayon", "value": "C123030"},
                      {"label": "Vesele", "value": "C123031"},
                      {"label": "Vilniansk Raion", "value": "C123032"},
                      {"label": "Vodyane", "value": "C123033"},
                      {"label": "Voznesenka", "value": "C123034"},
                      {"label": "Yakymivka", "value": "C123035"},
                      {"label": "Yakymivka Raion", "value": "C123036"},
                      {"label": "Zaliznychne", "value": "C123037"},
                      {"label": "Zaporizka Miskrada", "value": "C123038"},
                      {"label": "Zaporizhia", "value": "C123039"}],
 "Zaqatala District": [{"label": "Aliabad", "value": "C8539"},
                       {"label": "Faldarli", "value": "C8540"},
                       {"label": "Mamrux", "value": "C8541"},
                       {"label": "Qandax", "value": "C8542"},
                       {"label": "Zaqatala", "value": "C8543"}],
 "Zardab District": [{"label": "Zardob", "value": "C8544"}],
 "Zarqa Governorate": [{"label": "Al Azraq ash Shamali", "value": "C64274"},
                       {"label": "Russeifa", "value": "C64275"},
                       {"label": "Zarqa", "value": "C64276"}],
 "Zavkhan Province": [{"label": "Uliastay", "value": "C75593"}],
 "Zavrc Municipality": [{"label": "Zavrc", "value": "C107866"}],
 "Zawiya District": [{"label": "Az Zawiyah", "value": "C65067"},
                     {"label": "Sabratah", "value": "C65068"},
                     {"label": "Surman", "value": "C65069"},
                     {"label": "Zawiya", "value": "C65070"}],
 "Zebbug Gozo": [{"label": "Haz-Zebbug", "value": "C65933"}],
 "Zebbug Malta": [{"label": "Zebbug", "value": "C65934"}],
 "Zeeland": [{"label": "Aagtekerke", "value": "C77667"},
             {"label": "Aardenburg", "value": "C77668"},
             {"label": "Arnemuiden", "value": "C77669"},
             {"label": "Axel", "value": "C77670"},
             {"label": "Borssele", "value": "C77671"},
             {"label": "Breskens", "value": "C77672"},
             {"label": "Brouwershaven", "value": "C77673"},
             {"label": "Bruinisse", "value": "C77674"},
             {"label": "Colijnsplaat", "value": "C77675"},
             {"label": "Dauwendaele", "value": "C77676"},
             {"label": "Domburg", "value": "C77677"},
             {"label": "Goes", "value": "C77678"},
             {"label": "Griffioen", "value": "C77679"},
             {"label": "Grijpskerke", "value": "C77680"},
             {"label": "Haamstede", "value": "C77681"},
             {"label": "Hulst", "value": "C77682"},
             {"label": "IJzendijke", "value": "C77683"},
             {"label": "Kamperland", "value": "C77684"},
             {"label": "Kapelle", "value": "C77685"},
             {"label": "Klarenbeek", "value": "C77686"},
             {"label": "Kloetinge", "value": "C77687"},
             {"label": "Koewacht", "value": "C77688"},
             {"label": "Koudekerke", "value": "C77689"},
             {"label": "Lammerenburg", "value": "C77690"},
             {"label": "Malta", "value": "C77691"},
             {"label": "Meliskerke", "value": "C77692"},
             {"label": "Middelburg", "value": "C77693"},
             {"label": "Nieuw- en Sint Joosland", "value": "C77694"},
             {"label": "Nieuwerkerk", "value": "C77695"},
             {"label": "Oostburg", "value": "C77696"},
             {"label": "Oosterland", "value": "C77697"},
             {"label": "Oostkapelle", "value": "C77698"},
             {"label": "Othene", "value": "C77699"},
             {"label": "Oud-Vossemeer", "value": "C77700"},
             {"label": "Poortvliet", "value": "C77701"},
             {"label": "Renesse", "value": "C77702"},
             {"label": "Sas van Gent", "value": "C77703"},
             {"label": "Scharendijke", "value": "C77704"},
             {"label": "Scherpenisse", "value": "C77705"},
             {"label": "Schoondijke", "value": "C77706"},
             {"label": "Schouwen-Duiveland", "value": "C77707"},
             {"label": "Serooskerke", "value": "C77708"},
             {"label": "Sint Laurens", "value": "C77709"},
             {"label": "Sint Philipsland", "value": "C77710"},
             {"label": "Sluis", "value": "C77711"},
             {"label": "Sluiskil", "value": "C77712"},
             {"label": "Stavenisse", "value": "C77713"},
             {"label": "Terneuzen", "value": "C77714"},
             {"label": "Tholen", "value": "C77715"},
             {"label": "Vlissingen", "value": "C77716"},
             {"label": "Waarde", "value": "C77717"},
             {"label": "Wemeldinge", "value": "C77718"},
             {"label": "West-Souburg", "value": "C77719"},
             {"label": "Westdorpe", "value": "C77720"},
             {"label": "Westerzicht", "value": "C77721"},
             {"label": "Westkapelle", "value": "C77722"},
             {"label": "Wissenkerke", "value": "C77723"},
             {"label": "Wolphaartsdijk", "value": "C77724"},
             {"label": "Yerseke", "value": "C77725"},
             {"label": "Zaamslag", "value": "C77726"},
             {"label": "Zierikzee", "value": "C77727"},
             {"label": "Zoutelande", "value": "C77728"}],
 "Zejtun": [{"label": "Zejtun", "value": "C65935"}],
 "Zelenikovo Municipality": [{"label": "Zelenikovo", "value": "C65545"}],
 "Zelezniki Municipality": [{"label": "Zelezniki", "value": "C107867"}],
 "Zelino Municipality": [{"label": "Dobarce", "value": "C65546"},
                         {"label": "Larce", "value": "C65547"},
                         {"label": "Sedlarevo", "value": "C65548"},
                         {"label": "Strimnica", "value": "C65549"},
                         {"label": "Zelino", "value": "C65550"}],
 "Zetale Municipality": [{"label": "Zetale", "value": "C107868"}],
 "Zhejiang": [{"label": "Deqing", "value": "C19494"},
              {"label": "Dongyang", "value": "C19495"},
              {"label": "Fenghua", "value": "C19496"},
              {"label": "Fuyang", "value": "C19497"},
              {"label": "Guli", "value": "C19498"},
              {"label": "Haining", "value": "C19499"},
              {"label": "Hangzhou", "value": "C19500"},
              {"label": "Huangyan", "value": "C19501"},
              {"label": "Huzhou", "value": "C19502"},
              {"label": "Jiaojiang", "value": "C19503"},
              {"label": "Jiashan", "value": "C19504"},
              {"label": "Jiaxing", "value": "C19505"},
              {"label": "Jiaxing Shi", "value": "C19506"},
              {"label": "Jinhua", "value": "C19507"},
              {"label": "Jinxiang", "value": "C19508"},
              {"label": "Kunyang", "value": "C19509"},
              {"label": "Lanxi", "value": "C19510"},
              {"label": "Lianghu", "value": "C19511"},
              {"label": "Linhai", "value": "C19512"},
              {"label": "Linping", "value": "C19513"},
              {"label": "Lishui", "value": "C19514"},
              {"label": "Luqiao", "value": "C19515"},
              {"label": "Ningbo", "value": "C19516"},
              {"label": "Ninghai", "value": "C19517"},
              {"label": "Puyang", "value": "C19518"},
              {"label": "Quzhou", "value": "C19519"},
              {"label": "Shangyu", "value": "C19520"},
              {"label": "Shaoxing", "value": "C19521"},
              {"label": "Shenjiamen", "value": "C19522"},
              {"label": "Taizhou", "value": "C19523"},
              {"label": "Wenling", "value": "C19524"},
              {"label": "Wenzhou", "value": "C19525"},
              {"label": "Wuzhen", "value": "C19526"},
              {"label": "Xianju", "value": "C19527"},
              {"label": "Xiaoshan", "value": "C19528"},
              {"label": "Yiwu", "value": "C19529"},
              {"label": "Yuyao", "value": "C19530"},
              {"label": "Zhaobaoshan", "value": "C19531"},
              {"label": "Zhicheng", "value": "C19532"},
              {"label": "Zhoushan", "value": "C19533"},
              {"label": "Zhuji", "value": "C19534"}],
 "Zhemgang District": [{"label": "Panbang", "value": "C9652"},
                       {"label": "Shemgang", "value": "C9653"}],
 "Zhytomyrska oblast": [{"label": "Andrushivka", "value": "C123040"},
                        {"label": "Andrushivskyy Rayon", "value": "C123041"},
                        {"label": "Baranivka", "value": "C123042"},
                        {"label": "Berdychiv", "value": "C123043"},
                        {"label": "Berdychivskyy Rayon", "value": "C123044"},
                        {"label": "Bila Krynytsya", "value": "C123045"},
                        {"label": "Brusyliv", "value": "C123046"},
                        {"label": "Brusylivskyy Rayon", "value": "C123047"},
                        {"label": "Bykivka", "value": "C123048"},
                        {"label": "Cherniakhiv", "value": "C123049"},
                        {"label": "Chernyakhivskyy Rayon", "value": "C123050"},
                        {"label": "Chervone", "value": "C123051"},
                        {"label": "Chopovychi", "value": "C123052"},
                        {"label": "Chornorudka", "value": "C123053"},
                        {"label": "Chudniv", "value": "C123054"},
                        {"label": "Chudniv Raion", "value": "C123055"},
                        {"label": "Denyshi", "value": "C123056"},
                        {"label": "Dovbysh", "value": "C123057"},
                        {"label": "Holovyne", "value": "C123058"},
                        {"label": "Horodnytsya", "value": "C123059"},
                        {"label": "Horodok", "value": "C123060"},
                        {"label": "Hryshkivtsi", "value": "C123061"},
                        {"label": "Khoroshiv Raion", "value": "C123062"},
                        {"label": "Kornyn", "value": "C123063"},
                        {"label": "Korosten", "value": "C123064"},
                        {"label": "Korostyshiv", "value": "C123065"},
                        {"label": "Korostyshiv Raion", "value": "C123066"},
                        {"label": "Liubar", "value": "C123067"},
                        {"label": "Luhyny", "value": "C123068"},
                        {"label": "Luhyny Raion", "value": "C123069"},
                        {"label": "Malyn", "value": "C123070"},
                        {"label": "Malyn Raion", "value": "C123071"},
                        {"label": "Narodychi", "value": "C123072"},
                        {"label": "Narodychi Raion", "value": "C123073"},
                        {"label": "Nova Borova", "value": "C123074"},
                        {"label": "Novi Bilokorovychi", "value": "C123075"},
                        {"label": "Novohrad-Volynskyi", "value": "C123076"},
                        {"label": "Olevsk", "value": "C123077"},
                        {"label": "Ovruch", "value": "C123078"},
                        {"label": "Ovruch Raion", "value": "C123079"},
                        {"label": "Ozerne", "value": "C123080"},
                        {"label": "Pershotravneve", "value": "C123081"},
                        {"label": "Polyanka", "value": "C123082"},
                        {"label": "Popilnia", "value": "C123083"},
                        {"label": "Popilnia Raion", "value": "C123084"},
                        {"label": "Pulyny Raion", "value": "C123085"},
                        {"label": "Radomyshl", "value": "C123086"},
                        {"label": "Radomyshl Raion", "value": "C123087"},
                        {"label": "Romaniv", "value": "C123088"},
                        {"label": "Ruzhyn", "value": "C123089"},
                        {"label": "Stavyshche", "value": "C123090"},
                        {"label": "Topory", "value": "C123091"},
                        {"label": "Yemilchyne", "value": "C123092"},
                        {"label": "Zhytomyr", "value": "C123093"},
                        {"label": "Zhytomyr Raion", "value": "C123094"}],
 "Ziguinchor": [{"label": "Adeane", "value": "C106890"},
                {"label": "Bignona", "value": "C106891"},
                {"label": "Oussouye", "value": "C106892"},
                {"label": "Tionk Essil", "value": "C106893"},
                {"label": "Ziguinchor", "value": "C106894"}],
 "Zilina Region": [{"label": "Bytca", "value": "C107527"},
                   {"label": "Cadca", "value": "C107528"},
                   {"label": "Dolny Kubin", "value": "C107529"},
                   {"label": "Hybe", "value": "C107530"},
                   {"label": "Krasnany", "value": "C107531"},
                   {"label": "Kysucke Nove Mesto", "value": "C107532"},
                   {"label": "Liptovsky Hradok", "value": "C107533"},
                   {"label": "Liptovsky Mikulas", "value": "C107534"},
                   {"label": "Lucky", "value": "C107535"},
                   {"label": "Martin", "value": "C107536"},
                   {"label": "Namestovo", "value": "C107537"},
                   {"label": "Nizna", "value": "C107538"},
                   {"label": "Okres Bytca", "value": "C107539"},
                   {"label": "Okres Cadca", "value": "C107540"},
                   {"label": "Okres Dolny Kubin", "value": "C107541"},
                   {"label": "Okres Kysucke Nove Mesto", "value": "C107542"},
                   {"label": "Okres Liptovsky Mikulas", "value": "C107543"},
                   {"label": "Okres Martin", "value": "C107544"},
                   {"label": "Okres Namestovo", "value": "C107545"},
                   {"label": "Okres Ruzomberok", "value": "C107546"},
                   {"label": "Okres Turcianske Teplice", "value": "C107547"},
                   {"label": "Okres Tvrdosin", "value": "C107548"},
                   {"label": "Okres Zilina", "value": "C107549"},
                   {"label": "Oravska Lesna", "value": "C107550"},
                   {"label": "Oravsky Podzamok", "value": "C107551"},
                   {"label": "Pribylina", "value": "C107552"},
                   {"label": "Rajec", "value": "C107553"},
                   {"label": "Ruzomberok", "value": "C107554"},
                   {"label": "Terchova", "value": "C107555"},
                   {"label": "Trstena", "value": "C107556"},
                   {"label": "Turcianske Teplice", "value": "C107557"},
                   {"label": "Turzovka", "value": "C107558"},
                   {"label": "Tvrdosin", "value": "C107559"},
                   {"label": "Vrutky", "value": "C107560"},
                   {"label": "Zilina", "value": "C107561"}],
 "Zilupe Municipality": [{"label": "Zilupe", "value": "C64963"}],
 "Zinder Region": [{"label": "Departement de Goure", "value": "C78103"},
                   {"label": "Departement de Kantche", "value": "C78104"},
                   {"label": "Departement de Tanout", "value": "C78105"},
                   {"label": "Goure", "value": "C78106"},
                   {"label": "Magaria", "value": "C78107"},
                   {"label": "Matamey", "value": "C78108"},
                   {"label": "Mirriah", "value": "C78109"},
                   {"label": "Tanout", "value": "C78110"},
                   {"label": "Zinder", "value": "C78111"}],
 "Ziri Municipality": [{"label": "Ziri", "value": "C107869"}],
 "Zirovnica Municipality": [{"label": "Zirovnica", "value": "C107870"}],
 "Zlin": [{"label": "Babice", "value": "C23209"},
          {"label": "Banov", "value": "C23210"},
          {"label": "Bilovice", "value": "C23211"},
          {"label": "Bojkovice", "value": "C23212"},
          {"label": "Borsice", "value": "C23213"},
          {"label": "Breznice", "value": "C23214"},
          {"label": "Brezolupy", "value": "C23215"},
          {"label": "Brezova", "value": "C23216"},
          {"label": "Buchlovice", "value": "C23217"},
          {"label": "Bystrice pod Hostynem", "value": "C23218"},
          {"label": "Chropyne", "value": "C23219"},
          {"label": "Chvalcov", "value": "C23220"},
          {"label": "Dolni Becva", "value": "C23221"},
          {"label": "Dolni Nemci", "value": "C23222"},
          {"label": "Francova Lhota", "value": "C23223"},
          {"label": "Frystak", "value": "C23224"},
          {"label": "Halenkov", "value": "C23225"},
          {"label": "Halenkovice", "value": "C23226"},
          {"label": "Hluk", "value": "C23227"},
          {"label": "Holesov", "value": "C23228"},
          {"label": "Horni Becva", "value": "C23229"},
          {"label": "Horni Lidec", "value": "C23230"},
          {"label": "Hostalkova", "value": "C23231"},
          {"label": "Hovezi", "value": "C23232"},
          {"label": "Hulin", "value": "C23233"},
          {"label": "Hvozdna", "value": "C23234"},
          {"label": "Jablunka", "value": "C23235"},
          {"label": "Jalubi", "value": "C23236"},
          {"label": "Karolinka", "value": "C23237"},
          {"label": "Kelc", "value": "C23238"},
          {"label": "Knezpole", "value": "C23239"},
          {"label": "Korycany", "value": "C23240"},
          {"label": "Kromeriz", "value": "C23241"},
          {"label": "Kunovice", "value": "C23242"},
          {"label": "Kvasice", "value": "C23243"},
          {"label": "Lesna", "value": "C23244"},
          {"label": "Lidecko", "value": "C23245"},
          {"label": "Liptal", "value": "C23246"},
          {"label": "Luhacovice", "value": "C23247"},
          {"label": "Lukov", "value": "C23248"},
          {"label": "Mistrice", "value": "C23249"},
          {"label": "Napajedla", "value": "C23250"},
          {"label": "Nedakonice", "value": "C23251"},
          {"label": "Nedasov", "value": "C23252"},
          {"label": "Nivnice", "value": "C23253"},
          {"label": "Novy Hrozenkov", "value": "C23254"},
          {"label": "Okres Kromeriz", "value": "C23255"},
          {"label": "Okres Uherske Hradiste", "value": "C23256"},
          {"label": "Okres Vsetin", "value": "C23257"},
          {"label": "Okres Zlin", "value": "C23258"},
          {"label": "Ostrozska Lhota", "value": "C23259"},
          {"label": "Ostrozska Nova Ves", "value": "C23260"},
          {"label": "Otrokovice", "value": "C23261"},
          {"label": "Polesovice", "value": "C23262"},
          {"label": "Popovice", "value": "C23263"},
          {"label": "Pozlovice", "value": "C23264"},
          {"label": "Prostredni Becva", "value": "C23265"},
          {"label": "Prusinovice", "value": "C23266"},
          {"label": "Rataje", "value": "C23267"},
          {"label": "Ratibor", "value": "C23268"},
          {"label": "Roznov pod Radhostem", "value": "C23269"},
          {"label": "Slavicin", "value": "C23270"},
          {"label": "Slusovice", "value": "C23271"},
          {"label": "Spytihnev", "value": "C23272"},
          {"label": "Stare Mesto", "value": "C23273"},
          {"label": "Stitna nad Vlari", "value": "C23274"},
          {"label": "Strani", "value": "C23275"},
          {"label": "Sucha Loz", "value": "C23276"},
          {"label": "Sumice", "value": "C23277"},
          {"label": "Tecovice", "value": "C23278"},
          {"label": "Tlumacov", "value": "C23279"},
          {"label": "Topolna", "value": "C23280"},
          {"label": "Traplice", "value": "C23281"},
          {"label": "Trnava", "value": "C23282"},
          {"label": "Tupesy", "value": "C23283"},
          {"label": "Uherske Hradiste", "value": "C23284"},
          {"label": "Uhersky Brod", "value": "C23285"},
          {"label": "Uhersky Ostroh", "value": "C23286"},
          {"label": "Ujezd", "value": "C23287"},
          {"label": "Valasska Bystrice", "value": "C23288"},
          {"label": "Valasska Polanka", "value": "C23289"},
          {"label": "Valasske Klobouky", "value": "C23290"},
          {"label": "Valasske Mezirici", "value": "C23291"},
          {"label": "Velehrad", "value": "C23292"},
          {"label": "Velke Karlovice", "value": "C23293"},
          {"label": "Vidce", "value": "C23294"},
          {"label": "Vizovice", "value": "C23295"},
          {"label": "Vlachovice", "value": "C23296"},
          {"label": "Vlcnov", "value": "C23297"},
          {"label": "Vsemina", "value": "C23298"},
          {"label": "Vsetin", "value": "C23299"},
          {"label": "Zahorovice", "value": "C23300"},
          {"label": "Zasova", "value": "C23301"},
          {"label": "Zborovice", "value": "C23302"},
          {"label": "Zdounky", "value": "C23303"},
          {"label": "Zlechov", "value": "C23304"},
          {"label": "Zlin", "value": "C23305"},
          {"label": "Zlutava", "value": "C23306"},
          {"label": "Zubri", "value": "C23307"}],
 "Zonguldak": [{"label": "Alapli", "value": "C121153"},
               {"label": "Caycuma", "value": "C121154"},
               {"label": "Devrek", "value": "C121155"},
               {"label": "Eregli", "value": "C121156"},
               {"label": "Gokcebey Ilcesi", "value": "C121157"},
               {"label": "Kilimli", "value": "C121158"},
               {"label": "Kozlu", "value": "C121159"},
               {"label": "Zonguldak", "value": "C121160"}],
 "Zou Department": [{"label": "Abomey", "value": "C9626"},
                    {"label": "Bohicon", "value": "C9627"},
                    {"label": "Commune of Agbangnizoun", "value": "C9628"},
                    {"label": "Cove", "value": "C9629"}],
 "Zrece Municipality": [{"label": "Zrece", "value": "C107871"}],
 "Zrnovci Municipality": [{"label": "Zrnovci", "value": "C65551"}],
 "Zug": [{"label": "Baar", "value": "C117430"},
         {"label": "Cham", "value": "C117431"},
         {"label": "Hunenberg", "value": "C117432"},
         {"label": "Menzingen", "value": "C117433"},
         {"label": "Rotkreuz", "value": "C117434"},
         {"label": "Steinhausen", "value": "C117435"},
         {"label": "Unterageri", "value": "C117436"},
         {"label": "Walchwil", "value": "C117437"},
         {"label": "Zug", "value": "C117438"}],
 "Zulia": [{"label": "Cabimas", "value": "C147250"},
           {"label": "Ciudad Ojeda", "value": "C147251"},
           {"label": "La Villa del Rosario", "value": "C147252"},
           {"label": "Lagunillas", "value": "C147253"},
           {"label": "Machiques", "value": "C147254"},
           {"label": "Maracaibo", "value": "C147255"},
           {"label": "San Carlos del Zulia", "value": "C147256"},
           {"label": "Santa Rita", "value": "C147257"}],
 "Zurich": [{"label": "Adliswil", "value": "C117439"},
            {"label": "Adliswil  Adliswil (Stadtkern)", "value": "C117440"},
            {"label": "Adliswil  Hundli-Zopf", "value": "C117441"},
            {"label": "Adliswil  Oberleimbach", "value": "C117442"},
            {"label": "Adliswil  Sonnenberg", "value": "C117443"},
            {"label": "Adliswil  Sood", "value": "C117444"},
            {"label": "Adliswil  Tal", "value": "C117445"},
            {"label": "Aesch", "value": "C117446"},
            {"label": "Affoltern  Hasenbuel", "value": "C117447"},
            {"label": "Affoltern  Oberdorf", "value": "C117448"},
            {"label": "Affoltern  Sonnenberg", "value": "C117449"},
            {"label": "Affoltern  Unterdorf", "value": "C117450"},
            {"label": "Affoltern am Albis", "value": "C117451"},
            {"label": "Andelfingen", "value": "C117452"},
            {"label": "Au", "value": "C117453"},
            {"label": "Au  Mittel-Dorf", "value": "C117454"},
            {"label": "Au  Unter-Dorf", "value": "C117455"},
            {"label": "Bachenbulach", "value": "C117456"},
            {"label": "Baretswil", "value": "C117457"},
            {"label": "Bassersdorf", "value": "C117458"},
            {"label": "Bauma", "value": "C117459"},
            {"label": "Benglen", "value": "C117460"},
            {"label": "Bezirk Affoltern", "value": "C117461"},
            {"label": "Bezirk Andelfingen", "value": "C117462"},
            {"label": "Bezirk Bulach", "value": "C117463"},
            {"label": "Bezirk Dielsdorf", "value": "C117464"},
            {"label": "Bezirk Dietikon", "value": "C117465"},
            {"label": "Bezirk Hinwil", "value": "C117466"},
            {"label": "Bezirk Horgen", "value": "C117467"},
            {"label": "Bezirk Meilen", "value": "C117468"},
            {"label": "Bezirk Pfaffikon", "value": "C117469"},
            {"label": "Bezirk Uster", "value": "C117470"},
            {"label": "Bezirk Winterthur", "value": "C117471"},
            {"label": "Bezirk Zurich", "value": "C117472"},
            {"label": "Binz", "value": "C117473"},
            {"label": "Binzikon", "value": "C117474"},
            {"label": "Birchwil", "value": "C117475"},
            {"label": "Birmensdorf", "value": "C117476"},
            {"label": "Bonstetten", "value": "C117477"},
            {"label": "Brutten", "value": "C117478"},
            {"label": "Bruttisellen", "value": "C117479"},
            {"label": "Bubikon", "value": "C117480"},
            {"label": "Bulach", "value": "C117481"},
            {"label": "Bulach  Gstuckt", "value": "C117482"},
            {"label": "Bulach  Seematt", "value": "C117483"},
            {"label": "Bulach  Soliganter", "value": "C117484"},
            {"label": "Dachsen", "value": "C117485"},
            {"label": "Dallikon  Dallikon (Dorf)", "value": "C117486"},
            {"label": "Danikon", "value": "C117487"},
            {"label": "Dielsdorf", "value": "C117488"},
            {"label": "Dietikon", "value": "C117489"},
            {"label": "Dietikon  Almend", "value": "C117490"},
            {"label": "Dietikon  Guggenbuhl", "value": "C117491"},
            {"label": "Dietikon  Hofacker", "value": "C117492"},
            {"label": "Dietikon  Kreuzacker", "value": "C117493"},
            {"label": "Dietikon  Oberdorf", "value": "C117494"},
            {"label": "Dietikon  Schonenwerd", "value": "C117495"},
            {"label": "Dietikon  Vorstadt", "value": "C117496"},
            {"label": "Dietlikon  Dietlikon (Dorf)", "value": "C117497"},
            {"label": "Dietlikon  Eichwiesen", "value": "C117498"},
            {"label": "Dorf", "value": "C117499"},
            {"label": "Dubendorf", "value": "C117500"},
            {"label": "Dubendorf  Kunklerstrasse", "value": "C117501"},
            {"label": "Dubendorf  Sonnenberg", "value": "C117502"},
            {"label": "Dubendorf  Vogelquartier", "value": "C117503"},
            {"label": "Dubendorf  Wasserfurren", "value": "C117504"},
            {"label": "Durnten", "value": "C117505"},
            {"label": "Ebmatingen", "value": "C117506"},
            {"label": "Effretikon", "value": "C117507"},
            {"label": "Effretikon  Rappenhalde-Bannhalde", "value": "C117508"},
            {"label": "Effretikon  Rikon", "value": "C117509"},
            {"label": "Effretikon  Watt", "value": "C117510"},
            {"label": "Egg", "value": "C117511"},
            {"label": "Elgg", "value": "C117512"},
            {"label": "Elgg  Stadtchen und Umgebung", "value": "C117513"},
            {"label": "Elsau-Raterschen", "value": "C117514"},
            {"label": "Elsau-Raterschen  Raterschen", "value": "C117515"},
            {"label": "Embrach", "value": "C117516"},
            {"label": "Embrach  Embrach (Dorfkern)", "value": "C117517"},
            {"label": "Embrach  Kellersacker", "value": "C117518"},
            {"label": "Erlenbach", "value": "C117519"},
            {"label": "Erlenbach  links des Dorfbachs oberhalb Bahnlinie",
             "value": "C117520"},
            {"label": "Erlenbach  rechts des Dorfbachs oberhalb Bahnlinie",
             "value": "C117521"},
            {"label": "Esslingen", "value": "C117522"},
            {"label": "Fallanden", "value": "C117523"},
            {"label": "Fehraltorf", "value": "C117524"},
            {"label": "Feldmeilen", "value": "C117525"},
            {"label": "Feuerthalen", "value": "C117526"},
            {"label": "Flurlingen", "value": "C117527"},
            {"label": "Freienstein", "value": "C117528"},
            {"label": "Gattikon", "value": "C117529"},
            {"label": "Geroldswil", "value": "C117530"},
            {"label": "Glattbrugg", "value": "C117531"},
            {"label": "Glattbrugg  RohrPlatten-Balsberg", "value": "C117532"},
            {"label": "Glattbrugg  WydackerBettackerLattenwiesen",
             "value": "C117533"},
            {"label": "Glattfelden", "value": "C117534"},
            {"label": "Gockhausen", "value": "C117535"},
            {"label": "Gossau", "value": "C117536"},
            {"label": "Greifensee", "value": "C117537"},
            {"label": "Greifensee  Mullerwis  Seilerwis", "value": "C117538"},
            {"label": "Greifensee  Pfisterholzli", "value": "C117539"},
            {"label": "GrossackerOpfikon", "value": "C117540"},
            {"label": "Grut", "value": "C117541"},
            {"label": "Gutenswil", "value": "C117542"},
            {"label": "Hadlikon", "value": "C117543"},
            {"label": "Hausen am Albis  Hausen (Dorf)", "value": "C117544"},
            {"label": "Hedingen", "value": "C117545"},
            {"label": "Hegnau", "value": "C117546"},
            {"label": "Hegnau  Dammboden-Grindel", "value": "C117547"},
            {"label": "Hegnau  Sunnebuel-Eich", "value": "C117548"},
            {"label": "Henggart", "value": "C117549"},
            {"label": "Herrliberg", "value": "C117550"},
            {"label": "Hettlingen", "value": "C117551"},
            {"label": "Hinteregg", "value": "C117552"},
            {"label": "Hinwil", "value": "C117553"},
            {"label": "Hittnau  Hittnau (Dorf)", "value": "C117554"},
            {"label": "Hochfelden", "value": "C117555"},
            {"label": "Hofstetten", "value": "C117556"},
            {"label": "Hombrechtikon", "value": "C117557"},
            {"label": "Horgen", "value": "C117558"},
            {"label": "Horgen  Allmend", "value": "C117559"},
            {"label": "Horgen  Horgen (Dorfkern)", "value": "C117560"},
            {"label": "Horgen  Oberdorf", "value": "C117561"},
            {"label": "Horgen  Scheller-Stockerstrasse", "value": "C117562"},
            {"label": "Hori", "value": "C117563"},
            {"label": "Illnau", "value": "C117564"},
            {"label": "Illnau  Unter-Illnau", "value": "C117565"},
            {"label": "Kilchberg", "value": "C117566"},
            {"label": "Kilchberg  Bachler-Stocken", "value": "C117567"},
            {"label": "Kilchberg  Kilchberg (Dorfkern)", "value": "C117568"},
            {"label": "Kindhausen  Kindhausen (Dorf)", "value": "C117569"},
            {"label": "Kleinandelfingen", "value": "C117570"},
            {"label": "Kloten", "value": "C117571"},
            {"label": "Kloten  Balsberg", "value": "C117572"},
            {"label": "Kloten  Freienberg (Chanzler-Chlini Chaseren)",
             "value": "C117573"},
            {"label": "Kloten  Geissberg", "value": "C117574"},
            {"label": "Kloten  Holberg", "value": "C117575"},
            {"label": "Kloten  Horainli", "value": "C117576"},
            {"label": "Kloten  Hostrass", "value": "C117577"},
            {"label": "Kloten  Kloten (Zentrum)", "value": "C117578"},
            {"label": "Kloten  Rutlen", "value": "C117579"},
            {"label": "Kloten  Spitz", "value": "C117580"},
            {"label": "Knonau", "value": "C117581"},
            {"label": "Kollbrunn", "value": "C117582"},
            {"label": "Kollbrunn  Kollbrunn (Dorfkern)", "value": "C117583"},
            {"label": "Kusnacht", "value": "C117584"},
            {"label": "Kusnacht  Dorf", "value": "C117585"},
            {"label": "Kusnacht  Goldbach", "value": "C117586"},
            {"label": "Kusnacht  Heslibach", "value": "C117587"},
            {"label": "Kusnacht  Itschnach", "value": "C117588"},
            {"label": "Kusnacht  Schiedhalden", "value": "C117589"},
            {"label": "Langnau  Langnau (Dorf)", "value": "C117590"},
            {"label": "Langnau  Vitaquartier", "value": "C117591"},
            {"label": "Langnau am Albis", "value": "C117592"},
            {"label": "Mannedorf", "value": "C117593"},
            {"label": "Mannedorf  Ausserfeld", "value": "C117594"},
            {"label": "Mannedorf  Dorfkern", "value": "C117595"},
            {"label": "Marthalen", "value": "C117596"},
            {"label": "Mattenbach (Kreis 7)", "value": "C117597"},
            {"label": "Mattenbach (Kreis 7)  Deutweg", "value": "C117598"},
            {"label": "Mattenbach (Kreis 7)  Endliker", "value": "C117599"},
            {"label": "Mattenbach (Kreis 7)  Gutschick", "value": "C117600"},
            {"label": "Meilen", "value": "C117601"},
            {"label": "Mettmenstetten", "value": "C117602"},
            {"label": "Monchaltorf", "value": "C117603"},
            {"label": "Monchaltorf  Dorf", "value": "C117604"},
            {"label": "Muhlehalde", "value": "C117605"},
            {"label": "Nanikon", "value": "C117606"},
            {"label": "Nanikon  Nanikon (Dorfkern)", "value": "C117607"},
            {"label": "Neerach", "value": "C117608"},
            {"label": "Neftenbach  Dorf Neftenbach", "value": "C117609"},
            {"label": "Niederglatt", "value": "C117610"},
            {"label": "Niederglatt  Niederglatt (Dorfkern)",
             "value": "C117611"},
            {"label": "Niederhasli", "value": "C117612"},
            {"label": "Nurensdorf", "value": "C117613"},
            {"label": "Oberengstringen", "value": "C117614"},
            {"label": "Oberengstringen  Rauchacher", "value": "C117615"},
            {"label": "Oberengstringen  Sonnenberg", "value": "C117616"},
            {"label": "Oberengstringen  Zentrum", "value": "C117617"},
            {"label": "Oberglatt", "value": "C117618"},
            {"label": "Oberglatt  Bahnhofquartier", "value": "C117619"},
            {"label": "Oberglatt  Oberglatt (Dorfkern)", "value": "C117620"},
            {"label": "Obermeilen", "value": "C117621"},
            {"label": "Oberrieden", "value": "C117622"},
            {"label": "Oberrieden  Berg", "value": "C117623"},
            {"label": "Oberrieden  Mitte", "value": "C117624"},
            {"label": "Oberwinterthur (Kreis 2)", "value": "C117625"},
            {"label": "Oberwinterthur (Kreis 2)  Guggenbuhl",
             "value": "C117626"},
            {"label": "Oberwinterthur (Kreis 2)  Hegi", "value": "C117627"},
            {"label": "Oberwinterthur (Kreis 2)  Talacker", "value": "C117628"},
            {"label": "Oberwinterthur (Kreis 2)  Zinzikon", "value": "C117629"},
            {"label": "Obfelden", "value": "C117630"},
            {"label": "Obfelden  Oberlunnern", "value": "C117631"},
            {"label": "Obfelden  Toussen", "value": "C117632"},
            {"label": "Oetwil", "value": "C117633"},
            {"label": "Opfikon", "value": "C117634"},
            {"label": "Otelfingen", "value": "C117635"},
            {"label": "Ottenbach", "value": "C117636"},
            {"label": "Pfaffhausen", "value": "C117637"},
            {"label": "Pfaffikon", "value": "C117638"},
            {"label": "Pfaffikon  Irgenhausen", "value": "C117639"},
            {"label": "Pfaffikon  Pfaffikon (Dorfkern)", "value": "C117640"},
            {"label": "Pfungen", "value": "C117641"},
            {"label": "Rafz", "value": "C117642"},
            {"label": "Regensdorf", "value": "C117643"},
            {"label": "Regensdorf  Feldblumen-Riedthofstrasse",
             "value": "C117644"},
            {"label": "Regensdorf  Hofacher-Geeren", "value": "C117645"},
            {"label": "Regensdorf  Obstgarten", "value": "C117646"},
            {"label": "Richterswil", "value": "C117647"},
            {"label": "Richterswil  Burghalde", "value": "C117648"},
            {"label": "Richterswil  Dorfkern", "value": "C117649"},
            {"label": "Richterswil  Richterswil (Dorfkern)",
             "value": "C117650"},
            {"label": "Rorbas", "value": "C117651"},
            {"label": "Rumlang", "value": "C117652"},
            {"label": "Rumlang  Rumlang (Dorfkern)", "value": "C117653"},
            {"label": "Ruschlikon", "value": "C117654"},
            {"label": "Russikon", "value": "C117655"},
            {"label": "Ruti", "value": "C117656"},
            {"label": "Ruti  Dorfzentrum, Sudl. Teil", "value": "C117657"},
            {"label": "Ruti  Oberdorf", "value": "C117658"},
            {"label": "Ruti  Westlicher Dorfteil", "value": "C117659"},
            {"label": "Samstagern", "value": "C117660"},
            {"label": "Schachen", "value": "C117661"},
            {"label": "Schlieren", "value": "C117662"},
            {"label": "Schlieren  Boden", "value": "C117663"},
            {"label": "Schlieren  Engstingerquartier", "value": "C117664"},
            {"label": "Schlieren  Freiestrasse", "value": "C117665"},
            {"label": "Schlieren  Kamp", "value": "C117666"},
            {"label": "Schlieren  Spital", "value": "C117667"},
            {"label": "Schlieren  Zentrum", "value": "C117668"},
            {"label": "Schwerzenbach", "value": "C117669"},
            {"label": "Schwerzenbach  Blatten", "value": "C117670"},
            {"label": "Schwerzenbach  Chimli", "value": "C117671"},
            {"label": "Seen (Kreis 3)", "value": "C117672"},
            {"label": "Seen (Kreis 3)  Buelwiesen", "value": "C117673"},
            {"label": "Seen (Kreis 3)  Ganzenbuhl", "value": "C117674"},
            {"label": "Seen (Kreis 3)  Oberseen", "value": "C117675"},
            {"label": "Seen (Kreis 3)  Waldegg", "value": "C117676"},
            {"label": "Seen (Kreis 3)  Waser", "value": "C117677"},
            {"label": "Seglingen", "value": "C117678"},
            {"label": "Sellenburen", "value": "C117679"},
            {"label": "Seuzach  Seuzach (Dorf)", "value": "C117680"},
            {"label": "Seuzach Dorf", "value": "C117681"},
            {"label": "Seuzach Dorf  Breite-Weid", "value": "C117682"},
            {"label": "Sonnhalde", "value": "C117683"},
            {"label": "Stadt Winterthur (Kreis 1)", "value": "C117684"},
            {"label": "Stadt Winterthur (Kreis 1)  Altstadt",
             "value": "C117685"},
            {"label": "Stadt Winterthur (Kreis 1)  Bruhlberg",
             "value": "C117686"},
            {"label": "Stadt Winterthur (Kreis 1)  Heiligberg",
             "value": "C117687"},
            {"label": "Stadt Winterthur (Kreis 1)  Lind", "value": "C117688"},
            {"label": "Stadt Winterthur (Kreis 1)  Neuwiesen",
             "value": "C117689"},
            {"label": "Stadt Winterthur (Kreis 1)  Tossfeld",
             "value": "C117690"},
            {"label": "Stafa", "value": "C117691"},
            {"label": "Steinmaur", "value": "C117692"},
            {"label": "Sulz", "value": "C117693"},
            {"label": "Tagelswangen", "value": "C117694"},
            {"label": "Tann", "value": "C117695"},
            {"label": "Tann  Tann (Dorfkern)", "value": "C117696"},
            {"label": "Thalwil", "value": "C117697"},
            {"label": "Thalwil  Berg", "value": "C117698"},
            {"label": "Thalwil  Dorfkern", "value": "C117699"},
            {"label": "Thalwil  Nord", "value": "C117700"},
            {"label": "Thalwil  See", "value": "C117701"},
            {"label": "Thalwil  Sud", "value": "C117702"},
            {"label": "Toss (Kreis 4)", "value": "C117703"},
            {"label": "Toss (Kreis 4)  Eichliacker", "value": "C117704"},
            {"label": "Toss (Kreis 4)  Schlosstal", "value": "C117705"},
            {"label": "Toss (Kreis 4)  Vorder-Dattnau", "value": "C117706"},
            {"label": "Turbenthal", "value": "C117707"},
            {"label": "Uerikon", "value": "C117708"},
            {"label": "Uetikon", "value": "C117709"},
            {"label": "Uetikon  Grossdorf", "value": "C117710"},
            {"label": "Uhwiesen", "value": "C117711"},
            {"label": "Uitikon", "value": "C117712"},
            {"label": "Unterengstringen", "value": "C117713"},
            {"label": "Urdorf", "value": "C117714"},
            {"label": "Urdorf  Bodenfeld", "value": "C117715"},
            {"label": "Urdorf  Moos", "value": "C117716"},
            {"label": "Urdorf  Oberurdorf", "value": "C117717"},
            {"label": "Uster", "value": "C117718"},
            {"label": "Uster  Gschwader", "value": "C117719"},
            {"label": "Uster  Kirch-Uster", "value": "C117720"},
            {"label": "Uster  Nieder-Uster", "value": "C117721"},
            {"label": "Uster  Nossikon", "value": "C117722"},
            {"label": "Uster  Ober-Uster", "value": "C117723"},
            {"label": "Veltheim (Kreis 5)", "value": "C117724"},
            {"label": "Veltheim (Kreis 5)  Blumenau", "value": "C117725"},
            {"label": "Veltheim (Kreis 5)  Rosenberg", "value": "C117726"},
            {"label": "Volketswil  Volketswil (Dorf)", "value": "C117727"},
            {"label": "Wadenswil", "value": "C117728"},
            {"label": "Wadenswil  Boller-Giessen", "value": "C117729"},
            {"label": "Wadenswil  Buelen", "value": "C117730"},
            {"label": "Wadenswil  Dorf (Wadenswil)", "value": "C117731"},
            {"label": "Wadenswil  Eichweid", "value": "C117732"},
            {"label": "Wadenswil  Hangenmoos", "value": "C117733"},
            {"label": "Wadenswil  Leihof-Muhlebach", "value": "C117734"},
            {"label": "Wadenswil  Untermosen-Fuhr", "value": "C117735"},
            {"label": "Wald", "value": "C117736"},
            {"label": "Wallisellen", "value": "C117737"},
            {"label": "Wallisellen  Rieden", "value": "C117738"},
            {"label": "Wallisellen  Wallisellen-Ost", "value": "C117739"},
            {"label": "Wallisellen  Wallisellen-West", "value": "C117740"},
            {"label": "Wangen", "value": "C117741"},
            {"label": "Weiningen", "value": "C117742"},
            {"label": "Weisslingen", "value": "C117743"},
            {"label": "Wettswil  Ausser-Dorf", "value": "C117744"},
            {"label": "Wettswil  Wettswil (Dorf)", "value": "C117745"},
            {"label": "Wetzikon", "value": "C117746"},
            {"label": "Wetzikon  Kempten", "value": "C117747"},
            {"label": "Wetzikon  Ober-Wetzikon", "value": "C117748"},
            {"label": "Wetzikon  Robenhausen", "value": "C117749"},
            {"label": "Wetzikon  Unter-Wetzikon", "value": "C117750"},
            {"label": "Wiesendangen  Wiesendangen (Dorf)", "value": "C117751"},
            {"label": "Wila", "value": "C117752"},
            {"label": "Winterthur", "value": "C117753"},
            {"label": "Wolfhausen", "value": "C117754"},
            {"label": "Wulflingen (Kreis 6)", "value": "C117755"},
            {"label": "Wulflingen (Kreis 6)  Harti", "value": "C117756"},
            {"label": "Wulflingen (Kreis 6)  Lindenplatz", "value": "C117757"},
            {"label": "Wulflingen (Kreis 6)  Niederfeld", "value": "C117758"},
            {"label": "Wulflingen (Kreis 6)  Oberfeld", "value": "C117759"},
            {"label": "Zollikerberg", "value": "C117760"},
            {"label": "Zollikon", "value": "C117761"},
            {"label": "Zumikon", "value": "C117762"},
            {"label": "Zurich", "value": "C117763"},
            {"label": "Zurich (Kreis 1)", "value": "C117764"},
            {"label": "Zurich (Kreis 1)  City", "value": "C117765"},
            {"label": "Zurich (Kreis 1)  Lindenhof", "value": "C117766"},
            {"label": "Zurich (Kreis 1)  Rathaus", "value": "C117767"},
            {"label": "Zurich (Kreis 10)", "value": "C117768"},
            {"label": "Zurich (Kreis 10)  Hongg", "value": "C117769"},
            {"label": "Zurich (Kreis 10)  Rutihof", "value": "C117770"},
            {"label": "Zurich (Kreis 10)  Wipkingen", "value": "C117771"},
            {"label": "Zurich (Kreis 11)", "value": "C117772"},
            {"label": "Zurich (Kreis 11)  Affoltern", "value": "C117773"},
            {"label": "Zurich (Kreis 11)  Oerlikon", "value": "C117774"},
            {"label": "Zurich (Kreis 11)  Schwandenholz", "value": "C117775"},
            {"label": "Zurich (Kreis 11)  Seebach", "value": "C117776"},
            {"label": "Zurich (Kreis 12)", "value": "C117777"},
            {"label": "Zurich (Kreis 12)  Auzelg", "value": "C117778"},
            {"label": "Zurich (Kreis 12)  Hirzenbach", "value": "C117779"},
            {"label": "Zurich (Kreis 12)  Saatlen", "value": "C117780"},
            {"label": "Zurich (Kreis 12)  Schwamendingen-Mitte",
             "value": "C117781"},
            {"label": "Zurich (Kreis 2)", "value": "C117782"},
            {"label": "Zurich (Kreis 2)  Enge", "value": "C117783"},
            {"label": "Zurich (Kreis 2)  Mittel-Leimbach", "value": "C117784"},
            {"label": "Zurich (Kreis 2)  Unter-Leimbach", "value": "C117785"},
            {"label": "Zurich (Kreis 2)  Wollishofen", "value": "C117786"},
            {"label": "Zurich (Kreis 3)", "value": "C117787"},
            {"label": "Zurich (Kreis 3)  Alt-Wiedikon", "value": "C117788"},
            {"label": "Zurich (Kreis 3)  Friesenberg", "value": "C117789"},
            {"label": "Zurich (Kreis 3)  Sihlfeld", "value": "C117790"},
            {"label": "Zurich (Kreis 4)  Aussersihl", "value": "C117791"},
            {"label": "Zurich (Kreis 4)  Hard", "value": "C117792"},
            {"label": "Zurich (Kreis 4)  Langstrasse", "value": "C117793"},
            {"label": "Zurich (Kreis 4)  Werd", "value": "C117794"},
            {"label": "Zurich (Kreis 5)", "value": "C117795"},
            {"label": "Zurich (Kreis 5)  Escher-Wyss", "value": "C117796"},
            {"label": "Zurich (Kreis 5)  Gewerbeschule", "value": "C117797"},
            {"label": "Zurich (Kreis 6)", "value": "C117798"},
            {"label": "Zurich (Kreis 6)  Oberstrass", "value": "C117799"},
            {"label": "Zurich (Kreis 6)  Unterstrass", "value": "C117800"},
            {"label": "Zurich (Kreis 7)", "value": "C117801"},
            {"label": "Zurich (Kreis 7)  Fluntern", "value": "C117802"},
            {"label": "Zurich (Kreis 7)  Hirslanden", "value": "C117803"},
            {"label": "Zurich (Kreis 7)  Hottingen", "value": "C117804"},
            {"label": "Zurich (Kreis 7)  Witikon", "value": "C117805"},
            {"label": "Zurich (Kreis 8)", "value": "C117806"},
            {"label": "Zurich (Kreis 8)  Muhlebach", "value": "C117807"},
            {"label": "Zurich (Kreis 8)  Seefeld", "value": "C117808"},
            {"label": "Zurich (Kreis 8)  Weinegg", "value": "C117809"},
            {"label": "Zurich (Kreis 9)", "value": "C117810"},
            {"label": "Zurich (Kreis 9)  Albisrieden", "value": "C117811"},
            {"label": "Zurich (Kreis 9)  Altstetten", "value": "C117812"}],
 "Zurrieq": [{"label": "Zurrieq", "value": "C65936"}],
 "Zuzemberk Municipality": [{"label": "Zuzemberk", "value": "C107872"}],
 "`Eua": [{"label": "Ohonua", "value": "C119648"}],
 "ancash": [{"label": "Asuncion", "value": "C80638"},
            {"label": "Caras", "value": "C80639"},
            {"label": "Carhuaz", "value": "C80640"},
            {"label": "Carlos Fermin Fitzcarrald", "value": "C80641"},
            {"label": "Chimbote", "value": "C80642"},
            {"label": "Coishco", "value": "C80643"},
            {"label": "Huaraz", "value": "C80644"},
            {"label": "Huarmey", "value": "C80645"},
            {"label": "Pomabamba", "value": "C80646"},
            {"label": "Provincia de Aija", "value": "C80647"},
            {"label": "Provincia de Antonio Raymondi", "value": "C80648"},
            {"label": "Provincia de Bolognesi", "value": "C80649"},
            {"label": "Provincia de Carhuaz", "value": "C80650"},
            {"label": "Provincia de Casma", "value": "C80651"},
            {"label": "Provincia de Corongo", "value": "C80652"},
            {"label": "Provincia de Huaraz", "value": "C80653"},
            {"label": "Provincia de Huari", "value": "C80654"},
            {"label": "Provincia de Huarmey", "value": "C80655"},
            {"label": "Provincia de Huaylas", "value": "C80656"},
            {"label": "Provincia de Mariscal Luzuriaga", "value": "C80657"},
            {"label": "Provincia de Pallasca", "value": "C80658"},
            {"label": "Provincia de Pomabamba", "value": "C80659"},
            {"label": "Provincia de Recuay", "value": "C80660"},
            {"label": "Provincia de Santa", "value": "C80661"},
            {"label": "Provincia de Sihuas", "value": "C80662"},
            {"label": "Provincia de Yungay", "value": "C80663"},
            {"label": "Puerto Santa", "value": "C80664"},
            {"label": "Yauya", "value": "C80665"},
            {"label": "Yungay", "value": "C80666"}],
 "districts of Republican Subordination": [{"label": "Darband",
                                            "value": "C117995"},
                                           {"label": "Hisor",
                                            "value": "C117996"},
                                           {"label": "Karakenja",
                                            "value": "C117997"},
                                           {"label": "Khodzha-Maston",
                                            "value": "C117998"},
                                           {"label": "Novobod",
                                            "value": "C117999"},
                                           {"label": "Obigarm",
                                            "value": "C118000"},
                                           {"label": "Rasht",
                                            "value": "C118001"},
                                           {"label": "Roghun",
                                            "value": "C118002"},
                                           {"label": "Shahrinav",
                                            "value": "C118003"},
                                           {"label": "Tagob",
                                            "value": "C118004"},
                                           {"label": "Tursunzoda",
                                            "value": "C118005"},
                                           {"label": "Vahdat",
                                            "value": "C118006"},
                                           {"label": "Vahdat District",
                                            "value": "C118007"},
                                           {"label": "Varzob",
                                            "value": "C118008"},
                                           {"label": "Varzob District",
                                            "value": "C118009"}],
 "equateur": [{"label": "Gemena", "value": "C23311"},
              {"label": "Lisala", "value": "C23312"},
              {"label": "Lukolela", "value": "C23313"},
              {"label": "Mbandaka", "value": "C23314"}],
 "evora": [{"label": "Alandroal", "value": "C91030"},
           {"label": "Arraiolos", "value": "C91031"},
           {"label": "Borba", "value": "C91032"},
           {"label": "Estremoz", "value": "C91033"},
           {"label": "evora", "value": "C91034"},
           {"label": "Montemor-o-Novo", "value": "C91035"},
           {"label": "Mora", "value": "C91036"},
           {"label": "Mourao", "value": "C91037"},
           {"label": "Portel", "value": "C91038"},
           {"label": "Redondo", "value": "C91039"},
           {"label": "Reguengos de Monsaraz", "value": "C91040"},
           {"label": "Vendas Novas", "value": "C91041"},
           {"label": "Viana do Alentejo", "value": "C91042"},
           {"label": "Vila Vicosa", "value": "C91043"}],
 "neembucu Department": [{"label": "Cerrito", "value": "C80598"},
                         {"label": "General Jose Eduvigis Diaz",
                          "value": "C80599"},
                         {"label": "Pilar", "value": "C80600"}],
 "nuble": [{"label": "Bulnes", "value": "C18120"},
           {"label": "Chillan", "value": "C18121"},
           {"label": "Chillan Viejo", "value": "C18122"},
           {"label": "Cobquecura", "value": "C18123"},
           {"label": "Coelemu", "value": "C18124"},
           {"label": "Coihueco", "value": "C18125"},
           {"label": "El Carmen", "value": "C18126"},
           {"label": "Ninhue", "value": "C18127"},
           {"label": "niquen", "value": "C18128"},
           {"label": "Pemuco", "value": "C18129"},
           {"label": "Pinto", "value": "C18130"},
           {"label": "Portezuelo", "value": "C18131"},
           {"label": "Quillon", "value": "C18132"},
           {"label": "Quirihue", "value": "C18133"},
           {"label": "Ranquil", "value": "C18134"},
           {"label": "San Carlos", "value": "C18135"},
           {"label": "San Fabian", "value": "C18136"},
           {"label": "San Ignacio", "value": "C18137"},
           {"label": "San Nicolas", "value": "C18138"},
           {"label": "Treguaco", "value": "C18139"},
           {"label": "Yungay", "value": "C18140"}]}

