import { Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { FC } from 'react'

interface OwnProps {
  variant?: Variant,
  label: string
  labelInjected?: any
}
const TextField: FC<OwnProps> = ({
  label,
  ...props
}) => {
  if(props?.labelInjected) {
    return  props.labelInjected
  }
  return <Typography variant = {props?.variant ?? 'inherit'}>
    {
      label
    }
  </Typography>
}

export default TextField;
