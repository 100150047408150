//import axios from 'axios';
import Axios from 'axios';
import { getCookieValue, getUserRole } from 'dynamic-form-components/lib/service';
import moment from 'moment';
import { DONATION_TYPE_VALUES } from '../constants';

import { getTimeZone } from '../components/common/DateUtils';
import { getSubContextAndSetDonationTypeHeaders, setDonationIdHeaderDynamically, setDonationIdHeaderonEveryRequest, setDonationTypeHeader } from './routeConstants';

const config = require(`./../config.${process.env.NODE_ENV}.json`);


interface IGLobalSearchFilter {
    key: string,
    value: string[] | string | number,
    matchType?: 'EQUALS' | 'STARTS_WITH' | 'ENDS_WITH' | 'CONTAINS' | 'IN' | 'RANGE'
}

export interface IDashboardFilter {
    key: string,
    value: string[] | string | number,
    endValue?: string | number,
    matchType: 'EQUALS' | 'STARTS_WITH' | 'ENDS_WITH' | 'CONTAINS' | 'IN' | 'RANGE'
}

Axios.interceptors.request.use((axiosConfig: any) => {
    const token = getCookieValue('authToken');
    const userId = getCookieValue('userId');
    if (!axiosConfig.headers) {
        axiosConfig.headers = {}
    }
    axiosConfig.headers.Accept = 'text/html, application/json';
    axiosConfig.headers.Authorization = `Bearer ${token}`;
    axiosConfig.headers['X-Timezone'] = getTimeZone();
    axiosConfig.withCredentials = true;
    axiosConfig.headers.userId = userId;
    axiosConfig.headers.userRole = getUserRole();
    // This is setting Header only for Space & emergency, future we will consider other donation Types
    let donationType = setDonationTypeHeader();;
    if (donationType) {
        axiosConfig.headers["DONATION-TYPE"] = donationType;
    }
    setDonationIdHeaderonEveryRequest();
    return axiosConfig;
});

const exportExcelAxios = Axios.create();
/* istanbul ignore next */
exportExcelAxios.interceptors.request.use((axiosConfig: any) => {
    const token = getCookieValue('authToken');
    const userId = getCookieValue('userId');
    if (!axiosConfig.headers) {
        axiosConfig.headers = {}
    }
    axiosConfig.headers.Authorization = `Bearer ${token}`;
    axiosConfig.headers['X-Timezone'] = getTimeZone();
    axiosConfig.withCredentials = true;
    axiosConfig.headers.userId = userId;
    axiosConfig.headers.userRole = getUserRole();
    // This is setting Header only for Space & emergency, future we will consider other donation Types
    let donationType = setDonationTypeHeader();;
    if (donationType) {
        axiosConfig.headers["DONATION-TYPE"] = donationType;
    }
    setDonationIdHeaderonEveryRequest();
    return axiosConfig;
});

export const getLanguageListbyCountryCode = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { countryCode }] = queryKey
    return await Axios.get(`${config.appBaseUrl}/internalusers/languages/country/${countryCode}`)
        .then(res => res.data)
}

/* To get the current month CA/AC, store/market level budget details based on parameters */
export const getBudgetInfo = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { ...payload }] = queryKey
    return await Axios.post(`${config.appBaseUrl}/budget/store-budget`, payload)
        .then(res => {
            return {
                data: res?.data
            }
        }).catch((err) => {
            /* istanbul ignore next */
            if (err.response && err.response.status === 401) {
                window.location.href = config.ssoUrl;
            }
            return {
                ...err?.response?.data
            }
        });
}

// To get budget details for a specific month by donation id
export const getBudgetInfoById = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, donationId] = queryKey
    return await Axios.post(`${config.appBaseUrl}/good-onetime/store-budget`, { donationId })
        .then(res => {
            return {
                data: res?.data
            }
        }).catch((err) => {
            /* istanbul ignore next */
            if (err.response && err.response.status === 401) {
                window.location.href = config.ssoUrl;
            }
            return {
                ...err?.response?.data
            }
        });
}

export const getOneTimeGoodsDonations = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { ...payload }] = queryKey
    const { data, page, totalCount } = await Axios.post(`${config.appBaseUrl}/good-onetime/dashboard`, payload)
        .then(result => {
            return {
                data: result.data.content,
                page: (result.data.number || 0),
                totalCount: result.data.totalElements,
            }
        }).catch((error) => {
            /* istanbul ignore next */
            if (error.response && error.response.status === 401) {
                window.location.href = config.pingfedUrl;
            }
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    return { data, page, totalCount }
}

export const getOneTimeFoodDonations = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { ...payload }] = queryKey
    const { pageNumber, pageSize, sortOrder, searchText, globalSearch } = queryKey[1];
    const orderBy = [{
        orderByField: payload?.sortBy,
        orderDir: sortOrder
    }]
    let params = { pageSize, pageNumber, orderBy, searchText, globalSearch }
    const globalSearchFilters: IGLobalSearchFilter[] = []
    if (Object.keys(payload.filters).length > 0) {
        for (const property in payload.filters) {
            if (payload?.filters[property]?.value) {
                globalSearchFilters.push({ key: property, value: payload.filters[property].value })
            }
        }
    }

    // @ts-ignore
    params = { ...params, globalSearchFilters }
    const URL = `${config.appBaseUrl}/food-onetime/dashboard`;

    const { data, page, totalCount } = await Axios.post(URL, params)
        .then((result) => {
            return {
                data: result.data.content,
                page: (result.data.number || 0),
                totalCount: result.data.totalElements,
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                window.location.href = config.pingfedUrl;
            }
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    return { data, page, totalCount }
}

export const getDonationsData = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { ...payload }] = queryKey
    const { pageNumber, pageSize, sortOrder, searchText, globalSearch } = queryKey[1];
    const orderBy = [{
        orderByField: payload?.sortBy,
        orderDir: sortOrder
    }]
    let params = { pageSize, pageNumber: pageNumber + 1, orderBy, searchText, globalSearch }
    const globalSearchFilters: IGLobalSearchFilter[] = []
    const defaultFilters: IGLobalSearchFilter[] = [{ key: 'type', value: [payload.type], matchType: 'IN' }]

    if (Object.keys(payload.filters).length > 0) {
        for (const property in payload.filters) {
            if (payload?.filters[property]?.value) {
                if (property === 'currentStatusCode') {
                    globalSearchFilters.push({ key: property, value: payload.filters[property].value, matchType: 'IN' })
                } else {
                    if (property === 'id' || property === 'migratedId') {
                        defaultFilters.push({
                            key: property, matchType: 'CONTAINS',
                            value: +payload.filters[property].value
                        })
                    } else if (property === 'orgLegalName') {
                        defaultFilters.push({
                            key: 'organization.orgLegalName', matchType: 'CONTAINS',
                            value: payload.filters[property].value
                        })
                    } else {
                        defaultFilters.push({ key: property, value: payload.filters[property].value, matchType: 'CONTAINS' })
                    }
                }
            }
        }
    }
    const donationUrls: { [key: string]: string } = {
        DONATION_OF_MONEY: 'donations/dashboard/money',
        DONATION_OF_GOODS_RECURRING: 'donations/dashboard/goods-recurring',
        FOOD_ONE_TIME: 'food-onetime/dashboard',
        DONATION_OF_GOODS_ONE_TIME: 'good-onetime/dashboard'
    }
    // @ts-ignore
    params = { ...params, globalSearchFilters, defaultFilters }
    const URL = `${config.appBaseUrl}/${donationUrls[payload.type]}`;
    const { data, page, totalCount } = await Axios.post(URL, params)
        .then((result) => {
            return {
                data: result.data.data,
                page: (result.data.totalNoOfPages || 0),
                totalCount: result.data.totalNoOfRecords,
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                window.location.href = config.pingfedUrl;
            }
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    return { data, page, totalCount }
}

export const getSocialImpactMonetaryDonations = async ({ queryKey }: any) => {
    // @ts-ignore
    const { pageNum, pageSize, filters } = queryKey[1];
    let url = `${config.appBaseUrl}/social-impact-reports/monetary/dashboard?pageNum=${pageNum + 1}&pageSize=${pageSize}&projectId=`;
    if (filters?.projectId?.value) {
        url = `${config.appBaseUrl}/social-impact-reports/monetary/dashboard?pageNum=${pageNum + 1}&pageSize=${pageSize}&projectId=${filters.projectId.value}`;
    }
    const { data, page, totalCount } = await Axios.get(url)
        .then(result => {
            return {
                data: result.data.content,
                page: (result.data.number || 0),
                totalCount: result.data.totalElements,
            }
        }).catch((error) => {
            /* istanbul ignore next */
            if (error.response && error.response.status === 401) {
                window.location.href = config.pingfedUrl;
            }
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    return { data, page, totalCount }
}

/* istanbul ignore next */
export const exportToExcel = async (excelToExcelPayload: any) => {
    try {
        const response = await exportExcelAxios.post(`${config.appBaseUrl}${excelToExcelPayload?.endpoint}`,
            {
                ...excelToExcelPayload?.payload
            },
            { responseType: 'blob' }
        );
        const url = URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${excelToExcelPayload?.fileName}_${moment().format('MMMM Do YYYY, h:mm:ss a')}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return response;
    } catch (downloaderror: any) {
        if (downloaderror.response && downloaderror.response.status === 401) {
            window.location.href = config.pingfedUrl;
        }
        return {
            errorMessage: (downloaderror && downloaderror.response &&
                downloaderror.response.data && downloaderror.response.data.errorMessage)
                ? downloaderror.response.data.errorMessage : ''
        };
    }
}
export const getOneTimeGoodsDonationDataById = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { donationId }] = queryKey
    return await Axios.get(`${config.appBaseUrl}/good-onetime/one/${donationId}`)
        .then(res => res.data).catch(hanldeError)
}

export const getOneTimeFoodDonationDataById = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { donationId }] = queryKey
    return await Axios.get(`${config.appBaseUrl}/food-onetime/one/${donationId}`)
        .then(res => res.data).catch(hanldeError)
}


export const updateNGOAcknowledgement = async (data: any) => {
    let response;
    try {
        response = await Axios.put(`${config.appBaseUrl}/good-onetime/acknowledge`, data)
            .then(res => res.data)
    } catch (error) {
        response = hanldeError(error)
        throw response
    }
    return response;
}


export const saveOneTimeGoodsDonation = async (data: any) => {
    let response;
    try {
        response = await Axios.post(`${config.appBaseUrl}/good-onetime/ngo`, data)
            .then(res => res.data)
    } catch (error) {
        /* istanbul ignore next */
        response = hanldeError(error)
        throw response
    }
    return response;
}


export const saveOrgProfile = async (data: any) => {
    const formData = new FormData();
    formData.append('organization', JSON.stringify(data.organization));
    formData.append('governanceDetails', JSON.stringify(data.governaceDetails))
    let response;
    try {
        response = await Axios.put(`${config.appBaseUrl}/organizations/update`, formData)
            .then(res => res.data)
    } catch (error) {
        /* istanbul ignore next */
        response = hanldeError(error)
        throw response
    }
    return response;
}

export const saveOneTimeFoodDonation = async (data: any) => {
    let response;
    try {
        response = await Axios.post(`${config.appBaseUrl}/food-onetime/ngo`, data)
    } catch (error) {
        /* istanbul ignore next */
        response = hanldeError(error)
        throw response
    }
    return response;
}

export const updateOneTimeGoodsDonation = async (data: any) => {
    let response;
    try {
        response = await Axios.put(`${config.appBaseUrl}/good-onetime/tasks`, data)
            .then(res => res.data)
    } catch (error) {
        /* istanbul ignore next */
        response = hanldeError(error)
        throw response
    }
    return response;
}

export const getAppendixTypes = (donation: string, donationId: number, donationType: string) => {
    let uri = donation + `/appendix?donationId=${donationId}&donationTypes=${donationType}`
    if (donationType === DONATION_TYPE_VALUES.FOOD_ONE_TIME || DONATION_TYPE_VALUES.DONATION_OF_GOODS_ONE_TIME) {
        donation + `/appendix/${donationId}?donationTypes=${donationType}`
    }
    return Axios.get(`${config.appBaseUrl}/${uri}`)
        .then(res => res.data).catch(hanldeError)
}

export const updateOneTimeFoodDonation = async (data: any) => {
    let response;
    try {
        response = await Axios.put(`${config.appBaseUrl}/food-onetime/tasks`, data)
            .then(res => res.data)
    } catch (error) {
        /* istanbul ignore next */
        response = hanldeError(error)
        throw response
    }
    return response;
}

export const getDonationDataByIdAndType = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { donationId }] = queryKey
    return getRecurringDonationById(donationId)
}

export const getRecurringDonationById = async (id: number) => {
    return await Axios.get(`${config.appBaseUrl}/donations/goods-recurring/${id}`)
        .then(res => res.data).catch(hanldeError)
}

export const getDonationDataById = async (id: number) => {
    return await Axios.get(`${config.appBaseUrl}/donations/money/${id}`)
        .then(res => res.data).catch(hanldeError)
}

export const approveDonationRequest = (data: any) => {
    return Axios.put(`${config.appBaseUrl}/donations/tasks/${data.donationType}/bulk`, data.payload)
        .then(response => response.data).catch(hanldeError);
}

export const getAppendixCHistory = async (donationId: string | number) => {
    return await Axios.get(`${config.appBaseUrl}/donations/appendixcHistory/${donationId}`)
        .then(res => res.data).catch(hanldeError)
}

export const getListOfAppendixCDates = async (params: any) => {
    const { startDate, endDate, donationId } = params;
    const querystring = `/donations/appendixc/dates?startDate=${startDate}&endDate=${endDate}&donationId=${donationId}`
    return await Axios.get(`${config.appBaseUrl}${querystring}`)
        .then(res => res.data).catch(hanldeError)
}

export const getOrgProfileData = async () => {
    return await Axios.get(`${config.appBaseUrl}/organizations/email`)
        .then(res => res.data).catch(hanldeError)
}

export const getDonationsForDashboard = async ({ queryKey }: any) => {
    // @ts-ignore
    const [_key, { ...payload }] = queryKey
    const { pageNumber, pageSize, sortOrder } = queryKey[1];
    const orderBy = [{
        orderByField: payload?.sortBy,
        orderDir: sortOrder
    }]
    let params = { pageSize, pageNumber, orderBy }
    const globalSearchFilters = []
    if (Object.keys(payload.filters).length > 0) {
        for (const property in payload.filters) {
            if (payload?.filters[property]?.value) {
                globalSearchFilters.push({ key: property, value: payload.filters[property].value })
            }
        }
    }
    // @ts-ignore
    params = { ...params, globalSearchFilters }
    let subContextPath = getSubContextAndSetDonationTypeHeaders(payload.type);
    let URL = `${config.appBaseUrl}/${subContextPath}`;
    const { data, page, totalCount } = await Axios.post(URL, params)
        .then((result) => {
            return {
                data: result.data.content,
                page: (result.data.number || 0),
                totalCount: result.data.totalElements,
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                window.location.href = config.pingfedUrl;
            }
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    return { data, page, totalCount }
}

export const getRdaConfig = async (data: any) => {
    return Axios.post(
        `${config.appBaseUrl}/rda/newdonation/check`,
        data
    )
        .then((response) => response.data)
        .catch(hanldeError);
}

export const getDonationDetailsById = async ({ queryKey }: any) => {
    const [_key, { donationId, subContext }] = queryKey;
    setDonationIdHeaderDynamically(donationId);
    return Axios.get(`${config.appBaseUrl}/${subContext}?donationId=${donationId}`)
        .then((response) => response.data)
        .catch(hanldeError);
}

export const createNewDonation = async (data: any, subContext: string) => {
    return Axios.post(`${config.appBaseUrl}/${subContext}`, data)
        .then((response) => response.data)
        .catch(hanldeError);
}

export const postDonationTasksByAction = async (data: FormData, subContext: string) => {
    return Axios.post(`${config.appBaseUrl}/${subContext}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then((response) => response.data)
        .catch(hanldeError);
}

/* istanbul ignore next */
export const hanldeError = (error: any) => {
    if (error?.response?.status === 401) {
        window.location.href = config.pingfedUrl;
    }
    return {
        errorMessage: (error?.response?.data?.errorMessage)
            ? error?.response?.data?.errorMessage : ''
    };
}

export const getAppConfigTypes = (keyText: string) => {
    return Axios.get(`${config.appBaseUrl}/multiLingual/${keyText}`).then(response => response.data)
}
