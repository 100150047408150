import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core';

export interface OwnProps {
    backgroundColor: string;
    fullScreen?: boolean;
    classes?: any;
}

const styles = (theme: any) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class LoaderComponent extends React.PureComponent<any> {
    public render() {
        const {classes} = this.props;
        return <>
        {
            this.props.fullScreen ?
                <Backdrop className={classes.backdrop} open={true} >
                    <CircularProgress color='inherit' />
                </Backdrop>
          :
                <ContentLoader
                speed={2}
                width={436}
                viewBox='0 0 436 400'
                backgroundColor='#f3f3f3'
                foregroundColor='#D3D3D3'
                {...this.props}
            >
                <rect x='10' y='50' rx='3' ry='3' width='200' height='15' />
                <rect x='10' y='100' rx='3' ry='3' width='200' height='15' />
                <rect x='10' y='150' rx='3' ry='3' width='200' height='15' />
                <rect x='10' y='200' rx='3' ry='3' width='200' height='15' />
                <rect x='10' y='250' rx='3' ry='3' width='200' height='15' />
                <rect x='10' y='300' rx='3' ry='3' width='200' height='15' />
                <rect x='10' y='350' rx='3' ry='3' width='200' height='15' />
            </ContentLoader>

        }
        </>;
    }
}

export default withStyles(styles) (LoaderComponent);
