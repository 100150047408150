import { objectHasValue } from '../../../common/utils';
import { AppendixBRequestDTO } from '../../../../models/iOneTimeFoodDTO';
import { DONATION_TYPE, taxIdPlaceholderTxt } from '../../../../constants';
import instance from '../../../../i18n';
import { getCurrencySymbol } from '../../utils';
import { IFormConfig } from '../../../common/FormSection/FormSection'
import { Trans } from 'react-i18next';

export const getAboutOrgConfig = (disabled = false, countryCode: string, appendixB: AppendixBRequestDTO): IFormConfig[] => {

  return [
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'orglegalName',
        label: instance.t('orgRegForm.labels.gelegalName'),
        disabled: true,
        fieldName: 'organization.orgLegalName',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'taxId',
        label: instance.t('orgRegForm.labels.taxId'),
        disabled,
        fieldName: 'organization.taxId',
        required: false,
        type: 'text',
        placeholder: taxIdPlaceholderTxt[countryCode]
      }
    },
    {
      gridConfig: {
        md: 12,
        xs: 12,
        lg: 10
      },
      uiConfig: {
        id: 'streetAddress',
        label: instance.t('orgRegForm.labels.streetAddr'),
        disabled: disabled && objectHasValue('organization.address.addressLine1', appendixB),
        fieldName: 'organization.address.addressLine1',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'postalCode',
        label: instance.t('orgRegForm.labels.postalCode'),
        disabled: disabled && objectHasValue('organization.address.postalCode', appendixB),
        fieldName: 'organization.address.postalCode',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'country',
        label: instance.t('appendices.c.donationRecipient.countryOrRegion'),
        disabled: disabled && objectHasValue('organization.address.country', appendixB),
        fieldName: 'organization.address.country',
        type: 'text',
        required: true
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'telephone',
        label: instance.t('orgRegForm.labels.telephone'),
        disabled: disabled && objectHasValue('organization.telephone', appendixB),
        fieldName: 'organization.telephone',
        type: 'text',
        required: true
      }
    }
  ]
}

export const getPrimaryContactConfig = (disabled = false, appendixB: AppendixBRequestDTO): IFormConfig[] => {

  return [
    {
      gridConfig: {
        md: 4,
        xs: 12,
        lg: 3
      },
      uiConfig: {
        id: 'primaryOrgUser.userTitle',
        label: instance.t('orgRegForm.labels.userTitle'),
        disabled: disabled && objectHasValue('organization.primaryOrgUser.userTitle', appendixB),
        fieldName: 'organization.primaryOrgUser.userTitle',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 8,
        xs: 12,
        lg: 7
      },
      uiConfig: {
        id: 'primaryOrgUser.userName',
        label: instance.t('orgRegForm.labels.primaryuser'),
        disabled: disabled && objectHasValue('organization.primaryOrgUser.userName', appendixB),
        fieldName: 'organization.primaryOrgUser.userName',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'primaryuserEmail',
        label: instance.t('orgRegForm.labels.primaryuserEmail'),
        disabled: true,
        fieldName: 'organization.primaryOrgUser.userEmail',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'telephone',
        label: instance.t('orgRegForm.labels.telephone'),
        disabled,
        required: false,
        fieldName: 'organization.primaryOrgUser.userTelephone',
        type: 'text',
      }
    }
  ]
}


export const getDonationDetails = (disabled = false, _disableAppendixAData = false,
  countryCode: string, appendixB: any, apendixBDataDefault: any): IFormConfig[] => {
  const donationCheckBoxOptions = [
    { value: DONATION_TYPE.MONETARY, labelKey: 'appendixBDonationLabels.money' },
    { value: DONATION_TYPE.GOODSANDFOOD, labelKey: 'appendixBDonationLabels.goods' },
    { value: DONATION_TYPE.FOOD_ONE_TIME, labelKey: 'appendixBDonationLabels.food' }
  ]
  return [
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'donationTitle',
        label: instance.t('oneTimeGoodsDonation.heading.donationTitle'),
        disabled,
        fieldName: 'donationTitle',
        type: 'text',
      }
    }, {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'typeOfDonation',
        label: instance.t('appendixBDonationLabels.typeOfDonation'),
        fieldName: 'donationType',
        type: 'checkboxCombo',
        disabled: true,
        required: true,
        defaultValue: DONATION_TYPE.FOOD_ONE_TIME,
        qno: '1',
        options: donationCheckBoxOptions
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'listOfCountriesActivityDone',
        question: instance.t('appendixBDonationLabels.listTheCountries'),
        label: instance.t('oneTimeFoodDonation.listOfCountries'),
        fieldName: 'listOfCountries',
        type: 'descriptionBox',
        disabled,
        required: true,
        qno: '2'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'donationOfFoodType',
        question: instance.t('oneTimeFoodDonation.donationOfFoodType'),
        label: instance.t('oneTimeFoodDonation.explain'),
        fieldName: 'description',
        type: 'descriptionBox',
        disabled,
        required: true,
        qno: '3'
      }
    },
    {
      gridConfig: {
        xs: 12,
        md: 10,
        container: true,
        spacing: 2
      },
      uiConfig: {
        id: 'requestAmount',
        label: `${instance.t('oneTimeGoodsDonation.labels.value')} (${getCurrencySymbol(countryCode)})`,
        question: instance.t('oneTimeFoodDonation.amountIf'),
        disabled,
        fieldName: 'amount',
        type: 'requestAmount',
        qno: '4'
      }
    },

    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'purposeOfDonation',
        question: instance.t('oneTimeFoodDonation.purposeOfDonation'),
        label: instance.t('oneTimeFoodDonation.explain'),
        fieldName: 'purpose',
        disabled,
        required: true,
        type: 'descriptionBox',
        qno: '5'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'isRecurring',
        question: instance.t('formQuestion.oneTimeOrRecurringHeading'),
        yesLabel: instance.t('formQuestion.recurring'),
        noLabel: instance.t('formQuestion.oneTime'),
        label: instance.t('formQuestion.recurringDonationDetail'),
        fieldName: 'isRecurring.description',
        optionName: 'isRecurring.value',
        required: true,
        disabled,
        defaultValue: appendixB.isRecurring?.value,
        type: 'yesOrNoQuestion',
        qno: '6'
      }
    }, {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'donationAttachments',
        fieldName: 'donationAttachments',
        label: instance.t('common.heading.donationDocumnets'),
        disabled,
        defaultValue: apendixBDataDefault?.uploadFiles ?? [],
        required: false,
        type: 'fileUpload'
      }
    }
  ]
}


export const getCertificationConfig = (disabled = false, _countryCode: string, _appendixB: any,
  apendixBDataDefault: any, hideConcentFile: boolean): IFormConfig[] => {
  return [
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'certificationCheckBox1',
        label: instance.t('geAppendixBCertification.checkbox1'),
        fieldName: 'organization.certificationCheckBox1',
        defaultValue: hideConcentFile,
        type: 'permission',
        disabled,
        required: true,
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'certificationCheckBox2',
        label: 'geAppendixBCertification.checkbox2',
        fieldName: 'organization.certificationCheckBox2',
        defaultValue: hideConcentFile,
        type: 'permission',
        disabled,
        required: true,
        labelInjected: <Trans i18nKey='geAppendixBCertification.checkbox2'>
          Organization understands that the Company's Global Anti-Corruption Policy is available at
          <a target='_blank' href='https://walmartethics.com' rel='noreferrer'>https://walmartethics.com</a>
        </Trans>,
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'certificationCheckBox3',
        label: instance.t('geAppendixBCertification.checkbox3'),
        fieldName: 'organization.certificationCheckBox3',
        defaultValue: hideConcentFile,
        type: 'permission',
        disabled,
        required: true,
      }
    },
    {
      gridConfig: {
        md: 12,
        xs: 12,
        lg: 10
      },
      uiConfig: {
        id: 'signature',
        label: instance.t('oneTimeGoodsDonation.heading.signature'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.signature',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignee.userTitle',
        label: instance.t('appendixBCertification.userTitle'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.userTitle',
        type: 'text',
      }
    }, {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignee.userName',
        label: instance.t('common.orgMembers.name'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.userName',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'employer',
        label: instance.t('appendixBCertification.employer'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.employer',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignedDate',
        label: instance.t('appendixBCertification.todayDate'),
        disabled: true,
        fieldName: 'organization.certificateSignedDate',
        type: 'text',
      }
    }, {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'certificationText',
        fieldName: 'geAppendixBCertification.certificationText',
        label: instance.t('appendixBCertification.certificationText'),
        variant: 'body1',
        type: 'textField'
      }
    }, {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'organizationAttachments',
        fieldName: 'organizationAttachments',
        label: instance.t('common.heading.orgDocuments'),
        disabled,
        defaultValue: apendixBDataDefault?.orgUploadFiles,
        required: false,
        type: 'fileUpload'
      }
    }
  ]
}
