import React, { FC } from 'react';
import { SelectOptionTemplateProps } from './../../../types/SelectOptionTemplateProps';

const SelectOptionTemplateComponent:FC<SelectOptionTemplateProps> = ({option}) =>{
    return (
        <div className='p-d-flex p-ai-center'>
            <div>{option?.name}</div>
        </div>
    );
}

export default React.memo(SelectOptionTemplateComponent)