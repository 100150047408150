import { useEffect } from 'react';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useMutation } from 'react-query';

const service: Service = SpaceDonationService;

const FIleUploadComponent: any = (props: any) => {
    console.log(props.getValues());
    const mutation = useMutation((data: any) => service.saveImage(data));
    useEffect(() => {
        if (mutation.isSuccess) {
            const data = mutation.data;
            props.setFileId(data.fileName);
            props.setUploadDir(data.uploadDir);
            props.setIsFileUploading(false);
        }
        if (mutation.error) {
            props.setIsFileUploading(false);
        }
    }, [mutation.isLoading])
    return <div className='file-upload-container'>
        <div className='checkout-text-header'>We would love to share your story</div>
        <div className='checkout-text-info'>
            Please share a picture of your event with us!
        </div>
        <div className='file-upload-section'>
            {props.isImageFetching && <div>Loading....</div>}
            {props.isFileUploading && <div>Uploading....</div>}
            {!props.file && !props.isImageFetching && !props.isFileUploading &&
                <input type='file' id='feedback-file' className='file-upload'
                    accept='image/png, image/jpg, image/jpeg'
                    onChange={(event: any) => {
                        props.setFile(URL.createObjectURL(event.target.files[0]));
                        mutation.mutate({
                            file: event.target.files[0],
                            donationId: props.donationId,
                            countryCode: props.countryCode,
                            fileType: 'image'
                        });
                        props.setIsFileUploading(true);
                    }
                    } />}

            {props.file && !props.isImageFetching && !props.isFileUploading &&
                <img className='file-upload-content' src={props.file} />
            }
        </div>
        {props.file && !props.isImageFetching && !props.isFileUploading &&
            <div className='change-button' onClick={() => {
                props.setFile('');
                props.setFileId();
                props.setUploadDir(null)
            }}> Remove</div>}
            <br/>
        <div style={{fontSize: '14px'}}>I hereby give Walmart permission to use my social media post(s) in connection with Walmart Spark Good,
        to include name, voice, moving images, photographs, videos and likeness for the purpose of
        posting on Walmart’s social media relating to Walmart Spark Good.
            I hereby release and discharge Walmart from any and all claims arising out of use of my posts.</div>
    </div>
}

export default FIleUploadComponent;