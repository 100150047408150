import * as React from 'react';
import { Backdrop, withStyles } from '@material-ui/core';

export interface OwnProps {
    backgroundColor: string;
    classes?: any;
}

const styles = (theme: any) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
    },
});

class OnboardingLoaderComponent extends React.PureComponent<any> {
    public render() {
        const {classes} = this.props;
        return <Backdrop className={classes.backdrop} open={true} >
            <div>
                <img height='150px' width='150px'
                      src={require('src/components/spacedonation/commonComponents/assets/logos/Walmart_Spark.png')} />
            </div>
            <div>
                Thank You!{' '}&nbsp;
            </div>
            <br/>
            <div>
            We're preparing your experience...
            </div>
        </Backdrop>;
    }
}

export default withStyles(styles) (OnboardingLoaderComponent);

// import * as React from 'react';
// import Button from '../../../commonComponents/uiComponents/LDButton';
//
// const ThankYou: any = () => {
//     return <div className='thank-you-container'>
//         <div><img height='150px' width='150px'
//                   src={require('src/components/spacedonation/commonComponents/assets/logos/Walmart_Spark.png')} /></div>
//         <div className='checkout-text-header'>Thank You!</div>
//         <div className='checkout-text-value'>We're preparing your experience...</div>
//         <div className='go-back-button'><Button>Back to home</Button></div>
//     </div>
// }
//
// export default ThankYou;
