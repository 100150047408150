import { FC } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

export interface OwnProps {
  showDialog: boolean;
  onClose: () => void;
  classes?: any;
  content?: any;
}

const ReportModel: FC<OwnProps> = ({
  classes = [], ...props
}) => {
  const { t } = useTranslation();
  const statusMessages = props?.content?.failedOperations > 0
    && props?.content?.failedRecords?.map((item: any) => {
      return (
        <div className='dialog-msg row' key={item.record}>
          <div className='error-msg col-md-6'>{item.record}</div>
          <div className='error-msg col-md-6'>{item.failureMessages[0]}</div>
        </div>
      )
    })
  const errorMessages = props?.content?.commonErrors?.map((item: any) => {
    return (
      <div className='dialog-msg row' key={item.record}>
        <div className='error-msg col-md-12'>{item}</div>
      </div>
    )
  })
  return (<Dialog
    disableEnforceFocus={true}
    title={t('organizationStoreAssignment.fileUploadSuccessMsg')}
    fullWidth={true}
    maxWidth={'md'}
    open={props?.showDialog}
  >
    <DialogTitle id='form-edit-dialog'>{t('organizationStoreAssignment.fileUploadSuccessMsg')}
      <IconButton aria-label='close' className={'reportModel-closeButton'} onClick={props?.onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <p className='popup-message'>
        {`${t('organizationStoreAssignment.totalRecords')}${props?.content?.totalRecords}`}
      </p>
      <p className='popup-message'>
        {`${t('organizationStoreAssignment.successfullRecords')}${props?.content?.successfulOperations}`}
      </p>
      <p className='popup-message'>
        {`${t('organizationStoreAssignment.failedRecords')}${props?.content?.failedOperations}`}
      </p>
      {statusMessages && statusMessages.length > 0 && <div className='dialog-msg row'>
        <div className='error-msg col-md-6'>{t('organizationStoreAssignment.rowNumber')}</div>
        <div className='error-msg col-md-6'>{t('organizationStoreAssignment.failureReason')}</div>
      </div>}
      {statusMessages}
      {errorMessages && errorMessages.length > 0 && <div className='dialog-msg row'>
        <div className='error-msg col-md-12'>{t('organizationStoreAssignment.failureReason')}</div>
      </div>}
      {errorMessages}
    </DialogContent>
  </Dialog>)
}

export default ReportModel;
