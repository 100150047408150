//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GlobalDonationState } from '../../models/globalDonationState';
import { selectedMenu } from '../../state/globalDonationActions';
import { getQueryParam } from './ResponsiveMenuComponent';
import { withStyles } from '@material-ui/core';

const styles: any = (_theme: any) => ({
  menuItemTextRootList: {
    fontFamily: 'var(--appFont)',
    fontWeight: 'bold',


  },
  menuItemTextRoot: {
    '&$menuItemTextRootList:focus, &$menuItemTextRootList:active, &$menuItemTextRootList, &$menuItemTextRootList:hover': {
      backgroundColor: window.countryCode && window.countryCode.trim().toUpperCase() === 'GB' ?
        'rgba(104, 165, 28, 0.1) !important' : 'rgba(0, 113, 206, 0.07) !important',
      color: window.countryCode && window.countryCode.trim().toUpperCase() === 'GB' ?
        '#228B22 !important' : '#0071ce !important'
    },
    menuItemTextRootList: {},
    fontFamily: 'var(--appFont)',
    fontWeight: '550'
  }
});

export interface OwnProps {
  handleClose: () => void;
  name: string;
  routeLink: string;
  activeLink: string;
  classes: any;
  icon?: React.ReactElement<any>;
}

export interface StateProps {
  selectedMenuOption: string
}
export interface DispatchProps {
  selectedMenu: (data: string) => void;
}

class NavigationComponent extends React.PureComponent<OwnProps & RouteComponentProps & DispatchProps & StateProps> {

  public componentDidMount() {
    this.props.selectedMenu(getQueryParam() || '');
  }
  public UNSAFE_componentWillReceiveProps() {
    this.props.selectedMenu(getQueryParam() || '');
  }
  public render() {
    const { classes } = this.props;
    return (
      <div onClick={this.handleClick}>
        <MenuItem button={true}
          key={this.props.name}
          selected={this.props.selectedMenuOption === this.props.activeLink}
          classes={{ selected: classes.menuItemTextRootList, root: classes.menuItemTextRoot }}>
          {/* <ListItemIcon>
            {this.props.icon}
          </ListItemIcon> */}
          <ListItemText classes={{ root: classes.menuItemTextRoot, primary: classes.menuItemTextRoot }} 
          key={`key-${this.props.name}`}
          datatest-id={`'route-'${this.props.name}`}
          primary={this.props.name} />
        </MenuItem>
      </div>
    );
  }

  private handleClick = () => {
    this.props.history.push(this.props.routeLink);
    this.props.selectedMenu(getQueryParam() || '');
  };
}
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
  selectedMenu: (data: string) => dispatch(selectedMenu(data))
});

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
  selectedMenuOption: state.selectedMenuOption
})

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationComponent)));;