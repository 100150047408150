import React from 'react';
import { Box } from '@material-ui/core';
import { TabPanelProps } from '../../../types/formComponentTypes'

const CustomTabPanel:React.FC<TabPanelProps> = ({ value, index, role = '', id = '', ...other }) => {

    return (
      <div
        role={role}
        hidden={value !== index}
        id={`${id}-${index}`}
        aria-labelledby={`${role}-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Box component='div'>{other?.children}</Box>
          </Box>
        )}
      </div>
    );
  }

export default React.memo(CustomTabPanel)

