
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { AppendixBRequestDTO } from '../../../../models/iOneTimeFoodDTO';
import {
  getAboutOrgConfig, getPrimaryContactConfig, getQuestions,
  getDonationDetails, getCertificationConfig, getConcentConfig
} from '../configs/appendixBNgoConfig';
import FormSection from '../../../common/FormSection/FormSection'

export interface AppendixBProps {
  apendixBDataDefault: AppendixBRequestDTO,
  appendixBformData: AppendixBRequestDTO | undefined
  countryCode: string,
  maxAllowedBudget: number,
  disabledSections: AppendixBSections[],
  formDisabled?: boolean,
  hideConcentFile?: boolean,
  requestSourceApp: string
}

interface OwnProps {
  apendixBDataDefault: AppendixBRequestDTO,
  appendixBformData: AppendixBRequestDTO | undefined
  countryCode: string,
  maxAllowedBudget: number,
  formDisabled: boolean,
  disabledSections: AppendixBSections[]
}
export enum AppendixBSections {
  ORG = 'orgDetails',
  PRIMARY_USER = 'primaryUser',
  QUESTIONS = 'questionnaire',
  DONATION = 'donationDetails',
  CERTIFICATION = 'certification',
  CONCENT = 'concent'
}
const AppendixBForm: FC<OwnProps> = ({
  apendixBDataDefault,
  appendixBformData,
  disabledSections,
  formDisabled,
  countryCode }) => {

  const { t } = useTranslation();

  return <>

    <FormSection
      heading={t('oneTimeGoodsDonation.heading.orgDetails')}
      configs={
        getAboutOrgConfig(formDisabled || disabledSections.includes(AppendixBSections.ORG), countryCode, apendixBDataDefault)
      }
    />
    <FormSection
      heading={t('orgMngmtTbl.cols.primaryContact')}
      configs={
        getPrimaryContactConfig((formDisabled || disabledSections.includes(AppendixBSections.PRIMARY_USER)), apendixBDataDefault)
      }
    />
    <FormSection
      heading={t('common.heading.questionnaire')}
      configs={
        getQuestions(formDisabled || disabledSections.includes(AppendixBSections.QUESTIONS), appendixBformData ?? apendixBDataDefault)
      }
    />
    <FormSection
      heading={t('oneTimeGoodsDonation.heading.donationDetails')}
      configs={
        getDonationDetails(formDisabled || disabledSections.includes(AppendixBSections.DONATION),
          countryCode, appendixBformData ?? apendixBDataDefault, apendixBDataDefault)
      }
    />

    <FormSection
      heading={t('appendixBCertification.certification')}
      configs={
        getCertificationConfig(formDisabled || disabledSections.includes(AppendixBSections.CERTIFICATION), apendixBDataDefault)
      }
    />
    <FormSection
      heading={t('appendixBCertification.consentTitle')}
      configs={
        getConcentConfig(formDisabled || disabledSections.includes(AppendixBSections.CONCENT), countryCode)
      }
    />
  </>
}

export default AppendixBForm;
