import React, { useEffect } from 'react';
import { Badge } from '@walmart-web/livingdesign-components';
import startOfDay from 'date-fns/startOfDay';
import moment from 'moment';
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker
} from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
const AVAILABLE_COLOR = '#EAF3E6';
type legendType='available' | 'conflicting' | 'optedOut'|'selected' | 'approved' | 'pending' ;
export const Legend: React.FC<{
  label: string;
  type: legendType;
}> = ({ label, type }) => {
  const availableStyles = {
    backgroundColor: AVAILABLE_COLOR,
    height:' 1rem',
    width: '1rem',
  };
  const conflictingStyles = {
    backgroundColor: '#FFF9E9',
    border: '1px solid #ffe18e',
    borderRadius: '50%',
    height:' 1rem',
    width: '1rem',
  };
  const approvedStyles = {
    backgroundColor: '#beefa8',
    height:' 1rem',
    width: '1rem',
  };
  const pendingStyles = {
    backgroundColor: '#fff690',
    height:' 1rem',
    width: '1rem',
  };
  const optedOutStyles = {
    backgroundColor: '#e0797c',
    height:' 1rem',
    width: '1rem',
  };
  const selectedStyles = {
    backgroundColor: '#bcd9f7',
    height:' 1rem',
    width: '1rem',
  };

  const styles = {
    available: availableStyles,
    conflicting: conflictingStyles,
    optedOut: optedOutStyles,
    selected:selectedStyles,
    approved:approvedStyles,
    pending: pendingStyles,
  }[type];

  return (
    <span className='d-flex align-items-center mx-1'>
      <Badge UNSAFE_style={styles} />
      <span style={{ fontSize: 12, margin: '0 6px' }}>{label}</span>
    </span>
  );
};

export const findIndexDate = (dates: any, date: any) => {
  const dateTime = date.getTime();
  return dates.findIndex((item: any) => item.getTime() === dateTime);
};

export interface DatePickerProps {
  approvedDates: any[];
  submittedDates: any[];
  rejectedDates: any[];
  onChangeAvailable: (values: any) => void;
  disabled: boolean;
  minDate: any;
  maxDate: any;
  defaultCalendarMonth?: Date;
}

const MultiDatePicker: React.FunctionComponent<DatePickerProps> = ({
  approvedDates,
  submittedDates,
  rejectedDates,
  onChangeAvailable,
  disabled,
  minDate,
  maxDate,
  defaultCalendarMonth
}) => {
  const [values, setValues] = React.useState<Date[]>(approvedDates);
  const [available, setAvailableDates] = React.useState<Date[]>(approvedDates);
  const [submitted, setSubmittedDates] = React.useState<Date[]>(submittedDates);
  const [rejected, setRejectedDates] = React.useState<Date[]>(rejectedDates);
  useEffect(() => {
    if (disabled) {
      return;
    }
    onChangeAvailable(values);
  }, [values]);

  useEffect(() => {
    setValues(approvedDates);
    setAvailableDates(approvedDates);
  }, [approvedDates]);

  useEffect(() => {
    setSubmittedDates(submittedDates);
  }, [submittedDates]);
  useEffect(() => {
    setRejectedDates(rejectedDates);
  }, [rejectedDates]);

  const findDate = (dates: any, date: any) => {
    const dateTime = date.getTime();
    return dates.find(
      (item: any) => item.getTime() === dateTime && date > new Date()
    );
  };

  const renderPickerDay = (
    day: any,
    _selectedDate: any,
    pickersDayProps: any
  ) => {
    const dayInCurrentMonth = !pickersDayProps.outsideCurrentMonth;
    const selected = findDate(values, day);
    const isSubmitted: any = submitted.find(
      (d) =>
        moment(d).isSame(moment(day)) && day > new Date() && dayInCurrentMonth
    );
    const isRejected: any = rejected.find(
      (d) =>
        moment(d).isSame(moment(day)) && day > new Date() && dayInCurrentMonth
    );
    const isAvailable: any = available.find(
      (d) =>
        moment(d).isSame(moment(day)) && day > new Date() && dayInCurrentMonth
    );
    const isPast =
      moment(day).isBefore(moment(startOfDay(minDate))) ||
      moment(day).isSameOrBefore(moment());
    const isFuture = moment(day).isAfter(moment(maxDate));
    const renderClassName = () => {
      let className = '';
      if (disabled !== true) {
        if (selected && dayInCurrentMonth) {
          className = 'selected-button';
        }
      } else {
        if (isSubmitted) {
          className = 'submitted-button';
        } else if (isRejected) {
          className = 'rejected-button';
        } else if (isAvailable) {
          className = 'available-button';
        }
      }
      return className;
    };
    return (
      <div className={renderClassName()}>
        <PickersDay disabled={isPast || isFuture} {...pickersDayProps}>
          {dayInCurrentMonth ? <span>{day.getDate()}</span> : <></>}
        </PickersDay>
      </div>
    );
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
              displayStaticWrapperAs='desktop'
              label='Week picker'
              value={values}
              onChange={(newValue) => {
                if (
                  newValue &&
                  startOfDay(newValue) <= maxDate &&
                  startOfDay(newValue) >=startOfDay(minDate)
                ) {
                  setValues((previousValues) => {
                    const array = previousValues;
                    const date = startOfDay(newValue);
                    const index = findIndexDate(array, date);
                    if (index >= 0) {
                      array.splice(index, 1);
                    } else {
                      array.push(date);
                    }
                    return array;
                  });
                }
              }}
              renderDay={renderPickerDay}
              renderInput={(params) => <TextField {...params} />}
              views={['day']}
              disableHighlightToday={true}
              readOnly={disabled}
              maxDate={maxDate}
              minDate={minDate}
             defaultCalendarMonth={defaultCalendarMonth}   
              />
    </LocalizationProvider>
    </>
  );
};

export default MultiDatePicker;
