import React from 'react';
import Card from '../../uiComponents/LDCard';
import CardItem from '../../uiComponents/LDCardItem';
import Button from '../../uiComponents/LDButton';
import './styles.css';
import { useQuery } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LoaderComponentFitDiv from '../../../commonComponents/appComponents/loader/LoaderComponentFitDiv';

export interface AdCardProps {
    header: string,
    buttonName: string,
    fileId:string,
    directory:string,
    buttonLink:string,
    className?:any,
    children?: any
}

const service: Service = SpaceDonationService;

const AdCard: React.SFC<AdCardProps> = (props) => {
        const { data,isFetching} = useQuery(['downloadProjectFile', {
            fileId: props.fileId,
            container: 'space-donation',
            directory: props.directory,
            type: 'image'
        }],
            service.downloadProjectFile, { refetchOnWindowFocus: false });
    return <>{isFetching? <LoaderComponentFitDiv halfScreen={true}/> : <Card className='ad-card'>
        <img className='imgAlign' height='100px' width='330px' src={data} />
        <CardItem className='ad-header'>
            {props.header}
        </CardItem>
        <CardItem className='ad-content'>
            {props?.children}
        </CardItem>
        <CardItem className='ad-button'>
            <Button onClick={() => window.open(props.buttonLink.toString())}>{props.buttonName}</Button>
        </CardItem>
    </Card>
    }
    </>
}

export default AdCard;
