import { contextPath } from "src/constants";

export enum DonationFlow {
    AddDonation = "AddDonation",
    ThankYou = "ThankYou",
    Dashboard = "Dashboard",
    ViewRequest = "ViewRequest",
    ShowCard = "ShowCard",
  }
export const defaultFacilityType = {
  facilityNo: "",
  postalCode: "",
  county: "",
  state: "",
  streetAddress: "",
  city: "",
  facilityType: "",
}
export const addDonationbreadcrumbsData = [
    {
      name: "emergencyDonations.nav.home",
      path: "/",
    },
    {
      name: "emergencyDonations.nav.emergencyDonations",
      path: `${contextPath}/dashboard/emergency-donation?type=emergency-donation`,
    },
    {
      name: "emergencyDonations.form.newDonation",
      active: true,
      applyFormat: false,
    },
  ];