import { FC } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { objectHasValue } from '../../common/utils';
import { ErrorMessageComponent } from '../formComponents/ErrorMessage';
import { useTranslation } from 'react-i18next'
import { ILabelValue } from './FormSection';

interface OwnProps {
  fieldName: string,
  defaultValue?: string,
  options: ILabelValue[],
  label: string,
  required?: boolean,
  disabled?: boolean,
  qno?: string
}
const CheckboxCombo: FC<OwnProps> = ({
  fieldName,
  defaultValue = '',
  options,
  label,
  required = false,
  disabled = false,
  qno = ''
}) => {
  const { errors, control } = useFormContext();
  const hasError = fieldName ? objectHasValue(fieldName, errors) : false;
  const { t } = useTranslation();
  return <FormControl component='fieldset' >
    <FormLabel required={required} id={fieldName} component='legend'>{qno ? `${qno} .` : ''} {label}</FormLabel>
    <Controller
      control={control}
      name={fieldName}
      defaultValue={defaultValue}
      required={required}
      render={(field: any) => (
        <FormGroup row={true} data-testid={fieldName}>
          {options.map((option) => <FormControlLabel
            key={option.value}
            control={<Checkbox {...field} disabled={disabled}
              value={option.value} defaultChecked={defaultValue === option.value} />}
            label={t(option.labelKey)}
          />)}
        </FormGroup>
      )}
    />
    {hasError && <ErrorMessageComponent errors={errors} fieldName={fieldName} />}
  </FormControl>
}

export default CheckboxCombo;
