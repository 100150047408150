/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import './../../../styles/onetimegoods-dashboard.css';

import { makeStyles, Theme } from '@material-ui/core';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalDonationDataService from 'src/service';

import { getDateOnlyForLocale, getFormattedDate } from '../../../components/common/DateUtils';
import DownloadAppendixModal from './../../../components/common/download-appendix/DownloadAppendixDialog';
import { paginator } from './../../../components/common/paginatorConfig';
import { numberFormat } from './../../../components/common/utils';
import LoaderComponent from './../../../components/LoaderComponent';
import {
    contextPath,
    DashBoardTypes,
    reviewPageAllowedDonationStatus,
} from './../../../constants';
import { useFetchOneTimeGoodsDonations } from './../../../custom-hooks/useFetchOneTimeGoodsDonations';
import {
    useFetchSocialImpactReportMonetaryDonations,
} from './../../../custom-hooks/useFetchSocialImpactReportMonetaryDonations';
import { exportToExcel, getAppendixTypes, hanldeError } from './../../../service/apiService';
import { DownloadAppendixProps } from './../../../types/downloadPdfTypes';
import { oneTimeGoodsDonationStatuses } from './oneTimeGoodsDonationStatuses';
import SelectOptionTemplateComponent from './SelectOptionTemplateComponent';

const useStyles = makeStyles((_theme: Theme) => ({
    orgResultTbl: {
        fontFamily: 'var(--appFont) !important'
    }
}));

const DataTableComponent = (props: any) => {
    /* istanbul ignore next */
    const initlDownloadAppendixProps = {
        countryCode: props?.countryCode,
        donationDetails: {},
        appendixTypeList: [{
            label: '',
            value: ''
        }],
        showDialog: false,
        closeDailog: () => {
            setShowAppendixDownloadModal(false)
        },
        onDownload: (_data: any) => {
            setShowAppendixDownloadModal(false)
        },
        errorMessage: '',
        selectedLang: props?.selectedLang
    }
    const isSocialImpactMonetaryDashboard = props?.dashboardType === DashBoardTypes.SocialImpactMonetaryDashBoard;
    const { t } = useTranslation();
    const classes = useStyles()
    const [dateFilter, setDateFilter] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>('');
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [, setShowAppendixDownloadModal] = useState(false);
    const [, setSelectedDonation] = useState<any>();
    const [appendixMsg, setAppendixMsg] = useState<string>('');
    // @ts-ignore
    const [downloadAppendixProps, setDownloadAppendixProps] = useState<DownloadAppendixProps>(initlDownloadAppendixProps);
    const dt: any = useRef();
    const projectStatusesWithTranslation = oneTimeGoodsDonationStatuses[props?.selectedLang]
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        pageSize: 10,
        pageNum: 0,
        page: 0,
        filters: {
            ...props?.defaultFilters
        },
        countryCode: props?.countryCode,
        sortBy: 'id',
        sortOrder: -1,
        searchText: '',
        globalSearch: false
    });

    useEffect(() => {
      const defaultStatusCodes = props?.defaultFilters?.currentStatusCode?.value?.length > 0 ? [...props?.defaultFilters?.currentStatusCode?.value] : [props?.defaultFilters?.currentStatusCode?.value]
      dt.current.filter(defaultStatusCodes, 'currentStatusCode', 'equals');
      setSelectedStatus(defaultStatusCodes);
        setLazyParams({
            ...lazyParams,
            searchText: props.searchText ? props.searchText : '',
            globalSearch: props.searchText ? true : false,
            pageNum: lazyParams.page + 1,
            pageSize: lazyParams.rows,
            sortBy: lazyParams.sortBy
        })
    }, [])

    useEffect(() => {
        setLazyParams({
            ...lazyParams,
            searchText: props.searchText ? props.searchText : '',
            globalSearch: props.searchText ? true : false,
        })
    }, [props.searchText])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: results, isLoading, isFetching } = isSocialImpactMonetaryDashboard ? useFetchSocialImpactReportMonetaryDonations({
        ...lazyParams, sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC', sortBy: 'projectId'
        // eslint-disable-next-line react-hooks/rules-of-hooks
    }) : useFetchOneTimeGoodsDonations({
        ...lazyParams, sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC'
    })

    const onPage = (event: any) => {
        const _lazyParams = { ...lazyParams, ...event, pageSize: event.rows };
        /* istanbul ignore next */
        if (event.page !== lazyParams.page || event.rows !== lazyParams.rows) {
            setLazyParams(_lazyParams);
        }
    }
    /* istanbul ignore next */
    const onSort = (event: any) => {
        const _lazyParams = { ...lazyParams, ...event }

        setLazyParams({
            ..._lazyParams,
            sortBy: event?.sortField
        });
    }

    const onFilter = (event: any) => {
        const _lazyParams = { ...lazyParams, ...event };
        _lazyParams.first = 0;
        setLazyParams(_lazyParams);
    }

    const renderDateFilter = () => {
        return (
            <Calendar
                maxDate={new Date()}
                showIcon={false}
                value={dateFilter} onChange={onDateFilterChange}
                placeholder={t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.requestDate')}
                dateFormat='dd-mm-yy'
                className='p-column-filter' />
        );
    }
    /* istanbul ignore next */
    const formatDate = (date: Date) => {
        return getFormattedDate(date);
    }
    /* istanbul ignore next */
    const filterDate = (value: any, filter: any) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }
        if (value === undefined || value === null) {
            return false;
        }
        return value === formatDate(filter);
    }
    /* istanbul ignore next */
    const onDateFilterChange = (event: any) => {
        if (event.value !== null) {
            // @ts-ignore
            dt.current.filter(formatDate(event.value), 'submittedDate', 'equals');
        } else {
            // @ts-ignore
            dt.current.filter(null, 'submittedDate', 'equals');
        }
        // @ts-ignore
        setDateFilter(event.value);
    }
    /* istanbul ignore next */
    const amountBodyTemplate = (rowData: any) => {
        const requestAmount = rowData?.donationDetail?.requestAmount
        return <div>
            {requestAmount ? numberFormat(requestAmount, rowData?.countryCode) : 'NA'}
        </div>
    }
    /* istanbul ignore next */
    const onStatusFilterChange = (event: any) => {
        dt.current.filter([event.value], 'currentStatusCode', 'equals');
        setSelectedStatus(event.value);
    }

    const renderStatusFilter = () => {
        return (
            <Dropdown
                value={selectedStatus}
                optionLabel='name'
                optionValue='code'
                options={projectStatusesWithTranslation}
                onChange={onStatusFilterChange}
                itemTemplate={statusItemTemplate}
                showClear={true}
                placeholder={t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.projectStatus')}
                className='p-column-filter'
            />
        );
    }

    /* istanbul ignore next */
    const statusItemTemplate = (option: any) => {
        return <SelectOptionTemplateComponent option={option} />
    }

    /* istanbul ignore next */
    const projectStatusBody = (rowData: any) => {
        const translatedStatusCodes: any[] = oneTimeGoodsDonationStatuses[props?.selectedLang] || []
        const statusText = translatedStatusCodes.find(status => status?.code === rowData?.currentStatusCode)
        return (
            <>{statusText?.name}</>
        )
    }
    const organizationDonationStatus = ['WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT', 'WAITING_FOR_NGO_ACKNOWLEDGEMENT']

    /* istanbul ignore next */
    const viewDonationRequest = (orgDetail: any) => {
        const payload: any = {
            value: {
                ...orgDetail
            },
            key: 'ONE_TIME_GOODS'
        }
        props.addDataToStore(payload)
        if (!orgDetail?.additionalDetails?.appendixBIsFilled &&
            !reviewPageAllowedDonationStatus.includes(orgDetail?.currentStatusCode)) {
            props?.setWarningDialog(true)
            return
        }
        organizationDonationStatus.includes(orgDetail?.currentStatusCode) ?
            props.history.push(props?.reviewDonationUrl) :
            props.history.push(`${contextPath}/donation/view/one-time-goods?type=one-time-goods`);
    }
    /* istanbul ignore next */
    const closeAppendixModal = () => {
        setShowAppendixDownloadModal(false)
        // @ts-ignore
        setDownloadAppendixProps({
            ...downloadAppendixProps,
            showDialog: false
        })
    }
    /* istanbul ignore next */
    const downloadAppendix = (data: any) => {
        setShowAppendixDownloadModal(false)
        const payLoad = {
            ...data,
            donation: {
                ...data?.donation,
            },
            donationType: 'DONATION_OF_GOODS_ONE_TIME',
            langCode: data?.langCode
        }
        // @ts-ignore
        setDownloadAppendixProps({
            ...downloadAppendixProps,
            showDialog: false
        })
        setLoading(true)
        setError('')
        GlobalDonationDataService.downloadProjectFile(payLoad).then((_res) => {
            setLoading(false)
        }).catch((err: any) => {
            setShowAppendixDownloadModal(false)
            setLoading(false)
            setError(err?.response.data)
        })
    }
    /* istanbul ignore next */
    const downloadProjectDocs = (donationDetails: any) => {
        setLoading(true)
        getAppendixTypes('good-onetime', donationDetails?.id, 'DONATION_OF_GOODS_ONE_TIME').then((res: any) => {
            setLoading(false)
            if (res?.length > 0) {
                setShowAppendixDownloadModal(true)
                setDownloadAppendixProps({
                    countryCode: donationDetails?.countryCode,
                    donationDetails,
                    appendixTypeList: res,
                    showDialog: true,
                    closeDailog: closeAppendixModal,
                    onDownload: downloadAppendix,
                    errorMessage: '',
                    selectedLang: props?.selectedLang
                })
            } else {
                setShowAppendixDownloadModal(false)
                setAppendixMsg(`${t('common.infoMsg.appendixMsg')}:${new Date().getTime()}`)
            }
        }).catch((errorRes: any) => {
            setLoading(false)
            hanldeError(errorRes)
        })
        setSelectedDonation(donationDetails)
    }

    const exportBudgetDetails = () => {
        setLoading(true)
        exportToExcel({ payload: { ...lazyParams }, endpoint: '/good-onetime/excel', fileName: 'onetimegoods' }).then(_res => {
            setLoading(false)
        })
    }

    const header = (
        <div className='table-header'>
            <Button label={t('tableData.export')}
                icon='pi pi-download'
                className='p-button'
                onClick={exportBudgetDetails}
            />
        </div>
    );

    const dateFilterElement = renderDateFilter();
    const statusFilterElement = renderStatusFilter();
    const columns: any = [
        {
            field: 'id',
            header: t('oneTimeGoodsDonationDashboard.columns.projectId'),
            sortable: true,
            sortField: 'id',
            filter: true,
            filterField: 'id',
            filterMatchMode: 'equals',
            filterPlaceholder: t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.projectId'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return <div>
                    <button type='button' className='p-button p-component p-button-link'
                        // @ts-ignore
                        onClick={() => { viewDonationRequest(rowData) }}>
                        <span className='p-button-label p-c' style={{ fontWeight: 'bold' }}>{rowData?.id}</span>
                    </button>
                </div>
            },
            style: {
                width: '14em'
            }
        },
        {
            field: 'submittedDate',
            header: t('oneTimeGoodsDonationDashboard.columns.requestDate'),
            sortable: true,
            sortField: 'submittedDate',
            filter: true,
            body: (rowData: any) => {
                /* istanbul ignore next */
                return (
                    <React.Fragment>
                        <span>
                            {
                                rowData?.submittedDate ? getDateOnlyForLocale(rowData?.submittedDate) : 'NA'
                            }
                        </span>
                    </React.Fragment>
                );
            },
            filterField: 'submittedDate',
            filterMatchMode: 'custom',
            filterFunction: filterDate,
            filterElement: dateFilterElement,
            style: {
                width: '15em'
            }
        },
        {
            field: 'organization.orgLegalName',
            header: t('oneTimeGoodsDonationDashboard.columns.orgLegalName'),
            sortable: false,
            sortField: 'orgLegalName',
            filter: false,
            filterField: 'orgLegalName',
            filterPlaceholder: t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.orgName'),
            style: {
                width: '20em'
            }
        },
        {
            field: 'organization.taxId',
            header: ('oneTimeGoodsDonationDashboard.columns.taxId'),
            sortable: false,
            filter: false,
            sortField: 'taxId',
            filterField: 'taxId',
            filterPlaceholder: t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.taxId'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return rowData?.organization?.taxId ? rowData?.organization?.taxId : 'NA'
            },
            style: { width: '14em' },
        },
        {
            field: `storeNumber`,
            header: t('oneTimeGoodsDonationDashboard.columns.storeNumber'),
            sortable: false,
            filterField: 'storeNumber',
            filter: true,
            filterMatchMode: 'equals',
            filterPlaceholder: t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.storeNumber'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return rowData?.storeNumber
            },
            style: { width: '14em' },
        },
        {
            field: 'donationDetail.donationTitle',
            header: t('oneTimeGoodsDonationDashboard.columns.projectTtile'),
            sortable: false,
            style: { width: '14em' },
        },
        {
            // request amount from appendix A considered as approved amount after CA approved
            field: 'donationDetail.requestAmount',
            header: ('oneTimeGoodsDonationDashboard.columns.approvedAmount'),
            sortable: true,
            sortField: 'requestAmount',
            body: amountBodyTemplate,
            style: { width: '14em' }
        },
        {
            field: `currentStatusCode`,
            header: t('oneTimeGoodsDonationDashboard.columns.projectStatus'),
            sortable: false,
            filter: props?.showStatusFilter,
            filterField: 'currentStatusCode',
            filterElement: statusFilterElement,
            body: projectStatusBody,
            style: { width: '14em' },
        },
        {
            field: 'donationDetail.projectDocs',
            header: t('oneTimeGoodsDonationDashboard.columns.projectDocs'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return <div>
                    <button type='button' className='p-button p-component p-button-link'
                        // @ts-ignore
                        onClick={() => { downloadProjectDocs(rowData) }}>
                        <span className='p-button-label p-c' style={{ fontWeight: 'bold' }}>
                            {t('oneTimeGoodsDonationDashboard.projectDocsTxt')}
                        </span>
                    </button>
                </div>
            },
            sortable: false,
            style: { width: '14em' },
        }
    ];

    const columnList: any = isSocialImpactMonetaryDashboard ? props?.socialImpactDashboardColumns.map((column: any) => {
        return <Column key={column.field} {...{ ...column, header: t(column.header) }} />
    }) : columns?.map((column: any) => {
        return <Column key={column.field} {...{ ...column, header: t(column.header) }} />
    });



    return (<>
        <div className={`one-time-goods ${classes.orgResultTbl}`}>
            <div className='card'>
                <DataTable
                    ref={dt}
                    className='p-datatable-sm'
                    header={props?.showExportExcel ? header : ''}
                    lazy={true}
                    // @ts-ignore
                    value={results?.data}
                    dataKey='id'
                    paginator={true}
                    paginatorTemplate={paginator.paginatorTemplate}
                    currentPageReportTemplate={t('orgMngmtTbl.labels.paginationText')}
                    rows={lazyParams.pageSize}
                    rowsPerPageOptions={paginator.rowsPerPageOptions}
                    first={lazyParams.first}
                    totalRecords={results?.totalCount}
                    onPage={onPage}
                    onSort={onSort}
                    sortField={lazyParams.sortBy}
                    sortOrder={lazyParams.sortOrder as DataTableSortOrderType}
                    onFilter={onFilter}
                    filters={lazyParams.filters}
                    loading={isLoading || isFetching}
                    scrollable={true}
                    scrollHeight='620px'
                    emptyMessage={t('common.errorMesg.noRecordFound')}
                    autoLayout={true}
                    style={{
                        minBodyHeight: '450px',
                        maxBodyHeight: '600px',
                        width: '100%'
                    } as any}
                    filterDelay={600}
                >
                    {columnList}
                </DataTable>
            </div>
        </div>
        <DownloadAppendixModal {...downloadAppendixProps} />
        <ErrorSnackbarComponent isError={error ? true : false} message={t('common.errorMesg.docDownloaddError')} />
        <ErrorSnackbarComponent isError={appendixMsg ? true : false} message={appendixMsg} />
        {loading && <LoaderComponent fullScreen={true} />}
    </>
    );
}
export default React.memo(DataTableComponent);

