import { yupResolver } from '@hookform/resolvers/yup'

import { FC, memo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormSection from '../../../components/common/FormSection/FormSection'
import {
    getCompanyEmpCertificationSection,
    getDonationRecipientSection,
    getRecipientCertificationSection
} from './appendixCFormConfig'
import { getRecurringGoodsAppendixCValidationSchema } from './appendixCValidationSchema'
import { getFormattedAppendixCData } from './helper'

interface AppendixCFormProps {
    donationData: any
}
export const AppendixCHistoryForm: FC<AppendixCFormProps> = memo(({ donationData }) => {
    const { t } = useTranslation();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: getFormattedAppendixCData(donationData),
        resolver: yupResolver(getRecurringGoodsAppendixCValidationSchema())
    });
    return (<FormProvider {...methods}>
        <form
            noValidate={true}
            autoComplete={'off'}
        >
            <FormSection
                heading={t('appendices.c.donationRecipient.heading')}
                subTitle={t('appendices.c.donationRecipient.subTitle')}
                configs={getDonationRecipientSection(true, getFormattedAppendixCData(donationData))}
            />
            {donationData?.appendixC?.recipientInfo?.name &&
                <FormSection
                    heading={t('appendices.c.recipient.heading')}
                    subTitle={t('appendices.c.recipient.subTitle')}
                    configs={getRecipientCertificationSection(true, getFormattedAppendixCData(donationData))}
                />
            }
            <FormSection
                heading={t('appendices.c.companyEmployee.heading')}
                subTitle={t('appendices.c.companyEmployee.subTitle')}
                configs={getCompanyEmpCertificationSection(true, getFormattedAppendixCData(donationData), true)}
            />
        </form>
    </FormProvider>
    )
})