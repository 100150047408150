import { createStyles, Grid, IconButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import React, { FC, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormBlockHeading } from 'src/components/donations/utils';
import { downloadLocalFile } from '../utils';

import { ErrorMessageComponent } from './ErrorMessage';

const config = require(`../../../config.${process.env.NODE_ENV}.json`);


const useStyles = makeStyles(() => createStyles({
    root: {
        textAlign: 'center',
        cursor: 'pointer',
        color: '#333',
        padding: '10px',
        marginTop: '20px',
        border: '2px solid #c1c1c1',
        borderStyle: 'dotted',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '68px'
    },
    icon: {
        marginTop: '16px',
        color: '#888888',
        fontSize: '42px',
    },
    label: {
        fontSize: '16px',
        fontFamily: 'Bogle',
        fontWeight: 500
    },
    filesType: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        color: '#6d7278',
        marginTop: '8px'
    },
    tableContainer: {
        padding: '10px',
        backgroundColor: '#fbfbfb'

    },
    rowRoot: {
        '&:last-child td': {
            borderBottom: 0,
        },
        '&:last-child th': {
            borderBottom: 0,
        },
    }
}));


interface IFileUploaderProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string,
    name: string,
    fileDownloader: (data: any) => void
}

export const FileUploader: FC<IFileUploaderProps> = (props: IFileUploaderProps) => {
    const { name } = props
    const styles = useStyles();
    const { t } = useTranslation();
    const {
        register,
        unregister,
        setValue,
        getValues,
        watch,
        errors
    } = useFormContext()
    const files: File[] = watch(name)
    const onDrop = useCallback(
        (droppedFiles: any[]) => {
            let fileList: any[] = [];
            const _files = getValues(name);
            if (_files) {
                fileList = [...droppedFiles, ..._files];
            } else {
                fileList = [...droppedFiles]
            }

            setValue(name, fileList, { shouldValidate: true })
        },
        [setValue, name],
    )
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: props.accept,
    })
    useEffect(() => {
        register(name)
        return () => {
            unregister(name)
        }
    }, [register, unregister, name])

    const handleRemove = (index: number) => {
        // @ts-ignore
        const newList = files.filter((value, idx) => {
            return idx !== index;
        });

        setValue(name, newList, { shouldValidate: true })
    }

    const downloadFile = (file: any) => {
        if (file?.fileName) {
            const fileInfo = {
                fileId: file?.fileName,
                fileName: file?.fileName,
                directory: file?.uploadDir,
                container: file?.container
            }
            const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
            const dataUri = `files?fileInfo=${encryptedFileInfo}`;
            props.fileDownloader({
                name: `${file?.fileName}`,
                type: 'downloadFiles',
                actionUrl: `${config.appBaseUrl}/${dataUri}`
            });
        }
        else if (file?.name) {
            downloadLocalFile(file);
        }
    }

    return (
        <>
            <Grid container={true} item={true} xs={12} md={5}>
                <FormBlockHeading heading={t('common.fileUpload.title')} />
                <Grid container={true} item={true} xs={12}>
                    <Paper
                        variant='outlined'
                        className={styles.root}{...getRootProps()}>
                        <input

                            id={name}
                            {...getInputProps()}
                        />
                        <Typography classes={{ root: styles.label }} >{t('common.fileUpload.label')}</Typography>

                    </Paper>

                </Grid>
                <Typography classes={{ root: styles.filesType }}>{t('common.fileUpload.fileType')}</Typography>

            </Grid >
            <Grid container={true} item={true} xs={12} md={8} lg = {5} style={{ marginBottom: '10px' }}>
                {!!files?.length && (
                    <TableContainer component={Paper} elevation={3} variant='elevation' className={styles.tableContainer}>
                        <Table aria-label='attached files table' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }} >{t('common.fileUpload.columnFileName')}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((file: any, index) => {
                                    const fileName = file?.name ? file.name : file.fileName;
                                    return (
                                        <TableRow key={index} classes={{root: styles.rowRoot}}>
                                            <TableCell component='th' scope='row'>
                                                {fileName}
                                            </TableCell>
                                            <TableCell align='right' >
                                                <IconButton color='default'
                                                    aria-label='Delete File' onClick={() => downloadFile(file)} component='span'>
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                                <IconButton color='default'
                                                    aria-label='Delete File' onClick={() => handleRemove(index)} component='span'>
                                                    <DeleteIcon />
                                                </IconButton></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </Grid>
            <ErrorMessageComponent errors={errors} fieldName={name} />
        </>
    )
}