import {FC, Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomAccordion} from 'src/components/common/Accordion/CustomAccordion';
import {LoadingSkeleton} from '../common/LoadingSkeleton';
import { TabsCommonProps } from '../organizationDetails/orgDetailsDefaults';
import AttachmentsTableComponent, {IFileSnapshot} from './attachmentsTable';


interface AttachmentsTabProps extends Omit<TabsCommonProps, 'setFormStatus'> {
    files: Map<string, IFileSnapshot>
    setAttachmentFullfill: (status: boolean) => void,
    donationId: string
    lang: string
    setLasModified: (lastModified: Date) => void,
}
const AttachmentsTabComponent: FC<AttachmentsTabProps> = (props) => {

    const { t } = useTranslation();
    const [tabFulfilled, setTabFullFilled] = useState(false);
    const getTranslation = (key: string) => t(`monetaryDonation.attachmentsSection.${key}`);


    useEffect(() => {
        props.setAttachmentFullfill(tabFulfilled);
    }, [tabFulfilled])

    return <Fragment>
        <CustomAccordion defaultExpanded={true} isEditable={props?.isFormEditable} id = 'supportingDocuments'
            title={getTranslation('supportingDocuments')} hasError={!tabFulfilled}>
                {props.isLoading ? <LoadingSkeleton /> :
                    <AttachmentsTableComponent {...props} setTabFullFilled={setTabFullFilled}/>
                }
        </CustomAccordion>
    </Fragment>
}

export default AttachmentsTabComponent;