import { Action } from 'redux';

export enum ProvinceSearchActionTypes {
    PROVINCE_DATA_LOAD = 'PROVINCE_DATA_LOAD',
    PROVINCE_LOAD_SUCCESS = 'PROVINCE_LOAD_SUCCESS',
    PROVINCE_LOAD_FAILURE = 'PROVINCE_LOAD_FAILURE'
}

export interface ProvinceData extends Action {
    type: ProvinceSearchActionTypes.PROVINCE_DATA_LOAD;
    data: any;
}

export const provinceData = (data: string): ProvinceData => ({
    type: ProvinceSearchActionTypes.PROVINCE_DATA_LOAD,
    data
});

export interface ProvinceLoadSuccess extends Action {
    type: ProvinceSearchActionTypes.PROVINCE_LOAD_SUCCESS;
    data: any;
}

export const provinceLoadSuccess = (data: any): ProvinceLoadSuccess => ({
    type: ProvinceSearchActionTypes.PROVINCE_LOAD_SUCCESS,
    data
});

export interface ProvinceLoadFailure extends Action {
    type: ProvinceSearchActionTypes.PROVINCE_LOAD_FAILURE;
    data?: string;
}

export const provinceLoadFailure = (data: string): ProvinceLoadFailure => ({
    type: ProvinceSearchActionTypes.PROVINCE_LOAD_FAILURE,
    data
});

export type ProvinceSearchActions = ProvinceData | ProvinceLoadSuccess | ProvinceLoadFailure;