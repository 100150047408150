export const gridTranslations = {
    gridLocalization: {
        en: {
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}',
                labelRowsSelect: 'rows',
                labelRowsPerPage: 'Rows per page: ',
                firstAriaLabel: 'First Page',
                firstTooltip: 'First Page',
                previousAriaLabel: 'Previous Page',
                previousTooltip: 'Previous Page',
                nextAriaLabel: 'Next Page',
                nextTooltip: 'Next Page',
                lastAriaLabel: 'Last Page',
                lastTooltip: 'Last Page'
            },
            toolbar: {
                exportTitle: 'Export',
                exportAriaLabel: 'Export',
                exportName: 'Export as CSV/Excel',
                searchTooltip: 'Search',
                searchPlaceholder: 'Search with project id',
                nRowsSelected: '{0} record(s) selected'
            },
            header: {
                actions: 'Actions'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            },
            viewTooltip: 'Click to view details',
            editTooltip: 'Edit details',
            deleteTooltip: 'Delete',
            refreshDataTooltip: 'Refresh Data',
        },
        es: {
            pagination: {
                labelDisplayedRows: '{from} - {to} of {count}',
                labelRowsSelect: 'filas',
                labelRowsPerPage: 'Filas por página:',
                firstAriaLabel: 'Primera página',
                firstTooltip: 'Primera página',
                previousAriaLabel: 'Página anterior',
                previousTooltip: 'Página anterior',
                nextAriaLabel: 'Página siguiente',
                nextTooltip: 'Página siguiente',
                lastAriaLabel: 'Última página',
                lastTooltip: 'Última página'
            },
            toolbar: {
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exporta',
                exportName: 'Exportar como CSV / Excel',
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar con ID del proyecto',
                nRowsSelected: '{0} record(s) selected'
            },
            header: {
                actions: 'Comportamiento'
            },
            body: {
                emptyDataSourceMessage: 'No hay registros que mostrar',
                filterRow: {
                    filterTooltip: 'Filtrar'
                }
            },
            viewTooltip: 'Haga clic para ver los detalles',
            editTooltip: 'Editar detalles',
            deleteTooltip: 'Eliminar',
            refreshDataTooltip: 'Actualizar datos',
        },
        fr: {
            pagination: {
                labelDisplayedRows: '{from} - {to} of {count}',
                labelRowsSelect: 'lignes',
                labelRowsPerPage: 'Lignes par page:',
                firstAriaLabel: 'Première page',
                firstTooltip: 'Première page',
                previousAriaLabel: 'Page précédente',
                previousTooltip: 'Page précédente',
                nextAriaLabel: 'Page suivante',
                nextTooltip: 'Page suivante',
                lastAriaLabel: 'Dernière page',
                lastTooltip: 'Dernière page'
            },
            toolbar: {
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportName: 'Exporter au format CSV / Excel',
                searchTooltip: 'Chercher',
                searchPlaceholder: 'Rechercher avec l\'identifiant du projet',
                nRowsSelected: '{0} enregistrement (s) sélectionné (s)'
            },
            header: {
                actions: 'Actions'
            },
            body: {
                emptyDataSourceMessage: 'Aucun enregistrement à afficher',
                filterRow: {
                    filterTooltip: 'Filtre'
                }
            },
            viewTooltip: 'Cliquez pour voir les détails',
            editTooltip: 'Modifier les détails',
            deleteTooltip: 'Supprimer',
            refreshDataTooltip: 'Actualiser les données',
        },
        ja:{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}',
                labelRowsSelect: '行',
                labelRowsPerPage: 'ページあたりの行数:',
                firstAriaLabel: '最初のページ',
                firstTooltip: '最初のページ',
                previousAriaLabel: '前のページ',
                previousTooltip: '前のページ',
                nextAriaLabel: '次のページ',
                nextTooltip: '次のページ',
                lastAriaLabel: '最終ページ',
                lastTooltip: '最後のページ'
            },
            toolbar: {
                exportTitle: 'Export',
                exportAriaLabel: 'Export',
                exportName: 'Export as CSV/Excel',
                searchTooltip: 'Search',
                searchPlaceholder: 'プロジェクトIDで検索',
                nRowsSelected: '{0} record(s) selected'
            },
            header: {
                actions: 'Actions'
            },
            body: {
                emptyDataSourceMessage: '表示するレコードがありません',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            },
            viewTooltip: 'Click to view details',
            editTooltip: 'Edit details',
            deleteTooltip: 'Delete',
            refreshDataTooltip: 'Refresh Data',
        },
        zh:{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}',
                labelRowsSelect: '行',
                labelRowsPerPage: '每页行数:',
                firstAriaLabel: '首页',
                firstTooltip: '首页',
                previousAriaLabel: '上一页',
                previousTooltip: '上一页',
                nextAriaLabel: '下一页',
                nextTooltip: '下一页',
                lastAriaLabel: '最后一页',
                lastTooltip: '最后一页'
            },
            toolbar: {
                exportTitle: 'Export',
                exportAriaLabel: 'Export',
                exportName: 'Export as CSV/Excel',
                searchTooltip: 'Search',
                searchPlaceholder: '搜索项目编号',
                nRowsSelected: '{0} record(s) selected'
            },
            header: {
                actions: 'Actions'
            },
            body: {
                emptyDataSourceMessage: '无记录可显示',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            },
            viewTooltip: 'Click to view details',
            editTooltip: 'Edit details',
            deleteTooltip: 'Delete',
            refreshDataTooltip: 'Refresh Data',
        }
    }
}

export const socialImpactgridTranslations = {
    gridLocalization: {
        en: {
            ...gridTranslations.gridLocalization.en,
            toolbar:{
                ...gridTranslations.gridLocalization.en.toolbar,
                searchPlaceholder:'Search'
            }
        },
        es: {
            ...gridTranslations.gridLocalization.es,
            toolbar: {
                ...gridTranslations.gridLocalization.es.toolbar,
                searchPlaceholder: 'Buscar'
            }
        },
        fr: {
            ...gridTranslations.gridLocalization.fr,
            toolbar: {
                ...gridTranslations.gridLocalization.fr.toolbar,
                searchPlaceholder: 'Rechercher'
            }
        },
        ja: {
            ...gridTranslations.gridLocalization.ja,
            toolbar: {
                ...gridTranslations.gridLocalization.ja.toolbar,
                searchPlaceholder: '探す'
            }
        },
        zh: {
            ...gridTranslations.gridLocalization.zh,
            toolbar: {
                ...gridTranslations.gridLocalization.zh.toolbar,
                searchPlaceholder: '搜索'
            }
        }
    }
}