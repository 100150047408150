import { IconButton, Paper, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FC } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputTextField } from 'src/components/common/Input/InputTextField';
import { FileUploader } from '../../../common/formComponents/FilesUploader';
import { SectionContainer } from '../../donationOfMoney/sectionsContainer';
import AddRow from '../common/AddRow';
import BiLingualDropdown, { BiLingualDropdownTypes } from '../common/biLingualDropdown';
import { CustomCell } from '../common/CustomCell';
import { CustomDivider } from '../common/CustomDivider';
import MoreDetailsTable from '../common/MoreDetailsTable';
import { IncomeTotals } from './incomeTotalsTable';
import { DefaultOrganizationIncome, Sections } from './orgDetailsDefaults';


interface OrganizationIncomeProps {
    fieldName?: string,
    fileDownloader: (data: any) => void;
}


const columnHeader = [{ key: 'incType', width: '24%' },
{ key: 'donorsCount', width: '24%' }, { key: 'annIncome', width: '24%' },
{ key: 'percentage', width: '24%' }, { key: 'actions', width: '6%' }];


export const OrganizationIncome: FC<OrganizationIncomeProps> = React.memo(({ fileDownloader }) => {
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);

    const { control, errors } = useFormContext();

    const currentFinancialYearFields = useFieldArray({
        name: Sections.currentIncome,
        control
    });

    const previousFinancialYearFields = useFieldArray({
        name: Sections.previousIncome,
        control
    });


    const currentYearWatcher = useWatch<any>({ control, name: Sections.currentIncome });
    const previousYearWatcher = useWatch<any>({ control, name: Sections.previousIncome });


    return (<>
        <SectionContainer title={getTranslation('currFin')}>
            <CustomTable fields={currentFinancialYearFields.fields}
                id={'currentFinancialYearTable'}
                t={t}
                fieldName={Sections.currentIncome}
                removeRowHandler={currentFinancialYearFields.remove}
                defaultValues={DefaultOrganizationIncome.currentFinancialYear[0]}
                addRowhandler={currentFinancialYearFields.append}
                ariaLabel={'Organization Income Current Financial Year Table'}
                totalFieldName={'totals.incomeCurrentFinancialYear'}
                watchedFields={currentYearWatcher}
                getTranslation={getTranslation}
                control={control}
                errors={errors} />
        </SectionContainer>
        <SectionContainer title={getTranslation('prevFin')}>
            <CustomTable fields={previousFinancialYearFields.fields}
                id={'previousFinancialYearTable'}
                t={t}
                defaultValues={DefaultOrganizationIncome.previousFinancialYear[0]}
                fieldName={Sections.previousIncome}
                removeRowHandler={previousFinancialYearFields.remove}
                addRowhandler={previousFinancialYearFields.append}
                ariaLabel={'Organization Income Previous Financial Year Table'}
                totalFieldName={'totals.incomePreviousFinancialYear'}
                watchedFields={previousYearWatcher}
                getTranslation={getTranslation}
                control={control}
                errors={errors} />
            <FileUploader name={Sections.incomeAttachments} fileDownloader={fileDownloader} />
        </SectionContainer>
    </>
    );
})


interface CustomIncomeComponentProps {
    fields: any;
    fieldName: any;
    removeRowHandler: any;
    addRowhandler: any;
    defaultValues: any;
    t: any;
    id: any;
    ariaLabel?: any;
    watchedFields: any;
    getTranslation: any;
    totalFieldName: any;
    control: any;
    errors: any;
}

const CustomIncomeComponent: FC<CustomIncomeComponentProps> = ({ fields, fieldName, removeRowHandler, addRowhandler, defaultValues, t, id,
    watchedFields, getTranslation, totalFieldName, control, errors }) => {
    const styles = { maxWidth: '870px', padding: '15px', backgroundColor: '#fbfbfb' }
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField')
    return (<>
        <Paper elevation={3} variant='elevation' style={styles}>
            <MoreDetailsTable
                getTranslation={getTranslation}
                id={id}
                header={columnHeader}>
                {fields.map((field: any, index: number) => {
                    const errorKey = `${fieldName}.${index}`;
                    return (<TableRow key={field.id} >
                        <CustomCell>
                            <BiLingualDropdown
                                fieldName={`${fieldName}[${index}].type`}
                                id={field.id}
                                optionKey={BiLingualDropdownTypes.INCOMES_TYPE}
                                errorKeyName={`${errorKey}.type`}
                                control={control}
                                errors={errors}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <InputTextField
                                fieldName={`${fieldName}[${index}].numberOfDonors`}
                                id={field.id}
                                type={'number'}
                                defaultValue={field.numberOfDonors}
                                errorKeyName={`${errorKey}.numberOfDonors`}
                                rules={{
                                    required: requiredFieldMsg,
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <InputTextField
                                fieldName={`${fieldName}[${index}].amount`}
                                id={field.id}
                                type={'number'}
                                defaultValue={field.amount}
                                errorKeyName={`${errorKey}.amount`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <InputTextField
                                fieldName={`${fieldName}[${index}].percentage`}
                                id={field.id}
                                type={'number'}
                                defaultValue={field.percentage}
                                errorKeyName={`${errorKey}.percentage`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <IconButton color='default' id={field.id} onClick={() => removeRowHandler(index)}
                                disabled={fields?.length === 1}
                                data-testid='delete-row'
                                aria-label='delete row' component='span'>
                                <DeleteIcon />
                            </IconButton>
                        </CustomCell>
                    </TableRow>)
                })}
            </MoreDetailsTable>
            <IncomeTotals watchedFields={watchedFields} getTranslation={getTranslation}
                totalFieldName={totalFieldName} />
            <CustomDivider />
            <AddRow
                handler={() => addRowhandler(defaultValues)}
                key={id}
                t={t}
            />
        </Paper>
    </>
    );
};
const CustomTable = React.memo(CustomIncomeComponent);