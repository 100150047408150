import { FC } from 'react';
import './Button.css';

interface Props {
  id?: string,
  className?: string,
  type: 'reset' | 'button' | 'submit',
  title?: string,
  onClick?: () => void,
  onSubmit?: () => void,
  disabled?: boolean,
  children?: any,
  style?: any
}

const Button: FC<Props> = ({
  className = '', title = '', disabled = false, children = null, ...props
}) => {
  return (
    <button
      style={{ ...props?.style }}
      className={className}
      disabled={disabled ? disabled : false}
      onSubmit={props?.onSubmit}
      {...props}
    >
      {title && title.length > 0 && title}
      {children}
    </button>
  )
}
export default Button;