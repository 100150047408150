import React, { FC } from 'react';
import './Heading.css';

interface Props {
  id?: string,
  className?: string,
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  decoration?: string,
  children?: React.ReactNode,
}

const Heading: FC<Props> = ({
id = '', level, ...props
}) => {
  const Level = level;
  return (
    <Level
      className={props?.className}
      {...props}
    >
      {props?.children}
    </Level>
  );
};

export const CapitalizedHeading: FC<Props> = ({
  id = '', decoration = '', level, ...props
}) => (
  <Heading
    className={props?.className}
    decoration={`capitalized ${decoration || ''}`}
    level={level}
    {...props}
  >
    {props?.children}
  </Heading>
);
export default Heading;