import { Action } from 'redux';

export enum orgProfileActionTypes {
    ORG_PROFILE = 'ORG_PROFILE',
    ORG_PROFILE_SUCCESS = 'ORG_PROFILE_SUCCESS',
    ORG_PROFILE_FAILURE = 'ORG_PROFILE_FAILURE',
    SAVE_ORG_PROFILE = 'SAVE_ORG_PROFILE',
    SAVE_ORG_PROFILE_SUCCESS = 'SAVE_ORG_PROFILE_SUCCESS',
    SAVE_ORG_PROFILE_FAILURE = 'SAVE_ORG_PROFILE_FAILURE',
    CITY_STATE_MAPPING_DATA_LOAD = 'CITY_STATE_MAPPING_DATA_LOAD',
    CITY_STATE_MAPPING_DATA_LOAD_SUCCESS = 'CITY_STATE_MAPPING_DATA_LOAD_SUCCESS',
    CITY_STATE_MAPPING_DATA_LOAD_FAILURE = 'CITY_STATE_MAPPING_DATA_LOAD_FAILURE',
    COUNTRIES_DATA_LOAD_FAILURE='COUNTRIES_DATA_LOAD_FAILURE',
    COUNTRIES_DATA_LOAD_SUCCESS= 'COUNTRIES_DATA_LOAD_SUCCESS'
}

/* Get organization profile */

export interface GetOrgProfile extends Action {
    type: orgProfileActionTypes.ORG_PROFILE;
    data: string;
}

export const getOrgProfile = (data: string): GetOrgProfile => ({
    type: orgProfileActionTypes.ORG_PROFILE,
    data
});

export interface GetOrgProfileSuccess extends Action {
    type: orgProfileActionTypes.ORG_PROFILE_SUCCESS;
    data: any;
}

export const getOrgProfileSuccess = (data: any): GetOrgProfileSuccess => ({
    type: orgProfileActionTypes.ORG_PROFILE_SUCCESS,
    data
});

export interface GetOrgProfileFailure extends Action {
    type: orgProfileActionTypes.ORG_PROFILE_FAILURE;
    data?: string;
}

export const getOrgProfileFailure = (data: string): GetOrgProfileFailure => ({
    type: orgProfileActionTypes.ORG_PROFILE_FAILURE,
    data
});

export interface SaveOrgProfile extends Action {
    type: orgProfileActionTypes.SAVE_ORG_PROFILE;
    data: string;
}

export const saveOrgProfile = (data: string): SaveOrgProfile => ({
    type: orgProfileActionTypes.SAVE_ORG_PROFILE,
    data
});

export interface SaveOrgProfileSuccess extends Action {
    type: orgProfileActionTypes.SAVE_ORG_PROFILE_SUCCESS;
    data: any;
}

export const saveOrgProfileSuccess = (data: any): SaveOrgProfileSuccess => ({
    type: orgProfileActionTypes.SAVE_ORG_PROFILE_SUCCESS,
    data
});

export interface SaveOrgProfileFailure extends Action {
    type: orgProfileActionTypes.SAVE_ORG_PROFILE_FAILURE;
    data?: string;
}

export const saveOrgProfileFailure = (data: string): SaveOrgProfileFailure => ({
    type: orgProfileActionTypes.SAVE_ORG_PROFILE_FAILURE,
    data
});

export interface CityStateMappingDataLoad extends Action {
    type: orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD;
    data: any;
}

export const cityStateMappingDataLoad = (data: string): CityStateMappingDataLoad => ({
    type: orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD,
    data
});

export interface CityStateMappingDataLoadSuccess extends Action {
    type: orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD_SUCCESS;
    data: any;
}

export const cityStateMappingDataLoadSuccess = (data: any): CityStateMappingDataLoadSuccess => ({
    type: orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD_SUCCESS,
    data
});

export interface CityStateMappingDataLoadFailure extends Action {
    type: orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD_FAILURE;
    data?: any;
}

export const cityStateMappingDataLoadFailure = (data: string): CityStateMappingDataLoadFailure => ({
    type: orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD_FAILURE,
    data
});

export interface CountriesDataLoadFailure extends Action {
    type: orgProfileActionTypes.COUNTRIES_DATA_LOAD_FAILURE;
    data: string;
}

export const countriesDataLoadFailure = (data: string): CountriesDataLoadFailure => ({
    type: orgProfileActionTypes.COUNTRIES_DATA_LOAD_FAILURE,
    data
});

export interface CountriesDataLoadSuccess extends Action {
    type: orgProfileActionTypes.COUNTRIES_DATA_LOAD_SUCCESS;
    data: string;
}

export const countriesDataLoadSuccess = (data: string): CountriesDataLoadSuccess => ({
    type: orgProfileActionTypes.COUNTRIES_DATA_LOAD_SUCCESS,
    data
});


export type orgProfileActions = GetOrgProfile | GetOrgProfileSuccess |
    GetOrgProfileFailure | SaveOrgProfile | SaveOrgProfileSuccess | SaveOrgProfileFailure |
    CityStateMappingDataLoad | CityStateMappingDataLoadSuccess | CityStateMappingDataLoadFailure |
    CountriesDataLoadSuccess | CountriesDataLoadSuccess;