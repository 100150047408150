

import { CheckCircle } from '../../../commonComponents/icons/icon';
import Divider from '../../../commonComponents/uiComponents/LDDivider';
import Rating from '../../../commonComponents/appComponents/emojiRating/emojiRating';
import TextArea from '../../../commonComponents/uiComponents/LDTextArea';


const questionByRating: any = {
    0: '',
    1: 'what went wrong?',
    2: 'What could have been better?',
    3: 'What did you love about your experiences?'
}

const RatingForm: any = (props: any) => {
    return <div className='feedback-rating-form'>
        {!props.isNew && <div className='feedback-rating-form-section-1'>
            <CheckCircle className='checkout-success-icon' />
            <div className='checkout-text-header'>You are checked out</div>
            <div className='checkout-text-info'>
                Thank you for hosting your event! We’ll let the store manager know that your event has ended.

            </div>
        </div>}
        <Divider />
        <div className='checkout-rate-exp-text-1'>
            <div>Rate your experience at </div>
            <div>{props.store.city} - {props.store.storeName} {props.store.storeNbr}</div>
        </div>
        <div className='checkout-rate-exp-text-2'>Your feedback will help improve the experience</div>
        <Rating setRating={props.setRating} rating={props.rating} />
        <div className='checkout-text-info'>{questionByRating[props.rating]}</div>
        <TextArea label='Write a comment' onChange={(e: any) => { props.setRatingComment(e.target.value) }}
            value={props.ratingComment} />
    </div>
}
export default RatingForm;