import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { FALLBACK_LNG, DEV_LOAD_PATH, PROD_LOAD_PATH } from './constants';

const instance = i18n.createInstance();

instance.use(Backend)
    .use(initReactI18next)
    .init({
        nsSeparator: '|',
        fallbackLng: FALLBACK_LNG,
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: process.env.NODE_ENV === 'production' ? PROD_LOAD_PATH : DEV_LOAD_PATH,
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            useSuspense: false,
        }
    });

export default instance;
