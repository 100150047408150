import { IActivityWithHistory, ISocialImpactActivity, MonetarySocialImpactSections } from '../socialImpactReportData';
import { FC, useEffect, useState } from 'react';
import { Box, Grid, Typography, makeStyles, Paper } from '@material-ui/core';
import GridItem from '../../../common/Grid/GridItem';
import { InputTextField } from '../../../common/Input/InputTextField';
import { useFormContext } from 'react-hook-form';
import TableFileHandler from '../TableFileHandler';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../../common/utils';
import { countryCodeToCurrenccySymbol, activitiesOption } from 'src/constants';
import KeyValueDropdown, { IDropdownOptions, IMLDropdownOption } from 'src/components/common/Dropdown/keyValueDropdown';
import { IFileDetails } from 'src/components/donations/moreDetailsSections/attachments/attachmentsTable';
import TextFieldMaxSizeIndicator from 'src/components/donations/moreDetailsSections/common/TextFieldMaxSizeIndicator';
import { getStatusIcon } from './utils'


interface OwnProps {
  index: number
  field: IActivityWithHistory
  selectedLang: string
  reportId: string
  finalized: boolean
  activity: ISocialImpactActivity
  countryCode: string
  getFileDetails: (sno: number, fileDetails: IFileDetails) => void

}

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold'
  },
  paper: { padding: '15px', backgroundColor: '#fbfbfb' },
  highLightRow: {
    background: '#ebebeb'
  },
  border: {
    justifyContent: 'space-between',
    borderBottom: '1px solid rgb(212, 212, 212)',
    padding: '10px'
  },
  borderNone: {
    outline: 'none',
    margin: '0px',
    padding: '0px'
  }


})

const activitiesOptn: IMLDropdownOption[] = [
  {
    keyText: 'NOT_STARTED',
    language: {
      en: 'Not Started',
      es: 'No empezado',
      fr: 'Pas commencé',
      ja: '始まっていない',
      zh: '没有开始'
    },
  }, {
    keyText: 'IN_PROGRESS',
    language: {
      en: 'In Progress',
      es: 'En curso',
      fr: 'En cours',
      ja: '進行中',
      zh: '进行中'
    },
  }, {
    keyText: 'COMPLETED',
    language: {
      en: 'Completed',
      es: 'Terminado',
      fr: 'Complété',
      ja: '完了',
      zh: '完全的'
    },
  }
]

const findSum = (field: IActivityWithHistory): number => {
  let sum = 0;
  field.list?.map((history) => {
    sum += history.amountUsed ? Number(history.amountUsed) : 0
  });
  const totalAmount = field.amount
  return totalAmount - sum;
}

const ActivitiesHistory: FC<OwnProps> = ({ field, index, selectedLang, reportId, finalized, countryCode, getFileDetails }) => {
  const fieldName = MonetarySocialImpactSections.Activities;
  const [activityStatusOptions, setOptions] = useState<IDropdownOptions[]>([]);
  const { control, errors, register, watch, setValue } = useFormContext();
  const { t } = useTranslation();
  const [total, setTotal] = useState(findSum(field));
  const getCurrencyFormat = (amount: number) => currencyFormat(amount || 0, countryCodeToCurrenccySymbol[countryCode]);
  const readOnly = watch('readOnly')

  const currentAmount = watch(`${fieldName}.activityForSocialReport[${index}].amountUsed`)


  useEffect(() => {
    if(+currentAmount === total) {
      setValue(`${fieldName}.activityForSocialReport[${index}].activityStatus`, activitiesOptn[2].keyText)
    }
  }, [currentAmount])

  useEffect(() => {
    setTotal(findSum(field));
  }, [field])

  const classes = useStyles();
  useEffect(() => {
    const options = activitiesOptn.map((opt: any) => ({
      label: opt.language[selectedLang] ?? opt.language.en,
      value: opt.keyText
    }));
    setOptions(options);
  }, [selectedLang])



  return <Box sx={{ width: '100%' }}>
    <Paper classes={{ root: classes.paper }}>
      <Grid container={true} xs={12} spacing={1}>
        <GridItem lg={6} md={12}>
          <Grid container={true} xs={12} spacing={1} classes={{ root: classes.title }} >
            <GridItem xs={1}>
              {t('monetaryDonation.projectDetailsSection.activityNumber')}
            </GridItem>
            <GridItem xs={3}>
              {t('socialImpactReport.description')}
            </GridItem>
            <GridItem xs={2}>
              {t('monetaryDonation.projectDetailsSection.startDate')}
            </GridItem>
            <GridItem xs={2}>
              {t('monetaryDonation.projectDetailsSection.endDate')}
            </GridItem>
            <GridItem xs={2}>
              {t('monetaryDonation.projectDetailsSection.amount')}
            </GridItem>
            <GridItem xs={2}>
              {t('oneTimeGoodsDonationDashboard.balanceTxt')} $
            </GridItem>
          </Grid>
          <Grid container={true} xs={12} spacing={1}>
            <GridItem xs={1}
              style={{ marginTop: '0.5rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {field.number}
            </GridItem>
            <GridItem xs={3}>
              <InputTextField
                required={false}
                fieldName={`${fieldName}.activityForSocialReport[${index}].description`}
                errorKeyName={`${fieldName}.activityForSocialReport[${index}].description`}
                id={`${fieldName}.activityForSocialReport[${index}].description`}
                multiline={true}
                rows={3}
                InputProps={{ readOnly: true }}
                defaultValue={field.description ?? ''}
              />
            </GridItem>
            <GridItem xs={2} style={{ marginTop: '0.5rem' }}>
              {new Date(field.startDate as unknown as string).toLocaleDateString()}
            </GridItem>
            <GridItem xs={2} style={{ marginTop: '0.5rem' }}>
              {new Date(field.endDate as unknown as string)?.toLocaleDateString()}
            </GridItem>
            <GridItem xs={2}>
              <InputTextField
                required={false}
                fieldName={`${fieldName}.activityForSocialReport[${index}].amount`}
                errorKeyName={`${fieldName}.activityForSocialReport[${index}].amount`}
                id={`${fieldName}.activityForSocialReport[${index}].amount`}
                multiline={false}
                rows={3}
                InputProps={{ readOnly: true }}
                defaultValue={field.amount ?? ''}
              />
            </GridItem>
            <GridItem xs={2} style={{ marginTop: '0.5rem' }}>
              <input type='hidden' value={total}
                // @ts-ignore
                name={`${fieldName}.activityForSocialReport[${index}].balance`} ref={register} />
              <b>{getCurrencyFormat(total ?? 0)}
              </b>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem lg={6} md={12} >
        <Grid container={true} xs={12} spacing={1} classes={{ root: classes.title }} >
            <GridItem xs={2}>
              {t('socialImpactReport.amountUsed')}
            </GridItem>
            <GridItem xs={4}>
              {t('common.labels.comment')}
            </GridItem>
            <GridItem xs={2}>
              {t('socialImpactReport.status')}
            </GridItem>
            <GridItem xs={4}>
              {t('monetaryDonation.tabs.attachment')}
            </GridItem>
          </Grid>
          {field.activityStatus !== 'COMPLETED' && !readOnly &&
          <>
          <Grid container={true} xs={12} spacing={1} >
            <GridItem xs={2}>
              <InputTextField
                required={true}
                type={'number'}
                fieldName={`${fieldName}.activityForSocialReport[${index}].amountUsed`}
                errorKeyName={`${fieldName}.activityForSocialReport[${index}].amountUsed`}
                id={`${fieldName}.activityForSocialReport[${index}].amountUsed`}
                InputProps={{ readOnly: field.activityStatus === 'COMPLETED' ? true : false }}
                disabled={field.activityStatus === 'COMPLETED' ? true : false}
                defaultValue={field.amountUsed ?? 0}
              />
            </GridItem>
            <GridItem xs={4}>
              <InputTextField
                required={true}
                fieldName={`${fieldName}.activityForSocialReport[${index}].comment`}
                errorKeyName={`${fieldName}.activityForSocialReport[${index}].comment`}
                id={`${fieldName}.activityForSocialReport[${index}].comment`}
                multiline={true}
                rows={3}
                InputProps={{ readOnly: field.activityStatus === 'COMPLETED' ? true : false }}
                disabled={field.activityStatus === 'COMPLETED' ? true : false}
                defaultValue={field.comment ?? ''}
                rules={{
                  required: t('common.validationMsg.requiredField'),
                }}

              />
              <TextFieldMaxSizeIndicator
                fieldName={`${fieldName}.activityForSocialReport[${index}].comment`}
                maxSize={1000} />
            </GridItem>
            <GridItem xs={2} style={{ marginTop: '15px' }}>
              <KeyValueDropdown
                id={`activityStatus_${index}`}
                fieldName={`${fieldName}.activityForSocialReport[${index}].activityStatus`}
                defaultValue={field.activityStatus ?? 'NOT_STARTED'}
                options={activityStatusOptions}
                control={control}
                errors={errors}
                errorKeyName={`${fieldName}.activityForSocialReport[${index}].activityStatus`}
                disabled={field.activityStatus === 'COMPLETED' ? true : false}
              />

            </GridItem>
            <GridItem xs={4}>
              <TableFileHandler
                sno={field.sno}
                fileDetails={field.activityAttachment}
                reportId={reportId}
                readOnly={finalized}
                disabled={field.activityStatus === 'COMPLETED' ? true : false}
                getFileDetails={getFileDetails} />
            </GridItem>
          </Grid>
          </>
          }
          {
            field.list?.map((history) =>
            (<Grid container={true} xs={12} spacing={1} classes={{ root: classes.border }}>
              <GridItem xs={2} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {history.amountUsed}
              </GridItem>
              <GridItem xs={4} style={{ wordBreak: 'break-word' }}>
                {history.comment}
              </GridItem>
              <GridItem xs={2}>
                <span style={{
                  backgroundColor: history.activityStatus === 'IN_PROGRESS' ?
                    '#FFF9E9' : history.activityStatus === 'COMPLETED' ? '#EAF3E6' : '',
                  color: history.activityStatus === 'IN_PROGRESS' ? '#df982f' : history.activityStatus === 'COMPLETED' ? '#7DA46D' : '',
                }}
                >
                  {getStatusIcon(history.activityStatus)}
                  {activitiesOption[history.activityStatus][selectedLang]}
                </span>
              </GridItem>
              <GridItem xs={4} zeroMinWidth={true}>
                <Typography style={{ display: 'flex' }}>
                  <TableFileHandler
                    sno={field.sno}
                    fileDetails={history.activityAttachment}
                    reportId={reportId}
                    readOnly={true}
                    disabled={false}
                    getFileDetails={getFileDetails} />
                </Typography>
              </GridItem>
            </Grid>
            ))

          }
        </GridItem>
      </Grid>
    </Paper>
  </Box >
};

export default ActivitiesHistory;
