
import { Modal } from '@walmart-web/livingdesign-components';

declare global {
    interface Window {
        modalBoxComments: any
    }
}

const LDModal = (props: any) => {
    return <Modal {...props} />
}
export default LDModal;