import {
    Box,
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core';
import {FC, Fragment} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormBlockHeading} from 'src/components/donations/utils';
import AddRow from '../common/AddRow';

import {DefaultProjectLocation} from './projectDetailsDefaults';
import ProjectLocationRow from './projectLocationRow';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    title: {
        fontWeight: 'bold'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectRoot: {
        color: 'rgba(0, 0, 0, 0.87) !important',
    },
    cell: {
        padding: '6px ',
        verticalAlign: 'baseline'
    },
    cellHead: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
        '&:not(:last-child):after': {
            content: '"  *"'
        },
    },
    selectIcon:  {
        color: '#181818 !important'
    },
    selectRootLabel : {
        top: '-8px',
        left: '15px'
    }
}));

export interface IProjectLocation {
    fieldName: string,
    data: any
}

const colummHeader = [{ key: 'geographicArea', width: '20%' }, { key: 'othersInGeographicArea', width: '20%' },
    { key: 'state', width: '20%' },
    { key: 'city', width: '20%' }
]

export const ProjectLocation: FC<IProjectLocation> = ({ fieldName }) => {
    const { control, errors } = useFormContext();
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
    const geoCoverageFields = useFieldArray({
        name: fieldName,
        control
    })
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField')
    const classes = useStyles();
    const handleRemove = (index: number) => {
        geoCoverageFields.remove(index);
    }

    return (
        <Fragment>
            <FormBlockHeading heading={getTranslation('locations')} />
            <Box style={{
                width: '100%', padding: '10px',
                boxShadow: 'box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1)', borderRadius: '6px'
            }}
                 bgcolor='#fbfbfb'>
                <TableContainer >
                    <Table aria-label='Project Location Table' size='small'>
                        <TableHead>
                            <TableRow>
                                {colummHeader.map((column, index) => {

                                    return (<TableCell classes={{ head: classes.cellHead }} key={`header_${index}`}
                                                       style={{ width: column.width }}>
                                        {column.key === 'actions' ? null : getTranslation(column.key)}
                                    </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {geoCoverageFields.fields.map((field, index) => {
                                const errorKey = `${fieldName}.${index}`;
                                const disableDelete = geoCoverageFields.fields && geoCoverageFields.fields .length <= 1;
                                return <ProjectLocationRow key = {field.id}
                                                           control={control}
                                                           errors = {errors}
                                                           index = {index}
                                                           errorKey={errorKey}
                                                           field = {field}
                                                           fieldName = {fieldName}
                                                           handleRemove= {handleRemove}
                                                           disableDelete = {disableDelete}
                                                           requiredFieldMsg = {requiredFieldMsg}  />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <AddRow
                    handler = {() => geoCoverageFields.append(DefaultProjectLocation)}
                    key = 'addProjectLocation'
                    t = {t}
                />
            </Box>
        </ Fragment>
    );
}
