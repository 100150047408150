import { orgProfileActionTypes, orgProfileActions } from './orgProfileActions';
import { OrgProfile } from '../../models/orgProfile';

export const defaultOrgProfile: OrgProfile = {
    orgProfile: {},
    getOrgProfileLoading: false,
    getOrgProfileFailure: false,
    getOrgProfileSuccess: false,
    saveOrgProfileLoading: false,
    saveOrgProfileFailure: false,
    saveOrgProfileSuccess: false,
    notificationMsg: '',
    cityStateMapping: [],
    cityStateMappingLoading: false,
    cityStateMappingSuccess: false,
    cityStateMappingFailure: false,
};

export const orgProfileReducer = (state: OrgProfile = defaultOrgProfile, action: orgProfileActions):
    OrgProfile => {
    switch (action.type) {
        case orgProfileActionTypes.ORG_PROFILE: return {
            ...state,
            notificationMsg: '',
            getOrgProfileLoading: true,
            getOrgProfileSuccess: false,
            getOrgProfileFailure: false,
        };
        case orgProfileActionTypes.ORG_PROFILE_SUCCESS: return {
            ...state,
            orgProfile: action.data,
            notificationMsg: '',
            getOrgProfileLoading: false,
            getOrgProfileFailure: false,
            getOrgProfileSuccess: true,
        };
        case orgProfileActionTypes.ORG_PROFILE_FAILURE: return {
            ...state,
            notificationMsg: action.data,
            getOrgProfileLoading: false,
            getOrgProfileFailure: true,
            getOrgProfileSuccess: false,
        };
        case orgProfileActionTypes.SAVE_ORG_PROFILE: return {
            ...state,
            notificationMsg: '',
            saveOrgProfileLoading: true,
            saveOrgProfileSuccess: false,
            saveOrgProfileFailure: false,
        };
        case orgProfileActionTypes.SAVE_ORG_PROFILE_SUCCESS: return {
            ...state,
            notificationMsg: '',
            orgProfile: action.data,
            saveOrgProfileLoading: false,
            saveOrgProfileFailure: false,
            saveOrgProfileSuccess: true,
        };
        case orgProfileActionTypes.SAVE_ORG_PROFILE_FAILURE: return {
            ...state,
            notificationMsg: action.data,
            saveOrgProfileLoading: false,
            saveOrgProfileFailure: true,
            saveOrgProfileSuccess: false,
        };
        case orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD: return {
            ...state,
            cityStateMapping: [],
            cityStateMappingLoading: true,
            cityStateMappingSuccess: false,
            cityStateMappingFailure: false,
        };
        case orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD_SUCCESS: return {
            ...state,
            cityStateMapping: action.data,
            cityStateMappingLoading: false,
            cityStateMappingSuccess: true,
            cityStateMappingFailure: false,
        };
        case orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD_FAILURE: return {
            ...state,
            cityStateMapping: [],
            cityStateMappingLoading: false,
            cityStateMappingSuccess: false,
            cityStateMappingFailure: true,
        };

        default: return state;
    }
}