import { useMutation, useQuery } from "react-query";

import { contextPath, reactQueryConfigOptions } from "../../../../constants";
import {
  getDonationsForDashboard,
  createNewDonation,
 // getDonationDetailsById,
 // getRdaConfig
} from "../../../../service/apiService";

export const useFetchEmergencyDonationDashboard = (payload: any) => {
  const {
      isLoading,
      isSuccess,
      error,
      isError,
      isFetching,
      isRefetching,
      data
  } = useQuery(['getEmergencyDonationDashboard', { ...payload, pageNumber: payload?.page }], getDonationsForDashboard,
      { ...reactQueryConfigOptions, keepPreviousData: true, cacheTime: 0 });

  return { data, isLoading, isSuccess, isError, isFetching,isRefetching, error };
}

export const usePostEmergencyDonationNGO = (history: any,setError:any) => {
  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return createNewDonation(payload, 'emergency-donation/create');
    },
    onSuccess: (data: any) => {
      if (data.hasOwnProperty("errorMessage")) {
        setError(true);
      } else {
        history.push({
          pathname: `${contextPath}/dashboard/thank-you/emergency-donation?type=emergency-donation`,
          state: {
            donationId: data,
          },
        });
      }
    },
  });
  return mutation;
};

