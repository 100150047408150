import * as React from 'react';
import Spinner  from '../../uiComponents/LDSpinner';

// tslint:disable-next-line
export interface LoaderProps {
}

const Loader: React.SFC<LoaderProps> = () => {
    return <div style={{
        'position': 'absolute',
        'top': '50%',
        'left': '50%',
        'transform': 'translate(-50%, -50%)',
        'zIndex': 1
    }} ><Spinner/></div>
}

export default Loader;
