import { ErrorMessage } from '@hookform/error-message';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FC } from 'react';

interface IErrorMessageComponentProps {
    fieldName: string,
    errors: any
    errorClassName?:string
}

// @ts-ignore
export const useStyles = makeStyles((theme: Theme) => createStyles({
    errorMessage: {
        fontSize: '14px',
        fontFamily: 'Bogle',
        color: 'red',
    }
}));

export const ErrorMessageComponent: FC<IErrorMessageComponentProps> = ({ fieldName, errors, errorClassName }) => {

    const classes = useStyles();

    return (<>
        <ErrorMessage
            errors={errors}
            name={fieldName}
            render={({ message }) => <div className={`${classes.errorMessage} ${errorClassName}`} >{message}</div>}
        />
    </>);
}
