import { Action } from 'redux';

export enum registerUserActionTypes {
    REGISTER_USER = 'REGISTER_USER',
    REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE',

    REQUEST_VERIFICATION_CODE = 'REQUEST_VERIFICATION_CODE',
    REQUEST_VERIFICATION_CODE_SUCCESS = 'REQUEST_VERIFICATION_CODE_SUCCESS',
    REQUEST_VERIFICATION_CODE_FAILURE = 'REQUEST_VERIFICATION_CODE_FAILURE',

    VERIFY_CODE = 'VERIFY_CODE',
    VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS',
    VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE',

    RESET_PASSWORD = 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',

    SELECT_AREA = 'SELECT_AREA',
    SELECT_AREA_SUCCESS = 'SELECT_AREA_SUCCESS',
    SELECT_AREA_FAILURE = 'SELECT_AREA_FAILURE',
}

/* Register new organization */

export interface RegisterUser extends Action {
    type: registerUserActionTypes.REGISTER_USER;
    data: string;
}

export const registerUser = (data: string): RegisterUser => ({
    type: registerUserActionTypes.REGISTER_USER,
    data
});

export interface RegisterUserSuccess extends Action {
    type: registerUserActionTypes.REGISTER_USER_SUCCESS;
    data: any;
}

export const registerUserSuccess = (data: any): RegisterUserSuccess => ({
    type: registerUserActionTypes.REGISTER_USER_SUCCESS,
    data
});

export interface RegisterUserFailure extends Action {
    type: registerUserActionTypes.REGISTER_USER_FAILURE;
    data?: string;
}

export const registerUserFailure = (data: string): RegisterUserFailure => ({
    type: registerUserActionTypes.REGISTER_USER_FAILURE,
    data
});

/* Register new organization */

export interface GetSelectArea extends Action {
    type: registerUserActionTypes.SELECT_AREA;
    data: string;
}

export const getSelectArea = (data: string): GetSelectArea => ({
    type: registerUserActionTypes.SELECT_AREA,
    data
});

export interface GetSelectAreaSuccess extends Action {
    type: registerUserActionTypes.SELECT_AREA_SUCCESS;
    data: any;
}

export const getSelectAreaSuccess = (data: any): GetSelectAreaSuccess => ({
    type: registerUserActionTypes.SELECT_AREA_SUCCESS,
    data
});

export interface GetSelectAreaFailure extends Action {
    type: registerUserActionTypes.SELECT_AREA_FAILURE;
    data?: string;
}

export const getSelectAreaFailure = (data: string): GetSelectAreaFailure => ({
    type: registerUserActionTypes.SELECT_AREA_FAILURE,
    data
});


/* Reset password - enter email */

export interface RequestVerificationCode extends Action {
    type: registerUserActionTypes.REQUEST_VERIFICATION_CODE;
    data: string;
}

export const requestVerificationCode = (data: string): RequestVerificationCode => ({
    type: registerUserActionTypes.REQUEST_VERIFICATION_CODE,
    data
});

export interface RequestVerificationCodeSuccess extends Action {
    type: registerUserActionTypes.REQUEST_VERIFICATION_CODE_SUCCESS;
    data: any;
}

export const requestVerificationCodeSuccess = (data: any): RequestVerificationCodeSuccess => ({
    type: registerUserActionTypes.REQUEST_VERIFICATION_CODE_SUCCESS,
    data
});

export interface RequestVerificationCodeFailure extends Action {
    type: registerUserActionTypes.REQUEST_VERIFICATION_CODE_FAILURE;
    data?: string;
}

export const requestVerificationCodeFailure = (data: string): RequestVerificationCodeFailure => ({
    type: registerUserActionTypes.REQUEST_VERIFICATION_CODE_FAILURE,
    data
});

/* Reset password - enter verification code */


export interface VerifyCode extends Action {
    type: registerUserActionTypes.VERIFY_CODE;
    data: string;
}

export const verifyCode = (data: string): VerifyCode => ({
    type: registerUserActionTypes.VERIFY_CODE,
    data
});

export interface VerifyCodeSuccess extends Action {
    type: registerUserActionTypes.VERIFY_CODE_SUCCESS;
    data: any;
}

export const verifyCodeSuccess = (data: any): VerifyCodeSuccess => ({
    type: registerUserActionTypes.VERIFY_CODE_SUCCESS,
    data
});

export interface VerifyCodeFailure extends Action {
    type: registerUserActionTypes.VERIFY_CODE_FAILURE;
    data?: string;
}

export const verifyCodeFailure = (data: string): VerifyCodeFailure => ({
    type: registerUserActionTypes.VERIFY_CODE_FAILURE,
    data
});

/* Reset password - reset password */


export interface ResetPassword extends Action {
    type: registerUserActionTypes.RESET_PASSWORD;
    data: string;
}

export const resetPassword = (data: string): ResetPassword => ({
    type: registerUserActionTypes.RESET_PASSWORD,
    data
});

export interface ResetPasswordSuccess extends Action {
    type: registerUserActionTypes.RESET_PASSWORD_SUCCESS;
    data: any;
}

export const resetPasswordSuccess = (data: any): ResetPasswordSuccess => ({
    type: registerUserActionTypes.RESET_PASSWORD_SUCCESS,
    data
});

export interface ResetPasswordFailure extends Action {
    type: registerUserActionTypes.RESET_PASSWORD_FAILURE;
    data?: string;
}

export const resetPasswordFailure = (data: string): ResetPasswordFailure => ({
    type: registerUserActionTypes.RESET_PASSWORD_FAILURE,
    data
});


export type registerUserActions = RegisterUser | RegisterUserSuccess | RegisterUserFailure
| RequestVerificationCode | RequestVerificationCodeFailure | RequestVerificationCodeSuccess
| VerifyCode | VerifyCodeFailure | VerifyCodeSuccess
| ResetPassword | ResetPasswordFailure | ResetPasswordSuccess |
GetSelectArea | GetSelectAreaSuccess | GetSelectAreaFailure;