import { Action } from 'redux';

export enum LogoutUserActionTypes {
    USER_LOGOUT_LOADING = 'USER_LOGOUT_LOADING',
    USER_LOGOUT_LOADING_SUCCESS = 'USER_LOGOUT_LOADING_SUCCESS',
    USER_LOGOUT_LOADING_FAILURE = 'USER_LOGOUT_LOADING_FAILURE',
    CRLEAR_LOGOUT_STATE = 'CRLEAR_LOGOUT_STATE'
}

export interface LogoutUser extends Action {
    type: LogoutUserActionTypes.USER_LOGOUT_LOADING;
}

export const logoutUser = (): LogoutUser => ({
    type: LogoutUserActionTypes.USER_LOGOUT_LOADING,
});

export interface LogoutUserSuccess extends Action {
    type: LogoutUserActionTypes.USER_LOGOUT_LOADING_SUCCESS;
    data: any;
}

export const logoutUserSuccess = (data: any): LogoutUserSuccess => ({
    type: LogoutUserActionTypes.USER_LOGOUT_LOADING_SUCCESS,
    data
});

export interface LogoutUserFailure extends Action {
    type: LogoutUserActionTypes.USER_LOGOUT_LOADING_FAILURE;
    data?: string;
}

export const logoutUserFailure = (data: string): LogoutUserFailure => ({
    type: LogoutUserActionTypes.USER_LOGOUT_LOADING_FAILURE,
    data
});

export interface CleareLogoutState extends Action {
    type: LogoutUserActionTypes.CRLEAR_LOGOUT_STATE;
}

export const cleareLogoutState = (): CleareLogoutState => ({
    type: LogoutUserActionTypes.CRLEAR_LOGOUT_STATE,
});

export type LogoutUserActions = LogoutUser | LogoutUserSuccess | LogoutUserFailure | CleareLogoutState;