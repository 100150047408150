import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputTextField } from '../../common/Input/InputTextField'
import { resolvePath } from '../../common/utils';
import { IYesOrNoQuestion } from '../../../models/iAppendixA';
import { ErrorMessageComponent } from '../formComponents/ErrorMessage'

interface OwnProps {
  question: string,
  description?: string,
  label: string,
  yesLabel: string,
  noLabel: string,
  id: string,
  optionName: string,
  fieldName: string,
  qno: string,
  showTextAreaOnly?: boolean
  showDescOnFalse?: boolean,
  disabled: boolean,
  defaultValue: boolean | IYesOrNoQuestion
}
const YesOrNoQuestion: FC<OwnProps> = ({
  id,
  question,
  noLabel,
  yesLabel,
  fieldName,
  optionName,
  label,
  qno,
  showTextAreaOnly = false,
  defaultValue,
  disabled
}) => {
  const { watch, errors, control } = useFormContext();
  const {t} = useTranslation();


  const yesOrNo = watch(optionName);
  const fieldError = optionName ? resolvePath(optionName, errors, '')?.message : '';

  return <>

      <FormControl error={fieldError ? true : false}>
        <FormLabel id={optionName}>{qno ?`${qno} .`: ''} {question}</FormLabel>
        {!showTextAreaOnly && optionName &&
        <Controller
            control={control}
            name={optionName}
            defaultValue = {defaultValue?.toString()}
            render={(field: any) => (
              <RadioGroup data-testid = {optionName}
                {...field}
                value = {field.value?.toString()}
                row = {true}>
                <FormControlLabel disabled = {disabled} value = {'true'} control={
                <Radio required = {true} color='primary'/>} label={yesLabel ?? t('common.buttonLabels.yes')} />
                <FormControlLabel disabled = {disabled}  value = {'false'} control={
                <Radio required = {true} color='primary'/>} label= {noLabel?? t('common.buttonLabels.no')} />
              </RadioGroup>
            )}
          />
           }
           {fieldError && <ErrorMessageComponent errors={errors} fieldName={optionName} />}
      </FormControl>
      {
       (showTextAreaOnly || yesOrNo === 'true' || yesOrNo === true) &&
        <InputTextField
          errorKeyName = {fieldName}
          fieldName = {fieldName}
          disabled = {disabled}
          label = {label}
          id = {`ans_${id}`}
          rows = {3}
          multiline = {true}
        />
      }
  </>
}
export default YesOrNoQuestion;
