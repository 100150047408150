import ScheduleIcon from '@material-ui/icons/Schedule';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export const getStatusIcon = (status: string) => {
  if (status === 'IN_PROGRESS') {
    return (<ScheduleIcon fontSize='small' style={{ paddingRight: '3px' }} />)
  }
  if (status === 'COMPLETED') {
    return (<CheckCircleOutlineIcon fontSize='small' style={{ paddingRight: '3px' }} />)
  }
  return <></>;
}
