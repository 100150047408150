import React, { useContext } from 'react';
import NonVerifiedOnboarding from './nonVerifiedOnBoarding';
import withRouter from 'src/polyfils/customRouter';
import { contextPath } from 'src/constants';
import UserContext from '../../../store/user-context';
import SideBarHandler from '../../../commonComponents/appComponents/sideBar/sideBarHadler';

const isMobileResolution = () => window.innerWidth <= 425;

class NonVerifiedOnboardingParetPage extends React.Component {
    public render() {
        return <>
            <SideBarHandler hideOnMount={true} showOnUnmount={!isMobileResolution()}>
                <Onboarding {...this.props} />
            </SideBarHandler>
       </>
    }
}

const Onboarding = (props: any) => {
    const userContext: any = useContext(UserContext);
    const firstName = userContext?.organization?.userContactDetails?.firstName;
    const lastName = userContext?.organization?.userContactDetails?.lastName;
    const email = userContext?.organization?.userContactDetails?.email;
    const userId = userContext.organization?.userContactDetails?.cid;
    return <NonVerifiedOnboarding
        data={{ firstName, lastName, email, userId }}
        history={props.history}
        contextPath={contextPath} />


}


// @ts-ignore
export default withRouter(NonVerifiedOnboardingParetPage);