const registerConstants:any =  {
    verifyEmail: {
        pageHeader: {
            en: 'Reset Password',
            es: 'Restablecer la contraseña',
            fr: 'réinitialiser le mot de passe',
        },
        pageSubHeader: {
            en: 'Enter the email address of primary contact associated with GDMS',
            es: 'Ingrese la dirección de correo electrónico del contacto principal asociado con GDMS',
            fr: 'Saisissez l\'adresse e-mail du contact principal associé au GDMS',
        },
        formAlias: 'enterEmail',
    },
    verifyCode: {
        pageHeader: {
            en: 'Enter Verification Code',
            es: 'Ingrese el código de verificación',
            fr: 'Entrez le code de vérification',
        },
        pageSubHeader: {
            en: 'For security reasons we have to authenticate your request. We have sent a verification code in your email. Please enter that below',
            es: 'Por razones de seguridad, tenemos que autenticar su solicitud. Hemos enviado un código de verificación en su correo electrónico. Ingrese eso a continuación',
            fr: 'Pour des raisons de sécurité, nous devons authentifier votre demande. Nous avons envoyé un code de vérification dans votre e-mail. Veuillez le saisir ci-dessous',
        },
        formAlias: 'verifyCode',
    },
    resetPassword: {
        pageHeader: {
            en: 'Reset Password',
            es: 'Restablecer la contraseña',
            fr: 'réinitialiser le mot de passe',
        },
        pageSubHeader: {
            en: 'Almost done! Please enter your new password below',
            es: '¡Casi termino! Por favor ingrese su nueva contraseña a continuación',
            fr: 'Presque fini! S\'il vous plaît entrez votre nouveau mot de passe ci-dessous',
        },
        formAlias: 'resetPassword',
    },
    passwordSuccess: {
        en:'Password changed successfully!',
        es:'¡Contraseña cambiada con éxito!',
        fr:'Le mot de passe a été changé avec succès!',
    },
    passwordSuccessDesc: {
        en:'with new password',
        es:'con nueva contraseña',
        fr:'avec un nouveau mot de passe',
    }
}

export default registerConstants;