
import { BreadcrumbsComponent } from "../../common/index";
import { Heading } from "@walmart-web/livingdesign-components";

export const PageBanner = (props: any) => {
    const { heading = 'Default Header', history, breadcrumbsData } = props;

    return (
        <>
            <BreadcrumbsComponent
                breadcrumbsData={breadcrumbsData}
                history={history}
            />
            <Heading as="h2" size="medium" weight={700}>
                {heading}
            </Heading>
        </>
    )

}