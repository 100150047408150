import { ConfigDataActions, ConfigActionTypes } from './configActions';
import { ConfigState } from '../../models/globalDonationState';

export const defaultConfigState: ConfigState = {
    configData: [],
    configLoading: false,
    configLoadingFailure: false,
    configLoadingSuccess: false,
};

export const configDataReducer = (state: ConfigState = defaultConfigState, action: ConfigDataActions):
    ConfigState => {
    switch (action.type) {
        case ConfigActionTypes.CONFIG_DATA_LOAD: return {
            ...state,
            configLoading: true,
            configLoadingSuccess: false,
            configLoadingFailure: false,
        };
        case ConfigActionTypes.CONFIG_LOAD_SUCCESS: return {
            ...state,
            configData: action.data,
            configLoading: false,
            configLoadingFailure: false,
            configLoadingSuccess: true,
        };
        case ConfigActionTypes.CONFIG_LOAD_FAILURE: return {
            ...state,
            configLoading: false,
            configLoadingFailure: true,
            configLoadingSuccess: false,
        };
        default: return state;
    }
}