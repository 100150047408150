import { Box } from '@material-ui/core'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessageComponent } from '../../common'
import TextField from './TextField'

interface OwnProps {
  id: string,
  disabled: boolean,
  fieldName: string,
  required: boolean,
  label: string,
  labelInjected?: any,
  defaultVale?: boolean
}

const labelStyle = {
  padding: 0,
  fontSize: '1rem',
  fontFamily: 'Bogle,Roboto',
  fontWeight: 400,
  lineHeight: 1,
  marginLeft: 5
}
const PermissionCheckbox: FC<OwnProps> = ({
  id,
  disabled,
  fieldName,
  required,
  label,
  ...props
}) => {
  const {errors, register} = useFormContext()

  return   <div>
  <Box display = 'flex'>
        <input
        type='checkbox'
        id={id}
        ref={register}
        data-testid = {fieldName}
        style = {{minHeight: 18, minWidth: 18}}
        value = 'true'
        disabled = {disabled}
        required = {required}
        name={fieldName} />
        <label
        style = {labelStyle}
        htmlFor={id}> <TextField
               label = {label}
               labelInjected = {props?.labelInjected}
               /></label>
  </Box>
  <ErrorMessageComponent errors={errors} fieldName={fieldName} />
</div>
}

export default PermissionCheckbox;
