import { ProvinceSearchActions, ProvinceSearchActionTypes } from './provinceSearchActions';
import { ProvinceSearchState } from '../../models/globalDonationState';

export const defaultProvinceSearchState: ProvinceSearchState = {
    provinceData: [{}],
    provinceSearchLoading: false,
    provinceSearchSuccess: false,
    provinceSearchFailure: false
};

export const provinceSearchReducer = (state: ProvinceSearchState = defaultProvinceSearchState, action: ProvinceSearchActions):
    ProvinceSearchState => {
    switch (action.type) {
        case ProvinceSearchActionTypes.PROVINCE_DATA_LOAD: return {
            ...state,
            provinceSearchLoading: true,
            provinceSearchSuccess: false,
            provinceSearchFailure: false,
        };
        case ProvinceSearchActionTypes.PROVINCE_LOAD_SUCCESS: return {
            ...state,
            provinceData: action.data,
            provinceSearchLoading: false,
            provinceSearchFailure: false,
            provinceSearchSuccess: true,
        };
        case ProvinceSearchActionTypes.PROVINCE_LOAD_FAILURE: return {
            ...state,
            provinceSearchLoading: false,
            provinceSearchFailure: true,
            provinceSearchSuccess: false,
        };
        default: return state;
    }
}