import { Box, makeStyles, Paper, TableRow, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ISocialImpactObjectiveMeasures, ISocialImpactObjectives, MonetarySocialImpactSections } from '../socialImpactReportData';
import MaterialTable, {IMTColoumn} from '../../../common/MaterialTable'
import { InputTextField } from 'src/components/common/Input/InputTextField';
import { withStyles } from '@material-ui/styles';
import { CustomCell } from 'src/components/donations/moreDetailsSections/common/CustomCell';
import TextFieldMaxSizeIndicator from 'src/components/common/FormSection/TextFieldMaxSizeIndicator';


interface OwnProps {
    projectObjectives: ISocialImpactObjectives[]
}
const useStyles = makeStyles({
    title: {
        fontWeight: 'bold'
    },
    paper: { padding: '15px', backgroundColor: '#fbfbfb' }
})

const columnHeader: IMTColoumn[] = [
    { key: 'monetaryDonation.projectDetailsSection.projectObjectives' },
    { key: 'monetaryDonation.projectDetailsSection.measureOfSuccess' },
    { key: 'monetaryDonation.projectDetailsSection.results' },
    { key: 'socialImpactReport.actualResToDate', required: true}];

interface IProjectObjectivesRow extends ISocialImpactObjectiveMeasures {
    projectObservation: string
    key: string
}
const RowCell = withStyles({
    root: {
        verticalAlign: 'middle',
        minWidth: '200px'
    },
    sizeSmall: {
        width: '180px',
    },
})(CustomCell);
const ProjectObjectives: FC<OwnProps> = ({projectObjectives}) => {
    const { t } = useTranslation();

    const classes = useStyles();
    const [tableRow, setRows] = useState<IProjectObjectivesRow[]>([]);
    const fieldName = MonetarySocialImpactSections.ProjectObjectives;

    useEffect(() => {
        const rows: IProjectObjectivesRow[] = [];
        projectObjectives.forEach((objective, rootIndex) => {
            objective.measureResults.forEach((res, index) => {
                const row: IProjectObjectivesRow = {
                    ...res,
                    projectObservation: '',
                    key: `${fieldName}[${rootIndex}].measureResults[${index}]`
                }
                if(index === 0) {
                    row.projectObservation = objective.projectObservation
                }
                rows.push(row);
            });
        });
        setRows(rows);
    }, [projectObjectives]);
    return <>
        <Box display = 'flex' mb = {2}>
            <Typography classes = {{root: classes.title}} variant = 'body2'>
                {t('socialImpactReport.projObjectivesSummary')}
            </Typography>
        </Box>
        <Paper classes = {{root: classes.paper}}>
            <MaterialTable
                getTranslation ={t}
                header = {columnHeader}>
                    {tableRow.map((field, index) => (<TableRow data-testid = {'observationTableRow'} key={index}>
                            <RowCell data-testId = {`cell_${index}`}>
                                {field.projectObservation}
                            </RowCell>
                            <RowCell data-testId = {`cell_${index}`}>
                            <InputTextField
                                  required = {false}
                                  fieldName={`${field.key}.measure`}
                                  errorKeyName={`${field.key}.measure`}
                                  id={`${field.key}.measure`}
                                  multiline={true}
                                  rows={4}
                                  InputProps={{ readOnly: true }}
                                  fullWidth={true}
                                  defaultValue={field.measure??''}
                                  />
                            </RowCell>
                            <RowCell data-testId = {`cell_${index}`}>
                                {field.result}
                            </RowCell>
                            <RowCell data-testId = {`cell_${index}`}>
                                    <InputTextField
                                        required={true}
                                        fieldName={`${field.key}.actualResultToDate`}
                                        id={`${field.key}.actualResultToDate`}
                                        errorKeyName = {`${field.key}.actualResultToDate`}
                                        InputProps={{readOnly: false}}
                                        fullWidth={true}
                                        rows={3}
                                        multiline={true}
                                        defaultValue={field.actualResultToDate}
                                    />
                                    <TextFieldMaxSizeIndicator
                                        fieldName = {`${field.key}.actualResultToDate`}
                                        maxSize = {1000}/>
                            </RowCell >
                            </TableRow>))}
            </MaterialTable>
        </Paper>
    </>
};
export default ProjectObjectives;

