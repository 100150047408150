import { Action } from 'redux';

export enum ReportDataActionTypes {
    SEND_REPORT_DATA = 'SEND_REPORT_DATA',
    SEND_REPORT_DATA_SUCCESS = 'SEND_REPORT_DATA_SUCCESS',
    SEND_REPORT_DATA_FAILURE = 'SEND_REPORT_DATA_FAILURE'
}

export interface DownloadReportData extends Action {
    type: ReportDataActionTypes.SEND_REPORT_DATA;
    data: any;
}

export const downloadReportData = (data: any): DownloadReportData => ({
    type: ReportDataActionTypes.SEND_REPORT_DATA,
    data
});

export interface ReportDataLoadSuccess extends Action {
    type: ReportDataActionTypes.SEND_REPORT_DATA_SUCCESS;
    data: any;
}

export const reportDataLoadSuccess = (data: any): ReportDataLoadSuccess => ({
    type: ReportDataActionTypes.SEND_REPORT_DATA_SUCCESS,
    data
});

export interface ReportDataLoadFailure extends Action {
    type: ReportDataActionTypes.SEND_REPORT_DATA_FAILURE;
    data?: string;
}

export const reportDataLoadFailure = (data: string): ReportDataLoadFailure => ({
    type: ReportDataActionTypes.SEND_REPORT_DATA_FAILURE,
    data
});

export type ReportDataActions = DownloadReportData | ReportDataLoadSuccess | ReportDataLoadFailure;