import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import SuccessSnackBarComponent from 'dynamic-form-components/lib/components/menu/SuccessSnackBarComponent';
import { GlobalDonationState } from '../../models/globalDonationState';
import { withStyles } from '@material-ui/core';
import styles from '../menu/ThemeStyle';
import '../../styles/formStyles.css';
import '../../styles/orgProfile.css';
import '../../styles/donationOfMoney.css';
import { getOrgProfile, saveOrgProfile, cityStateMappingDataLoad } from 'src/state/OrgProfile/orgProfileActions';
import LoaderComponent from '../LoaderComponent';
import { contextPath, tableTranslations } from '../../constants';
import { OrgProfileGovtForm } from './OrgProfileGovtForm';
import { resolvePath } from '../common/utils';
import analytics from '../../adobe-analytics/analytics';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';


export interface OwnProps {
    formAlias: string;
    classes: any;
    appi18n: any;
}

export interface StateProps {
    submissionData?: any;
    selectedLang: any;
    selectedDonationData?: any;
    orgProfile: any;
    orgProfileState: any;
    formState: any;
    loggedInUserDataLoading: boolean;
}

export interface DispatchProps {
    editOrgProfile: (data: any) => void;
    getOrgProfile: (data: any) => void;
    getCityStateMapping: (data: any) => void;
}

const appi18n: any = {
    organizationDetailsLabel: {
        en: 'Organization Details',
        es: 'Detalles de la organización',
        fr: 'Détails de l\'organization',
        ja: '組織の詳細',
        zh: '机构详细资料',
    },
    genericSuccessMessage: {
        en: 'Successfully updated the organization profile',
        es: 'Perfil de la organización actualizado correctamente',
        fr: 'Le profil de l\'organization a bien été mis à jour',
        ja: '組織プロファイルが正常に更新されました',
        zh: '成功更新組織資料',
    },
    genericFailureMessage: {
        en: 'Failed to update organization profile',
        es: 'No se pudo actualizar el perfil de la organización',
        fr: 'Échec de la mise à jour du profil de l\'organization',
        ja: '組織プロファイルの更新に失敗しました',
        zh: '無法更新組織資料',
    },
    ...tableTranslations
}

class OrganizationProfile extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {

    public state = {
        formAlias: 'orgProfile',
        options: {
            noAlerts: true
        }
    };

    // public options: any;
    public componentWillMount() {
        /* this.options = {
            ...this.options,
            noAlerts:true,
            language: this.props.selectedLang
        } */
        this.setState({
            options: {
                ...this.state.options,
                language: this.props.selectedLang
            }
        })
        const orgProfileData = this.props.orgProfile ? JSON.parse(JSON.stringify(this.props.orgProfile)) : {};
        const countryCodeFromLocalStorage = localStorage.getItem('countryCode') || ''
        const countryCode = (orgProfileData && orgProfileData.countryCode)
            ? orgProfileData.countryCode
            : ((countryCodeFromLocalStorage) ? countryCodeFromLocalStorage : '');
        if (countryCode) {
            this.props.getCityStateMapping({
                countryCode
            })
        }
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.selectedLang !== this.props.selectedLang) {
            this.setState({
                options: {
                    ...this.state.options,
                    language: newProps.selectedLang
                }
            })
            this.translateGovernanceDetailsHeaders();
        }
        if (newProps.orgProfile !== this.props.orgProfile
            && !newProps.orgProfileState.updateOrgProfileSuccess) {
            const orgProfileData = newProps.orgProfile ? JSON.parse(JSON.stringify(newProps.orgProfile)) : {};
            if (orgProfileData && orgProfileData.countryCode) {
                this.props.getCityStateMapping({
                    countryCode: orgProfileData.countryCode
                })
            }
        }
        this.setState({
            options: {
                ...this.state.options,
                readOnly: false,
                language: newProps.selectedLang
            }
        })
    }

    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.organization.organizationProfilePage);
        const email: string = localStorage.getItem('userEmail') || ''
        this.props.getOrgProfile({ email });
        this.addEventListener();
        this.translateGovernanceDetailsHeaders();
    }
    public componentWillUnmount() {
        this.removeEventListener();
    }

    private translateGovernanceDetailsHeaders = () => {
        const boardMembersHeader = document.querySelectorAll('.board-members-cols')
        // @ts-ignore
        if (boardMembersHeader !== null) {
            // @ts-ignore
            boardMembersHeader.forEach((item: any, _index: number) => {
                item.innerHTML = appi18n?.boardMemberHeaders?.[item?.id]?.[this.props.selectedLang];
            })
        }

        const executiveOfficersHeader = document.querySelectorAll('.executive-officers-cols')
        // @ts-ignore
        if (executiveOfficersHeader !== null) {
            // @ts-ignore
            executiveOfficersHeader.forEach((item: any, _index: number) => {
                item.innerHTML = appi18n?.executiveOfficerHeaders?.[item?.id]?.[this.props.selectedLang];
            })
        }

        const keyPersonsHeader = document.querySelectorAll('.key-persons-cols')
        // @ts-ignore
        if (keyPersonsHeader !== null) {
            // @ts-ignore
            keyPersonsHeader.forEach((item: any, _index: number) => {
                item.innerHTML = appi18n?.keyPersonsHeaders?.[item?.id]?.[this.props.selectedLang];
            })
        }

        const deleteBtns = document.querySelectorAll('.removeRow');
        if (deleteBtns !== null) {
            deleteBtns.forEach((btn: any, _index: number) => {
                btn.innerHTML = appi18n?.deleteBtnText?.[this.props.selectedLang];
            })
            if (this.props?.orgProfile?.primary === false) {
                deleteBtns.forEach((btn: any, _index: number) => {
                    btn.style.display = 'none';
                })
            }
        }
        const editBtns = document.querySelectorAll('.editRow');
        if (editBtns !== null) {
            editBtns.forEach((btn: any, _index: number) => {
                btn.innerHTML = appi18n.editBtnText[this.props.selectedLang];
            })
            if (this.props?.orgProfile?.primary === false) {
                editBtns.forEach((btn: any, _index: number) => {
                    btn.style.display = 'none';
                })
            }
        }
        const messageElements = document.querySelectorAll('.editgrid-row-error.help-block');
        if (messageElements !== null) {
            messageElements.forEach((ele: any, _index: number) => {
                ele.style.display = 'none';
            })
        }
        const boardMembersInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersInvalidMsg !== null && boardMembersInvalidMsg.nextElementSibling !== null &&
            boardMembersInvalidMsg.nextElementSibling != null && boardMembersInvalidMsg.nextElementSibling.innerHTML) {
            boardMembersInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        const executiveOfficersInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersInvalidMsg !== null && executiveOfficersInvalidMsg.nextElementSibling !== null &&
            executiveOfficersInvalidMsg.nextElementSibling != null && executiveOfficersInvalidMsg.nextElementSibling.innerHTML) {
            executiveOfficersInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        const keyPersonsInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsInvalidMsg !== null && keyPersonsInvalidMsg.nextElementSibling !== null &&
            keyPersonsInvalidMsg.nextElementSibling != null && keyPersonsInvalidMsg.nextElementSibling.innerHTML) {
            keyPersonsInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        this.removeEventListener();
        this.addEventListener();
    }
    private addEventListener = () => {
        // border member fields
        // @ts-ignore
        const boardMembersSavebtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-saveRow\']');
        if (boardMembersSavebtn) {
            boardMembersSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const boardMembersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-cancelRow\']')
        if (boardMembersCancelBtn) {
            boardMembersCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const boardMembersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersAddMoreBtn) {
            boardMembersAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        // executive officers fields
        const executiveOfficersSavebtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-saveRow\']');
        if (executiveOfficersSavebtn) {
            executiveOfficersSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const executiveOfficersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-cancelRow\']')
        if (executiveOfficersCancelBtn) {
            executiveOfficersCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const executiveOfficersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersAddMoreBtn) {
            executiveOfficersAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // key persons fields
        const keyPersonsSavebtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-saveRow\']');
        if (keyPersonsSavebtn) {
            keyPersonsSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const keyPersonsCancelBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-cancelRow\']')
        if (keyPersonsCancelBtn) {
            keyPersonsCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const keyPersonsAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsAddMoreBtn) {
            keyPersonsAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        const governanceDetailsSection = document.querySelector('.donation-of-money-form .governance-details');
        if (governanceDetailsSection) {
            governanceDetailsSection.addEventListener('mouseover', this.translateGovernanceDetailsHeaders);
        }
        const governanceDetailsSectionClick = document.querySelector('.donation-of-money-form .governance-details');
        if (governanceDetailsSectionClick) {
            governanceDetailsSectionClick.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
    }

    private removeEventListener = () => {

        // border member fields
        // @ts-ignore
        const boardMembersSavebtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-saveRow\']');
        if (boardMembersSavebtn) {
            boardMembersSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const boardMembersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-cancelRow\']')
        if (boardMembersCancelBtn) {
            boardMembersCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const boardMembersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersAddMoreBtn) {
            boardMembersAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        // executive officers fields
        const executiveOfficersSavebtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-saveRow\']');
        if (executiveOfficersSavebtn) {
            executiveOfficersSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const executiveOfficersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-cancelRow\']')
        if (executiveOfficersCancelBtn) {
            executiveOfficersCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const executiveOfficersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersAddMoreBtn) {
            executiveOfficersAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // key persons fields
        const keyPersonsSavebtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-saveRow\']');
        if (keyPersonsSavebtn) {
            keyPersonsSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const keyPersonsCancelBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-cancelRow\']')
        if (keyPersonsCancelBtn) {
            keyPersonsCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const keyPersonsAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsAddMoreBtn) {
            keyPersonsAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        const governanceDetailsSection = document.querySelector('.donation-of-money-form .governance-details');
        if (governanceDetailsSection) {
            governanceDetailsSection.removeEventListener('mouseover', this.translateGovernanceDetailsHeaders);
        }
    }

    private goToDashboard = () => {
        this.props.history.push(`${contextPath}/dashboard`);
    }

    private updateOrgProfileData = (data: any) => {
        const orgData = {
            ...data,
            orgAdditionalDetails: {
                isRegisteredNew: false
            },
            caUserCertificateSignee: null,
            caUserCertificateSignedDate: null
        }
        this.props.editOrgProfile(orgData);
    }

    public render() {
        const { orgProfileState, classes } = this.props;
        const provinceList: any = [];
        if (orgProfileState.cityStateMapping && orgProfileState.cityStateMapping.length > 0) {
            orgProfileState.cityStateMapping.forEach((item: any) => {
                if (item.label) {
                    provinceList.push(item.label)
                }
            })
        }

        if (this.props.orgProfileState.updateOrgProfileSuccess || this.props.orgProfileState.getOrgProfileFailure) {
            setTimeout(() => {
                this.goToDashboard();
            }, 3000);
        }
        const orgProfileStateData = resolvePath('orgProfileState.failedOrgProfileData.id', this.props, '')
            ? this.props.orgProfileState.failedOrgProfileData : this.props.orgProfileState.orgProfile;

        return (<>
                
                <OrgProfileGovtForm
                    selectedLang={this.props.selectedLang}
                    classes={this.props.classes}
                    orgProfileData={orgProfileStateData}
                    provinceList={provinceList}
                    submission={{}}
                    // onSubmit={this.updateOrgProfileData}
                    goToDashboard={this.goToDashboard}
                    onSubmitForm={this.updateOrgProfileData}
                    failedOrgProfileData={this.props.orgProfileState.failedOrgProfileData}
                    readOnlyMode={false}
                />
            <ErrorSnackbarComponent isError={orgProfileState.saveOrgProfileFailure
                || this.props.orgProfileState.updateOrgProfileFailure} classes={classes}
                message={(orgProfileState.notificationMsg)
                    ? orgProfileState.notificationMsg : appi18n.genericFailureMessage[this.props.selectedLang]} />
            <SuccessSnackBarComponent isSuccess={orgProfileState.saveOrgProfileSuccess ||
                this.props.orgProfileState.updateOrgProfileSuccess}
                message={appi18n.genericSuccessMessage[this.props.selectedLang]} />
            {(orgProfileState.saveOrgProfileLoading || orgProfileState.cityStateMappingLoading
                || this.props.orgProfileState.updateOrgProfileLoading
                || orgProfileState.getOrgProfileLoading || this.props.loggedInUserDataLoading)
                ? <LoaderComponent fullScreen={true} /> : <></>}
        </>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    submissionData: state.submissionDataState.submissionData,
    selectedLang: state.selectedLang,
    selectedDonationData: state.selectedDataState.addDataToStore,
    orgProfile: state.loggedInUserState.userDetail,
    orgProfileState: state.orgProfile,
    formState: state.formState,
    loggedInUserDataLoading: state.loggedInUserState.loggedInUserloading
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    editOrgProfile: (data: any) => dispatch(saveOrgProfile(data)),
    getOrgProfile: (data: any) => dispatch(getOrgProfile(data)),
    getCityStateMapping: (data: any) => dispatch(cityStateMappingDataLoad(data))
});


export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationProfile)));
