import { useEffect, useState } from 'react';
import { InputBox, AlertBox, FormRadioButton, FileUploadComponent } from '../index';
import {
  FormBlockHeading,
  validatePositiveNumber,
  wordLengthValidation,
  getCurrencySymbol
} from '../../donations/utils';
import { useTranslation } from 'react-i18next';
import {
  stringFormat, setAppendixBDonationFields
} from '../utils';
import { useFormContext } from 'react-hook-form';
import { formErrorEleClass } from '../../../styles/styleUtil';
import CheckboxCombo from '../FormSections/CheckboxCombo';
import { DONATION_TYPE_VALUES } from 'src/constants';
import { donationCheckBoxOptions } from 'src/components/donations/oneTimeGoods/GEAppendixBFormComponent/GEAppendixBDonationDetails';

export const AppendixBDonationDetails = (props: any) => {
  const { t } = useTranslation();
  const { control, errors, setValue } = useFormContext()
  const [valueAlertMsg, setvalueAlertMsg] = useState('');
  const commonErrorMessage = t('common.validationMsg.requiredField')

  const { disabled, readOnly } = props
  const countryCode = props?.data?.organization?.countryCode

  useEffect(() => {
    if (props?.data) {
      setAppendixBDonationFields(setValue, props?.data?.donationDetail)
    }
  }, [props?.data]);

  /* istanbul ignore next */
  const requiredFieldValidation = () => props?.readOnly ? false : commonErrorMessage

  /* istanbul ignore next */
  const validateAmount = (amount: any) => {
    const validation = validatePositiveNumber(amount, props.selectedLang);
    if (validation !== true) {
      // @ts-ignore
      setvalueAlertMsg(validation);
      return false;
    }
    setvalueAlertMsg('');
    return true;
  }

  /* istanbul ignore next */
  const wordLengthValidate600 = (value: any) => {
    const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 600);
    return wordLengthValidation(value, message, 600);
  }

  return (
    <>
      <FormBlockHeading heading={t('oneTimeGoodsDonation.heading.donationDetails')} />
      <div className='row' style={{ marginBottom: '20px' }}>
        <div className='col-sm-12 col-md-10 col-xl-8'>
        <CheckboxCombo
            fieldName = {'donationType'}
            label = {t('appendixBDonationLabels.typeOfDonation')}
            disabled = {true}
            required = {true}
            qno = {''}
            options = {donationCheckBoxOptions}
            defaultValue = {DONATION_TYPE_VALUES.DONATION_OF_GOODS_ONE_TIME}
          />
        </div>
      </div>
      <div className='row' style={{ marginBottom: '20px' }}>
        <div className='col-sm-12 col-md-10 col-xl-8'>
          <InputBox
            id={'donation_title'}
            className={'donation_title'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('oneTimeGoodsDonation.heading.donationTitle')}
            fieldName={'donationDetail.donationTitle'}
            defaultValue={''}
            required={true}
            disabled={disabled}
            InputProps={{
              readOnly: props?.data?.donationDetail?.donationTitle && readOnly,
              classes: {
                disabled: props?.data?.donationDetail?.donationTitle && disabled ? props?.classes?.disabled : ''
              }
            }}
            rules={{
              required: requiredFieldValidation(),
            }}
            errorMsg={errors?.donationDetail?.donationTitle?.message}
          />
        </div>
      </div>
      <div className='row' style={{ marginBottom: '20px' }}>
        <div className='col-md-8'>
          <InputBox
            id={'donationDetail-countries'}
            className={'donationDetail-countries'}
            rows={3}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('appendixBDonationLabels.listTheCountries')}
            fieldName={'donationDetail.countries'}
            defaultValue={''}
            required={true}
            disabled={disabled}
            InputProps={{
              readOnly: props?.data?.donationDetail?.countries && readOnly,
              classes: {
                disabled: props?.data?.donationDetail?.countries && disabled ? props?.classes?.disabled : ''
              }
            }}
            rules={{
              required: requiredFieldValidation(),
              validate: wordLengthValidate600
            }}
            multiline={true}
            errorMsg={errors?.donationDetail?.countries?.message}
          />
        </div>
      </div>
      <div className='row' style={{ display: 'inherit', alignItems: 'center', marginBottom: '20px' }}>
        <div className='col-md-2'>
          <InputBox
            id={'donation-amount'}
            className={'donation-amount'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={`${t('oneTimeGoodsDonation.labels.value')} (${getCurrencySymbol(countryCode)})`}
            fieldName={'donationDetail.amount'}
            defaultValue={''}
            required={true}
            disabled={disabled}
            InputProps={{
              readOnly: props?.data?.donationDetail?.amount && readOnly,
              classes: {
                disabled: props?.data?.donationDetail?.amount && disabled ? props?.classes?.disabled : ''
              }
            }}
            rules={{
              required: requiredFieldValidation(),
              validate: validateAmount
            }}
            errorMsg={errors?.donationDetail?.amount?.message}
          />
        </div>
        {valueAlertMsg && <div className='col-md-6' style={{ marginBottom: '20px' }}>
          <AlertBox
            rowClass={formErrorEleClass}
            severity={'error'}
            message={valueAlertMsg}
            colGrid={'col-md-12'}
          />
        </div>}
      </div>
      <div className='row' style={{ marginBottom: '20px' }}>
        <div className='col-md-8'>
          <InputBox
            id={'donation-description'}
            className={'donation-description'}
            rows={3}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('appendixBDonationLabels.donationDescription')}
            fieldName={'donationDetail.description'}
            defaultValue={''}
            required={true}
            disabled={disabled}
            InputProps={{
              classes: {
                disabled: props?.data?.donationDetail?.description && disabled ? props?.classes?.disabled : ''
              }
            }}
            rules={{
              required: requiredFieldValidation(),
              validate: wordLengthValidate600
            }}
            multiline={true}
            errorMsg={errors?.donationDetail?.description?.message}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <InputBox
            id={'donation-purpose'}
            className={'donation-purpose'}
            rows={3}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('appendixBDonationLabels.donationPurpose')}
            fieldName={'donationDetail.purpose'}
            defaultValue={''}
            required={true}
            disabled={disabled}
            InputProps={{
              classes: {
                disabled: props?.data?.donationDetail?.purpose && disabled ? props?.classes?.disabled : ''
              }
            }}
            rules={{
              required: requiredFieldValidation(),
              validate: wordLengthValidate600
            }}
            multiline={true}
            errorMsg={errors?.donationDetail?.purpose?.message}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-xl-12'>
          <FormRadioButton
            id={'oneTimeOrRecurring-radio-btn'}
            className={'oneTimeOrRecurring-radio-btn'}
            radioButtonHeading={t('formQuestion.oneTimeOrRecurringHeading')}
            inputBoxHeading={t('formQuestion.recurringDonationDetail')}
            fieldName={'donationDetail.isRecurring'}
            fieldNameInput={'donationDetail.recurringDonationDetail'}
            yesLabel={t('formQuestion.recurring')}
            noLabel={t('formQuestion.oneTime')}
            gridCol='col-sm-12 col-md-8 col-xl-8'
            classes={props.classes}
            formErrors={errors}
            control={control}
            defaultValue={props?.data?.donationDetail?.isRecurring}
            required={true}
            disabled={props?.disabled}
            setValue={setValue}
            noInputBox={false}
            radioButtonValue={props?.data?.donationDetail?.isRecurring}
            inputBoxValue={props?.data?.donationDetail?.recurringDonationDetail}
            rules={{
                required: commonErrorMessage
            }}
          />
        </div>
      </div>
      <div className='row' style={{ marginBottom: '20px' }}>
        <div className='col-md-10'>
          <FileUploadComponent
            name={`donationAttachments`}
            heading={t('common.heading.donationDocumnets')}
            defaultFiles={props?.data?.donationDetail?.uploadFiles}
            disabled={true}
            readOnly={true}
            allowMultiple={true}
            required={false}
            showFileUpload={!disabled}
            showEmptyFileMessage={props?.showEmptyFileMessage}
          />
        </div>
      </div>
      <div className='row' style={{ marginBottom: '20px' }}>
        <div className='col-md-8'>
          <FormRadioButton
            id={'govtSuggestion'}
            className={'govtSuggestion'}
            gridCol={'col-md-12'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            radioButtonHeading={t('appendixBDonationLabels.govtSuggestion')}
            inputBoxHeading={t('appendixBDonationLabels.govtSuggestionDetails')}
            fieldName={'govtSuggestion'}
            fieldNameInput={'govtSuggestionDetails'}
            defaultValue={props?.data?.donationDetail?.govtSuggestion}
            required={true}
            noInputBox={false}
            disabled={props?.disabled}
            setValue={setValue}
            radioButtonValue={props?.data?.donationDetail?.govtSuggestion}
            inputBoxValue={props?.data?.donationDetail?.govtSuggestionDetails}
            rules={{
              required: commonErrorMessage
            }}
          />
        </div>
      </div>
    </>
  )
}
