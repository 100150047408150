import { validateWalmartEmail } from "../../common/utils";
import { formatDateToYYYYMMDD, formatTimeToAMPM, get2DaysAfterDate } from "../commonFunctions";

export const getStartTime = () => {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + 3);
    todayDate.setHours(6);
    todayDate.setMinutes(0);
    todayDate.setSeconds(0);
    return todayDate;
}

export const getEndTime = () => {
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 3);
    endDate.setHours(6);
    endDate.setMinutes(30);
    endDate.setSeconds(0);
    return endDate;
}

export const errorAttributes = {
    eventStartDate: '',
    eventStartTime: '',
    eventEndDate: '',
    eventEndTime: '',
    eventName: '',
    purposeOfEvent: '',
    locationKnown: ''
}

export const spaceDonationFormAttributes = () => {
    const obj = {
        eventStartDate: get2DaysAfterDate().toString(),
        eventEndDate: get2DaysAfterDate().toString(),
        eventStartTime: getStartTime().toString(),
        eventEndTime: getEndTime().toString(),
        locationType: "Store",
        locationKnown: "No",
        primaryEmail: "",
        secondaryEmail: "",
        eventName: '',
        purposeOfEvent: '',
        primaryEmailError: "",
        secondaryEmailError: "",
        errorMessage: errorAttributes
    };
    return obj;
}


export const checkCalendarTimeValidation = (eventStartTime: string, eventEndTime: string) => {
    const firstTime = new Date(eventStartTime);
    const secondTime = new Date(eventEndTime);
    return secondTime.getTime() === firstTime.getTime();
}

export const spaceFormValidation = (formAttributes: any, t: any, facilityName: string,
    isLocationMandatory?: any, locationNumber?: any) => {
    formAttributes.errorMessage.eventEndTime = checkCalendarTimeValidation(formAttributes.eventStartTime, formAttributes.eventEndTime) ? t('spaceDonations.form.error.eventEndTime') : "";
    formAttributes.errorMessage.eventName = formAttributes.eventName ? "" : t('spaceDonations.form.error.eventName');
    formAttributes.errorMessage.purposeOfEvent = formAttributes.purposeOfEvent ? "" : t('spaceDonations.form.error.purposeOfEvent');

    let locationErrorMessage =
        isRDALocationAndStoreMandatory(isLocationMandatory, formAttributes.locationKnown,
            facilityName, locationNumber)
            ? t("spaceDonations.location.error").replace(/FACILITY/g, formAttributes.locationType) : "";
    formAttributes.errorMessage.locationKnown = locationErrorMessage;

    if (formAttributes.locationKnown === "Yes" && locationNumber) {
        if (facilityName.toLowerCase() === "store") {
            formAttributes.primaryEmailError = validateWalmartEmail(formAttributes.primaryEmail) ? "" : t('spaceDonations.location.primaryEmailError');
            if (formAttributes.secondaryEmail.length) {
                formAttributes.secondaryEmailError =
                    validateWalmartEmail(formAttributes.secondaryEmail) ? "" : t('spaceDonations.location.emailError');
            }
        } else {
            //if not store(May be DC & HomeOffice)
            if (formAttributes.primaryEmail.length) {
                formAttributes.primaryEmailError =
                    validateWalmartEmail(formAttributes.primaryEmail) ? "" : t('spaceDonations.location.emailError');
            }
            if (formAttributes.secondaryEmail.length) {
                formAttributes.secondaryEmailError =
                    validateWalmartEmail(formAttributes.secondaryEmail) ? "" : t('spaceDonations.location.emailError');
            }

        }

    }

    return formAttributes;
}

export const isRDALocationAndStoreMandatory = (isRDALocationMandatory: any, locationSelected: string,
    locationPicked: string, locationNumber: string) => {
    return isRDALocationMandatory && locationSelected === "Yes" &&
        locationPicked.toLowerCase() === "store" &&
        (locationNumber === undefined || locationNumber === "")
}

export const checkValidation = (formAttributes: any) => {
    let isAnyPropError = false;
    let errorItems = formAttributes.errorMessage;
    for (var formProp in errorItems) {
        if (errorItems[formProp]) { isAnyPropError = true; break; }
    }
    // Add code for Primary Contact Details Validation
    if (formAttributes.primaryEmailError) { isAnyPropError = true; }
    if (formAttributes.secondaryEmailError) { isAnyPropError = true; }
    return isAnyPropError;
}

export const createSpaceDonationPayload = (donationFormAttributes: any,
    selectedFacility: any, facilityType: string) => {
    const payload: any = {
        donationDetails: {
            "purposeOfEvent": donationFormAttributes.purposeOfEvent,
            "eventName": donationFormAttributes.eventName,
            "locationType": donationFormAttributes.locationKnown === "Yes" ? facilityType : "NA",
            "facilityDetails": {
                ...selectedFacility
            }
        },
        "eventStartDate": formatDateToYYYYMMDD(donationFormAttributes.eventStartDate),
        "eventEndDate": formatDateToYYYYMMDD(donationFormAttributes.eventEndDate),
        "eventStartTime": formatTimeToAMPM(donationFormAttributes.eventStartTime),
        "eventEndTime": formatTimeToAMPM(donationFormAttributes.eventEndTime),
        // "type": orgType,
        "isLocationTypeSelected": false,
        "requestSourceApp": "EXTERNAL"
    }

    if (donationFormAttributes.locationKnown === "Yes") {
        payload["isLocationTypeSelected"] = true;
        console.log("facilityType::::", facilityType);
        let facilityPropType = facilityType === "STORE" ? "storeNumber" :
            (facilityType === "DC" ? "dcNumber" : "homeofficeNumber");

        payload.donationDetails[facilityPropType] = selectedFacility.facilityNo;

        if (donationFormAttributes.primaryEmail) {
            payload.donationDetails["primaryEmail"] = donationFormAttributes.primaryEmail;
        }
        if (donationFormAttributes.secondaryEmail) {
            payload.donationDetails["secondaryEmail"] = donationFormAttributes.secondaryEmail;
        }
    }
    return payload;
}