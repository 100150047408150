import {
    Body, Card, Heading
} from "@walmart-web/livingdesign-components";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

export interface FormProps {
    setIsWorkflowOpen?: any;
    data?: any;
    status?: any;
}

export interface CardProps {
    HeaderIcon?: any;
    headerContent: any;
    bodyContent?: any;
    openWorkflow?: any;
    bodyTrans?: any;
    children?: any;
}
export interface LocProps {
    data?: any;
}
export const CardOutline: FC<CardProps> = ({
    HeaderIcon,
    headerContent,
    bodyContent,
    openWorkflow,
    bodyTrans,
    children
}) => {
    const { t } = useTranslation();
    return (
        <Card
            size='small'
            style={{ marginTop: "20px", padding: "10px", maxWidth: "1200" }}
        >
            <div style={{ margin: "10px 5px" }}>
                <Heading
                    as='h5'
                    size='small'
                    weight={700}
                    UNSAFE_style={{ padding: "0px 5px" }}
                >
                    {HeaderIcon}
                    {t(headerContent)}
                </Heading>
                <Body
                    as='p'
                    UNSAFE_style={{ marginTop: "15px" }}
                    UNSAFE_className={openWorkflow ? "with-workflow" : "without-workflow"}
                >
                    {bodyTrans && <Trans i18nKey={bodyTrans} />}
                    {bodyContent}
                    
                </Body>
                {children}
            </div>
        </Card>
    );
};