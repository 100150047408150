import * as React from 'react';
import Card from '../../uiComponents/LDCard';
import CardItem from '../../uiComponents/LDCardItem';
import Divider from '../../uiComponents/LDDivider';
import Button from '../../uiComponents/LDButton';
import './styles.css'


export interface AccountInfoCardProps {
    header: any,
    footer?: any,
    showEditButton?: boolean,
    buttonHandler?: any,
    alignFooterLeft?: boolean,
    editButtonName?: any,
    children?: any
}

const AccountInfoCard: React.SFC<AccountInfoCardProps> = ({ header, footer = null, alignFooterLeft = false,
    children = null, showEditButton = false, editButtonName = null, ...props }) => {
    return <div className='info-card'><Card className='info-card-item'>
        <CardItem className='info-card-item info-card-header-grid'>
            <div className='info-card-header'>{header}</div>
            {showEditButton && <Button variant='tertiary'
                onClick={() => {
                  if(props?.buttonHandler) {
                    props?.buttonHandler(true)
                  }
                }}>{editButtonName ? editButtonName : 'Edit'}</Button>}
        </CardItem>
        <Divider />
        <CardItem className='info-card-item'><div className='info-card-child'>{children}</div></CardItem>
        <CardItem className='info-card-item'>
            <div className={!alignFooterLeft ? 'info-card-footer' : 'info-card-bootomContent'}>{footer}</div></CardItem>
    </Card></div>
}

export default AccountInfoCard;
