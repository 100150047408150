import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import AppLogoHandler from '../../commonComponents/appComponents/topBar/AppLogoHadler';
import SideBarHandler from '../../commonComponents/appComponents/sideBar/sideBarHadler';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LoaderComponent from '../../commonComponents/appComponents/loader/Spinner';
import { CheckCircle, CloseCircleFill } from '../../commonComponents/icons/icon';
import Button from '../../commonComponents/uiComponents/LDButton';
import OtpInput from 'react-otp-input';
import { useMutation } from 'react-query';
import './styles.css';

const service: Service = SpaceDonationService;

const CustomActionPage = () => {
    // @ts-ignore
    const { uuid, action } = useParams()
    const { isFetching, status } = useQuery(['doCustomUserAction', { uuid, action, type: 'otp' }],
        service.getOtp, { refetchOnWindowFocus: false, retry: false });
    const [otp, setOtp] = useState('');
    const [actionSuccess, setActionSuccess] = useState('');
    return <AppLogoHandler>
        <SideBarHandler hideOnMount={true} showOnUnmount={false}>
            {actionSuccess === 'true' && <SuccessIcon />}
            {actionSuccess === 'false' && <ErrorIcon />}
            {(actionSuccess === '' || actionSuccess === 'false' )&&
                <>
                    {isFetching && <LoaderComponent />}
                    {!isFetching && status === 'success' && <div className='otp-section'>
                        <h6>Please Enter the otp received on your mail</h6>
                        <AskOTP otp={otp} setOtp={setOtp} />
                        <ButtonSection otp={otp} action={action} uuid={uuid} actionSuccess={actionSuccess}
                            setActionSuccess={setActionSuccess} />
                    </div>}
                    {!isFetching && status !== 'success' && <div>
                        <ErrorIcon />
                    </div>}
                </>}
        </SideBarHandler>
    </AppLogoHandler>
}

export default CustomActionPage;

const ErrorIcon = () => <><CloseCircleFill className='error-icon otp-section' />
    <h3  className='otp-message'> Error!</h3><br /><br /></>

const SuccessIcon = () => <><CheckCircle className='success-icon otp-section' />
    <h3 className='otp-message'> Success!</h3><br /> <br /></>

const ButtonSection = (props: any) => {
    const mutation: any = useMutation((data: any) => service.doCustomAction(data));
    useEffect(() => {
        if (mutation.isSuccess) {
            props.setActionSuccess('true');
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            props.setActionSuccess('false');
        }
    }, [mutation.isError]);

    return <>
    <br/>
        {!mutation.isLoading && <Button className='phone-button' variant='primary' onClick={() => {
            props.setActionSuccess('');
            mutation.mutate({ uuid: props.uuid, action: props.action, otp: props.otp });
        }} disabled={props?.otp?.length < 6} >{props.action}</Button>}
         {mutation.isLoading && <Button className='phone-button' variant='primary'
         disabled={true} >Performing {props.action}</Button>}
    </>
}

const AskOTP = (props: any) => {
    return <><OtpInput
        value={props.otp}
        onChange={props.setOtp}
        numInputs={6}
        separator={<span>-</span>}
    /></>
}
