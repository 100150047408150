//@ts-nocheck
import React from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, TextField, Divider, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SubmissionGridComponent from 'dynamic-form-components/lib/components/SubmissionGridComponent';
import { withStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { facilityData } from './../../../state/facilitySearch/facilitySearchActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { GlobalDonationState } from './../../../models/globalDonationState';
import { provinceData } from './../../../state/provinceSearch/provinceSearchActions';
import LoaderComponent from './../../LoaderComponent';
// import Check from '@material-ui/icons/Check';
import GlobalDonationDataService from 'src/service';
import { landigPageUrl } from 'src/constants';
import { gridTranslations } from 'src/static/GridTranslations';

export interface OwnProps {
    title: string;
    component: any;
    value: any;
    onChange?: (event: any, data?: any) => void;
    classes?: any;
}

export interface DispatchProps {
    getFacilityDetails: (data: any) => void;
    getProvinceDetails: (data: any) => void;
}

const styles = (theme: Theme) => ({
    closeButton: {
        position: 'absolute' as 'absolute',
        right: 25,
        top: 10,
        color: theme.palette.grey[500],
    },
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    selectRoot: {
        color: '#000',
        backgroundColor: '#fff'
    },
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#ff9100',
        }
    },
    caption: {
        color: 'green',
        padding: 8,
        border: '1px dashed grey',
        fontSize: '0.875rem'
    },
    toolbar: {
        '& > p:nth-of-type(2)': {
            fontSize: '1.25rem',
            color: 'red',
            fontWeight: 600
        }
    }
});
const gridTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#ff9100',
        }
    }
});
const appi18n: any = {
    ...gridTranslations,
    gridTitle: {
        en: 'Donation Requests',
        es: 'Solicitudes de donación',
        fr: 'Demandes de dons'
    },
    createNewBtnLable: {
        en: 'Create New',
        es: 'Crear nueva',
        fr: 'Créer un nouveau'
    },
    backBtnLabel: {
        en: 'Back',
        es: 'atrás',
        fr: 'Arrière'
    },
    errorMessage: {
        en: 'Please enter a store number or select province',
        es: 'Please enter a store number or select province spaishIngrese un número de tienda o seleccione una provincia',
        fr: 'Veuillez entrer un numéro de magasin ou sélectionner la province'
    },
    labelCity: {
        en: 'Select City',
        es: 'Ciudad selecta',
        fr: 'Sélectionnez une ville'
    },
    labelProvince: {
        en: 'Select Province',
        es: 'Seleccionar provincia',
        fr: 'Sélectionnez la province'
    },
    labelFacilityNo: {
        en: 'Facility#',
        es: 'Instalaciones #',
        fr: 'Établissement #'
    },
    modalTitle: {
        en: 'Find Facility',
        es: 'Encontrar instalación',
        fr: 'Trouver une installation'
    },
    searchResultText: {
        en: 'Search Results',
        es: 'Resultados de la búsqueda',
        fr: 'Résultats de recherche'
    },
    searchBtnText: {
        en: 'Search',
        es: 'Buscar',
        fr: 'Chercher'
    },
    resetBtnText: {
        en: 'Reset',
        es: 'Reiniciar',
        fr: 'Réinitialiser'
    },
    labelChooseStore: {
        en: 'I\'ll choose my store',
        es: 'Elegiré mi tienda',
        fr: 'Je vais choisir mon magasin',
    },
    labelChangeStore: {
        en: 'Change Store',
        es: 'Cambiar tienda',
        fr: 'Changer de magasin'
    },
    facilityNoErr: {
        en: 'Please enter a valid store number',
        es: 'Ingrese un número de tienda válido',
        fr: 'Veuillez saisir un numéro de magasin valide'
    },
    labelAddress: {
        en: 'Street Address',
        es: 'Dirección',
        fr: 'Adresse de rue',
    },
    cityTxt: {
        en: 'City',
        es: 'Ciudad',
        fr: 'Ville'
    },
    provinceTxt: {
        en: 'Province',
        es: 'Provincia',
        fr: 'Province'
    },
    labelPostalCode: {
        en: 'Postal Code',
        es: 'Código postal',
        fr: 'code postal',
    },
    facilityNoPlaceHolder: {
        en: 'Enter a store number',
        es: 'Ingrese un número de tienda',
        fr: 'Entrez un numéro de magasin',
    }
}
class FacilitySearchDialog extends React.PureComponent<OwnProps & DispatchProps & GlobalDonationState> {
    public options = {
        noAlerts: true
    }

    public columns = [
        {
            'title': {
                'en': 'Facility #',
                'fr': 'Établissement #',
                'es': 'Instalaciones #'
            },
            'field': 'facilityNo',
            'hidden': false,
            'sortable': true
        },
        {
            'title': {
                'en': 'Province',
                'fr': 'Province',
                'es': 'Provincia'
            },
            'field': 'state',
            'hidden': false,
            'sortable': true
        },
        {
            'title': {
                'en': 'City',
                'fr': 'Ville',
                'es': 'Ciudad'
            },
            'field': 'city',
            'hidden': false,
            'sortable': true
        },
        {
            'title': {
                'en': 'Postal Code',
                'fr': 'code postal',
                'es': 'Código postal'
            },
            'field': 'postalCode',
            'hidden': false,
            'sortable': true
        },
        {
            'title': {
                'en': 'Street Address',
                'fr': 'Adresse de rue',
                'es': 'Dirección'
            },
            'field': 'streetAddress',
            'hidden': false,
            'sortable': true
        },
        {
            'title': {
                'en': 'Facility Type',
                'fr': 'Type d\'installation',
                'es': `Type d'installation`
            },
            'field': 'facilityType',
            'hidden': false,
            'sortable': true
        }
    ]

    public state = {
        showDialog: false,
        value: '',
        selectedOption: {
            facilityNo: '',
            postalCode: '',
            county: '',
            state: '',
            streetAddress: '',
            city: '',
            facilityType: ''
        },
        facilityNo: '',
        city: '',
        province: {
            value: '',
            label: '',
            cityList: []
        },
        radioChecked: false,
        facilityNoErr: false,
        cityList: [],
        errorMessage: '',
        readOnly: false,
        filters: {},
        refresh: false
    }

    constructor(props: any) {
        super(props);
        this.state = {
            ... this.state,
            value: props.value
        }
    }
    public UNSAFE_componentWillMount() {
        const countryCode = this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
            this.props.loggedInUserState.userDetail.countryCode ? this.props.loggedInUserState.userDetail.countryCode : 'GB';
        this.props.getProvinceDetails({
            key: 'provinceFacilitySearch',
            level: 'market',
            levelValue: 'default',
            countryCode,
            checkDefaultLevels: 'false',
            pageNum: 1,
            pageSize: 100
        })
        if (this.props.selectedDataState.addDataToStore &&
            this.props.selectedDataState.addDataToStore.communityGrant &&
            this.props.selectedDataState.addDataToStore.communityGrant.facility) {
            const selectedData = JSON.parse(JSON.stringify((this.props.selectedDataState.addDataToStore.communityGrant.facility)));
            this.setState(
                {
                    value: selectedData,
                    selectedOption: selectedData,
                    facilityNo: selectedData.facilityNo,
                    radioChecked: true,
                    readOnly: true
                },
                // @ts-ignore
                () => this.props.onChange(this.state.value, null));
        }
        this.setState({
            filters: {
                countryCode
            }
        })
    }
    private closeDialog = () => {
        this.setState({ showDialog: false })
    }
    private openDialog = () => {
        this.setState({ showDialog: true, radioChecked: true })
    }
    private handleFacilityChange = (event: any) => {
        this.validateFacilityNo(event.target.value);
        // @ts-ignore
        this.setState({ facilityNo: event.target.value, errorMessage: '' });
    }
    private handleCityChange = (_event: any, selectedCity: any) => {
        // @ts-ignore
        this.setState({ city: selectedCity, errorMessage: '' });
        this.setState({
            filters: {
                ...this.state.filters,
                city: selectedCity
            }
        });
    }

    private handleStateChange = (_event: any, selectedProvince: any) => {
        // @ts-ignore
        this.setState({ province: selectedProvince, errorMessage: '' });
        this.setState({
            cityList: selectedProvince && selectedProvince.cityList ? selectedProvince.cityList : [],
            city: ''
        })
        this.setState({
            filters: {
                ...this.state.filters,
                state: (selectedProvince?.value === null || selectedProvince?.value === undefined) ? '' : selectedProvince?.value,
            }
        });
    }

    private handleRowSelection = (_event: any, rowData: any, _togglePanel: any) => {
        // @ts-ignore
        window.facility = true;
        // @ts-ignore
        this.setState(
            {
                value: rowData,
                selectedOption: rowData,
                showDialog: false
            },
            // @ts-ignore
            () => this.props.onChange(this.state.value, null));
    }

    private findFacility = (_event: any) => {
        if (!this.state.facilityNo && !this.state.province.value) {
            this.setState({ errorMessage: appi18n.errorMessage[this.props.selectedLang] });
            return;
        }
        this.setState({
            filters: {
                city: this.state.city === null ? '' : this.state.city,
                state: (this.state.province.value === null || this.state.province.value === undefined) ? '' : this.state.province.value,
                number: this.state.facilityNo,
                countryCode: this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
                    this.props.loggedInUserState.userDetail.countryCode ? this.props.loggedInUserState.userDetail.countryCode : 'GB'
            },
            refresh: !this.state.refresh
        });
        /* this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
        this.props.loggedInUserState.userDetail.countryCode === 'GB' ? this.props.getFacilityDetails({
                city: this.state.city === null ? '' : this.state.city ,
                state: '' ,
                number:this.state.facilityNo,
                countryCode:'GB',
                pageNum:1
            }):
        this.props.getFacilityDetails({
            city: this.state.city === null ? '' : this.state.city ,
            state: (this.state.province.value === null || this.state.province.value === undefined) ? '' : this.state.province.value ,
            number:this.state.facilityNo,
            countryCode:'CA',
            pageNum:1
        }) */
    }

    private resetForm = (_event: any) => {
        this.setState({
            facilityNo: '',
            city: '',
            province: '',
            value: ''
        });
        // @ts-ignore
        this.props.onChange(this.state.value, null);
    }

    private validateFacilityNo = (value: any) => {
        if (value && !/^[0-9]+$/.test(value)) {
            this.setState({ facilityNoErr: true })
            return false;
        }
        this.setState({ facilityNoErr: false })
        return true;
    }
    private getSearchResults = (query: any) => {
        const filter = query && query.filters ? query.filters[0] : {};
        const payload: any = {
            /* city: filter.city === null || filter.city === undefined ? '' : filter.city,
            state: (filter.state === null || filter.state === undefined) ? '' : filter.state,
            number: filter.number === null || filter.number === undefined ? '' : filter.number,
            countryCode: filter.countryCode === null || filter.countryCode === undefined ? '' : filter.countryCode, */
            city: this.state.filters.city === null || this.state.filters.city === undefined ? '' : this.state.filters.city,
            state: (this.state.filters.state === null || this.state.filters.state === undefined) ? '' : this.state.filters.state,
            number: this.state.filters.number === null || this.state.filters.number === undefined ? '' : this.state.filters.number,
            countryCode: this.state.filters.countryCode === null || this.state.filters.countryCode === undefined ? '' : this.state.filters.countryCode,
            pageNum: query.page + 1,
            numItemsPerPage: query.pageSize
        }
        return GlobalDonationDataService.getFacilityDetails(payload).catch((e: any) => {
            if (e.response && e.response.status === 401) {
                window.location.href = landigPageUrl;
            }
        })
    }
    public render() {
        let facilityDetails: any = '';
        /* const facilitySearchResults = this.props.facilitySearchState.facilityData &&
                                      this.props.facilitySearchState.facilityData.length > 0 ?
            JSON.parse(JSON.stringify(this.props.facilitySearchState.facilityData)) : []; */
        const provinceList = this.props.provinceSearchState.provinceData.provinceList;
        if ((this.state.selectedOption && this.state.selectedOption.facilityNo)) {
            facilityDetails = (<div className='col-md-10 offset-md-1'>
                <span> <strong>{appi18n.labelFacilityNo[this.props.selectedLang]} :</strong>
                    {this.state.selectedOption.facilityNo}</span><br />
                <span> <strong>{appi18n.cityTxt[this.props.selectedLang]}	:</strong>
                    {this.state.selectedOption.city}</span><br />
                <span> <strong>{appi18n.labelAddress[this.props.selectedLang]} :</strong>
                    {this.state.selectedOption.streetAddress}</span><br />
                <span> <strong>{appi18n.provinceTxt[this.props.selectedLang]} :</strong>
                    {this.state.selectedOption.state}</span><br />
                <span> <strong>{appi18n.labelPostalCode[this.props.selectedLang]} :</strong>
                    {this.state.selectedOption.postalCode}</span><br /><br />
                <button type='button'
                    className={`btn btn-primary btn-sm donation-request-btn ${this.state.readOnly ? 'disabled' : ''}`}
                    onClick={this.openDialog}>{appi18n.labelChangeStore[this.props.selectedLang]}</button>
            </div>);
        }
        return (
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className={`form-check ${this.state.readOnly ? 'disabled' : ''}`} onClick={this.openDialog}>
                            <input className='form-check-input' type='radio'
                                name={this.props.component.key} id={this.props.component.key}
                                value={this.state.facilityNo} defaultChecked={this.state.radioChecked}
                                checked={this.state.radioChecked} disabled={this.state.readOnly} />
                            <label className={`form-check-label cursor-pointer ${this.state.readOnly ? 'disabled' : ''}`}>
                                {appi18n.labelChooseStore[this.props.selectedLang]}
                            </label>
                        </div>
                    </div>
                </div>
                <br />
                <div className='row facilty-info-container'>
                    {facilityDetails}
                </div>
                <Dialog disableEnforceFocus={true}
                    title={appi18n.modalTitle[this.props.selectedLang]} fullWidth={true} maxWidth={'md'}
                    open={this.state.showDialog}>
                    <DialogTitle id='facility-search-dialog' className='facilty-dailog-title'>
                        {appi18n.modalTitle[this.props.selectedLang]}
                        <IconButton aria-label='close' className='pull-right' onClick={this.closeDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <div className='formio-errors'>
                            <div className='form-text error'>
                                {this.state.errorMessage}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <form>
                                    <div className='form-group textFieldStyle'>
                                        <label>{appi18n.labelFacilityNo[this.props.selectedLang]}</label>
                                        <input type='text'
                                            className={`form-control ${this.state.facilityNoErr ? 'has-error is-invalid' : ''}`}
                                            name='facilityNo'
                                            id='facilityNo'
                                            value={this.state.facilityNo}
                                            placeholder={appi18n.facilityNoPlaceHolder[this.props.selectedLang]}
                                            onChange={this.handleFacilityChange} />
                                        {(this.state.facilityNoErr) ?
                                            <div className='formio-errors invalid-feedback'>
                                                <div className='form-text error'>
                                                    {appi18n.facilityNoErr[this.props.selectedLang]}
                                                </div>
                                            </div> : ''
                                        }
                                    </div>
                                    <div className='form-group selectFieldStyle dropdown'>
                                        <Autocomplete
                                            id='choose-Province'
                                            options={provinceList || []}
                                            // @ts-ignore
                                            value={this.state.province}
                                            // @ts-ignore
                                            onChange={this.handleStateChange}
                                            // tslint:disable-next-line: jsx-no-lambda
                                            getOptionSelected={(option: any, value: any) => option.value === value.value}
                                            // tslint:disable-next-line: jsx-no-lambda
                                            getOptionLabel={(option: any) => option.label}
                                            style={{ width: '100%' }}
                                            // tslint:disable-next-line: jsx-no-lambda
                                            renderInput={(params: any) => <TextField {...params}
                                                label={appi18n.labelProvince[this.props.selectedLang]} variant='outlined' />}
                                        />
                                    </div>
                                    <div className='form-group selectFieldStyle dropdown'>
                                        <Autocomplete
                                            id='choose-city'
                                            value={this.state.city}
                                            options={this.state.cityList || []}
                                            // @ts-ignore
                                            onChange={this.handleCityChange}
                                            // tslint:disable-next-line: jsx-no-lambda
                                            getOptionSelected={(option: any, value: any) => option === value}
                                            // tslint:disable-next-line: jsx-no-lambda
                                            getOptionLabel={(option: any) => option}
                                            style={{ width: '100%' }}
                                            // tslint:disable-next-line: jsx-no-lambda
                                            renderInput={(params: any) => <TextField {...params}
                                                label={appi18n.labelCity[this.props.selectedLang]} variant='outlined' />}
                                        />
                                    </div>
                                    <div className='form-group row donation-request-btn button'>
                                        <div className='col-md-12'>
                                            <button type='button' className='btn btn-primary btn-mr-right'
                                                onClick={this.findFacility}>{appi18n.searchBtnText[this.props.selectedLang]}</button>
                                            <button type='button' className='btn btn-warning btn-mr-right reset-btn'
                                                onClick={this.resetForm}>{appi18n.resetBtnText[this.props.selectedLang]}</button>
                                        </div>
                                    </div>
                                </form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <MuiThemeProvider theme={gridTheme}>
                                            <SubmissionGridComponent
                                                key={this.state.refresh}
                                                title={appi18n.searchResultText[this.props.selectedLang]}
                                                formAlias={''}
                                                filters={this.state.filters}
                                                remoteDataFunc={this.getSearchResults}
                                                columns={this.columns}
                                                selectedLanguage={this.props.selectedLang}
                                                i18n={appi18n}
                                                onRowSelect={this.handleRowSelection}
                                                options={{
                                                    exportButton: false,
                                                    pageSize: 10,
                                                    headerStyle: {
                                                        backgroundColor: '#ffffff',
                                                        fontFamily: 'Bogle',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: '0.09px',
                                                        color: '#041f41',
                                                    },
                                                    rowStyle: {
                                                        backgroundColor: '#ffffff',
                                                        fontFamily: 'Bogle',
                                                        fontSize: '14px',
                                                        fontStretch: 'normal',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                        letterSpacing: '0.09px',
                                                    },
                                                    filtering: false,
                                                    search: false
                                                }}
                                            />
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.facilitySearchState.facilitySearchLoading ? <LoaderComponent fullScreen={true} /> : <></>}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
};

const mapStateToProps = (state: GlobalDonationState): GlobalDonationState => {
    return state;
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
    return {
        getFacilityDetails: (data: any) => dispatch(facilityData(data)),
        getProvinceDetails: (params: any) => dispatch(provinceData(params))
    }
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FacilitySearchDialog));;