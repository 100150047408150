import { Component } from 'react';

export interface OwnProps {
    component: any;
    value:any;
    onChange?: (param:any, data: any) => void;
}

 class DateComponent extends Component<OwnProps> {

    public state = {
         value:{}
    }
    constructor(props:any) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    public handleChange = (event:any) => {
         // @ts-ignore
         this.setState({ value: event.target.value }, () => this.props.onChange(null, this.state.value));
    }
    public render() {
        return (
            <div className='row'>
                <div className='col-md-8'>
                    <div className='form-group'>
                        <input type='date' className='form-control flatpickr-input'
                            name={this.props.component.key}
                            id={this.props.component.key}
                            onChange={this.handleChange} />
                    </div>
                </div>
            </div>
        );
    }
};

export default DateComponent;