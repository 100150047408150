import { useEffect, useState } from 'react';
import { FormBlockHeading, wordLengthValidation } from '../../donations/utils';
import { DropDown, InputBox } from '../../common';
import { countryCodeTranslations, taxIdFormat, taxIdPlaceholderTxt } from '../../../constants';
import '../../../styles/formStyles.css';
import GlobalDonationDataService from '../../../service';
import { useTranslation } from 'react-i18next';
import { setAppendixBOrgFields, stringFormat, validateOrgEstablishedDate } from '../../common/utils';
import InputTextField from './InputTextField';
import { useFormContext } from 'react-hook-form';

export const AppendixBOrgDetails = (props: any) => {
  const { control, errors, setValue } = useFormContext()
  const { t } = useTranslation();
  const countryCode = props?.data?.organization?.countryCode ? props.data.organization?.countryCode : '';
  const [stateList, setStateList] = useState([]);
  const [sampleTaxId, setSampleTaxId] = useState('');
  const countryName = countryCodeTranslations.find(row => row.code === countryCode)?.label?.[props?.selectedLang];
  const requiredFieldTranslation = t('common.validationMsg.requiredField');
  const wordLengthlimit = t('common.validationMsg.wordLengthlimit')

  const {
    website,
    address,
    missionAndPurpose,
    geographicCoverage } = props?.data?.organization ?? {}

  const { disabled } = props

  useEffect(() => {
    if (props?.data) {
      setAppendixBOrgFields(setValue, props?.data)
    }
  }, [props?.data]);

  /* istanbul ignore next */
  useEffect(() => {
    setValue('organization.address.country', props?.data?.organization?.countryCode ?? countryName);
    if (props?.data?.organization?.countryCode) {
      GlobalDonationDataService.getStateList({ countryCode }).then(res => {
        setStateList(res);
        setValue('organization.address.province', props?.data?.organization?.address?.province)
      })
    }
    setSampleTaxId(taxIdPlaceholderTxt[countryCode]);
  }, [props?.data?.organization?.countryCode]);

  /* istanbul ignore next */
  const wordLengthValidate10 = (value: any) => {
    const message = stringFormat(wordLengthlimit, 10);
    return wordLengthValidation(value, message, 10)
  };
  /* istanbul ignore next */
  const wordLengthValidate600 = (value: any) => {
    const message = stringFormat(wordLengthlimit, 600);
    return wordLengthValidation(value, message, 600)
  };

  /* istanbul ignore next */
  const validateTaxId = (orgTaxId: any) => {
    const format = taxIdFormat[countryCode]
    if (orgTaxId && format) {
      return format.test(orgTaxId) ? true : t('orgRegForm.validationMsg.taxIdError')
    }
    return true
  }

  const taxIdplaceholdertxt = t('orgRegForm.placeHolders.taxId');
  const taxIdPlaceholder = sampleTaxId ? stringFormat(taxIdplaceholdertxt, sampleTaxId) : '';

  return (
    <div>
      <FormBlockHeading heading={t(props?.heading)} />
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputTextField
            required={true}
            label={t('orgRegForm.labels.orglegalName')}
            fieldName={`organization.orgLegalName`}
            id={`orgLegalName`}
            defaultValue={''}
            isUsedForArray={false}
            errorKeyName={'organization'}
            errorFieldName={'orgLegalName'}
            disabled={true}
            rules={{
              required: true,
              validate: wordLengthValidate600
            }}
            InputProps={{
              readOnly: true,
              classes: {
                disabled: props.classes.disabled
              }
            }}
          />
        </div>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputTextField
            required={countryCode !== 'CA'}
            label={t('orgRegForm.labels.taxId')}
            fieldName={`organization.taxId`}
            id={`taxId`}
            defaultValue={''}
            isUsedForArray={false}
            errorKeyName={'organization'}
            errorFieldName={'taxId'}
            disabled={true}
            placeholder={taxIdPlaceholder}
            rules={{
              required: countryCode === 'CA' ? false : requiredFieldTranslation,
              validate: countryCode === 'CA' ? false : validateTaxId
            }}
            InputProps={{
              readOnly: true,
              classes: {
                disabled: props.classes.disabled
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputTextField
            required={true}
            label={t('oneTimeGoodsDonation.labels.countryRegion')}
            fieldName={`organization.country`}
            id={`country`}
            defaultValue={''}
            isUsedForArray={false}
            errorKeyName={'organization'}
            errorFieldName={'country'}
            disabled={true}
            rules={{
              required: true
            }}
            InputProps={{
              readOnly: true,
              classes: {
                disabled: props.classes.disabled
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-xl-8'>
          <InputBox
            id={'org-street-address'}
            className={'org-street-address'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.streetAddr')}
            fieldName={'organization.address.addressLine1'}
            defaultValue={''}
            multiline={true}
            required={true}
            rules={{
              required: true,
              validate: wordLengthValidate600
            }}
            errorMsg={errors?.organization?.address?.addressLine1?.message}
            disabled={(address?.addressLine1 && props?.disabled) ? true : false}
            InputProps={{
              readOnly: (address?.addressLine1 && props?.disabled) ? true : false,
              classes: {
                disabled: (address?.addressLine1 && props?.disabled ) ? props.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
      <div className='row org_details_row' style={{ display: 'flex', alignItems: 'center' }}>
        <div className='col-sm-12 col-md-10 col-lg-6 col-xl-2 state_dropDown'>
          <DropDown
            id={'org-state'}
            className={`org-state ${(address?.province && props?.disabled) ? 'disabled-dropdown' : ''}`}
            formErrors={errors}
            control={control}
            inputLabel={t('orgRegForm.labels.state')}
            fieldName={'organization.address.province'}
            required={true}
            defaultValue={''}
            dropDownData={stateList}
            rules={{
              required: requiredFieldTranslation
            }}
            disabled={(address?.province && props?.disabled) ? true : false}
            classes={{
              ...props.classes,
              disabled: (address?.province && props?.disabled) ? props.classes.disabled : ''
            }}
            errorMsg={errors?.organization?.address?.province?.message}
          />
        </div>
        <div className='col-sm-12 col-md-10 col-lg-6 col-xl-3'>
          <InputBox
            id={'org-city'}
            className={'org-city inputBox'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.city')}
            fieldName={'organization.address.city'}
            defaultValue={address?.city}
            required={true}
            rules={{
              required: requiredFieldTranslation,
              validate: wordLengthValidate600
            }}
            errorMsg={errors?.address?.city?.message}
            disabled={(address?.city && props?.disabled) ? true : false}
            InputProps={{
              readOnly: (address?.city && props?.disabled) ? true : false,
              classes: {
                disabled: (address?.city && props?.disabled) ? props.classes.disabled : ''
              }
            }}
          />
        </div>
        <div className='col-sm-12 col-md-10 col-lg-6 col-xl-5'>
          <InputBox
            id={'org-postalcode'}
            className={'org-postalcode'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.postalCode')}
            fieldName={'organization.address.postalCode'}
            defaultValue={address?.postalCode}
            required={true}
            rules={{
              required: requiredFieldTranslation,
              validate: wordLengthValidate10
            }}
            errorMsg={errors.organization?.address?.postalCode?.message}
            disabled={(address?.postalCode && props?.disabled) ? true : false}
            InputProps={{
              readOnly: (address?.postalCode && props?.disabled) ? true : false,
              classes: {
                disabled: (address?.postalCode && props?.disabled) ? props.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'mission-prpose'}
            className={'mission-purpose'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            multiline={true}
            rows={4}
            label={t('orgRegForm.labels.missionAndPurpose')}
            fieldName={'organization.missionAndPurpose'}
            defaultValue={missionAndPurpose}
            required={true}
            rules={{
              required: (missionAndPurpose && disabled) ?
                false : requiredFieldTranslation
            }}
            errorMsg={errors?.organization?.missionAndPurpose?.message}
            disabled={disabled}
            InputProps={{
              readOnly: disabled,
              classes: {
                disabled: disabled ? props.classes.disabled : ''
              }
            }}
          />
        </div>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'geographic-coverage'}
            className={'geographic-coverage'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            multiline={true}
            rows={4}
            label={t('orgRegForm.labels.geographicCoverage')}
            fieldName={'organization.geographicCoverage'}
            defaultValue={geographicCoverage}
            required={true}
            rules={{
              required: disabled ? false : requiredFieldTranslation
            }}
            errorMsg={errors?.organization?.geographicCoverage?.message}
            disabled={disabled}
            InputProps={{
              readOnly: disabled,
              classes: {
                disabled: disabled ? props.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'org-website'}
            className={'org-website'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.website')}
            fieldName={'organization.website'}
            defaultValue={website}
            required={false}
            rules={{
              required: false
            }}
            errorMsg={errors?.organization?.website?.message}
            disabled={disabled}
            InputProps={{
              readOnly: disabled,
              classes: {
                disabled: disabled ? props.classes.disabled : ''
              }
            }}
          />
        </div>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'org-establishedDate'}
            className={'org-establishedDate'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.establishedDate')}
            fieldName={'organization.establishedDate'}
            defaultValue={''}
            placeholder='dd-mm-yyyy'
            required={true}
            rules={{
              required: requiredFieldTranslation,
              validate: (data: any) => validateOrgEstablishedDate(data, t)
            }}
            errorMsg={errors?.organization?.establishedDate?.message}
            disabled={disabled}
            InputProps={{
              readOnly: disabled,
              classes: {
                disabled: disabled ? props.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
            <InputBox
              id={'org-telephone'}
              className={'org-telephone'}
              classes={props.classes}
              formErrors={errors}
              control={control}
              label={t('orgRegForm.labels.telephone')}
              fieldName={'organization.telephone'}
              defaultValue={''}
              placeholder=''
              required={true}
              rules={{
                required: requiredFieldTranslation,
              }}
              errorMsg={errors?.organization?.telephone?.message}
              disabled={disabled}
              InputProps={{
                readOnly: disabled,
                classes: {
                  disabled: disabled ? props.classes.disabled : ''
                }
              }}
            />
          </div>
      </div>
    </div>
  )
}
