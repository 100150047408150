/* eslint-disable */

import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import withRouter from "src/polyfils/customRouter";
import { BreadcrumbsComponent } from "../../../components/common/index";
import { connect } from "react-redux";
import "./styles.css";
import {
  getOrgProfile,
  saveOrgProfile,
} from "src/state/OrgProfile/orgProfileActions";
import { GlobalDonationState } from "../../../models/globalDonationState";
import RecepientDetails from "./components/RecepientDetails";
import { contextPath } from "src/constants";
import {  validatePrimaryAndSecondaryEmail } from "./../../common/utils";
import { RdaCheck } from "../CommonComponents/commonApi";

import {
  Heading,
  Alert,
  Button,
  ButtonGroup,
  Spinner,
} from "@walmart-web/livingdesign-components";

import {
  usePostEmergencyDonationNGO,
} from "./common/emergencyDonationsApi";

import { FacilitySearchComponent } from "src/components/common/FacilitySearchComponent";
import CommonLDModal, {
  CommonModalProps,
} from "src/components/common/CommonLDModal";
import {
  addDonationbreadcrumbsData,
  defaultFacilityType,
} from "./common/DataTypes";

export interface OwnProps {
  history: any;
  selectedLang?: any;
}
export interface StateProps {
  orgProfile: any;
}

export interface DispatchProps {
  editOrgProfile: (data: any) => void;
  getOrgProfile: (data: any) => void;
}
const EmergencyDonations: FC<OwnProps & DispatchProps & StateProps> = ({
  history,
  getOrgProfile,
  orgProfile,
  selectedLang,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [isLocationMandatory, setIsLocationMandatory] = useState(false);

  const mutate = usePostEmergencyDonationNGO(history, setError);
  const rdaCheckQuery = RdaCheck(setIsLocationMandatory, setError);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CommonModalProps>({
    header: "Modal",
    children: "Modal",
  });

  const [isContinued, setisContinued] = useState<boolean>(false);

  const [donationData, setDonationData] = useState({
    donationProducts: "",
    purposeOfDonation: "",
    locationType: "STORE",
    locationKnown: "No",
    primaryEmail: "",
    secondaryEmail: "",
    primaryEmailError: "",
    secondaryEmailError: ""
  });

  const [isStoreRadioMandatory] = useState<boolean>(false);
  const [showStoreSearchDialog, setShowStoreSearchDialog] = useState(false);
  const [selectedFacility, setSelectedFacility] =
    useState<any>(defaultFacilityType);

  const handleFacilityChange = (faciltyDetails: any) => {
    setSelectedFacility({
      ...faciltyDetails,
      loading: true,
    });
    setShowStoreSearchDialog(false);
  };

  const handleTargetChange = (donationField: any, value: any) => {
    let newVal: any = { ...donationData };
    newVal[donationField] = value;
    setDonationData(newVal);
  };

  const handleModalAction = (show: boolean) => {
    setShowStoreSearchDialog(show);
  };

  // Get org details via api calls from redux
  useEffect(() => {
    const email: string = localStorage.getItem("userEmail") || "";
    getOrgProfile({ email });
  }, []);

  useEffect(() => {
    if (orgProfile.id) {
      setLoading(false);
      rdaCheckQuery.mutate({
        orgTypes: [
          "org_type_ngo",
          "org_type_governmental",
          "org_type_individual",
        ],
        marketCode: orgProfile.countryCode,
        donationType: "EMERGENCY_DONATION",
      });
    }
  }, [orgProfile]);

  // Submit for add donations flow
  const onSubmit = () => {
    let payload: any = {
      orgId: orgProfile.id,
      countryCode: orgProfile.countryCode,
      type: orgProfile.organizationType?.keyText,
      isLocationTypeSelected: false,
      donationDetails: {
        purposeOfDonation: donationData.purposeOfDonation,
        donationProducts: donationData.donationProducts,
        locationType: "NA",
      },
      requestSourceApp: "EXTERNAL",
    };
    if (donationData.locationKnown === "Yes") {
      payload["isLocationTypeSelected"] = true;
      payload.donationDetails = {
        ...payload.donationDetails,
        locationType: donationData.locationType.toUpperCase(),
      };
      if (selectedFacility.facilityNo !== "") {
        payload.donationDetails.pickUpAddress = selectedFacility;
        payload["donationDetails"]["primaryEmail"] =
          donationData.primaryEmail;
        payload["donationDetails"]["secondaryEmail"] =
          donationData.secondaryEmail;
        if (donationData.locationType === "STORE") {
          payload["donationDetails"]["storeNumber"] =
            selectedFacility.facilityNo;
        } else {
          payload["donationDetails"]["dcNumber"] = selectedFacility.facilityNo;
        }
      }
    }

    mutate.mutate(payload);
  };

  // Modal definitions
  const submitModal: CommonModalProps = {
    header: t("emergencyDonations.form.submitHead"),
    children: t("emergencyDonations.form.submitHelper"),
    buttons: [
      {
        variant: "tertiary",
        callback: () => {
          setModalOpen(false);
        },
        text: t("emergencyDonations.form.cancel"),
      },
      {
        variant: "primary",
        callback: () => {
          onSubmit();
        },
        text: t("common.buttonLabels.submitBtn"),
      },
    ],
  };

  // Modal definitions
  const discardModal: CommonModalProps = {
    header: t("emergencyDonations.form.discardHead"),
    children: t("emergencyDonations.form.discardHelper"),
    buttons: [
      {
        variant: "tertiary",
        callback: () => {
          setModalOpen(false);
        },
        text: t("emergencyDonations.form.cancel"),
      },
      {
        variant: "primary",
        callback: () => {
          history.push(
            `${contextPath}/dashboard/emergency-donation?type=emergency-donation`
          );
        },
        text: t("emergencyDonations.form.discard"),
      },
    ],
  };

  // Add loader while any data is loading
  if (loading || mutate.isLoading || rdaCheckQuery.isLoading) {
    return (
      <div className='flex-container'>
        <div className='row'>
          <div className='flex-item'>
            <Spinner
              color='gray'
              size='large'
              className='emergency-donation-loader'
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <>
        <BreadcrumbsComponent
          breadcrumbsData={addDonationbreadcrumbsData}
          history={history}
        />

        <div style={{ paddingTop: "5px", marginTop: "0px" }}>
          <Heading as='h2' size='medium' weight={700}>
            {t("emergencyDonations.form.newDonation")}
          </Heading>
        </div>

        {orgProfile && (
          <RecepientDetails
            history={history}
            data={orgProfile}
            editable
            orgType={orgProfile.organizationType?.keyText}
            isContinued={isContinued}
            selectedFacility={selectedFacility}
            setSelectedFacility={setSelectedFacility}
            setShowStoreSearchDialog={setShowStoreSearchDialog}
            handleTargetChange={handleTargetChange}
            donationData={donationData}
            isStoreRadioMandatory={isStoreRadioMandatory}
            setDonationData={setDonationData}
            isLocationMandatory={isLocationMandatory}
          />
        )}

        {error || mutate.data?.errorMessage || mutate.error ? (
          <Alert variant='error' style={{ marginBottom: "10px" }}>
            {t("emergencyDonations.form.errorResponse")}
          </Alert>
        ) : null}

        <div style={{}}>
          <ButtonGroup>
            <Button
              data-testid='cancel'
              onClick={() => {
                setModalData(discardModal);
                setModalOpen(true);
              }}
            >
              {t("emergencyDonations.form.cancel")}
            </Button>

            <Button
              data-testid='continue'
              onClick={() => {
                setisContinued(true);


                if (donationData.locationKnown === "Yes") {
                  let outcome = validatePrimaryAndSecondaryEmail(donationData, t("emergencyDonations.location.primaryEmailError") ,
                  t("emergencyDonations.location.emailError") , handleTargetChange);
                  //Stop Form submission if error in primary and Secondary email
                  if (outcome && selectedFacility.facilityNo !== "")
                    return;
                }
                // const isInvalidEmail =
                //   !validateWalmartEmail(donationData.primaryEmail) ||
                //   (donationData.secondaryEmail.length > 0 &&
                //     !validateWalmartEmail(donationData.secondaryEmail));

                const isMandatoryStoreNotSelected =
                  donationData.locationKnown === "Yes" &&
                  isLocationMandatory &&
                  donationData.locationType === "STORE" &&
                  selectedFacility.facilityNo === "";

                // const isEmailNotFilled = selectedFacility.facilityNo !== "" && isInvalidEmail;

                if (
                  donationData.purposeOfDonation.trim().length < 2 ||
                  donationData.donationProducts.length < 2 ||
                  isMandatoryStoreNotSelected
                  // isEmailNotFilled
                ) {
                  return;
                }
                // if (donationData.locationKnown === "Yes") {
                //   let outcome = validatePrimaryAndSecondaryEmail(donationData, t("emergencyDonations.location.primaryEmailError") ,
                //   t("emergencyDonations.location.emailError") , handleTargetChange);
                //   //Stop Form submission if error in primary and Secondary email
                //   if (outcome)
                //     return;
                // }

                setModalData(submitModal);
                setModalOpen(true);
              }}
              variant='primary'
            >
              {t("emergencyDonations.form.continue")}
            </Button>
          </ButtonGroup>
        </div>
      </>

      <CommonLDModal
        setOpen={setModalOpen}
        isOpen={isModalOpen}
        modalConfig={modalData}
      ></CommonLDModal>

      {showStoreSearchDialog && (
        <FacilitySearchComponent
          countryCode={orgProfile?.countryCode}
          selectedLang={selectedLang}
          onFaciltySelection={handleFacilityChange}
          handleModalAction={handleModalAction}
          showModal={showStoreSearchDialog}
          fetchType={donationData.locationType.toLowerCase()}
        />
        
        )}
      </>
  );
};

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
  orgProfile: state.orgProfile.orgProfile
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
  editOrgProfile: (data: any) => dispatch(saveOrgProfile(data)),
  getOrgProfile: (data: any) => dispatch(getOrgProfile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmergencyDonations)
);
