//import { useState } from "react";
import { TextField } from "@walmart-web/livingdesign-components";
import { useState } from "react";
import { contextPath } from "../../../constants";
import { defaultButton } from "../../../models/GenericDashBoardModel";
import { getBreadCrumbDashBoardData } from "../CommonComponents/BreadCrumbRouting";
import { createButtons } from "../commonFunctions";

export const spacePageType = 'space-international';

export const discardModal = (t: any, onClose: any, onFormDiscard: any) => {
    return {
        header: t("spaceDonations.form.discardHead"),
        children: t("spaceDonations.form.discardHelper"),
        buttons: createButtons(() => onClose(false), () => { onFormDiscard() },
            t("spaceDonations.form.cancel"), t("spaceDonations.form.discard"), "", "primary")
    }
};

export const continueModal = (t: any, onClose: any, onFormSubmit: any) => {
    return {
        header: t("spaceDonations.form.submitHead"),
        children: t("spaceDonations.form.submitHelper"),
        buttons: createButtons(() => onClose(false), () => { onFormSubmit() },
            t("spaceDonations.form.cancel"), t("spaceDonations.form.submit"), "tertiary", "primary")
    }
};

const dashBoardStatusActions = [
    "UNDER_RDA_REVIEW",
    "PENDING_APPROVAL",
    "AWAITING_RDA_ACKNOWLEDGEMENT",
    "CANCELLATION_DUE_TO_INACTIVITY",
    "APPROVED",
    "REJECTED",
    "CANCELLED",
    "COMPLETED"
];

export const getDashboardStatusActions = () => {
    return dashBoardStatusActions;
}

export const getMyDashboardStatusActions = () => {
    return ["PENDING_APPROVAL"];
}

export const ModalTextBox = (props: any) => {
    const [value, setValue] = useState<string>("");
    const handleChange = (event: any) => {
        setValue(event.target.value);
        window.modalBoxComments = event.target.value;
    }

    return (
        <TextField
            data-testid="space-donation-event-input"
            label={props.label}
            className={"commentBox-Text"}
            onChange={handleChange}
            size="large"
            value={value}
            placeholder={props.placeHolder}
        />
    )
}

export const cancelModal = (t: any, onClose: any, onDonationCancel: any, action: string) => {
    return {
        header: t("spaceDonations.form.cancelHead"),
        children: <ModalTextBox placeHolder={t("spaceDonations.form.commentPlaceHolder")}
            label={t("spaceDonations.form.cancelHelper")} />,
        buttons: createButtons(() => onClose(false), () => { onDonationCancel(action) },
            t("spaceDonations.form.discard"), t("spaceDonations.form.cancelProceed"), "", "primary")
    }
};

export const acceptModal = (t: any, onClose: any, onDonationAccept: any, action: string) => {
    return {
        header: t("spaceDonations.form.alternateProposalHead"),
        children: <ModalTextBox placeHolder={t("spaceDonations.form.commentPlaceHolder")}
            label={t("spaceDonations.form.alternateProposalHelper")} />,
        buttons: createButtons(() => onClose(false), () => { onDonationAccept(action) },
            t("spaceDonations.form.discard"), t("spaceDonations.form.alternateProposalProceed"), "", "primary")
    }
};

export const createPayloadForAction = (
    data: any,
    comments: any,
    action: string
) => {

    let spaceObject = { ...data.spaceAndServiceDonation, comments: comments };
    const payload = {
        ...data,
        spaceAndServiceDonation: spaceObject,
        waaSTask: {
            taskId: data.waaSTaskMessage.taskId,
            requestId: data.spaceAndServiceDonation.id,
            taskName: data.waaSTaskMessage.taskName,
            variables: {
                userAction: action,
            },
        },
    };

    const form = new FormData();
    form.append("donationWaaSTask", JSON.stringify(payload));
    return form;
};

export const getDashboardTabFilters = (tabNumber: number, lang: string) => {
    let dashboardActions: any = getMyDashboardStatusActions();
    if (tabNumber === 1) {
        dashboardActions = getDashboardStatusActions();
    }
    return {
        selectedLang: lang,
        defaultFilters: {
            currentStatusCode: {
                value: dashboardActions,
                matchMode: 'equals'
            }
        },
        tableRowFilters: {
            selectedDonationFrom: '',
            selectedServiceType: ''
        },
        showStatusFilter: false,
        showExportExcel: true
    }
}

export const getTabsGroup = (t: any, lang: string) => {
    return [
        { label: t("spaceDonations.tabs.myDonationTitle"), isActive: true, tableFilters: getDashboardTabFilters(0, lang) },
        { label: t("spaceDonations.tabs.allDonationTitle"), isActive: true, tableFilters: getDashboardTabFilters(1, lang) }
    ];
}

const onAdd = (history: any) => {
    history.push({ pathname: `${contextPath}/add/${spacePageType}?type=${spacePageType}` });
}

export const topButtons = (t: any, history: any) => {
    // In this usecase i want only one Button
    return [{
        ...defaultButton,
        buttonText: t("spaceDonations.form.newDonation"),
        callback: () => onAdd(history),
        buttonId: 'space-add-donation-button-id',
        variant: "primary",
        className: ""
    }
    ];
}

export const getDashBoardData = (t: any, lang: string, history: any) => {
    return {
        pageTitle: t("spaceDonations.dashboard.heading"),
        breadcrumbs: getBreadCrumbDashBoardData("spaceDonations"),
        tabs: getTabsGroup(t, lang),
        topRightHeaderButtons: topButtons(t, history),
        isTabs: true
    };
}

export const formatDateTimeForReadOnly = (dateValue: string, timeValue: string) => {
    const splitDate = dateValue.split("-");
    const convertedDate = splitDate[1] + '/' +  splitDate[2] + '/' +  splitDate[0];
    return convertedDate + " " + timeValue;
}
