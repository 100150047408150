import {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomAccordion} from '../../../common/Accordion/CustomAccordion';
import {ProjectDetailsTabProps, Sections} from './projectDetailsDefaults';
import LogisticsAdminQuestions from './logisticsAdminQuestions';
import {ProjectLocation} from './projectLocation';
import {ProjectBeneficiaries} from './projectBeneficiaries';
import BeneficiaryInformation from './beneficiaryInformation';


const GoodsAndFoodDonationTab: FC<ProjectDetailsTabProps> = ({projectDetailsData, isFormEditable, errors}) => {

    const { t } = useTranslation();
    const getTranslations = (key: string) => {
        return t(`monetaryDonation.projectDetailsSection.${key}`)
    }
    return <Fragment>
            <CustomAccordion title={getTranslations('projectLocation')} isEditable={isFormEditable}
                id = 'projectLocation'
                hasError={(Sections.ProjectLocation in errors)}>
                <ProjectLocation fieldName={Sections.ProjectLocation} data={projectDetailsData.locations} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('beneficiaryInformation')} isEditable={isFormEditable}
                id = 'beneficiaryInformation'
                hasError={(Sections.BeneficiaryInformation in errors)}>
                <BeneficiaryInformation fieldName={Sections.BeneficiaryInformation} data={projectDetailsData.beneficiaryInformation} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectBeneficiaries')} isEditable={isFormEditable}
                id = 'projectBeneficiaries'
                hasError={(Sections.ProjectBeneficiaries in errors)}>
                <ProjectBeneficiaries fieldName={Sections.ProjectBeneficiaries}
                    data={projectDetailsData.beneficiaries} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('logisticsAdminQn')} isEditable={isFormEditable}
                id = 'logisticsAdminQn'
                hasError={(Sections.LogisticsAdminQuestions in errors)}>
                <LogisticsAdminQuestions fieldName={Sections.LogisticsAdminQuestions}
                    data={projectDetailsData.logisticsAdminQuestions} />
            </CustomAccordion>
    </Fragment>
}

export default GoodsAndFoodDonationTab;