import { IDropdownOptions } from './Dropdown/keyValueDropdown';

const moment = require('moment');
const dateFormat = 'DD-MM-YYYY';
const dateFormatReqexDDMMYYY = /^(0?[1-9]|[12][0-9]|3[01])[\-](0?[1-9]|1[012])[\-]\d{4}$/;

export function getDateFormat() {
    return dateFormat;
}

export function getMonthDateRange(date: any) {
    const d = new moment(date, dateFormat);
    const startOfMonth = d.clone().startOf('month');
    const endOfMonth = d.clone().endOf('month');
    return {
        startDate: startOfMonth.format(dateFormat),
        endDate: endOfMonth.format(dateFormat)
    };
}

export function getDate(offsetDays: any, hour: any) {
    offsetDays = offsetDays || 0;
    const offset = offsetDays * 24 * 60 * 60 * 1000;
    const date = new Date(new Date().getTime() + offset);
    if (hour) {
        date.setHours(hour);
    }
    return date;
}

export function getCurrentFiscalYearDates() {
    const startDate = getFormattedDate(moment().month('February').startOf('month'));
    const endDate = getFormattedDate(moment().add(1, 'years').month('January').endOf('month'));
    return {
        startDate,
        endDate
    }
}

export function getAbsoulteMonths(momentDate: any) {
    const months = Number(momentDate.format('MM'));
    const years = Number(momentDate.format('YYYY'));
    return months + (years * 12);
}

// Get todays date DD-MM-YYYY string format
export const getTodaysDate = () => {
    return getFormattedDate(new Date());
}

// Get any date DD-MM-YYYY string format
// @ts-ignore
export const getFormattedDate = (dateValue: any) => {
    if (dateFormatReqexDDMMYYY.test(dateValue)) {
        if (moment(dateValue, dateFormat, true).isValid()) {
            return dateValue;
        }
        else{
            return moment(dateValue, 'MM-DD-YYYY')
        }
    } else {
        if (dateValue?.toString()?.endsWith('T00:00:00.000+00:00')) {
            return convertFromUtcToLocal(dateValue);
        } else {
            return moment(new Date(dateValue)).format(dateFormat) ? moment(new Date(dateValue)).format(dateFormat) : dateValue;
        }
    }
}

// Get Date from UTC to Local Date without offset/ZoneId conversion. Can be used for date of birth, org establishment date etc.
// @ts-ignore
export const convertFromUtcToLocal = (dateValue: any) => {
    if (dateValue) {
        if (dateFormatReqexDDMMYYY.test(dateValue)) {
            if (moment(dateValue, dateFormat, true).isValid()) {
                return dateValue;
            }
            else{
                return moment(dateValue, 'MM-DD-YYYY')
            }
        }
        const utc = (new Date(dateValue)).toUTCString();
        const newDate = moment()
            .set('year', new Date(utc).getUTCFullYear())
            .set('month', (new Date(utc).getUTCMonth()))
            .set('date', (new Date(utc).getUTCDate()));
        return getFormattedDate(newDate);
    } else {
        return dateValue;
    }
}
// Get Date only in the Locale with format as an example "20 December 2020, GMT+5:30"
export const getDateOnlyForLocale = (dateValue: any) => {
    if (dateValue) {
        return getFormattedDate(dateValue);
    } else {
        return dateValue;
    }
}

// Get current year in YYYY string format
export const getCurrentYear = () => {
    const today = new Date();
    const currentYear = moment(today).format('YYYY');
    return currentYear;
};
export const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getQuarterStartAndEndDate = (quarter:number, year:number, yearFormat:string='YYYY') => {
    const start = moment(year, yearFormat).quarter(quarter).startOf('quarter');
    const end = moment(year, yearFormat).quarter(quarter).endOf('quarter');

    return {
        startDate:start.format('DD-MM-YYYY'),
        endDate: end.format('DD-MM-YYYY'),
        quarterStartMonth:start.month()
    }
}

export const getQuaetersDateRangeForAppendixC = () => {
    const years: number[] = [];
    const quartersList:IDropdownOptions[] = []
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2022; --i) {
        years.push(i);
    }
    years.forEach((year: number) => {
        for (let i = 1; i <= 4; i++) {
            const quarterDateRange:any = getQuarterStartAndEndDate(i, year)
            if (year === currentYear &&
                (quarterDateRange?.quarterStartMonth <= moment().month())) {
                quartersList.push({
                    label: `Q${i} (${year})`,
                    value: quarterDateRange
                })
            } else if(year < currentYear) {
                quartersList.push({
                    label: `Q${i} (${year})`,
                    value: getQuarterStartAndEndDate(i, year)
                })
            }
        }
    })
    return quartersList
}
