import { withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import styles from '../../menu/ThemeStyle';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { contextPath, DONATION_TYPE } from '../../../constants';
import RecurringGoodsAppendixC from './RecurringGoodsAppenidxC';
import LoaderComponent from '../../../components/LoaderComponent';

interface OwnProps {
    selectedData: any
    language: string;
}

export interface StateToProps {
    selectedData: any
    language: string;
}

class AppendixcContainer extends React.PureComponent<OwnProps & RouteComponentProps & StateToProps> {
    public state = {
        donationId: '',
        donationType: new URLSearchParams(this.props.location.search).get('type') ?? DONATION_TYPE.GOODSANDFOOD,
    };
    public async componentDidMount() {
        let donationId = '';
        if (this.state.donationType === DONATION_TYPE.GOODSANDFOOD) {
            donationId = this.props?.selectedData?.donationOfGoods?.id;
            this.setState({
                donationId
            })
        }

        if (!donationId) {
            this.reDirectToDashboard();
        }
    }
    private reDirectToDashboard() {
        this.props.history.push(`${contextPath}/dashboard/donation-goods?type=${this.state.donationType}`);
    }
    public render() {
        return (<>
            {this.state?.donationId ?
                <RecurringGoodsAppendixC
                    donationId={this.state?.donationId}
                    selectedLang={this.props?.language}
                /> : <LoaderComponent fullScreen={true} />}
        </>)
    }
}
const mapStateToProps = (state: GlobalDonationState): StateToProps => ({
    selectedData: state.selectedDataState.addDataToStore,
    language: state.selectedLang
});

export default withStyles(styles)(withTranslation()(withRouter(connect(mapStateToProps, null)(AppendixcContainer))));
