import React, { useEffect } from 'react';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import { Panel, Checkbox, Chip , Badge } from '@walmart-web/livingdesign-components';
import CalenderPicker from './MultiDatePickerForSelect';
import './NationalCampaignEvent.css';
import moment from 'moment';
import _ from 'lodash';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useMutation } from 'react-query';
import { contextPath } from 'src/constants';
import { useNavigate } from 'react-router';
const service: Service = SpaceDonationService;

type LegendTypes = 'selected' | 'pending' | 'available' | 'notAvailable' | 'approved' | 'optedOut';
export interface PanelMoreDaysProps {
  buttonTitle: string;
  confirmButtonDisabled?: boolean;
  panelContent?: string;
  hideAllAlerts: any;
  panelContentHeading?: string;
  data: any;
  mutation: any;
  timeSlots?: any[];
  selectedDates: any[];
  approvedDates: any[];
  submittedDates: any[];
  rejectedDates: any[];
  disabledDates: any[];
  disabled: boolean;
  minDate: any;
  maxDate: any;
  defaultCalendarMonth: any;
  numDaysApproved?: any;
  numDaysRejected?: any;
  numDaysSubmitted?:any;
  maxAllowedDays: number;
  consecutiveDaysRestriction?: boolean;
  restrictedDays?: number;
  proposedDatesWithSlots: any[];
  status?: string;
  availableEditsLeft?:number;
  editEventFlag?:boolean;
}

export const getInitialSelectedTimeSlots = (existingSlots: any[], eventSlots: any[]) => {
  return (existingSlots || [])
    .filter(item => (item.storeStatus === 'SUBMITTED' || (item.storeStatus === 'APPROVED' && moment(item.eventDateString).diff(moment(),'days') >= 3)) && moment(item.eventDateString).isSameOrAfter(moment()))
    .map((item: any) => {
      let selectedEventSlots = eventSlots.map((slot) => {
        const selected = item.eventSlots.find((s: any) => s.slotId === slot.slotId);
        return { ...slot, selected: !!selected, status: selected?.status }
      });

      // manage disabled slot
      if (item.eventSlots.length > 1) {
        const isAllDaySlot = item.eventSlots.find((s: any) => s.slotId === 0);
        if (isAllDaySlot) {
          selectedEventSlots = selectedEventSlots.map((s: any) => ({ ...s, isDisabled: false }));
        } else {
          selectedEventSlots = selectedEventSlots.map((s: any) => ({ ...s, isDisabled: s.slotId === 0 }));
        }
      } else if (item.eventSlots.length === 1) {
        const isAllDaySlot = item.eventSlots[0].slotId === 0;
        if (isAllDaySlot) {
          selectedEventSlots = selectedEventSlots.map((s: any) => ({ ...s, isDisabled: s.slotId !== 0 }));
        } else {
          selectedEventSlots = selectedEventSlots.map((s: any) => ({ ...s, isDisabled: s.slotId === 0 }));
        }
      }

      return {
        eventDate: moment(item.eventDateString).format('YYYY-MM-DD'),
        eventDateLabel: new Date(item.eventDateString),
        eventslots: selectedEventSlots
      }
    });
}

// for individual date slot selection
export const handleSelectionCallback = (prev: any, dateIdx: any, slotIdx: any) => {
  if (dateIdx > -1 && slotIdx > -1) {
    const selectedDateSlots = prev[dateIdx].eventslots;
    const selectedSlot = selectedDateSlots[slotIdx];
    const isSlotSelected = !selectedSlot.selected;
    prev[dateIdx].eventslots[slotIdx] = { ...selectedSlot, selected: isSlotSelected };

    if (isSlotSelected) {
      if (selectedSlot.slotId === 0) {
        prev[dateIdx].eventslots = selectedDateSlots.map((s: any) => {
          return { ...s, isDisabled: s.slotId !== 0 }
        });
      } else {
        prev[dateIdx].eventslots = selectedDateSlots.map((s: any) => {
          return { ...s, isDisabled: s.slotId === 0 }
        });
      }
    } else {
      if (selectedSlot.slotId === 0 || prev[dateIdx].eventslots.every((s: any) => !s.selected)) {
        prev[dateIdx].eventslots = selectedDateSlots.map((s: any) => {
          return { ...s, isDisabled: false }
        });
      }
    }
  }
  return [...prev]
}

// for apply all dates slot selection
export const handleSlot = (eventSlots: any, slot: any) => {
  const updatedSlots = [...eventSlots];
  const selectedSlotIndex = eventSlots.findIndex((st: any) => st.slotId === slot.slotId);
  if (selectedSlotIndex > -1) {
    const selectedSlot = updatedSlots[selectedSlotIndex];
    const isSlotSelected = !selectedSlot.selected;
    updatedSlots[selectedSlotIndex].selected = isSlotSelected;

    if (isSlotSelected) {
      if (selectedSlot.slotId === 0) {
        return updatedSlots.map((st: any) => {
          st.isDisabled = st.slotId !== 0;
          return st;
        });
      } else {
        return updatedSlots.map((st: any) => {
          st.isDisabled = st.slotId === 0;
          return st;
        });
      }
    } else {
      if (selectedSlot.slotId === 0 || updatedSlots.every((s: any) => !s.selected)) {
        return updatedSlots.map((s: any) => {
          return { ...s, isDisabled: false }
        });
      }
    }
  }
  return updatedSlots;
}

const Legend: React.FC<{ label: string, type: LegendTypes }> = ({ label, type }) => {
  const styles = {
    selected: 'selected-dates-legend-panel',
    pending: 'pending-legend-panel',
    available: 'available-dates-legend-panel',
    notAvailable: 'not-available-dates-legend-panel',
    approved : 'approved-legend-panel',
    optedOut: 'optedOut-legend-panel'
  }[type];
  const includeContent = ['available', 'notAvailable'].includes(type);
  return (
    <span className='d-flex align-items-center mx-1 legends-bottom'>
      <Badge UNSAFE_className={styles}>{includeContent ? '#' : ''}</Badge>
      <span style={{ fontSize: 12, margin: '0 6px' }}>{label}</span>
    </span>
  )
}
const PanelMoreDays: React.FunctionComponent<PanelMoreDaysProps> = ({
  selectedDates,
  approvedDates,
  submittedDates,
  rejectedDates,
  disabledDates,
  minDate,
  buttonTitle,
  panelContent,
  panelContentHeading,
  maxDate,
  defaultCalendarMonth,
  data,
  maxAllowedDays,
  timeSlots,
  consecutiveDaysRestriction,
  restrictedDays,
  proposedDatesWithSlots,
  status,
  availableEditsLeft,
  editEventFlag,
}) => {
  const history = useNavigate()
  const allowedDays = Math.min((moment(maxDate).diff(minDate, 'days') + 1), maxAllowedDays)
  const [showPanel, setShowPanel] = React.useState(false);
  const [eventSlots, setEventSlots] = React.useState<any[]>(timeSlots || []);
  const [showTimeSlot, setShowTimeSlot] = React.useState(false);
  const [indivTimeSlot, setIndivTimeSlot] = React.useState<any[]>([]);
  const [values, setValues] = React.useState<Date[]>([]);
  const [newSelectedDates, setNewSelectedDates] = React.useState([]);
  const [deselectedDates, setDeselectedDates] = React.useState([]);
  useEffect(() => {
    setIndivTimeSlot([]);
  }, []);
  useEffect(() => {
    setEventSlots((timeSlots || []).map((s: any) => ({ ...s, selected: false, isDisabled: false })));
  }, [timeSlots, showTimeSlot]);
  useEffect(() => {
    if (showPanel === false) {
      setDeselectedDates([]);
      setNewSelectedDates([]);
    }
  }, [showPanel]);

  useEffect(() => {
    if (proposedDatesWithSlots && showPanel) {
      setIndivTimeSlot(getInitialSelectedTimeSlots(proposedDatesWithSlots, eventSlots));
    }
  }, [proposedDatesWithSlots, showPanel]);

  useEffect(() => {
    // reset the preselected slots while unchecking `Apply the same slot`
    if (proposedDatesWithSlots && !showTimeSlot) {
      const initialSelection = getInitialSelectedTimeSlots(proposedDatesWithSlots, eventSlots);
      setIndivTimeSlot(prev => {
       return [...prev].map((item: any) => {
        const isSelectedInitially = initialSelection.find((dt: any) => dt.eventDate === item.eventDate);
        if (isSelectedInitially) {
          return isSelectedInitially;
        } else {
          item.eventslots = (timeSlots || []).map((s: any) => ({ ...s, selected: false, isDisabled: false }));
          return item;
        }
       })
      });
    }
  }, [proposedDatesWithSlots, showTimeSlot]);

 

  const selectionHandler = (dateIdx: any, slotIdx: any) => {
    setIndivTimeSlot((prev: any) => handleSelectionCallback(prev, dateIdx, slotIdx))
  }

  const setCheckboxSelection = () => setShowTimeSlot(prev => !prev);

  const _mutation = useMutation((AddMoreDatesInfo: any) => service.CalenderAddMoreDatesInfo(AddMoreDatesInfo));
  const confirmHandler = () => {
    const updatedProsposeEvent = indivTimeSlot.map(et => {
      return {
        eventDate: et.eventDate,
        eventSlots: ((et.eventslots.filter((st: { selected: boolean; }) => st.selected === true)) || [])
          .map((tempdata: any) => {
            if(tempdata.status === "APPROVED" || tempdata.status === "REJECTED"){
              return { ..._.omit(tempdata, ['selected', 'isDisabled'])}
            } 
            return { ..._.omit(tempdata, ['selected', 'isDisabled']), status: 'SUBMITTED' }
          })
      }
    })
    const payload = {
      ncId: data?.ncId,
      orgId: data?.spaceOrganizationId,
      storeNumber: data?.storeNbr,
      datesAdded: updatedProsposeEvent,
      datesRemoved: deselectedDates.map(d => moment(d).format('yyy-MM-DD'))
    }
    _mutation.mutate(payload);
    setShowPanel(false);
    history(`${contextPath}/space-donation/events/ngo-detail`, {
      state: {
        ncId: data?.ncId,
        orgId: data?.spaceOrganizationId
      }
    });
  }

  const isSlotSelectedForAllDates = indivTimeSlot.every((t) => t.eventslots.filter((ev: any) => ev.selected).length > 0);
  return (
    <>
      <LDButton
        type='submit'
        variant='secondary'
        data-testid='manage-button'
        onClick={() => {
          setShowPanel(true);
        }}
      >
        {buttonTitle}
      </LDButton>
      <Panel
        isOpen={showPanel}
        data-testid='closepanel-button'
        onClose={() => {
          setShowPanel(false);
          setValues([]);
          setIndivTimeSlot([]);
          setShowTimeSlot(false);
          setEventSlots((timeSlots || []).map((s: any) => ({ ...s, selected: false, isDisabled: false })));
        }}
        position='right'
        size='small'
        title={buttonTitle}
        UNSAFE_className='nc-event-manage-panel'
      >
        <div>
          {panelContentHeading && (
            <h6 className='heading-panel'>{panelContentHeading}</h6>
          )}
          {/* DO NOT remove next `div` or `id` attribute. Some content will be
              rendered using React Portals from CalenderPicker Component */}
          <div id='panel-head-content' />
          <CalenderPicker
            selectedDates={selectedDates}
            approvedDates={approvedDates}
            submittedDates={submittedDates}
            rejectedDates={rejectedDates}
            newSelectedDates={newSelectedDates}
            setNewSelectedDates={setNewSelectedDates}
            deselectedDates={deselectedDates}
            setDeselectedDates={setDeselectedDates}
            disabledDates={disabledDates}
            onChangeAvailable={({ addedDates, removedDates }) => {
              let updatedArr = addedDates.map((dt: any) => {
                if (values.includes(dt)) {
                  return indivTimeSlot.find(obj => obj?.eventDateLabel === dt);
                } else {
                  return {
                    eventDate: moment(new Date(dt)).format('YYYY-MM-DD'),
                    eventDateLabel: dt,
                    eventslots: JSON.parse(JSON.stringify(timeSlots))
                  };
                }
              });
              setValues(addedDates);

              const removed = removedDates.map(d => moment(d).format('YYYY-MM-DD'));
              const initiallySelectedSlots = getInitialSelectedTimeSlots(proposedDatesWithSlots, eventSlots);

              const updatedInviTimeSlots = updatedArr.filter((item: any) => !removed.includes(item?.eventDate));

              // add pending date (when pending -> available & available -> pending)
              initiallySelectedSlots.forEach(s => {
                const isRemoved = removed.includes(s.eventDate);
                const isSelected = updatedInviTimeSlots.find((t: any) => t.eventDate === s.eventDate);
                const isEdited = indivTimeSlot.find((t: any) => t.eventDate === s.eventDate);
                if (!isSelected && !isRemoved) {
                  updatedInviTimeSlots.push(isEdited ? isEdited : s);
                }
              });

              setIndivTimeSlot(updatedInviTimeSlots.sort((a: any, b: any) => a.eventDateLabel?.getTime?.() - b.eventDateLabel?.getTime?.()));
            }}
            disabled={false}
            minDate={minDate}
            maxDate={maxDate}
            defaultCalendarMonth={defaultCalendarMonth}
            consecutiveDaysRestriction={consecutiveDaysRestriction}
            restrictedDays={restrictedDays}
            totalDays={allowedDays}
            panelContent={panelContent}
            status={status}
            availableEditsLeft={availableEditsLeft}
            editEventFlag={editEventFlag}
          />
          <div className='reserve-date-time-legend-container'>
            <div className='reserve-date-time-mobile'>
              <div className='reserve-date-time-mobile-inner-div'>
                <Legend label='Approved' type='approved' />
                <Legend label='Pending' type='pending' />
                <Legend label='Selected' type='selected' />
              </div>
              <div className='reserve-date-time-mobile-inner-div'>
                <Legend label='Opted Out' type='optedOut' />
                <Legend label='Available' type='available' />
                <Legend label='Not available' type='notAvailable' />
              </div>
            </div>
          </div>
          <br />
          <div className='reserve-date_and_time-title'>Time slots</div>
          <div className='reserve-date_and_time-subtitle'>Please select your preferred time slots below.</div>
          <div className='reserve-date_and_time-subtitle'>*Required</div>
          <div className='d-flex align-items-center py-3'>
            <Checkbox
              name='slotSelectionCheckbox'
              checked={showTimeSlot}
              label='Apply the same time slot'
              className='pr-3'
              data-testid='checkbox'
              onChange={setCheckboxSelection}
              disabled={indivTimeSlot.length === 0 || editEventFlag === false}
            />
          </div>
          <div>
            {indivTimeSlot.length > 0 && showTimeSlot && eventSlots && eventSlots.map((slot: any, index1: any) => {
              {/*@ts-ignore*/}
              return <Chip className={slot.selected ? 'timeslot-chip selected' : 'timeslot-chip'}
                disabled={slot.isDisabled}
                placeholder={slot.label}
                key={index1} size='small'
                onClick={() => {
                  if (!slot.isDisabled) {
                    const updatedSlots = handleSlot(eventSlots, slot);
                    setEventSlots(updatedSlots);
                    setIndivTimeSlot(prev => {
                      const updated = prev.map((dt: any) => {
                        dt.eventslots = [...updatedSlots];
                        return dt;
                      })
                      return [...updated];
                    })
                  }
                }}
                selected={slot.selected}>
                {slot.label}
              </Chip>
            })}
          </div>
          {!showTimeSlot && indivTimeSlot && indivTimeSlot.map((obj, index) => {
            if (!obj) {
              return null;
            }
            const dt = new Date(obj.eventDateLabel);
            return <div key={index}>
              <div><h6>{moment(dt).format('MMM')}, {dt.getDate()}*</h6></div>
              <div className='timeslot-chip-wrapper'>
                {obj.eventslots && (obj.eventslots).map((slot: any, index1: any) => {
                  let className = 'timeslot-chip ';
                  let rejectFlag = false;
                  if (slot.selected) {
                    if (slot.status === 'APPROVED') {
                      className += 'approved';
                    } else if (slot.status === 'SUBMITTED') {
                      className += 'submitted';
                    } else if(slot.status === 'REJECTED') {
                      className += 'rejected';
                      rejectFlag = true;    
                    } else {
                      className += 'selected';
                    }
                  }
                  {/*@ts-ignore*/}
                  return <Chip className={className}
                    disabled={slot.isDisabled || editEventFlag === false || rejectFlag === true}
                    key={index1} size='small'
                    placeholder={slot.label}
                    data-testid='datetimechip'
                    onClick={() => !slot.isDisabled && selectionHandler(index, index1)}
                    selected={slot.selected}>
                    {slot.label}
                  </Chip>
                })}
              </div>
            </div>
          })}
        </div>
        <div>
          <LDButton
            className='panel-button-confirm'
            type='submit'
            variant='primary'
            data-testid='confirm-button'
            disabled={(showTimeSlot ? eventSlots.every((et: any) => !et.selected) : !isSlotSelectedForAllDates) || editEventFlag === false}
            onClick={confirmHandler}
          >
            Confirm
          </LDButton>
        </div>
      </Panel>
    </>
  );
};

export default PanelMoreDays;
