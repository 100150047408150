import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  Box
} from '@material-ui/core';
import SingleFileUpload from '../../common/formComponents/SingleFileUpload'
import GlobalDonationDataService from '../../../service';
import LoaderComponent from '../../../components/LoaderComponent';
import { IFileDetails } from '../moreDetailsSections/attachments/attachmentsTable';
import { DonationStatus } from 'src/constants';

interface OwnProps {
  resetFileUpload: boolean
  fileDownloader: (data: any) => void,
  donationId: string,
  isReview: boolean,
  fileDetails?: IFileDetails,
  showChinaMonetaryDialog: any,
  uploadLabel: string,
  label: string,
  isAppendixC?: boolean
  loadDonationData: any
  onDonationAck: any
  showPaymentAck?: boolean
}

let file: File | null = null;
const AppendixFileAttachment: FC<OwnProps> = ({
  resetFileUpload,
  fileDownloader,
  donationId,
  isReview,
  showChinaMonetaryDialog,
  uploadLabel,
  label,
  loadDonationData,
  onDonationAck,
  ...props
}) => {

  const {t} = useTranslation()
  const [isSubmitEnabled, enableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const uploadFile = (fileUploaded: File | null) => {
    file = fileUploaded;
    enableSubmit(file ? true: false);
  }

  const submitDonation = async () => {
    if(file?.name) {
      setLoading(true);
      const data =  await GlobalDonationDataService.submitChinaMonetary(donationId, file as File, props?.isAppendixC ?? false)
      if(data?.currentStatusCode === DonationStatus.SavedAppendixC) {
        loadDonationData(data)
      }
      showChinaMonetaryDialog(props?.isAppendixC);
      setLoading(false);

    }
  }

  return <>
          <Box my = {1} className = 'appendixBUpload'>
            <SingleFileUpload
            label = {t(label)}
            fileDownloader = {fileDownloader}
            onFileUpload = {uploadFile}
            deleteFile = {resetFileUpload}
            title = {t(uploadLabel)}
            file = {props?.fileDetails}
            readOnly = {isReview || props?.showPaymentAck}
            />
         {!isReview && <><button className='btn btn-primary btn-md'
              id = 'submitDonation'
              data-testid = 'submitDonation'
              disabled = {!isSubmitEnabled}
              onClick={submitDonation}>
              {props?.isAppendixC ? t('common.buttonLabels.upload'): t('common.buttonLabels.submitBtn')}
          </button>
          {props?.showPaymentAck && <button className='btn btn-primary btn-md'
              id = 'submitDonation'
              data-testid = 'submitDonation'
              disabled = {false}
              onClick={onDonationAck}>
              {t('common.buttonLabels.submitBtn')}
          </button>}
          </>}
          </Box>
          {loading && <LoaderComponent fullScreen={true} /> }
  </>
}

export default AppendixFileAttachment;
