import {TableCell, TableRow} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {useContext, useEffect} from 'react';
import BiLingualDropdown, {BiLingualDropdownContext, BiLingualDropdownTypes} from '../common/biLingualDropdown';
import {InputTextField} from '../../../common/Input/InputTextField';
import {useFormContext, useWatch} from 'react-hook-form';
import {CustomCell} from '../common/CustomCell';
import KeyvalueDropdown from 'src/components/common/Dropdown/keyValueDropdown';

interface ILocationRow {
    index: number;
    fieldName: string;
    errorKey: string;
    handleRemove: (index: number) => void;
    requiredFieldMsg: string,
    control: any,
    field: any
    errors: any,
    disableDelete: boolean
}

const OTHER_AREA: string = 'geographicArea_type_other';

const ProjectLocationRow = ({index, fieldName, errorKey, handleRemove,
    requiredFieldMsg, control, field, errors, disableDelete}: ILocationRow) => {

    const { stateList } = useContext(BiLingualDropdownContext);
    const geographicalLocation = useWatch<string>({
        name: `${fieldName}[${index}].geographicalLocation`,
        control
    });

    const {setValue} = useFormContext();

    useEffect(() => {
        if(geographicalLocation === OTHER_AREA) {
            setValue(`${fieldName}[${index}].others`,'')
        } else {
            setValue(`${fieldName}[${index}].others`,'NA')
        }
    }, [geographicalLocation]);
    return (<TableRow key={`row_${field.id}`} >
        <CustomCell >
            <BiLingualDropdown
            fieldName={`${fieldName}[${index}].geographicalLocation`}
            id={`${index}`}
                optionKey={BiLingualDropdownTypes.GEOGRAPHIC_AREAS_TYPE}
                errorKeyName={`${errorKey}.geographicalLocation`}
                errors = {errors}
                rules={{
                    required: requiredFieldMsg
                }}
                control = {control}
            />
        </CustomCell>
        <CustomCell  >
            <InputTextField
                required={true}
                fieldName={`${fieldName}[${index}].others`}
                id={`${index}`}
                defaultValue={field.others}
                errorKeyName={`${errorKey}.others`}
                disabled = {geographicalLocation !== OTHER_AREA}
                rules={{
                    required: requiredFieldMsg
                }}
            />
        </CustomCell>
        <CustomCell  >
            {
                <KeyvalueDropdown
                    id={`${index}`}
                    fieldName={`${fieldName}[${index}].state`}
                    defaultValue = {field.state}
                    options={stateList}
                    errors = {errors}
                    control = {control}
                    errorKeyName = {`${errorKey}.state`}
                />
            }
        </CustomCell>
        <TableCell >
            <InputTextField
                required={true}
                fieldName={`${fieldName}[${index}].city`}
                id={`${index}`}
                defaultValue={field.city}
                errorKeyName={`${errorKey}.city`}
                rules={{
                    required: requiredFieldMsg
                }}
            />
        </TableCell>
        <CustomCell>
            <IconButton
                color='default'
                disabled = {disableDelete}
                onClick={() => handleRemove(index)}
                aria-label='delete row' component='span'>
                <DeleteIcon fontSize='small' />
            </IconButton>
        </CustomCell>
    </TableRow>)
}
export default ProjectLocationRow;