import { Action } from 'redux';

export enum DonationOfMoneyActionTypes {
    SAVE_ORG_ATTACHMENT = 'SAVE_ORG_ATTACHMENT',
    SAVE_DONATION_ATTACHMENT = 'SAVE_DONATION_ATTACHMENT',
}

export interface SaveOrgAttachment extends Action {
    type: DonationOfMoneyActionTypes.SAVE_ORG_ATTACHMENT;
    data?: any;
}

export const saveOrgAttachment = (data: any): SaveOrgAttachment => ({
    type: DonationOfMoneyActionTypes.SAVE_ORG_ATTACHMENT,
    data
});

export interface SaveDonationAttachment extends Action {
    type: DonationOfMoneyActionTypes.SAVE_DONATION_ATTACHMENT;
    data?: any;
}

export const saveDonationAttachment = (data: any): SaveDonationAttachment => ({
    type: DonationOfMoneyActionTypes.SAVE_DONATION_ATTACHMENT,
    data
});


export type DonationOfMoneyActions = SaveOrgAttachment | SaveDonationAttachment;