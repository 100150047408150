import sagas from 'dynamic-form-components/lib/sagas';
import GlobalDonationService, { Service } from './service';
import { call, Effect, put, takeEvery } from 'redux-saga/effects';
import { notificationDataSuccess, notificationDataFailure, NotificationDataActionTypes } from './state/Globalnotification/notificationDataActions';
import { globalDonationDataFailure } from './state/globalDonationActions';
import { ConfigData, configFailure, configSuccess, ConfigActionTypes } from './state/configuration/configActions';
import { TraslationData, traslationSuccess, translationFailure, TraslationActionTypes } from './state/translations/translationActions';
import { registerUserActionTypes, RegisterUser, registerUserSuccess, registerUserFailure, verifyCodeSuccess, verifyCodeFailure, resetPasswordSuccess, resetPasswordFailure, requestVerificationCodeSuccess, requestVerificationCodeFailure, getSelectAreaSuccess, getSelectAreaFailure } from './state/userLogin/registerUserActions';
import { facilityLoadSuccess, facilityLoadFailure, FaccilitySearchActionTypes } from './state/facilitySearch/facilitySearchActions';
import { provinceLoadSuccess, provinceLoadFailure, ProvinceSearchActionTypes } from './state/provinceSearch/provinceSearchActions';
// import { submissionDataSuccess, submissionDataFailure } from './state/submission/submissionDataActions';
import { reportDataLoadSuccess, reportDataLoadFailure, ReportDataActionTypes } from './state/report/reportDataActions';
import { selectedDataLoadSuccess, selectedDataLoadFailure, SelectedDataActionTypes } from './state/selectedData/selectedDataActions';
import { orgProfileActionTypes, getOrgProfileSuccess, getOrgProfileFailure, saveOrgProfileSuccess, saveOrgProfileFailure, cityStateMappingDataLoadSuccess, cityStateMappingDataLoadFailure,countriesDataLoadSuccess,countriesDataLoadFailure } from './state/OrgProfile/orgProfileActions';
import { foodSurplusConfirmationSuccess, foodSurplusConfirmationFailure, FoodSurplusConfirmationTypes } from './state/foodSurplusConfirmation/foodSurplusConfirmationAction';
import { downloadfileDataLoadSuccess, downloadfileDataLoadFailure, DownloadFileDataActionTypes } from './state/downloadFile/downloadFileDataActions';
// const config = require(`${__dirname}/config.${process.env.NODE_ENV}.json`);
import { donationApprovalSuccess, donationApprovalFailure, DonationApprovalActionTypes } from './state/donationOfApproval/donationOfApprovalActions';

import { contextPath, landigPageUrl } from './constants';
import { loggedInUserLoadSuccess, loggedInUserLoadFailure, LoggedInUserActionTypes } from './state/loggedInUser/loggedInUserActions';
import { ManageWorkflowActionTypes, ViewOrDownloadFile, viewOrDownloadFileSuccess, viewOrDownloadFileFailure } from './state/ManageWorkflow/fileActions';
import { logoutUserSuccess, logoutUserFailure, LogoutUserActionTypes } from './state/logout/logoutUserActions';
import { DeleteFileActionTypes, deleteFileSuccess, deleteFileFailure } from './state/downloadFile/deleteFileActions';
import { SubmissionDataActionTypes, submissionDataSuccess, submissionDataFailure } from './state/submission/submissionDataActions';
import { DivisionDataActionTypes, divisionDataLoadSuccess, divisionDataLoadFailure } from './state/divisionData/divisionDataActions';
import {
    OrgUserManagementActionTypes,
    selectedUserChanged,
    addOrgUserSuccess,
    addOrgUserFailure,
    editOrgUserSuccess,
    editOrgUserFailure,
    removeOrgUserSuccess,
    removeOrgUserFailure,
    setAsPrimaryOrgUserSuccess,
    setAsPrimaryOrgUserFailure,
    sendTemporaryPasswordSuccess,
    sendTemporaryPasswordFailure,
    checkFirstTimeLogInSuccessful, saveOrgUserPasswordSuccessful, saveOrgUserPasswordFailure
    // saveOrgUserPasswordSuccessful,
    // saveOrgUserPasswordFailure
} from './state/orgUserManagement/orgUserManagementActions';
import { DonationOfGoodsActionTypes } from './state/donationOfGoods/donationOfGoodsAction';
import { getNgoPageDataSuccess, getNgoPageDataFailure } from './state/donationOfGoods/donationOfGoodsAction';
import {
    UploadDownloadActionTypes,
    uploadAssignmentSuccess,
    uploadAssignmentFailure,
    ViewOrDownloadAssignment,
    viewOrDownloadAssignmentSuccess,
    viewOrDownloadAssignmentFailure
} from './state/uploadDownloadFile/uploadDownloadFileActions';
import { select } from 'redux-saga/effects';
import { sidebarLoadFailure, SidebarLoadSuccess } from './state/sidebar/sidebarActions';

const config = require(`${__dirname}/config.${process.env.NODE_ENV}.json`);

const appi18n: any = {
    uploadSuccessMsg: {
        en: 'Successfully uploaded file',
        es: 'Archivo subido correctamente',
        fr: 'Fichier téléversé avec succès',
        ja: 'ファイルが正常にアップロードされました',
        zh: '成功上傳文件'
    }
}

export let service: Service = GlobalDonationService;

export function injectService(injectedService: Service) {
    service = injectedService;
}

export function* getNotificationData(action: any): Iterator<Effect> {
    const { getNotification } = service;
    try {
        // @ts-ignore
        const notificationResponse: any = yield call(getNotification(action.data));
        yield put(notificationDataSuccess(notificationResponse));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(notificationDataFailure(''));
        // yield put(globalDonationDataFailure(`Notification load failed!:${new Date()}`))
    }
}

export function* getConfigData(action: ConfigData): Iterator<Effect> {
    const { getConfigurations } = service;
    try {
        const configData: any = yield call(getConfigurations, action.data);
        yield put(configSuccess(configData));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        // yield put(configFailure(''));
        // yield put(globalDonationDataFailure(`Configuration load failed!:${new Date()}`))
    }
}

export function* getProvienceData(action: ConfigData): Iterator<Effect> {
    const { getConfigurations } = service;
    try {
        const configData: any = yield call(getConfigurations, action.data);
        yield put(provinceLoadSuccess(configData));
        // @ts-ignore
        window.provinceList = configData.provinceList;
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(provinceLoadFailure(''));
        // yield put(globalDonationDataFailure(`province load failed!:${new Date()}`))
    }
}

export function* getTranslationData(action: TraslationData): Iterator<Effect> {
    const { getTranslation } = service;
    try {
        const configData: any = yield call(getTranslation, action.data);
        yield put(traslationSuccess(configData));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(configFailure(''));
        yield put(translationFailure(`Translation load failed!:${new Date()}`))
    }
}

export function* registerUser(action: RegisterUser): Iterator<Effect> {
    const { registerNewUser } = service;
    try {
        const configData: any = yield call(registerNewUser, action.data);
        setTimeout(() => {
            const selectedCountry = localStorage.getItem('country') || '';
            const hostName = decodeURIComponent(encodeURIComponent(window.location.origin));
            // @ts-ignore
            const loginUrl = `${hostName}${contextPath}/Public/login?lang=${selectedCountry ? selectedCountry.toUpperCase() : 'EN_IN'}`
            window.location.href = loginUrl;
        }, 5000);
        yield put(registerUserSuccess(configData));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        const errorMessage = (e.response && e.response.data) ? e.response && e.response.data.errorMessage : '';
        yield put(registerUserFailure(errorMessage || 'Failed to register!'))
    }
}

export function* verificationCode(action: RegisterUser): Iterator<Effect> {
    const { getVerificationCode } = service;
    try {
        const configData: any = yield call(getVerificationCode, action.data);
        yield put(requestVerificationCodeSuccess(configData));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(requestVerificationCodeFailure(e.response.data.message || e.response.data.errorMessage || `Invalid email ID!`))
    }
}

export function* verifyCode(action: RegisterUser): Iterator<Effect> {
    const { validateCode } = service;
    try {
        const configData: any = yield call(validateCode, action.data);
        yield put(verifyCodeSuccess(configData));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(verifyCodeFailure(e.response.data.errorMessage || `Invalid verification code!`))
    }
}

export function* resetPassword(action: RegisterUser): Iterator<Effect> {
    const { changePassword } = service;
    try {
        const configData: any = yield call(changePassword, action.data);
        yield put(resetPasswordSuccess(configData));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(resetPasswordFailure(e.response.data.message || `Failed to reset password!`))
    }
}

export function* getFacilityData(action: any): Iterator<Effect> {
    const { getFacilityDetails } = service;
    try {
        const facilityData: any = yield call(getFacilityDetails, action.data);
        yield put(facilityLoadSuccess(facilityData));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(facilityLoadSuccess({}));
        yield put(facilityLoadFailure(`Search facility failed!:${new Date()}`))
        yield put(globalDonationDataFailure(`Search facility failed!!:${new Date()}`))
    }
}

export function* saveSelctedDataToStore(action: any): Iterator<Effect> {
    try {
        yield put(selectedDataLoadSuccess(action));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(selectedDataLoadFailure(`save submission data to state failed!:${new Date()}`))
    }
}

export function* downloadReport(action: any): Iterator<Effect> {
    const { downloaReport } = service;
    try {
        const response: any = yield call(downloaReport, action.data);
        yield put((reportDataLoadSuccess(response)));
        // window.open(response);
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(reportDataLoadFailure(`Download Report failed!:${new Date()}`))
        yield put(globalDonationDataFailure(`Download Report failed!:${new Date()}`))
    }
}
export function* downloadFile(action: any): Iterator<Effect> {
    const { downloadProjectFile } = service;
    try {
        const response: any = yield call(downloadProjectFile, action.data);
        yield put((downloadfileDataLoadSuccess(response)));
        // window.open(response);
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(downloadfileDataLoadFailure(`Download file failed!:${new Date()}`))
        yield put(globalDonationDataFailure(`Download pdf file failed!:${new Date()}`))
    }
}
export function* removeFile(action: any): Iterator<Effect> {
    const { deleteFile } = service;
    try {
        const response: any = yield call(deleteFile, action.data);
        yield put((deleteFileSuccess(response)));
        // window.open(response);
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(deleteFileFailure(`delete file failed!:${new Date()}`))
    }
}

export function* getSelectArea(action: any): Iterator<Effect> {
    const { getSelectAreaData, getOrganizationTypes } = service;
    try {
        const selectAreaDetails: any = yield call(getSelectAreaData, action.data);
        const orgTypes: any = yield call(getOrganizationTypes, action.data);
        // @ts-ignore
        window.selectAreaDetails = selectAreaDetails || [];
        // @ts-ignore
        window.organizationTypes = orgTypes || [];
        yield put((getSelectAreaSuccess({ selectAreaDetails, orgTypes })));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(getSelectAreaFailure(`Failed to load data!:${new Date()}`))
    }
}

export function* getOrgProfile(_action: any): Iterator<Effect> {
    const { getOrgProfileData } = service;
    try {
        const orgProfile: any = yield call(getOrgProfileData);
        yield put(getOrgProfileSuccess(orgProfile));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(getOrgProfileFailure(`Failed to load organization profile!:${new Date()}`))
    }
}

export function* saveOrgProfile(action: any): Iterator<Effect> {
    const { saveOrgProfileData } = service;
    try {
        const orgProfile: any = yield call(saveOrgProfileData, action.data);
        yield put(saveOrgProfileSuccess(orgProfile));
        yield put((loggedInUserLoadSuccess(orgProfile)));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(saveOrgProfileFailure((e.response && e.response.status === 400
            && e.response.data && e.response.data.errorMessage) ? e.response.data.errorMessage : ''))
    }
}

export function* foodSurplusConfirmation(action: any): Iterator<Effect> {
    const { foodSurplusConfirmationService } = service;
    try {
        const response: any = yield call(foodSurplusConfirmationService, action.data);
        yield put((foodSurplusConfirmationSuccess(response)));
        // window.open(response);
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(foodSurplusConfirmationFailure(e.response))
    }
}

export function* getLoggedInUserDetails(_action: any): Iterator<Effect> {
    const { getLoggedInUserDetail } = service;
    try {
        const response: any = yield call(getLoggedInUserDetail);
        yield put((loggedInUserLoadSuccess(response)));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(loggedInUserLoadFailure(e.response))
    }
}


export function* getSidebarDetails(_action: any): Iterator<Effect> {
    const { getSidebarDetail } = service;
    try {
        const response: any = yield call(getSidebarDetail);
        yield put((SidebarLoadSuccess(response)));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = landigPageUrl;
        }
        yield put(sidebarLoadFailure(e.response))
    }
}

export function* approveDonations(action: any): Iterator<Effect> {
    const { approveDonationRequest } = service;
    try {
        const response: any = yield call(approveDonationRequest, action.data);
        yield put(donationApprovalSuccess(response));
        // window.open(response);
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(donationApprovalFailure(e.response))
    }
}
export function* viewOrDownload(action: ViewOrDownloadFile): Iterator<Effect> {
    const { getFileData } = service;
    try {
        const data = yield call(getFileData, action.data);
        yield put(viewOrDownloadFileSuccess(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(viewOrDownloadFileFailure('Failed to download file'))
    }
}

export function* logoutUser(action: any): Iterator<Effect> {
    const { logout } = service;
    try {
        // @ts-ignore
        const data = yield call(logout, action.data);
        yield put(logoutUserSuccess(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(logoutUserFailure('Failed to download file'))
    }
}

export function* getSocialImpactReport(action: any): Iterator<Effect> {
    const { getSocialImpactReportById } = service;
    try {
        const data = yield call(getSocialImpactReportById, action.data);
        yield put(submissionDataSuccess(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(submissionDataFailure(`Failed to get report for id: ${action.data.id}`))
    }
}

export function* getDivisionList(action: any): Iterator<Effect> {
    const { getDivisions } = service;
    try {
        const data = yield call(getDivisions, action.data);
        // @ts-ignore
        yield put(divisionDataLoadSuccess(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(divisionDataLoadFailure('Failed to load divisions'))
    }
}

export function* setNewSelectedUserForUserGrid(data: any): Iterator<Effect> {
    yield put(selectedUserChanged(data));
}

export function* addNewUserForOrg(action: any): Iterator<Effect> {
    const { addNewOrgUser } = service;
    try {
        const addUserResponse: any = yield call(addNewOrgUser, action.data);
        // @ts-ignore
        if(addUserResponse && addUserResponse.error){
            yield put(addOrgUserFailure(addUserResponse));
        }
        else{
            yield put(addOrgUserSuccess(addUserResponse));
        }
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
    }
}

export function* editUserForOrg(action: any): Iterator<Effect> {
    const { editOrgUser } = service;
    try {
        const editUserResponse: any = yield call(editOrgUser, action.data.oldEmailId, action.data);
        // @ts-ignore
        if(editUserResponse && editUserResponse.error){
            yield put(editOrgUserFailure(editUserResponse));
        }
        else{
            yield put(editOrgUserSuccess(editUserResponse));
        }
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
    }
}

export function* removeUserForOrg(action: any): Iterator<Effect> {
    const { removeOrgUser } = service;
    try {
        const removeUserResponse: any = yield call(removeOrgUser, action.data.emailId, action.data.orgId);
        // @ts-ignore
        if(removeUserResponse && removeUserResponse.error){
            yield put(removeOrgUserFailure(removeUserResponse));
        }
        else{
            yield put(removeOrgUserSuccess(removeUserResponse));
        }
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
    }
}

export function* setAsPrimaryUserForOrg(action: any): Iterator<Effect> {
    const { setAsPrimaryOrgUser } = service;
    try {
        const setAsPrimaryOrgUserResponse: any = yield call(setAsPrimaryOrgUser, action.data.emailId, action.data.orgId);
        // @ts-ignore
        if(setAsPrimaryOrgUserResponse && setAsPrimaryOrgUserResponse.error){
            yield put(setAsPrimaryOrgUserFailure(setAsPrimaryOrgUserResponse));
        }
        else{
            yield put(setAsPrimaryOrgUserSuccess(setAsPrimaryOrgUserResponse));
        }
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
    }
}

export function* sendTemporaryPasswordForOrgUser(action: any): Iterator<Effect> {
    const { sendOrgUserTemporaryPassword } = service;
    try {
        const sendOrgUserTemporaryPasswordResponse: any = yield call(sendOrgUserTemporaryPassword, action.data.emailId, action.data.orgId);
        // @ts-ignore
        if(sendOrgUserTemporaryPasswordResponse && sendOrgUserTemporaryPasswordResponse.error){
            yield put(sendTemporaryPasswordFailure(sendOrgUserTemporaryPasswordResponse));
        }
        else{
            yield put(sendTemporaryPasswordSuccess(sendOrgUserTemporaryPasswordResponse));
        }
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
    }
}

export function* getCityStateMappingList(action: any): Iterator<Effect> {
    const { getStateList } = service;
    try {
        const data = yield call(getStateList, action.data);
        // @ts-ignore
        yield put(cityStateMappingDataLoadSuccess(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(cityStateMappingDataLoadFailure('Failed to load state list'))
    }
}


export function* checkForFirstTimeLogin(_action: any): Iterator<Effect> {
    const { checkFirstTimeUserLogin } = service;
    try {
        const data = yield call(checkFirstTimeUserLogin);
        // @ts-ignore
        yield put(checkFirstTimeLogInSuccessful(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(checkFirstTimeLogInSuccessful(false));
    }
}

export function* getCountries(action: any): Iterator<Effect> {
    const { getCountry } = service;
    try {
        const data = yield call(getCountry, action.data);
        // @ts-ignore
        yield put(countriesDataLoadSuccess(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(countriesDataLoadFailure('Failed to load countries'))
    }
}

export function* saveNewOrgUserPassword(action: any): Iterator<Effect> {
    const { saveOrgUserPassword } = service;
    try {
        const data = yield call(saveOrgUserPassword,action.data);
        yield put(saveOrgUserPasswordSuccessful(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(saveOrgUserPasswordFailure(false));
    }
}

export function* fetchNgoPageData(action: any): Iterator<Effect> {
    const { fetchNgoPage } = service;
    try {
        const ngoPageData: any = yield call(fetchNgoPage, action.data);
        yield put(getNgoPageDataSuccess(ngoPageData));
    } catch (e: any) {
        yield put(getNgoPageDataFailure(e.response));
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
    }
}

export function* toUploadAssignment(action: any): Iterator<Effect> {
    const { uploadAssignment } = service;
    try {
        const responseObj: any = yield call(uploadAssignment, action.data);
        const store: any = yield select();
        yield put(uploadAssignmentSuccess({ responseObj, message: appi18n.uploadSuccessMsg[store && store.selectedLang || 'en'] }));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(uploadAssignmentFailure('Failed to upload file'));
    }
}

export function* viewOrDownloadAssignment(action: ViewOrDownloadAssignment): Iterator<Effect> {
    const { getFileDownloadData } = service;
    try {
        const data = yield call(getFileDownloadData, action.data);
        yield put(viewOrDownloadAssignmentSuccess(data));
    } catch (e: any) {
        if (e.response && e.response.status === 401) {
            window.location.href = config.ssoUrl;
        }
        yield put(viewOrDownloadAssignmentFailure('Failed to download file'))
    }
}

export function* globalDonationSaga(): Iterator<Effect> {
    yield takeEvery(NotificationDataActionTypes.NOTIFICATION_DATA_LOAD, getNotificationData);
    yield takeEvery(ConfigActionTypes.CONFIG_DATA_LOAD, getConfigData);
    yield takeEvery(TraslationActionTypes.TRANSLATION_DATA_LOAD, getTranslationData);
    yield takeEvery(registerUserActionTypes.REGISTER_USER, registerUser);

    yield takeEvery(registerUserActionTypes.REQUEST_VERIFICATION_CODE, verificationCode);
    yield takeEvery(registerUserActionTypes.VERIFY_CODE, verifyCode);
    yield takeEvery(registerUserActionTypes.RESET_PASSWORD, resetPassword);
    yield takeEvery(FaccilitySearchActionTypes.FACILITY_DATA_LOAD, getFacilityData);
    yield takeEvery(ProvinceSearchActionTypes.PROVINCE_DATA_LOAD, getProvienceData);
    yield takeEvery(ReportDataActionTypes.SEND_REPORT_DATA, downloadReport);
    yield takeEvery(SelectedDataActionTypes.SELECTED_DATA_LOAD, saveSelctedDataToStore);
    yield takeEvery(registerUserActionTypes.SELECT_AREA, getSelectArea);
    yield takeEvery(orgProfileActionTypes.ORG_PROFILE, getOrgProfile);
    yield takeEvery(orgProfileActionTypes.SAVE_ORG_PROFILE, saveOrgProfile);
    yield takeEvery(FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD, foodSurplusConfirmation);
    yield takeEvery(DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA, downloadFile);
    yield takeEvery(LoggedInUserActionTypes.LOGGED_IN_USER_DATA_LOAD, getLoggedInUserDetails);
    yield takeEvery(DonationApprovalActionTypes.DONATION_APPROVAL_LOAD, approveDonations);
    yield takeEvery(ManageWorkflowActionTypes.VIEW_OR_DOWNLOAD_FILE, viewOrDownload);
    yield takeEvery(LogoutUserActionTypes.USER_LOGOUT_LOADING, logoutUser);
    yield takeEvery(DeleteFileActionTypes.DELETE_FILE, removeFile);
    yield takeEvery(SubmissionDataActionTypes.SUBMISSION_DATA_LOAD, getSocialImpactReport);
    yield takeEvery(DivisionDataActionTypes.DIVISION_DATA_LOAD, getDivisionList);
    yield takeEvery(OrgUserManagementActionTypes.CHANGE_SELECTED_USER, setNewSelectedUserForUserGrid);
    yield takeEvery(OrgUserManagementActionTypes.ADD_ORG_USER, addNewUserForOrg);
    yield takeEvery(OrgUserManagementActionTypes.EDIT_ORG_USER, editUserForOrg);
    yield takeEvery(OrgUserManagementActionTypes.REMOVE_ORG_USER, removeUserForOrg);
    yield takeEvery(OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER, setAsPrimaryUserForOrg);
    yield takeEvery(OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD, sendTemporaryPasswordForOrgUser);
    yield takeEvery(orgProfileActionTypes.CITY_STATE_MAPPING_DATA_LOAD, getCityStateMappingList);
    yield takeEvery(OrgUserManagementActionTypes.CHECK_FIRST_TIME_LOGIN, checkForFirstTimeLogin);
    yield takeEvery(OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD, saveNewOrgUserPassword);
    yield takeEvery(DonationOfGoodsActionTypes.GET_NGO_PAGE_DATA, fetchNgoPageData);
    yield takeEvery(UploadDownloadActionTypes.UPLOAD_ASSIGNMENT, toUploadAssignment);
    yield takeEvery(UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT, viewOrDownloadAssignment);
}

export default [
    globalDonationSaga,
    ...sagas
];
