import * as React from 'react';
import './styles.css';

export interface UserNameProps {
    name: any
}

const UserName: React.SFC<UserNameProps> = ({name}) => {
    return <div className='sidebar-username'>{name}</div>
}

export default UserName;