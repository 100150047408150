import { getTodaysDate } from '../../../components/common/DateUtils'
import { resolvePath } from '../../../components/common/utils'

export const getFormattedAppendixCHistoryList = (data: any) => {

    const appendixCHistoryList: any = []
    const appenidxArr = Object.entries(data)
    appenidxArr?.forEach((row: any, index:number) => {
        appendixCHistoryList.push({
            id:index,
            date: row?.[0],
            appendixC: row?.[1]
        })
    })
    return appendixCHistoryList?.reverse()
}

export const getFormattedAppendixCData = (data: any) => {
    return {
        orgLegalName: resolvePath('appendixC.orgLegalName', data, ''),
        telephone: resolvePath('appendixC.telephone', data, ''),
        donationReceivedDate: resolvePath('appendixC.donationReceivedDate', data, ''),
        reasonOfDonation: resolvePath('appendixC.reasonOfDonation', data, ''),
        address: {
            country: resolvePath('appendixC.address.country', data, ''),
            addressLine1: resolvePath('appendixC.address.addressLine1', data, ''),
            postalCode: resolvePath('appendixC.address.postalCode', data, ''),
        },
        recipientInfo: {
            name: resolvePath('appendixC.recipientInfo.name', data, ''),
            signature: resolvePath('appendixC.recipientInfo.signature', data, ''),
            date: resolvePath('appendixC.recipientInfo.date', data, getTodaysDate()),
        },
        companyEmployee: {
            name: resolvePath('appendixC.companyEmployee.name', data, ''),
            title: resolvePath('appendixC.companyEmployee.title', data, ''),
            date: resolvePath('appendixC.companyEmployee.date', data, getTodaysDate()),
            signature: resolvePath('appendixC.companyEmployee.signature', data, ''),
        },
        fileInfo: resolvePath('appendixC.fileInfo', data, '')
    }
}
export const getFormattedAppendixCDates = (dates: any[]) => {
    const formattedDates: any[] = []
    dates?.forEach((date: string) => {
        formattedDates.push({
            label: date,
            value: date
        })
    })
    return formattedDates;
}
