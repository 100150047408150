import { AppendixBRequestDTO } from '../../../../models/iOneTimeFoodDTO';
import { convertFromUtcToLocal, getTodaysDate } from '../../../../components/common/DateUtils';
import IOrganizationInfo from '../../../../models/iOrganizationInfo'


const defaultBoarMemberData = [{
  userName: '',
  userTitle: '',
  birthYear: null,
  cityOfResidence: '',
  stateOfResidence: '',
  countryOfResidence: ''
}]

export const getAppendixBDataFromOrgData =
(orgData: IOrganizationInfo,_type: string, isNew = true): any => {
  const appendixB: AppendixBRequestDTO = {
    donationTitle: '',
    amount: undefined,
    description: '',
    listOfCountries: '',
    purpose: '',
    organization: {
      ...orgData,
      boardMembers: orgData?.boardMembers ?? defaultBoarMemberData,
      executiveOfficers: orgData?.executiveOfficers ?? defaultBoarMemberData,
      keyPersons: orgData?.keyPersons ?? defaultBoarMemberData,
    },
  } as AppendixBRequestDTO;
  appendixB.organization.establishedDate = convertFromUtcToLocal(appendixB.organization.establishedDate);
  // @ts-ignore
  delete appendixB.organization.consentSignee;
  // @ts-ignore
  delete appendixB.organization.caUserCertificateSignee;
  // @ts-ignore
  delete appendixB.organization.certificateSignee;
  // @ts-ignore
  delete appendixB.organization.appendixBFillReason;

  const today = getTodaysDate();
  appendixB.organization.consentSignedDate =  isNew ? today:  convertFromUtcToLocal(appendixB.organization.consentSignedDate);
  appendixB.organization.certificateSignedDate =   isNew ?
  today:  convertFromUtcToLocal(appendixB.organization.certificateSignedDate);
  appendixB.organization.caUserCertificateSignedDate =   isNew ?
  today: convertFromUtcToLocal(appendixB.organization.caUserCertificateSignedDate);
  return appendixB;
}


