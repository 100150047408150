/* eslint-disable */
import { Link } from "@material-ui/core";
import { FormControl, FormLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Alert, Button,
  ButtonGroup, GridColumn, TextArea, TextField
} from "@walmart-web/livingdesign-components";
import moment from 'moment';
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonLDModal from "src/components/common/CommonLDModal";
import { contextPath } from "../../../constants";
import DatePickerComponent from "../../common/DatePickerComponent";
import { FacilitySearchComponent } from "../../common/FacilitySearchComponent";
import RadioButtonComponent from "../../common/RadioButtons";
import TimePickerComponent from "../../common/TimePickerComponent";
import StoreContactDetails from "../CommonComponents/StoreContact";
import { LocationTypes, createButtons, getFacilityLabel, getFacilityTypes, selectionChoices, get2DaysAfterDate } from "../commonFunctions";
import {
  continueModal, discardModal, spacePageType
} from "./SpaceInternationalDataFunctions";
import {
  checkCalendarTimeValidation, checkValidation, createSpaceDonationPayload, errorAttributes, getEndTime, getStartTime, isRDALocationAndStoreMandatory, spaceDonationFormAttributes, spaceFormValidation
} from "./SpaceInternationalFormValidation";
import "./styles.css";
//import CustomElementRederer from './CustomElementType';

export interface OwnProps {
  countryCode: any,
  selectedLang: any,
  isReadOnly?: any;
  isContinued?: any;
  history?: any;
  isLocationMandatory?: any;
  createDonationMutate?: any;
  formAttributes?: any;
  data?: any;
  editable?: any;
}

const SpaceDonationForm: FC<OwnProps> = ({
  countryCode,
  selectedLang,
  //orgType,
  history,
  isReadOnly, // true only for View donation
  isLocationMandatory,
  createDonationMutate,
  //formAttributes,
  data,
  editable
}) => {
  let donationData = spaceDonationFormAttributes();
  if (!editable) {
    donationData = {
      ...data?.donationDetails,
      eventEndDate: data?.eventEndDate,
      eventStartDate: data?.eventStartDate,
      eventEndTime: data?.eventEndTime,
      eventStartTime: data?.eventStartTime,
      errorMessage: errorAttributes
    };
  }
  const { t } = useTranslation();
  const [donationForm, setDonationFrom] = useState<any>(donationData);

  const setDonationAttributes = (name: string, value: any, message?: string) => {
    let messages = { ...donationForm.errorMessage, [name]: message };
    let newAttributes = { ...donationForm, [name]: value, errorMessage: messages };
    setDonationFrom(newAttributes);
  }

  const [facilityType, setFacilityType] = useState<any>(LocationTypes.store);
  const [showStoreSearchDialog, setShowStoreSearchDialog] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<any>({})

  const handleFacilityChange = (faciltyDetails: any) => {
    setSelectedFacility({
      ...faciltyDetails,
      loading: true
    })
    setShowStoreSearchDialog(false);
  }

  const handleModalAction = (show: boolean) => {
    setShowStoreSearchDialog(show)
  }

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<any>({});

  const cancelDonationCallback = () => {
    let createModalProps = discardModal(t, setIsPopupOpen, onFormDiscard);
    setModalProps(createModalProps);
    setIsPopupOpen(true);
  }

  const setLocationChoice = (dataValue: string) => {
    setDonationAttributes("locationKnown", dataValue);
  }
  // Submit for add donations flow
  const onFormSubmit = () => {
    let payload = createSpaceDonationPayload(donationForm, selectedFacility, facilityType);
    const form = new FormData();
    form.append("baseSpaceAndServiceDonationRequest", JSON.stringify(payload));
    createDonationMutate.mutate(form);
    setIsPopupOpen(false);
  };

  const continueDonationCallback = () => {
    let newAttributes = spaceFormValidation(donationForm, t, facilityType, isLocationMandatory, selectedFacility?.facilityNo);
    let isAnyAttributeHasError = checkValidation(newAttributes);
    if (isAnyAttributeHasError) {
      let modifiedAttributes = { ...donationForm, ...newAttributes };
      setDonationFrom(modifiedAttributes);
    } else {
      setIsPopupOpen(true);
      let submitModalProps = continueModal(t, setIsPopupOpen, onFormSubmit);
      setModalProps(submitModalProps);
    }
  }

  const initialStartDate = get2DaysAfterDate();
  const [defaultStartTime, setDefaultStartTime] = useState<any>(isReadOnly ? moment(donationForm.eventStartTime, "hh:mm A").toDate() : getStartTime());
  const [defaultEndTime, setDefaultEndTime] = useState<any>(isReadOnly ? moment(donationForm.eventEndTime, "hh:mm A").toDate() : getEndTime());
  const isReadOnlyClassName = isReadOnly ? `date-time-text-label-disabled` : `date-time-text-label`;

  const updateCalendarTime = (e: any, attributeName: string) => {
    let timeToString = e.value.toString();
    if (attributeName === "eventStartTime") {
      setDefaultStartTime(e.value);
      let calendarChangedTime = new Date(e.value);
      calendarChangedTime.setMinutes(calendarChangedTime.getMinutes() + 30);
      setDefaultEndTime(calendarChangedTime);
      // Below 2 lines of code is specifically to update the Error message of Event End Time, when start Time is changed
      let endTimeErrorMessage = checkCalendarTimeValidation(timeToString, donationForm.eventEventTime) ? t("spaceDonations.form.error.eventEndTime") : "";
      let messages = { ...donationForm.errorMessage, "eventEndTime": endTimeErrorMessage };

      setDonationFrom({ ...donationForm, "eventStartTime": timeToString, "eventEndTime": calendarChangedTime.toString(), errorMessage: messages });
    } else {
      let endTimeErrorMessage = checkCalendarTimeValidation(donationForm.eventStartTime, timeToString) ? t("spaceDonations.form.error.eventEndTime") : "";
      setDonationAttributes("eventEndTime", timeToString, endTimeErrorMessage);
    }
  }

  const updateCalendarDate = (e: any, attributeName: string) => {
    if (attributeName === "eventStartDate") {
      let calendarChangedDate = new Date(e.value);
      setDonationFrom({ ...donationForm, "eventStartDate": e.value.toString(), "eventEndDate": calendarChangedDate.toString() });
    } else {
      setDonationFrom({ ...donationForm, "eventEndDate": e.value.toString() });
    }
  }


  const onFormDiscard = () => {
    setIsPopupOpen(false);
    history.push(
      `${contextPath}/dashboard/${spacePageType}`
    )
  };

  const isLocationAndStoreMandatory = () => {
    let isMandatory = isRDALocationAndStoreMandatory(isLocationMandatory, donationForm.locationKnown,
      facilityType, selectedFacility?.facilityNo);
    return isMandatory;
  };

  let spaceFormButtons = createButtons(cancelDonationCallback, continueDonationCallback,
    t("spaceDonations.form.cancel"), t("spaceDonations.form.continue"), "", "primary")

  useEffect(() => {
    setSelectedFacility({});
  }, [facilityType])


  const clearDonationFormEmailAttributes = () => {
    let messages = { 'primaryEmail': '', 'secondaryEmail': '', 'primaryEmailError': '', 'secondaryEmailError': '' };
    let newAttributes = { ...donationForm, ...messages };
    setDonationFrom(newAttributes);
  }

  return (
    <>
      <div className="space-donation-Form">

        {isReadOnly ? "" :
          <> <GridColumn
            sm={12}
            key={"space-donation-location-selection-radio"}
            className={"space-donation-location-selection"}>
            <RadioButtonComponent setSelectedItem={setLocationChoice}
              title={t("spaceDonations.location.locationRadioTitle")}
              selectedItem={"No"} radioTypeInputs={selectionChoices(t)} isReadOnly={isReadOnly}
            />
          </GridColumn>
            <br />
            {!isReadOnly && donationForm.locationKnown === "Yes" && (
              <Alert variant='warning'>
                {t("spaceDonations.location.warning1")} {" " + getFacilityLabel(t, facilityType) + " "}{t("spaceDonations.location.warning2")}
              </Alert>
            )}
            {donationForm.locationKnown === "Yes" ?
              <>
                <GridColumn
                  sm={12}
                  key={"store-ho-dc-homeoffice-selection"}
                  style={{ margin: "15px 0px 7px 0px" }}>
                  <RadioButtonComponent setSelectedItem={setFacilityType}
                    title={t("spaceDonations.location.fromWhere")}
                    selectedItem={facilityType}
                    radioTypeInputs={getFacilityTypes(t)} facilityNumber={selectedFacility?.facilityNo}
                    callBack={clearDonationFormEmailAttributes} />
                </GridColumn>
                <GridColumn
                  sm={12}
                  key={"store-ho-dc-homeoffice-selection-type"}
                  style={{ margin: "15px 0px" }}
                  className="locationSelectionButton">
                  <Link color="inherit" component="button" variant="inherit"
                    onClick={() => {
                      handleModalAction(true);
                    }}
                    data-testid={"selectedRadioElementType"} className={"selectionButton"}>
                    {t(`common.selectFacility.${facilityType}`)}{isLocationAndStoreMandatory() ? "*":""}
                    
                  </Link>
                  {isLocationAndStoreMandatory() ? <FormHelperText className="helperText"> {donationForm.errorMessage.locationKnown}</FormHelperText> : ""}
                </GridColumn>
                {selectedFacility?.facilityNo &&
                  <>
                    <GridColumn sm={12}
                      style={{ margin: "10px 0px", padding: "0px 4px" }}>
                      <b>{t("spaceDonations.cards.facility")}</b>:{" "}{selectedFacility?.facilityNo}<br />
                      <b>{t("spaceDonations.cards.facilityType")}</b>:{" "}
                      {selectedFacility?.facilityType}
                      <br />
                      <b>{t("spaceDonations.cards.streetAddr")}</b>:{" "}{selectedFacility?.streetAddress}
                      <br />
                      <b>{t("spaceDonations.cards.city")}</b>:{" "}{selectedFacility?.city}
                      <br />
                      <b>{t("spaceDonations.cards.postalCode")}</b>:{" "}
                      {selectedFacility?.postalCode}
                      <br />
                      <b>{t("spaceDonations.cards.country")} </b>:{" "}
                      {selectedFacility?.country}
                    </GridColumn>
                    <StoreContactDetails
                      disabled={false}
                      readOnly={false}
                      contactDetails={donationForm}
                      setContactDetails={setDonationFrom}
                      isStoreSelected={facilityType.toLowerCase() === "store" && selectedFacility?.facilityNo}
                      languageObj={"spaceDonations"}
                    /></>
                }
              </> : ""}
          </>}
        <GridColumn
          sm={6}
          key={"eventStartDateFields"}
          className={"custom-margin"}>
          <FormControl className="space-form ">
            <FormLabel className={isReadOnlyClassName}>{t("spaceDonations.form.startDate")} {isReadOnly ? "" : <span> * </span>}</FormLabel>
            <DatePickerComponent dateFilter={isReadOnly? moment(donationForm.eventStartDate, "YYYY-MM-DD").toDate(): new Date(donationForm.eventStartDate)}
              updateCalendarDate={updateCalendarDate}
              attributeName="eventStartDate" errorMessage={donationForm.errorMessage.eventStartDate}
              minDate={new Date(initialStartDate)} isDisabled={isReadOnly} />
          </FormControl>
        </GridColumn>
        <GridColumn
          sm={6}
          key={"evenEndDateField"}
          className={'custom-margin'}>
          <FormControl className="space-form ">
            <FormLabel className={isReadOnlyClassName}>{t("spaceDonations.form.endDate")} {isReadOnly ? "" : <span> * </span>}</FormLabel>
            <DatePickerComponent dateFilter={isReadOnly? moment(donationForm.eventEndDate, "YYYY-MM-DD").toDate(): new Date(donationForm.eventEndDate)}
              updateCalendarDate={updateCalendarDate}
              attributeName="eventEndDate" errorMessage={donationForm.errorMessage.eventEndDate}
              minDate={new Date(donationForm.eventEndDate)} allowEndDate={new Date(donationForm.eventStartDate)}
              isDisabled={isReadOnly} />
          </FormControl>
        </GridColumn>
        {
          !isReadOnly &&
            <GridColumn
              sm={12}
              key={"donationInfo"}
              className={'custom-margin'}>
              <Alert variant="info">
                {t('spaceDonations.form.info')}
            </Alert>
            </GridColumn>
        }
        <GridColumn
          sm={6}
          key={"eventStartTimeField"}
          className={'custom-margin'}>
          <FormControl className="space-form ">
            <FormLabel className={isReadOnlyClassName}>{t("spaceDonations.form.startTime")}{isReadOnly ? "" : <span> * </span>}</FormLabel>
            <TimePickerComponent eventTime={defaultStartTime} updateTime={updateCalendarTime}
              errorMessage={donationForm.errorMessage.eventStartTime} attributeName={"eventStartTime"}
              isDisabled={isReadOnly} />
          </FormControl>
        </GridColumn>
        <GridColumn
          sm={6}
          key={"eventEndtimeField"}
          className={'custom-margin'}>
          <FormControl className="space-form ">
            <FormLabel className={isReadOnlyClassName}>{t("spaceDonations.form.endTime")} {isReadOnly ? "" : <span> * </span>}</FormLabel>
            <TimePickerComponent eventTime={defaultEndTime} updateTime={updateCalendarTime}
              errorMessage={donationForm.errorMessage.eventEndTime} attributeName={"eventEndTime"}
              minDate={defaultStartTime} isDisabled={isReadOnly} />
          </FormControl>
        </GridColumn>
        <GridColumn
          sm={12}
          key={"space-localTime-Label"}>
          <FormHelperText className="formLocaltimeText">{t("spaceDonations.form.eventWarningMessage")} </FormHelperText>
        </GridColumn>
        <GridColumn
          sm={12}
          key={"start-event-name"}
          style={{ margin: "15px 0px" }}>
          <TextField
            data-testid="space-donation-event-input"
            label={`${t("spaceDonations.form.event")}*`}
            onChange={(event: any) => setDonationAttributes("eventName", event.target.value,
              event.target.value ? "" : t("spaceDonations.form.error.eventName"))}
            size="large"
            value={donationForm.eventName}
            className={`${donationForm.errorMessage.eventName ? "space-input-required" : ""}`}
            disabled={isReadOnly}
            readOnly={isReadOnly}
          />
          <FormHelperText className="helperText">{donationForm.errorMessage.eventName}</FormHelperText>
        </GridColumn>
        <GridColumn
          sm={12}
          key={"start-event-purpose"}
          style={{ margin: "15px 0px" }}>
          <TextArea
            data-testid="space-donation-purpose-input"
            label={`${t("spaceDonations.form.purpose")}*`}
            onChange={(event: any) => setDonationAttributes("purposeOfEvent", event.target.value,
              event.target.value ? "" : t("spaceDonations.form.error.purposeOfEvent"))}
            size="large"
            value={donationForm.purposeOfEvent}
            disabled={isReadOnly}
            readOnly={isReadOnly}
            className={`${donationForm.errorMessage.purposeOfEvent ? "space-input-required" : ""}`}
          />
          <FormHelperText className="helperText">{donationForm.errorMessage.purposeOfEvent}</FormHelperText>
        </GridColumn>
        {isReadOnly ? "" :
          <GridColumn sm={12} key={"space-international-submit-event-purpose"}
            style={{ margin: "15px 0px" }}>
            <ButtonGroup style={{justifyContent: "space-between"}}>
              {spaceFormButtons.map((button: any) => {
                return (
                  <Button
                    variant={button.variant}
                    data-testid={button.buttoinId}
                    onClick={() => button.callback()}
                    leading={''}
                  >
                    {button.text}
                  </Button>
                )
              })}
            </ButtonGroup>
          </GridColumn>}

        {showStoreSearchDialog && <FacilitySearchComponent
          countryCode={countryCode}
          selectedLang={selectedLang}
          onFaciltySelection={handleFacilityChange}
          handleModalAction={handleModalAction}
          showModal={showStoreSearchDialog}
          fetchType={facilityType.toLowerCase()}
        />}
      </div>
      <CommonLDModal setOpen={setIsPopupOpen} isOpen={isPopupOpen}
        modalConfig={modalProps} ></CommonLDModal>
    </>

  );
};

export default SpaceDonationForm;