import { Theme, makeStyles, Paper, Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import Table from '../../common/Table';
import { DonationDashboardProps } from './Type';
import { home } from '../utils';
import { useTranslation } from 'react-i18next';
import { appFontFamily, DISPLAY_INLINE_FLEX } from '../../../styles/styleUtil';
import CustomTab, { a11yProps } from '../../common/formComponents/CustomTab';
import CustomTabs from '../../common/formComponents/CustomTabs';
import CustomTabPanel from '../../common/formComponents/CustomTabPanel';
import { BreadcrumbsComponent } from '../../common/index';;


const useStyles = makeStyles((_theme: Theme) => ({
  budgetInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    top: '10px',
  },
  budgetInfoBox: {
    display: DISPLAY_INLINE_FLEX,
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: 'max-content',
    fontFamily: appFontFamily,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    flexGrow: 1,
    flexBasis: 0,
  },
  createDonationBtn: {
    height: '40px',
    borderRadius: '20px',
    backgroundColor: '#0071ce',
    textTransform: 'uppercase',
    color: '#fff',
    border: 'solid 1px',
    fontSize: '14px',
    fontWeight: 'bold',
    width: 'auto'
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1200,
    opacity: 0.4
  },
  root: {
    flexGrow: 1,
    backgroundColor: _theme.palette.background.paper,
    margin: '10px',
    borderRadius: '8px'
  }
}));

const DonationDashboard: FC<DonationDashboardProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);
  const createNewDonation = () => props.history.push(props?.createNewDonationUrl);

  const donationNameKey: { [key: string]: string } = {
    DONATION_OF_MONEY: 'navigations.donationTypes.title.monetaryDonation',
    DONATION_OF_GOODS_RECURRING: 'navigations.donationTypes.title.goodsRecurringDonation',
    FOOD_ONE_TIME: 'navigations.donationTypes.title.oneTimeFoodDonation',
    DONATION_OF_GOODS_ONE_TIME: 'navigations.donationTypes.title.oneTimeGoodsDonation'
  }

  const dashboardPageBreadcrumbs = [
    {
      name: home,
      path: '/',
    },
    {
      name: donationNameKey[props.type],
      active: true,
      applyFormat: false
    }
  ];

  useEffect(() => {
    // @ts-ignore
    props?.closeApprovalDialog()
  }, [props.type])


  return (<>
    <div style={{ paddingTop: '5px', fontWeight: 'bold', fontSize: '20px', margin: '10px' }}>
      <BreadcrumbsComponent breadcrumbsData={dashboardPageBreadcrumbs} history={props.history} />
    </div>
    <div style={{ paddingTop: '5px', fontWeight: 'bold', fontSize: '20px', margin: '10px' }}>
      {t(donationNameKey[props.type])}
    </div>

    {props?.showCreateNewBtn && <div style={{ marginLeft: '10px', display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>
      <button type='button'
        data-testid='request-onetimeFood'
        onClick={createNewDonation}
        className={classes.createDonationBtn}
        id='request-onetimeFood'
        style={{ minWidth: '0px', width: 'auto', textTransform: 'none', padding: '0px 20px 0px 20px' }}>
        {t('oneTimeGoodsDonationDashboard.requestNewBtnTxt')}
      </button>
    </div>}

    {/* Dashboard section */}
    <div className={classes.root}>
      <Box
        style={{ borderRadius: '8px' }}
        boxShadow={0.5}
        bgcolor='background.paper'
        m={1}
        p={1}>
        <Paper square={true}>
          <CustomTabs
            value={value}
            indicatorColor='primary'
            textColor='primary'
            onChange={handleChange}
            aria-label='one time food tabs'
          >
            <CustomTab
              label={t('oneTimeGoodsDonationDashboard.tabs.myDonationTitle')}
              {...a11yProps(0, 'one-time-food', 'one time food')}
            />
            <CustomTab
              label={t('oneTimeGoodsDonationDashboard.tabs.allDonationTitle')}
              {...a11yProps(1, 'one-time-food', 'one time food')}
            />
          </CustomTabs>
        </Paper>
        <CustomTabPanel value={value} index={0}>
          <Table
            {...{ ...props?.myDonationTableProps }}
            history={props.history}
            addDataToStore={props?.addDataToStore}
            reviewDonationUrl={props?.reviewDonationUrl}
            ngoAcknowledgementReviewURL={props?.ngoAcknowledgementReviewURL}
            type={props.type}
            showStatusFilter={false}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Table
            {...{ ...props?.allDonationTableProps }}
            history={props.history}
            addDataToStore={props?.addDataToStore}
            reviewDonationUrl={props?.reviewDonationUrl}
            ngoAcknowledgementReviewURL={props?.ngoAcknowledgementReviewURL}
            type={props.type}
            showStatusFilter={true}
          />
        </CustomTabPanel>
      </Box>
    </div>
  </>
  )
}

export default React.memo(DonationDashboard);
