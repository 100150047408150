import { Action } from 'redux';

export enum FaccilitySearchActionTypes {
    FACILITY_DATA_LOAD = 'FACILITY_DATA_LOAD',
    FACILITY_LOAD_SUCCESS = 'FACILITY_LOAD_SUCCESS',
    FACILITY_LOAD_FAILURE = 'FACILITY_LOAD_FAILURE'
}

export interface FacilityData extends Action {
    type: FaccilitySearchActionTypes.FACILITY_DATA_LOAD;
    data: any;
}

export const facilityData = (data: string): FacilityData => ({
    type: FaccilitySearchActionTypes.FACILITY_DATA_LOAD,
    data
});

export interface FacilityLoadSuccess extends Action {
    type: FaccilitySearchActionTypes.FACILITY_LOAD_SUCCESS;
    data: any;
}

export const facilityLoadSuccess = (data: any): FacilityLoadSuccess => ({
    type: FaccilitySearchActionTypes.FACILITY_LOAD_SUCCESS,
    data
});

export interface FacilityLoadFailure extends Action {
    type: FaccilitySearchActionTypes.FACILITY_LOAD_FAILURE;
    data?: string;
}

export const facilityLoadFailure = (data: string): FacilityLoadFailure => ({
    type: FaccilitySearchActionTypes.FACILITY_LOAD_FAILURE,
    data
});

export type FacilitySearchActions = FacilityData | FacilityLoadSuccess | FacilityLoadFailure;