import { Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import InformationBar from '../common/informationBar';
import { InputTextField } from 'src/components/common/Input/InputTextField';
import { sectionClasses } from '../common/styles';
import { BaseSectionProps } from './orgDetailsDefaults';

export interface OrganizationMissionProps extends BaseSectionProps {
    data: any
}

export const OrganizationMission: FC<OrganizationMissionProps> = (props) => {
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField')
    const getSnackbar = (translationKey: string) => {
        return (<InformationBar>
            {getTranslation(translationKey)}</InformationBar>);
    }

    return (<div className={sectionClasses.root}>
        <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
                <Typography classes={{ root: sectionClasses.title }}>{getTranslation('orgMisTitle')}</Typography>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        fieldName={`${props.fieldName}[missionDetails]`}
                        label={getTranslation('orgMisPur')}
                        id={'missionDetails'}
                        multiline={true}
                        rows={2}
                        defaultValue={props?.data?.missionDetails}
                        errorKeyName={`${props.fieldName}.missionDetails`}
                        rules={{
                            required: requiredFieldMsg
                        }}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('orgMisPurDesc')}
                </Grid>
            </Grid>
        </Grid>
    </div>

    );
}