import { Box, Paper } from "@material-ui/core";
import {
    Button,
    ButtonGroup
} from "@walmart-web/livingdesign-components";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DONATION_TYPE_VALUES } from "../../../constants";
import { getDonationDataProps } from "../../../models/GenericDashBoardModel";
import withRouter from "../../../polyfils/customRouter";
import { getAppConfigTypes } from "../../../service/apiService";
import CustomTab, { a11yProps } from "../../common/formComponents/CustomTab";
import CustomTabPanel from "../../common/formComponents/CustomTabPanel";
import CustomTabs from "../../common/formComponents/CustomTabs";
import Table from '../../common/Table';
import { useLocation } from "react-router";
import { PageBanner } from "./PageBanner";
import "./style.css";
export interface OwnProps {
    history: any;
    selectedLang?: any;
    donationCategory: any;
}

const GenericDashBoardTemplate: FC<OwnProps> = ({ donationCategory, history, selectedLang }) => {
    const { t } = useTranslation();
    let { state } = useLocation();

    const dynamicNumber = state?.tabSelected || 0;
    const [value, setValue] = useState(Number(dynamicNumber));
    const data = getDonationDataProps(donationCategory, t, selectedLang, history);

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);
    const [serviceTypes, setServiceTypes] = useState<any>([]);

    useEffect(() => {
       if (donationCategory == DONATION_TYPE_VALUES.SERVICE_DONATION) {
            getAppConfigTypes('serviceType').then((res) => {
                setServiceTypes(res);
            })
        }
    }, [])

    const renderTabsToDashboard = () => {
        return (
            <>
                <Paper square={true}>
                    <CustomTabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}>
                        {data.tabs.map((tabName, index) => {
                            return (
                                tabName.isActive ?
                                    <CustomTab
                                        label={tabName.label}
                                        {...a11yProps(index, `${donationCategory}-${index}`, `${donationCategory}'-tab-'${index}`)}
                                    /> : "")
                        })}
                    </CustomTabs>
                </Paper>

                {
                    data.tabs.map((tabName, index) => {
                        return (tabName.isActive ? <CustomTabPanel value={value} index={index}>
                            <Table
                                {...tabName.tableFilters}
                                history={history}
                                type={donationCategory}
                                showStatusFilter={true}
                                data-testid={tabName}
                                serviceTypes={serviceTypes}
                            />
                        </CustomTabPanel> : null)
                    })
                }
            </>
        )
    }

    const renderPlainTableToDashboard = () => {
        return (
            <>
                {
                    data.tabs.map((tabName: any) => {
                        return (tabName.isActive ?
                            <Table
                                {...tabName.tableFilters}
                                history={history}
                                type={donationCategory}
                                showStatusFilter={true}
                                data-testid={tabName}
                                serviceTypes={serviceTypes}
                            />
                            : null)
                    })
                }</>
        )
    }

    return (
        <div>
            <PageBanner breadcrumbsData={data.breadcrumbs} history={history} heading={data.pageTitle} />
            <ButtonGroup className={`generic-template-dashboard-top-right-button`}>
                {data.topRightHeaderButtons.map((customButtons: any) => {
                    return (
                        <Button onClick={customButtons.callback} variant={customButtons.variant}
                            data-testid={customButtons.buttonId} className={customButtons.className}>
                            {customButtons.buttonText}
                        </Button>)
                })}
            </ButtonGroup>

            <div className={`generic-template-dashboard`}>
                <Box style={{ borderRadius: '8px' }} boxShadow={0.5} bgcolor='background.paper' m={1} p={1}>
                    {data.isTabs ? renderTabsToDashboard() : renderPlainTableToDashboard() }
                </Box>
            </div>

        </div>

    );
};
export default withRouter(GenericDashBoardTemplate);
