export const getDuplicateFacilityDetails = (getValues:any) => {
        const duplicateFacilities: any = {};
        const facilityList: any = getValues().donationReceiptSummaryList || [];
        facilityList.forEach((item: any, index: number) => {
            if (!(item.facilityNo === '')) {
                if (duplicateFacilities[item.facilityNo]) {
                    duplicateFacilities[item.facilityNo] = {
                        ...duplicateFacilities[item.facilityNo],
                        isDuplicate: true,
                    }
                } else {
                    duplicateFacilities[item.facilityNo] = {
                        isDuplicate:false,
                        value: item.facilityNo,
                        index
                    };
                }
            }
        });
        return duplicateFacilities;
}

export const validateTextLimit = (text:string, size:number) => {
    return text && text.length > size ? false : true;
}