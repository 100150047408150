import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomAccordion } from '../../../common/Accordion/CustomAccordion';
import { ProjectBeneficiaries } from './projectBeneficiaries';
import { ProjectBeneficiariesParticipation } from './projectBeneficiariesParticipation';
import { ProjectCoInvestors } from './projectCoInvestors';
import { ProjectDetailsTabProps, Sections } from './projectDetailsDefaults';
import { ProjectExecution } from './projectExecution';
import { ProjectImpactAndContinuity } from './projectImpactAndContinuity';
import { ProjectJustification } from './projectJustification';
import { ProjectLocation } from './projectLocation';
import { ProjectObjectives } from './projectObjectives';
import { ProjectScheduleOfActivities } from './projectScheduleOfActivities';


const MonetaryDonationTab: FC<ProjectDetailsTabProps> = ({projectDetailsData, isFormEditable, errors}) => {

    const { t } = useTranslation();
    const getTranslations = (key: string) => {
        return t(`monetaryDonation.projectDetailsSection.${key}`)
    }
    return <Fragment>
            <CustomAccordion title={getTranslations('projectObjectives')}
                id = 'projectObjectives'
                hasError={(Sections.ProjectObjectives in errors)} isEditable={isFormEditable}>
                <ProjectObjectives fieldName={Sections.ProjectObjectives} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectJustification')} isEditable={isFormEditable}
                id = 'projectJustification'
                hasError={(Sections.ProjectJustification in errors)}>
                <ProjectJustification fieldName={Sections.ProjectJustification}
                    data={projectDetailsData.justificationDetails} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectScheduleOfActivities')} isEditable={isFormEditable}
                id = 'projectScheduleOfActivities'
                hasError={(Sections.ProjectScheduleOfActivities in errors
                    || Sections.ProjectScheduleOfActivitiesCategory in errors
                    || Sections.ProjectScheduleOfActivitiesSubCategory in errors
                    || Sections.ProjectJustificationTotals in errors)}>
                <ProjectScheduleOfActivities fieldName={Sections.ProjectScheduleOfActivities}
                    data={projectDetailsData.projectScheduleOfActivities} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectExecution')} isEditable={isFormEditable}
                id = 'projectExecution'
                hasError={(Sections.ProjectExecution in errors)}>
                <ProjectExecution fieldName={Sections.ProjectExecution} data={projectDetailsData.executionDetails} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectLocation')} isEditable={isFormEditable}
                id = 'projectLocation'
                hasError={(Sections.ProjectLocation in errors)}>
                <ProjectLocation fieldName={Sections.ProjectLocation} data={projectDetailsData.locations} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectBeneficiaries')} isEditable={isFormEditable}
                id = 'projectBeneficiaries'
                hasError={(Sections.ProjectBeneficiaries in errors)}>
                <ProjectBeneficiaries fieldName={Sections.ProjectBeneficiaries}
                    data={projectDetailsData.beneficiaries} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectBeneficiariesParticipation')} isEditable={isFormEditable}
                id = 'projectBeneficiariesParticipation'
                hasError={(Sections.ProjectBeneficiariesParticipation in errors)}>
                <ProjectBeneficiariesParticipation fieldName={Sections.ProjectBeneficiariesParticipation}
                    data={projectDetailsData.projectBeneficiariesParticipant} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectCoInvestors')} isEditable={isFormEditable}
                id = 'projectCoInvestors'
                hasError={(Sections.ProjectCoInvestors in errors)}>
                <ProjectCoInvestors fieldName={Sections.ProjectCoInvestors} data={projectDetailsData.coInvestors} />
            </CustomAccordion>
            <CustomAccordion title={getTranslations('projectImpactAndContinuity')} isEditable={isFormEditable}
                id = 'projectImpactAndContinuity'
                hasError={(Sections.ProjectImpactAndContinuity in errors)}>
                <ProjectImpactAndContinuity fieldName={Sections.ProjectImpactAndContinuity}
                    data={projectDetailsData.impactContinuityDetails} />
            </CustomAccordion>
    </Fragment>
}

export default MonetaryDonationTab;