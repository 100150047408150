import { Action } from 'redux';

export enum LoggedInUserActionTypes {
    LOGGED_IN_USER_DATA_LOAD = 'LOGGED_IN_USER_DATA_LOAD',
    LOGGED_IN_USER_LOAD_SUCCESS = 'LOGGED_IN_USER_LOAD_SUCCESS',
    LOGGED_IN_USER_LOAD_FAILURE = 'LOGGED_IN_USER_LOAD_FAILURE'
}

export interface LoggedInUserData extends Action {
    type: LoggedInUserActionTypes.LOGGED_IN_USER_DATA_LOAD;
}

export const loggedInUserData = (): LoggedInUserData => ({
    type: LoggedInUserActionTypes.LOGGED_IN_USER_DATA_LOAD,
});

export interface LoggedInUserLoadSuccess extends Action {
    type: LoggedInUserActionTypes.LOGGED_IN_USER_LOAD_SUCCESS;
    data: any;
}

export const loggedInUserLoadSuccess = (data: any): LoggedInUserLoadSuccess => ({
    type: LoggedInUserActionTypes.LOGGED_IN_USER_LOAD_SUCCESS,
    data
});

export interface LoggedInUserLoadFailure extends Action {
    type: LoggedInUserActionTypes.LOGGED_IN_USER_LOAD_FAILURE;
    data?: string;
}

export const loggedInUserLoadFailure = (data: string): LoggedInUserLoadFailure => ({
    type: LoggedInUserActionTypes.LOGGED_IN_USER_LOAD_FAILURE,
    data
});

export type LoggedInUserActions = LoggedInUserData | LoggedInUserLoadSuccess | LoggedInUserLoadFailure;