import LDButton from '../../../commonComponents/uiComponents/LDButton';
import LDModal from '../../../commonComponents/uiComponents/LDModal';
import './CampaignFooter.css';
interface ModalProps {
    headerText: string;
    bodyText: any;
    cancelBtn: any;
    applyBtn: any;
    disableFlag?:boolean;
}
function CampaignFooterModal({ headerText, bodyText, cancelBtn, applyBtn,disableFlag=false }: ModalProps) {
    return (
        <LDModal className='modal-main cancel-event-modal' title={headerText} size='medium'
        isOpen={true} onClose={cancelBtn.actionFn}
        closeButtonProps={{
          'data-testid': 'modal-close-btn',
          style: {
            zIndex: 99, paddingRight: '20px',
            paddingTop: '10px'
          }
        }}>
            <div className='modal-bodytext'>{bodyText}</div>
            <div className='modal-footer cancel-event-modal-footer'>
                <button className='reject-button'
                    data-testid='confirmation-cancel-btn'
                    onClick={cancelBtn.actionFn}>{cancelBtn.label}</button>
                <LDButton className='new-reservation-request-button' variant='primary'
                    data-testid='confirmation-btn'
                    onClick={applyBtn.actionFn} disabled={disableFlag}>{applyBtn.label}</LDButton>
            </div>
        </LDModal>
    );
}

export default CampaignFooterModal;
