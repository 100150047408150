import { DivisionDataActions, DivisionDataActionTypes } from './divisionDataActions';
import { DivisionDataState } from '../../models/globalDonationState';

export const defaultDivisionDataState: DivisionDataState = {
    divisionData: [],
    divisionDataLoading: false,
    divisionDataLoadingSuccess: false,
    divisionDataLoadingFailure: false,
};

export const divisionDataReducer = (state: DivisionDataState = defaultDivisionDataState, action: DivisionDataActions):
    DivisionDataState => {
    switch (action.type) {
        case DivisionDataActionTypes.DIVISION_DATA_LOAD: return {
            ...state,
            divisionData: [],
            divisionDataLoading: true,
            divisionDataLoadingSuccess: false,
            divisionDataLoadingFailure: false,
        };
        case DivisionDataActionTypes.DIVISION_DATA_LOAD_SUCCESS: return {
            ...state,
            divisionData: action.data,
            divisionDataLoading: false,
            divisionDataLoadingSuccess: true,
            divisionDataLoadingFailure: false,
        };
        case DivisionDataActionTypes.DIVISION_DATA_LOAD_FAILURE: return {
            ...state,
            divisionData: [],
            divisionDataLoading: false,
            divisionDataLoadingSuccess: false,
            divisionDataLoadingFailure: true,
        };
        default: return state;
    }
}