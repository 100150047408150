import React from 'react';
import { Paper, TableBody, TableCell, TableRow } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Trans, useTranslation } from 'react-i18next';
import { Heading } from '../../common';
import { numberFormat } from '../../common/utils';
import { appFontFamily } from '../utils';

export const CartonDetails = React.memo((props: any) => {
    const getCurrencyFormat = (amount: any) => numberFormat(amount || 0, props?.countryCode);
    const { t } = useTranslation();
    return (
        <div className='row' style={{ marginTop: '20px', marginBottom: '20px' }}>
            <div className='col-md-11' style={{ marginBottom: '20px' }}>
                <Heading
                    id={`cartonDetails-heading`}
                    className={`h16_bold_title cartonDetails-heading mr-bt`}
                    level={'h6'}
                >{t('tableData.cartonDetails')}</Heading>
                <Heading level={'h6'}>
                    <Trans i18nKey='oneTimeGoodsDonation.ack.cartonReceivedInfo' />
                </Heading>
                <MaterialTable
                    style={{
                        border: '0px solid #eff2f5'
                    }}
                    options={{
                        draggable: false,
                        paging: false,
                        search: false,
                        showTitle: true,
                        toolbar: false,
                        sorting: false,
                        headerStyle: {
                            fontFamily: 'var(--appFont)',
                            fontWeight: 'bold'
                        },
                    }}
                    columns={[
                        { title: t('tableData.itemNumber'), field: 'itemNumber' },
                        { title: t('tableData.itemDescription'), field: 'description' },
                        { title: t('tableData.quantity'), field: 'quantity' },
                        {
                            title: t('tableData.unitPrice'),
                            field: 'costAmount'
                        },
                        {
                            title: t('tableData.amount'),
                            field: 'totalAmount'
                        },
                    ]}
                    data={props?.cartonDetails?.donationItems}
                    components={{
                        Container: (contianerProps: any) => <Paper {...contianerProps} elevation={0} />,
                        Body: () => {
                            return (<TableBody style={{ fontFamily: appFontFamily }}>
                                {props?.cartonDetails?.donationItems?.map((row: any) => (
                                    <TableRow key={row?.itemNumber}>
                                        <TableCell>{row?.itemNumber}</TableCell>
                                        <TableCell>{row?.description}</TableCell>
                                        <TableCell>{row?.itemQuantity}</TableCell>
                                        <TableCell>
                                            {getCurrencyFormat(row?.retailAmount)}
                                        </TableCell>
                                        <TableCell>
                                            {getCurrencyFormat((row?.retailAmount) * row?.itemQuantity)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell style={{ fontFamily: appFontFamily, fontWeight: 'bold' }}>
                                        {t('tableData.totalAmount')}
                                    </TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell colSpan={0} style={{ fontFamily: appFontFamily, fontWeight: 'bold' }}>
                                        {getCurrencyFormat(props?.cartonDetails?.totalRetailAmount)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>)
                        }
                    }}
                />
            </div>
        </div>
    )
});
