import { DeleteFileActions, DeleteFileActionTypes } from './deleteFileActions';
import { DeleteFileState } from '../../models/globalDonationState';

export const defaultDeleteFileState: DeleteFileState = {
    fileData:{},
    deleteFileSuccess: false,
    deleteFileFailure: false,
    deleteFileLoading: false
};

export const deleteFileReducer = (state: DeleteFileState = defaultDeleteFileState, action: DeleteFileActions):
    DeleteFileState => {
    switch (action.type) {
        case DeleteFileActionTypes.DELETE_FILE: return {
            ...state,
            deleteFileLoading: true,
            deleteFileFailure: false,
            deleteFileSuccess: false,
        };
        case DeleteFileActionTypes.DELETE_FILE_SUCCESS: return {
            ...state,
            deleteFileLoading: false,
            deleteFileSuccess: true,
            deleteFileFailure: false,
        };
        case DeleteFileActionTypes.DELETE_FILE_FAILURE: return {
            ...state,
            deleteFileLoading: false,
            deleteFileFailure: true,
            deleteFileSuccess: false,
        };
        default: return state;
    }
}