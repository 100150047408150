//NOTE: These components are specific to MX wireframe. If need to reused at other place, move them common folder
import {createStyles, makeStyles, TableCell, TableCellProps} from '@material-ui/core';
import {FC} from 'react';

const useStyles = makeStyles(() => createStyles({
    cell: {
        padding: '6px',
        verticalAlign: 'baseline'
    },
    cellHead: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
        '&:not(:last-child):after': {
            content: '"  *"'
        },
    }
}));

export const CustomCell: FC<TableCellProps> = (props) => {
    const classes = useStyles();
    return <TableCell classes={{ sizeSmall: classes.cell }} {...props} />;
}


export const CustomHeaderCell: FC<TableCellProps> = (props) => {
    const classes = useStyles();
    return <TableCell classes={{ head: classes.cellHead }} {...props} />;
}}