import { Divider, Grid, IconButton, Paper, TableRow, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputTextField } from 'src/components/common/Input/InputTextField';

import AddRow from '../common/AddRow';
import BiLingualDropdown, { BiLingualDropdownTypes } from '../common/biLingualDropdown';
import { CustomCell } from '../common/CustomCell';
import { DatePickerField } from '../common/DatePickerField';
import MoreDetailsTable from '../common/MoreDetailsTable';
import { sectionClasses } from '../common/styles';
import { DefaultScheduleOfActivities, IScheduleOfActivitiesFields, Sections } from './projectDetailsDefaults';
import { ProjectScheduleOfActivitiesTotals } from './projectScheduleOfActivitiesTotalTable';

export interface ProjectScheduleOfActivitiesProps {
    fieldName?: string,
    data: any
}

const columnHeader = [
    { key: 'activityNumber', width: '20%' },
    { key: 'activityDescription', width: '35%' },
    { key: 'amount', width: '15%' },
    { key: 'startDate', width: '15%' },
    { key: 'endDate', width: '15%' }];
export const ProjectScheduleOfActivities: FC<ProjectScheduleOfActivitiesProps> = ({ }) => {
    const { t } = useTranslation();
    const { control, errors, setValue } = useFormContext();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
    const scheduleOfActivityFields = useFieldArray({
        name: Sections.ProjectScheduleOfActivities,
        control,
    });

    const categoryValue = useWatch<string>({
        name: Sections.ProjectScheduleOfActivitiesCategory,
        control
    });

    useEffect(() => {
        setValue(Sections.ProjectScheduleOfActivitiesSubCategory, null);
    }, [categoryValue])

    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField');
    const totals = useWatch<IScheduleOfActivitiesFields>({ control, name: Sections.ProjectScheduleOfActivities });
    const handleRemove = (index: number) => {
        scheduleOfActivityFields.remove(index);
    }
    const addRowHandler = () => {
        scheduleOfActivityFields.append(DefaultScheduleOfActivities.activities[0]);
    }

    return (<div className={sectionClasses.root}>
        <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
                <Typography
                    classes={{ root: sectionClasses.title }}>{getTranslation('scheduleActivitiesSummary')}</Typography>
            </Grid>

            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    {
                        <BiLingualDropdown
                            id='category'
                            inputLabel={getTranslation('category')}
                            control={control}
                            errors={errors}
                            fieldName={Sections.ProjectScheduleOfActivitiesCategory}
                            errorKeyName={Sections.ProjectScheduleOfActivitiesCategory}
                            optionKey={BiLingualDropdownTypes.PROJECT_SCHEDULE_ACTIVITIES}
                            required={true}
                        />
                    }
                </Grid>
                <Grid item={true} xs={12} md={4}>
                    {
                        <BiLingualDropdown
                            id='subCategory'
                            inputLabel={getTranslation('subCategory')}
                            control={control}
                            errors={errors}
                            errorKeyName={Sections.ProjectScheduleOfActivitiesSubCategory}
                            fieldName={Sections.ProjectScheduleOfActivitiesSubCategory}
                            disabled={!categoryValue}
                            optionKey={BiLingualDropdownTypes.PROJECT_SCHEDULE_ACTIVITIES}
                            isSecondary={true}
                            primaryValue={categoryValue}
                            required={true}
                            setValue={setValue}
                        />
                    }
                </Grid>
            </Grid>
            <Grid item={true} xs={12}>
                <CustomTable
                    addRowHandler={addRowHandler}
                    fieldName={Sections.ProjectScheduleOfActivities}
                    fields={scheduleOfActivityFields.fields}
                    getTranslation={getTranslation}
                    header={columnHeader}
                    removeHandler={handleRemove}
                    requiredFieldMsg={requiredFieldMsg}
                    t={t}
                    totalFieldName={'totals.activities'}
                    watchedFields={totals}
                />
            </Grid>
        </Grid>
    </div>

    );
}

// @ts-ignore
const CustomTable = ({ t, fields, fieldName, header, getTranslation, requiredFieldMsg, removeHandler, addRowHandler,
    // @ts-ignore
    totalFieldName, watchedFields }) => {
    const styles = { padding: '15px', backgroundColor: '#fbfbfb' }

    return (<>
        <Paper elevation={3} variant='elevation' style={styles}>
            <MoreDetailsTable
                getTranslation={getTranslation}
                header={header}>
                {fields.map((field: any, index: number) => {
                    const partialFieldKey = `${fieldName}[${index}]`;
                    const errorKey = `${fieldName}.[${index}]`;
                    return (<TableRow key={field.id}>
                        <CustomCell >
                            <InputTextField
                                required={true}
                                fieldName={`${partialFieldKey}.number`}
                                id={`number_${index}`}
                                defaultValue={field.number}
                                errorKeyName={`${errorKey}.number`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell>
                            <InputTextField
                                required={true}
                                fieldName={`${partialFieldKey}.description`}
                                id={`psa_desc_${index}`}
                                defaultValue={field.description}
                                errorKeyName={`${errorKey}.description`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <InputTextField
                                required={true}
                                type={'number'}
                                fieldName={`${partialFieldKey}.amount`}
                                id={`psa_amount_${index}`}
                                defaultValue={field.amount}
                                errorKeyName={`${errorKey}.amount`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <DatePickerField
                                defaultValue={field.startDate}
                                id={`psa_sd_${index}`}
                                errorKeyName={`${errorKey}.startDate`}
                                fieldName={`${partialFieldKey}.startDate`}
                            />
                        </CustomCell>
                        <CustomCell >
                            <DatePickerField
                                defaultValue={field.endDate}
                                errorKeyName={`${errorKey}.endDate`}
                                fieldName={`${partialFieldKey}.endDate`}
                                id={`psa_ed_${index}`}
                            />
                        </CustomCell>
                        <CustomCell style={{ verticalAlign: 'middle' }}>
                            <IconButton color='default' key={`delete-row_${index}`} onClick={() => removeHandler(index)}
                                disabled={fields?.length === 1}
                                aria-label='delete row' component='span'>
                                <DeleteIcon />
                            </IconButton>
                        </CustomCell>

                    </TableRow>)
                })}
            </MoreDetailsTable>
            <ProjectScheduleOfActivitiesTotals watchedFields={watchedFields} getTranslation={getTranslation}
                totalFieldName={totalFieldName} />
            <Divider classes={{ root: sectionClasses.dividerRoot }} />
            <AddRow
                handler={() => addRowHandler()}
                key='addProjSchedule'
                t={t}
            />
        </Paper>

    </>);
}