import { FC } from "react";
import { useTranslation } from "react-i18next";
import { getFacilityLabel } from "../commonFunctions";
import { formatDateTimeForReadOnly } from "./SpaceInternationalDataFunctions";

export interface LocProps {
    data?: any;
}
export const SpaceAlternateProposalDetails: FC<LocProps> = ({ data }) => {
    const { t } = useTranslation();
    let isLocationTypeChanged = data.donationDetails?.locationType !== data.donationDetails?.alternateTimeSlotHistory[0].locationType;

    let eventStartDateTime = data.eventStartDate + " " + data.eventStartTime;
    let historyStartDateTime = data.donationDetails?.alternateTimeSlotHistory[0].eventStartDate + " " + data.donationDetails?.alternateTimeSlotHistory[0].eventStartTime;
    let eventEndDateTime = data.eventEndDate + " " + data.eventEndTime;
    let historyEndDateTime = data.donationDetails?.alternateTimeSlotHistory[0].eventEndDate + " " + data.donationDetails?.alternateTimeSlotHistory[0].eventEndTime;
    let isStartDateTimeChanged = eventStartDateTime !== historyStartDateTime;
    let isEndDateTimeChanged = eventEndDateTime !== historyEndDateTime;

    // For Facility check
    let currentFacilityNumber = data.donationDetails?.facilityDetails?.facilityNo;
    let historyFacilityNumber = data.donationDetails?.alternateTimeSlotHistory[0].facilityDetails?.facilityNo;
    let isFacilityNumberChanged = currentFacilityNumber !== historyFacilityNumber;

    let isAnyScheduleTypeChanged = isLocationTypeChanged || isStartDateTimeChanged ||
        isEndDateTimeChanged || isFacilityNumberChanged;

       //let converted =  moment(data.eventStartDate, "YYYY-MM-DD").toDate();

    if (isAnyScheduleTypeChanged) {
        return (
            <>
                <div className="alternateProposalDetailsTitle">
                    <b> {t("spaceDonations.cards.alternateProposal.header")} :  </b>
                </div>
                <div className="alternateProposalDetailsLocation">
                    {isLocationTypeChanged ?
                        <div className="space-alternate-proposal">
                            <b>{t("spaceDonations.cards.alternateProposal.facility")}</b> : {
                            getFacilityLabel(t ,data.donationDetails?.locationType)}
                        </div> : ""}
                    {isFacilityNumberChanged ?
                        <div className="space-alternate-proposal">
                            <b>{t("spaceDonations.cards.alternateProposal.facilityNumber")}</b> : {currentFacilityNumber}
                        </div> : ""}
                    {isStartDateTimeChanged ? <> <div className="space-alternate-proposal">
                        <b>{t("spaceDonations.cards.alternateProposal.startDate")} </b> : {formatDateTimeForReadOnly(data.eventStartDate , data.eventStartTime)}
                    </div> </>: ""}
                    {isEndDateTimeChanged ? <div className="space-alternate-proposal">
                        <b> {t("spaceDonations.cards.alternateProposal.endDate")} </b> : {formatDateTimeForReadOnly(data.eventEndDate , data.eventEndTime)}
                    </div> : ""}
                </div>

                <div className="plannedProposalDetailsTitle">
                    <b> {t("spaceDonations.cards.alternateProposal.previousHeader")} :  </b>
                </div>
                <div className="alternateProposalDetailsLocation">
                    {isLocationTypeChanged ?
                        <div className="space-alternate-proposal">
                            <b> {t("spaceDonations.cards.alternateProposal.previousFacility")} </b> : {getFacilityLabel(t ,data.donationDetails?.alternateTimeSlotHistory[0].locationType)}
                        </div> : ""}
                    {isFacilityNumberChanged ?
                        <div className="space-alternate-proposal">
                            <b> {t("spaceDonations.cards.alternateProposal.previousFacilityNumber")}</b> : {historyFacilityNumber ? historyFacilityNumber : "NA"}
                        </div> : ""}
                    {isStartDateTimeChanged ? <div className="space-alternate-proposal">
                        <b> {t("spaceDonations.cards.alternateProposal.previousStartDate")}</b> : {formatDateTimeForReadOnly(data.donationDetails?.alternateTimeSlotHistory[0].eventStartDate, data.donationDetails?.alternateTimeSlotHistory[0].eventStartTime)}
                    </div> : ""}
                    {isEndDateTimeChanged ? <div className="space-alternate-proposal">
                        <b>  {t("spaceDonations.cards.alternateProposal.previousEndDate")}</b> : {formatDateTimeForReadOnly(data.donationDetails?.alternateTimeSlotHistory[0].eventEndDate, data.donationDetails?.alternateTimeSlotHistory[0].eventEndTime)}
                    </div> : ""}
                </div>
            </>
        )
    }
    return <></>;

}